/* auto-inject-disable */
import Di from "@Di";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import * as CareRegisterProxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientId from "@Primitives/PatientId.g";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import IOperationResult from "@Toolkit/CommonWeb/ApiAdapter/IOperationResult";
import { GetTypeNameWithPrefix } from "@AssecoMedPlugin/PluginHelper";
import * as CareProxy from "@AssecoMedPlugin/BoundedContexts/WebAppBackend/Api/Proxy.g";
import CareActivityId from "@Primitives/CareActivityId.g";

@Di.injectable()
export default class CareActivitiesForEpisodeOfCareScreenExtensionApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("ICareRegisterClient") private readonly careRegisterClient: CareRegisterProxy.ICareRegisterClient,
        @Di.inject(GetTypeNameWithPrefix("ICareClient")) private readonly careClient: CareProxy.ICareClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public isForeignTelemetrySessionInProgressAsync(
        patientId: PatientId,
        episodeOfCareId: EpisodeOfCareId
    ): Promise<IOperationResult<boolean>> {
        return this.executeOperationAsync<boolean, CareRegisterProxy.IsForeignTelemetrySessionInProgressQueryResponse>(
            () => this.careRegisterClient.isForeignTelemetrySessionInProgressQueryAsync(CreateRequestId(), new CareRegisterProxy.IsForeignTelemetrySessionInProgressControllerDto({
                patientId: patientId,
                relativeEpisodeOfCareId: episodeOfCareId
            })),
            response => response.isForeignTelemetrySessionInProgress
        );
    }

    @State.bound
    public setTelemedicineUnderCareAsync(careActivityId: CareActivityId): Promise<IOperationResult<boolean>> {
        return this.executeOperationAsync<boolean, CareProxy.SetTelemedicineUnderCareCommandResponse>(
            () => this.careClient.setTelemedicineUnderCareCommandAsync(CreateRequestId(), new CareProxy.SetTelemedicineUnderCareControllerDto({
                careActivityId: careActivityId
            })),
            response => true
        );
    }
}