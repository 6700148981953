import React, { useMemo, useCallback } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import EditableLayoutCustomBlockStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableLayoutCustomBlockStore";
import * as Ui from "@CommonControls";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import EmbeddedFormBlockSettings from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EmbeddedFormBlockSettings";

function EmbeddedFormBlockPropertyPanel() {

    const store = useFormLayoutEditorStore();

    if (!store.selectedCustomBlock || !(store.selectedCustomBlock instanceof EditableLayoutCustomBlockStore)) {
        return null;
    }

    const settings = store.selectedCustomBlock.settings as EmbeddedFormBlockSettings;  
    const setDataReferenceCallback = useCallback((value: string) => settings.setDataReference(value), [store.selectedCustomBlock]);

    return (
        <Ui.GroupBox title={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.Title} style={{ margin: 4 }}>

            <Ui.TextBox
                label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.LabelDataElementName}
                value={settings.dataReference}
                onChange={setDataReferenceCallback}
                automationId="EmbeddedFormBlockPropertyPanel_TextBox"
            />
        </Ui.GroupBox>
    );
}

export default State.observer(EmbeddedFormBlockPropertyPanel);