import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/CommonReferenceData/Api/Proxy.g";
import EducationLevelId from "@Primitives/EducationLevelId.g";
import MaritalStatusId from "@Primitives/MaritalStatusId.g";
import GenderId from "@Primitives/GenderId.g";
import ReligionId from "@Primitives/ReligionId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import AddressTypeId from "@Primitives/AddressTypeId.g";
import TelecomUseId from "@Primitives/TelecomUseId.g";
import OccupationId from "@Primitives/OccupationId.g";
import TelecomTypeId from "@Primitives/TelecomTypeId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class CommonReferenceDataExtensibleEnumApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("ICommonReferenceDataClient") private readonly commonReferenceDataApiClient: Proxy.ICommonReferenceDataClient
    ) {
        super();
    }

    @State.bound
    public loadEducationLevelIdsAsync(): Promise<SimpleStore<EducationLevelId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.commonReferenceDataApiClient.getEducationLevelIdsQueryAsync(...args),
            response => response.educationLevelIds
        );
    }

    @State.bound
    public loadMaritalStatusIdsAsync(): Promise<SimpleStore<MaritalStatusId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.commonReferenceDataApiClient.getMaritalStatusIdsQueryAsync(...args),
            response => response.maritalStatusIds
        );
    }

    @State.bound
    public loadGenderIdsAsync(): Promise<SimpleStore<GenderId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.commonReferenceDataApiClient.getGenderIdsQueryAsync(...args),
            response => response.genderIds
        );
    }

    @State.bound
    public loadReligionIdsAsync(): Promise<SimpleStore<ReligionId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.commonReferenceDataApiClient.getReligionIdsQueryAsync(...args),
            response => response.religionIds
        );
    }

    @State.bound
    public loadAddressTypesAsync(): Promise<SimpleStore<AddressTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.commonReferenceDataApiClient.getAddressTypeIdsQueryAsync(...args),
            response => response.addressTypeIds
        );
    }

    @State.bound
    public loadTelecomUsesAsync(): Promise<SimpleStore<TelecomUseId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.commonReferenceDataApiClient.getTelecomUseIdsQueryAsync(...args),
            response => response.telecomUseIds
        );
    }

    @State.bound
    public loadTelecomTypesAsync(): Promise<SimpleStore<TelecomTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.commonReferenceDataApiClient.getTelecomTypeIdsQueryAsync(...args),
            response => response.telecomTypeIds
        );
    }

    @State.bound
    public loadOccupationIdsAsync(): Promise<SimpleStore<OccupationId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.commonReferenceDataApiClient.getOccupationIdsQueryAsync(...args),
            response => response.occupationIds
        );
    }
}