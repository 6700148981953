import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import PerformanceStatementCareActivityStatusType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementCareActivityStatusType.g";

interface IPerformanceStatementCareActivityStatusTypeMultiSelectProps extends ISelectBoxBaseProps {
    value?: PerformanceStatementCareActivityStatusType | PerformanceStatementCareActivityStatusType[];
    onChange?: (newValue: PerformanceStatementCareActivityStatusType | PerformanceStatementCareActivityStatusType[]) => void;
}


const PerformanceStatementCareActivityStatusTypeSelectBox: React.FC<IPerformanceStatementCareActivityStatusTypeMultiSelectProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"PerformanceStatementCareActivityStatusType"}
        enumOrigin="server"
        enumType={PerformanceStatementCareActivityStatusType}
        displayMode="selectBox"
    />
);

export default PerformanceStatementCareActivityStatusTypeSelectBox;