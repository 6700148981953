import HunSocialSecurityUseCases from "@HunSocialSecurityPlugin/UseCases/HunSocialSecurityUseCases";
import PerformanceReportScorePlanDetailPanel, { IPerformanceReportScorePlanDetailPanelProps } from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Panels/PerformanceReportScorePlanWorklistPanel/PerformanceReportScorePlanDetailPanel";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import PerformanceReportScorePlanId from "@Primitives/PerformanceReportScorePlanId.g";

export default function configurePerformanceReportScorePlansUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2<IPerformanceReportScorePlanDetailPanelProps>(
        {
            identifiers: [HunSocialSecurityUseCases.performanceReportScorePlanDetail],
            component: PerformanceReportScorePlanDetailPanel,
            mapUseCaseArgumentsToProps: args => {
                const map = new Map(args.map(a => [a.name, a.value]));
                const performanceReportScorePlanId = map.get("performanceReportScorePlanId") ?? new PerformanceReportScorePlanId("new");
                return {
                    performanceReportScorePlanId: performanceReportScorePlanId
                };
            }
        }
    );

}
