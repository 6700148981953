import React, { useMemo, useContext, useEffect, useLayoutEffect } from "react";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import ValidationBoundaryContext from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryContext";
import RootValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/RootValidationBoundaryStore";
import NestedValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/NestedValidationBoundaryStore";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import EventHandler from "@Toolkit/ReactClient/Components/EventHandler/EventHandler";

interface IProviderProps {
    validationResults?: IClientValidationResult[];
    mapEntityId?: boolean;

    entityTypeName?: string;
    entityId?: string;
    pathPrefix?: string;

    validateOnMount?: boolean;
    onValidateAsync?: (dirtyFields?: string[]) => Promise<IClientValidationResult[]>;
    onChanged?: (fullPropertyPath: string, value: any) => void;
    isRequired?: (fullPropertyPath: string) => boolean;
    problemFilterPredicate?: (problem: IClientValidationProblem) => boolean;
    getProblemMessage?: (problem: IClientValidationProblem) => string;
    validateAllEvent?: TypedEvent;
    validateEvent?: TypedEvent;
    clearDirtyFieldsEvent?: TypedEvent;

    children?: React.ReactNode;
}


const ValidationBoundary: React.FC<IProviderProps> = props => {

    const parentContextStore = useContext(ValidationBoundaryContext);

    const store = useMemo(() => {

        if (props.validationResults !== undefined) {
            return new RootValidationBoundaryStore(
                props.validationResults,
                props.mapEntityId,
                props.entityTypeName,
                props.entityId,
                props.pathPrefix,
                props.onValidateAsync,
                props.onChanged,
                props.isRequired,
                props.validateAllEvent ?? new TypedEvent(),
                props.validateEvent ?? new TypedEvent(),
                props.problemFilterPredicate,
                props.getProblemMessage
            );
        } else if (!!parentContextStore) {

            return new NestedValidationBoundaryStore(
                parentContextStore,
                props.entityTypeName || parentContextStore.entityTypeName,
                props.entityId || parentContextStore.entityId,
                getPathPrefix(props.pathPrefix, parentContextStore.pathPrefix),
                props.problemFilterPredicate,
                props.getProblemMessage
            );
        }

        return null;

    }, [props.validationResults, props.entityId, props.entityTypeName, props.pathPrefix, props.mapEntityId, parentContextStore]);

    useEffect(() => {
        if (props.validationResults && props.validateOnMount) {
            store?.changed();
        }
    }, []);

    if (!store) {
        return (
            <>
                {props.children}
            </>
        );
    }

    return (
        <ValidationBoundaryContext.Provider value={store}>
            <EventHandler event={props.clearDirtyFieldsEvent} onFired={store.clearDirtyFields} />
            {props.children}
        </ValidationBoundaryContext.Provider>
    );
};

const getPathPrefix = (pathPrefix: string, parentPathPrefix: string) => {
    if (pathPrefix) {
        return parentPathPrefix ? `${parentPathPrefix}.${pathPrefix}` : pathPrefix;
    }
    return parentPathPrefix;
};

export default ValidationBoundary;