import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PractitionerScheduleEntryTypeSelectBox from "@HisPlatform/BoundedContexts/Scheduling/Components/Controls/PractitionerScheduleEntryTypeSelectBox/PractitionerScheduleEntryTypeSelectBox";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import PractitionerScheduleEntryType from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/PractitionerScheduleEntryType.g";

interface IPractitionerScheduleEntryTypeColumnDependencies {
    localizationService: ILocalizationService;
}

interface IPractitionerScheduleEntryTypeColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IPractitionerScheduleEntryTypeColumnDependencies;
}

const PractitionerScheduleEntryTypeColumn: React.FC<IPractitionerScheduleEntryTypeColumnProps> = props => {

    const { children, ...columnProps } = props;

    const valueRenderer = useCallback((value: PractitionerScheduleEntryType) => {
        const localizedItem =
            props._dependencies.localizationService.localizeEnum(PractitionerScheduleEntryType[value], "PractitionerScheduleEntryType");

        return (<>{localizedItem.Name}</>);
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<PractitionerScheduleEntryType | PractitionerScheduleEntryType[]>) => {
        return <PractitionerScheduleEntryTypeSelectBox
            {...filterProps}
            displayMode="selectBox"
            hoverOnlyIndicatorIcons
            maxMenuWidth={commonMenuMaxWidth} />;
    }, []);

    return (
        <DataGridColumn
            width={100}
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
            showHint={false}
        />
    );
};

const filterValueSerializer: IDataGridFilterValueSerializer<PractitionerScheduleEntryType | PractitionerScheduleEntryType[]> = {
    serialize: value => JSON.stringify(value),
    deserialize: value => JSON.parse(value)
};

export default connect(
    PractitionerScheduleEntryTypeColumn,
    new DependencyAdapter<IPractitionerScheduleEntryTypeColumnProps, IPractitionerScheduleEntryTypeColumnDependencies>(c => {
        return {
            localizationService: c.resolve("ILocalizationService")
        };
    })
);