import State from "@Toolkit/ReactClient/Common/StateManaging";
import CurativeItem from "./CurativeItem";

export default class SpaService {
    @State.observable public serviceCode: string = "";
    @State.observable public serviceName: string = "";

    @State.action.bound
    public setServiceCode(serviceCode: string) {
        this.serviceCode = serviceCode;
    }
    @State.action.bound
    public setServiceName(serviceName: string) {
        this.serviceName = serviceName;
    }
}