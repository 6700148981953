import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";

export default class CompositeFormExtension<TFormData> implements IFormExtension<TFormData> {

    constructor(
        private readonly extensions: Array<IFormExtension<TFormData>>
    ) { }

    public entityChanged(entity: TFormData, dirtyFields: string[]): void {
        this.extensions?.forEach(e => e?.entityChanged?.(entity, dirtyFields));
    }

    public async fireEventAsync(eventName: string, entity: TFormData) {
        for (const extension of this.extensions) {
            await extension.fireEventAsync?.(eventName, entity);
        }
    }

    public async invokeCallbackAsync<TResult>(callbackName: string, entity: TFormData): Promise<TResult[]> {
        const results = [];
        for (const extension of this.extensions) {
            const result = await extension.invokeCallbackAsync?.<TResult>(callbackName, entity);

            results.push(...result);
        }

        return results;
    }

    public invokeCallback<TResult>(callbackName: string, entity: TFormData): TResult[] {
        const results = [];
        for (const extension of this.extensions) {
            const result = extension.invokeCallback?.<TResult>(callbackName, entity);
            
            results.push(...result);
        }

        return results;
    }

    public dispose?(): void {
        this.extensions?.forEach(e => e?.dispose?.());
    }
}