import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import Prescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Prescription";
import SubsidyClaimTypeSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/SubsidyClaimTypeSelector/SubsidyClaimTypeSelector";
import ReferenceDataApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/ReferenceDataApiAdapter";
import MedicationId from "@Primitives/MedicationId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import MedicationPricingAndSubsidies from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationPricingAndSubsidies";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import ConditionCodeSelector from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ConditionCodeSelector";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import MedicationSubsidy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationSubsidy";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import SingleMedicationReference from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/SingleMedicationReference";
import MedicationSubsidyOptionList from "./MedicationSubsidyOptionList";
import MedicationEquipmentList from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationEquipmentList";
import MedicationVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/MedicationVersion";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import { getSubsidyByClaimTypeId } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestHelpers";
import TextualPrescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/TextualPrescription";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { formatString, formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import MedicationTypeId from "@Primitives/MedicationTypeId.g";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import PrescriptionMonthSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionMonthSelectBox/PrescriptionMonthSelectBox";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import SubsidyOptionCheckResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/SubsidyOptionCheckResult";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import MedicationFormulaList from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationFormulaList";
import MedicationFormulaPanel from "./MedicationFormulaPanel";
import TextualPrescriptionDetailView from "./TextualPrescriptionDetailView";
import MedicationEquipmentListDetailView from "./MedicationEquipmentListDetailView";
import SingleMedicationReferenceDetailView from "./SingleMedicationReferenceDetailView";
import _ from "@HisPlatform/Common/Lodash";
import { isNullOrUndefined, isNullOrWhiteSpace } from "@Toolkit/CommonWeb/NullCheckHelpers";
import MedicationEquipmentListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationEquipmentListItem";
import MedicationEquipmentPrescriptionType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationEquipmentPrescriptionType";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import ExcludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierSystemIdFilter";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import PrescriptionTemplateType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionTemplateType";
import MedicationSubsidyOptionCheckType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/ReferenceData/Prescription/Enum/MedicationSubsidyOptionCheckType.g";
import WellKnownReferenceCodes from "@HunEHealthInfrastructurePlugin/Common/WellKnownReferenceCodes";

interface IPrescriptionDetailViewDependencies {
    referenceDataAdapter: ReferenceDataApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    hunCareReferenceDataStore: MedicationRequestReferenceDataStore;
    localizationService: ILocalizationService;
    dialogService: IDialogService;
    notificationService: INotificationService;
    dateTimeFormatProvider: IDateTimeFormatProvider;
}

interface IPrescriptionDetailViewProps {
    _dependencies?: IPrescriptionDetailViewDependencies;
    prescription: Prescription;
    medications: MedicationVersion[];
    saveAsync: (releaseLock: boolean) => Promise<Prescription>;
    onReleaseLockAsync: () => Promise<void>;
    daysSupplied: number;
    calculatedDaysSupplied: number;
    patientHasValidCurativeSupplyPass: boolean;
    onValidateAsync: (dirtyFields?: string[]) => Promise<IClientValidationResult[]>;
    optionCheckResults: SubsidyOptionCheckResult[];
    setOptionCheckResults: (optionCheckResults: SubsidyOptionCheckResult[]) => void;
    pointOfCareId: PointOfCareId;
    medicationReferenceData: MedicationPricingAndSubsidies[];
    setIsUnpackaged: (isUnpackaged: boolean) => void;
    medicationOfSingleMedicationReference: MedicationVersion;
    medicationReferenceDataOfSingleMedicationReference: MedicationPricingAndSubsidies;
    onCreateNewMedicationEquipmentListItemAsync: () => Promise<MedicationEquipmentListItem>;
    ePrescriptionNotAllowedIsoPrefixes: string[];
    prescribeWithIsoGroupAllowedIsoCodes: string[];
    isClaimTypeTemporaryPermission: boolean;
    setClaimTypeId: (claimTypeId: MedicationSubsidyClaimTypeId) => void;
    setEquipmentListType: (type: MedicationEquipmentPrescriptionType) => void;
}

/** @screen */
@State.observer
class PrescriptionDetailView extends React.Component<IPrescriptionDetailViewProps> {

    //#region deps
    private get referenceDataAdapter() { return this.props._dependencies.referenceDataAdapter; }
    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get organizationReferenceDataStore() { return this.props._dependencies.organizationReferenceDataStore; }
    private get hunSocialSecurityReferenceDataStore() { return this.props._dependencies.hunCareReferenceDataStore; }
    private get dialogService() { return this.props._dependencies.dialogService; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get dateTimeFormatProvider() { return this.props._dependencies.dateTimeFormatProvider; }
    //#endregion

    private actualMedicationTypesIds = [
        MedicationTypeId.G3,
        MedicationTypeId.G7
    ];

    @State.observable private isLoading = false;

    @State.computed private get claimTypes() {
        const medicationReference = this.props.prescription.medicationReferenceBase;
        if (medicationReference instanceof MedicationFormulaList || medicationReference instanceof TextualPrescription) {
            const filterableMedicationSubsidyClaimType = [MedicationSubsidyClaimTypeId.N.value, MedicationSubsidyClaimTypeId.T.value, MedicationSubsidyClaimTypeId.KGY.value];
            const medicationSubsidyClaimTypesIds = this.hunSocialSecurityReferenceDataStore.medicationSubsidyClaimTypes.items.filter(item => filterableMedicationSubsidyClaimType.includes(item.code)).map(item => item.id);
            return medicationSubsidyClaimTypesIds;
        }

        if (medicationReference instanceof MedicationEquipmentList && medicationReference.type === MedicationEquipmentPrescriptionType.Repair) {
            return null;
        }

        return _.flatten(this.props.medicationReferenceData?.map(i => i?.pricing?.claimTypes));
    }

    @State.computed private get highestGrossPrice() {
        return _.max(this.props.medicationReferenceData?.map(i => i.pricing.grossPrice));
    }

    @State.computed private get selectedSubsidy() {
        return getSubsidyByClaimTypeId(
            this.hunSocialSecurityReferenceDataStore,
            _.flatten(this.props.medicationReferenceData?.map(i => i.subsidies)),
            this.props.prescription?.medicationRequestCoverage?.claimTypeId
        );
    }

    @State.computed private get medicationId() {
        return this.getMedicationId(this.props);
    }

    @State.computed private get firstSubstanceBasedRestrictionId() {
        return (this.props.medicationReferenceData).find(i => !isNullOrUndefined(i?.pricing?.substanceBasedRestrictionId))?.pricing?.substanceBasedRestrictionId;
    }

    @State.computed
    private get isRepairAllowedOnly(): boolean {
        const medicationReference = this.props.prescription.medicationReferenceBase;
        if (!(medicationReference instanceof MedicationEquipmentList)) {
            return false;
        }

        const firstMedicationVersionSelector = medicationReference.items[0]?.medicationVersionSelector;
        const medication = this.props.medications?.find(i => ValueWrapper.equals(i.id, firstMedicationVersionSelector.id));
        return !isNullOrUndefined(medication) && !medication.validity.includes(LocalDate.today());
    }

    private getMedicationId(props: IPrescriptionDetailViewProps) {
        if (props.prescription.medicationReferenceBase instanceof SingleMedicationReference) {
            return props.prescription.medicationReferenceBase.medicationVersionSelector;
        } else if (props.prescription.medicationReferenceBase instanceof MedicationEquipmentList) {
            return props.prescription.medicationReferenceBase.items[0]?.medicationVersionSelector;
        }
        return null;
    }

    @State.boundLoadingState("isLoading")
    private async loadMedicationReferenceDataAsync(versionSelector: IEntityVersionSelector<MedicationId>) {
        const medicationId = versionSelector?.id;
        await this.organizationReferenceDataStore.professionalExamMap.ensureAllLoadedAsync();
        await this.hunSocialSecurityReferenceDataStore.medicationSubsidyClaimTypes.ensureAllLoadedAsync();
        await this.hunSocialSecurityReferenceDataStore.organizationRestrictionTypes.ensureLoadedAsync();
        await this.hunSocialSecurityReferenceDataStore.medicationSubsidizedPricingTypes.ensureLoadedAsync();
        if (medicationId) {
            const medicationReferenceData = await this.referenceDataAdapter.getMedicationPricingAndSubsidiesAsync(medicationId, versionSelector.validOn);
            await this.hunSocialSecurityReferenceDataStore.substanceBasedRestrictions.ensureLoadedAsync([medicationReferenceData?.value?.pricing?.substanceBasedRestrictionId]);

            if (medicationReferenceData?.value?.subsidies) {
                for (const subsidy of medicationReferenceData.value.subsidies) {
                    for (const option of subsidy.options) {
                        await this.careReferenceDataStore.conditionsWithoutVersion.ensureLoadedAsync(option.restrictionConditions);
                    }
                }
            }
        }
    }

    @State.bound
    private setCountersignMandatory(countersignMandatory: boolean) {
        if (this.props.prescription?.medicationReferenceBase instanceof MedicationEquipmentList) {
            this.props.prescription.medicationReferenceBase.setCountersignMandatory(countersignMandatory);
            if (countersignMandatory) {
                for (const item of this.props.prescription.medicationReferenceBase.items) {
                    const medication = this.careReferenceDataStore.medications.get(item.medicationVersionSelector);
                    if (medication.isLint === false) {
                        item.setIsInsubstitutable(true);
                    }
                }
            }
        }
    }

    public componentDidMount() {
        if (this.medicationId) {
            dispatchAsyncErrors(this.loadMedicationReferenceDataAsync(this.medicationId), this);
        }
    }

    public componentDidUpdate(prevProps: IPrescriptionDetailViewProps) {
        if (!ValueWrapper.equals(this.medicationId?.id, this.getMedicationId(prevProps)?.id)) {
            dispatchAsyncErrors(this.loadMedicationReferenceDataAsync(this.medicationId), this);
        }
    }

    @State.bound
    private async onNavigatingAwayAsync() {
        if (this.props.prescription.isNew || this.props.prescription.isDirty()) {
            const dialogResult = await this.dialogService.confirmIfNotSaved(StaticWebAppResources.Common.DialogTitle.ConfirmationTitle, StaticWebAppResources.Common.DialogMessage.OkToNavigateAndLoseChanges);

            if (dialogResult.resultCode === DialogResultCode.Yes) {
                const result = await this.props.saveAsync(true);
                return result.operationWasSuccessful;
            } else if (dialogResult.resultCode === DialogResultCode.No) {
                await this.props.onReleaseLockAsync();
                return true;
            } else {
                return false;
            }
        }
        await this.props.onReleaseLockAsync();
        return true;
    }

    @State.bound
    public renderSubsidy(subsidy: MedicationSubsidy) {
        return (
            <MedicationSubsidyOptionList
                subsidy={subsidy}
                careActivityId={this.props.prescription?.careActivityId}
                medicationId={this.medicationId?.id}
                checkType={MedicationSubsidyOptionCheckType.Prescription}
                optionCheckResults={this.props.optionCheckResults}
                setOptionCheckResults={this.props.setOptionCheckResults}
                prescriber={this.props.prescription?.prescriber}
                claimTypeId={this.props.prescription?.medicationRequestCoverage?.claimTypeId}
            />
        );
    }

    @State.bound
    private daysSuppliedBlur() {
        if (this.props.medicationOfSingleMedicationReference?.daysSuppliedCanBeCalculated && (this.props.daysSupplied !== this.props.calculatedDaysSupplied)) {
            this.notificationService.warning(StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.DaysSuppliedCalculationDifferenceWarning);
        }
    }
    @State.bound
    public getSubtitle(index: number, dateOfUsableFrom: string) {
        return formatStringWithObjectParams(StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.PrescriptionsDateOfUsableFrom.DateOfUsableFrom, {
            dateOfUsableFrom: dateOfUsableFrom,
            index: index.toString()
        });
    }

    @State.computed
    private get selectedClaimType() {
        return this.hunSocialSecurityReferenceDataStore.medicationSubsidyClaimTypes?.items?.find(i => ValueWrapper.equals(i.id, this.props.prescription?.medicationRequestCoverage?.claimTypeId));
    }

    @State.bound
    private datesOfUsableFrom() {
        let datesWithDateOfUsableFrom = "";
        if (this.props.calculatedDaysSupplied || this.props.daysSupplied) {
            for (let i = 1; i <= this.props.prescription.suppliedMonths; i++) {
                let dateOfUsableFrom;
                if (this.medicationPriceAbove50000) {
                    if (i > 1 && (this.props.daysSupplied && this.props.prescription.usableFrom)) {
                        const plusDays = this.props.daysSupplied * (i - 1);
                        dateOfUsableFrom = this.props.prescription.usableFrom.plusDays(plusDays).toUtcDayStartMoment().format(this.dateTimeFormatProvider.getDateFormat());
                    } else {
                        dateOfUsableFrom = this.props.prescription.usableFrom.toUtcDayStartMoment().format(this.dateTimeFormatProvider.getDateFormat());
                    }
                } else {
                    dateOfUsableFrom = this.props.prescription.usableFrom.toUtcDayStartMoment().format(this.dateTimeFormatProvider.getDateFormat());
                }
                datesWithDateOfUsableFrom = datesWithDateOfUsableFrom.concat(`${this.getSubtitle(i, dateOfUsableFrom)}\n`);
            }
        }
        return datesWithDateOfUsableFrom;
    }

    @State.computed
    private get medicationPriceAbove50000() {
        return this.highestGrossPrice?.amount > 50000;
    }

    @State.computed
    private get showAbove30DaysWarning() {
        return this.highestGrossPrice?.amount < 50000
            && this.props.prescription?.medicationReferenceBase instanceof SingleMedicationReference
            && this.props.prescription.medicationReferenceBase?.amount > 1
            && this.props.prescription.suppliedMonths === 1
            && this.props.prescription.medicationReferenceBase?.daysSupplied > 30
            && this.props.prescription.medicationReferenceBase?.daysSupplied <= 365
            && this.actualMedicationTypesIds.some(i => this.props.medications.some(j => ValueWrapper.equals(i, j?.medicationTypeId)));
    }

    @State.computed
    private get countersignMandatoryDisabled() {
        const isEquipmentList = this.props.prescription.medicationReferenceBase instanceof MedicationEquipmentList;
        const equipmentList = this.props.prescription.medicationReferenceBase as MedicationEquipmentList;

        return isEquipmentList
            && equipmentList.type !== MedicationEquipmentPrescriptionType.Repair
            && this.props.medicationReferenceData?.some(i => i?.pricing?.counterSignDesiredValue === true || i?.pricing?.counterSignDesiredValue === false);
    }

    @State.computed
    private get isEPrescriptionNotAllowedMedications() {
        return this.props.medications?.filter(i => !isNullOrWhiteSpace(i.isoCode) && this.props.ePrescriptionNotAllowedIsoPrefixes?.some(j => i.isoCode.startsWith(j)));
    }

    public render() {
        const prescription = this.props.prescription;
        const isSingleMedicationReference = prescription.medicationReferenceBase instanceof SingleMedicationReference;
        const isEquipmentList = prescription.medicationReferenceBase instanceof MedicationEquipmentList;
        const isTextualPrescription = prescription.medicationReferenceBase instanceof TextualPrescription;
        const isMedicationFormulaList = prescription.medicationReferenceBase instanceof MedicationFormulaList;
        const resources = StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail;
        const equipmentList = prescription.medicationReferenceBase as MedicationEquipmentList;
        const textualPrescription = prescription.medicationReferenceBase as TextualPrescription;
        const hasEhiIdentifier = isSingleMedicationReference || isMedicationFormulaList || (isTextualPrescription && textualPrescription.type === PrescriptionTemplateType.Formula);
        const morphologyIdentifierSystemId = new IdentifierSystemId(WellKnownReferenceCodes.MorphologyIdentifier);
        const morphologyIdentifierSystemIdFilter: FilterBase[] = [new ExcludeIdentifierSystemIdFilter(morphologyIdentifierSystemId)];
        const pointOfCareIdFilter: FilterBase[] = this.props.pointOfCareId
            ? [new ExplicitIdentifierFilter("PointOfCare", this.props.pointOfCareId.value)]
            : [];

        const showEPrescriptionNotAllowedWarning = this.isEPrescriptionNotAllowedMedications?.length > 0;
        const showPatientStatementWarning = isEquipmentList && !equipmentList.patientStatementAvailable;

        if (this.isLoading) {
            return <></>;
        }

        return (
            prescription && (
                <>
                    <ValidationBoundary
                        validationResults={prescription.validationResults}
                        entityTypeName="Prescription"
                        onValidateAsync={this.props.onValidateAsync}
                    >
                        {(this.props.patientHasValidCurativeSupplyPass || this.firstSubstanceBasedRestrictionId || this.medicationPriceAbove50000 || this.showAbove30DaysWarning || this.isRepairAllowedOnly || showEPrescriptionNotAllowedWarning || showPatientStatementWarning) &&
                            <Ui.InfoBox iconName="info_with_circle">
                                <ul style={{ margin: 0 }}>
                                    {this.props.patientHasValidCurativeSupplyPass && (<li>{resources.PatientHasCurativePass}</li>)}
                                    {this.firstSubstanceBasedRestrictionId && (
                                        <li>{formatString(resources.Labels.SubstanceBasedRestriction,
                                            this.hunSocialSecurityReferenceDataStore.substanceBasedRestrictions.get(this.firstSubstanceBasedRestrictionId)?.description)}
                                        </li>)}
                                    {this.medicationPriceAbove50000 && <li>{resources.MedicationPriceAbove50000Warning}</li>}
                                    {this.showAbove30DaysWarning && <li>{resources.AmountAbove1AndDaysSuppliedAbove30Warning}</li>}
                                    {this.isRepairAllowedOnly && <li>{resources.RepairAllowedOnlyWarning}</li>}
                                    {showEPrescriptionNotAllowedWarning && this.isEPrescriptionNotAllowedMedications.map(m => <li>{formatString(resources.EPrescriptionNotAllowedMessage, m.name)}</li>)}
                                    {showPatientStatementWarning && <li>{resources.CheckPatientStatementAvailableMessage}</li>}
                                </ul>
                            </Ui.InfoBox>
                        }
                        <Ui.ValidationResultSummary propertyPath="General.*;MedicationReference.General.*" />
                        {isSingleMedicationReference && (
                            <SingleMedicationReferenceDetailView
                                daysSupplied={this.props.daysSupplied}
                                daysSuppliedBlur={this.daysSuppliedBlur}
                                substanceBasedRestrictionId={this.firstSubstanceBasedRestrictionId}
                                selectedSubsidy={this.selectedSubsidy}
                                medication={this.props.medicationOfSingleMedicationReference}
                                prescription={prescription}
                                setIsUnpackaged={this.props.setIsUnpackaged}
                            />
                        )}
                        {isEquipmentList && (
                            <MedicationEquipmentListDetailView
                                medications={this.props.medications}
                                pricingsAndSubsidies={this.props.medicationReferenceData}
                                selectedClaimType={this.selectedClaimType}
                                prescription={prescription}
                                onCreateNewMedicationEquipmentListItemAsync={this.props.onCreateNewMedicationEquipmentListItemAsync}
                                ePrescriptionNotAllowedIsoPrefixes={this.props.ePrescriptionNotAllowedIsoPrefixes}
                                prescribeWithIsoGroupAllowedIsoCodes={this.props.prescribeWithIsoGroupAllowedIsoCodes}
                                isClaimTypeTemporaryPermission={this.props.isClaimTypeTemporaryPermission}
                                onTypeChanged={this.props.setEquipmentListType}
                            />
                        )}
                        {isTextualPrescription && (
                            <TextualPrescriptionDetailView prescription={prescription} />
                        )}
                        {isMedicationFormulaList && (
                            <MedicationFormulaPanel
                                prescription={prescription} />
                        )}
                        {!isEquipmentList && <Ui.Flex>
                            <Ui.Flex.Item xs={12}>
                                <Ui.TextBox
                                    label={resources.Labels.Notes}
                                    value={prescription.notes}
                                    onChange={prescription.setNotes}
                                    propertyIdentifier="Notes"
                                    automationId="notesTextBox"
                                    showMultipleValidationProblems
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>}
                        <Ui.GroupBox title={resources.GroupTitles.SubmissionData}>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={4}>
                                    <SubsidyClaimTypeSelector
                                        label={resources.Labels.ClaimType}
                                        idFilter={this.claimTypes}
                                        value={prescription.medicationRequestCoverage?.claimTypeId}
                                        onChange={this.props.setClaimTypeId}
                                        propertyIdentifier="MedicationRequestCoverage.MedicationSubsidyClaimTypeId"
                                        automationId="subsidyClaimTypeSelector"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <ConditionCodeSelector
                                        label={resources.Labels.Condition}
                                        value={prescription.conditionId}
                                        onChange={prescription.setConditionId}
                                        filters={morphologyIdentifierSystemIdFilter}
                                        propertyIdentifier="ConditionId"
                                        automationId="conditionSelector"
                                    />
                                </Ui.Flex.Item>

                                <Ui.Flex.Item xs={4}>
                                    <HisUi.DoctorCodeSelector
                                        label={resources.Labels.Prescriber}
                                        value={prescription.prescriber}
                                        onChange={prescription.setPrescriber}
                                        filters={pointOfCareIdFilter}
                                        propertyIdentifier="Prescriber"
                                        automationId="prescriberSelector"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={4}>
                                    <Ui.DatePicker
                                        label={resources.Labels.PrescribedOn}
                                        value={prescription.prescribedOn}
                                        onChange={prescription.setPrescribedOn}
                                        propertyIdentifier="PrescribedOn"
                                        automationId="prescribedOnPicker"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <PrescriptionMonthSelectBox
                                        label={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.PrescriptionsDateOfUsableFrom.Header}
                                        value={prescription.suppliedMonths}
                                        onChange={prescription.setSuppliedMonths}
                                        isReadOnly={isEquipmentList}
                                        propertyIdentifier="SuppliedMonths"
                                        automationId="suppliedMonthsSelectBox"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <Ui.DatePicker
                                        label={resources.Labels.UsableFrom}
                                        value={prescription.usableFrom}
                                        onChange={prescription.setUsableFrom}
                                        propertyIdentifier="UsableFrom"
                                        infoLabel={this.datesOfUsableFrom()}
                                        clearable={false}
                                        automationId="usableFromPicker"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </Ui.GroupBox>
                        {isEquipmentList && (
                            <>
                                <Ui.Flex>
                                    <Ui.Flex.Item xs={6}>
                                        <Ui.CheckBox
                                            value={equipmentList.patientStatementAvailable}
                                            onChange={equipmentList.setPatientStatementAvailable}
                                            automationId="patientStatementAvailableCheckbox"
                                            label={resources.Labels.PatientStatementAvailable}
                                        />
                                    </Ui.Flex.Item>
                                </Ui.Flex>
                                {equipmentList.type === MedicationEquipmentPrescriptionType.Prescription && <>
                                    <Ui.Flex>
                                        <Ui.Flex.Item xs={6}>
                                            <Ui.CheckBox
                                                value={equipmentList.represcribeWithinSupportPeriod}
                                                onChange={equipmentList.setReprescribeWithinSupportPeriod}
                                                automationId="represcribeWithinSupportPeriodCheckbox"
                                                label={resources.Labels.ReprescribeWithinSupportPeriod}
                                                propertyIdentifier="MedicationReference.ReprescribeWithinSupportPeriod"
                                            />
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                    {equipmentList.represcribeWithinSupportPeriod && <>
                                        <Ui.Flex>
                                            <Ui.Flex.Item xs={12}>
                                                <Ui.TextBox
                                                    value={equipmentList.stateChangeDescription}
                                                    onChange={equipmentList.setStateChangeDescription}
                                                    automationId="stateChangeTextbox"
                                                    propertyIdentifier="MedicationReference.StateChangeDescription"
                                                    label={resources.Labels.StateChangeDescription}
                                                />
                                            </Ui.Flex.Item>
                                        </Ui.Flex>
                                        <Ui.Flex>
                                            <Ui.Flex.Item xs={12}>
                                                <Ui.TextBox
                                                    value={equipmentList.sizeChangeDescription}
                                                    onChange={equipmentList.setSizeChangeDescription}
                                                    automationId="sizeChangeTextbox"
                                                    propertyIdentifier="MedicationReference.SizeChangeDescription"
                                                    label={resources.Labels.SizeChangeDescription}
                                                />
                                            </Ui.Flex.Item>
                                        </Ui.Flex>
                                    </>}
                                </>}
                            </>
                        )}
                        <Ui.GroupBox title={resources.GroupTitles.PractitionerRecommendation}>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={4}>
                                    <HisUi.DoctorCodeSelector
                                        value={prescription.prescriptionPractitionerRecommendation.practitionerId}
                                        onChange={prescription.prescriptionPractitionerRecommendation.setPractitionerId}
                                        label={resources.Labels.DoctorCode}
                                        propertyIdentifier="PrescriptionPractitionerRecommendation.PractitionerId"
                                        automationId="practitionerSelector"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <Ui.DatePicker
                                        value={prescription.prescriptionPractitionerRecommendation.issuedOn}
                                        onChange={prescription.prescriptionPractitionerRecommendation.setIssuedOn}
                                        label={resources.Labels.IssuedOn}
                                        propertyIdentifier="PrescriptionPractitionerRecommendation.IssuedOn"
                                        automationId="issuedOnPicker"
                                    />
                                </Ui.Flex.Item>
                                {isEquipmentList &&
                                    <Ui.Flex.Item xs={4}>
                                        <Ui.TextBox
                                            value={prescription.prescriptionPractitionerRecommendation.careIdentifier}
                                            onChange={prescription.prescriptionPractitionerRecommendation.setCareIdentifier}
                                            label={resources.Labels.CareIdentifier}
                                            propertyIdentifier="PrescriptionPractitionerRecommendation.CareIdentifier"
                                            automationId="careIdentifierTextBox"
                                        />
                                    </Ui.Flex.Item>
                                }
                                {hasEhiIdentifier && (
                                    <Ui.Flex.Item xs={4}>
                                        <Ui.TextBox
                                            value={prescription.prescriptionPractitionerRecommendation.ehiIdentifier}
                                            onChange={prescription.prescriptionPractitionerRecommendation.setEhiIdentifier}
                                            label={resources.Labels.EhiIdentifier}
                                            propertyIdentifier="PrescriptionPractitionerRecommendation.EhiIdentifier"
                                            automationId="ehiIdentifierTextBox"
                                        />
                                    </Ui.Flex.Item>)
                                }
                            </Ui.Flex>
                        </Ui.GroupBox>
                        {isEquipmentList && (
                            <Ui.GroupBox title={resources.GroupTitles.Registry}>
                                <Ui.Flex>
                                    <Ui.Flex.Item xs={4}>
                                        <Ui.CheckBox
                                            value={equipmentList.countersignMandatory}
                                            onChange={this.setCountersignMandatory}
                                            automationId="countersignMandatoryCheckbox"
                                            label={resources.Labels.CountersignMandatory}
                                            disabled={this.countersignMandatoryDisabled}
                                        />
                                    </Ui.Flex.Item>
                                    {equipmentList.countersignMandatory === false && (<>
                                        <Ui.Flex.Item xs={4}>
                                            <Ui.TextBox
                                                value={equipmentList.countersignApprovalNumber}
                                                onChange={equipmentList.setCountersignApprovalNumber}
                                                label={resources.Labels.CountersignApprovalNumber}
                                                automationId="countersignApprovalNumberTextbox"
                                                propertyIdentifier="MedicationReference.CountersignApprovalNumber"
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item xs={4}>
                                            <Ui.DatePicker
                                                value={equipmentList.countersignValidFrom}
                                                onChange={equipmentList.setCountersignValidFrom}
                                                label={resources.Labels.CountersignValidFrom}
                                                automationId="countersignValidFromDatePicker"
                                                propertyIdentifier="MedicationReference.CountersignValidFrom"
                                            />
                                        </Ui.Flex.Item>
                                    </>)}
                                </Ui.Flex>
                            </Ui.GroupBox>
                        )}
                        {(isSingleMedicationReference || isEquipmentList) && (
                            <Ui.GroupBox title={resources.GroupTitles.TemporaryPermission}>
                                <Ui.Flex>
                                    <Ui.Flex.Item xs={4}>
                                        <Ui.TextBox
                                            value={prescription.temporaryPermission.permissionIdentifier}
                                            onChange={prescription.temporaryPermission.setPermissionIdentifier}
                                            label={resources.Labels.TemporaryPermissionIdentifier}
                                            propertyIdentifier="TemporaryPrescriptionPermission.PermissionIdentifier"
                                            automationId="permissionIdentifierTextBox"
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={4}>
                                        <Ui.DatePicker
                                            value={prescription.temporaryPermission.validFrom}
                                            onChange={prescription.temporaryPermission.setValidFrom}
                                            label={resources.Labels.TemporaryPermissionValidFrom}
                                            propertyIdentifier="TemporaryPrescriptionPermission.ValidFrom"
                                            automationId="temporaryPremissionValidFromPicker"
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={4}>
                                        <Ui.DatePicker
                                            value={prescription.temporaryPermission.validTo}
                                            onChange={prescription.temporaryPermission.setValidTo}
                                            label={resources.Labels.TemporaryPermissionValidTo}
                                            propertyIdentifier="TemporaryPrescriptionPermission.ValidTo"
                                            automationId="temporaryPremissionValidToPicker"
                                        />
                                    </Ui.Flex.Item>
                                </Ui.Flex>
                            </Ui.GroupBox>
                        )}
                        {
                            isSingleMedicationReference && <Ui.GroupBox title={resources.GroupTitles.SubsidyOptionRestrictions}>
                                {this.props.medicationReferenceDataOfSingleMedicationReference?.subsidies?.map(s => this.renderSubsidy(s))}
                            </Ui.GroupBox>}
                        <NavigateAwayHook onNavigateAwayAsync={this.onNavigatingAwayAsync} />
                    </ValidationBoundary>
                </>)
        );
    }
}

export default connect(
    PrescriptionDetailView,
    new DependencyAdapter<IPrescriptionDetailViewProps, IPrescriptionDetailViewDependencies>(c => ({
        referenceDataAdapter: c.resolve("ReferenceDataApiAdapter"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        hunCareReferenceDataStore: c.resolve("MedicationRequestReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService"),
        dialogService: c.resolve("IDialogService"),
        notificationService: c.resolve("INotificationService"),
        dateTimeFormatProvider: c.resolve("IDateTimeFormatProvider")
    }))
);
