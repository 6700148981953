import React from "react";
import * as Ui from "@CommonControls";
import IdentifierSystemSelectBox from "@HisPlatform/BoundedContexts/CommonReferenceData/Components/Controls/IdentifierSystemSelectBox/IdentifierSystemSelectBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IdentifierTypeId from "@Primitives/IdentifierTypeId.g";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";

interface IIdentifierAssignmentViewProps {
    identifier: Identifier;
    index: number;
}


const ExternalLocationIdentifierView: React.FC<IIdentifierAssignmentViewProps> = props =>
(
    <>
        <Ui.Flex>
            <Ui.Flex.Item xs={6}>
                <IdentifierSystemSelectBox
                    label={StaticOrganizationResources.ExternalLocationPanel.Labels.IdentifierSystem}
                    value={props.identifier.identifierSystemId}
                    onChange={props.identifier.setIdentifierSystemId}
                    propertyIdentifier="IdentifierSystemId"
                    identifierTypeIds={[IdentifierTypeId.LocationRegister]}
                    automationId="identifierSystemId"
                />
            </Ui.Flex.Item>
            <Ui.Flex.Item xs={6}>
                <Ui.TextBox
                    label={StaticOrganizationResources.ExternalLocationPanel.Labels.IdentifierValue}
                    value={props.identifier.value}
                    onChange={props.identifier.setValue}
                    propertyIdentifier="Value"
                    automationId="identifierValue"
                />
            </Ui.Flex.Item>
        </Ui.Flex>
    </>
);

export default State.observer(ExternalLocationIdentifierView);
