import React from "react";
import Styles from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchPanelView.less";
import * as Ui from "@CommonControls";
import StaticToolkitResources from "@Toolkit/ReactClient/Services/Definition/LocalizationService/StaticToolkitResources";
import ComplexSearchFilterStore from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchFilterStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";

type displayMode = "filter" | "newItem";

interface ISearchAndNewItemPanelProps {
    filterStore: ComplexSearchFilterStore;
    isMultiSelect: boolean;
    mode: displayMode;
    createNewItemAsync: (code: string, name: string) => Promise<void>;
    createNewItemCodePlaceHolder: string;
    createNewItemNamePlaceHolder: string;
    createNewItemCodePropertyIdentifier: string;
    createNewItemNamePropertyIdentifier: string;
    createNewItemValidationEntityName: string;
    onValidateAllAsync: (code: string, name: string) => Promise<IClientValidationResult[]>;
    validationResults: IClientValidationResult[];
    selectableItemsSearchPlaceHolder: string;
    selectedItemsSearchPlaceHolder: string;
}

@State.observer
export default class SearchAndNewItemPanel extends React.Component<ISearchAndNewItemPanelProps> {

    @State.observable.ref private newCode: string;
    @State.observable.ref private newName: string;

    @State.action.bound
    private setNewCode(newValue: string) {
        this.newCode = newValue;
    }

    @State.action.bound
    private setNewName(newValue: string) {
        this.newName = newValue;
    }

    @State.bound
    private async validateAsync() {
        return await this.props.onValidateAllAsync(this.newCode, this.newName);
    }

    @State.bound
    private async onCreateNewElementAsync() {
        await this.props.createNewItemAsync(this.newCode, this.newName);

    }

    @State.bound
    private renderFilterFields() {
        return (
            <Ui.Flex>
                <Ui.Flex.Item sm={this.props.isMultiSelect ? 6 : 12}>
                    <Ui.TextBox value={this.props.filterStore.commonFilterText}
                                onChange={this.props.filterStore.setCommonFilterText}
                                placeholder={this.props.selectableItemsSearchPlaceHolder}
                                automationId="commonFilterTextBox"/>

                </Ui.Flex.Item>
                {
                    this.props.isMultiSelect &&
                    <Ui.Flex.Item sm={6}>
                        <Ui.TextBox value={this.props.filterStore.selectedFilterText}
                                    onChange={this.props.filterStore.setSelectedCommonFilterText}
                                    placeholder={this.props.selectedItemsSearchPlaceHolder}
                                    automationId="selectedFilterTextBox"/>

                    </Ui.Flex.Item>
                }
            </Ui.Flex>
        );
    }

    @State.bound
    private renderNewItemFields() {
        return (
            <ValidationBoundary
                validationResults={this.props.validationResults}
                onValidateAsync={this.validateAsync}
                validateOnMount
                entityTypeName={this.props.createNewItemValidationEntityName}
            >
                <Ui.Flex>
                    <Ui.Flex.Item sm={2}>
                        <Ui.TextBox value={this.newCode}
                                    onChange={this.setNewCode}
                                    placeholder={this.props.createNewItemCodePlaceHolder}
                                    propertyIdentifier={this.props.createNewItemCodePropertyIdentifier}
                                    automationId="newCodeTextBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item grow>
                        <Ui.TextBox value={this.newName}
                                    onChange={this.setNewName}
                                    placeholder={this.props.createNewItemNamePlaceHolder}
                                    propertyIdentifier={this.props.createNewItemNamePropertyIdentifier}
                                    automationId="newNameTextBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item>
                        <Ui.Button iconName="check" onClickAsync={this.onCreateNewElementAsync} visualStyle="standard"
                                   visualMode="inverted" size="compact" automationId="createNewElementButton" />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </ValidationBoundary>
        );
    }

    public render() {
        return (
            <div className={Styles.searchTextContainer}>
                {
                    this.props.mode === "filter" ? this.renderFilterFields() : this.renderNewItemFields()
                }
            </div>
        );
    }
}
