import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ReferenceDataApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/ReferenceDataApiAdapter";
import MedicationId from "@Primitives/MedicationId.g";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { IMedicationEquivalenceListItem } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationInfo/IMedicationEquivalence";
import Money from "@Toolkit/CommonWeb/Model/Money";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import OrderTypeId from "@Primitives/OrderTypeId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

interface IMedicationListPanelDependencies {
    apiAdapter: ReferenceDataApiAdapter;
    localizationService: ILocalizationService;
}

interface IMedicationEquivalenceListPanelProps {
    _dependencies?: IMedicationListPanelDependencies;
    medicationId: MedicationId;
    onClick?: (medication: IMedicationEquivalenceListItem) => void;
}

@State.observer
class MedicationEquivalenceListPanel extends React.Component<IMedicationEquivalenceListPanelProps> {

    @State.observable private medicationEquivalences: IMedicationEquivalenceListItem[] = [];
    @State.observable.ref private dataSource: InMemoryDataGridDataSource<IMedicationEquivalenceListItem> = new InMemoryDataGridDataSource<IMedicationEquivalenceListItem>(() => this.medicationEquivalences, { columnId: "Name", direction: "asc" });

    private get apiAdapter() { return this.props._dependencies.apiAdapter; }
    private get localizationService() { return this.props._dependencies.localizationService; }

    constructor(props: IMedicationEquivalenceListPanelProps) {
        super(props);
        this.dataSource.paging.pageSize = 20;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IMedicationEquivalenceListPanelProps) {
        if (!ValueWrapper.equals(prevProps.medicationId, this.props.medicationId)) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.boundLoadingState("isLoading")
    private async loadAsync() {
        if (!!this.props.medicationId) {
            const medicationEquivalences = await this.apiAdapter.getMedicationEquivalenceListAsync(this.props.medicationId);
            this.setMedications(medicationEquivalences.value);
        }
    }

    @State.action.bound
    private setMedications(medicationEquivalences: IMedicationEquivalenceListItem[]) {
        this.medicationEquivalences = medicationEquivalences;
    }

    @State.bound
    private renderGrossPrice(grossPrice: Money) {
        return this.localizationService.localizeMoney(grossPrice);
    }

    @State.bound
    private renderOrderTypeId(orderTypeId: OrderTypeId) {
        return orderTypeId.value;
    }

    public render() {
        return (
            <Ui.DataGrid
                dataSource={this.dataSource}
                fixedLayout
                fixedHeight="calc(100% - 45px)"
                rowHeight={30}
                changeOnMount
                onRowClick={this.props.onClick}
                automationId="medicationEquivalenceListPanel_dataGrid"
            >
                <DataGridColumn
                    dataGetter={"name"}
                    header={StaticCareResources.ReferenceData.MedicationListPanel.Columns.Name}
                    isOrderable
                    clientSideOrderableValueGetter={"name"}
                    id="Name"
                    width="60%"
                    hideOverflow
                />
                <DataGridColumn
                    dataGetter={"textAmount"}
                    header={StaticCareResources.ReferenceData.MedicationListPanel.Columns.AmountInPackage}
                    hideOverflow
                    width={100}
                />
                <DataGridColumn
                    dataGetter={"grossPrice"}
                    onRenderCellValue={this.renderGrossPrice}
                    header={StaticCareResources.ReferenceData.MedicationInfoPanel.Label.MedicationGrossPrice}
                    hideOverflow
                    width={75}
                />
                <DataGridColumn
                    dataGetter={"orderTypeId"}
                    onRenderCellValue={this.renderOrderTypeId}
                    header={StaticCareResources.ReferenceData.MedicationInfoPanel.Label.OrderType}
                    width={50}
                />
            </Ui.DataGrid>
        );
    }
}

export default connect(
    MedicationEquivalenceListPanel,
    new DependencyAdapter<IMedicationEquivalenceListPanelProps, IMedicationListPanelDependencies>(c => ({
        apiAdapter: c.resolve("ReferenceDataApiAdapter"),
        localizationService: c.resolve("ILocalizationService")
    }))
);