import MedicationSubsidyOptionId from "@Primitives/MedicationSubsidyOptionId.g";
import SubsidyOptionRestrictionCheckResult from "./SubsidyOptionRestrictionCheckResult";
import MedicationSubsidyId from "@Primitives/MedicationSubsidyId.g";

export default class SubsidyOptionCheckResult {
    constructor(
        public medicationSubsidyOptionId: MedicationSubsidyOptionId,
        public medicationSubsidyId: MedicationSubsidyId,
        public conditionRestrictionsMatch: boolean,
        public optionRestrictionResults: SubsidyOptionRestrictionCheckResult[]
    ) { }

    public get successful() {
        return this.conditionRestrictionsMatch && this.optionRestrictionResults.some(i => i.successful);
    }

    public get isOnlySuccessfulResultRestricted() {
        return this.optionRestrictionResults.filter(i => i.successful).every(i => i.restrictionIsDesignated);
    }
}