import ExtendedFilterStoreBase from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ExtendedFilterStoreBase";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientId from "@Primitives/PatientId.g";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import { IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import { createFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterHelpers";

export default class AppointmentNDataPanelFilterStore extends ExtendedFilterStoreBase {
    constructor(
        filterStore: IFilterStore
    ) {
        super(filterStore);
    }

    @State.observable.ref public patientId: PatientId = null;

    @State.action.bound
    public setPatientId(value: PatientId) {
        this.patientId = value;
        this.setFilter("AppointmentScheduleEntryBased_PatientId", value);
    }

    public static getFilterDescriptors() {
        const descriptors: IDataGridFilterDescriptor[] = [];

        const patientIdFilterDescriptor = createFilterDescriptor("AppointmentScheduleEntryBased_PatientId", {
            serialize: (value: PatientId) => value.value,
            deserialize: (value: string) => new PatientId(value)
        } as IDataGridFilterValueSerializer<PatientId>,
        WorklistConditionType.EntityId);
        descriptors.push(patientIdFilterDescriptor);

        return descriptors;
    }
}