exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PatientAdministrativeDataPanel_no-text-selection_1EyzN {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.PatientAdministrativeDataPanel_title_1mP9G > h1.PatientAdministrativeDataPanel_title-not-uppercase_TTCDi {\n  text-transform: none;\n}\n.PatientAdministrativeDataPanel_title-badge_3Qjjw {\n  margin-right: 4px;\n}\n.PatientAdministrativeDataPanel_titleIconContainer_1QEM3 {\n  display: flex;\n  align-items: center;\n}\n.PatientAdministrativeDataPanel_identifier-checkbox_3H5kf {\n  padding-top: 28px;\n}\n.PatientAdministrativeDataPanel_estimated-label_2pS6G {\n  font-size: 14px;\n  margin-left: 4px;\n}\n.PatientAdministrativeDataPanel_estimated-value_2nlVQ {\n  line-height: 31px;\n  margin-left: 4px;\n}\n.PatientAdministrativeDataPanel_checkbox-vertical_2WD-z {\n  padding-top: 30px;\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "PatientAdministrativeDataPanel_no-text-selection_1EyzN",
	"noTextSelection": "PatientAdministrativeDataPanel_no-text-selection_1EyzN",
	"title": "PatientAdministrativeDataPanel_title_1mP9G",
	"title-not-uppercase": "PatientAdministrativeDataPanel_title-not-uppercase_TTCDi",
	"titleNotUppercase": "PatientAdministrativeDataPanel_title-not-uppercase_TTCDi",
	"title-badge": "PatientAdministrativeDataPanel_title-badge_3Qjjw",
	"titleBadge": "PatientAdministrativeDataPanel_title-badge_3Qjjw",
	"titleIconContainer": "PatientAdministrativeDataPanel_titleIconContainer_1QEM3",
	"identifier-checkbox": "PatientAdministrativeDataPanel_identifier-checkbox_3H5kf",
	"identifierCheckbox": "PatientAdministrativeDataPanel_identifier-checkbox_3H5kf",
	"estimated-label": "PatientAdministrativeDataPanel_estimated-label_2pS6G",
	"estimatedLabel": "PatientAdministrativeDataPanel_estimated-label_2pS6G",
	"estimated-value": "PatientAdministrativeDataPanel_estimated-value_2nlVQ",
	"estimatedValue": "PatientAdministrativeDataPanel_estimated-value_2nlVQ",
	"checkbox-vertical": "PatientAdministrativeDataPanel_checkbox-vertical_2WD-z",
	"checkboxVertical": "PatientAdministrativeDataPanel_checkbox-vertical_2WD-z"
};