import Dosage from "./Dosage";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class TextualDosage extends Dosage {
    @State.observable public dosageInstructions: string;

    @State.action.bound
    public setDosageInstruction(dosageInstruction: string) {
        this.dosageInstructions = dosageInstruction;
    }
}