/* auto-inject-disable */
import ApiAdapterBase2 from "./ApiAdapterBase2";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import IMapperService from "@HisPlatform/Services/Definition/MapperService/IMapperService";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import IWorklistData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistData";
import IDynamicListDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IDynamicListDefinition";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import IFrontendListDataWithDefinition from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/IFrontendListDataWithDefinition";

@Di.injectable()
export default class CareWorklistApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("ICareClient") private readonly apiClient: Proxy.ICareClient,
        @Di.inject("IMapperService") private readonly mapperService: IMapperService,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public getCareWorklistAsync(pointOfCareId: PointOfCareId, worklistCode: string, frontendListParameters: FrontendListParameters) {
        return this.executeOperationAsync<IFrontendListDataWithDefinition, Proxy.SearchPatientsQueryResponse>(
            () => this.apiClient.getCareWorklistQueryAsync(
                CreateRequestId(),

                new Proxy.GetCareWorklistControllerDto({
                    pointOfCareId,
                    worklistCode,
                    filters: this.mapperService.mapByName("FilterDtoArray", frontendListParameters),
                    pagingAndOrderings: this.mapperService.mapByName("QueryPagingsAndOrderings", frontendListParameters),
                    requestMode: frontendListParameters.requestMode
                })
            ),
            response => {
                return this.mapperService.map<Proxy.FrontendListDto, IFrontendListDataWithDefinition>(response.frontendList);
            }
        );
    }
}
