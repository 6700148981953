/* auto-inject-disable */
import Di from "@Di";
import _ from "@HisPlatform/Common/Lodash";
import IServiceRequestDataService from "@HisPlatform/BoundedContexts/Care/Services/Definition/ServiceRequestManagement/IServiceRequestDataService";
import ServiceRequestManagementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestManagementApiAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import ServiceRequestStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestStoreMapper";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import {getObservation} from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/Observations/ObservationStoreMapper";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import RequestedServiceId from "@Primitives/RequestedServiceId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import LockAcquirerOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockAcquirerOperationInfo";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import {CreateRequestId} from "@HisPlatform/Common/RequestHelper";
import {createOperationInfo} from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import {getCreateServiceRequestCommandDto, getModifyServiceRequestCommandDto} from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestDtoMapper";
import {getObservationCreationDto, getObservationUpdateDto} from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/Observations/ObservationDtoMappers";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import IObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/IObservation";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import {mapValidationResults} from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import AdjustedCareActivityBaseDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/AdjustedCareActivityBaseDataStore";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import {mapProxyFormFieldDataAsync} from "@HisPlatform/BoundedContexts/FormEngine/ApplicationLogic/ApiAdapter/FormEngineMappers";
import CareActivityBaseData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareActivityBaseData";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import RequestedServiceAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceAction.g";

@Di.injectable()
export default class ServiceRequestDataService extends ApiAdapterBase implements IServiceRequestDataService {
    constructor(
        @Di.inject("ServiceRequestManagementApiAdapter") public serviceRequestManagementApi: ServiceRequestManagementApiAdapter,
        @Di.inject("CareReferenceDataStore") public careReferenceData: CareReferenceDataStore,
        @Di.inject("LockingApiAdapter") public lockingApiAdapter: LockingApiAdapter,

        @Di.inject("IServiceRequestManagementClient") private readonly apiClient: Proxy.IServiceRequestManagementClient,
        @Di.inject("ServiceRequestStoreMapper") private readonly serviceRequestStoreMapper: ServiceRequestStoreMapper,
        @Di.inject("IFormEngineReferenceDataStore") private readonly formEngineReferenceDataStore: IFormEngineReferenceDataStore
    ) {
        super();
    }

    public async getServiceRequestByIdAsync(
        serviceRequestId: ServiceRequestId,
        careActivityId: CareActivityId,
        requestLockIfStarter: boolean,
        requestLockIfExecuting: boolean): Promise<ServiceRequestStore> {
        const response =
            await this.getServiceRequestByIdApiCallAsync(
                serviceRequestId,
                careActivityId,
                requestLockIfStarter,
                requestLockIfExecuting);

        const store = await this.mapServiceRequestAsync(response);
        return store;
    }

    public getAdjustedCareActivityBaseDataAsync(serviceRequestId: ServiceRequestId): Promise<AdjustedCareActivityBaseDataStore> {
        return this.processOperationAsync(
            new AdjustedCareActivityBaseDataStore(),
            async target => {
                const response = await this.apiClient.getAdjustedCareActivityBaseDataQueryAsync(
                    CreateRequestId(),
                    serviceRequestId.value,
                    false);

                const mappedFormFieldData: FormFieldDataBase[] = [];
                await mapProxyFormFieldDataAsync(response.adjustedCareActivityBaseData.data as any, mappedFormFieldData, this.formEngineReferenceDataStore);

                const adjustedCareActivityBaseData = new CareActivityBaseData(
                    mappedFormFieldData,
                    response.adjustedCareActivityBaseData.rowVersion,
                    null,
                    null);
                target.operationInfo = createOperationInfo(response);
                target.setOriginalPointOfCareId(response.originalPointOfCareId);
                target.setAdjustedCareActivityBaseData(adjustedCareActivityBaseData);
            }
        );
    }

    public async getServiceRequestByIdWithForceLockAsync(
        serviceRequestId: ServiceRequestId,
        careActivityId: CareActivityId): Promise<ServiceRequestStore> {

        let response = await this.getServiceRequestByIdApiCallAsync(serviceRequestId, careActivityId, true, true);

        if (response.operationInfo instanceof LockAcquirerOperationInfo) {
            const lockInfo = (response.operationInfo as LockAcquirerOperationInfo).lockInfo;

            if (lockInfo.lockState === EntityLockState.LockingRequiredAndLockNotHeld) {
                await this.lockingApiAdapter.forceReleaseLockAsync(lockInfo.preventingLockId);

                response = await this.getServiceRequestByIdApiCallAsync(serviceRequestId, careActivityId, true, true);
            }
        }

        const store = await this.mapServiceRequestAsync(response);

        return store;
    }

    public async executeRequestedServiceActionsAsync(
        activeCareActivityId: CareActivityId,
        serviceRequestId: ServiceRequestId,
        rowVersion: RowVersion,
        actionsForRequestedServices: Array<{ id: RequestedServiceId, action: RequestedServiceAction }>,
        lockInfo: LockInfo,
        releaseLockIfSuccessful: boolean,
        extensionData: any): Promise<ServiceRequestStore> {
        const response = await this.executeRequestedServiceActionsApiCallAsync(
            activeCareActivityId,
            serviceRequestId,
            rowVersion,
            actionsForRequestedServices,
            lockInfo,
            releaseLockIfSuccessful,
            extensionData);

        const store = await this.mapServiceRequestAsync(response);
        if (!response.isPersistedByOperationInfo) {
            store.lockInfo = lockInfo;
        }
        return store;
    }

    public async completeDiagnosticReportAsync(
        activeCareActivityId: CareActivityId,
        serviceRequestStore: ServiceRequestStore,
        lockId: LockId,
        releaseLockIfSuccessful: boolean,
        isValidateOnly: boolean): Promise<ServiceRequestStore> {
        let observations: IObservation[] = null;
        const response = await this.completeDiagnosticReportApiCallAsync(
            activeCareActivityId,
            serviceRequestStore,
            lockId,
            releaseLockIfSuccessful,
            isValidateOnly);

        const store = new ServiceRequestStore();
        const mapper = this.serviceRequestStoreMapper;
        mapper.applyToStore(store, response.value);

        await this.initializeObservationDefinitionsAsync(store);
        if (response.isPersistedByOperationInfo) {
            observations = response.value.observations.map(getObservation);
        }
        observations = _.flatten(serviceRequestStore.requestedServices.map(i => i.newObservations));
        store.requestedServices.forEach(i => i.initializeObservations(store.patientId, store.executingCareActivityId, observations));
        return store;
    }

    public async createServiceRequestAsync(
        store: ServiceRequestStore,
        shouldSaveWithValidationProblems: boolean,
        requestLock: boolean,
        isEmpty: boolean): Promise<ServiceRequestStore> {
        const response =
            await this.createServiceRequestApiCallAsync(store, shouldSaveWithValidationProblems, requestLock, false, isEmpty, false);

        const result = await this.mapServiceRequestAsync(response);
        return result;
    }

    public async checkPermissionForCreateServiceRequestAsync(store: ServiceRequestStore): Promise<ServiceRequestStore> {
        const response =
            await this.createServiceRequestApiCallAsync(store, false, false, false, false, true);

        const result = await this.mapServiceRequestAsync(response);
        return result;
    }

    public async validateServiceRequestAsync(store: ServiceRequestStore, isEmpty: boolean) {
        const response =
            await this.createServiceRequestApiCallAsync(store, false, false, true, isEmpty, false);

        const result = mapValidationResults(response.value.compositeValidationResult as unknown as IServerCompositeValidationResult);
        return result;
    }

    public async modifyServiceRequestAsync(
        store: ServiceRequestStore,
        shouldSaveWithValidationProblems: boolean,
        careActivityId: CareActivityId,
        lockId: LockId,
        releaseLockIfSuccessful: boolean,
        isEmpty: boolean): Promise<ServiceRequestStore> {
        const response =
            await this.modifyServiceRequestApiCallAsync(
                store,
                shouldSaveWithValidationProblems,
                careActivityId,
                lockId,
                releaseLockIfSuccessful,
                isEmpty,
                false);

        const result = await this.mapServiceRequestAsync(response);
        return result;
    }

    public async checkPermissionForModifyServiceRequestAsync(store: ServiceRequestStore, careActivityId: CareActivityId): Promise<ServiceRequestStore> {
        const response =
            await this.modifyServiceRequestApiCallAsync(
                store,
                false,
                careActivityId,
                new LockId("-1"),
                false,
                false,
                true);

        const result = await this.mapServiceRequestAsync(response);
        return result;
    }

    public async renewSubmittedServiceRequestAsync(
        id: ServiceRequestId,
        activeCareActivityId: CareActivityId,
        rowVersion: RowVersion,
        requestLock: boolean,
        lockId: LockId,
        extensionData: any): Promise<ServiceRequestStore> {
        const response =
            await this.renewSubmittedServiceRequestApiCallAsync(
                id,
                activeCareActivityId,
                rowVersion,
                requestLock,
                lockId,
                extensionData,
                false);

        const result = await this.mapServiceRequestAsync(response);
        return result;
    }

    public async checkPermissionForRenewSubmittedServiceRequestAsync(id: ServiceRequestId, activeCareActivityId: CareActivityId): Promise<ServiceRequestStore> {
        const response =
            await this.renewSubmittedServiceRequestApiCallAsync(
                id,
                activeCareActivityId,
                new RowVersion(-1),
                false,
                new LockId("-1"),
                null,
                true);

        const result = await this.mapServiceRequestAsync(response);
        return result;
    }

    public async getServiceRequestForExecutingCareActivityAsync(
        careActivityId: CareActivityId,
        requestLock: boolean): Promise<ServiceRequestStore> {
        const response =
            await this.getServiceRequestForExecutingCareActivityApiCallAsync(
                careActivityId,
                requestLock);

        if (isNullOrUndefined(response.value)) {
            const store = new ServiceRequestStore();
            store.isNull = true;

            return store;
        }

        const result = await this.mapServiceRequestAsync(response);
        return result;
    }

    public async modifyServiceRequestExecutionDataAsync(
        executingCareActivityId: CareActivityId,
        executingDoctorId: PractitionerId,
        serviceRequestId: ServiceRequestId,
        rowVersion: RowVersion,
        lockId: LockId,
        releaseLockIfSuccessful: boolean): Promise<ServiceRequestStore> {
        const response =
            await this.modifyServiceRequestExecutionDataApiCallAsync(
                executingCareActivityId,
                executingDoctorId,
                serviceRequestId,
                rowVersion,
                lockId,
                releaseLockIfSuccessful,
                false);

        const result = await this.mapServiceRequestAsync(response);
        return result;
    }

    public async checkPermissionForModifyServiceRequestExecutionDataAsync(serviceRequestId: ServiceRequestId): Promise<ServiceRequestStore> {
        const response =
            await this.modifyServiceRequestExecutionDataApiCallAsync(
                new CareActivityId("-1"),
                null,
                serviceRequestId,
                new RowVersion(-1),
                new LockId("-1"),
                false,
                true);

        const result = await this.mapServiceRequestAsync(response);
        return result;
    }

    public deleteDraftServiceRequestAsync(
        id: ServiceRequestId,
        activeCareActivityId: CareActivityId,
        rowVersion: RowVersion,
        lockId: LockId,
        releaseLockIfSuccessful: boolean): Promise<ServiceRequestStore> {
        return this.processOperationAsync(
            new ServiceRequestStore(),
            async target => {
                const response = await this.apiClient.deleteDraftServiceRequestCommandAsync(CreateRequestId(), new Proxy.DeleteDraftServiceRequestControllerDto({
                    activeCareActivityId: activeCareActivityId,
                    serviceRequestId: id,
                    rowVersion: rowVersion,
                    lockId: lockId,
                    releaseLockIfSuccessful: releaseLockIfSuccessful
                }));

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public checkPermissionForDeleteDraftServiceRequestAsync(id: ServiceRequestId, activeCareActivityId: CareActivityId): Promise<ServiceRequestStore> {
        return this.processOperationAsync(
            new ServiceRequestStore(),
            async target => {
                const response = await this.apiClient.deleteDraftServiceRequestCommandAsync(CreateRequestId(), new Proxy.DeleteDraftServiceRequestControllerDto({
                    activeCareActivityId: activeCareActivityId,
                    serviceRequestId: id,
                    rowVersion: new RowVersion(-1),
                    lockId: new LockId("-1"),
                    releaseLockIfSuccessful: false
                }), true);

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public async mapServiceRequestAsync(response: SimpleStore<any>) {
        const store = new ServiceRequestStore();

        if (response.operationInfo instanceof LockAcquirerOperationInfo) {
            store.lockInfo = (response.operationInfo as LockAcquirerOperationInfo).lockInfo;
        }

        const mapper = this.serviceRequestStoreMapper;
        mapper.applyToStore(store, response.value);

        await this.initializeObservationDefinitionsAsync(store);

        if (!isNullOrUndefined(store.executingCareActivityId)) {
            const observationsResponse = await this.serviceRequestManagementApi.getObservationsForRequestedServicesAsync(store.id);
            store.requestedServices.forEach(i => i.initializeObservations(store.patientId, store.executingCareActivityId, observationsResponse.value));
        }

        return store;
    }

    private async initializeObservationDefinitionsAsync(store: ServiceRequestStore) {
        const medicalServices = store.requestedServices.map(i => i.medicalServiceVersionSelector);
        const observationDefinitions =
            await this.careReferenceData.observationDefinition.loadObservationDefinitionsForMedicalServicesAsync(medicalServices);

        store.requestedServices.forEach(i => i.initializeObservationDefinitions(observationDefinitions.value));
    }

    @State.bound
    private getServiceRequestByIdApiCallAsync(
        serviceRequestId: ServiceRequestId,
        careActivityId: CareActivityId,
        requestLockIfStarting: boolean,
        requestLockIfExecuting: boolean): Promise<SimpleStore<Proxy.GetServiceRequestCommandResponse>> {
        return this.processOperationAsync(
            new SimpleStore<Proxy.GetServiceRequestCommandResponse>(),
            async target => {
                const response =
                    await this.apiClient.getServiceRequestCommandAsync(CreateRequestId(),
                        new Proxy.GetServiceRequestControllerDto({
                            activeCareActivityId: careActivityId,
                            serviceRequestId: serviceRequestId,
                            requestLockIfStarting: requestLockIfStarting,
                            requestLockIfExecuting: requestLockIfExecuting,
                            requestLock: requestLockIfStarting || requestLockIfExecuting // must be set for appropriate permission checks
                        }));

                target.operationInfo = createOperationInfo(response);
                target.value = response;
            }
        );
    }

    @State.bound
    private executeRequestedServiceActionsApiCallAsync(
        activeCareActivityId: CareActivityId,
        serviceRequestId: ServiceRequestId,
        rowVersion: RowVersion,
        actionsForRequestedServices: Array<{ id: RequestedServiceId, action: RequestedServiceAction }>,
        lockInfo: LockInfo,
        releaseLockIfSuccessful: boolean,
        extensionData: any): Promise<SimpleStore<Proxy.ExecuteRequestedServiceActionsCommandResponse>> {
        return this.processOperationAsync(
            new SimpleStore<Proxy.ExecuteRequestedServiceActionsCommandResponse>(),
            async target => {
                const response = await this.apiClient.executeRequestedServiceActionsCommandAsync(
                    CreateRequestId(),
                    new Proxy.ExecuteRequestedServiceActionsControllerDto({
                        activeCareActivityId: activeCareActivityId,
                        serviceRequestId: serviceRequestId,
                        serviceRequestRowVersion: rowVersion,
                        actionsToExecute: actionsForRequestedServices.map(i => {
                            const action = new Proxy.ActionForRequestedServiceDto();
                            action.requestedServiceId = i.id;
                            action.action = i.action;

                            return action;
                        }),
                        lockId: lockInfo.lockId,
                        releaseLockIfSuccessful: releaseLockIfSuccessful,
                        extensionData: extensionData
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response;
            }
        );
    }

    @State.bound
    private createServiceRequestApiCallAsync(
        store: ServiceRequestStore,
        shouldSaveWithValidationProblems: boolean,
        requestLock: boolean,
        isValidateOnly: boolean,
        isEmpty: boolean,
        isPermissionCheckOnly: boolean): Promise<SimpleStore<Proxy.CreateServiceRequestCommandResponse>> {
        return this.processOperationAsync(
            new SimpleStore<Proxy.CreateServiceRequestCommandResponse>(),
            async target => {
                const dto = getCreateServiceRequestCommandDto(store, shouldSaveWithValidationProblems, requestLock, isValidateOnly, isEmpty);
                const response = await this.apiClient.createServiceRequestCommandAsync(CreateRequestId(), dto, isPermissionCheckOnly);
                target.operationInfo = createOperationInfo(response);
                target.value = response;
            }
        );
    }

    @State.bound
    private modifyServiceRequestApiCallAsync(
        store: ServiceRequestStore,
        shouldSaveWithValidationProblems: boolean,
        careActivityId: CareActivityId,
        lockId: LockId,
        releaseLockIfSuccessful: boolean,
        isEmpty: boolean,
        isPermissionCheckOnly: boolean): Promise<SimpleStore<Proxy.ModifyServiceRequestCommandResponse>> {
        return this.processOperationAsync(
            new SimpleStore<Proxy.ModifyServiceRequestCommandResponse>(),
            async target => {
                const dto =
                    getModifyServiceRequestCommandDto(
                        store,
                        shouldSaveWithValidationProblems,
                        careActivityId,
                        lockId,
                        releaseLockIfSuccessful,
                        isEmpty);
                const response = await this.apiClient.modifyServiceRequestCommandAsync(CreateRequestId(), dto, isPermissionCheckOnly);

                target.operationInfo = createOperationInfo(response);
                target.value = response;
            }
        );
    }

    @State.bound
    private renewSubmittedServiceRequestApiCallAsync(// TODO: backend
        id: ServiceRequestId,
        activeCareActivityId: CareActivityId,
        rowVersion: RowVersion,
        requestLock: boolean,
        lockId: LockId,
        extensionData: any,
        isPermissionCheckOnly: boolean): Promise<SimpleStore<Proxy.RenewSubmittedServiceRequestCommandResponse>> {
        return this.processOperationAsync(
            new SimpleStore<Proxy.RenewSubmittedServiceRequestCommandResponse>(),
            async target => {
                const response = await this.apiClient.renewSubmittedServiceRequestCommandAsync(CreateRequestId(),
                    new Proxy.RenewSubmittedServiceRequestControllerDto({
                        activeCareActivityId: activeCareActivityId,
                        serviceRequestId: id,
                        serviceRequestRowVersion: rowVersion,
                        requestLock: requestLock,
                        lockId: lockId,
                        releaseLockIfSuccessful: false,
                        extensionData: extensionData
                    }), isPermissionCheckOnly);

                target.operationInfo = createOperationInfo(response);
                target.value = response;
            }
        );
    }

    @State.bound
    private getServiceRequestForExecutingCareActivityApiCallAsync(careActivityId: CareActivityId, requestLock: boolean): Promise<SimpleStore<Proxy.TryGetServiceRequestForExecutingCareActivityCommandResponse>> {
        return this.processOperationAsync(
            new SimpleStore<Proxy.TryGetServiceRequestForExecutingCareActivityCommandResponse>(),
            async target => {
                const response = await this.apiClient.tryGetServiceRequestForExecutingCareActivityCommandAsync(
                    CreateRequestId(),
                    new Proxy.TryGetServiceRequestForExecutingCareActivityControllerDto({
                        careActivityId: careActivityId,
                        requestLock: requestLock
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response;
            }
        );
    }

    @State.bound
    private modifyServiceRequestExecutionDataApiCallAsync(
        executingCareActivityId: CareActivityId,
        executingDoctorId: PractitionerId,
        serviceRequestId: ServiceRequestId,
        rowVersion: RowVersion,
        lockId: LockId,
        releaseLockIfSuccessful: boolean,
        isPermissionCheckOnly: boolean): Promise<SimpleStore<Proxy.ModifyServiceRequestExecutionDataCommandResponse>> {
        return this.processOperationAsync(
            new SimpleStore<Proxy.ModifyServiceRequestExecutionDataCommandResponse>(),
            async target => {
                const response = await this.apiClient.modifyServiceRequestExecutionDataCommandAsync(
                    CreateRequestId(),
                    new Proxy.ModifyServiceRequestExecutionDataControllerDto({
                        executingCareActivityId: executingCareActivityId,
                        executingDoctorId: executingDoctorId,
                        serviceRequestId: serviceRequestId,
                        rowVersion: rowVersion,
                        lockId: lockId,
                        releaseLockIfSuccessful: releaseLockIfSuccessful
                    }), isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response;
            }
        );
    }

    @State.bound
    private completeDiagnosticReportApiCallAsync(
        activeCareActivityId: CareActivityId,
        serviceRequestStore: ServiceRequestStore,
        lockId: LockId,
        releaseLockIfSuccessful: boolean,
        isValidateOnly: boolean): Promise<SimpleStore<Proxy.CompleteDiagnosticReportCommandResponse>> {
        return this.processOperationAsync(
            new SimpleStore<Proxy.CompleteDiagnosticReportCommandResponse>(),
            async target => {
                const response = await this.apiClient.completeDiagnosticReportCommandAsync(
                    CreateRequestId(),
                    new Proxy.CompleteDiagnosticReportControllerDto({
                        activeCareActivityId: activeCareActivityId,
                        serviceRequestId: serviceRequestStore.id,
                        observationsToAdd:
                            _.flatten(serviceRequestStore.requestedServices
                                .filter(i => i.newObservations.length > 0)
                                .map(i => i.newObservations.map(j => getObservationCreationDto(i.id, j)))),
                        observationsToUpdate: _.flatten(serviceRequestStore.requestedServices.map(i =>
                            i.modifiedObservations.map(getObservationUpdateDto))),
                        lockId: lockId,
                        releaseLockIfSuccessful: releaseLockIfSuccessful,
                        isValidateOnly: isValidateOnly
                    })
                );
                target.operationInfo = createOperationInfo(response);
                target.value = response;
            }
        );
    }
}