import State from "@Toolkit/ReactClient/Common/StateManaging";
import Guid from "@Toolkit/CommonWeb/Guid";
import IEditableLayoutBlockStore from "./IEditableLayoutBlockStore";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import EditableMultiLingualLabel from "@Toolkit/FormEngine/Model/Layout/EditableMultiLingualLabel";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Language, { convertCultureCodeToLanguage, convertLanguageToCultureCode, getCurrentOrDefaultCultureCode } from "@Primitives/Language";

export default class EditableLayoutGroupBlockStore implements IEditableLayoutBlockStore {

    public readonly id: string;
    public readonly contentBlocks = State.createObservableShallowArray<IEditableLayoutBlockStore>([]);
    @State.observable public multiLingualDisplayName: EditableMultiLingualLabel;
    @State.observable public currentLabelLanguage: Language;
    public parentDataReferences: string[] = [];
    @State.observable.ref public dataReference: string = null;
    @State.observable.ref public isArray: boolean = false;
    @State.observable.ref public hasDataReference: boolean = false;

    constructor(
        currentCultureCode: string,
        dataReference: string,
        parentDataRerefences: string[],
        isArray: boolean,
        multiLingualDisplayName?: EditableMultiLingualLabel
    ) {
        this.id = Guid.newGuid();
        this.dataReference = dataReference;
        this.hasDataReference = isNullOrUndefined(dataReference) ? false : true;
        this.parentDataReferences = parentDataRerefences;
        this.isArray = isArray;

        if (!isNullOrUndefined(multiLingualDisplayName)) {
            this.multiLingualDisplayName = multiLingualDisplayName;
            this.setCurrentLabelLanguage(getCurrentOrDefaultCultureCode(currentCultureCode, this.multiLingualDisplayName));
        } else {
            this.multiLingualDisplayName = new EditableMultiLingualLabel();
            this.currentLabelLanguage = convertCultureCodeToLanguage(currentCultureCode);
            this.multiLingualDisplayName.setLocalizedLabel(convertLanguageToCultureCode(this.currentLabelLanguage), StaticFormEngineResources.FormDefinitionEditor.Default.NewGroupDefaultLabel);
        }
    }

    @State.action.bound
    public setLocalizedLabel(label: string) {
        this.multiLingualDisplayName.setLocalizedLabel(convertLanguageToCultureCode(this.currentLabelLanguage), label);
    }

    @State.computed
    public get localizedLabel() {
        return this.multiLingualDisplayName.getLocalizedLabel(convertLanguageToCultureCode(this.currentLabelLanguage));
    }

    @State.action.bound
    public setCurrentLabelLanguage(currentLabelLanguage: Language) {
        this.currentLabelLanguage = currentLabelLanguage;
    }

    @State.action.bound
    public setDataReference(value: string) {
        this.dataReference = value;
    }

    @State.action.bound
    public setHasDataReference(value: boolean) {
        this.hasDataReference = value;
    }

    @State.action.bound
    public setIsArray(value: boolean) {
        this.isArray = value;
    }

    @State.action.bound
    public addParentDataReference(value: string) {
        if (isNullOrUndefined(this.parentDataReferences)) {
            this.parentDataReferences = [value];
        } else {
            this.parentDataReferences.push(value);
        }
    }
}