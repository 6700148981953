import React from "react";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import { RadioButtonGroupFactory } from "@CommonControls/RadioButtonGroup";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export interface IExtensibleEnumRadioButtonGroupProps<TEnumId extends IStringEntityId = any> {
    extensibleEnumStore: ExtensibleEnumStore<TEnumId>;
    display: "name" | "shortHandAndName";
    value: TEnumId;
    onChange: (id: TEnumId) => void;
    direction?: "row" | "column";
    displayType?: "buttons" | "groupedButtons";
    automationId?: string;
    label?: string;
    addNullItem?: boolean;
    nullItemText?: string;
    isInline?: boolean;
}


const RadioButtonGroup = RadioButtonGroupFactory<IStringEntityId>();
@State.observer
export default class ExtensibleEnumRadioButtonGroup<TEnumId extends IStringEntityId = any> extends React.Component<IExtensibleEnumRadioButtonGroupProps<TEnumId>> {

    public componentDidMount() {
        dispatchAsyncErrors(this.props.extensibleEnumStore.ensureLoadedAsync(), this);
    }

    public render() {
        const items = this.props.extensibleEnumStore.items.map(item => item.id);
        return (
            <RadioButtonGroup
                label={this.props.label}
                value={this.props.value}
                items={items}
                getDisplayValue={this.renderEnumValue}
                onChange={this.props.onChange}
                direction={this.props.direction}
                displayType={this.props.displayType}
                automationId={this.props.automationId}
                addNullItem={this.props.addNullItem}
                inline={this.props.isInline}
            />
        );
    }
    @State.bound
    private renderEnumValue(id: TEnumId) {
        if (id === null) {
            return this.props.nullItemText;
        }

        const item = this.props.extensibleEnumStore.items.find(element => element.id === id);
        switch (this.props.display) {
            case "name":
                return item.displayValue.Name;
            case "shortHandAndName":
                return `${item.displayValue.Shorthand} - ${item.displayValue.Name}`;

        }
    }
}