import React, { useMemo } from "react";
import Styles from "./PageBox.less";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";

export interface IPageBoxHeaderProps {
    title?: React.ReactNode;
    toolbar?: "right" | "both" | "left";
}


const PageBoxHeader: React.SFC<IPageBoxHeaderProps> = props => {

    const className = new CompositeClassName();

    const toolbarClass = useMemo(() => {
        switch (props.toolbar) {
            case "left":
                return Styles.toolbarContainerLeft;
            case "right":
                return Styles.toolbarContainerRight;
            case "both":
                return Styles.toolbarContainerBoth;
        }
        return null;
    }, [props.toolbar]);

    className.addIf(!!toolbarClass, toolbarClass);

    if (props.toolbar) {
        return (
            <div className={className.classNames}>
                {props.title !== undefined ? (
                    <>
                        <div>{typeof props.title === "string" ? <h1>{props.title}</h1> : props.title}</div>
                        <div>{props.children}</div>
                    </>
                ) : props.children}
            </div>
        );
    }

    if (props.title) {
        if (typeof props.title === "string") {
            return <h1>{props.title}</h1>;
        } else {
            return props.title as any;
        }
    }

    return props.children as any;
};

export default PageBoxHeader;