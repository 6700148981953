import React from "react";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import { RowId, IRowIndicatorStyle } from "@CommonControls/DataGrid/IDataGridProps";
import { IRowData } from "@CommonControls/DataGrid/ViewModel";
import Styles from "./DataGrid.less";

interface IDataGridRowIndicatorCellProps {
    rowData: IRowData;
    rowIndex: number;
    rowIndicatorStyle: IRowIndicatorStyle;
}


const DataGridRowIndicatorCell: React.FC<IDataGridRowIndicatorCellProps> = props => {
    if (!props.rowIndicatorStyle) {
        return <td role="rowIndicator" />;
    }
    const classes = combineClasses(Styles.rowIndicator, props.rowIndicatorStyle.className);
    return <td role="rowIndicator" className={classes} style={{ ...props.rowIndicatorStyle.style, backgroundColor: props.rowIndicatorStyle.color }} />;
};

export default DataGridRowIndicatorCell;