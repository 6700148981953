import React, { useMemo } from "react";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";

interface IFormattedTextProps {
    children: string;
    [id: string]: string;
}


const FormattedText: React.FC<IFormattedTextProps> = props => {
    const text = useMemo(() => {
        const params = Object.keys(props).reduce((all, key) => {
            if (key.startsWith("p_")) {
                all[key.substr(2)] = props[key];
            }
            return all;
        }, {});

        return formatStringWithObjectParams(props.children, params);

    }, [props.children]);
    return <>{text}</>;
};

export default FormattedText;