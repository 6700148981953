import React from "react";
import * as Ui from "@CommonControls";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import AllergyIntoleranceClinicalStatus from "@Primitives/AllergyIntoleranceClinicalStatus";

interface IAllergyIntoleranceClinicalStatusSelectBoxProps extends ISelectBoxBaseProps {
    value?: AllergyIntoleranceClinicalStatus;
    onChange?: (newValue: AllergyIntoleranceClinicalStatus) => void;
}


const AllergyIntoleranceClinicalStatusSelectBox: React.FC<IAllergyIntoleranceClinicalStatusSelectBoxProps> = props => (
    <Ui.UniversalEnumSelector
        {...props}
        enumName={"AllergyIntoleranceClinicalStatus"}
        enumOrigin="server"
        enumType={AllergyIntoleranceClinicalStatus}
        displayMode="selectBox"
        clearable
    />
);

export default AllergyIntoleranceClinicalStatusSelectBox;