import React from "react";
import Icon, { iconNameType } from "@CommonControls/Icon";
import { ToastType } from "react-toastify";
import Styles from "./Toast.less";

interface IToast {
    closeToast?: () => void;
    toastType: ToastType;
    text: string;
}

const Toast: React.FC<IToast> = (props) => {
    let fillTypeClassName;
    let iconName: iconNameType;

    switch (props.toastType) {
        case "success" as ToastType:
            fillTypeClassName = Styles.successFill;
            iconName = "check_circle_regular";
            break;
        case "info" as ToastType:
            fillTypeClassName = Styles.infoFill;
            iconName = "info_with_circle";
            break;
        case "warning" as ToastType:
            fillTypeClassName = Styles.warningFill;
            iconName = "warning";
            break;
        case "error" as ToastType:
            fillTypeClassName = Styles.errorFill;
            iconName = "invalid_outlined_circle";
            break;
        default:
            throw new Error("Invalid ToastType: " + props.toastType);
    }

    return (
        <div className={Styles.toastWrapper}>
            <div style={{paddingRight: 11, paddingLeft: 8 }}>
                <Icon iconName={iconName} className={fillTypeClassName} />
            </div>
            <div className={Styles.toastText}>{props.text}</div>
            <div style={{paddingRight: 8, paddingLeft: 11 }}>
                <Icon iconName="close_x" className={fillTypeClassName} onClick={props.closeToast} />
            </div>
        </div>
    );
};

export default Toast;