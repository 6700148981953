import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import IOutpatientTreatmentRouteParams from "@HisPlatform/Application/Routes/IOutpatientTreatmentRouteParams";
import CareActivityId from "@Primitives/CareActivityId.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import standaloneRouteDefinitions, { ICareUseCaseRouteParams } from "@HunEHealthInfrastructurePlugin/UseCases/CareUseCase/CareUseCaseRoutes";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { getUseCaseAsUrlParam, parseUseCaseStateFromUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import EhiCovidStatusReportWorklistPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidStatusReportWorklistPanel/EhiCovidStatusReportWorklistPanel";
import EhiCovidStatusReportId from "@Primitives/EhiCovidStatusReportId.g";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";


interface IEhiCovidStatusReportStandaloneHostDependencies {
    routingStore: GlobalRoutingStore;
}

interface IEhiCovidStatusReportStandaloneHostProps extends IRoutingFrameContentProps {
    _dependencies?: IEhiCovidStatusReportStandaloneHostDependencies;
    _careActivityId?: CareActivityId;
}

class EhiCovidStatusReportStandaloneHost extends React.Component<IEhiCovidStatusReportStandaloneHostProps> {

    @State.computed
    private get careActivityId() {
        let careActivityId: CareActivityId = null;

        const careActivityIdString = (this.props.routingController.currentRoute.parameters as IOutpatientTreatmentRouteParams).careActivityId;
        if (careActivityIdString) {
            careActivityId = new CareActivityId(careActivityIdString);
        } else if (this.props._careActivityId) {
            careActivityId = this.props._careActivityId;
        }

        return careActivityId;
    }

    @State.computed
    private get selectedEhiCovidStatusReportId(): EhiCovidStatusReportId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments[0].value;
        }

        if (this.useCaseState?.useCase.value === HunEHealthInfrastructureUseCases.ehiCovidStatusReportDetail) {
            return new EhiCovidStatusReportId("new");
        }

        return null;
    }

    @State.computed
    private get isReadOnly(): boolean {
        let isReadOnly = false;
        if (this.props._dependencies.routingStore.currentQueryString) {
            const params = new URLSearchParams(this.props._dependencies.routingStore.currentQueryString);
            isReadOnly = params.get("isReadOnly") === "false" ? false : true;
        }

        return isReadOnly;
    }

    @State.computed
    private get useCaseState() {
        const useCaseState = (this.props.routingController.currentRoute.parameters as ICareUseCaseRouteParams)?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState) as INDataUseCaseState;
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setUseCaseState(ehiCovidStatusReportId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.replace(
            standaloneRouteDefinitions.ehiCovidStatusReports.makeRoute({
                careActivityId: this.careActivityId.value,
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            }), "?isReadOnly=" + this.isReadOnly ? "true" : "false"
        );
    }

    @State.bound
    private navigateToEhiCovidStatusReport(ehiCovidStatusReportId: EhiCovidStatusReportId) {
        this.props.routingController.replace(
            standaloneRouteDefinitions.ehiCovidStatusReports.makeRoute({
                careActivityId: this.careActivityId.value,
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(HunEHealthInfrastructureUseCases.ehiCovidStatusReportDetail),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(ehiCovidStatusReportId, "ehiCovidStatusReportId")])
            }), "?isReadOnly=" + this.isReadOnly ? "true" : "false"
        );
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(
            standaloneRouteDefinitions.ehiCovidStatusReports.makeRoute({
                careActivityId: this.careActivityId.value,
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(HunEHealthInfrastructureUseCases.ehiCovidStatusReports),
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument("null", "ehiCovidStatusReportId")])
            }, { "isReadOnly": this.isReadOnly })
        );
    }

    public render() {
        return (
            <EhiCovidStatusReportWorklistPanel
                onChange={this.setUseCaseState}
                useCaseState={this.useCaseState}
                selectedRowId={this.selectedEhiCovidStatusReportId?.value}
                onBack={this.goBack}
                onCloseDetail={this.setClosedState}
                onSelectedEhiCovidStatusReportIdChange={this.navigateToEhiCovidStatusReport}
                _careActivityId={this.careActivityId}
                hideMasterToolbar={this.isReadOnly}
            />
        );
    }
}

export default connect(
    EhiCovidStatusReportStandaloneHost,
    new DependencyAdapter<IEhiCovidStatusReportStandaloneHostProps, IEhiCovidStatusReportStandaloneHostDependencies>(container => {
        return {
            routingStore: container.resolve("GlobalRoutingStore"),
        };
    }),
    new CareActivityContextAdapter<IEhiCovidStatusReportStandaloneHostProps>(c => ({
        _careActivityId: c.careActivityId
    }))
);