exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Dashboard_mode-button_3pR9R {\n  border: 0px;\n  background-color: transparent;\n  width: 38px;\n  margin-top: 4px;\n  color: white;\n  height: 100%;\n}\n.Dashboard_mode-button_3pR9R svg {\n  fill: white;\n  width: 24px;\n  height: 24px;\n  margin-top: 0;\n}\n.Dashboard_mode-button_3pR9R:hover {\n  border: 0px;\n  background-color: transparent;\n  width: 38px;\n  margin-top: 4px;\n  color: white;\n  height: 100%;\n}\n.Dashboard_mode-button_3pR9R:hover svg {\n  fill: white;\n  width: 24px;\n  height: 24px;\n  margin-top: 0;\n}\n.Dashboard_mode-button_3pR9R:hover svg {\n  fill: #eef1f7;\n}\n.Dashboard_sidebar-button_zA56H {\n  border: 0px;\n  background-color: #495468;\n  margin: 8px;\n  margin-left: 6px;\n  width: 35px;\n  color: white;\n  height: 100%;\n  max-height: 32px;\n}\n.Dashboard_sidebar-button_zA56H svg {\n  fill: white;\n  stroke: white;\n}\n.Dashboard_sidebar-button_zA56H:hover {\n  border: 0px;\n  background-color: #495468;\n  margin: 8px;\n  margin-left: 6px;\n  width: 35px;\n  color: white;\n  height: 100%;\n  max-height: 32px;\n}\n.Dashboard_sidebar-button_zA56H:hover svg {\n  fill: white;\n  stroke: white;\n}\n.Dashboard_dashboard-button_1MOEU {\n  cursor: pointer;\n  min-width: 10px;\n  border: 0px;\n  height: 22%;\n  background-color: #384252;\n  width: 4px;\n  margin: auto 5px;\n  border-radius: 10px;\n  opacity: 0.5;\n}\n.Dashboard_dashboard-button_1MOEU:hover {\n  border: 0px;\n  border-radius: 10px;\n  background-color: #505C71;\n  min-width: 15px;\n  transition: all 0.1s ease-in-out;\n}\n.Dashboard_hide_pR5oZ {\n  display: none;\n}\n.Dashboard_separator_1TjYk {\n  min-height: 2px;\n  margin: 10px auto;\n  width: 80%;\n  background-color: #ffffff6e;\n}\n", ""]);

// exports
exports.locals = {
	"mode-button": "Dashboard_mode-button_3pR9R",
	"modeButton": "Dashboard_mode-button_3pR9R",
	"sidebar-button": "Dashboard_sidebar-button_zA56H",
	"sidebarButton": "Dashboard_sidebar-button_zA56H",
	"dashboard-button": "Dashboard_dashboard-button_1MOEU",
	"dashboardButton": "Dashboard_dashboard-button_1MOEU",
	"hide": "Dashboard_hide_pR5oZ",
	"separator": "Dashboard_separator_1TjYk"
};