import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Log from "@Log";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import { isNullOrUndefined, arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { expandValidationPropertyPaths } from "@Toolkit/ReactClient/Components/ValidationBoundary/PropertyPathExpander";


export default class RootValidationBoundaryStore implements IValidationBoundaryStore {

    private readonly originalValidationProblemsMap: Map<string, IClientValidationProblem[]>;
    @State.observable.ref private _validationProblemsMap: Map<string, IClientValidationProblem[]> = null;
    @State.computed public get validationProblemsMap() {
        return this._validationProblemsMap ? this._validationProblemsMap : this.originalValidationProblemsMap;
    }

    private readonly originalCheckedRuleIdsMap: Map<string, string[]>;
    @State.observable.ref private _checkedRuleIdsMap: Map<string, string[]> = null;
    @State.computed private get checkedRuleIdsMap() {
        return this._checkedRuleIdsMap ? this._checkedRuleIdsMap : this.originalCheckedRuleIdsMap;
    }

    @State.observable public readonly dirtyFields: Set<string> = new Set();

    constructor(
        validationResults: IClientValidationResult[],
        public readonly mapEntityId: boolean,
        public readonly entityTypeName: string,
        public readonly entityId: string,
        public readonly pathPrefix: string,
        private readonly onValidateAsync: (dirtyFields: string[]) => Promise<IClientValidationResult[]>,
        private readonly _onChanged: (fullPropertyPath: string, value: any) => void,
        private readonly _isRequired: (fullPropertyPath: string) => boolean,
        public readonly validateAllEvent: TypedEvent,
        public readonly validateEvent: TypedEvent,
        private readonly problemFilterPredicate: (problem: IClientValidationProblem) => boolean,
        private readonly getProblemMessage?: (problem: IClientValidationProblem) => string
    ) {
        const mapped = this.mapValidationResults(validationResults, mapEntityId);
        this.originalValidationProblemsMap = mapped.validationProblemsMap;
        this.originalCheckedRuleIdsMap = mapped.checkedRuleIdsMap;
        this.validateEvent.on(() => this.validateField());
    }

    @State.action.bound
    public clearDirtyFields(): void {
        this.dirtyFields.clear();
    }

    private mapValidationResults(validationResults: IClientValidationResult[], mapEntityId: boolean) {
        const validationProblemsMap = new Map<string, IClientValidationProblem[]>();
        const checkedRuleIdsMap = new Map<string, string[]>();

        if (!!validationResults) {
            validationResults.forEach(result => {
                const mappedEntityId = mapEntityId ? `(${result.entityId})` : "";

                if (result.problems) {
                    result.problems.forEach(problem => {
                        const fullyQualifiedPropertyPath = `${result.entityName}${mappedEntityId}.${problem.propertyPath}`;
                        const existing = validationProblemsMap.get(fullyQualifiedPropertyPath);
                        if (existing) {
                            existing.push(problem);
                        } else {
                            validationProblemsMap.set(fullyQualifiedPropertyPath, [problem]);
                        }
                    });
                }

                if (result.checkedRules) {
                    result.checkedRules.forEach(checkedRule => {
                        const fullyQualifiedPropertyPath = `${result.entityName}${mappedEntityId}.${checkedRule.propertyPath}`;

                        const existing = checkedRuleIdsMap.get(fullyQualifiedPropertyPath);

                        if (existing) {
                            existing.push(checkedRule.ruleId);
                        } else {
                            checkedRuleIdsMap.set(fullyQualifiedPropertyPath, [checkedRule.ruleId]);
                        }
                    });
                }
            });
        }

        return { validationProblemsMap, checkedRuleIdsMap };
    }

    public getValidationProblems(propertyPath: string, isFullPath?: boolean, ignoreDirtyCheck?: boolean): IClientValidationProblem[] {
        if (isNullOrUndefined(propertyPath)) {
            return [];
        }
        const fullPropertyPaths = this.getFullyQualifiedPropertyPaths(propertyPath, isFullPath);

        const expandedPropertyPaths = _.flatten(fullPropertyPaths.map(p => expandValidationPropertyPaths(p)));

        const regexPaths = expandedPropertyPaths.filter(p => p.endsWith("*"));
        const fullPaths = expandedPropertyPaths.filter(p => !regexPaths.includes(p));

        const problems: IClientValidationProblem[] = [];

        regexPaths.forEach(r => {
            problems.push(...this.getValidationProblemsByRegex(new RegExp(`^${r.substr(0, r.length - 2)}`), ignoreDirtyCheck));
        });

        problems.push(...this.getFilteredValidationProblems(fullPaths, ignoreDirtyCheck));

        return this.transformProblemMessages(problems);
    }

    public getValidationProblemsByRegex(regex: RegExp, ignoreDirtyCheck?: boolean): IClientValidationProblem[] {
        const fullPropertyPaths = Array.from(this.validationProblemsMap.keys())
            .filter(path => regex.test(path));

        return this.transformProblemMessages(this.getFilteredValidationProblems(fullPropertyPaths, ignoreDirtyCheck));
    }

    public getAllValidationProblems(ignoreDirtyCheck?: boolean): IClientValidationProblem[] {
        const fullPropertyPaths = Array.from(this.validationProblemsMap.keys());

        return this.transformProblemMessages(this.getFilteredValidationProblems(fullPropertyPaths, ignoreDirtyCheck));
    }

    private transformProblemMessages(problems: IClientValidationProblem[]): IClientValidationProblem[] {
        if (this.getProblemMessage) {
            return problems.map(p => ({ ...p, rawMessage: this.getProblemMessage(p) }));
        }

        return problems;
    }

    private getFilteredValidationProblems(fullPropertyPaths: string[], ignoreDirtyCheck?: boolean) {
        const allProblems: IClientValidationProblem[] = [];

        if (!!ignoreDirtyCheck) {
            fullPropertyPaths.forEach(fullPropertyPath => {
                const problems =
                    this.validationProblemsMap.get(fullPropertyPath);
                if (!arrayIsNullOrEmpty(problems)) {
                    allProblems.push(...problems);
                }
            });
        } else {
            fullPropertyPaths.forEach(fullPropertyPath => {
                const problems = this.dirtyFields.has(fullPropertyPath) ?
                    this.validationProblemsMap.get(fullPropertyPath) :
                    this.originalValidationProblemsMap.get(fullPropertyPath);
                if (!arrayIsNullOrEmpty(problems)) {
                    allProblems.push(...problems);
                }
            });
        }
        return allProblems.filter(x => !!x && (!this.problemFilterPredicate || this.problemFilterPredicate(x)));
    }

    public isRequired(propertyPath: string, isFullPath?: boolean): boolean {
        if (isNullOrUndefined(propertyPath)) {
            return false;
        }
        const fullPropertyPaths = this.getFullyQualifiedPropertyPaths(propertyPath, isFullPath);

        const expandedPropertyPaths = _.flatten(fullPropertyPaths.map(p => expandValidationPropertyPaths(p)));

        for (const expandedPropertyPath of expandedPropertyPaths) {
            if (this._isRequired) {
                if (this._isRequired(expandedPropertyPath)) {
                    return true;
                }
            }

            const checkedRuleIds = this.checkedRuleIdsMap.get(expandedPropertyPath);
            const isRequired = checkedRuleIds?.some(ruleId => ruleId.includes("ShouldBeFilled")) ?? false;
            if (isRequired) {
                return true;
            }
        }

        return false;
    }

    @State.action
    public removeItemFromList(propertyPath: string, followingItemPropertyPaths: string[], isFullPath?: boolean): void {
        if (isNullOrUndefined(propertyPath)) {
            return;
        }

        const fullPropertyPath = isFullPath ? propertyPath : this.getFullyQualifiedPropertyPath(propertyPath);
        const followingItemFullPropertyPaths = isFullPath ? followingItemPropertyPaths : followingItemPropertyPaths.map(this.getFullyQualifiedPropertyPath);

        if (arrayIsNullOrEmpty(followingItemFullPropertyPaths)) {
            // Last item removed
            this.moveItem(fullPropertyPath, null, true);
        } else {
            let previousItem: string = fullPropertyPath;
            const lastItem = followingItemFullPropertyPaths[followingItemFullPropertyPaths.length - 1];
            for (const currentItem of followingItemFullPropertyPaths) {
                this.moveItem(currentItem, previousItem, lastItem === currentItem);
                previousItem = currentItem;
            }
        }
    }

    private moveItem(source: string, destination: string, removeSource: boolean) {
        this.moveMapItems(this.originalValidationProblemsMap, source, destination, removeSource);
        this.moveMapItems(this._validationProblemsMap, source, destination, removeSource);
        this.moveMapItems(this._checkedRuleIdsMap, source, destination, removeSource);
        this.moveSetItems(this.dirtyFields, source, destination, removeSource);
    }

    private moveSetItems(set: Set<string>, startOfSourceKey: string, startOfDestinationKey: string, removeSource: boolean) {
        if (!set) {
            return;
        }

        const allKeys = Array.from(set.keys());
        const sourceKeys = this.getArrayItemsStartsWith(allKeys, startOfSourceKey);
        const destinationKeys = startOfDestinationKey == null ? null : this.getArrayItemsStartsWith(allKeys, startOfDestinationKey);

        if (destinationKeys !== null) {
            destinationKeys.forEach(key => set.delete(key));
        }

        sourceKeys.forEach(key => {

            if (startOfDestinationKey !== null) {
                const newDestinationKey = `${startOfDestinationKey}${key.substring(startOfSourceKey.length)}`;
                set.add(newDestinationKey);
            }

            if (removeSource) {
                set.delete(key);
            }
        });
    }

    private moveMapItems(map: Map<string, any>, startOfSourceKey: string, startOfDestinationKey: string, removeSource: boolean) {
        if (!map) {
            return;
        }

        const allKeys = Array.from(map.keys());
        const sourceKeys = this.getArrayItemsStartsWith(allKeys, startOfSourceKey);
        const destinationKeys = this.getArrayItemsStartsWith(allKeys, startOfDestinationKey);

        destinationKeys.forEach(key => map.delete(key));

        sourceKeys.forEach(key => {

            const newDestinationKey = `${startOfDestinationKey}${key.substring(startOfSourceKey.length)}`;
            map[newDestinationKey] = map[key];

            if (removeSource) {
                map.delete(key);
            }
        });
    }

    private getArrayItemsStartsWith(items: string[], startOfKey: string) {
        return items.filter(k => k.startsWith(startOfKey));
    }

    private async validateFieldAsync(): Promise<void> {

        if (!this.onValidateAsync) {
            return;
        }

        try {
            const results = await this.onValidateAsync(Array.from(this.dirtyFields.values()));
            State.runInAction(() => {
                const mapped = this.mapValidationResults(results, this.mapEntityId);

                this._validationProblemsMap = mapped.validationProblemsMap;
                this._checkedRuleIdsMap = mapped.checkedRuleIdsMap;
            });
        } catch (err) {
            Log.warn(err, "Automatic form validation failed.");
        }
    }

    public validateFieldDebounced = _.debounce(() => {
        this.validateField();
    }, 500);

    public validateField() {
        this.validateFieldAsync().catch(() => {/* hide all errors */ });
    }

    @State.action.bound
    public changed(propertyPath?: string, value?: any, isFullPath?: boolean) {

        if (!isNullOrUndefined(propertyPath)) {
            const fullPropertyPaths = this.getFullyQualifiedPropertyPaths(propertyPath, isFullPath);

            const expandedPropertyPaths = _.flatten(fullPropertyPaths.map(p => expandValidationPropertyPaths(p)));

            expandedPropertyPaths.forEach(expandedPropertyPath => {
                this.dirtyFields.add(expandedPropertyPath);
                this._validationProblemsMap?.delete(expandedPropertyPath);

                if (this._onChanged) {
                    this._onChanged(expandedPropertyPath, value);
                }
            });
        }

        this.validateFieldDebounced();
    }

    @State.action.bound
    public setDirty(propertyPath?: string, isFullPath?: boolean) {
        const fullPropertyPaths = this.getFullyQualifiedPropertyPaths(propertyPath, isFullPath);
        const expandedPropertyPaths = _.flatten(fullPropertyPaths.map(p => expandValidationPropertyPaths(p)));

        expandedPropertyPaths.forEach(expandedPropertyPath => {
            this.dirtyFields.add(expandedPropertyPath);
            this._validationProblemsMap?.delete(expandedPropertyPath);
        });
    }

    @State.bound
    private getFullyQualifiedPropertyPaths(propertyPath: string, isFullPath: boolean) {
        const paths = propertyPath.split(";");

        return !!isFullPath ? paths : paths.map(p => this.getFullyQualifiedPropertyPath(p));
    }

    @State.bound
    private getFullyQualifiedPropertyPath(propertyPath: string) {
        const mappedEntityId = this.entityId ? `(${this.entityId})` : "";
        const pathPrefix = this.pathPrefix ? `.${this.pathPrefix}.` : ".";
        const lastPart = propertyPath === "." ? "" : propertyPath;
        return `${this.entityTypeName}${mappedEntityId}${pathPrefix}${lastPart}`;
    }
}
