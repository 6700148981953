import {IModalParams} from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import AppointmentScheduleDefinition from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentScheduleDefinition";

export interface IAppointmentScheduleDefinitionBaseDataModalResult  {
    result: AppointmentScheduleDefinition;
}


export interface IAppointmentScheduleDefinitionBaseDataModalParams extends IModalParams {
}

export default class AppointmentScheduleDefinitionBaseDataModalParams implements IAppointmentScheduleDefinitionBaseDataModalParams {
    public static type = "AppointmentScheduleDefinitionBaseDataModal";
    public get type() { return AppointmentScheduleDefinitionBaseDataModalParams.type; }
}
