import React, { Fragment, LegacyRef, useCallback } from "react";
import EditableLayoutRowBlockStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableLayoutRowBlockStore";
import EditableLayoutRowColumn from "./EditableLayoutRowColumn";
import Styles from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditor.less";
import ColumnDropZone from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/ColumnDropZone";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import IEditableLayoutStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/IEditableLayoutStore";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import Icon from "@CommonControls/Icon";

interface IEditableLayoutRowBlockProps {
    block: EditableLayoutRowBlockStore;
    parentCollection: IObservableArray<IEditableLayoutStore>;
    opacity: number;
}

function EditableLayoutRowBlock(props: IEditableLayoutRowBlockProps, ref: LegacyRef<HTMLDivElement>) {

    const store = useFormLayoutEditorStore();
    const removeRow = useCallback(() => {
        store.removeBlock(props.parentCollection, props.block);
    }, [props.parentCollection, props.block]);

    return (
        <div ref={ref} className={Styles.elementRow} style={{ opacity: props.opacity }}>
            <ColumnDropZone collection={props.block.content} indexToInsert={0} isFirst={props.block.content.length === 0} />
            <div className={Styles.columnContainer}>
                {props.block.content.map((c, idx) => (
                    <Fragment key={c.id}>
                        <EditableLayoutRowColumn column={c} parentCollection={props.block.content} />
                        <ColumnDropZone collection={props.block.content} indexToInsert={idx + 1} />
                    </Fragment>
                ))}
            </div>
            <div className={Styles.toolbar} style={{ visibility: store.areDropZonesHighlighted ? "hidden" : "visible" }}>
                <button onClick={removeRow}>x</button>
                <Icon iconName="bars" />
            </div>
        </div>
    );
}


const _EditableLayoutRowBlock = React.forwardRef(EditableLayoutRowBlock);
export default State.observer(_EditableLayoutRowBlock);