import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import { getNavigationMenuItems } from "@HunEHealthInfrastructurePlugin/Packages/Settings/Configuration/NavigationMenuItems";
import GlobalActionRegistry from "@Toolkit/ReactClient/ActionProcessing/GlobalActionRegistry";

class SettingsPackageInitializer implements IPackageInitializer {

    public configureActionProcessor(registry: GlobalActionRegistry) {

    }

    public configureDynamicNavigation(registry: IDynamicNavigationRegistry): void {
        getNavigationMenuItems().forEach(navigationItem => registry.register(navigationItem));
    }
}

export default new SettingsPackageInitializer();