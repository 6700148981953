import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ShowNeakPerformanceStatementsScreenAction from "@HunSocialSecurityPlugin/Packages/PerformanceStatement/FrontendActions/ShowNeakPerformanceStatementsScreenAction.g";
import ShowPerformanceStatementCareActivitiesScreenAction from "@HunSocialSecurityPlugin/Packages/PerformanceStatement/FrontendActions/ShowPerformanceStatementCareActivitiesScreenAction.g";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import NavMenuActionItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuActionItem";
import NavMenuGroupItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuGroupItem";

export function getNavigationMenuItems(): INavMenuItem[] {
    const leftTopLevelMenuItems: INavMenuItem[] = [
        NavMenuGroupItem.createTopLevel(
            "PerformanceStatement",
            {
                automationId: "performanceStatementMain",
                side: "left",
                order: 5,
                iconNameType: "list_ol"
            }
        ),

    ];

    const rightTopLevelMenuItems: INavMenuItem[] = [
    ];

    const childMenuItems: INavMenuItem[] = [
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "PerformanceStatementCareActivities",
            "PerformanceStatement",
            new ShowPerformanceStatementCareActivitiesScreenAction(),
            "ViewPerformanceStatementCareActivity",
            {
                automationId: "performanceStatementCareActivity",
                text: StaticWebAppResources.Navbar.PerformanceStatement.PerformanceStatementCareActivity,
                order: 1
            }
        ),
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "NeakPerformanceStatements",
            "PerformanceStatement",
            new ShowNeakPerformanceStatementsScreenAction(),
            "ViewPerformanceStatements",
            {
                automationId: "neakPerformanceStatement",
                text: StaticWebAppResources.Navbar.PerformanceStatement.NeakPerformanceStatement,
                order: 2
            }
        )
    ];

    return [...leftTopLevelMenuItems, ...rightTopLevelMenuItems, ...childMenuItems];
}