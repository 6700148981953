import EhiCovidStatusReportDetailPanel, { IEhiCovidStatusReportDetailPanelProps } from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidStatusReportWorklistPanel/EhiCovidStatusReportDetailPanel";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import EhiCovidStatusReportId from "@Primitives/EhiCovidStatusReportId.g";
import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import standaloneRouteDefinitions from "@HunEHealthInfrastructurePlugin/UseCases/CareUseCase/CareUseCaseRoutes";
import EhiCovidStatusReportStandaloneHost from "@HunEHealthInfrastructurePlugin/UseCases/CareUseCase/EhiCovidStatusReport/EhiCovidStatusReportStandaloneHost";

export default function configureEhiCovidStatusReportUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add(UseCaseDescriptorBuilder.create(
        HunEHealthInfrastructureUseCases.ehiCovidStatusReports,
        builder => {
            builder.standalone(b => b
                .routeDefinition(standaloneRouteDefinitions.ehiCovidStatusReports)
                .component(EhiCovidStatusReportStandaloneHost));
        }, false
    ));

    useCaseRegistry.add(UseCaseDescriptorBuilder.create(
        HunEHealthInfrastructureUseCases.readOnlyEhiCovidStatusReports,
        builder => {
            builder.standalone(b => b
                .routeDefinition(standaloneRouteDefinitions.ehiCovidStatusReports).additionalQueryParams({ isReadOnly: true })
                .component(EhiCovidStatusReportStandaloneHost));
        }, false
    ));

    useCaseRegistry.add2<IEhiCovidStatusReportDetailPanelProps>(
        {
            identifiers: [HunEHealthInfrastructureUseCases.ehiCovidStatusReportDetail],
            component: EhiCovidStatusReportDetailPanel,
            mapUseCaseArgumentsToProps: args => {
                const map = new Map(args.map(a => [a.name, a.value]));
                const ehiCovidStatusReportId = map.get("ehiCovidStatusReportId") ?? new EhiCovidStatusReportId("new");
                return {
                    ehiCovidStatusReportId: ehiCovidStatusReportId
                };
            }
        }
    );

    useCaseRegistry.add2<IEhiCovidStatusReportDetailPanelProps>(
        {
            identifiers: [HunEHealthInfrastructureUseCases.readOnlyEhiCovidStatusReportDetail],
            component: EhiCovidStatusReportDetailPanel,
            mapUseCaseArgumentsToProps: args => {
                const map = new Map(args.map(a => [a.name, a.value]));
                const ehiCovidStatusReportId = map.get("ehiCovidStatusReportId") ?? new EhiCovidStatusReportId("new");
                return {
                    ehiCovidStatusReportId: ehiCovidStatusReportId,
                    readOnly: true
                };
            }
        }
    );
}
