import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class NestedValidationBoundaryStore implements IValidationBoundaryStore {

    constructor(
        private readonly parentStore: IValidationBoundaryStore,
        public readonly entityTypeName: string,
        public readonly entityId: string,
        public readonly pathPrefix: string,
        private readonly problemFilterPredicate?: (problem: IClientValidationProblem) => boolean,
        private readonly getProblemMessage?: (problem: IClientValidationProblem) => string
    ) { }

    public get validateAllEvent() { return this.parentStore.validateAllEvent; }
    public get validateEvent() { return this.parentStore.validateEvent; }
    public get mapEntityId() { return this.parentStore.mapEntityId;  }

    public getValidationProblems(propertyPath: string, isFullPath?: boolean, ignoreDirtyCheck?: boolean): IClientValidationProblem[] {
        const fullPropertyPath = !isFullPath ? this.getFullyQualifiedPropertyPath(propertyPath) : propertyPath;
        const problems = this.parentStore.getValidationProblems(fullPropertyPath, true, ignoreDirtyCheck);
        const filteredProblems = this.filterProblems(problems);
        return this.transformProblemMessages(filteredProblems);
    }

    public isRequired(propertyPath: string, isFullPath?: boolean): boolean {
        const fullPropertyPath = !isFullPath ? this.getFullyQualifiedPropertyPath(propertyPath) : propertyPath;
        return this.parentStore.isRequired(fullPropertyPath, true);
    }

    public changed(propertyPath?: string, value?: any, isFullPath?: boolean) {
        const fullPropertyPath = !isFullPath ? this.getFullyQualifiedPropertyPath(propertyPath) : propertyPath;
        this.parentStore.changed(fullPropertyPath, value, true);
    }

    public setDirty(propertyPath?: string, isFullPath?: boolean) {
        const fullPropertyPath = !isFullPath ? this.getFullyQualifiedPropertyPath(propertyPath) : propertyPath;
        this.parentStore.setDirty(fullPropertyPath, true);
    }

    public getValidationProblemsByRegex(regex: RegExp, ignoreDirtyCheck?: boolean): IClientValidationProblem[] {
        const problems = this.parentStore.getValidationProblemsByRegex(regex, ignoreDirtyCheck);
        return this.transformProblemMessages(this.filterProblems(problems));
    }

    public getAllValidationProblems(ignoreDirtyCheck?: boolean): IClientValidationProblem[] {
        const problems = this.parentStore.getAllValidationProblems(ignoreDirtyCheck);
        return this.transformProblemMessages(this.filterProblems(problems));
    }

    public removeItemFromList(propertyPath: string, followingItemPropertyPaths: string[], isFullPath?: boolean): void {
        if (!isFullPath) {
            this.parentStore.removeItemFromList(this.getFullyQualifiedPropertyPath(propertyPath), followingItemPropertyPaths.map(this.getFullyQualifiedPropertyPath), true);
        } else {
            this.parentStore.removeItemFromList(propertyPath, followingItemPropertyPaths, true);
        }
    }

    private transformProblemMessages(problems: IClientValidationProblem[]): IClientValidationProblem[] {
        if (this.getProblemMessage) {
            return problems.map(p => ({ ...p, rawMessage: this.getProblemMessage(p) }));
        }

        return problems;
    }

    private filterProblems = State.computedFunction((problems: IClientValidationProblem[]) => {
        if (!!this.problemFilterPredicate && problems) {
            return problems.filter(this.problemFilterPredicate);
        }
        return problems;
    });

    @State.bound
    private getFullyQualifiedPropertyPath(propertyPath?: string) {
        if (!propertyPath) {
            return null;
        }

        const mappedEntityId = (this.mapEntityId && this.entityId) ? `(${this.entityId})` : "";
        const pathPrefix = this.pathPrefix ? `.${this.pathPrefix}.` : ".";

        const paths = propertyPath.split(";");

        const fullPaths = paths.map(p => {
            const lastPart = propertyPath === "." ? "" : propertyPath;
            return `${this.entityTypeName}${mappedEntityId}${pathPrefix}${lastPart}`;
        });

        return fullPaths.join(";");
    }

    @State.bound
    public clearDirtyFields() {
        this.parentStore.clearDirtyFields();
    }
}
