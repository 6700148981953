import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React, {  } from "react";
import * as Ui from "@CommonControls";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import PatientDocumentTypeSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/PatientRegister/PatientDocumentTypeSelectBox";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import { IPatientIdentifierDependencies, IPatientIdentifierProps } from "@HisPlatform/Packages/Patients/Components/PatientIdentifier/PatientIdentifier";

export interface IPatientHunSocialSecurityIdentifierDependencies extends IPatientIdentifierDependencies {
}

export interface IPatientHunSocialSecurityIdentifierProps extends IPatientIdentifierProps {
}

function PatientHunSocialSecurityIdentifier(props: IPatientHunSocialSecurityIdentifierProps) {
    return (
        <>
            <Ui.Flex>
                <Ui.Flex.Item xs={3}>
                    <Ui.TextBox
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.Value}
                        value={props.identifierValue}
                        onChange={props.onIdentifierValueChange}
                        propertyIdentifier="IdentifierValue"
                        automationId="DocumentNumber"
                        mask="111 111 111"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={3}>
                    <PatientDocumentTypeSelectBox
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.DocumentType}
                        value={props.identifierTypeId}
                        onChange={props.onIdentifierTypeIdChange}
                        propertyIdentifier="DocumentTypeId"
                        automationId="DocumentTypeId"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </>
    );
}

export default connect<IPatientHunSocialSecurityIdentifierProps>(
    PatientHunSocialSecurityIdentifier,
    new DependencyAdapter<IPatientHunSocialSecurityIdentifierProps, IPatientHunSocialSecurityIdentifierDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore")
        };
    }),
    new HisModalServiceAdapter());