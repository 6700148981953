/* auto-inject-disable */
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import Di from "@Di";
import { mapFormFieldDataToProxy, restoreCustomFormDataStoreAsync } from "@HisPlatform/BoundedContexts/FormEngine/ApplicationLogic/ApiAdapter/FormEngineMappers";
import PatientId from "@Primitives/PatientId.g";
import IReferenceDataLoader from "@HisPlatform/Services//Definition/ReferenceDataLoader/IReferenceDataLoader";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import Form from "@Toolkit/FormEngine/Model/Form";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import { IFormDataStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormDataStore";
import PatientDocumentTypeId from "@Primitives/PatientDocumentTypeId.g";

@Di.injectable()
export default class PatientAdministrativeDataScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("IPatientsClient") private readonly apiClient: Proxy.IPatientsClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("IFormEngineReferenceDataStore") private formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("ValidationProblemParameterMapperService") private validationProblemParameterMapperService: ValidationProblemParameterMapperService
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public getNewPatientAdministrativeScreenDataAsync(appointmentScheduleEntryId?: AppointmentScheduleEntryId) {
        return this.executeOperationAsync<IForm, Proxy.GetNewPatientAdministrativeScreenDataQueryResponse>(
            () => this.apiClient.getNewPatientAdministrativeScreenDataQueryAsync(CreateRequestId(), new Proxy.GetNewPatientAdministrativeScreenDataControllerDto({
                appointmentScheduleEntryId: appointmentScheduleEntryId
            })),
            async response => {
                const form = await this.mapToFormAsync(this.formEngineReferenceDataStore, response.data, response.initialFormDefinitionId, [] as any, response.formLogic);
                return form;
            }
        );
    }

    @State.bound
    public getPatientAdministrativeScreenDataAsync(patientId: PatientId, requestLock: boolean) {
        return this.executeOperationAsync<IForm, Proxy.GetPatientAdministrativeScreenDataCommandResponse>(
            () => this.apiClient.getPatientAdministrativeScreenDataCommandAsync(CreateRequestId(), new Proxy.GetPatientAdministrativeScreenDataControllerDto({
                patientId: patientId,
                requestLock: requestLock
            })),
            async response => {
                const rowVersions: Map<string, RowVersion> = new Map<string, RowVersion>();
                rowVersions["PatientAdministrativeData"] = response.rowVersions;
                const form = await this.mapToFormAsync(this.formEngineReferenceDataStore, response.data, response.initialFormDefinitionId, response.compositeValidationResult, response.formLogic, response.rowVersions);
                return form;
            }
        );
    }

    @State.bound
    public createPatientAdministrativeDataAsync(form: IForm, isValidateOnly: boolean = false) {
        return this.executeOperationAsync<IForm, Proxy.CreatePatientAdministrativeDataCommandResponse>(
            () => {
                const mappedFormFieldData: Proxy.FormFieldData[] = [];

                mapFormFieldDataToProxy(form.data.Content, mappedFormFieldData as any);

                return this.apiClient.createPatientAdministrativeDataCommandAsync(CreateRequestId(), new Proxy.CreatePatientAdministrativeDataControllerDto({
                    data: mappedFormFieldData,
                    isValidateOnly: isValidateOnly,
                }));
            },
            async response => {
                const rowVersions: { [key: string]: RowVersion } = {};
                rowVersions["PatientAdministrativeData"] = response.patientAdministrativeData?.rowVersion;

                const resultForm =
                    await this.mapToFormAsync(this.formEngineReferenceDataStore, response.patientAdministrativeData?.data, form.definitionId, response.compositeValidationResult, form.formLogic, rowVersions, form.data);
                return resultForm;
            }
        );
    }

    @State.bound
    public updatePatientAdministrativeDataAsync(form: IForm, releaseLockIfSuccessful: boolean, lockInfo: LockInfo, isValidateOnly: boolean = false) {
        return this.executeOperationAsync<IForm, Proxy.UpdatePatientAdministrativeDataCommandResponse>(
            () => {
                const mappedFormFieldData: Proxy.FormFieldData[] = [];
                mapFormFieldDataToProxy(form.data.Content, mappedFormFieldData as any);

                return this.apiClient.updatePatientAdministrativeDataCommandAsync(CreateRequestId(), new Proxy.UpdatePatientAdministrativeDataControllerDto({
                    data: mappedFormFieldData,
                    rowVersion: form.rowVersions.get("PatientAdministrativeData"),
                    releaseLockIfSuccessful: releaseLockIfSuccessful,
                    lockId: lockInfo.lockId,
                    isValidateOnly: isValidateOnly
                }));
            },
            async response => {
                const rowVersions: { [key: string]: RowVersion } = {};
                rowVersions["PatientAdministrativeData"] = response.patientAdministrativeData?.rowVersion;

                const resultForm =
                    await this.mapToFormAsync(this.formEngineReferenceDataStore, response.patientAdministrativeData?.data, form.definitionId, response.compositeValidationResult, form.formLogic, rowVersions, form.data);
                return resultForm;
            }
        );
    }

    @State.bound
    public getPatientForNonUniqueDocument(identifierValue: string, documentTypeId: PatientDocumentTypeId, patientId: PatientId) {
        return this.executeOperationAsync(
            () => {
                return this.apiClient.getPatientForNonUniqueDocumentQueryAsync(CreateRequestId(), new Proxy.GetPatientForNonUniqueDocumentControllerDto({
                    identifierValue: identifierValue,
                    documentTypeId: documentTypeId,
                    patientId: patientId
                }));
            },
            response => {
                return {
                    patientId: response.patientId,
                    patientName: response.patientName,
                    birthDate: response.birthDate,
                    documentTypeIdentifierSystemId: response.documentTypeIdentifierSystemId
                };
            }
        );
    }

    @State.bound
    public combinePatientsAsync(patientToBeKeptId: PatientId, patientToBeDeletedId: PatientId) {
        return this.executeOperationAsync<any, Proxy.CombinePatientsCommandResponse>(
            () => this.apiClient.combinePatientsCommandAsync(CreateRequestId(), new Proxy.CombinePatientsControllerDto({
                patientToBeKept: patientToBeKeptId,
                patientToBeDeleted: patientToBeDeletedId
            })),
            response => {
                return;
            }
        );
    }

    private async mapToFormAsync(formEngineReferenceDataStore: IFormEngineReferenceDataStore, data: Proxy.FormFieldData[], definitionId: FormDefinitionId, validationResults: Proxy.CompositeValidationResult, formLogic: string, rowVersions: { [key: string]: RowVersion } = null, existingData: IFormDataStore = null) {
        const definition = await formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(definitionId);

        const mappedValidationResults = mapValidationResults(validationResults as unknown as IServerCompositeValidationResult);

        mappedValidationResults.forEach(i => {
            i.problems.forEach((j: IClientValidationProblem) => {
                this.validationProblemParameterMapperService.resolveValidationProblemParameters("PatientAdministrativeDataScreenApiAdapter", j);
            });
        });

        const form = new Form(
            FormInstanceId.new,
            RowVersion.initial,
            definitionId,
            data ? await restoreCustomFormDataStoreAsync(data as any, definition, formEngineReferenceDataStore) : existingData,
            mappedValidationResults,
            null,
            null,
            formLogic);

        if (!isNullOrUndefined(rowVersions)) {
            form.rowVersions = new Map(Object.entries(rowVersions));
        }

        return form;
    }
}