import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import PatientId from "@Primitives/PatientId.g";
import { DataGrid, Button, Message, Flex } from "@CommonControls";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import EhiOrganizationUnitProviderService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Organization/Services/Implementation/EhiOrganizationUnitProviderService";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import ICovidTestResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/PatientRegister/Model/ICovidTestResult";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import IEhiResult from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiResult";
import IEhiError from "@HunEHealthInfrastructurePlugin/Common/Model/IEhiError";
import EhiErrorSummary from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiErrorSummary";
import EhiCovidTestTypeColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiCovidTestTypeColumn/EhiCovidTestTypeColumn";
import { IRowIndicatorStyle } from "@CommonControls/DataGrid/IDataGridProps";
import EhiConditionCovidStatus from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiConditionCovidStatus";
import EhiConditionCovidStatusColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiConditionCovidStatusColumn/EhiConditionCovidStatusColumn";
import * as Styles from "./EhiCovidTestResultsPanel.less";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import EhiCareServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareServiceFactory";

interface IEhiCovidTestResultsPanelDependencies {
    ehiCareServiceFactory: EhiCareServiceFactory;
    ehiOrganizationUnitProviderService: EhiOrganizationUnitProviderService;
    userContext: UserContext;
}

interface IEhiCovidTestResultsPanelProps {
    _dependencies?: IEhiCovidTestResultsPanelDependencies;
    _patientId?: PatientId;
    _modalService?: IModalService;
}

@State.observer
class EhiCovidTestResultsPanel extends React.Component<IEhiCovidTestResultsPanelProps> {

    private get ehiCareServiceFactory() { return this.props._dependencies!.ehiCareServiceFactory; }

    @State.observable.ref private isInitialEmptyState = true;
    @State.observable.ref private isLoading = false;
    @State.observable.ref private testResults: ICovidTestResult[] = [];
    @State.observable.ref private ehiErrors: IEhiError[] = [];
    private isNullResult = false;
    private readonly ehiCareService = this.ehiCareServiceFactory.getEhiCareService();

    @State.computed private get emptyStateSettings() {
        return !this.isInitialEmptyState && this.isNullResult
            ? {
                title: StaticHunEHealthInfrastructureCareResources.EhiCovidTests.CannotQueryData,
                message: StaticHunEHealthInfrastructureCareResources.EhiCovidTests.PressLoadButtonToQueryDataAndSelectOrganizationUnit,
                content: (
                    <>
                        <Button automationId="reloadList" text={StaticHunEHealthInfrastructureCareResources.EhiCovidTests.Load} visualStyle="primary" onClickAsync={this.loadPanelAsync} />
                    </>
                )
            }
            : undefined;
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadPanelAsync);

    @State.boundLoadingState()
    private async loadPanelAsync() {
        const result = await this.ehiCareService.getCovidTestResultsAsync(this.props._patientId!);
        this.setLoadedState(result?.result?.value ?? null);
    }

    @State.action
    private setLoadedState(testResults: IEhiResult<ICovidTestResult[]> | null) {
        this.isNullResult = testResults === null;
        this.testResults = !testResults ? [] : testResults.value;
        this.ehiErrors = !testResults ? [] : testResults.errors;
        this.isInitialEmptyState = false;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IEhiCovidTestResultsPanelProps) {
        if (this.props._patientId !== prevProps._patientId) {
            dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        }
    }

    public render() {

        return (
            <UseCaseFrame title={StaticHunEHealthInfrastructureCareResources.EhiCovidTests.Title} fallbackFrameType="PageBox" iconName="covid">
                <DataGrid
                    fixedHeight="100%"
                    fixedLayout
                    isLoading={this.isLoading}
                    dataSource={this.testResults}
                    extraFilter={(
                        <Flex outerSpacing="regular">
                            <Flex.Item xs={12}>
                                {StaticHunEHealthInfrastructureCareResources.EhiCovidTests.Information && <Message type="info">{StaticHunEHealthInfrastructureCareResources.EhiCovidTests.Information}</Message>}
                                <EhiErrorSummary errors={this.ehiErrors} />
                            </Flex.Item>
                        </Flex>
                    )}
                    isExtraFilterVisible
                    emptyStateSettings={this.emptyStateSettings}
                    getRowIndicatorStyle={this.getRowIndicatorStyle}
                    automationId="ehiCovidTestResultsPanel_dataGrid"
                >
                    <EhiCovidTestTypeColumn header={StaticHunEHealthInfrastructureCareResources.EhiCovidTests.TestType} width="40%" dataGetter={"testType"} />
                    <DataGridDateTimeColumn header={StaticHunEHealthInfrastructureCareResources.EhiCovidTests.ExecutedAt} width="30%" dataGetter={"executedAt"} />
                    <EhiConditionCovidStatusColumn header={StaticHunEHealthInfrastructureCareResources.EhiCovidTests.TestResult} width="30%" dataGetter={"covidStatus"} />
                </DataGrid>
            </UseCaseFrame>
        );
    }

    private getRowIndicatorStyle(row: ICovidTestResult): IRowIndicatorStyle {
        switch (row.covidStatus) {
            case EhiConditionCovidStatus.Positive:
                return { className: Styles.positiveTestResult };
            case EhiConditionCovidStatus.Negative:
                return { className: Styles.negativeTestResult };
        }
        return null;
    }
}

export default connect(
    EhiCovidTestResultsPanel,
    new DependencyAdapter<IEhiCovidTestResultsPanelProps, IEhiCovidTestResultsPanelDependencies>(c => ({
        ehiCareServiceFactory: c.resolve("EhiCareServiceFactory"),
        ehiOrganizationUnitProviderService: c.resolve("EhiOrganizationUnitProviderService"),
        userContext: c.resolve("UserContext"),
    })),
    new PatientContextAdapter(p => ({
        _patientId: p.patientId
    })),
    new HisModalServiceAdapter()
);