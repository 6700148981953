import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import CareActivityId from "@Primitives/CareActivityId.g";
import DiagnosisListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisListStore";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import DiagnosisListStoreMapper from "./DiagnosisListStoreMapper";
import Di from "@Di";
import { getUpdateDiagnosisListDto } from "./DiagnosisListDtoMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import ConditionId from "@Primitives/ConditionId.g";
import moment from "moment";
import DiagnosesForEpisodeOfCareStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosesForEpisodeOfCare/DiagnosesForEpisodeOfCareStore";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";

@Di.injectable()
export default class DiagnosisListApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("ICareRegisterClient") private apiClient: Proxy.ICareRegisterClient,
        @Di.inject("DiagnosisListStoreMapper") private diagnosisListStoreMapper: DiagnosisListStoreMapper
    ) {
        super();
    }

    @State.bound
    public getDiagnosesOfEpisodeOfCareAsync(
        episodeOfCareId: EpisodeOfCareId
    ): Promise<DiagnosesForEpisodeOfCareStore> {
        return this.processOperationAsync(
            new DiagnosesForEpisodeOfCareStore(),
            async target => {
                const response = await this.apiClient.getDiagnosesForEpisodeOfCareQueryAsync(CreateRequestId(), new Proxy.GetDiagnosesForEpisodeOfCareControllerDto({
                    episodeOfCareId: episodeOfCareId
                }));

                target.operationInfo = createOperationInfo(response);
                target.setConditionIds(response.conditionIds);
                target.setCreatedAt(response.createdAt);
            }
        );
    }

    @State.bound
    public getDiagnosisListAsync(careActivityId: CareActivityId, requestLock: boolean, isPermissionCheckOnly: boolean = false) {
        return this.processOperationAsync(
            new DiagnosisListStore(),
            async target => {
                const response = await this.apiClient.getDiagnosisListCommandAsync(CreateRequestId(), new Proxy.GetDiagnosisListControllerDto({
                    careActivityId: careActivityId,
                    requestLock: requestLock,
                    skipValidation: false
                }), isPermissionCheckOnly);

                if (!isPermissionCheckOnly) {
                    await this.diagnosisListStoreMapper.applyToStoreAndResolveValidationProblemsAsync(target, response);
                } else {
                    target.operationInfo = createOperationInfo(response);
                }
            }
        );
    }

    public async checkPermissionForGetDiagnosisListAsync(careActivityId: CareActivityId) {
        const response = await this.getDiagnosisListAsync(careActivityId, false);
        return response;
    }

    @State.bound
    public updateDiagnosisListAsync(diagnosisList: DiagnosisListStore, releaseLock: boolean = false) {
        return this.processOperationAsync(
            new DiagnosisListStore(),
            async target => {
                const response = await this.apiClient.updateDiagnosisListCommandAsync(CreateRequestId(), getUpdateDiagnosisListDto(diagnosisList, releaseLock));
                await this.diagnosisListStoreMapper.applyToStoreWithExistingLockAndResolveValidationProblemsAsync(target, response, diagnosisList.lockInfo, releaseLock);
            }
        );
    }

    @State.bound
    public updateDiagnosisListPermissionCheckAsync(careActivityId: CareActivityId) {
        return this.processOperationAsync(
            new DiagnosisListStore(),
            async target => {
                const response = await this.apiClient.updateDiagnosisListCommandAsync(CreateRequestId(),
                    new Proxy.UpdateDiagnosisListControllerDto({
                        careActivityId: careActivityId,
                        releaseLockIfSuccessful: false,
                        updateWithoutValidation: false,
                        diagnoses: []
                    }), true);

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public updateDiagnosisListWithoutExistingLockAsync(diagnosisList: DiagnosisListStore, releaseLock: boolean = false, updateWithoutValidation: boolean = false) {
        return this.processOperationAsync(
            new DiagnosisListStore(),
            async target => {
                const response = await this.apiClient.updateDiagnosisListCommandAsync(CreateRequestId(), getUpdateDiagnosisListDto(diagnosisList, releaseLock, false, updateWithoutValidation));
                this.diagnosisListStoreMapper.applyToStore(target, response);
            }
        );
    }

    public validateAsync(store: DiagnosisListStore): Promise<SimpleStore<IClientValidationResult[]>> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.updateDiagnosisListCommandAsync(CreateRequestId(), getUpdateDiagnosisListDto(store, false, true));
                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }
}
