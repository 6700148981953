import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import NameStore from "@Primitives/NameStore";
import PractitionerIdentifierStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerIdentifierStore";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import PractitionerProfessionalExamStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerProfessionalExamStore";

export function mapNameStore(name: Proxy.PersonName) {
    const ret = new NameStore();

    if (!name) {
        return ret;
    }

    ret.prefix = name.prefix;
    ret.familyName = name.familyName;
    ret.givenName1 = name.givenName1;
    ret.givenName2 = name.givenName2;
    ret.postfix = name.postfix;
    return ret;
}

export function mapIdentifier(dto: Proxy.PractitionerIdentifierDto) {
    const store = new PractitionerIdentifierStore();
    store.identifier = new Identifier(dto.identifier.identifierSystemId, dto.identifier.value);
    store.name = dto.name;
    return store;
}

export function mapProfessions(dto: Proxy.PractitionerProfessionalExamDto) {
    const store = new PractitionerProfessionalExamStore();
    store.professionalExamId =  dto.professionalExamId;
    store.isExpired = dto.isExpired;
    return store;
}
