import FormFieldDataBase from "./FormFieldDataBase";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";

export default class TextArrayFormFieldData extends FormFieldDataBase {
    public isArray: boolean = true;
    @State.observable.ref public value: IObservableArray<string> = State.createObservableShallowArray([]);

    constructor(fieldName: string, isReadonly: boolean, isVisible: boolean, value: IObservableArray<string>) {
        super(fieldName, isReadonly, isVisible);

        this.value = value;
    }
}