import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import ExternalApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/External/ExternalApiAdapter";
import EditableExternalLocationStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/External/EditableExternalLocationStore";
import ExternalLocationPanelView from "@HisPlatform/BoundedContexts/Organization/Components/Panels/ExternalLocation/ExternalLocationPanelView";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import React from "react";

interface IExternalLocationPanelDependencies {
    externalApiAdapter: ExternalApiAdapter;
    dialogService: IDialogService;
    notificationService: INotificationService;
    commonReferenceDataStore: CommonReferenceDataDataStore;
}
interface IExternalLocationPanelProps extends IRoutingFrameContentProps {
    _dependencies?: IExternalLocationPanelDependencies;
    externalLocationId: ExternalLocationId;
    onExternalLocationCreatedAsync?: (externalLocationId: ExternalLocationId) => Promise<void>;
}

@State.observer
class ExternalLocationPanel extends React.Component<IExternalLocationPanelProps> {

    private get externalLocationId() { return this.props.externalLocationId; }
    private get externalApiAdapter() { return this.props._dependencies.externalApiAdapter; }
    private get dialogService() { return this.props._dependencies.dialogService; }
    private get notificationService() { return this.props._dependencies.notificationService; }

    @State.observable.ref private store: EditableExternalLocationStore = null;
    @State.computed private get isNew() { return this.externalLocationId.value === "new"; }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.action.bound
    private async loadAsync() {

        let store: EditableExternalLocationStore = null;

        if (this.isNew) {
            store = new EditableExternalLocationStore(true);
            store.id = new ExternalLocationId("new");
        } else {
            store = await this.externalApiAdapter.getEditableExternalLocationByIdAsync(this.externalLocationId);
        }
        this.setStore(store);
    }

    @State.action.bound
    private setStore(newValue: EditableExternalLocationStore) {
        this.store = newValue;
        this.store.takeSnapshot();
    }

    @State.action.bound
    private replaceStore(newStore: EditableExternalLocationStore) {
        if (newStore.operationWasSuccessful) {
            if (newStore.isPersistedByOperationInfo) {
                this.setStore(newStore);
                this.notificationService.showSavedSuccessfully();
            } else {
                State.runInAction(() => {
                    this.store.validationResults = newStore.validationResults;
                });
            }
        }
    }

    @State.bound
    private isUnsavedOrDirty() {
        return !this.store.isPersistedByOperationInfo || this.store.isDirty();
    }

    @State.bound
    private async navigateAwayAsync() {
        if (this.isUnsavedOrDirty()) {
            const dialogResult = await this.dialogService.confirmIfNotSaved(StaticCareResources.Common.Dialog.ConfirmationTitle, StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);
            if (dialogResult.resultCode === DialogResultCode.Yes) {
                await this.saveStoreAsync(false);
            } else if (dialogResult.resultCode === DialogResultCode.No) {
                return true;
            }
            return false;
        }
        return true;
    }

    @State.action.bound
    private async validateAsync(): Promise<IClientValidationResult[]> {
        if (!this.store) {
            return [];
        }

        const result = await this.saveStoreAsync(true);

        return result.validationResults;
    }

    @State.action.bound
    private async saveStoreAsync(isValidateOnly: boolean) {
        let result: EditableExternalLocationStore = null;

        if (this.isNew) {
            result = await this.externalApiAdapter.addExternalLocationAsync(this.store, isValidateOnly);
        } else {
            result = await this.externalApiAdapter.updateExternalLocationAsync(this.store, isValidateOnly);
        }

        if (!isValidateOnly) {
            this.replaceStore(result);

            if (result.operationWasSuccessful && result.isPersistedByOperationInfo && this.isNew) {
                await this.props.onExternalLocationCreatedAsync?.(result.id);
                return result;
            }
        }

        return result;
    }

    @State.bound
    private async saveAsync() {
        await this.saveStoreAsync(false);
    }

    @State.bound
    public async onDeleteIdentifierAssignmentConfirmationAsync(identifier: Identifier) {
        if (!identifier?.identifierSystemId && !identifier?.value) {
            return true;
        } else {
            const message = StaticOrganizationResources.ExternalLocationPanel.Dialog.IdentifierDeleteConfirmationMessage;
            const textParts: string[] = [];

            const identifierSystemItem = this.props._dependencies.commonReferenceDataStore.identifierSystemMap.items.find(item =>
                item.Entity.id.value === identifier.identifierSystemId.value);

            textParts.push(identifierSystemItem.Localization.Name);
            textParts.push(identifier.value);
            const dialogResult = await this.props._dependencies.dialogService.yesNo(StaticOrganizationResources.ExternalLocationPanel.Dialog.ConfirmationTitle, formatString(message, textParts.join(" ")));
            return dialogResult.resultCode === DialogResultCode.Yes;
        }
    }

    public render() {
        if (!this.store) {
            return null;
        }

        return (
            <ExternalLocationPanelView
                store={this.store}
                onSaveAsync={this.saveAsync}
                onValidateAsync={this.validateAsync}
                onNavigateAwayAsync={this.navigateAwayAsync}
                createIdentifier={this.createIdentifierAsync}
                onDeleteIdentifierConfirmationAsync={this.onDeleteIdentifierAssignmentConfirmationAsync}
            />
        );
    }

    private createIdentifierAsync = () => Promise.resolve(new Identifier());
}

export default connect(
    ExternalLocationPanel,
    new DependencyAdapter<IExternalLocationPanelProps, IExternalLocationPanelDependencies>(c => ({
        externalApiAdapter: c.resolve<ExternalApiAdapter>("ExternalApiAdapter"),
        dialogService: c.resolve<IDialogService>("IDialogService"),
        notificationService: c.resolve<INotificationService>("INotificationService"),
        commonReferenceDataStore: c.resolve("CommonReferenceDataDataStore"),
    }))
);