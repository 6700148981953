import Orientation from "@HisPlatform/BoundedContexts/DocumentManagement/Api/PdfTemplating/Enum/Orientation.g";
import PaperSize from "@HisPlatform/BoundedContexts/DocumentManagement/Api/PdfTemplating/Enum/PaperSize.g";
import Rotation from "@HisPlatform/BoundedContexts/DocumentManagement/Api/PdfTemplating/Enum/Rotation.g";
import PrescriptionId from "@Primitives/PrescriptionId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

const rotations = [
    Rotation.RotateAngle0,
    Rotation.RotateAngle90,
    Rotation.RotateAngle180,
    Rotation.RotateAngle270,
];

export default class PrescriptionPrintPreferences {
    public prescriptionId: PrescriptionId | null;
    @State.observable.ref public orientation: Orientation | null = Orientation.Portrait;
    @State.observable.ref public paperSize: PaperSize | null = PaperSize.Auto;
    @State.observable.ref public rotation: Rotation | null = Rotation.RotateAngle0;
    @State.observable.ref public offsetX: number | null = 0;
    @State.observable.ref public offsetY: number | null = 0;

    @State.action.bound
    public setOrientation(orientation: Orientation) {
        this.orientation = orientation;
    }

    @State.action.bound
    public setPaperSize(paperSize: PaperSize) {
        this.paperSize = paperSize;
    }

    @State.action.bound
    public rotateLeft() {
        let index = rotations.indexOf(this.rotation);
        index--;
        if (index === -1) index = rotations.length - 1;
        this.rotation = rotations[index];
    }

    @State.action.bound
    public rotateRight() {
        let index = rotations.indexOf(this.rotation);
        index++;
        if (index === rotations.length - 1) index = 0;
        this.rotation = rotations[index];
    }

    @State.action.bound
    public setOffsetX(offsetX: number) {
        this.offsetX = offsetX;
    }

    @State.action.bound
    public setOffsetY(offsetY: number) {
        this.offsetY = offsetY;
    }
}