import WorklistDefinitionId from "@Primitives/WorklistDefinitionId.g";
import WorklistType from "@Primitives/WorklistType.g";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import WorklistItemDefinitionStore from "./WorklistItemDefinitionStore";
import _ from "@HisPlatform/Common/Lodash";

export default class WorklistDefinitionStore {
    @State.observable.ref public worklistItemDefinitions: IObservableArray<WorklistItemDefinitionStore> = State.observable([]);
    @State.observable.ref public worklistItemDefinitionsDisplay: WorklistItemDefinitionStore[] = [];

    constructor(
        public readonly id: WorklistDefinitionId,
        public readonly name: string,
        public readonly worklistType: WorklistType,
        worklistItemDefinitions: WorklistItemDefinitionStore[],
        public readonly rowVersion: number
    ) {
        worklistItemDefinitions.forEach(wid => this.worklistItemDefinitions.push(wid));

        State.autorun(() => {
            const sortedCheckedDefinitions = _.sortBy(this.worklistItemDefinitions.filter(i => i.checkState.isChecked), ["order"], ["asc"]);
            const sortedNotCheckedDefinitions = _.sortBy(this.worklistItemDefinitions.filter(i => !i.checkState.isChecked).filter(i => i.hasLocalizedName), ["columnName"], ["asc"]);
    
            State.runInAction(() => this.worklistItemDefinitionsDisplay = [...sortedCheckedDefinitions, ...sortedNotCheckedDefinitions]);
        }, {delay: 500});
    }

    @State.action.bound
    public checkRow(isChecked: boolean, row: WorklistItemDefinitionStore) {
        row.checkState = {
            ...row.checkState,
            isChecked: isChecked
        };

        if (isChecked && row.order === 0) {
            const orders = this.worklistItemDefinitions.map(wid => wid.order);
            row.order = Math.max(...orders) + 1;
        }
    }

    @State.action.bound
    public addWorklistItemDefinition(row: WorklistItemDefinitionStore) {
        if (row.order === 0) {
            const orders = this.worklistItemDefinitions.map(wid => wid.order);
            row.order = Math.max(...orders) + 1;
        }

        this.worklistItemDefinitions.push(row);
    }
}