import Di from "@Di";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import IncludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierFilter";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ShowCreateNewDecisionSupportScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowCreateNewDecisionSupportScreenAction.g";
import DecisionSupportScreenApiAdapter from "@AssecoMedPlugin/Packages/Care/Screens/DecisionSupportScreen/DecisionSupportScreenApiAdapter";
import SaveDecisionSupportAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/SaveTelemetrySessionAction.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import HisPermissionScopes from "@HisPlatform/Common/FrontendActions/HisPermissionScopes";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";

@Di.injectable()
class DecisionSupportScreenFormLogic implements IFormLogic
{
    constructor(
        @Di.inject("AuthorizationService") private readonly authorizationService: AuthorizationService,
        @Di.inject("DecisionSupportScreenApiAdapter") private readonly apiAdapter: DecisionSupportScreenApiAdapter
    ) {
    }

    public async onFormLoadedAsync(
        form: IForm,
        showScreenAction: ShowScreenFrontendActionBase,
        dataElements: FormDataElementBase[]
    ): Promise<void> {
        const formFields = form.data.Content;

        if (showScreenAction instanceof ShowCreateNewDecisionSupportScreenAction) {
            await this.filterPointsOfCareAsync(formFields);
        }

        this.setReadOnlyFields(formFields, showScreenAction);
    }

    public async executeAsync(
        form: IForm,
        showScreenAction: ShowScreenFrontendActionBase,
        dataElements: FormDataElementBase[]
    ): Promise<void> {
        const formFields = form.data.Content;

        if (showScreenAction instanceof ShowCreateNewDecisionSupportScreenAction) {
            await this.filterPointsOfCareAsync(formFields);
        }

        this.setReadOnlyFields(formFields, showScreenAction);
    }

    async filterPointsOfCareAsync(formFields: FormFieldDataBase[]): Promise<void> {
        const field = getField<ReferencedEntityFormFieldData>(formFields, "PointOfCareId");
        if (isNullOrUndefined(field)) {
            return;
        }

        const pointOfCareIds = await this.apiAdapter.getDecisionSupportPointOfCareIdsAsync();
        const filters = State.createObservableShallowArray(
            pointOfCareIds.result
                .filter(pointOfCareId => this.hasSaveDecisionSupportPermissionFor(pointOfCareId))
                .map(pointOfCareId => new IncludeIdentifierFilter(
                        new Identifier(
                            new IdentifierSystemId("UNUSED"),
                            pointOfCareId.value
                        )
                    )
                )
        );
        State.runInAction(() => field.filters = filters);
    }

    private setReadOnlyFields(formFields: FormFieldDataBase[], showScreenAction: ShowScreenFrontendActionBase): void {
        if (!isNullOrUndefined(showScreenAction) && !(showScreenAction instanceof ShowCreateNewDecisionSupportScreenAction)) {
            this.setFieldAsReadOnly(formFields, "PointOfCareId");
        }
    }

    private setFieldAsReadOnly(formFields: FormFieldDataBase[], name: string): void {
        const field = getField(formFields, name);
        if (isNullOrUndefined(field)) {
            return;
        }

        State.runInAction(() => field.isReadOnly = true);
    }

    private hasSaveDecisionSupportPermissionFor(pointOfCareId: PointOfCareId): boolean {
        return this.authorizationService.hasPermissionFor(
            SaveDecisionSupportAction.id,
            HisPermissionScopes.pointOfCare(pointOfCareId.value)
        );
    }
}

export default DecisionSupportScreenFormLogic;