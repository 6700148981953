import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";
import React, { useContext, useMemo } from "react";

export class PermissionScopeProviderStore {
    constructor(
        public readonly scopes: FrontendActionPermissionScope[]
    ) { }

    public static empty = new PermissionScopeProviderStore([]);
}

const PermissionScopeProviderReactContext = React.createContext<PermissionScopeProviderStore>(PermissionScopeProviderStore.empty);

export function usePermissionScopes() {
    return useContext(PermissionScopeProviderReactContext).scopes;
}

interface IPermissionScopeProviderProps {
    scopes: FrontendActionPermissionScope[];
}

export default function PermissionScopeProvider(props: React.PropsWithChildren<IPermissionScopeProviderProps>) {

    const parentScopes = usePermissionScopes();

    const store = useMemo(() => {
        const ret = new Map<string, string>(parentScopes.map(ps => [ps.type, ps.values[0]]));
        props.scopes.forEach(s => ret.set(s.type, s.values[0]));
        const scopes = Array.from(ret).map(([type, value]) => new FrontendActionPermissionScope(type, [value]));
        return new PermissionScopeProviderStore(scopes);
    }, [props.scopes, parentScopes]);

    return (
        <PermissionScopeProviderReactContext.Provider value={store}>
            {props.children}
        </PermissionScopeProviderReactContext.Provider>
    );
}