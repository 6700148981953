import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import DependencyContainer, { Container } from "@DiContainer";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import IScreenRegistry from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import GlobalActionRegistry from "@Toolkit/ReactClient/ActionProcessing/GlobalActionRegistry";
import ServiceRequestListScreen from "@HisPlatform/Packages/ServiceRequest/ServiceRequestListScreen/ServiceRequestListScreen";
import PatientMainMenuSideBar from "@HisPlatform/Components/Pages/Patient/PatientMainPage/View/PatientMainMenuSideBar";
import CareScreenContextData from "@HisPlatform/Services/Definition/ActionProcessing/CareScreenContextData";
import { StringEntityId } from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import createHisDashboardScreenSidebar from "@HisPlatform/Components/ShowScreenAction/ScreenDashboardFactory";
import UseCases from "@Primitives/UseCases";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ShowCareActivityServiceRequestListScreenAction from "@HisPlatform/Packages/ServiceRequest/FrontendActions/ShowCareActivityServiceRequestListScreenAction.g";
import ShowPatientServiceRequestListScreenAction from "@HisPlatform/Packages/ServiceRequest/FrontendActions/ShowPatientServiceRequestListScreenAction.g";
import PatientScreenContextData from "@HisPlatform/Services/Definition/ActionProcessing/PatientScreenContextData";
import ShowServiceRequestScreenAction from "@HisPlatform/Packages/ServiceRequest/FrontendActions/ShowServiceRequestScreenAction.g";
import ShowCreateNewServiceRequestScreenAction from "@HisPlatform/Packages/ServiceRequest/FrontendActions/ShowCreateNewServiceRequestScreenAction.g";
import { configureFrontendActionMappings } from "@HisPlatform/Packages/ServiceRequest/FrontendActions/MapperConfigurations.g";
import { configureFrontendListMappings } from "@HisPlatform/BoundedContexts/WebAppBackend/FrontendListMapper";
import ServiceRequestScreen from "@HisPlatform/Packages/ServiceRequest/ServiceRequestScreen/ServiceRequestScreen";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import OldServiceRequestListScreen from "@HisPlatform/Packages/ServiceRequest/OldServiceRequestListScreen/OldServiceRequestListScreen";

class ServiceRequestPackageInitializer implements IPackageInitializer {
    public configureDependencies(container: Container): void {
        // Moved DI registrations to WebAppBackendDependencyConfiguration to mitigate a DI error

    }    
    public configureMapper(configurator: IMapperConfigurator): void {
        const currentCultureProvider = DependencyContainer.get<ICurrentCultureProvider>("ICurrentCultureProvider");

        configureFrontendActionMappings(configurator);
        configureFrontendListMappings(configurator, currentCultureProvider);
    }

    public configureScreens(registry: IScreenRegistry): void {
        registry.register<ShowCareActivityServiceRequestListScreenAction>(
            ShowCareActivityServiceRequestListScreenAction.id,
            this.getServiceRequestListScreenDescriptor(UseCases.serviceRequestManagementViewPatientServiceRequests2));

        registry.register<ShowPatientServiceRequestListScreenAction>(
            ShowPatientServiceRequestListScreenAction.id,
            this.getServiceRequestListScreenDescriptor(UseCases.serviceRequestManagementViewPatientServiceRequests2));

        registry.register<ShowCreateNewServiceRequestScreenAction>(
            ShowCreateNewServiceRequestScreenAction.id,
            {
                component: ServiceRequestScreen as any,
                getScreenContextData: _ => null,
                sidebarComponent: createHisDashboardScreenSidebar(UseCases.serviceRequestManagementViewServiceRequest)
            },
            {
                displayName: StaticWebAppResources.Common.Button.Add,
                iconName: "plus"
            }
        );

        registry.register<ShowServiceRequestScreenAction>(
            ShowServiceRequestScreenAction.id,
            {
                component: ServiceRequestScreen as any,
                getScreenContextData: _ => null,
                sidebarComponent: createHisDashboardScreenSidebar(UseCases.serviceRequestManagementViewServiceRequest)
            },
            {
                iconName: "pen"
            }
        );

    }

    private getServiceRequestListScreenDescriptor<TAction extends ShowPatientServiceRequestListScreenAction |
        ShowCareActivityServiceRequestListScreenAction>(useCase: string) {
        return {
            component: OldServiceRequestListScreen as any,
            getScreenContextData: (a: TAction) => {
                if (a instanceof ShowPatientServiceRequestListScreenAction) {
                    return new PatientScreenContextData((a as any).patientId);
                }
                return new CareScreenContextData((a as any).careActivityId);
            },
            sidebarComponent: createHisDashboardScreenSidebar(useCase)
        };
    }

    public configureActionProcessor(registry: GlobalActionRegistry) {
    }

}

export default new ServiceRequestPackageInitializer();