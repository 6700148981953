import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import StoreBase from "./StoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";

export default abstract class EntityStoreBase<TEntityId extends IStringEntityId> extends StoreBase {
    public id: TEntityId = null;
    public rowVersion: RowVersion = null;

    @State.observable.ref public validationResults: IClientValidationResult[] = [];

    @State.action.bound
    public setValidationResults(newValue: IClientValidationResult[]) {
        this.validationResults = newValue;
    }

    public get hasValidationError() {
        return !arrayIsNullOrEmpty(this.validationResults) && this.validationResults.some(
            r => r.problems && r.problems.some(p => p.severity === "error"));
    }

    public get hasValidationWarning() {
        return !arrayIsNullOrEmpty(this.validationResults) && this.validationResults.some(
            r => r.problems && r.problems.some(p => p.severity === "warning"));
    }

    public get isValid() {
        return arrayIsNullOrEmpty(this.validationResults) || this.validationResults.every(
            r => arrayIsNullOrEmpty(r.problems));
    }

    constructor(
        public isNew: boolean = false
    ) {
        super();
    }
}