import React, { useCallback, useMemo } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FormEditorRegistry, { IFormEditorComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ReferencedEntityFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEntityFormDataElement";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import StaticFormEditingResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import * as Ui from "@CommonControls";


interface IFormEntitySelectorProps extends IFormEditorComponentProps {

}

function FormEntitySelector(props: IFormEntitySelectorProps) {

    const { formEditorRegistry } = useDependencies(c => ({
        formEditorRegistry: c.resolve<FormEditorRegistry>("FormEditorRegistry")
    }));

    const dataElement = (props.dataElement as ReferencedEntityFormDataElement);
    const editor = formEditorRegistry.tryGetEntityReferenceEditorComponent(dataElement.referencedEntity);

    const valueObj = useMemo(() => {
        if (dataElement.isArray) {
            return isNullOrUndefined(props.value) && editor ? State.createObservableShallowArray([]) : State.createObservableShallowArray(props.value.map((i: any) => new editor.entityIdCtor(`${i}`)));
        } else {
            return isNullOrUndefined(props.value) || !editor ? null : new editor.entityIdCtor(`${props.value}`);
        }
    }, [props.value, editor]);

    const change = useCallback((value: IStringEntityId | IStringEntityId[] | number | number[]) => {
        if (Array.isArray(value)) {
            if (value.length === 0) {
                props.onChange(value);
            }
            if (typeof value[0] === "number") {
                props.onChange(value);
            } else {
                props.onChange((value as []).map((i: IStringEntityId) => parseInt(i.value, 10)));
            }
        } else {
            if (typeof value === "number") {
                props.onChange(value);
            } else {
                props.onChange(isNullOrUndefined(value) ? null : parseInt(value.value, 10));
            }
        }
    }, [props.onChange]);

    if (editor) {
        return React.createElement(
            editor.componentType,
            { ...editor.props, ...props, onChange: change, value: valueObj, multiSelect: dataElement.isArray }
        );
    }
    else {
        return <Ui.Message type="danger">{StaticFormEditingResources.FormFieldRenderError} {dataElement.name}</Ui.Message>;
    }
}

export default State.observer(FormEntitySelector);