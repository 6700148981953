import _ from "@HisPlatform/Common/Lodash";
import Di from "@Di";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import IFormExtensionRegistry from "@PluginInterface/FormExtension/IFormExtensionRegistry";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { IDecisionSupportScreenProps } from "./DecisionSupportScreen";
import DecisionSupportScreenApiAdapter from "./DecisionSupportScreenApiAdapter";
import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import IFormLogicRegistry, { IFormLogicExecutor } from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import HisPermissionScopes from "@HisPlatform/Common/FrontendActions/HisPermissionScopes";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import SaveDecisionSupportAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/SaveTelemetrySessionAction.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import PatientId from "@Primitives/PatientId.g";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import ShowCreateNewDecisionSupportScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowCreateNewDecisionSupportScreenAction.g";
import ShowEditDecisionSupportScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowEditDecisionSupportScreenAction.g";
import ShowReadOnlyDecisionSupportScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowReadOnlyDecisionSupportScreenAction.g";
import LockAcquirerOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockAcquirerOperationInfo";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { ChangeAction } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";
import DecisionSupportModalParams from "./DecisionSupportModal/DecisionSupportModalParams";
import CareActivityId from "@Primitives/CareActivityId.g";
import config from "@Config";
import IExternalDecisionSupportAccessor from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/ExternalDecisionSupport/IExternalDecisionSupportAccessor";
import IProcessData from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/ExternalDecisionSupport/IProcessData";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import DiagnosisListApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/DiagnosisList/DiagnosisListApiAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ReferencedEntityArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityArrayFormFieldData";
import Log from "@HisPlatform/Services/Definition/Logger/Log";

@Di.injectable()
export default class DecisionSupportScreenStore extends EditorScreenPanelStoreBase<IDecisionSupportScreenProps> implements ILoadablePanelStore {
    @State.observable.ref public form: IForm = null;
    private formEngineDefinition: IFormDefinition;
    private formLogicExecutor: IFormLogicExecutor = null;

    @State.computed
    public get decisionSupportModelName(): string {
        if (isNullOrUndefined(this.form)) {
            return null;
        }

        const decisionSupportNameField = getField<TextFormFieldData>(this.form.data.Content, "DecisionSupportName");
        return decisionSupportNameField?.value || "";
    }

    @State.computed
    public get externalDecisionSupportId(): string {
        if (!(this.showScreenAction instanceof ShowEditDecisionSupportScreenAction)) {
            return null;
        }

        return this.showScreenAction.externalDecisionSupportId;
    }

    @State.computed
    public get hasPermissionForSaveDecisionSupport() {
        return this.authorizationService.hasPermissionForDescriptor(this.saveDecisionSupportAction);
    }

    @State.computed
    public get formDefinitionId() {
        return this.form?.definitionId;
    }

    @State.computed
    public get isNewDecisionSupportScreen() {
        return this.showScreenAction instanceof ShowCreateNewDecisionSupportScreenAction;
    }

    @State.computed
    public get isEditDecisionSupportScreen() {
        return this.showScreenAction instanceof ShowEditDecisionSupportScreenAction;
    }

    @State.computed
    public get isReadOnlyDecisionSupportScreen() {
        return this.showScreenAction instanceof ShowReadOnlyDecisionSupportScreenAction;
    }

    @State.computed
    public get saveDecisionSupportAction() {
        if (!this.form) {
            return ActionDescriptor.fromAction(new SaveDecisionSupportAction());
        }

        const pointOfCareIdRawValue = getField<ReferencedEntityFormFieldData>(this.form.data.Content, "PointOfCareId")?.value;
        return ActionDescriptor.fromAction(new SaveDecisionSupportAction(), HisPermissionScopes.pointOfCare(pointOfCareIdRawValue?.toString()));
    }

    @State.computed
    public get vIsReadOnly() {
        if (this.isNewDecisionSupportScreen) {
            return false;
        }

        return this.canAcquireLock
            || (this.isEditDecisionSupportScreen && !this.hasPermissionForSaveDecisionSupport)
            || this.isReadOnlyDecisionSupportScreen;
    }

    @State.computed
    protected get contentToDirtyCheck() {
        return [this.form?.data.Content];
    }

    protected get showScreenAction(): ShowScreenFrontendActionBase {
        return this.props.action;
    }

    @State.computed
    private get patientId(): PatientId {
        if (isNullOrUndefined(this.form)) {
            return null;
        }

        const patientIdField = getField<ReferencedEntityFormFieldData>(this.form.data.Content, "PatientId");
        return isNullOrUndefined(patientIdField)
            ? null
            : new PatientId(patientIdField.value.toString());
    }

    @State.computed
    private get careActivityId(): CareActivityId {
        if (isNullOrUndefined(this.form)) {
            return null;
        }

        const careActivityIdField = getField<ReferencedEntityFormFieldData>(this.form.data.Content, "CareActivityId");
        return isNullOrUndefined(careActivityIdField)
            ? null
            : new CareActivityId(careActivityIdField.value.toString());
    }

    @State.computed
    private get episodeOfCareId(): EpisodeOfCareId {
        if (this.showScreenAction instanceof ShowCreateNewDecisionSupportScreenAction) {
            return this.showScreenAction.episodeOfCareId;
        }

        if (isNullOrUndefined(this.form)) {
            return null;
        }

        const episodeOfCareIdsField = getField<ReferencedEntityArrayFormFieldData>(this.form.data.Content, "EpisodeOfCareIds");
        if (isNullOrUndefined(episodeOfCareIdsField)) {
            Log.warn(`Required 'EpisodeOfCareIds' field for decision support with identifier '${this.careActivityId}' cannot be found.`);
            return null;
        }

        if (episodeOfCareIdsField.value.length === 0) {
            Log.warn(`Decision support with identifier '${this.careActivityId}' has no associated episode of care identifier.`);
            return null;
        }

        const firstEpisodeOfCareId = episodeOfCareIdsField.value[0].toString();
        if (episodeOfCareIdsField.value.length > 1) {
            Log.warn(`Decision support with identifier '${this.careActivityId}' has ${episodeOfCareIdsField.value.length} associated episode of care identifiers, will use ${firstEpisodeOfCareId}.`);
        }

        return new EpisodeOfCareId(firstEpisodeOfCareId);
    }

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("DecisionSupportScreenApiAdapter") private apiAdapter: DecisionSupportScreenApiAdapter,
        @Di.inject("DiagnosisListApiAdapter") private diagnosisListApiAdapter: DiagnosisListApiAdapter,
        @Di.inject("CareReferenceDataStore") private careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("AuthorizationService") private authorizationService: AuthorizationService,
        @Di.inject("IFormLogicRegistry") private formLogicRegistry: IFormLogicRegistry,
        @Di.inject("IFormEngineReferenceDataStore") private formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("IContainerService") private containerService: IContainerService,
        @Di.inject("IFormExtensionRegistry") private formExtensionRegistry: IFormExtensionRegistry,
        @Di.inject("IExternalDecisionSupportAccessor") private decisionSupportAccessor: IExternalDecisionSupportAccessor) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    public async loadCoreAsync(): Promise<void | { loadedSignals?: string[]; }> {
        if (this.props.action instanceof ShowCreateNewDecisionSupportScreenAction) {
            const response = await this.apiAdapter.getNewDecisionSupportScreenDataAsync(this.props.action.patientId, this.props.action.episodeOfCareId);
            this.setForm(response.result.form);
        } else if (this.props.action instanceof ShowEditDecisionSupportScreenAction || this.props.action instanceof ShowReadOnlyDecisionSupportScreenAction) {
            const response = await this.apiAdapter.getDecisionSupportScreenDataAsync(this.props.action.careActivityId, this.isEditDecisionSupportScreen);
            if (response.operationInfo instanceof LockAcquirerOperationInfo) {
                State.runInAction(() => this.lockInfo = (response.operationInfo as LockAcquirerOperationInfo).lockInfo);
            }

            if (this.isReadOnlyDecisionSupportScreen) {
                this.vSetValidationResults(response.result.form.validationResults);
            }

            this.setForm(response.result.form);
        } else {
            return;
        }

        this.formEngineDefinition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(this.form.definitionId);

        const loadedSignals: string[] = [];
        const formExtensions = this.formExtensionRegistry.resolveAll(this.containerService, "DecisionSupportScreen");
        formExtensions.forEach(i => {
            const items = (i.invokeCallback("GetLoadedSignals", {}) as string[]);
            loadedSignals.push(..._.flatten(items));
        });

        this.formLogicExecutor = this.formLogicRegistry.getExecutorForForm(
            this.form, 
            this.showScreenAction, 
            this.formEngineDefinition.name, 
            this.formEngineDefinition);

        await this.formLogicExecutor.formLoadedAsync();

        return {
            loadedSignals: loadedSignals
        };
    }

    public readonly onDataChangeAsync = this.async(async (propertyIdentifier: string, value: any, action: ChangeAction) => {
        const showScreenAction = this.props.action;
        if (showScreenAction instanceof ShowCreateNewDecisionSupportScreenAction) {
            switch (propertyIdentifier) {
                case "PointOfCareId":
                    if (isNullOrUndefined(value) || !await this.tryReloadFormAsync(showScreenAction.patientId, new PointOfCareId(value.toString()), showScreenAction.episodeOfCareId)) {
                        return;
                    }
                    break;
                default:
                    return;
            }
        }

        await this.formLogicExecutor.fieldChangedAsync(action, propertyIdentifier, value);
    });

    public readonly onValidateAsync: () => Promise<IClientValidationResult[]> = this.async(async () => {
        if (!this.form || this.vIsReadOnly) {
            return [];
        }

        const pointOfCareIdRawValue = getField<ReferencedEntityFormFieldData>(this.form.data.Content, "PointOfCareId")?.value;
        if (isNullOrUndefined(pointOfCareIdRawValue)) {
            return [];
        }

        const formValidationResult = await this.apiAdapter.createDecisionSupportAsync(this.form, true);
        return formValidationResult.result.validationResults;
    });

    public readonly continueOrStartSessionAsync: () => Promise<void> = this.async(() => {
        return this.showDecisionSupportModalAsync(this.externalDecisionSupportId);
    });

    @State.bound
    public onCancelAsync(): Promise<any> {
        this.props._screenState.cancelled();
        return Promise.resolve();
    }

    @State.bound
    public async onSaveAsync(): Promise<any> {
        if (this.isNewDecisionSupportScreen) {
            const result = await this.handleSaveResultAsync(() => this.apiAdapter.createDecisionSupportAsync(this.form));
            if (result.isPersisted) {
                this.setForm(result.result);
                await this.showDecisionSupportModalAsync(null);
                this.props._screenState.savedNew(result.result.id);
            }

            return result.isPersisted;
        }

        const action = this.props.action;
        if (action instanceof ShowEditDecisionSupportScreenAction) {
            const result = await this.handleSaveResultAsync(() => this.apiAdapter.updateDecisionSupportAsync(action.careActivityId, this.form, this.lockInfo?.lockId, false));
            if (result.isPersisted) {
                this.setForm(result.result);
                this.formLogicExecutor = this.formLogicRegistry.getExecutorForForm(
                    this.form, 
                    this.showScreenAction, 
                    this.formEngineDefinition.name, 
                    this.formEngineDefinition);
                await this.formLogicExecutor.formLoadedAsync();
                this.props._screenState.savedExisting();
            }

            return result.isPersisted;
        }

        return false;
    }

    protected saveFunction: () => Promise<boolean> = this.onSaveAsync;

    private readonly tryReloadFormAsync = this.async(async (patientId: PatientId, pointOfCareId: PointOfCareId, episodeOfCareId: EpisodeOfCareId): Promise<boolean> => {
        const formDefinitionResponse = await this.apiAdapter.getDecisionSupportScreenFormDefinitionAsync(pointOfCareId);
        if (ValueWrapper.equals(formDefinitionResponse.result, this.form.definitionId)) {
            return false;
        }

        const formResponse = await this.apiAdapter.getNewDecisionSupportScreenDataAsync(patientId, episodeOfCareId);
        this.setForm(formResponse.result.form);
        this.formLogicExecutor = this.formLogicRegistry.getExecutorForForm(
            this.form, 
            this.showScreenAction, 
            this.formEngineDefinition.name, 
            this.formEngineDefinition);
        await this.formLogicExecutor.formLoadedAsync();

        return true;
    });

    private readonly showDecisionSupportModalAsync: (externalDecisionSupportId: string) => Promise<void> = this.async(async (externalDecisionSupportId: string) => {
        const diagnosisCodes = await this.getDiagnosisCodesAsync();
        await this.modalService.showModalAsync(new DecisionSupportModalParams(
            config.decisionSupport.startDecisionSupportUrl,
            this.patientId,
            this.careActivityId,
            await this.decisionSupportAccessor.getInitializationPayloadAsync(
                this.patientId,
                diagnosisCodes,
                externalDecisionSupportId,
                "patient",
                false,
                true
            ),
            (subscription) => this.decisionSupportAccessor.registerProcessUpdatedEventHandler(subscription, this.onProcessUpdatedAsync)
        ));
    });

    private readonly onProcessUpdatedAsync = this.async(async (data: IProcessData): Promise<void> => {
        if (!data.caseId || !this.careActivityId) {
            return;
        }

        if (data.forms.length > 0) {
            const firstForm = data.forms[0];
            const keys = Object.keys(firstForm.answers);
            if (keys.length > 0) {
                const firstAnswer = firstForm.answers[keys[0]];
                this.setDecisionSupportName(firstAnswer ?? "");
            }
        }

        await this.apiAdapter.updateExternalDecisionSupportDataAsync(
            this.careActivityId,
            data.workflowName,
            data.caseId,
            data.forms
        );
    });

    private readonly getDiagnosisCodesAsync = this.async(async (): Promise<string[]> => {
        if (!this.episodeOfCareId) {
            return [];
        }

        const diagnoses = await this.diagnosisListApiAdapter.getDiagnosesOfEpisodeOfCareAsync(this.episodeOfCareId);
        const validOn = LocalDate.createFromMoment(diagnoses.createdAt);
        const conditionVersionSelectors = diagnoses.conditionIds.map(d => new EntityVersionSelector(d, validOn));
        await this.careReferenceDataStore.condition.ensureLoadedAsync(conditionVersionSelectors);

        return conditionVersionSelectors
            .map(s => this.careReferenceDataStore.condition.get(s)?.code)
            .filter(Boolean);
    });

    @State.action.bound
    private setForm(form: IForm): void {
        this.form = form;
    }

    @State.action.bound
    private setDecisionSupportName(value: string): void {
        if (!this.form?.data?.Content) {
            return;
        }

        const decisionSupportNameField = getField<TextFormFieldData>(this.form.data.Content, "DecisionSupportName");
        if (!decisionSupportNameField) {
            return;
        }

        decisionSupportNameField.value = value;
    }
}