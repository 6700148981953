import React from "react";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import RoleApiAdapter from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/OperationAccessControl/RoleApiAdapter";
import RoleId from "@Primitives/RoleId.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Role from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/Role";
import RoleManagementPanelView from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/RoleManagement/RoleManagementPanelView";
import AuthorizationReferenceDataStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/AuthorizationReferenceDataStore";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import RequestStatus from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/RequestStatus";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";

interface IRoleManagementPanelDependencies {
    roleApiAdapter: RoleApiAdapter;
    dialogService: IDialogService;
    notificationService: INotificationService;
    authorizationReferenceDataStore: AuthorizationReferenceDataStore;
    permissionDefinitionRegistry: IPermissionDefinitionRegistry;
}

interface IRoleManagementPanelProps {
    _dependencies?: IRoleManagementPanelDependencies;
    roleId: RoleId;

    onNavigationChanged(roleId: string): void;

    backToPreviousPage(): void;
}

@State.observer
class RoleManagementPanel extends React.Component<IRoleManagementPanelProps> {
    @State.observable private isLoading: boolean;
    @State.observable.ref private selectedRole: Role = null;
    @State.observable.ref private dataSource = new InMemoryDataGridDataSource(() => this.roleList.items || []);

    private get roleList() {
        return this.props._dependencies.authorizationReferenceDataStore.roleMap;
    }

    private get notificationService() {
        return this.props._dependencies.notificationService;
    }

    private get apiAdapter() {
        return this.props._dependencies.roleApiAdapter;
    }

    private get dialogService() {
        return this.props._dependencies.dialogService;
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync, this.apiAdapter.updateRolePermissionCheckAsync);
    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(this.props.roleId), this);
    }

    public componentDidUpdate(prevProps: IRoleManagementPanelProps) {
        if (prevProps.roleId && !this.props.roleId) {
            this.unload();
        } else if (!prevProps.roleId || !wrappedValuesAreEquals(prevProps.roleId, this.props.roleId) || !this.props.roleId) {
            dispatchAsyncErrors(this.setRoleByIdAsync(this.props.roleId), this);
        }
    }

    @State.bound
    private async loadAsync(roleId: RoleId) {
        this.setIsLoading(true);

        const getRolePromise = this.setRoleByIdAsync(roleId);
        const updateListPromise = this.updateListAsync();
        await Promise.all([getRolePromise, updateListPromise]);

        this.setIsLoading(false);
    }

    @State.action.bound
    private unload() {
        this.selectedRole = null;
    }

    @State.action.bound
    private async setRoleByIdAsync(roleId: RoleId) {
        if (!roleId) {
            return;
        }

        let newStore: Role;

        if (roleId.value === "new") {
            newStore = new Role();
            newStore.id = roleId;
            newStore.isNew = true;
            newStore.permissions.definitions = [];
        } else {
            const response = await this.apiAdapter.getRoleById(roleId);
            if (response.operationInfo.requestStatus === RequestStatus.Success) {
                newStore = response;
            }
        }
        if (newStore) {
            this.setRole(newStore);
        }
    }

    @State.action.bound
    private setRole(newValue: Role) {
        this.selectedRole = newValue;
        this.selectedRole.takeSnapshot();
    }

    @State.bound
    private onSelectedItemChange(roleId: string) {
        this.props.onNavigationChanged(roleId);
    }

    @State.bound
    private addNewRole() {
        this.props.onNavigationChanged("new");
    }

    @State.bound
    private async updateListAsync() {
        this.roleList.invalidate();
        await this.roleList.ensureAllLoadedAsync();
    }

    @State.action.bound
    private async saveRoleAsync() {
        this.setIsLoading(true);
        let newStore: Role;
        if (this.selectedRole.isNew) {
            newStore = await this.apiAdapter.addRole(this.selectedRole);
        } else {
            newStore = await this.apiAdapter.updateRole(this.selectedRole);
        }

        if (newStore.operationInfo.requestStatus !== RequestStatus.Success || newStore.hasValidationError) {
            this.notificationService.showCannotSaveBecauseOfErrors();
            this.setValidation(newStore.validationResults);
            this.setIsLoading(false);
            return;
        }

        this.notificationService.showSaveResult(newStore.isPersistedByOperationInfo, newStore.hasValidationWarning);
        if (this.selectedRole.isNew) {
            this.selectedRole.takeSnapshot();
            this.props.onNavigationChanged(newStore.id.value);
        } else {
            this.setRole(newStore);
        }
        await this.updateListAsync();
        this.setIsLoading(false);
    }

    @State.bound
    private async validateAsync() {
        const result = await this.apiAdapter.validateAsync(this.selectedRole);
        return result.value;
    }

    @State.action.bound
    private setValidation(validation: IClientValidationResult[]) {
        this.selectedRole.validationResults = validation;
    }

    @State.action.bound
    private setIsLoading(newValue: boolean) {
        this.isLoading = newValue;
    }

    @State.bound
    private async tryCloseUserEditorAsync() {
        if (this.selectedRole?.isDirty()) {
            const answer = await this.dialogService.confirmIfNotSaved(StaticAuthorizationResources.RoleManagement.Message.SaveBeforeNavigationConfirmationTitle,
                StaticAuthorizationResources.RoleManagement.Message.SaveBeforeNavigationConfirmationMessage);
            if (answer.resultCode === DialogResultCode.Yes) {
                await this.saveRoleAsync();
                return !this.selectedRole.hasValidationError;
            } else {
                return answer.resultCode === DialogResultCode.No;
            }
        }
        return true;
    }

    @State.computed private get allPermissionDefinitions() {
        return this.props._dependencies.permissionDefinitionRegistry.getAll();
    }

    @State.bound
    private GetAssignedPermissionCountForRole(role: Role) {
        return this.allPermissionDefinitions.filter(item =>
            role.permissions.definitions.some(selected =>
                item.ids.some(id => id.value === selected.ids[0].value) && selected.includesPermission(item)
            )
        ).length;
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticAuthorizationResources.RoleManagement.Title} />;
        }

        return (
            <>
                <RoleManagementPanelView
                    role={this.selectedRole}
                    backToPreviousPage={this.props.backToPreviousPage}
                    onSelectedItemChange={this.onSelectedItemChange}
                    isLoading={this.isLoading}
                    dataSource={this.dataSource}
                    addNewRole={this.addNewRole}
                    saveRoleAsync={this.saveRoleAsync}
                    validateRoleAsync={this.validateAsync}
                    onGetAssignedPermissionCountForRole={this.GetAssignedPermissionCountForRole}
                />
                <NavigateAwayHook isEnabled onNavigateAwayAsync={this.tryCloseUserEditorAsync} />
            </>
        );
    }
}

export default connect(
    RoleManagementPanel,
    new DependencyAdapter<IRoleManagementPanelProps, IRoleManagementPanelDependencies>(container => {
        return {
            roleApiAdapter: container.resolve("RoleApiAdapter"),
            dialogService: container.resolve("IDialogService"),
            notificationService: container.resolve("INotificationService"),
            authorizationReferenceDataStore: container.resolve("AuthorizationReferenceDataStore"),
            permissionDefinitionRegistry: container.resolve("IPermissionDefinitionRegistry")
        };
    })
);
