import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import AutonomyDisabilityStatementId from "@Primitives/AutonomyDisabilityStatementId.g";
import AutonomyDisabilityStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/AutonomyDisabilityStatementStore";
import AutonomyDisabilityStatementStoreMapper from "./AutonomyDisabilityStatementStoreMapper";
import PatientId from "@Primitives/PatientId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import { getAddAutonomyDisabilityStatementControllerDto, getUpdateAutonomyDisabilityStatementControllerDto, getDeleteAutonomyDisabilityStatementControllerDto, getDeleteAutonomyDisabilityStatementHistoryItemControllerDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/AutonomyDisabilityStatement/AutonomyDisabilityStatementDtoMapper";

@Di.injectable()
export default class AutonomyDisabilityStatementApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IAutonomyDisabilityStatementClient") private readonly apiClient: Proxy.IAutonomyDisabilityStatementClient,
        @Di.inject("AutonomyDisabilityStatementStoreMapper") private readonly storeMapper: AutonomyDisabilityStatementStoreMapper
    ) {
        super();
    }

    public getByIdAsync(id: AutonomyDisabilityStatementId, requestLock: boolean): Promise<AutonomyDisabilityStatementStore> {
        return this.processOperationAsync(
            new AutonomyDisabilityStatementStore(false),
            async target => {
                const response = await this.apiClient.getAutonomyDisabilityStatementByIdCommandAsync(
                    CreateRequestId(),
                    new Proxy.GetAutonomyDisabilityStatementByIdControllerDto({ autonomyDisabilityStatementId: id, requestLock: requestLock })
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public addAutonomyDisabilityStatementAsync(store: AutonomyDisabilityStatementStore, patientId: PatientId, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new AutonomyDisabilityStatementStore(false),
            async target => {
                const response = await this.apiClient.addAutonomyDisabilityStatementCommandAsync(
                    CreateRequestId(),
                    getAddAutonomyDisabilityStatementControllerDto(store, patientId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public updateAutonomyDisabilityStatementAsync(store: AutonomyDisabilityStatementStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new AutonomyDisabilityStatementStore(false),
            async target => {
                const response = await this.apiClient.updateAutonomyDisabilityStatementCommandAsync(
                    CreateRequestId(),
                    getUpdateAutonomyDisabilityStatementControllerDto(store, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deleteAutonomyDisabilityStatementHistoryItemAsync(store: AutonomyDisabilityStatementStore, versionNumber: number, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new AutonomyDisabilityStatementStore(false),
            async target => {
                const response = await this.apiClient.deleteAutonomyDisabilityStatementHistoryItemCommandAsync(
                    CreateRequestId(),
                    getDeleteAutonomyDisabilityStatementHistoryItemControllerDto(store, versionNumber, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deleteAutonomyDisabilityStatementAsync(store: AutonomyDisabilityStatementStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<any>(),
            async target => {
                const response = await this.apiClient.deleteAutonomyDisabilityStatementCommandAsync(
                    CreateRequestId(),
                    getDeleteAutonomyDisabilityStatementControllerDto(store),
                    isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public validateAsync(store: AutonomyDisabilityStatementStore, patientId: PatientId) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response =
                    store.isNew ?
                        await this.apiClient.addAutonomyDisabilityStatementCommandAsync(CreateRequestId(), getAddAutonomyDisabilityStatementControllerDto(store, patientId, true)) :
                        await this.apiClient.updateAutonomyDisabilityStatementCommandAsync(CreateRequestId(), getUpdateAutonomyDisabilityStatementControllerDto(store, true, store.lockInfo?.lockId, false));

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    public async checkPermissionForAddNewItemAsync(store: AutonomyDisabilityStatementStore, patientId: PatientId): Promise<AutonomyDisabilityStatementStore> {
        return await this.addAutonomyDisabilityStatementAsync(store, patientId, true);        
    }

    public async checkPermissionForUpdateItemAsync(store: AutonomyDisabilityStatementStore): Promise<AutonomyDisabilityStatementStore> {
        return await this.updateAutonomyDisabilityStatementAsync(store, true);
    }

    public async checkPermissionForDeleteItemAsync(store: AutonomyDisabilityStatementStore): Promise<SimpleStore> {
        return await this.deleteAutonomyDisabilityStatementAsync(store, true);
    }
}
