import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import TherapyRecommendationStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/TherapyRecommendationStore";
import TherapyRecommendationId from "@Primitives/TherapyRecommendationId.g";
import TherapyRecommendationHistoryItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/TherapyRecommendationHistoryItem";
import ReferencedTherapyAssignment from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/ReferencedTherapyAssignment";
import CustomTherapyAssignment from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/CustomTherapyAssignment";
import { MedicalConditionHistoryItemDetailDto } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";

type ResponseType = Proxy.GetTherapyRecommendationByIdCommandResponse | Proxy.AddTherapyRecommendationCommandResponse | Proxy.UpdateTherapyRecommendationCommandResponse | Proxy.DeleteTherapyRecommendationHistoryItemCommandResponse;

@Di.injectable()
export default class TherapyRecommendationStoreMapper extends LockingEntityStoreMapper<Proxy.TherapyRecommendationDto, TherapyRecommendationStore, ResponseType> {

    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    protected storeEntityIdType = TherapyRecommendationId;

    protected entityDtoSelector = (response: ResponseType) => {
        return response.therapyRecommendationDto;
    };

    protected applyToStoreCore(target: TherapyRecommendationStore, response: any) {
        const dto = this.entityDtoSelector(response);

        if (dto != null) {
            target.id = dto.id;
            target.therapyAssignment = this.getEhiTherapyAssignment(dto);

            target.historyItems = State.observable(this.getHistoryItems(dto.historyItems));
            target.currentItemIndex = target.historyItems.length - 1;
            target.patientId = dto.patientId;
            target.extensionData = dto.extensionData;
        }
    }

    private getHistoryItems(dtoArray: Proxy.TherapyRecommendationHistoryItemDto[]): TherapyRecommendationHistoryItem[] {
        return dtoArray.map(dto => this.getHistoryItem(dto)).sort((a, b) => a.versionNumber - b.versionNumber);
    }

    private getHistoryItem(dto: Proxy.TherapyRecommendationHistoryItemDto): TherapyRecommendationHistoryItem {
        const returnItem = new TherapyRecommendationHistoryItem();

        returnItem.historyItemDetail = this.getHistoryItemDetail(dto.historyItemDetail);
        returnItem.description = dto.description;
        returnItem.endDate = dto.endDate;
        returnItem.startDate = dto.startDate;
        returnItem.versionNumber = dto.versionNumber;
        returnItem.index = dto.index;
        returnItem.createdAt = dto.createdAt;
        returnItem.extensionData = dto.extensionData;

        return returnItem;
    }

    private getHistoryItemDetail(dto: MedicalConditionHistoryItemDetailDto): MedicalConditionHistoryItemDetail {
        const returnItem = new MedicalConditionHistoryItemDetail();

        returnItem.asserter = dto.asserter;
        returnItem.asserterType = dto.asserterType;
        returnItem.notes = dto.notes;
        returnItem.verifier = dto.verifier;

        return returnItem;
    }

    private getEhiTherapyAssignment(dto: Proxy.TherapyRecommendationDto) {
        let therapyAssignment;

        if (dto.therapyAssignment instanceof Proxy.ReferencedTherapyAssignmentDto) {
            therapyAssignment = new ReferencedTherapyAssignment(dto.therapyAssignment.therapyId);
        } else if (dto.therapyAssignment instanceof Proxy.CustomTherapyAssignmentDto) {
            therapyAssignment = new CustomTherapyAssignment(dto.therapyAssignment.name);
        }

        return therapyAssignment;
    }
}
