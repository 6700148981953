import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import SchedulingServiceFilter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceFilter";
import moment from "moment";

export default class SchedulePlan {
    @State.observable.ref public from: moment.Moment = null;
    @State.observable.ref public to: moment.Moment = null;
    @State.observable.ref public schedulingServiceFilters: IObservableArray<SchedulingServiceFilter> = State.observable([]);
    
    @State.action.bound
    public setFrom(from: moment.Moment) {
        this.from = from;
    }

    @State.action.bound
    public setTo(to: moment.Moment) {
        this.to = to;
    }

    @State.action.bound
    public removeSchedulingServiceFilter(identifier: SchedulingServiceFilter) {
        this.schedulingServiceFilters.remove(identifier);
    }
}
