import DependencyContainer from "@DiContainer";
import DocumentSnippetSelectorDialogApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Dialog/DocumentSnippetSelectorDialogApiAdapter";
import DocumentSnippetDialogStoreMapper from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Dialog/DocumentSnippetDialogStoreMapper";

DependencyContainer
    .bind("DocumentSnippetSelectorDialogApiAdapter")
    .to(DocumentSnippetSelectorDialogApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("DocumentSnippetDialogStoreMapper")
    .to(DocumentSnippetDialogStoreMapper)
    .inSingletonScope();