import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ServiceRequestManagementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestManagementApiAdapter";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import { SelectBox } from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import ServiceRequestDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestDefinition";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import _ from "@HisPlatform/Common/Lodash";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";

interface IServiceRequestDefinitionFilterSelectBoxDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    serviceRequestManagementApiAdapter: ServiceRequestManagementApiAdapter;
}

interface IServiceRequestDefinitionFilterSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IServiceRequestDefinitionFilterSelectBoxDependencies;
    direction?: ServiceRequestDirection;
    validOn?: LocalDate;
    explicitIds?: ServiceRequestDefinitionId[];
    pointOfCareIdsFilter?: PointOfCareId[];
}

@State.observer
class ServiceRequestDefinitionFilterSelectBox extends React.Component<IServiceRequestDefinitionFilterSelectBoxProps> {
    private get dependencies() { return this.props._dependencies; }
    @State.observable.ref private isLoading = true;
    @State.observable.ref private items: Array<ISelectBoxItem<ServiceRequestDefinitionId>> = null;
    private definitions: ServiceRequestDefinition[] = [];

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IServiceRequestDefinitionFilterSelectBoxProps) {
        if (prevProps.direction !== this.props.direction ||
            prevProps.explicitIds !== this.props.explicitIds ||
            prevProps.pointOfCareIdsFilter !== this.props.pointOfCareIdsFilter) {
            this.filterDefinitionsByFilters(this.getValidOn());
        }
    }

    @State.bound
    private getDisplayValue(props: any) {
        const definition = this.definitions.find(def => def.id === props.data.value);
        return <><b>{definition.code.value}</b>{` - ${definition.name}`}</>;
    }

    private getValidOn() {
        return isNullOrUndefined(this.props.validOn) ? LocalDate.today() : this.props.validOn;
    }

    private async loadAsync() {
        const validOn = this.getValidOn();

        const activeItems = await this.dependencies.serviceRequestManagementApiAdapter.getActiveServiceRequestDefinitionsAsync(validOn);
        if (activeItems.operationWasSuccessful) {
            await this.dependencies.careReferenceDataStore.serviceRequestDefinition.ensureLoadedAsync(
                activeItems.value.map(def => new EntityVersionSelector<ServiceRequestDefinitionId>(def.id, validOn))
            );

            this.definitions = activeItems.value
                .map(item => this.dependencies.careReferenceDataStore.serviceRequestDefinition.get(
                    new EntityVersionSelector<ServiceRequestDefinitionId>(item.id, validOn)
                ));

            this.filterDefinitionsByFilters(validOn);
        }
    }

    private filterDefinitionsByFilters(validOn: LocalDate): any {
        const filteredItems = isNullOrUndefined(this.props.direction) ?
            this.definitions :
            this.definitions.filter(i => i.availableDirections.some(j => j === this.props.direction));

        const filteredByExplicitIds = isNullOrUndefined(this.props.explicitIds) ?
            filteredItems :
            filteredItems.filter(i => this.props.explicitIds.some(s => ValueWrapper.equals(s, i.id)));

        const filteredByPointOfCareIds = isNullOrUndefined(this.props.pointOfCareIdsFilter) ?
            filteredByExplicitIds :
            filteredByExplicitIds.filter(i => !!_.intersectionWith(this.props.pointOfCareIdsFilter, i.internalLocationsAsPointOfCareIds, ValueWrapper.equals).length);

        this.setLoadedItems(this.mapDefinitionsToSelectBoxItems(filteredByPointOfCareIds, validOn));
    }

    private mapDefinitionsToSelectBoxItems(definitions: ServiceRequestDefinition[], validOn: LocalDate): Array<ISelectBoxItem<ServiceRequestDefinitionId>> {
        return definitions.map(def => ({
            text: `${def.code.value} - ${def.name}`,
            value: def.id
        } as ISelectBoxItem<ServiceRequestDefinitionId>));
    }

    @State.action
    private setLoadedItems(items: Array<ISelectBoxItem<ServiceRequestDefinitionId>>) {
        this.items = items;
        this.isLoading = false;
    }

    public render() {
        return (
            <SelectBox
                {...this.props}
                loading={this.isLoading}
                items={this.items}
                customValueRenderer={this.getDisplayValue}
            />
        );
    }
}

export default connect(
    ServiceRequestDefinitionFilterSelectBox,
    new DependencyAdapter<IServiceRequestDefinitionFilterSelectBoxProps, IServiceRequestDefinitionFilterSelectBoxDependencies>(c => ({
        careReferenceDataStore: c.resolve<CareReferenceDataStore>("CareReferenceDataStore"),
        serviceRequestManagementApiAdapter: c.resolve<ServiceRequestManagementApiAdapter>("ServiceRequestManagementApiAdapter"),
    }))
);