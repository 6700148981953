import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import CombinePatientsScreenStore from "./CombinePatientsScreenStore";

const {
    ContextComponent: CombinePatientsScreenStoreContext,
    StoreProvider: CombinePatientsScreenStoreProvider,
    useStore: useCombinePatientsScreenStore,
    provideStore: provideCombinePatientsScreenStore,
} = createPanelStoreProvider(CombinePatientsScreenStore);

export {
    CombinePatientsScreenStoreContext,
    CombinePatientsScreenStoreProvider,
    useCombinePatientsScreenStore,
    provideCombinePatientsScreenStore
};