import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import { IRegisteredPatientAppointmentListRouteParams } from "./IRegisteredPatientAppointmentListRouteParams";
import PatientId from "@Primitives/PatientId.g";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import StandaloneAppointmentRoutes from "@HisPlatform/Components/Pages/AppointmentPages/StandaloneAppointmentRoutes";
import RegisteredPatientNDataPanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/RegisteredPatientNDataPanel";
import PatientContextProvider from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextProvider";
import DashboardLayout from "@HisPlatformControls/DashboardLayout/DashboardLayout";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import CareActivityId from "@Primitives/CareActivityId.g";
import CareActivityContextProvider from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextProvider";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { getUseCaseAsUrlParam, parseUseCaseStateFromUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import CareActivityGuidanceHeader from "@HisPlatform/Packages/Guidance/Components/CareActivityGuidanceHeader/CareActivityGuidanceHeader";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import ViewContextProvider from "@Toolkit/ReactClient/Components/ViewContext/ViewContextProvider";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

interface IStandaloneRegisteredPatientAppointmentsPageDependencies {
    useCaseRegistry: IUseCaseRegistry;
    applicationContext: ApplicationContext;
}

interface IStandaloneRegisteredPatientAppointmentsPageProps extends IRoutingFrameContentProps {
    _dependencies?: IStandaloneRegisteredPatientAppointmentsPageDependencies;
}

@State.observer
class StandaloneRegisteredPatientAppointmentsPage extends React.Component<IStandaloneRegisteredPatientAppointmentsPageProps> {
    @State.computed private get selectedAppointmentId(): AppointmentScheduleEntryId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments[0].value;
        }

        if (this.useCaseState?.useCase.value === UseCases.newAppointment) {
            return new AppointmentScheduleEntryId("new");
        }

        return null;
    }

    private get patientId(): PatientId {
        return new PatientId((this.props.routingController.currentRoute.parameters as IRegisteredPatientAppointmentListRouteParams)?.patientId);
    }

    private get careActivityId(): CareActivityId {
        const careActivityIdId = (this.props.routingController.currentRoute.parameters as IRegisteredPatientAppointmentListRouteParams)?.careActivityId;
        if (!!careActivityIdId) {
            return new CareActivityId(careActivityIdId);
        }
        return null;
    }

    @State.computed private get useCaseState() {
        const useCaseState = (this.props.routingController.currentRoute.parameters as IRegisteredPatientAppointmentListRouteParams)?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState) as INDataUseCaseState;
    }

    @State.bound
    private navigateToAppointment(appointmentId: AppointmentScheduleEntryId) {
        this.props.routingController.replace(

            StandaloneAppointmentRoutes.registeredPatientAppointments.makeRoute({
                patientId: this.patientId.value.toString(),
                careActivityId: this.careActivityId?.value,
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.editAppointmentFromList),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(appointmentId, "appointmentId")])
            })
        );
    }

    @State.bound
    private setUseCaseState(selectedAppointmentId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.replace(
            StandaloneAppointmentRoutes.registeredPatientAppointments.makeRoute({
                patientId: this.patientId.value,
                careActivityId: this.careActivityId?.value,
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            })
        );
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(
            StandaloneAppointmentRoutes.registeredPatientAppointments.makeRoute({
                patientId: this.patientId.value,
                careActivityId: this.careActivityId?.value,
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.patientRegisterViewAppointments),
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument("null", "appointmentId")])
            })
        );
    }

    @State.bound
    private navigateToAdmission(appointmentId: AppointmentScheduleEntryId) {
        // this.props.routingController.push(ApplicationRoutes.patientAdmission.makeRoute({
        //     careActivityId: "new",
        //     patientId: this.patientId.value,
        //     appointmentId: appointmentId.value
        // }));
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.computed private get currentActionDescriptor() {
        const adapter = this.props._dependencies.useCaseRegistry.tryGetScreenAdapter(this.props._dependencies.applicationContext.currentUseCaseIdentifier);
        return adapter?.(this.props._dependencies.applicationContext.currentUseCaseIdentifier, []);
    }

    public render() {
        return (
            <PatientContextProvider patientId={this.patientId}>
                <CareActivityContextProvider careActivityId={this.careActivityId}>
                    <DashboardLayout>
                        <Ui.ToolbarLayout topToolbarHeight={"auto"}
                            topToolbar={(
                                <CareActivityGuidanceHeader action={this.currentActionDescriptor?.action as any ?? null} screenState={null} />
                            )}
                        >
                            <HisUi.HisErrorBoundary>
                                <LoadingBoundary>
                                    <ViewContextProvider totalHeightLoss={85}>
                                        <RegisteredPatientNDataPanel
                                            onBack={this.goBack}
                                            onAdmitPatient={this.navigateToAdmission}
                                            onSelectedAppointmentIdChange={this.navigateToAppointment}
                                            selectedRowId={this.selectedAppointmentId?.value}
                                            useCaseState={this.useCaseState}
                                            onChange={this.setUseCaseState}
                                            onCloseDetail={this.setClosedState}
                                            isAdmitPatientAllowed
                                        />
                                    </ViewContextProvider>
                                </LoadingBoundary>
                            </HisUi.HisErrorBoundary>
                        </Ui.ToolbarLayout>
                    </DashboardLayout>
                </CareActivityContextProvider>
            </PatientContextProvider>
        );
    }
}

export default connect(
    StandaloneRegisteredPatientAppointmentsPage,
    new DependencyAdapter<IStandaloneRegisteredPatientAppointmentsPageProps, IStandaloneRegisteredPatientAppointmentsPageDependencies>(c => ({
        applicationContext: c.resolve<ApplicationContext>("ApplicationContext"),
        useCaseRegistry: c.resolve<IUseCaseRegistry>("IUseCaseRegistry"),
    }))
);
