// tslint:disable:variable-name
import createPanelStoreProvider, { IStoreProviderSettings } from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import ServiceRequestScreenStore from "./ServiceRequestScreenStore";
import StaticResources from "@StaticResources";

const { 
    ContextComponent: ServiceRequestScreenStoreContext, 
    StoreProvider: ServiceRequestScreenStoreProvider, 
    useStore: useServiceRequestScreenStore,
    provideStore: provideServiceRequestScreenStore,
} = createPanelStoreProvider(
    ServiceRequestScreenStore,
    () => ({
        unauthorizedPageTitle: StaticResources.OutpatientWorkflow.ServiceRequestManagementStep.Title
    } as IStoreProviderSettings)
);

export {
    ServiceRequestScreenStoreContext,
    ServiceRequestScreenStoreProvider,
    useServiceRequestScreenStore,
    provideServiceRequestScreenStore
};