import AppointmentScheduleDefinitionId from "@Primitives/AppointmentScheduleDefinitionId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IAppointmentSubjectStore } from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/IAppointmentSubjectStore";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";

export default class ServiceRequestSubjectStore extends EntityStoreBase<ServiceRequestId> implements IAppointmentSubjectStore {
    @State.observable public name: string = null;
    @State.observable public code: string = null;
    @State.observable public durationInMinutes?: number = null;
    @State.observable public definitionId: ServiceRequestDefinitionId;

    constructor(isNew?: boolean) {
        super(isNew);
        this.validationResults = [];
    }

    @State.computed
    public get codeValue() {
        return this.code;
    }

    @State.action.bound public setName(newValue: string) {
        this.name = newValue;
    }

    @State.action.bound public setCode(newValue: string) {
        this.code = newValue;
    }

    @State.action.bound public setDurationInMinutes(newValue: number) {
        this.durationInMinutes = newValue;
    }

    @State.action.bound public setDefinitionId(newValue: ServiceRequestDefinitionId) {
        this.definitionId = newValue;
    }
}