import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ConditionStatementId from "@Primitives/ConditionStatementId.g";
import ConditionAssignmentBase from "./ConditionAssignmentBase";
import ReferencedConditionAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/ReferencedConditionAssignment";
import CustomConditionAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/CustomConditionAssignment";
import MedicalConditionItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionItemBase";
import { ConditionExtendedCodeSelectorItem } from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ConditionExtendedCodeSelector/ConditionExtendedCodeSelectorItem";
import ConditionStatementClinicalStatus from "@HisPlatform/BoundedContexts/Care/Api/MedicalCondition/ConditionStatement/Enum/ConditionStatementClinicalStatus.g";

export default class ConditionStatementStore extends MedicalConditionItemBase<ConditionStatementId> {

    @State.observable.ref public conditionAssignment: ConditionAssignmentBase;
    @State.observable public conditionName: string;
    @State.observable public clinicalStatus: ConditionStatementClinicalStatus;

    constructor(isNew: boolean) {
        super(isNew);
    }

    @State.computed public get conditionCodeSelectorItem() {
        if (!this.conditionAssignment) {
            return null;
        }
        if (this.conditionAssignment instanceof ReferencedConditionAssignment) {
            return new ConditionExtendedCodeSelectorItem({ id: this.conditionAssignment.conditionId });
        } else if (this.conditionAssignment instanceof CustomConditionAssignment) {
            return new ConditionExtendedCodeSelectorItem({ text: this.conditionAssignment.name });
        }
        return null;
    }

    @State.action.bound
    public setConditionAssignment(newValue: ConditionAssignmentBase) {
        this.conditionAssignment = newValue;
    }

    @State.action.bound
    public setConditionName(newValue: string) {
        this.conditionName = newValue;
    }

    @State.action.bound
    public setClinicalStatus(newValue: ConditionStatementClinicalStatus) {
        this.clinicalStatus = newValue;
    }
}