import React, { useCallback } from "react";
import * as Ui from "@CommonControls";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { iconNameType } from "@CommonControls/Icon";
import CareActivityEReferralStore from "@HunEHealthInfrastructurePlugin/Model/CareActivityEReferralStore";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import EhiEReferralId from "@Primitives/EhiEReferralId.g";

interface ICareActivityEhiEReferralPanelListButtonsProps {
    row?: CareActivityEReferralStore;
    selectedEhiEReferralId: EhiEReferralId;
    onSelectEReferral: (row: CareActivityEReferralStore) => void;
    onShowDetail: (row: CareActivityEReferralStore) => void;
}


const CareActivityEhiEReferralPanelListButtons: React.FC<ICareActivityEhiEReferralPanelListButtonsProps> = props => {

    const onClickSelectReferral = useCallback(() => {
        props.onSelectEReferral(props.row);
    }, [props.onSelectEReferral, props.row]);

    const onClickShowDetail = useCallback(() => {
        props.onShowDetail(props.row);
    }, [props.onShowDetail, props.row]);

    const isLinked = ValueWrapper.equals(props.row.ehiEReferralId, props.selectedEhiEReferralId);

    const linkIconName = isLinked ? "link" : "unlink" as iconNameType;

    return (
        <>
            <ReadOnlyContext.Provider value={false}>
                <Ui.Button
                    automationId="showDetailButton"
                    onClick={onClickShowDetail}
                    size="compact"
                    iconName={"ophthalmology"}
                />
                <Ui.Button
                    automationId={"linkReferralButton_" + (isLinked ? "linked" : "unlinked")}
                    onClick={onClickSelectReferral}
                    size="compact"
                    iconName={linkIconName}
                />
            </ReadOnlyContext.Provider>
        </>
    );
};

export default CareActivityEhiEReferralPanelListButtons;