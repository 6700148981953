import * as Proxy from "@HisPlatform/BoundedContexts/Authorization/Api/Proxy.g";
import EntityPermissionStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/DataAccessControl/EntityPermissionStore";
import AccessControlEntry from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/DataAccessControl/AccessControlEntry";
import UserGroupSubject from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/DataAccessControl/UserGroupSubject";
import UserSubject from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/DataAccessControl/UserSubject";
import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import EntityProtectionLevel from "@Primitives/EntityProtectionLevel";
import ObjectPermission from "@Primitives/ObjectPermission";
import EntityPermissionId from "@Primitives/EntityPermissionId.g";
import Di from "@Di";

type ResponseType = Proxy.GetEntityPermissionQueryResponse | Proxy.SetEntityPermissionCommandResponse;

@Di.injectable()
export default class EntityPermissionStoreMapper extends EntityStoreMapper<Proxy.EntityPermissionDto, EntityPermissionStore, ResponseType> {
    protected storeEntityIdType = EntityPermissionId;
    
    protected entityDtoSelector(response: ResponseType) {
        return response.entityPermission;
    }

    public applyToStoreCore(target: EntityPermissionStore, response: ResponseType) {
        const entity = this.entityDtoSelector(response);
        target.entityId = entity.entityId;
        target.entityType = entity.entityType;

        if (response instanceof Proxy.GetEntityPermissionQueryResponse) {
            target.isCurrentUserAllowedToSetPermissions = response.isCurrentUserAllowedToSetPermissions;
        }

        target.accessControlList.entityProtectionLevel = entity.accessControlList.protectionLevel as unknown as EntityProtectionLevel;
        target.accessControlList.entries.replace(entity.accessControlList.entries.map(entryDto => {
            const newEntry = new AccessControlEntry();

            if (entryDto.subject instanceof Proxy.UserGroupSubject) {
                const subject = new UserGroupSubject();
                subject.userGroupId = entryDto.subject.userGroupId;
                newEntry.subject = subject;
            } else if (entryDto.subject instanceof Proxy.UserSubject) {
                const subject = new UserSubject();
                subject.userId = entryDto.subject.userId;
                newEntry.subject = subject;
            }
            newEntry.permission = new ObjectPermission(entryDto.permission.id);
            return newEntry;
        }));
    }
}