import React from "react";
import { IFormEditorComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import { MoneyBox, NumberBox } from "@CommonControls";
import NumberFormDataElement from "@Toolkit/FormEngine/Model/Schema/NumberFormDataElement";


interface IFormHufMoneyBoxProps extends IFormEditorComponentProps {

}

function FormHufMoneyBox(props: IFormHufMoneyBoxProps) {

    return (
        <MoneyBox
            {...props}
            currency="HUF" // TODO: refact this to FormMoneyBox and make currency configurable from form editor!
        />
    ); 
}

export default FormHufMoneyBox;