import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";

export function getTemporalVersionByValidity<T extends { validity?: LocalDateRange }>(items: T[], validOn: LocalDate): T {
    return items.find((value, index) => isInInterval(value.validity, validOn));
}

/** This code is duplicated at server side in Asseco.Toolkit.Common.PrimitiveDataTypes.Interval class. */
export function isInInterval(interval: LocalDateRange, value: LocalDate): boolean {
    if (LocalDate.isNullOrEmpty(interval.from) && LocalDate.isNullOrEmpty(interval.to)) {
        return true;
    }

    if (!LocalDate.isNullOrEmpty(interval.from) && interval.from.toUnix() > value.toUnix()) {
        return false;
    }

    if (!LocalDate.isNullOrEmpty(interval.to) && interval.to.toUnix() <= value.toUnix()) {
        return false;
    }

    return true;
}

export function isInCloseInterval(interval: LocalDateRange, value: LocalDate): boolean {
    if (LocalDate.isNullOrEmpty(interval.from) && LocalDate.isNullOrEmpty(interval.to)) {
        return true;
    }

    if (!LocalDate.isNullOrEmpty(interval.from) && interval.from.toUnix() > value.toUnix()) {
        return false;
    }

    if (!LocalDate.isNullOrEmpty(interval.to) && interval.to.toUnix() < value.toUnix()) {
        return false;
    }

    return true;
}
