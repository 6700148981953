import CareActivityBasedWorklistEntityType from "@Primitives/CareActivityBasedWorklistEntityType";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import {
    IDataGridColumnBaseProps,
    IDataGridColumnFilterProps
} from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import MedicalServicePriority from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalServices/Enum/MedicalServicePriority.g";
import React from "react";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import UrgencyBadge from "@HisPlatformControls/UrgencyBadge";
import MedicalServicePriorityBadge from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/MedicalServicePriorityBadge";
import CareActivityBasedWorklistPrioritySelectBox from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/CustomColumns/CareActivityBasedWorklistPrioritySelectBox";
import Urgency from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/Urgency.g";

export interface ICareActivityBasedWorklistPriority {
    value: Urgency | MedicalServicePriority;
    type: CareActivityBasedWorklistEntityType;
}

interface ICareActivityBasedWorklistPriorityColumnDependencies {
    localizationService: ILocalizationService;
}

interface ICareActivityBasedWorklistPriorityColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: ICareActivityBasedWorklistPriorityColumnDependencies;
    displayMode?: "name" | "badge";
}

class CareActivityBasedWorklistPriorityColumn extends React.Component<ICareActivityBasedWorklistPriorityColumnProps> {

    private renderCareActivityUrgency(value: Urgency) {
        switch (this.props.displayMode) {
            case "name":
                const localized = isNullOrUndefined(value) ? null : this.props._dependencies.localizationService.localizeEnum(Urgency[value], "Urgency");
                return isNullOrUndefined(localized) ? null : localized.Name;
            case "badge":
            default:
                return <UrgencyBadge value={value}/>;
        }
    }

    private renderAppointmentPriority() {
        return (<></>);
    }

    private renderServiceRequestPriority(value: MedicalServicePriority) {
        switch (this.props.displayMode) {
            case "name":
                const localized = isNullOrUndefined(value) ? null : this.props._dependencies.localizationService.localizeEnum(MedicalServicePriority[value], "MedicalServicePriority");
                return isNullOrUndefined(localized) ? null : localized.Name.substr(0, 2);
            case "badge":
            default:
                return <MedicalServicePriorityBadge value={value} />;
        }
    }

    @State.bound
    private valueRenderer(value: ICareActivityBasedWorklistPriority) {
        switch (value.type) {
            case 1:
                return this.renderCareActivityUrgency(value.value as Urgency);
            case 2:
                return this.renderAppointmentPriority();
            case 3:
                return this.renderServiceRequestPriority(value.value as MedicalServicePriority);
            default:
                return <></>;
        }
    }

    public render() {
        const {children, ...columnProps} = this.props;
        return (
            <DataGridColumn
                width={this.props.displayMode === "badge" ? 40 : undefined}
                onRenderCellValue={this.valueRenderer}
                onRenderFilter={this.filterRenderer}
                showHint={false}
                {...columnProps}
            />
        );
    }

    private filterRenderer(filterProps: IDataGridColumnFilterProps<CareActivityBasedWorklistEntityType | CareActivityBasedWorklistEntityType[]>) {
        return (
            <CareActivityBasedWorklistPrioritySelectBox
                {...filterProps}
                displayMode="selectBox"
                multiSelect
                hoverOnlyIndicatorIcons
                automationId="careActivityBasedWorklistPriorityFilterSelectBox"
            />
        );
    }
}

export default connect(
    CareActivityBasedWorklistPriorityColumn,
    new DependencyAdapter<ICareActivityBasedWorklistPriorityColumnProps, ICareActivityBasedWorklistPriorityColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
