import React from "react";
import Icon, { iconNameType } from "@CommonControls/Icon";
import { getIconNameByHealthcareProfessions } from "@HisPlatform/BoundedContexts/Organization/Components/Helpers/IconTypeNameHelper";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import HealthcareProfessionId from "@Primitives/HealthcareProfessionId.g";

interface IOrganizationUnitIconDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IOrganizationUnitIconProps {
    _dependencies?: IOrganizationUnitIconDependencies;
    definitionCode: string;
    healthcareProfessionIds: HealthcareProfessionId[];
}

const iconMap: { [definitionCode: string]: iconNameType } = {
    Root: "conservator",
    Institute: "institution",
    Site: "sites",
    PointOfCare: "pin"
};


const OrganizationUnitIcon: React.FC<IOrganizationUnitIconProps> = props => {
    const iconName: iconNameType = iconMap[props.definitionCode] ||
        (getIconNameByHealthcareProfessions(props.healthcareProfessionIds?.map(props._dependencies.organizationReferenceDataStore.healthCareProfession.get)));

    return iconName && (
        <Icon
            iconName={iconName}
            style={{ marginRight: 5 }}
        />
    );
};

export default connect(
    OrganizationUnitIcon,
    new DependencyAdapter<IOrganizationUnitIconProps, IOrganizationUnitIconDependencies>(container => {
        return {
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore")
        };
    })
);
