import React, { useMemo, useCallback, useContext } from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import WorklistTypeBoundNDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/WorklistTypeBoundNDataPanel";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { getUseCaseFromNDataUseCaseStateAsUrlParam, parseUseCaseStateFromUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import PatientId from "@Primitives/PatientId.g";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import FilterConditionConverters from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/FilterConditionConverters";
import IRatFormPatientBasedRouteParams from "@HunEHealthInfrastructurePlugin/UseCases/CareUseCase/RatForm/IRatFormRouteParams";

function RatFormPage(props: IRoutingFrameContentProps<IRatFormPatientBasedRouteParams>) {

    const [patientId, selectedId, useCaseState] = useMemo(() => {
        const routeParameters = props.routingController.currentRoute.parameters;
        return [
            new PatientId(routeParameters.patientId),
            isNullOrUndefined(routeParameters.selectedId) ? null : new FormInstanceId(routeParameters.selectedId),
            parseUseCaseStateFromUrlParam(routeParameters.useCase)
        ];
    }, [props.routingController.currentRoute.parameters.patientId, props.routingController.currentRoute.parameters.selectedId, props.routingController.currentRoute.parameters.useCase]);

    const setState = useCallback((id: string, newUseCaseState: INDataUseCaseState) => {
        props.routingController.replace(props.routingController.currentRoute.definition.makeRoute({
            selectedId: id ?? "null",
            useCase: getUseCaseFromNDataUseCaseStateAsUrlParam(newUseCaseState),
            patientId: patientId.value,
            appointmentId: null,
            mode: "read-write"
        }));
    }, [props.routingController]);

    const dynamicFilters = {};
    const conditionConverter = FilterConditionConverters[WorklistConditionType.String];
    dynamicFilters["FormField_RatForm.Patient"] = conditionConverter(patientId.value);

    return (
        <SingleLayout>
            <WorklistTypeBoundNDataPanel
                worklistDefinitionReference="RatFormForPatient"
                cacheKey={`PatientId_${patientId.value}`}
                dynamicFilters={dynamicFilters}
                onChange={setState}
                useCaseState={useCaseState}
                selectedRowId={selectedId?.value}
                disableDetailStrictMode
                hasPrintButton={false}
                hasRefreshButton
            />
        </SingleLayout>
    );
}
export default withLoadingBoundary(RatFormPage);