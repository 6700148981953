import autobind from "autobind-decorator";
import { observer, Observer } from "mobx-react";
import * as Mobx from "mobx";
import * as MobxUtils from "mobx-utils";
import { loadingState, boundLoadingState } from "@Toolkit/ReactClient/Common/LoadingStateDecorator";
import { useObserver } from "mobx-react-lite";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import ReactionComponent from "@Toolkit/ReactClient/Common/ReactionComponent";
import { promisedComputed, asyncComputed, PromisedComputedValue as OrigPromisedComputedValue } from "computed-async-mobx";

export type IObservableArray<T> = Mobx.IObservableArray<T>;
export type IObservableValue<T> = Mobx.IObservableValue<T>;
export type IPromiseBasedObservable<T> = MobxUtils.IPromiseBasedObservable<T>;
export type ObservableMap<TKey, TValue> = Mobx.ObservableMap<TKey, TValue>;
export type ObservableSet<TKey> = Mobx.ObservableSet<TKey>;
export type ReactionDisposer = () => void;
export type PromisedComputedValue<T> = OrigPromisedComputedValue<T>;
export type IReactionDisposer = Mobx.IReactionDisposer;

const configureStrictMode = () => {
    Mobx.configure({
        enforceActions: "observed"
    });
};

const computed: Mobx.IComputed & { valueWrapper?: Mobx.IComputed } = Mobx.computed;
computed.valueWrapper = Mobx.computed({ equals: ValueWrapper.equals });

function createObservableShallowArray<T>(initialValues: T[]) {
    return Mobx.observable.array<T>(initialValues, {
        deep: false
    });
}

function createObservableShallowSet<T>(initialValues: T[]) {
    return Mobx.observable.set<T>(initialValues, {
        deep: false
    });
}

function createObservableShallowMap<TKey, TValue>(initialValues?: [[TKey, TValue]]) {
    return Mobx.observable.map<TKey, TValue>(initialValues ?? [], {
        deep: false
    });
}

const defer = (actionToDefer: () => void) => {
    setTimeout(() => {
        actionToDefer();
    }, 0);
};

const deferAsync = () => {
    return new Promise((resolve: (result: void) => void) => {
        setTimeout(() => {
            resolve();
        }, 0);
    });
};


const State = {
    bound: autobind,
    observer,
    Observer,
    observable: Mobx.observable,
    action: Mobx.action,
    runInAction: Mobx.runInAction,
    computed,
    reaction: Mobx.reaction,
    Reaction: ReactionComponent,
    observe: Mobx.observe,
    extendObservable: Mobx.extendObservable,
    autorun: Mobx.autorun,
    when: Mobx.when,
    loadingState,
    boundLoadingState,
    transaction: Mobx.transaction,
    isObservableArray: Mobx.isObservableArray,
    trace: Mobx.trace,
    useObserver,
    configureStrictMode,
    spy: Mobx.spy,
    fromPromise: MobxUtils.fromPromise,
    promisedComputed,
    asyncComputed,
    createObservableShallowArray,
    createObservableShallowMap,
    createObservableShallowSet,
    defer,
    deferAsync,
    computedFunction: MobxUtils.computedFn,
};

export default State;
