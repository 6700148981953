import StackTrace from "stacktrace-js";

export async function mapStackAsync(error: Error): Promise<string> {
    if (!error.stack) {
        return "no-stack";
    }
    
    try {
        const mappedStack = await StackTrace.fromError(error);
        return mappedStack.map(s => `${s.getFileName()}:${s.getLineNumber()} (fn: ${s.getFunctionName()})`).join("\r\n");
    } catch (err) {
        return error.stack;
    }
}