import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IReportDefinition } from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/Model/IReportDefinition";
import ReportingApiAdapter from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/ReportingApiAdapter";

export default class ReportingDefinitionRefernceDataStore {
    @State.observable.ref private map: Map<string, IReportDefinition> = null;

    @State.computed public get items() {
        if (!!this.map) {
            return Array.from(this.map.values());
        } else {
            return null;
        }
    }

    constructor(
        private readonly reportingApiAdapter: ReportingApiAdapter
    ) {
    }

    @State.bound
    public async ensureLoadedAsync() {
        if (!this.map) {
            const result = await this.reportingApiAdapter.getReportDefinitionsAsync();

            State.runInAction(() => {
                this.map = new Map<string, IReportDefinition>(
                    result.value.map(i => [i.reportDefinitionIdentifier.value, i])
                );
            });
        }
    }

    @State.bound
    public tryGetById(id: string) {
        if (this.map?.has(id)) {
            return this.map.get(id);
        } else {
            return null;
        }
    }
}