import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import SchedulingServiceFilter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceFilter";
import SchedulingServiceFilterPanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/SchedulingServiceFilterPanel/SchedulingServiceFilterPanel";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import React from "react";
import * as Ui from "@CommonControls";
import SchedulePlan from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulePlan";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import ScheduledAppointmentStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ScheduledAppointmentStore";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import PointOfCareColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";
import SchedulingServiceColumn from "@HisPlatform/BoundedContexts/Scheduling/Components/Controls/SchedulingServiceColumn";
import PractitionerColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerColumn/PractitionerColumn";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import { IRowCheckState } from "@CommonControls/DataGrid/IDataGridProps";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import PatientId from "@Primitives/PatientId.g";
import DurationBox from "@CommonControls/DurationBox/DurationBox";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";

interface IScheduleAppointmentsDialogDependencies {
    schedulingApiAdapter: SchedulingApiAdapter;
    dialogService: IDialogService;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
    notificationService: INotificationService;
}

export interface IScheduleAppointmentsDialogProps {
    _dependencies?: IScheduleAppointmentsDialogDependencies;
    _patientId?: PatientId;
    onScheduledAppointmentsCreated: () => Promise<void>;
}

/** @screen */
@State.observer
class ScheduleAppointmentsDialog extends React.Component<IScheduleAppointmentsDialogProps> {

    private readonly resources = StaticSchedulingResources.ScheduleAppointmentsDialog;

    private createIdentifierAsync = () => Promise.resolve(new SchedulingServiceFilter());

    @State.observable.ref private schedulePlan: SchedulePlan = new SchedulePlan();
    @State.observable.ref public validationResults: IClientValidationResult[] = [];
    @State.observable.ref private closeEvent = new TypedEvent<boolean>();
    private scheduledAppointments = State.createObservableShallowArray([]);
    @State.observable private scheduledAppointmentsCreated: boolean = false;
    @State.observable private isLoading: boolean = false;

    @State.computed public get hasSelectedItems() {
        return !!this.scheduledAppointments && this.scheduledAppointments.some(i => i.isSelectedForCreation);
    }

    private get schedulingApiAdapter() {
        return this.props._dependencies.schedulingApiAdapter;
    }

    public get hasValidationError() {
        return !arrayIsNullOrEmpty(this.validationResults) && this.validationResults.some(
            r => r.problems && r.problems.some(p => p.severity === "error"));
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.initializeAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.action.bound
    private async initializeAsync() {
        await this.loadAllSchedulesAsync();
    }

    @State.action.bound
    private async loadAllSchedulesAsync() {
        const dataStore = this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleSlotSeries;
        await dataStore.ensureAllLoadedAsync();
    }

    @State.action.bound
    private async validateAsync() {
        const response = await this.schedulingApiAdapter.scheduleAppointments(this.schedulePlan, true);
        return response.value.validationResults;
    }

    @State.boundLoadingState()
    private async scheduleAppointmentsAsync() {
        const response = await this.schedulingApiAdapter.scheduleAppointments(this.schedulePlan, false);

        State.runInAction(() => {
            this.scheduledAppointments.replace(response.value.scheduledAppointments);
            this.validationResults = response.value.validationResults;
        });
    }

    @State.boundLoadingState()
    private async createScheduledAppointmentsAsync() {
        const scheduledAppointmentsForCreation = this.scheduledAppointments.filter(i => i.isSelectedForCreation);
        const scheduledAppointmentsForCancellation = this.scheduledAppointments.filter(i => i.isBooked && !i.isSelectedForCreation);

        await this.schedulingApiAdapter.createScheduledAppointmentsAsync(scheduledAppointmentsForCreation, scheduledAppointmentsForCancellation, this.props._patientId);

        State.runInAction(() => {
            this.scheduledAppointmentsCreated = true;
        });

        this.props.onScheduledAppointmentsCreated();
        this.close();
    }

    @State.action.bound
    private async cancelScheduledAppointmentsSlotBookingsAsync() {
        if (!!this.scheduledAppointments) {
            await this.schedulingApiAdapter.cancelScheduledAppointmentsSlotBookingsAsync(this.scheduledAppointments);
        }
    }

    @State.action.bound
    private cancelScheduledAppointmentsSlotBookings() {
        if (!this.scheduledAppointmentsCreated) {
            dispatchAsyncErrors(this.cancelScheduledAppointmentsSlotBookingsAsync(), this);
        }
    }

    @State.bound
    private close() {
        this.closeEvent.emit(false);
    }

    @State.bound
    private rowCheckState(row: ScheduledAppointmentStore): IRowCheckState {
        const isCreateable = row.isBooked;
        return { isChecked: row.isSelectedForCreation, isVisible: isCreateable, isDisabled: false } as IRowCheckState;
    }

    @State.action.bound
    private checkRow(isChecked: boolean, row: ScheduledAppointmentStore) {
        row.isSelectedForCreation = isChecked;
    }

    @State.bound
    private handleTelecommunicationCallCreationError() {
        dispatchAsyncErrors(this.handleTelecommunicationCallCreationErrorAsync(), this);
        return true;
    }

    @State.bound
    private async handleTelecommunicationCallCreationErrorAsync() {
        await this.cancelScheduledAppointmentsSlotBookingsAsync();
        this.props._dependencies.notificationService.showCannotSaveBecauseOfErrors(StaticSchedulingResources.BusinessErrors.TelecommunicationCallCreationErrorMessage);
        this.close();
        return true;
    }

    @State.bound
    public renderSchedulingServiceFilterItem(schedulingServiceFilter: SchedulingServiceFilter, index: number) {
        return (
            <SchedulingServiceFilterPanel
                schedulingServiceFilter={schedulingServiceFilter}
                index={index}
                readonly={false}
            />
        );
    }

    @State.bound
    public renderSchedulePlan() {
        return (
            <ValidationBoundary
                entityTypeName="ScheduledAppointments"
                validationResults={this.validationResults}
                validateOnMount
                onValidateAsync={this.validateAsync}>

                <Ui.GroupBox title={this.resources.GeneralSettingsTitle}>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <Ui.DateTimePicker
                                label={this.resources.IntervalFrom}
                                value={this.schedulePlan.from}
                                onChange={this.schedulePlan.setFrom}
                                propertyIdentifier="From"
                                automationId="From"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.DateTimePicker
                                label={this.resources.IntervalTo}
                                value={this.schedulePlan.to}
                                onChange={this.schedulePlan.setTo}
                                propertyIdentifier="To"
                                automationId="To"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <DurationBox
                                label={this.resources.ScheduleLength}
                                from={this.schedulePlan.from}
                                to={this.schedulePlan.to}
                                automationId="scheduleLength"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.GroupBox>
                <Ui.GroupBox title={this.resources.SchedulingServiceFilterTitle}>
                    <ListPanel<SchedulingServiceFilter>
                        alwaysEdit
                        items={this.schedulePlan.schedulingServiceFilters}
                        renderItemEditor={this.renderSchedulingServiceFilterItem}
                        onRemoveItem={this.schedulePlan.removeSchedulingServiceFilter}
                        onCreateNewAsync={this.createIdentifierAsync}
                        propertyIdentifier="SchedulingServiceFilters"
                        isCompactEmptyState
                        automationId="schedulingServiceFilters"
                    />
                </Ui.GroupBox>
            </ValidationBoundary>
        );
    }

    @State.bound
    public renderScheduledAppointmentsList() {
        return this.scheduledAppointments && (
            <>
                <BusinessErrorHandler.Register businessErrorName="TelecommunicationCallCreationError" handler={this.handleTelecommunicationCallCreationError} />
                <Ui.DataGrid
                    dataSource={this.scheduledAppointments}
                    isSelectable={true}
                    hasHeader
                    fixedLayout
                    automationId="scheduledAppointmentsList"
                    rowCheckState={this.rowCheckState}
                    onRowChecked={this.checkRow}
                >
                    <DataGridDateTimeColumn
                        header={this.resources.ScheduledAppointmentsList.FromColumn}
                        id={"from"}
                        dataGetter={"from"}
                        nullValue={this.resources.ScheduledAppointmentsList.FromColumnNoValue}
                    />
                    <SchedulingServiceColumn
                        header={this.resources.ScheduledAppointmentsList.SchedulingServiceColumn}
                        id={"schedulingServiceId"}
                        dataGetter={"schedulingServiceId"}
                        displayScheduleDefinitionName
                    />
                    <PointOfCareColumn
                        header={this.resources.ScheduledAppointmentsList.OrganizationUnitColumn}
                        id={"organizationUnitId"}
                        dataGetter={"organizationUnitId"}
                    />
                    <PractitionerColumn
                        header={this.resources.ScheduledAppointmentsList.PractitionerColumn}
                        id={"practitionerId"}
                        dataGetter={"practitionerId"}
                    />
                </Ui.DataGrid>
            </>
        );
    }

    public render() {
        return (
            <UseCaseFrame
                title={this.resources.Title}
                modalCloseEvent={this.closeEvent}
                modalOnClose={this.cancelScheduledAppointmentsSlotBookings}
                modalSize="compact"
                modalMaxHeight={694}
                isLoading={this.isLoading}
                rightFooter={(!this.scheduledAppointments || !this.scheduledAppointments.length) ?
                    <Ui.Button
                        onClickAsync={this.scheduleAppointmentsAsync}
                        text={this.resources.ScheduleAppointmentsButton}
                        visualStyle="primary"
                        automationId="scheduleAppointmentsButton" /> :
                    <Ui.Button
                        onClickAsync={this.createScheduledAppointmentsAsync}
                        text={this.resources.CreateAppointmentsButton}
                        visualStyle="primary"
                        automationId="createAppointmentsButton"
                        disabled={!this.hasSelectedItems} />
                }
                leftFooter={<Ui.Button onClick={this.close} text={StaticWebAppResources.Common.Button.Cancel} automationId="closeButton" />}
            >
                {(!this.scheduledAppointments || !this.scheduledAppointments.length) ? this.renderSchedulePlan() : this.renderScheduledAppointmentsList()}
            </UseCaseFrame>
        );
    }
}

export default connect(
    ScheduleAppointmentsDialog,
    new DependencyAdapter<IScheduleAppointmentsDialogProps, IScheduleAppointmentsDialogDependencies>(c => ({
        schedulingApiAdapter: c.resolve("SchedulingApiAdapter"),
        dialogService: c.resolve("IDialogService"),
        schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore"),
        notificationService: c.resolve("INotificationService"),
    })),
    new PatientContextAdapter<IScheduleAppointmentsDialogProps>(c => ({
        _patientId: c.patientId
    })),
);