import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import * as Ui from "@CommonControls";
import CurativeItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/CurativeItem";
import FamilyDoctorDocumentEquipmentReference from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentEquipmentReference";
import MedicationCodeSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationCodeSelector";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import MedicationId from "@Primitives/MedicationId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ConditionCodeSelector from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ConditionCodeSelector";
import * as HisUi from "@HisPlatformControls";
import SpaService from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/SpaService";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import MedicationTypeId from "@Primitives/MedicationTypeId.g";

interface ICurativeItemListItemProps {
    item: CurativeItem;
    index: number;
}

const CurativeItemListItem: React.FC<ICurativeItemListItemProps> = props => {
    const setMedication = (value: MedicationId) => {
        const medicationVersionSelector = new EntityVersionSelector<MedicationId>(value, LocalDate.today());
        if (props.item.detail instanceof FamilyDoctorDocumentEquipmentReference) {
            props.item.detail.setMedicationVersionSelector(medicationVersionSelector);
        }
    };
    return (
        <>
            {props.item.detail instanceof FamilyDoctorDocumentEquipmentReference &&
                <>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={10}>
                            <MedicationCodeSelector
                                label={props.item.isMedicationEquipment ?
                                    StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.MedicationEquipmentNameAndForm :
                                    StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.GYFNameAndForm}
                                value={props.item.detail.medicationVersionSelector?.id}
                                propertyIdentifier="MedicationVersionSelector"
                                onChange={setMedication}
                                automationId="medicationSelector"
                                medicationTypes={[MedicationTypeId.S.value]}
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <Ui.CheckBox
                                verticalAlign="noPadding"
                                align="center"
                                displayMode="check"
                                value={props.item.isMedicationEquipment}
                                onChange={props.item.setIsMedicationEquipment}
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.IsMedicationEquipment}
                                labelPosition={"left"}
                                automationId="isMedicationEquipment"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <ConditionCodeSelector
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.ConditionCodeSelector}
                                value={props.item.conditionId}
                                onChange={props.item.setConditionId}
                                propertyIdentifier="ConditionId"
                                automationId="conditionSelector"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.TextBox
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.MonthlyPrescribedAmount}
                                value={props.item.monthlyAmount}
                                onChange={props.item.setMonthlyAmount}
                                propertyIdentifier="MonthlyAmount"
                                automationId="monthlyAmount"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <HisUi.DoctorCodeSelector
                                value={props.item.practitionerId}
                                onChange={props.item.setPractitionerId}
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.PractitionerSelector}
                                propertyIdentifier="PractitionerId"
                                automationId="practitionerSelector"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </>}
            {props.item.detail instanceof SpaService &&
                <>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={10}>
                            <Ui.TextBox
                                label={props.item.isMedicationEquipment ?
                                    StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.MedicationEquipmentNameAndForm :
                                    StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.GYFNameAndForm}
                                value={props.item.detail.serviceName}
                                onChange={props.item.detail.setServiceName}
                                propertyIdentifier="ServiceName"
                                automationId="serviceName"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <Ui.CheckBox
                                verticalAlign="noPadding"
                                align="center"
                                displayMode="check"
                                value={props.item.isMedicationEquipment}
                                onChange={props.item.setIsMedicationEquipment}
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.IsMedicationEquipment}
                                labelPosition={"left"}
                                automationId="isMedicationEquipment"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <ConditionCodeSelector
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.ConditionCodeSelector}
                                value={props.item.conditionId}
                                onChange={props.item.setConditionId}
                                propertyIdentifier="ConditionId"
                                automationId="conditionSelector"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.TextBox
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.MonthlyPrescribedAmount}
                                value={props.item.monthlyAmount}
                                onChange={props.item.setMonthlyAmount}
                                propertyIdentifier="MonthlyAmount"
                                automationId="monthlyAmount"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <HisUi.DoctorCodeSelector
                                value={props.item.practitionerId}
                                onChange={props.item.setPractitionerId}
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.PractitionerSelector}
                                propertyIdentifier="PractitionerId"
                                automationId="practitionerSelector"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.TextBox
                                label={props.item.isMedicationEquipment ?
                                    StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.ServiceCode :
                                    StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.GYFServiceCode}
                                value={props.item.detail.serviceCode}
                                onChange={props.item.detail.setServiceCode}
                                propertyIdentifier="ServiceCode"
                                automationId="serviceCode"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </>}
        </>
    );
};

export default State.observer(CurativeItemListItem);