import DependencyContainer from "@DiContainer";
import * as ReportingProxy from "@HisPlatform/BoundedContexts/Reporting/Api/Proxy.g";

DependencyContainer
.bind("IReportingClient")
.to(ReportingProxy.ReportingClient);

DependencyContainer
.bind("IStatisticClient")
.to(ReportingProxy.StatisticClient);
