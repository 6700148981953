import FamilyDoctorDocumentStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import * as Ui from "@CommonControls";
import FamilyDoctorDocumentSubject from "@Primitives/FamilyDoctorDocumentSubject";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import FamilyDoctorDocumentMedicationReference from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentMedicationReference";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import FamilyDoctorDocumentMedicationReferenceListItem from "./FamilyDoctorDocumentMedicationReferenceListItem";
import CurativeItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/CurativeItem";
import CurativeItemListItem from "./CurativeItemListItem";

interface IFamilyDoctorDocumentDetailView {
    familyDoctorDocument: FamilyDoctorDocumentStore;
    familyDoctorDocumentMedicationReferenceListItemFactory(): Promise<FamilyDoctorDocumentMedicationReference>;
    curativeItemListItemFactory(): Promise<CurativeItem>;
}


const FamilyDoctorDocumentDetailView: React.FC<IFamilyDoctorDocumentDetailView> = props => {

    const getFamilyDoctorDocumentSubjectDisplayValue = (item: FamilyDoctorDocumentSubject) => {
        switch (item) {
            case FamilyDoctorDocumentSubject.IndividualMedicationLimitReview:
                return StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Detail.IndividualMedicationLimitReview;
            case FamilyDoctorDocumentSubject.CurativeCertificate:
                return StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Detail.CurativeCertificate;
            default:
                return null;
        }
    };

    const renderFamilyDoctorDocumentMedicationReferenceListItem = (item: FamilyDoctorDocumentMedicationReference, index: number) =>
        <FamilyDoctorDocumentMedicationReferenceListItem index={index} item={item} />;

    const renderCurativeItemListItem = (item: CurativeItem, index: number) =>
        <CurativeItemListItem index={index} item={item} />;

    return (
        <Ui.Flex>
            <Ui.Flex.Item xs={12}>
                <Ui.RadioButtonGroup
                    direction="column"
                    items={[FamilyDoctorDocumentSubject.CurativeCertificate, FamilyDoctorDocumentSubject.IndividualMedicationLimitReview]}
                    value={props.familyDoctorDocument.familyDoctorDocumentSubject}
                    onChange={props.familyDoctorDocument.setFamilyDoctorDocumentSubject}
                    getDisplayValue={getFamilyDoctorDocumentSubjectDisplayValue}
                    label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.FamilyDoctorDocumentPurposeOfIssue}
                    propertyIdentifier="Subject"
                />
            </Ui.Flex.Item>
            <Ui.Flex.Item xs={12}>
                <ListPanel<FamilyDoctorDocumentMedicationReference>
                    title={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.MedicationPrescribedMonthly}
                    alwaysEdit
                    items={props.familyDoctorDocument.familyDoctorDocumentMedicationReferences}
                    renderItemEditor={renderFamilyDoctorDocumentMedicationReferenceListItem}
                    onRemoveItem={props.familyDoctorDocument.removeFamilyDoctorDocumentMedicationReferenceListItem}
                    onCreateNewAsync={props.familyDoctorDocumentMedicationReferenceListItemFactory}
                    noItemsMessage={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Detail.MedicationPrescribedMonthly.NoItemMessage}
                    automationId="__familyDoctorDocumentMedicationReferenceListItemPanel"
                    propertyIdentifier="MedicationReferencesItems"
                    isCompactEmptyState
                />
            </Ui.Flex.Item>
            <Ui.Flex.Item xs={12}>
                <ListPanel<CurativeItem>
                    title={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.MedicationEquipmentAndSpaService}
                    alwaysEdit
                    items={props.familyDoctorDocument.curativeItems}
                    renderItemEditor={renderCurativeItemListItem}
                    onRemoveItem={props.familyDoctorDocument.removeCurativeListItem}
                    onCreateNewAsync={props.curativeItemListItemFactory}
                    noItemsMessage={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Detail.MedicationEquipmentAndSpaService.NoItemMessage}
                    automationId="curativeItem"
                    propertyIdentifier="CurativeItems"
                    isCompactEmptyState
                />
            </Ui.Flex.Item>
        </Ui.Flex>
    );
};

export default State.observer(FamilyDoctorDocumentDetailView);