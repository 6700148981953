import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCases from "@Primitives/UseCases";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import ExternalLocationPanel from "@HisPlatform/BoundedContexts/Organization/Components/Panels/ExternalLocation/ExternalLocationPanel";

export default function configureExternalLocationUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2({
        identifiers: [UseCases.externalLocationDetail],
        component: ExternalLocationPanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));
            const externalLocationId = map.get("externalLocationId") ?? new ExternalLocationId("new");
            return {
                externalLocationId: externalLocationId
            };
        }
    });
}
