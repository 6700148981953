import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import React from "react";
import { ContextAwareModal } from "@HisPlatformControls";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import * as Ui from "@CommonControls";
import { ExternalLocationVersionSelector } from "@Primitives/ExternalLocationId.g";
import InsertServiceRequestResultGrid from "./InsertServiceRequestResultGrid";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import InsertServiceRequestResultDetailPanel from "./InsertServiceRequestResultDetailPanel";
import DocumentContentStore, { IDocumentContentStore } from "@CommonControls/DocumentEditor/DocumentContentStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IInsertServiceRequestResultDialogViewProps {
    serviceRequestList: ServiceRequestStore[];
    onClose: () => void;
    selected: ServiceRequestStore;
    setSelected: (row: ServiceRequestStore) => void;
    displayMode?: "inline" | "modal";
    externalLocationVersionSelectors: ExternalLocationVersionSelector[];
    selectedContent: IDocumentContentStore;
    onSaveAsync: () => Promise<void>;
}

export default class InsertServiceRequestResultDialogView extends React.PureComponent<IInsertServiceRequestResultDialogViewProps> {
    private renderCore() {
        const resources = StaticCareResources.CareRegister.CareActivityTextBlockListPanel.InsertServiceRequestResult;
        return (
            <MasterDetailLayout  onSelectedItemChange={this.props.setSelected} selectedItem={this.props.selected} >
                <MasterDetail.Master title={resources.MasterTitle}>
                    <InsertServiceRequestResultGrid
                        serviceRequestList={this.props.serviceRequestList}
                        externalLocationVersionSelectors={this.props.externalLocationVersionSelectors}
                    />
                </MasterDetail.Master>
                <MasterDetail.Detail title={resources.DetailTitle}>
                    <InsertServiceRequestResultDetailPanel contentStore={this.props.selectedContent} />
                </MasterDetail.Detail>
            </MasterDetailLayout>
        );
    }
    private renderButton() {
        return (
            <Ui.Flex xsJustify="between" verticalSpacing="none" >
                <Ui.Flex.Item>
                    <Ui.Button
                        text={StaticWebAppResources.Common.Button.Close}
                        onClick={this.props.onClose}
                        visualStyle="standard"
                        automationId="insertServiceRequestResultCloseButton"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item>
                    <Ui.Button
                        visualStyle="primary"
                        onClickAsync={this.props.onSaveAsync}
                        text={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.InsertServiceRequestResult.Buttons.Insert}
                        automationId="insertServiceRequestResultSaveButton"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    private renderModal() {
        if (this.props.displayMode === "modal") {
            return (
                <>

                    <Ui.Modal.Body>
                        {this.renderCore()}
                    </Ui.Modal.Body>

                    <Ui.Modal.Footer>
                        {this.renderButton()}
                    </Ui.Modal.Footer>

                </>
            );
        } else {
            return (
                <div>
                    {this.renderCore()}
                    {this.renderButton()}
                </div>
            );
        }
    }

    public render() {
        return (
            <ContextAwareModal
                onClose={this.props.onClose}
                isOpen
                title={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.InsertServiceRequestResult.ModalTitle}
                size="normal"
                fixedHeight={694}
            >
                {this.renderModal()}
            </ContextAwareModal>
        );
    }

}