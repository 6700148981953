import PatientId from "@Primitives/PatientId.g";
import * as WebAppBackendProxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import ImmunizationStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ImmunizationStore";
import ReferencedVaccineAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ReferencedVaccineAssignment";
import CustomVaccineAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/CustomVaccineAssignment";
import VaccineAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/VaccineAssignmentBase";
import TargetDiseaseAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/TargetDiseaseAssignmentBase";
import CustomTargetDiseaseAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/CustomTargetDiseaseAssignment";
import ImmunizationHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ImmunizationHistoryItem";
import { getHistoryItemDetailDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalConditionDtoMapper";
import VaccineDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/VaccineDetail";
import LockId from "@Toolkit/CommonWeb/Model/LockId";

export function getAddImmunizationControllerDto(source: ImmunizationStore, patientId: PatientId, isValidateOnly: boolean) {
    return new WebAppBackendProxy.AddImmunizationControllerDto({
        patientId: patientId,
        historyItemDtos: [getImmunizationHistoryItemDto(source.latestHistoryItem as ImmunizationHistoryItem)],
        vaccineAssignmentBaseDto:  getVaccineAssignmentDto(source.vaccineAssignment),
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
    });
}

export function getUpdateImmunizationControllerDto(source: ImmunizationStore, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new WebAppBackendProxy.UpdateImmunizationControllerDto({
        immunizationId: source.id,
        historyItemDtos: [getImmunizationHistoryItemDto(source.latestHistoryItem as ImmunizationHistoryItem)],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteImmunizationHistoryItemControllerDto(source: ImmunizationStore, versionNumber: number, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new WebAppBackendProxy.DeleteImmunizationHistoryItemControllerDto({
        immunizationId: source.id,
        versionNumbers: [versionNumber],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteImmunizationControllerDto(store: ImmunizationStore) {
    return new WebAppBackendProxy.DeleteImmunizationControllerDto({
        immunizationId: store.id,
        lockId: store.lockInfo?.lockId,
        extensionData: store.extensionData,
        isValidateOnly: false,
        releaseLockIfSuccessful: true
    });
}

function getVaccineAssignmentDto(source: VaccineAssignmentBase) {
    let dto;

    if (source instanceof ReferencedVaccineAssignment) {
        dto = new WebAppBackendProxy.ReferencedVaccineAssignmentDto({ vaccineId: source.vaccineId });
    } else if (source instanceof CustomVaccineAssignment) {
        dto = new WebAppBackendProxy.CustomVaccineAssignmentDto({ name: source.name });
    }

    return dto;
}

function getImmunizationHistoryItemDto(source: ImmunizationHistoryItem) {
    return new WebAppBackendProxy.ImmunizationHistoryItemDto({
        versionNumber: source.versionNumber,
        index: source.index,
        createdAt: source.createdAt,
        historyItemDetail: getHistoryItemDetailDto(source.historyItemDetail),
        immunizationDate: source.immunizationDate,
        vaccineDetail: getVaccineDetailDto(source.vaccineDetail),
        targetDiseaseAssignment: getTargetDiseaseAssignmentDto(source.targetDiseaseAssignment),
        extensionData: source.extensionData
    });
}

function getTargetDiseaseAssignmentDto(source: TargetDiseaseAssignmentBase) {
    let dto;

    if (source instanceof CustomTargetDiseaseAssignment) {
        dto = new WebAppBackendProxy.CustomTargetDiseaseAssignmentDto({ name: source.name });
    }

    return dto;
}

function getVaccineDetailDto(source: VaccineDetail) {
    return new WebAppBackendProxy.VaccineDetailDto({
        brand: source.brand,
        serialNumber: source.serialNumber
    });
}
