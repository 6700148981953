import MedicationId from "@Primitives/MedicationId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class FamilyDoctorDocumentMedicationFormulaItem {
    @State.observable.ref public medicationVersionSelector: EntityVersionSelector<MedicationId>;
    @State.observable public substanceAmount: string = "";

    @State.action.bound
    public setMedicationVersionSelector(medicationVersionSelector: EntityVersionSelector<MedicationId>) {
        this.medicationVersionSelector = medicationVersionSelector;
    }

    @State.action.bound
    public setSubstanceAmount(substanceAmount: string) {
        this.substanceAmount = substanceAmount;
    }

}