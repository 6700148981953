import React, { useMemo } from "react";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import { ScreenStateContextAdapter, ScreenStateContextStore } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import { provideDecisionSupportScreenStore, useDecisionSupportScreenStore } from "./DecisionSupportScreenStoreProvider";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import FormPanel from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";
import ToolbarButtons from "@Toolkit/ReactClient/Components/ToolbarButtons/ToolbarButtons";
import { LockIndicatorComponent } from "@HisPlatform/Components/HisPlatformControls";
import StaticAssecoMedWebAppResources from "@AssecoMedPlugin/StaticResources/StaticAssecoMedWebAppResources";
import ShowCreateNewDecisionSupportScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowCreateNewDecisionSupportScreenAction.g";
import ShowEditDecisionSupportScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowEditDecisionSupportScreenAction.g";
import ShowReadOnlyDecisionSupportScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowReadOnlyDecisionSupportScreenAction.g";
import Button from "@CommonControls/Button";

export interface IDecisionSupportScreenProps extends IScreenPropsBase<ShowCreateNewDecisionSupportScreenAction | ShowEditDecisionSupportScreenAction | ShowReadOnlyDecisionSupportScreenAction> {
    _screenState?: ScreenStateContextStore;
    _modalService?: IModalService;
}

function DecisionSupportScreen(props: IDecisionSupportScreenProps) {
    const store = useDecisionSupportScreenStore();

    const continueOrStartSessionButtonText = useMemo(() => {
        return !!store.externalDecisionSupportId
            ? StaticAssecoMedWebAppResources.DecisionSupportScreen.Buttons.ContinueSession
            : StaticAssecoMedWebAppResources.DecisionSupportScreen.Buttons.StartSession;
    }, [store.externalDecisionSupportId]);

    const title = useMemo(() => {
        return !!store.decisionSupportModelName
            ? store.decisionSupportModelName
            : StaticAssecoMedWebAppResources.DecisionSupportScreen.Title;
    }, [store.decisionSupportModelName]);

    return (
        <Screen
            title={title}
            toolbar={
                <>
                    <ToolbarButtons
                        beforeButtons={store.isEditDecisionSupportScreen && store.canAcquireLock && (
                            <LockIndicatorComponent
                                locked={true}
                                onLoadClickedAsync={store.loadAsync}
                                lockedBy={store.lockInfo?.preventingLockUserId}
                                preventingLockId={store.lockInfo?.preventingLockId} />
                        )}
                        betweenButtons={store.isEditDecisionSupportScreen && !store.canAcquireLock && (
                            <Button
                                text={continueOrStartSessionButtonText}
                                onClickAsync={store.continueOrStartSessionAsync}
                                automationId="__continueSession" />
                        )}
                        onCancelAsync={store.onCancelAsync}
                        saveButtonVisible={(!store.canAcquireLock && store.isEditDecisionSupportScreen) || store.isNewDecisionSupportScreen}
                        saveActionsDescriptor={store.saveDecisionSupportAction}
                        onExecuteSaveAsync={store.onSaveAsync}
                    />
                </>
            }>
            <ValidationBoundary
                entityTypeName="CareActivityBaseData"
                validationResults={store.validationResults}
                onValidateAsync={store.onValidateAsync}
                validateOnMount>
                <FormPanel definitionId={store.formDefinitionId} form={store.form} onChange={store.onDataChangeAsync.fireAndForget} isReadOnly={store.vIsReadOnly} />
            </ValidationBoundary>
        </Screen>
    );
}

export default connect<IDecisionSupportScreenProps>(
    provideDecisionSupportScreenStore(DecisionSupportScreen),
    new ScreenStateContextAdapter(),
    new HisModalServiceAdapter()
);