import React, { useMemo, useCallback } from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import DateTimePicker from "@CommonControls/DateTimePicker/DateTimePicker";
import moment from "moment";
import { SelectBoxCore } from "@CommonControls/SelectBox";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";

interface IMonthPickerProps extends ISelectBoxBaseProps {
    valueOnClear?: YearMonth;
    value: YearMonth;
    onChange: (newValue: YearMonth) => void;
    forwardedRef?: React.Ref<SelectBoxCore>;
}


const MonthPicker: React.FC<IMonthPickerProps> = props => {

    const value = useMemo(() => props.value && props.value.toMoment(), [props.value]);
    const changeHandler = useCallback((newValue: moment.Moment) => {
        if (props.onChange) {
            props.onChange(YearMonth.createFromMoment(newValue));
        }
    }, [props.onChange]);

    return (
        <DateTimePicker
            {...props}
            value={value}
            onChange={changeHandler}
            monthOnlyMode
            forwardedRef={props.forwardedRef}
        />
    );
};

export default React.forwardRef<SelectBoxCore, IMonthPickerProps>((props, ref) => <MonthPicker {...props} forwardedRef={ref} />);