import React from "react";
import IMasterDetailState from "@CommonControls/Layout/IMasterDetailState";
import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";
import { emptyObject } from "@Toolkit/CommonWeb/NullCheckHelpers";

export interface IMasterDetailContext {
    parent: IMasterDetailContext | null;
    state: IMasterDetailState;
    detailChildren: React.ReactNode;
    compactListSize: number | string;
}

const MasterDetailContext = React.createContext<IMasterDetailContext | null>(null);

export class MasterDetailContextAdapter<TProps> implements IComponentAdapter<TProps> {
    public get usedContexts(): Array<React.Context<any>> {
        return [MasterDetailContext];
    }

    constructor(
        private readonly mapToProps: (props: TProps, value: IMasterDetailContext) => Partial<TProps>
    ) { }

    public getMappedProps(props: TProps, contextValues?: Map<React.Context<any>, any>): Partial<TProps> {
        const contextValue: IMasterDetailContext | null = contextValues.get(MasterDetailContext);
        if (!contextValue) {
            return emptyObject;
        }
        return this.mapToProps(props, contextValue);
    }
}

export {
    MasterDetailContext
};