import React from "react";
import IOncologyWidgetDisplayData from "@HunEHealthInfrastructurePlugin/Common/Components/Widgets/OncologyWidget/IOncologyWidgetDisplayData";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Styles from "./OncologyDataContainer.less";
import * as Ui from "@CommonControls";
import OncologyDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/OncologyDataStore";
import IConditionVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IConditionVersion";
import CancerClassificationDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CancerClassificationDataStore";

interface IOncologyDataContainerProps {
    data: IOncologyWidgetDisplayData;
    openModal: (dataStore: OncologyDataStore, condition: IConditionVersion, cancerClassificationDataStore: CancerClassificationDataStore) => void;
}


const OncologyDataContainer: React.FC<IOncologyDataContainerProps> = (props) => {

    const openModal = () => {
        props.openModal(props.data.oncologyDataStore, props.data.condition, props.data.cancerClassificationDataStore);
    };

    return (
        <div className={Styles.dataContainer}>
            <Ui.Flex>
                <Ui.Flex.Item xs={9}>
                    <b>{props.data.condition.code} - {props.data.condition.name}</b><br />
                    {props.data.diagnosisDate}
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={3}>
                    <Ui.Button
                        className={Styles.openButton}
                        iconName={"angleRight"}
                        onClick={openModal}
                        visualStyle={"standard"}
                        visualMode="dark"
                        float={"right"}
                        automationId={props.data.id + "_openModalButton"}
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </div>
    );
};

export default OncologyDataContainer;