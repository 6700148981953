import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCases from "@Primitives/UseCases";
import PregnancyStatementPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/PregnancyStatement/PregnancyStatementPanel";
import PregnancyStatementId from "@Primitives/PregnancyStatementId.g";

export default function configurePregnancyStatementUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2({
        identifiers: [UseCases.pregnancyStatementDetail],
        component: PregnancyStatementPanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));
            const id = map.get("pregnancyStatementId") ?? new PregnancyStatementId("new");
            return {
                id: id,
            };
        }
    });
}
