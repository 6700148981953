import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import MedicationStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/MedicationStatementStore";
import MedicationStatementId from "@Primitives/MedicationStatementId.g";
import MedicationStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/MedicationStatementHistoryItem";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import ReferencedMedicationAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/ReferencedMedicationAssignment";
import ReferencedSubstanceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/ReferencedSubstanceAssignment";
import ReferencedInternationalNonproprietaryNameAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/ReferencedInternationalNonproprietaryNameAssignment";
import CustomSubjectAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/CustomSubjectAssignment";
import TreatmentDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/TreatmentDetail";
import StatementSubjectDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/StatementSubjectDetail";

type ResponseType = Proxy.GetMedicationStatementByIdCommandResponse | Proxy.AddMedicationStatementCommandResponse | Proxy.UpdateMedicationStatementCommandResponse | Proxy.DeleteMedicationStatementHistoryItemCommandResponse;

@Di.injectable()
export default class MedicationStatementStoreMapper extends LockingEntityStoreMapper<Proxy.MedicationStatementDto, MedicationStatementStore, ResponseType> {

    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    protected storeEntityIdType = MedicationStatementId;

    protected entityDtoSelector = (response: ResponseType) => {
        return response.medicationStatementDto;
    };

    protected applyToStoreCore(target: MedicationStatementStore, response: any) {
        const dto = this.entityDtoSelector(response);
        if (dto != null) {
            target.id = dto.id;
            target.medicationStatementSubjectAssignment = this.getMedicationStatementSubjectAssignment(dto);

            target.historyItems = State.observable(this.getHistoryItems(dto.historyItems));
            target.currentItemIndex = target.historyItems.length - 1;
            target.patientId = dto.patientId;
            target.extensionData = dto.extensionData;
        }
    }

    private getHistoryItems(dtoArray: Proxy.MedicationStatementHistoryItemDto[]): MedicationStatementHistoryItem[] {
        return dtoArray.map(dto => this.getHistoryItem(dto)).sort((a, b) => a.versionNumber - b.versionNumber);
    }

    private getHistoryItem(dto: Proxy.MedicationStatementHistoryItemDto): MedicationStatementHistoryItem {
        const returnItem = new MedicationStatementHistoryItem();

        returnItem.historyItemDetail = this.getHistoryItemDetail(dto.historyItemDetail);
        returnItem.statementSubjectDetail = this.getStatementSubjectDetail(dto);
        returnItem.treatmentDetail = this.getTreatmentDetail(dto);
        returnItem.versionNumber = dto.versionNumber;
        returnItem.index = dto.index;
        returnItem.createdAt = dto.createdAt;
        returnItem.extensionData = dto.extensionData;

        return returnItem;
    }

    private getHistoryItemDetail(dto: Proxy.MedicalConditionHistoryItemDetailDto): MedicalConditionHistoryItemDetail {
        const returnItem = new MedicalConditionHistoryItemDetail();

        returnItem.asserter = dto.asserter;
        returnItem.asserterType = dto.asserterType;
        returnItem.notes = dto.notes;
        returnItem.verifier = dto.verifier;

        return returnItem;
    }

    private getMedicationStatementSubjectAssignment(dto: Proxy.MedicationStatementDto) {
        let medicationStatementSubjectAssignment;

        if (dto.medicationStatementSubjectAssignment instanceof Proxy.ReferencedMedicationAssignmentDto) {
            medicationStatementSubjectAssignment = new ReferencedMedicationAssignment(dto.medicationStatementSubjectAssignment.medicationId);
        } else if (dto.medicationStatementSubjectAssignment instanceof Proxy.ReferencedSubstanceAssignmentDto) {
            medicationStatementSubjectAssignment = new ReferencedSubstanceAssignment(dto.medicationStatementSubjectAssignment.substanceId);
        } else if (dto.medicationStatementSubjectAssignment instanceof Proxy.ReferencedInternationalNonproprietaryNameAssignmentDto) {
            medicationStatementSubjectAssignment = new ReferencedInternationalNonproprietaryNameAssignment(dto.medicationStatementSubjectAssignment.internationalNonproprietaryNameId);
        } else if (dto.medicationStatementSubjectAssignment instanceof Proxy.CustomSubjectAssignmentDto) {
            medicationStatementSubjectAssignment = new CustomSubjectAssignment(dto.medicationStatementSubjectAssignment.name);
        }

        return medicationStatementSubjectAssignment;
    }

    private getStatementSubjectDetail(dto: Proxy.MedicationStatementHistoryItemDto) {
        return new StatementSubjectDetail(dto.statementSubjectDetail.dosageAmount, dto.statementSubjectDetail.dosageForm, dto.statementSubjectDetail.efficacy, dto.statementSubjectDetail.intakeFrequency);
    }

    private getTreatmentDetail(dto: Proxy.MedicationStatementHistoryItemDto) {
        return new TreatmentDetail(dto.treatmentDetail.length, dto.treatmentDetail.startDate);
    }
}
