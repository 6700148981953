import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IDataGridColumnChildProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import * as Ui from "@CommonControls";
import TableTokenValueFormatterSettingStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TableTokenValueFormatterSettingStore";

interface ITableTokenValueFormatterSettingOrderCellProps extends IDataGridColumnChildProps<TableTokenValueFormatterSettingStore, any> {
}

/** @screen */
@State.observer
export class TableTokenValueFormatterSettingOrderCell extends React.Component<ITableTokenValueFormatterSettingOrderCellProps> {
    public render() {
        return <Ui.NumberBox value={this.props.row.order} onChange={this.props.row.setOrder} />;
    }
}
