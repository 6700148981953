import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import PerformanceStatementTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementTypeSelectBox/PerformanceStatementTypeSelectBox";
import PerformanceStatementType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementType.g";

interface IPerformanceStatementTypeColumnDependencies {
    localizationService: ILocalizationService;
}

interface IPerformanceStatementTypeColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IPerformanceStatementTypeColumnDependencies;
    displayMode?: "name";
}


const PerformanceStatementTypeColumn: React.FC<IPerformanceStatementTypeColumnProps> = (props: IPerformanceStatementTypeColumnProps) => {

    const valueRenderer = useCallback((value: PerformanceStatementType) => {

        switch (props.displayMode) {
            case "name":
                const localized = isNullOrUndefined(value) ? null : props._dependencies.localizationService.localizeEnum(PerformanceStatementType[value], "PerformanceStatementType");
                return isNullOrUndefined(localized) ? null : localized.Name;
        }
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<PerformanceStatementType | PerformanceStatementType[]>) => {
        return (
            <PerformanceStatementTypeSelectBox
                {...filterProps}
                multiSelect
                maxMenuWidth={commonMenuMaxWidth}
                hoverOnlyIndicatorIcons
            />
        );
    }, []);

    return (
        <DataGridColumn
            width={undefined}
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            showHint={false}
        />
    );
};

export default connect(
    PerformanceStatementTypeColumn,
    new DependencyAdapter<IPerformanceStatementTypeColumnProps, IPerformanceStatementTypeColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
