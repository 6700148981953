import React, { useEffect } from "react";
import SideToolbox from "./SideToolbox";
import WorkingArea from "./WorkingArea";
import { Split } from "@geoffcox/react-splitter";
import Styles from "./FormLayoutEditor.less";
import IFormLayoutEditorProps from "./IFormLayoutEditorProps";
import { provideFormLayoutEditorStore, useFormLayoutEditorStore } from "./FormLayoutEditorStoreProvider";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ModalServiceAdapter from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ScrollView from "@CommonControls/ScrollView/ScrollView";

function FormLayoutEditor(props: IFormLayoutEditorProps) {

    const store = useFormLayoutEditorStore();

    useEffect(() => {
        props.handle.attach(store.getDefinition, store.setDefinition, store.getEditorChanges, store.resetEditorChanges);
        return props.handle.detach;
    }, [props.handle]);

    return (
        <div className={Styles.root}>
            <DndProvider backend={HTML5Backend}>
                <Split minPrimarySize="15%" minSecondarySize="50%" initialPrimarySize="25%">
                    <ScrollView>
                        <SideToolbox />
                    </ScrollView>
                    <ScrollView>
                        <WorkingArea />
                    </ScrollView>
                </Split>
            </DndProvider>
        </div>
    );
}

export default connect<IFormLayoutEditorProps>(provideFormLayoutEditorStore(FormLayoutEditor),
    new ModalServiceAdapter()
);