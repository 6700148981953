import React from "react";

interface ISeparatorProps {
    thickness?: number;
}


const Separator: React.SFC<ISeparatorProps> = (props) => {
    return (
        <hr style={{height: props.thickness, margin: "8px 4px"}} />
    );
};

export default Separator;