import IStore from "./IStore";
import IOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/IOperationInfo";
import CommandOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/CommandOperationInfo";
import QueryOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/QueryOperationInfo";
import LockAcquirerOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockAcquirerOperationInfo";
import RequestStatus from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/RequestStatus";

export default abstract class StoreBase implements IStore {

    public operationInfo: IOperationInfo = null;

    public updateOperationInfo(operationInfo: IOperationInfo) {
        if (operationInfo) {
            this.operationInfo = operationInfo;
        }
    }

    public updateOperationInfoFromStore(store: IStore) {
        if (store) {
            this.updateOperationInfo(store.operationInfo);
        }
    }

    public get isPersistedByOperationInfo() {
        const opInfo = this.operationInfo;
        return opInfo && (
            (opInfo instanceof CommandOperationInfo && opInfo.isPersisted) ||
            (opInfo instanceof QueryOperationInfo) ||
            (opInfo instanceof LockAcquirerOperationInfo && opInfo.isPersisted)
        );
    }

    public get operationWasSuccessful() {
        return this.operationInfo && this.operationInfo.requestStatus === RequestStatus.Success;
    }
}