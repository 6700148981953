import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Proxy.g";
import PerformanceReport from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReport";
import WellKnownReferenceCodes from "@HunSocialSecurityPlugin/Common/WellKnownReferenceCodes";
import PerformanceReportGroupingType from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportGroupingType";
import PerformanceReportIntervalGrouping from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportIntervalGrouping";
import PerformanceReportEnumBasedGrouping from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportEnumBasedGrouping";
import HealthcareProfession from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/HealthcareProfession";
import PerformanceReportId from "@Primitives/PerformanceReportId.g";
import InstituteGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/InstituteGroupingField.g";
import SiteGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/SiteGroupingField.g";
import AmbulanceGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/AmbulanceGroupingField.g";
import PointOfCareGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/PointOfCareGroupingField.g";
import FinancingClassGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/FinancingClassGroupingField.g";
import CareDoctorGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/CareDoctorGroupingField.g";
import CareAssistantGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/CareAssistantGroupingField.g";
import ReferralWorkplaceGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/ReferralWorkplaceGroupingField.g";
import ServiceGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/ServiceGroupingField.g";

export default class PerformanceReportDtoMapper {

    public getAddPerformanceReportDto(performanceReport: PerformanceReport, professions: HealthcareProfession[], isValidateOnly: boolean = false) {
        return new Proxy.AddPerformanceReportControllerDto({
            name: performanceReport.name,
            parameters: this.getPerformanceReportParametersDto(performanceReport, professions),
            isValidateOnly: isValidateOnly
        } as Proxy.IAddPerformanceReportControllerDto);
    }

    public getUpdatePerformanceReportDto(performanceReport: PerformanceReport, professions: HealthcareProfession[], isValidateOnly: boolean = false) {
        return new Proxy.UpdatePerformanceReportControllerDto({
            performanceReportId: performanceReport.id,
            name: performanceReport.name,
            parameters: this.getPerformanceReportParametersDto(performanceReport, professions),
            isValidateOnly: isValidateOnly
        } as Proxy.IUpdatePerformanceReportControllerDto);
    }

    public getDeletePerformanceReportDto(performanceReportId: PerformanceReportId) {
        return new Proxy.DeletePerformanceReportControllerDto({
            id: performanceReportId
        } as Proxy.IDeletePerformanceReportControllerDto);
    }

    public getPerformanceReportParametersDto(performanceReport: PerformanceReport, professions: HealthcareProfession[]) {
        const filters = [];

        if (performanceReport.wentUnderCareAtDateRange && performanceReport.wentUnderCareAtDateRange.from && performanceReport.wentUnderCareAtDateRange.to) {
            const dateFilter = new Proxy.PerformanceReportWentUnderCareAtDateFilterDto({
                from: performanceReport.wentUnderCareAtDateRange.from,
                to: performanceReport.wentUnderCareAtDateRange.to
            } as Proxy.IPerformanceReportWentUnderCareAtDateFilterDto);
            filters.push(dateFilter);
        }

        if (performanceReport.insurerOrganization) {
            const insurerOrganizationFilter = new Proxy.PerformanceReportInsurerOrganizationFilterDto({
                insurerOrganizationId: performanceReport.insurerOrganization
            } as Proxy.IPerformanceReportInsurerOrganizationFilterDto);
            filters.push(insurerOrganizationFilter);
        }

        if (performanceReport.pointOfCares && performanceReport.pointOfCares.length > 0) {
            const pointOfCareFilter = new Proxy.PerformanceReportPointOfCareFilterDto({
                pointOfCareIds: performanceReport.pointOfCares
            } as Proxy.IPerformanceReportPointOfCareFilterDto);
            filters.push(pointOfCareFilter);
        }

        if (performanceReport.healthcareProfessions && performanceReport.healthcareProfessions.length > 0) {
            const healthcareProfessionsFilter = new Proxy.PerformanceReportProfessionCodeFilterDto({
                healthCareProfessionIds: performanceReport.healthcareProfessions,
                codes: performanceReport.healthcareProfessions.map(x => professions.filter(y => y.id.value === x.value)[0].getCodeValueByIdentifierSystem(WellKnownReferenceCodes.HunSocialSecurityInsuranceCode))
            } as Proxy.IPerformanceReportProfessionCodeFilterDto);
            filters.push(healthcareProfessionsFilter);
        }

        if (performanceReport.referralWorkPlaceTypes && performanceReport.referralWorkPlaceTypes.length > 0) {
            const referralWorkplaceTypeFilter = new Proxy.PerformanceReportReferralWorkplaceTypeFilterDto({
                ids: performanceReport.referralWorkPlaceTypes,
                types: performanceReport.referralWorkPlaceTypes.map(x => x.value)
            } as Proxy.IPerformanceReportReferralWorkplaceTypeFilterDto);
            filters.push(referralWorkplaceTypeFilter);
        }

        if (performanceReport.doctors && performanceReport.doctors.length > 0) {
            const doctorsFilter = new Proxy.PerformanceReportCareDoctorFilterDto({
                doctorIds: performanceReport.doctors
            } as Proxy.IPerformanceReportCareDoctorFilterDto);
            filters.push(doctorsFilter);
        }

        if (performanceReport.assistants && performanceReport.assistants.length > 0) {
            const assistantsFilter = new Proxy.PerformanceReportCareAssistantFilterDto({
                assistantIds: performanceReport.assistants
            } as Proxy.IPerformanceReportCareAssistantFilterDto);
            filters.push(assistantsFilter);
        }

        if (performanceReport.referralWorkplaces && performanceReport.referralWorkplaces.length > 0) {
            const externalLocationsFilter = new Proxy.PerformanceReportReferralWorkplaceFilterDto({
                referralWorkPlaceIds: performanceReport.referralWorkplaces
            } as Proxy.IPerformanceReportReferralWorkplaceFilterDto);
            filters.push(externalLocationsFilter);
        }

        if (performanceReport.isExternal !== null) {
            const isExternalFilter = new Proxy.PerformanceReportReferralWorkplaceLocationFilterDto({
                onlyExternal: performanceReport.isExternal
            } as Proxy.IPerformanceReportReferralWorkplaceLocationFilterDto);
            filters.push(isExternalFilter);
        }

        if (performanceReport.financingClasses && performanceReport.financingClasses.length > 0) {
            const financingClassesFilter = new Proxy.PerformanceReportFinancingClassFilterDto({
                financingClassIds: performanceReport.financingClasses
            } as Proxy.IPerformanceReportFinancingClassFilterDto);
            filters.push(financingClassesFilter);
        }

        if (performanceReport.careActivityStates && performanceReport.careActivityStates.length > 0) {
            const careActivityStatesFilter = new Proxy.PerformanceReportCareActivityStateFilterDto({
                careActivityStates: performanceReport.careActivityStates
            } as Proxy.IPerformanceReportCareActivityStateFilterDto);
            filters.push(careActivityStatesFilter);
        }

        if (performanceReport.isWithoutFinancedServices) {
            const withoutServicesFilter = new Proxy.PerformanceReportFinancedServicesFilterDto({
                withoutFinancedServices: performanceReport.isWithoutFinancedServices
            } as Proxy.IPerformanceReportFinancedServicesFilterDto);
            filters.push(withoutServicesFilter);
        }

        const groupings = [];

        let dto;

        for (const currentItem of performanceReport.groupings) {
            if (currentItem.type === PerformanceReportGroupingType.Interval) {
                dto = new Proxy.PerformanceReportIntervalGroupingDto({
                    isSummaryNeeded: currentItem.isSummaryNeeded,
                    intervalGroupingResolution: (currentItem.grouping as PerformanceReportIntervalGrouping).intervalGroupingResolution
                } as Proxy.IPerformanceReportIntervalGroupingDto);
            } else {
                const enumBasedGrouping = currentItem.grouping as PerformanceReportEnumBasedGrouping;

                if (currentItem.type === PerformanceReportGroupingType.Institute) {
                    dto = new Proxy.PerformanceReportInstituteLevelGroupingDto({
                        isSummaryNeeded: currentItem.isSummaryNeeded,
                        groupingFields: this.getGroupingFields(enumBasedGrouping.selectedColumns, InstituteGroupingField),
                        orderingField: enumBasedGrouping.selectedOrdering
                    } as Proxy.IPerformanceReportInstituteLevelGroupingDto);
                } else if (currentItem.type === PerformanceReportGroupingType.Site) {
                    dto = new Proxy.PerformanceReportSiteLevelGroupingDto({
                        isSummaryNeeded: currentItem.isSummaryNeeded,
                        groupingFields: this.getGroupingFields(enumBasedGrouping.selectedColumns, SiteGroupingField),
                        orderingField: enumBasedGrouping.selectedOrdering
                    } as Proxy.IPerformanceReportSiteLevelGroupingDto);
                } else if (currentItem.type === PerformanceReportGroupingType.Ambulance) {
                    dto = new Proxy.PerformanceReportAmbulanceLevelGroupingDto({
                        isSummaryNeeded: currentItem.isSummaryNeeded,
                        groupingFields: this.getGroupingFields(enumBasedGrouping.selectedColumns, AmbulanceGroupingField),
                        orderingField: enumBasedGrouping.selectedOrdering
                    } as Proxy.IPerformanceReportAmbulanceLevelGroupingDto);
                } else if (currentItem.type === PerformanceReportGroupingType.PointOfCare) {
                    dto = new Proxy.PerformanceReportPointOfCareLevelGroupingDto({
                        isSummaryNeeded: currentItem.isSummaryNeeded,
                        groupingFields: this.getGroupingFields(enumBasedGrouping.selectedColumns, PointOfCareGroupingField),
                        orderingField: enumBasedGrouping.selectedOrdering
                    } as Proxy.IPerformanceReportPointOfCareLevelGroupingDto);
                } else if (currentItem.type === PerformanceReportGroupingType.FinancingClass) {
                    dto = new Proxy.PerformanceReportFinancingClassGroupingDto({
                        isSummaryNeeded: currentItem.isSummaryNeeded,
                        groupingFields: this.getGroupingFields(enumBasedGrouping.selectedColumns, FinancingClassGroupingField),
                        orderingField: enumBasedGrouping.selectedOrdering
                    } as Proxy.IPerformanceReportFinancingClassGroupingDto);
                } else if (currentItem.type === PerformanceReportGroupingType.CareDoctor) {
                    dto = new Proxy.PerformanceReportCareDoctorGroupingDto({
                        isSummaryNeeded: currentItem.isSummaryNeeded,
                        groupingFields: this.getGroupingFields(enumBasedGrouping.selectedColumns, CareDoctorGroupingField),
                        orderingField: enumBasedGrouping.selectedOrdering
                    } as Proxy.IPerformanceReportCareDoctorGroupingDto);
                } else if (currentItem.type === PerformanceReportGroupingType.CareAssistant) {
                    dto = new Proxy.PerformanceReportCareAssistantGroupingDto({
                        isSummaryNeeded: currentItem.isSummaryNeeded,
                        groupingFields: this.getGroupingFields(enumBasedGrouping.selectedColumns, CareAssistantGroupingField),
                        orderingField: enumBasedGrouping.selectedOrdering
                    } as Proxy.IPerformanceReportCareAssistantGroupingDto);
                } else if (currentItem.type === PerformanceReportGroupingType.ReferralWorkplace) {
                    dto = new Proxy.PerformanceReportReferralWorkplaceGroupingDto({
                        isSummaryNeeded: currentItem.isSummaryNeeded,
                        groupingFields: this.getGroupingFields(enumBasedGrouping.selectedColumns, ReferralWorkplaceGroupingField),
                        orderingField: enumBasedGrouping.selectedOrdering
                    } as Proxy.IPerformanceReportReferralWorkplaceGroupingDto);
                } else if (currentItem.type === PerformanceReportGroupingType.Service) {
                    dto = new Proxy.PerformanceReportServiceGroupingDto({
                        isSummaryNeeded: currentItem.isSummaryNeeded,
                        groupingFields: this.getGroupingFields(enumBasedGrouping.selectedColumns, ServiceGroupingField),
                        orderingField: enumBasedGrouping.selectedOrdering
                    } as Proxy.IPerformanceReportServiceGroupingDto);
                }
            }

            groupings.push(dto);
        }
        const outputColumns = [];

        for (const currentItem of performanceReport.outputColumns) {
            if (currentItem != null) {
                const careActivityOutputColumn = new Proxy.PerformanceReportOutputColumnDto({
                    columnType: currentItem.type
                } as Proxy.IPerformanceReportOutputColumnDto);
                outputColumns.push(careActivityOutputColumn);
            }
        }

        const parameter = new Proxy.PerformanceReportParametersDto({
            filters: filters,
            groupings: groupings,
            outputColumns: outputColumns
        } as Proxy.IPerformanceReportParametersDto);

        return parameter;
    }

    private getGroupingFields(items: boolean[], enumType: any) {
        const groupingFields: any[] = [];

        const enumValues = Object.values(enumType).filter(x => typeof x === "string");

        enumValues.map((currentEnum, index) => {
            if (items[index]) {
                groupingFields.push(enumValues[index]);
            }
        });

        return groupingFields;
    }
}