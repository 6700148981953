import IDosageLocalizationService from "./IDosageLocalizationService";
import Dosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Dosage";
import FrequencyBasedDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/FrequencyBasedDosage";
import ScheduledDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/ScheduledDosage";
import TextualDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/TextualDosage";
import DosageInterval from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/DosageInterval";
import Di from "@Di";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";

@Di.injectable()
export default class DosageLocalizationService implements IDosageLocalizationService {
    constructor(@Di.inject("ILocalizationService") private readonly localizationService: ILocalizationService) {
    }

    public localize(dosage?: Dosage): string {
        if (!dosage) {
            return "";
        }

        if (dosage instanceof FrequencyBasedDosage) {
            return this.localizationService.localizeEnum(DosageInterval[dosage.interval], "DosageInterval")?.Name + " " + dosage.frequency + "x" + dosage.amount;
        } else if (dosage instanceof ScheduledDosage) {
            return `R: ${dosage.morningAmount} D: ${dosage.noonAmount} E: ${dosage.eveningAmount} L: ${dosage.beforeSleepAmount}`;
        } else if (dosage instanceof TextualDosage) {
            return dosage.dosageInstructions;
        } else {
            throw new Error("Dosage descendant not recognized.");
        }
    }
}