import NDataScreen from "@HisPlatform/BoundedContexts/Productivity/Components/NDataScreen/NDataScreen";
import { useScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import ShowCareActivitiesForEpisodeOfCareScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivitiesForEpisodeOfCareScreenAction.g";
import ShowPatientEpisodeOfCareListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowPatientEpisodeOfCareListScreenAction.g";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import React, { useCallback, useMemo } from "react";
import PatientEpisodeOfCareListScreenApiAdapter from "./PatientEpisodeOfCareListScreenApiAdapter";

export default function PatientEpisodeOfCareListScreen(props: IScreenPropsBase<ShowPatientEpisodeOfCareListScreenAction>) {

    const { episodeOfCareListScreenApiAdapter } = useDependencies(c => ({
        episodeOfCareListScreenApiAdapter: c.resolve<PatientEpisodeOfCareListScreenApiAdapter>("PatientEpisodeOfCareListScreenApiAdapter"),
    }));

    const getDynamicListAsync = useCallback(async (args: FrontendListParameters) => {
        const response = await episodeOfCareListScreenApiAdapter.getPatientEpisodeOfCareListAsync(props.action.patientId, args);
        return response.result;
    }, []);

    const navigationContext = useScreenNavigationContext();

    const isShowCareActivitiesForEpisodeOfCareAction = useMemo(() => {
        return navigationContext.currentSecondaryScreen?.action instanceof ShowCareActivitiesForEpisodeOfCareScreenAction;
    }, [navigationContext.currentSecondaryScreen]);

    return (
        <NDataScreen
            iconName="episode_of_care"
            onGetDynamicListAsync={getDynamicListAsync}
            isMasterDetailSeparatedMode={isShowCareActivitiesForEpisodeOfCareAction}
        />
    );
}