import DependencyContainer from "@DiContainer";
import * as OrganizationProxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";

DependencyContainer
    .bind("IStructureClient")
    .to(OrganizationProxy.StructureClient).inSingletonScope();

DependencyContainer
    .bind("IExternalClient")
    .to(OrganizationProxy.ExternalClient).inSingletonScope();
    
DependencyContainer
    .bind("IPractitionersClient")
    .to(OrganizationProxy.PractitionersClient).inSingletonScope();

DependencyContainer
    .bind("IDynamicPropertiesClient")
    .to(OrganizationProxy.DynamicPropertiesClient).inSingletonScope();

DependencyContainer
    .bind("IOrganizationReferenceDataClient")
    .to(OrganizationProxy.OrganizationReferenceDataClient).inSingletonScope();
