import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import { createNestedRoutes } from "@Toolkit/ReactClient/Routing/CreateNestedRoutes";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import IPatientRouteParams from "@HisPlatform/Application/Routes/IPatientRouteParams";
import IImmunizationRouteParams from "@HisPlatform/Application/Routes/IImmunizationRouteParams";
import IProcedureStatementRouteParams from "@HisPlatform/Application/Routes/IProcedureStatementRouteParams";
import IAutonomyDisabilityStatementRouteParams from "@HisPlatform/Application/Routes/IAutonomyDisabilityStatementRouteParams";
import IConditionStatementRouteParams from "@HisPlatform/Application/Routes/IConditionStatementRouteParams";
import IDeviceUseStatementRouteParams from "@HisPlatform/Application/Routes/IDeviceUseStatementRouteParams";
import IPregnancyStatementRouteParams from "@HisPlatform/Application/Routes/IPregnancyStatementRouteParams";
import IMedicationStatementRouteParams from "@HisPlatform/Application/Routes/IMedicationStatementRouteParams";
import IRiskAssessmentRouteParams from "@HisPlatform/Application/Routes/IRiskAssessmentRouteParams";
import IPatientAllergyIntoleranceRouteParams from "@HisPlatform/Application/Routes/IPatientAllergyIntoleranceRouteParams";
import IMedicalAlertStatementRouteParams from "@HisPlatform/Application/Routes/IMedicalAlertStatementRouteParams";
import ISurgeryForPatientRouteParams from "@HisPlatform/Application/Routes/ISurgeryForPatientRouteParams";


const PatientRoutes = createNestedRoutes(ApplicationRoutes.patient, {
    healthInsuranceContractList: new RouteDefinition<IPatientRouteParams>("/healthInsuranceContracts"),
    patientEhr: new RouteDefinition<IPatientRouteParams>("/ehr"),
    appointmentList: new RouteDefinition<IPatientRouteParams>("/appointments"),    
    immunization: new RouteDefinition<IImmunizationRouteParams>("/immunization/:useCase?"),
    procedureStatement: new RouteDefinition<IProcedureStatementRouteParams>("/procedureStatement/:useCase?"),
    autonomyDisabilityStatement: new RouteDefinition<IAutonomyDisabilityStatementRouteParams>("/autonomyDisabilityStatement/:useCase?"),
    conditionStatement: new RouteDefinition<IConditionStatementRouteParams>("/conditionStatement/:clinicalStatus/:useCase?"),
    deviceUseStatement: new RouteDefinition<IDeviceUseStatementRouteParams>("/deviceUseStatement/:useCase?"),
    pregnancyStatement: new RouteDefinition<IPregnancyStatementRouteParams>("/pregnancyStatement/:useCase?"),
    medicationStatement: new RouteDefinition<IMedicationStatementRouteParams>("/medicationStatement/:useCase?"),
    riskAssessment: new RouteDefinition<IRiskAssessmentRouteParams>("/riskAssessment/:useCase?"),
    patientAllergyIntolerance: new RouteDefinition<IPatientAllergyIntoleranceRouteParams>("/patientAllergyIntolerance/:useCase?"),
    medicalAlertStatement: new RouteDefinition<IMedicalAlertStatementRouteParams>("/medicalAlertStatement/:useCase?"),
    surgeryForPatient: new RouteDefinition<ISurgeryForPatientRouteParams>("/surgery/:selectedId?/:useCase?")
});

export default PatientRoutes;
