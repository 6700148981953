import Di from "@Di";
import { IValidationProblemParameterMapper } from "./IValidationProblemParameterMapper";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";

@Di.injectable()
export default class ValidationProblemParameterMapperService {
    private paramaterMappers: Map<string, IValidationProblemParameterMapper[]> = new Map<string, IValidationProblemParameterMapper[]>();

    public registerMapper(storeIdentifier: string, mapper: IValidationProblemParameterMapper) {
        if (this.paramaterMappers.has(storeIdentifier)) {
            this.paramaterMappers.get(storeIdentifier).push(mapper);
        } else {
            this.paramaterMappers.set(storeIdentifier, [mapper]);
        }
    }

    public collectValidationProblemParameterReferenceData(storeIdentifier: string, vp: IClientValidationProblem) {
        const mappers = this.paramaterMappers.get(storeIdentifier);
        if (mappers && mappers.length > 0) {
            for (const mapper of mappers) {
                mapper.collectValidationProblemParameterReferenceData(vp);
            }
        }
    }

    public async loadCollectedValidationProblemParameterReferenceDataAsync(storeIdentifier: string) {
        const mappers = this.paramaterMappers.get(storeIdentifier);
        if (mappers && mappers.length > 0) {
            for (const mapper of mappers) {
                await mapper.loadCollectedValidationProblemParameterReferenceDataAsync();
            }
        }
    }

    public resolveValidationProblemParameters(storeIdentifier: string, vp: IClientValidationProblem) {
        const mappers = this.paramaterMappers.get(storeIdentifier);
        if (mappers && mappers.length > 0) {
            for (const mapper of mappers) {
                const resolvedParameters = mapper.resolveValidationProblemParameters(vp);
                vp.resolvedParameters = {...vp.resolvedParameters, ...resolvedParameters};
            }
        }
    }

    public async resolveParametersAsync(mapperIdentifier: string, vp: IClientValidationProblem) {
        this.collectValidationProblemParameterReferenceData(mapperIdentifier, vp);
        await this.loadCollectedValidationProblemParameterReferenceDataAsync(mapperIdentifier);
        this.resolveValidationProblemParameters(mapperIdentifier, vp);
    }
}