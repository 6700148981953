import { IRowCheckState } from "@CommonControls/DataGrid/IDataGridProps";
import AttributeReference from "@Primitives/AttributeReference.g";
import ItemVisibility from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/ItemVisibility.g";
import ItemPlacement from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/ItemPlacement.g";
import ResourceId from "@Primitives/ResourceId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class WorklistItemDefinitionStore {
    @State.observable.ref public checkState: IRowCheckState = {
        isChecked: false,
        isDisabled: false,
        isVisible: true
    };

    @State.observable public order: number;
    @State.observable public itemVisibility: ItemVisibility;
    @State.observable public isFiltered: boolean;
    @State.observable public isSortingEnabled: boolean;
    @State.observable public defaultColumnWidth: string;
    @State.observable public ellipsisOnOverflow: boolean;

    constructor(
        isChecked: boolean,
        order: number,
        itemVisibility: ItemVisibility,
        public itemPlacement: ItemPlacement,
        isFiltered: boolean,
        isSortingEnabled: boolean,
        defaultColumnWidth: string,
        ellipsisOnOverflow: boolean,
        public attributeReference: AttributeReference,
        public headerResourceId: ResourceId,
        public columnName: string,
        public displayValue: string,
        public hasLocalizedName: boolean
    ) {
        this.checkState.isChecked = isChecked;
        this.order = order;
        this.itemVisibility = itemVisibility;
        this.isFiltered = isFiltered;
        this.isSortingEnabled = isSortingEnabled;
        this.defaultColumnWidth = defaultColumnWidth;
        this.ellipsisOnOverflow = ellipsisOnOverflow;
    }

    @State.action.bound
    public setOrder(value: number) {
        this.order = value;
    }

    @State.action.bound
    public setItemVisibility(value: ItemVisibility) {
        this.itemVisibility = value;
    }

    @State.action.bound
    public setFilterMode(value: boolean) {
        this.isFiltered = value;
    }

    @State.action.bound
    public setSortingMode(value: boolean) {
        this.isSortingEnabled = value;
    }

    @State.action.bound
    public setDefaultColumnWidth(value: string) {
        this.defaultColumnWidth = value;
    }

    @State.action.bound
    public setEllipsisOnOverflow(value: boolean) {
        this.ellipsisOnOverflow = value;
    }
}