import Di from "@Di";
import IPatientCareActivitiesTabComponentService from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabComponentService";
import CareActivityId from "@Primitives/CareActivityId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import DiagnosisListApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/DiagnosisList/DiagnosisListApiAdapter";
import ApiBusinessError from "@Toolkit/CommonWeb/ApiAdapter/ApiBusinessError";
import UnauthorizedOperationBusinessError from "@Toolkit/CommonWeb/Model/UnauthorizedOperationBusinessError";

@Di.injectable()
export default class DiagnosisListTabComponentService implements IPatientCareActivitiesTabComponentService {

    constructor(@Di.inject("DiagnosisListApiAdapter") private diagnosisListAdapter: DiagnosisListApiAdapter) {
    }

    public async isTabAllowedAsync(careActivityId: CareActivityId, organizationUnitId: OrganizationUnitId): Promise<boolean> {
        try {
            await this.diagnosisListAdapter.getDiagnosisListAsync(careActivityId, false, true);
            return true;
        } catch (err) {
            if (err instanceof ApiBusinessError && err.error instanceof UnauthorizedOperationBusinessError) {
                return false;
            }

            throw err;
        }
    }
}