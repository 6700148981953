exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RowBody_row-body_1lRoN {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  padding-bottom: 7px;\n  background: #F4F7FC;\n  display: block;\n  width: fit-content;\n  padding: 7px;\n  border-radius: 3px;\n  margin: 5px 0 3px 0;\n}\n.RowBody_row-body_1lRoN span {\n  white-space: normal;\n}\n.RowBody_row-body_1lRoN .RowBody_flex-item_2Ki0H:not(:last-child):after {\n  content: \"\\2022\";\n  margin-left: 4px;\n  margin-right: 4px;\n}\n", ""]);

// exports
exports.locals = {
	"row-body": "RowBody_row-body_1lRoN",
	"rowBody": "RowBody_row-body_1lRoN",
	"flex-item": "RowBody_flex-item_2Ki0H",
	"flexItem": "RowBody_flex-item_2Ki0H"
};