import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import MedicalServiceFinancingStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/MedicalServiceFinancingStore";
import { IValidationProblemProcessor } from "@Toolkit/CommonWeb/Validation/ValidationProblemProcessorService";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import _ from "@HisPlatform/Common/Lodash";

export class PerformedAtRuleValidationProblemProcessor implements IValidationProblemProcessor<MedicalServiceFinancingStore> {
    public isRuleHighlightable = true;

    constructor(public ruleId: string) { }

    public mapValidationProblem(problem: IClientValidationProblem, rows: MedicalServiceFinancingStore[]) {
        const financedServiceIds = this.getServiceIds(problem);
        return rows.filter(i => financedServiceIds.some(j => i.financedServicesWithQuantities.some(k => ValueWrapper.equals(k.financedServiceId.id, j))));
    }

    public renderInfo = () => undefined as React.ReactNode;

    private getServiceIds(problem: IClientValidationProblem) {
        const ids = _.get(problem.parameters, "FinancedServiceIds") as any[];
        const parsedIds = ids?.map(i => new FinancedServiceId(i?.Value?.toString())) || [];
        return parsedIds;
    }
}
