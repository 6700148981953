
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import SurgeryPanelStore from "./SurgeryPanelStore";

const { 
    ContextComponent: SurgeryPanelStoreContext, 
    StoreProvider: SurgeryPanelStoreProvider, 
    useStore: useSurgeryPanelStore,
    provideStore: provideSurgeryPanelStore,
} = createPanelStoreProvider(SurgeryPanelStore);

export {
    SurgeryPanelStoreContext,
    SurgeryPanelStoreProvider,
    useSurgeryPanelStore,
    provideSurgeryPanelStore
};