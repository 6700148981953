import React, { useCallback } from "react";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import DataGridExtensibleEnumColumn from "@CommonControls/DataGrid/Column/DataGridExtensibleEnumColumn";
import DataGridBooleanColumn from "@CommonControls/DataGrid/Column/DataGridBooleanColumn";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import InsurerOrganizationTypeId from "@Primitives/InsurerOrganizationTypeId.g";
import InsurerOrganization from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/InsurerOrganizations/InsurerOrganization";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";

interface IInsurerOrganizationsListPanelViewProps {
    insurerOrganizationTypes: ExtensibleEnumStore<InsurerOrganizationTypeId>;
    selectedOrganizationId?: InsurerOrganizationId;
    organizations: InsurerOrganization[];
    onEdit: (row: InsurerOrganization) => () => void;
    onDeleteAsync: (row: InsurerOrganization) => () => Promise<void>;
    showInsurerOrganizationDetailPanel: () => void;
}


const InsurerOrganizationsListPanelView: React.FC<IInsurerOrganizationsListPanelViewProps> = props => {

    const renderActions = useCallback((value: any, row: InsurerOrganization) => {
        return (
            <>
                {!row.isClaimSettlementPartner && <>
                    <Ui.Button iconName="pen" visualStyle="primary" size="compact" onClick={props.onEdit(row)} automationId={`edit_${row.code}`} />
                    <Ui.Button iconName="trash" visualStyle="negative" size="compact" onClick={props.onDeleteAsync(row)} disabled={row.hasPatientData} automationId={`delete_${row.code}`} />
                </>}
                {row.isClaimSettlementPartner && <Ui.Button iconName="info" size="compact" onClick={props.onEdit(row)} automationId={`info_${row.code}`} />}
            </>
        );
    }, [props]);

    return (
        <>
            <Ui.Button iconName="plus" visualStyle="primary" size="compact" onClick={props.showInsurerOrganizationDetailPanel} automationId="createInsurerOrganizationButton" />
            <Ui.DataGrid dataSource={props.organizations} automationId="insurerOrganizationGrid">
                <DataGridColumn dataGetter="name" header={StaticFinanceResources.InsurerOganizations.Labels.Name} />
                <DataGridColumn dataGetter="code" header={StaticFinanceResources.InsurerOganizations.Labels.Code} />
                <DataGridColumn dataGetter="displayCode" header={StaticFinanceResources.InsurerOganizations.Labels.DisplayCode} />
                <DataGridExtensibleEnumColumn dataGetter="insurerOrganizationTypeId" extensibleEnumStore={props.insurerOrganizationTypes} header={StaticFinanceResources.InsurerOganizations.Labels.InsurerOrganizationType} />
                <DataGridBooleanColumn dataGetter="isActive" header={StaticFinanceResources.InsurerOganizations.Labels.IsActive} />
                <DataGridBooleanColumn dataGetter="isClaimSettlementPartner" header={StaticFinanceResources.InsurerOganizations.Labels.IsClaimSettlementPartner} />
                <DataGridColumn onRenderCellValue={renderActions} width={75} />
            </Ui.DataGrid>
        </>
    );
};

export default InsurerOrganizationsListPanelView;
