import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import IRoleEditorRouteParams from "@HisPlatform/Application/Routes/IRoleEditorRouteParams";
import RoleId from "@Primitives/RoleId.g";
import RoleManagementPanel from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/RoleManagement/RoleManagemetPanel";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";


interface IRoleManagementPageDependencies {
}

interface IRoleManagementPageProps extends IRoutingFrameContentProps {
    _dependencies?: IRoleManagementPageDependencies;
}

@State.observer
class RoleManagementPage extends React.Component<IRoleManagementPageProps> {
    private get routeParams(): IRoleEditorRouteParams {
        return (this.props.routingController.currentRoute.parameters as IRoleEditorRouteParams);
    }

    @State.bound
    private updateRoute(roleId: string) {
        this.props.routingController.replace(ApplicationRoutes.roleManagement.makeRoute({roleId: roleId}));
    }

    @State.bound
    private backToPreviousPage() {
        this.props.routingController.goBack();
    }

    public render() {
        return (
            <LoadingBoundary>
                <RoleManagementPanel
                    onNavigationChanged={this.updateRoute}
                    roleId={this.routeParams?.roleId && new RoleId(this.routeParams.roleId)}
                    backToPreviousPage={this.backToPreviousPage}
                />
            </LoadingBoundary>
        );
    }
}

export default RoleManagementPage;
