import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import MedicationVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/MedicationVersion";
import SubstanceId from "@Primitives/SubstanceId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import ISubstanceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/ISubstanceVersion";
import { IPagingState, IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import MedicationId from "@Primitives/MedicationId.g";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IMedicationEquipmentClassificationVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/IMedicationEquipmentClassificationVersion";
import MedicationEquipmentClassificationId from "@Primitives/MedicationEquipmentClassificationId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import MedicationEquipmentClassificationSelectorQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/Medication/Enum/MedicationEquipmentClassificationSelectorQueryOrderingFields.g";
import SubstanceSelectorQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/Medication/Enum/SubstanceSelectorQueryOrderingFields.g";
import MedicationSelectorQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/Medication/Enum/MedicationSelectorQueryOrderingFields.g";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";

@Di.injectable()
export default class MedicationsApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IMedicationClient") private apiClient: Proxy.IMedicationClient
    ) {
        super();
    }

    @State.bound
    public medicationSelectorQueryAsync(
        name: string,
        ordering: IOrderingState,
        paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<MedicationVersion>>(),
            async (target) => {
                const response = await this.apiClient.medicationSelectorQueryAsync(
                    CreateRequestId(),
                    new Proxy.MedicationSelectorControllerDto({
                        name: name,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfMedicationSelectorQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfMedicationSelectorQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: MedicationSelectorQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results.values.map(this.mapMedicationListItem)
                };
            }
        );
    }
    @State.bound
    public substanceSelectorQueryAsync(
        name: string,
        ordering: IOrderingState,
        paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<ISubstanceVersion>>(),
            async (target) => {
                const response = await this.apiClient.substanceSelectorQueryAsync(
                    CreateRequestId(),
                    new Proxy.SubstanceSelectorControllerDto({
                        name: name,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfSubstanceSelectorQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfSubstanceSelectorQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: SubstanceSelectorQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results.values.map(this.mapSubstance)
                };
            }
        );
    }

    private mapMedicationListItem(i: Proxy.MedicationVersionDto): MedicationVersion {
        return new MedicationVersion(
            i.id,
            i.name,
            i.efficacy,
            i.manufacturerName,
            i.amountInPackage,
            i.identifiers.map(id => new Identifier(id.identifierSystemId, id.value)),
            i.isDeleted,
            i.medicationTypeId,
            i.medicationFormId,
            i.substances,
            [],
            i.textAmount,
            i.dosageModeId,
            i.dosageAmount,
            i.registryIdentifier,
            i.substanceAmount,
            i.daysSuppliedCanBeCalculated,
            i.validity,
            i.pharmacyTypeId,
            i.uniqueSizing
        );
    }

    @State.bound
    public getSubstancesByVersionSelectors(versionSelectors: Array<IEntityVersionSelector<SubstanceId>>) {
        return this.processOperationAsync(
            new SimpleStore<ISubstanceVersion[]>(),
            async target => {
                const response = await this.apiClient.getSubstancesByVersionSelectorsQueryAsync(CreateRequestId(), new Proxy.GetSubstancesByVersionSelectorsControllerDto({
                    versionSelectors: versionSelectors?.map(i => new Proxy.EntityVersionSelectorOfSubstanceId({ entityId: i.id, validOn: i.validOn })) || []
                }));
                target.operationInfo = createOperationInfo(response);
                target.value = response.substanceVersions.map(this.mapSubstance);
            }
        );
    }

    @State.bound
    private mapSubstance(i: Proxy.SubstanceVersionDto): ISubstanceVersion {
        return {
            id: i.id,
            code: i.identifier.value,
            name: i.name,
            identifier: new Identifier(i.identifier.identifierSystemId, i.identifier.value),
            validity: i.validity
        };
    }

    @State.bound
    public getMedicationVersionsBySelectorsAsync(
        versionSelectors: Array<IEntityVersionSelector<MedicationId>>
    ) {
        return this.processOperationAsync(
            new SimpleStore<MedicationVersion[]>(),
            async target => {
                const response = await this.apiClient.getMedicationVersionsBySelectorsQueryAsync(CreateRequestId(), new Proxy.GetMedicationVersionsBySelectorsControllerDto({
                    versionSelectors: versionSelectors.map(i => new Proxy.EntityVersionSelectorOfMedicationId({ entityId: i.id, validOn: i.validOn }))
                }));

                target.operationInfo = createOperationInfo(response);
                target.value = response.medicationVersions.map(this.mapMedicationListItem);
            }
        );
    }

    @State.bound
    public getMedicationEquipmentClassificationsByVersionSelectorsAsync(versionSelectors: Array<IEntityVersionSelector<MedicationEquipmentClassificationId>>) {
        return this.processOperationAsync(
            new SimpleStore<IMedicationEquipmentClassificationVersion[]>(),
            async target => {
                const response = await this.apiClient.getMedicationEquipmentClassificationsByVersionSelectorsQueryAsync(CreateRequestId(), new Proxy.GetMedicationEquipmentClassificationsByVersionSelectorsControllerDto({
                    versionSelectors: versionSelectors.map(i => new Proxy.EntityVersionSelectorOfMedicationEquipmentClassificationId({ entityId: i.id, validOn: i.validOn }))
                }));

                target.operationInfo = createOperationInfo(response);
                target.value = response.medicationEquipmentClassificationVersions.map(this.mapMedicationEquipmentClassification);
            }
        );
    }

    @State.bound
    public getAllMedicationEquipmentClassifications(validOn: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<IMedicationEquipmentClassificationVersion[]>(),
            async target => {
                const response = await this.apiClient.getAllMedicationEquipmentClassificationVersionsQueryAsync(CreateRequestId(), validOn.stringify());

                target.operationInfo = createOperationInfo(response);
                target.value = response.medicationEquipmentClassificationVersions.map(this.mapMedicationEquipmentClassification);
            }
        );
    }

    @State.bound
    public searchMedicationEquipmentClassifications(filterText: string, ordering: IOrderingState, paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new PagedItemStore<IMedicationEquipmentClassificationVersion>(),
            async (target) => {
                const response = await this.apiClient.medicationEquipmentClassificationSelectorQueryAsync(
                    CreateRequestId(),
                    new Proxy.MedicationEquipmentClassificationSelectorControllerDto({
                        filterText: filterText,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfMedicationEquipmentClassificationSelectorQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfMedicationEquipmentClassificationSelectorQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: MedicationEquipmentClassificationSelectorQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({ pageIndex: paging.currentPage || 0, pageSize: paging.pageSize || 20 })
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.items = response.results && response.results.values.map(this.mapMedicationEquipmentClassification);
                target.totalCount = response.results && response.results.totalCount;
            }
        );
    }

    @State.bound
    public getMedicationVersionsByIdentifiersAsync(
        identifiers: Identifier[],
        validOn?: LocalDate
    ) {
        return this.processOperationAsync(
            new SimpleStore<Map<Identifier, MedicationVersion[]>>(),
            async target => {
                const response = await this.apiClient.getMedicationVersionsByIdentifiersQueryAsync(CreateRequestId(), new Proxy.GetMedicationVersionsByIdentifiersControllerDto({
                    identifiers: identifiers,
                    validOn: validOn
                }));

                target.operationInfo = createOperationInfo(response);
                target.value = new Map<Identifier, MedicationVersion[]>();
                for (const identifier of Object.keys(response.medicationVersions)) {
                    const identifierParts = identifier.split("|");
                    const typedIdentifier = new Identifier(new IdentifierSystemId(identifierParts[0]), identifierParts[1]);
                    target.value.set(typedIdentifier, response.medicationVersions[identifier].map(item => this.mapMedicationListItem(item)));
                }
            }
        );
    }

    private mapMedicationEquipmentClassification(dto: Proxy.MedicationEquipmentClassificationVersionDto) {
        return {
            id: dto.id,
            code: dto.code,
            name: dto.name,
            validity: dto.validity
        } as IMedicationEquipmentClassificationVersion;
    }
}