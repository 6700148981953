import Di from "@Di";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import HunEHealthInfrastructureUserManagementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/HunEHealthInfrastructureUserManagementApiAdapter";
import IEhiTokenRepository from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/Repositories/IEhiTokenRepository";
import PractitionerIdentifierStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerIdentifierStore";
import EhiLoginDialogParams from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/Extensions/PractitionerEhiLoginExtension/EhiLoginDialogParams";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IEhiLoginDialogResult } from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/Extensions/PractitionerEhiLoginExtension/IEhiLoginDialogResult";
import { TokenContainer } from "./TokenContainer";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import IAuthenticationService from "@HisPlatform/Services/Definition/Authentication/IAuthenticationService";

@Di.injectable()
export default class EhiUserContext {

    private static readonly ehiIdentifierSystemId = new IdentifierSystemId("HunEHealthInfrastructure");

    @State.observable public _tokenContainer: TokenContainer = null;

    public static getEhiIdentifier(identifiers: PractitionerIdentifierStore[]) {
        return identifiers && identifiers.find(ia => ValueWrapper.equals(this.ehiIdentifierSystemId, ia.identifier.identifierSystemId));
    }

    public static identifiersContainEhiIdentifier(identifiers: PractitionerIdentifierStore[]) {
        const ehiIdentifier = this.getEhiIdentifier(identifiers);
        return !isNullOrUndefined(ehiIdentifier);
    }

    @State.computed
    public get token() {
        return !!this._tokenContainer ? this._tokenContainer.token : null;
    }

    @State.computed
    public get isLoggedIn() {
        return !!this._tokenContainer ? this._tokenContainer.isLoggedIn : null;
    }

    @State.computed
    public get hasEhiId() {
        const identifiers = this.userContext.identifiers;
        return EhiUserContext.identifiersContainEhiIdentifier(identifiers);
    }

    @State.computed
    public get ehiIdentifier() {
        const identifiers = this.userContext.identifiers;
        return EhiUserContext.getEhiIdentifier(identifiers);
    }

    @State.computed
    public get ehiIdentifierValue() {
        return this.ehiIdentifier && this.ehiIdentifier.identifier.value;
    }

    @State.computed
    public get loggedInUserPractitionerId() {
        return this.userContext.practitionerId;
    }

    @State.computed
    public get loggedInUserName() {
        return !!this._tokenContainer && !!this._tokenContainer.loggedInUserName ? this._tokenContainer.loggedInUserName.get() : null;
    }

    constructor(
        @Di.inject("PractitionerApiAdapter") practitionerApiAdapter: PractitionerApiAdapter,
        @Di.inject("IAuthenticationService") authenticationService: IAuthenticationService,
        @Di.inject("UserContext") private readonly userContext: UserContext,
        @Di.inject("IEhiTokenRepository") private readonly ehiTokenRepository: IEhiTokenRepository,
        @Di.inject("HunEHealthInfrastructureUserManagementApiAdapter") private readonly apiAdapter: HunEHealthInfrastructureUserManagementApiAdapter,
        @Di.inject("IModalService") private readonly modalService: IModalService
    ) {
        this._tokenContainer = new TokenContainer(ehiTokenRepository, practitionerApiAdapter);
        authenticationService.onTriedLogin.on(() => {
            this.clearToken();
        });
        authenticationService.onLoggingOutAsync.on(async () => {
            await this.logoutAsync();
        });
    }

    @State.action.bound
    public async loginAsync(doctorIdentifierValue?: string, loginName?: string) {
        const result = await this.modalService.showDialogAsync<IEhiLoginDialogResult>(new EhiLoginDialogParams(isNullOrUndefined(doctorIdentifierValue) ? this.ehiIdentifierValue : doctorIdentifierValue, this._tokenContainer.token, false, loginName));
        if (isNullOrUndefined(result)) {
            return null;
        }

        if (result?.operationWasSuccessful) {
            if (result?.value?.wasSuccessful) {
                this.ehiTokenRepository.setToken(result.value.token);
                await this._tokenContainer.replaceAsync(result.value.token);
            } else {
                this.ehiTokenRepository.clearToken();
                this._tokenContainer.clear();
            }
        }
        return result?.value;
    }

    @State.action.bound
    public async loginRenewAsync() {
        const result = await this.modalService.showDialogAsync<IEhiLoginDialogResult>(new EhiLoginDialogParams(this.ehiIdentifierValue, this._tokenContainer.token, true, this.loggedInUserName));
        if (result && result?.operationWasSuccessful) {
            if (result?.value?.wasSuccessful) {
                this.ehiTokenRepository.setToken(result.value.token);
                await this._tokenContainer.replaceAsync(result.value.token);
            } else {
                this.ehiTokenRepository.clearToken();
                this._tokenContainer.clear();
            }
        }
    }

    @State.action.bound
    public async logoutAsync() {
        if (this._tokenContainer.isLoggedIn) {
            await this.apiAdapter.logoutAsync(this._tokenContainer.token.tokenValue);
            this.clearToken();
        }
    }

    @State.action.bound
    public clearToken() {
        this._tokenContainer.clear();
        this.ehiTokenRepository.clearToken();
    }
}
