import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import * as Ui from "@CommonControls";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import MedicationStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/MedicationStatementStore";
import MedicationStatementId from "@Primitives/MedicationStatementId.g";
import MedicationStatementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicationStatement/MedicationStatementApiAdapter";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import MedicalConditionHistoryItemDetailPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicalConditionHistoryItemDetailPanel";
import MedicationStatementFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/MedicationStatementFactory";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import PatientId from "@Primitives/PatientId.g";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import MedicationStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/MedicationStatementHistoryItem";
import MedicalConditionItemHeaderPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicalConditionItemHeaderPanel";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import { EntityLockState } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import IMedicalConditionReferenceDataResolver from "@HisPlatform/BoundedContexts/Care/Services/Definition/MedicalConditionReferenceDataResolver/IMedicalConditionReferenceDataResolver";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { MedicationStatementSubstanceCodeSelector } from "@HisPlatformControls";
import { MedicationStatementSubstanceCodeSelectorItem } from "@HisPlatformControls/MedicationStatementSubstanceCodeSelector/MedicationStatementSubstanceCodeSelectorItem";
import MedicationStatementSubjectAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/MedicationStatementSubjectAssignmentBase";
import ReferencedMedicationAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/ReferencedMedicationAssignment";
import MedicationId from "@Primitives/MedicationId.g";
import SubstanceId from "@Primitives/SubstanceId.g";
import ReferencedSubstanceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/ReferencedSubstanceAssignment";
import InternationalNonproprietaryNameId from "@Primitives/InternationalNonproprietaryNameId.g";
import ReferencedInternationalNonproprietaryNameAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/ReferencedInternationalNonproprietaryNameAssignment";
import CustomSubjectAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/CustomSubjectAssignment";
import IServiceRequestHeaderExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestHeaderExtensionPointProps";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import DosageFormSelectBox from "@HisPlatformControls/DosageFormSelectBox";
import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import FormExtensionRegistryAdapter from "@PluginInterface/FormExtension/FormExtensionRegistryAdapter";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";

interface IMedicationStatementPanelDependencies {
    dialogService: IDialogService;
    notificationService: INotificationService;
    medicationStatementApiAdapter: MedicationStatementApiAdapter;
    medicationStatementFactory: MedicationStatementFactory;
    lockingApiAdapter: LockingApiAdapter;
    medicalConditionReferenceDataResolver: IMedicalConditionReferenceDataResolver;
}

interface IMedicationStatementPanelProps extends IRoutingFrameContentProps {
    _dependencies?: IMedicationStatementPanelDependencies;
    _patientId?: PatientId;
    _formExtension?: IFormExtension<PatientId>;

    id: MedicationStatementId;
    onMedicationStatementCreatedAsync?: (medicationStatementId: MedicationStatementId) => Promise<void>;
    onCloseDetailAsync: () => Promise<void>;
    onRefreshListAsync: () => Promise<void>;
}

@State.observer
class MedicationStatementPanel extends React.Component<IMedicationStatementPanelProps> {

    private get medicationStatementId() { return this.props.id; }
    private get patientId() { return this.props._patientId; }
    @State.observable.ref private store: MedicationStatementStore = this.medicationStatementFactory.createNewStore();
    @State.computed private get currentHistoryItem() { return this.store.currentHistoryItem as MedicationStatementHistoryItem; }
    @State.computed private get isNew() { return this.medicationStatementId.value === "new"; }

    private get dialogService() { return this.props._dependencies.dialogService; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get medicationStatementApiAdapter() { return this.props._dependencies.medicationStatementApiAdapter; }
    private get medicationStatementFactory() { return this.props._dependencies.medicationStatementFactory; }
    private get lockingApiAdapter() { return this.props._dependencies.lockingApiAdapter; }
    private get medicalConditionReferenceDataResolver() { return this.props._dependencies.medicalConditionReferenceDataResolver; }

    private get resources() { return StaticCareResources.MedicalCondition.MedicationStatements; }
    private get medicalConditionResources() { return StaticCareResources.MedicalCondition; }
    private get labels() { return this.resources.Label; }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(
        this.loadAsync,
        this.isNew
            ? async () => await this.props._dependencies.medicationStatementApiAdapter.checkPermissionForAddNewItemAsync(this.medicationStatementFactory.createNewStore(), new PatientId("1"))
            : () => Promise.resolve([])
    );

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IMedicationStatementPanelProps) {
        if (prevProps.id.value !== this.props.id.value) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.action.bound
    private async loadAsync(forceReleaseLockNeeded: boolean = false) {
        if (forceReleaseLockNeeded && !!this.store?.lockInfo?.preventingLockId) {
            await this.lockingApiAdapter.forceReleaseLockAsync(this.store.lockInfo.preventingLockId);
        }

        let store = null;
        if (this.isNew) {
            store = this.medicationStatementFactory.createNewStore();
        } else {
            store = await this.medicationStatementApiAdapter.getByIdAsync(this.medicationStatementId, true);
        }
        this.store.setExtensionData(store.extensionData);
        await this.setStoreAsync(store);
    }

    @State.action.bound
    private async setStoreAsync(newValue: MedicationStatementStore) {
        this.store = newValue;
        const substanceName = await this.medicalConditionReferenceDataResolver.getMedicationStatementSubstanceNameAsync(this.store.substanceCodeSelectorItem, LocalDate.today());
        this.store.setSubstanceName(substanceName);
        this.store.takeSnapshot();
    }

    @State.bound
    private async navigateAwayAsync() {
        if (this.store?.isMutable === true && this.isUnsavedOrDirty()) {
            const dialogResult = await this.dialogService.confirmIfNotSaved(StaticCareResources.Common.Dialog.ConfirmationTitle, StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);
            if (dialogResult.resultCode === DialogResultCode.Yes) {
                await this.saveStoreAsync();
                await this.releaseLockAsync();
            } else if (dialogResult.resultCode === DialogResultCode.No) {
                await this.releaseLockAsync();
                return true;
            }
            return false;
        }
        await this.releaseLockAsync();
        return true;
    }

    @State.action.bound
    private async releaseLockAsync() {
        if (this.store.isMutable && this.store.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld) {
            await this.lockingApiAdapter.releaseLockAsync(this.store.lockInfo.lockId);
            this.store.releaseLock();
            return true;
        }
        return false;
    }

    private isUnsavedOrDirty() {
        return this.store.isDirty() && this.currentHistoryItem.isNew;
    }

    @State.action.bound
    private async validateAsync(): Promise<IClientValidationResult[]> {
        if (!this.store || !this.store.isMutable || !this.currentHistoryItem.isNew) {
            return [];
        }
        const validationResultResponse = await this.medicationStatementApiAdapter.validateAsync(this.store, this.patientId);
        return validationResultResponse.value;
    }

    @State.action.bound
    private async validateBeforeSaveAsync() {
        const newValue = await this.validateAsync();
        this.store.setValidationResults(newValue);
    }

    @State.action.bound
    private async saveStoreAsync() {
        await this.validateBeforeSaveAsync();

        if (this.store.hasValidationError) {
            return false;
        }

        await this.setExtensionDataAsync();

        let newStore = null;

        if (this.isNew) {
            newStore = await this.medicationStatementApiAdapter.addMedicationStatementAsync(this.store, this.patientId);
        } else {
            newStore = await this.medicationStatementApiAdapter.updateMedicationStatementAsync(this.store);
        }

        if (newStore.operationWasSuccessful && newStore.isPersistedByOperationInfo && this.isNew) {
            this.store.setExtensionData(newStore.extensionData);
            this.store.takeSnapshot();
            await this.props.onMedicationStatementCreatedAsync?.(newStore.id);
            return true;
        }

        await this.replaceStoreAsync(newStore);
        await this.props.onRefreshListAsync();
        return true;
    }

    @State.action.bound
    private async setExtensionDataAsync() {
        const extensionDataArray = await this.props._formExtension.invokeCallbackAsync<any>("ExtendStore", this.props._patientId);

        let extensionDataForStore = this.store.extensionData;
        for (const extensionData of extensionDataArray) {
            extensionDataForStore = { ...extensionData, ...extensionDataForStore };
        }

        this.store.setExtensionData(extensionDataForStore);
    }

    @State.action.bound
    private async replaceStoreAsync(newStore: MedicationStatementStore) {
        if (newStore.operationWasSuccessful) {
            if (newStore.isPersistedByOperationInfo) {
                newStore.lockInfo = this.store.lockInfo;
                await this.setStoreAsync(newStore);
                this.notificationService.showSavedSuccessfully();
            } else {
                State.runInAction(() => {
                    this.store.validationResults = newStore.validationResults;
                });
            }
        }
    }

    @State.action.bound
    private async deleteHistoryItemAsync() {
        const message = formatString(this.resources.Dialog.DeleteVersionConfirmationMessage, (this.store.currentItemIndex + 1).toString());
        const dialogResult = await this.dialogService.yesNo(StaticCareResources.Common.Dialog.ConfirmationTitle, message);

        if (dialogResult.resultCode === DialogResultCode.No) {
            return false;
        }

        await this.setExtensionDataAsync();

        if (this.store.historyItems.length === 1) {
            await this.medicationStatementApiAdapter.deleteMedicationStatementAsync(this.store);
            this.store.releaseLock();
            this.props.onCloseDetailAsync();
            return true;
        } else {
            const newStore = await this.medicationStatementApiAdapter.deleteMedicationStatementHistoryItemAsync(this.store, this.store.currentHistoryItem.versionNumber);

            await this.replaceStoreAsync(newStore);
            return true;
        }
    }

    @State.action.bound
    private async forceLoadAsync() {
        await this.loadAsync(true);
    }

    @State.computed
    private get errorExtensionProps() {
        return {
            extensionData: this.store.currentHistoryItem.extensionData
        } as IServiceRequestHeaderExtensionPointProps;
    }

    @State.bound
    private showNoEhiCompatibleIdentifierFoundError() {
        this.notificationService.error(this.medicalConditionResources.Common.Messages.NoEhiCompatibleIdentifierFoundErrorMessage);
        return true;
    }

    @State.computed
    public get renderErrorExtension() {
        return (
            <HisPlatformExtensionPoint
                type="ServiceRequestHeader"
                extensionProps={this.errorExtensionProps}>
                <></>
            </HisPlatformExtensionPoint>
        );
    }

    public render() {
        const prefix = this.store.validationPropertyPrefix;
        const substanceName = this.store.isNew ?
            StaticCareResources.MedicalCondition.Common.Label.NewEntry : this.store.substanceName;

        return (
            <PermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                <BusinessErrorHandler.Register businessErrorName="NoEhiCompatibleIdentifierFoundError" handler={this.showNoEhiCompatibleIdentifierFoundError} />
                <UseCaseFrame
                    toolbar={(
                        <MedicalConditionItemHeaderPanel
                            store={this.store}
                            onDeleteHistoryItemAsync={this.deleteHistoryItemAsync}
                            onSaveStoreAsync={this.saveStoreAsync}
                            onLockEditClickedAsync={this.forceLoadAsync} />
                    )}
                    title={substanceName}>
                    {this.initialLoadPanelAsync.isUnauthorizedAccess ? <UnauthorizedAccessContent /> :
                        <>
                            {this.renderErrorExtension}
                            <ValidationBoundary
                                validationResults={this.store.validationResults}
                                entityTypeName="MedicationStatement"
                                onValidateAsync={this.validateAsync}
                                validateOnMount
                            >
                                <ReadOnlyContext.Provider value={!this.store.isMutable || !this.currentHistoryItem.isNew}>
                                    <Ui.Flex>
                                        <Ui.Flex.Item sm={4}>
                                            <MedicationStatementSubstanceCodeSelector
                                                label={this.labels.MedicationStatementSubjectAssignment}
                                                value={this.store.substanceCodeSelectorItem}
                                                onChange={this.setMedicationStatementSubjectAssignment}
                                                isReadOnly={!this.store.isNew}
                                                propertyIdentifier="ReferenceDataOrName"
                                                automationId="__substanceId"
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item sm={4}>
                                            <DosageFormSelectBox
                                                label={this.labels.DosageMode}
                                                value={this.currentHistoryItem.statementSubjectDetail.dosageFormId}
                                                onChange={this.currentHistoryItem.statementSubjectDetail.setDosageFormId}
                                                propertyIdentifier={`${prefix}.StatementSubjectDetail.DosageFormId`}
                                                automationId="__dosageFormId" />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item sm={4}>
                                            <Ui.DatePicker
                                                label={this.labels.StartDate}
                                                value={this.currentHistoryItem.treatmentDetail.startDate}
                                                onChange={this.currentHistoryItem.treatmentDetail.setStartDate}
                                                propertyIdentifier={`${prefix}.TreatmentDetail.StartDate`}
                                                automationId="__startDate"
                                            />
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                    <Ui.Flex>
                                        <Ui.Flex.Item sm={4}>
                                            <Ui.TextBox
                                                label={this.labels.Efficacy}
                                                value={this.currentHistoryItem.statementSubjectDetail.efficacy}
                                                onChange={this.currentHistoryItem.statementSubjectDetail.setEfficacy}
                                                propertyIdentifier={`${prefix}.StatementSubjectDetail.Efficacy`}
                                                automationId="__efficacy"
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item sm={4}>
                                            <Ui.TextBox
                                                label={this.labels.IntakeFrequency}
                                                value={this.currentHistoryItem.statementSubjectDetail.intakeFrequency}
                                                onChange={this.currentHistoryItem.statementSubjectDetail.setIntakeFrequency}
                                                propertyIdentifier={`${prefix}.StatementSubjectDetail.IntakeFrequency`}
                                                automationId="__intakeFrequency"
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item sm={4}>
                                            <Ui.TextBox
                                                label={this.labels.DosageAmount}
                                                value={this.currentHistoryItem.statementSubjectDetail.dosageAmount}
                                                onChange={this.currentHistoryItem.statementSubjectDetail.setDosageAmount}
                                                propertyIdentifier={`${prefix}.StatementSubjectDetail.DosageAmount`}
                                                automationId="__dosageAmount"
                                            />
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                    <Ui.Flex>
                                        <Ui.Flex.Item sm={4}>
                                            <Ui.TextBox
                                                label={this.labels.Length}
                                                value={this.currentHistoryItem.treatmentDetail.length}
                                                onChange={this.currentHistoryItem.treatmentDetail.setLength}
                                                propertyIdentifier={`${prefix}.TreatmentDetail.Length`}
                                                automationId="__length"
                                            />
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                    <br />
                                    <MedicalConditionHistoryItemDetailPanel store={this.store} prefix={prefix} />
                                </ReadOnlyContext.Provider>
                            </ValidationBoundary>
                            <NavigateAwayHook onNavigateAwayAsync={this.navigateAwayAsync} />
                        </>
                    }
                </UseCaseFrame>
            </PermissionCheckContextProvider>
        );
    }

    @State.computed private get permissionCheckedOperations() {
        const res = {};

        const store = this.medicationStatementFactory.createNewStore();
        store.id = new MedicationStatementId("1");

        if (this.props.id) {
            res["AddNewVersion"] = async () => await this.props._dependencies.medicationStatementApiAdapter.checkPermissionForUpdateItemAsync(store);
            res["DeleteVersion"] = async () => await this.props._dependencies.medicationStatementApiAdapter.checkPermissionForDeleteItemAsync(store);
        }

        return res;
    }

    @State.bound
    private setMedicationStatementSubjectAssignment(newValue: MedicationStatementSubstanceCodeSelectorItem) {
        let medicationStatementSubjectAssignment: MedicationStatementSubjectAssignmentBase;
        if (!newValue) {
            medicationStatementSubjectAssignment = new ReferencedMedicationAssignment(null);
        } else if (newValue.id instanceof MedicationId) {
            medicationStatementSubjectAssignment = new ReferencedMedicationAssignment(newValue.id);
        } else if (newValue.id instanceof SubstanceId) {
            medicationStatementSubjectAssignment = new ReferencedSubstanceAssignment(newValue.id);
        } else if (newValue.id instanceof InternationalNonproprietaryNameId) {
            medicationStatementSubjectAssignment = new ReferencedInternationalNonproprietaryNameAssignment(newValue.id);
        } else {
            medicationStatementSubjectAssignment = new CustomSubjectAssignment(newValue.text);
        }
        this.store.setMedicationStatementSubjectAssignment(medicationStatementSubjectAssignment);
    }

}

export default connect(
    MedicationStatementPanel,
    new DependencyAdapter<IMedicationStatementPanelProps, IMedicationStatementPanelDependencies>(c => ({
        dialogService: c.resolve<IDialogService>("IDialogService"),
        notificationService: c.resolve<INotificationService>("INotificationService"),
        medicationStatementApiAdapter: c.resolve<MedicationStatementApiAdapter>("MedicationStatementApiAdapter"),
        medicationStatementFactory: c.resolve<MedicationStatementFactory>("MedicationStatementFactory"),
        lockingApiAdapter: c.resolve<LockingApiAdapter>("LockingApiAdapter"),
        medicalConditionReferenceDataResolver: c.resolve("IMedicalConditionReferenceDataResolver"),
    })),
    new PatientContextAdapter<IMedicationStatementPanelProps>(c => ({
        _patientId: c.patientId
    })),
    new FormExtensionRegistryAdapter("ManageMedicalCondition")
);
