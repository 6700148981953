import Di from "@Di";
import ProcedureStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ProcedureStatement/ProcedureStatementStore";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import ReferencedMedicalServiceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ProcedureStatement/ReferencedMedicalServiceAssignment";
import ProcedureStatementId from "@Primitives/ProcedureStatementId.g";
import ProcedureStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ProcedureStatement/ProcedureStatementHistoryItem";
import moment from "moment";

@Di.injectable()
export default class ProcedureStatementFactory {

    public createNewStore() {
        const store = new ProcedureStatementStore(true);
        store.id = new ProcedureStatementId("new");
        store.procedureAssignment = new ReferencedMedicalServiceAssignment(null);
        store.historyItems.push(this.createNewHistoryItem());
        store.currentItemIndex = 0;

        return store;
    }

    private createNewHistoryItem() {
        const historyItem = new ProcedureStatementHistoryItem();

        historyItem.isNew = true;
        historyItem.versionNumber = 1;
        historyItem.index = 0;
        historyItem.createdAt = moment(moment.now());
        historyItem.description = "";
        historyItem.performedAt = LocalDate.today();
        historyItem.historyItemDetail = new MedicalConditionHistoryItemDetail();

        return historyItem;
    }
}