import React from "react";
import Classes from "./TreeGrid.less";
import TreeNode from "./TreeNode";
import { IRenderedTableFrameColumn } from "./TableFrame";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ITreeNodeStore from "./ITreeNodeStore";

interface ITreeGridViewProps {
    data: ITreeNodeStore[];
    selectedNode?: ITreeNodeStore;
    onNodeToggle?: (node: ITreeNodeStore, isToggled: boolean) => void;
    onNodeRenderDisplayValue?: (node: ITreeNodeStore, column: IRenderedTableFrameColumn, columnIndex: number) => React.ReactNode;
    onNodeSelect?: (node: ITreeNodeStore) => void;
    onNodeChecked?: (node: ITreeNodeStore, isChecked: boolean) => void;
    columns: IRenderedTableFrameColumn[];
    multiSelect?: boolean;
    automationId?: string;
}

@State.observer
export default class TreeGridView extends React.Component<ITreeGridViewProps> {   
    public render() {
        return (
            <ul className={Classes.tree}>
                {this.props.data.map((node, index) => this.mapNode(node, index))}
            </ul>
        );
    }

    private mapNode(node: ITreeNodeStore, index: number, treeLevel: number = 0) {
        return (
            <TreeNode
                columns={this.props.columns}
                key={node.key ?? `${treeLevel}_${index}`}
                node={node}
                treeLevel={treeLevel}
                children={!!node.children && node.children.length > 0 && node.children.map((child, childIndex) => this.mapNode(child, childIndex, treeLevel + 1))}
                onToggle={this.props.onNodeToggle}
                onRenderDisplayValue={this.props.onNodeRenderDisplayValue}
                onSelect={this.props.onNodeSelect}
                onChecked={this.props.onNodeChecked}
                active={node === this.props.selectedNode}
                toggled={!!node.children && node.children.length > 0 && node.isOpen}
                isCheckable={this.props.multiSelect}
                stopClickPropagation
                automationId={`${node.filterableValue}_TreeNode`}
            />
        );
    }
}