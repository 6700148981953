import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import ITemplateInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateInfo";
import ITemplateListItem from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateListItem";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import TokenLockInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TokenLockInfo";

export function mapTemplateInfo(dto: Proxy.TemplateInfoDto): ITemplateInfo {
    return {
        id: dto.id,
        code: dto.code,
        name: dto.name,
        description: dto.description,
        contentId: dto.contentId,
        documentTypeId: dto.documentTypeId ? new DocumentTypeId(dto.documentTypeId.value.toString()) : null,
        cultureCode: dto.cultureCode ? dto.cultureCode.value : "en-US",
        rowVersion: dto.rowVersion,
        isPrimary: dto.isPrimary,
        isDeleted: dto.isDeleted     
    };
}

export function mapITemplateListItem(dto: Proxy.TemplateListItemDto): ITemplateListItem {
    return {
        id: dto.id,
        code: dto.code,
        name: dto.name,
        description: dto.description,
        documentTypeId: dto.documentTypeId ? new DocumentTypeId(dto.documentTypeId.value.toString()) : null,
        availableAt: dto.availableAt,
        isDeleted: dto.isDeleted
    };
}

export function getTokenLockInfo(dto: Proxy.EditableTemplateTokenInfoDto) {
    const resp = new TokenLockInfo(dto.tokenReference,
        dto.tokenName,
        dto.sourceId,
        dto.sourceRowVersion);

    return resp;
}