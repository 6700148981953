import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import SuspectedDiagnosis from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/SuspectedDiagnosis";
import { CareLocation } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/CareLocation";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import CareActivityId from "@Primitives/CareActivityId.g";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import ServiceRequestCreationType from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestCreationType.g";

export function getCreateServiceRequestCommandDto(store: ServiceRequestStore, shouldSubmitServiceRequest: boolean, requestLock: boolean, isValidateOnly: boolean, emptyServiceRequest: boolean) {
    return new Proxy.CreateServiceRequestControllerDto({
        serviceRequest: new Proxy.ServiceRequestCreationDto({
            clinicalQuestion: store.clinicalQuestion,
            remark: store.remark,
            requestedServices: getProxyRequestedServices(store.requestedServices),
            requesterDoctorId: store.requesterDoctorId,
            serviceRequestDefinitionId: store.serviceRequestDefinitionVersion && store.serviceRequestDefinitionVersion.id,
            starterCareActivityId: store.starterCareActivityId,
            suspectedDiagnosisList: getProxySuspectedDiganoses(store.suspectedDiagnosisList),
            targetCareLocation: store.targetCareLocation && getProxyCareLocationDto(store.targetCareLocation),
            targetDoctorId: store.targetDoctorId,
            shouldSubmitServiceRequest: shouldSubmitServiceRequest,
            direction: store.direction,
            extensionData: store.extensionData,
            expectedCompletionTime: emptyServiceRequest && store.expectedCompletionTime,
            priority: emptyServiceRequest && store.medicalServicePriority,
            serviceRequestCreationType: emptyServiceRequest ? ServiceRequestCreationType.Empty : ServiceRequestCreationType.WithRequestedServices,
            specimenType: store.specimenTypeId,
            specimenCollectedAt: store.specimenCollectedAt,
            selectedMedicalServicePanelIds: store.selectedMedicalServicePanelIds
        }),
        requestLock: requestLock,
        isValidateOnly: isValidateOnly
    } as Proxy.ICreateServiceRequestControllerDto);
}

export function getModifyServiceRequestCommandDto(store: ServiceRequestStore, shouldSubmitServiceRequest: boolean, careActivityId: CareActivityId, lockId: LockId, releaseLockIfSuccessful: boolean, emptyServiceRequest: boolean) {
    return new Proxy.ModifyServiceRequestControllerDto({
        activeCareActivityId: careActivityId,
        serviceRequest: new Proxy.ServiceRequestModificationDto({
            clinicalQuestion: store.clinicalQuestion,
            remark: store.remark,
            requestedServices: getProxyRequestedServices(store.requestedServices),
            requesterDoctorId: store.requesterDoctorId,
            starterCareActivityId: store.starterCareActivityId,
            suspectedDiagnosisList: getProxySuspectedDiganoses(store.suspectedDiagnosisList),
            targetCareLocation: store.targetCareLocation && getProxyCareLocationDto(store.targetCareLocation),
            targetDoctorId: store.targetDoctorId,
            shouldSubmitServiceRequest: shouldSubmitServiceRequest,
            rowVersion: store.rowVersion,
            serviceRequestId: store.id,
            extensionData: store.extensionData,
            expectedCompletionTime: emptyServiceRequest && store.expectedCompletionTime,
            priority: emptyServiceRequest && store.medicalServicePriority,
            specimenType: store.specimenTypeId,
            specimenCollectedAt: store.specimenCollectedAt,
            selectedMedicalServicePanelIds: store.selectedMedicalServicePanelIds
        }),
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    } as Proxy.IModifyServiceRequestControllerDto);
}

function getProxyCareLocationDto(careLocation: CareLocation): Proxy.CareLocationDto {
    if (careLocation instanceof ExternalCareLocation) {
        return new Proxy.ExternalCareLocationDto({
            externalLocationSelector: new Proxy.EntityVersionSelectorOfExternalLocationId({
                entityId: careLocation.externalLocationSelector.id,
                validOn: careLocation.externalLocationSelector.validOn
            })
        });
    } else if (careLocation instanceof InternalCareLocation) {
        return new Proxy.InternalCareLocationDto({
            pointOfCareId: careLocation.pointOfCareId
        });
    }
    throw new Error("Unknown CareLocation type.");
}

function getProxyRequestedServices(items: RequestedServiceStore[]): Proxy.RequestedServiceCreationDto[] {
    return items.map(s => {
        return new Proxy.RequestedServiceCreationDto({
            serviceId: s.medicalServiceVersionSelector.id,
            expectedCompletionTime: s.expectedCompletionTime,
            lateralityId: s.lateralityId,
            priority: s.priority
        });
    });
}

function getProxySuspectedDiganoses(items: SuspectedDiagnosis[]): Proxy.SuspectedDiagnosisCreationDto[] {
    return items.map(d => {
        return new Proxy.SuspectedDiagnosisCreationDto({
            conditionId: d.conditionId,
            lateralityId: d.lateralityId
        });
    });
}
