import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import TextualPrescriptionTemplateMasterDetailPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/TextualPrescriptionTemplateMasterDetailPanel/TextualPrescriptionTemplateMasterDetailPanel";
import TextualPrescriptionTemplateId from "@Primitives/TextualPrescriptionTemplateId.g";
import textualPrescriptionTemplateStandaloneRouteDefinitions from "@HunEHealthInfrastructurePlugin/UseCases/PrescriptionsUseCase/TextualPrescriptionTemplateRoutes";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface ITextualPrescriptionTemplatePageDependencies {
    applicationContext: ApplicationContext;
}

interface ITextualPrescriptionTemplatePageProps extends IRoutingFrameContentProps {
    _dependencies?: ITextualPrescriptionTemplatePageDependencies;
}

@State.observer
class TextualPrescriptionTemplatePage extends React.Component<ITextualPrescriptionTemplatePageProps> {

    @State.bound
    private onNavigate(textualprescriptiontemplateId: TextualPrescriptionTemplateId) {
        this.props.routingController.replace(textualPrescriptionTemplateStandaloneRouteDefinitions.textualPrescriptionTemplates.makeRoute({ templateId: textualprescriptiontemplateId?.toUrl() }));
    }

    public render() {
        return (
            <TextualPrescriptionTemplateMasterDetailPanel
                onBack={this.props.routingController.goBack}
                onSelectedIdChanged={this.onNavigate}
                selectedId={isNullOrUndefined(this.props.routingController.currentRoute.parameters.templateId) ? null : new TextualPrescriptionTemplateId(this.props.routingController.currentRoute.parameters.templateId)}
            />
        );
    }
}

export default connect(
    withHisErrorBoundary(TextualPrescriptionTemplatePage),
    new DependencyAdapter<ITextualPrescriptionTemplatePageProps, ITextualPrescriptionTemplatePageDependencies>(container => {
        return {
            applicationContext: container.resolve("ApplicationContext")
        };
    })
);
