import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import CareActivityId from "@Primitives/CareActivityId.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import ICareActivityTextBlockListItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/ICareActivityTextBlockListItem";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import CareActivityTextBlock from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/CareActivityTextBlock";
import CareActivityTextBlockStoreMapper from "./CareActivityTextBlockStoreMapper";
import CareActivityTextBlockId from "@Primitives/CareActivityTextBlockId.g";
import ICareActivityTextBlockHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/ICareActivityTextBlockHistoryItem";
import ICareActivityPreviousTextBlockItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/ICareActivityPreviousTextBlockItem";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import PatientId from "@Primitives/PatientId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import UserId from "@Primitives/UserId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import SearchCareActivityTextBlockQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/SearchCareActivityTextBlockQueryOrderingFields.g";

@Di.injectable()
export default class CareActivityTextBlockApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("ICareRegisterClient") private apiClient: Proxy.ICareRegisterClient,
        @Di.inject("CareActivityTextBlockStoreMapper") private storeMapper: CareActivityTextBlockStoreMapper
    ) {
        super();
    }

    public getCareActivityTextBlockIdsAndTypesAsync(careActivityId: CareActivityId) {
        if (!careActivityId) {
            throw new Error("CareActivityId is required!");
        }
        return this.processOperationAsync(
            new SimpleStore<{ list: ICareActivityTextBlockListItem[], mandatoryTextBlockTypes: TextBlockTypeId[] }>(),
            async (target) => {
                const response = await this.apiClient.getCareActivityTextBlocksQueryAsync(CreateRequestId(), careActivityId.value);
                target.value = {
                    list: response?.careActivityTextBlockAndTypeIds.map(i => ({
                        careActivityTextBlockId: i.careActivityTextBlockId,
                        textBlockTypeId: i.textBlockTypeId,
                        lastModifiedBy: i.lastModifiedBy,
                        lastModifiedAt: i.lastModifiedAt
                    })),
                    mandatoryTextBlockTypes: response.mandatoryTextBlockTypes
                };
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public getAutomaticFillDocumentTypesAsync(textBlockTypeId: TextBlockTypeId, careActivityId: CareActivityId) {
        return this.processOperationAsync(
            new SimpleStore<Proxy.GetAutomaticFillDocumentContentQueryResponse>(),
            async target => {
                const response = await this.apiClient.getAutomaticFillDocumentContentQueryAsync(
                    CreateRequestId(),
                    careActivityId.value,
                    textBlockTypeId.value

                );

                target.operationInfo = createOperationInfo(response);
                target.value = response;
            }
        );
    }

    public getCareActivityTextBlockAsync(careActivityTextBlockId: CareActivityTextBlockId, requestLock: boolean = true) {
        return this.processOperationAsync(
            new CareActivityTextBlock(),
            async (target) => {
                const response = await this.apiClient.getCareActivityTextBlockCommandAsync(CreateRequestId(), new Proxy.GetCareActivityTextBlockControllerDto({
                    careActivityTextBlockId,
                    requestLock
                }));
                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public tryGetCareActivityTextBlockAsync(careActivityId: CareActivityId, textBlockTypeId: TextBlockTypeId, requestLock: boolean = true) {
        return this.processOperationAsync(
            new CareActivityTextBlock(),
            async (target) => {
                const response = await this.apiClient.tryGetCareActivityTextBlockCommandAsync(CreateRequestId(), new Proxy.TryGetCareActivityTextBlockControllerDto({
                    requestLock: requestLock,
                    careActivityId: careActivityId,
                    textBlockTypeId: textBlockTypeId
                }));
                if (response.textBlock) {
                    this.storeMapper.applyToStore(target, response);
                } else {
                    target.operationInfo = createOperationInfo(response);
                }
            }
        );
    }

    public createCareActivityTextBlockAsync(block: CareActivityTextBlock, requestLock: boolean = true, skipValidation: boolean = false) {
        return this.processOperationAsync(
            new CareActivityTextBlock(false),
            async (target) => {
                const response = await this.apiClient.createCareActivityTextBlockCommandAsync(CreateRequestId(), new Proxy.CreateCareActivityTextBlockControllerDto({
                    requestLock: requestLock,
                    careActivityId: block.careActivityId,
                    textBlockTypeId: block.textBlockTypeId,
                    content: block.contentStore.getContent(),
                    skipValidation
                }));

                this.storeMapper.applyToStore(target, response);
                target.isNew = !response.isPersisted;
            }
        );
    }

    public createCareActivityTextblockPermissionCheckAsync(careActivityId: CareActivityId) {
        return this.processOperationAsync(
            new CareActivityTextBlock(false),
            async (target) => {
                const response = await this.apiClient.createCareActivityTextBlockCommandAsync(CreateRequestId(), new Proxy.CreateCareActivityTextBlockControllerDto({
                    requestLock: false,
                    careActivityId: careActivityId,
                    textBlockTypeId: new TextBlockTypeId("-1"),
                    content: "content",
                    skipValidation: false
                }), true);

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public saveCareActivityTextblockPermissionCheckAsync(block: CareActivityTextBlock) {
        return this.processOperationAsync(
            new CareActivityTextBlock(false),
            async (target) => {
                const response = await this.apiClient.saveCareActivityTextBlockContentCommandAsync(CreateRequestId(), new Proxy.SaveCareActivityTextBlockContentControllerDto({
                    releaseLockIfSuccessful: false,
                    careActivityTextBlockId: block.id,
                    lockId: block.lockInfo.lockId,
                    newContent: block.contentStore.getContent(),
                    rowVersion: block.rowVersion,
                    skipValidation: false
                }), true);

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public saveCareActivityTextBlockAsync(block: CareActivityTextBlock, releaseLock: boolean = false, skipValidation: boolean = false) {
        return this.processOperationAsync(
            new CareActivityTextBlock(false),
            async (target) => {
                const response = await this.apiClient.saveCareActivityTextBlockContentCommandAsync(CreateRequestId(), new Proxy.SaveCareActivityTextBlockContentControllerDto({
                    releaseLockIfSuccessful: releaseLock,
                    careActivityTextBlockId: block.id,
                    lockId: block.lockInfo.lockId,
                    newContent: block.contentStore.getContent(),
                    rowVersion: block.rowVersion,
                    skipValidation
                }));

                this.storeMapper.applyToStoreWithExistingLock(target, response, block.lockInfo, releaseLock);
            }
        );
    }

    public createCareActivityTextBlockFromContentAsync(content: string, careActivityId: CareActivityId, textBlockTypeId: TextBlockTypeId, requestLock: boolean = true, skipValidation: boolean = false) {
        return this.processOperationAsync(
            new CareActivityTextBlock(false),
            async (target) => {
                const response = await this.apiClient.createCareActivityTextBlockCommandAsync(CreateRequestId(), new Proxy.CreateCareActivityTextBlockControllerDto({
                    requestLock: requestLock,
                    careActivityId: careActivityId,
                    textBlockTypeId: textBlockTypeId,
                    content: content,
                    skipValidation
                }));

                this.storeMapper.applyToStoreAndResolveValidationProblemsAsync(target, response);
                target.isNew = !response.isPersisted;
            }
        );
    }

    public saveCareActivityTextBlockFromContentAsync(blockId: CareActivityTextBlockId, content: string, lockInfo: LockInfo, rowVersion: RowVersion, releaseLock: boolean = false, skipValidation: boolean = false) {
        return this.processOperationAsync(
            new CareActivityTextBlock(false),
            async (target) => {
                const response = await this.apiClient.saveCareActivityTextBlockContentCommandAsync(CreateRequestId(), new Proxy.SaveCareActivityTextBlockContentControllerDto({
                    releaseLockIfSuccessful: releaseLock,
                    careActivityTextBlockId: blockId,
                    lockId: lockInfo.lockId,
                    newContent: content,
                    rowVersion: rowVersion,
                    skipValidation
                }));

                this.storeMapper.applyToStoreWithExistingLockAndResolveValidationProblemsAsync(target, response, lockInfo, releaseLock);
            }
        );
    }

    public getCareActivityTextBlockHistoryAsync(id: CareActivityTextBlockId) {
        return this.processOperationAsync(
            new SimpleStore<ICareActivityTextBlockHistoryItem[]>(),
            async target => {
                const response = await this.apiClient.getCareActivityTextBlockHistoryQueryAsync(CreateRequestId(), id.value);
                target.operationInfo = createOperationInfo(response);
                target.value = response.history.map(i => ({
                    createdById: i.createById,
                    createdAt: i.createdAt,
                    version: i.version
                }));
            }
        );
    }

    public getCareActivityTextBlockHistoryItemContentAsync(id: CareActivityTextBlockId, version: number) {
        return this.processOperationAsync(
            new SimpleStore<string>(),
            async target => {
                const response = await this.apiClient.getCareActivityTextBlockHistoryItemContentQueryAsync(CreateRequestId(), id.value, version.toString());
                target.operationInfo = createOperationInfo(response);
                target.value = response.content;
            }
        );
    }

    public searchCareActivityTextBlockAsync(
        careActivityId: CareActivityId,
        patientId: PatientId,
        textBlockTypeIds: TextBlockTypeId[],
        pointOfCareId: PointOfCareId,
        searchDateRange: LocalDateRange,
        lastModifiedById: UserId,
        paging: IPagingState,
        ordering: IOrderingState
    ) {
        return this.processOperationAsync(

            new PagedItemStore<ICareActivityPreviousTextBlockItem>([], 0),
            async target => {
                const queryOrdering = ordering && new Proxy.QueryOrderingOfSearchCareActivityTextBlockQueryOrderingFields({
                    fieldName: SearchCareActivityTextBlockQueryOrderingFields[ordering.columnId],
                    direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending
                });

                const requestDto = new Proxy.SearchCareActivityTextBlockControllerDto({
                    careActivityId: careActivityId,
                    patientId: patientId,
                    textBlockTypeIds: textBlockTypeIds,
                    pointOfCareId: pointOfCareId,
                    searchDateRange: searchDateRange,
                    lastModifiedById: lastModifiedById,
                    pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfSearchCareActivityTextBlockQueryOrderingFields({
                        orderings: queryOrdering ? [queryOrdering] : [],
                        paging: new Proxy.QueryPaging({ pageIndex: paging.currentPage, pageSize: paging.pageSize })
                    })
                });
                const response = await this.apiClient.searchCareActivityTextBlockQueryAsync(CreateRequestId(), requestDto);

                target.items = response.results.values.map(i => ({
                    id: i.id,
                    textBlockTypeId: i.textBlockTypeId,
                    pointOfCareId: i.pointOfCareId,
                    wentUnderCareAt: i.wentUnderCareAt,
                    lastModifiedById: i.lastModifiedById
                } as ICareActivityPreviousTextBlockItem));

                target.totalCount = response.results.totalCount;
            }
        );
    }
}