import State from "@Toolkit/ReactClient/Common/StateManaging";
import ProfessionalExamId from "@Primitives/ProfessionalExamId.g";

export default class PractitionerProfessionalExamStore {
    @State.observable.ref public professionalExamId: ProfessionalExamId;
    @State.observable public isExpired: boolean = false;

    @State.action.bound
    public setProfessionalExamId(newValue: ProfessionalExamId) {
        this.professionalExamId = newValue;
    }

    @State.action.bound
    public setIsExpired(newValue: boolean) {
        this.isExpired = newValue;
    }

    public get isEmpty() {
        return !this.professionalExamId;
    }
}
