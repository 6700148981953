import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DiagnosisListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisListStore";
import * as Ui from "@CommonControls";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import * as Styles from "./DiagnosisList.less";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import DiagnosisStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisStore";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ConditionId from "@Primitives/ConditionId.g";
import LateralityId from "@Primitives/LateralityId.g";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";
import ConditionCodeSelector from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ConditionCodeSelector";
import DiagnosisRoleSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/DiagnosisRoleSelectBox";
import { LateralitySelectBox } from "@HisPlatform/BoundedContexts/Care/Components";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import _ from "@HisPlatform/Common/Lodash";
import IDiagnosisAdditionalActionsProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IDiagnosisAdditionalActionsProps";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import PatientId from "@Primitives/PatientId.g";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import ExcludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierSystemIdFilter";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import CareActivityBaseData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareActivityBaseData";
import { IEmptyStateSettings } from "@CommonControls/DataGrid/IDataGridProps";

interface IDiagnosisListDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    localizationService: ILocalizationService;
}

interface IDiagnosisListProps {
    _dependencies?: IDiagnosisListDependencies;
    _careActivityBaseData?: CareActivityBaseData;
    _patientId?: PatientId;
    diagnosisListStore: DiagnosisListStore;
    forceReleaseLockAndLoadAsync: () => Promise<void>;
    newDiagnosis: DiagnosisStore;
    selectedDiagnosis: DiagnosisStore;
    setSelectedDiagnosis: (store: DiagnosisStore) => void;
    onAddDiagnosis: () => void;
    onDeleteDiagnosisAsync: (store: DiagnosisStore) => Promise<void>;
    readonly: boolean;

    mapValidationProblems: (validationProblem: IClientValidationProblem) => any[];
    problemFilterPredicate: (validationProblem: IClientValidationProblem) => boolean;
    validationProblemInfoRenderer: (validationProblem: IClientValidationProblem) => React.ReactNode;

    highlightedValidationProblem?: IClientValidationProblem;
    setHighlightedValidationProblem?: (id: IClientValidationProblem) => void;
    highlightableRuleIds?: string[];

    selectedDiagnosisForDetail: DiagnosisStore;
    onSelectedDiagnosisForDetailChange: (newValue: DiagnosisStore) => void;

    pointOfCareId?: PointOfCareId;
}

/** @screen */
@State.observer
class DiagnosisList extends React.Component<IDiagnosisListProps> {
    @State.computed
    private get isReadonly() {
        return this.props.readonly || !(this.props.diagnosisListStore && this.props.diagnosisListStore.isMutable);
    }

    public render() {
        return (
            <ValidationBoundary
                validationResults={this.props.diagnosisListStore?.validationResults || []}
                entityTypeName="DiagnosisList"
            >
                <>
                    {this.renderCore()}
                </>
            </ValidationBoundary>
        );
    }

    @State.bound
    public renderFirstRow() {
        return (
            <tr className={Styles.newElementPanel}>
                <th />
                <th>
                    {this.renderDiagnosisRoleEditor(this.props.newDiagnosis)}
                </th>
                <th>
                    {this.renderConditionEditor(this.props.newDiagnosis)}
                </th>
                <th>
                    {this.renderLateralityEditor(this.props.newDiagnosis)}
                </th>
                <th>
                    <Ui.Button
                        onClick={this.props.onAddDiagnosis}
                        iconName="check"
                        size="compact"
                        float="right"
                        visualMode="inverted"
                        automationId="__addNewDiagnosis"
                        className={Styles.marginRight}
                    />
                </th>
            </tr>
        );
    }

    @State.bound
    private renderCondition(versionSelector: IEntityVersionSelector<ConditionId>, d: DiagnosisStore) {
        if (!versionSelector) {
            return "";
        }
        const condition = this.props._dependencies.careReferenceDataStore.condition.get(versionSelector);
        return condition && (
            <div>
                <b>{condition.code}</b>{" " + condition.name}
                {condition.description && <div className={Styles.infoButtonContainer}>
                    <Ui.InfoButton iconName="infoCircle" tooltipContent={condition.description} />
                </div>}
            </div>
        );
    }

    @State.bound
    private renderConditionHint(value: any) {
        if (!value) {
            return "";
        }
        const condition = this.props._dependencies.careReferenceDataStore.condition.get(value);
        return condition.code + " " + condition.name;
    }

    @State.bound
    private renderLaterality(lateralityId: LateralityId, d: DiagnosisStore) {

        if (d === this.props.selectedDiagnosis) {
            return this.renderLateralityEditor(d);
        }

        if (!lateralityId) {
            return "";
        }
        const laterality = this.props._dependencies.careReferenceDataStore.laterality.get(lateralityId);
        return laterality && laterality.displayValue && laterality.displayValue.Shorthand;
    }

    @State.bound
    private renderDiagnosisRole(diagnosisRoleId: DiagnosisRoleId, d: DiagnosisStore) {

        if (d === this.props.selectedDiagnosis) {
            return this.renderDiagnosisRoleEditor(d);
        }

        if (!diagnosisRoleId) {
            return "";
        }
        const diagnosisRole = this.props._dependencies.careReferenceDataStore.diagnosisRole.get(diagnosisRoleId);
        return diagnosisRole && diagnosisRole.displayValue && diagnosisRole.displayValue.Shorthand;
    }

    private renderConditionEditor(d: DiagnosisStore) {
        const filters: FilterBase[] = [new ExcludeIdentifierSystemIdFilter(IdentifierSystemId.OncologyMorphology)];

        return (
            <ConditionCodeSelector
                onChange={d.setCondition}
                value={d.conditionVersionSelector && d.conditionVersionSelector.id}
                validOn={LocalDate.createFromMoment(this.props._careActivityBaseData.wentUnderCareAt)}
                showFavoritesAndGroup
                pointOfCareId={this.props.pointOfCareId}
                filters={filters}
                automationId="__conditionCode"
            />
        );
    }

    private renderDiagnosisRoleEditor(d: DiagnosisStore) {
        return (
            <DiagnosisRoleSelectBox
                value={d.use}
                onChange={d.setUse}
                automationId="__diagnosisRole"
            />
        );
    }

    private renderLateralityEditor(d: DiagnosisStore) {
        return (
            <LateralitySelectBox
                value={d.lateralityId}
                onChange={d.setLateralityId}
                automationId="__laterality"
            />
        );
    }

    @State.bound
    private onUpdateDiagnosisHandlerFactory(store: DiagnosisStore) {
        return () => {
            this.props.setSelectedDiagnosis(null);
        };
    }

    @State.bound
    private deleteHandlerFactory(store: DiagnosisStore) {
        return async () => {
            await this.props.onDeleteDiagnosisAsync(store);
        };
    }

    @State.bound
    private editHandlerFactory(store: DiagnosisStore) {
        return () => {
            this.props.setSelectedDiagnosis(store);
        };
    }

    @State.bound
    private getExtensionPointProps(store: DiagnosisStore): IDiagnosisAdditionalActionsProps {
        return {
            diagnosisStore: store,
            setSelectedItem: this.props.onSelectedDiagnosisForDetailChange,
            selectedItem: this.props.selectedDiagnosisForDetail,
            pointOfCareId: this.props.pointOfCareId,
            patientId: this.props._patientId,
            careActivityId: this.props.diagnosisListStore.id
        };
    }

    @State.bound
    private renderRowButtons(value: any, store: DiagnosisStore) {
        if (store !== this.props.selectedDiagnosis) {
            return (
                <div>
                    <Ui.Button
                        visualStyle="negative-standard"
                        size="compact"
                        iconName="trash"
                        onClickAsync={this.deleteHandlerFactory(store)}
                        automationId="__delete"
                        float="right"
                        className={Styles.marginRight}
                        disabled={this.isReadonly}
                    />
                    <Ui.Button
                        visualStyle="standard"
                        size="compact"
                        iconName="pen"
                        onClick={this.editHandlerFactory(store)}
                        automationId="__edit"
                        float="right"
                        disabled={this.isReadonly}
                    />
                    <HisPlatformExtensionPoint extensionProps={this.getExtensionPointProps(store)}
                        type="diagnosisAdditionalActions">
                        <></>
                    </HisPlatformExtensionPoint>
                </div>
            );
        } else {
            return (
                <Ui.Button
                    onClick={this.onUpdateDiagnosisHandlerFactory(store)}
                    iconName="check"
                    visualStyle="standard"
                    size="compact"
                    float="right"
                    automationId="__saveModifications"
                    className={Styles.marginRight}
                    disabled={this.isReadonly}
                />
            );
        }
    }

    private get emptyStateSettings() {
        return {
            message: StaticCareResources.DiagnosisList.NoRecordFound
        } as IEmptyStateSettings;
    }

    public renderCore() {
        return (
            <ValidationBoundary problemFilterPredicate={this.props.problemFilterPredicate}>
                <ReadOnlyContext.Provider
                    value={this.props.readonly || !(this.props.diagnosisListStore && this.props.diagnosisListStore.isMutable)}>
                    <Ui.DataGrid
                        dataSource={this.props.diagnosisListStore && this.props.diagnosisListStore.diagnoses || []}
                        onRenderHeader={this.props.diagnosisListStore && this.props.diagnosisListStore.isMutable && this.renderFirstRow}
                        rowHeight={44}
                        fixedHeight="100%"
                        automationId="__diagnosisList"
                        onMapValidationProblem={this.props.mapValidationProblems}
                        propertyIdentifier=".*"
                        emptyStateSettings={this.emptyStateSettings}
                        isExtraFilterVisible
                        extraFilter={(
                            <Ui.ValidationResultSummary
                                results={_.flatten(this.props.diagnosisListStore?.validationResults?.map(i => i.problems))}
                                highlightedId={this.props.highlightedValidationProblem}
                                setHighlightedId={this.props.setHighlightedValidationProblem}
                                infoRenderer={this.props.validationProblemInfoRenderer}
                                highlightableRuleIds={this.props.highlightableRuleIds}
                                excludedRuleIds="ShouldBeFilled"
                            />
                        )}
                    >
                        <DataGridColumn
                            dataGetter={"use"}
                            onRenderCellValue={this.renderDiagnosisRole}
                            header={StaticCareResources.OutpatientWorkflow.RecordedDiagnosesStep.Columns.DiagnosisReason}
                            automationId={"DiagnosisReason".split(".").pop()}
                            leftPadding="small"
                            width="150px"
                            isObserver
                        />
                        <DataGridColumn
                            dataGetter={"conditionVersionSelector"}
                            onRenderCellValue={this.renderCondition}
                            onRenderHintValue={this.renderConditionHint}
                            header={StaticCareResources.OutpatientWorkflow.RecordedDiagnosesStep.Columns.DiagnosisDescription}
                            automationId={"DiagnosisDescription".split(".").pop()}
                        />
                        <DataGridColumn
                            dataGetter={"lateralityId"}
                            onRenderCellValue={this.renderLaterality}
                            header={StaticCareResources.OutpatientWorkflow.RecordedDiagnosesStep.Columns.Laterality}
                            automationId={"Laterality".split(".").pop()}
                            width="160px"
                            isObserver
                        />
                        <DataGridColumn
                            onRenderCellValue={this.renderRowButtons}
                            width="120px"
                            header=""
                            automationId={"Actions"}
                            isObserver
                        />
                    </Ui.DataGrid>
                </ReadOnlyContext.Provider>
            </ValidationBoundary>
        );
    }
}

export default connect(
    DiagnosisList,
    new DependencyAdapter
        <IDiagnosisListProps, IDiagnosisListDependencies>(container => {
            return {
                careReferenceDataStore: container.resolve("CareReferenceDataStore"),
                localizationService: container.resolve("ILocalizationService")
            };
        }),
    new CareActivityContextAdapter<IDiagnosisListProps>(careActivityContext => ({
        _careActivityBaseData: careActivityContext?.baseData
    })),
    new PatientContextAdapter<IDiagnosisListProps>(c => ({
        _patientId: c.patientId,
    }))
);
