import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import RegisteredPatientNDataPanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/RegisteredPatientNDataPanel";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import PatientAppointmentRoutes from "@HisPlatform/Components/Pages/Patient/PatientAppointmentRoutes";
import PatientId from "@Primitives/PatientId.g";
import IPatientAppointmentListRouteParams from "@HisPlatform/Application/Routes/IPatientAppointmentListRouteParams";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { parseUseCaseStateFromUrlParam, getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";


interface IPatientAppointmentsPageProps extends IRoutingFrameContentProps {
}

@State.observer
class PatientAppointmentsPage extends React.Component<IPatientAppointmentsPageProps> {

    @State.computed private get selectedAppointmentId(): AppointmentScheduleEntryId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments[0].value;
        }

        if (this.useCaseState?.useCase.value === UseCases.newAppointment) {
            return new AppointmentScheduleEntryId("new");
    }

        return null;
    }

    @State.computed private get useCaseState() {
        const useCaseState = (this.props.routingController.currentRoute.parameters as IPatientAppointmentListRouteParams)?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.bound
    private setUseCaseState(selectedAppointmentId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.replace(
            PatientAppointmentRoutes.appointmentList.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            })
        );
    }

    @State.computed
    private get patientId() {
        return new PatientId((this.props.routingController.currentRoute.parameters as IPatientAppointmentListRouteParams)?.patientId);
    }

    @State.bound
    private  navigateToAppointment(appointmentId: AppointmentScheduleEntryId)  {
        this.props.routingController.replace(
            
            PatientAppointmentRoutes.appointmentList.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.editAppointmentFromList),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(appointmentId, "appointmentId")])
            })
        );
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private navigateToAdmission(appointmentId: AppointmentScheduleEntryId) {
        // this.props.routingController.push(ApplicationRoutes.patientAdmission.makeRoute({
        //     careActivityId: "new",
        //     patientId: this.patientId.value,
        //     appointmentId: appointmentId.value
        // }));
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(
            PatientAppointmentRoutes.appointmentList.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.patientRegisterViewAppointments),
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument(new AppointmentScheduleEntryId("null"), "appointmentId")])
            })
        );
    }

    public render() {
        return (
            <LoadingBoundary>
                <RegisteredPatientNDataPanel
                    _patientId={this.patientId}
                    onChange={this.setUseCaseState}
                    useCaseState={this.useCaseState}
                    selectedRowId={this.selectedAppointmentId?.value}
                    onSelectedAppointmentIdChange={this.navigateToAppointment}
                    onBack={this.goBack}
                    onAdmitPatient={this.navigateToAdmission}
                    onCloseDetail={this.setClosedState}
                    isAdmitPatientAllowed
                />
            </LoadingBoundary>
        );
    }
}

export default connect(
    PatientAppointmentsPage
);
