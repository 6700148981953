// tslint:disable:variable-name
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import CareActivityTextBlockScreenStore from "@HisPlatform/Packages/Care/Screens/CareActivityTextBlockScreen/CareActivityTextBlockScreenStore";

const { 
    ContextComponent: CareActivityTextBlockScreenStoreContext, 
    StoreProvider: CareActivityTextBlockScreenStoreProvider, 
    useStore: useCareActivityTextBlockScreenStore,
    provideStore: provideCareActivityTextBlockScreenStore,
} = createPanelStoreProvider(CareActivityTextBlockScreenStore);

export {
    CareActivityTextBlockScreenStoreContext,
    CareActivityTextBlockScreenStoreProvider,
    useCareActivityTextBlockScreenStore,
    provideCareActivityTextBlockScreenStore
};