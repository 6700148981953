import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IAssecoMedCommonReferenceDataResources from "./IAssecoMedCommonReferenceDataResources";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";

const StaticSAssecoMedCommonReferenceDataResources = createStaticResourceDictionary<IAssecoMedCommonReferenceDataResources>();

export function loadStaticCommonReferenceDataResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    StaticSAssecoMedCommonReferenceDataResources.load(localizationService.getResourceGroup("WebApp").CommonReferenceData);
}

export default StaticSAssecoMedCommonReferenceDataResources;