import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import * as WebAppBackendProxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import PerformedServiceListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/PerformedServiceListStore";
import PerformedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/PerformedServiceStore";
import FinancedServiceWithQuantityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/FinancedServiceWithQuantityStore";
import MedicalServiceFinancingStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/MedicalServiceFinancingStore";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import CareActivityId from "@Primitives/CareActivityId.g";
import Di from "@Di";

type ResponseType = Proxy.GetPerformedServiceListCommandResponse
                    | Proxy.UpdatePerformedServicesCommandResponse
                    | Proxy.ArrangePerformedServicesCommandResponse
                    | WebAppBackendProxy.GetPerformedServicesScreenDataCommandResponse;

@Di.injectable()
export default class PerformedServicesStoreMapper extends LockingEntityStoreMapper<Proxy.PerformedServiceListDto, PerformedServiceListStore, ResponseType> {
    protected storeEntityIdType = CareActivityId;

    protected vGetStoreIdentifier() {
        return "PerformedServiceListStore";
    }

    protected entityDtoSelector = (response: ResponseType) => {
        if (response instanceof WebAppBackendProxy.GetPerformedServicesScreenDataCommandResponse) {
            return response.performedServices;
        }

        return response.performedServiceList as Proxy.PerformedServiceListDto;
    };

    protected applyToStoreCore(target: PerformedServiceListStore, response: any) {
        const entity = this.entityDtoSelector(response);
        target.id = entity.id;
        target.financings = entity.medicalServiceFinancings.map(financing => {
            const item = new MedicalServiceFinancingStore();

            item.id = financing.id;

            item.financedServicesWithQuantities = financing.financedServicesWithQuantity.map(service => {
                const id =
                    new EntityVersionSelector(
                        service.financedServiceVersionSelector.entityId,
                        service.financedServiceVersionSelector.validOn);

                return new FinancedServiceWithQuantityStore(
                    financing.id,
                    id,
                    service.quantity,
                    service.extensionData
                );
            });

            item.performedServices = financing.performedMedicalServices.map(i => {
                const service = new PerformedServiceStore();
                service.medicalServiceId = {
                    id: i.id.entityId,
                    validOn: i.id.validOn
                };
                
                service.financingId = financing.id;

                service.medicalServiceQuantity = i.medicalServiceQuantity;
                service.lateralityId = i.lateralityId;
                service.performedAt = i.performedAt;
                service.performedBy = i.performedBy;

                return service;
            });

            return item;
        });
    }
}
