import { Container } from "@DiContainer";
import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import { configureFrontendActionMappings } from "@HisPlatform/Packages/ActionModel/FrontendActions/MapperConfigurations.g";
import TabbedScreen from "@HisPlatform/Packages/ActionModel/Screens/TabbedScreen/TabbedScreen";
import TabbedScreenApiAdapter from "@HisPlatform/Packages/ActionModel/Screens/TabbedScreen/TabbedScreenApiAdapter";
import ShowCareActivityTabbedScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityTabbedScreenAction.g";
import IScreenRegistry from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";

class ActionModelPackageInitializer implements IPackageInitializer {

    public configureDependencies(container: Container): void {
        container.bind("IActionModelClient").to(Proxy.ActionModelClient).inSingletonScope();
        container.bind("TabbedScreenApiAdapter").to(TabbedScreenApiAdapter).inSingletonScope();
    }

    public configureMapper(configurator: IMapperConfigurator): void {
        configureFrontendActionMappings(configurator);
    }

    public configureScreens(registry: IScreenRegistry): void {
        registry.register(ShowCareActivityTabbedScreenAction.id, {
            component: TabbedScreen
        });
    }
}

export default new ActionModelPackageInitializer();