import React from "react";
import * as Ui from "@CommonControls";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiEReferralCovidData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralCovidData";
import EhiEReferralCovidSymptomsSeveritySelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralCovidSymptomsSeveritySelectBox";
import EhiEReferralCovidSymptomsSeverityId from "@Primitives/EhiEReferralCovidSymptomsSeverityId.g";

interface ISymptomsViewProps {
    store: EhiEReferralCovidData;
}


const SymptomsView: React.FC<ISymptomsViewProps> = (props: ISymptomsViewProps) => {
    if (!props.store) {
        return null;
    }
    return (
        <Ui.GroupBox title={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.SymptomsTitle}>
            <Ui.Flex>
                <Ui.Flex.Item sm={4}>
                    <EhiEReferralCovidSymptomsSeveritySelectBox value={props.store.symptomsSeverityId}
                                                                onChange={props.store.setSymptomsSeverityId}
                                                                label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.SymptomsSeverityId}
                                                                propertyIdentifier="SymptomsSeverityId"
                                                                automationId="__symptomsSeverityId"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item sm={8}>
                    <></>
                </Ui.Flex.Item>
            </Ui.Flex>
            {
                (props.store.symptomsSeverityId?.value === EhiEReferralCovidSymptomsSeverityId.Severe.value ||
                props.store.symptomsSeverityId?.value === EhiEReferralCovidSymptomsSeverityId.Mild.value) &&
                <Ui.TextBox value={props.store.symptomsDescription}
                            onChange={props.store.setSymptomsDescription}
                            multiline
                            label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.SymptomsDescription}
                            propertyIdentifier="SymptomsDescription"
                            automationId="__symptomsDescription"
                />
            }
        </Ui.GroupBox>
    );
};

export default State.observer(SymptomsView);
