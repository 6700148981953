import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalConditionItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionItemBase";
import ReferencedLifeStyleRiskAssignment from "./ReferencedLifeStyleRiskAssignment";
import { LifeStyleRiskCodeSelectorItem } from "@HisPlatformControls/LifeStyleRiskCodeSelector/LifeStyleRiskCodeSelectorItem";
import CustomRiskAssignment from "./CustomRiskAssignment";
import RiskAssessmentId from "@Primitives/RiskAssessmentId.g";
import RiskAssignmentBase from "./RiskAssignmentBase";

export default class RiskAssessmentStore extends MedicalConditionItemBase<RiskAssessmentId> {

    @State.observable.ref public riskAssignment: RiskAssignmentBase;
    @State.observable public lifeStyleRiskName: string;

    constructor(isNew: boolean) {
        super(isNew);
    }

    @State.computed public get lifeStyleRiskCodeSelectorItem() {
        if (!this.riskAssignment) {
            return null;
        }
        if (this.riskAssignment instanceof ReferencedLifeStyleRiskAssignment) {
            return new LifeStyleRiskCodeSelectorItem({ id: this.riskAssignment.lifeStyleRiskId });
        } else if (this.riskAssignment instanceof CustomRiskAssignment) {
            return new LifeStyleRiskCodeSelectorItem({ text: this.riskAssignment.name });
        }
        return null;
    }

    @State.action.bound
    public setRiskAssignment(newValue: RiskAssignmentBase) {
        this.riskAssignment = newValue;
    }

    @State.action.bound
    public setLifeStyleRiskName(newValue: string) {
        this.lifeStyleRiskName = newValue;
    }
}