exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MedicationSubsidyIcon_main_2yNL1 {\n  display: inline-block;\n  width: 22px;\n  height: 22px;\n  padding: 0;\n  line-height: 22px;\n}\n.MedicationSubsidyIcon_upper-index_2sJPW {\n  position: relative;\n  width: 16px;\n  height: 16px;\n  top: -12px;\n  left: -4px;\n  line-height: 16px;\n}\n.MedicationSubsidyIcon_state-empty_2H2Qm {\n  background-color: #8695a7;\n  color: white;\n}\n", ""]);

// exports
exports.locals = {
	"main": "MedicationSubsidyIcon_main_2yNL1",
	"upper-index": "MedicationSubsidyIcon_upper-index_2sJPW",
	"upperIndex": "MedicationSubsidyIcon_upper-index_2sJPW",
	"state-empty": "MedicationSubsidyIcon_state-empty_2H2Qm",
	"stateEmpty": "MedicationSubsidyIcon_state-empty_2H2Qm"
};