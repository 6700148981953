import { IValidationProblemParameterMapper } from "@Toolkit/CommonWeb/ApiAdapter/IValidationProblemParameterMapper";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import TelecomTypeId from "@Primitives/TelecomTypeId.g";

@Di.injectable()
export default class PatientValidationProblemMapper implements IValidationProblemParameterMapper {

    constructor(
        @Di.inject("CommonReferenceDataDataStore") private referenceDataStore: CommonReferenceDataDataStore
    ) { }

    public collectValidationProblemParameterReferenceData(vp: IClientValidationProblem) {
        return;
    }

    @State.bound
    public async loadCollectedValidationProblemParameterReferenceDataAsync(): Promise<void> {
        await this.referenceDataStore.telecomType.ensureLoadedAsync();
    }

    @State.bound
    public resolveValidationProblemParameters(vp: IClientValidationProblem): { [id: string]: string; } {
        const results: { [id: string]: string; } = {};
        for (const key in vp.parameters) {
            if (key) {
                switch (key) {
                    case "TelecomTypeId":
                        const telecomType = this.referenceDataStore.telecomType.get(new TelecomTypeId(vp.parameters.TelecomTypeId.Value));
                        if (telecomType) {
                            results.TelecomType = telecomType.displayValue.Name;
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        return results;
    }
}