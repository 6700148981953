import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { ExtensibleEnumSelectBox } from "@HisPlatform/Components/HisPlatformControls";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatform/Components/HisPlatformControls/ExtensibleEnumSelectBox";

interface IEquipmentPositionSelectBoxDependencies {
    medicationRequestReferenceDataStore: MedicationRequestReferenceDataStore;
}

interface IEquipmentPositionSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IEquipmentPositionSelectBoxDependencies;
}

@State.observer
class EquipmentPositionSelectBox extends React.Component<IEquipmentPositionSelectBoxProps> {

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.props._dependencies.medicationRequestReferenceDataStore.equipmentPosition}
            />
        );
    }
}

export default connect(
    EquipmentPositionSelectBox,
    new DependencyAdapter<IEquipmentPositionSelectBoxProps, IEquipmentPositionSelectBoxDependencies>(c => ({
        medicationRequestReferenceDataStore: c.resolve("MedicationRequestReferenceDataStore")
    }))
);