exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Backdrop_container_1Mic7 {\n  position: fixed;\n  display: flex;\n  -webkit-box-align: center;\n  align-items: center;\n  -webkit-box-pack: center;\n  justify-content: center;\n  inset: 0px;\n  background-color: rgba(0, 0, 0, 0.5);\n  -webkit-tap-highlight-color: transparent;\n  color: #ffffff;\n  z-index: 1201;\n}\n", ""]);

// exports
exports.locals = {
	"container": "Backdrop_container_1Mic7"
};