import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import * as Ui from "@CommonControls";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import MedicalConditionItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionItemBase";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import { LockIndicatorComponent } from "@HisPlatformControls";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";

interface IMedicalConditionItemHeaderPanelDependencies {
}

interface IMedicalConditionItemHeaderPanelProps {
    _dependencies?: IMedicalConditionItemHeaderPanelDependencies;

    store: MedicalConditionItemBase<StringEntityId>;
    onDeleteHistoryItemAsync: () => Promise<boolean>;
    onSaveStoreAsync: () => Promise<boolean>;
    onLockEditClickedAsync: () => Promise<void>;
}

@State.observer
class MedicalConditionItemHeaderPanel extends React.Component<IMedicalConditionItemHeaderPanelProps> {

    private get store() { return this.props.store; }
    @State.computed private get isNew() { return this.store.id.value === "new"; }
    @State.computed private get canDelete() { return this.store.historyItems.length > 0; }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IMedicalConditionItemHeaderPanelProps) {
        if (prevProps.store !== this.props.store) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.action
    
    private async loadAsync() {
    }

    @State.action.bound
    private cancelHistoryItem() {
        this.store.removeNewVersion();
    }

    public render() {
        if (!this.store) {
            return null;
        }

        return (

            <ReadOnlyContext.Provider value={false}>
                <Ui.Flex>
                    <Ui.Flex.Item sm={12} horizontalContentAlignment="right">
                        <LockIndicatorComponent
                            locked={!this.store.isMutable}
                            lockedBy={this.store.lockInfo?.preventingLockUserId}
                            onEditClickedAsync={this.props.onLockEditClickedAsync}
                        />
                        {!this.store.hasNewVersion && this.store.isMutable &&
                            <>
                                {this.store.isLatestItemSelected &&
                                    <Ui.Button
                                        text={StaticCareResources.MedicalCondition.Common.Label.AddNewVersion}
                                        onClick={this.store.addNewVersion}
                                        size="compact"
                                        automationId="__addNewVersion"
                                        visualStyle={"primary"}
                                        disabled={!this.store.isMutable}
                                        permissionCheckOperationNames="AddNewVersion"
                                        permissionDeniedStyle="disabled"
                                    />}
                                {this.canDelete &&
                                    <Ui.Button
                                        text={StaticCareResources.Common.Button.Delete}
                                        onClickAsync={this.props.onDeleteHistoryItemAsync}
                                        size="compact"
                                        automationId="__deleteHistoryItem"
                                        disabled={!this.store.isMutable}
                                        permissionCheckOperationNames="DeleteVersion"
                                        permissionDeniedStyle="disabled"
                                    />}
                            </>}
                        {this.store.hasNewVersion &&
                            <>
                                <Ui.Button
                                    text={StaticCareResources.Common.Button.Save}
                                    onClickAsync={this.props.onSaveStoreAsync}
                                    size="compact"
                                    automationId="__addHistoryItem"
                                    visualStyle={"primary"}
                                    disabled={!this.store.isMutable}
                                    permissionCheckOperationNames="AddNewVersion"
                                    permissionDeniedStyle="disabled"
                                />
                                {!this.isNew &&
                                    <Ui.Button
                                        text={StaticCareResources.Common.Button.Cancel}
                                        onClick={this.cancelHistoryItem}
                                        size="compact"
                                        automationId="__cancel"
                                        disabled={!this.store.isMutable}
                                    />}
                            </>}
                    </Ui.Flex.Item>
                </Ui.Flex>
            </ReadOnlyContext.Provider>
        );
    }
}

export default connect(
    MedicalConditionItemHeaderPanel,
    new DependencyAdapter<IMedicalConditionItemHeaderPanelProps, IMedicalConditionItemHeaderPanelDependencies>(c => ({
    }))
);
