import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MasterDetailMaster from "@CommonControls/Layout/MasterDetailMaster";
import { Button, DataGrid } from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { useFormDefinitionMasterDetailPanelStore } from "./FormDefinitionMasterDetailPanelStoreProvider";
import IFormDefinitionListItem from "@Toolkit/FormEngine/Model/IFormDefinitionListItem";
import StaticFormEditingResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";

/** @screen */
function FormDefinitionList() {

    const store = useFormDefinitionMasterDetailPanelStore();
    return (
        <MasterDetailMaster
            title={StaticFormEditingResources.FormDefinitionList.Title}
            passStateToChild={false}
            toolbar={(
                <>
                    <Button text={StaticFormEditingResources.FormDefinitionList.Button.CreateNew} iconName="plus" automationId="addNewFormDefinition" onClick={store.createNew} />
                </>
            )}
        >
            <DataGrid
                dataSource={store.listItems}
                fixedHeight="100%"
                onRowClick={store.selectListItem}
                selectedRow={store.selectedListItem}
                automationId="formDefinitionList_dataGrid"
            >
                <DataGridColumn header={StaticFormEditingResources.FormDefinitionList.Column.Name} dataGetter={"displayName"} />
            </DataGrid>
        </MasterDetailMaster>
    );
}

export default State.observer(FormDefinitionList);