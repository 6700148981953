import _ from "@HisPlatform/Common/Lodash";
import Di from "@Di";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import IFormExtensionRegistry from "@PluginInterface/FormExtension/IFormExtensionRegistry";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { IInvoiceScreenProps } from "./InvoiceScreen";
import InvoiceScreenApiAdapter from "./InvoiceScreenApiAdapter";
import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import IFormLogicRegistry, { IFormLogicExecutor } from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import HisPermissionScopes from "@HisPlatform/Common/FrontendActions/HisPermissionScopes";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import LockAcquirerOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockAcquirerOperationInfo";
import ShowCreateNewInvoiceScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowCreateNewInvoiceScreenAction.g";
import ShowInvoiceScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowInvoiceScreenAction.g";
import SaveInvoiceAction from "@HisPlatform/Packages/Patients/FrontendActions/SaveInvoiceAction.g";
import InvoiceState from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/InvoiceState";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ReferencedExtensibleEnumFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedExtensibleEnumFormFieldData";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import PaymentTypeId from "@Primitives/PaymentTypeId.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { ChangeAction } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";
import DeleteInvoiceAction from "@HisPlatform/Packages/Patients/FrontendActions/DeleteInvoiceAction.g";
import InvoiceId from "@Primitives/InvoiceId.g";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import LockAcquisitionError from "@Toolkit/CommonWeb/Model/LockAcquisitionError";
import DialogResult from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResult";
import ILockingNotificationService from "@HisPlatform/BoundedContexts/Locking/Services/Definition/ILockingNotificationService";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";

@Di.injectable()
export default class InvoiceScreenStore extends EditorScreenPanelStoreBase<IInvoiceScreenProps> implements ILoadablePanelStore {
    @State.observable.ref public form: IForm = null;
    @State.observable.ref public invoiceState: InvoiceState = null;
    @State.observable.ref public logicInProgress = false;

    private formEngineDefinition: IFormDefinition;
    private formLogicExecutor: IFormLogicExecutor = null;

    @State.computed
    public get hasPermissionForSaveInvoice() {
        return this.authorizationService.hasPermissionForDescriptor(this.saveInvoiceAction);
    }

    @State.computed
    public get formDefinitionId() {
        return this.form?.definitionId;
    }

    @State.computed
    public get isNewInvoiceScreen() {
        return this.showScreenAction instanceof ShowCreateNewInvoiceScreenAction;
    }

    @State.computed
    public get isEditInvoiceScreen() {
        return this.showScreenAction instanceof ShowInvoiceScreenAction
            && !this.showScreenAction.isReadOnly;
    }

    @State.computed
    public get isReadOnlyInvoiceScreen() {
        return this.showScreenAction instanceof ShowInvoiceScreenAction
            && this.showScreenAction.isReadOnly;
    }

    @State.computed
    public get mustFinalizeInvoiceOnFulfillmentDay(): boolean {
        if (isNullOrUndefined(this.form?.data?.Content)) {
            return false;
        }

        const paymentTypeIdField = getField<ReferencedExtensibleEnumFormFieldData>(this.form.data.Content, "BaseData.PaymentTypeId");
        if (isNullOrUndefined(paymentTypeIdField)) {
            return false;
        }

        return paymentTypeIdField.value === PaymentTypeId.Cash.value;
    }

    @State.computed
    public get mustFinalizeInvoiceIn8Days(): boolean {
        if (isNullOrUndefined(this.form?.data?.Content)) {
            return false;
        }

        const paymentTypeIdField = getField<ReferencedExtensibleEnumFormFieldData>(this.form.data.Content, "BaseData.PaymentTypeId");
        if (isNullOrUndefined(paymentTypeIdField)) {
            return false;
        }

        return paymentTypeIdField.value === PaymentTypeId.Transfer.value;
    }

    @State.computed
    public get saveInvoiceAction() {
        if (!this.form) {
            return ActionDescriptor.fromAction(new SaveInvoiceAction());
        }

        const pointOfCareIdRawValue = getField<ReferencedEntityFormFieldData>(this.form.data.Content, "PointOfCareId")?.value;
        return ActionDescriptor.fromAction(new SaveInvoiceAction(), HisPermissionScopes.pointOfCare(pointOfCareIdRawValue?.toString()));
    }

    @State.computed
    public get deleteInvoiceAction() {
        if (!this.form) {
            return ActionDescriptor.fromAction(new DeleteInvoiceAction());
        }

        const pointOfCareIdRawValue = getField<ReferencedEntityFormFieldData>(this.form.data.Content, "PointOfCareId")?.value;
        return ActionDescriptor.fromAction(new DeleteInvoiceAction(), HisPermissionScopes.pointOfCare(pointOfCareIdRawValue?.toString()));
    }

    @State.computed
    public get showInfoBox(): boolean {
        return this.mustFinalizeInvoiceOnFulfillmentDay || this.mustFinalizeInvoiceIn8Days;
    }

    @State.computed
    public get highlightedValidationProblems(): IClientValidationProblem[] {
        return this.validationResults?.map(i => i.problems).flat().filter(i => i.ruleId === "FulfilledOnAndMustFulfillByMustMatchWhenPaidInCash") || [];
    }

    @State.computed
    public get vIsReadOnly() {
        return this.invoiceState === InvoiceState.Issued
            || this.canAcquireLock
            || (this.isNewInvoiceScreen && !this.hasPermissionForSaveInvoice)
            || (this.isEditInvoiceScreen && !this.hasPermissionForSaveInvoice)
            || this.isReadOnlyInvoiceScreen;
    }

    @State.computed
    protected get contentToDirtyCheck() {
        return [this.form?.data.Content];
    }

    protected get showScreenAction(): ShowScreenFrontendActionBase {
        return this.props.action;
    }

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("InvoiceScreenApiAdapter") private readonly apiAdapter: InvoiceScreenApiAdapter,
        @Di.inject("AuthorizationService") private readonly authorizationService: AuthorizationService,
        @Di.inject("IFormLogicRegistry") private readonly formLogicRegistry: IFormLogicRegistry,
        @Di.inject("IFormEngineReferenceDataStore") private readonly formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("IContainerService") private readonly containerService: IContainerService,
        @Di.inject("IFormExtensionRegistry") private readonly formExtensionRegistry: IFormExtensionRegistry,
        @Di.inject("ILockingNotificationService") private readonly lockingNotificationService: ILockingNotificationService) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    public async loadCoreAsync(): Promise<void | { loadedSignals?: string[]; }> {
        if (this.props.action instanceof ShowCreateNewInvoiceScreenAction) {
            const response = await this.apiAdapter.getNewInvoiceDataAsync(this.props.action.patientId);
            this.setForm(response.result.form);
            State.runInAction(() => {
                this.invoiceState = null;
            });
        } else if (this.props.action instanceof ShowInvoiceScreenAction) {
            const response = await this.apiAdapter.getInvoiceScreenDataAsync(this.props.action.invoiceId, this.isEditInvoiceScreen);
            if (response.operationInfo instanceof LockAcquirerOperationInfo) {
                State.runInAction(() => this.lockInfo = (response.operationInfo as LockAcquirerOperationInfo).lockInfo);
            }

            if (this.isReadOnlyInvoiceScreen) {
                this.vSetValidationResults(response.result.form.validationResults);
            }

            this.setForm(response.result.form);
            State.runInAction(() => {
                this.invoiceState = response.result.invoiceState;
            });
        }

        this.formEngineDefinition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(this.form.definitionId);

        const loadedSignals: string[] = [];
        const formExtensions = this.formExtensionRegistry.resolveAll(this.containerService, "InvoiceScreen");
        formExtensions.forEach(i => {
            const items = (i.invokeCallback("GetLoadedSignals", {}) as string[]);
            loadedSignals.push(..._.flatten(items));
        });

        this.formLogicExecutor = this.formLogicRegistry.getExecutorForForm(
            this.form,
            this.showScreenAction,
            this.formEngineDefinition.name,
            this.formEngineDefinition,
            State.action(() => this.logicInProgress = true),
            State.action(() => this.logicInProgress = false));

        await this.formLogicExecutor.formLoadedAsync();

        return {
            loadedSignals: loadedSignals
        };
    }

    public readonly onDataChangeAsync = this.backgroundAsync((propertyIdentifier: string, value: any, action: ChangeAction) => this.formLogicExecutor.fieldChangedAsync(action, propertyIdentifier, value));

    public onValidateAsync: () => Promise<IClientValidationResult[]> = this.backgroundAsync(async () => {
        if (!this.form || this.vIsReadOnly) {
            return [];
        }

        let validationResults: IClientValidationResult[];
        if (this.showScreenAction instanceof ShowCreateNewInvoiceScreenAction) {
            const result = await this.apiAdapter.createInvoiceAsync(this.showScreenAction.patientId, this.form, false, true);
            validationResults = result.result.form.validationResults;
        } else if (this.showScreenAction instanceof ShowInvoiceScreenAction) {
            const result = await this.apiAdapter.updateInvoiceAsync(this.showScreenAction.invoiceId, this.form, null, false, true);
            validationResults = result.result.form.validationResults;
        }

        this.vSetValidationResults(validationResults);

        return validationResults ?? [];
    });

    @State.bound
    public onCancelAsync(): Promise<any> {
        this.props._screenState.cancelled();
        return Promise.resolve();
    }

    @State.bound
    public async onSaveAsync(): Promise<any> {
        return await this.createOrUpdateAsync(false);
    }

    @State.bound
    public async onFinalizeAsync(): Promise<any> {
        return await this.createOrUpdateAsync(true);
    }

    @State.bound
    public async onDeleteAsync(): Promise<void> {
        if (!this.form) {
            return;
        }

        const invoiceIdField = getField<ReferencedEntityFormFieldData>(this.form.data.Content, "InvoiceId");
        if (!invoiceIdField || !invoiceIdField.value) {
            return;
        }

        const dialogResult = await this.dialogService.yesNoCancel(
            StaticWebAppResources.Common.DialogTitle.ConfirmationTitle,
            StaticFinanceResources.InvoiceScreen.DeleteInvoiceConfirmationDialog.Text
        );

        if (dialogResult.resultCode !== DialogResultCode.Yes) {
            return;
        }

        const result = await this.handleSaveResultAsync(() => this.apiAdapter.deleteInvoiceAsync(new InvoiceId(`${invoiceIdField.value}`)));
        if (result.isPersisted) {
            this.props._screenState.deleted();
        }
    }

    private async createOrUpdateAsync(shouldIssue: boolean) {
        try {
            return await this.createOrUpdateCoreAsync(shouldIssue);
        }
        catch (err) {
            if (!!err.error && err.error instanceof LockAcquisitionError) {
                const lockError = err.error as LockAcquisitionError;
                if (lockError.lockId) {
                    const owner = await this.lockingNotificationService.formatNameForLockingMessageAsync(lockError.lockOwnerUserId);
                    const result = await this.dialogService.yesNo(
                        StaticCareResources.Common.Dialog.LockDoesNotExistsErrorTitle,
                        formatStringWithObjectParams(
                            StaticFinanceResources.InvoiceScreen.Warnings.ForceReleasePerformedServicesLockWarning,
                            {
                                UserName: owner
                            })
                    );

                    if (result.resultCode === DialogResultCode.Yes) {
                        await this.lockingApiAdapter.forceReleaseLockAsync(lockError.lockId);
                        return await this.createOrUpdateCoreAsync(shouldIssue);
                    }
                }
            }
            throw err;
        }
    }

    private async createOrUpdateCoreAsync(shouldIssue: boolean) {
        const action = this.props.action;
        if (action instanceof ShowCreateNewInvoiceScreenAction) {
            const result = await this.handleSaveResultAsync(() => this.apiAdapter.createInvoiceAsync(action.patientId, this.form, shouldIssue, false));
            this.setForm(result.result.form);

            if (result.result.issueErrorMessage) {
                this.dialogService.ok(StaticWebAppResources.Common.Label.Error, result.result.issueErrorMessage);
            }

            if (result.isPersisted) {
                this.props._screenState.savedNew(result.result.createdInvoiceId, new ShowInvoiceScreenAction(action.displayMode, result.result.createdInvoiceId, false));
            }

            return result.isPersisted;
        }

        if (action instanceof ShowInvoiceScreenAction) {
            const result = await this.handleSaveResultAsync(() => this.apiAdapter.updateInvoiceAsync(action.invoiceId, this.form, this.lockInfo?.lockId, shouldIssue, false));

            if (result.result.issueErrorMessage) {
                this.dialogService.ok(StaticWebAppResources.Common.Label.Error, result.result.issueErrorMessage);
            }

            if (result.isPersisted) {
                this.setForm(result.result.form);
                State.runInAction(() => {
                    this.invoiceState = result.result.invoiceState;
                });
                this.props._screenState.savedExisting();
            }

            return result.isPersisted;
        }

        return false;
    }

    protected saveFunction: () => Promise<boolean> = this.onSaveAsync;

    @State.action.bound
    private setForm(form: IForm) {
        this.form = form;
    }
}