import Di from "@Di";
import ITokenRepository from "@Toolkit/CommonWeb/Repositories/Definition/TokenRepository/ITokenRepository";
import ITokenStorageModel from "@Toolkit/CommonWeb/Repositories/Definition/TokenRepository/ITokenStorageModel";
import AccessToken from "@Toolkit/CommonWeb/Authentication/Definition/AccessToken";

@Di.injectable()
export default class TokenRepository implements ITokenRepository {

    private static storageKey = "access_token";
    private static get storage() {
        return sessionStorage;
    }

    public saveAccessToken(token: AccessToken): void {
        TokenRepository.storage.setItem(TokenRepository.storageKey, JSON.stringify({
            token: token.tokenValue,
            expiresOn: token.expiresOn.format(),
            refreshToken: token.refreshTokenValue
        } as ITokenStorageModel));
    }

    public loadAccessToken(): AccessToken {
        const stringData = TokenRepository.storage.getItem(TokenRepository.storageKey);
        
        if (stringData) {

            const raw: ITokenStorageModel = JSON.parse(stringData);

            if (raw) {
                return AccessToken.LoadBack(raw.token, raw.expiresOn, raw.refreshToken);
            }
        }

        return null;
    }

    public clearAccessToken(): void {
        TokenRepository.storage.removeItem(TokenRepository.storageKey);
    }

}