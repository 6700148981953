import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import HunCareReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/HunCareReferenceDataStore";

interface ITravelExpenseClaimTypeSelectBoxDependencies {
    careReferenceDataStore: HunCareReferenceDataStore;
}

interface ITravelExpenseClaimTypeSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: ITravelExpenseClaimTypeSelectBoxDependencies;
}

@State.observer
class TravelExpenseClaimTypeSelectBox extends React.Component<ITravelExpenseClaimTypeSelectBoxProps> {

    private get dependencies() {
        return this.props._dependencies;
    }

    private get extensibleEnumStore() {
        return this.dependencies.careReferenceDataStore.travelExpenseClaimTypes;
    }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    TravelExpenseClaimTypeSelectBox,
    new DependencyAdapter<ITravelExpenseClaimTypeSelectBoxProps, ITravelExpenseClaimTypeSelectBoxDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("HunCareReferenceDataStore")
        };
    })
);
