import _ from "@HisPlatform/Common/Lodash";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class PermanentPrescriptionFilterStore {
    @State.observable.ref private _organizationUnitIds: OrganizationUnitId[] = [];
    private readonly onChangeDebounced = _.debounce(this.onFilterChanged, 2000);

    @State.computed
    public get organizationUnitIds(): OrganizationUnitId[] {
        return this._organizationUnitIds;
    }

    constructor(
        private onFilterChanged: () => void) {
    }

    @State.action.bound
    public addOrganizationUnitId(newValue: OrganizationUnitId) {
        if (this._organizationUnitIds.some(id => id.value === newValue.value)) {
            return;
        }

        this.setOrganizationUnitIds([...this._organizationUnitIds, newValue]);
    }

    @State.action.bound
    public removeOrganizationUnitId(value: OrganizationUnitId) {
        if (!this._organizationUnitIds.some(id => id.value === value.value)) {
            return;
        }

        this.setOrganizationUnitIds(this._organizationUnitIds.filter(id => id.value !== value.value));
    }

    @State.action.bound
    public setOrganizationUnitIds(value: OrganizationUnitId[]): void {
        this._organizationUnitIds = value;
        this.onChangeDebounced();
    }
}