import EhiToken from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiToken";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export interface IEhiServiceStateStore {
    ehiToken: EhiToken;
    organizationUnitId: OrganizationUnitId;

    setEhiToken(ehiToken: EhiToken): void;
    setOrganizationUnitId(organizationUnitId: OrganizationUnitId): void;
}

export class EhiServiceStateStore implements IEhiServiceStateStore {
    public ehiToken: EhiToken;
    public organizationUnitId: OrganizationUnitId;

    @State.bound
    public setEhiToken(ehiToken: EhiToken): void {
        this.ehiToken = ehiToken;
    }

    @State.bound
    public setOrganizationUnitId(organizationUnitId: OrganizationUnitId): void {
        this.organizationUnitId = organizationUnitId;
    }
}