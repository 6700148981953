import DependencyContainer from "@DiContainer";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import SchedulingStoreMapper from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingStoreMapper";
import SchedulingServiceRequestStoreMapper from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/ServiceRequestStoreMapper";
import SchedulingConfigurationApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingConfigurationApiAdapter";
import ScheduleDefinitionStoreMapper from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/ScheduleDefinitionStoreMapper";
import SchedulingServiceStoreMapper from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingServiceStoreMapper";
import ResourceBlockingStoreMapper from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/ResourceBlockingStoreMapper";
import ResourceManagementApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/ResourceManagementApiAdapter";

DependencyContainer
    .bind("SchedulingApiAdapter")
    .to(SchedulingApiAdapter);

DependencyContainer
    .bind("SchedulingStoreMapper")
    .to(SchedulingStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("ResourceBlockingStoreMapper")
    .to(ResourceBlockingStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("ResourceManagementApiAdapter")
    .to(ResourceManagementApiAdapter);

DependencyContainer
    .bind("SchedulingServiceRequestStoreMapper")
    .to(SchedulingServiceRequestStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("SchedulingConfigurationApiAdapter")
    .to(SchedulingConfigurationApiAdapter);

DependencyContainer
    .bind("ScheduleDefinitionStoreMapper")
    .to(ScheduleDefinitionStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("SchedulingServiceStoreMapper")
    .to(SchedulingServiceStoreMapper)
    .inSingletonScope();
