export default {
    MorphologyIdentifier: "OncologyMorphology",
    OthInsuranceCode: "OTH",
    HunSocialSecurityInsuranceCode: "Neak",
    DocumentIdentifierSystems: {
        HunSocialSecurityNumber: "HunSocialSecurityNumber",
        NewbornHunSocialSecurityNumber: "NewbornHunSocialSecurityNumber",
        ReplacementHunSocialSecurityNumber: "ReplacementHunSocialSecurityNumber"
    }
};
