import React, { useMemo } from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import EditableDataElementEditorStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableDataElementEditorStore";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import DefaultValuePanel from "./DefaultValuePanel";
import FieldValidationPanel from "./FieldValidationPanel";
import EntityReferenceEditor from "./EntityReferenceEditor";
import StringFormDataElement from "@Toolkit/FormEngine/Model/Schema/StringFormDataElement";
import ReferencedEntityFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEntityFormDataElement";
import ReferencedExtensibleEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedExtensibleEnumFormDataElement";
import EnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/EnumFormDataElement";
import NumberFormDataElement from "@Toolkit/FormEngine/Model/Schema/NumberFormDataElement";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import Language from "@Primitives/Language";
import ReferencedExtensibleEnumEditor from "./ReferencedExtensibleEnumEditor";
import ReferencedEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEnumFormDataElement";
import ReferencedEnumEditor from "./ReferencedEnumEditor";

function EditorPropertyPanel() {

    const store = useFormLayoutEditorStore();
    const availableEditorTypes = useMemo<Array<ISelectBoxItem<string>>>(() => {
        return store.formEditorRegistry.getNewToolboxEditorItems().map(i => ({
            text: i.displayName,
            value: i.editorType,
        } as ISelectBoxItem<string>));
    }, []);

    if (!store.selectedEditor || !(store.selectedEditor instanceof EditableDataElementEditorStore)) {
        return null;
    }

    return (
        <Ui.GroupBox title={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.Title} style={{ margin: 4 }}>

            <Ui.TextBox
                label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.LabelDataElementName}
                value={store.selectedEditor.dataReference}
                onChange={store.setEditorDataElementName}
                automationId="EditorDataElementName_TextBox"
            />

            <Ui.Flex>
                <Ui.Flex.Item xs={7}>
                    <Ui.TextBox
                        label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.LabelEditorLabel}
                        value={store.selectedEditor.localizedLabel}
                        onChange={store.selectedEditor.setLocalizedLabel}
                        automationId="EditorLabel_TextBox"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={5}>
                    <Ui.UniversalEnumSelector
                        label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.LanguageSelectorLabel}
                        enumName={"Language"}
                        enumType={Language}
                        enumOrigin="client"
                        value={store.selectedEditor.currentLabelLanguage}
                        onChange={store.selectedEditor.setCurrentLabelLanguage}
                        automationId="_LanguageSelector"
                        clearable={false}
                    />
                </Ui.Flex.Item>
            </Ui.Flex>

            <Ui.SelectBox
                label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.LabelEditorType}
                value={store.selectedEditor.editorType}
                onChange={store.setEditorType}
                automationId="EditorType_SelectBox"
                items={availableEditorTypes}
            />

            <Ui.CheckBox
                label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.IsReadOnlyLabel}
                value={store.selectedDataElement.isReadOnly ?? false}
                onChange={store.selectedDataElement.setIsReadOnly}
                automationId="EditorIsReadOnly_CheckBox"
            />

            <Ui.CheckBox
                label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.IsArrayLabel}
                value={store.selectedDataElement.isArray}
                onChange={store.selectedDataElement.setIsArray}
                automationId="EditorIsArray_CheckBox"
            />

            <Ui.CheckBox
                label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.IsVisibleLabel}
                value={store.selectedDataElement.isVisible ?? true}
                onChange={store.selectedDataElement.setIsVisible}
                automationId="EditorIsVisible_CheckBox"
            />

            {store.selectedDataElement instanceof NumberFormDataElement && (
                <Ui.CheckBox
                    automationId="IsInteger_CheckBox"
                    value={store.isIntegerNumber}
                    label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.IsIntegerNumberLabel}
                    onChange={store.setIntegerNumber}
                />
            )}

            {store.selectedDataElement instanceof StringFormDataElement && (
                <Ui.CheckBox
                    automationId="IsLongText_CheckBox"
                    value={store.isLongText}
                    label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.IsLongTextLabel}
                    onChange={store.setLongText}
                />
            )}

            {store.selectedDataElement instanceof EnumFormDataElement && (
                <Ui.Button
                    automationId="EditFormEnumReference_Button"
                    text={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.EditFormEnumReferenceButton}
                    onClick={store.editFormEnumReferenceAsync.fireAndForget}
                />
            )}

            {store.selectedDataElement instanceof ReferencedEntityFormDataElement && (
                <EntityReferenceEditor />
            )}

            {store.selectedDataElement instanceof ReferencedExtensibleEnumFormDataElement && (
                <ReferencedExtensibleEnumEditor />
            )}

            {store.selectedDataElement instanceof ReferencedEnumFormDataElement && (
                <ReferencedEnumEditor />
            )}

            <Ui.Button
                automationId="EntityPropertyPanel_FormFieldDependency_Button"
                text={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.FormFieldDependencyButton}
                onClick={store.openFormFieldDependencyModalAsync}
            />

            <DefaultValuePanel />

            <FieldValidationPanel />
        </Ui.GroupBox>
    );
}
export default State.observer(EditorPropertyPanel);