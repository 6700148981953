import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FormDataElementBase from "./FormDataElementBase";

export default class ReferencedEntityFormDataElement extends FormDataElementBase {

    @State.observable.ref public referencedEntity: string;
    @State.observable.ref public filters: FilterBase[];

    constructor(
        name: string,
        isArray: boolean,
        defaultValue: number,
        isReadOnly: boolean,
        isVisible: boolean,
        referencedEntity?: string,
        public customParams?: {[key: string]: string},
        filters?: FilterBase[]
    ) {
        super(name, isArray, defaultValue, isReadOnly, isVisible);
        this.referencedEntity = referencedEntity;
        this.filters = filters;
    }    
    
    @State.action.bound
    public setReferencedEntity(referencedEntity: string) {
        this.referencedEntity = referencedEntity;
    }

    @State.action.bound
    public setFilters(filters: FilterBase[]) {
        this.filters = filters;
    }
    
    public formDataElementType = "ReferencedEntity";
}