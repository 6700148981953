import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCases from "@Primitives/UseCases";
import SurgeryTypeDefinitionId from "@Primitives/SurgeryTypeDefinitionId.g";
import SurgeryTypeDefinitionPanel from "@HisPlatform/BoundedContexts/Surgery/Components/Panels/SurgeryTypeDefinitionPanel";
import SurgeryPanel from "@HisPlatform/BoundedContexts/Surgery/Components/Panels/SurgeryPanel";
import SurgeryId from "@Primitives/SurgeryId.g";
import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import PatientRoutes from "@HisPlatform/Components/Pages/Patient/PatientRoutes";

export default function configureSurgeryUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2({
        identifiers: [UseCases.surgeryTypeDefinitionDetail],
        component: SurgeryTypeDefinitionPanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));
            const id = map.get("surgeryTypeDefinitionId") ?? new SurgeryTypeDefinitionId("new");
            return {
                selectedId: id
            };
        }
    });

    useCaseRegistry.add2({
        identifiers: [UseCases.surgeryDetail],
        component: SurgeryPanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));
            const id = map.get("surgeryId") ?? new SurgeryId("new");
            return {
                selectedId: id
            };
        }
    });

    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            UseCases.surgeryForPatientDetail,
            builder => {
                builder.standalone(b => b
                    .routeDefinition(PatientRoutes.surgeryForPatient)
                    .additionalRouteParamsFactory(params => ({
                        patientId: params.useCaseArguments.find(arg => arg.name === "patientId").value.value,
                        mode: "read-write",
                        appointmentId: "null"
                    }))
                );
            }
        )
    );
}
