import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import PatientRegisterReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/PatientRegisterReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IDeathPlaceTypeSelectBoxDependencies {
    patientRegisterReferenceDataStore: PatientRegisterReferenceDataStore;
}

interface IDeathPlaceTypeSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IDeathPlaceTypeSelectBoxDependencies;
}


@State.observer
class DeathPlaceTypeSelectBox extends React.Component<IDeathPlaceTypeSelectBoxProps> {
    private get dependencies() { return this.props._dependencies; }
    private get extensibleEnumStore() { return this.dependencies.patientRegisterReferenceDataStore.deathPlaceType; }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    DeathPlaceTypeSelectBox,
    new DependencyAdapter<IDeathPlaceTypeSelectBoxProps, IDeathPlaceTypeSelectBoxDependencies>( (containerService) => {
        return {
            patientRegisterReferenceDataStore: containerService.resolve("PatientRegisterReferenceDataStore")
        };
    })
);
