import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import PatientName from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/PatientName";
import PatientId from "@Primitives/PatientId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import moment from "moment";
import IWorklistItemContextInfo from "./IWorklistItemMetadata";

export default class CareActivityWorklistItemContextInfo implements IWorklistItemContextInfo {
    constructor(
        public readonly patientName: PatientName | null,
        public readonly careActivityState: CareActivityState,
        public readonly careIntervalFrom: moment.Moment | null,
        public readonly careIntervalTo: moment.Moment | null,
        public readonly mostRelevantPractitionerId: PractitionerId | null,
        public readonly pointOfCareId: PointOfCareId | null,
        public readonly careIdentifier: string | null,
        public readonly mergedPatientId: PatientId | null,
        public readonly birthDate: LocalDate,
    ) {}
}