import Guid from "@Toolkit/CommonWeb/Guid";
import IEditableLayoutStore from "./IEditableLayoutStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import moment from "moment";
import TokenDefaultValue from "@Toolkit/FormEngine/Model/Layout/TokenDefaultValue";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import EditableMultiLingualLabel from "@Toolkit/FormEngine/Model/Layout/EditableMultiLingualLabel";
import Language, { convertCultureCodeToLanguage, convertLanguageToCultureCode, getCurrentOrDefaultCultureCode } from "@Primitives/Language";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export default class EditableDataElementEditorStore implements IEditableLayoutStore {

    public readonly id: string;
    public parentDataReferences: string[] = [];
    @State.observable.ref public editorType: string;
    @State.observable.ref public dataReference: string;
    @State.observable public multiLingualLabel: EditableMultiLingualLabel;
    @State.observable public currentLabelLanguage: Language;
    @State.observable.ref public defaultValue: string | LocalDate | TimeOfDay | moment.Moment | number | TokenDefaultValue | null;

    constructor(
        editorType: string,
        currentCultureCode: string,
        multiLingualLabel?: EditableMultiLingualLabel
    ) {
        this.id = Guid.newGuid();
        this.editorType = editorType;
        this.dataReference = null;

        if (!isNullOrUndefined(multiLingualLabel)) {
            this.multiLingualLabel = multiLingualLabel;
            this.setCurrentLabelLanguage(getCurrentOrDefaultCultureCode(currentCultureCode, this.multiLingualLabel));
        } else {
            this.multiLingualLabel = new EditableMultiLingualLabel();
            this.currentLabelLanguage = convertCultureCodeToLanguage(currentCultureCode);
            this.multiLingualLabel.setLocalizedLabel(convertLanguageToCultureCode(this.currentLabelLanguage), StaticFormEngineResources.FormDefinitionEditor.Default.NewEditorDefaultLabel);
        }

        this.defaultValue = null;
    }

    @State.action.bound
    public setDataReference(dataReference: string) {
        this.dataReference = dataReference;
    }

    @State.action.bound
    public setLocalizedLabel(label: string) {
        this.multiLingualLabel.setLocalizedLabel(convertLanguageToCultureCode(this.currentLabelLanguage), label);
    }

    @State.computed
    public get localizedLabel() {
        return this.multiLingualLabel.getLocalizedLabel(convertLanguageToCultureCode(this.currentLabelLanguage));
    }

    @State.action.bound
    public setCurrentLabelLanguage(currentLabelLanguage: Language) {
        this.currentLabelLanguage = currentLabelLanguage;
    }

    @State.action.bound
    public setEditorType(editorType: string) {
        this.editorType = editorType;
    }

    @State.action.bound
    public setDefaultValue(value: any) {
        this.defaultValue = value;
    }

    @State.action.bound
    public addParentDataReference(value: string) {
        if (isNullOrUndefined(this.parentDataReferences)) {
            this.parentDataReferences = [value];
        } else {
            this.parentDataReferences.push(value);
        }
    }

    public clone() {
        const newStore = new EditableDataElementEditorStore(this.editorType, convertLanguageToCultureCode(this.currentLabelLanguage));
        this.multiLingualLabel.localizedLabels.forEach(l => newStore.setLocalizedLabel(l.get()));
        newStore.dataReference = this.dataReference;
        newStore.defaultValue = this.defaultValue;
        return newStore;
    }

}
