import Di from "@Di";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import { ICustomerScreenProps } from "./CustomerScreen";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LockAcquirerOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockAcquirerOperationInfo";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import CustomerScreenApiAdapter from "./CustomerScreenApiAdapter";
import ShowCreateNewCustomerScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowCreateNewCustomerScreenAction.g";
import ShowCustomerScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowCustomerScreenAction.g";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import CustomerId from "@Primitives/CustomerId.g";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import IFormLogicRegistry from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";

@Di.injectable()
export default class CustomerScreenStore extends EditorScreenPanelStoreBase<ICustomerScreenProps> {
    @State.observable.ref public form: IForm = null;
    public formEngineDefinition: IFormDefinition;
    private formLogics: IFormLogic[] = [];

    @State.computed
    protected get showScreenAction() {
        return this.props.action;
    }

    @State.computed
    protected get contentToDirtyCheck() {
        return [this.form?.data.Content];
    }

    @State.computed
    public get vIsReadOnly() {
        return this.canAcquireLock;
    }

    @State.computed
    public get isCreateNewCustomerScreen() {
        return this.props.action instanceof ShowCreateNewCustomerScreenAction;
    }

    @State.computed
    public get isCustomerScreen() {
        return this.props.action instanceof ShowCustomerScreenAction;
    }

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("CustomerScreenApiAdapter") private customerScreenApiAdapter: CustomerScreenApiAdapter,
        @Di.inject("IFormEngineReferenceDataStore") private formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("IFormLogicRegistry") private formLogicRegistry: IFormLogicRegistry) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    @State.bound
    public async loadCoreAsync() {
        if (this.isCreateNewCustomerScreen) {
            const response = await this.customerScreenApiAdapter.getNewCustomerScreenDataAsync();
            this.setForm(response.result);
        } else {
            const requestLock = this.isCustomerScreen;
            const response = await this.customerScreenApiAdapter.getCustomerScreenDataAsync((this.props.action as ShowCustomerScreenAction).customerId, requestLock);

            if (response.operationInfo instanceof LockAcquirerOperationInfo) {
                State.runInAction(() => this.lockInfo = (response.operationInfo as LockAcquirerOperationInfo).lockInfo);
            }

            this.vSetValidationResults(response.result.validationResults);
            this.setForm(response.result);
        }

        this.formEngineDefinition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(this.form.definitionId);
        this.formLogics = this.formLogicRegistry.getAll(this.formEngineDefinition.name);
        await this.executeFormLogicAsync();
    }

    public readonly onDataChangeAsync = this.async((propertyIdentifier: string, value: any) => {
        return this.executeFormLogicAsync();
    });

    @State.bound
    public async onSaveAsync(): Promise<any> {
        if (this.isCreateNewCustomerScreen) {
            return await this.onCreateAsync();
        } else {
            return await this.onUpdateAsync(false);
        }
    }

    @State.bound
    public async onCreateAsync(): Promise<any> {
        const saveResult = await this.handleSaveResultAsync(() => this.customerScreenApiAdapter.createCustomerAsync(this.form));

        if (saveResult.isPersisted) {
            const idField = getField<ReferencedEntityFormFieldData>(saveResult.result.data.Content, "CustomerId");
            const newId = new CustomerId(idField.value.toString());
            this.props._screenState.savedNew(newId, new ShowCustomerScreenAction(this.props.action.displayMode, newId));
        }

        return saveResult.isPersisted;
    }

    @State.bound
    public async onUpdateAsync(releaseLock: boolean): Promise<any> {
        const saveResult =
            await this.handleSaveResultAsync<IForm>(() => this.customerScreenApiAdapter.updateCustomerAsync(this.form, releaseLock, this.lockInfo));

        this.setForm(saveResult.result);
        this.vSetValidationResults(saveResult.result.validationResults);

        if (saveResult.isPersisted) {
            // TODO: confirmed by BA that the whole locking is not needed for Customer
            //State.runInAction(() => this.lockInfo = null);
            this.props._screenState.savedExisting();
        }

        return saveResult.isPersisted;
    }

    @State.bound
    public async onDeleteAsync(): Promise<any> {
        const message = StaticFinanceResources.Customers.Messages.DeleteConfirmation;
        const canDeleteAnswer = await this.dialogService.yesNo(StaticCareResources.Common.Dialog.ConfirmationTitle, message);
        if (canDeleteAnswer.resultCode === DialogResultCode.Yes) {
            const saveResult =
                await this.handleSaveResultAsync(() => this.customerScreenApiAdapter.deleteCustomerAsync(
                    (this.props.action as ShowCustomerScreenAction).customerId,
                    this.form,
                    this.lockInfo));

            if (saveResult.isPersisted) {
                this.props._screenState.deleted();
            }
        }
    }

    @State.bound
    public onCancelAsync(): Promise<any> {
        this.props._screenState.cancelled();
        return Promise.resolve();
    }

    @State.bound
    public async onValidateAsync(): Promise<IClientValidationResult[]> {
        if (!this.form) {
            return [];
        }

        let formValidationResult: IForm;
        if (this.isCreateNewCustomerScreen) {
            const operationResult = await this.customerScreenApiAdapter.createCustomerAsync(this.form, true);
            formValidationResult = operationResult.result;
        } else {
            const operationResult = await this.customerScreenApiAdapter.updateCustomerAsync(this.form, false, this.lockInfo, true);
            formValidationResult = operationResult.result;
        }

        return formValidationResult.validationResults;
    }

    @State.action.bound
    private setForm(form: IForm) {
        this.form = form;
    }

    @State.action.bound
    private readonly executeFormLogicAsync = this.async(async () => {
        for (const formLogic of this.formLogics) {
            await formLogic.executeAsync(this.form, this.showScreenAction);
        }
    });

    protected saveFunction: () => Promise<boolean> = this.onSaveAsync;
}