exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Sidebar_no-text-selection_1Fg_G {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.Sidebar_sidebar-container_2uYLK {\n  position: absolute;\n  top: 0;\n  right: 0;\n  width: 32px;\n  height: 100%;\n  box-shadow: -2px 0 5px 0 rgba(0, 0, 0, 0.1);\n  background-color: white;\n}\n.Sidebar_sidebar_8S2dF {\n  height: 32px;\n  display: flex;\n  flex-direction: row;\n  transform-origin: 100% 100%;\n  transform: translate(-100%, 0) rotate(90deg);\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "Sidebar_no-text-selection_1Fg_G",
	"noTextSelection": "Sidebar_no-text-selection_1Fg_G",
	"sidebar-container": "Sidebar_sidebar-container_2uYLK",
	"sidebarContainer": "Sidebar_sidebar-container_2uYLK",
	"sidebar": "Sidebar_sidebar_8S2dF"
};