import Di from "@Di";
import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import HunCareApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/HunCareApiAdapter";
import HunCareContagiousPatientReportApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ContagiousPatientReport/HunCareContagiousPatientReportApiAdapter";
import NursingLocationTypeId from "@Primitives/NursingLocationTypeId.g";
import ContagiousPatientReportOutcomeTypeId from "@Primitives/ContagiousPatientReportOutcomeTypeId.g";

@Di.injectable()
export default class HunCareReferenceDataStore extends ReferenceDataStoreBase {

    public performedServiceClassificationMap = new ExtensibleEnumStore(this.careApiAdapter.getPerformedServiceClassificationIdsAsync, this.localizationService.localizeEnumId);
    public ctMrExaminationRequestTypes = new ExtensibleEnumStore(this.careApiAdapter.getCtMrExaminationRequestTypeIdsAsync, this.localizationService.localizeEnumId);
    public earningCapacityConsiderationTypes = new ExtensibleEnumStore(this.careApiAdapter.getEarningCapacityConsiderationTypeIdsAsync, this.localizationService.localizeEnumId);
    public laboratoryExaminationRequestTypes = new ExtensibleEnumStore(this.careApiAdapter.getLaboratoryExaminationRequestTypeIdsAsync, this.localizationService.localizeEnumId);
    public physiotherapyExaminationRequestTypes = new ExtensibleEnumStore(this.careApiAdapter.getPhysiotherapyExaminationRequestTypeIdsAsync, this.localizationService.localizeEnumId);
    public travelExpenseClaimTypes = new ExtensibleEnumStore(this.careApiAdapter.getTravelExpenseClaimTypeIdsAsync, this.localizationService.localizeEnumId);
    public imagingExaminationRequestTypes = new ExtensibleEnumStore(this.careApiAdapter.getImagingExaminationRequestTypeIdsAsync, this.localizationService.localizeEnumId);
    public nursingLocationTypes = new ExtensibleEnumStore<NursingLocationTypeId>(this.contagiousPatientReportApiAdapter.getNursingLocationTypeIdsAsync, this.localizationService.localizeEnumId);
    public contagiousPatientReportOutcomeTypes = new ExtensibleEnumStore<ContagiousPatientReportOutcomeTypeId>(this.contagiousPatientReportApiAdapter.getContagiousPatientreportOutcomeTypeIdsAsync, this.localizationService.localizeEnumId);
    public inpatientAdmissionTypeMap = new ExtensibleEnumStore(this.careApiAdapter.getInpatientAdmissionTypeIdsAsync, this.localizationService.localizeEnumId);
    public inpatientAdmissionTypeByCareAspectMap = new ExtensibleEnumStore(this.careApiAdapter.getInpatientAdmissionTypeByCareAspectIdsAsync, this.localizationService.localizeEnumId);
    public inpatientAdmissionTypeByLegalAspectMap = new ExtensibleEnumStore(this.careApiAdapter.getInpatientAdmissionTypeByLegalAspectIdsAsync, this.localizationService.localizeEnumId);

    constructor(
        @Di.inject("HunCareApiAdapter") private readonly careApiAdapter: HunCareApiAdapter,
        @Di.inject("HunCareContagiousPatientReportApiAdapter") private readonly contagiousPatientReportApiAdapter: HunCareContagiousPatientReportApiAdapter,
        @Di.inject("ILocalizationService") private localizationService: ILocalizationService,
    ) {
        super();
    }
}
