import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import DosageEditor, { IDosageEditorProps } from "./DosageEditor";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";

interface IExtendedDosageEditorProps extends IDosageEditorProps {
    isSubstitutable: boolean;
    setIsSubstitutable: (newValue: boolean) => void;
    inSubstitutableReason: string;
    setInSubstitutableReason: (newValue: string) => void;
    instruction: string;
    setInstruction: (newValue: string) => void;
    showReason?: boolean;

    showEquippedAndTextualDosage?: boolean;
    textualDosage?: string;
    setTextualDosage?: (newValue: string) => void;
    equipped?: number;
    setEquipped?: (newValue?: number) => void;
}

/** @screen */
@State.observer
export default class ExtendedDosageEditor extends React.Component<IExtendedDosageEditorProps> {

    public render() {
        return (
            <>
                {this.props.dosage && <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <DosageEditor
                            dosage={this.props.dosage}
                            setDosage={this.props.setDosage}
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={6} style={{ paddingTop: 56 }}>
                                <Ui.TextBox
                                    value={this.props.dosage.dosageMode}
                                    onChange={this.props.dosage.setDosageMode}
                                    label={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.Labels.DosageMode}
                                    propertyIdentifier="Dosage.DosageMode"
                                    automationId="dosageModeTextBox"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Flex.Item>
                </Ui.Flex>}
                <Ui.TextBox
                    label={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.Labels.Instruction}
                    value={this.props.instruction}
                    onChange={this.props.setInstruction}
                    propertyIdentifier="Instruction"
                    multiline
                    automationId="instructionTextBox"
                    showMultipleValidationProblems
                />
                {this.props.showEquippedAndTextualDosage &&
                    <Ui.Flex>
                        <Ui.Flex.Item xs={8}>
                            <Ui.TextBox
                                value={this.props.textualDosage}
                                onChange={this.props.setTextualDosage}
                                label={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.Labels.TextualDosage}
                                propertyIdentifier="TextualDosage,MedicationReference.TextualDosage"
                                automationId="textualDosageTextBox"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.NumberBox
                                value={this.props.equipped}
                                onChange={this.props.setEquipped}
                                label={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.Labels.Equipped}
                                propertyIdentifier="Equipped"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>}

                {this.props.dosage && this.props.showReason && <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        <Ui.TextBox
                            value={this.props.dosage.reason}
                            onChange={this.props.dosage.setReason}
                            label={StaticHunSocialSecurityMedicationRequestResources.DosageEditor.Reason}
                            propertyIdentifier="Dosage.Reason"
                            automationId="dosageReasonTextBox"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>}
            </>
        );
    }
}
