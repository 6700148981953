import ICurrentCultureRepository from "@Toolkit/CommonWeb/Repositories/Definition/CurrentCultureRepository/ICurrentCultureRepository";
import Di from "@Di";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import UserId from "@Primitives/UserId.g";
import IDashboard from "@HisPlatform/BoundedContexts/Dashboards/ApplicationLogic/Model/Dashboards/IDashboard";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import config from "@Config";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";

@Di.injectable()
export default class ApplicationContext implements ICurrentCultureProvider {
    @State.observable.ref public currentUseCaseIdentifier: UseCaseIdentifier = null;
    @State.observable.ref public cultureCode = config.defaultCultureCode;
    @State.observable.ref public dashboardConfigStore: Map<string, SimpleStore<IDashboard>> = new Map<string, SimpleStore<IDashboard>>();
    @State.observable.ref public dashboardConfigStoreKey: string = null;
    @State.observable.ref private _isUnderMaintenance: boolean = false;

    @State.computed public get twoLetterISOLanguageName() {
        return this.cultureCode.substring(0, 2);
    }
    public onCultureCodeChanged = new TypedEvent();
    public feedbackDialogOpenEvent = new TypedEvent();

    @State.observable.ref public spike_showAssecoRooms = false;
    @State.observable.ref public isApplicationInformationModalOpen = false;
    @State.observable.ref public contextualOrganizationUnitId: OrganizationUnitId | null = null;

    
    constructor(
        @Di.inject("ICurrentCultureRepository") private readonly currentCultureRepository: ICurrentCultureRepository,
    ) {
        this.setCurrentCulture(this.currentCultureRepository.getCurrentCulture() || config.defaultCultureCode);
    }

    @State.action.bound
    public setUnderMaintenance() {
        this._isUnderMaintenance = true;
    }

    public get isUnderMaintenance(): boolean {
        return this._isUnderMaintenance;
    }

    @State.action
    public setDashboardConfigKey(currentPointOfCareIdOrWorklistDefinitionId: string, userId: UserId, currentUseCaseIdentifier: string) {
        if (!isNullOrEmptyString(currentUseCaseIdentifier) && !isNullOrEmptyString(currentPointOfCareIdOrWorklistDefinitionId) && !isNullOrUndefined(userId)) {
            this.dashboardConfigStoreKey = `${currentUseCaseIdentifier}+${currentPointOfCareIdOrWorklistDefinitionId}+${userId.value}`;
        }
    }

    @State.action
    public setDashboardConfig(dashboard: SimpleStore<IDashboard>, currentPointOfCareIdOrWorklistDefinitionId: string, userId: UserId, currentUseCaseIdentifier: string) {
        if (dashboard && !isNullOrUndefined(currentPointOfCareIdOrWorklistDefinitionId) && !isNullOrUndefined(userId)) {
            this.setDashboardConfigKey(currentPointOfCareIdOrWorklistDefinitionId, userId, currentUseCaseIdentifier);
            if (!this.dashboardConfigStore.has(this.dashboardConfigStoreKey)) {
                this.dashboardConfigStore.set(this.dashboardConfigStoreKey, dashboard);
            }
        }
    }

    public getDashboardConfig(currentPointOfCareIdOrWorklistDefinitionId: string, userId: UserId, currentUseCaseIdentifier: string): SimpleStore<IDashboard> {
        this.setDashboardConfigKey(currentPointOfCareIdOrWorklistDefinitionId, userId, currentUseCaseIdentifier);
        return this.dashboardConfigStore.get(this.dashboardConfigStoreKey);
    }

    @State.action
    public setCurrentCulture(cultureCode: string) {
        const oldCultureCode = this.cultureCode;
        this.cultureCode = cultureCode;
        if (oldCultureCode !== cultureCode) {
            this.currentCultureRepository.saveCurrentCulture(this.cultureCode);
            this.onCultureCodeChanged.emit(null);
        }
        document.documentElement.lang = cultureCode.substr(0, 2);
    }

    @State.action
    public setCurrentUseCase(useCaseIdentifier: UseCaseIdentifier) {
        if (isNullOrUndefined(useCaseIdentifier?.value)) {
            this.currentUseCaseIdentifier = null;
        } else if (!ValueWrapper.equals(useCaseIdentifier, this.currentUseCaseIdentifier)) {
            this.currentUseCaseIdentifier = useCaseIdentifier;
        }
    }

    @State.action.bound
    public spike_toggleAssecoRooms() {
        this.spike_showAssecoRooms = !this.spike_showAssecoRooms;
    }

    @State.action.bound
    public openApplicationInformationModal() {
        this.isApplicationInformationModalOpen = true;
    }

    @State.action.bound
    public closeApplicationInformationModal() {
        this.isApplicationInformationModalOpen = false;
    }

    @State.bound
    public openFeedbackDialog() {
        this.feedbackDialogOpenEvent.emit();
    }
}