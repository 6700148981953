import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import MedicationId from "@Primitives/MedicationId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import TextualPrescriptionTemplateId from "@Primitives/TextualPrescriptionTemplateId.g";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import PermanentPrescriptionId from "@Primitives/PermanentPrescriptionId.g";

export interface IMedicationSelectorDialogParams extends IModalParams {
    alreadyAddedMedications: MedicationId[];
    careActivityId: CareActivityId;
    validOn: LocalDate;
}

export interface IMedicationSelectorDialogResult {
    medicationAndClaimTypeIds: Array<{ medicationId: MedicationId, claimTypeId: MedicationSubsidyClaimTypeId }>;
    templateIds: TextualPrescriptionTemplateId[];
    medicalFormulas: Array<{ name: string, claimTypeId: MedicationSubsidyClaimTypeId, isMedicalFormula: boolean }>;
    permanentPrescriptionIds: PermanentPrescriptionId[];
}

export default class MedicationSelectorDialogParams implements IMedicationSelectorDialogParams {
    public static type = "MedicationSelectorDialog";
    public get type() { return MedicationSelectorDialogParams.type; }

    constructor(public alreadyAddedMedications: MedicationId[], public careActivityId: CareActivityId, public validOn: LocalDate) { }
}
