// tslint:disable:variable-name
import DocumentScreenStore from "./DocumentScreenStore";
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import { editorScreenRenderFunc } from "@Toolkit/CommonWeb/PanelStore/EditorScreenRenderFunc";

const {
    ContextComponent: DocumentScreenStoreContext, 
    StoreProvider: DocumentScreenStoreProvider, 
    useStore: useDocumentScreenStore,
    provideStore: provideDocumentScreenStore,
} = createPanelStoreProvider<DocumentScreenStore>(DocumentScreenStore, null, editorScreenRenderFunc);

export {
    DocumentScreenStoreContext,
    DocumentScreenStoreProvider,
    useDocumentScreenStore,
    provideDocumentScreenStore
};