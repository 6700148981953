import { ITokenLockService } from "@HisPlatform/BoundedContexts/DocumentManagement/Services/Definition/ITokenLockService";
import TokenLockInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TokenLockInfo";
import Di from "@Di";
import CareActivityTextBlockApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityTextBlock/CareActivityTextBlockApiAdapter";
import CareActivityTextBlockId from "@Primitives/CareActivityTextBlockId.g";
import { EntityLockState } from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import ScopeIdentifier from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ScopeIdentifier";
import CareActivityId from "@Primitives/CareActivityId.g";
import TemplateApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Templating/TemplateApiAdapter";
import DocumentFile from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentFile";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import TokenApiAdapter from "@HisPlatform/BoundedContexts/TokenBasedDataGathering/ApplicationLogic/ApiAdapter/Token/TokenApiAdapter";
import DocumentScope from "@HisPlatform/BoundedContexts/DocumentManagement/Api/ReferenceData/Enum/DocumentScope.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class TokenLockService implements ITokenLockService {

    constructor(
        @Di.inject("CareActivityTextBlockApiAdapter") private careActivityTextBlockApi: CareActivityTextBlockApiAdapter,
        @Di.inject("TemplateApiAdapter") private templateApiAdapter: TemplateApiAdapter,
        @Di.inject("CareReferenceDataStore") private careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("TokenApiAdapter") private tokenApiAdapter: TokenApiAdapter
    ) { }

    public async acquireLocksAsync(locks: TokenLockInfo[]): Promise<boolean> {

        await this.careReferenceDataStore.textBlockType.ensureLoadedAsync();

        for (const tokenLock of locks) {
            if (tokenLock.isNew) { continue; }
            await this.acquireLockAsync(tokenLock);
        }

        return !locks.some(it => !it?.isNew && it?.acquiredLockInfo.lockState === EntityLockState.LockingRequiredAndLockNotHeld);
    }

    public getMissingLockDetails(locks: TokenLockInfo[]): string {
        const missingLocks = locks.filter(it => it.acquiredLockInfo?.lockState === EntityLockState.LockingRequiredAndLockNotHeld);
        let lockDetails = "";
        for (const missingLock of missingLocks) {
            lockDetails += `${this.getTokenDisplayValue(missingLock)} (${missingLock.acquiredLockInfo?.preventingLockOwnerName})\n`;
        }

        return lockDetails;
    }

    private getTokenDisplayValue(lock: TokenLockInfo) {
        if (isNullOrUndefined(lock.sourceDescription) || !lock.sourceDescription.includes(":")) {
            return "";
        }

        const splitString = lock.sourceDescription.split(":");
        if (splitString[0] === "TextBlockTypeId") {
            const textBlockType = this.careReferenceDataStore.textBlockType.get(new TextBlockTypeId(splitString[1]));
            return textBlockType.displayValue.Name;
        }

        return "";
    }

    public async updateLockSourcesAsync(locks: TokenLockInfo[], documentFile: DocumentFile, scopeIdentifiers: ScopeIdentifier[], releaseLock: boolean) {
        const result = await this.templateApiAdapter.extractTokenContentAsync(documentFile, locks.map(it => it.tokenReference));
        const extracts = result.value;
        let isAllPersisted = true;

        for (const tokenLock of locks) {
            const extract = extracts.find(it => it.token === tokenLock.tokenReference);
            if (tokenLock.isNew && extract.isEmpty) { continue; }
            const isPersisted = await this.updateLockSourceAsync(tokenLock, extract.content, scopeIdentifiers, releaseLock);
            const newRowVersion = tokenLock.acquiredRowVersion;
            const key = `${tokenLock.tokenReference}_RowVersion`;
            documentFile.metadata[key] = newRowVersion.value.toString();
            isAllPersisted = isAllPersisted && isPersisted;
        }
        return isAllPersisted;
    }

    private async updateLockSourceAsync(tokenLock: TokenLockInfo, content: any, scopeIdentifiers: ScopeIdentifier[], releaseLock: boolean): Promise<boolean> {
        const result = await this.tokenApiAdapter.getTokenByNameAsync(tokenLock.tokenName);
        const token = result.value;

        if (token?.tokenValueProviderAddress.toUpperCase() === "Platform.Care.CareRegister".toUpperCase() &&
            token?.providersName.toUpperCase() === "TextBlock".toUpperCase()) {
            const textBlockTypeId = TextBlockTypeId.fromJS(JSON.parse(token.providersParameter));
            if (tokenLock.isNew) {
                const careActivityId = new CareActivityId(scopeIdentifiers.find(it => it.documentScope === DocumentScope.CareActivity)?.identifier.toString());
                const resp = await this.careActivityTextBlockApi.createCareActivityTextBlockFromContentAsync(content, careActivityId, textBlockTypeId, true);
                State.runInAction(() => {
                    tokenLock.acquiredRowVersion = resp.rowVersion;
                    tokenLock.acquiredLockInfo = resp.lockInfo;
                    tokenLock.sourceId = resp.id.value;
                    tokenLock.validationResults = resp.validationResults;
                });
                return resp.isPersistedByOperationInfo;
            } else {
                const textBlockId = new CareActivityTextBlockId(tokenLock.sourceId);
                const resp = await this.careActivityTextBlockApi.saveCareActivityTextBlockFromContentAsync(textBlockId, content, tokenLock.acquiredLockInfo,
                    tokenLock.acquiredRowVersion, releaseLock);
                tokenLock.validationResults = resp.validationResults;
                tokenLock.acquiredRowVersion = resp.rowVersion;
                return resp.isPersistedByOperationInfo;
            }
        } else {
            throw new Error(`Unknown tokenLock providerAddress=${token?.tokenValueProviderAddress}, providerName=${token?.providersName}`);
        }
    }

    private async acquireLockAsync(tokenLock: TokenLockInfo): Promise<void> {
        const result = await this.tokenApiAdapter.getTokenByNameAsync(tokenLock.tokenName);
        const token = result.value;

        if (token?.tokenValueProviderAddress.toUpperCase() === "Platform.Care.CareRegister".toUpperCase() &&
            token?.providersName.toUpperCase() === "TextBlock".toUpperCase()) {
            const textBlockId = new CareActivityTextBlockId(tokenLock.sourceId);
            const resp = await this.careActivityTextBlockApi.getCareActivityTextBlockAsync(textBlockId, true);
            State.runInAction(() => {
                tokenLock.acquiredLockInfo = resp.lockInfo;
                tokenLock.acquiredRowVersion = resp.rowVersion;
                const textBlockType = this.careReferenceDataStore.textBlockType.get(resp.textBlockTypeId);
                tokenLock.sourceDescription = textBlockType.displayValue.Name;
            });
        } else {
            throw new Error(`Unknown tokenLock providerAddress=${token?.tokenValueProviderAddress}, providerName=${token?.providersName}`);
        }
    }
}   