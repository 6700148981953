import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import {SelectBox} from "@CommonControls";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import {dispatchAsyncErrors} from "@Toolkit/CommonWeb/AsyncHelpers";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import PatientDocumentTypeId from "@Primitives/PatientDocumentTypeId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IPatientDocumentTypeSelectBoxDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IPatientDocumentTypeSelectBoxProps extends ISelectBoxBaseProps {
    includeNotSelectable?: boolean;
    _dependencies?: IPatientDocumentTypeSelectBoxDependencies;
}

@State.observer
class PatientDocumentTypeSelectBox extends React.Component<IPatientDocumentTypeSelectBoxProps> {

    @State.computed
    private get items() {
        let items = this.props._dependencies.careReferenceDataStore.patientDocumentType.items;
        if (!this.props.includeNotSelectable) {
            items = items.filter(item => item.Entity.isSelectable);
        }
        return items.map(idt => {
            return {
                text: idt.Localization.Name,
                value: idt.Entity.id
            } as IPatientDocumentTypeSelectBoxItem;
        });
    }

    public render() {
        return (
            <SelectBox
                {...this.props}
                items={this.items}
            />
        );
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.props._dependencies.careReferenceDataStore.patientDocumentType.ensureAllLoadedAsync(), this);
    }
}

interface IPatientDocumentTypeSelectBoxItem extends ISelectBoxItem<PatientDocumentTypeId> {

}

export default connect(
    PatientDocumentTypeSelectBox,
    new DependencyAdapter<IPatientDocumentTypeSelectBoxProps, IPatientDocumentTypeSelectBoxDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore")
        };
    })
);
