import PointOfCareId from "@Primitives/PointOfCareId.g";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { ScreenStateContextAdapter, ScreenStateContextStore } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import { provideDischargePatientScreenStore, useDischargePatientScreenStore } from "./DischargePatientScreenStoreProvider";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import * as Ui from "@CommonControls";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import LockIndicatorComponent from "@HisPlatformControls/LockIndicatorComponent";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import ExternalLocationCodeSelector from "@HisPlatform/BoundedContexts/Organization/Components/Controls/ExternalLocationCodeSelector";
import DischargeReasonSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/DischargeReasonSelectBox";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ShowDischargePatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowDischargePatientScreenAction.g";
import ShowEhrDischargePatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEhrDischargePatientScreenAction.g";
import ShowReadOnlyDischargePatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyDischargePatientScreenAction.g";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import PatientId from "@Primitives/PatientId.g";
import ToolbarButtons from "@Toolkit/ReactClient/Components/ToolbarButtons/ToolbarButtons";
import FormExtensionRegistryAdapter from "@PluginInterface/FormExtension/FormExtensionRegistryAdapter";
import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";


export interface IDischargePatientScreenProps extends IScreenPropsBase<
    ShowDischargePatientScreenAction |
    ShowReadOnlyDischargePatientScreenAction |
    ShowEhrDischargePatientScreenAction> {
    _screenState?: ScreenStateContextStore;
    _pointOfCareId?: PointOfCareId;
    _patientId?: PatientId;
}

function DischargePatientScreen(props: IDischargePatientScreenProps) {

    const store = useDischargePatientScreenStore();

    const isReadonly = store.vIsReadOnly || store.isEhrDischargePatientScreen;

    let title;
    if (isReadonly) {
        title = StaticCareResources.CareRegister.CareActivityDischargeDataPanel.ReadOnlyTitle;
    } else {
        title = StaticCareResources.CareRegister.CareActivityDischargeDataPanel.Title;
    }


    return (

        <Screen
            title={title}
            toolbar={
                <ToolbarButtons
                    beforeButtons={
                        (store.canAcquireLock && !store.isEhrDischargePatientScreen && !store.isReadonlyDischargePatientScreen) && (
                            <LockIndicatorComponent
                                locked={true}
                                onLoadClickedAsync={store.loadAsync}
                                lockedBy={store.lockInfo?.preventingLockUserId}
                                preventingLockId={store.lockInfo?.preventingLockId} />
                        )
                    }
                    onCancelAsync={store.onCancelAsync}
                    saveButtonVisible={store.isDischargePatientScreen && !isReadonly && !store.canAcquireLock}
                    saveActionsDescriptor={store.actionForSaveButton}
                    onExecuteSaveAsync={store.onSaveAsync}
                />
            }
        >
            {store.careActivityDischargeDataStore &&
                <ValidationBoundary
                    validationResults={store.validationResults || []}
                    entityTypeName="CareActivityDischargeData"
                    validateOnMount
                    onValidateAsync={store.onValidateAsync}
                >
                    <ReadOnlyContext.Provider value={store.vIsReadOnly}>
                        {!store.vIsReadOnly && <Ui.InfoBox iconName="info_with_circle">
                            {StaticCareResources.CareRegister.CareActivityDischargeDataPanel.InfoBox.ShowDischargeInfo}
                        </Ui.InfoBox>}
                        <Ui.Flex>
                            <Ui.Flex.Item xs={4}>
                                <Ui.DateTimePicker
                                    label={StaticCareResources.CareRegister.CareActivityDischargeDataPanel.Label.DischargedAt}
                                    value={store.careActivityDischargeDataStore.dischargedAt}
                                    onChange={store.careActivityDischargeDataStore.setDischargedAt}
                                    propertyIdentifier="DischargedAt"
                                    automationId="DischargedAt"
                                    tooltipContent={!store.hasPermissionForDischargePatient && StaticWebAppResources.Common.ToastMessage.UnauthorizedFillField}
                                    disabled={!store.hasPermissionForDischargePatient}
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <DischargeReasonSelectBox
                                    label={StaticCareResources.CareRegister.CareActivityDischargeDataPanel.Label.DischargeReason}
                                    value={store.careActivityDischargeDataStore.dischargeReasonId}
                                    onChange={store.careActivityDischargeDataStore.setDischargeReasonId}
                                    propertyIdentifier="DischargeReasonId"
                                    automationId="DischargeReasonId"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <ExternalLocationCodeSelector
                                    label={StaticCareResources.CareRegister.CareActivityDischargeDataPanel.Label.ReceivingInstitute}
                                    value={store.careActivityDischargeDataStore?.receivingInstitute && store.careActivityDischargeDataStore.receivingInstitute?.id}
                                    validOn={LocalDate.createFromMoment(store.careActivityDischargeDataStore.dischargedAt)}
                                    onChange={store.careActivityDischargeDataStore.setReceivingInstitute}
                                    disabled={!store.careActivityDischargeDataStore.isForwardingValueSelected}
                                    propertyIdentifier="ReceivingInstituteId"
                                    automationId="receivingInstituteId"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>

                        <HisPlatformExtensionPoint extensionProps={store.extensionPointProps} type="careActivityDischargeData" >
                            <></>
                        </HisPlatformExtensionPoint>
                        <NavigateAwayHook onNavigateAwayAsync={store.navigateAwayAsync} />
                    </ReadOnlyContext.Provider>
                </ValidationBoundary>
            }
            <BusinessErrorHandler.Register businessErrorName="CantChangeCareActivityStateError" handler={store.handleCantChangeCareActivityStateError} />
        </Screen >
    );
}

export default connect<IDischargePatientScreenProps>(
    provideDischargePatientScreenStore(DischargePatientScreen),
    new ScreenStateContextAdapter(),
    new CareActivityContextAdapter<IDischargePatientScreenProps>(c => ({
        _pointOfCareId: c.careActivity?.pointOfCareId,
    })),
    new PatientContextAdapter<IDischargePatientScreenProps>(c => ({
        _patientId: c.patientId
    }))
);

