import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { IdentifierSystemSelectBoxView } from "@HisPlatform/BoundedContexts/CommonReferenceData/Components/Controls/IdentifierSystemSelectBox/IdentifierSystemSelectBoxView";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import IdentifierTypeId from "@Primitives/IdentifierTypeId.g";
import {wrappedValuesAreEquals} from "@HisPlatform/Common/ValueWrapperHelpers";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";


interface IIdentifierSystemSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IIdentifierSystemSelectBoxDependencies;
    identifierTypeIds?: IdentifierTypeId[];
}

interface IIdentifierSystemSelectBoxDependencies {
    referenceDataStore: CommonReferenceDataDataStore;
}

@State.observer
class IdentifierSystemSelectBox extends React.Component<IIdentifierSystemSelectBoxProps> {

    private get referenceDataStore() { return this.props._dependencies.referenceDataStore; }
    
    @State.observable public items: IObservableArray<ISelectBoxItem<IdentifierSystemId>> = State.observable([]);

    constructor(props: IIdentifierSystemSelectBoxProps) {
        super(props);
    }

    public render() {
        return (
            <IdentifierSystemSelectBoxView {...this.props} items={this.items} />
        );
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.fetchItemsAsync(), this);
    }

    @State.bound
    public async fetchItemsAsync() {
        await this.referenceDataStore.identifierSystemMap.ensureAllLoadedAsync();

        let identifierSystems = this.referenceDataStore.identifierSystemMap.items;

        if (this.props.identifierTypeIds) {
           identifierSystems = identifierSystems.filter(item => this.props.identifierTypeIds.some(id => wrappedValuesAreEquals(id, item.Entity.identifierTypeId)));
        }

        this.setItems(identifierSystems.map(s => {
            return {
                value: s.Entity.id,
                text: s.Localization.Name
            } as ISelectBoxItem<IdentifierSystemId>;
        }));
    }

    @State.action.bound
    private setItems(items: Array<ISelectBoxItem<IdentifierSystemId>>) {
        this.items.replace(items);
    }
}

export default connect(
    IdentifierSystemSelectBox,
    new DependencyAdapter<IIdentifierSystemSelectBoxProps, IIdentifierSystemSelectBoxDependencies>((container) => {
        return {
            referenceDataStore: container.resolve("CommonReferenceDataDataStore")
        };
    })
);
