import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import InsurerOrganizationsApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/InsurerOrganizations/InsurerOrganizationsApiAdapter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import InsurerOrganization from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/InsurerOrganizations/InsurerOrganization";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { IInsurerOrganizationDetailPanelModalParams, IInsurerOrganizationDetailPanelModalResult } from "./InsurerOrganizationDetailPanelModalParams";
import InsurerOrganizationDetailPanelModalView from "./InsurerOrganizationDetailPanelModalView";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";

interface IInsurerOrganizationDetailPanelModalDependencies {
    insurerOrganizationsApiAdapter: InsurerOrganizationsApiAdapter;
    financeReferenceDataStore: FinanceReferenceDataStore;
    notificationService: INotificationService;
}
interface IInsurerOrganizationDetailPanelModalProps extends IModalComponentParams<IInsurerOrganizationDetailPanelModalResult>, IInsurerOrganizationDetailPanelModalParams {
    _dependencies: IInsurerOrganizationDetailPanelModalDependencies;
}

/** @screen */
@State.observer
class InsurerOrganizationDetailPanelModal extends React.Component<IInsurerOrganizationDetailPanelModalProps> {
    private get notificationService() { return this.props._dependencies.notificationService; }

    @State.observable.ref private insurerOrganization: InsurerOrganization = null;
    @State.observable.ref private validationResults: IClientValidationResult[] = [];

    private get apiAdapter() {
        return this.props._dependencies.insurerOrganizationsApiAdapter;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadOrInitializeNewAsync(), this);
    }

    @State.bound
    private async loadOrInitializeNewAsync() {
        if (this.props.insurerOrganizationId) {
            const insurerOrganization = await this.apiAdapter.getInsurerOrganizationByIdAsync(this.props.insurerOrganizationId);
            if (insurerOrganization.operationWasSuccessful) {
                this.setInsurerOrganization(insurerOrganization.value);
            }
        } else {
            this.setInsurerOrganization(new InsurerOrganization(true));
        }
    }

    @State.action.bound
    private setInsurerOrganization(newValue: InsurerOrganization) {
        this.insurerOrganization = newValue;
    }

    @State.bound
    private async saveAsync() {
        let result: SimpleStore<InsurerOrganization>;

        if (this.props.insurerOrganizationId) {
            result = await this.apiAdapter.updateInsurerOrganizationAsync(this.insurerOrganization);
        } else {
            result = await this.apiAdapter.createInsurerOrganizationAsync(this.insurerOrganization);
        }

        this.setValidationResults(result.value.validationResults);

        this.notificationService.showSaveResult(result.isPersistedByOperationInfo);
        await this.props._dependencies.financeReferenceDataStore.insurerOrganizationMap.reloadAsync();

        if (result.isPersistedByOperationInfo) {
            this.props.onClose({ operationWasSuccesful: result.isPersistedByOperationInfo, insurerOrganizationId: result.value.id });
        }
    }

    @State.action.bound
    public setValidationResults(newValue: IClientValidationResult[]) {
        this.validationResults = newValue;
    }

    @State.bound
    private onClose() {
        this.props.onClose(null);
    }

    @State.bound
    private async validateAsync() {
        const result = await this.apiAdapter.validateAsync(this.insurerOrganization);
        return result.value;
    }

    public render() {
        return (
            <InsurerOrganizationDetailPanelModalView
                insurerOrganization={this.insurerOrganization}
                onSaveAsync={this.saveAsync}
                onClose={this.onClose}
                onValidateAsync={this.validateAsync}
                validationResults={this.validationResults}
            />
        );
    }
}

export default connect(
    InsurerOrganizationDetailPanelModal,
    new DependencyAdapter<IInsurerOrganizationDetailPanelModalProps, IInsurerOrganizationDetailPanelModalDependencies>((container) => {
        return {
            insurerOrganizationsApiAdapter: container.resolve("InsurerOrganizationsApiAdapter"),
            financeReferenceDataStore: container.resolve("FinanceReferenceDataStore"),
            notificationService: container.resolve("INotificationService"),
        };
    })
);
