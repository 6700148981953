import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import ErrorEntryRecordType from "@Primitives/ErrorEntryRecordType";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import ErrorEntryRecordTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/ErrorEntryRecordTypeSelectBox/ErrorEntryRecordTypeSelectBox";

interface IErrorEntryRecordTypeColumnDependencies {
    localizationService: ILocalizationService;
}

interface IErrorEntryRecordTypeColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IErrorEntryRecordTypeColumnDependencies;
}


const ErrorEntryRecordTypeColumn: React.FC<IErrorEntryRecordTypeColumnProps> = (props: IErrorEntryRecordTypeColumnProps) => {

    const valueRenderer = useCallback((value: ErrorEntryRecordType) => {
        return isNullOrUndefined(value) ? null :
            props._dependencies.localizationService.localizeEnum(ErrorEntryRecordType[value], "ErrorEntryRecordType").Name;
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<ErrorEntryRecordType | ErrorEntryRecordType[]>) => {
        return (
            <ErrorEntryRecordTypeSelectBox
                {...filterProps}
                multiSelect
                maxMenuWidth={commonMenuMaxWidth}
                hoverOnlyIndicatorIcons
            />
        );
    }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            showHint={false}
        />
    );
};

export default connect(
    ErrorEntryRecordTypeColumn,
    new DependencyAdapter<IErrorEntryRecordTypeColumnProps, IErrorEntryRecordTypeColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);