import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientId from "@Primitives/PatientId.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { parseUseCaseStateFromUrlParam, getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import ITherapyRecommendationRouteParams from "./ITherapyRecommendationRouteParams";
import TherapyRecommendationId from "@Primitives/TherapyRecommendationId.g";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import TherapyRecommendationListPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/MedicalCondition/TherapyRecommendation/TherapyRecommendationListPanel";
import PatientRoutes from "@HisPlatform/Components/Pages/Patient/PatientRoutes";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import HunPatientRoutes from "@HunEHealthInfrastructurePlugin/UseCases/PatientMainPageUseCase/HunPatientRoutes";

interface ITherapyRecommendationPageProps extends IRoutingFrameContentProps {
}

@State.observer
class TherapyRecommendationPage extends React.Component<ITherapyRecommendationPageProps> {

    @State.computed private get routeParams(): ITherapyRecommendationRouteParams {
        return this.props.routingController.currentRoute.parameters as ITherapyRecommendationRouteParams;
    }

    @State.computed private get selectedTherapyRecommendationId(): TherapyRecommendationId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments[0].value;
        }

        if (this.useCaseState?.useCase.value === HunEHealthInfrastructureUseCases.therapyRecommendationDetail) {
            return new TherapyRecommendationId("new");
        }

        return null;
    }

    @State.computed
    private get patientId() {
        return new PatientId(this.routeParams?.patientId);
    }

    @State.computed private get useCaseState() {
        const useCaseState = this.routeParams?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.bound
    private setUseCaseState(selectedTherapyRecommendationId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.replace(
            HunPatientRoutes.therapyRecommendation.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            })
        );
    }

    @State.bound
    private navigateToTherapyRecommendation(therapyRecommendationId: TherapyRecommendationId) {
        this.props.routingController.replace(
            HunPatientRoutes.therapyRecommendation.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(HunEHealthInfrastructureUseCases.therapyRecommendationDetail),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(therapyRecommendationId, "therapyRecommendationId")])
            })
        );
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(
            HunPatientRoutes.therapyRecommendation.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    null,
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument(new TherapyRecommendationId("null"), "therapyRecommendationId")])
            })
        );
    }

    public render() {
        return (
            <TherapyRecommendationListPanel
                _patientId={this.patientId}
                onChange={this.setUseCaseState}
                useCaseState={this.useCaseState}
                selectedRowId={this.selectedTherapyRecommendationId?.value}
                onSelectedTherapyRecommendationIdChange={this.navigateToTherapyRecommendation}
                onBack={this.goBack}
                onCloseDetail={this.setClosedState}
            />
        );
    }
}

export default connect(
    TherapyRecommendationPage
);
