import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import ContagiousPatientReportState from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReportState";
import ContagiousPatientReportStateSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/ContagiousPatientReportStateSelectBox/ContagiousPatientReportStateSelectBox";

interface IContagiousPatientReportStateColumnDependencies {
    localizationService: ILocalizationService;
}

interface IContagiousPatientReportStateColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IContagiousPatientReportStateColumnDependencies;
    displayMode?: "name";
}


const ContagiousPatientReportStateColumn: React.FC<IContagiousPatientReportStateColumnProps> = (props: IContagiousPatientReportStateColumnProps) => {

    const valueRenderer = useCallback((value: ContagiousPatientReportState) => {

        switch (props.displayMode) {
            case "name":
                const localized = isNullOrUndefined(value) ? null : props._dependencies.localizationService.localizeEnum(ContagiousPatientReportState[value], "ContagiousPatientReportState");
                return isNullOrUndefined(localized) ? null : localized.Name;
        }
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<ContagiousPatientReportState | ContagiousPatientReportState[]>) => {
        return <ContagiousPatientReportStateSelectBox {...filterProps} multiSelect />;
    }, []);

    return (
        <DataGridColumn
            width={undefined}
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            showHint={false}
        />
    );
};

export default connect(
    ContagiousPatientReportStateColumn,
    new DependencyAdapter<IContagiousPatientReportStateColumnProps, IContagiousPatientReportStateColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
