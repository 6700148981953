enum LoginResult {
    Success,
    InvalidUserNameOrPassword,
    InvalidScope,
    InvalidClient,
    UnknownError,
    IsNotEnabled,
    PasswordHasBeenExpired
}

export default LoginResult;
