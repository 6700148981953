import React from "react";
import Styles from "./PageBoxContainer.less";

interface IPageBoxContainerProps {
    children?: React.ReactNode;
}


const PageBoxContainer: React.FC<IPageBoxContainerProps> = props => (
    <div className={Styles.singleLayout}>
        <div className={Styles.pageBoxContainer}>
            {props.children}
        </div>
    </div>
);

export default PageBoxContainer;