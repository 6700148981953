import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import IMedicationSubsidyClaimType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IMedicationSubsidyClaimType";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import SubsidyClaimTypeSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/SubsidyClaimTypeSelector/SubsidyClaimTypeSelector";

interface IMedicationSubsidyClaimTypeColumnDependencies {
    hunReferenceDataStore: MedicationRequestReferenceDataStore;
}

interface IMedicationSubsidyClaimTypeColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IMedicationSubsidyClaimTypeColumnDependencies;
    displayMode?: "name" | "code";
}


const MedicationSubsidyClaimTypeColumn: React.FC<IMedicationSubsidyClaimTypeColumnProps> = props => {
    const valueRenderer = useCallback((value: MedicationSubsidyClaimTypeId | MedicationSubsidyClaimTypeId[]) => {
        if (Array.isArray(value)) {
            const medicationSubsidyClaimTypes = value.map(i => props._dependencies.hunReferenceDataStore.medicationSubsidyClaimTypes.get(i));
            return medicationSubsidyClaimTypes.map(i => getDisplayValueByMode(props.displayMode, i)).join(", ");
        }
        const localized = isNullOrUndefined(value) ? null : props._dependencies.hunReferenceDataStore.medicationSubsidyClaimTypes.get(value);
        return isNullOrUndefined(localized) ? null : getDisplayValueByMode(props.displayMode, localized);
    }, []);

    const getDisplayValueByMode = (displayMode: string, value: IMedicationSubsidyClaimType) => {
        return displayMode === "code" ? value.code : value.name;
    };

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<MedicationSubsidyClaimTypeId | MedicationSubsidyClaimTypeId[]>) => {
        return <SubsidyClaimTypeSelector {...filterProps} hoverOnlyIndicatorIcons />;
    }, []);

    return (
        <DataGridColumn
            {...props as IDataGridColumnBaseProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
        />
    );
};

export default connect(
    MedicationSubsidyClaimTypeColumn,
    new DependencyAdapter<IMedicationSubsidyClaimTypeColumnProps, IMedicationSubsidyClaimTypeColumnDependencies>(c => ({
        hunReferenceDataStore: c.resolve("MedicationRequestReferenceDataStore"),
    }))
);