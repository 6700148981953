import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IPerformedServiceExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPerformedServiceExtensionPointProps";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import StaticHunSocialSecurityCareResources from "@HunSocialSecurityPlugin/BoundedContexts/Care/StaticResources/StaticHunSocialSecurityCareResources";
import PerformedServiceClassificationColumnCell from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/PerformedService/PerformedServiceClassificationColumnCell";

@State.observer
export default class PerformedServiceClassificationColumn extends React.Component<IPerformedServiceExtensionPointProps> {
    public render() {
        return (
            <DataGridColumn
                width="180px"
                displayOrder={401}
                
                header={StaticHunSocialSecurityCareResources.PerformedServices.PerformedServiceClassification.ColumnHeader}>
                <PerformedServiceClassificationColumnCell
                    {...this.props}
                />
            </DataGridColumn>
        );
    }
}