// #region imports
import CareActivityId from "@Primitives/CareActivityId.g";
import CareActivityDischargeDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityDischargeData/CareActivityDischargeDataStore";
import CareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CareActivityStore";
import React, { useContext } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientId from "@Primitives/PatientId.g";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import { VoidSyncEvent } from "ts-events";
import UnauthorizedOperationBusinessError from "@Toolkit/CommonWeb/Model/UnauthorizedOperationBusinessError";
import ContextHeaderApiAdapter from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ContextHeaderApiAdapter";
import CareActivityBaseData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareActivityBaseData";
// #endregion

export default class CareActivityContext implements IDisposable {

    @State.observable.ref public isLoading = false;
    @State.observable.ref public careActivityId: CareActivityId = null;
    @State.observable.ref public patientId: PatientId = null;
    @State.observable.ref public isAuthorizedForPatient: boolean = true;
    @State.observable.ref public careActivity: CareActivityStore = null;
    @State.observable.ref public isAuthorizedForCareActivity: boolean = true;
    @State.observable.ref public baseData: CareActivityBaseData = null;
    @State.observable.ref public isAuthorizedForCareActivityBaseData: boolean = true;
    @State.observable.ref public dischargeData: CareActivityDischargeDataStore = null;
    @State.observable.ref public isAuthorizedForDischargeData: boolean = true;

    public readonly diagnosisListChangedEvent: VoidSyncEvent = new VoidSyncEvent();

    public readonly careActivityBaseDataSavedEvent = new TypedEvent<CareActivityBaseData>();

    constructor(
        private readonly contextHeaderApiAdapter: ContextHeaderApiAdapter
    ) {
        this.careActivityBaseDataSavedEvent.on(this.setCareActivityBaseDataFromEvent);
    }

    public dispose(): void {
        this.careActivityBaseDataSavedEvent.dispose();
    }

    @State.boundLoadingState()
    public async loadAsync(careActivityId: CareActivityId) {
        this.setCareActivityId(careActivityId);
        const contextData = await this.contextHeaderApiAdapter.getCareAndPatientContextAsync(careActivityId);

        State.runInAction(() => {
            this.setCareActivity(contextData.result.careActivity);
            this.setPatientId(contextData.result.patient.id);
            this.setCareActivityBaseData(contextData.result.baseData);
            this.setCareActivityDischargeData(contextData.result.dischargeData);
        });
        
    }

    @State.action
    private setUnauthorizedDischargeState(reason: any) {
        if (reason.error instanceof UnauthorizedOperationBusinessError) {
            this.isAuthorizedForDischargeData = false;
        }
    }

    @State.action
    private setUnAuthorizedForCareActivity(reason: any) {
        if (reason.error instanceof UnauthorizedOperationBusinessError) {
            this.isAuthorizedForCareActivity = false;
        }
    }

    @State.action
    private setUnAuthorizedForCareActivityBaseData(reason: any) {
        if (reason.error instanceof UnauthorizedOperationBusinessError) {
            this.isAuthorizedForCareActivityBaseData = false;
        }
    }

    @State.action
    private setUnAuthorizedForPatient(reason: any) {
        if (reason.error instanceof UnauthorizedOperationBusinessError) {
            this.isAuthorizedForPatient = false;
        }
    }

    @State.action.bound
    private setCareActivity(careActivity: CareActivityStore) {
        this.careActivity = careActivity;
    }

    @State.action.bound
    private setCareActivityId(careActivityId: CareActivityId) {
        this.careActivityId = careActivityId;
    }

    @State.action.bound
    private setPatientId(patientId: PatientId) {
        this.patientId = patientId;
    }

    @State.action.bound
    private setCareActivityBaseData(careActivityBaseData: CareActivityBaseData) {
        this.baseData = careActivityBaseData;
    }

    @State.action.bound
    private setCareActivityDischargeData(careActivityDischargeData: CareActivityDischargeDataStore) {
        this.dischargeData = careActivityDischargeData;
    }

    @State.action.bound
    private setCareActivityBaseDataFromEvent(newValue: CareActivityBaseData) {
        this.baseData = newValue;
    }
}


const CareActivityReactContext = React.createContext<CareActivityContext>(null);

function useCareActivityContext(): CareActivityContext | null {
    return useContext(CareActivityReactContext);
}

export {
    CareActivityReactContext,
    useCareActivityContext
};