import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import HunCareReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/HunCareReferenceDataStore";

interface IInpatientAdmissionTypeSelectBoxDependencies {
    careReferenceDataStore: HunCareReferenceDataStore;
}

interface IInpatientAdmissionTypeSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IInpatientAdmissionTypeSelectBoxDependencies;
}

@State.observer
class InpatientAdmissionTypeSelectBox extends React.Component<IInpatientAdmissionTypeSelectBoxProps> {

    private get dependencies() {
        return this.props._dependencies;
    }

    private get extensibleEnumStore() {
        return this.dependencies.careReferenceDataStore.inpatientAdmissionTypeMap;
    }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
                clearable={false}
            />
        );
    }
}

export default connect(
    InpatientAdmissionTypeSelectBox,
    new DependencyAdapter<IInpatientAdmissionTypeSelectBoxProps, IInpatientAdmissionTypeSelectBoxDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("HunCareReferenceDataStore")
        };
    })
);
