import DependencyContainer from "@DiContainer";
import * as FinanceProxy from "@HisPlatform/BoundedContexts/Finance/Api/Proxy.g";
import InsurerOrganizationStoreMapper from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/InsurerOrganizations/InsurerOrganizationStoreMapper";

DependencyContainer
    .bind("IFinanceClient")
    .to(FinanceProxy.FinanceClient);

DependencyContainer
    .bind("IInvoicingClient")
    .to(FinanceProxy.InvoicingClient)
    .inSingletonScope();

DependencyContainer
    .bind("InsurerOrganizationStoreMapper")
    .to(InsurerOrganizationStoreMapper)
    .inSingletonScope();