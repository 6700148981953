import React from "react";
import HisUseCaseHost from "@HisPlatform/Components/HisUseCaseHost/HisUseCaseHost";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";

interface INDataPanelDetailViewProps extends IHasMasterDetailState {
    useCaseIdentifier: UseCaseIdentifier;
    useCaseArguments: UseCaseArgument[];
    onGetPanelProps?: (useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) => object;

    state?: string;
    onStateChange?: (newState: string) => void;
}

export default class NDataPanelDetailView extends React.PureComponent<INDataPanelDetailViewProps> {
    public render() {
        return (
            <HisUseCaseHost
                frameType="MasterDetailDetail"
                useCaseIdentifier={this.props.useCaseIdentifier}
                useCaseArguments={this.props.useCaseArguments}
                onGetPanelProps={this.props.onGetPanelProps}
                state={this.props.state}
                onStateChange={this.props.onStateChange}
            />
        );
    }
}
