import IUseCaseDescriptor2 from "@PluginInterface/UseCases/IUseCaseDescriptor2";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import FamilyDoctorDocumentId from "@Primitives/FamilyDoctorDocumentId.g";
import FamilyDoctorDocumentDetailPanel, { IFamilyDoctorDocumentDetailPanelProps } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/FamilyDoctorDocumentListPanel/FamilyDoctorDocumentDetailPanel";

export default function configureFamilyDoctorDocumentUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2<IFamilyDoctorDocumentDetailPanelProps>(
        {
            identifiers: [HunEHealthInfrastructureUseCases.createFamilyDoctorDocument],
            component: FamilyDoctorDocumentDetailPanel,
            mapUseCaseArgumentsToProps: args => {
                return {
                    familyDoctorDocumentId: new FamilyDoctorDocumentId("new"),
                };
            },
        } as IUseCaseDescriptor2<IFamilyDoctorDocumentDetailPanelProps>
    );
    useCaseRegistry.add2<IFamilyDoctorDocumentDetailPanelProps>(
        {
            identifiers: [HunEHealthInfrastructureUseCases.modifyFamilyDoctorDocument],
            component: FamilyDoctorDocumentDetailPanel,
            mapUseCaseArgumentsToProps: args => {
                const familyDoctorDocumentId = args.find(a => a.value instanceof FamilyDoctorDocumentId).value;
                return {
                    familyDoctorDocumentId: familyDoctorDocumentId
                };
            }
        } as IUseCaseDescriptor2<IFamilyDoctorDocumentDetailPanelProps>
    );
}