import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import IPatientsCareActivityListInfoExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientsCareActivityListInfoExtensionPointProps";

@Di.injectable()
export default class PatientsCareActivityListInfoExtensionComponentService implements IExtensionComponentService<IPatientsCareActivityListInfoExtensionPointProps> {

    public isVisible(props: IPatientsCareActivityListInfoExtensionPointProps) {
        return !!(props.extensionData && props.extensionData.EhiErrors);
    }

    public shouldRerunIsVisible(props: IPatientsCareActivityListInfoExtensionPointProps, prevProps: IPatientsCareActivityListInfoExtensionPointProps) {
        return props.extensionData !== prevProps?.extensionData;
    }
}