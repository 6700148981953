import { ScreenDisplayMode } from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import ShowPatientInvoiceListScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientInvoiceListScreenAction.g";
import IPatientInvoicesSideMenuItemExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientInvoicesSideMenuItemExtensionPointProps";
import ActionBoundSideMenuItem from "@Toolkit/ReactClient/ActionProcessing/ActionBoundSideMenuItem";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import React, { useMemo } from "react";

export default function PatientInvoicesPatientMainMenuItemExtension(props: IPatientInvoicesSideMenuItemExtensionPointProps) {
    const showPatientInvoiceListScreenAction = useMemo(() => {
        return new ActionDescriptor(
            new ShowPatientInvoiceListScreenAction(
                ScreenDisplayMode.Full,
                props.patientId
            ),
            null,
            []
        );
    }, [props.isNew, props.patientId]);

    return (
        <ActionBoundSideMenuItem
            automationId="__patientInvoices"
            actionDescriptor={showPatientInvoiceListScreenAction}
            disabled={props.isNew}
        />
    );
}