import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import PatientName from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/PatientName";
import GenderId from "@Primitives/GenderId.g";
import { Icon } from "@CommonControls";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IPatientNameColumnDependencies {
    localizationService: ILocalizationService;
}

interface IPatientNameColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IPatientNameColumnDependencies;
}

const getGenderIcon = (value: GenderId) => {
    if (wrappedValuesAreEquals(value, GenderId.Male)) {
        return "patient_man";
    } else if (wrappedValuesAreEquals(value, GenderId.Female)) {
        return "patient_woman";
    } else {
        return "patient_unknown";
    }
};


const PatientNameColumn: React.FC<IPatientNameColumnProps> = (props: IPatientNameColumnProps) => {

    const valueRenderer = useCallback((value: PatientName) => {
        if (isNullOrUndefined(value)) {
            return null;
        }

        return (
            <>
                <Icon iconName={getGenderIcon(value.genderId)} style={{ marginRight: "10px" }} />
                {renderName(value)}
                {value.isDead && <Icon iconName="ribbon" style={{ marginLeft: "10px" }} />}
            </>
        );
    }, []);

    const renderName = useCallback((value: PatientName) => {
        return (value.isUnregistered || value.isDataCleansingNeeded)
            ? <i>{props._dependencies.localizationService.localizePersonName(value.name)}</i>
            : <>{props._dependencies.localizationService.localizePersonName(value.name)}</>;
    }, []);

    const stringValueRenderer = useCallback((value: PatientName) => {
        return props._dependencies.localizationService.localizePersonName(value?.name);
    }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderHintValue={stringValueRenderer}
        />
    );
};

export default connect(
    PatientNameColumn,
    new DependencyAdapter<IPatientNameColumnProps, IPatientNameColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
