import PanelStoreBase from "./PanelStoreBase";
import SimpleDirtyChecker from "@Toolkit/CommonWeb/Model/SimpleDirtyChecker";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import { isNullOrUndefined, arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import { LoadedSignalContextStore } from "./LoadedSignal";
import ILoadablePanelStore from "./ILoadablePanelStore";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import IOperationResult from "@Toolkit/CommonWeb/ApiAdapter/IOperationResult";

export default abstract class EditorScreenPanelStoreBase<TProps extends IScreenPropsBase> extends PanelStoreBase<TProps> implements ILoadablePanelStore<TProps> {
    @State.observable.ref public validationResults: IClientValidationResult[] = [];
    @State.observable.ref public lockInfo?: LockInfo = null;

    protected readonly dirtyChecker = new SimpleDirtyChecker();

    // use a computed field for this
    protected abstract contentToDirtyCheck: any[];
    protected abstract saveFunction: () => Promise<boolean>;
    protected abstract get showScreenAction(): ShowScreenFrontendActionBase | null;

    protected onNavigatingAwayCallback: (isSaved: boolean) => Promise<void> = () => Promise.resolve();

    public readonly loadedSignalStore = new LoadedSignalContextStore();

    @State.computed
    public get canAcquireLock() {
        return this.lockInfo && this.lockInfo.lockState === EntityLockState.LockingRequiredAndLockNotHeld && this.lockInfo.wasLockRequested;
    }

    constructor(
        protected dialogService: IDialogService,
        protected notificationService: INotificationService,
        public localizationService: IToolkitLocalizationService,
        public lockingApiAdapter: LockingApiAdapter) {
        super();
    }

    @State.action
    protected releaseLock() {
        if (this.lockInfo.lockState === EntityLockState.LockingRequiredAndLockHeld) {
            this.lockInfo = new LockInfo(EntityLockState.LockingRequiredAndLockNotHeld, null, false, false, null, null, null);
        }
    }

    protected vGetReloadTriggerProps(props: TProps): any[] {
        return [];
    }

    public getReloadTriggerProps(props: TProps) {
        return [props.action, ...this.vGetReloadTriggerProps(props)];
    }

    @State.bound
    public async navigateAwayAsync(): Promise<boolean> {
        if (this.dirtyChecker.isDirty(this.contentToDirtyCheck)) {
            const dialogResult = await this.dialogService.confirmIfNotSaved(this.localizationService.staticResources.common.navigateAwayDialogTitle, this.localizationService.staticResources.common.navigateAwayDialogQuestion);
            if (dialogResult.resultCode === DialogResultCode.Yes) {
                const persisted = await this.saveFunction();
                await this.onNavigatingAwayCallback(persisted);
                return persisted;
            } else if (dialogResult.resultCode === DialogResultCode.No) {
                await this.forceReleaseLockIfLockHeldAsync();
                await this.onNavigatingAwayCallback(false);
                return true;
            } else {
                return false;
            }
        } else {
            await this.forceReleaseLockIfLockHeldAsync();
            await this.onNavigatingAwayCallback(true);
            return true;
        }
    }

    @State.computed
    public get hasValidationWarning() {
        return !arrayIsNullOrEmpty(this.validationResults) && this.validationResults.some(
            r => r.problems && r.problems.some(p => p.severity === "warning"));
    }

    @State.action.bound
    protected vSetValidationResults(newValue: IClientValidationResult[]) {
        this.validationResults = newValue;
    }

    public get vIsReadOnly(): boolean {
        return false;
    }

    public abstract loadCoreAsync(): Promise<void | { loadedSignals?: string[] }>;

    public readonly loadAsync = this.async(async () => {

        this.loadedSignalStore.clearAllSignals();

        const loadResult = await this.loadCoreAsync();

        if (loadResult && loadResult.loadedSignals) {
            await this.loadedSignalStore.waitForAllSignalsAsync(loadResult.loadedSignals);
        }

        if (!this.vIsReadOnly) {
            this.dirtyChecker.takeSnapshot(this.contentToDirtyCheck);
        }
    });

    @State.bound
    protected async handleSaveResultAsync<TResult>(saveFunc: () => Promise<IOperationResult<TResult>>): Promise<{ result: TResult, isPersisted: boolean }> {
        const response = await saveFunc();

        const validationResult = this.hasValidationResults(response.result);
        if (validationResult.hasValidationResults) {
            this.vSetValidationResults(validationResult.validationResults);
        }
        this.notificationService.showSaveResult(response.operationInfo.isPersisted, this.hasValidationWarning);

        if (!response.operationInfo.isPersisted) {
            const validationResult = this.hasValidationResults(response.result);
            if (validationResult.hasValidationResults) {
                this.vSetValidationResults(validationResult.validationResults);
            }

            return { result: response.result, isPersisted: false };
        }

        this.dirtyChecker.setPersisted();
        return { result: response.result, isPersisted: true };
    }

    private hasValidationResults(data: any): { hasValidationResults: boolean, validationResults: IClientValidationResult[] } {
        if (!data) {
            return { hasValidationResults: false, validationResults: null };
        }

        const validationResults = data.validationResults;

        return { hasValidationResults: validationResults !== undefined, validationResults };
    }

    @State.bound
    private async forceReleaseLockIfLockHeldAsync() {
        if (!isNullOrUndefined(this.lockInfo)) {
            if (!!this.lockInfo.lockId) {
                await this.lockingApiAdapter.forceReleaseLockAsync(this.lockInfo.lockId);
            }
        }
    }
}