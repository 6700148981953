import MedicationSubsidyOptionRestrictionId from "@Primitives/MedicationSubsidyOptionRestrictionId.g";

export default class SubsidyOptionRestrictionCheckResult {
    constructor(
        public subsidyOptionRestrictionId: MedicationSubsidyOptionRestrictionId,
        public organizationTypeMatches: boolean,
        public professionRestrictionMatches: boolean,
        public restrictionTypeMatches: boolean,
        public restrictionIsDesignated: boolean
    ) {}

    public get successful() {
        return this.organizationTypeMatches && this.professionRestrictionMatches && this.restrictionTypeMatches;
    }
}