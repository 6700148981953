import React from "react";
import TableFrame, { IRenderedTableFrameColumn } from "@CommonControls/TreeGrid/TableFrame";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined, isNullOrWhiteSpace } from "@Toolkit/CommonWeb/NullCheckHelpers";
import * as Ui from "@CommonControls";
import ITreeGridProps from "./ITreeGridProps";
import TreeGridView from "./TreeGridView";
import ITreeNodeStore from "./ITreeNodeStore";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import _ from "@HisPlatform/Common/Lodash";
import { filterTree, checkMatchForNode } from "./TreeGridFilter";

@State.observer
export default class TreeGrid extends React.Component<ITreeGridProps> {

    @State.observable.ref private filterValue: string = "";
    private debouncedFilterValueBox = State.observable.box("");
    @State.observable.ref private columns: IRenderedTableFrameColumn[] = null;
    @State.observable.ref private editedNode: ITreeNodeStore = null;

    @State.observable.ref private _internalSelectedNode: ITreeNodeStore = null;
    @State.computed private get selectedNode() { return this.props.isSelectionControlled ? this.props.selectedNode : this._internalSelectedNode; }
    
    @State.computed public get visibleData() {
        if (!this.props.filterable) {
            return this.props.data;
        }
        return filterTree(this.debouncedFilterValueBox.get(), this.props.data);
    }

    @State.action.bound
    private setColumns(columns: IRenderedTableFrameColumn[]) {
        this.columns = columns;
    }

    @State.action.bound
    private onToggle(node: ITreeNodeStore, isToggled: boolean) {
        this._internalSelectedNode = node;

        if (node.isOpen !== isToggled) {
            this.onNodeOpenStateChange(node, isToggled);
        }
    }

    @State.action.bound
    private onNodeOpenStateChange(node: ITreeNodeStore, isOpen: boolean) {
        node.isOpen = isOpen;
    }

    @State.action.bound
    private onChecked(node: ITreeNodeStore, isChecked: boolean) {
        if (isChecked === null) {
            node.isChecked = false;
        } else {
            node.isChecked = isChecked;
        } 

        if (this.props.onCheckNode) {
            this.props.onCheckNode(node);
        }

        if (!!node.children && node.children.length > 0) {
            for (const child of node.children) {
                this.onCheckChild(child, node.isChecked);
            }
        }

        if (!!this.props.data && this.props.data.length > 0) {
            for (const n of this.props.data) {
                this.recalculateCheckValues(n);
            }
        }
    }

    @State.action.bound
    private onCheckChild(child: ITreeNodeStore, isChecked: boolean) {
        child.isChecked = isChecked;

        if (this.props.onCheckNode) {
            this.props.onCheckNode(child);
        }

        if (!!child.children && child.children.length > 0) {
            for (const c of child.children) {
                this.onCheckChild(c, isChecked);
            }
        }
    }

    @State.bound
    private recalculateCheckValues(node: ITreeNodeStore): boolean {
        if (!!node.children && node.children.length > 0) {
            let falseValues = 0;
            let trueValues = 0;

            for (const child of node.children) {
                const checkValue = this.recalculateCheckValues(child);
                if (checkValue === true) {
                    trueValues++;
                } else if (checkValue === false) {
                    falseValues++;
                }
            }

            if (trueValues === node.children.length) {
                node.isChecked = true;
            } else if (falseValues === node.children.length) {
                node.isChecked = false;
            } else {
                node.isChecked = null;
            }

            if (this.props.onCheckNode) {
                this.props.onCheckNode(node);
            }
        }

        return node.isChecked;
    }

    @State.action.bound
    public setFilterValue(value: string) {
        this.filterValue = value;
        this.setDebouncedFilterValue();
    }

    public setDebouncedFilterValue = _.debounce(() => {
        State.runInAction(() => {
            this.debouncedFilterValueBox.set(this.filterValue);

            if (isNullOrUndefined(this.filterValue) || isNullOrWhiteSpace(this.filterValue)) {
                this.props.data.forEach(x => this.openAllChildrenNode(x));
            } else {
                this.visibleData.forEach(x => this.openFilteredTree(x));
            }
            
            if (this.props.onFilterValueChange) {
                this.props.onFilterValueChange(this.filterValue);
            }
        });
    }, 300);

    @State.action.bound
    private openFilteredTree(node: ITreeNodeStore): boolean {
        if (node) {
            let shouldBeVisible = false;
            if (!!node.children && node.children.length > 0) {
                for (const child of node.children) {
                    shouldBeVisible = this.openFilteredTree(child) || shouldBeVisible;
                }
            }
            node.isOpen = shouldBeVisible;
            return shouldBeVisible || checkMatchForNode(this.debouncedFilterValueBox.get(), node);
        }
        return false;
    }

    @State.bound
    private openAllChildrenNode(node: ITreeNodeStore) {
        if (node) {
            node.isOpen = true;
            if (!!node.children && node.children.length > 0) {
                node.children.forEach(x => this.openAllChildrenNode(x));
            }
        }
    }

    public render() {
        return (
            <Ui.Flex>
                {this.props.filterable &&
                    <Ui.Flex.Item xs={12}>
                        <Ui.TextBox
                            label={StaticWebAppResources.Common.Label.Filter}
                            onChange={this.setFilterValue}
                            value={this.filterValue}
                            valueOnClear={""}
                            automationId={this.props.automationId ? `${this.props.automationId}_Filter` : undefined}
                        />
                    </Ui.Flex.Item>
                }
                <Ui.Flex.Item xs={12}>
                    <TableFrame
                        columns={this.props.columns}
                        onColumnsChange={this.setColumns}
                        automationId={this.props.automationId ? `${this.props.automationId}_TableFrame` : undefined}
                        footer={this.props.footer}
                        hasBackButton={this.props.hasBackButton}
                        onBack={this.props.onBack}
                        backButtonText={this.props.backButtonText}>
                        {this.props.onRenderFirstRow && this.props.onRenderFirstRow(this.columns) || null}
                        <Ui.ScrollView height={this.props.fixedHeight}>
                            <TreeGridView
                                columns={this.columns}
                                data={this.visibleData}
                                onNodeRenderDisplayValue={this.renderDisplayValue}
                                onNodeSelect={this.setSelectedNode}
                                onNodeToggle={this.onToggle}
                                onNodeChecked={this.onChecked}
                                selectedNode={this.selectedNode}
                                multiSelect={this.props.multiSelect}
                                automationId={this.props.automationId}
                            />
                        </Ui.ScrollView>
                    </TableFrame>
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.bound
    private renderDisplayValue(node: ITreeNodeStore, column: IRenderedTableFrameColumn, columnIndex: number) {

        if (this.editedNode === node) {
            if (!isNullOrUndefined(column.editValueGetter)) {

                if (typeof column.editValueGetter === "function") {
                    return column.editValueGetter(node, () => {
                        this.setEditedNode(null);
                    });
                }
            }
            if (!isNullOrUndefined(node.editValue)) {
                return node.editValue[columnIndex];
            }
        }

        if (isNullOrUndefined(column.displayValueGetter)) {
            return node.displayValue[columnIndex];
        }

        if (typeof column.displayValueGetter === "function") {
            return column.displayValueGetter(node, () => {
                this.setEditedNode(node);
            });
        }

        return node.displayValue[column.displayValueGetter];
    }

    @State.action.bound
    private setEditedNode(node: ITreeNodeStore) {
        this.editedNode = node;
    }

    @State.action.bound
    private setSelectedNode(node: ITreeNodeStore) {
        this._internalSelectedNode = node;

        if (this.props.onSelectNode) {
            this.props.onSelectNode(node);
        }
    }
}
