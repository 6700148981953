import React from "react";
import Styles from "./RadioButton.less";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import { iconNameType } from "@CommonControls/Icon";

interface IRadioButtonProps {
    text: string;
    value: string;
    isChecked: boolean;
    onChanged?: (value: string) => void;
    style?: any;
    iconName?: iconNameType;
    displayType?: "buttons" | "groupedButtons";
    disabled?: boolean;
    validationResultSeverity?: "error" | "warning";
    automationId?: string;
    size?: "standard" | "compact";
}

@State.observer
export default class RadioButton extends React.Component<IRadioButtonProps> {

    public render() {
        const inputId = `${this.props.automationId}_${this.props.value}`;

        return (
            <>
                <label htmlFor={inputId} className={this.getClassName()}>
                    {this.props.displayType &&
                        this.props.iconName &&
                        <Ui.Icon style={this.props.style} iconName={this.props.iconName} automationId={`${this.props.automationId}_icon`}/>} {this.props.text}
                    <input
                        id={inputId}
                        value={this.props.value}
                        checked={this.props.isChecked}
                        onChange={this.valueChanged}
                        type="radio"
                        name={this.props.automationId}
                    />
                    <span className={this.getCheckmarkClass()}/>
                </label>
            </>
        );
    }

    private getClassName() {
        let className: CompositeClassName;

        const validationProblemSeverity = this.props.validationResultSeverity;

        if (this.props.displayType) {
            switch (this.props.displayType) {
                case "groupedButtons":
                    className = new CompositeClassName(Styles.containerButton);
                    className.addIf(this.props.isChecked, Styles.containerButtonChecked);
                    className.addIf(this.props.disabled, Styles.disabled);
                    className.addIf(this.props.isChecked && this.props.disabled, Styles.disabledChecked);
                    break;
                case "buttons":
                    className = new CompositeClassName(Styles.containerButtonUngrouped);
                    className.addIf(this.props.isChecked, Styles.containerButtonChecked);
                    break;
            }
            className.addIf(validationProblemSeverity && validationProblemSeverity === "warning", Styles.warning);
            className.addIf(validationProblemSeverity && validationProblemSeverity === "error", Styles.error);
            className.addIf(this.props.disabled, Styles.disabled);
            className.addIf(this.props.disabled && this.props.isChecked, Styles.disabledChecked);
            className.addIf(this.props.size === "compact", Styles.compactSize);
            return className.classNames;
        }

        className = new CompositeClassName(Styles.container);
        className.addIf(this.props.isChecked, Styles.checked);
        className.addIf(validationProblemSeverity && validationProblemSeverity === "warning", Styles.containerWarningColor);
        className.addIf(validationProblemSeverity && validationProblemSeverity === "error", Styles.containerErrorColor);
        return className.classNames;
    }

    private getCheckmarkClass() {

        const validationProblemSeverity = this.props.validationResultSeverity;

        if (this.props.displayType && this.props.displayType === "buttons" || this.props.displayType === "groupedButtons") {
            return Styles.checkmarkButton;
        } else {
            const className = new CompositeClassName(Styles.checkmark);
            className.addIf(validationProblemSeverity && validationProblemSeverity === "warning", Styles.checkmarkWarning);
            className.addIf(validationProblemSeverity && validationProblemSeverity === "error", Styles.checkmarkError);
            className.addIf(this.props.disabled, Styles.checkmarkDisabled);
            return className.classNames;
        }
    }

    @State.bound
    private valueChanged(e: React.ChangeEvent<HTMLInputElement>) {
        if (!this.props.disabled) {
            this.props.onChanged(e.target.value);
        }
    }
}