import CareContactType from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareContactType.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import moment from "moment";

export class PatientServiceListItem {
    constructor(
        public quantity: number,
        public isBilled: boolean
    ) {
    }
}

export class PatientServiceList {
    constructor(
        public careActivityId: CareActivityId,
        public pointOfCareId: PointOfCareId,
        public careContactType: CareContactType,
        public careIdentifier: string,
        public wentUnderCareAt: moment.Moment,
        public items: PatientServiceListItem[]
    ) {
    }
}