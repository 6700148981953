import React, { useMemo, useCallback, useContext } from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import { isNullOrUndefined, isNullOrUndefinedOrGivenString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import WorklistTypeBoundNDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/WorklistTypeBoundNDataPanel";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { getUseCaseFromNDataUseCaseStateAsUrlParam, parseUseCaseStateFromUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import FilterConditionConverters from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/FilterConditionConverters";
import OutpatientTreatmentRoutes from "./OutpatientTreatmentRoutes";
import IFormRouteParams from "./IFormRouteParams";
import UseCases from "@Primitives/UseCases";
import CareActivityId from "@Primitives/CareActivityId.g";

function CareActivityFormsPage(props: IRoutingFrameContentProps<IFormRouteParams>) {

    const [selectedId, useCaseState, careActivityId, isReadOnly] = useMemo(() => {
        const routeParameters = props.routingController.currentRoute.parameters;
        return [
            isNullOrUndefinedOrGivenString(routeParameters.selectedId, "null") ? null : new FormInstanceId(routeParameters.selectedId),
            parseUseCaseStateFromUrlParam(routeParameters.useCase),
            isNullOrUndefinedOrGivenString(routeParameters.careActivityId, "null") ? null : new CareActivityId(routeParameters.careActivityId),
            isNullOrUndefinedOrGivenString(routeParameters.mode, "null") ? false : true
        ];
    }, [props.routingController]);

    const setState = useCallback((id: string, newUseCaseState: INDataUseCaseState) => {
        let useCaseId = id;
        if (newUseCaseState?.useCase?.value === UseCases.editableFormInstanceDetail) {
            useCaseId = newUseCaseState.useCaseArguments.find(it => it.name === "formInstanceId")?.value?.value ?? "new";
        }

        props.routingController.replace(OutpatientTreatmentRoutes.form.makeRoute({
            careActivityId: props.routingController.currentRoute.parameters.careActivityId,
            selectedId: useCaseId ?? "null",
            useCase: getUseCaseFromNDataUseCaseStateAsUrlParam(newUseCaseState),
            mode: isReadOnly ? "read-only" : "null"
        }));
    }, [props.routingController]);

    const dynamicFilters = {};
    dynamicFilters["FormInstanceBased_IsUserForm"] = FilterConditionConverters[WorklistConditionType.Boolean](true);
    dynamicFilters["FormInstanceBased_FormBaseEntity"] = FilterConditionConverters[WorklistConditionType.String]("CareActivity");
    dynamicFilters["FormInstanceBased_FormBaseEntityId"] = FilterConditionConverters[WorklistConditionType.Numeric](parseInt(careActivityId?.value, 10));

    return (
        <SingleLayout>
            <WorklistTypeBoundNDataPanel
                worklistDefinitionReference="FormInstance"
                cacheKey={`CAreActivityId_${careActivityId?.value}`}
                dynamicFilters={dynamicFilters}
                onChange={setState}
                useCaseState={useCaseState}
                selectedRowId={selectedId?.value}
                disableDetailStrictMode
                hasPrintButton={false}
                hasRefreshButton
                hideMasterToolbar={isReadOnly}
            />
        </SingleLayout>
    );
}
export default withLoadingBoundary(CareActivityFormsPage);