import React from "react";
import * as Ui from "@CommonControls";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import EhiCareActivityBaseDataPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCareActivityBaseDataPanel/EhiCareActivityBaseDataPanel";
import PatientId from "@Primitives/PatientId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiDocumentListPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiDocumentListPanel/EhiDocumentListPanel";
import IEhiPatientsCareActivitiesListExtensionData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiPatientsCareActivitiesListFilter/IEhiPatientsCareActivitiesListExtensionData";
import EhiEmergencyEventsPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiEmergencyEventsPanel/EhiEmergencyEventsPanel";
import EhiEvent from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEvent";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiCovidSelfExaminationResultPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidSelfExaminationResultPanel/EhiCovidSelfExaminationResultPanel";
import EhiEReferralId from "@Primitives/EhiEReferralId.g";
import EhiEReferralPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralPanel/EhiEReferralPanel";
import EhiCareServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareServiceFactory";
import EhiDigitalPrescriptionMasterDetailPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/EhiDigitalPrescriptionMasterDetailPanel/EhiDigitalPrescriptionMasterDetailPanel";
import EhiPrescriptionServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/EhiPrescriptionServiceFactory";
import MultiEhiService from "@HunEHealthInfrastructurePlugin/Services/MultiEhiService";
import ViewContextProvider from "@Toolkit/ReactClient/Components/ViewContext/ViewContextProvider";
import {MasterDetail} from "@CommonControls/Layout/MasterDetailLayout";
import EhiCareService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareService";
import EhiPrescriptionService from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/EhiPrescriptionService";
import moment from "moment";
import EhiCareActivityDataPanelView from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCareActivityDataPanel/EhiCareActivityDataPanelView";
import CareTypeId from "@Primitives/CareTypeId.g";

interface IEhiCareActivityPanelDependencies {
    ehiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    ehiCareServiceFactory: EhiCareServiceFactory;
    ehiPrescriptionServiceFactory: EhiPrescriptionServiceFactory;
}

interface IEhiCareActivityPanelProps {
    _dependencies?: IEhiCareActivityPanelDependencies;
    patientId: PatientId;
    extensionData: { [id: string]: string };
    listExtensionData: IEhiPatientsCareActivitiesListExtensionData;
    activeTabName: string;
    setActiveTabName: (newValue: string) => void;
    ehiCareService: EhiCareService;
    beginningOfCare: moment.Moment;
    dischargedAt: moment.Moment;
    careTypeId: CareTypeId;
}

/** @screen */
@State.observer
class EhiCareActivityPanel extends React.Component<IEhiCareActivityPanelProps> {

    private readonly baseDataTabName = "baseData";
    private readonly extendedBaseDataTabName = "extendedBaseData";
    private readonly eReferralTabName = "eReferral";
    private readonly documentsTabName = "documents";
    private readonly prescriptionsTabName = "prescriptions";
    private readonly emergencyEventsTabName = "emergencyEvents";
    private readonly selfExaminationTabName = "selfExamination";

    @State.observable.ref private hasEmergencyTab = false;
    @State.observable.ref private ehiServices: MultiEhiService<{ care: EhiCareService, prescription: EhiPrescriptionService }> = null;

    @State.computed
    private get hasEReferralTab() {
        return !!this.ehiEReferralId;
    }

    @State.computed
    private get hasSelfExaminationTab() {
        return !isNullOrUndefined(this.ehiCovidSelfExaminationResult);
    }

    private ehiIdFieldName = "EhiId";
    private ehiCareIdentifierFieldName = "EhiCareIdentifier";
    private ehiCovidSelfExaminationResultFieldName = "CovidSelfExaminationForm";
    private ehiEReferralIdFieldName = "EReferralEhiId";

    private get ehiId() {
        return this.props.extensionData && this.props.extensionData[this.ehiIdFieldName];
    }

    private get ehiCareIdentifier() {
        return this.props.extensionData && this.props.extensionData[this.ehiCareIdentifierFieldName];
    }

    private get ehiCovidSelfExaminationResult() {
        return this.props.extensionData && this.props.extensionData[this.ehiCovidSelfExaminationResultFieldName];
    }

    private get ehiEReferralId() {
        return !isNullOrEmptyString(this.props.extensionData && this.props.extensionData[this.ehiEReferralIdFieldName])
            && new EhiEReferralId(this.props.extensionData[this.ehiEReferralIdFieldName]);
    }

    @State.computed
    private get ehiCareService() {
        return this.ehiServices?.ehiServices?.care;
    }

    @State.computed
    private get ehiPrescriptionService() {
        return this.ehiServices?.ehiServices?.prescription;
    }

    @State.action.bound
    private initializeServices() {
        if (!this.ehiServices) {
            this.ehiServices = new MultiEhiService({
                care: this.props.ehiCareService,
                prescription: this.props._dependencies.ehiPrescriptionServiceFactory.getEhiPrescriptionService()
            });
        }
    }

    public componentDidMount() {
        this.initializeServices();
    }

    public componentDidUpdate() {
        this.initializeServices();
        if ((this.props.activeTabName === this.eReferralTabName && !this.hasEReferralTab) ||
            (this.props.activeTabName === this.emergencyEventsTabName && !this.hasEmergencyTab) ||
            (this.props.activeTabName === this.selfExaminationTabName && !this.hasSelfExaminationTab)) {
            this.props.setActiveTabName(this.baseDataTabName);
        }
    }

    @State.action.bound
    private initBaseData(event: EhiEvent) {
        if (!!event?.ehiCareTypeId) {
            const careType = this.props._dependencies!.ehiCareReferenceDataStore.ehiCareTypes.get(event?.ehiCareTypeId);
            this.hasEmergencyTab = careType.code?.toUpperCase().startsWith("SBO"); // TODO: do this better when emergency care type appears in HisPlatform
        }
    }

    @State.bound
    private renderWithPadding(content: React.ReactNode) {
        return (
            <Ui.PageBox2>
                <Ui.PageBox2.Body
                    hasSidePadding
                    hasVerticalPadding
                >
                    {content}
                </Ui.PageBox2.Body>
            </Ui.PageBox2>
        );
    }

    @State.bound
    private renderMasterDetailPanel(content: React.ReactNode) {
        return (
            <ViewContextProvider totalHeightLoss={149}>
                {content}
            </ViewContextProvider>
        );
    }

    @State.bound
    public renderTabs(): React.ReactNode[] {
        const tabs: React.ReactNode[] = [];
        let key = 1;
        tabs.push(
            <MasterDetail.Tab
                title={""}
                tabName={this.baseDataTabName}
                tabTitle={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Titles.BaseData}
                key={key++}
            >
                {this.renderWithPadding(!!this.ehiCareService && (
                    <EhiCareActivityBaseDataPanel
                        isEmergency={this.props.listExtensionData?.EmergencyAccess.IsEmergency ?? false}
                        emergencyReason={this.props.listExtensionData?.EmergencyAccess.EmergencyReason}
                        patientId={this.props.patientId}
                        ehiId={this.ehiId}
                        ehiCareService={this.ehiCareService}
                        onLoaded={this.initBaseData}
                    />
                ))}
            </MasterDetail.Tab>
        );
        
        tabs.push(
            <MasterDetail.Tab
                title={""}
                tabName={this.extendedBaseDataTabName}
                tabTitle={"Diagnozis és beavatkozás adatok"}
                key={key++}
            >
                {this.renderWithPadding(!!this.ehiCareService && (
                    <EhiCareActivityDataPanelView
                        isEmergency={this.props.listExtensionData?.EmergencyAccess.IsEmergency ?? false}
                        emergencyReason={this.props.listExtensionData?.EmergencyAccess.EmergencyReason}
                        patientId={this.props.patientId}
                        ehiId={this.ehiId}
                        ehiCareService={this.ehiCareService}

                    />
                ))}
            </MasterDetail.Tab>
        );

        if (this.hasEReferralTab) {
            tabs.push(
                <MasterDetail.Tab
                    title={""}
                    tabName={this.eReferralTabName}
                    tabTitle={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Titles.EReferral}
                    key={key++}
                >
                    {this.renderWithPadding(!!this.ehiCareService && (
                        <EhiEReferralPanel
                            ehiEReferralId={this.ehiEReferralId}
                            ehiCareService={this.ehiCareService}
                            isEmergency={this.props.listExtensionData?.EmergencyAccess.IsEmergency ?? false}
                            emergencyReason={this.props.listExtensionData?.EmergencyAccess.EmergencyReason}
                            patientId={this.props.patientId}
                        />
                    ))}
                </MasterDetail.Tab>
            );
        }

        tabs.push(
            <MasterDetail.Tab
                title={""}
                tabName={this.documentsTabName}
                tabTitle={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Titles.Documents}
                key={key++}
            >
                {this.renderWithPadding(!!this.ehiCareService && (
                    <EhiDocumentListPanel
                        isEmergency={this.props.listExtensionData?.EmergencyAccess.IsEmergency ?? false}
                        emergencyReason={this.props.listExtensionData?.EmergencyAccess.EmergencyReason}
                        ehiCareService={this.ehiCareService}
                        ehiEventCatalogIdentifier={this.ehiCareIdentifier}
                        patientId={this.props.patientId}
                    />
                ))}
            </MasterDetail.Tab>
        );

        if (this.ehiPrescriptionService && this.ehiCareIdentifier && this.props.careTypeId?.value !== "CovidRapidTest") {
            tabs.push(
                <MasterDetail.Tab
                    title={""}
                    tabName={this.prescriptionsTabName}
                    tabTitle={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Titles.Prescriptions}
                    key={key++}
                >
                    {this.renderMasterDetailPanel(
                        <EhiDigitalPrescriptionMasterDetailPanel
                            ehiCareIdentifier={this.ehiCareIdentifier}
                            patientId={this.props.patientId}
                            isEmergency={this.props.listExtensionData?.EmergencyAccess.IsEmergency ?? false}
                            emergencyReason={this.props.listExtensionData?.EmergencyAccess.EmergencyReason}
                            ehiPrescriptionService={this.ehiPrescriptionService}
                            beginningOfCare={this.props.beginningOfCare}
                            dischargedAt={this.props.dischargedAt}
                        />
                    )}
                </MasterDetail.Tab>
            );
        }

        if (this.hasEmergencyTab) {
            tabs.push(
                <MasterDetail.Tab
                    title={""}
                    tabName={this.emergencyEventsTabName}
                    tabTitle={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Titles.EmergencyEvents}
                    key={key++}
                >
                    {!!this.ehiCareService && this.renderMasterDetailPanel(
                        <EhiEmergencyEventsPanel
                            isEmergency={this.props.listExtensionData?.EmergencyAccess.IsEmergency ?? false}
                            emergencyReason={this.props.listExtensionData?.EmergencyAccess.EmergencyReason}
                            ehiCareService={this.ehiCareService}
                            ehiId={this.ehiId}
                            patientId={this.props.patientId}
                        />
                    )}
                </MasterDetail.Tab>
            );
        }

        if (this.hasSelfExaminationTab) {
            tabs.push(
                <MasterDetail.Tab
                    title={""}
                    tabName={this.selfExaminationTabName}
                    tabTitle={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Titles.SelfExamination}
                    key={key++}
                >
                    {this.renderWithPadding(
                        <EhiCovidSelfExaminationResultPanel
                            extensionData={this.ehiCovidSelfExaminationResult}
                        />
                    )}
                </MasterDetail.Tab>
            );
        }

        return tabs;
    }

    public render() {
        return (
            <MasterDetail.Detail
                initiallyActiveTabName={this.baseDataTabName}
            >
                {this.renderTabs()}
            </MasterDetail.Detail>
        );
    }
}

export default connect(
    EhiCareActivityPanel,
    new DependencyAdapter<IEhiCareActivityPanelProps, IEhiCareActivityPanelDependencies>(c => {
        return {
            ehiCareReferenceDataStore: c.resolve("HunEhiCareReferenceDataStore"),
            ehiCareServiceFactory: c.resolve("EhiCareServiceFactory"),
            ehiPrescriptionServiceFactory: c.resolve("EhiPrescriptionServiceFactory")
        };
    }),
);