import moment from "moment";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import TokenDefaultValue from "@Toolkit/FormEngine/Model/Layout/TokenDefaultValue";

export default abstract class FormDataElementBase {

    @State.observable.ref public name: string;
    @State.observable.ref public isReadOnly: boolean;
    @State.observable.ref public isArray: boolean;
    @State.observable.ref public isVisible: boolean;
    @State.observable.ref public defaultValue: boolean | string | LocalDate | TimeOfDay | moment.Moment | number | TokenDefaultValue | null;

    constructor(
        name: string,
        isArray: boolean,
        defaultValue: boolean | string | LocalDate | TimeOfDay | moment.Moment | number | TokenDefaultValue | null,
        isReadOnly: boolean,
        isVisible: boolean
    ) {
        this.name = name;
        this.isArray = isArray;
        this.isReadOnly = isReadOnly;
        this.isVisible = isVisible;
        this.defaultValue = defaultValue;
    }

    @State.action.bound
    public setName(name: string) {
        this.name = name;
    }

    @State.action.bound
    public setIsArray(isArray: boolean) {
        this.isArray = isArray;
    }

    @State.action.bound
    public setIsReadOnly(isReadOnly: boolean) {
        this.isReadOnly = isReadOnly;
    }

    @State.action.bound
    public setIsVisible(isVisible: boolean) {
        this.isVisible = isVisible;
    }

    @State.action.bound
    public setDefaultValue(defaultValue: boolean | string | LocalDate | TimeOfDay | moment.Moment | number | TokenDefaultValue | null) {
        this.defaultValue = defaultValue;
    }

    public abstract formDataElementType: string;
}