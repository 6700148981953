import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import ConditionId from "@Primitives/ConditionId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { stringifyVersionSelectors } from "./ReferenceDataHelpers";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import IConditionVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IConditionVersion";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { IConditionListItem } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IConditionListItem";
import { IPagingState, IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import { ICondition } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ICondition";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IConditionWithoutVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IConditionWithoutVersion";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import IncludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierFilter";
import ExcludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierFilter";
import IncludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierSystemIdFilter";
import ExcludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierSystemIdFilter";
import CodeStartsWithFilter from "@Toolkit/CommonWeb/Model/Filtering/CodeStartsWithFilter";
import CodeDoesNotStartWithFilter from "@Toolkit/CommonWeb/Model/Filtering/CodeDoesNotStartWithFilter";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";
import ConditionSelectorQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/Conditions/Enum/ConditionSelectorQueryOrderingFields.g";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";

@Di.injectable()
export default class ConditionsApiAdapter extends ApiAdapterBase {

    constructor(@Di.inject("IConditionsClient") private apiClient: Proxy.IConditionsClient) {
        super();
    }

    @State.bound
    public loadConditionVersionsAsync(versionSelectors: Array<IEntityVersionSelector<ConditionId>>) {
        return this.processOperationAsync(
            new SimpleStore<IConditionVersion[]>(),
            async (target) => {
                const versionSelectorsString = stringifyVersionSelectors(versionSelectors);
                const response = await this.apiClient.getConditionVersionsBySelectorsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetConditionVersionsBySelectorsControllerDto({
                        conditionVersionSelectors: versionSelectors.map(i => (new Proxy.EntityVersionSelectorOfConditionId({
                            validOn: i.validOn,
                            entityId: i.id
                        })))
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.conditionVersions.map(this.mapConditionVersion);
            }
        );
    }

    @State.bound
    public searchConditionsByCode(code: string, maxResultCount: number, validOn: LocalDate, filters?: FilterBase[]) {
        return this.processOperationAsync(
            new SimpleStore<any[]>(),
            async (target) => {
                const response = await this.apiClient.searchConditionsByCodeQueryAsync(
                    CreateRequestId(),
                    new Proxy.SearchConditionsByCodeControllerDto({
                        code: code,
                        maxResultCount: maxResultCount,
                        validOn: validOn,
                        filters: this.convertFiltersToProxyFilters(filters)
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.results.map(r => {
                    return { id: r.id, code: r.code, name: r.name };
                });
            }
        );
    }

    @State.bound
    public searchConditions(filterText: string, validOn: LocalDate, ordering: IOrderingState, paging: IPagingState, filters?: FilterBase[]) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<{ values: IConditionListItem[], totalCount: number }>(),
            async (target) => {
                const response = await this.apiClient.conditionSelectorQueryAsync(
                    CreateRequestId(),
                    new Proxy.ConditionSelectorControllerDto({
                        filterText: filterText,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfConditionSelectorQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfConditionSelectorQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: ConditionSelectorQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({ pageIndex: paging.currentPage || 0, pageSize: paging.pageSize || 20 })
                        }),
                        validOn: validOn,
                        filters: this.convertFiltersToProxyFilters(filters)
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    values: response.results && response.results.values.map(this.mapConditionListItem),
                };
            }
        );
    }

    @State.bound
    public getConditionIdsByCodesAsync(codes: string[]) {
        return this.processOperationAsync(
            new SimpleStore<ICondition[]>(),
            async target => {
                const response = await this.apiClient.tryGetConditionIdsByCodesQueryAsync(CreateRequestId(),
                    new Proxy.TryGetConditionIdsByCodesControllerDto({
                        codes: codes.map(c => new Identifier(new IdentifierSystemId("ICD10"), c)),
                    }));
                target.operationInfo = createOperationInfo(response);
                target.value = this.mapToInterface(response.results);
            }
        );
    }

    @State.bound
    public getConditionsWithoutVersionsAsync(ids: ConditionId[], validOn: LocalDate = null) {
        return this.processOperationAsync(
            new SimpleStore<IConditionWithoutVersion[]>(),
            async target => {
                const response = await this.apiClient.getConditionsWithoutVersionsByIdsQueryAsync(CreateRequestId(),
                    new Proxy.GetConditionsWithoutVersionsByIdsControllerDto({
                        ids,
                        validOn
                    })
                );
                target.operationInfo = createOperationInfo(response);
                target.value = response.conditionsWithoutVersions?.map(i => ({
                    id: i.id,
                    code: i.code,
                    hasValidVersion: i.hasValidVersion
                }));
            }
        );
    }

    @State.bound
    private convertFiltersToProxyFilters(filters: FilterBase[]): Proxy.FilterBase[] {
        if (!filters) {
            return [];
        }

        return filters.map((filter: FilterBase) => this.mapFilterToProxyFilter(filter));
    }

    private mapFilterToProxyFilter(filter: FilterBase): Proxy.FilterBase {
        if (filter instanceof IncludeIdentifierFilter) {
            return new Proxy.IncludeIdentifierFilter({
                value: new Identifier(
                    new IdentifierSystemId(filter.value.identifierSystemId.value),
                    filter.value.value
                )
            });
        } else if (filter instanceof ExcludeIdentifierFilter) {
            return new Proxy.ExcludeIdentifierFilter({
                value: new Identifier(
                    new IdentifierSystemId(filter.value.identifierSystemId.value),
                    filter.value.value
                )
            });
        } else if (filter instanceof IncludeIdentifierSystemIdFilter) {
            return new Proxy.IncludeIdentifierSystemIdFilter({
                value: filter.value
            });
        } else if (filter instanceof ExcludeIdentifierSystemIdFilter) {
            return new Proxy.ExcludeIdentifierSystemIdFilter({
                value: filter.value
            });
        } else if (filter instanceof CodeStartsWithFilter) {
            return new Proxy.CodeStartsWithFilter({
                value: filter.value
            });
        } else if (filter instanceof CodeDoesNotStartWithFilter) {
            return new Proxy.CodeDoesNotStartWithFilter({
                value: filter.value
            });
        } else if (filter instanceof ExplicitIdentifierFilter) {
            return new Proxy.ExplicitIdentifierFilter({
                type: filter.type,
                value: filter.value
            });
        } else {
            throw new Error(`Unsupported filter: ${typeof filter}`);
        }
    }

    private mapToInterface(obj: object) {
        return Object.keys(obj).map(key => {
            const splittedKey = key.split("|");
            return {
                identifierSystemid: splittedKey[0],
                code: splittedKey[1],
                value: obj[key]
            } as ICondition;
        });
    }

    private mapConditionListItem(dto: Proxy.ConditionListItemDto) {
        return {
            id: dto.id,
            code: dto.code,
            name: dto.name
        } as IConditionListItem;
    }

    private mapConditionVersion(dto: Proxy.ConditionVersionDto) {
        return {
            alternativeNames: dto.alternativeNames,
            alternativeOf: dto.alternativeOf,
            code: dto.code,
            description: dto.description,
            genderDependence: dto.genderDependence,
            hasLaterality: dto.hasLaterality,
            id: dto.id,
            name: dto.name,
            validity: dto.validity
        } as IConditionVersion;
    }
}
