import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import PermissionDefinitionBuilder from "@PluginInterface/OperationAccessControl/PermissionDefinitionBuilder";
import { HunEHealthInfrastructurePermissionDefinitions } from "./HunEHealthInfrastructurePermissionDefinitions.g";

export default function configureEesztIntegrationPermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry) {
    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder
            .create(HunEHealthInfrastructurePermissionDefinitions.EesztIntegration.CommunicationLogs.ViewEhiCommunicationLogs)
            .build()
    );
}
