import Money from "@Toolkit/CommonWeb/Model/Money";
import MedicationId from "@Primitives/MedicationId.g";
import MedicationPricingId from "@Primitives/MedicationPricingId.g";
import SubstanceBasedRestrictionId from "@Primitives/SubstanceBasedRestrictionId.g";
import OrderTypeId from "@Primitives/OrderTypeId.g";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import StoreBase from "@Toolkit/CommonWeb/Model/StoreBase";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";

export default class MedicationPricing extends StoreBase {
    public id: MedicationPricingId;
    public medicationId: MedicationId;
    public curativeLimit: number;
    public dailyTherapyCost: Money;
    public grossPrice: Money;
    public netPrice: Money;
    public substanceBasedRestrictionId: SubstanceBasedRestrictionId;
    public orderTypeId: OrderTypeId;
    public claimTypes: MedicationSubsidyClaimTypeId[];
    public validity: LocalDateRange;
    public counterSignDesiredValue: boolean;

    constructor() {
        super();
    }
}