import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import * as Ui from "@CommonControls";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { LockIndicatorComponent } from "@HisPlatformControls";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import FamilyDoctorDocumentStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentStore";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { IPermissionCheckOperation } from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContext";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";

interface IFamilyDoctorDocumentHeaderPanelDependencies {
}

interface IFamilyDoctorDocumentHeaderPanelProps {
    _dependencies?: IFamilyDoctorDocumentHeaderPanelDependencies;
    store: FamilyDoctorDocumentStore;
    operationsToChecks: IPermissionCheckOperation;
    openFamilyDoctorDocumentDocumentAsync(): Promise<void>;
    onDeleteFamilyDoctorDocumentAsync: () => Promise<boolean>;
    onSaveStoreAsync: () => Promise<boolean>;
    onLockEditClickedAsync: () => Promise<void>;
}

@State.observer
class FamilyDoctorDocumentHeaderPanel extends React.Component<IFamilyDoctorDocumentHeaderPanelProps> {

    @State.computed private get store() { return this.props.store; }
    @State.computed private get isNew() { return this.store?.id?.value === "new"; }

    public render() {
        if (!this.store) {
            return null;
        }

        return (
            <PermissionCheckContextProvider operationsToCheck={this.props.operationsToChecks}>
                <ReadOnlyContext.Provider value={false}>
                    <Ui.Flex>
                        <Ui.Flex.Item sm={12} horizontalContentAlignment="right">
                            <LockIndicatorComponent
                                locked={!this.store.isMutable}
                                lockedBy={this.store.lockInfo?.preventingLockUserId}
                                onEditClickedAsync={this.props.onLockEditClickedAsync}
                                permissionCheckOperationNames="SaveFamilyDoctorDocument"
                            />
                            {!this.isNew && this.store.isMutable &&
                                <>
                                    <Ui.Button
                                        text={StaticCareResources.Common.Button.Delete}
                                        onClickAsync={this.props.onDeleteFamilyDoctorDocumentAsync}
                                        size="compact"
                                        automationId="__deleteFamilyDoctorDocument"
                                        iconName="trash"
                                        visualStyle="negative-standard"
                                        permissionCheckOperationNames="RemoveFamilyDoctorDocument"
                                        permissionDeniedStyle="disabled"
                                    />
                                    <Ui.Button
                                        text={StaticWebAppResources.Common.Button.PrintPreview}
                                        iconName="print"
                                        size="compact"
                                        onClickAsync={this.props.openFamilyDoctorDocumentDocumentAsync}
                                        automationId="openFamilyDoctorDocumentDocumentButton"
                                        permissionCheckOperationNames="CreateFamilyDoctorDocument"
                                        permissionDeniedStyle="disabled"
                                    />
                                </>}
                            <Ui.SaveButton
                                text={StaticCareResources.Common.Button.Save}
                                onClickAsync={this.props.onSaveStoreAsync}
                                size="compact"
                                automationId="__addHistoryItem"
                                visualStyle={"primary"}
                                disabled={!this.store.isMutable}
                                permissionCheckOperationNames="SaveFamilyDoctorDocument"
                                permissionDeniedStyle="disabled"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </ReadOnlyContext.Provider>
            </PermissionCheckContextProvider>
        );
    }
}

export default connect(
    FamilyDoctorDocumentHeaderPanel,
    new DependencyAdapter<IFamilyDoctorDocumentHeaderPanelProps, IFamilyDoctorDocumentHeaderPanelDependencies>(c => ({
    }))
);
