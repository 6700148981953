import React, { useContext } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { BusinessErrorHandlerContext, IBusinessErrorHandler } from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import * as Styles from "./ToggleMenuItem.less";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";

interface IMenuItemProps {
    value?: boolean;
    onChange?: (newValue: boolean) => void;
    onChangeAsync?: (newValue: boolean) => Promise<void>;
    children?: React.ReactNode;
    disabled?: boolean;
    _businessErrorHandler?: IBusinessErrorHandler;
}

@State.observer
class ToggleMenuItemCore extends React.Component<IMenuItemProps> {

    @State.observable private isLoading = false;

    @State.computed private get outerClassName() {
        const classNames = new CompositeClassName("react-contexify__item", "__context-menu-item");
        classNames.addIf(this.props.disabled, "__disabled");
        classNames.addIf(!!this.props.value, Styles.toggleOn);
        return classNames.classNames;
    }

    @State.computed private get innerClassName() {
        const classNames = new CompositeClassName("react-contexify__item__content");
        classNames.addIf(!!this.props.value, Styles.whiteText);
        return classNames.classNames;
    }

    public render() {

        return (
            <div onClick={this.changeHandler} className={this.outerClassName}>
                <div className={this.innerClassName}>
                    {this.props.children}
                </div>
            </div>
        );
    }

    @State.bound
    private changeHandler() {
        if (this.props.onChangeAsync && !this.isLoading) {
            this.setLoadingState();
            dispatchAsyncErrors(
                this.props.onChangeAsync(!this.props.value)
                    .then(() => this.setLoadingState(false))
                    .finally(() => {
                        this.setLoadingState(false);
                    }), this);
        } else {
            this.props.onChange(!this.props.value);
        }
    }

    @State.action
    private setLoadingState(loading = true) {
        this.isLoading = loading;
    }
}


const ToggleMenuItem: React.FC<IMenuItemProps> = props => {
    const businessErrorHandler = useContext(BusinessErrorHandlerContext);

    return (
        <ToggleMenuItemCore
            {...props}
            _businessErrorHandler={businessErrorHandler} />
    );
};

export default ToggleMenuItem;