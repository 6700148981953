import React from "react";
import Styles from "./PageBoxButton.less";
import { ICommonControlProps, getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";


interface IPageBoxButton extends ICommonControlProps {
    onClick?: () => void;
    automationId?: string;
}

@State.observer
export default class PageBoxButton extends React.Component<IPageBoxButton> {

    public render() {
        const htmlProps = getStandardHtmlProps(this.props);
        return (
            <div {...htmlProps} className={Styles.pageBoxButton} onClick={this.props.onClick} data-automation-id={this.props.automationId || undefined}>
               {this.props.children}
            </div>
        );
    }

}