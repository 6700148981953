import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import StoreMapper from "@Toolkit/CommonWeb/ApiAdapter/StoreMapper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import Di from "@Di";
import HealthcareProfession from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/HealthcareProfession";
import HealthcareProfessionStoreMapper from "./HealthcareProfessionStoreMapper";

@Di.injectable()
export default class HealthcareProfessionsStoreMapper extends StoreMapper<SimpleStore<HealthcareProfession[]>, Proxy.GetHealthCareProfessionsByIdsQueryResponse> {

    constructor(
        @Di.inject("HealthcareProfessionStoreMapper") private readonly healthcareProfessionStoreMapper: HealthcareProfessionStoreMapper
    ) {
        super();
    }

    protected applyToStoreCore(target: SimpleStore<HealthcareProfession[]>, response: Proxy.GetHealthCareProfessionsByIdsQueryResponse) {
        const mapped: HealthcareProfession[] = [];

        for (const hp of response.healthCareProfessions) {
            const healthcareProfession = new HealthcareProfession();
            this.healthcareProfessionStoreMapper.applyToStore(healthcareProfession, hp);
            mapped.push(healthcareProfession);
        }

        target.value = mapped;
        target.operationInfo = createOperationInfo(response);
    }
}