import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityId from "@Primitives/CareActivityId.g";
import PatientId from "@Primitives/PatientId.g";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import MasterDetailLayout from "@CommonControls/Layout/MasterDetailLayout";
import MasterDetailMaster from "@CommonControls/Layout/MasterDetailMaster";
import Prescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Prescription";
import MasterDetailDetail from "@CommonControls/Layout/MasterDetailDetail";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import IPrescriptionListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IPrescriptionListItem";
import PrescriptionId from "@Primitives/PrescriptionId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import MedicationSelectorDialogParams, { IMedicationSelectorDialogResult } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationSelectorDialog/MedicationSelectorDialogParams";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import MedicationVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/MedicationVersion";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import MedicationId from "@Primitives/MedicationId.g";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import FrequencyBasedDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/FrequencyBasedDosage";
import { LockIndicatorComponent } from "@HisPlatform/Components/HisPlatformControls";
import PrescriptionListView from "./PrescriptionListView";
import PrescriptionFilterStore from "./PrescriptionFilterStore";
import _ from "@HisPlatform/Common/Lodash";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import { DataGridLoadType, IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import SingleMedicationReference from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/SingleMedicationReference";
import MedicationTypeId from "@Primitives/MedicationTypeId.g";
import MedicationEquipmentList from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationEquipmentList";
import MedicationEquipmentListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationEquipmentListItem";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import TextualPrescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/TextualPrescription";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import PrescriptionSearchBase from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionSearchBase";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import WellKnownReferenceCodes from "@HunEHealthInfrastructurePlugin/Common/WellKnownReferenceCodes";
import IStatusChangeReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/IStatusChangeReason";
import StatusChangeReasonDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/StatusChangeReasonDialog/StatusChangeReasonDialogParams";
import StatusChangeReasonTypeId from "@Primitives/StatusChangeReasonTypeId.g";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import { arrayIsNullOrEmpty, isNullOrEmptyString, isNullOrUndefined, nullFunction, isNullOrWhiteSpace } from "@Toolkit/CommonWeb/NullCheckHelpers";
import CareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CareActivityStore";
import PrintPrescriptionDialogParams from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrintPrescriptionDialog/PrintPrescriptionDialogParams";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { IDefaultDetailPanelButtonsProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/Common/DefaultDetailPanelButtons";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import SubsidyOptionCheckResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/SubsidyOptionCheckResult";
import ReferenceDataApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/ReferenceDataApiAdapter";
import DynamicPropertiesApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/DynamicProperties/DynamicPropertiesApiAdapter";
import PractitionerIdentifierStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerIdentifierStore";
import DiagnosisListApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/DiagnosisList/DiagnosisListApiAdapter";
import DiagnosisStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisStore";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";
import LateralityId from "@Primitives/LateralityId.g";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import FormExtensionRegistryAdapter from "@PluginInterface/FormExtension/FormExtensionRegistryAdapter";
import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import IPrescriptionToBeCreated from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionsToBeCreated/IPrescriptionToBeCreated";
import PrescriptionToEhiDialogParams, { IPrescriptionToEhiDialogResult } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/PrescriptionToEhiDialog/PrescriptionToEhiDialogParams";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import EhiUserContext from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiUserContext";
import PrescriptionDataSource from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionDataSource";
import ExternalPrescriptionDetailModalParams from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/ExternalMedicationDetailModal/ExternalPrescriptionDetailModalParams";
import MedicationInfoModalParams from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/MedicationInfoModal/MedicationInfoModalParams";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import MedicationPricingAndSubsidies from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationPricingAndSubsidies";
import DosageModeId from "@Primitives/DosageModeId.g";
import PrescriptionTemplateType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionTemplateType";
import IDoctor from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IDoctor";
import TextualDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/TextualDosage";
import PrescriptionDetailView from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/PrescriptionListPanel/PrescriptionDetailView";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import ExternalPrescriptionDetailViewSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/ExternalPrescriptionDetailView/ExternalPrescriptionDetailViewSelector";
import IExternalPrescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IExternalPrescription";
import PricingAndSubsidyLoader from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/PricingAndSubsidyLoader";
import StaticHunEHealthInfrastructureWebAppResources from "@HunEHealthInfrastructurePlugin/StaticResources/StaticHunEHealthInfrastructureWebAppResources";
import IPrescriptionEhiErrorsExtensionPointProps from "@PluginInterface/BoundedContexts/MedicationRequest/ExtensionPoints/IPrescriptionEhiErrorsExtensionPointProps";
import EhiPrescriptionSearchMode from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/EhiPrescriptionSearchMode";
import EhiPrescriptionServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/EhiPrescriptionServiceFactory";
import EhiPrescriptionService from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/EhiPrescriptionService";
import EhiUserType from "@HunEHealthInfrastructurePlugin/Model/EhiUserType";
import EhiServiceCallStatus from "@HunEHealthInfrastructurePlugin/Model/EhiServiceCallStatus";
import MedicationFormulaList from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationFormulaList";
import MedicationFormulaListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationFormulaListItem";
import MedicationSubsidizedPricingTypeId from "@Primitives/MedicationSubsidizedPricingTypeId.g";
import DesignatedOrganization from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/DesignatedOrganization";
import IMedicationSubsidyClaimType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IMedicationSubsidyClaimType";
import PractitionerId from "@Primitives/PractitionerId.g";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import ISearchParametersService from "@Toolkit/CommonWeb/SearchParametersService/Definition/ISearchParametersService";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import ApiBusinessError from "@Toolkit/CommonWeb/ApiAdapter/ApiBusinessError";
import UnauthorizedOperationBusinessError from "@Toolkit/CommonWeb/Model/UnauthorizedOperationBusinessError";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import MedicationReferenceBase from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationReferenceBase";
import MedicationEquipmentSelectorDialogParams, { IMedicationEquipmentSelectorDialogResult } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationEquipmentSelectorDialog/MedicationEquipmentSelectorDialogParams";
import * as PermanentPrescriptionRouteDefinition from "@HunEHealthInfrastructurePlugin/UseCases/PermanentPrescriptionsUseCase/PermanentPrescriptionRoutes";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import StringProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/StringProperty";
import MedicationEquipmentPrescriptionType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationEquipmentPrescriptionType";
import moment from "moment";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import CareActivityBaseData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareActivityBaseData";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import PrescriptionItemType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/PrescriptionItemType.g";
import PrescriptionStatus from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/PrescriptionStatus.g";
import MedicationSubsidyOptionCheckType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/ReferenceData/Prescription/Enum/MedicationSubsidyOptionCheckType.g";
import PrescriptionRestrictionType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/ReferenceData/Prescription/Enum/PrescriptionRestrictionType.g";
import NoEhiCompatibleIdentifierFoundError from "@HunEHealthInfrastructurePlugin/Model/NoEhiCompatibleIdentifierFoundError";

interface IPrescriptionMasterDetailPanelDependencies {
    prescriptionApiAdapter: PrescriptionApiAdapter;
    careActivityApiAdapter: CareActivityApiAdapter2;
    structureApiAdapter: StructureApiAdapter;
    referenceDataApiAdapter: ReferenceDataApiAdapter;
    notificationService: INotificationService;
    careReferenceDataStore: CareReferenceDataStore;
    localizationService: ILocalizationService;
    hunCareReferenceDataStore: MedicationRequestReferenceDataStore;
    lockingApiAdapter: LockingApiAdapter;
    dialogService: IDialogService;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    userContext: UserContext;
    practitionerApiAdapter: PractitionerApiAdapter;
    dynamicPropertiesApiAdapter: DynamicPropertiesApiAdapter;
    diagnosisListApiAdapter: DiagnosisListApiAdapter;
    ehiUserContext: EhiUserContext; // TODO: separate HunSocialSecurity from Ehi plugin,
    pricingAndSubsidyLoader: PricingAndSubsidyLoader;
    ehiPrescriptionServiceFactory: EhiPrescriptionServiceFactory;
    searchParametersService: ISearchParametersService;
    globalRoutingStore: GlobalRoutingStore;
}

interface IPrescriptionMasterDetailPanelProps extends IRoutingFrameContentProps {
    _dependencies?: IPrescriptionMasterDetailPanelDependencies;
    _careActivityId?: CareActivityId;
    _careActivityBaseData?: CareActivityBaseData;
    _patientId?: PatientId;
    _patient?: PatientAdministrativeData;
    _modalService?: IModalService;
    _formExtension?: IFormExtension<IPrescriptionToBeCreated[]>;
    _pointOfCareId?: PointOfCareId;

    prescriptionId: PrescriptionId;
    onBack?: () => void;
    onNavigateToPrescription: (prescriptionId: PrescriptionId) => void;
    buttons?: React.ReactElement<IDefaultDetailPanelButtonsProps>;
    readonly?: boolean;
    isCompact?: boolean;
    showDetailCloseButton?: boolean;
    defaultPrescriptionStatuses?: PrescriptionStatus[];
    hideUpdateDateWarning?: boolean;
    careActivityStartedAt?: moment.Moment;
    careActivityDischargedAt?: moment.Moment;
}

/** @screen */
@State.observer
class PrescriptionMasterDetailPanel extends React.Component<IPrescriptionMasterDetailPanelProps> {
    // #region deps
    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get hunReferenceDataStore() { return this.props._dependencies.hunCareReferenceDataStore; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get lockingApiAdapter() { return this.props._dependencies.lockingApiAdapter; }
    private get prescriptionApiAdapter() { return this.props._dependencies.prescriptionApiAdapter; }
    private get careActivityApiAdapter() { return this.props._dependencies.careActivityApiAdapter; }
    private get diagnosisListApiAdapter() { return this.props._dependencies.diagnosisListApiAdapter; }
    private get structureApiAdapter() { return this.props._dependencies.structureApiAdapter; }
    private get dialogService() { return this.props._dependencies!.dialogService; }
    private get modalService() { return this.props._modalService; }
    private get organizationReferenceDataStore() { return this.props._dependencies.organizationReferenceDataStore; }
    private get userContext() { return this.props._dependencies.userContext; }
    private get referenceDataApiAdapter() { return this.props._dependencies.referenceDataApiAdapter; }
    private get ehiUserContext() { return this.props._dependencies.ehiUserContext; } // TODO: separate HunSocialSecurity from Ehi plugin
    private get dynamicPropertiesApiAdapter() { return this.props._dependencies.dynamicPropertiesApiAdapter; }
    private get practitionerApiAdapter() { return this.props._dependencies.practitionerApiAdapter; }
    private get pricingAndSubsidyLoader() { return this.props._dependencies.pricingAndSubsidyLoader; }
    private get ehiPrescriptionServiceFactory() { return this.props._dependencies.ehiPrescriptionServiceFactory; }
    // #endregion

    // #region consts
    private dayString = "nap";
    // #region

    @State.observable private isLoading: boolean = false;
    @State.observable.ref private prescriptionList: PagedItemStore<IPrescriptionListItem> = new PagedItemStore([], 0);
    @State.observable.ref private selectedPrescription: Prescription = null;
    @State.observable.ref private medications: MedicationVersion[] = [];
    @State.observable public paging: IPagingState = { currentPage: 0, pageSize: 20 };
    @State.observable public ordering: IOrderingState = { columnId: "PrescribedOn", direction: "desc" };
    @State.observable.ref private careActivityStore: CareActivityStore = null;
    @State.observable public showFilter: boolean = false;
    @State.observable.ref private subsidyOptionCheckResults: SubsidyOptionCheckResult[] = [];
    @State.observable.ref public medicationReferenceData: MedicationPricingAndSubsidies[] = [];
    @State.observable.ref private lastSuccessfulMedicationUpdateDate: LocalDate = undefined;
    @State.observable.ref private selectedExternalPrescription: IExternalPrescription = null;
    @State.observable.ref private ehiErrorExtensionData: any = null;
    @State.observable.ref private isUnauthorizedAccess = false;
    @State.observable.ref private ePrescriptionNotAllowedIsoPrefixes: string[] = [];
    @State.observable.ref private prescribeWithIsoGroupAllowedIsoCodes: string[] = [];
    private extensionData: any = {};

    private filterStore = new PrescriptionFilterStore(this.debouncedLoadSync, this.setShowFilter, this.clearPrescriptionList);

    private validationOperation: "save" | "submit" = "save";

    private ehiPrescriptionService: EhiPrescriptionService = null;
    private ehiServiceCallStatus: EhiServiceCallStatus = null;
    private isInitialLoad: boolean = true;

    private noDesignatedOrganizationCodeValue: string = "-";

    @State.action.bound
    public setMedicationReferenceData(medicationReferenceData: MedicationPricingAndSubsidies[]) {
        this.medicationReferenceData = medicationReferenceData;
    }

    @State.action.bound
    private setPrescriptionList(prescriptionList: PagedItemStore<IPrescriptionListItem>) {
        this.prescriptionList = prescriptionList;
    }

    @State.action.bound
    private setEhiErrorExtensionData(store: PagedItemStore<IPrescriptionListItem>) {
        this.ehiErrorExtensionData = store?.extensionData;
    }

    @State.action.bound
    private setSelectedPrescription(newPrescription: Prescription) {
        this.selectedPrescription = newPrescription;
    }

    @State.action.bound
    private setMedications(medications: MedicationVersion[]) {
        this.medications = medications;
    }

    @State.action.bound
    public setShowFilter(showFilter: boolean) {
        this.showFilter = showFilter;
    }

    @State.action.bound
    public setSelectedCareActivity(careActivityStore: CareActivityStore) {
        this.careActivityStore = careActivityStore;
    }

    @State.action.bound
    private setSubsidyOptionCheckResults(optionCheckResults: SubsidyOptionCheckResult[]) {
        this.subsidyOptionCheckResults = optionCheckResults;
    }

    @State.action.bound
    private setSelectedExternalPrescription(externalPrescription: IExternalPrescription) {
        this.selectedExternalPrescription = externalPrescription;
    }

    @State.action.bound
    private setLastSuccessfulMedicationUpdateDate(newValue: LocalDate) {
        this.lastSuccessfulMedicationUpdateDate = newValue;
    }

    @State.action.bound
    private setEquipmentDynamicProperties(ePrescriptionNotAllowedIsoPrefixes: string, prescribeWithIsoGroupAllowed: string) {
        this.ePrescriptionNotAllowedIsoPrefixes = ePrescriptionNotAllowedIsoPrefixes?.split(",").filter(t => !isNullOrWhiteSpace(t)) || [];
        this.prescribeWithIsoGroupAllowedIsoCodes = prescribeWithIsoGroupAllowed?.split(",") || [];
    }

    @State.action.bound
    private setEquipmentListType(type: MedicationEquipmentPrescriptionType) {
        const medicationEquipmentList = this.selectedPrescription.medicationReferenceBase as MedicationEquipmentList;
        if(type === MedicationEquipmentPrescriptionType.Prescription) {
            // we iterate, so the last one will be correct, but there is a validation for the values to be consistent
            for(const referenceData of this.medicationReferenceData) { 
                if (referenceData?.pricing?.counterSignDesiredValue === true) {
                    medicationEquipmentList.setCountersignMandatory(true);
                } else if (referenceData?.pricing?.counterSignDesiredValue === false) {
                    medicationEquipmentList.setCountersignMandatory(false);
                }
            }
        } else if (type === MedicationEquipmentPrescriptionType.Repair) {
            medicationEquipmentList.setCountersignMandatory(false);
        }
        medicationEquipmentList.setType(type);
    }

    @State.computed
    private get isCareActivityClosed() {
        if (isNullOrUndefined(this.careActivityStore)) {
            return false;
        }

        return this.careActivityStore.state === CareActivityState.Closed;
    }

    private static getMedicationId(medicationReferenceBase: MedicationReferenceBase): IEntityVersionSelector<MedicationId> | undefined {
        if (medicationReferenceBase instanceof SingleMedicationReference) {
            return medicationReferenceBase.medicationVersionSelector;
        } else if (medicationReferenceBase instanceof MedicationEquipmentList) {
            return medicationReferenceBase.items[0]?.medicationVersionSelector;
        } else if (medicationReferenceBase instanceof MedicationFormulaList) {
            return medicationReferenceBase.medicationFormulaListItems[0]?.medicationVersionSelector;
        }
        return null;
    }

    @State.bound
    private loadExternalPrescriptionSync(identifier: string, itemType?: PrescriptionItemType) {
        dispatchAsyncErrors(this.loadExternalPrescriptionAsync(identifier, itemType), this);
    }

    @State.boundLoadingState("isLoading")
    private async loadExternalPrescriptionAsync(identifier: string, itemType?: PrescriptionItemType) {
        const extensionData = {};

        if (isNullOrWhiteSpace(identifier) || isNullOrUndefined(itemType)) {
            this.setSelectedExternalPrescription(null);
            return;
        }

        const ehiServiceCallStatus = await this.setEhiAccessDataToExtensionDataAsync(extensionData);
        this.handleEhiServiceCall(ehiServiceCallStatus);

        if (ehiServiceCallStatus === EhiServiceCallStatus.Success) {
            const externalPrescriptionStore = await this.ehiPrescriptionService.getExternalPrescriptionDataAsync(
                this.props._patientId,
                identifier,
                this.props._careActivityId,
                this.filterStore.isEmergency,
                this.filterStore.emergencyReason,
                itemType,
                extensionData);

            if (externalPrescriptionStore.ehiServiceCallStatus !== EhiServiceCallStatus.Success) {
                this.handleEhiServiceCall(externalPrescriptionStore.ehiServiceCallStatus);
            } else {
                this.setSelectedExternalPrescription(externalPrescriptionStore.result.value.value);
            }
        }
    }

    private readonly initializePanelAsync = createInitialPanelLoader(this._initializePanelAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initializePanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IPrescriptionMasterDetailPanelProps) {
        if (!ValueWrapper.equals(prevProps._careActivityId, this.props._careActivityId) || !ValueWrapper.equals(prevProps.prescriptionId, this.props.prescriptionId)) {
            this.loadSync();
        }
        if (ValueWrapper.equals(this.props.prescriptionId, null) && this.selectedPrescription !== null) {
            this.setSelectedPrescription(null);
        }

        if (this.props.careActivityStartedAt !== prevProps.careActivityStartedAt || this.props.careActivityDischargedAt !== prevProps.careActivityDischargedAt) {
            this.setDateRangeBasedOnProps();
        }
    }

    @State.bound
    private async _initializePanelAsync() {
        this.ehiPrescriptionService = this.ehiPrescriptionServiceFactory.getEhiPrescriptionService();

        await this.setDefaultFiltersAsync();
        await this.loadAsync();

        const startNewPrescription = this.props._dependencies.searchParametersService.get("startNewPrescription", null);
        if (!!startNewPrescription) {
            this.addNewPrescriptionAsync();
        }
    }

    @State.bound
    private async setDefaultFiltersAsync() {
        if (this.isCareActivityDataAvailable) {
            const careActivity = await this.careActivityApiAdapter.loadByIdAsync(this.props._careActivityId);
            if (careActivity) {
                const healthcareProfessionIds = (await this.structureApiAdapter
                    .getHealthCareProfessionIdsByOrganizationUnitIdAndIdentifierSystemIdAsync(
                        new OrganizationUnitId(careActivity.pointOfCareId.value),
                        new IdentifierSystemId(WellKnownReferenceCodes.OthInsuranceCode))).value;
                if (healthcareProfessionIds) {
                    this.filterStore.setHealthCareProfessionIds(healthcareProfessionIds);
                }
            }
        } else if (this.isPatientDataAvailable) {
            this.filterStore.setPrescriptionSearchBase(PrescriptionSearchBase.Patient);
        }

        if (this.props.defaultPrescriptionStatuses?.length > 0) {
            this.filterStore.setStatuses(this.props.defaultPrescriptionStatuses);
        }

        this.setDateRangeBasedOnProps();
    }

    @State.bound
    private setDateRangeBasedOnProps() {
        if (this.props.careActivityStartedAt) {
            const end = this.props.careActivityDischargedAt ? LocalDate.createFromMoment(this.props.careActivityDischargedAt) : LocalDate.today();
            this.filterStore.setDateRangeFilter(new LocalDateRange(LocalDate.createFromMoment(this.props.careActivityStartedAt), end));
        }
    }

    @State.bound
    private clearPrescriptionList() {
        this.setPrescriptionList(PagedItemStore.createEmpty());
    }

    @State.bound
    private setExtensionDataOrganizationUnitId(value: OrganizationUnitId) {
        this.extensionData["OrganizationUnitId"] = value;
    }

    @State.boundLoadingState("isLoading")
    private async clearOrganizationUnitIdAndLoadAsync() {
        if (!isNullOrUndefined(this.extensionData)) {
            this.setExtensionDataOrganizationUnitId(null);
        }
        await this.loadAsync();
    }

    @State.bound
    private async setEhiAccessDataToExtensionDataAsync(extensionData: any, practitionerId?: PractitionerId) {
        let serviceCallStatus = null;
        if (this.isCareActivityDataAvailable) {
            serviceCallStatus = await this.ehiPrescriptionService.setEhiAccessDataWithoutOrganizationUnitCheckToExtensionDataAsync(extensionData, EhiUserType.Doctor, this.careActivityStore.pointOfCareId, practitionerId);
            return serviceCallStatus;
        }
        serviceCallStatus = await this.ehiPrescriptionService.setEhiAccessDataToExtensionDataAsync(this.props._patientId, extensionData, EhiUserType.Doctor, practitionerId);
        return serviceCallStatus;
    }

    @State.boundLoadingState("isLoading")
    private async loadAsync() {
        try {
            // TODO separate HunSocialSecurity from Ehi plugin
            if (this.filterStore.prescriptionDataSource === PrescriptionDataSource.Ehi &&
                (!this.ehiUserContext.hasEhiId || !this.ehiUserContext.isLoggedIn)) {
                await this.props._dependencies.dialogService.ok(
                    StaticWebAppResources.Common.DialogTitle.WarningTitle,
                    StaticHunEHealthInfrastructureCareResources.EhiCareActivityList.Messages.UserIsNotLoggedIntoEhiMessage);
            } else {
                if (this.filterStore.prescriptionDataSource === PrescriptionDataSource.Ehi && (isNullOrUndefined(this.extensionData) || isNullOrUndefined(this.extensionData["OrganizationUnitId"]))) {
                    this.ehiServiceCallStatus = await this.setEhiAccessDataToExtensionDataAsync(this.extensionData, this.ehiUserContext.loggedInUserPractitionerId);
                    if (this.ehiServiceCallStatus !== EhiServiceCallStatus.Success) {
                        return;
                    }
                }
                const results = await this.prescriptionApiAdapter.getPrescriptionsAsync(
                    this.filterStore.prescriptionSearchBase === PrescriptionSearchBase.CareActivity ? this.props._careActivityId : null,
                    this.props._patientId,
                    this.filterStore.dateRangeFilter,
                    this.paging,
                    this.ordering,
                    this.filterStore.statuses,
                    this.filterStore.medicationNameFilter,
                    this.filterStore.pointOfCareIds,
                    this.filterStore.practitionerId,
                    this.filterStore.prescriptionSearchBase,
                    this.filterStore.healthCareProfessionIds,
                    this.filterStore.prescriptionDataSource,
                    this.filterStore.ehiPrescriptionSearchMode,
                    this.filterStore.ehiPrescriptionSearchStartDate,
                    this.filterStore.ehiPrescriptionSearchMonths,
                    this.filterStore.isEmergency,
                    this.filterStore.emergencyReason,
                    this.filterStore.ehiPrescriptionStatuses,
                    this.filterStore.ehiPrescriptionIdentifier,
                    null,
                    this.extensionData
                );
                if (!isNullOrEmptyString(this.filterStore.ehiPrescriptionIdentifier) &&
                    arrayIsNullOrEmpty(results.items) &&
                    this.filterStore.ehiPrescriptionSearchMode === EhiPrescriptionSearchMode.SearchByIdentifier) {
                    this.notificationService.warning(StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Messages.PrescriptionWasNotFound);
                }

                await this.loadReferenceDataAsync(results);
                this.setPrescriptionList(results);
                this.setEhiErrorExtensionData(results);

                if (this.filterStore.prescriptionDataSource === PrescriptionDataSource.Ehi) {
                    const ehiServiceCallStatus = await this.setEhiAccessDataToExtensionDataAsync(this.extensionData);
                    if (ehiServiceCallStatus !== EhiServiceCallStatus.Success) {
                        return;
                    }
                }

                if (this.isCareActivityDataAvailable) {
                    const careActivityStore = await this.careActivityApiAdapter.loadByIdAsync(this.props._careActivityId);
                    this.setSelectedCareActivity(careActivityStore);
                }

                if (!!this.props.prescriptionId) {
                    this.validationOperation = "save";
                    await this.loadPrescriptionAsync(this.props.prescriptionId);
                } else {
                    this.setSelectedPrescription(null);
                }
            }

            State.runInAction(() => {
                this.isUnauthorizedAccess = false;
            });
        } catch (err) {
            if (err instanceof ApiBusinessError && err.error instanceof UnauthorizedOperationBusinessError) {
                State.runInAction(() => {
                    this.isUnauthorizedAccess = true;
                });
            } else if (err instanceof ApiBusinessError && err.error instanceof NoEhiCompatibleIdentifierFoundError) {
                this.notificationService.error(StaticHunEHealthInfrastructureCareResources.CommonEhiMessage.NoEhiCompatibleIdentifierFoundError);
                return;
            }

            throw err;
        } finally {
            this.isInitialLoad = false;
        }
    }

    @State.bound
    private debouncedLoadSync() {
        if (this.filterStore && this.filterStore.prescriptionDataSource === PrescriptionDataSource.His) {
            this.loadSync();
        }
    }

    @State.bound
    private loadSync() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.bound
    private async loadReferenceDataAsync(list: PagedItemStore<IPrescriptionListItem>) {
        for (const prescription of list.items) {
            const medicationVersionSelectors = prescription.products
                .map(i => i.medicationVersionSelector)
                .filter(i => !isNullOrUndefined(i));

            await this.careReferenceDataStore.medications.ensureLoadedAsync(medicationVersionSelectors);
            await this.careReferenceDataStore.dosageMode.ensureLoadedAsync();
            await this.careReferenceDataStore.condition.ensureLoadedAsync([{ id: prescription.conditionId, validOn: prescription.prescribedOn || LocalDate.today() }]);
        }

        await this.hunReferenceDataStore.medicationSubsidyClaimTypes.ensureAllLoadedAsync();
        await this.organizationReferenceDataStore.healthCareProfession.ensureAllLoadedAsync();
        await this.hunReferenceDataStore.packagingUnits.ensureAllLoadedAsync();
        await this.hunReferenceDataStore.ehiPrescriptionStatuses.ensureLoadedAsync();

        const medicationUpdateData = await this.prescriptionApiAdapter.getLastSuccessfulMedicationUpdateDateAsync();
        this.setLastSuccessfulMedicationUpdateDate(medicationUpdateData.value);
        
        if (this.props._pointOfCareId) { // we can prescribe only if there is a point of care defined, so no harm not loading them if there isn't
            const prescribeWithIsoGroupAllowed = await this.dynamicPropertiesApiAdapter.getPropertyValueByOrganizationUnitIdAsync(new OrganizationUnitId(this.props._pointOfCareId.value), "MedicationRequestValidationProperties", "PrescribeWithIsoGroupAllowedIsoCodes");
            const ePrescriptionNotAllowedIsoPrefixes = await this.dynamicPropertiesApiAdapter.getPropertyValueByOrganizationUnitIdAsync(new OrganizationUnitId(this.props._pointOfCareId.value), "MedicationRequestValidationProperties", "EPrescriptionNotAllowedIsoPrefixes");
            this.setEquipmentDynamicProperties(
                (ePrescriptionNotAllowedIsoPrefixes as StringProperty)?.getEffectiveValue(),
                (prescribeWithIsoGroupAllowed as StringProperty)?.getEffectiveValue()
            );
        }
    }

    private get documents() { return this.props._patient?.patientDocuments; }

    @State.computed private get isCareActivityDataAvailable() {
        return !!this.props._careActivityId;
    }

    @State.computed private get isPatientDataAvailable() {
        return !!this.props._patientId;
    }

    @State.computed private get referenceDate() {
        if (this.selectedPrescription?.usableFrom && !this.selectedPrescription.usableFrom.isEmpty()) {
            return this.selectedPrescription.usableFrom;
        }
        if (!this.isCareActivityDataAvailable) {
            return LocalDate.createFromMoment(DateTimeService.now());
        }
        const wentUnderCareAt = this.props._careActivityBaseData?.wentUnderCareAt;
        return !isNullOrUndefined(wentUnderCareAt) ? LocalDate.createFromMoment(wentUnderCareAt) : LocalDate.createFromMoment(DateTimeService.now());
    }

    @State.computed private get curativeSupplyPassValidityReferenceDate() {
        if (this.selectedPrescription?.prescribedOn) {
            return this.selectedPrescription.prescribedOn;
        }
        return null;
    }

    @State.computed private get patientHasValidCurativeSupplyPass() {
        return this.documents?.some(d => d.identifierSystemId.value === IdentifierSystemId.CurativeSupplyPass.value && d.isDocumentValidAtTime(this.curativeSupplyPassValidityReferenceDate));
    }

    @State.action.bound
    private async addNewPrescriptionAsync() {

        const dialogResult = await this.props._modalService.showDialogAsync<IMedicationSelectorDialogResult>(new MedicationSelectorDialogParams([], this.props._careActivityId, this.referenceDate));

        if (!dialogResult) {
            return;
        }
        const medicationAndClaimTypeIds = dialogResult.medicationAndClaimTypeIds;
        const medicationVersionSelectors = medicationAndClaimTypeIds.map(i => ({ id: i.medicationId, validOn: LocalDate.today() }));

        await this.loadMedicationsAsync(medicationVersionSelectors);

        let createdPrescriptionFromPermanent = null;
        if (dialogResult.permanentPrescriptionIds.length > 0) {
            createdPrescriptionFromPermanent = await this.prescriptionApiAdapter.createPrescriptionsBasedOnAllPermanentPrescriptionsAsync(this.props._careActivityId, dialogResult.permanentPrescriptionIds);
        }

        const prescriptionsToBeCreated: IPrescriptionToBeCreated[] = [];
        for (const medicationAndClaimTypeId of medicationAndClaimTypeIds) {
            const medicationVersionSelector = { id: medicationAndClaimTypeId.medicationId, validOn: LocalDate.today() };
            const medication = this.careReferenceDataStore.medications.get(medicationVersionSelector, true);
            const pricingResponse = await this.referenceDataApiAdapter.getMedicationPricingAndSubsidiesAsync(medication.id, medicationVersionSelector.validOn);
            const isNullOrUndefinedsubstanceBasedRestrictionId = isNullOrUndefined(pricingResponse.value.pricing.substanceBasedRestrictionId);
            if (ValueWrapper.equals(medication?.medicationTypeId, MedicationTypeId.S)) {
                const medicationEquipmentList = new MedicationEquipmentList();
                const medicationEquipmentListItem = this.createMedicationEquipmentListItem(medicationAndClaimTypeId.claimTypeId, medication.id, medicationEquipmentList);
                if (medication?.validity.includes(LocalDate.today()) !== true) {
                    medicationEquipmentList.setType(MedicationEquipmentPrescriptionType.Repair);
                } else if (medication?.textAmount === this.dayString) {
                    medicationEquipmentList.setType(MedicationEquipmentPrescriptionType.Lend);
                }

                medicationEquipmentList.items.push(medicationEquipmentListItem);

                if (pricingResponse.value?.pricing?.counterSignDesiredValue === true) {
                    medicationEquipmentList.setCountersignMandatory(true);
                } else if (pricingResponse.value?.pricing?.counterSignDesiredValue === false) {
                    medicationEquipmentList.setCountersignMandatory(false);
                }
                prescriptionsToBeCreated.push({ reference: medicationEquipmentList, claimTypeId: medicationAndClaimTypeId.claimTypeId, pointOfCareId: this.careActivityStore.pointOfCareId });
            } else {
                const singleMedicationReference = new SingleMedicationReference();
                let packagingUnitId;
                if (isNullOrUndefinedsubstanceBasedRestrictionId) {
                    packagingUnitId = this.hunReferenceDataStore.packagingUnits.items.find(i => i.latinName === "Scat. Orig. No.")?.id;
                } else {
                    if (isNullOrUndefined(medication.dosageModeId?.value)) {
                        packagingUnitId = this.hunReferenceDataStore.packagingUnits.items.find(i => i.name === DosageModeId.tabletta.value)?.id;
                    } else if (medication.dosageModeId?.value === DosageModeId.tabletta.value || medication.dosageModeId?.value === DosageModeId.kapszula.value) {
                        packagingUnitId = this.hunReferenceDataStore.packagingUnits.items.find(i => i.name === medication.dosageModeId.value)?.id;
                    }
                }
                singleMedicationReference.medicationVersionSelector = medicationVersionSelector;
                singleMedicationReference.dosage = new FrequencyBasedDosage();
                singleMedicationReference.amount = isNullOrUndefinedsubstanceBasedRestrictionId ? 0 : medication.amountInPackage;
                singleMedicationReference.dosage.setDosageMode(medication?.dosageModeId?.value);
                singleMedicationReference.isDaysSuppliedFilledManually = !medication?.daysSuppliedCanBeCalculated;
                singleMedicationReference.packagingUnitId = packagingUnitId;
                prescriptionsToBeCreated.push({ reference: singleMedicationReference, claimTypeId: medicationAndClaimTypeId.claimTypeId, pointOfCareId: this.careActivityStore.pointOfCareId });
            }

        }

        for (const templateId of dialogResult?.templateIds ?? []) {
            const template = await this.prescriptionApiAdapter.getTextualPrescriptionTemplateByIdAsync(templateId);

            const textualPrescription = new TextualPrescription();
            const packagingUnitId = this.hunReferenceDataStore.packagingUnits.items.find(i => i.latinName === "Dos. No.")?.id;

            textualPrescription.amount = 0;
            textualPrescription.content = template.content;
            textualPrescription.preparation = template.preparation;
            textualPrescription.displayName = template.displayName;
            textualPrescription.name = template.name;
            textualPrescription.dosage = new TextualDosage();
            textualPrescription.textualPrescriptionTemplateId = templateId;
            textualPrescription.type = template.type;
            textualPrescription.packagingUnitId = packagingUnitId;

            prescriptionsToBeCreated.push({ reference: textualPrescription, claimTypeId: new MedicationSubsidyClaimTypeId("1"), pointOfCareId: this.careActivityStore.pointOfCareId });
        }
        for (const medicalFormula of dialogResult?.medicalFormulas ?? []) {
            const medicalFormulaList = new MedicationFormulaList();
            medicalFormulaList.medicationFormulaListItems.push(new MedicationFormulaListItem());
            medicalFormulaList.dosage = new TextualDosage();
            const packagingUnitId = this.hunReferenceDataStore.packagingUnits.items.find(i => i.latinName === "Dos. No.")?.id;
            medicalFormulaList.packagingUnitId = packagingUnitId;
            medicalFormulaList.name = medicalFormula.name;

            prescriptionsToBeCreated.push({ reference: medicalFormulaList, claimTypeId: medicalFormula.claimTypeId, pointOfCareId: this.careActivityStore.pointOfCareId });
        }

        let prescription = null;
        if (prescriptionsToBeCreated.length > 0) {
            const doctorId = this.props._careActivityBaseData.primaryParticipant;
            prescriptionsToBeCreated.forEach(e => {
                e.prescriber = doctorId;
            });
            await this.props._formExtension.fireEventAsync("NewPrescriptionCreating", prescriptionsToBeCreated);
            prescription = await this.prescriptionApiAdapter.createPrescriptionsAsync(prescriptionsToBeCreated, this.props._careActivityId, false, false);
        }
        const prescriptionIdToSelect = prescription?.id || createdPrescriptionFromPermanent?.id;
        if (prescriptionIdToSelect) {
            this.filterStore.resetFilters();
            await this.setDefaultFiltersAsync();
            this.props.onNavigateToPrescription(prescriptionIdToSelect);
        }
    }

    @State.bound
    private async loadMedicationsAsync(versionSelectors: Array<IEntityVersionSelector<MedicationId>>) {
        if (versionSelectors && versionSelectors.length > 0 && !isNullOrUndefined(versionSelectors[0])) {
            await this.careReferenceDataStore.medications.ensureLoadedAsync(versionSelectors);
            const medications = versionSelectors.map(i => this.careReferenceDataStore.medications.get(i, true));
            this.setMedications(medications);
            const referenceData = [];
            for (const versionSelector of versionSelectors) {
                const pricingResponse = await this.referenceDataApiAdapter.getMedicationPricingAndSubsidiesAsync(versionSelector.id, versionSelector.validOn);
                await this.pricingAndSubsidyLoader.loadReferenceDataAsync(pricingResponse.value, versionSelector.validOn);
                referenceData.push(pricingResponse.value);
            }
            this.setMedicationReferenceData(referenceData);
        } else {
            this.setMedications([]);
            this.setMedicationReferenceData([]);
        }

    }

    @State.action.bound
    private async saveAndNavigateIfNecessaryAsync(releaseLock = false) {
        const wasNew = this.selectedPrescription.isNew;
        const responsePrescription = await this.saveAsync(releaseLock);

        this.notificationService.showSaveResult(responsePrescription.isPersistedByOperationInfo, responsePrescription.hasValidationWarning);

        if (wasNew && responsePrescription.isPersistedByOperationInfo) {
            this.props.onNavigateToPrescription(responsePrescription.id);
        }
    }

    @State.action.bound
    private async saveAsync(releaseLock = false, isValidateOnly = false) {
        let responsePrescription: Prescription;

        if (this.selectedPrescription.isNew) {
            responsePrescription = await this.prescriptionApiAdapter.createPrescriptionAsync(this.selectedPrescription, !releaseLock, isValidateOnly);
        } else {
            responsePrescription = await this.prescriptionApiAdapter.updatePrescriptionAsync(this.selectedPrescription, releaseLock, isValidateOnly);
        }

        if (!isValidateOnly) {
            this.setSelectedPrescription(responsePrescription);
            responsePrescription.takeSnapshot();
        }

        return responsePrescription;
    }

    @State.bound
    private async forceReleaseLockAsync() {
        if (this.selectedPrescription?.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockNotHeld) {
            await this.lockingApiAdapter.forceReleaseLockAsync(this.selectedPrescription.lockInfo.preventingLockId);
        }
    }

    @State.action.bound
    private async releaseLockAsync() {
        if (this.selectedPrescription?.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld) {
            await this.lockingApiAdapter.releaseLockAsync(this.selectedPrescription.lockInfo.lockId);
            this.selectedPrescription.releaseLock();
        }
    }

    @State.bound
    private async loadPrescriptionAsync(prescriptionId: PrescriptionId) {
        const readonlyResult = await this.prescriptionApiAdapter.getPrescriptionByIdAsync(prescriptionId, false);

        const shouldRequestLock = ValueWrapper.equals(readonlyResult.careActivityId, this.props._careActivityId);
        const result = await this.prescriptionApiAdapter.getPrescriptionByIdAsync(prescriptionId, shouldRequestLock);

        const medicationIds = [];
        if (result.medicationReferenceBase instanceof SingleMedicationReference) {
            medicationIds.push(result.medicationReferenceBase.medicationVersionSelector);
        } else if (result.medicationReferenceBase instanceof MedicationEquipmentList) {
            medicationIds.push(...result.medicationReferenceBase.items?.map(i => i.medicationVersionSelector) ?? []);
        }

        await this.loadMedicationsAsync(medicationIds);
        result.takeSnapshot();
        this.setSelectedPrescription(result);
    }

    @State.computed
    private get getManuallyFilledDaysSupplied() {
        if (this.selectedPrescription?.medicationReferenceBase instanceof SingleMedicationReference) {
            return this.selectedPrescription.medicationReferenceBase.daysSupplied;
        } else {
            return null;
        }
    }

    @State.computed
    private get medicationOfSingleMedicationReference() {
        if (this.selectedPrescription?.medicationReferenceBase instanceof SingleMedicationReference) {
            return this.medications.find(i => ValueWrapper.equals(i.id, (this.selectedPrescription.medicationReferenceBase as SingleMedicationReference).medicationVersionSelector.id));
        } else {
            return null;
        }
    }

    @State.computed
    private get medicationReferenceDataOfSingleMedicationReference() {
        if (this.selectedPrescription?.medicationReferenceBase instanceof SingleMedicationReference) {
            return this.medicationReferenceData.find(i => ValueWrapper.equals(i?.pricing?.medicationId, (this.selectedPrescription.medicationReferenceBase as SingleMedicationReference).medicationVersionSelector.id));
        } else {
            return null;
        }
    }

    @State.computed
    private get getCalculatedDaysSupplied() {
        if (this.medicationOfSingleMedicationReference?.daysSuppliedCanBeCalculated === true) {
            return this.getDaysSuppliedAsync.get();
        }
        return null;
    }

    private getDaysSuppliedAsync = State.asyncComputed(null, 1000, async () => {

        if (this.selectedPrescription?.medicationReferenceBase instanceof SingleMedicationReference) {
            const result = await this.prescriptionApiAdapter.getDaysSuppliedQueryAsync(
                this.selectedPrescription.medicationReferenceBase.amount,
                this.selectedPrescription.medicationReferenceBase.dosage,
                this.medicationOfSingleMedicationReference.dosageAmount,
                this.selectedPrescription.medicationReferenceBase.isUnpackaged,
                this.medicationReferenceDataOfSingleMedicationReference?.pricing?.substanceBasedRestrictionId);
            return result.value;
        } else {
            return null;
        }
    });

    @State.bound
    private selectRow(row: IPrescriptionListItem) {
        if (this.filterStore.prescriptionDataSource === PrescriptionDataSource.His) {
            this.props.onNavigateToPrescription(row?.id?.value ? new PrescriptionId(row?.id?.value) : null);
        } else {
            this.loadExternalPrescriptionSync(row?.extensionData?.EhiIdentifier, row?.itemType);
        }
    }

    @State.bound
    private async onEditClickedAsync() {
        if (this.selectedPrescription.id) {
            await this.forceReleaseLockAsync();
            await this.loadPrescriptionAsync(this.selectedPrescription.id);
        }
    }

    @State.bound
    private async printPrescriptionAsync() {
        if (this.selectedPrescription.id) {
            await this.modalService.showModalAsync(new PrintPrescriptionDialogParams(this.selectedPrescription.id));
        }
    }

    @State.bound
    private async onSubmitClickedAsync() {

        const { triedAdding, operationSuccess } = await this.addDiagnosisIfNecessaryAsync();

        if (triedAdding && operationSuccess === false) {
            await this.dialogService.ok(StaticWebAppResources.Common.Label.Error, StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.PrescriptionDiagnosisCouldNotBeAddedMessage);
            return;
        }

        if (this.subsidyClaimTypeIsEuEmOrEuKiem()) {
            const allSubsidyOptionsDesignatedWithoutOrg = this.areAllSuccesfulSubsidyOptionsDesignatedWithNoOrganizationDefined();
            const secondCondition = this.areAllSuccessfulPrescribeTypeOptionsDesignatedWithNoOrganizationDefinedAndAllOtherArePrescibeWithRecommendation();
            if (allSubsidyOptionsDesignatedWithoutOrg) {
                const dialogResult = await this.dialogService.yesNoCancel(
                    StaticWebAppResources.Common.DialogTitle.ConfirmationTitle,
                    StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.DesignatedSubsidyOptionRestrictionDialog.Text);

                if (dialogResult.resultCode === DialogResultCode.Yes) {
                    this.selectedPrescription.setApprovedDesignation(true);
                    await this.submitAsync();
                }
            } else if (secondCondition) {
                const dialogResult = await this.dialogService.yesNoCancel(
                    StaticWebAppResources.Common.DialogTitle.ConfirmationTitle,
                    StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.DesignatedAndPrescribeWithRestrictionDialog.Text);

                if (dialogResult.resultCode === DialogResultCode.Yes) {
                    this.selectedPrescription.setApprovedDesignation(true);
                    await this.submitAsync();
                } else if (dialogResult.resultCode === DialogResultCode.No) {
                    this.selectedPrescription.setApprovedDesignation(false);
                    await this.submitAsync();
                } else {
                    return;
                }
            } else {
                await this.submitAsync();
            }

        } else {
            await this.submitAsync();
        }
    }

    private async setIsEhiBasedOnBusinessErrorsAsync(prescription: Prescription) {
        const dialogResult = await this.confirmPrescriptionFormDialogAsync(prescription.extensionData?.EhiBusinessError.Value);
        if (!isNullOrUndefined(dialogResult)) {
            if (dialogResult === "digital") {
                prescription.setExtensionData("IsDigital", { Value: true });
            } else if (dialogResult === "paper") {
                prescription.setExtensionData("IsDigital", { Value: false });
                return await this.prescriptionApiAdapter.setPrescriptionStatusAsync(prescription, PrescriptionStatus.Submitted, true, false, null, null, true, this.extensionData);
            }
        }
        return prescription;
    }

    private async submitAsync() {
        this.validationOperation = "submit";

        const validationResponse = await this.submitPrescriptionAsync(this.selectedPrescription, true);

        if (validationResponse.hasValidationError) {
            this.setSelectedPrescription(validationResponse);
            validationResponse.takeSnapshot();
            this.notificationService.showSaveResult(false);
            return;
        }

        let submitResult = await this.submitPrescriptionAsync(this.selectedPrescription, false);
        if (isNullOrUndefined(submitResult)) {
            await this.onSaveClickedAsync();
            return;
        }

        this.notificationService.showSaveResult(submitResult.isPersistedByOperationInfo, submitResult.hasValidationWarning);
        // TODO: it will need a refactor because using another plugin data
        if (!isNullOrUndefined(submitResult.extensionData?.WasEhiEnabled)) {

            if (isNullOrEmptyString(submitResult.digitalPrescriptionIdentifier)
                && submitResult.status === PrescriptionStatus.Submitted
                && !arrayIsNullOrEmpty(submitResult.extensionData?.EhiError)
            ) {
                const firstError = submitResult.extensionData.EhiError[0];
                if (!firstError?.IsGatewayError) {
                    this.notificationService.error(submitResult.extensionData.EhiError[0].Message);
                } else {
                    this.notificationService.error(StaticHunEHealthInfrastructureWebAppResources.GatewayError);
                }

            } else if (!isNullOrEmptyString(submitResult.digitalPrescriptionIdentifier) &&
                submitResult.status === PrescriptionStatus.Submitted &&
                submitResult.extensionData.WasEhiEnabled
            ) {
                this.notificationService.success(StaticHunSocialSecurityMedicationRequestResources.SuccessfulSavingToEESZT);
            } else if (!isNullOrEmptyString(submitResult.extensionData.EhiBusinessError)) {
                submitResult = await this.setIsEhiBasedOnBusinessErrorsAsync(submitResult);
            }
        }
        if (submitResult.isPersistedByOperationInfo) {
            await this.loadAsync();
        } else {
            this.setSelectedPrescription(submitResult);
        }
        submitResult.takeSnapshot();
    }

    private async addDiagnosisIfNecessaryAsync(): Promise<{ triedAdding: boolean, operationSuccess: boolean }> {
        const careActivityDiagnosisList = await this.diagnosisListApiAdapter.getDiagnosisListAsync(this.selectedPrescription.careActivityId, true);

        const careActivityContainsSelectedCondition = careActivityDiagnosisList.diagnoses
            ?.some(i => i.conditionVersionSelector.id?.value === this.selectedPrescription.conditionId?.value
                && (i.use?.value === DiagnosisRoleId.Comorbity?.value || i.use?.value === DiagnosisRoleId.Discharge?.value));

        let triedAdding = false;
        let operationSuccess = false;

        if (this.selectedPrescription.conditionId && !careActivityContainsSelectedCondition) {

            const dialogResult = await this.dialogService.yesNo(StaticWebAppResources.Common.DialogTitle.ConfirmationTitle,
                StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.AddConditionToCareActivityDialog.Text);

            if (dialogResult.resultCode === DialogResultCode.Yes) {
                triedAdding = true;
                if (careActivityDiagnosisList.lockInfo?.lockState !== EntityLockState.LockingRequiredAndLockNotHeld) {
                    const newDiagnosis = new DiagnosisStore(true);
                    newDiagnosis.setCondition(this.selectedPrescription.conditionId);
                    newDiagnosis.setUse(careActivityDiagnosisList.diagnoses.length === 0 ? DiagnosisRoleId.Discharge : DiagnosisRoleId.Comorbity);
                    newDiagnosis.setLateralityId(LateralityId.NotApplicable);
                    careActivityDiagnosisList.addDiagnosis(newDiagnosis);

                    const result = await this.diagnosisListApiAdapter.updateDiagnosisListWithoutExistingLockAsync(careActivityDiagnosisList, true, true);
                    operationSuccess = result.isPersistedByOperationInfo;
                }

                if (operationSuccess && this.subsidyClaimTypeIsEuEmOrEuKiem()) {
                    const claimType = this.hunReferenceDataStore.medicationSubsidyClaimTypes.get(this.selectedPrescription.medicationRequestCoverage.claimTypeId); // loading already ensured in loadReferenceDataAsync
                    await this.reloadCheckResultsAsync(claimType.medicationSubsidizedPricingTypeId);
                }
            }
        }

        return { triedAdding: triedAdding, operationSuccess };
    }

    @State.bound
    private async reloadCheckResultsAsync(pricingType: MedicationSubsidizedPricingTypeId) {
        const optionResults = [];
        for (const medication of this.medications) {
            const results = await this.referenceDataApiAdapter.checkMedicationSubsidyOptionAsync(
                medication.id,
                this.selectedPrescription.careActivityId,
                MedicationSubsidyOptionCheckType.Prescription,
                pricingType,
                this.selectedPrescription.prescriber
            );
            optionResults.push(...results.value);
        }
        this.setSubsidyOptionCheckResults(optionResults);
    }

    private async confirmPrescriptionFormDialogAsync(digitalPrescriptionError?: string) {
        const dialogResult = await this.props._modalService.showDialogAsync<IPrescriptionToEhiDialogResult>(new PrescriptionToEhiDialogParams(digitalPrescriptionError));
        if (!isNullOrUndefined(dialogResult)) {
            return dialogResult;
        }
        return null;
    }

    private subsidyClaimTypeIsEuEmOrEuKiem(): boolean {
        const claimTypeId = this.selectedPrescription?.medicationRequestCoverage?.claimTypeId;

        if (!isNullOrUndefined(claimTypeId)) {
            const claimType = this.hunReferenceDataStore.medicationSubsidyClaimTypes.get(claimTypeId); // loading already ensured in loadReferenceDataAsync
            return ValueWrapper.equals(claimType.medicationSubsidizedPricingTypeId, MedicationSubsidizedPricingTypeId.EE)
                || ValueWrapper.equals(claimType.medicationSubsidizedPricingTypeId, MedicationSubsidizedPricingTypeId.EK);
        }

        return false;
    }

    @State.bound
    private isClaimTypeTemporaryPermission(claimTypeId: MedicationSubsidyClaimTypeId) {
        if (!claimTypeId) {
            return false;
        } else {
            if (claimTypeId) {
                const claimType = this.hunReferenceDataStore.medicationSubsidyClaimTypes.get(claimTypeId);
                return claimType?.code === MedicationSubsidyClaimTypeId.ET.value;
            }
    
            return false;
        }
    }

    @State.computed
    private get subsidyClaimTypeIsTemporaryPermission(): boolean {
        return this.isClaimTypeTemporaryPermission(this.selectedPrescription?.medicationRequestCoverage?.claimTypeId);
    }

    @State.bound
    private areAllSuccesfulSubsidyOptionsDesignatedWithNoOrganizationDefined(): boolean {
        const claimTypeId = this.selectedPrescription?.medicationRequestCoverage?.claimTypeId;

        if (!isNullOrUndefined(claimTypeId)) {
            const claimType = this.hunReferenceDataStore.medicationSubsidyClaimTypes.get(claimTypeId); // loading already ensured in loadReferenceDataAsync
            const successfulOptionCheckResults = this.getSuccessfulOptionCheckResults(claimType);
            const designatedOrganizationsByRestrictionIdsMap = this.getDesignatedOrganizationsByRestrictionIdsMap();

            return successfulOptionCheckResults.length > 0 && successfulOptionCheckResults.every(i => {

                const successfulRestrictionResults = i.optionRestrictionResults?.filter(j => j?.successful === true);

                return successfulRestrictionResults?.length > 0
                    && successfulRestrictionResults.every(j => j?.restrictionIsDesignated
                        && designatedOrganizationsByRestrictionIdsMap.get(j.subsidyOptionRestrictionId.value).instituteCode === this.noDesignatedOrganizationCodeValue);
            });
        }
        return false;
    }

    @State.bound // it's a mouthful but I couldn't shorten it
    private areAllSuccessfulPrescribeTypeOptionsDesignatedWithNoOrganizationDefinedAndAllOtherArePrescibeWithRecommendation() {
        const claimTypeId = this.selectedPrescription?.medicationRequestCoverage?.claimTypeId;

        if (!isNullOrUndefined(claimTypeId)) {
            const claimType = this.hunReferenceDataStore.medicationSubsidyClaimTypes.get(claimTypeId); // loading already ensured in loadReferenceDataAsync
            const successfulOptionCheckResults = this.getSuccessfulOptionCheckResults(claimType);

            const successfulOptionsRestrictionsWithCheckResults = this.getSuccessfulOptionsAndRestrictionsWithCheckResults(successfulOptionCheckResults);

            const prescribeTypeAndDesignatedResults = successfulOptionsRestrictionsWithCheckResults
                .filter(i => (i.restriction.prescriptionRestrictionType === PrescriptionRestrictionType.Prescribe
                    || i.restriction.prescriptionRestrictionType === PrescriptionRestrictionType.PrescribeAndRecommend)
                    && i.restrictionCheckResult.restrictionIsDesignated
                    && i.restriction.designatedOrganizationId
                    && this.hunReferenceDataStore.designatedOrganizations.get({
                        id: i.restriction.designatedOrganizationId,
                        validOn: this.selectedPrescription.prescribedOn || LocalDate.today()
                    }).instituteCode === this.noDesignatedOrganizationCodeValue);

            const prescribeWithRecommendationResults = successfulOptionsRestrictionsWithCheckResults
                .filter(i => i.restriction.prescriptionRestrictionType === PrescriptionRestrictionType.PrescribeWithRecommendation);

            if (prescribeTypeAndDesignatedResults.length > 0
                && prescribeWithRecommendationResults.length > 0
                && successfulOptionsRestrictionsWithCheckResults.every(i =>
                    prescribeTypeAndDesignatedResults.includes(i)
                    || prescribeWithRecommendationResults.includes(i))
            ) {
                const recommendationDataFilled = !isNullOrUndefined(this.selectedPrescription.prescriptionPractitionerRecommendation.practitionerId)
                    && this.selectedPrescription.prescriptionPractitionerRecommendation.issuedOn?.isEmpty() === false;
                return !recommendationDataFilled; // if it's filled, don't throw error
            }
        }
        return false;
    }

    @State.bound
    private getSuccessfulOptionsAndRestrictionsWithCheckResults(optionsCheckResults: SubsidyOptionCheckResult[]) {
        const successfulRestrictionsWithReferenceData = [];
        for (const checkResult of optionsCheckResults) {
            const successfulRestrictions = checkResult.optionRestrictionResults.filter(i => i.successful);

            const option = _.flatten(this.medicationReferenceData.map(i => i.subsidies))
                .find(i => ValueWrapper.equals(i.id, checkResult.medicationSubsidyId))
                ?.options
                ?.find(i => ValueWrapper.equals(i.id, checkResult.medicationSubsidyOptionId));

            for (const successfulRestriction of successfulRestrictions) {

                const restriction = option
                    ?.optionRestrictions
                    ?.find(i => ValueWrapper.equals(i.id, successfulRestriction.subsidyOptionRestrictionId));

                successfulRestrictionsWithReferenceData.push({
                    optionCheckResult: checkResult,
                    restrictionCheckResult: successfulRestriction,
                    restriction: restriction,
                    option: option
                });
            }
        }
        return successfulRestrictionsWithReferenceData;
    }

    @State.bound
    private getSuccessfulOptionCheckResults(claimType: IMedicationSubsidyClaimType) {
        return this.subsidyOptionCheckResults.filter(i =>
            i?.successful === true
            && ValueWrapper.equals(_.flatten(this.medicationReferenceData.map(j => j.subsidies)).find(j => ValueWrapper.equals(i.medicationSubsidyId, j.id))?.pricingType,
                claimType.medicationSubsidizedPricingTypeId)
        );
    }

    @State.bound
    private getDesignatedOrganizationsByRestrictionIdsMap() {
        const subsidyOptions = _.flatten(_.flatten(this.medicationReferenceData.map(j => j.subsidies)).map(i => i.options));
        const optionRestrictions = _.flatten(subsidyOptions.map(i => i.optionRestrictions));

        const designatedOrganizationsByRestrictionIdsMap = new Map<string, DesignatedOrganization>();
        for (const optionRestriction of optionRestrictions) {
            const designatedOrganization = this.hunReferenceDataStore.designatedOrganizations.get({ id: optionRestriction.designatedOrganizationId, validOn: LocalDate.today() });
            designatedOrganizationsByRestrictionIdsMap.set(optionRestriction.id.value, designatedOrganization);
        }
        return designatedOrganizationsByRestrictionIdsMap;
    }

    private readonly ehiIdentifierSystemId = new IdentifierSystemId("HunEHealthInfrastructure");
    private getEhiIdentifier(identifiers: PractitionerIdentifierStore[]) {
        return identifiers && identifiers.find(ia => ValueWrapper.equals(this.ehiIdentifierSystemId, ia.identifier.identifierSystemId));
    }

    @State.bound
    private async submitPrescriptionAsync(prescription: Prescription, isValidateOnly = false) {

        if (!!isValidateOnly) {
            return await this.prescriptionApiAdapter.setPrescriptionStatusAsync(prescription, PrescriptionStatus.Submitted, false, true, null, null, true, this.extensionData);
        }

        const careActivity = await this.careActivityApiAdapter.loadByIdAsync(this.props._careActivityId);
        const isEventCatalogEnabledPropertyValueResponse = await this.dynamicPropertiesApiAdapter.getPropertyValueByOrganizationUnitIdAsync(new OrganizationUnitId(careActivity.pointOfCareId.value), "HunEHealthInfrastructureSettings", "IsEventCatalogEnabled");
        const ambulancyLicenceIdentifierPropertyValueResponse = await this.dynamicPropertiesApiAdapter.getPropertyValueByOrganizationUnitIdAsync(new OrganizationUnitId(careActivity.pointOfCareId.value), "HunEHealthInfrastructureSettings", "AmbulancyLicenceIdentifier");

        const wasEhiEnabled = isEventCatalogEnabledPropertyValueResponse.getEffectiveValue();
        const ambulancyLicenceIdentifier = ambulancyLicenceIdentifierPropertyValueResponse.getEffectiveValue();

        if (wasEhiEnabled && !ambulancyLicenceIdentifier) {
            this.notificationService.warning(StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.NoAmbulancyLicenceIdentifierWarning);
        }

        const prescriptionIsMedicationEquipmentList = prescription.medicationReferenceBase instanceof MedicationEquipmentList;
        const prescriptionIsTextual = prescription.medicationReferenceBase instanceof TextualPrescription;
        const prescriptionIsNotMedicationEquipmentListAndNotTextual = !prescriptionIsMedicationEquipmentList && !prescriptionIsTextual;

        const prescriptionIsTextualNotOther = prescription.medicationReferenceBase instanceof TextualPrescription
            && prescription.medicationReferenceBase.type !== PrescriptionTemplateType.Other;

        const practitionerIds = await this.practitionerApiAdapter.getIdentifiersOfPractitionerAsync(prescription.prescriber);
        const prescriberEhiIdentifier = this.getEhiIdentifier(practitionerIds.value);
        const prescriber = await this.organizationReferenceDataStore.doctorMap.getOrLoadAsync(prescription.prescriber);

        let ehiLoginAttemptResult: DialogResultCode = DialogResultCode.No;
        let ehiLoginServiceCallStatus: EhiServiceCallStatus = null;

        const shouldAttemptLogin = wasEhiEnabled
            && ambulancyLicenceIdentifier
            && (prescriptionIsMedicationEquipmentList || prescriptionIsTextualNotOther || prescriptionIsNotMedicationEquipmentListAndNotTextual);

        if (shouldAttemptLogin) {
            if (isNullOrUndefined(prescriberEhiIdentifier)) {
                this.notificationService.warning(StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.PrescriberHasNoEhiIdentifierWarning);
            } else {
                while (ehiLoginAttemptResult === DialogResultCode.No) {
                    const { ehiServiceCallStatus, ehiLoginDialogResultCode } = await this.tryLoginAsync(prescriber);
                    ehiLoginAttemptResult = ehiLoginDialogResultCode;
                    ehiLoginServiceCallStatus = ehiServiceCallStatus;
                }
                if (ehiLoginAttemptResult === DialogResultCode.Cancel) {
                    return null;
                }
            }
        }

        if (prescription?.id) {
            const isDigital = this.prescriptionIsDigital(
                wasEhiEnabled,
                ambulancyLicenceIdentifier,
                prescriptionIsMedicationEquipmentList,
                prescriberEhiIdentifier,
                ehiLoginAttemptResult,
                ehiLoginServiceCallStatus,
                shouldAttemptLogin);

            if (!isNullOrUndefined(isDigital)) {
                prescription.setExtensionData("IsDigital", { value: isDigital });
            }

            return await this.prescriptionApiAdapter.setPrescriptionStatusAsync(prescription, PrescriptionStatus.Submitted, true, false, null, null, true, this.extensionData);
        }
        return null;
    }

    private prescriptionIsDigital(
        wasEhiEnabled: string | boolean,
        ambulancyLicenceIdentifier: string | boolean,
        prescriptionIsMedicationEquipmentList: boolean,
        prescriberEhiIdentifier: PractitionerIdentifierStore,
        ehiLoginAttemptResult: DialogResultCode,
        ehiLoginServiceCallStatus: EhiServiceCallStatus,
        shouldSendToEhi: boolean
    ): boolean {

        if (!wasEhiEnabled) {
            return false;
        }

        if (!ambulancyLicenceIdentifier) {
            return false;
        }

        if (shouldSendToEhi) {
            if (isNullOrUndefined(prescriberEhiIdentifier)) {
                return false;
            }

            if (ehiLoginServiceCallStatus === EhiServiceCallStatus.NoEhiToken && ehiLoginAttemptResult === DialogResultCode.Yes) {
                return false;
            }
        }

        const hasDisallowedIsoCodes = this.medications
            .map(medication => medication.isoCode)
            .filter(isoCode => !isNullOrUndefined(isoCode))
            .some(isoCode => this.ePrescriptionNotAllowedIsoPrefixes.some(prefix => isoCode.startsWith(prefix)));
        if (hasDisallowedIsoCodes) {
            return false;
        }

        if (prescriptionIsMedicationEquipmentList) {
            return true;
        }

        return null;
    }

    @State.bound
    private async tryLoginAsync(prescriber: IDoctor): Promise<{ ehiServiceCallStatus: EhiServiceCallStatus, ehiLoginDialogResultCode: DialogResultCode }> {
        this.extensionData = {};
        const ehiServiceCallStatus = await this.setEhiAccessDataToExtensionDataAsync(this.extensionData, prescriber.id);
        switch (ehiServiceCallStatus) {
            case EhiServiceCallStatus.Success:
                return { ehiServiceCallStatus, ehiLoginDialogResultCode: DialogResultCode.Yes };
            case EhiServiceCallStatus.NoEhiToken:
                const dialogResult = await this.showPaperPrescriptionConfirmationDialogAsync();
                if (dialogResult.resultCode === DialogResultCode.Yes) {
                    this.extensionData = null;
                }
                return { ehiServiceCallStatus, ehiLoginDialogResultCode: dialogResult.resultCode };
            default:
                break;
        }
        return { ehiServiceCallStatus, ehiLoginDialogResultCode: DialogResultCode.No };
    }

    @State.bound
    private async showPaperPrescriptionConfirmationDialogAsync() {
        const dialogResult = await this.dialogService.yesNoCancel(
            StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.PaperPrescriptionConfirmationDialog.Title,
            StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.PaperPrescriptionConfirmationDialog.Message);
        return dialogResult;
    }

    @State.bound
    private async cancelSubmissionAsync() {
        let response: Prescription = null;
        if (this.selectedPrescription.id) {
            if (!isNullOrUndefined(this.selectedPrescription.digitalPrescriptionIdentifier)) {
                response = await this.deleteExternalPrescriptionAsync(PrescriptionStatus.UnderRecording);
            } else {
                response = await this.prescriptionApiAdapter.setPrescriptionStatusAsync(this.selectedPrescription, PrescriptionStatus.UnderRecording, false);
            }
            if (!isNullOrUndefined(response)) {
                if (response.status === PrescriptionStatus.UnderRecording || response.status === PrescriptionStatus.WaitingForSubmissionCancellation) {
                    this.notificationService.showSaveResult(response.isPersistedByOperationInfo, response.hasValidationWarning);
                }

                if (response.isPersistedByOperationInfo) {
                    await this.loadAsync();
                }
            }
        }
    }

    @State.bound
    private async savePrescriptionAsPermanentAsync() {
        const medicationSelector = PrescriptionMasterDetailPanel.getMedicationId(this.selectedPrescription.medicationReferenceBase);
        if (medicationSelector) {
            const medicationVersion = await this.careReferenceDataStore.medications.getOrLoadAsync(medicationSelector);
            if (medicationVersion && medicationVersion.identifiers.length > 0) {
                const matchingCountStore = await this.prescriptionApiAdapter.getPermanentPrescriptionCountByIdentifierAsync(medicationVersion.identifiers[0], this.props._patientId);
                if (matchingCountStore && matchingCountStore.value > 0) {
                    const dialogResult = await this.dialogService.yesNo(StaticWebAppResources.Common.DialogTitle.ConfirmationTitle,
                        StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.SavePermanentPrescriptionDialog.Text);
                    if (dialogResult.resultCode !== DialogResultCode.Yes) {
                        return;
                    }
                }
            }
        }

        const permanentPrescription = await this.prescriptionApiAdapter.createPermanentPrescriptionAsync(this.selectedPrescription.id, this.patientId);
        if (!isNullOrUndefined(permanentPrescription.id)) {
            this.notificationService.success(StaticHunSocialSecurityMedicationRequestResources.CreatedPermanentPrescriptionSuccessfully);
        } else {
            this.notificationService.error(permanentPrescription.operationInfo.businessError.name);
        }
    }

    @State.bound
    private async deleteExternalPrescriptionAsync(status: PrescriptionStatus) {
        let response: Prescription = null;
        const extensionData = {};
        const doctor = await this.organizationReferenceDataStore.doctorMap.getOrLoadAsync(this.userContext.practitionerId);
        if (!isNullOrUndefined(this.selectedPrescription.digitalPrescriptionIdentifier)) {
            const prescriber = await this.organizationReferenceDataStore.doctorMap.getOrLoadAsync(this.selectedPrescription.prescriber);
            const ehiServiceCallStatus = await this.setEhiAccessDataToExtensionDataAsync(extensionData, prescriber.id);

            if (ehiServiceCallStatus === EhiServiceCallStatus.NoEhiToken) {
                this.notificationService.error(StaticHunSocialSecurityMedicationRequestResources.PrescriptionCanBeDeletedByPrescriberOnly);
            } else {
                this.handleEhiServiceCall(ehiServiceCallStatus);
            }

            if (ehiServiceCallStatus !== EhiServiceCallStatus.Success) {
                return null;
            }

            if ((!isNullOrUndefined(extensionData) && !isNullOrUndefined(doctor))) {

                const dialogResult = await this.props._modalService.showDialogAsync<IStatusChangeReason>(new StatusChangeReasonDialogParams(
                    new StatusChangeReasonTypeId("")
                ));

                if (dialogResult) {
                    if (PrescriptionStatus.Deleted === status) {
                        response = await this.prescriptionApiAdapter.setPrescriptionStatusAsync(this.selectedPrescription, status, true, false, dialogResult.statusChangeReasonId, dialogResult.additionalText, false, extensionData);
                    } else if (PrescriptionStatus.UnderRecording === status) {
                        response = await this.prescriptionApiAdapter.setPrescriptionStatusAsync(this.selectedPrescription, PrescriptionStatus.UnderRecording, false, false, dialogResult.statusChangeReasonId, dialogResult.additionalText, false, extensionData);
                    }
                }
                if (!isNullOrUndefined(response)) {
                    // TODO: it will need a refactor because using another plugin data
                    if (!isNullOrUndefined(response?.extensionData?.WasEhiEnabled)) {
                        if (!arrayIsNullOrEmpty(response.extensionData?.EhiBusinessError)) {
                            this.notificationService.error(response.extensionData?.EhiBusinessError[0].Message);
                        } else if (response.extensionData?.EhiHasTechnicalError === true) {
                            this.notificationService.error(StaticHunSocialSecurityMedicationRequestResources.EhiTechnicalErrorOnDelete);
                        } else if (response?.status === status) {
                            this.notificationService.success(StaticHunSocialSecurityMedicationRequestResources.SuccessfulDeleteFromEESZT);
                        }
                    }
                }
            }
        }
        return response;
    }

    @State.bound
    private async validateAsync(dirtyFields?: string[]): Promise<IClientValidationResult[]> {
        if (!this.selectedPrescription) {
            return [];
        }

        let result: Prescription;
        if (this.validationOperation === "save") {
            result = await this.saveAsync(false, true);
        } else { // === "submit"
            result = await this.submitPrescriptionAsync(this.selectedPrescription, true);
        }
        return result.validationResults;
    }

    @State.bound
    private async deletePrescriptionAsync() {
        let response: Prescription = null;

        if (this.selectedPrescription.status === PrescriptionStatus.Submitted) {
            if (!isNullOrUndefined(this.selectedPrescription.digitalPrescriptionIdentifier)) {
                response = await this.deleteExternalPrescriptionAsync(PrescriptionStatus.Deleted);
            } else {
                const dialogResult = await this.props._modalService.showDialogAsync<IStatusChangeReason>(new StatusChangeReasonDialogParams(
                    new StatusChangeReasonTypeId("")
                ));

                if (dialogResult) {
                    response = await this.prescriptionApiAdapter.setPrescriptionStatusAsync(this.selectedPrescription, PrescriptionStatus.Deleted, true, false, dialogResult.statusChangeReasonId, dialogResult.additionalText, false);
                }
            }

        } else {
            const dialogResult = await this.dialogService.yesNoCancel(StaticWebAppResources.Common.DialogTitle.ConfirmationTitle,
                StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.DeletePrescriptionDialog.Text);

            if (dialogResult.resultCode === DialogResultCode.Yes) {
                response = await this.prescriptionApiAdapter.setPrescriptionStatusAsync(this.selectedPrescription, PrescriptionStatus.Deleted, true);
            }
        }

        if (!isNullOrUndefined(response)) {
            const isDeleted = response.status === PrescriptionStatus.Deleted || response.status === PrescriptionStatus.WaitingForDelete;
            if (isDeleted) {
                this.notificationService.showSaveResult(response.isPersistedByOperationInfo, response.hasValidationWarning);
            }

            if (response.isPersistedByOperationInfo) {
                if (isDeleted) {
                    this.props.onNavigateToPrescription(null);
                }
            }
        }
    }

    @State.bound
    private async onSaveClickedAsync() {
        this.validationOperation = "save";
        await this.saveAndNavigateIfNecessaryAsync(false);
    }

    @State.action.bound
    private async onGridChangeAsync(type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState | IOrderingState[]) {
        if (type === "changed") {
            this.paging = paging;
            this.ordering = _.isArray(ordering) ? ordering[0] : ordering as IOrderingState;
            await this.loadAsync();
        }
    }

    @State.bound
    private getExternalPrescriptionDetailAsyncFactory(identifier: string, prescriptionId: PrescriptionId, itemType: PrescriptionItemType): () => Promise<void> {
        return async () => {
            let prescription = null;
            if (!this.props._careActivityId) {
                prescription = await this.prescriptionApiAdapter.getPrescriptionByIdAsync(prescriptionId, false);
            }

            const extensionData = {};
            const ehiServiceCallStatus = await this.setEhiAccessDataToExtensionDataAsync(extensionData);
            this.handleEhiServiceCall(ehiServiceCallStatus);

            if (ehiServiceCallStatus === EhiServiceCallStatus.Success) {
                const externalPrescription = await this.ehiPrescriptionService.getExternalPrescriptionDataAsync(
                    this.props._patientId,
                    identifier,
                    this.props._careActivityId || prescription?.careActivityId,
                    this.filterStore.isEmergency,
                    this.filterStore.emergencyReason,
                    itemType,
                    extensionData);

                const ehiErrors = externalPrescription.result?.value.errors ?? [];
                if (externalPrescription.ehiServiceCallStatus !== EhiServiceCallStatus.Success) {
                    this.handleEhiServiceCall(externalPrescription.ehiServiceCallStatus);
                } else if (ehiErrors.length > 0) {
                    ehiErrors.forEach(ehiError =>
                        this.notificationService.error(formatStringWithObjectParams(
                            StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Messages.EhiCommunicationError,
                            {
                                errorMessage: ehiError.message
                            }
                        )));
                } else {
                    await this.modalService.showModalAsync(new ExternalPrescriptionDetailModalParams(externalPrescription.result.value.value));
                    if (!isNullOrUndefined(this.selectedPrescription)) {
                        this.props.onNavigateToPrescription(null);
                    }
                }
            }
        };
    }

    @State.bound
    private createMedicationEquipmentListItem(claimTypeId: MedicationSubsidyClaimTypeId, id: MedicationId, equipmentList: MedicationEquipmentList) {
        const item = new MedicationEquipmentListItem();       
        item.setMedicationId(id, this.referenceDate);

        const medication = this.careReferenceDataStore.medications.get(item.medicationVersionSelector, true);
        if (medication?.uniqueSizing === true ||
            this.subsidyClaimTypeIsTemporaryPermission ||
            (equipmentList.countersignMandatory && medication?.isLint === false) ||
            this.isClaimTypeTemporaryPermission(claimTypeId)) {
            item.setIsInsubstitutable(true);
        }

        return item;
    }

    @State.bound
    private async onCreateNewMedicationEquipmentListItemAsync(): Promise<MedicationEquipmentListItem> {
        const dialogResult = await this.props._modalService.showDialogAsync<IMedicationEquipmentSelectorDialogResult>(
            new MedicationEquipmentSelectorDialogParams([], this.props._careActivityId, this.referenceDate));

        if (!dialogResult?.medicationId) {
            return null;
        }

        const equipmentList = this.selectedPrescription.medicationReferenceBase as MedicationEquipmentList;

        if (equipmentList?.items.some(i => ValueWrapper.equals(i.medicationVersionSelector?.id, dialogResult.medicationId))) {
            this.notificationService.error(StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Messages.EquipmentAlreadyAdded);
            return null;
        }

        await this.loadMedicationsAsync([
            ...equipmentList.items?.map(i => i.medicationVersionSelector) ?? [],
            {id: dialogResult.medicationId, validOn: this.selectedPrescription.usableFrom}
        ]);
        return this.createMedicationEquipmentListItem(this.selectedPrescription?.medicationRequestCoverage.claimTypeId, dialogResult.medicationId, equipmentList);
    }

    @State.action.bound
    public setClaimTypeId(claimTypeId: MedicationSubsidyClaimTypeId) {
        const coverage = this.selectedPrescription?.medicationRequestCoverage;

        if (!ValueWrapper.equals(claimTypeId, coverage?.claimTypeId)) {
            this.selectedPrescription.setApprovedDesignation(false);
        }

        if (this.selectedPrescription?.medicationReferenceBase instanceof MedicationEquipmentList && this.isClaimTypeTemporaryPermission(claimTypeId)) {
            for (const item of this.selectedPrescription.medicationReferenceBase.items) {
                item.setIsInsubstitutable(true);
            }
        }

        coverage?.setClaimTypeId(claimTypeId);
    }

    @State.computed
    private get operationsToChecks() {
        const res = {};

        if (!this.props.readonly && this.props._careActivityId) {
            res["CreatePrescription"] = async () => {
                await this.prescriptionApiAdapter.createPrescriptionsAsync([], this.props._careActivityId, false, true);
            };
        }

        if (this.selectedPrescription) {
            if (!this.props.readonly) {
                res["UpdatePrescription"] = async () => {
                    await this.prescriptionApiAdapter.updatePrescriptionAsync(this.selectedPrescription, false, false, true);
                };
                if (this.selectedPrescription.status !== PrescriptionStatus.Deleted) {
                    res["DeletePrescription"] = async () => {
                        await this.prescriptionApiAdapter.setPrescriptionStatusAsync(this.selectedPrescription, PrescriptionStatus.Deleted, false, false, null, null, false, null, true);
                    };
                }
                if (this.selectedPrescription.status === PrescriptionStatus.UnderRecording) {
                    res["SubmitPrescription"] = async () => {
                        await this.prescriptionApiAdapter.setPrescriptionStatusAsync(this.selectedPrescription, PrescriptionStatus.Submitted, false, false, null, null, false, null, true);
                    };
                }
                if (this.selectedPrescription.status === PrescriptionStatus.Submitted) {
                    res["CancelPrescriptionSubmission"] = async () => {
                        await this.prescriptionApiAdapter.setPrescriptionStatusAsync(this.selectedPrescription, PrescriptionStatus.UnderRecording, false, false, null, null, false, null, true);
                    };
                    res["SavePrescriptionAsPermanent"] = async () => {
                        await this.prescriptionApiAdapter.createPermanentPrescriptionAsync(this.selectedPrescription.id, this.patientId, false, true);
                    };
                }
            }
            res["PrintPrescription"] = async () => {
                await this.prescriptionApiAdapter.printPrescriptionAsync(this.selectedPrescription.id, null, null, null, null, null, true);
            };
        }

        return res;
    }

    @State.bound
    private renderPageButtons() {
        if (this.props.buttons) {
            return (
                <HisUi.HisPanelButtonPortal>
                    {this.props.buttons}
                </HisUi.HisPanelButtonPortal>
            );
        }
        return null;
    }
    @State.computed
    private get patientId() {
        return this.props._patientId ?? this.careActivityStore.patientId;
    }

    @State.computed
    private get errorExtensionProps() {
        return {
            extensionData: this.ehiErrorExtensionData
        } as IPrescriptionEhiErrorsExtensionPointProps;
    }

    @State.bound
    private renderMasterToolbar() {
        return (
            <Ui.Flex xsVerticalAlign={"middle"} verticalSpacing={"none"}>
                {!this.props.readonly && !this.selectedPrescription && !this.selectedExternalPrescription &&
                    <Ui.Flex.Item>
                        <Ui.UniversalEnumSelector
                            enumName={"PrescriptionSearchBase"}
                            enumOrigin="server"
                            value={this.filterStore.prescriptionSearchBase}
                            onChange={this.filterStore.setPrescriptionSearchBase}
                            enumType={PrescriptionSearchBase}
                            displayMode="groupedRadioButtons"
                            automationId="prescriptionSearchBaseSelector"
                        />
                    </Ui.Flex.Item>
                }
                {!this.props.readonly && !this.selectedExternalPrescription &&
                    <Ui.Flex.Item>
                        <Ui.Button
                            onClickAsync={this.loadAsync} text={StaticWebAppResources.Common.Button.Reload} iconName="circleArrowCcw" automationId="reloadButton" />
                    </Ui.Flex.Item>
                }
                {!this.props.isCompact && !this.selectedPrescription && !this.selectedExternalPrescription &&
                    <Ui.Flex.Item>
                        <Ui.CheckBox displayMode="toggle-button" value={this.showFilter} onChange={this.setShowFilter} label={StaticWebAppResources.Common.Label.DetailedSearch} toggleButtonProps={{ iconName: "filter", automationId: "toggleButton" }} verticalAlign="noPadding" automationId="showFiltersCheckBox" />
                    </Ui.Flex.Item>
                }
                {!this.props.readonly && !this.selectedExternalPrescription && !this.isCareActivityClosed &&
                    <Ui.Flex.Item>
                        <Ui.AddButton
                            permissionCheckOperationNames="CreatePrescription"
                            permissionDeniedStyle="disabled"
                            onClickAsync={this.addNewPrescriptionAsync}
                            text={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.NewPrescription}
                            visualStyle="primary"
                            automationId="addNewButton"
                        />
                    </Ui.Flex.Item>
                }
                {!this.selectedPrescription && !this.selectedExternalPrescription &&
                    <Ui.Flex.Item>
                        <Ui.ContextMenu.Provider id="prescription_master_actions_menu" event="onClick">
                            <Ui.Button
                                iconName="more"
                                onClick={nullFunction}
                                tooltipContent={StaticWebAppResources.Common.Button.FurtherActions}
                                tooltipPosition="bottom"
                                disabled={false}
                                automationId="moreButton"
                            />
                        </Ui.ContextMenu.Provider>
                        <Ui.ContextMenu id="prescription_master_actions_menu" key="prescription_master_actions_menu">
                            <Ui.ContextMenu.Item
                                onClickAsync={this.navigateToPermanentPrescriptionsAsync}
                                permissionCheckOperationNames="CreatePrescription"
                                permissionDeniedStyle="disabled">
                                <SpanWithIcon iconName="pills">{StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.ManagePermanentPrescriptions}</SpanWithIcon>
                            </Ui.ContextMenu.Item>
                        </Ui.ContextMenu>
                    </Ui.Flex.Item>
                }
            </Ui.Flex>
        );
    }
    @State.bound
    private async renderMedicationInfoModalAsync() {
        await this.modalService.showModalAsync(new MedicationInfoModalParams(this.medicationOfSingleMedicationReference));
    }
    @State.bound
    private renderContextMenu() {
        const notNewAndMutable = (this.selectedPrescription?.isNew === false) && this.selectedPrescription?.isMutable;
        const notSameCare = !ValueWrapper.equals(this.selectedPrescription?.careActivityId, this.props._careActivityId);
        return (
            <Ui.ContextMenu id="prescription_actions_menu" key="prescription_actions_menu">
                <Ui.ContextMenu.Item
                    onClickAsync={this.deletePrescriptionAsync}
                    disabled={!notNewAndMutable || this.selectedPrescription?.status === PrescriptionStatus.Deleted || notSameCare}
                    permissionCheckOperationNames="DeletePrescription"
                    permissionDeniedStyle="disabled">
                    <SpanWithIcon iconName={"trash"} visualStyle="error">{StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.Buttons.Delete}</SpanWithIcon>
                </Ui.ContextMenu.Item>
                <Ui.ContextMenu.Item
                    onClickAsync={this.cancelSubmissionAsync}
                    disabled={!notNewAndMutable || this.selectedPrescription?.status !== PrescriptionStatus.Submitted || notSameCare}
                    permissionCheckOperationNames="CancelPrescriptionSubmission"
                    permissionDeniedStyle="disabled">
                    <SpanWithIcon iconName={"circleArrowCcw"}>{StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.Buttons.CancelSubmission}</SpanWithIcon>
                </Ui.ContextMenu.Item>
                <Ui.ContextMenu.Item
                    onClickAsync={this.savePrescriptionAsPermanentAsync}
                    disabled={notSameCare || this.selectedPrescription?.status !== PrescriptionStatus.Submitted}
                    permissionCheckOperationNames="SavePrescriptionAsPermanent"
                    permissionDeniedStyle="disabled">
                <SpanWithIcon iconName={"save"}>{StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.Buttons.SavePrescriptionAsPermanent}</SpanWithIcon>
                </Ui.ContextMenu.Item>
            </Ui.ContextMenu>
        );
    }

    @State.bound
    private renderDetailToolbar(): React.ReactNode {
        const notNewAndMutable = (this.selectedPrescription?.isNew === false) && this.selectedPrescription?.isMutable;
        return !this.props.readonly && !this.selectedExternalPrescription && (
            <>
                <LockIndicatorComponent
                    onEditClickedAsync={this.onEditClickedAsync}
                    locked={!this.selectedPrescription?.isNew && !this.selectedPrescription?.isMutable}
                    lockedBy={this.selectedPrescription?.lockInfo?.preventingLockUserId}
                    disabled={this.isReadonly}
                    buttonVisualStyle="standard"
                />
                {this.selectedPrescription?.medicationReferenceBase instanceof SingleMedicationReference &&
                    <Ui.Button
                        iconName={"info"}
                        onClickAsync={this.renderMedicationInfoModalAsync}
                        automationId="medicationInfoTriggerButton"
                    />
                }
                {!this.isCareActivityClosed && this.selectedPrescription?.status === PrescriptionStatus.UnderRecording && this.isSameCareActivity &&
                    <Ui.SaveButton
                        onClickAsync={this.onSaveClickedAsync}
                        visualStyle="standard"
                        automationId="saveButton"
                        permissionCheckOperationNames="UpdatePrescription"
                        permissionDeniedStyle="disabled"
                    />
                }
                {notNewAndMutable && this.selectedPrescription?.status === PrescriptionStatus.UnderRecording && this.isSameCareActivity && !this.isCareActivityClosed && (
                    <Ui.Button
                        visualStyle="primary"
                        text={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.Buttons.Submit}
                        onClickAsync={this.onSubmitClickedAsync}
                        permissionCheckOperationNames="SubmitPrescription"
                        permissionDeniedStyle="disabled"
                        automationId="submitButton"
                    />
                )}
                {notNewAndMutable && this.selectedPrescription?.status === PrescriptionStatus.Submitted && (
                    <Ui.Button
                        text={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.Buttons.Print}
                        onClickAsync={this.printPrescriptionAsync}
                        permissionCheckOperationNames="PrintPrescription"
                        permissionDeniedStyle="disabled"
                        automationId="printButton"
                    />
                )}
                <Ui.ContextMenu.Provider id="prescription_actions_menu" event="onClick">
                    <Ui.Button
                        iconName="more"
                        onClick={nullFunction}
                        tooltipContent={StaticWebAppResources.Common.Button.FurtherActions}
                        tooltipPosition="bottom"
                        disabled={false}
                        automationId="moreButton"
                    />
                </Ui.ContextMenu.Provider>
                {this.renderContextMenu()}
            </>
        );
    }

    @State.bound
    private showEhiTokenNeededForPrescriptionSendingError() {
        this.notificationService.error(StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Messages.EhiTokenNeededForPrescriptionSendingError);
        return true;
    }

    @State.computed
    private get daysSupplied() {
        if (this.selectedPrescription?.medicationReferenceBase instanceof SingleMedicationReference) {
            if (this.selectedPrescription.medicationReferenceBase.isDaysSuppliedFilledManually) {
                return this.getManuallyFilledDaysSupplied;
            }

            return this.getCalculatedDaysSupplied;
        }

        return null;
    }

    @State.bound
    public observeDaysSupplied() {
        return this.daysSupplied;
    }

    @State.action.bound
    private setDaysSupplied(days: number) {
        if (this.selectedPrescription?.medicationReferenceBase instanceof SingleMedicationReference) {
            this.selectedPrescription.medicationReferenceBase.setDaysSupplied(days);
        }
    }

    @State.bound
    private handleEhiServiceCall(ehiServiceCallStatus: EhiServiceCallStatus) {
        switch (ehiServiceCallStatus) {
            case EhiServiceCallStatus.NoEhiId:
                this.notificationService.error(StaticHunSocialSecurityMedicationRequestResources.NoEhiIdError);
                break;
            case EhiServiceCallStatus.NoEhiToken:
                this.notificationService.error(StaticHunSocialSecurityMedicationRequestResources.NoEhiTokenError);
                break;
            case EhiServiceCallStatus.NoOrganizationUnit:
                this.notificationService.error(StaticHunSocialSecurityMedicationRequestResources.NoOrganizationUnitError);
                break;
            case EhiServiceCallStatus.Success:
            default:
                break;
        }
    }

    @State.action.bound
    private setIsUnpackaged(isUnpackaged: boolean) {
        if (this.selectedPrescription.medicationReferenceBase instanceof SingleMedicationReference) {
            this.selectedPrescription.medicationReferenceBase.setIsUnpackaged(isUnpackaged);
            if (isUnpackaged) {
                this.selectedPrescription.medicationReferenceBase.setPackagingUnitId(null);
            } else {
                const defaultPackagingUnitId = this.hunReferenceDataStore.packagingUnits.items.find(i => i.latinName === "Scat. Orig. No.")?.id;
                this.selectedPrescription.medicationReferenceBase.setPackagingUnitId(defaultPackagingUnitId);
            }
        }
    }

    @State.computed
    private get subtitle(): string {
        if (!this.lastSuccessfulMedicationUpdateDate) {
            return null;
        }

        const formattedDate = !isNullOrUndefined(this.lastSuccessfulMedicationUpdateDate)
            ? this.props._dependencies.localizationService.localizeDate(this.lastSuccessfulMedicationUpdateDate)
            : StaticHunSocialSecurityMedicationRequestResources.MedicationReferenceDataUpdateUnavailable;

        return formatStringWithObjectParams(StaticHunSocialSecurityMedicationRequestResources.MedicationReferenceDataUpdatedAt, {
            puphaxDate: formattedDate
        });
    }

    @State.action.bound
    private async navigateToPermanentPrescriptionsAsync() {
        await this.props._dependencies.globalRoutingStore.pushAsync(PermanentPrescriptionRouteDefinition.default.permanentPrescriptions.makeRoute({
            careActivityId: this.props._careActivityId?.value,
            permanentPrescriptionId: null
        }));
    }

    private renderUnauthorizedMaster() {
        return (
            <Ui.ToolbarLayout
                topToolbar={(
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <Ui.Flex>
                                <Ui.Flex.Item>
                                    <Ui.UniversalEnumSelector
                                        enumName={"PrescriptionSearchBase"}
                                        enumOrigin="server"
                                        value={this.filterStore.prescriptionSearchBase}
                                        onChange={this.filterStore.setPrescriptionSearchBase}
                                        enumType={PrescriptionSearchBase}
                                        displayMode="groupedRadioButtons"
                                        automationId="prescriptionSearchBaseSelector"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                )}
            >
                <UnauthorizedAccessContent />
            </Ui.ToolbarLayout>
        );
    }

    private get isSameCareActivity() {
        return ValueWrapper.equals(this.selectedPrescription?.careActivityId, this.props._careActivityId);
    }

    @State.computed
    private get isReadonly() {
        const result = this.props.readonly
            || this.isCareActivityClosed
            || (!this.selectedPrescription?.isNew && !this.selectedPrescription?.isMutable)
            || (this.selectedPrescription && this.selectedPrescription?.status !== PrescriptionStatus.UnderRecording)
            || !this.isSameCareActivity;

        return result;
    }

    public render() {
        const resources = StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel;

        return (
            <PermissionCheckContextProvider operationsToCheck={this.operationsToChecks} >
                <State.Reaction inspector={this.observeDaysSupplied} reaction={this.setDaysSupplied} />
                <BusinessErrorHandler.Register
                    businessErrorName="EhiTokenNeededForPrescriptionSendingError"
                    handler={this.showEhiTokenNeededForPrescriptionSendingError}
                />
                {/* {this.renderErrorExtension} */}
                <SingleLayout>
                    <MasterDetailLayout
                        selectedItem={this.filterStore.prescriptionDataSource === PrescriptionDataSource.His ? this.selectedPrescription : this.selectedExternalPrescription}
                        onSelectedItemChange={this.selectRow}
                        isCompact={this.props.isCompact}
                        showDetailCloseButton={this.props.showDetailCloseButton}
                    >
                        <MasterDetailMaster
                            title={resources.MasterTitle}
                            subTitle={this.subtitle}
                            iconName="pills"
                            toolbar={this.renderMasterToolbar()}
                        >
                            {(this.initializePanelAsync.isUnauthorizedAccess || this.isUnauthorizedAccess) ? this.renderUnauthorizedMaster() : (
                                <PrescriptionListView
                                    isLoading={this.isLoading}
                                    onRowSelected={this.selectRow}
                                    prescriptionList={this.prescriptionList}
                                    selectedPrescription={this.selectedPrescription}
                                    showExtendedFilter={this.showFilter}
                                    filterStore={this.filterStore}
                                    onBack={this.props.onBack}
                                    onGridChangeAsync={this.onGridChangeAsync}
                                    ordering={this.ordering}
                                    paging={this.paging}
                                    isCompact={this.props.isCompact}
                                    readonly={this.props.readonly}
                                    onLoadAsync={this.clearOrganizationUnitIdAndLoadAsync}
                                    getExternalPrescriptionDetailAsyncFactory={this.getExternalPrescriptionDetailAsyncFactory}
                                    externalPrescriptionSelected={!!this.selectedExternalPrescription}
                                    ehiServiceCallStatus={this.ehiServiceCallStatus}
                                    isInitialLoad={this.isInitialLoad}
                                    hideUpdateDateWarning={this.props.hideUpdateDateWarning}
                                    errorExtensionProps={this.errorExtensionProps}
                                />
                            )}

                        </MasterDetailMaster>
                        <MasterDetailDetail
                            title={this.props.isCompact ? this.medicationOfSingleMedicationReference?.name || resources.Detail.Title : resources.Detail.Title}
                            toolbar={this.renderDetailToolbar()}
                            hasSidePadding
                        >
                            {this.filterStore.prescriptionDataSource === PrescriptionDataSource.His ?
                                <ReadOnlyContext.Provider value={this.isReadonly}>
                                    <PrescriptionDetailView
                                        pointOfCareId={this.careActivityStore?.pointOfCareId}
                                        prescription={this.selectedPrescription}
                                        saveAsync={this.saveAsync}
                                        onReleaseLockAsync={this.releaseLockAsync}
                                        medications={this.medications}
                                        daysSupplied={this.getManuallyFilledDaysSupplied}
                                        calculatedDaysSupplied={this.getCalculatedDaysSupplied}
                                        patientHasValidCurativeSupplyPass={this.patientHasValidCurativeSupplyPass}
                                        onValidateAsync={this.validateAsync}
                                        optionCheckResults={this.subsidyOptionCheckResults}
                                        setOptionCheckResults={this.setSubsidyOptionCheckResults}
                                        medicationReferenceData={this.medicationReferenceData}
                                        setIsUnpackaged={this.setIsUnpackaged}
                                        medicationOfSingleMedicationReference={this.medicationOfSingleMedicationReference}
                                        medicationReferenceDataOfSingleMedicationReference={this.medicationReferenceDataOfSingleMedicationReference}
                                        onCreateNewMedicationEquipmentListItemAsync={this.onCreateNewMedicationEquipmentListItemAsync}
                                        ePrescriptionNotAllowedIsoPrefixes={this.ePrescriptionNotAllowedIsoPrefixes}
                                        prescribeWithIsoGroupAllowedIsoCodes={this.prescribeWithIsoGroupAllowedIsoCodes}
                                        isClaimTypeTemporaryPermission={this.subsidyClaimTypeIsTemporaryPermission}
                                        setClaimTypeId={this.setClaimTypeId}
                                        setEquipmentListType={this.setEquipmentListType}
                                    />
                                </ReadOnlyContext.Provider> :
                                <ExternalPrescriptionDetailViewSelector
                                    externalPrescription={this.selectedExternalPrescription}
                                />
                            }
                        </MasterDetailDetail>
                    </MasterDetailLayout>
                </SingleLayout >
                {this.renderPageButtons()}
            </PermissionCheckContextProvider >
        );
    }
}

export default connect(
    PrescriptionMasterDetailPanel,
    new DependencyAdapter<IPrescriptionMasterDetailPanelProps, IPrescriptionMasterDetailPanelDependencies>(c => ({
        prescriptionApiAdapter: c.resolve("PrescriptionApiAdapter"),
        careActivityApiAdapter: c.resolve("CareActivityApiAdapter2"),
        structureApiAdapter: c.resolve("StructureApiAdapter"),
        referenceDataApiAdapter: c.resolve("ReferenceDataApiAdapter"),
        notificationService: c.resolve("INotificationService"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService"),
        hunCareReferenceDataStore: c.resolve("MedicationRequestReferenceDataStore"),
        lockingApiAdapter: c.resolve("LockingApiAdapter"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        dialogService: c.resolve("IDialogService"),
        userContext: c.resolve("UserContext"),
        practitionerApiAdapter: c.resolve("PractitionerApiAdapter"),
        dynamicPropertiesApiAdapter: c.resolve("DynamicPropertiesApiAdapter"),
        diagnosisListApiAdapter: c.resolve("DiagnosisListApiAdapter"),
        ehiUserContext: c.resolve("EhiUserContext"), // TODO: separate HunSocialSecurity from Ehi plugin
        pricingAndSubsidyLoader: c.resolve("PricingAndSubsidyLoader"),
        ehiPrescriptionServiceFactory: c.resolve("EhiPrescriptionServiceFactory"),
        searchParametersService: c.resolve("ISearchParametersService"),
        globalRoutingStore: c.resolve("GlobalRoutingStore")
    })),
    new CareActivityContextAdapter<IPrescriptionMasterDetailPanelProps>(careActivityContext => ({
        _careActivityId: careActivityContext.careActivityId,
        _careActivityBaseData: careActivityContext?.baseData,
        _pointOfCareId: careActivityContext.careActivity?.pointOfCareId
    })),
    new PatientContextAdapter<IPrescriptionMasterDetailPanelProps>(patientContext => ({
        _patientId: patientContext.patientId,
        _patient: patientContext.patient
    })),
    new HisModalServiceAdapter(),
    new FormExtensionRegistryAdapter("ManagePrescriptions")
);
