import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IEhiLoginDialogParams } from "./EhiLoginDialogParams";
import EhiLoginDialogView from "./EhiLoginDialogView";
import StaticHunEHealthInfrastructureUserManagementResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/StaticResources/StaticHunEHealthInfrastructureUserManagementResources";
import EhiLoginMode from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiLoginMode";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";
import EidAdapter, { IEidError, IEidSuccess } from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/EidAdapter/EidAdapter";
import HunEHealthInfrastructureUserManagementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/HunEHealthInfrastructureUserManagementApiAdapter";
import Log from "@Log";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiUserContext from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiUserContext";
import { UserManagement } from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/Api/ApiConstraints.g";

interface IEhiLoginDialogDependencies {
    localizationService: ILocalizationService;
    notificationService: INotificationService;
    apiAdapter: HunEHealthInfrastructureUserManagementApiAdapter;
    eidAdapter: EidAdapter;
    ehiUserContext: EhiUserContext;
}
interface IEhiLoginDialogProps extends IModalComponentParams, IEhiLoginDialogParams {
    _dependencies?: IEhiLoginDialogDependencies;
}

/** @screen */
@State.observer
class EhiLoginDialog extends React.Component<IEhiLoginDialogProps> {
    private get localizationService() { return this.props._dependencies.localizationService; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get eidAdapter() { return this.props._dependencies.eidAdapter; }
    private get apiAdapter() { return this.props._dependencies.apiAdapter; }

    private validator = new ClientSideValidator(UserManagement, {
        shouldBeFilled: StaticHunEHealthInfrastructureUserManagementResources?.Validation?.ShouldBeFilled
    });

    @State.observable private ehiLoginMode: EhiLoginMode = EhiLoginMode.Eid;

    @State.observable private password: string = "";
    @State.observable private otp: string = "";

    @State.observable public eidTerminalLoading: boolean = false;

    @State.action.bound
    private setEhiLoginMode(newValue: EhiLoginMode) {
        this.ehiLoginMode = newValue;
    }

    @State.action.bound
    private setPassword(newValue: string) {
        this.password = newValue;
    }

    @State.action.bound
    private setOtp(newValue: string) {
        this.otp = newValue;
    }
    @State.bound
    private validate(): boolean {
        if (this.ehiLoginMode === EhiLoginMode.Otp || this.ehiLoginMode === EhiLoginMode.Mobile) {
            if (!this.validator.isValid()) {
                this.notificationService.error(StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.LoginError);
                return false;
            }
        }
        return true;
    }

    @State.action.bound
    private close() {
        this.props.onClose(null);
    }
    private createErrorResult(errorText: string) {
        return {
            operationWasSuccessful: false,
            value: {
                wasSuccessful: false,
                error: errorText,
                token: null as any
            }
        };
    }
    @State.action.bound
    private setEidTerminalLoading(newValue: boolean) {
        this.eidTerminalLoading = newValue;
    }

    @State.bound
    private async loginAsync() {
        let result = null;
        let ridTokenResult = null;
        let eidTokenResult = null;
        if (isNullOrUndefined(this.props.ehiIdentifier)) {
            this.notificationService.error(StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.NoEhiIdentifier);
            return;
        }
        const validated = this.validate();
        if (validated) {
            if (this.ehiLoginMode === EhiLoginMode.Otp) {
                try {
                    result = await this.apiAdapter.loginWithOtpAsync(this.props.ehiIdentifier, this.password, this.otp);
                } catch (e) {
                    Log.error(e);
                    this.notificationService.error(StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.CommunicationError);
                    return;
                }
                if (isNullOrUndefined(result.value)) {
                    result = this.createErrorResult(StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.LoginError);
                }
            } else if (this.ehiLoginMode === EhiLoginMode.Mobile) {
                try {
                    result = await this.apiAdapter.loginWithMobileAsync(this.props.ehiIdentifier, this.otp);
                } catch (e) {
                    Log.error(e);
                    this.notificationService.error(StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.CommunicationError);
                    return;
                }
                if (isNullOrUndefined(result.value)) {
                    result = this.createErrorResult(StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.LoginError);
                }
            } else {
                try {
                    ridTokenResult = await this.apiAdapter.getEidRidTokenAsync();
                } catch (e) {
                    Log.error(e);
                    this.notificationService.error(StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.CommunicationError);
                    return;
                }
                if (!ridTokenResult.operationWasSuccessful) {
                    result = this.createErrorResult(StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.RidRequestError);
                } else if (isNullOrUndefined(ridTokenResult.value)) {
                    result = this.createErrorResult(StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.RidRequestError);
                    this.notificationService.error(StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.RidRequestError);
                    return;
                } else {

                    this.setEidTerminalLoading(true);
                    try {
                        eidTokenResult = await this.eidAdapter.tryGetEidDataAsync(ridTokenResult.value.rid);
                    } catch (e) {
                        Log.error(e);
                        this.notificationService.error(StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.CommunicationError);
                        return;
                    }
                    this.setEidTerminalLoading(false);

                    const eidSuccess = (eidTokenResult as IEidSuccess);
                    const eidError = (eidTokenResult as IEidError);

                    if (eidSuccess?.id_token) {

                        if (!(eidSuccess?.state === "NMW-Nonce-" + ridTokenResult.value.eidGuid)) {
                            Log.error("Incorrect nonce in terminal request.");
                            result = this.createErrorResult(StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.TerminalError);
                        } else {
                            try {
                                result = await this.apiAdapter.loginWithEidAsync(
                                    ridTokenResult.value.eidGuid,
                                    ridTokenResult.value.requestedAt,
                                    (eidTokenResult as IEidSuccess).id_token,
                                    this.props.ehiIdentifier
                                );
                            } catch (e) {
                                Log.error(e);
                                this.notificationService.error(StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.CommunicationError);
                                return;
                            }
                        }
                    } else if (eidError?.error_description) {
                        result = this.createErrorResult(eidError.error_description);

                    } else {
                        result = this.createErrorResult(StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.TerminalError);
                    }
                }
            }
            if (result.value?.wasSuccessful) {
                this.props.onClose(result);
            } else {
                this.notificationService.error(result?.value?.error || StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.CommunicationError);
            }
        }
    }

    public render() {
        return (
            <EhiLoginDialogView
                otp={this.otp}
                setOtp={this.setOtp}
                password={this.password}
                setPassword={this.setPassword}
                localizationService={this.localizationService}
                validator={this.validator}
                ehiLoginMode={this.ehiLoginMode}
                setEhiLoginMode={this.setEhiLoginMode}
                onClose={this.close}
                loginAsync={this.loginAsync}
                ehiToken={this.props.ehiToken}
                eidTerminalLoading={this.eidTerminalLoading}
                ehiIdentifier={this.props.ehiIdentifier}
                isRenew={this.props.isRenew}
                loginName={this.props.ehiLoginName}
            />
        );
    }
}

export default connect(
    EhiLoginDialog,
    new DependencyAdapter<IEhiLoginDialogProps, IEhiLoginDialogDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
        notificationService: c.resolve("INotificationService"),
        apiAdapter: c.resolve("HunEHealthInfrastructureUserManagementApiAdapter"),
        eidAdapter: c.resolve("EidAdapter"),
        ehiUserContext: c.resolve("EhiUserContext")
    }))
);