import React from "react";
import Styles from "./PageRoot.css";
import { ScrollView } from "@CommonControls";

interface IPageRootProps {
    noScroll?: boolean;
    className?: string;
    fadeIn?: boolean;
    children?: React.ReactNode;
}

const renderChildren = (props: IPageRootProps) => {
    if (props.noScroll) {
        return props.children;
    }

    return (
        <ScrollView>{props.children}</ScrollView>
    );
};


const PageRoot: React.SFC<IPageRootProps> = (props) => {
    const classes = [Styles.pageRoot];

    if (props.fadeIn) {
        classes.push(Styles.fadeIn);
    }

    if (!props.noScroll) {
        classes.push(Styles.scrollable);
    }

    if (props.className) {
        classes.push(props.className);
    }

    return (
        <div className={classes.join(" ")}>{renderChildren(props)}</div>
    );
};

export default PageRoot;