import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IPerformedServiceFirstRowExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPerformedServiceFirstRowExtensionPointProps";
import PerformedServiceClassificationSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/PerformedServiceClassificationSelectBox/PerformedServiceClassificationSelectBox";
import PerformedServiceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/PerformedServiceDataStore";
import PerformedServiceClassificationId from "@Primitives/PerformedServiceClassificationId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PerformedServiceId from "@Primitives/PerformedServiceId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";

@State.observer
export default class PerformedServiceFirstRow extends React.Component<IPerformedServiceFirstRowExtensionPointProps> {
    @State.observable.ref public store: PerformedServiceDataStore;

    public componentDidMount(): void {
        this.initialize();
    }

    @State.action.bound
    private initialize() {
        if (this.props.newPerformedService?.extensionData) {
            this.store = PerformedServiceDataStore.CreateFromJsonObject(this.props.newPerformedService.extensionData);

            if (isNullOrUndefined(this.store.classification)) {
                this.setClassification(PerformedServiceClassificationId.V);
            }
        } else {
            this.store = new PerformedServiceDataStore();
            this.store.careActivityId = this.props.careActivityId;

            this.setClassification(PerformedServiceClassificationId.V);
        }
    }

    @State.action.bound
    private setClassification(newValue: PerformedServiceClassificationId) {
        this.store.classification = newValue;

        this.props.newPerformedService.setExtensionData({
            ...this.props.newPerformedService.extensionData,
            ["HunSocialSecurity_PerformedServiceData"]: {
                Id: new PerformedServiceId("-1").toJSON(), // required
                CareActivityId: this.props.careActivityId.toJSON(),
                MedicalServiceFinancingId: this.store.medicalServiceFinancingId?.toJSON(),
                PerformedServiceClassificationId: this.store.classification.toJSON(),
                FinancedServiceId: this.store.financedServiceId?.toJSON(),
                RowVersion: new RowVersion(-1).toJSON(),
            }
        });
    }

    public render() {
        if (isNullOrUndefined(this.store)) {
            return (<></>);
        }

        return (
            <PerformedServiceClassificationSelectBox
                value={this.store.classification}
                onChange={this.setClassification}
                propertyIdentifier="PerformedServiceClassificationId"
                disabled={this.props.readonly}
                automationId="__performedService"
            />
        );
    }
}
