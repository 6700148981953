exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Toast_toast-wrapper_26A_x {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.Toast_toast-text_TIfEi {\n  flex: 1;\n  text-align: left;\n}\n.Toast_success-fill_Y3qFh,\n.Toast_success-fill_Y3qFh:hover {\n  fill: #21C313 !important;\n}\n.Toast_error-fill_288kG,\n.Toast_error-fill_288kG:hover {\n  fill: #CE5374 !important;\n}\n.Toast_warning-fill_1tWEg,\n.Toast_warning-fill_1tWEg:hover {\n  fill: #FE8443 !important;\n}\n.Toast_info-fill_3aNPP,\n.Toast_info-fill_3aNPP:hover {\n  fill: #00B4EC !important;\n}\n", ""]);

// exports
exports.locals = {
	"toast-wrapper": "Toast_toast-wrapper_26A_x",
	"toastWrapper": "Toast_toast-wrapper_26A_x",
	"toast-text": "Toast_toast-text_TIfEi",
	"toastText": "Toast_toast-text_TIfEi",
	"success-fill": "Toast_success-fill_Y3qFh",
	"successFill": "Toast_success-fill_Y3qFh",
	"error-fill": "Toast_error-fill_288kG",
	"errorFill": "Toast_error-fill_288kG",
	"warning-fill": "Toast_warning-fill_1tWEg",
	"warningFill": "Toast_warning-fill_1tWEg",
	"info-fill": "Toast_info-fill_3aNPP",
	"infoFill": "Toast_info-fill_3aNPP"
};