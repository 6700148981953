import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import TemplateId from "@Primitives/TemplateId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import ScopeIdentifier from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ScopeIdentifier";
import { getScopeIdentifiersDto } from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/DtoMappers";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import Encoding from "@Toolkit/CommonWeb/Encoding";
import ITemplateInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateInfo";
import ITokenGroup from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITokenGroup";
import ITemplateToken from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateToken";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import TemplateStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TemplateStore";
import { mapContentStringTemplate, mapTemplateContentBase64 } from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Templating/TemplateDtoMappers";
import TokenUsageListStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TokenUsageListStore";
import DocumentFile from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentFile";
import { mapTemplateInfo, mapITemplateListItem, getTokenLockInfo } from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Templating/TemplateStoreMappers";
import ITemplateListItem from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateListItem";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IExtractedTokenContent from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/IExtractedTokenContent";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import LargeDataToken from "@Primitives/LargeDataToken.g";

@Di.injectable()
export default class TemplateApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("ITemplatingClient") private readonly apiClient: Proxy.ITemplatingClient,
        @Di.inject("ILocalizationService") private readonly localizationService: ILocalizationService
    ) {
        super();
    }

    @State.bound
    public getByIdsAsync(ids: TemplateId[]): Promise<SimpleStore<ITemplateInfo[]>> {
        return this.processOperationAsync(
            new SimpleStore<ITemplateInfo[]>(),
            async target => {
                const response = await this.apiClient.getTemplatesByIdsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetTemplatesByIdsControllerDto({
                        ids
                    }));

                target.operationInfo = createOperationInfo(response);
                target.value = response.templates.map(mapTemplateInfo);
            });
    }

    @State.bound
    public getAvailableIdsAsync(organizationUnitId: OrganizationUnitId, templateScope: string) {
        return this.processOperationAsync(
            new SimpleStore<TemplateId[]>(),
            async target => {
                const response = await this.apiClient.getAvailableTemplateIdsQueryAsync(CreateRequestId(), 
                new Proxy.GetAvailableTemplateIdsControllerDto(
                    {
                        organizationUnitId: organizationUnitId,
                        templateScope: templateScope
                    } as Proxy.IGetAvailableTemplateIdsControllerDto));

                target.operationInfo = createOperationInfo(response);
                target.value = response && response.availableTemplateIds;
            }
        );
    }

    @State.bound
    public getAvailablePrimaryTemplatesQueryAsync(organizationUnitId: OrganizationUnitId) {
        return this.processOperationAsync(
            new SimpleStore<ITemplateInfo[]>(),
            async target => {
                const response = await this.apiClient.getAvailableTemplatesQueryAsync(CreateRequestId(), organizationUnitId.value);

                target.operationInfo = createOperationInfo(response);
                target.value = response.availableTemplates.filter((template) => template.isPrimary).map(template => mapTemplateInfo(template));
            }
        );
    }
    
    @State.bound
    public getTemplateWithHeaderFooterAsync(templateId: TemplateId, organizationUnitId: OrganizationUnitId) {
        return this.processOperationAsync(
            new SimpleStore<LargeDataToken>(),
            async target => {
                const response = await this.apiClient.getTemplateWithHeaderFooterQueryAsync(CreateRequestId(), templateId.value, organizationUnitId.value);

                target.operationInfo = createOperationInfo(response);
                target.value = response && response.id;
            }
        );
    }

    public extractTokenContentAsync(currentDocFile: DocumentFile, tokens: string[]) {
        return this.processOperationAsync(
            new SimpleStore<IExtractedTokenContent[]>(), async target => {
                const response = await this.apiClient.extractTemplateTokenContentsQueryAsync(
                    CreateRequestId(),
                    new Proxy.ExtractTemplateTokenContentsControllerDto({
                        content: currentDocFile?.getBase64(),
                        tokensToExtract: tokens
                    })
                );

                const extracts = response.response.map(it => ({
                    token: it.tokenReference,
                    content: it.contentAsJson,
                    isEmpty: it.isEmpty
                } as IExtractedTokenContent));

                target.operationInfo = createOperationInfo(response);
                target.value = extracts;
            });
    }

    public executeTemplateAsync(templateContentId: LargeDataToken, scopeIdentifiers: ScopeIdentifier[], currentDocFile: DocumentFile) {
        return this.processOperationAsync(
            new SimpleStore<any>(), async target => {
                const response = await this.apiClient.executeTemplateQueryAsync(
                    CreateRequestId(),
                    new Proxy.ExecuteTemplateControllerDto({
                        templateContentId: templateContentId,
                        scopeIdentifiers: getScopeIdentifiersDto(scopeIdentifiers),
                        currentContent: currentDocFile?.getBase64()
                    })
                );

                const docFile = DocumentFile.create(response.content);

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    contentType: response.contentType,
                    content: docFile,
                    hasChanges: response.changesApplied?.length > 0,
                    lockInfos: response.editableTemplateTokensUsed?.map(it => getTokenLockInfo(it))
                };
            });
    }

    public getTemplateListAsync(showDeleted: boolean) {
        return this.processOperationAsync(
            new SimpleStore<ITemplateListItem[]>(),
            async target => {
                const response = await this.apiClient.getTemplateListQueryAsync(CreateRequestId(), showDeleted.toString());

                target.operationInfo = createOperationInfo(response);
                target.value = response.items?.map(mapITemplateListItem);
            }
        );
    }

    public getTemplateByIdAsync(id: TemplateId) {
        return this.processOperationAsync(
            new TemplateStore(),
            async target => {
                const response = await this.apiClient.getTemplateByIdQueryAsync(CreateRequestId(), id.value);

                const contentByteArray = Base64Converter.toByteArray(response.content);
                const rawContent = Encoding.UTF8.getString(contentByteArray);
                const rawParsed: any = JSON.parse(rawContent);
                const content = mapContentStringTemplate(rawParsed);

                target.operationInfo = createOperationInfo(response);
                target.id = response.info.id;
                target.info = mapTemplateInfo(response.info);
                target.contentLargeDataId = response.contentLargeDataId;
                target.documentContent.setContent(content.documentContent);
                target.tokenUsages = new TokenUsageListStore(content.tokenUsages);
            }
        );
    }

    public createTemplateAsync(template: TemplateStore) {
        return this.processOperationAsync(
            new SimpleStore<TemplateId>(),
            async target => {

                const response = await this.apiClient.createTemplateCommandAsync(
                    CreateRequestId(),
                    new Proxy.CreateTemplateControllerDto({
                        code: template.info.code,
                        name: template.info.name,
                        description: template.info.description,
                        documentTypeId: template.info.documentTypeId,
                        cultureCode: template.info.cultureCode,
                        content: mapTemplateContentBase64(template.documentContent.getContent(), template.tokenUsages.items),
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.id;
            }
        );
    }

    public updateTemplateAsync(template: TemplateStore) {
        return this.processOperationAsync(
            new SimpleStore<RowVersion>(),
            async target => {

                const response = await this.apiClient.updateTemplateCommandAsync(
                    CreateRequestId(),
                    new Proxy.UpdateTemplateControllerDto({
                        id: template.id,
                        name: template.info.name,
                        description: template.info.description,
                        documentTypeId: template.info.documentTypeId,
                        cultureCode: template.info.cultureCode,
                        rowVersion: template.info.rowVersion,
                        content: mapTemplateContentBase64(template.documentContent.getContent(), template.tokenUsages.items),
                        isDeleted: template.info.isDeleted
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.rowVersion;
            }
        );
    }

    @State.bound
    public updateTemplatePermissionCheckAsync() {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.updateTemplateCommandAsync(
                    CreateRequestId(),
                    new Proxy.UpdateTemplateControllerDto({
                        id: new TemplateId("-1"),
                        name: "PermissionCheck",
                        cultureCode: "PermissionCheck",
                        documentTypeId: new DocumentTypeId("-1"),
                        content: "",
                        rowVersion: new RowVersion(0),
                        isDeleted: false
                    }),
                    true
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public getAllTokenGroupsAsync() {
        return this.processOperationAsync(
            new SimpleStore<ITokenGroup[]>(),
            async target => {
                const response = await this.apiClient.getAllTemplateTokenGroupsQueryAsync(CreateRequestId());

                target.operationInfo = createOperationInfo(response);
                target.value = response.tokenGroups?.map(g => ({
                    id: g.id,
                    parentId: g.parentId,
                    code: g.code,
                    name: g.name
                } as ITokenGroup));
            }
        );
    }

    public getAllTokensAsync() {
        return this.processOperationAsync(
            new SimpleStore<ITemplateToken[]>(),
            async target => {
                const response = await this.apiClient.getAllTemplateTokensQueryAsync(CreateRequestId());

                target.operationInfo = createOperationInfo(response);
                target.value = response.tokens?.map(t => ({
                    symbol: t.symbol,
                    description: t.description,
                    groupId: t.templateTokenGroupId,
                    providerParameterConfiguration: t.tokenFormatterConfiguration,
                    tokenFormatter: t.tokenFormatter,
                    tokenFormatterConfiguration: t.tokenFormatterConfiguration,
                    tokenFormatterSettingsType: t.tokenFormatterSettingsType,
                    displayName: t.displayName == null ? `SYM_${t.symbol}` : this.localizationService.localizeReferenceDataWithDefault(t.displayName, t.displayName.value ?? `SYM_${t.symbol}`)
                } as ITemplateToken));
            }
        );
    }
}
