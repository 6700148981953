import DependencyContainer from "@DiContainer";
import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import FormEngineBoundedContext from "@HisPlatform/BoundedContexts/FormEngine/FormEngineBoundedContext";
import * as FormEngineProxy from "@HisPlatform/BoundedContexts/FormEngine/Api/Proxy.g";
import FormFieldNameFactory from "@HisPlatform/BoundedContexts/FormEngine/Services/FormFieldNameFactory";
import FormEngineApiAdapter from "@HisPlatform/BoundedContexts/FormEngine/ApplicationLogic/ApiAdapter/FormEngineApiAdapter";
import IFormEngineApiAdapter, { FormEngineApiAdapterFactorySymbol } from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import IFormFieldNameFactory from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/IFormFieldNameFactory";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import FormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/FormEngineReferenceDataBase";

DependencyContainer
    .bind("IFormEngineApiAdapter")
    .to(FormEngineApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind(FormEngineApiAdapterFactorySymbol)
    .toFactory(c => () => c.container.get("IFormEngineApiAdapter"));

DependencyContainer
    .bind("IFormEngineReferenceDataStore")
    .to(FormEngineReferenceDataStore)
    .inSingletonScope();

DependencyContainer
    .bind("IBoundedContext")
    .to(FormEngineBoundedContext);

DependencyContainer
    .bind("IFormEditingClient")
    .to(FormEngineProxy.FormEditingClient);

DependencyContainer
    .bind("IFormInstanceHandlingClient")
    .to(FormEngineProxy.FormInstanceHandlingClient);

DependencyContainer
    .bind("IFormTokenHandlingClient")
    .to(FormEngineProxy.FormTokenHandlingClient);

DependencyContainer
    .bind("IFormFieldNameFactory")
    .to(FormFieldNameFactory);