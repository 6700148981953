import React from "react";
import Styles from "./PageBox.less";
import PageBox2Header from "@CommonControls/PageBox2/PageBox2Header";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PageBox2Body from "@CommonControls/PageBox2/PageBox2Body";
import PageBox2Tab, { IPageBox2TabBodyProps } from "@CommonControls/PageBox2/PageBox2Tab";
import Tab from "@CommonControls/Tab";
import TabPane from "@CommonControls/TabPane";
import PageBox2Footer from "@CommonControls/PageBox2/PageBox2Footer";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import { flattenFragments, ElementsType } from "@Toolkit/ReactClient/Common/FlattenFragments";
import PageBox2Wrapper from "@CommonControls/PageBox2/PageBox2Wrapper";

interface IPageBox2Props {
    hasTabs?: boolean;
    fullHeight?: boolean;

    initiallyActiveTabName?: string;
    activeTabName?: string;
    onActiveTabChange?: (tabName: string) => void;
}

@State.observer
export default class PageBox2 extends React.Component<IPageBox2Props> {

    
    public static Header = PageBox2Header;
    public static Body = PageBox2Body;
    public static Tab = PageBox2Tab;
    public static Footer = PageBox2Footer;
    

    public static defaultProps: Partial<IPageBox2Props> = {
        fullHeight: true
    };

    @State.observable.ref private _activeTabName: string = this.props.initiallyActiveTabName ?? null;
    @State.computed private get activeTabName() {
        return this.props.activeTabName === undefined ? this._activeTabName : this.props.activeTabName;
    }

    @State.computed private get className() {
        return combineClasses(
            Styles.pageBox,
            this.props.fullHeight && Styles.fullHeight,
            this.props.hasTabs && Styles.hasTabs
        );
    }

    public render() {
        return (
            <>
                {this.props.hasTabs && this.renderTabs()}
                <PageBox2Wrapper className={this.className}>
                    {this.renderChildren(this.props.children)}
                </PageBox2Wrapper>
            </>
        );
    }

    private renderChildren(children: React.ReactNode) {
        return React.Children.map(flattenFragments(children as ElementsType), this.renderChild);
    }

    private renderTabs() {
        return (
            <Tab
                headerTextAlign="center"
                tabsGrow={false}
                visualStyle="pageBox"
                noContent
                activeIndex={this.activeTabName}
                onSelectedIndexChange={this.setActiveTabName}
            >
                {React.Children.map(this.props.children, this.renderBodyChildAsTab)}
            </Tab>
        );
    }

    @State.bound
    private renderChild(element: React.ReactElement): React.ReactNode {

        if (
            element?.type === PageBox2Header ||
            element?.type === PageBox2Body ||
            element?.type === PageBox2Footer
        ) {
            return element;
        }

        if (element?.type === PageBox2Tab) {
            return this.activeTabName === element.props.tabName ?
                this.renderChildren(element.props.children) :
                null;
        }

        throw new Error(`Invalid child '${element?.type ?? element}' in a PageBox. 
        Content must be placed in a Header/Body/Footer sub-component within a PageBox.`);
    }

    @State.bound
    private renderBodyChildAsTab(element: React.ReactElement<IPageBox2TabBodyProps>) {
        if (element?.type === PageBox2Tab) {
            return (
                <TabPane
                    title={element.props.tabTitle}
                    disabled={element.props.disabled}
                    disablingTooltip={element.props.disablingTooltip}
                    name={element.props.tabName}
                    propertyPathRegexPattern={element.props.propertyPathRegexPattern}
                    automationId={element.props.automationId}
                    iconName={element.props.iconName}
                />
            );
        }
        return null;
    }

    @State.action.bound
    private setActiveTabName(name: string) {
        this.props.onActiveTabChange?.(name);
        this._activeTabName = name;
    }
}