import FormSchemaVersion from "./FormSchemaVersion";
import FormSchemaId from "@Toolkit/FormEngine/Model/Primitives/FormSchemaId.g";
import _ from "@HisPlatform/Common/Lodash";

export default class FormSchema {

    public readonly versions: FormSchemaVersion[];

    constructor(
        public readonly id: FormSchemaId,
        versions: FormSchemaVersion[]
    ) { 
        this.versions = _.orderBy(versions, v => v.versionNumber);
    }

    public getVersion(versionNumber: number) {
        const result = this.getVersionOrNull(versionNumber);
        if (!result) { throw new Error(`FormSchema version ${versionNumber} not found.`); }
        return result;
    }

    public getVersionOrNull(versionNumber: number) {
        return this.versions.find(v => v.versionNumber === versionNumber);
    }

    public getLatestVersionOrNull() {
        return this.versions.length === 0 ? null : this.versions.slice(-1)[0];
    }
}