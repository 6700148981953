/* auto-inject-disable */
import CareActivityId from "@Primitives/CareActivityId.g";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import CareActivityBaseData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareActivityBaseData";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { mapProxyFormFieldDataAsync } from "@HisPlatform/BoundedContexts/FormEngine/ApplicationLogic/ApiAdapter/FormEngineMappers";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import CareParticipantSettings from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareParticipantSettings";
import CareIdentifierSettings from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareIdentifierSettings";

@Di.injectable()
export default class CareActivityBaseDataApiAdapter extends ApiAdapterBase2 {

    constructor(
        @Di.inject("ICareClient") private readonly apiClient: Proxy.ICareClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("IFormEngineReferenceDataStore") private formEngineReferenceDataStore: IFormEngineReferenceDataStore
    ) {
        super(referenceDataLoader);
    }

    public loadByIdAsync(id: CareActivityId) {
        return this.executeOperationAsync<CareActivityBaseData, Proxy.GetCareActivityBaseDataAndSettingsCommandResponse>(
            () => this.apiClient.getCareActivityBaseDataAndSettingsCommandAsync(CreateRequestId(), new Proxy.GetCareActivityBaseDataAndSettingsControllerDto({
                careActivityId: id,
                requestLock: false
            })),
            async response => {
                const mappedFormFieldData: FormFieldDataBase[] = [];
                await mapProxyFormFieldDataAsync(response.careActivityBaseData.data as any, mappedFormFieldData, this.formEngineReferenceDataStore);

                return new CareActivityBaseData(
                    mappedFormFieldData,
                    response.careActivityBaseData.rowVersion,
                    new CareParticipantSettings(
                        response.careParticipantSettings.availableParticipants.map(i => {
                            return { id: i.careParticipantTypeId, role: i.role };
                        }), response.careParticipantSettings.primaryParticipant),
                    new CareIdentifierSettings(response.careIdentifierSettings.availableIdentifiers, response.careIdentifierSettings.primaryCareIdentifier));
            }
        );
    }
}
