import AppointmentScheduleSlotSeries from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentSchedule";
import moment from "moment";
import BookSlotDialogSettings from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/BookAppointmentDialog/BookSlotDialogSettings";
import AppointmentScheduleSlotSeriesId from "@Primitives/AppointmentScheduleSlotSeriesId.g";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";

export interface IBookSlotDialogParams {
    start: moment.Moment;
    end: moment.Moment;
    appointment: Appointment;
    settings: BookSlotDialogSettings;
    appointmentSchedules: AppointmentScheduleSlotSeries[];
    isTelemedicineConsultation: boolean;
    isAdditionalParticipantsAllowed: boolean;
}

export interface IBookSlotDialogResult {
    isBooked: boolean;
    updatedAppointment: Appointment;
}

export default class BookSlotDialogParams implements IBookSlotDialogParams {
    public static type = "BookSlotDialog";
    public get type() { return BookSlotDialogParams.type; }

    constructor(
        public readonly start: moment.Moment,
        public readonly end: moment.Moment,
        public readonly appointment: Appointment,
        public readonly settings: BookSlotDialogSettings,
        public readonly appointmentSchedules: AppointmentScheduleSlotSeries[],
        public readonly isTelemedicineConsultation: boolean,
        public readonly isAdditionalParticipantsAllowed: boolean,
    ) {
    }
}
