import React, { useCallback, useMemo } from "react";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import BusinessErrorHandler, { BusinessErrorHandlerContext } from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import UnauthorizedOperationBusinessError from "@Toolkit/CommonWeb/Model/UnauthorizedOperationBusinessError";
import LockAcquisitionError from "@Toolkit/CommonWeb/Model/LockAcquisitionError";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import LockDoesNotExistsError from "@Toolkit/CommonWeb/Model/LockDoesNotExistsError";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import ILockingNotificationService from "@HisPlatform/BoundedContexts/Locking/Services/Definition/ILockingNotificationService";
import UnauthenticatedUserBusinessError from "@Toolkit/CommonWeb/Model/UnauthenticatedUserBusinessError";
import UnauthorizedDataAccessError from "@Toolkit/CommonWeb/Model/UnauthorizedDataAccessError";
import UniversalBusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/UniversalBusinessErrorHandler";
import ServiceUnavailableError from "@Toolkit/CommonWeb/Model/ServiceUnavailableError";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";

interface IGlobalBusinessErrorHandlersProps {
    onUnauthenticated: () => void;
    onLockAcquisitionError?: () => void;
}


const HisBusinessErrorHandler: React.FC<IGlobalBusinessErrorHandlersProps> = props => {

    const dependencies = useDependencies(c => ({
        notificationService: c.resolve<INotificationService>("INotificationService"),
        lockingNotificationService: c.resolve<ILockingNotificationService>("ILockingNotificationService"),
        applicationContext: c.resolve<ApplicationContext>("ApplicationContext")
    }));

    const handler = useMemo(() => {
        const h = new UniversalBusinessErrorHandler();

        const handleUnauthorizedError = (err: UnauthorizedOperationBusinessError) => {
            dependencies.notificationService.error(StaticWebAppResources.Common.ToastMessage.UnauthorizedOperation);
            return true;
        };
    
        const handleUnauthenticatedError = (err: UnauthenticatedUserBusinessError) => {
            props.onUnauthenticated();
            return true;
        };
    
        const handleLockAcquisitionError = (err: LockAcquisitionError) => {
            dependencies.lockingNotificationService.displayErrorNotificationAsync(err);
            if (props.onLockAcquisitionError) {
                props.onLockAcquisitionError();
            }
            return true;
        };

        const handleServiceUnavailableError = (err: ServiceUnavailableError) => {
            dependencies.applicationContext.setUnderMaintenance();
            dependencies.notificationService.error(StaticWebAppResources.Common.ToastMessage.UnauthorizedOperation);
            return true;
        };
    
        const handleLockDoesNotExistsError = (err: LockDoesNotExistsError) => {
            dependencies.notificationService.error(StaticWebAppResources.Common.ToastMessage.LockAcquisitionFailed);
            return true;
        };
    
        const handleConcurrencyError = (err: any) => {
            dependencies.notificationService.error(StaticWebAppResources.Common.ToastMessage.LockAcquisitionFailed);
            return true;
        };

        h.registerHandler(UnauthenticatedUserBusinessError.businessErrorName, handleUnauthenticatedError);
        h.registerHandler(UnauthorizedOperationBusinessError.businessErrorName, handleUnauthorizedError);
        h.registerHandler(UnauthorizedDataAccessError.businessErrorName, handleUnauthorizedError);
        h.registerHandler(LockDoesNotExistsError.businessErrorName, handleLockDoesNotExistsError);
        h.registerHandler(LockAcquisitionError.businessErrorName, handleLockAcquisitionError);
        h.registerHandler(ServiceUnavailableError.businessErrorName, handleServiceUnavailableError);
        h.registerHandler("AggregateConcurrencyError", handleConcurrencyError);

        return h;
    }, [props.onLockAcquisitionError, props.onUnauthenticated]);

    return (
        <BusinessErrorHandlerContext.Provider value={handler}>
            {props.children}
            <HisPlatformExtensionPoint type="globalErrorHandler" extensionProps={null} />
        </BusinessErrorHandlerContext.Provider>
    );
};

export default HisBusinessErrorHandler;