import DependencyContainer from "@DiContainer";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import { GetTypeNameWithPrefix } from "@HunEHealthInfrastructurePlugin/PluginHelper";

DependencyContainer
    .bind(GetTypeNameWithPrefix("ICareClient"))
    .to(Proxy.CareClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("ICareRegisterClient"))
    .to(Proxy.CareRegisterClient)
    .inSingletonScope();

DependencyContainer
    .bind(GetTypeNameWithPrefix("IPatientRegisterClient"))
    .to(Proxy.PatientRegisterClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("IMedicalConditionClient"))
    .to(Proxy.MedicalConditionClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("IImmunizationClient"))
    .to(Proxy.ImmunizationClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("IAutonomyDisabilityStatementClient"))
    .to(Proxy.AutonomyDisabilityStatementClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("IPregnancyStatementClient"))
    .to(Proxy.PregnancyStatementClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("IMedicationStatementClient"))
    .to(Proxy.MedicationStatementClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("IConditionStatementClient"))
    .to(Proxy.ConditionStatementClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("ITherapyRecommendationClient"))
    .to(Proxy.TherapyRecommendationClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("ITherapiesClient"))
    .to(Proxy.TherapiesClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("IPatientBloodTypeClient"))
    .to(Proxy.PatientBloodTypeClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("IDeviceUseStatementClient"))
    .to(Proxy.DeviceUseStatementClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("IProcedureStatementClient"))
    .to(Proxy.ProcedureStatementClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("IRiskAssessmentClient"))
    .to(Proxy.RiskAssessmentClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("IPatientAllergyIntoleranceClient"))
    .to(Proxy.PatientAllergyIntoleranceClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("IMedicalAlertStatementClient"))
    .to(Proxy.MedicalAlertStatementClient);

DependencyContainer
    .bind(GetTypeNameWithPrefix("IHunReferenceDataClient"))
    .to(Proxy.HunReferenceDataClient)
    .inSingletonScope();