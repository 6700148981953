
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import CareActivityBasedFormDetailPanelStore from "./CareActivityBasedFormDetailPanelStore";

const { 
    ContextComponent: CareActivityBasedFormDetailPanelStoreContext, 
    StoreProvider: CareActivityBasedFormDetailPanelStoreProvider, 
    useStore: useCareActivityBasedFormDetailPanelStore,
    provideStore: provideCareActivityBasedFormDetailPanelStore,
} = createPanelStoreProvider(CareActivityBasedFormDetailPanelStore);

export {
    CareActivityBasedFormDetailPanelStoreContext,
    CareActivityBasedFormDetailPanelStoreProvider,
    useCareActivityBasedFormDetailPanelStore,
    provideCareActivityBasedFormDetailPanelStore
};