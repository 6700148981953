import moment from "moment";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import Log from "@Log";
import Money from "./Model/Money";
import ValueWrapper from "./Model/ValueWrapper";
import LocalDateRange from "./LocalDateRange";

export interface IEqualsParameters {
    stringContains?: boolean;
    dateTimeSeconds?: boolean;
    stringIgnoreCase?: boolean;
    checkRangeInclusions?: boolean;
}

const defaultParameters = {
    stringContains: false,
    dateTimeSeconds: false,
    stringIgnoreCase: false,
    checkRangeInclusions: false
} as IEqualsParameters;

function rangeCheckPossible(one: any, other: any) {
    return (one instanceof LocalDate && other instanceof LocalDateRange)
        || (moment.isMoment(one) && other instanceof LocalDateRange)
        || (one instanceof LocalDateRange && moment.isMoment(other))
        || (one instanceof LocalDateRange && other instanceof LocalDate);
}

function checkRangeInclusion(one: any, other: any, parameters: IEqualsParameters = defaultParameters) {

    if (rangeCheckPossible(one, other) === false) {
        throw new Error("Don't use this with values that aren't checked with rangeCheckPossible() first!");
    }

    if (one instanceof LocalDate && other instanceof LocalDateRange) {
        return other.includes(one);
    } else if (moment.isMoment(one) && other instanceof LocalDateRange) {
        return other.includesMoment(one);
    } else if (one instanceof LocalDateRange && moment.isMoment(other)) {
        return one.includesMoment(other);
    } else { // if (one instanceof LocalDateRange && other instanceof LocalDate) { <-- this branch is ensured by the checking above
        return one.includes(other);
    }
}

export function equals(one: any, other: any, parameters: IEqualsParameters = defaultParameters): boolean {

    if (one === null) {
        return other === null;
    }

    if (other === null) {
        return one === null;
    }

    if (one === undefined) {
        return other === undefined;
    }

    if (other === undefined) {
        return one === undefined;
    }

    if (typeof one === "string" && typeof other === "string") {
        const oneToCompare = parameters.stringIgnoreCase ? (one as string).toUpperCase() : (one as string);
        const otherToCompare = parameters.stringIgnoreCase ? (other as string).toUpperCase() : (other as string);
        if (parameters.stringContains && oneToCompare && otherToCompare) {
            return oneToCompare.includes(otherToCompare as string);
        } else {
            return oneToCompare === otherToCompare;
        }
    }

    if (typeof one === "number" || typeof one === "boolean") {
        return one === other;
    }

    // IEquatable
    if (typeof one === "object" && one.equals && typeof one.equals === "function") {
        return one.equals(other);
    }

    // static areEquals
    if (one.constructor && one.constructor.areEquals) {
        return one.constructor.areEquals(one, other);
    }

    if (one instanceof Money) {
        return moneyEquals(one, other);
    }

    if (moment.isMoment(one) && moment.isMoment(other)) {
        if (parameters.dateTimeSeconds) {
            return (one as moment.Moment).isSame(other);
        } else {
            return (one as moment.Moment).seconds(0).isSame(other.seconds(0));
        }
    }

    if (one instanceof ValueWrapper) {
        return ValueWrapper.equals(one, other);
    }

    if (one instanceof LocalDate && other instanceof LocalDate) {
        return one.equals(other);
    }

    if (parameters.checkRangeInclusions && rangeCheckPossible(one, other)) {
        return checkRangeInclusion(one, other);
    }

    Log.warn("EqualityHelper.equals couldn't compare values, so fallback value of false was returned. Values:");
    Log.warn(one);
    Log.warn(other);
    return false;
}

export function moneyEquals(one: Money, two: Money): boolean {
    return one.amount === two.amount && one.currencyCode === two.currencyCode;
}

export function getHashValue(option: any): string {
    if (option === null) {
        return "null";
    }

    if (option === undefined) {
        return "undefined";
    }

    if (typeof option === "string") {
        return option;
    }

    if (typeof option === "number") {
        return option.toString();
    }

    if (typeof option === "boolean") {
        return option.toString();
    }

    if (typeof option === "object") {
        if (option.value === undefined) {
            return "undefined";
        } else if (option.value === null) {
            return "null";
        } else if (typeof option.value === "object") {
            if ("value" in option.value && (typeof option.value.value === "string" || typeof option.value.value === "number")) {
                return option.value.value;
            } else if (option.value.getHashCode) {
                return option.value.getHashCode();
            } else if (Array.isArray(option.value)) {
                return option.value.map(getHashValue).join(",");
            } else if ("id" in option.value && option.value.id && "value" in option.value.id) {
                return option.value.id.value;
            } else {
                if (Log.isWarnLogEnabled) {
                    Log.warn(`Performance issue: getHashValue fallback to JSON.stringify. With option: ${JSON.stringify(option)}`);
                }
                return JSON.stringify(option.value);
            }
        }

        return option.value === null || option.value === undefined ? option : option.value;
    }

    throw new Error("Invalid option type: " + (typeof option));
}