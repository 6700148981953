import React from "react";
import { UniversalEnumSelector } from "@CommonControls";
import MedicalServicePriority from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalServices/Enum/MedicalServicePriority.g";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";

interface IMedicalServicePrioritySelectBoxProps extends IUniversalEnumSelectorBaseProps {
    value?: MedicalServicePriority;
    onChange?: (newValue: MedicalServicePriority) => void;
}


const MedicalServicePrioritySelectBox: React.FC<IMedicalServicePrioritySelectBoxProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"MedicalServicePriority"}
        enumOrigin="server"
        enumType={MedicalServicePriority}
        displayMode="selectBox"
    />
);

export default MedicalServicePrioritySelectBox;