import CtMrExaminationRequestTypeId from "@Primitives/CtMrExaminationRequestTypeId.g";
import EarningCapacityConsiderationTypeId from "@Primitives/EarningCapacityConsiderationTypeId.g";
import LaboratoryExaminationRequestTypeId from "@Primitives/LaboratoryExaminationRequestTypeId.g";
import PhysiotherapyExaminationRequestTypeId from "@Primitives/PhysiotherapyExaminationRequestTypeId.g";
import TravelExpenseClaimTypeId from "@Primitives/TravelExpenseClaimTypeId.g";
import ImagingExaminationRequestTypeId from "@Primitives/ImagingExaminationRequestTypeId.g";
import Money from "@Toolkit/CommonWeb/Model/Money";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityFinanceDataId from "@Primitives/CareActivityFinanceDataId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import LockingEntityStoreBase from "@Toolkit/CommonWeb/Model/LockingEntityStoreBase";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";

export default class CareActivityFinanceData extends LockingEntityStoreBase<CareActivityFinanceDataId> implements IDirtyChecked {

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }

    private _dirtyChecker: StoreDirtyChecker<CareActivityFinanceData> = new StoreDirtyChecker<CareActivityFinanceData>();

    public careActivityId: CareActivityId;

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "operationInfo",
        "validationContextStore",
    ];

    @State.observable.ref public ctrExaminationRequestTypeId: CtMrExaminationRequestTypeId = null;
    @State.observable.ref public earningCapacityConsiderationTypeId: EarningCapacityConsiderationTypeId = null;
    @State.observable.ref public laboratoryExaminationRequestTypeId: LaboratoryExaminationRequestTypeId = null;
    @State.observable.ref public physiotherapyExaminationRequestTypeId: PhysiotherapyExaminationRequestTypeId = null;
    @State.observable.ref public travelExpenseClaimTypeId: TravelExpenseClaimTypeId = null;
    @State.observable.ref public imagingExaminationRequestTypeId: ImagingExaminationRequestTypeId = null;
    @State.observable public numberOfPrescriptions: number = 0;
    @State.observable public numberOfGySePrescriptions: number = 0;
    @State.observable public numberOfTherapyPrescriptions: number = 0;
    @State.observable.ref public partiallyReimbursedCost: Money = new Money(0, "HUF");

    public static CreateFromJsonObject(source: any): CareActivityFinanceData {
        const result = new CareActivityFinanceData();

        if (!isNullOrUndefined(source)) {
            const careActivityFinanceData = source["HunSocialSecurity_CareActivityFinanceData"];

            if (!isNullOrUndefined(careActivityFinanceData)) {
                result.careActivityId = new CareActivityId(careActivityFinanceData.Id.Value);
                result.ctrExaminationRequestTypeId = CtMrExaminationRequestTypeId.fromJS(careActivityFinanceData.CtMrExaminationRequestTypeId);
                result.earningCapacityConsiderationTypeId = EarningCapacityConsiderationTypeId.fromJS(careActivityFinanceData.EarningCapacityConsiderationTypeId);
                result.laboratoryExaminationRequestTypeId = LaboratoryExaminationRequestTypeId.fromJS(careActivityFinanceData.LaboratoryExaminationRequestTypeId);
                result.physiotherapyExaminationRequestTypeId = PhysiotherapyExaminationRequestTypeId.fromJS(careActivityFinanceData.PhysiotherapyExaminationRequestTypeId);
                result.travelExpenseClaimTypeId = TravelExpenseClaimTypeId.fromJS(careActivityFinanceData.TravelExpenseClaimTypeId);
                result.imagingExaminationRequestTypeId = ImagingExaminationRequestTypeId.fromJS(careActivityFinanceData.ImagingExaminationRequestTypeId);
                result.rowVersion = RowVersion.fromJS(careActivityFinanceData.RowVersion);
                result.numberOfPrescriptions = careActivityFinanceData.NumberOfPrescriptions;
                result.numberOfGySePrescriptions = careActivityFinanceData.NumberOfGySePrescriptions;
                result.numberOfTherapyPrescriptions = careActivityFinanceData.NumberOfTherapyPrescriptions;
                result.partiallyReimbursedCost = new Money(careActivityFinanceData.PartiallyReimbursedCost?.Amount, "HUF");
            }

            result.takeSnapshot();
        }

        return result;
    }

    @State.action.bound
    public setctrExaminationRequestTypeId(newctrExaminationRequestTypeId: CtMrExaminationRequestTypeId) {
        this.ctrExaminationRequestTypeId = newctrExaminationRequestTypeId;
    }

    @State.action.bound
    public setearningCapacityConsiderationTypeId(newearningCapacityConsiderationTypeId: EarningCapacityConsiderationTypeId) {
        this.earningCapacityConsiderationTypeId = newearningCapacityConsiderationTypeId;
    }

    @State.action.bound
    public setlaboratoryExaminationRequestTypeId(newlaboratoryExaminationRequestTypeId: LaboratoryExaminationRequestTypeId) {
        this.laboratoryExaminationRequestTypeId = newlaboratoryExaminationRequestTypeId;
    }

    @State.action.bound
    public setphysiotherapyExaminationRequestTypeId(newphysiotherapyExaminationRequestTypeId: PhysiotherapyExaminationRequestTypeId) {
        this.physiotherapyExaminationRequestTypeId = newphysiotherapyExaminationRequestTypeId;
    }

    @State.action.bound
    public settravelExpenseClaimTypeId(newtravelExpenseClaimTypeId: TravelExpenseClaimTypeId) {
        this.travelExpenseClaimTypeId = newtravelExpenseClaimTypeId;
    }

    @State.action.bound
    public setimagingExaminationRequestTypeId(newimagingExaminationRequestTypeId: ImagingExaminationRequestTypeId) {
        this.imagingExaminationRequestTypeId = newimagingExaminationRequestTypeId;
    }

    @State.action.bound
    public setnumberOfPrescriptions(newnumberOfPrescriptions: number) {
        this.numberOfPrescriptions = newnumberOfPrescriptions;
    }

    @State.action.bound
    public setnumberOfGySePrescriptions(newnumberOfGySePrescriptions: number) {
        this.numberOfGySePrescriptions = newnumberOfGySePrescriptions;
    }

    @State.action.bound
    public setnumberOfTherapyPrescriptions(newnumberOfTherapyPrescriptions: number) {
        this.numberOfTherapyPrescriptions = newnumberOfTherapyPrescriptions;
    }

    @State.action.bound
    public setpartiallyReimbursedCostAmount(newAmount: number) {
        this.partiallyReimbursedCost = new Money(newAmount, this.partiallyReimbursedCost.currencyCode);
    }
}