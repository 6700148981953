import Di from "@Di";
import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import TemporalReferenceDataStore from "@Toolkit/CommonWeb/TemporalData/TemporalReferenceDataStore";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import JobPositionId from "@Primitives/JobPositionId.g";
import IExternalLocationVersion from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/External/IExternalLocation";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import ExternalApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/External/ExternalApiAdapter";
import IDoctor from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IDoctor";
import OrganizationExtensibleEnumApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/ReferenceData/OrganizationExtensibleEnumApiAdapter";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import OrganizationReferenceDataApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/ReferenceData/OrganizationReferenceDataApiAdapter";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import ExternalLocationTypeId from "@Primitives/ExternalLocationTypeId.g";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

@Di.injectable()
export default class OrganizationReferenceDataStore extends ReferenceDataStoreBase {

    public externalLocationStore = new TemporalReferenceDataStore<ExternalLocationId, IExternalLocationVersion>(
        this.externalApiAdapter.getExternalLocationVersionsBySelectorsAsync,
        this.externalApiAdapter.getAllExternalLocationIdsAsync
    );

    public allPointsOfCareMap = new ReferenceDataStore(this.structureApiAdapter.getPointsOfCareByIdsAsync, this.structureApiAdapter.getAllPointOfCareIdsAsync, "allPointsOfCareMap");
    public pointOfCareMap = new ReferenceDataStore(this.structureApiAdapter.getPointsOfCareByIdsAsync, this.structureApiAdapter.getNotDeletedPointsOfCareIdsAsync, "pointsOfCareMap");
    public practitionerMap = new ReferenceDataStore(this.practitionerApiAdapter.getPractitionersByIdsAsync);
    public assistantMap = new ReferenceDataStore(this.practitionerApiAdapter.getAssistantsByIdsAsync);
    public doctorMap = new ReferenceDataStore(this.practitionerApiAdapter.getDoctorsByIdsAsync, this.practitionerApiAdapter.getAllDoctorsAsync);
    public practitionersWithUserIdMap = new ReferenceDataStore(this.practitionerApiAdapter.getPractitionersByIdsAsync, this.practitionerApiAdapter.getPractitionerIdsWithUserIdAsync, "practitionersWithUserIdMap");
    public organizationUnitDefinitionMap = new ReferenceDataStore(this.structureApiAdapter.getOrganizationUnitDefinitionsByIdsAsync);
    public organizationUnitMap = new ReferenceDataStore(this.structureApiAdapter.getOrganizationUnitsByIdsAsync, this.structureApiAdapter.getAllIdsAsync);

    public contactType = new ExtensibleEnumStore(this.extEnumAdapter.loadContactTypeIdsAsync, this.localizationService.localizeEnumId);
    public jobPositions = new ExtensibleEnumStore<JobPositionId>(this.extEnumAdapter.loadJobPositionsAsync, this.localizationService.localizeEnumId);
    public externalLocationTypes = new ExtensibleEnumStore<ExternalLocationTypeId>(this.extEnumAdapter.loadExternalLocationsAsync, this.localizationService.localizeEnumId);

    public healthCareProfession = new ReferenceDataStore(this.structureApiAdapter.getHealthCareProfessionsByIdsAsync, this.structureApiAdapter.getAllHealthCareProfessionIdsAsync);
    public professionalExamMap = new ReferenceDataStore(this.referenceApiAdapter.getProfessionalExamsByIdsAsync, this.referenceApiAdapter.getAllProfessionalExamIds, "professionalExamMap");

    public doctorIdentifierSystemId: IdentifierSystemId = null;

    constructor(
        @Di.inject("StructureApiAdapter") private readonly structureApiAdapter: StructureApiAdapter,
        @Di.inject("PractitionerApiAdapter") private readonly practitionerApiAdapter: PractitionerApiAdapter,
        @Di.inject("ExternalApiAdapter") private readonly externalApiAdapter: ExternalApiAdapter,
        @Di.inject("OrganizationExtensibleEnumApiAdapter") private readonly extEnumAdapter: OrganizationExtensibleEnumApiAdapter,
        @Di.inject("ILocalizationService") private readonly localizationService: ILocalizationService,
        @Di.inject("OrganizationReferenceDataApiAdapter") private readonly referenceApiAdapter: OrganizationReferenceDataApiAdapter,
        @Di.inject("IReferenceDataLoader") private readonly referenceDataLoader: IReferenceDataLoader
    ) {
        super();

        referenceDataLoader.register<OrganizationUnitId>(OrganizationUnitId.typeName, ids => this.organizationUnitMap.ensureLoadedAsync(ids));
        referenceDataLoader.register<PointOfCareId>(PointOfCareId.typeName, ids => this.allPointsOfCareMap.ensureLoadedAsync(ids));
        referenceDataLoader.register<PractitionerId>(PractitionerId.typeName, async ids => { await Promise.all([this.doctorMap.ensureLoadedAsync(ids), this.practitionerMap.ensureLoadedAsync(ids), this.assistantMap.ensureLoadedAsync(ids)]); });
        referenceDataLoader.register<OrganizationUnitId>("InstituteDto", ids => this.organizationUnitMap.ensureLoadedAsync(ids));
        referenceDataLoader.register<ExternalLocationTypeId>(ExternalLocationTypeId.typeName, () => this.externalLocationTypes.ensureLoadedAsync());
        referenceDataLoader.register<ExternalLocationId>(ExternalLocationId.typeName, ids => this.externalLocationStore.ensureLoadedAsync(ids.map(i => ({ id: i, validOn: DateTimeService.today()}))));
    }

    public async ensurePractitionerIdsLoadedAsync(ids: PractitionerId[]) {
        if (!!ids) {
            await this.doctorMap.ensureLoadedAsync(ids);
        }
    }

    public async loadDoctorIdentifierSystemIdAsync() {
        if (!this.doctorIdentifierSystemId) {
            const result = await this.practitionerApiAdapter.getDoctorIdentifierSystemIdAsync();
            if (result.operationWasSuccessful) {
                this.doctorIdentifierSystemId = result.value;
            }
        }
    }

    public getDoctorById(id: PractitionerId): IDoctor {
        if (!!id) {
            return this.doctorMap.get(id);
        }
        return null;
    }
}
