import TextualPrescriptionTemplate from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/TextualPrescriptionTemplate";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import TextualPrescriptionTemplateId from "@Primitives/TextualPrescriptionTemplateId.g";
import Di from "@Di";

type ResponseType = Proxy.UpdateTextualPrescriptionTemplateCommandResponse | Proxy.GetTextualPrescriptionTemplateByIdQueryResponse | Proxy.CreateTextualPrescriptionTemplateCommandResponse | Proxy.TextualPrescriptionTemplateDto;

@Di.injectable()
export default class TextualPrescriptionTemplateStoreMapper extends EntityStoreMapper<Proxy.TextualPrescriptionTemplateDto, TextualPrescriptionTemplate, ResponseType> {
    
    protected storeEntityIdType = TextualPrescriptionTemplateId;

    protected entityDtoSelector(response: ResponseType) {
        if (response instanceof Proxy.TextualPrescriptionTemplateDto) {
            return response;
        }
        return response.textualPrescriptionTemplate;
    }

    protected applyToStoreCore(target: TextualPrescriptionTemplate, response: ResponseType): void {
        const entity = this.entityDtoSelector(response);
        target.content = entity.content;
        target.preparation = entity.preparation;
        target.displayName = entity.displayName;
        target.name = entity.name;
        target.type = entity.type;
    }
}