import IEntityVersionSelector from "./IEntityVersionSelector";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export default class TemporalReferenceDataCollector<TId extends IStringEntityId> {
    private collected: Array<IEntityVersionSelector<TId>> = [];
    private rawCollected: Set<string> = new Set<string>();

    private createKey(versionSelector: IEntityVersionSelector<TId>) {
        return versionSelector?.id?.value + "|" + versionSelector?.validOn?.stringify();
    }

    public collect(versionSelector: IEntityVersionSelector<TId>) {
        const key = this.createKey(versionSelector);
        if (versionSelector?.id?.value && !isNullOrUndefined(versionSelector.validOn) && !this.rawCollected.has(key)) {
            this.collected.push(versionSelector);
            this.rawCollected.add(key);
        }
    }

    public getCollectedVersionSelectors() {
        return this.collected;
    }

    public clear() {
        this.collected = [];
        this.rawCollected.clear();
    }
}