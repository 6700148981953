import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PrescriptionMasterDetailPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/PrescriptionListPanel/PrescriptionMasterDetailPanel";
import PrescriptionId from "@Primitives/PrescriptionId.g";
import PrescriptionStatus from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/PrescriptionStatus.g";

@State.observer
export default class ViewSubmittedPrescriptionsStandaloneHost extends React.Component<IRoutingFrameContentProps> {

    @State.observable private prescriptionId: PrescriptionId = null;

    @State.bound
    private onPrescriptionIdChange(prescriptionId: PrescriptionId) {
        this.prescriptionId = prescriptionId;
    }

    private onPrescriptionIdReset() {
        this.prescriptionId = null;
    }

    public render() {
        return (
            <PrescriptionMasterDetailPanel
                prescriptionId={this.prescriptionId}
                onBack={this.onPrescriptionIdReset}
                onNavigateToPrescription={this.onPrescriptionIdChange}
                readonly
                defaultPrescriptionStatuses={[PrescriptionStatus.Submitted]}
                hideUpdateDateWarning
            />
        );
    }
}