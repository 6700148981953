import React, { useCallback, useMemo, useEffect, useRef } from "react";
import RequestedServiceItem from "@HisPlatformControls/RequestedServiceListPanel/RequestedServiceItem";
import { LateralitySelectBox } from "@HisPlatform/BoundedContexts/Care/Components";
import StaticWebAppResources from "@StaticResources";
import { Icon } from "@CommonControls";
import IMedicalServicePanel from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServices/IMedicalServicePanel";
import Styles from "./RequestedServiceListPanel.less";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ReadOnlyContextAdapter from "@Toolkit/ReactClient/Components/ReadOnlyContext/ReadOnlyContextAdapter";

interface IRequestedServiceRowBodyProps {
    row: RequestedServiceItem;
    isReadOnly?: boolean;
    isPanel: boolean;
    isSelected: boolean;

    onSelectRow: (row: RequestedServiceItem) => void;
    onDeselectRow: (row: RequestedServiceItem) => void;
    onDeselectPanel: (panel: IMedicalServicePanel) => void;

    setTooltipReferenceAndInitialize: (reference: any, content: string) => void;
    disposeTooltip: () => void;
    index: number;
}


const RequestedServiceRowBody: React.SFC<IRequestedServiceRowBodyProps> = props => {

    const service = props.row.medicalService;

    const reference = useRef<HTMLDivElement>();

    const alternativeNameIfExists = useMemo(() => {
        return (!isNullOrUndefined(service.alternativeName) && service.alternativeName === "") ? service.name : service.alternativeName;
    }, [service]);

    const name = useMemo(() => service.name,  [service]);

    const mouseEnter = useCallback(() => {
        if (reference.current && alternativeNameIfExists !== service.name) {
            props.setTooltipReferenceAndInitialize(reference.current, service.name);
        }
    }, [props.setTooltipReferenceAndInitialize, service, alternativeNameIfExists]);

    const mouseLeave = useCallback(() => {
        props.disposeTooltip();
    }, [props.setTooltipReferenceAndInitialize, service]);

    const deselectRow = useCallback(() => {
        props.onDeselectRow(props.row);
    }, [props.onDeselectRow, props.row]);

    const selectRow = useCallback(() => {
        props.onSelectRow(props.row);
    }, [props.onSelectRow, props.row]);

    const deselectPanel = useCallback(() => {
        props.onDeselectPanel(props.row.panel);
    }, [props.onDeselectPanel, props.row]);

    const connectedServiceNames = useMemo(() => {
        return props.row.connectedServices.map(s => s.name).join(", ");
    }, [props.row]);

    const clickHandler = !props.isReadOnly ? (
        props.isSelected ? deselectRow : selectRow
    ) : undefined;

    return (
        <State.Observer>
            {() =>
                <ValidationBoundary pathPrefix={`RequestedServices[${props.row.selectionIndex}]`}>
                    <div onClick={clickHandler}>
                        <div className={Styles.rowHeaderContainer}>
                            <span className={Styles.rowHeaderTitle}>
                                <p ref={reference} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} style={{ margin: 4 }}>{name}</p>
                            </span>
                            {!props.isReadOnly && !props.isPanel && props.row.behavior === "panel-bound" && props.isSelected && <Icon iconName="link" onClick={deselectPanel} />}
                            {props.isPanel && <p className={Styles.categoryName}>{props.row.category.name}</p>}
                        </div>
                        {service.connectedServiceIds.length !== 0 && <span className={Styles.connectedServices}>{connectedServiceNames}</span>}
                    </div>
                    {service.hasLaterality && props.isSelected &&
                        <div style={{ paddingBottom: 4, paddingRight: 4 }}>
                            <LateralitySelectBox
                                label={StaticWebAppResources.SuspectedDiagnosisListPanel.Laterality}
                                value={props.row.store.lateralityId}
                                onChange={props.row.store.setLateralityId}
                                propertyIdentifier="LateralityId"
                                size="compact"
                                automationId={`service_laterality_${props.row.medicalService.code.value}`}
                            />
                        </div>
                    }
                </ValidationBoundary>
            }
        </State.Observer>
    );
};

export default connect(
    RequestedServiceRowBody,
    new ReadOnlyContextAdapter()
);