import React from "react";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { ContextAwareModal } from "@HisPlatformControls";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import StaticHunEHealthInfrastructureWebAppResources from "@HunEHealthInfrastructurePlugin/StaticResources/StaticHunEHealthInfrastructureWebAppResources";
import EhiCareService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareService";
import PatientId from "@Primitives/PatientId.g";
import EhiServiceCallStatus from "@HunEHealthInfrastructurePlugin/Model/EhiServiceCallStatus";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";

interface IEhiCareActivityListButtonsDependencies {
    applicationContext: ApplicationContext;
    notificationService: INotificationService;
}

interface IEhiCareActivityListButtonsProps {
    _dependencies?: IEhiCareActivityListButtonsDependencies;
    row: PatientsCareActivityStore;
    patientId: PatientId;
    isEmergency: boolean;
    emergencyReason: string;
    ehiCareService: EhiCareService;
}

@State.observer
class EhiCareActivityListButtons extends React.Component<IEhiCareActivityListButtonsProps> {

    @State.observable.ref private documentContent: Uint8Array = null;

    @State.action.bound
    private setDocumentContent(newValue: Uint8Array) {
        this.documentContent = newValue;
    }

    @State.action.bound
    private unsetDocumentContent() {
        this.documentContent = null;
    }

    @State.computed
    private get organizationUnitId() {
        return this.props._dependencies.applicationContext.contextualOrganizationUnitId;
    }

    @State.bound
    private async loadDocumentAsync() {
        if (!this.organizationUnitId) {
            return Promise.resolve();
        }
        const ehiServiceCallResult = await this.props.ehiCareService.loadEhiCareDocumentAsync(
            this.props.patientId,
            this.ehiDocumentIdentifier,
            this.props.isEmergency,
            this.props.emergencyReason);

        if (ehiServiceCallResult.ehiServiceCallStatus === EhiServiceCallStatus.Success &&
            ehiServiceCallResult.result &&
            ehiServiceCallResult.result.operationWasSuccessful &&
            ehiServiceCallResult.result.value) {

            this.setDocumentContent(ehiServiceCallResult.result.value.value);
        } else {
            this.props._dependencies.notificationService.error(StaticHunEHealthInfrastructureWebAppResources.UnableToLoadDocument);
        }
    }

    private ehiDocumentIdentifierFieldName = "FirstDocumentEhiIdentifier";
    private ehiDocumentVersionFieldName = "FirstDocumentVersion";

    private get ehiDocumentIdentifier() {
        return this.props.row && this.props.row.extensionData && this.props.row.extensionData[this.ehiDocumentIdentifierFieldName];
    }

    private get ehiDocumentVersion() {
        return this.props.row && this.props.row.extensionData && this.props.row.extensionData[this.ehiDocumentVersionFieldName];
    }

    public render() {
        return this.ehiDocumentIdentifier && this.ehiDocumentVersion && (
            <>
                <Ui.Button
                    iconName="document"
                    onClickAsync={this.loadDocumentAsync}
                    size="compact"
                    stopPropagation={{ leftClick: true }}
                    style={{ marginRight: "8px" }}
                    automationId="loadDocumentButton"
                />
                <ContextAwareModal isOpen={!!this.documentContent} onClose={this.unsetDocumentContent} closeOnOutsideClick={false}>
                    <Ui.Modal.Body noGap>
                        <Ui.ScrollView>
                            <Ui.PdfViewer binaryPdf={this.documentContent} />
                        </Ui.ScrollView>
                    </Ui.Modal.Body>
                </ContextAwareModal>
            </>
        ) || "";
    }
}

export default connect(
    EhiCareActivityListButtons,
    new DependencyAdapter<IEhiCareActivityListButtonsProps, IEhiCareActivityListButtonsDependencies>(container => {
        return {
            applicationContext: container.resolve("ApplicationContext"),
            notificationService: container.resolve("INotificationService")
        };
    })
);