import React from "react";
import styles from "./WorkflowStep.less";
import Icon from "@CommonControls/Icon";
import { Tooltip, ContentAlignment } from "@Toolkit/ReactClient/Components/Tooltip";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export interface IWorkflowStepProps {
    number: number;
    title: string;
    type?: "correct" | "incorrect" | "inactive" | "notvalidated";
    onStepSelected?: (selected: IWorkflowStepProps) => void;
    automationId: string;
    isSelected: boolean;
    tooltipContent?: string;
    tooltipTextAlign: ContentAlignment;
}

export default class WorkflowStep extends React.PureComponent<IWorkflowStepProps> {

    private getContainerStyle() {

        if (this.props.type === "inactive") {
            return styles.containerInactive;
        }

        if (this.props.isSelected) {
            return styles.containerSelected;
        }

        return styles.container;
    }

    private getTextStyle() {
        if (this.props.type === "inactive") {
            return styles.stepTextInactive;
        }
        if (this.props.isSelected) {
            return styles.stepTextSelected;
        }
        return styles.stepText;
    }

    private getLineStyle() {

        switch (this.props.type) {
            case "correct":
                return styles.svgLineCorrect;
            case "incorrect":
                return styles.svgLineIncorrect;
            case "inactive":
                return styles.svgLineInactive;
            case "notvalidated":
            default:
                return styles.svgLineUnselected;
        }
    }

    private getCircleStyle() {
        switch (this.props.type) {
            case "correct":
                return styles.svgCircleCorrect;
            case "incorrect":
                return styles.svgCircleIncorrect;
            case "inactive":
                return styles.svgCircleInactive;
            case "notvalidated":
            default:
                return styles.svgCircleUnselected;
        }
    }

    private getNumber() {
        switch (this.props.type) {
            case "correct":
                return (
                    <Icon iconName="check" visualStyle="white" className={styles.numberIcon} />);
            case "incorrect":
                return (
                    <Icon iconName="exclamation" visualStyle="white" className={styles.numberIcon} />);
            default:
                return this.props.number;
        }
    }

    @State.bound
    private onClick() {
        if (this.props.onStepSelected) {
            this.props.onStepSelected(this.props);
        }
    }

    public render() {
        return (
            <Tooltip content={this.props.tooltipContent} placement="bottom" contentAlignment={this.props.tooltipTextAlign}>
                <div
                    className={this.getContainerStyle()}
                    onClick={this.onClick} data-automation-id={this.props.automationId || undefined}
                >
                    <div className={styles.numberDiv}>
                        <div className={styles.numberText}>
                            {this.getNumber()}
                        </div>
                    </div>
                    <svg className={styles.svgContainer} >
                        <svg x="8" y="0" width="100%" height="100%">
                            <line x1="8" y1="18" x2="100%" y2="18" className={this.getLineStyle()} />
                        </svg >
                        <circle cx="18px" cy="18px" r="10px" className={this.getCircleStyle()} />
                    </svg>
                    <span className={this.getTextStyle()}>
                        {this.props.title}
                    </span>
                </div>
            </Tooltip>
        );
    }
}