import Button from "@CommonControls/Button";
import Flex from "@CommonControls/Flex";
import FlexItem, { width } from "@CommonControls/Flex/FlexItem";
import SelectBox from "@CommonControls/SelectBox";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import CareActivityId from "@Primitives/CareActivityId.g";
import PatientId from "@Primitives/PatientId.g";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React from "react";
import { provideEpisodeOfCareSelectBoxStore, useEpisodeOfCareSelectBoxStore } from "./EpisodeOfCareSelectBoxStoreProvider";

export interface IEpisodeOfCareSelectBoxProps extends ISelectBoxBaseProps {
    patientId: PatientId;
    careActivityId?: CareActivityId;
    hasAddNew: boolean;
    width?: width;
}

function EpisodeOfCareSelectBox(props: IEpisodeOfCareSelectBoxProps) {

    const store = useEpisodeOfCareSelectBoxStore();

    return (
        <FlexItem xs={props.width ?? 12}>
            <SelectBox
                {...props}
                label={StaticCareResources.EpisodeOfCare.Labels.Episodes}
                items={store.selectableEpisodesOfCare}
                newItemText={StaticCareResources.EpisodeOfCare.Titles.NewEpisodeOfCare}
                onNewItemClick={props.hasAddNew && store.onAddNewEpisodeAsync}

            />
        </FlexItem>
    );
}

export default connect<IEpisodeOfCareSelectBoxProps>(provideEpisodeOfCareSelectBoxStore(EpisodeOfCareSelectBox));