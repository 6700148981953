import PropertyGroupDefinitionId from "@Primitives/PropertyGroupDefinitionId.g";
import ScalarProperty from "./ScalarProperty";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import PropertyGroupId from "@Primitives/PropertyGroupId.g";

/* For simplicity's sake, this class represents both PropertyGroup and PropertyGroupDefinition classes from server. */
export default class PropertyGroup extends EntityStoreBase<PropertyGroupId> implements IDirtyChecked  {

    private _dirtyChecker = new StoreDirtyChecker();

    public definitionId: PropertyGroupDefinitionId = null;
    public name: string;

    @State.computed public get isVisible() {
        return this.properties.some(x => x.isVisible);
    }

    @State.observable.ref public properties: ScalarProperty[] = [];

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "isVisible",
        "operationInfo",
        "definitionId"
    ];
    
    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }
}