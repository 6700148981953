import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import * as HisUi from "@HisPlatformControls";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import HisUseCaseIdentifierSetter from "@HisPlatform/Components/HisUseCaseIdentifierSetter";
import * as Ui from "@CommonControls";
import * as Styles from "./StandaloneUnregisteredPatientAppointmentPage.less";
import UnregisteredPatientAppointmentPanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/UnregisteredPatientAppointmentPanel/UnregisteredPatientAppointmentPanel";
import PatientId from "@Primitives/PatientId.g";
import PatientAppointmentRoutes from "@HisPlatform/Components/Pages/Patient/PatientAppointmentRoutes";
import { getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

interface IStandaloneUnregisteredPatientAppointmentPageProps extends IRoutingFrameContentProps {
}

@State.observer
class StandaloneUnregisteredPatientAppointmentPage extends React.Component<IStandaloneUnregisteredPatientAppointmentPageProps> {

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setPatientId(id: PatientId) {
        this.props.routingController.replace(
            PatientAppointmentRoutes.appointmentList.makeRoute({
                patientId: id.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.patientRegisterViewAppointments),
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument(new AppointmentScheduleEntryId("null"), "appointmentId")])
            })
        );
    }
    public render() {
        return (
                <HisUi.DashboardLayout>
                    <HisUi.HisErrorBoundary>
                        <LoadingBoundary>
                            <HisUseCaseIdentifierSetter value={"UnregisteredPatientAppointment"} />
                            <Ui.ToolbarLayout
                                bodyClassName={Styles.toolbarWorkingArea}
                                body={ <UnregisteredPatientAppointmentPanel
                                    onPatientIdChanged={this.setPatientId}
                                    onBack={this.goBack}
                                />}
                                bottomToolbar={<HisUi.HisPanelButtonPortal.Host style={{ width: "100%" }} />}
                            />
                        </LoadingBoundary>
                    </HisUi.HisErrorBoundary>
                </HisUi.DashboardLayout>
        );
    }
}

export default connect(
    StandaloneUnregisteredPatientAppointmentPage
);
