import ExtendedFilterStoreBase from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ExtendedFilterStoreBase";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import PractitionerId from "@Primitives/PractitionerId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { createFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterHelpers";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

export default class PractitionerScheduleEntryFilterStore extends ExtendedFilterStoreBase {
    constructor(filterStore: IFilterStore) {
        super(filterStore);
    }

    @State.action.bound
    public setPractitionerId(value: PractitionerId) {
        this.setFilter("PractitionerScheduleEntryBased_Practitioner", value);
    }

    @State.action.bound
    public setDateInterval(value: LocalDateRange) {
        this.setFilter("PractitionerScheduleEntryBased_DateInterval", value);
    }

    public static getFilterDescriptors() {
        const descriptors: IDataGridFilterDescriptor[] = [];

        const practitionerIdFilterDescriptor = createFilterDescriptor(
            "PractitionerScheduleEntryBased_Practitioner",
            null,
            WorklistConditionType.EntityId
        );

        descriptors.push(practitionerIdFilterDescriptor);
        return descriptors;
    }
}