import CustomBlockSettingsBase, { ICustomBlockSettingsBase } from "./CustomBlockSettingsBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export interface IEmbeddedFormBlockSettings extends ICustomBlockSettingsBase {
    dataReference: string;
}

export default class EmbeddedFormBlockSettings extends CustomBlockSettingsBase implements IEmbeddedFormBlockSettings {
    
    @State.observable.ref public dataReference: string;

    @State.action.bound
    public setDataReference(dataReference: string) {
        this.dataReference = dataReference;
    }

    public clone(): CustomBlockSettingsBase {
        const res = new EmbeddedFormBlockSettings();
        res.dataReference = this.dataReference;
        return res;
    }
}