exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PropertyVersionModal_first-row_3HkQv {\n  margin-top: 10px;\n  background: #82d4ed;\n  height: max-content;\n  width: 100%;\n  padding: 6px 10px;\n}\n.PropertyVersionModal_margin-right_3TTz7 {\n  margin-right: 16px;\n}\n.PropertyVersionModal_center-text_2uiHE {\n  text-align: center;\n}\n.PropertyVersionModal_right-text_21w54 {\n  text-align: right;\n}\n", ""]);

// exports
exports.locals = {
	"first-row": "PropertyVersionModal_first-row_3HkQv",
	"firstRow": "PropertyVersionModal_first-row_3HkQv",
	"margin-right": "PropertyVersionModal_margin-right_3TTz7",
	"marginRight": "PropertyVersionModal_margin-right_3TTz7",
	"center-text": "PropertyVersionModal_center-text_2uiHE",
	"centerText": "PropertyVersionModal_center-text_2uiHE",
	"right-text": "PropertyVersionModal_right-text_21w54",
	"rightText": "PropertyVersionModal_right-text_21w54"
};