import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IAutonomyDisabilityHintItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IAutonomyDisabilityHintItem";
import AutonomyDisabilityId from "@Primitives/AutonomyDisabilityId.g";
import IAutonomyDisability from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IAutonomyDisability";
import ComplexSearchAutonomyDisabilitiesQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/AutonomyDisabilities/Enum/ComplexSearchAutonomyDisabilitiesQueryOrderingFields.g";

@Di.injectable()
export default class AutonomyDisabilitiesApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IAutonomyDisabilitiesClient") private readonly apiClient: Proxy.IAutonomyDisabilitiesClient
    ) {
        super();
    }

    public searchAutonomyDisabilities(freeText: string, maxResultCount: number) {
        return this.processOperationAsync(
            new SimpleStore<IAutonomyDisabilityHintItem[]>(),
            async target => {
                const response = await this.apiClient.searchAutonomyDisabilitiesQueryAsync(
                    CreateRequestId(),
                    freeText,
                    maxResultCount.toString()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.autonomyDisabilities.map(ai => {
                    return {
                        id: ai.id,
                        code: ai.code,
                        name: ai.name
                    } as IAutonomyDisabilityHintItem;
                });
            }
        );
    }

    public getAllIdsAsync(): Promise<SimpleStore<AutonomyDisabilityId[]>> {
        return this.processOperationAsync(
            new SimpleStore<AutonomyDisabilityId[]>(),
            async target => {
                const response = await this.apiClient.getAutonomyDisabilityIdsQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.ids.map(id => id);
            }
        );
    }

    @State.bound
    public loadAutonomyDisabilitiesAsync(ids: AutonomyDisabilityId[]): Promise<SimpleStore<IAutonomyDisability[]>> {
        return this.processOperationAsync(
            new SimpleStore<IAutonomyDisability[]>(),
            async (target) => {
                const response = await this.apiClient.getAutonomyDisabilitiesByIdsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetAutonomyDisabilitiesByIdsControllerDto({ ids: ids })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.autonomyDisabilities.map(this.mapAutonomyDisability);
            }
        );
    }

    private mapAutonomyDisability(ai: Proxy.AutonomyDisabilityDto): IAutonomyDisability {
        return {
            id: ai.id,
            code: ai.code,
            name: ai.name
        } as IAutonomyDisability;
    }

    @State.bound
    public complexSearchAutonomyDisabilities(
        freeText: string,
        ordering: IOrderingState,
        paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<IAutonomyDisability>>(),
            async (target) => {
                const response = await this.apiClient.complexSearchAutonomyDisabilitiesQueryAsync(
                    CreateRequestId(),
                    new Proxy.ComplexSearchAutonomyDisabilitiesControllerDto({
                        freeText: freeText,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfComplexSearchAutonomyDisabilitiesQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfComplexSearchAutonomyDisabilitiesQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: ComplexSearchAutonomyDisabilitiesQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results && response.results.values.map(this.mapAutonomyDisability)
                };

            }
        );
    }
}
