import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import React, { useMemo } from "react";
import ScreenRenderer from "./ScreenRenderer";
import parseScreenStateFromUrl from "./ShowScreenActionHostHelper";

interface IShowScreenActionHostProps {
    screenRaw?: string;
    isUriDecoded?: boolean;

    onClose: () => void;
    onSavedExisting?: () => void;
    onSavedNew?: (newId: StringEntityId) => void;
    onCancelled?: () => void;
    onDeleted?: () => void;
}

export default function ShowScreenActionHost(props: IShowScreenActionHostProps) {

    const { screenState, action } = useMemo(() => isNullOrEmptyString(props.screenRaw) ? { action: null, screenState: null } : parseScreenStateFromUrl(props.screenRaw, props.isUriDecoded), [props.screenRaw, props.isUriDecoded]);

    if (!action) {
        return null;
    }

    return (
        <ScreenRenderer
            action={action}
            screenState={screenState}
            onClose={props.onClose}
            onCancelled={props.onCancelled}
            onSavedExisting={props.onSavedExisting}
            onSavedNew={props.onSavedNew}
            onDeleted={props.onDeleted}
        />
    );
}