import React from "react";
import Measure, { ContentRect } from "react-measure";

export interface ISize {
    width: number;
    height: number;
}

interface IAutoSizerProps extends React.HTMLAttributes<HTMLDivElement> {
    onResize?: (size: ISize) => void;
    onRender?: (size: ISize) => React.ReactNode;
}

const getDivProps = (props: IAutoSizerProps) => {
    const ret = { ...props };
    delete ret.onResize;
    delete ret.onRender;
    return ret;
};

const resizeHandler = (props: IAutoSizerProps) => {
    return (rect: ContentRect) => {
        if (props.onResize) {
            props.onResize(rect.bounds as ISize);
        }
    };
};


const SizeMeasurer: React.SFC<IAutoSizerProps> = props => (
    <Measure
        bounds
        onResize={resizeHandler(props)}
    >
        {({ measureRef, contentRect }) => (
            <div {...getDivProps(props)} ref={measureRef}>{props.onRender ? props.onRender(contentRect.bounds as ISize) : props.children}</div>
        )}
    </Measure>
);

export default SizeMeasurer;