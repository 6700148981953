import React from "react";
import * as Ui from "@CommonControls";
import Prescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Prescription";
import MedicationVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/MedicationVersion";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import MedicationSubsidy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationSubsidy";
import ExtendedDosageEditor from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DosageEditor/ExtendedDosageEditor";
import SingleMedicationReference from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/SingleMedicationReference";
import PackagingUnitSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PackagingUnitSelector/PackagingUnitSelector";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import SubstanceBasedRestrictionId from "@Primitives/SubstanceBasedRestrictionId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface ISingleMedicationReferenceDetailView {
    prescription: Prescription;
    medication: MedicationVersion;
    selectedSubsidy: MedicationSubsidy;
    daysSupplied: number;
    daysSuppliedBlur: () => void;
    substanceBasedRestrictionId: SubstanceBasedRestrictionId;
    setIsUnpackaged: (isUnpackaged: boolean) => void;
}


const SingleMedicationReferenceDetailView: React.FC<ISingleMedicationReferenceDetailView> = props => {
    const resources = StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail;
    const singleMedicationReference = props.prescription.medicationReferenceBase as SingleMedicationReference;
    return (
        <>
            <Ui.Flex>
                <Ui.Flex.Item xs={9}>
                    <p style={{ fontWeight: "bold", marginLeft: "4px" }}>{props.medication?.name}</p>
                </Ui.Flex.Item>
                <Ui.Flex.Item style={{ alignSelf: "center" }} xs={3} >
                    <Ui.CheckBox
                        value={!props.prescription.isSubstitubable}
                        onChange={props.prescription.setIsSubstitubable}
                        label={resources.Labels.IsSubstitubable}
                        labelPosition="right"
                        automationId="isSubstituableCheckBox" />
                </Ui.Flex.Item>
                {!props.prescription.isSubstitubable &&
                    <Ui.Flex.Item xs={12}>
                        <Ui.TextBox
                            value={props.prescription.insubstitutableReason}
                            onChange={props.prescription.setInsubstitutableReason}
                            label={resources.Labels.InsubstitutableReason}
                            isReadOnly={props.prescription.isSubstitubable}
                            propertyIdentifier="InsubstitutableReason"
                            automationId="insubstitutableReasonTextBox" />
                    </Ui.Flex.Item>}
            </Ui.Flex>
            <Ui.GroupBox title={resources.GroupTitles.QuantitativeData}>
                <Ui.Flex>
                    <Ui.Flex.Item xs={3}>
                        <PackagingUnitSelector
                            value={singleMedicationReference.packagingUnitId}
                            onChange={singleMedicationReference.setPackagingUnitId}
                            label={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.Labels.PackagingUnit}
                            propertyIdentifier="MedicationReference.PackagingUnitId"
                            automationId="packagingUnitSelector"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={2}>
                        <Ui.NumberBox
                            value={singleMedicationReference.amount}
                            nullValue={0}
                            onChange={singleMedicationReference.setAmount}
                            integerOnly
                            label={resources.Labels.Amount}
                            propertyIdentifier="MedicationReference.Amount"
                            automationId="quantityNumberBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item style={{ paddingTop: 19 }} xs={2}>
                        <Ui.CheckBox
                            value={singleMedicationReference.isUnpackaged}
                            onChange={props.setIsUnpackaged}
                            label={resources.Labels.IsUnpackaged}
                            disabled={!isNullOrUndefined(props.substanceBasedRestrictionId)}
                            labelPosition="right"
                            propertyIdentifier="MedicationReference.IsUnpackaged"
                            automationId="isUnpackagedCheckBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={2}>
                        <Ui.NumberBox
                            value={props.daysSupplied}
                            nullValue={0}
                            onChange={singleMedicationReference.setDaysSupplied}
                            onBlur={props.daysSuppliedBlur}
                            integerOnly
                            disabled={!singleMedicationReference.isDaysSuppliedFilledManually}
                            label={resources.Labels.DaysSupplied}
                            propertyIdentifier="MedicationReference.DaysSupplied"
                            forceShowValidationResults
                            automationId="daysNumberBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item style={{ paddingTop: 19 }} xs={3}>
                        <Ui.CheckBox
                            value={singleMedicationReference.isDaysSuppliedFilledManually}
                            onChange={singleMedicationReference.setIsDaysSuppliedFilledManually}
                            disabled={!props.medication?.daysSuppliedCanBeCalculated}
                            label={resources.Labels.DaysSuppliedFilledManually}
                            labelPosition="right"
                            automationId="isDaysSuppliedFilledManuallyCheckBox" />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>
            <Ui.GroupBox title={resources.GroupTitles.Dosage}>
                <ExtendedDosageEditor
                    dosage={singleMedicationReference.dosage}
                    setDosage={singleMedicationReference.setDosage}
                    inSubstitutableReason={props.prescription.insubstitutableReason}
                    setInSubstitutableReason={props.prescription.setInsubstitutableReason}
                    instruction={props.prescription.instruction}
                    setInstruction={props.prescription.setInstruction}
                    isSubstitutable={props.prescription.isSubstitubable}
                    setIsSubstitutable={props.prescription.setIsSubstitubable}
                    showReason={true}
                    showEquippedAndTextualDosage={false}
                />
            </Ui.GroupBox>
        </>
    );
};
export default State.observer(SingleMedicationReferenceDetailView);