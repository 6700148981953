export default class ReleaseInfoStore {
    private static releaseInfo: IReleaseInfo;
    public static async getReleaseInfoAsync() {
        if (ReleaseInfoStore.releaseInfo === undefined) {
            try {
                const json = await fetch("/api/configuration/releaseInfo");
                ReleaseInfoStore.releaseInfo = await json.json();
            } catch (ex) {
                ReleaseInfoStore.releaseInfo = {
                    SetupKitBuildNumber: "N/A",
                    BackendBuildNumber: "N/A",
                    WebAppBuildNumber: "N/A",
                    JiraTickets: [],
                    TfsChanges: []
                };
            }
        }
        return ReleaseInfoStore.releaseInfo;
    }
}

export interface IReleaseInfo {
    SetupKitBuildNumber: string;
    BackendBuildNumber: string;
    WebAppBuildNumber: string;
    JiraTickets: IJiraTicket[];
    TfsChanges: ITfsChange[];
}

export interface IJiraTicket {
    key: string;
    fields: {
        issuetype: {
            name: string;
            subtask: boolean;
        };
        status: {
            name: string;
        };
        description: string;
        summary: string;
    };
}

export interface ITfsChange {
    id: string;
    message: string;
    type: string;
    author: {
        displayName: string;
        id: string;
        uniqueName: string;
    };
    timestamp: string;
}