import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";
import ModalServiceReactContext from "@Toolkit/ReactClient/Components/ModalService/ModalServiceContext";
import ModalContextStore from "@Toolkit/ReactClient/Components/ModalService/ModalContextStore";
import { emptyObject } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";

export default class ModalServiceAdapter<TProps extends { _modalService?: IModalService }> implements IComponentAdapter<TProps> {

    public get usedContexts(): Array<React.Context<any>> {
        return [ModalServiceReactContext];
    }

    public getMappedProps(props: TProps, contextValues: Map<React.Context<any>, any>): Partial<TProps> {
        const store = contextValues.get(ModalServiceReactContext) as ModalContextStore;
        return {
            _modalService: store as IModalService
        } as Partial<TProps>;
    }

}