import React from "react";
import ReactLocalizedStrings from "react-localization";

export type Formatted = number | string | JSX.Element;
export interface IFormatObject<U extends Formatted> { [key: string]: U; }
export type StaticResourcesDictionary<T> = T & { load: (dictionary: T) => void };

export function createStaticResourceDictionary<T>(): StaticResourcesDictionary<T> {

    const strings: any = new ReactLocalizedStrings({ main: {} });

    strings.load = (dictionary: T) => {
        strings.setContent({ main: dictionary });
        strings.setLanguage("main");
    };

    return strings;
}

const helperInstance = new ReactLocalizedStrings({ main: {} });
export function formatReactString(str: string, ...values: any[]): React.ReactNode {
    return helperInstance.formatString(str, ...values);
}

export function formatReactStringWithLineBreaks(str: string, ...values: any[]): React.ReactNode {
    return formatReactString(str, ...[{"NL": <br/>}, ...values]);
}