import {IModalComponentParams} from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import {IExternalPrescriptionDetailModalParams} from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/ExternalMedicationDetailModal/ExternalPrescriptionDetailModalParams";
import React from "react";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import StaticWebAppResources from "@StaticResources";
import ExternalPrescriptionDetailViewSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/ExternalPrescriptionDetailView/ExternalPrescriptionDetailViewSelector";

interface IExternalPrescriptionDetailModalProps extends IModalComponentParams, IExternalPrescriptionDetailModalParams {
}

export default class ExternalPrescriptionDetailModal extends React.Component<IExternalPrescriptionDetailModalProps> {
    private get resources() {
        return StaticHunSocialSecurityMedicationRequestResources.ExternalPrescriptionDetailModal;
    }

    public render() {
        return (
            <HisUi.ContextAwareModal
                title={this.resources.Title}
                isOpen
                onClose={this.onClose}
                size={"normal"}>
                <Ui.Modal.Body>
                    <ExternalPrescriptionDetailViewSelector
                        externalPrescription={this.props.externalPrescription}
                    />
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Button onClick={this.onClose} visualStyle={"flat"} text={StaticWebAppResources.Common.Button.Back} float={"right"} automationId="closeButton"/>
                </Ui.Modal.Footer>
            </HisUi.ContextAwareModal>
        );
    }
    
    @State.bound
    private onClose() {
        this.props.onClose(null);
    }
}