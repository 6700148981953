import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import ShowAppointmentScheduleDefinitionsScreenAction from "@HisPlatform/Packages/Scheduling/FrontendActions/ShowAppointmentScheduleDefinitionsScreenAction.g";
import ShowInvalidAppointmentsScreenAction from "@HisPlatform/Packages/Scheduling/FrontendActions/ShowInvalidAppointmentsScreenAction.g";
import ShowPractitionerAppointmentsScreenAction from "@HisPlatform/Packages/Scheduling/FrontendActions/ShowPractitionerAppointmentsScreenAction.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import NavMenuActionItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuActionItem";
import NavMenuGroupItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuGroupItem";

export function getNavigationMenuItems(): INavMenuItem[] {
    
    const leftTopLevelMenuItems: INavMenuItem[] = [
        NavMenuGroupItem.createTopLevel(
            "Scheduling",
            {
                side: "left",
                automationId: "scheduling",
                order: 4,
                iconNameType: "appointments"
            }
        )
    ];
    
    const rightTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const childMenuItems: INavMenuItem[] = [
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "PractitionerAppointments",
            "Scheduling",
            new ShowPractitionerAppointmentsScreenAction(),
            "ViewPractitionerAppointments, ViewPractitionerBlockings",
            {
                automationId: "practitionerSchedules",
                text: StaticWebAppResources.Navbar.Scheduling.PractitionerSchedules,
                order: 1
            }
        ),
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "InvalidAppointments",
            "Scheduling",
            new ShowInvalidAppointmentsScreenAction(),
            "ViewInvalidAppointments",
            {
                automationId: "invalidAppointments",
                text: StaticWebAppResources.Navbar.Scheduling.InvalidAppointments,
                order: 2
            }
        ),
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "AppointmentScheduleDefinitions",
            "Settings",
            new ShowAppointmentScheduleDefinitionsScreenAction(),
            "ViewSchedules",
            {
                automationId: "appointmentScheduleDefinitions",
                text: StaticSchedulingResources.AppointmentSchedulesDefinitionPage.NavBarTitle,
                order: 10
            }
        )
    ];

    return [...leftTopLevelMenuItems, ...rightTopLevelMenuItems , ...childMenuItems];
}