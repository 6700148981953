import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import ExternalApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/External/ExternalApiAdapter";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import * as HisUi from "@HisPlatformControls";
import IExternalLocationVersion from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/External/IExternalLocation";
import StaticWebAppResources from "@StaticResources";
import {IOrderingState, IPagingState} from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import {IUniversalCodeSelectorProps} from "@HisPlatformControls/UniversalCodeSelector";
import EntityCollectionOwner from "@HisPlatform/BoundedContexts/Productivity/Api/Personalization/Enum/EntityCollectionOwner.g";

interface IExternalLocationCodeSelectorDependencies {
    externalApiAdapter: ExternalApiAdapter;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IExternalLocationCodeSelectorProps extends ICodeSelectorCommonProps<ExternalLocationId> {
    _dependencies?: IExternalLocationCodeSelectorDependencies;
    externalLocationIds?: ExternalLocationId[];
    autoFillSingleItem?: boolean;
    showFavoritesAndGroup?: boolean;
    pointOfCareId?: PointOfCareId;
}

@State.observer
class ExternalLocationCodeSelector extends React.Component<IExternalLocationCodeSelectorProps> {

    private get dependencies() {
        return this.props._dependencies;
    }

    @State.computed
    private get autoFillNeeded(): boolean {
        return this.props.externalLocationIds &&
            this.props.autoFillSingleItem &&
            this.props.externalLocationIds.length === 1;
    }

    @State.bound
    private async getDisplayValueAsync(value: ExternalLocationId) {
        const item = await this.dependencies.organizationReferenceDataStore.externalLocationStore.getOrLoadAsync({
            id: value,
            validOn: this.props.validOn
        } as IEntityVersionSelector<ExternalLocationId>);

        return <><b>{item.identifierAssignments[0].identifier.value}</b>{` ${item.name}`}</>;
    }

    @State.bound
    private async getTextValueAsync(value: ExternalLocationId) {
        const item = await this.dependencies.organizationReferenceDataStore.externalLocationStore.getOrLoadAsync({
            id: value,
            validOn: this.props.validOn
        } as IEntityVersionSelector<ExternalLocationId>);

        return item.identifierAssignments[0].identifier.value + " " + item.name;
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const result = await this.dependencies.externalApiAdapter.searchExternalLocationVersionSelectorsByTextAsync(
            text,
            10,
            this.props.validOn,
            this.props.externalLocationIds
        );
        if (result.operationWasSuccessful) {
            await this.dependencies.organizationReferenceDataStore.externalLocationStore.ensureLoadedAsync(result.value);
            return result.value.map(x => x.id);
        }
        return [];
    }

    @State.bound
    public componentDidMount() {
        if (this.autoFillNeeded) {
            this.props.onChange(this.props.externalLocationIds[0]);
        }
    }

    @State.bound
    public componentDidUpdate(prevProps: IExternalLocationCodeSelectorProps) {
        if ((prevProps.externalLocationIds !== this.props.externalLocationIds ||
            prevProps.autoFillSingleItem !== this.props.autoFillSingleItem) &&
            this.autoFillNeeded) {
            this.props.onChange(this.props.externalLocationIds[0]);
        }
    }

    @State.bound
    private getDisplayValue(id: ExternalLocationId) {
        const item = this.dependencies.organizationReferenceDataStore.externalLocationStore.get({
            id: id,
            validOn: LocalDate.today()
        });
        if (!item) {
            return null;
        }
        return (
            <>
                <b>{item.identifierAssignments.length && item.identifierAssignments[0]?.identifier.value}</b> {item.name}
            </>
        );
    }

    @State.bound
    private getComplexSearchEntitiesByIds(ids: ExternalLocationId[]) {
        const selectors = this.mapToVersionSelectors(ids);
        return this.dependencies.organizationReferenceDataStore.externalLocationStore.getAll(selectors);
    }

    @State.bound
    private onComplexSearchSingleSelect(item: IExternalLocationVersion) {
        this.props.onChange(item.id);
    }

    @State.bound
    private onComplexSearchMultiSelect(items: IExternalLocationVersion[]) {
        const selectors = items.map(item => item.id);
        this.props.onChange(selectors);
    }

    private mapToVersionSelectors(ids: ExternalLocationId[]) {
        return ids.map(id => ({
            id,
            validOn: LocalDate.today()
        } as IEntityVersionSelector<ExternalLocationId>));
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: IExternalLocationVersion[]): Promise<IPagedItems<IExternalLocationVersion>> {
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;
        const results = await this.dependencies.externalApiAdapter.searchExternalLocation(filterText, LocalDate.today(), customOrdering, paging, this.props.externalLocationIds);

        return results.value;
    }

    private get entityCollectionProps(): Partial<IUniversalCodeSelectorProps<ExternalLocationId, IExternalLocationVersion>> {
        return this.props.showFavoritesAndGroup && {
            entityName: "ExternalLocation",
            ownerType: EntityCollectionOwner.PointOfCare,
            ownerId: this.props.pointOfCareId,
            currentPointOfCareId: this.props.pointOfCareId,
            getEntityDisplayValue: this.getDisplayValue,
            getEntityId: (id: ExternalLocationId) => id
        };
    }

    public render() {
        return (
            <HisUi.UniversalCodeSelector
                {...this.props}
                getDisplayValueAsync={this.getDisplayValueAsync}
                getTextValueAsync={this.getTextValueAsync}
                onQuickSearchAsync={this.quickSearchAsync}
                hasComplexSearch
                complexSearchLoadAsync={this.complexSearchLoadAsync}
                complexSearchModalTitle={StaticWebAppResources.Selectors.ExternalLocationSelector.Title}
                codeGetter="identifierAssignments[0].identifier.value"
                nameGetter={"name"}
                getComplexSearchEntitiesByIds={this.getComplexSearchEntitiesByIds}
                onComplexSearchSingleSelect={this.onComplexSearchSingleSelect}
                onComplexSearchMultiSelect={this.onComplexSearchMultiSelect}
                showMenuSections={this.props.showFavoritesAndGroup}
                {...this.entityCollectionProps}
                isReadOnly={this.props.isReadOnly || this.autoFillNeeded}
            />
        );
    }
}

export default connect(
    ExternalLocationCodeSelector,
    new DependencyAdapter<IExternalLocationCodeSelectorProps, IExternalLocationCodeSelectorDependencies>((container) => {
        return {
            externalApiAdapter: container.resolve("ExternalApiAdapter"),
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore")
        };
    })
);
