import { IPrescriptionsToBeCreatedListItem } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionsToBeCreated/IPrescriptionsToBeCreatedListItem";
import MedicationId from "@Primitives/MedicationId.g";
import TextualPrescriptionTemplateId from "@Primitives/TextualPrescriptionTemplateId.g";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";

export class MedicationBasedPrescriptionsToBeCreatedListItem implements IPrescriptionsToBeCreatedListItem {
    constructor(
        public readonly name: string,
        public readonly claimType: MedicationSubsidyClaimTypeId,
        public readonly medicationId: MedicationId,
        public readonly templateId: TextualPrescriptionTemplateId,
        public readonly isMedicalFormula: boolean
    ) {
    }
}
