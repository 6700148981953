import State from "@Toolkit/ReactClient/Common/StateManaging";
import TherapyAssignmentBase from "./TherapyAssignmentBase";
import TherapyId from "@Primitives/TherapyId.g";

export default class ReferencedTherapyAssignment extends TherapyAssignmentBase  {
    @State.observable.ref public therapyId: TherapyId = null;

    constructor(therapyId: TherapyId) {
        super();
        this.therapyId = therapyId;
    }
}