import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/Proxy.g";
import Di from "@Di";
import NeakErrorList from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/NeakErrorList";
import NeakPerformanceStatementId from "@Primitives/NeakPerformanceStatementId.g";

@Di.injectable()
export default class NeakErrorListStoreMapper extends EntityStoreMapper<Proxy.NeakErrorListDto, NeakErrorList> {
    protected storeEntityIdType = NeakPerformanceStatementId;

    public applyToStoreCore(target: NeakErrorList, response: Proxy.NeakErrorListDto) {
        const entity = response;

        if (!!entity) {
            target.id = entity.id;
            target.rowVersion = entity.rowVersion;
            target.processLog = entity.processLog;
            target.hasProcessError = entity.hasProcessError;
        }
    }
}
