import _ from "@HisPlatform/Common/Lodash";

export function formatString(sourceString: string, ...arg: any[]) {
    let formattedString = sourceString;
    for (let i = 0; i < arg.length; i++) {
        const regexp = new RegExp("\\{" + i + "\\}", "gi");
        formattedString = formattedString.replace(regexp, `${arg[i]}`);
    }
    return formattedString;
}

// eslint-disable-next-line no-useless-escape
const formatStringWithObjectParamsRegexp = /(\{[\w\.\-]+\})/gi;
export function formatStringWithObjectParams(sourceString: string, params: { [id: string]: string }) {

    if (!params || !sourceString) {
        return sourceString;
    }

    let formattedString = sourceString;
    const results = sourceString.match(formatStringWithObjectParamsRegexp);

    if (!!results) {
        for (const result of results) {
            formattedString = formattedString.replace(result, `${_.get(params, result.substr(1, result.length - 2))}`);
        }
    }

    return formattedString;
}