import LockId from "@Toolkit/CommonWeb/Model/LockId";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import UserId from "@Primitives/UserId.g";

export default class LockInfo {
    constructor(
        public readonly lockState: EntityLockState,
        public readonly lockId: LockId,
        public readonly wasLockRequested: boolean,
        public readonly hasUpdatePermission: boolean,
        public readonly preventingLockOwnerName: string,
        public readonly preventingLockId: LockId,
        public readonly preventingLockUserId: UserId
    ) {}
}