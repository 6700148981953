import State from "@Toolkit/ReactClient/Common/StateManaging";
import IFormLayoutBlockStore from "@Toolkit/FormEngine/Model/Layout/IFormLayoutBlockStore";
import Guid from "@Toolkit/CommonWeb/Guid";
import IEditableLayoutBlockStore from "./IEditableLayoutBlockStore";
import EditableCustomBlockSettingsBase from "./CustomBlockSettingsBase";

export default class EditableLayoutCustomBlockStore implements IFormLayoutBlockStore, IEditableLayoutBlockStore {

    public readonly id: string;
    @State.observable.ref public customBlockIdentifier: string | null = null;
    public settings?: EditableCustomBlockSettingsBase;
    
    constructor(customBlockIdentifier: string, settings?: EditableCustomBlockSettingsBase) {
        this.id = Guid.newGuid();
        this.customBlockIdentifier = customBlockIdentifier;
        this.settings = settings;
    }

    public clone() {
        const ret = new EditableLayoutCustomBlockStore(this.customBlockIdentifier, this.settings?.clone());
        return ret;
    }
}