import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import * as Ui from "@CommonControls";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import PractitionerId from "@Primitives/PractitionerId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import MomentRange from "@Toolkit/CommonWeb/Model/MomentRange";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import IDoctor from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IDoctor";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IAssistant from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IAssistant";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface IAppointmentSchedulerEntryTooltipDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
    localizationService: ILocalizationService;
}
interface IAppointmentSchedulerEntryTooltipProps {
    _dependencies?: IAppointmentSchedulerEntryTooltipDependencies;
    patientName: string;
    practitionerIds: PractitionerId[];
    pointOfCareId: PointOfCareId;
    schedulingServiceId: SchedulingServiceId;
    description: string;
    appointmentInterval: MomentRange;
}

@State.observer
class AppointmentSchedulerEntryTooltip extends React.Component<IAppointmentSchedulerEntryTooltipProps> {

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.bound
    private async loadAsync() {
        await this.props._dependencies.organizationReferenceDataStore.doctorMap.ensureLoadedAsync(this.props.practitionerIds);
        await this.props._dependencies.organizationReferenceDataStore.assistantMap.ensureLoadedAsync(this.props.practitionerIds);
    }

    @State.computed private get practitioners() {
        const practitioners: Array<IAssistant | IDoctor> = [];
        if (!!this.props.practitionerIds.length) {
            this.props.practitionerIds.forEach(i => {
                const doctor = this.props._dependencies.organizationReferenceDataStore.doctorMap.get(i);
                if (!isNullOrUndefined(doctor)) {
                    practitioners.push(doctor);
                } else {
                    const assistant = this.props._dependencies.organizationReferenceDataStore.assistantMap.get(i);
                    if (!isNullOrUndefined(assistant)) {
                        practitioners.push(assistant);
                    }
                }
            });
        }
        return practitioners;
    }

    @State.computed private get practitionerData() {
        if (!!this.practitioners.length) {
            return this.practitioners.map((p: IAssistant | IDoctor) => {
                return { name: this.props._dependencies.localizationService.localizePersonName(p?.name), code: (p as IDoctor)?.code };
            }
            );
        }
        return [];
    }

    @State.computed private get interval() {
        const duration = this.props.appointmentInterval.to.diff(this.props.appointmentInterval.from, "minute");
        return formatStringWithObjectParams(StaticSchedulingResources.PractitionerAppointments.tooltipDate,
            { Interval: `${this.props.appointmentInterval.from.format("LLLL")} - ${this.props.appointmentInterval.to.format("LT")}`, Duration: duration.toString() });
    }

    @State.computed private get schedulingService() {
        if (this.props.schedulingServiceId) {
            return this.props._dependencies.schedulingReferenceDataStore.schedulingServices.get(this.props.schedulingServiceId);
        }

        return null;
    }

    @State.computed private get pointOfCare() {
        if (this.props.pointOfCareId) {
            return this.props._dependencies.organizationReferenceDataStore.pointOfCareMap.get(this.props.pointOfCareId);
        }

        return null;
    }

    private renderPractitionerRows(practitionerData: Array<{ name: string, code: string }>) {
        return !!practitionerData.length && (
            <>
                {practitionerData.map(d => this.renderTooltipRow(StaticSchedulingResources.BookSlotDialog.Practitioner, d.name ?? "", d.code ?? ""))}
            </>
        );
    }

    private renderTooltipRow(label: string, data: string, code?: string) {
        return (
            <Ui.Flex verticalSpacing="none">
                <Ui.Flex.Item xs={5}>{label}:</Ui.Flex.Item>
                <Ui.Flex.Item xs={7}>{!!code && <b>{code} </b>}{data}</Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    public render() {
        return (
            <>
                <div>
                    {this.renderTooltipRow(StaticSchedulingResources.AppointmentParticipantPanel.SubjectService, this.schedulingService?.name ?? "", this.schedulingService?.code ?? "")}
                    {this.renderTooltipRow(StaticSchedulingResources.BookSlotDialog.WorkPlace, this.pointOfCare?.name ?? "", this.pointOfCare?.shorthand)}
                    {this.renderPractitionerRows(this.practitionerData)}
                    {this.renderTooltipRow(StaticSchedulingResources.PractitionerAppointments.PatientName, this.props.patientName)}
                    {this.renderTooltipRow(StaticSchedulingResources.RegisteredPatientAppointmentListPanel.IntervalColumn, this.interval)}
                    {this.renderTooltipRow(StaticSchedulingResources.PractitionerAppointments.Note, this.props.description)}
                </div>
            </>
        );
    }
}

export default connect(
    AppointmentSchedulerEntryTooltip,
    new DependencyAdapter<IAppointmentSchedulerEntryTooltipProps, IAppointmentSchedulerEntryTooltipDependencies>(c => ({
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService"),
    }))
);
