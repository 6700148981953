/* auto-inject-disable */
import * as Proxy from "@AssecoMedPlugin/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientId from "@Primitives/PatientId.g";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import Form from "@Toolkit/FormEngine/Model/Form";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import { mapFormFieldDataToProxy, restoreCustomFormDataStoreAsync } from "@HisPlatform/BoundedContexts/FormEngine/ApplicationLogic/ApiAdapter/FormEngineMappers";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import { IFormDataStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormDataStore";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import { CompositeValidationResult } from "@HisPlatform/BoundedContexts/CommonReferenceData/Api/Proxy.g";
import { FormFieldData } from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import { GetTypeNameWithPrefix } from "@AssecoMedPlugin/PluginHelper";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import IOperationResult from "@Toolkit/CommonWeb/ApiAdapter/IOperationResult";
import IProcessForm from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/ExternalDecisionSupport/IProcessForm";

@Di.injectable()
export default class DecisionSupportScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject(GetTypeNameWithPrefix("ICareClient")) private readonly apiClient: Proxy.ICareClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("IFormEngineReferenceDataStore") private formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("ValidationProblemParameterMapperService") private validationProblemParameterMapperService: ValidationProblemParameterMapperService
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public getNewDecisionSupportScreenDataAsync(patientId: PatientId, episodeOfCareId: EpisodeOfCareId) {
        return this.executeOperationAsync<{ form: IForm, extensionData: { [key: string]: any; } }, Proxy.GetNewDecisionSupportScreenDataQueryResponse>(
            () => this.apiClient.getNewDecisionSupportScreenDataQueryAsync(CreateRequestId(), new Proxy.GetNewDecisionSupportScreenDataControllerDto({
                patientId: patientId,
                episodeOfCareId: episodeOfCareId
            })),
            async response => {
                const form = await this.mapToFormAsync(response.data, response.initialFormDefinitionId, [] as any, response.formLogic);
                return {
                    form: form,
                    extensionData: response.extensionData || {}
                };
            }
        );
    }

    @State.bound
    public getDecisionSupportScreenFormDefinitionAsync(pointOfCareId: PointOfCareId) {
        return this.executeOperationAsync<FormDefinitionId, Proxy.GetDecisionSupportScreenFormDefinitionQueryResponse>(
            () => this.apiClient.getDecisionSupportScreenFormDefinitionQueryAsync(CreateRequestId(), new Proxy.GetDecisionSupportScreenFormDefinitionControllerDto({
                pointOfCareId: pointOfCareId
            })),
            response => response.formDefinitionId
        );
    }

    @State.bound
    public getDecisionSupportScreenDataAsync(careActivityId: CareActivityId, requestLock: boolean) {
        return this.executeOperationAsync<{ form: IForm, extensionData: { [key: string]: any; } }, Proxy.GetDecisionSupportScreenDataCommandResponse>(
            () => this.apiClient.getDecisionSupportScreenDataCommandAsync(CreateRequestId(), new Proxy.GetDecisionSupportScreenDataControllerDto({
                careActivityId: careActivityId,
                requestLock: requestLock
            })),
            async response => {
                const form = await this.mapToFormAsync(response.data, response.formDefinitionId, response.compositeValidationResult, response.formLogic, response.rowVersions);
                return {
                    form: form,
                    extensionData: response.extensionData || {}
                };
            }
        );
    }

    @State.bound
    public createDecisionSupportAsync(form: IForm, isValidateOnly: boolean = false) {
        return this.executeOperationAsync<IForm, Proxy.CreateDecisionSupportCommandResponse>(
            () => {
                const mappedFormFieldData: FormFieldData[] = [];
                mapFormFieldDataToProxy(form.data.Content, mappedFormFieldData as any);

                return this.apiClient.createDecisionSupportCommandAsync(CreateRequestId(), new Proxy.CreateDecisionSupportControllerDto({
                    data: mappedFormFieldData,
                    formDefinitionId: form.definitionId,
                    rowVersions: Object.fromEntries(form.rowVersions),
                    isValidateOnly: isValidateOnly
                }));
            },
            async response => {
                return await this.mapToFormAsync(response.data, form.definitionId, response.compositeValidationResult, form.formLogic, response.rowVersions);
            }
        );
    }

    @State.bound
    public updateDecisionSupportAsync(careActivityId: CareActivityId, form: IForm, lockId: LockId, isValidateOnly: boolean = false) {
        return this.executeOperationAsync<IForm, Proxy.UpdateDecisionSupportCommandResponse>(
            () => {
                const mappedFormFieldData: FormFieldData[] = [];
                mapFormFieldDataToProxy(form.data.Content, mappedFormFieldData as any);

                return this.apiClient.updateDecisionSupportCommandAsync(CreateRequestId(), new Proxy.UpdateDecisionSupportControllerDto({
                    careActivityId: careActivityId,
                    data: mappedFormFieldData,
                    formDefinitionId: form.definitionId,
                    rowVersions: Object.fromEntries(form.rowVersions),
                    lockId: lockId,
                    isValidateOnly: isValidateOnly,
                    releaseLockIfSuccessful: false
                }));
            },
            async response => {
                const resultForm = await this.mapToFormAsync(response.data, form.definitionId, response.compositeValidationResult, form.formLogic, response.rowVersions);
                return resultForm;
            }
        );
    }

    @State.bound
    public getDecisionSupportPointOfCareIdsAsync() {
        return this.executeOperationAsync<PointOfCareId[], Proxy.GetDecisionSupportPointOfCareIdsQueryResponse>(
            () => this.apiClient.getDecisionSupportPointOfCareIdsQueryAsync(CreateRequestId()),
            response => Promise.resolve(response.pointOfCareIds)
        );
    }

    @State.bound
    public updateExternalDecisionSupportDataAsync(
        careActivityId: CareActivityId,
        decisionSupportName: string,
        externalDecisionSupportId: string,
        steps: IProcessForm[]
    ): Promise<IOperationResult<void>> {
        return this.executeOperationAsync<void, Proxy.UpdateExternalDecisionSupportData2CommandResponse>(
            () => this.apiClient.updateExternalDecisionSupportData2CommandAsync(CreateRequestId(), new Proxy.UpdateExternalDecisionSupportData2ControllerDto({
                careActivityId: careActivityId,
                decisionSupportName: decisionSupportName,
                externalDecisionSupportId: externalDecisionSupportId,
                steps: steps.map(step => new Proxy.DecisionSupportStepDto({
                    title: step.title,
                    answers: Object.keys(step.answers).map(key => new Proxy.DecisionSupportStepAnswerDto({
                        label: key,
                        value: step.answers[key]
                    }))
                }))
            })),
            _ => Promise.resolve()
        );
    }

    private async mapToFormAsync(
        data: FormFieldData[],
        definitionId: FormDefinitionId,
        validationResults: CompositeValidationResult,
        formLogic: string,
        rowVersions: { [key: string]: RowVersion } = null,
        existingData: IFormDataStore = null
    ) {
        const definition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(definitionId);

        const mappedValidationResults = mapValidationResults(validationResults as unknown as IServerCompositeValidationResult);

        mappedValidationResults.forEach(i => {
            i.problems.forEach((j: IClientValidationProblem) => {
                this.validationProblemParameterMapperService.resolveValidationProblemParameters("DecisionSupportScreenApiAdapter", j);
            });
        });

        const form = new Form(
            FormInstanceId.new,
            RowVersion.initial,
            definitionId,
            existingData ?? await restoreCustomFormDataStoreAsync(data as any, definition, this.formEngineReferenceDataStore),
            mappedValidationResults,
            null,
            null,
            formLogic);

        if (!isNullOrUndefined(rowVersions)) {
            form.rowVersions = new Map(Object.entries(rowVersions));
        }

        return form;
    }
}