import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import IPatientsCareActivityExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientsCareActivityExtensionPointProps";

@Di.injectable()
export default class PatientsCareActivityEhiExtensionComponentService implements IExtensionComponentService<IPatientsCareActivityExtensionPointProps> {

    public isVisible(props: IPatientsCareActivityExtensionPointProps) {
        return props.careActivitySource && props.careActivitySource.value === "HunEHealthInfrastructure";
    }

    public shouldRerunIsVisible(props: IPatientsCareActivityExtensionPointProps, prevProps: IPatientsCareActivityExtensionPointProps) {
        return props.careActivitySource?.value !== prevProps?.careActivitySource.value || props.extensionData !== prevProps?.extensionData;
    }
}