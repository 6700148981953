import DependencyContainer from "@DiContainer";

import IAuthenticationService from "@HisPlatform/Services/Definition/Authentication/IAuthenticationService";
import AuthenticationService from "@HisPlatform/Services/Implementation/Authentication/AuthenticationService";
DependencyContainer
    .bind("IAuthenticationService")
    .to(AuthenticationService)
    .inSingletonScope();

import IAgeCalculationService from "@HisPlatform/Services/Definition/AgeCalculation/IAgeCalculationService";
import AgeCalculationService from "@HisPlatform/Services/Implementation/AgeCalculation/AgeCalculationService";
DependencyContainer
    .bind("IAgeCalculationService")
    .to(AgeCalculationService)
    .inSingletonScope();

import { ICareActivityWorklistCommonService } from "@HisPlatform/Services/Definition/CareActivityWorklistCommonService/ICareActivityWorklistCommonService";
import CareActivityWorklistCommonService from "@HisPlatform/Services/Implementation/CareActivityWorklistCommonService/CareActivityWorklistCommonService";
DependencyContainer
    .bind("ICareActivityWorklistCommonService")
    .to(CareActivityWorklistCommonService)
    .inSingletonScope();

import LocalizationService from "@HisPlatform/Services/Implementation/Localization/LocalizationService";
DependencyContainer
    .bind("ILocalizationService")
    .to(LocalizationService)
    .inSingletonScope();

DependencyContainer
    .bind("IResourceFetcher")
    .toDynamicValue(() => DependencyContainer.get<IResourceFetcher>("ILocalizationService"));

import ITokenRepository from "@Toolkit/CommonWeb/Repositories/Definition/TokenRepository/ITokenRepository";
import TokenRepository from "@Toolkit/CommonWeb/Repositories/Implementation/TokenRepository/TokenRepository";
DependencyContainer
    .bind("ITokenRepository")
    .to(TokenRepository);

import ICurrentCultureRepository from "@Toolkit/CommonWeb/Repositories/Definition/CurrentCultureRepository/ICurrentCultureRepository";
import CurrentCultureRepository from "@Toolkit/CommonWeb/Repositories/Implementation/CurrentCultureRepository/CurrentCultureRepository";
DependencyContainer
    .bind("ICurrentCultureRepository")
    .to(CurrentCultureRepository);

import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import ToastifyNotificationService from "@Toolkit/ReactClient/Services/Implementation/ToastifyNotificationService/ToastifyNotificationService";
DependencyContainer
    .bind("INotificationService")
    .to(ToastifyNotificationService);

import DialogService from "@Toolkit/ReactClient/Services/Implementation/DialogService/DialogService";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
DependencyContainer
    .bind("IDialogService")
    .to(DialogService)
    .inSingletonScope();

import IClientSessionService from "@HisPlatform/Services/Definition/ClientSession/IClientSessionService";
import ClientSessionService from "@HisPlatform/Services/Implementation/ClientSession/ClientSessionService";
DependencyContainer
    .bind("IClientSessionService")
    .to(ClientSessionService)
    .inSingletonScope();

import IClientSessionRepository from "@Toolkit/CommonWeb/Repositories/Definition/ClientSessionRepository/IClientSessionRepository";
import ClientSessionRepository from "@Toolkit/CommonWeb/Repositories/Implementation/ClientSessionRepository/ClientSessionRepository";
DependencyContainer
    .bind("IClientSessionRepository")
    .to(ClientSessionRepository)
    .inSingletonScope();

import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import DateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Implementation/DateTimeFormatProvider";
DependencyContainer
    .bind("IDateTimeFormatProvider")
    .to(DateTimeFormatProvider)
    .inSingletonScope();

import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import FileSaverService from "@Toolkit/ReactClient/Services/Implementation/FileSaverService/FileSaverService";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IResourceFetcher from "@Toolkit/CommonWeb/Abstractions/Localization/IResourceFetcher";
DependencyContainer
    .bind("IFileSaverService")
    .to(FileSaverService)
    .inSingletonScope();

import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import ToolkitLocalizationService from "@HisPlatform/Services/Implementation/Localization/ToolkitLocalizationService";
DependencyContainer
    .bind("IToolkitLocalizationService")
    .to(ToolkitLocalizationService)
    .inSingletonScope();

import IScreenshotService from "@HisPlatform/Services/Definition/ScreenshotService/IScreenshotService";
import ScreenshotService from "@HisPlatform/Services/Implementation/ScreenshotService/ScreenshotService";
import IExtensionComponentRegistry from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import ExtensionComponentRegistry from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/ExtensionComponentRegistry";
import ISearchParametersService from "@Toolkit/CommonWeb/SearchParametersService/Definition/ISearchParametersService";
import SearchParametersService from "@Toolkit/CommonWeb/SearchParametersService/Implementation/SearchParametersService";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCaseRegistry from "@HisPlatform/Services/Implementation/UseCaseRegistry/UseCaseRegistry";
import IWidgetRegistry from "@PluginInterface/Dashboard/IWidgetRegistry";
import WidgetRegistry from "@HisPlatform/Services/Implementation/WidgetRegistry/WidgetRegistry";
import IStateChangeBlockingRegistry from "@PluginInterface/BoundedContexts/Care/CareRegister/StateChange/IStateChangeBlockingRegistry";
import StateChangeBlockingRegistry from "@HisPlatform/Services/Implementation/StateChangeBlockingRegistry/StateChangeBlockingRegistry";
import IPatientDocumentFormattingRegistry from "@PluginInterface/PatientDocumentFormatting/IPatientDocumentFormattingRegistry";
import PatientDocumentFormattingRegistry from "@HisPlatform/Services/Implementation/PatientDocumentFormattingRegistry/PatientDocumentFormattingRegistry";
import PatientLoader from "@HisPlatform/Model/DomainModel/PatientContext/PatientLoader";
import CareActivityLoader from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityLoader";
import ModalServiceRegistry from "@Toolkit/ReactClient/Components/ModalService/ModalServiceRegistry";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import EntityCollectionsService from "@HisPlatform/Services/Implementation/EntityCollections/EntityCollectionsService";
import IEntityCollectionsService from "@HisPlatform/Services/Definition/EntityCollectionService/IEntityCollectionsService";
import ITokenSettingsPanelRegistry from "@PluginInterface/BoundedContexts/DocumentManagement/TokenSettingsPanelRegistry/ITokenSettingsPanelRegistry";
import TokenSettingsPanelRegistry from "@HisPlatform/BoundedContexts/DocumentManagement/Services/TokenSettingsPanelRegistry/TokenSettingsPanelRegistry";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import ReferenceDataLoader from "@HisPlatform/Services/Implementation/ReferenceDataLoader/ReferenceDataLoader";
import FormExtensionRegistry from "@HisPlatform/Services/Implementation/FormExtensionRegistry/FormExtensionRegistry";
import IFormExtensionRegistry from "@PluginInterface/FormExtension/IFormExtensionRegistry";
import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import PermissionDefinitionRegistry from "@HisPlatform/Services/Implementation/PermissionDefinitionRegistry/PermissionDefinitionRegistry";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import ActivityRegistry from "@HisPlatform/Services/Implementation/ActivityRegistry/ActivityRegistry";
import NDataAttributeService from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/NDataAttributeService";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import INDataAttributeService from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/INDataAttributeService";
import ModalContextStore from "@Toolkit/ReactClient/Components/ModalService/ModalContextStore";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import MapperService from "@HisPlatform/Services/Implementation/MapperService/MapperService";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import IMapperService from "@HisPlatform/Services/Definition/MapperService/IMapperService";
import BusinessErrorBase from "@HisPlatform/Model/DomainModel/BusinessError/BusinessErrorBase";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";

DependencyContainer
    .bind("IScreenshotService")
    .to(ScreenshotService)
    .inSingletonScope();

DependencyContainer
    .bind("IExtensionComponentRegistry")
    .to(ExtensionComponentRegistry)
    .inSingletonScope();

DependencyContainer
    .bind("ISearchParametersService")
    .to(SearchParametersService);

DependencyContainer
    .bind("IUseCaseRegistry")
    .to(UseCaseRegistry)
    .inSingletonScope();

DependencyContainer
    .bind("IWidgetRegistry")
    .to(WidgetRegistry)
    .inSingletonScope();

DependencyContainer
    .bind("IStateChangeBlockingRegistry")
    .to(StateChangeBlockingRegistry)
    .inSingletonScope();

DependencyContainer
    .bind("IPatientDocumentFormattingRegistry")
    .to(PatientDocumentFormattingRegistry)
    .inSingletonScope();

DependencyContainer
    .bind("PatientLoader")
    .to(PatientLoader)
    .inSingletonScope();

DependencyContainer
    .bind("CareActivityLoader")
    .to(CareActivityLoader)
    .inSingletonScope();

DependencyContainer
    .bind("ModalServiceRegistry")
    .to(ModalServiceRegistry)
    .inSingletonScope();

DependencyContainer
    .bind("ValidationProblemParameterMapperService")
    .to(ValidationProblemParameterMapperService)
    .inSingletonScope();

DependencyContainer
    .bind("IEntityCollectionsService")
    .to(EntityCollectionsService)
    .inSingletonScope();

DependencyContainer
    .bind("ITokenSettingsPanelRegistry")
    .to(TokenSettingsPanelRegistry)
    .inSingletonScope();

DependencyContainer
    .bind("IReferenceDataLoader")
    .to(ReferenceDataLoader)
    .inSingletonScope();

DependencyContainer
    .bind("IFormExtensionRegistry")
    .to(FormExtensionRegistry)
    .inSingletonScope();

DependencyContainer
    .bind("IPermissionDefinitionRegistry")
    .to(PermissionDefinitionRegistry)
    .inSingletonScope();

DependencyContainer
    .bind("IActivityRegistry")
    .to(ActivityRegistry)
    .inSingletonScope();

DependencyContainer
    .bind(NDataAttributeService)
    .to(NDataAttributeService)
    .inSingletonScope();

DependencyContainer
    .bind("INDataAttributeConfigurator")
    .toDynamicValue(() => DependencyContainer.get(NDataAttributeService));

DependencyContainer
    .bind("INDataAttributeService")
    .toDynamicValue(() => DependencyContainer.get(NDataAttributeService));

DependencyContainer
    .bind("IModalService")
    .to(ModalContextStore)
    .inSingletonScope();

DependencyContainer
    .bind(MapperService)
    .to(MapperService)
    .inSingletonScope();

DependencyContainer
    .bind("IMapperConfigurator")
    .toDynamicValue(() => DependencyContainer.get(MapperService));

DependencyContainer
    .bind("IMapperService")
    .toDynamicValue(() => DependencyContainer.get(MapperService));

BusinessErrorBase.setMapperServiceLocator(() => DependencyContainer.get(MapperService));
ClientSideActionDto.setMapperServiceLocator(() => DependencyContainer.get(MapperService));
FrontendActionApiResult.setMapperServiceLocator(() => DependencyContainer.get(MapperService));

DependencyContainer
    .bind("FormEditorRegistry")
    .to(FormEditorRegistry)
    .inSingletonScope();

DependencyContainer
    .bind("IFormLogicRegistry")
    .to(FormLogicRegistry)
    .inSingletonScope();

DependencyContainer
    .bind("IRoutingFrameCaseRegistry")
    .to(RoutingFrameCaseRegistry)
    .inSingletonScope();

import ICommandDispatcher from "@Toolkit/CommonWeb/CommandProcessing/Definition/ICommandDispatcher";
import CommandDispatcher from "@Toolkit/CommonWeb/CommandProcessing/Implementation/CommandDispatcher";
import IMessagingSubscriptionManager from "@Toolkit/CommonWeb/CommandProcessing/Definition/IMessagingSubscriptionManager";
import MessagingSubscriptionManager from "@Toolkit/CommonWeb/CommandProcessing/Implementation/MessagingSubscriptionManager";
import FilterService from "@HisPlatform/Services/Implementation/FilterService/FilterService";
import ScreenRegistry from "@HisPlatform/Services/Implementation/ActionProcessing/ScreenRegistry";
import IScreenRegistry from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import GlobalActionRegistry from "@Toolkit/ReactClient/ActionProcessing/GlobalActionRegistry";
import FrontendActionApiResult from "@HisPlatform/Model/DomainModel/FrontendAction/FrontendActionApiResult";
import FormLogicRegistry from "@HisPlatform/Services/Implementation/FormLogicRegistry/FormLogicRegistry";
import IFormLogicRegistry from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import DynamicNavigationRegistry from "@HisPlatform/Services/Implementation/DynamicNavigationRegistry/DynamicNavigationRegistry";
import IRoutingFrameCaseRegistry from "@HisPlatform/Services/Definition/RoutingFrameCaseRegistry/IRoutingFrameCaseRegistry";
import RoutingFrameCaseRegistry from "@HisPlatform/Services/Implementation/RoutingFrameCaseRegistry/RoutingFrameCaseRegistry";

DependencyContainer
    .bind("ICommandDispatcher")
    .to(CommandDispatcher)
    .inSingletonScope();

DependencyContainer
    .bind("IMessagingSubscriptionManager")
    .to(MessagingSubscriptionManager)
    .inSingletonScope();

DependencyContainer
    .bind(FilterService)
    .to(FilterService)
    .inSingletonScope();

DependencyContainer
    .bind("GlobalActionRegistry")
    .to(GlobalActionRegistry)
    .inSingletonScope();

DependencyContainer
    .bind("IScreenRegistry")
    .to(ScreenRegistry)
    .inSingletonScope();

DependencyContainer
    .bind("IDynamicNavigationRegistry")
    .to(DynamicNavigationRegistry)
    .inSingletonScope();
