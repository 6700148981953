import React from "react";
import INDataRow from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataRow";
import INDataAction from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataAction";
import _ from "@HisPlatform/Common/Lodash";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ActionPlacement from "@Primitives/ActionPlacement";
import ContextMenu from "@CommonControls/ContextMenu";
import NDataSecondaryMenuItem from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataSecondaryMenuItem";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import FrontendListSecondaryMenuItem from "./FrontendListSecondaryMenuItem";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import IListItemActionPresentation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/FrontendActions/IListItemActionPresentation";
import ListItemActionPlacement from "@HisPlatform/BoundedContexts/WebAppBackend/Api/FrontendLists/Enum/ListItemActionPlacement.g";
import WorklistActionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistActionType.g";

function renderFrontendListSecondaryMenu(actionDescriptors: ActionDescriptor<IListItemActionPresentation>[]) {

    const filteredActions = actionDescriptors.filter(val => (
        val.presentation.placement === ListItemActionPlacement.ContextMenu ||
        val.presentation.placement === ListItemActionPlacement.InRowAndContextMenu
    ) && !val.presentation.supportsBatching);

    const orderedActions = _.orderBy(filteredActions, [i => i.presentation.groupId]);
    const groupedOrderedActions = _.groupBy<ActionDescriptor<IListItemActionPresentation>>(orderedActions, i => i.presentation.groupId);

    const actionMenuItems: React.ReactElement[] = [];
    Object.keys(groupedOrderedActions).forEach((key, idx) => {
        const currentGroup = groupedOrderedActions[key];

        const actionsInGroupBySubGroups = _.groupBy<ActionDescriptor<IListItemActionPresentation>>(currentGroup, i => i.presentation.groupNameResourceId);

        Object.keys(actionsInGroupBySubGroups).forEach((innerKey, innerId) => {

            const actionsInGroup = actionsInGroupBySubGroups[innerKey];

            const groupTitleResourceId = actionsInGroup.length > 0 ? actionsInGroup[0].presentation.groupNameResourceId : null;

            if (isNullOrUndefined(groupTitleResourceId)) {
                addFrontendListGroupActionsToMenu(actionMenuItems, actionsInGroup, `${idx}_${innerId}`);
            } else {
                const actionSubMenuItems: React.ReactElement[] = [];
                addFrontendListGroupActionsToMenu(actionSubMenuItems, actionsInGroup, `${idx}_${innerId}`);

                actionMenuItems.push(
                    <NDataSecondaryMenuItem
                        isGroup
                        key={`submenu_${idx}_${innerId}`}
                        textResourceId={groupTitleResourceId}
                        isDisabled={false}
                    >
                        {actionSubMenuItems}
                    </NDataSecondaryMenuItem>
                );
            }
        });

        if (idx !== Object.keys(groupedOrderedActions).length - 1) {
            actionMenuItems.push(
                <ContextMenu.Separator key={"separator" + idx} />
            );
        }

    });

    return actionMenuItems.length > 0 ? actionMenuItems : null;
}

function addFrontendListGroupActionsToMenu(
    actionMenuItems: React.ReactElement[],
    actionsInGroup: ActionDescriptor<IListItemActionPresentation>[],
    idx: string,
) {
    const orderedActionsInGroup = _.orderBy<ActionDescriptor<IListItemActionPresentation>>(actionsInGroup, [i => i.presentation.order]);

    orderedActionsInGroup.forEach((it, innerIdx) => {
        actionMenuItems.push(
            <FrontendListSecondaryMenuItem
                isGroup={false}
                key={`${idx}_${innerIdx}`}
                descriptor={it}
            />
        );
    });
}

export function renderNDataSecondaryMenu(
    row: INDataRow,
    onActionAsync: (rowId: string, actionToken: string, argumentItems: { useCaseDisplayMode?: UseCaseDisplayMode }, worklistActionType: WorklistActionType, activityReference: string, clientSideAction?: ClientSideActionDto) => Promise<void>,
    activityRegistry: IActivityRegistry
) {
    if (arrayIsNullOrEmpty(row.__actions) && arrayIsNullOrEmpty(row.__actionDescriptors)) {
        return null;
    }

    if (row.__actionDescriptors) {
        return renderFrontendListSecondaryMenu(row.__actionDescriptors);
    }

    const filteredActions = row.__actions.filter(val => (val.placement === ActionPlacement.Context || val.placement === ActionPlacement.Both) && val.worklistActionType !== WorklistActionType.Batch);
    const orderedActions = _.orderBy(filteredActions, [i => i.groupId]);
    const groupedOrderedActions = _.groupBy<INDataAction>(orderedActions, i => i.groupId);

    const actionMenuItems: React.ReactElement[] = [];
    Object.keys(groupedOrderedActions).forEach((key, idx) => {
        const currentGroup = groupedOrderedActions[key];

        const actionsInGroupBySubGroups = _.groupBy<INDataAction>(currentGroup, i => i.groupTitleResourceId);

        Object.keys(actionsInGroupBySubGroups).forEach((innerKey, innerId) => {

            const actionsInGroup = actionsInGroupBySubGroups[innerKey];

            const groupTitleResourceId = actionsInGroup.length > 0 ? actionsInGroup[0].groupTitleResourceId : null;

            if (isNullOrUndefined(groupTitleResourceId)) {
                addGroupActionsToMenu(actionMenuItems, actionsInGroup, `${idx}_${innerId}`, row.__id, onActionAsync, activityRegistry);
            } else {
                const actionSubMenuItems: React.ReactElement[] = [];
                addGroupActionsToMenu(actionSubMenuItems, actionsInGroup, `${idx}_${innerId}`, row.__id, onActionAsync, activityRegistry);

                actionMenuItems.push(
                    <NDataSecondaryMenuItem
                        isGroup
                        key={`submenu_${idx}_${innerId}`}
                        textResourceId={groupTitleResourceId}
                        isDisabled={false}
                        _activityRegistry={activityRegistry}
                    >
                        {actionSubMenuItems}
                    </NDataSecondaryMenuItem>
                );
            }
        });

        if (idx !== Object.keys(groupedOrderedActions).length - 1) {
            actionMenuItems.push(
                <ContextMenu.Separator key={"separator" + idx} />
            );
        }

    });

    return actionMenuItems;
}

function addGroupActionsToMenu(
    actionMenuItems: React.ReactElement[],
    actionsInGroup: INDataAction[],
    idx: string,
    rowId: string,
    onActionAsync: (rowId: string, actionToken: string, argumentItems: { useCaseDisplayMode?: UseCaseDisplayMode }, worklistActionType: WorklistActionType, activityReference: string, clientSideAction?: ClientSideActionDto) => Promise<void>,
    activityRegistry: IActivityRegistry
) {
    const orderedActionsInGroup = _.orderBy<INDataAction>(actionsInGroup, [i => i.order]);

    orderedActionsInGroup.forEach((it, innerIdx) => {
        actionMenuItems.push(
            <NDataSecondaryMenuItem
                isGroup={false}
                isDisabled={!it.isEnabled}
                key={`${idx}_${innerIdx}`}
                textResourceId={it.titleResourceId}
                commandToken={it.commandToken}
                clientSideAction={it.clientSideAction}
                onActionAsync={onActionAsync}
                activityReference={it.activityReference}
                useCaseDisplayMode={it.useCaseDisplayMode}
                rowId={rowId}
                actionType={it.worklistActionType}
                _activityRegistry={activityRegistry}
            />
        );
    });
}