import React, { useEffect, RefObject } from "react";
import { components } from "react-select";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export interface IMenuProps {
    children: Node;
}


export default class Menu extends React.Component<IMenuProps> {
    private wrapperRef: RefObject<HTMLDivElement> = React.createRef();

    public componentDidMount() {
        document.getElementById("__select-box-portal").addEventListener("mousedown", this.handleClickOutside);
    }

    @State.action.bound
    public handleClickOutside(e: MouseEvent) {
        const menuList = this.wrapperRef?.current.querySelector(".__menu-list");
        if (menuList) {
            if (this.wrapperRef && e.target === menuList) {
                e.stopPropagation();
            }
        }
    }

    public componentWillUnmount() {
        document.getElementById("__select-box-portal").removeEventListener("mousedown", this.handleClickOutside);
    }

    public render() {
        return (
            <div ref={this.wrapperRef}>
                <components.Menu {...this.props}>
                    {this.props.children}
                </components.Menu>
            </div>
        );

    }
}