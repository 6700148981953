import React from "react";
import Modal from "@CommonControls/Modal";

interface IDialogProps {
    title?: string;
    isOpen: boolean;
    onClose?: () => void;
}

export default class Dialog extends React.PureComponent<IDialogProps> {
    public render() {
        return (
            <Modal isOpen={this.props.isOpen} onClose={this.props.onClose} title={this.props.title}>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
            </Modal>
        );
    }
}