import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";
import LoadingContext from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingContext";
import ILoadingContext from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingContextStore";

export default class LoadingContextAdapter<TProps> implements IComponentAdapter<TProps> {
    public get usedContexts(): Array<React.Context<any>> {
        return [LoadingContext];
    }

    constructor(
        private readonly mapToProps: (props: TProps, isLoading: boolean) => Partial<TProps> =
            (props: any, isLoading) => ({ _isLoading: isLoading } as any)
    ) { }

    public getMappedProps(props: TProps, contextValues?: Map<React.Context<any>, any>): Partial<TProps> {
        const contextValue: ILoadingContext = contextValues.get(LoadingContext);
        return this.mapToProps(props, contextValue?.isLoading);
    }
}