import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import NavigateToRouteActionProcessor from "@HisPlatform/Services/Implementation/ActionProcessing/NavigateToRouteActionProcessor";
import { getNavigationMenuItems } from "@HunSocialSecurityPlugin/Packages/PerformanceStatement/Configuration/NavigationMenuItems";
import ShowNeakPerformanceStatementsScreenAction from "@HunSocialSecurityPlugin/Packages/PerformanceStatement/FrontendActions/ShowNeakPerformanceStatementsScreenAction.g";
import ShowPerformanceStatementCareActivitiesScreenAction from "@HunSocialSecurityPlugin/Packages/PerformanceStatement/FrontendActions/ShowPerformanceStatementCareActivitiesScreenAction.g";
import performanceStatementStandaloneRouteDefinitions from "@HunSocialSecurityPlugin/UseCases/PerformanceStatementUseCase/PerformanceStatementRoutes";
import GlobalActionRegistry from "@Toolkit/ReactClient/ActionProcessing/GlobalActionRegistry";

class PerformanceStatementPackageInitializer implements IPackageInitializer {

    public configureActionProcessor(registry: GlobalActionRegistry) {

        registry.register(
            ShowPerformanceStatementCareActivitiesScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => performanceStatementStandaloneRouteDefinitions.performanceStatementCareActivity.makeRoute()));

        registry.register(
            ShowNeakPerformanceStatementsScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => performanceStatementStandaloneRouteDefinitions.neakPerformanceStatement.makeRoute()));
    }

    public configureDynamicNavigation(registry: IDynamicNavigationRegistry): void {
        getNavigationMenuItems().forEach(navigationItem => registry.register(navigationItem));
    }
}

export default new PerformanceStatementPackageInitializer();