import ResourceBlocking from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ResourceBlocking";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import React from "react";
import ResourceBlockingEditorDialogView from "./ResourceBlockingEditorDialogView";
import { IResourceBlockingEditorDialogParams } from "./ResourceBlockingEditorDialogParams";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ResourceManagementApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/ResourceManagementApiAdapter";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";

interface IResourceBlockingEditorDialogDependencies {
    resourceManagementApiAdapter: ResourceManagementApiAdapter;
    dialogService: IDialogService;
}

interface IResourceBlockingEditorDialogProps extends IModalComponentParams, IResourceBlockingEditorDialogParams {
    _dependencies?: IResourceBlockingEditorDialogDependencies;
}

/** @screen */
@State.observer
class ResourceBlockingEditorDialog extends React.Component<IResourceBlockingEditorDialogProps> {
    @State.observable.ref private resourceBlocking: ResourceBlocking = new ResourceBlocking(true);
    @State.observable.ref private isDeleteModalOpen: boolean = false;

    constructor(props: IResourceBlockingEditorDialogProps) {
        super(props);

        if (!isNullOrUndefined(this.props.resourceBlocking)) {
            this.setResourceBlocking(this.props.resourceBlocking);
        }
    }

    @State.action.bound
    private async saveResourceBlockingAsync() {
        if (this.resourceBlocking.isNew) {
            return await this.props._dependencies.resourceManagementApiAdapter.createResourceBlockingAsync(this.resourceBlocking);
        } else {
            return await this.props._dependencies.resourceManagementApiAdapter.updateResourceBlockingAsync(this.resourceBlocking);
        }
    }

    @State.action.bound
    private close() {
        this.props.onClose({
            ResourceBlocking: null
        });
    }

    @State.action.bound
    private async saveAsync() {
        const newResourceBlocking = await this.saveResourceBlockingAsync();
        if (!newResourceBlocking.hasValidationError) {
            return this.props.onClose({
                resourceBlocking: newResourceBlocking
            });
        } else {
            State.runInAction(() => {
                this.resourceBlocking.validationResults = newResourceBlocking.validationResults;
            });
        }
    }

    @State.action.bound
    private async deleteAsync() {
        this.isDeleteModalOpen = true;

        const dialogResult = await this.props._dependencies.dialogService.yesNoCancel(
            StaticSchedulingResources.ResourceBlockingEditorDialog.DeleteResourceBlockingTitle,
            StaticSchedulingResources.ResourceBlockingEditorDialog.DeleteResourceBlockingText);

        if (dialogResult.resultCode === DialogResultCode.Yes) {
            await this.props._dependencies.resourceManagementApiAdapter.deleteResourceBlockingAsync(this.resourceBlocking);

            return this.props.onClose({
                resourceBlocking: this.resourceBlocking
            });
        }

        State.runInAction(() => {
            this.isDeleteModalOpen = false;
        });
    }

    @State.action.bound
    private setResourceBlocking(newValue: ResourceBlocking) {
        this.resourceBlocking = newValue;
    }

    @State.action.bound
    private async validateAsync() {
        const result = await this.props._dependencies.resourceManagementApiAdapter.validateResourceBlockingAsync(this.resourceBlocking);
        return result.value;
    }

    public render() {
        return (
            <>
                <ReadOnlyContext.Provider value={this.isDeleteModalOpen}>
                    <ResourceBlockingEditorDialogView
                        onClose={this.close}
                        onDeleteAsync={this.deleteAsync}
                        saveAsync={this.saveAsync}
                        resourceBlocking={this.resourceBlocking}
                        onValidateAsync={this.validateAsync}
                    />
                </ReadOnlyContext.Provider>
            </>
        );
    }
}

export default connect(
    ResourceBlockingEditorDialog,
    new DependencyAdapter<IResourceBlockingEditorDialogProps, IResourceBlockingEditorDialogDependencies>(c => ({
        resourceManagementApiAdapter: c.resolve("ResourceManagementApiAdapter"),
        dialogService: c.resolve("IDialogService")
    }))
);
