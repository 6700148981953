import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicationStatementSubjectAssignmentBase from "./MedicationStatementSubjectAssignmentBase";
import MedicationId from "@Primitives/MedicationId.g";

export default class ReferencedMedicationAssignment extends MedicationStatementSubjectAssignmentBase  {
    @State.observable.ref public medicationId: MedicationId = null;

    constructor(medicationId: MedicationId) {
        super();
        this.medicationId = medicationId;
    }
}