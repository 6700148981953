import AccessRuleBase from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/AccessRuleBase";
import PermissionScope from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/PermissionScope";
import IPermissionScope from "@PluginInterface/OperationAccessControl/IPermissionScope";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import RoleId from "@Primitives/RoleId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class RoleAccessRule extends AccessRuleBase {
    @State.observable.ref public roleId: RoleId = null;

    @State.observable.ref public assignedScopes: IPermissionScope[] = [];
    public constructor(isNew: boolean) {
        super(isNew);
    }

    @State.action.bound
    public setRoleId(value: RoleId) {
        this.roleId = value;
        this.assignedScopes = [];
    }

    @State.action.bound
    public overwriteScope(scope: IPermissionScope) {
        this.assignedScopes = [...this.assignedScopes.filter(s => s.type !== scope.type), scope];
    }

    @State.action.bound
    public setIsDeleted() {
        this.isDeleted = true;
    }

    @State.computed
    public get pointOfCareScopeValues() {
        return this.pointOfCareScope?.includedValues?.map(v => new PointOfCareId(v));
    }

    @State.computed
    public get pointOfCareScope() {
        return this.assignedScopes.find(s => s.type === PermissionScopeType.pointOfCare);
    }

    @State.bound
    public assignPointOfCareTypeScopeValues(value: PointOfCareId[], isAll: boolean = false) {
        const pointOfCareScopeToAssign = isAll
            ? PermissionScope.createAll(PermissionScopeType.pointOfCare)
            : PermissionScope.createPositive(PermissionScopeType.pointOfCare, value?.map(dt => dt.value) ?? []);
        this.overwriteScope(pointOfCareScopeToAssign);
    }

    @State.bound
    public assignPointOfCareTypeScope(scope: IPermissionScope) {
        this.overwriteScope(scope ?? PermissionScope.createPositive(PermissionScopeType.pointOfCare, []));
    }
}