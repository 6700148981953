import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import { MedicalServiceExtensibleCodeSelectorItem } from "@HisPlatformControls/MedicalServiceExtensibleCodeSelector/MedicalServiceExtensibleCodeSelectorItem";
import * as HisUi from "@HisPlatformControls";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import MedicationsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicationsApiAdapter";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import MedicalServiceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicalServiceApiAdapter";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import IMedicalServiceExtendedVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalServiceExtendedVersion";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import CareTypeId from "@Primitives/CareTypeId.g";

interface IMedicalServiceExtensibleCodeSelectorDependencies {
    medicalServiceApiAdapter: MedicalServiceApiAdapter;
    medicationsApiAdapter: MedicationsApiAdapter;
    localizationService: IToolkitLocalizationService;
    careReferenceDataStore: CareReferenceDataStore;
}

interface IMedicalServiceExtensibleCodeSelectorProps extends ICodeSelectorCommonProps<MedicalServiceExtensibleCodeSelectorItem> {
    _dependencies?: IMedicalServiceExtensibleCodeSelectorDependencies;
    careTypeId?: CareTypeId;
}

@State.observer
class MedicalServiceExtensibleCodeSelector extends React.Component<IMedicalServiceExtensibleCodeSelectorProps> {

    private get dependencies() {
        return this.props._dependencies;
    }

    @State.bound
    private async getDisplayValueAsync(value: MedicalServiceExtensibleCodeSelectorItem) {

        let item;
        if (value.id instanceof MedicalServiceId) {
            if (!value.text) {
                item = await this.dependencies.careReferenceDataStore.medicalService.getOrLoadAsync({
                    id: value.id,
                    validOn: this.props.validOn ? this.props.validOn : LocalDate.today()
                });
            } else {
                item = { name: value.text };
            }
        } else {
            item = { name: value.text };
        }
        return item.name;
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const results = await this.dependencies.medicalServiceApiAdapter.searchMedicalServicesByCodeAsync(
            text,
            10,
            this.props.careTypeId,
            this.props.validOn ? this.props.validOn : LocalDate.today()
        );

        if (results.operationWasSuccessful) {

            const services = results.value.map(x => {
                const textOfQuickSearch = `${x.name} (${x.codeValue})`;

                return new MedicalServiceExtensibleCodeSelectorItem({
                    id: x.id,
                    text: textOfQuickSearch
                });
            });

            const referenceMedicalServices = services.filter(s => s.id instanceof MedicalServiceId);

            await this.dependencies.careReferenceDataStore.medicalService.ensureLoadedAsync(referenceMedicalServices.map(s => ({
                id: s.id,
                validOn: this.props.validOn ? this.props.validOn : LocalDate.today()
            } as IEntityVersionSelector<MedicalServiceId>)));

            return services;
        }
        return null;
    }

    @State.bound
    private addNewItem(text: string): MedicalServiceExtensibleCodeSelectorItem {
        return new MedicalServiceExtensibleCodeSelectorItem({ text: text });
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState) {
        const validOn = this.props.validOn ? this.props.validOn : LocalDate.today();
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;

        const results = await this.props._dependencies.medicalServiceApiAdapter.getMedicalServicesAsync(
            filterText,
            customOrdering,
            paging,
            this.props.careTypeId,
            validOn);
        const versionSelectors = results.items.map(x => new EntityVersionSelector<MedicalServiceId>(x.id, validOn));
        await this.dependencies.careReferenceDataStore.medicalService.ensureLoadedAsync(versionSelectors);
        const medicalServiceExtendedItems = results.items.map(x => {
            return {
                id: x.id,
                code: x.codeValue,
                name: x.name
            } as IMedicalServiceExtendedVersion;
        });
        const newStore = new PagedItemStore(medicalServiceExtendedItems, results.totalCount, results.operationInfo);
        return newStore;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: MedicalServiceExtensibleCodeSelectorItem) {
        this.props.onChange({ id: item.id });
    }

    @State.bound
    private onComplexSearchMultiSelect(items: MedicalServiceExtensibleCodeSelectorItem[]) {
        this.props.onChange(items.map(item => ({ id: item.id, text: item.text })));
    }
    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticCareResources.ReferenceData.MedicalServices.CodeSelectorComplexSearchTitle,
            codeGetter: "code",
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect,
            hideAllgroup: true
        };
        return (
            <HisUi.UniversalCodeSelector
                {...props}
                isCreatable
                addNewItem={this.addNewItem}
            />
        );
    }
}

export default connect(
    MedicalServiceExtensibleCodeSelector,
    new DependencyAdapter<IMedicalServiceExtensibleCodeSelectorProps, IMedicalServiceExtensibleCodeSelectorDependencies>((container) => {
        return {
            medicalServiceApiAdapter: container.resolve("MedicalServiceApiAdapter"),
            medicationsApiAdapter: container.resolve("MedicationsApiAdapter"),
            localizationService: container.resolve("IToolkitLocalizationService"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
        };
    })
);
