import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import AuthorizationReferenceDataStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/AuthorizationReferenceDataStore";
import _ from "@HisPlatform/Common/Lodash";
import UserId from "@Primitives/UserId.g";
import UserGroupId from "@Primitives/UserGroupId.g";
import RoleAccessRule from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/RoleAccessRule";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";
import RoleId from "@Primitives/RoleId.g";
import RolePermissionConfigurationPanelView from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/RoleManagement/PermissionConfigurationPanel/RolePermissionConfigurationPanel/RolePermissionConfigurationPanelView";
import IPermissionScope from "@PluginInterface/OperationAccessControl/IPermissionScope";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";

interface IRolePermissionConfigurationPanelDependencies {
    authorizationReferenceDataStore: AuthorizationReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IRolePermissionConfigurationPanelProps {
    _dependencies?: IRolePermissionConfigurationPanelDependencies;
    subjectId: UserId | UserGroupId;
    accessRules: RoleAccessRule[];
    onAccessRulesChange: (newValues: RoleAccessRule[]) => void;
}

@State.observer
class RolePermissionConfigurationPanel extends React.Component<IRolePermissionConfigurationPanelProps> {
    @State.observable.ref private accessRuleToAdd: RoleAccessRule = null;

    private get authorizationReferenceDataStore() {
        return this.props._dependencies.authorizationReferenceDataStore;
    }

    private get organizationReferenceDataStore() {
        return this.props._dependencies.organizationReferenceDataStore;
    }

    @State.computed
    private get notDeletedAccessRules() {
        return this.props.accessRules.filter(i => !i.isDeleted);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IRolePermissionConfigurationPanelProps) {
        if (!ValueWrapper.equals(this.props.subjectId, prevProps.subjectId)) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    private async loadAsync() {
        await this.authorizationReferenceDataStore.roleMap.ensureAllLoadedAsync();
        await this.organizationReferenceDataStore.pointOfCareMap.ensureAllLoadedAsync();
        if (this.props.subjectId) {
            this.initializeNewAccessRule();
        }
    }

    @State.action.bound
    private initializeNewAccessRule() {
        this.accessRuleToAdd = new RoleAccessRule(true);
        this.accessRuleToAdd.subject = this.props.subjectId;
        this.accessRuleToAdd.takeSnapshot();
    }

    @State.bound
    private isRoleUsingScopeType(id: RoleId, permissionScopeType: string) {
        const role = this.authorizationReferenceDataStore.roleMap.get(id);
        return role?.permissions.definitions.some(d => d.scopes.some(s => s.type === permissionScopeType));
    }

    @State.bound
    private getScopeTypeValueForAccessRule(accessRule: RoleAccessRule, scopeType: string) {
        const scopesForAccessRule = accessRule.assignedScopes;
        if (!scopesForAccessRule) {
            return null;
        }
        const scopeTypeValuesForRole = scopesForAccessRule.find(r => r.type === scopeType);
        return scopeTypeValuesForRole;
    }

    @State.bound
    public getPointOfCareScopeValue(row: RoleAccessRule): IPermissionScope {
        return this.getScopeTypeValueForAccessRule(row, PermissionScopeType.pointOfCare);
    }

    @State.action.bound
    private addAccessRule() {
        if (!this.accessRuleToAdd?.roleId) {
            return;
        }

        // if the scope is needed but no value is selected default to all
        if (this.isRoleUsingScopeType(this.accessRuleToAdd.roleId, PermissionScopeType.pointOfCare) && !this.accessRuleToAdd.pointOfCareScopeValues?.length) {
            this.accessRuleToAdd.assignPointOfCareTypeScopeValues([], true);
        }

        const reAddedDeletedItem = this.props.accessRules.find(i => ValueWrapper.equals(i.roleId, this.accessRuleToAdd.roleId) && i.isDeleted);

        if (!!reAddedDeletedItem) {
            reAddedDeletedItem.isDeleted = false;
            this.props.onAccessRulesChange(this.props.accessRules);
        } else {
            this.props.onAccessRulesChange([...this.props.accessRules, this.accessRuleToAdd]);
        }

        this.initializeNewAccessRule();
    }

    @State.bound
    private getPointOfCareNames(scope: IPermissionScope) {
        if (!scope) {
            return "";
        }

        if (scope.isAll) {
            return StaticAuthorizationResources.PermissionConfiguration.PointOfCareScopeSelector.AllValue;
        }

        if (scope.mode === "positive") {
            const poCNames =
                this.organizationReferenceDataStore.pointOfCareMap.items
                    .filter(item => scope.includedValues.some(id => item.id.value === id)).map(d => d.name);
            return poCNames.join(", ");
        }

        if (scope.mode === "negative") {
            const poCNames =
                this.organizationReferenceDataStore.pointOfCareMap.items
                    .filter(item => scope.excludedValues.some(id => item.id.value === id)).map(d => d.name);
            return "-" + poCNames.join(", ");
        }

        return "";
    }

    public render() {
        return (
            <RolePermissionConfigurationPanelView
                accessRuleToAdd={this.accessRuleToAdd}
                accessRules={this.notDeletedAccessRules}
                addAccessRule={this.addAccessRule}
                getPointOfCareScopeValue={this.getPointOfCareScopeValue}
                isRoleUsingScopeType={this.isRoleUsingScopeType}
                getPointOfCareNames={this.getPointOfCareNames}
            />
        );
    }
}

export default connect(
    RolePermissionConfigurationPanel,
    new DependencyAdapter<IRolePermissionConfigurationPanelProps, IRolePermissionConfigurationPanelDependencies>(c => ({
        authorizationReferenceDataStore: c.resolve("AuthorizationReferenceDataStore"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
    }))
);