import State from "@Toolkit/ReactClient/Common/StateManaging";
import ConditionId from "@Primitives/ConditionId.g";
import moment from "moment";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";

export default class DiagnosesForEpisodeOfCareStore extends EntityStoreBase<EpisodeOfCareId> {
    @State.observable.ref public conditionIds: ConditionId[];
    @State.observable.ref public createdAt: moment.Moment;

    @State.action.bound
    public setConditionIds(conditionIds: ConditionId[]): void {
        this.conditionIds = conditionIds;
    }

    @State.action.bound
    public setCreatedAt(createdAt: moment.Moment): void {
        this.createdAt = createdAt;
    }
}