import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import CareActivityId from "@Primitives/CareActivityId.g";
import AccidentTypeId from "@Primitives/AccidentTypeId.g";
import HunFinancingClassId from "@Primitives/HunFinancingClassId.g";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IdentifierTypeId from "@Primitives/IdentifierTypeId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import { useFormExtensibleEnumIdField, useFormEntityIdentifierField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";

export default class CareActivityCoverageDataStore extends EntityStoreBase<CareActivityId> implements IDirtyChecked {
    @State.observable.ref public accidentTypeId: AccidentTypeId;
    @State.observable.ref public financingClassId: HunFinancingClassId;
    @State.observable.ref public identifierTypeId: IdentifierTypeId;
    @State.observable.ref public identifier: Identifier;

    @State.bound
    public takeSnapshot() {
        this._dirtyChecker.takeSnapshot(this);
    }

    @State.bound
    public isDirty() {
        return this._dirtyChecker.isDirty(this);
    }

    public static createFromFormFields(source: FormFieldDataBase[]): CareActivityCoverageDataStore {
        const result = new CareActivityCoverageDataStore();

        if (!isNullOrUndefined(source)) {
            const [accidentTypeId, setAccidentTypeId] = useFormExtensibleEnumIdField<AccidentTypeId>(source, "AccidentTypeId", AccidentTypeId);
            const [financingClassId, setFinancingClassId] = useFormExtensibleEnumIdField<HunFinancingClassId>(source, "HunFinancingClassId", HunFinancingClassId);
            const [identifierTypeId, setIdentifierTypeId] = useFormExtensibleEnumIdField<IdentifierTypeId>(source, "IdentifierTypeId", IdentifierTypeId);
            const [identifier, setIdentifier] = useFormEntityIdentifierField(source, "Identifier");

            result.accidentTypeId = accidentTypeId;
            result.financingClassId = financingClassId;
            result.identifierTypeId = identifierTypeId;
            result.identifier = identifier;

            result.takeSnapshot();
        }

        return result;
    }

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "validationContextStore",
        "operationInfo",
        "rowVersion"
    ];

    private _dirtyChecker: StoreDirtyChecker<CareActivityCoverageDataStore> = new StoreDirtyChecker<CareActivityCoverageDataStore>();

    @State.action.bound
    public setAccidentTypeId(id: AccidentTypeId) {
        this.accidentTypeId = id;
    }

    @State.action.bound
    public setFinancingClass(id: HunFinancingClassId) {
        this.financingClassId = id;
    }

    @State.action.bound
    public setIdentifierType(id: IdentifierTypeId) {
        this.identifierTypeId = id;
        this.financingClassId = null;
    }
}
