import React from "react";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import {Button, DataGrid, DatePicker, Flex, Modal, PdfViewer, ScrollView} from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiErrorSummary from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiErrorSummary";
import IEhiError from "@HunEHealthInfrastructurePlugin/Common/Model/IEhiError";
import IEhiCovidVaccinationListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IEhiCovidVaccinationListItem";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EhiOrganizationUnitSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/Organization/Components/Controls/EhiOrganizationUnitSelector/EhiOrganizationUnitSelector";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import EhiHealthcareProviderOrganizationUnitColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiHealthcareProviderOrganizationUnitColumn/EhiHealthcareProviderOrganizationUnitColumn";
import PractitionerColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerColumn/PractitionerColumn";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import EhiHealthcareProviderColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiHealthcareProviderColumn/EhiHealthcareProviderColumn";
import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";
import ClientSideValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidationBoundary";
import IDataGridDataSource from "@CommonControls/DataGrid/DataSource/IDataGridDataSource";
import PersonNameColumn from "@HisPlatformControls/DataGridColumns/PersonNameColumn";
import NameStore from "@Primitives/NameStore";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import {ContextAwareModal} from "@HisPlatformControls";

interface IEhiCovidVaccinationsListPanelViewProps {
    ehiErrors: IEhiError[];
    dataSource: IDataGridDataSource<IEhiCovidVaccinationListItem>;
    selectedDate: LocalDate;
    onSelectedDateChange: (newValue: LocalDate) => void;
    selectedPointOfCareId: PointOfCareId;
    onSelectedPointOfCareIdChange: (newValue: PointOfCareId) => void;
    isLoading: boolean;
    onQueryButtonClickedAsync: () => Promise<void>;
    onBack: () => void;
    extraFilterValidator: ClientSideValidator;
    localizationService: ILocalizationService;
    onShowDocumentAsync: (row: IEhiCovidVaccinationListItem) => Promise<void>;
    documentContent: Uint8Array;
    unsetDocumentContent: () => void;
}

@State.observer
export default class EhiCovidVaccinationsListPanelView extends React.Component<IEhiCovidVaccinationsListPanelViewProps> {

    @State.bound
    private getNameValue(name: NameStore) {
        return !isNullOrUndefined(name) ? this.props.localizationService.localizePersonName(name) : "";
    }
    
    @State.bound
    private renderExtraFilter() {
        return (
            <ClientSideValidationBoundary validator={this.props.extraFilterValidator} entityTypeName={"ExtraFilter"}>
                <Flex outerSpacing={"regular"}>
                    <Flex.Item xs={12}>
                        <Flex>
                            <Flex.Item xs={2}>
                                <DatePicker
                                    label={StaticHunEHealthInfrastructureCareResources.EhiCovidVaccinationList.DateSelector}
                                    value={this.props.selectedDate}
                                    onChange={this.props.onSelectedDateChange}
                                    automationId={"CovidVaccinationList_DatePicker"}
                                    propertyIdentifier={"SelectedDate"}
                                />
                            </Flex.Item>
                            <Flex.Item xs={2}>
                                <EhiOrganizationUnitSelector
                                    label={StaticHunEHealthInfrastructureCareResources.EhiCovidVaccinationList.PointOfCareSelector}
                                    value={this.props.selectedPointOfCareId}
                                    onChange={this.props.onSelectedPointOfCareIdChange}
                                    automationId={"CovidVaccinationList_OrgUnitSelector"}
                                    displayMode={"shorthand-name"}
                                    propertyIdentifier={"SelectedPointOfCare"}
                                />
                            </Flex.Item>
                            <Flex.Item xs={8} horizontalContentAlignment={"right"}>
                                <Button
                                    text={StaticHunEHealthInfrastructureCareResources.EhiCovidVaccinationList.Query}
                                    automationId={"CovidVaccinationList_QueryButton"}
                                    visualStyle={"primary"}
                                    iconName={"search"}
                                    iconTextOrder={"icon-text"}
                                    onClickAsync={this.props.onQueryButtonClickedAsync}
                                />
                            </Flex.Item>
                        </Flex>
                        <Flex>
                            <Flex.Item xs={12}>
                                <EhiErrorSummary errors={this.props.ehiErrors} />
                            </Flex.Item>
                        </Flex>
                    </Flex.Item>
                </Flex>
            </ClientSideValidationBoundary>
        );
    }

    @State.bound
    private onShowDocumentAsyncClickHandlerFactory(row: IEhiCovidVaccinationListItem) {
        return async () => {
            await this.props.onShowDocumentAsync(row);
        };
    }

    @State.bound
    private renderButtons(_: any, row: IEhiCovidVaccinationListItem) {
        return row?.ehiDocumentIdentifier && (
            <>
                <Button
                    iconName="document"
                    onClickAsync={this.onShowDocumentAsyncClickHandlerFactory(row)}
                    size="compact"
                    tooltipContent={StaticHunEHealthInfrastructureCareResources.EhiCovidVaccinationList.ShowDocument}
                    automationId={"showDocumentButton"}
                />
            </>
        ) || "";
    }
    
    public render() {
        return (
            <UseCaseFrame 
                title={StaticHunEHealthInfrastructureCareResources.EhiCovidVaccinationList.Title}
                fallbackFrameType="PageBox" 
                iconName="covid"
            >
                <DataGrid
                    fixedHeight="100%"
                    fixedLayout                    
                    extraFilter={this.renderExtraFilter()}
                    isLoading={this.props.isLoading}
                    isExtraFilterVisible
                    dataSource={this.props.dataSource}
                    automationId={"CovidVaccinationList_DataGrid"}
                    hasBackButton
                    generateInitialFilterStore
                    onBack={this.props.onBack}
                    changeOnMount
                    hasFilterClearButton
                >
                    <PersonNameColumn
                        id={"patientName"}
                        header={StaticHunEHealthInfrastructureCareResources.EhiCovidVaccinationList.PatientName}
                        dataGetter={"patientName"}
                        isFilterable
                        isOrderable
                        hideOverflow
                        clientSideOrderableValueGetter={this.getNameValue}
                        clientSideFilterableValueGetter={this.getNameValue}
                    />
                    <DataGridColumn
                        id={"patientIdentifier"}
                        header={StaticHunEHealthInfrastructureCareResources.EhiCovidVaccinationList.PatientIdentifier}
                        dataGetter={"patientIdentifier"}
                        isFilterable
                        isOrderable
                        hideOverflow
                    />
                    <EhiHealthcareProviderColumn
                        id={"careOrganization"}
                        header={StaticHunEHealthInfrastructureCareResources.EhiCovidVaccinationList.CareOrganization}
                        dataGetter={"careOrganizationId"}
                        validOn={this.props.selectedDate}
                        isFilterable
                        isOrderable
                        displayMode={"shorthand-name"}
                        hideOverflow
                    />
                    <EhiHealthcareProviderOrganizationUnitColumn
                        id={"careOrganizationUnit"}
                        header={StaticHunEHealthInfrastructureCareResources.EhiCovidVaccinationList.CareOrganizationUnit}
                        dataGetter={"careOrganizationUnitId"}
                        isFilterable
                        isOrderable
                        displayMode={"shorthand-name"}
                        hideOverflow
                    />
                    <PractitionerColumn
                        id={"carePractitioner"}
                        header={StaticHunEHealthInfrastructureCareResources.EhiCovidVaccinationList.CarePractitioner}
                        dataGetter={"careDoctorId"}
                        isFilterable
                        isOrderable
                        displayMode={"code-name"}
                        hideOverflow
                    />
                    <DataGridDateTimeColumn
                        id={"beginningOfCare"}
                        header={StaticHunEHealthInfrastructureCareResources.EhiCovidVaccinationList.StartOfCare}
                        dataGetter={"beginningOfCare"}
                        isFilterable
                        isOrderable
                        hideOverflow
                    />
                    <DataGridDateTimeColumn
                        id={"endOfCare"}
                        header={StaticHunEHealthInfrastructureCareResources.EhiCovidVaccinationList.EndOfCare}
                        dataGetter={"endOfCare"}
                        isFilterable
                        isOrderable
                        hideOverflow
                    />
                    <DataGridColumn
                        id={"ehiCareIdentifier"}
                        header={StaticHunEHealthInfrastructureCareResources.EhiCovidVaccinationList.EhiCareIdentifier}
                        dataGetter={"ehiCareIdentifier"}
                        isFilterable
                        isOrderable
                        hideOverflow
                    />
                    <DataGridColumn
                        id={"ehiDocumentIdentifier"}
                        header={StaticHunEHealthInfrastructureCareResources.EhiCovidVaccinationList.EhiDocumentIdentifier}
                        dataGetter={"ehiDocumentIdentifier"}
                        isFilterable
                        isOrderable
                        hideOverflow
                    />
                    <DataGridColumn
                        id={"buttons"}
                        width={"45px"}
                        cellTextAlign={"center"}
                        onRenderCellValue={this.renderButtons}
                    />
                </DataGrid>
                <ContextAwareModal isOpen={!!this.props.documentContent} onClose={this.props.unsetDocumentContent} closeOnOutsideClick={false}>
                    <Modal.Body noGap>
                        <ScrollView>
                            <PdfViewer binaryPdf={this.props.documentContent} />
                        </ScrollView>
                    </Modal.Body>
                </ContextAwareModal>
            </UseCaseFrame>
        );
    }
}