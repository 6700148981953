import * as React from "react";
import * as HisUi from "@HisPlatformControls";
import SurgeryApiAdapter from "@HisPlatform/BoundedContexts/Surgery/ApplicationLogic/ApiAdapter/SurgeryApiAdapter";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SurgeryReferenceDataStore from "@HisPlatform/BoundedContexts/Surgery/ApplicationLogic/Model/SurgeryReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IPagingState, IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import SurgeryTypeDefinitionId from "@Primitives/SurgeryTypeDefinitionId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ISurgeryTypeDefinition from "@HisPlatform/BoundedContexts/Surgery/ApplicationLogic/Model/ISurgeryTypeDefinition";
import StaticSurgeryResources from "@HisPlatform/BoundedContexts/Surgery/StaticResources/StaticSurgeryResources";

interface ISurgeryTypeDefinitionSelectorDependencies {
    surgeryApiAdapter: SurgeryApiAdapter;
    surgeryReferenceDataStore: SurgeryReferenceDataStore;
}

interface ISurgeryTypeDefinitionSelectorProps extends ICodeSelectorCommonProps<SurgeryTypeDefinitionId> {
    _dependencies?: ISurgeryTypeDefinitionSelectorDependencies;
}

@State.observer
class SurgeryTypeDefinitionSelector extends React.Component<ISurgeryTypeDefinitionSelectorProps> {

    private get dependencies() { return this.props._dependencies; }
    private get apiAdapter() { return this.dependencies.surgeryApiAdapter; }
    private get surgeryTypeReferenceData() { return this.dependencies.surgeryReferenceDataStore.surgeryTypeDefinitions; }

    @State.bound
    private getDisplayValue(value: SurgeryTypeDefinitionId) {
        if (isNullOrUndefined(value)) { return null; }
        const item = this.surgeryTypeReferenceData.get(value);
        return (<> <b>{item?.code}</b> {item?.name}</>);
    }

    @State.bound
    private async getDisplayValueAsync(value: SurgeryTypeDefinitionId) {
        if (isNullOrUndefined(value)) { return null; }
        const item = await this.surgeryTypeReferenceData.getOrLoadAsync(value);
        return <><b>{item.code}</b>{` ${item.name}`}</>;
    }

    @State.bound
    private async getTextValueAsync(value: SurgeryTypeDefinitionId) {
        if (isNullOrUndefined(value)) { return null; }
        const item = await this.surgeryTypeReferenceData.getOrLoadAsync(value);
        return `${item.code} ${item.name}`;
    }

    @State.bound
    private async quickSearchAsync(text: string): Promise<SurgeryTypeDefinitionId[]> {
        const results = await this.apiAdapter.quickSearchSurgeryTypeDefinitionAsync(text, 10);
        return results.value;
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: ISurgeryTypeDefinition[])
        : Promise<IPagedItems<ISurgeryTypeDefinition>> {
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;
        const result = await this.apiAdapter.searchSurgeryTypeDefinitionAsync(
            filterText,
            customOrdering,
            paging);
        await this.surgeryTypeReferenceData.ensureLoadedAsync(result.items);
        const values = this.surgeryTypeReferenceData.multiGet(result.items);
        return ({
            items: values,
            totalCount: result.totalCount
        });
    }

    @State.bound
    private onComplexSearchSingleSelect(item: ISurgeryTypeDefinition) {
        this.props.onChange(item.id);
    }

    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            getDisplayValue: this.getDisplayValue,
            getTextValueAsync: this.getTextValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            multiSelect: false,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticSurgeryResources.SurgeryTypeDefinitionList.Title,
            codeGetter: "code",
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            hideAllgroup: true
        };
        return (
            <HisUi.UniversalCodeSelector
                {...props}
            />
        );
    }
}

export default connect(
    SurgeryTypeDefinitionSelector,
    new DependencyAdapter<ISurgeryTypeDefinitionSelectorProps, ISurgeryTypeDefinitionSelectorDependencies>((container) => {
        return {
            surgeryApiAdapter: container.resolve("SurgeryApiAdapter"),
            surgeryReferenceDataStore: container.resolve("SurgeryReferenceDataStore"),
        };
    })
);
