import EhiSelfDeterminationId from "@Primitives/EhiSelfDeterminationId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class EhiMedicalConditionHistoryItem {
    @State.observable public versionNumber: number = null;
    @State.observable public isDeleted: boolean = false;
    @State.observable public ehiVersion: number = null;
    @State.observable public selfDetermination: EhiSelfDeterminationId = null;

    @State.action.bound
    public setSelfDetermination(newValue: EhiSelfDeterminationId) {
        const value = isNullOrUndefined(newValue) ? null : newValue;
        this.selfDetermination = value;
    }

    public static createFromExtensionDto(source: any) {
        const result = new EhiMedicalConditionHistoryItem();

        if (!isNullOrUndefined(source)) {
            const extensionData = source["HunEHealthInfrastructure_EhiMedicalConditionInfo"];

            if (!isNullOrUndefined(extensionData)) {
                result.versionNumber = !isNullOrUndefined(extensionData.VersionNumber) ? extensionData.VersionNumber : null;
                result.isDeleted = !isNullOrUndefined(extensionData.IsDeleted) ? extensionData.IsDeleted : null;
                result.ehiVersion = !isNullOrUndefined(extensionData.EhiVersion) ? extensionData.EhiVersion : null;
                result.selfDetermination = !isNullOrUndefined(extensionData.SelfDetermination) ? new EhiSelfDeterminationId(extensionData.SelfDetermination.Value) : null;
            }
        }
        return result;
    }
}
