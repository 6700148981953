import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import PatientId from "@Primitives/PatientId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";

@Di.injectable()
export default class ScalarValueChartWidgetApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("ICareRegisterClient") private readonly apiClient: Proxy.ICareRegisterClient
    ) {
        super();
    }

    @State.bound
    public getScalarObservationDefinitionsByPatientIdQueryAsync(patientId: string) {
        return this.processOperationAsync(
            new SimpleStore<Proxy.ObservationDefinitionDto[]>(),
            async target => {
                const response = await this.apiClient.getScalarObservationDefinitionsByPatientIdQueryAsync(CreateRequestId(), patientId);

                target.operationInfo = createOperationInfo(response);
                target.value = response.definitions;
            }
        );
    }

    @State.bound
    public getScalarObservationValuesByDefinitionQueryAsync(definitionId: string, patientId: string) {
        return this.processOperationAsync(
            new SimpleStore<Proxy.ScalarObservationValueDto>(),
            async target => {
                const response = await this.apiClient.getScalarObservationValuesByDefinitionQueryAsync(CreateRequestId(), definitionId, patientId);

                target.operationInfo = createOperationInfo(response);
                target.value = response.scalarObservationValueDto;
            }
        );
    }
}