import React, { useMemo, useCallback } from "react";
import ContextMenu from "@CommonControls/ContextMenu";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import { useHisActionDispatcher } from "@HisPlatform/Common/FrontendActions/HisActionDispatcher";
import IListItemActionPresentation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/FrontendActions/IListItemActionPresentation";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";

interface IFrontendListSecondaryMenuItemProps {
    isGroup: boolean;
    descriptor: ActionDescriptor<IListItemActionPresentation>;
}


export default function FrontendListSecondaryMenuItem(props: React.PropsWithChildren<IFrontendListSecondaryMenuItemProps>) {

    const actionDispatcher = useHisActionDispatcher();
    const displaySettings = useMemo(() => actionDispatcher.getDisplaySettings(props.descriptor.action), [props.descriptor]);

    const clickAsync = useCallback(async () => {
        await actionDispatcher.dispatchAsync(props.descriptor.action);
    }, [props.descriptor, actionDispatcher]);

    // TODO: beordogh const groupIcon = useMemo(() => props.isGroup ? props._activityRegistry?.getGroupByName(props.textResourceId?.value)?.iconName : null, [props.textResourceId?.value, props.isGroup]);

    if (props.isGroup) {
        return (
            <ContextMenu.SubMenuItem
                label={(
                    <SpanWithIcon iconName="angleRight" automationId={displaySettings.displayName}>{displaySettings.displayName}</SpanWithIcon>
                )}
            >
                {props.children}
            </ContextMenu.SubMenuItem>
        );
    }

    return (
        <ContextMenu.Item disabled={!props.descriptor.presentation.isEnabled} onClickAsync={clickAsync}>
            <SpanWithIcon iconName={displaySettings.iconName} automationId={props.descriptor.action.id.value}>{displaySettings.displayName}</SpanWithIcon>
        </ContextMenu.Item>
    );
}