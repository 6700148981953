import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IMedicalAlertHintItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalAlertHintItem";
import MedicalAlertId from "@Primitives/MedicalAlertId.g";
import IMedicalAlert from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalAlert";
import ComplexSearchMedicalAlertsQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalAlerts/Enum/ComplexSearchMedicalAlertsQueryOrderingFields.g";

@Di.injectable()
export default class MedicalAlertsApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IMedicalAlertsClient") private readonly apiClient: Proxy.IMedicalAlertsClient
    ) {
        super();
    }

    public searchMedicalAlerts(freeText: string, maxResultCount: number) {
        return this.processOperationAsync(
            new SimpleStore<IMedicalAlertHintItem[]>(),
            async target => {
                const response = await this.apiClient.searchMedicalAlertsQueryAsync(
                    CreateRequestId(),
                    freeText,
                    maxResultCount.toString()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.medicalAlerts.map(ai => {
                    return {
                        id: ai.id,
                        code: ai.code,
                        name: ai.name
                    } as IMedicalAlertHintItem;
                });
            }
        );
    }

    public getAllIdsAsync(): Promise<SimpleStore<MedicalAlertId[]>> {
        return this.processOperationAsync(
            new SimpleStore<MedicalAlertId[]>(),
            async target => {
                const response = await this.apiClient.getMedicalAlertIdsQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.ids.map(id => id);
            }
        );
    }

    @State.bound
    public loadMedicalAlertsAsync(ids: MedicalAlertId[]): Promise<SimpleStore<IMedicalAlert[]>> {
        return this.processOperationAsync(
            new SimpleStore<IMedicalAlert[]>(),
            async (target) => {
                const response = await this.apiClient.getMedicalAlertsByIdsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetMedicalAlertsByIdsControllerDto({ ids: ids })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.medicalAlerts.map(this.mapMedicalAlert);
            }
        );
    }

    private mapMedicalAlert(ai: Proxy.MedicalAlertDto): IMedicalAlert {
        return {
            id: ai.id,
            code: ai.code,
            name: ai.name
        } as IMedicalAlert;
    }

    @State.bound
    public complexSearchMedicalAlerts(
        freeText: string,
        ordering: IOrderingState,
        paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<IMedicalAlert>>(),
            async (target) => {
                const response = await this.apiClient.complexSearchMedicalAlertsQueryAsync(
                    CreateRequestId(),
                    new Proxy.ComplexSearchMedicalAlertsControllerDto({
                        freeText: freeText,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfComplexSearchMedicalAlertsQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfComplexSearchMedicalAlertsQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: ComplexSearchMedicalAlertsQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results && response.results.values.map(this.mapMedicalAlert)
                };

            }
        );
    }
}
