exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PropertyGroupGrid_no-text-selection_3KZTi {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.PropertyGroupGrid_container_1Mk4Y {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n}\n.PropertyGroupGrid_label-container_1dCI_ {\n  flex: 0 0 auto;\n  max-width: calc(100% - 23px);\n}\n.PropertyGroupGrid_icon-container_1po8e {\n  flex: \"0 0 16px\";\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "PropertyGroupGrid_no-text-selection_3KZTi",
	"noTextSelection": "PropertyGroupGrid_no-text-selection_3KZTi",
	"container": "PropertyGroupGrid_container_1Mk4Y",
	"label-container": "PropertyGroupGrid_label-container_1dCI_",
	"labelContainer": "PropertyGroupGrid_label-container_1dCI_",
	"icon-container": "PropertyGroupGrid_icon-container_1po8e",
	"iconContainer": "PropertyGroupGrid_icon-container_1po8e"
};