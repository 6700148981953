import React from "react";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import EpisodeOfCareStatus from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/EpisodeOfCareStatus.g";

const EpisodeOfCareStatusSelectBox: React.FC<IUniversalEnumSelectorBaseProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumOrigin="server"
        enumName={"EpisodeOfCareStatus"}
        enumType={EpisodeOfCareStatus}
        displayMode="selectBox"
    />
);

export default EpisodeOfCareStatusSelectBox;