import CareActivityId from "@Primitives/CareActivityId.g";
import Command from "@Toolkit/CommonWeb/CommandProcessing/Definition/Command";

export default class CreateServiceRequestCommand extends Command {
    public careActivityId: CareActivityId;

    constructor(
        originator: string,
        public readonly serviceCode: string | undefined) {
        super("CreateServiceRequestCommand", originator);
    }
}