import StoreMapper from "@Toolkit/CommonWeb/ApiAdapter/StoreMapper";
import EhiEvent from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEvent";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import IEhiResult from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiResult";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import EhiErrorSeverity from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiErrorSeverity";
import Di from "@Di";

@Di.injectable()
export default class EhiEventStoreMapper extends StoreMapper<SimpleStore<IEhiResult<EhiEvent>>, Proxy.GetEhiCareActivityDetailsQueryResponse> {
    protected applyToStoreCore(target: SimpleStore<IEhiResult<EhiEvent>>, response: Proxy.GetEhiCareActivityDetailsQueryResponse) {
        const dto = response.event;

        target.value = {
            value: null,
            errors: response.errors?.map(e => new EhiError(e.code, e.moduleName, e.message, e.ehiErrorSeverity as any as EhiErrorSeverity))
        };

        if (dto != null) {
            const event = new EhiEvent();

            event.ehiId = dto.ehiId;
            event.beginningOfCare = dto.beginningOfCare;
            event.careDoctorCode = dto.careDoctorCode;
            event.careDoctorId = dto.careDoctorId;
            event.careIdentifier = dto.careIdentifier;
            event.patientIdentifier = dto.patientIdentifier;
            event.patientIdentifierTypeId = dto.ehiPatientIdentifierTypeId;
            event.patientAgeInYears = dto.patientAgeInYears;
            event.patientZipCode = dto.patientZipCode;

            event.ehiCareTypeId = dto.ehiCareTypeId;
            event.ehiDischargeReasonId = dto.ehiDischargeReasonId;
            event.ehiInpatientAdmissionTypeId = dto.ehiInpatientAdmissionTypeId;
            event.ehiInpatientAdmissionClassificationId = dto.ehiInpatientAdmissionClassificationId;
            event.ehiPatientFurtherTreatmentId = dto.ehiPatientFurtherTreatmentId;
            event.ehiEmergencyLevelClaimTypeId = dto.ehiEmergencyLevelClaimTypeId;
            event.ehiEmergencyExaminationClaimTypeId = dto.ehiEmergencyExaminationClaimTypeId;

            event.ehiEventTypeId = dto.ehiEventTypeId;
            event.beginningOfCare = dto.beginningOfCare;
            event.endOfCare = dto.endOfCare;
            event.careOrganizationUnitCode = dto.careOrganizationUnitCode;
            event.careOrganizationUnitId = dto.careOrganizationUnitId;
            event.ehiEReferralServiceTypeId = dto.ehiEReferralServiceTypeId;
            event.careDoctorCode = dto.careDoctorCode;
            event.careDoctorId = dto.careDoctorId;
            event.careIdentifier = dto.careIdentifier;
            event.registrationNumber = dto.registrationNumber;
            event.nursingNumber = dto.nursingNumber;
            event.mainDiagnosisJustifyingNursing = dto.mainDiagnosisJustifyingNursing;
            event.mainDiagnosisJustifyingNursingId = dto.mainDiagnosisJustifyingNursingId;
            event.rescuePickUpPlace = dto.rescuePickUpPlace;
            event.rescueHandoverPlace = dto.rescueHandoverPlace;
            event.rescueDataSheetNumber = dto.rescueDataSheetNumber;
            event.rescueReceivingInstituteId = dto.rescueReceivingInstituteId;
            event.rescueReceivingInstituteCode = dto.rescueReceivingInstituteCode;

            event.referralWorkplaceCode = dto.referralWorkplaceCode;
            event.referralWorkplaceId = dto.referralWorkplaceId;
            event.referralDoctorCode = dto.referralDoctorCode;
            event.referralDoctorId = dto.referralDoctorId;
            event.referralDiagnosisCode = dto.referralDiagnosisCode;
            event.referralDiagnosisId = dto.referralDiagnosisId;
            event.referralCareIdentifier = dto.eReferralCareIdentifier;
            event.eReferralEhiId = dto.eReferralEhiId;

            event.version = dto.version;
            event.lastModifiedAt = dto.lastModifiedAt;
            event.lastModifiedBy = dto.lastModifiedBy;
            event.createdAt = dto.createdAt;
            event.createdBy = dto.createdBy;

            event.data = dto.data;
            event.mimeType = dto.mimeType;
            target.value.value = event;
        }
    }
}