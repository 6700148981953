import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { Modal } from "@CommonControls";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { ICareActivityStatusChangeNotPossibleDialogParams, ICareActivityStatusChangeNotPossibleDialogResult } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityStatusChangeNotPossiblePanel/CareActivityStatusChangeNotPossibleDialogParams";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { ContextAwareModal } from "@HisPlatformControls";
import IStatusChangeBlockingReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/IStatusChangeBlockingReason";
import DocumentManagementReferenceDataStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentManagementReferenceDataStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import StateChangeBlockingData from "@Primitives/StateChangeBlockingData.g";
import IStateChangeBlockingRegistry from "@PluginInterface/BoundedContexts/Care/CareRegister/StateChange/IStateChangeBlockingRegistry";
import { formatString } from "@Toolkit/CommonWeb/Formatters";

interface IStatusChangeBlockingDataInfo {
    stateChangeBlockingData: StateChangeBlockingData;
    message?: string;
}

interface ICareActivityStatusChangeNotPossiblePanelDependencies {
    localizationService: ILocalizationService;
    careActivityApiAdapter: CareActivityApiAdapter2;
    referenceDataStore: DocumentManagementReferenceDataStore;
    stateChangeBlockingRegistry: IStateChangeBlockingRegistry;
}

interface ICareActivityStatusChangeNotPossiblePanelProps extends
    IModalComponentParams<ICareActivityStatusChangeNotPossibleDialogResult>,
    ICareActivityStatusChangeNotPossibleDialogParams {
    _dependencies?: ICareActivityStatusChangeNotPossiblePanelDependencies;
}

/** @screen */
@State.observer
class CareActivityStatusChangeNotPossiblePanel extends React.Component<ICareActivityStatusChangeNotPossiblePanelProps> {

    @State.observable.ref private statusChangeBlockingDataInfos: IStatusChangeBlockingDataInfo[] = [];

    private get dependencies() {
        return this.props._dependencies;
    }

    private get documentTypeMap() {
        return this.props._dependencies.referenceDataStore.documentTypeMap;
    }

    @State.computed private get title() {
        return this.dependencies.localizationService.localizeReferenceData(this.props.actionTitleResourceId);
    }

    @State.computed private get message() {
        const activity = this.dependencies.localizationService.localizeReferenceData(this.props.activityReferenceResourceId);
        return formatString(StaticWebAppResources.DailyPatientList.CareActivityStateChangeNotPossible, activity);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.action.bound
    private async loadAsync() {
        await this.props._dependencies.referenceDataStore.documentTypeMap.ensureAllLoadedAsync();

        this.setStatusChangeBlockingDataInfos(this.props.reasons.map(r => ({
            stateChangeBlockingData: r.stateChangeBlockingData,
            message: this.getMessage(r)
        } as IStatusChangeBlockingDataInfo)));
    }

    @State.action.bound
    private setStatusChangeBlockingDataInfos(statusChangeBlockingDataInfos: IStatusChangeBlockingDataInfo[]) {
        this.statusChangeBlockingDataInfos = statusChangeBlockingDataInfos;
    }

    @State.bound
    private navigateTo(navigateTo: StateChangeBlockingData) {
        this.props.onClose({
            navigateTo
        });
    }

    @State.bound
    private cancel() {
        this.props.onClose(null);
    }

    @State.bound
    private getMessage(reason: IStatusChangeBlockingReason): string {
        return this.dependencies.stateChangeBlockingRegistry.getMessage(this.dependencies.localizationService, reason.stateChangeBlockingData, reason);
    }

    @State.bound
    private onClickHandlerFactory(r: IStatusChangeBlockingDataInfo) {
        return () => this.navigateTo(r.stateChangeBlockingData);
    }

    public render() {
        return (
            <ContextAwareModal onClose={this.cancel} isOpen title={this.title} size="compact">
                <Modal.Body>
                    {this.message}
                    <ul>
                        {this.statusChangeBlockingDataInfos.map((r, i) =>
                            <li key={i}>
                                <a onClick={this.onClickHandlerFactory(r)}>
                                    {this.dependencies.stateChangeBlockingRegistry.getTitle(this.dependencies.localizationService, r.stateChangeBlockingData)}
                                </a>
                                {!!r.message && ` - ${r.message}`}
                            </li>
                        )}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Modal.Button closeModalOnClick visualStyle="primary" automationId="okButton" float="right">
                        {StaticWebAppResources.Common.DialogButton.Ok}
                    </Modal.Button>
                </Modal.Footer>
            </ContextAwareModal>
        );
    }
}

export default connect(
    CareActivityStatusChangeNotPossiblePanel,
    new DependencyAdapter<ICareActivityStatusChangeNotPossiblePanelProps, ICareActivityStatusChangeNotPossiblePanelDependencies>(container => {
        return {
            localizationService: container.resolve("ILocalizationService"),
            careActivityApiAdapter: container.resolve("CareActivityApiAdapter2"),
            referenceDataStore: container.resolve("DocumentManagementReferenceDataStore"),
            stateChangeBlockingRegistry: container.resolve("IStateChangeBlockingRegistry")
        };
    })
);
