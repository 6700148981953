import React from "react";
import flexboxGridStyles from "./flexboxgrid.less";
import styles from "./flex.less";
import FlexItem from "./FlexItem";
import State from "@Toolkit/ReactClient/Common/StateManaging";

type justify = "start" | "center" | "end" | "between" | "around";
type verticalAlign = "top" | "middle" | "bottom";
type spacingType = 0 | 8 | 16 | 24 | 40 | 64 | 80 | "none" | "tiny" | "small" | "regular" | "medium" | "large" | "extra large";

export interface IFlexProps {
    xsJustify?: justify;
    smJustify?: justify;
    mdJustify?: justify;
    lgJustify?: justify;
    xsVerticalAlign?: verticalAlign;
    smVerticalAlign?: verticalAlign;
    mdVerticalAlign?: verticalAlign;
    lgVerticalAlign?: verticalAlign;
    innerSpacing?: spacingType;
    outerSpacing?: spacingType;
    spacing?: spacingType;
    style?: React.CSSProperties;
    className?: string;
    id?: string;
    name?: string;
    verticalSpacing?: spacingType;
    automationId?: string;
    noWrap?: boolean;
}

export default class Flex extends React.PureComponent<IFlexProps> {

    
    public static Item = FlexItem;

    public static defaultProps: Partial<IFlexProps> = {
        verticalSpacing: "tiny"
    };

    private getSpacingClass(spacing: spacingType) {
        switch (spacing) {
            case 0:
            case "none":
                return styles.spacing0;
            case 8:
            case "tiny":
                return styles.spacing8;
            case 16:
            case "small":
                return styles.spacing16;
            case 24:
            case "regular":
                return styles.spacing24;
            case 40:
            case "medium":
                return styles.spacing40;
            case 64:
            case "large":
                return styles.spacing64;
            case 80:
            case "extra large":
                return styles.spacing80;
            default:
                return null;
        }
    }

    private getOuterSpacingClass(spacing: spacingType) {
        switch (spacing) {
            case 0:
            case "none":
                return styles.outerSpacing0;
            case 8:
            case "tiny":
                return styles.outerSpacing8;
            case 16:
            case "small":
                return styles.outerSpacing16;
            case 24:
            case "regular":
                return styles.outerSpacing24;
            case 40:
            case "medium":
                return styles.outerSpacing40;
            case 64:
            case "large":
                return styles.outerSpacing64;
            case 80:
            case "extra large":
                return styles.outerSpacing80;
            default:
                return null;
        }
    }

    private getVerticalSpacingClass(spacing: spacingType) {
        switch (spacing) {
            case 8:
            case "tiny":
                return styles.vSpacing8;
            case 16:
            case "small":
                return styles.vSpacing16;
            case 24:
            case "regular":
                return styles.vSpacing24;
            case 40:
            case "medium":
                return styles.vSpacing40;
            case 64:
            case "large":
                return styles.vSpacing64;
            case 80:
            case "extra large":
                return styles.vSpacing80;
            case 0:
            case "none":
            default:
                return null;
        }
    }

    @State.computed
    public get classNames() {
        const classNames = [flexboxGridStyles.row];
        if (this.props.xsJustify) { classNames.push((flexboxGridStyles as any)[this.props.xsJustify + "Xs"]); }
        if (this.props.smJustify) { classNames.push((flexboxGridStyles as any)[this.props.smJustify + "Sm"]); }
        if (this.props.mdJustify) { classNames.push((flexboxGridStyles as any)[this.props.mdJustify + "Md"]); }
        if (this.props.lgJustify) { classNames.push((flexboxGridStyles as any)[this.props.lgJustify + "Lg"]); }

        if (this.props.xsVerticalAlign) { classNames.push((flexboxGridStyles as any)[this.props.xsVerticalAlign + "Xs"]); }
        if (this.props.smVerticalAlign) { classNames.push((flexboxGridStyles as any)[this.props.smVerticalAlign + "Sm"]); }
        if (this.props.mdVerticalAlign) { classNames.push((flexboxGridStyles as any)[this.props.mdVerticalAlign + "Md"]); }
        if (this.props.lgVerticalAlign) { classNames.push((flexboxGridStyles as any)[this.props.lgVerticalAlign + "Lg"]); }

        const spacingClass = this.getSpacingClass(this.props.spacing);
        const innerSpacingClass = this.getSpacingClass(this.props.innerSpacing);
        const outerSpacingClass = this.getOuterSpacingClass(this.props.outerSpacing);
        const verticalSpacingClass = this.getVerticalSpacingClass(this.props.verticalSpacing);

        if (innerSpacingClass) {
            classNames.push(innerSpacingClass);
            if (!outerSpacingClass && !spacingClass) {
                classNames.push(styles.outerSpacing0);
            }
        }

        if (outerSpacingClass) {
            classNames.push(outerSpacingClass);
        }

        if (spacingClass) {
            classNames.push(spacingClass);
        }

        if (verticalSpacingClass) {
            classNames.push(verticalSpacingClass);
        }

        if (this.props.className) {
            classNames.push(this.props.className);
        }

        if (this.props.noWrap) {
            classNames.push(flexboxGridStyles.nowrap);
        }

        return classNames.join(" ");
    }

    constructor(props: IFlexProps) {
        super(props);
    }

    public render() {
        const htmlProps = {
            style: this.props.style,
            id: this.props.id,
            name: this.props.name
        };
        return (<div {...htmlProps} className={this.classNames} data-automation-id={this.props.automationId || undefined}>{this.props.children}</div>);
    }
}