import React from "react";
import IDiagnosisAdditionalActionsProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IDiagnosisAdditionalActionsProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import PatientId from "@Primitives/PatientId.g";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import HunCareApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/HunCareApiAdapter";

interface IDiagnosisAdditionalActionsPanelDependencies {
    hunCareApiAdapter: HunCareApiAdapter;
}

interface IDiagnosisAdditionalActionsPanelProps extends IDiagnosisAdditionalActionsProps {
    _dependencies?: IDiagnosisAdditionalActionsPanelDependencies;
}

@State.observer
class DiagnosisAdditionalActionsPanel extends React.Component<IDiagnosisAdditionalActionsPanelProps> {
    @State.observable private showButton: boolean;
    @State.observable private isButtonToggled: boolean;

    public componentDidMount(): void {
        this.init();
    }

    private init() {
        this.setShowButton(true);
        this.setButtonToggle(this.props.selectedItem === this.props.diagnosisStore);
    }

    public componentDidUpdate(prevProps: IDiagnosisAdditionalActionsPanelProps) {
        const diagnosisStoreChanged = this.props.diagnosisStore !== prevProps.diagnosisStore;

        if (diagnosisStoreChanged || this.props.selectedItem !== prevProps.selectedItem) {
            this.setButtonToggle(this.props.diagnosisStore === this.props.selectedItem);
        }
    }

    @State.action.bound
    private setShowButton(newValue: boolean) {
        this.showButton = newValue;
    }

    @State.action.bound
    private setButtonToggle(newValue: boolean) {
        this.isButtonToggled = newValue;
    }

    @State.bound
    private onClick() {
        this.props.setSelectedItem(this.props.diagnosisStore);
    }

    @State.bound
    private onCheckBoxClick(newValue: boolean) {
        this.props.setSelectedItem(this.props.diagnosisStore);
    }

    @State.bound
    private renderCheckBox() {
        return (
            <Ui.CheckBox
                value={this.isButtonToggled}
                onChange={this.onCheckBoxClick}
                displayMode="toggle-button"
                toggleButtonProps={{ iconName: "disease", size: "compact", float: "right", automationId: "contagiousPatientReportButton" }}
                verticalAlign="noPadding"
                automationId="toggleButtonCheckBox"
            />
        );
    }

    @State.bound
    private renderButton() {
        return (
            <ReadOnlyContext.Provider value={false}>
                <Ui.Button
                    iconName="disease"
                    size="compact"
                    float="right"
                    onClick={this.onClick}
                    automationId="contagiousPatientReportButton"
                />
            </ReadOnlyContext.Provider>);
    }

    @State.bound
    private renderCore() {
        return this.props.selectedItem
            ? this.renderCheckBox()
            : this.renderButton();
    }

    public render() {
        return this.showButton ? this.renderCore() : <></>;
    }
}

export default connect(
    DiagnosisAdditionalActionsPanel,
    new DependencyAdapter<IDiagnosisAdditionalActionsPanelProps, IDiagnosisAdditionalActionsPanelDependencies>(container => {
        return {
            hunCareApiAdapter: container.resolve("HunCareApiAdapter")
        };
    })
);
