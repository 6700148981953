import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import HunWorklistApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/HunWorklistApiAdapter";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import CareRegisterReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareRegisterReferenceDataStore";
import PerformanceReportScorePlanId from "@Primitives/PerformanceReportScorePlanId.g";
import PerformanceReportScorePlanApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/PerformanceReportScorePlanApiAdapter";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import StaticHunSocialSecurityReportingResources from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/StaticResources/StaticHunSocialSecurityReportingResources";
import ReportingApiAdapter from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/ReportingApiAdapter";
import ReportDefinitionIdentifier from "@Primitives/ReportDefinitionIdentifier.g";

interface IPerformanceReportScorePlanWorklistPanelDependencies {
    applicationContext: ApplicationContext;
    worklistApiAdapter: HunWorklistApiAdapter;
    careRegisterReferenceDataStore: CareRegisterReferenceDataStore;
    performanceReportScorePlanApiAdapter: PerformanceReportScorePlanApiAdapter;
    reportingApiAdapter: ReportingApiAdapter;
}

export interface IPerformanceReportScorePlanWorklistPanelProps {
    _dependencies?: IPerformanceReportScorePlanWorklistPanelDependencies;
    onBack: () => void;
    useCaseState: INDataUseCaseState;
    selectedRowId: string;
    onChange: (selectedRowId: string, useCaseState: INDataUseCaseState) => void;
    onCloseDetail: () => void;
    onSelectedPerformanceReportScorePlanIdChange: (performanceReportScorePlanId: PerformanceReportScorePlanId) => void;
}

/** @screen */
@State.observer
class PerformanceReportScorePlanWorklistPanel extends React.Component<IPerformanceReportScorePlanWorklistPanelProps> {

    @State.observable.ref private worklistDefinition: IWorklistDefinition = null;

    private readonly refreshListEvent = new TypedAsyncEvent();

    @State.computed private get hasNewRow() { return this.props.selectedRowId === "new"; }

    @State.computed private get permissionCheckedOperations() {
        return async () => await this.props._dependencies.reportingApiAdapter.runReportAsync(new ReportDefinitionIdentifier("HunSocialSecurity_PerformanceReport"), {} as JSON, true);
    }

    private readonly loadAsync = createInitialPanelLoader(this.initializePanelAsync, this.permissionCheckedOperations);

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.bound
    private async initializePanelAsync() {
        const [worklistDefinition] = await Promise.all(
            [
                this.props._dependencies.worklistApiAdapter.getPerformanceReportScorePlanBoundWorklistDefinitionAsync()
            ]);
            
        State.runInAction(() => {
            this.worklistDefinition = worklistDefinition.value;
        });

        await this.props._dependencies.careRegisterReferenceDataStore.financingClass.ensureLoadedAsync();
    }

    @State.action.bound
    private async closeDetailAsync() {
        await this.refreshListEvent.emitAsync();
        this.props.onCloseDetail?.();
    }

    @State.action.bound
    private getPanelProps(useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) {
        return {
            onPerformanceReportScorePlanCreatedAsync: this.performanceReportScorePlanCreatedAsync,
            onDeletePerformanceReportScorePlanAsync: this.closeDetailAsync
        };
    }

    @State.action.bound
    private async performanceReportScorePlanCreatedAsync(id: PerformanceReportScorePlanId) {
        await this.refreshListEvent.emitAsync();
        this.props.onSelectedPerformanceReportScorePlanIdChange?.(id);
    }

    public render() {
        if (this.loadAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.PerformanceReportScorePlans} />;
        }
        
        return this.worklistDefinition && (
            <SingleLayout>
                <NDataPanel
                    definition={this.worklistDefinition}
                    defaultPageSize={10}
                    selectedRowId={this.props.selectedRowId}
                    useCaseState={this.props.useCaseState}
                    onChange={this.props.onChange}
                    onBack={this.props.onBack}
                    disableDetailStrictMode
                    hasNewRow={this.hasNewRow}
                    onGetPanelProps={this.getPanelProps}
                    refreshListEvent={this.refreshListEvent}
                    hasPrintButton={false}
                />
            </SingleLayout>
        );
    }
}

export default connect(
    withHisErrorBoundary(PerformanceReportScorePlanWorklistPanel),
    new DependencyAdapter<IPerformanceReportScorePlanWorklistPanelProps, IPerformanceReportScorePlanWorklistPanelDependencies>(c => {
        return {
            applicationContext: c.resolve("ApplicationContext"),
            worklistApiAdapter: c.resolve("HunWorklistApiAdapter"),
            careRegisterReferenceDataStore: c.resolve("CareRegisterReferenceDataStore"),
            performanceReportScorePlanApiAdapter: c.resolve("PerformanceReportScorePlanApiAdapter"),
            reportingApiAdapter: c.resolve("ReportingApiAdapter")
        };
    })
);