import { TreeTheme } from "react-treebeard";

// todo: figure out theming here...


const treeViewStyle: TreeTheme = {
    tree: {
        base: {
            listStyle: "none",
            backgroundColor: "#fff",
            margin: 0,
            padding: 0,
            color: "inherit",
            fontFamily: "PragatiNarrow",
            fontSize: "16px"
        },
        node: {
            base: {
                position: "relative",
            } as any,
            link: {
                cursor: "pointer",
                position: "relative",
                padding: "0px 5px",
                display: "block"
            },
            activeLink: {
                background: "#ddf1f7"
            },
            header: {
                base: {
                    display: "inline-block",
                    verticalAlign: "top",
                    width: "calc(100% - 20px)",
                    marginLeft: 20,
                    "&:hover": {
                        textDecoration: "underline"
                    }
                } as any,
                title: {
                    lineHeight: "24px",
                    verticalAlign: "middle"
                }
            } as any,
            subtree: {
                listStyle: "none",
                paddingLeft: 6,
                borderLeft: "1px dotted #ddd",
                marginLeft: 13
            },
            loading: {
            }
        } as any
    }
};

export default treeViewStyle;