exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MedicationInfoPanel_title-style_1xAaE {\n  display: block;\n  font-family: ArchivoNarrowBold;\n  font-size: 20px;\n  color: #004358;\n}\n.MedicationInfoPanel_title-style_1xAaE:first-of-type {\n  margin-top: 15px;\n}\n.MedicationInfoPanel_child-title-style_1u4Z8 {\n  display: block;\n  font-family: ArchivoNarrowBold;\n  font-size: 16px;\n  color: #4F5B72;\n}\n.MedicationInfoPanel_content-container_3MuAV {\n  font-family: ArchivoNarrow;\n  padding: 0px;\n}\n.MedicationInfoPanel_content-container-fixed_2ukb_ {\n  font-family: ArchivoNarrow;\n  padding: 0px;\n  width: calc(100% - 430px);\n  padding-left: 10px;\n}\n.MedicationInfoPanel_title-container_49CLU {\n  height: 100%;\n  min-width: 300px;\n}\n.MedicationInfoPanel_container_1FlKb {\n  display: flex;\n  flex-direction: row;\n  position: relative;\n  height: 100%;\n}\n.MedicationInfoPanel_tab-container_1gOD1 {\n  height: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"title-style": "MedicationInfoPanel_title-style_1xAaE",
	"titleStyle": "MedicationInfoPanel_title-style_1xAaE",
	"child-title-style": "MedicationInfoPanel_child-title-style_1u4Z8",
	"childTitleStyle": "MedicationInfoPanel_child-title-style_1u4Z8",
	"content-container": "MedicationInfoPanel_content-container_3MuAV",
	"contentContainer": "MedicationInfoPanel_content-container_3MuAV",
	"content-container-fixed": "MedicationInfoPanel_content-container-fixed_2ukb_",
	"contentContainerFixed": "MedicationInfoPanel_content-container-fixed_2ukb_",
	"title-container": "MedicationInfoPanel_title-container_49CLU",
	"titleContainer": "MedicationInfoPanel_title-container_49CLU",
	"container": "MedicationInfoPanel_container_1FlKb",
	"tab-container": "MedicationInfoPanel_tab-container_1gOD1",
	"tabContainer": "MedicationInfoPanel_tab-container_1gOD1"
};