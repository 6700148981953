import _ from "@HisPlatform/Common/Lodash";
import Di from "@Di";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { ITelemedicineScreenProps } from "./TelemedicineScreen";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import IAssistant from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IAssistant";
import IDoctor from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IDoctor";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import ShowEditTelemedicineScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowEditTelemedicineScreenAction.g";
import TelemedicineAppointmentDialogParams, { ITelemedicineAppointmentDialogResult } from "./TelemedicineAppointmentDialog/TelemedicineAppointmentDialogParams";
import ResourceId from "@Primitives/ResourceId.g";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import IStateChangeBlockingRegistry from "@PluginInterface/BoundedContexts/Care/CareRegister/StateChange/IStateChangeBlockingRegistry";
import CantChangeCareActivityStateError from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CantChangeCareActivityStateError";
import CareActivityStatusChangeNotPossibleDialogParams, { ICareActivityStatusChangeNotPossibleDialogResult } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityStatusChangeNotPossiblePanel/CareActivityStatusChangeNotPossibleDialogParams";
import TelemedicineScreenApiAdapter from "./TelemedicineScreenApiAdapter";
import StatusChangeReasonId from "@Primitives/StatusChangeReasonId.g";
import StatusChangeReasonDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/StatusChangeReasonDialog/StatusChangeReasonDialogParams";
import StatusChangeReasonTypeId from "@Primitives/StatusChangeReasonTypeId.g";
import * as Ui from "@CommonControls";
import React from "react";

@Di.injectable()
export default class TelemedicineScreenStore extends EditorScreenPanelStoreBase<ITelemedicineScreenProps> implements ILoadablePanelStore {
    protected contentToDirtyCheck: any[];
    protected saveFunction: () => Promise<boolean>;
    protected get showScreenAction(): ShowScreenFrontendActionBase {
        return this.props.action;
    }

    @State.observable.ref
    public appointment: Appointment = null;

    @State.action.bound
    private setAppointment(appointment: Appointment) {
        this.appointment = appointment;
    }

    @State.computed
    public get schedulingService() {
        return this.appointment?.schedulingServiceId
            ? this.schedulingReferenceDataStore.schedulingServices.get(this.appointment.schedulingServiceId)
            : null;
    }

    @State.computed
    public get pointOfCare() {
        return this.appointment?.pointOfCareId
            ? this.organizationReferenceDataStore.pointOfCareMap.get(this.appointment.pointOfCareId)
            : null;
    }

    @State.computed
    public get practitionerData() {
        if (!!this.practitioners.length) {
            return this.practitioners.map((p: IAssistant | IDoctor) => {
                return { name: this.localizationService2.localizePersonName(p?.name), code: (p as IDoctor)?.code };
            });
        }
        return [];
    }

    @State.computed
    public get practitioners() {
        const practitioners: Array<IAssistant | IDoctor> = [];
        if (this.appointment?.practitionerIds) {
            if (!!this.appointment.practitionerIds.length) {
                this.appointment.practitionerIds.forEach(i => {
                    const doctor = this.organizationReferenceDataStore.doctorMap.get(i);
                    if (!isNullOrUndefined(doctor)) {
                        practitioners.push(doctor);
                    } else {
                        const assistant = this.organizationReferenceDataStore.assistantMap.get(i);
                        if (!isNullOrUndefined(assistant)) {
                            practitioners.push(assistant);
                        }
                    }
                });
            }
        }
        return practitioners;
    }

    @State.computed
    public get localizedPatientName() {
        return this.props._patientName
            ? this.localizationService2.localizePersonName(this.props._patientName)
            : "";
    }

    @State.computed
    public get interval() {
        if (this.appointment?.interval) {
            const duration = this.appointment.interval.to.diff(this.appointment.interval.from, "minute");
            return formatStringWithObjectParams(StaticSchedulingResources.PractitionerAppointments.tooltipDate,
                { Interval: `${this.appointment.interval.from.format("LLLL")} - ${this.appointment.interval.to.format("LT")}`, Duration: duration.toString() });
        }
        return "";
    }

    @State.computed
    public get isShowEditTelemedicineScreenAction() {
        return this.showScreenAction instanceof ShowEditTelemedicineScreenAction;
    }

    public readonly onEditTelemedicineAppointmentAsync: () => Promise<void> = this.async(async () => {
        if (this.showScreenAction instanceof ShowEditTelemedicineScreenAction) {
            await this.schedulingApiAdapter.disconnectAppointmentToCareActivityAsync(this.showScreenAction.careActivityId, this.showScreenAction.appointmentScheduleEntryId, this.showScreenAction.rowVersion);
            const result = await this.modalService.showDialogAsync<ITelemedicineAppointmentDialogResult>(new TelemedicineAppointmentDialogParams(this.props._patientId, null, this.showScreenAction.appointmentScheduleEntryId));
            await this.schedulingApiAdapter.connectAppointmentToCareActivityAsync(this.showScreenAction.careActivityId, this.showScreenAction.appointmentScheduleEntryId, result.rowVersion);
            await this.loadCoreAsync();
        }
        this.props._screenState.savedExisting();
    });

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("IToolkitLocalizationService") toolkitLocalizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("TelemedicineScreenApiAdapter") private telemedicineScreenApiAdapter: TelemedicineScreenApiAdapter,
        @Di.inject("ILocalizationService") private localizationService2: ILocalizationService,
        @Di.inject("SchedulingApiAdapter") private schedulingApiAdapter: SchedulingApiAdapter,
        @Di.inject("SchedulingReferenceDataStore") private schedulingReferenceDataStore: SchedulingReferenceDataStore,
        @Di.inject("OrganizationReferenceDataStore") private organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("IStateChangeBlockingRegistry") private readonly stateChangeBlockingRegistry: IStateChangeBlockingRegistry,
        @Di.inject("GlobalRoutingStore") private readonly globalRoutingStore: GlobalRoutingStore) {
        super(dialogService, notificationService, toolkitLocalizationService, lockingApiAdapter);
    }

    public async loadCoreAsync(): Promise<void | { loadedSignals?: string[]; }> {
        if (this.showScreenAction instanceof ShowEditTelemedicineScreenAction) {
            const appointment = await this.schedulingApiAdapter.getAppointmentByIdAsync(this.showScreenAction.appointmentScheduleEntryId);
            this.setAppointment(appointment);
        }
    }

    @State.bound
    public async onDeleteTelemedicineAppointmentAsync(): Promise<void> {
        if (!(this.showScreenAction instanceof ShowEditTelemedicineScreenAction)) {
            return;
        }

        const dialogResult = await this.modalService.showDialogAsync<{ statusChangeReasonId: StatusChangeReasonId, additionalText: string }>(new StatusChangeReasonDialogParams(
            new StatusChangeReasonTypeId("CareActivityDelete"),
            null,
            <Ui.InfoBox iconName="info_with_circle" iconVisualStyle="primary" />
        ));

        if (dialogResult && dialogResult.statusChangeReasonId) {
            await this.telemedicineScreenApiAdapter.deleteTelemedicineAsync(this.showScreenAction.careActivityId, dialogResult.statusChangeReasonId, dialogResult.additionalText);
        } else {
            return null;
        }

        this.props._screenState.deleted();
    }

    @State.bound
    public onCancelAsync(): Promise<void> {
        this.props._screenState.cancelled();
        return Promise.resolve();
    }

    @State.bound
    public handleCantChangeCareActivityStateError(err: CantChangeCareActivityStateError) {
        this.showCareActivityStatusChangeNotPossibleDialogAsync.fireAndForget(err);
        return true;
    }

    private readonly showCareActivityStatusChangeNotPossibleDialogAsync = this.backgroundAsync(async (err: CantChangeCareActivityStateError) => {
        const result = await this.modalService.showDialogAsync<ICareActivityStatusChangeNotPossibleDialogResult>(
            new CareActivityStatusChangeNotPossibleDialogParams(
                err.careActivityId,
                err.activityReferenceResourceId,
                err.reasons,
                new ResourceId("Worklist.CareActivity.WarningTitle")
            )
        );

        if (!!result?.navigateTo) {
            this.stateChangeBlockingRegistry.navigateTo(this.globalRoutingStore, this.actionDispatcher, result.navigateTo, err.careActivityId);
        }
    });
}