exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SmallValidationBadge_container_1E0RN {\n  padding-top: 9px;\n  padding-left: 10px;\n}\n.SmallValidationBadge_small-container_6dO2s {\n  width: 16px;\n  height: 16px;\n  border-radius: 8px;\n  color: white;\n  background-color: #00b4ec;\n  font-family: 'TitilliumWebBold';\n  font-size: 12px;\n  text-align: center;\n  line-height: 16px;\n}\n.SmallValidationBadge_small-container_6dO2s > svg {\n  display: block;\n  stroke: white;\n  fill: white;\n  margin: 4px 2px;\n  top: 0px;\n}\n.SmallValidationBadge_container-error_SaneE {\n  background-color: #ce5374;\n}\n.SmallValidationBadge_container-warning_EGk3K {\n  background-color: #fe8443;\n}\n", ""]);

// exports
exports.locals = {
	"container": "SmallValidationBadge_container_1E0RN",
	"small-container": "SmallValidationBadge_small-container_6dO2s",
	"smallContainer": "SmallValidationBadge_small-container_6dO2s",
	"container-error": "SmallValidationBadge_container-error_SaneE",
	"containerError": "SmallValidationBadge_container-error_SaneE",
	"container-warning": "SmallValidationBadge_container-warning_EGk3K",
	"containerWarning": "SmallValidationBadge_container-warning_EGk3K"
};