import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import ProcedureStatementId from "@Primitives/ProcedureStatementId.g";
import ProcedureStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ProcedureStatement/ProcedureStatementStore";
import ProcedureStatementStoreMapper from "./ProcedureStatementStoreMapper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import { getAddProcedureStatementControllerDto, getUpdateProcedureStatementControllerDto, getDeleteProcedureStatementControllerDto, getDeleteProcedureStatementHistoryItemControllerDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ProcedureStatement/ProcedureStatementDtoMapper";
import PatientId from "@Primitives/PatientId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";

@Di.injectable()
export default class ProcedureStatementApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IProcedureStatementClient") private readonly apiClient: Proxy.IProcedureStatementClient,
        @Di.inject("ProcedureStatementStoreMapper") private readonly storeMapper: ProcedureStatementStoreMapper
    ) {
        super();
    }

    public getByIdAsync(procedureStatementId: ProcedureStatementId, requestLock: boolean): Promise<ProcedureStatementStore> {
        return this.processOperationAsync(
            new ProcedureStatementStore(false),
            async target => {
                const response = await this.apiClient.getProcedureStatementByIdCommandAsync(
                    CreateRequestId(),
                    new Proxy.GetProcedureStatementByIdControllerDto({ procedureStatementId: procedureStatementId, requestLock: requestLock })
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public addProcedureStatementAsync(store: ProcedureStatementStore, patientId: PatientId, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new ProcedureStatementStore(false),
            async target => {
                const response = await this.apiClient.addProcedureStatementCommandAsync(
                    CreateRequestId(),
                    getAddProcedureStatementControllerDto(store, patientId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public updateProcedureStatementAsync(store: ProcedureStatementStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new ProcedureStatementStore(false),
            async target => {
                const response = await this.apiClient.updateProcedureStatementCommandAsync(
                    CreateRequestId(),
                    getUpdateProcedureStatementControllerDto(store, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deleteProcedureStatementHistoryItemAsync(store: ProcedureStatementStore, versionNumber: number, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new ProcedureStatementStore(false),
            async target => {
                const response = await this.apiClient.deleteProcedureStatementHistoryItemCommandAsync(
                    CreateRequestId(),
                    getDeleteProcedureStatementHistoryItemControllerDto(store, versionNumber, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deleteProcedureStatementAsync(store: ProcedureStatementStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<any>(),
            async target => {
                const response = await this.apiClient.deleteProcedureStatementCommandAsync(
                    CreateRequestId(),
                    getDeleteProcedureStatementControllerDto(store),
                    isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public validateAsync(store: ProcedureStatementStore, patientId: PatientId) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response =
                    store.isNew ?
                        await this.apiClient.addProcedureStatementCommandAsync(CreateRequestId(), getAddProcedureStatementControllerDto(store, patientId, true)) :
                        await this.apiClient.updateProcedureStatementCommandAsync(CreateRequestId(), getUpdateProcedureStatementControllerDto(store, true, store.lockInfo?.lockId, false));

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    public async checkPermissionForAddNewItemAsync(store: ProcedureStatementStore, patientId: PatientId): Promise<ProcedureStatementStore> {
        return await this.addProcedureStatementAsync(store, patientId, true);        
    }

    public async checkPermissionForUpdateItemAsync(store: ProcedureStatementStore): Promise<ProcedureStatementStore> {
        return await this.updateProcedureStatementAsync(store, true);
    }

    public async checkPermissionForDeleteItemAsync(store: ProcedureStatementStore): Promise<SimpleStore> {
        return await this.deleteProcedureStatementAsync(store, true);
    }
}
