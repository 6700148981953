import React from "react";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import { RowId } from "@CommonControls/DataGrid/IDataGridProps";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";

interface IErrorListWorklistPanelView {
    worklistDefinition: IWorklistDefinition;

    selectedRowId: string;
    useCaseState: INDataUseCaseState;
    onChange: (rowId: string, useCaseState: INDataUseCaseState) => void;
    
    onFilterStoreCreatedAsync?: (filterStore: any) => Promise<void>;
    onGetExtendedFilterDescriptors?: () => IDataGridFilterDescriptor[];
    onPerformClientSideActionAsync?: (clientSideAction: ClientSideActionDto, rowId: RowId) => Promise<WorkListArgument | boolean>;
    onBack?: () => void;

    title?: string;
}


const ErrorListWorklistPanelView: React.FC<IErrorListWorklistPanelView> = (props) => {

    return props.worklistDefinition && (
        <NDataPanel
            definition={props.worklistDefinition}
            defaultPageSize={10}
            selectedRowId={props.selectedRowId}
            useCaseState={props.useCaseState}
            onChange={props.onChange}
            onFilterStoreCreatedAsync={props.onFilterStoreCreatedAsync}
            onGetExtendedFilterDescriptors={props.onGetExtendedFilterDescriptors}
            hasBackButton={true}
            onBack={props.onBack}
            onPerformClientSideActionAsync={props.onPerformClientSideActionAsync}
            disableDetailStrictMode
            hasRefreshButton
            masterTitle={props.title}
        />
    );
};

export default State.observer(ErrorListWorklistPanelView);
