import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { provideAdmitPatientAndCareActivityBaseDataScreenStore, useAdmitPatientAndCareActivityBaseDataScreenStore } from "./AdmitPatientAndCareActivityBaseDataScreenStoreProvider";
import * as Ui from "@CommonControls";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import FormPanel from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { ScreenStateContextAdapter, ScreenStateContextStore } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import LockIndicatorComponent from "@HisPlatformControls/LockIndicatorComponent";
import ActionBoundButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundButton";
import ActionBoundSaveButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundSaveButton";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ShowCareActivityBaseDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityBaseDataScreenAction.g";
import ShowEditPatientAdmissionDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEditPatientAdmissionDataScreenAction.g";
import ShowEhrCareActivityBaseDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEhrCareActivityBaseDataScreenAction.g";
import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import ShowReadOnlyCareActivityBaseDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyCareActivityBaseDataScreenAction.g";
import ToolbarButtons from "@Toolkit/ReactClient/Components/ToolbarButtons/ToolbarButtons";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";

export interface IAdmitPatientAndCareActivityBaseDataScreenProps extends IScreenPropsBase<ShowNewAdmitPatientScreenAction | ShowEditPatientAdmissionDataScreenAction | ShowCareActivityBaseDataScreenAction | ShowReadOnlyCareActivityBaseDataScreenAction | ShowEhrCareActivityBaseDataScreenAction> {
    _screenState?: ScreenStateContextStore;
    _pointOfCareId?: PointOfCareId;
}

function AdmitPatientAndCareActivityBaseDataScreen(props: IAdmitPatientAndCareActivityBaseDataScreenProps) {

    const store = useAdmitPatientAndCareActivityBaseDataScreenStore();

    let title;
    if (store.isNewAdmitPatientScreen || store.isEditPatientAdmissionDataScreen) {
        title = StaticCareResources.PatientAdmission.Titles.Admission;
    } else if (store.isCareActivityBaseDataScreen || store.isReadOnlyCareActivityBaseDataScreen || store.isEhrCareActivityBaseDataScreen) {
        title = StaticWebAppResources.OutpatientWorkflow.CareActivityBaseDataStep.Title;
    }

    return (
        <Screen
            title={title}
            iconName="add_healthcare"
            toolbar={
                <>
                    <ToolbarButtons
                        beforeButtons={store.canAcquireLock && !store.isEhrCareActivityBaseDataScreen && !store.isReadOnlyCareActivityBaseDataScreen && (
                            <LockIndicatorComponent locked={true} onLoadClickedAsync={store.loadAsync} lockedBy={store.lockInfo?.preventingLockUserId} preventingLockId={store.lockInfo?.preventingLockId} />
                        )}
                        onCancelAsync={store.onCancelAsync}
                        betweenButtons={<>
                            {store.isEditPatientAdmissionDataScreen && !store.canAcquireLock && (
                                <>
                                    <Ui.Button text={StaticWebAppResources.Common.Button.Delete} visualStyle="negative-standard" onClickAsync={store.onDeleteAsync} automationId="__delete" />
                                    <ActionBoundSaveButton actionDescriptor={store.actionForSaveButton} onExecuteAsync={store.onSaveAsync} automationId="__save" />
                                </>
                            )}
                            {store.isNewAdmitPatientScreen && (
                                <ActionBoundButton visualStyle="primary" text={StaticCareResources.PatientAdmission.Button.Admit} iconName="add_healthcare" actionDescriptor={store.admitPatientAction} onExecuteAsync={store.onSaveAsync} automationId="__admit" />
                            )}
                        </>}
                        saveButtonVisible={store.isCareActivityBaseDataScreen && !store.canAcquireLock}
                        saveActionsDescriptor={store.editCareActivityBaseDataAction}
                        onExecuteSaveAsync={store.onSaveAsync}
                    />
                </>
            }>
            <ValidationBoundary
                validationResults={store.validationResults}
                entityTypeName="CareActivityBaseData"
                validateOnMount
                onValidateAsync={store.onValidateAsync}
            >
                <HisPlatformExtensionPoint
                    type="admitPatientAndCareActivityBaseDataScreenInfoBoxExtension"
                    extensionProps={store.infoBoxExtensionProps}>
                    <></>
                </HisPlatformExtensionPoint>
                <FormPanel form={store.form} definitionId={store.formDefinitionId} onChange={store.onDataChange.fireAndForget} isReadOnly={store.vIsReadOnly} />
            </ValidationBoundary>
            <NavigateAwayHook isEnabled onNavigateAwayAsync={store.navigateAwayAsync} />
        </Screen>
    );
}

export default connect<IAdmitPatientAndCareActivityBaseDataScreenProps>(provideAdmitPatientAndCareActivityBaseDataScreenStore(AdmitPatientAndCareActivityBaseDataScreen),
    new ScreenStateContextAdapter(),
    new CareActivityContextAdapter<IAdmitPatientAndCareActivityBaseDataScreenProps>(c => ({
        _pointOfCareId: c.careActivity?.pointOfCareId
    })));