import FinancedServiceWithQuantityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/FinancedServiceWithQuantityStore";
import PerformedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/PerformedServiceStore";
import _ from "@HisPlatform/Common/Lodash";
import MedicalServiceFinancingId from "@Primitives/MedicalServiceFinancingId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LateralityId from "@Primitives/LateralityId.g";
import moment from "moment";

export default class MedicalServiceFinancingStore {
    public static Create(financedServicesWithQuantities: FinancedServiceWithQuantityStore[]): MedicalServiceFinancingStore {
        const store = new MedicalServiceFinancingStore();
        store.setFinancedServicesWithQuantities(financedServicesWithQuantities);

        return store;
    }

    @State.action.bound
    public setFinancedServicesWithQuantities(financedServicesWithQuantities: FinancedServiceWithQuantityStore[]) {
        this.financedServicesWithQuantities = financedServicesWithQuantities;
    }

    @State.observable.ref public id: MedicalServiceFinancingId = null;
    @State.observable.ref public financedServicesWithQuantities: FinancedServiceWithQuantityStore[] = [];
    @State.observable.ref public performedServices: PerformedServiceStore[] = [];

    @State.action.bound
    public setPerformedServiceLaterality(newValue: LateralityId, index: number) {
        this.performedServices[index].setLaterality(newValue);
    }

    @State.action.bound
    public setPerformedServiceQuantity(newValue: number, index: number) {
        this.performedServices[index].setMedicalServiceQuantity(newValue);
        
        this.financedServicesWithQuantities.forEach(i => i.setQuantity(newValue));
    }

    @State.action.bound
    public setFinancedServiceQuantity(newValue: number, index: number) {
        this.financedServicesWithQuantities[index].setQuantity(newValue);
    }

    @State.action.bound
    public setPerformedServicePerformedAt(newValue: moment.Moment, index: number) {
        this.performedServices[index].setPerformedAt(newValue);
    }
}
