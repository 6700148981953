import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import EhiUserContext from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiUserContext";
import EhiTokenChecker from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/Components/EhiTokenChecker";
import EhiTokenProvider from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Services/EhiTokenProvider/EhiTokenProvider";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PractitionerEhiLoginMenuView from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/Extensions/PractitionerEhiLoginExtension/PractitionerEhiLoginMenuView";

interface IPractitionerEhiLoginMenuDependencies {
    localizationService: ILocalizationService;
    userContext: UserContext;
    ehiUserContext: EhiUserContext;
    ehiTokenProvider: EhiTokenProvider;
}

interface IPractitionerEhiLoginMenuProps {
    _dependencies?: IPractitionerEhiLoginMenuDependencies;
}

/** @screen */
@State.observer
class PractitionerEhiLoginMenu extends React.Component<IPractitionerEhiLoginMenuProps> {
    private get localizationService() { return this.props._dependencies.localizationService; }
    private get userContext() { return this.props._dependencies.userContext; }
    private get ehiUserContext() { return this.props._dependencies.ehiUserContext; }
    private get ehiTokenProvider() { return this.props._dependencies.ehiTokenProvider; }

    @State.computed
    private get isLoggedIn() {
        return this.ehiUserContext.isLoggedIn;
    }

    @State.computed
    private get loggedInUserName(): string {
        return this.ehiUserContext.loggedInUserName;
    }

    @State.bound
    private async openDialogAsync() {
        await this.ehiTokenProvider.loginAsync(this.userContext.practitionerId);
    }

    @State.boundLoadingState("isLoading")
    private async logoutAsync() {
        await this.ehiUserContext.logoutAsync();
    }

    public render() {
        return (
            <>
                {this.isLoggedIn && <EhiTokenChecker loginAsync={this.ehiUserContext.loginRenewAsync} />}
                <PractitionerEhiLoginMenuView
                    ehiIdentifier={this.ehiUserContext.ehiIdentifierValue}
                    logoutAsync={this.logoutAsync}
                    ehiToken={this.ehiUserContext.token}
                    localizationService={this.localizationService}
                    openDialogAsync={this.openDialogAsync}
                    loginName={this.loggedInUserName}
                />
            </>
        );
    }
}

export default connect(
    PractitionerEhiLoginMenu,
    new DependencyAdapter<IPractitionerEhiLoginMenuProps, IPractitionerEhiLoginMenuDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
        userContext: c.resolve("UserContext"),
        ehiUserContext: c.resolve("EhiUserContext"),
        ehiTokenProvider: c.resolve("EhiTokenProvider")
    }))
);