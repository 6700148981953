import DiContainer from "./Container";
import IContainerService, { ServiceIdentifier } from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import Di from "@Di";
import * as Inversify from "inversify";

@Di.injectable()
export default class ContainerService implements IContainerService {

    private readonly container: Inversify.Container;

    constructor(
        container: Inversify.Container = null
    ) {
        this.container = container ?? DiContainer;
    }

    public resolve<T>(storeIdentifier: ServiceIdentifier): T {
        return this.container.get<T>(storeIdentifier);
    }

    public createScope(builderAction: (container: Inversify.Container) => void): IContainerService {

        const subContainer = new Inversify.Container({
            skipBaseClassChecks: true
        });

        subContainer.parent = this.container;
        builderAction(subContainer);
        
        return new ContainerService(subContainer);

    }
}
