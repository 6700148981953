import IFormValidationRule, { IFormValidationRuleParameters } from "@Toolkit/FormEngine/Model/Schema/Validation/IFormValidationRule";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import RuleTypeId from "@Primitives/RuleTypeId.g";

export default class EditableShouldNotBeShorterThanValidationRule implements IFormValidationRule {
    public get ruleType() { return RuleTypeId.ShouldNotBeShorterThan.value; }

    @State.observable.ref public minLength: number;
    
    public get ruleParameters() {
        return {
            minLength: this.minLength
        };
    }

    constructor(
        public entityPath: string,
        ruleParameters: IFormValidationRuleParameters & { minLength: number }
    ) {
        this.minLength = ruleParameters.minLength;
    }

    @State.action.bound
    public setMinLength(value: number) {
        this.minLength = value;
    }
}