import React from "react";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";

interface IEventHandlerProps<TArg> {
    event: TypedEvent<TArg> | TypedAsyncEvent<TArg>;
    onFired?: (event: TArg) => void;
    onFiredAsync?: (event: TArg) => Promise<void>;
}

export default class EventHandler<TArg = any> extends React.PureComponent<IEventHandlerProps<TArg>> {

    private eventHandler: IDisposable = null;

    public componentDidMount() {
        this.registerEventHandler();
    }

    public componentDidUpdate(prevProps: IEventHandlerProps<TArg>) {
        if (prevProps.event !== this.props.event) {
            this.safeDisposeEventHandler();
            this.registerEventHandler();
        }
    }

    public componentWillUnmount() {
        this.safeDisposeEventHandler();
    }

    private registerEventHandler() {

        if (!this.props.event) {
            return;
        }

        if (this.props.event instanceof TypedEvent) {
            this.eventHandler = this.props.event.on(arg => {
                this.props.onFired(arg);
            });
        } else if (this.props.event instanceof TypedAsyncEvent) {
            this.eventHandler = this.props.event.on(async arg => {
                await this.props.onFiredAsync(arg);
            });
        }
    }

    private safeDisposeEventHandler() {
        if (this.eventHandler) {
            this.eventHandler.dispose();
        }
    }

    public render() {
        return null as React.ReactNode;
    }
}