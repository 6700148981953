import DynamicPropertiesApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/DynamicProperties/DynamicPropertiesApiAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import StringProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/StringProperty";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import _ from "@HisPlatform/Common/Lodash";
import Form from "@Toolkit/FormEngine/Model/Form";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import { IFormDataStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormDataStore";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import IFormScopes from "@Toolkit/FormEngine/Panels/FormPanel/IFormScopes";
import IFormEngineApiAdapter from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import ConfigurationDynamicPropertiesApiAdapter from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/ApiAdapter/DynamicProperties/ConfigurationDynamicPropertiesApiAdapter";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

type FormPlaceName = string;
type FormDefinitionRawId = string;

export default class EmbeddedFormStore {

    @State.observable.ref private forms: Map<FormPlaceName, IForm[]> = null;

    private formSettings: { [key: string]: string[] } | null = null;
    private formDefinitionIds: FormDefinitionId[] | null = [];
    private static newFormValidationId = -1;

    constructor(
        private readonly dynamicPropertiesApiAdapter: DynamicPropertiesApiAdapter,
        private readonly configurationDynamicPropertiesApiAdapter: ConfigurationDynamicPropertiesApiAdapter,
        private readonly formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        private readonly formEngineApiAdapter: IFormEngineApiAdapter,
        private readonly baseEntityType: string
    ) { }

    public async initializeOnceAsync(propertyGroupName: string, propertyName: string, organizationUnitId?: OrganizationUnitId) {
        let formSettingsRawValue;

        if (organizationUnitId) {
            const property = await this.dynamicPropertiesApiAdapter.getPropertyValueByOrganizationUnitIdAsync(
                organizationUnitId,
                propertyGroupName,
                propertyName
            ) as StringProperty;
            formSettingsRawValue = property.getEffectiveValue();
        } else {
            const propertyValue = await this.configurationDynamicPropertiesApiAdapter.getPropertyValueQueryAsync(propertyGroupName, propertyName);
            formSettingsRawValue = String(propertyValue);
        }

        const configuredFormSettings: { [key: string]: string[] } | null = isNullOrEmptyString(formSettingsRawValue) ? {} : JSON.parse(formSettingsRawValue);

        this.formSettings = {};

        for (const formPlaceName of Object.keys(configuredFormSettings)) {
            for (const formDefinitionId of configuredFormSettings[formPlaceName]) {
                const id = new FormDefinitionId(formDefinitionId);
                const formDefinition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(id);
                if (formDefinition != null && formDefinition.baseEntityType === this.baseEntityType) {
                    if (this.formSettings[formPlaceName]) {
                        this.formSettings[formPlaceName].push(formDefinitionId);
                    }
                    else {
                        this.formSettings[formPlaceName] = [formDefinitionId];
                    }
                    this.formDefinitionIds.push(id);
                }
            }
        }
    }

    public async loadAsync(baseEntityType: string, baseEntityId: StringEntityId, scopes: IFormScopes, loadedForms: IForm[]) {

        if (!this.formSettings) {
            throw new Error("Cannot load embedded forms because there is no loaded form settings. Please call initializeOnceAsync before loadAsync!");
        }

        const formDefinitionMap = await this.getFormDefinitionsByIdMapAsync();
        const forms = await this.createAllFormsAsync(loadedForms, baseEntityType, baseEntityId, formDefinitionMap, scopes);

        this.setLoadedState(forms);
    }

    @State.action
    public setFormData(forms: IForm[]) {
        this.forms.forEach((values: IForm[]) => {
            for (const formValue of values) {
                const foundForm = forms.find(i => i.definitionId.value === formValue.definitionId.value);
                Object.assign(formValue.data.Content, foundForm.data.Content);
            }
        });
    }

    public getFormDefinitionIds() {
        return this.formDefinitionIds;
    }

    public getFormsForPlace(placeName: string) {
        return this.forms ? this.forms.get(placeName) : null;
    }

    public getAllForms() {
        return this.formSettings && this.forms ? _.flatten(Array.from(this.forms.values())) : null;
    }

    private async getFormDefinitionsByIdMapAsync() {
        const formDefinitions = await this.formEngineReferenceDataStore.getOrLoadDefinitionsByIdsAsync(this.getFormDefinitionIds());
        return new Map(formDefinitions.map(fd => ([fd.id.value, fd])));
    }

    private async createAllFormsAsync(
        loadedForms: IForm[],
        baseEntityType: string,
        baseEntityId: StringEntityId,
        formDefinitionMap: Map<FormDefinitionRawId, IFormDefinition>,
        scopes: IFormScopes) {
        const formsMap = new Map<FormPlaceName, IForm[]>();
        const loadedFormByDefinitionId = new Map(loadedForms.map(f => ([f.definitionId.value, f])));

        for (const formPlaceName of Object.keys(this.formSettings)) {
            const formDefinitionIds = this.formSettings[formPlaceName];

            const forms: IForm[] = [];
            for (const fd of formDefinitionIds) {
                const loadedForm = loadedFormByDefinitionId.get(fd);
                if (loadedForm) {
                    forms.push(loadedForm);
                    continue;
                }

                const definitionForNewForm = formDefinitionMap.get(fd);

                const newFormResponse = await this.formEngineApiAdapter.getNewFormInstanceAsync(
                    baseEntityType,
                    baseEntityId,
                    definitionForNewForm.id,
                    scopes);

                forms.push(EmbeddedFormStore.createNewForm(definitionForNewForm.id, newFormResponse.value.data));
            }

            formsMap.set(formPlaceName, forms);
        }

        return formsMap;
    }

    @State.action
    private setLoadedState(formOrPlaceholdersByPlace: Map<FormPlaceName, IForm[]>) {

        this.forms = new Map(Array.from(formOrPlaceholdersByPlace.keys()).map(formPlaceName => {
            const formsOrPlaceholders = formOrPlaceholdersByPlace.get(formPlaceName);
            return [formPlaceName, formsOrPlaceholders];
        }));
    }

    private static createNewForm(formDefinitionId: FormDefinitionId, formDataStore: IFormDataStore) {
        return new Form(
            new FormInstanceId((EmbeddedFormStore.newFormValidationId--).toString()),
            RowVersion.initial,
            formDefinitionId,
            formDataStore,
            [],
            null,
            null
        );
    }
}