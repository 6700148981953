import { useHomeControllerStore } from "@HisPlatform/Packages/Care/Components/HomeMenu/HomeControllerProvider";
import LoadingContext from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingContext";
import LoadingContextStore from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingContextStore";
import React, { useContext, useEffect } from "react";

const loadingStateKey = "homeController";

export default function HomeControllerLoadingBoundaryAdapter() {
    const loadingContext = useContext<LoadingContextStore>(LoadingContext);
    const homeController = useHomeControllerStore();

    useEffect(() => {
        if (homeController.isLoading) {
            loadingContext.setLoadingState(loadingStateKey);
        } else {
            loadingContext.clearLoadingState(loadingStateKey);
        }
    }, [homeController.isLoading]);

    return (
        <></>
    );
}