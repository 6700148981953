import React from "react";
import * as Ui from "@CommonControls";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import OrganizationSelector from "@HisPlatform/BoundedContexts/Organization/Components/OrganizationSelector";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import { IReportDefinition } from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/Model/IReportDefinition";

interface IOutpatientActivityLogPanelViewProps {
    definition: IReportDefinition;
    dateRange: LocalDateRange;
    onDateRangeChange: (newValue: LocalDateRange) => void;
    pointOfCares: PointOfCareId[];
    onPointOfCareChange: (newValue: PointOfCareId, checkedValue: boolean) => void;
    onCreateAsync: () => Promise<void>;
}


const OutpatientActivityLogPanelView: React.FC<IOutpatientActivityLogPanelViewProps> = (props) => {
    return (
        <SingleLayout>
            <Ui.PageBox2>
                <Ui.PageBox2.Header title={props.definition.name} toolbar={(
                    <div style={{ textAlign: "right" }}>
                        <Ui.Button
                            visualStyle="primary"
                            text={StaticWebAppResources.Reporting.OutpatientActivityLogPanel.Create}
                            onClickAsync={props.onCreateAsync}
                            automationId="createButton" />
                    </div>
                )} />
                <Ui.PageBox2.Body>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <Ui.DateRangePicker
                                value={props.dateRange}
                                onChange={props.onDateRangeChange}
                                label={StaticWebAppResources.Reporting.OutpatientActivityLogPanel.Period}
                                automationId="periodPicker"
                                required />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <OrganizationSelector
                                value={props.pointOfCares}
                                onChange={props.onPointOfCareChange}
                                label={StaticWebAppResources.Reporting.OutpatientActivityLogPanel.PointOfCare}
                                isRequired
                                pointOfCareMode={true} 
                                automationId="organization" />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.PageBox2.Body>
            </Ui.PageBox2>
        </SingleLayout>
    );
};

export default OutpatientActivityLogPanelView;