enum MedicalConditionType {
    Immunization,
    AutonomyDisabilityStatement,
    PregnancyStatement,
    MedicationStatement,
    CurrentConditionStatement,
    FormerConditionStatement,
    DeviceUseStatement,
    ProcedureStatement,
    RiskAssessment,
    PatientAllergyIntolerance,
    MedicalAlertStatement,
    TherapyRecommendation,
    PatientBloodType
}

export default MedicalConditionType;
