import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import SurgeryTypeDefinitionId from "@Primitives/SurgeryTypeDefinitionId.g";

export interface INewSurgerytDialogParams extends IModalParams {
    title: string;
}

export interface INewSurgerytDialogResult {
    selectedId: SurgeryTypeDefinitionId;
    isUrgent: boolean;
}

export default class NewSurgerytDialogParams implements INewSurgerytDialogParams {

    public static type = "NewSurgerytDialog";
    public get type() { return NewSurgerytDialogParams.type; }

    constructor(
        public title: string = ""
    ) {}
}