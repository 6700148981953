
import DependencyContainer from "@DiContainer";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";

import "./ApiClientConfiguration";
import "./StoreConfiguration";
import EhiEventStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiEventStoreMapper";
import OncologyApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/OncologyApiAdapter";
import EhiCovidStatusReportApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCovidStatusReportApiAdapter";
import EhiCovidStatusReportStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCovidStatusReportStoreMapper";
import EhiImmunizationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiImmunizationApiAdapter";
import EhiMedicalConditionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiMedicalConditionApiAdapter";
import EhiOrganizationUnitProviderService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Organization/Services/Implementation/EhiOrganizationUnitProviderService";
import TherapiesApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/TherapiesApiAdapter";
import TherapyRecommendationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/TherapyRecommendationApiAdapter";
import TherapyRecommendationStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/TherapyRecommendationStoreMapper";
import EhiMedicalConditionReferenceDataResolver from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Services/Implementation/EhiMedicalConditionDataResolver/EhiMedicalConditionReferenceDataResolver";
import EhiCovidSelfExaminationStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidSelfExaminationResultPanel/EhiCovidSelfExaminationStoreMapper";
import PatientBloodTypeApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/PatientBloodTypeApiAdapter";
import PatientBloodTypeStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/PatientBloodTypeStoreMapper";
import EhiCovidTestResultsApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/PatientRegister/ApiAdapter/EhiCovidTestResultsApiAdapter";
import EhiAutonomyDisabilityStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiAutonomyDisabilityStatementApiAdapter";
import EhiCareServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareServiceFactory";
import EhiDeviceUseStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiDeviceUseStatementApiAdapter";
import EhiMedicationStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiMedicationStatementApiAdapter";
import EhiPregnancyStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiPregnancyStatementApiAdapter";
import EhiCurrentConditionStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiCurrentConditionStatementApiAdapter";
import EhiFormerConditionStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiFormerConditionStatementApiAdapter";
import EhiProcedureStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiProcedureStatementApiAdapter";
import EhiMedicalConditionServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Services/EhiMedicalConditionServiceFactory";
import EhiRiskAssessmentApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiRiskAssessmentApiAdapter";
import IEhiMedicalConditionReferenceDataResolver from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Services/Definition/EhiMedicalConditionDataResolver/IEhiMedicalConditionReferenceDataResolver";
import EhiTherapyRecommendationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiTherapyRecommendationApiAdapter";
import EhiPatientAllergyIntoleranceApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiPatientAllergyIntoleranceAdapter";
import EhiPatientBloodTypeApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiPatientBloodTypeApiAdapter";
import EhiMedicalAlertStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiMedicalAlertStatementApiAdapter";
import RatDeviceApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/RatDeviceApiAdapter";
import IRatDeviceReferenceDataResolver from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Services/Definition/EhiMedicalConditionDataResolver/IRatDeviceReferenceDataResolver";
import RatDeviceReferenceDataResolver from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Services/Implementation/EhiMedicalConditionDataResolver/RatDeviceReferenceDataResolver";

DependencyContainer
    .bind("HunEhiCareReferenceDataStore")
    .to(HunEhiCareReferenceDataStore)
    .inSingletonScope();

DependencyContainer
    .bind("EhiCareApiAdapter")
    .to(EhiCareApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiCovidTestResultsApiAdapter")
    .to(EhiCovidTestResultsApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiEventStoreMapper")
    .to(EhiEventStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("OncologyApiAdapter")
    .to(OncologyApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiCovidStatusReportApiAdapter")
    .to(EhiCovidStatusReportApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiCovidStatusReportStoreMapper")
    .to(EhiCovidStatusReportStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("EhiMedicalConditionApiAdapter")
    .to(EhiMedicalConditionApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiImmunizationApiAdapter")
    .to(EhiImmunizationApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiAutonomyDisabilityStatementApiAdapter")
    .to(EhiAutonomyDisabilityStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiPregnancyStatementApiAdapter")
    .to(EhiPregnancyStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiMedicationStatementApiAdapter")
    .to(EhiMedicationStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiCurrentConditionStatementApiAdapter")
    .to(EhiCurrentConditionStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiDeviceUseStatementApiAdapter")
    .to(EhiDeviceUseStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiProcedureStatementApiAdapter")
    .to(EhiProcedureStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiFormerConditionStatementApiAdapter")
    .to(EhiFormerConditionStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiOrganizationUnitProviderService")
    .to(EhiOrganizationUnitProviderService)
    .inSingletonScope();

DependencyContainer
    .bind("TherapiesApiAdapter")
    .to(TherapiesApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("RatDeviceApiAdapter")
    .to(RatDeviceApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("TherapyRecommendationApiAdapter")
    .to(TherapyRecommendationApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("TherapyRecommendationStoreMapper")
    .to(TherapyRecommendationStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("IEhiMedicalConditionReferenceDataResolver")
    .to(EhiMedicalConditionReferenceDataResolver)
    .inSingletonScope();

DependencyContainer
    .bind("IRatDeviceReferenceDataResolver")
    .to(RatDeviceReferenceDataResolver)
    .inSingletonScope();

DependencyContainer
    .bind("EhiCovidSelfExaminationStoreMapper")
    .to(EhiCovidSelfExaminationStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("PatientBloodTypeApiAdapter")
    .to(PatientBloodTypeApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PatientBloodTypeStoreMapper")
    .to(PatientBloodTypeStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("EhiCareServiceFactory")
    .to(EhiCareServiceFactory)
    .inSingletonScope();

DependencyContainer
    .bind("EhiMedicalConditionServiceFactory")
    .to(EhiMedicalConditionServiceFactory)
    .inSingletonScope();

DependencyContainer
    .bind("EhiRiskAssessmentApiAdapter")
    .to(EhiRiskAssessmentApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiPatientAllergyIntoleranceApiAdapter")
    .to(EhiPatientAllergyIntoleranceApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiTherapyRecommendationApiAdapter")
    .to(EhiTherapyRecommendationApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiPatientBloodTypeApiAdapter")
    .to(EhiPatientBloodTypeApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EhiMedicalAlertStatementApiAdapter")
    .to(EhiMedicalAlertStatementApiAdapter)
    .inSingletonScope();
