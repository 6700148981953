import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import IDocumentNameExtensionPointProps from "@PluginInterface/BoundedContexts/DocumentManagement/ExtensionPoints/IDocumentNameExtensionPointProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiSyncStatusLabel from "@HunEHealthInfrastructurePlugin/Common/Components/EhiSyncStatusLabel";

@State.observer
export default class EhiDocumentNameExtension extends React.Component<IDocumentNameExtensionPointProps> {

    public render() {
        const extensionObject = JSON.parse(this.props.extensionData.HunEHealthInfrastructure_EhiDocumentInfo.Value);
        if (!isNullOrUndefined(extensionObject.EHI_SYNC_STATUS)) {
            return <EhiSyncStatusLabel value={extensionObject.EHI_SYNC_STATUS} upperIndex />;
        } else {
            return null;
        }
    }
}