import React from "react";
import { TextBox } from "@CommonControls";
import { iconNameType } from "@CommonControls/Icon";
import { Tooltip } from "@Toolkit/ReactClient/Components/Tooltip";
import { ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ICommonEditorProps from "@CommonControls/ICommonEditorProps";

export interface INumberBoxProps extends ICommonControlProps, ICommonEditorProps<number> {
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    id?: string;
    name?: string;
    className?: string;
    style?: React.CSSProperties;
    placeholder?: string;
    nullValue?: number;
    label?: string;
    iconName?: iconNameType;
    required?: boolean;
    innerLabel?: { upperText: string, lowerText: string };
    size?: "compact" | "standard";
    embedded?: boolean;
    propertyIdentifier?: string;
    automationId: string;
    maxLength?: number;
    onlyNumbersAllowedMessage?: string;
    textAlign?: "left" | "center";
    star?: string;
    integerOnly?: boolean;
    stopClickPropagation?: boolean;
    visualMode?: "normal" | "dark";
    clearable?: boolean;
    forceShowValidationResults?: boolean;
    leadingZerosToLength?: number;
}

@State.observer
export default class NumberBox extends React.Component<INumberBoxProps, undefined> {
    @State.observable.ref private internalText: string = null;
    @State.observable private isTooltipSticky: boolean = false;

    @State.computed private get displayValue() {
        return isNullOrUndefined(this.props.value) ? null : this.props.value.toString();
    }

    public static defaultProps: Partial<INumberBoxProps> = {
        nullValue: null,
        size: "standard",
        disabled: false,
        required: false,
        textAlign: "left",
        clearable: true
    };

    constructor(props: INumberBoxProps) {
        super(props);
        this.updateInternalState();
    }

    public componentDidUpdate(prevProps: INumberBoxProps) {
        if (prevProps.value !== this.props.value) {
            this.updateInternalState();
        }
    }

    @State.action
    private updateInternalState() {
        this.internalText = this.displayValue;
    }

    @State.action.bound
    private changeHandler(newValue: string) {
        // eslint-disable-next-line no-useless-escape
        this.internalText = this.props.integerOnly ? newValue.replace(/([^\d]+)/g, "") : newValue.replace(/([^\d,\.]+)/g, "");

        if (this.props.onChange) {
            if (!isNullOrUndefined(this.internalText)) {
                const numValue = this.props.integerOnly ? parseInt(this.internalText, 10) : parseFloat(this.internalText.replace(",", "."));
                this.props.onChange(isNaN(numValue) ? this.props.nullValue : numValue);
            } else if (this.internalText === "") {
                this.props.onChange(this.props.nullValue);
            }
        }
    }

    @State.action.bound
    private blur(event: React.FocusEvent<HTMLInputElement>) {
        if (this.displayValue !== this.internalText) {
            if (this.props.onlyNumbersAllowedMessage) {
                this.isTooltipSticky = true;
                window.setTimeout(this.hideTooltip, 3000);
            }

            this.updateInternalState();
        }
        
        this.props.onBlur?.(event);
    }

    @State.bound
    private focusHandler(event: React.FocusEvent<HTMLInputElement>) {
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }

        event.target.select();
    }

    @State.action.bound
    private hideTooltip() {
        this.isTooltipSticky = false;
    }

    public render() {
        const htmlProps = {
            name: this.props.name,
            id: this.props.id,
            style: this.props.style,
            className: this.props.className,
            placeholder: this.props.placeholder,
            onClick: this.props.onClick,
            onBlur: this.props.onBlur,
            onFocus: this.focusHandler,
            onKeyDown: this.props.onKeyDown,
            disabled: this.props.disabled,
            isReadOnly: this.props.isReadOnly,
            maxLength: this.props.maxLength,
            textAlign: this.props.textAlign
        };

        return (
            <TextBox
                {...htmlProps}
                label={this.props.label}
                value={this.props.leadingZerosToLength ? this.internalText?.padStart(this.props.leadingZerosToLength, "0") : this.internalText}
                onChange={this.changeHandler}
                iconName={this.props.iconName}
                innerLabel={this.props.innerLabel}
                size={this.props.size}
                embedded={this.props.embedded}
                propertyIdentifier={this.props.propertyIdentifier}
                automationId={this.props.automationId}
                textAlign={this.props.textAlign}
                required={this.props.required}
                onBlur={this.blur}
                stopClickPropagation={this.props.stopClickPropagation}
                visualMode={this.props.visualMode}
                valueOnClear=""
                clearable={this.props.clearable}
                forceShowValidationResults={this.props.forceShowValidationResults}
                tooltipContent={this.props.onlyNumbersAllowedMessage}
                tooltipPosition={this.props.tooltipPosition}
                tooltipTextAlign={this.props.tooltipTextAlign}
            />
        );

    }
}
