import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { GetTypeNameWithPrefix } from "@HunEHealthInfrastructurePlugin/PluginHelper";
import EhiEvent from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEvent";
import EhiEventStoreMapper from "./EhiEventStoreMapper";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import EhiEventTypeId from "@Primitives/EhiEventTypeId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiCareTypeId from "@Primitives/EhiCareTypeId.g";
import {
    createOperationInfo,
    createOperationInfoForFileResponse
} from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import EhiCareType from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiCareType";
import EhiDischargeReasonId from "@Primitives/EhiDischargeReasonId.g";
import EhiPatientIdentifierTypeId from "@Primitives/EhiPatientIdentifierTypeId.g";
import EhiToken from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiToken";
import PatientId from "@Primitives/PatientId.g";
import EhiDocumentListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiDocumentListItem";
import IEhiResult from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiResult";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import EhiErrorSeverity from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiErrorSeverity";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import CareActivityEReferralStore from "@HunEHealthInfrastructurePlugin/Model/CareActivityEReferralStore";
import EhiEReferralId from "@Primitives/EhiEReferralId.g";
import { mapToNameStore } from "@HisPlatform/Common/PersonNameMapper";
import EhiEReferralStatusId from "@Primitives/EhiEReferralStatusId.g";
import EhiEReferralTypeId from "@Primitives/EhiEReferralTypeId.g";
import EhiEReferralReasonId from "@Primitives/EhiEReferralReasonId.g";
import EhiEReferralOrganizationTypeId from "@Primitives/EhiEReferralOrganizationTypeId.g";
import EhiEReferralTravelExpenseTypeId from "@Primitives/EhiEReferralTravelExpenseTypeId.g";
import EhiEReferralScheduleStatusId from "@Primitives/EhiEReferralScheduleStatusId.g";
import EhiEReferralPatientIdentifierTypeId from "@Primitives/EhiEReferralPatientIdentifierTypeId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import IEhiEReferralPatientIdentifierTypeVersion from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IEhiEReferralPatientIdentifierTypeVersion";
import IEhiEReferralServiceTypeVersion from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IEhiEReferralServiceTypeVersion";
import EhiEReferralServiceTypeId from "@Primitives/EhiEReferralServiceTypeId.g";
import IEhiEReferralProfessionCodeVersion from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IEhiEReferralProfessionCodeVersion";
import EhiEReferralProfessionCodeId from "@Primitives/EhiEReferralProfessionCodeId.g";
import IEhiHealthcareProviderVersion from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IEhiHealthcareProviderVersion";
import EhiHealthcareProviderId from "@Primitives/EhiHealthcareProviderId.g";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import _ from "@HisPlatform/Common/Lodash";
import { buildQueryStringArray } from "@Toolkit/CommonWeb/QueryStringBuilder";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import EhiHealthcareProviderOrganizationUnitId from "@Primitives/EhiHealthcareProviderOrganizationUnitId.g";
import EhiHealthcareProviderOrganizationUnit from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiHealthcareProviderOrganizationUnit";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import EhiEReferralCovidExaminationSampleTypeId from "@Primitives/EhiEReferralCovidExaminationSampleTypeId.g";
import EhiEReferralCovidExaminationTypeId from "@Primitives/EhiEReferralCovidExaminationTypeId.g";
import EhiEReferralCovidContactExaminationReasonId from "@Primitives/EhiEReferralCovidContactExaminationReasonId.g";
import EhiEReferralCovidScreeningExaminationReasonId from "@Primitives/EhiEReferralCovidScreeningExaminationReasonId.g";
import EhiEReferralCovidSymptomsSeverityId from "@Primitives/EhiEReferralCovidSymptomsSeverityId.g";
import EhiEReferralCovidPreviousResultId from "@Primitives/EhiEReferralCovidPreviousResultId.g";
import EhiEReferralCovidQuickTestResultId from "@Primitives/EhiEReferralCovidQuickTestResultId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EhiEReferralQueryScenarioCodeId from "@Primitives/EhiEReferralQueryScenarioCodeId.g";
import EhiEReferralListQuerySettingsStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralListQuerySettingsStore";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import EhiCovidBreathingStatusId from "@Primitives/EhiCovidBreathingStatusId.g";
import EhiCovidSymptomsSeverityId from "@Primitives/EhiCovidSymptomsSeverityId.g";
import EhiCovidSymptomStatusId from "@Primitives/EhiCovidSymptomStatusId.g";
import EhiCovidUrineAmountId from "@Primitives/EhiCovidUrineAmountId.g";
import EhiCovidVentilationStatusId from "@Primitives/EhiCovidVentilationStatusId.g";
import HealthcareProfessionId from "@Primitives/HealthcareProfessionId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import EhiDocumentTypeId from "@Primitives/EhiDocumentTypeId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import EhiDoctor from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiDoctor";
import EhiOrganizationUnit from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiOrganizationUnit";
import IMapperService from "@HisPlatform/Services/Definition/MapperService/IMapperService";
import EhiInpatientAdmissionTypeId from "@Primitives/EhiInpatientAdmissionTypeId.g";
import EhiInpatientAdmissionClassificationId from "@Primitives/EhiInpatientAdmissionClassificationId.g";
import EhiPatientFurtherTreatmentId from "@Primitives/EhiPatientFurtherTreatmentId.g";
import EhiEmergencyLevelClaimTypeId from "@Primitives/EhiEmergencyLevelClaimTypeId.g";
import EhiEmergencyExaminationClaimTypeId from "@Primitives/EhiEmergencyExaminationClaimTypeId.g";
import EhiCovidSelfExaminationTimeCodeId from "@Primitives/EhiCovidSelfExaminationTimeCodeId.g";
import EhiCovidSelfExaminationGeneralStatusId from "@Primitives/EhiCovidSelfExaminationGeneralStatusId.g";
import EhiStructuredDocumentContactTypeId from "@Primitives/EhiStructuredDocumentContactTypeId.g";
import EhiStructuredDocumentContactStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiStructuredDocumentContactStore";
import IEhiCovidVaccinationListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IEhiCovidVaccinationListItem";
import NameStore from "@Primitives/NameStore";
import { QueryPaging } from "@HisPlatform/BoundedContexts/CommonReferenceData/Api/Proxy.g";
import { PatientCareActivityDataDto } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import EhiHealthcareProviderOrganizationUnitSelectorQueryOrderingFields from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Care/Enum/EhiHealthcareProviderOrganizationUnitSelectorQueryOrderingFields.g";
import EhiEReferralServiceTypeSelectorQueryOrderingFields from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Care/Enum/EhiEReferralServiceTypeSelectorQueryOrderingFields.g";
import EhiHealthcareProviderSelectorQueryOrderingFields from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Care/Enum/EhiHealthcareProviderSelectorQueryOrderingFields.g";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";
import IHungarianPatientDefaultData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/PatientRegister/Model/IHungarianPatientDefaultData";
import EuCardReplacementTypeId from "@Primitives/EuCardReplacementTypeId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import CoverageEligibilityCheckResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/CareRegister/Enum/CoverageEligibilityCheckResult.g";
import ICoverageEligibilityCheck from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CheckCoverageEligibility/CheckCoverageEligibility";
import ICoverageCheckListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CheckCoverageEligibility/ICoverageCheckListItem";
import GetCoverageEligibilityCheckResultsQueryOrderingFields from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/CareRegister/Enum/GetCoverageEligibilityCheckResultsQueryOrderingFields.g";



/* auto-inject-disable */
@Di.injectable()
export default class EhiCareApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("ICareClient")) private apiClient: Proxy.ICareClient,
        @Di.inject(GetTypeNameWithPrefix("IPatientRegisterClient")) private patientRegisterClient: Proxy.IPatientRegisterClient,
        @Di.inject(GetTypeNameWithPrefix("IHunReferenceDataClient")) private readonly referenceDataClient: Proxy.IHunReferenceDataClient,
        @Di.inject("EhiEventStoreMapper") private ehiEventStoreMapper: EhiEventStoreMapper,
        @Di.inject("IMapperService") private readonly mapperService: IMapperService,
        @Di.inject(GetTypeNameWithPrefix("ICareRegisterClient")) private readonly careRegisterClient: Proxy.ICareRegisterClient
    ) {
        super();
    }

    @State.bound
    public getEventDetailsAsync(
        ehiToken: EhiToken,
        careIdentifier: string,
        organizationUnitId: OrganizationUnitId,
        isEmergency: boolean,
        emergencyReason: string) {

        return this.processOperationAsync(
            new SimpleStore<IEhiResult<EhiEvent>>(),
            async target => {
                const response = await this.apiClient.getEhiCareActivityDetailsQueryAsync(CreateRequestId(), new Proxy.GetEhiCareActivityDetailsControllerDto({
                    careActivityIdentifier: careIdentifier,
                    ehiTokenDto: this.mapToken(ehiToken),
                    organizationUnitId: organizationUnitId,
                    isEmergency,
                    emergencyReason
                }));

                this.ehiEventStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public getEventEmergencyEventsAsync(
        ehiToken: EhiToken,
        patientId: PatientId,
        ehiId: string,
        organizationUnitId: OrganizationUnitId,
        isEmergency: boolean,
        emergencyReason: string) {

        return this.processOperationAsync(
            new SimpleStore<IEhiResult<any>>(),
            async target => {
                const response = await this.apiClient.getEhiEmergencyEntriesOfCareActivityQueryAsync(CreateRequestId(), new Proxy.GetEhiEmergencyEntriesOfCareActivityControllerDto({
                    patientId,
                    ehiId,
                    ehiTokenDto: this.mapToken(ehiToken),
                    organizationUnitId: organizationUnitId,
                    isEmergency,
                    emergencyReason
                }));

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    value: response.results.map(x => this.mapperService.mapByName("PatientCareActivityDataDto", x)),
                    errors: response.errors?.map(e => {
                        return new EhiError(e.code, e.moduleName, e.message, e.ehiErrorSeverity as any as EhiErrorSeverity);
                    }) || []
                };
            }
        );
    }


    public getCoverageEligibilityCheckAsync(id: CareActivityId, patientId: PatientId, identifierValue: string) {
        return this.processOperationAsync(
            new SimpleStore<ICoverageEligibilityCheck>(),
            async target => {
                const request = new Proxy.CheckCoverageEligibilityControllerDto();
                request.careActivityId = id;
                request.identifierValue = identifierValue;
                request.patientId = patientId;

                const response = await this.careRegisterClient.checkCoverageEligibilityCommandAsync(
                    CreateRequestId(),
                    request
                );

                target.operationInfo = createOperationInfo(response);

                const result = {
                    coverageEligibilityCheckAvailability: response.coverageEligibilityCheckAvailability,
                    coverageEligibilityCheckResult: response.coverageEligibilityCheckResult,
                    checkedAt: response.checkedAt
                } as ICoverageEligibilityCheck;

                target.value = result;
            }
        );
    }

    public getCoverageEligibilityCheckResultsAsync(
        checkedAtRange: LocalDateRange,
        patientName: string,
        patientBirthDate: LocalDateRange,
        patientIdentifierValue: string,
        transactionCode: string,
        errorCode: string,
        coverageEligibilityCheckResult: CoverageEligibilityCheckResult,
        pointOfCareIds: PointOfCareId[],
        paging: IPagingState,
        ordering: IOrderingState
    ) {
        return this.processOperationAsync(

            new PagedItemStore<ICoverageCheckListItem>([], 0),
            async target => {

                const queryOrdering = ordering && new Proxy.QueryOrderingOfGetCoverageEligibilityCheckResultsQueryOrderingFields({
                    fieldName: GetCoverageEligibilityCheckResultsQueryOrderingFields[ordering.columnId],
                    direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending
                });

                const requestDto = new Proxy.GetCoverageEligibilityCheckResultsControllerDto({
                    coverageEligibilityCheckResult: coverageEligibilityCheckResult,
                    errorCode: errorCode,
                    checkedAtRange: checkedAtRange,
                    patientBirthDate: patientBirthDate,
                    patientIdentifierValue: patientIdentifierValue,
                    patientName: patientName,
                    transactionCode: transactionCode,
                    pointOfCareIds: pointOfCareIds,
                    pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfGetCoverageEligibilityCheckResultsQueryOrderingFields({
                        orderings: queryOrdering ? [queryOrdering] : [],
                        paging: new QueryPaging({ pageIndex: paging.currentPage, pageSize: paging.pageSize })
                    })
                });
                const response = await this.careRegisterClient.getCoverageEligibilityCheckResultsQueryAsync(CreateRequestId(), requestDto);

                target.items = response.results.values.map(i => ({
                    id: i.id,
                    checkedAt: i.checkedAt,
                    coverageEligibilityCheckResult: i.coverageEligibilityCheckResult,
                    errorCode: i.errorCode,
                    identiferValue: i.identifierValue,
                    patientBirthDate: i.patientBirthDate,
                    patientId: i.patientId,
                    patientName: NameStore.create(i.patientName),
                    transactionCode: i.transactionCode
                } as ICoverageCheckListItem));

                target.totalCount = response.results.totalCount;
            }
        );
    }

    @State.bound
    public getCoverageEligibilityCheckResultsPermissionCheckAsync() {
        return this.processOperationAsync(

            new PagedItemStore<ICoverageCheckListItem>([], 0),
            async target => {
                const requestDto = new Proxy.GetCoverageEligibilityCheckResultsControllerDto();
                const response = await this.careRegisterClient.getCoverageEligibilityCheckResultsQueryAsync(CreateRequestId(), requestDto, true);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getEhiCovidVaccinationEventEntriesAsync(ehiToken: EhiToken, date: LocalDate, pointOfCareId: PointOfCareId) {
        return this.processOperationAsync(
            new SimpleStore<IEhiResult<IEhiCovidVaccinationListItem[]>>(),
            async target => {
                const response = await this.apiClient.getCovidVaccinationEventEntriesQueryAsync(CreateRequestId(),
                    new Proxy.GetCovidVaccinationEventEntriesControllerDto({
                        ehiTokenDto: this.mapToken(ehiToken),
                        organizationUnitId: new OrganizationUnitId(pointOfCareId.value),
                        date: date
                    }));

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    value: response.covidVaccinationEntries?.map(x => {
                        return {
                            patientName: NameStore.create(x.patientName),
                            careOrganizationUnitId: x.careOrganizationUnitId,
                            beginningOfCare: x.beginningOfCare,
                            careDoctorId: x.careDoctorId,
                            ehiCareIdentifier: x.ehiCareIdentifier,
                            careOrganizationId: x.careOrganizationId,
                            ehiDocumentIdentifier: x.ehiDocumentIdentifier,
                            endOfCare: x.endOfCare,
                            patientIdentifier: x.patientIdentifier
                        } as IEhiCovidVaccinationListItem;
                    }),
                    errors: response.errors?.map(e => {
                        return new EhiError(e.code, e.moduleName, e.message, e.ehiErrorSeverity as any as EhiErrorSeverity);
                    }) || []
                };
            }
        );
    }

    @State.bound
    public getEhiDocumentsForPatientAsync(
        ehiToken: EhiToken,
        organizationUnitId: OrganizationUnitId,
        patientId: PatientId,
        isEmergency: boolean,
        dateRange?: LocalDateRange,
        documentType?: EhiDocumentTypeId,
        emergencyReason?: string) {

        return this.processOperationAsync(
            new SimpleStore<IEhiResult<EhiDocumentListItem[]>>(),
            async target => {
                const response = await this.apiClient.getEhiDocumentListByPatientIdQueryAsync(CreateRequestId(), new Proxy.GetEhiDocumentListByPatientIdControllerDto({
                    ehiTokenDto: this.mapToken(ehiToken),
                    organizationUnitId: organizationUnitId,
                    patientId: patientId,
                    dateRange: dateRange,
                    isEmergency: isEmergency,
                    emergencyReason: emergencyReason,
                    documentType: documentType
                }));

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    value: response.documents?.map(d => {
                        const newStore = new EhiDocumentListItem();
                        newStore.ehiDoctor = this.mapEhiDoctor(d.ehiDoctor);
                        newStore.doctorId = d.doctorId;
                        newStore.documentIdentifier = d.documentIdentifier;
                        newStore.ehiDocumentTypeId = d.ehiDocumentTypeId;
                        newStore.timeStamp = d.timeStamp;
                        newStore.version = d.version;
                        newStore.ehiId = d.ehiId;
                        newStore.ehiOrganizationUnit = this.mapEhiOrganizationUnit(d.ehiOrganizationUnit);
                        newStore.organizationUnitId = d.healthcareProviderOrganizationUnitId;
                        return newStore;
                    }) || [],
                    errors: response.errors?.map(e => {
                        return new EhiError(e.code, e.moduleName, e.message, e.ehiErrorSeverity as any as EhiErrorSeverity);
                    }) || []
                };
            }
        );
    }

    @State.bound
    public getEhiDocumentsAsync(
        ehiEventCatalogIdentifier: string,
        ehiToken: EhiToken,
        organizationUnitId: OrganizationUnitId,
        patientId: PatientId,
        isEmergency: boolean,
        emergencyReason: string) {

        return this.processOperationAsync(
            new SimpleStore<IEhiResult<EhiDocumentListItem[]>>(),
            async target => {
                const response = await this.apiClient.getEhiDocumentListByCareIdentifierQueryAsync(CreateRequestId(), new Proxy.GetEhiDocumentListByCareIdentifierControllerDto({
                    ehiCareIdentifier: ehiEventCatalogIdentifier,
                    ehiTokenDto: this.mapToken(ehiToken),
                    organizationUnitId: organizationUnitId,
                    patientId: patientId,
                    isEmergency,
                    emergencyReason
                }));

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    value: response.documents?.map(d => {
                        const newStore = new EhiDocumentListItem();
                        newStore.ehiDoctor = this.mapEhiDoctor(d.ehiDoctor);
                        newStore.doctorId = d.doctorId;
                        newStore.documentIdentifier = d.documentIdentifier;
                        newStore.ehiDocumentTypeId = d.ehiDocumentTypeId;
                        newStore.timeStamp = d.timeStamp;
                        newStore.version = d.version;
                        newStore.ehiId = d.ehiId;
                        newStore.ehiOrganizationUnit = this.mapEhiOrganizationUnit(d.ehiOrganizationUnit);
                        newStore.organizationUnitId = d.healthcareProviderOrganizationUnitId;
                        return newStore;
                    }) || [],
                    errors: response.errors?.map(e => {
                        return new EhiError(e.code, e.moduleName, e.message, e.ehiErrorSeverity as any as EhiErrorSeverity);
                    }) || []
                };
            }
        );
    }

    private mapToken(ehiToken: EhiToken): Proxy.EhiTokenDto {
        return new Proxy.EhiTokenDto({
            tokenValue: ehiToken.tokenValue,
            validFrom: ehiToken.validFrom,
            validTo: ehiToken.validTo
        });
    }

    @State.bound
    public loadEhiEventTypesAsync(): Promise<SimpleStore<EhiEventTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEventTypeIdsQueryAsync(...args),
            response => response.ehiEventTypeIds
        );
    }

    @State.bound
    public loadEhiPatientIdentifierTypesAsync(): Promise<SimpleStore<EhiPatientIdentifierTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiPatientIdentifierTypeIdsQueryAsync(...args),
            response => response.ehiPatientIdentifierTypeIds
        );
    }

    @State.bound
    public loadEhiDischargeReasonsAsync(): Promise<SimpleStore<EhiDischargeReasonId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiDischargeReasonIdsQueryAsync(...args),
            response => response.ehiDischargeReasonIds
        );
    }

    @State.bound
    public loadEhiDocumentTypesAsync(): Promise<SimpleStore<EhiDischargeReasonId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiDocumentTypeIdsQueryAsync(...args),
            response => response.ehiDocumentTypeIds
        );
    }

    @State.bound
    public loadEhiEReferralStatusIdsAsync(): Promise<SimpleStore<EhiEReferralStatusId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEReferralStatusIdsQueryAsync(...args),
            response => response.ehiEReferralStatusIds
        );
    }

    @State.bound
    public loadEhiEReferralTypeIdsAsync(): Promise<SimpleStore<EhiEReferralTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEReferralTypeIdsQueryAsync(...args),
            response => response.ehiEReferralTypeIds
        );
    }

    @State.bound
    public loadEhiEReferralReasonIdsAsync(): Promise<SimpleStore<EhiEReferralReasonId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEReferralReasonIdsQueryAsync(...args),
            response => response.ehiEReferralReasonIds
        );
    }

    @State.bound
    public loadEhiEReferralOrganizationTypeIdsAsync(): Promise<SimpleStore<EhiEReferralOrganizationTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEReferralOrganizationTypeIdsQueryAsync(...args),
            response => response.ehiEReferralOrganizationTypeIds
        );
    }

    @State.bound
    public loadEhiEReferralTravelExpenseTypeIdsAsync(): Promise<SimpleStore<EhiEReferralTravelExpenseTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEReferralTravelExpenseTypeIdsQueryAsync(...args),
            response => response.ehiEReferralTravelExpenseTypeIds
        );
    }

    @State.bound
    public loadEhiEReferralScheduleStatusIdsAsync(): Promise<SimpleStore<EhiEReferralScheduleStatusId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEReferralScheduleStatusIdsQueryAsync(...args),
            response => response.ehiEReferralScheduleStatusIds
        );
    }

    @State.bound
    public loadEhiEReferralQueryScenarioCodeIdsAsync(): Promise<SimpleStore<EhiEReferralQueryScenarioCodeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEReferralQueryScenarioCodeIdsQueryAsync(...args),
            response => response.ehiEReferralQueryScenarioCodeIds
        );
    }

    @State.bound
    public getEhiEReferralPatientIdentifierTypeVersionsBySelectorsAsync(
        versionSelectors: Array<IEntityVersionSelector<EhiEReferralPatientIdentifierTypeId>>
    ) {
        return this.processOperationAsync(
            new SimpleStore<IEhiEReferralPatientIdentifierTypeVersion[]>(),
            async target => {
                const response = await this.apiClient.getEhiEReferralPatientIdentifierTypeVersionsByVersionSelectorsQueryAsync(CreateRequestId(), new Proxy.GetEhiEReferralPatientIdentifierTypeVersionsByVersionSelectorsControllerDto({
                    versionSelectors: versionSelectors.map(i => new Proxy.EntityVersionSelectorOfEhiEReferralPatientIdentifierTypeId({
                        entityId: i.id,
                        validOn: i.validOn
                    }))
                }));

                target.operationInfo = createOperationInfo(response);
                target.value = response.versions.map(this.mapEhiEReferralPatientIdentifierTypeVersion);
            }
        );
    }

    @State.bound
    public getAllEhiEReferralPatientIdentifierTypeIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<EhiEReferralPatientIdentifierTypeId[]>(),
            async target => {
                const response = await this.apiClient.getAllEhiEReferralPatientIdentifierTypeIdsQueryAsync(CreateRequestId(), new Proxy.GetAllEhiEReferralPatientIdentifierTypeIdsControllerDto());

                target.operationInfo = createOperationInfo(response);
                target.value = response && response.ids;
            }
        );
    }

    private mapEhiEReferralPatientIdentifierTypeVersion(i: Proxy.EhiEReferralPatientIdentifierTypeVersionDto): IEhiEReferralPatientIdentifierTypeVersion {
        return {
            id: i.id,
            code: i.id.value,
            name: i.name,
            validity: i.validity
        } as IEhiEReferralPatientIdentifierTypeVersion;
    }

    @State.bound
    public getEhiEReferralProfessionCodeVersionsBySelectorsAsync(
        versionSelectors: Array<IEntityVersionSelector<EhiEReferralProfessionCodeId>>
    ) {
        return this.processOperationAsync(
            new SimpleStore<IEhiEReferralProfessionCodeVersion[]>(),
            async target => {
                const response = await this.apiClient.getEhiEReferralProfessionCodeVersionsByVersionSelectorsQueryAsync(CreateRequestId(), new Proxy.GetEhiEReferralProfessionCodeVersionsByVersionSelectorsControllerDto({
                    versionSelectors: versionSelectors.map(i => new Proxy.EntityVersionSelectorOfEhiEReferralProfessionCodeId({
                        entityId: i.id,
                        validOn: i.validOn
                    }))
                }));

                target.operationInfo = createOperationInfo(response);
                target.value = response.versions.map(this.mapEhiEReferralProfessionCodeVersion);
            }
        );
    }

    @State.bound
    public getAllEhiEReferralProfessionCodeIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<EhiEReferralProfessionCodeId[]>(),
            async target => {
                const response = await this.apiClient.getAllEhiEReferralProfessionCodeIdsQueryAsync(CreateRequestId(), new Proxy.GetAllEhiEReferralProfessionCodeIdsControllerDto());

                target.operationInfo = createOperationInfo(response);
                target.value = response && response.ids;
            }
        );
    }

    private mapEhiEReferralProfessionCodeVersion(i: Proxy.EhiEReferralProfessionCodeVersionDto): IEhiEReferralProfessionCodeVersion {
        return {
            id: i.id,
            code: i.id.value,
            name: i.name,
            validity: i.validity
        } as IEhiEReferralProfessionCodeVersion;
    }

    @State.bound
    public getEhiEReferralServiceTypeVersionsBySelectorsAsync(
        versionSelectors: Array<IEntityVersionSelector<EhiEReferralServiceTypeId>>
    ) {
        return this.processOperationAsync(
            new SimpleStore<IEhiEReferralServiceTypeVersion[]>(),
            async target => {
                const response = await this.apiClient.getEhiEReferralServiceTypeVersionsByVersionSelectorsQueryAsync(CreateRequestId(), new Proxy.GetEhiEReferralServiceTypeVersionsByVersionSelectorsControllerDto({
                    versionSelectors: versionSelectors.map(i => new Proxy.EntityVersionSelectorOfEhiEReferralServiceTypeId({
                        entityId: i.id,
                        validOn: i.validOn
                    }))
                }));

                target.operationInfo = createOperationInfo(response);
                target.value = response.versions.map(this.mapEhiEReferralServiceTypeVersion);
            }
        );
    }

    @State.bound
    public getAllEhiEReferralServiceTypeIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<EhiEReferralServiceTypeId[]>(),
            async target => {
                const response = await this.apiClient.getAllEhiEReferralServiceTypeIdsQueryAsync(CreateRequestId(), new Proxy.GetAllEhiEReferralServiceTypeIdsControllerDto());

                target.operationInfo = createOperationInfo(response);
                target.value = response && response.ids;
            }
        );
    }

    @State.bound
    public searchEhiEReferralServiceTypes(filterText: string, ordering: IOrderingState, paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new PagedItemStore<IEhiEReferralServiceTypeVersion>(),
            async (target) => {
                const response = await this.apiClient.ehiEReferralServiceTypeSelectorQueryAsync(
                    CreateRequestId(),
                    new Proxy.EhiEReferralServiceTypeSelectorControllerDto({
                        filterText: filterText,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfEhiEReferralServiceTypeSelectorQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfEhiEReferralServiceTypeSelectorQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: EhiEReferralServiceTypeSelectorQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.items = response.results && response.results.values.map(this.mapEhiEReferralServiceTypeVersion);
                target.totalCount = response.results && response.results.totalCount;
            }
        );
    }

    private mapEhiEReferralServiceTypeVersion(i: Proxy.EhiEReferralServiceTypeVersionDto): IEhiEReferralServiceTypeVersion {
        return {
            id: i.id,
            code: i.code,
            name: i.name,
            validity: i.validity
        } as IEhiEReferralServiceTypeVersion;
    }

    @State.bound
    public loadEhiCareTypesByIdsAsync(ids: EhiCareTypeId[]) {
        return this.processOperationAsync(
            new SimpleStore<EhiCareType[]>(),
            async target => {
                const response = await this.apiClient.getEhiCareTypesByIdsQueryAsync(CreateRequestId(), new Proxy.GetEhiCareTypesByIdsControllerDto({ ids: ids }));
                target.operationInfo = createOperationInfo(response);
                if (target.operationWasSuccessful) {
                    target.value = response.ehiCareTypes.map(ect => {
                        const result = new EhiCareType();
                        result.id = ect.id;
                        result.name = ect.name;
                        result.code = ect.code;
                        return result;
                    });
                }
            }
        );
    }

    @State.bound
    public loadAllEhiCareTypeIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<EhiCareTypeId[]>(),
            async target => {
                const response = await this.apiClient.getAllEhiCareTypeIdsQueryAsync(CreateRequestId());
                target.operationInfo = createOperationInfo(response);
                if (target.operationWasSuccessful) {
                    target.value = response.ids;
                }
            }
        );
    }

    @State.bound
    public loadEhiCareDocumentAsync(
        ehiToken: EhiToken,
        documentEhiId: string,
        organizationUnitId: OrganizationUnitId,
        isEmergency: boolean,
        emergencyReason: string) {

        return this.processOperationAsync(
            new SimpleStore<IEhiResult<Uint8Array>>(),
            async target => {
                target.value = {
                    value: await this.getFileResponseAsByteArrayAsync(async () => {
                        const response = await this.apiClient.getEhiDocumentContentQueryAsync(
                            CreateRequestId(),
                            documentEhiId,
                            ehiToken.tokenValue,
                            emergencyReason,
                            isEmergency,
                            organizationUnitId.value);
                        target.operationInfo = createOperationInfoForFileResponse(response);
                        return response;
                    }),
                    errors: null // TODO
                };
            }
        );
    }

    @State.bound
    public getEReferralsForPatientAsync(
        patientId: PatientId,
        pointOfCareId: PointOfCareId,
        isEmergency: boolean,
        emergencyReason?: string,
        ehiToken?: EhiToken) {

        return this.processOperationAsync(
            new SimpleStore<IEhiResult<CareActivityEReferralStore[]>>(),
            async target => {
                const response = await this.apiClient.getEhiEReferralsForPatientQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetEhiEReferralsForPatientControllerDto({
                        patientId: patientId,
                        pointOfCareId: pointOfCareId,
                        ehiTokenDto: !!ehiToken ? this.mapToken(ehiToken) : null,
                        isEmergency: isEmergency,
                        emergencyReason: emergencyReason
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    value: response.eReferrals?.map(item => {
                        const newStore = new CareActivityEReferralStore();
                        this.mapToCareActivityEReferralStore(item, newStore);
                        return newStore;
                    }),
                    errors: response.errors?.map(e => {
                        return new EhiError(e.code, e.moduleName, e.message, e.ehiErrorSeverity as any as EhiErrorSeverity);
                    })
                };
            });
    }

    @State.bound
    public getEReferralsForOrganizationUnitAsync(
        querySettings: EhiEReferralListQuerySettingsStore,
        ehiToken?: EhiToken
    ) {
        return this.processOperationAsync(
            new SimpleStore<IEhiResult<CareActivityEReferralStore[]>>(),
            async target => {
                const response = await this.apiClient.getEhiEReferralsForOrganizationUnitQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetEhiEReferralsForOrganizationUnitControllerDto({
                        scenarioCodeId: querySettings.scenarioCode,
                        startDate: querySettings.interval.from,
                        endDate: querySettings.interval.to,
                        breakGlass: querySettings.breakGlass,
                        organizationUnitId: querySettings.institute,
                        ehiTokenDto: !!ehiToken ? this.mapToken(ehiToken) : null
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    value: response.eReferrals?.map(item => {
                        const newStore = new CareActivityEReferralStore();
                        this.mapToCareActivityEReferralStore(item, newStore);
                        return newStore;
                    }),
                    errors: response.errors?.map(e => {
                        return new EhiError(e.code, e.moduleName, e.message, e.ehiErrorSeverity as any as EhiErrorSeverity);
                    })
                };
            });
    }

    @State.bound
    public getEReferralsForOrganizationUnitPermissionCheckAsync() {

        return this.processOperationAsync(
            new SimpleStore<IEhiResult<CareActivityEReferralStore[]>>(),
            async target => {
                const response = await this.apiClient.getEhiEReferralsForOrganizationUnitQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetEhiEReferralsForOrganizationUnitControllerDto({
                        scenarioCodeId: new EhiEReferralQueryScenarioCodeId("-1"),
                        startDate: LocalDate.today(),
                        endDate: LocalDate.today(),
                        breakGlass: false,
                        organizationUnitId: new OrganizationUnitId("-1"),
                        ehiTokenDto: null
                    }),
                    true
                );
                target.operationInfo = createOperationInfo(response);
            });
    }

    public getEReferralByIdAsync(
        ehiEReferralId: EhiEReferralId,
        pointOfCareId: PointOfCareId,
        isEmergency: boolean,
        emergencyReason?: string,
        ehiToken?: EhiToken) {

        return this.processOperationAsync(
            new SimpleStore<IEhiResult<CareActivityEReferralStore>>(),
            async target => {
                const response = await this.apiClient.getEhiEReferralByIdQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetEhiEReferralByIdControllerDto({
                        ehiEReferralId: ehiEReferralId,
                        pointOfCareId: pointOfCareId,
                        ehiTokenDto: !!ehiToken ? this.mapToken(ehiToken) : null,
                        isEmergency: isEmergency,
                        emergencyReason: emergencyReason
                    })
                );

                target.operationInfo = createOperationInfo(response);

                let newStore = null;
                const item = response.eReferral;

                if (!!item) {
                    newStore = new CareActivityEReferralStore();
                    this.mapToCareActivityEReferralStore(item, newStore);
                }

                target.value = {
                    value: newStore,
                    errors: response.errors?.map(e => {
                        return new EhiError(e.code, e.moduleName, e.message, e.ehiErrorSeverity as any as EhiErrorSeverity);
                    })
                };
            });
    }

    public searchEhiHealthcareProviderIdsAsync(filterText: string, maxNumber: number, validOn?: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<Array<IEntityVersionSelector<EhiHealthcareProviderId>>>(),
            async target => {
                const response = await this.apiClient.searchEhiHealthcareProvidersQueryAsync(
                    CreateRequestId(),
                    filterText,
                    maxNumber.toString(),
                    validOn ? validOn.stringify() : "");

                target.operationInfo = createOperationInfo(response);
                target.value = response.results.map(i => new EntityVersionSelector(i, response.validOn));
            });
    }

    public searchEhiHealthcareProvidersAsync(filterText: string, ordering: IOrderingState, paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new PagedItemStore<IEhiHealthcareProviderVersion>(),
            async target => {
                const response = await this.apiClient.ehiHealthcareProviderSelectorQueryAsync(
                    CreateRequestId(),
                    new Proxy.EhiHealthcareProviderSelectorControllerDto({
                        filterText: filterText,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfEhiHealthcareProviderSelectorQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfEhiHealthcareProviderSelectorQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: EhiHealthcareProviderSelectorQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    }));

                target.operationInfo = createOperationInfo(response);
                target.items = response.results && response.results.values.map(this.mapToEhiHealthcareProviderVersion);
                target.totalCount = response.results && response.results.totalCount;
            });
    }

    @State.bound
    public getAllEhiHealthcareProviderIdsAsync(): Promise<SimpleStore<EhiHealthcareProviderId[]>> {
        return this.processOperationAsync(
            new SimpleStore<EhiHealthcareProviderId[]>(),
            async target => {
                const response = await this.apiClient.getAllEhiHealthcareProvidersIdsQueryAsync(CreateRequestId());
                target.operationInfo = createOperationInfo(response);
                target.value = response.resultList;
            }
        );
    }

    @State.bound
    public getEhiHealthcareProviderVersionsBySelectorsAsync(versionSelectors: Array<IEntityVersionSelector<EhiHealthcareProviderId>>) {
        return this.processOperationAsync(
            new SimpleStore<IEhiHealthcareProviderVersion[]>(),
            async target => {
                const response = await this.apiClient.getEhiHealthcareProviderVersionsBySelectorsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetEhiHealthcareProviderVersionsBySelectorsControllerDto({
                        versionSelectors: versionSelectors.map(i => new Proxy.EntityVersionSelectorOfEhiHealthcareProviderId({
                            entityId: i.id,
                            validOn: i.validOn
                        }))
                    }));

                target.operationInfo = createOperationInfo(response);
                target.value = response.versions.map(this.mapToEhiHealthcareProviderVersion);
            }
        );
    }

    public searchEhiHealthcareProviderOrganizationUnitIdsAsync(filterText: string, maxNumber: number, providerId: EhiHealthcareProviderId) {
        return this.processOperationAsync(
            new SimpleStore<EhiHealthcareProviderOrganizationUnitId[]>(),
            async target => {
                const response = await this.apiClient.searchEhiHealthcareProviderOrganizationUnitsQueryAsync(
                    CreateRequestId(),
                    providerId?.toUrl(),
                    filterText,
                    maxNumber.toString());

                target.operationInfo = createOperationInfo(response);

                target.value = response.results.map(item => item);
            });
    }

    public searchEhiHealthcareProviderOrganizationUnitsAsync(filterText: string, ordering: IOrderingState, paging: IPagingState, providerId: EhiHealthcareProviderId) {
        const columnName = ordering && ordering.columnId as string;

        return this.processOperationAsync(
            new SimpleStore<IPagedItems<EhiHealthcareProviderOrganizationUnit>>(),
            async target => {
                const response = await this.apiClient.ehiHealthcareProviderOrganizationUnitSelectorQueryAsync(
                    CreateRequestId(),
                    new Proxy.EhiHealthcareProviderOrganizationUnitSelectorControllerDto({
                        filterText: filterText,
                        ehiHealthcareProviderId: providerId,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfEhiHealthcareProviderOrganizationUnitSelectorQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfEhiHealthcareProviderOrganizationUnitSelectorQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: EhiHealthcareProviderOrganizationUnitSelectorQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    }));

                target.operationInfo = createOperationInfo(response);

                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results.values.map(this.mapToEhiHealthcareProviderOrganizationUnit),
                };
            });
    }

    @State.bound
    public getAllEhiHealthcareProviderOrganizationUnitIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<EhiHealthcareProviderOrganizationUnitId[]>(),
            async target => {
                const response = await this.apiClient.getAllEhiHealthcareProviderOrganizationUnitIdsQueryAsync(CreateRequestId());
                target.operationInfo = createOperationInfo(response);
                target.value = response.resultList;
            }
        );
    }

    @State.bound
    public getEhiHealthcareProviderOrganizationUnitsByIdsAsync(ids: EhiHealthcareProviderOrganizationUnitId[]) {
        return this.processOperationAsync(
            new SimpleStore<EhiHealthcareProviderOrganizationUnit[]>(),
            async target => {
                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value));
                const response = await this.apiClient.getEhiHealthcareProviderOrganizationUnitsByIdsQueryAsync(CreateRequestId(),
                    buildQueryStringArray(normalizedIds));
                target.operationInfo = createOperationInfo(response);
                target.value = response.resultList.map(this.mapToEhiHealthcareProviderOrganizationUnit);
            }
        );
    }

    @State.bound
    public getEhiHealthcareProviderOrganizationUnitNameByCodeAsync(code: string) {
        return this.processOperationAsync(
            new SimpleStore<string>(),
            async target => {
                const response = await this.apiClient.tryGetEhiHealthCareProviderOrganizationUnitNameByCodeQueryAsync(CreateRequestId(), code);
                target.operationInfo = createOperationInfo(response);
                target.value = response.organizationUnitName;
            }
        );
    }

    @State.bound
    public tryGetPatientEhiIdentifierQueryAsync(patientId: PatientId, careActivityId?: CareActivityId, enforceValidity: boolean = false) {
        return this.processOperationAsync(
            new SimpleStore<EhiPatientIdentifierTypeId>(),
            async target => {
                const response = await this.apiClient.tryGetPatientEhiIdentifierQueryAsync(CreateRequestId(), new Proxy.TryGetPatientEhiIdentifierControllerDto(({
                    patientId: patientId,
                    careActivityId: careActivityId,
                    enforceValidity: enforceValidity
                })));
                target.operationInfo = createOperationInfo(response);
                target.value = response.ehiPatientIdentifierTypeId;
            });
    }

    @State.bound
    public getEhiHealthcareProviderNameByCodeAsync(code: string) {
        return this.processOperationAsync(
            new SimpleStore<string>(),
            async target => {
                const response = await this.apiClient.tryGetEhiHealthcareProviderNameByCodeQueryAsync(CreateRequestId(), code);
                target.operationInfo = createOperationInfo(response);
                target.value = response.healthcareProviderName;
            }
        );
    }

    @State.bound
    public getEhiOrganizationUnitExtensionAsync(patientId: PatientId, practitionerId: PractitionerId) {
        return this.processOperationAsync(
            new SimpleStore<OrganizationUnitId>(),
            async target => {
                const response = await this.apiClient.getEhiOrganizationUnitExtensionQueryAsync(CreateRequestId(), patientId.value, practitionerId.value);
                target.operationInfo = createOperationInfo(response);
                target.value = response.organizationUnitId;
            }
        );
    }

    @State.bound
    public isEhiEReferralDataNeededAsync(pointOfCareId: PointOfCareId, direction: ServiceRequestDirection) {
        return this.processOperationAsync(
            new SimpleStore<boolean>(),
            async target => {
                const response = await this.apiClient.isEhiEReferralDataNeededQueryAsync(CreateRequestId(), pointOfCareId.toUrl(), direction.toString());
                target.operationInfo = createOperationInfo(response);
                target.value = response.isEhiEReferralDataNeeded;
            }
        );
    }

    @State.bound
    public getEhiEReferralHealthCareProviderOrganizationUnitAsync(externalLocationId: ExternalLocationId) {
        return this.processOperationAsync(
            new SimpleStore<EhiHealthcareProviderOrganizationUnit>(),
            async target => {
                const response = await this.apiClient.tryGetEhiHealthCareProviderOrganizationUnitByExternalLocationIdQueryAsync(CreateRequestId(), externalLocationId.toUrl());
                target.operationInfo = createOperationInfo(response);
                target.value = response.ehiHealthcareProviderOrganizationUnit && this.mapToEhiHealthcareProviderOrganizationUnit(response.ehiHealthcareProviderOrganizationUnit);
            }
        );
    }

    @State.bound
    public loadEhiEReferralCovidExaminationSampleTypeIdsAsync(): Promise<SimpleStore<EhiEReferralCovidExaminationSampleTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEReferralCovidExaminationSampleTypeIdsQueryAsync(...args),
            response => response.ehiEReferralCovidExaminationSampleTypeIds
        );
    }

    @State.bound
    public loadEhiEReferralCovidExaminationTypeIdsAsync(): Promise<SimpleStore<EhiEReferralCovidExaminationTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEReferralCovidExaminationTypeIdsQueryAsync(...args),
            response => response.ehiEReferralCovidExaminationTypeIds
        );
    }

    @State.bound
    public loadEhiEReferralCovidContactExaminationReasonIdsAsync(): Promise<SimpleStore<EhiEReferralCovidContactExaminationReasonId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEReferralCovidContactExaminationReasonIdsQueryAsync(...args),
            response => response.ehiEReferralCovidContactExaminationReasonIds
        );
    }

    @State.bound
    public loadEhiEReferralCovidScreeningExaminationReasonIdsAsync(): Promise<SimpleStore<EhiEReferralCovidScreeningExaminationReasonId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEReferralCovidScreeningExaminationReasonIdsQueryAsync(...args),
            response => response.ehiEReferralCovidScreeningExaminationReasonIds
        );
    }

    @State.bound
    public loadEhiEReferralCovidSymptomsSeverityIdsAsync(): Promise<SimpleStore<EhiEReferralCovidSymptomsSeverityId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEReferralCovidSymptomsSeverityIdsQueryAsync(...args),
            response => response.ehiEReferralCovidSymptomsSeverityIds
        );
    }

    @State.bound
    public loadEhiEReferralCovidPreviousResultIdsAsync(): Promise<SimpleStore<EhiEReferralCovidPreviousResultId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEReferralCovidPreviousResultIdsQueryAsync(...args),
            response => response.ehiEReferralCovidPreviousResultIds
        );
    }

    @State.bound
    public loadEhiEReferralCovidQuickTestResultIdsAsync(): Promise<SimpleStore<EhiEReferralCovidQuickTestResultId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEReferralCovidQuickTestResultIdsQueryAsync(...args),
            response => response.ehiEReferralCovidQuickTestResultIds
        );
    }

    @State.bound
    public loadEhiCovidBreathingStatusIdsAsync(): Promise<SimpleStore<EhiCovidBreathingStatusId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiCovidBreathingStatusIdsQueryAsync(...args),
            response => response.ehiCovidBreathingStatusIds
        );
    }

    @State.bound
    public loadEhiCovidSymptomsSeverityIdsAsync(): Promise<SimpleStore<EhiCovidSymptomsSeverityId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiCovidSymptomsSeverityIdsQueryAsync(...args),
            response => response.ehiCovidSymptomsSeverityIds
        );
    }

    @State.bound
    public loadEhiCovidSymptomStatusIdsAsync(): Promise<SimpleStore<EhiCovidSymptomStatusId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiCovidSymptomStatusIdsQueryAsync(...args),
            response => response.ehiCovidSymptomStatusIds
        );
    }

    @State.bound
    public loadEhiCovidUrineAmountIdsAsync(): Promise<SimpleStore<EhiCovidUrineAmountId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiCovidUrineAmountIdsQueryAsync(...args),
            response => response.ehiCovidUrineAmountIds
        );
    }

    @State.bound
    public loadEhiCovidVentilationStatusIdsAsync(): Promise<SimpleStore<EhiCovidVentilationStatusId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiCovidVentilationStatusIdsQueryAsync(...args),
            response => response.ehiCovidVentilationStatusIds
        );
    }

    @State.bound
    public loadEhiCovidSelfExaminationTimeCodeIdsAsync(): Promise<SimpleStore<EhiCovidSelfExaminationTimeCodeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiCovidSelfExaminationTimeCodeIdsQueryAsync(...args),
            response => response.ehiCovidSelfExaminationTimeCodeIds
        );
    }

    @State.bound
    public loadEhiCovidSelfExaminationGeneralStatusIdsAsync(): Promise<SimpleStore<EhiCovidSelfExaminationGeneralStatusId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiCovidSelfExaminationGeneralStatusIdsQueryAsync(...args),
            response => response.ehiCovidSelfExaminationGeneralStatusIds
        );
    }

    @State.bound
    public tryGetEhiEReferralServiceTypeIdByCodeAsync(code: string): Promise<SimpleStore<EhiEReferralServiceTypeId>> {
        return this.processOperationAsync(
            new SimpleStore<EhiEReferralServiceTypeId>(),
            async target => {
                const response = await this.apiClient.tryGetEhiEReferralServiceTypeIdByCodeQueryAsync(CreateRequestId(), code);
                target.operationInfo = createOperationInfo(response);
                target.value = response.ehiEReferralServiceTypeId;
            }
        );
    }

    @State.bound
    public tryGetEhiEReferralServiceTypeIdByHealthcareProfessionIdAsync(healthcareProfessionId: HealthcareProfessionId): Promise<SimpleStore<EhiEReferralServiceTypeId>> {
        return this.processOperationAsync(
            new SimpleStore<EhiEReferralServiceTypeId>(),
            async target => {
                const response = await this.apiClient.tryGetEhiEReferralServiceTypeIdByHealthcareProfessionIdQueryAsync(CreateRequestId(), healthcareProfessionId.value);
                target.operationInfo = createOperationInfo(response);
                target.value = response.ehiEReferralServiceTypeId;
            }
        );
    }

    @State.bound
    public tryGetEhiEReferralServiceTypeIdByPointOfCareIdAsync(pointOfCareId: PointOfCareId): Promise<SimpleStore<EhiEReferralServiceTypeId>> {
        return this.processOperationAsync(
            new SimpleStore<EhiEReferralServiceTypeId>(),
            async target => {
                const response = await this.apiClient.tryGetEhiEReferralServiceTypeIdByOrganizationUnitIdQueryAsync(CreateRequestId(), pointOfCareId.value);
                target.operationInfo = createOperationInfo(response);
                target.value = response.ehiEReferralServiceTypeId;
            }
        );
    }

    @State.bound
    public getEhiStructuredDocumentContactsForPractitionerAsync(practitionerId: PractitionerId): Promise<SimpleStore<EhiStructuredDocumentContactStore[]>> {
        return this.processOperationAsync(
            new SimpleStore<EhiStructuredDocumentContactStore[]>(),
            async target => {
                const response = await this.apiClient.getEhiStructuredDocumentContactsForPractitionerQueryAsync(CreateRequestId(), practitionerId.value);
                target.operationInfo = createOperationInfo(response);
                target.value = response.ehiStructuredDocumentContacts && response.ehiStructuredDocumentContacts.map(i => this.mapEhiStructuredDocumentContact(i));
            }
        );
    }

    @State.bound
    public getEhiStructuredDocumentContactsForPatientAsync(patientId: PatientId): Promise<SimpleStore<EhiStructuredDocumentContactStore[]>> {
        return this.processOperationAsync(
            new SimpleStore<EhiStructuredDocumentContactStore[]>(),
            async target => {
                const response = await this.apiClient.getEhiStructuredDocumentContactsForPatientQueryAsync(CreateRequestId(), patientId.value);
                target.operationInfo = createOperationInfo(response);
                target.value = response.ehiStructuredDocumentContacts && response.ehiStructuredDocumentContacts.map(i => this.mapEhiStructuredDocumentContact(i));
            }
        );
    }

    private mapEhiStructuredDocumentContact(ehiStructuredDocumentContact: Proxy.EhiStructuredDocumentContactDto) {
        return new EhiStructuredDocumentContactStore(ehiStructuredDocumentContact.ehiStructuredDocumentContactTypeId, ehiStructuredDocumentContact.value);
    }

    @State.bound
    public tryGetEhiEReferralIdByServiceRequestIdQueryAsync(serviceRequestId: ServiceRequestId): Promise<SimpleStore<EhiEReferralId>> {
        return this.processOperationAsync(
            new SimpleStore<EhiEReferralId>(),
            async target => {
                const response = await this.apiClient.tryGetEhiEReferralIdByServiceRequestIdQueryAsync(CreateRequestId(), serviceRequestId.value);
                target.operationInfo = createOperationInfo(response);
                target.value = response.ehiEReferralId;
            }
        );
    }

    private mapToEhiHealthcareProviderVersion(dto: Proxy.EhiHealthcareProviderVersionDto): IEhiHealthcareProviderVersion {
        return {
            id: dto.id,
            code: dto.code,
            name: dto.name,
            validity: dto.validity
        } as IEhiHealthcareProviderVersion;
    }

    private mapToEhiHealthcareProviderOrganizationUnit(dto: Proxy.EhiHealthcareProviderOrganizationUnitDto) {
        const store = new EhiHealthcareProviderOrganizationUnit();
        store.id = dto.id;
        store.code = dto.code;
        store.name = dto.name;
        store.ehiHealthcareProviderId = dto.ehiHealthcareProviderId;
        store.healthcareProfessionIds = dto.healthcareProfessionIds;
        return store;
    }

    private mapToCareActivityEReferralStore(item: Proxy.EhiEReferralDto, newStore: CareActivityEReferralStore) {
        newStore.ehiEReferralId = item.id;
        newStore.name = mapToNameStore(item.name);
        newStore.genderCode = item.genderCode;
        newStore.genderId = item.genderId;
        newStore.birthDate = item.birthDate;
        newStore.mothersName = mapToNameStore(item.mothersName);
        newStore.patientIdentifierTypeCode = item.patientIdentifierTypeCode;
        newStore.patientIdentifierTypeId = item.patientIdentifierTypeId;
        newStore.patientIdentifierValue = item.patientIdentifierValue;
        newStore.countryId = item.countryId;
        newStore.country = item.country;
        newStore.city = item.city;
        newStore.street = item.street;
        newStore.referralInstituteCode = item.referralInstituteCode;
        newStore.referralInstituteName = item.referralInstituteName;
        newStore.referralLocationCode = item.referralLocationCode;
        newStore.referralLocationName = item.referralLocationName;
        newStore.referralLocation = item.referralLocation;
        newStore.referralDoctorName = mapToNameStore(item.referralDoctorName);
        newStore.referralDoctorCode = item.referralDoctorCode;
        newStore.referralDoctor = item.referralDoctor;
        newStore.careInstituteCode = item.careInstituteCode;
        newStore.careInstituteName = item.careInstituteName;
        newStore.careLocationCode = item.careLocationCode;
        newStore.careLocationName = item.careLocationName;
        newStore.createdAt = item.createdAt;
        newStore.validAt = item.validAt;
        newStore.referralNote = item.referralNote;
        newStore.referralServiceTypeCode = item.referralServiceTypeCode;
        newStore.referralServiceTypeId = item.referralServiceTypeId;
        newStore.referralReasonCode = item.referralReasonCode;
        newStore.referralReasonId = item.referralReasonId;
        newStore.referralDiagnosisCode = item.referralDiagnosisCode;
        newStore.referralDiagnosisDescription = item.referralDiagnosisDescription;
        newStore.referralTypeCode = item.referralTypeCode;
        newStore.referralTypeId = item.referralTypeId;
        newStore.referralInstituteTypeCode = item.referralInstituteTypeCode;
        newStore.referralInstituteTypeId = item.referralInstituteTypeId;
        newStore.isInAreaCare = item.isInAreaCare;
        newStore.isUrgentReferral = item.isUrgentReferral;
        newStore.isPostponedInstituteChoice = item.isPostponedInstituteChoice;
        newStore.isPaperBased = item.isPaperBased;
        newStore.isOutOfOrder = item.isOutOfOrder;
        newStore.sickAllowanceIdentifier = item.sickAllowanceIdentifier;
        newStore.anamnesis = item.anamnesis;
        newStore.concilium = item.concilium;
        newStore.professionCode = item.professionCode;
        newStore.professionCodeId = item.professionCodeId;
        newStore.referralServiceCode = item.referralServiceCode;
        newStore.referralServiceId = item.referralServiceId;
        newStore.sicknessInDaysSinceStart = item.sicknessInDaysSinceStart;
        newStore.travelExpenseTypeCode = item.travelExpenseTypeCode;
        newStore.travelExpenseTypeId = item.travelExpenseTypeId;
        newStore.travelVoucherIdentifier = item.travelVoucherIdentifier;
        newStore.ehiIdentifier = item.ehiIdentifier;
        newStore.reportedAt = item.reportedAt;
        newStore.referralStatusCode = item.referralStatusCode;
        newStore.referralStatusId = item.referralStatusId;
        newStore.lastChangeAt = item.lastChangeAt;
        newStore.referralCareIdentifier = item.referralCareIdentifier;
        newStore.doctorNotification = item.doctorNotification;
        newStore.patientNotification = item.patientNotification;
    }

    private mapEhiDoctor(ehiDoctor: Proxy.EhiDoctorDto) {
        const store = new EhiDoctor();
        store.name = {
            prefix: ehiDoctor?.name?.prefix,
            familyName: ehiDoctor?.name?.familyName,
            givenName: ehiDoctor?.name?.givenName1
        };
        store.stampCode = ehiDoctor?.stampCode;
        return store;
    }

    private mapEhiOrganizationUnit(ehiOrganizationUnit: Proxy.EhiOrganizationUnitDto) {
        const store = new EhiOrganizationUnit();
        store.code = ehiOrganizationUnit.code;
        store.name = ehiOrganizationUnit.name;
        return store;
    }

    @State.bound
    public loadEhiInpatientAdmissionTypesAsync(): Promise<SimpleStore<EhiInpatientAdmissionTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiInpatientAdmissionTypeIdsQueryAsync(...args),
            response => response.ehiInpatientAdmissionTypeIds
        );
    }

    @State.bound
    public loadEhiInpatientAdmissionClassificationsAsync(): Promise<SimpleStore<EhiInpatientAdmissionClassificationId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiInpatientAdmissionClassificationIdsQueryAsync(...args),
            response => response.ehiInpatientAdmissionClassificationIds
        );
    }

    @State.bound
    public loadEhiPatientFurtherTreatmentsAsync(): Promise<SimpleStore<EhiPatientFurtherTreatmentId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiPatientFurtherTreatmentIdsQueryAsync(...args),
            response => response.ehiPatientFurtherTreatmentIds
        );
    }

    @State.bound
    public loadEhiEmergencyLevelClaimTypesAsync(): Promise<SimpleStore<EhiEmergencyLevelClaimTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEmergencyLevelClaimTypeIdsQueryAsync(...args),
            response => response.ehiEmergencyLevelClaimTypeIds
        );
    }

    @State.bound
    public loadEhiEmergencyExaminationClaimTypesAsync(): Promise<SimpleStore<EhiEmergencyExaminationClaimTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiEmergencyExaminationClaimTypeIdsQueryAsync(...args),
            response => response.ehiEmergencyExaminationClaimTypeIds
        );
    }

    @State.bound
    public loadEhiStructuredDocumentContactTypeIdsAsync(): Promise<SimpleStore<EhiStructuredDocumentContactTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiStructuredDocumentContactTypeIdsQueryAsync(...args),
            response => response.ehiStructuredDocumentContactTypeIds
        );
    }

    @State.bound
    public getHungarianPatientDefaultDataAsync() {
        return this.processOperationAsync(
            new SimpleStore<IHungarianPatientDefaultData>(),
            async target => {
                const response = await this.patientRegisterClient.getHungarianPatientDefaultDataQueryAsync(CreateRequestId());

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    addressTypeId: response.addressTypeId,
                    countryId: response.countryId,
                    insurerOrganizationId: response.insurerOrganizationId,
                    patientDocumentTypeId: response.patientDocumentTypeId
                };
            }
        );
    }

    @State.bound
    public getEuCardReplacementTypeIdsAsync(): Promise<SimpleStore<EuCardReplacementTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.referenceDataClient.getEuCardReplacementTypeIdsQueryAsync(...args),
            response => response.euCardReplacementTypeIds
        );
    }
}
