import React from "react";
import ReactHtmlParser from "react-html-parser";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import OrganizationUnitDefinitionId from "@Primitives/OrganizationUnitDefinitionId.g";
import { IOrganizationUnitPanelController } from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitPanel/OrganizationUnitPanel";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import OrganizationUnitTreeNode from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnitTreeNode";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import OrganizationUnitTreePanelView from "./OrganizationUnitTreePanelView";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import { IPropertyGroupsPanelController } from "@HisPlatform/BoundedContexts/Organization/Components/Panels/PropertyGroupsPanel/PropertyGroupsPanel";
import ITreeViewNode from "@CommonControls/TreeView/ITreeViewNode";
import _ from "@HisPlatform/Common/Lodash";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { OrganizationUnitTreePanelDetailMode } from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/OrganizationUnitTreePanelDetailMode";
import { checkMatchForNode, filterTree } from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/OrganizationUnitTreeFilter";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import OrganizationUnitDefinitionSelectorDialogParams, { IOrganizationUnitDefinitionSelectorDialogResult } from "./OrganizationUnitDefinitionSelectorDialogParams";

interface IOrganizationUnitTreePanelDependencies {
    apiAdapter: StructureApiAdapter;
    referenceDataStore: OrganizationReferenceDataStore;
    dialogService: IDialogService;
    notificationService: INotificationService;
}

interface IOrganizationUnitTreePanelProps {
    _dependencies?: IOrganizationUnitTreePanelDependencies;
    _modalService?: IModalService;
    detail: OrganizationUnitTreePanelDetailMode;
    selectedId: OrganizationUnitId;
    onChange: (
        id: OrganizationUnitId,
        detailMode: OrganizationUnitTreePanelDetailMode) => void;
}

/** @screen */
@State.observer
class OrganizationUnitTreePanel extends React.Component<IOrganizationUnitTreePanelProps> {

    private get apiAdapter() { return this.props._dependencies.apiAdapter; }
    private get referenceDataStore() { return this.props._dependencies.referenceDataStore; }
    private get dialogService() { return this.props._dependencies.dialogService; }
    private get notificationService() { return this.props._dependencies.notificationService; }

    @State.observable.ref private organizationStructure: OrganizationUnitTreeNode[] = [];

    @State.computed private get selectedNode() {
        return this.getById(this.props.selectedId);
    }

    @State.computed private get isNew() {
        return this.selectedNode && this.selectedNode.organizationUnitId && this.selectedNode.organizationUnitId.value === "new";
    }

    @State.observable.ref private newOrganizationUnitParentId: OrganizationUnitId = null;
    @State.observable.ref private newOrganizationUnitDefinitionId: OrganizationUnitDefinitionId = null;

    private organizationUnitPanelController = {
        isDirty: null
    } as IOrganizationUnitPanelController;

    private propertyGroupsPanelController = {
        isDirty: null
    } as IPropertyGroupsPanelController;

    private isOpenMap = new Map<string, boolean>();
    @State.observable.ref private filterValue: string = "";
    private debouncedFilterValueBox = State.observable.box("");

    @State.observable private isLoading: boolean = false;
    private navigatingToPropertiesTab = false;

    @State.computed public get filteredStructure() {
        return filterTree(this.debouncedFilterValueBox.get(), this.organizationStructure);
    }

    private getById(id: OrganizationUnitId, node: OrganizationUnitTreeNode = null): OrganizationUnitTreeNode {

        if (node && ValueWrapper.equals(node.organizationUnitId, id)) {
            return node;
        }

        if (node && !node.children) {
            return null;
        }

        const children = node ? node.children : this.filteredStructure;

        for (const child of children) {
            const result = this.getById(id, child);
            if (result) {
                return result;
            }
        }

        return null;
    }

    @State.bound
    private async canCloseDetailAsync() {
        if (this.props.detail === "base-data") {
            return await this.canCloseBaseDataDetailAsync();
        } else if (this.props.detail === "parameters") {
            return await this.canClosePropertiesDetailAsync();
        }
        return true;
    }

    @State.action.bound
    public setNewOrganizationParentProperties(parentId: OrganizationUnitId) {
        this.newOrganizationUnitParentId = parentId;
    }

    @State.action.bound
    public setNewOrganizationUnitDefinitionId(definitionId: OrganizationUnitDefinitionId) {
        this.newOrganizationUnitDefinitionId = definitionId;
    }

    @State.action.bound
    private successfullySaved(id: OrganizationUnitId) {
        dispatchAsyncErrors((async () => {
            await this.loadAsync();
            if (this.props.selectedId && this.props.selectedId.value === "new") {
                if (this.navigatingToPropertiesTab) {
                    this.props.onChange(id, "parameters");
                    this.navigatingToPropertiesTab = false;
                } else {
                    this.props.onChange(id, null);
                }
            } else if (ValueWrapper.equals(this.props.selectedId, id)) {
                this.closeDetail();
            }
        })(), this);
    }

    @State.boundLoadingState("isLoading")
    private async loadAsync(openAll?: boolean) {
        const organizationStructure = await this.apiAdapter.getOrganizationStructureAsync(this.isOpenMap, false);
        await this.loadHealthcareProfessionsAsync(organizationStructure.value);
        this.setOrganizationStructure(organizationStructure.value);

        if (openAll) {
            this.filteredStructure.forEach(x => this.openAllChildrenNode(x));
        }
    }

    @State.bound
    private async loadHealthcareProfessionsAsync(nodes: OrganizationUnitTreeNode[]) {
        const ids = _.flatten(nodes.map(item => item.getHealthCareProfessionIds()));
        const uniqIds = _.uniqBy(ids, item => item.value);
        await this.referenceDataStore.healthCareProfession.ensureLoadedAsync(uniqIds);
    }

    @State.bound
    private async canCloseBaseDataDetailAsync() {
        if (this.organizationUnitPanelController.isDirty && this.organizationUnitPanelController.isDirty()) {
            const answer = await this.dialogService.confirmIfNotSaved(StaticOrganizationResources.OrganizationUnitPanel.Messages.DiscardChangesConfirmationTitle, StaticOrganizationResources.OrganizationUnitPanel.Messages.DiscardChangesConfirmationMessage);
            if (answer.resultCode === DialogResultCode.Yes) {
                if (this.organizationUnitPanelController.externalSaveAsync) {
                    return await this.organizationUnitPanelController.externalSaveAsync();
                }
                return true;
            } else if (answer.resultCode === DialogResultCode.No) {
                return true;
            } else { // result === DialogResultCode.Cancel
                return false;
            }
        }
        return true;
    }

    @State.bound
    private async canClosePropertiesDetailAsync() {
        if (this.propertyGroupsPanelController.isDirty && this.propertyGroupsPanelController.isDirty()) {
            const answer = await this.dialogService.confirmIfNotSaved(StaticOrganizationResources.OrganizationUnitPanel.Messages.DiscardChangesConfirmationTitle, StaticOrganizationResources.OrganizationUnitPanel.Messages.DiscardChangesConfirmationMessage);
            if (answer.resultCode === DialogResultCode.Yes) {
                if (this.propertyGroupsPanelController.externalSaveAsync) {
                    await this.propertyGroupsPanelController.externalSaveAsync();
                }
                return true;
            } else if (answer.resultCode === DialogResultCode.No) {
                return true;
            } else { // result === DialogResultCode.Cancel
                return false;
            }
        }
        return true;
    }

    @State.bound
    private async tryDeleteOrganizationUnitAsync(node: OrganizationUnitTreeNode) {
        const answer = await this.dialogService.yesNo(
            StaticOrganizationResources.OrganizationUnitPanel.Messages.DeleteConfirmationTitle,
            this.renderDeleteConfirmation(node.name)
        );

        if (answer.resultCode === DialogResultCode.Yes) {
            await this.apiAdapter.deleteOrganizationUnitAsync(node.organizationUnitId, node.rowVersion);
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.bound
    private renderDeleteConfirmation(name: string) {
        const text = formatString(StaticOrganizationResources.OrganizationUnitPanel.Messages.DeleteConfirmationMessage, "<b>" + name + "</b>");
        return (<p>{ReactHtmlParser(text)}</p>);
    }

    @State.action.bound
    private setOrganizationStructure(newValue: OrganizationUnitTreeNode[]) {
        this.organizationStructure = newValue;
    }

    @State.action.bound
    public setFilterValue(value: string) {
        this.filterValue = value;
        this.setDebouncedFilterValue();
    }

    public setDebouncedFilterValue = _.debounce(() => {
        State.runInAction(() => {
            this.debouncedFilterValueBox.set(this.filterValue);

            if (this.filterValue === "") {
                this.organizationStructure.forEach(x => this.openAllChildrenNode(x));
            } else {
                this.filteredStructure.forEach(x => this.openFilteredTree(x));
            }
        });
    }, 300);

    @State.action.bound
    private openFilteredTree(node: ITreeViewNode): boolean {
        if (node instanceof OrganizationUnitTreeNode) {
            let shouldBeVisible = false;
            if (node.children) {
                for (const child of node.children) {
                    shouldBeVisible = this.openFilteredTree(child) || shouldBeVisible;
                }
            }
            this.setIsOpen(node, shouldBeVisible);
            return shouldBeVisible || checkMatchForNode(this.debouncedFilterValueBox.get(), node);
        }
        return false;
    }

    @State.bound
    private openAllChildrenNode(node: ITreeViewNode) {
        if (node instanceof OrganizationUnitTreeNode) {
            this.setIsOpen(node, true);
            if (!node.isLeaf && node.children) {
                node.children.forEach(x => this.openAllChildrenNode(x));
            }
        }
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadPanelAsync, this.apiAdapter.updateOrganizationUnitPermissionCheckAsync);

    private initialize() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.bound
    private async loadPanelAsync() {
        await this.loadAsync(true);

        if (this.props.selectedId && this.props.selectedId.value === "new") {
            this.props.onChange(null, null);
        }
    }

    @State.bound
    private setIsOpen(node: OrganizationUnitTreeNode, isOpen: boolean) {
        this.isOpenMap.set(node.organizationUnitId.value, isOpen);
        node.setIsOpen(isOpen);
    }

    @State.bound
    private async addNewOrganizationUnitAsync(node: OrganizationUnitTreeNode) {
        this.closeDetail();

        this.setNewOrganizationParentProperties(node.organizationUnitId);
        const canClose = await this.canCloseDetailAsync();

        if (canClose) {
            const childDefinitionIds = await this.apiAdapter.getChildOrganizationUnitDefinitionIdsAsync(node.definitionId);
            if (childDefinitionIds.value && childDefinitionIds.value.length > 1) {
                const result =
                    await this.props._modalService.showDialogAsync<IOrganizationUnitDefinitionSelectorDialogResult>(new OrganizationUnitDefinitionSelectorDialogParams(childDefinitionIds.value));

                if (result?.result) {
                    this.setNewOrganizationUnitDefinitionId(result?.result);
                    this.editOrganizationUnit(this.createNewDraftNode(node, result?.result), node.organizationUnitId);
                }
            } else if (childDefinitionIds.value && childDefinitionIds.value.length === 1) {
                const definitionId = childDefinitionIds.value[0];
                this.setNewOrganizationUnitDefinitionId(definitionId);
                this.editOrganizationUnit(this.createNewDraftNode(node, definitionId), node.organizationUnitId);
            }
        }
    }

    @State.action
    private createNewDraftNode(node: OrganizationUnitTreeNode, definitionId: OrganizationUnitDefinitionId) {
        const newNode = OrganizationUnitTreeNode.create({
            organizationUnitId: new OrganizationUnitId("new"),
            code: "*",
            name: StaticWebAppResources.ToolkitControls.SelectBox.NewItem,
            parentId: node.organizationUnitId,
            definitionId: definitionId,
            children: []
        });
        node.children.push(newNode);
        return newNode;
    }

    @State.bound
    private async editOrganizationUnitAsync(node: OrganizationUnitTreeNode, newParentId?: OrganizationUnitId) {
        const canClose = await this.canCloseDetailAsync();
        if (canClose) {
            this.props.onChange(
                node.organizationUnitId, !!newParentId || !this.props.detail ? "base-data" : this.props.detail);
        }
    }

    @State.bound
    private editOrganizationUnit(node: OrganizationUnitTreeNode, newParentId?: OrganizationUnitId) {
        dispatchAsyncErrors(this.editOrganizationUnitAsync(node, newParentId), this);
    }

    @State.bound
    private setDetailMode(detail: OrganizationUnitTreePanelDetailMode) {
        dispatchAsyncErrors(this.setDetailModeAsync(detail), this);
    }

    private async setDetailModeAsync(detail: OrganizationUnitTreePanelDetailMode) {
        if (this.isNew && detail === "parameters") {
            const dialogResult = await this.dialogService.yesNo(StaticOrganizationResources.OrganizationUnitPanel.Messages.SaveNewUnitFirstTitle, StaticOrganizationResources.OrganizationUnitPanel.Messages.SaveNewUnitFirst);
            if (dialogResult.resultCode === DialogResultCode.Yes) {
                if (this.organizationUnitPanelController.externalSaveAsync) {
                    this.navigatingToPropertiesTab = true;
                    await this.organizationUnitPanelController.externalSaveAsync();
                }
            }
        } else {
            const canClose = await this.canCloseDetailAsync();
            if (canClose) {
                this.props.onChange(this.props.selectedId, detail);
            }
        }
    }

    @State.bound
    private closeDetail(checkDirtyFlag: boolean = true) {
        if (checkDirtyFlag) {
            this.canCloseDetailAsync().then(canClose => {
                if (canClose) {
                    this.props.onChange(this.isNew ? null : this.props.selectedId, null);
                    this.removeNewDraftItem();
                }
            });
        } else {
            this.props.onChange(this.isNew ? null : this.props.selectedId, null);
            this.removeNewDraftItem();
        }
    }

    @State.action.bound
    private cancelDetail() {
        this.closeDetail();
    }

    public componentDidMount() {
        this.initialize();
    }

    @State.action
    private removeNewDraftItem() {
        const newItem = this.getById(new OrganizationUnitId("new"));
        if (newItem) {
            const parent = this.getById(newItem.parentId);
            if (parent) {
                _.remove(parent.children, u => u.organizationUnitId.value === "new");
            }
        }
    }

    @State.bound
    private showOrganizationUnitHasCareActivitiesError() {
        this.notificationService.error(StaticOrganizationResources.OrganizationUnitPanel.Messages.OrganizationUnitHasCareActivitiesErrorMessage);
        return true;
    }

    @State.bound
    private showDeletingNotLeafOrganizationUnitError() {
        this.notificationService.error(StaticOrganizationResources.OrganizationUnitPanel.Messages.DeletingNotLeafOrganizationUnitExceptionMessage);
        return true;
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticOrganizationResources.OrganizationUnitPanel.Title} />;
        }

        return (
            <>
                <BusinessErrorHandler.Register
                    businessErrorName="OrganizationUnitHasCareActivitiesError"
                    handler={this.showOrganizationUnitHasCareActivitiesError}
                />
                <BusinessErrorHandler.Register
                    businessErrorName="DeletingNotLeafOrganizationUnitException"
                    handler={this.showDeletingNotLeafOrganizationUnitError}
                />
                <OrganizationUnitTreePanelView
                    newOrganizationUnitDefinitionId={this.newOrganizationUnitDefinitionId}
                    newOrganizationUnitParentId={this.newOrganizationUnitParentId}
                    nodeOpened={this.setIsOpen}
                    filterValue={this.filterValue}
                    setFilterValue={this.setFilterValue}
                    organizationStructure={this.filteredStructure}
                    organizationUnitPanelController={this.organizationUnitPanelController}
                    propertyGroupsPanelController={this.propertyGroupsPanelController}
                    debouncedFilterValueBox={this.debouncedFilterValueBox}
                    addingNew={this.isNew}

                    detailMode={this.props.detail}
                    onDetailChange={this.setDetailMode}
                    selectedOrganizationUnit={this.selectedNode}
                    onCloseDetail={this.closeDetail}
                    onCancelDetail={this.cancelDetail}
                    onSuccessfulSave={this.successfullySaved}

                    onAddChildAsync={this.addNewOrganizationUnitAsync}
                    onDeleteAsync={this.tryDeleteOrganizationUnitAsync}
                    onEdit={this.editOrganizationUnit}

                    isLoading={this.isLoading}
                />
            </>
        );
    }
}

export default connect(
    OrganizationUnitTreePanel,
    new DependencyAdapter<IOrganizationUnitTreePanelProps, IOrganizationUnitTreePanelDependencies>(container => {
        return {
            apiAdapter: container.resolve("StructureApiAdapter"),
            referenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            dialogService: container.resolve("IDialogService"),
            notificationService: container.resolve("INotificationService")
        };
    }),
    new HisModalServiceAdapter()
);
