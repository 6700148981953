import React, { useCallback } from "react";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import DiagnosisListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisListStore";
import DiagnosisStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisStore";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import DiagnosisList from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/DiagnosisListPanel/Master/DiagnosisList";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import DiagnosisDetail from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/DiagnosisListPanel/Detail/DiagnosisDetail";
import OpenDetailDiagnosisList from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/DiagnosisListPanel/Master/OpenDetailDiagnosisList";
import ExtensionController from "@HisPlatform/Components/HisPlatformExtensionPoint/ExtensionController";
import SaveButton from "@CommonControls/SaveButton";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { LockIndicatorComponent } from "@HisPlatformControls";
import Flex from "@CommonControls/Flex";
import PatientId from "@Primitives/PatientId.g";
import PermissionCheckContextAdapter from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import Button from "@CommonControls/Button";
import ViewContextProvider from "@Toolkit/ReactClient/Components/ViewContext/ViewContextProvider";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import MasterDetailScreen from "@Toolkit/ReactClient/Components/Screen/MasterDetailScreen";

interface IDiagnosisListPanelViewProps {
    diagnosisListStore: DiagnosisListStore;
    forceReleaseLockAndLoadAsync: () => Promise<void>;
    isLoading?: boolean;
    newDiagnosis: DiagnosisStore;
    selectedDiagnosis: DiagnosisStore;
    setSelectedDiagnosis: (store: DiagnosisStore) => void;
    onAddDiagnosis: () => void;
    onDeleteDiagnosisAsync: (store: DiagnosisStore) => Promise<void>;
    readonly: boolean;
    canAcquireLock: boolean;
    onNavigateBack?: () => void;

    mapValidationProblems: (validationProblem: IClientValidationProblem) => any[];
    problemFilterPredicate: (validationProblem: IClientValidationProblem) => boolean;
    validationProblemInfoRenderer: (validationProblem: IClientValidationProblem) => React.ReactNode;

    highlightedValidationProblem?: IClientValidationProblem;
    setHighlightedValidationProblem?: (id: IClientValidationProblem) => void;
    highlightableRuleIds?: string[];

    selectedDiagnosisForDetail: DiagnosisStore;
    onSelectedDiagnosisForDetailChange: (newValue: DiagnosisStore) => void;
    initializeExtensionData: () => void;
    extensionController: ExtensionController;
    saveAsync: () => Promise<void>;
    validateAsync: (dirtyFields?: string[]) => Promise<IClientValidationResult[]>;

    pointOfCareId?: PointOfCareId;
    patientId: PatientId;
    _permissionDenied?: boolean;
    permissionCheckOperationNames?: string;
}


const DiagnosisListPanelView: React.FC<IDiagnosisListPanelViewProps> = (props) => {
    const renderList = () => props.selectedDiagnosisForDetail
        ? (
            <OpenDetailDiagnosisList
                onSelectedDiagnosisForDetailChange={props.onSelectedDiagnosisForDetailChange}
                diagnosisListStore={props.diagnosisListStore}
                selectedDiagnosis={props.selectedDiagnosisForDetail}
                pointOfCareId={props.pointOfCareId}
            />
        )
        : (
            <DiagnosisList
                diagnosisListStore={props.diagnosisListStore}
                forceReleaseLockAndLoadAsync={props.forceReleaseLockAndLoadAsync}
                highlightableRuleIds={props.highlightableRuleIds}
                highlightedValidationProblem={props.highlightedValidationProblem}
                mapValidationProblems={props.mapValidationProblems}
                newDiagnosis={props.newDiagnosis}
                onAddDiagnosis={props.onAddDiagnosis}
                onDeleteDiagnosisAsync={props.onDeleteDiagnosisAsync}
                problemFilterPredicate={props.problemFilterPredicate}
                readonly={props.readonly}
                selectedDiagnosis={props.selectedDiagnosis}
                setHighlightedValidationProblem={props.setHighlightedValidationProblem}
                setSelectedDiagnosis={props.setSelectedDiagnosis}
                validationProblemInfoRenderer={props.validationProblemInfoRenderer}
                onSelectedDiagnosisForDetailChange={props.onSelectedDiagnosisForDetailChange}
                selectedDiagnosisForDetail={props.selectedDiagnosisForDetail}
                pointOfCareId={props.pointOfCareId}
            />
        );

    const renderToolbar = () => {
        return (
            <Flex verticalSpacing={0}>
                {!!props.readonly && props.canAcquireLock &&
                    <Flex.Item>
                        <LockIndicatorComponent
                            buttonSize="compact"
                            permissionCheckOperationNames="Save"
                            locked={true}
                            onEditClickedAsync={props.forceReleaseLockAndLoadAsync}
                            lockedBy={props.diagnosisListStore?.lockInfo?.preventingLockUserId}
                            preventingLockId={props.diagnosisListStore?.lockInfo?.preventingLockId} />
                    </Flex.Item>
                }
                {props.onNavigateBack && <Flex.Item>
                    <Button text={StaticWebAppResources.Common.Button.Back} onClick={props.onNavigateBack} automationId="__cancel" />
                </Flex.Item>}
                {!props.readonly && props.diagnosisListStore?.isMutable && (
                    <Flex.Item>
                        <SaveButton automationId="diagnosisListStandAloneSaveButton" onClickAsync={props.saveAsync} />
                    </Flex.Item>
                )}
            </Flex>
        );
    };

    const close = useCallback(() => {
        props.onSelectedDiagnosisForDetailChange(null);
    }, [props.onSelectedDiagnosisForDetailChange]);

    return (
        <MasterDetailScreen
            title={StaticCareResources.OutpatientWorkflow.RecordedDiagnosesStep.Title}
            iconName="editDiagnosis"
            toolbar={!props.selectedDiagnosisForDetail && renderToolbar()}
            legacyIsDetailOpen={!!props.selectedDiagnosisForDetail}
            legacyDetailContent={!!props.selectedDiagnosisForDetail && (
                <DiagnosisDetail
                    diagnosisStore={props.selectedDiagnosisForDetail}
                    initializeExtensionData={props.initializeExtensionData}
                    careActivityId={props.diagnosisListStore?.id}
                    extensionController={props.extensionController}
                    readonly={props.readonly || props._permissionDenied}
                    saveAsync={props.saveAsync}
                    diagnosisListStore={props.diagnosisListStore}
                    validateAsync={props.validateAsync}
                    pointOfCareId={props.pointOfCareId}
                    patientId={props.patientId}
                    onClose={close}
                />
            )}
        >
            {renderList()}
        </MasterDetailScreen>
    );
};

export default connect(
    State.observer(DiagnosisListPanelView),
    new PermissionCheckContextAdapter()
);
