import DependencyContainer from "@DiContainer";
import HunFinanceApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/HunFinanceApiAdapter";
import HunFinanceExtensibleEnumAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/FinanceExtensibleEnumAdapter";
import * as FinanceProxy from "@HunSocialSecurityPlugin/BoundedContexts/Finance/Api/Proxy.g";
import { GetTypeNameWithPrefix } from "@HunSocialSecurityPlugin/PluginHelper";

DependencyContainer
    .bind("HunFinanceApiAdapter")
    .to(HunFinanceApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("HunFinanceExtensibleEnumAdapter")
    .to(HunFinanceExtensibleEnumAdapter)
    .inSingletonScope();

DependencyContainer
    .bind(GetTypeNameWithPrefix("IFinanceClient"))
    .to(FinanceProxy.FinanceClient)
    .inSingletonScope();

DependencyContainer
    .bind(GetTypeNameWithPrefix("IActionsClient"))
    .to(FinanceProxy.ActionsClient)
    .inSingletonScope();
