import React, { useEffect } from "react";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IHisUseCaseIdentifierSetterDependencies {
    applicationContext: ApplicationContext;
}

interface IHisUseCaseIdentifierSetterProps {
    _dependencies?: IHisUseCaseIdentifierSetterDependencies;
    value: string;
}


class HisUseCaseIdentifierSetter extends React.PureComponent<IHisUseCaseIdentifierSetterProps> {

    private get applicationContext() { return this.props._dependencies.applicationContext; }

    @State.action.bound
    private setUseCaseIdentifier(useCaseIdentifierValue: string) {
        if (this.applicationContext) {
            const useCaseIdentifier = new UseCaseIdentifier(useCaseIdentifierValue);
            this.applicationContext.setCurrentUseCase(useCaseIdentifier);
        }
    }

    public componentDidMount() {
        this.setUseCaseIdentifier(this.props.value);
    }

    public render() {
        return (
            <></>
        );
    }
}

export default connect(
    HisUseCaseIdentifierSetter,
    new DependencyAdapter<IHisUseCaseIdentifierSetterProps, IHisUseCaseIdentifierSetterDependencies>( c => ({
        applicationContext: c.resolve("ApplicationContext")
    }))
);