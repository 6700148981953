import React from "react";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import IWorklistRow from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/IWorklistRow";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import moment from "moment";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import Localized from "@CommonControls/Localized/Localized";
import { iconNameType } from "@CommonControls/Icon";

interface INDataPanelViewProps {
    isLoading: boolean;

    masterDetailSelectedId: string;
    onMasterDetailSelectedItemChange: (selected: IWorklistRow) => void;

    masterTitle: string;
    lastUpdatedAt: moment.Moment;
    masterContent: React.ReactNode;
    masterToolbar: React.ReactNode;
    masterSubtitle: React.ReactNode;

    detailTitle?: string;
    detailContent: React.ReactNode;
    disableDetailStrictMode?: boolean;

    iconName?: iconNameType;
    hasDetailFooter: boolean;

    hasTabs?: boolean;
    onRenderList?: (content: React.ReactNode) => React.ReactNode;
    hideMasterHeader?: boolean;
    hideDetailHeader?: boolean;
    isCompact?: boolean;
    compactListSize?: number | string;
    noInnerSpacing?: boolean;
}

@State.observer
export default class NDataPanelView extends React.Component<INDataPanelViewProps> {
    private renderMasterSubtitle(): React.ReactNode {
        if (this.props.masterSubtitle) {
            return this.props.masterSubtitle;
        }

        return (
            <>
                {StaticWebAppResources.Worklist.Label.LastUpdated}: <Localized.DateTime value={this.props.lastUpdatedAt} useTodayString={!!this.props.masterDetailSelectedId} />
            </>
        );
    }

    public render() {
        return (
            <MasterDetailLayout
                isLoading={this.props.isLoading}
                selectedItem={this.props.masterDetailSelectedId}
                onSelectedItemChange={this.props.onMasterDetailSelectedItemChange}
                hasTabs={this.props.hasTabs}
                isCompact={this.props.isCompact}
                compactListSize={this.props.compactListSize}
                hideDetailHeader={this.props.hideDetailHeader}
                noInnerSpacing={this.props.noInnerSpacing}
                master={(
                    <MasterDetail.Master
                        title={this.props.masterTitle}
                        onRenderList={this.props.onRenderList}
                        subTitle={this.renderMasterSubtitle()}
                        hideHeader={this.props.hideMasterHeader}
                        toolbar={this.props.masterToolbar}
                        iconName={this.props.iconName}
                    >
                        {this.props.masterContent}
                    </MasterDetail.Master>
                )}
                detail={this.props.disableDetailStrictMode ? this.props.detailContent : (
                    <MasterDetail.Detail
                        title={this.props.detailTitle}
                        hasFooter={this.props.hasDetailFooter}>
                        {this.props.detailContent}
                    </MasterDetail.Detail>
                )}
            />
        );
    }
}
