import "reflect-metadata";
import "@Toolkit/CommonWeb/Extensions/StringExtensions";
import "@Toolkit/CommonWeb/Extensions/ArrayExtensions";
import "@Toolkit/CommonWeb/Extensions/MapExtensions";
import "@Toolkit/CommonWeb/Extensions/BlobExtensions";

import "@GlobalStyles/normalize.global.css";
import "@GlobalStyles/fonts/fonts.global.css";
import "@GlobalStyles/root.less";

import moment from "moment";
import React from "react";
import ReactDOM from "react-dom";

import DependencyContainer from "@DiContainer";

import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import ContainerService from "@Toolkit/CommonWeb/DependencyInjection/Implementation/Inversify/ContainerService";

const containerService = new ContainerService();
DependencyContainer
    .bind("IContainerService")
    .toConstantValue(containerService);

import "@HisPlatform/DependencyConfiguration/ApiClientConfiguration";
import "@HisPlatform/DependencyConfiguration/CommonConfiguration";
import "@HisPlatform/DependencyConfiguration/PageStoreConfiguration";
import "@HisPlatform/DependencyConfiguration/HisPlatformControlsConfiguration";
import "@HisPlatform/DependencyConfiguration/ClientDomainModelConfiguration";
import "@HisPlatform/DependencyConfiguration/FormLogicConfiguration";

import "@HisPlatform/BoundedContexts/Localization/DependencyConfiguration/LocalizationDependencyConfiguration";
import "@HisPlatform/BoundedContexts/Care/DependencyConfiguration/CareDependencyConfiguration";
import "@HisPlatform/BoundedContexts/UserManagement/DependencyConfiguration/UserManagementDependencyConfiguration";
import "@HisPlatform/BoundedContexts/Configuration/DependencyConfiguration/ConfigurationDependencyConfiguration";
import "@HisPlatform/BoundedContexts/Organization/DependencyConfiguration/OrganizationDependencyConfiguration";
import "@HisPlatform/BoundedContexts/Authorization/DependencyConfiguration/AuthorizationDependencyConfiguration";
import "@HisPlatform/BoundedContexts/DocumentManagement/DependencyConfiguration/DocumentManagementDependencyConfiguration";
import "@HisPlatform/BoundedContexts/TokenBasedDataGathering/DependencyConfiguration/TokenBasedDataGatheringDependencyConfiguration";
import "@HisPlatform/BoundedContexts/Locking/DependencyConfiguration/LockingDependencyConfiguration";
import "@HisPlatform/BoundedContexts/CommonReferenceData/DependencyConfiguration/CommonReferenceDataDependencyConfiguration";
import "@HisPlatform/BoundedContexts/Finance/DependencyConfiguration/FinanceDependencyConfiguration";
import "@HisPlatform/BoundedContexts/Dashboards/DependencyConfiguration/DashboardsDependencyConfiguration";
import "@HisPlatform/BoundedContexts/Reporting/DependencyConfiguration/ReportingDependencyConfiguration";
import "@HisPlatform/BoundedContexts/Productivity/DependencyConfiguration/ProductivityConfiguration";
import "@HisPlatform/BoundedContexts/Scheduling/DependencyConfiguration/SchedulingDependencyConfiguration";
import "@HisPlatform/BoundedContexts/FormEngine/DependencyConfiguration/FormEngineDependencyConfiguration";
import "@HisPlatform/BoundedContexts/Surgery/DependencyConfiguration/SurgeryDependencyConfiguration";
import "@HisPlatform/BoundedContexts/Diagnostics/DependencyConfiguration/DiagnosticsDependencyConfiguration";
import "@HisPlatform/BoundedContexts/WebAppBackend/DependencyConfiguration/WebAppBackendDependencyConfiguration";

import Application from "@HisPlatform/Application/Application";

import IClientSessionService from "@HisPlatform/Services/Definition/ClientSession/IClientSessionService";
import ApplicationWrapper from "@Toolkit/ReactClient/Components/ApplicationWrapper/ApplicationWrapper";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import HuDateTimeFormatConfiguration from "@HisPlatform/Common/HuDateTimeFormatConfiguration";
import EnDateTimeFormatConfiguration from "@HisPlatform/Common/EnDateTimeFormatConfiguration";
import SkDateTimeFormatConfiguration from "@HisPlatform/Common/SkDateTimeFormatConfiguration";
import config from "@Config";
import { loadStaticWebAppResources } from "@HisPlatform/StaticResources/StaticWebAppResources";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import { loadStaticCareResources } from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import IResourceFetcher from "@Toolkit/CommonWeb/Abstractions/Localization/IResourceFetcher";
import Log from "@Log";
import TraceLogger from "@HisPlatform/Common/TraceLogger";
import { loadStaticAuthorizationResources } from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";
import { loadStaticUserManagementResources } from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import HostRoutingAdapter from "@Toolkit/ReactClient/Routing/HostRoutingAdapter";
import IHostRoutingAdapter from "@Toolkit/ReactClient/Routing/Abstractions/IHostRoutingAdapter";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import IHeaderProvider from "@Toolkit/CommonWeb/Abstractions/HttpRequests/HeaderProvider/IHeaderProvider";
import { loadStaticFinanceResources } from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import HunSocialSecurityPlugin from "./HunSocialSecurityPlugin/HunSocialSecurityPlugin";
import IExtensionComponentRegistry from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import { ExtensionPointType } from "@PluginInterface/ExtensionPoint/ExtensionPointType";
import { loadStaticConfigurationResources } from "@HisPlatform/BoundedContexts/Configuration/StaticResources/StaticConfigurationResources";
import { loadStaticOrganizationResources } from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import configureHisPlatformUseCases from "@HisPlatform/Configuration/UseCases/UseCaseConfiguration";
import HunEHealthInfrastructurePlugin from "./HunEHealthInfrastructurePlugin/HunEHealthInfrastructurePlugin";
import { loadStaticProductivityResources } from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import IWidgetRegistry from "@PluginInterface/Dashboard/IWidgetRegistry";
import configureHisPlatformWidgets from "./HisPlatform/Configuration/Widgets/WidgetConfiguration";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ICardioIntegrationPlugin from "./ICardioIntegrationPlugin/ICardioIntegrationPlugin";
import IPatientDocumentFormattingRegistry from "@PluginInterface/PatientDocumentFormatting/IPatientDocumentFormattingRegistry";
import { loadStaticToolkitResources } from "@Toolkit/ReactClient/Services/Definition/LocalizationService/StaticToolkitResources";
import ModalServiceRegistry from "@Toolkit/ReactClient/Components/ModalService/ModalServiceRegistry";
import configureHisPlatformModals from "./HisPlatform/Configuration/Modals/ModalConfiguration";
import { configureValidationProblemMapping, configureMapper } from "./HisPlatform/Configuration/Mapping/MappingConfiguration";
import { loadStaticDocumentManagementResources } from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import ITokenSettingsPanelRegistry from "@PluginInterface/BoundedContexts/DocumentManagement/TokenSettingsPanelRegistry/ITokenSettingsPanelRegistry";
import { configureHisPlatformTemplateTokenFormatterSettingsPanels } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TokenSettingsPanels/TokenSettingsPanelConfiguration";
import { loadStaticSchedulingResources } from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import IFormExtensionRegistry from "@PluginInterface/FormExtension/IFormExtensionRegistry";
import IStateChangeBlockingRegistry from "@PluginInterface/BoundedContexts/Care/CareRegister/StateChange/IStateChangeBlockingRegistry";
import configureStateChangeBlockings from "./HisPlatform/Configuration/StateChangeBlocking/ConfigureStateChangeBlockings";
import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import configureHisPlatformPermissionDeifinitions from "./HisPlatform/Configuration/PermissionDefinitions/PermissionDefinitionsConfiguration";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import { configureHisPlatformActivities } from "./HisPlatform/Configuration/UseCases/ActivityConfiguration";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import { configureHisPlatformNDataValueConverters } from "./HisPlatform/Configuration/NData/ValueConverters/HisPlatformNDataValueConverterConfiguration";
import { configureHisPlatformNDataColumns } from "./HisPlatform/Configuration/NData/Columns/HisPlatformNDataColumnConfiguration";
import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import AlwaysVisibleExtensionComponentService from "@PluginInterface/ExtensionPoint/AlwaysVisibleExtensionComponentService";
import AppStatusStore from "@Toolkit/CommonWeb/AppStatusStore";
import DocumentManagementReferenceDataStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentManagementReferenceDataStore";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import { configureFormEngineCommonEditors, configureFormEngineModals } from "@Toolkit/FormEngine/Configuration/FormEngineCommonConfiguration";
import { loadStaticFormEngineResources } from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import ConfigurationStatusApiAdapter from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/ApiAdapter/Status/ConfigurationStatusApiAdapter";
import ConsoleLogger from "@HisPlatform/Services/Implementation/ConsoleLogger/ConsoleLogger";
import ILogger, { fullLoggingConfiguration } from "@HisPlatform/Services/Definition/Logger/ILogger";
import WebWorkerLogger from "@HisPlatform/Services/Implementation/WebWorkerLogger/WebWorkerLogger";
import { loadStaticSurgeryResources } from "@HisPlatform/BoundedContexts/Surgery/StaticResources/StaticSurgeryResources";
import IPlatformPlugin from "@PluginInterface/IPlatformPlugin";
import "@HisPlatform/BoundedContexts/WebAppBackend/WebAppBackendBoundedContext";
import GlobalActionRegistry from "@Toolkit/ReactClient/ActionProcessing/GlobalActionRegistry";
import IScreenRegistry from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { registerLicense } from "@syncfusion/ej2-base";
import { configureFormEngineSerializer, configureFormEngineDeserializer } from "@Toolkit/FormEngine/Configuration/FormEngineMappers";
import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import CarePackageInitializer from "@HisPlatform/Packages/Care/Configuration/CarePackageInitializer";
import PatientsPackageInitializer from "@HisPlatform/Packages/Patients/Configuration/PatientsPackageInitializer";
import UnknownFrontendAction from "@Toolkit/ReactClient/ActionProcessing/UnknownFrontendAction";
import ServiceRequestPackageInitializer from "@HisPlatform/Packages/ServiceRequest/Configuration/ServiceRequestPackageInitializer";
import IFormLogicRegistry from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";
import AssecoRoomsPlugin from "@AssecoRoomsPlugin/AssecoRoomsPlugin";
import HunSocialSecurityPerformanceStatementPackageInitializer from "@HunSocialSecurityPlugin/Packages/PerformanceStatement/Configuration/PerformanceStatementPackageInitializer";
import HunSocialSecurityCarePackageInitializer from "@HunSocialSecurityPlugin/Packages/Care/Configuration/CarePackageInitializer";
import HunSocialSecuritySettingsPackageInitializer from "@HunSocialSecurityPlugin/Packages/Settings/Configuration/SettingsPackageInitializer";
import HunEHealthInfrastructureMedicationRequestPackageInitializer from "@HunEHealthInfrastructurePlugin/Packages/MedicationRequest/Configuration/MedicationRequestPackageInitializer";
import HunEHealthInfrastructureSettingsPackageInitializer from "@HunEHealthInfrastructurePlugin/Packages/Settings/Configuration/SettingsPackageInitializer";
import HunEHealthInfrastructureUserManagementPackageInitializer from "@HunEHealthInfrastructurePlugin/Packages/UserManagement/Configuration/UserManagementPackageInitializer";
import HunEHealthInfrastructureCarePackageInitializer from "@HunEHealthInfrastructurePlugin/Packages/Care/Configuration/CarePackageInitializer";
import { loadStaticTelecommunicationResources } from "@HisPlatform/BoundedContexts/Telecommunication/StaticResources/StaticTelecommunicationResources";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import SettingsPackageInitializer from "@HisPlatform/Packages/Settings/Configuration/SettingsPackageInitializer";
import SchedulingPackageInitializer from "@HisPlatform/Packages/Scheduling/Configuration/SchedulingPackageInitializer";
import AuthorizationPackageInitializer from "@HisPlatform/Packages/Authorization/Configuration/AuthorizationPackageInitializer";
import OrganizationPackageInitializer from "@HisPlatform/Packages/Organization/Configuration/OrganizationPackageInitializer";
import UserManagementPackageInitializer from "@HisPlatform/Packages/UserManagement/Configuration/UserManagementPackageInitializer";
import IRoutingFrameCaseRegistry from "@HisPlatform/Services/Definition/RoutingFrameCaseRegistry/IRoutingFrameCaseRegistry";
import GuidancePackageInitializer from "@HisPlatform/Packages/Guidance/Configuration/GuidancePackageInitializer";
import { panelStoreProviderGlobalSettings } from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import { useHisActionDispatcher } from "@HisPlatform/Common/FrontendActions/HisActionDispatcher";
import MedioPlugin from "@MedioPlugin/MedioPlugin";
import AssecoMedPlugin from "@AssecoMedPlugin/AssecoMedPlugin";
import LoggingConfigurationApiAdapter from "@HisPlatform/Application/LoggingConfigurationApiAdapter";
import ActionModelPackageInitializer from "@HisPlatform/Packages/ActionModel/Configuration/ActionModelPackageInitializer";
import AssecoMedCarePackageInitializer from "@AssecoMedPlugin/Packages/Care/Configuration/CarePackageInitializer";
import IAuthenticationService from "@HisPlatform/Services/Definition/Authentication/IAuthenticationService";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import FinancePackageInitializer from "@HisPlatform/Packages/Finance/Configuration/FinancePackageInitializer";

const hostRoutingAdapter = HostRoutingAdapter.createBrowserHistoryRoutingAdapter();

DependencyContainer
    .bind("IHostRoutingAdapter")
    .toConstantValue(hostRoutingAdapter);

DependencyContainer
    .bind("GlobalRoutingStore")
    .to(GlobalRoutingStore)
    .inSingletonScope();

State.configureStrictMode();

export default function hisPlatformInit() {

    if (config.syncfusion?.licenseKey) {
        registerLicense(config.syncfusion.licenseKey);
    }

    if (config.logTarget === "Server") {
        DependencyContainer
            .bind("ILogger")
            .to(WebWorkerLogger)
            .inSingletonScope();
    } else {
        DependencyContainer
            .bind("ILogger")
            .to(ConsoleLogger)
            .inSingletonScope();
    }

    if (TraceLogger.isEnabled()) {

        Log.configure(fullLoggingConfiguration);

        global["tracer"] = new TraceLogger(
            Log.logEvent,
            hostRoutingAdapter,
            sessionStorage
        );
        Log.trace("Starting up application");
    }

    Object.defineProperty(window, "appStatus", {
        get: () => !AppStatusStore.instance.isLoading()
    });

    panelStoreProviderGlobalSettings.useActionDispatcherHook = useHisActionDispatcher;

    const applicationContext = DependencyContainer.get<ApplicationContext>("ApplicationContext");
    const userContext = DependencyContainer.get<UserContext>("UserContext");
    const configurationStatusApiAdapter = DependencyContainer.get<ConfigurationStatusApiAdapter>("ConfigurationStatusApiAdapter");

    const startupAsync = async () => {

        if (!TraceLogger.isEnabled()) {
            const loggingConfiguration = await new LoggingConfigurationApiAdapter().getLoggingConfigurationAsync();
            if (loggingConfiguration != null) {
                Log.configure(loggingConfiguration);
            }
        }

        const currentDateTime = await configurationStatusApiAdapter.getCurrentDateTimeAsync();
        DateTimeService.synchronize(currentDateTime.value);

        const clientSessionService = DependencyContainer.get<IClientSessionService>("IClientSessionService");
        await clientSessionService.initializeAsync();

        await userContext.initializeAsync();

        const resourceFetcher = DependencyContainer.get<IResourceFetcher>("IResourceFetcher");

        const dateTimeFormatProvider = DependencyContainer.get<IDateTimeFormatProvider>("IDateTimeFormatProvider");

        if (applicationContext.cultureCode === "hu-HU") {
            dateTimeFormatProvider.loadConfiguration(HuDateTimeFormatConfiguration);
        } else if (applicationContext.cultureCode === "sk-SK" || applicationContext.cultureCode === "cs-CZ") {
            dateTimeFormatProvider.loadConfiguration(SkDateTimeFormatConfiguration);
        } else {
            dateTimeFormatProvider.loadConfiguration(EnDateTimeFormatConfiguration);
        }

        await resourceFetcher.fetchResourceGroupAsync("WebApp", "WebApp", applicationContext.cultureCode);
        await resourceFetcher.fetchResourceGroupAsync("ReferenceData", "ReferenceData", applicationContext.cultureCode);
        await resourceFetcher.fetchResourceGroupAsync("ValidationMessages", "ValidationMessages", applicationContext.cultureCode);
        await resourceFetcher.fetchResourceGroupAsync("DynamicProperty", "DynamicProperty", applicationContext.cultureCode);

        loadStaticWebAppResources();
        loadStaticCareResources();
        loadStaticDocumentManagementResources();
        loadStaticSchedulingResources();
        loadStaticAuthorizationResources();
        loadStaticUserManagementResources();
        loadStaticFinanceResources();
        loadStaticConfigurationResources();
        loadStaticOrganizationResources();
        loadStaticProductivityResources();
        loadStaticToolkitResources();
        loadStaticFormEngineResources();
        loadStaticSurgeryResources();
        loadStaticTelecommunicationResources();

        moment.locale(applicationContext.cultureCode);

        (window as any).headerProvider = DependencyContainer.get<IHeaderProvider>("IHeaderProvider");

        (window as any).application.hideLoader();

        // Initialize UseCaseRegistry
        const useCaseRegistry: IUseCaseRegistry = DependencyContainer.get("IUseCaseRegistry");
        configureHisPlatformUseCases(useCaseRegistry);

        // Initialize ActivityRegistry
        const activityRegistry: IActivityRegistry = DependencyContainer.get("IActivityRegistry");
        configureHisPlatformActivities(activityRegistry);

        // Initialize WidgetRegistry
        const widgetRegistry: IWidgetRegistry = DependencyContainer.get("IWidgetRegistry");
        configureHisPlatformWidgets(widgetRegistry);

        // Initialize StateChangeBlockingRegistry
        const stateChangeBlockingRegistry: IStateChangeBlockingRegistry = DependencyContainer.get("IStateChangeBlockingRegistry");
        const documentManagementReferenceDataStore: DocumentManagementReferenceDataStore = DependencyContainer.get("DocumentManagementReferenceDataStore");
        configureStateChangeBlockings(stateChangeBlockingRegistry, documentManagementReferenceDataStore);

        // Initialize PatientDocumentFormattingRegistry
        const patientDocumentFormattingRegistry: IPatientDocumentFormattingRegistry = DependencyContainer.get("IPatientDocumentFormattingRegistry");

        // Initialize ModalServiceRegistry
        const modalServiceRegistry: ModalServiceRegistry = DependencyContainer.get("ModalServiceRegistry");
        configureHisPlatformModals(modalServiceRegistry);

        // Initialize Template
        const tokenSettingsPanelRegistry: ITokenSettingsPanelRegistry = DependencyContainer.get("ITokenSettingsPanelRegistry");
        configureHisPlatformTemplateTokenFormatterSettingsPanels(tokenSettingsPanelRegistry);

        // Initialize PermissionDefinitionRegistry
        const permissionDefinitionRegistry: IPermissionDefinitionRegistry = DependencyContainer.get("IPermissionDefinitionRegistry");
        configureHisPlatformPermissionDeifinitions(permissionDefinitionRegistry);

        // Mapping config
        const validationProblemMapperService = DependencyContainer.get<ValidationProblemParameterMapperService>("ValidationProblemParameterMapperService");
        configureValidationProblemMapping(DependencyContainer);

        // Mapping config TODO: can be merged with ValidationProblemParameterMapperService?
        const mapperConfigurator = DependencyContainer.get<IMapperConfigurator>("IMapperConfigurator");
        configureMapper(mapperConfigurator);

        // Initialize form extensions
        const formExtensionRegistry: IFormExtensionRegistry = DependencyContainer.get("IFormExtensionRegistry");

        // Initialize form logics
        const formLogicsRegistry: IFormLogicRegistry = DependencyContainer.get("IFormLogicRegistry");

        // Initialize routing frame use cases
        const routingFrameCaseRegistry: IRoutingFrameCaseRegistry = DependencyContainer.get("IRoutingFrameCaseRegistry");

        // ConfigureNData
        const nDataConfigurator: INDataAttributeConfigurator = DependencyContainer.get("INDataAttributeConfigurator");
        configureHisPlatformNDataValueConverters(nDataConfigurator);
        configureHisPlatformNDataColumns(nDataConfigurator);

        // Configure FormEngine
        const formEngineEditorRegistry: FormEditorRegistry = DependencyContainer.get("FormEditorRegistry");
        configureFormEngineCommonEditors(formEngineEditorRegistry);
        configureFormEngineSerializer(mapperConfigurator);
        configureFormEngineDeserializer(mapperConfigurator);
        configureFormEngineModals(modalServiceRegistry);

        const referenceDataLoader: IReferenceDataLoader = DependencyContainer.get("IReferenceDataLoader");

        const globalActionRegistry = DependencyContainer.get<GlobalActionRegistry>("GlobalActionRegistry");
        globalActionRegistry.register(UnknownFrontendAction.id, null);
        const screenRegistry = DependencyContainer.get<IScreenRegistry>("IScreenRegistry");

        const navigationRegistry = DependencyContainer.get<IDynamicNavigationRegistry>("IDynamicNavigationRegistry");

        const contextInitializers = DependencyContainer.getAll<IBoundedContext>("IBoundedContext");
        for (const contextInitializer of contextInitializers) {
            await contextInitializer.initializeAsync();

            contextInitializer.configureUseCases?.(useCaseRegistry);
            contextInitializer.configureActivities?.(activityRegistry);
            contextInitializer.configureWidgets?.(widgetRegistry);
            contextInitializer.configureModals?.(modalServiceRegistry);
            contextInitializer.configurePermissionDefinitions?.(permissionDefinitionRegistry);
            contextInitializer.configureValidationProblemParameterMappers?.(validationProblemMapperService);
            contextInitializer.configureFormExtensions?.(formExtensionRegistry);
            contextInitializer.configureNData?.(nDataConfigurator);
            contextInitializer.configureReferenceDataLoaders?.(referenceDataLoader);
            contextInitializer.configureMapper?.(mapperConfigurator);
            contextInitializer.configureFormEngine?.(formEngineEditorRegistry);
            contextInitializer.configureActionProcessor?.(globalActionRegistry);
            contextInitializer.configureScreens?.(screenRegistry);
            contextInitializer.configureFormLogics?.(formLogicsRegistry);
            contextInitializer.configureDynamicNavigation?.(navigationRegistry);
        }

        const packages: IPackageInitializer[] = [
            CarePackageInitializer,
            FinancePackageInitializer,
            PatientsPackageInitializer,
            ServiceRequestPackageInitializer,
            SettingsPackageInitializer,
            SchedulingPackageInitializer,
            AuthorizationPackageInitializer,
            OrganizationPackageInitializer,
            UserManagementPackageInitializer,
            GuidancePackageInitializer,
            ActionModelPackageInitializer,
            // never add plugins directly to this list
        ];

        for (const pluginName of config.plugins) {
            switch (pluginName) {
                case "HunSocialSecurity":
                    packages.push(HunSocialSecurityCarePackageInitializer);
                    packages.push(HunSocialSecurityPerformanceStatementPackageInitializer);
                    packages.push(HunSocialSecuritySettingsPackageInitializer);
                    break;
                case "HunEHealthInfrastructure":
                    packages.push(HunEHealthInfrastructureCarePackageInitializer);
                    packages.push(HunEHealthInfrastructureSettingsPackageInitializer);
                    packages.push(HunEHealthInfrastructureUserManagementPackageInitializer);
                    packages.push(HunEHealthInfrastructureMedicationRequestPackageInitializer);
                    break;
                case "AssecoMedPlugin":
                    packages.push(AssecoMedCarePackageInitializer);
                    break;
            }
        }

        // Initialize plugins
        if (config.plugins) {
            DependencyContainer.bind("AlwaysVisibleExtensionComponentService")
                .to(AlwaysVisibleExtensionComponentService);

            const extensionComponentRegistry: IExtensionComponentRegistry<ExtensionPointType> = DependencyContainer.get("IExtensionComponentRegistry");

            for (const pluginName of config.plugins) {
                switch (pluginName) {
                    case "HunSocialSecurity":
                        await HunSocialSecurityPlugin.configureAsync(DependencyContainer);
                        HunSocialSecurityPlugin.configureExtensionComponents(extensionComponentRegistry);
                        HunSocialSecurityPlugin.configureUseCases(useCaseRegistry);
                        HunSocialSecurityPlugin.configurePatientDocumentFormattings(patientDocumentFormattingRegistry);
                        HunSocialSecurityPlugin.configureFormExtensions(formExtensionRegistry);
                        HunSocialSecurityPlugin.configureStateChangeBlockings(stateChangeBlockingRegistry);
                        HunSocialSecurityPlugin.configurePermissionDefinitions(permissionDefinitionRegistry);
                        HunSocialSecurityPlugin.configureActivities(activityRegistry);
                        HunSocialSecurityPlugin.configureNData(nDataConfigurator);
                        HunSocialSecurityPlugin.configureMapper(mapperConfigurator);
                        (HunSocialSecurityPlugin as IPlatformPlugin).configureFormEngine(formEngineEditorRegistry);
                        (HunSocialSecurityPlugin as IPlatformPlugin).configureFormLogics?.(formLogicsRegistry);
                        (HunSocialSecurityPlugin as IPlatformPlugin).configureWidgets?.(widgetRegistry);
                        (HunSocialSecurityPlugin as IPlatformPlugin).configureRoutingFrameCases?.(routingFrameCaseRegistry);
                        break;
                    case "HunEHealthInfrastructure":
                        await HunEHealthInfrastructurePlugin.configureAsync(DependencyContainer);
                        HunEHealthInfrastructurePlugin.configureExtensionComponents(extensionComponentRegistry);
                        HunEHealthInfrastructurePlugin.configureUseCases(useCaseRegistry);
                        HunEHealthInfrastructurePlugin.configureModals(modalServiceRegistry);
                        HunEHealthInfrastructurePlugin.configureFormExtensions(formExtensionRegistry);
                        HunEHealthInfrastructurePlugin.configureStateChangeBlockings(stateChangeBlockingRegistry);
                        HunEHealthInfrastructurePlugin.configurePermissionDefinitions(permissionDefinitionRegistry);
                        HunEHealthInfrastructurePlugin.configureActivities(activityRegistry);
                        HunEHealthInfrastructurePlugin.configureNData(nDataConfigurator);
                        HunEHealthInfrastructurePlugin.configureModals(modalServiceRegistry);
                        HunEHealthInfrastructurePlugin.configureMapper(mapperConfigurator);
                        (HunEHealthInfrastructurePlugin as IPlatformPlugin).configureFormEngine(formEngineEditorRegistry);
                        (HunEHealthInfrastructurePlugin as IPlatformPlugin).configureFormLogics?.(formLogicsRegistry);
                        (HunEHealthInfrastructurePlugin as IPlatformPlugin).configureWidgets?.(widgetRegistry);
                        (HunEHealthInfrastructurePlugin as IPlatformPlugin).configureRoutingFrameCases?.(routingFrameCaseRegistry);
                        break;
                    case "ICardioIntegrationPlugin":
                        await ICardioIntegrationPlugin.configureAsync(DependencyContainer);
                        ICardioIntegrationPlugin.configureExtensionComponents(extensionComponentRegistry);
                        ICardioIntegrationPlugin.configureUseCases(useCaseRegistry);
                        ICardioIntegrationPlugin.configureFormExtensions(formExtensionRegistry);
                        ICardioIntegrationPlugin.configurePermissionDefinitions(permissionDefinitionRegistry);
                        ICardioIntegrationPlugin.configureActivities(activityRegistry);
                        ICardioIntegrationPlugin.configureNData(nDataConfigurator);
                        ICardioIntegrationPlugin.configureMapper(mapperConfigurator);
                        (ICardioIntegrationPlugin as IPlatformPlugin).configureFormEngine(formEngineEditorRegistry);
                        (ICardioIntegrationPlugin as IPlatformPlugin).configureFormLogics?.(formLogicsRegistry);
                        (ICardioIntegrationPlugin as IPlatformPlugin).configureWidgets?.(widgetRegistry);
                        (ICardioIntegrationPlugin as IPlatformPlugin).configureRoutingFrameCases?.(routingFrameCaseRegistry);
                        break;
                    case "AssecoRoomsPlugin":
                        await AssecoRoomsPlugin.configureAsync(DependencyContainer);
                        AssecoRoomsPlugin.configureExtensionComponents(extensionComponentRegistry);
                        AssecoRoomsPlugin.configureUseCases(useCaseRegistry);
                        AssecoRoomsPlugin.configureFormExtensions(formExtensionRegistry);
                        AssecoRoomsPlugin.configurePermissionDefinitions(permissionDefinitionRegistry);
                        AssecoRoomsPlugin.configureActivities(activityRegistry);
                        AssecoRoomsPlugin.configureNData(nDataConfigurator);
                        AssecoRoomsPlugin.configureMapper(mapperConfigurator);
                        (AssecoRoomsPlugin as IPlatformPlugin).configureFormEngine(formEngineEditorRegistry);
                        (AssecoRoomsPlugin as IPlatformPlugin).configureWidgets?.(widgetRegistry);
                        (AssecoRoomsPlugin as IPlatformPlugin).configureRoutingFrameCases?.(routingFrameCaseRegistry);
                        break;
                    case "MedioPlugin":
                        await MedioPlugin.configureAsync(DependencyContainer);
                        MedioPlugin.configureExtensionComponents(extensionComponentRegistry);
                        MedioPlugin.configureUseCases(useCaseRegistry);
                        MedioPlugin.configureFormExtensions(formExtensionRegistry);
                        MedioPlugin.configurePermissionDefinitions(permissionDefinitionRegistry);
                        MedioPlugin.configureActivities(activityRegistry);
                        MedioPlugin.configureNData(nDataConfigurator);
                        MedioPlugin.configureMapper(mapperConfigurator);
                        (MedioPlugin as IPlatformPlugin).configureFormEngine(formEngineEditorRegistry);
                        (MedioPlugin as IPlatformPlugin).configureWidgets?.(widgetRegistry);
                        (MedioPlugin as IPlatformPlugin).configureRoutingFrameCases?.(routingFrameCaseRegistry);
                        break;
                    case "AssecoMedPlugin":
                        await AssecoMedPlugin.configureAsync(DependencyContainer);
                        AssecoMedPlugin.configureExtensionComponents(extensionComponentRegistry);
                        AssecoMedPlugin.configureUseCases(useCaseRegistry);
                        AssecoMedPlugin.configureFormExtensions(formExtensionRegistry);
                        AssecoMedPlugin.configurePermissionDefinitions(permissionDefinitionRegistry);
                        AssecoMedPlugin.configureActivities(activityRegistry);
                        AssecoMedPlugin.configureNData(nDataConfigurator);
                        AssecoMedPlugin.configureMapper(mapperConfigurator);
                        (AssecoMedPlugin as IPlatformPlugin).configureFormEngine(formEngineEditorRegistry);
                        (AssecoMedPlugin as IPlatformPlugin).configureFormLogics?.(formLogicsRegistry);
                        (AssecoMedPlugin as IPlatformPlugin).configureWidgets?.(widgetRegistry);
                        (AssecoMedPlugin as IPlatformPlugin).configureRoutingFrameCases?.(routingFrameCaseRegistry);
                        break;
                }
            }
        }

        for (const p of packages) {
            p.configureDependencies?.(DependencyContainer);
            p.configureModals?.(modalServiceRegistry);
            p.configureValidationProblemParameterMappers?.(validationProblemMapperService);
            p.configureNData?.(nDataConfigurator);
            p.configureReferenceDataLoaders?.(referenceDataLoader);
            p.configureMapper?.(mapperConfigurator);
            p.configureFormEngine?.(formEngineEditorRegistry);
            p.configureFormExtensions?.(formExtensionRegistry);
            p.configureActionProcessor?.(globalActionRegistry);
            p.configureScreens?.(screenRegistry);
            p.configureFormLogics?.(formLogicsRegistry);
            p.configureDynamicNavigation?.(navigationRegistry);
            p.configureWidgets?.(widgetRegistry);
            p.configureRoutingFrameCases?.(routingFrameCaseRegistry);
        }

        if (window.location.pathname === "/loginCallback") {
            const authenticationService = DependencyContainer.get<IAuthenticationService>("IAuthenticationService");
            const { returnUrl } = await authenticationService.processAuthenticationCallbackAsync();

            userContext.userLoggedIn();

            setImmediate(() => {
                const routingStore = DependencyContainer.get<GlobalRoutingStore>("GlobalRoutingStore");
                if (returnUrl) {
                    const redirect = decodeURIComponent(returnUrl);
                    routingStore.replaceRaw(redirect);
                } else {
                    routingStore.replace(ApplicationRoutes.home.makeRoute());
                }
            });
        }
    };

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    ReactDOM.render((
        <ApplicationWrapper
            startupAsync={startupAsync}
            developerMode={config.developerMode}
            containerService={containerService}>
            <Application isMobile={isMobile} />
        </ApplicationWrapper>
    ), document.getElementById("app"));

}

requestAnimationFrame(() => {
    hisPlatformInit();
});
