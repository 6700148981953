import Di from "@Di";
import IStateChangeBlockingRegistry from "@PluginInterface/BoundedContexts/Care/CareRegister/StateChange/IStateChangeBlockingRegistry";
import StateChangeBlockingData from "@Primitives/StateChangeBlockingData.g";
import IStatusChangeBlockingReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/IStatusChangeBlockingReason";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import CareActivityId from "@Primitives/CareActivityId.g";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";

@Di.injectable()
export default class StateChangeBlockingRegistry implements IStateChangeBlockingRegistry {
    private statusChangeBlockingReasonMap = new Map<string,
        {
            navigationHandler: (routingController: IRoutingController<any>, actionDispatcher: IActionDispatcher, careActivityId: CareActivityId) => void,
            titleBuilder: (localizationService: ILocalizationService) => string,
            messageBuilder: (localizationService: ILocalizationService, statusChangeBlockingReason: IStatusChangeBlockingReason) => string
        }>();

    public register(
        stateChangeBlockingData: StateChangeBlockingData,
        navigationHandler: (routingController: IRoutingController<any>, actionDispatcher: IActionDispatcher, careActivityId: CareActivityId) => void,
        titleBuilder: (localizationService: ILocalizationService) => string,
        messageBuilder: (localizationService: ILocalizationService, statusChangeBlockingReason: IStatusChangeBlockingReason) => string): void {
        if (this.statusChangeBlockingReasonMap.has(stateChangeBlockingData.value)) {
            throw new Error(`StateChangeBlocking with the same name is already added: '${stateChangeBlockingData.value}'.`);
        }

        this.statusChangeBlockingReasonMap.set(stateChangeBlockingData.value, {
            navigationHandler: navigationHandler,
            titleBuilder: titleBuilder,
            messageBuilder: messageBuilder
        });
    }

    public getTitle(localizationService: ILocalizationService, stateChangeBlockingData: StateChangeBlockingData): string {
        const item = this.statusChangeBlockingReasonMap.get(stateChangeBlockingData.value);
        return item.titleBuilder(localizationService);
    }

    public getMessage(localizationService: ILocalizationService, stateChangeBlockingData: StateChangeBlockingData, reason: IStatusChangeBlockingReason): string {
        const item = this.statusChangeBlockingReasonMap.get(stateChangeBlockingData.value);
        return item.messageBuilder(localizationService, reason);
    }

    public navigateTo(routingController: IRoutingController<any>, actionDispatcher: IActionDispatcher, stateChangeBlockingData: StateChangeBlockingData, careActivityId: CareActivityId): void {
        const item = this.statusChangeBlockingReasonMap.get(stateChangeBlockingData.value);
        item.navigationHandler(routingController, actionDispatcher, careActivityId);
    }
}