import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import IExtensionPointBaseProps from "@HisPlatform/Components/HisPlatformExtensionPoint/IExtensionPointBaseProps";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ICoverageCheckListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CheckCoverageEligibility/ICoverageCheckListItem";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import CoverageCheckListPanelFilterStore from "./CoverageCheckListPanelFilterStore";
import { IPagingState, IOrderingState, DataGridLoadType } from "@CommonControls/DataGrid/IDataGridProps";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import CoverageCheckListPanelView from "./CoverageCheckListPanelView";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";

interface ICoverageCheckListPanelDependencies {
    hunCareApiAdapter: EhiCareApiAdapter;
}

interface ICoverageCheckListPanelProps extends IRoutingFrameContentProps, IExtensionPointBaseProps {
    _dependencies?: ICoverageCheckListPanelDependencies;
}

/** @screen */
@State.observer
class CoverageCheckListPanel extends React.Component<ICoverageCheckListPanelProps> {

    private get apiAdapter() {
        return this.props._dependencies.hunCareApiAdapter;
    }

    @State.observable.ref private results: PagedItemStore<ICoverageCheckListItem> = null;

    private filterStore = new CoverageCheckListPanelFilterStore(this.reload);

    @State.observable.ref private paging: IPagingState = {
        currentPage: 0,
        pageSize: 10
    };

    @State.observable.ref private ordering: IOrderingState = null;

    @State.action.bound
    private setGridState(
        results: PagedItemStore<ICoverageCheckListItem>,
        paging: IPagingState,
        ordering: IOrderingState
    ) {
        this.results = results;
        this.paging = paging;
        this.ordering = ordering;
    }

    @State.bound
    public reload() {
        dispatchAsyncErrors(this.loadDataAsync("changed", this.paging, this.ordering, null, null), this);
    }

    public componentDidMount() {
        this.reload();
    }

    private readonly loadDataAsync = createInitialPanelLoader(this._loadDataAsync, this.apiAdapter.getCoverageEligibilityCheckResultsPermissionCheckAsync);

    @State.bound
    public async _loadDataAsync(
        type: DataGridLoadType,
        paging: IPagingState,
        ordering: IOrderingState | IOrderingState[],
        filter: any,
        columns: IDataGridColumnProps[]
    ) {
        const singleOrdering = (ordering as IOrderingState[])?.length ? ordering[0] : ordering;
        const results = await this.props._dependencies.hunCareApiAdapter.getCoverageEligibilityCheckResultsAsync(
            this.filterStore.checkedAtRange,
            this.filterStore.patientName,
            this.filterStore.patientBirthDate,
            this.filterStore.patientIdentifier,
            this.filterStore.transactionCode,
            this.filterStore.errorCode,
            this.filterStore.coverageCheckResult,
            this.filterStore.pointOfCareIds,
            paging,
            singleOrdering
        );
        this.setGridState(results, paging, singleOrdering);
    }

    public render() {

        if (this.loadDataAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticHunEHealthInfrastructureCareResources.CareRegister.CoverageCheckListPanel.Title} />;
        }

        return (
            <CoverageCheckListPanelView
                filterStore={this.filterStore}
                onChangeAsync={this.loadDataAsync}
                ordering={this.ordering}
                paging={this.paging}
                results={this.results}
            />
        );
    }
}

export default connect(
    CoverageCheckListPanel,
    new DependencyAdapter<ICoverageCheckListPanelProps, ICoverageCheckListPanelDependencies>(c => ({
        hunCareApiAdapter: c.resolve("EhiCareApiAdapter")
    }))
);