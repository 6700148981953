import React from "react";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import TargetDataPageBox, { ITargetDataPageBoxProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestPanel/TargetDataPageBox";
import ReferralDataPageBox, { IReferralDataPageBoxProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestPanel/ReferralDataPageBox";
import { IPanelWithButtonPortalProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/IPanelWithButtonPortalProps";
import SuspectedDiagnosis from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/SuspectedDiagnosis";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import ServiceRequestDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestDefinition";
import GenderId from "@Primitives/GenderId.g";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import IServiceRequestReferralExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestReferralExtensionPointProps";
import IServiceRequestClinicalQuestionExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestClinicalQuestionExtensionPointProps";
import IServiceRequestHeaderExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestHeaderExtensionPointProps";
import SpecimenTypeSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/ServiceRequestManagement/SpecimenTypeSelectBox/SpecimenTypeSelectBox";
import Money from "@Toolkit/CommonWeb/Model/Money";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import FinancedServiceApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/Finance/FinancedServiceApiAdapter";
import MedicalServicePanelId from "@Primitives/MedicalServicePanelId.g";

interface IServiceRequestPanelViewDependencies {
    financedServiceApiAdapter: FinancedServiceApiAdapter;
    localizationService: IToolkitLocalizationService;
}

interface IServiceRequestPanelView2Props extends ITargetDataPageBoxProps, IReferralDataPageBoxProps, IPanelWithButtonPortalProps {
    _dependencies?: IServiceRequestPanelViewDependencies;
    _pointOfCareId?: PointOfCareId;
    diagnosisItems: IObservableArray<SuspectedDiagnosis>;
    medicalServiceItems: IObservableArray<RequestedServiceStore>;
    selectedMedicalServicePanels: IObservableArray<MedicalServicePanelId>;
    medicalServiceIds: Array<IEntityVersionSelector<MedicalServiceId>>;
    isSuspectedDiagnosisLoading: boolean;
    isLoading: boolean;
    clinicalQuestionValue: string;
    isClinicalQuestionAllowed: boolean;
    isRenewAllowed: boolean;
    isDeleteAllowed: boolean;
    isSubmitAllowed: boolean;
    isEditingAllowed: boolean;
    onClinicalQuestionChange: (newValue: string) => void;
    remarkValue: string;
    onRemarkChange: (newValue: string) => void;
    onPanelSelectionChanged: (panelId: MedicalServicePanelId, isSelected: boolean) => void;

    serviceRequest: ServiceRequestStore;
    serviceRequestDefinition: ServiceRequestDefinition;
    isAdministration: boolean;
    onSaveServiceRequestAsync: (shouldSubmitServiceRequest: boolean) => Promise<boolean>;
    onRenewServiceRequestAsync: () => Promise<void>;
    onDeleteServiceRequestAsync: () => Promise<void>;
    onClose: () => void;
    openForEditAsync: () => Promise<void>;

    canAddNewMedicalService: boolean;
    currentPatientGenderId: GenderId;
    serviceRequestIdentifier: string;
    serviceRequestCreatedAt: string;
    isMutable: boolean;
    validateAllEvent: TypedEvent;

    textBoxHeight?: string;
    onTextBoxHeightChange: (newValue: string) => void;
    onValidateAsync: () => Promise<IClientValidationResult[]>;

    referralExtensionPanelProps: IServiceRequestReferralExtensionPointProps;
    headerExtensionPanelProps: IServiceRequestHeaderExtensionPointProps;

    clinicalQuestionExtensionPanelProps: IServiceRequestClinicalQuestionExtensionPointProps;
}
@State.observer
export class ServiceRequestPanelView extends React.Component<IServiceRequestPanelView2Props> {
    @State.observable.ref private isLoading = false;
    @State.observable.ref private isMedicalServicePriceDisplayEnabled = false;
    @State.observable.ref private totalNetPrice: number = 0;
    @State.observable.ref private currencyCode: string = "HUF";

    @State.computed
    private get localizedRequestedServicesGroupTitle() {
        if (!this.isMedicalServicePriceDisplayEnabled) {
            return StaticWebAppResources.NewServiceRequestPage.RequestedServices;
        }

        const localizedMoney = this.props._dependencies.localizationService.localizeMoney(
            new Money(this.totalNetPrice, this.currencyCode)
        );

        return formatStringWithObjectParams(
            StaticWebAppResources.NewServiceRequestPage.RequestedServicesWithPrice,
            {
                TotalNetPrice: localizedMoney
            }
        );
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadPanelAsync(), this);
    }

    @State.bound
    private async loadPanelAsync() {
        State.runInAction(() => this.isLoading = true);
        const isMedicalServicePriceDisplayEnabled = isNullOrUndefined(this.props._pointOfCareId) ? false :
            (await this.props._dependencies.financedServiceApiAdapter.isMedicalServicePriceDisplayEnabledAsync(
                new OrganizationUnitId(this.props._pointOfCareId.value)
                )).value;
        State.runInAction(() => {
            this.isMedicalServicePriceDisplayEnabled = isMedicalServicePriceDisplayEnabled;
            this.isLoading = false;
        });
    }

    public render() {
        return (
            !this.isLoading && <UseCaseFrame
                title={`${this.props.serviceRequestDefinition.shortName} ${StaticWebAppResources.NewServiceRequestPage.PageTitle} ${this.props.serviceRequestIdentifier ? `(${this.props.serviceRequestIdentifier})` : ""}`}
                subTitle={`${StaticWebAppResources.NewServiceRequestPage.CreatedAt}: ${this.props.serviceRequestCreatedAt}`}
                toolbar={this.renderToolbar()}
            >
                <ValidationBoundary
                    entityTypeName="Appointment,ServiceRequest"
                    validationResults={this.props.serviceRequest.validationResults}
                    onValidateAsync={this.props.onValidateAsync}
                    validateAllEvent={this.props.validateAllEvent}>
                    <ReadOnlyContext.Provider
                        value={!this.props.isEditingAllowed || !this.props.serviceRequest.isMutable}>
                        {this.renderHeaderExtensionPoint()}
                        {this.renderSummary()}
                        {this.renderReferralExtensionPoint()}
                        {this.renderSpecimenData()}
                        {this.renderRequestedServices()}
                        {this.renderMisc()}
                    </ReadOnlyContext.Provider>
                </ValidationBoundary>
            </UseCaseFrame>
        );
    }

    private renderSpecimenData() {
        if (!this.props.serviceRequestDefinition.specimenDataRequired) {
            return null;
        }

        return (
            <>
                <Ui.GroupBox title={StaticWebAppResources.NewServiceRequestPage.SpecimenData}>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <SpecimenTypeSelectBox
                                label={StaticWebAppResources.NewServiceRequestPage.SpecimenTypeLabel}
                                propertyIdentifier="SpecimenTypeId"
                                onChange={this.props.serviceRequest?.setSpecimenTypeId}
                                value={this.props.serviceRequest?.specimenTypeId}
                                searchable clearable
                                automationId="__specimenType" />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                        <Ui.DateTimePicker
                            value={this.props.serviceRequest?.specimenCollectedAt}
                            onChange={this.props.serviceRequest?.setSpecimenCollectedAt}
                            label={StaticWebAppResources.NewServiceRequestPage.SpecimenCollectedAtLabel}
                            propertyIdentifier="SpecimenCollectedAt"
                            automationId="__SpecimenCollectedAt"
                            validateOnMount />
                        </Ui.Flex.Item>                            
                    </Ui.Flex>
                </Ui.GroupBox>
            </>
        );
    }

    private renderToolbar() {
        const props = this.props;
        return (
            <>
                {!isNullOrUndefined(props.serviceRequest.lockInfo) &&
                    props.serviceRequest.lockInfo.lockState !== EntityLockState.LockingRequiredAndLockHeld &&
                    !props.isAdministration &&
                    <HisUi.LockIndicatorComponent
                        locked={!props.serviceRequest.isMutable}
                        lockedBy={props.serviceRequest?.lockInfo?.preventingLockUserId}
                        onEditClickedAsync={props.openForEditAsync}
                        permissionCheckOperationNames="Modify"
                        permissionDeniedStyle="invisible"
                        style={{ alignContent: "right" }}
                    />
                }
                {props.isRenewAllowed && props.serviceRequest.isMutable &&
                    <Ui.Button
                        text={StaticWebAppResources.NewServiceRequestPage.Renew}
                        visualStyle="primary"
                        permissionCheckOperationNames="Renew"
                        permissionDeniedStyle="disabled"
                        onClickAsync={props.onRenewServiceRequestAsync}
                        automationId="__renewRequest" />
                }
                {props.isDeleteAllowed && props.serviceRequest.isMutable &&
                    <Ui.Button
                        iconName="trash"
                        visualStyle="negative"
                        permissionCheckOperationNames="DeleteDraft"
                        permissionDeniedStyle="disabled"
                        onClickAsync={props.onDeleteServiceRequestAsync}
                        automationId="__deleteRequest" />
                }
                {props.isEditingAllowed && (
                    <>
                        <Ui.SaveButton
                            text={StaticWebAppResources.NewServiceRequestPage.SaveRequest}
                            visualStyle="standard"
                            permissionCheckOperationNames="CreateNew,Modify"
                            permissionDeniedStyle="disabled"
                            onClickAsync={this.saveAsync}
                            automationId="__saveRequest"
                        />
                        <Ui.Button
                            text={StaticWebAppResources.NewServiceRequestPage.SaveAndSendRequest}
                            visualStyle="primary"
                            permissionCheckOperationNames="CreateNew,Modify"
                            permissionDeniedStyle="disabled"
                            onClickAsync={this.saveAndSubmitAsync}
                            automationId="__saveAndSendRequest"
                            iconName="paper_plane"
                        />
                    </>
                )}
            </>
        );
    }

    @State.bound
    private async saveAsync() {
        return await this.props.onSaveServiceRequestAsync(false);
    }

    @State.bound
    private async saveAndSubmitAsync() {
        await this.props.onSaveServiceRequestAsync(true);
    }

    @State.action.bound
    private setTotalNetPrice(newValue: number): void {
        this.totalNetPrice = newValue;
    }

    private renderSummary() {
        return (
            <>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6} style={{ paddingRight: "10px" }} automationId="__referralData">
                        <Ui.GroupBox title={StaticWebAppResources.NewServiceRequestPage.ReferralData}>
                            {!this.props.isLoading ? <ReferralDataPageBox {...this.props} /> : <></>}
                        </Ui.GroupBox>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6} automationId="__executionData">
                        <Ui.GroupBox title={StaticWebAppResources.NewServiceRequestPage.ExecutionalData}>
                            <TargetDataPageBox {...this.props} />
                        </Ui.GroupBox>
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.GroupBox title={StaticWebAppResources.NewServiceRequestPage.SuspectedDiagnoses}>
                    {!this.props.isSuspectedDiagnosisLoading ?
                        <HisUi.SuspectedDiagnosisListPanel
                            items={this.props.diagnosisItems}
                            readonly={this.props.serviceRequest.suspectedDiagnosisListReadOnly}
                        /> : <></>}
                </Ui.GroupBox>
            </>
        );
    }

    private renderRequestedServices() {
        if (!this.props.medicalServiceIds?.length) {
            return null;
        }

        return (
            <>
                <Ui.GroupBox title={this.localizedRequestedServicesGroupTitle}>
                    <Ui.ValidationResultSummary
                        propertyPath="RequestedServices"
                    />
                    <HisUi.RequestedServiceListPanel
                        selectedItems={this.props.medicalServiceItems}
                        availableMedicalServices={this.props.medicalServiceIds}
                        isAvailable={this.props.canAddNewMedicalService}
                        availablePanels={this.props.serviceRequestDefinition.medicalServicePanelIds}
                        selectedPanels={this.props.selectedMedicalServicePanels}
                        currentPatientGenderId={this.props.currentPatientGenderId}
                        onTotalNetPriceChanged={this.setTotalNetPrice}
                        onPanelSelectionChanged={this.props.onPanelSelectionChanged}
                    />
                </Ui.GroupBox>
            </>
        );
    }

    private renderMisc() {
        return (
            <Ui.Flex>
                <Ui.Flex.Item xs={4}>
                    <Ui.GroupBox title={StaticWebAppResources.NewServiceRequestPage.AdditionalData}>
                        <Ui.TextBox
                            label={StaticWebAppResources.NewServiceRequestPage.AdditionalData}
                            isReadOnly
                            autoResize
                            multiline
                            multilineMaxLineCount={6}
                            propertyIdentifier="AdditionalData"
                            automationId="__additionalData"
                            autoResizeHeight={this.props.textBoxHeight}
                            onHeightChange={this.props.onTextBoxHeightChange}
                        />
                    </Ui.GroupBox>
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={8}>
                    <Ui.GroupBox title={StaticWebAppResources.NewServiceRequestPage.ExecutionNotes}
                        automationId="__executionNotesPanel">
                        <Ui.Flex>
                            <Ui.Flex.Item xs={6}>
                                {this.renderClinicalQuestionExtensionPoint()}
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={6}>
                                <Ui.TextBox
                                    label={StaticWebAppResources.NewServiceRequestPage.ExecutionNotesLabels.Remark}
                                    value={this.props.remarkValue}
                                    onChange={this.props.onRemarkChange}
                                    autoResize
                                    multiline
                                    multilineMaxLineCount={6}
                                    propertyIdentifier="Remark"
                                    automationId="__remark"
                                    autoResizeHeight={this.props.textBoxHeight}
                                    onHeightChange={this.props.onTextBoxHeightChange}
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.GroupBox>
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    private renderReferralExtensionPoint() {
        return (
            <HisPlatformExtensionPoint
                extensionProps={this.props.referralExtensionPanelProps}
                type="ServiceRequestReferralData">
                <></>
            </HisPlatformExtensionPoint>
        );
    }

    private renderHeaderExtensionPoint() {
        return (
            <HisPlatformExtensionPoint
                extensionProps={this.props.headerExtensionPanelProps}
                type="ServiceRequestHeader">
                <></>
            </HisPlatformExtensionPoint>
        );
    }

    private renderClinicalQuestionExtensionPoint() {
        return (
            <HisPlatformExtensionPoint
                type="ServiceRequestClinicalQuestion"
                extensionProps={this.props.clinicalQuestionExtensionPanelProps}>
                <Ui.TextBox
                    label={StaticWebAppResources.NewServiceRequestPage.ExecutionNotesLabels.ClinicalQuestion}
                    value={this.props.clinicalQuestionValue}
                    onChange={this.props.onClinicalQuestionChange}
                    autoResize
                    multiline
                    multilineMaxLineCount={6}
                    disabled={!this.props.isClinicalQuestionAllowed}
                    propertyIdentifier="ClinicalQuestion"
                    automationId="__clinicalQuestion"
                    autoResizeHeight={this.props.textBoxHeight}
                    onHeightChange={this.props.onTextBoxHeightChange}
                />
            </HisPlatformExtensionPoint>
        );
    }
}

export default connect(
    ServiceRequestPanelView,
    new DependencyAdapter<IServiceRequestPanelView2Props, IServiceRequestPanelViewDependencies>(c => ({
        financedServiceApiAdapter: c.resolve<FinancedServiceApiAdapter>("FinancedServiceApiAdapter"),
        localizationService: c.resolve<IToolkitLocalizationService>("IToolkitLocalizationService")
    })),
    new CareActivityContextAdapter<IServiceRequestPanelView2Props>(careActivityContext => ({
        _pointOfCareId: careActivityContext.careActivity.pointOfCareId
    }))
);