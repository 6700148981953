import LateralityId from "@Primitives/LateralityId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import PerformedServiceGroupIdentifier from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/PerformedServiceGroupIdentifier";
import moment from "moment";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import MedicalServiceFinancingId from "@Primitives/MedicalServiceFinancingId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class PerformedServiceStore {
    @State.observable.ref public performedAt: moment.Moment = null;
    @State.observable.ref public lateralityId: LateralityId = LateralityId.NotApplicable;
    @State.observable.ref public medicalServiceId: IEntityVersionSelector<MedicalServiceId>;
    @State.observable.ref public financingId: MedicalServiceFinancingId;
    @State.observable.ref public partOf: PerformedServiceGroupIdentifier = null;
    @State.observable.ref public performedBy: PractitionerId = null;
    @State.observable.ref public selectedIds: Array<IEntityVersionSelector<MedicalServiceId>>;
    @State.observable.ref public extensionData: any = {};

    @State.observable public medicalServiceQuantity: number = 1;

    constructor() {
        this.reset();
    }

    @State.action.bound
    public setMedicalService(medicalService: IEntityVersionSelector<MedicalServiceId>) {
        this.medicalServiceId = medicalService;
    }

    @State.action.bound
    public setSelectedIds(ids: Array<IEntityVersionSelector<MedicalServiceId>>) {
        this.selectedIds = ids;
    }

    @State.action.bound
    public setLaterality(laterality: LateralityId) {
        this.lateralityId = laterality;
    }

    @State.action.bound
    public setPerformedAt(newValue: moment.Moment) {
        this.performedAt = newValue;
    }

    @State.action.bound
    public setMedicalServiceQuantity(newValue: number) {
        this.medicalServiceQuantity = newValue;
    }

    @State.action.bound
    public setExtensionData(extensionData: any) {
        this.extensionData = extensionData;
    }

    @State.action.bound
    public reset() {
        this.performedAt = null;
        this.lateralityId = LateralityId.NotApplicable;
        this.financingId = null;
        this.medicalServiceId = null;
        this.partOf = null;
        this.performedBy = null;
        this.medicalServiceQuantity = 1;
        this.selectedIds =  [];
    }
}
