import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import * as Ui from "@CommonControls";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import PatientAllergyIntoleranceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceStore";
import PatientAllergyIntoleranceId from "@Primitives/PatientAllergyIntoleranceId.g";
import PatientAllergyIntoleranceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceApiAdapter";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import { AllergyIntoleranceCodeSelector, AllergyIntoleranceReactionCodeSelector } from "@HisPlatformControls";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import MedicalConditionHistoryItemDetailPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicalConditionHistoryItemDetailPanel";
import PatientAllergyIntoleranceFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceFactory";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import PatientId from "@Primitives/PatientId.g";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import PatientAllergyIntoleranceHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceHistoryItem";
import MedicalConditionItemHeaderPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicalConditionItemHeaderPanel";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import { EntityLockState } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import IMedicalConditionReferenceDataResolver from "@HisPlatform/BoundedContexts/Care/Services/Definition/MedicalConditionReferenceDataResolver/IMedicalConditionReferenceDataResolver";
import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import FormExtensionRegistryAdapter from "@PluginInterface/FormExtension/FormExtensionRegistryAdapter";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import IServiceRequestHeaderExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestHeaderExtensionPointProps";
import AllergyIntoleranceCriticalityRadioButtonGroup from "@HisPlatform/BoundedContexts/Care/Components/Controls/PatientRegister/PatientAllergyIntolerance/AllergyIntoleranceCriticalityRadioButtonGroup/AllergyIntoleranceCriticalityRadioButtonGroup";
import { AllergyIntoleranceCodeSelectorItem } from "@HisPlatformControls/AllergyIntoleranceCodeSelector/AllergyIntoleranceCodeSelectorItem";
import AllergyIntoleranceAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/AllergyIntoleranceAssignmentBase";
import AllergyIntoleranceId from "@Primitives/AllergyIntoleranceId.g";
import ReferencedAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/ReferencedAllergyIntoleranceAssignment";
import CustomAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/CustomAllergyIntoleranceAssignment";
import MedicationAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/MedicationAllergyIntoleranceAssignment";
import MedicationId from "@Primitives/MedicationId.g";
import SubstanceAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/SubstanceAllergyIntoleranceAssignment";
import SubstanceId from "@Primitives/SubstanceId.g";
import AllergyIntoleranceClinicalStatusSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/PatientRegister/PatientAllergyIntolerance/AllergyIntoleranceClinicalStatusSelectBox/AllergyIntoleranceClinicalStatusSelectBox";
import AllergyIntoleranceReactionId from "@Primitives/AllergyIntoleranceReactionId.g";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import AllergyIntoleranceReactionAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/AllergyIntoleranceReactionAssignment";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import InternationalNonproprietaryNameId from "@Primitives/InternationalNonproprietaryNameId.g";
import InternationalNonproprietaryNameAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/InternationalNonproprietaryNameAllergyIntoleranceAssignment";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";

interface IPatientAllergyIntolerancePanelDependencies {
    dialogService: IDialogService;
    notificationService: INotificationService;
    patientAllergyIntoleranceApiAdapter: PatientAllergyIntoleranceApiAdapter;
    patientAllergyIntoleranceFactory: PatientAllergyIntoleranceFactory;
    lockingApiAdapter: LockingApiAdapter;
    medicalConditionReferenceDataResolver: IMedicalConditionReferenceDataResolver;
}

interface IPatientAllergyIntolerancePanelProps extends IRoutingFrameContentProps {
    _dependencies?: IPatientAllergyIntolerancePanelDependencies;
    _patientId?: PatientId;
    _formExtension?: IFormExtension<PatientId>;

    id: PatientAllergyIntoleranceId;
    onPatientAllergyIntoleranceCreatedAsync?: (patientAllergyIntoleranceId: PatientAllergyIntoleranceId) => Promise<void>;
    onCloseDetailAsync: () => Promise<void>;
    onRefreshListAsync: () => Promise<void>;
}

@State.observer
class PatientAllergyIntolerancePanel extends React.Component<IPatientAllergyIntolerancePanelProps> {

    private get patientAllergyIntoleranceId() { return this.props.id; }
    private get patientId() { return this.props._patientId; }
    @State.observable.ref private store: PatientAllergyIntoleranceStore = this.patientAllergyIntoleranceFactory.createNewStore();
    @State.computed private get currentHistoryItem() { return this.store.currentHistoryItem as PatientAllergyIntoleranceHistoryItem; }
    @State.computed private get isNew() { return this.patientAllergyIntoleranceId.value === "new"; }

    private get dialogService() { return this.props._dependencies.dialogService; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get patientAllergyIntoleranceApiAdapter() { return this.props._dependencies.patientAllergyIntoleranceApiAdapter; }
    private get patientAllergyIntoleranceFactory() { return this.props._dependencies.patientAllergyIntoleranceFactory; }
    private get lockingApiAdapter() { return this.props._dependencies.lockingApiAdapter; }
    private get medicalConditionReferenceDataResolver() { return this.props._dependencies.medicalConditionReferenceDataResolver; }

    private get resources() { return StaticCareResources.MedicalCondition.PatientAllergyIntolerances; }
    private get medicalConditionResources() { return StaticCareResources.MedicalCondition; }

    private readonly clearDirtyFieldsEvent = new TypedEvent();

    private readonly initialLoadPanelAsync = createInitialPanelLoader(
        this.loadAsync,
        this.isNew
            ? async () => await this.props._dependencies.patientAllergyIntoleranceApiAdapter.checkPermissionForAddNewItemAsync(this.patientAllergyIntoleranceFactory.createNewStore(), new PatientId("1"))
            : () => Promise.resolve([])
    );

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IPatientAllergyIntolerancePanelProps) {
        if (prevProps.id.value !== this.props.id.value) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.action.bound
    private async loadAsync(forceReleaseLockNeeded: boolean = false) {
        if (forceReleaseLockNeeded && !!this.store?.lockInfo?.preventingLockId) {
            await this.lockingApiAdapter.forceReleaseLockAsync(this.store.lockInfo.preventingLockId);
        }

        let store = null;
        if (this.isNew) {
            store = this.patientAllergyIntoleranceFactory.createNewStore();
        } else {
            store = await this.patientAllergyIntoleranceApiAdapter.getByIdAsync(this.patientAllergyIntoleranceId, true);
        }
        this.store.setExtensionData(store.extensionData);
        await this.setStoreAsync(store);
    }

    @State.action.bound
    private async setStoreAsync(newValue: PatientAllergyIntoleranceStore) {
        this.store = newValue;
        const name = await this.medicalConditionReferenceDataResolver.getAllergyIntoleranceNameAsync(this.store.allergyIntoleranceCodeSelectorItem);
        this.store.setName(name);
        this.store.takeSnapshot();
    }

    @State.bound
    private async navigateAwayAsync() {
        if (this.store?.isMutable === true && this.isUnsavedOrDirty()) {
            const dialogResult = await this.dialogService.confirmIfNotSaved(StaticCareResources.Common.Dialog.ConfirmationTitle, StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);
            if (dialogResult.resultCode === DialogResultCode.Yes) {
                await this.saveStoreAsync();
                await this.releaseLockAsync();
            } else if (dialogResult.resultCode === DialogResultCode.No) {
                await this.releaseLockAsync();
                return true;
            }
            return false;
        }
        await this.releaseLockAsync();
        return true;
    }

    @State.action.bound
    private async releaseLockAsync() {
        if (this.store.isMutable && this.store.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld) {
            await this.lockingApiAdapter.releaseLockAsync(this.store.lockInfo.lockId);
            this.store.releaseLock();
            return true;
        }
        return false;
    }

    private isUnsavedOrDirty() {
        return this.store.isDirty() && this.currentHistoryItem.isNew;
    }

    @State.action.bound
    private async validateAsync(): Promise<IClientValidationResult[]> {
        if (!this.store || !this.store.isMutable || !this.currentHistoryItem.isNew) {
            return [];
        }
        const validationResultResponse = await this.patientAllergyIntoleranceApiAdapter.validateAsync(this.store, this.patientId);
        return validationResultResponse.value;
    }

    @State.action.bound
    private async validateBeforeSaveAsync() {
        const newValue = await this.validateAsync();
        this.store.setValidationResults(newValue);
    }

    @State.action.bound
    private async saveStoreAsync() {
        this.clearDirtyFieldsEvent.emit();

        await this.validateBeforeSaveAsync();

        if (this.store.hasValidationError) {
            return false;
        }

        await this.setExtensionDataAsync();

        let newStore = null;

        if (this.isNew) {
            newStore = await this.patientAllergyIntoleranceApiAdapter.addPatientAllergyIntoleranceAsync(this.store, this.patientId);
        } else {
            newStore = await this.patientAllergyIntoleranceApiAdapter.updatePatientAllergyIntoleranceAsync(this.store);
        }

        if (newStore.operationWasSuccessful && newStore.isPersistedByOperationInfo && this.isNew) {
            this.store.setExtensionData(newStore.extensionData);
            this.store.takeSnapshot();
            await this.props.onPatientAllergyIntoleranceCreatedAsync?.(newStore.id);
            return true;
        }

        await this.replaceStoreAsync(newStore);
        await this.props.onRefreshListAsync();
        return true;
    }

    @State.action.bound
    private async setExtensionDataAsync() {
        const extensionDataArray = await this.props._formExtension.invokeCallbackAsync<any>("ExtendStore", this.props._patientId);

        let extensionDataForStore = this.store.extensionData;
        for (const extensionData of extensionDataArray) {
            extensionDataForStore = { ...extensionData, ...extensionDataForStore };
        }

        this.store.setExtensionData(extensionDataForStore);
    }

    @State.action.bound
    private async replaceStoreAsync(newStore: PatientAllergyIntoleranceStore) {
        if (newStore.operationWasSuccessful) {
            if (newStore.isPersistedByOperationInfo) {
                newStore.lockInfo = this.store.lockInfo;
                await this.setStoreAsync(newStore);
                this.notificationService.showSavedSuccessfully();
            } else {
                State.runInAction(() => {
                    this.store.validationResults = newStore.validationResults;
                });
            }
        }
    }

    @State.action.bound
    private async deleteHistoryItemAsync() {
        const message = formatString(this.resources.Dialog.DeleteVersionConfirmationMessage, (this.store.currentItemIndex + 1).toString());
        const dialogResult = await this.dialogService.yesNo(StaticCareResources.Common.Dialog.ConfirmationTitle, message);

        if (dialogResult.resultCode === DialogResultCode.No) {
            return false;
        }

        await this.setExtensionDataAsync();

        if (this.store.historyItems.length === 1) {
            await this.patientAllergyIntoleranceApiAdapter.deletePatientAllergyIntoleranceAsync(this.store);
            this.store.releaseLock();
            this.props.onCloseDetailAsync();
            return true;
        } else {
            const newStore = await this.patientAllergyIntoleranceApiAdapter.deletePatientAllergyIntoleranceHistoryItemAsync(this.store, this.store.currentHistoryItem.versionNumber);

            await this.replaceStoreAsync(newStore);
            return true;
        }
    }

    @State.action.bound
    private async forceLoadAsync() {
        await this.loadAsync(true);
    }

    @State.computed
    private get errorExtensionProps() {
        return {
            extensionData: this.store.currentHistoryItem.extensionData
        } as IServiceRequestHeaderExtensionPointProps;
    }

    @State.computed
    public get renderErrorExtension() {
        return (
            <HisPlatformExtensionPoint
                type="ServiceRequestHeader"
                extensionProps={this.errorExtensionProps}>
                <></>
            </HisPlatformExtensionPoint>
        );
    }

    @State.bound
    private showNoEhiCompatibleIdentifierFoundError() {
        this.notificationService.error(this.medicalConditionResources.Common.Messages.NoEhiCompatibleIdentifierFoundErrorMessage);
        return true;
    }

    public render() {
        const prefix = this.store.validationPropertyPrefix;
        const labels = StaticCareResources.MedicalCondition.PatientAllergyIntolerances.Label;
        const allergyIntoleranceName = this.store.isNew ?
            StaticCareResources.MedicalCondition.Common.Label.NewEntry : this.store.name;

        return (
            <PermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                <BusinessErrorHandler.Register businessErrorName="NoEhiCompatibleIdentifierFoundError" handler={this.showNoEhiCompatibleIdentifierFoundError} />
                <UseCaseFrame
                    toolbar={(
                        <MedicalConditionItemHeaderPanel
                            store={this.store}
                            onDeleteHistoryItemAsync={this.deleteHistoryItemAsync}
                            onSaveStoreAsync={this.saveStoreAsync}
                            onLockEditClickedAsync={this.forceLoadAsync} />
                    )}
                    title={allergyIntoleranceName}>
                    {this.initialLoadPanelAsync.isUnauthorizedAccess ? <UnauthorizedAccessContent /> :
                        <>
                            {this.renderErrorExtension}
                            <ValidationBoundary
                                validationResults={this.store.validationResults}
                                entityTypeName="PatientAllergyIntolerance"
                                onValidateAsync={this.validateAsync}
                                clearDirtyFieldsEvent={this.clearDirtyFieldsEvent}
                                validateOnMount
                            >
                                <ReadOnlyContext.Provider value={!this.store.isMutable || !this.currentHistoryItem.isNew}>
                                    <Ui.Flex>
                                        <Ui.Flex.Item sm={6}>
                                            <AllergyIntoleranceCodeSelector
                                                isReadOnly={!this.store.isNew}
                                                label={labels.AllergyIntolerance}
                                                value={this.store.allergyIntoleranceCodeSelectorItem}
                                                onChange={this.setAssignment}
                                                propertyIdentifier="AllergyIntoleranceIdOrName"
                                                automationId="__allergyIntoleranceId"
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item sm={6}>
                                            <AllergyIntoleranceCriticalityRadioButtonGroup
                                                label={labels.AllergyIntoleranceCriticality}
                                                value={this.currentHistoryItem.criticality}
                                                onChange={this.currentHistoryItem.setCriticality}
                                                propertyIdentifier={`${prefix}.Criticality`}
                                                automationId="__allergyIntoleranceCriticality"
                                            />
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                    <Ui.Flex>
                                        <Ui.Flex.Item sm={6}>
                                            <AllergyIntoleranceClinicalStatusSelectBox
                                                value={this.currentHistoryItem.clinicalStatus}
                                                onChange={this.currentHistoryItem.setClinicalStatus}
                                                label={labels.AllergyIntoleranceClinicalStatus}
                                                propertyIdentifier={`${prefix}.ClinicalStatus`}
                                                automationId="__clinicalStatus"
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item sm={6}>
                                            <Ui.DatePicker
                                                value={this.currentHistoryItem.lastOccurrence}
                                                onChange={this.currentHistoryItem.setLastOccurrence}
                                                label={labels.LastOccurrence}
                                                propertyIdentifier={`${prefix}.LastOccurrence`}
                                                automationId="__lastOccurrence"
                                            />
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                    <Ui.Flex>
                                        <Ui.Flex.Item sm={12}>
                                            <ListPanel<AllergyIntoleranceReactionAssignment>
                                                onCreateNewAsync={this.currentHistoryItem.addNewAllergyIntoleranceReaction}
                                                renderItemEditor={this.renderItemEditor}
                                                actionButtonsOrientation="vertical"
                                                items={this.currentHistoryItem.reactions}
                                                onDeleteItemConfirmationAsync={this.currentHistoryItem.deletePatientAllergyIntoleranceReactionAsync}
                                                alwaysEdit
                                                automationId="__reactionsListPanel"
                                                propertyIdentifier={`${prefix}.AllergyIntoleranceReactionAssignment`}
                                            />
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                    <br />
                                    <MedicalConditionHistoryItemDetailPanel store={this.store} prefix={prefix} />
                                </ReadOnlyContext.Provider>
                            </ValidationBoundary>
                            <NavigateAwayHook onNavigateAwayAsync={this.navigateAwayAsync} />
                        </>
                    }
                </UseCaseFrame>
            </PermissionCheckContextProvider>
        );
    }

    @State.computed private get permissionCheckedOperations() {
        const res = {};

        const store = this.patientAllergyIntoleranceFactory.createNewStore();
        store.id = new PatientAllergyIntoleranceId("1");

        if (this.props.id) {
            res["AddNewVersion"] = async () => await this.props._dependencies.patientAllergyIntoleranceApiAdapter.checkPermissionForUpdateItemAsync(store);
            res["DeleteVersion"] = async () => await this.props._dependencies.patientAllergyIntoleranceApiAdapter.checkPermissionForDeleteItemAsync(store);
        }

        return res;
    }

    @State.bound
    private setAssignment(newValue: AllergyIntoleranceCodeSelectorItem) {
        let assignment: AllergyIntoleranceAssignmentBase;
        if (!newValue) {
            assignment = new CustomAllergyIntoleranceAssignment();
        } else if (newValue.id instanceof AllergyIntoleranceId) {
            const referencedAssignment = new ReferencedAllergyIntoleranceAssignment();
            referencedAssignment.setAllergyIntolerance(newValue.id);
            assignment = referencedAssignment;
        } else if (newValue.id instanceof MedicationId) {
            const medicationAssignment = new MedicationAllergyIntoleranceAssignment();
            medicationAssignment.setMedication(newValue.id);
            assignment = medicationAssignment;
        } else if (newValue.id instanceof SubstanceId) {
            const substanceAssignment = new SubstanceAllergyIntoleranceAssignment();
            substanceAssignment.setSubstance(newValue.id);
            assignment = substanceAssignment;
        } else if (newValue.id instanceof InternationalNonproprietaryNameId) {
            const innAssignment = new InternationalNonproprietaryNameAllergyIntoleranceAssignment();
            innAssignment.setId(newValue.id);
            assignment = innAssignment;
        } else {
            const customAssignment = new CustomAllergyIntoleranceAssignment();
            customAssignment.setName(newValue.text);
            assignment = customAssignment;
        }
        this.store.setAssignment(assignment);
    }

    @State.bound
    private setCustomAllergyIntoleranceAssignment(custom: string) {
        const customAssignment = new CustomAllergyIntoleranceAssignment();
        customAssignment.setName(custom);
        this.store.setAssignment(customAssignment);
    }

    @State.bound
    private renderItemEditor(allergyIntoleranceReactionAssignment: AllergyIntoleranceReactionAssignment, index: number) {
        const prefix = this.store.validationPropertyPrefix;

        return (
            <State.Observer>{() => (
                <Ui.Flex>
                    <Ui.Flex.Item sm={6}>
                        <Ui.TextBox
                            label={StaticCareResources.MedicalCondition.PatientAllergyIntolerances.Label.AllergyIntoleranceReactionDescription + " " + (index + 1)}
                            value={allergyIntoleranceReactionAssignment.description}
                            onChange={this.setAllergyIntoleranceReactionDescription(index)}
                            automationId="__description"
                            propertyIdentifier={`Description`}
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={6}>
                        <AllergyIntoleranceReactionCodeSelector
                            label={StaticCareResources.MedicalCondition.PatientAllergyIntolerances.Label.AllergyIntoleranceReaction + " " + (index + 1)}
                            value={allergyIntoleranceReactionAssignment
                                && allergyIntoleranceReactionAssignment.allergyIntoleranceReactionId.id}
                            onChange={this.setAllergyIntoleranceReactionHandlerFactory(index)}
                            propertyIdentifier="Value"
                            automationId="__reactionSelector"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            )}</State.Observer>
        );
    }

    private setAllergyIntoleranceReactionHandlerFactory(index: number) {
        return (newAllergyIntoleranceReactionId: AllergyIntoleranceReactionId) => {
            this.currentHistoryItem.setAllergyIntoleranceReaction(newAllergyIntoleranceReactionId, index);
        };
    }

    private setAllergyIntoleranceReactionDescription(index: number) {
        return (description: string) => {
            this.currentHistoryItem.setAllergyIntoleranceReactionDescription(description, index);
        };
    }
}

export default connect(
    PatientAllergyIntolerancePanel,
    new DependencyAdapter<IPatientAllergyIntolerancePanelProps, IPatientAllergyIntolerancePanelDependencies>(c => ({
        dialogService: c.resolve<IDialogService>("IDialogService"),
        notificationService: c.resolve<INotificationService>("INotificationService"),
        patientAllergyIntoleranceApiAdapter: c.resolve<PatientAllergyIntoleranceApiAdapter>("PatientAllergyIntoleranceApiAdapter"),
        patientAllergyIntoleranceFactory: c.resolve<PatientAllergyIntoleranceFactory>("PatientAllergyIntoleranceFactory"),
        lockingApiAdapter: c.resolve<LockingApiAdapter>("LockingApiAdapter"),
        medicalConditionReferenceDataResolver: c.resolve("IMedicalConditionReferenceDataResolver"),
    })),
    new PatientContextAdapter<IPatientAllergyIntolerancePanelProps>(c => ({
        _patientId: c.patientId
    })),
    new FormExtensionRegistryAdapter("ManageMedicalCondition")
);
