import FormLogic from "@Toolkit/FormEngine/Model/FormLogic";
import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";

export interface IFormLogicEditorDialogParams extends IModalParams {
    readonly formLogics: FormLogic[];
}

export interface IFormLogicEditorDialogResult {
    formLogics: FormLogic[];

}

export default class FormLogicEditorDialogParams implements IFormLogicEditorDialogParams {

    public static type = "FormLogicEditorDialog";
    public get type() { return FormLogicEditorDialogParams.type; }

    constructor(
        public readonly formLogics: FormLogic[]
    ) { }

}