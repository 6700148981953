import Di from "@Di";
import FormExtensionBase from "@PluginInterface/FormExtension/FormExtensionBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientId from "@Primitives/PatientId.g";
import EhiUserType from "@HunEHealthInfrastructurePlugin/Model/EhiUserType";
import EhiMedicalConditionService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Services/EhiMedicalConditionService";
import EhiMedicalConditionServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Services/EhiMedicalConditionServiceFactory";

@Di.injectable()
export default class ManageMedicalConditionExtension extends FormExtensionBase<PatientId> {

    private ehiMedicalConditionService: EhiMedicalConditionService = null;

    constructor(
        @Di.inject("EhiMedicalConditionServiceFactory") private readonly ehiMedicalConditionServiceFactory: EhiMedicalConditionServiceFactory
    ) {
        super();

        this.registerAsyncCallback<any>("ExtendStore", this.getExtensionDataForStoreAsync);
        this.ehiMedicalConditionService = this.ehiMedicalConditionServiceFactory.getEhiMedicalConditionService(null);
    }

    @State.action.bound
    private async getExtensionDataForStoreAsync(patientId: PatientId = null) {
        const extensionData: any = {};

        await this.ehiMedicalConditionService.setEhiAccessDataToExtensionDataAsync(patientId, extensionData, EhiUserType.Doctor);

        return extensionData;
    }
}