import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import NeakPerformanceStatement from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/NeakPerformanceStatement";
import NeakPerformanceStatementApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/NeakPerformanceStatementApiAdapter";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import NeakPerformanceStatementId from "@Primitives/NeakPerformanceStatementId.g";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import StaticHunSocialSecurityPerformanceStatementResources from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/StaticResources/StaticHunSocialSecurityPerformanceStatementResources";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessUseCaseFrame from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessUseCaseFrame";

interface INeakPerformanceStatementTransactionCodeDialogDependencies {
    neakPerformanceStatementApiAdapter: NeakPerformanceStatementApiAdapter;
}

export interface INeakPerformanceStatementTransactionCodeDialogProps {
    _dependencies?: INeakPerformanceStatementTransactionCodeDialogDependencies;
    neakPerformanceStatementId: NeakPerformanceStatementId;
}

/** @screen */
@State.observer
class NeakPerformanceStatementTransactionCodeDialog extends React.Component<INeakPerformanceStatementTransactionCodeDialogProps> {

    @State.observable.ref private neakPerformanceStatement: NeakPerformanceStatement = null;
    @State.observable.ref private transactionCode: string = null;
    @State.observable.ref private closeEvent = new TypedEvent<boolean>();

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.bound
    private async loadAsync() {
        const result = await this.props._dependencies.neakPerformanceStatementApiAdapter.getNeakPerformanceStatementByIdAsync(this.props.neakPerformanceStatementId);
        this.setNeakPerformanceStatement(result);
    }

    @State.action.bound
    public async saveAsync() {
        const result = await this.props._dependencies.neakPerformanceStatementApiAdapter.setTransactionCodeAsync(
            this.props.neakPerformanceStatementId,
            this.neakPerformanceStatement.rowVersion,
            this.transactionCode);

        if (result.isPersistedByOperationInfo) {
            this.closeEvent.emit(true);
        } else {
            State.runInAction(() => {
                this.neakPerformanceStatement.validationResults = result.validationResults;
            });
        }
    }

    @State.action.bound
    public async validateAsync() {
        const result = await this.props._dependencies.neakPerformanceStatementApiAdapter.setTransactionCodeAsync(
            this.props.neakPerformanceStatementId,
            this.neakPerformanceStatement.rowVersion,
            this.transactionCode,
            true);
        return result.validationResults;
    }

    @State.action.bound
    private close() {
        this.closeEvent.emit(false);
    }

    @State.action.bound
    private setNeakPerformanceStatement(neakPerformanceStatement: NeakPerformanceStatement) {
        this.neakPerformanceStatement = neakPerformanceStatement;
    }

    @State.action.bound
    public setTransactionCode(transactionCode: string) {
        this.transactionCode = transactionCode;
    }

    public render() {
        const resources = StaticHunSocialSecurityPerformanceStatementResources.NeakPerformanceStatementTransactionCodeDialog;

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessUseCaseFrame title={resources.Title} />;
        }

        return (
            this.neakPerformanceStatement && (
                <UseCaseFrame
                    modalCloseEvent={this.closeEvent}
                    title={resources.Title}
                    modalSize="tiny"
                    leftFooter={<Ui.Button text={StaticWebAppResources.Common.Button.Close} onClick={this.close} visualStyle="link" automationId="closeButton" />}
                    rightFooter={<Ui.SaveButton visualStyle="primary" onClickAsync={this.saveAsync} automationId="saveButton" />}
                >
                        <ValidationBoundary
                            validationResults={this.neakPerformanceStatement.validationResults}
                            entityTypeName="NeakPerformanceStatement"
                            onValidateAsync={this.validateAsync}
                            validateOnMount
                        >
                            <Ui.Flex>
                                <Ui.Flex.Item sm={10}>
                                    <Ui.TextBox
                                        label={resources.TransactionCode}
                                        value={this.transactionCode}
                                        onChange={this.setTransactionCode}
                                        propertyIdentifier="TransactionCode"
                                        automationId="trasactionCodeTextBox"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </ValidationBoundary>
                </UseCaseFrame>
            )
        );
    }
}

export default connect(
    NeakPerformanceStatementTransactionCodeDialog,
    new DependencyAdapter<INeakPerformanceStatementTransactionCodeDialogProps, INeakPerformanceStatementTransactionCodeDialogDependencies>(c => ({
        neakPerformanceStatementApiAdapter: c.resolve("NeakPerformanceStatementApiAdapter"),
    }))
);
