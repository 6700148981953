import React, { useCallback } from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import CareTypeId from "@Primitives/CareTypeId.g";
import { DischargeReasonSelectBox } from "@HisPlatform/BoundedContexts/Care/Components";

interface IDischargeReasonColumnDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IDischargeReasonColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IDischargeReasonColumnDependencies;    
}


const DischargeReasonColumn: React.SFC<IDischargeReasonColumnProps> = (props: IDischargeReasonColumnProps) => {

    const valueRenderer = (value: any, row: any, rowId: React.ReactText, rowIndex: number, isUnderEditing: boolean, dataSetter: (newValue: any) => void) => {
        if (value) {
            if (Array.isArray(value)) {
                const dischargeReasons = value?.map(i => props._dependencies.careReferenceDataStore.dischargeReason.get(i)?.displayValue?.Name);
                return dischargeReasons.join(", ");
            }
            const dischargeReason = props._dependencies.careReferenceDataStore.dischargeReason.get(value)?.displayValue?.Name;
            return dischargeReason;
        }
        return "";
    };

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<CareTypeId>) => {
        return <DischargeReasonSelectBox {...filterProps} maxMenuWidth={commonMenuMaxWidth} hoverOnlyIndicatorIcons />;
    }, []);

    return (
        <DataGridColumn
            header={"DischargeReason"}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            {...props}
            hideOverflow
        />
    );
};

export default connect(
    DischargeReasonColumn,
    new DependencyAdapter<IDischargeReasonColumnProps, IDischargeReasonColumnDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore")
    }))
);