import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import PermissionDefinitionBuilder from "@PluginInterface/OperationAccessControl/PermissionDefinitionBuilder";
import { PermissionDefinitions } from "@HisPlatform/PermissionDefinitions/PermissionDefinitions.g";

export default function configureWebAppPermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry) {

    // Configure here only those permissions that has no corresponding legacy permission.
    // For those BFF permissions that has a corresponding legacy permission, 
    // add the BFF permission name to the legacy permission's builder like this: PermissionDefinitionBuilder.create(legacyPermission, BffPermission)...

    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(PermissionDefinitions.WebAppBackend.Patients.CombinePatients).build());
    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(PermissionDefinitions.WebAppBackend.Patients.ViewPatientRelatedDocuments).build());
    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(PermissionDefinitions.WebAppBackend.Patients.EditPatientRelatedDocuments).build());
    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(PermissionDefinitions.WebAppBackend.Patients.PrintPatientRelatedDocumentsPreview).build());
    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(PermissionDefinitions.WebAppBackend.Settings.EditCustomer).build());
    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(PermissionDefinitions.WebAppBackend.Patients.EditInvoice).build());
}