import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import HunCareReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/HunCareReferenceDataStore";

interface ILaboratoryExaminationRequestTypeSelectBoxDependencies {
    careReferenceDataStore: HunCareReferenceDataStore;
}

interface ILaboratoryExaminationRequestTypeSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: ILaboratoryExaminationRequestTypeSelectBoxDependencies;
}

@State.observer
class LaboratoryExaminationRequestTypeSelectBox extends React.Component<ILaboratoryExaminationRequestTypeSelectBoxProps> {

    private get dependencies() {
        return this.props._dependencies;
    }

    private get extensibleEnumStore() {
        return this.dependencies.careReferenceDataStore.laboratoryExaminationRequestTypes;
    }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    LaboratoryExaminationRequestTypeSelectBox,
    new DependencyAdapter<ILaboratoryExaminationRequestTypeSelectBoxProps, ILaboratoryExaminationRequestTypeSelectBoxDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("HunCareReferenceDataStore")
        };
    })
);
