import React from "react";
import * as DiContainer from "@DiContainer";
import DialogService from "./DialogService";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import { Modal, Button } from "@CommonControls";
import DialogType from "./DialogType";
import Styles from "./DialogService.less";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StaticWebAppResources from "@StaticResources";

export interface IDialogContainerProps {
    closeButton?: boolean;
    renderContextHeader?: () => React.ReactNode;
    isHeaderPrimary?: boolean;
}

@State.observer
export default class DialogContainer extends React.Component<IDialogContainerProps> {

    // lazyResolve should be used here, because DI context is not initialized at this point
    @DiContainer.lazyResolve("IDialogService") private store: DialogService;

    public render() {
        return (
            <Modal
                automationId="__dialogContainer"
                isOpen={this.store.isOpen}
                onClose={this.store.onClose}
                sideGaps={20}
                title={this.store.title}
                size="tiny"
                closeButton={this.props.closeButton}
                renderContextHeader={this.props.renderContextHeader}
                isHeaderPrimary={this.props.isHeaderPrimary}
                index={4}
                closeOnOutsideClick={this.store.dialogType === DialogType.confirmIfNotSaved ? false : null}
                closeOnEscape={this.store.dialogType === DialogType.confirmIfNotSaved ? false : null}
            >
                <Modal.Body>
                    {this.store.content}
                </Modal.Body>
                <Modal.Footer>
                    {this.renderFooter()}
                </Modal.Footer>
            </Modal>
        );
    }

    private renderFooter() {
        switch (this.store.dialogType) {
            case DialogType.default:
                return this.renderDefault();
            case DialogType.confirmIfNotSaved:
                return this.renderConfirmIfNotSaved();
        }
    }

    private renderDefault() {
        return (
            <div className={Styles.buttonContainer}>
                <div>
                    {this.store.isCancelButton && <Button visualStyle="link" onClick={this.store.onCancel} automationId="cancelButton">{StaticWebAppResources.Common.DialogButton.Cancel}</Button>}
                </div>
                <div>
                    {this.store.isNoButton && <Button visualStyle="negative-standard" onClick={this.store.onNo} automationId="noButton">{StaticWebAppResources.Common.DialogButton.No}</Button>}
                    {this.store.isYesButton && <Button visualStyle="primary" onClick={this.store.onYes} automationId="yesButton">{StaticWebAppResources.Common.DialogButton.Yes}</Button>}
                    {this.store.isOkButton && <Button visualStyle="primary" onClick={this.store.onOk} automationId="okButton">{StaticWebAppResources.Common.DialogButton.Ok}</Button>}
                </div>
            </div>
        );
    }

    private renderConfirmIfNotSaved() {
        return (
            <div className={Styles.buttonContainer}>
                <div>
                    <Button visualStyle="link" onClick={this.store.onCancel} automationId="cancelButton">{StaticWebAppResources.Common.DialogButton.ConfirmIfNotSaved.Cancel}</Button>
                </div>
                <div>
                    <Button visualStyle="negative-standard" onClick={this.store.onNo} automationId="noButton">{StaticWebAppResources.Common.DialogButton.ConfirmIfNotSaved.DiscardChanges}</Button>
                    <Button visualStyle="primary" onClick={this.store.onYes} automationId="yesButton">{StaticWebAppResources.Common.DialogButton.ConfirmIfNotSaved.SaveChanges}</Button>
                </div>
            </div>
        );
    }
}