import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Finance/Api/Proxy.g";
import IVendor, { IVendorBankAccount } from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/IVendor";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class VendorApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("IInvoicingClient") private readonly invoicingApiClient: Proxy.IInvoicingClient,
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public getAllProviderAsync() {
        return this.executeOperationAsync(
            () => this.invoicingApiClient.getAllVendorsQueryAsync(CreateRequestId()),
            response => {
                return response.vendors?.map<IVendor>(v => ({
                    id: v.id,
                    name: v.name,
                    bankAccounts: v.bankAccounts.map<IVendorBankAccount>(ba => ({
                        name: ba.name,
                        bankName: ba.bankName,
                        bankAccountNumber: ba.bankAccountNumber
                    }))
                }));
            }
        );
    }
}