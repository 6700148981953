import FinancingClassId from "@Primitives/FinancingClassId.g";
import Di from "@Di";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import CareExtensibleEnumApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/CareExtensibleEnumApiAdapter";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import AdmissionReasonId from "@Primitives/AdmissionReasonId.g";
import DischargeReasonId from "@Primitives/DischargeReasonId.g";
import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";

@Di.injectable()
export default class CareRegisterReferenceDataStore extends ReferenceDataStoreBase {

    public financingClass = new ExtensibleEnumStore<FinancingClassId>(this.apiAdapter.loadFinancingClassIdsAsync, this.localizationService.localizeEnumId);
    public admissionReason = new ExtensibleEnumStore<AdmissionReasonId>(this.apiAdapter.loadAdmissionReasonIdsAsync, this.localizationService.localizeEnumId);
    public dischargeReason = new ExtensibleEnumStore<DischargeReasonId>(this.apiAdapter.loadDischargeReasonIdsAsync, this.localizationService.localizeEnumId);

    constructor(
        @Di.inject("CareExtensibleEnumApiAdapter") private readonly apiAdapter: CareExtensibleEnumApiAdapter,
        @Di.inject("ILocalizationService") private readonly localizationService: ILocalizationService,
        @Di.inject("IReferenceDataLoader") private readonly referenceDataLoader: IReferenceDataLoader
    ) { 
        super();

        referenceDataLoader.register(FinancingClassId.typeName, () => this.financingClass.ensureLoadedAsync());
        referenceDataLoader.register(AdmissionReasonId.typeName, () => this.admissionReason.ensureLoadedAsync());
        referenceDataLoader.register(DischargeReasonId.typeName, () => this.dischargeReason.ensureLoadedAsync());
    }
}