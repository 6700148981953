exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ServiceRequestListPanel_under-recording_ZoUJF {\n  background-color: #e8e8e8;\n}\n.ServiceRequestListPanel_submitted_1WgdF {\n  background-color: #e8e8e8;\n}\n.ServiceRequestListPanel_registered-as-received_etbFX {\n  background-color: #82d4ed;\n}\n.ServiceRequestListPanel_activity-completed_34d90 {\n  background-color: #ddc00d;\n}\n.ServiceRequestListPanel_non-validated-completed_1bfQU {\n  background-color: #fe8443;\n}\n.ServiceRequestListPanel_validated-completed_2fWWZ {\n  background-color: #21c313;\n}\n.ServiceRequestListPanel_deleted_1dYT5 {\n  background-color: #ce5374;\n}\n@media (max-width: 1899px) {\n  .ServiceRequestListPanel_service-filter_1HvT2 {\n    margin-bottom: 5px;\n    display: inline-block;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"under-recording": "ServiceRequestListPanel_under-recording_ZoUJF",
	"underRecording": "ServiceRequestListPanel_under-recording_ZoUJF",
	"submitted": "ServiceRequestListPanel_submitted_1WgdF",
	"registered-as-received": "ServiceRequestListPanel_registered-as-received_etbFX",
	"registeredAsReceived": "ServiceRequestListPanel_registered-as-received_etbFX",
	"activity-completed": "ServiceRequestListPanel_activity-completed_34d90",
	"activityCompleted": "ServiceRequestListPanel_activity-completed_34d90",
	"non-validated-completed": "ServiceRequestListPanel_non-validated-completed_1bfQU",
	"nonValidatedCompleted": "ServiceRequestListPanel_non-validated-completed_1bfQU",
	"validated-completed": "ServiceRequestListPanel_validated-completed_2fWWZ",
	"validatedCompleted": "ServiceRequestListPanel_validated-completed_2fWWZ",
	"deleted": "ServiceRequestListPanel_deleted_1dYT5",
	"service-filter": "ServiceRequestListPanel_service-filter_1HvT2",
	"serviceFilter": "ServiceRequestListPanel_service-filter_1HvT2"
};