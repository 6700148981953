import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import EhiCareActivityDataPanelStore from "./EhiCareActivityDataPanelStore";

const { 
    ContextComponent: EhiCareActivityDataPanelStoreContext, 
    StoreProvider: EhiCareActivityDataPanelStoreProvider, 
    useStore: useEhiCareActivityDataPanelStore,
    provideStore: provideEhiCareActivityDataPanelStore,
} = createPanelStoreProvider(EhiCareActivityDataPanelStore);

export {
    EhiCareActivityDataPanelStoreContext,
    EhiCareActivityDataPanelStoreProvider,
    useEhiCareActivityDataPanelStore,
    provideEhiCareActivityDataPanelStore
};