import React from "react";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import moment from "moment";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PointOfCareSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareSelectBox";
import MedicalServicePrioritySelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/MedicalServicePrioritySelectBox/MedicalServicePrioritySelectBox";
import MedicalServicePriority from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalServices/Enum/MedicalServicePriority.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ReadOnlyNestedProvider from "@Toolkit/ReactClient/Components/ReadOnlyContext/ReadOnlyNestedProvider";
import ExternalLocationCodeSelector from "@HisPlatform/BoundedContexts/Organization/Components/Controls/ExternalLocationCodeSelector";
import PractitionerId from "@Primitives/PractitionerId.g";
import * as Styles from "./TargetDataPageBox.less";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";

export interface ITargetDataPageBoxProps {
    targetDoctorId: PractitionerId;
    onTargetDoctorIdChange: (newValue: PractitionerId) => void;

    targetCompletionAt: moment.Moment;
    onTargetCompletionDateChange: (newValue: moment.Moment) => void;

    readOnlyMode: boolean;
    isExternalCareLocation: boolean;
    isTargetCareLocationEditingAllowed: boolean;
    availableExternalLocationVersionSelectors: Array<IEntityVersionSelector<ExternalLocationId>>;
    externalLocationVersionSelector: IEntityVersionSelector<ExternalLocationId>;
    onExternalLocationVersionSelectorChange: (newValue: ExternalLocationId) => void;

    availablePointOfCareIds: PointOfCareId[];
    pointOfCareId: PointOfCareId;
    onPointOfCareIdChange: (newValue: PointOfCareId) => void;

    availablePriorities: MedicalServicePriority[];
    priority: MedicalServicePriority;
    onPriorityChange: (newValue: MedicalServicePriority) => void;

    isAppointmentRequired: boolean;
    onOpenAppointmentModal?: () => void;
    dateTimeFormatProvider: IDateTimeFormatProvider;
}

// tslint:disable-next-line:variable-name
const TargetDataPageBox: React.SFC<ITargetDataPageBoxProps> = props => {
    const pointOfCareIdFilter: FilterBase[] = props.pointOfCareId
        ? [new ExplicitIdentifierFilter("PointOfCare", props.pointOfCareId.value)]
        : [];

    return <>
        <Ui.Flex>
            <ReadOnlyNestedProvider value={!props.isTargetCareLocationEditingAllowed || !!props.onOpenAppointmentModal}>
                <Ui.Flex.Item xs={6}>
                    {
                        props.isExternalCareLocation ?
                            (
                                <ExternalLocationCodeSelector
                                    label={StaticWebAppResources.NewServiceRequestPage.TargetDataLabels.PerformingExternalLocation}
                                    externalLocationIds={props.availableExternalLocationVersionSelectors.map(x => x.id)}
                                    value={props.externalLocationVersionSelector && props.externalLocationVersionSelector.id}
                                    validOn={props.externalLocationVersionSelector && props.externalLocationVersionSelector.validOn}
                                    onChange={props.onExternalLocationVersionSelectorChange}
                                    propertyIdentifier="TargetCareLocation"
                                    automationId="__externalLocation"
                                />
                            ) : (
                                <PointOfCareSelectBox
                                    label={StaticWebAppResources.NewServiceRequestPage.TargetDataLabels.PerformingLocation}
                                    value={props.pointOfCareId}
                                    onChange={props.onPointOfCareIdChange}
                                    explicitIds={props.availablePointOfCareIds}
                                    propertyIdentifier="TargetCareLocation"
                                    shouldSelectOnlyItem={true}
                                    automationId="__pointOfCare"
                                />
                            )
                    }
                </Ui.Flex.Item>
            </ReadOnlyNestedProvider>
            <Ui.Flex.Item xs={6}>
                <MedicalServicePrioritySelectBox
                    disabled={isNullOrUndefined(props.availablePriorities)}
                    label={StaticWebAppResources.NewServiceRequestPage.TargetDataLabels.Priority}
                    value={props.priority}
                    onChange={props.onPriorityChange}
                    availableOptions={props.availablePriorities}
                    propertyIdentifier="RequestedService.Priority"
                    automationId="__requestPriority"
                />
            </Ui.Flex.Item>
        </Ui.Flex>
        <Ui.Flex noWrap>
            <Ui.Flex.Item xs={6}>
                <ReadOnlyNestedProvider value={!!props.onOpenAppointmentModal}>
                    {props.isExternalCareLocation ?
                        (
                            <HisUi.DoctorCodeSelector
                                disabled={isNullOrUndefined(props.pointOfCareId) && isNullOrUndefined(props.externalLocationVersionSelector)}
                                value={props.targetDoctorId}
                                onChange={props.onTargetDoctorIdChange}
                                propertyIdentifier="TargetDoctorId"
                                automationId="__requestedExecutor"
                                label={StaticWebAppResources.NewServiceRequestPage.TargetDataLabels.PerformingDoctor}
                            />
                        ) : (
                            <HisUi.DoctorCodeSelector
                                disabled={isNullOrUndefined(props.pointOfCareId) && isNullOrUndefined(props.externalLocationVersionSelector)}
                                value={props.targetDoctorId}
                                onChange={props.onTargetDoctorIdChange}
                                filters={pointOfCareIdFilter}
                                propertyIdentifier="TargetDoctorId"
                                automationId="__requestedExecutor"
                                label={StaticWebAppResources.NewServiceRequestPage.TargetDataLabels.PerformingDoctor}
                            />
                        )
                    }
                </ReadOnlyNestedProvider>
            </Ui.Flex.Item>
            {props.isAppointmentRequired ?
                <ReadOnlyNestedProvider value={!props.onOpenAppointmentModal}>
                    <Ui.Flex.Item grow>
                        <Ui.TextBox
                            disableAutofill
                            value={props.targetCompletionAt?.format(props.dateTimeFormatProvider.getDateTimeWithoutSecondsFormat())}
                            onClick={props.onOpenAppointmentModal}
                            onEnterKeyDown={props.onOpenAppointmentModal}
                            label={StaticWebAppResources.NewServiceRequestPage.TargetDataLabels.DateOfAppointment}
                            propertyIdentifier="UsedSlotIds"
                            automationId="__appointmentDate"
                            clearable={false}
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item className={Styles.appointmentButtonContainer}>
                        <Ui.Button
                            className={Styles.appointmentButton}
                            visualStyle="primary"
                            iconName="appointments"
                            onClick={props.onOpenAppointmentModal}
                            automationId="openAppointmentModalButton"
                        />
                    </Ui.Flex.Item>
                </ReadOnlyNestedProvider> :
                <Ui.Flex.Item xs={6}>
                    <Ui.DateTimePicker
                        value={props.targetCompletionAt}
                        onChange={props.onTargetCompletionDateChange}
                        label={StaticWebAppResources.NewServiceRequestPage.TargetDataLabels.ExpectedCompletionTime}
                        propertyIdentifier="RequestedServices[0].ExpectedCompletionTime"
                        automationId="__expectedCompletionTime"
                        validateOnMount
                    />
                </Ui.Flex.Item>
            }
        </Ui.Flex>
    </>;
};

export default TargetDataPageBox;
