import React from "react";
import Styles from "./DiagnosesFrequencyWidget.less";
import { IDiagnosisFrequencyData } from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/DiagnosesFrequencyWidget/DiagnosesFrequencyWidget";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";

interface IDataContainerProps {
    data: IDiagnosisFrequencyData;
}


const DiagnosisFrequencyDataContainer: React.FC<IDataContainerProps> = (props) => {
    return (
        <Popper
            tooltipContent={(<div>{StaticProductivityResources.Widgets.DiagnosesFrequencyWidget.FirstOccurrenceAt}: {props.data.firstOccurrenceAt}<br />
                {StaticProductivityResources.Widgets.DiagnosesFrequencyWidget.LastOccurrenceAt}: {props.data.lastOccurrenceAt}</div>)}
            wrapperElementType={"div"}
            tooltipPlacement={"right"}
        >
        <div className={Styles.dataContainer}>
            <b>{props.data.diagnosis}</b><br />
            {props.data.count} {StaticProductivityResources.Widgets.DiagnosesFrequencyWidget.Occurrence}
        </div>
        </Popper>
    );
};

export default DiagnosisFrequencyDataContainer;