import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import SlotStore from "./SlotStore";
import AppointmentScheduleSlotSeriesId from "@Primitives/AppointmentScheduleSlotSeriesId.g";
import PatientId from "@Primitives/PatientId.g";
import UnregisteredPatientStore from "./UnregisteredPatientStore";
import moment from "moment";
import CareActivityId from "@Primitives/CareActivityId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import AppointmentStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentStatus.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import ReferralStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/ReferralStore";
import ServiceRequestSubject from "./ServiceRequestSubject";
import SchedulingServiceSubject from "./SchedulingServiceSubject";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import {IAppointmentSubject} from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/IAppointmentSubject";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import AppointmentInvalidationReason from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentInvalidationReason.g";
import MomentRange from "@Toolkit/CommonWeb/Model/MomentRange";
import AppointmentScheduleSlotId from "@Primitives/AppointmentScheduleSlotId.g";
import { isNullOrUndefined, arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import NameStore from "@Primitives/NameStore";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import InvalidationInfo from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/InvalidationInfo";

export default class Appointment extends EntityStoreBase<AppointmentScheduleEntryId> implements IDirtyChecked {
    private _dirtyChecker = new StoreDirtyChecker();

    public patientId: PatientId = null;
    public patientName: NameStore = null;
    public unregisteredPatient: UnregisteredPatientStore = null;
    
    @State.observable.ref public pointOfCareId: PointOfCareId = null;
    @State.observable.ref public practitionerIds: PractitionerId[] = [];
    @State.observable.ref public additionalPractitionerIds: PractitionerId[] = [];
    @State.observable.ref public additionalParticipants: string = "";
    
    @State.observable.ref public subjectService: IAppointmentSubject;
    @State.observable.ref public description: string;
    @State.observable.ref public appointmentScheduleSlotSeriesId: AppointmentScheduleSlotSeriesId;
    @State.observable.ref public intervalFrom: moment.Moment;
    @State.observable.ref public intervalTo: moment.Moment;
    @State.observable.ref public referral: ReferralStore = new ReferralStore();
    @State.observable.ref public slotsToBook: SlotStore[] = [];
    @State.observable.ref public episodeOfCareIds: EpisodeOfCareId[] = [];
    
    public usedSlotIds: AppointmentScheduleSlotId[] = [];

    public careActivityId: CareActivityId;
    public status: AppointmentStatus;
    public isInvalid: boolean;
    public invalidationReason: AppointmentInvalidationReason;
    public invalidationInfo: InvalidationInfo = null;

    constructor(isNew: boolean = false) {
        super(isNew);
    }
    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker"
    ];
    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }
    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }

    @State.action.bound
    public setSubjectService(id: IEntityVersionSelector<ServiceRequestId> | SchedulingServiceId) {
        let subjectService: IAppointmentSubject = null;

        if (!!id) {
            if ((id as IEntityVersionSelector<ServiceRequestId>).id instanceof ServiceRequestId) {
                subjectService = new ServiceRequestSubject();
                (subjectService as ServiceRequestSubject).versionedId = id as IEntityVersionSelector<ServiceRequestId>;
            } else if (id instanceof SchedulingServiceId) {
                subjectService = new SchedulingServiceSubject();
                (subjectService as SchedulingServiceSubject).schedulingServiceId = id;
            }
        }
        this.subjectService = subjectService;
    }

    @State.action.bound
    public setEpisodeOfCareIds(value: EpisodeOfCareId[]) {
        this.episodeOfCareIds = value;
    }
    
    @State.action.bound
    public setDescription(value: string) {
        this.description = value;
    }

    @State.action.bound
    public setAppointmentScheduleId(appointmentScheduleId: AppointmentScheduleSlotSeriesId) {
        this.appointmentScheduleSlotSeriesId = appointmentScheduleId;
    }

    @State.action.bound
    public setPointOfCareId(pointOfCareId: PointOfCareId) {
        this.pointOfCareId = pointOfCareId;
    } 

    @State.action.bound
    public setPractitionerIds(practitionerIds: PractitionerId[]) {
        this.practitionerIds = practitionerIds;
    }

    @State.action.bound
    public setAdditionalPractitionerIds(practitionerIds: PractitionerId[]): void {
        this.additionalPractitionerIds = practitionerIds;
    }

    @State.action.bound
    public setAdditionalParticipants(additionalParticipants: string): void {
        this.additionalParticipants = additionalParticipants;
    }

    @State.action.bound
    public setReferral(referral: ReferralStore) {
        this.referral = referral;
    }

    @State.action.bound
    public setIntervalFrom(newValue: moment.Moment) {
        this.intervalFrom = newValue;
    }

    @State.action.bound
    public setIntervalTo(newValue: moment.Moment) {
        this.intervalTo = newValue;
    }

    @State.action.bound
    public setSlotsToBook(newValue: SlotStore[]) {
        this.slotsToBook = newValue;
    }

    @State.computed
    public get schedulingServiceId() {
        return this.subjectService instanceof SchedulingServiceSubject ?
            (this.subjectService as SchedulingServiceSubject).schedulingServiceId :
            null;
    }

    @State.computed
    public get serviceRequestId() {
        return this.subjectService instanceof ServiceRequestSubject ?
            (this.subjectService as ServiceRequestSubject).versionedId :
            null;
    }
    
    public getHashCode() {
        return this.id.value;
    }

    @State.computed
    public get interval() {
        return new MomentRange(this.intervalFrom, this.intervalTo);
    }

    @State.computed
    public get hasPractitionerOrPointOfCare() {
        return !isNullOrUndefined(this.pointOfCareId) || !arrayIsNullOrEmpty(this.practitionerIds);
    }
}
