import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import { mapPermissionScopesFromDto } from "@HisPlatform/BoundedContexts/WebAppBackend/FrontendActionMapperHelpers";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { IGuidance, IGuidanceStep } from "@HisPlatform/Packages/Guidance/Model/IGuidance";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import CareActivityId from "@Primitives/CareActivityId.g";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";

@Di.injectable()
export default class GuidanceApiAdapter extends ApiAdapterBase2 {

    constructor(
        @Di.inject("IGuidanceClient") private readonly apiClient: Proxy.IGuidanceClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader
    ) {
        super(referenceDataLoader);
    }

    public getGuidanceForCareActivityAsync(careActivityId: CareActivityId) {
        return this.executeOperationAsync<IGuidance, Proxy.GetGuidanceForCareActivityQueryResponse>(
            () => this.apiClient.getGuidanceForCareActivityQueryAsync(CreateRequestId(), careActivityId.value),
            response => Promise.resolve({
                steps: response.guidance.steps.map<IGuidanceStep>(step => ({
                    localizedDisplayName: new MultiLingualLabel(new Map(step.localizedDisplayName.map(ldn => [ldn.cultureCode.value, ldn.label]))),
                    stepId: step.stepId,
                    actionDescriptor: new ActionDescriptor(
                        step.actionDescriptor.action.frontendAction,
                        step.actionDescriptor.presentation,
                        mapPermissionScopesFromDto(step.actionDescriptor.requiredPermissionScopes)
                    ),
                    legacy_userCaseIdentifiers: step.lEGACY_UseCaseIdentifiers ?? null
                }))
            })
        );
    }
}