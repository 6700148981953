import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import HunCareReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/HunCareReferenceDataStore";

interface IInpatientAdmissionTypeByLegalAspectSelectBoxDependencies {
    careReferenceDataStore: HunCareReferenceDataStore;
}

interface IInpatientAdmissionTypeByLegalAspectSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IInpatientAdmissionTypeByLegalAspectSelectBoxDependencies;
}

@State.observer
class InpatientAdmissionTypeByLegalAspectSelectBox extends React.Component<IInpatientAdmissionTypeByLegalAspectSelectBoxProps> {

    private get dependencies() {
        return this.props._dependencies;
    }

    private get extensibleEnumStore() {
        return this.dependencies.careReferenceDataStore.inpatientAdmissionTypeByLegalAspectMap;
    }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
                clearable={false}
            />
        );
    }
}

export default connect(
    InpatientAdmissionTypeByLegalAspectSelectBox,
    new DependencyAdapter<IInpatientAdmissionTypeByLegalAspectSelectBoxProps, IInpatientAdmissionTypeByLegalAspectSelectBoxDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("HunCareReferenceDataStore")
        };
    })
);
