import { ISelectBoxProps } from "@CommonControls/SelectBox";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import * as Ui from "@CommonControls";
import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PermissionId from "@Primitives/PermissionId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import Permission from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/Permission";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import IPermissionDefinition from "@PluginInterface/OperationAccessControl/IPermissionDefinition";
import { getPermissionDefinitionName } from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/PermissionLocalizationHelper";

interface IPermissionDefinitionSelectBoxDependencies {
    localizationService: ILocalizationService;
    permissionDefinitionRegistry: IPermissionDefinitionRegistry;
}

interface IPermissionDefinitionSelectBoxProps extends ISelectBoxProps {
    _dependencies?: IPermissionDefinitionSelectBoxDependencies;
    excludedItems?: IPermissionDefinition[];
}

@State.observer
class PermissionDefinitionSelectBox extends React.Component<IPermissionDefinitionSelectBoxProps> {
    private get permissionDefinitionRegistry() {
        return this.props._dependencies.permissionDefinitionRegistry;
    }

    private get localizationService() {
        return this.props._dependencies.localizationService;
    }

    @State.computed
    private get items() {
        let items = this.permissionDefinitionRegistry.getAll();
        if (this.props.excludedItems) {
            items = items.filter(p => !this.props.excludedItems.some(i => ValueWrapper.equals(i.aliasedId, p.aliasedId)));
        }

        return items.map(this.toSelectBoxItem);
    }

    @State.bound
    private toSelectBoxItem(item: Permission) {
        const localizedName = getPermissionDefinitionName(this.localizationService, item);
        return {
            text: localizedName,
            value: item.aliasedId
        } as ISelectBoxItem<PermissionId>;
    }
    public render() {
        return (
            <Ui.SelectBox
                {...this.props}
                loading={!this.items}
                items={this.items}
            />
        );
    }
}

export default connect(
    PermissionDefinitionSelectBox,
    new DependencyAdapter<IPermissionDefinitionSelectBoxProps, IPermissionDefinitionSelectBoxDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
        permissionDefinitionRegistry: c.resolve("IPermissionDefinitionRegistry")
    }))
);