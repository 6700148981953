import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicationId from "@Primitives/MedicationId.g";
import * as Ui from "@CommonControls";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import ReferenceDataApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/ReferenceDataApiAdapter";
import MedicationSubsidy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationSubsidy";
import SelectItemClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/SelectItemClientSideAction";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import MedicationFilterStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/MedicationListPanel/Filter/MedicationFilterStore";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import { getSubsidyByClaimTypeId } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestHelpers";
import NDataGrid from "@HisPlatform/BoundedContexts/Productivity/Components/NDataGrid/NDataGrid";
import * as Styles from "./MedicationEquivalenceListNDataPanel.less";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import MedicationSubsidizedPricingTypeId from "@Primitives/MedicationSubsidizedPricingTypeId.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { IRowIndicatorStyle } from "@CommonControls/DataGrid/IDataGridProps";
import { getMedicationRowStyle } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/MedicationListPanel/MedicationRowStylesHelper";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";

interface IMedicationEquivalenceListNDataPanelDependencies {
    referenceDataAdapter: ReferenceDataApiAdapter;
    worklistApiAdapter: WorklistApiAdapter;
    referenceDataStore: MedicationRequestReferenceDataStore;
}

interface IMedicationEquivalenceListNDataPanelProps {
    _dependencies?: IMedicationEquivalenceListNDataPanelDependencies;
    medicationId: MedicationId;
    claimTypeId: MedicationSubsidyClaimTypeId;
    subsidies: MedicationSubsidy[];

    onSelectMedication: (medicationId: MedicationId) => void;

    selectedRowId: string;
    onRowClick: (row: any, rowId: React.ReactText, rowIndex: number) => void;
    useCaseState: INDataUseCaseState;
    onChange: (rowId: string, useCaseState: INDataUseCaseState) => void;

    validOn: LocalDate;
}

enum EquivalenceFilterType {
    SupportGroupId,
    EquivalenceId
}

@State.observer
class MedicationEquivalenceListNDataPanel extends React.Component<IMedicationEquivalenceListNDataPanelProps> {

    private get referenceDataAdapter() { return this.props._dependencies.referenceDataAdapter; }
    private get apiAdapter() { return this.props._dependencies.worklistApiAdapter; }
    private get referenceDataStore() { return this.props._dependencies.referenceDataStore; }

    @State.observable public filterType: EquivalenceFilterType = EquivalenceFilterType.SupportGroupId;
    @State.observable.ref public equivalenceOriginalId: number = null;

    @State.observable private definition: IWorklistDefinition = null;
    @State.observable.ref private filterStore: MedicationFilterStore = null;

    @State.observable private data: any = null;

    @State.action.bound
    private setData(newValue: any) {
        this.data = newValue;
    }

    @State.computed private get supportGroupId() {
        return this.getSelectedSubsidySupportGroupId(this.props);
    }

    private getSelectedSubsidySupportGroupId(props: IMedicationEquivalenceListNDataPanelProps) {
        const subsidy = getSubsidyByClaimTypeId(this.referenceDataStore, props.subsidies, props.claimTypeId);
        return subsidy?.supportGroupdId;
    }

    @State.computed
    private get hasNormativeSubsidy() {
        return this.getHasNormativeSubsidy(this.props);
    }

    @State.computed
    private get isFilterTypeSupportGroupId() {
        return this.filterType === EquivalenceFilterType.SupportGroupId;
    }

    @State.computed
    private get isFilterTypeEquivalenceId() {
        return this.filterType === EquivalenceFilterType.EquivalenceId;
    }

    @State.computed
    private get isSupportGroupFilterWithMissingClaimType() {
        return this.isFilterTypeSupportGroupId && !this.props.claimTypeId;
    }

    @State.computed
    private get isSupportGroupFilterWithMissingGroupId() {
        return this.isFilterTypeSupportGroupId && this.props.claimTypeId && !this.supportGroupId;
    }

    @State.computed
    private get isValidSupportGroupFilter() {
        return this.isFilterTypeSupportGroupId && this.props.claimTypeId && this.supportGroupId;
    }

    @State.computed
    private get isEquivalenceFilterWithMissingEquivalenceId() {
        return this.isFilterTypeEquivalenceId && !this.equivalenceOriginalId;
    }

    @State.computed
    private get isValidEquivalenceFilter() {
        return this.isFilterTypeEquivalenceId && this.equivalenceOriginalId;
    }

    private getHasNormativeSubsidy(props: IMedicationEquivalenceListNDataPanelProps) {
        return props.subsidies?.some(i => ValueWrapper.equals(i.pricingType, MedicationSubsidizedPricingTypeId.N));
    }

    @State.action.bound
    private async loadDefinitionAsync() {
        const response = await this.apiAdapter.getMedicationBoundWorklistDefinition();
        State.runInAction(() => {
            this.definition = response.value;
        });
    }

    @State.action.bound
    public setFilterType(filterType: EquivalenceFilterType) {
        this.filterType = filterType;
        this.setFilters();
    }

    @State.action.bound
    public setEquivalenceOriginalId(equivalenceId: number) {
        this.equivalenceOriginalId = equivalenceId;
    }

    @State.bound
    private async loadEquivalenceAsync() {
        if (this.props.medicationId) {
            const response = await this.referenceDataAdapter.getMedicationEquivalenceOriginalIdAsync(this.props.medicationId);
            this.setEquivalenceOriginalId(response.value);
            this.setFilters();
        }
    }

    @State.bound
    private loadMedicationAsync() {
        if (this.props.medicationId) {
            this.setFilters();
        }
        return Promise.resolve();
    }

    @State.bound
    private async loadReferenceDataAsync() {
        await this.referenceDataStore.medicationSubsidyClaimTypes.ensureAllLoadedAsync();
    }

    @State.bound
    private performClientSideActionAsync(action: ClientSideActionDto) {
        if (action instanceof SelectItemClientSideAction) {
            this.props.onSelectMedication(new MedicationId(action.entityId.value));
        }
        return Promise.resolve(null);
    }

    @State.action.bound
    private onFilterStoreCreatedAsync(filterStore: IFilterStore) {
        this.filterStore = new MedicationFilterStore(filterStore);
        this.setFilters();
        return Promise.resolve();
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadDefinitionAsync(), this);
        dispatchAsyncErrors(this.loadEquivalenceAsync(), this);
        dispatchAsyncErrors(this.loadMedicationAsync(), this);
        dispatchAsyncErrors(this.loadReferenceDataAsync(), this);
        if (this.supportGroupId) {
            this.setFilters();
        }
    }

    @State.action.bound
    private setFilters() {
        if (this.filterType === EquivalenceFilterType.EquivalenceId) {
            this.filterStore?.setEquivalenceId(this.equivalenceOriginalId);
            this.filterStore?.setIsNormative(this.hasNormativeSubsidy);
            this.filterStore?.setSupportGroupId(null);
        } else { // this.filterType === EquivalenceFilterType.SupportGroupId
            this.filterStore?.setSupportGroupId(this.supportGroupId);
            this.filterStore?.setIsNormative(null);
            this.filterStore?.setEquivalenceId(null);
        }
        this.filterStore?.setMedicationSubsidyClaimTypeIdForClassification(this.props.claimTypeId);
    }

    @State.computed private get defaultOrderingColumnName() {
        return this.getDefaultOrderingColumnName(this.props);
    }

    @State.bound
    private getDefaultOrderingColumnName(props: IMedicationEquivalenceListNDataPanelProps) {
        const claimType = this.referenceDataStore.medicationSubsidyClaimTypes.get(props.claimTypeId);

        if (this.filterType === EquivalenceFilterType.SupportGroupId) {
            switch (claimType?.code) {
                case "N":
                    return "MedicationBased_NormativeDailyTherapyCost";
                case "EE":
                    return "MedicationBased_EuEmDailyTherapyCost";
                case "EK":
                    return "MedicationBased_EuKiemDailyTherapyCost";
            }
        } else { // this.filterType === EquivalenceFilterType.EquivalenceId
            return "MedicationBased_DailyTherapyCost";
        }
        return null;
    }

    private getFilterDescriptors() {
        return MedicationFilterStore.getFilterDescriptors();
    }

    public componentDidUpdate(prevProps: IMedicationEquivalenceListNDataPanelProps) {
        if (!ValueWrapper.equals(prevProps.medicationId, this.props.medicationId)) {
            dispatchAsyncErrors(this.loadEquivalenceAsync(), this);
        }
        if ((this.supportGroupId && this.getSelectedSubsidySupportGroupId(prevProps) !== this.supportGroupId)
            || (this.hasNormativeSubsidy !== this.getHasNormativeSubsidy(prevProps))
            || (!ValueWrapper.equals(prevProps.claimTypeId, this.props.claimTypeId))) {
            this.setFilters();
        }
    }

    @State.bound
    private getRowIndicatorStyle(row: any): IRowIndicatorStyle {
        if (this.filterStore.medicationSubsidyClaimTypeIdForClassification) {
            return getMedicationRowStyle(row["MedicationBased_MedicationSubsidyClassification"], Styles.rowBold);
        }
        return null;
    }

    @State.bound
    private renderGrid() {
        return (
            <NDataGrid
                definition={this.definition}
                defaultExtraFilterVisibility={true}
                useCaseState={this.props.useCaseState}
                onChange={this.props.onChange}
                selectedRowId={this.props.selectedRowId}
                onRowClick={this.props.onRowClick}
                defaultPageSize={10}
                onGetExtendedFilterDescriptors={this.getFilterDescriptors}
                onFilterStoreCreatedAsync={this.onFilterStoreCreatedAsync}
                onPerformClientSideActionAsync={this.performClientSideActionAsync}
                onDataLoaded={this.setData}
                data={this.data}
                statePersisterSettings={{ isEnabled: false }}
                getRowIndicatorStyle={this.getRowIndicatorStyle}
                horizontalScroll
                actionsOnLeftSide
                defaultOrderingColumnName={this.defaultOrderingColumnName}
                orderingEnabled={false}
                referenceDate={this.props.validOn}
            />
        );
    }

    public render() {
        return (
            <>
                <Ui.Flex>
                    <Ui.Flex.Item>
                        <Ui.UniversalEnumSelector
                            enumOrigin="server"
                            displayMode="groupedRadioButtons"
                            enumType={EquivalenceFilterType}
                            enumName={"EquivalenceFilterType"}
                            value={this.filterType}
                            onChange={this.setFilterType}
                            automationId="equivalenceFilterTypeSelector"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    {this.isSupportGroupFilterWithMissingClaimType && (
                        <Ui.Message className={Styles.message} type="info">{StaticHunSocialSecurityMedicationRequestResources.MedicationEquivalencePanel.Messages.ChooseClaimTypeMessage}</Ui.Message>
                    )}
                    {this.isSupportGroupFilterWithMissingGroupId && (
                        <Ui.Message className={Styles.message} type="info">{StaticHunSocialSecurityMedicationRequestResources.MedicationEquivalencePanel.Messages.NoSupportGroupMessage}</Ui.Message>
                    )}
                    {this.isEquivalenceFilterWithMissingEquivalenceId && (
                        <Ui.Message className={Styles.message} type="info">{StaticHunSocialSecurityMedicationRequestResources.MedicationEquivalencePanel.Messages.NoEquivalenceIdMessage}</Ui.Message>
                    )}
                    {((this.isValidSupportGroupFilter)
                        || (this.isValidEquivalenceFilter))
                        && this.definition && (// this is a dirty trick, but need we to rerender the grid when the default ordering changes
                        <>
                            {this.filterType === EquivalenceFilterType.EquivalenceId && this.renderGrid()} 
                            {this.filterType === EquivalenceFilterType.SupportGroupId && this.renderGrid()}
                        </>
                    )}
                </Ui.Flex>
            </>
        );
    }
}

export default connect(
    MedicationEquivalenceListNDataPanel,
    new DependencyAdapter<IMedicationEquivalenceListNDataPanelProps, IMedicationEquivalenceListNDataPanelDependencies>(c => ({
        referenceDataAdapter: c.resolve("ReferenceDataApiAdapter"),
        worklistApiAdapter: c.resolve("WorklistApiAdapter"),
        referenceDataStore: c.resolve("MedicationRequestReferenceDataStore"),
    }))
);