import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import { mapToNameDto } from "@HisPlatform/Common/PersonNameMapper";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import PractitionerBaseDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerBaseDataStore";
import PractitionerAddressStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerAddressStore";
import PractitionerTelecomStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerTelecomStore";
import PractitionerIdentifierStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerIdentifierStore";
import PractitionerProfessionalExamStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerProfessionalExamStore";

export function getPractitionerBaseDataDto(store: PractitionerBaseDataStore) {
    return new Proxy.PractitionerBaseDataDto({
        name: mapToNameDto(Proxy.PersonName, store.name),
        birthName: store.hasBirthName ? mapToNameDto(Proxy.PersonName, store.birthName) : null,
        genderId: store.genderId,
        mothersName: store.mothersName,
        notes: store.notes,
    });
}

export function getAddressDto(store: PractitionerAddressStore) {
    return new Proxy.PractitionerAddressDto({
        address: new Proxy.AddressDto({
            addressLine: store.addressLine,
            countryId: store.countryId,
            settlement: store.settlement,
            zipCode: store.zipCode
        }),
        addressTypeId: store.addressTypeId,
        validity: new LocalDateRange(store.validFrom, store.validTo)
    });
}

export function getTelecomDto(store: PractitionerTelecomStore) {
    return new Proxy.PractitionerTelecomDto({
        telecomTypeId: store.telecomTypeId,
        telecomUseId: store.telecomUseId,
        value: store.value
    });
}

export function getIdentifierDto(store: PractitionerIdentifierStore) {
    return new Proxy.PractitionerIdentifierDto({
        identifier: store.identifier,
        name: store.name
    });
}

export function getProfessionDto(store: PractitionerProfessionalExamStore) {
    return new Proxy.PractitionerProfessionalExamDto({
        isExpired: store.isExpired,
        professionalExamId: store.professionalExamId
    });
}

export function getAddPractitionerDto(store: PractitionerStore, hasUser: boolean) {
    return new Proxy.AddPractitionerControllerDto({
        baseData: getPractitionerBaseDataDto(store.baseData),
        addresses: store.addresses.map(getAddressDto) || [],
        telecoms: store.telecoms.map(getTelecomDto) || [],
        identifiers: store.identifiers.filter(i => !i.isEmpty).map(getIdentifierDto) || [],
        userId: (!store.userId || store.userId.value === "new") ? null : store.userId,
        validity: LocalDateRange.Unbound,
        jobPositionId: store.jobPositionId,
        practitionerProfessionalExams: store.professionalExams.map(getProfessionDto),
        hasUser: hasUser
    });
}

export function getUpdatePractitionerDto(store: PractitionerStore, hasUser: boolean) {
    return new Proxy.UpdatePractitionerControllerDto({
        practitionerId: store.id,
        baseData: getPractitionerBaseDataDto(store.baseData),
        addresses: store.addresses.map(getAddressDto) || [],
        telecoms: store.telecoms.map(getTelecomDto) || [],
        identifiers: store.identifiers.map(getIdentifierDto) || [],
        userId: store.userId,
        validity: LocalDateRange.Unbound,
        jobPositionId: store.jobPositionId,
        practitionerProfessionalExams: store.professionalExams.map(getProfessionDto),
        hasUser: hasUser
    });
}
