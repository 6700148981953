import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

export interface IPropertyIdentifiers {
    entityName: string;
    entityId?: string;
    entityPath?: string;
}

export default class ValidationResultsBuilder {
    private _validationResults: IClientValidationResult[] = [];

    @State.bound
    public clearProblemsForProperty(propertyIdentifiers: IPropertyIdentifiers, clearCheckedRules: boolean = false) {
        const { entityName, entityId } = propertyIdentifiers;
        const entityPath = propertyIdentifiers.entityPath ?? "";

        const existing = this._validationResults.find(r => this.isValidationResultMatch(r, entityName, entityId));

        if (existing) {
            existing.problems = [...existing.problems.filter(p => p.propertyPath !== entityPath)];

            if (clearCheckedRules) {
                existing.checkedRules = [...existing.checkedRules.filter(p => p.propertyPath !== entityPath)];
            }
        }
    }

    @State.bound
    public addCheckedRule(propertyIdentifiers: IPropertyIdentifiers, ruleId: string, severity: "error" | "warning") {

        const { entityName, entityId } = propertyIdentifiers;
        const entityPath = propertyIdentifiers.entityPath ?? "";

        const result = this.getOrCreateValidationResult(entityName, entityId);

        result.checkedRules.push({
            propertyPath: entityPath,
            ruleId,
            severity
        });

    }

    @State.bound
    public addProblem(params: IPropertyIdentifiers, ruleId: string, severity: "error" | "warning", parameters?: object, resourceGroup?: string, message?: string) {

        const { entityName, entityId } = params;
        const entityPath = params.entityPath ?? "";

        const result = this.getOrCreateValidationResult(entityName, entityId);

        const messageOrResourceId = message ??
            (resourceGroup ? `!${resourceGroup}.${entityPath}${entityPath ? "." : ""}${ruleId}` : `${entityPath}${entityPath ? "." : ""}${ruleId}`);

        result.problems.push({
            propertyPath: entityPath,
            ruleId,
            message: messageOrResourceId,
            severity,
            parameters
        });

    }

    public getValidationResults(): IClientValidationResult[] {
        return this._validationResults;
    }

    public getValidationResultsClone(): IClientValidationResult[] {
        return [
            ...this._validationResults.map(r => ({
                checkedRules: [...r.checkedRules],
                entityId: r.entityId,
                entityName: r.entityName,
                problems: [...r.problems]
            } as IClientValidationResult))
        ];
    }

    private getOrCreateValidationResult(entityName: string, entityId: string) {
        let existing = this._validationResults.find(r => this.isValidationResultMatch(r, entityName, entityId));

        if (!existing) {
            existing = {
                entityId,
                entityName,
                problems: [],
                checkedRules: []
            };
            this._validationResults.push(existing);
        }

        return existing;
    }

    private isValidationResultMatch(r: IClientValidationResult, entityName: string, entityId?: string) {
        return r.entityName === entityName && (r.entityId === entityId || entityId === undefined);
    }
}