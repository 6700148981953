exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MedicationListPanel_row-bold_3TiFI {\n  font-weight: 600;\n}\n", ""]);

// exports
exports.locals = {
	"row-bold": "MedicationListPanel_row-bold_3TiFI",
	"rowBold": "MedicationListPanel_row-bold_3TiFI"
};