import React from "react";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Button from "@CommonControls/Button";
import DocumentManagementListPanel from "./DocumentManagementListPanel";
import DocumentManagementDetailPanel from "./DocumentManagementDetailPanel";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import ScopeIdentifier from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ScopeIdentifier";
import CareActivityId from "@Primitives/CareActivityId.g";
import PatientId from "@Primitives/PatientId.g";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import DocumentInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentInfo";
import { arrayIsNullOrEmpty, isNullOrUndefined, nullFunction } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import CreateNewDocumentDialogParams, { ICreateNewDocumentDialogResult } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/CreateNewDocumentDialog/CreateNewDocumentDialogParams";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import DocumentId from "@Primitives/DocumentId.g";
import DocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/DocumentApiAdapter";
import BinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/BinaryDocument";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import TemplateApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Templating/TemplateApiAdapter";
import TemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TemplateBasedDocument";
import DocumentBase from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentBase";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import DocumentManagementReferenceDataStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentManagementReferenceDataStore";
import * as Ui from "@CommonControls";
import TemplateBasedDocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/TemplateBasedDocumentApiAdapter";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import * as HisUi from "@HisPlatformControls";
import DocumentPreviewModalParams from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Modals/DocumentPreviewModal/DocumentPreviewModalParams";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import _ from "@HisPlatform/Common/Lodash";
import BinaryDocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/BinaryDocumentApiAdapter";
import { DocumentHistoryModalParams } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Modals/DocumentHistoryModal/DocumentHistoryModal";
import * as Styles from "./DocumentManagementMasterDetailPanel.less";
import ITemplateInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateInfo";
import DocumentTypesApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/ReferenceData/DocumentTypesApiAdapter";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import CareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CareActivityStore";
import { ITokenLockService } from "@HisPlatform/BoundedContexts/DocumentManagement/Services/Definition/ITokenLockService";
import TokenLockInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TokenLockInfo";
import { EntityLockState } from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import CareActivityDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentApiAdapter";
import PermissionCheckContext from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContext";
import PermissionCheckReactContext from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckReactContext";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import DocumentAction from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentAction.g";
import DocumentState from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentState.g";
import DocumentKind from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentKind.g";
import MasterDetailScreen from "@Toolkit/ReactClient/Components/Screen/MasterDetailScreen";
import MasterDetailMode from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Care/Enum/MasterDetailMode.g";

interface IDocumentManagementMasterDetailPanelDependencies {
    careDocumentApiAdapter: CareActivityDocumentApiAdapter;
    documentApiAdapter: DocumentApiAdapter;
    templateApiAdapter: TemplateApiAdapter;
    templateBasedDocumentApiAdapter: TemplateBasedDocumentApiAdapter;
    binaryDocumentApiAdapter: BinaryDocumentApiAdapter;
    documentTypesApiAdapter: DocumentTypesApiAdapter;
    localizationService: ILocalizationService;
    userManagementDataProviderStore: UserManagementDataProviderStore;
    documentManagementReferenceDataStore: DocumentManagementReferenceDataStore;
    fileSaverService: IFileSaverService;
    dialogService: IDialogService;
    notificationService: INotificationService;
    userContext: UserContext;
    tokenLockService: ITokenLockService;
    lockingApiAdapter: LockingApiAdapter;
}

interface IDocumentManagementMasterDetailPanelProps {
    _dependencies?: IDocumentManagementMasterDetailPanelDependencies;
    _modalService?: IModalService;
    _careActivity?: CareActivityStore;
    _careActivityId?: CareActivityId;
    _patientId?: PatientId;
    _careActivityPointOfCareId?: PointOfCareId;
    _careActivityState?: CareActivityState;
    selectedDocumentId: DocumentId;
    onSelectedDocumentIdChange: (newId: DocumentId) => void;
    readOnly?: boolean;
    careActivityRelatedDataOnly: boolean;
    isCareActivityRelated?: boolean;
    showPrimaryDocument?: boolean;
    hiddenMasterMode?: boolean;
}

@State.observer
class DocumentManagementMasterDetailPanel extends React.Component<IDocumentManagementMasterDetailPanelProps> {

    private get modalService() { return this.props._modalService!; }
    private get careDocumentApiAdapter() { return this.props._dependencies!.careDocumentApiAdapter; }
    private get documentApiAdapter() { return this.props._dependencies!.documentApiAdapter; }
    private get templateApiAdapter() { return this.props._dependencies!.templateApiAdapter; }
    private get templateBasedDocumentApiAdapter() { return this.props._dependencies!.templateBasedDocumentApiAdapter; }
    private get documentTypesApiAdapter() { return this.props._dependencies!.documentTypesApiAdapter; }
    private get localizationService() { return this.props._dependencies!.localizationService; }
    private get userManagementDataProviderStore() { return this.props._dependencies!.userManagementDataProviderStore; }
    private get fileSaverService() { return this.props._dependencies!.fileSaverService; }
    private get dialogService() { return this.props._dependencies.dialogService; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get tokenLockService() { return this.props._dependencies.tokenLockService; }
    private get lockingApiAdapter() { return this.props._dependencies.lockingApiAdapter; }
    private get careActivityDocumentApiAdapter() { return this.props._dependencies.careDocumentApiAdapter; }

    @State.observable.ref private isLoading = false;
    @State.observable.ref private showDeleted: boolean = false;
    @State.observable.ref private _documentInfoList: IPagedItems<DocumentInfo> = { items: [], totalCount: 0 };
    @State.observable.ref private currentDocument: DocumentBase = null;
    private downloadDocumentAfterSelect = false;

    @State.observable.ref private paging: IPagingState = { currentPage: 0, pageSize: 20 };

    @State.observable.ref private ordering: IOrderingState = { columnId: "Name", direction: "asc" };
    @State.observable.ref private newTemplateBasedDocument: TemplateBasedDocument = null;
    @State.observable.ref private templateBasedDocumentValidationResults: IClientValidationResult[] = null;
    @State.observable.ref private customValidationProblems: IClientValidationProblem[] = [];
    @State.observable private permissionCheckContextStore = new PermissionCheckContext();
    @State.observable.ref private documentEditorCacheKey = 0;

    private readonly panelResources = StaticDocumentManagementResources.DocumentManagementMasterDetailPanel;

    private contentSnapshot: string = null;
    private previousDocumentAction: DocumentAction;

    @State.computed private get permissionCheckedOperations() {
        const res = {};

        if (!!this.props.selectedDocumentId && this.props.selectedDocumentId?.value !== "new") {
            res["PrintPreview"] = async () => await this.documentApiAdapter.getDocumentPreviewAsync(this.props.selectedDocumentId, true);
            res["UpdateDocument"] = async () => await this.templateBasedDocumentApiAdapter.updateTemplateBasedDocumentPermissionCheckAsync(this.props.selectedDocumentId, DocumentAction.DataRefresh);
            res["FinalizeOrEditDocument"] = async () => await this.templateBasedDocumentApiAdapter.updateTemplateBasedDocumentPermissionCheckAsync(this.props.selectedDocumentId, DocumentAction.Finalize);
            res["PublishDocument"] = async () => await this.templateBasedDocumentApiAdapter.updateTemplateBasedDocumentPermissionCheckAsync(this.props.selectedDocumentId, DocumentAction.Publish);
            res["Delete"] = async () => await this.templateBasedDocumentApiAdapter.updateTemplateBasedDocumentPermissionCheckAsync(this.props.selectedDocumentId, DocumentAction.Delete);
        }

        if (this.props._careActivityId) {
            res["Save"] = async () => await this.careActivityDocumentApiAdapter.saveNewCareActivityRelatedTemplateBasedDocumentPermissionCheckAsync(this.props._careActivityId);
        }

        return res;
    }

    @State.computed private get currentDocumentStatusDisplayValue() {
        if (!this.currentDocument) {
            return "";
        } else if (this.currentDocument.isNew) {
            return this.panelResources.NewDocumentState;
        }

        return this.localizationService.localizeEnum(DocumentState[this.currentDocument.info.state], "DocumentState")?.Name;
    }

    private get isDetailOpen() {
        return !!this.props.selectedDocumentId;
    }

    private get showPrimaryDocument() {
        return false; // isNullOrUndefined(this.props.showPrimaryDocument) || this.props.showPrimaryDocument === true;
    }

    @State.computed private get documentInfoList(): IPagedItems<DocumentInfo> {
        return {
            items: this.newTemplateBasedDocument ? [this.newTemplateBasedDocument.info, ...this._documentInfoList.items] : this._documentInfoList.items,
            totalCount: this.newTemplateBasedDocument ? this._documentInfoList.totalCount + 1 : this._documentInfoList.totalCount,
        };
    }

    @State.computed private get scopeIdentifiers(): ScopeIdentifier[] {
        const identifiers = [];
        if (this.props._careActivityId) {
            identifiers.push(ScopeIdentifier.createCareActivityScopeIdentifier(this.props._careActivityId!));
        }
        if (this.props._patientId) {
            identifiers.push(ScopeIdentifier.createPatientScopeIdentifier(this.props._patientId!));
        }
        if (this.props._careActivityPointOfCareId) {
            identifiers.push(ScopeIdentifier.createOrganizationScopeIdentifier(this.props._careActivityPointOfCareId!));
        }
        return identifiers;
    }

    private get isDirty() {
        return !!this.currentDocument &&
            (this.currentDocument instanceof TemplateBasedDocument &&
                (this.currentDocument?.isNew || this.currentDocument.documentFile.contentStore.getContent() !== this.contentSnapshot));
    }

    @State.computed private get hasPrimaryItemInList(): boolean {
        return this.documentInfoList.items.some(d => d.isPrimary);
    }

    @State.computed private get hasNewItemInList(): boolean {
        return this.documentInfoList.items.some(d => d.isNew);
    }

    @State.computed private get isReadOnlyDocument(): boolean {
        return !!this.currentDocument &&
            (this.props._careActivityState === CareActivityState.Closed ||
                this.hasAnyRequiredAndNotHeldTokenLock() ||
                this.currentDocument?.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockNotHeld);
    }

    private hasAnyRequiredAndNotHeldTokenLock(): boolean {
        return !!this.currentDocument &&
            (this.currentDocument instanceof TemplateBasedDocument &&
                (this.currentDocument.tokenLockInfos.some(it => !it.isNew && it.acquiredLockInfo?.lockState === EntityLockState.LockingRequiredAndLockNotHeld)));
    }

    private get documentTypeStore() {
        return this.props._dependencies.documentManagementReferenceDataStore.documentTypeMap;
    }
    private get canCreateNewDocument() {
        return !this.props.readOnly
            && (this.props._careActivityState === CareActivityState.InProgress
                || this.props._careActivityState === CareActivityState.Discharged)
            && (this.permissionCheckContextStore.checkedPermissions.find(p => p.operationName === "Save")?.isAuthorized ?? true);
    }

    @State.computed get validationProblems() {
        const validationResult = _.flatten(this.templateBasedDocumentValidationResults?.map(i => i.problems));
        const textBlockValidationResults = validationResult.filter(i => i.ruleId === "TextBlockContentShouldNotBeEmpty");

        if (textBlockValidationResults.length > 0) {
            const message = formatStringWithObjectParams(this.panelResources.CustomValidationMessages.TextBlockContentShouldNotBeEmpty, {
                TextBlocks: textBlockValidationResults.map(i => i.resolvedParameters.TextBlock).join(", ").toLowerCase()
            });

            const textBlockValidationResult = this.createCustomValidationProblem(textBlockValidationResults[0].severity, message);

            let finalizeValidationProblem = null;
            if (this.previousDocumentAction === DocumentAction.Finalize) {
                finalizeValidationProblem = this.createCustomValidationProblem("error", this.panelResources.CustomValidationMessages.FinalizeValidationMessage);
                return [finalizeValidationProblem, ...validationResult.filter(i => i.ruleId !== "TextBlockContentShouldNotBeEmpty"), textBlockValidationResult];
            } else {
                return [...validationResult.filter(i => i.ruleId !== "TextBlockContentShouldNotBeEmpty"), textBlockValidationResult];
            }
        }

        return validationResult;
    }
    @State.action.bound
    public async changeShowDeletedAsync() {
        this.showDeleted = !this.showDeleted;
        await this.loadListAsync(this.ordering, this.paging);
    }

    private setTheSelectedIdToPrimaryDocument() {
        for (const item of this.documentInfoList.items) {
            if (item.isPrimary) {
                this.props.onSelectedDocumentIdChange(item.id);
                return;
            }
        }
    }

    @State.bound
    private clearSelectedDocument() {
        this.props.onSelectedDocumentIdChange(null);
    }

    public render() {

        if (!this.props.readOnly && this.initializePanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={this.panelResources.Documents} />;
        }

        return (
            <>
                <PermissionCheckReactContext.Provider value={this.permissionCheckContextStore}>
                    <MasterDetailScreen
                        title={this.panelResources.Documents}
                        iconName="editDocument"
                        toolbar={this.renderMasterToolbar()}
                        isSeparatedMode={this.props.hiddenMasterMode}
                        legacyIsDetailOpen={this.isDetailOpen}
                        legacyDetailContent={this.isDetailOpen && (
                            <MasterDetail.Detail
                                title={this.currentDocument?.info.name ?? this.panelResources.Document}
                                subTitle={this.currentDocumentStatusDisplayValue}
                                toolbar={this.renderDetailToolbar()}
                                onClose={this.clearSelectedDocument}
                            >
                                <>
                                    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <div style={{ flexGrow: 1, flexShrink: 0, flexBasis: "auto" }}>
                                            <Ui.ValidationResultSummary results={this.validationProblems} />
                                        </div>
                                        <div style={{ flexGrow: 3, flexShrink: 3, flexBasis: "100%", overflow: "hidden" }}>
                                            <DocumentManagementDetailPanel
                                                key={this.currentDocument?.id.value ?? "null"}
                                                documentEditorCacheKey={this.documentEditorCacheKey}
                                                printPermissionCheckOperationName="PrintPreview"
                                                permissionCheckOperationNames="Save"
                                                permissionDeniedStyle="disabled"
                                                document={this.currentDocument}
                                                isReadOnly={this.props.readOnly || this.isReadOnlyDocument || (this.currentDocument?.isNew === false && !this.currentDocument?.info?.possibleActions?.some(a => a === DocumentAction.Save))}
                                            />
                                        </div>
                                    </div>
                                </>
                            </MasterDetail.Detail>
                        )}
                    >
                        <DocumentManagementListPanel
                            documents={this.documentInfoList}
                            onLoadListAsync={this.loadListAsync}
                            selectedId={this.props.selectedDocumentId}
                            onSelect={this.props.onSelectedDocumentIdChange}
                            ordering={this.ordering}
                            paging={this.paging}
                            onDownloadAsync={this.downloadAsync}
                            readOnly={this.props.readOnly}
                            customValidationProblems={this.customValidationProblems}
                        />
                    </MasterDetailScreen>
                </PermissionCheckReactContext.Provider>
                <NavigateAwayHook onNavigateAwayAsync={this.confirmNavigationWithoutSaveAsync} />
            </>
        );
    }

    @State.bound
    public async confirmNavigationWithoutSaveAsync(): Promise<boolean> {
        if (this.isDirty) {
            const dialogResult = await this.dialogService.confirmIfNotSaved(this.panelResources.Message.SaveBeforeNavigationConfirmationTitle,
                this.panelResources.Message.SaveBeforeNavigationConfirmationMessage);

            switch (dialogResult.resultCode) {
                case DialogResultCode.Yes:
                    await this.saveTemplateBasedDocumentAsync();
                    await this.releaseLockAsync();
                    await this.loadListAsync(this.ordering, this.paging);
                    return true;
                case DialogResultCode.No:
                    this.setNewTemplateBasedDocumentState(null);
                    await this.releaseLockAsync();
                    return true;
                case DialogResultCode.Cancel:
                case DialogResultCode.None:
                default:
                    return false;
            }
        }
        await this.releaseLockAsync();
        return true;
    }

    @State.bound
    private async releaseLockAsync() {
        if (this.currentDocument?.lockInfo?.lockId) {
            const lockId = this.currentDocument.lockInfo.lockId;
            this.currentDocument.releaseLock();
            await this.lockingApiAdapter.releaseLockAsync(lockId);
        }
        if (this.currentDocument instanceof TemplateBasedDocument) {
            for (const lock of this.currentDocument.tokenLockInfos) {
                if (!lock.isNew && lock.acquiredLockInfo?.lockId) {
                    const lockId = lock.acquiredLockInfo.lockId;
                    lock.releaseLock();
                    await this.lockingApiAdapter.releaseLockAsync(lockId);
                }
            }
        }
    }

    @State.bound
    private async forceReleaseLockAsync() {
        if (this.currentDocument?.lockInfo?.preventingLockId) {
            await this.lockingApiAdapter.forceReleaseLockAsync(this.currentDocument.lockInfo.preventingLockId);
        }
        if (this.currentDocument instanceof TemplateBasedDocument) {
            for (const lock of this.currentDocument.tokenLockInfos) {
                if (lock.acquiredLockInfo?.preventingLockId) {
                    await this.lockingApiAdapter.forceReleaseLockAsync(lock.acquiredLockInfo.preventingLockId);
                }
            }
        }
    }

    @State.bound
    private renderDetailToolbar() {
        if (!this.props.readOnly) {
            return (
                <>
                    {this.currentDocument?.info.kind === DocumentKind.TemplateBased && this.renderTemplatingButtonsByState()}
                    {this.renderContextMenu()}
                </>
            );
        } else {
            return (
                <>
                    <Ui.Button
                        permissionCheckOperationNames="PrintPreview"
                        permissionDeniedStyle="disabled"
                        iconName="print"
                        text={this.panelResources.Button.PrintPreview}
                        onClickAsync={this.onPreviewAsync}
                        disabled={!this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.Print)}
                        automationId="printButton"
                    />
                    <Ui.Button
                        iconName="document_history"
                        text={this.panelResources.Button.ViewHistory}
                        onClickAsync={this.onDocumentHistoryAsync}
                        disabled={!this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.ViewHistory)}
                        automationId="viewHistoryButton"
                    />
                </>
            );
        }
    }

    @State.bound
    private renderMasterToolbar() {
        return (
            <Ui.Flex xsJustify="end" verticalSpacing="none" className={Styles.headerContainer}>
                {!this.isDetailOpen && <Ui.Flex.Item>
                    <Ui.CheckBox
                        verticalAlign="noPadding"
                        className={Styles.headerButton}
                        label={StaticWebAppResources.Common.Button.ShowDeleted}
                        value={this.showDeleted}
                        labelPosition={"right"}
                        onChange={this.changeShowDeletedAsync}
                        displayMode="switch"
                        automationId="showDeletedCheckBox" />

                </Ui.Flex.Item>}
                {!this.props.readOnly &&
                    <Ui.Flex.Item>
                        <Button
                            className={Styles.headerButton}
                            iconName="plus"
                            text={this.panelResources.NewDocument}
                            onClickAsync={this.createNewDocumentAsync}
                            disabled={this.hasNewItemInList || !this.canCreateNewDocument}
                            automationId="createNewDocument"
                            permissionDeniedStyle="disabled"
                            permissionCheckOperationNames="Save" />
                    </Ui.Flex.Item>}
            </Ui.Flex>
        );
    }

    @State.bound
    private renderTemplatingButtonsByState() {
        const buttons = [];
        let key = 0;

        if (this.currentDocument.isNew) {
            buttons.push(
                <Button
                    key={key++}
                    text={this.panelResources.Discard}
                    permissionCheckOperationNames="Save"
                    permissionDeniedStyle="invisible"
                    onClickAsync={this.discardNewTemplateBasedDocumentAsync} automationId="discardButton"
                    iconName="close_x"
                />
            );
        }

        const templatingDocument = this.currentDocument as TemplateBasedDocument;
        buttons.push(
            <HisUi.LockIndicatorComponent
                key={key++}
                locked={!templatingDocument?.isMutable}
                lockedBy={templatingDocument?.lockInfo?.preventingLockUserId}
                onEditClickedAsync={this.onForceReleaseLockAsync}
                permissionCheckOperationNames="Save" />
        );

        buttons.push(
            <Button
                permissionCheckOperationNames="UpdateDocument"
                permissionDeniedStyle="disabled"
                key={key++}
                text={this.panelResources.Button.RefreshTemplateBasedDocument}
                disabled={(this.currentDocument?.isNew === true || !this.currentDocument.info.possibleActions.some(a => a === DocumentAction.DataRefresh))}
                onClickAsync={this.refreshTemplateBasedDocumentAsync}
                automationId="refreshButton"
                iconName="sync" />
        );

        buttons.push(
            <Button
                permissionCheckOperationNames="PrintPreview"
                permissionDeniedStyle="disabled"
                key={key++}
                text={this.panelResources.Button.PrintPreview}
                disabled={!this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.Print)}
                onClickAsync={this.onPreviewAsync}
                automationId="printPreviewButton" 
                iconName="print" />
        );

        if (this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.Publish)) {
            buttons.push(
                <Ui.Button
                    permissionCheckOperationNames="PublishDocument"
                    permissionDeniedStyle="disabled"
                    key={key++}
                    iconName="checkDouble"
                    text={this.panelResources.Button.Publish}
                    onClickAsync={this.executePublishAsync}
                    disabled={this.isReadOnlyDocument}
                    automationId="publishButton"
                />
            );
        }

        if (this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.Finalize)) {
            buttons.push(
                <Ui.Button
                    permissionCheckOperationNames="FinalizeOrEditDocument"
                    permissionDeniedStyle="disabled"
                    key={key++}
                    iconName="checkDouble"
                    text={this.panelResources.Button.Finalize}
                    onClickAsync={this.executeFinalizeAsync}
                    disabled={this.isReadOnlyDocument}
                    automationId="finalizeButton"
                />
            );
        }

        buttons.push(
            <Ui.SaveButton
                permissionCheckOperationNames="Save"
                permissionDeniedStyle="disabled"
                onClickAsync={this.saveTemplateBasedDocumentAndBackAsync}
                disabled={this.isReadOnlyDocument || (this.currentDocument?.isNew === false && !this.currentDocument?.info?.possibleActions?.some(a => a === DocumentAction.Save))}
                key={key++}
                automationId="saveDocument"
            />);

        buttons.push(
            <Ui.ContextMenu.Provider key={key++} id="document_actions_menu" event="onClick">
                <Ui.Button
                    key={key++}
                    iconName="more"
                    onClick={nullFunction}
                    tooltipContent={StaticWebAppResources.Common.Button.FurtherActions}
                    tooltipPosition="bottom"
                    disabled={false}
                    automationId="moreButton"
                />
            </Ui.ContextMenu.Provider>
        );

        return buttons;
    }

    @State.bound
    private renderContextMenu() {
        return (
            <Ui.ContextMenu id="document_actions_menu" key="document_actions_menu">

                <Ui.ContextMenu.Item onClickAsync={this.refreshTemplateBasedDocumentAsync}
                    permissionCheckOperationNames="UpdateDocument"
                    permissionDeniedStyle="disabled"
                    disabled={(this.currentDocument?.isNew === true || !this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.DataRefresh))}>
                    <SpanWithIcon iconName="sync" >{this.panelResources.Button.RefreshTemplateBasedDocument}</SpanWithIcon>
                </Ui.ContextMenu.Item>

                <Ui.ContextMenu.Item onClickAsync={this.saveTemplateBasedDocumentAndBackAsync}
                    permissionCheckOperationNames="Save"
                    permissionDeniedStyle="disabled"
                    disabled={this.isReadOnlyDocument || (this.currentDocument?.isNew === false && !this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.Save))}>
                    <SpanWithIcon iconName="check" >{StaticWebAppResources.Common.Button.Save}</SpanWithIcon>
                </Ui.ContextMenu.Item>

                <Ui.ContextMenu.Item onClickAsync={this.executePublishAsync}
                    permissionCheckOperationNames="PublishDocument"
                    permissionDeniedStyle="disabled"
                    disabled={this.isReadOnlyDocument || !this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.Publish)} >
                    <SpanWithIcon iconName="checkDouble" >{this.panelResources.Button.Publish}</SpanWithIcon>
                </Ui.ContextMenu.Item>

                <Ui.ContextMenu.Item onClickAsync={this.executeCancelPublishAsync}
                    permissionCheckOperationNames="PublishDocument"
                    permissionDeniedStyle="disabled"
                    disabled={this.isReadOnlyDocument || !this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.CancelPublish)} >
                    <SpanWithIcon iconName="circleArrowCcw" >{this.panelResources.Button.CancelPublish}</SpanWithIcon>
                </Ui.ContextMenu.Item>

                <Ui.ContextMenu.Item onClickAsync={this.executeFinalizeAsync}
                    permissionCheckOperationNames="FinalizeOrEditDocument"
                    permissionDeniedStyle="disabled"
                    disabled={this.isReadOnlyDocument || !this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.Finalize)} >
                    <SpanWithIcon iconName="checkDouble" >{this.panelResources.Button.Finalize}</SpanWithIcon>
                </Ui.ContextMenu.Item>

                <Ui.ContextMenu.Item onClickAsync={this.executeEditAsync}
                    permissionCheckOperationNames="FinalizeOrEditDocument"
                    permissionDeniedStyle="disabled"
                    disabled={this.props._careActivityState === CareActivityState.Closed || !this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.Edit)} >
                    <SpanWithIcon iconName="circleArrowCcw" >{this.panelResources.Button.EditDocument}</SpanWithIcon>
                </Ui.ContextMenu.Item>

                <Ui.ContextMenu.Separator />

                <Ui.ContextMenu.Item onClickAsync={this.onDocumentHistoryAsync}
                    disabled={!this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.ViewHistory)} >
                    <SpanWithIcon iconName="document_history" >{this.panelResources.Button.ViewHistory}</SpanWithIcon>
                </Ui.ContextMenu.Item>

                <Ui.ContextMenu.Item onClickAsync={this.executeDeleteAsync}
                    permissionCheckOperationNames="Delete"
                    permissionDeniedStyle="disabled"
                    disabled={this.isReadOnlyDocument || !this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.Delete)} >
                    <SpanWithIcon iconName="trash" >{this.panelResources.Button.Delete}</SpanWithIcon>
                </Ui.ContextMenu.Item>
            </Ui.ContextMenu>
        );
    }

    @State.bound
    private async discardNewTemplateBasedDocumentAsync() {
        const dialogResult = await this.dialogService.yesNoCancel(this.panelResources.Message.DiscardChangesConfirmationTitle,
            this.panelResources.Message.DiscardChangesConfirmationMessage);

        if (dialogResult.resultCode === DialogResultCode.Yes) {
            this.setDetailState(null);
            this.setNewTemplateBasedDocumentState(null);
            this.props.onSelectedDocumentIdChange(null);
        }
    }

    @State.bound
    private async saveTemplateBasedDocumentAsync(documentAction?: DocumentAction, releaseLockIfSuccessful: boolean = false) {
        this.previousDocumentAction = documentAction;
        const templateDocument = this.currentDocument as TemplateBasedDocument;

        if (templateDocument.dataChangedSinceLastRefresh && templateDocument.dataChangedSinceLastRefresh.length > 0) {
            const changedSymbols = templateDocument.dataChangedSinceLastRefresh
                .map(it => this.localizationService.localizeReferenceDataWithDefault(it.nameResourceId, it.symbol)).join(", ");
            const message = formatStringWithObjectParams(this.panelResources.Message.TextBlocksChangedNotificationOnSaveMessage, {
                ChangedSymbols: changedSymbols
            });
            this.notificationService.warning(message);
        }

        // Updating text blocks of the document with changes occured during single page editing
        await this.tokenLockService.updateLockSourcesAsync(templateDocument.tokenLockInfos,
            templateDocument.documentFile, this.scopeIdentifiers, releaseLockIfSuccessful);

        // currently we ignore silently any problems when updating text blocks based on document changes. Is this the right way to do? 
        const validationResults = _.flatten(templateDocument.tokenLockInfos.map(it => it.validationResults));

        let result;
        if (this.currentDocument.isNew) {
            result = await this.careActivityDocumentApiAdapter.saveNewCareActivityRelatedTemplateBasedDocumentAsync(templateDocument, this.props._careActivityId);
            if (result.isPersistedByOperationInfo) {
                this.setNewTemplateBasedDocumentState(null);
            }
        } else {
            result = await this.templateBasedDocumentApiAdapter.updateTemplateBasedDocumentAsync(templateDocument, releaseLockIfSuccessful, documentAction);
        }

        if (!releaseLockIfSuccessful) {
            result.value.documentInfo.lockInfo = templateDocument.info.lockInfo;
        }

        this.contentSnapshot = await templateDocument.documentFile.contentStore.getContentAsync();

        return result;
    }

    @State.bound
    private async refreshTemplateBasedDocumentAsync() {
        const templateDocument = this.currentDocument as TemplateBasedDocument;

        if (templateDocument != null) {
            const currentDocumentInfo = this.documentInfoList.items.find(d => d.id.value === this.currentDocument.id.value);
            const scopeIdentifiers = currentDocumentInfo?.scopeIdentifiers ?? this.scopeIdentifiers;

            const result = await this.templateApiAdapter.executeTemplateAsync(
                templateDocument.templateContentToken, scopeIdentifiers, templateDocument.documentFile);

            if (result.value.hasChanges) {
                await this.acquireTokenLocksAsync(result.value.lockInfos);
            }

            State.runInAction(() => {
                if (result.value.hasChanges) {
                    templateDocument.documentFile = result.value.content;
                    templateDocument.tokenLockInfos = result.value.lockInfos;
                }
                this.documentEditorCacheKey++;
                templateDocument.dataChangedSinceLastRefresh = [];
            });
        }
    }

    @State.bound
    private async executePublishAsync() {
        await this.executeDocumentActionAsync(DocumentAction.Publish);
    }

    @State.bound
    private async executeCancelPublishAsync() {
        await this.executeDocumentActionAsync(DocumentAction.CancelPublish);
    }

    @State.bound
    private async executeFinalizeAsync() {
        await this.executeDocumentActionAsync(DocumentAction.Finalize);
    }

    @State.bound
    private async executeDeleteAsync() {
        const dialogResult = await this.dialogService.yesNoCancel(this.panelResources.Message.DeleteConfirmationTitle,
            this.panelResources.Message.DeleteConfirmationMessage);

        if (dialogResult.resultCode === DialogResultCode.Yes) {
            await this.executeDocumentActionAsync(DocumentAction.Delete);
        }
    }

    @State.bound
    private async executeEditAsync() {
        const answer = await this.confirmCancelFinalizeAsync();
        if (answer) { await this.executeDocumentActionAsync(DocumentAction.Edit); }
    }

    @State.bound
    private async executeDocumentActionAsync(documentAction: DocumentAction) {
        if (this.currentDocument instanceof BinaryDocument) {
            await this.saveBinaryDocumentAndRefreshAsync(documentAction);
        } else {
            await this.saveTemplateBasedDocumentAndRefreshAsync(documentAction);
        }
        if (DocumentAction.Delete === documentAction) {
            this.props.onSelectedDocumentIdChange(null);
        }
    }

    @State.bound
    private async confirmCancelFinalizeAsync(): Promise<boolean> {
        const dialogResult = await this.dialogService.yesNoCancel(this.panelResources.Message.CancelFinalizeConfirmationTitle,
            this.panelResources.Message.CancelFinalizeConfirmationMessage);

        switch (dialogResult.resultCode) {
            case DialogResultCode.Yes:
                return true;
            case DialogResultCode.No:
            case DialogResultCode.Cancel:
            case DialogResultCode.None:
            default:
                return false;
        }
    }

    @State.bound
    private async saveTemplateBasedDocumentAndBackAsync() {
        await this.saveTemplateBasedDocumentAndRefreshAsync();
    }

    @State.bound
    private async saveBinaryDocumentAndRefreshAsync(documentAction?: DocumentAction) {
        const result = await this.saveTemplateBasedDocumentAsync(documentAction);

        this.onSelectedDocumentInfoChange(result.value.documentInfo);
        await this.loadListAsync(this.ordering, this.paging);
    }

    @State.bound
    private async saveTemplateBasedDocumentAndRefreshAsync(documentAction?: DocumentAction) {
        const result = await this.saveTemplateBasedDocumentAsync(documentAction);

        this.setTemplateBasedDocumentValidationResults(result.value.validationResult);

        const hasValidationProblems = this.validationProblems.length > 0;
        this.notificationService.showSaveResult(result.isPersistedByOperationInfo, hasValidationProblems);

        if (this.currentDocument?.isNew && result.isPersistedByOperationInfo) {
            this.setDetailState(null);
        }

        if (result.isPersistedByOperationInfo) {
            this.onSelectedDocumentInfoChange(result.value.documentInfo);
        }

        await this.loadListAsync(this.ordering, this.paging);
    }

    @State.action
    private setTemplateBasedDocumentValidationResults(validationResult: IClientValidationResult[]) {
        this.templateBasedDocumentValidationResults = validationResult;
    }

    @State.bound
    private async onForceReleaseLockAsync() {
        await this.loadPanelAsync(true);
    }

    @State.action
    private onSelectedDocumentInfoChange(documentInfo: DocumentInfo) {
        if (!!this.currentDocument) {
            this.currentDocument.info = documentInfo;
        }
        this.props.onSelectedDocumentIdChange(documentInfo.id);
    }

    @State.bound
    private downloadAsync(doc: DocumentInfo) {
        if (!ValueWrapper.equals(doc.id, this.currentDocument?.id)) {
            this.downloadDocumentAfterSelect = true;
            this.props.onSelectedDocumentIdChange(doc.id);
        } else if (this.currentDocument instanceof BinaryDocument) {
            this.downloadDocumentContent(this.currentDocument as BinaryDocument);
        }
        return Promise.resolve();
    }

    private readonly initializePanelAsync = createInitialPanelLoader(this._initializePanelAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initializePanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IDocumentManagementMasterDetailPanelProps) {
        if (!ValueWrapper.equals(this.props.selectedDocumentId, prevProps.selectedDocumentId)) {
            dispatchAsyncErrors(this.loadPanelAsync(), this);
            if (prevProps.selectedDocumentId?.value !== "new") {
                this.setTemplateBasedDocumentValidationResults(null);
            }
        }
    }

    @State.bound
    private async loadReferenceDataAsync() {
        await this.documentTypeStore.ensureAllLoadedAsync();
    }

    @State.bound
    private async _initializePanelAsync() {
        await this.loadReferenceDataAsync();
        if (this.props.selectedDocumentId?.value === "new") {
            this.props.onSelectedDocumentIdChange(null);
        } else {
            await this.loadPanelAsync();
        }
        await this.loadListAsync(this.ordering, this.paging);

        if (this.canCreateNewDocument && (arrayIsNullOrEmpty(this.documentInfoList.items) || !this.hasPrimaryItemInList)) {
            this.createPrimaryTemplateAsync();
        } else if (this.hasPrimaryItemInList && this.showPrimaryDocument) {
            this.setTheSelectedIdToPrimaryDocument();
        }
    }

    @State.boundLoadingState()
    private async loadListAsync(ordering: IOrderingState, paging: IPagingState) {
        let result: PagedItemStore<DocumentInfo>;
        if (this.props.careActivityRelatedDataOnly) {
            result = await this.careDocumentApiAdapter.getCareActivityDocumentListAsync(this.props._careActivityId, ordering, paging, this.showDeleted);
        } else if (this.props._patientId) {
            result = await this.careDocumentApiAdapter.getPatientDocumentListAsync(this.props._patientId, this.props.isCareActivityRelated, ordering, paging, this.showDeleted);
        }

        await this.userManagementDataProviderStore.users.ensureLoadedAsync([
            ...result.items.map(i => i.createdById),
            ...result.items.map(i => i.lastModifiedById),
        ]);

        this.setListState(result, ordering, paging);
    }

    @State.action
    private setListState(results: PagedItemStore<DocumentInfo>, ordering: IOrderingState, paging: IPagingState) {
        this.paging = paging;
        this.ordering = ordering;
        this._documentInfoList = results;
    }
    @State.bound
    private createNewTemplateBasedDocumentAsync(templateInfo: ITemplateInfo) {
        const newTemplateBasedDocument = new TemplateBasedDocument(true);
        newTemplateBasedDocument.info = new DocumentInfo(
            true,
            new DocumentId("new"),
            this.scopeIdentifiers,
            templateInfo.documentTypeId,
            DocumentKind.TemplateBased,
            this.props._dependencies.userContext.id,
            DateTimeService.now(),
            this.props._dependencies.userContext.id,
            DateTimeService.now(),
            DocumentState.Draft,
            this.documentTypeStore.get(templateInfo.documentTypeId).name,
            [],
            templateInfo.isPrimary
        );

        this.setNewTemplateBasedDocumentState(newTemplateBasedDocument);

        this.props.onSelectedDocumentIdChange(newTemplateBasedDocument.id);

        return Promise.resolve(newTemplateBasedDocument);
    }
    @State.bound
    private async createNewDocumentAsync() {
        const dialogResult = await this.modalService.showDialogAsync<ICreateNewDocumentDialogResult>(new CreateNewDocumentDialogParams("CareActivity", null));

        if (dialogResult?.documentKind === DocumentKind.TemplateBased) {
            const templateInfoResult = await this.templateApiAdapter.getByIdsAsync([dialogResult.templateId]);
            const templateResultValue = templateInfoResult.value[0];
            const templateWithHeaderFooterResult = await this.templateApiAdapter.getTemplateWithHeaderFooterAsync(templateResultValue.contentId, this.props._careActivityPointOfCareId);

            const result = await this.templateApiAdapter.executeTemplateAsync(templateWithHeaderFooterResult.value, this.scopeIdentifiers, null);
            const newTemplateBasedDocument = await this.createNewTemplateBasedDocumentAsync(templateResultValue);

            newTemplateBasedDocument.description = dialogResult.description;
            newTemplateBasedDocument.documentFile = result.value.content;
            newTemplateBasedDocument.templateId = dialogResult.templateId;
            newTemplateBasedDocument.templateContentToken = templateWithHeaderFooterResult.value;
            newTemplateBasedDocument.tokenLockInfos = result.value.lockInfos;

            await this.acquireTokenLocksAsync(newTemplateBasedDocument.tokenLockInfos);

            this.setNewTemplateBasedDocumentState(newTemplateBasedDocument);

            this.props.onSelectedDocumentIdChange(newTemplateBasedDocument.id);
        }

        await this.loadListAsync(this.ordering, this.paging);
    }

    @State.action
    private async acquireTokenLocksAsync(locks: TokenLockInfo[]) {
        const success = await this.tokenLockService.acquireLocksAsync(locks);
        if (!success) {
            const missingLocks = locks.filter(it => it.acquiredLockInfo?.lockState === EntityLockState.LockingRequiredAndLockNotHeld);
            let lockDetails = "";
            for (const missingLock of missingLocks) {
                lockDetails += `${missingLock.sourceDescription} (${missingLock.acquiredLockInfo?.preventingLockOwnerName})\n`;
            }

            const message = formatStringWithObjectParams(this.panelResources.Message.TextBlocksLocked, {
                LockInfo: lockDetails
            });
            this.notificationService.warning(message);
        }
    }

    @State.action
    private setNewTemplateBasedDocumentState(doc: TemplateBasedDocument) {
        this.newTemplateBasedDocument = doc;
    }

    @State.bound
    private async createPrimaryTemplateAsync() {

        if (!this.props._careActivityPointOfCareId) {
            return;
        }

        const availablePrimaryTemplates = await this.templateApiAdapter.getAvailablePrimaryTemplatesQueryAsync(this.props._careActivityPointOfCareId);
        if (arrayIsNullOrEmpty(availablePrimaryTemplates.value)) {
            return;
        }
        const templateInfo = availablePrimaryTemplates.value[0];
        if (isNullOrUndefined(templateInfo)) {
            return;
        }
        const templateWithHeaderFooterResult = await this.templateApiAdapter.getTemplateWithHeaderFooterAsync(templateInfo.contentId, this.props._careActivityPointOfCareId);
        const result = await this.templateApiAdapter.executeTemplateAsync(templateWithHeaderFooterResult.value, this.scopeIdentifiers, null);
        const newTemplateBasedDocument = await this.createNewTemplateBasedDocumentAsync(templateInfo);

        newTemplateBasedDocument.documentFile = result.value.content;
        newTemplateBasedDocument.templateId = templateInfo.id;
        newTemplateBasedDocument.templateContentToken = templateWithHeaderFooterResult.value;
        newTemplateBasedDocument.tokenLockInfos = result.value.lockInfos;

        await this.acquireTokenLocksAsync(newTemplateBasedDocument.tokenLockInfos);

        this.setNewTemplateBasedDocumentState(newTemplateBasedDocument);

        this.props.onSelectedDocumentIdChange(newTemplateBasedDocument.id);

        await this.loadListAsync(this.ordering, this.paging);
    }

    @State.boundLoadingState()
    private async loadPanelAsync(forceReleaseLock: boolean = false) {

        await this.permissionCheckContextStore.checkPermissionsAsync(this.permissionCheckedOperations);

        if (forceReleaseLock) {
            await this.forceReleaseLockAsync();
        }

        await this.loadCustomValidationProblemsAsync();

        if (!!this.props.selectedDocumentId) {
            if (this.props.selectedDocumentId?.value !== "new") {
                let doc: DocumentBase;
                if (this.props.careActivityRelatedDataOnly) {
                    const res =
                        await this.careDocumentApiAdapter.getCareActivityDocumentById(this.props.selectedDocumentId, this.props._careActivityId, true);
                    doc = res.value;
                } else {
                    const res =
                        await this.careActivityDocumentApiAdapter.getEhrDocumentByIdAsync(this.props.selectedDocumentId);
                    doc = res.value;
                }
                if (doc instanceof TemplateBasedDocument) {
                    await this.acquireTokenLocksAsync(doc.tokenLockInfos);
                }

                this.processActions(doc);
                this.setDetailState(doc);

                if (this.currentDocument instanceof TemplateBasedDocument) {
                    this.contentSnapshot = await this.currentDocument.documentFile.contentStore.getContentAsync();
                    if (this.currentDocument.dataChangedSinceLastRefresh && this.currentDocument.dataChangedSinceLastRefresh.length > 0) {
                        const changedSymbols = this.currentDocument.dataChangedSinceLastRefresh
                            .map(it => this.localizationService.localizeReferenceDataWithDefault(it.nameResourceId, it.symbol)).join(", ");
                        const message = formatStringWithObjectParams(this.panelResources.Message.TextBlocksChangedMessage, {
                            ChangedSymbols: changedSymbols
                        });
                        this.notificationService.warning(message);
                    }
                }

            } else {
                // Reacquire locks if needed
                if (forceReleaseLock) {
                    await this.acquireTokenLocksAsync(this.newTemplateBasedDocument.tokenLockInfos);
                }
                this.setDetailState(this.newTemplateBasedDocument);
                this.contentSnapshot = null;
            }
        } else {
            this.setDetailState(null);
        }
    }

    @State.bound
    private async loadCustomValidationProblemsAsync() {
        if (!this.props._careActivityId || this.props.readOnly) {
            return;
        }

        const validationProblems: IClientValidationProblem[] = [];

        if (this.props._careActivityPointOfCareId) {
            const notCreatedRequiredDocumentTypes = await this.documentTypesApiAdapter.getNotCreatedRequiredDocumentTypesAsync(this.scopeIdentifiers);
            if (notCreatedRequiredDocumentTypes.value.length > 0) {

                const message = formatStringWithObjectParams(this.panelResources.CustomValidationMessages.NotAllRequiredDocumentsAreCreated, {
                    DocumentTypes: notCreatedRequiredDocumentTypes.value.map(i => i.name).join(", ").toLowerCase()
                });

                validationProblems.push(this.createCustomValidationProblem("warning", message));
            }
        }

        this.setCustomValidationProblems(validationProblems);
    }

    @State.action
    private setDetailState(doc: DocumentBase) {
        this.currentDocument = doc;
    }

    private processActions(doc: DocumentBase) {
        if (doc instanceof BinaryDocument && this.downloadDocumentAfterSelect) {
            this.downloadDocumentContent(doc);
            this.downloadDocumentAfterSelect = false;
        }
    }

    private downloadDocumentContent(doc: BinaryDocument) {
        this.fileSaverService.saveAs(doc.content, doc.info.name);
    }

    @State.bound
    private async onPreviewAsync() {
        await this.modalService.showModalAsync(new DocumentPreviewModalParams(this.currentDocument.id));
    }

    @State.bound
    private async onDocumentHistoryAsync() {
        await this.modalService.showDialogAsync(new DocumentHistoryModalParams(this.currentDocument));
    }

    private createCustomValidationProblem(severity: "error" | "warning", rawMessage: string): IClientValidationProblem {
        return {
            message: null,
            rawMessage: rawMessage,
            severity: severity
        };
    }

    @State.action.bound
    public setCustomValidationProblems(validationProblems: IClientValidationProblem[]) {
        this.customValidationProblems = validationProblems;
    }
}

export default connect(
    DocumentManagementMasterDetailPanel,
    new DependencyAdapter<IDocumentManagementMasterDetailPanelProps, IDocumentManagementMasterDetailPanelDependencies>(c => ({
        documentApiAdapter: c.resolve("DocumentApiAdapter"),
        templateApiAdapter: c.resolve("TemplateApiAdapter"),
        templateBasedDocumentApiAdapter: c.resolve("TemplateBasedDocumentApiAdapter"),
        binaryDocumentApiAdapter: c.resolve("BinaryDocumentApiAdapter"),
        documentTypesApiAdapter: c.resolve("DocumentTypesApiAdapter"),
        localizationService: c.resolve("ILocalizationService"),
        userManagementDataProviderStore: c.resolve("UserManagementDataProviderStore"),
        documentManagementReferenceDataStore: c.resolve<DocumentManagementReferenceDataStore>("DocumentManagementReferenceDataStore"),
        fileSaverService: c.resolve("IFileSaverService"),
        dialogService: c.resolve("IDialogService"),
        userContext: c.resolve("UserContext"),
        notificationService: c.resolve("INotificationService"),
        tokenLockService: c.resolve("ITokenLockService"),
        lockingApiAdapter: c.resolve("LockingApiAdapter"),
        careDocumentApiAdapter: c.resolve("CareActivityDocumentApiAdapter")
    })),
    new HisModalServiceAdapter(),
    new CareActivityContextAdapter<IDocumentManagementMasterDetailPanelProps>(c => ({
        _careActivityId: c.careActivityId,
        _careActivityPointOfCareId: c.careActivity?.pointOfCareId,
        _careActivityState: c.careActivity?.state
    })),
    new PatientContextAdapter<IDocumentManagementMasterDetailPanelProps>(c => ({
        _patientId: c.patientId
    })),
);
