import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import * as Ui from "@CommonControls";
import SchedulingServiceSelectBox from "@HisPlatform/BoundedContexts/Scheduling/Components/Controls/SchedulingServiceSelectBox/SchedulingServiceSelectBox";
import AppointmentFilterStore from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/DoctorPointOfCareListPanel/AppointmentFilterStore";
import PointOfCareSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareSelectBox";
import PractitionerSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerSelectBox";
import PractitionerId from "@Primitives/PractitionerId.g";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";

interface IAppointmentParticipantPanelViewProps {
    readonly: boolean;
    setLoadingState?: (state: boolean) => void;
    filterStore: AppointmentFilterStore;
    onDoctorIdsChanged: (doctorIds: PractitionerId[]) => void;
    onSchedulingServiceChanged: (schedulingServiceId: SchedulingServiceId) => void;
    onPointOfCareIdsChanged: (pointOfCareIds: PointOfCareId[]) => void;
    explicitIds: any;
    isServiceRequestSubject: boolean;
}

@State.observer
export default class AppointmentParticipantPanelView extends React.Component<IAppointmentParticipantPanelViewProps> {

    public render() {
        return (
            <Ui.Flex>
                {!this.props.isServiceRequestSubject && <Ui.Flex.Item xs={4} style={{ paddingTop: 10 }}>
                    <SchedulingServiceSelectBox
                        propertyIdentifier={"Subject"}
                        value={this.props.filterStore.schedulingServiceId}
                        multiSelect={false}
                        label={StaticSchedulingResources.AppointmentParticipantPanel.SubjectService}
                        onChange={this.props.onSchedulingServiceChanged}
                        isReadOnly={this.props.readonly}
                        explicitIds={this.props.explicitIds.explicitSchedulingServiceIds}
                        setLoadingState={this.props.setLoadingState}
                        automationId="_schedulingServiceSelectBox"
                    />
                </Ui.Flex.Item>}
                <Ui.Flex.Item xs={8}>
                    <Ui.Flex style={{ marginTop: 10 }}>
                        <Ui.Flex.Item xs={6}>
                            <PointOfCareSelectBox
                                label={StaticSchedulingResources.DoctorPointOfCareList.Workplaces}
                                value={this.props.filterStore.pointOfCareIds}
                                onChange={this.props.onPointOfCareIdsChanged}
                                explicitIds={this.props.explicitIds.explicitPointOfCareIds}
                                isReadOnly={this.props.readonly}
                                automationId="__schedulingPointOfCareSelectBox"
                                multiSelect
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            <PractitionerSelectBox
                                label={StaticSchedulingResources.DoctorPointOfCareList.Practitioners}
                                onChange={this.props.onDoctorIdsChanged}
                                value={this.props.filterStore.doctorIds}
                                explicitIds={this.props.explicitIds.explicitPractitionerIds}
                                isReadOnly={this.props.readonly}
                                automationId="__schedulingPractitionerSelectBox"
                                multiSelect
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }
}
