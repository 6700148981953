import * as React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import * as HisUi from "@HisPlatformControls";
import {IOrderingState, IPagingState} from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import {IUniversalCodeSelectorProps} from "@HisPlatformControls/UniversalCodeSelector";
import EhiHealthcareProviderId from "@Primitives/EhiHealthcareProviderId.g";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import IEhiHealthcareProviderVersion from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IEhiHealthcareProviderVersion";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface IEhiHealthcareProviderSelectorDependencies {
    referenceDataStore: HunEhiCareReferenceDataStore;
    apiAdapter: EhiCareApiAdapter;
}

interface IEhiHealthcareProviderSelectorProps extends ICodeSelectorCommonProps<EhiHealthcareProviderId> {
    _dependencies?: IEhiHealthcareProviderSelectorDependencies;
}

@State.observer
class EhiHealthcareProviderSelector extends React.Component<IEhiHealthcareProviderSelectorProps> {
    public static defaultProps: Partial<IEhiHealthcareProviderSelectorProps> = {
        maxResultCount: 10
    };

    private get validOn() {
        return this.props.validOn || LocalDate.today();
    }

    @State.bound
    private async getDisplayValueAsync(value: EhiHealthcareProviderId) {
        const versionSelector = { id: value, validOn: this.validOn };
        const item = await this.dependencies.referenceDataStore.ehiHealthcareProviders.getOrLoadAsync(versionSelector);
        return <><b>{item.code}</b>{` ${item.name}`}</>;
    }

    @State.bound
    private async getTextValueAsync(value: EhiHealthcareProviderId) {
        const versionSelector = { id: value, validOn: this.validOn };
        const item = await this.dependencies.referenceDataStore.ehiHealthcareProviders.getOrLoadAsync(versionSelector);
        return `${item.code} ${item.name}`;
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const result = await this.dependencies.apiAdapter.searchEhiHealthcareProviderIdsAsync(
            text,
            this.props.maxResultCount,
            this.props.validOn
        );

        if (result.operationWasSuccessful) {
            await this.dependencies.referenceDataStore.ehiHealthcareProviders.ensureLoadedAsync(result.value);
            return result.value.map(i => i.id);
        }

        return [];
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: IEhiHealthcareProviderVersion[]): Promise<IPagedItems<IEhiHealthcareProviderVersion>> {
        const customOrdering = {
            columnId: "code",
            direction: "asc"
        } as IOrderingState;
        const results = await this.dependencies.apiAdapter.searchEhiHealthcareProvidersAsync(filterText, customOrdering, paging);

        return {
            totalCount: results.totalCount,
            items: results.items
        };
    }

    @State.bound
    private onComplexSearchSingleSelect(item: IEhiHealthcareProviderVersion) {
        this.props.onChange(item.id);
    }

    @State.bound
    private onComplexSearchMultiSelect(items: IEhiHealthcareProviderVersion[]) {
        this.props.onChange(items.map(item => item.id));
    }

    public render() {

        const props = {
            ...this.props,
            quickSearchMinimumCharacters: 2,
            getDisplayValueAsync: this.getDisplayValueAsync,
            getTextValueAsync: this.getTextValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            codeGetter: "code",
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect,
        } as IUniversalCodeSelectorProps<EhiHealthcareProviderId, IEhiHealthcareProviderVersion>;

        return (
            <HisUi.UniversalCodeSelector
                {...props}
                automationId={this.props.automationId}
            />
        );
    }

    private get dependencies() {
        return this.props._dependencies;
    }
}

export default connect(
    EhiHealthcareProviderSelector,
    new DependencyAdapter<IEhiHealthcareProviderSelectorProps, IEhiHealthcareProviderSelectorDependencies>((container) => {
        return {
            apiAdapter: container.resolve("EhiCareApiAdapter"),
            referenceDataStore: container.resolve("HunEhiCareReferenceDataStore"),
        };
    })
);
