import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import UseCases from "@Primitives/UseCases";
import OutpatientTreatmentRoutes from "@HisPlatform/Components/Pages/OutpatientTreatment/OutpatientTreatmentRoutes";
import ServiceRequestPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestPanel/ServiceRequestPanel";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import ServiceAdministrationPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceAdministrationPanel/ServiceAdministrationPanel";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import { getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import CareActivityId from "@Primitives/CareActivityId.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";

export default function configureServiceRequestManagementUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            UseCases.careRegisterCreateServiceRequests,
            builder => {
                builder.standalone(b => b
                    .routeDefinition(OutpatientTreatmentRoutes.servicesRequests)
                    .additionalRouteParams({
                        filter: "CareActivity",
                        mode: "edit"
                    })
                );
            }
        )
    );

    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            UseCases.serviceRequestManagementAdministrateServiceRequest,
            builder => {
                builder.standalone(b => b.routeFactory((params) => {
                    const serviceRequestId = params.useCaseArguments.find(item => item.value instanceof ServiceRequestId);
                    const careActivityId = params.useCaseArguments.find(item => item.value instanceof CareActivityId);
                    return OutpatientTreatmentRoutes.servicesRequests.makeRoute({
                        filter: "CareActivity",
                        mode: "edit",
                        careActivityId: careActivityId?.value.value.toString(),
                        selectedId: serviceRequestId?.value.value.toString(),
                        useCase: getUseCaseAsUrlParam(
                            new UseCaseIdentifier(UseCases.serviceRequestManagementAdministrateServiceRequest),
                            UseCaseDisplayMode.MasterDetail,
                            [new UseCaseArgument(serviceRequestId.value, "serviceRequestId")])
                    });
                }));
            }
        )
    );

    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            UseCases.careRegisterViewServiceRequests,
            builder => {
                builder.standalone(b => b
                    .routeDefinition(OutpatientTreatmentRoutes.servicesRequests)
                    .additionalRouteParams({
                        filter: "CareActivity",
                        mode: "read-only"
                    })
                );
            }
        )
    );

    useCaseRegistry.addScreenAdapter(UseCases.serviceRequestManagementAdmitPatientWithServiceRequest, (identifier, args) => {
        return new ActionDescriptor(
            new ShowNewAdmitPatientScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "patientId")?.value,
                args.find(a => a.name === "appointmentScheduleEntryId")?.value,
                args.find(a => a.name === "serviceRequestId")?.value),
            null,
            []
        );
    });

    useCaseRegistry.add2({
        identifiers: [UseCases.serviceRequestManagementViewServiceRequest],
        component: ServiceRequestPanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));

            return {
                serviceRequestId: map.get("serviceRequestId"),
                serviceRequestDefinitionVersionSelector: EntityVersionSelector.parseFromUrl(map.get("definitionId"), ServiceRequestDefinitionId),
                serviceRequestDefinitionDirection: ServiceRequestDirection[map.get("definitionDirection") as string],
            };
        }
    });

    useCaseRegistry.add2({
        identifiers: [UseCases.serviceRequestManagementAdministrateServiceRequest],
        component: ServiceAdministrationPanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));

            return {
                serviceRequestId: map.get("serviceRequestId"),
            };
        }
    });
}
