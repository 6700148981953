import IFormDefinitionListItem from "@Toolkit/FormEngine/Model/IFormDefinitionListItem";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import FormSchemaVersion from "@Toolkit/FormEngine/Model/Schema/FormSchemaVersion";
import FormLayoutVersionStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutVersionStore";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import FormSchemaId from "@Toolkit/FormEngine/Model/Primitives/FormSchemaId.g";
import FormLayoutId from "@Toolkit/FormEngine/Model/Primitives/FormLayoutId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import IFormListItem from "@Toolkit/FormEngine/Model/IFormListItem";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import { IFormDataStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormDataStore";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import IFormScopes from "@Toolkit/FormEngine/Panels/FormPanel/IFormScopes";
import IFormToken from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/IFormToken";
import FormDefinitionDescriptor from "@Toolkit/FormEngine/Model/FormDefinitionDescriptor";
import IFormEnumsInDefinition from "@Toolkit/FormEngine/Model/IFormEnumsInDefinition";
import AggregateRootSchema from "@Toolkit/FormEngine/Model/Schema/AggregateRootSchema";
import FormEnumStore from "@Toolkit/FormEngine/Model/Schema/FormEnumStore";
import FormEditorChange from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/FormEditorChange";
import FormEditBehavior from "@Toolkit/FormEngine/Model/FormEditBehavior";
import FormLogicType from "@Primitives/FormLogicType";
import FormLogicToken from "@Toolkit/FormEngine/Model/FormLogicToken";
import FormLogic from "@Toolkit/FormEngine/Model/FormLogic";
import { IFormDefinitionVersion } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorHandle";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";


export const FormEngineApiAdapterFactorySymbol = Symbol.for("FormEngineApiAdapterFactory");

export default interface IFormEngineApiAdapter {
    getFormDefinitionListAsync(baseEntityType: string, onlyUserForms: boolean): Promise<SimpleStore<PagedItemStore<IFormDefinitionListItem>>>;
    getFormDefinitionsAsync(baseEntityType: string, onlyUserForms: boolean): Promise<SimpleStore<IFormDefinition[]>>;
    checkPermissionForFormDefinitionListAsync(): Promise<SimpleStore>;
    getFormDefinitionDetailAsync(id: FormDefinitionId): Promise<SimpleStore<IFormDefinition>>;
    createFormDefinitionAsync(baseEntityType: string, name: string, multiLingualDisplayName: MultiLingualLabel, rootSchemaVersion: FormSchemaVersion, formEnums: FormEnumStore[], rootLayoutVersion: FormLayoutVersionStore, formEditBehavior: FormEditBehavior, formLogicType: FormLogicType, formLogicTokens: FormLogicToken[], formLogics: FormLogic[], withoutDataHandling: boolean, isUserForm: boolean, instanceLimit: number): Promise<SimpleStore<IFormDefinition>>;
    updateFormDefinitionAsync(id: FormDefinitionId, rowVersion: RowVersion, multiLingualDisplayName: MultiLingualLabel, rootSchemaId: FormSchemaId, rootSchemaVersion: FormSchemaVersion, formEnums: FormEnumStore[], rootLayoutId: FormLayoutId, rootLayoutVersion: FormLayoutVersionStore, formEditorChanges: FormEditorChange[], formEditBehavior: FormEditBehavior, formLogicType: FormLogicType, formLogics: FormLogic[], formLogicTokens: FormLogicToken[], withoutDataHandling: boolean, isUserForm: boolean, instanceLimit: number): Promise<SimpleStore<IFormDefinition>>;
    getFormDefinitionExportData(formDefinition: IFormDefinition, actualVersion: IFormDefinitionVersion): string;
    getFormDefinitionFromImportData(importData: string): IFormDefinition;
    getAllFormTokensAsync(): Promise<SimpleStore<IFormToken[]>>;
    getAllFormDefinitionDescriptorsForFormBaseEntityAsync(formBaseEntity: string): Promise<SimpleStore<FormDefinitionDescriptor[]>>;
    getAllFormDefinitionDescriptorsAsync(): Promise<SimpleStore<FormDefinitionDescriptor[]>>;
    getAllAggregateRootSchemasAsync(): Promise<SimpleStore<AggregateRootSchema[]>>;

    getFormInstanceListAsync(baseEntityType: string, baseEntityId: StringEntityId): Promise<SimpleStore<PagedItemStore<IFormListItem>>>;
    getFormInstanceDetailAsync(id: FormInstanceId): Promise<SimpleStore<IForm>>;

    getNewFormInstanceAsync(baseEntityType: string, baseEntityId: StringEntityId, formDefinitionId: FormDefinitionId, scopes: IFormScopes): Promise<SimpleStore<IForm>>;
    createFormInstanceAsync(baseEntityType: string, baseEntityId: StringEntityId, formDefinitionId: FormDefinitionId, formData: IFormDataStore, scopes: IFormScopes): Promise<SimpleStore<IForm>>;
    validateNewFormInstanceAsync(baseEntityType: string, baseEntityId: StringEntityId, formDefinitionId: FormDefinitionId, formData: IFormDataStore, scopes: IFormScopes): Promise<SimpleStore<IForm>>;

    updateFormInstanceAsync(id: FormInstanceId, formDefinitionId: FormDefinitionId, formData: IFormDataStore, formScopes: IFormScopes, rowVersion: RowVersion): Promise<SimpleStore<IForm>>;
    validateExistingFormInstanceAsync(id: FormInstanceId, formDefinitionId: FormDefinitionId, formData: IFormDataStore, formScopes: IFormScopes, rowVersion: RowVersion): Promise<SimpleStore<IForm>>;
    deleteFormInstanceAsync(id: FormInstanceId, rowVersion: RowVersion): Promise<SimpleStore<any>>;
    
    getAvailableFormEnumsAsync(): Promise<SimpleStore<IFormEnumsInDefinition[]>>;
    hasCreatableFormInstanceLeftAsync(formDefinitionType: string, baseEntityId: string): Promise<SimpleStore<boolean>>;
}
