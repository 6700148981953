import React from "react";
import { ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import * as Styles from "./SmallValidationBadge.less";
import Icon, { iconNameType } from "@CommonControls/Icon";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ValidationBoundaryAdapter from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryAdapter";
import { Badge } from "@CommonControls";

interface ISmallValidationBadgeProps extends ICommonControlProps {
    _validationContext?: IValidationBoundaryStore;
    iconName?: iconNameType;
    hideIfNoErrors?: boolean;
    propertyPathRegexPattern?: string;

    severity?: "error" | "warning";
    numberOfProblems?: number;
}

@State.observer
class SmallValidationBadge extends React.PureComponent<ISmallValidationBadgeProps> {
    public static defaultProps: Partial<ISmallValidationBadgeProps> = {
        hideIfNoErrors: false,
        iconName: "checkCircle",
    };

    @State.computed
    private get validationResults(): IClientValidationProblem[] {
        if (this.props._validationContext && this.props.propertyPathRegexPattern) {
            const regex = new RegExp(this.props.propertyPathRegexPattern);
            return this.props._validationContext.getValidationProblemsByRegex(regex);
        }
        return emptyArray;
    }

    @State.computed
    private get numberOfErrors() {
        if (this.props.severity === "error") {
            return this.props.numberOfProblems;
        }

        return this.validationResults.filter(v => v.severity === "error").length;
    }

    @State.computed
    private get numberOfWarnings() {
        if (this.props.severity === "warning") {
            return this.props.numberOfProblems;
        }

        return this.validationResults.filter(v => v.severity === "warning").length;
    }

    public render(): React.ReactNode {
        const hasErrors = this.numberOfErrors > 0;
        const hasWarnings = this.numberOfWarnings > 0;

        const count = hasErrors ? this.numberOfErrors : this.numberOfWarnings;

        const classNames = new CompositeClassName(this.props.className, Styles.smallContainer);
        if (hasErrors) {
            classNames.add(Styles.containerError);
        } else if (hasWarnings) {
            classNames.add(Styles.containerWarning);
        }

        if (hasErrors || hasWarnings) {
            return (<div className={classNames.classNames} style={this.props.style}>{count}</div>);
        } else if (!this.props.hideIfNoErrors) {
            return (<div className={classNames.classNames} style={this.props.style}><Icon size="mini" iconName={this.props.iconName} /></div>);
        }

        return null;
    }
}

export default connect(
    SmallValidationBadge,
    new ValidationBoundaryAdapter<ISmallValidationBadgeProps>((props, validationStore) => ({ _validationContext: validationStore }))
);