import React, { useCallback } from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import InpatientAdmissionTypeId from "@Primitives/InpatientAdmissionTypeId.g";
import HunCareReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/HunCareReferenceDataStore";
import InpatientAdmissionTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/InpatientAdmissionTypeSelectBox/InpatientAdmissionTypeSelectBox";

interface IInpatientAdmissionTypeColumnDependencies {
    referenceDataStore: HunCareReferenceDataStore;
}

interface IInpatientAdmissionTypeColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IInpatientAdmissionTypeColumnDependencies;
}

// tslint:disable-next-line: variable-name
const InpatientAdmissionTypeColumn: React.FC<IInpatientAdmissionTypeColumnProps> = (props: IInpatientAdmissionTypeColumnProps) => {

    const valueRenderer = (value: InpatientAdmissionTypeId) => {
        if (value) {
            const inPatientAdmissionType = props._dependencies.referenceDataStore.inpatientAdmissionTypeMap.get(value);
            return inPatientAdmissionType?.displayValue?.Name ?? "";
        }
        return "";
    };

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<InpatientAdmissionTypeId | InpatientAdmissionTypeId[]>) => {
        return (
            <InpatientAdmissionTypeSelectBox
                {...filterProps}
                multiSelect
                hoverOnlyIndicatorIcons
            />
        );
    }, []);

    return (
        <DataGridColumn
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            {...props}
        />
    );
};

export default connect(
    InpatientAdmissionTypeColumn,
    new DependencyAdapter<IInpatientAdmissionTypeColumnProps, IInpatientAdmissionTypeColumnDependencies>(c => ({
        referenceDataStore: c.resolve("HunCareReferenceDataStore")
    }))
);
