import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IHunSocialSecurityFinanceResources from "@HunSocialSecurityPlugin/BoundedContexts/Finance/StaticResources/IHunSocialSecurityFinanceResources";
import HunSocialSecurityFinanceResources from "@HunSocialSecurityPlugin/BoundedContexts/Finance/StaticResources/HunSocialSecurityFinanceResources";

const resources: IHunSocialSecurityFinanceResources = {} as any;

export function loadStaticHunSocialSecurityFinanceResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    const newResources = new HunSocialSecurityFinanceResources(localizationService);
    Object.assign(resources, newResources);
}

export default resources as IHunSocialSecurityFinanceResources;
