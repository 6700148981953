import IUseCaseRegistry, { ScreenConverter } from "@PluginInterface/UseCases/IUseCaseRegistry";
import IUseCaseDescriptor from "@PluginInterface/UseCases/IUseCaseDescriptor";
import Di from "@Di";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import IRouteDefinition from "@Toolkit/ReactClient/Routing/Abstractions/IRouteDefinition";
import IUseCaseDescriptor2 from "@PluginInterface/UseCases/IUseCaseDescriptor2";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";

@Di.injectable()
export default class UseCaseRegistry implements IUseCaseRegistry {
    private readonly useCases: IUseCaseDescriptor[] = [];

    public add(useCase: IUseCaseDescriptor): void {
        this.useCases.push(useCase);
    }

    public getAll(): IUseCaseDescriptor[] {
        return this.useCases;
    }

    public getStandaloneRouteDefinitions(): { [key: string]: RouteDefinition } {
        return this.useCases.reduce((routes, useCase) => {
            return {
                ...routes,
                ...useCase.standaloneRouteDefinitions
            };
        }, {});
    }

    public renderStandaloneRoutingCases(): React.ReactNode {
        return this.useCases.map(uc => uc.standaloneRoutingFrameCaseRenderer());
    }

    public renderStandaloneRoutingCasesByParentRoute(parentRoute: RouteDefinition): React.ReactNode {
        return this.useCases.filter(uc => this.useCaseContainsStandaloneRoute(uc, parentRoute)).map(uc => uc.standaloneRoutingFrameCaseRenderer());
    }

    private useCaseContainsStandaloneRoute(uc: IUseCaseDescriptor, parentRoute: RouteDefinition): boolean {
        const routeDefinitions = [
            ...(uc.standaloneRouteDefinitions && Object.values(uc.standaloneRouteDefinitions))
        ];

        for (const route of routeDefinitions) {
            if (this.routeDefinitionContainsParentRoute(route, parentRoute)) {
                return true;
            }
        }

        return false;
    }

    private routeDefinitionContainsParentRoute(currentRoute: RouteDefinition, parentRoute: RouteDefinition): boolean {
        if (currentRoute === parentRoute) {
            return true;
        } else {
            if (currentRoute.parent) {
                return this.routeDefinitionContainsParentRoute(currentRoute.parent, parentRoute);
            }
        }

        return false;
    }

    public get(useCase: string): IUseCaseDescriptor {
        return this.useCases.find(uc => uc.name === useCase);
    }

    public getUseCaseNameForRouteDefinition(routeDefinition: IRouteDefinition): string {
        return this.getUseCaseForRouteDefinition(routeDefinition)?.name;
    }

    public getUseCaseForRouteDefinition(routeDefinition: IRouteDefinition): IUseCaseDescriptor {
        const result = this.useCases.find(uc => {
            const routeDefinitions = [
                ...(uc.standaloneRouteDefinitions && Object.values(uc.standaloneRouteDefinitions)),
                ...(uc.masterDetailRouteDefinitions && Object.values(uc.masterDetailRouteDefinitions))
            ];

            return routeDefinitions.some(rd => rd === routeDefinition);
        });

        return result;
    }

    /// ------- NEW {
    private readonly useCaseMap = new Map<string, IUseCaseDescriptor2>();

    public add2(useCase: IUseCaseDescriptor2): void {
        useCase.identifiers.forEach(i => this.useCaseMap.set(i, useCase));
    }

    public tryGet2(useCaseIdentifier: UseCaseIdentifier): IUseCaseDescriptor2 {
        return this.useCaseMap.get(useCaseIdentifier?.value);
    }
    /// ------- NEW }

    /// ------- VERY NEW {
    private readonly useCaseToScreenMap = new Map<string, ScreenConverter>();

    public addScreenAdapter(identifierValue: string, converter: ScreenConverter): void {
        this.useCaseToScreenMap.set(identifierValue, converter);
    }

    public tryGetScreenAdapter(useCaseIdentifier: UseCaseIdentifier): ScreenConverter {
        return this.useCaseToScreenMap.get(useCaseIdentifier?.value);
    }
    /// ------- VERY NEW }
}
