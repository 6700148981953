import PermissionScope from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/PermissionScope";
import * as Ui from "@CommonControls";
import OrganizationSelector from "@HisPlatform/BoundedContexts/Organization/Components/OrganizationSelector";
import IPermissionScope from "@PluginInterface/OperationAccessControl/IPermissionScope";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import React from "react";
import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";

export interface IPointOfCareScopeSelectorDialogProps {
    _isOpen?: boolean;
    _onClose?: () => void;
    onSave: (newValue: IPermissionScope) => void;
    value: IPermissionScope;
}

@State.observer
export default class PointOfCareScopeSelectorDialog extends React.Component<IPointOfCareScopeSelectorDialogProps> {
    @State.observable.ref selectedPointOfCareIds: PointOfCareId[];
    @State.observable isAll: boolean = false;

    private get staticResources() {
        return StaticAuthorizationResources.PermissionConfiguration.PointOfCareScopeSelector;
    }
    public componentDidMount() {
        this.initializeSelectedPointOfCareIds();
    }

    public componentDidUpdate(prevProps: IPointOfCareScopeSelectorDialogProps) {
        if (prevProps.value !== this.props.value) {
            this.initializeSelectedPointOfCareIds();
        }
    }

    @State.action.bound
    private initializeSelectedPointOfCareIds() {
        this.isAll = this.props.value?.isAll ?? false;
        this.selectedPointOfCareIds = [];
        if (!this.isAll) {
            this.selectedPointOfCareIds = this.props.value?.includedValues.map(v => new PointOfCareId(v)) ?? [];
        }

    }

    @State.action.bound
    private setSelectedPointOfCareIds(newValue: PointOfCareId, isChecked: boolean) {
        if (isChecked) {
            this.selectedPointOfCareIds.push(newValue);
        } else {
            this.selectedPointOfCareIds = this.selectedPointOfCareIds.filter(p => !ValueWrapper.equals(p, newValue));
        }
    }

    @State.bound
    private getScopeFromSelectedValues() {
        if (this.isAll) {
            return PermissionScope.createAll(PermissionScopeType.pointOfCare);
        }
        return PermissionScope.createPositive(PermissionScopeType.pointOfCare, this.selectedPointOfCareIds.map(p => p.value));
    }

    @State.bound
    private save() {
        const newScope = this.getScopeFromSelectedValues();
        this.props.onSave(newScope);
        this.props._onClose();
    }

    @State.action.bound
    private setIsAll(newValue: boolean) {
        this.isAll = newValue;
        this.selectedPointOfCareIds = [];
    }

    @State.bound
    private close() {
        this.initializeSelectedPointOfCareIds();
        this.props._onClose();
    }

    public render() {
        return (
            <Ui.Modal
                title={this.staticResources.Title}
                isOpen={this.props._isOpen}
                closeOnEscape
                onClose={this.close}
                closeButton
                automationId="__pointOfCareScopeModal"
                fixedHeight="75%">
                <Ui.Modal.Body>
                    <Ui.CheckBox
                        label={StaticAuthorizationResources.PermissionConfiguration.PointOfCareScopeSelector.AllPointOfCares}
                        value={this.isAll}
                        onChange={this.setIsAll}
                        automationId="isAllCheckBox"
                    />
                    <ReadOnlyContext.Provider value={this.isAll} >
                        <OrganizationSelector
                            onChange={this.setSelectedPointOfCareIds}
                            value={this.selectedPointOfCareIds}
                            isOpen={true}
                            readOnly={this.isAll}
                            isAllChecked={this.isAll}
                            pointOfCareMode={true}
                            automationId="organization"
                        />
                    </ReadOnlyContext.Provider>
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Flex xsJustify="between" verticalSpacing="none">
                        <Ui.Flex.Item>
                            <Ui.Button
                                text={this.staticResources.Cancel}
                                onClick={this.props._onClose}
                                visualStyle="link"
                                automationId="__close"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item>
                            <Ui.Button
                                text={this.staticResources.Select}
                                visualStyle="primary"
                                onClick={this.save}
                                automationId="__save"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.Modal.Footer>
            </Ui.Modal>
        );
    }
}