import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import IServiceRequestReferralExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestReferralExtensionPointProps";
import { IsEhiEReferralExtensionNeeded } from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/EhiEReferralExtension/EhiEReferralExtensionHelper";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PatientId from "@Primitives/PatientId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

@Di.injectable()
export default class EhiEReferralExtensionComponentService implements IExtensionComponentService<IServiceRequestReferralExtensionPointProps> {

    private lastPatientId: PatientId = null;
    private lastCareActivityId: CareActivityId = null;
    private lastIsVisible: boolean = null;

    constructor(@Di.inject("EhiCareApiAdapter") private readonly ehiCareApiAdapter: EhiCareApiAdapter) { }

    public async isVisibleAsync(props: IServiceRequestReferralExtensionPointProps) {

        if (ValueWrapper.equals(props.patientId, this.lastPatientId) && ValueWrapper.equals(props.careActivityId, this.lastCareActivityId)) {
            return this.lastIsVisible;
        }

        this.lastPatientId = props.patientId;
        this.lastCareActivityId = props.careActivityId;

        const patientEhiIdentifier = await this.ehiCareApiAdapter.tryGetPatientEhiIdentifierQueryAsync(props.patientId, props.careActivityId, false);

        this.lastIsVisible = ((props.serviceRequest?.extensionData !== null || props.serviceRequest?.isNew) &&
            IsEhiEReferralExtensionNeeded(props.requestingPointOfCareDynamicProperties, props.serviceRequest.direction)) &&
            !isNullOrUndefined(patientEhiIdentifier.value);

        return this.lastIsVisible;
    }

    public shouldRerunIsVisible(props: IServiceRequestReferralExtensionPointProps, prevProps: IServiceRequestReferralExtensionPointProps) {
        return true;
    }
}