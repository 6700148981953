/* auto-inject-disable */
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityId from "@Primitives/CareActivityId.g";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import ICareActivityTextBlockData from "./ICareActivityTextBlockData";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IAutomaticFillDocumentContent from "./IAutomaticFillDocumentContent";
import CareActivityTextBlockStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityTextBlock/CareActivityTextBlockStoreMapper";
import CareActivityTextBlock from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/CareActivityTextBlock";
import IOperationResult from "@Toolkit/CommonWeb/ApiAdapter/IOperationResult";
import LockId from "@Toolkit/CommonWeb/Model/LockId";

@Di.injectable()
export default class CareActivityTextBlockScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("ICareClient") private readonly apiClient: Proxy.ICareClient,
        @Di.inject("CareActivityTextBlockStoreMapper") private readonly textBlockStoreMapper: CareActivityTextBlockStoreMapper,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public getCareActivityTextBlockAsync(
        careActivityId: CareActivityId,
        textBlockTypeId: TextBlockTypeId,
        requestLock: boolean = true
    ): Promise<IOperationResult<ICareActivityTextBlockData>> {
        return this.executeOperationAsync<ICareActivityTextBlockData, Proxy.GetCareActivityTextBlockDataCommandResponse>(
            () => this.apiClient.getCareActivityTextBlockDataCommandAsync(CreateRequestId(), {
                careActivityId: careActivityId,
                textBlockTypeId: textBlockTypeId,
                requestLock: requestLock
            } as Proxy.GetCareActivityTextBlockDataControllerDto, false),
            response => {
                const textBlock = new CareActivityTextBlock(response.textBlock?.isNew ?? false);
                this.textBlockStoreMapper.applyToStore(textBlock, response);

                return Promise.resolve({
                    textBlock: textBlock,
                    automaticFillDocumentContent: !isNullOrUndefined(response.automaticFillDocumentContent)
                        ? {
                            actualContent: response.automaticFillDocumentContent.actualContent,
                            lastModifiedAt: response.automaticFillDocumentContent.lastModifiedAt,
                            lastModifiedBy: response.automaticFillDocumentContent.lastModifiedBy,
                            pointOfCareName: response.automaticFillDocumentContent.pointOfCareName
                        } as IAutomaticFillDocumentContent
                        : null
                } as ICareActivityTextBlockData);
            }
        );
    }

    @State.bound
    public createCareActivityTextBlockAsync(
        textBlock: CareActivityTextBlock,
        temporaryLockId: LockId,
        requestLock: boolean = true,
        skipValidation: boolean = false
    ): Promise<IOperationResult<CareActivityTextBlock>> {
        return this.executeOperationAsync<CareActivityTextBlock, Proxy.CreateNewCareActivityTextBlockCommandResponse>(
            () => this.apiClient.createNewCareActivityTextBlockCommandAsync(CreateRequestId(), {
                careActivityId: textBlock.careActivityId,
                textBlockTypeId: textBlock.textBlockTypeId,
                content: textBlock.contentStore.getContent(),
                temporaryLockId: temporaryLockId,
                requestLock: requestLock,
                skipValidation: skipValidation
            } as Proxy.CreateNewCareActivityTextBlockControllerDto, false),
            response => {
                const result = new CareActivityTextBlock(!response.isPersisted);
                this.textBlockStoreMapper.applyToStore(result, response);
                return Promise.resolve(result);
            }
        );
    }

    @State.bound
    public saveCareActivityTextBlockAsync(
        textBlock: CareActivityTextBlock,
        releaseLock: boolean = true,
        skipValidation: boolean = false
    ): Promise<IOperationResult<CareActivityTextBlock>> {
        return this.executeOperationAsync<CareActivityTextBlock, Proxy.SaveCareActivityTextBlockContentCommandResponse>(
            () => this.apiClient.saveCareActivityTextBlockContentCommandAsync(CreateRequestId(), {
                releaseLockIfSuccessful: releaseLock,
                careActivityTextBlockId: textBlock.id,
                lockId: textBlock.lockInfo.lockId,
                newContent: textBlock.contentStore.getContent(),
                rowVersion: textBlock.rowVersion,
                skipValidation: skipValidation
            } as Proxy.SaveCareActivityTextBlockContentControllerDto, false),
            response => {
                const result = new CareActivityTextBlock(false);
                this.textBlockStoreMapper.applyToStoreWithExistingLock(result, response, textBlock.lockInfo, releaseLock);
                return Promise.resolve(result);
            }
        );
    }
}