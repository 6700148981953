import ICurrentCultureRepository from "@Toolkit/CommonWeb/Repositories/Definition/CurrentCultureRepository/ICurrentCultureRepository";
import Di from "@Di";

const storageKey = "Culture-code";

@Di.injectable()
export default class CurrentCultureRepository implements ICurrentCultureRepository {
    public saveCurrentCulture(cultureCode: string): void {
        localStorage.setItem(storageKey, cultureCode);
    }

    public getCurrentCulture(): string {
        return localStorage.getItem(storageKey);
    }
}