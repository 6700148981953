import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import StaticHunEHealthInfrastructureEesztIntegrationResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/EesztIntegration/StaticResources/StaticHunEHealthInfrastructureEesztIntegrationResources";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import CommunicationLogDownloaderModalParams from "@HunEHealthInfrastructurePlugin/BoundedContexts/EesztIntegration/Components/Panels/CommunicationLogDownloaderModal/CommunicationLogDownloaderModalParams";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import EesztIntegrationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/EesztIntegration/ApplicationLogic/ApiAdapter/EesztIntegrationApiAdapter";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";


interface IEesztCommunicationLogsMenuDependencies {
    apiAdapter: EesztIntegrationApiAdapter;
}

interface IEesztCommunicationLogsMenuProps {
    _dependencies?: IEesztCommunicationLogsMenuDependencies;
    _modalService?: IModalService;
}

@State.observer
class EesztCommunicationLogsMenu extends React.Component<IEesztCommunicationLogsMenuProps> {
    private get modalService() {
        return this.props._modalService;
    }

    @State.computed
    private get permissionCheckedOperations() {
        return {
            "ViewEhiCommunicationLogs": () => this.props._dependencies.apiAdapter.getCommunicationLogsFileAsync(
                LocalDate.today(),
                LocalDate.today(),
                true
            ),
        };
    }

    public render() {
        return (
            <PermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                <Ui.NavBar.Item
                    onClickAsync={this.openCommunicationLogsDialogAsync}
                    automationId="eesztCommunicationLogs"
                    permissionCheckOperationNames="ViewEhiCommunicationLogs"
                    permissionDeniedStyle="disabled"
                >
                    {StaticHunEHealthInfrastructureEesztIntegrationResources.CommunicationLogs.DownloadPanel.NavBarTitle}
                </Ui.NavBar.Item>
            </PermissionCheckContextProvider>
        );
    }

    @State.bound
    private openCommunicationLogsDialogAsync() {
        return this.modalService.showModalAsync(new CommunicationLogDownloaderModalParams());
    }
}

export default connect(
    EesztCommunicationLogsMenu,
    new DependencyAdapter<IEesztCommunicationLogsMenuProps, IEesztCommunicationLogsMenuDependencies>(c => ({
        apiAdapter: c.resolve("EesztIntegrationApiAdapter")
    })),
    new HisModalServiceAdapter()
);