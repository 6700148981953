import moment from "moment";
import { ISchedulerEvent } from "./ISchedulerProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class NewEvent implements ISchedulerEvent {
    @State.observable.ref public startTime: moment.Moment;
    @State.observable.ref public endTime: moment.Moment;

    constructor(
        public readonly id: number,
        startTime: moment.Moment,
        endTime: moment.Moment,
    ) {
        this.startTime = startTime;
        this.endTime = endTime;
    }

    @State.bound
    public getDurationInMinutes() {
        return this.endTime.clone().diff(this.startTime, "minutes");
    }
}