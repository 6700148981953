import React, { useCallback } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { ISchedulerEvent, ISchedulerEntryEventHandlers } from "@CommonControls/Scheduler/ISchedulerProps";
import moment from "moment";
import Icon, { iconNameType } from "@CommonControls/Icon";
import Styles from "./AppointmentScheduler.less";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import NewEvent from "@CommonControls/Scheduler/NewEvent";

interface IAppointmentSchedulerEntryComponentDependencies {
    dateTimeFormatProvider: IDateTimeFormatProvider;
}

interface IAppointmentSchedulerEventEntryProps {
    _dependencies?: IAppointmentSchedulerEntryComponentDependencies;
    event: ISchedulerEvent;
    handlers: ISchedulerEntryEventHandlers;
    isPlaceable?: boolean;
    onRenderTooltip?: (entry: ISchedulerEvent) => React.ReactNode;
    className?: string;
    suppressEditIcon?: boolean;
    editIconClass?: string;
    onGetNewEntryIconName?: (entry: ISchedulerEvent) => iconNameType;
    onGetNewEntryIconClass?: (entry: ISchedulerEvent) => string;
    onRenderAdditionalEntryComponent?: (id: number) => React.ReactNode;
    onGetAutomationIdPrefix: (entry: ISchedulerEvent) => string;
    isReadOnly?: boolean;
}

@State.observer
class AppointmentSchedulerEventEntry extends React.Component<IAppointmentSchedulerEventEntryProps> {

    @State.computed private get isNew() {
        if (isNullOrUndefined(this.props.event)) {
            return false;
        }
        return this.props.event instanceof NewEvent;
    }

    @State.computed private get automationId() {
        const dateTimeFormat = this.props._dependencies.dateTimeFormatProvider.getDateTimeWithoutSecondsFormat();

        const prefix = this.props.onGetAutomationIdPrefix?.(this.props.event) ?? "entry";

        return `__${prefix}_${this.props.event.startTime.format(dateTimeFormat)}`;
    }

    @State.computed private get entryLabel() {
        return moment(this.props.event.startTime).format(this.props._dependencies.dateTimeFormatProvider.getTimeWithoutSecondsFormat());
    }

    @State.computed
    private get effectiveEntryClick(): (e: React.MouseEvent) => void {
        if (!!this.props.isReadOnly) {
            return () => {};
        }

        return (e: React.MouseEvent) => this.props.handlers.onClick?.(this.props.event, e);
    }

    @State.bound
    private onMouseEnter(e: React.MouseEvent<HTMLElement>) {
        this.props.handlers.onMouseEnter?.(this.props.event, e);
    }

    @State.bound
    private onMouseLeave(e: React.MouseEvent<HTMLElement>) {
        this.props.handlers.onMouseLeave?.(this.props.event, e);
    }

    @State.bound
    private getEntryIcon() {
        if (!!this.props.isReadOnly) {
            return <></>;
        }

        if (this.isNew) {
            return this.props.isPlaceable
                ? (
                    <Icon
                        className={combineClasses(Styles.newEntryIcon, this.props.onGetNewEntryIconClass?.(this.props.event))}
                        iconName={this.props.onGetNewEntryIconName?.(this.props.event) ?? "pen"}
                    />)
                : <></>;
        }
        return !this.props.suppressEditIcon && <Icon className={combineClasses(Styles.editIcon, this.props.editIconClass)} iconName={"pen"} />;
    }

    private renderContent() {
        return (
            <div data-automation-id={this.automationId}>
                <div className={Styles.slotContent}>
                    {this.entryLabel}
                    &#32;
                    {this.props.onRenderAdditionalEntryComponent?.(this.props.event.id)}
                    {this.getEntryIcon()}
                </div>
            </div>
        );
    }

    public render() {
        const tooltipContent = this.props.onRenderTooltip?.(this.props.event);
        return (
            <>
                {(!!tooltipContent) ?
                    <Popper
                        className={combineClasses(Styles.popperContainer, this.props.className)}
                        onClick={this.effectiveEntryClick}
                        onMouseEnter={this.onMouseEnter}
                        onMouseLeave={this.onMouseLeave}
                        wrapperElementType="div"
                        tooltipPlacement="top"
                        tooltipClassName={Styles.popperTooltip}
                        tooltipContent={tooltipContent}
                    >
                        {this.renderContent()}
                    </Popper>
                    :
                    <div
                        className={combineClasses(Styles.slotContainer, this.props.className)}
                        onClick={this.effectiveEntryClick}
                        onMouseEnter={this.onMouseEnter}
                        onMouseLeave={this.onMouseLeave}>
                        {this.renderContent()}
                    </div>
                }
            </>
        );
    }
}

export default connect(
    AppointmentSchedulerEventEntry,
    new DependencyAdapter<IAppointmentSchedulerEventEntryProps, IAppointmentSchedulerEntryComponentDependencies>(c => ({
        dateTimeFormatProvider: c.resolve("IDateTimeFormatProvider")
    }))
);