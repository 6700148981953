import React from "react";
import Option from "@CommonControls/SelectBox/Option";
import ISelectBoxSectionItem from "@CommonControls/SelectBox/ISelectBoxSectionItem";
import Styles from "./MenuSection.less";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Icon from "@CommonControls/Icon";
import StaticWebAppResources from "@StaticResources";

interface IMenuSectionProps {
    title: string;
    items: Array<ISelectBoxSectionItem<any>>;
    renderMenuSectionItem: (value: ISelectBoxSectionItem<any>) => string | React.ReactNode;
    multiSelect: boolean;
    onItemSelected: () => void;
    innerProps: any;
    maxCount?: number;
}

@State.observer
export default class MenuSection extends React.Component <IMenuSectionProps> {
    @State.observable private showAll = false;

    @State.computed
    private get maxCount() {
        return this.props.maxCount || 5;
    }

    @State.computed
    private get isBeyondLimit() {
        return this.props.items.length > this.maxCount;
    }

    @State.computed
    private get items() {
        return !this.isBeyondLimit || this.showAll ? this.props.items : this.props.items.slice(0, this.maxCount);
    }

    @State.action.bound
    private setShowAll(event: React.MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.showAll = !this.showAll;
    }

    @State.bound
    private renderShowMore() {
        if (!this.isBeyondLimit) {
            return (<></>);
        }
        const count = this.props.items.length - this.maxCount;
        const text = this.showAll ? StaticWebAppResources.Selectors.Common.ShowLess : StaticWebAppResources.Selectors.Common.ShowAllItemWithReplaceableCount.replace("#count#", count.toString());

        return (
            <div className={Styles.showMoreContainer} onMouseDown={this.setShowAll}>
                <Icon iconName={this.showAll ? "caretUp" : "caretRight"} style={{marginRight: "4px"}} automationId={`${this.props.title}_showAll`}/>
                {text}
            </div>
        );
    }

    public render() {
        return (
            <>
                <div className={Styles.menuSectionContainer}>
                    <b>{this.props.title} </b>
                </div>
                {this.items.map((item, key) => (
                        <div className={Styles.menuSectionItem} key={key}>
                            <Option {...this.props}
                                    data={item}
                                    selectOption={this.props.onItemSelected}
                                    isMulti={this.props.multiSelect}
                                    isSelected={item.isSelected}
                                    key={key}
                                    iconName={item.iconName}
                                    onIconClick={item.onIconClick}
                            >
                                {item.text}
                            </Option>
                        </div>
                    )
                )}
                {this.renderShowMore()}
            </>
        );
    }
}
