import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IMedicationStatementSubstanceHintItemExtended from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicationStatementSubstanceHintItemExtended";
import MedicationStatementSubstanceCategoryId from "@Primitives/MedicationStatementSubstanceCategoryId.g";

@Di.injectable()
export default class MedicationStatementSubstanceApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IMedicationStatementClient") private readonly apiClient: Proxy.IMedicationStatementClient
    ) {
        super();
    }

    public searchMedicationStatementSubstancesExtendedByFreeTextAsync(searchString: string, maxResultCount: number, validOn?: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<IMedicationStatementSubstanceHintItemExtended[]>(),
            async target => {
                const response = await this.apiClient.searchMedicationStatementSubstancesExtendedQueryAsync(
                    CreateRequestId(),
                    searchString,
                    maxResultCount.toString(),
                    validOn ? validOn.stringify() : ""
                );
                target.operationInfo = createOperationInfo(response);
                target.value = [];
                for (let index = 0; index < maxResultCount; index++) { 
                    if (index < response.internationalNonproprietaryNames.length && target.value.length <= maxResultCount) {
                        target.value.push({
                            internationalNonproprietaryNameId: response.internationalNonproprietaryNames[index].id,
                            code: response.internationalNonproprietaryNames[index].code,
                            name: response.internationalNonproprietaryNames[index].name,
                            categoryId: MedicationStatementSubstanceCategoryId.InternationalNonproprietaryName
                        } as IMedicationStatementSubstanceHintItemExtended);
                    }
                    if (index < response.medications.length && target.value.length <= maxResultCount) {
                        target.value.push({
                            medicationId: response.medications[index].id,
                            code: response.medications[index].identifiers[0].value,
                            name: response.medications[index].name,
                            categoryId: MedicationStatementSubstanceCategoryId.Medication
                        } as IMedicationStatementSubstanceHintItemExtended);
                    }
                    if (index < response.substances.length && target.value.length <= maxResultCount) {
                        target.value.push({
                            substanceId: response.substances[index].id,
                            name: response.substances[index].name,
                            code: response.substances[index].identifier.value,
                            categoryId: MedicationStatementSubstanceCategoryId.Medication
                        } as IMedicationStatementSubstanceHintItemExtended);
                    }
                    if (index > response.internationalNonproprietaryNames.length && index > response.substances.length && index > response.medications.length || target.value.length >= maxResultCount) {
                        return;
                    }
                }
            }
        );
    }

    @State.bound
    public loadMedicationStatementSubstanceCategoryIdsAsync(): Promise<SimpleStore<MedicationStatementSubstanceCategoryId[]>> {
        return this.processOperationAsync(
            new SimpleStore<MedicationStatementSubstanceCategoryId[]>(),
            async target => {
                const response = await this.apiClient.getMedicationStatementSubstanceCategoryIdsQueryAsync(CreateRequestId(), "");
                target.operationInfo = createOperationInfo(response);
                target.value = response.medicationStatementSubstanceCategoryIds.map(x => new MedicationStatementSubstanceCategoryId(x.value));
            }
        );
    }
}
