import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import ICareActivityCoverageDataExtensionPointProps from "@PluginInterface/BoundedContexts/Care/PatientRegister/ExtensionPoints/ICareActivityCoverageDataExtensionPointProps";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import HunFinanceReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/Model/HunFinanceReferenceDataStore";
import CareActivityCoverageDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/Model/CareActivityCoverageDataStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import Styles from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/HeaderCareActivityCoverageData/HeaderCareActivityCoverageData.less";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

interface IHeaderCareActivityCoverageDataPanelDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    financeReferenceDataStore: HunFinanceReferenceDataStore;
}

interface IHeaderCareActivityCoverageDataPanelProps extends ICareActivityCoverageDataExtensionPointProps {
    _dependencies?: IHeaderCareActivityCoverageDataPanelDependencies;
}

/** @screen */
@State.observer
class HeaderCareActivityCoverageDataPanel extends React.Component<IHeaderCareActivityCoverageDataPanelProps> {
    @State.observable private isLoaded: boolean = false;

    @State.observable.ref private store: CareActivityCoverageDataStore;

    private get dependencies() {
        return this.props._dependencies;
    }

    public componentDidMount(): void {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }

    public componentDidUpdate(prevProps: IHeaderCareActivityCoverageDataPanelProps) {
        if (!ValueWrapper.equals(this.props.patientId, prevProps.patientId)
            || !ValueWrapper.equals(this.props.careActivityId, prevProps.careActivityId)
            || this.props.data !== prevProps.data) {
            this.initializeStore();
        }
    }

    private async initializeAsync() {
        const financingClassPromise = this.dependencies.financeReferenceDataStore.financingClassMap.ensureLoadedAsync();
        const docTypePromise = this.dependencies.careReferenceDataStore.patientDocumentType.ensureAllLoadedAsync();

        await Promise.all([financingClassPromise, docTypePromise]);
        this.initializeStore();
        State.runInAction(() => {
            this.isLoaded = true;
        });
    }

    @State.action.bound
    private initializeStore() {
        const newStore = CareActivityCoverageDataStore.createFromFormFields(this.props.data);
        if (newStore) {
            this.store = newStore;
        }
    }

    @State.bound
    private getDocumentText() {
        if (this.store.identifier && this.store.identifier.identifierSystemId) {
            const docType = this.dependencies.careReferenceDataStore.patientDocumentType.items
                .find(doc => doc.Entity.identifierSystemId.value === this.store.identifier.identifierSystemId.value);

            return docType && docType.Localization.Name;
        }
        return null;
    }

    @State.bound
    private getFinancingClassText() {
        if (this.store.financingClassId) {
            const financingClass = this.dependencies.financeReferenceDataStore.financingClassMap.get(this.store.financingClassId);
            return financingClass.displayValue.Shorthand;
        }
        return null;
    }

    public render() {
        return (
            this.isLoaded && (
                <>
                    <div className={Styles.separator8}>
                        {this.getDocumentText() && (
                            <div>
                                <span> {this.getDocumentText()}: &nbsp;{this.store.identifier && this.store.identifier.value}</span>
                            </div>
                        )}
                    </div>
                </>)
        );
    }
}

export default connect(
    HeaderCareActivityCoverageDataPanel,
    new DependencyAdapter<IHeaderCareActivityCoverageDataPanelProps, IHeaderCareActivityCoverageDataPanelDependencies>( (container) => {
        return {
            careReferenceDataStore: container.resolve<CareReferenceDataStore>("CareReferenceDataStore"),
            financeReferenceDataStore: container.resolve<HunFinanceReferenceDataStore>("HunFinanceReferenceDataStore"),
        };
    })
);
