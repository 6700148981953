import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import CoverageEligibilityCheckWidget from "@HunEHealthInfrastructurePlugin/BoundedContexts/Dashboards/Components/Widgets/CoverageEligibilityCheckWidget/CoverageEligibilityCheckWidget";
import IWidgetRegistry from "@PluginInterface/Dashboard/IWidgetRegistry";
import WidgetName from "@Primitives/WidgetName.g";
import { getNavigationMenuItems } from "./NavigationMenuItems";

class CarePackageInitializer implements IPackageInitializer {

    public configureDynamicNavigation(registry: IDynamicNavigationRegistry): void {
        getNavigationMenuItems().forEach(navigationItem => registry.register(navigationItem));
    }

    public configureWidgets(widgetRegistry: IWidgetRegistry) {
        widgetRegistry.register(new WidgetName("CoverageEligibilityCheckWidget"), CoverageEligibilityCheckWidget);
    }
}

export default new CarePackageInitializer();