import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IPatientsCareActivityExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientsCareActivityExtensionPointProps";
import EhiCareActivityPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCareActivityPanel/EhiCareActivityPanel";
import EhiPatientsCareActivitiesDataSource from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiPatientCareActivitiesDataSource/EhiPatientCareActivitiesDataSource";

@State.observer
export default class PatientsCareActivityEhiExtension extends React.Component<IPatientsCareActivityExtensionPointProps> {

    public render() {
        return (
            <EhiCareActivityPanel
                extensionData={this.props.extensionData}
                patientId={this.props.patientId}
                listExtensionData={this.props.listExtensionData}
                activeTabName={this.props.activeTabName}
                setActiveTabName={this.props.setActiveTabName}
                ehiCareService={(this.props.dataSource as EhiPatientsCareActivitiesDataSource)?.ehiCareService}
                beginningOfCare={this.props.beginningOfCare}
                dischargedAt={this.props.dischargedAt}
                careTypeId={this.props.careTypeId}
            />
        );
    }    
}
