import React from "react";
import * as Ui from "@CommonControls";
import MedicationFormulaListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationFormulaListItem";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicationCodeSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationCodeSelector";
import MedicationId from "@Primitives/MedicationId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import MedicationTypeId from "@Primitives/MedicationTypeId.g";

interface IMedicationFormulaListItemPanelProps {
    medicationFormulaListItem: MedicationFormulaListItem;
    validOn: LocalDate;
    index: number;
}

const MedicationFormulaListItemPanel: React.FC<IMedicationFormulaListItemPanelProps> = props => {
    const setMedication = (value: MedicationId) => {
        const medicationVersionSelector = new EntityVersionSelector<MedicationId>(value, props.validOn);
        props.medicationFormulaListItem.setMedicationVersionSelector(medicationVersionSelector);
    };
    return (
        <Ui.Flex>
            <Ui.Flex.Item xs={12}>
                <MedicationCodeSelector
                    label={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.MedicationFormula.MedicationSelectorLabel}
                    value={props.medicationFormulaListItem.medicationVersionSelector?.id}
                    onChange={setMedication}
                    propertyIdentifier="MedicationId"
                    automationId="medicationSelector"
                    medicationTypes={
                        [
                            MedicationTypeId.G1.value,
                            MedicationTypeId.G3.value,
                            MedicationTypeId.G4.value,
                            MedicationTypeId.G5.value,
                            MedicationTypeId.G6.value,
                            MedicationTypeId.G7.value,
                            MedicationTypeId.H1.value,
                            MedicationTypeId.T1.value
                        ]
                    }
                />
            </Ui.Flex.Item>
            <Ui.Flex.Item xs={4}>
                <Ui.NumberBox
                    value={props.medicationFormulaListItem.amount}
                    nullValue={0}
                    automationId="medicationAmount"
                    onChange={props.medicationFormulaListItem.setAmount}
                    label={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.MedicationFormula.AmountLabel}
                    propertyIdentifier="Amount"
                />
            </Ui.Flex.Item>
            <Ui.Flex.Item xs={4}>
                <Ui.TextBox
                    automationId="medicationUnit"
                    value={props.medicationFormulaListItem.unit}
                    onChange={props.medicationFormulaListItem.setUnit}
                    label={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.MedicationFormula.UnitLabel}
                    propertyIdentifier="Unit"
                />
            </Ui.Flex.Item>
        </Ui.Flex>);
};

export default State.observer(MedicationFormulaListItemPanel);