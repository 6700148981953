import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCases from "@Primitives/UseCases";
import DeviceUseStatementPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/DeviceUseStatement/DeviceUseStatementPanel";
import DeviceUseStatementId from "@Primitives/DeviceUseStatementId.g";

export default function configureDeviceUseStatementUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2({
        identifiers: [UseCases.deviceUseStatementDetail],
        component: DeviceUseStatementPanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));
            const id = map.get("deviceUseStatementId") ?? new DeviceUseStatementId("new");
            return {
                id: id,
            };
        }
    });
}
