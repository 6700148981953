import ValueWrapperMap from "@Toolkit/CommonWeb/Model/ValueWrapperMap";
import FrontendActionBase from "./FrontendActionBase";
import { assertNotNull, emptyObject } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FrontendActionId from "./FrontendActionId";
import { ActionProcessorFactory } from "./ActionProcessorFactory";
import React, { useContext, useEffect, useMemo } from "react";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import GlobalActionRegistry from "./GlobalActionRegistry";
import useModalService from "@Toolkit/ReactClient/Components/ModalService/useModalService";
import IContextualServices from "./IContextualServices";
import IActionDispatcher from "./IActionDispatcher";
import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";
import IFrontendActionDisplaySettings from "./IFrontendActionDisplaySettings";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ResourceId from "@Primitives/ResourceId.g";
import UnknownFrontendAction from "@Toolkit/ReactClient/ActionProcessing/UnknownFrontendAction";
import Log from "@HisPlatform/Services/Definition/Logger/Log";

export interface IActionRegistryItem {
    processorFactory: ActionProcessorFactory;
    displaySettings?: IFrontendActionDisplaySettings;
    conditionalDisplaySettings?: Array<(action: FrontendActionBase) => IFrontendActionDisplaySettings>;
}

class ActionDispatcherStore implements IActionDispatcher {
    private readonly registry = new ValueWrapperMap<FrontendActionId, IActionRegistryItem>();

    constructor(
        private readonly containerService: IContainerService,
        private readonly globalActionRegistry: GlobalActionRegistry,
        private readonly contextualServices: IContextualServices,
        private readonly parentStore: IActionDispatcher | undefined,
        private readonly localizationService: ILocalizationService,
    ) { }

    @State.bound
    public registerLocal(
        actionId: FrontendActionId,
        processAsync: (action: FrontendActionBase) => Promise<void>,
        actionProcessorFactory?: ActionProcessorFactory,
        displaySettings?: IFrontendActionDisplaySettings
    ): IDisposable {

        this.registry.set(actionId, {
            processorFactory: actionProcessorFactory ?? (_ => ({ processAsync })),
            displaySettings
        });

        return {
            dispose: () => this.registry.remove(actionId)
        };
    }

    @State.bound
    public async dispatchAsync<TCallContextParams = any>(action: FrontendActionBase, callContextParams?: TCallContextParams) {

        assertNotNull(action, "action should not be null");
        assertNotNull(action.id, "action.id should not be null");

        if (action instanceof UnknownFrontendAction) {
            Log.warn(`Dispatching an unknown action: ${action.unknownType}`);
            return;
        }

        let actionRegistryItem = this.registry.getOrNull(action.id);

        if (!actionRegistryItem) {
            if (!!this.parentStore) {
                await this.parentStore.dispatchAsync(action, callContextParams);
                return;
            } else {

                actionRegistryItem = this.globalActionRegistry.getOrNull(action.id);

                if (!actionRegistryItem) {
                    throw new Error(`ActionDispatcher: unknown FrontendAction '${action.id.value}'. Register an ActionProcessor in the corresponding package initializer.`);
                }
            }
        }

        const processor = actionRegistryItem.processorFactory(this.containerService, this.contextualServices);

        await processor.processAsync(action, callContextParams);
    }

    @State.bound
    public getDisplaySettings(action: FrontendActionBase): IFrontendActionDisplaySettings {
        let registryItem = this.registry.getOrNull(action.id);
        if (!registryItem) {
            if (!!this.parentStore) {
                return this.parentStore.getDisplaySettings(action);
            }

            registryItem = this.globalActionRegistry.getOrNull(action.id);
            if (!registryItem) {
                return {
                    iconName: undefined,
                    displayName: this.localizationService.localizeReferenceData(new ResourceId(`FrontendAction.${action.id.value}`))
                };
            }
        }

        const staticDisplaySettings = registryItem.displaySettings ?? {};
        const displaySettings = {
            ...staticDisplaySettings
        };
        registryItem.conditionalDisplaySettings?.map(s => s(action) ?? {}).forEach(s => Object.assign(displaySettings, s));

        return {
            iconName: displaySettings.iconName,
            displayName: displaySettings.displayName ?? this.localizationService.localizeReferenceData(new ResourceId(`FrontendAction.${action.id.value}`))
        };
    }
}

export const ActionDispatcherReactContext = React.createContext<ActionDispatcherStore | null>(null);

export function useActionDispatcher(): IActionDispatcher {
    return useContext(ActionDispatcherReactContext);
}

export function ActionDispatcherProvider(props: React.PropsWithChildren<any>) {

    const parentDispatcher = useActionDispatcher();
    const modalService = useModalService();

    const { containerService, globalActionRegistry, localizationService } = useDependencies(c => ({
        containerService: c.resolve<IContainerService>("IContainerService"),
        globalActionRegistry: c.resolve<GlobalActionRegistry>("GlobalActionRegistry"),
        localizationService: c.resolve<ILocalizationService>("ILocalizationService"),
    }));

    const store = useMemo(() => {

        const contextualServices: IContextualServices = {
            modalService
        };

        return new ActionDispatcherStore(
            containerService,
            globalActionRegistry,
            contextualServices,
            parentDispatcher,
            localizationService
        );
    }, []);

    return (
        <ActionDispatcherReactContext.Provider value={store}>
            {props.children}
        </ActionDispatcherReactContext.Provider>
    );
}

export function RegisterLocalActionProcessor(props: { actionId: FrontendActionId, processAsync?(action: FrontendActionBase): Promise<void>, actionProcessorFactory?: ActionProcessorFactory, displaySettings?: IFrontendActionDisplaySettings }) {
    const dispatcher = useActionDispatcher();

    useEffect(() => dispatcher.registerLocal(props.actionId, props.processAsync, props.actionProcessorFactory, props.displaySettings).dispose, [props.actionId, props.processAsync]);

    return null as React.ReactElement;
}

export class ActionDispatcherAdapter<TProps = { _actionDispatcher: IActionDispatcher }> implements IComponentAdapter<TProps> {
    public get usedContexts(): Array<React.Context<any>> {
        return [ActionDispatcherReactContext];
    }

    constructor(
        private readonly mapToProps: (actionDispatcher: IActionDispatcher, props: TProps) => Partial<TProps> = (actionDispatcher) => ({ _actionDispatcher: actionDispatcher } as any)
    ) { }

    public getMappedProps(props: TProps, contextValues: Map<React.Context<any>, any>): Partial<TProps> {
        const careActivityContext = contextValues.get(ActionDispatcherReactContext) as IActionDispatcher;
        if (!careActivityContext) {
            return emptyObject;
        }
        return this.mapToProps(careActivityContext, props);
    }
}