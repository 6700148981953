import "./ApiClientConfiguration";

import "./Profiles/ApiAdapterConfiguration";
import "./Profiles/StoreConfiguration";

import "./StoreConfiguration";
import "./Groups/ApiAdapterConfiguration";

import "./Users/ApiAdapterConfiguration";

import DependencyContainer from "@DiContainer";
import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import UserManagementBoundedContext from "@HisPlatform/BoundedContexts/UserManagement/UserManagementBoundedContext";

DependencyContainer
    .bind("IBoundedContext")
    .to(UserManagementBoundedContext);