import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import FormDataElementBase from "./FormDataElementBase";

export default class DateFormDataElement extends FormDataElementBase {

    constructor(
        name: string,
        isArray: boolean,
        defaultValue: LocalDate,
        isReadOnly: boolean,
        isVisible: boolean,
    ) {
        super(name, isArray, defaultValue, isReadOnly, isVisible);
    }

    public formDataElementType = "Date";
}