import React from "react";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ReportingReferenceDataStore from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/Model/ReportingReferenceDataStore";
import ReportingApiAdapter from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/ReportingApiAdapter";
import { NestedPermissionCheckContextProvider } from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import HunWorklistApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/HunWorklistApiAdapter";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import contagiousPatientReportStandaloneRouteDefinitions from "@HunSocialSecurityPlugin/UseCases/ContagiousPatientReportsUseCase/ContagiousPatientReportRoutes";

interface IHunSocialSecurityReportingToolbarDependencies {
    reportingReferenceDataStore: ReportingReferenceDataStore;
    reportingApiAdapter: ReportingApiAdapter;
    worklistApiAdapter: WorklistApiAdapter;
    hunWorklistApiAdapter: HunWorklistApiAdapter;
    globalRoutingStore: GlobalRoutingStore;
}

interface IHunSocialSecurityReportingToolbarProps {
    _dependencies?: IHunSocialSecurityReportingToolbarDependencies;
}

@State.observer
class HunSocialSecurityReportingToolbar extends React.Component<IHunSocialSecurityReportingToolbarProps> {

    private get globalRoutingStore() { return this.props._dependencies.globalRoutingStore; }
    private get reportingReferenceDataStore() { return this.props._dependencies.reportingReferenceDataStore; }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private async loadAsync() {
        await this.reportingReferenceDataStore.reportingDefinitions.ensureLoadedAsync();
    }

    @State.bound
    private navigateToContagiousPatientReports() {
        this.globalRoutingStore.push(contagiousPatientReportStandaloneRouteDefinitions.contagiousPatientReports.makeRoute());
    }

    private get permissionCheckedOperations() {
        const res = {};

        res["GetInstituteContagiousPatientReports"] = async () => {
            const definition = await this.props._dependencies.hunWorklistApiAdapter.getContagiousPatientReportForInstituteBoundWorklistDefinitionAsync();
            await this.props._dependencies.worklistApiAdapter.getWorklistDataByDefinitionIdPermissionCheck(definition.value);
        };

        return res;
    }

    public render() {

        if (!this.reportingReferenceDataStore.reportingDefinitions) {
            return null;
        }

        return (
            <NestedPermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                {this.navBarItems}
            </NestedPermissionCheckContextProvider>
        );
    }

    private get navBarItems() {
        const navBarItems: JSX.Element[] = [];

        const contagiousPatientReportNavBarItem = (
            <Ui.NavBar.Item
                key="contagiousPatientReport"
                onClick={this.navigateToContagiousPatientReports}
                automationId="contagiousPatientReports"
                permissionCheckOperationNames="GetInstituteContagiousPatientReports"
                permissionDeniedStyle="disabled"
            >
                {StaticWebAppResources.Navbar.ContagiousPatientReport.ContagiousPatientReport}
            </Ui.NavBar.Item>
        );

        navBarItems?.push(contagiousPatientReportNavBarItem);

        return navBarItems as JSX.Element[];
    }
}
export default connect(
    HunSocialSecurityReportingToolbar,
    new DependencyAdapter<IHunSocialSecurityReportingToolbarProps, IHunSocialSecurityReportingToolbarDependencies>(c => ({
        reportingReferenceDataStore: c.resolve("ReportingReferenceDataStore"),
        reportingApiAdapter: c.resolve("ReportingApiAdapter"),
        worklistApiAdapter: c.resolve("WorklistApiAdapter"),
        hunWorklistApiAdapter: c.resolve("HunWorklistApiAdapter"),
        globalRoutingStore: c.resolve("GlobalRoutingStore")
    }))
);