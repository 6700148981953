import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import Di from "@Di";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";

@Di.injectable()
export default class AvailablePointOfCareIdListApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IActionsClient") private readonly apiClient: Proxy.IActionsClient
    ) {
        super();
    }

    public getAvailableIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<PointOfCareId[]>(),
            async target => {
                const response = await this.apiClient.getAvailablePointsOfCareForCurrentUserQueryAsync(CreateRequestId());

                target.operationInfo = createOperationInfo(response);
                target.value = response.availablePointsOfCare;
            }
        );

    }
}
