import React from "react";
import Styles from "./MenuSection.less";
import Icon from "@CommonControls/Icon";
import StaticWebAppResources from "@StaticResources";

interface INewMenuItemSectionProps {
    onNewItemClick?: () => void;
    newItemText?: string;
}


const NewMenuItemSection: React.FC<INewMenuItemSectionProps> = (props) => {
    const newItemClick = React.useMemo(() => {
        return (event: React.MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();
            props.onNewItemClick();
        };
    }, [props.onNewItemClick]);

    return (
        <div className={Styles.extraFunctionsContainer}>
            <div className={Styles.extraFunctionItem} onMouseDown={newItemClick}>
                <Icon iconName="plus" style={{ marginRight: "4px" }} automationId="newItem" />
                {props.newItemText || StaticWebAppResources.Selectors.Common.NewItem}
            </div>
        </div>
    );
};

export default NewMenuItemSection;
