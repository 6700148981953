exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LockIndicator_container_1f8Ez {\n  display: inline;\n}\n.LockIndicator_container_1f8Ez svg {\n  margin-left: 8px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "LockIndicator_container_1f8Ez"
};