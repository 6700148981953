exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MedicationSubsidyOptionList_valid_1DE4_ {\n  color: #21C313;\n}\n.MedicationSubsidyOptionList_invalid_18ONo {\n  color: #CE5374;\n}\n.MedicationSubsidyOptionList_valid-icon_2vyRf {\n  fill: #21C313;\n}\n.MedicationSubsidyOptionList_icon-column_gmq9D {\n  width: 30px;\n}\n", ""]);

// exports
exports.locals = {
	"valid": "MedicationSubsidyOptionList_valid_1DE4_",
	"invalid": "MedicationSubsidyOptionList_invalid_18ONo",
	"valid-icon": "MedicationSubsidyOptionList_valid-icon_2vyRf",
	"validIcon": "MedicationSubsidyOptionList_valid-icon_2vyRf",
	"icon-column": "MedicationSubsidyOptionList_icon-column_gmq9D",
	"iconColumn": "MedicationSubsidyOptionList_icon-column_gmq9D"
};