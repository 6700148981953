exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PractitionerAppointmentsSchedule_entry_9Z5NW {\n  color: #4f5b72 !important;\n  background-color: #DDF1F7 !important;\n  padding-left: 5px;\n  border-left: 6px solid #00b4ec !important;\n  border-bottom: 1px solid rgba(103, 106, 108, 0.2) !important;\n}\n.PractitionerAppointmentsSchedule_entry-block_Au-IG {\n  color: #4f5b72 !important;\n  background-color: #f8e5ea !important;\n  border-left: 6px solid #ce5374 !important;\n  border-bottom: 1px solid rgba(103, 106, 108, 0.2) !important;\n}\n.PractitionerAppointmentsSchedule_practitionerSelectBox_3hYb0 {\n  padding-bottom: 15px;\n  min-width: 250px;\n}\n.PractitionerAppointmentsSchedule_calendar_75SVf {\n  color: #00b4ec;\n}\n.PractitionerAppointmentsSchedule_edit-icon_2nMzx {\n  fill: #4f5b72 !important;\n}\n.PractitionerAppointmentsSchedule_schedulerPlaceholder_3f9Qv {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"entry": "PractitionerAppointmentsSchedule_entry_9Z5NW",
	"entry-block": "PractitionerAppointmentsSchedule_entry-block_Au-IG",
	"entryBlock": "PractitionerAppointmentsSchedule_entry-block_Au-IG",
	"practitionerSelectBox": "PractitionerAppointmentsSchedule_practitionerSelectBox_3hYb0",
	"calendar": "PractitionerAppointmentsSchedule_calendar_75SVf",
	"edit-icon": "PractitionerAppointmentsSchedule_edit-icon_2nMzx",
	"editIcon": "PractitionerAppointmentsSchedule_edit-icon_2nMzx",
	"schedulerPlaceholder": "PractitionerAppointmentsSchedule_schedulerPlaceholder_3f9Qv"
};