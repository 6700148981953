import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DataGrid from "@CommonControls/DataGrid/DataGrid";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IWorklistRow from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/IWorklistRow";
import { IPagingState, IOrderingState, DataGridLoadType, IStatePersisterSettings, IRowIndicatorStyle, IRowBody, IEmptyStateSettings, RowId, IRowCheckState } from "@CommonControls/DataGrid/IDataGridProps";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import ToolbarLayout from "@CommonControls/ToolbarLayout/ToolbarLayout";
import Message from "@CommonControls/Message";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { renderNDataSecondaryMenu } from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataSecondaryMenu";
import INDataRow from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataRow";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import WorklistActionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistActionType.g";

interface INDataGridViewProps extends IHasMasterDetailState {
    activityRegistry: IActivityRegistry;

    isLoading: boolean;
    showLoadingIndicator: boolean;
    dataSource: IPagedItems<IWorklistRow>;
    paging: IPagingState;
    ordering: IOrderingState;
    filterStore: IFilterStore;
    onChangeAsync: (type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState | IOrderingState[], filter: any, columns: IDataGridColumnProps[]) => Promise<void>;
    selectedRow: IWorklistRow;

    worklistUniqueIdentifier: string;
    columns: React.ReactNode;
    extraFilter: React.ReactNode;
    hasRefreshButton: boolean;
    hasExtraFilterButton: boolean;
    hasFilterClearButton: boolean;
    hasBackButton?: boolean;
    onBack?: () => void;
    defaultExtraFilterVisibility: boolean;
    someItemsAreHidden: boolean;
    rowBodyHeight: React.ReactText;
    rowBodyPadding: React.ReactText;
    statePersisterSettings?: Partial<IStatePersisterSettings>;

    onActionAsync: (rowId: string, actionToken: string, argumentItems: { useCaseDisplayMode?: UseCaseDisplayMode }, worklistActionType: WorklistActionType, activityReference: string, clientSideAction?: ClientSideActionDto) => Promise<void>;
    getRowIndicatorStyle: (row: INDataRow, rowId: string, rowIndex: number) => IRowIndicatorStyle;
    onRenderRowBody: (row: INDataRow, rowId: string, rowIndex: number) => IRowBody;
    onRowClick?: (row: INDataRow, rowId: React.ReactText, rowIndex: number) => void;
    onRowRightClick?: (row: INDataRow, rowId: React.ReactText, rowIndex: number) => void;
    onGenerateFilterStore?: () => IDataGridFilterDescriptor[];
    fullHeight?: boolean;
    horizontalScroll?: boolean;
    hidePointerCursorForRows: boolean;
    emptyStateSettings?: IEmptyStateSettings;
    onClearFilter?: () => void;
    onInitializeFilter?: (filter: any) => void;
    titleOfPrintableContent?: string;
    onPrintDataFetchAsync?: () => Promise<IWorklistRow[]>;

    onGetRowCheckState: (row: INDataRow, rowId: RowId, rowIndex: number) => IRowCheckState;
    onRowChecked: (isChecked: boolean, row: INDataRow, rowId: RowId, rowIndex: number) => void;
    showCheckAll: boolean;
    checkAllValue: boolean;
    onCheckAllValueChanged: (value: boolean) => void;
    hasPrintButton?: boolean;
    containerStyle?: React.CSSProperties;
    alwaysVisibleExtraFilter?: React.ReactNode;
}

@State.observer
export default class NDataGridView extends React.Component<INDataGridViewProps> {

    private readonly statePersisterSettings: IStatePersisterSettings = {
        isEnabled: true,
        filter: true,
        ordering: true,
        paging: true,
        selectedRow: false,
        keyPrefix: null,
        getSelectedRowStringId: (selectedRow: IWorklistRow) => selectedRow && selectedRow.__id
    };

    @State.computed
    private get statePersisterKey() {
        return this.props.worklistUniqueIdentifier.getHashCode()?.toString();
    }

    private getRowId(row: any, rowIndex: number) {
        return row.__id;
    }

    @State.computed
    private get containerStyle() {
        if (this.props.containerStyle) {
            return this.props.containerStyle;
        }

        return { height: this.props.fullHeight ? "100%" : "auto"};
    }
    public render() {
        return (
            <ToolbarLayout
                containerStyle={this.containerStyle}
                topToolbar={this.props.someItemsAreHidden && (
                    <Message type="warning">{StaticWebAppResources.DailyPatientList.Label.OneOrMoreHidden}</Message>
                )}
                topToolbarHeight="auto"
                body={(
                    <DataGrid
                        isLoading={this.props.isLoading}
                        changeOnMount
                        dataSource={this.props.dataSource}
                        paging={this.props.paging}
                        ordering={this.props.ordering}
                        filterStore={this.props.filterStore}
                        onContextMenu={this.props.onRowRightClick ? undefined : this.renderContextMenu}
                        onRowRightClick={this.props.onRowRightClick}
                        getRowIndicatorStyle={this.props.getRowIndicatorStyle}
                        statePersisterSettings={{ ...this.statePersisterSettings, ...this.props.statePersisterSettings }}
                        statePersisterKey={this.statePersisterKey}
                        onChangeAsync={this.props.onChangeAsync}
                        generateInitialFilterStore
                        rowHeight={53}
                        rowBody={this.props.onRenderRowBody}
                        rowBodyHeight={this.props.rowBodyHeight}
                        rowBodyPadding={this.props.rowBodyPadding}
                        fixedLayout
                        isSelectable
                        selectOnRowClick={false}
                        automationId="NDataGrid"
                        selectedRow={this.props.selectedRow}
                        fixedHeight={this.props.fullHeight ? "100%" : undefined}
                        hasFilterClearButton={this.props.hasFilterClearButton}
                        hasRefreshButton={this.props.hasRefreshButton}
                        hasExtraFilterButton={this.props.hasExtraFilterButton}
                        defaultExtraFilterVisibility={this.props.defaultExtraFilterVisibility}
                        extraFilter={this.props.extraFilter}
                        onRowClick={this.props.onRowClick}
                        rowId={this.getRowId}
                        onGenerateFilterStore={this.props.onGenerateFilterStore}
                        horizontalScroll={this.props.horizontalScroll}
                        hidePointerCursorForRows={this.props.hidePointerCursorForRows}
                        emptyStateSettings={this.props.emptyStateSettings}
                        hasBackButton={this.props.hasBackButton}
                        onBack={this.props.onBack}
                        onClearFilter={this.props.onClearFilter}
                        initializeFilter={this.props.onInitializeFilter}
                        titleOfPrintableContent={this.props.titleOfPrintableContent}
                        onPrintDataFetchAsync={this.props.onPrintDataFetchAsync}
                        hasPrintButton={this.props.hasPrintButton}
                        rowCheckState={this.props.onGetRowCheckState}
                        onRowChecked={this.props.onRowChecked}
                        showCheckAll={this.props.showCheckAll}
                        checkAllValue={this.props.checkAllValue}
                        onCheckAllValueChanged={this.props.onCheckAllValueChanged}
                        alwaysVisibleExtraFilter={this.props.alwaysVisibleExtraFilter}
                        showLoadingIndicator={this.props.showLoadingIndicator}
                    >
                        {this.props.columns}
                    </DataGrid>
                )}
            />
        );
    }

    @State.bound
    private renderContextMenu(row: INDataRow, rowId: string, rowIndex: number) {
        return renderNDataSecondaryMenu(row, this.props.onActionAsync, this.props.activityRegistry);
    }
}
