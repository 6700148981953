import Di from "@Di";
import MedicalAlertStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalAlertStatement/MedicalAlertStatementStore";
import MedicalAlertStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalAlertStatement/MedicalAlertStatementHistoryItem";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import MedicalAlertStatementId from "@Primitives/MedicalAlertStatementId.g";
import moment from "moment";

@Di.injectable()
export default class MedicalAlertStatementFactory {

    public createNewStore() {
        const store = new MedicalAlertStatementStore(true);
        store.id = new MedicalAlertStatementId("new");
        store.name = "";
        store.historyItems.push(this.createNewHistoryItem());
        store.currentItemIndex = 0;
        store.validationResults = [];

        return store;
    }

    private createNewHistoryItem() {
        const historyItem = new MedicalAlertStatementHistoryItem();

        historyItem.isNew = true;
        historyItem.versionNumber = 1;
        historyItem.index = 0;
        historyItem.createdAt = moment(moment.now());
        historyItem.historyItemDetail = new MedicalConditionHistoryItemDetail();
        historyItem.description = "";
        historyItem.medicalAlertId = null;
        historyItem.statementDate = LocalDate.today();

        return historyItem;
    }
}
