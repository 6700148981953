import React, { useMemo } from "react";
import * as Ui from "@CommonControls";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import ISelectBoxGroup from "@CommonControls/SelectBox/ISelectBoxGroup";
import IWorklistDefinitionGroup from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinitionGroup";
import _ from "@HisPlatform/Common/Lodash";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import { iconNameType } from "@CommonControls/Icon";
import { getIconNameByHealthcareProfessions } from "@HisPlatform/BoundedContexts/Organization/Components/Helpers/IconTypeNameHelper";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PermissionCheckContextAdapter from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextAdapter";

interface IWorklistDefinitionSelectBoxDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IWorklistDefinitionSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IWorklistDefinitionSelectBoxDependencies;
    _permissionDenied?: boolean;
    value: IWorklistDefinition;
    definitionGroups: IWorklistDefinitionGroup[];
    onChange: (newValue: any) => void;
    permissionCheckOperationNames?: string;
    permissionDeniedStyle?: "disabled" | "invisible";
}


const WorklistDefinitionSelectBox: React.FC<IWorklistDefinitionSelectBoxProps> = props => {

    if (props._permissionDenied && props.permissionDeniedStyle === "invisible") {
        return (
            <>
            </>
        );
    }

    const groups = useMemo(() => {
        const orderedGroupList = _.sortBy(
            props.definitionGroups,
            [(i: IWorklistDefinitionGroup) => i.shortName]
        );
        return orderedGroupList.map(g => {
            return { label: g.shortName, options: mapDefinitions(g) } as ISelectBoxGroup;
        });
    }, [props.definitionGroups]);

    const valueRenderer = useMemo(() => (value: any, isSelectedValue: boolean) => {
        if (!value || !value.data || isNullOrUndefined(value.data.value)) {
            return "null";
        }

        const healthcareProfessions = value.data.value.healthcareProfessionIds.map(props._dependencies.organizationReferenceDataStore.healthCareProfession.get);
        const iconName: iconNameType = getIconNameByHealthcareProfessions(healthcareProfessions);

        return (
            <SpanWithIcon iconName={iconName} visualStyle={isSelectedValue ? "white" : "primary"}>{`${value.data.value.shortName}`}</SpanWithIcon>
        );
    }, []);

    return (
        <Ui.SelectBox
            {...props}
            items={groups}
            customValueRenderer={valueRenderer}
            getOptionText={getWorklistDefinitionDisplayValue}
            getOptionValue={getWorklistDefinitionValue}
            clearable={false}
            orderItems
        />
    );
};

const mapDefinitions = (group: IWorklistDefinitionGroup) => {
    return group.definitions.map(d => {
        return { value: d } as ISelectBoxItem<IWorklistDefinition>;
    });
};

const getWorklistDefinitionValue = (value: any) => {
    if (isNullOrUndefined(value.value)) {
        return null;
    }
    return value.value.localId;
};

const getWorklistDefinitionDisplayValue = (value: any) => {
    if (isNullOrUndefined(value.value)) {
        return "null";
    }
    return `${value.value.shortName}`;
};

export default connect(
    WorklistDefinitionSelectBox,
    new DependencyAdapter<IWorklistDefinitionSelectBoxProps, IWorklistDefinitionSelectBoxDependencies>((containerService) => {
        return {
            organizationReferenceDataStore: containerService.resolve("OrganizationReferenceDataStore")
        };
    }),
    new PermissionCheckContextAdapter()
);
