import AppointmentScheduleDefinitionId from "@Primitives/AppointmentScheduleDefinitionId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import PlanningPeriod from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/PlanningPeriod";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import LockingEntityStoreBase from "@Toolkit/CommonWeb/Model/LockingEntityStoreBase";

export default class AppointmentScheduleDefinition  extends LockingEntityStoreBase<AppointmentScheduleDefinitionId> implements IDirtyChecked {
    @State.observable public name: string;
    @State.observable public description: string;
    @State.observable.ref public planningPeriods: PlanningPeriod[] = [];
    @State.observable.ref public selectedPlanningPeriod: PlanningPeriod = null;
    public ownedSchedulingServices: SchedulingServiceId[] = [];
    @State.observable.ref public extensionData: any = {};

    @State.action.bound
    public setName(value: string) {
        this.name = value;
    }

    @State.action.bound
    public setDescription(value: string) {
        this.description = value;
    }

    @State.action.bound
    public addPlanningPeriod(value: PlanningPeriod) {
        this.planningPeriods = this.planningPeriods.concat([value]);
    }

    @State.action.bound
    public removePlanningPeriod(value: PlanningPeriod) {
        this.planningPeriods = this.planningPeriods.filter(p => p !== value);
    }

    @State.action.bound 
    public setSelectedPlanningPeriod(value: PlanningPeriod) {
        this.selectedPlanningPeriod = value;
    }

    @State.action.bound
    public setExtensionData(newValue: any) {
        this.extensionData = newValue;
    }
    
    private _dirtyChecker = new StoreDirtyChecker();

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "operationInfo",
        "validationResults",
        "lockInfo",
        "selectedPlanningPeriod",
        "extensionData"
    ];

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this) || this.planningPeriods.some(i => i.isDirty());
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }
}
