import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import { ActionProcessorFactory } from "@Toolkit/ReactClient/ActionProcessing/ActionProcessorFactory";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import IActionProcessor from "@Toolkit/ReactClient/ActionProcessing/IActionProcessor";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import Route from "@Toolkit/ReactClient/Routing/Abstractions/Route";

export default class NavigateToRouteActionProcessor<TAction extends FrontendActionBase> implements IActionProcessor<TAction> {

    constructor(
        private readonly routingStore: GlobalRoutingStore,
        private readonly routeFactory: (action: FrontendActionBase) => Route
    ) { }

    public static createFactory(routeFactory: (action: FrontendActionBase) => Route): ActionProcessorFactory {
        return (containerService: IContainerService) => new NavigateToRouteActionProcessor(
            containerService.resolve<GlobalRoutingStore>("GlobalRoutingStore"),
            routeFactory
        );
    }

    public async processAsync(action: FrontendActionBase): Promise<void> {
        const route = this.routeFactory(action);
        await this.routingStore.pushAsync(route);
    }

}