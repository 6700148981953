/* auto-inject-disable */
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/Care/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientId from "@Primitives/PatientId.g";
import IFinancingClassGroup from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/Model/IFinancingClassGroup";
import { GetTypeNameWithPrefix } from "@HunSocialSecurityPlugin/PluginHelper";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import PerformedServiceClassificationId from "@Primitives/PerformedServiceClassificationId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import NameStore from "@Primitives/NameStore";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { IPagingState, IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import AccidentTypeId from "@Primitives/AccidentTypeId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import InpatientAdmissionTypeId from "@Primitives/InpatientAdmissionTypeId.g";
import InpatientAdmissionTypeByCareAspectId from "@Primitives/InpatientAdmissionTypeByCareAspectId.g";
import InpatientAdmissionTypeByLegalAspectId from "@Primitives/InpatientAdmissionTypeByLegalAspectId.g";
import Urgency from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/Urgency.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import RegionalCareObligationStatus from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/RegionalCareObligationStatus";

@Di.injectable()
export default class HunCareApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("ICareRegisterClient")) private readonly careRegisterClient: Proxy.ICareRegisterClient
    ) {
        super();
    }

    @State.bound
    public async getPossibleFinancingClassIdsAsync(patientId: PatientId, pointOfCareId: PointOfCareId, urgency?: Urgency) {
        const response = await this.careRegisterClient.getPossibleFinancingClassIdsQueryAsync(
            CreateRequestId(),
            isNullOrUndefined(urgency) ? "" : urgency.toString(),
            patientId.value,
            pointOfCareId.value
        );
        return response.possibleFinancingClassGroups.map(item => {
            return {
                PossibleFinancingClassIds: item.financingClassIdList,
                IdentifierTypeId: item.identifierTypeId,
                DefaultFinancingClassId: item.defaultFinancingClass,
                Identifier: item.identifier && new Identifier(item.identifier.identifierSystemId, item.identifier.value)
            } as IFinancingClassGroup;
        });
    }

    @State.bound
    public getPerformedServiceClassificationIdsAsync(): Promise<SimpleStore<PerformedServiceClassificationId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterClient.getPerformedServiceClassificationIdsQueryAsync(...args),
            response => response.performedServiceClassificationIds
        );
    }

    @State.bound
    public getInpatientAdmissionTypeIdsAsync(): Promise<SimpleStore<InpatientAdmissionTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterClient.getInpatientAdmissionTypeIdsQueryAsync(...args),
            response => response.inpatientAdmissionTypeIds
        );
    }

    @State.bound
    public getInpatientAdmissionTypeByCareAspectIdsAsync(): Promise<SimpleStore<InpatientAdmissionTypeByCareAspectId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterClient.getInpatientAdmissionTypeByCareAspectIdsQueryAsync(...args),
            response => response.inpatientAdmissionTypeByCareAspectIds
        );
    }

    @State.bound
    public getInpatientAdmissionTypeByLegalAspectIdsAsync(): Promise<SimpleStore<InpatientAdmissionTypeByLegalAspectId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterClient.getInpatientAdmissionTypeByLegalAspectIdsQueryAsync(...args),
            response => response.inpatientAdmissionTypeByLegalAspectIds
        );
    }

    @State.bound
    public getCtMrExaminationRequestTypeIdsAsync(): Promise<SimpleStore<AccidentTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterClient.getCtMrExaminationRequestTypeIdsQueryAsync(...args),
            response => response.ctMrExaminationRequestTypeIds
        );
    }

    @State.bound
    public getEarningCapacityConsiderationTypeIdsAsync(): Promise<SimpleStore<AccidentTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterClient.getEarningCapacityConsiderationTypeIdsQueryAsync(...args),
            response => response.earningCapacityConsiderationTypeIds
        );
    }

    @State.bound
    public getLaboratoryExaminationRequestTypeIdsAsync(): Promise<SimpleStore<AccidentTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterClient.getLaboratoryExaminationRequestTypeIdsQueryAsync(...args),
            response => response.laboratoryExaminationRequestTypeIds
        );
    }

    @State.bound
    public getPhysiotherapyExaminationRequestTypeIdsAsync(): Promise<SimpleStore<AccidentTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterClient.getPhysiotherapyExaminationRequestTypeIdsQueryAsync(...args),
            response => response.physiotherapyExaminationRequestTypeIds
        );
    }

    @State.bound
    public getTravelExpenseClaimTypeIdsAsync(): Promise<SimpleStore<AccidentTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterClient.getTravelExpenseClaimTypeIdsQueryAsync(...args),
            response => response.travelExpenseClaimTypeIds
        );
    }

    @State.bound
    public getImagingExaminationRequestTypeIdsAsync(): Promise<SimpleStore<AccidentTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterClient.getImagingExaminationRequestTypeIdsQueryAsync(...args),
            response => response.imagingExaminationRequestTypeIds
        );
    }

    @State.bound
    public getRegionalCareObligationStatusAsync(patientId: PatientId, pointOfCareId: PointOfCareId, validOn?: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<RegionalCareObligationStatus>(),
            async target => {
                const response = await this.careRegisterClient.getRegionalCareObligationStatusQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetRegionalCareObligationStatusControllerDto({
                        patientId: patientId,
                        pointOfCareId: pointOfCareId,
                        validOn: validOn
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.regionalCareObligationStatus;
            }
        );
    }
}
