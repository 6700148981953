import PatientId from "@Primitives/PatientId.g";
import PatientAllergyIntoleranceStore from "./PatientAllergyIntoleranceStore";
import LockingEntityStoreBase from "@Toolkit/CommonWeb/Model/LockingEntityStoreBase";
import PatientAllergyIntoleranceId from "@Primitives/PatientAllergyIntoleranceId.g";
import _ from "@HisPlatform/Common/Lodash";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";

export default class PatientAllergyIntoleranceListStore extends LockingEntityStoreBase<PatientId> {
    public patientAllergyIntolerances: PatientAllergyIntoleranceStore[] = [];
    public newPatientAllergyIntoleranceId?: PatientAllergyIntoleranceId;

    public clone(): PatientAllergyIntoleranceListStore {
        const clone = _.cloneDeepWith(this, this.cloneCustomizer) as any;
        return clone;
    }

    @State.bound
    protected cloneCustomizer(value: any, key: number | string, object: object, stack: any): any {
        if (State.isObservableArray(value)) {
            return State.observable((value as IObservableArray<any>).slice());
        }
        return undefined;
    }
}