import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import FormLogicType from "@Primitives/FormLogicType";

function FormLogicGroupBoxPanel() {

    const store = useFormLayoutEditorStore();

    return (
        <Ui.GroupBox isCollapsible title={StaticFormEngineResources.FormDefinitionEditor.SideToolbox.GroupTitle.FormLogic}>

            <Ui.UniversalEnumSelector
                label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.FormLogic.TypeLabel}
                automationId=""
                value={store.formLogicType}
                onChange={store.setFormLogicType}
                enumName={"FormLogicType"}
                enumOrigin="server"
                enumType={FormLogicType} />
            <Ui.Flex style={{ marginTop: "10px" }}>
                <Ui.Flex.Item style={{ justifyContent: "center", display: "flex" }} xs={6}>
                    <Ui.Button size="compact" fluid automationId="" text={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.FormLogic.FormLogicDialogButtonText} onClick={store.showFormLogicTextDialogAsync.fireAndForget} />
                </Ui.Flex.Item>
                <Ui.Flex.Item style={{ justifyContent: "center", display: "flex" }} xs={6}>
                    <Ui.Button size="compact" fluid automationId="" text={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.FormLogic.FormLogicDialogTokenButtonText} onClick={store.showFormLogicTokenDialogAsync.fireAndForget} />
                </Ui.Flex.Item>
            </Ui.Flex>

        </Ui.GroupBox>
    );
}

export default State.observer(FormLogicGroupBoxPanel);