import StoreBase from "@Toolkit/CommonWeb/Model/StoreBase";
import UserGroup from "./UserGroup";
import UserGroupId from "@Primitives/UserGroupId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class UserGroupListStore extends StoreBase {
    @State.observable.ref public groups: UserGroup[] = null;

    @State.action.bound
    public setGroups(groups: UserGroup[]) {
        this.groups = groups;
    }

    @State.bound
    public getGroupById(id: UserGroupId) {
        return this.groups && this.groups.find(g => g.id.value === id.value);
    }
}