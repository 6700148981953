import ILogoutHandlerExtension from "@PluginInterface/BoundedContexts/UserManagement/ILogoutHandlerExtension";
import Di from "@Di";
import AssecoRoomsCallContext from "@AssecoRoomsPlugin/AssecoRoomsCallContext";

@Di.injectable()
export default class LogoutHandlerExtension implements ILogoutHandlerExtension {

    constructor(
        @Di.inject("AssecoRoomsCallContext") private assecoRoomsCallContext: AssecoRoomsCallContext,
    ) { }

    public async logoutAsync(): Promise<void> {
        this.assecoRoomsCallContext.hideAssecoRooms();
        return await Promise.resolve();
    }
}