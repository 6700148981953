import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import StatusChangeReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/StatusChangeReason";
import StatusChangeReasonTypeId from "@Primitives/StatusChangeReasonTypeId.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";

@Di.injectable()
export default class StatusChangeReasonApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IReferenceDataClient") private readonly apiClient: Proxy.IReferenceDataClient
    ) {
        super();
    }

    public loadStatusChangeReasonsAsync(typeId: StatusChangeReasonTypeId) {
        return this.processOperationAsync(
            new SimpleStore<StatusChangeReason[]>(),
            async target => {
                const response = await this.apiClient.getStatusChangeReasonsQueryAsync(
                    CreateRequestId(),
                    typeId.value
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.reasons.map(r => new StatusChangeReason(r.id, r.name, typeId, r.hasAdditionalText));
            }
        );
    }
}