import React, { useCallback, Key } from "react";
import * as Ui from "@CommonControls";
import StaticWebAppResources from "@StaticResources";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import ServiceRequestActions from "./ServiceRequestActions";
import CareActivityId from "@Primitives/CareActivityId.g";
import { RadioButtonGroupFactory } from "@CommonControls/RadioButtonGroup";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ServiceRequestFilter from "@Primitives/ServiceRequestFilter";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { ExternalLocationVersionSelector } from "@Primitives/ExternalLocationId.g";
import Styles from "./ServiceRequestListPanel.less";
import MedicalServicePriorityColumn2 from "@HisPlatform/BoundedContexts/Care/Components/Controls/ServiceRequestManagement/MedicalServicePriorityColumn2/MedicalServicePriorityColumn2";
import MedicalServicePriority from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalServices/Enum/MedicalServicePriority.g";
import ServiceRequestDefinitionColumn2 from "@HisPlatform/BoundedContexts/Care/Components/Controls/ServiceRequestManagement/ServiceRequestDefinitionColumn2/ServiceRequestDefinitionColumn2";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import CareLocationColumn2 from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/CareLocationColumn2/CareLocationColumn2";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import ServiceRequestStatusColumn2 from "@HisPlatform/BoundedContexts/Care/Components/Controls/ServiceRequestManagement/ServiceRequestStatusColumn2/ServiceRequestStatusColumn2";
import PractitionerColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerColumn/PractitionerColumn";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import { IPanelWithButtonPortalProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/IPanelWithButtonPortalProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import ServiceRequestListToolbar from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestListPanel/ServiceRequestListToolbar";
import ServiceRequestState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestState.g";
import ServiceRequestAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestAction.g";

interface IServiceRequestListPanelViewProps extends IPanelWithButtonPortalProps {
    localizationService: ILocalizationService;
    notificationService: INotificationService;
    onCreateNewRequestAsync: () => Promise<void>;
    serviceRequestFilter: ServiceRequestFilter;
    onFilterServiceRequest: (newValue: ServiceRequestFilter) => void;
    isServiceRequestSelected: boolean;

    dataSource: InMemoryDataGridDataSource;
    selectedServiceRequest: ServiceRequestStore;

    onOpenRequest: (serviceRequest: ServiceRequestId) => void;
    onOpenRequestDocumentAsync: (serviceRequest: ServiceRequestStore) => Promise<void>;
    onOpenResults: (row: ServiceRequestId) => void;
    onOpenAdministration: (serviceRequest: ServiceRequestId) => void;
    onOpenFinalDocumentAsync: (serviceRequest: ServiceRequestStore) => Promise<void>;

    onBack: () => void;

    currentCareActivityId: CareActivityId;
    externalLocationVersionSelectors: ExternalLocationVersionSelector[];

    pageboxHeaderNoPadding?: boolean;
    pageboxHeaderSize?: "normal" | "large";
    withoutPageBox?: boolean;
    hideTitle?: boolean;
    showServiceRequestFilter: boolean;

    columnSet?: "normal" | "reduced";
    isListReadOnly: boolean;
    hidePager?: boolean;
}

const getClassNameByState = (row: any, key: Key, rowIndex: number) => {
    switch (row.state) {
        case ServiceRequestState.UnderRecording:
            return { className: Styles.underRecording };
        case ServiceRequestState.Submitted:
        case ServiceRequestState.WaitingForSubmission:
            return { className: Styles.submitted };
        case ServiceRequestState.RegisteredAsReceived:
            return { className: Styles.registeredAsReceived };
        case ServiceRequestState.NonValidatedCompleted:
            return { className: Styles.nonValidatedCompleted };
        case ServiceRequestState.ActivityCompleted:
            return { className: Styles.activityCompleted };
        case ServiceRequestState.ValidatedCompleted:
            return { className: Styles.validatedCompleted };
        case ServiceRequestState.Deleted:
        case ServiceRequestState.WaitingForDelete:
        case ServiceRequestState.HasError:
            return { className: Styles.deleted };
        default:
            return null;
    }
};

const getColumns = (props: IServiceRequestListPanelViewProps) => {

    const isDetailOpen = !!props.isServiceRequestSelected;
    const showAllColumns = !isDetailOpen;

    const openServiceRequest = useCallback((row: ServiceRequestStore) => {
        props.onOpenRequest(row.id);
    }, [props.onOpenRequest]);

    if (props.columnSet === "normal" || (props.columnSet === "reduced" && !props.isServiceRequestSelected)) {
        const isReducedColumnSet = props.columnSet === "reduced";
        return (
            <>
                <ServiceRequestDefinitionColumn2
                    header={StaticCareResources.ServiceRequestManagement.ServiceRequestsList.ServiceRequestDefinitionField}
                    dataGetter={"serviceRequestDefinitionVersion"}
                    onLinkClicked={openServiceRequest}
                    isFilterable
                    automationId="Definition"
                    width="30%"
                />
                <ServiceRequestStatusColumn2
                    isVisible={showAllColumns && !isReducedColumnSet}
                    header={StaticCareResources.ServiceRequestManagement.ServiceRequestsList.StatusField}
                    dataGetter={"state"}
                    nonMatchingChildrenInfoFieldName={"nonMatchingChildrenInfo"}
                    isFilterable
                    automationId="ServiceRequestState"
                    displayMode="badge"
                    width="50px"
                />
                <MedicalServicePriorityColumn2
                    id={"MedicalServicePriority"}
                    key={"MedicalServicePriority"}
                    header={StaticCareResources.ServiceRequestManagement.ServiceRequestsList.PriorityField}
                    dataGetter={"medicalServicePriority"}
                    isOrderable
                    isFilterable
                    automationId="MedicalServicePriority"
                    width="50px"
                />
                <DataGridColumn
                    isVisible={showAllColumns}
                    header={StaticCareResources.ServiceRequestManagement.ServiceRequestsList.RequestIdentifierField}
                    dataGetter={"serviceRequestIdentifier"}
                    isFilterable
                    automationId="ServiceRequestIdentifier"
                    width="15%"
                />
                <CareLocationColumn2
                    isVisible={showAllColumns && !isReducedColumnSet}
                    header={StaticCareResources.ServiceRequestManagement.ServiceRequestsList.ReferralLocationField}
                    dataGetter={"referralLocation"}
                    isFilterable
                    externalLocationVersionSelectors={props.externalLocationVersionSelectors}
                    automationId="ReferralLocation"
                    width="90px"
                />
                <PractitionerColumn
                    isVisible={showAllColumns}
                    header={StaticCareResources.ServiceRequestManagement.ServiceRequestsList.RequestingDoctorField}
                    dataGetter={"requesterDoctorId"}
                    // TODO: isFilterable
                    automationId="RequesterDoctor"
                    isCodeBold
                    width="30%"
                />
                <DataGridDateTimeColumn
                    isVisible={showAllColumns && !isReducedColumnSet}
                    header={StaticCareResources.ServiceRequestManagement.ServiceRequestsList.SubmittedAt}
                    dataGetter={s => s.requestedServices?.length > 0 ? s.requestedServices[0].submitTime : null}
                    // TODO: isFilterable
                    automationId="SubmittedAt"
                />
                <DataGridDateTimeColumn
                    header={StaticCareResources.ServiceRequestManagement.ServiceRequestsList.ExpectedCompletionTime}
                    dataGetter={"expectedCompletionTime"}
                    // TODO: isFilterable
                    automationId="ExpectedCompletionTime"
                />
                <CareLocationColumn2
                    header={StaticCareResources.ServiceRequestManagement.ServiceRequestsList.ExecutingLocationField}
                    dataGetter={"targetCareLocation"}
                    externalLocationVersionSelectors={props.externalLocationVersionSelectors}
                    isFilterable
                    automationId="TargetCareLocation"
                    width="90px"
                />
                <DataGridColumn width={isDetailOpen ? "67px" : (isReducedColumnSet ? "67px" : "145px")}
                    automationId="Actions">
                    <ServiceRequestActions
                        currentCareActivityId={props.currentCareActivityId}
                        onOpenRequest={props.onOpenRequest}
                        onOpenRequestDocumentAsync={props.onOpenRequestDocumentAsync}
                        onOpenResults={props.onOpenResults}
                        onOpenAdministration={props.onOpenAdministration}
                        onOpenFinalDocumentAsync={props.onOpenFinalDocumentAsync}
                        isDetailOpen={isDetailOpen}
                        mininalButtonSet={isReducedColumnSet}
                    />
                </DataGridColumn>
            </>
        );
    } else {
        return (
            <CareLocationColumn2
                dataGetter={"targetCareLocation"}
                externalLocationVersionSelectors={props.externalLocationVersionSelectors}
                automationId="ExecutingLocation"
            />
        );
    }
};


const ServiceRequestListPanelView: React.FC<IServiceRequestListPanelViewProps> = props => {

    const rowClickHandler = useCallback((row: ServiceRequestStore) => {
        if (row.id) {
            const isViewResultsAllowed = row.possibleActions.some(j => j === ServiceRequestAction.ViewDiagnosticReport);
            const isAdministrationAllowed = row.possibleActions.some(i => i === ServiceRequestAction.Administrate);

            if (isViewResultsAllowed) {
                props.onOpenResults(row.id);
            } else if (isAdministrationAllowed) {
                props.onOpenAdministration(row.id);
            } else {
                props.onOpenRequest(row.id);
            }
        }
    }, [props]);

    const isMinimal = props.columnSet === "reduced" && props.isServiceRequestSelected;

    return (
        <MasterDetail.Master
            iconName="stethoscope"
            title={StaticWebAppResources.OutpatientWorkflow.ServiceRequestManagementStep.Title}
            toolbar={(
                <ServiceRequestListToolbar
                    isListReadOnly={props.isListReadOnly}
                    isServiceRequestSelected={!!props.selectedServiceRequest}
                    onCreateNewRequestAsync={props.onCreateNewRequestAsync}
                    onFilterServiceRequest={props.onFilterServiceRequest}
                    serviceRequestFilter={props.serviceRequestFilter}
                    showServiceRequestFilter={props.showServiceRequestFilter}
                />
            )}
        >
            <Ui.DataGrid
                isLoading={isNullOrUndefined(props.dataSource)}
                dataSource={props.dataSource}
                selectedRow={props.selectedServiceRequest}
                rowHeight={!isMinimal ? "54px" : "40px"}
                onRowClick={rowClickHandler}
                getRowIndicatorStyle={!isMinimal && getClassNameByState}
                automationId="_serviceRequestList"
                fixedLayout
                generateInitialFilterStore
                changeOnMount
                fixedHeight="100%"
                isSelectable
                hasHeader={true}
                hidePager={props.hidePager}
                statePersisterKey="serviceRequests"
                hasBackButton={props.columnSet === "normal"}
                onBack={props.onBack}
                hasRefreshButton={props.columnSet === "normal"}
            >
                {getColumns(props)}
            </Ui.DataGrid>
        </MasterDetail.Master>
    );
};

export default ServiceRequestListPanelView;
