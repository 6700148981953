import React, { useEffect } from "react";
import * as Ui from "@CommonControls";
import FamilyDoctorDocumentMedicationFormulaItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentMediactionFormulaItem";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicationCodeSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationCodeSelector";
import MedicationId from "@Primitives/MedicationId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";

interface IFamilyDoctorDocumentMedicationFormulaListItemProps {
    item: FamilyDoctorDocumentMedicationFormulaItem;
    index: number;
}


const FamilyDoctorDocumentMedicationFormulaListItem: React.FC<IFamilyDoctorDocumentMedicationFormulaListItemProps> = props => {
    const setMedication = (value: MedicationId) => {
        const medicationVersionSelector = new EntityVersionSelector<MedicationId>(value, LocalDate.today());
        props.item.setMedicationVersionSelector(medicationVersionSelector);

    };

    return (
        <>
            <Ui.Flex>
                <Ui.Flex.Item xs={7}>
                    <MedicationCodeSelector
                        label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.MedicationCodeSelector}
                        value={props.item.medicationVersionSelector?.id}
                        onChange={setMedication}
                        propertyIdentifier="MedicationVersionSelector"
                        automationId="medicationSelector"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={5}>
                    <Ui.TextBox
                        label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.SubstanceAmount}
                        value={props.item.substanceAmount}
                        onChange={props.item.setSubstanceAmount}
                        propertyIdentifier="SubstanceAmount"
                        automationId="substanceAmount"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </>
    );
};
export default State.observer(FamilyDoctorDocumentMedicationFormulaListItem);