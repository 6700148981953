
import FilterBase from "./FilterBase";

export default class ExplicitIdentifierFilter extends FilterBase {
    private _type: string;
    private _value: string;

    constructor(type: string, value: string) {
        super();
        this._type = type;
        this._value = value;
    }

    public get type(): string {
        return this._type;
    }

    public get value(): string {
        return this._value;
    }

    public setType(type: string) {
        this._type = type;
    }

    public setValue(value: string) {
        this._value = value;
    }
}