import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientId from "@Primitives/PatientId.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { parseUseCaseStateFromUrlParam, getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import IRiskAssessmentRouteParams from "@HisPlatform/Application/Routes/IRiskAssessmentRouteParams";
import RiskAssessmentId from "@Primitives/RiskAssessmentId.g";
import RiskAssessmentListPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/RiskAssessment/RiskAssessmentListPanel";
import PatientRoutes from "@HisPlatform/Components/Pages/Patient/PatientRoutes";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";


interface IRiskAssessmentPageProps extends IRoutingFrameContentProps {
}

@State.observer
class RiskAssessmentPage extends React.Component<IRiskAssessmentPageProps> {

    @State.computed private get routeParams(): IRiskAssessmentRouteParams {
        return this.props.routingController.currentRoute.parameters as IRiskAssessmentRouteParams;
    }

    @State.computed private get selectedRiskAssessmentId(): RiskAssessmentId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments[0].value;
        }

        if (this.useCaseState?.useCase.value === UseCases.riskAssessmentDetail) {
            return new RiskAssessmentId("new");
        }

        return null;
    }

    @State.computed
    private get patientId() {
        return new PatientId(this.routeParams?.patientId);
    }

    @State.computed private get useCaseState() {
        const useCaseState = this.routeParams?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.bound
    private setUseCaseState(selectedRiskAssessmentId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.replace(
            PatientRoutes.riskAssessment.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            })
        );
    }

    @State.bound
    private navigateToRiskAssessment(riskAssessmentId: RiskAssessmentId) {
        this.props.routingController.replace(
            PatientRoutes.riskAssessment.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.riskAssessmentDetail),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(riskAssessmentId, "riskAssessmentId")])
            })
        );
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(
            PatientRoutes.riskAssessment.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    null,
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument(new RiskAssessmentId("null"), "riskAssessmentId")])
            })
        );
    }

    public render() {
        return (
            <LoadingBoundary>
                <RiskAssessmentListPanel
                    _patientId={this.patientId}
                    onChange={this.setUseCaseState}
                    useCaseState={this.useCaseState}
                    selectedRowId={this.selectedRiskAssessmentId?.value}
                    onSelectedRiskAssessmentIdChange={this.navigateToRiskAssessment}
                    onBack={this.goBack}
                    onCloseDetail={this.setClosedState}
                />
            </LoadingBoundary>
        );
    }
}

export default connect(
    RiskAssessmentPage
);
