import FormFieldDataBase from "./FormFieldDataBase";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";

export default class CompositeFormFieldData extends FormFieldDataBase {
    public isArray: boolean = false;
    @State.observable.ref public value: IObservableArray<FormFieldDataBase> = State.createObservableShallowArray([]);

    constructor(fieldName: string, isReadonly: boolean, isVisible: boolean, value: IObservableArray<FormFieldDataBase>) {
        super(fieldName, isReadonly, isVisible);

        this.value = value;
    }
}