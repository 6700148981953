import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ExternalLocationCodeSelector from "@HisPlatform/BoundedContexts/Organization/Components/Controls/ExternalLocationCodeSelector";

interface IExternalLocationColumnDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IExternalLocationColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IExternalLocationColumnDependencies;
    displayMode?: DisplayMode;
}

export type DisplayMode = "shorthand" | "name" | "shorthand-name";

const filterValueSerializer: IDataGridFilterValueSerializer<ExternalLocationId | ExternalLocationId[]> = {
    deserialize: (value: string) => {
        if (!value) {
            return null;
        }
        const items = value.split(",");
        if (items.length === 1) {
            return new ExternalLocationId(items[0]);
        }

        return items.map(i => new ExternalLocationId(i));
    },
    serialize: (value: ExternalLocationId | ExternalLocationId[]) => {
        return Array.isArray(value) ? value.map(v => v.value).join(",") : value.value;
    }
};

export function externalLocationColumnCellRenderer(organizationReferenceDataStore: OrganizationReferenceDataStore, id: ExternalLocationId, displayMode?: DisplayMode) {
        
    const value = { id, validOn: DateTimeService.today() } as IEntityVersionSelector<ExternalLocationId>;
    const item = isNullOrUndefined(value) ? null : organizationReferenceDataStore.externalLocationStore.get(value);

    if (isNullOrUndefined(item)) {
        return null;
    }
    let text: React.ReactNode;

    switch (displayMode) {
        case "name":
            text = item.name;
            break;
        case "shorthand":
            text = item.shortName;
            break;
        case "shorthand-name":
        default:
            text = <><b>{item.shortName}</b>{` - ${item.name}`}</>;
    }

    return (
        <>           
            {text}
        </>
    );
}


const ExternalLocationColumn: React.SFC<IExternalLocationColumnProps> = (props: IExternalLocationColumnProps) => {

    const valueRenderer = useCallback((value: ExternalLocationId) =>
        externalLocationColumnCellRenderer(props._dependencies.organizationReferenceDataStore, value, props.displayMode)
        , []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<ExternalLocationId | ExternalLocationId[]>) => {
        return <ExternalLocationCodeSelector  {...filterProps} hoverOnlyIndicatorIcons />;
    }, []);

    const hintRenderer = (id: ExternalLocationId): string => {
        const value = { id, validOn: DateTimeService.today() } as IEntityVersionSelector<ExternalLocationId>;
        const item = isNullOrUndefined(value) ? null : props._dependencies.organizationReferenceDataStore.externalLocationStore.get(value);

        if (isNullOrUndefined(item)) {
            return null;
        }

        return item.name;
    };

    const getOrderableValue = (id: ExternalLocationId) => {
        const value = { id, validOn: DateTimeService.today() } as IEntityVersionSelector<ExternalLocationId>;
        const item = isNullOrUndefined(value) ? null : props._dependencies.organizationReferenceDataStore.externalLocationStore.get(value);

        if (isNullOrUndefined(item)) {
            return null;
        }

        return item.name;
    };

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            onRenderHintValue={hintRenderer}
            filterValueSerializer={filterValueSerializer}
            clientSideOrderableValueGetter={getOrderableValue}
        />
    );
};

ExternalLocationColumn.defaultProps = {
    displayMode: "shorthand"
};

export default connect(
    ExternalLocationColumn,
    new DependencyAdapter<IExternalLocationColumnProps, IExternalLocationColumnDependencies>(c => ({
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
    }))
);
