import React from "react";
import {nullFunction} from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ServiceRequestSummaryPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceAdministrationPanel/ServiceRequestSummaryPanel";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import ServiceObservationsPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceAdministrationPanel/ServiceObservationsPanel";
import RequestedServiceBatchSelection from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceAdministrationPanel/BatchSelection/RequestedServiceBatchSelection";
import * as HisUi from "@HisPlatformControls";
import {IPanelWithButtonPortalProps} from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/IPanelWithButtonPortalProps";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import {Button, SaveButton} from "@CommonControls";
import CareActivityId from "@Primitives/CareActivityId.g";
import RequestedServiceAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceAction.g";

interface IServiceAdministrationPanelViewProps extends IPanelWithButtonPortalProps {
    requestedServices: RequestedServiceStore[];
    onSaveAsync: () => Promise<void>;
    onChecked: (isChecked: boolean, item: RequestedServiceStore) => void;
    serviceRequest: ServiceRequestStore;
    serviceRequestDefinitionName: string;
    isAdministration: boolean;
    setContentSnapshot?: (contentSnapshot: string) => void;

    onCloseAsync: () => Promise<void>;
    onPrintAsync: () => Promise<void>;
    openForEditAsync: () => Promise<void>;

    onSetAdministrativeActionAsync: (item: RequestedServiceStore, newAction: RequestedServiceAction) => Promise<void>;
    batchSelection: RequestedServiceBatchSelection;
    onBatchSelectionChange: (newValue: RequestedServiceBatchSelection) => void;
    selectedMedicalServiceIdSet: Set<string>;

    onBatchReceiveAsync: () => Promise<void>;
    onBatchCompleteAsync: () => Promise<void>;
    onBatchValidateAsync: () => Promise<void>;
    withoutPageBox?: boolean;

    hasNoVerticalMargin?: boolean;
    isLoading?: boolean;
    isReadOnly?: boolean;
    careActivityId?: CareActivityId;
}

const onSaveHandlerFactory = (props: IServiceAdministrationPanelViewProps) => {
    return async () => {
        await props.onSaveAsync();
        return true;
    };
};


const ServiceAdministrationPanelView: React.SFC<IServiceAdministrationPanelViewProps> = props => {

    const toolbar = (
        <>
            {props.isAdministration &&
                props.serviceRequest.lockInfo.lockState !== EntityLockState.LockingRequiredAndLockHeld &&
                <HisUi.LockIndicatorComponent locked={!props.serviceRequest.isMutable}
                    onEditClickedAsync={props.openForEditAsync} style={{ alignContent: "right" }}
                    lockedBy={props.serviceRequest?.lockInfo?.preventingLockUserId}
                />}
            {props.careActivityId && (
                <Button
                    iconName="print"
                    text={StaticCareResources.Common.Button.PrintPreview}
                    onClickAsync={props.onPrintAsync}
                    automationId="__printResultDocument"
                />
            )}
            {!props.isReadOnly && props.isAdministration && props.serviceRequest.isMutable && (
                <SaveButton
                    onClickAsync={props.onSaveAsync}
                    automationId="__standAloneSaveButton"
                />
            )}
        </>
    );

    return (
        <UseCaseFrame
            title={props.serviceRequestDefinitionName}
            toolbar={toolbar}
        >
            <ReadOnlyContext.Provider value={props.isReadOnly || (!props.isAdministration && !props.serviceRequest.isMutable)}>
                <ServiceRequestSummaryPanel
                    isLoading={props.isLoading}
                    isAdministration={props.isAdministration}
                    serviceRequestSummaryTitle={props.serviceRequestDefinitionName}
                    serviceRequest={props.serviceRequest}
                    onCloseAsync={props.onCloseAsync}
                    onDeleteAsync={nullFunction as any}
                    onPrintAsync={props.onPrintAsync}
                    withoutPageBox={props.withoutPageBox}
                    hideTitle={props.withoutPageBox}
                    hasNoTopMargin={props.hasNoVerticalMargin}
                    openForEditAsync={props.openForEditAsync}
                />
                <ServiceObservationsPanel
                    isLoading={props.isLoading}
                    onChecked={props.onChecked}
                    batchSelection={props.batchSelection}
                    onBatchSelectionChange={props.onBatchSelectionChange}
                    requestedServices={props.requestedServices}
                    selectedMedicalServiceIdSet={props.selectedMedicalServiceIdSet}
                    isAdministration={props.isAdministration}
                    onSaveAsync={props.onSaveAsync}
                    onBatchReceiveAsync={props.onBatchReceiveAsync}
                    onBatchCompleteAsync={props.onBatchCompleteAsync}
                    onBatchValidateAsync={props.onBatchValidateAsync}
                    onSetAdministrativeActionAsync={props.onSetAdministrativeActionAsync}
                    withoutPageBox={props.withoutPageBox}
                    hasNoBottomMargin={props.hasNoVerticalMargin}
                    setContentSnapshot={props.setContentSnapshot}
                />
                {!props.hidePortalButtons && props.buttons && (
                    <HisUi.HisPanelButtonPortal>
                        <ReadOnlyContext.Provider value={false}>
                            {React.cloneElement(props.buttons, {
                                onSavePanelAsync: onSaveHandlerFactory(props),
                                onSavePanelAndReleaseLockAsync: onSaveHandlerFactory(props)
                            })}
                        </ReadOnlyContext.Provider>
                    </HisUi.HisPanelButtonPortal>
                )}
            </ReadOnlyContext.Provider>
        </UseCaseFrame>
    );
};

export default State.observer(ServiceAdministrationPanelView);
