import React from "react";
import IDataGridProps, { RowReference, RowId, DataGridLoadType, IPagingState, IOrderingState, IRowIndicatorStyle } from "./IDataGridProps";
import DataGridView from "@CommonControls/DataGrid/DataGridView";
import { IRowData } from "@CommonControls/DataGrid/ViewModel";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import _ from "@HisPlatform/Common/Lodash";
import ColumnContainer from "@CommonControls/DataGrid/ColumnContainer";
import { createAsyncErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import { isNullOrUndefined, emptyArray, arrayIsNullOrEmpty, isNullOrWhiteSpace } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ContextMenu from "@CommonControls/ContextMenu";
import Guid from "@Toolkit/CommonWeb/Guid";
import { generateFilterStore } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import IDataGridDataSource from "@CommonControls/DataGrid/DataSource/IDataGridDataSource";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import ISearchParametersService from "@Toolkit/CommonWeb/SearchParametersService/Definition/ISearchParametersService";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import ValidationBoundaryAdapter from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryAdapter";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import Styles from "./DataGrid.less";
import { createFilterDescriptorsByColumns } from "@CommonControls/DataGrid/Filter/FilterHelpers";
import ViewContextAdapter from "@Toolkit/ReactClient/Components/ViewContext/ViewContextAdapter";
import IToolkitViewContextSettings from "@Toolkit/ReactClient/Components/ViewContext/IToolkitViewContextSettings";
import ComponentPrinter from "@CommonControls/ComponentPrinter";
import DataGridDefaultFooter from "./DataGridDefaultFooter";

interface IDataGridDependencies {
    searchParametersService: ISearchParametersService;
}

type IDataGridPropsWithDependencies = IDataGridProps & {
    _dependencies?: IDataGridDependencies;
    _validationBoundary?: IValidationBoundaryStore;
};

export const dataGridDefaultProps: Partial<IDataGridProps> = {
    hasHeader: true,

    onRenderRow: (props) => <tr {...props} />,
    rowId: (row: any, rowIndex: number) => rowIndex,
    footer: <DataGridDefaultFooter />,
    rowComparer: "reference",
    adjacentButtonsCount: 2,
    autoFilterTimeout: 500,
    availablePageSizes: [10, 20, 50, 100],
    selectOnRowClick: true,
    statePersisterKey: "g",
    actionsColumn: false,
    defaultExtraFilterVisibility: false,
    showSeparatorLines: true,
    autoReloadOnFilter: true,
    fixedLayout: true,
    rowHeight: 48
};

export const initialFilterStore = generateFilterStore([]);

@State.observer
class DataGrid extends React.Component<IDataGridPropsWithDependencies> {

    public static defaultProps = dataGridDefaultProps;

    private runAsync = createAsyncErrorDispatcher(this);
    private get searchParametersService() { return this.props._dependencies.searchParametersService; }

    private contextMenuId = Guid.newGuid();
    @State.observable.ref private isInitialized = false;
    @State.observable.ref private internalLoadingState = false;
    @State.observable.ref private _columns: IDataGridColumnProps[] = [];
    @State.observable.ref private internalSelectedRowReference: RowReference<any> = null;
    @State.observable.ref private rightClickedRow: { row: IRowData, rowIndex: number } = null;
    @State.observable.ref private _isExtraFilterVisible: boolean = this.props.defaultExtraFilterVisibility;
    private disposers: Array<() => void> = [];
    private filterStoreReactionDisposer: () => void = null;

    private printContainer = React.createRef<HTMLDivElement>();
    private componentPrinterRef = React.createRef<ComponentPrinter>();
    @State.observable private isPrinting = false;
    private printableData: any[] | IPagedItems<any> = null;
    private isHeaderVisible = true;

    @State.computed private get printContainerRef() {
        return () => this.printContainer.current;
    }

    @State.computed private get isExtraFilterVisible() {
        if (this.props.isExtraFilterVisible !== undefined) {
            return this.props.isExtraFilterVisible;
        } else {
            return this._isExtraFilterVisible;
        }
    }

    @State.computed private get columns() {
        return this._columns.filter(c => !!c.isVisible);
    }

    @State.computed private get isDataSource() {
        return !isNullOrUndefined(this.props.dataSource) && "__dataGridDataSource" in this.props.dataSource;
    }

    @State.computed private get rows(): IRowData[] {

        if (isNullOrUndefined(this.props.dataSource)) {
            return emptyArray;
        }

        if (Array.isArray(this.props.dataSource)) {
            return this.props.dataSource.map(this.rowMapper);
        }

        if (this.isDataSource) {
            const dataSource = this.props.dataSource as IDataGridDataSource;
            if (Array.isArray(dataSource.data)) {
                return dataSource.data.map(this.rowMapper);
            }
            return isNullOrUndefined(dataSource.data) ? emptyArray : dataSource.data.items.map(this.rowMapper);
        }

        return (this.props.dataSource as IPagedItems<any>).items.map(this.rowMapper);
    }

    @State.computed private get printableRows() {
        if (isNullOrUndefined(this.printableData)) {
            return emptyArray;
        }

        if (Array.isArray(this.printableData)) {
            return this.printableData.map(this.rowMapper);
        }

        return (this.printableData as IPagedItems<any>).items.map(this.rowMapper);
    }

    @State.computed private get totalRowCount(): number {

        if (isNullOrUndefined(this.props.dataSource)) {
            return 0;
        }

        if (Array.isArray(this.props.dataSource)) {
            return this.props.dataSource.length;
        }

        if (this.isDataSource) {
            const dataSource = this.props.dataSource as IDataGridDataSource;
            if (Array.isArray(dataSource.data)) {
                return dataSource.data.length;
            }
            return isNullOrUndefined(dataSource.data) ? 0 : dataSource.data.totalCount;
        }

        return (this.props.dataSource as IPagedItems<any>).totalCount;
    }

    @State.computed private get selectedRow(): IRowData {

        if (!this.props.isSelectable) {
            return null;
        }

        const rowReference = this.props.selectedRow === undefined ? this.internalSelectedRowReference : this.props.selectedRow;

        if (this.props.rowComparer === "rowId") {
            const found = this.rows.find(r => r.id === rowReference);
            return found;
        }

        if (this.props.rowComparer === "reference") {
            const found = this.rows.find(r => r.data === rowReference);
            return found;
        }

        if (typeof this.props.rowComparer === "function") {
            const found = this.rows.find(r => (this.props.rowComparer as (left: any, right: any) => boolean)(r.data, rowReference));
            return found;
        }

        return null;
    }

    @State.computed private get rowUnderEditing(): IRowData | "all" {
        if (isNullOrUndefined(this.props.rowUnderEditing)) {
            return null;
        }

        if (this.props.rowUnderEditing === "all") {
            return "all";
        }

        if (this.props.rowComparer === "rowId") {
            const found = this.rows.find(r => r.id === this.props.rowUnderEditing);
            return found;
        }

        if (this.props.rowComparer === "reference") {
            const found = this.rows.find(r => r.data === this.props.rowUnderEditing);
            return found;
        }

        if (typeof this.props.rowComparer === "function") {
            const found = this.rows.find(r => (this.props.rowComparer as (left: any, right: any) => boolean)(r.data, this.props.rowUnderEditing));
            return found;
        }

        return null;
    }

    @State.computed private get ordering() {
        if (this.isDataSource) {
            return (this.props.dataSource as IDataGridDataSource).ordering;
        }
        return this.props.ordering;
    }

    @State.computed private get paging() {
        if (this.isDataSource) {
            return (this.props.dataSource as IDataGridDataSource).paging;
        }
        return this.props.paging;
    }

    @State.computed private get filterStore() {
        if (this.isDataSource) {
            return (this.props.dataSource as IDataGridDataSource).filterStore;
        }
        return this.props.filterStore;
    }

    @State.computed private get pageCount() {
        if (!this.paging) {
            return 0;
        }

        return Math.ceil(this.totalRowCount / this.paging.pageSize);
    }

    @State.computed private get orderingMap() {
        if (Array.isArray(this.ordering)) {
            return new Map<string | number, IOrderingState>(this.ordering.map(o => [o.columnId, o] as [string | number, IOrderingState]));
        }

        return this.ordering ? new Map<string | number, IOrderingState>(
            [[this.ordering.columnId, this.ordering]]
        ) : new Map();
    }

    @State.computed private get isLoading() {
        return this.internalLoadingState || this.props.isLoading;
    }

    @State.computed private get onChangeAsync() {
        if (this.isDataSource) {
            const dataSource = this.props.dataSource as InMemoryDataGridDataSource;
            return dataSource.onChangeAsync;
        }
        return this.props.onChangeAsync;
    }

    @State.computed private get rowsWithValidationProblem() {
        if (!!this.props.onMapValidationProblem && !!this.props._validationBoundary) {

            const ret = new Map<RowReference<any>, IClientValidationProblem[]>();

            this.props._validationBoundary.getValidationProblems(this.props.propertyIdentifier)?.forEach(problem => {
                const rowReferences = this.props.onMapValidationProblem(problem);

                rowReferences?.forEach(rowReference => {
                    const problemsOnRow = ret.get(rowReference);
                    if (!problemsOnRow) {
                        ret.set(rowReference, [problem]);
                    } else {
                        problemsOnRow.push(problem);
                    }
                });
            });

            return ret;
        }
        return null;
    }

    @State.computed private get rowIndicatorsState(): { [key: number]: IRowIndicatorStyle } {
        return this.rows.reduce((ret, row, rowIndex) => {

            let style: IRowIndicatorStyle;

            if (!!this.rowsWithValidationProblem) {
                const problems = this.rowsWithValidationProblem.get(this.getRowReference(row.data, row.id));
                if (!arrayIsNullOrEmpty(problems)) {
                    const hasError = problems.some(p => p.severity === "error");

                    style = {
                        className: (hasError ? Styles.rowErrorIndicator : Styles.rowWarningIndicator),
                        rowBackgroundClassName: (hasError ? Styles.rowError : Styles.rowWarning),
                    };
                }
            } else if (!!this.props.getRowIndicatorStyle) {
                style = this.props.getRowIndicatorStyle(row.data, row.id, rowIndex);
            }

            return {
                ...ret,
                [rowIndex]: style
            };
        }, {});
    }

    private setHeaderVisibility() {
        if (isNullOrUndefined(this.filterStore)) {
            this.isHeaderVisible = false;
        } else {
            const isEmpty = Object.keys(this.filterStore).filter(key => !key.startsWith("set_") && !key.startsWith("__")).every(name => {
                if (isNullOrWhiteSpace(this.filterStore[name])) {
                    return true;
                }
                if (Array.isArray(this.filterStore[name]) && this.filterStore[name].length === 0) {
                    return true;
                }
                return false;
            });
            this.isHeaderVisible = !isEmpty;
        }
    }

    @State.bound
    private generateFilterStoreHandler() {
        if (this.props.onGenerateFilterStore) {
            const extendedFilterDescriptors = this.props.onGenerateFilterStore();
            const columnFilterDescriptors = createFilterDescriptorsByColumns(this.columns);
            const ret = columnFilterDescriptors.concat(extendedFilterDescriptors);
            return generateFilterStore(ret);
        }
        return generateFilterStore(createFilterDescriptorsByColumns(this.columns));
    }

    public componentDidMount() {
        if (this.props.changeOnMount) {
            this.disposers.push(State.reaction(() => (this._columns), () => { // important to leave expression on this._columns because otherwise isVisible (or other) column prop changes trigger a reload
                const filterStore = this.props.generateInitialFilterStore ? this.generateFilterStoreHandler() : this.filterStore;
                const ordering = this.restoreOrderingState() || this.ordering;
                const paging = this.restorePagingState() || this.paging;
                this.restoreFilter(filterStore);

                if (this.props.initializeFilter) {
                    this.props.initializeFilter(filterStore);
                    this.storeFilter(filterStore);
                }

                this.runAsync(this.loadAsync("initial", paging, ordering, filterStore).then(() => {
                    this.restoreSelectedItem();

                    this.subscribeToFilterStoreChanged();
                }));

            }, { delay: 100 })
            );

        } else {
            this.subscribeToFilterStoreChanged();
        }

        this.disposers.push(State.reaction(() => ({ ordering: this.ordering, paging: this.paging }), () => {
            if (this.props.statePersisterSettings && this.props.statePersisterSettings.isEnabled) {
                if (this.props.statePersisterSettings.ordering && this.ordering) {
                    const singleOrdering = Array.isArray(this.ordering) ? (this.ordering.length > 0 ? this.ordering[0] : null) : this.ordering;
                    if (singleOrdering) {
                        this.searchParametersService.set(this.props.statePersisterKey, "ordering", `${singleOrdering.columnId} ${singleOrdering.direction}`);
                    }
                }

                if (this.props.statePersisterSettings.paging && this.paging) {
                    this.searchParametersService.set(this.props.statePersisterKey, "paging", `${this.paging.pageSize} ${this.paging.currentPage}`);
                }
            }
        }));

        this.disposers.push(State.reaction(() => this.selectedRow, selectedRow => {
            if (this.props.statePersisterSettings &&
                this.props.statePersisterSettings.isEnabled &&
                this.props.statePersisterSettings.selectedRow &&
                this.props.statePersisterSettings.getSelectedRowStringId &&
                typeof this.props.statePersisterSettings.getSelectedRowStringId === "function"
            ) {
                if (selectedRow) {
                    const selectedRowRef = this.getRowReference(selectedRow.data, selectedRow.id);
                    this.searchParametersService.set(this.props.statePersisterKey, "selected", this.props.statePersisterSettings.getSelectedRowStringId(selectedRowRef));
                } else {
                    this.restoreSelectedItem();

                    if (!this.selectedRow) {
                        this.searchParametersService.remove(this.props.statePersisterKey, "selected");
                    }
                }
            }
        }));

        this.setInitializedState();
    }

    @State.bound
    private async explicitRefreshAsync() {
        await this.loadAsync("explicit-refresh", this.paging, this.ordering, this.filterStore);
    }

    public componentWillUnmount() {
        this.disposers.forEach(d => d());
    }

    public componentDidUpdate(prevProps: IDataGridProps) {
        if (prevProps.filterStore !== this.props.filterStore || prevProps.autoReloadOnFilter !== this.props.autoReloadOnFilter) {
            this.subscribeToFilterStoreChanged();
        }
    }

    @State.bound
    private subscribeToFilterStoreChanged() {
        if (this.filterStoreReactionDisposer) {
            this.filterStoreReactionDisposer();
        }

        if (this.props.autoReloadOnFilter) {
            this.filterStoreReactionDisposer = State.reaction(() => JSON.stringify(this.filterStore), () => {
                this.filterChanged();
            });
        }
    }

    private restoreOrderingState(): IOrderingState {
        const ordering = this.searchParametersService.get(this.props.statePersisterKey, "ordering");

        if (this.props.statePersisterSettings && this.props.statePersisterSettings.isEnabled && this.props.statePersisterSettings.ordering && ordering) {
            const orderingPieces = ordering.split(" ");
            if (!arrayIsNullOrEmpty(orderingPieces) && orderingPieces.length === 2) {
                return {
                    columnId: orderingPieces[0],
                    direction: orderingPieces[1] as any
                };
            }
        }

        return null;
    }

    private restorePagingState(): IPagingState {
        const paging = this.searchParametersService.get(this.props.statePersisterKey, "paging");

        if (this.props.statePersisterSettings && this.props.statePersisterSettings.isEnabled && this.props.statePersisterSettings.paging && paging) {
            const pagingPieces = paging.split(" ");
            if (!arrayIsNullOrEmpty(pagingPieces) && pagingPieces.length === 2) {
                return {
                    pageSize: +pagingPieces[0],
                    currentPage: +pagingPieces[1]
                };
            }
        }

        return null;
    }

    private restoreSelectedItem() {
        const selected = this.searchParametersService.get(this.props.statePersisterKey, "selected");

        if (this.props.statePersisterSettings &&
            this.props.statePersisterSettings.isEnabled &&
            this.props.statePersisterSettings.selectedRow &&
            selected &&
            this.props.statePersisterSettings.getSelectedRowStringId
        ) {
            const selectedRow = this.rows.find(r => typeof this.props.statePersisterSettings.getSelectedRowStringId === "function" &&
                this.props.statePersisterSettings.getSelectedRowStringId(this.getRowReference(r.data, r.id)) === selected
            );

            if (selectedRow) {
                this.props.onSelectedRowChange(this.getRowReference(selectedRow.data, selectedRow.id));
            }
        }
    }

    private restoreFilter(filterStore: any) {
        const shouldRestoreFilter = this.searchParametersService.get("restoreFilter", "");

        if (shouldRestoreFilter !== "false") {
            const filter = this.searchParametersService.getObject(this.props.statePersisterKey, "filter");

            if (this.props.statePersisterSettings &&
                this.props.statePersisterSettings.isEnabled &&
                this.props.statePersisterSettings.filter &&
                filter
            ) {
                filterStore["__restore"](filter);
            }
        } else {
            this.onClearFilter();
        }

        this.searchParametersService.remove("restoreFilter", "");
        this.searchParametersService.setObject(this.props.statePersisterKey, "filter", filterStore?.["__serialize"]?.() ?? {});
    }

    @State.bound
    private async loadAsync(type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState | IOrderingState[], filter: any) {
        try {
            this.setInternalLoadingState(true);
            await this.onChangeAsync(type, paging, ordering, filter, this.columns);
        } finally {
            this.setInternalLoadingState(false);
        }
    }

    private loadSync(type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState | IOrderingState[], filter: any) {
        this.runAsync(this.loadAsync(type, paging, ordering, filter));
    }

    @State.action
    private setInternalLoadingState(isLoading: boolean) {
        this.internalLoadingState = isLoading;
    }

    @State.action
    private setInitializedState() {
        this.isInitialized = true;
    }

    @State.bound
    private rowMapper(row: any, rowIndex: number): IRowData {
        if (typeof this.props.rowId === "function") {
            return { id: this.props.rowId(row, rowIndex), data: row };
        }

        if (typeof this.props.rowId === "string") {
            return { id: _.get(row, this.props.rowId), data: row };
        }

        throw new Error("Cannot get row's id.");
    }

    @State.action.bound
    private setColumns(columns: IDataGridColumnProps[]) {
        this._columns = columns;
    }

    private setInternalSelectedRow(row: any, rowId: RowId) {
        this.internalSelectedRowReference = this.getRowReference(row, rowId);
    }

    @State.action.bound
    private rowClicked(row: any, rowId: RowId, rowIndex: number, event: React.MouseEvent) {
        if (this.props.isSelectable && this.props.selectOnRowClick) {
            this.setInternalSelectedRow(row, rowId);

            if (this.props.onSelectedRowChange) {
                this.props.onSelectedRowChange(this.getRowReference(row, rowId));
            }
        }

        if (this.props.onRowClick) {
            this.props.onRowClick(row, rowId, rowIndex);
        }
    }

    private getRowReference(row: any, rowId: RowId) {
        if (this.props.rowComparer === "rowId") {
            return rowId;
        } else if (this.props.rowComparer === "reference" || typeof this.props.rowComparer === "function") {
            return row;
        }

        throw new Error("Cannot determine row reference.");
    }

    @State.bound
    private changeCurrentPagingState(pagingState: IPagingState) {
        this.loadSync("changed", pagingState, this.ordering, this.filterStore);
    }

    @State.bound
    private columnClick(column: IDataGridColumnProps) {
        if (!column.isOrderable) {
            return;
        }

        const currentDirection = this.ordering && (!Array.isArray(this.ordering) ? this.ordering.direction : "desc");
        const direction = currentDirection === "asc" ? "desc" : "asc";

        const newOrdering = {
            columnId: column.id,
            direction
        } as IOrderingState;

        this.loadSync("changed", this.paging, newOrdering, this.filterStore);
    }

    @State.action.bound
    private rowRightClicked(row: any, rowId: RowId, rowIndex: number, event: React.MouseEvent) {
        if (this.props.onContextMenu) {
            event.preventDefault();
            this.rightClickedRow = {
                row: { data: row, id: rowId },
                rowIndex
            };

            requestAnimationFrame(() => {
                ContextMenu.Controller.showOnEvent(this.contextMenuId, event);
            });
        } else {
            this.rightClickedRow = null;

            if (this.props.onRowRightClick) {
                event.preventDefault();
                this.props.onRowRightClick(row, rowId, rowIndex);
            }
        }

        if (this.props.isSelectable && this.props.selectOnRowClick) {
            this.setInternalSelectedRow(row, rowId);

            if (this.props.onSelectedRowChange) {
                this.props.onSelectedRowChange(this.getRowReference(row, rowId));
            }
        }
    }

    @State.action.bound
    private setExtraFilterVisibility(newValue: boolean) {
        this._isExtraFilterVisible = newValue;
    }

    @State.bound
    private onExtraFilterVisibilityChanged(newValue: boolean) {
        this.setExtraFilterVisibility(newValue);
        this.props.onExtraFilterVisibilityChanged?.(newValue);
    }

    private filterChanged = _.debounce(() => {
        this.storeFilter(this.filterStore);
        const paging: IPagingState = {
            ...this.paging,
            currentPage: 0
        };

        this.loadSync("changed", paging, this.ordering, this.filterStore);
    }, this.props.autoFilterTimeout);

    private storeFilter(filterStore: any) {
        if (this.props.statePersisterSettings && this.props.statePersisterSettings.isEnabled && this.props.statePersisterSettings.filter) {
            if (filterStore && "__serialize" in filterStore) {
                this.searchParametersService.setObject(this.props.statePersisterKey, "filter", filterStore["__serialize"]());
                this.searchParametersService.remove("restoreFilter", "");
            }
        }
    }

    @State.bound
    private onClearFilter() {
        this.filterStore?.__reset();
        if (this.props.onClearFilter) {
            this.props.onClearFilter();
        }
    }

    private renderBody(isPrinting: boolean) {
        const columns = isPrinting ? this.columns.filter(c => c.id !== "Actions") : this.columns;
        return (
            <>
                {isPrinting &&
                    <div style={{ textAlign: "center" }}>
                        <h3>{this.props.titleOfPrintableContent}</h3>
                    </div>}
                <DataGridView
                    columns={columns}
                    rows={!isPrinting ? this.rows : this.printableRows}
                    onRenderRow={this.props.onRenderRow}
                    rowBody={this.props.rowBody}
                    rowBodyHeight={this.props.rowBodyHeight}
                    rowBodyPadding={this.props.rowBodyPadding}
                    hasHeader={(!isPrinting || this.isHeaderVisible) && this.props.hasHeader}
                    onRenderHeader={this.props.onRenderHeader}
                    footer={!isPrinting && this.props.footer}
                    totalRowCount={this.totalRowCount}
                    selectedRow={this.selectedRow}
                    onRowClick={this.rowClicked}
                    rowCheckState={this.props.rowCheckState}
                    onRowChecked={this.props.onRowChecked}
                    emptyStateSettings={this.props.emptyStateSettings}
                    showLoadingIndicator={this.props.showLoadingIndicator}
                    isLoading={this.isLoading}
                    adjacentButtonsCount={this.props.adjacentButtonsCount}
                    paging={this.paging}
                    onPagingStateChange={this.changeCurrentPagingState}
                    pageCount={this.pageCount}
                    isCompactPagerMode={this.props.compactFooter}
                    rowHeight={this.props.rowHeight}
                    onColumnClick={this.columnClick}
                    orderingMap={this.orderingMap}
                    onRowRightClick={this.rowRightClicked}
                    rowIndicatorsState={this.rowIndicatorsState}
                    hasRowIndicator={!!this.props.getRowIndicatorStyle || !!this.props.onMapValidationProblem}
                    filterStore={this.filterStore}
                    onFilterChanged={this.filterChanged}
                    fixedLayout={this.props.fixedLayout || !!this.props.getRowIndicatorStyle || !!this.props.onMapValidationProblem}
                    availablePageSizes={this.props.availablePageSizes}
                    hidePager={this.props.hidePager}
                    automationId={this.props.automationId}
                    fixedHeight={!isPrinting && this.props.fixedHeight}
                    maxHeight={!isPrinting && this.props.maxHeight}
                    actionsColumn={!isPrinting && this.props.actionsColumn}
                    rowUnderEditing={this.rowUnderEditing}
                    hasBackButton={this.props.hasBackButton}
                    backButtonText={this.props.backButtonText}
                    onBack={this.props.onBack}
                    disableOnRowHover={this.props.disableOnRowHover}

                    hasRefreshButton={this.props.hasRefreshButton}
                    onRefreshAsync={this.explicitRefreshAsync}

                    extraFilter={this.props.extraFilter}
                    alwaysVisibleExtraFilter={this.props.alwaysVisibleExtraFilter}
                    hasExtraFilterButton={this.props.hasExtraFilterButton}
                    isExtraFilterVisible={this.isExtraFilterVisible}
                    onExtraFilterVisibilityChanged={this.onExtraFilterVisibilityChanged}

                    hasFilterClearButton={this.props.hasFilterClearButton}
                    onClearFilter={this.onClearFilter}

                    showSeparatorLines={this.props.showSeparatorLines}

                    horizontalScroll={this.props.horizontalScroll}
                    hidePointerCursorForRows={this.props.hidePointerCursorForRows}

                    hasPrintButton={this.props.hasPrintButton}
                    onPrintAsync={this.onPrintAsync}

                    showCheckAll={this.props.showCheckAll}
                    checkAllValue={this.props.checkAllValue}
                    onCheckAllValueChanged={this.props.onCheckAllValueChanged}
                />
                <ContextMenu id={this.contextMenuId}>
                    {this.rightClickedRow ? this.props.onContextMenu(this.rightClickedRow.row.data, this.rightClickedRow.row.id, this.rightClickedRow.rowIndex) : <p>...</p>}
                </ContextMenu>
            </>
        );
    }

    @State.bound
    private async onPrintAsync() {
        this.setHeaderVisibility();
        await this.fetchDataForPrintingAsync();
        this.setIsPrinting(true);
        setTimeout(() => {
            this.componentPrinterRef.current.print();
        }, 1250);
        Promise.resolve();
    }

    @State.action.bound
    private setIsPrinting(value: boolean) {
        this.isPrinting = value;
    }

    @State.action.bound
    private hidePrintableContent() {
        this.setIsPrinting(false);
        this.isHeaderVisible = true;
    }

    @State.action.bound
    private async fetchDataForPrintingAsync() {
        const result = await this.props.onPrintDataFetchAsync();
        this.printableData = result;
    }

    public render() {
        return (
            <>
                <ColumnContainer onColumnsChanged={this.setColumns}>{this.props.children}</ColumnContainer>
                {this.isInitialized && (
                    <>
                        <ComponentPrinter
                            ref={this.componentPrinterRef}
                            componentRefToPrint={this.printContainerRef}
                            onAfterPrint={this.hidePrintableContent}>
                            {this.renderBody(false)}
                            <div ref={this.printContainer}>
                                {this.isPrinting && this.renderBody(true)}
                            </div>
                        </ComponentPrinter>
                    </>
                )}
            </>
        );
    }
}

export default connect(
    DataGrid,
    new DependencyAdapter<IDataGridPropsWithDependencies, IDataGridDependencies>(c => ({
        searchParametersService: c.resolve("ISearchParametersService")
    })),
    new ValidationBoundaryAdapter(),
    new ViewContextAdapter<IDataGridPropsWithDependencies, IToolkitViewContextSettings>((props, settings) => ({
        compactFooter: settings.compactControlWidth
    }))
);