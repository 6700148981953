//#region imports
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import NDataPanelView from "./NDataPanelView";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import moment from "moment";
import { IRowIndicatorStyle, IRowBody, RowId, IEmptyStateSettings } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import Action from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/Action";
import INDataAction from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataAction";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import NDataGlobalActions from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataGlobalActions";
import INDataRow from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataRow";
import NDataPanelDetailView from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanelDetailView";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import _ from "@HisPlatform/Common/Lodash";
import { iconNameType } from "@CommonControls/Icon";
import NDataGrid from "@HisPlatform/BoundedContexts/Productivity/Components/NDataGrid/NDataGrid";
import NDataActionProcessor from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/NDataActionProcessor";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import IWorklistRow from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/IWorklistRow";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IWorklistItemDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistItemDefinition";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import IDynamicListDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IDynamicListDefinition";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import IFrontendListDataWithDefinition from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/IFrontendListDataWithDefinition";
//#endregion

export interface INDataPanelDependencies {
    worklistApiAdapter: WorklistApiAdapter;
    notificationService: INotificationService;
    localizationService: ILocalizationService;
    fileSaverService: IFileSaverService;
    activityRegistry: IActivityRegistry;
}

export interface INDataPanelProps {
    _dependencies?: INDataPanelDependencies;

    definition?: IWorklistDefinition;
    onGetDynamicListAsync?: (frontendListParameters: FrontendListParameters) => Promise<IFrontendListDataWithDefinition>;

    useCaseState?: INDataUseCaseState;
    selectedRowId?: string;

    extraFilter?: React.ReactNode;
    hasRefreshButton?: boolean;
    hasExtraFilterButton?: boolean;
    hasFilterClearButton?: boolean;
    hasTabs?: boolean;
    hasBackButton?: boolean;
    onBack?: () => void;
    hasNewRow?: boolean;
    defaultExtraFilterVisibility?: boolean;
    defaultPageSize?: number;
    rowBodyHeight?: React.ReactText;
    getRowIndicatorStyle?: (row: INDataRow, rowId: string, rowIndex: number) => IRowIndicatorStyle;

    onChange?: (rowId: RowId, useCaseState: INDataUseCaseState) => void;
    onPerformActionAsync?: (action: Action, rowId: RowId) => Promise<boolean>;
    onPerformClientSideActionAsync?: (clientSideAction: ClientSideActionDto, rowId: RowId) => Promise<WorkListArgument | boolean>;
    onFilterStoreCreatedAsync?: (filterStore: any) => Promise<void>;
    onRenderRowBody?: (row: INDataRow, rowId: RowId, rowIndex: number, rowBodyItemDefinition: IWorklistItemDefinition) => IRowBody;
    onGetExtendedFilterDescriptors?: () => IDataGridFilterDescriptor[];
    onGetPanelProps?: (useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) => object;
    onSaveAsync?: () => Promise<void>;
    onRowClick?: (row: INDataRow, rowId: RowId, rowIndex: number) => void;
    onRowRightClick?: (row: INDataRow, rowId: RowId, rowIndex: number) => void;
    onDataLoaded?: (data: IPagedItems<IWorklistRow>) => void;
    onRenderDetail?: (detailContent: React.ReactNode) => React.ReactNode;
    onClearFilter?: () => void;
    onDefinitionLoaded?: (globalActions: INDataAction[], itemDefinitions: IWorklistItemDefinition[]) => void;
    iconName?: iconNameType;
    refreshListEvent?: TypedAsyncEvent;
    masterTitle?: string;
    detailTitle?: string;
    beforeGlobalActionsToolbar?: React.ReactNode;
    afterGlobalActionsToolbar?: React.ReactNode;
    onInitializeFilter?: (filterStore: any, itemDefinitions: IWorklistItemDefinition[]) => void;

    disableDetailStrictMode?: boolean;
    actionsOnLeftSide?: boolean;
    horizontalScroll?: boolean;
    emptyStateSettings?: IEmptyStateSettings;
    hasDetailFooter?: boolean;

    referenceDate?: LocalDate;

    onRenderMasterList?: (content: React.ReactNode) => React.ReactNode;
    masterSubtitle?: React.ReactNode;

    deferredActionTaskType?: string;
    multiActionActivityReference?: string;
    onMultiActionActivityReferenceChange?: (value: string) => void;

    hideMasterToolbar?: boolean;
    hasPrintButton?: boolean;
    hideMasterHeader?: boolean;
    hideDetailHeader?: boolean;
    isCompact?: boolean;
    compactListSize?: number | string;
    noInnerSpacing?: boolean;
    gridContainerStyle?: React.CSSProperties;
    alwaysVisibleExtraFilter?: React.ReactNode;
    shouldDetailOpen?: boolean;
    showLoadingIndicator?: boolean;
}

@State.observer
class NDataPanel extends React.Component<INDataPanelProps> {

    public static defaultProps: Partial<INDataPanelProps> = { shouldDetailOpen: true };

    //#region dependencies
    private get worklistApiAdapter() {
        return this.props._dependencies.worklistApiAdapter;
    }

    private get notificationService() {
        return this.props._dependencies.notificationService;
    }

    private get localizationService() {
        return this.props._dependencies.localizationService;
    }

    private get fileSaverService() {
        return this.props._dependencies.fileSaverService;
    }

    private get activityRegistry() {
        return this.props._dependencies.activityRegistry;
    }

    //#endregion

    private refreshListEvent = new TypedAsyncEvent();

    @State.observable.ref private isActionLoading = false;
    @State.observable.ref private lastUpdatedAt: moment.Moment = null;
    @State.observable.ref private listDefinition: IDynamicListDefinition = null;

    @State.computed private get globalActions(): INDataAction[] {
        return this.listDefinition?.globalActions ?? [];
    }

    @State.computed private get masterDetailSelectedId() {
        return (this.props.useCaseState?.displayMode === UseCaseDisplayMode.MasterDetail && this.props.shouldDetailOpen) ? this.props.selectedRowId : null;
    }

    private readonly actionProcessor = new NDataActionProcessor(
        this.worklistApiAdapter,
        this.notificationService,
        this.localizationService,
        this.fileSaverService,
        (...args) => this.props.onPerformClientSideActionAsync?.(...args),
        (...args) => this.props.onPerformActionAsync?.(...args),
        async () => await this.refreshListEvent.emitAsync(),
        (...args) => this.props.onChange?.(...args),
        () => this.props.selectedRowId,
        () => this.props.useCaseState,
        this.setActionLoadingState,
        (activityReference) => {
            if (this.props.multiActionActivityReference === activityReference) {
                this.props.onMultiActionActivityReferenceChange(null);
            } else {
                this.props.onMultiActionActivityReferenceChange?.(activityReference);
            }
        }
    );

    public componentDidMount() {
        if (this.props.refreshListEvent) {
            this.refreshListEvent = this.props.refreshListEvent;
        }
    }

    @State.action.bound
    private onGetPanelProps(useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) {
        const panelProps: any = this.props.onGetPanelProps?.(useCaseIdentifier, useCaseArguments) ?? {};
        const saveWithReloadAsync = async () => {
            if (this.props.onSaveAsync) {
                await this.props.onSaveAsync();
            }

            await this.refreshListEvent.emitAsync();
        };

        panelProps.onSaveAsync = saveWithReloadAsync;

        panelProps.onUseCaseChange = this.props.onChange;

        panelProps.onClose = () => {
            this.props.onChange(null, null);
        };

        return panelProps;
    }

    public render() {
        return (this.props.definition || this.props.onGetDynamicListAsync) ? (
            <NDataPanelView
                isLoading={this.isActionLoading}
                hasDetailFooter={this.props.hasDetailFooter}
                masterTitle={this.props.masterTitle
                    ?? this.props.definition?.name
                    ?? this.listDefinition?.name
                    ?? ""}
                masterSubtitle={this.props.masterSubtitle}
                lastUpdatedAt={this.lastUpdatedAt}
                masterDetailSelectedId={this.masterDetailSelectedId}
                onMasterDetailSelectedItemChange={this.selectRow}
                iconName={this.props.iconName}
                detailTitle={this.props.detailTitle}
                disableDetailStrictMode={this.props.disableDetailStrictMode}
                hasTabs={this.props.hasTabs}
                onRenderList={this.props.onRenderMasterList}
                hideMasterHeader={this.props.hideMasterHeader}
                hideDetailHeader={this.props.hideDetailHeader}
                isCompact={this.props.isCompact}
                compactListSize={this.props.compactListSize}
                noInnerSpacing={this.props.noInnerSpacing}
                masterContent={(
                    <NDataGrid
                        extraFilter={this.props.extraFilter}
                        selectedRowId={this.props.selectedRowId}
                        hasRefreshButton={this.props.hasRefreshButton}
                        hasExtraFilterButton={this.props.hasExtraFilterButton}
                        hasFilterClearButton={this.props.hasFilterClearButton}
                        defaultExtraFilterVisibility={this.props.defaultExtraFilterVisibility}
                        getRowIndicatorStyle={this.props.getRowIndicatorStyle}
                        onRenderRowBody={this.props.onRenderRowBody}
                        onGetExtendedFilterDescriptors={this.props.onGetExtendedFilterDescriptors}
                        onRowClick={this.props.onRowClick}
                        onRowRightClick={this.props.onRowRightClick}
                        definition={this.props.definition}
                        defaultPageSize={this.props.defaultPageSize}
                        hasNewRow={this.props.hasNewRow}
                        onChange={this.props.onChange}
                        onFilterStoreCreatedAsync={this.props.onFilterStoreCreatedAsync}
                        onDefinitionLoaded={this.listDefinitionLoaded}
                        onPerformActionAsync={this.props.onPerformActionAsync}
                        onPerformClientSideActionAsync={this.props.onPerformClientSideActionAsync}
                        refreshListEvent={this.refreshListEvent}
                        useCaseState={this.props.useCaseState}
                        onDataLoaded={this.setLoadedData}
                        rowBodyHeight={this.props.rowBodyHeight}
                        actionsOnLeftSide={this.props.actionsOnLeftSide}
                        horizontalScroll={this.props.horizontalScroll}
                        detailTitle={this.props.detailTitle}
                        emptyStateSettings={this.props.emptyStateSettings}
                        fullHeight={true}
                        hasBackButton={this.props.hasBackButton}
                        onBack={this.props.onBack}
                        referenceDate={this.props.referenceDate}
                        onClearFilter={this.props.onClearFilter}
                        onInitializeFilter={this.props.onInitializeFilter}
                        onGetInlinePanelProps={this.onGetPanelProps}
                        titleOfPrintableContent={this.props.masterTitle ?? this.listDefinition?.name ?? ""}
                        deferredActionTaskType={this.props.deferredActionTaskType}
                        multiActionActivityReference={this.props.multiActionActivityReference}
                        onMultiActionActivityReferenceChange={this.props.onMultiActionActivityReferenceChange}
                        hasPrintButton={this.props.hasPrintButton}
                        containerStyle={this.props.gridContainerStyle}
                        alwaysVisibleExtraFilter={this.props.alwaysVisibleExtraFilter}
                        onGetDynamicListAsync={this.props.onGetDynamicListAsync}
                        showLoadingIndicator={this.props.showLoadingIndicator}
                    />
                )}
                masterToolbar={this.props.hideMasterToolbar ? (<></>) : (
                    <>
                        {this.props.beforeGlobalActionsToolbar}
                        <NDataGlobalActions
                            actions={this.globalActions}
                            useCaseState={this.props.useCaseState}
                            onActionAsync={this.actionProcessor.processActionAsync}
                            currentBatchActivityReference={this.props.multiActionActivityReference}
                            _activityRegistry={this.activityRegistry}
                        />
                        {this.props.afterGlobalActionsToolbar}
                    </>
                )}
                detailContent={(this.props.useCaseState?.displayMode === UseCaseDisplayMode.MasterDetail && this.props.shouldDetailOpen) ? this.renderDetail() : null}
            />
        ) : null;
    }

    private renderDetail() {
        const content = (
            <NDataPanelDetailView
                onGetPanelProps={this.onGetPanelProps}
                useCaseIdentifier={this.props.useCaseState.useCase}
                useCaseArguments={this.props.useCaseState.useCaseArguments}
            />
        );

        return this.props.onRenderDetail ? this.props.onRenderDetail(content) : content;
    }

    @State.action.bound
    private selectRow(selectedRow: INDataRow) {
        this.props.onChange?.(selectedRow?.__id ?? null, selectedRow?.__id ? this.props.useCaseState : null);
    }

    @State.action.bound
    private listDefinitionLoaded(listDefinition: IDynamicListDefinition) {
        this.listDefinition = listDefinition;
        this.props.onDefinitionLoaded?.(listDefinition.globalActions, listDefinition.itemDefinitions);
    }

    @State.action.bound
    private setLoadedData(data: IPagedItems<INDataRow>) {
        this.lastUpdatedAt = DateTimeService.now();
        this.props.onDataLoaded?.(data);
    }

    @State.action.bound
    private setActionLoadingState(isLoading: boolean) {
        this.isActionLoading = isLoading;
    }
}

export default connect(
    NDataPanel,
    new DependencyAdapter<INDataPanelProps, INDataPanelDependencies>(c => ({
        worklistApiAdapter: c.resolve("WorklistApiAdapter"),
        notificationService: c.resolve("INotificationService"),
        localizationService: c.resolve("ILocalizationService"),
        fileSaverService: c.resolve("IFileSaverService"),
        activityRegistry: c.resolve("IActivityRegistry")
    }))
);
