import {IModalParams} from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import PlanningPeriod from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/PlanningPeriod";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

type resultType = "save" | "cancel" | "delete";

export interface IEditPlanningPeriodModalResult {
    result: resultType;
}

export interface IEditPlanningPeriodModalParams extends IModalParams {
    store: PlanningPeriod;
    isCopy: boolean;
    validationResults: IClientValidationResult[];
    onValidateAsync: () => Promise<IClientValidationResult[]>;
}

export default class EditPlanningPeriodModalParams implements IEditPlanningPeriodModalParams {
    public static type = "EditPlanningPeriodModal";
    public get type() {
        return EditPlanningPeriodModalParams.type;
    }

    constructor(public store: PlanningPeriod,
                public isCopy: boolean,
                public validationResults: IClientValidationResult[],
                public onValidateAsync: () => Promise<IClientValidationResult[]>) {
    }
}
