import React, { useMemo } from "react";
import moment from "moment";
import * as Ui from "@CommonControls";
// import Styles from "./CoverageEligibilityCheckWidget.less";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import IWidgetComponentProps from "@PluginInterface/Dashboard/IWidgetComponentProps";
import CoverageEligibilityChecker from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/CoverageEligibilityCheckLed/CoverageEligibilityChecker";
import CareActivityId from "@Primitives/CareActivityId.g";
import PatientId from "@Primitives/PatientId.g";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import WellKnownReferenceCodes from "@HunEHealthInfrastructurePlugin/Common/WellKnownReferenceCodes";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

type Document = {
    identifierSystemId: IdentifierSystemId;
    isDocumentValidAtTime: (referenceTime: LocalDate | moment.Moment) => boolean;
    identifierValue: string;
};

export interface ICoverageEligibilityCheckWidgetProps extends IWidgetComponentProps {
    _careActivityId?: CareActivityId;
    _patientId?: PatientId;
    _documents?: Document[];
}

function CoverageEligibilityCheckWidget(props: ICoverageEligibilityCheckWidgetProps) {
    return (
        <Ui.Dashboard.Widget 
                name={props.name}
                icon={"rss"}
                isCloseByDefault={false}
                title={StaticHunEHealthInfrastructureCareResources.Widgets.CoverageEligibilityCheck.Title}
                automationId="CoverageEligibilityCheckerWidget"
            >
            {renderContent(props)}
        </Ui.Dashboard.Widget>
        );
}

function renderDebug(props: ICoverageEligibilityCheckWidgetProps) {
    const identifier = useMemo(() => props._documents.find(it => it.identifierSystemId.value === WellKnownReferenceCodes.DocumentIdentifierSystems.HunSocialSecurityNumber)?.identifierValue, props._documents);
    return (
        <div>Debug Info: CareActivityId: {props._careActivityId.value} PatientId: {props._patientId.value} Identifier:{identifier}</div>
    );
}

function renderContent(props: ICoverageEligibilityCheckWidgetProps) {
    const identifier = useMemo(() => props._documents.find(it => it.identifierSystemId.value === WellKnownReferenceCodes.DocumentIdentifierSystems.HunSocialSecurityNumber)?.identifierValue, props._documents);
    if (isNullOrUndefined(identifier)) {
        return (
            <div>{StaticHunEHealthInfrastructureCareResources.Widgets.CoverageEligibilityCheck.NoSSNMessage}</div>
        );
    }
    else {
        return(
            <CoverageEligibilityChecker careActivityId={props._careActivityId} 
                                        patientId={props._patientId}
                                        identifierValue={identifier}
                                        doNotRunAutomatically={true}
                                        mode={"widget"}
                                        />
        );
    }
}

export default connect<ICoverageEligibilityCheckWidgetProps>(
    withLoadingBoundary(CoverageEligibilityCheckWidget),
    new CareActivityContextAdapter<ICoverageEligibilityCheckWidgetProps>(ctx => ({
        _careActivityId: ctx.careActivityId,
    })),
    new PatientContextAdapter<ICoverageEligibilityCheckWidgetProps>(c => ({
        _patientId: c.patientId,
        _documents: c.patient.patientDocuments
    })),
);