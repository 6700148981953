import React from "react";
import IPatientsCareActivityListReferralOrganizationUnitColumnExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientsCareActivityListReferralOrganizationUnitColumnExtensionPointProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientsCareActivityListReferralOrganizationUnitColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/PatientsCareActivityListReferralOrganizationUnitColumnExtension/PatientsCareActivityListReferralOrganizationUnitColumn";

@State.observer
export default class PatientsCareActivityListReferralOrganizationUnitColumnExtension extends React.Component<IPatientsCareActivityListReferralOrganizationUnitColumnExtensionPointProps> {
    public render() {
        return (
            <PatientsCareActivityListReferralOrganizationUnitColumn
                header={this.props.header}
                displayOrder={this.props.displayOrder}
                showName={this.props.showName}
                isVisible={this.props.isFullGrid}
            />
        );
    }
}