import React from "react";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityId from "@Primitives/CareActivityId.g";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientId from "@Primitives/PatientId.g";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import ServiceRequestFilter from "@Primitives/ServiceRequestFilter";
import HisUseCaseHost from "@HisPlatform/Components/HisUseCaseHost/HisUseCaseHost";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import UseCases from "@Primitives/UseCases";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import { ServiceRequestMasterDetailPanelMode } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestMasterDetailPanel/ServiceRequestMasterDetailPanelMode";
import ServiceRequestListPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestListPanel/ServiceRequestListPanel";
import { IPanelWithButtonPortalProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/IPanelWithButtonPortalProps";
import { HisPanelButtonPortal } from "@HisPlatformControls";
import ISearchParametersService from "@Toolkit/CommonWeb/SearchParametersService/Definition/ISearchParametersService";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";

interface IServiceRequestMasterDetailPanelDependencies {
    searchParametersService: ISearchParametersService;
}

interface IServiceRequestMasterDetailPanelProps extends IPanelWithButtonPortalProps {
    _dependencies?: IServiceRequestMasterDetailPanelDependencies;
    _careActivityId?: CareActivityId;
    _patientId?: PatientId;

    mode: ServiceRequestMasterDetailPanelMode;

    selectedId: ServiceRequestId | null;
    useCase: INDataUseCaseState;
    onChange: (id: ServiceRequestId | null, state: INDataUseCaseState) => void;
    onCloseUseCase: (successfullySaved: boolean) => void;
    onBack: () => void;

    showServiceRequestFilter?: boolean;
    serviceRequestFilter: ServiceRequestFilter;
    onServiceRequestFilterChange: (newValue: ServiceRequestFilter) => void;

    /** Manually sets the PageBox's height. Do NOT use the elsewhere this is a temporary solution.
     * @obsolete
     * @deprecated
     */
    manualHeight?: string;
}

@State.observer
class ServiceRequestMasterDetailPanel extends React.Component<IServiceRequestMasterDetailPanelProps> {

    private readonly refreshListEvent = new TypedEvent();

    public static defaultProps: Partial<IServiceRequestMasterDetailPanelProps> = {
        showServiceRequestFilter: true
    };

    public componentDidMount() {
        const startNewServiceRequest = this.props._dependencies.searchParametersService.get("startNewServiceRequest", null);
        if (!!startNewServiceRequest) {
            const id = this.props._dependencies.searchParametersService.get("id", null);
            const validity = this.props._dependencies.searchParametersService.get("validity", null);
            const direction = this.props._dependencies.searchParametersService.get("direction", null);
            if (!id || !validity || !direction) {
                return;
            }

            const definitionId = new EntityVersionSelector<ServiceRequestDefinitionId>(
                new ServiceRequestDefinitionId(id),
                new LocalDate(parseInt(validity))
            );
            this.createServiceRequest(definitionId, parseInt(direction));
        }
    }

    public render() {
        return (
            <>
                <MasterDetailLayout
                    selectedItem={this.props.selectedId?.value ?? null}
                    onClose={this.closeUseCase}
                    master={this.renderMaster()}
                    detail={this.renderDetail()}
                    style={this.props.manualHeight ? { height: this.props.manualHeight } : null}
                />
                {!this.props.hidePortalButtons && this.props.buttons && !this.props.selectedId?.value && (
                    <HisPanelButtonPortal>
                        {React.cloneElement(this.props.buttons, {
                            isReadOnly: true
                        })}
                    </HisPanelButtonPortal>
                )}
            </>
        );
    }

    private renderMaster() {
        return (
            <ServiceRequestListPanel
                onCreateServiceRequest={this.createServiceRequest}
                onOpenAdministration={this.openAdministration}
                onOpenRequest={this.openServiceRequest}
                onOpenResults={this.openAdministration}
                onServiceRequestFilterChange={this.props.onServiceRequestFilterChange}

                selectedServiceRequestId={this.props.selectedId}
                serviceRequestFilter={this.props.serviceRequestFilter}
                refreshListEvent={this.refreshListEvent}
                onBack={this.props.onBack}
                isReadOnly={this.props.mode === "read-only"}

                showServiceRequestFilter={this.props.showServiceRequestFilter}
            />
        );
    }

    private renderDetail() {
        return (
            <HisUseCaseHost
                frameType="MasterDetailDetail"
                useCaseIdentifier={this.props.useCase?.useCase}
                useCaseArguments={this.props.useCase?.useCaseArguments}
                onClose={this.props.onCloseUseCase}
                onGetPanelProps={this.getPanelProps}
            />
        );
    }

    @State.bound
    private getPanelProps(useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) {
        return {
            onSaved: this.detailSaved,
            onClose: this.closeUseCase,
            isReadOnly: this.props.mode === "read-only",
            buttons: this.props.buttons,
            hidePortalButtons: this.props.hidePortalButtons,
            onNewCreated: this.openNewServiceRequest,
            onRenewed: this.openNewServiceRequest
        };
    }

    @State.bound
    private detailSaved() {
        this.refreshListEvent.emit();
    }

    @State.bound
    private createServiceRequest(definitionId: EntityVersionSelector<ServiceRequestDefinitionId>, definitionDirection: ServiceRequestDirection) {
        this.props.onChange(new ServiceRequestId("new"), {
            useCase: new UseCaseIdentifier(UseCases.serviceRequestManagementViewServiceRequest),
            displayMode: UseCaseDisplayMode.MasterDetail,
            useCaseArguments: [
                { name: "definitionId", value: definitionId.toUrl() },
                { name: "definitionDirection", value: ServiceRequestDirection[definitionDirection] }
            ]
        });
    }

    @State.bound
    private openServiceRequest(id: ServiceRequestId) {
        this.props.onChange(id, {
            useCase: new UseCaseIdentifier(UseCases.serviceRequestManagementViewServiceRequest),
            displayMode: UseCaseDisplayMode.MasterDetail,
            useCaseArguments: [
                { name: "serviceRequestId", value: id }
            ]
        });
    }

    @State.bound
    private openNewServiceRequest(id: ServiceRequestId) {
        this.refreshListEvent.emit();
        this.openServiceRequest(id);
    }

    @State.bound
    private openAdministration(id: ServiceRequestId) {
        this.props.onChange(id, {
            useCase: new UseCaseIdentifier(UseCases.serviceRequestManagementAdministrateServiceRequest),
            displayMode: UseCaseDisplayMode.MasterDetail,
            useCaseArguments: [
                { name: "serviceRequestId", value: id }
            ]
        });
    }

    @State.bound
    private closeUseCase() {
        this.props.onCloseUseCase(false);
    }
}

export default connect(
    ServiceRequestMasterDetailPanel,
    new DependencyAdapter<IServiceRequestMasterDetailPanelProps, IServiceRequestMasterDetailPanelDependencies>(c => ({
        searchParametersService: c.resolve("ISearchParametersService")
    })),
    new CareActivityContextAdapter<IServiceRequestMasterDetailPanelProps>(c => ({
        _careActivityId: c.careActivityId
    })),
    new PatientContextAdapter<IServiceRequestMasterDetailPanelProps>(c => ({
        _patientId: c.patientId
    })),
);
