import PractitionerRecommendation from "./PractitionerRecommendation";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ConditionId from "@Primitives/ConditionId.g";
import MedicationEquipmentClassificationId from "@Primitives/MedicationEquipmentClassificationId.g";
import EquipmentSupportTypeId from "@Primitives/EquipmentSupportTypeId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { RecommendationType } from "./RecommendationType";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ProfessionalExamId from "@Primitives/ProfessionalExamId.g";

export default class EquipmentPractitionerRecommendation extends PractitionerRecommendation {

    constructor(isNew?: boolean) {
        super(isNew);
        this.type = RecommendationType.GYSE;
    }

    @State.observable public furtherConditions: ConditionId[] = null;
    @State.observable.ref public professionalExamId: ProfessionalExamId = null;
    @State.observable public notes: string = null;
    @State.observable public amount: number = null;
    @State.observable.ref public recommendedEquipment1: IEntityVersionSelector<MedicationEquipmentClassificationId> = null;
    @State.observable.ref public recommendedEquipment2: IEntityVersionSelector<MedicationEquipmentClassificationId> = null;
    @State.observable.ref public equipmentSupportTypeId: EquipmentSupportTypeId = null;
    @State.observable public validTo: LocalDate = LocalDate.today();

    @State.action.bound
    public setFurtherConditions(conditions: ConditionId[]) {
        this.furtherConditions = conditions;
    }

    @State.action.bound
    public setProfessionalExamId(id: ProfessionalExamId) {
        this.professionalExamId = id;
    }

    @State.action.bound
    public setNotes(notes: string) {
        this.notes = notes;
    }

    @State.action.bound
    public setAmount(amount: number) {
        this.amount = amount;
    }

    @State.action.bound
    public setRecommendedEquipment1(id: MedicationEquipmentClassificationId) {
        this.recommendedEquipment1 = {id: id, validOn: this.recordedOn || LocalDate.today()};
    }

    @State.action.bound
    public setRecommendedEquipment2(id: MedicationEquipmentClassificationId) {
        this.recommendedEquipment2 = {id: id, validOn: this.recordedOn || LocalDate.today()};
    }

    @State.action.bound
    public setSupportTypeId(id: EquipmentSupportTypeId) {
        this.equipmentSupportTypeId = id;
    }

    @State.action.bound
    public setValidTo(validTo: LocalDate) {
        this.validTo = validTo;
    }
}