import React from "react";
import { IBookSlotDialogParams } from "./BookSlotDialogParams";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import BookSlotDialogView from "./BookSlotDialogView";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import PractitionerId from "@Primitives/PractitionerId.g";
import _ from "@HisPlatform/Common/Lodash";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import { isNullOrUndefined, arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import AppointmentScheduleSlotSeries from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentSchedule";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";

interface IBookSlotDialogDependencies {
    schedulingApiAdapter: SchedulingApiAdapter;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    notificationService: INotificationService;
}

interface IBookSlotDialogProps extends IModalComponentParams, IBookSlotDialogParams {
    _dependencies?: IBookSlotDialogDependencies;
}

/** @screen */
@State.observer
class BookSlotDialog extends React.Component<IBookSlotDialogProps> {
    private readonly propertiesToValidate: string[] = ["Description", "Appointment.Referral.ReferralCareIdentifier"];

    @State.observable.ref private updatedAppointment = new Appointment();

    @State.computed private get selectedPointOfCareId() {
        return this.updatedAppointment.pointOfCareId;
    }

    @State.computed private get selectedPractitioners() {
        return this.updatedAppointment.practitionerIds;
    }

    @State.computed private get selectedSchedule() {
        let schedules = this.props.appointmentSchedules;

        schedules = schedules.filter(s =>
            (arrayIsNullOrEmpty(this.selectedPractitioners) ||
                s.practitionerParticipants.every(j =>
                    this.selectedPractitioners.some(k => ValueWrapper.equals(j, k))
                )
            )
            &&
            (isNullOrUndefined(this.selectedPointOfCareId?.value) ||
                s.locationParticipants.some(l => ValueWrapper.equals(l, this.selectedPointOfCareId)))
        );

        return schedules[0];
    }

    @State.computed private get allPointOfCareIds() {
        return this.getPointOfCareIds(this.props.appointmentSchedules);
    }

    @State.computed private get isPointOfCareReadOnly() {
        return !(this.allPointOfCareIds?.length > 1);
    }

    @State.computed private get filteredPointOfCareIds() {
        const filteredSchedules = this.selectedPractitioners?.length > 0
            ? this.props.appointmentSchedules.filter(s => s.practitionerParticipants.every(p => this.selectedPractitioners.some(i => ValueWrapper.equals(i, p))))
            : this.props.appointmentSchedules;

        return this.getPointOfCareIds(filteredSchedules);
    }

    @State.bound
    private getPointOfCareIds(schedules: AppointmentScheduleSlotSeries[]) {
        const result = schedules.reduce((aggregate, item) => {
            aggregate.push(...item.locationParticipants);
            return aggregate;
        }, []);

        return _.uniqBy(result, i => i.value);
    }

    @State.computed private get allPractitionerOptions() {
        return this.getPractitionerOptions(this.props.appointmentSchedules);
    }

    @State.computed private get isPractitionersReadOnly() {
        return !(this.allPractitionerOptions?.length > 1);
    }

    @State.computed private get filteredPractitionerOptions() {
        const filteredSchedules = !isNullOrUndefined(this.selectedPointOfCareId)
            ? this.props.appointmentSchedules.filter(i => i.locationParticipants.some(j => ValueWrapper.equals(this.selectedPointOfCareId, j)))
            : this.props.appointmentSchedules;

        return this.getPractitionerOptions(filteredSchedules);
    }

    @State.bound
    private getPractitionerOptions(schedules: AppointmentScheduleSlotSeries[]) {
        const result = schedules.map(s => s.practitionerParticipants.sort());
        return _.uniqWith(result, _.isEqual) as PractitionerId[][];
    }

    @State.computed private get bookableInterval() {
        if (this.props.settings.isEdit) {
            return `${this.props.start.toDate().toLocaleString()} - ${this.props.end.toDate().toLocaleString()}`;
        }
        const slots = this.selectedSchedule?.bookableSlotsForDate(this.props.start, this.props.end);
        if (slots?.length) {
            return `${slots[0].from.toDate().toLocaleString()} - ${slots[slots.length - 1].to.toDate().toLocaleString()}`;
        }
        return "";
    }

    @State.computed private get bookDate() {
        const duration = this.props.end.diff(this.props.start, "minute");
        return formatStringWithObjectParams(StaticSchedulingResources.PractitionerAppointments.tooltipDate,
            { Interval: `${this.props.start.format("LLLL")} - ${this.props.end.format("LT")}`, Duration: duration.toString() });
    }

    @State.computed private get selectedServiceName() {
        return this.props.settings.service.name;
    }

    @State.computed private get selectedServiceCode() {
        return this.props.settings.service.code;
    }

    constructor(props: IBookSlotDialogProps) {
        super(props);

        this.updatedAppointment = _.cloneDeep(props.appointment);
    }

    @State.action.bound
    private async saveAsync() {
        this.updatedAppointment.setAppointmentScheduleId(this.selectedSchedule.id);

        const result = await this.validateAsync();
        const problems = (_.flatten(result.map(r => r.problems))).filter(p => this.propertiesToValidate.includes(p.propertyPath));

        if (!problems.length) {
            return this.props.onClose({
                isBooked: true,
                updatedAppointment: this.updatedAppointment
            });
        } else {
            this.props._dependencies.notificationService.showCannotSaveBecauseOfErrors();
        }
    }

    @State.action.bound
    private close() {
        this.props.onClose({
            isBooked: false
        });
    }

    @State.bound
    private async validateAsync() {
        const result = await this.props._dependencies.schedulingApiAdapter.validateAsync(this.updatedAppointment);
        return result.value;
    }

    public render() {
        return (
            <BookSlotDialogView
                onClose={this.close}
                saveAsync={this.saveAsync}

                pointOfCareIds={this.filteredPointOfCareIds}
                practitionerOptions={this.filteredPractitionerOptions}

                isPointOfCareReadOnly={this.isPointOfCareReadOnly}
                isPractitionersReadOnly={this.isPractitionersReadOnly}

                bookableInterval={this.bookableInterval}
                bookDate={this.bookDate}
                serviceName={this.selectedServiceName}
                serviceCode={this.selectedServiceCode}
                appointment={this.updatedAppointment}
                settings={this.props.settings}
                index={this.props.index}

                onValidateAsync={this.validateAsync}
                isTelemedicineConsultation={this.props.isTelemedicineConsultation}
                isAdditionalParticipantsAllowed={this.props.isAdditionalParticipantsAllowed}
            />
        );
    }
}

export default connect(
    BookSlotDialog,
    new DependencyAdapter<IBookSlotDialogProps, IBookSlotDialogDependencies>(c => {
        return {
            schedulingApiAdapter: c.resolve("SchedulingApiAdapter"),
            organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
            localizationService: c.resolve("ILocalizationService"),
            notificationService: c.resolve("INotificationService"),
        };
    })
);
