exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TargetDataPageBox_appointment-button-container_3PzcL {\n  padding-top: 19px;\n}\n.TargetDataPageBox_appointment-button_gQkr_ {\n  margin: 3px 4px 3px 0;\n}\n", ""]);

// exports
exports.locals = {
	"appointment-button-container": "TargetDataPageBox_appointment-button-container_3PzcL",
	"appointmentButtonContainer": "TargetDataPageBox_appointment-button-container_3PzcL",
	"appointment-button": "TargetDataPageBox_appointment-button_gQkr_",
	"appointmentButton": "TargetDataPageBox_appointment-button_gQkr_"
};