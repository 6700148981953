import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IDataGridColumnFilterProps, IDataGridColumnBaseProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import UserId from "@Primitives/UserId.g";
import { UserCodeSelector } from "@HisPlatformControls";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IUserColumnDependencies {
    userManagementDataProviderStore: UserManagementDataProviderStore;
    localizationService: ILocalizationService;
}

interface IUserColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IUserColumnDependencies;
}


const UserColumn: React.FC<IUserColumnProps> = (props) => {

    const { children, ...columnProps } = props;

    const renderValue = useCallback((value: UserId) => {
        if (isNullOrUndefined(value)) {return "n/a"; }
        const user = props._dependencies.userManagementDataProviderStore.users.get(value);
        return user ? user.displayName : "n/a";
    }, [props._dependencies.userManagementDataProviderStore.users.items]);

    return (
        <DataGridColumn
            onRenderCellValue={renderValue}
            onRenderFilter={renderFilter}
            clientSideOrderableValueGetter={renderValue}
            filterValueSerializer={filterValueSerializer}
            {...columnProps}
        />
    );
};

const renderFilter = (filterProps: IDataGridColumnFilterProps<UserId>) => {
    return (
        <UserCodeSelector {...filterProps} hoverOnlyIndicatorIcons />
    );
};

const filterValueSerializer: IDataGridFilterValueSerializer<UserId> = {
    deserialize: (value: string) => {
        if (!value) {
            return null;
        }
        
        return new UserId(value);
    },
    serialize: (value: UserId) => {
        return value.value;
    }
};

export default connect(
    UserColumn,
    new DependencyAdapter<IUserColumnProps, IUserColumnDependencies>(c => ({
        userManagementDataProviderStore: c.resolve("UserManagementDataProviderStore"),
        localizationService: c.resolve("ILocalizationService")
    }))
);
