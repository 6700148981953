import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import { ScreenNavigationReactContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationReactContext";
import { emptyObject, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import React, { useContext, useMemo } from "react";
import parseScreenStateFromUrl from "./ShowScreenActionHostHelper";

export interface IScreenNavigationContext {
    readonly secondaryLevel: number;

    readonly isSecondaryScreenOpen: boolean;
    readonly isSecondaryDetailOpen: boolean;
    readonly isSecondaryModalOpen: boolean;
    readonly currentPrimaryScreenRaw: string;
    readonly currentPrimaryScreen: { screenState: string; action: ShowScreenFrontendActionBase; };
    readonly currentSecondaryScreenRaw: string;
    readonly currentSecondaryScreen: { screenState: string; action: ShowScreenFrontendActionBase; };

    showAbsolutePrimary(action: FrontendActionBase, primaryScreenState?: string): void;
    setPrimaryScreenState(state: string): void;
    showPrimary(action: FrontendActionBase, primaryScreenState?: string): void;
    showSecondary(action: FrontendActionBase, screenStates?: { primaryScreenState?: string, secondaryScreenState?: string }): void;
    clearAllNotPrimary(primaryScreenState?: string): void;
}

export class ScreenNavigationContextStore implements IScreenNavigationContext {

    private readonly primaryLevel: number;
    public readonly secondaryLevel: number;
    private readonly tertiaryLevel: number;
    private readonly quaternaryLevel: number;

    @State.computed public get isSecondaryScreenOpen() {
        return !isNullOrUndefined(this.currentSecondaryScreen);
    }

    @State.computed public get isSecondaryDetailOpen() {
        const action = this.currentSecondaryScreen?.action;
        return !!action && action.displayMode === ScreenDisplayMode.Detail;
    }

    @State.computed public get isSecondaryModalOpen() {
        const action = this.currentSecondaryScreen?.action;
        return !!action && action.displayMode === ScreenDisplayMode.Modal;
    }

    @State.computed public get currentPrimaryScreenRaw(): string {
        return this.routingStore.currentRoute?.parameters.hasOwnProperty(`screen${this.primaryLevel}`) ? this.routingStore.currentRoute.parameters[`screen${this.primaryLevel}`] : null;
    }

    @State.computed public get currentPrimaryScreen() {
        return parseScreenStateFromUrl(this.currentPrimaryScreenRaw);
    }

    @State.computed public get currentSecondaryScreenRaw(): string {
        return this.routingStore.currentRoute.parameters[`screen${this.secondaryLevel}`];
    }

    @State.computed public get currentSecondaryScreen() {
        return parseScreenStateFromUrl(this.currentSecondaryScreenRaw);
    }

    constructor(
        private readonly routingStore: GlobalRoutingStore,
        parent: IScreenNavigationContext
    ) {
        this.primaryLevel = parent ? parent.secondaryLevel : 1;
        this.secondaryLevel = this.primaryLevel + 1;
        this.tertiaryLevel = this.secondaryLevel + 1;
        this.quaternaryLevel = this.tertiaryLevel + 1;
    }

    public showAbsolutePrimary(action: FrontendActionBase, primaryScreenState?: string) {
        const screenUrlState = this.getScreenUrlState(action, primaryScreenState);

        this.routingStore.push(ApplicationRoutes.screen.makeRoute({
            screen1: screenUrlState,
        }));
    }

    public setPrimaryScreenState(state: string) {
        this.routingStore.replace(ApplicationRoutes.screen.makeRoute({
            ...this.routingStore.currentRoute.parameters,
            [`screen${this.primaryLevel}`]: this.getScreenUrlWithModifiedState(this.currentPrimaryScreenRaw, state),
        }));
    }

    public showPrimary(action: FrontendActionBase, primaryScreenState?: string) {
        const screenUrlState = this.getScreenUrlState(action, primaryScreenState);

        this.routingStore.push(ApplicationRoutes.screen.makeRoute({
            ...this.routingStore.currentRoute.parameters,
            [`screen${this.primaryLevel}`]: screenUrlState,
            [`screen${this.secondaryLevel}`]: "null",
            [`screen${this.tertiaryLevel}`]: "null",
            [`screen${this.quaternaryLevel}`]: "null",
        }));
    }

    public showSecondary(action: FrontendActionBase, screenStates?: { primaryScreenState?: string, secondaryScreenState?: string }) {
        const screenUrlState = this.getScreenUrlState(action, screenStates?.secondaryScreenState);

        this.routingStore.replace(ApplicationRoutes.screen.makeRoute({
            ...this.routingStore.currentRoute.parameters,
            [`screen${this.primaryLevel}`]: this.getScreenUrlWithModifiedState(this.currentPrimaryScreenRaw, screenStates?.primaryScreenState),
            [`screen${this.secondaryLevel}`]: screenUrlState,
            [`screen${this.tertiaryLevel}`]: "null",
            [`screen${this.quaternaryLevel}`]: "null",
        }));
    }

    @State.bound
    public clearAllNotPrimary(primaryScreenState?: string) {

        if (!isNullOrUndefined(primaryScreenState)) {
            this.routingStore.replace(ApplicationRoutes.screen.makeRoute({
                ...this.routingStore.currentRoute.parameters,
                [`screen${this.primaryLevel}`]: this.getScreenUrlWithModifiedState(this.currentPrimaryScreenRaw, primaryScreenState),
                [`screen${this.secondaryLevel}`]: "null",
                [`screen${this.tertiaryLevel}`]: "null",
                [`screen${this.quaternaryLevel}`]: "null",
            }));
        } else {
            this.routingStore.replace(ApplicationRoutes.screen.makeRoute({
                ...this.routingStore.currentRoute.parameters,
                [`screen${this.secondaryLevel}`]: "null",
                [`screen${this.tertiaryLevel}`]: "null",
                [`screen${this.quaternaryLevel}`]: "null",
            }));
        }
    }

    private getScreenUrlState(action: FrontendActionBase, state?: string) {

        if (action.id.value.includes(";")) {
            throw new Error("Id of a ShowScreenFrontendAction base cannot contains ';' character.");
        }

        const actionUrlParams = (action as any).toUrl() as string[];

        if (actionUrlParams.some(p => p.includes(";"))) {
            throw new Error("ShowScreenFrontendAction.toUrl cannot return any value that contains ';' character.");
        }

        if (state?.includes(";")) {

            throw new Error("Screen state cannot contains ';' character.");
        }

        return encodeURIComponent(`${state ?? "null"};${action.id.value};${actionUrlParams.join(";")}`);
    }

    private getScreenUrlWithModifiedState(rawUrlState: string, newState: string): string {
        if (isNullOrUndefined(newState)) {
            return rawUrlState;
        }

        const primaryRawStateSegments = decodeURIComponent(rawUrlState).split(";");
        primaryRawStateSegments[0] = newState;
        return encodeURIComponent(primaryRawStateSegments.join(";"));
    }
}

export function useScreenNavigationContext() {
    return useContext(ScreenNavigationReactContext);
}

export function ScreenNavigationContextProvider(props: React.PropsWithChildren<any>) {
    const parentContext = useScreenNavigationContext();
    const { globalRoutingStore } = useDependencies(c => ({
        globalRoutingStore: c.resolve<GlobalRoutingStore>("GlobalRoutingStore"),
    }));

    const store = useMemo(() => new ScreenNavigationContextStore(globalRoutingStore, parentContext), []);

    return (
        <ScreenNavigationReactContext.Provider value={store}>
            {props.children}
        </ScreenNavigationReactContext.Provider>
    );
}

export class ScreenNavigationContextAdapter<TProps = { _screenNavigationContext: ScreenNavigationContextStore }> implements IComponentAdapter<TProps> {
    public get usedContexts(): Array<React.Context<any>> {
        return [ScreenNavigationReactContext];
    }

    constructor(
        private readonly mapToProps: (screenNavigationContext: ScreenNavigationContextStore, props: TProps) => Partial<TProps> = (screenNavigationContext) => ({ _screenNavigationContext: screenNavigationContext } as any)
    ) { }

    public getMappedProps(props: TProps, contextValues: Map<React.Context<any>, any>): Partial<TProps> {
        const careActivityContext = contextValues.get(ScreenNavigationReactContext) as ScreenNavigationContextStore;
        if (!careActivityContext) {
            return emptyObject;
        }
        return this.mapToProps(careActivityContext, props);
    }
}
