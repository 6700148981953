import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import { createAsyncErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import DynamicValueConverter from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ValueConverters/DynamicValueConverter";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import _ from "@HisPlatform/Common/Lodash";
import PerformanceStatementCareActivityFilterStore from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityWorklistPanel/Filters/PerformanceStatementCareActivityFilterStore";
import PerformanceStatementCareActivityWorklistPanelView from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityWorklistPanel/PerformanceStatementCareActivityWorklistPanelView";
import HunWorklistApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/HunWorklistApiAdapter";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import INDataRow from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataRow";
import { IRowBody, RowId } from "@CommonControls/DataGrid/IDataGridProps";
import IWorklistItemDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistItemDefinition";
import ValidationResultBody from "./RowBody/ValidationResultBody";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";


interface IPerformanceStatementCareActivityWorklistPanelDependencies {
    routingStore: GlobalRoutingStore;
    applicationContext: ApplicationContext;
    worklistApiAdapter: HunWorklistApiAdapter;
    useCaseRegistry: IUseCaseRegistry;
    dynamicValueConverter: DynamicValueConverter;
}

interface IPerformanceStatementCareActivityWorklistPanelProps extends IRoutingFrameContentProps {
    _dependencies?: IPerformanceStatementCareActivityWorklistPanelDependencies;
    selectedRowId: string;
    useCaseState: INDataUseCaseState;
    onChange: (rowId: string, useCaseState: INDataUseCaseState) => void;
}

@State.observer
class PerformanceStatementCareActivityWorklistPanel extends React.Component<IPerformanceStatementCareActivityWorklistPanelProps> {
    private runAsync = createAsyncErrorDispatcher(this);
    @State.observable.ref private worklistDefinition: IWorklistDefinition = null;
    @State.observable.ref private filterStore: PerformanceStatementCareActivityFilterStore = null;
    @State.observable.ref private multiActionActivityReference: string | null = null;

    private readonly currentUseCaseIdentifier = new UseCaseIdentifier("PerformanceStatement_CareActivities");

    public componentDidMount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(this.currentUseCaseIdentifier);
        this.runAsync(this.initializeAsync());
    }

    public componentWillUnmount() {
        this.filterStore?.clearAllFilters();
        this.props._dependencies.applicationContext.setCurrentUseCase(null);
    }

    private async initializeAsync() {
        const [worklistDefinition] = await Promise.all([this.props._dependencies.worklistApiAdapter.getPerformanceStatementCareActivityBoundWorklistDefinitionAsync()]);

        State.runInAction(() => {
            this.worklistDefinition = worklistDefinition.value;
        });
    }

    @State.action.bound
    private onFilterStoreCreatedAsync(filterStore: IFilterStore) {
        this.filterStore = new PerformanceStatementCareActivityFilterStore(filterStore);
        return Promise.resolve();
    }

    @State.bound
    private getPanelProps() {
        return {
            onCloseDetail: () => this.props.onChange(this.props.selectedRowId, null)
        };
    }

    @State.bound
    private getExtendedFilterDescriptors() {
        return PerformanceStatementCareActivityFilterStore.getFilterDescriptors();
    }

    @State.bound
    private renderRowBody(row: INDataRow, rowId: RowId, rowIndex: number, rowBodyItemDefinition: IWorklistItemDefinition): IRowBody {

        if (!!rowBodyItemDefinition) {
            const rawValue = _.get(row, rowBodyItemDefinition.attributeName);

            if (!!rawValue && rowBodyItemDefinition.attributeType === "ValidationResult" && this.hasValidationErrors(rawValue)) {
                return {
                    showCells: true,
                    content: <ValidationResultBody rawJsonValue={rawValue} />
                };
            }
        }
        return null;
    }

    @State.bound
    private initializeFilter(filterStore: any, itemDefinitions: IWorklistItemDefinition[]) {
        if (!filterStore) {
            return;
        }

        const prevMonthStart = LocalDate.today().plusMonths(-1).firstDayOfMonth();
        const prevMonthEnd = prevMonthStart.plusMonths(1).plusDays(-1);

        const wentUnderCareAtDef = itemDefinitions.filter(d => d.attributeName === "CareActivityBased_WentUnderCareAt");
        if (wentUnderCareAtDef && isNullOrUndefined(filterStore["CareActivityBased_WentUnderCareAt"])) {
            const setter = filterStore["set_CareActivityBased_WentUnderCareAt"];
            if (setter) {
                setter(new LocalDateRange(prevMonthStart, prevMonthEnd));
            }
        }
    }

    @State.bound
    private hasValidationErrors(validationResults: IClientValidationResult[]): boolean {
        return _.flatten(validationResults.map(i => i.problems)).filter(i => i.severity === "error").length > 0;
    }

    public render() {
        return (
            <PerformanceStatementCareActivityWorklistPanelView
                worklistDefinition={this.worklistDefinition}
                filterStore={this.filterStore && this.filterStore}
                onFilterStoreCreatedAsync={this.onFilterStoreCreatedAsync}
                onGetExtendedFilterDescriptors={this.getExtendedFilterDescriptors}
                onChange={this.props.onChange}
                useCaseState={this.props.useCaseState}
                selectedRowId={this.props.selectedRowId}
                getPanelProps={this.getPanelProps}
                onRenderRowBody={this.renderRowBody}
                multiActionActivityReference={this.multiActionActivityReference}
                onMultiActionActivityReferenceChange={this.setMultiActivityReference}
                onInitializeFilter={this.initializeFilter}
            />
        );
    }

    @State.action.bound
    private setMultiActivityReference(value: string) {
        this.multiActionActivityReference = value;
    }
}

export default connect(
    withHisErrorBoundary(PerformanceStatementCareActivityWorklistPanel),
    new DependencyAdapter<IPerformanceStatementCareActivityWorklistPanelProps, IPerformanceStatementCareActivityWorklistPanelDependencies>(container => {
        return {
            routingStore: container.resolve("GlobalRoutingStore"),
            applicationContext: container.resolve("ApplicationContext"),
            worklistApiAdapter: container.resolve("HunWorklistApiAdapter"),
            useCaseRegistry: container.resolve("IUseCaseRegistry"),
            dynamicValueConverter: container.resolve("DynamicValueConverter")
        };
    })
);
