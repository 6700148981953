import React from "react";
import * as Ui from "@CommonControls";
import TextualPrescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/TextualPrescription";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import PackagingUnitSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PackagingUnitSelector/PackagingUnitSelector";
import Prescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Prescription";
import ExtendedDosageEditor from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DosageEditor/ExtendedDosageEditor";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PrescriptionTemplateType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionTemplateType";

interface ITextualPrescriptionDetailView {
    prescription: Prescription;
}

const TextualPrescriptionDetailView: React.FC<ITextualPrescriptionDetailView> = props => {
    const textualPrescription = props.prescription.medicationReferenceBase as TextualPrescription;
    const isFormulaTextualPrescriptionType = textualPrescription.type === PrescriptionTemplateType.Formula;
    const resources = StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail;
    return (
        <>
            {isFormulaTextualPrescriptionType &&
                <Ui.InfoBox iconName="info_with_circle">
                    {resources.FormulaTextualPrescriptionWarning}
                </Ui.InfoBox>
            }
            <Ui.Flex>
                <Ui.Flex.Item xs={6}>
                    <ReadOnlyContext.Provider value={true}>
                        <Ui.TextBox
                            value={textualPrescription.name}
                            isReadOnly
                            label={resources.Labels.Name}
                            automationId="nameTextBox" />
                    </ReadOnlyContext.Provider>
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={6}>
                    <Ui.TextBox
                        value={textualPrescription.displayName}
                        onChange={textualPrescription.setDisplayName}
                        label={resources.Labels.DisplayName}
                        automationId="displayNameTextBox"
                        propertyIdentifier="MedicationReference.DisplayName" />
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex>
                <Ui.Flex.Item xs={12}>
                    <Ui.TextBox
                        value={textualPrescription.content}
                        onChange={textualPrescription.setContent}
                        label={isFormulaTextualPrescriptionType ? resources.Labels.Content : resources.Labels.Rp}
                        multiline
                        propertyIdentifier="MedicationReference.Content"
                        automationId="contentTextBox" />
                </Ui.Flex.Item>
                {isFormulaTextualPrescriptionType && (<>
                    <Ui.Flex.Item xs={12}>
                        <Ui.TextBox
                            value={textualPrescription.preparation}
                            onChange={textualPrescription.setPreparation}
                            label={resources.Labels.Preparation}
                            multiline
                            propertyIdentifier="MedicationReference.Preparation"
                            automationId="preparationTextBox" />
                    </Ui.Flex.Item>
                </>)}
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <PackagingUnitSelector
                            value={textualPrescription.packagingUnitId}
                            onChange={textualPrescription.setPackagingUnitId}
                            label={resources.Labels.PackagingUnit}
                            propertyIdentifier="MedicationReference.PackagingUnitId"
                            automationId="packagingUnitSelector"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <Ui.NumberBox
                            value={textualPrescription.amount}
                            onChange={textualPrescription.setAmount}
                            label={resources.Labels.Amount}
                            propertyIdentifier="MedicationReference.Amount"
                            automationId="amount"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item style={{ alignSelf: "center" }} xs={4} >
                        <Ui.CheckBox
                            value={!props.prescription.isSubstitubable}
                            onChange={props.prescription.setIsSubstitubable}
                            label={resources.Labels.IsSubstitubable}
                            labelPosition="right" automationId="isSubstitubableCheckBox" />
                    </Ui.Flex.Item>
                    {!props.prescription.isSubstitubable &&
                        <Ui.Flex.Item xs={12}>
                            <Ui.TextBox
                                value={props.prescription.insubstitutableReason}
                                onChange={props.prescription.setInsubstitutableReason}
                                label={resources.Labels.InsubstitutableReason}
                                isReadOnly={props.prescription.isSubstitubable} propertyIdentifier="InsubstitutableReason"
                                automationId="insubstitutableReasonTextBox" />
                        </Ui.Flex.Item>}
                </Ui.Flex>
            </Ui.Flex>
            <Ui.GroupBox title={resources.GroupTitles.Dosage}>
                <ExtendedDosageEditor
                    dosage={textualPrescription.dosage}
                    setDosage={textualPrescription.setDosage}
                    inSubstitutableReason={props.prescription.insubstitutableReason}
                    setInSubstitutableReason={props.prescription.setInsubstitutableReason}
                    instruction={props.prescription.instruction}
                    setInstruction={props.prescription.setInstruction}
                    isSubstitutable={props.prescription.isSubstitubable}
                    setIsSubstitutable={props.prescription.setIsSubstitubable}
                    showReason={false}
                    showEquippedAndTextualDosage={false}
                />
            </Ui.GroupBox>
        </>
    );
};
export default State.observer(TextualPrescriptionDetailView);