import React from "react";
import { VelocityTransitionGroup, VelocityComponent } from "velocity-react";
import Classes from "./TreeGrid.less";
import { IRenderedTableFrameColumn } from "./TableFrame";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Icon from "@CommonControls/Icon";
import ITreeNodeStore from "./ITreeNodeStore";
import CheckBox from "@CommonControls/CheckBox";

interface ITreeNodeProps {
    columns: IRenderedTableFrameColumn[];
    active: boolean;
    toggled: boolean;
    node: ITreeNodeStore;
    treeLevel: number;
    onToggle: (node: ITreeNodeStore, isToggled: boolean) => void;
    onRenderDisplayValue?: (node: ITreeNodeStore, column: IRenderedTableFrameColumn, columnIndex: number) => React.ReactNode;
    onSelect?: (node: ITreeNodeStore) => void;
    onChecked?: (node: ITreeNodeStore, isChecked: boolean) => void;
    stopClickPropagation?: boolean;
    isCheckable?: boolean;
    automationId?: string;
}

@State.observer
export default class TreeNode extends React.Component<ITreeNodeProps> {
    @State.bound
    private select(e: React.MouseEvent) {
        if (this.props.stopClickPropagation) {
            e.stopPropagation();
        }

        if (this.props.onSelect) {
            this.props.onSelect(this.props.node);
        }
    }

    @State.bound
    private toggle(e: React.MouseEvent) {
        if (this.props.stopClickPropagation) {
            e.stopPropagation();
        }

        if (this.props.onToggle) {
            this.props.onToggle(this.props.node, !this.props.node.isOpen);
        }
    }

    @State.bound
    private checked(newValue: boolean) {
        if (this.props.onChecked) {
            this.props.onChecked(this.props.node, newValue);
        }
    }

    public render() {
        return (
            <>
                <li
                    className={this.props.active ? Classes.nodeSelected : Classes.node}
                    onClick={this.select}
                    style={{ paddingLeft: `${this.props.treeLevel * 15}px` }}
                >
                    {!!this.props.node.children && this.props.node.children.length > 0 && this.renderToggle()}
                    {this.renderDisplay()}
                </li>
                {
                    <VelocityTransitionGroup
                        enter={{ animation: "slideDown", duration: 300 }}
                        leave={{ animation: "slideUp", duration: 300 }}
                        component={null}
                    >
                        {this.props.toggled ? this.props.children : null}
                    </VelocityTransitionGroup>
                }
            </>
        );
    }

    private renderToggle() {
        return (
            <VelocityComponent animation={{ rotateZ: this.props.node.isOpen ? 90 : 0 }} duration={300}>
                <div className={Classes.nodeToggle} onClick={this.toggle} data-automation-id={`${this.props.automationId}_Toggle`}>
                    <Icon iconName="caretRight" />
                </div>
            </VelocityComponent>

        );
    }

    private renderDisplay() {
        return this.props.columns && (
            <div className={Classes.nodeDisplay}>
                {this.props.isCheckable &&
                    <CheckBox
                        value={this.props.node.isChecked}
                        stopClickPropagation={this.props.stopClickPropagation}
                        onChange={this.checked}
                        verticalAlign="noPadding"
                        className={Classes.nodeCheckbox}
                        canBeNull={!!this.props.node.children && this.props.node.children.length > 0}
                        automationId={`${this.props.automationId}_CheckBox`}
                    />}

                {this.props.columns.map((c, cIdx) => {
                    if (cIdx === 0) {
                        let firstElementWidth: number = c.renderedWidth - 30 - (15 * this.props.treeLevel);
                        if (this.props.isCheckable) {
                            firstElementWidth -= 27;
                        }

                        const style: React.CSSProperties = {
                            flexBasis: `${firstElementWidth}px`
                        };
                        if (this.props.node.isChecked || this.props.node.isChecked === null) {
                            style.fontFamily = "PragatiNarrowBold";
                        }
                        
                        return (
                            <div key={cIdx} style={style}>
                                {this.props.onRenderDisplayValue(this.props.node, c, cIdx)}
                            </div>
                        );
                    }

                    return (
                        <div key={cIdx} style={{ flexBasis: `${c.renderedWidth}px` }}>
                            {this.props.onRenderDisplayValue(this.props.node, c, cIdx)}
                        </div>
                    );
                })}
            </div>
        ) || null;
    }
}