import React from "react";
import * as Ui from "@CommonControls";
import * as Styles from "./OrganizationUnitTreePanel.less";
import MasterDetailView from "@HisPlatform/Components/Panels/ServiceRequestPanel/MasterDetailView";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import PropertyGroupsPanel, { IPropertyGroupsPanelController } from "@HisPlatform/BoundedContexts/Organization/Components/Panels/PropertyGroupsPanel/PropertyGroupsPanel";
import OrganizationUnitPanel, { IOrganizationUnitPanelController } from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitPanel/OrganizationUnitPanel";
import OrganizationUnitDefinitionId from "@Primitives/OrganizationUnitDefinitionId.g";
import OrganizationUnitTreeNode from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnitTreeNode";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import OrganizationUnitTreeGrid from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/OrganizationUnitTreeGrid";
import { IObservableValue } from "@Toolkit/ReactClient/Common/StateManaging";
import { OrganizationUnitTreePanelDetailMode } from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/OrganizationUnitTreePanelDetailMode";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { OrganizationUnitName } from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/TreeColumnRenderers";

interface IOrganizationUnitTreePanelViewProps {
    newOrganizationUnitDefinitionId: OrganizationUnitDefinitionId;
    onSuccessfulSave: (id: OrganizationUnitId) => void;
    newOrganizationUnitParentId: OrganizationUnitId;
    organizationUnitPanelController: IOrganizationUnitPanelController;
    propertyGroupsPanelController: IPropertyGroupsPanelController;
    organizationStructure: OrganizationUnitTreeNode[];
    nodeOpened: (node: OrganizationUnitTreeNode, isOpen: boolean) => void;
    filterValue: string;
    debouncedFilterValueBox: IObservableValue<string>;
    setFilterValue: (value: string) => void;

    selectedOrganizationUnit: OrganizationUnitTreeNode;
    detailMode: OrganizationUnitTreePanelDetailMode;
    addingNew: boolean;
    onEdit: (node: OrganizationUnitTreeNode) => void;
    onCloseDetail: () => void;
    onCancelDetail: () => void;
    onDeleteAsync: (node: OrganizationUnitTreeNode) => Promise<void>;
    onAddChildAsync: (node: OrganizationUnitTreeNode) => Promise<void>;
    onDetailChange: (detail: OrganizationUnitTreePanelDetailMode) => void;

    isLoading: boolean;
}

const renderList = (props: IOrganizationUnitTreePanelViewProps) => {
    return (
        <Ui.PageBox widthMode="unlimited" title={StaticOrganizationResources.OrganizationUnitPanel.Title} isLoading={props.isLoading}>
            <Ui.PageBox.Body>
                <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        <Ui.TextBox
                            label={StaticWebAppResources.Common.Label.Filter}
                            onChange={props.setFilterValue}
                            value={props.filterValue}
                            valueOnClear=""
                            automationId="filterValueTextBox"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                {props.organizationStructure && props.organizationStructure.length > 0 &&
                    <OrganizationUnitTreeGrid
                        organizationStructure={props.organizationStructure}
                        onNodeOpenStateChange={props.nodeOpened}
                        selectedNode={props.selectedOrganizationUnit}
                        debouncedFilterValueBox={props.debouncedFilterValueBox}
                        onAddChildAsync={props.onAddChildAsync}
                        onDeleteAsync={props.onDeleteAsync}
                        onEdit={props.onEdit}
                        isDetailOpen={!!props.selectedOrganizationUnit && !isNullOrUndefined(props.detailMode)}
                        addingNew={props.addingNew}
                    />
                }
                {
                    (!props.organizationStructure || props.organizationStructure.length === 0) && props.filterValue !== "" &&
                    <>
                        <br />
                        <Ui.Message type="dark">{StaticWebAppResources.Common.Label.NoFilterMatch}</Ui.Message>
                    </>
                }
            </Ui.PageBox.Body>
        </Ui.PageBox >
    );
};

const renderDetail = (props: IOrganizationUnitTreePanelViewProps) => {

    const setDetail = (newIndex: number) => {
        props.onDetailChange(newIndex === 0 ? "base-data" : "parameters");
    };

    return (
        <div className={Styles.detailContainer}>
            <Ui.Tab
                activeIndex={props.detailMode === "base-data" ? 0 : 1}
                onSelectedIndexChange={setDetail}
                headerTextAlign="center"
                tabsGrow={false}
                visualStyle="pageBox"
                noContent
                style={{ marginTop: 8 }}
            >
                <Ui.TabPane title={StaticOrganizationResources.OrganizationUnitPanel.BaseData} automationId="baseData" />
                <Ui.TabPane title={StaticOrganizationResources.OrganizationUnitPanel.Parameters} automationId="parameters" />
            </Ui.Tab>

            <Ui.PageBox
                widthMode="unlimited"
                style={{ minHeight: "calc(100% - 50px)" }}
                noMargin
            >
                <Ui.PageBox.Header toolbar="left">
                    <Ui.Button
                        iconName="angleLeft"
                        visualStyle="standard"
                        onClick={props.onCancelDetail}
                        size="compact"
                        automationId="closeDetail" />
                    <div className={Styles.titleSpace}>
                        <h1>
                            <OrganizationUnitName
                                node={props.selectedOrganizationUnit}
                            />
                        </h1>
                    </div>
                </Ui.PageBox.Header>
                <Ui.PageBox.Body>
                    {props.detailMode === "base-data" ? (
                        <OrganizationUnitPanel
                            organizationUnitId={props.selectedOrganizationUnit.organizationUnitId}
                            newItemDefinitionId={props.newOrganizationUnitDefinitionId}
                            onSuccessfulSave={props.onSuccessfulSave}
                            parentOrganizationUnitId={props.newOrganizationUnitParentId}
                            onCancel={props.onCancelDetail}
                            controller={props.organizationUnitPanelController}
                        />
                    ) : (
                            <PropertyGroupsPanel
                                organizationUnitId={props.selectedOrganizationUnit.organizationUnitId}
                                onCancel={props.onCloseDetail}
                                controller={props.propertyGroupsPanelController} />
                        )}
                </Ui.PageBox.Body>
            </Ui.PageBox>
        </div>
    );
};


const OrganizationUnitTreePanelView: React.FC<IOrganizationUnitTreePanelViewProps> = props => {
    return (
        <>
            <MasterDetailView
                alignCenter
                list={renderList(props)}
                detail={!!props.selectedOrganizationUnit ? renderDetail(props) : null}
                isDetailOpen={!!props.selectedOrganizationUnit && !isNullOrUndefined(props.detailMode)}
                className={Styles.noMargin}
            />
        </>
    );
};

export default OrganizationUnitTreePanelView;