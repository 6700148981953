
export type ConstructorType<T = any> = new (...args: any[]) => T;
export interface ITypeInfo {
    typeName: string;
    constructor: ConstructorType;
    attributes: string[];
}

class Reflection {

    private readonly _typeMap = new Map<string, ITypeInfo>();

    public registerType(type: ConstructorType, typeName: string, ...attributes: string[]) {
        this._typeMap.set(typeName, {
            typeName,
            constructor: type,
            attributes
        });
    }

    public createInstance<T>(typeName: string, ...args: any[]) {
        const typeInfo = this._typeMap.get(typeName);

        if (!typeInfo) {
            throw new Error(`Reflection.createInstance: Cannot find registered type with name: '${typeName}'`);
        }

        return new typeInfo.constructor(...args) as T;
    }

    public getTypeInfo(typeName: string): ITypeInfo | null {
        return this._typeMap.get(typeName) ?? null;
    }

}

export default new Reflection();