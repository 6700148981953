import StaticHunEHealthInfrastructureUserManagementResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/StaticResources/StaticHunEHealthInfrastructureUserManagementResources";
import React, { ReactNode } from "react";
import * as Styles from "./PractitionerEhiLogin.less";
import * as Ui from "@CommonControls";
import EhiLoginMode from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiLoginMode";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ClientSideValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidationBoundary";
import EhiToken from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiToken";
import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IEhiLoginDialogViewProps {
    ehiIdentifier: string;
    ehiToken: EhiToken;
    eidTerminalLoading: boolean;
    password: string;
    setPassword: (newValue: string) => void;
    otp: string;
    setOtp: (newValue: string) => void;
    ehiLoginMode: EhiLoginMode;
    setEhiLoginMode: (selectedItem: any) => void;
    localizationService: ILocalizationService;
    validator: ClientSideValidator;
    loginAsync: () => void;
    onClose: () => void;
    isRenew: boolean;
    loginName?: string;
}
const localizeEhiLoginMode = (localizationService: ILocalizationService) => (loginMode: EhiLoginMode) => {
    return localizationService.localizeEnum(EhiLoginMode[loginMode], "EhiLoginMode").Name;
};
const renderLogin = (props: IEhiLoginDialogViewProps) => {
    return (
        <>
            <Ui.Flex>
                <Ui.Flex.Item xs={12}>
                    <Ui.RadioButtonGroup
                        items={[EhiLoginMode.Eid, EhiLoginMode.Mobile, EhiLoginMode.Otp]}
                        getDisplayValue={localizeEhiLoginMode(props.localizationService)}
                        displayType="groupedButtons"
                        disabled={props.eidTerminalLoading}
                        onChange={props.setEhiLoginMode}
                        value={props.ehiLoginMode} />
                </Ui.Flex.Item>
            </Ui.Flex>
            {props.ehiLoginMode === EhiLoginMode.Otp && (
                <ClientSideValidationBoundary validator={props.validator} entityTypeName="LoginToEhiWithOtp">
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <Ui.TextBox
                                label={StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Labels.Otp}
                                onChange={props.setOtp}
                                value={props.otp}
                                propertyIdentifier="OneTimePassword"
                                automationId="otpTextBox"
                                required />
                        </Ui.Flex.Item>
                    </Ui.Flex>

                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <Ui.TextBox
                                label={StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Labels.Password}
                                isPassword
                                onChange={props.setPassword}
                                value={props.password}
                                propertyIdentifier="Password"
                                automationId="passwordTextBox"
                                required />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </ClientSideValidationBoundary>
            )}
            {props.ehiLoginMode === EhiLoginMode.Mobile && (
                <ClientSideValidationBoundary validator={props.validator} entityTypeName="LoginToEhiWithMobile">
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <Ui.TextBox
                                label={StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Labels.Otp}
                                onChange={props.setOtp}
                                value={props.otp}
                                propertyIdentifier="OneTimePassword"
                                automationId="otpTextBox"
                                required />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </ClientSideValidationBoundary>
            )}
            {props.ehiLoginMode === EhiLoginMode.Eid && (
                <p>
                    {props.eidTerminalLoading && StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.CommunicatingWithTerminal}
                </p>
            )}
        </>
    );
};
const renderLoggedInContent = (ehiToken: EhiToken, localizationService: ILocalizationService) => {
    return (
        <>
            {StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.LoggedIn}<br />
            <span className={Styles.label}>{StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Labels.Validity}:</span> <span className={Styles.bold}>{localizationService.localizeDateTime(ehiToken.validFrom)} - {localizationService.localizeDateTime(ehiToken.validTo)}</span>
        </>
    );
};


const EhiLoginDialogView: React.FC<IEhiLoginDialogViewProps> = props => (
    <Ui.Modal
        isOpen
        title={StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.ModalTitle}
        size="tiny"
        closeOnOutsideClick={false}
        closeButton
        isHeaderPrimary
        onClose={props.onClose}
    >
        <Ui.Modal.Body>
            <div className={Styles.modalBody}>
                <Ui.Flex>
                    <Ui.Flex.Item>
                        <label><span className={Styles.label}>{StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Labels.Identifier}:</span> <span className={Styles.bold}>{props.ehiIdentifier}</span>{!isNullOrUndefined(props.loginName) && <span className={Styles.label}> - {props.loginName}</span>}</label>
                    </Ui.Flex.Item>
                </Ui.Flex>
                {(!props.ehiToken || props.isRenew) && renderLogin(props)}
                {(props.ehiToken && !props.isRenew) && renderLoggedInContent(props.ehiToken, props.localizationService)}

            </div>
        </Ui.Modal.Body>

        <Ui.Modal.Footer>
            {renderButtons(props)}
        </Ui.Modal.Footer>

    </Ui.Modal>
);
const renderButtons = (props: IEhiLoginDialogViewProps) => {
    return (
        <Ui.Flex xsJustify="between">
            {(!props.ehiToken || props.isRenew) && (
                <Ui.Flex.Item>
                    <Ui.Button visualStyle="link" onClick={props.onClose} text={StaticWebAppResources.Common.Button.Cancel} automationId="cancelButton" />
                </Ui.Flex.Item>
            )}
            <Ui.Flex.Item>
                {(props.ehiToken && !props.isRenew) && (
                    <Ui.Button visualStyle="link" onClick={props.onClose} text={StaticWebAppResources.Common.Button.Close} automationId="closeButton" />
                )}
                {(!props.ehiToken || props.isRenew) && (
                    <Ui.Button onClick={props.loginAsync} text={StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Buttons.Login} visualStyle="primary" automationId="loginButton" />
                )}
            </Ui.Flex.Item>
        </Ui.Flex>
    );
};

export default EhiLoginDialogView;
