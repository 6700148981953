import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import DeviceUseStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/DeviceUseStatementStore";
import DeviceUseStatementId from "@Primitives/DeviceUseStatementId.g";
import ReferencedDeviceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/ReferencedDeviceAssignment";
import CustomDeviceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/CustomDeviceAssignment";
import DeviceUseStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/DeviceUseStatementHistoryItem";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import DeviceDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/DeviceDetail";
import UsageDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/UsageDetail";

type ResponseType = Proxy.GetDeviceUseStatementByIdCommandResponse | Proxy.AddDeviceUseStatementCommandResponse | Proxy.UpdateDeviceUseStatementCommandResponse | Proxy.DeleteDeviceUseStatementHistoryItemCommandResponse;

@Di.injectable()
export default class DeviceUseStatementStoreMapper extends LockingEntityStoreMapper<Proxy.DeviceUseStatementDto, DeviceUseStatementStore, ResponseType> {

    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    protected storeEntityIdType = DeviceUseStatementId;

    protected entityDtoSelector = (response: ResponseType) => {
        return response.deviceUseStatementDto;
    };

    protected applyToStoreCore(target: DeviceUseStatementStore, response: any) {
        const dto = this.entityDtoSelector(response);
        if (dto != null) {
            target.id = dto.id;
            target.extendedDeviceName = dto.extendedDeviceName;
            target.deviceAssignment = this.getDeviceAssignment(dto);
            target.historyItems = State.observable(this.getHistoryItems(dto.historyItems));
            target.currentItemIndex = target.historyItems.length - 1;
            target.patientId = dto.patientId;
            target.extensionData = dto.extensionData;
        }
    }

    private getHistoryItems(dtoArray: Proxy.DeviceUseStatementHistoryItemDto[]): DeviceUseStatementHistoryItem[] {
        return dtoArray.map(dto => this.getHistoryItem(dto)).sort((a, b) => a.versionNumber - b.versionNumber);
    }

    private getHistoryItem(dto: Proxy.DeviceUseStatementHistoryItemDto): DeviceUseStatementHistoryItem {
        const returnItem = new DeviceUseStatementHistoryItem();

        returnItem.historyItemDetail = this.getHistoryItemDetail(dto.historyItemDetail);
        returnItem.deviceDetail = this.getDeviceDetail(dto);
        returnItem.usageDetail = this.getUsageDetail(dto);
        returnItem.versionNumber = dto.versionNumber;
        returnItem.index = dto.index;
        returnItem.createdAt = dto.createdAt;
        returnItem.extensionData = dto.extensionData;

        return returnItem;
    }

    private getHistoryItemDetail(dto: Proxy.MedicalConditionHistoryItemDetailDto): MedicalConditionHistoryItemDetail {
        const returnItem = new MedicalConditionHistoryItemDetail();

        returnItem.asserter = dto.asserter;
        returnItem.asserterType = dto.asserterType;
        returnItem.notes = dto.notes;
        returnItem.verifier = dto.verifier;

        return returnItem;
    }

    private getDeviceAssignment(dto: Proxy.DeviceUseStatementDto) {
        let deviceAssignment;

        if (dto.deviceAssignment instanceof Proxy.ReferencedDeviceAssignmentDto) {
            deviceAssignment = new ReferencedDeviceAssignment(dto.deviceAssignment.deviceId);
        } else if (dto.deviceAssignment instanceof Proxy.CustomDeviceAssignmentDto) {
            deviceAssignment = new CustomDeviceAssignment(dto.deviceAssignment.name);
        }

        return deviceAssignment;
    }

    private getDeviceDetail(dto: Proxy.DeviceUseStatementHistoryItemDto) {
        return new DeviceDetail(dto.deviceDetail.manufacturer, dto.deviceDetail.modelName, dto.deviceDetail.modelType, dto.deviceDetail.serialNumber);
    }

    private getUsageDetail(dto: Proxy.DeviceUseStatementHistoryItemDto) {
        return new UsageDetail(dto.usageDetail.endOfUse, dto.usageDetail.startOfUse);
    }
}
