import React from "react";
import * as Recharts from "recharts";
import { IChartProps } from "@CommonControls/Chart/IChartProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export interface ILineChartProps extends IChartProps {
    lineSettings?: Array<{ dataKey: string; lineColor: string; pointColor: string; label?: string; unit?: string; }>;
    theme?: "normal" | "dark";
    XAxisTick?: boolean;
    YAxisTick?: boolean;
    lowReferenceValue?: number;
    highReferenceValue?: number;
}


const LineChart: React.FC<ILineChartProps> = (props) => {

    const isDarkTheme = props.theme && props.theme === "dark";
    const multiplier = props.highReferenceValue && props.highReferenceValue * 0.1;
    let domain: [Recharts.AxisDomain, Recharts.AxisDomain] = null;

    if (!isNullOrUndefined(props.lowReferenceValue) && !isNullOrUndefined(props.highReferenceValue)) {
        const lowValue = props.lowReferenceValue - multiplier;
        const highValue = props.highReferenceValue + multiplier;
        domain = [lowValue, highValue];
    }

    return (
        <Recharts.ResponsiveContainer>
            <Recharts.LineChart
                width={props.width}
                height={props.height}
                data={props.data}
                syncId={props.syncId}
                margin={props.margin}
            >
                <Recharts.CartesianGrid strokeDasharray="3 3" stroke={isDarkTheme ? "white" : undefined} />
                <Recharts.XAxis
                    dataKey={props.XAxisDataKey}
                    stroke={isDarkTheme ? "white" : undefined}
                    tick={props.XAxisTick} />
                <Recharts.YAxis
                    domain={domain}
                    dataKey={props.YAxisDataKey}
                    stroke={isDarkTheme ? "white" : undefined}
                    tick={props.YAxisTick} />
                {props.isTooltipEnabled && <Recharts.Tooltip labelStyle={{ color: "#000" }} />}
                {props.isLegendEnabled && <Recharts.Legend />}
                {props.highReferenceValue && <Recharts.ReferenceLine y={props.highReferenceValue} stroke="lightcoral" />}
                {props.lowReferenceValue && <Recharts.ReferenceLine y={props.lowReferenceValue} stroke="lightcoral" />}
                {props.lineSettings.map(s => {
                    return (
                        <Recharts.Line
                            type="monotone"
                            key={s.dataKey.toString()}
                            dataKey={s.dataKey}
                            unit={s.unit}
                            name={s.label}
                            stroke={s.lineColor}
                            fill={s.pointColor} />
                    );
                })}
            </Recharts.LineChart>
        </Recharts.ResponsiveContainer>
    );
};

LineChart.defaultProps = {
    lineSettings: [
        {
            dataKey: "value",
            pointColor: "#00b4ec",
            lineColor: "#00b4ec"
        }
    ]
};


export default LineChart;