import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import StoreMapper from "@Toolkit/CommonWeb/ApiAdapter/StoreMapper";
import IDocumentSnippet from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentSnippet/IDocumentSnippet";
import Di from "@Di";

@Di.injectable()
export default class DocumentSnippetDialogStoreMapper extends StoreMapper<SimpleStore<IDocumentSnippet[]>, Proxy.GetDocumentSnippetsByDocumentSnippetCategoryIdQueryResponse> {

    protected applyToStoreCore(target: SimpleStore<IDocumentSnippet[]>, response: Proxy.GetDocumentSnippetsByDocumentSnippetCategoryIdQueryResponse) {
        target.value = response.documentSnippets.map((s) => {
            return {
                id: s.id,
                code: s.code,
                groupName: s.groupName,
                name: s.name
            } as IDocumentSnippet;
        });
    }
}