import LockId from "@Toolkit/CommonWeb/Model/LockId";
import * as Proxy from "@HisPlatform/BoundedContexts/Locking/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import BusinessErrorOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/BusinessErrorOperationInfo";
import LockDoesNotExistsError from "@Toolkit/CommonWeb/Model/LockDoesNotExistsError";

@Di.injectable()
export default class LockingApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("ILockingClient") private readonly apiClient: Proxy.ILockingClient
    ) {
        super();
    }

    public releaseLockAsync(lockId: LockId, throwOnLockDoesNotExistsError: boolean = false): Promise<SimpleStore> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {

                const requestId = CreateRequestId();

                try {
                    const response = await this.apiClient.releaseLockCommandAsync(
                        requestId,
                        new Proxy.ReleaseLockControllerDto({
                            lockId: lockId
                        })
                    );

                    target.operationInfo = createOperationInfo(response);

                } catch (err) {
                    target.operationInfo = new BusinessErrorOperationInfo(err.businessError, requestId);
                    if (err.businessError instanceof LockDoesNotExistsError && !throwOnLockDoesNotExistsError) {
                        return;
                    }
                    throw err;
                }
            }
        );
    }

    public forceReleaseLockAsync(lockId: LockId, throwOnLockDoesNotExistsError: boolean = false): Promise<SimpleStore> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const requestId = CreateRequestId();

                try {
                    const response = await this.apiClient.forceReleaseLockCommandAsync(
                        requestId,
                        new Proxy.ForceReleaseLockControllerDto({
                            lockId: lockId
                        })
                    );

                    target.operationInfo = createOperationInfo(response);

                } catch (err) {
                    target.operationInfo = new BusinessErrorOperationInfo(err.businessError, requestId);
                    if (err.businessError instanceof LockDoesNotExistsError && !throwOnLockDoesNotExistsError) {
                        return;
                    }
                    throw err;
                }
            }
        );
    }

    public releaseCurrentUserLocksAsync(): Promise<SimpleStore> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.releaseMyLocksCommandAsync(
                    CreateRequestId(),
                    new Proxy.ReleaseMyLocksControllerDto({})
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public renewMyLocksAsync(): Promise<SimpleStore> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.renewMyLocksCommandAsync(
                    CreateRequestId(),
                    new Proxy.RenewMyLocksControllerDto({})
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}