/* eslint-disable @typescript-eslint/no-this-alias */

interface Blob {
    toUint8ArrayAsync(): Promise<Uint8Array>;
    toDataURLAsync(): Promise<string>;
}

Blob.prototype.toUint8ArrayAsync = function () {
    const blob = this;
    const fileReader = new FileReader();
    
    return new Promise<Uint8Array>((resolve, reject) => {
        fileReader.onerror = () => {
            fileReader.abort();
            reject("BlobExtensions: Cannot convert Blob to Uint8Array");
        };

        fileReader.onload = () => {
            resolve(new Uint8Array(fileReader.result as ArrayBuffer));
        };

        fileReader.readAsArrayBuffer(blob);
        return Promise.resolve();
    });
};

Blob.prototype.toDataURLAsync = function () {
    const blob = this;
    const fileReader = new FileReader();
    
    return new Promise<string>((resolve, reject) => {
        fileReader.onerror = () => {
            fileReader.abort();
            reject("BlobExtensions: Cannot convert Blob to Uint8Array");
        };

        fileReader.onload = () => {
            resolve(fileReader.result as string);
        };

        fileReader.readAsDataURL(blob);
        return Promise.resolve();
    });
};