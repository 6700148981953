import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PointOfCareSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareSelectBox";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { iconNameType } from "@CommonControls/Icon";
import * as Ui from "@CommonControls";
import { getIconNameByHealthcareProfessions } from "@HisPlatform/BoundedContexts/Organization/Components/Helpers/IconTypeNameHelper";

interface IPointOfCareColumnDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IPointOfCareColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IPointOfCareColumnDependencies;
    displayMode?: DisplayMode;
}

export type DisplayMode = "shorthand" | "name" | "shorthand-name";

const filterValueSerializer: IDataGridFilterValueSerializer<PointOfCareId | PointOfCareId[]> = {
    deserialize: (value: string) => {
        if (!value) {
            return null;
        }
        const items = value.split(",");
        if (items.length === 1) {
            return new PointOfCareId(items[0]);
        }

        return items.map(i => new PointOfCareId(i));
    },
    serialize: (value: PointOfCareId | PointOfCareId[]) => {
        return Array.isArray(value) ? value.map(v => v.value).join(",") : value.value;
    }
};

export function pointOfCareColumnCellRenderer(organizationReferenceDataStore: OrganizationReferenceDataStore, value: PointOfCareId, displayMode?: DisplayMode) {
    const item = isNullOrUndefined(value) ? null : organizationReferenceDataStore.allPointsOfCareMap.get(value);

    if (isNullOrUndefined(item)) {
        return null;
    }
    let text: React.ReactNode;

    switch (displayMode) {
        case "name":
            text = item.name;
            break;
        case "shorthand":
            text = item.shorthand;
            break;
        case "shorthand-name":
        default:
            text = <><b>{item.shorthand}</b>{` - ${item.name}`}</>;
    }

    const iconName: iconNameType = getIconNameByHealthcareProfessions(item.healthcareProfessionIds.map(organizationReferenceDataStore.healthCareProfession.get));

    return (
        <>
            {iconName &&
                <Ui.Icon
                    iconName={iconName}
                    style={{ marginRight: 5 }}
                />}
            {text}
        </>
    );
}


const PointOfCareColumn: React.FC<IPointOfCareColumnProps> = (props: IPointOfCareColumnProps) => {
    const valueRenderer = useCallback((value: PointOfCareId) =>
        pointOfCareColumnCellRenderer(props._dependencies.organizationReferenceDataStore, value, props.displayMode)
        , []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<PointOfCareId | PointOfCareId[]>) => {
        return <PointOfCareSelectBox {...filterProps} hoverOnlyIndicatorIcons />;
    }, []);

    const hintRenderer = (value: PointOfCareId): string => {
        const item = isNullOrUndefined(value) ? null : props._dependencies.organizationReferenceDataStore.allPointsOfCareMap.get(value);

        if (isNullOrUndefined(item)) {
            return null;
        }

        return `${item.shorthand} - ${item.name}`;
    };

    const getOrderableValue = (value: PointOfCareId) => {
        const item = isNullOrUndefined(value) ? null : props._dependencies.organizationReferenceDataStore.allPointsOfCareMap.get(value);

        if (isNullOrUndefined(item)) {
            return null;
        }

        return item.name;
    };

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            onRenderHintValue={hintRenderer}
            filterValueSerializer={filterValueSerializer}
            clientSideOrderableValueGetter={getOrderableValue}
        />
    );
};

PointOfCareColumn.defaultProps = {
    displayMode: "shorthand"
};

export default connect(
    PointOfCareColumn,
    new DependencyAdapter<IPointOfCareColumnProps, IPointOfCareColumnDependencies>(c => ({
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
    }))
);
