/* auto-inject-disable */
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import { GetTypeNameWithPrefix } from "@HunSocialSecurityPlugin/PluginHelper";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/Proxy.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import _ from "@HisPlatform/Common/Lodash";
import EuPerformanceStatementStoreMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/EuPerformanceStatementStoreMapper";
import EuPerformanceStatementDtoMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/EuPerformanceStatementDtoMapper";
import { IEuReportFilter } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/EuReportPanel/IEuReportFilter";
import EuPerformanceStatement from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/EuPerformanceStatement";
import EuPerformanceStatementId from "@Primitives/EuPerformanceStatementId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import CareActivityId from "@Primitives/CareActivityId.g";
import IEuPerformanceStatementListItem from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/IEuPerformanceStatementListItem";
import EuErrorListStoreMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/EuErrorListStoreMapper";
import EuErrorListDtoMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/EuErrorListDtoMapper";
import EuErrorList from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/EuErrorList";

@Di.injectable()
export default class EuPerformanceStatementApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("IPerformanceStatementClient")) private readonly apiClient: Proxy.IPerformanceStatementClient,
        @Di.inject("EuPerformanceStatementStoreMapper") private readonly euPerformanceStatementStoreMapper: EuPerformanceStatementStoreMapper,
        @Di.inject("EuPerformanceStatementDtoMapper") private readonly euPerformanceStatementDtoMapper: EuPerformanceStatementDtoMapper,
        @Di.inject("EuErrorListStoreMapper") private readonly euErrorListStoreMapper: EuErrorListStoreMapper,
        @Di.inject("EuErrorListDtoMapper") private readonly euErrorListDtoMapper: EuErrorListDtoMapper
    ) {
        super();
    }

    @State.bound
    public createEuPerformanceStatement(filter: IEuReportFilter) {
        const dto = this.euPerformanceStatementDtoMapper.getCreateEuPerformanceReportDto(filter);
        return this.processOperationAsync(
            new EuPerformanceStatement(),
            async target => {
                const response = await this.apiClient.createEuPerformanceStatementCommandAsync(
                    CreateRequestId(),
                    dto
                );

                this.euPerformanceStatementStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public hasFinalizedEuPerformanceStatementForMonthAsync(filter: IEuReportFilter): Promise<SimpleStore<boolean>> {
        const dto = this.euPerformanceStatementDtoMapper.hasFinalizedEuPerformanceStatementForMonthDto(filter);
        return this.processOperationAsync(
            new SimpleStore<boolean>(),
            async target => {
                const response = await this.apiClient.hasFinalizedEuPerformanceStatementForMonthQueryAsync(
                    CreateRequestId(),
                    dto
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.hasFinalizedEuPerformanceStatement;
            }
        );
    }

    @State.bound
    public getEuPerformanceStatementByIdAsync(id: EuPerformanceStatementId) {
        return this.processOperationAsync(
            new EuPerformanceStatement(),
            async target => {
                const response = await this.apiClient.getEuPerformanceStatementByIdQueryAsync(
                    CreateRequestId(),
                    id.value
                );

                this.euPerformanceStatementStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public setTransactionCodeAsync(id: EuPerformanceStatementId, rowVersion: RowVersion, transactionCode: string, isValidateOnly: boolean = false) {
        return this.processOperationAsync(
            new EuPerformanceStatement(),
            async target => {
                const response = await this.apiClient.setEuPerformanceStatementTransactionCodeCommandAsync(
                    CreateRequestId(),
                    new Proxy.SetEuPerformanceStatementTransactionCodeControllerDto({
                        euPerformanceStatementId: id,
                        rowVersion: rowVersion,
                        transactionCode: transactionCode,
                        isValidateOnly: isValidateOnly
                    })
                );

                this.euPerformanceStatementStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public getPerformanceStatementsByCareActivityIdAsync(careActivityId: CareActivityId) {
        return this.processOperationAsync(
            new SimpleStore<IEuPerformanceStatementListItem[]>(),
            async target => {
                const response = await this.apiClient.getEuPerformanceStatementsByCareActivityIdQueryAsync(
                    CreateRequestId(),
                    careActivityId.value
                );

                target.value = response.euPerformanceStatements.map(this.mapEuPerformanceStatementListItem);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public setEuPerformanceStatementErrorListAsync(euPerformanceStatement: EuPerformanceStatement, fileName: string, binaryFile: Uint8Array) {
        return this.processOperationAsync(
            new EuPerformanceStatement(),
            async target => {
                const response = await this.apiClient.setEuPerformanceStatementErrorListCommandAsync(
                    CreateRequestId(),
                    new Proxy.SetEuPerformanceStatementErrorListControllerDto({
                        euPerformanceStatementId: euPerformanceStatement.id,
                        fileName: fileName,
                        errorList: Base64Converter.fromByteArray(binaryFile),
                        rowVersion: euPerformanceStatement.rowVersion
                    })
                );

                this.euPerformanceStatementStoreMapper.applyToStore(target, response);
            }
        );
    }
    
    @State.bound
    public getEuErrorListById(euPerformanceStatementId: EuPerformanceStatementId) {
        return this.processOperationAsync(
            new EuErrorList(),
            async target => {
                const response = await this.apiClient.getEuErrorListByIdQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetEuErrorListByIdControllerDto({
                        id: euPerformanceStatementId
                    })
                );

                this.euErrorListStoreMapper.applyToStore(target, response.euErrorListDto);

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    private mapEuPerformanceStatementListItem(i: Proxy.EuPerformanceStatementListItemDto): IEuPerformanceStatementListItem {
        return {
            ...i,
            coveredPeriod: i.coveredPeriod,
            cardValue: i.cardValue,
            cardExpiration: i.cardExpiration
        } as IEuPerformanceStatementListItem;
    }
}