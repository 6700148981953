import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as HisUi from "@HisPlatformControls";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import TherapiesApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/TherapiesApiAdapter";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import IEhiMedicalConditionReferenceDataResolver from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Services/Definition/EhiMedicalConditionDataResolver/IEhiMedicalConditionReferenceDataResolver";
import { TherapyCodeSelectorItem } from "./TherapyCodeSelectorItem";
import ITherapy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/ITherapy";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";

interface ITherapyCodeSelectorDependencies {
    therapiesApiAdapter: TherapiesApiAdapter;
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    ehiMedicalConditionReferenceDataResolver: IEhiMedicalConditionReferenceDataResolver;
}

interface ITherapyCodeSelectorProps extends ICodeSelectorCommonProps<TherapyCodeSelectorItem> {
    _dependencies?: ITherapyCodeSelectorDependencies;
}

@State.observer
class TherapyCodeSelector extends React.Component<ITherapyCodeSelectorProps> {

    private get dependencies() { return this.props._dependencies; }
    private get apiAdapter() { return this.dependencies.therapiesApiAdapter; }
    private get referenceDataStore() { return this.dependencies.hunEhiCareReferenceDataStore.therapies; }
    private get ehiMedicalConditionReferenceDataResolver() { return this.dependencies.ehiMedicalConditionReferenceDataResolver; }

    @State.bound
    public async getDisplayValueAsync(value: TherapyCodeSelectorItem) {
        return await this.ehiMedicalConditionReferenceDataResolver.getTherapyNameAsync(value);
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const results = await this.apiAdapter.searchTherapies(
            text,
            10
        );

        if (results.operationWasSuccessful) {
            const therapies = results.value.map(x => {
                const textOfQuickSearch = `${x.name} (${x.code})`;
                if (x.id) {
                    return new TherapyCodeSelectorItem({
                        id: x.id,
                        text: textOfQuickSearch
                    });
                }
                return null;
            });

            await this.referenceDataStore.ensureLoadedAsync(therapies.map(x => x.id));
            return therapies;
        }
        return null;
    }

    @State.bound
    private addNewItem(text: string): TherapyCodeSelectorItem {
        return new TherapyCodeSelectorItem({ text: text });
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState) {
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;

        const results = await this.apiAdapter.complexSearchTherapies(
            filterText,
            customOrdering,
            paging);

        await this.referenceDataStore.ensureLoadedAsync(results.value.items.map(value => value.id));

        return results.value;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: TherapyCodeSelectorItem) {
        this.props.onChange({ id: item.id });
    }

    @State.bound
    private onComplexSearchMultiSelect(items: ITherapy[]) {
        this.props.onChange(items.map(item => ({ id: item.id, text: item.code })));
    }
    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticHunEHealthInfrastructureCareResources.MedicalCondition.Therapies.Title,
            codeGetter: "code",
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect,
            hideAllgroup: true
        };
        return (
            <HisUi.UniversalCodeSelector
                {...props}
                isCreatable
                addNewItem={this.addNewItem}
            />
        );
    }
}

export default connect(
    TherapyCodeSelector,
    new DependencyAdapter<ITherapyCodeSelectorProps, ITherapyCodeSelectorDependencies>((container) => {
        return {
            therapiesApiAdapter: container.resolve("TherapiesApiAdapter"),
            hunEhiCareReferenceDataStore: container.resolve("HunEhiCareReferenceDataStore"),
            ehiMedicalConditionReferenceDataResolver: container.resolve("IEhiMedicalConditionReferenceDataResolver"),
        };
    })
);
