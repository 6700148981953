import ConditionId from "@Primitives/ConditionId.g";
import ContagiousPatientReportId from "@Primitives/ContagiousPatientReportId.g";
import ContagiousPatientReportOutcomeTypeId from "@Primitives/ContagiousPatientReportOutcomeTypeId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class EndReportData {
    public contagiousPatientOnsetReportId: ContagiousPatientReportId;
    @State.observable.ref public endDate: LocalDate;
    @State.observable public endDiagnosisId: ConditionId;
    @State.observable public outcomeTypeId: ContagiousPatientReportOutcomeTypeId;

    @State.action.bound
    public setEndDate(value: LocalDate) {
        this.endDate = value;
    }

    @State.action.bound
    public setEndDiagnosisId(value: ConditionId) {
        this.endDiagnosisId = value;
    }

    @State.action.bound
    public setOutcomeTypeId(value: ContagiousPatientReportOutcomeTypeId) {
        this.outcomeTypeId = value;
    }
}