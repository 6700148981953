import CareActivityId from "@Primitives/CareActivityId.g";
import DischargeReasonId from "@Primitives/DischargeReasonId.g";
import moment from "moment";
import ICareActivityDischargeDataStore from "@PluginInterface/BoundedContexts/Care/CareRegister/CareActivityDischargeData/ICareActivityDischargeDataStore";
import CloneableLockingEntityStoreBase from "@Toolkit/CommonWeb/Model/CloneableLockingEntityStoreBase";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IForwardingNeededAtDischarge from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IForwardingNeededAtDischarge";
import PossibleAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PossibleAction";

export default class CareActivityDischargeDataStore
    extends CloneableLockingEntityStoreBase<CareActivityDischargeDataStore, CareActivityId>
    implements ICareActivityDischargeDataStore {

    @State.observable.ref public dischargeReasonId: DischargeReasonId = null;
    @State.observable.ref public dischargedAt: moment.Moment = null;
    @State.observable.ref public receivingInstitute: EntityVersionSelector<ExternalLocationId> = new EntityVersionSelector<ExternalLocationId>(null, null);
    @State.observable.ref public extensionData: object = {};
    @State.observable.ref public possibleAction: PossibleAction[] = [];

    constructor(private forwardingNeededAtDischargeReferenceMap = new Map<string, IForwardingNeededAtDischarge>()) {
        super();
    }

    private selectedDischargeReasonIdIsNullOrIsForwarding(value: DischargeReasonId) {
        return ValueWrapper.equals(value, DischargeReasonId.MoveToAnotherInstitute);
    }

    @State.action.bound
    public setDischargeReasonId(newValue: DischargeReasonId) {
        if (newValue === null) {
            this.receivingInstitute = this.receivingInstitute.withId(null);
        }
        if (!this.selectedDischargeReasonIdIsNullOrIsForwarding(newValue)) {
            this.receivingInstitute = this.receivingInstitute.withId(null);
        }
        this.dischargeReasonId = newValue;
    }

    @State.action.bound
    public setDischargedAt(newValue: moment.Moment) {
        this.receivingInstitute = this.receivingInstitute.withValidOn(LocalDate.createFromMoment(newValue));
        this.dischargedAt = newValue;
    }

    @State.action.bound
    public setReceivingInstitute(newValue: ExternalLocationId) {
        this.receivingInstitute = this.receivingInstitute.withId(newValue);
    }

    @State.computed
    public get isForwardingValueSelected(): boolean {
        if (isNullOrUndefined(this.dischargeReasonId)) {
            return false;
        }

        if (isNullOrUndefined(this.forwardingNeededAtDischargeReferenceMap)) {
            return false;
        }

        const forwardingData = this.forwardingNeededAtDischargeReferenceMap.get(this.dischargeReasonId.value);

        if (isNullOrUndefined(forwardingData)) {
            return false;
        } else {
            return forwardingData.isForwardingNeeded;
        }
    }

    @State.action.bound
    public setExtensionData(key: string, newValue: any) {
        this.extensionData = {
            ...this.extensionData,
            [key]: newValue
        };
    }
}
