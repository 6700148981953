import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MasterDetailMaster from "@CommonControls/Layout/MasterDetailMaster";
import { DataGrid } from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { useWorklistDefinitionMasterDetailPanelStore } from "./WorklistDefinitionMasterDetailPanelStoreProvider";
import IWorklistDefinitionListItem from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/WorklistDefinition/IWorklistDefinitionBaseInformation";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";

/** @screen */
function WorklistDefinitionList() {

    const store = useWorklistDefinitionMasterDetailPanelStore();

    return (
        <MasterDetailMaster
            title={StaticProductivityResources.Worklist.Definition.EditorTitle}
            passStateToChild={false}
        >
            <DataGrid
                dataSource={store.listItems}
                fixedHeight="100%"
                onRowClick={store.selectListItem}
                selectedRow={store.selectedListItem}
                automationId="worklistDefinitionList_dataGrid"
            >
                <DataGridColumn header={StaticProductivityResources.Worklist.Definition.Columns.Name}
                    dataGetter={"name"} />
                <DataGridColumn header={StaticProductivityResources.Worklist.Definition.Columns.OrganizationUnitCodes}
                    dataGetter={"organizationUnitCodes"}
                    isVisible={store.selectedDetail === null} />
            </DataGrid>
        </MasterDetailMaster>
    );
}

export default State.observer(WorklistDefinitionList);