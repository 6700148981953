import Dosage from "./Dosage";
import DosageInterval from "./DosageInterval";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export default class FrequencyBasedDosage extends Dosage {
    @State.observable public interval: DosageInterval = DosageInterval.Daily;
    @State.observable public frequency: number = 0;
    @State.observable public amount: number = 0;
    @State.observable public intervalMultiplier: number = 1;
    
    @State.action.bound
    public setInterval(interval: DosageInterval) {
        this.interval = interval;
        this.setDailyAmountBasedOnData();
    }

    @State.action.bound
    public setAmount(amount: number) {
        this.amount = amount;
        this.setDailyAmountBasedOnData();
    }

    @State.action.bound
    public setFrequency(frequency: number) {
        this.frequency = frequency;
        this.setDailyAmountBasedOnData();
    }

    @State.action.bound
    public setIntervalMultiplier(intervalMultiplier: number) {
        if (intervalMultiplier === 0) {
            this.intervalMultiplier = 1;
        } else {
            this.intervalMultiplier = intervalMultiplier;
        }
        this.setDailyAmountBasedOnData();
        
    }

    @State.bound
    private setDailyAmountBasedOnData() {
        this.setDailyAmount(this.interval === DosageInterval.Daily
            ? (this.frequency * this.amount) / this.intervalMultiplier
            : (this.frequency * this.amount) / (7 * this.intervalMultiplier) // weekly
        );
    }
}