import React, { useMemo } from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import EhiEvent from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEvent";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import * as Ui from "@CommonControls";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import IPropertyListItem from "@CommonControls/PropertyList/IPropertyListItem";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiErrorSummary from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiErrorSummary";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import formatCodeName from "@HisPlatformControls/CodeNameFormatter/CodeNameFormatter";

interface IEhiCareActivityBaseDataPanelViewDependencies {
    ehiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    careReferenceDataStore: CareReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    localizationService: ILocalizationService;
}

interface IEhiCareActivityBaseDataPanelViewProps {
    _dependencies?: IEhiCareActivityBaseDataPanelViewDependencies;
    event: EhiEvent;
    patientName: string;
    errors: EhiError[];
    reloadAsync: (event: any) => Promise<void>;
}


const EhiCareActivityBaseDataPanelView: React.FC<IEhiCareActivityBaseDataPanelViewProps> = props => {

    const ehiCareReferenceDataStore = props._dependencies.ehiCareReferenceDataStore;
    const careReferenceDataStore = props._dependencies.careReferenceDataStore;
    const organizationReferenceDataStore = props._dependencies.organizationReferenceDataStore;

    const ehiCareType = ehiCareReferenceDataStore.ehiCareTypes.get(props.event?.ehiCareTypeId);
    const ehiEventType = ehiCareReferenceDataStore.ehiEventTypes.get(props.event?.ehiEventTypeId);
    const ehiDischargeReason = ehiCareReferenceDataStore.ehiDischargeReasons.get(props.event?.ehiDischargeReasonId);
    const ehiPatientIdentifierType = ehiCareReferenceDataStore.ehiPatientIdentifierTypes.get(props.event?.patientIdentifierTypeId);

    const validOn = LocalDate.createFromMoment(props.event?.beginningOfCare) || LocalDate.today();

    const referralDiagnosis = props.event?.referralDiagnosisId && careReferenceDataStore.condition.get({ id: props.event.referralDiagnosisId, validOn: validOn });
    const referralWorkplace = props.event?.referralWorkplaceId && ehiCareReferenceDataStore.ehiHealthcareProviderOrganizationUnits.get(props.event.referralWorkplaceId);
    const careOrganizationUnit = props.event?.careOrganizationUnitId && ehiCareReferenceDataStore.ehiHealthcareProviderOrganizationUnits.get(props.event.careOrganizationUnitId);
    const careDoctor = organizationReferenceDataStore.doctorMap.get(props.event?.careDoctorId);
    const referralDoctor = organizationReferenceDataStore.doctorMap.get(props.event?.referralDoctorId);
    const mainDiagnosisJustifyingNursing = props.event?.mainDiagnosisJustifyingNursingId && careReferenceDataStore.condition.get({id : props.event.mainDiagnosisJustifyingNursingId, validOn: validOn});

    const ehiInpatientAdmissionType = ehiCareReferenceDataStore.ehiInpatientAdmissionTypes.get(props.event?.ehiInpatientAdmissionTypeId);
    const ehiInpatientAdmissionClassification = ehiCareReferenceDataStore.ehiInpatientAdmissionClassifications.get(props.event?.ehiInpatientAdmissionClassificationId);
    const ehiPatientFurtherTreatment = ehiCareReferenceDataStore.ehiPatientFurtherTreatments.get(props.event?.ehiPatientFurtherTreatmentId);
    const ehiEmergencyLevelClaimType = ehiCareReferenceDataStore.ehiEmergencyLevelClaimTypes.get(props.event?.ehiEmergencyLevelClaimTypeId);
    const ehiEmergencyExaminationClaimType = ehiCareReferenceDataStore.ehiEmergencyExaminationClaimTypes.get(props.event?.ehiEmergencyExaminationClaimTypeId);
    const ehiEReferralServiceType = props.event?.ehiEReferralServiceTypeId && ehiCareReferenceDataStore.ehiEReferralServiceTypes.get({ id: props.event.ehiEReferralServiceTypeId, validOn: validOn });
    const rescueReceivingInstitute = props.event?.rescueReceivingInstituteId && ehiCareReferenceDataStore.ehiHealthcareProviders.get({ id: props.event.rescueReceivingInstituteId, validOn: validOn });

    let claimTypeValue: JSX.Element = null;

    if (ehiEmergencyLevelClaimType) {
        claimTypeValue = ehiEmergencyLevelClaimType && formatCodeName(ehiEmergencyLevelClaimType.displayValue.Shorthand, ehiEmergencyLevelClaimType.displayValue.Name);
    } else if (ehiEmergencyExaminationClaimType) {
        claimTypeValue = ehiEmergencyExaminationClaimType && formatCodeName(ehiEmergencyExaminationClaimType.displayValue.Shorthand, ehiEmergencyExaminationClaimType.displayValue.Name);
    }

    const patientProperties = useMemo(() => {
        if (isNullOrUndefined(props.event)) {
            return null;
        }
        return [
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.PatientName,
                value: props.patientName,
                automationId: "patientName"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.PatientIdentifier,
                value: props.event.patientIdentifier,
                automationId: "patientIdentifier"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.PatientIdentifierType,
                value: ehiPatientIdentifierType && formatCodeName(ehiPatientIdentifierType.displayValue.Shorthand, ehiPatientIdentifierType.displayValue.Name),
                automationId: "patientIdentifierType"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.PatientAgeInYears,
                value: props.event.patientAgeInYears,
                automationId: "patientAgeInYears"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.PatientZipCode,
                value: props.event.patientZipCode,
                automationId: "patientZipCode"
            }
        ] as IPropertyListItem[];
    }, [props.patientName, props.event, ehiPatientIdentifierType]);

    const careProperties = useMemo(() => {
        if (isNullOrUndefined(props.event)) {
            return null;
        }
        return [
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.EhiCareType,
                value: ehiCareType && ehiCareType.name,
                automationId: "ehiCareType"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.EhiEventType,
                value: ehiEventType && formatCodeName(ehiEventType.displayValue.Shorthand, ehiEventType.displayValue.Name),
                automationId: "ehiEventType"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.EhiDischargeReason,
                value: ehiDischargeReason && formatCodeName(ehiDischargeReason.displayValue.Shorthand, ehiDischargeReason.displayValue.Name),
                automationId: "ehiDischargeReason"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.EhiInpatientAdmissionType,
                value: ehiInpatientAdmissionType && formatCodeName(ehiInpatientAdmissionType.displayValue.Shorthand, ehiInpatientAdmissionType.displayValue.Name),
                automationId: "ehiInpatientAdmissionType"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.EhiInpatientAdmissionClassification,
                value: ehiInpatientAdmissionClassification && formatCodeName(ehiInpatientAdmissionClassification.displayValue.Shorthand, ehiInpatientAdmissionClassification.displayValue.Name),
                automationId: "ehiInpatientAdmissionClassification"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.EhiPatientFurtherTreatment,
                value: ehiPatientFurtherTreatment && formatCodeName(ehiPatientFurtherTreatment.displayValue.Shorthand, ehiPatientFurtherTreatment.displayValue.Name),
                automationId: "ehiPatientFurtherTreatment"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.EhiClaimType,
                value: claimTypeValue,
                automationId: "ehiClaimType"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.BeginningOfCare,
                value: props._dependencies.localizationService.localizeDateTime(props.event.beginningOfCare, true, true, false),
                automationId: "beginningOfCare"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.EndOfCare,
                value: props._dependencies.localizationService.localizeDateTime(props.event.endOfCare, true, true, false),
                automationId: "endOfCare"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.CareOrganizationUnit,
                value: (careOrganizationUnit && formatCodeName(careOrganizationUnit.code, careOrganizationUnit.name)) || props.event.careOrganizationUnitCode,
                automationId: "careOrganizationUnit"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.EhiEReferralServiceType,
                value: ehiEReferralServiceType && formatCodeName(ehiEReferralServiceType.code, ehiEReferralServiceType.name),
                automationId: "ehiEReferralServiceType"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.CareDoctor,
                value: (careDoctor && formatCodeName(careDoctor.code, props._dependencies.localizationService.localizePersonName(careDoctor.name))) || props.event.careDoctorCode,
                automationId: "careDoctor"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.CareIdentifier,
                value: props.event.careIdentifier,
                automationId: "careIdentifier"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.RegistrationNumber,
                value: props.event.registrationNumber,
                automationId: "registrationNumber"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.NursingNumber,
                value: props.event.nursingNumber,
                automationId: "nursingNumber"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.MainDiagnosisJustifyingNursing,
                value: (mainDiagnosisJustifyingNursing && formatCodeName(mainDiagnosisJustifyingNursing.code, mainDiagnosisJustifyingNursing.name)) || props.event.mainDiagnosisJustifyingNursing,
                automationId: "mainDiagnosisJustifyingNursing"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.RescuePickUpPlace,
                value: props.event.rescuePickUpPlace,
                automationId: "rescuePickUpPlace"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.RescueHandoverPlace,
                value: props.event.rescueHandoverPlace,
                automationId: "rescueHandoverPlace"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.RescueDataSheetNumber,
                value: props.event.rescueDataSheetNumber,
                automationId: "rescueDataSheetNumber"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.RescueReceivingInstitute,
                value: (rescueReceivingInstitute && formatCodeName(rescueReceivingInstitute.code, rescueReceivingInstitute.name)) || props.event.rescueReceivingInstituteCode,
                automationId: "rescueReceivingInstitute"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.EhiCareIdentifier,
                value: props.event.ehiId,
                automationId: "ehiId"
            }
        ] as IPropertyListItem[];
    }, [props.event,
        ehiEventType,
        ehiCareType,
        ehiDischargeReason,
        ehiInpatientAdmissionType,
        ehiInpatientAdmissionClassification,
        ehiPatientFurtherTreatment,
        claimTypeValue,
        careOrganizationUnit,
        ehiEReferralServiceType,
        careDoctor]);

    const referralProperties = useMemo(() => {
        if (isNullOrUndefined(props.event)) {
            return null;
        }
        return [
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.ReferralWorkplace,
                value: (referralWorkplace && formatCodeName(referralWorkplace.code, referralWorkplace.name)) || props.event.referralWorkplaceCode,
                automationId: "referralWorkplace"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.ReferralDoctor,
                value: (referralDoctor && formatCodeName(referralDoctor.code, props._dependencies.localizationService.localizePersonName(referralDoctor.name))) || props.event.referralDoctorCode,
                automationId: "referralDoctor"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.ReferralDiagnosis,
                value: (referralDiagnosis && formatCodeName(referralDiagnosis.code, referralDiagnosis.name)) || props.event.referralDiagnosisCode,
                automationId: "referralDiagnosis"
            },
            {
                name: StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.EReferralIdentifier,
                value: props.event.referralCareIdentifier,
                automationId: "eReferralIdentifier"
            }
        ] as IPropertyListItem[];
    }, [props.event, referralWorkplace, referralDoctor, referralDiagnosis]);

    const versionTooltip = useMemo(() => {
        if (isNullOrUndefined(props.event)) {
            return "";
        }
        const detail = [];
        detail.push(`${StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.Version}: <b>${props.event.version}</b>`);
        detail.push(`${StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.CreatedAt}: <b>${props._dependencies.localizationService.localizeDateTime(props.event.createdAt, true, true, false)}</b>`);
        detail.push(`${StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.CreatedBy}: <b>${props.event.createdBy}</b>`);
        detail.push(`${StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.LastModifiedAt}: <b>${props._dependencies.localizationService.localizeDateTime(props.event.lastModifiedAt, true, true, false)}</b>`);
        detail.push(`${StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.LastModifiedBy}: <b>${props.event.lastModifiedBy}</b>`);
        return detail.join("<br>");
    }, [props.event]);

    const version = useMemo(() => {
        if (isNullOrUndefined(props.event)) {
            return "";
        }
        return `${StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.Version} ${props.event.version} (${StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.LastModifiedAt} ${props._dependencies.localizationService.localizeDateTime(props.event.lastModifiedAt, true, true, false)})`;
    
    }, [props.event]);

    return (
        <Ui.ScrollView height="calc(100vh - 170px)">
            <EhiErrorSummary errors={props.errors} />
            {!props.event && <Ui.Button automationId={"reloadButton"} text={StaticWebAppResources.Common.Button.Reload} iconName="sync" onClickAsync={props.reloadAsync} />}
            {props.event && (
                <ReadOnlyContext.Provider value>
                    <Ui.PropertyList
                        properties={patientProperties}
                        title={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.PatientTitle}
                        nameWidth={180}
                        hasColonAfterNames
                        hideIfEmpty
                        hidePropertyIfNoValue />
                    <Ui.PropertyList
                        properties={careProperties}
                        title={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.CareTitle}
                        nameWidth={180}
                        hasColonAfterNames
                        hideIfEmpty
                        hidePropertyIfNoValue />
                    <Ui.PropertyList
                        properties={referralProperties}
                        title={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Labels.ReferralTitle}
                        nameWidth={180}
                        hasColonAfterNames
                        hideIfEmpty
                        hidePropertyIfNoValue />

                    <Ui.Flex xsJustify="end">
                        <Ui.Message tooltipTextAlign="left" tooltipContent={versionTooltip} tooltipPosition="top" type="none">
                            {version}
                        </Ui.Message>                            
                    </Ui.Flex>
                </ReadOnlyContext.Provider>
            )}
        </Ui.ScrollView>
    );
};

export default connect(
    EhiCareActivityBaseDataPanelView,
    new DependencyAdapter<IEhiCareActivityBaseDataPanelViewProps, IEhiCareActivityBaseDataPanelViewDependencies>(container => {
        return {
            ehiCareReferenceDataStore: container.resolve("HunEhiCareReferenceDataStore"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            localizationService: container.resolve("ILocalizationService"),
        };
    })
);