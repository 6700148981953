import State from "@Toolkit/ReactClient/Common/StateManaging";
import StatementSubjectDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/StatementSubjectDetail";
import TreatmentDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/TreatmentDetail";
import MedicalConditionHistoryItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemBase";

export default class MedicationStatementHistoryItem extends MedicalConditionHistoryItemBase {    
    @State.observable.ref public statementSubjectDetail: StatementSubjectDetail;
    @State.observable.ref public treatmentDetail: TreatmentDetail;
    
    @State.action.bound
    public setStatementSubjectDetail(newValue: StatementSubjectDetail) {
        this.statementSubjectDetail = newValue;
    }

    @State.action.bound
    public setTreatmentDetail(newValue: TreatmentDetail) {
        this.treatmentDetail = newValue;
    }
}
