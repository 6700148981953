import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiConditionCovidStatus from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiConditionCovidStatus";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import * as Styles from "./EhiConditionCovidStatusColumn.less";

interface IEhiConditionCovidStatusColumnDependencies {
    localizationService: ILocalizationService;
}

interface IEhiConditionCovidStatusColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IEhiConditionCovidStatusColumnDependencies;
}


const EhiConditionCovidStatusColumn: React.FC<IEhiConditionCovidStatusColumnProps> = (props: IEhiConditionCovidStatusColumnProps) => {

    const valueRenderer = (value: EhiConditionCovidStatus) => {
        if (!isNullOrUndefined(value)) {
            return (
                <>
                    <SpanWithIcon iconName="covid" className={value === EhiConditionCovidStatus.Positive ? Styles.positiveTestResult : (value === EhiConditionCovidStatus.Negative ? Styles.negativeTestResult : undefined)}>
                        {props._dependencies!.localizationService.localizeEnum(EhiConditionCovidStatus[value], "EhiConditionCovidStatus").Name}
                    </SpanWithIcon>
                </>
            );
        }
        return "";
    };

    return (
        <DataGridColumn
            onRenderCellValue={valueRenderer}
            {...props}
        />
    );
};

export default connect(
    EhiConditionCovidStatusColumn,
    new DependencyAdapter<IEhiConditionCovidStatusColumnProps, IEhiConditionCovidStatusColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService")
    }))
);