import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import SubstanceId from "@Primitives/SubstanceId.g";

interface ISubstancesColumnDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface ISubstancesColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: ISubstancesColumnDependencies;
    validOn?: LocalDate;
}


const SubstancesColumn: React.SFC<ISubstancesColumnProps> = (props: ISubstancesColumnProps) => {

    const valueRenderer = (value: any, row: any, rowId: React.ReactText, rowIndex: number, isUnderEditing: boolean, dataSetter: (newValue: any) => void) => {
        if (value) {
            const validOn = props.validOn || LocalDate.today();
            if (Array.isArray(value)) {
                const substanceNames = value?.map(i => props._dependencies.careReferenceDataStore.substances.get({id: i as SubstanceId, validOn: validOn}).name);
                return substanceNames.join(", ");
            }
            const substance = props._dependencies.careReferenceDataStore.substances.get({id: value, validOn: validOn});
            return substance.name;
        }
        return "";
    };

    return (
        <DataGridColumn
            header={"Substances"}
            onRenderCellValue={valueRenderer}
            {...props}
            hideOverflow
        />
    );
};

export default connect(
    SubstancesColumn,
    new DependencyAdapter<ISubstancesColumnProps, ISubstancesColumnDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore")
    }))
);