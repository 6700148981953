import {ValueConverter} from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ValueConverter";
import PatientName from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/PatientName";
import NameStore from "@Primitives/NameStore";
import GenderId from "@Primitives/GenderId.g";

export default {
    PatientNameDisplayDto: rawValue => new PatientName(NameStore.create({
            familyName: rawValue.Name.FamilyName,
            givenName1: rawValue.Name.GivenName1,
            givenName2: rawValue.Name.GivenName2,
            postfix: rawValue.Name.Postfix,
            prefix: rawValue.Name.Prefix
        }),
        rawValue.IsDead,
        !!rawValue.GenderId ? new GenderId(rawValue.GenderId.Value) : null,
        rawValue.IsUnregistered,
        rawValue.IsDataCleansingNeeded)
} as ValueConverter;
