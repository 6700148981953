import IOperationInfo from "./IOperationInfo";
import RequestStatus from "./RequestStatus";
import IBusinessError from "@Toolkit/CommonWeb/Model/IBusinessError";

export default class QueryOperationInfo implements IOperationInfo {
    public get requestStatus(): RequestStatus { return RequestStatus.Success; }
    public get hasTechnicalError(): boolean { return false; }
    public get businessError(): IBusinessError { return null; }
    public get isPersisted(): boolean { return true; }

    constructor(
        public readonly operationName?: string,
        public readonly operationId?: string
    ) {}
} 