import DependencyContainer from "@DiContainer";
import * as UserManagementProxy from "@HisPlatform/BoundedContexts/UserManagement/Api/Proxy.g";

DependencyContainer
    .bind("IProfilesClient")
    .to(UserManagementProxy.ProfilesClient);

DependencyContainer
    .bind("IUsersClient")
    .to(UserManagementProxy.UsersClient);

DependencyContainer
    .bind("IGroupsClient")
    .to(UserManagementProxy.GroupsClient);
