import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

export default class PractitionerRecommendationFilterStore {
    @State.observable.ref public dateRangeFilter: LocalDateRange = null;
    @State.observable public includeDeleted: boolean = false;
    @State.observable public includeExpired: boolean = false;
    @State.observable.ref public pointOfCareIds: PointOfCareId[] = [];

    constructor(private onFilterChanged: () => void) {
    }

    private onChangeDebounced = _.debounce(this.onFilterChanged, 2000);
    
    @State.action.bound
    public setIncludeDeleted(includeDeleted: boolean) {
        this.includeDeleted = includeDeleted;
        this.onChangeDebounced();
    }

    @State.action.bound
    public setDateRangeFilter(dateRangeFilter: LocalDateRange) {
        this.dateRangeFilter = dateRangeFilter;
        this.onChangeDebounced();
    }

    @State.action.bound
    public setIncludeExpired(includeExpired: boolean) {
        this.includeExpired = includeExpired;
        this.onChangeDebounced();
    }

    @State.action.bound
    public setPointOfCareIds(pointOfCareId: PointOfCareId, isChecked: boolean) {
        if (isChecked) {
            if (!this.pointOfCareIds?.some(i => ValueWrapper.equals(i, pointOfCareId))) {
                this.pointOfCareIds.push(pointOfCareId);
                this.onChangeDebounced();
            }
        } else {
            if (this.pointOfCareIds?.some(i => ValueWrapper.equals(i, pointOfCareId))) {
                this.pointOfCareIds = this.pointOfCareIds.filter(i => !ValueWrapper.equals(i, pointOfCareId));
                this.onChangeDebounced();
            }
        }
    }
}