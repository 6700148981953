import React from "react";
import * as Ui from "@CommonControls";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import OrganizationSelector from "@HisPlatform/BoundedContexts/Organization/Components/OrganizationSelector";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import StaticHunSocialSecurityPerformanceStatementResources from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/StaticResources/StaticHunSocialSecurityPerformanceStatementResources";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";

interface IEuReportPanelViewProps {
    pointOfCares: PointOfCareId[];
    onPointOfCareChange: (newValue: PointOfCareId, checkedValue: boolean) => void;
    reportValidityDate: YearMonth;
    onReportValidityDateChange: (newValue: YearMonth) => void;
    containsOnlyClosedCareActivities: boolean;
    onContainsOnlyClosedCareActivitiesChange: (newValue: boolean) => void;

    onCreateAsync: () => Promise<void>;
    onBack: () => void;
}


const EuReportPanelView: React.FC<IEuReportPanelViewProps> = (props) => {
    const resources = StaticHunSocialSecurityPerformanceStatementResources.EuReportPanel;
    return (
        <SingleLayout>
            <Ui.PageBox2>
                <Ui.PageBox2.Header title={resources.Title}
                    toolbar={(
                        <>
                            <Ui.Button visualStyle="primary" text={resources.Create} onClickAsync={props.onCreateAsync} automationId="createButton" />
                            <Ui.Button text={StaticWebAppResources.Common.Button.Back} onClick={props.onBack} automationId="backButton" />
                        </>
                    )} />
                <Ui.PageBox2.Body>
                    <Ui.Flex spacing="none" innerSpacing="none" outerSpacing="none" verticalSpacing="none">
                        <Ui.Flex.Item xs={4}>
                            <Ui.MonthPicker
                                label={resources.Period}
                                value={props.reportValidityDate}
                                onChange={props.onReportValidityDateChange}
                                automationId="periodPicker" />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4} style={{ paddingTop: 19 }}>
                            <Ui.CheckBox
                                value={props.containsOnlyClosedCareActivities}
                                onChange={props.onContainsOnlyClosedCareActivitiesChange}
                                label={resources.ContainsOnlyClosedCareActivities}
                                automationId="containsOnlyClosedCareActivitiesCheckBox"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={12} style={{ paddingTop: 10 }}>
                            <OrganizationSelector
                                value={props.pointOfCares}
                                onChange={props.onPointOfCareChange}
                                label={resources.ReportableInstitute}
                                filteringPropertyGroup={"HunMandatoryReportsSettings"}
                                filteringPropertyName={"PerformanceStatementEnabled"}
                                filteringPropertyValue={true}
                                isOpen
                                pointOfCareMode={true}
                                automationId="organization"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.PageBox2.Body>
            </Ui.PageBox2>
        </SingleLayout>
    );
};

export default EuReportPanelView;