import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ConfigurationDynamicPropertiesApiAdapter from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/ApiAdapter/DynamicProperties/ConfigurationDynamicPropertiesApiAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PropertyGroup from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/PropertyGroup";
import PropertyGroupGrid from "./PropertyGroupGrid";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import * as Ui from "@CommonControls";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import _ from "@HisPlatform/Common/Lodash";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import StaticConfigurationResources from "@HisPlatform/BoundedContexts/Configuration/StaticResources/StaticConfigurationResources";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";

interface IPropertyGroupsPanelDependencies {
    dynamicPropertiesApiAdapter: ConfigurationDynamicPropertiesApiAdapter;
    notificationService: INotificationService;
    localizationService: ILocalizationService;
}

interface IPropertyGroupsPanelProps {
    _dependencies?: IPropertyGroupsPanelDependencies;
    onCancel: () => void;
}

/** @screen */
@State.observer
class PropertyGroupsPanel extends React.Component<IPropertyGroupsPanelProps> {

    private get notificationService() { return this.props._dependencies.notificationService; }

    @State.observable.ref public propertyGroups: PropertyGroup[] = null;
    @State.observable.ref public filterValue: string = "";
    @State.observable private isLoading: boolean = true;

    private get apiAdapter() {
        return this.props._dependencies.dynamicPropertiesApiAdapter;
    }

    @State.computed public get visiblePropertyGroups() {
        return this.propertyGroups && this.propertyGroups.filter(x => x.isVisible) || [];
    }

    @State.computed public get noVisiblePropertyGroups() {
        return !this.visiblePropertyGroups || this.visiblePropertyGroups.length === 0;
    }

    constructor(props: IPropertyGroupsPanelProps) {
        super(props);
    }

    @State.bound
    private async saveAsync() {
        if (!this.propertyGroups) {
            return;
        }

        const newStore = await this.apiAdapter.updatePropertyGroupsAsync(this.propertyGroups);
        this.notificationService.showSaveResult(newStore.isPersistedByOperationInfo);
        const newPropertyGroups = newStore.value;
        this.setPropertyGroups(newPropertyGroups);
    }

    @State.action.bound
    public setPropertyGroups(propertyGroups: PropertyGroup[]) {
        this.propertyGroups = propertyGroups;
        this.propertyGroups.forEach(x => x.takeSnapshot());

        this.filterProperties();
    }

    @State.action.bound
    public filterProperties() {
        const filterValue = this.filterValue && this.filterValue.toLowerCase();
        for (const propertyGroup of this.propertyGroups) {
            for (const property of propertyGroup.properties) {
                if (isNullOrUndefined(this.filterValue) || this.filterValue === "") {
                    property.isVisible = true;
                } else {
                    const rowNameSelector = `${propertyGroup.name}.${property.name}`;
                    let localizedName = this.props._dependencies.localizationService.localizeProperty(rowNameSelector);

                    if (!localizedName) {
                        localizedName = property.name;
                    }

                    property.isVisible = localizedName.toLowerCase().includes(filterValue);
                }
            }
        }
    }

    @State.action.bound
    public setFilterValue(value: string) {
        this.filterValue = value;
        this.filterProperties();
    }

    @State.boundLoadingState("isLoading")
    private async loadAsync() {

        const results = await this.apiAdapter.getAllPropertyGroupsAsync();

        State.runInAction(() => {
            this.setPropertyGroups(results.value);
        });
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticConfigurationResources.PropertyGroupsPanel.Title} />;
        }

        if (this.isLoading) {
            return <></>;
        }

        return (
            <Ui.PageBox widthMode="limited">
                <Ui.PageBox.Header title={StaticConfigurationResources.PropertyGroupsPanel.Title} toolbar="right" >
                    <Ui.Button
                        size="compact"
                        text={StaticWebAppResources.Common.Button.Cancel}
                        onClick={this.props.onCancel}
                        automationId="cancelButton"
                    />
                    <Ui.SaveButton
                        size="compact"
                        onClickAsync={this.saveAsync}
                        visualStyle="primary"
                        disabled={this.noVisiblePropertyGroups}
                        automationId="saveButton"
                    />

                </Ui.PageBox.Header>
                <Ui.PageBox.Body>
                    <>
                        <Ui.Flex verticalSpacing="none">
                            <Ui.Flex.Item xs={12}>
                                <Ui.TextBox
                                    label={StaticWebAppResources.Common.Label.Filter}
                                    onChange={this.setFilterValue}
                                    value={this.filterValue}
                                    automationId="filterValueTextBox"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <Ui.Flex verticalSpacing="regular">
                            <Ui.Flex.Item xs={12}>
                                <Ui.ScrollView height="calc(100vh - 250px)">
                                    {this.visiblePropertyGroups.map((pg: PropertyGroup) =>
                                        <PropertyGroupGrid
                                            key={pg.name}
                                            propertyGroup={pg}
                                            filterValue={this.filterValue} />)
                                    }
                                    {this.noVisiblePropertyGroups && !this.isLoading &&
                                        <>
                                            <br />
                                            <Ui.NoItemsMessage message={StaticWebAppResources.Common.Label.NoFilterMatch} />
                                        </>
                                    }
                                </Ui.ScrollView>
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync, this.apiAdapter.updatePropertyGroupsPermissionCheckAsync);
    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }
}

export default connect(
    PropertyGroupsPanel,
    new DependencyAdapter<IPropertyGroupsPanelProps, IPropertyGroupsPanelDependencies>(container => {
        return {
            dynamicPropertiesApiAdapter: container.resolve("ConfigurationDynamicPropertiesApiAdapter"),
            notificationService: container.resolve("INotificationService"),
            localizationService: container.resolve("ILocalizationService")
        };
    })
);
