import React from "react";
import Styles from "./PageBoxContainer.less";

interface IPageBoxContainerProps {
    children?: React.ReactNode;
    noPadding?: boolean;
}


const PageBoxContainer: React.FC<IPageBoxContainerProps> = (props) => {
    return (
        <>
            <div className={Styles.outerDiv} style={{ "--padding": props.noPadding ? "0" : "8px" } as any}>
                {props.children}
            </div>
        </>
    );
};

export default PageBoxContainer;