/* eslint "@typescript-eslint/no-this-alias": "off" */
import React from "react";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import IUseCaseDescriptor from "@PluginInterface/UseCases/IUseCaseDescriptor";
import IUseCaseNavigatorParameters from "@PluginInterface/UseCases/IUseCaseNavigatorParameters";
import Route from "@Toolkit/ReactClient/Routing/Abstractions/Route";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import RoutingFrame from "@Toolkit/ReactClient/Routing/RoutingFrame";

export default class UseCaseDescriptorBuilder {

    private descriptor: IUseCaseDescriptor;

    private constructor(
        private readonly useCaseName: string,
        showToolbar = true
    ) {
        this.descriptor = {
            name: useCaseName,
            showToolbar,

            standaloneRouteDefinitions: {},
            standaloneRouteFactory: null,
            standaloneRoutingFrameCaseRenderer: () => null,

            masterDetailRouteDefinitions: {},
            masterDetailRouteFactory: null,
            masterDetailRoutingFrameCaseRenderer: () => null
        };
    }

    public static create(useCaseName: string, builderAction: (builder: UseCaseDescriptorBuilder) => void, showToolbar: boolean = true): IUseCaseDescriptor {
        const builder = new UseCaseDescriptorBuilder(useCaseName, showToolbar);
        builderAction(builder);
        return builder.build();
    }

    public static clone(source: IUseCaseDescriptor, newUseCaseName: string): IUseCaseDescriptor {
        return {
            ...source,
            name: newUseCaseName
        };
    }

    public standalone(subBuilderAction: (builder: StandaloneRouteAndNavigatorBuilder) => void) {
        const builder = new StandaloneRouteAndNavigatorBuilder(
            this.descriptor,
            this.useCaseName
        );
        subBuilderAction(builder);
        return this;
    }
    public hideFooter() {
        this.descriptor.showToolbar = false;
    }

    public masterDetail(subBuilderAction: (builder: MasterDetailRouteAndNavigatorBuilder) => void) {
        const builder = new MasterDetailRouteAndNavigatorBuilder(
            this.descriptor,
            this.useCaseName
        );
        subBuilderAction(builder);
        return this;
    }

    private build() {
        return this.descriptor;
    }
}

abstract class RouteAndNavigatorBuilder {

    protected abstract getRouteDefinitions(): { [key: string]: RouteDefinition };
    protected abstract setRouteFactory(factory: (params: IUseCaseNavigatorParameters) => Route): void;
    protected abstract setRoutingFrameCaseRenderer(renderer: () => React.ReactNode): void;

    constructor(
        protected readonly descriptor: IUseCaseDescriptor,
        protected readonly useCaseName: string
    ) { }

    public routeDefinition(routeDefinition: RouteDefinition) {
        const me = this;
        this.getRouteDefinitions()[this.useCaseName] = routeDefinition;
        this.setRouteFactory((params: IUseCaseNavigatorParameters) => routeDefinition.makeRoute(me.buildRouteParams(params)));
        return this;
    }

    public additionalRouteParamsFactory(additionalRouteParamsFactory: (params: IUseCaseNavigatorParameters) => object) {
        const me = this;
        const routeDefinition = this.getRouteDefinitions()[this.useCaseName];
        this.setRouteFactory((params: IUseCaseNavigatorParameters) => routeDefinition.makeRoute(me.buildRouteParams(params, additionalRouteParamsFactory(params))));
        return this;
    }

    public additionalRouteParams(additionalRouteParams: object) {
        const me = this;
        const routeDefinition = this.getRouteDefinitions()[this.useCaseName];
        this.setRouteFactory((params: IUseCaseNavigatorParameters) => routeDefinition.makeRoute(me.buildRouteParams(params, additionalRouteParams)));
        return this;
    }

    public additionalQueryParams(additionalQueryParams: object) {
        const me = this;
        const routeDefinition = this.getRouteDefinitions()[this.useCaseName];
        this.setRouteFactory((params: IUseCaseNavigatorParameters) => routeDefinition.makeRoute(me.buildRouteParams(params), additionalQueryParams));
        return this;
    }

    public routeFactory(
        routeFactory: (
            params: IUseCaseNavigatorParameters,
            routeParamsBuilder: (navigatorParams: IUseCaseNavigatorParameters, additionalParams?: any) => object
        ) => Route
    ) {
        const me = this;
        this.setRouteFactory((params: IUseCaseNavigatorParameters) => routeFactory(params, me.buildRouteParams));
        return this;
    }

    public component(component: React.ComponentClass<{ routingController?: IRoutingController }> | React.FC<{ routingController?: IRoutingController }>) {
        const routeDefinition = this.getRouteDefinitions()[this.useCaseName];
        const key = this.useCaseName;

        this.setRoutingFrameCaseRenderer(() => (
            <RoutingFrame.Case route={routeDefinition} key={key} component={component} />
        ));
    }

    private buildRouteParams(navigatorParams: IUseCaseNavigatorParameters, additionalParams?: any): object {
        return {
            ...!!navigatorParams.useCaseArguments && navigatorParams.useCaseArguments.reduce((ret: any, arg: any) => {
                ret[arg.name] = arg.value.value;
                return ret;
            }, {}),
            ...!!additionalParams && additionalParams
        };
    }
}

class StandaloneRouteAndNavigatorBuilder extends RouteAndNavigatorBuilder {

    protected getRouteDefinitions(): { [key: string]: RouteDefinition<any>; } {
        return this.descriptor.standaloneRouteDefinitions;
    }

    protected setRouteFactory(factory: (params: IUseCaseNavigatorParameters) => Route<any>): void {
        this.descriptor.standaloneRouteFactory = factory;
    }

    protected setRoutingFrameCaseRenderer(renderer: () => React.ReactNode): void {
        this.descriptor.standaloneRoutingFrameCaseRenderer = renderer;
    }

}

class MasterDetailRouteAndNavigatorBuilder extends RouteAndNavigatorBuilder {

    protected getRouteDefinitions(): { [key: string]: RouteDefinition<any>; } {
        return this.descriptor.masterDetailRouteDefinitions;
    }

    protected setRouteFactory(factory: (params: IUseCaseNavigatorParameters) => Route<any>): void {
        this.descriptor.masterDetailRouteFactory = factory;
    }

    protected setRoutingFrameCaseRenderer(renderer: () => React.ReactNode): void {
        this.descriptor.masterDetailRoutingFrameCaseRenderer = renderer;
    }

}