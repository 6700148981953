import { IPagingState, IOrderingState, DataGridLoadType } from "@CommonControls/DataGrid/IDataGridProps";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import PatientsCareActivitiesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/PatientCareActivities/PatientsCareActivitiesApiAdapter";
import Di from "@Di";
import PatientId from "@Primitives/PatientId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PractitionerId from "@Primitives/PractitionerId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import _ from "@HisPlatform/Common/Lodash";
import DataGridDataSourceBase from "@CommonControls/DataGrid/DataSource/DataGridDataSourceBase";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import ListQueryOptions from "@Toolkit/CommonWeb/Model/ListQueryOptions";
import CareActivityDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentApiAdapter";
import IPatientsCareActivitiesFilterStore from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientsCareActivitiesFilterStore";
import PatientsCareActivitiesFilterStore from "./PatientsCareActivitiesFilterStore";
import IPatientCareActivitiesDataSource from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientCareActivitiesDataSource";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import DataLoadError from "@CommonControls/DataGrid/DataSource/DataLoadError";

@Di.injectable()
export default class PatientsCareActivitiesDataSource extends DataGridDataSourceBase<PatientsCareActivityStore, IPatientsCareActivitiesFilterStore> implements IPatientCareActivitiesDataSource {
    
    public __dataGridDataSource: true = true;

    public patientId: PatientId;
    @State.observable public isLoading: boolean = false;
    @State.observable public isPermissionFiltered: boolean = false;

    @State.observable.ref public extensionData: any = null;

    @State.action.bound
    public setExtensionData(newValue: any) {
        this.extensionData = newValue;
    }

    @State.action.bound
    public setIsLoading(newValue: boolean) {
        this.isLoading = newValue;
    }

    @State.action.bound
    public setIsPermissionFiltered(newValue: boolean) {
        this.isPermissionFiltered = newValue;
    }

    constructor(
        @Di.inject("PatientsCareActivitiesApiAdapter") private patientsCareActivitiesApiAdapter: PatientsCareActivitiesApiAdapter,
        @Di.inject("CareActivityDocumentApiAdapter") private careDocumentApiAdapter: CareActivityDocumentApiAdapter,
        @Di.inject("OrganizationReferenceDataStore") private organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("error") onErrorAsync: (error: @Di.inject("Promise") DataLoadError) => Promise<void>
    ) {
        super(onErrorAsync);
        this.setState(
            { totalCount: 0, items: [] },
            { currentPage: 0, pageSize: 10 },
            { columnId: "carePeriod", direction: "desc" },
            new PatientsCareActivitiesFilterStore()
        );

        this.filterStore.onChangeDebounced.attach(() => {
            dispatchAsyncErrors(this.refreshAsync(), null);
        });
    }

    @State.bound
    public async clearExtensionDataCacheAndReloadAsync() {
        await this.refreshAsync();
    }

    @State.bound
    public async refreshAsync() {
        await this.onChangeAsync("changed", this.paging, this.ordering as IOrderingState, this.filterStore, []);
    }

    @State.bound
    protected async onChangeCoreAsync(type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState, filterStore: IPatientsCareActivitiesFilterStore, columns: IDataGridColumnProps[]) {

        if (!this.patientId) {
            return;
        }

        const queryOptions = paging && ListQueryOptions.create({
            currentPage: paging.currentPage,
            pageSize: paging.pageSize,
            orderBy: ordering.columnId && [{ ascending: ordering.direction === "asc", fieldName: ordering.columnId as string }]
        });

        this.setIsLoading(true);

        const data = await this.patientsCareActivitiesApiAdapter.searchPatientCareActivities(
            this.patientId,
            filterStore.episodeOfCareIds,
            filterStore.practitionerIds,
            filterStore.pointOfCareIds,
            filterStore.dateRange || new LocalDateRange(),
            filterStore.careActivitySource,
            filterStore.careTypeIds,
            filterStore.careActivityStates,
            filterStore.careIdentifier,
            queryOptions,
            filterStore?.getExtensionData() ?? {}
        );

        if (data.operationWasSuccessful) {
            await this.loadPrimaryDocumentsInfoAsync(data.value.results);
            await this.loadReferenceDataAsync(data.value.results);

            this.setExtensionData(data.value.extensionData);

            this.setState({
                items: data.value.results,
                totalCount: data.value.totalCount
            }, paging, ordering, filterStore);
            this.setIsPermissionFiltered(data.value.isFiltered);
        }
        this.setIsLoading(false);
    }

    @State.bound
    private async loadPrimaryDocumentsInfoAsync(items: PatientsCareActivityStore[]) {
        for (const i of items) {
            if (i.careActivityId) {
                const doc = await this.careDocumentApiAdapter.getSingleDocumentForCareActivityAsync(i.careActivityId, null, true, false);

                if (doc.value?.id) {
                    i.primaryDocumentId = doc.value.id;
                }
            }
        }
    }

    @State.bound
    private async loadReferenceDataAsync(items: PatientsCareActivityStore[]) {
        if (!items) {
            return;
        }

        const practitionerIds: PractitionerId[] = [];
        const externalLocationIds: Array<IEntityVersionSelector<ExternalLocationId>> = [];
        const pointOfCareIds: PointOfCareId[] = [];

        items.forEach(item => {
            if (item.primaryParticipantId) {
                practitionerIds.push(item.primaryParticipantId);
            }
            if (item.organizationUnitId) {
                pointOfCareIds.push(item.organizationUnitId);
            }
            if (item.referralDoctorId) {
                practitionerIds.push(item.referralDoctorId);
            }
            if (item.referralWorkplaceId) {
                externalLocationIds.push(item.referralWorkplaceId);
            }
        });

        await Promise.all([
            this.organizationReferenceDataStore.doctorMap.ensureLoadedAsync(practitionerIds),
            this.organizationReferenceDataStore.externalLocationStore.ensureLoadedAsync(externalLocationIds),
            this.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync(pointOfCareIds),
        ]);
        let healthcareProfessionIds = _.flatten(pointOfCareIds.map(item => this.organizationReferenceDataStore.allPointsOfCareMap.get(item).healthcareProfessionIds));
        healthcareProfessionIds = _.uniqBy(healthcareProfessionIds, item => item.value);
        await this.organizationReferenceDataStore.healthCareProfession.ensureLoadedAsync(healthcareProfessionIds);
    }
}
