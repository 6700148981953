import React from "react";
import * as Ui from "@CommonControls";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import Styles from "./ServiceAdministrationPanel.less";
import ActionButton from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ServiceAdministrationActionButton/ActionButton";
import MenuController from "@CommonControls/ContextMenu/MenuController";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import RequestedServiceStateBadge from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/RequestedServiceStateBadge/RequestedServiceStateBadge";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import RequestedServiceAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceAction.g";
import RequestedServiceState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceState.g";
import ReadOnlyContextAdapter from "@Toolkit/ReactClient/Components/ReadOnlyContext/ReadOnlyContextAdapter";

interface IServiceAdministrationActionDependencies {
    localizationService: ILocalizationService;
    careReferenceDataStore: CareReferenceDataStore;
}

interface IServiceAdministrationActionProps {
    _dependencies?: IServiceAdministrationActionDependencies;
    row?: RequestedServiceStore;
    rowIndex?: number;
    onSetAdministrativeActionAsync: (item: RequestedServiceStore, newAction: RequestedServiceAction) => Promise<void>;
    isReadOnly?: boolean;
}

const forwardActions: RequestedServiceAction[] = [
    RequestedServiceAction.RegisterAsReceived,
    RequestedServiceAction.CompleteActivity,
    RequestedServiceAction.ValidateDiagnosticReport
];

const actionToStateMap = {
    [RequestedServiceAction.RegisterAsReceived]: RequestedServiceState.RegisteredAsReceived,
    [RequestedServiceAction.CompleteActivity]: RequestedServiceState.ActivityCompleted,
    [RequestedServiceAction.ValidateDiagnosticReport]: RequestedServiceState.ValidatedCompleted
};

@State.observer
class ServiceAdministrationAction extends React.Component<IServiceAdministrationActionProps> {

    private get localizationService() { return this.props._dependencies.localizationService; }
    private readonly contextMenuId =
        "__service_actions_ctx_menu_row_" + this.props.row.medicalServiceVersionSelector.id.value;

    @State.computed private get service() {
        return this.props._dependencies.careReferenceDataStore.medicalService.get(this.props.row.medicalServiceVersionSelector);
    }

    @State.computed private get nextState() {

        if (!isNullOrUndefined(this.props.row.administrationAction)) {
            const ret = actionToStateMap[this.props.row.administrationAction];
            return ret === undefined ? null : ret;
        }

        return null;
    }

    @State.computed private get forwardAction() {
        return this.props.row.possibleActions.find(a => forwardActions.includes(a));
    }

    private changeStateAsyncHandlerFactory(newAction: RequestedServiceAction) {
        return async () => {
            MenuController.closeAll();
            await this.props.onSetAdministrativeActionAsync(this.props.row, newAction);
        };
    }

    private renderForwardStateIndicator() {
        if (isNullOrUndefined(this.props.row.administrationAction)) {
            return <ActionButton actionToPerform={this.forwardAction} onClickAsync={this.changeStateAsyncHandlerFactory(this.forwardAction)} automationId={`service_nextAction_${this.service.code.value}`} />;
        } else {
            if (isNullOrUndefined(this.nextState)) {
                return <><Ui.Icon visualStyle="dark" iconName="angleRight" />&nbsp;<RequestedServiceStateBadge state={this.props.row.state} isCancellation /></>;
            } else {
                return <><Ui.Icon visualStyle="dark" iconName="angleRight" />&nbsp;<RequestedServiceStateBadge state={this.nextState} /></>;
            }
        }
        return null;
    }

    public render() {
        if (arrayIsNullOrEmpty(this.props.row.possibleActions)) {
            return null;
        }

        return (
            <div className={Styles.actionContainer}>
                <div>
                    {this.renderForwardStateIndicator()}
                </div>
                <Ui.ContextMenu id={this.contextMenuId}>
                    {!isNullOrUndefined(this.props.row.administrationAction) && (
                        <>
                            <Ui.ContextMenu.Item
                                key="item_cancel"
                                onClickAsync={this.changeStateAsyncHandlerFactory(null)}
                            >
                                {StaticCareResources.Common.Button.Cancel}
                            </Ui.ContextMenu.Item>
                            <Ui.ContextMenu.Separator />
                        </>
                    )}
                    {this.props.row.possibleActions.map(i => (
                        <Ui.ContextMenu.Item
                            key={"item_" + i}
                            onClickAsync={this.changeStateAsyncHandlerFactory(i)}
                        >
                            {this.localizationService.localizeEnum(RequestedServiceAction[i], "RequestedServiceAction").Name}
                        </Ui.ContextMenu.Item>
                    ))}
                </Ui.ContextMenu>
                <Ui.ContextMenu.Provider id={this.contextMenuId} event="onClick">
                    <div className={Styles.moreButton}>
                        <Ui.Button iconName="more" visualStyle="link" automationId={`service_otherActions_${this.service.code.value}`} />
                    </div>
                </Ui.ContextMenu.Provider>
            </div>
        );
    }
}

export default connect(
    ServiceAdministrationAction,
    new DependencyAdapter<IServiceAdministrationActionProps, IServiceAdministrationActionDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
    })),
    new ReadOnlyContextAdapter()
);