import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import Modal from "@CommonControls/Modal";
import Button from "@CommonControls/Button";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import TextBox from "@CommonControls/TextBox";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import TemplateSelectBox from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Controls/TemplateSelectBox/TemplateSelectBox";
import TemplateId from "@Primitives/TemplateId.g";
import { ICreateNewDocumentDialogResult } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/CreateNewDocumentDialog/CreateNewDocumentDialogParams";
import ScopeIdentifier from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ScopeIdentifier";
import CareActivityId from "@Primitives/CareActivityId.g";
import TemplateBasedDocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/TemplateBasedDocumentApiAdapter";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import DocumentKind from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentKind.g";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PatientId from "@Primitives/PatientId.g";

interface INewTemplateBasedDocumentPanelDependencies {
    templateBasedDocumentApiAdapter: TemplateBasedDocumentApiAdapter;
    dialogService: IDialogService;
}

interface INewTemplateBasedDocumentPanelProps {
    _dependencies?: INewTemplateBasedDocumentPanelDependencies;

    onCancel: () => void;
    onSuccess: (dialogResult: ICreateNewDocumentDialogResult) => void;

    _careActivityId?: CareActivityId;
    _patientId?: PatientId;
    _careActivityPointOfCareId?: PointOfCareId;
    scope?: string;
}

@State.observer
class NewTemplateBasedDocumentPanel extends React.Component<INewTemplateBasedDocumentPanelProps> {

    private readonly modalResources = StaticDocumentManagementResources.DocumentManagementMasterDetailPanel.CreateNewDocumentDialog;

    @State.observable.ref private templateId: TemplateId = null;
    @State.observable.ref private description: string = null;

    @State.action.bound
    private setTemplateId(newTemplateId: TemplateId) {
        this.templateId = newTemplateId;
    }

    @State.action.bound
    public setDescription(value: string) {
        this.description = value;
    }

    @State.computed private get scopeIdentifiers(): ScopeIdentifier[] {
        const returnList = new Array<ScopeIdentifier>();

        if (!isNullOrUndefined(this.props._careActivityId)) {
            returnList.push(ScopeIdentifier.createCareActivityScopeIdentifier(this.props._careActivityId!));
        }
        if (!isNullOrUndefined(this.props._patientId)) {
            returnList.push(ScopeIdentifier.createPatientScopeIdentifier(this.props._patientId!));
        }

        return returnList;
    }

    private get templateBasedDocumentApiAdapter() { return this.props._dependencies!.templateBasedDocumentApiAdapter; }

    private notAvailableTemplateIds: TemplateId[] = [];

    @State.observable.ref public validationResults: IClientValidationResult[] = null;

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.bound
    private async loadAsync() {
        if (!isNullOrUndefined(this.props._careActivityPointOfCareId)) {
            const notAvailableTemplateIds = await this.templateBasedDocumentApiAdapter.getNotAvailableTemplateIdsForCareActivityAsync(this.props._careActivityPointOfCareId, this.scopeIdentifiers);
            this.notAvailableTemplateIds = notAvailableTemplateIds.value;
        }
    }

    public render() {
        return (
            <ValidationBoundary validationResults={this.validationResults} entityTypeName="GenerateNewTemplateBasedDocument">
                <Modal.Body>
                    <TemplateSelectBox
                        label={this.modalResources.TemplateName}
                        onChange={this.setTemplateId}
                        value={this.templateId}
                        propertyIdentifier="TemplateId"
                        automationId="templateSelectBox"
                        scope={this.props.scope}
                    />
                    <TextBox
                        label={this.modalResources.AttachmentDescription}
                        multiline
                        multilineMinLineCount={2}
                        multilineMaxLineCount={3}
                        value={this.description}
                        onChange={this.setDescription}
                        automationId="descriptionTextBox"
                    />
                </Modal.Body>
                <Modal.Footer left={(
                    <Button text={StaticDocumentManagementResources.Common.Cancel} onClick={this.props.onCancel} automationId="cancelButton" />
                )} right={(
                    <Button visualStyle="primary" text={this.modalResources.Create} onClick={this.addTemplateBasedDocument} automationId="addTemplateBasedDocumentButton" />
                )} />
            </ValidationBoundary>
        );
    }

    @State.boundLoadingState()
    private addTemplateBasedDocument() {
        if (!this.templateId) {
            this.addError("GenerateNewTemplateBasedDocument", "TemplateId", StaticDocumentManagementResources.Validation.Required);
            return;
        }

        if (this.notAvailableTemplateIds.some(i => ValueWrapper.equals(i, this.templateId))) {
            this.addError("GenerateNewTemplateBasedDocument", "TemplateId", this.modalResources.TemplateBasedDocumentAlreadyExistsInCareActivity);
            return;
        }

        this.props.onSuccess(
            {
                templateId: this.templateId,
                documentKind: DocumentKind.TemplateBased,
                description: this.description
            } as ICreateNewDocumentDialogResult);
    }

    @State.action.bound
    public addError(entityName: string, propertyPath: string, message: string) {
        this.validationResults = [
            {
                entityName: entityName,
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: propertyPath,
                        severity: "error",
                        message: message,
                    }
                ]
            }
        ];
    }
}

export default connect(
    NewTemplateBasedDocumentPanel,
    new DependencyAdapter<INewTemplateBasedDocumentPanelProps, INewTemplateBasedDocumentPanelDependencies>(c => ({
        templateBasedDocumentApiAdapter: c.resolve("TemplateBasedDocumentApiAdapter"),
        dialogService: c.resolve("IDialogService"),
    })),
    new CareActivityContextAdapter<INewTemplateBasedDocumentPanelProps>(c => ({
        _careActivityId: c.careActivityId,
        _careActivityPointOfCareId: c.careActivity?.pointOfCareId
    })),
    new PatientContextAdapter<INewTemplateBasedDocumentPanelProps>(c => ({
        _patientId: c.patientId
    }))
);