import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import Di from "@Di";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import SubstanceId from "@Primitives/SubstanceId.g";
import MedicationNameColumnData from "@HisPlatformControls/DataGridColumns/MedicationNameColumnData";
import SubstanceAmountColumnData from "@HisPlatformControls/DataGridColumns/SubstanceAmountColumnData";
import PointOfCareColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";
import DataGridAgeColumn from "@CommonControls/DataGrid/Column/DataGridAgeColumn";
import UrgencyColumn from "@HisPlatformControls/DataGridColumns/UrgencyColumn";
import React from "react";
import ServiceRequestStateColumn from "@HisPlatform/BoundedContexts/Care/Components/Controls/ServiceRequestManagement/ServiceRequestStateColumn/ServiceRequestStateColumn";
import CareActivityStateColumn from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/CareActivityStateColumn/CareActivityStateColumn";
import CareActivityBasedWorklistStateColumn from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/CustomColumns/CareActivityBasedWorklistStateColumn";
import MedicalServicePriorityColumn2 from "@HisPlatform/BoundedContexts/Care/Components/Controls/ServiceRequestManagement/MedicalServicePriorityColumn2/MedicalServicePriorityColumn2";
import PatientNameColumn from "@HisPlatform/BoundedContexts/Care/Components/Controls/DataGridColumns/PatientNameColumn";
import MedicalServiceColumn from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/MedicalServiceColumn/MedicalServiceColumn";
import MedicationTypeColumn from "@HisPlatformControls/DataGridColumns/MedicationTypeColumn";
import MedicationFormColumn from "@HisPlatformControls/DataGridColumns/MedicationFormColumn";
import SubstancesColumn from "@HisPlatformControls/DataGridColumns/SubstancesColumn";
import MedicationNameColumn from "@HisPlatformControls/DataGridColumns/MedicationNameColumn";
import SubstanceAmountColumn from "@HisPlatformControls/DataGridColumns/SubstanceAmountColumn";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import CareActivityIsAlreadyAServiceRequestExecutingCareActivityError from "./ApplicationLogic/ApiAdapter/ServiceRequestManagement/CareActivityIsAlreadyAServiceRequestExecutingCareActivityError";
import CantCloseCareActivityError from "./ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CantCloseCareActivityError";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import { mapPatientCareActivityDataDtoToStore } from "./ApplicationLogic/ApiAdapter/CareRegister/PatientCareActivities/PatientCareActivityDataDtoMapper";
import FinancingClassColumn from "@HisPlatformControls/DataGridColumns/FinancingClassColumn";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import OutpatientTreatmentRoutes from "@HisPlatform/Components/Pages/OutpatientTreatment/OutpatientTreatmentRoutes";
import CareActivityFormsPage from "@HisPlatform/Components/Pages/OutpatientTreatment/CareActivityFormsPage";
import MergedPatientIdentifierColumn from "./Components/Controls/DataGridColumns/MergedPatientIdentifierColumn";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import { PatientDocumentSourceType } from "@Toolkit/CommonWeb/Model/PatientDocumentSourceType";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import DependencyContainer from "@DiContainer";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import ConditionCodeSelector from "./Components/Controls/ReferenceData/ConditionCodeSelector";
import ConditionId from "@Primitives/ConditionId.g";
import CareReferenceDataStore from "./ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import DiagnosisId from "@Primitives/DiagnosisId.g";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import StaticCareResources from "./StaticResources/StaticCareResources";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import MedicalServiceNonTemporalCodeSelector from "@HisPlatformControls/MedicalServiceNonTemporalCodeSelector";
import CareTypeId from "@Primitives/CareTypeId.g";
import CareTypeColumn from "@HisPlatformControls/DataGridColumns/CareTypeColumn";
import { AppointmentsPanelRegistryItem } from "./Components/Panels/CareRegister/CareActivityBaseDataPanel/AppointmentsPanelFormCustomBlock";
import IScreenRegistry from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import createHisDashboardScreenSidebar from "@HisPlatform/Components/ShowScreenAction/ScreenDashboardFactory";
import PatientWorklistScreen from "./Components/Panels/Worklist/PatientWorklist/PatientWorklistScreen";
import StaticResources from "@StaticResources";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import CareActivityStateSelectBox from "./Components/Controls/CareRegister/CareActivityStateSelectBox/CareActivityStateSelectBox";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import DischargeReasonId from "@Primitives/DischargeReasonId.g";
import { DischargeReasonSelectBox, AdmissionReasonSelectBox, DeathPlaceTypeSelectBox } from "./Components";
import DischargeReasonColumn from "@HisPlatformControls/DataGridColumns/DischargeReasonColumn";
import { UrgencySelectBox } from "@HisPlatformControls";
import InsuranceId from "@Primitives/InsuranceId.g";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import AdmissionReasonId from "@Primitives/AdmissionReasonId.g";
import CareRegisterReferenceDataStore from "./ApplicationLogic/Model/CareRegister/CareRegisterReferenceDataStore";
import ShowSearchPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowSearchPatientScreenAction.g";
import IdentifierWithSourceType from "@Toolkit/CommonWeb/Model/IdentifierWithSourceType";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import CareLocationColumn from "./Components/Controls/ReferenceData/CareLocationColumn/CareLocationColumn";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ServiceRequestDefinitionColumn from "./Components/Controls/ServiceRequestManagement/ServiceRequestDefinitionColumn/ServiceRequestDefinitionColumn";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import InsurerOrganizationSelectBox from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Components/Controls/InsurerOrganizationSelectBox";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import PatientRelativeTypeId from "@Primitives/PatientRelativeTypeId.g";
import PatientRelativeTypeSelectBox from "./Components/Controls/PatientRegister/PatientRelativeTypeSelectBox";
import PatientPractitionerTypeId from "@Primitives/PatientPractitionerTypeId.g";
import PatientPractitionerTypeSelectBox from "@HisPlatform/BoundedContexts/CommonReferenceData/Components/PatientRegister/PatientPractitionerTypeSelectBox/PatientPractitionerTypeSelectBox";
import PractitionSpecialtyId from "@Primitives/PractitionSpecialtyId.g";
import PractitionSpecialtySelectBox from "@HisPlatform/BoundedContexts/CommonReferenceData/Components/PatientRegister/PractitionSpecialtySelectBox/PractitionSpecialtySelectBox";
import DeathPlaceTypeId from "@Primitives/DeathPlaceTypeId.g";
import PatientRegisterReferenceDataStore from "./ApplicationLogic/Model/PatientRegister/PatientRegisterReferenceDataStore";
import Urgency from "./Api/CareRegister/Enum/Urgency.g";
import { CompositeValidationResult } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import IPatientCareActivitiesTabRegistry from "./Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabRegistry";
import PerformedServicesTabComponentService from "@HisPlatform/Packages/Care/Screens/PerformedServicesScreen/PerformedServicesTabComponentService";
import WrappedReadOnlyPerformedServiceScreen from "@HisPlatform/Packages/Care/Screens/PerformedServicesScreen/WrappedReadOnlyPerformedServiceScreen";
import WrappedEhrCareActivityBaseDataScreen from "@HisPlatform/Packages/Care/Screens/AdmitPatientAndCareActivityBaseDataScreen/WrappedEhrCareActivityBaseDataScreen";
import CareActivityBaseDataTabComponentService from "@HisPlatform/Packages/Care/Screens/AdmitPatientAndCareActivityBaseDataScreen/CareActivityBaseDataTabComponentService";
import WrappedEhrDischargePatientScreen from "@HisPlatform/Packages/Care/Screens/DischargePatientScreen/WrappedEhrDischargePatientScreen";
import DischargePatientTabComponentService from "@HisPlatform/Packages/Care/Screens/DischargePatientScreen/DischargePatientTabComponentService";
import DiagnosisListTabComponentService from "./Components/Panels/CareRegister/DiagnosisListPanel/DiagnosisListTabComponentService";
import WrappedEhrDiagnosisListPanel from "./Components/Panels/CareRegister/DiagnosisListPanel/WrappedEhrDiagnosisListPanel";
import WrappedEhrDocumentManagementMasterDetailPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/DocumentManagementMasterDetailPanel/WrappedEhrDocumentManagementMasterDetailPanel";
import DocumentManagementTabComponentService from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/DocumentManagementMasterDetailPanel/DocumentManagementTabComponentService";
import ServiceRequestTabComponentService from "./Components/Panels/ServiceRequestManagement/ServiceRequestMasterDetailPanel/ServiceRequestTabComponentService";
import WrappedEhrServiceRequestMasterDetailPanel from "./Components/Panels/ServiceRequestManagement/ServiceRequestMasterDetailPanel/WrappedEhrServiceRequestMasterDetailPanel";
import FormInstanceTabComponentService from "./Components/Panels/PatientRegister/FormInstanceListForPatientPanel/FormInstanceTabComponentService";
import WrappedEhrFormInstanceListForPatientPanel from "./Components/Panels/PatientRegister/FormInstanceListForPatientPanel/WrappedEhrFormInstanceListForPatientPanel";
import CantChangeCareActivityStateError from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CantChangeCareActivityStateError";
import ResourceId from "@Primitives/ResourceId.g";
import IStatusChangeBlockingMessageParameters from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/IStatusChangeBlockingMessageParameters";
import IStatusChangeBlockingReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/IStatusChangeBlockingReason";
import StateChangeBlockingData from "@Primitives/StateChangeBlockingData.g";

@Di.injectable()
export default class CareBoundedContext implements IBoundedContext {
    public initializeAsync(): Promise<void> {
        const registry = DependencyContainer.get<IPatientCareActivitiesTabRegistry>("IPatientCareActivitiesTabRegistry");

        registry.register("CareActivityBaseData", 10, "CareActivityBaseDataTabComponentService", WrappedEhrCareActivityBaseDataScreen);
        registry.register("CareActivityDischargeData", 20, "DischargePatientTabComponentService", WrappedEhrDischargePatientScreen);
        registry.register("DiagnosisList", 30, "DiagnosisListTabComponentService", WrappedEhrDiagnosisListPanel);
        registry.register("PerformedServiceList", 40, "PerformedServicesTabComponentService", WrappedReadOnlyPerformedServiceScreen);
        registry.register("Documents", 50, "DocumentManagementTabComponentService", WrappedEhrDocumentManagementMasterDetailPanel);
        registry.register("ServiceRequests", 60, "ServiceRequestTabComponentService", WrappedEhrServiceRequestMasterDetailPanel);
        registry.register("Forms", 70, "FormInstanceTabComponentService", WrappedEhrFormInstanceListForPatientPanel);

        DependencyContainer.bind("CareActivityBaseDataTabComponentService").to(CareActivityBaseDataTabComponentService);
        DependencyContainer.bind("DischargePatientTabComponentService").to(DischargePatientTabComponentService);
        DependencyContainer.bind("DiagnosisListTabComponentService").to(DiagnosisListTabComponentService);
        DependencyContainer.bind("PerformedServicesTabComponentService").to(PerformedServicesTabComponentService);
        DependencyContainer.bind("DocumentManagementTabComponentService").to(DocumentManagementTabComponentService);
        DependencyContainer.bind("ServiceRequestTabComponentService").to(ServiceRequestTabComponentService);
        DependencyContainer.bind("FormInstanceTabComponentService").to(FormInstanceTabComponentService);

        return Promise.resolve();
    }

    public configureNData?(configurator: INDataAttributeConfigurator): void {
        configurator.registerColumn("PointOfCareId", <PointOfCareColumn />);
        configurator.registerColumn("AgeDataDto", <DataGridAgeColumn />);
        configurator.registerColumn("Urgency", <UrgencyColumn />);
        configurator.registerColumn("ServiceRequestState", <ServiceRequestStateColumn displayMode="badge" />);

        configurator.registerColumn("CareActivityState", <CareActivityStateColumn displayMode="badge" />);
        configurator.registerColumn("CareStateDto", <CareActivityBasedWorklistStateColumn displayMode="badge" />);
        configurator.registerColumn("MedicalServicePriority", <MedicalServicePriorityColumn2 />);
        configurator.registerColumn("PatientNameDisplayDto", <PatientNameColumn />);
        configurator.registerColumn("MedicalServiceId", <MedicalServiceColumn />);
        configurator.registerColumn("MedicationTypeId", <MedicationTypeColumn />);
        configurator.registerColumn("MedicationFormId", <MedicationFormColumn />);
        configurator.registerColumn("SubstanceId", <SubstancesColumn />);
        configurator.registerColumn("MedicationNameDto", <MedicationNameColumn />);
        configurator.registerColumn("SubstanceAmountDto", <SubstanceAmountColumn />);
        configurator.registerColumn("FinancingClassId", <FinancingClassColumn />);
        configurator.registerColumn("IdentifierWithSourceTypeDto", <MergedPatientIdentifierColumn isOrderable={false} hideOverflow />);
        configurator.registerColumn("CareTypeId", <CareTypeColumn />);
        configurator.registerColumn("DischargeReasonId", <DischargeReasonColumn />);
        configurator.registerValueConverter("CareLocationDto", (rawValue: any) => {
            let careLocationDto;
            if (rawValue._discriminator === "InternalCareLocationDto") {
                careLocationDto = new Proxy.InternalCareLocationDto({ pointOfCareId: new PointOfCareId(rawValue.PointOfCareId.Value.toString()) });
            } else {
                careLocationDto = new Proxy.ExternalCareLocationDto({
                    externalLocationSelector: new Proxy.EntityVersionSelectorOfExternalLocationId({
                        entityId: rawValue.ExternalLocationSelector.Id,
                        validOn: rawValue.ExternalLocationSelector.ValidOn
                    })
                });
            }
            return careLocationDto;
        });
        configurator.registerColumn("CareLocationDto", <CareLocationColumn />);
        configurator.registerColumn("ServiceRequestDefinitionId", <ServiceRequestDefinitionColumn />);

        this.configureMedicationNData(configurator);
    }

    private configureMedicationNData(configurator: INDataAttributeConfigurator) {

        configurator.registerValueConverter("SubstanceId", (rawValue: { IdentifierSystemId: { Value: string }, IdentifierValue: { Value: string }, MedicationId: number, SubstanceId: number }) => {
            return new SubstanceId(rawValue.SubstanceId.toString());
        });

        configurator.registerValueConverter("MedicationNameDto", (rawValue: any) => {
            return new MedicationNameColumnData(rawValue.Name, rawValue.IsDeleted, rawValue.ModificationType, rawValue.PharmacyType, rawValue.IsValid);
        });

        configurator.registerValueConverter("SubstanceAmountDto", (rawValue: any) => {
            return new SubstanceAmountColumnData(rawValue.SubstanceAmount, rawValue.SubstanceAmountUnit);
        });

        configurator.registerValueConverter("TextAmountDto", (rawValue: any) => {
            return rawValue.TextAmount;
        });

        configurator.registerValueConverter("IdentifierWithSourceTypeDto", (rawValue: any) => {
            return new IdentifierWithSourceType(Identifier.fromJS(rawValue.Identifier), rawValue.SourceType as PatientDocumentSourceType);
        });

        configurator.registerValueConverter("CareTypeId", (rawValue: any) => {
            return new CareTypeId(rawValue);
        });

        configurator.registerValueConverter("DischargeReasonId", (rawValue: any) => {
            return new DischargeReasonId(rawValue);
        });
    }

    public configureMapper(mapper: IMapperConfigurator) {
        mapper.registerByName("CareActivityIsAlreadyAServiceRequestExecutingCareActivityError", _ => new CareActivityIsAlreadyAServiceRequestExecutingCareActivityError());

        mapper.registerByName("CantCloseCareActivityError", (businessError: any) => {
            return new CantCloseCareActivityError(
                CareActivityId.fromJS(businessError.CareActivityId),
                mapValidationResults(CompositeValidationResult.fromJS(businessError.CompositeValidationResult) as unknown as IServerCompositeValidationResult));
        });

        mapper.registerByName("CantChangeCareActivityStateError", (businessError: any) => {
            return new CantChangeCareActivityStateError(
                CareActivityId.fromJS(businessError.CareActivityId),
                new ResourceId("CareActions." + businessError.Activity.Value),
                (businessError.Reasons as any[]).map(i => ({
                    stateChangeBlockingData: StateChangeBlockingData.fromJS(i.StateChangeBlockingData),
                    resourceId: i.StateChangeBlockingMessage?.ResourceId && ResourceId.fromJS(i.StateChangeBlockingMessage.ResourceId),
                    messsageParameters: i.StateChangeBlockingMessage?.Properties && mapToStatusChangeBlockingMessageParameters(i.StateChangeBlockingMessage.Properties)
                } as IStatusChangeBlockingReason)));
        });

        mapper.register(Proxy.PatientCareActivityDataDto, mapPatientCareActivityDataDtoToStore);
        mapper.registerByName("PatientCareActivityDataDto", mapPatientCareActivityDataDtoToStore);

        function mapToStatusChangeBlockingMessageParameters(obj: object) {
            return Object.keys(obj).map(key => {
                return {
                    key: key,
                    parameter: obj[key]
                } as IStatusChangeBlockingMessageParameters;
            });
        }
    }

    public configureActivities(activityRegistry: IActivityRegistry) {
        activityRegistry.register({
            reference: "CareRegister_EditForms",
            iconName: "pen"
        });
        activityRegistry.register({
            reference: "CareRegister_ViewReadOnlyForms",
            iconName: "pen"
        });
    }

    public configureUseCases(useCaseRegistry: IUseCaseRegistry) {
        useCaseRegistry.add(UseCaseDescriptorBuilder.create("CareRegister_EditForms", builder => builder
            .standalone(standaloneBuilder => standaloneBuilder
                .routeDefinition(OutpatientTreatmentRoutes.form)
                .component(CareActivityFormsPage))
                .hideFooter()
        ));
        useCaseRegistry.add(UseCaseDescriptorBuilder.create("CareRegister_ViewReadOnlyForms", builder => builder
            .standalone(standaloneBuilder => standaloneBuilder
                .routeDefinition(OutpatientTreatmentRoutes.form)
                .additionalRouteParams({ mode: "read-only" })
                .component(CareActivityFormsPage))
                .hideFooter()
        ));
    }

    public configureReferenceDataLoaders(referenceDataLoader: IReferenceDataLoader) {
        referenceDataLoader.register("IdentifierWithSourceTypeDto", async (refs: any[]) => {
            const identifierSystemIdstoLoad = refs.map(s => s.identifier.identifierSystemId);
            const commonReferenceDataStore: CommonReferenceDataDataStore = DependencyContainer.get("CommonReferenceDataDataStore");
            await commonReferenceDataStore.identifierSystemMap.ensureLoadedAsync(identifierSystemIdstoLoad);
        });
        referenceDataLoader.register<CareTypeId>("CareTypeId", async () => {
            const careReferenceDataStore: CareReferenceDataStore = DependencyContainer.get("CareReferenceDataStore");
            await careReferenceDataStore.careType.ensureLoadedAsync();
        });
        referenceDataLoader.register<DischargeReasonId>("DischargeReasonId", async () => {
            const careReferenceDataStore: CareReferenceDataStore = DependencyContainer.get("CareReferenceDataStore");
            await careReferenceDataStore.dischargeReason.ensureLoadedAsync();
        });
        referenceDataLoader.register<ServiceRequestDefinitionId>("ServiceRequestDefinitionId", async (refs: any[]) => {
            const serviceRequestDefinitionToLoad = refs.map(s => new EntityVersionSelector<ServiceRequestDefinitionId>(s, LocalDate.today()));
            const careReferenceDataStore: CareReferenceDataStore = DependencyContainer.get("CareReferenceDataStore");
            await careReferenceDataStore.serviceRequestDefinition.ensureLoadedAsync(serviceRequestDefinitionToLoad);
        });
    }

    public configureFormEngine(registry: FormEditorRegistry): void {
        registry.registerEntityReferenceEditor(
            "Insurance",
            {
                displayName: "Insurance - DO NOT USE",
                componentType: null,
                entityIdCtor: InsuranceId,
                loadReferenceDataAsync: async (id: InsuranceId | InsuranceId[]) => { }
            }
        );

        registry.registerEntityReferenceEditor(
            "AppointmentScheduleEntry",
            {
                displayName: "AppointmentScheduleEntry - DO NOT USE",
                componentType: null,
                entityIdCtor: AppointmentScheduleEntryId,
                loadReferenceDataAsync: async (id: AppointmentScheduleEntryId | AppointmentScheduleEntryId[]) => { }
            }
        );

        registry.registerEntityReferenceEditor(
            "Condition",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineEntityReferenceDisplayName.Conditions,
                componentType: ConditionCodeSelector,
                entityIdCtor: ConditionId,
                loadReferenceDataAsync: (id: DiagnosisId | DiagnosisId[]) => {
                    if (Array.isArray(id)) {
                        const entityVersionSelectors = id.map(i => ({ id: i, validOn: DateTimeService.today() }));
                        DependencyContainer.get<CareReferenceDataStore>("CareReferenceDataStore").condition.ensureLoadedAsync(entityVersionSelectors);
                    } else {
                        DependencyContainer.get<CareReferenceDataStore>("CareReferenceDataStore").condition.ensureLoadedAsync([{ id, validOn: DateTimeService.today() }]);
                    }
                    return Promise.resolve();
                }
            }
        );

        registry.registerEntityReferenceEditor(
            "MedicalService",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineEntityReferenceDisplayName.MedicalServices,
                componentType: MedicalServiceNonTemporalCodeSelector,
                entityIdCtor: MedicalServiceId,
                loadReferenceDataAsync: (id: MedicalServiceId | MedicalServiceId[]) => {
                    if (Array.isArray(id)) {
                        const entityVersionSelectors = id.map(i => ({ id: i, validOn: DateTimeService.today() }));
                        DependencyContainer.get<CareReferenceDataStore>("CareReferenceDataStore").medicalService.ensureLoadedAsync(entityVersionSelectors);
                    } else {
                        DependencyContainer.get<CareReferenceDataStore>("CareReferenceDataStore").medicalService.ensureLoadedAsync([{ id, validOn: DateTimeService.today() }]);
                    }
                    return Promise.resolve();
                }
            }
        );

        registry.registerEntityReferenceEditor(
            "InsurerOrganization",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineEntityReferenceDisplayName.InsurerOrganization,
                componentType: InsurerOrganizationSelectBox,
                entityIdCtor: InsurerOrganizationId,
                loadReferenceDataAsync: (id: InsurerOrganizationId | InsurerOrganizationId[]) => {
                    if (Array.isArray(id)) {
                        DependencyContainer.get<FinanceReferenceDataStore>("FinanceReferenceDataStore").insurerOrganizationMap.ensureLoadedAsync(id);
                    } else {
                        DependencyContainer.get<FinanceReferenceDataStore>("FinanceReferenceDataStore").insurerOrganizationMap.ensureLoadedAsync([id]);
                    }
                    return Promise.resolve();
                }
            }
        );

        registry.registerExtensibleEnumEditor(
            "AdmissionReason",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineExtensibleEnumDisplayName.CareType,
                componentType: AdmissionReasonSelectBox,
                entityIdCtor: AdmissionReasonId,
                loadReferenceDataAsync: async () => {
                    await DependencyContainer.get<CareRegisterReferenceDataStore>("CareRegisterReferenceDataStore").admissionReason.ensureLoadedAsync();
                }
            }
        );

        registry.registerExtensibleEnumEditor(
            "DischargeReason",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineExtensibleEnumDisplayName.DischargeReason,
                componentType: DischargeReasonSelectBox,
                entityIdCtor: DischargeReasonId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<CareReferenceDataStore>("CareReferenceDataStore").dischargeReason.ensureLoadedAsync();
                    return Promise.resolve();
                }
            }
        );

        registry.registerExtensibleEnumEditor(
            "PatientRelativeType",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineExtensibleEnumDisplayName.PatientRelativeType,
                componentType: PatientRelativeTypeSelectBox,
                entityIdCtor: PatientRelativeTypeId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<CareReferenceDataStore>("CareReferenceDataStore").patientRelativeType.ensureLoadedAsync();
                    return Promise.resolve();
                }
            }
        );

        registry.registerExtensibleEnumEditor(
            "PatientPractitionerType",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineExtensibleEnumDisplayName.PatientPractitionerType,
                componentType: PatientPractitionerTypeSelectBox,
                entityIdCtor: PatientPractitionerTypeId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<CareReferenceDataStore>("CareReferenceDataStore").patientPractitionerType.ensureLoadedAsync();
                    return Promise.resolve();
                }
            }
        );

        registry.registerExtensibleEnumEditor(
            "PractitionSpecialty",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineExtensibleEnumDisplayName.PractitionSpecialty,
                componentType: PractitionSpecialtySelectBox,
                entityIdCtor: PractitionSpecialtyId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<CareReferenceDataStore>("CareReferenceDataStore").practitionSpecialty.ensureLoadedAsync();
                    return Promise.resolve();
                }
            }
        );

        registry.registerExtensibleEnumEditor(
            "DeathPlaceType",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineExtensibleEnumDisplayName.DeathPlaceType,
                componentType: DeathPlaceTypeSelectBox,
                entityIdCtor: DeathPlaceTypeId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<PatientRegisterReferenceDataStore>("PatientRegisterReferenceDataStore").deathPlaceType.ensureLoadedAsync();
                    return Promise.resolve();
                }
            }
        );

        registry.registerCustomBlock("AppointmentsPanel", AppointmentsPanelRegistryItem);

        registry.registerEnumEditor(
            "CareActivityState",
            {
                displayName: StaticCareResources.EHR.PatientsCareActivities.Labels.CareActivityState,
                componentType: CareActivityStateSelectBox,
                enumType: CareActivityState
            }
        );

        registry.registerEnumEditor(
            "Urgency",
            {
                displayName: StaticResources.OutpatientWorkflow.CareActivityBaseDataStep.Urgency,
                componentType: UrgencySelectBox,
                enumType: Urgency
            }
        );
    }

    public configureScreens(registry: IScreenRegistry): void {
        registry.register<ShowSearchPatientScreenAction>(ShowSearchPatientScreenAction.id, {
            component: PatientWorklistScreen,
            sidebarComponent: createHisDashboardScreenSidebar("PatientRegister_SearchPatient")
        });
    }
}
