import MedicationId from "@Primitives/MedicationId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CurativeItem from "./CurativeItem";

export default class FamilyDoctorDocumentEquipmentReference  {
    @State.observable.ref public medicationVersionSelector: EntityVersionSelector<MedicationId>;

    @State.action.bound
    public setMedicationVersionSelector(medicationVersionSelector: EntityVersionSelector<MedicationId>) {
        this.medicationVersionSelector = medicationVersionSelector;
    }

}