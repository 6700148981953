import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CountryId from "@Primitives/CountryId.g";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import SettlementApiAdapter from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/SettlementApiAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as HisUi from "@HisPlatformControls";
import * as Ui from "@CommonControls";
import { IAutoCompleteFieldProps } from "@CommonControls/AutoCompleteField/AutoCompleteField";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";

interface ISettlementTextboxDependencies {
    apiAdapter: SettlementApiAdapter;
}

interface ISettlementTextboxProps extends ISelectBoxBaseProps {
    _dependencies?: ISettlementTextboxDependencies;
    countryId?: CountryId;
    capitalize?: boolean;
}

@State.observer
class SettlementTextbox extends React.Component<ISettlementTextboxProps> {
    @State.observable.ref private settlements: string[] = [];

    @State.computed
    private get settlementItems(): Array<ISelectBoxItem<string>> {
        return this.settlements.map(item => ({
            text: item,
            value: item
        } as ISelectBoxItem<string>));
    }

    public render() {
        return (
            <Ui.AutoCompleteField
                value={this.props.value}
                onSearchForSuggestions={this.searchAsync}
                suggestions={this.settlementItems}
                onChange={this.props.onChange}
                propertyIdentifier={this.props.propertyIdentifier}
                label={this.props.label}
                automationId={this.props.automationId}
                capitalize={this.props.capitalize}
            />
        );
    }

    @State.bound
    public async searchAsync() {
        const results = await this.props._dependencies.apiAdapter.searchAsync(this.props.value, this.props.countryId);
        this.setSettlementSearchResult(results);
    }

    @State.action.bound
    private setSettlementSearchResult(items: string[]) {
        this.settlements = items;
    }
}

export default connect(
    SettlementTextbox,
    new DependencyAdapter<ISettlementTextboxProps, ISettlementTextboxDependencies>(container => {
        return {
            apiAdapter: container.resolve("SettlementApiAdapter")
        };
    })
);
