exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Table_no-text-selection_2Z2vU {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.Table_table_1bBt1 {\n  width: 100%;\n  background-color: white;\n  color: #676a6c;\n  font-size: 16px;\n  font-family: PragatiNarrow;\n  border-spacing: 0;\n}\n.Table_table_1bBt1 th {\n  font-family: PragatiNarrowBold;\n  border-bottom: 1px solid #676a6c;\n  padding: 3px 3px 1px 3px;\n  text-align: left;\n}\n.Table_table_1bBt1 td {\n  padding: 3px 3px 3px 3px;\n  height: 32px;\n}\n.Table_table_1bBt1 tr:nth-child(even) {\n  background-color: #f4f7fc;\n}\n.Table_table_1bBt1 tr:hover {\n  background-color: #ddd;\n  cursor: pointer;\n}\n.Table_dark_15zRO {\n  width: 100%;\n  background-color: white;\n  color: #676a6c;\n  font-size: 16px;\n  font-family: PragatiNarrow;\n  border-spacing: 0;\n  background-color: #384252;\n  color: white;\n}\n.Table_dark_15zRO th {\n  font-family: PragatiNarrowBold;\n  border-bottom: 1px solid #676a6c;\n  padding: 3px 3px 1px 3px;\n  text-align: left;\n}\n.Table_dark_15zRO td {\n  padding: 3px 3px 3px 3px;\n  height: 32px;\n}\n.Table_dark_15zRO tr:nth-child(even) {\n  background-color: #f4f7fc;\n}\n.Table_dark_15zRO tr:hover {\n  background-color: #ddd;\n  cursor: pointer;\n}\n.Table_dark_15zRO th {\n  border-bottom: 1px solid #53637a;\n}\n.Table_dark_15zRO tr:nth-child(even) {\n  background-color: #3e4a5c;\n}\n.Table_dark_15zRO tr:hover {\n  background-color: #49576b;\n}\n.Table_scrollable_1DxM4 {\n  overflow-y: auto;\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "Table_no-text-selection_2Z2vU",
	"noTextSelection": "Table_no-text-selection_2Z2vU",
	"table": "Table_table_1bBt1",
	"dark": "Table_dark_15zRO",
	"scrollable": "Table_scrollable_1DxM4"
};