import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import { IReportingClient } from "@HisPlatform/BoundedContexts/Reporting/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import IPerformedServiceData from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/GrossNeakIncomeWidget/IPerformedServiceData";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { IFinanceClient } from "@HisPlatform/BoundedContexts/Finance/Api/Proxy.g";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";

@Di.injectable()
export default class GrossNeakIncomeWidgetApiAdapter extends ApiAdapterBase {
    
    constructor(
        @Di.inject("IReportingClient") private readonly reportingClient: IReportingClient,
        @Di.inject("IFinanceClient") private readonly financeClient: IFinanceClient,
        @Di.inject("StructureApiAdapter") private readonly structureApiAdapter: StructureApiAdapter
    ) {
        super();
    }

    public getAsync(convertedCurrencyCode: string, from: string, to: string, insurerOrganizationCode: string, organizationUnitId: OrganizationUnitId) {
        return this.processOperationAsync(
            new SimpleStore<IPerformedServiceData[]>(),
            async target => {

                const getChildResponse = await this.structureApiAdapter.getChildrenByParentIdAsync(organizationUnitId);
                const children = getChildResponse.value;
                const hasChildren = children.length > 0;

                const getInsurerOrganizationResponse = await this.financeClient.tryGetInsurerOrganizationIdByCodeQueryAsync(
                    CreateRequestId(),
                    insurerOrganizationCode
                );

                if (!getInsurerOrganizationResponse.insurerOrganizationId) {
                    throw new Error("InsurerOrganizationId not found!");
                }

                const insurerOrganizationId = getInsurerOrganizationResponse.insurerOrganizationId.value;

                const response = await this.reportingClient.getPerformedServiceDataQueryAsync(
                    CreateRequestId(),
                    convertedCurrencyCode,
                    from,
                    insurerOrganizationId,
                    hasChildren ? children.map(o => o.value).join(";") : organizationUnitId.value,
                    to
                );
                
                target.operationInfo = createOperationInfo(response);
                target.value = response.performedServiceData.map(s => {
                    return {
                        serviceCode: s.serviceCode,
                        serviceName: s.serviceName,
                        count: s.count,
                        originalCurrencyUnitPrice: s.originalCurrencyUnitPrice,
                        originalCurrencySumPrice: s.originalCurrencySumPrice,
                        convertedCurrencySumPrice: s.convertedCurrencySumPrice
                    } as IPerformedServiceData;
                });
            }
        );
    }
}