import {Flex} from "@CommonControls";
import React from "react";

interface ICombinePatientsListItemProps {
    listItemName: string;
    patientToKeepData: string;
    patientToDeleteData: string;
}

export default function CombinePatientsListItem(props: ICombinePatientsListItemProps) {
    return (
        <Flex>
            <Flex.Item xs={3}>
                {props.listItemName}
            </Flex.Item>
            <Flex.Item xs={3} horizontalContentAlignment={"center"}>
                {props.patientToKeepData}
            </Flex.Item>
            <Flex.Item xs={3} horizontalContentAlignment={"center"}>
                {props.patientToDeleteData}
            </Flex.Item>
        </Flex>
    );
}