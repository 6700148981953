import IPlatformPlugin from "@PluginInterface/IPlatformPlugin";
import { Container } from "inversify";
import IResourceFetcher from "@Toolkit/CommonWeb/Abstractions/Localization/IResourceFetcher";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import IExtensionComponentRegistry from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import { ExtensionPointType } from "@PluginInterface/ExtensionPoint/ExtensionPointType";
import PerformedServiceClassificationColumn from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/PerformedService/PerformedServiceClassificationColumn";
import "@HunSocialSecurityPlugin/BoundedContexts/Finance/DependencyConfiguration/FinanceDependencyConfiguration";
import "@HunSocialSecurityPlugin/BoundedContexts/Care/DependencyConfiguration/CareDependencyConfiguration";
import "@HunSocialSecurityPlugin/BoundedContexts/Reporting/DependencyConfiguration/ReportingDependencyConfiguration";
import "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/DependencyConfiguration/PerformanceStatementDependencyConfiguration";
import "@HunSocialSecurityPlugin/BoundedContexts/Productivity/DependencyConfiguration/ProductivityDependencyConfiguration";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import IPatientDocumentFormattingRegistry from "@PluginInterface/PatientDocumentFormatting/IPatientDocumentFormattingRegistry";
import PerformedServiceFirstRow from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/PerformedService/PerformedServiceFirstRow";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import HunCareDiagnosisListValidationParameterMapper from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/HunCareDiagnosisListValidationParameterMapper";
import HunCarePerformedServiceListValidationParameterMapper from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/HunCarePerformedServiceListValidationParameterMapper";
import ReportingExtension from "./BoundedContexts/Reporting/Extensions/ReportingExtension";
import { loadStaticCareResources } from "@HunSocialSecurityPlugin/BoundedContexts/Care/StaticResources/StaticHunSocialSecurityCareResources";
import IFormExtensionRegistry from "@PluginInterface/FormExtension/IFormExtensionRegistry";
import { loadStaticPerformanceStatementResources } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/StaticResources/StaticHunSocialSecurityPerformanceStatementResources";
import configurePerformanceStatementUseCases from "./UseCases/PerformanceStatementUseCase/PerformanceStatementUseCaseConfiguration";
import IStateChangeBlockingRegistry from "@PluginInterface/BoundedContexts/Care/CareRegister/StateChange/IStateChangeBlockingRegistry";
import configureStateChangeBlockings from "./Configuration/StateChangeBlocking/ConfigureStateChangeBlockings";
import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import configureCarePermissionDefinitions from "@HunSocialSecurityPlugin/PermissionDefinitions/CarePermissionDefinitionConfiguration/CarePermissionDefinitionConfiguration";
import configurePerformanceStatementPermissionDefinitions from "@HunSocialSecurityPlugin/PermissionDefinitions/PerformanceStatementPermissionDefinitionConfiguration/PerformanceStatementPermissionDefinitionConfiguration";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import React from "react";
import PerformanceStatementStatusColumn from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementStatusColumn/PerformanceStatementStatusColumn";
import PerformanceStatementTypeColumn from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementTypeColumn/PerformanceStatementTypeColumn";
import PerformanceStatementCareActivityStatusTypeColumn from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityStatusTypeColumn/PerformanceStatementCareActivityStatusTypeColumn";
import ErrorEntryRecordTypeColumn from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/ErrorEntryRecordTypeColumn/ErrorEntryRecordTypeColumn";
import BatchUpdatePerformanceStatementCareActivityManualNeakStatusAndMonthArgument from "@HunSocialSecurityPlugin/BoundedContexts/Productivity/ApplicationLogic/Model/BatchUpdatePerformanceStatementCareActivityManualNeakStatusAndMonthArgument";
import CareActivityFinanceDataPanel from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/CareActivityFinanceDataPanel/CareActivityFinanceDataPanel";
import CareActivityFinanceDataExtensionComponentService from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/CareActivityFinanceDataPanel/CareActivityFinanceDataExtensionComponentService";
import PerformedServiceExtensionComponentService from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/PerformedService/PerformedServiceExtensionComponentService";
import HeaderCareActivityCoverageDataExtensionComponentService from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/HeaderCareActivityCoverageData/HeaderCareActivityCoverageDataExtensionComponentService";
import HeaderCareActivityCoverageDataPanel from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/HeaderCareActivityCoverageData/HeaderCareActivityCoverageDataPanel";
import ReportingExtensionComponentService from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Extensions/ReportExtensionComponentService";
import DiagnosisAdditionalActionsPanel from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/DiagnosisAdditionalActions/DiagnosisAdditionalActionsPanel";
import DiagnosisAdditionalActionsExtensionComponentService from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/DiagnosisAdditionalActions/DiagnosisAdditionalActionsExtensionComponentService";
import DiagnosisExtensionPanel from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/DiagnosisExtension/DiagnosisExtensionPanel";
import DiagnosisExtensionComponentService from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/DiagnosisExtension/DiagnosisExtensionComponentService";
import ContagiousPatientReportStateColumn from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/ContagiousPatientReportStateColumn/ContagiousPatientReportStateColumn";
import ContagiousPatientReportTypeColumn from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/ContagiousPatientReportTypeColumn/ContagiousPatientReportTypeColumn";
import configureContagiousPatientReportUseCases from "@HunSocialSecurityPlugin/UseCases/ContagiousPatientReportsUseCase/ContagiousPatientReportUseCaseConfiguration";
import ContagiousPatientReportTypeWithOnsetColumn from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/ContagiousPatientReportTypeWithOnsetColumn";
import ContagiousPatientReportOnsetDateWithTypeData from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/ContagiousPatientReportTypeWithOnsetColumn/ContagiousPatientReportTypeWithOnsetData";
import { mapEhiErrors } from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/EhiErrorHelper";
import ShowEhiErrorsClientSideAction from "@HunSocialSecurityPlugin/BoundedContexts/Productivity/ApplicationLogic/Model/ShowEhiErrorsClientSideAction";
import RegionalCareObligationStatusExtensionComponentService from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/RegionalCareObligationStatusExtension/RegionalCareObligationStatusExtensionComponentService";
import RegionalCareObligationStatusExtension from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/RegionalCareObligationStatusExtension/RegionalCareObligationStatusExtension";
import configurePerformanceReportScorePlansUseCases from "@HunSocialSecurityPlugin/UseCases/PerformanceReportScorePlansUseCase/PerformanceReportScorePlansUseCaseConfiguration";
import { loadStaticHunSocialSecurityReportingResources } from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/StaticResources/StaticHunSocialSecurityReportingResources";
import HunSocialSecurityIdentifierWithSourceTypeDisplay from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/PatientRegister/HunSocialSecurityIdentifiertWithSourceTypeDisplay";
import HunSocialSecurityIdentifierWithSourceTypeDisplayExtensionComponentService from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/PatientRegister/HunSocialSecurityIdentifierWithSourceTypeDisplayExtensionComponentService";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import { CareActivityCoverageDataPanelRegistryItem } from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/CareActivityCoverageData/CareActivityCoverageDataPanelFormCustomBlock";
import AccidentTypeId from "@Primitives/AccidentTypeId.g";
import HunFinancingClassId from "@Primitives/HunFinancingClassId.g";
import IdentifierTypeId from "@Primitives/IdentifierTypeId.g";
import { RegionalCareObligationStatusExtensionRegistryItem } from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/RegionalCareObligationStatusExtension/RegionalCareObligationStatusFormCustomBlock";
import InpatientAdmissionTypeId from "@Primitives/InpatientAdmissionTypeId.g";
import DependencyContainer from "@DiContainer";
import HunCareReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/HunCareReferenceDataStore";
import InpatientAdmissionTypeByCareAspectId from "@Primitives/InpatientAdmissionTypeByCareAspectId.g";
import InpatientAdmissionTypeByLegalAspectId from "@Primitives/InpatientAdmissionTypeByLegalAspectId.g";
import StaticHunSocialSecurityFinanceResources, { loadStaticHunSocialSecurityFinanceResources } from "@HunSocialSecurityPlugin/BoundedContexts/Finance/StaticResources/StaticHunSocialSecurityFinanceResources";
import InpatientAdmissionTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/InpatientAdmissionTypeSelectBox/InpatientAdmissionTypeSelectBox";
import InpatientAdmissionTypeByCareAspectSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/InpatientAdmissionTypeByCareAspectSelectBox/InpatientAdmissionTypeByCareAspectSelectBox";
import InpatientAdmissionTypeByLegalAspectSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/InpatientAdmissionTypeByLegalAspectSelectBox/InpatientAdmissionTypeByLegalAspectSelectBox";
import InpatientAdmissionTypeColumn from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/InpatientAdmissionTypeColumn/InpatientAdmissionTypeColumn";
import AdmitPatientAndCareActivityBaseDataScreenFormInpatientBaseFormLogic from "./Packages/Care/FormLogics/AdmitPatientAndCareActivityBaseDataScreenFormInpatientBaseFormLogic";
import DiagnosisListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisListStore";
import PerformedServiceListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/PerformedServiceListStore";
import IFormLogicRegistry from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import InstituteColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/InstituteColumn/InstituteColumn";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import { IEhiErrorDto } from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import IRoutingFrameCaseRegistry from "@HisPlatform/Services/Definition/RoutingFrameCaseRegistry/IRoutingFrameCaseRegistry";
import performanceStatementStandaloneRouteDefinitions from "./UseCases/PerformanceStatementUseCase/PerformanceStatementRoutes";
import CreateEuPerformanceStatementPage from "./BoundedContexts/PerformanceStatement/Pages/CreateEuPerformanceStatementPage";
import CreateNeakPerformanceStatementPage from "./BoundedContexts/PerformanceStatement/Pages/CreateNeakPerformanceStatementPage";
import CreateNeakRevisionPerformanceStatementPage from "./BoundedContexts/PerformanceStatement/Pages/CreateNeakRevisionPerformanceStatementPage";
import ErrorListPage from "./BoundedContexts/PerformanceStatement/Pages/ErrorListPage";
import NeakPerformanceStatementPage from "./BoundedContexts/PerformanceStatement/Pages/NeakPerformanceStatementPage";
import PerformanceStatementCareActivityPage from "./BoundedContexts/PerformanceStatement/Pages/PerformanceStatementCareActivityPage";
import ContagiousPatientReportPage from "./BoundedContexts/Care/Pages/ContagiousPatientReportPage";
import contagiousPatientReportStandaloneRouteDefinitions from "./UseCases/ContagiousPatientReportsUseCase/ContagiousPatientReportRoutes";
import performanceReportScorePlanStandaloneRouteDefinitions from "./UseCases/PerformanceReportScorePlansUseCase/PerformanceReportScorePlanRoutes";
import PerformanceReportScorePage from "./BoundedContexts/Reporting/Pages/PerformanceReportScorePage";
import HunAdmitPatientAndCareActivitiyBaseDataScreenExtension from "./Packages/Care/FormExtensions/HunAdmitPatientAndCareActivitiyBaseDataScreenExtension";
import HunSocialSecurityMedicationRequestValidationParameterMapper from "./BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/HunSocialSecurityMedicationRequestValidationParameterMapper";
import Prescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Prescription";

class HunSocialSecurityPlugin implements IPlatformPlugin {
    public name: string = "HunSocialSecurityPlugin";

    public async configureAsync(container: Container) {
        const applicationContext = container.get<ICurrentCultureProvider>("ICurrentCultureProvider");

        
        const resourceFetcher = container.get<IResourceFetcher>("IResourceFetcher");
        await resourceFetcher.fetchResourceGroupAsync("HunSocialSecurityValidationMessages", "ValidationMessages", applicationContext.cultureCode);
        await resourceFetcher.fetchResourceGroupAsync("HunSocialSecurityReferenceData", "ReferenceData", applicationContext.cultureCode);
        await resourceFetcher.fetchResourceGroupAsync("HunSocialSecurityWebApp", "WebApp", applicationContext.cultureCode);
        await resourceFetcher.fetchResourceGroupAsync("HunSocialSecurityMedicationRequest", "WebApp", applicationContext.cultureCode);

        const validationParameterMapperService = container.get<ValidationProblemParameterMapperService>("ValidationProblemParameterMapperService");
        const diagnosisValidationParameterMapper = container.get<HunCareDiagnosisListValidationParameterMapper>("HunCareDiagnosisListValidationParameterMapper");
        const serviceValidationParameterMapper = container.get<HunCarePerformedServiceListValidationParameterMapper>("HunCarePerformedServiceListValidationParameterMapper");
        const medicationRequestValidationParameterMapper = container.get<HunSocialSecurityMedicationRequestValidationParameterMapper>("HunSocialSecurityMedicationRequestValidationParameterMapper");

        validationParameterMapperService.registerMapper("DiagnosisListStore", diagnosisValidationParameterMapper);
        validationParameterMapperService.registerMapper("PerformedServiceListStore", serviceValidationParameterMapper);
        validationParameterMapperService.registerMapper("Prescription", medicationRequestValidationParameterMapper);

        loadStaticCareResources();
        loadStaticHunSocialSecurityFinanceResources();
        loadStaticPerformanceStatementResources();
        loadStaticHunSocialSecurityReportingResources();

        container.bind("AdmitPatientAndCareActivityBaseDataScreenFormInpatientBaseFormLogic")
            .to(AdmitPatientAndCareActivityBaseDataScreenFormInpatientBaseFormLogic);

        container.bind("HunAdmitPatientAndCareActivitiyBaseDataScreenExtension").to(HunAdmitPatientAndCareActivitiyBaseDataScreenExtension);

        this.configureExtensionComponentServices(container);
    }

    public configureExtensionComponents(registry: IExtensionComponentRegistry<ExtensionPointType>) {
        registry.register("careActivityDischargeData", CareActivityFinanceDataPanel, "CareActivityFinanceDataExtensionComponentService");
        registry.register("performedService", PerformedServiceClassificationColumn, "PerformedServiceExtensionComponentService");
        registry.register("performedServiceFirstRow", PerformedServiceFirstRow, "PerformedServiceExtensionComponentService");
        registry.register("headerCareActivityCoverageData", HeaderCareActivityCoverageDataPanel, "HeaderCareActivityCoverageDataExtensionComponentService");
        registry.register("identifierWithSourceTypeDisplay", HunSocialSecurityIdentifierWithSourceTypeDisplay, "HunSocialSecurityIdentifierWithSourceTypeDisplayExtensionComponentService");
        registry.register("reportingExtension", ReportingExtension, "ReportingExtensionComponentService");
        registry.register("diagnosisAdditionalActions", DiagnosisAdditionalActionsPanel, "HunSocialSecurity" + "DiagnosisAdditionalActionsExtensionComponentService");
        registry.register("diagnosisExtension", DiagnosisExtensionPanel, "HunSocialSecurity" + "DiagnosisExtensionComponentService");
        registry.register("regionalCareObligationStatusExtension", RegionalCareObligationStatusExtension, "RegionalCareObligationStatusExtensionComponentService");
    }

    private configureExtensionComponentServices(container: Container) {
        container.bind("CareActivityFinanceDataExtensionComponentService")
            .to(CareActivityFinanceDataExtensionComponentService);
        container.bind("PerformedServiceExtensionComponentService")
            .to(PerformedServiceExtensionComponentService);
        container.bind("HeaderCareActivityCoverageDataExtensionComponentService")
            .to(HeaderCareActivityCoverageDataExtensionComponentService);
        container.bind("ReportingExtensionComponentService")
            .to(ReportingExtensionComponentService);
        container.bind("HunSocialSecurity" + "DiagnosisAdditionalActionsExtensionComponentService")
            .to(DiagnosisAdditionalActionsExtensionComponentService);
        container.bind("HunSocialSecurity" + "DiagnosisExtensionComponentService")
            .to(DiagnosisExtensionComponentService);
        container.bind("RegionalCareObligationStatusExtensionComponentService")
            .to(RegionalCareObligationStatusExtensionComponentService);
        container.bind("HunSocialSecurityIdentifierWithSourceTypeDisplayExtensionComponentService")
            .to(HunSocialSecurityIdentifierWithSourceTypeDisplayExtensionComponentService);
    }

    public configureUseCases(useCaseRegistry: IUseCaseRegistry): void {
        configurePerformanceStatementUseCases(useCaseRegistry);
        configureContagiousPatientReportUseCases(useCaseRegistry);
        configurePerformanceReportScorePlansUseCases(useCaseRegistry);
    }

    public configureStateChangeBlockings(stateChangeBlockingRegistry: IStateChangeBlockingRegistry) {
        configureStateChangeBlockings(stateChangeBlockingRegistry);
    }

    public configurePatientDocumentFormattings(patientDocumentFormattingRegistry: IPatientDocumentFormattingRegistry): void {
        patientDocumentFormattingRegistry.register(
            ["HunSocialSecurityNumber", "ReplacementHunSocialSecurityNumber", "NewbornHunSocialSecurityNumber"],
            /(\d{3})(\d{3})(\d{3})/,
            "$1 $2 $3"
        );
    }

    public configureFormExtensions(formExtensionRegistry: IFormExtensionRegistry): void {
        formExtensionRegistry.register("AdmitPatientAndCareActivityBaseDataScreen", "HunAdmitPatientAndCareActivitiyBaseDataScreenExtension");
    }

    public configureFormLogics(formLogicRegistry: IFormLogicRegistry): void {
        formLogicRegistry.register("AdmitPatientAndCareActivityBaseDataScreenForm_Inpatient",
            "AdmitPatientAndCareActivityBaseDataScreenFormInpatientBaseFormLogic");
    }

    public configurePermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry): void {
        configureCarePermissionDefinitions(permissionDefinitionRegistry);
        configurePerformanceStatementPermissionDefinitions(permissionDefinitionRegistry);
    }

    public configureActivities(activityRegistry: IActivityRegistry) {
        activityRegistry.register({ reference: "PerformanceStatement_SelectPerformanceStatementForCreation", iconName: "plus" });
        activityRegistry.register({ reference: "PerformanceStatement_DeleteNotFinalizedPerformanceStatements", iconName: "remove" });
        activityRegistry.register({ reference: "PerformanceStatement_CloseCareActivities" });
        activityRegistry.register({ reference: "ContagiousPatientReport_Modify", iconName: "pen" });
        activityRegistry.register({ reference: "AddPerformanceReportScorePlan", iconName: "plus" });

        return;
    }

    public configureReferenceDataLoaders(referenceDataLoader: IReferenceDataLoader) {
        referenceDataLoader.register<InpatientAdmissionTypeId>("InpatientAdmissionTypeId", async () => {
            const careReferenceDataStore: HunCareReferenceDataStore = DependencyContainer.get("HunCareReferenceDataStore");
            await careReferenceDataStore.inpatientAdmissionTypeMap.ensureLoadedAsync();
        });
    }

    public configureNData(configurator: INDataAttributeConfigurator): void {

        configurator.registerValueConverter("InstituteDto", rawValue => rawValue.OrganizationUnitId && new OrganizationUnitId(rawValue.OrganizationUnitId.Value.toString()));

        configurator.registerColumn("InPatientAdmissionTypeId", <InpatientAdmissionTypeColumn />);
        configurator.registerColumn("InstituteDto", <InstituteColumn />);

        this.configureNDataForPerformanceStatement(configurator);
        this.configureContagiousPatientReport(configurator);
    }

    private configureNDataForPerformanceStatement(configurator: INDataAttributeConfigurator) {
        configurator.registerColumn("PerformanceStatementStatus", <PerformanceStatementStatusColumn displayMode="name" />);
        configurator.registerColumn("PerformanceStatementType", <PerformanceStatementTypeColumn displayMode="name" />);
        configurator.registerColumn("PerformanceStatementCareActivityStatusType", <PerformanceStatementCareActivityStatusTypeColumn displayMode="badge" />);
        configurator.registerColumn("ErrorEntryRecordType", <ErrorEntryRecordTypeColumn />);
    }

    private configureContagiousPatientReport(configurator: INDataAttributeConfigurator) {
        configurator.registerColumn("ContagiousPatientReportState", <ContagiousPatientReportStateColumn displayMode="name" />);
        configurator.registerColumn("ContagiousPatientReportType", <ContagiousPatientReportTypeColumn displayMode="name" />);
        configurator.registerColumn("ContagiousPatientReportOnsetDateWithTypeDto", <ContagiousPatientReportTypeWithOnsetColumn />);

        configurator.registerValueConverter("ContagiousPatientReportOnsetDateWithTypeDto", (rawValue: any) => {
            return new ContagiousPatientReportOnsetDateWithTypeData(rawValue.Type, rawValue.OnsetDate);
        });
    }

    public configureMapper(configurator: IMapperConfigurator): void {
        configurator.register<BatchUpdatePerformanceStatementCareActivityManualNeakStatusAndMonthArgument, object>(
            BatchUpdatePerformanceStatementCareActivityManualNeakStatusAndMonthArgument,
            source => ({
                toJSON: () => ({
                    ReportValidityDate: source.newValidity.toJSON(),
                    Type: source.newType,
                    _discriminator: "BatchUpdatePerformanceStatementCareActivityManualNeakStatusAndMonthArgumentDto"
                })
            }));

        configurator.registerByName("ShowEhiErrorsClientSideActionDto", (rawClientSideAction: any) => {
            const ehiErrors = rawClientSideAction.EhiErrors.map((i: any) => {
                return {
                    ehiErrorSeverity: i.EhiErrorSeverity,
                    isGatewayError: i.IsGatewayError,
                    isTechnical: i.IsTechnical,
                    code: i.Code,
                    message: i.Message,
                    moduleName: i.ModuleName
                } as IEhiErrorDto;
            });
            return new ShowEhiErrorsClientSideAction(mapEhiErrors(ehiErrors));
        });
    }

    public configureFormEngine(registry: FormEditorRegistry): void {
        registry.registerCustomBlock("HunCareActivityCoverageDataPanel", CareActivityCoverageDataPanelRegistryItem);
        registry.registerCustomBlock("RegionalCareObligationStatusExtension", RegionalCareObligationStatusExtensionRegistryItem);

        registry.registerExtensibleEnumEditor(
            "AccidentType",
            {
                displayName: "AccidentType - DO NOT USE",
                componentType: null,
                entityIdCtor: AccidentTypeId,
                loadReferenceDataAsync: async () => { }
            }
        );

        registry.registerExtensibleEnumEditor(
            "HunFinancingClass",
            {
                displayName: "HunFinancingClass - DO NOT USE",
                componentType: null,
                entityIdCtor: HunFinancingClassId,
                loadReferenceDataAsync: async () => { }
            }
        );

        registry.registerExtensibleEnumEditor(
            "IdentifierType",
            {
                displayName: "IdentifierType - DO NOT USE",
                componentType: null,
                entityIdCtor: IdentifierTypeId,
                loadReferenceDataAsync: async () => { }
            }
        );

        registry.registerExtensibleEnumEditor(
            "InpatientAdmissionType",
            {
                displayName: StaticHunSocialSecurityFinanceResources.AdmissionData.Label.InPatientAdmissionType,
                componentType: InpatientAdmissionTypeSelectBox,
                entityIdCtor: InpatientAdmissionTypeId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<HunCareReferenceDataStore>("HunCareReferenceDataStore").inpatientAdmissionTypeMap.ensureLoadedAsync();
                    return Promise.resolve();
                }
            }
        );

        registry.registerExtensibleEnumEditor(
            "InpatientAdmissionTypeByCareAspect",
            {
                displayName: StaticHunSocialSecurityFinanceResources.AdmissionData.Label.InPatientAdmissionTypeByCareAspect,
                componentType: InpatientAdmissionTypeByCareAspectSelectBox,
                entityIdCtor: InpatientAdmissionTypeByCareAspectId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<HunCareReferenceDataStore>("HunCareReferenceDataStore").inpatientAdmissionTypeByCareAspectMap.ensureLoadedAsync();
                    return Promise.resolve();
                }
            }
        );

        registry.registerExtensibleEnumEditor(
            "InpatientAdmissionTypeByLegalAspect",
            {
                displayName: StaticHunSocialSecurityFinanceResources.AdmissionData.Label.InPatientAdmissionTypeByLegalAspect,
                componentType: InpatientAdmissionTypeByLegalAspectSelectBox,
                entityIdCtor: InpatientAdmissionTypeByLegalAspectId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<HunCareReferenceDataStore>("HunCareReferenceDataStore").inpatientAdmissionTypeByCareAspectMap.ensureLoadedAsync();
                    return Promise.resolve();
                }
            }
        );
    }

    public configureRoutingFrameCases(registry: IRoutingFrameCaseRegistry) {
        registry.register("Application", CreateEuPerformanceStatementPage, performanceStatementStandaloneRouteDefinitions.createEuPerformanceStatement, "createEuPerformanceStatement");
        registry.register("Application", CreateNeakPerformanceStatementPage, performanceStatementStandaloneRouteDefinitions.createNeakPerformanceStatement, "createNeakPerformanceStatement");
        registry.register("Application", CreateNeakRevisionPerformanceStatementPage, performanceStatementStandaloneRouteDefinitions.createNeakRevisionPerformanceStatement, "createNeakRevisionPerformanceStatement");
        registry.register("Application", ErrorListPage, performanceStatementStandaloneRouteDefinitions.errorList, "errorList");
        registry.register("Application", NeakPerformanceStatementPage, performanceStatementStandaloneRouteDefinitions.neakPerformanceStatement, "neakPerformanceStatement");
        registry.register("Application", PerformanceStatementCareActivityPage, performanceStatementStandaloneRouteDefinitions.performanceStatementCareActivity, "performanceStatementCareActivity");
        registry.register("Application", ContagiousPatientReportPage, contagiousPatientReportStandaloneRouteDefinitions.contagiousPatientReports, "contagiousPatientReports");
        registry.register("Application", PerformanceReportScorePage, performanceReportScorePlanStandaloneRouteDefinitions.performanceReportScorePlan, "performanceReportScorePlan");
    }
}

export default new HunSocialSecurityPlugin();
