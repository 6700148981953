import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";
import IFormEngineResources from "./IFormEngineResources";
import FormEngineValidationProblemMessages from "@Toolkit/FormEngine/StaticResources/FormEngineValidationProblemMessages";


const StaticFormEditingResources = createStaticResourceDictionary<IFormEngineResources>();

export function loadStaticFormEngineResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    StaticFormEditingResources.load(localizationService.getResourceGroup("WebApp").FormEngine);

    FormEngineValidationProblemMessages.load(StaticFormEditingResources.FormValidationProblemMessages);
}

export default StaticFormEditingResources;