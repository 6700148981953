import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

export default class EntityVersionSelector<TId extends IStringEntityId> implements IEntityVersionSelector<TId> {
    constructor(
        public readonly id: TId,
        public readonly validOn: LocalDate,
    ) {}

    public static areEquals<TId>(left: IEntityVersionSelector<TId>, right: IEntityVersionSelector<TId>) {
        if (isNullOrUndefined(left) && isNullOrUndefined(right)) {
            return true;
        }

        if (!isNullOrUndefined(left) && !isNullOrUndefined(right)) {
            return ValueWrapper.equals(left.id as any, right.id as any) && LocalDate.areEquals(left.validOn, right.validOn);
        }

        return false;
    }

    public static areIdEquals<TId>(left: IEntityVersionSelector<TId>, right: IEntityVersionSelector<TId>) {
        if (isNullOrUndefined(left) && isNullOrUndefined(right)) {
            return true;
        }

        if (!isNullOrUndefined(left) && !isNullOrUndefined(right)) {
            return ValueWrapper.equals(left.id as any, right.id as any);
        }

        return false;
    }

    public getHashCode() {
        return `${this.id?.value ?? "NULL"}--${this.validOn?.stringify() ?? "NULL"}`;
    }

    public static getHashCode<TId extends IStringEntityId>(versionSelector: IEntityVersionSelector<TId>) {
        return `${versionSelector?.id?.value ?? "NULL"}--${versionSelector?.validOn?.stringify() ?? "NULL"}`;
    }

    public toUrl() {
        return this.getHashCode();
    }

    public static parseFromUrl<TId extends IStringEntityId>(value: string, entityId: new (value: any) => TId) {
        if (!value) {
            return null;
        }
        
        const parts = value.split("--");

        if (parts.length !== 2) {
            throw new Error(`Cannot parse EntityVersionSelector from url: '${value}'`);
        }

        return new EntityVersionSelector(new entityId(parts[0]), LocalDate.parse(parts[1]));
    }

    public withId(newId: TId) {
        return new EntityVersionSelector<TId>(newId, this.validOn);
    }

    public withValidOn(newValue: LocalDate) {
        return new EntityVersionSelector<TId>(this.id, newValue);
    }
}