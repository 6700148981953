import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Styles from "./ContagiousPatientReportFilter.less";
import OrganizationSelector from "@HisPlatform/BoundedContexts/Organization/Components/OrganizationSelector";
import ContagiousPatientReportFilterStore from "./ContagiousPatientReportFilterStore";
import StaticHunSocialSecurityCareResources from "@HunSocialSecurityPlugin/BoundedContexts/Care/StaticResources/StaticHunSocialSecurityCareResources";

interface IContagiousPatientReportFilterProps {
    filterStore: ContagiousPatientReportFilterStore;
    isVisible: boolean;
}


const ContagiousPatientReportFilter: React.FC<IContagiousPatientReportFilterProps> = (props) => (
    <>
        <div className={Styles.outerContainer}>
            <Ui.Flex>
                <Ui.Flex.Item xs={12} className={Styles.searchItem}>
                    <OrganizationSelector
                        value={props.filterStore && props.filterStore.pointOfCareIds}
                        onChange={props.filterStore && props.filterStore.onPointOfCareChange}
                        pointOfCareMode={true}
                        automationId="organization"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item>
                    <Ui.CheckBox
                        displayMode={"switch"}
                        value={props.filterStore.onlyItemsWithoutEndReports}
                        onChange={props.filterStore.setOnlyItemsWithoutEndReports}
                        label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.OnlyItemsWithoutEndReports}
                        automationId="onlyItemsWithoutEndReportsCheckBox"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </div>
    </>
);

export default State.observer(ContagiousPatientReportFilter);
