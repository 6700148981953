import React from "react";
import PractitionerId from "@Primitives/PractitionerId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import moment from "moment";
import * as Ui from "@CommonControls";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import PractitionerAppointmentsSchedule from "./PractitionerAppointmentsSchedule";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import AppointmentSchedulerEntryTooltip from "@HisPlatform/BoundedContexts/Scheduling/Components/Controls/AppointmentSchedulerEntryTooltip/AppointmentSchedulerEntryTooltip";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import PatientApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/Patient/PatientApiAdapter";
import NameStore from "@Primitives/NameStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import ResourceBlocking from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ResourceBlocking";
import ResourceBlockingEditorDialogParams, { IResourceBlockingEditorDialogResult } from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/ResourceBlockingEditorDialog/ResourceBlockingEditorDialogParams";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import ResourceManagementApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/ResourceManagementApiAdapter";
import ResourceBlockingTooltip from "@HisPlatform/BoundedContexts/Scheduling/Components/Controls/ResourceBlockingTooltip/ResourceBlockingTooltip";
import _ from "@HisPlatform/Common/Lodash";
import PatientId from "@Primitives/PatientId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import ServiceRequestManagementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestManagementApiAdapter";
import NDataGrid from "@HisPlatform/BoundedContexts/Productivity/Components/NDataGrid/NDataGrid";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import PractitionerScheduleEntryFilterStore from "./PractitionerScheduleEntryFilterStore";
import SchedulerScale from "@CommonControls/Scheduler/SchedulerScale";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ServiceRequestAppointmentWarningDialogParams, { IServiceRequestAppointmentWarningDialogResult } from "./ServiceRequestAppointmentWarningDialog/ServiceRequestAppointmentWarningDialogParams";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import { RowId } from "@CommonControls/DataGrid/IDataGridProps";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import IUseCaseNavigatorParameters from "@PluginInterface/UseCases/IUseCaseNavigatorParameters";
import Route from "@Toolkit/ReactClient/Routing/Abstractions/Route";
import UseCases from "@Primitives/UseCases";
import ResourceBlockingId from "@Primitives/ResourceBlockingId.g";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import { ISchedulerEvent } from "@CommonControls/Scheduler/ISchedulerProps";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IPractitionerAppointmentsPanelDependencies {
    schedulingApiAdapter: SchedulingApiAdapter;
    resourceManagementApiAdapter: ResourceManagementApiAdapter;
    serviceRequestManagementApiAdapter: ServiceRequestManagementApiAdapter;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
    localizationService: ILocalizationService;
    patientApiAdapter: PatientApiAdapter;
    notificationService: INotificationService;
    worklistApiAdapter: WorklistApiAdapter;
    useCaseRegistry: IUseCaseRegistry;
}

interface IPractitionerAppointmentsPanelProps {
    _dependencies?: IPractitionerAppointmentsPanelDependencies;
    _modalService?: IModalService;
    practitionerId?: PractitionerId;
    onPractitionerIdChanged: (newValue: PractitionerId) => void;
    onEditRegisteredPatientAppointment: (patientId: PatientId, careActivityId: CareActivityId, appointmentId: AppointmentScheduleEntryId) => void;
    onEditUnegisteredPatientAppointment: (appointmentId: AppointmentScheduleEntryId) => void;
    onOpenRegisteredPatientServiceRequest: (serviceReqestId: ServiceRequestId, careActivityId: CareActivityId) => void;
    navigate: (useCase: UseCaseIdentifier, useCaseArguments: UseCaseArgument[], displayMode: UseCaseDisplayMode, routeFactory: (params: IUseCaseNavigatorParameters) => Route) => void;
}

/** @screen */
@State.observer
class PractitionerAppointmentsPanel extends React.Component<IPractitionerAppointmentsPanelProps> {

    @State.observable private selectedMoment: moment.Moment = DateTimeService.now();
    @State.observable private practitionersWithAppointments: PractitionerId[] = [];
    @State.observable.ref private patientAppointmentsMap: Map<string, string> = new Map<string, string>();
    @State.observable.ref private appointmentsForPractitioner: Appointment[] = [];
    @State.observable.ref private blockingsForPractitioner: ResourceBlocking[] = [];
    @State.observable private isLoading: boolean = false;
    @State.observable private definition: IWorklistDefinition = null;
    @State.observable.ref private filterStore: PractitionerScheduleEntryFilterStore = null;
    @State.observable.ref private selectedSchedulerScale: SchedulerScale = SchedulerScale.Week;
    @State.observable.ref private selectedMonth: LocalDate = LocalDate.today();

    @State.computed private get listView() {
        return this.renderListView();
    }

    @State.bound
    private openEntryDetails(event: ISchedulerEvent) {
        if (event.id > 0) {
            const appointment = this.appointmentsForPractitioner.find(a => parseInt(a.id.value, 10) === event.id);

            if (appointment.patientId.value === "unregistered") {
                this.props.onEditUnegisteredPatientAppointment(appointment.id);
            } else if (!!appointment.serviceRequestId) {
                // dispatchAsyncErrors(this.openRegisteredPatientServiceRequestAsync(patientId, serviceReqestId.id), this); // TODO open request in modal after HISP-7602 is done
                dispatchAsyncErrors(this.showServiceRequestMessageAsync(appointment.patientId, appointment.serviceRequestId), this);
            } else {
                const careActivityId = appointment.careActivityId;
                this.props.onEditRegisteredPatientAppointment(appointment.patientId, careActivityId, appointment.id);
            }
        } else {
            const resourceBlocking = this.blockingsForPractitioner.find(i => parseInt(i.id.value, 10) === -event.id);

            dispatchAsyncErrors(this.editResourceBlockingAsync(resourceBlocking), this);
        }
    }

    private async showServiceRequestMessageAsync(patientId: PatientId, serviceRequestId: IEntityVersionSelector<ServiceRequestId>) {
        const dialogResult = await this.props._modalService.showDialogAsync<IServiceRequestAppointmentWarningDialogResult>(
            new ServiceRequestAppointmentWarningDialogParams(patientId, serviceRequestId));
    }

    // TODO open request in modal after HISP-7602 is done
    private async openRegisteredPatientServiceRequestAsync(patientId: PatientId, serviceRequestId: ServiceRequestId) {
        const patientServiceRequests = await this.props._dependencies.serviceRequestManagementApiAdapter.getServiceRequestsForPatientAsync(patientId, null);
        const serviceRequest = patientServiceRequests.items.find(i => i.id.value === serviceRequestId.value);
        this.props.onOpenRegisteredPatientServiceRequest(serviceRequestId, serviceRequest.starterCareActivityId);
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(
        this.loadAsync,
        this.props._dependencies.schedulingApiAdapter.getAppointmentsForPractitionerPermissionCheckAsync,
        this.props._dependencies.resourceManagementApiAdapter.getResourceBlockingsForPractitionerPermissionCheckAsync
    );

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IPractitionerAppointmentsPanelProps) {
        if (!ValueWrapper.equals(prevProps.practitionerId, this.props.practitionerId)) {
            dispatchAsyncErrors(this.loadAppointmentsAsync(), this);
        }
    }

    @State.action.bound
    private async loadAsync() {
        this.isLoading = true;

        const appointmentSchedules = await this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleSlotSeries.loadAllIdsAsync();
        await this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleSlotSeries.ensureAllLoadedAsync();

        const practitionerIds: PractitionerId[] = [];

        appointmentSchedules.value.forEach((item) => {
            const schedule = this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleSlotSeries.get(item);
            if (!isNullOrUndefined(schedule) && schedule.practitionerParticipants?.length) {
                practitionerIds.push(...schedule.practitionerParticipants);
            }
        });

        State.runInAction(() => {
            this.practitionersWithAppointments = practitionerIds;
        });

        await this.loadAppointmentsAsync();
        await this.loadDefinitionAsync();
    }

    @State.action.bound
    private async loadDefinitionAsync() {
        const response = await this.props._dependencies.worklistApiAdapter.getPractitionerScheduleEntryBoundWorklistDefinition();
        State.runInAction(() => {
            this.definition = response.value;

        });
    }

    @State.action.bound
    private async loadAppointmentsAsync() {
        this.isLoading = true;

        await this.loadAppointmentsAndBlockingsForPractitionerAsync();
        this.loadPatients();

        State.runInAction(() => {
            this.isLoading = false;
        });
    }

    @State.action.bound
    private async loadAppointmentsAndBlockingsForPractitionerAsync() {
        if (!this.props.practitionerId || !this.props.practitionerId.value) {
            return;
        }

        const dateRange = new LocalDateRange(LocalDate.createFromMoment(this.selectedMoment.clone().startOf("year")), LocalDate.createFromMoment(this.selectedMoment.clone().endOf("year")));

        const appointments =
            await this.props._dependencies.schedulingApiAdapter.getAppointmentsForPractitionerAsync(this.props.practitionerId, dateRange);

        await this.props._dependencies.organizationReferenceDataStore.pointOfCareMap.ensureLoadedAsync(appointments.value.map(a => a.pointOfCareId));

        const schedulingServicesToLoad = appointments.value.filter(a => !!a.schedulingServiceId).map(a => a.schedulingServiceId);
        await this.props._dependencies.schedulingReferenceDataStore.schedulingServices.ensureLoadedAsync(schedulingServicesToLoad);

        await this.props._dependencies.organizationReferenceDataStore.doctorMap.ensureLoadedAsync([this.props.practitionerId]);

        const blockings =
            await this.props._dependencies.resourceManagementApiAdapter.getResourceBlockingsForPractitionerAsync(this.props.practitionerId, dateRange);

        const itemsToSort = appointments.value.map(i => {
            return { from: i.intervalFrom, to: i.intervalTo };
        }).concat(blockings.value.map(i => {
            return { from: i.dateWithTimeRange.toMomentRange.from, to: i.dateWithTimeRange.toMomentRange.to };
        }));

        const sortedItems = itemsToSort.sort((a, b) => {
            return a.to.unix() - b.from.unix();
        });

        State.runInAction(() => {
            this.appointmentsForPractitioner = appointments.value;
            this.blockingsForPractitioner = blockings.value;

            this.selectedMoment = sortedItems[0]?.from ?? DateTimeService.now();
        });
    }

    @State.action.bound
    private loadPatients() {
        const res = new Map<string, string>();
        if (!this.appointmentsForPractitioner) {
            return;
        }

        for (const appointment of this.appointmentsForPractitioner) {
            let patientNameStore: NameStore = null;
            if (appointment.patientName) {
                patientNameStore = appointment.patientName;
            }

            const patientName = patientNameStore ? this.props._dependencies.localizationService.localizePersonName(patientNameStore) : "";

            res.set(appointment.id.value, patientName);
        }

        State.runInAction(() => {
            this.patientAppointmentsMap = res;
        });
    }

    @State.bound
    private async addResourceBlockingAsync() {
        if (!this.props.practitionerId) {
            this.props._dependencies.notificationService.error(StaticSchedulingResources.PractitionerAppointments.SelectPractitioner);
            return;
        }

        const resourceBlocking = new ResourceBlocking(true);
        resourceBlocking.setResource(this.props.practitionerId);
        await this.editResourceBlockingAsync(resourceBlocking);
    }

    @State.bound
    private async editResourceBlockingAsync(resourceBlocking: ResourceBlocking) {
        const clone = new ResourceBlocking(resourceBlocking.isNew);
        clone.id = resourceBlocking.id;
        clone.dateWithTimeRange = _.clone(resourceBlocking.dateWithTimeRange);
        clone.description = resourceBlocking.description;
        clone.rowVersion = resourceBlocking.rowVersion;
        clone.resource = resourceBlocking.resource;

        const result = await this.props._modalService.showDialogAsync<IResourceBlockingEditorDialogResult>(
            new ResourceBlockingEditorDialogParams(clone)
        );

        if (result.resourceBlocking) {
            this.props._dependencies.notificationService.showSaveResult(true);
            await this.loadAsync();
        }
    }

    @State.computed
    private get events() {
        return this.appointmentsForPractitioner.map(a => {
            return {
                id: parseInt(a.id.value, 10),
                startTime: a.intervalFrom,
                endTime: a.intervalTo
            } as ISchedulerEvent;
        }).concat(this.blockingsForPractitioner.map(a => {
            return {
                id: -parseInt(a.id.value, 10),
                startTime: a.dateWithTimeRange.toMomentRange.from,
                endTime: a.dateWithTimeRange.toMomentRange.to
            } as ISchedulerEvent;
        }));
    }

    @State.action.bound
    private onFilterStoreCreatedAsync(filterStore: IFilterStore) {
        this.filterStore = new PractitionerScheduleEntryFilterStore(filterStore);

        this.filterStore.setPractitionerId(this.props.practitionerId);
        this.calculateDateIntervalAndSetFilter();

        return Promise.resolve();
    }

    @State.action.bound
    private navigateToUseCase(rowId: RowId, useCaseState: INDataUseCaseState) {
        if (!!useCaseState) {
            switch (useCaseState.useCase.value) {
                case UseCases.editAppointmentFromPractitionerList:

                    const useCaseAppointmentScheduleEntryId = parseInt((useCaseState.useCaseArguments[0].value as AppointmentScheduleEntryId).value, 10);
                    const appointment = this.appointmentsForPractitioner.find(a => parseInt(a.id.value, 10) === useCaseAppointmentScheduleEntryId);

                    if (appointment.patientId.value === "unregistered") {
                        this.props.onEditUnegisteredPatientAppointment(appointment.id);
                    } else if (!!appointment.serviceRequestId) {
                        // dispatchAsyncErrors(this.openRegisteredPatientServiceRequestAsync(patientId, serviceReqestId.id), this); // TODO open request in modal after HISP-7602 is done
                        dispatchAsyncErrors(this.showServiceRequestMessageAsync(appointment.patientId, appointment.serviceRequestId), this);
                    } else {
                        this.props.onEditRegisteredPatientAppointment(appointment.patientId, appointment.careActivityId, appointment.id);
                    }
                    break;
                case UseCases.editResourceBlockingFromList:

                    const useCaseResourceBlockingId = parseInt((useCaseState.useCaseArguments[0].value as ResourceBlockingId).value, 10);
                    const resourceBlocking = this.blockingsForPractitioner.find(i => parseInt(i.id.value, 10) === useCaseResourceBlockingId);

                    dispatchAsyncErrors(this.editResourceBlockingAsync(resourceBlocking), this);
                    break;
                default: throw new Error("Unrecognized use case.");
            }
        }
    }

    @State.bound
    private getExtendedFilterDescriptors() {
        return PractitionerScheduleEntryFilterStore.getFilterDescriptors();
    }

    @State.bound private setSelectedMoment(value: moment.Moment) {
        this.selectedMoment = value;

        this.calculateDateIntervalAndSetFilter();
    }

    @State.bound private onScheduleScaleChanged(scale: SchedulerScale) {
        this.selectedSchedulerScale = scale;

        this.calculateDateIntervalAndSetFilter();
    }

    @State.bound private onSelectedMonthChange(localDate: LocalDate) {
        this.selectedMonth = localDate;

        this.calculateDateIntervalAndSetFilter();
    }

    @State.bound private calculateDateIntervalAndSetFilter() {
        if (this.selectedSchedulerScale === SchedulerScale.Week) {
            const weekStart = this.selectedMoment.clone().startOf("isoWeek");
            const weekEnd = this.selectedMoment.clone().endOf("isoWeek");

            this.setDateIntervalFilter(weekStart, weekEnd);
        } else if (this.selectedSchedulerScale === SchedulerScale.Month) {
            const monthStart = this.selectedMonth.toUtcDayStartMoment().startOf("month");
            const monthEnd = this.selectedMonth.toUtcDayStartMoment().endOf("month");

            this.setDateIntervalFilter(monthStart, monthEnd);
        } else if (this.selectedSchedulerScale === SchedulerScale.Day) {
            const start = this.selectedMoment.clone().startOf("day");
            const end = this.selectedMoment.clone().endOf("day");

            this.setDateIntervalFilter(start, end);
        } else if (this.selectedSchedulerScale === SchedulerScale.Year) {
            const yearStart = this.selectedMonth.toUtcDayStartMoment().startOf("year");
            const yearEnd = this.selectedMonth.toUtcDayStartMoment().endOf("year");

            this.setDateIntervalFilter(yearStart, yearEnd);
        }
    }

    @State.bound private setDateIntervalFilter(start: moment.Moment, end: moment.Moment) {
        this.filterStore.setDateInterval(new LocalDateRange(LocalDate.createFromMoment(start), LocalDate.createFromMoment(end)));
    }

    @State.action.bound private renderEntryTooltip(entry: ISchedulerEvent) {
        if (entry.id > 0) {
            const appointment = this.appointmentsForPractitioner.filter(a => a.id.value === entry.id.toString())[0];
            const patientName = this.patientAppointmentsMap.get(entry.id.toString());
            return (
                <AppointmentSchedulerEntryTooltip
                    patientName={patientName}
                    practitionerIds={appointment.practitionerIds}
                    pointOfCareId={appointment.pointOfCareId}
                    schedulingServiceId={appointment.schedulingServiceId}
                    description={appointment.description}
                    appointmentInterval={appointment.interval}
                />
            );
        } else {
            const resourceBlocking = this.blockingsForPractitioner.find(i => (-(entry.id)).toString() === i.id.value);
            return (<ResourceBlockingTooltip resourceBlocking={resourceBlocking} />);
        }
    }

    @State.bound private renderAdditionalData(id: number) {
        if (id > 0) {
            return this.patientAppointmentsMap.get(id.toString());
        } else {
            const resourceBlocking = this.blockingsForPractitioner.find(i => (-id).toString() === i.id.value);
            return resourceBlocking?.description;
        }

    }

    @State.bound
    private renderListView() {
        return (
            <NDataGrid
                definition={this.definition}
                onGetExtendedFilterDescriptors={this.getExtendedFilterDescriptors}
                onFilterStoreCreatedAsync={this.onFilterStoreCreatedAsync}
                onChange={this.navigateToUseCase}
                hasRefreshButton />
        );
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticSchedulingResources.PractitionerAppointments.PractitionerCalendars} />;
        }

        return (
            <>
                <Ui.PageBox fullHeight>
                    <Ui.PageBox.Header toolbar="right" >
                        <Ui.HeaderWithIcon
                            title={StaticSchedulingResources.PractitionerAppointments.PractitionerCalendars}
                            iconName="appointments"
                        />
                        <div>
                            {this.renderAddResourceBlockingButton()}
                        </div>
                    </Ui.PageBox.Header>
                    <Ui.PageBox.Body>
                        <>
                            {!this.isLoading && <PractitionerAppointmentsSchedule
                                currentMoment={this.selectedMoment}
                                listView={this.listView}
                                newEventDuration={30}
                                onEditEntry={this.openEntryDetails}
                                events={this.events}
                                setSelectedMoment={this.setSelectedMoment}
                                onScheduleScaleChanged={this.onScheduleScaleChanged}
                                onSelectedMonthChange={this.onSelectedMonthChange}
                                onPlaceNewEntry={null}
                                onRenderTooltip={this.renderEntryTooltip}
                                onPractitionerIdChanged={this.props.onPractitionerIdChanged}
                                practitionerId={this.props.practitionerId}
                                explicitPractitionerIds={this.practitionersWithAppointments}
                                onRenderAdditionalEntryComponent={this.renderAdditionalData}
                            />}
                        </>
                    </Ui.PageBox.Body>
                </Ui.PageBox>
            </>
        );
    }

    private renderAddResourceBlockingButton() {
        return (
            <Ui.Button
                visualStyle="standard"
                iconName="plus"
                onClick={this.addResourceBlockingAsync}
                size="standard"
                automationId="__addResourceBlocking"
                text={StaticSchedulingResources.PractitionerAppointments.AddResourceBlocking}
            />
        );
    }
}

export default connect(
    PractitionerAppointmentsPanel,
    new DependencyAdapter<IPractitionerAppointmentsPanelProps, IPractitionerAppointmentsPanelDependencies>(c => ({
        schedulingApiAdapter: c.resolve("SchedulingApiAdapter"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore"),
        patientApiAdapter: c.resolve("PatientApiAdapter"),
        localizationService: c.resolve("ILocalizationService"),
        notificationService: c.resolve("INotificationService"),
        resourceManagementApiAdapter: c.resolve("ResourceManagementApiAdapter"),
        serviceRequestManagementApiAdapter: c.resolve("ServiceRequestManagementApiAdapter"),
        worklistApiAdapter: c.resolve("WorklistApiAdapter"),
        useCaseRegistry: c.resolve("IUseCaseRegistry")
    })),
    new HisModalServiceAdapter()
);
