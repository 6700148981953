import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import { GetTypeNameWithPrefix } from "@HunEHealthInfrastructurePlugin/PluginHelper";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import ConditionId from "@Primitives/ConditionId.g";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import OncologyRegisterExaminationModeId from "@Primitives/OncologyRegisterExaminationModeId.g";
import OncologyRegisterFurtherTreatmentId from "@Primitives/OncologyRegisterFurtherTreatmentId.g";
import OncologyRegisterTumorStatusId from "@Primitives/OncologyRegisterTumorStatusId.g";
import OncologyRegisterExtensionId from "@Primitives/OncologyRegisterExtensionId.g";
import CancerClassificationDataStore, { ICancerClassificationData } from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CancerClassificationDataStore";
import CancerClassificationId from "@Primitives/CancerClassificationId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import IOncologyDataInfo from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IOncologyDataInfo";
import OncologyDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/OncologyDataStore";
import OncologyDataId from "@Primitives/OncologyDataId.g";
import { buildQueryStringArray } from "@Toolkit/CommonWeb/QueryStringBuilder";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";

/* auto-inject-disable */

@Di.injectable()
export default class OncologyApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("ICareClient")) private apiClient: Proxy.ICareClient
    ) {
        super();
    }

    @State.bound
    public isOncologyDataNeededQueryAsync(conditionId: ConditionId, diagnosisRoleId: DiagnosisRoleId, pointOfCareId: PointOfCareId): Promise<SimpleStore<boolean>> {
        return this.processOperationAsync(
            new SimpleStore<boolean>(),
            async target => {
                const response = await this.apiClient.isOncologyDataNeededQueryAsync(CreateRequestId(), conditionId.value, pointOfCareId.value, diagnosisRoleId.value);
                target.operationInfo = createOperationInfo(response);
                target.value = response.isOncologyDataNeeded;
            }
        );
    }

    @State.bound
    public loadOncologyRegisterExaminationModeIdsAsync(): Promise<SimpleStore<OncologyRegisterExaminationModeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getOncologyRegisterExaminationModeIdsQueryAsync(...args),
            response => response.oncologyRegisterExaminationModeIds
        );
    }

    @State.bound
    public loadOncologyRegisterFurtherTreatmentIdsAsync(): Promise<SimpleStore<OncologyRegisterFurtherTreatmentId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getOncologyRegisterFurtherTreatmentIdsQueryAsync(...args),
            response => response.oncologyRegisterFurtherTreatmentIds
        );
    }

    @State.bound
    public loadOncologyRegisterTumorStatusIdsAsync(): Promise<SimpleStore<OncologyRegisterTumorStatusId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getOncologyRegisterTumorStatusIdsQueryAsync(...args),
            response => response.oncologyRegisterTumorStatusIds
        );
    }

    @State.bound
    public loadOncologyRegisterExtensionIdsAsync(): Promise<SimpleStore<OncologyRegisterExtensionId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getOncologyRegisterExtensionIdsQueryAsync(...args),
            response => response.oncologyRegisterExtensionIds
        );
    }

    @State.bound
    public loadOncologyRegisterLateralityIdsAsync(): Promise<SimpleStore<OncologyRegisterExtensionId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getOncologyRegisterLateralityIdsQueryAsync(...args),
            response => response.oncologyRegisterLateralityIds
        );
    }

    @State.bound
    public getCancerClassificationIdByConditionAsync(conditionId: ConditionId): Promise<SimpleStore<CancerClassificationId>> {
        return this.processOperationAsync(
            new SimpleStore<CancerClassificationId>(),
            async target => {
                const response = await this.apiClient.getCancerClassificationIdByConditionQueryAsync(CreateRequestId(), conditionId.value);
                target.operationInfo = createOperationInfo(response);
                target.value = response.cancerClassificationId;
            }
        );
    }

    @State.bound
    public getAllCancerClassificationIdsAsync(): Promise<SimpleStore<CancerClassificationId[]>> {
        return this.processOperationAsync(
            new SimpleStore<CancerClassificationId[]>(),
            async target => {
                const response = await this.apiClient.getAllCancerClassificationIdsQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.ids;
            }
        );
    }

    @State.bound
    public getCancerClassificationsByIdsAsync(ids: CancerClassificationId[]): Promise<SimpleStore<CancerClassificationDataStore[]>> {
        return this.processOperationAsync(
            new SimpleStore<CancerClassificationDataStore[]>(),
            async target => {
                const response = await this.apiClient.getCancerClassificationsByIdsQueryAsync(CreateRequestId(), new Proxy.GetCancerClassificationsByIdsControllerDto({ ids: ids }));
                target.operationInfo = createOperationInfo(response);
                if (target.operationWasSuccessful) {
                    target.value = response.cancerClassifications.map(cc => {
                        const result = new CancerClassificationDataStore();
                        result.id = cc.id;
                        result.tumorList = cc.tumorValues.map(t => {
                            return {
                                code: t.code,
                                id: t.id,
                                value: t.value
                            } as ICancerClassificationData;
                        });

                        result.metastasisList = cc.metastasisValues.map(m => {
                            return {
                                code: m.code,
                                id: m.id,
                                value: m.value
                            } as ICancerClassificationData;
                        });
                        result.nodeList = cc.nodeValues.map(n => {
                            return {
                                code: n.code,
                                id: n.id,
                                value: n.value
                            } as ICancerClassificationData;
                        });

                        return result;
                    });
                }
            }
        );
    }

    @State.bound
    public getOncologyInfoDataListAsync(id: CareActivityId): Promise<SimpleStore<IOncologyDataInfo[]>> {
        return this.processOperationAsync(
            new SimpleStore<IOncologyDataInfo[]>(),
            async target => {
                const response = await this.apiClient.getOncologyDataInfoListQueryAsync(CreateRequestId(), id.value);
                target.value = response.oncologyDataInfos.map(i => {
                    return {
                        id: i.id,
                        conditionId: i.conditionId,
                        dateOfDiagnosis: i.dateOfDiagnosis,
                        extensionData: i.extensionData
                    } as IOncologyDataInfo;
                });
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getOncologyDataByIdsAsync(ids: OncologyDataId[]): Promise<SimpleStore<OncologyDataStore[]>> {
        return this.processOperationAsync(
            new SimpleStore<OncologyDataStore[]>(),
            async target => {
                const response = await this.apiClient.getOncologyDataByIdsQueryAsync(CreateRequestId(), buildQueryStringArray(ids.map(i => i.value)));
                target.value = response.dtos.map(d => OncologyDataStore.createFromDto(d));
                target.operationInfo = createOperationInfo(response);
            }
        );
    }
    @State.bound
    public getOncologyRelevantMedicalServiceIdsAsync(careActivityId: CareActivityId) {
        return this.processOperationAsync(
            new SimpleStore<MedicalServiceId[]>(),
            async target => {
                const response = await this.apiClient.getOncologyRelevantMedicalServicesQueryAsync(CreateRequestId(), careActivityId.value);
                target.value = response.relevantMedicalServices!;
                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}
