import moment from "moment";
import PractitionerId from "@Primitives/PractitionerId.g";
import EhiDocumentTypeId from "@Primitives/EhiDocumentTypeId.g";
import EhiHealthcareProviderOrganizationUnitId from "@Primitives/EhiHealthcareProviderOrganizationUnitId.g";
import EhiDoctor from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiDoctor";
import EhiOrganizationUnit from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiOrganizationUnit";

export default class EhiDocumentListItem {
    public documentIdentifier: string;
    public ehiDocumentTypeId: EhiDocumentTypeId;
    public timeStamp: moment.Moment;
    public ehiDoctor: EhiDoctor;
    public doctorId: PractitionerId;
    public version: string;
    public ehiId: string;
    public ehiOrganizationUnit: EhiOrganizationUnit;
    public organizationUnitId: EhiHealthcareProviderOrganizationUnitId;
}