import * as MedicationRequestProxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import DependencyContainer from "@DiContainer";
import { GetTypeNameWithPrefixExt } from "@HunEHealthInfrastructurePlugin/PluginHelper";

DependencyContainer
    .bind(GetTypeNameWithPrefixExt("IPrescriptionClient", "MedicationRequest"))
    .to(MedicationRequestProxy.PrescriptionClient)
    .inSingletonScope();

DependencyContainer
    .bind(GetTypeNameWithPrefixExt("IReferenceDataClient", "MedicationRequest"))
    .to(MedicationRequestProxy.ReferenceDataClient)
    .inSingletonScope();

DependencyContainer
    .bind("IPractitionerRecommendationClient")
    .to(MedicationRequestProxy.PractitionerRecommendationClient)
    .inSingletonScope();

DependencyContainer
    .bind("IFamilyDoctorDocumentClient")
    .to(MedicationRequestProxy.FamilyDoctorDocumentClient)
    .inSingletonScope();