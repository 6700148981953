import Di from "@Di";
import IPatientCareActivitiesTabComponentService from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabComponentService";
import CareActivityId from "@Primitives/CareActivityId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";

@Di.injectable()
export default class DocumentManagementTabComponentService implements IPatientCareActivitiesTabComponentService {

    constructor(@Di.inject("CareActivityApiAdapter2") private careActivityApiAdapter: CareActivityApiAdapter2) {
    }

    public async isTabAllowedAsync(careActivityId: CareActivityId, organizationUnitId: OrganizationUnitId): Promise<boolean> {
        const result = await this.careActivityApiAdapter.checkCareActivityOperationPermissionsAsync(careActivityId);
        return result.value.hasListCareDocumentsPermission;
    }
}