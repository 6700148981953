exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SidebarTab_no-text-selection_2pk77 {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.SidebarTab_sidebar-tab_3w7fI {\n  height: 32px;\n  display: block;\n  background-color: white;\n  text-align: center;\n  cursor: pointer;\n  white-space: nowrap;\n  overflow: visible;\n  padding: 0px 10px;\n}\n.SidebarTab_sidebar-tab-selected_1el9z {\n  height: 32px;\n  display: block;\n  background-color: white;\n  text-align: center;\n  cursor: pointer;\n  white-space: nowrap;\n  overflow: visible;\n  padding: 0px 10px;\n  border-bottom: 2px solid #00b4ec;\n}\n.SidebarTab_sidebar-tab-label_1aDUB {\n  font-family: PragatiNarrowBold;\n  font-size: 13px;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.85;\n  letter-spacing: normal;\n  text-transform: uppercase;\n  color: #8695a7;\n  margin-top: 4px;\n}\n.SidebarTab_sidebar-tab-label-selected_1IP9b {\n  font-family: PragatiNarrowBold;\n  font-size: 13px;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.85;\n  letter-spacing: normal;\n  text-transform: uppercase;\n  color: #8695a7;\n  margin-top: 4px;\n  color: #004358;\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "SidebarTab_no-text-selection_2pk77",
	"noTextSelection": "SidebarTab_no-text-selection_2pk77",
	"sidebar-tab": "SidebarTab_sidebar-tab_3w7fI",
	"sidebarTab": "SidebarTab_sidebar-tab_3w7fI",
	"sidebar-tab-selected": "SidebarTab_sidebar-tab-selected_1el9z",
	"sidebarTabSelected": "SidebarTab_sidebar-tab-selected_1el9z",
	"sidebar-tab-label": "SidebarTab_sidebar-tab-label_1aDUB",
	"sidebarTabLabel": "SidebarTab_sidebar-tab-label_1aDUB",
	"sidebar-tab-label-selected": "SidebarTab_sidebar-tab-label-selected_1IP9b",
	"sidebarTabLabelSelected": "SidebarTab_sidebar-tab-label-selected_1IP9b"
};