import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import UsersApiAdapter from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Users/UsersApiAdapter";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import PanelController from "@Toolkit/ReactClient/Components/PanelController";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import UserId from "@Primitives/UserId.g";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import RequestStatus from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/RequestStatus";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import PractitionerId from "@Primitives/PractitionerId.g";
import JobPositionId from "@Primitives/JobPositionId.g";
import UserWithIdentifiers from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/UserWithIdentifiers";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import PointOfCareRoleApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Actions/PointOfCareRoleApiAdapter";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import AuthorizationReferenceDataStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/AuthorizationReferenceDataStore";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import ExternalPractitionerDataSource from "./ExternalPractitionerDataSource";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import UserManagementPanelView from "./UserManagementPanelView";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import AccessRuleApiAdapter from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/OperationAccessControl/AccessRuleApiAdapter";
import AccessRuleBase from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/AccessRuleBase";

interface IUserManagementPanelDependencies {
    usersApiAdapter: UsersApiAdapter;
    userManagementDataProviderStore: UserManagementDataProviderStore;
    practitionerApiAdapter: PractitionerApiAdapter;
    dialogService: IDialogService;
    notificationService: INotificationService;
    pointOfCareRoleApiAdapter: PointOfCareRoleApiAdapter;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    authorizationReferenceDataStore: AuthorizationReferenceDataStore;
    commonReferenceDataDataStore: CommonReferenceDataDataStore;
    accessRuleApiAdapter: AccessRuleApiAdapter;
}

interface IUserManagementPanelProps {
    _dependencies?: IUserManagementPanelDependencies;
    navigationController: PanelController;
    userId: UserId;
    practitionerId: PractitionerId;
    tab: string;
    onBack: () => void;

    onNavigationChanged(type: string, id: string, tab: string): void;
}

/** @screen */
@State.observer
class UserManagementPanel extends React.Component<IUserManagementPanelProps> {

    @State.observable.ref public usersWithIdentifiers: UserWithIdentifiers[] = null;
    private isTabNavigation: boolean;

    @State.observable.ref public selectedUser: User = null;
    @State.observable.ref public selectedPractitioner: PractitionerStore = null;
    @State.observable.ref public usersWithIdentifiersDataSource: InMemoryDataGridDataSource = new InMemoryDataGridDataSource(() => this.usersWithIdentifiers);
    private currentJobPositionId: JobPositionId = null;
    @State.observable public userMode: boolean = true;
    @State.observable public newUserMode: boolean = true;
    @State.observable public addUserToPractitioner: boolean = false;
    @State.observable public isLoading: boolean = true;
    @State.observable.ref public selectedUserAccessRules: AccessRuleBase[] = [];

    private externalPractitionersDataSource: ExternalPractitionerDataSource = new ExternalPractitionerDataSource(this.props._dependencies.practitionerApiAdapter, null);

    @State.computed
    private get isNew() {
        return (this.props.userId && this.props.userId.value === "new") ||
            (this.props.practitionerId && this.props.practitionerId.value === "new");
    }

    @State.computed
    private get isProperSelectedUser() {
        return this.selectedUser && this.selectedUser.id && this.selectedUser.id.value !== "new" && this.selectedUser.id.value !== "";
    }

    private get usersApiAdapter() {
        return this.props._dependencies.usersApiAdapter;
    }

    private get userManagementDataProviderStore() {
        return this.props._dependencies.userManagementDataProviderStore;
    }

    private get practitionerApiAdapter() {
        return this.props._dependencies.practitionerApiAdapter;
    }

    private get dialogService() {
        return this.props._dependencies.dialogService;
    }

    private get notificationService() {
        return this.props._dependencies.notificationService;
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync, this.usersApiAdapter.updateUserPermissionCheckAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(this.props.userId, this.props.practitionerId), this);
    }

    public componentDidUpdate(prevProps: IUserManagementPanelProps) {

        if (!this.props.userId && !this.props.practitionerId) {
            this.setUser(null);
            this.setPractitioner(null);
        }

        if (this.props.userId && (!prevProps.userId || prevProps.userId.value !== this.props.userId.value)) {
            dispatchAsyncErrors(this.setUserOrPractitionerAsync(this.props.userId, null), this);
        } else if (this.props.practitionerId && (!prevProps.practitionerId || prevProps.practitionerId.value !== this.props.practitionerId.value)) {
            dispatchAsyncErrors(this.setUserOrPractitionerAsync(null, this.props.practitionerId), this);
        }
    }

    @State.action.bound
    public async closeUserEditorAsync() {
        const success = await this.tryCloseUserEditorAsync();

        if (success) {
            this.props.onNavigationChanged(null, null, null);
        }
        return success;
    }

    @State.action.bound
    private onSelectedItemChange(id: string) {
        if (this.userMode) {
            const tab = id && id !== "new" ? (isNullOrUndefined(this.props.tab) ? "PersonalData" : this.props.tab) : null;
            this.navigate("user", id, tab);
        } else {
            const tab = id && id !== "new" ? (isNullOrUndefined(this.props.tab) || this.props.tab === "PointOfCares" || this.props.tab === "Groups" ? "PersonalData" : this.props.tab) : null;
            this.navigate("practitioner", id, tab);
        }
    }

    @State.bound
    private navigate(mode: string, id: string, tab: string) {
        this.props.onNavigationChanged(mode, id, tab);
    }

    @State.action.bound
    private onTabChanged(newTab: string) {
        this.isTabNavigation = true;
        if (this.props.userId) {
            this.props.onNavigationChanged("user", this.props.userId.value, newTab);
        } else {
            this.props.onNavigationChanged("practitioner", this.props.practitionerId.value, newTab);
        }
    }

    @State.action.bound
    private setUserMode(newValue: boolean) {
        this.userMode = newValue;
    }

    @State.action.bound
    private setNewUserMode(newValue: boolean) {
        this.newUserMode = newValue;
    }

    @State.action.bound
    private setAddUserToPractitioner(newValue: boolean) {
        this.addUserToPractitioner = newValue;
    }

    @State.bound
    private async validateAllAsync() {
        const userResults = await this.validateUserAsync();
        const practitionerResults = await this.validatePractitionerAsync();
        const accessRuleResults = await this.validateAccessRulesAsync();
        return [...userResults, ...practitionerResults, ...accessRuleResults];
    }

    private async validateUserAsync() {
        if (!this.selectedUser) {
            return [];
        }

        if (this.selectedPractitioner && this.selectedUser.displayName !== this.selectedPractitioner.displayName) {
            this.selectedUser.setDisplayName(this.selectedPractitioner.displayName);
        }

        const result = await this.usersApiAdapter.validateAsync(this.selectedUser);
        return result.value;
    }

    private async validatePractitionerAsync() {
        if (!this.selectedPractitioner) {
            return [];
        }

        if (this.isNew) {
            this.selectedPractitioner.userId = this.newUserMode ? new UserId("0") : null;
        } else if (this.isProperSelectedUser) {
            this.selectedPractitioner.userId = this.selectedUser.id;
        }

        const result = await this.practitionerApiAdapter.validateAsync(this.selectedPractitioner, !!this.selectedUser);
        return result.value;
    }

    private async validateAccessRulesAsync() {
        if (!this.notDeletedAccessRules.length) {
            return [];
        }

        const result = await this.props._dependencies.accessRuleApiAdapter.validateAccessRules(this.notDeletedAccessRules);
        return result.value;
    }

    @State.bound
    private isAnyAccessRuleDirty() {
        return this.selectedUserAccessRules.some(a => a.isDirty() && !(a.isNew && a.isDeleted));
    }

    @State.bound
    private async tryCloseUserEditorAsync() {
        if (!this.isTabNavigation && (this.selectedUser?.isDirty() || this.selectedPractitioner?.isDirty() || this.isAnyAccessRuleDirty())) {
            const answer = await this.dialogService.confirmIfNotSaved(StaticUserManagementResources.UserManagementPanel.Message.SaveBeforeNavigationConfirmationTitle,
                StaticUserManagementResources.UserManagementPanel.Message.SaveBeforeNavigationConfirmationMessage);
            if (answer.resultCode === DialogResultCode.Yes) {
                const saveResult = await this.saveAsync();
                this.isTabNavigation = null;
                return saveResult;
            } else if (answer.resultCode === DialogResultCode.No) {
                this.isTabNavigation = null;
                return true;
            } else { // result === DialogResultCode.Cancel
                return false;
            }
        }
        this.isTabNavigation = null;
        return true;
    }

    @State.computed
    public get isEntityEdited() {
        return this.props.userId !== null || this.props.practitionerId !== null;
    }

    @State.action.bound
    public setUsersWithIdentifiers(newValue: UserWithIdentifiers[]) {
        this.usersWithIdentifiers = newValue;
    }

    @State.action.bound
    public async setUserByIdAsync(id: UserId) {
        if (id) {
            if (id.value === "new") {
                this.createUser(id);
                await this.setPractitionerByIdAsync(new PractitionerId("new"));
            } else {
                const userId = new UserId(id.value.split("-")[1]);
                const userList = await this.usersApiAdapter.getUsersByIdsAsync([userId]);
                this.setUser(userList.value[0]);

                this.jumpToSelectedUser();

                if (this.selectedUser) {
                    const matchedPractitioner = await this.practitionerApiAdapter.getPractitionerByUserIdAsync(userId);
                    this.setPractitioner(matchedPractitioner);
                }
            }
        }
    }

    @State.action.bound
    public jumpToSelectedUser() {
        const currentPage = Math.trunc(this.usersWithIdentifiersDataSource.orderedData.map(x => x.user.id.value).indexOf(this.selectedUser.id.value) / this.usersWithIdentifiersDataSource.paging.pageSize);

        if (this.usersWithIdentifiersDataSource.paging.currentPage !== currentPage) {
            this.usersWithIdentifiersDataSource.paging = {
                currentPage: currentPage,
                pageSize: this.usersWithIdentifiersDataSource.paging.pageSize
            };
        }
    }

    @State.action.bound
    private createUser(id: UserId) {
        this.setUser(new User(id, null, null, true, true, false, []));
        this.selectedUser.isNew = true;
    }

    @State.action.bound
    private setIsLoading(newValue: boolean) {
        this.isLoading = newValue;
    }

    @State.bound
    public onNewUserModeChange(userMode: boolean) {
        if (userMode) {
            this.createUser(new UserId("new"));
            this.setNewUserMode(true);
        } else {
            this.setUser(null);
            this.setNewUserMode(false);
        }
    }

    @State.action.bound
    public onAddUserToPractitioner(addUserToPractitioner: boolean) {
        if (addUserToPractitioner) {
            this.createUser(new UserId("new"));
            this.setAddUserToPractitioner(true);
        } else {
            this.setUser(null);
            this.setAddUserToPractitioner(false);
        }
    }

    @State.action.bound
    public setUser(user: User) {
        if (user === undefined || user === null) {
            this.selectedUser = null;
            return;
        }

        this.selectedUser = new User(user.id, user.displayName, user.loginName, user.isEnabled, user.isPasswordExpirable, user.isNewPassword, [...user.userGroupIds], user.rowVersion);
        this.selectedUser.takeSnapshot();
    }

    @State.action.bound
    public async setPractitionerByIdAsync(id: PractitionerId) {
        if (id) {
            if (id.value === "new") {
                this.setPractitioner(new PractitionerStore(true));
            } else {
                const practitionerId = new PractitionerId(id.value.split("-")[1]);
                const practitionerStore = await this.props._dependencies.practitionerApiAdapter.getPractitionerByIdAsync(practitionerId);
                this.setPractitioner(practitionerStore);
            }
        }
    }

    @State.action.bound
    public setPractitioner(practitioner: PractitionerStore) {
        if (practitioner === undefined || practitioner === null) {
            this.selectedPractitioner = null;
            return;
        }

        this.setAddUserToPractitioner(false);

        this.currentJobPositionId = practitioner?.jobPositionId && new JobPositionId(practitioner?.jobPositionId.value);
        this.selectedPractitioner = practitioner.clone();
        this.selectedPractitioner.takeSnapshot();
    }

    @State.bound
    public async loadAsync(userId: UserId, practitionerId: PractitionerId) {

        this.setIsLoading(true);

        await this.refreshUsersWithIdentifiersDataSourceAsync();

        await this.setUserOrPractitionerAsync(userId, practitionerId);

        await this.loadAccessRulesAsync(userId);

        this.setIsLoading(false);
    }

    private async refreshUsersWithIdentifiersDataSourceAsync() {
        this.userManagementDataProviderStore.usersWithIdentifiersOnlyWithPractitioners.invalidate();
        await Promise.all([
            this.userManagementDataProviderStore.usersWithIdentifiersOnlyWithPractitioners.ensureAllLoadedAsync(),
            this.props._dependencies.authorizationReferenceDataStore.roleMap.ensureAllLoadedAsync(),
            this.props._dependencies.organizationReferenceDataStore.pointOfCareMap.ensureAllLoadedAsync(),
            this.props._dependencies.commonReferenceDataDataStore.identifierSystemMap.ensureAllLoadedAsync()
        ]);
        this.setUsersWithIdentifiers(this.userManagementDataProviderStore.usersWithIdentifiersOnlyWithPractitioners.items);
    }

    @State.bound
    private async setUserOrPractitionerAsync(userId: UserId, practitionerId: PractitionerId) {
        this.setIsLoading(true);
        if (userId) {
            if (userId.value !== "new") {
                this.setUserMode(true);
            } else {
                this.setNewUserMode(true);
            }
            await this.setUserByIdAsync(userId);
            await this.loadAccessRulesAsync(userId);
        } else if (practitionerId) {
            if (practitionerId.value !== "new") {
                this.setUserMode(false);
            } else {
                this.setNewUserMode(true);
            }
            this.setUser(null);
            await this.setPractitionerByIdAsync(practitionerId);
        }
        this.setIsLoading(false);
    }

    @State.bound
    private async loadAccessRulesAsync(id: UserId) {
        if (!id) {
            return;
        }
        const accessRules = [];
        if (id.value !== "new") {
            // original user id needs to keep form user-{id} because of dataGrid row selection
            const userId = new UserId(id.value.split("-")[1]);
            const res = await this.props._dependencies.accessRuleApiAdapter.getAccessRulesAsync(userId);
            accessRules.push(...res.value);
        }
        
        accessRules.forEach(a => a.takeSnapshot());
        this.setAccessRules(accessRules);
    }

    @State.bound
    private async saveAccessRulesAsync() {
        // Temporary solution, TODO: HISP-8406
        const accessRulesAreValid = await this.props._dependencies.accessRuleApiAdapter.validateAccessRules(this.notDeletedAccessRules);
        if (accessRulesAreValid.value.some(p => p.problems.length)) {
            return false;
        } else {
            const result = await this.props._dependencies.accessRuleApiAdapter.setAccessRulesAsync(this.notDeletedAccessRules);
            const deleteResults = [];
            for (const accessRule of this.deletedAccessRules) {
                deleteResults.push(await this.props._dependencies.accessRuleApiAdapter.deleteAccessRuleAsync(accessRule));
            }

            result.value.forEach(a => a.takeSnapshot());
            this.setAccessRules(result.value);
            return true;
        }
    }

    @State.action.bound
    public async saveAsync() {
        this.setIsLoading(true);

        const validationResult = await this.validateAllAsync();
        const hasValidationError = validationResult.some(item => item.problems.some(p => p.severity === "error"));
        if (!hasValidationError) {

            const isNewUser = this.selectedUser?.isNew;
            const isNewPractitioner = this.selectedPractitioner?.isNew;

            // Do not change the order of these two operation
            const successfulUserSave = await this.saveUserAsync();
            const successfulPractitionerSave = await this.savePractitionerAsync();
            const successfulAccessRulesSave = await this.saveAccessRulesAsync();

            if (successfulUserSave && successfulPractitionerSave && successfulAccessRulesSave) {
                this.setIsLoading(false);
                this.notificationService.showSavedSuccessfully();
                if (isNewUser || isNewPractitioner) {
                    this.props.onNavigationChanged(isNewUser ? "user" : "practitioner",
                        isNewUser ? `user-${this.selectedUser.id.value}` : `practitioner-${this.selectedPractitioner.id.value}`,
                        "PersonalData");
                }
                return true;
            }
        } else {
            this.setPractitionerValidationResult(validationResult);
            this.setUserValidationResult(validationResult);
        }

        this.setIsLoading(false);
        this.notificationService.showCannotSaveBecauseOfErrors();
        return false;
    }

    @State.action.bound
    private async saveUserAsync(): Promise<boolean> {
        if (!this.selectedUser) {
            return true;
        }

        if (this.selectedPractitioner && this.selectedUser.displayName !== this.selectedPractitioner.displayName) {
            this.selectedUser.setDisplayName(this.selectedPractitioner.displayName);
        }
        let newStore: User;

        if (this.selectedUser.isNew) {
            newStore = await this.usersApiAdapter.addUserAsync(this.selectedUser);
        } else {
            newStore = await this.usersApiAdapter.updateUserAsync(this.selectedUser);
        }

        if (newStore.operationInfo.requestStatus !== RequestStatus.Success || !newStore.isPersistedByOperationInfo || newStore.hasValidationError) {
            this.setUserValidationResult(newStore.validationResults);
            return false;
        }

        this.setUser(newStore);
        return true;
    }

    @State.action.bound
    private async savePractitionerAsync(): Promise<boolean> {
        if (!this.selectedPractitioner) {
            return true;
        }

        if (this.isProperSelectedUser) {
            this.selectedPractitioner.userId = this.selectedUser.id;
        } else if (this.isNew) {
            this.selectedPractitioner.userId = this.newUserMode ? new UserId("0") : null;
        }

        let newStore: PractitionerStore;

        if (this.selectedPractitioner.isNew) {
            newStore = await this.practitionerApiAdapter.addPractitionerAsync(this.selectedPractitioner, !!this.selectedUser);
        } else {
            newStore = await this.practitionerApiAdapter.updatePractitionerAsync(this.selectedPractitioner, !!this.selectedUser);
        }

        if (newStore.operationInfo.requestStatus !== RequestStatus.Success || !newStore.isPersistedByOperationInfo || newStore.hasValidationError) {
            this.setPractitionerValidationResult(newStore.validationResults);
            return false;
        }

        if (!this.selectedUser && !this.externalPractitionersDataSource.containsId(newStore.id)) {
            this.externalPractitionersDataSource.practitionerId = new PractitionerId(`practitioner-${newStore.id.value}`);
            this.externalPractitionersDataSource.reloadAsync();
        } else if (this.selectedUser) {
            await this.refreshUsersWithIdentifiersDataSourceAsync();
        }

        if (this.addUserToPractitioner) {
            this.setUserMode(true);
        }

        this.setPractitioner(newStore);
        return true;
    }

    @State.action.bound
    private setUserValidationResult(validationResults: IClientValidationResult[]) {
        if (!!this.selectedUser) {
            this.selectedUser.validationResults = validationResults;
        }
    }

    @State.action.bound
    private setPractitionerValidationResult(validationResults: IClientValidationResult[]) {
        if (!!this.selectedPractitioner) {
            this.selectedPractitioner.validationResults = validationResults;
        }
    }

    @State.action.bound
    private addNewEntityAsync() {
        this.props.onNavigationChanged("user", "new", null);
        return Promise.resolve();
    }

    @State.action.bound
    private setAccessRules(newValue: AccessRuleBase[]) {
        this.selectedUserAccessRules = newValue;
    }

    @State.computed
    private get notDeletedAccessRules(): AccessRuleBase[] {
        return this.selectedUserAccessRules.filter(a => !a.isDeleted);
    }

    @State.computed
    private get deletedAccessRules(): AccessRuleBase[] {
        return this.selectedUserAccessRules.filter(a => a.isDeleted && !!a.id);
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticUserManagementResources.UserManagementPanel.Title} />;
        }

        return (
            <>
                <UserManagementPanelView
                    tab={this.props.tab}
                    onTabChanged={this.onTabChanged}
                    isLoading={this.isLoading}
                    user={this.selectedUser}
                    practitioner={(this.props.userId || this.props.practitionerId) ? this.selectedPractitioner : null}
                    saveAsync={this.saveAsync}
                    onCancel={this.closeUserEditorAsync}
                    isNew={this.isNew}
                    onNewUserModeChange={this.onNewUserModeChange}
                    newUserMode={this.newUserMode}
                    currentJobPositionId={this.currentJobPositionId}
                    onAddUserToPractitioner={this.onAddUserToPractitioner}
                    addUserToPractitioner={this.addUserToPractitioner}
                    onValidateAllAsync={this.validateAllAsync}
                    addNewEntityAsync={this.addNewEntityAsync}
                    usersWithIdentifiersDataSource={this.usersWithIdentifiersDataSource}
                    practitionersDataSource={this.externalPractitionersDataSource}
                    selectedId={this.props.userId ? this.props.userId.value : this.props.practitionerId ? this.props.practitionerId.value : null}
                    onSelectedItemChange={this.onSelectedItemChange}
                    userMode={this.userMode}
                    onUserModeChange={this.setUserMode}
                    onBack={this.props.onBack}
                    accessRules={this.selectedUserAccessRules}
                    onAccessRulesChange={this.setAccessRules}
                />
                <NavigateAwayHook isEnabled onNavigateAwayAsync={this.tryCloseUserEditorAsync} />
            </>
        );
    }
}

export default connect(
    UserManagementPanel,
    new DependencyAdapter<IUserManagementPanelProps, IUserManagementPanelDependencies>(container => {
        return {
            usersApiAdapter: container.resolve("UsersApiAdapter"),
            userManagementDataProviderStore: container.resolve("UserManagementDataProviderStore"),
            practitionerApiAdapter: container.resolve("PractitionerApiAdapter"),
            dialogService: container.resolve("IDialogService"),
            notificationService: container.resolve("INotificationService"),
            pointOfCareRoleApiAdapter: container.resolve("PointOfCareRoleApiAdapter"),
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            authorizationReferenceDataStore: container.resolve("AuthorizationReferenceDataStore"),
            commonReferenceDataDataStore: container.resolve("CommonReferenceDataDataStore"),
            accessRuleApiAdapter: container.resolve("AccessRuleApiAdapter")
        };
    })
);
