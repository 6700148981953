import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import {
    IDataGridColumnBaseProps,
    IDataGridColumnFilterProps, IDataGridFilterValueSerializer
} from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import React from "react";
import AppointmentInvalidationReason from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentInvalidationReason.g";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import AppointmentInvalidationReasonSelectBox from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentInvalidationReasonSelectBox";

interface IAppointmentInvalidationReasonColumnDependencies {
    localizationService: ILocalizationService;
}

interface IAppointmentInvalidationReasonColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IAppointmentInvalidationReasonColumnDependencies;
}

@State.observer
class AppointmentInvalidationReasonColumn extends React.Component<IAppointmentInvalidationReasonColumnProps> {
    private filterValueSerializer: IDataGridFilterValueSerializer<AppointmentInvalidationReason | AppointmentInvalidationReason[]> = {
        serialize: value => JSON.stringify(value),
        deserialize: value => JSON.parse(value)
    };

    @State.bound
    private renderValue(value: AppointmentInvalidationReason) {
        const name = this.props._dependencies.localizationService.localizeEnum(AppointmentInvalidationReason[value], "AppointmentInvalidationReason");
        return name?.Name;
    }

    @State.bound
    private filterRenderer(filterProps: IDataGridColumnFilterProps<AppointmentInvalidationReason | AppointmentInvalidationReason[]>) {
        return (
            <AppointmentInvalidationReasonSelectBox
                {...filterProps}
                maxMenuWidth="300px"
                displayMode="selectBox"
                hoverOnlyIndicatorIcons
            />
        );
    }

    public render() {
        const {children, ...columnProps} = this.props;
        return (
            <DataGridColumn
                {...columnProps}
                onRenderFilter={this.filterRenderer}
                filterValueSerializer={this.filterValueSerializer}
                onRenderCellValue={this.renderValue}
            />
        );
    }
}

export default connect(
    AppointmentInvalidationReasonColumn,
    new DependencyAdapter<IAppointmentInvalidationReasonColumnProps, IAppointmentInvalidationReasonColumnDependencies>(c => {
        return {
            localizationService: c.resolve("ILocalizationService")
        };
    })
);
