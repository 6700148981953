import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Finance/Api/Proxy.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import FinancedService from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinancedService";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import InsurancePlanId from "@Primitives/InsurancePlanId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IMedicalServiceWithFinancedServices from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/IMedicalServiceWithFinancedServices";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import SearchFinancedServicesQueryOrderingFields from "@HisPlatform/BoundedContexts/Finance/Api/Finance/Enum/SearchFinancedServicesQueryOrderingFields.g";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import FinancedServicePriceData from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinancedServicePriceData";
import Money from "@Toolkit/CommonWeb/Model/Money";
import MedicalServicePanelId from "@Primitives/MedicalServicePanelId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";

@Di.injectable()
export default class FinancedServiceApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IFinanceClient") private readonly apiClient: Proxy.IFinanceClient
    ) {
        super();
    }

    @State.bound
    public loadFinancedServicesAsync(ids: Array<IEntityVersionSelector<FinancedServiceId>>): Promise<SimpleStore<FinancedService[]>> {
        return this.processOperationAsync(
            new SimpleStore<FinancedService[]>(),
            async target => {

                if (arrayIsNullOrEmpty(ids)) {
                    target.value = [];
                }

                const response = await this.apiClient.getFinancedServiceVersionsByIdsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetFinancedServiceVersionsByIdsControllerDto({
                        financedServiceVersionSelectors: ids.map(item => new Proxy.EntityVersionSelectorOfFinancedServiceId({
                            entityId: item.id,
                            validOn: item.validOn || LocalDate.today()
                        })),
                    }
                    ));

                target.operationInfo = createOperationInfo(response);
                target.value = response.financedServiceVersion.map(this.mapFinancedServiceVersionDto);
            }
        );
    }

    @State.bound
    public getFinancedServicePriceDataAsync(id: FinancedServiceId, versionDate: LocalDate): Promise<SimpleStore<FinancedServicePriceData>> {
        return this.processOperationAsync(
            new SimpleStore<FinancedServicePriceData>(),
            async target => {
                const response = await this.apiClient.getFinancedServicePriceDataByIdQueryAsync(CreateRequestId(), id.value, versionDate.stringify());
                target.operationInfo = createOperationInfo(response);
                target.value = this.mapFinancedServicePriceDataDto(id, response.result);
            }
        );
    }

    @State.bound
    public getFinancedServiceIdsByMedicalServiceIdsAsync(ids: Array<IEntityVersionSelector<MedicalServiceId>>, insurancePlanId: InsurancePlanId, validOn: LocalDate): Promise<SimpleStore<IMedicalServiceWithFinancedServices[]>> {
        return this.processOperationAsync(
            new SimpleStore<IMedicalServiceWithFinancedServices[]>(),
            async target => {

                if (arrayIsNullOrEmpty(ids)) {
                    target.value = [];
                }

                const response = await this.apiClient.getFinancedServiceIdsByMedicalServiceIdsQueryAsync(
                    CreateRequestId(),
                    {
                        insurancePlanId: insurancePlanId,
                        medicalServiceIds: ids.map(i => i.id),
                        validOn: validOn
                    } as Proxy.GetFinancedServiceIdsByMedicalServiceIdsControllerDto);

                target.operationInfo = createOperationInfo(response);
                target.value = response.financedServiceIds;
            }
        );
    }

    @State.bound
    public searchFinancedServicesByCodeAsync(code: string, maxResultCount: number, validOn: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<FinancedService[]>(),
            async target => {
                const response = await this.apiClient.searchFinancedServicesByCodeQueryAsync(
                    CreateRequestId(),
                    new Proxy.SearchFinancedServicesByCodeControllerDto({
                        code: code,
                        maxResultCount: maxResultCount,
                        validOn: validOn
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.results && response.results.map(this.mapFinancedServiceListItemDto);
            }
        );
    }

    @State.bound
    public searchFinancedServices(filterText: string, validOn: LocalDate, ordering: IOrderingState, paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<{ values: FinancedService[], totalCount: number }>(),
            async (target) => {
                const response = await this.apiClient.searchFinancedServicesQueryAsync(
                    CreateRequestId(),
                    new Proxy.SearchFinancedServicesControllerDto({
                        filterText: filterText,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfSearchFinancedServicesQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfSearchFinancedServicesQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: SearchFinancedServicesQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({ pageIndex: paging.currentPage || 0, pageSize: paging.pageSize || 20 })
                        }),
                        validOn: validOn
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    values: response.results && response.results.values.map(this.mapFinancedServiceListItemDto),
                };
            }
        );
    }

    @State.bound
    public getMedicalServicePricesAsync(
        medicalServicePanelIds: MedicalServicePanelId[],
        medicalServiceIds: MedicalServiceId[],
        validOn: LocalDate
    ): Promise<SimpleStore<number>> {
        return this.processOperationAsync(
            new SimpleStore<number>(),
            async (target) => {
                const response = await this.apiClient.getMedicalServicePricesQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetMedicalServicePricesControllerDto({
                        medicalServicePanelIds: medicalServicePanelIds,
                        medicalServiceIds: medicalServiceIds,
                        validOn: validOn
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.totalNetPrice;
            }
        );
    }

    @State.bound
    public isMedicalServicePriceDisplayEnabledAsync(
        organizationUnitId: OrganizationUnitId
    ): Promise<SimpleStore<boolean>> {
        return this.processOperationAsync(
            new SimpleStore<boolean>(),
            async (target) => {
                const response = await this.apiClient.isMedicalServicePriceDisplayEnabledQueryAsync(
                    CreateRequestId(),
                    organizationUnitId.value
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.isMedicalServicePriceDisplayEnabled;
            }
        );
    }

    private mapFinancedServiceVersionDto(dto: Proxy.FinancedServiceVersionDto) {
        return new FinancedService(
            dto.financedServiceId,
            dto.code,
            dto.validity,
            dto.name,
            dto.unitPrice,
            dto.unitTypeId,
            dto.medicalServiceIds
        );
    }

    private mapFinancedServiceListItemDto(dto: Proxy.FinancedServiceListItemDto) {
        return {
            id: dto.id,
            code: new Identifier(dto.identifierSystemId, dto.code),
            name: dto.name
        } as FinancedService;
    }

    private mapFinancedServicePriceDataDto(financedServiceId: FinancedServiceId, dto: Proxy.FinancedServicePriceDataDto) {
        return {
            financedServiceId: financedServiceId,
            vatRateId: dto.vatRateId,
            netUnitPrice: new Money(dto.netUnitPrice.amount, dto.netUnitPrice.currencyCode)
        } as FinancedServicePriceData;
    }
}
