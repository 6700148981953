import TherapyId from "@Primitives/TherapyId.g";

export class TherapyCodeSelectorItem {
    public id: TherapyId;
    public name?: string;
    public code?: string;
    public text?: string;

    constructor({ id = null, name = "", code = "",  text = "" }: { id?: TherapyId, name?: string, code?: string, text?: string }) {
        this.id = id;
        this.name = name;
        this.text = text;
        this.code = code;
    }
}