import Di from "@Di";
import { ITabbedScreenDefinition } from "@HisPlatform/Packages/ActionModel/Model/ITabbedScreenDefinition";
import TabbedScreenApiAdapter from "@HisPlatform/Packages/ActionModel/Screens/TabbedScreen/TabbedScreenApiAdapter";
import ShowCareActivityTabbedScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityTabbedScreenAction.g";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { TabbedScreenTab } from "./TabbedScreenTab";

@Di.injectable()
export default class TabbedScreenStore extends PanelStoreBase<IScreenPropsBase<ShowCareActivityTabbedScreenAction>> implements ILoadablePanelStore<IScreenPropsBase<ShowCareActivityTabbedScreenAction>> {
    @State.observable.ref public screenDefinition: ITabbedScreenDefinition | null = null;

    @State.computed public get tabs(): TabbedScreenTab[] {
        return this.screenDefinition?.tabs.map(t => new TabbedScreenTab(
            this.cultureCodeProvider.cultureCode,
            t
        )) ?? [];
    }

    @State.observable.ref public selectedTab: TabbedScreenTab | null = null;

    constructor(
        @Di.inject("TabbedScreenApiAdapter") private readonly apiAdapter: TabbedScreenApiAdapter,
        @Di.inject("ICurrentCultureProvider") private readonly cultureCodeProvider: ICurrentCultureProvider
    ) {
        super();
    }

    public getReloadTriggerProps(props: IScreenPropsBase<ShowCareActivityTabbedScreenAction>): any[] {
        return [props.action];
    }

    public readonly loadAsync = this.async(async () => {
        const def = await this.apiAdapter.getTabbedScreenDefinitionForCareActivityAsync(
            this.props.action.careActivityId,
            this.props.action.definitionCode
        );

        State.runInAction(() => {
            this.screenDefinition = def.result;
        });

        let defaultDelectedTab = isNullOrUndefined(def.result.defaultTabFrontendActionId)
            ? null
            : this.tabs.find(t => ValueWrapper.equals(t.tabDef.actionDescriptor.action.id, def.result.defaultTabFrontendActionId));

        if (!defaultDelectedTab && this.tabs.length > 0) {
            defaultDelectedTab = this.tabs[0];
        }

        if (defaultDelectedTab) {
            this.selectTabAsync(defaultDelectedTab);
        }
    });

    public readonly selectTabAsync = this.async(async (tab: TabbedScreenTab) => {
        (tab.tabDef.actionDescriptor.action as ShowScreenFrontendActionBase).displayMode = ScreenDisplayMode.Detail;
        await this.actionDispatcher.dispatchAsync(tab.tabDef.actionDescriptor.action);

        State.runInAction(() => {
            this.selectedTab = tab;
        });
    });
}

