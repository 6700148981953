import React from "react";
import { InfoBox } from "@CommonControls";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { iconVisualStyleType } from "@CommonControls/Icon";

interface IUnauthorizedAccessContentProps {
    showInfoBox?: boolean;
    visualStyle?: iconVisualStyleType;
}

function UnauthorizedAccessContent(props: React.PropsWithChildren<IUnauthorizedAccessContentProps>) {
    const message = StaticWebAppResources.Common.Paragraphs.UnauthorizedDescription;

    if (props.showInfoBox) {
        return <InfoBox iconVisualStyle={props.visualStyle} iconName="shield">{message}</InfoBox>;
    }

    return <SpanWithIcon iconName="shield">{message}</SpanWithIcon>;
}

UnauthorizedAccessContent.defaultProps = {
    showInfoBox: true,
    visualStyle: "primary"
};

export default UnauthorizedAccessContent;