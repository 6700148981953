import { iconNameType } from "@CommonControls/Icon";
import Di from "@Di";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { getIconNameByHealthcareProfessions } from "@HisPlatform/BoundedContexts/Organization/Components/Helpers/IconTypeNameHelper";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import HomeMenuApiAdapter from "@HisPlatform/Packages/Care/Components/HomeMenu/HomeMenuApiAdapter";
import { createHomeMenuLocalizationHelpers } from "@HisPlatform/Packages/Care/Components/HomeMenu/HomeMenuLocalizationHelpers";
import { HomeMenuItem, HomeMenuItemGroup, IHomeMenu } from "@HisPlatform/Packages/Care/Components/HomeMenu/IHomeMenu";
import Log from "@HisPlatform/Services/Definition/Logger/Log";
import HealthcareProfessionId from "@Primitives/HealthcareProfessionId.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import { getUniqueKey } from "@Toolkit/CommonWeb/UniqueKey";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";

export interface IHomeMenuListItemView {
    index: number;
    key: React.Key;
    displayValue: string;
    menuItem: HomeMenuItem;
    iconName?: iconNameType;

    onSelect?: () => void;
}

export interface IHomeMenuListItemGroupView {
    title: React.ReactNode;
    items: IHomeMenuListItemView[];
    key: React.Key;
}

const lastSelectedHomeMenuItemKey = "lastSelectedHomeMenuItem";

@Di.injectable()
export default class HomeControllerStore extends PanelStoreBase implements ILoadablePanelStore {

    @State.observable.ref public itemGroups: IHomeMenuListItemGroupView[] = [];
    @State.observable.ref public selectedItem: HomeMenuItem | null = null;

    private readonly localizationHelpers = createHomeMenuLocalizationHelpers(this.localizationService);

    constructor(
        @Di.inject("HomeMenuApiAdapter") private readonly apiAdapter: HomeMenuApiAdapter,
        @Di.inject("ILocalizationService") private readonly localizationService: ILocalizationService,
        @Di.inject("OrganizationReferenceDataStore") private readonly organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("ApplicationContext") private readonly applicationContext: ApplicationContext,
        @Di.inject("UserContext") private readonly userContext: UserContext,
    ) {
        super();
    }

    public readonly loadAsync = this.async(async () => {
        const [getMenuResult, _] = await Promise.all([
            this.apiAdapter.getHomeMenuAsync(),
            this.organizationReferenceDataStore.healthCareProfession.ensureAllLoadedAsync()
        ]);
        this.setLoadedState(getMenuResult.result!);
    });

    @State.action
    private setLoadedState(menu: IHomeMenu) {
        let index = 0;
        this.itemGroups = menu.items?.map<IHomeMenuListItemGroupView>(g => {

            if (g instanceof HomeMenuItemGroup) {
                const groupDisplayName = this.localizationHelpers.getLocalizedText(g.localizedDisplayName, g.displayNameResourceId);
                return ({
                    key: getUniqueKey(),
                    title: <div><b style={{ color: "#8695a7", margin: "0 4px" }}> {groupDisplayName} </b></div>,
                    items: g.items.map<IHomeMenuListItemView>(i => {
                        const itemDisplayName = this.localizationHelpers.getLocalizedText(i.localizedShortName, i.shortNameResourceId);
                        return ({
                            index: index++,
                            key: getUniqueKey(),
                            displayValue: itemDisplayName,
                            onSelect: () => this.selectMenuItemAsync.fireAndForget(i),
                            menuItem: i,
                            iconName: this.tryGetIconName(i.contextualHealthcareProfessionIds, i.actionDescriptor.action)
                        });
                    })
                });
            } else if (g instanceof HomeMenuItem) {
                const displayName = this.localizationHelpers.getLocalizedText(g.localizedShortName, g.shortNameResourceId);
                return ({
                    key: getUniqueKey(),
                    title: <></>,
                    items: [{
                        index: index++,
                        key: getUniqueKey(),
                        displayValue: displayName,
                        onSelect: () => this.selectMenuItemAsync.fireAndForget(g),
                        menuItem: g,
                        iconName: this.tryGetIconName(g.contextualHealthcareProfessionIds, g.actionDescriptor.action)
                    }]
                });
            }

            throw new Error("Unknown HomeMenuItem type");

        }) ?? [];

        const item = this.getSavedOrDefaultItem();
        if (!item) return;

        State.runInAction(() => {
            this.selectedItem = item;
            this.saveLastSelectedHomeMenuItem(this.userContext.id.value, item);
        });
    }

    private tryGetIconName(contextualHealthcareProfessionIds: HealthcareProfessionId[] | null, action: FrontendActionBase): iconNameType | null {
        const healthcareProfessions = contextualHealthcareProfessionIds?.map(this.organizationReferenceDataStore.healthCareProfession.get);
        return getIconNameByHealthcareProfessions(healthcareProfessions) ?? this.actionDispatcher.getDisplaySettings(action)?.iconName ?? null;
    }

    private getSavedOrDefaultItem(): HomeMenuItem | null {
        const lastSelectedHomeMenuItem = this.getLastSelectedHomeMenuItem(this.userContext.id.value);
        const allItems = this.itemGroups.flatMap(g => g.items);

        let item: IHomeMenuListItemView | null = null;
        if (lastSelectedHomeMenuItem) {
            item = allItems.find(i => this.stringifyAction(i.menuItem.actionDescriptor.action) === lastSelectedHomeMenuItem);
        }

        item = item ?? (allItems.length > 0 ? allItems[0] : null);

        return item?.menuItem ?? null;
    }

    private readonly selectMenuItemAsync = this.async(async (item: HomeMenuItem) => {
        if (!item) return;

        State.runInAction(() => {
            this.selectedItem = item;
            this.saveLastSelectedHomeMenuItem(this.userContext.id.value, item);
            this.applicationContext.contextualOrganizationUnitId = item.contextualOrganizationUnitId ?? null;
        });

        await this.actionDispatcher.dispatchAsync(item.actionDescriptor.action);
    });

    public readonly showSelectedItemAsync = this.async(async () => {
        await this.selectMenuItemAsync(this.selectedItem);
    });

    private stringifyAction(action: FrontendActionBase) {
        const parts = [action.id.value];

        if ('toUrl' in action) {
            parts.push(...(action as any).toUrl());
        }

        return parts.join('/');
    }

    private getLastSelectedHomeMenuItems(): { [userId: string]: string } {
        try {
            const saved = localStorage.getItem(lastSelectedHomeMenuItemKey);
            const savedHomeMenuItems = JSON.parse(saved);
            if (!savedHomeMenuItems || typeof savedHomeMenuItems !== "object") {
                return {};
            }

            return savedHomeMenuItems;
        } catch (err) {
            Log.warn(`Failed to deserialize the last selected home menu items: ${err.message}`);
            return {};
        }
    }

    private getLastSelectedHomeMenuItem(userId: string): string {
        if (!userId) {
            return null;
        }

        const lastSelectedHomeMenuItems = this.getLastSelectedHomeMenuItems();

        return lastSelectedHomeMenuItems[userId];
    }

    private saveLastSelectedHomeMenuItem(userId: string, homeMenuItem: HomeMenuItem): void {
        if (!userId || !homeMenuItem) {
            return;
        }

        const lastSelectedHomeMenuItems = this.getLastSelectedHomeMenuItems();

        lastSelectedHomeMenuItems[userId] = this.stringifyAction(homeMenuItem.actionDescriptor.action);
        localStorage.setItem(lastSelectedHomeMenuItemKey, JSON.stringify(lastSelectedHomeMenuItems));
    }
}

