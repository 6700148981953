import MedicationId from "@Primitives/MedicationId.g";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import MedicationSubsidyId from "@Primitives/MedicationSubsidyId.g";
import MedicationSubsidyOptionId from "@Primitives/MedicationSubsidyOptionId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FamilyDoctorDocumentMedicationReference from "./FamilyDoctorDocumentMedicationReference";

export default class FamilyDoctorDocumentSingleMedication {
    @State.observable.ref public medicationSubsidyClaimTypeId: MedicationSubsidyClaimTypeId = null;
    @State.observable.ref public medicationSubsidyId: MedicationSubsidyId = null;
    @State.observable.ref public subsidyOptionId: MedicationSubsidyOptionId = null;
    @State.observable.ref public medicationVersionSelector: EntityVersionSelector<MedicationId>;
    @State.observable.ref public substanceDailyAmount: string = "";
    @State.observable.ref public substanceName: string = "";
    @State.observable.ref public practitionerId: PractitionerId = null;

    @State.action.bound
    public setMedicationSubsidyClaimTypeId(medicationSubsidyClaimTypeId: MedicationSubsidyClaimTypeId) {
        this.medicationSubsidyClaimTypeId = medicationSubsidyClaimTypeId;
        this.subsidyOptionId = null;
    }

    @State.action.bound
    public setMedicationVersionSelector(medicationVersionSelector: EntityVersionSelector<MedicationId>) {
        this.medicationVersionSelector = medicationVersionSelector;
    }

    @State.action.bound
    public setSubstanceDailyAmount(substanceDailyAmount: string) {
        this.substanceDailyAmount = substanceDailyAmount;
    }
    @State.action.bound
    public setSubstanceName(substanceName: string) {
        this.substanceName = substanceName;
    }
    @State.action.bound
    public setMedicationSubsidyId(medicationSubsidyId: MedicationSubsidyId) {
        this.medicationSubsidyId = medicationSubsidyId;
    }

    @State.action.bound
    public setSubsidyOptionId(subsidyOptionId: MedicationSubsidyOptionId) {
        this.subsidyOptionId = subsidyOptionId;
    }
    @State.action.bound
    public setPractitionerId(practitionerId: PractitionerId) {
        this.practitionerId = practitionerId;
    }
}