import _ from "@HisPlatform/Common/Lodash";
import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Locking/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class LockToolbarComponentStore {     

    constructor(
        @Di.inject("ILockingClient") private lockingClient: Proxy.ILockingClient
    ) {
    }

    
    public initialize(): any {
    }

    @State.bound
    @State.action
    public async releaseAllLocksAsync() {
        const dto: Proxy.ReleaseAllLocksControllerDto = new Proxy.ReleaseAllLocksControllerDto();
        await this.lockingClient.releaseAllLocksCommandAsync(CreateRequestId(), dto);
    }

    @State.bound
    @State.action
    public async releaseMyLocksAsync() {
        const dto: Proxy.ReleaseMyLocksControllerDto = new Proxy.ReleaseMyLocksControllerDto();
        await this.lockingClient.releaseMyLocksCommandAsync(CreateRequestId(), dto);
    }    
}