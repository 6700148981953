import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import UserColumn from "@HisPlatformControls/DataGridColumns/UserColumn";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import IMasterDetailState from "@CommonControls/Layout/IMasterDetailState";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ICareActivityPreviousTextBlockItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/ICareActivityPreviousTextBlockItem";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import { IPagingState, IOrderingState, DataGridLoadType } from "@CommonControls/DataGrid/IDataGridProps";
import IDataGridColumnProps, { IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import PointOfCareColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import TextBlockTypeSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/TextBlockTypeSelectBox";

interface IPreviousTextBlocksGridDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IPreviousTextBlocksGridProps extends IHasMasterDetailState {
    _dependencies?: IPreviousTextBlocksGridDependencies;
    filterStore: any;
    results: IPagedItems<ICareActivityPreviousTextBlockItem>;
    paging: IPagingState;
    ordering: IOrderingState;
    onChangeAsync: (
        type: DataGridLoadType,
        paging: IPagingState,
        ordering: IOrderingState | IOrderingState[],
        filter: any,
        columns: IDataGridColumnProps[]) => Promise<void>;
}

@State.observer
class PreviousTextBlocksGrid extends React.Component<IPreviousTextBlocksGridProps> {

    @State.computed
    private get masterDetailState() { return this.props._masterDetailState as IMasterDetailState<ICareActivityPreviousTextBlockItem>; }

    @State.computed
    private get selectedId() { return this.masterDetailState.selectedItem; }

    @State.bound
    private setSelectedId(item: ICareActivityPreviousTextBlockItem) {
        return this.masterDetailState?.onSelectedItemChange(item);
    }

    @State.bound
    private onRenderCellValue(value: TextBlockTypeId) {
        if (!value) {
            return "";
        }
        const localizedEnum = this.props._dependencies.careReferenceDataStore.textBlockType.get(value);
        return (<>{localizedEnum.displayValue.getShorthandAndName()}</>);
    }

    @State.bound
    private onRenderFilter(filterProps: IDataGridColumnFilterProps<TextBlockTypeId>) {
        return <TextBlockTypeSelectBox {...filterProps} multiSelect hoverOnlyIndicatorIcons automationId="textBlockTypeFilterSelectBox" />;
    }

    public render() {
        return (
            <Ui.DataGrid
                dataSource={this.props.results}
                onChangeAsync={this.props.onChangeAsync}
                actionsColumn={false}
                rowHeight={50}
                isSelectable
                selectedRow={this.selectedId}
                onSelectedRowChange={this.setSelectedId}
                fixedLayout
                fixedHeight="100%"
                changeOnMount
                paging={this.props.paging}
                ordering={this.props.ordering}
                filterStore={this.props.filterStore}
                generateInitialFilterStore
                hasRefreshButton
                automationId="previousTextBlockGrid_dataGrid">

                <DataGridColumn
                    dataGetter={"textBlockTypeId"}
                    onRenderCellValue={this.onRenderCellValue}
                    onRenderFilter={this.onRenderFilter}
                    isOrderable
                    id="TextBlockTypeIds"
                    header={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.PreviousCareActivityTextBlocksDialog.Columns.TextBlockType}
                    isFilterable
                />
                <PointOfCareColumn
                    dataGetter={"pointOfCareId"}
                    isOrderable
                    id="PointOfCareId"
                    header={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.PreviousCareActivityTextBlocksDialog.Columns.PointOfCare}
                    isFilterable
                />
                <DataGridDateTimeColumn
                    dataGetter={"wentUnderCareAt"}
                    isOrderable
                    id="SearchDateRange"
                    header={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.PreviousCareActivityTextBlocksDialog.Columns.WentUnderCareAt}
                    isFilterable
                />
                <UserColumn
                    dataGetter={"lastModifiedById"}
                    isOrderable
                    id="LastModifiedById"
                    header={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.PreviousCareActivityTextBlocksDialog.Columns.LastModifiedBy}
                    isFilterable
                />
            </Ui.DataGrid>
        );
    }
}

export default connect(
    PreviousTextBlocksGrid,
    new DependencyAdapter<IPreviousTextBlocksGridProps, IPreviousTextBlocksGridDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore")
    }))
);