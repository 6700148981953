import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import moment from "moment";

export default class AccessToken {

    private constructor(public tokenValue: string, public expiresOn: moment.Moment, public refreshTokenValue?: string) {
    }

    public static NowRequested(token: string, expiresInSeconds: number, refreshTokenValue: string): AccessToken {
        return new AccessToken(token, DateTimeService.now().add(expiresInSeconds, "seconds"), refreshTokenValue);
    }

    public static LoadBack(tokenValue: string, expiresAt: string, refreshTokenValue?: string): AccessToken {
        return new AccessToken(tokenValue, moment(expiresAt), refreshTokenValue);
    }
}