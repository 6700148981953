import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import ConditionId from "@Primitives/ConditionId.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface IConditionColumnDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IConditionColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IConditionColumnDependencies;
}


const ConditionColumn: React.FC<IConditionColumnProps> = (props: IConditionColumnProps) => {
    const valueRenderer = useCallback((value: ConditionId) => {
        return (
            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ flexBasis: "auto", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                    {getCondition(props._dependencies.careReferenceDataStore, value)?.code}
                </div>
            </div>
        );
    }, []);

    const hintRenderer = useCallback((value: ConditionId) => {
        return getCondition(props._dependencies.careReferenceDataStore, value)?.name;
    }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderHintValue={hintRenderer}
        />
    );
};

function getCondition(careReferenceDataStore: CareReferenceDataStore, conditionId: ConditionId) {
    return isNullOrUndefined(conditionId)
        ? null
        : careReferenceDataStore.condition.get(new EntityVersionSelector<ConditionId>(conditionId, LocalDate.today()));
}

export default connect(
    ConditionColumn,
    new DependencyAdapter<IConditionColumnProps, IConditionColumnDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore")
    }))
);