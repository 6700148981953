import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PermanentPrescriptionMasterDetailPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/PermanentPrescriptionListPanel/PermanentPrescriptionMasterDetailPanel";
import CareActivityId from "@Primitives/CareActivityId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import { IPermanentPrescriptionRouteParams } from "./PermanentPrescriptionRoutes";

interface IPermanentPrescriptionsStandaloneHostProps extends IRoutingFrameContentProps {
    _careActivityId?: CareActivityId;
}

class PermanentPrescriptionsStandaloneHost extends React.Component<IPermanentPrescriptionsStandaloneHostProps> {
    @State.computed
    private get careActivityId() {
        const careActivityIdString = (this.props.routingController.currentRoute.parameters as IPermanentPrescriptionRouteParams).careActivityId;
        return careActivityIdString
            ? new CareActivityId(careActivityIdString)
            : this.props._careActivityId;
    }

    public render() {
        return <PermanentPrescriptionMasterDetailPanel
            careActivityId={this.careActivityId}
            onBack={this.navigateBackward} />;
    }

    @State.action.bound
    private navigateBackward(): void {
        this.props.routingController?.goBack();
    }
}

export default connect(
    PermanentPrescriptionsStandaloneHost,
    new CareActivityContextAdapter<IPermanentPrescriptionsStandaloneHostProps>(container => ({
        _careActivityId: container.careActivityId
    }))
);