import IExtensibleEnumValue from "./IExtensibleEnumValue";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import ILocalizedObject from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalization";
import StoreBase from "./StoreBase";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export default class ExtensibleEnumStore<TId extends IStringEntityId = any> extends StoreBase {
    @State.observable private map = new Map<string, IExtensibleEnumValue<TId>>();

    @State.observable.ref public isLoaded: boolean = false;
    private isLoading: boolean = false;

    @State.computed
    public get items() {
        return Array.from(this.map.values());
    }

    public get(id: TId) {
        return !isNullOrUndefined(id) ? this.map.get(id.value) : null;
    }

    public async ensureLoadedAsync() {
        if (this.isLoaded === false && this.isLoading === false) {
            this.isLoading = true;
            try {
                const result = await this.lazyLoader();
                if (result.operationWasSuccessful) {
                    State.runInAction(() => {
                        result.value.forEach(v => {
                            const localization = this.localizer(v);
                            this.map.set(v.value, {
                                id: v,
                                displayValue: localization,
                            });
                        });

                        this.isLoaded = true;
                    });
                }
            } finally {
                this.isLoading = false;
            }
        }
    }

    public reload() {
        State.runInAction(() => {
            this.map = new Map<string, IExtensibleEnumValue<TId>>();
            this.isLoaded = false;
        });
        this.ensureLoadedAsync();
    }

    constructor(
        private readonly lazyLoader: () => Promise<SimpleStore<TId[]>>,
        private readonly localizer: (id: TId) => ILocalizedObject
    ) {
        super();
    }
}
