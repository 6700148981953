import ShowCreateNewEpisodeOfCareScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCreateNewEpisodeOfCareScreenAction.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import React, { useCallback } from "react";
import EpisodeOfCareScreen from "@HisPlatform/Packages/Care/Screens/EpisodeOfCareScreen/EpisodeOfCareScreen";
import { IEpisodeOfCareDialogParams, IEpisodeOfCareDialogResult } from "./EpisodeOfCareDialogParams";
import { ScreenStateContextProvider } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import ScreenContextWrapper from "@Toolkit/ReactClient/Components/Screen/ScreenContextWrapper";
import CareScreenContextData from "@HisPlatform/Services/Definition/ActionProcessing/CareScreenContextData";
import PatientScreenContextData from "@HisPlatform/Services/Definition/ActionProcessing/PatientScreenContextData";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";

interface IEpisodeOfCareDialogProps extends IModalComponentParams<IEpisodeOfCareDialogResult>, IEpisodeOfCareDialogParams {
}

function EpisodeOfCareDialog(props: IEpisodeOfCareDialogProps) {
    const action = new ShowCreateNewEpisodeOfCareScreenAction(
        ScreenDisplayMode.Modal,
        props.patientId);

    const screenContextData = props.careActivityId ? new CareScreenContextData(props.careActivityId) : new PatientScreenContextData(props.patientId);

    const cancelled = useCallback(() => {
        props.onClose(null);
    }, [props.onClose]);

    const savedNew = useCallback((newId: EpisodeOfCareId) => {
        props.onClose({ newId: newId });
    }, [props.onClose]);

    return (
        <ScreenStateContextProvider
            action={action}
            screenState={null}
            displayMode={action.displayMode}
            screenContextData={screenContextData}
            key="none"
            onCancelled={cancelled}
            onSavedNew={savedNew}
        >
            <ScreenContextWrapper>
                <EpisodeOfCareScreen
                    action={action}
                    screenState={null}
                />
            </ScreenContextWrapper>
        </ScreenStateContextProvider>
    );
}

export default State.observer(EpisodeOfCareDialog);