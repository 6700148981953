import DependencyContainer from "@DiContainer";
import * as CareProxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import CareActivityTextBlockValidationProblemMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityTextBlock/CareActivityTextBlockValidationProblemMapper";
import CareActivityDocumentValidationProblemMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentValidationProblemMapper";
import PatientValidationProblemMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/Patient/PatientValidationProblemMapper";

DependencyContainer
    .bind("ICareRegisterClient")
    .to(CareProxy.CareRegisterClient);

DependencyContainer
    .bind("IActionsClient")
    .to(CareProxy.ActionsClient);

DependencyContainer
    .bind("IPatientRegisterClient")
    .to(CareProxy.PatientRegisterClient);

DependencyContainer
    .bind("IReferenceDataClient")
    .to(CareProxy.ReferenceDataClient);

DependencyContainer
    .bind("IAllergiesAndIntolerancesClient")
    .to(CareProxy.AllergiesAndIntolerancesClient);

DependencyContainer
    .bind("IServiceRequestManagementClient")
    .to(CareProxy.ServiceRequestManagementClient);

DependencyContainer
    .bind("IMedicalServicesClient")
    .to(CareProxy.MedicalServicesClient);

DependencyContainer
    .bind("IConditionsClient")
    .to(CareProxy.ConditionsClient);

DependencyContainer
    .bind("IMedicationClient")
    .to(CareProxy.MedicationClient);

DependencyContainer
    .bind("CareActivityTextBlockValidationProblemMapper")
    .to(CareActivityTextBlockValidationProblemMapper);

DependencyContainer
    .bind("CareActivityDocumentValidationProblemMapper")
    .to(CareActivityDocumentValidationProblemMapper);

DependencyContainer
    .bind("PatientValidationProblemMapper")
    .to(PatientValidationProblemMapper);

DependencyContainer
    .bind("IVaccinesClient")
    .to(CareProxy.VaccinesClient);

DependencyContainer
    .bind("IImmunizationClient")
    .to(CareProxy.ImmunizationClient);

DependencyContainer
    .bind("IProcedureStatementClient")
    .to(CareProxy.ProcedureStatementClient);

DependencyContainer
    .bind("IAutonomyDisabilitiesClient")
    .to(CareProxy.AutonomyDisabilitiesClient);

DependencyContainer
    .bind("IAutonomyDisabilityStatementClient")
    .to(CareProxy.AutonomyDisabilityStatementClient);

DependencyContainer
    .bind("IConditionStatementClient")
    .to(CareProxy.ConditionStatementClient);

DependencyContainer
    .bind("IDevicesClient")
    .to(CareProxy.DevicesClient);

DependencyContainer
    .bind("IDeviceUseStatementClient")
    .to(CareProxy.DeviceUseStatementClient);

DependencyContainer
    .bind("IPregnancyStatementClient")
    .to(CareProxy.PregnancyStatementClient);

DependencyContainer
    .bind("IMedicationStatementClient")
    .to(CareProxy.MedicationStatementClient);

DependencyContainer
    .bind("ILifeStyleRisksClient")
    .to(CareProxy.LifeStyleRisksClient);

DependencyContainer
    .bind("IRiskAssessmentClient")
    .to(CareProxy.RiskAssessmentClient);

DependencyContainer
    .bind("IPatientAllergyIntoleranceClient")
    .to(CareProxy.PatientAllergyIntoleranceClient);

DependencyContainer
    .bind("IMedicalAlertsClient")
    .to(CareProxy.MedicalAlertsClient);

DependencyContainer
    .bind("IMedicalAlertStatementClient")
    .to(CareProxy.MedicalAlertStatementClient);