/* auto-inject-disable */
import Di from "@Di";
import Command from "@Toolkit/CommonWeb/CommandProcessing/Definition/Command";
import ICommandDispatcher from "@Toolkit/CommonWeb/CommandProcessing/Definition/ICommandDispatcher";
import IGenericCommandProcessor from "@Toolkit/CommonWeb/CommandProcessing/Definition/IGenericCommandProcessor";

/**
 * A service responsible for dispatching commands to the appropriate processors.
 */
@Di.injectable()
export default class CommandDispatcher implements ICommandDispatcher {
    constructor(
        @Di.multiInject("IGenericCommandProcessor") @Di.optional() private readonly _commandProcessors: IGenericCommandProcessor[]
    ) {
    }

    public dispatchAsync(command: Command): Promise<void> {
        const processor = this._commandProcessors.find(item => item.commandType === command.commandType);
        if (!processor) {
            throw new Error(`None of the command processors can process a command of type '${command.commandType}'.`);
        }

        return processor.processGenericAsync(command);
    }
}