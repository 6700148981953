import { IRowIndicatorStyle } from "@CommonControls/DataGrid/IDataGridProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import * as Styles from "./WorklistPanel.less";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import ServiceRequestState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestState.g";

export function getRowIndicatorStyle(row: any): IRowIndicatorStyle {
    if (!isNullOrUndefined(row.ServiceRequestBased_ServiceRequestState)) {
        switch (row.ServiceRequestBased_ServiceRequestState) {
            case ServiceRequestState.UnderRecording:
                return { className: Styles.serviceRequsetStateUnderRecording };
            case ServiceRequestState.Submitted:
            case ServiceRequestState.WaitingForSubmission:
                return { className: Styles.serviceRequsetStateSubmitted };
            case ServiceRequestState.RegisteredAsReceived:
                return { className: Styles.serviceRequsetStateRegisteredAsReceived };
            case ServiceRequestState.ActivityCompleted:
                return { className: Styles.serviceRequsetStateActivityCompleted };
            case ServiceRequestState.NonValidatedCompleted:
                return { className: Styles.serviceRequsetStateNonValidatedCompleted };
            case ServiceRequestState.ValidatedCompleted:
                return { className: Styles.serviceRequsetStateValidatedCompleted };
            case ServiceRequestState.Deleted:
            case ServiceRequestState.WaitingForDelete:
            case ServiceRequestState.HasError:
                return { className: Styles.serviceRequsetStateDeleted };
            default:
                return null;
        }
    } else {
        switch (row.CareActivityBased_State) {
            case CareActivityState.Admitted:
                return { className: Styles.careActivityStateAdmitted };
            case CareActivityState.Waiting:
                return { className: Styles.careActivityStateWaiting };
            case CareActivityState.AdmissionDeleted:
                return { className: Styles.careActivityStateAdmissionDeleted };
            case CareActivityState.Deleted:
                return { className: Styles.careActivityStateDeleted };
            case CareActivityState.DidNotAttend:
                return { className: Styles.careActivityStateDidNotAttend };
            case CareActivityState.InProgress:
                return { className: Styles.careActivityStateUnderCare };
            case CareActivityState.Suspended:
                return { className: Styles.careActivityStateSuspended };
            case CareActivityState.Discharged:
                return { className: Styles.careActivityStateDischarged };
            case CareActivityState.Closed:
                return { className: Styles.careActivityStateClosed };
            default:
                return null;
        }
    }
}
