import {IValidationProblemProcessor} from "@Toolkit/CommonWeb/Validation/ValidationProblemProcessorService";
import MedicalServiceFinancingStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/MedicalServiceFinancingStore";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import _ from "@HisPlatform/Common/Lodash";
import MedicalServiceFinancingId from "@Primitives/MedicalServiceFinancingId.g";
import React from "react";
import {wrappedValuesAreEquals} from "@HisPlatform/Common/ValueWrapperHelpers";

export default class QuantityRuleValidationProblemProcessor implements IValidationProblemProcessor<MedicalServiceFinancingStore> {
    public ruleId: string = "ShouldNotContainPerformedServiceWithZeroQuantity";
    public isRuleHighlightable = true;

    public mapValidationProblem(problem: IClientValidationProblem, items: MedicalServiceFinancingStore[]): MedicalServiceFinancingStore[] {
        const serviceId = this.getServiceId(problem);
        return items.filter(item => wrappedValuesAreEquals(item.id, serviceId));
    }

    public renderInfo = (problem: IClientValidationProblem) => undefined as React.ReactNode;

    private getServiceId(problem: IClientValidationProblem) {
        const serviceIdValue = _.get(problem.parameters, "PerformedServiceQuantityViolationParameterDto.MedicalServiceFinancingId.Value");
        return serviceIdValue ? new MedicalServiceFinancingId(serviceIdValue.toString()) : null;
    }
}
