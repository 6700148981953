import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import StoreBase from "@Toolkit/CommonWeb/Model/StoreBase";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import AsyncMessageQueue from "@Toolkit/CommonWeb/AsyncMessageQueue";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ReferenceDataCollector from "./ReferenceDataCollector";

export default class ReferenceDataStore<TId extends IStringEntityId, TStore extends { id: IStringEntityId }> extends StoreBase {
    @State.observable protected map = new Map<string, TStore>();
    private messageQueue = new AsyncMessageQueue<TId[]>(this._ensureLoadedAsync, this.name);
    private debouncedIds: TId[] = [];
    private allLoaded = false;

    private collector: ReferenceDataCollector<TId> = new ReferenceDataCollector<TId>();

    @State.computed public get items() {
        return Array.from(this.map.values());
    }

    @State.bound
    public async ensureLoadedAsync(ids: TId[]) {
        const notNulls = ids?.filter(Boolean);
        if (notNulls?.length > 0) {
            await this.messageQueue.enqueueAndProcessAsync(notNulls);
        }
    }

    @State.bound
    public preLoadItems(stores: TStore[]) {
        State.runInAction(() => {
            stores.forEach((item) => {
                if (!this.map.has(item.id.value)) {
                    this.map.set(item.id.value, item);
                }
            });
        });
    }

    @State.bound
    public async reloadAsync() {
        this.invalidate();
        await this.ensureAllLoadedAsync();
    }

    @State.bound
    public debouncedEnsureLoaded(ids: TId[]) {
        this.debouncedIds.push(...ids);
        this.processDebouncedIds();
    }

    private processDebouncedIds = _.debounce(() => {
        const debouncedIds = this.debouncedIds;
        this.debouncedIds = [];
        dispatchAsyncErrors(this.ensureLoadedAsync(debouncedIds), null);
    }, 250);

    @State.bound
    private async _ensureLoadedAsync(ids: TId[]) {

        let newIds = ids.filter(id => !!id && isNullOrUndefined(this.map.get(id.value)));
        newIds = _.uniqBy(newIds, id => id.value);

        if (newIds.length === 0) {
            return;
        }

        const newItemsStore = await this.loadAsync(newIds);
        this.updateOperationInfoFromStore(newItemsStore);

        if (!this.operationWasSuccessful) {
            return;
        }

        State.runInAction(() => {
            newItemsStore.value.forEach((item) => {
                this.map.set(item.id.value, item);
            });
        });
    }

    @State.bound
    public get(id: TId): TStore {
        if (isNullOrUndefined(id)) {
            return null;
        }
        return this.map.get(id.value) || null;
    }

    @State.bound
    public multiGet(ids: TId[]): TStore[] {
        const notNulls = ids?.filter(Boolean);
        if (notNulls?.length > 0) {
            return ids.map(id => {
                const mappedValue = this.map.get(id.value);
                if (isNullOrUndefined(mappedValue)) {
                    throw new Error(`No item found in store for id ${id.value}.`);
                }
                return mappedValue;
            });
        }
        return null;
    }

    public has(id: TId): boolean {
        if (isNullOrUndefined(id)) {
            return false;
        }
        return this.map.has(id.value);
    }

    public async getOrLoadAsync(id: TId): Promise<TStore> {
        await this.ensureLoadedAsync([id]);
        return this.get(id);
    }

    public invalidate() {
        this.allLoaded = false;
        this.map.clear();
    }

    @State.bound
    public async ensureAllLoadedAsync() {
        if (this.allLoaded) {
            return;
        }

        let ids: TId[] = [];
        const result = this.loadAllIdsAsync && await this.loadAllIdsAsync();
        if (result && result.value) {
            ids = result.value;
        }

        await this.ensureLoadedAsync(ids);
        this.allLoaded = true;
    }

    @State.bound
    public collectId(id: TId) {
        this.collector.collect(id);
    }

    @State.bound
    public async loadCollectedAsync() {
        await this.ensureLoadedAsync(this.collector.getCollectedIds());
    }

    constructor(
        private readonly loadAsync: (ids: TId[]) => Promise<SimpleStore<TStore[]>>,
        public readonly loadAllIdsAsync?: () => Promise<SimpleStore<TId[]>>,
        private readonly name: string = ""
    ) {
        super();
    }
}
