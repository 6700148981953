import React, { useMemo } from "react";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Styles from "./CareActivityStateIcon.less";
import EnumBadge from "@CommonControls/EnumBadge";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";

interface ICareActivityStateIconProps {
    state: CareActivityState;
    isIndex?: boolean;
    hasBorder?: boolean;
    className?: string;
}

function CareActivityStateIcon(props: ICareActivityStateIconProps): React.ReactElement {

    if (isNullOrUndefined(props.state)) {
        return null;
    }

    const className = useMemo(() => {
        switch (props.state) {
            case CareActivityState.Admitted:
                return Styles.stateAdmitted;
            case CareActivityState.Waiting:
                return Styles.stateWaiting;
            case CareActivityState.AdmissionDeleted:
                return Styles.stateAdmissionDeleted;
            case CareActivityState.Deleted:
                return Styles.stateDeleted;
            case CareActivityState.DidNotAttend:
                return Styles.stateDidNotAttend;
            case CareActivityState.InProgress:
                return Styles.stateInProgress;
            case CareActivityState.Suspended:
                return Styles.stateSuspended;
            case CareActivityState.Discharged:
                return Styles.stateDischarged;
            case CareActivityState.Closed:
                return Styles.stateClosed;
            default:
                return null;
        }
    }, [props.state]);

    return (
        <EnumBadge
            enumType={CareActivityState}
            enumName={"CareActivityState"}
            value={props.state}
            className={combineClasses(className, props.className)}
            isIndex={!!props.isIndex}
            bordered={!!props.hasBorder} />
    );
}

export default CareActivityStateIcon;