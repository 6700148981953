import React from "react";
import * as Ui from "@CommonControls";
import StaticWebAppResources from "@StaticResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IRoleListToolbarProps {
    onAddRole: () => void;
}


const RoleListToolbar: React.FC<IRoleListToolbarProps> = (props) => {
    return (
        <Ui.AddButton
            onClick={props.onAddRole}
            automationId="addRoleButton"
        />
    );
};

export default State.observer(RoleListToolbar);
