exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PageBoxContainer_outerDiv_1sgPi {\n  width: 100%;\n  height: 100%;\n  padding-left: var(--padding);\n  padding-right: var(--padding);\n}\n", ""]);

// exports
exports.locals = {
	"outerDiv": "PageBoxContainer_outerDiv_1sgPi"
};