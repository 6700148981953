import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import EhiCovidTestResultsPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidTestResultsPanel/EhiCovidTestResultsPanel";
import SingleLayout from "@CommonControls/Layout/SingleLayout";


interface IEhiCovidTestResultsPageProps extends IRoutingFrameContentProps {
}

@State.observer
class EhiCovidTestResultsPage extends React.Component<IEhiCovidTestResultsPageProps> {

    public render() {
        return (
            <SingleLayout>
                <EhiCovidTestResultsPanel />
            </SingleLayout>
        );
    }
}

export default connect(
    EhiCovidTestResultsPage
);
