import Di from "@Di";
import IExtensionComponentRegistry, { ExtensionComponent } from "./IExtensionComponentRegistry";

@Di.injectable()
export default class ExtensionComponentRegistry<TExtensionPointType> implements IExtensionComponentRegistry<TExtensionPointType> {

    private readonly _registry = new Map<TExtensionPointType, Array<{ component: ExtensionComponent, extensionComponentServiceName: string }>>();

    public register(type: TExtensionPointType, component: ExtensionComponent, extensionComponentServiceName: string) {
        const existing = this._registry.get(type);

        if (existing) {
            existing.unshift({ component: component, extensionComponentServiceName: extensionComponentServiceName });
        } else {
            this._registry.set(type, [{ component: component, extensionComponentServiceName: extensionComponentServiceName }]);
        }
    }

    public get(type: TExtensionPointType): Array<{ component: ExtensionComponent, extensionComponentServiceName: string }> {
        return this._registry.get(type) ?? [];
    }
}