import SelectBox from "@CommonControls/SelectBox";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import VatRateId from "@Primitives/VatRateId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { useAsyncMemo } from "@Toolkit/ReactClient/Common/Hooks";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import React from "react";

interface IVatRateSelectBoxProps extends ISelectBoxBaseProps<VatRateId> {
}

export default function VatRateSelectBox(props: IVatRateSelectBoxProps) {

    const { financeReferenceDataStore } = useDependencies(c => ({
        financeReferenceDataStore: c.resolve<FinanceReferenceDataStore>("FinanceReferenceDataStore"),
    }));
    
    const { value: options, isLoading } = useAsyncMemo<Array<ISelectBoxItem<VatRateId>>>([], async () => {
        await financeReferenceDataStore.vatRateMap.ensureLoadedAsync();
        return financeReferenceDataStore.vatRateMap.items.map<ISelectBoxItem<VatRateId>>(vi => ({
            value: vi.id,
            text: vi.code,
        }));
    }, []);

    return (
        <SelectBox 
            {...props}
            items={options}
            equalityComparer={ValueWrapper.equals}
            loading={isLoading}
        />
    );
}