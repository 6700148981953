import React from "react";

export interface IPortalHostProps {
    className?: string;
    style?: React.CSSProperties;
    elementType?: keyof React.ReactHTML;
    name: string;
}

function PortalHost(props: React.PropsWithChildren<IPortalHostProps>): React.ReactElement {
    return React.createElement(props.elementType, {
        className: props.className,
        style: props.style,
        id: "portal_" + props.name
    });
}

const reactElement = PortalHost as React.FC<IPortalHostProps>;
reactElement.defaultProps = {
    elementType: "div"
};

export default PortalHost;