/* auto-inject-disable */
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import CareActivityId from "@Primitives/CareActivityId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import { IPagingState, IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPrescriptionListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IPrescriptionListItem";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import Di from "@Di";
import { GetTypeNameWithPrefixExt } from "@HunEHealthInfrastructurePlugin/PluginHelper";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Prescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Prescription";
import PrescriptionStoreMapper from "./PrescriptionStoreMapper";
import { getCreatePrescriptionDto, getUpdatePrescriptionDto, getMedicationReferenceBaseDto, getDosageDto, getPrescriptionDto } from "./PrescriptionDtoMapper";
import PrescriptionId from "@Primitives/PrescriptionId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { mapDosage, mapEhiMedicationBase } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/MedicationRequestMapperHelpers";
import EquipmentPositionId from "@Primitives/EquipmentPositionId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import TextualPrescriptionTemplateId from "@Primitives/TextualPrescriptionTemplateId.g";
import TextualPrescriptionTemplate from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/TextualPrescriptionTemplate";
import TextualPrescriptionTemplateStoreMapper from "./TextualPrescriptionTemplateStoreMapper";
import ITextualPrescriptionTemplateListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/ITextualPrescriptionTemplateListItem";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import OrderTypeId from "@Primitives/OrderTypeId.g";
import TargetValueId from "@Primitives/TargetValueId.g";
import Dosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Dosage";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import PrescriptionSearchBase from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionSearchBase";
import StatusChangeReasonId from "@Primitives/StatusChangeReasonId.g";
import HealthcareProfessionId from "@Primitives/HealthcareProfessionId.g";
import IPrescriptionProduct from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IPrescriptionProduct";
import IPrescriptionExtensionDataProvider from "@PluginInterface/BoundedContexts/MedicationRequest/Prescription/IPrescriptionExtensionDataProvider";
import MedicationId from "@Primitives/MedicationId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import PatientId from "@Primitives/PatientId.g";
import PrescriptionTemplateType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionTemplateType";
import IPrescriptionStatusColumnData from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IPrescriptionStatusColumnData";
import PrescriptionDataSource from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionDataSource";
import EhiPrescriptionSearchMode from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/EhiPrescriptionSearchMode";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IPrescriptionToBeCreated from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionsToBeCreated/IPrescriptionToBeCreated";
import IExternalPrescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IExternalPrescription";
import EhiPrescriptionStatusId from "@Primitives/EhiPrescriptionStatusId.g";
import EhiPrescriptionCategoryId from "@Primitives/EhiPrescriptionCategoryId.g";
import SubstanceBasedRestrictionId from "@Primitives/SubstanceBasedRestrictionId.g";
import IPrintPrescriptionResponse from "./IPrintPrescriptionResponse";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import PrescriptionPrintDataId from "@Primitives/PrescriptionPrintDataId.g";
import { IPrescriptionPrintDataResponse } from "./IPrescriptionPrintDataResponse";
import Guid from "@Toolkit/CommonWeb/Guid";
import IEhiResult from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiResult";
import EhiErrorSeverity from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiErrorSeverity";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import PermanentPrescriptionId from "@Primitives/PermanentPrescriptionId.g";
import PrescriptionInfo from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionInfo";
import PermanentPrescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PermanentPrescription";
import PermanentPrescriptionStoreMapper from "./PermanentPrescriptionStoreMapper";
import PermanentPrescriptionDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PermanentPrescriptionDosage";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import { IPermanentPrescriptionListItem } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IPermanentPrescriptionListItem";
import ICondition from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/ICondition";
import IPractitioner from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IPractitioner";
import IPatient from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IPatient";
import IPractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IPractitionerRecommendation";
import { QueryPaging } from "@HisPlatform/BoundedContexts/CommonReferenceData/Api/Proxy.g";
import Orientation from "@HisPlatform/BoundedContexts/DocumentManagement/Api/PdfTemplating/Enum/Orientation.g";
import PaperSize from "@HisPlatform/BoundedContexts/DocumentManagement/Api/PdfTemplating/Enum/PaperSize.g";
import Rotation from "@HisPlatform/BoundedContexts/DocumentManagement/Api/PdfTemplating/Enum/Rotation.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import GetPermanentPrescriptionsQueryOrderingFields from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/GetPermanentPrescriptionsQueryOrderingFields.g";
import GetPrescriptionsQueryOrderingFields from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/GetPrescriptionsQueryOrderingFields.g";
import PrescriptionItemType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/PrescriptionItemType.g";
import PrescriptionStatus from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/PrescriptionStatus.g";

@Di.injectable()
export default class PrescriptionApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefixExt("IPrescriptionClient", "MedicationRequest")) private readonly apiClient: Proxy.IPrescriptionClient,
        @Di.inject("PrescriptionStoreMapper") private readonly prescriptionMapper: PrescriptionStoreMapper,
        @Di.inject("TextualPrescriptionTemplateStoreMapper") private readonly textualPrescriptionTemplateStoreMapper: TextualPrescriptionTemplateStoreMapper,
        @Di.inject("PermanentPrescriptionStoreMapper") private readonly permanentPrescriptionMapper: PermanentPrescriptionStoreMapper,
        @Di.inject("IPrescriptionExtensionDataProvider") private readonly prescriptionExtensionDataProvider: IPrescriptionExtensionDataProvider
    ) {
        super();
    }

    @State.bound
    public getPrescriptionsAsync(
        careActivityId: CareActivityId,
        patientId: PatientId,
        range: LocalDateRange,
        paging: IPagingState,
        ordering: IOrderingState,
        statuses: PrescriptionStatus[],
        medicationName: string,
        pointOfCareIds: PointOfCareId[],
        practitionerId: PractitionerId,
        prescriptionSearchBase: PrescriptionSearchBase,
        healthCareProfessionIds: HealthcareProfessionId[],
        prescriptionDataSource: PrescriptionDataSource,
        ehiPrescriptionSearchMode: EhiPrescriptionSearchMode,
        prescriptionSearchStart: LocalDate,
        prescriptionSearchMonths: number,
        isEmergency: boolean,
        emergencyReason: string,
        ehiPrescriptionStatuses: EhiPrescriptionStatusId[],
        ehiPrescriptionIdentifier: string,
        ehiCareIdentifier: string,
        extensionData: any
    ) {
        return this.processOperationAsync(
            new PagedItemStore<IPrescriptionListItem>(),
            async target => {
                const response = await this.apiClient.getPrescriptionsQueryAsync(CreateRequestId(), new Proxy.GetPrescriptionsControllerDto({
                    careActivityId,
                    patientId,
                    dateRange: range,
                    medicationName: medicationName,
                    statuses,
                    pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfGetPrescriptionsQueryOrderingFields({
                        orderings: [new Proxy.QueryOrderingOfGetPrescriptionsQueryOrderingFields({
                            direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                            fieldName: GetPrescriptionsQueryOrderingFields[ordering.columnId]
                        })],
                        paging: new QueryPaging({
                            pageIndex: paging.currentPage,
                            pageSize: paging.pageSize
                        })
                    }),
                    pointOfCareIds,
                    practitionerId,
                    prescriptionSearchBase,
                    healthCareProfessionIds,
                    prescriptionDataSource,
                    searchDateRangeStart: prescriptionSearchStart,
                    searchDateRangeMonths: prescriptionSearchMonths,
                    ehiPrescriptionSearchMode,
                    isEmergency,
                    emergencyReason,
                    ehiPrescriptionStatuses,
                    ehiPrescriptionIdentifier,
                    ehiCareIdentifier: ehiCareIdentifier,
                    extensionData: extensionData
                }));
                target.extensionData = response.extensionData;
                target.operationInfo = createOperationInfo(response);
                target.totalCount = response.results?.totalCount;
                target.items = response.results?.values?.map(i => {
                    const isMedicationFormula = i.itemType === PrescriptionItemType.MedicationFormula;
                    return {
                        id: i.id,
                        itemType: i.itemType,
                        conditionId: i.conditionId,
                        prescribedOn: i.prescribedOn,
                        statusColumn: {
                            status: i.status,
                            digitalPrescriptionIdentifier: i.digitalPrescriptionIdentifier
                        } as IPrescriptionStatusColumnData,
                        subsidyClaimTypeId: i.medicationSubsidyClaimTypeId,
                        amount: i.amount,
                        packagingUnitId: i.packagingUnitId,
                        dosage: mapDosage(i.dosage),
                        pointOfCareCode: i.pointOfCareCode,
                        pointOfCareName: i.pointOfCareName,
                        products: i.products.map(product => ({
                            name: this.getPrescriptionListItemName(isMedicationFormula, product.medicationFormulaName, product.name),
                            suppliedMonths: i.suppliedMonths,
                            medicationVersionSelector: new EntityVersionSelector<MedicationId>(product.medicationVersionSelector.entityId, product.medicationVersionSelector.validOn),
                            amount: i.amount
                        } as IPrescriptionProduct)),
                        ehiStatus: i.ehiPrescriptionStatusId,
                        textAmount: isMedicationFormula ? "" : i.textAmount,
                        extensionData: i.extensionData
                    } as IPrescriptionListItem;
                });
            }
        );
    }
    @State.bound
    private getPrescriptionListItemName(isMedicationFormula: boolean, medicationFormulaName: string, name: string) {
        if (isMedicationFormula) {
            return isNullOrEmptyString(medicationFormulaName) ? StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.MedicationFormulaName : medicationFormulaName;
        }
        return name;
    }

    @State.bound
    public getExternalPrescriptionDataAsync(identifier: string, careActivityId: CareActivityId, isEmergency: boolean, emergencyReason: string, prescriptionItemType: PrescriptionItemType, extensionData: any) {
        return this.processOperationAsync(
            new SimpleStore<IEhiResult<IExternalPrescription>>(),
            async target => {
                const response = await this.apiClient.getExternalPrescriptionDataQueryAsync(CreateRequestId(), new Proxy.GetExternalPrescriptionDataControllerDto({
                    externalIdentifier: identifier,
                    careActivityId: careActivityId,
                    isEmergency: isEmergency,
                    emergencyReason: emergencyReason,
                    prescriptionItemType: prescriptionItemType,
                    extensionData: extensionData
                }));
                target.operationInfo = createOperationInfo(response);
                const errors = response.extensionData["EhiError"];
                target.value = {
                    value: !!response.prescriptionData ? {
                        identifier: response.prescriptionData.identifier,
                        status: response.prescriptionData.status,
                        ehiPrescriptionCategoryId: response.prescriptionData.ehiPrescriptionCategoryId,
                        ehiPrescriptionTypeId: response.prescriptionData.ehiPrescriptionTypeId,
                        ehiProductType: response.prescriptionData.ehiProductType,
                        condition: {
                            code: response.prescriptionData.condition.key,
                            name: response.prescriptionData.condition.value
                        } as ICondition,
                        practitioner: {
                            identifier: response.prescriptionData.practitioner.identifier,
                            firstName: response.prescriptionData.practitioner.firstName,
                            lastName: response.prescriptionData.practitioner.lastName
                        } as IPractitioner,
                        patient: {
                            identifier: response.prescriptionData.patient.identifier,
                            name: response.prescriptionData.patient.name,
                            address: response.prescriptionData.patient.address,
                            birthDate: response.prescriptionData.patient.birthDate,
                        } as IPatient,
                        careIdentifier: response.prescriptionData.careIdentifier,
                        organizationId: response.prescriptionData.organizationId,
                        organizationName: response.prescriptionData.organizationName,
                        organizationUnitId: response.prescriptionData.organizationUnitId,
                        organizationUnitName: response.prescriptionData.organizationUnitName,
                        prescribedOn: response.prescriptionData.prescribedOn,
                        validity: response.prescriptionData.validity,
                        isRecurrent: response.prescriptionData.isRecurrent,
                        isCountersignMandatory: response.prescriptionData.isCountersignMandatory,
                        practitionerRecommendation: !!response.prescriptionData.practitionerRecommendation
                            ? {
                                practitionerCode: response.prescriptionData.practitionerRecommendation?.practitioner?.key,
                                practitionerName: response.prescriptionData.practitionerRecommendation?.practitioner?.value,
                                logNumber: response.prescriptionData.practitionerRecommendation?.logNumber,
                                date: response.prescriptionData.practitionerRecommendation?.date
                            } as IPractitionerRecommendation
                            : null,
                        products: response.prescriptionData.products.map((product) => mapEhiMedicationBase(product)),
                        sizeChangeDescription: response.prescriptionData.sizeChangeDescription,
                        stateChangeDescription: response.prescriptionData.stateChangeDescription,
                        extensionData: response.prescriptionData.extensionData
                    } as IExternalPrescription : null,
                    errors: errors?.map((e: any) => {
                        return new EhiError(e.Code, e.ModuleName, e.Message, e.EhiErrorSeverity as any as EhiErrorSeverity);
                    }) || []
                };
            }
        );
    }

    @State.bound
    public getPrescriptionByIdAsync(id: PrescriptionId, requestLock: boolean) {
        return this.processOperationAsync(
            new Prescription(),
            async target => {
                const response = await this.apiClient.getPrescriptionByIdCommandAsync(CreateRequestId(), new Proxy.GetPrescriptionByIdControllerDto({
                    requestLock: requestLock,
                    prescriptionId: id
                }));
                return this.prescriptionMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public createPrescriptionsAsync(
        prescriptionsToBeCreated: IPrescriptionToBeCreated[],
        careActivityId: CareActivityId,
        requestLock: boolean,
        isPermissionCheckOnly: boolean) {
        const prescriptionToBeCreatedDtos = prescriptionsToBeCreated.map((prescriptionToBeCreated) => {
            return new Proxy.PrescriptionCreateItemDto({
                medicationReferenceBase: getMedicationReferenceBaseDto(prescriptionToBeCreated.reference),
                medicationSubsidyClaimTypeId: prescriptionToBeCreated.claimTypeId,
                prescriber: prescriptionToBeCreated.prescriber
            });
        });
        return this.processOperationAsync(
            new Prescription(),
            async target => {
                const response = await this.apiClient.createPrescriptionsCommandAsync(CreateRequestId(),
                    new Proxy.CreatePrescriptionsControllerDto({
                        careActivityId: careActivityId,
                        prescriptionsToBeCreated: prescriptionToBeCreatedDtos,
                        requestLock: requestLock
                    }), isPermissionCheckOnly);

                this.prescriptionMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public createPrescriptionAsync(prescription: Prescription, requestLock: boolean, isValidateOnly = false) {
        return this.processOperationAsync(
            new Prescription(),
            async target => {
                const response = await this.apiClient.createPrescriptionCommandAsync(CreateRequestId(), getCreatePrescriptionDto(prescription, requestLock, isValidateOnly));
                return this.prescriptionMapper.applyToStore(target, response);
            }
        );
    }

    public printPrescriptionAsync(
        prescriptionId: PrescriptionId,
        orientation: Orientation,
        paperSize: PaperSize,
        rotation: Rotation,
        offsetX: number,
        offsetY: number,
        isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new SimpleStore<IPrintPrescriptionResponse>(),
            async target => {
                const request = new Proxy.PrintPrescriptionControllerDto({
                    prescriptionId: prescriptionId,
                    orientation: orientation,
                    paperSize: paperSize,
                    rotation: rotation,
                    offsetX: offsetX * 100,
                    offsetY: offsetY * 100,
                });
                const response = await this.apiClient.printPrescriptionCommandAsync(CreateRequestId(), request, isPermissionCheckOnly);
                target.operationInfo = createOperationInfo(response);
                target.value = {
                    largeDataToken: response.printoutLargeDataToken,
                    downloadFileName: response.downloadFileName,
                    isAlreadyPrinted: response.isAlreadyPrinted
                } as IPrintPrescriptionResponse;
            }
        );

    }

    @State.bound
    public updatePrescriptionAsync(prescription: Prescription, releaseLock: boolean, isValidateOnly: boolean = false, isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new Prescription(),
            async target => {
                const response = await this.apiClient.updatePrescriptionCommandAsync(CreateRequestId(), getUpdatePrescriptionDto(prescription, releaseLock, prescription.lockInfo.lockId, isValidateOnly), isPermissionCheckOnly);
                return await this.prescriptionMapper.applyToStoreWithExistingLockAndResolveValidationProblemsAsync(target, response, prescription.lockInfo, releaseLock);
            }
        );
    }

    @State.bound
    public createPrescriptionPrintDataAsync(prescriptionId: PrescriptionId, reprintReason: string) {
        return this.processOperationAsync(
            new SimpleStore<IPrescriptionPrintDataResponse>(),
            async target => {
                const response = await this.apiClient.createPrescriptionPrintDataCommandAsync(CreateRequestId(), {
                    prescriptionId: prescriptionId,
                    reprintReason: reprintReason
                } as Proxy.CreatePrescriptionPrintDataControllerDto);
                target.operationInfo = createOperationInfo(response);
                target.value = {
                    prescriptionPrintDataId: response.id,
                    deleteToken: response.deleteToken,
                    validationResults: mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult)
                };
            }
        );
    }

    @State.bound
    public deletePrescriptionPrintDataAsync(id: PrescriptionPrintDataId, deleteToken: Guid) {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.deletePrescriptionPrintDataCommandAsync(CreateRequestId(), {
                    id: id,
                    deleteToken: deleteToken
                } as Proxy.DeletePrescriptionPrintDataControllerDto);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public setPrescriptionStatusAsync(
        prescription: Prescription,
        status: PrescriptionStatus,
        releaseLock: boolean,
        isValidateOnly: boolean = false,
        statusChangeReasonId: StatusChangeReasonId = null,
        additionalText: string = null,
        alsoUpdate: boolean = false,
        extensionData: any = null,
        isPermissionCheckOnly = false
    ) {
        return this.processOperationAsync(
            new Prescription(),
            async target => {
                const response = await this.apiClient.setPrescriptionStatusCommandAsync(CreateRequestId(), new Proxy.SetPrescriptionStatusControllerDto({
                    prescriptionId: prescription.id,
                    status: status,
                    statusChangeReasonId: statusChangeReasonId,
                    additionalText: additionalText,
                    lockId: prescription.lockInfo.lockId,
                    releaseLockIfSuccessful: releaseLock,
                    isValidateOnly: isValidateOnly,
                    extensionData: extensionData,
                    prescription: alsoUpdate && prescription && getPrescriptionDto(prescription)
                }), isPermissionCheckOnly);
                const releaseLockIfSuccessful = target.status === status && releaseLock;
                return this.prescriptionMapper.applyToStoreWithExistingLockAndResolveValidationProblemsAsync(target, response, prescription.lockInfo, releaseLockIfSuccessful);
            }
        );
    }

    @State.bound
    public getDaysSuppliedQueryAsync(amount: number, dosage: Dosage, dosageAmount: number, isUnpackaged: boolean, substanceBasedRestrictionId: SubstanceBasedRestrictionId) {
        const dosageDto = getDosageDto(dosage);

        return this.processOperationAsync(
            new SimpleStore<number>(),
            async target => {
                const response = await this.apiClient.getDaysSuppliedQueryAsync(CreateRequestId(), new Proxy.GetDaysSuppliedControllerDto({
                    amount: amount,
                    dosage: dosageDto,
                    dosageAmount: dosageAmount,
                    isUnpackaged: isUnpackaged,
                    substanceBasedRestrictionId: substanceBasedRestrictionId
                }));
                target.operationInfo = createOperationInfo(response);
                target.value = response.daysSupplied;
            }
        );
    }

    @State.bound
    public getEquipmentPositionIds() {
        return this.processOperationAsync(
            new SimpleStore<EquipmentPositionId[]>(),
            async target => {
                const response = await this.apiClient.getEquipmentPositionIdsQueryAsync(CreateRequestId(), LocalDate.today().stringify());
                target.value = response.equipmentPositionIds;
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getTextualPrescriptionTemplateByIdAsync(id: TextualPrescriptionTemplateId) {
        return this.processOperationAsync(
            new TextualPrescriptionTemplate(),
            async target => {
                const response = await this.apiClient.getTextualPrescriptionTemplateByIdQueryAsync(CreateRequestId(), new Proxy.GetTextualPrescriptionTemplateByIdControllerDto({
                    id: id
                }));
                this.textualPrescriptionTemplateStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public getTextualPrescriptionTemplatesAsync(isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new SimpleStore<ITextualPrescriptionTemplateListItem[]>(),
            async target => {
                const response = await this.apiClient.getTextualPrescriptionTemplatesQueryAsync(CreateRequestId(), new Proxy.GetTextualPrescriptionTemplatesControllerDto(), isPermissionCheckOnly);
                target.operationInfo = createOperationInfo(response);

                if (!isPermissionCheckOnly) {
                    target.value = response.templates?.map(i => ({ id: i.id, name: i.name, type: i.type, lastModifiedBy: i.lastModifiedBy, lastModifiedAt: i.lastModifiedAt }));
                }
            }
        );
    }

    @State.bound
    public updateTextualPrescriptionTemplateAsync(template: TextualPrescriptionTemplate) {
        return this.processOperationAsync(
            new TextualPrescriptionTemplate(),
            async target => {
                const response = await this.apiClient.updateTextualPrescriptionTemplateCommandAsync(CreateRequestId(), new Proxy.UpdateTextualPrescriptionTemplateControllerDto({
                    id: template.id,
                    rowVersion: template.rowVersion,
                    content: template.content,
                    preparation: template.preparation,
                    displayName: template.displayName,
                    name: template.name,
                    type: template.type
                }));
                this.textualPrescriptionTemplateStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public createTextualPrescriptionTemplateAsync(template: TextualPrescriptionTemplate) {
        return this.processOperationAsync(
            new TextualPrescriptionTemplate(),
            async target => {
                const response = await this.apiClient.createTextualPrescriptionTemplateCommandAsync(CreateRequestId(), new Proxy.CreateTextualPrescriptionTemplateControllerDto({
                    content: template.content,
                    preparation: template.preparation,
                    displayName: template.displayName,
                    name: template.name,
                    type: template.type
                }));
                this.textualPrescriptionTemplateStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public createTextualPrescriptionTemplatePermissionCheckAsync() {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.createTextualPrescriptionTemplateCommandAsync(CreateRequestId(), new Proxy.CreateTextualPrescriptionTemplateControllerDto({
                    type: PrescriptionTemplateType.Other,
                    content: "PermissionCheck",
                    displayName: "PermissionCheck",
                    name: "PermissionCheck"
                }), true);

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public deleteTextualPrescriptionTemplateAsync(id: TextualPrescriptionTemplateId, rowVersion: RowVersion) {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.deleteTextualPrescriptionTemplateCommandAsync(CreateRequestId(), new Proxy.DeleteTextualPrescriptionTemplateControllerDto({
                    id: id,
                    rowVersion: rowVersion
                }));
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getLastSuccessfulMedicationUpdateDateAsync() {
        return this.processOperationAsync(
            new SimpleStore<LocalDate>(),
            async target => {
                const response = await this.apiClient.getLastSuccessfulMedicationUpdateDateQueryAsync(CreateRequestId());
                target.operationInfo = createOperationInfo(response);
                target.value = response.puphaxImportDate;
            }
        );
    }

    @State.bound
    public loadOrderTypeIdsAsync(): Promise<SimpleStore<OrderTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getOrderTypeIdsQueryAsync(...args),
            response => response.orderTypeIds
        );
    }

    @State.bound
    public loadTargetValueIdsAsync(): Promise<SimpleStore<TargetValueId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getTargetValueIdsQueryAsync(...args),
            response => response.targetValueIds
        );
    }

    @State.bound
    public loadEhiPrescriptionStatusIdsAsync(): Promise<SimpleStore<EhiPrescriptionStatusId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiPrescriptionStatusIdsQueryAsync(...args),
            response => response.ehiPrescriptionStatusIds
        );
    }

    @State.bound
    public loadEhiPrescriptionCategoryIdsAsync(): Promise<SimpleStore<EhiPrescriptionCategoryId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEhiPrescriptionStatusIdsQueryAsync(...args),
            response => response.ehiPrescriptionStatusIds
        );
    }

    @State.bound
    public createPermanentPrescriptionAsync(prescriptionId: PrescriptionId, patientId: PatientId, requestLock: boolean = false, isPermissionCheckOnly: boolean = false): Promise<PermanentPrescription> {
        return this.processOperationAsync(
            new PermanentPrescription(),
            async target => {
                const response = await this.apiClient.createPermanentPrescriptionCommandAsync(CreateRequestId(),
                    new Proxy.CreatePermanentPrescriptionControllerDto({
                        prescriptionId: prescriptionId,
                        patientId: patientId,
                        requestLock: false
                    }), isPermissionCheckOnly);
                
                this.permanentPrescriptionMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public createPrescriptionsBasedOnAllPermanentPrescriptionsAsync(
        careActivityId: CareActivityId,
        permanentPrescriptionIds: PermanentPrescriptionId[]
    ): Promise<Prescription> {
        return this.processOperationAsync(
            new Prescription(),
            async target => {
                const response = await this.apiClient.createPrescriptionsBasedOnAllPermanentPrescriptionsCommandAsync(CreateRequestId(),
                    new Proxy.CreatePrescriptionsBasedOnAllPermanentPrescriptionsControllerDto({
                        careActivityId: careActivityId,
                        permanentPrescriptionIds: permanentPrescriptionIds
                    }));

                this.prescriptionMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public getPermanentPrescriptionInfoQueryAsync(permanentPrescriptionId: PermanentPrescriptionId): Promise<SimpleStore<PrescriptionInfo>> {
        return this.processOperationAsync(
            new SimpleStore<PrescriptionInfo>(),
            async target => {
                const response = await this.apiClient.getPermanentPrescriptionInfoQueryAsync(CreateRequestId(), permanentPrescriptionId.value, false);
                target.operationInfo = createOperationInfo(response);
                target.value = new PrescriptionInfo();
                target.value.setPermanentPrescriptionId(response.permanentPrescriptionInfo.permanentPrescriptionId);
                target.value.setDisplayName(response.permanentPrescriptionInfo.displayName);
                target.value.setClaimTypeId(response.permanentPrescriptionInfo.claimTypeId);
            }
        );
    }

    @State.bound
    public getDosagesByPermanentPrescriptionIdsAsync(permanentPrescriptionIds: PermanentPrescriptionId[]): Promise<SimpleStore<PermanentPrescriptionDosage[]>> {
        return this.processOperationAsync(
            new SimpleStore<PermanentPrescriptionDosage[]>(),
            async target => {
                const response = await this.apiClient.getDosagesByPermanentPrescriptionIdsQueryAsync(CreateRequestId(),
                    new Proxy.GetDosagesByPermanentPrescriptionIdsControllerDto({
                        permanentPrescriptionIds: permanentPrescriptionIds
                    }), false);
                target.operationInfo = createOperationInfo(response);
                target.value = response.dosageInfos.map(info => new PermanentPrescriptionDosage(
                    info.permanentPrescriptionId,
                    mapDosage(info.dosage)));
            }
        );
    }

    @State.bound
    public searchPermanentPrescriptionsAsync(
        text?: string,
        claimTypeId?: MedicationSubsidyClaimTypeId,
        organizationUnitIds?: OrganizationUnitId[],
        patientId?: PatientId,
        validOn?: LocalDate): Promise<SimpleStore<PermanentPrescriptionId[]>> {
        return this.processOperationAsync(
            new SimpleStore<PermanentPrescriptionId[]>(),
            async target => {
                const response = await this.apiClient.searchPermanentPrescriptionsQueryAsync(CreateRequestId(),
                    new Proxy.SearchPermanentPrescriptionsControllerDto({
                        text: text,
                        claimTypeId: claimTypeId,
                        organizationUnitIds: organizationUnitIds,
                        patientId: patientId,
                        validOn: validOn
                    }));

                target.operationInfo = createOperationInfo(response);
                target.value = response.permanentPrescriptionIds;
            }
        );
    }

    @State.bound
    public getPermanentPrescriptionCountByIdentifierAsync(identifier: Identifier, patientId: PatientId): Promise<SimpleStore<number>> {
        return this.processOperationAsync(
            new SimpleStore<number>(),
            async target => {
                const response = await this.apiClient.getPermanentPrescriptionCountByIdentifierQueryAsync(CreateRequestId(),
                    new Proxy.GetPermanentPrescriptionCountByIdentifierControllerDto({
                        identifier: identifier,
                        patientId: patientId
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.permanentPrescriptionCount;
            }
        );
    }

    @State.bound
    public getPermanentPrescriptionsAsync(
        careActivityId: CareActivityId,
        patientId: PatientId,
        paging: IPagingState,
        ordering: IOrderingState,
        organizationUnitIds: OrganizationUnitId[]): Promise<PagedItemStore<IPermanentPrescriptionListItem>> {
        return this.processOperationAsync(
            new PagedItemStore<IPermanentPrescriptionListItem>(),
            async target => {
                const response = await this.apiClient.getPermanentPrescriptionsQueryAsync(CreateRequestId(), new Proxy.GetPermanentPrescriptionsControllerDto({
                    careActivityId: careActivityId,
                    patientId: patientId,
                    organizationUnitIds: organizationUnitIds,
                    pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfGetPermanentPrescriptionsQueryOrderingFields({
                        orderings: [new Proxy.QueryOrderingOfGetPermanentPrescriptionsQueryOrderingFields({
                            direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                            fieldName: GetPermanentPrescriptionsQueryOrderingFields[ordering.columnId]
                        })],
                        paging: new QueryPaging({
                            pageIndex: paging.currentPage,
                            pageSize: paging.pageSize
                        })
                    })
                }));
                target.operationInfo = createOperationInfo(response);
                target.totalCount = response.results?.totalCount;
                target.items = response.results?.values?.map(i => {
                    return {
                        id: i.id,
                        createdAt: i.createdAt,
                        medicationName: i.preferredName ?? i.medicationName,
                        isAvailable: i.isAvailable,
                        quantity: i.quantity,
                        textAmount: i.textAmount,
                        conditionId: i.conditionId,
                        claimTypeId: i.claimTypeId,
                        organizationUnitId: i.organizationUnitId
                    } as IPermanentPrescriptionListItem;
                });
            }
        );
    }

    @State.bound
    public deletePermanentPrescriptionAsync(permanentPrescriptionId: PermanentPrescriptionId): Promise<SimpleStore> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.deletePermanentPrescriptionCommandAsync(CreateRequestId(),
                    new Proxy.DeletePermanentPrescriptionControllerDto({
                        permanentPrescriptionId: permanentPrescriptionId
                    }));
                
                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}
