import React from "react";

export interface IHeaderGroupProps {
    headingType: 1 | 2 | 3 | 4 | 5 | 6;
}


const HeaderGroup: React.SFC<IHeaderGroupProps> = props => {
    return React.createElement(`h${props.headingType}`, null,
    props.children);
};

export default HeaderGroup;