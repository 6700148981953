import React from "react";
import Styles from "./GroupBox.less";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";

interface IGroupBoxProps {
    title: React.ReactNode;
    visualStyle?: "standard" | "primary";
    isCollapsible?: boolean;
    isOpen?: boolean;
    defaultIsOpen?: boolean;
    onOpenStateChanged?: (isOpen: boolean) => void;
    hasBorder?: boolean;
    style?: React.CSSProperties;
    className?: string;
    automationId?: string;
    theme?: "normal" | "dark";
    isScrollable?: boolean;
    contentFullHeight?: boolean;
    children?: React.ReactNode;
}

@State.observer
export default class GroupBox extends React.Component<IGroupBoxProps> {

    public static defaultProps: Partial<IGroupBoxProps> = {
        hasBorder: false,
        defaultIsOpen: true
    };

    @State.observable public innerIsOpen: boolean = this.props.defaultIsOpen;

    @State.computed private get isOpen() { return this.props.isOpen !== undefined ? this.props.isOpen : this.innerIsOpen; }

    @State.action.bound
    private isOpenClickHandler() {
        if (this.props.onOpenStateChanged) {
            this.props.onOpenStateChanged(this.props.isOpen);
        } else {
            this.innerIsOpen = !this.innerIsOpen;
        }
    }

    public getContainerClass = () => {
        const classes = new CompositeClassName(this.props.className);
        classes.addIf(this.props.visualStyle === "primary", Styles.containerPrimary);
        classes.addIf(this.props.hasBorder, Styles.containerHasBorder);
        classes.addIf(this.props.isCollapsible, Styles.headerCollapsable);
        classes.addIf(!this.props.hasBorder, Styles.standardUncontrolled);
        classes.addIf(this.props.hasBorder === false, Styles.container);
        classes.addIf(this.isOpen && this.props.isCollapsible, Styles.headerCollapsableRotateIcon);
        classes.addIf(this.props.isScrollable, Styles.relativePos);
        classes.addIf(this.props.contentFullHeight, Styles.fullHeightContent);
        return classes.classNames;
    };

    private renderTitle() {
        const titleClasses = new CompositeClassName(Styles.title);
        titleClasses.addIf(this.props.theme === "dark", Styles.darkLabel);
        if (typeof this.props.title === "string") {
            return (
                <h4 className={titleClasses.classNames}>
                    {this.props.title}
                </h4>
            );
        }

        return (
            <div className={Styles.title}>
                {this.props.title}
            </div>
        );
    }

    public render() {
        return (
            <div className={this.getContainerClass()} style={this.props.style} data-automation-id={this.props.automationId || undefined}>
                <div className={Styles.header} onClick={this.props.isCollapsible ? this.isOpenClickHandler : null}>
                    {this.renderTitle()}
                    {this.props.isCollapsible &&
                        <div className={Styles.iconContainer}>
                            <Ui.Icon iconName="chevronDown" automationId={this.props.automationId ? `${this.props.automationId}_Toggle` : undefined} />
                        </div>
                    }
                </div>
                {this.props.isScrollable ? <Ui.ScrollView height="calc(100% - 22px)">{this.renderContent()}</Ui.ScrollView> : this.renderContent()}
            </div>
        );
    }

    private renderContent() {
        if (this.props.isCollapsible) {
            return (
                <>
                    {this.isOpen &&
                        <div className={this.props.contentFullHeight ? Styles.fullHeightContent : Styles.content}>
                            {this.props.children}
                        </div>}
                </>
            );
        } else {
            return (
                <div className={this.props.contentFullHeight ? Styles.fullHeightContent : Styles.content}>
                    {this.props.children}
                </div>
            );
        }
    }
}
