import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import PermissionDefinitionBuilder from "@PluginInterface/OperationAccessControl/PermissionDefinitionBuilder";
import { PermissionDefinitions } from "@HisPlatform/PermissionDefinitions/PermissionDefinitions.g";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";

export default function configureReportingPermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry) {

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.Reporting.Reporting.RunReport)
            .withPermissionScope(
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.reportType, ["HunEHealthInfrastructure_OncologyDataReport"])
            )
            .withAlias("OncologyDataReport", PermissionScopeType.reportType)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.Reporting.Reporting.RunReport)
            .withPermissionScope(
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.reportType, ["HunSocialSecurity_OutpatientActivityLog"])
            )
            .withAlias("OutpatientActivityLog", PermissionScopeType.reportType)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.Reporting.Reporting.RunReport)
            .withPermissionScopes(
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.reportType, ["HunSocialSecurity_PerformanceReport"])
            )
            .withAlias("PerformanceReport", PermissionScopeType.reportType)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.Productivity.Worklist.ViewWorklists)
            .withPermissionScopes(
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType, ["PerformanceReportScorePlanBased"])
            )
            .withAlias("PerformanceReportScorePlans", PermissionScopeType.worklistType)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.Reporting.Reporting.RunReport)
            .withPermissionScope(
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.reportType, ["HunSocialSecurity_FinancedServiceMinimumTimeReport"])
            )
            .withAlias("FinancedServiceMinimumTimeReport", PermissionScopeType.reportType)
            .build()
    );
}