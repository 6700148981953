import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import moment, { Moment } from "moment";

export default abstract class MedicalConditionHistoryItemBase {
    @State.observable.ref public historyItemDetail: MedicalConditionHistoryItemDetail;
    @State.observable public versionNumber: number; 
    @State.observable public createdAt: Moment; 
    @State.observable public isNew: boolean = false;  
    @State.observable public index: number; 
    @State.observable.ref public extensionData: any;

    @State.action.bound
    public setHistoryItemDetail(newValue: MedicalConditionHistoryItemDetail) {
        this.historyItemDetail = newValue;
    }

    @State.action.bound
    public markAsNewVersion(newIndex: number) {
        this.isNew = true;
        this.createdAt = moment(moment.now());
        this.index = newIndex;
    }

    @State.action.bound
    public setExtensionData(newValue: any) {
        this.extensionData = newValue;
    }
}
