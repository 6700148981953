import { RowId } from "@CommonControls/DataGrid/IDataGridProps";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import WorklistActionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistActionType.g";
import CareActivityWorklistItemContextInfo from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/CareActivityWorklistItemMetadata";
import INDataRow from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataRow";
import { CareActivityMenu } from "@HisPlatform/Components/HisPlatformControls";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import ActionPlacement from "@Primitives/ActionPlacement";
import React, { useMemo } from "react";

interface IWorklistCareActivityMenuProps {
    row: INDataRow | null;
    onClose?: () => void;
    onActionProcessed?: () => void;
    onPerformClientSideActionAsync?: (clientSideAction: ClientSideActionDto) => Promise<WorkListArgument | boolean>;
}

export default function WorklistCareActivityMenu(props: IWorklistCareActivityMenuProps) {

    const actions = useMemo(() => props.row?.__actions.filter(val =>
        (val.placement === ActionPlacement.Context || val.placement === ActionPlacement.Both) && val.worklistActionType !== WorklistActionType.Batch) ?? null, [props.row]);

    const contextInfo = props.row.__contextInfo;
    return contextInfo instanceof CareActivityWorklistItemContextInfo && (
        <CareActivityMenu
            careActivityState={contextInfo.careActivityState}
            pointOfCareId={contextInfo.pointOfCareId}
            primaryCareIdentifier={contextInfo.careIdentifier}
            primaryParticipant={contextInfo.mostRelevantPractitionerId}
            wentUnderCareAt={contextInfo.careIntervalFrom}
            dischargedAt={contextInfo.careIntervalTo}
            actions={actions}
            onClose={props.onClose}
            patientName={contextInfo.patientName}
            patientBirthDate={contextInfo.birthDate}
            hideGroupHeaders
            onPerformClientSideActionAsync={props.onPerformClientSideActionAsync}
            onActionProcessed={props.onActionProcessed}
        />
    );
}