import DeviceId from "@Primitives/DeviceId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DeviceAssignmentBase from "./DeviceAssignmentBase";

export default class ReferencedDeviceAssignment extends DeviceAssignmentBase  {
    @State.observable.ref public deviceId: DeviceId = null;

    constructor(deviceId: DeviceId) {
        super();
        this.deviceId = deviceId;
    }
}