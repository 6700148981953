import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IEhiMedicalConditionMenuItemExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IEhiMedicalConditionMenuItemExtensionPointProps";
import SideMenuItem from "@CommonControls/SideMenu/SideMenuItem";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import HunPatientRoutes from "@HunEHealthInfrastructurePlugin/UseCases/PatientMainPageUseCase/HunPatientRoutes";

@State.observer
export default class EhiMedicalConditionMenuItemExtension extends React.Component<IEhiMedicalConditionMenuItemExtensionPointProps> {
    public render() {
        return (
            <>
                <SideMenuItem
                    isActive={this.props.routingController.currentRoute?.definition === HunPatientRoutes.therapyRecommendation}
                    automationId="__therapyRecommendation"
                    iconName="rehabilitation"
                    text={StaticHunEHealthInfrastructureCareResources.MedicalCondition.Menu.TherapyRecommendations}
                    onClick={this.navigateToTherapyRecommendation}
                    disabled={this.props.isNew}
                    permissionCheckOperationNames="ViewPatientMedicalConditions"
                    permissionDeniedStyle="disabled"
                />
                <SideMenuItem
                    isActive={this.props.routingController.currentRoute?.definition === HunPatientRoutes.patientBloodType}
                    automationId="__patientBloodType"
                    iconName="vaccine"
                    text={StaticHunEHealthInfrastructureCareResources.MedicalCondition.Menu.PatientBloodTypes}
                    onClick={this.navigateToPatientBloodType}
                    disabled={this.props.isNew}
                    permissionCheckOperationNames="ViewPatientMedicalConditions"
                    permissionDeniedStyle="disabled"
                />
                <SideMenuItem
                    isActive={this.props.routingController.currentRoute?.definition === HunPatientRoutes.ratFormPatientBased}
                    automationId="__ratForm"
                    iconName="covid"
                    text={StaticHunEHealthInfrastructureCareResources.MedicalCondition.Menu.RatForm}
                    onClick={this.navigateToRatForms}
                    disabled={this.props.isNew}
                />
                <SideMenuItem
                    isActive={this.props.routingController.currentRoute?.definition === HunPatientRoutes.ehiCovidTestResults}
                    automationId="__covidTestResults"
                    iconName="covid"
                    text={StaticHunEHealthInfrastructureCareResources.MedicalCondition.Menu.CovidTestResults}
                    onClick={this.navigateToCovidTestResultList}
                    disabled={this.props.isNew}
                />

            </>
        );
    }

    @State.bound
    public navigateToRatForms() {
        if (!this.props.patientId) {
            return;
        }

        const route = HunPatientRoutes.ratFormPatientBased.makeRoute({
            patientId: this.props.patientId.value,
            appointmentId: "null",
            mode: "read-write",
            selectedId: "null",
            useCase: "null"
        });
        this.props.routingController.push(route);
    }

    @State.bound
    public navigateToCovidTestResultList() {
        if (!this.props.patientId) {
            return;
        }

        const route = HunPatientRoutes.ehiCovidTestResults.makeRoute({
            patientId: this.props.patientId.value,
            appointmentId: "null",
            mode: "read-write"
        });
        this.props.routingController.push(route);
    }

    @State.bound
    public navigateToTherapyRecommendation() {
        if (!this.props.patientId) {
            return;
        }

        const route = HunPatientRoutes.therapyRecommendation.makeRoute({
            patientId: this.props.patientId.value,
            appointmentId: "null",
            mode: "read-write"
        });
        this.props.routingController.push(route);
    }

    @State.bound
    public navigateToPatientBloodType() {
        if (!this.props.patientId) {
            return;
        }

        const route = HunPatientRoutes.patientBloodType.makeRoute({
            patientId: this.props.patientId.value,
            appointmentId: "null",
            mode: "read-write"
        });
        this.props.routingController.push(route);
    }

}
