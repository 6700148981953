import React from "react";
import * as Ui from "@CommonControls";
import { ITokenSettingsPanelPropsBase } from "@PluginInterface/BoundedContexts/DocumentManagement/TokenSettingsPanelRegistry/ITokenSettingsPanelRegistry";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import Styles from "./UnknownTokenSettingsPanel.less";

@State.observer
export default class UnknownTokenSettingsPanel extends React.Component<ITokenSettingsPanelPropsBase> {

    @State.observable.ref private settingsJson = "";
    @State.observable.ref private settingsJsonError: string = null;

    public componentDidMount() {
        this.loadSettings();
    }

    public componentDidUpdate(prevProps: ITokenSettingsPanelPropsBase) {
        if (prevProps.tokenFormatterSettings !== this.props.tokenFormatterSettings) {
            this.loadSettings();
        }
    }

    @State.action
    private loadSettings() {
        this.settingsJson = JSON.stringify(this.props.tokenFormatterSettings, null, "    ");
    }

    @State.action.bound
    private setSettingsJson(value: string) {
        this.settingsJson = value;
        this.parseDebounced();
    }

    private parseDebounced = _.debounce(() => {
        try {
            const parsed = JSON.parse(this.settingsJson);
            State.runInAction(() => {
                this.props.onSettingsChange(parsed, null);
                this.settingsJsonError = null;
            });
        } catch (err) {
            State.runInAction(() => {
                this.settingsJsonError = err.message;
            });
        }
    }, 100);

    public render() {
        return (
            <>
                <Ui.TextBox
                    label={StaticDocumentManagementResources.TemplateManagement.TokenSettingsType}
                    value={this.props.tokenFormatterSettingsType}
                    isReadOnly
                    automationId="tokenFormatterSettingsTypeTextBox"
                />
                <Ui.TextBox
                    multiline
                    multilineMinLineCount={5}
                    clearable={false}
                    label={StaticDocumentManagementResources.TemplateManagement.TokenSettings}
                    value={this.settingsJson}
                    onChange={this.setSettingsJson}
                    className={Styles.jsonEditor}
                    automationId="settingsJsonTextBox"
                />
                {!!this.settingsJsonError && <Ui.Badge visualStyle="danger" text={this.settingsJsonError} />}
            </>
        );
    }
}