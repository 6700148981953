import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import SubstanceAmountColumnData from "./SubstanceAmountColumnData";


interface ISubstanceAmountColumnProps extends IDataGridColumnBaseProps {
}


const SubstanceAmountColumn: React.FC<ISubstanceAmountColumnProps> = (props: ISubstanceAmountColumnProps) => {
    const valueRenderer = useCallback((value: SubstanceAmountColumnData) => {  
        if (value?.substanceAmount && value.substanceAmountUnit) {
            return `${value.substanceAmount} ${value.substanceAmountUnit}`;
        }

        return "";
    }, []);

    return (
        <DataGridColumn
            onRenderCellValue={valueRenderer}
            {...props}
        />
    );
};

export default SubstanceAmountColumn;