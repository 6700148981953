import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";

export interface IFilterDialogParams extends IModalParams {
    existingFilters: FilterBase[];
}

export interface IFilterDialogResult {
    resultFilters: FilterBase[];
}

export default class FilterDialogParams implements IFilterDialogParams {
    public static type = "FilterDialog";
    public get type() { return FilterDialogParams.type; }

    constructor(
        public existingFilters: FilterBase[] = []
    ) {}
}