import React from "react";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import Address from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/Address";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PractitionerId from "@Primitives/PractitionerId.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";

interface IPractitionerViewDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IPractitionerViewProps {
    _dependencies?: IPractitionerViewDependencies;
    practitionerId: PractitionerId;

    loadMode?: "debounced" | "preloaded";
    showCountry?: boolean;
}

@State.observer
class PractitionerView extends React.Component<IPractitionerViewProps> {
    private get organizationReferenceDataStore() { return this.props._dependencies.organizationReferenceDataStore; }

    public static defaultProps: Partial<IPractitionerViewProps> = {
        loadMode: "debounced"
    };

    @State.computed private get doctor() {
        return this.organizationReferenceDataStore.doctorMap.get(this.props.practitionerId);
    }

    public componentDidMount() {
        if (this.props.loadMode === "debounced") {
            dispatchAsyncErrors(Promise.all([
                this.organizationReferenceDataStore.doctorMap.debouncedEnsureLoaded([this.props.practitionerId]),
            ]), this);
        }
    }

    public render() {
        if (!this.doctor) {
            return this.props.loadMode === "debounced" ? null : "N/A";
        }

        return `${this.doctor.code} - ${this.doctor.name}`;
    }
}

export default connect(
    PractitionerView,
    new DependencyAdapter<IPractitionerViewProps, IPractitionerViewDependencies>( c => ({
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
    }))
);