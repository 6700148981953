import State from "@Toolkit/ReactClient/Common/StateManaging";
import FormDataElementBase from "./FormDataElementBase";

export default class NumberFormDataElement extends FormDataElementBase {

    @State.observable.ref public isInteger: boolean;

    constructor(
        name: string,
        isArray: boolean,
        defaultValue: number,
        isReadOnly: boolean,
        isInteger: boolean,
        isVisible: boolean

    ) {
        super(name, isArray, defaultValue, isReadOnly, isVisible);
        this.isInteger = isInteger;
    }

    @State.action.bound
    public setIsInteger(isInteger: boolean) {
        this.isInteger = isInteger;
    }

    public formDataElementType = "Number";
}