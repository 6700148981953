import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import ObservationDefinitionId from "@Primitives/ObservationDefinitionId.g";
import { IObservationDefinition } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServices/IObservationDefinition";
import ObservationDefinitionApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ObservationDefinitionApiAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import _ from "@HisPlatform/Common/Lodash";

export default class ObservationDefinitionReferenceDataStore extends ReferenceDataStore<ObservationDefinitionId, IObservationDefinition> {

    constructor(private readonly apiAdapter: ObservationDefinitionApiAdapter) {
        super(apiAdapter.loadObservationDefinitionsByIdsAsync);
    }

    public async loadObservationDefinitionsForMedicalServicesAsync(medicalServiceIds: Array<IEntityVersionSelector<MedicalServiceId>>) {
        const observationDefinitionsForMedicalServices =
            await this.apiAdapter.loadObservationDefinitionsForMedicalServicesAsync(medicalServiceIds);

        const observationDefinitions = _.flatten(observationDefinitionsForMedicalServices.value.map(i => i.observationDefinitions));

        this.preLoadItems(observationDefinitions);

        return observationDefinitionsForMedicalServices;
    }

    public getIdByCode(code: string) {
        const item = this.items.find(i => i.code === code);
        if (!isNullOrUndefined(item)) {
            return item.id;
        }
        return null;
    }
}