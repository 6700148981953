import NumericComparisonType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/NumericComparisonType.g";
import StringComparisonType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/StringComparisonType.g";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";

export interface IStringCondition {
    type: WorklistConditionType.String;
    value: string;
    comparisonType: StringComparisonType;
}

export interface INameCondition {
    type: WorklistConditionType.PersonName;
    namePart: string;
}

export interface IEntityIdCondition {
    type: WorklistConditionType.EntityId | WorklistConditionType.ExtensibleEnum;
    values: string[];
}

export interface IExtensibleEnumCondition {
    type: WorklistConditionType.ExtensibleEnum;
    values: string[];
}

export interface IStringEnumCondition {
    type: WorklistConditionType.StringEnum;
    values: string[];
}

export interface IStringEnumListCondition {
    type: WorklistConditionType.StringEnumList;
    values: Array<{ name: string, values: number[] }>;
}

export interface IIntEnumCondition {
    type: WorklistConditionType.IntEnum;
    values: number[];
}

export interface INumericCondition {
    type: WorklistConditionType.Numeric;
    value: number;
    comparisonType: NumericComparisonType;
}

export interface IBooleanCondition {
    type: WorklistConditionType.Boolean;
    value: boolean;
}

export interface IDateCondition {
    type: WorklistConditionType.Date;
    value: LocalDateRange;
}

export interface IDateTimeCondition {
    type: WorklistConditionType.DateAndTime;
    value: LocalDateRange;
}

export interface IYearMonthCondition {
    type: WorklistConditionType.YearMonth;
    value: YearMonth;
}

export class NumericFilterCondition {
    constructor(
        public value: number,
        public comparisonType: NumericComparisonType
    ) { }
}

export type ICondition = IStringCondition | INameCondition | IEntityIdCondition | IExtensibleEnumCondition | IStringEnumCondition | IIntEnumCondition | INumericCondition | IDateCondition | IDateTimeCondition | IYearMonthCondition | IBooleanCondition | IStringEnumListCondition;
