import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import RoleApiAdapter from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/OperationAccessControl/RoleApiAdapter";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import ConfigurationDynamicPropertiesApiAdapter from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/ApiAdapter/DynamicProperties/ConfigurationDynamicPropertiesApiAdapter";
import TemplateApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Templating/TemplateApiAdapter";
import InsurerOrganizationsApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/InsurerOrganizations/InsurerOrganizationsApiAdapter";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import WorklistDefinitionApiAdapter from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/Worklist/WorklistDefinitionApiAdapter";
import ResourceManagementApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/ResourceManagementApiAdapter";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import UserGroupsApiAdapter from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Groups/UserGroupsApiAdapter";
import UsersApiAdapter from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Users/UsersApiAdapter";
import { ScreenNavigationContextAdapter } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import IFormEngineApiAdapter from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import ActionBoundNavButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundNavButton";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import NavMenuActionItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuActionItem";
import NavMenuCustomItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuCustomItem";
import NavMenuGroupItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuGroupItem";
import NavMenuSeparatorItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuSeparatorItem";
import _ from "@HisPlatform/Common/Lodash";
import { emptyObject } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IDynamicNavigationDependencies {
    userContext: UserContext;
    worklistApiAdapter: WorklistApiAdapter;
    schedulingApiAdapter: SchedulingApiAdapter;
    resourceManagementApiAdapter: ResourceManagementApiAdapter;
    dynamicNavigationRegistry: IDynamicNavigationRegistry;
    insurerOrganizationsApiAdapter: InsurerOrganizationsApiAdapter;
    configurationDynamicPropertiesApiAdapter: ConfigurationDynamicPropertiesApiAdapter
    structureApiAdapter: StructureApiAdapter;
    usersApiAdapter: UsersApiAdapter;
    userGroupsApiAdapter: UserGroupsApiAdapter;
    roleApiAdapter: RoleApiAdapter;
    templateApiAdapter: TemplateApiAdapter;
    formEngineApiAdapter: IFormEngineApiAdapter;
    worklistDefinitionApiAdapter: WorklistDefinitionApiAdapter;
}

interface IDynamicNavigationProps {
    _dependencies?: IDynamicNavigationDependencies;
}

/** @screen */
@State.observer
class DynamicNavigation extends React.Component<IDynamicNavigationProps> {

    private get insurerOrganizationsApiAdapter() { return this.props._dependencies.insurerOrganizationsApiAdapter; }
    private get worklistApiAdapter() { return this.props._dependencies.worklistApiAdapter; }
    private get configurationDynamicPropertiesApiAdapter() { return this.props._dependencies.configurationDynamicPropertiesApiAdapter; }
    private get structureApiAdapter() { return this.props._dependencies.structureApiAdapter; }
    private get userApiAdapter() { return this.props._dependencies.usersApiAdapter; }
    private get userGroupApiAdapter() { return this.props._dependencies.userGroupsApiAdapter; }
    private get roleApiAdapter() { return this.props._dependencies.roleApiAdapter; }
    private get templateApiAdapter() { return this.props._dependencies.templateApiAdapter; }
    private get formEngineApiAdapter() { return this.props._dependencies.formEngineApiAdapter; }
    private get worklistDefinitionApiAdapter() {return this.props._dependencies.worklistDefinitionApiAdapter; }

    @State.computed private get permissionCheckedOperations() {
        const res = {};

        res["PatientSearch"] = (async () => {
            const definition = await this.props._dependencies.worklistApiAdapter.getPatientBoundWorklistDefinition();
            await this.props._dependencies.worklistApiAdapter.getWorklistDataByDefinitionIdPermissionCheck(definition.value);
        });

        res["ViewPractitionerAppointments"] = (async () => {
            await this.props._dependencies.schedulingApiAdapter.getAppointmentsForPractitionerPermissionCheckAsync();
        });

        res["ViewPractitionerBlockings"] = (async () => {
            await this.props._dependencies.resourceManagementApiAdapter.getResourceBlockingsForPractitionerPermissionCheckAsync();
        });

        res["ViewInvalidAppointments"] = (async () => {
            const definition = await this.props._dependencies.worklistApiAdapter.getInvalidAppointmentScheduleEntryBoundWorklistDefinition();
            await this.props._dependencies.worklistApiAdapter.getWorklistDataByDefinitionIdPermissionCheck(definition.value);
        });

        res["UpdateInsurerOrganizations"] = async () => {
            await this.insurerOrganizationsApiAdapter.updateInsurerOrganizationPermissionCheckAsync();
        };

        res["UpdateDynamicProperties"] = async () => {
            await this.configurationDynamicPropertiesApiAdapter.updatePropertyGroupsPermissionCheckAsync();
        };

        res["UpdateOrganizationStructure"] = async () => {
            await this.structureApiAdapter.updateOrganizationUnitPermissionCheckAsync();
        };

        res["UpdateUsers"] = async () => {
            await this.userApiAdapter.updateUserPermissionCheckAsync();
        };

        res["UpdateUserGroups"] = async () => {
            await this.userGroupApiAdapter.updateUserGroupPermissionCheckAsync();
        };

        res["UpdateRoles"] = async () => {
            await this.roleApiAdapter.updateRolePermissionCheckAsync();
        };

        res["UpdateDocumentTemplates"] = async () => {
            await this.templateApiAdapter.updateTemplatePermissionCheckAsync();
        };

        res["ViewSchedules"] = async () => {
            const definition = await this.worklistApiAdapter.getAppointmentScheduleDefinitionBoundWorklistDefinition();
            await this.worklistApiAdapter.getWorklistDataByDefinitionIdPermissionCheck(definition.value);
        };

        res["ViewMedications"] = async () => {
            const definition = await this.worklistApiAdapter.getStandaloneMedicationBoundWorklistDefinition();
            await this.worklistApiAdapter.getWorklistDataByDefinitionIdPermissionCheck(definition.value);
        };

        res["UpdateExternalLocations"] = async () => {
            const definition = await this.worklistApiAdapter.getExternalLocationBoundWorklistDefinition();
            await this.worklistApiAdapter.getWorklistDataByDefinitionIdPermissionCheck(definition.value);
        };

        res["GetFormDefinitions"] = async () => {
            await this.formEngineApiAdapter.checkPermissionForFormDefinitionListAsync();
        };

        res["WorklistDefinitions"] = async () => {
            await this.worklistDefinitionApiAdapter.getWorklistDefinitionBaseInformationAsync(true);
        };

        return res;
    }


    private renderNodes(navMenuItem: INavMenuItem, key: string | number) {

        if (navMenuItem instanceof NavMenuCustomItem) {
            return React.createElement(navMenuItem.customContentComponentType, { ...(navMenuItem.props ?? emptyObject), key });
        }

        if (navMenuItem instanceof NavMenuActionItem) {
            const navAction = ActionDescriptor.fromAction(navMenuItem.frontendAction);

            return <ActionBoundNavButton
                actionDescriptor={navAction}
                automationId={navMenuItem.automationId}
                key={key}
                text={navMenuItem.text}
                iconName={navMenuItem.iconName}
                permissionCheckOperationNames={navMenuItem.permissionCheckOperationNames} />;
        }

        if (navMenuItem instanceof NavMenuSeparatorItem) {
            return <Ui.NavBar.Separator key={key} />;
        }

        const navMenuGroupItem = (navMenuItem as NavMenuGroupItem);
        const orederedChildren = _.orderBy(navMenuGroupItem.children, i => i.order);

        return (
            <Ui.NavBar.Item
                content={
                    <>
                        {navMenuGroupItem.iconName && <Ui.Icon iconName={navMenuGroupItem.iconName} visualStyle="white" />}
                        {navMenuGroupItem.text}
                    </>
                }
                key={key}
                hasNestedItems
                automationId={navMenuGroupItem.automationId}>
                {
                    orederedChildren.map((child) => {
                        return this.renderNodes(child, child.identifier);
                    })
                }
            </Ui.NavBar.Item>
        );
    }

    public render() {

        const navigationItems = this.props._dependencies.dynamicNavigationRegistry.tree;
        const leftRootNavBarItems = _.orderBy(navigationItems.filter(i => i.parentIdentifier === null && i.side !== "right"), i => i.order);
        const rightRootNavBarItems = _.orderBy(navigationItems.filter(i => i.parentIdentifier === null && i.side === "right"), i => i.order);

        const leftNavBarItems: JSX.Element[] = [];
        for (const rootNavBarItem of leftRootNavBarItems) {
            const renderedRoot = this.renderNodes(rootNavBarItem, rootNavBarItem.identifier);
            leftNavBarItems.push(renderedRoot);
        }

        const rightNavBarItems: JSX.Element[] = [];
        for (const rootNavBarItem of rightRootNavBarItems) {
            const renderedRoot = this.renderNodes(rootNavBarItem, rootNavBarItem.identifier);
            rightNavBarItems.push(renderedRoot);
        }

        return (
            <PermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                <Ui.NavBar>
                    <Ui.NavBar.LeftItems>
                        {leftNavBarItems}
                    </Ui.NavBar.LeftItems>
                    <Ui.NavBar.RightItems>
                        {rightNavBarItems}
                    </Ui.NavBar.RightItems>
                </Ui.NavBar>
            </PermissionCheckContextProvider>
        );
    }
}

export default connect(
    DynamicNavigation,
    new DependencyAdapter<IDynamicNavigationProps, IDynamicNavigationDependencies>(c => ({
        userContext: c.resolve("UserContext"),
        worklistApiAdapter: c.resolve("WorklistApiAdapter"),
        schedulingApiAdapter: c.resolve("SchedulingApiAdapter"),
        resourceManagementApiAdapter: c.resolve("ResourceManagementApiAdapter"),
        dynamicNavigationRegistry: c.resolve("IDynamicNavigationRegistry"),
        insurerOrganizationsApiAdapter: c.resolve("InsurerOrganizationsApiAdapter"),
        configurationDynamicPropertiesApiAdapter: c.resolve("ConfigurationDynamicPropertiesApiAdapter"),
        structureApiAdapter: c.resolve("StructureApiAdapter"),
        usersApiAdapter: c.resolve("UsersApiAdapter"),
        userGroupsApiAdapter: c.resolve("UserGroupsApiAdapter"),
        roleApiAdapter: c.resolve("RoleApiAdapter"),
        templateApiAdapter: c.resolve("TemplateApiAdapter"),
        formEngineApiAdapter: c.resolve("IFormEngineApiAdapter"),
        worklistDefinitionApiAdapter: c.resolve("WorklistDefinitionApiAdapter")
    })),
    new ScreenNavigationContextAdapter()
);
