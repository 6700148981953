import React from "react";
import Styles from "./SideMenu.less";
import * as Ui from "@CommonControls";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import { ICommonControlProps, getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SideMenuItem from "@CommonControls/SideMenu/SideMenuItem";

export interface ISideMenuProps extends ICommonControlProps {
    children?: React.ReactNode;
    collapsable?: boolean;
    visualMode?: "main" | "light" | "dark";
}

@State.observer
export default class SideMenu extends React.Component<ISideMenuProps, undefined> {

    public static defaultProps: Partial<ISideMenuProps> = {
        collapsable: false,
        visualMode: "main"
    };

    
    public static Item = SideMenuItem;

    @State.observable public sideMenuState: "opened" | "closed" = "opened";

    @State.action.bound
    private setMode() {
        if (this.sideMenuState === "opened") {
            this.sideMenuState = "closed";
        } else {
            this.sideMenuState = "opened";
        }
    }

    private getContainerVisualMode(visualMode: string) {
        switch (visualMode) {
            case "main": return Styles.container;
            case "light": return Styles.containerLight;
            case "dark": return Styles.containerDark;
            default: return Styles.container;
        }
    }

    private getClassName() {
        const classes = new CompositeClassName(this.props.className);
        classes.addIf(this.sideMenuState === "opened", this.getContainerVisualMode(this.props.visualMode));
        classes.addIf(this.sideMenuState === "closed", Styles.containerClosed);
        return classes.classNames;
    }

    private getMenuBackgroundByVisualMode(visualMode: string) {
        switch (visualMode) {
            case "main": return Styles.menuBackgroundMain;
            case "light": return Styles.menuBackgroundLight;
            case "dark": return Styles.menuBackgroundDark;
            default: return Styles.menuBackgroundMain;
        }
    }

    private getCollapseButtonStyle() {
        const classes = new CompositeClassName(Styles.container);
        classes.add(Styles.collapseButton);
        return classes.classNames;
    }

    public render() {
        const standardProps = getStandardHtmlProps(this.props);
        return (
            <div {...standardProps} className={this.getClassName()} data-automation-id="__sideMenuContainer">
                {this.props.collapsable && <Ui.Button
                    iconName="bars"
                    visualStyle="standard"
                    visualMode="inverted"
                    size="standard"
                    onClick={this.setMode}
                    className={this.getCollapseButtonStyle()}
                    automationId="__setModeButton"
                />}
                <Ui.ScrollView>
                    <div className={this.getMenuBackgroundByVisualMode(this.props.visualMode)}>
                        {this.props.children}
                    </div>
                </Ui.ScrollView>
            </div>
        );
    }

}