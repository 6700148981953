import React, { useCallback } from "react";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import Age from "@Toolkit/CommonWeb/Model/Age";
import NumberBox from "@CommonControls/NumberBox";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface IDataGridAgeColumnDependencies {
    toolkitLocalizationService: IToolkitLocalizationService;
}

type IDataGridAgeColumnProps = IDataGridColumnBaseProps & { _dependencies?: IDataGridAgeColumnDependencies; };

const filterValueSerializer: IDataGridFilterValueSerializer<number> = {
    deserialize: (value: string) => {
        return +value;
    },
    serialize: (value: number) => {
        return `${value}`;
    }
};


const DataGridAgeColumn: React.FC<IDataGridAgeColumnProps> = props => {

    const valueRenderer = useCallback((value: Age) => {
        const localizer = props._dependencies.toolkitLocalizationService;
        return isNullOrUndefined(value) ? null : localizer.localizeAge(value);
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<number>) => {
        return <NumberBox {...filterProps} integerOnly />;
    }, []);

    const { children, ...columnProps } = props;

    return (
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
        />
    );
};

export default connect(
    DataGridAgeColumn,
    new DependencyAdapter<IDataGridAgeColumnProps, IDataGridAgeColumnDependencies>(c => ({
        toolkitLocalizationService: c.resolve("IToolkitLocalizationService"),
    }))
);