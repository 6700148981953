import State from "@Toolkit/ReactClient/Common/StateManaging";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import ContactTypeId from "@Primitives/ContactTypeId.g";

export default class Contact extends EntityStoreBase<null> {
    @State.observable public value: string = "";

    @State.action.bound
    public setValue(value: string) {
        this.value = value;
    }

    constructor(
        public readonly contactTypeId: ContactTypeId,
        value?: string
    ) {
        super();
        this.value = value;
    }

    public static isPhone(store: Contact) {
        return store.contactTypeId && store.contactTypeId.value === ContactTypeId.Phone.value;
    }

    public static isEmail(store: Contact) {
        return store.contactTypeId && store.contactTypeId.value === ContactTypeId.Email.value;
    }

    public static isFax(store: Contact) {
        return store.contactTypeId && store.contactTypeId.value === ContactTypeId.Fax.value;
    }
}