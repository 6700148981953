import Di from "@Di";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResult from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResult";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import DialogType from "./DialogType";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class DialogService implements IDialogService {

    private resolveCurrentFunc: (res: DialogResult) => void = null;

    @State.observable public dialogType: DialogType = DialogType.default;

    @State.observable public isOkButton: boolean = false;
    @State.observable public isCancelButton: boolean = false;
    @State.observable public isYesButton: boolean = false;
    @State.observable public isNoButton: boolean = false;

    @State.observable public isOpen: boolean = false;
    @State.observable.ref public content: React.ReactNode = null;
    @State.observable.ref public title: string = null;

    constructor() {
        this.clear();
    }

    @State.action
    public ok(title: string, text: React.ReactNode): Promise<DialogResult> {
        return new Promise((resolve: (value: DialogResult) => void) => {
            this.isOpen = true;
            this.content = text;
            this.isOkButton = true;
            this.resolveCurrentFunc = resolve;
            this.title = title;
            this.dialogType = DialogType.default;
        });
    }

    @State.action
    public okCancel(title: string, text: React.ReactNode): Promise<DialogResult> {
        return new Promise((resolve: (value: DialogResult) => void) => {
            this.isOpen = true;
            this.content = text;
            this.isOkButton = true;
            this.isCancelButton = true;
            this.resolveCurrentFunc = resolve;
            this.title = title;
            this.dialogType = DialogType.default;
        });
    }

    @State.action
    public yesNo(title: string, text: React.ReactNode): Promise<DialogResult> {
        return new Promise((resolve: (value: DialogResult) => void) => {
            this.isOpen = true;
            this.content = text;
            this.isYesButton = true;
            this.isNoButton = true;
            this.resolveCurrentFunc = resolve;
            this.title = title;
            this.dialogType = DialogType.default;
        });
    }

    @State.action
    public yesNoCancel(title: string, text: React.ReactNode): Promise<DialogResult> {
        return new Promise((resolve: (value: DialogResult) => void) => {
            this.isOpen = true;
            this.content = text;
            this.isYesButton = true;
            this.isNoButton = true;
            this.isCancelButton = true;
            this.resolveCurrentFunc = resolve;
            this.title = title;
            this.dialogType = DialogType.default;
        });
    }

    @State.action
    public confirmIfNotSaved(title: string, text: React.ReactNode): Promise<DialogResult> {
        return new Promise((resolve: (value: DialogResult) => void) => {
            this.isOpen = true;
            this.content = text;
            this.isYesButton = true;
            this.isNoButton = true;
            this.isCancelButton = true;
            this.resolveCurrentFunc = resolve;
            this.title = title;
            this.dialogType = DialogType.confirmIfNotSaved;
        });
    }

    @State.bound
    @State.action
    public onClose() {
        this.complete(DialogResultCode.None);
    }

    @State.bound
    @State.action
    public onOk() {
        this.complete(DialogResultCode.Ok);
    }

    @State.bound
    @State.action
    public onCancel() {
        this.complete(DialogResultCode.Cancel);
    }

    @State.bound
    @State.action
    public onYes() {
        this.complete(DialogResultCode.Yes);
    }

    @State.bound
    @State.action
    public onNo() {
        this.complete(DialogResultCode.No);
    }

    private complete(resultCode: DialogResultCode) {
        if (this.resolveCurrentFunc) {
            this.clear();
            this.resolveCurrentFunc({
                resultCode
            });
            this.resolveCurrentFunc = null;
        }
    }

    @State.action
    private clear() {
        this.isOpen = false;
        this.content = null;
        this.isOkButton = false;
        this.isCancelButton = false;
        this.isYesButton = false;
        this.isNoButton = false;
    }
}