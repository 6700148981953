import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import LateralityId from "@Primitives/LateralityId.g";
import { RowId } from "@CommonControls/DataGrid/IDataGridProps";
import LateralitySelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/LateralitySelectBox";
import Styles from "./LateralityColumn.less";

interface ILateralityColumnDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface ILateralityColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: ILateralityColumnDependencies;
}


const LateralityColumn: React.FC<ILateralityColumnProps> = (props: ILateralityColumnProps) => {

    const careReferenceDataStore = props._dependencies.careReferenceDataStore;

    const valueRenderer = useCallback((value: LateralityId | LateralityId[], row: any, rowId: RowId, rowIndex: number, isUnderEditing: boolean, dataSetter: (newValue: LateralityId, index: number) => void) => {
        const ids: LateralityId[] = !value ? emptyArray : (Array.isArray(value) ? value : [value]);
        return ids.map((id, index) => {
            const laterality = id ? careReferenceDataStore.laterality.get(id) : null;
            const setter = (newValue: LateralityId) => {
                dataSetter?.(newValue, index);
            };

            return (
                <div key={index} className={isUnderEditing ? Styles.inlineInputContainer : undefined}>
                    {isUnderEditing ? <LateralitySelectBox value={id} onChange={setter} automationId="lateralitySelectBox" /> : laterality?.displayValue?.Shorthand}                    
                </div>
            );
        });
    }, []);

    const hintRenderer = useCallback((value: any) => {
        const ids: LateralityId[] = !value ? emptyArray : (Array.isArray(value) ? value : [value]);
        const displayValues: string[] = [];
        ids.map((id, index) => {
            const laterality = id ? careReferenceDataStore.laterality.get(id) : null;
            displayValues.push(laterality?.displayValue.Shorthand);
        });
        return displayValues.join(", ");
    }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderHintValue={hintRenderer}
        />
    );
};

export default connect(
    LateralityColumn,
    new DependencyAdapter<ILateralityColumnProps, ILateralityColumnDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
    }))
);
