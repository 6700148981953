import moment from "moment";
import FormDataElementBase from "./FormDataElementBase";

export default class DateTimeFormDataElement extends FormDataElementBase {

    constructor(
        name: string,
        isArray: boolean,
        defaultValue: moment.Moment,
        isReadOnly: boolean,
        isVisible: boolean
    ) {
        super(name, isArray, defaultValue, isReadOnly, isVisible);
    }

    public formDataElementType = "DateTime";
}