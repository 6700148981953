import React from "react";
import * as Ui from "@CommonControls";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiEReferralCovidData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralCovidData";
import EhiEReferralCovidExaminationSampleTypeSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralCovidExaminationSampleTypeSelectBox";

interface IExaminationSampleViewProps {
    store: EhiEReferralCovidData;
}


const ExaminationSampleView: React.FC<IExaminationSampleViewProps> = (props: IExaminationSampleViewProps) => {
    if (!props.store) {
        return null;
    }
    return (
        <Ui.GroupBox title={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.ExaminationSampleTitle}>
            <Ui.Flex>
                <Ui.Flex.Item sm={4}>
                    <Ui.TextBox value={props.store.examinationSampleIdentifier}
                                onChange={props.store.setExaminationSampleIdentifier}
                                label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.ExaminationSampleIdentifier}
                                propertyIdentifier="ExaminationSampleIdentifier"
                                automationId="__examinationSampleIdentifier"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item sm={4}>
                    <EhiEReferralCovidExaminationSampleTypeSelectBox value={props.store.examinationSampleTypeId}
                                                                     onChange={props.store.setExaminationSampleTypeId}
                                                                     label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.ExaminationSampleTypeId}
                                                                     propertyIdentifier="ExaminationSampleTypeId"
                                                                     automationId="__examinationSampleTypeId"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item sm={4}>
                    <Ui.DateTimePicker value={props.store.samplingDate}
                                       onChange={props.store.setSamplingDate}
                                       label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.SamplingDate}
                                       propertyIdentifier="SamplingDate"
                                       automationId="__quickTestDate"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </Ui.GroupBox>
    );
};

export default State.observer(ExaminationSampleView);
