import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import { addFieldsForPatientIdentifier, usePatientIdentifierCustomBlockHelper } from "@HisPlatform/Packages/Patients/Components/PatientIdentifier/PatientIdentifierFormCustomBlock";
import PatientDocumentTypeId from "@Primitives/PatientDocumentTypeId.g";
import { useErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import { useFormEntityIdField, useFormTextField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { getCompositeArrayFields } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import { useFormLayoutContext } from "@Toolkit/FormEngine/Panels/FormLayoutContext";
import { ICustomBlockRegistryItem, IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React, { useEffect, useMemo, useState } from "react";
import PatientHunSocialSecurityIdentifier from "./PatientHunSocialSecurityIdentifier";

interface IPatientHunSocialSecurityIdentifierFormCustomBlockDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IPatientHunSocialSecurityIdentifierFormCustomBlockProps extends IFormCustomBlockComponentProps {
    _dependencies?: IPatientHunSocialSecurityIdentifierFormCustomBlockDependencies;
}


function _PatientHunSocialSecurityIdentifierFormCustomBlock(props: IPatientHunSocialSecurityIdentifierFormCustomBlockProps) {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext.compositeDataReferences.join(".");

    const fields = getCompositeArrayFields(props.form.data.Content, compositeFieldName);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        addFieldsForPatientHunSocialSecurityIdentifier(props.form.data.Content, fields, compositeFieldName);
        addFieldsForPatientIdentifier(props.form.data.Content, fields, compositeFieldName);
        setIsLoaded(true);
    }, []);

    return isLoaded && (
        <PatientHunSocialSecurityIdentifierFormCustomBlockCore {...props} />
    );
}

const PatientHunSocialSecurityIdentifierFormCustomBlockCore = State.observer((props: IPatientHunSocialSecurityIdentifierFormCustomBlockProps) => {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext.compositeDataReferences.join(".");

    const [identifierValue, setIdentifierValue] = useFormTextField(props.form.data.Content, compositeFieldName + ".IdentifierValue");
    const [identifierTypeId, setIdentifierTypeId] = useFormEntityIdField<PatientDocumentTypeId>(props.form.data.Content, compositeFieldName + ".DocumentTypeId", PatientDocumentTypeId);

    const errorDispatcher = useErrorDispatcher();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        props._dependencies.careReferenceDataStore.patientDocumentType.ensureAllLoadedAsync()
            .catch(errorDispatcher)
            .then(() => setIsLoaded(true));
    }, []);

    const patientIdentifierTypeIdentifierSystemId = useMemo(() => {
        const patientDocumentType = props._dependencies.careReferenceDataStore.patientDocumentType.items.find(i => i.Entity.id.value === identifierTypeId?.value);
        return patientDocumentType?.Entity.identifierSystemId.value;
    }, [isLoaded, identifierTypeId]);

    usePatientIdentifierCustomBlockHelper(compositeFieldName, patientIdentifierTypeIdentifierSystemId, ["HunSocialSecurityNumber"], "PatientHunSocialSecurityIdentifierFormCustomBlock");

    return (patientIdentifierTypeIdentifierSystemId === "HunSocialSecurityNumber") && (
        <PatientHunSocialSecurityIdentifier
            identifierTypeId={identifierTypeId}
            onIdentifierTypeIdChange={setIdentifierTypeId}
            identifierValue={identifierValue}
            onIdentifierValueChange={setIdentifierValue}
        />
    );
});

function addFieldsForPatientHunSocialSecurityIdentifier(formFields: FormFieldDataBase[], array: IObservableArray<FormFieldDataBase>, compositeFieldName: string) {
    State.runInAction(() => {

    });
}

const PatientHunSocialSecurityIdentifierFormCustomBlock = connect(_PatientHunSocialSecurityIdentifierFormCustomBlock,
    new DependencyAdapter<IPatientHunSocialSecurityIdentifierFormCustomBlockProps, IPatientHunSocialSecurityIdentifierFormCustomBlockDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore")
        };
    }));

export const PatientHunSocialSecurityIdentifierRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Patient hun social security identifier",
    dataElementsFactory: () => [
    ],
    componentType: PatientHunSocialSecurityIdentifierFormCustomBlock
};

export default PatientHunSocialSecurityIdentifierFormCustomBlock;
