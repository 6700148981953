
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import FormLogicTokenEditorDialogStore from "./FormLogicTokenEditorDialogStore";

const { 
    ContextComponent: FormLogicTokenEditorDialogStoreContext, 
    StoreProvider: FormLogicTokenEditorDialogStoreProvider, 
    useStore: useFormLogicTokenEditorDialogStore,
    provideStore: provideFormLogicTokenEditorDialogStore,
} = createPanelStoreProvider(FormLogicTokenEditorDialogStore);

export {
    FormLogicTokenEditorDialogStoreContext,
    FormLogicTokenEditorDialogStoreProvider,
    useFormLogicTokenEditorDialogStore,
    provideFormLogicTokenEditorDialogStore
};