import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import * as Proxy from "@HisPlatform/BoundedContexts/Productivity/Api/Proxy.g";
import InputFormType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/InputFormType.g";
import InputDataClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/InputDataClientSideAction";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import Reflection from "@Toolkit/CommonWeb/Reflection/Reflection";
import ShowCareActivityStateChangeNotPossibleClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/ShowCareActivityStateChangeNotPossibleClientSideAction";
import IStatusChangeBlockingReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/IStatusChangeBlockingReason";
import IStatusChangeBlockingMessageParameters from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/IStatusChangeBlockingMessageParameters";
import SelectItemClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/SelectItemClientSideAction";
import CreateItemClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/CreateItemClientSideAction";
import ShowMessageClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/ShowMessageClientSideAction";
import UnknownClientSideAction from "@HisPlatform/Model/DomainModel/ClientSideAction/UnknownClientSideAction";

export function configureClientSideActionMappings(mapper: IMapperConfigurator) {
    mapper.registerByName<any, UnknownClientSideAction>("UnknownClientSideAction", _ => new UnknownClientSideAction());

    mapper.registerByName("InputDataClientSideActionDto", rawClientSideAction => {
        const dto = Proxy.InputDataClientSideActionDto.fromJS(rawClientSideAction);
        return new InputDataClientSideAction(dto.inputFormType as unknown as InputFormType, mapUseCaseArgumentsFromEntities(dto.arguments));
    });

    mapper.registerByName("ShowCareActivityStateChangeNotPossibleClientSideActionDto", rawClientSideAction => {
        const dto = Proxy.ShowCareActivityStateChangeNotPossibleClientSideActionDto.fromJS(rawClientSideAction);
        return mapShowCareActivityStateChangeNotPossibleClientSideAction(dto);
    });

    mapper.registerByName("SelectItemClientSideActionDto", rawClientSideAction => {
        const dto = Proxy.SelectItemClientSideActionDto.fromJS(rawClientSideAction);
        const typeName = dto.entity?.entityTypeName.split(".").pop();
        const entityId = Reflection.createInstance(typeName, dto.entity.value?.Value?.toString());
        return new SelectItemClientSideAction(entityId);
    });

    mapper.registerByName("CreateItemClientSideActionDto", rawClientSideAction => {
        const dto = Proxy.CreateItemClientSideActionDto.fromJS(rawClientSideAction);
        return new CreateItemClientSideAction(dto.worklistType.value);
    });

    mapper.registerByName("ShowMessageClientSideActionDto", rawClientSideAction => {
        const dto = Proxy.ShowMessageClientSideActionDto.fromJS(rawClientSideAction);
        return new ShowMessageClientSideAction(dto.titleResourceId, dto.messageResourceId);
    });
}

function mapShowCareActivityStateChangeNotPossibleClientSideAction(dto: Proxy.ShowCareActivityStateChangeNotPossibleClientSideActionDto): ShowCareActivityStateChangeNotPossibleClientSideAction {
    return new ShowCareActivityStateChangeNotPossibleClientSideAction(
        dto.titleResourceId,
        dto.careActivityId,
        dto.activityReferenceResourceId,
        dto.reasons.map(i => ({
            stateChangeBlockingData: i.stateChangeBlockingData,
            resourceId: i.stateChangeBlockingMessage?.resourceId,
            messsageParameters: i.stateChangeBlockingMessage?.properties && mapToStatusChangeBlockingMessageParameters(i.stateChangeBlockingMessage.properties)
        } as IStatusChangeBlockingReason))
    );
}

function mapUseCaseArgumentsFromEntities(entities: Proxy.EntityDto[]): UseCaseArgument[] {
    return entities.map(i => {
        const typeName = i.entityTypeName.split(".").pop();
        const entityId = Reflection.createInstance(typeName, i.value?.Value?.toString());

        return new UseCaseArgument(entityId, typeName.charAt(0).toLowerCase() + typeName.slice(1));
    });
}

function mapToStatusChangeBlockingMessageParameters(obj: object) {
    return Object.keys(obj).map(key => {
        return {
            key: key,
            parameter: obj[key]
        } as IStatusChangeBlockingMessageParameters;
    });
}