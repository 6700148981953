import React from "react";
import * as Ui from "@CommonControls";
import StaticHunSocialSecurityFinanceResources from "@HunSocialSecurityPlugin/BoundedContexts/Finance/StaticResources/StaticHunSocialSecurityFinanceResources";
import LaboratoryExaminationRequestTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/LaboratoryExaminationRequestTypeSelectBox";
import ImagingExaminationRequestTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/ImagingExaminationRequestTypeSelectBox";
import CtMrExaminationRequestTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/CtMrExaminationRequestTypeSelectBox";
import PhysiotherapyExaminationRequestTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/PhysiotherapyExaminationRequestTypeSelectBox";
import TravelExpenseClaimTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/TravelExpenseClaimTypeSelectBox";
import EarningCapacityConsiderationTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/EarningCapacityConsiderationTypeSelectBox";
import CareActivityFinanceData from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/CareActivityFinanceData";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface ICareActivityFinanceDataPanelViewProps {
    financeData: CareActivityFinanceData;
}


const CareActivityFinanceDataPanelView: React.FC<ICareActivityFinanceDataPanelViewProps> = props => {
    return (
        <State.Observer>
            {() => (
                <>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <Ui.NumberBox
                                integerOnly
                                innerLabel={{ upperText: props.financeData.partiallyReimbursedCost.currencyCode, lowerText: "" }}
                                label={StaticHunSocialSecurityFinanceResources.CareActivityFinanceDataPanel.Label.PartiallyReimbursedCost}
                                value={props.financeData.partiallyReimbursedCost.amount}
                                onChange={props.financeData.setpartiallyReimbursedCostAmount}
                                propertyIdentifier="PartiallyReimbursedCost"
                                automationId="partiallyReimbursedCost"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <LaboratoryExaminationRequestTypeSelectBox
                                label={StaticHunSocialSecurityFinanceResources.CareActivityFinanceDataPanel.Label.LaboratoryExaminationRequestType}
                                value={props.financeData.laboratoryExaminationRequestTypeId}
                                onChange={props.financeData.setlaboratoryExaminationRequestTypeId}
                                propertyIdentifier="LaboratoryExaminationRequestTypeId"
                                automationId="laboratoryExaminationRequestType"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <ImagingExaminationRequestTypeSelectBox
                                label={StaticHunSocialSecurityFinanceResources.CareActivityFinanceDataPanel.Label.ImagingExaminationRequestType}
                                value={props.financeData.imagingExaminationRequestTypeId}
                                onChange={props.financeData.setimagingExaminationRequestTypeId}
                                propertyIdentifier="ImagingExaminationRequestTypeId"
                                automationId="imagingExaminationRequestType"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <CtMrExaminationRequestTypeSelectBox
                                label={StaticHunSocialSecurityFinanceResources.CareActivityFinanceDataPanel.Label.CtMrExaminationRequestType}
                                value={props.financeData.ctrExaminationRequestTypeId}
                                onChange={props.financeData.setctrExaminationRequestTypeId}
                                propertyIdentifier="CtMrExaminationRequestTypeId"
                                automationId="ctMrExaminationRequestType"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <PhysiotherapyExaminationRequestTypeSelectBox
                                label={StaticHunSocialSecurityFinanceResources.CareActivityFinanceDataPanel.Label.PhysiotherapyExaminationRequestType}
                                value={props.financeData.physiotherapyExaminationRequestTypeId}
                                onChange={props.financeData.setphysiotherapyExaminationRequestTypeId}
                                propertyIdentifier="PhysiotherapyExaminationRequestTypeId"
                                automationId="physiotherapyExaminationRequestType"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <Ui.NumberBox
                                integerOnly
                                label={StaticHunSocialSecurityFinanceResources.CareActivityFinanceDataPanel.Label.NumberOfPrescriptions}
                                value={props.financeData.numberOfPrescriptions}
                                onChange={props.financeData.setnumberOfPrescriptions}
                                propertyIdentifier="NumberOfPrescriptions"
                                automationId="numberOfPrescriptions"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.NumberBox
                                integerOnly
                                label={StaticHunSocialSecurityFinanceResources.CareActivityFinanceDataPanel.Label.NumberOfGySePrescriptions}
                                value={props.financeData.numberOfGySePrescriptions}
                                onChange={props.financeData.setnumberOfGySePrescriptions}
                                propertyIdentifier="NumberOfGySePrescriptions"
                                automationId="numberOfGySePrescriptions"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.NumberBox
                                integerOnly
                                label={StaticHunSocialSecurityFinanceResources.CareActivityFinanceDataPanel.Label.NumberOfTherapyPrescriptions}
                                value={props.financeData.numberOfTherapyPrescriptions}
                                onChange={props.financeData.setnumberOfTherapyPrescriptions}
                                propertyIdentifier="NumberOfTherapyPrescriptions"
                                automationId="numberOfTherapyPrescriptions"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <TravelExpenseClaimTypeSelectBox
                                label={StaticHunSocialSecurityFinanceResources.CareActivityFinanceDataPanel.Label.TravelExpenseClaimType}
                                value={props.financeData.travelExpenseClaimTypeId}
                                onChange={props.financeData.settravelExpenseClaimTypeId}
                                propertyIdentifier="TravelExpenseClaimTypeId"
                                automationId="travelExpenseClaimType"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <EarningCapacityConsiderationTypeSelectBox
                                label={StaticHunSocialSecurityFinanceResources.CareActivityFinanceDataPanel.Label.EarningCapacityConsiderationType}
                                value={props.financeData.earningCapacityConsiderationTypeId}
                                onChange={props.financeData.setearningCapacityConsiderationTypeId}
                                propertyIdentifier="EarningCapacityConsiderationTypeId"
                                automationId="earningCapacityConsiderationType"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </>
            )}
        </State.Observer>
    );
};

export default CareActivityFinanceDataPanelView;
