import IEntityDto from "./IEntityDto";
import ILockingEntityStore from "@Toolkit/CommonWeb/Model/ILockingEntityStore";
import EntityStoreMapper from "./EntityStoreMapper";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";

export default abstract class LockingEntityStoreMapper<TEntityDto extends IEntityDto, TStore extends ILockingEntityStore, TResponse = TEntityDto>
    extends EntityStoreMapper<TEntityDto, TStore, TResponse> {

    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    public applyToStore(target: TStore, response: TResponse, isPermissionCheckOnly: boolean = false) {

        this.applyOperationInfo(target, response);

        if (!!response && "lockState" in response) {
            const responseWithLock = response as any;
            target.lockInfo = new LockInfo(
                responseWithLock.lockState,
                responseWithLock.heldLockId,
                responseWithLock.wasLockRequested,
                responseWithLock.hasUpdatePermission,
                responseWithLock.preventingLock?.owner?.name,
                responseWithLock.preventingLock?.id,
                responseWithLock.preventingLock?.owner?.userId
            );
        }

        if (isPermissionCheckOnly === false) {
            this.applyToStoreCore(target, response);
            this.applyToEntityStore(target, response);
        }
    }

    public applyToStoreWithExistingLock(target: TStore, response: any, existingLock: LockInfo, releaseLockIfSuccessful: boolean, isPermissionCheckOnly: boolean = false) {

        if (!("isPersisted" in response && response.isPersisted === true && releaseLockIfSuccessful)) {
            target.lockInfo = existingLock;
        } else {
            target.lockInfo = null;
        }

        this.applyToStore(target, response, isPermissionCheckOnly);
    }

    public async applyToStoreWithExistingLockAndResolveValidationProblemsAsync(target: TStore, response: any, existingLock: LockInfo, releaseLockIfSuccessful: boolean, isPermissionCheckOnly: boolean = false) {
        this.applyToStoreWithExistingLock(target, response, existingLock, releaseLockIfSuccessful, isPermissionCheckOnly);
        this.collectValidationParameterReferenceData(target.validationResults);
        await this.loadCollectedValidationParameterReferenceDataAsync();
        this.resolveValidationProblemParameters(target);
    }

    protected abstract applyToStoreCore(target: TStore, reponse: any): void;
}