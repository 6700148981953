import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import Money from "@Toolkit/CommonWeb/Model/Money";
import UnitTypeId from "@Primitives/UnitTypeId.g";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";

export default class FinancedService {
    constructor(
        public id: FinancedServiceId,
        public code: Identifier,
        public validity: LocalDateRange,
        public name: string,
        public unitPrice: Money,
        public unitTypeId: UnitTypeId,
        public medicalServiceIds: MedicalServiceId[]) {
    }
}
