import React from "react";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import MedicationEquipmentClassificationId from "@Primitives/MedicationEquipmentClassificationId.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import { IUniversalCodeSelectorProps } from "@HisPlatformControls/UniversalCodeSelector";
import IMedicationEquipmentClassificationVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/IMedicationEquipmentClassificationVersion";
import * as HisUi from "@HisPlatformControls";
import { IPagingState, IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import { IComplexSearchItemGroup } from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchPanel";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicationsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicationsApiAdapter";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface IMedicationEquipmentClassificationCodeSelectorDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    apiAdapter: MedicationsApiAdapter;
}

interface IMedicationEquipmentClassificationCodeSelectorProps extends ICodeSelectorCommonProps<MedicationEquipmentClassificationId> {
    _dependencies?: IMedicationEquipmentClassificationCodeSelectorDependencies;
    validOn?: LocalDate;
}

class MedicationEquipmentClassificationCodeSelector extends React.Component<IMedicationEquipmentClassificationCodeSelectorProps> {

    private get validOn() {
        return this.props.validOn || LocalDate.today();
    }

    @State.bound
    private async getDisplayValueAsync(value: MedicationEquipmentClassificationId) {
        if (value) {
            const versionSelector = { id: value, validOn: this.validOn };
            await this.props._dependencies.careReferenceDataStore.medicationEquipmentClassifications.ensureLoadedAsync([versionSelector]);

            const item = this.props._dependencies.careReferenceDataStore.medicationEquipmentClassifications.get(versionSelector);
            return item.code + " " + item.name;
        }
        return "";
    }

    @State.bound
    private async loadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: IMedicationEquipmentClassificationVersion[], selectedGroup: IComplexSearchItemGroup) {
        const response = await this.props._dependencies.apiAdapter.searchMedicationEquipmentClassifications(
            filterText,
            ordering,
            paging);

        return {
            totalCount: response.totalCount,
            items: response.items
        };
    }
    @State.bound
    private async quickSearchAsync(text: string) {      
        const result = await this.props._dependencies.apiAdapter.searchMedicationEquipmentClassifications(
            text,
            null,
            null
        );

        if (result.operationWasSuccessful) {
            const ids = result.items.map(r => r.id);
            return ids;
        }

        return null;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: IMedicationEquipmentClassificationVersion) {
        this.props.onChange(item.id);
    }

    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            codeGetter: "code",
            nameGetter: "name",
            complexSearchLoadAsync: this.loadAsync,
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            hasComplexSearch: true
        } as IUniversalCodeSelectorProps<MedicationEquipmentClassificationId, IMedicationEquipmentClassificationVersion>;
        return (
            <>
                <HisUi.UniversalCodeSelector
                    {...props}
                    automationId={this.props.automationId}
                    onQuickSearchAsync={this.quickSearchAsync}
                />
            </>
        );
    }
}

export default connect(
    MedicationEquipmentClassificationCodeSelector,
    new DependencyAdapter<IMedicationEquipmentClassificationCodeSelectorProps, IMedicationEquipmentClassificationCodeSelectorDependencies>((container) => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            apiAdapter: container.resolve("MedicationsApiAdapter")
        };
    })
);