import { iconNameType } from "@CommonControls/Icon";
import React from "react";

export interface ITabPaneProps {
    title: string | React.ReactNode;
    name?: string;
    children?: React.ReactNode | (() => React.ReactNode);
    disabled?: boolean;
    disablingTooltip?: string;
    automationId: string;
    className?: string;
    propertyPathRegexPattern?: string;
    iconName?: iconNameType;
}

const defaultProps: Partial<ITabPaneProps> = {
    disabled: false
};


const TabPane: React.SFC<ITabPaneProps> = (props) => {
    return null; // Tab renders this.
};

TabPane.defaultProps = defaultProps;

export default TabPane;