import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import * as Ui from "@CommonControls";
import SchedulingServiceSelectBox from "@HisPlatform/BoundedContexts/Scheduling/Components/Controls/SchedulingServiceSelectBox/SchedulingServiceSelectBox";
import PointOfCareSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareSelectBox";
import PractitionerSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerSelectBox";
import SchedulingServiceFilter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceFilter";
import SchedulingServiceFilterType from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/SchedulingServiceFilterType.g";

interface ISchedulingServiceFilterPanelViewProps {
    readonly: boolean;
    setLoadingState?: (state: boolean) => void;
    schedulingServiceFilter: SchedulingServiceFilter;
    explicitIds: any;
}

@State.observer
export default class SchedulingServiceFilterPanelView extends React.Component<ISchedulingServiceFilterPanelViewProps> {

    private readonly resources = StaticSchedulingResources.SchedulingServiceFilterPanel;

    public render() {
        return (
            <>
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <SchedulingServiceSelectBox
                            propertyIdentifier="SchedulingServiceId"
                            value={this.props.schedulingServiceFilter.schedulingServiceId}
                            label={this.resources.SchedulingService}
                            onChange={this.props.schedulingServiceFilter.setSchedulingServiceId}
                            isReadOnly={this.props.readonly}
                            explicitIds={this.props.explicitIds.explicitSchedulingServiceIds}
                            setLoadingState={this.props.setLoadingState}
                            automationId="schedulingServiceSelectBox"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <PointOfCareSelectBox
                            label={this.resources.PointOfCare}
                            value={this.props.schedulingServiceFilter.pointOfCareId}
                            onChange={this.props.schedulingServiceFilter.setPointOfCareId}
                            explicitIds={this.props.explicitIds.explicitPointOfCareIds}
                            isReadOnly={this.props.readonly}
                            automationId="pointOfCareSelectBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <Ui.UniversalEnumSelector
                            label={this.resources.PointOfCareFilterType}
                            enumOrigin="server"
                            displayMode="groupedRadioButtons"
                            enumName={"SchedulingServiceFilterType"}
                            enumType={SchedulingServiceFilterType}
                            value={this.props.schedulingServiceFilter.pointOfCareFilterType}
                            onChange={this.props.schedulingServiceFilter.setPointOfCareFilterType}
                            automationId="pointOfCareFilterTypeSelector"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <PractitionerSelectBox
                            label={this.resources.Practitioner}
                            onChange={this.props.schedulingServiceFilter.setPractitionerId}
                            value={this.props.schedulingServiceFilter.practitionerId}
                            explicitIds={this.props.explicitIds.explicitPractitionerIds}
                            isReadOnly={this.props.readonly}
                            automationId="practitionerSelectBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <Ui.UniversalEnumSelector
                            label={this.resources.PractitionerFilterType}
                            enumOrigin="server"
                            displayMode="groupedRadioButtons"
                            enumName={"SchedulingServiceFilterType"}
                            enumType={SchedulingServiceFilterType}
                            value={this.props.schedulingServiceFilter.practitionerFilterType}
                            onChange={this.props.schedulingServiceFilter.setPractitionerFilterType}
                            automationId="practitionerFilterTypeSelector"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </>
        );
    }
}
