import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class MedicalServiceStore extends EntityStoreBase<MedicalServiceId> {
    public id: MedicalServiceId;
    public name: string;
    public alternativeName: string;
    public code: Identifier;

    @State.computed
    public get codeValue() {
        return this.code?.value;
    }
}
