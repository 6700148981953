import React from "react";
import {ContextAwareModal} from "@HisPlatformControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientWorklistPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/PatientWorklist/PatientWorklistPanel";
import {Modal} from "@CommonControls";
import PatientId from "@Primitives/PatientId.g";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import * as Ui from "@CommonControls";
import {TypedEvent} from "@Toolkit/CommonWeb/TypedEvent";

export interface IPatientSelectorDialogProps {
    _isOpen: boolean;
    _onClose: () => void;
    modalTitle: string;
    value: PatientId;
    setValue: (newValue: PatientId) => void;
}

@State.observer
class PatientSelectorDialog extends React.Component<IPatientSelectorDialogProps> {
    private clearFiltersEvent = new TypedEvent();

    @State.bound
    private onSelectPatient(patientId: PatientId) {
        this.props.setValue(patientId);
        this.onClose();
    }

    @State.bound
    private onClose() {
        this.props._onClose();
        this.clearFiltersEvent.emit();
    }

    public render() {
        return (
            <ContextAwareModal
                isOpen={this.props._isOpen}
                onClose={this.onClose}
                title={this.props.modalTitle}
                isHeaderPrimary
                closeButton
            >
                <Modal.Body>
                    <LoadingBoundary>
                        <PatientWorklistPanel
                            mode={"Selector"}
                            onSelectPatient={this.onSelectPatient}
                            clearFiltersEvent={this.clearFiltersEvent}
                        />
                    </LoadingBoundary>
                </Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Button
                        onClick={this.onClose}
                        visualStyle={"flat"}
                        text={StaticCareResources.Common.Button.Cancel}
                        float="left"
                        automationId="cancelButton"
                    />
                </Ui.Modal.Footer>
            </ContextAwareModal>
        );
    }
}

export default PatientSelectorDialog;