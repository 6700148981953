exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MenuSeparator_menu-separator_QZUWN:not(:first-child) {\n  padding: 16px 0 4px 0;\n}\n.MenuSeparator_menu-separator_QZUWN {\n  font-family: 'PragatiNarrowBold', Arial, Helvetica, sans-serif;\n  margin-left: 13px;\n  color: #8695a7;\n}\n", ""]);

// exports
exports.locals = {
	"menu-separator": "MenuSeparator_menu-separator_QZUWN",
	"menuSeparator": "MenuSeparator_menu-separator_QZUWN"
};