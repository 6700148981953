import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";

export interface IAccessControlModalParams extends IModalParams {
    entityType: string;
    entityId: string;
}

export default class AccessControlModalParams implements IAccessControlModalParams {

    public static type = "AccessControlModal";
    public get type() { return AccessControlModalParams.type; }

    constructor(
        public readonly entityType: "CareActivity" | "CareDocument",
        public readonly entityId: string
    ) {}
}