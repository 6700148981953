import Command from "./Command";

/**
 * A command that has no specified type. Intended for backward compatibility only!
 */
export default class UnspecifiedCommand extends Command {
    constructor(
        originator: string,
        public readonly data: object) {
        super("UnspecifiedCommand", originator);
    }
}