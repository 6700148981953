import React, { useCallback } from "react";
import PropertyBase from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyBase";
import BoolProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/BoolProperty";
import NumericProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/NumericProperty";
import NumericRangeProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/NumericRangeProperty";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";
import * as Ui from "@CommonControls";
import IPropertyValueVersion from "@HisPlatform/Model/DomainModel/Common/IPropertyValueVersion";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DynamicPropertyInputType from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/DynamicPropertyInputType";
import Encoding from "@Toolkit/CommonWeb/Encoding";
import Log from "@HisPlatform/Services/Definition/Logger/Log";

interface IPropertyVersionValueEditorProps {
    property: PropertyBase;
    version: IPropertyValueVersion<any>;
}

const booleanPropertyItems: boolean[] = [true, false];
const displayValueGetter = (item: boolean) => {
    return item ?
        StaticOrganizationResources.PropertyGroupsPanel.BoolPropertyEditor.On :
        StaticOrganizationResources.PropertyGroupsPanel.BoolPropertyEditor.Off;
};


const PropertyVersionValueEditor: React.FC<IPropertyVersionValueEditorProps> = (props: IPropertyVersionValueEditorProps) => {
    const commonProps = {
        value: props.version.value,
        onChange: props.version.setValue
    };

    const inputOpenFileRef = React.createRef<HTMLInputElement>();

    const setPropertyValueAsync = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();

        const file = event.target.files[0];

        try {
            const bytes = await file.toUint8ArrayAsync();
            const content = Encoding.UTF8.getString(bytes);
            props.version.setValue(content);
        } catch (error) {
            Log.error(error);
        }
    }, []);

    const showOpenFileDialog = useCallback(() => inputOpenFileRef.current.click(), []);

    if (props.property.inputType === DynamicPropertyInputType.File) {
        return (
            <>
                <Ui.Button iconName="align_top" onClick={showOpenFileDialog} automationId={props.property.name + "_openFileButton"} />
                <input type="file" onChange={setPropertyValueAsync} ref={inputOpenFileRef} style={{ display: "none" }} />
            </>
        );
    }

    if (props.property instanceof BoolProperty) {
        return (
            <Ui.RadioButtonGroup
                {...commonProps}
                items={booleanPropertyItems}
                getDisplayValue={displayValueGetter}
                displayType="groupedButtons"
            />
        );
    } else if (props.property instanceof NumericProperty) {
        return (
            <Ui.NumberBox {...commonProps} placeholder={StaticOrganizationResources.PropertyVersionModal.Value} automationId={props.property.name + "_" + props.version.validFrom + "-" + props.version.validTo + "_numberBox"} />
        );
    } else if (props.property instanceof NumericRangeProperty) {
        return (
            <Ui.Flex>
                <Ui.Flex.Item sm={6}>
                    <Ui.NumberBox value={props.version.value?.from ?? 0} onChange={props.version.setValue} automationId={props.property.name + "_" + props.version.validFrom + "-" + props.version.validTo + "_fromNumberBox"} />
                </Ui.Flex.Item>
                <Ui.Flex.Item sm={6}>
                    <Ui.NumberBox value={props.version.value?.to ?? 0} onChange={props.version.setValue} automationId={props.property.name + "_" + props.version.validFrom + "-" + props.version.validTo + "_toNumberBox"} />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    } else {
        return (
            <Ui.TextBox {...commonProps} placeholder={StaticOrganizationResources.PropertyVersionModal.Value} automationId={props.property.name + "_" + props.version.validFrom + "-" + props.version.validTo + "_textBox"} />
        );
    }
};

export default State.observer(PropertyVersionValueEditor);
