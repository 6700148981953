import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/UserManagement/Api/Proxy.g";
import _ from "@HisPlatform/Common/Lodash";
import UserId from "@Primitives/UserId.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import UserListStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/UserListStore";
import UserStoreMapper from "./UserStoreMapper";
import Di from "@Di";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { getAddUserControllerDto, getUpdateUserControllerDto, getUpdateUserPermissionCheckDto } from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Users/UserDtoMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { buildQueryStringArray } from "@Toolkit/CommonWeb/QueryStringBuilder";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

@Di.injectable()
export default class UsersApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IUsersClient") private apiClient: Proxy.IUsersClient,
        @Di.inject("UserStoreMapper") private userStoreMapper: UserStoreMapper

    ) {
        super();
    }

    public validateChangeUserPasswordAsync(loginName: string, oldPassword: string, newPassword: string, newPasswordAgain: string) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response = await this.apiClient.changeUserPasswordCommandAsync(CreateRequestId(), new Proxy.ChangeUserPasswordControllerDto({
                    loginName: loginName,
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                    newPasswordAgain: newPasswordAgain,
                    isValidateOnly: true
                }));

                const newUser = new User();
                await this.userStoreMapper.applyToStoreAndResolveValidationProblemsAsync(newUser, response);

                target.value = newUser.validationResults;
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public changeUserPasswordAsync(loginName: string, oldPassword: string, newPassword: string, newPasswordAgain: string) {
        return this.processOperationAsync(
            new User(),
            async target => {
                const response = await this.apiClient.changeUserPasswordCommandAsync(CreateRequestId(), new Proxy.ChangeUserPasswordControllerDto({
                    loginName: loginName,
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                    newPasswordAgain: newPasswordAgain,
                    isValidateOnly: false
                }));

                this.userStoreMapper.applyToStore(target, response);

                await this.userStoreMapper.applyToStoreAndResolveValidationProblemsAsync(target, response);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public loadAllUsersAsync() {
        return this.processOperationAsync(
            new UserListStore(),
            async target => {
                const response = await this.apiClient.getAllUsersQueryAsync(CreateRequestId());
                target.users = response.users.map(u => new User(u.id, u.displayName, u.loginName, u.isEnabled, u.isPasswordExpirable, false, u.userGroupIds));
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public loadUserByIdAsync(userId: UserId) {
        return this.processOperationAsync(
            new User(),
            async target => {
                const response = await this.apiClient.getUserByIdQueryAsync(
                    CreateRequestId(),
                    userId.value
                );

                this.userStoreMapper.applyToStore(target, response);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public validateAsync(user: User) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const dto = user.isNew ?
                    getAddUserControllerDto(user) :
                    getUpdateUserControllerDto(user);

                dto.isValidateOnly = true;

                const response = user.isNew ? await this.apiClient.addUserCommandAsync(
                    CreateRequestId(),
                    dto
                ) : await this.apiClient.updateUserCommandAsync(
                    CreateRequestId(),
                    dto as Proxy.UpdateUserControllerDto
                );

                const newUser = new User();
                await this.userStoreMapper.applyToStoreAndResolveValidationProblemsAsync(newUser, response);

                target.value = newUser.validationResults;
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public addUserAsync(user: User) {
        const dto = getAddUserControllerDto(user);

        return this.processOperationAsync(
            new User(),
            async target => {
                const response = await this.apiClient.addUserCommandAsync(
                    CreateRequestId(),
                    dto
                );

                await this.userStoreMapper.applyToStoreAndResolveValidationProblemsAsync(target, response);
            }
        );
    }

    public updateUserAsync(user: User) {
        const dto = getUpdateUserControllerDto(user);

        return this.processOperationAsync(
            new User(),
            async target => {
                const response = await this.apiClient.updateUserCommandAsync(
                    CreateRequestId(),
                    dto
                );

                await this.userStoreMapper.applyToStoreAndResolveValidationProblemsAsync(target, response);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public updateUserPermissionCheckAsync() {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.updateUserCommandAsync(
                    CreateRequestId(),
                    getUpdateUserPermissionCheckDto(),
                    true
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getAllUserIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<UserId[]>(),
            async target => {
                const response = await this.apiClient.getAllUserIdQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.ids;
            }
        );
    }

    @State.bound
    public getUsersByIdsAsync(ids: UserId[]): Promise<SimpleStore<User[]>> {
        return this.processOperationAsync(
            new SimpleStore<User[]>(),
            async target => {
                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value));
                const response = await this.apiClient.getUsersByIdsQueryAsync(CreateRequestId(),
                    buildQueryStringArray(normalizedIds));

                target.operationInfo = createOperationInfo(response);
                target.value = response.users.map((item) => {
                    const newStore = new User();
                    this.userStoreMapper.applyToStore(newStore, item);
                    return newStore;
                });
            }
        );
    }

    @State.bound
    public getDaysUntilPasswordExpirationByUserIdAsync(id: UserId): Promise<SimpleStore<number>> {
        return this.processOperationAsync(
            new SimpleStore<number>(),
            async target => {
                const response = await this.apiClient.getDaysUntilPasswordExpirationByUserIdQueryAsync(CreateRequestId(), id.value);

                target.operationInfo = createOperationInfo(response);
                target.value = response.daysUntilPasswordExpiration;
            }
        );
    }
}