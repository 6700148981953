import Di from "@Di";
import IMedicalConditionLastSynchronizationSubtitleExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IMedicalConditionLastSynchronizationSubtitleExtensionPointProps";
import IMedicalConditionSynchronizationExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IMedicalConditionSynchronizationExtensionPointProps";
import ExtensionStore from "@PluginInterface/Common/ExtensionStore";
import MedicalConditionType from "@Primitives/MedicalConditionType";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ImmunizationListScreenApiAdapter from "./ImmunizationListScreenApiAdapter";
import { IImmunizationListScreenProps } from "./ImmunizationListScreen";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";

@Di.injectable()
export default class ImmunizationListScreenStore extends PanelStoreBase<IImmunizationListScreenProps> {

    public readonly refreshListEvent = new TypedAsyncEvent();
    private readonly extensionStore = new ExtensionStore();
    private readonly medicalConditionSynchronizationExtensionEvent = new TypedAsyncEvent();

    @State.computed
    public get synchronizationExtensionProps() {
        return {
            medicalConditionType: MedicalConditionType.Immunization,
            patientId: this.props.action.patientId,
            refreshListAsync: this.refreshListEvent.emitAsync,
            extensionStore: this.extensionStore,
            medicalConditionSynchronizationExtensionEvent: this.medicalConditionSynchronizationExtensionEvent
        } as IMedicalConditionSynchronizationExtensionPointProps;
    }

    @State.computed
    public get lastSynchronizationSubtitleExtensionProps() {
        return {
            patientId: this.props.action.patientId,
            medicalConditionType: MedicalConditionType.Immunization,
            medicalConditionSynchronizationExtensionEvent: this.medicalConditionSynchronizationExtensionEvent
        } as IMedicalConditionLastSynchronizationSubtitleExtensionPointProps;
    }

    constructor(
        @Di.inject("ImmunizationListScreenApiAdapter") private readonly immunizationListScreenApiAdapter: ImmunizationListScreenApiAdapter
    ) {
        super();
    }

    @State.bound
    public async getDynamicListAsync(args: FrontendListParameters) {
        const response = await this.immunizationListScreenApiAdapter.getImmunizationListAsync(this.props.action.patientId, args);
        return response.result;
    }
}