import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import ServiceRequestDefinitionCode from "./ServiceRequestDefinitionCode";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import { CareLocation } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/CareLocation";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import MedicalServicePanelId from "@Primitives/MedicalServicePanelId.g";

export default class ServiceRequestDefinition {

    public readonly internalLocationsAsPointOfCareIds: PointOfCareId[];
    public readonly externalLocations: Array<IEntityVersionSelector<ExternalLocationId>>;

    constructor(
        public readonly id: ServiceRequestDefinitionId,
        public readonly code: ServiceRequestDefinitionCode,
        public readonly name: string,
        public readonly shortName: string,
        public readonly clinicalQuestionAllowed: boolean,
        public readonly medicalServiceIds: MedicalServiceId[],
        public readonly careLocations: CareLocation[],
        public readonly validity: LocalDateRange,
        public readonly availableDirections: ServiceRequestDirection[],
        public readonly medicalServicePanelIds: MedicalServicePanelId[],
        public readonly requestDocumentTypeCode: string,
        public readonly reportDocumentTypeCode: string,
        public readonly specimenDataRequired: boolean
    ) {
        this.internalLocationsAsPointOfCareIds = (this.careLocations as InternalCareLocation[]).map(loc => loc.pointOfCareId).filter(l => !!l);
        this.externalLocations = (this.careLocations as ExternalCareLocation[]).map(loc => loc.externalLocationSelector).filter(l => !!l);
    }
}
