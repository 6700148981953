import { IRoutingController } from "./Abstractions/IRoutingController";
import IHostRoutingAdapter from "./Abstractions/IHostRoutingAdapter";
import Route from "./Abstractions/Route";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class RoutingController<TRouteParameters = any> implements IRoutingController<TRouteParameters> {

    constructor(
        private readonly hostRoutingAdapter: IHostRoutingAdapter,
        public readonly currentRoute: Route<TRouteParameters>
    ) { }

    public navigatingAwayHookAsync: () => Promise<boolean> = null;

    public pushRaw(pathname: string, queryString?: string): void {
        this.hostRoutingAdapter.push(pathname, queryString);
    }

    public replaceRaw(pathname: string, queryString?: string): void {
        this.hostRoutingAdapter.replace(pathname, queryString);
    }

    @State.bound
    public goBack() {
        this.hostRoutingAdapter.goBack();
    }

    public push(route: Route, queryString?: string): void {
        this.hostRoutingAdapter.push(route.pathname, queryString);
    }

    public replace(route: Route, queryString?: string): void {
        this.hostRoutingAdapter.replace(route.pathname, queryString);
    }

    public replaceQueryString(query: string): void {
        this.hostRoutingAdapter.replaceQueryString(query);
    }
}