exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CareActivityMenu_no-margin_2HzMN {\n  margin: 0;\n}\n.CareActivityMenu_monogram-conatiner_23FAZ {\n  display: flex;\n  flex-direction: row;\n  line-height: 1;\n  align-items: baseline;\n  column-gap: 4px;\n  margin: 0.2rem 0 0.2rem 0;\n}\n.CareActivityMenu_monogram-conatiner_23FAZ .CareActivityMenu_icon_aEfcf {\n  border: 1px solid #00607e;\n}\n.CareActivityMenu_care-status-conatiner_1RNPn {\n  display: flex;\n  flex-direction: row;\n  line-height: 1;\n  align-items: baseline;\n  margin: 0.2rem 0 0.2rem 0;\n  column-gap: 8px;\n  padding-left: 3px;\n}\n.CareActivityMenu_care-status-conatiner_1RNPn .CareActivityMenu_icon_aEfcf {\n  border: 1px solid #00607e;\n}\n", ""]);

// exports
exports.locals = {
	"no-margin": "CareActivityMenu_no-margin_2HzMN",
	"noMargin": "CareActivityMenu_no-margin_2HzMN",
	"monogram-conatiner": "CareActivityMenu_monogram-conatiner_23FAZ",
	"monogramConatiner": "CareActivityMenu_monogram-conatiner_23FAZ",
	"icon": "CareActivityMenu_icon_aEfcf",
	"care-status-conatiner": "CareActivityMenu_care-status-conatiner_1RNPn",
	"careStatusConatiner": "CareActivityMenu_care-status-conatiner_1RNPn"
};