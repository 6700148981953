import React from "react";
import Dosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Dosage";
import FrequencyBasedDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/FrequencyBasedDosage";
import ScheduledDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/ScheduledDosage";
import TextualDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/TextualDosage";
import * as Ui from "@CommonControls";
import DosageInterval from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/DosageInterval";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { DosageType } from "./DosageType";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ValidationBoundaryAdapter from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryAdapter";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import { arrayIsNullOrEmpty, emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";

export interface IDosageEditorProps {
    dosage?: Dosage;
    setDosage?: (newDosage: Dosage) => void;
    _validationBoundary?: IValidationBoundaryStore;
}

@State.observer
class DosageEditor extends React.Component<IDosageEditorProps> {

    @State.observable public dosageType: DosageType = null;

    private static nonPropertyRegex: RegExp = new RegExp("Dosage$");

    @State.computed
    private get nonPropertyValidationProblems() {
        if (this.props._validationBoundary) {
            const problems = this.props._validationBoundary.getValidationProblemsByRegex(DosageEditor.nonPropertyRegex);
            return arrayIsNullOrEmpty(problems) ? emptyArray : problems;
        }
        return emptyArray;
    }

    @State.action.bound
    public setDosageTypeAndDosage(dosageType: DosageType) {
        this.setDosageType(dosageType);
        switch (dosageType) {
            case DosageType.FrequencyBased:
                const frequencyBasedDosage = new FrequencyBasedDosage();
                frequencyBasedDosage.dosageMode = this.props.dosage.dosageMode;
                this.props.setDosage(frequencyBasedDosage);
                break;
            case DosageType.Scheduled:
                const scheduledDosage = new ScheduledDosage();
                scheduledDosage.dosageMode = this.props.dosage.dosageMode;
                this.props.setDosage(scheduledDosage);
                break;
            case DosageType.Textual:
                const textualDosage = new TextualDosage();
                textualDosage.dosageMode = this.props.dosage.dosageMode;
                this.props.setDosage(textualDosage);
                break;
            default:
                throw new Error("Nope.");
        }
    }

    @State.action.bound
    public setDosageType(dosageType: DosageType) {
        this.dosageType = dosageType;
    }

    @State.bound
    private inspectDosage() {
        return this.props.dosage;
    }

    @State.bound
    private fireValidationChanged() {
        this.props._validationBoundary?.changed("Dosage", this.props.dosage);
    }

    public componentDidMount() {
        if (!this.props.dosage || this.props.dosage instanceof FrequencyBasedDosage) {
            this.setDosageType(DosageType.FrequencyBased);
        } else if (this.props.dosage instanceof ScheduledDosage) {
            this.setDosageType(DosageType.Scheduled);
        } else if (this.props.dosage instanceof TextualDosage) {
            this.setDosageType(DosageType.Textual);
        }
    }

    private renderFrequencyBasedEditor() {
        const typedDosage = this.props.dosage as FrequencyBasedDosage;
        return (
            <>
                <Ui.Flex.Item xs={2}>
                    <Ui.NumberBox
                        value={typedDosage.intervalMultiplier}
                        onChange={typedDosage.setIntervalMultiplier}
                        integerOnly
                        label={StaticHunSocialSecurityMedicationRequestResources.DosageEditor.FrequencyBased.IntervalMultiplier}
                        nullValue={1}
                        propertyIdentifier="Dosage.IntervalMultiplier"
                        automationId="intervalMultiplierNumberBox"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={3}>
                    <Ui.UniversalEnumSelector
                        enumName={"DosageInterval"}
                        enumOrigin="server"
                        value={typedDosage.interval}
                        onChange={typedDosage.setInterval}
                        enumType={DosageInterval}
                        displayMode="selectBox"
                        label={StaticHunSocialSecurityMedicationRequestResources.DosageEditor.FrequencyBased.DosageInterval}
                        valueOnClear={DosageInterval.Daily}
                        automationId="intervalSelector"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item grow>
                    <Ui.NumberBox
                        value={typedDosage.frequency}
                        onChange={typedDosage.setFrequency}
                        integerOnly
                        label={StaticHunSocialSecurityMedicationRequestResources.DosageEditor.FrequencyBased.Frequency}
                        nullValue={0}
                        propertyIdentifier="Dosage.Frequency"
                        automationId="frequencyNumberBox"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item style={{ alignSelf: "flex-end" }}>
                    <div style={{ paddingBottom: 4 }}>X</div>
                </Ui.Flex.Item>
                <Ui.Flex.Item grow>
                    <Ui.NumberBox
                        value={typedDosage.amount}
                        onChange={typedDosage.setAmount}
                        label={StaticHunSocialSecurityMedicationRequestResources.DosageEditor.FrequencyBased.Amount}
                        nullValue={0}
                        propertyIdentifier="Dosage.Amount"
                        automationId="amountNumberBox"
                    />
                </Ui.Flex.Item>
            </>
        );
    }

    private renderScheduledEditor() {
        const typedDosage = this.props.dosage as ScheduledDosage;
        return (
            <>
                <Ui.Flex.Item xs={3}>
                    <Ui.NumberBox value={typedDosage.morningAmount} onChange={typedDosage.setMorningAmount}
                        label={StaticHunSocialSecurityMedicationRequestResources.DosageEditor.Scheduled.MorningAmount} nullValue={0}
                        automationId="morningAmountNumberBox"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={3}>
                    <Ui.NumberBox value={typedDosage.noonAmount} onChange={typedDosage.setNoonAmount}
                        label={StaticHunSocialSecurityMedicationRequestResources.DosageEditor.Scheduled.NoonAmount} nullValue={0}
                        automationId="noonAmountNumberBox"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={3}>
                    <Ui.NumberBox value={typedDosage.eveningAmount} onChange={typedDosage.setEveningAmount}
                        label={StaticHunSocialSecurityMedicationRequestResources.DosageEditor.Scheduled.EveningAmount} nullValue={0}
                        automationId="eveningAmountNumberBox"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={3}>
                    <Ui.NumberBox value={typedDosage.beforeSleepAmount} onChange={typedDosage.setBeforeSleepAmount}
                        label={StaticHunSocialSecurityMedicationRequestResources.DosageEditor.Scheduled.BeforeSleepAmount} nullValue={0}
                        automationId="beforeSleepAmountNumberBox"
                    />
                </Ui.Flex.Item>
            </>
        );
    }

    private renderTextualEditor() {
        const typedDosage = this.props.dosage as TextualDosage;
        return (
            <>
                <Ui.Flex.Item xs={9}>
                    <Ui.TextBox
                        value={typedDosage.dosageInstructions}
                        onChange={typedDosage.setDosageInstruction}
                        label={StaticHunSocialSecurityMedicationRequestResources.DosageEditor.TextualDosage.DosageInstruction}
                        propertyIdentifier="Dosage.DosageInstructions"
                        automationId="dosageInstructionsTextBox"
                        showMultipleValidationProblems
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={3}>
                    <Ui.NumberBox
                        value={typedDosage.dailyAmount}
                        onChange={typedDosage.setDailyAmount}
                        label={StaticHunSocialSecurityMedicationRequestResources.DosageEditor.TextualDosage.DailyAmount}
                        nullValue={0}
                        propertyIdentifier="Dosage.DailyAmount"
                        automationId="dailyAmountNumberBox"
                    />
                </Ui.Flex.Item>
            </>
        );
    }

    public render() {
        return (
            <>
                <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        <Ui.UniversalEnumSelector
                            enumName={"DosageType"}
                            enumOrigin="server"
                            value={this.dosageType}
                            onChange={this.setDosageTypeAndDosage}
                            enumType={DosageType}
                            displayMode="groupedRadioButtons"
                            label={StaticHunSocialSecurityMedicationRequestResources.DosageEditor.MainLabel}
                            automationId="dosageTypeSelector"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex noWrap>
                    {this.props.dosage instanceof FrequencyBasedDosage && this.renderFrequencyBasedEditor()}
                    {this.props.dosage instanceof ScheduledDosage && this.renderScheduledEditor()}
                    {this.props.dosage instanceof TextualDosage && this.renderTextualEditor()}
                </Ui.Flex>
                {this.nonPropertyValidationProblems && <Ui.Flex>
                    <Ui.FieldValidationResult problems={this.nonPropertyValidationProblems} />
                </Ui.Flex>}
                <State.Reaction inspector={this.inspectDosage} reaction={this.fireValidationChanged} />
            </>
        );
    }
}

export default connect(DosageEditor,
    new ValidationBoundaryAdapter()
);