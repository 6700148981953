import OrganizationUnitTreeNode from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnitTreeNode";
import { isNullOrUndefined, isNullOrWhiteSpace } from "@Toolkit/CommonWeb/NullCheckHelpers";

export function filterTree(text: string, tree: OrganizationUnitTreeNode[]) {
    if (isNullOrUndefined(text) || isNullOrWhiteSpace(text)) {
        return tree;
    } else {
        const filteredStructure: OrganizationUnitTreeNode[] = [];
        for (const currentStructure of tree) {
            const result = filterTreeNode(text, currentStructure, false);

            if (result !== null) {
                filteredStructure.push(result);
            }
        }
        return filteredStructure;
    }
}

export function checkMatchForNode(text: string, node: OrganizationUnitTreeNode) {
    if (!node || !node.code || !node.name) {
        return false;
    }
    return node.label.toLowerCase().includes(text.toLocaleLowerCase());
}

const filterTreeNode = (text: string, currentNode: OrganizationUnitTreeNode, keepElement: boolean) => {
    const newNode = OrganizationUnitTreeNode.create(currentNode);

    const filteredChildren: OrganizationUnitTreeNode[] = [];

    const elementShouldBeKept = checkMatchForNode(text, newNode) || keepElement;

    if (newNode.children) {
        for (const child of newNode.children) {
            const filteredChild = filterTreeNode(text, child, elementShouldBeKept);

            if (filteredChild !== null) {
                filteredChildren.push(filteredChild);
            }
        }
    }

    if (checkMatchForNode(text, newNode) || filteredChildren.length > 0 || elementShouldBeKept) {
        newNode.children = filteredChildren.length > 0 ? filteredChildren : null;
        return newNode;
    } else {
        return null;
    }
};