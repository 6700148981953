import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class IssueAttachment {
    @State.observable public fileName: string;
    @State.observable public content: string;
    
    constructor(
        fileName: string,
        content: string
    ) {
        this.fileName = fileName;
        this.content = content;
    }

    @State.action.bound
    public setFileName(fileName: string) {
        this.fileName = fileName;
    }

    @State.action.bound
    public setContent(content: string) {
        this.content = content;
    }
}