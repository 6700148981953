import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class CheckBoxStore {
    @State.observable public hover = false;

    @State.action.bound
    public setHover(newValue: boolean) {
        this.hover = newValue;
    }

    @State.computed public get showValidationMessage() {
        return this.hover;
    }
}