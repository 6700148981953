exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RequestedServiceStateBadge_container_F73KG {\n  display: inline-block;\n  position: relative;\n}\n.RequestedServiceStateBadge_container_F73KG svg {\n  position: absolute;\n  top: 0;\n  left: 0;\n  stroke: #ce5374;\n  stroke-width: 2px;\n}\n.RequestedServiceStateBadge_badge_2N15F {\n  display: inline-block;\n  width: 22px;\n  height: 22px;\n  padding: 0;\n  line-height: 1.5;\n}\n.RequestedServiceStateBadge_badge_2N15F.RequestedServiceStateBadge_state-created_30KNK {\n  background-color: #E8E8E8;\n  color: #4F5B72;\n  border: 2px dotted #8695a7;\n  line-height: 1.4;\n}\n.RequestedServiceStateBadge_badge_2N15F.RequestedServiceStateBadge_state-requested_2MP14 {\n  background-color: #E8E8E8;\n  color: #4F5B72;\n}\n.RequestedServiceStateBadge_badge_2N15F.RequestedServiceStateBadge_state-registered-as-received_GRA0_ {\n  background-color: #82d4ed;\n  color: #fff;\n}\n.RequestedServiceStateBadge_badge_2N15F.RequestedServiceStateBadge_state-activity-completed_wazxH {\n  background-color: #ddc00d;\n  color: #fff;\n}\n.RequestedServiceStateBadge_badge_2N15F.RequestedServiceStateBadge_state-non-validated-completed_x0Dcw {\n  background-color: #fe8443;\n  color: #fff;\n}\n.RequestedServiceStateBadge_badge_2N15F.RequestedServiceStateBadge_state-validated-completed_3g6DX {\n  background-color: #21c313;\n  color: #fff;\n}\n.RequestedServiceStateBadge_badge_2N15F.RequestedServiceStateBadge_state-deleted_uTHAC {\n  background-color: #ce5374;\n  color: #fff;\n}\n", ""]);

// exports
exports.locals = {
	"container": "RequestedServiceStateBadge_container_F73KG",
	"badge": "RequestedServiceStateBadge_badge_2N15F",
	"state-created": "RequestedServiceStateBadge_state-created_30KNK",
	"stateCreated": "RequestedServiceStateBadge_state-created_30KNK",
	"state-requested": "RequestedServiceStateBadge_state-requested_2MP14",
	"stateRequested": "RequestedServiceStateBadge_state-requested_2MP14",
	"state-registered-as-received": "RequestedServiceStateBadge_state-registered-as-received_GRA0_",
	"stateRegisteredAsReceived": "RequestedServiceStateBadge_state-registered-as-received_GRA0_",
	"state-activity-completed": "RequestedServiceStateBadge_state-activity-completed_wazxH",
	"stateActivityCompleted": "RequestedServiceStateBadge_state-activity-completed_wazxH",
	"state-non-validated-completed": "RequestedServiceStateBadge_state-non-validated-completed_x0Dcw",
	"stateNonValidatedCompleted": "RequestedServiceStateBadge_state-non-validated-completed_x0Dcw",
	"state-validated-completed": "RequestedServiceStateBadge_state-validated-completed_3g6DX",
	"stateValidatedCompleted": "RequestedServiceStateBadge_state-validated-completed_3g6DX",
	"state-deleted": "RequestedServiceStateBadge_state-deleted_uTHAC",
	"stateDeleted": "RequestedServiceStateBadge_state-deleted_uTHAC"
};