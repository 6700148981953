export default class AsyncMessageQueue<TMessage, TResult = void> {
    private readonly _queue: TMessage[] = [];
    private workerPromise: Promise<void> = null;
    private results = new Map<TMessage, TResult>();
    private isRunning: boolean = false;

    constructor(
        private readonly messageHandler: (message: TMessage) => Promise<TResult>,
        private readonly name: string = ""
    ) { }

    public enqueue(message: TMessage) {
        this._queue.unshift(message);
    }

    public async enqueueAndProcessAsync(message: TMessage) {
        this._queue.unshift(message);
        await this.processAsync();

        const result = this.results.get(message);
        this.results.delete(message);
        return result;
    }

    public processAsync() {
        if (this.isRunning === false) {
            this.isRunning = true;
            this.workerPromise = new Promise((resolve: () => void, reject: (e: Error) => void) => {
                this._processMessageAsync().then(() => resolve()).catch(err => reject(err));
            });
        }

        return this.workerPromise;
    }

    private async _processMessageAsync() {
        try {
            // eslint-disable-next-line no-constant-condition
            while (true) {
                const currentMessage = this._queue.shift();
                if (!currentMessage) {
                    break;
                }
                const result = await this.messageHandler(currentMessage);
                this.results.set(currentMessage, result);
            }

        } finally {
            this.isRunning = false;
        }
    }
}
