import React, { useCallback } from "react";
import { isNullOrUndefined, isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareLocationColumn2Filter from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/CareLocationColumn2/CareLocationColumn2Filter";
import { ExternalLocationVersionSelector } from "@Primitives/ExternalLocationId.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { iconNameType } from "@CommonControls/Icon";
import * as Ui from "@CommonControls";
import { getIconNameByHealthcareProfessions } from "@HisPlatform/BoundedContexts/Organization/Components/Helpers/IconTypeNameHelper";

interface ICareLocationColumn2Dependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface ICareLocationColumn2Props extends IDataGridColumnBaseProps {
    _dependencies?: ICareLocationColumn2Dependencies;
    externalLocationVersionSelectors: ExternalLocationVersionSelector[];
}


const CareLocationColumn2: React.FC<ICareLocationColumn2Props> = (props) => {

    const { children, ...columnProps } = props;
    const organizationReferenceDataStore = props._dependencies.organizationReferenceDataStore;

    const valueRenderer = useCallback((value: any) => {
        if (!isNullOrUndefined(value)) {
            if (value instanceof InternalCareLocation) {
                const poc = organizationReferenceDataStore.allPointsOfCareMap.get(value.pointOfCareId);
                if (!isNullOrUndefined(poc)) {
                    const iconName: iconNameType = getIconNameByHealthcareProfessions(poc.healthcareProfessionIds.map(props._dependencies.organizationReferenceDataStore.healthCareProfession.get));

                    return (
                        <>
                            {iconName &&
                                <Ui.Icon
                                    iconName={iconName}
                                    style={{ marginRight: 5 }}
                                />}
                            {poc.shorthand}
                        </>
                    );
                }
            } else if (value instanceof ExternalCareLocation) {
                const ext = organizationReferenceDataStore.externalLocationStore.get(value.externalLocationSelector);
                if (!isNullOrUndefined(ext)) {
                    return !isNullOrEmptyString(ext.shortName) ? ext.shortName : (ext.name ?? "");
                }
            } else {
                throw new Error("Unknown CareLocation type");
            }
        }
        return "";
    }, []);

    const hintRenderer = useCallback((value: any) => {
        if (!isNullOrUndefined(value)) {
            if (value instanceof InternalCareLocation) {
                const poc = organizationReferenceDataStore.allPointsOfCareMap.get(value.pointOfCareId);
                if (!isNullOrUndefined(poc)) {
                    return !isNullOrEmptyString(poc.name) ? poc.name : (poc.shorthand ?? "");
                }
            } else if (value instanceof ExternalCareLocation) {
                const ext = organizationReferenceDataStore.externalLocationStore.get(value.externalLocationSelector);
                if (!isNullOrUndefined(ext)) {
                    return !isNullOrEmptyString(ext.name) ? ext.name : (ext.shortName ?? "");
                }
            }
        }
        return "";
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps) => (
        <CareLocationColumn2Filter
            {...filterProps}
            externalLocationVersionSelectors={props.externalLocationVersionSelectors}
            hoverOnlyIndicatorIcons
        />
    ), [props.externalLocationVersionSelectors]);

    return (
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            onRenderHintValue={hintRenderer}
        />
    );
};

export default connect(
    CareLocationColumn2,
    new DependencyAdapter<ICareLocationColumn2Props, ICareLocationColumn2Dependencies>(c => ({
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore")
    }))
);
