import React, {useCallback} from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import {arrayIsNullOrEmpty, isNullOrUndefined, isNullOrWhiteSpace} from "@Toolkit/CommonWeb/NullCheckHelpers";
import {IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer} from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import PractitionerId from "@Primitives/PractitionerId.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import DoctorCodeSelector from "@HisPlatformControls/DoctorCodeSelector/DoctorCodeSelector";
import _ from "@HisPlatform/Common/Lodash";
import {joinReactNodes} from "@Toolkit/CommonWeb/JoinReactNodes";
import formatCodeName from "@HisPlatformControls/CodeNameFormatter/CodeNameFormatter";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";

interface IPractitionerColumnDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    localizationService: ILocalizationService;
}

export type PractitionerColumnDisplayMode =  "code" | "name" | "code-name";

interface IPractitionerColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IPractitionerColumnDependencies;
    displayMode?: PractitionerColumnDisplayMode;
    isCodeBold?: boolean;
    fallbackValueGetter?: string;
    fallbackValueRenderer?: (row: any, displayMode: PractitionerColumnDisplayMode, isCodeBold: boolean) => React.ReactNode;
}


const PractitionerColumn: React.SFC<IPractitionerColumnProps> = (props: IPractitionerColumnProps) => {

    const valueRenderer = useCallback((value: PractitionerId | PractitionerId[], row: any) => {
        const noItem = isNullOrUndefined(value);
        if (!noItem) {

            let items: PractitionerStore[];
            if (value instanceof PractitionerId) {
                const practitioner = props._dependencies.organizationReferenceDataStore.practitionerMap.get(value);
                items = practitioner ? [practitioner] : [];
            } else if (value instanceof Array) {
                items = value.map(i => props._dependencies.organizationReferenceDataStore.practitionerMap.get(i)).filter(Boolean);
            }

            if (arrayIsNullOrEmpty(items)) {
                return null;
            }

            const names = items.map(i => props._dependencies.localizationService.localizePersonName(i.baseData.name)).join(", ");
            const codes = joinReactNodes(<>{", "}<br /></>, items.map(i => props.isCodeBold ? <b>{i.code}</b> : i.code));

            const defaults = joinReactNodes(<>{", "}<br /></>, items.map(i => {
                const name = isNullOrWhiteSpace(i.baseData.name?.familyName) ? i.displayName?.trim() : props._dependencies.localizationService.localizePersonName(i.baseData.name);
                return formatCodeName(i.code, name, props.isCodeBold);
            }));

            switch (props.displayMode) {
                case "name":
                    return names;
                case "code":
                    return codes;
                case "code-name":
                default:
                    return defaults;
            }
        } else if (noItem && props.fallbackValueGetter) {
            return _.get(row, props.fallbackValueGetter);
        } else if (noItem && props.fallbackValueRenderer) {
            return props.fallbackValueRenderer(row, props.displayMode, props.isCodeBold);
        } else {
            return null;
        }
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<PractitionerId>) => {
        return <DoctorCodeSelector {...filterProps} hoverOnlyIndicatorIcons isExternal={false} />;
    }, []);

    const renderHintValue = (value: any) => {
        const noItem = isNullOrUndefined(value);
        if (!noItem) {

            let items: PractitionerStore[];
            if (value instanceof PractitionerId) {
                const practitioner = props._dependencies.organizationReferenceDataStore.practitionerMap.get(value);
                items = practitioner ? [practitioner] : [];
            } else if (value instanceof Array) {
                items = value.map(i => props._dependencies.organizationReferenceDataStore.practitionerMap.get(i)).filter(Boolean);
            }

            if (arrayIsNullOrEmpty(items)) {
                return null;
            }

            const names = items.map(i => props._dependencies.localizationService.localizePersonName(i.baseData.name)).join(", ");
            const codes = items.map(i => i.code).join(", ");
            const defaults = items.map(i => {
                const name = props._dependencies.localizationService.localizePersonName(i.baseData.name);
                if (isNullOrUndefined(i.code) || i.code === "") {
                    return `${name}`;
                }
                return `${i.code} - ${name}`;
            }).join(", ");

            switch (props.displayMode) {
                case "name":
                    return names;
                case "code":
                    return codes;
                case "code-name":
                default:
                    return defaults;
            }
        } else {
            return null;
        }
    };
    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
            onRenderHintValue={renderHintValue}
        />
    );
};

const filterValueSerializer: IDataGridFilterValueSerializer<PractitionerId> = {
    deserialize: (value: string) => {
        if (!value) {
            return null;
        }
        return new PractitionerId(value);
    },
    serialize: (value: PractitionerId) => {
        return value == null ? null : (value && value.value);
    }
};

PractitionerColumn.defaultProps = {
    displayMode: "code-name"
};

export default connect(
    PractitionerColumn,
    new DependencyAdapter<IPractitionerColumnProps, IPractitionerColumnDependencies>(c => ({
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService"),
    }))
);
