import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import {dispatchAsyncErrors} from "@Toolkit/CommonWeb/AsyncHelpers";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import {RowId} from "@CommonControls/DataGrid/IDataGridProps";
import {IModalService} from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import AppointmentScheduleDefinitionBaseDataModalParams , {IAppointmentScheduleDefinitionBaseDataModalResult} from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/CreateNewItemModal/AppointmentScheduleDefinitionBaseDataModalParams";
import CreateItemClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/CreateItemClientSideAction";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import CreateAppointmentScheduleDefinitionWorkListArgument from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/CreateNewItemModal/CreateAppointmentScheduleDefinitionWorkListArgument";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";

interface IAppointmentScheduleDefinitionsPanelPanelDependencies {
    worklistApiAdapter: WorklistApiAdapter;
}

interface IAppointmentScheduleDefinitionsPanelProps {
    _dependencies?: IAppointmentScheduleDefinitionsPanelPanelDependencies;
    _modalService?: IModalService;
    onBack: () => void;
    useCaseState: INDataUseCaseState;
    selectedRowId: string;
    onChange: (selectedRowId: string, useCaseState: INDataUseCaseState) => void;
    onCloseDetail: () => void;
}

@State.observer
class AppointmentScheduleDefinitionsPanel extends React.Component<IAppointmentScheduleDefinitionsPanelProps> {
    @State.observable.ref private worklistDefinition: IWorklistDefinition = null;

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);
    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.bound
    private async loadAsync() {
        const resp = await this.props._dependencies.worklistApiAdapter.getAppointmentScheduleDefinitionBoundWorklistDefinition();
        State.runInAction(() => {
            this.worklistDefinition = resp.value;
        });
    }

    @State.bound
    private async onPerformActionAsync(clientSideAction: ClientSideActionDto, rowId: RowId) {
        if (clientSideAction instanceof CreateItemClientSideAction && clientSideAction.worklistType === "AppointmentScheduleDefinitionBased") {
            const result = await this.props._modalService.showDialogAsync<IAppointmentScheduleDefinitionBaseDataModalResult>(new AppointmentScheduleDefinitionBaseDataModalParams());
            if (result?.result) {
                return new CreateAppointmentScheduleDefinitionWorkListArgument(result?.result?.name, result?.result?.description, result?.result?.selectedPlanningPeriod?.interval);
            }
        }
        return null;
    }

    @State.bound
    private onDeleteAsync() {
        this.props.onCloseDetail();
        return Promise.resolve();
    }
    
    @State.bound
    private getPanelProps() {
        return {
            onDeleteAsync: this.onDeleteAsync,
            onCloseDetail: this.props.onCloseDetail
        };
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.BaseData.PanelName} />;
        }

        return (
            <SingleLayout>
                <NDataPanel
                    onGetPanelProps={this.getPanelProps}
                    definition={this.worklistDefinition}
                    hasRefreshButton
                    onPerformClientSideActionAsync={this.onPerformActionAsync}
                    onChange={this.props.onChange}
                    useCaseState={this.props.useCaseState}
                    selectedRowId={this.props.selectedRowId}
                    iconName="calendar_edit"
                    disableDetailStrictMode
                    hasTabs
                />
            </SingleLayout>
        );
    }
}

export default connect(
    AppointmentScheduleDefinitionsPanel,
    new DependencyAdapter<IAppointmentScheduleDefinitionsPanelProps, IAppointmentScheduleDefinitionsPanelPanelDependencies>(c => ({
        worklistApiAdapter: c.resolve("WorklistApiAdapter")
    })),
    new HisModalServiceAdapter()
);
