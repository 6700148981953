import React from "react";
import { iconNameType } from "@CommonControls/Icon";
import { ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import Styles from "./BadgeWithIcon.less";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";

export type badgeVisualStyleType = "blue" | "red" | "gray";

export interface IBadgeWithIconProps extends ICommonControlProps {
    badgeVisualStyleType: badgeVisualStyleType;
    iconName: iconNameType;
    text: string;
}


const BadgeWithIcon: React.FC<IBadgeWithIconProps> = (props) => {
    const classes = new CompositeClassName(Styles.container);
    let iconFillTypeClassName;

    switch (props.badgeVisualStyleType) {
        case "blue":
            classes.add(Styles.blue);
            iconFillTypeClassName = Styles.blueFill;
            break;
        case "red":
            classes.add(Styles.red);
            iconFillTypeClassName = Styles.redFill;
            break;
        case "gray":
            classes.add(Styles.gray);
            iconFillTypeClassName = Styles.grayFill;
            break;
    }

    return (
        <div className={classes.classNames}>
            <SpanWithIcon iconName={props.iconName} iconFillTypeClassName={iconFillTypeClassName}>{props.text}</SpanWithIcon>
        </div>
    );
};

export default BadgeWithIcon;