import React from "react";
import { nullFunction, arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import _ from "@HisPlatform/Common/Lodash";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { inputChangeActionType } from "@CommonControls/SelectBox";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import { ModalSize } from "@CommonControls/Modal";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import ISelectBoxSectionItem from "@CommonControls/SelectBox/ISelectBoxSectionItem";
import EntityCollectionStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/Personalization/EntityCollectionStore";
import ISelectBoxSection from "@CommonControls/SelectBox/ISelectBoxSection";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import IEntityCollectionsService from "@HisPlatform/Services/Definition/EntityCollectionService/IEntityCollectionsService";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import UserId from "@Primitives/UserId.g";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { iconNameType } from "@CommonControls/Icon";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import UniversalCodeSelectorView from "@HisPlatformControls/UniversalCodeSelector/UniversalCodeSelectorView";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { IComplexSearchItemGroup } from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchPanel";
import IColumnDescriptor from "./ComplexSearch/IColumnDescriptor";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import EntityCollectionOwner from "@HisPlatform/BoundedContexts/Productivity/Api/Personalization/Enum/EntityCollectionOwner.g";

export interface IUniversalCodeSelectorController {
    onClose: () => void;
}

interface IUniversalCodeSelectorDependencies {
    entityCollectionsService: IEntityCollectionsService;
}

export interface IUniversalCodeSelectorProps<TItem, TComplexSearchItem> extends ICodeSelectorCommonProps<TItem> {
    _baseDependencies?: IUniversalCodeSelectorDependencies;

    // Behavior
    quickSearchDebounceTimeout?: number;
    quickSearchMinimumCharacters?: number;
    isCreatable?: boolean;
    isClearable?: boolean;
    addNewItem?: (text: string) => TItem;
    multiSelect?: boolean;
    hideAllgroup?: boolean;

    // Extendability
    getDisplayValueAsync: (value: TItem) => Promise<string> | Promise<React.ReactElement>;
    getTextValueAsync?: (value: TItem) => Promise<string>;
    onQuickSearchAsync: (quickSearchText: string, filters?: FilterBase[]) => Promise<TItem[]>;
    filters?: FilterBase[];
    onRawValueChanged?: (newValue: string) => void;
    complexSearchTitle?: React.ReactNode;
    complexSearchModalSize?: ModalSize;
    defaultSuggestedItems?: TItem[];
    getItemHashCode?: (value: any) => string;
    getCustomValue?: (value: any) => React.ReactNode;
    getOptionValue?: (value: any) => string;

    controller?: IUniversalCodeSelectorController;

    // Menu Sections
    showMenuSections?: boolean;
    entityName?: string;
    ownerType?: EntityCollectionOwner;
    ownerId?: PointOfCareId | UserId;
    currentPointOfCareId?: PointOfCareId;
    collectionsMaxCount?: number;
    getEntityDisplayValue?: (value: TItem) => string | React.ReactElement;
    idMatchesEntity?: (id: IStringEntityId, item: TItem) => boolean;
    getEntityById?: (id: IStringEntityId) => TItem;
    getEntitiesById?: (id: IStringEntityId[]) => TItem[];
    getEntityId?: (value: TItem) => IStringEntityId;
    dropDownNewItemText?: string;

    // complex search
    hasComplexSearch?: boolean;
    complexSearchLoadAsync?: (filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: TComplexSearchItem[], selectedGroup: IComplexSearchItemGroup, filters?: FilterBase[]) => Promise<IPagedItems<TComplexSearchItem>>;
    complexSearchItemGroups?: IComplexSearchItemGroup[];
    selectedItems?: IObservableArray<TComplexSearchItem>;
    complexSearchModalTitle?: string;
    codeGetter?: string;
    nameGetter?: string;
    getNameValueString?: (value: any) => string;
    customNameRender?: (value: any, row: TComplexSearchItem) => React.ReactNode;
    getComplexSearchEntitiesByIds?: (id: TItem[]) => TComplexSearchItem[];
    onComplexSearchSingleSelect?: (value: TComplexSearchItem) => void;
    onComplexSearchMultiSelect?: (value: TComplexSearchItem[]) => void;
    getIdOfComplexSearchItem?: (value: TComplexSearchItem) => IStringEntityId;
    createNewItemAsync?: (code: string, name: string) => Promise<{ item: TComplexSearchItem, validationResults: IClientValidationResult[] }>;
    onValidateAllAsync?: (code: string, name: string) => Promise<IClientValidationResult[]>;
    createNewItemButtonText?: string;
    createNewItemCodePlaceHolder?: string;
    createNewItemNamePlaceHolder?: string;
    createNewItemCodePropertyIdentifier?: string;
    createNewItemNamePropertyIdentifier?: string;
    createNewItemValidationEntityName?: string;
    selectableItemsSearchPlaceHolder?: string;
    selectedItemsSearchPlaceHolder?: string;
    columnDescriptors?: IColumnDescriptor[];
    newItemPermissionIdentifier?: string;
    customContentComponentType?: React.ComponentType<any>;
    customContentComponentProps?: any;
}

@State.observer
class UniversalCodeSelector<TItem, TComplexSearchItem> extends React.Component<IUniversalCodeSelectorProps<TItem, TComplexSearchItem>> {

    public static defaultProps: Partial<IUniversalCodeSelectorProps<any, any>> = {
        onChange: nullFunction,
        quickSearchDebounceTimeout: 500,
        quickSearchMinimumCharacters: 3,
        capitalize: false,
        maxResultCount: 10,
        collectionsMaxCount: 5,
        idMatchesEntity: (id, item) => id?.value && item?.value && wrappedValuesAreEquals(id as { value: string }, item as { value: string }),
        getEntityId: (item) => item.id,
        getIdOfComplexSearchItem: (item) => item.id,
        getEntitiesById: (item) => item,
        getEntityById: (item) => item
    };

    @State.observable.ref private quickSearchText: string = "";
    @State.observable.ref private _quickSearchResults: Array<ISelectBoxItem<TItem>> = null;
    @State.observable.ref private _defaultSuggestedItems: Array<ISelectBoxItem<TItem>> = null;
    @State.observable.ref private isComplexSearchOpen: boolean = false;

    @State.observable.ref private isLoading: boolean = false;
    @State.observable.ref private menuIsOpen: boolean = false;
    @State.observable.ref private showNewItem: boolean = false;

    @State.observable.ref private entityCollections: IObservableArray<EntityCollectionStore> = State.observable([]);
    @State.observable.ref private selectedItems: IObservableArray<TComplexSearchItem> = State.observable([]);

    private get entityCollectionService() {
        return this.props._baseDependencies.entityCollectionsService;
    }

    @State.computed
    private get quickSearchResults() {
        return isNullOrUndefined(this._defaultSuggestedItems)
            ? this._quickSearchResults
            : (this._quickSearchResults ? this._defaultSuggestedItems.concat(this._quickSearchResults) : this._defaultSuggestedItems);
    }

    @State.action.bound
    private setQuickSearchText(newValue: string, action: inputChangeActionType) {
        let newNewValue = newValue;
        if (this.props.capitalize) {
            newNewValue = _.capitalize(newValue);
        }

        if (this.props.onRawValueChanged && action === "input-change") {
            this.props.onRawValueChanged(newNewValue);
        }

        if (action === "set-value") {
            this.quickSearchText = "";
        } else {
            if (this.quickSearchText !== newNewValue) {
                this.quickSearchText = newNewValue;
                if (this.quickSearchText === "") {
                    this.setQuickSearchResults([]);
                }
                this.doQuickSearchDebounced();
            }
        }
    }

    private doQuickSearchDebounced = _.debounce(() => {
        if (this.quickSearchText && this.quickSearchText.length >= this.props.quickSearchMinimumCharacters) {
            dispatchAsyncErrors(this.doQuickSearchAsync(), this);
        }
    }, this.props.quickSearchDebounceTimeout);

    private async doQuickSearchAsync() {
        if (!this.props.onQuickSearchAsync) {
            return;
        }

        const results = await this.props.onQuickSearchAsync(this.quickSearchText);

        if (arrayIsNullOrEmpty(results)) {
            this.setQuickSearchResults(null);
            return;
        }

        const items: Array<ISelectBoxItem<TItem>> = [];

        for (const result of results) {
            const item = await this.createQuickSearchResultItemAsync(result);
            items.push(item);
        }

        this.setQuickSearchResults(items);
    }

    private async createQuickSearchResultItemAsync(item: TItem) {
        const textValue = this.props.getTextValueAsync ? await this.props.getTextValueAsync(item) : await this.props.getDisplayValueAsync(item);
        const displayValue = await this.props.getDisplayValueAsync(item);
        return {
            text: textValue,
            value: item,
            longDisplayValue: displayValue
        } as ISelectBoxItem<TItem>;
    }

    private async setNewValueAsync(newValue: TItem | TItem[]) {
        if (!newValue) {
            State.runInAction(() => this._quickSearchResults = null);
            return;
        }

        if (Array.isArray(newValue)) {
            await this.setNewMultiValueAsync(newValue);
        } else {
            await this.setNewSingleValueAsync(newValue);
        }
    }

    @State.action
    private async setNewSingleValueAsync(newValue: TItem) {
        if (!newValue) {
            return;
        }

        this.isLoading = true;
        const searchResults = await this.getSearchResultsFromIdsAsync([newValue]);

        State.runInAction(() => {
            this._quickSearchResults = searchResults;
            this.isLoading = false;
        });
    }

    @State.action
    private async setNewMultiValueAsync(newValues: TItem[]) {
        if (!newValues) {
            return;
        }

        this.isLoading = true;

        const searchResults = await this.getSearchResultsFromIdsAsync(newValues);

        State.runInAction(() => {
            this._quickSearchResults = searchResults;
            this.isLoading = false;
        });
    }

    private async getSearchResultsFromIdsAsync(newValues: TItem[]) {
        const results = await Promise.all(
            newValues.map(async value => ({
                value,
                textValue: this.props.getTextValueAsync ? await this.props.getTextValueAsync(value) : await this.props.getDisplayValueAsync(value),
                displayValue: await this.props.getDisplayValueAsync(value)
            }))
        );

        return results.map(result => ({
            text: result.textValue,
            value: result.value,
            longDisplayValue: result.displayValue
        } as ISelectBoxItem<TItem>));
    }

    @State.action.bound
    private setQuickSearchResults(items: Array<ISelectBoxItem<TItem>>) {
        if (this.props.multiSelect) {
            this.cleanupQuickSearchResults();
            if (!isNullOrUndefined(items)) {
                this._quickSearchResults =
                    _.uniqBy(this._quickSearchResults ? [...this._quickSearchResults, ...items] : items, i => i.text);
            }
        } else {
            this._quickSearchResults = items;
        }
    }

    @State.action.bound
    private cleanupQuickSearchResults() {
        this._quickSearchResults = this._quickSearchResults && this._quickSearchResults.filter(r => {
            if (this.props.multiSelect) {
                const multiValues = (this.props.value as TItem[]);
                return multiValues && multiValues.includes(r.value);
            }
            return r.value === this.props.value;
        });
    }

    @State.action.bound
    private keyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.ctrlKey && e.key === " ") {
            // ctrl + space
            this.openComplexSearch();
        }
    }

    @State.action.bound
    private openMenu() {
        this.menuIsOpen = true;
    }

    @State.action.bound
    private closeMenu() {
        this.menuIsOpen = false;
    }

    public componentDidMount() {

        if (this.props.controller) {
            this.props.controller.onClose = this.complexSearchClose;
        }
        dispatchAsyncErrors((async () => {
            if (this.props.defaultSuggestedItems) {
                const items: Array<ISelectBoxItem<TItem>> = [];
                for (const result of this.props.defaultSuggestedItems) {
                    const item = await this.createQuickSearchResultItemAsync(result);
                    items.push(item);
                }
                this.setDefaultSuggestions(items);
            }

            if (this.props.value) {
                await this.setNewValueAsync(this.props.value);
            }
            if (this.props.entityName && this.props.showMenuSections) {
                await this.loadEntityCollectionsAsync();
            }
        })(), this);
    }

    @State.action
    private setDefaultSuggestions(items: Array<ISelectBoxItem<TItem>>) {
        this._defaultSuggestedItems = items;
    }

    public componentDidUpdate(prevProps: IUniversalCodeSelectorProps<TItem, TComplexSearchItem>) {
        if (prevProps.value !== this.props.value) {
            dispatchAsyncErrors(this.setNewValueAsync(this.props.value), this);
        }
        if (prevProps.controller !== this.props.controller) {
            prevProps.controller.onClose = null;
            this.props.controller.onClose = this.complexSearchClose;
        }

        if (((!prevProps.entityName || !prevProps.showMenuSections) && this.props.entityName && this.props.showMenuSections) ||
            (!prevProps.ownerId && this.props.ownerId) || (prevProps.ownerId && this.props.ownerId && !wrappedValuesAreEquals(prevProps.ownerId, this.props.ownerId))) {
            dispatchAsyncErrors(this.loadEntityCollectionsAsync(), this);
        }
    }

    @State.action.bound
    private addNewItem(text: string) {
        const value = this.props.addNewItem(text);
        this._quickSearchResults = [
            ...this._quickSearchResults || [],
            { text, value }
        ];
        if (!this.props.multiSelect) {
            this.props.onChange(value);
        }
    }

    @State.bound
    private async loadEntityCollectionsAsync() {
        const [collections, mostUsed] = await Promise.all([
            this.entityCollectionService.getEntityCollectionsAsync(this.props.entityName, this.props.ownerId, this.props.ownerType),
            this.entityCollectionService.getMostUsedEntityIdsAsync(this.props.entityName, this.props.currentPointOfCareId, this.props.collectionsMaxCount)
        ]);
        const result = collections;
        if (mostUsed.length) {
            const mostUsedCollection = new EntityCollectionStore(null, mostUsed, "MostUsed", null, null);
            result.push(mostUsedCollection);
        }

        this.setEntityCollections(result);
    }

    @State.action.bound
    private setEntityCollections(stores: EntityCollectionStore[]) {
        this.entityCollections = State.observable(stores);
    }

    @State.action.bound
    private async updateEntityCollectionsAsync(item: IStringEntityId) {
        const listStores = this.entityCollections.filter(i => i.type === "List");
        const store = listStores.length && listStores[0] || new EntityCollectionStore(null, [], "List", null, null);

        const existingItem = store.idList.find(id => wrappedValuesAreEquals(id as any, item as any));

        if (existingItem) {
            _.remove(store.idList, existingItem);
        } else {
            store.idList.push(item);
        }
        const response = await this.entityCollectionService.setEntityCollectionAsync(store, this.props.entityName, this.props.ownerType, this.props.ownerId);
        if (!store.id) {
            State.runInAction(() => {
                this.entityCollections.push(response);
            });
        }
        this.setEntityCollections([].concat(this.entityCollections));
    }

    @State.bound
    private getOptionIconName(item: TItem): iconNameType {
        if (!this.props.showMenuSections) {
            return null;
        }

        const contains = this.entityCollections.some(collection => collection.type === "List" && collection.idList.some(id => this.props.idMatchesEntity(id, item)));
        return contains ? "star_filled" : "star_outline";
    }

    @State.bound
    private updateEntityCollectionBySelectBoxItem(item: ISelectBoxItem<TItem>) {
        this.updateEntityCollectionByEntity(item.value);
    }

    @State.bound
    private updateEntityCollectionByEntity(item: TItem) {
        dispatchAsyncErrors(this.updateEntityCollectionsAsync(this.props.getEntityId(item)), this);
    }

    @State.bound
    private updateEntityCollectionByComplexSearchEntity(item: TComplexSearchItem) {
        dispatchAsyncErrors(this.updateEntityCollectionsAsync(this.props.getIdOfComplexSearchItem(item)), this);
    }

    @State.computed
    private get getSelectBoxSections(): Array<ISelectBoxSection<TItem>> {
        if (!this.props.entityName || !this.props.showMenuSections) {
            return null;
        }

        if (!this.entityCollections?.length) {
            return [];
        }

        const result = [];
        const mostUsed = this.entityCollections.filter(item => item.type === "MostUsed");
        const favorites = this.entityCollections.filter(item => item.type === "List");
        const groups = this.entityCollections.filter(item => item.type === "Group");

        const mostUsedIds = _.flatten(mostUsed.map(item => item.idList));
        if (mostUsed.length) {
            result.push({
                title: StaticProductivityResources.EntityCollection.Type.MostUsed,
                items: mostUsedIds.filter(item => !this.props.multiSelect || !(this.props.value as TItem[])?.some(i => this.props.idMatchesEntity(item, i))).map(id => {
                    const value = this.props.getEntityById(id);
                    return {
                        value: value,
                        isMultiValue: false,
                        iconName: favorites.some(favorite => favorite.idList.some(i => wrappedValuesAreEquals(id as any, i as any))) ? "star_filled" : "star_outline",
                        onIconClick: () => this.updateEntityCollectionByEntity(value),
                        text: this.props.getEntityDisplayValue(value),
                        isSelected: false
                    } as ISelectBoxSectionItem<TItem>;
                })
            } as ISelectBoxSection<TItem>);
        }

        if (favorites.length) {
            result.push({
                title: StaticProductivityResources.EntityCollection.Type.Favorites,
                items: _.flatten(favorites.map(item => item.idList))
                    .filter(item => mostUsedIds.every(id => !wrappedValuesAreEquals(item as any, id as any)) &&
                        (!this.props.multiSelect || !(this.props.value as TItem[])?.some(i => this.props.idMatchesEntity(item, i))))
                    .map(id => {
                        const value = this.props.getEntityById(id);
                        return {
                            value: value,
                            iconName: "star_filled",
                            onIconClick: () => this.updateEntityCollectionByEntity(value),
                            text: this.props.getEntityDisplayValue(value),
                            isSelected: false
                        } as ISelectBoxSectionItem<TItem>;
                    })
            } as ISelectBoxSection<TItem>);
        }

        if (this.props.multiSelect && groups.length) {
            result.push({
                title: StaticProductivityResources.EntityCollection.Type.Groups,
                items: groups.map(group => {
                    const values = this.props.getEntitiesById(group.idList);
                    return {
                        value: values,
                        isMultiValue: true,
                        text: `${group.code} ${group.name}`,
                        isSelected: this.props.multiSelect && group.idList.every(id => (this.props.value as TItem[])?.some(item => this.props.idMatchesEntity(id, item)))
                    } as ISelectBoxSectionItem<TItem[]>;
                })
            } as ISelectBoxSection<TItem>);
        }

        return result;
    }

    // complex search

    @State.action.bound
    private openComplexSearch() {
        this.isComplexSearchOpen = true;
        if (this.props.multiSelect) {
            this.selectedItems = this.props.selectedItems;
            const listItems = State.observable(this.props.getComplexSearchEntitiesByIds((this.props.value as TItem[])));
            State.runInAction(() => {
                this.selectedItems = listItems;
            });
        }

        this.menuIsOpen = false;
    }

    @State.action.bound
    private openComplexSearchWithNewItems() {
        this.showNewItem = true;
        this.isComplexSearchOpen = true;
        this.menuIsOpen = false;
    }

    @State.action.bound
    private complexSearchClose() {
        this.isComplexSearchOpen = false;
        this.showNewItem = false;
        this.selectedItems = State.observable([]);
    }

    @State.action.bound
    private onComplexSearchValueChanged(item: TComplexSearchItem) {
        this.props.onComplexSearchSingleSelect(item);
        this.complexSearchClose();
    }

    @State.action.bound
    private onComplexSearchMultiValueChanged() {
        this.props.onComplexSearchMultiSelect(this.selectedItems);
        this.selectedItems = State.observable([]);
        this.complexSearchClose();
    }

    @State.action.bound
    private async onNewItemCreateAsync(code: string, name: string) {
        const newItem = await this.props.createNewItemAsync(code, name);
        if (!newItem.validationResults || newItem.validationResults?.some(item => item.problems.length && item.problems.some(problem => problem.severity === "error"))) {
            return newItem.validationResults;
        }
        if (this.props.multiSelect) {
            State.runInAction(() => {
                this.selectedItems.push(newItem.item);
            });
        } else {
            this.onComplexSearchValueChanged(newItem.item);
        }
        return newItem.validationResults || [];
    }

    @State.bound
    private isFavorite(value: any) {
        const id = this.props.getIdOfComplexSearchItem ? this.props.getIdOfComplexSearchItem(value) : value.id || value;

        if (!id) {
            console.warn(`€cannot find entityId of object = ${value}`);
            return false;
        }

        return this.entityCollections.some(item => item.type === "List" && item.idList.some(i => wrappedValuesAreEquals(i as { value: string }, id)));
    }

    @State.bound
    private getCustomValue(props: any) {
        if (this.props.getCustomValue) {
            return this.props.getCustomValue(props);
        }

        return props.data.longDisplayValue ? props.data.longDisplayValue : props.data.text;
    }

    @State.action.bound
    public onChange(value: TItem | TItem[]) {
        this.quickSearchText = "";
        this._quickSearchResults = null;

        this.props.onChange(value);
    }

    public render() {
        return (
            <ReadOnlyContext.Consumer>
                {(readOnly) =>
                    <State.Observer>
                        {() => <UniversalCodeSelectorView
                            automationId={this.props.automationId}
                            quickSearchText={this.quickSearchText}
                            onQuickSearchTextChanged={this.setQuickSearchText}
                            quickSearchResults={this.quickSearchResults}
                            value={this.props.value}
                            onChange={this.onChange}
                            getOptionText={this.props.getOptionValue}

                            isLoading={this.isLoading}
                            disabled={this.props.disabled}
                            label={this.props.label}
                            propertyIdentifier={this.props.propertyIdentifier}
                            readOnly={this.props.isReadOnly || readOnly}
                            tooltipContent={this.props.tooltipContent}
                            tooltipPosition={this.props.tooltipPosition}
                            className={this.props.className}
                            style={this.props.style}
                            id={this.props.id}
                            name={this.props.name}
                            tabIndex={this.props.tabIndex}
                            required={this.props.required}
                            menuIsOpen={this.menuIsOpen}
                            onMenuOpen={this.openMenu}
                            onMenuClose={this.closeMenu}
                            onKeyDown={this.keyDown}
                            onBlur={this.cleanupQuickSearchResults}
                            isCreatable={this.props.isCreatable}
                            addNewItem={this.addNewItem}
                            isClearable={this.props.isClearable}
                            complexSearchModalSize={this.props.complexSearchModalSize}
                            multiSelect={this.props.multiSelect}
                            infoLabel={this.props.infoLabel}
                            getItemHashCode={this.props.getItemHashCode}
                            customValueRenderer={this.getCustomValue}

                            menuSections={this.getSelectBoxSections}
                            getOptionIconName={this.getOptionIconName}
                            onOptionIconClick={this.updateEntityCollectionBySelectBoxItem}

                            hasComplexSearch={this.props.hasComplexSearch}
                            complexSearchLoadAsync={this.props.complexSearchLoadAsync}
                            selectedItems={this.selectedItems}
                            complexSearchOpen={this.isComplexSearchOpen}
                            onComplexSearchOpen={this.props.hasComplexSearch && this.openComplexSearch}
                            onComplexSearchClose={this.props.hasComplexSearch && this.complexSearchClose}
                            singleSelect={this.onComplexSearchValueChanged}
                            codeGetter={this.props.codeGetter}
                            nameGetter={this.props.nameGetter}
                            getNameValueString={this.props.getNameValueString}
                            customNameRender={this.props.customNameRender}
                            showEntityCollections={!!this.props.entityName && this.props.showMenuSections}
                            isMultiSelect={this.props.multiSelect}
                            entityCollections={this.props.showMenuSections ? this.entityCollections : []}
                            complexSearchTitle={this.props.complexSearchModalTitle}
                            isFavorite={this.isFavorite}
                            setFavorite={this.updateEntityCollectionByComplexSearchEntity}
                            getEntitiesByIds={this.props.getComplexSearchEntitiesByIds}
                            onMultiSelectSave={this.onComplexSearchMultiValueChanged}
                            createNewItemAsync={this.props.createNewItemAsync && this.onNewItemCreateAsync}
                            onValidateAllAsync={this.props.onValidateAllAsync}
                            createNewItemButtonText={this.props.createNewItemButtonText}
                            createNewItemCodePlaceHolder={this.props.createNewItemCodePlaceHolder}
                            createNewItemNamePlaceHolder={this.props.createNewItemNamePlaceHolder}
                            createNewItemCodePropertyIdentifier={this.props.createNewItemCodePropertyIdentifier}
                            createNewItemNamePropertyIdentifier={this.props.createNewItemNamePropertyIdentifier}
                            createNewItemValidationEntityName={this.props.createNewItemValidationEntityName}
                            showNewItem={this.showNewItem}
                            onNewItemClick={this.props.createNewItemAsync && this.openComplexSearchWithNewItems}
                            dropDownNewItemText={this.props.dropDownNewItemText}
                            selectableItemsSearchPlaceHolder={this.props.selectableItemsSearchPlaceHolder}
                            selectedItemsSearchPlaceHolder={this.props.selectedItemsSearchPlaceHolder}
                            itemGroups={this.props.complexSearchItemGroups}
                            hideAllgroup={this.props.hideAllgroup}
                            hoverOnlyIndicatorIcons={this.props.hoverOnlyIndicatorIcons}
                            columnDescriptors={this.props.columnDescriptors}
                            newItemPermissionIdentifier={this.props.newItemPermissionIdentifier}
                            customContentComponentType={this.props.customContentComponentType}
                            customContentComponentProps={this.props.customContentComponentProps}
                        />}
                    </State.Observer>
                }
            </ReadOnlyContext.Consumer>
        );
    }
}

export default connect(
    UniversalCodeSelector,
    new DependencyAdapter<IUniversalCodeSelectorProps<any, any>, IUniversalCodeSelectorDependencies>(container => {
        return {
            entityCollectionsService: container.resolve("IEntityCollectionsService")
        };
    }, (dependencies) => ({
        _baseDependencies: dependencies
    }))
);
