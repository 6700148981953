import React from "react";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import IPatientCareActivitiesDataSource from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientCareActivitiesDataSource";
import PatientId from "@Primitives/PatientId.g";
import IPatientsCareActivitiesFilterStore from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientsCareActivitiesFilterStore";
import AccessLevel from "@Primitives/AccessLevel";
import * as Ui from "@CommonControls";
import * as Styles from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PatientsCareActivitiesPanel/PatientCareActivitiesMasterDetailPanel.less";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import StaticWebAppResources from "@StaticResources";
import CareActivityStateColumn2 from "@HisPlatform/BoundedContexts/Care/Components/Controls/DataGridColumns/CareActivitiyStateColumn";
import DataGridDateTimeRangeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeRangeColumn";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import IPatientsCareActivityListCareOrganizationUnitColumnExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientsCareActivityListCareOrganizationUnitColumnExtensionPointProps";
import PractitionerColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerColumn/PractitionerColumn";
import IPatientsCareActivityListConditionColumnExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientsCareActivityListCondtionColumnExtensionPointProps";
import IPatientsCareActivityListReferralOrganizationUnitColumnExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientsCareActivityListReferralOrganizationUnitColumnExtensionPointProps";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";
import PatientsCareActivityGridButtons from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PatientsCareActivitiesPanel/PatientsCareActivityGridButtons";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivitySourceRadioButtonGroup from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/CareActivitySourceRadioButtonGroup";
import DateRangePicker, {IntervalOption} from "@CommonControls/DateRangePicker/DateRangePicker";
import CareTypeSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/CareTypeSelectBox";
import CareActivityStateSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/CareActivityStateSelectBox/CareActivityStateSelectBox";
import PointOfCareSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareSelectBox";
import * as HisUi from "@HisPlatformControls";
import IPatientsCareActivityListWarningExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientsCareActivityListWarningExtensionPointProps";
import ICareActivitySourceRegistry from "@HisPlatform/BoundedContexts/Care/Services/Definition/CareActivitySourceRegistry/ICareActivitySourceRegistry";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface IPatientCareActivitiesMasterDetailListViewDependencies {
    careActivitySourceRegistry: ICareActivitySourceRegistry;
}

interface IPatientCareActivitiesMasterDetailListViewProps {
    _dependencies?: IPatientCareActivitiesMasterDetailListViewDependencies;
    selectedCareActivity: PatientsCareActivityStore;
    selectCareActivityAsync: (row: PatientsCareActivityStore) => Promise<void>;
    isFilterOpen: boolean;
    filterStore: IPatientsCareActivitiesFilterStore;
    dataSource: IPatientCareActivitiesDataSource;
    showSomeItemsAreHiddenMessage: boolean;
    resetSelectedCareActivity: () => void;
    showPrimaryDocumentAsync: (row: PatientsCareActivityStore) => Promise<void>;
    patientId: PatientId;
    tooltipReferenceElement: HTMLElement;
    tooltipContent: React.ReactNode;
    isTooltipOpen: boolean;
}

@State.observer
class PatientCareActivitiesListView extends React.Component<IPatientCareActivitiesMasterDetailListViewProps> {
    private renderCareIdentifierColumnValue(value: string, row: PatientsCareActivityStore) {
        return (
            <>
                {value}
                {row.accessLevel < AccessLevel.Full && <Ui.Icon className={Styles.shieldIcon} iconName="shield" />}
                {row.careActivitySource && row.careActivitySource.value === "HunEHealthInfrastructure" && <Ui.Badge text="EESZT" visualStyle="light-blue" size="mini" />}
            </>
        );
    }

    @State.bound
    private renderFilters() {
        const patientsCareActivityListExtraFilterExtensionProps: IPatientsCareActivityListWarningExtensionPointProps = {
            careActivitySource: this.props.filterStore.careActivitySource,
            filterStore: this.props.filterStore,
            isFilterOpen: this.props.isFilterOpen,
            onQueryAsync: this.props.dataSource.clearExtensionDataCacheAndReloadAsync
        };
        const shouldRenderSourceSelector = this.props._dependencies.careActivitySourceRegistry.careActivitySources.length > 1;

        return !this.props.selectedCareActivity && this.props.isFilterOpen && (
            <HisPlatformExtensionPoint
                type="patientsCareActivityListExtraFilter"
                extensionProps={patientsCareActivityListExtraFilterExtensionProps}
            >
                <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        <Ui.Flex>
                            {shouldRenderSourceSelector && <Ui.Flex.Item xs={3}>
                                <CareActivitySourceRadioButtonGroup
                                    label={StaticCareResources.EHR.PatientsCareActivities.Labels.CareActivitySource}
                                    items={this.props._dependencies.careActivitySourceRegistry.careActivitySources}
                                    value={this.props.filterStore.careActivitySource}
                                    onChange={this.props.filterStore.setCareActivitySource}
                                />
                            </Ui.Flex.Item>}
                            <Ui.Flex.Item xs={3}>
                                <DateRangePicker
                                    label={StaticCareResources.EHR.PatientsCareActivities.Labels.DateRange}
                                    value={this.props.filterStore.dateRange}
                                    onChange={this.props.filterStore.setDateRange}
                                    intervalOptions={[
                                        IntervalOption.Today,
                                        IntervalOption.ThisWeek,
                                        IntervalOption.LastWeek,
                                        IntervalOption.ThisMonth,
                                        IntervalOption.Last30Days,
                                        IntervalOption.LastYear,
                                        IntervalOption.Last5Years
                                    ]}
                                    automationId="dateRange"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={3}>
                                <CareTypeSelectBox
                                    label={StaticCareResources.EHR.PatientsCareActivities.Labels.CareType}
                                    value={this.props.filterStore.careTypeIds}
                                    onChange={this.props.filterStore.setCareTypeIds}
                                    multiSelect
                                    automationId="careTypeIds"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={3}>
                                <Ui.TextBox
                                    value={this.props.filterStore.careIdentifier}
                                    onChange={this.props.filterStore.setCareIdentifier}
                                    label={StaticCareResources.EHR.PatientsCareActivities.Labels.CareIdentifier}
                                    automationId="careIdentifier"
                                />
                            </Ui.Flex.Item>
                            {!shouldRenderSourceSelector && this.renderEpisodeOfCareFilter()}
                            <Ui.Flex.Item xs={3}>
                                <CareActivityStateSelectBox
                                    value={this.props.filterStore.careActivityStates}
                                    onChange={this.props.filterStore.setCareActivityStates}
                                    label={StaticCareResources.EHR.PatientsCareActivities.Labels.CareActivityState}
                                    multiSelect
                                    automationId="careActivityStates"
                                />

                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={3}>
                                <PointOfCareSelectBox
                                    label={StaticCareResources.EHR.PatientsCareActivities.Labels.PointOfCare}
                                    value={this.props.filterStore.pointOfCareIds}
                                    onChange={this.props.filterStore.setPointOfCareIds}
                                    multiSelect
                                    automationId="pointOfCareIds"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={3}>
                                <HisUi.DoctorCodeSelector
                                    label={StaticCareResources.EHR.PatientsCareActivities.Labels.LeadDoctor}
                                    value={this.props.filterStore.practitionerIds}
                                    onChange={this.props.filterStore.setPractitionerId}
                                    multiSelect={true}
                                    automationId="practitionerId"
                                />
                            </Ui.Flex.Item>
                            {shouldRenderSourceSelector && this.renderEpisodeOfCareFilter()}
                        </Ui.Flex>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </HisPlatformExtensionPoint>
        );
    }

    @State.bound
    private renderEpisodeOfCareFilter() {
        return (
            <Ui.Flex.Item xs={3}>
                <HisUi.EpisodeOfCareSelectBox
                    patientId={this.props.patientId}
                    value={this.props.filterStore.episodeOfCareIds}
                    onChange={this.props.filterStore.setEpisodeOfCareIds}
                    hasAddNew={false}
                    multiSelect
                    automationId="__episodeOfCareSelectBox" />
            </Ui.Flex.Item>
        );
    }

    @State.bound
    private renderMessages() {
        return (
            <HisPlatformExtensionPoint
                type="patientsCareActivityListInfo"
                extensionProps={{
                    extensionData: this.props.dataSource.extensionData,
                    isFilterOpen: this.props.isFilterOpen
                }}
            >
                <p>{this.props.showSomeItemsAreHiddenMessage && (`${StaticWebAppResources.DailyPatientList.Label.OneOrMoreHidden}.`)}</p>
            </HisPlatformExtensionPoint>
        );
    }

    public render() {
        const isFullGrid = !this.props.selectedCareActivity;

        return (
            <Ui.ToolbarLayout
                topToolbar={this.renderFilters()}
                topToolbarHeight={"auto"}
            >
                <Ui.DataGrid
                    dataSource={this.props.dataSource}
                    filterStore={this.props.filterStore}
                    rowHeight="49px"
                    selectedRow={this.props.selectedCareActivity}
                    isSelectable
                    changeOnMount
                    fixedLayout
                    autoReloadOnFilter={false}
                    fixedHeight={"100%"}
                    onRowClick={this.props.selectCareActivityAsync} // todo fix async problem
                    alwaysVisibleExtraFilter={this.renderMessages()} // todo this is a hack, but at least the grid doesn't overflow when messages appear
                    isLoading={this.props.dataSource.isLoading}
                    automationId="patientCareActivitiesListView_dataGrid"
                >
                    <CareActivityStateColumn2
                        dataGetter="careTypeId"
                        careActivityStateDataGetter="careActivityState"
                        header={""}
                        width="60px"
                    />
                    <DataGridDateTimeRangeColumn
                        id="carePeriod"
                        dataGetter="carePeriod"
                        header={StaticCareResources.EHR.PatientsCareActivities.ColumnHeaders.CareDateRange}
                        isOrderable
                        width="125px"
                    />
                    <DataGridColumn
                        id="careIdentifier"
                        isVisible={isFullGrid}
                        dataGetter="primaryCareIdentifier"
                        header={StaticCareResources.EHR.PatientsCareActivities.ColumnHeaders.CareIdentifier}
                        onRenderCellValue={this.renderCareIdentifierColumnValue}
                        isOrderable
                    />
                    <HisPlatformExtensionPoint
                        type="patientsCareActivityListCareOrganizationUnitColumn"
                        extensionProps={{
                            header: StaticCareResources.EHR.PatientsCareActivities.ColumnHeaders.OrganizationUnit,
                            displayOrder: 350
                        } as IPatientsCareActivityListCareOrganizationUnitColumnExtensionPointProps}>
                        <></>
                    </HisPlatformExtensionPoint>
                    <PractitionerColumn
                        isVisible={isFullGrid}
                        id="practitionerId"
                        dataGetter="primaryParticipantId"
                        fallbackValueGetter="primaryParticipantCode"
                        header={StaticCareResources.EHR.PatientsCareActivities.ColumnHeaders.LeadDoctor}
                        isOrderable
                        isCodeBold
                    />
                    <HisPlatformExtensionPoint
                        type="patientsCareActivityListConditionColumn"
                        extensionProps={{
                            header: StaticCareResources.EHR.PatientsCareActivities.ColumnHeaders.Condition,
                            displayOrder: 450,
                            width: 100,
                            isFullGrid: isFullGrid
                        } as IPatientsCareActivityListConditionColumnExtensionPointProps}>
                        <></>
                    </HisPlatformExtensionPoint>
                    <HisPlatformExtensionPoint
                        type="patientsCareActivityListReferralOrganizationUnitColumn"
                        extensionProps={{
                            header: StaticCareResources.EHR.PatientsCareActivities.ColumnHeaders.ReferralWorkplace,
                            displayOrder: 451,
                            isFullGrid: isFullGrid,
                            showName: isFullGrid
                        } as IPatientsCareActivityListReferralOrganizationUnitColumnExtensionPointProps}>
                        <></>
                    </HisPlatformExtensionPoint>
                    <PractitionerColumn
                        isVisible={isFullGrid}
                        id="referralDoctorId"
                        dataGetter="referralDoctorId"
                        fallbackValueGetter="referralDoctorCode"
                        header={StaticCareResources.EHR.PatientsCareActivities.ColumnHeaders.ReferralDoctor}
                        isOrderable
                        isCodeBold
                    />
                    <DataGridColumn width="80px">
                        <PatientsCareActivityGridButtons
                            onBack={this.props.resetSelectedCareActivity}
                            selectCareActivityAsync={this.props.selectCareActivityAsync}
                            selectedCareActivity={this.props.selectedCareActivity}
                            showPrimaryDocumentAsync={this.props.showPrimaryDocumentAsync}
                            patientsCareActivityListExtensionData={this.props.filterStore?.getExtensionData?.()}
                            patientId={this.props.patientId}
                            dataSource={this.props.dataSource}
                        />
                    </DataGridColumn>
                </Ui.DataGrid>
                <Popper
                    referenceElement={this.props.tooltipReferenceElement}
                    tooltipContent={this.props.tooltipContent}
                    isOpen={this.props.isTooltipOpen}
                    tooltipPlacement="right"
                />
            </Ui.ToolbarLayout>
        );
    }
}

export default connect(
    PatientCareActivitiesListView,
    new DependencyAdapter<IPatientCareActivitiesMasterDetailListViewProps, IPatientCareActivitiesMasterDetailListViewDependencies>(c => ({
        careActivitySourceRegistry: c.resolve("ICareActivitySourceRegistry")
    }))
);