import NDataScreen from "@HisPlatform/BoundedContexts/Productivity/Components/NDataScreen/NDataScreen";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import React, { useCallback, useMemo } from "react";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import { useScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import GlobalEpisodeOfCareListScreenApiAdapter from "@HisPlatform/Packages/Care/Screens/GlobalEpisodeOfCareListScreen/GlobalEpisodeOfCareListScreenApiAdapter";
import ShowGlobalEpisodeOfCareListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowGlobalEpisodeOfCareListScreenAction.g";
import ShowCareActivitiesForEpisodeOfCareScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivitiesForEpisodeOfCareScreenAction.g";

export default function GlobalEpisodeOfCareListScreen(props: IScreenPropsBase<ShowGlobalEpisodeOfCareListScreenAction>) {

    const { episodeOfCareListScreenApiAdapter } = useDependencies(c => ({
        episodeOfCareListScreenApiAdapter: c.resolve<GlobalEpisodeOfCareListScreenApiAdapter>("GlobalEpisodeOfCareListScreenApiAdapter"),
    }));

    const getDynamicListAsync = useCallback(async (args: FrontendListParameters) => {
        const response = await episodeOfCareListScreenApiAdapter.getGlobalEpisodeOfCareListAsync(args);
        return response.result;
    }, []);

    const navigationContext = useScreenNavigationContext();

    const isShowCareActivitiesForEpisodeOfCareAction = useMemo(() => {
        return navigationContext.currentSecondaryScreen?.action instanceof ShowCareActivitiesForEpisodeOfCareScreenAction;
    }, [navigationContext.currentSecondaryScreen]);

    return (
        <NDataScreen
            iconName="episode_of_care"
            onGetDynamicListAsync={getDynamicListAsync}
            isMasterDetailSeparatedMode={isShowCareActivitiesForEpisodeOfCareAction}
        />
    );
}
