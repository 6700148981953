import React from "react";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IDataGridDataSource from "@CommonControls/DataGrid/DataSource/IDataGridDataSource";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import MultiEntitySelectorBase from "@CommonControls/MultiEntitySelectorBase";
import FavoriteDataGridColumn from "./FavoriteDataGridColumn";
import EntityCollectionStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/Personalization/EntityCollectionStore";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import _ from "@HisPlatform/Common/Lodash";
import { DataGridLoadType, IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";

type displayType = "entity" | "entityGroup";

interface IComplexSearchMultiSelectProps<TRecord = any> {
    dataSource: TRecord[] | IPagedItems<TRecord> | IDataGridDataSource;
    groupDataSource: IPagedItems<EntityCollectionStore>;
    onChangeAsync: (type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState | IOrderingState[], filter: any, columns: IDataGridColumnProps[]) => Promise<void>;
    isLoading: boolean;
    displayType: displayType;
    selectedItems: IObservableArray<TRecord>;
    getEntitiesByIds: (id: IStringEntityId[]) => TRecord[];

    codeGetter: string;
    nameGetter: string;
    complexSearchRenderNameCell: (value: any, row: TRecord) => React.ReactNode;
    paging: IPagingState;

    showFavoritesColumn: boolean;
    isFavorite: (value: any) => boolean;
    setFavorite: (value: TRecord) => void;
    groupName: string;
}

@State.observer
export default class ComplexSearchMultiSelect<TRecord = any> extends React.Component<IComplexSearchMultiSelectProps<TRecord>> {

    @State.bound
    private getColumns(codeGetter: string, nameGetter: string) {
        return (
            <>
                <DataGridColumn
                    id="code"
                    dataGetter={codeGetter}
                    leftPadding="small"
                    width="10%"
                />
                <DataGridColumn
                    id="name"
                    dataGetter={nameGetter}
                    onRenderCellValue={this.props.complexSearchRenderNameCell}
                />
                {
                    this.props.showFavoritesColumn && (
                        <FavoriteDataGridColumn
                            isFavorite={this.props.isFavorite}
                            onFavoriteIconClick={this.props.setFavorite}
                        />
                    )
                }
            </>
        );
    }

    @State.action.bound
    private onAdded(value: TRecord | EntityCollectionStore) {
        switch (this.props.displayType) {
            case "entity":
                this.props.selectedItems.push(value as TRecord);
                break;
            case "entityGroup":
                const items = this.props.getEntitiesByIds((value as EntityCollectionStore).idList);
                this.props.selectedItems.push(...items);
                break;
            default:
                throw new Error(`Unknown displayType: ${this.props.displayType}`);
        }
    }

    @State.action.bound
    private onRemoved(value: TRecord) {
        _.remove(this.props.selectedItems, value);
    }

    @State.action.bound
    private onGroupAdded(value: EntityCollectionStore) {
        const items = this.props.getEntitiesByIds(value.idList);
        const filteredItems = items.filter(item => !this.props.selectedItems.some(i => i === item));
        this.props.selectedItems.push(...filteredItems);
    }

    @State.bound
    private renderColumns() {
        switch (this.props.displayType) {
            case "entity":
                return this.getColumns(this.props.codeGetter, this.props.nameGetter);
            case "entityGroup":
                return this.getColumns("code", "name");
            default:
                throw new Error(`Unknown displayType: ${this.props.displayType}`);
        }
    }

    @State.bound
    private renderSelectedColumns() {
        return this.getColumns(this.props.codeGetter, this.props.nameGetter);
    }

    @State.computed
    private get dataSource() {
        switch (this.props.displayType) {
            case "entity":
                return this.props.dataSource;
            case "entityGroup":
                return this.props.groupDataSource;
            default:
                throw new Error(`Unknown displayType: ${this.props.displayType}`);
        }
    }

    @State.computed
    private get selectedItems() {
        return {
            items: this.props.selectedItems,
            totalCount: this.props.selectedItems.length
        } as IPagedItems<TRecord>;
    }

    private selectedItemsOnChange() {
        return Promise.resolve();
    }

    public render() {
        return (
            <MultiEntitySelectorBase
                renderColumns={this.renderColumns}
                renderSelectedColumns={this.renderSelectedColumns}
                allItemsDataSource={this.dataSource}
                allItemsOnChange={this.props.onChangeAsync}
                allItemsIsLoading={this.props.isLoading}
                paging={this.props.paging}
                selectedItemsDataSource={this.selectedItems}
                selectedItemsOnChange={this.selectedItemsOnChange}
                onAdded={this.onAdded}
                onRemoved={this.onRemoved}
                fixedHeight="100%"
                ignoreDisable={this.props.displayType === "entityGroup"}
                rowHeight="34px"
                hidePager={false}
                key={this.props.groupName}
                changeOnMount
            />
        );
    }
}
