import React from "react";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import AddressTypeSelectBox from "@HisPlatform/BoundedContexts/CommonReferenceData/Components/Controls/AddressTypeSelectBox";
import PractitionerAddressStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerAddressStore";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IPractitionerAddressViewProps {
    address: PractitionerAddressStore;
    index: number;
}


const PractitionerAddressView: React.FC<IPractitionerAddressViewProps> = props =>
    (
        <>
            <Ui.Flex>
                <Ui.Flex.Item xs={3}>
                    <AddressTypeSelectBox
                        value={props.address.addressTypeId}
                        onChange={props.address.setAddressTypeId}
                        label={StaticUserManagementResources.UserPanel.Label.AddressType}
                        propertyIdentifier="AddressTypeId"
                        automationId="addressTypeId"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={3}>
                    <HisUi.CountrySelector
                        variant="country"
                        label={StaticUserManagementResources.UserPanel.Label.Country}
                        value={props.address.countryId}
                        onChange={props.address.setCountryId}
                        propertyIdentifier="CountryId"
                        automationId="countryId"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={6}>
                    <HisUi.SettlementAndZipCodeSelector
                        countryId={props.address.countryId}
                        settlement={props.address.settlement}
                        setSettlement={props.address.setSettlement}
                        zipCode={props.address.zipCode}
                        setZipCode={props.address.setZipCode}
                        automationIdPrefix="settlementAndZipCode"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex>
                <Ui.Flex.Item xs={6}>
                    <Ui.TextBox
                        label={StaticUserManagementResources.UserPanel.Label.AddressLine}
                        value={props.address.addressLine}
                        onChange={props.address.setAddressLine}
                        propertyIdentifier="AddressLine"
                        automationId="addressLine"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={3}>
                    <Ui.DatePicker
                        label={StaticUserManagementResources.UserPanel.Label.ValidFrom}
                        value={props.address.validFrom}
                        onChange={props.address.setValidFrom}
                        propertyIdentifier="ValidFrom"
                        automationId="validFrom"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={3}>
                    <Ui.DatePicker
                        label={StaticUserManagementResources.UserPanel.Label.ValidTo}
                        value={props.address.validTo}
                        onChange={props.address.setValidTo}
                        propertyIdentifier="ValidTo"
                        automationId="validTo"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </>
    );

export default State.observer(PractitionerAddressView);
