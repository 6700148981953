import * as React from "react";
import FieldValidationResult from "@CommonControls/FieldValidationResult";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ValidationBoundaryAdapter from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { arrayIsNullOrEmpty, emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EventHandler from "@Toolkit/ReactClient/Components/EventHandler/EventHandler";
import CheckBoxCore, { ICheckBoxBaseProps } from "@CommonControls/CheckBox/CheckBoxCore";
import ReadOnlyContextAdapter from "@Toolkit/ReactClient/Components/ReadOnlyContext/ReadOnlyContextAdapter";
import PermissionCheckContextAdapter from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextAdapter";

interface ICheckBoxProps extends ICheckBoxBaseProps {
    showMultipleValidationProblems?: boolean;
    propertyIdentifier?: string;
    _validationContext?: IValidationBoundaryStore;
    forceShowValidationsResults?: boolean;
}

@State.observer
class CheckBox extends React.Component<ICheckBoxProps> {

    @State.computed
    private get validationProblems() {
        if (this.props._validationContext) {
            const problems = this.props._validationContext.getValidationProblems(this.props.propertyIdentifier);
            return arrayIsNullOrEmpty(problems) ? emptyArray : problems;
        }
        return emptyArray;
    }

    @State.computed
    private get validationResult() {
        return this.validationProblems.length === 0 ? null : this.validationProblems[0];
    }

    @State.computed
    private get validationProblemSeverity() {
        return this.validationResult?.severity;
    }

    public componentDidUpdate(prevProps: ICheckBoxProps) {
        if (this.props.value !== prevProps.value) {
            this.validate();
        }
    }

    @State.bound
    private validate() {
        this.props._validationContext?.changed(this.props.propertyIdentifier, this.props.value);
    }

    public render() {
        const baseProps = this.props as ICheckBoxBaseProps;
        const fieldValidationResult = (!this.props.disabled || this.props.forceShowValidationsResults) &&
            <FieldValidationResult problems={this.validationProblems} showMultipleProblems={this.props.showMultipleValidationProblems} />;

        return (
            <>
                <CheckBoxCore
                    {...baseProps}
                    validationProblemSeverity={this.validationProblemSeverity}
                    validationResults={fieldValidationResult}
                />
                <EventHandler event={this.props._validationContext?.validateAllEvent} onFired={this.validate} />
            </>
        );
    }
}

export default connect(
    CheckBox,
    new ValidationBoundaryAdapter((props, store) => ({ _validationContext: store })),
    new ReadOnlyContextAdapter<ICheckBoxProps>((props, isReadOnly) => ({ isReadOnly: props.isReadOnly || isReadOnly })),
    new PermissionCheckContextAdapter()
);
