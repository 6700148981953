import DependencyContainer from "@DiContainer";
import IssueReportingApiAdapter from "@HisPlatform/BoundedContexts/Diagnostics/ApplicationLogic/ApiAdapter/IssueReportingApiAdapter";
import * as DiagnosticsProxy from "@HisPlatform/BoundedContexts/Diagnostics/Api/Proxy.g";
import * as BffProxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import ApplicationVersionApiAdapter from "@HisPlatform/BoundedContexts/Diagnostics/ApplicationLogic/ApiAdapter/ApplicationVersionApiAdapter";

DependencyContainer
    .bind("IssueReportingApiAdapter")
    .to(IssueReportingApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("IIssueReportingClient")
    .to(DiagnosticsProxy.IssueReportingClient)
    .inSingletonScope();

DependencyContainer
    .bind("IDiagnosticsClient")
    .to(BffProxy.DiagnosticsClient)
    .inSingletonScope();

DependencyContainer
    .bind("ApplicationVersionApiAdapter")
    .to(ApplicationVersionApiAdapter)
    .inSingletonScope();