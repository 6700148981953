import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import CareActivityEReferralStore from "@HunEHealthInfrastructurePlugin/Model/CareActivityEReferralStore";

export interface IEhiEReferralDetailDialogParams extends IModalParams {
    careActivityEReferralStore: CareActivityEReferralStore;
}


export interface IEhiEReferralDetailDialogResult {
}

export default class EhiEReferralDetailDialogParams implements IEhiEReferralDetailDialogParams {
    public static type = "EhiEReferralDetailDialog";
    public get type() { return EhiEReferralDetailDialogParams.type; }

    constructor(public careActivityEReferralStore: CareActivityEReferralStore) { }
}
