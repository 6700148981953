import React from "react";
import EhiErrorSummary from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiErrorSummary";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IMedicalConditionSynchronizationExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IMedicalConditionSynchronizationExtensionPointProps";
import EhiMedicalConditionSynchronizationExtensionStore from "./EhiMedicalConditionSynchronizationExtensionStore";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IEhiMedicalConditionSynchronizationErrorHandlerExtensionProps extends IMedicalConditionSynchronizationExtensionPointProps {
}

@State.observer
export default class EhiMedicalConditionSynchronizationErrorHandlerExtension extends React.Component<IEhiMedicalConditionSynchronizationErrorHandlerExtensionProps> {

    @State.computed private get ehiErrors() {
        const extensionStore = this.props.extensionStore.getOrAddExtensionStore("ehiMedicalConditionSynchronizationExtensionStore", () => new EhiMedicalConditionSynchronizationExtensionStore());
        return extensionStore.getEhiErrors();
    }

    public render() {
        if (isNullOrUndefined(this.ehiErrors) || this.ehiErrors.length === 0) {
            return (
                <></>
            );
        }
        return (
            <EhiErrorSummary
                errors={this.ehiErrors}
            />
        );
    }
}