import React from "react";
import ITokenSettingsPanelRegistry, { ITokenSettingsPanelPropsBase } from "@PluginInterface/BoundedContexts/DocumentManagement/TokenSettingsPanelRegistry/ITokenSettingsPanelRegistry";
import Di from "@Di";
import UnknownTokenSettingsPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Services/TokenSettingsPanelRegistry/UnknownTokenSettingsPanel";
import ITemplateToken from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateToken";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";

export interface ITokenSettings {
    tokenFormatterSettings: object;
    providerParameterSettings: object;
    onSettingsChange: (tokenFormatterSettings: object, providerParameterSettings: object) => void;
    onUpdateTokenDisplayName?: (newDisplayName: string) => void;
}

@Di.injectable()
export default class TokenSettingsPanelRegistry implements ITokenSettingsPanelRegistry {

    private readonly _registry = new Map<string, React.ComponentType<ITokenSettingsPanelPropsBase>>();

    public register(tokenFormatterSettingsType: string, panel: React.ComponentType<ITokenSettingsPanelPropsBase>): void {
        this._registry.set(tokenFormatterSettingsType, panel);
    }

    public render(tokenSettings: ITokenSettings, token: ITemplateToken): React.ReactNode {

        if (isNullOrUndefined(token.tokenFormatterSettingsType)) {
            return (
                <SpanWithIcon iconName="infoCircle">
                    {StaticDocumentManagementResources.TemplateManagement.ThisTokenUsageHasNoConfigurableParametersMessage}
                </SpanWithIcon>
            );
        }

        const panel = this._registry.get(token.tokenFormatterSettingsType) ?? UnknownTokenSettingsPanel;

        return React.createElement<ITokenSettingsPanelPropsBase>(panel, {
            tokenFormatterSettings: tokenSettings.tokenFormatterSettings,
            onSettingsChange: tokenSettings.onSettingsChange,
            onUpdateTokenDisplayName: tokenSettings.onUpdateTokenDisplayName,
            tokenFormatterSettingsType: token.tokenFormatterSettingsType,
            tokenFormatter: token.tokenFormatter,
            tokenFormatterConfiguration: token.tokenFormatterConfiguration,
            providerParameterConfiguration: token.providerParameterConfiguration,
            providerParameterSettings: tokenSettings.providerParameterSettings
        });
    }
}