import React from "react";
import Styles from "./Modal.less";

export interface IModalLabel {
    label: string;
}

export default class ModalLabel extends React.PureComponent<IModalLabel> {
    public render() {
        return (
            <div className={Styles.label}>
                <label>{this.props.label}</label>
                <p>{this.props.children}</p>
            </div>
        );
    }
}