import ExtendedFilterStoreBase from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ExtendedFilterStoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import { createFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterHelpers";
import { IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";

export default class RequestedServicesFilterStore extends ExtendedFilterStoreBase {

    constructor(
        filterStore: IFilterStore
    ) {
        super(filterStore);

    }

    @State.observable.ref public medicalServiceIds: MedicalServiceId[] = [];

    @State.action.bound
    public setMedicalServiceIds(newValue: Array<IEntityVersionSelector<MedicalServiceId>>) {
        this.medicalServiceIds = newValue ? newValue.map(item => item.id) : [];
        this.setFilter("CareActivityBased_RequestedServices", this.medicalServiceIds);
    }

    @State.action.bound
    public clearFilters() {
        this.medicalServiceIds = [];
    }

    @State.bound
    public static getFilterDescriptors() {
        const descriptors: IDataGridFilterDescriptor[] = [];

        const medicalServiceIdsFilterDescriptor = createFilterDescriptor(
            "CareActivityBased_RequestedServices",
            {
                serialize: (value: MedicalServiceId[]) => {
                    const stringValues = value.map(i => i.value);
                    return stringValues.join(";");
                },
                deserialize: (value: string) => {
                    const values = value.split(";");
                    return values.map(v => {
                        return new MedicalServiceId(v);
                    });
                }
            } as IDataGridFilterValueSerializer<MedicalServiceId[]>,
            WorklistConditionType.EntityId);
        descriptors.push(medicalServiceIdsFilterDescriptor);

        return descriptors;
    }
}
