import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/Proxy.g";
import Di from "@Di";
import EuPerformanceStatementId from "@Primitives/EuPerformanceStatementId.g";
import EuPerformanceStatement from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/EuPerformanceStatement";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";

type ResponseType =
    Proxy.GetEuPerformanceStatementByIdQueryResponse |
    Proxy.CreateEuPerformanceStatementCommandResponse |
    Proxy.SetEuPerformanceStatementTransactionCodeCommandResponse |
    Proxy.SetEuPerformanceStatementErrorListCommandResponse;

@Di.injectable()
export default class EuPerformanceStatementStoreMapper extends EntityStoreMapper<Proxy.EuPerformanceStatementDto, EuPerformanceStatement, ResponseType> {
    
    protected storeEntityIdType = EuPerformanceStatementId;

    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    protected entityDtoSelector(response: ResponseType) {
        if (response instanceof Proxy.EuPerformanceStatementDto) {
            return response;
        }
        return response.euPerformanceStatementDto;
    }

    public applyToStoreCore(target: EuPerformanceStatement, response: ResponseType) {
        if (!response.euPerformanceStatementDto) {
            return;
        }

        const dto = this.entityDtoSelector(response);

        target.id = dto.id;
        target.rowVersion = dto.rowVersion;
        target.transactionCode = dto.transactionCode;
        target.type = dto.type;
        target.reportValidityDate = dto.reportValidityDate;
        target.largeDataIds = [dto.contentId, dto.dataSheetId];
    }
}
