import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import UserId from "@Primitives/UserId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IProfileManagementRouteParams } from "@HisPlatform/Application/Routes/IProfileManagementRouteParams";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ProfileManagementPanel from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/ProfileManagementPanel/ProfileManagementPanel";
import { HisErrorBoundary } from "@HisPlatformControls";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

@State.observer
export default class ProfileManagementPage extends React.Component<IRoutingFrameContentProps> {

    private get userId(): UserId {
        const userIdString = (this.props.routingController.currentRoute.parameters as IProfileManagementRouteParams).userId;
        if (!isNullOrUndefined(userIdString)) {
            return new UserId(userIdString);
        }
        return null;
    }

    public render() {
        return (
            <HisErrorBoundary>
                <LoadingBoundary>
                    <ProfileManagementPanel userId={this.userId} />
                </LoadingBoundary>
            </HisErrorBoundary>
        );
    }
}