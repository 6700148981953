import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IFinanceResources from "./IFinanceResources";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";

const resources = createStaticResourceDictionary<IFinanceResources>();

export function loadStaticFinanceResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    resources.load(localizationService.getResourceGroup("WebApp").Finance);
}

export default resources;