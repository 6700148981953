import ShowNewPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowNewPatientScreenAction.g";
import ShowPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientScreenAction.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { IScreenPropsBase } from "@HisPlatform/Services//Definition/ActionProcessing/IScreenRegistry";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { providePatientAdministrativeDataScreenStore, usePatientAdministrativeDataScreenStore } from "./PatientAdministrativeDataScreenStoreProvider";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import React from "react";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import FormPanel from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";
import { ScreenStateContextAdapter, ScreenStateContextStore } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import { LockIndicatorComponent } from "@HisPlatform/Components/HisPlatformControls";
import * as Ui from "@CommonControls";
import ActionBoundSaveButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundSaveButton";
import ActionBoundButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundButton";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import { HisActionDispatcherAdapter } from "@HisPlatform/Common/FrontendActions/HisActionDispatcher";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import BillablePerformedServicesPanel from "@HisPlatform/Packages/Patients/Components/BillableServicesPanel/BillableServicesPanel";

export interface IPatientAdministrativeDataScreenProps extends IScreenPropsBase<
    ShowNewPatientScreenAction |
    ShowPatientScreenAction> {
    _screenState?: ScreenStateContextStore;
    _actionDispatcher?: IActionDispatcher;
    _dependencies?: IPatientAdministrativeDataScreenDependencies;
}

interface IPatientAdministrativeDataScreenDependencies {
    routingController: GlobalRoutingStore;
}

function PatientAdministrativeDataScreen(props: IPatientAdministrativeDataScreenProps) {

    const store = usePatientAdministrativeDataScreenStore();

    return (
        <>
            <BusinessErrorHandler.Register businessErrorName="DeletingInsurerWithFinancedCareActivitiesError" handler={store.showDeletingInsurerWithFinancedCareActivitiesError} />
            <Screen title={StaticWebAppResources.PatientBaseData.Menu.PatientData}
                iconName="person"
                toolbar={(
                    <>
                        {
                            store.canAcquireLock && (
                                <LockIndicatorComponent locked={true} onLoadClickedAsync={store.loadAsync} lockedBy={store.lockInfo?.preventingLockUserId} preventingLockId={store.lockInfo?.preventingLockId} />
                            )
                        }
                        <Ui.Button text={StaticWebAppResources.Common.Button.Cancel} onClickAsync={store.onCancelAsync} automationId="__cancel" />
                        {
                            !store.canAcquireLock && (
                                <>
                                    <Ui.Button
                                        text={StaticCareResources.PatientRegister.PatientBaseData.Button.CreateNewAppointment}
                                        iconName="new_appointment"
                                        automationId="__createNewAppointment"
                                        onClickAsync={store.onCreateNewAppointmentAsync}
                                        disabled={store.isCreateNewPatientScreen} />
                                    <ActionBoundButton
                                        text={StaticCareResources.PatientRegister.PatientBaseData.Button.AdmitPatient}
                                        iconName={"add_healthcare"}
                                        actionDescriptor={store.showNewAdmitPatientScreenAction}
                                        onBeforeExecuteAsync={store.beforeAdmitPatientAsync}
                                        automationId="__addToAmbulatoryCareActivityList"
                                        disabled={store.isAdmissionDisabled} />
                                    <ActionBoundSaveButton
                                        visualStyle="primary"
                                        actionDescriptor={store.actionForSaveButton}
                                        onExecuteAsync={store.onSaveAsync}
                                        automationId="__save" />
                                </>
                            )
                        }
                    </>
                )}>

                <ValidationBoundary
                    validationResults={store.validationResults}
                    entityTypeName="PatientAdministrativeData"
                    validateOnMount
                    onValidateAsync={store.onValidateAsync}
                >
                    <FormPanel form={store.form} definitionId={store.formDefinitionId} onChange={store.onDataChangeAsync.fireAndForget} isReadOnly={store.vIsReadOnly} />
                </ValidationBoundary>
            </Screen>
        </>
    );
}

export default connect<IPatientAdministrativeDataScreenProps>(
    providePatientAdministrativeDataScreenStore(PatientAdministrativeDataScreen),
    new DependencyAdapter<IPatientAdministrativeDataScreenProps, IPatientAdministrativeDataScreenDependencies>(c => ({
        routingController: c.resolve("GlobalRoutingStore"),
    })),
    new ScreenStateContextAdapter(),
    new HisActionDispatcherAdapter<IPatientAdministrativeDataScreenProps>()
);