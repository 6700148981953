import React from "react";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import PractitionerScheduleEntryType from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/PractitionerScheduleEntryType.g";

function PractitionerScheduleEntryTypeSelectBox(props: IUniversalEnumSelectorBaseProps): React.ReactElement {
    return (
        <UniversalEnumSelector
            {...props}
            enumOrigin="server"
            enumName={"PractitionerScheduleEntryType"} 
            enumType={PractitionerScheduleEntryType}
            multiSelect
            clearable
        />
    );
}

(PractitionerScheduleEntryTypeSelectBox as React.FC<IUniversalEnumSelectorBaseProps>).defaultProps = {
    displayMode: "groupedRadioButtons"
};

export default PractitionerScheduleEntryTypeSelectBox;