//#region imports
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ServiceAdministrationPanelView from "./ServiceAdministrationPanelView";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ObservationDefinitionApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ObservationDefinitionApiAdapter";
import ObservationsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/Observations/ObservationsApiAdapter";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import _ from "@HisPlatform/Common/Lodash";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import CareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CareActivityStore";
import CareActivityBaseDataApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityBaseData/CareActivityBaseDataApiAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import StatusChangeReasonTypeId from "@Primitives/StatusChangeReasonTypeId.g";
import ServiceRequestDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestDefinition";
import DiagnosisId from "@Primitives/DiagnosisId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import RequestedServiceBatchSelection from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceAdministrationPanel/BatchSelection/RequestedServiceBatchSelection";
import IStatusChangeReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/IStatusChangeReason";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import PractitionerId from "@Primitives/PractitionerId.g";
import * as Ui from "@CommonControls";
import { IPanelWithButtonPortalProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/IPanelWithButtonPortalProps";
import EventHandler from "@Toolkit/ReactClient/Components/EventHandler/EventHandler";
import IServiceRequestDataService from "@HisPlatform/BoundedContexts/Care/Services/Definition/ServiceRequestManagement/IServiceRequestDataService";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import StatusChangeReasonDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/StatusChangeReasonDialog/StatusChangeReasonDialogParams";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import DocumentPreviewModalParams from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Modals/DocumentPreviewModal/DocumentPreviewModalParams";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import ServiceRequestDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestDocument/ServiceRequestDocumentApiAdapter";
import RequestedServiceAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceAction.g";
import RequestedServiceState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceState.g";
//#endregion


interface IServiceAdministrationPanelDependencies {
    serviceRequestDataService: IServiceRequestDataService;
    careReferenceDataStore: CareReferenceDataStore;
    localizationService: ILocalizationService;
    observationsApiAdapter: ObservationsApiAdapter;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    careActivityApiAdapter: CareActivityApiAdapter2;
    careActivityBaseDataApiAdapter: CareActivityBaseDataApiAdapter;
    notificationService: INotificationService;
    dialogService: IDialogService;
    lockingApiAdapter: LockingApiAdapter;
    documentApiAdapter: ServiceRequestDocumentApiAdapter;
}

interface IServiceAdministrationPanelProps extends IPanelWithButtonPortalProps {
    _dependencies?: IServiceAdministrationPanelDependencies;
    _modalService?: IModalService;

    serviceRequestId: ServiceRequestId;
    currentCareActivityId?: CareActivityId;
    onClose?: () => void;
    onSaved: () => void;
    withoutPageBox?: boolean;
    hasNoVerticalMargin?: boolean;
    refreshEvent?: TypedEvent;
    hideLockIndicator?: boolean;
    isReadOnly?: boolean;
}

/** @screen */
@State.observer
class ServiceAdministrationPanel extends React.Component<IServiceAdministrationPanelProps> {
    //#region dependencies
    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get observationsApiAdapter() { return this.props._dependencies.observationsApiAdapter; }
    private get serviceRequestDataService() { return this.props._dependencies.serviceRequestDataService; }
    private get organizationReferenceDataStore() { return this.props._dependencies.organizationReferenceDataStore; }
    private get careActivityApiAdapter() { return this.props._dependencies.careActivityApiAdapter; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get dialogService() { return this.props._dependencies.dialogService; }
    private get lockingApiAdapter() { return this.props._dependencies.lockingApiAdapter; }
    private get modalService() { return this.props._modalService; }
    private get documentApiAdapter() { return this.props._dependencies.documentApiAdapter; }
    //#endregion

    @State.observable.ref private isLoading = true;
    @State.observable.ref private selectedMedicalServiceIdSet: Set<string> = new Set<string>();
    @State.observable.ref private serviceRequest: ServiceRequestStore = null;
    @State.observable.ref private serviceRequestDefinition: ServiceRequestDefinition = null;

    private sourceCareActivity: CareActivityStore = null;

    @State.observable.ref private batchSelection: RequestedServiceBatchSelection = null;

    private info: React.ReactNode;
    private disposables: IDisposable[] = [];

    @State.computed private get isAdministration() {
        return ValueWrapper.equals(this.props.currentCareActivityId, this.serviceRequest.executingCareActivityId);
    }

    @State.computed private get careActivityId() {
        return this.props.currentCareActivityId;
    }
    @State.computed get isDirty() {
        return this.serviceRequest.isDirty();
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(
        this.loadPanelAsync,
        async () => await this.props._dependencies.serviceRequestDataService.checkPermissionForModifyServiceRequestExecutionDataAsync(this.props.serviceRequestId));

    public componentDidMount() {
        this.initialLoadPanelSync();
    }

    public componentWillUnmount() {
        this.disposables.forEach(d => d.dispose());
    }

    public componentDidUpdate(prevProps: IServiceAdministrationPanelProps) {
        if (!ValueWrapper.equals(prevProps.serviceRequestId, this.props.serviceRequestId)) {
            this.initialLoadPanelSync();
        }
    }

    @State.bound
    private initialLoadPanelSync() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.boundLoadingState("isLoading")
    private async loadPanelAsync(forceReleaseLock: boolean = false, isPersisted: boolean = true) {

        let serviceRequest: ServiceRequestStore;
        if (forceReleaseLock) {
            serviceRequest =
                await this.serviceRequestDataService.getServiceRequestByIdWithForceLockAsync(
                    this.props.serviceRequestId,
                    this.careActivityId);
        } else {
            serviceRequest =
                await this.serviceRequestDataService.getServiceRequestByIdAsync(
                    this.props.serviceRequestId,
                    this.careActivityId,
                    false,
                    true);
        }

        const medicalServiceVersionSelectors = serviceRequest.requestedServices.map(i => i.medicalServiceVersionSelector);

        await this.careReferenceDataStore.medicalService.ensureLoadedAsync(medicalServiceVersionSelectors);
        await this.careReferenceDataStore.medicalService.ensureLoadedAsync(
            _.flatten(
                medicalServiceVersionSelectors.map(vs =>
                    this.careReferenceDataStore.medicalService.get(vs).connectedServiceIds.map(id => new EntityVersionSelector(id, vs.validOn))
                )
            )
        );

        this.sourceCareActivity = await this.careActivityApiAdapter.loadByIdAsync(serviceRequest.starterCareActivityId);

        await this.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync([
            serviceRequest.targetPointOfCareId,
            this.sourceCareActivity.pointOfCareId
        ]);

        await this.organizationReferenceDataStore.ensurePractitionerIdsLoadedAsync([serviceRequest.requesterDoctorId, serviceRequest.targetDoctorId]);

        if (serviceRequest.referralLocation instanceof ExternalCareLocation) {
            await this.organizationReferenceDataStore.externalLocationStore.ensureLoadedAsync([serviceRequest.referralLocation.externalLocationSelector]);
        } else if (serviceRequest.referralLocation instanceof InternalCareLocation) {
            await this.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync([serviceRequest.referralLocation.pointOfCareId]);
        }

        const serviceRequestDefinition = await this.careReferenceDataStore.serviceRequestDefinition.getOrLoadAsync(serviceRequest.serviceRequestDefinitionVersion);
        await
            this.careReferenceDataStore.condition.ensureLoadedAsync(
                serviceRequest.suspectedDiagnosisList.map(sd => new EntityVersionSelector<DiagnosisId>(
                    sd.conditionId,
                    LocalDate.createFromMoment(serviceRequest.createdAt)
                ))
            );

        // ---
        const contentLoaderPromises = serviceRequest.requestedServices
            .filter(pms =>
                !!pms.textualObservationDefinition &&
                !!pms.textualObservation &&
                !isNullOrUndefined(pms.textualObservation.careContentId))
            .map(async pms => {
                pms.textualObservation.startContentLoading();
                const contentResponse = await this.observationsApiAdapter.getTextualObservationContentAsync(pms.textualObservation.careContentId);
                pms.textualObservation.initializeContent(contentResponse.value);
            });

        await Promise.all(contentLoaderPromises);
        this.setPanelState(serviceRequest, serviceRequestDefinition, isPersisted);
    }

    @State.bound
    private async saveAsync() {
        await this.saveAdministrationAsync();
        this.props.onSaved?.();
    }

    @State.bound
    public async unloadAsync(): Promise<boolean> {
        if (this.serviceRequest) {
            if (this.isDirty) {
                const dialogResult = await this.dialogService.confirmIfNotSaved(StaticCareResources.Common.Dialog.ConfirmationTitle, StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);
                if (dialogResult.resultCode === DialogResultCode.Yes) {
                    await this.saveAsync();
                    return true;
                } else if (dialogResult.resultCode === DialogResultCode.No) {
                    if (this.serviceRequest.lockInfo.lockState === EntityLockState.LockingRequiredAndLockHeld) {
                        await this.lockingApiAdapter.releaseLockAsync(this.serviceRequest.lockInfo.lockId);
                        this.serviceRequest.releaseLock();
                    }

                    return true;
                } else {
                    return false;
                }
            } else {
                if (this.serviceRequest.lockInfo.lockState === EntityLockState.LockingRequiredAndLockHeld) {
                    await this.lockingApiAdapter.releaseLockAsync(this.serviceRequest.lockInfo.lockId);
                    this.serviceRequest.releaseLock();
                }

                return true;
            }
        }
        return true;
    }

    private async modifyServiceRequestExecutionDataAsync() {
        const serviceRequest =
            await this.serviceRequestDataService.getServiceRequestByIdAsync(this.serviceRequest.id, this.careActivityId, false, true);
        if (serviceRequest.operationInfo.hasTechnicalError || !!serviceRequest.operationInfo.businessError) {
            throw new Error();
        }

        if (!PractitionerId.equals(serviceRequest.executingDoctorId, this.serviceRequest.executingDoctorId)) {
            const response = await this.serviceRequestDataService.modifyServiceRequestExecutionDataAsync(
                this.careActivityId,
                this.serviceRequest.executingDoctorId,
                this.serviceRequest.id,
                serviceRequest.rowVersion,
                serviceRequest.lockInfo.lockId,
                false);

            if (response.operationInfo.hasTechnicalError || !!response.operationInfo.businessError) {
                throw new Error();
            }
        }
    }

    private async saveAdministrationAsync() {
        const lockInfo = this.serviceRequest.lockInfo;
        const requestedServices = this.serviceRequest.requestedServices;
        const possibleActions = this.serviceRequest.possibleActions;
        const textualObservation = requestedServices.toJS()[0];
        let completeDiagnosticReportResponse: ServiceRequestStore = null;
        let isCompleteDiagnosticReportPersisted: boolean = false;
        let isExecuteRequestedServiceActionsPersisted: boolean = false;

        try {
            if (this.serviceRequest.requestedServices.filter(s => s.isCompleteDiagnosticReportAllowed).length > 0) {
                completeDiagnosticReportResponse = await this.serviceRequestDataService.completeDiagnosticReportAsync(
                    this.careActivityId,
                    this.serviceRequest,
                    this.serviceRequest.lockInfo.lockId,
                    false,
                    false
                );

                if (completeDiagnosticReportResponse.operationInfo.hasTechnicalError || !!completeDiagnosticReportResponse.operationInfo.businessError) {
                    throw new Error();
                }

                State.runInAction(() => {

                    this.serviceRequest = completeDiagnosticReportResponse;
                    this.serviceRequest.lockInfo = lockInfo;
                    this.serviceRequest.possibleActions = possibleActions;
                });
            }

            if (requestedServices.filter(i => i.administrationAction !== null).length > 0) {
                const executeResponse = await this.serviceRequestDataService.executeRequestedServiceActionsAsync(
                    this.careActivityId,
                    this.serviceRequest.id,
                    this.serviceRequest.rowVersion,
                    requestedServices.filter(i =>
                        i.administrationAction !== null).map(i => ({ id: i.id, action: i.administrationAction })),
                    this.serviceRequest.lockInfo,
                    false,
                    this.serviceRequest.extensionData
                );

                if (executeResponse.operationInfo.hasTechnicalError || !!executeResponse.operationInfo.businessError) {
                    throw new Error();
                }
                isExecuteRequestedServiceActionsPersisted = executeResponse.isPersistedByOperationInfo;
            }

            await this.modifyServiceRequestExecutionDataAsync();

            isCompleteDiagnosticReportPersisted = completeDiagnosticReportResponse?.isPersistedByOperationInfo;

            if (isCompleteDiagnosticReportPersisted || isExecuteRequestedServiceActionsPersisted) {
                this.notificationService.success(StaticCareResources.Common.Message.SaveSuccessful);
            }
        } catch (err) {
            this.notificationService.error(StaticCareResources.Common.Message.OperationError);
        }

        await this.loadPanelAsync(false, isCompleteDiagnosticReportPersisted || isExecuteRequestedServiceActionsPersisted);
    }

    @State.action
    private setPanelState(
        serviceRequest: ServiceRequestStore,
        serviceRequestDefinition: ServiceRequestDefinition,
        isPersisted: boolean
    ) {

        if (isPersisted) {
            this.serviceRequest = serviceRequest;
        }

        this.serviceRequest.takeSnapshot();

        this.serviceRequestDefinition = serviceRequestDefinition;
    }

    @State.action.bound
    private setSelectedRequestedService(isChecked: boolean, service: RequestedServiceStore) {
        this.batchSelection = null;

        const selectedIds = Array.from(this.selectedMedicalServiceIdSet.values());
        this.selectedMedicalServiceIdSet = isChecked
            ? new Set<string>([...selectedIds, service.medicalServiceVersionSelector.id.value])
            : new Set<string>(selectedIds.filter(i => i !== service.medicalServiceVersionSelector.id.value));
    }

    @State.bound
    private async setAdministrativeActionAsync(item: RequestedServiceStore, newAction: RequestedServiceAction) {
        if (
            newAction === RequestedServiceAction.CancelCompleteActivity ||
            newAction === RequestedServiceAction.CancelCompleteDiagnosticReport ||
            newAction === RequestedServiceAction.CancelRegisterAsReceived ||
            newAction === RequestedServiceAction.CancelValidateDiagnosticReport ||
            newAction === RequestedServiceAction.DeleteForExecutorBeforeCompletion ||
            newAction === RequestedServiceAction.DeleteForExecutorAfterCompletion
        ) {
            const service = this.props._dependencies.careReferenceDataStore.medicalService.get(item.medicalServiceVersionSelector);

            const reason = await this.modalService.showDialogAsync<IStatusChangeReason>(new StatusChangeReasonDialogParams(
                StatusChangeReasonTypeId.RequestedService,
                null,
                <Ui.InfoBox iconName="info_with_circle" iconVisualStyle="primary">{this.info}</Ui.InfoBox>,
                service.alternativeName || service.name,
            ));

            if (reason !== null) {
                item.setAdministrationAction(newAction, reason);
            }
        } else {
            item.setAdministrationAction(newAction, null);
        }
    }

    @State.bound
    private async printAsync() {
        const doc = await this.documentApiAdapter.getSingleDocumentForServiceRequestAsync(
            this.serviceRequest.id,
            this.careActivityId,
            this.serviceRequestDefinition.reportDocumentTypeCode,
            null,
            true);

        if (!doc.value) {
            this.notificationService.info(StaticWebAppResources.NewServiceRequestPage.DocumentDoesNotExist);
        } else {
            await this.modalService.showModalAsync(new DocumentPreviewModalParams(doc.value.id));
        }
    }

    @State.action.bound
    private setBatchSelection(newValue: RequestedServiceBatchSelection) {
        this.batchSelection = newValue;

        const stateToCheck = (() => {
            switch (this.batchSelection) {
                case RequestedServiceBatchSelection.Received:
                    return RequestedServiceState.RegisteredAsReceived;
                case RequestedServiceBatchSelection.Requested:
                    return RequestedServiceState.Submitted;
                case RequestedServiceBatchSelection.CompletedNotValidated:
                    return RequestedServiceState.NonValidatedCompleted;
                case RequestedServiceBatchSelection.CompletedValidated:
                    return RequestedServiceState.ActivityCompleted;
            }
            return null;
        })();

        let servicesToSelect: RequestedServiceStore[] = [];

        if (this.batchSelection === RequestedServiceBatchSelection.All) {
            servicesToSelect = this.serviceRequest.requestedServices;
        } else if (stateToCheck !== null) {
            servicesToSelect = this.serviceRequest.requestedServices.filter(ms => ms.state === stateToCheck);
        }

        this.selectedMedicalServiceIdSet = new Set<string>(servicesToSelect.map(i => i.medicalServiceVersionSelector.id.value));
    }

    @State.action.bound
    private async setBatchReceiveAsync() {
        await this.setBatchActionAsync(
            this.getSelectedRequestedServices().filter(item => item.possibleActions.includes(RequestedServiceAction.RegisterAsReceived)),
            RequestedServiceAction.RegisterAsReceived
        );
    }

    @State.action.bound
    private async setBatchCompleteAsync() {
        await this.setBatchActionAsync(
            this.getSelectedRequestedServices().filter(item => item.possibleActions.includes(RequestedServiceAction.CompleteActivity)),
            RequestedServiceAction.CompleteActivity
        );
    }

    @State.action.bound
    private async setBatchValidateAsync() {
        await this.setBatchActionAsync(
            this.getSelectedRequestedServices().filter(item => item.possibleActions.includes(RequestedServiceAction.ValidateDiagnosticReport)),
            RequestedServiceAction.ValidateDiagnosticReport
        );
    }

    @State.action.bound
    private openForEditAsync() {
        return this.loadPanelAsync(true);
    }

    @State.action.bound
    private async onCloseAsync() {
        await this.unloadAsync();

        this.props.onClose?.();
    }

    private getSelectedRequestedServices() {
        return this.serviceRequest.requestedServices.filter(row =>
            this.selectedMedicalServiceIdSet.has(row.medicalServiceVersionSelector.id.value)
        );
    }

    private async setBatchActionAsync(items: RequestedServiceStore[], action: RequestedServiceAction) {

        const negativeActions = [
            RequestedServiceAction.CancelCompleteActivity,
            RequestedServiceAction.CancelCompleteDiagnosticReport,
            RequestedServiceAction.CancelRegisterAsReceived,
            RequestedServiceAction.CancelValidateDiagnosticReport,
            RequestedServiceAction.DeleteForExecutorBeforeCompletion,
            RequestedServiceAction.DeleteForExecutorAfterCompletion
        ];

        let reason = null as IStatusChangeReason;

        if (negativeActions.includes(action)) {
            reason = await this.modalService.showDialogAsync<IStatusChangeReason>(new StatusChangeReasonDialogParams(
                StatusChangeReasonTypeId.RequestedService
            ));
        }

        items.forEach(i => i.setAdministrationAction(
            action,
            reason
        ));
    }

    public render() {
        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }

        if (!this.serviceRequest) {
            return null;
        }

        return (
            <>
                <ValidationBoundary
                    onValidateAsync={this.onValidateAsync}
                    validationResults={this.serviceRequest.validationResults}
                    mapEntityId>
                    <ServiceAdministrationPanelView
                        isAdministration={this.isAdministration}
                        requestedServices={this.serviceRequest.requestedServices}
                        onSaveAsync={this.saveAsync}
                        onChecked={this.setSelectedRequestedService}
                        onSetAdministrativeActionAsync={this.setAdministrativeActionAsync}
                        serviceRequestDefinitionName={this.serviceRequestDefinition.name}
                        serviceRequest={this.serviceRequest}
                        onCloseAsync={this.onCloseAsync}
                        onPrintAsync={this.printAsync}
                        batchSelection={this.batchSelection}
                        onBatchSelectionChange={this.setBatchSelection}
                        selectedMedicalServiceIdSet={this.selectedMedicalServiceIdSet}
                        onBatchReceiveAsync={this.setBatchReceiveAsync}
                        onBatchCompleteAsync={this.setBatchCompleteAsync}
                        onBatchValidateAsync={this.setBatchValidateAsync}
                        withoutPageBox={this.props.withoutPageBox}
                        hasNoVerticalMargin={this.props.hasNoVerticalMargin}
                        buttons={this.props.buttons}
                        hidePortalButtons={this.props.hidePortalButtons}
                        isLoading={this.isLoading}
                        openForEditAsync={this.openForEditAsync}
                        isReadOnly={this.props.isReadOnly}
                        careActivityId={this.careActivityId}
                    />
                </ValidationBoundary>
                <EventHandler event={this.props.refreshEvent} onFired={this.initialLoadPanelSync} />
                <NavigateAwayHook onNavigateAwayAsync={this.unloadAsync} />
            </>
        );
    }

    @State.action.bound
    private async onValidateAsync() {
        const response = await this.serviceRequestDataService.completeDiagnosticReportAsync(
            this.careActivityId,
            this.serviceRequest,
            this.serviceRequest.lockInfo.lockId,
            false,
            true
        );
        return response.validationResults;
    }
}

export default connect(
    ServiceAdministrationPanel,
    new DependencyAdapter<IServiceAdministrationPanelProps, IServiceAdministrationPanelDependencies>(container => ({
        serviceRequestDataService: container.resolve("IServiceRequestDataService"),
        observationDefinitionApiAdapter: container.resolve("ObservationDefinitionApiAdapter"),
        careReferenceDataStore: container.resolve("CareReferenceDataStore"),
        localizationService: container.resolve("ILocalizationService"),
        observationsApiAdapter: container.resolve("ObservationsApiAdapter"),
        organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
        careActivityApiAdapter: container.resolve("CareActivityApiAdapter2"),
        careActivityBaseDataApiAdapter: container.resolve("CareActivityBaseDataApiAdapter"),
        notificationService: container.resolve("INotificationService"),
        dialogService: container.resolve("IDialogService"),
        lockingApiAdapter: container.resolve("LockingApiAdapter"),
        documentApiAdapter: container.resolve("ServiceRequestDocumentApiAdapter"),
    })),
    new CareActivityContextAdapter((ctx, props) => ({ currentCareActivityId: props.currentCareActivityId ?? ctx.careActivityId })),
    new HisModalServiceAdapter()
);
