import FormFieldDataBase from "./FormFieldDataBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";

export default class TimeFormFieldData extends FormFieldDataBase {
    public isArray: boolean = false;
    @State.observable.ref public value: TimeOfDay = null;

    constructor(fieldName: string, isReadonly: boolean, isVisible: boolean, value: TimeOfDay) {
        super(fieldName, isReadonly, isVisible);

        this.value = value;
    }
}