import EhiToken from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiToken";
import Di from "@Di";
import moment from "moment";
import IEhiTokenRepository from "./IEhiTokenRepository";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import UserId from "@Primitives/UserId.g";

@Di.injectable()
export default class EhiTokenRepository implements IEhiTokenRepository {

    private storageKey = "ehi_token";

    public getToken(): EhiToken {
        const token = sessionStorage.getItem(this.storageKey);
        if (token) {
            const parsedToken = JSON.parse(token);
            const readToken = {
                ...parsedToken,
                validFrom: moment(parsedToken.validFrom),
                validTo: moment(parsedToken.validTo),
                userId: UserId.fromJS(parsedToken.userId)
            };

            const now = DateTimeService.now();

            if (now.isBetween(readToken.validFrom, readToken.validTo)) {
                return readToken;
            } else {
                return null;
            }

        }
        return null;
    }

    public setToken(token: EhiToken) {
        if (token) {
            const tokenJson = JSON.stringify({
                ...token,
                userId: token.userId.toJSON()
            });
            
            sessionStorage.setItem(this.storageKey, tokenJson);
        }
    }

    public clearToken() {
        sessionStorage.removeItem(this.storageKey);
    }
}