import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import {dispatchAsyncErrors} from "@Toolkit/CommonWeb/AsyncHelpers";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import IMedicalServiceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalServiceVersion";
import * as Ui from "@CommonControls";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";
import MedicalServiceId, {IMedicalServiceVersionSelector} from "@Primitives/MedicalServiceId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import * as Styles from "./RequestedServicesBody.less";

interface IExecutingProvidedMedicalServicesBodyDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IExecutingProvidedMedicalServicesBodyProps {
    _dependencies?: IExecutingProvidedMedicalServicesBodyDependencies;
    rawJsonValue: string;
}

interface IRequestedServiceItem {
    identifier: string;
    medicalServiceVersionSelector: IMedicalServiceVersionSelector;
    medicalService: IMedicalServiceVersion;
}

@State.observer
class RequestedServicesBody extends React.Component<IExecutingProvidedMedicalServicesBodyProps> {

    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }

    @State.observable.ref private requestedServices: IRequestedServiceItem[] = null;

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IExecutingProvidedMedicalServicesBodyProps) {
        if (this.props.rawJsonValue !== prevProps.rawJsonValue) {
            this.loadAsync();
        }
    }

    private async loadAsync() {

        const rawValue = JSON.parse(this.props.rawJsonValue);

        const requestedServices: IRequestedServiceItem[] = rawValue && rawValue.map((it: any) => ({
            identifier: it.RequestedServiceIdentifier,
            medicalServiceVersionSelector: new EntityVersionSelector<MedicalServiceId>(MedicalServiceId.fromJS(it.MedicalServiceVersionSelector.EntityId), LocalDate.fromJS(it.MedicalServiceVersionSelector.ValidOn)),
            medicalService: null
        } as IRequestedServiceItem));

        if (!!requestedServices) {
            const medicalServiceSelectors = requestedServices.map(s => s.medicalServiceVersionSelector);
            await this.careReferenceDataStore.medicalService.ensureLoadedAsync(medicalServiceSelectors);

            requestedServices.forEach(srv => {
                srv.medicalService = this.careReferenceDataStore.medicalService.get(srv.medicalServiceVersionSelector);
            });

            this.set(requestedServices);
        }
    }

    @State.action
    private set(requestedServices: IRequestedServiceItem[]) {
        this.requestedServices = requestedServices;
    }

    private get iconName() {
        return this.requestedServices.every(item => !item.identifier) ? "editService" : "stethoscope";
    }

    public render() {
        if (!this.requestedServices) {
            return null;
        }

        const providedServiceNames = this.requestedServices.map(s => `${s.identifier} ${s.medicalService.name}`).join(", ");

        return (
            <div className={Styles.rowBody}>
                <Ui.Icon iconName={this.iconName} />&nbsp;
                <Popper
                    tooltipPlacement="bottom-start"
                    tooltipContent={providedServiceNames}
                    tooltipStyle={{ textAlign: "left" }}
                >
                    {this.requestedServices.map((s, i) => [
                        i > 0 && ", ",
                        <><b>{s.identifier || s.medicalService.code.value}</b> {(s.medicalService.alternativeName || s.medicalService.name)}</>
                    ])}
                </Popper>
            </div>
        );
    }
}

export default connect(
    RequestedServicesBody,
    new DependencyAdapter<IExecutingProvidedMedicalServicesBodyProps, IExecutingProvidedMedicalServicesBodyDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
    }))
);
