/* auto-inject-disable */
import ApiAdapterBase2 from "./ApiAdapterBase2";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import IMapperService from "@HisPlatform/Services/Definition/MapperService/IMapperService";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import { IPatientsClient } from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import IFrontendListDataWithDefinition from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/IFrontendListDataWithDefinition";
import PatientId from "@Primitives/PatientId.g";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import IAgeCalculationService from "@HisPlatform/Services//Definition/AgeCalculation/IAgeCalculationService";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import { mapProxyFormFieldDataAsync } from "@HisPlatform/BoundedContexts/FormEngine/ApplicationLogic/ApiAdapter/FormEngineMappers";
import PatientNameAndIdentifierData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientNameAndIdentifierData";
import IPatientNameAndIdentifierData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientNameAndIdentifierData";
import NameStore from "@Primitives/NameStore";

@Di.injectable()
export default class PatientApiAdapter2 extends ApiAdapterBase2 {
    constructor(
        @Di.inject("IPatientsClient") private readonly apiClient: Proxy.IPatientsClient,
        @Di.inject("IMapperService") private readonly mapperService: IMapperService,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("IFormEngineReferenceDataStore") private readonly formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("IAgeCalculationService") private readonly ageCalculationService: IAgeCalculationService
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public searchPatientAsync(frontendListParameters: FrontendListParameters) {
        return this.executeOperationAsync<IFrontendListDataWithDefinition, Proxy.SearchPatientsQueryResponse>(
            () => this.apiClient.searchPatientsQueryAsync(CreateRequestId(), new Proxy.SearchPatientsControllerDto({
                filters: this.mapperService.mapByName("FilterDtoArray", frontendListParameters),
                pagingAndOrderings: this.mapperService.mapByName("QueryPagingsAndOrderings", frontendListParameters),
                requestMode: frontendListParameters.requestMode
            })),
            response => {
                return this.mapperService.map<Proxy.FrontendListDto, IFrontendListDataWithDefinition>(response.frontendList);
            }
        );
    }

    public loadByIdAsync(id: PatientId) {
        return this.executeOperationAsync<PatientAdministrativeData, Proxy.GetPatientAdministrativeDataCommandResponse>(
            () => this.apiClient.getPatientAdministrativeDataCommandAsync(CreateRequestId(), new Proxy.GetPatientAdministrativeDataControllerDto({
                patientId: id,
                requestLock: false
            })),
            async response => {
                const mappedFormFieldData: FormFieldDataBase[] = [];
                await mapProxyFormFieldDataAsync(response.patientAdministrativeData.data as any, mappedFormFieldData, this.formEngineReferenceDataStore);

                return new PatientAdministrativeData(mappedFormFieldData, response.patientAdministrativeData.rowVersion, this.ageCalculationService);
            }
        );
    }

    public getPatientNameAndIdentifierAsync(patientId: PatientId) {
        return this.executeOperationAsync<IPatientNameAndIdentifierData, Proxy.GetPatientNameAndIdentifierByIdQueryResponse>(
            () => this.apiClient.getPatientNameAndIdentifierByIdQueryAsync(CreateRequestId(), patientId.value),
            response => Promise.resolve({
                name: NameStore.create({
                    familyName: response.result.name.familyName,
                    givenName1: response.result.name.givenName1,
                    givenName2: response.result.name.givenName2,
                    postfix: response.result.name.postfix,
                    prefix: response.result.name.prefix
                }),
                identifier: response.result.identifier
            } as IPatientNameAndIdentifierData)
        );
    }
}
