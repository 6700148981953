import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import StoreBase from "@Toolkit/CommonWeb/Model/StoreBase";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import ILocalizedObject from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalization";
import AsyncMessageQueue from "@Toolkit/CommonWeb/AsyncMessageQueue";

/** @deprecated Do we need this? */
export default class LocalizedReferenceDatStore<TId extends IStringEntityId, TStore extends { id: IStringEntityId }> extends StoreBase {
    @State.observable private map = new Map<string, ILocalizedEntity<TStore>>();
    private messageQueue = new AsyncMessageQueue<TId[]>(this._ensureLoadedAsync);
    private allLoaded = false;

    @State.computed public get items() {
        return Array.from(this.map.values());
    }

    @State.bound
    public async ensureLoadedAsync(ids: TId[]) {
        const notNulls = ids?.filter(Boolean);
        if (notNulls?.length > 0) {
            await this.messageQueue.enqueueAndProcessAsync(notNulls);
        }
    }

    @State.bound
    public async _ensureLoadedAsync(ids: TId[]) {

        let newIds = ids.filter(id => !!id && isNullOrUndefined(this.map.get(id.value)));
        newIds = _.uniqBy(newIds, id => id.value);

        if (newIds.length === 0) {
            return;
        }

        const newItemsStore = await this.loadAsync(newIds);
        this.updateOperationInfoFromStore(newItemsStore);

        if (!this.operationWasSuccessful) {
            return;
        }

        State.runInAction(() => {
            newItemsStore.value.forEach((item) => {
                this.map.set(item.id.value, {
                    Entity: item,
                    Localization: this.localizer(this.getLocalizationId(item), this.typeName)
                });
            });
        });
    }

    public get(id: TId): TStore {
        if (isNullOrUndefined(id)) {
            return null;
        }
        return this.map.get(id.value).Entity || null;
    }

    public has(id: TId): boolean {
        if (isNullOrUndefined(id)) {
            return false;
        }
        return this.map.has(id.value);
    }

    public async getOrLoadAsync(id: TId): Promise<TStore> {
        await this.ensureLoadedAsync([id]);
        return this.get(id);
    }

    @State.bound
    public async ensureAllLoadedAsync() {
        if (this.allLoaded) {
            return;
        }
        
        let ids: TId[] = [];
        const result = this.loadAllIdsAsync && await this.loadAllIdsAsync();
        if (result && result.value) {
            ids = result.value;
        }
        await this.ensureLoadedAsync(ids);
        this.allLoaded = true;
    }

    public getLocalization(id: TId): ILocalizedObject {
        if (isNullOrUndefined(id)) {
            return null;
        }
        return this.map.get(id.value)?.Localization ?? null;
    }

    public async getOrLoadLocalizationAsync(id: TId): Promise<ILocalizedObject> {
        await this.ensureLoadedAsync([id]);
        return this.getLocalization(id);
    }

    constructor(
        private readonly loadAsync: (ids: TId[]) => Promise<SimpleStore<TStore[]>>,
        private readonly localizer: (valueName: string, typeName: string) => ILocalizedObject,
        private readonly getLocalizationId: (item: TStore) => string,
        private readonly typeName: string,
        public readonly loadAllIdsAsync?: () => Promise<SimpleStore<TId[]>>
    ) {
        super();
    }
}

interface ILocalizedEntity<TEntity> {
    Entity: TEntity;
    Localization: ILocalizedObject;
}