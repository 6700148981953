
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import WorklistDefinitionMasterDetailPanelStore from "./WorklistDefinitionMasterDetailPanelStore";

const { 
    ContextComponent: WorklistDefinitionMasterDetailPanelStoreContext, 
    StoreProvider: WorklistDefinitionMasterDetailPanelStoreProvider, 
    useStore: useWorklistDefinitionMasterDetailPanelStore,
    provideStore: provideWorklistDefinitionMasterDetailPanelStore,
} = createPanelStoreProvider(WorklistDefinitionMasterDetailPanelStore, () => ({
    unauthorizedPageTitle: StaticProductivityResources.Worklist.Definition.EditorTitle
}));

export {
    WorklistDefinitionMasterDetailPanelStoreContext,
    WorklistDefinitionMasterDetailPanelStoreProvider,
    useWorklistDefinitionMasterDetailPanelStore,
    provideWorklistDefinitionMasterDetailPanelStore
};