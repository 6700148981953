import FilterBase from "./FilterBase";

export default class CodeStartsWithFilter extends FilterBase {
    private _value: string;

    constructor(value: string) {
        super();
        this._value = value;
    }

    public get value(): string {
        return this._value;
    }

    public setValue(identifier: string) {
        this._value = identifier;
    }
}