import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import TemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TemplateBasedDocument";
import CareActivityId from "@Primitives/CareActivityId.g";
import ITemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ITemplateBasedDocument";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import CareActivityTextBlock from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/CareActivityTextBlock";
import { applyToDocument, applyToDocumentWithLockInfo, getDocumentInfo } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentStoreMapper";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import _ from "@HisPlatform/Common/Lodash";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import NewBinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/Creation/NewBinaryDocument";
import DocumentId from "@Primitives/DocumentId.g";
import DocumentInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentInfo";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import { getBinaryDocumentDtoAsync, getDocumentsForCareActivityDto, getDocumentsForPatientDto, getSaveTemplatePermissionCheckDocumentDto, getSingleDocumentDto, getTemplateBasedDocumentDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentDtoMapper";
import PatientId from "@Primitives/PatientId.g";
import DocumentBase from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentBase";
import BinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/BinaryDocument";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import LargeDataToken from "@Primitives/LargeDataToken.g";
import DocumentKind from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentKind.g";
import * as WebAppBackendProxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";

@Di.injectable()
export default class CareActivityDocumentApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("ICareRegisterClient") private readonly apiClient: Proxy.ICareRegisterClient,
        @Di.inject("ICareClient") private readonly careWebAppClient: WebAppBackendProxy.ICareClient,
        @Di.inject("IPatientsClient") private readonly patientsWebAppClient: WebAppBackendProxy.IPatientsClient,
        @Di.inject("ValidationProblemParameterMapperService") private readonly validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super();
    }

    public saveNewCareActivityRelatedTemplateBasedDocumentAsync(templateBasedDocument: TemplateBasedDocument, careActivityId: CareActivityId) {
        return this.processOperationAsync(
            new SimpleStore<ITemplateBasedDocument>(),
            async target => {
                const response = await this.careWebAppClient.saveNewCareActivityRelatedTemplateBasedDocumentCommandAsync(
                    CreateRequestId(),
                    new WebAppBackendProxy.SaveNewCareActivityRelatedTemplateBasedDocumentControllerDto({
                        careActivityId: careActivityId,
                        templateBasedDocumentDto: getTemplateBasedDocumentDto(templateBasedDocument)
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    documentInfo: getDocumentInfo(response.documentInfo),
                    validationResult: mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult)
                } as ITemplateBasedDocument;

                const validationProblems = _.flatten(target.value.validationResult?.map(i => i.problems));
                await this.validationProblemParameterMappingService.loadCollectedValidationProblemParameterReferenceDataAsync("CareActivityTextBlock");
                validationProblems.map(i => this.validationProblemParameterMappingService.resolveValidationProblemParameters("CareActivityTextBlock", i));
            }
        );
    }

    public saveNewPatientRelatedTemplateBasedDocumentAsync(templateBasedDocument: TemplateBasedDocument, patientId: PatientId) {
        return this.processOperationAsync(
            new SimpleStore<ITemplateBasedDocument>(),
            async target => {
                const response = await this.patientsWebAppClient.saveNewPatientRelatedTemplateBasedDocumentCommandAsync(
                    CreateRequestId(),
                    new WebAppBackendProxy.SaveNewPatientRelatedTemplateBasedDocumentControllerDto({
                        patientId: patientId,
                        templateBasedDocumentDto: getTemplateBasedDocumentDto(templateBasedDocument)
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    documentInfo: getDocumentInfo(response.documentInfo),
                    validationResult: mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult)
                } as ITemplateBasedDocument;

                const validationProblems = _.flatten(target.value.validationResult?.map(i => i.problems));
                await this.validationProblemParameterMappingService.loadCollectedValidationProblemParameterReferenceDataAsync("CareActivityTextBlock");
                validationProblems.map(i => this.validationProblemParameterMappingService.resolveValidationProblemParameters("CareActivityTextBlock", i));
            }
        );
    }

    public saveNewCareActivityRelatedTemplateBasedDocumentPermissionCheckAsync(careActivityId: CareActivityId) {
        return this.processOperationAsync(
            new SimpleStore<ITemplateBasedDocument>(),
            async target => {
                const response = await this.careWebAppClient.saveNewCareActivityRelatedTemplateBasedDocumentCommandAsync(
                    CreateRequestId(),
                    getSaveTemplatePermissionCheckDocumentDto(careActivityId),
                    true
                );

                target.operationInfo = createOperationInfo(response);
            },
        );
    }

    public saveNewCareActivityRelatedBinaryDocumentCommandAsync(newDocument: NewBinaryDocument, careActivityId: CareActivityId) {
        return this.processOperationAsync(
            new SimpleStore<DocumentId>(),
            async target => {
                const dto = new WebAppBackendProxy.SaveNewCareActivityRelatedBinaryDocumentControllerDto({
                    careActivityId: careActivityId,
                    binaryDocumentDto: await getBinaryDocumentDtoAsync(newDocument)
                });

                const response = await this.careWebAppClient.saveNewCareActivityRelatedBinaryDocumentCommandAsync(
                    CreateRequestId(),
                    dto
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.documentInfo?.id;
            }
        );
    }

    public saveNewPatientRelatedBinaryDocumentCommandAsync(newDocument: NewBinaryDocument, patientId: PatientId) {
        return this.processOperationAsync(
            new SimpleStore<DocumentId>(),
            async target => {
                const dto = new WebAppBackendProxy.SaveNewPatientRelatedBinaryDocumentControllerDto({
                    patientId: patientId,
                    binaryDocumentDto: await getBinaryDocumentDtoAsync(newDocument)
                });
                
                const response = await this.patientsWebAppClient.saveNewPatientRelatedBinaryDocumentCommandAsync(
                    CreateRequestId(),
                    dto
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.documentInfo?.id;
            }
        );
    }

    public getSingleDocumentForCareActivityAsync(careActivityId: CareActivityId, documentTypeCode?: string, isPrimary?: boolean, isAutomatic?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<DocumentInfo>(),
            async target => {
                const response = await this.apiClient.getSingleDocumentForCareActivityQueryAsync(
                    CreateRequestId(),
                    getSingleDocumentDto(careActivityId, documentTypeCode, isPrimary, isAutomatic)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = getDocumentInfo(response.document as unknown as WebAppBackendProxy.DocumentInfoDto);
            }
        );
    }

    public getCareActivityDocumentListAsync(careActivityId: CareActivityId, ordering: IOrderingState, paging: IPagingState, showDeleted: boolean) {
        return this.processOperationAsync(
            new PagedItemStore<DocumentInfo>(),
            async target => {
                const response = await this.apiClient.getDocumentsForCareActivityQueryAsync(
                    CreateRequestId(),
                    getDocumentsForCareActivityDto(careActivityId, ordering, paging, showDeleted),
                );

                target.operationInfo = createOperationInfo(response);
                target.totalCount = response.results.totalCount;
                target.items = response.results.values.map(x => x as unknown as WebAppBackendProxy.DocumentInfoDto).map(getDocumentInfo);
            }
        );
    }

    public getPatientDocumentListAsync(patientId: PatientId, isCareActivityRelated: boolean, ordering: IOrderingState, paging: IPagingState, showDeleted: boolean, dateRange?: LocalDateRange) {
        return this.processOperationAsync(
            new PagedItemStore<DocumentInfo>(),
            async target => {
                const response = await this.apiClient.getDocumentsForPatientQueryAsync(
                    CreateRequestId(),
                    getDocumentsForPatientDto(patientId, isCareActivityRelated, ordering, paging, showDeleted, dateRange)
                );

                target.operationInfo = createOperationInfo(response);
                target.totalCount = response.results.totalCount;
                target.items = response.results.values.map(x => x as unknown as WebAppBackendProxy.DocumentInfoDto).map(getDocumentInfo);
            }
        );
    }

    public getCareActivityDocumentById(id: DocumentId, careActivityId: CareActivityId, requestLock: boolean = false) {
        return this.processOperationAsync(
            new SimpleStore<DocumentBase>(),
            async target => {
                const response = await this.apiClient.getCareActivityDocumentByIdCommandAsync(
                    CreateRequestId(),
                    new Proxy.GetCareActivityDocumentByIdControllerDto({
                        careActivityId: careActivityId,
                        requestLock: requestLock,
                        documentId: id
                    })
                );

                if (response.document.document.documentKind === DocumentKind.Binary) {
                    target.value = new BinaryDocument();
                } else if (response.document.document.documentKind === DocumentKind.TemplateBased) {
                    target.value = new TemplateBasedDocument();
                }

                applyToDocumentWithLockInfo(target.value, response);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public getEhrDocumentByIdAsync(id: DocumentId, forceBinary: boolean = false, isPermissionCheckOnly: boolean = false) {
        return this.processOperationAsync(
            new SimpleStore<DocumentBase>(),
            async target => {
                const response = await this.apiClient.getEhrDocumentByIdQueryAsync(
                    CreateRequestId(),
                    id.value,
                    forceBinary.toString(),
                    isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);

                if (isPermissionCheckOnly) {
                    return;
                }
                
                if (response.document.document.documentKind === DocumentKind.Binary) {
                    target.value = new BinaryDocument();
                } else if (response.document.document.documentKind === DocumentKind.TemplateBased) {
                    target.value = new TemplateBasedDocument();
                }

                applyToDocument(target.value, response.document);
            }
        );
    }

    public getCareDocumentContentById(documentId: DocumentId, largeDataId: LargeDataToken) {
        return this.processOperationAsync(
            new SimpleStore<{content: string, mediaType: string, fileName: string}>(),
            async target => {
                const response = await this.apiClient.getCareDocumentContentByIdQueryAsync(
                    CreateRequestId(),
                    documentId.value,
                    largeDataId.value
                );

                target.value = { mediaType: response.documentContentDto.mediaType, content: response.documentContentDto.content, fileName: response.documentContentDto.fileName };
                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}