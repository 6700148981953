import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import { RadioButtonGroupFactory, IRadioGroupBaseProps } from "@CommonControls/RadioButtonGroup";
import GenderId from "@Primitives/GenderId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";


const GenderRadioButton = RadioButtonGroupFactory<GenderId>();

interface IGenderRadioButtonGroupDependencies {
    commonReferenceDataStore: CommonReferenceDataDataStore;
}

interface IGenderRadioButtonGroupProps extends IRadioGroupBaseProps<GenderId> {
    _dependencies?: IGenderRadioButtonGroupDependencies;
}

@State.observer
class GenderRadioButtonGroup extends React.Component<IGenderRadioButtonGroupProps> {

    @State.bound
    private async loadAsync() {
        await this.props._dependencies.commonReferenceDataStore.gender.ensureLoadedAsync();
    }

    @State.computed
    private get items() {
        return this.props._dependencies.commonReferenceDataStore.gender.items.map(i => i.id);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private getGenderIcon(value: GenderId) {
        if (value.value === "Male") {
            return "male";
        } else {
            return "female";
        }
    }

    private getGenderIconStyle(value: GenderId): React.CSSProperties {
        if (value.value === "Male") {
            return {fill: "#00B4EC"};
        } else {
            return {fill: "#CE5374"};
        }
    }

    @State.bound
    private getDisplayValue(value: GenderId) {
        return this.props._dependencies.commonReferenceDataStore.gender.get(value).displayValue.Name;
    }

    public render() {       
        return (
            <GenderRadioButton
                displayType="groupedButtons"
                automationId="genderRadioButtonGroup"
                items={this.items}
                getIconName={this.getGenderIcon}
                getDisplayValue={this.getDisplayValue}
                getIconStyle={this.getGenderIconStyle}
                {...this.props}
            />
        );
    }
}

export default connect(
    GenderRadioButtonGroup,
    new DependencyAdapter<IGenderRadioButtonGroupProps, IGenderRadioButtonGroupDependencies>(container => {
        return {
            commonReferenceDataStore: container.resolve("CommonReferenceDataDataStore")
        };
    })
);