import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import { SelectBox } from "@CommonControls";
import StatusChangeReasonId from "@Primitives/StatusChangeReasonId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import StatusChangeReasonTypeId from "@Primitives/StatusChangeReasonTypeId.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import StatusChangeReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/StatusChangeReason";

interface IStatusChangeReasonSelectBoxDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IStatusChangeReasonSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IStatusChangeReasonSelectBoxDependencies;
    value: StatusChangeReasonId;
    onChange: (newValue: StatusChangeReasonId) => void;
    statusChangeReasonTypeId: StatusChangeReasonTypeId;
}

@State.observer
class StatusChangeReasonSelectBox extends React.Component<IStatusChangeReasonSelectBoxProps> {

    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }

    @State.observable.ref private items: Array<ISelectBoxItem<StatusChangeReasonId>> = null;

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IStatusChangeReasonSelectBoxProps) {
        if (!ValueWrapper.equals(prevProps.statusChangeReasonTypeId, this.props.statusChangeReasonTypeId)) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    private async loadAsync() {
        const items = await this.careReferenceDataStore.statusChangeReason.getOrLoadByTypeIdAsync(this.props.statusChangeReasonTypeId);
        if (items) {
            this.initialize(items);
        }
    }

    @State.action
    private initialize(items: StatusChangeReason[]) {
        this.items = items.map(i => ({
            text: i.name,
            value: i.id
        } as ISelectBoxItem<StatusChangeReasonId>));
    }

    public render() {
        return (
            <SelectBox
                {...this.props}
                items={this.items}
                orderItems={false}
                automationId={this.props.automationId}
            />
        );
    }
}

export default connect(
    StatusChangeReasonSelectBox,
    new DependencyAdapter<IStatusChangeReasonSelectBoxProps, IStatusChangeReasonSelectBoxDependencies>(c => ({
        careReferenceDataStore: c.resolve<CareReferenceDataStore>("CareReferenceDataStore"),
    }))
);