import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import PerformedServiceListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/PerformedServiceListStore";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export function getUpdatePerformedServicesDto(
    performedServices: PerformedServiceListStore,
    releaseLock: boolean
): Proxy.UpdatePerformedServicesControllerDto {
    return new Proxy.UpdatePerformedServicesControllerDto({
        performedServiceList: mapPerformedServiceList(performedServices),
        rowVersion: performedServices.rowVersion,
        lockId: performedServices.lockInfo && performedServices.lockInfo.lockId,
        releaseLockIfSuccessful: releaseLock
    });
}

export function getArrangePerformedServicesDto(store: PerformedServiceListStore) {
    return new Proxy.ArrangePerformedServicesControllerDto({
        careActivityId: store.id,
        performedServiceList: mapPerformedServiceList(store),
        addedPerformedServices: store.addedPerformedServices.map(ps => new Proxy.AddedPerformedServiceDto({
            lateralityId: ps.lateralityId,
            medicalServiceQuantity: ps.medicalServiceQuantity,
            medicalServiceId: ps.medicalServiceId.id,
            performedAt: ps.performedAt,
            extensionData: ps.extensionData
        })),
        deletedFinancingIds: store.deletedFinancingsIds,
        updatedPerformedServices: store.updatedPerformedServices.map(ps => new Proxy.UpdatedPerformedServiceDto({
            id: ps.financingId,
            medicalServiceId: ps.medicalServiceId.id,
            lateralityId: ps.lateralityId,
            medicalServiceQuantity: ps.medicalServiceQuantity,
            performedAt: ps.performedAt
        })),
        updatedFinancedServices: store.updatedFinancedServices.map(fs => new Proxy.UpdatedFinancedServiceDto({
            financingId: fs.financingId,
            financedServiceId: fs.financedServiceId.id,
            extensionData: fs.extensionData,
            quantity: fs.quantity
        })),
        lockId: store.lockInfo && store.lockInfo.lockId,
        releaseLockIfSuccessful: false
    });
}

function mapPerformedServiceList(store: PerformedServiceListStore): Proxy.PerformedServiceListDto {
    return new Proxy.PerformedServiceListDto({
        id: store.id,
        medicalServiceFinancings: store.financings.map(i => new Proxy.MedicalServiceFinancingDto({
            id: i.id,
            financedServicesWithQuantity: i.financedServicesWithQuantities.map(j => new Proxy.FinancedServiceWithQuantityDto({
                financedServiceVersionSelector: new Proxy.EntityVersionSelectorOfFinancedServiceId({
                    entityId: j.financedServiceId.id,
                    validOn: j.financedServiceId.validOn
                }),
                quantity: j.quantity,
                extensionData: j.extensionData
            })),
            performedMedicalServices: i.performedServices.map(j => new Proxy.PerformedMedicalServiceDto({
                id: new Proxy.EntityVersionSelectorOfMedicalServiceId({
                    entityId: j.medicalServiceId.id,
                    validOn: j.medicalServiceId.validOn
                }),
                medicalServiceQuantity: j.medicalServiceQuantity,
                performedAt: j.performedAt,
                performedBy: j.performedBy,
                lateralityId: j.lateralityId,
                partOf: isNullOrUndefined(j.partOf)
                    ? null
                    : new Proxy.PerformedServiceGroupIdentifierDto({
                        careActivityId: j.partOf.careActivityId,
                        performedServiceGroupId: j.partOf.performedServiceGroupId
                    })
            }))
        })),
        performedServiceGroups: [],
        rowVersion: store.rowVersion
    });
}