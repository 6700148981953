import React from "react";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import { LateralitySelectBox } from "@HisPlatform/BoundedContexts/Care/Components";
import SuspectedDiagnosis from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/SuspectedDiagnosis";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ConditionId from "@Primitives/ConditionId.g";
import ConditionCodeSelector from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ConditionCodeSelector";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import ExcludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierSystemIdFilter";

interface IDiagnosisListPanelDependencies {
    dialogService: IDialogService;
    careReferenceDataStore: CareReferenceDataStore;
}
interface IDiagnosisListPanelProps {
    _dependencies?: IDiagnosisListPanelDependencies;
    items: IObservableArray<SuspectedDiagnosis> | SuspectedDiagnosis[];
    readonly?: boolean;
}

class SuspectedDiagnosisListPanel extends React.Component<IDiagnosisListPanelProps> {

    private get dialogService() { return this.props._dependencies.dialogService; }
    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }

    public render() {
        return (
            <ListPanel<SuspectedDiagnosis>
                onCreateNewAsync={this.createNewItemAsync}
                renderItemEditor={this.renderItemEditor}
                actionButtonsOrientation="horizontal"
                items={this.props.items}
                onDropChangesConfirmationAsync={this.dropChangesConfirmationAsync}
                onDeleteItemConfirmationAsync={this.deleteItemConfirmationAsync}
                noItemsMessage={StaticWebAppResources.SuspectedDiagnosisListPanel.NoItemsMessage}
                alwaysEdit
                automationId="__suspectedDiagnoses"
                propertyIdentifier="SuspectedDiagnosisList"
                allowCreatingNew={!this.props.readonly}
            />
        );
    }

    private createNewItemAsync() {
        return Promise.resolve(new SuspectedDiagnosis());
    }

    @State.bound
    private renderItemEditor(item: SuspectedDiagnosis, index: number) {
        const filters: FilterBase[] = [new ExcludeIdentifierSystemIdFilter(IdentifierSystemId.OncologyMorphology)];

        return (
            <State.Observer>{() => (
                <Ui.Flex>
                    <Ui.Flex.Item xs={9}>
                        <ConditionCodeSelector
                            label={StaticWebAppResources.SuspectedDiagnosisListPanel.DiagnosisCode}
                            value={item.conditionId}
                            onChange={item.setConditionId}
                            isReadOnly={this.props.readonly}
                            filters={filters}
                            propertyIdentifier="ConditionId"
                            automationId="__diagnosis"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <LateralitySelectBox
                            label={StaticWebAppResources.SuspectedDiagnosisListPanel.Laterality}
                            value={item.lateralityId}
                            onChange={item.setLateralityId}
                            propertyIdentifier="LateralityId"
                            automationId="__laterality"
                            isReadOnly={this.props.readonly}
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            )}</State.Observer>
        );
    }

    @State.bound
    private async deleteItemConfirmationAsync(item: SuspectedDiagnosis) {
        if (isNullOrUndefined(item.conditionId)) {
            return true;
        }
        const diagnosis = await this.getConditionVersionAsync(item.conditionId);
        const dialogResult = await this.dialogService.yesNo(StaticCareResources.Common.Dialog.ConfirmationTitle, `Biztosan törli a ${diagnosis.code} diagnózist?`);
        return dialogResult.resultCode === DialogResultCode.Yes;
    }

    @State.bound
    private async dropChangesConfirmationAsync(item: SuspectedDiagnosis) {
        if (isNullOrUndefined(item.conditionId)) {
            return true;
        }
        const diagnosis = await this.getConditionVersionAsync(item.conditionId);
        const dialogResult = await this.dialogService.yesNo(StaticCareResources.Common.Dialog.ConfirmationTitle, `A ${diagnosis.code} diagnózis módosítva lett. Biztosan elveti a módosításokat?`);
        return dialogResult.resultCode === DialogResultCode.Yes;
    }

    @State.bound
    private async getConditionVersionAsync(id: ConditionId) {
        const result = await this.careReferenceDataStore.condition.getOrLoadAsync({
            id: id,
            validOn: LocalDate.today()
        });
        return result;
    }
}

export default connect(
    SuspectedDiagnosisListPanel,
    new DependencyAdapter<IDiagnosisListPanelProps, IDiagnosisListPanelDependencies>(c => ({
        dialogService: c.resolve<IDialogService>("IDialogService"),
        careReferenceDataStore: c.resolve<CareReferenceDataStore>("CareReferenceDataStore")
    }))
);
