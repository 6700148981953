import Di from "@Di";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FormEditorRegistry from "./FormEditorRegistry";
import { IFormPanelProps } from "./FormPanel";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import { getAllReferencedEntityFormFieldData } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormEnumStore from "@Toolkit/FormEngine/Model/Schema/FormEnumStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import EnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/EnumFormDataElement";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";

@Di.injectable()
export default class FormPanelStore extends PanelStoreBase<IFormPanelProps> implements ILoadablePanelStore {
    public readonly contextData = State.createObservableShallowMap<string, any>();

    public readonly definition = this.promisedComputed<IFormDefinition>(null, async () => {
        if (!this.props.definitionId) {
            return null;
        }

        if (this.props.formDefinition) {
            return this.props.formDefinition;
        }

        return await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(this.props.definitionId);
    });

    public readonly enumsByPropertyIdentifiers = this.promisedComputed<Map<string, FormEnumStore> | null>(null, async () => {

        const definition = this.definition.get();

        if (!definition) {
            return null;
        }

        const enums = await this.formEngineReferenceDataStore.getOrLoadAllFormEnumsAsync();

        return new Map<string, FormEnumStore>(
            definition.rootSchema.getLatestVersionOrNull()?.dataElements
                .filter(de => de instanceof EnumFormDataElement)
                .map((de: EnumFormDataElement) => {
                    const dataElementName = de.name;

                    if (de.enumReference.isExternal) {
                        const externalEnumFormDefinitionGroup = enums.find(e => ValueWrapper.equals(e.formDefinitionId, de.enumReference.formDefinitionId));
                        const externalEnum = externalEnumFormDefinitionGroup?.enums.find(e => e.enum.name === de.enumReference.enumName)?.enum;
                        return [dataElementName, externalEnum];
                    } else {
                        const localEnum = definition.formEnums.find(le => le.name === de.enumReference.enumName);
                        return [dataElementName, localEnum];
                    }
                })
        );
    });

    public readonly areReferenceDataLoaded = this.promisedComputed<boolean>(false, async () => {

        const definition = this.definition.get();
        if (!definition) {
            return false;
        }

        const promises =
            getAllReferencedEntityFormFieldData(this.props.form.data.Content, definition.rootSchema.getLatestVersionOrNull()?.dataElements)
                .map(i => {
                    const loader = this.formEditorRegistry.getEntityReferenceLoader((i.dataElement as any).referencedEntity);

                    if (isNullOrUndefined(i.field.value)) {
                        return null;
                    }

                    if (i.field instanceof ReferencedEntityFormFieldData) {
                        if (!isNullOrUndefined(i.field.value)) {
                            return loader.loader?.(new loader.entityIdCtor(i.field.value));
                        } else {
                            return null;
                        }
                    } else {
                        if (i.field.value?.length > 0) {
                            return loader.loader?.(i.field.value.map(j => new loader.entityIdCtor(j)));
                        } else {
                            return null;
                        }
                    }
                }).filter(Boolean);

        await Promise.all(promises);

        return true;
    });

    @State.computed public get layout() {
        if (!this.props.form) {
            return null;
        }

        const layoutReference = this.props.form.data.LayoutReference;
        return this.definition.get()?.rootLayout.getVersion(layoutReference.versionNumber)?.content;
    }

    constructor(
        @Di.inject("FormEditorRegistry") public readonly formEditorRegistry: FormEditorRegistry,
        @Di.inject("ICurrentCultureProvider") public readonly cultureCodeProvider: ICurrentCultureProvider,
        @Di.inject("IFormEngineReferenceDataStore") private readonly formEngineReferenceDataStore: IFormEngineReferenceDataStore
    ) {
        super();
    }

    public initialize() {
        // nop
    }
}
