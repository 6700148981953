import RoleApiAdapter from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/OperationAccessControl/RoleApiAdapter";
import React from "react";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import { IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import IPermissionDefinition from "@PluginInterface/OperationAccessControl/IPermissionDefinition";
import { IMultiSelectorBaseProps } from "@CommonControls/MultiSelector";
import { getPermissionDefinitionName } from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/PermissionLocalizationHelper";

interface IPermissionDefinitionMultiSelectorDependencies {
    apiAdapter: RoleApiAdapter;
    localizationService: ILocalizationService;
    permissionDefinitionRegistry: IPermissionDefinitionRegistry;
}

interface IPermissionDefinitionMultiSelectorProps extends IMultiSelectorBaseProps {
    _dependencies?: IPermissionDefinitionMultiSelectorDependencies;
    fixedHeight?: string | number;
    onChange?: (permissionDefinitions: IPermissionDefinition[]) => void;
    selectedItems: IPermissionDefinition[];
}

@State.observer
class PermissionDefinitionMultiSelector extends React.Component<IPermissionDefinitionMultiSelectorProps> {

    @State.observable.ref private allPermissionDefinition: IPermissionDefinition[] = [];

    @State.observable.ref private allGroupsDataGridDataSource = new InMemoryDataGridDataSource(() => this.allUserGroupsDataSource);
    @State.observable.ref private selectedItemsDataGridDataSource = new InMemoryDataGridDataSource(() => this.selectedItemsDataSource);

    @State.computed
    private get allUserGroupsDataSource() {
        return this.allPermissionDefinition.filter(item =>
            !this.selectedItemsDataSource.some(item2 =>
                item.ids.some(id => id.value === item2.ids[0].value) && item2.alias === item.alias
            )
        );
    }

    @State.computed
    private get selectedItemsDataSource() {
        return this.allPermissionDefinition.filter(item =>
            this.props.selectedItems?.some(selected => item.ids.some(id => id.value === selected.ids[0].value) && selected.includesPermission(item)));
    }

    public componentDidMount() {
        this.reload();
    }

    @State.action.bound
    private reload() {
        const permissions = this.props._dependencies.permissionDefinitionRegistry.getAll();
        this.allPermissionDefinition = permissions;
        this.setOrdering();
    }

    @State.action.bound
    private setOrdering() {
        this.allGroupsDataGridDataSource.ordering = { direction: "asc", columnId: 0 } as IOrderingState;
        this.selectedItemsDataGridDataSource.ordering = { direction: "asc", columnId: 0 } as IOrderingState;
    }

    @State.action.bound
    private addNewItem(item: IPermissionDefinition) {
        const ids = [...this.selectedItemsDataSource, item];
        this.props.onChange(ids);
    }

    @State.action.bound
    private removeItem(item: IPermissionDefinition) {
        const ids = [...this.selectedItemsDataSource].filter(i => i !== item);
        this.props.onChange(ids);
    }

    @State.action.bound
    private renderAllItemsColumn() {
        return this.renderColumns(this.props.allItemsTableTitle ? this.props.allItemsTableTitle : "");
    }

    @State.action.bound
    private renderChosenItemsColumn() {
        return this.renderColumns(this.props.chosenItemsTableTitle ? this.props.chosenItemsTableTitle : "");
    }

    @State.bound
    private renderCell(_value: any, row: IPermissionDefinition) {
        return getPermissionDefinitionName(this.props._dependencies.localizationService, row);
    }

    @State.bound
    private orderValue(_value: any, row: IPermissionDefinition) {
        return getPermissionDefinitionName(this.props._dependencies.localizationService, row);
    }

    @State.bound
    private filterValue(_value: any, row: IPermissionDefinition) {
        return getPermissionDefinitionName(this.props._dependencies.localizationService, row);
    }

    private renderColumns(header: string) {
        return (
            <DataGridColumn
                id={0}
                onRenderCellValue={this.renderCell}
                header={header}
                automationId="Name"
                isOrderable
                isFilterable
                clientSideOrderableValueGetter={this.orderValue}
                clientSideFilterableValueGetter={this.filterValue}
            />
        );
    }

    public render() {
        return (
            <Ui.MultiSelector
                {...this.props}
                renderColumns={this.renderAllItemsColumn}
                renderSelectedColumns={this.renderChosenItemsColumn}
                allItemsDataSource={this.allGroupsDataGridDataSource}
                selectedItemsDataSource={this.selectedItemsDataGridDataSource}
                onAdded={this.addNewItem}
                onRemoved={this.removeItem}
                automationId="__multiEntitySelector"
                generateInitialFilterStore
                changeOnMount
                fixedHeight={this.props.fixedHeight}
            />
        );
    }
}

export default connect(
    PermissionDefinitionMultiSelector,
    new DependencyAdapter<IPermissionDefinitionMultiSelectorProps, IPermissionDefinitionMultiSelectorDependencies>(container => {
        return {
            apiAdapter: container.resolve("RoleApiAdapter"),
            localizationService: container.resolve("ILocalizationService"),
            permissionDefinitionRegistry: container.resolve("IPermissionDefinitionRegistry")
        };
    })
);
