import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import ITemplateInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateInfo";

export interface ITemplateInfoDialogParams extends IModalParams {
    currentInfo: ITemplateInfo;
    isCreation: boolean;
}

export interface ITemplateInfoDialogResult {
    newTemplateInfo: ITemplateInfo;
}

export default class TemplateInfoDialogParams implements ITemplateInfoDialogParams {

    public static type = "TemplateInfoDialog";
    public get type() { return TemplateInfoDialogParams.type; }

    constructor(
        public readonly currentInfo: ITemplateInfo,
        public readonly isCreation: boolean,
    ) { }
}