import BadgeWithIcon from "@CommonControls/BadgeWithIcon/BadgeWithIcon";
import StaticResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { useFormBooleanField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { ICustomBlockRegistryItem, IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";

function PatientValidityFormCustomBlock(props: IFormCustomBlockComponentProps) {
    const [isDataCleansingNeeded, setIsDataCleansingNeeded] = useFormBooleanField(props.form.data.Content, "IsDataCleansingNeeded");

    if (!isDataCleansingNeeded)
        return <></>;

    return (
        <BadgeWithIcon
            iconName="invalid_outlined_circle"
            text={StaticResources.PatientRegister.PatientBaseData.Message.IsInvalid}
            badgeVisualStyleType="red"
        />
    );
}

export const PatientValidityRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Patient validity",
    dataElementsFactory: () => [
    ],
    componentType: State.observer(PatientValidityFormCustomBlock)
};

export default PatientValidityFormCustomBlock;
