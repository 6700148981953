import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import UserGroup from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroup";
import * as Proxy from "@HisPlatform/BoundedContexts/UserManagement/Api/Proxy.g";
import UserGroupListStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroupListStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import Di from "@Di";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import UserGroupId from "@Primitives/UserGroupId.g";
import UserGroupStoreMapper from "./UserGroupStoreMapper";
import { getAddUserGroupControllerDto, getUpdateUserGroupControllerDto, getUpdateUserGroupPermissionCheckDto } from "./UserGroupDtoMapper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import {buildQueryStringArray} from "@Toolkit/CommonWeb/QueryStringBuilder";
import UserId from "@Primitives/UserId.g";

@Di.injectable()
export default class UserGroupsApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IGroupsClient") private readonly apiClient: Proxy.IGroupsClient,
        @Di.inject("UserGroupStoreMapper") private readonly userGroupStoreMapper: UserGroupStoreMapper
    ) {
        super();
    }

    public getAllUserGroupsAsync(): Promise<UserGroupListStore> {
        return this.processOperationAsync(
            new UserGroupListStore(),
            async target => {
                const response = await this.apiClient.getAllUserGroupsQueryAsync(CreateRequestId());

                target.operationInfo = createOperationInfo(response);
                target.groups = response.userGroups.map((item) => {
                    const newStore = new UserGroup();
                    this.userGroupStoreMapper.applyToStore(newStore, item);
                    return newStore;
                });
            }
        );
    }

    public addUserGroupAsync(userGroup: UserGroup) {
        const dto = getAddUserGroupControllerDto(userGroup);

        return this.processOperationAsync(
            new UserGroup(),
            async target => {
                const response = await this.apiClient.addUserGroupCommandAsync(
                    CreateRequestId(),
                    dto
                );

                if (response.validatedResult) {
                    this.userGroupStoreMapper.applyToStore(target, response.validatedResult);
                }
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public updateUserGroupAsync(userGroup: UserGroup) {
        const dto = getUpdateUserGroupControllerDto(userGroup);

        return this.processOperationAsync(
            new UserGroup(),
            async target => {
                const response = await this.apiClient.updateUserGroupCommandAsync(
                    CreateRequestId(),
                    dto
                );

                if (response.validatedResult) {
                    this.userGroupStoreMapper.applyToStore(target, response.validatedResult);
                }
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public updateUserGroupPermissionCheckAsync() {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.updateUserGroupCommandAsync(
                    CreateRequestId(),
                    getUpdateUserGroupPermissionCheckDto(),
                    true
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public getUserGroupByIdAsync(id: UserGroupId) {
        return this.processOperationAsync(
            new UserGroup(),
            async target => {
                const response = await this.apiClient.getUserGroupByIdQueryAsync(
                    CreateRequestId(),
                    id.value
                );

                this.userGroupStoreMapper.applyToStore(target, response.validatedResult);
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public validateAsync(userGroup: UserGroup): Promise<SimpleStore<IClientValidationResult[]>> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = userGroup.isNew
                    ? await this.apiClient.addUserGroupCommandAsync(CreateRequestId(), getAddUserGroupControllerDto(userGroup, true))
                    : await this.apiClient.updateUserGroupCommandAsync(CreateRequestId(), getUpdateUserGroupControllerDto(userGroup, true));

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    @State.bound
    public getAllUserGroupId(): Promise<SimpleStore<UserGroupId[]>> {
        return this.processOperationAsync(
            new SimpleStore<UserGroupId[]>(),
            async target => {
                const response = await this.apiClient.getAllUserGroupIdsQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.ids;
            }
        );
    }

    @State.bound
    public getUserGroupsByIdsAsync(ids: UserGroupId[]): Promise<SimpleStore<UserGroup[]>> {
        return this.processOperationAsync(
            new SimpleStore<UserGroup[]>(),
            async target => {
                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value));
                const response = await this.apiClient.getUserGroupsByIdsQueryAsync(CreateRequestId(),
                    buildQueryStringArray(normalizedIds));

                target.operationInfo = createOperationInfo(response);
                target.value = response.userGroups.map((item) => {
                    const newStore = new UserGroup();
                    this.userGroupStoreMapper.applyToStore(newStore, item);
                    return newStore;
                });
            }
        );
    }

    @State.bound
    public getUserGroupIdsByUserIdAsync(id: UserId): Promise<SimpleStore<UserGroupId[]>> {
        return this.processOperationAsync(
            new SimpleStore<UserGroupId[]>(),
            async target => {
                const response = await this.apiClient.getUserGroupIdsByUserIdQueryAsync(CreateRequestId(), id.value);

                target.operationInfo = createOperationInfo(response);
                target.value = response.userGroupIds;
            }
        );
    }
}
