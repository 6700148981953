import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import Di from "@Di";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import CareActivityBasedWorklistEntityType from "@Primitives/CareActivityBasedWorklistEntityType";
import PatientPhoneNumber from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/PatientPhoneNumber";
import TelecomUseId from "@Primitives/TelecomUseId.g";
import CareActivityBasedWorklistEntityTypeColumn from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/CustomColumns/CareActivityBasedWorklistEntityTypeColumn";
import React from "react";
import CareActivityBasedWorklistPriorityColumn from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/CustomColumns/CareActivityBasedWorklistPriorityColumn";
import PatientPhoneNumbersColumn from "@HisPlatform/BoundedContexts/Scheduling/Components/PatientPhoneNumbersColumn/PatientPhoneNumbersColumn";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import { configureClientSideActionMappings } from "./Configuration/ClientSideActionMappings";
import CareActivityBasedWorklistHasValidationErrorColumn from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/CustomColumns/CareActivityBasedWorklistHasValidationErrorColumn";
import CareActivityBasedWorklistValidationContextColumn from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/CustomColumns/CareActivityBasedWorklistValidationContextColumn";
import AppointmentDateColumn from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentDateColumn/AppointmentDateColumn";
import * as Proxy from "@HisPlatform/BoundedContexts/Productivity/Api/Proxy.g";
import CareActivityWorklistItemContextInfo from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/CareActivityWorklistItemMetadata";
import PatientName from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/PatientName";
import NameStore from "@Primitives/NameStore";
import DependencyContainer from "@DiContainer";
import WorklistDefinitionReferenceDataStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/WorklistDefinition/WorklistDefinitionReferenceDataStore";
import PatientEmailAddressesColumn from "@HisPlatform/BoundedContexts/Scheduling/Components/PatientEmailAddressesColumn/PatientEmailAddressesColumn";
import PatientEmailAddress from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/PatientEmailAddress";

@Di.injectable()
export default class ProductivityBoundedContext implements IBoundedContext {
    public initializeAsync(): Promise<void> {

        DependencyContainer
            .bind("WorklistDefinitionReferenceDataStore")
            .to(WorklistDefinitionReferenceDataStore)
            .inSingletonScope();

        return Promise.resolve();
    }

    public configureNData?(configurator: INDataAttributeConfigurator): void {
        configurator.registerValueConverter("CareActivityBasedWorklistEntityType", rawValue => rawValue as unknown as CareActivityBasedWorklistEntityType[]);

        configurator.registerValueConverter("CareStateDto", (rawValue: any) => {
            const type = rawValue.Type as CareActivityBasedWorklistEntityType;
            return {
                type: type,
                value: rawValue.State,
                additionalNote: rawValue.AdditionalNote
            };
        });

        configurator.registerValueConverter("CarePriorityDto", (rawValue: any) => {
            const type = rawValue.Type as CareActivityBasedWorklistEntityType;
            return {
                type: type,
                value: rawValue.Priority
            };
        });

        configurator.registerValueConverter("PatientPhoneNumbersDto", rawValue =>
            rawValue.PhoneNumbers?.map((p: any) =>
                (isNullOrUndefined(p?.PhoneNumber) || isNullOrUndefined(p?.TelecomUseId?.Value)) ? null :
                    new PatientPhoneNumber(p.PhoneNumber, new TelecomUseId(p.TelecomUseId.Value))));

        configurator.registerValueConverter("PatientEmailAddressesDto", rawValue =>
            rawValue.EmailAddresses?.map((p: any) =>
                (isNullOrUndefined(p?.EmailAddress) || isNullOrUndefined(p?.TelecomUseId?.Value)) ? null :
                    new PatientEmailAddress(p.EmailAddress, new TelecomUseId(p.TelecomUseId.Value))));

        configurator.registerColumn("CareActivityBasedWorklistEntityType", <CareActivityBasedWorklistEntityTypeColumn />);
        configurator.registerColumn("CarePriorityDto", <CareActivityBasedWorklistPriorityColumn displayMode="badge" />);
        configurator.registerColumn("PatientPhoneNumbersDto", <PatientPhoneNumbersColumn />);
        configurator.registerColumn("PatientEmailAddressesDto", <PatientEmailAddressesColumn />);
        configurator.registerColumn("HasValidationError", <CareActivityBasedWorklistHasValidationErrorColumn />);
        configurator.registerColumn("ValidationContext", <CareActivityBasedWorklistValidationContextColumn />);
        configurator.registerColumnWithName("PractitionerScheduleEntryBased_DateInterval", <AppointmentDateColumn />);
    }

    public configureMapper(mapper: IMapperConfigurator) {
        configureClientSideActionMappings(mapper);
        mapper.register(Proxy.CareActivityWorklistItemContextInfoDto, (s: Proxy.CareActivityWorklistItemContextInfoDto, _) => new CareActivityWorklistItemContextInfo(
            new PatientName(
                NameStore.create({
                    familyName: s.patientName.name.familyName,
                    givenName1: s.patientName.name.givenName1,
                    givenName2: s.patientName.name.givenName2,
                    postfix: s.patientName.name.postfix,
                    prefix: s.patientName.name.prefix
                }),
                s.patientName.isDead,
                s.patientName.genderId,
                false,
                s.patientName.isDataCleansingNeeded
            ),
            s.careActivityState,
            s.careInterval?.from ?? null,
            s.careInterval?.to ?? null,
            s.mostRelevantPractitionerId,
            s.pointOfCareId,
            s.careIdentifier,
            s.mergedPatientId,
            s.birthDate
        ));
    }
}
