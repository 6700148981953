exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes PageRoot_fadeInLeft_2VDla {\r\n    0% {\r\n        opacity: 0;\r\n        -webkit-transform: translateX(-20px);\r\n        transform: translateX(-20px);\r\n    }\r\n\r\n    100% {\r\n        opacity: 1;\r\n        -webkit-transform: translateX(0);\r\n        transform: translateX(0);\r\n    }\r\n}\r\n\r\n@keyframes PageRoot_fadeInLeft_2VDla {\r\n    0% {\r\n        opacity: 0;\r\n        -webkit-transform: translateX(15px);\r\n        -ms-transform: translateX(15px);\r\n        transform: translateX(15px);\r\n    }\r\n\r\n    100% {\r\n        opacity: 1;\r\n        -webkit-transform: translateX(0);\r\n        -ms-transform: translateX(0);\r\n        transform: translateX(0);\r\n    }\r\n}\r\n\r\n.PageRoot_fadeIn_3x4xg {\r\n    -webkit-animation-name: PageRoot_fadeInLeft_2VDla;\r\n    animation-name: PageRoot_fadeInLeft_2VDla;\r\n    animation-duration: 0.5s;\r\n}\r\n\r\n.PageRoot_page-root_V643Q {\r\n    height: 100%;\r\n}\r\n\r\n.PageRoot_scrollable_1L5Bf {\r\n    overflow-y: auto;\r\n}", ""]);

// exports
exports.locals = {
	"fadeIn": "PageRoot_fadeIn_3x4xg",
	"fadeInLeft": "PageRoot_fadeInLeft_2VDla",
	"page-root": "PageRoot_page-root_V643Q",
	"pageRoot": "PageRoot_page-root_V643Q",
	"scrollable": "PageRoot_scrollable_1L5Bf"
};