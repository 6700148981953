import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CallScopeIdentifier from "@HisPlatform/Packages/Care/Model/CallScopeIdentifier";
import Call from "@HisPlatform/Packages/Care/Model/Call";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import CallId from "@Primitives/CallId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import CallState from "@HisPlatform/BoundedContexts/Telecommunication/Api/Telecommunication/Enum/CallState.g";

@Di.injectable()
export default class TelecommunicationApiAdapter extends ApiAdapterBase2 {

    constructor(
        @Di.inject("ICareClient") private readonly apiClient: Proxy.ICareClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public tryGetCallByCareActivityIdAsync(careActivityId: CareActivityId) {
        return this.executeOperationAsync<Call, Proxy.TryGetCallByCareActivityIdQueryResponse>(
            () => this.apiClient.tryGetCallByCareActivityIdQueryAsync(CreateRequestId(), careActivityId.value),
            response => {
                return !!response.call ? this.mapCall(response.call) : null;
            }
        );
    }

    @State.bound
    public updateCallStateByIdAsync(callId: CallId, callState: CallState, rowVersion: RowVersion) {
        return this.executeOperationAsync<Call, Proxy.UpdateCallStateByIdCommandResponse>(
            () => this.apiClient.updateCallStateByIdCommandAsync(CreateRequestId(), new Proxy.UpdateCallStateByIdControllerDto({
                callId: callId,
                callState: callState,
                rowVersion: rowVersion
            })),
            response => {
                return this.mapCall(response.call);
            }
        );
    }

    @State.bound
    private mapCall(callDto: Proxy.CallDto) {
        return new Call(
            callDto.id,
            callDto.callScopeIdentifiers.map(i => new CallScopeIdentifier(i.scope, i.value)),
            callDto.callIdentifier,
            callDto.password,
            callDto.organizerIdentifier,
            callDto.joinUri,
            callDto.callState,
            callDto.organizerName,
            callDto.timeStart,
            callDto.timeEnd,
            callDto.rowVersion);
    }
}