import { IModalComponentParams, IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import Di from "@Di";

@Di.injectable()
export default class ModalServiceRegistry {
    private readonly modalComponentTypes = new Map<string, React.ComponentType<IModalComponentParams>>();

    public register(type: string, modalComponent: React.ComponentType<IModalComponentParams>): void {
        this.modalComponentTypes.set(type, modalComponent);
    }

    public getComponentType(params: IModalParams) {
        const type = this.modalComponentTypes.get(params.type);
        if (!type) {
            throw new Error("Unknown modal type: " + params.type);
        }
        return type;
    }
}