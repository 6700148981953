import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import OrganizationUnit from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnit";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import Contact from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/Contact";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import PractitionerId from "@Primitives/PractitionerId.g";
import CountryId from "@Primitives/CountryId.g";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

export function getUpdateOrganizationUnitDto(organizationUnit: OrganizationUnit): Proxy.UpdateOrganizationUnitControllerDto {
    return new Proxy.UpdateOrganizationUnitControllerDto({
        baseData: mapBaseData(organizationUnit),
        id: organizationUnit.id,
        code: organizationUnit.code,
        naturalIdentifierDate: LocalDate.today(),
        validity: organizationUnit.validity
    });
}

export function getUpdateOrganizationUnitPermissionCheckDto() {
    const now = LocalDate.createFromMoment(DateTimeService.now());
    return new Proxy.UpdateOrganizationUnitControllerDto({
        id: new OrganizationUnitId("-1"),
        code: "PermissionCheck",
        validity: new LocalDateRange(now, now),
        baseData: new Proxy.OrganizationUnitBaseDataDto({
            address: new Proxy.AddressDto({
                countryId: new CountryId("-1"),
                addressLine: "PermissionCheck",
                settlement: "PermissionCheck",
                zipCode: "PermissionCheck"
            }),
            organizationUnitNaturalIdentifier: new Proxy.OrganizationUnitNaturalIdentifierDto({
                name: "PermissionCheck",
                shortName: "PermissionCheck"
            }),
            managerId: new PractitionerId("-1"),
            contacts: []
        })
    });
}

export function getAddUpdateOrganizationUnitDto(organizationUnit: OrganizationUnit): Proxy.AddOrganizationUnitControllerDto {
    return new Proxy.AddOrganizationUnitControllerDto({
        baseData: mapBaseData(organizationUnit),
        code: organizationUnit.code,
        organizationUnitDefinitionId: organizationUnit.organizationUnitDefinitionId,
        parentId: organizationUnit.parentId,
        validity: organizationUnit.validity
    });
}

function mapBaseData(organizationUnit: OrganizationUnit) {
    return new Proxy.OrganizationUnitBaseDataDto({
        address: new Proxy.AddressDto({
            addressLine: organizationUnit.baseData.address.addressLine,
            countryId: organizationUnit.baseData.address.countryId,
            settlement: organizationUnit.baseData.address.settlement,
            zipCode: organizationUnit.baseData.address.zipCode
        }),
        organizationUnitNaturalIdentifier: new Proxy.OrganizationUnitNaturalIdentifierDto({
            name: organizationUnit.baseData.naturalIdentifier.name,
            shortName: organizationUnit.baseData.naturalIdentifier.shortName
        }),
        contacts: organizationUnit.baseData.contacts.map(mapContact),
        managerId: organizationUnit.baseData.managerId
    });
}

function mapContact(c: Contact): Proxy.ContactDto {
    return new Proxy.ContactDto({
        contactTypeId: c.contactTypeId,
        value: c.value
    });
}
