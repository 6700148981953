import React from "react";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { SelectBox } from "@CommonControls";
import { inputChangeActionType } from "@CommonControls/SelectBox";
import { Placement } from "@Toolkit/ReactClient/Components/Tooltip";
import StaticWebAppResources from "@StaticResources";
import { ModalSize } from "@CommonControls/Modal";
import Icon, { iconNameType } from "@CommonControls/Icon";
import ISelectBoxSection from "@CommonControls/SelectBox/ISelectBoxSection";
import ComplexSearchPanel, { IComplexSearchItemGroup } from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchPanel";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import EntityCollectionStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/Personalization/EntityCollectionStore";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import IColumnDescriptor from "./ComplexSearch/IColumnDescriptor";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";

interface IUniversalCodeSelectorViewProps<TRecord, TComplexSearchRecord> {
    value: TRecord | TRecord[];

    className: string;
    style: React.CSSProperties;

    // selectBox
    readOnly: boolean;
    onChange: (value: TRecord) => void;
    isLoading: boolean;
    propertyIdentifier: string;
    disabled: boolean;
    label: string;
    infoLabel?: string;
    tooltipContent: string | HTMLElement;
    tooltipPosition: Placement;
    id: string;
    name: string;
    tabIndex: number;
    required?: boolean;
    menuIsOpen: boolean;
    isCreatable: boolean;
    isClearable: boolean;
    addNewItem: (text: string) => void;
    onMenuOpen: () => void;
    onMenuClose: () => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onBlur: () => void;
    multiSelect?: boolean;
    getItemHashCode?: (value: any) => string;
    onNewItemClick: () => void;
    dropDownNewItemText: string;
    customValueRenderer: (props: any) => React.ReactNode;
    hoverOnlyIndicatorIcons?: boolean;
    getOptionText?: (value: any) => string;

    // quickSearch
    quickSearchText: string;
    onQuickSearchTextChanged: (newValue: string, action: inputChangeActionType) => void;
    quickSearchResults: Array<ISelectBoxItem<TRecord>>;
    menuSections?: Array<ISelectBoxSection<TRecord>>;
    getOptionIconName?: (item: TRecord) => iconNameType;
    onOptionIconClick?: (item: ISelectBoxItem<TRecord>) => void;

    // complexSearch
    hasComplexSearch: boolean;
    complexSearchOpen: boolean;
    createNewItemAsync: (code: string, name: string) => Promise<IClientValidationResult[]>;
    onValidateAllAsync: (code: string, name: string) => Promise<IClientValidationResult[]>;
    createNewItemButtonText: string;
    createNewItemCodePlaceHolder: string;
    createNewItemNamePlaceHolder: string;
    createNewItemCodePropertyIdentifier: string;
    createNewItemNamePropertyIdentifier: string;
    createNewItemValidationEntityName: string;
    showNewItem: boolean;
    selectableItemsSearchPlaceHolder: string;
    selectedItemsSearchPlaceHolder: string;

    complexSearchLoadAsync: (filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: TComplexSearchRecord[], selectedGroup: IComplexSearchItemGroup, filters?: FilterBase[]) => Promise<IPagedItems<TComplexSearchRecord>>;
    filters?: FilterBase[];
    itemGroups: IComplexSearchItemGroup[];
    selectedItems: IObservableArray<TComplexSearchRecord>;
    entityCollections: EntityCollectionStore[];
    singleSelect: (row: TComplexSearchRecord) => void;
    onMultiSelectSave: () => void;
    complexSearchTitle: string;
    complexSearchModalSize: ModalSize;
    showEntityCollections: boolean;
    isMultiSelect: boolean;
    codeGetter: string;
    nameGetter: string;
    getNameValueString: (value: any) => string;
    customNameRender: (value: any, row: TComplexSearchRecord) => React.ReactNode;
    getEntitiesByIds: (id: IStringEntityId[]) => TComplexSearchRecord[];
    onComplexSearchOpen: () => void;
    onComplexSearchClose: () => void;
    isFavorite: (value: TComplexSearchRecord) => boolean;
    setFavorite: (value: TComplexSearchRecord) => void;

    automationId: string;
    hideAllgroup?: boolean;
    columnDescriptors?: IColumnDescriptor[];
    newItemPermissionIdentifier?: string;
    customContentComponentType?: React.ComponentType;
    customContentComponentProps?: any;
}

export default class UniversalCodeSelectorView<TRecord, TComplexSearchRecord> extends React.Component<IUniversalCodeSelectorViewProps<TRecord, TComplexSearchRecord>> {

    @State.bound
    private renderSearchButton() {
        return (
            <Icon
                iconName="search"
                size="compact"
                visualStyle="secondary"
                onClick={this.props.onComplexSearchOpen}
                style={{ marginRight: 8, marginLeft: 4 }}
                automationId="__searchButton"
                disabled={this.props.disabled || this.props.readOnly}
            />
        );
    }

    @State.bound
    private renderCustomComplexSearch() {
        return  React.createElement<any>(
            this.props.customContentComponentType,
            { ...this.props.customContentComponentProps, _onClose: this.props.onComplexSearchClose, _isOpen: this.props.complexSearchOpen }
        );
    }

    public render() {
        return (
            <div data-automation-id={this.props.automationId || undefined} className={this.props.className}
                style={this.props.style}>
                <SelectBox
                    items={this.props.quickSearchResults}
                    onInputChange={this.props.onQuickSearchTextChanged}
                    inputValue={this.props.quickSearchText}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    additionalIndicatorRenderer={this.props.hasComplexSearch && this.renderSearchButton.bind(null)}
                    loading={this.props.isLoading}
                    hideDropdownIndicator
                    tabSelectsValue
                    isReadOnly={this.props.readOnly}
                    label={this.props.label}
                    infoLabel={this.props.infoLabel}
                    propertyIdentifier={this.props.propertyIdentifier}
                    valueOnClear={Array.isArray(this.props.value) ? [] : null}
                    tooltipContent={this.props.tooltipContent}
                    tooltipPosition={this.props.tooltipPosition}
                    automationId={this.props.automationId + "__selectBox"}
                    disabled={this.props.disabled}
                    id={this.props.id}
                    name={this.props.name}
                    tabIndex={this.props.tabIndex}
                    required={this.props.required}
                    menuIsOpen={this.props.menuIsOpen}
                    onMenuOpen={this.props.onMenuOpen}
                    onMenuClose={this.props.onMenuClose}
                    noOptionsMessage={StaticWebAppResources.CodeSelector.TypeSomethingToSearch}
                    onKeyDown={this.props.onKeyDown}
                    onBlur={this.props.onBlur}
                    isCreatable={this.props.isCreatable}
                    onAddNewOption={this.props.addNewItem}
                    clearable={this.props.isClearable}
                    multiSelect={this.props.multiSelect}
                    getOptionValue={this.props.getItemHashCode}
                    customValueRenderer={this.props.customValueRenderer}

                    menuSections={this.props.menuSections}
                    getOptionIconName={this.props.getOptionIconName}
                    onOptionIconClick={this.props.onOptionIconClick}
                    onShowAllItemClick={this.props.onComplexSearchOpen}
                    onNewItemClick={this.props.onNewItemClick}
                    newItemPermissionIdentifier={this.props.newItemPermissionIdentifier}
                    newItemText={this.props.dropDownNewItemText}
                    hoverOnlyIndicatorIcons={this.props.hoverOnlyIndicatorIcons}
                    getOptionText={this.props.getOptionText}
                    hideSelectedOptions={false}
                />
                {
                    this.props.hasComplexSearch && !this.props.customContentComponentType &&
                    <ComplexSearchPanel
                        {...this.props}
                    />
                }
                {
                    !!this.props.customContentComponentType && this.renderCustomComplexSearch()
                }
            </div>
        );
    }
}
