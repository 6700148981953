import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { Message } from "@CommonControls";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IMedicationUpdateDateWarningDependencies {
    prescriptionApiAdapter: PrescriptionApiAdapter;
}

interface IMedicationUpdateDateWarningProps {
    _dependencies?: IMedicationUpdateDateWarningDependencies;
}

/** @screen */
@State.observer
class MedicationUpdateDateWarning extends React.Component<IMedicationUpdateDateWarningProps> {

    @State.observable.ref private lastSuccessfulMedicationUpdateDate: LocalDate = undefined;

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public render() {
        if (this.lastSuccessfulMedicationUpdateDate === undefined) {
            return null;
        }

        const expectedLastImportDate = LocalDate.today().firstDayOfMonth();
        if (!MedicationUpdateDateWarning.isDataOutdated(this.lastSuccessfulMedicationUpdateDate, expectedLastImportDate)) {
            return null;
        }

        const displayMessage = formatStringWithObjectParams(StaticHunSocialSecurityMedicationRequestResources.MedicationReferenceDataWarning, {
            sources: "Puphax"
        });
        return <Message style={{margin: "12px"}} type="warning">{displayMessage}</Message>;
    }

    private async loadAsync() {
        const medicationUpdateData = await this.props._dependencies.prescriptionApiAdapter.getLastSuccessfulMedicationUpdateDateAsync();
        this.setLastSuccessfulMedicationUpdateDate(medicationUpdateData.value);
    }

    @State.action.bound
    private setLastSuccessfulMedicationUpdateDate(newValue: LocalDate) {
        this.lastSuccessfulMedicationUpdateDate = newValue;
    }

    private static isDataOutdated(updateDate: LocalDate, threshold: LocalDate): boolean {
        return isNullOrUndefined(updateDate) || updateDate.lessThan(threshold);
    }
}

export default connect(
    MedicationUpdateDateWarning,
    new DependencyAdapter<IMedicationUpdateDateWarningProps, IMedicationUpdateDateWarningDependencies>(c => ({
        prescriptionApiAdapter: c.resolve("PrescriptionApiAdapter")
    }))
);