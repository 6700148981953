import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PatientRegisterReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/PatientRegisterReferenceDataStore";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import * as HisUi from "@HisPlatformControls";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IAllergyIntoleranceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/IAllergyIntoleranceVersion";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { IComplexSearchItemGroup } from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchPanel";
import MedicationsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicationsApiAdapter";
import SubstanceId from "@Primitives/SubstanceId.g";
import MedicationId from "@Primitives/MedicationId.g";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import { MedicationStatementSubstanceCodeSelectorItem } from "./MedicationStatementSubstanceCodeSelectorItem";
import InternationalNonproprietaryNameId from "@Primitives/InternationalNonproprietaryNameId.g";
import InternationalNonproprietaryNamesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/InternationalNonproprietaryNamesApiAdapter";
import MedicationStatementSubstanceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/ReferenceData/MedicationStatementSubstanceApiAdapter";
import MedicationStatementId from "@Primitives/MedicationStatementId.g";
import IMedicalConditionReferenceDataResolver from "@HisPlatform/BoundedContexts/Care/Services/Definition/MedicalConditionReferenceDataResolver/IMedicalConditionReferenceDataResolver";

interface IMedicationStatementSubstanceCodeSelectorDependencies {
    patientRegisterReferenceDataStore: PatientRegisterReferenceDataStore;
    medicationsApiAdapter: MedicationsApiAdapter;
    localizationService: IToolkitLocalizationService;
    careReferenceDataStore: CareReferenceDataStore;
    internationalNonproprietaryNameApiAdapter: InternationalNonproprietaryNamesApiAdapter;
    medicationStatementSubstanceApiAdapter: MedicationStatementSubstanceApiAdapter;
    medicalConditionReferenceDataResolver: IMedicalConditionReferenceDataResolver;
}

interface IMedicationStatementSubstanceCodeSelectorProps extends ICodeSelectorCommonProps<MedicationStatementSubstanceCodeSelectorItem> {
    _dependencies?: IMedicationStatementSubstanceCodeSelectorDependencies;
}

@State.observer
class MedicationStatementCodeSelector extends React.Component<IMedicationStatementSubstanceCodeSelectorProps> {
    private get medicalConditionReferenceDataResolver() { return this.dependencies.medicalConditionReferenceDataResolver; }
    private get itemGroups(): IComplexSearchItemGroup[] {
        return ([
            {
                name: "Medication",
                localizedName: StaticCareResources.PatientRegister.MedicationStatementRegister.ComplexSearchTabs.MedicationTab,
                icon: "pills"
            },
            {
                name: "Substance",
                localizedName: StaticCareResources.PatientRegister.MedicationStatementRegister.ComplexSearchTabs.AtcTab,
                icon: "laboratory_medicine"
            },
            {
                name: "InternationalNonproprietaryName",
                localizedName: StaticCareResources.PatientRegister.MedicationStatementRegister.ComplexSearchTabs.InternationalNonproprietaryNameTab,
                icon: "language"
            },
        ]);
    }

    private get dependencies() {
        return this.props._dependencies;
    }

    @State.bound
    private async getDisplayValueAsync(value: MedicationStatementSubstanceCodeSelectorItem) {
        return await this.medicalConditionReferenceDataResolver.getMedicationStatementSubstanceNameAsync(value, this.props.validOn ? this.props.validOn : LocalDate.today());
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const results = await this.dependencies.medicationStatementSubstanceApiAdapter.searchMedicationStatementSubstancesExtendedByFreeTextAsync(
            text,
            10,
            this.props.validOn ? this.props.validOn : LocalDate.today()
        );

        if (results.operationWasSuccessful) {
            const items = results.value.map(x => {
                const textOfQuickSearch = `${x.name} (${this.dependencies.patientRegisterReferenceDataStore.medicationStatementSubstanceCategory.get(x.categoryId).displayValue.Name})`;
                if (x.internationalNonproprietaryNameId) {
                    return new MedicationStatementSubstanceCodeSelectorItem({
                        id: x.internationalNonproprietaryNameId,
                        text: textOfQuickSearch
                    });
                }
                if (x.medicationId) {
                    return new MedicationStatementSubstanceCodeSelectorItem({
                        id: x.medicationId,
                        name: x.name,
                        code: x.code,
                        text: textOfQuickSearch
                    });
                }
                if (x.substanceId) {
                    return new MedicationStatementSubstanceCodeSelectorItem({
                        id: x.substanceId,
                        name: x.name,
                        code: x.code,
                        text: textOfQuickSearch
                    });
                }
                return null;
            });

            const internationalNonproprietaryNames = items.filter(item => item.id instanceof InternationalNonproprietaryNameId);
            const substances = items.filter(item => item.id instanceof SubstanceId);
            const medications = items.filter(item => item.id instanceof MedicationId);           
            await this.dependencies.careReferenceDataStore.internationalNonproprietaryNames.ensureLoadedAsync(internationalNonproprietaryNames.map(item => item.id));            
            await this.dependencies.careReferenceDataStore.substances.ensureLoadedAsync(substances.map(item => ({
                id: item.id,
                validOn: this.props.validOn ? this.props.validOn : LocalDate.today()
            } as IEntityVersionSelector<MedicationStatementId>)));
            await this.dependencies.careReferenceDataStore.medications.ensureLoadedAsync(medications.map(item => ({
                id: item.id,
                validOn: this.props.validOn ? this.props.validOn : LocalDate.today()
            } as IEntityVersionSelector<MedicationStatementId>)));
            return items;
        }
        return null;
    }

    @State.bound
    private addNewItem(text: string): MedicationStatementSubstanceCodeSelectorItem {
        return new MedicationStatementSubstanceCodeSelectorItem({ text: text });
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: IAllergyIntoleranceVersion[], group: IComplexSearchItemGroup) {
        const validOn = this.props.validOn ? this.props.validOn : LocalDate.today();
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;
        let results;
        switch (group.name) {
            case "Medication":
                results = await this.props._dependencies.medicationsApiAdapter.medicationSelectorQueryAsync(
                    filterText,
                    customOrdering,
                    paging);
                await this.dependencies.careReferenceDataStore.medications.ensureLoadedAsync(results.value.items.map(value => ({
                    id: value.id,
                    validOn
                } as IEntityVersionSelector<MedicationId>)));
                return results.value;
            case "Substance":
                results = await this.props._dependencies.medicationsApiAdapter.substanceSelectorQueryAsync(
                    filterText,
                    customOrdering,
                    paging);
                await this.dependencies.careReferenceDataStore.substances.ensureLoadedAsync(results.value.items.map(value => ({
                    id: value.id,
                    validOn
                } as IEntityVersionSelector<SubstanceId>)));
                return results.value;
            case "InternationalNonproprietaryName":
                results = await this.props._dependencies.internationalNonproprietaryNameApiAdapter.complexSearchInternationalNonproprietaryNames(
                    filterText,
                    customOrdering,
                    paging);
                await this.dependencies.careReferenceDataStore.internationalNonproprietaryNames.ensureLoadedAsync(results.value.items.map(value => value.id));
                return results.value;            
        }
        return null;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: MedicationStatementSubstanceCodeSelectorItem) {
        this.props.onChange({ id: item.id });
    }

    @State.bound
    private onComplexSearchMultiSelect(items: IAllergyIntoleranceVersion[]) {
        this.props.onChange(items.map(item => ({ id: item.id, text: item.code })));
    }
    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticCareResources.MedicalCondition.MedicationStatementSubstances.Title,
            codeGetter: "code",
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect,
            complexSearchItemGroups: this.itemGroups,
            hideAllgroup: true            
        };
        return (
            <HisUi.UniversalCodeSelector
                {...props}
                isCreatable
                addNewItem={this.addNewItem}
            />
        );
    }
}

export default connect(
    MedicationStatementCodeSelector,
    new DependencyAdapter<IMedicationStatementSubstanceCodeSelectorProps, IMedicationStatementSubstanceCodeSelectorDependencies>((container) => {
        return {
            patientRegisterReferenceDataStore: container.resolve("PatientRegisterReferenceDataStore"),
            medicationsApiAdapter: container.resolve("MedicationsApiAdapter"),
            localizationService: container.resolve("IToolkitLocalizationService"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            internationalNonproprietaryNameApiAdapter: container.resolve("InternationalNonproprietaryNamesApiAdapter"),
            medicationStatementSubstanceApiAdapter: container.resolve("MedicationStatementSubstanceApiAdapter"),
            medicalConditionReferenceDataResolver: container.resolve("IMedicalConditionReferenceDataResolver")
        };
    })
);
