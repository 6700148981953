import React, { LegacyRef, Fragment, useCallback } from "react";
import EditableLayoutGroupBlockStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableLayoutGroupBlockStore";
import Styles from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditor.less";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import IEditableLayoutStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/IEditableLayoutStore";
import BlockDropZone from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/BlockDropZone";
import EditableLayoutAnyBlock from "./EditableLayoutAnyBlock";
import { GroupBox, Icon } from "@CommonControls";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import { FormLayoutContextProvider } from "@Toolkit/FormEngine/Panels/FormLayoutContext";

interface IEditableLayoutGroupBlock {
    block: EditableLayoutGroupBlockStore;
    parentCollection: IObservableArray<IEditableLayoutStore>;
    opacity: number;
}

function EditableLayoutGroupBlock(props: IEditableLayoutGroupBlock, ref: LegacyRef<HTMLDivElement>) {

    const store = useFormLayoutEditorStore();
    const removeGroup = useCallback(() => {
        store.removeBlock(props.parentCollection, props.block);
    }, [props.parentCollection, props.block]);

    const click = useCallback(() => {
        store.selectGroup(props.block);
    }, [props.block]);

    return (
        <div ref={ref} className={combineClasses(Styles.elementGroup, store.selectedGroup === props.block && Styles.selected)} style={{ opacity: props.opacity, cursor: "pointer" }} onClick={click}>
            <GroupBox title={props.block.localizedLabel}>
                <FormLayoutContextProvider dataReference={props.block.dataReference}>
                    <BlockDropZone collection={props.block.contentBlocks} indexToInsert={0} />
                    {props.block.contentBlocks.map((b, idx) => (
                        <Fragment key={b.id}>
                            <EditableLayoutAnyBlock block={b} parentCollection={props.block.contentBlocks} />
                            <BlockDropZone collection={props.block.contentBlocks} indexToInsert={idx + 1} />
                        </Fragment>
                    ))}
                </FormLayoutContextProvider>
            </GroupBox>
            <div className={Styles.toolbar} style={{ visibility: store.areDropZonesHighlighted ? "hidden" : "visible" }}>
                <button onClick={removeGroup}>x</button>
                <Icon iconName="bars" />
            </div>
        </div>
    );
}

const _EditableLayoutGroupBlock = React.forwardRef(EditableLayoutGroupBlock);
export default State.observer(_EditableLayoutGroupBlock);