import React, { useMemo, useCallback } from "react";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EditableShouldMatchPatternValidationRule from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/Validation/EditableShouldMatchPatternValidationRule";
import { TextBox } from "@CommonControls";
import RuleTypeId from "@Primitives/RuleTypeId.g";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";

function ShouldMatchPatternValidationRulePropertyPanel() {

    const store = useFormLayoutEditorStore();

    const rule = useMemo(
        () => store.selectedEditorValidationRules.find(vr => vr.ruleType === RuleTypeId.ShouldMatchPattern.value) as EditableShouldMatchPatternValidationRule,
        [store.selectedEditorValidationRules]
    );
    const setPattern = useCallback((value: string) => {

        if (!isNullOrEmptyString(value)) {
            if (rule) {
                rule.setPattern(value);
            } else {
                store.addValidationRule(new EditableShouldMatchPatternValidationRule(
                    store.selectedEditor.dataReference,
                    {
                        pattern: value
                    }
                ), store.selectedEditor.parentDataReferences);
            }
        } else {
            store.removeValidationRule(rule);
        }

    }, [rule, store.selectedEditor.dataReference]);

    return (
        <TextBox label="Minta (regex)" automationId="FieldPAttern_NumberBox" value={rule?.pattern ?? ""} onChange={setPattern} />
    );
}

export default State.observer(ShouldMatchPatternValidationRulePropertyPanel);