import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import ServiceRequestFilter from "@Primitives/ServiceRequestFilter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import { parseUseCaseStateFromUrlParam, getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import PatientEHRRoutes, { IPatientEHRServiceRequestsRouteParams } from "@HisPlatform/Components/Pages/Patient/PatientEHRRoutes";
import ServiceRequestMasterDetailPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestMasterDetailPanel/ServiceRequestMasterDetailPanel";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import SingleLayout from "@CommonControls/Layout/SingleLayout";

@State.observer
export default class PatientsServiceRequestsPage extends React.Component<IRoutingFrameContentProps<IPatientEHRServiceRequestsRouteParams>> {

    @State.computed private get selectedId() {
        const rawId = this.props.routingController.currentRoute.parameters.selectedId;
        return isNullOrUndefined(rawId) ? null : new ServiceRequestId(rawId);
    }

    @State.computed private get useCase() {
        return parseUseCaseStateFromUrlParam(this.props.routingController.currentRoute.parameters.useCase);
    }

    public render() {
        return (
            <SingleLayout>
                <ServiceRequestMasterDetailPanel
                    selectedId={this.selectedId}
                    onCloseUseCase={this.clearUseCase}
                    useCase={this.useCase}
                    onChange={this.setUseCase}
                    mode="read-only"
                    serviceRequestFilter={ServiceRequestFilter.Patient}
                    onServiceRequestFilterChange={null}
                    onBack={this.goBack}
                />
            </SingleLayout>
        );
    }

    @State.bound
    private setUseCase(id: ServiceRequestId, state: INDataUseCaseState) {
        this.props.routingController.replace(PatientEHRRoutes.serviceRequests.makeRoute({
            ...this.props.routingController.currentRoute.parameters,
            selectedId: id.value,
            useCase: getUseCaseAsUrlParam(state.useCase, state.displayMode, state.useCaseArguments)
        }));
    }

    @State.bound
    private clearUseCase(_successfullySaved: boolean) {
        this.props.routingController.replace(PatientEHRRoutes.serviceRequests.makeRoute({
            ...this.props.routingController.currentRoute.parameters,
            selectedId: null,
            useCase: null
        }));
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }
}