import CheckBox from "@CommonControls/CheckBox";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import DataGridNumberColumn from "@CommonControls/DataGrid/Column/DataGridNumberColumn";
import { IDataGridColumnChildProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DataGrid from "@CommonControls/DataGrid/DataGrid";
import Icon from "@CommonControls/Icon";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import FinancedServiceWithQuantityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/FinancedServiceWithQuantityStore";
import PerformedServicesCostCell from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PerformedServicesPanel/PerformedServicesCostCell";
import { getIconNameForCareContactType } from "@HisPlatform/BoundedContexts/Care/Services/CareContactTypeIconProvider";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import { provideBillableServicesPanelStore, useBillableServicesPanelStore } from "@HisPlatform/Packages/Patients/Components/BillableServicesPanel/BillableServicesPanelStoreProvider";
import { PatientPerformedServiceListItem } from "@HisPlatform/Packages/Patients/Components/BillableServicesPanel/PatientPerformedServiceList";
import CareActivityId from "@Primitives/CareActivityId.g";
import InvoiceId from "@Primitives/InvoiceId.g";
import PatientId from "@Primitives/PatientId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import React, { useCallback, useMemo } from "react";
import Styles from "./BillableServicesPanel.less";
import { PatientServiceList, PatientServiceListItem } from "./PatientServiceList";
import BillableServiceColumnCell, { IServiceRequestDisplayData } from "./BillableServiceColumnCell";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import { PatientServiceRequestListItem } from "./PatientServiceRequestList";
import Money from "@Toolkit/CommonWeb/Model/Money";
import { PatientPerformedServiceToBill, PatientServiceRequestToBill, PatientServiceToBill } from "./PatientServiceToBill";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";

export interface IBillableServicesPanelProps {
    _patientId?: PatientId;
    invoiceId?: InvoiceId;
    value: PatientServiceToBill[];
    onChange: (newValue: PatientServiceToBill[]) => void;
    onCheckItem?: (item: PatientServiceToBill) => void;
}

function BillableServicesPanel(props: IBillableServicesPanelProps) {

    const store = useBillableServicesPanelStore();

    if (store.isLoading) {
        return null;
    }

    return (
        <div>
            {store.notBilledServices?.map(patientServiceList => (
                <CareActivityPerformedServicesPanel
                    key={patientServiceList.careActivityId.value}
                    list={patientServiceList}
                />
            ))}
        </div>
    );
}

function CareActivityPerformedServicesPanel(props: {
    list: PatientServiceList,
}) {

    const { organizationReferenceDataStore } = useDependencies(c => ({
        organizationReferenceDataStore: c.resolve<OrganizationReferenceDataStore>("OrganizationReferenceDataStore"),
    }));

    const store = useBillableServicesPanelStore();

    return (
        <div className={Styles.careActivityBlock}>
            <div className={Styles.titleRow}>
                <SpanWithIcon iconName={getIconNameForCareContactType(props.list.careContactType)}># {props.list.careIdentifier}</SpanWithIcon>
                <SpanWithIcon iconName="pin">{organizationReferenceDataStore.allPointsOfCareMap.get(props.list.pointOfCareId)?.shorthand}</SpanWithIcon>
                <SpanWithIcon iconName="clock">{props.list.wentUnderCareAt.format("L LT")}</SpanWithIcon>
            </div>
            <div className={Styles.servicesGrid}>
                <DataGrid
                    dataSource={props.list.items}
                    isSelectable={false}
                    rowHeight="44px"
                    fixedHeight="100%"
                    automationId="__performedServicesList"
                    footer={null}
                >
                    <DataGridColumn
                        header={StaticCareResources.OutpatientWorkflow.PerformedServicesStep.MedicalService}
                        automationId="BillableService"
                        leftPadding="small">
                        <BillableServiceColumnCell
                            dataGetter={getBillableServiceData}
                            medicalServiceDisplayMode="code-name"
                        />
                    </DataGridColumn>
                    <DataGridNumberColumn
                        width="60px"
                        header={StaticCareResources.OutpatientWorkflow.PerformedServicesStep.Quantity}
                        automationId="Quantity"
                        integerColumn
                        dataGetter="quantity"
                    />
                    <DataGridColumn
                        width="100px"
                        header={StaticCareResources.OutpatientWorkflow.PerformedServicesStep.Money}
                        automationId="Money"
                        cellTextAlign="right"
                        headerTextAlign="right"
                    >
                        <PerformedServicesCostCell dataGetter={getFinancedServiceData} useLocalizationService />
                    </DataGridColumn>
                    <DataGridColumn
                        width="55px"
                        rightPadding="small"
                        header=""
                        cellTextAlign="right"
                        automationId="Actions"
                        onRenderCellValue={(_, row) => (
                            <State.Observer>{() => (
                                <RowActions
                                    careActivityId={props.list.careActivityId}
                                    value={store.props.value}
                                    onChange={store.props.onChange}
                                    onCheck={store.props.onCheckItem}
                                    row={row}
                                />
                            )}</State.Observer>
                        )}
                    />
                </DataGrid>
            </div>
        </div>
    );
}

interface IRowActionsProps extends IDataGridColumnChildProps<PatientServiceListItem, any> {
    careActivityId: CareActivityId,
    value: PatientServiceToBill[],
    onChange: (newValue: PatientServiceToBill[]) => void,
    onCheck: (item: PatientServiceToBill) => void,
}

function RowActionsCore(props: IRowActionsProps) {
    const { localizationService } = useDependencies(c => ({
        localizationService: c.resolve<ILocalizationService>("ILocalizationService")
    }));

    const performedServicesToBill = useMemo(
        () => props.value.filter(v => v instanceof PatientPerformedServiceToBill) as PatientPerformedServiceToBill[],
        [props.value, props.row]
    );

    const serviceRequestsToBill = useMemo(
        () => props.value.filter(v => v instanceof PatientServiceRequestToBill) as PatientServiceRequestToBill[],
        [props.value, props.row]
    );

    const localizedServiceRequestName = useMemo(
        () => {
            if (!(props.row instanceof PatientServiceRequestListItem)) {
                return null;
            }

            return `${props.row.serviceRequestIdentifier} - ${StaticFinanceResources.InvoiceScreen.InvoiceItems.BillableServiceColumn.ServiceRequestName} (${localizationService.localizeDateTime(props.row.createdAt)})`;
        },
        [props.row]
    );

    const isChecked = useMemo(() => {
            if (props.row instanceof PatientPerformedServiceListItem) {
                const typedRow = props.row as PatientPerformedServiceListItem;
                return performedServicesToBill.some(
                    l => ValueWrapper.equals(l.careActivityId, props.careActivityId)
                         && ValueWrapper.equals(l.medicalServiceFinancingId, typedRow.medicalServiceFinancingId)
                );
            }
            if (props.row instanceof PatientServiceRequestListItem) {
                const typedRow = props.row as PatientServiceRequestListItem;
                return serviceRequestsToBill.some(
                    l => ValueWrapper.equals(l.careActivityId, props.careActivityId)
                         && ValueWrapper.equals(l.serviceRequestId, typedRow.serviceRequestId)
                );
            }

            return false;
        },
        [props.value, props.row]
    );

    const setChecked = useCallback((value: boolean) => {
        if (value) {
            let newItem;
            if (props.row instanceof PatientPerformedServiceListItem) {
                newItem = new PatientPerformedServiceToBill(
                    props.careActivityId,
                    props.row.quantity,
                    props.row.medicalServiceFinancingId,
                    props.row.medicalServiceId,
                    props.row.financedServiceId
                );
            } else if (props.row instanceof PatientServiceRequestListItem) {
                newItem = new PatientServiceRequestToBill(
                    props.careActivityId,
                    props.row.quantity,
                    props.row.serviceRequestId,
                    props.row.totalNetPrice.amount,
                    localizedServiceRequestName
                );
            } else {
                return;
            }

            props.onCheck?.(newItem);

            props.onChange([...props.value, newItem]);
        } else {
            props.onChange([...props.value.filter(i => {
                if (i instanceof PatientPerformedServiceToBill) {
                    if (!(props.row instanceof PatientPerformedServiceListItem)) {
                        return true;
                    }

                    return !(
                        ValueWrapper.equals(i.careActivityId, props.careActivityId)
                        && ValueWrapper.equals(i.medicalServiceFinancingId, props.row.medicalServiceFinancingId)
                    );
                }
                
                if (i instanceof PatientServiceRequestToBill) {
                    if (!(props.row instanceof PatientServiceRequestListItem)) {
                        return true;
                    }

                    return !(
                        ValueWrapper.equals(i.careActivityId, props.careActivityId)
                        && ValueWrapper.equals(i.serviceRequestId, props.row.serviceRequestId)
                    );
                }

                return true;
            })]);
        }
    }, [props.value, props.careActivityId, props.row, props.onChange]);

    return props.row.isBilled ? (
        <Icon iconName="fileInvoiceSolid" className={Styles.billedIcon} tooltipContent={StaticFinanceResources.InvoiceScreen.Tooltip.AlreadyBilled} />
    ) : (
        <CheckBox align="right" automationId="checked" value={props.row.isBilled || isChecked} onChange={setChecked} />
    );
}

const RowActions = State.observer(RowActionsCore);

function getFinancedServiceData(row: PatientServiceListItem): FinancedServiceWithQuantityStore[] | Money {
    if (row instanceof PatientPerformedServiceListItem) {
        return [new FinancedServiceWithQuantityStore(
            row.medicalServiceFinancingId,
            new EntityVersionSelector(row.financedServiceId, LocalDate.today()),
            row.quantity,
            null
        )];
    }

    if (row instanceof PatientServiceRequestListItem) {
        return row.totalNetPrice;
    }

    return null;
}

function getBillableServiceData(row: PatientServiceListItem): MedicalServiceId | IServiceRequestDisplayData {
    if (row instanceof PatientPerformedServiceListItem) {
        return row.medicalServiceId;
    }

    if (row instanceof PatientServiceRequestListItem) {
        return {
            createdAt: row.createdAt,
            serviceRequestIdentifier: row.serviceRequestIdentifier
        } as IServiceRequestDisplayData;
    }

    return null;
}

export default connect(
    provideBillableServicesPanelStore(BillableServicesPanel),
    new PatientContextAdapter<IBillableServicesPanelProps>((ctx) => ({
        _patientId: ctx.patientId,
    })),
);