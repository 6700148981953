import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import Styles from "./ImageViewer.less";

export enum ValidMimeTypesForImageAndPdfViewer {
    Jpeg = "image/jpeg",
    Png = "image/png",
    Bmp = "image/bmp",
    Pdf = "application/pdf",
    Gif = "image/gif"
}
interface IImageViewer {
    data: Blob;
    alt: string;
}

@State.observer
export default class ImageViewer extends React.Component<IImageViewer> {
    @State.observable.ref private base64image: string;
    @State.observable.ref private isLoading = true;

    public componentDidMount() {
        dispatchAsyncErrors(this.convertBlobToBase64Async(), this);
    }

    public componentDidUpdate(prevProps: IImageViewer) {
        if (this.props.data !== prevProps.data) {
            dispatchAsyncErrors(this.convertBlobToBase64Async(), this);
        }
    }
    @State.action.bound
    public base64imageSetter(base64image: string) {
        this.base64image = base64image;
        this.isLoading = false;
    }

    @State.bound
    public async convertBlobToBase64Async() {
        if (this.validateBlobMimeType()) {
            const uInt8byteArray = await this.props.data?.toUint8ArrayAsync();
            this.base64imageSetter(Base64Converter.fromByteArray(uInt8byteArray));
        }
    }

    @State.bound
    private validateBlobMimeType() {
        if (this.props.data.type === ValidMimeTypesForImageAndPdfViewer.Jpeg || this.props.data.type === ValidMimeTypesForImageAndPdfViewer.Gif || this.props.data.type === ValidMimeTypesForImageAndPdfViewer.Bmp || this.props.data.type === ValidMimeTypesForImageAndPdfViewer.Png) {
            return true;
        }
        return false;
    }
    public render() {
        if (this.isLoading) {
            return <></>;
        }

        return (
            <div className={Styles.container}>
                <img className={Styles.image} alt={this.props.alt} src={`data:${this.props.data.type};base64,${this.base64image}`} />
            </div>
        );
    }
}