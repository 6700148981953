import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/Proxy.g";
import Di from "@Di";
import NeakPerformanceStatementId from "@Primitives/NeakPerformanceStatementId.g";
import NeakPerformanceStatement from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/NeakPerformanceStatement";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";

type ResponseType =
    Proxy.GetNeakPerformanceStatementByIdQueryResponse |
    Proxy.CreateNeakPerformanceStatementCommandResponse |
    Proxy.CreateNeakRevisionPerformanceStatementCommandResponse |
    Proxy.SetPerformanceStatementTransactionCodeCommandResponse |
    Proxy.SetPerformanceStatementErrorListCommandResponse;

@Di.injectable()
export default class NeakPerformanceStatementStoreMapper extends EntityStoreMapper<Proxy.NeakPerformanceStatementDto, NeakPerformanceStatement, ResponseType> {

    protected storeEntityIdType = NeakPerformanceStatementId;

    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    protected entityDtoSelector(response: ResponseType) {
        if (response instanceof Proxy.NeakPerformanceStatementDto) {
            return response;
        }
        return response.neakPerformanceStatementDto;
    }

    public applyToStoreCore(target: NeakPerformanceStatement, response: ResponseType) {
        if (!response.neakPerformanceStatementDto) {
            return;
        }

        const dto = this.entityDtoSelector(response);

        target.id = dto.id;
        target.rowVersion = dto.rowVersion;
        target.transactionCode = dto.transactionCode;
        target.type = dto.type;
        target.reportValidityDate = dto.reportValidityDate;
        target.largeDataIds = [dto.contentId, dto.dataSheetId];
    }
}
