import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import IReportingRouteParams from "@HisPlatform/Application/Routes/IReportingRouteParams";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { IReportDefinition } from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/Model/IReportDefinition";
import ReportingReferenceDataStore from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/Model/ReportingReferenceDataStore";
import Route from "@Toolkit/ReactClient/Routing/Abstractions/Route";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

interface IReportingPageDependencies {
    reportingReferenceDataStore: ReportingReferenceDataStore;
}

interface IReportingPageProps extends IRoutingFrameContentProps {
    _dependencies?: IReportingPageDependencies;
}

@State.observer
class ReportingPage extends React.Component<IReportingPageProps> {

    @State.computed private get reportDefinitionId(): string {
        return (this.props.routingController.currentRoute.parameters as IReportingRouteParams).reportDefinitionId;
    }

    private get reportingReferenceDataStore() { return this.props._dependencies.reportingReferenceDataStore; }

    @State.observable.ref private reportDefinition: IReportDefinition = null;

    @State.action.bound
    private setReportDefinition(reportDefinition: IReportDefinition) {
        this.reportDefinition = reportDefinition;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IReportingPageProps) {
        if ((prevProps.routingController.currentRoute.parameters as IReportingRouteParams).reportDefinitionId !== this.reportDefinitionId) {
            this.setReportDefinition(this.reportingReferenceDataStore.reportingDefinitions.tryGetById(this.reportDefinitionId));
        }
    }

    private async loadAsync() {
        await this.reportingReferenceDataStore.reportingDefinitions.ensureLoadedAsync();
        this.setReportDefinition(this.reportingReferenceDataStore.reportingDefinitions.tryGetById(this.reportDefinitionId));
    }

    @State.action.bound
    private onCancel() {
        this.reportDefinition = null;
    }

    @State.bound
    private navigateTo(route: Route<any>) {
        this.props.routingController.push(route);
    }

    public render() {
        return (
            this.reportDefinition && (
                <>
                    <div>
                        <LoadingBoundary>
                            <HisPlatformExtensionPoint
                                type="reportingExtension"
                                extensionProps={{
                                    onCancel: this.onCancel,
                                    definition: this.reportDefinition,
                                    onNavigateTo: this.navigateTo
                                }}>
                                <></>
                            </HisPlatformExtensionPoint>
                        </LoadingBoundary>
                    </div>
                </>
            )
        );
    }
}
export default connect(
    ReportingPage,
    new DependencyAdapter<IReportingPageProps, IReportingPageDependencies>(c => ({
        reportingReferenceDataStore: c.resolve("ReportingReferenceDataStore")
    }))
);