import Dashboard from "@CommonControls/Dashboard";
import SidebarLayout from "@CommonControls/SidebarLayout/SidebarLayout";
import { renderError } from "@HisPlatform/Components/HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import { ISidebarPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { HisErrorBoundary } from "@HisPlatformControls";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import React from "react";
import { useScreenStateContext } from "./ScreenStateContext";

interface IScreenSidebarWrapperProps {
    sidebarComponent?: React.ComponentType<ISidebarPropsBase<ShowScreenFrontendActionBase>>;
}

const renderDashboardError = (err: Error, reload: () => void) => (
    <Dashboard orientation="vertical" mode="normal">
        {renderError(err, reload)}
    </Dashboard>
);

export default function ScreenSidebarWrapper(props: React.PropsWithChildren<IScreenSidebarWrapperProps>) {
    const store = useScreenStateContext();

    if (store?.displayMode === ScreenDisplayMode.Full && !!props?.sidebarComponent) {

        const sidebarProps: ISidebarPropsBase = {
            action: store.action,
            screenState: store.screenState
        };

        return (
            <SidebarLayout
                leftSidebar={(
                    <HisErrorBoundary onRenderError={renderDashboardError}>
                        {React.createElement(props.sidebarComponent, sidebarProps)}
                    </HisErrorBoundary>
                )}>
                {props.children}
            </SidebarLayout>
        );
    }

    return (
        <>
            {props.children}
        </>
    );
}