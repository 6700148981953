import DependencyContainer from "@DiContainer";

import IHeaderProvider from "@Toolkit/CommonWeb/Abstractions/HttpRequests/HeaderProvider/IHeaderProvider";
import HeaderProvider from "@HisPlatform/Services/Implementation/HeaderProvider/HeaderProvider";
import IHeaderProviderService from "@HisPlatform/Services/Definition/HeaderProvider/IHeaderProviderService";
DependencyContainer
    .bind("IHeaderProvider")
    .to(HeaderProvider)
    .inSingletonScope();

DependencyContainer
    .bind("IHeaderProviderService")
    .toDynamicValue(() => DependencyContainer.get<IHeaderProviderService>("IHeaderProvider"));