import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";
import ModalServiceReactContext from "@Toolkit/ReactClient/Components/ModalService/ModalServiceContext";
import ModalContextStore from "@Toolkit/ReactClient/Components/ModalService/ModalContextStore";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import PatientContext, { PatientReactContext } from "@HisPlatform/Model/DomainModel/PatientContext/PatientContext";
import CareActivityContext, { CareActivityReactContext } from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContext";
import HisModalService from "@HisPlatform/Services/Implementation/HisModalService/HisModalService";

export default class HisModalServiceAdapter<TProps extends { _modalService?: IModalService }> implements IComponentAdapter<TProps> {

    public get usedContexts(): Array<React.Context<any>> {
        return [ModalServiceReactContext, PatientReactContext, CareActivityReactContext];
    }

    public getMemoizedState(props: TProps, contextValues?: Map<React.Context<any>, any>): HisModalService {
        const modalServiceStore = contextValues.get(ModalServiceReactContext) as ModalContextStore;
        const patientContextStore = contextValues.get(PatientReactContext) as PatientContext;
        const careActivityContextStore = contextValues.get(CareActivityReactContext) as CareActivityContext;

        return new HisModalService(
            modalServiceStore,
            patientContextStore,
            careActivityContextStore
        );
    }

    public getMappedProps(props: TProps, contextValues: Map<React.Context<any>, any>, memoizedState: HisModalService): Partial<TProps> {
        const patientContextStore = contextValues.get(PatientReactContext) as PatientContext;
        const careActivityContextStore = contextValues.get(CareActivityReactContext) as CareActivityContext;

        memoizedState.careActivityContext = careActivityContextStore;
        memoizedState.patientContext = patientContextStore;

        return {
            _modalService: memoizedState as IModalService
        } as Partial<TProps>;
    }

}