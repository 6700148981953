export function expandValidationPropertyPaths(multiKey: string) {
    const pathSegments = multiKey.split(".");
    const pathSubSegments = pathSegments.map(s => s.split(","));
    const paths = new Set<string>();

    const combinations = pathSubSegments.reduce<number>((prev: number, currentSubSegments: string[]) => {
        return prev * currentSubSegments.length;
    }, 1);

    const maxIndices = pathSubSegments.map(subSegments => subSegments.length - 1);
    const currentIndices = maxIndices.map(_ => 0);

    for (let index = 0; index < combinations; index++) {

        paths.add(pathSubSegments.map((subSegment, subSegmentIndex) => {
            return subSegment[currentIndices[subSegmentIndex]];
        }).join("."));

        setNextIndices(currentIndices, maxIndices);
    }

    return Array.from(paths.values());
}

function setNextIndices(indices: number[], maxIndices: number[]) {

    let currentDigitIndex = 1;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        const currentIndex = indices.length - currentDigitIndex;

        indices[currentIndex]++;
        if (indices[currentIndex] > maxIndices[currentIndex]) {
            indices[currentIndex] = 0;
            currentDigitIndex++;

            if (currentDigitIndex > 1000) {
                throw new Error("PropertyPathExpander reached its limits.");
            }
        } else {
            break;
        }
    }
}