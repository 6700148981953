import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IConfigurationResources from "./IConfigurationResources";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";

const resources = createStaticResourceDictionary<IConfigurationResources>();

export function loadStaticConfigurationResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    resources.load(localizationService.getResourceGroup("WebApp").Configuration);
}

export default resources;