import State from "@Toolkit/ReactClient/Common/StateManaging";
import PregnancyOutcomeAssignmentBase from "./PregnancyOutcomeAssignmentBase";
import CategoryObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/CategoryObservation";

export default class ReferencedPregnancyOutcomeAssignment extends PregnancyOutcomeAssignmentBase  {
    @State.observable.ref public categoryObservation: CategoryObservation = null;

    constructor(categoryObservation: CategoryObservation) {
        super();
        this.categoryObservation = categoryObservation;
    }
}