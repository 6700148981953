import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { LifeStyleRiskCodeSelectorItem } from "@HisPlatformControls/LifeStyleRiskCodeSelector/LifeStyleRiskCodeSelectorItem";
import * as HisUi from "@HisPlatformControls";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import LifeStyleRisksApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/LifeStyleRisksApiAdapter";
import ILifeStyleRisk from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/ILifeStyleRisk";
import IMedicalConditionReferenceDataResolver from "@HisPlatform/BoundedContexts/Care/Services/Definition/MedicalConditionReferenceDataResolver/IMedicalConditionReferenceDataResolver";

interface ILifeStyleRiskCodeSelectorDependencies {
    lifeStyleRisksApiAdapter: LifeStyleRisksApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
    medicalConditionReferenceDataResolver: IMedicalConditionReferenceDataResolver;
}

interface ILifeStyleRiskCodeSelectorProps extends ICodeSelectorCommonProps<LifeStyleRiskCodeSelectorItem> {
    _dependencies?: ILifeStyleRiskCodeSelectorDependencies;
}

@State.observer
class LifeStyleRiskCodeSelector extends React.Component<ILifeStyleRiskCodeSelectorProps> {

    private get dependencies() { return this.props._dependencies; }
    private get apiAdapter() { return this.dependencies.lifeStyleRisksApiAdapter; }
    private get referenceDataStore() { return this.dependencies.careReferenceDataStore.lifeStyleRisks; }
    private get medicalConditionReferenceDataResolver() { return this.dependencies.medicalConditionReferenceDataResolver; }

    @State.bound
    public async getDisplayValueAsync(value: LifeStyleRiskCodeSelectorItem) {
        return await this.medicalConditionReferenceDataResolver.getLifeStyleRiskNameAsync(value);
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const results = await this.apiAdapter.searchLifeStyleRisks(
            text,
            10
        );

        if (results.operationWasSuccessful) {
            const lifeStyleRisks = results.value.map(x => {
                const textOfQuickSearch = `${x.name} (${x.code})`;
                if (x.id) {
                    return new LifeStyleRiskCodeSelectorItem({
                        id: x.id,
                        text: textOfQuickSearch
                    });
                }
                return null;
            });

            await this.referenceDataStore.ensureLoadedAsync(lifeStyleRisks.map(x => x.id));
            return lifeStyleRisks;
        }
        return null;
    }

    @State.bound
    private addNewItem(text: string): LifeStyleRiskCodeSelectorItem {
        return new LifeStyleRiskCodeSelectorItem({ text: text });
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: ILifeStyleRisk[]) {
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;

        const results = await this.apiAdapter.complexSearchLifeStyleRisks(
            filterText,
            customOrdering,
            paging);

        await this.referenceDataStore.ensureLoadedAsync(results.value.items.map(value => value.id));

        return results.value;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: LifeStyleRiskCodeSelectorItem) {
        this.props.onChange({ id: item.id });
    }

    @State.bound
    private onComplexSearchMultiSelect(items: ILifeStyleRisk[]) {
        this.props.onChange(items.map(item => ({ id: item.id, text: item.code })));
    }
    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticCareResources.MedicalCondition.LifeStyleRisks.Title,
            codeGetter: "code",
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect,
            hideAllgroup: true
        };
        return (
            <HisUi.UniversalCodeSelector
                {...props}
                isCreatable
                addNewItem={this.addNewItem}
            />
        );
    }
}

export default connect(
    LifeStyleRiskCodeSelector,
    new DependencyAdapter<ILifeStyleRiskCodeSelectorProps, ILifeStyleRiskCodeSelectorDependencies>((container) => {
        return {
            lifeStyleRisksApiAdapter: container.resolve("LifeStyleRisksApiAdapter"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            medicalConditionReferenceDataResolver: container.resolve("IMedicalConditionReferenceDataResolver"),
        };
    })
);
