import NDataScreen from "@HisPlatform/BoundedContexts/Productivity/Components/NDataScreen/NDataScreen";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import React, { useCallback } from "react";
import ShowPatientInvoiceListScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientInvoiceListScreenAction.g";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import InvoiceListScreenApiAdapter from "./InvoiceListScreenApiAdapter";

export function InvoiceListScreen(props: IScreenPropsBase<ShowPatientInvoiceListScreenAction>) {
    const { invoiceListScreenApiAdapter } = useDependencies(c => ({
        invoiceListScreenApiAdapter: c.resolve<InvoiceListScreenApiAdapter>("InvoiceListScreenApiAdapter"),
    }));

    const getDynamicListAsync = useCallback(async (args: FrontendListParameters) => {
        const response = await invoiceListScreenApiAdapter.getInvoiceListAsync(props.action.patientId, args);
        return response.result;
    }, []);

    return (
        <NDataScreen
            iconName="fileInvoiceSolid"
            onGetDynamicListAsync={getDynamicListAsync}
            defaultOrderingColumnName="InvoiceBased_IssuedOn"
            defaultOrderingDirection="desc"
            hasRefreshButton />
    );
}