import PatientId from "@Primitives/PatientId.g";
import * as CareProxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { getHistoryItemDetailDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalConditionDtoMapper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import ProcedureStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ProcedureStatement/ProcedureStatementStore";
import ProcedureStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ProcedureStatement/ProcedureStatementHistoryItem";
import ReferencedMedicalServiceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ProcedureStatement/ReferencedMedicalServiceAssignment";
import CustomProcedureAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ProcedureStatement/CustomProcedureAssignment";
import ProcedureAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ProcedureStatement/ProcedureAssignmentBase";
import ProcedureStatementId from "@Primitives/ProcedureStatementId.g";

export function getAddProcedureStatementControllerDto(source: ProcedureStatementStore, patientId: PatientId, isValidateOnly: boolean) {
    return new CareProxy.AddProcedureStatementControllerDto({
        patientId: patientId,
        historyItemDtos: [getProcedureStatementHistoryItemDto(source.latestHistoryItem as ProcedureStatementHistoryItem)],
        procedureAssignmentBaseDto: getProcedureAssignmentDto(source.procedureAssignment),
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData
    });
}

export function getUpdateProcedureStatementControllerDto(source: ProcedureStatementStore, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.UpdateProcedureStatementControllerDto({
        procedureStatementId: source.id,
        historyItemDtos: [getProcedureStatementHistoryItemDto(source.latestHistoryItem as ProcedureStatementHistoryItem)],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteProcedureStatementHistoryItemControllerDto(source: ProcedureStatementStore, versionNumber: number, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.DeleteProcedureStatementHistoryItemControllerDto({
        procedureStatementId: source.id,
        versionNumbers: [versionNumber],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteProcedureStatementControllerDto(store: ProcedureStatementStore) {
    return new CareProxy.DeleteProcedureStatementControllerDto({
        procedureStatementId: store.id,
        lockId: store.lockInfo?.lockId,
        extensionData: store.extensionData,
        releaseLockIfSuccessful: true
    });
}

function getProcedureAssignmentDto(source: ProcedureAssignmentBase) {
    let dto;

    if (source instanceof ReferencedMedicalServiceAssignment) {
        dto = new CareProxy.ReferencedMedicalServiceAssignmentDto({ medicalServiceId: source.medicalServiceId });
    } else if (source instanceof CustomProcedureAssignment) {
        dto = new CareProxy.CustomProcedureAssignmentDto({ name: source.name });
    }

    return dto;
}

function getProcedureStatementHistoryItemDto(source: ProcedureStatementHistoryItem) {
    return new CareProxy.ProcedureStatementHistoryItemDto({
        versionNumber: source.versionNumber,
        index: source.index,
        createdAt: source.createdAt,
        historyItemDetail: getHistoryItemDetailDto(source.historyItemDetail),
        description: source.description,
        performedAt: source.performedAt,
        extensionData: source.extensionData
    });
}