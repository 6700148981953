import React from "react";
import * as Recharts from "recharts";
import { IChartProps } from "@CommonControls/Chart/IChartProps";

export type PositionType =
    "top" | "left" | "right" | "bottom" | "inside" | "outside" | "insideLeft" | "insideRight" |
    "insideTop" | "insideBottom" | "insideTopLeft" | "insideBottomLeft" | "insideTopRight" |
    "insideBottomRight" | "insideStart" | "insideEnd" | "end" | "center";

export type ContentRenderer<P> = (props: P) => React.ReactNode;
export type AxisDomain = string | number | ContentRenderer<any> | "auto" | "dataMin" | "dataMax";

export interface IAxisProps {
    type?: "category" | "number";
    dataKey?: string;
    domain?: [AxisDomain, AxisDomain];
    allowDecimals?: boolean;
}

export interface ILabelProps {
    position?: PositionType;
    stroke?: number | string;
    fill?: string;
}

export interface IBarProps {
    dataKey: string;
    fill?: string;
    name?: string;
    nameKey?: string;
    label?: ILabelProps;
    getFill?: (name: string) => string;
    unit?: string;
}

export interface IBarChartProps extends IChartProps {
    theme?: "normal" | "dark";
    barSettings?: IBarProps[];
    XAxisProps?: IAxisProps;
    YAxisProps?: IAxisProps;
    layout?: "horizontal" | "vertical";
    withGrid?: boolean;
    XAxisTick?: boolean;
    YAxisTick?: boolean;
}


const BarChart: React.FC<IBarChartProps> = (props) => {

    const isDarkTheme = props.theme && props.theme === "dark";

    return (
        <Recharts.ResponsiveContainer>
            <Recharts.BarChart
                width={props.width}
                height={props.height}
                data={props.data}
                syncId={props.syncId}
                margin={props.margin}
                layout={props.layout}
            >
                {props.withGrid && <Recharts.CartesianGrid
                    strokeDasharray="3 3"
                    stroke={isDarkTheme ? "white" : undefined}
                />}
                <Recharts.XAxis
                    dataKey={props.XAxisDataKey}
                    stroke={isDarkTheme ? "white" : undefined}
                    tick={props.XAxisTick}
                    {...props.XAxisProps}
                />
                <Recharts.YAxis
                    dataKey={props.YAxisDataKey}
                    stroke={isDarkTheme ? "white" : undefined}
                    tick={props.YAxisTick}
                    {...props.YAxisProps}
                />
                {props.isTooltipEnabled && <Recharts.Tooltip labelStyle={{ color: "#000" }} cursor={{ fill: "#636f84" }} />}
                {props.isLegendEnabled && <Recharts.Legend />}
                {props.barSettings.map(s => {
                    if (!s.getFill) {
                        return (
                            <Recharts.Bar
                                key={s.dataKey.toString()}
                                name={s.name}
                                dataKey={s.dataKey}
                                fill={s.fill}
                                label={s.label}
                                unit={s.unit}
                            />
                        );
                    } else {
                        return (
                            <Recharts.Bar
                                key={s.dataKey.toString()}
                                name={s.name}
                                dataKey={s.dataKey}
                                fill={s.fill}
                                label={s.label}
                                unit={s.unit}
                            >
                                {props.data.map((entry, key) => {
                                    return (
                                        <Recharts.Cell key={`cell_${key}`} fill={s.getFill(entry[s.nameKey])} />
                                    );
                                })}
                            </Recharts.Bar>
                        );
                    }
                })}

            </Recharts.BarChart>
        </Recharts.ResponsiveContainer>
    );
};

BarChart.defaultProps = {
    theme: "normal",
    barSettings: [
        {
            dataKey: "value",
            fill: "#00b4ec"
        }
    ]
};

export default BarChart;