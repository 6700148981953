import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import {HisErrorBoundary} from "@HisPlatformControls";
import AppointmentScheduleDefinitionsPanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/AppointmentScheduleDefinitionsPanel";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import {getUseCaseAsUrlParam, parseUseCaseStateFromUrlParam} from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import IAppointmentScheduleDefinitionsRouteParams from "@HisPlatform/Components/Pages/AppointmentScheduleDefinitionPages/IAppointmentScheduleDefinitionsRouteParams";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

@State.observer
export default class AppointmentScheduleDefinitionsPage extends React.Component<IRoutingFrameContentProps> {
    @State.computed
    private get selectedAppointmentScheduleDefinitionId(): string {
        const id = this.useCaseState?.useCaseArguments?.find(item => item.name === "appointmentScheduleDefinitionId")?.value;
        return id?.value;
    }

    @State.computed
    private get useCaseState() {
        const useCaseState = (this.props.routingController.currentRoute.parameters as IAppointmentScheduleDefinitionsRouteParams)?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState) as INDataUseCaseState;
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setUseCaseState(selectedAppointmentId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.replace(
            ApplicationRoutes.appointmentScheduleDefinitions.makeRoute({
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            })
        );
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(ApplicationRoutes.appointmentScheduleDefinitions.makeRoute());
    }

    public render() {
        return (
            <HisErrorBoundary>
                <LoadingBoundary>
                    <AppointmentScheduleDefinitionsPanel
                        onChange={this.setUseCaseState}
                        useCaseState={this.useCaseState}
                        selectedRowId={this.selectedAppointmentScheduleDefinitionId}
                        onBack={this.goBack}
                        onCloseDetail={this.setClosedState}
                    />
                </LoadingBoundary>
            </HisErrorBoundary>
        );
    }
}
