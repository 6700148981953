import Di from "@Di";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import { ICareActivityBasedFormDetailPanelProps } from "./CareActivityBasedFormDetailPanel";
import IFormEngineApiAdapter from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import DynamicPropertiesApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/DynamicProperties/DynamicPropertiesApiAdapter";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import StringProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/StringProperty";
import IFormScopes from "@Toolkit/FormEngine/Panels/FormPanel/IFormScopes";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

@Di.injectable()
export default class CareActivityBasedFormDetailPanelStore extends PanelStoreBase<ICareActivityBasedFormDetailPanelProps> {

    @State.observable public possibleFormDefinitions: IObservableArray<IFormDefinition> = State.observable([]);
    @State.observable public scopes: IFormScopes = null;

    constructor(
        @Di.inject("IFormEngineApiAdapter") private readonly formEngineApiAdapter: IFormEngineApiAdapter,
        @Di.inject("DynamicPropertiesApiAdapter") private readonly dynamicPropertiesApiAdapter: DynamicPropertiesApiAdapter
    ) {
        super();
    }

    public getReloadTriggerProps() {
        return [this.props.selectedId];
    }

    public readonly loadAsync = this.namedAsync("loadingCareActivityBasedFormDetailPanelStore", async () => {
        
        const scopes = {
            CareActivity: isNullOrUndefined(this.props._careActivityId) ? null : parseInt(this.props._careActivityId.value, 10),
            Patient: isNullOrUndefined(this.props._patientId) ? null : parseInt(this.props._patientId?.value, 10),
            PointOfCare: isNullOrUndefined(this.props._pointOfCareId) ? null : parseInt(this.props._pointOfCareId?.value, 10),
            OrganizationUnit: isNullOrUndefined(this.props._pointOfCareId) ? null : parseInt(this.props._pointOfCareId?.value, 10)
        } as IFormScopes;

        const definitions = (await this.formEngineApiAdapter.getFormDefinitionsAsync("CareActivity", true)).value;

        let filteredDescriptors = definitions;
        if (!isNullOrUndefined(this.props._pointOfCareId)) {
            const forbiddenFormDefinitions = await this.dynamicPropertiesApiAdapter.getPropertyValueByOrganizationUnitIdAsync(
                new OrganizationUnitId(this.props._pointOfCareId.value),
                "FormSettings",
                "FormDefinitionsDenied") as StringProperty;
    
            const forbiddenFormDefinitionsArray = forbiddenFormDefinitions.getEffectiveValue().split(";");
    
            filteredDescriptors = definitions.filter(x => !forbiddenFormDefinitionsArray.some(y => y === x.name));    
        }
        
        State.runInAction(() => {
            this.possibleFormDefinitions = State.observable(filteredDescriptors);
            this.scopes = scopes;
        });
    });
}
