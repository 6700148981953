import MedicationId from "@Primitives/MedicationId.g";
import EquipmentPositionId from "@Primitives/EquipmentPositionId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { EMedicationDosage, TextualEMedicationDosage } from "./EMedicationDosage";

export default class MedicationEquipmentListItem {
    @State.observable.ref public medicationVersionSelector: IEntityVersionSelector<MedicationId> = null;
    @State.observable public size: string = "";
    @State.observable public amount: number = 1;
    @State.observable.ref public equipmentPositionId: EquipmentPositionId = null;
    @State.observable public amountReason: string = "";
    @State.observable public bodyPart: string = "";
    @State.observable public instruction: string = "";
    @State.observable public instructionForDispenser: string = "";
    @State.observable public isInsubstitutable: boolean = false;
    @State.observable public insubstitutableReason: string = "";
    @State.observable.ref public dosage: EMedicationDosage = new TextualEMedicationDosage();
    @State.observable public faultDescription: string = "";
    @State.observable public serialNumber: string = "";
    @State.observable public supportPeriodMonths: number = null;

    @State.action.bound
    public setAmountReason(amountReason: string) {
        this.amountReason = amountReason;
    }

    @State.action.bound
    public setBodyPart(bodyPart: string) {
        this.bodyPart = bodyPart;
    }

    @State.action.bound
    public setInstruction(instruction: string) {
        this.instruction = instruction;
    }

    @State.action.bound
    public setInstructionForDispenser(instructionForDispenser: string) {
        this.instructionForDispenser = instructionForDispenser;
    }

    @State.action.bound
    public setIsInsubstitutable(isInsubstitutable: boolean) {
        this.isInsubstitutable = isInsubstitutable;
    }

    @State.action.bound
    public setInsubstitutableReason(insubstitutableReason: string) {
        this.insubstitutableReason = insubstitutableReason;
    }

    @State.action.bound
    public setDosage(dosage: EMedicationDosage) {
        this.dosage = dosage;
    }

    @State.action.bound
    public setMedicationId(medicationId: MedicationId, validOn?: LocalDate) {
        this.medicationVersionSelector = { id: medicationId, validOn: validOn || this.medicationVersionSelector?.validOn || LocalDate.today() };
    }
    
    @State.action.bound
    public setAmount(amount: number) {
        this.amount = amount;
    }
    
    @State.action.bound
    public setSize(size: string) {
        this.size = size;
    }

    @State.action.bound
    public setEquipmentPositionId(equipmentPositionId: EquipmentPositionId) {
        this.equipmentPositionId = equipmentPositionId;
    }

    @State.action.bound
    public setFaultDescription(faultDescription: string) {
        this.faultDescription = faultDescription;
    }

    @State.action.bound
    public setSerialNumber(serialNumber: string) {
        this.serialNumber = serialNumber;
    }

    @State.action.bound
    public setSupportPeriodMonths(supportPeriodMonths: number) {
        this.supportPeriodMonths = supportPeriodMonths;
    }
}
