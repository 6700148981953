import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import MedicalServiceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicalServiceApiAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import * as HisUi from "@HisPlatformControls";
import MedicalServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServiceStore";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import _ from "@HisPlatform/Common/Lodash";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import SchedulingConfigurationApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingConfigurationApiAdapter";
import IMedicalServiceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalServiceVersion";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import CareTypeId from "@Primitives/CareTypeId.g";

interface IMostRelevantServiceCodeSelectorDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
    medicalServiceApiAdapter: MedicalServiceApiAdapter;
    schedulingConfigurationApiAdapter: SchedulingConfigurationApiAdapter;
}

interface IMostRelevantServiceCodeSelectorProps extends ICodeSelectorCommonProps<MedicalServiceId | SchedulingServiceId> {
    _dependencies?: IMostRelevantServiceCodeSelectorDependencies;
    useAlternativeName: boolean;
    careTypeId?: CareTypeId;
}

@State.observer
class MostRelevantServiceCodeSelector extends React.Component<IMostRelevantServiceCodeSelectorProps> {
    private medicalServiceTotalCount: number = 0;
    private schedulingServiceTotalCount: number = 0;

    private get dependencies() {
        return this.props._dependencies;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);       
    }

    @State.bound
    private async loadAsync() {
        await this.dependencies.schedulingReferenceDataStore.appointmentScheduleDefinitions.ensureAllLoadedAsync();
    }

    @State.bound
    private async getDisplayValueAsync(value: MedicalServiceId | SchedulingServiceId) {
        if (value instanceof MedicalServiceId) {
            const item = await this.dependencies.careReferenceDataStore.medicalService.getOrLoadAsync(this.toVersionSelector(value));
            return item?.alternativeName && this.props.useAlternativeName && item.name !== item.alternativeName
                ? <><b>{item?.code.value}</b> {item?.name}<br /> <i style={{ paddingLeft: "24px" }}>{item?.alternativeName}</i> </>
                : <><b>{item?.code.value}</b>{` ${item?.name}`}</>;
        } else if (value instanceof SchedulingServiceId) {
            const item = await this.dependencies.schedulingReferenceDataStore.schedulingServices.getOrLoadAsync(value);

            const scheduleDefinition = this.dependencies.schedulingReferenceDataStore.appointmentScheduleDefinitions.items.find(a =>
                a.ownedSchedulingServices.some(o => o.value === item.id.value));

            return <><b>{item?.code}</b>{` ${item?.name} (${scheduleDefinition.name})`}</>;
        } else {
            return <></>;
        }
    }

    @State.bound
    private async getTextValueAsync(value: MedicalServiceId | SchedulingServiceId) {
        if (value instanceof MedicalServiceId) {
            const item = await this.dependencies.careReferenceDataStore.medicalService.getOrLoadAsync(this.toVersionSelector(value));
            return this.props.useAlternativeName && item?.alternativeName && item.alternativeName !== item.name
                ? `${item?.code.value} ${item?.name} - ${item?.alternativeName}`
                : `${item?.code.value} ${item?.name}`;
        } else if (value instanceof SchedulingServiceId) {
            const item = await this.dependencies.schedulingReferenceDataStore.schedulingServices.getOrLoadAsync(value);

            const scheduleDefinition = this.dependencies.schedulingReferenceDataStore.appointmentScheduleDefinitions.items.find(a =>
                a.ownedSchedulingServices.some(o => o.value === item.id.value));

            return `${item?.code} ${item?.name} (${scheduleDefinition.name})`;
        } else {
            return "";
        }
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const medicalServiceResult = await this.dependencies.medicalServiceApiAdapter.searchMedicalServicesByCodeAsync(
            text,
            10,
            this.props.careTypeId,
            LocalDate.today(),
            [],
            false
        );

        if (medicalServiceResult.operationWasSuccessful) {
            const ids: Array<(SchedulingServiceId | MedicalServiceId)> = [];

            const medicalServiceIds = medicalServiceResult.value.map(r => r.id);
            const medicalServiceVersionSelectors = this.mapToVersionSelectors(medicalServiceIds);

            await this.dependencies.careReferenceDataStore.medicalService.ensureLoadedAsync(medicalServiceVersionSelectors);

            medicalServiceIds.forEach(i => ids.push(i));

            if (10 - medicalServiceResult.value.length > 0) {
                const schedulingServiceResult =
                    await this.dependencies.schedulingConfigurationApiAdapter.searchSchedulingServicesAsync(text, 10 - medicalServiceResult.value.length);

                if (schedulingServiceResult.operationWasSuccessful) {
                    const schedulingServiceIds = schedulingServiceResult.value;
                    await this.dependencies.schedulingReferenceDataStore.schedulingServices.ensureLoadedAsync(schedulingServiceIds);

                    schedulingServiceIds.forEach(i => ids.push(i));
                }
            }

            return ids;
        }

        return null;
    }

    @State.bound
    private getComplexSearchEntitiesByIds(ids: Array<MedicalServiceId | SchedulingServiceId>) {
        const medicalServiceIds = ids.filter(i => i instanceof MedicalServiceId);
        const medicalServiceSelectors = this.mapToVersionSelectors(medicalServiceIds);

        const medicalServiceStores = this.dependencies.careReferenceDataStore.medicalService.getAll(medicalServiceSelectors);

        const schedulingServiceIds = ids.filter(i => i instanceof SchedulingServiceId);

        const schedulingServiceStores = this.dependencies.schedulingReferenceDataStore.getSchedulingServicesByIds(schedulingServiceIds);

        const result: Array<IMedicalServiceVersion | SchedulingServiceSubjectStore> = [];
        medicalServiceStores.forEach(i => result.push(i));
        schedulingServiceStores.forEach(i => result.push(i));

        return result;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: MedicalServiceStore | SchedulingServiceSubjectStore) {
        this.props.onChange(item.id);
    }

    @State.bound
    private onComplexSearchMultiSelect(items: Array<(MedicalServiceStore | SchedulingServiceSubjectStore)>) {
        const itemIds = items.map(item => item.id);
        this.props.onChange(itemIds);
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: any): Promise<IPagedItems<MedicalServiceStore | SchedulingServiceSubjectStore>> {
        if (this.medicalServiceTotalCount > 0 || this.schedulingServiceTotalCount > 0) {
            if (paging.currentPage > 0) {
                const medicalServicePageCount = Math.ceil(this.medicalServiceTotalCount / paging.pageSize);
                const currentSchedulingServicePage = paging.currentPage - medicalServicePageCount >= 0 ? paging.currentPage - medicalServicePageCount : 0;

                if (paging.pageSize * paging.currentPage > this.medicalServiceTotalCount) {
                    return await this.queryMedicalAndSchedulingServicesAsync(filterText, ordering, paging, { currentPage: currentSchedulingServicePage, pageSize: paging.pageSize }, "SchedulingService");
                } else {
                    return await this.queryMedicalAndSchedulingServicesAsync(filterText, ordering, paging, { currentPage: currentSchedulingServicePage, pageSize: paging.pageSize }, "MedicalService");
                }
            } else {
                return await this.queryMedicalAndSchedulingServicesAsync(filterText, ordering, paging, { currentPage: 0, pageSize: paging.pageSize }, "MedicalService");
            }
        } else {
            return await this.queryMedicalAndSchedulingServicesAsync(filterText, ordering, paging, { currentPage: 0, pageSize: paging.pageSize }, "MedicalService");
        }
    }

    private async queryMedicalAndSchedulingServicesAsync(
        filterText: string,
        ordering: IOrderingState,
        pagingForMedicalServices: IPagingState,
        pagingForSchedulingServices: IPagingState,
        resultSetUsage: "MedicalService" | "SchedulingService" | "Both") {

        const medicalServiceResults =
            await this.dependencies.medicalServiceApiAdapter.getMedicalServicesAsync(filterText,
                ordering,
                pagingForMedicalServices,
                this.props.careTypeId,
                LocalDate.today(),
                [],
                this.props.useAlternativeName);

        const schedulingServiceResults =
            await this.dependencies.schedulingConfigurationApiAdapter.querySchedulingServicesAsync(filterText,
                { columnId: "Name", direction: "asc" },
                pagingForSchedulingServices);

        this.medicalServiceTotalCount = medicalServiceResults.totalCount;
        this.schedulingServiceTotalCount = schedulingServiceResults.totalCount;

        const resultItems: Array<MedicalServiceStore | SchedulingServiceSubjectStore> = [];

        if (resultSetUsage === "MedicalService") {
            medicalServiceResults.items.forEach(i => resultItems.push(i));

            if (resultItems.length === 0) {
                schedulingServiceResults.items.forEach(i => resultItems.push(i));
            }
        } else if (resultSetUsage === "SchedulingService") {
            schedulingServiceResults.items.forEach(i => resultItems.push(i));

            if (resultItems.length === 0) {
                medicalServiceResults.items.forEach(i => resultItems.push(i));
            }
        } else {
            medicalServiceResults.items.forEach(i => resultItems.push(i));
            schedulingServiceResults.items.slice(0, pagingForSchedulingServices.pageSize - medicalServiceResults.items.length).forEach(i => resultItems.push(i));
        }

        return {
            items: resultItems,
            totalCount: this.medicalServiceTotalCount + this.schedulingServiceTotalCount
        } as IPagedItems<MedicalServiceStore | SchedulingServiceSubjectStore>;
    }

    @State.bound
    private renderName(value: any, store: MedicalServiceStore | SchedulingServiceSubjectStore) {
        if (store instanceof MedicalServiceStore) {
            return this.props.useAlternativeName
                ? store.name !== store.alternativeName
                    ? <>{store.name}<br /> <i>{store.alternativeName} </i> </>
                    : store.name
                : store.name;
        } else if (store instanceof SchedulingServiceSubjectStore) {
            const scheduleDefinition = this.dependencies.schedulingReferenceDataStore.appointmentScheduleDefinitions.items.find(a =>
                a.ownedSchedulingServices.some(o => o.value === store.id.value));

            return <>{store.name} ({scheduleDefinition.name})<br /></>;
        } else {
            return <></>;
        }
    }

    public render() {
        return (
            <HisUi.UniversalCodeSelector
                {...this.props}
                getDisplayValueAsync={this.getDisplayValueAsync}
                getTextValueAsync={this.getTextValueAsync}
                onQuickSearchAsync={this.quickSearchAsync}
                customNameRender={this.renderName}
                codeGetter={"codeValue"}
                hasComplexSearch
                complexSearchLoadAsync={this.complexSearchLoadAsync}
                complexSearchModalTitle={StaticCareResources.ReferenceData.MedicalServices.CodeSelectorComplexSearchTitle}
                getComplexSearchEntitiesByIds={this.getComplexSearchEntitiesByIds}
                onComplexSearchSingleSelect={this.onComplexSearchSingleSelect}
                onComplexSearchMultiSelect={this.onComplexSearchMultiSelect}
                showMenuSections={false}
                selectedItemsSearchPlaceHolder={StaticCareResources.ReferenceData.MedicalServices.SelectedService}
                selectableItemsSearchPlaceHolder={StaticCareResources.ReferenceData.MedicalServices.SelectableService}
            />
        );
    }

    private mapToVersionSelectors(ids: MedicalServiceId[]) {
        return ids.map(id => ({
            id,
            validOn: LocalDate.today()
        } as IEntityVersionSelector<MedicalServiceId>));
    }

    private toVersionSelector(id: MedicalServiceId) {
        return {
            id: id,
            validOn: LocalDate.today()
        };
    }
}

export default connect(
    MostRelevantServiceCodeSelector,
    new DependencyAdapter<IMostRelevantServiceCodeSelectorProps, IMostRelevantServiceCodeSelectorDependencies>((container) => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            medicalServiceApiAdapter: container.resolve("MedicalServiceApiAdapter"),
            schedulingConfigurationApiAdapter: container.resolve("SchedulingConfigurationApiAdapter"),
            schedulingReferenceDataStore: container.resolve("SchedulingReferenceDataStore")
        };
    })
);
