import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined, emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import PerformedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/PerformedServiceStore";
import InfoButton from "@CommonControls/InfoButton";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import IMedicalServiceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalServiceVersion";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface IMedicalServiceColumnDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

type displayModeType = "name" | "alternativeName" | "code-name" | "code-alternativeName";
interface IMedicalServiceColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IMedicalServiceColumnDependencies;
    displayMode?: displayModeType;
}

const getDisplayValue = (displayMode: displayModeType, service: IMedicalServiceVersion) => {
    switch (displayMode) {
        case "name":
            return service.name;
        case "alternativeName":
            return service.alternativeName;
        case "code-alternativeName":
            return <><b>{service.code.value}</b>{` - ${service.alternativeName}`}</>;
        case "code-name":
        default:
            return <><b>{service.code.value}</b>{` - ${service.name}`}</>;
    }
};

const getStringValue = (displayMode: displayModeType, service: IMedicalServiceVersion) => {
    switch (displayMode) {
        case "name":
            return service.name;
        case "alternativeName":
            return service.alternativeName;
        case "code-alternativeName":
            return `${service.code.value} - ${service.alternativeName}`;
        case "code-name":
        default:
            return `${service.code.value} - ${service.name}`;
    }
};


const MedicalServiceColumn: React.FC<IMedicalServiceColumnProps> = (props: IMedicalServiceColumnProps) => {

    const careReferenceDataStore = props._dependencies.careReferenceDataStore;

    const getVersionedIds: (value: any) => Array<IEntityVersionSelector<MedicalServiceId>> = (value: any) => {
        const valueArray = !value ? emptyArray : (Array.isArray(value) ? value : [value]);

        return valueArray.map(v => {
            if (v instanceof MedicalServiceId) {
                return new EntityVersionSelector<MedicalServiceId>(v, LocalDate.today());
            }
            if ("id" in v && "validOn" in v) {
                return v as IEntityVersionSelector<MedicalServiceId>;
            }
            throw new Error(`MedicalServiceColumn should receive values as ${"MedicalServiceId"} or ${"EntityVersionSelector"}.`);
        });
    };

    const valueRenderer = useCallback((value: IEntityVersionSelector<MedicalServiceId> | Array<IEntityVersionSelector<MedicalServiceId>>) => {
        const ids = getVersionedIds(value);

        return ids.map((id, index) => {
            const service = careReferenceDataStore.medicalService.get(id);

            return service && (
                <div key={index}>
                    {getDisplayValue(props.displayMode, service)}&nbsp;
                    {service.description && <InfoButton iconName="infoCircle" tooltipContent={service.description} position="baseline" />}
                </div>
            );
        }
        );
    }, [props.displayMode]);
    
    const hintRenderer = useCallback((value: any) => {
        const ids = getVersionedIds(value);
        const displayValues: string[] = [];

        ids.map((id, index) => {
            const service = careReferenceDataStore.medicalService.get(id);
            const serviceStringValue = service && getStringValue(props.displayMode, service);
            displayValues.push(serviceStringValue);
        });

        return displayValues.join(", ");
    }, [props.displayMode]);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderHintValue={hintRenderer}
        />
    );
};

export default connect(
    MedicalServiceColumn,
    new DependencyAdapter<IMedicalServiceColumnProps, IMedicalServiceColumnDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
    }))
);
