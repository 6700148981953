
declare interface String {
    getHashCode(): number;
}



String.prototype.getHashCode = function() {
    let hash1 = 5381;
    let hash2 = hash1;

    const charCodes = Array.from(this as string).map((c) => c.charCodeAt(0));
    let charIndex = 0;
    let length = charCodes.length;
    while (length > 2) {
        hash1 = ((hash1 << 5) + hash1 + (hash1 >> 27)) ^ charCodes[charIndex];
        hash2 = ((hash2 << 5) + hash2 + (hash2 >> 27)) ^ charCodes[charIndex + 1];
        charIndex += 2;
        length -= 4;
    }

    if (length > 0) {
        hash1 = ((hash1 << 5) + hash1 + (hash1 >> 27)) ^ charCodes[charIndex];
    }
    
    return hash1 + (hash2 * 1566083941);
};
