import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicationStatementSubjectAssignmentBase from "./MedicationStatementSubjectAssignmentBase";
import SubstanceId from "@Primitives/SubstanceId.g";

export default class ReferencedSubstanceAssignment extends MedicationStatementSubjectAssignmentBase  {
    @State.observable.ref public substanceId: SubstanceId = null;

    constructor(substanceId: SubstanceId) {
        super();
        this.substanceId = substanceId;
    }
}