import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import * as Ui from "@CommonControls";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import MedicalConditionHistoryItemDetailPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicalConditionHistoryItemDetailPanel";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import PatientId from "@Primitives/PatientId.g";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import MedicalConditionItemHeaderPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicalConditionItemHeaderPanel";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import { EntityLockState } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import TherapyRecommendationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/TherapyRecommendationApiAdapter";
import TherapyRecommendationFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/TherapyRecommendationFactory";
import TherapyRecommendationId from "@Primitives/TherapyRecommendationId.g";
import TherapyRecommendationStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/TherapyRecommendationStore";
import TherapyRecommendationHistoryItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/TherapyRecommendationHistoryItem";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import { TherapyCodeSelectorItem } from "@HunEHealthInfrastructurePlugin/Common/Components/TherapyCodeSelector/TherapyCodeSelectorItem";
import TherapyAssignmentBase from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/TherapyAssignmentBase";
import ReferencedEhiTherapyAssignment from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/ReferencedTherapyAssignment";
import TherapyId from "@Primitives/TherapyId.g";
import CustomEhiTherapyAssignment from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/CustomTherapyAssignment";
import TherapyCodeSelector from "@HunEHealthInfrastructurePlugin/Common/Components/TherapyCodeSelector";
import IEhiMedicalConditionReferenceDataResolver from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Services/Definition/EhiMedicalConditionDataResolver/IEhiMedicalConditionReferenceDataResolver";
import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import FormExtensionRegistryAdapter from "@PluginInterface/FormExtension/FormExtensionRegistryAdapter";
import IServiceRequestHeaderExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestHeaderExtensionPointProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";

interface ITherapyRecommendationPanelDependencies {
    dialogService: IDialogService;
    notificationService: INotificationService;
    therapyRecommendationApiAdapter: TherapyRecommendationApiAdapter;
    therapyRecommendationFactory: TherapyRecommendationFactory;
    lockingApiAdapter: LockingApiAdapter;
    ehiMedicalConditionReferenceDataResolver: IEhiMedicalConditionReferenceDataResolver;
}

interface ITherapyRecommendationPanelProps extends IRoutingFrameContentProps {
    _dependencies?: ITherapyRecommendationPanelDependencies;
    _patientId?: PatientId;
    _formExtension?: IFormExtension<PatientId>;

    id: TherapyRecommendationId;
    onTherapyRecommendationCreatedAsync?: (therapyRecommendationId: TherapyRecommendationId) => Promise<void>;
    onCloseDetailAsync: () => Promise<void>;
    onRefreshListAsync: () => Promise<void>;
}

@State.observer
class TherapyRecommendationPanel extends React.Component<ITherapyRecommendationPanelProps> {
    private get therapyRecommendationId() { return this.props.id; }
    private get patientId() { return this.props._patientId; }
    @State.observable.ref private store: TherapyRecommendationStore = this.therapyRecommendationFactory.createNewStore();
    @State.computed private get currentHistoryItem() { return this.store.currentHistoryItem as TherapyRecommendationHistoryItem; }
    @State.computed private get isNew() { return this.therapyRecommendationId.value === "new"; }

    private get dialogService() { return this.props._dependencies.dialogService; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get therapyRecommendationApiAdapter() { return this.props._dependencies.therapyRecommendationApiAdapter; }
    private get therapyRecommendationFactory() { return this.props._dependencies.therapyRecommendationFactory; }
    private get lockingApiAdapter() { return this.props._dependencies.lockingApiAdapter; }
    private get ehiReferenceDataResolver() { return this.props._dependencies.ehiMedicalConditionReferenceDataResolver; }

    private get resources() { return StaticHunEHealthInfrastructureCareResources.MedicalCondition.TherapyRecommendations; }
    private get medicalConditionResources() { return StaticCareResources.MedicalCondition; }
    private get labels() { return this.resources.Label; }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(
        this.loadAsync,
        this.isNew
            ? async () => await this.props._dependencies.therapyRecommendationApiAdapter.checkPermissionForAddNewItemAsync(this.therapyRecommendationFactory.createNewStore(), new PatientId("1"))
            : () => Promise.resolve([])
    );

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: ITherapyRecommendationPanelProps) {
        if (prevProps.id.value !== this.props.id.value) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.action.bound
    private async loadAsync(forceReleaseLockNeeded: boolean = false) {
        if (forceReleaseLockNeeded && !!this.store?.lockInfo?.preventingLockId) {
            await this.lockingApiAdapter.forceReleaseLockAsync(this.store.lockInfo.preventingLockId);
        }

        let store = null;
        if (this.isNew) {
            store = this.therapyRecommendationFactory.createNewStore();
        } else {
            store = await this.therapyRecommendationApiAdapter.getByIdAsync(this.therapyRecommendationId, true);
        }
        await this.setStoreAsync(store);
    }

    @State.action.bound
    private async setStoreAsync(newValue: TherapyRecommendationStore) {
        this.store = newValue;
        const therapyName = await this.ehiReferenceDataResolver.getTherapyNameAsync(this.store.therapyCodeSelectorItem);
        this.store.setTherapyName(therapyName);
        this.store.takeSnapshot();
    }

    @State.bound
    private async navigateAwayAsync() {
        if (this.store?.isMutable === true && this.isUnsavedOrDirty()) {
            const dialogResult = await this.dialogService.confirmIfNotSaved(StaticCareResources.Common.Dialog.ConfirmationTitle, StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);
            if (dialogResult.resultCode === DialogResultCode.Yes) {
                await this.saveStoreAsync();
                await this.releaseLockAsync();
            } else if (dialogResult.resultCode === DialogResultCode.No) {
                await this.releaseLockAsync();
                return true;
            }
            return false;
        }
        await this.releaseLockAsync();
        return true;
    }

    @State.action.bound
    private async releaseLockAsync() {
        if (this.store.isMutable && this.store.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld) {
            await this.lockingApiAdapter.releaseLockAsync(this.store.lockInfo.lockId);
            this.store.releaseLock();
            return true;
        }
        return false;
    }

    private isUnsavedOrDirty() {
        return this.store.isDirty();
    }

    @State.action.bound
    private async validateAsync(): Promise<IClientValidationResult[]> {
        if (!this.store || !this.store.isMutable) {
            return [];
        }
        const validationResultResponse = await this.therapyRecommendationApiAdapter.validateAsync(this.store, this.patientId);
        return validationResultResponse.value;
    }

    @State.action.bound
    private async validateBeforeSaveAsync() {
        const newValue = await this.validateAsync();
        this.store.setValidationResults(newValue);
    }

    @State.action.bound
    private async saveStoreAsync() {
        await this.validateBeforeSaveAsync();

        if (this.store.hasValidationError) {
            return false;
        }

        await this.setExtensionDataAsync();
        let newStore = null;

        if (this.isNew) {
            newStore = await this.therapyRecommendationApiAdapter.addTherapyRecommendationAsync(this.store, this.patientId);
        } else {
            newStore = await this.therapyRecommendationApiAdapter.updateTherapyRecommendationAsync(this.store);
        }

        if (newStore.operationWasSuccessful && newStore.isPersistedByOperationInfo && this.isNew) {
            this.store.takeSnapshot();
            await this.props.onTherapyRecommendationCreatedAsync?.(newStore.id);
            return true;
        }

        await this.replaceStoreAsync(newStore);
        await this.props.onRefreshListAsync();
        return true;
    }

    @State.action.bound
    private async setExtensionDataAsync() {
        const extensionDataArray = await this.props._formExtension.invokeCallbackAsync<any>("ExtendStore", this.props._patientId);

        let extensionDataForStore = this.store.extensionData;
        for (const extensionData of extensionDataArray) {
            extensionDataForStore = { ...extensionData, ...extensionDataForStore };
        }

        this.store.setExtensionData(extensionDataForStore);
    }

    @State.action.bound
    private async replaceStoreAsync(newStore: TherapyRecommendationStore) {
        if (newStore.operationWasSuccessful) {
            if (newStore.isPersistedByOperationInfo) {
                newStore.lockInfo = this.store.lockInfo;
                await this.setStoreAsync(newStore);
                this.notificationService.showSavedSuccessfully();
            } else {
                State.runInAction(() => {
                    this.store.validationResults = newStore.validationResults;
                });
            }
        }
    }

    @State.action.bound
    private async deleteHistoryItemAsync() {
        const message = formatString(this.resources.Dialog.DeleteVersionConfirmationMessage, (this.store.currentItemIndex + 1).toString());
        const dialogResult = await this.dialogService.yesNo(StaticCareResources.Common.Dialog.ConfirmationTitle, message);

        if (dialogResult.resultCode === DialogResultCode.No) {
            return false;
        }

        await this.setExtensionDataAsync();
        if (this.store.historyItems.length === 1) {
            await this.therapyRecommendationApiAdapter.deleteTherapyRecommendationAsync(this.store);
            this.store.releaseLock();
            this.props.onCloseDetailAsync();
            return true;
        } else {
            const newStore = await this.therapyRecommendationApiAdapter.deleteTherapyRecommendationHistoryItemAsync(this.store, this.store.currentHistoryItem.versionNumber);

            await this.replaceStoreAsync(newStore);
            return true;
        }
    }

    @State.action.bound
    private async forceLoadAsync() {
        await this.loadAsync(true);
    }

    @State.computed
    private get errorExtensionProps() {
        return {
            extensionData: this.store.extensionData
        } as IServiceRequestHeaderExtensionPointProps;
    }

    @State.bound
    private showNoEhiCompatibleIdentifierFoundError() {
        this.notificationService.error(this.medicalConditionResources.Common.Messages.NoEhiCompatibleIdentifierFoundErrorMessage);
        return true;
    }

    @State.computed
    public get renderErrorExtension() {
        return (
            <HisPlatformExtensionPoint
                type="ServiceRequestHeader"
                extensionProps={this.errorExtensionProps}>
                <></>
            </HisPlatformExtensionPoint>
        );
    }

    public render() {
        const prefix = this.store.validationPropertyPrefix;
        const therapyName = this.store.isNew === true ? StaticCareResources.MedicalCondition.Common.Label.NewEntry : this.store.therapyName;

        return (
            <PermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                <BusinessErrorHandler.Register businessErrorName="NoEhiCompatibleIdentifierFoundError" handler={this.showNoEhiCompatibleIdentifierFoundError} />
                <UseCaseFrame
                    toolbar={(
                        <MedicalConditionItemHeaderPanel
                            store={this.store}
                            onDeleteHistoryItemAsync={this.deleteHistoryItemAsync}
                            onSaveStoreAsync={this.saveStoreAsync}
                            onLockEditClickedAsync={this.forceLoadAsync} />
                    )}
                    title={therapyName}>
                    {this.initialLoadPanelAsync.isUnauthorizedAccess ? <UnauthorizedAccessContent /> :
                        <>
                            {this.renderErrorExtension}
                            <ValidationBoundary
                                validationResults={this.store.validationResults}
                                entityTypeName="TherapyRecommendation"
                                onValidateAsync={this.validateAsync}
                                validateOnMount
                            >
                                <ReadOnlyContext.Provider value={!this.store.isMutable || !this.currentHistoryItem.isNew}>
                                    <Ui.Flex>
                                        <Ui.Flex.Item sm={4}>
                                            <TherapyCodeSelector
                                                label={this.labels.TherapyAssignment}
                                                value={this.store.therapyCodeSelectorItem}
                                                onChange={this.setTherapyAssignment}
                                                isReadOnly={!this.store.isNew}
                                                propertyIdentifier="TherapyIdOrName"
                                                automationId="__therapyId"
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item sm={4}>
                                            <Ui.DatePicker
                                                label={this.labels.StartDate}
                                                value={this.currentHistoryItem.startDate}
                                                onChange={this.currentHistoryItem.setStartDate}
                                                propertyIdentifier={`${prefix}.StartDate`}
                                                automationId="__startDate"
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item sm={4}>
                                            <Ui.DatePicker
                                                label={this.labels.EndDate}
                                                value={this.currentHistoryItem.endDate}
                                                onChange={this.currentHistoryItem.setEndDate}
                                                propertyIdentifier={`${prefix}.EndDate`}
                                                automationId="__endDate"
                                            />
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                    <Ui.Flex>
                                        <Ui.Flex.Item sm={12}>
                                            <Ui.TextBox
                                                label={this.labels.Description}
                                                value={this.currentHistoryItem.description}
                                                onChange={this.currentHistoryItem.setDescription}
                                                propertyIdentifier={`${prefix}.Description`}
                                                automationId="__description"
                                                multiline
                                                multilineMinLineCount={4}
                                            />
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                    <br />
                                    <MedicalConditionHistoryItemDetailPanel store={this.store} prefix={prefix} />
                                </ReadOnlyContext.Provider>
                            </ValidationBoundary>
                            <NavigateAwayHook onNavigateAwayAsync={this.navigateAwayAsync} />
                        </>
                    }
                </UseCaseFrame>
            </PermissionCheckContextProvider>
        );
    }

    @State.computed private get permissionCheckedOperations() {
        const res = {};

        const store = this.therapyRecommendationFactory.createNewStore();
        store.id = new TherapyRecommendationId("1");

        if (this.props.id) {
            res["AddNewVersion"] = async () => await this.props._dependencies.therapyRecommendationApiAdapter.checkPermissionForUpdateItemAsync(store);
            res["DeleteVersion"] = async () => await this.props._dependencies.therapyRecommendationApiAdapter.checkPermissionForDeleteItemAsync(store);
        }

        return res;
    }

    @State.bound
    private setTherapyAssignment(newValue: TherapyCodeSelectorItem) {
        let therapyAssignment: TherapyAssignmentBase;
        if (!newValue) {
            therapyAssignment = new ReferencedEhiTherapyAssignment(null);
        } else if (newValue.id instanceof TherapyId) {
            therapyAssignment = new ReferencedEhiTherapyAssignment(newValue.id);
        } else {
            therapyAssignment = new CustomEhiTherapyAssignment(newValue.text);
        }
        this.store.setTherapyAssignment(therapyAssignment);
    }
}

export default connect(
    TherapyRecommendationPanel,
    new DependencyAdapter<ITherapyRecommendationPanelProps, ITherapyRecommendationPanelDependencies>(c => ({
        dialogService: c.resolve<IDialogService>("IDialogService"),
        notificationService: c.resolve<INotificationService>("INotificationService"),
        therapyRecommendationApiAdapter: c.resolve<TherapyRecommendationApiAdapter>("TherapyRecommendationApiAdapter"),
        therapyRecommendationFactory: c.resolve<TherapyRecommendationFactory>("TherapyRecommendationFactory"),
        lockingApiAdapter: c.resolve<LockingApiAdapter>("LockingApiAdapter"),
        ehiMedicalConditionReferenceDataResolver: c.resolve("IEhiMedicalConditionReferenceDataResolver"),
    })),
    new PatientContextAdapter<ITherapyRecommendationPanelProps>(c => ({
        _patientId: c.patientId,
    })),
    new FormExtensionRegistryAdapter("ManageMedicalCondition")
);
