import React, { useMemo, useCallback } from "react";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EditableShouldBeWithinRangeValidationRule from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/Validation/EditableShouldBeWithinRangeValidationRule";
import { NumberBox } from "@CommonControls";
import RuleTypeId from "@Primitives/RuleTypeId.g";

function ShouldBeWithinRangeValidationRulePropertyPanel() {

    const store = useFormLayoutEditorStore();

    const rule = useMemo(
        () => store.selectedEditorValidationRules.find(vr => vr.ruleType === RuleTypeId.ShouldBeWithinRange.value) as EditableShouldBeWithinRangeValidationRule,
        [store.selectedEditorValidationRules]
    );

    const setMinValue = useCallback((value: number | null) => {

        if (rule) {
            if (value === null && rule.maxValue === null) {
                store.removeValidationRule(rule);
            } else {
                rule.setMinValue(value);
            }
        } else {
            store.addValidationRule(new EditableShouldBeWithinRangeValidationRule(
                store.selectedEditor.dataReference,
                {
                    minValue: value,
                    maxValue: null
                }
            ), store.selectedEditor.parentDataReferences);
        }

    }, [rule, store.selectedEditor.dataReference]);

    const setMaxValue = useCallback((value: number | null) => {

        if (rule) {
            if (value === null && rule.minValue === null) {
                store.removeValidationRule(rule);
            } else {
                rule.setMaxValue(value);
            }
        } else {
            store.addValidationRule(new EditableShouldBeWithinRangeValidationRule(
                store.selectedEditor.dataReference,
                {
                    minValue: null,
                    maxValue: value
                }
            ), store.selectedEditor.parentDataReferences);
        }

    }, [rule, store.selectedEditor.dataReference]);

    return (
        <>
            <NumberBox label="Minimum érték" automationId="FieldMinValue_NumberBox" value={rule?.minValue ?? null} onChange={setMinValue} nullValue={null} clearable />
            <NumberBox label="Maximum érték" automationId="FieldMaxValue_NumberBox" value={rule?.maxValue ?? null} onChange={setMaxValue} nullValue={null} clearable />
        </>
    );
}

export default State.observer(ShouldBeWithinRangeValidationRulePropertyPanel);