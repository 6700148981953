import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import * as Proxy from "@HisPlatform/BoundedContexts/Productivity/Api/Proxy.g";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";

@Di.injectable()
export default class WorklistDefinitionCache extends ApiAdapterBase {

    private worklistDefinitionCache: Map<string, SimpleStore<IWorklistDefinition>> = new Map<string, SimpleStore<IWorklistDefinition>>();

    constructor(@Di.inject("ICurrentCultureProvider") private readonly cultureCodeProvider: ICurrentCultureProvider) {
        super();
    }

    private mapWorklistDefinition(dto: Proxy.BoundWorklistDefinitionDto): IWorklistDefinition {
        return {
            localId: dto.toString(),
            worklistToken: dto.worklistToken.value,
            name: this.mapLocalizedLabelsToMultiLingualLabelMap(dto.localizedNames)
                ?.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode),
            shortName: this.mapLocalizedLabelsToMultiLingualLabelMap(dto.localizedShortNames)
                ?.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode),
            code: dto.code,
            organizationUnitId: dto.organizationUnitId,
            healthcareProfessionIds: dto.healthcareProfessionIds
        };
    }

    public getWorklistDefinitionAsync(key: string, getter: () => Promise<Proxy.IResponseBase & { definition?: Proxy.BoundWorklistDefinitionDto }>) {
        // Search in cache first
        if (!isNullOrUndefined(this.worklistDefinitionCache.get(key))) {
            return Promise.resolve(this.worklistDefinitionCache.get(key));
        }

        return this.processOperationAsync(new SimpleStore<IWorklistDefinition>(),
            async target => {
                const response = await getter();
                target.operationInfo = createOperationInfo(response);
                target.value = this.mapWorklistDefinition(response.definition);

                this.worklistDefinitionCache.set(key, target);
            }
        );
    }

    public invalidateWorklistDefinitionCache() {
        this.worklistDefinitionCache.clear();
    }

    private mapLocalizedLabelsToMultiLingualLabelMap(localizedLabels: Proxy.LocalizedLabel[]) {
        if (localizedLabels === null)
            return null;
        const localizedLabelMap = new Map<string, string>();
        localizedLabels.forEach(l => localizedLabelMap.set(l.cultureCode?.value, l.label));
        return new MultiLingualLabel(localizedLabelMap);
    }
}
