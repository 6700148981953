import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import TimePhaseEditorDialogView from "./TimePhaseEditorDialogView";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { ITimePhaseEditorDialogParams } from "./TimePhaseEditorDialogParams";
import PractitionerResource from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/PractitionerResource";
import PractitionerId from "@Primitives/PractitionerId.g";
import OrganizationUnitResource from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/OrganizationUnitResource";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import _ from "@HisPlatform/Common/Lodash";
import TimePhaseType from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseType";

interface ITimePhaseEditorDialogDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    practitionerApiAdapter: PractitionerApiAdapter;
}

interface ITimePhaseEditorDialogProps extends IModalComponentParams, ITimePhaseEditorDialogParams {
    _dependencies?: ITimePhaseEditorDialogDependencies;
}

/** @screen */
@State.observer
class TimePhaseEditorDialog extends React.Component<ITimePhaseEditorDialogProps> {

    constructor(props: ITimePhaseEditorDialogProps) {
        super(props);
    }

    private pathPrefix = "PlanningPeriods";

    @State.observable.ref private isLoading: boolean = false;

    @State.action.bound
    private setLoadingState(state: boolean) {
        this.isLoading = state;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }

    public componentDidUpdate(prevProps: ITimePhaseEditorDialogProps) {
        if (this.props.timePhase?.organizationUnitResources !== prevProps.timePhase?.organizationUnitResources) {
            dispatchAsyncErrors(this.initializeAsync(), this);
        }
    }

    @State.bound
    private async initializeAsync() {
        State.runInAction(() => this.setLoadingState(true));

        if (!this.props.timePhase.organizationUnitResources?.length) {
            await this.props._dependencies.organizationReferenceDataStore.pointOfCareMap.ensureAllLoadedAsync();
        } else {
            const ids = this.props.timePhase.organizationUnitResources.map(i => i.id);
            await this.props._dependencies.organizationReferenceDataStore.pointOfCareMap.ensureLoadedAsync(ids);
        }
        State.runInAction(() => this.setLoadingState(false));
    }

    @State.action.bound
    private close() {
        this.props.onClose({
            saved: false
        });
    }

    @State.action.bound
    private async saveAsync() {
        const result = await this.props.onValidateAsync();

        const problems = (_.flatten(result.map(r => r.problems))).filter(p => p.propertyPath?.includes(this.pathPrefix));

        if (!problems.length) {
            return this.props.onClose({
                saved: true
            });
        }
    }

    @State.computed private get practitionerIds() {
        return this.props.timePhase.practitionerResources?.map(r => r.id);
    }

    @State.computed private get pointOfCareIds() {
        return this.props.timePhase.organizationUnitResources?.map(r => r.id);
    }

    @State.action.bound private setPointOfCareResources(ids: PointOfCareId[]) {
        if (!ids || !ids.length) {
            this.props.timePhase.setAllowedServiceRequestDefinitions(null);
        }

        const organizationUnitResources = ids?.map(id => new OrganizationUnitResource(id)) ?? [];
        this.props.timePhase.setOrganizationUnitResources(organizationUnitResources);
    }

    @State.action.bound private setPractitionerResources(ids: PractitionerId[]) {
        const practitionerResources = ids?.map(id => new PractitionerResource(id)) ?? [];
        this.props.timePhase.setPractitionerResources(practitionerResources);
    }

    @State.action.bound private setTimePhaseInterval(from: TimeOfDay, to: TimeOfDay, isFinal: boolean) {
        if (isFinal) {
            this.props.timePhase.setInterval(from, to);
        }
    }

    @State.action.bound private setTimePhaseType(newTimePhaseType: TimePhaseType) {
        if (!this.props.timePhase.recurrenceElements?.length) {
            this.props.timePhase.resetRecurrenceElements();
        } 
        this.props.timePhase.setType(newTimePhaseType);
    }

    public render() {
        return (
            <TimePhaseEditorDialogView
                timePhase={this.props.timePhase}
                isNew={this.props.isNew}
                onClose={this.close}
                addAsync={this.saveAsync}
                possibleSchedulingServices={this.props.possibleSchedulingServices}
                practitionerIds={this.practitionerIds}
                pointOfCareIds={this.pointOfCareIds}
                setPointOfCareIds={this.setPointOfCareResources}
                setPractitionerIds={this.setPractitionerResources}
                setTimePhaseInterval={this.setTimePhaseInterval}
                onValidateAsync={this.props.onValidateAsync}
                validationResults={this.props.validationResults}
                pathPrefix={this.pathPrefix}
                isLoading={this.isLoading}
                onSetTimePhaseType={this.setTimePhaseType}
            />
        );
    }
}

export default connect(
    TimePhaseEditorDialog,
    new DependencyAdapter<ITimePhaseEditorDialogProps, ITimePhaseEditorDialogDependencies>(c => {
        return {
            organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
            practitionerApiAdapter: c.resolve("PractitionerApiAdapter")
        };
    })
);