import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import MedicationNameColumnData from "@HisPlatformControls/DataGridColumns/MedicationNameColumnData";
import * as Ui from "@CommonControls";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";


interface IMedicationNameColumnProps extends IDataGridColumnBaseProps {

}


const MedicationNameColumn: React.FC<IMedicationNameColumnProps> = (props: IMedicationNameColumnProps) => {

    const valueRenderer = useCallback((value: MedicationNameColumnData) => {

        return (
            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ flexBasis: "auto", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                    {value.name}
                </div>
                <div style={{ flexBasis: "auto", flexGrow: 1, flexShrink: 0, textAlign: "right", }}>
                    {value.isDeleted && <Ui.Badge tooltipContent={StaticWebAppResources.DataGrid.Columns.ToolTips.IsDeleted} text="TT" visualStyle="slate" />}
                    {value.modificationType === "U" && <Ui.Badge tooltipContent={StaticWebAppResources.DataGrid.Columns.ToolTips.ModificationType} text="U" visualStyle="slate" />}
                </div>
            </div>
        );
    }, []);

    const hintRenderer = useCallback((value: MedicationNameColumnData) => {
        return value.name;
    }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderHintValue={hintRenderer}
        />
    );
};

export default MedicationNameColumn;
