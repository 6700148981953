import DependencyContainer from "@DiContainer";
import FinanceApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/Finance/FinanceApiAdapter";
import VatRateApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/Finance/VatRateApiAdapter";
import VendorApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/Finance/VendorApiAdapter";

DependencyContainer
    .bind("FinanceApiAdapter")
    .to(FinanceApiAdapter)
    .inSingletonScope();


DependencyContainer
    .bind("VendorApiAdapter")
    .to(VendorApiAdapter)
    .inSingletonScope();


DependencyContainer
    .bind("VatRateApiAdapter")
    .to(VatRateApiAdapter)
    .inSingletonScope();   