import FormFieldDataBase from "./FormFieldDataBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class BooleanFormFieldData extends FormFieldDataBase {
    public isArray: boolean = false;
    @State.observable.ref public value: boolean = false;

    constructor(fieldName: string, isReadonly: boolean, isVisible: boolean, value: boolean) {
        super(fieldName, isReadonly, isVisible);

        this.value = value;
    }
}