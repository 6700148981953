/* auto-inject-disable */
import Di from "@Di";
import CareActivitySource from "@Primitives/CareActivitySource.g";
import ICareActivitySourceProvider from "@HisPlatform/BoundedContexts/Care/Services/Definition/CareActivitySourceRegistry/ICareActivitySourceProvider";
import ICareActivitySourceRegistry from "@HisPlatform/BoundedContexts/Care/Services/Definition/CareActivitySourceRegistry/ICareActivitySourceRegistry";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

@Di.injectable()
export default class CareActivitySourceRegistry implements ICareActivitySourceRegistry {
    public readonly careActivitySources: CareActivitySource[];

    constructor(
        @Di.multiInject("ICareActivitySourceProvider") @Di.optional() careActivitySourceProviders: ICareActivitySourceProvider[]
    ) {
        const careActivitySources: CareActivitySource[] = [
            CareActivitySource.InHouse
        ];

        for (const provider of careActivitySourceProviders) {
            const providedCareActivitySources = provider.getCareActivitySources();
            for (const careActivitySource of providedCareActivitySources) {
                if (!careActivitySources.some(s => ValueWrapper.equals(s, careActivitySource))) {
                    careActivitySources.push(careActivitySource);
                }
            }
        }
        this.careActivitySources = careActivitySources;
    }
}