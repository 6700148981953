import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import DocumentTemplateManagementMasterDetailPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/DocumentTemplateManagementMasterDetailPanel";
import Route from "@Toolkit/ReactClient/Routing/Abstractions/Route";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import TemplateId from "@Primitives/TemplateId.g";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

interface IDocumentTemplateManagementPageProps extends IRoutingFrameContentProps {

}

@State.observer
export default class DocumentTemplateManagementPage extends React.Component<IDocumentTemplateManagementPageProps> {

    @State.computed private get currentRoute() {
        return this.props.routingController!.currentRoute as Route<{ templateId?: string }>;
    }

    @State.computed private get selectedId() {
        return isNullOrEmptyString(this.currentRoute.parameters.templateId) ? null : new TemplateId(this.currentRoute.parameters.templateId!);
    }

    public render() {
        return (
            <LoadingBoundary>
                <DocumentTemplateManagementMasterDetailPanel
                    selectedId={this.selectedId}
                    onSelectedIdChange={this.select}
                />
            </LoadingBoundary>
        );
    }

    @State.bound
    private select(id: TemplateId) {
        this.props.routingController!.replace(ApplicationRoutes.documentTemplate.makeRoute({ templateId: id?.value ?? undefined }));
    }
}