import React from "react";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import PatientId from "@Primitives/PatientId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import UnregisteredPatientStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/UnregisteredPatientStore";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import UnregisteredPatientAppointmentPanelView from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/UnregisteredPatientAppointmentPanel/UnregisteredPatientAppointmentPanelView";
import PermissionCheckContext from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContext";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";

interface IUnregisteredPatientAppointmentPanelDependencies {
    apiAdapter: SchedulingApiAdapter;
    notificationService: INotificationService;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
    localizationService: ILocalizationService;
    dialogService: IDialogService;
}

interface IUnregisteredPatientAppointmentPanelProps {
    _dependencies?: IUnregisteredPatientAppointmentPanelDependencies;
    onBack: () => void;
    onPatientIdChanged: (newValue: PatientId) => void;
}

/** @screen */
@State.observer
class UnregisteredPatientAppointmentPanel extends React.Component<IUnregisteredPatientAppointmentPanelProps> {

    @State.observable.ref private appointment: Appointment;
    @State.observable.ref private selectedSchedulingService: SchedulingServiceSubjectStore = null;
    @State.observable private isUserDetailOpen?: boolean = null;
    @State.observable private hasValidationError: boolean = false;

    @State.observable private permissionCheckContextStore = new PermissionCheckContext();

    private get schedulingReferenceDataStore() {
        return this.props._dependencies.schedulingReferenceDataStore;
    }
    constructor(props: IUnregisteredPatientAppointmentPanelProps) {
        super(props);
    }

    @State.computed private get permissionCheckedOperations() {
        const res = {};

        res["Create"] = async () => await this.props._dependencies.apiAdapter.createAppointment(new Appointment(true), true);

        return res;
    }

    @State.computed private get isReadOnly(): boolean {
        return !(this.permissionCheckContextStore.checkedPermissions.find(p => p.operationName === "Create")?.isAuthorized ?? true);
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(
        this.initializeAsync,
        this.props._dependencies.apiAdapter.getAppointmentByIdPermissionCheckAsync
    );

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.bound
    private async initializeAsync() {
        await this.permissionCheckContextStore.checkPermissionsAsync(this.permissionCheckedOperations);
        this.initNewAppointment();
    }

    @State.action.bound
    private setAppointment(store: Appointment) {
        this.appointment = store;
    }

    @State.action.bound
    private initNewAppointment() {
        const newAppointment = new Appointment(true);
        newAppointment.id = new AppointmentScheduleEntryId("new");
        newAppointment.patientId = new PatientId("unregistered");
        newAppointment.unregisteredPatient = new UnregisteredPatientStore();
        newAppointment.validationResults = [];
        this.setAppointment(newAppointment);
        this.appointment.takeSnapshot();
    }

    @State.action.bound
    private setSelectedSchedulingService(id: SchedulingServiceId) {
        this.appointment.setSubjectService(id);
        this.loadSelectedService();
    }

    @State.action.bound
    private loadSelectedService() {
        dispatchAsyncErrors(this.loadSchedulingServiceAsync(), this);
    }

    @State.action.bound
    private async loadSchedulingServiceAsync() {
        if (!!this.appointment.schedulingServiceId) {
            const item = await this.schedulingReferenceDataStore.schedulingServices.getOrLoadAsync(this.appointment.schedulingServiceId);
            State.runInAction(() => {
                this.selectedSchedulingService = item;
            });
        } else {
            State.runInAction(() => {
                this.selectedSchedulingService = null as SchedulingServiceSubjectStore;
            });
        }
    }

    @State.action.bound
    public async saveAsync() {
        return await this.saveAppointmentAsync(this.appointment);
    }

    @State.bound
    private async validateAsync(dirtyFields?: string[], appointment?: Appointment) {
        const result = await this.props._dependencies.apiAdapter.validateAsync(appointment ?? this.appointment);

        if (result.value.some(i => i.problems && i.problems.some(j => j.severity === "error"))) {
            State.runInAction(() => this.hasValidationError = true);
        } else {
            State.runInAction(() => this.hasValidationError = false);
        }

        return result.value;
    }

    @State.action.bound
    private setValidationResults(validationResults: IClientValidationResult[]) {
        this.appointment.validationResults = validationResults;
    }

    @State.bound
    private async unloadAsync() {
        const dirty = this.appointment.isDirty();

        if (dirty) {
            const dialogResult = await this.props._dependencies.dialogService.confirmIfNotSaved(StaticSchedulingResources.UnregisteredPatientPanel.ConfirmationTitle, StaticSchedulingResources.UnregisteredPatientPanel.NavigateBeforeSaveQuestion);
            if (dialogResult.resultCode === DialogResultCode.Yes) {
                const saveResult = await this.saveAsync();
                return saveResult;
            } else if (dialogResult.resultCode === DialogResultCode.No) {
                return true;
            } else if (dialogResult.resultCode === DialogResultCode.Cancel) {
                return false;
            }
        }

        return true;
    }

    @State.computed private get patientFormOpen() {
        return this.isUserDetailOpen ?? (this.hasValidationError || !!this.appointment?.usedSlotIds?.length || !this.appointment?.subjectService || this.appointment.hasValidationError);
    }

    @State.action.bound
    private userDetailOpenStateChanged(newValue: boolean) {
        this.isUserDetailOpen = !this.isUserDetailOpen;
    }

    @State.action.bound
    private async saveAppointmentAsync(appointment: Appointment) {
        const result = await this.validateAsync(null, appointment);
        if (result.some(i => i.problems.some(j => j.severity === "error"))) {
            this.setValidationResults(result);
            this.props._dependencies.notificationService.showSaveResult(false);

            return false;
        }

        const newStore = await this.props._dependencies.apiAdapter.createAppointment(appointment);

        this.props._dependencies.notificationService.showSaveResult(newStore.isPersistedByOperationInfo);

        if (newStore.hasValidationError) {
            this.setValidationResults(newStore.validationResults);
            return false;
        } else {
            State.runInAction(() => {
                this.appointment = newStore;
            });

            this.appointment.takeSnapshot();
            this.props.onPatientIdChanged(this.appointment.patientId);
            return true;
        }
    }

    public render() {
        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticSchedulingResources.UnregisteredPatientPanel.Appointment} />;
        }
        return (
            <ReadOnlyContext.Provider value={this.isReadOnly}>
                <UnregisteredPatientAppointmentPanelView
                    appointment={this.appointment}
                    selectedSchedulingService={this.selectedSchedulingService}

                    isPatientFormOpen={this.patientFormOpen}
                    changeDetailOpenState={this.userDetailOpenStateChanged}

                    onBack={this.props.onBack}

                    setSelectedSchedulingService={this.setSelectedSchedulingService}
                    validateAsync={this.validateAsync}
                    unloadAsync={this.unloadAsync}
                    onSaveAppointmentAsync={this.saveAppointmentAsync}
                />
            </ReadOnlyContext.Provider>
        );
    }
}

export default connect(
    UnregisteredPatientAppointmentPanel,
    new DependencyAdapter<IUnregisteredPatientAppointmentPanelProps, IUnregisteredPatientAppointmentPanelDependencies>(c => ({
        apiAdapter: c.resolve("SchedulingApiAdapter"),
        schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore"),
        notificationService: c.resolve("INotificationService"),
        localizationService: c.resolve("ILocalizationService"),
        dialogService: c.resolve("IDialogService")
    }))
);