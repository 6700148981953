
import FrontendActionBase from "./FrontendActionBase";
import FrontendActionId from "./FrontendActionId";
import ScreenDisplayMode from "./ScreenDisplayMode";

export default abstract class ShowScreenFrontendActionBase extends FrontendActionBase {
    constructor(
        id: FrontendActionId,
        public displayMode: ScreenDisplayMode
    ) {
        super(id);
    }
}