import NameStore from "@Primitives/NameStore";

interface INameDto {
    prefix?: string;
    familyName?: string;
    givenName1?: string;
    givenName2?: string;
    postfix?: string;
}

export function mapToNameStore(dto: INameDto, target?: NameStore): NameStore {
    const ret = target || new NameStore();
    if (dto) {
        ret.prefix = dto.prefix;
        ret.givenName1 = dto.givenName1;
        ret.givenName2 = dto.givenName2;
        ret.postfix = dto.postfix;
        ret.familyName = dto.familyName;
    }
    return ret;
}

export function mapToNameDto(dtoType: new (initializer: INameDto) => any, store: NameStore): any {
    if (!store) {
        return null;
    }
    
    return new dtoType({
        familyName: store.familyName,
        givenName1: store.givenName1,
        givenName2: store.givenName2,
        postfix: store.postfix,
        prefix: store.prefix
    });
}