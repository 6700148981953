import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import ExternalLocationTypeId from "@Primitives/ExternalLocationTypeId.g";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";

export default class EditableExternalLocationStore extends EntityStoreBase<ExternalLocationId> implements IDirtyChecked {

    private _dirtyChecker = new StoreDirtyChecker();

    @State.observable.ref public shortName: string = null;
    @State.observable.ref public name: string = null;
    @State.observable.ref public externalLocationTypes: ExternalLocationTypeId[] = [];
    @State.observable.ref public identifiers: IObservableArray<Identifier> = State.observable([]);

    @State.action.bound
    public setShortName(value: string) {
        this.shortName = value;
    }

    @State.action.bound
    public setName(value: string) {
        this.name = value;
    }

    @State.action.bound
    public setExternalLocationTypes(newValue: ExternalLocationTypeId[]) {
        this.externalLocationTypes = newValue;
    }

    @State.action.bound
    public removeIdentifier(identifier: Identifier) {
        this.identifiers.remove(identifier);
    }

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker"
    ];
    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }
    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }
}