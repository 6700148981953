import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import Di from "@Di";
import DependencyContainer from "@DiContainer";

@Di.injectable()
export default class WebAppBackendBoundedContext implements IBoundedContext {

    public initializeAsync(): Promise<void> {
        return Promise.resolve();
    }
}

DependencyContainer
    .bind("IBoundedContext")
    .to(WebAppBackendBoundedContext)
    .inSingletonScope();
