import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import NDataScreen from "@HisPlatform/BoundedContexts/Productivity/Components/NDataScreen/NDataScreen";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import PatientApiAdapter2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/PatientApiAdapter2";
import React, { useCallback } from "react";
import ShowSearchPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowSearchPatientScreenAction.g";

export default function PatientWorklistScreen(props: IScreenPropsBase<ShowSearchPatientScreenAction>) {

    const { patientSearchApiAdapter } = useDependencies(c => ({
        patientSearchApiAdapter: c.resolve<PatientApiAdapter2>("PatientApiAdapter2"),
    }));

    const getDynamicListAsync = useCallback(async (args: FrontendListParameters) => {
        const response = await patientSearchApiAdapter.searchPatientAsync(args);
        return response.result;
    }, []);

    return (
        <NDataScreen
            onGetDynamicListAsync={getDynamicListAsync}
         />
    );
}