import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import Prescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Prescription";
import PrescriptionId from "@Primitives/PrescriptionId.g";
import Di from "@Di";
import MedicationRequestCoverage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationRequestCoverage";
import { mapMedicationReferenceBase } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/MedicationRequestMapperHelpers";

type ResponseType = Proxy.CreatePrescriptionCommandResponse
                    | Proxy.UpdatePrescriptionCommandResponse
                    | Proxy.GetPrescriptionByIdCommandResponse
                    | Proxy.CreatePrescriptionsBasedOnAllPermanentPrescriptionsCommandResponse;

@Di.injectable()
export default class PrescriptionStoreMapper extends LockingEntityStoreMapper<Proxy.PrescriptionDto, Prescription, ResponseType> {
    protected applyToStoreCore(target: Prescription, response: ResponseType): void {
        const dto = this.entityDtoSelector(response);
        if (!dto) {
            return null;
        }
        target.careActivityId = dto.careActivityId;
        target.conditionId = dto.conditionId;
        target.createdAt = dto.createdAt;
        target.createdBy = dto.createdBy;
        target.instruction = dto.instruction;
        target.insubstitutableReason = dto.insubstitutableReason;
        target.isEmergency = dto.isEmergency;
        target.isForcedPrescription = dto.isForcedPrescription;
        target.isSubstitubable = dto.isSubstitutable;
        target.notes = dto.notes;
        target.usableFrom = dto.usableFrom;
        target.prescribedOn = dto.prescribedOn;
        target.suppliedMonths = dto.suppliedMonths;
        target.status = dto.status;
        target.temporaryPermission.permissionIdentifier = dto.temporaryPermission?.permissionIdentifier;
        target.temporaryPermission.validTo = dto.temporaryPermission?.validTo;
        target.temporaryPermission.validFrom = dto.temporaryPermission?.validFrom;
        target.medicationRequestCoverage = new MedicationRequestCoverage();
        target.medicationRequestCoverage.claimTypeId = dto.medicationRequestCoverage?.medicationSubsidyClaimTypeId;
        target.medicationRequestCoverage.optionId = dto.medicationRequestCoverage?.medicationSubsidyOptionId;
        target.medicationRequestCoverage.subsidyId = dto.medicationRequestCoverage?.medicationSubsidyId;
        target.medicationReferenceBase = mapMedicationReferenceBase(dto.medicationReferenceBase);
        target.registryNumber = dto.registryNumber;
        target.prescriptionPractitionerRecommendation.practitionerId = dto.prescriptionPractitionerRecommendation?.practitionerId;
        target.prescriptionPractitionerRecommendation.careIdentifier = dto.prescriptionPractitionerRecommendation?.careIdentifier;
        target.prescriptionPractitionerRecommendation.issuedOn = dto.prescriptionPractitionerRecommendation?.issuedOn;
        target.prescriptionPractitionerRecommendation.ehiIdentifier = dto.prescriptionPractitionerRecommendation.ehiIdentifier;
        target.digitalPrescriptionIdentifier = dto.digitalPrescriptionIdentifier;
        target.extensionData = response.extensionData;
        target.prescriber = dto.prescriber;
        target.approvedDesignation = dto.approvedDesignation;
    }

    protected entityDtoSelector(response: ResponseType) {
        if (response instanceof Proxy.CreatePrescriptionsBasedOnAllPermanentPrescriptionsCommandResponse) {
            return response.prescriptions[0];
        }
        return response.prescription;
    }

    protected storeEntityIdType = PrescriptionId;

    protected vGetStoreIdentifier() {
        return "Prescription";
    }
}