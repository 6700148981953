import eHealthInfrastructureConfig from "@HunEHealthInfrastructurePlugin/config";
import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Log from "@Log";

export interface IEidSuccess {
    id_token: any;
    state: string;
}

export interface IEidError {
    error: string;
    error_description: string;
    state: string;
}

@Di.injectable()
export default class EidAdapter {

    private async getEidDataAsync(rid: string) {
        const response = await fetch(eHealthInfrastructureConfig.eidReaderAddress + rid, {
            method: "GET",
        });
        return await response.json();
    }

    @State.bound
    public async tryGetEidDataAsync(rid: string): Promise<IEidError | IEidSuccess> {
        try {
            const result = await this.getEidDataAsync(rid);
            return result;
        } catch (e) {
            Log.error(e);
            return e;
        }
    }
}