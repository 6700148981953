import React from "react";
import * as Ui from "@CommonControls";
import { iconVisualStyleType, iconNameType } from "@CommonControls/Icon";
import { ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import Styles from "./InfoBox.less";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";

export interface IInfoBoxProps extends ICommonControlProps {
    iconVisualStyle?: iconVisualStyleType;
    iconName: iconNameType;
}


const InfoBox: React.FC<IInfoBoxProps> = (props) => {

    const className = combineClasses(
        props.iconVisualStyle === "dark" && Styles.darkMode,
        Styles.container);

    return (
        <>
        {props.children &&
            <div className={className}>
                <div className={Styles.icon}>
                    <Ui.Icon iconName={props.iconName} visualStyle={props.iconVisualStyle} />
                </div>
                <div className={Styles.text}>{props.children}</div>
            </div>
        }
        </>
    );
};

InfoBox.defaultProps = {
    iconVisualStyle: "primary"
};
export default InfoBox;