import DependencyContainer from "@DiContainer";
import * as ProductivityProxy from "@HisPlatform/BoundedContexts/Productivity/Api/Proxy.g";
import WorklistDefinitionApiAdapter from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/Worklist/WorklistDefinitionApiAdapter";
import WorklistDefinitionCache from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/Worklist/WorklistDefinitionCache";

DependencyContainer
    .bind("IWorklistClient")
    .to(ProductivityProxy.WorklistClient);

DependencyContainer
    .bind("WorklistDefinitionApiAdapter")
    .to(WorklistDefinitionApiAdapter);

DependencyContainer
    .bind("WorklistDefinitionCache")
    .to(WorklistDefinitionCache)
    .inSingletonScope();