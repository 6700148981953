import AddressTypeId from "@Primitives/AddressTypeId.g";
import CountryId from "@Primitives/CountryId.g";
import GenderId from "@Primitives/GenderId.g";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import InsuranceId from "@Primitives/InsuranceId.g";
import InsurancePlanId from "@Primitives/InsurancePlanId.g";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import NameStore from "@Primitives/NameStore";
import PatientId from "@Primitives/PatientId.g";
import TelecomTypeId from "@Primitives/TelecomTypeId.g";
import TelecomUseId from "@Primitives/TelecomUseId.g";
import IAgeCalculationService from "@HisPlatform/Services//Definition/AgeCalculation/IAgeCalculationService";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import CompositeArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/CompositeArrayFormFieldData";
import CompositeFormFieldData from "@Toolkit/FormEngine/Model/Data/CompositeFormFieldData";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import { getBooleanFieldValue, getDateFieldValue, getEntityFieldValue, getExtensibleEnumFieldValue, getNumberFieldValue, getTextFieldValue } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import State, { IPromiseBasedObservable } from "@Toolkit/ReactClient/Common/StateManaging";
import moment from "moment";

export default class PatientAdministrativeData {

    constructor(
        public formFields: FormFieldDataBase[],
        public rowVersion: RowVersion,
        public ageCalculationService: IAgeCalculationService) {
    }

    public get id() {
        return getEntityFieldValue<PatientId>(this.formFields, "PatientId", PatientId);
    }

    public get isDataCleansingNeeded() {
        return getBooleanFieldValue(this.formFields, "IsDataCleansingNeeded");
    }

    public get isNew() {
        return isNullOrUndefined(this.id);
    }

    public get identifier() {
        const identifier = getTextFieldValue(this.formFields, "Identifier");
        return identifier;
    }

    public get patientDocuments() {
        const documents = getField<CompositeArrayFormFieldData>(this.formFields, "Documents");

        return documents.value.map(i => {
            return this.getDocument(i);
        });
    }

    public get insurances() {
        const insurances = getField<CompositeArrayFormFieldData>(this.formFields, "Insurances");

        return insurances.value.map(i => {
            return this.getInsurance(i);
        });
    }

    public get addresses() {
        const addresses = getField<CompositeArrayFormFieldData>(this.formFields, "ContactInfo.Addresses");

        return addresses.value.map(i => {
            return this.getAddress(i);
        });
    }

    public get telecomContactPoints() {
        const emails = getField<CompositeArrayFormFieldData>(this.formFields, "ContactInfo.EmailAddresses");
        const phoneNumbers = getField<CompositeArrayFormFieldData>(this.formFields, "ContactInfo.PhoneNumbers");

        const telecomEmails = emails.value.map(i => {
            return this.getTelecomEmail(i);
        });

        const telecomPhones = phoneNumbers.value.map(i => {
            return this.getTelecomPhone(i);
        });

        return [...telecomEmails, ...telecomPhones];
    }

    public get baseData() {
        const baseData = getField<CompositeFormFieldData>(this.formFields, "BaseData");

        const prefix = getTextFieldValue(baseData.value, "Prefix");
        const familyName = getTextFieldValue(baseData.value, "FamilyName");
        const givenName1 = getTextFieldValue(baseData.value, "GivenName1");
        const givenName2 = getTextFieldValue(baseData.value, "GivenName2");
        const postfix = getTextFieldValue(baseData.value, "Postfix");

        const birthNamePrefix = getTextFieldValue(baseData.value, "BirthNamePrefix");
        const birthFamilyName = getTextFieldValue(baseData.value, "BirthFamilyName");
        const birthGivenName1 = getTextFieldValue(baseData.value, "BirthGivenName1");
        const birthGivenName2 = getTextFieldValue(baseData.value, "BirthGivenName2");

        const mothersNamePrefix = getTextFieldValue(baseData.value, "MothersNamePrefix");
        const mothersFamilyName = getTextFieldValue(baseData.value, "MothersFamilyName");
        const mothersGivenName1 = getTextFieldValue(baseData.value, "MothersGivenName1");
        const mothersGivenName2 = getTextFieldValue(baseData.value, "MothersGivenName2");
        const mothersNamePostfix = getTextFieldValue(baseData.value, "MothersNamePostfix");

        const birthPlace = getTextFieldValue(baseData.value, "BirthPlace");
        const genderId = getExtensibleEnumFieldValue<GenderId>(baseData.value, "SexId", GenderId);
        const birthDate = getDateFieldValue(baseData.value, "BirthDate");
        const deathDate = getDateFieldValue(baseData.value, "DeathDate");
        const estimatedAgeInYears = getNumberFieldValue(baseData.value, "EstimatedAgeInYears");

        const birthCountryId = getEntityFieldValue<CountryId>(baseData.value, "BirthCountryId", CountryId);
        const citizenshipCountryId = getEntityFieldValue<CountryId>(baseData.value, "CitizenshipCountryId", CountryId);

        return {
            name: {
                prefix: prefix,
                familyName: familyName,
                givenName1: givenName1,
                givenName2: givenName2,
                postfix: postfix 
            } as NameStore,
            mothersName: {
                prefix: mothersNamePrefix,
                familyName: mothersFamilyName,
                givenName1: mothersGivenName1,
                givenName2: mothersGivenName2,
                postfix: mothersNamePostfix 
            } as NameStore,
            birthLocationInfo: {
                countryId: birthCountryId,
                location: birthPlace
            },
            ageInfo: {
                birthDate: birthDate,
                estimatedAgeInYears: estimatedAgeInYears
            },
            deathData: {
                date: deathDate
            },
            isFemale: genderId?.value === GenderId.Female.value,
            genderId: genderId,
            citizenshipCountryId: citizenshipCountryId,
            hasBirthName: !isNullOrUndefined(birthFamilyName),
            birthName: {
                prefix: birthNamePrefix,
                familyName: birthFamilyName,
                givenName1: birthGivenName1,
                givenName2: birthGivenName2
            } as NameStore,
        };
    }

    private getDocument(items: FormFieldDataBase[]) {
        const identifierSystemId = getExtensibleEnumFieldValue<IdentifierSystemId>(items, "IdentifierSystemId", IdentifierSystemId);
        const validFrom = getDateFieldValue(items, "ValidFrom");
        const validTo = getDateFieldValue(items, "ValidTo");
        const identifierValue = getTextFieldValue(items, "IdentifierValue");

        return {
            identifierSystemId: identifierSystemId,
            isDocumentValidAtTime: (referenceTime: LocalDate | moment.Moment) => {
                if (isNullOrUndefined(referenceTime)) {
                    return false;
                }
                if (referenceTime instanceof LocalDate) {
                    return new LocalDateRange(validFrom, validTo).includes(referenceTime);
                }
                return new LocalDateRange(validFrom, validTo).includesMoment(referenceTime);
            },
            identifierValue: identifierValue
        };
    }

    private getInsurance(items: FormFieldDataBase[]) {
        const insuranceId = getEntityFieldValue<InsuranceId>(items, "InsuranceId", InsuranceId);
        const insurerOrganizationId = getEntityFieldValue<InsurerOrganizationId>(items, "InsurerOrganizationId", InsurerOrganizationId);
        const insurancePlanId = getEntityFieldValue<InsurancePlanId>(items, "InsurancePlanId", InsurancePlanId);

        return {
            id: insuranceId,
            insurerOrganizationId: insurerOrganizationId,
            insurancePlanId: insurancePlanId
        };
    }

    private getAddress(items: FormFieldDataBase[]) {
        const countryId = getEntityFieldValue<CountryId>(items, "CountryId", CountryId);

        const addressTypeId = getExtensibleEnumFieldValue<AddressTypeId>(items, "AddressTypeId", AddressTypeId);
        const zipCode = getTextFieldValue(items, "ZipCode");
        const settlement = getTextFieldValue(items, "Settlement");
        const addressLine = getTextFieldValue(items, "AddressLine");

        return {
            countryId: countryId,
            addressTypeId: addressTypeId,
            zipCode: zipCode,
            settlement: settlement,
            addressLine: addressLine
        };
    }

    private getTelecomPhone(items: FormFieldDataBase[]) {
        const phoneUseId = getExtensibleEnumFieldValue<TelecomUseId>(items, "PhoneUseId", TelecomUseId);
        const phoneNumber = getTextFieldValue(items, "PhoneNumber");
        const isPrimary = getBooleanFieldValue(items, "IsPrimaryPhone");

        return {
            telecomTypeId: TelecomTypeId.Phone,
            telecomUseId: phoneUseId,
            value: phoneNumber,
            isPrimary: isPrimary,
            isPhone: true,
            isEmail: false
        };
    }

    private getTelecomEmail(items: FormFieldDataBase[]) {
        const emailUseId = getExtensibleEnumFieldValue<TelecomUseId>(items, "EmailUseId", TelecomUseId);
        const emailAddress = getTextFieldValue(items, "EmailAddress");
        const isPrimary = getBooleanFieldValue(items, "IsPrimaryEmail");

        return {
            telecomTypeId: TelecomTypeId.Email,
            telecomUseId: emailUseId,
            value: emailAddress,
            isPrimary: isPrimary,
            isPhone: false,
            isEmail: true
        };
    }

    private ageCalculationState = {
        lastAgeParameters: null,
        loadingAgePromise: null
    } as { lastAgeParameters: string, loadingAgePromise: IPromiseBasedObservable<string> };

    public get age() {
        const ageParams = `${this.baseData.ageInfo.birthDate ? this.baseData.ageInfo.birthDate.stringify() : "null"},${this.baseData.deathData.date ? this.baseData.deathData.date.stringify() : "null"}`;

        if (this.ageCalculationState.lastAgeParameters !== ageParams) {
            this.ageCalculationState.lastAgeParameters = ageParams;
            this.ageCalculationState.loadingAgePromise = State.fromPromise(
                this.ageCalculationService.calculateAgeAsync(this.baseData.ageInfo.birthDate, this.baseData.deathData.date)
            );
        }

        return this.ageCalculationState.loadingAgePromise;
    }
}