import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import React, { useCallback, useEffect, useState } from "react";
import ShowInvoicePdfAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowInvoicePdfAction.g";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import * as Ui from "@CommonControls";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import InvoicPdfScreenApiAdapter from "./InvoicePdfScreenApiAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { useAsyncMemo } from "@Toolkit/ReactClient/Common/Hooks";

export function InvoicePdfScreen(props: IScreenPropsBase<ShowInvoicePdfAction>) {

    const { invoicPdfScreenApiAdapter } = useDependencies(c => ({
        invoicPdfScreenApiAdapter: c.resolve<InvoicPdfScreenApiAdapter>("InvoicPdfScreenApiAdapter"),
    }));

    const {value, isLoading} = useAsyncMemo(null, async () => {
        const response = await invoicPdfScreenApiAdapter.getInvoicePdfAsync(props.action.invoiceId);
        return response.result;
    }, [props.action.invoiceId.value]);

    if (isNullOrUndefined(value))
        return null;

    return (
        <Screen>
            <Ui.PdfViewer2 documentPath={value.token} downloadFileName={value.name} />
        </Screen>
    );
}