import StatusChangeReasonTypeId from "@Primitives/StatusChangeReasonTypeId.g";
import { ReactNode } from "react";
import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import EhiLoginMode from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiLoginMode";
import EhiToken from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiToken";

export interface IEhiLoginDialogParams extends IModalParams {
    ehiIdentifier: string;
    ehiToken: EhiToken;
    isRenew: boolean;
    ehiLoginName?: string;
}

export default class EhiLoginDialogParams implements IEhiLoginDialogParams {

    public static type = "EhiLoginDialog";
    public get type() { return EhiLoginDialogParams.type; }

    constructor(
        public readonly ehiIdentifier: string,
        public readonly ehiToken: EhiToken,
        public readonly isRenew: boolean,
        public readonly ehiLoginName?: string
    ) { }
}
