import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Productivity/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IWorklistDefinitionBaseInformation from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/WorklistDefinition/IWorklistDefinitionBaseInformation";
import WorklistDefinitionId from "@Primitives/WorklistDefinitionId.g";
import WorklistDefinitionStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/WorklistDefinition/WorklistDefinitionStore";
import WorklistType from "@Primitives/WorklistType.g";
import _ from "@HisPlatform/Common/Lodash";
import WorklistItemDefinitionStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/WorklistDefinition/WorklistItemDefinitionStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";

@Di.injectable()
export default class WorklistDefinitionApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IWorklistClient") private readonly apiClient: Proxy.IWorklistClient,
        @Di.inject("ILocalizationService") private readonly localizationService: ILocalizationService,
        @Di.inject("ICurrentCultureProvider") private readonly cultureCodeProvider: ICurrentCultureProvider
    ) {
        super();
    }

    public getWorklistDefinitionBaseInformationAsync(isPermissionCheckOnly: boolean = false) {
        return this.processOperationAsync(
            new SimpleStore<IWorklistDefinitionBaseInformation[]>(),
            async target => {
                const response = await this.apiClient.getWorklistDefinitionBaseInformationQueryAsync(CreateRequestId(), isPermissionCheckOnly);
                target.operationInfo = createOperationInfo(response);
                if (!isPermissionCheckOnly) {
                    target.value = response.items.map(item => ({
                        id: item.id,
                        name: this.mapLocalizedLabelsToMultiLingualLabelMap(item.localizedNames).getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode),
                        organizationUnitCodes: item.organizationUnitCodes.join(", ")
                    } as IWorklistDefinitionBaseInformation));
                }
            }
        );
    }

    public getWorklistDefinitionAsync(id: WorklistDefinitionId, isPermissionCheckOnly: boolean = false) {
        return this.processOperationAsync(
            new SimpleStore<WorklistDefinitionStore>(),
            async target => {
                const response = await this.apiClient.getWorklistDefinitionQueryAsync(CreateRequestId(), id.value, isPermissionCheckOnly);
                target.operationInfo = createOperationInfo(response);
                if (!isPermissionCheckOnly) {
                    target.value = new WorklistDefinitionStore(
                        response.worklistDefinition.id,
                        this.mapLocalizedLabelsToMultiLingualLabelMap(response.worklistDefinition.localizedNames)
                            .getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode),
                        response.worklistDefinition.worklistType,
                        response.worklistDefinition.worklistItemDefinitions.map(wid => {
                            const localizedName = this.getLocalizedName(wid);
                            return new WorklistItemDefinitionStore(
                                true,
                                wid.order,
                                wid.itemVisibility,
                                wid.itemPlacement,
                                wid.isFiltered,
                                wid.isSortingEnabled,
                                wid.defaultColumnWidth,
                                wid.ellipsisOnOverflow,
                                wid.attributeReference,
                                wid.headerResourceId,
                                wid.displayValue ?? localizedName.value,
                                wid.displayValue,
                                localizedName.hasLocalizedName);
                        }),
                        response.worklistDefinition.rowVersion.value);
                }
            }
        );
    }

    public getWorklistItemDefinitionsAsync(type: WorklistType, isPermissionCheckOnly: boolean = false) {
        return this.processOperationAsync(
            new SimpleStore<WorklistItemDefinitionStore[]>(),
            async target => {
                const response = await this.apiClient.getWorklistItemDefinitionsQueryAsync(CreateRequestId(), type.value, isPermissionCheckOnly);
                target.operationInfo = createOperationInfo(response);
                if (!isPermissionCheckOnly) {
                    target.value = _.flatten(response.items).map(wid => {
                        const localizedName = this.getLocalizedName(wid);
                        return new WorklistItemDefinitionStore(
                            false,
                            wid.order,
                            wid.itemVisibility,
                            wid.itemPlacement,
                            wid.isFiltered,
                            wid.isSortingEnabled,
                            wid.defaultColumnWidth,
                            wid.ellipsisOnOverflow,
                            wid.attributeReference,
                            wid.headerResourceId,
                            wid.displayValue ?? localizedName.value,
                            wid.displayValue,
                            localizedName.hasLocalizedName);
                    });
                }
            }
        );
    }

    public updateWorklistDefinitionAsync(
        id: WorklistDefinitionId,
        rowVersion: number,
        worklistItemDefinitions: WorklistItemDefinitionStore[],
        isPermissionCheckOnly: boolean = false) {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const dto = new Proxy.UpdateWorklistDefinitionDto({
                    rowVersion: new RowVersion(rowVersion),
                    worklistItemDefinitions: worklistItemDefinitions
                        .map(wid => new Proxy.WorklistItemDefinitionDto({
                            attributeReference: wid.attributeReference,
                            order: wid.order,
                            itemVisibility: wid.itemVisibility,
                            itemPlacement: wid.itemPlacement,
                            isFiltered: wid.isFiltered,
                            isSortingEnabled: wid.isSortingEnabled,
                            defaultColumnWidth: wid.defaultColumnWidth,
                            ellipsisOnOverflow: wid.ellipsisOnOverflow,
                            headerResourceId: wid.headerResourceId,
                            displayValue: wid.displayValue
                        }))
                });
                const response = await this.apiClient.updateWorklistDefinitionCommandAsync(
                    CreateRequestId(),
                    new Proxy.UpdateWorklistDefinitionControllerDto({ id: id, data: dto, requestLock: true }),
                    isPermissionCheckOnly);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    private getLocalizedName(row: Proxy.WorklistItemDefinitionDto): { value: string, hasLocalizedName: boolean } {
        const localizedName = this.localizationService.localizeReferenceData(row.headerResourceId);
        return { value: localizedName || row.attributeReference.value, hasLocalizedName: !isNullOrEmptyString(localizedName) };
    }

    private mapLocalizedLabelsToMultiLingualLabelMap(localizedLabels: Proxy.LocalizedLabel[]) {
        if (localizedLabels == null)
            return null;
        const localizedLabelMap = new Map<string, string>();
        localizedLabels.forEach(l => localizedLabelMap.set(l.cultureCode?.value, l.label));
        return new MultiLingualLabel(localizedLabelMap);
    }
}