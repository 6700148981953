import Di from "@Di";
import FormExtensionBase from "@PluginInterface/FormExtension/FormExtensionBase";

@Di.injectable()
export default class HunAdmitPatientAndCareActivitiyBaseDataScreenExtension extends FormExtensionBase<any> {

    constructor(
    ) {
        super();

        this.registerCallback<string[]>("GetLoadedSignals", this.getLoadedSignals);
    }

    private getLoadedSignals() {
        return [
            "CustomBlock:HunCareActivityCoverageDataPanel"
        ];
    }
}