import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityId from "@Primitives/CareActivityId.g";
import PatientId from "@Primitives/PatientId.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import IMapperService from "@HisPlatform/Services/Definition/MapperService/IMapperService";
import IFrontendListDataWithDefinition from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/IFrontendListDataWithDefinition";

@Di.injectable()
export default class ServiceRequestListScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("IServiceRequestClient") private readonly apiClient: Proxy.IServiceRequestClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("IMapperService") private readonly mapperService: IMapperService,
    ) {
        super(referenceDataLoader);
    }
    
    @State.bound
    public getCareActivityServiceRequestListAsync(
        careActivityId: CareActivityId,
        frontendListParameters: FrontendListParameters
    ) {
        return this.executeOperationAsync<IFrontendListDataWithDefinition, Proxy.GetCareActivityServiceRequestListQueryResponse>(
            () => this.apiClient.getCareActivityServiceRequestListQueryAsync(CreateRequestId(), new Proxy.GetCareActivityServiceRequestListControllerDto({
                careActivityId: careActivityId,
                filters: this.mapperService.mapByName("FilterDtoArray", frontendListParameters),
                pagingAndOrderings: this.mapperService.mapByName("QueryPagingsAndOrderings", frontendListParameters),
                requestMode: frontendListParameters.requestMode
            })),
            response => {
                return this.mapperService.map<Proxy.FrontendListDto, IFrontendListDataWithDefinition>(response.frontendList);
            }
        );
    }

    @State.bound
    public getPatientServiceRequestListAsync(
        patientId: PatientId,
        careActivityId: CareActivityId,
        frontendListParameters: FrontendListParameters
    ) {
        return this.executeOperationAsync<IFrontendListDataWithDefinition, Proxy.GetPatientServiceRequestListQueryResponse>(
            () => this.apiClient.getPatientServiceRequestListQueryAsync(CreateRequestId(), new Proxy.GetPatientServiceRequestListControllerDto({
                patientId: patientId,
                careActivityId: careActivityId,
                filters: this.mapperService.mapByName("FilterDtoArray", frontendListParameters),
                pagingAndOrderings: this.mapperService.mapByName("QueryPagingsAndOrderings", frontendListParameters),
                requestMode: frontendListParameters.requestMode
            })),
            response => {
                return this.mapperService.map<Proxy.FrontendListDto, IFrontendListDataWithDefinition>(response.frontendList);
            }
        );
    }
}
