exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EhiSyncStatusLabel_main-container_2qfe8 {\n  display: inline-flex;\n  height: 14px;\n  line-height: 12.8px;\n  font-family: PagatiNarrow, sans-serif;\n  font-size: 9px;\n  border: 1px solid;\n  border-radius: 2px;\n  width: 47px;\n  margin-left: 3px;\n}\n.EhiSyncStatusLabel_upper-index_2PTwt {\n  top: -6px;\n  position: relative;\n}\n.EhiSyncStatusLabel_in-sync_1YsjT {\n  border-color: #21C313;\n  color: #21C313;\n  background-color: #D3F3D0;\n}\n.EhiSyncStatusLabel_not-in-sync_3eBBV {\n  border-color: #ddc00d;\n  color: #ddc00d;\n  background-color: #F8F2CF;\n}\n.EhiSyncStatusLabel_icon-container_HQNsi {\n  display: block;\n  height: 10px;\n  width: 10px;\n  margin-top: auto;\n  margin-bottom: auto;\n  margin-left: 1px;\n  margin-right: 4px;\n  border-radius: 2px;\n}\n.EhiSyncStatusLabel_icon-container_HQNsi svg {\n  top: 0px;\n  left: -1px;\n}\n.EhiSyncStatusLabel_icon-in-sync_1BRjy {\n  background-color: #21C313;\n}\n.EhiSyncStatusLabel_icon-in-sync_1BRjy svg {\n  fill: #D3F3D0;\n}\n.EhiSyncStatusLabel_icon-not-in-sync_14cJ8 {\n  background-color: #ddc00d;\n}\n.EhiSyncStatusLabel_icon-not-in-sync_14cJ8 svg {\n  fill: #F8F2CF;\n}\n", ""]);

// exports
exports.locals = {
	"main-container": "EhiSyncStatusLabel_main-container_2qfe8",
	"mainContainer": "EhiSyncStatusLabel_main-container_2qfe8",
	"upper-index": "EhiSyncStatusLabel_upper-index_2PTwt",
	"upperIndex": "EhiSyncStatusLabel_upper-index_2PTwt",
	"in-sync": "EhiSyncStatusLabel_in-sync_1YsjT",
	"inSync": "EhiSyncStatusLabel_in-sync_1YsjT",
	"not-in-sync": "EhiSyncStatusLabel_not-in-sync_3eBBV",
	"notInSync": "EhiSyncStatusLabel_not-in-sync_3eBBV",
	"icon-container": "EhiSyncStatusLabel_icon-container_HQNsi",
	"iconContainer": "EhiSyncStatusLabel_icon-container_HQNsi",
	"icon-in-sync": "EhiSyncStatusLabel_icon-in-sync_1BRjy",
	"iconInSync": "EhiSyncStatusLabel_icon-in-sync_1BRjy",
	"icon-not-in-sync": "EhiSyncStatusLabel_icon-not-in-sync_14cJ8",
	"iconNotInSync": "EhiSyncStatusLabel_icon-not-in-sync_14cJ8"
};