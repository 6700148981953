import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, {IExtensibleEnumSelectBoxPropsBase} from "@HisPlatformControls/ExtensibleEnumSelectBox";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IJobPositionSelectBoxDependencies {
    referenceDataStore: OrganizationReferenceDataStore;
}

interface IJobPositionSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IJobPositionSelectBoxDependencies;
}


@State.observer
class JobPositionSelectBox extends React.Component<IJobPositionSelectBoxProps> {
    private get dependencies() {
        return this.props._dependencies;
    }

    private get extensibleEnumStore() {
        return this.dependencies.referenceDataStore.jobPositions;
    }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                orderItems={true}
                extensibleEnumStore={this.extensibleEnumStore}
                {...this.props}
            />
        );
    }
}

export default connect(
    JobPositionSelectBox,
    new DependencyAdapter<IJobPositionSelectBoxProps, IJobPositionSelectBoxDependencies>((containerService) => {
        return {
            referenceDataStore: containerService.resolve("OrganizationReferenceDataStore")
        };
    })
);
