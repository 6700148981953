import React from "react";
import Styles from "./SidebarLayout.less";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

interface ISidebarLayoutViewProps {
    leftSidebar?: React.ReactNode;
    rightSidebar?: React.ReactNode;
    style?: React.CSSProperties;

    leftSidebarClassName?: string;
    rightSidebarClassName?: string;
    contentClassName?: string;
    className?: string;
}


const SidebarLayout: React.SFC<ISidebarLayoutViewProps> = (props) => {
    return (
        <>
            <div className={combineClasses(Styles.container, props.className)} style={props.style}>
                {props.leftSidebar && (
                    <div className={combineClasses(Styles.sidebar, props.leftSidebarClassName)}>
                        <LoadingBoundary>
                            {props.leftSidebar}
                        </LoadingBoundary>
                    </div>
                )}
                <div className={combineClasses(Styles.body, props.contentClassName)}>
                    <LoadingBoundary>
                        {props.children}
                    </LoadingBoundary>
                </div>
                {props.rightSidebar && (

                    <div className={combineClasses(Styles.sidebar, props.rightSidebarClassName)}>
                        <LoadingBoundary>
                            {props.rightSidebar}
                        </LoadingBoundary>
                    </div>
                )}
            </div>
        </>
    );
};

export default SidebarLayout;