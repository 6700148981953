import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/CommonReferenceData/Api/Proxy.g";
import CountryId from "@Primitives/CountryId.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import Di from "@Di";
import SettlementWithZipCodeId from "@Primitives/SettlementWithZipCodeId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import _ from "@HisPlatform/Common/Lodash";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { IClientSideSettlementWithZipCode } from "@HisPlatformControls/SettlementWithZipCodeSelector/IClientSideSettlementWithZipCode";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import SettlementsWithZipCodeSelectorQueryOrderingFields from "@HisPlatform/BoundedContexts/CommonReferenceData/Api/CommonReferenceData/Enum/SettlementsWithZipCodeSelectorQueryOrderingFields.g";

@Di.injectable()
export default class SettlementApiAdapter extends ApiAdapterBase {
    constructor(@Di.inject("ICommonReferenceDataClient") private client: Proxy.ICommonReferenceDataClient) {
        super();
    }

    @State.bound
    public async searchAsync(searchText: string, countryId: CountryId) {
        const searchDto = new Proxy.SearchSettlementsControllerDto({
            countryId: countryId,
            settlementNamePrefix: searchText
        });
        const results = await this.client.searchSettlementsQueryAsync(CreateRequestId(), searchDto);
        return results.settlementNames;
    }

    public searchSettlementsWithZipCodesByTextAsync(text: string, countryId: CountryId, maxResultCount: number, validOn: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<SettlementWithZipCodeId[]>(),
            async target => {
                const response = await this.client.searchSettlementsWithZipCodesQueryAsync(
                    CreateRequestId(),
                    this.getSearchSettlementsWithZipCodesControllerDto(text, countryId, maxResultCount, validOn)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.settlementsWithZipCodeIds;
            }
        );
    }

    public searchSettlementNamesByZipCodeAsync(text: string, zipCode: string, countryId: CountryId, maxResultCount: number, validOn: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<string[]>(),
            async target => {
                const response = await this.client.searchSettlementNamesByZipCodeQueryAsync(
                    CreateRequestId(),
                    new Proxy.SearchSettlementNamesByZipCodeControllerDto({
                        countryId: countryId,
                        maxResultCount: maxResultCount,
                        searchText: text,
                        validOn: validOn,
                        zipCode: zipCode
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.settlementNames;
            }
        );
    }

    public searchZipCodesBySettlementAsync(text: string, settlement: string, countryId: CountryId, maxResultCount: number, validOn: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<string[]>(),
            async target => {
                const response = await this.client.searchZipCodesBySettlementQueryAsync(
                    CreateRequestId(),
                    new Proxy.SearchZipCodesBySettlementControllerDto({
                        countryId: countryId,
                        maxResultCount: maxResultCount,
                        searchText: text,
                        validOn: validOn,
                        settlement: settlement
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.zipCodes;
            }
        );
    }

    private getSearchSettlementsWithZipCodesControllerDto(text: string, countryId: CountryId, maxResultCount: number, validOn: LocalDate): Proxy.SearchSettlementsWithZipCodesControllerDto {
        return new Proxy.SearchSettlementsWithZipCodesControllerDto({
            countryId: countryId,
            searchText: text,
            maxResultCount: maxResultCount,
            validOn: validOn,
            extensionData: null
        });
    }

    public getAllSettlementIdsByCountryIdAsync(countryId: CountryId): Promise<SimpleStore<SettlementWithZipCodeId[]>> {
        return this.processOperationAsync(
            new SimpleStore<SettlementWithZipCodeId[]>(),
            async target => {
                const result = await this.client.getAllSettlementWithZipCodeIdsByCountryIdQueryAsync(CreateRequestId(), countryId.value);
                target.value = result && result.settlementWithZipCodeIds || [];
                target.operationInfo = createOperationInfo(result);
            }
        );
    }

    @State.bound
    public getAllSettlementIdsAsync(): Promise<SimpleStore<SettlementWithZipCodeId[]>> {
        return this.processOperationAsync(
            new SimpleStore<SettlementWithZipCodeId[]>(),
            async target => {
                const result = await this.client.getAllSettlementWithZipCodeIdsQueryAsync(CreateRequestId());
                target.value = result && result.settlementWithZipCodeIds || [];
                target.operationInfo = createOperationInfo(result);
            }
        );
    }

    private getControllerDto(ids: SettlementWithZipCodeId[]): Proxy.GetSettlementWithZipCodesByIdsControllerDto {
        return new Proxy.GetSettlementWithZipCodesByIdsControllerDto({
            ids: ids,
            validOn: LocalDate.createFromMoment(DateTimeService.now())
        });
    }

    @State.bound
    public getSettlementsByIdsAsync(ids: SettlementWithZipCodeId[]): Promise<SimpleStore<IClientSideSettlementWithZipCode[]>> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                if (arrayIsNullOrEmpty(ids)) {
                    target.value = [];
                }
                const normalizedIds = _.uniq(ids.filter(id => !!id));

                const response = await this.client.getSettlementWithZipCodesByIdsQueryAsync(CreateRequestId(), this.getControllerDto(normalizedIds));

                target.operationInfo = createOperationInfo(response);
                target.value = response.settlementsWithZipCodes.map(c => {
                    return {
                        id: c.id,
                        settlementName: c.name,
                        zipCode: c.zipCode,
                        countryId: c.countryId
                    } as IClientSideSettlementWithZipCode;
                });
            }
        );
    }

    @State.bound
    public searchSettlements(name: string, countryId: CountryId, validOn: LocalDate, ordering: IOrderingState, paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<IClientSideSettlementWithZipCode>>(),
            async (target) => {
                const response = await this.client.settlementsWithZipCodeSelectorQueryAsync(
                    CreateRequestId(),
                    new Proxy.SettlementsWithZipCodeSelectorControllerDto({
                        name: name,
                        countryId: countryId,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfSettlementsWithZipCodeSelectorQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfSettlementsWithZipCodeSelectorQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: SettlementsWithZipCodeSelectorQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        }),
                        validOn: validOn
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results && response.results.values.map(c => {
                        return {
                            id: c.id,
                            settlementName: c.name,
                            zipCode: c.zipCode,
                            countryId: c.countryId
                        } as IClientSideSettlementWithZipCode;
                    })
                };
            }
        );
    }
}
