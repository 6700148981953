import PerformedServiceGroupId from "@Primitives/PerformedServiceGroupId.g";
import CareActivityId from "@Primitives/CareActivityId.g";

export default class PerformedServiceGroupIdentifier {
    constructor(
        public performedServiceGroupId: PerformedServiceGroupId,
        public careActivityId: CareActivityId
    ) {

    }
}
