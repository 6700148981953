import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/Proxy.g";
import Di from "@Di";
import EuErrorList from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/EuErrorList";

@Di.injectable()
export default class EuErrorListDtoMapper {

    public getEuErrorListDto(store: EuErrorList) {
        return new Proxy.EuErrorListDto(
            {
                processLog: store.processLog,
                hasProcessError: store.hasProcessError,
            } as Proxy.IEuErrorListDto);
    }
}