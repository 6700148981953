import FormDataElementBase from "./FormDataElementBase";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";

export default class CompositeFormDataElement extends FormDataElementBase {

    @State.observable.ref public formDataElements: IObservableArray<FormDataElementBase>;

    constructor(
        name: string,
        isArray: boolean,
        isReadOnly: boolean,
        isVisible: boolean,
        formDataElements: FormDataElementBase[]
    ) {
        super(name, isArray, null, isReadOnly, isVisible);
        this.formDataElements = State.createObservableShallowArray<FormDataElementBase>(formDataElements);
    }

    public formDataElementType = "Composite";
}