exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ToolbarLayout_no-text-selection_3Hscp {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.ToolbarLayout_layout_2OZ0U {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.ToolbarLayout_toolbar-common_uaMz_ {\n  flex: 0 1 auto;\n  background-color: white;\n  margin: 0px;\n  z-index: 1002;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.ToolbarLayout_top-toolbar_1BbUL {\n  flex: 0 1 auto;\n  background-color: white;\n  margin: 0px;\n  z-index: 1002;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.ToolbarLayout_working-area_134AG {\n  flex: 1;\n  overflow: hidden;\n}\n.ToolbarLayout_bottom-toolbar_AKvS1 {\n  flex: 0 1 auto;\n  background-color: white;\n  margin: 0px;\n  z-index: 1002;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 4px;\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "ToolbarLayout_no-text-selection_3Hscp",
	"noTextSelection": "ToolbarLayout_no-text-selection_3Hscp",
	"layout": "ToolbarLayout_layout_2OZ0U",
	"toolbar-common": "ToolbarLayout_toolbar-common_uaMz_",
	"toolbarCommon": "ToolbarLayout_toolbar-common_uaMz_",
	"top-toolbar": "ToolbarLayout_top-toolbar_1BbUL",
	"topToolbar": "ToolbarLayout_top-toolbar_1BbUL",
	"working-area": "ToolbarLayout_working-area_134AG",
	"workingArea": "ToolbarLayout_working-area_134AG",
	"bottom-toolbar": "ToolbarLayout_bottom-toolbar_AKvS1",
	"bottomToolbar": "ToolbarLayout_bottom-toolbar_AKvS1"
};