import DependencyContainer from "@DiContainer";
import DocumentTypesApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/ReferenceData/DocumentTypesApiAdapter";
import TemplateApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Templating/TemplateApiAdapter";

DependencyContainer
    .bind("DocumentTypesApiAdapter")
    .to(DocumentTypesApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("TemplateApiAdapter")
    .to(TemplateApiAdapter)
    .inSingletonScope();