import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import HealthcareProfessionId from "@Primitives/HealthcareProfessionId.g";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";

export default class HealthcareProfession extends EntityStoreBase<HealthcareProfessionId> {

    constructor(public name: string = "",
                public codes: Identifier[] = [],
                public parentId: HealthcareProfessionId = null) {
        super();
    }

    public getCodeValueByIdentifierSystem(identifierSystem: string): string {
        const filteredItems = this.codes.filter(x => x.identifierSystemId.value === identifierSystem);
        return filteredItems && filteredItems.length > 0 ? filteredItems[0].value : "";
    }
}
