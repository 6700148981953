/* auto-inject-disable */
import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as DocumentManagementProxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import DocumentType from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/ReferenceData/DocumentType";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { buildQueryStringArray } from "@Toolkit/CommonWeb/QueryStringBuilder";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import ScopeIdentifier from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ScopeIdentifier";
import { getScopeIdentifiersDto } from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/DtoMappers";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import CareActivityId from "@Primitives/CareActivityId.g";

@Di.injectable()
export default class DocumentTypesApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("DocumentManagementProxy.IReferenceDataClient") private readonly apiClient: DocumentManagementProxy.IReferenceDataClient
    ) {
        super();
    }

    @State.bound
    public getAvailableDocumentTypesAsync(organizationUnitId: OrganizationUnitId) {
        return this.processOperationAsync(
            new SimpleStore<DocumentType[]>(),
            async target => {
                const response = await this.apiClient.getAvailableDocumentTypesQueryAsync(
                    CreateRequestId(),
                    organizationUnitId.value
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.availableDocumentTypes?.map(t => this.mapDocumentType(t)) ?? [];
            }
        );
    }

    public mapDocumentType(dto: DocumentManagementProxy.DocumentTypeDto): DocumentType {
        return new DocumentType(
            new DocumentTypeId(dto.id.value.toString()),
            dto.name,
            dto.code);
    }

    @State.bound
    public getAllDocumentTypesAsync() {
        return this.processOperationAsync(
            new SimpleStore<DocumentType[]>(),
            async target => {
                const response = await this.apiClient.getAllDocumentTypesQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.documentTypes?.map(t => this.mapDocumentType(t)) ?? [];
            }
        );
    }

    @State.bound
    public getAllDocumentTypeIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<DocumentTypeId[]>(),
            async target => {
                const response = await this.apiClient.getAllDocumentTypeIdsQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.documentTypeIds;
            }
        );
    }

    @State.bound
    public getDocumentTypesByIdsAsync(ids: DocumentTypeId[]): Promise<SimpleStore<DocumentType[]>> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {

                if (arrayIsNullOrEmpty(ids)) {
                    target.value = [];
                }

                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value));

                const response = await this.apiClient.getDocumentTypesByIdsQueryAsync(
                    CreateRequestId(),
                    buildQueryStringArray(normalizedIds)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.documentTypes.map(a => {
                    return {
                        id: new DocumentTypeId(a.id.value.toString()),
                        name: a.name,
                        code: a.code
                    } as DocumentType;
                });
            }
        );
    }

    public getDocumentTypeByCodeAsync(code: string) {
        return this.processOperationAsync(
            new SimpleStore<DocumentType>(),
            async target => {
                const response = await this.apiClient.getDocumentTypeByCodeQueryAsync(CreateRequestId(), code);

                target.operationInfo = createOperationInfo(response);
                target.value = this.mapDocumentType(response.documentType) ?? null;
            }
        );
    }

    @State.bound
    public getNotCreatedRequiredDocumentTypesAsync(scopeIdentifiers: ScopeIdentifier[]) {
        return this.processOperationAsync(
            new SimpleStore<DocumentType[]>(),
            async target => {
                const response = await this.apiClient.getNotCreatedRequiredDocumentTypesQueryAsync(
                    CreateRequestId(),
                    new DocumentManagementProxy.GetNotCreatedRequiredDocumentTypesControllerDto({
                        scopeIdentifiers: getScopeIdentifiersDto(scopeIdentifiers)
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.notCreatedRequiredDocumentTypes?.map(t => this.mapDocumentType(t)) ?? [];
            }
        );
    }
}