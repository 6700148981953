import React from "react";
import Di from "@Di";
import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import GenderColumn from "@HisPlatformControls/DataGridColumns/GenderColumn";
import CommonReferenceDataDataStore from "./ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import DependencyContainer from "@DiContainer";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import { CountrySelector, CitizenshipSelector } from "@HisPlatformControls";
import CountryId from "@Primitives/CountryId.g";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import GenderRadioButtonGroup from "@HisPlatform/BoundedContexts/Care/Components/Controls/PatientRegister/GenderRadioButtonGroup";
import GenderId from "@Primitives/GenderId.g";
import OccupationSelectBox from "./Components/PatientRegister/OccupationSelectBox/OccupationSelectBox";
import OccupationId from "@Primitives/OccupationId.g";
import EducationLevelSelectBox from "./Components/Controls/EducationLevelSelectBox";
import EducationLevelId from "@Primitives/EducationLevelId.g";
import AddressTypeSelectBox from "./Components/Controls/AddressTypeSelectBox";
import AddressTypeId from "@Primitives/AddressTypeId.g";
import TelecomUseSelectBox from "./Components/Controls/TelecomUseSelectBox";
import TelecomUseId from "@Primitives/TelecomUseId.g";
import MaritalStatusSelectBox from "./Components/Controls/MaritalStatusSelectBox";
import MaritalStatusId from "@Primitives/MaritalStatusId.g";

@Di.injectable()
export default class CommonReferenceDataBoundedContext implements IBoundedContext {

    public initializeAsync(): Promise<void> {
        return Promise.resolve();
    }

    public configureNData?(configurator: INDataAttributeConfigurator): void {
        configurator.registerColumn("GenderId", <GenderColumn />);
    }

    public configureReferenceDataLoaders(referenceDataLoader: IReferenceDataLoader) {
        referenceDataLoader.register("Identifier", async (refs: any[]) => {
            const identifierSystemIdstoLoad = refs.map(s => s.identifierSystemId);
            const commonReferenceDataStore: CommonReferenceDataDataStore = DependencyContainer.get("CommonReferenceDataDataStore");
            await commonReferenceDataStore.identifierSystemMap.ensureLoadedAsync(identifierSystemIdstoLoad);
        });
    }

    public configureFormEngine(registry: FormEditorRegistry): void {
        
        registry.registerEntityReferenceEditor(
            "Country",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineEntityReferenceDisplayName.Country,
                componentType: CountrySelector,
                entityIdCtor: CountryId,
                loadReferenceDataAsync: (id: CountryId | CountryId[]) => {
                    if (Array.isArray(id)) {
                        DependencyContainer.get<CommonReferenceDataDataStore>("CommonReferenceDataDataStore").countryMap.ensureLoadedAsync(id);
                    } else {
                        DependencyContainer.get<CommonReferenceDataDataStore>("CommonReferenceDataDataStore").countryMap.ensureLoadedAsync([id]);
                    }
                    return Promise.resolve();
                }
            }
        );

        registry.registerEntityReferenceEditor(
            "Citizenship",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineEntityReferenceDisplayName.Citizenship,
                componentType: CitizenshipSelector,
                entityIdCtor: CountryId,
                loadReferenceDataAsync: (id: CountryId | CountryId[]) => {
                    if (Array.isArray(id)) {
                        DependencyContainer.get<CommonReferenceDataDataStore>("CommonReferenceDataDataStore").countryMap.ensureLoadedAsync(id);
                    } else {
                        DependencyContainer.get<CommonReferenceDataDataStore>("CommonReferenceDataDataStore").countryMap.ensureLoadedAsync([id]);
                    }
                    return Promise.resolve();
                }
            }
        );

        registry.registerExtensibleEnumEditor(
            "Gender",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineExtensibleEnumDisplayName.Gender,
                componentType: GenderRadioButtonGroup,
                entityIdCtor: GenderId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<CommonReferenceDataDataStore>("CommonReferenceDataDataStore").gender.ensureLoadedAsync();
                    return Promise.resolve();
                }
            }
        );

        registry.registerExtensibleEnumEditor(
            "Occupation",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineExtensibleEnumDisplayName.Occupation,
                componentType: OccupationSelectBox,
                entityIdCtor: OccupationId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<CommonReferenceDataDataStore>("CommonReferenceDataDataStore").occupation.ensureLoadedAsync();
                    return Promise.resolve();
                }
            }
        );

        registry.registerExtensibleEnumEditor(
            "EducationLevel",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineExtensibleEnumDisplayName.EducationLevel,
                componentType: EducationLevelSelectBox,
                entityIdCtor: EducationLevelId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<CommonReferenceDataDataStore>("CommonReferenceDataDataStore").educationLevel.ensureLoadedAsync();
                    return Promise.resolve();
                }
            }
        );

        registry.registerExtensibleEnumEditor(
            "AddressType",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineExtensibleEnumDisplayName.AddressType,
                componentType: AddressTypeSelectBox,
                entityIdCtor: AddressTypeId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<CommonReferenceDataDataStore>("CommonReferenceDataDataStore").addressType.ensureLoadedAsync();
                    return Promise.resolve();
                }
            }
        );

        registry.registerExtensibleEnumEditor(
            "TelecomUse",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineExtensibleEnumDisplayName.TelecomUse,
                componentType: TelecomUseSelectBox,
                entityIdCtor: TelecomUseId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<CommonReferenceDataDataStore>("CommonReferenceDataDataStore").telecomUse.ensureLoadedAsync();
                    return Promise.resolve();
                }
            }
        );

        registry.registerExtensibleEnumEditor(
            "MaritalStatus",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineExtensibleEnumDisplayName.MaritalStatus,
                componentType: MaritalStatusSelectBox,
                entityIdCtor: MaritalStatusId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<CommonReferenceDataDataStore>("CommonReferenceDataDataStore").maritalStatus.ensureLoadedAsync();
                    return Promise.resolve();
                }
            }
        );
    }
}