import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientId from "@Primitives/PatientId.g";
import {CreateRequestId} from "@HisPlatform/Common/RequestHelper";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import IReferenceDataLoader from "@HisPlatform/Services//Definition/ReferenceDataLoader/IReferenceDataLoader";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import Di from "@Di";

@Di.injectable()
export default class CombinePatientsScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("IPatientsClient") private readonly apiClient: Proxy.IPatientsClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public combinePatientsAsync(patientToBeKeptId: PatientId, patientToBeDeletedId: PatientId) {
        return this.executeOperationAsync<number, Proxy.CombinePatientsCommandResponse>(
            () => this.apiClient.combinePatientsCommandAsync(CreateRequestId(), new Proxy.CombinePatientsControllerDto({
                patientToBeKept: patientToBeKeptId,
                patientToBeDeleted: patientToBeDeletedId
            })),
            response => {
                return response.movedCareActivityCount;
            }
        );
    }
}