import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";

interface ICareActivityStateMultiSelectProps extends ISelectBoxBaseProps {
    value?: CareActivityState | CareActivityState[];
    onChange?: (newValue: CareActivityState | CareActivityState[]) => void;
}


const CareActivityStateSelectBox: React.FC<ICareActivityStateMultiSelectProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"CareActivityState"}
        enumOrigin="server"
        enumType={CareActivityState}
        displayMode="selectBox"
    />
);

export default CareActivityStateSelectBox;