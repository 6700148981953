import React from "react";
import { ITemplateInfoDialogParams, ITemplateInfoDialogResult } from "./TemplateInfoDialogParams";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import DocumentTypeSelectBox from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Controls/DocumentTypeSelectBox/DocumentTypeSelectBox";
import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";
import ClientSideValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidationBoundary";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { Templating } from "@HisPlatform/BoundedContexts/DocumentManagement/Api/ApiConstraints.g";

interface ITemplateInfoDialogDependencies {
    notificationService: INotificationService;
}

interface ITemplateInfoDialogProps extends IModalComponentParams<ITemplateInfoDialogResult>, ITemplateInfoDialogParams {
    _dependencies?: ITemplateInfoDialogDependencies;
}

/** @screen */
@State.observer
class TemplateInfoDialog extends React.Component<ITemplateInfoDialogProps> {

    private get notificationService() { return this.props._dependencies.notificationService; }

    @State.observable.ref private code = this.props.currentInfo.code ?? "";
    @State.observable.ref private name = this.props.currentInfo.name ?? "";
    @State.observable.ref private documentTypeId: DocumentTypeId = this.props.currentInfo.documentTypeId ?? null;
    @State.observable.ref private description = this.props.currentInfo.description ?? "";
    @State.observable.ref private cultureCode = isNullOrEmptyString(this.props.currentInfo.cultureCode) ? "hu-HU" : this.props.currentInfo.cultureCode;
    @State.observable.ref public preloadedCultureCodeOptions: Array<ISelectBoxItem<string>> = this.getOptions();

    private readonly clientSideValidator = new ClientSideValidator(Templating, {
        shouldBeFilled: StaticDocumentManagementResources.Validation.Required
    });
    private get staticResources() { return StaticDocumentManagementResources.TemplateManagement.TemplateInfoDialog; }

    public render() {
        return (
            <Ui.Modal
                isOpen
                onClose={this.cancel}
                title={this.staticResources.Title}
                size="tiny"
            >
                <Ui.Modal.Body>
                    <ClientSideValidationBoundary validator={this.clientSideValidator} entityTypeName="CreateTemplate">
                        <Ui.Flex>
                            <Ui.Flex.Item sm={6}>
                                <Ui.TextBox
                                    label={this.staticResources.Code}
                                    value={this.code}
                                    onChange={this.setCode}
                                    isReadOnly={!this.props.isCreation}
                                    propertyIdentifier="Code" automationId="codeTextBox"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item sm={6}>
                                <Ui.TextBox label={this.staticResources.Name} value={this.name} onChange={this.setName} propertyIdentifier="Name" automationId="nameTextBox" />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={6}>
                                <DocumentTypeSelectBox label={this.staticResources.DocumentType} value={this.documentTypeId} onChange={this.setDocumentTypeId} propertyIdentifier="DocumentTypeId" automationId="documentTypeSelectBox" />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={6}>
                                <Ui.SelectBox
                                    label={this.staticResources.CultureCode}
                                    items={this.preloadedCultureCodeOptions}
                                    getOptionValue={this.getCultureCodeOptionValue}
                                    value={this.cultureCode}
                                    onChange={this.setCultureCode}
                                    required
                                    size="standard"
                                    automationId="cultureCodeSelectBox"
                                    clearable={false}
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={12}>
                                <Ui.TextBox label={this.staticResources.Description} multiline multilineMinLineCount={5} multilineMaxLineCount={10} value={this.description} onChange={this.setDescription} propertyIdentifier="Description" automationId="descriptionTextBox" />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </ClientSideValidationBoundary>
                </Ui.Modal.Body>
                <Ui.Modal.Footer
                    left={<Ui.Modal.Button closeModalOnClick visualStyle="link" text={this.staticResources.Cancel} automationId="cancelButton" />}
                    right={<Ui.Button visualStyle="primary" text={this.props.isCreation ? this.staticResources.Create : this.staticResources.Ok} onClick={this.ok} automationId="saveButton" disabled={this.props.currentInfo?.isDeleted} />}
                />
            </Ui.Modal>
        );
    }

    @State.action.bound
    private setCode(value: string) {
        this.code = value?.toUpperCase();
    }

    @State.action.bound
    private setName(value: string) {
        this.name = value;
    }

    @State.action.bound
    private setDescription(value: string) {
        this.description = value;
    }

    @State.action.bound
    private setDocumentTypeId(value: DocumentTypeId) {
        this.documentTypeId = value;
    }

    @State.action.bound
    private setCultureCode(value: string) {
        this.cultureCode = value;
    }

    @State.bound
    private cancel() {
        this.props.onClose(null);
    }

    @State.bound
    private ok() {
        if (this.clientSideValidator.isValid()) {
            this.props.onClose({
                newTemplateInfo: {
                    id: this.props.currentInfo.id,
                    rowVersion: this.props.currentInfo.rowVersion,
                    contentId: this.props.currentInfo.contentId,
                    code: this.code,
                    name: this.name,
                    description: this.description,
                    documentTypeId: this.documentTypeId,
                    cultureCode: this.cultureCode,
                    isPrimary: this.props.currentInfo.isPrimary,
                    isDeleted: this.props.currentInfo.isDeleted
                }
            });
        } else {
            this.notificationService.showCannotSaveBecauseOfErrors();
        }
    }

    private getOptions() {
        return [
            { text: "hu-HU", value: "hu-HU" } as ISelectBoxItem<string>,
            { text: "en-US", value: "en-US" } as ISelectBoxItem<string>,
            { text: "sk-SK", value: "sk-SK" } as ISelectBoxItem<string>,
            { text: "cs-CZ", value: "cs-CZ" } as ISelectBoxItem<string>
        ];
    }

    private getCultureCodeOptionValue(item: ISelectBoxItem<string>) {
        if (!item.value) {
            return "hu-HU";
        }

        return item.value;
    }
}

export default connect(
    TemplateInfoDialog,
    new DependencyAdapter<ITemplateInfoDialogProps, ITemplateInfoDialogDependencies>(c => ({
        notificationService: c.resolve<INotificationService>("INotificationService")
    }))
);