import React from "react";
import Styles from "./Message.less";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import { ICommonControlProps, getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import { Tooltip } from "@Toolkit/ReactClient/Components/Tooltip";

interface IMessageProps extends ICommonControlProps {
    type: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" | "none";
}

export default class Message extends React.Component<IMessageProps> {

    public render() {
        const htmlProps = getStandardHtmlProps(this.props, this.getClassNameByType());
        return (
            <Tooltip
                content={this.props.tooltipContent}
                placement={this.props.tooltipPosition}
                contentAlignment={this.props.tooltipTextAlign}>
                <div {...htmlProps}>{this.props.children}</div>
            </Tooltip>
        );
    }

    private getClassNameByType() {
        const classes = new CompositeClassName(Styles.message);

        switch (this.props.type) {
            case "primary":
                classes.add(Styles.messagePrimary);
                break;
            case "secondary":
                classes.add(Styles.messageSecondary);
                break;
            case "success":
                classes.add(Styles.messageSuccess);
                break;
            case "danger":
                classes.add(Styles.messageDanger);
                break;
            case "warning":
                classes.add(Styles.messageWarning);
                break;
            case "info":
                classes.add(Styles.messageInfo);
                break;
            case "light":
                classes.add(Styles.messageLight);
                break;
            case "dark":
                classes.add(Styles.messageDark);
                break;
        }
        return classes.classNames;
    }
}