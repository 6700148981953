import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ITelecommunicationCallControlExtensionPointProps from "@PluginInterface/BoundedContexts/Telecommunication/ITelecommunicationCallControlExtensionPointProps";
import Button from "@CommonControls/Button";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import Call from "@HisPlatform/Packages/Care/Model/Call";
import StaticTelecommunicationResources from "@HisPlatform/BoundedContexts/Telecommunication/StaticResources/StaticTelecommunicationResources";
import AssecoRoomsCallContext from "@AssecoRoomsPlugin/AssecoRoomsCallContext";
import CallState from "@HisPlatform/BoundedContexts/Telecommunication/Api/Telecommunication/Enum/CallState.g";
import TelecommunicationApiAdapter from "@HisPlatform/Packages/Care/ApiAdapter/TelecommunicationApiAdapter";

interface ITelecommunicationCallControlExtensionDependencies {
    telecommunicationApiAdapter: TelecommunicationApiAdapter;
    assecoRoomsCallContext: AssecoRoomsCallContext;
}

interface ITelecommunicationCallControlExtensionProps extends ITelecommunicationCallControlExtensionPointProps {
    _dependencies?: ITelecommunicationCallControlExtensionDependencies;
}

@State.observer
class AssecoRoomsCallControlExtension extends React.Component<ITelecommunicationCallControlExtensionProps> {

    @State.observable.ref private call: Call = null;

    private get assecoRoomsCallContext() {
        return this.props._dependencies.assecoRoomsCallContext;
    }

    private get telecommunicationApiAdapter() {
        return this.props._dependencies.telecommunicationApiAdapter;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.bound
    private async loadAsync() {
        const call = await this.telecommunicationApiAdapter.tryGetCallByCareActivityIdAsync(this.props.careActivityId);
        this.setCall(call.result);
        await this.assecoRoomsCallContext.loadMicrophonePermissionAsync();
        await this.assecoRoomsCallContext.loadCameraPermissionAsync();
    }

    @State.action.bound
    private setCall(call: Call) {
        this.call = call;
    }

    @State.action.bound
    private async onStartCallAsync() {
        const startedCall = await this.telecommunicationApiAdapter.updateCallStateByIdAsync(this.call.id, CallState.Active, this.call.rowVersion);
        this.setCall(startedCall.result);
        this.assecoRoomsCallContext.showAssecoRooms(this.call.organizerIdentifier);
        await this.assecoRoomsCallContext.checkForPermissionsAsync();
    }

    @State.action.bound
    private async onResumeCallAsync() {
        this.assecoRoomsCallContext.showAssecoRooms(this.call.organizerIdentifier);
        await this.assecoRoomsCallContext.checkForPermissionsAsync();
    }

    @State.action.bound
    private async onCloseCallAsync() {
        const closedCall = await this.telecommunicationApiAdapter.updateCallStateByIdAsync(this.call.id, CallState.Closed, this.call.rowVersion);
        this.setCall(closedCall.result);
        this.assecoRoomsCallContext.hideAssecoRooms();
    }

    public render() {
        return !!this.call && (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: 8, padding: "4px 6px" }}>
                {
                    this.call.callState === CallState.NotStarted &&
                    <Button
                        visualStyle="positive"
                        size="compact"
                        text={StaticTelecommunicationResources.TelecommunicationCallControl.StartCallButton}
                        onClickAsync={this.onStartCallAsync}
                        automationId="startCallButton"
                    />
                }
                {
                    this.call.callState === CallState.Active && !this.assecoRoomsCallContext.assecoRoomsIsVisible &&
                    <Button
                        visualStyle="positive"
                        size="compact"
                        text={StaticTelecommunicationResources.TelecommunicationCallControl.ResumeCallButton}
                        onClickAsync={this.onResumeCallAsync}
                        automationId="resumeCallButton"
                    />
                }
                {
                    this.call.callState === CallState.Active &&
                    <Button
                        visualStyle="negative"
                        size="compact"
                        text={StaticTelecommunicationResources.TelecommunicationCallControl.CloseCallButton}
                        onClickAsync={this.onCloseCallAsync}
                        automationId="closeCallButton"
                    />
                }
            </div>
        );
    }
}

export default connect(
    AssecoRoomsCallControlExtension,
    new DependencyAdapter<ITelecommunicationCallControlExtensionProps, ITelecommunicationCallControlExtensionDependencies>(container => {
        return {
            telecommunicationApiAdapter: container.resolve("TelecommunicationApiAdapter"),
            assecoRoomsCallContext: container.resolve("AssecoRoomsCallContext")
        };
    })
);
