import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import EhiSyncStatus from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiSyncStatus";
import EhiSyncStatusLabel from "@HunEHealthInfrastructurePlugin/Common/Components/EhiSyncStatusLabel";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import EhiSyncStatusSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiSyncStatusSelectBox/EhiSyncStatusSelectBox";


interface IEhiSyncStatusColumnProps extends IDataGridColumnBaseProps {
}


const EhiSyncStatusColumn: React.FC<IEhiSyncStatusColumnProps> = (props: IEhiSyncStatusColumnProps) => {

    const valueRenderer = useCallback((value: EhiSyncStatus) => {
        return <EhiSyncStatusLabel value={value} />;
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<EhiSyncStatus | EhiSyncStatus[]>) => {
        return <EhiSyncStatusSelectBox {...filterProps} multiSelect maxMenuWidth={commonMenuMaxWidth} hoverOnlyIndicatorIcons />;
    }, []);
    
    return (
        <DataGridColumn
            width={undefined}
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            showHint={false}
        />
    );
};

export default EhiSyncStatusColumn;