import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import TimePhaseStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseStore";
import { ServiceRequestDefinitionSelectBox } from "@HisPlatform/BoundedContexts/Care/Components";
import TimePhaseType from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseType";
import PointOfCareSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareSelectBox";
import PractitionerId from "@Primitives/PractitionerId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import RecurringTimePhasePanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RecurringTimePhasePanel/RecurringTimePhasePanel";
import PractitionerCodeSelector from "@HisPlatformControls/PractitionerCodeSelector/PractitionerCodeSelector";
import _ from "@HisPlatform/Common/Lodash";
import AppointmentScheduleDefinition from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentScheduleDefinition";
import SingleTimePhasePanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/SingleTimePhasePanel";

interface ITimePhaseEditorDialogViewProps {
    timePhase: TimePhaseStore;
    possibleSchedulingServices: SchedulingServiceSubjectStore[];
    onClose: () => void;
    addAsync: () => Promise<void>;
    isAddDisabled?: boolean;
    pointOfCareIds: PointOfCareId[];
    setPointOfCareIds: (newValue: PointOfCareId[]) => void;
    practitionerIds: PractitionerId[];
    setPractitionerIds: (newValue: PractitionerId[]) => void;
    setTimePhaseInterval: (from: TimeOfDay, to: TimeOfDay, isFinal: boolean) => void;
    onValidateAsync: () => Promise<IClientValidationResult[]>;
    validationResults: IClientValidationResult[];
    pathPrefix?: string;
    isLoading?: boolean;
    isNew?: boolean;
    onSetTimePhaseType: (newTimePhaseType: TimePhaseType) => void;
}

const TimePhaseEditorDialogView: React.FC<ITimePhaseEditorDialogViewProps> = (props) => {
    const resources = StaticSchedulingResources.TimePhaseEditorDialog;

    const schedulingServicesSelectBoxItems = props.possibleSchedulingServices.map(s => {
        return {
            code: s.code,
            text: s.name,
            value: s.id
        } as ISelectBoxItem<SchedulingServiceId>;
    });

    const renderSchedulingService = (itemProps: any) => {
        return <><b>{itemProps.data.code}</b>{` ${itemProps.data.text}`}</>;
    };

    return (
        <Ui.Modal
            title={!!props.isNew ? resources.AddTimePhase : resources.EditTimePhase}
            isOpen
            onClose={props.onClose}
            size="compact"
            closeButton
            closeOnOutsideClick={false}
        >
            <ValidationBoundary
                entityTypeName={"AppointmentScheduleDefinition"}
                onValidateAsync={props.onValidateAsync}
                validationResults={props.validationResults}
                pathPrefix={props.pathPrefix}
                validateOnMount
            >
                <Ui.Modal.Body>
                    <Ui.GroupBox title={resources.TimePhase}>
                        <Ui.ValidationResultSummary
                            propertyPath={"TimePhases.Resources;TimePhases.Occurrence.StandaloneOccurrence.Interval;TimePhases;TimePhases.Subject"}
                            displayMode="list"
                            excludedRuleIds="ShouldHaveMaximumThreeElements,ShouldBeInPlanningPeriodRange"
                        />
                        <Ui.Flex>
                            <Ui.Flex.Item xs={3}>
                                <Ui.UniversalEnumSelector
                                    enumOrigin="client"
                                    displayMode="groupedRadioButtons"
                                    enumName={"TimePhaseType"}
                                    enumType={TimePhaseType}
                                    value={props.timePhase.type}
                                    onChange={props.onSetTimePhaseType}
                                    automationId="timePhaseSelector"
                                    disabled={!props.isNew}
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <ValidationBoundary
                            pathPrefix="TimePhases.Occurrence"
                            validateOnMount
                        >
                            {props.timePhase.type === TimePhaseType.Recurring &&
                                <RecurringTimePhasePanel
                                    interval={props.timePhase.interval}
                                    setTimePhaseInterval={props.setTimePhaseInterval}
                                    recurrenceElements={props.timePhase.recurrenceElements}
                                    onSetRecurrenceElements={props.timePhase.setRecurrenceElements}
                                />}
                            {props.timePhase.type === TimePhaseType.Single &&
                                <SingleTimePhasePanel
                                    date={props.timePhase.singleOccurrenceDate}
                                    onSetDate={props.timePhase.setSingleOccurrenceDate}
                                    interval={props.timePhase.interval}
                                    onSetTimePhaseInterval={props.setTimePhaseInterval}
                                />
                            }
                        </ValidationBoundary>
                    </Ui.GroupBox>
                    <Ui.GroupBox title={resources.TimeSlots.TimeSlots}>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={6}>
                                <Ui.NumberBox
                                    label={resources.TimeSlots.TimeSlotDuration}
                                    value={props.timePhase.slotSizeInMinutes}
                                    onChange={props.timePhase.setSlotSizeInMinutes}
                                    propertyIdentifier="TimePhases.SlotSizeInMinutes"
                                    automationId="slotSizeInMinutes"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={6}>
                                <Ui.NumberBox
                                    label={resources.TimeSlots.TimeSlotCount}
                                    isReadOnly
                                    value={props.timePhase.slotCount}
                                    automationId="slotCount"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.GroupBox>
                    <Ui.GroupBox title={resources.AppointmentSettings.AppointmentSettings}>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={6}>
                                <PointOfCareSelectBox
                                    propertyIdentifier="TimePhases.Resources.PointsOfCare"
                                    label={resources.AppointmentSettings.Workplaces}
                                    value={props.pointOfCareIds}
                                    onChange={props.setPointOfCareIds}
                                    multiSelect
                                    automationId="pointOfCaresSelectBox"
                                />
                            </Ui.Flex.Item>
                            {!props.isLoading ?
                                <Ui.Flex.Item xs={6} >
                                    <PractitionerCodeSelector
                                        propertyIdentifier="TimePhases.Resources.Practitioners"
                                        label={resources.AppointmentSettings.Practitioners}
                                        value={props.practitionerIds}
                                        onChange={props.setPractitionerIds}
                                        isExternal={false}
                                        multiSelect
                                        automationId="practitionersSelectBox"
                                    />
                                </Ui.Flex.Item> : <></>
                            }
                        </Ui.Flex>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={6}>
                                <Ui.SelectBox
                                    label={resources.AppointmentSettings.Services}
                                    value={props.timePhase.allowedSchedulingServices}
                                    onChange={props.timePhase.setAllowedSchedulingServices}
                                    items={schedulingServicesSelectBoxItems}
                                    customValueRenderer={renderSchedulingService}
                                    multiSelect
                                    automationId="schedulingServicesSelectBox"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={6}>
                                <ServiceRequestDefinitionSelectBox
                                    label={resources.AppointmentSettings.ServiceRequests}
                                    value={props.timePhase.allowedServiceRequestDefinitions}
                                    onChange={props.timePhase.setAllowedServiceRequestDefinitions}
                                    pointOfCareIdsFilter={props.pointOfCareIds}
                                    multiSelect
                                    isReadOnly={!props.timePhase.organizationUnitResources?.length}
                                    automationId="serviceRequestDefinitionsSelectBox"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={6}>
                                <Ui.NumberBox
                                    label={resources.AppointmentSettings.ParallelCapacity}
                                    value={props.timePhase.parallelCapacity}
                                    onChange={props.timePhase.setParallelCapacity}
                                    integerOnly
                                    propertyIdentifier="TimePhases.ParallelCapacity"
                                    automationId="parallelCapacity"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.GroupBox>
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Flex xsJustify="between" verticalSpacing="none" >
                        <Ui.Flex.Item>
                            <Ui.Button
                                text={resources.Cancel}
                                onClick={props.onClose}
                                visualStyle="link"
                                automationId="closeButton"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item>
                            <Ui.ValidationControlledButton
                                text={!!props.isNew ? resources.Add : resources.Modify}
                                visualStyle="primary"
                                onClickAsync={props.addAsync}
                                disabled={props.isAddDisabled}
                                automationId="saveButton"
                                propertyPath="*"
                                disableOnSeverity="error"
                                isLoading={props.isLoading}
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.Modal.Footer>
            </ValidationBoundary>
        </Ui.Modal>
    );
};

export default State.observer(TimePhaseEditorDialogView);
