import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Styles from "./PerformedServicesPanel.less";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import MedicalServiceCodeSelector from "@HisPlatformControls/MedicalServiceCodeSelector";
import PerformedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/PerformedServiceStore";
import LateralitySelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/LateralitySelectBox";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import IPerformedServiceFirstRowExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPerformedServiceFirstRowExtensionPointProps";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import CareTypeId from "@Primitives/CareTypeId.g";

interface IPerformedServicesNewRowProps {
    store: PerformedServiceStore;
    filteredIds?: MedicalServiceId[];
    showFinanceData: boolean;
    onAddAsync: () => Promise<void>;
    performedServiceFirstRowExtensionPointProps: IPerformedServiceFirstRowExtensionPointProps;
    pointOfCareId: PointOfCareId;
    readonly: boolean;
    hasInsurerOrganization: boolean;
}

@State.observer
export default class PerformedServicesNewRow extends React.Component<IPerformedServicesNewRowProps> {
    public render() {
        const { store, filteredIds } = this.props;

        return (
            <tr className={Styles.firstRow}>
                <th />
                <th>
                    <MedicalServiceCodeSelector
                        value={store.selectedIds}
                        multiSelect
                        onChange={store.setSelectedIds}
                        filteredIds={filteredIds}
                        automationId="__medicalService"
                        showFavoritesAndGroup
                        pointOfCareId={this.props.pointOfCareId}
                        disabled={this.props.readonly}
                        careTypeId={CareTypeId.Outpatient}
                    />
                </th>
                <th>
                    <LateralitySelectBox value={store.lateralityId}
                        onChange={store.setLaterality}
                        automationId="__laterality"
                        disabled={this.props.readonly}
                    />
                </th>
                <th>
                    <Ui.NumberBox value={store.medicalServiceQuantity}
                        onChange={store.setMedicalServiceQuantity}
                        integerOnly={true}
                        automationId="__quantity"
                        disabled={this.props.readonly}
                    />
                </th>
                <th>
                    <Ui.DateTimePicker value={store.performedAt}
                        onChange={store.setPerformedAt}
                        automationId="__performedAt"
                        disabled={this.props.readonly}
                        clearable={false}
                    />
                </th>
                {this.props.hasInsurerOrganization && <th>
                    <HisPlatformExtensionPoint
                        extensionProps={this.props.performedServiceFirstRowExtensionPointProps}
                        type="performedServiceFirstRow"
                    >
                        <></>
                    </HisPlatformExtensionPoint>
                </th>}
                {this.props.showFinanceData && <>
                    <th>
                        <Ui.TextBox value="" disabled automationId="financedDataTextBox1" />
                    </th>
                    <th>
                        <Ui.TextBox value="" disabled automationId="financedDataTextBox2" />
                    </th>
                    <th>
                        <Ui.TextBox value="" disabled automationId="financedDataTextBox3" />
                    </th>
                </>}
                <th>
                    <Ui.Button
                        onClickAsync={this.props.onAddAsync}
                        iconName="check"
                        size="compact"
                        float="right"
                        visualMode="inverted"
                        automationId="__addNewService"
                        className={Styles.marginRight}
                        disabled={this.props.readonly}
                    />
                </th>
            </tr>
        );
    }
}
