import LoadingStateStore from "./LoadingStateStore";
import State, { PromisedComputedValue } from "@Toolkit/ReactClient/Common/StateManaging";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import LoadingContextStore from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingContextStore";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";

export type ErrorDispatcherFunction = (err: Error) => void;

export default abstract class PanelStoreBase<TProps = unknown> {
    private readonly _loadingState = new LoadingStateStore();
    private readonly _disposers: IDisposable[] = [];

    private _dispatchError: ErrorDispatcherFunction = (err: Error) => {
        throw err; // TODO: GlobalErrorDispatcher?
    };

    protected modalService: IModalService;
    protected actionDispatcher: IActionDispatcher;

    public readonly props: TProps = State.observable.object<TProps>({} as any);

    public get loadingState() {
        return this._loadingState;
    }

    public get isLoading() {
        return this.loadingState?.isLoading;
    }

    protected promisedComputed<T>(init: T, computeAsync: () => Promise<T>): PromisedComputedValue<T> {
        const isLoading = State.observable.box(false, { name: `unknownPromisedComputed.isLoading` });
        this._loadingState.add(isLoading);
        const ret = State.promisedComputed(init, computeAsync);
        State.reaction(() => ret.busy, isBusy => isLoading.set(isBusy));
        return ret;
    }

    // Do not use with mobx PromisedComputed use our PromisedComputed instead
    protected namedAsync<TParams extends any[], TResult>(name: string, asyncAction: (...args: TParams) => Promise<TResult>, propagateToStoreLoadingState: boolean = true) {
        const isLoading = State.observable.box(false, { name: `${name}.isLoading` });

        const funcAsync = async (...args: TParams) => {
            try {
                State.runInAction(() => {
                    isLoading.set(true);
                });

                return await asyncAction(...args);

            } finally {
                State.runInAction(() => {
                    isLoading.set(false);
                });
            }
        };

        funcAsync.fireAndForget = (...args: TParams) => {
            funcAsync(...args).catch(this._dispatchError);
        };

        funcAsync.isLoading = isLoading;

        if (propagateToStoreLoadingState) {
            this._loadingState.add(isLoading);
        }

        return funcAsync;
    }

    protected async<TParams extends any[], TResult>(asyncAction: (...args: TParams) => Promise<TResult>) {
        return this.namedAsync("unknown", asyncAction, true);
    }

    protected backgroundAsync<TParams extends any[], TResult>(asyncAction: (...args: TParams) => Promise<TResult>) {
        return this.namedAsync("unknown", asyncAction, false);
    }

    public unload() {
        this._loadingState.cleanup();
        this._disposers.forEach(disposer => {
            try {
                disposer.dispose();
            } catch {
                // black hole
            }
        });
    }

    public setContextualServices(
        errorDispatcher: ErrorDispatcherFunction,
        loadingBoundaryStore: LoadingContextStore,
        modalService: IModalService,
        actionDispatcher: IActionDispatcher
    ) {
        this._dispatchError = errorDispatcher;
        this._loadingState.setLoadingBoundaryStore(loadingBoundaryStore);
        this.modalService = modalService;
        this.actionDispatcher = actionDispatcher;
    }

    public disposeOnUnload(disposer: IDisposable | (() => void)) {
        this._disposers.push(typeof (disposer) === "function" ? {
            dispose: disposer
        } : disposer);
    }

    public reaction<T>(expression: () => T, effect: (expressionResult: T) => void, fireImmediately?: boolean) {
        this.disposeOnUnload(State.reaction(() => expression(), r => effect(r), { fireImmediately }));
    }
}
