import { ILargeMenuItem, ILargeMenuItemGroup } from "@CommonControls/LargeMenu/LargeMenu";
import Di from "@Di";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import IWorklistAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistAction";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { ICareActivityMenuProps } from "./CareActivityMenu";
import _ from "@HisPlatform/Common/Lodash";
import UseCaseNavigationAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/UseCaseNavigationAction";
import NavigateToUseCaseArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/NavigateToUseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import IShowScreenActionCallContextParams from "@HisPlatform/Services/Definition/ActionProcessing/IShowScreenActionCallContextParams";
import Log from "@HisPlatform/Services/Definition/Logger/Log";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import INDataAction from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataAction";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import ExecuteClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ExecuteClientSideAction";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";

@Di.injectable()
export default class CareActivityMenuStore extends PanelStoreBase<ICareActivityMenuProps> {

    public readonly pointOfCareShorthand = this.promisedComputed<string>("", async () => {
        if (this.props.pointOfCareId) {
            const pointOfCare = await this.organizationReferenceDataStore.allPointsOfCareMap.getOrLoadAsync(this.props.pointOfCareId);
            return pointOfCare?.shorthand ?? "";
        }
        return "";
    });

    @State.computed public get doctor() {
        return this.props.primaryParticipant ? this.organizationReferenceDataStore.doctorMap.get(this.props.primaryParticipant) : null;
    }

    @State.computed public get wentUnderCareAt() { return this.props.wentUnderCareAt && this.localizationService.localizeDateTime(this.props.wentUnderCareAt); }
    @State.computed public get dischargedAt() { return this.props.dischargedAt && this.localizationService.localizeDateTime(this.props.dischargedAt); }

    @State.computed public get patientName() { return this.props.patientName && this.localizationService.localizePersonName(this.props.patientName.name); }
    @State.computed public get patientGenderShorthand() { return this.props.patientName && this.commonReferenceDataStore.gender.get(this.props.patientName.genderId)?.displayValue.Shorthand; }
    @State.computed public get patientBirthDate() { return this.props.patientBirthDate && this.localizationService.localizeDate(this.props.patientBirthDate); }

    public readonly standaloneActions = this.promisedComputed<ILargeMenuItemGroup[]>([], async () => {
        if (!isNullOrUndefined(this.props.careActivityId)) {
            const actions = await this.worklistApiAdapter.getCareActivityActionsAsync(this.props.careActivityId);
            return this.mapActionsToLargeMenuGroups(actions.value);
        }
        return [];
    });

    @State.computed
    public get actions() {
        if (this.props.actions) {
            return this.mapActionsToLargeMenuGroups(this.props.actions);
        }

        return this.standaloneActions.get();
    }

    @State.bound
    private resolveTitle(actionItem: IWorklistAction): string {

        if (isNullOrUndefined(actionItem.titleResourceId)) {
            return actionItem.title;
        }

        if (!isNullOrUndefined(actionItem.title) && actionItem.title !== "") {
            return actionItem.title;
        }

        return this.localizationService.localizeReferenceDataWithDefault(actionItem.titleResourceId, actionItem.titleResourceId.value);
    }

    constructor(
        @Di.inject("OrganizationReferenceDataStore") private readonly organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("ILocalizationService") private readonly localizationService: ILocalizationService,
        @Di.inject("WorklistApiAdapter") private readonly worklistApiAdapter: WorklistApiAdapter,
        @Di.inject("IUseCaseRegistry") private readonly useCaseRegistry: IUseCaseRegistry,
        @Di.inject("GlobalRoutingStore") private readonly globalRoutingStore: GlobalRoutingStore,
        @Di.inject("IActivityRegistry") private readonly activityRegistry: IActivityRegistry,
        @Di.inject("CommonReferenceDataDataStore") private readonly commonReferenceDataStore: CommonReferenceDataDataStore,
    ) {
        super();
    }

    private mapActionsToLargeMenuGroups(actions: INDataAction[] | IWorklistAction[]) {
        const orderedActions = _.orderBy(actions, [i => i.groupId]);
        const groupedOrderedActions = _.groupBy<IWorklistAction | INDataAction>(orderedActions, i => i.groupId);

        return Object.keys(groupedOrderedActions).map((key) => {
            const currentGroup = groupedOrderedActions[key];
            const orderedActionsInGroup = _.orderBy<IWorklistAction | INDataAction>(currentGroup, [i => i.order]);

            const menuItemGroup: ILargeMenuItemGroup = {
                key: orderedActionsInGroup[0].groupId,
                icon: this.activityRegistry?.getByReference(`CareActivityMenuGroup_${orderedActionsInGroup[0].groupId}`)?.iconName ?? "close_x",
                title: this.localizationService.localizeReferenceDataWithDefault(orderedActionsInGroup[0].groupTitleResourceId, orderedActionsInGroup[0].groupTitleResourceId?.value ?? "N/A"),
                items: orderedActionsInGroup.map<ILargeMenuItem>(a => ({
                    icon: this.activityRegistry?.getByReference(a.activityReference)?.iconName ?? "close_x",
                    title: this.resolveTitle(a),
                    payload: a,
                    key: a.commandToken,
                    automationId: a.activityReference
                }))
            };

            return menuItemGroup;
        });
    }

    @State.bound
    public selectItem(item: ILargeMenuItem) {
        this.processActionAsync.fireAndForget(item.payload as IWorklistAction);
        this.props.onClose?.();
    }

    private readonly dispatchActionAsync = this.async(async (a: FrontendActionBase) => {
        await this.actionDispatcher!.dispatchAsync<IShowScreenActionCallContextParams>(a);
    });

    private navigateToUseCase(useCase: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) {

        const converter = this.useCaseRegistry.tryGetScreenAdapter(useCase);

        if (converter) {
            const action = converter(useCase, useCaseArguments);
            this.dispatchActionAsync.fireAndForget(action.action);
            return;
        }

        // ---

        const useCaseDescriptor = this.useCaseRegistry.get(useCase.value);

        if (!useCaseDescriptor) {
            throw new Error(`Cannot find use case: ${useCase.value}`);
        }

        const routeFactory = useCaseDescriptor.standaloneRouteFactory;
        const route = routeFactory({
            currentRoute: this.globalRoutingStore.currentRoute,
            useCase: useCase,
            useCaseArguments: useCaseArguments,
            displayMode: UseCaseDisplayMode.Full
        });

        this.globalRoutingStore.push(route);
    }

    private readonly processActionAsync = this.backgroundAsync(async (action: IWorklistAction) => {
        try {

            if (action.clientSideAction) {
                await this.performClientSideActionAsync(action.clientSideAction, action.commandToken);
            } else {
                const argument = new NavigateToUseCaseArgument(UseCaseDisplayMode.Full);
                await this.processActionTokenAsync(action.commandToken, argument);
            }

            this.props.onActionProcessed?.();

        } catch (error) {
            Log.error(error.message);
        }
    });

    private async processActionTokenAsync(token: string, argument?: WorkListArgument) {
        const actionResult = (await this.worklistApiAdapter.executeActionTokenAsync(token, argument)).value;
        if (actionResult instanceof UseCaseNavigationAction) {
            this.navigateToUseCase(actionResult.useCase, actionResult.useCaseArguments);
            return;
        } else if (actionResult instanceof ExecuteClientSideAction) {
            await this.performClientSideActionAsync(actionResult.action, token);
        }
    }

    private async performClientSideActionAsync(clientSideAction: ClientSideActionDto, token: string) {
        if (!!this.props.onPerformClientSideActionAsync) {
            const clientSideResult = await this.props.onPerformClientSideActionAsync(clientSideAction);
            if (!isNullOrUndefined(clientSideResult)) {
                await this.processActionTokenAsync(token, clientSideResult);
            }
        } else {
            Log.warn("Cannot perform client side action, because onPerformClientSideActionAsync is not specified.");
        }
    }
}