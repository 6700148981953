import React from "react";
import EuReportPanelView from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/EuReportPanel/EuReportPanelView";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IEuReportFilter } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/EuReportPanel/IEuReportFilter";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import DocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/DocumentApiAdapter";
import EuPerformanceStatementApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/EuPerformanceStatementApiAdapter";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import HunSocialSecurityUseCases from "@HunSocialSecurityPlugin/UseCases/HunSocialSecurityUseCases";
import StaticHunSocialSecurityPerformanceStatementResources from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/StaticResources/StaticHunSocialSecurityPerformanceStatementResources";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";

interface IEuReportPanelDependencies {
    euPerformanceStatementApiAdapter: EuPerformanceStatementApiAdapter;
    documentApiAdapter: DocumentApiAdapter;
    fileSaverService: IFileSaverService;
    notificationService: INotificationService;
    routingStore: GlobalRoutingStore;
    applicationContext: ApplicationContext;
    dialogService: IDialogService;
}

export interface IEuReportPanelProps {
    _dependencies?: IEuReportPanelDependencies;
}

/** @screen */
@State.observer
class EuReportPanel extends React.Component<IEuReportPanelProps> {
    private resources = StaticHunSocialSecurityPerformanceStatementResources.EuReportPanel;

    private get euPerformanceStatementApiAdapter() { return this.props._dependencies.euPerformanceStatementApiAdapter; }
    private get documentApiAdapter() { return this.props._dependencies.documentApiAdapter; }
    private get fileSaverService() { return this.props._dependencies.fileSaverService; }
    private get notificationService() { return this.props._dependencies.notificationService; }

    private readonly currentUseCaseIdentifier = new UseCaseIdentifier(HunSocialSecurityUseCases.newEuPerformanceStatement);

    @State.observable.ref private reportValidityDate: YearMonth = null;
    @State.observable.ref private pointOfCares: PointOfCareId[] = [];
    @State.observable private containsOnlyClosedCareActivities: boolean = false;

    public componentDidMount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(this.currentUseCaseIdentifier);
    }

    public componentWillUnmount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(null);
    }

    @State.action.bound
    private onReportValidityDateChange(newValue: YearMonth) {
        this.reportValidityDate = newValue;
    }

    @State.action.bound
    public onPointOfCareChange(newValue: PointOfCareId, checkedValue: boolean) {
        if (this.pointOfCares) {
            if (checkedValue === false) {
                this.pointOfCares = this.pointOfCares.filter(x => x.value !== newValue.value);
            } else if (!this.pointOfCares.some(x => x.value === newValue.value)) {
                this.pointOfCares = [...this.pointOfCares, newValue];
            }
        }
    }

    @State.action.bound
    private onContainsOnlyClosedCareActivitiesChange(newValue: boolean) {
        this.containsOnlyClosedCareActivities = newValue;
    }

    @State.action.bound
    private async onCreateAsync() {
        const filter = {
            organizationUnitIds: this.pointOfCares.map(x => new OrganizationUnitId(x.value)),
            reportValidityDate: this.reportValidityDate,
            containsOnlyClosedCareActivities: this.containsOnlyClosedCareActivities
        } as IEuReportFilter;

        if (!this.isInvalid(filter)) {
            const result = await this.euPerformanceStatementApiAdapter.hasFinalizedEuPerformanceStatementForMonthAsync(filter);
            
            if (result.value) {
                const createEuPerformanceStatementAnswer = await this.props._dependencies.dialogService.yesNo(
                    this.resources.CreateConfirmationDialog.ConfirmationTitle,
                    this.resources.CreateConfirmationDialog.ConfirmationMessage);
                if (createEuPerformanceStatementAnswer.resultCode === DialogResultCode.Yes) {
                    await this.euPerformanceStatementApiAdapter.createEuPerformanceStatement(filter);
                    this.onBack();
                }
            } else {
                await this.euPerformanceStatementApiAdapter.createEuPerformanceStatement(filter);
                this.onBack();
            }
        }
    }

    @State.action.bound
    private onBack() {
        this.props._dependencies.routingStore.goBack();
    }

    // TODO: remove this, and move logic to metadata service
    @State.action
    private isInvalid(filters: IEuReportFilter): boolean {
        let i = 0;

        if (filters.organizationUnitIds.length === 0) {
            this.notificationService.error(this.resources.ErrorMessages.OrganizationUnitIdsShouldBeFilled);
            i++;
        }

        if (isNullOrUndefined(filters.reportValidityDate)) {
            this.notificationService.error(this.resources.ErrorMessages.ReportValidityDateShouldBeFilled);
            i++;
        }

        return i > 0;
    }

    public render() {
        return (
            <EuReportPanelView
                pointOfCares={this.pointOfCares}
                onPointOfCareChange={this.onPointOfCareChange}
                reportValidityDate={this.reportValidityDate}
                onReportValidityDateChange={this.onReportValidityDateChange}
                containsOnlyClosedCareActivities={this.containsOnlyClosedCareActivities}
                onContainsOnlyClosedCareActivitiesChange={this.onContainsOnlyClosedCareActivitiesChange}
                onCreateAsync={this.onCreateAsync}
                onBack={this.onBack}
            />
        );
    }
}

export default connect(
    EuReportPanel,
    new DependencyAdapter<IEuReportPanelProps, IEuReportPanelDependencies>((c) => {
        return {
            euPerformanceStatementApiAdapter: c.resolve("EuPerformanceStatementApiAdapter"),
            fileSaverService: c.resolve("IFileSaverService"),
            notificationService: c.resolve("INotificationService"),
            documentApiAdapter: c.resolve("DocumentApiAdapter"),
            routingStore: c.resolve("GlobalRoutingStore"),
            applicationContext: c.resolve("ApplicationContext"),
            dialogService: c.resolve("IDialogService"),
        };
    })
);