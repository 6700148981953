import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import CareContactType from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareContactType.g";
import React from "react";
import {UniversalEnumSelector} from "@CommonControls";

interface ICareContactTypeSelectBoxProps extends ISelectBoxBaseProps {
    value?: CareContactType;
    onChange?: (newValue: CareContactType) => void;
}

const CareContactTypeSelectBox: React.FC<ICareContactTypeSelectBoxProps> = (props: ICareContactTypeSelectBoxProps) => {
    return (
        <UniversalEnumSelector
            {...props}
            enumName={"CareContactType"}
            enumOrigin={"server"}
            enumType={CareContactType}
            displayMode={"selectBox"}
        />
    );
};

export default CareContactTypeSelectBox;