import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IAuthenticationService from "@HisPlatform/Services/Definition/Authentication/IAuthenticationService";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import NotificationClient from "./NotificationClient";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";

interface INotificationHandlerDependencies {
    authenticationService: IAuthenticationService;
}

interface INotificationHandlerProps {
    _dependencies?: INotificationHandlerDependencies;
    groupId: string;
    onReceiveMessage: (message: any) => void;
}

@State.observer
class NotificationHandler extends React.Component<INotificationHandlerProps> {

    private client: NotificationClient;

    constructor(props: INotificationHandlerProps) {
        super(props);
    }

    public componentDidMount() {
        if (!this.props.groupId) {
            throw new Error("GroupId is required for notificationHandlers!");
        }
        this.initClient();
    }

    @State.bound
    private initClient() {
        this.client = new NotificationClient(this.props.groupId, this.props.onReceiveMessage);
        this.client.buildConnection(this.props._dependencies.authenticationService);
        dispatchAsyncErrors(this.client.startAndJoinGroupAsync(), this);
    }

    public componentDidUpdate(prevProps: INotificationHandlerProps) {
        if (this.props.groupId !== prevProps.groupId || this.props.onReceiveMessage !== prevProps.onReceiveMessage) {
            this.client.disconnect();
            this.initClient();
        }
    }

    public componentWillUnmount() {
        this.client.disconnect();
    }

    public render() {
        return <></>;
    }
}

export default withHisErrorBoundary(connect(
    NotificationHandler,
    new DependencyAdapter<INotificationHandlerProps, INotificationHandlerDependencies>(c => ({
        authenticationService: c.resolve("IAuthenticationService")
    }))
));