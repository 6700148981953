import BusinessErrorBase from "@HisPlatform/Model/DomainModel/BusinessError/BusinessErrorBase";

export default class UnauthorizedDataAccessError extends BusinessErrorBase {

    public static businessErrorName = "UnauthorizedDataAccessError";
    public get name(): string { return UnauthorizedDataAccessError.businessErrorName; }

    constructor(
        public readonly entityId: string,
        public readonly entityType: string
    ) { 
        super();
    }
}