import React, { LegacyRef, useCallback } from "react";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import EditableLayoutCustomBlockStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableLayoutCustomBlockStore";
import IEditableLayoutStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/IEditableLayoutStore";
import Styles from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditor.less";
import Icon from "@CommonControls/Icon";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";

interface IEditableLayoutCustomBlockProps {
    block: EditableLayoutCustomBlockStore;
    parentCollection: IObservableArray<IEditableLayoutStore>;
    opacity: number;
}

function EditableLayoutCustomBlock(props: IEditableLayoutCustomBlockProps, ref: LegacyRef<HTMLDivElement>) {

    const store = useFormLayoutEditorStore();
    const removeGroup = useCallback(() => {
        store.removeBlock(props.parentCollection, props.block);
    }, [props.parentCollection, props.block]);

    const click = useCallback((e: React.MouseEvent) => {
        store.selectCustomBlock(props.block);
        e.stopPropagation();
    }, [props.block]);

    return (
        <div ref={ref} className={combineClasses(Styles.elementCustomBlock, store.selectedCustomBlock === props.block && Styles.selected)} style={{ opacity: props.opacity, cursor: "pointer" }} onClick={click}>
            <div className={Styles.content}>
                {props.block.customBlockIdentifier}
            </div>
            <div className={Styles.toolbar} style={{ visibility: store.areDropZonesHighlighted ? "hidden" : "visible" }}>
                <button onClick={removeGroup}>x</button>
                <Icon iconName="bars" />
            </div>
        </div>
    );
}

const _EditableLayoutCustomBlock = React.forwardRef(EditableLayoutCustomBlock);
export default State.observer(_EditableLayoutCustomBlock);