import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React from "react";
import ShowEhrDischargePatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEhrDischargePatientScreenAction.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ScreenRenderer from "@HisPlatform/Components/ShowScreenAction/ScreenRenderer";
import IPatientCareActivitiesTabProps from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabProps";

function WrappedEhrDischargePatientScreen(props: IPatientCareActivitiesTabProps) {

    const action =
        new ShowEhrDischargePatientScreenAction(
            ScreenDisplayMode.ContentOnly,
            props.careActivityId);

    return (
        <ScreenRenderer
            action={action}
            screenState={null}
            onClose={null}
        />
    );
}

export default connect<IPatientCareActivitiesTabProps>(
    WrappedEhrDischargePatientScreen
);