import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import FormEnumStore from "@Toolkit/FormEngine/Model/Schema/FormEnumStore";
import EditableDataElementEditorStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableDataElementEditorStore";
import { INamedFormEnum } from "@Toolkit/FormEngine/Model/IFormEnumsInDefinition";
import EnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/EnumFormDataElement";
import FormEnumReference from "@Toolkit/FormEngine/Model/Schema/FormEnumReference";

export interface IEnumReferenceEditorDialogParams extends IModalParams {
    readonly formDefinitionId: FormDefinitionId;
    readonly localEnums: INamedFormEnum[];
    readonly enumFormDataElement: EnumFormDataElement;
    readonly editor: EditableDataElementEditorStore;
}

export interface IEnumReferenceEditorDialogResult {
    formEnum: FormEnumStore;
    enumReference: FormEnumReference;
    activeValues: number[] | null;
    wasMyEnum: boolean;
    isMyEnum: boolean;
}

export default class EnumReferenceEditorDialogParams implements IEnumReferenceEditorDialogParams {

    public static type = "EnumReferenceEditorDialog";
    public get type() { return EnumReferenceEditorDialogParams.type; }

    constructor(
        public readonly enumFormDataElement: EnumFormDataElement,
        public readonly editor: EditableDataElementEditorStore,
        public readonly localEnums: INamedFormEnum[],
        public readonly formDefinitionId: FormDefinitionId
    ) {}

}