import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";

export default function getFormInstanceBasedUseCaseArguments(args: Array<UseCaseArgument<any>>, readonly: boolean) {
    const map = new Map(args.map(a => [a.name, a.value]));
    const id = map.get("formInstanceId") ?? new FormInstanceId("new");
    return {
        selectedId: id,
        readonly: readonly
    };
}