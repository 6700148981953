import FormSchemaId from "@Toolkit/FormEngine/Model/Primitives/FormSchemaId.g";

export default class FormSchemaReference {
    constructor(
        public readonly formSchemaId: FormSchemaId,
        public readonly versionNumber: number
    ) {}

    public toString() {
        return `FormSchemaReference:${this.formSchemaId?.value}:${this.versionNumber}`;
    }

    public toJSON() {
        return {
            FormSchemaId: this.formSchemaId,
            VersionNumber: this.versionNumber
        };
    }

    public static fromJs(raw: any) {
        return new FormSchemaReference(raw.FormSchemaId, raw.VersionNumber);
    }
}