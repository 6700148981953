
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import TelemedicineScreenStore from "./TelemedicineScreenStore";
import { editorScreenRenderFunc } from "@Toolkit/CommonWeb/PanelStore/EditorScreenRenderFunc";
import { ITelemedicineScreenProps } from "./TelemedicineScreen";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ShowEditPatientAdmissionDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEditPatientAdmissionDataScreenAction.g";
import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";

const {
    ContextComponent: TelemedicineScreenStoreContext,
    StoreProvider: TelemedicineScreenStoreProvider,
    useStore: useTelemedicineScreenStore,
    provideStore: provideTelemedicineScreenStore,
} = createPanelStoreProvider<TelemedicineScreenStore>(TelemedicineScreenStore, (props: ITelemedicineScreenProps) => ({
    unauthorizedPageTitle: (props.action instanceof ShowNewAdmitPatientScreenAction || props.action instanceof ShowEditPatientAdmissionDataScreenAction) ?
        StaticCareResources.PatientAdmission.Titles.Admission :
        StaticWebAppResources.OutpatientWorkflow.CareActivityBaseDataStep.Title
}), editorScreenRenderFunc);

export {
    TelemedicineScreenStoreContext,
    TelemedicineScreenStoreProvider,
    useTelemedicineScreenStore,
    provideTelemedicineScreenStore
};