import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import {IDataGridColumnBaseProps} from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import React from "react";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import {combineClasses} from "@Toolkit/ReactClient/Common/CompositeClassName";
import Styles from "@HisPlatformControls/DataGridColumns/UrgencyColumn.less";
import Badge, {IBadgeProps} from "@CommonControls/Badge";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import Urgency from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/Urgency.g";

interface IUrgencyBadgeDependencies {
    localizationService: ILocalizationService;
}

interface IUrgencyBadgeProps extends IDataGridColumnBaseProps {
    _dependencies?: IUrgencyBadgeDependencies;
    value: Urgency;
}


const UrgencyBadge: React.FC<IUrgencyBadgeProps> = props => {
    const localized = isNullOrUndefined(props.value) ? null : props._dependencies.localizationService.localizeEnum(Urgency[props.value], "Urgency");

    if (isNullOrUndefined(localized)) {
        return null;
    }

    const className = getClassNameByUrgencyValue(props.value);

    const labelProps = {
        fluid: true,
        shape: "rounded-rect",
        upperCase: false,
        text: localized.Shorthand,
        className: combineClasses(Styles.main, className),
        tooltipPosition: "bottom",
        tooltipContent: localized.Name,
        size: "small"
    } as IBadgeProps;

    return <Badge {...labelProps} />;
};

const getClassNameByUrgencyValue = (value: Urgency) => {
    switch (value) {
        case Urgency.Urgent:
            return Styles.urgent;
        case Urgency.Emergency:
            return Styles.emergency;
        case Urgency.Normal:
        default:
            return Styles.normal;
    }
};

export default connect(
    UrgencyBadge,
    new DependencyAdapter<IUrgencyBadgeProps, IUrgencyBadgeDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
