import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import * as Ui from "@CommonControls";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import OrganizationUnit from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnit";

interface IInstituteSelectBoxDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IInstituteSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IInstituteSelectBoxDependencies;
    explicitIds?: OrganizationUnitId[];
    defaultId?: OrganizationUnitId;
    shouldSelectOnlyItem?: boolean;
    shouldDefaultToFirstItem?: boolean;
}

@State.observer
class InstituteSelectBox extends React.Component<IInstituteSelectBoxProps> {

    private get referenceDataStore() {
        return this.props._dependencies.organizationReferenceDataStore;
    }

    @State.observable.ref private items: Array<ISelectBoxItem<OrganizationUnitId>> = null;

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IInstituteSelectBoxProps) {
        if (prevProps.explicitIds !== this.props.explicitIds || prevProps.shouldSelectOnlyItem !== this.props.shouldSelectOnlyItem) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    private async loadAsync() {
        if (!isNullOrUndefined(this.props.explicitIds)) {
            await this.referenceDataStore.organizationUnitMap.ensureLoadedAsync(this.props.explicitIds);
            await this.referenceDataStore.organizationUnitDefinitionMap.ensureLoadedAsync(this.referenceDataStore.organizationUnitMap.items.map(o => o.organizationUnitDefinitionId));
            this.initializeExplicitItems();
        } else {
            await this.referenceDataStore.organizationUnitMap.ensureAllLoadedAsync();
            await this.referenceDataStore.organizationUnitDefinitionMap.ensureLoadedAsync(this.referenceDataStore.organizationUnitMap.items.map(o => o.organizationUnitDefinitionId));
            this.initializeAllItems();
        }
    }

    @State.action
    private initializeExplicitItems() {
        this.items = this.referenceDataStore.organizationUnitMap.items
            .filter(i => this.props.explicitIds.some(s => ValueWrapper.equals(s, i.id)))
            .filter(i => this.referenceDataStore.organizationUnitDefinitionMap.get(i.organizationUnitDefinitionId).code === "Institute")
            .map(this.toSelectBoxItem);
        this.selectItem();
    }

    @State.action
    private initializeAllItems() {
        this.items = this.referenceDataStore.organizationUnitMap.items
            .filter(i => this.referenceDataStore.organizationUnitDefinitionMap.get(i.organizationUnitDefinitionId).code === "Institute")
            .map(this.toSelectBoxItem);
        this.selectItem();
    }

    private selectItem() {
        if (!isNullOrUndefined(this.props.defaultId)) {
            this.props.onChange(this.props.defaultId); 
        } else if ((this.props.shouldSelectOnlyItem && this.items.length === 1) || (!!this.props.shouldDefaultToFirstItem && this.items.length >= 1)) {
            this.props.onChange(this.items[0].value);
        }
    }

    @State.bound
    private toSelectBoxItem(item: OrganizationUnit) {
        return {
            text: item.baseData.naturalIdentifier.shortName,
            value: item.id
        } as ISelectBoxItem<OrganizationUnitId>;
    }
    public render() {
        return (
            <Ui.SelectBox
                {...this.props}
                loading={!this.items}
                items={this.items}
            />
        );
    }
}

export default connect(
    InstituteSelectBox,
    new DependencyAdapter<IInstituteSelectBoxProps, IInstituteSelectBoxDependencies>(container => {
        return {
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore")
        };
    })
);
