import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import MedicationSubsidyOptionId from "@Primitives/MedicationSubsidyOptionId.g";
import _ from "@HisPlatform/Common/Lodash";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import MedicationSubsidyId from "@Primitives/MedicationSubsidyId.g";
import PractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/PractitionerRecommendation";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

interface IMedicationSubsidyOptionColumnDependencies {
    medicationRequestReferenceDataStore: MedicationRequestReferenceDataStore;
}

interface IMedicationSubsidyOptionColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IMedicationSubsidyOptionColumnDependencies;
}


const MedicationSubsidyOptionColumn: React.FC<IMedicationSubsidyOptionColumnProps> = props => {
    const valueRenderer = useCallback((value: { subsidyId: MedicationSubsidyId, optionId: MedicationSubsidyOptionId }, row: PractitionerRecommendation) => {
        const subsidy = isNullOrUndefined(value?.subsidyId) ? null : props._dependencies.medicationRequestReferenceDataStore.medicationSubsidies.get({ id: value.subsidyId, validOn: row?.recordedOn || LocalDate.today() });
        const localized = isNullOrUndefined(value?.optionId) ? null : subsidy?.options.find(i => ValueWrapper.equals(i.id, value.optionId));
        return isNullOrUndefined(localized) ? null : localized.code;
    }, []);

    const filterRenderer = useCallback(() => {
        return null;
    }, []);

    return (
        <DataGridColumn
            {...props as IDataGridColumnBaseProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
        />
    );
};

export default connect(
    MedicationSubsidyOptionColumn,
    new DependencyAdapter<IMedicationSubsidyOptionColumnProps, IMedicationSubsidyOptionColumnDependencies>(c => ({
        medicationRequestReferenceDataStore: c.resolve("MedicationRequestReferenceDataStore"),
    }))
);