import * as signalR from "@microsoft/signalr";
import config from "@Config";
import Log from "@Log";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IAuthenticationService from "@HisPlatform/Services/Definition/Authentication/IAuthenticationService";
import { joinUrls } from "@Toolkit/CommonWeb/Extensions/UrlExtensions";

export default class NotificationClient {

    private connection: signalR.HubConnection;

    constructor(
        private groupId: string,
        private receiveMessage: (message: any) => void
    ) { }

    @State.bound
    public buildConnection(authenticationService: IAuthenticationService) {

        if (!config.notificationServiceEnabled || config.notificationServiceEnabled?.toString()?.toLowerCase() === "false") {
            Log.debug("NotificationService is disabled.");
            return;
        }

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(joinUrls(config.serverAddress, "/clientNotifications"), {
                accessTokenFactory: () => authenticationService.getCurrentToken(),
                logger: {
                    log: (logLevel, message) => {
                        switch (logLevel) {
                            case signalR.LogLevel.Critical:
                                Log.fatal(message);
                                break;
                            case signalR.LogLevel.Error:
                                Log.error(message);
                                break;
                            case signalR.LogLevel.Warning:
                                Log.warn(message);
                                break;
                            case signalR.LogLevel.Information:
                                Log.info(message);
                                break;
                            case signalR.LogLevel.Debug:
                                Log.debug(message);
                                break;
                            case signalR.LogLevel.Trace:
                                Log.trace(message);
                                break;
                        }
                    }
                }
            })
            .withAutomaticReconnect()
            .build();

        this.connection?.on("receiveMessageAsync", this.receiveMessage);
    }

    @State.bound
    public async startAndJoinGroupAsync() {

        if (!config.notificationServiceEnabled) {
            return;
        }

        if (!this.connection) {
            Log.error("Cannot build SignalR connection.");
            return;
        }

        try {
            await this.connection.start();
            await this.connection.send("joinGroup", this.groupId);
        } catch (err) {
            Log.error(err, "Cannot connect to SignalR hub.");
        }
    }

    @State.bound
    public sendMessageToServer(message: any) {

        if (!config.notificationServiceEnabled) {
            Log.debug("NotificationService is disabled.");
            return;
        }

        if (this.connection?.state === signalR.HubConnectionState.Connected) {
            this.connection.send("sendMessage", this.groupId, message);
        }
    }

    @State.bound
    public disconnect() {
        if (this.connection?.state === signalR.HubConnectionState.Connected) {
            this.connection.stop();
        }
    }
}