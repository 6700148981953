import IICardioData from "@ICardioIntegrationPlugin/Model/IICardioData";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class ICardioProcessWidgetState {
    @State.observable.ref public iCardioProcessWidgetState: IICardioData = null;

    @State.action.bound
    public setWidgetState(widgetState: IICardioData): void {
        this.iCardioProcessWidgetState = widgetState;
    }
}