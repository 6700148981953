import IDataGridDataSource from "@CommonControls/DataGrid/DataSource/IDataGridDataSource";
import { IPagingState, IOrderingState, DataGridLoadType } from "@CommonControls/DataGrid/IDataGridProps";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DataGridDataSourceBase from "@CommonControls/DataGrid/DataSource/DataGridDataSourceBase";
import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import PractitionerId from "@Primitives/PractitionerId.g";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";

@Di.injectable()
export default class ExternalPractitionerDataSource extends DataGridDataSourceBase implements IDataGridDataSource {
    
    public __dataGridDataSource: true = true;

    constructor(
        @Di.inject("PractitionerApiAdapter") private practitionerApiAdapter: PractitionerApiAdapter,
        @Di.inject("PractitionerId") public practitionerId: PractitionerId
    ) {
        super();
        this.practitionerId = practitionerId;
        this.ordering = { columnId: "Name", direction: "asc" };
        this.paging = { currentPage: 0, pageSize: 10 };
    }

    @State.bound
    public containsId(id: PractitionerId) {
        const items = (this.data as IPagedItems<PractitionerStore>)?.items;

        if (!items) {
            return false;
        }

        return items.map(x => x.id).some(y => y.value === id.value);
    }

    @State.bound
    protected async onChangeCoreAsync(type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState | IOrderingState[], filterStore: any, columns: Array<IDataGridColumnProps<any, any, any>>): Promise<void> {
        const ordering2 = Array.isArray(ordering) ? ordering[0] : ordering;
        const results = await this.practitionerApiAdapter.searchPractitionersAsync(
            null,
            null,
            true,
            filterStore && filterStore.Name,
            filterStore && filterStore.Identifier,
            null,
            ordering2,
            paging,
            this.practitionerId && new PractitionerId(this.practitionerId.value.split("-")[1])
        );

        paging.currentPage = results.value.pageIndex;
        
        this.practitionerId = null;

        this.setState({items: results.value.values, totalCount: results.value.totalCount} as IPagedItems<any>, paging, ordering, filterStore);
    }
}
