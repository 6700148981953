import React, { useMemo, useCallback } from "react";
import MedicalServiceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicalServiceApiAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import _ from "@HisPlatform/Common/Lodash";
import CareTypeId from "@Primitives/CareTypeId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import { MedicalServiceCodeSelector } from "@HisPlatformControls";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";

interface IMedicalServiceNonTemporalCodeSelectorDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    medicalServiceApiAdapter: MedicalServiceApiAdapter;
}

interface IMedicalServiceNonTemporalCodeSelectorProps extends ICodeSelectorCommonProps<IEntityVersionSelector<MedicalServiceId>> {
    _dependencies?: IMedicalServiceNonTemporalCodeSelectorDependencies;
    filteredIds?: MedicalServiceId[];
    showFavoritesAndGroup?: boolean;
    pointOfCareId?: PointOfCareId;
    useAlternativeName?: boolean;
    careTypeId?: CareTypeId;
}

// TODO 2022.02.28. this is a wrapper component workaround for form engine until MedicalServiceCodeSelector is refactored
function MedicalServiceNonTemporalCodeSelector(props: IMedicalServiceNonTemporalCodeSelectorProps) {

    const versionedValue = useMemo(() => {
        return _.isArray(props.value)
            ? props.value?.map(v => v)
            : props.value;
    }, [props.value, props.validOn]);

    const onChange = useCallback((temporalValue: EntityVersionSelector<MedicalServiceId> | Array<EntityVersionSelector<MedicalServiceId>>) => {
        const value = _.isArray(temporalValue)
            ? temporalValue.map(t => t)
            : (!isNullOrUndefined(temporalValue)
                ? temporalValue
                : (props.multiSelect ? [] : null)
            );

        props.onChange(value, props.validOn);
    }, [props.value, props.validOn]);

    return (
        <MedicalServiceCodeSelector
            {...props}
            value={versionedValue}
            onChange={onChange}
        />
    );
}

export default State.observer(MedicalServiceNonTemporalCodeSelector);