import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import EventHandler from "@Toolkit/ReactClient/Components/EventHandler/EventHandler";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FeedbackDialogView from "@HisPlatform/Components/Panels/FeedbackDialog/FeedbackDialogView";
import IScreenshotService from "@HisPlatform/Services/Definition/ScreenshotService/IScreenshotService";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import TraceLogger from "@HisPlatform/Common/TraceLogger";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { FeedbackType } from "@HisPlatform/BoundedContexts/Diagnostics/ApplicationLogic/Model/FeedbackType";
import config from "@Config";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IssueReportingApiAdapter from "@HisPlatform/BoundedContexts/Diagnostics/ApplicationLogic/ApiAdapter/IssueReportingApiAdapter";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import IssueAttachment from "@HisPlatform/BoundedContexts/Diagnostics/ApplicationLogic/Model/IssueAttachment";

interface IFeedbackDialogDependencies {
    issueReportingApiAdapter: IssueReportingApiAdapter;
    screenshotService: IScreenshotService;
    dialogService: IDialogService;
    notificationService: INotificationService;
}

interface IFeedbackDialogProps {
    _dependencies?: IFeedbackDialogDependencies;
    openEvent: TypedEvent;
    isBugOnly?: boolean;
}

@State.observer
class FeedbackDialog extends React.Component<IFeedbackDialogProps> {

    private get issueReportingApiAdapter() { return this.props._dependencies.issueReportingApiAdapter; }
    private get screenshotService() { return this.props._dependencies.screenshotService; }
    private get dialogService() { return this.props._dependencies.dialogService; }
    private get notificationService() { return this.props._dependencies.notificationService; }

    @State.observable.ref private isOpen = false;
    @State.observable.ref private summary = "";
    @State.observable.ref private description = "";
    @State.observable.ref private isLoading = false;
    @State.observable.ref private attachments: File[] = null;
    @State.observable.ref private feedbackType: FeedbackType = "bug";

    private feedbackTypes: Array<ISelectBoxItem<FeedbackType>> = [
        {
            value: "feedback",
            text: "Visszajelzés"
        },
        {
            value: "bug",
            text: "Hiba"
        }
    ];

    @State.action.bound
    private open() {
        this.isOpen = true;
        TraceLogger.ignore();
    }

    @State.action.bound
    private close() {
        TraceLogger.disableIgnore();
        this.isOpen = false;
    }

    @State.action
    private clear() {
        this.summary = "";
        this.description = "";
        this.attachments = null;
    }

    // Method name is ignored in TraceLogger.ts
    @State.action.bound
    private feedbackDialogSetSummary(newValue: string) {
        this.summary = newValue;
    }

    // Method name is ignored in TraceLogger.ts
    @State.action.bound
    private feedbackDialogSetDescription(newValue: string) {
        this.description = newValue;
    }

    @State.action.bound
    private setFeedbackType(newValue: FeedbackType) {
        this.feedbackType = newValue;
    }

    @State.action.bound
    private setAttachments(files: File[]) {
        this.attachments = files;
    }

    private showDialogWithLinkOfCreatedIssue(issueKey: string, issueUrl: string) {
        const message = <>Az elküldött visszajelzést/hibajegyet megtekintheti az alábbi linken: <br /><a href={issueUrl} target="_blank">{issueUrl}</a></>;
        this.dialogService.ok("Visszajelzés/hibajegy megtekintése", message);
    }

    @State.loadingState.bound("isLoading")
    private async sendAsync() {

        if (!this.summary) {
            this.notificationService.showCannotSaveBecauseOfErrors();
            return;
        }

        const attachmentsToUpload = [
            ...this.attachments || [],
        ];

        const screenshot = this.screenshotService.getLastScreenshot();
        if (screenshot) {
            attachmentsToUpload.push(screenshot);
        }

        if (global["tracer"]) {
            const log = new File([(global["tracer"] as TraceLogger).getLogContent()], "log.txt");
            const logReport = new File([(global["tracer"] as TraceLogger).getLogReportContent()], "interactive-log.html");
            attachmentsToUpload.push(log, logReport);
        }

        const currentUrl = window.location.href;

        const description = `ENV: ${config.environmentName}
VER: ${config.version}
LINK: ${currentUrl}
${this.description}`;

        const attachments = await Promise.all(attachmentsToUpload.map(attachments => this.getContentAsBase64Async(attachments)));

        const issueData = await this.issueReportingApiAdapter.createIssueCommandAsync(this.feedbackType,
            this.summary,
            description,
            null,
            attachments);

        this.clear();
        this.close();

        this.showDialogWithLinkOfCreatedIssue(issueData.value.issueKey, issueData.value.issueUrl);
    }

    private async getContentAsBase64Async(file: File) {
        const bytes = await file.toUint8ArrayAsync();
        const content = Base64Converter.fromByteArray(bytes);
        return new IssueAttachment(file.name, content);
    }

    public render() {
        return (
            <>
                <EventHandler event={this.props.openEvent} onFired={this.open} />
                <FeedbackDialogView
                    isOpen={this.isOpen}
                    onClose={this.close}
                    summary={this.summary}
                    onSummaryChange={this.feedbackDialogSetSummary}
                    description={this.description}
                    onDescriptionChange={this.feedbackDialogSetDescription}
                    onSendAsync={this.sendAsync}
                    onChangeAttachments={this.setAttachments}
                    isLoading={this.isLoading}
                    feedbackTypes={this.feedbackTypes}
                    onIssueTypeChange={this.setFeedbackType}
                    selectedFeedbackType={this.feedbackType}
                    isBugOnly={this.props.isBugOnly}
                />
            </>
        );
    }
}

export default connect(
    FeedbackDialog,
    new DependencyAdapter<IFeedbackDialogProps, IFeedbackDialogDependencies>( c => ({
        issueReportingApiAdapter: c.resolve("IssueReportingApiAdapter"),
        screenshotService: c.resolve<IScreenshotService>("IScreenshotService"),
        dialogService: c.resolve<IDialogService>("IDialogService"),
        notificationService: c.resolve<INotificationService>("INotificationService"),
    }))
);
