import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IPropertyListItem from "@CommonControls/PropertyList/IPropertyListItem";
import IExternalPrescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IExternalPrescription";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import EhiPatientIdentifierTypeId from "@Primitives/EhiPatientIdentifierTypeId.g";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import IEhiMedicationEquipment from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IEhiMedicationEquipment";
import ExternalPrescriptionDetailViewBase from "./ExternalPrescriptionDetailViewBase";
import MedicationInfoModalParams from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/MedicationInfoModal/MedicationInfoModalParams";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import MedicationVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/MedicationVersion";
import MedicationsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicationsApiAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import IEhiMedicationBase from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IEhiMedicationBase";
import { EMedicationDosage, FrequencyBasedEMedicationDosage, TextualEMedicationDosage } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/EMedicationDosage";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import formatCodeName from "@HisPlatformControls/CodeNameFormatter/CodeNameFormatter";
import { IMedicationEquipmentClassificationVersionDto } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";

const DEFAULT_PROPERTY_LIST_HEADER_WIDTH = "40%";
const TTT_IDENTIFIER_SYSTEM_ID: IdentifierSystemId = Object.freeze(new IdentifierSystemId("TTT"));

interface IExternalMedicationEquipmentBasedPrescriptionDetailViewProps {
    _dependencies?: IExternalMedicationEquipmentBasedPrescriptionDetailViewDependencies;
    externalPrescription: IExternalPrescription;
}

interface IExternalMedicationEquipmentBasedPrescriptionDetailViewDependencies {
    ehiCareApiAdapter?: EhiCareApiAdapter;
    localizationService?: ILocalizationService;
    medicationsApiAdapter?: MedicationsApiAdapter;
    modalService?: IModalService;
    notificationService?: INotificationService;
    practitionerApiAdapter?: PractitionerApiAdapter;
}

@State.observer
class ExternalMedicationEquipmentBasedPrescriptionDetailView extends ExternalPrescriptionDetailViewBase<IExternalMedicationEquipmentBasedPrescriptionDetailViewProps> {
    @State.observable.ref private medicationVersionsByTttCode: Map<string, MedicationVersion> = new Map<string, MedicationVersion>();
    @State.observable.ref private classificationsByIsoCode: Map<string, IMedicationEquipmentClassificationVersionDto> = new Map<string, IMedicationEquipmentClassificationVersionDto>();
    
    private readonly showProductInfoCallbackFactory = (tttIdentifier: string) => () => this.showProductDetailsAsync(tttIdentifier);

    @State.computed
    protected get prescription(): IExternalPrescription {
        return this.props.externalPrescription;
    }

    protected get localizationService(): ILocalizationService {
        return this.props._dependencies.localizationService;
    }

    protected get practitionerApiAdapter(): PractitionerApiAdapter {
        return this.props._dependencies.practitionerApiAdapter;
    }

    protected get ehiCareApiAdapter(): EhiCareApiAdapter {
        return this.props._dependencies.ehiCareApiAdapter;
    }

    private get localResources() {
        return this.resources.MedicationEquipment;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }

    public componentDidUpdate(prevProps: IExternalMedicationEquipmentBasedPrescriptionDetailViewProps) {
        if (this.props.externalPrescription !== prevProps.externalPrescription) {
            dispatchAsyncErrors(this.initializeAsync(), this);
        }
    }
    
    public render() {
        return !!this.prescription && (
            <>
                <Ui.Flex style={{paddingLeft: "20px"}}>
                    {this.renderProducts()}
                    <Ui.Flex.Item xs={12}>
                        <Ui.GroupBox title={this.resources.Common.PrescriptionProperties.Title}>
                            <Ui.PropertyList nameWidth={DEFAULT_PROPERTY_LIST_HEADER_WIDTH} hasColonAfterNames properties={this.renderPrescriptionProperties()} />
                        </Ui.GroupBox>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={12}>
                        <Ui.GroupBox title={this.localResources.PrescriptionSpecificProperties.Title}>
                            <Ui.PropertyList nameWidth={DEFAULT_PROPERTY_LIST_HEADER_WIDTH} hasColonAfterNames properties={this.renderMedicationEquipmentBasedPrescriptionProperties()} />
                        </Ui.GroupBox>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={12}>
                        <Ui.GroupBox title={this.resources.Common.PatientProperties.Title}>
                            <Ui.PropertyList nameWidth={DEFAULT_PROPERTY_LIST_HEADER_WIDTH} hasColonAfterNames properties={this.renderPatientProperties()} />
                        </Ui.GroupBox>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={12}>
                        <Ui.GroupBox title={this.resources.Common.OrganizationProperties.Title}>
                            <Ui.PropertyList nameWidth={DEFAULT_PROPERTY_LIST_HEADER_WIDTH} hasColonAfterNames properties={this.renderOrganizationProperties()} />
                        </Ui.GroupBox>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </>
        );
    }

    @State.boundLoadingState()
    private async initializeAsync() {
        if (!this.prescription) {
            return;
        }

        await this.loadClassificationsAsync();
        await this.loadMedicationVersionsAsync();
    }

    @State.bound
    private async loadClassificationsAsync() {
        const classificationsByIsoCode = new Map<string, IMedicationEquipmentClassificationVersionDto>();
        const classificationVersions = await this.props._dependencies.medicationsApiAdapter.getAllMedicationEquipmentClassifications(this.prescription.prescribedOn);
        for (const product of this.prescription.products) {
            const medicationEquipment = product as IEhiMedicationEquipment;
            if (!medicationEquipment || !medicationEquipment.isoIdentifier) {
                continue;
            }

            const classificationVersion = classificationVersions.value.find(i => i.code === medicationEquipment.isoIdentifier);
            classificationsByIsoCode.set(medicationEquipment.isoIdentifier, classificationVersion);
        }

        this.setClassificationsByIsoCode(classificationsByIsoCode);
    }

    @State.bound
    private async loadMedicationVersionsAsync() {
        const tttCodes: Set<Identifier> = new Set<Identifier>();
        for (const product of this.prescription.products) {
            const medication = product as IEhiMedicationEquipment;
            if (isNullOrUndefined(medication?.tttIdentifier)) {
                continue;
            }
            
            tttCodes.add(new Identifier(TTT_IDENTIFIER_SYSTEM_ID, medication.tttIdentifier));
        }

        const medicationVersions = await this.props._dependencies.medicationsApiAdapter.getMedicationVersionsByIdentifiersAsync(Array.from(tttCodes), this.prescription.prescribedOn);
        if (!medicationVersions.value) {
            return;
        }
        
        const medicationVersionsByTttCode = new Map<string, MedicationVersion>();
        for (const item of medicationVersions.value) {
            medicationVersionsByTttCode.set(item[0].value, item[1][0]);
        }

        this.setMedicationVersionsByTttCode(medicationVersionsByTttCode);
    }

    @State.bound
    private renderPrescriptionProperties() {
        const properties: IPropertyListItem[] = [{
            name: this.resources.Common.PrescriptionProperties.Properties.Status,
            value: this.localizationService.localizeEnumId(this.prescription.status).Name
        }, {
            name: this.resources.Common.PrescriptionProperties.Properties.Condition,
            value: formatCodeName(this.prescription.condition.code, this.prescription.condition.name)
        }, {
            name: this.resources.Common.PrescriptionProperties.Properties.SubsidyClaimType,
            value: this.localizationService.localizeEnumId(this.prescription.products[0].ehiMedicationSubsidyClaimTypeId).Name
        }, {
            name: this.localResources.PrescriptionProperties.Properties.CareIdentifier,
            value: this.prescription.careIdentifier
        }];

        if (!!this.prescription.practitionerRecommendation) {
            properties.push({
                name: this.localResources.PrescriptionProperties.Properties.PractitionerRecommendationDoctor,
                value: <>{this.practitionerRecommendationDoctor} ({this.prescription.practitionerRecommendation.logNumber}) {this.localizationService.localizeDate(this.prescription.practitionerRecommendation.date)}</>
            });
        }

        properties.push({
            name: this.resources.Common.PrescriptionProperties.Properties.PrescribedOn,
            value: this.localizationService.localizeDate(this.prescription.prescribedOn)
        }, {
            name: this.resources.Common.PrescriptionProperties.Properties.Validity,
            value: `${this.localizationService.localizeDate(this.prescription.validity.from)} - ${this.localizationService.localizeDate(this.prescription.validity.to)}`,
        }, {
            name: this.resources.MedicationEquipment.PrescriptionProperties.Properties.ProductType,
            value: this.prescription.ehiProductType
        }, {
            name: this.resources.Common.PrescriptionProperties.Properties.Category,
            value: this.localizationService.localizeEnumId(this.prescription.ehiPrescriptionCategoryId).Name
        }, {
            name: this.resources.Common.PrescriptionProperties.Properties.Type,
            value: this.localizationService.localizeEnumId(this.prescription.ehiPrescriptionTypeId).Name
        }, {
            name: this.resources.Common.PrescriptionProperties.Properties.Identifier,
            value: this.prescription.identifier
        });

        return properties;
    }

    @State.bound
    private renderMedicationEquipmentBasedPrescriptionProperties() {
        const properties: IPropertyListItem[] = [];

        if (!!this.prescription.sizeChangeDescription) {
            properties.push({
                name: this.localResources.PrescriptionSpecificProperties.Properties.SizeChangeReason,
                value: this.prescription.sizeChangeDescription
            });
        }
        
        if (!!this.prescription.stateChangeDescription) {
            properties.push({
                name: this.localResources.PrescriptionSpecificProperties.Properties.StateChangeReason,
                value: this.prescription.stateChangeDescription
            });
        }

        const medication = this.prescription.products[0] as IEhiMedicationEquipment;
        if (!isNullOrUndefined(medication?.faultDescription)) {
            properties.push({
                name: this.localResources.PrescriptionSpecificProperties.Properties.FaultDescription,
                value: medication.faultDescription
            });
        }

        properties.push({
            name: this.localResources.PrescriptionSpecificProperties.Properties.IsCountersignMandatory,
            value: this.localizationService.localizeBoolean(this.prescription.isCountersignMandatory)
        });

        return properties;
    }

    @State.bound
    private renderPatientProperties() {
        return [{
            name: this.resources.Common.PatientProperties.Properties.Name,
            value: this.prescription.patient.name
        }, {
            name: this.resources.Common.PatientProperties.Properties.IdentifierType,
            value: formatCodeName(this.prescription.extensionData.EhiPatientIdentifierType, this.localizationService.localizeEnumId(new EhiPatientIdentifierTypeId(this.prescription.extensionData.EhiPatientIdentifierType)).Name)
        }, {
            name: this.resources.Common.PatientProperties.Properties.Identifier,
            value: this.prescription.patient.identifier
        }, {
            name: this.resources.Common.PatientProperties.Properties.BirthDate,
            value: this.localizationService.localizeDate(this.prescription.patient.birthDate)
        }, {
            name: this.resources.Common.PatientProperties.Properties.Address,
            value: this.prescription.patient.address
        }] as IPropertyListItem[];
    }

    @State.bound
    private renderOrganizationProperties() {
        return [{
            name: this.resources.Common.OrganizationProperties.Properties.Practitioner,
            value: this.practitioner
        }, {
            name: this.resources.Common.OrganizationProperties.Properties.Organization,
            value: !!this.prescription.organizationName
                ? formatCodeName(this.prescription.organizationId, this.prescription.organizationName)
                : this.organization
        }, {
            name: this.resources.Common.OrganizationProperties.Properties.OrganizationUnit,
            value: !!this.prescription.organizationUnitName
                ? formatCodeName(this.prescription.organizationUnitId, this.prescription.organizationUnitName)
                : this.organizationUnit
        }] as IPropertyListItem[];
    }

    @State.bound
    private renderProductName(product: IEhiMedicationEquipment) {
        return (
            <Ui.Flex xsVerticalAlign="middle">
                {!!product.tttIdentifier && (
                    <>
                        <Ui.Flex.Item>{product.medicationName}</Ui.Flex.Item>
                        <Ui.Flex.Item grow={true} horizontalContentAlignment="right">
                            <Ui.Button
                                iconName="info"
                                onClickAsync={this.showProductInfoCallbackFactory(product.tttIdentifier)}
                                size="compact"
                                automationId="MedicationEquipmentPrescriptDetails_ShowProductInfoButton" />
                        </Ui.Flex.Item>
                    </>
                ) || (
                    <Ui.Flex.Item>{formatCodeName(product.isoIdentifier, this.getIsoGroupName(product.isoIdentifier))}</Ui.Flex.Item>
                )}
            </Ui.Flex>
        );
    }

    @State.bound
    private getIsoGroupName(isoCode: string): string {
        if (isNullOrUndefined(isoCode)) {
            return this.resources.Common.NoDataMessage;
        }

        const classification = this.classificationsByIsoCode.get(isoCode);
        if (isNullOrUndefined(classification)) {
            return this.resources.Common.NoDataMessage;
        }

        return classification.name;
    }

    @State.bound
    private renderProductInstructions(product: IEhiMedicationEquipment) {
        return (
            <Ui.Flex verticalSpacing="medium">
                {product.practitionerInstruction && <Ui.Flex.Item xs={12}>{product.practitionerInstruction}</Ui.Flex.Item>}
                {product.dispenserInstruction && <Ui.Flex.Item xs={12}>{product.dispenserInstruction}</Ui.Flex.Item>}
            </Ui.Flex>
        );
    }

    @State.bound
    private getDosageInfo(dosage: EMedicationDosage): { type: string, node: React.ReactNode } {
        if (!dosage) {
            return { type: "", node: "" };
        }

        if (dosage instanceof FrequencyBasedEMedicationDosage) {
            return { type: "Gyakoriság szerint", node: `${dosage.frequency}x${dosage.amount} ${dosage.unit}` };
        }
        
        if (dosage instanceof TextualEMedicationDosage) {
            return { type: "Szöveges", node: dosage.instruction };
        }
        
        throw new Error("Dosage descendant not recognized.");
    }

    @State.bound
    private renderProductProperties(product: IEhiMedicationBase) {
        const medication = product as IEhiMedicationEquipment;
        const dosageInfo = this.getDosageInfo(medication.dosage);

        const properties: IPropertyListItem[] = [{
            name: this.localResources.ProductProperties.Properties.Name,
            value: this.renderProductName(medication)
        }, {
            name: this.localResources.ProductProperties.Properties.UnsubstitutableReason,
            value: medication.unsubstitutableReason
        }, {
            name: this.localResources.ProductProperties.Properties.Amount,
            value: `${medication.amount} ${medication.textAmount}`
        }, {
            name: this.localResources.ProductProperties.Properties.AmountReason,
            value: medication.amountReason
        }, {
            name: this.localResources.ProductProperties.Properties.Lifespan,
            value: `${medication.lifespan} hónap`
        }, {
            name: this.localResources.ProductProperties.Properties.ApplicationArea,
            value: [medication.bodySide, medication.bodyArea, medication.size].filter(i => !!i).join(", ")
        }, {
            name: this.localResources.ProductProperties.Properties.IsCustomMade,
            value: this.localizationService.localizeBoolean(medication.isCustomMade)
        }, {
            name: this.localResources.ProductProperties.Properties.UsageType,
            value: dosageInfo.type
        }, {
            name: this.localResources.ProductProperties.Properties.UsageDescription,
            value: dosageInfo.node
        }, {
            name: this.localResources.ProductProperties.Properties.Instructions,
            value: this.renderProductInstructions(medication)
        }];

        return properties;
    }

    @State.bound
    private renderProducts() {
        const nodes: JSX.Element[] = [];
        for (const product of this.prescription.products) {
            nodes.push(
                <Ui.Flex.Item xs={12}>
                    <Ui.GroupBox title={this.localResources.ProductProperties.Title}>
                        <Ui.PropertyList nameWidth={DEFAULT_PROPERTY_LIST_HEADER_WIDTH} hasColonAfterNames properties={this.renderProductProperties(product)} />
                    </Ui.GroupBox>
                </Ui.Flex.Item>
            );
        }

        return nodes;
    }

    @State.action
    private showProductDetailsAsync(tttCode: string) {
        const medicationVersion = this.medicationVersionsByTttCode.get(tttCode);
        if (!medicationVersion) {
            this.props._dependencies.notificationService.error(this.localResources.Errors.ProductNotFound);
            return Promise.resolve();
        }

        return this.props._dependencies.modalService.showModalAsync(new MedicationInfoModalParams(medicationVersion));
    }

    @State.action.bound
    private setClassificationsByIsoCode(value: Map<string, IMedicationEquipmentClassificationVersionDto>) {
        this.classificationsByIsoCode = value;
    }

    @State.action.bound
    private setMedicationVersionsByTttCode(value: Map<string, MedicationVersion>) {
        this.medicationVersionsByTttCode = value;
    }
}

export default connect(
    ExternalMedicationEquipmentBasedPrescriptionDetailView,
    new DependencyAdapter<IExternalMedicationEquipmentBasedPrescriptionDetailViewProps, IExternalMedicationEquipmentBasedPrescriptionDetailViewDependencies>(c => ({
        ehiCareApiAdapter: c.resolve("EhiCareApiAdapter"),
        localizationService: c.resolve("ILocalizationService"),
        medicationsApiAdapter: c.resolve("MedicationsApiAdapter"),
        modalService: c.resolve("IModalService"),
        notificationService: c.resolve("INotificationService"),
        practitionerApiAdapter: c.resolve("PractitionerApiAdapter")
    }))
);