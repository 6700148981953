import CareActivityId from "@Primitives/CareActivityId.g";
import IDecisionSupportModalParams from "./IDecisionSupportDialogParams";
import PatientId from "@Primitives/PatientId.g";
import IMessagingSubscription from "@Toolkit/CommonWeb/CommandProcessing/Definition/IMessagingSubscription";

export default class DecisionSupportModalParams implements IDecisionSupportModalParams {
    public static type = "DecisionSupportDialog";

    public get type() {
        return DecisionSupportModalParams.type;
    }

    constructor(
        public readonly url: string,
        public readonly patientId: PatientId,
        public readonly careActivityId: CareActivityId,
        public readonly initializationPayload: object,
        public readonly registerEventHandlers: (subscription: IMessagingSubscription) => void
    ) {
    }
}