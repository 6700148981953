import DependencyContainer from "@DiContainer";
import EntityPermissionsApiAdapter from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/DataAccessControl/EntityPermissionsApiAdapter";
import EntityPermissionStoreMapper from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/DataAccessControl/EntityPermissionStoreMapper";

DependencyContainer
    .bind("EntityPermissionsApiAdapter")
    .to(EntityPermissionsApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EntityPermissionStoreMapper")
    .to(EntityPermissionStoreMapper)
    .inSingletonScope();