import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalConditionItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionItemBase";
import ReferencedTherapyAssignment from "./ReferencedTherapyAssignment";
import TherapyRecommendationId from "@Primitives/TherapyRecommendationId.g";
import CustomTherapyAssignment from "./CustomTherapyAssignment";
import { TherapyCodeSelectorItem } from "@HunEHealthInfrastructurePlugin/Common/Components/TherapyCodeSelector/TherapyCodeSelectorItem";
import TherapyAssignmentBase from "./TherapyAssignmentBase";

export default class TherapyRecommendationStore extends MedicalConditionItemBase<TherapyRecommendationId> {

    @State.observable.ref public therapyAssignment: TherapyAssignmentBase;
    @State.observable public therapyName: string;

    constructor(isNew: boolean) {
        super(isNew);
    }

    @State.computed public get therapyCodeSelectorItem() {
        if (!this.therapyAssignment) {
            return null;
        }
        if (this.therapyAssignment instanceof ReferencedTherapyAssignment) {
            return new TherapyCodeSelectorItem({ id: this.therapyAssignment.therapyId });
        } else if (this.therapyAssignment instanceof CustomTherapyAssignment) {
            return new TherapyCodeSelectorItem({ text: this.therapyAssignment.name });
        }
        return null;
    }

    @State.action.bound
    public setTherapyAssignment(newValue: TherapyAssignmentBase) {
        this.therapyAssignment = newValue;
    }

    @State.action.bound
    public setTherapyName(newValue: string) {
        this.therapyName = newValue;
    }
}