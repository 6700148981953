import DependencyContainer from "@DiContainer";
import { ITemporaryDataClient, TemporaryDataClient } from "@HisPlatform/BoundedContexts/Productivity/Api/Proxy.g";
import TemporaryDataApiAdapter from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/TemporaryData/TemporaryDataApiAdapter";

DependencyContainer
    .bind("ITemporaryDataClient")
    .to(TemporaryDataClient).inSingletonScope();

DependencyContainer
    .bind("TemporaryDataApiAdapter")
    .to(TemporaryDataApiAdapter);