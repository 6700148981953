import EesztCommunicationLogsMenu from "@HunEHealthInfrastructurePlugin/Packages/Settings/Components/EesztCommunicationLogsMenu";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import NavMenuCustomItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuCustomItem";
import HunEHealthInfrastructureSettingsMenu from "@HunEHealthInfrastructurePlugin/Packages/Settings/Components/HunEHealthInfrastructureSettingsMenu";
import CoverageEligibilitySettingsMenu from "@HunEHealthInfrastructurePlugin/Packages/Settings/Components/CoverageEligibilitySettingsMenu";

export function getNavigationMenuItems(): INavMenuItem[] {
    const leftTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const rightTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const childMenuItems: INavMenuItem[] = [
        NavMenuCustomItem.createChild(
            "EesztIntegrationNavbarSettings",
            "Settings",
            EesztCommunicationLogsMenu,
            14
        ),
        NavMenuCustomItem.createChild(
            "HunEHealthInfraStructureSettingsMenu",
            "Settings",
            HunEHealthInfrastructureSettingsMenu,
            15
        ),
        NavMenuCustomItem.createChild(
            "CoverageEligibilitySettingsMenu",
            "Settings",
            CoverageEligibilitySettingsMenu,
            16
        )        
    ];

    return [...leftTopLevelMenuItems, ...rightTopLevelMenuItems , ...childMenuItems];
}