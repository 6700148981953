import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import PatientBloodTypeId from "@Primitives/PatientBloodTypeId.g";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import PatientBloodTypePanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/MedicalCondition/PatientBloodType/PatientBloodTypePanel";

export default function configurePatientBloodTypeUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2({
        identifiers: [HunEHealthInfrastructureUseCases.patientBloodTypeDetail],
        component: PatientBloodTypePanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));
            const id = map.get("patientBloodTypeId") ?? new PatientBloodTypeId("new");
            return {
                id: id,
            };
        }
    });
}
