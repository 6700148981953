import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import CareActivityTextBlockId from "@Primitives/CareActivityTextBlockId.g";

export interface ITextBlockHistoryDialogParams extends IModalParams {
    textBlockId: CareActivityTextBlockId;
}


export interface ITextBlockHistoryDialogResult {
}

export default class TextBlockHistoryDialogParams implements IModalParams {
    public static type = "TextBlockHistoryDialog";
    public get type() { return TextBlockHistoryDialogParams.type; }

    constructor(public textBlockId: CareActivityTextBlockId) { }
}