import State from "@Toolkit/ReactClient/Common/StateManaging";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import SchedulingServiceFilterType from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/SchedulingServiceFilterType.g";
import SlotStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SlotStore";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";

export default class ComplexSchedulingServiceFilter {

    @State.observable.ref public schedulingServiceId: SchedulingServiceId = null;
    @State.observable.ref public practitionerId: PractitionerId = null;
    @State.observable.ref public pointOfCareId: PointOfCareId = null;
    @State.observable public practitionerFilterType: SchedulingServiceFilterType = SchedulingServiceFilterType.Mandatory;
    @State.observable public pointOfCareFilterType: SchedulingServiceFilterType = SchedulingServiceFilterType.Mandatory;
    @State.observable public isFirst: boolean = false;
    @State.observable public isLast: boolean = false;
    @State.observable public recurring: boolean = false;
    @State.observable public numberOfRepetitions: number;
    @State.observable public minimumTimeBetweenRepetitions: number;
    @State.observable public maximumTimeBetweenRepetitions: number;
    @State.observable public maxRepetitionPeriodInDays: number;
    @State.observable public intraDayTimePeriodConstraintStart: TimeOfDay = new TimeOfDay(0, 0);
    @State.observable public intraDayTimePeriodConstraintEnd: TimeOfDay = new TimeOfDay(23, 59);
    public preDesignatedAppointmentSlots: SlotStore[][] = [];

    @State.action.bound
    public setSchedulingServiceId(schedulingServiceId: SchedulingServiceId) {
        this.schedulingServiceId = schedulingServiceId;
    }

    @State.action.bound
    public setPractitionerId(practitionerId: PractitionerId) {
        this.practitionerId = practitionerId;
    }

    @State.action.bound
    public setPractitionerFilterType(practitionerFilterType: SchedulingServiceFilterType) {
        this.practitionerFilterType = practitionerFilterType;
    }

    @State.action.bound
    public setPointOfCareId(pointOfCareId: PointOfCareId) {
        this.pointOfCareId = pointOfCareId;
    }

    @State.action.bound
    public setPointOfCareFilterType(pointOfCareFilterType: SchedulingServiceFilterType) {
        this.pointOfCareFilterType = pointOfCareFilterType;
    }

    @State.action.bound
    public setIsFirst(isFirst: boolean) {
        this.isFirst = isFirst;
    }

    @State.action.bound
    public setIsLast(isLast: boolean) {
        this.isLast = isLast;
    }

    @State.action.bound
    public setRecurring(recurring: boolean) {
        this.recurring = recurring;
    }

    @State.action.bound
    public setNumberOfRepetitions(numberOfRepetitions: number) {
        this.numberOfRepetitions = numberOfRepetitions;
    }

    @State.action.bound
    public setMinimumTimeBetweenRepetitions(minimumTimeBetweenRepetitions: number) {
        this.minimumTimeBetweenRepetitions = minimumTimeBetweenRepetitions;
    }

    @State.action.bound
    public setMaximumTimeBetweenRepetitions(maximumTimeBetweenRepetitions: number) {
        this.maximumTimeBetweenRepetitions = maximumTimeBetweenRepetitions;
    }

    @State.action.bound
    public setMaxRepetitionPeriodInDays(maxRepetitionPeriodInDays: number) {
        this.maxRepetitionPeriodInDays = maxRepetitionPeriodInDays;
    }

    @State.action.bound
    public setIntraDayTimePeriodConstraint(newFromValue: TimeOfDay, newToValue: TimeOfDay) {
        this.intraDayTimePeriodConstraintStart = newFromValue;
        this.intraDayTimePeriodConstraintEnd = newToValue;
    }
}
