import React from "react";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import IDiagnosisExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IDiagnosisExtensionPointProps";
import DiagnosisStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IMasterDetailState from "@CommonControls/Layout/IMasterDetailState";
import CareActivityId from "@Primitives/CareActivityId.g";
import ExtensionController from "@HisPlatform/Components/HisPlatformExtensionPoint/ExtensionController";
import DiagnosisListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisListStore";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PatientId from "@Primitives/PatientId.g";

interface IDiagnosisDetailProps {
    _masterDetailState?: IMasterDetailState;
    diagnosisStore: DiagnosisStore;
    initializeExtensionData: () => void;
    careActivityId: CareActivityId;
    readonly: boolean;
    extensionController: ExtensionController;
    saveAsync: () => Promise<void>;
    diagnosisListStore: DiagnosisListStore;
    validateAsync: (dirtyFields?: string[]) => Promise<IClientValidationResult[]>;
    pointOfCareId: PointOfCareId;
    patientId: PatientId;
    onClose: () => void;
}

/** @screen */

const DiagnosisDetail: React.FC<IDiagnosisDetailProps> = (props) => {
    const extensionProps = () => {
        return {
            diagnosisStore: props.diagnosisStore,
            initializeExtensionData: props.initializeExtensionData,
            careActivityId: props.careActivityId,
            _masterDetailState: props._masterDetailState,
            extensionController: props.extensionController,
            readonly: props.readonly,
            saveAsync: props.saveAsync,
            diagnosisListStore: props.diagnosisListStore,
            validateAsync: props.validateAsync,
            pointOfCareId: props.pointOfCareId,
            patientId: props.patientId,
            onClose: props.onClose
        } as IDiagnosisExtensionPointProps;
    };

    return (
        <HisPlatformExtensionPoint extensionProps={extensionProps()}
                                   type="diagnosisExtension"
                                   {...props}
        >
            <></>
        </HisPlatformExtensionPoint>
    );
};

export default State.observer(DiagnosisDetail);
