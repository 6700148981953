import React, { useMemo, useCallback } from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import DateTimePicker from "@CommonControls/DateTimePicker/DateTimePicker";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import moment from "moment";
import { SelectBoxCore } from "@CommonControls/SelectBox";

interface IDatePicker extends ISelectBoxBaseProps {
    valueOnClear?: LocalDate;
    value: LocalDate;
    onChange: (newValue: LocalDate) => void;
    forwardedRef?: React.Ref<SelectBoxCore>;
}


const DatePicker: React.FC<IDatePicker> = props => {

    const value = useMemo(() => props.value && props.value.toUtcDayStartMoment(), [props.value]);
    const changeHandler = useCallback((newValue: moment.Moment) => {
        if (props.onChange) {
            props.onChange(LocalDate.createFromMoment(newValue));
        }
    }, [props.onChange]);

    return (
        <DateTimePicker
            {...props}
            value={value}
            onChange={changeHandler}
            dateOnlyMode
            forwardedRef={props.forwardedRef}
        />
    );
};

export default React.forwardRef<SelectBoxCore, IDatePicker>((props, ref) => <DatePicker {...props} forwardedRef={ref} />);