import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PractitionerId from "@Primitives/PractitionerId.g";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import PatientId from "@Primitives/PatientId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

@Di.injectable()
export default class EhiOrganizationUnitProviderService {

    constructor(
        @Di.inject("EhiCareApiAdapter") private readonly ehiCareApiAdapter: EhiCareApiAdapter
    ) {}
    
    @State.bound
    public async getOrganizationUnitIdAsync(practitionerId: PractitionerId, patientId: PatientId) {
        if (isNullOrUndefined(practitionerId) || isNullOrUndefined(patientId)) {
            return null;
        }

        const response = await this.ehiCareApiAdapter.getEhiOrganizationUnitExtensionAsync(patientId, practitionerId);

        if (!isNullOrUndefined(response) && !isNullOrUndefined(response.value)) {
            return response.value;
        }

        return null;
    }
}