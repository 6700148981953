import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiSyncStatusLabel from "@HunEHealthInfrastructurePlugin/Common/Components/EhiSyncStatusLabel";
import IPrescriptionListNameExtensionPointProps from "@PluginInterface/BoundedContexts/MedicationRequest/ExtensionPoints/IPrescriptionListNameExtensionPointProps";

@State.observer
export default class EhiPrescriptionListNameExtension extends React.Component<IPrescriptionListNameExtensionPointProps> {

    public render() {
        if (!isNullOrUndefined(this.props.extensionData.PrescriptionSyncStatus)) {
            return <EhiSyncStatusLabel value={this.props.extensionData.PrescriptionSyncStatus} upperIndex />;
        } else {
            return null;
        }
    }
}