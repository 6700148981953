import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import {dispatchAsyncErrors} from "@Toolkit/CommonWeb/AsyncHelpers";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";

interface IExternalLocationListPanelDependencies {
    worklistApiAdapter: WorklistApiAdapter;
}

interface IExternalLocationListPanelProps {
    _dependencies?: IExternalLocationListPanelDependencies;
    onBack: () => void;
    onSelectedExternalLocationIdChange: (externalLocationId: ExternalLocationId) => void;
    useCaseState: INDataUseCaseState;
    selectedRowId: string;
    onChange: (selectedRowId: string, useCaseState: INDataUseCaseState) => void;
    onCloseDetail: () => void;
}

@State.observer
class ExternalLocationListPanel extends React.Component<IExternalLocationListPanelProps> {
    @State.observable.ref private worklistDefinition: IWorklistDefinition = null;
    @State.computed private get hasNewRow() { return this.props.selectedRowId === "new"; }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.initializePanelAsync);

    private readonly refreshListEvent = new TypedAsyncEvent();

    public componentDidMount() {
        dispatchAsyncErrors(this.initializePanelAsync(), this);
    }

    @State.bound
    private async initializePanelAsync() {
        const resp = await this.props._dependencies.worklistApiAdapter.getExternalLocationBoundWorklistDefinition();
        State.runInAction(() => {
            this.worklistDefinition = resp.value;
        });
    }

    @State.action.bound
    private getPanelProps(useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) {
        return {
            onExternalLocationCreatedAsync: this.externalLocationCreatedAsync,
            onCloseDetailAsync: this.closeDetailAsync
        };
    }

    @State.action.bound
    private async closeDetailAsync() {
        await this.refreshListAsync();
        this.props.onCloseDetail?.();
    }

    @State.action.bound
    private async refreshListAsync() {
        await this.refreshListEvent.emitAsync();
    }

    @State.action.bound
    private async externalLocationCreatedAsync(id: ExternalLocationId) {
        await this.refreshListAsync();
        this.props.onSelectedExternalLocationIdChange?.(id);
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticOrganizationResources.ExternalLocationPanel.Title} />;
        }

        return (
            <SingleLayout>
                <NDataPanel
                    definition={this.worklistDefinition}
                    onChange={this.props.onChange}
                    useCaseState={this.props.useCaseState}
                    selectedRowId={this.props.selectedRowId}
                    hasNewRow={this.hasNewRow}
                    disableDetailStrictMode
                    refreshListEvent={this.refreshListEvent}
                    onGetPanelProps={this.getPanelProps}
                    masterTitle={StaticOrganizationResources.ExternalLocationPanel.Title}
                />
            </SingleLayout>
        );
    }
}

export default connect(
    ExternalLocationListPanel,
    new DependencyAdapter<IExternalLocationListPanelProps, IExternalLocationListPanelDependencies>(c => ({
        worklistApiAdapter: c.resolve("WorklistApiAdapter")
    }))
);
