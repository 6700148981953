import Action from "./Action";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";

export default class UseCaseNavigationAction extends Action {
    constructor(
        public readonly useCase: UseCaseIdentifier,
        public readonly useCaseArguments: UseCaseArgument[],
        public readonly displayMode: UseCaseDisplayMode,
        public readonly shouldRefresh: boolean = false
    ) {
        super();
    }
}
