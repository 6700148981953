import ICareActivityDischargeDataExtensionPointProps from "@PluginInterface/BoundedContexts/Care/PatientRegister/ExtensionPoints/ICareActivityDischargeDataExtensionPointProps";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import Di from "@Di";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

@Di.injectable()
export default class CareActivityFinanceDataExtensionComponentService implements IExtensionComponentService<ICareActivityDischargeDataExtensionPointProps> {

    public isVisible(props: ICareActivityDischargeDataExtensionPointProps) {
        return !isNullOrUndefined(props.extensionData.HunSocialSecurity_CareActivityFinanceData);
    }

    public shouldRerunIsVisible(_props: ICareActivityDischargeDataExtensionPointProps, _prevProps: ICareActivityDischargeDataExtensionPointProps) {
        return true;
    }
}
