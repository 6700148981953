import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import Role from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/Role";
import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";
import RoleDetailView from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/RoleManagement/Detail/RoleDetailView";
import RolesList from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/RoleManagement/Master/RolesList";
import RoleListToolbar from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/RoleManagement/Master/RoleListToolbar";
import SaveButton from "@CommonControls/SaveButton";

interface IRoleManagementPanelViewProps {
    role: Role;
    onSelectedItemChange: (roleId: string) => void;
    isLoading: boolean;
    addNewRole: () => void;
    dataSource: InMemoryDataGridDataSource<Role>;
    saveRoleAsync: () => Promise<void>;
    validateRoleAsync: () => Promise<IClientValidationResult[]>;
    backToPreviousPage: () => void;
    onGetAssignedPermissionCountForRole: (role: Role) => number;
}


const RoleManagementPanelView: React.FC<IRoleManagementPanelViewProps> = (props) => {
    const saveButton = () => {
        return (
            <SaveButton
                onClickAsync={props.saveRoleAsync}
                visualStyle="primary"
                automationId="saveRoleButton"
            />
        );
    };

    return (
        <SingleLayout>
            <MasterDetailLayout
                selectedItem={props.role?.id.value}
                onSelectedItemChange={props.onSelectedItemChange}
                isLoading={props.isLoading}
            >
                <MasterDetail.Master
                    title={StaticAuthorizationResources.RoleManagement.Title}
                    toolbar={(<RoleListToolbar onAddRole={props.addNewRole} />)}
                >
                    <RolesList
                        onGetAssignedPermissionCountForRole={props.onGetAssignedPermissionCountForRole}
                        dataSource={props.dataSource}
                        onBack={props.backToPreviousPage}
                    />
                </MasterDetail.Master>
                <MasterDetail.Detail
                    hasSidePadding
                    title={props.role?.displayName}
                    toolbar={saveButton()}
                >
                    <RoleDetailView
                        onValidateAsync={props.validateRoleAsync}
                        onSaveAsync={props.saveRoleAsync}
                        role={props.role}
                    />
                </MasterDetail.Detail>
            </MasterDetailLayout>
        </SingleLayout>
    );
};

export default State.observer(RoleManagementPanelView);
