import AccessRuleBase from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/AccessRuleBase";
import Permission from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/Permission";
import PermissionAssignmentAccessRule from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/PermissionAssignmentAccessRule";
import PermissionScope from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/PermissionScope";
import RoleAccessRule from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/RoleAccessRule";
import * as Proxy from "@HisPlatform/BoundedContexts/Authorization/Api/Proxy.g";
import Di from "@Di";
import AccessRuleId from "@Primitives/AccessRuleId.g";
import StoreMapper from "@Toolkit/CommonWeb/ApiAdapter/StoreMapper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";

type ResponseType = Proxy.SetAccessRulesCommandResponse | Proxy.GetAccessRulesQueryResponse;

@Di.injectable()
export default class AccessRuleStoreMapper extends StoreMapper<SimpleStore<AccessRuleBase[]>, ResponseType> {
    protected storeEntityIdType = AccessRuleId;
    protected applyToStoreCore(target: SimpleStore<AccessRuleBase[]>, response: ResponseType): void {
        target.value = response.accessRules?.map(applyAccessRules);
    }
}

function applyAccessRules(accessRule: Proxy.AccessRuleBaseDto) {
    let result;
    if (accessRule instanceof Proxy.RoleAssignmentRuleDto) {
        result = new RoleAccessRule(false);
        applyRoleAccessRule(result, accessRule);
    } else if (accessRule instanceof Proxy.PermissionAssignmentRuleDto) {
        result = new PermissionAssignmentAccessRule(false);
        applyAssignmentAccessRule(result, accessRule);
    } else if (accessRule instanceof Proxy.PermissionProhibitionRuleDto) {
        result = new PermissionAssignmentAccessRule(false);
        applyProhibitionAccessRule(result, accessRule);
    }

    return result;
}

function applyRoleAccessRule(target: RoleAccessRule, response: Proxy.RoleAssignmentRuleDto) {
    target.id = response.accessRuleId;
    target.roleId = response.roleId;
    target.assignedScopes = response.assignmentScopes.map(createPermissionScope);
    target.subject = applySubject(response.subject);
    target.rowVersion = response.rowVersion;
}

function applyAssignmentAccessRule(target: PermissionAssignmentAccessRule, response: Proxy.PermissionAssignmentRuleDto) {
    target.id = response.accessRuleId;
    target.subject = applySubject(response.subject);
    target.permissionStore.definitions = response.permissions.map(createPermission);
    target.rowVersion = response.rowVersion;
    target.isProhibition = false;
}

function applyProhibitionAccessRule(target: PermissionAssignmentAccessRule, response: Proxy.PermissionProhibitionRuleDto) {
    target.id = response.accessRuleId;
    target.subject = applySubject(response.subject);
    target.permissionStore.definitions = response.permissions.map(createPermission);
    target.rowVersion = response.rowVersion;
    target.isProhibition = true;
}

function applySubject(subject: Proxy.AccessControlSubjectBaseDto) {
    if (subject instanceof Proxy.UserGroupSubjectDto) {
        return subject.userGroupId;
    } else if (subject instanceof Proxy.UserSubjectDto) {
        return subject.userId;
    }
    throw new Error(`Cannot parse subject ${typeof subject}`);
}

function createPermission(permission: Proxy.Permission) {
    return new Permission(
        [permission.id],
        permission.scopes.map(createPermissionScope)
    );
}

function createPermissionScope(permissionScope: Proxy.PermissionScope) {
    return permissionScope.values!.mode === Proxy.SetSpecificationMode.Positive
        ? PermissionScope.createPositive(permissionScope.type.value, permissionScope.values!.includedItems)
        : PermissionScope.createNegative(permissionScope.type.value, permissionScope.values!.excludedItems);
}
