exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Panel_no-text-selection_1C2JU {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.Panel_inverted_1yMKf {\n  background-color: #7ec9e0;\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "Panel_no-text-selection_1C2JU",
	"noTextSelection": "Panel_no-text-selection_1C2JU",
	"inverted": "Panel_inverted_1yMKf"
};