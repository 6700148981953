import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import PermissionDefinitionBuilder from "@PluginInterface/OperationAccessControl/PermissionDefinitionBuilder";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";
import { PermissionDefinitions } from "@HisPlatform/PermissionDefinitions/PermissionDefinitions.g";

export default function configureAuthorizationPermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry) {
    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.Authorization.DataAccessControl.SetEntityPermissions)
        .withPermissionScopes(
            PermissionDefinitionBuilder.createAllPermissionScope(PermissionScopeType.entityType), 
            PermissionDefinitionBuilder.createAllPermissionScope(PermissionScopeType.entityLocation))
        .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.Authorization.DataAccessControl.OverrideEntityPermissions  )
        .withPermissionScopes(
            PermissionDefinitionBuilder.createAllPermissionScope(PermissionScopeType.entityType), 
            PermissionDefinitionBuilder.createAllPermissionScope(PermissionScopeType.entityLocation))
        .build());

    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(PermissionDefinitions.Authorization.OperationAccessControl.ManagePermissions).build());
    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(PermissionDefinitions.Authorization.OperationAccessControl.EditRoles).build());
}