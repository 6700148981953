import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import IMasterDetailState from "@CommonControls/Layout/IMasterDetailState";
import ICareActivityTextBlockListItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/ICareActivityTextBlockListItem";
import * as Ui from "@CommonControls";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import * as Styles from "./CareActivityTextBlockList.less";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import UserColumn from "@HisPlatformControls/DataGridColumns/UserColumn";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { iconNameType } from "@CommonControls/Icon";

interface ICareActivityTextBlockGridDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface ICareActivityTextBlockGridProps {
    _dependencies?: ICareActivityTextBlockGridDependencies;
    idList: ICareActivityTextBlockListItem[];
    mandatoryTextBlockTypes: TextBlockTypeId[];
    onSelectedRowChange: (newValue: ICareActivityTextBlockListItem) => void;
    onBack?: () => void;
    selectedItem?: ICareActivityTextBlockListItem;
}

@State.observer
class CareActivityTextBlockGrid extends React.Component<ICareActivityTextBlockGridProps> {
    @State.bound
    private setSelectedId(item: ICareActivityTextBlockListItem) {
        if (this.props.onSelectedRowChange) {
            this.props.onSelectedRowChange(item);
        }
    }

    @State.bound
    private onRenderCellValue(value: TextBlockTypeId) {
        if (!value) {
            return "";
        }
        const localizedEnum = this.props._dependencies.careReferenceDataStore.textBlockType.get(value);
        const isMandatory = this.props.mandatoryTextBlockTypes.some(i => ValueWrapper.equals(i, value));
        return (<><b><Ui.Icon iconName={this.getIconName(value)} /></b>{` ${localizedEnum.displayValue.Name}`}{isMandatory && <span className={Styles.requiredStar} />}</>);
    }

    @State.bound
    public getIconName(value: TextBlockTypeId): iconNameType {
        switch (value.value) {
            case TextBlockTypeId.Anamnesis.value:
            case TextBlockTypeId.Decourse.value:
                return "textblock_anamnesis";
            case TextBlockTypeId.Status.value:
            case TextBlockTypeId.TemplatedStatusMale.value:
            case TextBlockTypeId.TemplatedStatusFemale.value:
            case TextBlockTypeId.TemplatedStatusKid.value:
                return "textblock_status";
            case TextBlockTypeId.Epicrisis.value:
                return "textblock_epicrisis";
            case TextBlockTypeId.ResultsOfDiagnosticTests.value:
            case TextBlockTypeId.Therapy.value:
                return "textblock_diagnostic";
            case TextBlockTypeId.ResultsOfLaboratoryTests.value:
                return "textblock_lab";
            case TextBlockTypeId.FamilyMedicalHistory.value:
                return "textblock_family";
            case TextBlockTypeId.RecommendedTherapy.value:
            case TextBlockTypeId.TestResults.value:
                return "textblock_terapy";
            case TextBlockTypeId.MacroscopicExaminationDescription.value:
                return "textblock_macro";
            case TextBlockTypeId.MicroscopicExaminationDescription.value:
                return "textblock_micro";
            default:
                return null;
        }
    }

    public render() {
        return (
            <Ui.DataGrid
                dataSource={this.props.idList}
                selectedRow={this.props.selectedItem}
                onSelectedRowChange={this.setSelectedId}
                rowHeight={48}
                isSelectable
                onBack={this.props.onBack}
                fixedHeight="100%"
                hasBackButton={!!this.props.onBack}
                actionsColumn={false}
                automationId="careActivityTextBlockGrid_dataGrid"
            >
                <DataGridColumn
                    header={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.Columns.TextBlockType}
                    dataGetter={"textBlockTypeId"}
                    onRenderCellValue={this.onRenderCellValue}
                />
                <UserColumn
                    header={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.Columns.LastModifiedBy}
                    dataGetter={"lastModifiedBy"}
                    isVisible={!this.props.selectedItem}
                />
                <DataGridDateTimeColumn
                    header={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.Columns.LastModifiedAt}
                    dataGetter={"lastModifiedAt"}
                    isVisible={!this.props.selectedItem}
                />
            </Ui.DataGrid>
        );
    }
}

export default connect(
    CareActivityTextBlockGrid,
    new DependencyAdapter<ICareActivityTextBlockGridProps, ICareActivityTextBlockGridDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore")
    }))
);