import React from "react";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import * as Ui from "@CommonControls";
import IWidgetComponentProps from "@PluginInterface/Dashboard/IWidgetComponentProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import { ICareActivitiesByInsurerOrganizationDisplayData } from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/CareActivitiesByInsurerOrganizationWidget/ICareActivitiesByInsurerOrganizationDisplayData";
import CareActivitiesByInsurerOrganizationWidgetApiAdapter from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/CareActivitiesByInsurerOrganizationWidget/CareActivitiesByInsurerOrganizationWidgetApiAdapter";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";

interface ICareActivitiesByInsurerOrganizationWidgetDependencies {
    financeReferenceDataStore: FinanceReferenceDataStore;
    applicationContext: ApplicationContext;
    apiAdapter: CareActivitiesByInsurerOrganizationWidgetApiAdapter;
}

interface ICareActivitiesByInsurerOrganizationWidgetProps extends IWidgetComponentProps {
    _dependencies?: ICareActivitiesByInsurerOrganizationWidgetDependencies;
}

/** @screen */
@State.observer
class CareActivitiesByInsurerOrganizationWidget extends React.Component<ICareActivitiesByInsurerOrganizationWidgetProps> {

    private get referenceDataStore() { return this.props._dependencies.financeReferenceDataStore; }
    private get apiAdapter() { return this.props._dependencies.apiAdapter; }

    @State.observable.ref private dateRange: LocalDateRange = null;
    @State.observable.ref private displayData: ICareActivitiesByInsurerOrganizationDisplayData[] = null;
    @State.observable.ref private isErrorState = false;

    @State.action.bound
    private setDateRange(newValue: LocalDateRange) {
        this.dateRange = newValue;
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }

    @State.bound
    private async initializeAsync() {
        try {
            this.setInitialValues();
            await this.initialLoadPanelAsync();
        } catch (err) {
            this.setError();
        }
    }

    @State.action
    private setError() {
        this.isErrorState = true;
    }

    @State.action.bound
    private async loadAsync() {

        if (!isNullOrUndefined(this.props._dependencies!.applicationContext.contextualOrganizationUnitId) && !isNullOrUndefined(this.dateRange)) {
            const response = await this.apiAdapter.getNumberOfFinancedCareActivitiesByOrganizationIdsAsync(
                this.props._dependencies!.applicationContext.contextualOrganizationUnitId,
                this.dateRange.from,
                this.dateRange.to);

            await this.referenceDataStore.insurerOrganizationMap.ensureLoadedAsync(response.value.map(d => d.insurerOrganizationId));

            State.runInAction(() => {
                this.displayData = response.value.map(d => {
                    const organization = this.referenceDataStore.insurerOrganizationMap.get(d.insurerOrganizationId);
                    if (isNullOrUndefined(organization)) {
                        return null;
                    }
                    return {
                        InsurerOrganizationCode: organization.code,
                        InsurerOrganizationName: organization.name,
                        CareActivitiesCount: d.numberOfFinancedCareActivities
                    } as ICareActivitiesByInsurerOrganizationDisplayData;
                });
            });
        }
    }

    @State.action.bound
    private setInitialValues() {
        this.dateRange = new LocalDateRange(LocalDate.createFromMoment(DateTimeService.now().startOf("month")), LocalDate.createFromMoment(DateTimeService.now().endOf("month")));
    }

    @State.bound
    private renderCells() {
        return this.displayData.map(d => {
            return (
                <tr key={d.InsurerOrganizationCode}>
                    <td>{d.InsurerOrganizationName}</td>
                    <td>{d.CareActivitiesCount}</td>
                </tr>
            );
        });
    }

    @State.bound
    private renderWidgetContent() {
        if (isNullOrUndefined(this.displayData) || this.displayData.length === 0) {
            return (
                <div style={{ textAlign: "center" }}>
                    {StaticProductivityResources.Widgets.CareActivitiesByInsurerOrganizationWidget.CareActivitiesNotFoundMessage}
                </div>
            );
        }
        return (
            <Ui.Table
                visualStyle="dark"
                style={{ width: "98%", padding: "3px", margin: "auto", marginTop: "10px" }}
                outerDivStyle={{ margin: "0 4px", marginTop: 2 }}
                automationId="careActivitiesByInsurerOrganizationWidgetContentTable"
            >
                <thead>
                    <tr>
                        <th>{StaticProductivityResources.Widgets.CareActivitiesByInsurerOrganizationWidget.Name}</th>
                        <th>{StaticProductivityResources.Widgets.CareActivitiesByInsurerOrganizationWidget.Count}</th>
                    </tr>
                    {this.displayData && this.renderCells()}
                </thead>
            </Ui.Table>
        );
    }

    private renderFilters() {
        return (
            <Ui.DateRangePicker
                visualMode="dark"
                label={StaticProductivityResources.Widgets.CareActivitiesByInsurerOrganizationWidget.DateRange}
                value={this.dateRange}
                onChange={this.setDateRange}
                clearable={false}
                automationId="careActivitiesByInsurerOrganizationWidgetDateRangePicker"
            />
        );
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return null;
        }

        return (
            <Ui.Dashboard.Widget
                name={this.props.name}
                isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                icon={"chart"}
                isCollapsible
                containerHeight={this.props.configuration.InitialLayout.Height}
                title={StaticProductivityResources.Widgets.CareActivitiesByInsurerOrganizationWidget.Title}
                automationId="CareActivitiesByInsurerOrganizationWidget"
            >
                {this.isErrorState && (
                    <SpanWithIcon
                        visualStyle="warning"
                        iconName="exclamation">{StaticWebAppResources.Common.Messages.ExternalServiceIsNotAvailable}</SpanWithIcon>
                )}
                {!this.isErrorState && this.renderFilters()}
                {!this.isErrorState && this.renderWidgetContent()}
                <State.Reaction inspector={this.getOrganizationUnitId} reaction={this.reload} />
            </Ui.Dashboard.Widget>
        );
    }

    @State.bound
    private getOrganizationUnitId() {
        return this.props._dependencies!.applicationContext.contextualOrganizationUnitId;
    }

    @State.bound
    private reload() {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }
}

export default connect(
    withLoadingBoundary(CareActivitiesByInsurerOrganizationWidget),
    new DependencyAdapter<ICareActivitiesByInsurerOrganizationWidgetProps, ICareActivitiesByInsurerOrganizationWidgetDependencies>(c => ({
        financeReferenceDataStore: c.resolve("FinanceReferenceDataStore"),
        applicationContext: c.resolve("ApplicationContext"),
        apiAdapter: c.resolve("CareActivitiesByInsurerOrganizationWidgetApiAdapter")
    }))
);