import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import React, { useMemo } from "react";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import { ScreenStateContextAdapter, ScreenStateContextStore } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import { provideCareActivityTextBlockScreenStore, useCareActivityTextBlockScreenStore } from "@HisPlatform/Packages/Care/Screens/CareActivityTextBlockScreen/CareActivityTextBlockScreenStoreProvider";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ShowCareActivityTextBlockScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityTextBlockScreenAction.g";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import DocumentEditorStyles from "@CommonControls/DocumentEditor/DocumentEditor.less";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";

export interface ICareActivityTextBlockScreenProps extends IScreenPropsBase<ShowCareActivityTextBlockScreenAction> {
    _screenState?: ScreenStateContextStore;
    _modalService?: IModalService;
    _careActivityState?: CareActivityState;
}

function CareActivityTextBlockScreen(props: ICareActivityTextBlockScreenProps) {
    const store = useCareActivityTextBlockScreenStore();

    const iconName = useMemo(() => {
        switch (store.textBlockTypeId?.value) {
            case TextBlockTypeId.Anamnesis.value:
                return "textblock_anamnesis";
            case TextBlockTypeId.Status.value:
            case TextBlockTypeId.TemplatedStatusMale.value:
            case TextBlockTypeId.TemplatedStatusFemale.value:
            case TextBlockTypeId.TemplatedStatusKid.value:
                return "textblock_status";
            case TextBlockTypeId.Epicrisis.value:
                return "textblock_epicrisis";
            case TextBlockTypeId.ResultsOfDiagnosticTests.value:
                return "textblock_diagnostic";
            case TextBlockTypeId.ResultsOfLaboratoryTests.value:
                return "textblock_lab";
            case TextBlockTypeId.FamilyMedicalHistory.value:
                return "textblock_family";
            case TextBlockTypeId.RecommendedTherapy.value:
                return "textblock_terapy";
            case TextBlockTypeId.MacroscopicExaminationDescription.value:
                return "textblock_macro";
            case TextBlockTypeId.MicroscopicExaminationDescription.value:
                return "textblock_micro";
            default:
                return null;
        }
    }, [store.textBlockTypeId]);

    return (
        <Screen
            title={store.screenTitle}
            subTitle={store.screenSubtitle}
            iconName={iconName}
            toolbar={(
                <>
                    {props._screenState.displayMode !== ScreenDisplayMode.Detail && (
                        <Ui.Button text={StaticWebAppResources.Common.Button.Back} onClick={props._screenState.cancelled} automationId="__cancel" />
                    )}
                    {store.textBlock?.isNew === false && <Ui.ChangeHistoryButton
                            text={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.ChangeHistory}
                            onClick={store.showTextBlockHistoryDialogAsync}
                            automationId="openHistoryModalButton"
                    />}
                    {store.canAcquireLock && <HisUi.LockIndicatorComponent
                        permissionCheckOperationNames="SaveTextBlock"
                        locked={store.textBlock?.isMutable !== true || store.textBlock?.isNew === true}
                        onEditClickedAsync={store.onForceReleaseLockAsync}
                        lockedBy={store.textBlock?.lockInfo?.preventingLockUserId}
                    />}
                    <ReadOnlyContext.Provider value={store.vIsReadOnly}>
                        <Ui.SaveButton
                            permissionCheckOperationNames="SaveTextBlock"
                            permissionDeniedStyle="disabled"
                            onClickAsync={store.saveWithoutReleaseLockAsync}
                            automationId="saveButton"
                        />
                    </ReadOnlyContext.Provider>
                </>
            )}>
            <Ui.FieldValidationResult problems={store.validationProblems} />
            {store.textBlock && (
                <HisUi.HisDocumentEditor
                    mode="TextBlock"
                    contentStore={store.textBlock.contentStore.forEditor}
                    isReadOnly={store.vIsReadOnly}
                    temporaryDataId={store.temporaryDataId}
                    successfullySavedEvent={store.successfullySavedEvent}
                    leaveWithoutSavingEvent={store.leaveWithoutSavingEvent}
                    customToolbarItems={
                        <>
                            <Ui.Flex.Item className={DocumentEditorStyles.column}>
                                <Ui.Button
                                    iconName="stethoscope"
                                    onClickAsync={store.showServiceRequestInsertDialogAsync}
                                    size="compact"
                                    visualStyle="slate"
                                    tooltipContent={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.InsertServiceRequestResult.ModalTitle}
                                    tooltipPosition="bottom"
                                    automationId="openServiceRequestInsertDialogButton" />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item className={DocumentEditorStyles.column}>
                                <Ui.Button
                                    iconName="status"
                                    onClickAsync={store.showSnippetSelectorDialogAsync}
                                    size="compact"
                                    visualStyle="slate"
                                    tooltipContent={StaticDocumentManagementResources.DocumentSnippetSelectorModal.Buttons.DocumentSnippetSelectorModal}
                                    tooltipPosition="bottom"
                                    automationId="openSnippetSelectorModalButton" />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item className={DocumentEditorStyles.column}>
                                <Ui.Button
                                    iconName="clock"
                                    onClickAsync={store.showPreviousTextBlocksDialogAsync}
                                    size="compact"
                                    visualStyle="slate"
                                    tooltipContent={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.PreviousCareActivityTextBlocks}
                                    tooltipPosition="bottom"
                                    automationId="appendFragmentButton" />
                            </Ui.Flex.Item>
                        </>
                    }
                />
            )}
            <NavigateAwayHook isEnabled onNavigateAwayAsync={store.navigateAwayAsync} />
        </Screen>
    );
}

export default connect<ICareActivityTextBlockScreenProps>(
    provideCareActivityTextBlockScreenStore(CareActivityTextBlockScreen),
    new CareActivityContextAdapter<ICareActivityTextBlockScreenProps>(c => ({
        _careActivityState: c.careActivity?.state
    })),
    new ScreenStateContextAdapter(),
    new HisModalServiceAdapter()
);