import Di from "@Di";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import ShowPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientScreenAction.g";
import IHungarianPatientDefaultData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/PatientRegister/Model/IHungarianPatientDefaultData";
import WellKnownReferenceCodes from "@HunSocialSecurityPlugin/Common/WellKnownReferenceCodes";
import PatientDocumentTypeId from "@Primitives/PatientDocumentTypeId.g";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import BooleanFormFieldData from "@Toolkit/FormEngine/Model/Data/BooleanFormFieldData";
import CompositeArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/CompositeArrayFormFieldData";
import CompositeFormFieldData from "@Toolkit/FormEngine/Model/Data/CompositeFormFieldData";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import ReferencedExtensibleEnumFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedExtensibleEnumFormFieldData";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";
import CompositeFormDataElement from "@Toolkit/FormEngine/Model/Schema/CompositeFormDataElement";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import { getCompositeArrayFields, getEmptyFormFieldDataFor, getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import CountryId from "@Primitives/CountryId.g";

@Di.injectable()
class PatientAdministrativeDataHunEHealthInfrastructureDocumentFormLogic implements IFormLogic {

    private hungarianPatientDefaultData: IHungarianPatientDefaultData | null = null;

    constructor(
        @Di.inject("EhiCareApiAdapter") private readonly ehiCareApiAdapter: EhiCareApiAdapter,
        @Di.inject("CareReferenceDataStore") private readonly careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("CommonReferenceDataDataStore") private readonly commonReferenceDataStore: CommonReferenceDataDataStore,
        @Di.inject("FinanceReferenceDataStore") private readonly financeReferenceDataStore: FinanceReferenceDataStore,
        @Di.inject("HunEhiCareReferenceDataStore") private readonly hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore
    ) { }

    public async executeAsync(form: IForm, showScreenAction?: ShowScreenFrontendActionBase, dataElements?: FormDataElementBase[]): Promise<void> {

        const formData = form.data.Content;
        await this.hunEhiCareReferenceDataStore.euCardReplacementTypeMap.ensureLoadedAsync();
        const documents = getField<CompositeArrayFormFieldData>(formData, "Documents");
        const firstDocument = getCompositeArrayFields(formData, "Documents[0]");
        const baseData = getField<CompositeFormFieldData>(formData, "BaseData");
        const contactInfo = getField<CompositeFormFieldData>(formData, "ContactInfo");
        const addresses = getField<CompositeArrayFormFieldData>(contactInfo.value, "Addresses");
        const insurances = getField<CompositeArrayFormFieldData>(formData, "Insurances");

        for (let i = 0; i < documents.value.length; i++) {
            const documentTypeId = getField<ReferencedExtensibleEnumFormFieldData>(formData, `Main.Documents[${i}].DocumentTypeId`);

            if (!documentTypeId?.value) {
                continue;
            }

            const documentType = await this.careReferenceDataStore.patientDocumentType.getOrLoadAsync(new PatientDocumentTypeId(documentTypeId.value.toString()));

            if (documentType.identifierSystemId.value === "EuCardReplacement") {
                const insuranceNumberField = getField<TextFormFieldData>(formData, `Main.Documents[${i}].InsuranceNumber`);
                const insuranceCountryIdField = getField<ReferencedEntityFormFieldData>(baseData.value, "InsuranceCountryId");
                const identifierField = getField<TextFormFieldData>(formData, `Main.Documents[${i}].IdentifierValue`);

                if (!isNullOrUndefined(insuranceNumberField.value) && !isNullOrUndefined(insuranceCountryIdField.value)) {
                    const countryISO = this.commonReferenceDataStore.countryMap.get(new CountryId(insuranceCountryIdField.value.toString())).isoAlpha3;

                    State.runInAction(() => {
                        identifierField.value = countryISO + insuranceNumberField.value;
                    });
                }
            }

            if (documentType.identifierSystemId.value === "EuCard") {
                const insurerOrganizationIdField = getField<ReferencedExtensibleEnumFormFieldData>(formData, `Main.Documents[${i}].InsurerOrganizationId`);
                if (isNullOrUndefined(insurerOrganizationIdField.value)) {
                    await this.financeReferenceDataStore.insurerOrganizationMap.ensureAllLoadedAsync();
                    const insurerOrganization = this.financeReferenceDataStore.insurerOrganizationMap.items.find(i => i.code === WellKnownReferenceCodes.HunSocialSecurityInsuranceCode);
                    State.runInAction(() => insurerOrganizationIdField.value = insurerOrganization?.id?.value);
                }
            }
        }

        this.hungarianPatientDefaultData ??= (await this.ehiCareApiAdapter.getHungarianPatientDefaultDataAsync()).value;

        for (let i = 0; i < documents.value.length; i++) {
            const document = getCompositeArrayFields(formData, `Documents[${i}]`);
            const identifierValue = getField<TextFormFieldData>(formData, `Documents[${i}].IdentifierValue`);
            const documentTypeId = getField<ReferencedEntityFormFieldData>(formData, `Documents[${i}].DocumentTypeId`);

            if (!this.isCdvValid(identifierValue?.value) || documentTypeId?.value) {
                continue;
            }

            await this.setPatientDocumentAsync(document, this.hungarianPatientDefaultData);
        }

        const isDataCleansingNeededField = getField<BooleanFormFieldData>(formData, "IsDataCleansingNeeded");

        if (!isDataCleansingNeededField?.value && showScreenAction instanceof ShowPatientScreenAction) {
            return;
        }

        const isHunFirstRunField = getField<BooleanFormFieldData>(formData, "IsHunFirstRun");

        if (isHunFirstRunField?.value || isNullOrUndefined(firstDocument)) {
            return;
        }

        State.runInAction(() => {
            const citizenshipCountryId = getField<ReferencedEntityFormFieldData>(baseData.value, "CitizenshipCountryId");
            citizenshipCountryId.value = parseInt(this.hungarianPatientDefaultData.countryId.value);

            const insuranceCountryId = getField<ReferencedEntityFormFieldData>(baseData.value, "InsuranceCountryId");
            insuranceCountryId.value = parseInt(this.hungarianPatientDefaultData.countryId.value);

            const birthCountryId = getField<ReferencedEntityFormFieldData>(baseData.value, "BirthCountryId");
            birthCountryId.value = parseInt(this.hungarianPatientDefaultData.countryId.value);

            if (!isNullOrUndefined(this.hungarianPatientDefaultData.addressTypeId) && !isNullOrUndefined(this.hungarianPatientDefaultData.countryId)) {
                if (addresses.value.length === 0) {
                    const contactInfoDataElement: CompositeFormDataElement = dataElements.find(de => de.name === "ContactInfo") as any;
                    const addressesDataElement: CompositeFormDataElement = contactInfoDataElement.formDataElements.find(de => de.name === "Addresses") as any;
                    const address: IObservableArray<FormFieldDataBase> = State.createObservableShallowArray([]);
                    getEmptyFormFieldDataFor(addressesDataElement.formDataElements, address);
                    addresses.value.push(address);
                }
                const firstAddress = getCompositeArrayFields(contactInfo.value, "Addresses[0]");

                const addressTypeId = getField<ReferencedExtensibleEnumFormFieldData>(firstAddress, "AddressTypeId");
                addressTypeId.value = this.hungarianPatientDefaultData.addressTypeId.value;

                const countryId = getField<ReferencedEntityFormFieldData>(firstAddress, "CountryId");
                countryId.value = parseInt(this.hungarianPatientDefaultData.countryId.value);
            }

            if (!isNullOrUndefined(this.hungarianPatientDefaultData.insurerOrganizationId)) {
                if (insurances.value.length === 0) {
                    const dataElement: CompositeFormDataElement = dataElements.find(de => de.name === "Insurances") as any;
                    const insurance: IObservableArray<FormFieldDataBase> = State.createObservableShallowArray([]);
                    getEmptyFormFieldDataFor(dataElement.formDataElements, insurance);
                    insurances.value.push(insurance);
                }

                const firstInsurance = getCompositeArrayFields(formData, "Insurances[0]");

                const insurerOrganizationId = getField<ReferencedEntityFormFieldData>(firstInsurance, "InsurerOrganizationId");
                insurerOrganizationId.value = parseInt(this.hungarianPatientDefaultData.insurerOrganizationId.value);

                const isDefaultField = getField<BooleanFormFieldData>(firstInsurance, "IsDefault");
                isDefaultField.value = true;

                const isActiveField = getField<BooleanFormFieldData>(firstInsurance, "IsActive");
                isActiveField.value = true;
            }

            isHunFirstRunField.value = true;
        });
    }

    private isCdvValid(cdvString: string) {
        const preferredLength = 9;
        const cleanedString = cdvString?.replace(/ /gi, "");

        if (isNullOrEmptyString(cleanedString) || !cleanedString.match(/^\d{9}$/)) {
            return false;
        }

        const digitList = cleanedString.split("").map(x => parseInt(x, 10));

        let sumValue = 0;

        for (let i = 0; i < preferredLength - 1; i++) {
            sumValue += i % 2 === 0 ? digitList[i] * 3 : digitList[i] * 7;
        }

        return sumValue % 10 === digitList[preferredLength - 1];
    }

    private async setPatientDocumentAsync(formData: IObservableArray<FormFieldDataBase>, defaultData: IHungarianPatientDefaultData) {
        if (!isNullOrUndefined(defaultData.patientDocumentTypeId)) {
            await this.financeReferenceDataStore.insurerOrganizationMap.ensureAllLoadedAsync();

            State.runInAction(() => {
                const documentTypeId = getField<ReferencedEntityFormFieldData>(formData, "DocumentTypeId");
                documentTypeId.value = parseInt(defaultData.patientDocumentTypeId.value);

                const insurerOrganization = this.financeReferenceDataStore.insurerOrganizationMap.items.find(i => i.code === WellKnownReferenceCodes.HunSocialSecurityInsuranceCode);

                const insurerOrganizationId = getField<ReferencedEntityFormFieldData>(formData, "InsurerOrganizationId");
                insurerOrganizationId.value = parseInt(insurerOrganization?.id?.value);
            });
        }
    }
}

export default PatientAdministrativeDataHunEHealthInfrastructureDocumentFormLogic;