exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InfoBox_no-text-selection_2Nhgg {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.InfoBox_container_21RZc {\n  border: 1px solid #00b4ec;\n  background: #F3FBFE;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin: 5px 4px;\n}\n.InfoBox_dark-mode_384of {\n  border: unset;\n  background: unset;\n}\n.InfoBox_dark-mode_384of .InfoBox_text_1Eetc {\n  color: #DFE1E3;\n}\n.InfoBox_dark-mode_384of .InfoBox_container_21RZc {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin: 5px 4px;\n}\n.InfoBox_icon_c5g4i {\n  padding: 0 10px 0 7px;\n}\n.InfoBox_text_1Eetc {\n  flex: 1;\n  color: #00b4ec;\n  width: 100%;\n  text-align: left;\n  padding: 10px 10px 10px 0;\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "InfoBox_no-text-selection_2Nhgg",
	"noTextSelection": "InfoBox_no-text-selection_2Nhgg",
	"container": "InfoBox_container_21RZc",
	"dark-mode": "InfoBox_dark-mode_384of",
	"darkMode": "InfoBox_dark-mode_384of",
	"text": "InfoBox_text_1Eetc",
	"icon": "InfoBox_icon_c5g4i"
};