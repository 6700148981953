import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import HunFinanceReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/Model/HunFinanceReferenceDataStore";

interface IAccidentTypeSelectBoxDependencies {
    financeReferenceDataStore: HunFinanceReferenceDataStore;
}

interface IAccidentTypeSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IAccidentTypeSelectBoxDependencies;
}

@State.observer
class AccidentTypeSelectBox extends React.Component<IAccidentTypeSelectBoxProps> {

    private get dependencies() {
        return this.props._dependencies;
    }

    private get extensibleEnumStore() {
        return this.dependencies.financeReferenceDataStore.accidentTypeMap;
    }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    AccidentTypeSelectBox,
    new DependencyAdapter<IAccidentTypeSelectBoxProps, IAccidentTypeSelectBoxDependencies>(container => {
        return {
            financeReferenceDataStore: container.resolve("HunFinanceReferenceDataStore")
        };
    })
);