exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PatientCareActivity_care-activity-container_15wbH {\n  background-color: rgba(0, 0, 0, 0.08);\n  line-height: 1.2;\n  overflow-y: hidden;\n  margin: 2px 0;\n  padding-right: 4px;\n}\n.PatientCareActivity_source-rectangle_3G_X0 {\n  border-radius: 2px;\n  background-color: #82d4ed;\n  width: fit-content;\n  padding-left: 4px;\n  padding-right: 4px;\n}\n.PatientCareActivity_container_jZxfh {\n  position: relative;\n  overflow: hidden;\n}\n.PatientCareActivity_status-box_1kKpw {\n  position: absolute;\n  top: 14px;\n  left: 14px;\n}\n.PatientCareActivity_status-box_1kKpw > div {\n  z-index: 1;\n  display: block;\n  position: relative;\n}\n.PatientCareActivity_status-box_1kKpw:before {\n  content: \"\";\n  position: absolute;\n  z-index: 0;\n  top: -14px;\n  bottom: 0;\n  left: 48%;\n  border-left: 1px dashed #f4f7fc;\n  display: inline-block;\n}\n.PatientCareActivity_status-box_1kKpw:after {\n  content: \"\";\n  position: absolute;\n  z-index: 0;\n  top: 8px;\n  bottom: 0px;\n  padding-bottom: 1000px;\n  left: 48%;\n  border-left: 1px dashed #f4f7fc;\n  display: inline-block;\n}\n.PatientCareActivity_state-svg-line_OdZRn {\n  stroke: white;\n  stroke-width: 2;\n  stroke-dasharray: 5;\n}\n.PatientCareActivity_flex-padding_JDXkG {\n  margin-right: 6px;\n  padding-top: 10px;\n  font-size: 15px;\n  line-height: 9px;\n}\n.PatientCareActivity_button-padding_2CTXg {\n  padding-right: 4px;\n  top: -4px;\n  position: relative;\n}\n.PatientCareActivity_source-container_2goDs {\n  margin-top: 0;\n}\n.PatientCareActivity_type-icon_3xLVM {\n  padding-left: 4px;\n  display: inline-block;\n  position: relative;\n  top: 4px;\n}\n.PatientCareActivity_type-icon_3xLVM svg {\n  background-color: #e8ebf2;\n  fill: #0082AA;\n  width: 18px;\n  height: 18px;\n}\n.PatientCareActivity_tooltip-position_1SF0x {\n  position: absolute;\n}\n.PatientCareActivity_diagnosis-name_3ugtY {\n  font-family: PragatiNarrowBold;\n  margin: 0;\n  line-height: 1.1;\n}\n", ""]);

// exports
exports.locals = {
	"care-activity-container": "PatientCareActivity_care-activity-container_15wbH",
	"careActivityContainer": "PatientCareActivity_care-activity-container_15wbH",
	"source-rectangle": "PatientCareActivity_source-rectangle_3G_X0",
	"sourceRectangle": "PatientCareActivity_source-rectangle_3G_X0",
	"container": "PatientCareActivity_container_jZxfh",
	"status-box": "PatientCareActivity_status-box_1kKpw",
	"statusBox": "PatientCareActivity_status-box_1kKpw",
	"state-svg-line": "PatientCareActivity_state-svg-line_OdZRn",
	"stateSvgLine": "PatientCareActivity_state-svg-line_OdZRn",
	"flex-padding": "PatientCareActivity_flex-padding_JDXkG",
	"flexPadding": "PatientCareActivity_flex-padding_JDXkG",
	"button-padding": "PatientCareActivity_button-padding_2CTXg",
	"buttonPadding": "PatientCareActivity_button-padding_2CTXg",
	"source-container": "PatientCareActivity_source-container_2goDs",
	"sourceContainer": "PatientCareActivity_source-container_2goDs",
	"type-icon": "PatientCareActivity_type-icon_3xLVM",
	"typeIcon": "PatientCareActivity_type-icon_3xLVM",
	"tooltip-position": "PatientCareActivity_tooltip-position_1SF0x",
	"tooltipPosition": "PatientCareActivity_tooltip-position_1SF0x",
	"diagnosis-name": "PatientCareActivity_diagnosis-name_3ugtY",
	"diagnosisName": "PatientCareActivity_diagnosis-name_3ugtY"
};