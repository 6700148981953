import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import { EMedicationDosage, TextualEMedicationDosage, FrequencyBasedEMedicationDosage } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/EMedicationDosage";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";

enum EMedicationDosageType {
    Textual,
    FrequencyBased
}

interface IEMedicationDosageSelectorProps {
    value: EMedicationDosage;
    onChange: (newValue: EMedicationDosage) => void;
    automationIdPrefix?: string;
}

/** @screen */
@State.observer
export default class EMedicationDosageSelector extends React.Component<IEMedicationDosageSelectorProps> {

    @State.observable public type: EMedicationDosageType = EMedicationDosageType.Textual;
    
    @State.action.bound
    public setType(type: EMedicationDosageType) {
        this.type = type;
        this.replaceDosageIfNecessary(type);
    }

    @State.bound
    private replaceDosageIfNecessary(newType: EMedicationDosageType) {
        if (newType === EMedicationDosageType.Textual && !(this.props.value instanceof TextualEMedicationDosage)) {
            this.props.onChange(new TextualEMedicationDosage());
        } else if (newType === EMedicationDosageType.FrequencyBased && !(this.props.value instanceof FrequencyBasedEMedicationDosage)) {
            this.props.onChange(new FrequencyBasedEMedicationDosage());
        }
    }

    @State.bound
    private setTypeBasedOnProps() {
        if (this.props.value instanceof TextualEMedicationDosage) {
            this.setType(EMedicationDosageType.Textual);
        } else if (this.props.value instanceof FrequencyBasedEMedicationDosage) {
            this.setType(EMedicationDosageType.FrequencyBased);
        }
    }

    public componentDidMount() {
        this.setTypeBasedOnProps();
    }

    public componentDidUpdate() {
        this.setTypeBasedOnProps();
    }

    public render() {
        const prefix = this.props.automationIdPrefix || "";
        const resources = StaticHunSocialSecurityMedicationRequestResources.EMedicationDosageEditor;
        return (
            <Ui.GroupBox title={resources.Title}>
                <ValidationBoundary pathPrefix="Dosage">
                    <Ui.Flex>
                        <Ui.UniversalEnumSelector
                            displayMode="groupedRadioButtons"
                            enumName={"EMedicationDosageType"}
                            enumOrigin="server"
                            value={this.type}
                            onChange={this.setType}
                            enumType={EMedicationDosageType}
                            automationId={prefix + "eMedicationDosageTypeSelector"}
                        />
                    </Ui.Flex>
                    <Ui.Flex>
                        {this.props.value instanceof TextualEMedicationDosage &&
                            <Ui.Flex.Item xs={12}>
                                <Ui.TextBox
                                    value={this.props.value.instruction}
                                    onChange={this.props.value.setInstruction}
                                    label={resources.Instruction}
                                    automationId={prefix + "eMedicationDosageInstructionTextbox"}
                                    propertyIdentifier="Instruction"
                                />
                            </Ui.Flex.Item>
                        }
                        {this.props.value instanceof FrequencyBasedEMedicationDosage &&
                            <>
                                <Ui.Flex.Item xs={4}>
                                    <Ui.TextBox
                                        value={this.props.value.frequency}
                                        onChange={this.props.value.setFrequency}
                                        label={resources.Frequency}
                                        automationId={prefix + "eMedicationDosageFrequencyTextbox"}
                                        propertyIdentifier="Frequency"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <Ui.TextBox
                                        value={this.props.value.amount}
                                        onChange={this.props.value.setAmount}
                                        label={resources.Amount}
                                        automationId={prefix + "eMedicationDosageAmountTextbox"}
                                        propertyIdentifier="Amount"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <Ui.TextBox
                                        value={this.props.value.unit}
                                        onChange={this.props.value.setUnit}
                                        label={resources.Unit}
                                        automationId={prefix + "eMedicationDosageUnit"}
                                        propertyIdentifier="Unit"
                                    />
                                </Ui.Flex.Item>
                            </>
                        }
                    </Ui.Flex>
                </ValidationBoundary>
            </Ui.GroupBox>
        );
    }
}