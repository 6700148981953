import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import standaloneRouteDefinitions from "./PractitionerRecommendationRoutes";
import PractitionerRecommendationStandaloneHost from "./PractitionerRecommendationStandaloneHost";

export default UseCaseDescriptorBuilder.create(
    HunEHealthInfrastructureUseCases.practitionerRecommendations,
    builder => {
        builder.standalone(b => b
            .routeDefinition(standaloneRouteDefinitions.practitionerRecommendations)
            .component(PractitionerRecommendationStandaloneHost));
    }, false
);