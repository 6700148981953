import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import { PageBoxContainer } from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { HisErrorBoundary } from "@HisPlatformControls";
import PropertyGroupsPanel from "@HisPlatform/BoundedContexts/Configuration/Components/Panels/PropertyGroupsPanel/PropertyGroupsPanel";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";


interface IConfigurationEditorPageDependencies {

}

interface IConfigurationEditorPageProps extends IRoutingFrameContentProps {
    _dependencies?: IConfigurationEditorPageDependencies;
}

@State.observer
class ConfigurationEditorPage extends React.Component<IConfigurationEditorPageProps> {

    @State.bound
    public back() {
        this.props.routingController.goBack();
    }

    public render() {
        return (
            <PageBoxContainer>
                <HisErrorBoundary>
                    <LoadingBoundary>
                        <PropertyGroupsPanel
                            onCancel={this.back}
                        />
                    </LoadingBoundary>
                </HisErrorBoundary>
            </PageBoxContainer>
        );
    }
}

export default ConfigurationEditorPage;