import React from "react";
import * as Ui from "@CommonControls";
import PrescriptionStatus from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/PrescriptionStatus.g";

interface IPrescriptionStatusSelectorProps {
    value: PrescriptionStatus[],
    onChange: (newValue: PrescriptionStatus[]) => void;
    label: string
}

export default class PrescriptionStatusSelector extends React.Component<IPrescriptionStatusSelectorProps> {
    public render() {
        return (
            <Ui.UniversalEnumSelector
                enumName={"PrescriptionStatus"}
                enumOrigin="server"
                value={this.props.value}
                onChange={this.props.onChange}
                enumType={PrescriptionStatus}
                displayMode="selectBox"
                label={this.props.label}
                multiSelect
                automationId="prescriptionStatusSelector"
            />
        );
    }
}