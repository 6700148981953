import React from "react";
import { ContextAwareModal } from "@HisPlatformControls";
import Modal from "@CommonControls/Modal";
import DocumentKindSelector from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Controls/DocumentKindSelector/DocumentKindSelector";
import NewBinaryDocumentPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/CreateNewDocumentDialog/BinaryDocument/NewBinaryDocumentPanel";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import NewTemplateBasedDocumentPanel from "./TemplateBasedDocument/NewTemplateBasedDocumentPanel";
import { ICreateNewDocumentDialogResult } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/CreateNewDocumentDialog/CreateNewDocumentDialogParams";
import DocumentKind from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentKind.g";
import PatientId from "@Primitives/PatientId.g";

interface ICreateNewDocumentDialogViewProps {
    kind: DocumentKind;
    onSuccess: (dialogResult: ICreateNewDocumentDialogResult) => void;
    onClose: () => void;
    onKindChange: (value: DocumentKind) => void;
    scope?: string;
    patientId?: PatientId;
}


const CreateNewDocumentDialogView: React.FC<ICreateNewDocumentDialogViewProps> = props => {

    const modalResources = StaticDocumentManagementResources.DocumentManagementMasterDetailPanel.CreateNewDocumentDialog;
    
    return (
        <ContextAwareModal isOpen onClose={props.onClose} size="compact" title={modalResources.Title}>
            <Modal.Body>
                <DocumentKindSelector
                    value={props.kind}
                    onChange={props.onKindChange}
                    automationId="documentKindRadioButtonGroup"
                />
            </Modal.Body>
            {props.kind === DocumentKind.Binary && (
                <NewBinaryDocumentPanel
                    onCancel={props.onClose}
                    onSuccess={props.onSuccess}
                    scope={props.scope}
                    patientId={props.patientId}
                />
            )}
            {props.kind === DocumentKind.TemplateBased && (
                <NewTemplateBasedDocumentPanel
                    onCancel={props.onClose}
                    onSuccess={props.onSuccess}
                    scope={props.scope}
                />
            )}
        </ContextAwareModal>
    );
};

export default CreateNewDocumentDialogView;