import React from "react";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import {getEntryNames} from "@Toolkit/CommonWeb/EnumHelpers";
import SelectBox from "@CommonControls/SelectBox";
import AnyRadioButtonGroup from "@CommonControls/RadioButtonGroup";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ISelectBoxGroup from "@CommonControls/SelectBox/ISelectBoxGroup";
import { CheckBoxGroupFactory } from "@CommonControls/CheckBox/CheckBoxGroup";
import CheckBoxGroupItemStore from "@CommonControls/CheckBox/CheckBoxGroupItemStore";
import { IButtonProps } from "@CommonControls/Button";
import CheckBoxGroupStore from "@CommonControls/CheckBox/CheckBoxGroupStore";
import ILocalizedObject from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalization";

interface IUniversalEnumSelectorDependencies {
    localizationService: ILocalizationService;
}

interface IUniversalEnumSelectorProps extends IUniversalEnumSelectorBaseProps {
    _dependencies?: IUniversalEnumSelectorDependencies;
    enumOrigin: "client" | "server";
    enumName?: string;
    enumType?: any;
    isMultiEnum?: boolean;
    enums?: Array<{ enumName: string, displayName: string, enumType: any }>;
    boundedContextNameForLocalization?: string;
}

class UniversalEnumSelector extends React.Component<IUniversalEnumSelectorProps> {

    public static defaultProps: Partial<IUniversalEnumSelectorProps> = {
        direction: "row",
        displayMode: "selectBox",
        displayValue: "name"
    };

    private get itemMap() {
        const availableKeys = isNullOrUndefined(this.props.availableOptions) ?
            getEntryNames(this.props.enumType) :
            this.props.availableOptions.map(key => this.props.enumType[key]);
        return this.getLocalizedMap(availableKeys, this.props.enumType, this.props.enumName);
    }

    @State.bound
    private getLocalizedMap(availableKeys: string[], enumType: any, enumName: string) {
        return new Map<number, string>(
            availableKeys.map(key => {
                const itemDisplayValue: any = this.getDisplayString(this.localizeEnum(key, enumName));
                const enumValue = enumType[key];

                return [
                    enumValue as number,
                    itemDisplayValue as string
                ];
            }) as [[number, string]]
        );
    }

    @State.bound
    private localizeEnum(key: string, enumName: string) {
        switch (this.props.enumOrigin) {
            case "client":
                return this.props._dependencies.localizationService.localizeClientEnum(key, enumName, this.props.boundedContextNameForLocalization ?? "");
            case "server":
                return this.props._dependencies.localizationService.localizeEnum(key, enumName);
        }
    }

    @State.bound
    private getDisplayString(object: ILocalizedObject) {
        switch (this.props.displayValue) {
            case "name":
                return object.Name;
            case "shorthand":
                return object.Shorthand;
        }
    }

    private get radioButtonTypeName() {
        switch (this.props.displayMode) {
            case "radio":
                return undefined;
            case "radioButtons":
                return "buttons";
            case "groupedRadioButtons":
                return "groupedButtons";
            case "selectBox":
                return null;
        }
        throw new Error("Selected type is not valid!");
    }

    private get checkBoxTypeName() {
        switch (this.props.displayMode) {

            case "checkBox":
                return "check";
            case "checkBoxSwitch":
                return "switch";
            case "checkBoxButton":
                return "toggle-button";
        }
        throw new Error("Selected type is not valid!");
    }

    @State.bound
    private getDisplayValue(item: any) {
        const itemDisplayValue = this.itemMap.get(item);
        return isNullOrUndefined(itemDisplayValue) ? null : itemDisplayValue;
    }

    @State.bound
    private getSelectionState(enumItemValue: number) {
        return this.props.multiSelect ? 
        !isNullOrUndefined(this.props.value) && (this.props.value).some((v: any) => v === enumItemValue) : 
        this.props.value === enumItemValue;
    }

    private get radioButtonItems() {
        return Array.from(this.itemMap.keys());
    }

    @State.computed
    private get checkBoxItems() {
        const items = this.radioButtonItems.map(enumItemValue => {
            return new CheckBoxGroupItemStore<number>(enumItemValue, this.getSelectionState(enumItemValue));
        });
        return new CheckBoxGroupStore(items);
    }

    @State.action.bound
    private onCheckBoxGroupChange(key: string, newSelectionState: boolean) {
        const selectedItems = this.checkBoxItems.selectedValues;
        const changedItem = this.checkBoxItems.get(key).value;

        const newSelectedItem = newSelectionState ? [...selectedItems, changedItem] : [...selectedItems.filter(i => i !== changedItem)];

        this.props.onChange(newSelectedItem);
    }

    @State.computed
    private get getToggleButtonProps() {
        return (value: number) => {
            return {
                ...this.props.checkBoxGroupProps?.toggleButtonProps,
                iconName: !!this.props.getIconName && this.props.getIconName(value)
            } as IButtonProps;
        };
    }

    @State.computed
    private get selectBoxGroupItems() {
        return this.props.enums.map(item => {
            const availableKeys = getEntryNames(item.enumType);
            const localizedMap = this.getLocalizedMap(availableKeys, item.enumType, item.enumName);
            return {
                label: item.displayName,
                options: Array.from(localizedMap.keys()).map(value => ({
                    text: localizedMap.get(value),
                    value: { valueNumber: value, name: item.enumName, getHashCode: new Function(`return '${item.enumName+value}'`) }
                }) as ISelectBoxItem)
            } as ISelectBoxGroup;
        });
    }

    @State.computed
    private get selectBoxItems() {
        return this.props.isMultiEnum
            ? this.selectBoxGroupItems
            : this.radioButtonItems.map(enumItemValue => ({
                value: enumItemValue,
                text: this.itemMap.get(enumItemValue)
            } as ISelectBoxItem<any>));
    }
    public render() {

        switch (this.props.displayMode) {
            case "selectBox":
                return (
                    <SelectBox {...this.props}
                               items={this.selectBoxItems}
                    />
                );
            case "radio":
            case "radioButtons":
            case "groupedRadioButtons":
                if (this.props.multiSelect) {
                    throw new Error("Use check box type display modes with multi item selection!");
                } else {
                    return (
                        <AnyRadioButtonGroup
                            {...this.props}
                            inline={this.props.isInline}
                            items={this.radioButtonItems}
                            displayType={this.radioButtonTypeName}
                            getDisplayValue={this.getDisplayValue}
                            direction={this.props.direction}
                            getIconName={this.props.getIconName}
                        />
                    );
                }
            case "checkBox":
            case "checkBoxSwitch":
            case "checkBoxButton":
                if (!this.props.multiSelect) {
                    throw new Error("Use radio button type display modes with sinlge item selection!");
                } else {
                    
                    const NumberCheckBoxGroup = CheckBoxGroupFactory<number>();
                    return (
                        <NumberCheckBoxGroup
                            {...this.props.checkBoxGroupProps}
                            store={this.checkBoxItems}

                            isRequired={this.props.required}
                            disabled={this.props.disabled}
                            readOnly={this.props.isReadOnly}
                            automationId={this.props.automationId || undefined}
                            onChange={this.onCheckBoxGroupChange}
                            getDisplayValue={this.getDisplayValue}
                            getToggleButtonProps={this.props.displayMode === "checkBoxButton" && this.getToggleButtonProps}
                        
                            label={this.props.label}
                            inline={this.props.isInline}
                        
                            direction={this.props.direction}
                            displayType={this.checkBoxTypeName}
                            verticalAlign={!!this.props.label ? "noPadding" : "inlineInput"}
                        
                            itemCanBeNull={false}
                            stopClickPropagation={false}
                        
                            visualMode={this.props.visualMode === "dark" ? "dark" : "light"}
                        
                            propertyIdentifier={this.props.propertyIdentifier}
                            showMultipleValidationProblems={false}
                        />
                    );
                }
            default:
                throw new Error("Selected type is not valid!");
        }
    }
}

export default connect(
    UniversalEnumSelector,
    new DependencyAdapter<IUniversalEnumSelectorProps, IUniversalEnumSelectorDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
