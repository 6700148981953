import CareActivityId from "@Primitives/CareActivityId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import ActionIdentifier from "@Primitives/ActionIdentifier.g";

export default class PossibleAction {
    constructor(
        public careActivityId: CareActivityId,
        public serviceRequestId: ServiceRequestId,
        public actions: ActionIdentifier[]
    ) {
    }
}