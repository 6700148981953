import Guid from "@Toolkit/CommonWeb/Guid";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default abstract class FormFieldDataBase {
    @State.observable.ref public fieldName: string;
    @State.observable.ref public isReadOnly: boolean;
    @State.observable.ref public isVisible: boolean;
    public Guid: string = Guid.newGuid();

    constructor(fieldName: string, isReadonly: boolean, isVisible: boolean) {
        this.fieldName = fieldName;
        this.isReadOnly = isReadonly;
        this.isVisible = isVisible;
    }

    public abstract isArray: boolean;
}