import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import CareActivityId from "@Primitives/CareActivityId.g";
import PatientId from "@Primitives/PatientId.g";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";

export interface IPreviousTextBlocksDialogParams extends IModalParams {
    textBlockTypeId: TextBlockTypeId;
    careActivityId: CareActivityId;
    patientId: PatientId;
}


export interface IPreviousTextBlocksDialogResult {
}

export default class PreviousTextBlocksDialogParams implements IModalParams {
    public static type = "PreviousTextBlocksDialog";
    public get type() { return PreviousTextBlocksDialogParams.type; }

    constructor(
        public textBlockTypeId: TextBlockTypeId,
        public careActivityId: CareActivityId,
        public patientId: PatientId,
        ) { }
}