import { getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import StandaloneAppointmentRoutes from "@HisPlatform/Components/Pages/AppointmentPages/StandaloneAppointmentRoutes";
import Di from "@Di";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import CommandProcessorBase from "@Toolkit/CommonWeb/CommandProcessing/Definition/CommandProcessorBase";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import CreatePatientAppointmentCommand from "./CreatePatientAppointmentCommand";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";

@Di.injectable()
export default class CreatePatientAppointmentCommandProcessor extends CommandProcessorBase<CreatePatientAppointmentCommand> {
    public get commandType(): string {
        return "CreatePatientAppointmentCommand";
    }

    constructor(
        @Di.inject("GlobalRoutingStore") private readonly _globalRoutingStore: GlobalRoutingStore,
        @Di.inject("SchedulingReferenceDataStore") private readonly _schedulingReferenceDataStore: SchedulingReferenceDataStore,
        @Di.inject("INotificationService") private readonly _notificationService: INotificationService
    ) {
        super();
    }

    /** {@inheritdoc} */
    public async processAsync(command: CreatePatientAppointmentCommand): Promise<void> {
        const useCaseArguments: UseCaseArgument[] = [
            new UseCaseArgument(new AppointmentScheduleEntryId("new"), "appointmentId"),
            new UseCaseArgument("true", "proposeAfterDate")
        ];

        if (!!command.serviceCode) {
            const serviceId = await this.getServiceIdAsync(command.serviceCode);
            if (!serviceId) {
                return;
            }

            useCaseArguments.push(new UseCaseArgument(serviceId, "serviceId"));
        }

        if (!!command.afterDate) {
            useCaseArguments.push(new UseCaseArgument(command.afterDate.toString(), "afterDate"));
        }

        this._globalRoutingStore.push(StandaloneAppointmentRoutes.registeredPatientAppointments.makeRoute(
            {
                careActivityId: command.careActivityId.value,
                patientId: command.patientId.value,
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.newAppointment),
                    UseCaseDisplayMode.MasterDetail,
                    useCaseArguments
                )
            }));
    }

    private async getServiceIdAsync(serviceCode: string): Promise<SchedulingServiceId> {
        if (!serviceCode) {
            return null;
        }

        await this._schedulingReferenceDataStore.schedulingServices.ensureAllLoadedAsync();
        const services = this._schedulingReferenceDataStore.schedulingServices.items
            .filter(item => item.codeValue === serviceCode);
        if (services.length === 0) {
            this._notificationService.error(StaticSchedulingResources.CreatePatientAppointmentCommandProcessor.AppointmentScheduleNotFound);
            return null;
        } else if (services.length > 1) {
            this._notificationService.error(StaticSchedulingResources.CreatePatientAppointmentCommandProcessor.AppointmentScheduleCodeAmbiguous);
            return null;
        }

        return services[0].id;
    }
}