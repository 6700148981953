// tslint:disable:variable-name
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import BillableServicesPanelStore from "./BillableServicesPanelStore";

const { 
    ContextComponent: BillableServicesPanelStoreContext, 
    StoreProvider: BillableServicesPanelStoreProvider, 
    useStore: useBillableServicesPanelStore,
    provideStore: provideBillableServicesPanelStore,
} = createPanelStoreProvider(BillableServicesPanelStore);

export {
    BillableServicesPanelStoreContext,
    BillableServicesPanelStoreProvider,
    useBillableServicesPanelStore,
    provideBillableServicesPanelStore
};