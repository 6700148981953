import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import MedicationInfoPanel, { IMedicationInfoPanelProps } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/MedicationInfoPanel/MedicationInfoPanel";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import MedicationId from "@Primitives/MedicationId.g";
import IUseCaseDescriptor2 from "@PluginInterface/UseCases/IUseCaseDescriptor2";

export default function configureMedicationUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2<IMedicationInfoPanelProps>(
        {
            identifiers: [HunEHealthInfrastructureUseCases.medicationInfo],
            component: MedicationInfoPanel,
            mapUseCaseArgumentsToProps: args => {
                const medicationId = args.find(a => a.value instanceof MedicationId).value;
                return {
                    medicationId: new MedicationId(medicationId.value)
                } as IMedicationInfoPanelProps;
            }
        } as IUseCaseDescriptor2<IMedicationInfoPanelProps>
    );
}
