import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React, { useState } from "react";
import IPatientCareActivitiesTabProps from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabProps";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowReadOnlyDiagnosisScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyDiagnosisScreenAction.g";
import DetachedScreenRenderer from "@HisPlatform/Components/ShowScreenAction/DetachedScreenRenderer";

function WrappedEhrDiagnosisListPanel(props: IPatientCareActivitiesTabProps) {

    const action =
        new ShowReadOnlyDiagnosisScreenAction(
            ScreenDisplayMode.ContentOnly,
            props.careActivityId);

    return (
        <DetachedScreenRenderer primaryAction={action} defaultPrimaryState="null" />
    );
}

export default connect<IPatientCareActivitiesTabProps>(
    WrappedEhrDiagnosisListPanel
);