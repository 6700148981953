
import ImmunizationFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ImmunizationFactory";
import DependencyContainer from "@DiContainer";
import ProcedureStatementFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ProcedureStatement/ProcedureStatementFactory";
import AutonomyDisabilityStatementFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/AutonomyDisabilityStatementFactory";
import ConditionStatementFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/ConditionStatementFactory";
import DeviceUseStatementFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/DeviceUseStatementFactory";
import PregnancyStatementFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/PregnancyStatementFactory";
import MedicationStatementFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/MedicationStatementFactory";
import RiskAssessmentFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/RiskAssessmentFactory";
import PatientAllergyIntoleranceFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceFactory";
import MedicalAlertStatementFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalAlertStatement/MedicalAlertStatementFactory";

DependencyContainer
    .bind("ImmunizationFactory")
    .to(ImmunizationFactory)
    .inSingletonScope();

DependencyContainer
    .bind("ProcedureStatementFactory")
    .to(ProcedureStatementFactory)
    .inSingletonScope();

DependencyContainer
    .bind("AutonomyDisabilityStatementFactory")
    .to(AutonomyDisabilityStatementFactory)
    .inSingletonScope();

DependencyContainer
    .bind("ConditionStatementFactory")
    .to(ConditionStatementFactory)
    .inSingletonScope();

DependencyContainer
    .bind("DeviceUseStatementFactory")
    .to(DeviceUseStatementFactory)
    .inSingletonScope();

DependencyContainer
    .bind("PregnancyStatementFactory")
    .to(PregnancyStatementFactory)
    .inSingletonScope();

DependencyContainer
    .bind("MedicationStatementFactory")
    .to(MedicationStatementFactory)
    .inSingletonScope();

DependencyContainer
    .bind("RiskAssessmentFactory")
    .to(RiskAssessmentFactory)
    .inSingletonScope();

DependencyContainer
    .bind("PatientAllergyIntoleranceFactory")
    .to(PatientAllergyIntoleranceFactory)
    .inSingletonScope();

DependencyContainer
    .bind("MedicalAlertStatementFactory")
    .to(MedicalAlertStatementFactory)
    .inSingletonScope();