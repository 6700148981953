import React from "react";
import * as Ui from "@CommonControls";
import * as Styles from "./PatientCareActivitiesMasterDetailPanel.less";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import PatientId from "@Primitives/PatientId.g";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import CareActivityContextProvider from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextProvider";
import PatientContextProvider from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextProvider";
import DocumentId from "@Primitives/DocumentId.g";
import ViewContextProvider from "@Toolkit/ReactClient/Components/ViewContext/ViewContextProvider";
import IPatientCareActivitiesDataSource from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientCareActivitiesDataSource";
import IPatientCareActivitiesTabRegistry from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabRegistry";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

export interface IPatientsCareActivityDetailViewDependencies {
    patientCareActivitiesTabRegistry: IPatientCareActivitiesTabRegistry;
}

export interface IPatientsCareActivityDetailViewProps {
    _dependencies?: IPatientsCareActivityDetailViewDependencies;
    availableStepTypes: string[];
    activeIndex: number;
    isDetailLoading: boolean;

    onIndexSelected: (index: number) => void;

    patientId: PatientId;
    selectedDocumentContent?: string;

    selectedCareActivity: PatientsCareActivityStore;
    resetSelectedCareActivity: () => void;
    localizeStepTypeName: (type: string) => string;

    selectedDocumentId: DocumentId;
    onSelectedDocumentIdChange: (newId: DocumentId) => void;

    listExtensionData?: any;
    hasScroller?: boolean;

    dataSource: IPatientCareActivitiesDataSource;
}

const renderTitle = (props: IPatientsCareActivityDetailViewProps) => {
    return (
        <Ui.PageBox.Header>
            <Ui.Tab activeIndex={props.activeIndex} onSelectedIndexChange={props.onIndexSelected} visualStyle="pageBox" noContent>
                {props.availableStepTypes.map((type, key) => {
                    return <Ui.TabPane key={key} title={<div className={Styles.headerOption}>{props.localizeStepTypeName(type)}</div>} automationId={type} />;
                })}
            </Ui.Tab>
        </Ui.PageBox.Header>
    );
};

const renderWithPageBox = (props: IPatientsCareActivityDetailViewProps, content: React.ReactNode, noMarginOrGap?: boolean) => {
    return (
        <Ui.PageBox noFadeIn title={renderTitle(props)} isCenterAligned={false} headerNoPadding headerSize="large" noMargin={noMarginOrGap} hasNoVerticalLine widthMode="fullwidth">
            <hr className={Styles.headerLine} />
            <Ui.PageBox.Body hasNoGaps={noMarginOrGap}>
                {content}
            </Ui.PageBox.Body>
        </Ui.PageBox>
    );
};

function getStepRenderer(props: IPatientsCareActivityDetailViewProps, tabs: React.ReactNode[]) {
    return tabs[props.activeIndex];
}

/** @screen */
const PatientsCareActivityDetailView: React.SFC<IPatientsCareActivityDetailViewProps> = (props: IPatientsCareActivityDetailViewProps) => {
    const stepTypeOfActiveIndex = props.availableStepTypes && props.availableStepTypes[props.activeIndex];

    const components = props._dependencies.patientCareActivitiesTabRegistry.getAllComponents(props.selectedCareActivity.careActivityId, 
        props.selectedDocumentId, 
        props.selectedCareActivity.beginningOfCare, 
        props.selectedCareActivity.dischargedAt,
        true);

    const tabs: React.ReactNode[] = [];
    props.availableStepTypes.map(type => {
        tabs.push(renderWithPageBox(props, components.find(i => i.tabName === type).content));
    });

    if (props.isDetailLoading) {
        return <></>;
    }
    return (
        <>
            {props.selectedCareActivity.careActivitySource && props.selectedCareActivity.careActivitySource.value === "InHouse" &&
                <>
                    <CareActivityContextProvider careActivityId={props.selectedCareActivity.careActivityId}>
                        <PatientContextProvider>
                            <ViewContextProvider totalHeightLoss={200}>
                                {stepTypeOfActiveIndex !== null && stepTypeOfActiveIndex !== undefined && getStepRenderer(props, tabs)}
                                {!stepTypeOfActiveIndex && ""}
                            </ViewContextProvider>
                        </PatientContextProvider>
                    </CareActivityContextProvider>
                </>}
            {<HisPlatformExtensionPoint
                extensionProps={{
                    careActivitySource: props.selectedCareActivity.careActivitySource,
                    extensionData: props.selectedCareActivity.extensionData,
                    patientId: props.patientId,
                    careActivityId: props.selectedCareActivity.careActivityId,
                    listExtensionData: props.listExtensionData,
                    dataSource: props.dataSource,
                    beginningOfCare: props.selectedCareActivity.beginningOfCare,
                    dischargedAt: props.selectedCareActivity.dischargedAt
                }}
                type="patientsCareActivityDetail">
                <></>
            </HisPlatformExtensionPoint>}
        </>
    );
};

export default connect(
    PatientsCareActivityDetailView,
    new DependencyAdapter<IPatientsCareActivityDetailViewProps, IPatientsCareActivityDetailViewDependencies>(c => ({
        patientCareActivitiesTabRegistry: c.resolve("IPatientCareActivitiesTabRegistry")
    }))
);
