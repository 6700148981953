import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/Proxy.g";
import Di from "@Di";
import PerformanceStatementCareActivity from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/PerformanceStatementCareActivity";
import CareActivityId from "@Primitives/CareActivityId.g";
import PerformanceStatementCareActivityStatus from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/PerformanceStatementCareActivityStatus";

@Di.injectable()
export default class PerformanceStatementCareActivityStoreMapper extends EntityStoreMapper<Proxy.PerformanceStatementCareActivityDto, PerformanceStatementCareActivity> {
    protected storeEntityIdType = CareActivityId;

    public applyToStoreCore(target: PerformanceStatementCareActivity, response: Proxy.PerformanceStatementCareActivityDto) {
        const dto = this.entityDtoSelector(response);
        
        target.actualNeakStatus = this.mapPerformanceStatementCareActivityStatus(dto.actualNeakStatus);
        target.actualEuStatus = this.mapPerformanceStatementCareActivityStatus(dto.actualEuStatus);
        // TODO: apply properties
    }

    public mapPerformanceStatementCareActivityStatus(dto: Proxy.PerformanceStatementCareActivityStatusDto): PerformanceStatementCareActivityStatus {
        const result = new PerformanceStatementCareActivityStatus();
        result.validAt = dto.validAt;
        result.type = dto.type;
        result.modifiedAt = dto.modifiedAt;
        result.modifiedBy = dto.modifiedBy;
        result.reason = dto.reason;
        return result;
    }
}
