import { IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import React, { useMemo, useCallback, useContext } from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { IEmbeddedFormBlockSettings } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EmbeddedFormBlockSettings";
import PreviewFormDefinition from "@Toolkit/FormEngine/Model/PreviewFormDefinition";
import EmbeddedFormFormFieldData from "@Toolkit/FormEngine/Model/Data/EmbeddedFormFormFieldData";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import FormPanel from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";
import FormEngineValidationProblemMessages from "@Toolkit/FormEngine/StaticResources/FormEngineValidationProblemMessages";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";


interface IFormEmbeddedFormBlockProps extends IFormCustomBlockComponentProps {
}

function _FormEmbeddedFormBlock(props: IFormEmbeddedFormBlockProps) {

    const dataReference = (props.settings as IEmbeddedFormBlockSettings)?.dataReference;

    if (props.formDefinition instanceof PreviewFormDefinition) {
        // Preview mode
        return (
            <div>FormEmbeddedFormBlock for {dataReference}</div>
        );
    }

    const forms = useMemo(() => {
        const fieldData = (props.form.data.Content.find(it => it.fieldName === dataReference)) as EmbeddedFormFormFieldData;
        return fieldData?.value;
    }, [dataReference, props.form.data.Content]);

    const isReadOnly = useContext(ReadOnlyContext);

    if (isNullOrUndefined(forms)) {
        return null;
    }

    return (
        <>
            {forms?.map((form, idx) => (
                <ValidationBoundary
                    key={idx}
                    entityTypeName="Form"
                    entityId={form.id.value}
                    getProblemMessage={FormEngineValidationProblemMessages.getMessageByValidationProblem}
                >
                    <FormPanel
                        isReadOnly={isReadOnly}
                        form={form}
                        definitionId={form.definitionId}
                    />
                </ValidationBoundary>
            ))}
        </>
    );
}


const FormEmbeddedFormBlock = connect(
    _FormEmbeddedFormBlock
);

export function PlaceHolderEmbeddedFormBlock(props: IFormEmbeddedFormBlockProps) {
    return (
        <div>FormEmbeddedFormBlock placeholder</div>
    );
}

export default FormEmbeddedFormBlock;