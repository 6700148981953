import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import * as Ui from "@CommonControls";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import MedicalAlertStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalAlertStatement/MedicalAlertStatementStore";
import MedicalAlertStatementId from "@Primitives/MedicalAlertStatementId.g";
import MedicalAlertStatementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalAlertStatement/MedicalAlertStatementApiAdapter";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import MedicalConditionHistoryItemDetailPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicalConditionHistoryItemDetailPanel";
import MedicalAlertStatementFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalAlertStatement/MedicalAlertStatementFactory";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import PatientId from "@Primitives/PatientId.g";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import MedicalAlertStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalAlertStatement/MedicalAlertStatementHistoryItem";
import MedicalConditionItemHeaderPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicalConditionItemHeaderPanel";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import { EntityLockState } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import IMedicalConditionReferenceDataResolver from "@HisPlatform/BoundedContexts/Care/Services/Definition/MedicalConditionReferenceDataResolver/IMedicalConditionReferenceDataResolver";
import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import FormExtensionRegistryAdapter from "@PluginInterface/FormExtension/FormExtensionRegistryAdapter";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import IServiceRequestHeaderExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestHeaderExtensionPointProps";
import MedicalAlertCodeSelector from "@HisPlatformControls/MedicalAlertCodeSelector";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";

interface IMedicalAlertStatementPanelDependencies {
    dialogService: IDialogService;
    notificationService: INotificationService;
    medicalAlertStatementApiAdapter: MedicalAlertStatementApiAdapter;
    medicalAlertStatementFactory: MedicalAlertStatementFactory;
    lockingApiAdapter: LockingApiAdapter;
    medicalConditionReferenceDataResolver: IMedicalConditionReferenceDataResolver;
}

interface IMedicalAlertStatementPanelProps extends IRoutingFrameContentProps {
    _dependencies?: IMedicalAlertStatementPanelDependencies;
    _patientId?: PatientId;
    _formExtension?: IFormExtension<PatientId>;

    id: MedicalAlertStatementId;
    onMedicalAlertStatementCreatedAsync?: (medicalAlertStatementId: MedicalAlertStatementId) => Promise<void>;
    onCloseDetailAsync: () => Promise<void>;
    onRefreshListAsync: () => Promise<void>;
}

@State.observer
class MedicalAlertStatementPanel extends React.Component<IMedicalAlertStatementPanelProps> {

    private get medicalAlertStatementId() { return this.props.id; }
    private get patientId() { return this.props._patientId; }
    @State.observable.ref private store: MedicalAlertStatementStore = this.medicalAlertStatementFactory.createNewStore();
    @State.computed private get currentHistoryItem() { return this.store.currentHistoryItem as MedicalAlertStatementHistoryItem; }
    @State.computed private get isNew() { return this.medicalAlertStatementId.value === "new"; }

    private get dialogService() { return this.props._dependencies.dialogService; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get medicalAlertStatementApiAdapter() { return this.props._dependencies.medicalAlertStatementApiAdapter; }
    private get medicalAlertStatementFactory() { return this.props._dependencies.medicalAlertStatementFactory; }
    private get lockingApiAdapter() { return this.props._dependencies.lockingApiAdapter; }

    private get resources() { return StaticCareResources.MedicalCondition.MedicalAlertStatements; }
    private get medicalConditionResources() { return StaticCareResources.MedicalCondition; }
    private get labels() { return this.resources.Label; }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(
        this.loadAsync,
        this.isNew
            ? async () => await this.props._dependencies.medicalAlertStatementApiAdapter.checkPermissionForAddNewItemAsync(this.medicalAlertStatementFactory.createNewStore(), new PatientId("1"))
            : () => Promise.resolve([])
    );

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IMedicalAlertStatementPanelProps) {
        if (prevProps.id.value !== this.props.id.value) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.action.bound
    private async loadAsync(forceReleaseLockNeeded: boolean = false) {
        if (forceReleaseLockNeeded && !!this.store?.lockInfo?.preventingLockId) {
            await this.lockingApiAdapter.forceReleaseLockAsync(this.store.lockInfo.preventingLockId);
        }

        let store = null;
        if (this.isNew) {
            store = this.medicalAlertStatementFactory.createNewStore();
        } else {
            store = await this.medicalAlertStatementApiAdapter.getByIdAsync(this.medicalAlertStatementId, true);
        }
        this.store.setExtensionData(store.extensionData);
        this.setStore(store);
    }

    @State.action.bound
    private setStore(newValue: MedicalAlertStatementStore) {
        this.store = newValue;
        this.store.takeSnapshot();
    }

    @State.bound
    private async navigateAwayAsync() {
        if (this.store?.isMutable === true && this.isUnsavedOrDirty()) {
            const dialogResult = await this.dialogService.confirmIfNotSaved(StaticCareResources.Common.Dialog.ConfirmationTitle, StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);
            if (dialogResult.resultCode === DialogResultCode.Yes) {
                await this.saveStoreAsync();
                await this.releaseLockAsync();
            } else if (dialogResult.resultCode === DialogResultCode.No) {
                await this.releaseLockAsync();
                return true;
            }
            return false;
        }
        await this.releaseLockAsync();
        return true;
    }

    @State.action.bound
    private async releaseLockAsync() {
        if (this.store.isMutable && this.store.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld) {
            await this.lockingApiAdapter.releaseLockAsync(this.store.lockInfo.lockId);
            this.store.releaseLock();
            return true;
        }
        return false;
    }

    private isUnsavedOrDirty() {
        return this.store.isDirty() && this.currentHistoryItem.isNew;
    }

    @State.action.bound
    private async validateAsync(): Promise<IClientValidationResult[]> {
        if (!this.store || !this.store.isMutable || !this.currentHistoryItem.isNew) {
            return [];
        }
        const validationResultResponse = await this.medicalAlertStatementApiAdapter.validateAsync(this.store, this.patientId);
        return validationResultResponse.value;
    }

    @State.action.bound
    private async validateBeforeSaveAsync() {
        const newValue = await this.validateAsync();
        this.store.setValidationResults(newValue);
    }

    @State.action.bound
    private async saveStoreAsync() {
        await this.validateBeforeSaveAsync();

        if (this.store.hasValidationError) {
            return false;
        }

        await this.setExtensionDataAsync();

        let newStore = null;

        if (this.isNew) {
            newStore = await this.medicalAlertStatementApiAdapter.addMedicalAlertStatementAsync(this.store, this.patientId);
        } else {
            newStore = await this.medicalAlertStatementApiAdapter.updateMedicalAlertStatementAsync(this.store);
        }

        if (newStore.operationWasSuccessful && newStore.isPersistedByOperationInfo && this.isNew) {
            this.store.setExtensionData(newStore.extensionData);
            this.store.takeSnapshot();
            await this.props.onMedicalAlertStatementCreatedAsync?.(newStore.id);
            return true;
        }

        this.replaceStore(newStore);
        await this.props.onRefreshListAsync();
        return true;
    }

    @State.action.bound
    private async setExtensionDataAsync() {
        const extensionDataArray = await this.props._formExtension.invokeCallbackAsync<any>("ExtendStore", this.props._patientId);

        let extensionDataForStore = this.store.extensionData;
        for (const extensionData of extensionDataArray) {
            extensionDataForStore = { ...extensionData, ...extensionDataForStore };
        }

        this.store.setExtensionData(extensionDataForStore);
    }

    @State.action.bound
    private replaceStore(newStore: MedicalAlertStatementStore) {
        if (newStore.operationWasSuccessful) {
            if (newStore.isPersistedByOperationInfo) {
                newStore.lockInfo = this.store.lockInfo;
                this.setStore(newStore);
                this.notificationService.showSavedSuccessfully();
            } else {
                State.runInAction(() => {
                    this.store.validationResults = newStore.validationResults;
                });
            }
        }
    }

    @State.action.bound
    private async deleteHistoryItemAsync() {
        const message = formatString(this.resources.Dialog.DeleteVersionConfirmationMessage, (this.store.currentItemIndex + 1).toString());
        const dialogResult = await this.dialogService.yesNo(StaticCareResources.Common.Dialog.ConfirmationTitle, message);

        if (dialogResult.resultCode === DialogResultCode.No) {
            return false;
        }

        await this.setExtensionDataAsync();

        if (this.store.historyItems.length === 1) {
            await this.medicalAlertStatementApiAdapter.deleteMedicalAlertStatementAsync(this.store);
            this.store.releaseLock();
            this.props.onCloseDetailAsync();
            return true;
        } else {
            const newStore = await this.medicalAlertStatementApiAdapter.deleteMedicalAlertStatementHistoryItemAsync(this.store, this.store.currentHistoryItem.versionNumber);

            this.replaceStore(newStore);
            return true;
        }
    }

    @State.action.bound
    private async forceLoadAsync() {
        await this.loadAsync(true);
    }

    @State.computed
    private get errorExtensionProps() {
        return {
            extensionData: this.store.currentHistoryItem.extensionData
        } as IServiceRequestHeaderExtensionPointProps;
    }

    @State.bound
    private showNoEhiCompatibleIdentifierFoundError() {
        this.notificationService.error(this.medicalConditionResources.Common.Messages.NoEhiCompatibleIdentifierFoundErrorMessage);
        return true;
    }

    @State.computed
    public get renderErrorExtension() {
        return (
            <HisPlatformExtensionPoint
                type="ServiceRequestHeader"
                extensionProps={this.errorExtensionProps}>
                <></>
            </HisPlatformExtensionPoint>
        );
    }

    public render() {
        const prefix = this.store.validationPropertyPrefix;
        const name = this.store.isNew ?
            StaticCareResources.MedicalCondition.Common.Label.NewEntry : this.store.name;

        return (
            <PermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                <BusinessErrorHandler.Register businessErrorName="NoEhiCompatibleIdentifierFoundError" handler={this.showNoEhiCompatibleIdentifierFoundError} />
                <UseCaseFrame
                    toolbar={(
                        <MedicalConditionItemHeaderPanel
                            store={this.store}
                            onDeleteHistoryItemAsync={this.deleteHistoryItemAsync}
                            onSaveStoreAsync={this.saveStoreAsync}
                            onLockEditClickedAsync={this.forceLoadAsync} />
                    )}
                    title={name}>
                    {this.initialLoadPanelAsync.isUnauthorizedAccess ? <UnauthorizedAccessContent /> :
                        <>
                            {this.renderErrorExtension}
                            <ValidationBoundary
                                validationResults={this.store.validationResults}
                                entityTypeName="MedicalAlertStatement"
                                onValidateAsync={this.validateAsync}
                                validateOnMount
                            >
                                <ReadOnlyContext.Provider value={!this.store.isMutable || !this.currentHistoryItem.isNew}>
                                    <Ui.Flex>
                                        <Ui.Flex.Item sm={4}>
                                            <Ui.TextBox
                                                label={this.labels.Name}
                                                value={this.store.name}
                                                onChange={this.store.setName}
                                                propertyIdentifier="Name"
                                                automationId="__name"
                                                isReadOnly={!this.store.isNew}
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item sm={4}>
                                            <MedicalAlertCodeSelector
                                                label={this.labels.MedicalAlert}
                                                value={this.currentHistoryItem.medicalAlertCodeSelectorItem}
                                                onChange={this.currentHistoryItem.setMedicalAlert}
                                                propertyIdentifier={`${prefix}.MedicalAlertId`}
                                                automationId="__medicalAlertId"
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item sm={4}>
                                            <Ui.DatePicker
                                                label={this.labels.StatementDate}
                                                value={this.currentHistoryItem.statementDate}
                                                onChange={this.currentHistoryItem.setStatementDate}
                                                propertyIdentifier={`${prefix}.StatementDate`}
                                                automationId="__statementDate"
                                            />
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                    <Ui.Flex>
                                        <Ui.Flex.Item sm={12}>
                                            <Ui.TextBox
                                                label={this.labels.MedicalAlertDescription}
                                                value={this.currentHistoryItem.description}
                                                onChange={this.currentHistoryItem.setDescription}
                                                propertyIdentifier={`${prefix}.Description`}
                                                automationId="__description"
                                                multiline
                                                multilineMinLineCount={4}
                                            />
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                    <br />
                                    <MedicalConditionHistoryItemDetailPanel store={this.store} prefix={prefix} />
                                </ReadOnlyContext.Provider>
                            </ValidationBoundary>
                            <NavigateAwayHook onNavigateAwayAsync={this.navigateAwayAsync} />
                        </>
                    }
                </UseCaseFrame>
            </PermissionCheckContextProvider>
        );
    }

    @State.computed private get permissionCheckedOperations() {
        const res = {};

        const store = this.medicalAlertStatementFactory.createNewStore();
        store.id = new MedicalAlertStatementId("1");

        if (this.props.id) {
            res["AddNewVersion"] = async () => await this.props._dependencies.medicalAlertStatementApiAdapter.checkPermissionForUpdateItemAsync(store);
            res["DeleteVersion"] = async () => await this.props._dependencies.medicalAlertStatementApiAdapter.checkPermissionForDeleteItemAsync(store);
        }

        return res;
    }
}

export default connect(
    MedicalAlertStatementPanel,
    new DependencyAdapter<IMedicalAlertStatementPanelProps, IMedicalAlertStatementPanelDependencies>(c => ({
        dialogService: c.resolve<IDialogService>("IDialogService"),
        notificationService: c.resolve<INotificationService>("INotificationService"),
        medicalAlertStatementApiAdapter: c.resolve<MedicalAlertStatementApiAdapter>("MedicalAlertStatementApiAdapter"),
        medicalAlertStatementFactory: c.resolve<MedicalAlertStatementFactory>("MedicalAlertStatementFactory"),
        lockingApiAdapter: c.resolve<LockingApiAdapter>("LockingApiAdapter"),
        medicalConditionReferenceDataResolver: c.resolve("IMedicalConditionReferenceDataResolver"),
    })),
    new PatientContextAdapter<IMedicalAlertStatementPanelProps>(c => ({
        _patientId: c.patientId
    })),
    new FormExtensionRegistryAdapter("ManageMedicalCondition")
);
