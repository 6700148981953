import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IDataGridColumnChildProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import * as Ui from "@CommonControls";
import TableTokenValueFormatterSettingStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TableTokenValueFormatterSettingStore";

interface ITableTokenValueFormatterSettingHeadingCellProps extends IDataGridColumnChildProps<TableTokenValueFormatterSettingStore, any> {
}

/** @screen */
@State.observer
export class TableTokenValueFormatterSettingHeadingCell extends React.Component<ITableTokenValueFormatterSettingHeadingCellProps> {
    public render() {
        return <Ui.TextBox value={this.props.row.heading} onChange={this.props.row.setHeading} automationId="tableTokenValueFormatterSettingHeadingCellTextBox" />;
    }
}
