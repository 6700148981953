export default class HunEHealthInfrastructureUseCases {
    public static readonly ehiCovidStatusReports = "Care_EhiCovidStatusReports";
    public static readonly readOnlyEhiCovidStatusReports = "Care_ReadOnlyEhiCovidStatusReports";
    public static readonly ehiCovidStatusReportDetail = "EhiCovidStatusReportDetail";
    public static readonly readOnlyEhiCovidStatusReportDetail = "ReadOnlyEhiCovidStatusReportDetail";
    public static readonly therapyRecommendationDetail = "MedicalCondition_TherapyRecommendation_TherapyRecommendationDetail";
    public static readonly patientBloodTypeDetail = "MedicalCondition_PatientBloodType_PatientBloodTypeDetail";
    public static readonly ratForms = "Care_RatForms";
    public static readonly readOnlyRatForms = "Care_ReadOnlyRatForms";
    public static readonly editableRatFormDetail = "RatForm_EditableRatFormDetail";
    public static readonly readonlyRatFormDetail = "RatForm_ReadonlyRatFormDetail";
    public static readonly pregnancyRiskForms = "Care_PregnancyRiskForms";
    public static readonly readOnlyPregnancyRiskForms = "Care_ReadOnlyPregnancyRiskForms";
    public static readonly editablePregnancyRiskFormDetail = "PregnancyRiskForm_EditablePregnancyRiskFormDetail";
    public static readonly readOnlyPregnancyRiskFormDetail = "PregnancyRiskForm_ReadonlyPregnancyRiskFormDetail";

    public static readonly prescriptions = "Prescription_Prescriptions";
    public static readonly permanentPrescriptions = "Prescription_PermanentPrescriptions";
    public static readonly viewSubmittedPrescriptions = "Prescription_ViewSubmittedPrescriptions";
    public static readonly practitionerRecommendations = "PractitionerRecommendation_PractitionerRecommendations";
    public static readonly medicationInfo = "ReferenceData_MedicationInfo";
    public static readonly textualPrescriptionTemplates = "Prescription_TextualPrescriptionTemplates";
    public static readonly recordPrescription = "Prescriptions_RecordPrescription";
 
    public static readonly viewFamilyDoctorDocument = "FamilyDoctorDocument_View";
    public static readonly modifyFamilyDoctorDocument = "FamilyDoctorDocument_Modify";
    public static readonly createFamilyDoctorDocument = "FamilyDoctorDocument_Create";
}
