import Log from "@Log";
import moment from "moment";

export default class SimpleDirtyChecker {
    private _snapshot: string = null;
    private _isPersisted: boolean = true;

    public takeSnapshot(valuesToDirtyCheck: any[]) {
        this._snapshot = null;
        this._snapshot = this.stringifyValues(valuesToDirtyCheck);

        this._isPersisted = false;
    }

    public isDirty(valuesToDirtyCheck: any[]): boolean {
        if (this._isPersisted) {
            return false;
        }

        if (this._snapshot === null || this._snapshot === undefined) {
            throw Error("No available snapshot. Manually call takeSnapshot().");
        }

        const actualJson = this.stringifyValues(valuesToDirtyCheck);

        if (actualJson !== this._snapshot) {
            return true;
        } else {
            return false;
        }
    }

    public setPersisted() {
        this._isPersisted = true;
    }

    private stringifyValues(valuesToDirtyCheck: any[]) {
        return JSON.stringify(valuesToDirtyCheck, (key, value) => {
            if (moment.isMoment(value)) {
                return value.toISOString();
            }
            return value;
        });
    }
}