import Di from "@Di";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";
import BooleanFormFieldData from "@Toolkit/FormEngine/Model/Data/BooleanFormFieldData";
import CompositeFormFieldData from "@Toolkit/FormEngine/Model/Data/CompositeFormFieldData";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import NumberFormFieldData from "@Toolkit/FormEngine/Model/Data/NumberFormFieldData";
import DateFormFieldData from "@Toolkit/FormEngine/Model/Data/DateFormFieldData";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import DeathPlaceTypeId from "@Primitives/DeathPlaceTypeId.g";
import ReferencedExtensibleEnumFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedExtensibleEnumFormFieldData";
import AddressTypeId from "@Primitives/AddressTypeId.g";
import ShowNewPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowNewPatientScreenAction.g";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import StaticResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import CompositeArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/CompositeArrayFormFieldData";
import IForm from "@Toolkit/FormEngine/Model/IForm";

@Di.injectable()
class PatientAdministrativeDataScreenFormLogic implements IFormLogic {
    constructor(
        @Di.inject("INotificationService") private notificationService: INotificationService
    ) { }

    public executeAsync(form: IForm, showScreenAction?: ShowScreenFrontendActionBase): Promise<void> {
        State.runInAction(() => {
            const formData = form.data.Content;
            const isFirstRun = getField<BooleanFormFieldData>(formData, "Main.IsFirstRun");

            const showHideFields: Map<string, FormFieldDataBase> = new Map<string, FormFieldDataBase>([
                ["unknownPatient", getField<BooleanFormFieldData>(formData, "Main.BaseData.IsUnknown")],
                ["deadPatient", getField<BooleanFormFieldData>(formData, "Main.BaseData.IsDead")],
                ["deathDate", getField<BooleanFormFieldData>(formData, "Main.BaseData.DeathDate")],
                ["deathPlaceTypeId", getField<BooleanFormFieldData>(formData, "Main.BaseData.DeathPlaceTypeId")],
                ["deathPlace", getField<BooleanFormFieldData>(formData, "Main.BaseData.DeathPlace")],
                ["deathPlaceId", getField<BooleanFormFieldData>(formData, "Main.BaseData.DeathPlaceId")],
                ["citizenshipCountryId", getField<BooleanFormFieldData>(formData, "Main.BaseData.CitizenshipCountryId")],
                ["insuranceCountryId", getField<BooleanFormFieldData>(formData, "Main.BaseData.InsuranceCountryId")],
                ["contactInfo", getField<CompositeFormFieldData>(formData, "Main.ContactInfo")],
                ["insurances", getField<CompositeFormFieldData>(formData, "Main.Insurances")],
                ["miscData", getField<CompositeFormFieldData>(formData, "Main.MiscellaneousData")]
            ]);

            showHideFields.forEach(field => {
                if (isNullOrUndefined(field))
                    throw Error(`${field.fieldName} should exist.`);
            });

            const isUnknownFieldChecked = (showHideFields.get("unknownPatient") as BooleanFormFieldData).value;
            const isDeadFieldChecked = (showHideFields.get("deadPatient") as BooleanFormFieldData).value;

            showHideFields.forEach((field, id) => {
                if (id === "unknownPatient" || id === "deadPatient") {
                    return;
                }

                field.isVisible = id.startsWith("death")
                    ? isDeadFieldChecked
                    : !isUnknownFieldChecked;
            });

            if (isDeadFieldChecked) {
                const deathPlaceTypeId = (showHideFields.get("deathPlaceTypeId") as ReferencedExtensibleEnumFormFieldData).value;
                const isInstituteSelected = deathPlaceTypeId === DeathPlaceTypeId.Institute.value;
                showHideFields.get("deathPlace").isVisible = !isInstituteSelected;
                showHideFields.get("deathPlaceId").isVisible = isInstituteSelected;
            }

            this.executeCommonLogic(form, showScreenAction);

            const isUnknownBirthDataFieldChecked = getField<BooleanFormFieldData>(formData, "Main.BaseData.IsUnknownBirthDate").value;
            const estimatedAgeInYearsField = getField<NumberFormFieldData>(formData, "Main.BaseData.EstimatedAgeInYears");
            if (isUnknownBirthDataFieldChecked) {
                estimatedAgeInYearsField.isVisible = true;
            } else {
                estimatedAgeInYearsField.isVisible = false;
                estimatedAgeInYearsField.value = null;
            }

            const isBirthNameMatchingField = getField<BooleanFormFieldData>(formData, "Main.BaseData.IsBirthNameMatching");

            const isDataCleansingNeededField = getField<BooleanFormFieldData>(formData, "Main.IsDataCleansingNeeded");

            if (isDataCleansingNeededField.value === true && isFirstRun.value === false) {
                this.notificationService.warning(StaticResources.PatientRegister.PatientBaseData.Message.IsInvalidAndFillOut);
            }

            if (isDataCleansingNeededField.value === true &&
                !arrayIsNullOrEmpty(form.validationResults) &&
                !form.validationResults.some(r => r.problems && r.problems.some(p => p.severity === "error"))) {
                isDataCleansingNeededField.value = false;
            }

            if (showScreenAction instanceof ShowNewPatientScreenAction && !isFirstRun.value) {
                isBirthNameMatchingField.value = true;
            }

            const birthNameFields = [
                getField<TextFormFieldData>(formData, "Main.BaseData.BirthNamePrefix"),
                getField<TextFormFieldData>(formData, "Main.BaseData.BirthFamilyName"),
                getField<TextFormFieldData>(formData, "Main.BaseData.BirthGivenName1"),
                getField<TextFormFieldData>(formData, "Main.BaseData.BirthGivenName2")
            ];

            birthNameFields.forEach(birthNameField => birthNameField.isVisible = !isBirthNameMatchingField.value);
            isFirstRun.value = true;
        });

        return Promise.resolve();
    }

    public onFormLoadedAsync(
        form: IForm,
        showScreenAction: ShowScreenFrontendActionBase
    ): Promise<void> {
        State.runInAction(() => {
            this.executeCommonLogic(form, showScreenAction);
        });
        return Promise.resolve();
    }

    private executeCommonLogic(form: IForm, showScreenAction?: ShowScreenFrontendActionBase) {

        const formData = form.data.Content;
        const addresses = getField<CompositeArrayFormFieldData>(formData, "ContactInfo.Addresses");

        for (let i = 0; i < addresses.value.length; i++) {
            const addressTypeId = getField<ReferencedExtensibleEnumFormFieldData>(formData, `ContactInfo.Addresses[${i}].AddressTypeId`).value;
            const isAddressTypeOrganizational = addressTypeId === AddressTypeId.OrganizationLevel.value;

            const organizationNameField = getField<TextFormFieldData>(formData, `ContactInfo.Addresses[${i}].OrganizationName`);
            const contactNameField = getField<TextFormFieldData>(formData, `ContactInfo.Addresses[${i}].ContactName`);

            organizationNameField.isVisible = isAddressTypeOrganizational;
            contactNameField.isVisible = isAddressTypeOrganizational;
        }

        const practitioners = getField<CompositeFormFieldData>(formData, "Main.MiscellaneousData.Practitioners");

        for (let i = 0; i < practitioners.value.length; i++) {
            const practitionerId = getField<ReferencedEntityFormFieldData>(formData, `Main.MiscellaneousData.Practitioners[${i}].PractitionerId`);
            const practitionerName = getField<TextFormFieldData>(formData, `Main.MiscellaneousData.Practitioners[${i}].PractitionerName`);

            if (practitionerId.value) {
                practitionerId.isVisible = true;
                practitionerName.isVisible = false;
            } else if (practitionerName.value) {
                practitionerId.isVisible = false;
                practitionerName.isVisible = true;
            }

            const externalLocationIdValidOn = getField<DateFormFieldData>(formData, `Main.MiscellaneousData.Practitioners[${i}].ExternalLocationIdValidOn`);
            if (!externalLocationIdValidOn.value) {
                externalLocationIdValidOn.value = LocalDate.today();
            }

            // it should always be hidden
            externalLocationIdValidOn.isVisible = false;
        }
    }
}

export default PatientAdministrativeDataScreenFormLogic;
