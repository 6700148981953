import React, { useCallback, useLayoutEffect, useRef } from "react";
import { components } from "react-select";
import TextBox, { TextBoxCore } from "@CommonControls/TextBox";
import StaticToolkitResources from "@Toolkit/ReactClient/Services/Definition/LocalizationService/StaticToolkitResources";
import MenuSection from "@CommonControls/SelectBox/MenuSection";
import { inputChangeActionType } from "@CommonControls/SelectBox/SelectBox";
import ISelectBoxSection from "@CommonControls/SelectBox/ISelectBoxSection";
import ISelectBoxSectionItem from "@CommonControls/SelectBox/ISelectBoxSectionItem";
import AllMenuItemSection from "./AllMenuItemSection";
import NewMenuItemSectionWrapper from "./NewMenuItemSectionWrapper";

interface IMenuListProps {
    children: Node;
    maxHeight: number;
    className?: string;

    clearValue: () => void;
    getStyles: (name: string, currentProps: any) => object;
    getValue: () => any;
    hasValue: boolean;
    isMulti: boolean;
    options: object;
    selectOption: (newOption: any) => void;
    selectProps: any;
    setValue: (newValue: any, actionType: inputChangeActionType) => void;
    innerProps: any;

    searchText: string;
    onSearchTextChange: (newSearchText: string) => void;

    onSearchFocus: () => void;
    onSearchBlur: () => void;

    menuSections?: Array<ISelectBoxSection<any>>;
    renderSectionItem?: (value: ISelectBoxSectionItem<any>) => string | React.ReactNode;
    onItemSelected?: () => void;
    menuSectionMaxCount: number;

    onShowAllItemClick?: () => void;
    onNewItemClick?: () => void;
    newItemText: string;
    newItemPermissionIdentifier?: string;
}


const MenuList: React.FC<IMenuListProps> = (props) => {
    const searchBoxRef = useRef<TextBoxCore>();

    useLayoutEffect(() => {
        setImmediate(() => {
            searchBoxRef.current?.focus();
        });
    });

    const mouseDown = useCallback((e: React.MouseEvent) => {
        if (!(e.target instanceof HTMLInputElement)) {
            props.innerProps.onMouseDown?.(e);
            searchBoxRef.current?.focus();
        }
    }, [props.innerProps]);

    return (
        <components.MenuList {...props} innerProps={{ ...props.innerProps, onMouseDown: mouseDown }}>
            {props.isMulti && (

                <TextBox
                    placeholder={StaticToolkitResources.selectBox.startTypingToSearch}
                    stopClickPropagation
                    style={{ margin: 4 }}
                    value={props.searchText}
                    onChange={props.onSearchTextChange}
                    onFocus={props.onSearchFocus}
                    onBlur={props.onSearchBlur}
                    ref={searchBoxRef}
                    automationId="menuListSearchTextBox"
                />

            )}
            {props.children}
            {props.menuSections?.filter(section => section.items.length).map((item, key) => (
                <MenuSection
                    key={key}
                    title={item.title}
                    items={item.items}
                    renderMenuSectionItem={props.renderSectionItem}
                    multiSelect={props.isMulti}
                    onItemSelected={props.onItemSelected}
                    maxCount={props.menuSectionMaxCount}
                    {...props}
                />
            ))}
            {
                props.onShowAllItemClick &&
                <AllMenuItemSection onShowAllItemClick={props.onShowAllItemClick} />
            }
            {
                <NewMenuItemSectionWrapper
                    onNewItemClick={props.onNewItemClick}
                    newItemText={props.newItemText}
                    permissionCheckOperationNames={props.newItemPermissionIdentifier} />
            }
        </components.MenuList>
    );
};

export default MenuList;
