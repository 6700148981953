import DependencyContainer from "@DiContainer";
import IAssecoMedWebAppResources from "@AssecoMedPlugin/StaticResources/IAssecoMedWebAppResources";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import {createStaticResourceDictionary} from "@Toolkit/ReactClient/Common/LocalizedStrings";

const resources = createStaticResourceDictionary<IAssecoMedWebAppResources>();

export function loadStaticAssecoMedWebAppResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    resources.load(localizationService.getResourceGroup("WebApp"));
}

export default resources as IAssecoMedWebAppResources;
