import { iconNameType } from "@CommonControls/Icon";
import MasterDetailDetail from "@CommonControls/Layout/MasterDetailDetail";
import Modal, { ModalSize } from "@CommonControls/Modal";
import { ContextAwareModal } from "@HisPlatformControls";
import React from "react";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import MasterDetailLayout from "@CommonControls/Layout/MasterDetailLayout";
import MasterDetailMaster from "@CommonControls/Layout/MasterDetailMaster";
import ShowScreenActionHost from "@HisPlatform/Components/ShowScreenAction/ShowScreenActionHost";
import { useScreenStateContext } from "./ScreenStateContext";
import { ScreenNavigationContextProvider, useScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import useWindowTitle from "@Toolkit/ReactClient/Components/WindowTitleHook";
import PageBoxContainer from "@CommonControls/PageBox2/PageBoxContainer";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import ModalSecondaryScreenRenderer from "./ModalSecondaryScreenRenderer";

interface IMasterDetailScreenProps {
    /** PageBox, MD-Detail or Modal title */
    title?: string;

    /** PageBox, MD-Detail or Modal subtitle */
    subTitle?: React.ReactNode;

    /** PageBox, MD-Detail or Modal icon */
    iconName?: iconNameType;

    /** PageBox, MD-Detail toolbar */
    toolbar?: React.ReactNode;

    /** PageBox, MD-Detail or Modal footer */
    rightFooter?: React.ReactNode;

    /** PageBox, MD-Detail or Modal footer */
    leftFooter?: React.ReactNode;

    // TODO: Register screens as separate tabs!
    /** Show PageBox, MD-Detail or Modal tabs */
    // hasTabs?: boolean;
    // initiallyActiveTabName?: string;

    /** Modal only */
    modalSize?: ModalSize;
    modalMaxHeight?: number | string;
    modalFixedHeight?: number | string;

    onSecondarySavedExisting?: () => void;
    onSecondarySavedNew?: (newId: StringEntityId) => void;
    onSecondaryCancelled?: () => void;
    onSecondaryDeleted?: () => void;

    isSeparatedMode?: boolean;

    onModalCancelled?: () => void;

    // Legacy support:
    legacyIsDetailOpen?: boolean;
    legacyDetailContent?: React.ReactNode;
}

function MasterDetailScreen(props: React.PropsWithChildren<IMasterDetailScreenProps>) {

    const store = useScreenStateContext();
    const navigationContext = useScreenNavigationContext();

    useWindowTitle(props.title);

    switch (store.displayMode) {
        case ScreenDisplayMode.Modal:
            // Master-detail in modal
            return (
                <>
                    <ContextAwareModal
                        title={props.title}
                        iconName={props.iconName}
                        isOpen
                        closeButton
                        onClose={props.onModalCancelled ?? store.cancelled}
                        size={props.modalSize}
                        maxHeight={props.modalMaxHeight}
                        fixedHeight={props.modalFixedHeight}
                    >
                        <LoadingBoundary {...props}>
                            {/* TODO: Innen hiányzik a MD layout: */}
                            <Modal.Body>{props.children}</Modal.Body>
                            {(props.leftFooter || props.rightFooter) && (
                                <Modal.Footer left={props.leftFooter} right={props.rightFooter} />
                            )}
                        </LoadingBoundary>
                    </ContextAwareModal>
                    <ModalSecondaryScreenRenderer
                        onSavedNew={props.onSecondarySavedNew}
                        onSavedExisting={props.onSecondarySavedExisting}
                        onCancelled={props.onSecondaryCancelled}
                        onDeleted={props.onSecondaryDeleted}
                    />
                </>
            );
        case ScreenDisplayMode.Detail:
            // 2nd level master-detail
            return (
                <>
                    <MasterDetailDetail
                        title={props.title}
                        subTitle={props.subTitle}
                        iconName={props.iconName}
                        toolbar={props.toolbar}
                        hasSidePadding
                        footerLeftToolbar={props.leftFooter}
                        footerRightToolbar={props.rightFooter}
                        passStateToChild={false}
                        onClose={store.cancelled}
                    >
                        <MasterDetailLayout
                            master={(
                                <MasterDetailMaster
                                    hideHeader
                                    title={props.title}
                                    passStateToChild={false}
                                >
                                    {props.children}
                                </MasterDetailMaster>
                            )}
                            detail={(navigationContext.isSecondaryDetailOpen || props.legacyIsDetailOpen) && (
                                <ScreenNavigationContextProvider>
                                    {props.legacyDetailContent ?? (
                                        <ShowScreenActionHost
                                            screenRaw={navigationContext.currentSecondaryScreenRaw}
                                            onClose={navigationContext.clearAllNotPrimary}
                                            onSavedNew={props.onSecondarySavedNew}
                                            onSavedExisting={props.onSecondarySavedExisting}
                                            onCancelled={props.onSecondaryCancelled}
                                            onDeleted={props.onSecondaryDeleted}
                                        />
                                    )}
                                </ScreenNavigationContextProvider>
                            )}
                            selectedItem={(navigationContext.isSecondaryDetailOpen || props.legacyIsDetailOpen) || null}
                            isSeparatedMode={props.isSeparatedMode}
                        />
                    </MasterDetailDetail>
                    <ModalSecondaryScreenRenderer
                        onSavedNew={props.onSecondarySavedNew}
                        onSavedExisting={props.onSecondarySavedExisting}
                        onCancelled={props.onSecondaryCancelled}
                        onDeleted={props.onSecondaryDeleted}
                    />
                </>
            );
        default:
            // normal
            return (
                <>
                    <PageBoxContainer>
                        <MasterDetailLayout
                            isSeparatedMode={props.isSeparatedMode}
                            master={(
                                <MasterDetailMaster
                                    title={props.title}
                                    subTitle={props.subTitle}
                                    toolbar={props.toolbar}
                                    iconName={props.iconName}
                                    passStateToChild={false}
                                >
                                    {props.children}
                                </MasterDetailMaster>
                            )}
                            detail={(navigationContext.isSecondaryDetailOpen || props.legacyIsDetailOpen) && (
                                <ScreenNavigationContextProvider>
                                    {props.legacyDetailContent ?? (
                                        <ShowScreenActionHost
                                            screenRaw={navigationContext.currentSecondaryScreenRaw}
                                            onClose={navigationContext.clearAllNotPrimary}
                                            onSavedNew={props.onSecondarySavedNew}
                                            onSavedExisting={props.onSecondarySavedExisting}
                                            onCancelled={props.onSecondaryCancelled}
                                            onDeleted={props.onSecondaryDeleted}
                                        />
                                    )}
                                </ScreenNavigationContextProvider>
                            )}
                            selectedItem={(navigationContext.isSecondaryDetailOpen || props.legacyIsDetailOpen) || null}
                        />
                    </PageBoxContainer>
                    <ModalSecondaryScreenRenderer
                        onSavedNew={props.onSecondarySavedNew}
                        onSavedExisting={props.onSecondarySavedExisting}
                        onCancelled={props.onSecondaryCancelled}
                        onDeleted={props.onSecondaryDeleted}
                    />
                </>
            );
    }

    return <>{props.children}</>;
}

export default State.observer(MasterDetailScreen);