import React from "react";
import { SelectBox } from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import Category from "@Toolkit/CommonWeb/Model/Category";

interface ICategoryObservationSelectBoxProps {
    categories: Category[];
    isMultiSelect: boolean;
    currentSingleCategory: Category;
    currentMultiCategories: Category[];
    setSingleCategory: (newValue: Category) => void;
    setMultipleCategories: (newValue: Category[]) => void;
    readOnly: boolean;
    automationId: string;
}

@State.observer
export default class CategoryObservationSelectBox extends React.Component<ICategoryObservationSelectBoxProps> {

    @State.observable.ref private items: Array<ISelectBoxItem<Category>> = null;

    public componentDidMount() {
        this.initializeItems();
    }

    public componentDidUpdate(prevProps: ICategoryObservationSelectBoxProps) {
        if (prevProps.categories !== this.props.categories) {
            this.initializeItems();
        }
    }

    @State.action
    private initializeItems() {
        this.items = this.props.categories.map(c => ({ text: c.value, value: c } as ISelectBoxItem<Category>));
    }

    public render() {
        return (
            <SelectBox 
                items={this.items}
                value={this.props.isMultiSelect ? this.props.currentMultiCategories : this.props.currentSingleCategory}
                multiSelect={this.props.isMultiSelect}
                onChange={this.props.isMultiSelect ? this.props.setMultipleCategories : this.props.setSingleCategory}
                isReadOnly={this.props.readOnly}
                automationId={this.props.automationId}
            />
        );
    }
}