import FormDataElementDescriptor from "./FormDataElementDescriptor";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";
import FormDefinitionId from "./Primitives/FormDefinitionId.g";

export default class FormDefinitionDescriptor {
    constructor(
        public formDefinitionId: FormDefinitionId,
        public formDefinitionName: string,
        public formDefinitionDisplayName: MultiLingualLabel,
        public schemaVersion: number,
        public formDataElementDescriptors: FormDataElementDescriptor[]) {
    }
}