import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import PermanentPrescriptionId from "@Primitives/PermanentPrescriptionId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class PrescriptionInfo {
    @State.observable.ref public permanentPrescriptionId: PermanentPrescriptionId;
    @State.observable.ref public displayName: string;
    @State.observable.ref public claimTypeId: MedicationSubsidyClaimTypeId;

    @State.action.bound
    public setPermanentPrescriptionId(id: PermanentPrescriptionId) {
        this.permanentPrescriptionId = id;
    }

    @State.action.bound
    public setDisplayName(displayName: string) {
        this.displayName = displayName;
    }

    @State.action.bound
    public setClaimTypeId(claimTypeId: MedicationSubsidyClaimTypeId) {
        this.claimTypeId = claimTypeId;
    }
}