import React, { useCallback, useMemo } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FormEditorRegistry, { IFormEditorComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import { useFormPanelStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanelStoreProvider";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ReferencedEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEnumFormDataElement";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";


interface IFormReferencedEnumSelectorProps extends IFormEditorComponentProps {

}

function FormReferencedEnumSelector(props: IFormReferencedEnumSelectorProps) {

    const { formEditorRegistry } = useDependencies(c => ({
        formEditorRegistry: c.resolve<FormEditorRegistry>("FormEditorRegistry")
    }));
    const dataElement = (props.dataElement as ReferencedEnumFormDataElement);
    const editor = formEditorRegistry.tryGetEnumEditorComponent(dataElement.enumName);
    
    const valueObj = useMemo(() => isNullOrUndefined(props.value) || !editor ? null : +props.value, [props.value, editor]);
    
    const change = useCallback((value: number) => {
            props.onChange(value);
    }, [props.onChange]);

    return React.createElement(
        editor.componentType,
        { ...editor.props, ...props, onChange: change, value: valueObj }
    );

}

export default State.observer(FormReferencedEnumSelector);