import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import MedicationSubsidyOptionId from "@Primitives/MedicationSubsidyOptionId.g";
import MedicationSubsidyId from "@Primitives/MedicationSubsidyId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class MedicationRequestCoverage {
    @State.observable.ref public claimTypeId: MedicationSubsidyClaimTypeId;
    @State.observable.ref public optionId: MedicationSubsidyOptionId;
    @State.observable.ref public subsidyId: MedicationSubsidyId;

    @State.action.bound 
    public setClaimTypeId(claimTypeId: MedicationSubsidyClaimTypeId) {
        this.claimTypeId = claimTypeId;
    }

    @State.action.bound 
    public setOptionId(optionId: MedicationSubsidyOptionId) {
        this.optionId = optionId;
    }

    @State.action.bound 
    public setSubsidyId(subsidyId: MedicationSubsidyId) {
        this.subsidyId = subsidyId;
    }
}