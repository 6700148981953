import React, {useCallback} from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import {
    IDataGridColumnBaseProps,
    IDataGridColumnFilterProps,
    IDataGridFilterValueSerializer
} from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import MedicalServicePrioritySelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/ServiceRequestManagement/MedicalServicePrioritySelectBox/MedicalServicePrioritySelectBox";
import MedicalServicePriorityBadge from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/MedicalServicePriorityBadge";
import MedicalServicePriority from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalServices/Enum/MedicalServicePriority.g";

interface IMedicalServicePriorityColumn2Props extends IDataGridColumnBaseProps {

}

const MedicalServicePriorityColumn2: React.FC<IMedicalServicePriorityColumn2Props> = props => {

    const {children, ...columnProps} = props;

    const valueRenderer = useCallback((value: MedicalServicePriority) => {
        return (
            <MedicalServicePriorityBadge
                value={value}
            />
        );
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<MedicalServicePriority | MedicalServicePriority[]>) => {
        return <MedicalServicePrioritySelectBox {...filterProps} hoverOnlyIndicatorIcons />;
    }, []);

    return (
        <DataGridColumn
            width={50}
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
            showHint={false}
        />
    );
};

const filterValueSerializer: IDataGridFilterValueSerializer<MedicalServicePriority | MedicalServicePriority[]> = {
    serialize: value => JSON.stringify(value),
    deserialize: value => JSON.parse(value)
};

export default MedicalServicePriorityColumn2;
