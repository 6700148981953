export default class PreferredMedicationNameColumnData {
    public get displayName(): string {
        return this.preferredName ?? this.name;
    }

    constructor(
        public readonly name: string,
        public readonly preferredName: string
    ) {
    }
}