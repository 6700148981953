import React from "react";
import Icon from "@CommonControls/Icon";
import Styles from "./LockIndicator.less";
import { Button } from "@CommonControls";
import config from "@Config";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { visualStyleType } from "@CommonControls/Button";
import PermissionCheckContextAdapter from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextAdapter";
import UserId from "@Primitives/UserId.g";
import ILockingNotificationService from "@HisPlatform/BoundedContexts/Locking/Services/Definition/ILockingNotificationService";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import LockId from "@Toolkit/CommonWeb/Model/LockId";

export interface ILockIndicatorComponentDependencies {
    dialogService: IDialogService;
    lockingNotificationSerivce: ILockingNotificationService;
    lockingApiAdapter: LockingApiAdapter;
}

export interface ILockIndicatorComponentProps {
    _dependencies?: ILockIndicatorComponentDependencies;
    _permissionDenied?: boolean;
    preventingLockId?: LockId;
    locked?: boolean;
    lockedBy: UserId;
    style?: React.CSSProperties;
    className?: string;
    onEditClicked?: () => void;
    onEditClickedAsync?: () => Promise<void>;
    onLoadClickedAsync?: () => Promise<void>;
    buttonSize?: "standard" | "compact";
    disabled?: boolean;
    disabledMessage?: string;
    disabledMessagePlacement?: "top" | "left" | "bottom" | "right";
    buttonVisualStyle?: visualStyleType;
    permissionCheckOperationNames?: string;
    permissionDeniedStyle?: "disabled" | "invisible";
}

@State.observer
class LockIndicatorComponent extends React.Component<ILockIndicatorComponentProps> {

    private get dependencies() { return this.props._dependencies; }
    private get buttonDisabled() { return this.props.disabled || !this.props.locked; }

    public static defaultProps: Partial<ILockIndicatorComponentProps> = {
        locked: false,
        buttonSize: "compact",
        buttonVisualStyle: "primary"
    };

    @State.observable private lockedText: string = StaticWebAppResources.Common.ToastMessage.LockAcquisitionFailed;

    @State.action.bound
    private setLockedText(newValue: string) {
        this.lockedText = newValue;
    }

    @State.bound
    private async calculateLockedTextAsync() {
        let message = StaticWebAppResources.Common.ToastMessage.LockAcquisitionFailed;
        if (this.props.lockedBy) {
            message = await this.props._dependencies.lockingNotificationSerivce.getLockingMessageAsync(this.props.lockedBy);
        }
        this.setLockedText(message);
    }

    public componentDidUpdate(prevProps: ILockIndicatorComponentProps) {
        if (!ValueWrapper.equals(prevProps.lockedBy, this.props.lockedBy)) {
            dispatchAsyncErrors(this.calculateLockedTextAsync(), this);
        }
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.calculateLockedTextAsync(), this);
    }

    @State.bound
    private async onEditClickedWrapperAsync(): Promise<void> {
        if (config.testFeatures.forceReleaseLockEnabled && this.props.locked) {
            const formattedUserName = await this.dependencies.lockingNotificationSerivce.formatNameForLockingMessageAsync(this.props.lockedBy);
            const dialogResult = await this.dependencies.dialogService.yesNo(
                StaticWebAppResources.Common.DialogTitle.ConfirmationTitle,
                formatStringWithObjectParams(StaticWebAppResources.Common.DialogMessage.ForceReleaseLockWarning, { UserName: formattedUserName })
            );
            if (dialogResult.resultCode === DialogResultCode.Yes) {
                await this.props.onEditClickedAsync();
            }
        } else {
            await this.props.onEditClickedAsync();
        }

    }

    @State.bound
    private async onLoadClickedWrapperAsync(): Promise<void> {
        if (config.testFeatures.forceReleaseLockEnabled && this.props.locked) {
            const formattedUserName = await this.dependencies.lockingNotificationSerivce.formatNameForLockingMessageAsync(this.props.lockedBy);
            const dialogResult = await this.dependencies.dialogService.yesNo(
                StaticWebAppResources.Common.DialogTitle.ConfirmationTitle,
                formatStringWithObjectParams(StaticWebAppResources.Common.DialogMessage.ForceReleaseLockWarning, { UserName: formattedUserName })
            );
            if (dialogResult.resultCode === DialogResultCode.Yes) {              
                await this.dependencies.lockingApiAdapter.forceReleaseLockAsync(this.props.preventingLockId);
                await this.props.onLoadClickedAsync();
            }
        } else {
            await this.props.onLoadClickedAsync();
        }
    }

    @State.bound
    private renderEditButton() {
        if (!this.props.onEditClicked && !this.props.onEditClickedAsync) {
            return null;
        }

        return (
            <Button
                disabled={this.buttonDisabled}
                onClick={this.props.onEditClicked}
                onClickAsync={this.onEditClickedWrapperAsync}
                size={this.props.buttonSize}
                visualStyle={this.props.buttonVisualStyle}
                automationId="lockIndicatorEditButton"
                tooltipContent={this.props.disabled && this.props.disabledMessage && this.props.disabledMessage}
                tooltipPosition={this.props.disabledMessagePlacement ? this.props.disabledMessagePlacement : "left"}
            >
                {StaticWebAppResources.Common.Button.Edit}
            </Button>
        );
    }

    @State.bound
    private renderLoadButton() {
        if (!this.props.onLoadClickedAsync) {
            return null;
        }

        return (
            <Button
                disabled={this.buttonDisabled}
                onClickAsync={this.onLoadClickedWrapperAsync}
                size={this.props.buttonSize}
                visualStyle={this.props.buttonVisualStyle}
                automationId="lockIndicatorLoadButton"
                tooltipContent={this.props.disabled && this.props.disabledMessage && this.props.disabledMessage}
                tooltipPosition={this.props.disabledMessagePlacement ? this.props.disabledMessagePlacement : "left"}
            >
                {StaticWebAppResources.Common.Button.Edit}
            </Button>
        );
    }

    public render() {
        return (
            <>
                {
                    !this.props._permissionDenied && (
                        <div className={Styles.container} data-automation-id="lockIndicator">
                            {this.props.locked && this.renderEditButton()}
                            {this.props.locked && this.renderLoadButton()}
                            {this.renderIcon()}
                        </div>
                    )
                }
            </>
        );
    }

    private renderIcon() {
        return (
            <Popper tooltipContent={!(this.props.disabled && this.props.disabledMessage) && this.props.locked ? this.lockedText : undefined}>
                {this.props.locked && <Icon iconName={this.props.locked === true ? "lock" : "unlock"} style={this.props.style} className={this.props.className} />}
            </Popper>
        );
    }
}

export default connect(
    LockIndicatorComponent,
    new DependencyAdapter<ILockIndicatorComponentProps, ILockIndicatorComponentDependencies>((container) => {
        return {
            dialogService: container.resolve("IDialogService"),
            lockingNotificationSerivce: container.resolve("ILockingNotificationService"),
            lockingApiAdapter: container.resolve("LockingApiAdapter")
        };
    }),
    new PermissionCheckContextAdapter()
);