import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import EhiServiceCallStatus from "@HunEHealthInfrastructurePlugin/Model/EhiServiceCallStatus";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import Di from "@Di";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import { IEhiCareActivityDataPanelViewProps } from "./EhiCareActivityDataPanelView";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import format from "xml-formatter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiEvent from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEvent";
import EhiCareService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareService";
import PatientId from "@Primitives/PatientId.g";

interface IEhiCareActivityDataPanelProps {
    patientId: PatientId;
    ehiCareService: EhiCareService;
    ehiId: string;
    isEmergency: boolean;
    emergencyReason: string;

    onLoaded?: (event: EhiEvent) => void;
}

/** @screen */
@Di.injectable()
export default class EhiCareActivityDataPanelStore extends PanelStoreBase<IEhiCareActivityDataPanelViewProps> implements ILoadablePanelStore<IEhiCareActivityDataPanelViewProps> {
    @State.observable.ref public event: EhiEvent = null;
    @State.observable.ref public data: string | null = null;
    @State.observable.ref public errors: EhiError[] = [];

    constructor() {
        super();
    }

    @State.action.bound
    private setEvent(newValue: EhiEvent) {
        this.event = newValue;
        if (!isNullOrUndefined(newValue?.data)) {
            this.data = format(Base64Converter.toString(newValue.data), {
                whiteSpaceAtEndOfSelfclosingTag: true,
                collapseContent: true,
                indentation: " "
            });
        }
    }

    @State.action.bound
    private setErrors(newValue: EhiError[]) {
        this.errors = newValue;
    }

    public loadAsync = this.namedAsync("loadingEventDetails", async () => {
        if (this.props.ehiId) {
            const ehiServiceCallResult = await this.props.ehiCareService.getEventDetailsAsync(
                this.props.patientId,
                this.props.ehiId,
                this.props.isEmergency,
                this.props.emergencyReason);

            if (ehiServiceCallResult.ehiServiceCallStatus === EhiServiceCallStatus.Success &&
                ehiServiceCallResult.result &&
                ehiServiceCallResult.result.operationWasSuccessful) {

                const event = ehiServiceCallResult.result.value;

                this.setEvent(event.value);
                this.setErrors(event.errors);
            }
        }
    });
}

