import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import { addFieldsForPatientIdentifier, usePatientIdentifierCustomBlockHelper } from "@HisPlatform/Packages/Patients/Components/PatientIdentifier/PatientIdentifierFormCustomBlock";
import CountryId from "@Primitives/CountryId.g";
import EuCardReplacementTypeId from "@Primitives/EuCardReplacementTypeId.g";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import PatientDocumentTypeId from "@Primitives/PatientDocumentTypeId.g";
import { useErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import ReferencedExtensibleEnumFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedExtensibleEnumFormFieldData";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import { useFormDateField, useFormEntityIdField, useFormExtensibleEnumIdField, useFormTextField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { getCompositeArrayFields, getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import { useFormLayoutContext } from "@Toolkit/FormEngine/Panels/FormLayoutContext";
import { ICustomBlockRegistryItem, IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React, { useEffect, useMemo, useState } from "react";
import PatientEUCardIdentifier from "./PatientEUCardIdentifier";

interface IPatientEUCardIdentifierFormCustomBlockDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IPatientEUCardIdentifierFormCustomBlockProps extends IFormCustomBlockComponentProps {
    _dependencies?: IPatientEUCardIdentifierFormCustomBlockDependencies;
}


function _PatientEUCardIdentifierFormCustomBlock(props: IPatientEUCardIdentifierFormCustomBlockProps) {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext.compositeDataReferences.join(".");

    const fields = getCompositeArrayFields(props.form.data.Content, compositeFieldName);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        addFieldsForPatientEUCardIdentifier(props.form.data.Content, fields, compositeFieldName);
        addFieldsForPatientIdentifier(props.form.data.Content, fields, compositeFieldName);
        setIsLoaded(true);
    }, []);

    return isLoaded && (
        <PatientEUCardIdentifierFormCustomBlockCore {...props} />
    );
}

const PatientEUCardIdentifierFormCustomBlockCore = State.observer((props: IPatientEUCardIdentifierFormCustomBlockProps) => {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext.compositeDataReferences.join(".");

    const [identifierTypeId, setIdentifierTypeId] = useFormEntityIdField<PatientDocumentTypeId>(props.form.data.Content, compositeFieldName + ".DocumentTypeId", PatientDocumentTypeId);    const [identifierValue, setIdentifierValue] = useFormTextField(props.form.data.Content, compositeFieldName + ".IdentifierValue");
    const [insuranceCountryId, setInsuranceCountryId] = useFormEntityIdField<CountryId>(props.form.data.Content, compositeFieldName + ".InsuranceCountryId", CountryId);
    const [insuranceNumber, setInsuranceNumber] = useFormTextField(props.form.data.Content, compositeFieldName + ".InsuranceNumber");
    const [insurerOrganizationId, setInsurerOrganizationId] = useFormEntityIdField<InsurerOrganizationId>(props.form.data.Content, compositeFieldName + ".InsurerOrganizationId", InsurerOrganizationId);
    const [validFrom, setValidForm] = useFormDateField(props.form.data.Content, compositeFieldName + ".ValidFrom");
    const [validTo, setValidTo] = useFormDateField(props.form.data.Content, compositeFieldName + ".ValidTo");
    const [notes, setNotes] = useFormTextField(props.form.data.Content, compositeFieldName + ".Notes");
    const [euCardReplacementTypeId, setEuCardReplacementTypeId] = useFormExtensibleEnumIdField<EuCardReplacementTypeId>(props.form.data.Content, compositeFieldName + ".EuCardReplacementTypeId", EuCardReplacementTypeId);
    const [issuer, setIssuer] = useFormTextField(props.form.data.Content, compositeFieldName + ".Issuer");

    const errorDispatcher = useErrorDispatcher();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        props._dependencies.careReferenceDataStore.patientDocumentType.ensureAllLoadedAsync()
            .catch(errorDispatcher)
            .then(() => setIsLoaded(true));
    }, []);

    const patientIdentifierTypeIdentifierSystemId = useMemo(() => {
        const patientDocumentType = props._dependencies.careReferenceDataStore.patientDocumentType.items.find(i => i.Entity.id.value === identifierTypeId?.value);
        return patientDocumentType?.Entity.identifierSystemId.value;
    }, [isLoaded, identifierTypeId]);

    usePatientIdentifierCustomBlockHelper(compositeFieldName, patientIdentifierTypeIdentifierSystemId, ["EuCard", "EuCardReplacement"], "PatientEUCardIdentifierFormCustomBlock");

    return (patientIdentifierTypeIdentifierSystemId === "EuCard" || patientIdentifierTypeIdentifierSystemId === "EuCardReplacement") && (
        <PatientEUCardIdentifier
            identifierTypeId={identifierTypeId}
            onIdentifierTypeIdChange={setIdentifierTypeId}
            identifierValue={identifierValue}
            onIdentifierValueChange={setIdentifierValue}
            issuer={issuer}
            onIssuerChange={setIssuer}
            insurerOrganizationId={insurerOrganizationId}
            onInsurerOrganizationIdChange={setInsurerOrganizationId}
            validFrom={validFrom}
            onValidFromChange={setValidForm}
            validTo={validTo}
            onValidToChange={setValidTo}
            euCardReplacementTypeId={euCardReplacementTypeId}
            onEuCardReplacementTypeIdChange={setEuCardReplacementTypeId}
            insuranceCountryId={insuranceCountryId}
            onInsuranceCountryIdChange={setInsuranceCountryId}
            notes={notes}
            onNotesChange={setNotes}
            insuranceNumber={insuranceNumber}
            onInsuranceNumberChange={setInsuranceNumber}
        />
    );
});

function addFieldsForPatientEUCardIdentifier(formFields: FormFieldDataBase[], array: IObservableArray<FormFieldDataBase>, compositeFieldName: string) {
    State.runInAction(() => {
        const euCardReplacementTypeIdField = getField<ReferencedExtensibleEnumFormFieldData>(formFields, compositeFieldName + ".EuCardReplacementTypeId");
        if (!euCardReplacementTypeIdField) {
            array.push(new ReferencedExtensibleEnumFormFieldData("EuCardReplacementTypeId", false, true, null));
        }

        const insuranceNumberField = getField<TextFormFieldData>(formFields, compositeFieldName + ".InsuranceNumber");
        if (!insuranceNumberField) {
            array.push(new TextFormFieldData("InsuranceNumber", false, true, null));
        }

        const notesField = getField<TextFormFieldData>(formFields, compositeFieldName + ".Notes");
        if (!notesField) {
            array.push(new TextFormFieldData("Notes", false, true, null));
        }

        const insuranceCountryIdField = getField<ReferencedEntityFormFieldData>(formFields, compositeFieldName + ".InsuranceCountryId");
        if (!insuranceCountryIdField) {
            array.push(new ReferencedEntityFormFieldData("InsuranceCountryId", false, true, State.createObservableShallowArray([]), null));
        }
    });
}

const PatientEUCardIdentifierFormCustomBlock = connect(_PatientEUCardIdentifierFormCustomBlock,
    new DependencyAdapter<IPatientEUCardIdentifierFormCustomBlockProps, IPatientEUCardIdentifierFormCustomBlockDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore")
        };
    }));

export const PatientEUCardIdentifierRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Patient eu card identifier",
    dataElementsFactory: () => [
    ],
    componentType: PatientEUCardIdentifierFormCustomBlock
};

export default PatientEUCardIdentifierFormCustomBlock;
