import State from "@Toolkit/ReactClient/Common/StateManaging";
import PregnancyOutcomeAssignmentBase from "./PregnancyOutcomeAssignmentBase";
import TextualObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/TextualObservation";

export default class CustomPregnancyOutcomeAssignment extends PregnancyOutcomeAssignmentBase  {
    @State.observable.ref public textualObservation: TextualObservation = null;

    constructor(textualObservation: TextualObservation) {
        super();
        this.textualObservation = textualObservation;
    }
}