import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import TimePhaseStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseStore";
import React, { useMemo } from "react";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import Style from "./ScheduleTimePhasesGrid.less";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IEmptyStateSettings } from "@CommonControls/DataGrid/IDataGridProps";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import TimePhaseType from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseType";
import TimePhaseRecurrenceElement from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseRecurrenceElement";
import RecurrenceType from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/RecurrenceType";
import WeekParity from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/WeekParity";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { getNameByValue } from "@Toolkit/CommonWeb/EnumHelpers";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import _ from "@HisPlatform/Common/Lodash";
import DayOfWeek from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/DayOfWeek.g";
import WeekOfMonth from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/WeekOfMonth.g";

interface IScheduleTimePhasesGridDependencies {
    localizationService: ILocalizationService;
}

interface IScheduleTimePhasesGridProps {
    _dependencies?: IScheduleTimePhasesGridDependencies;
    dataSource: InMemoryDataGridDataSource;
    onAddTimePhaseAsync: () => Promise<void>;
    onEditTimePhaseAsync: (timePhase: TimePhaseStore) => Promise<void>;
    onDeleteTimePhaseAsync: (timePhase: TimePhaseStore) => Promise<void>;
    showSingle: boolean;
    onFilterSingleChanged: (newValue: boolean) => void;
    showRecurring: boolean;
    onFilterRecurringChanged: (newValue: boolean) => void;
    noItemsMessage: string;
    onSelectedRowChange: (row: TimePhaseStore) => void;
    dateFilter: LocalDateRange;
    onTimePhaseFilterChange: (newValue: LocalDateRange) => void;
}


const ScheduleTimePhasesGrid: React.FC<IScheduleTimePhasesGridProps> = props => {
    const deleteHandlerFactory = (store: TimePhaseStore) => {
        return () => {
            props.onDeleteTimePhaseAsync(store);
            return Promise.resolve();
        };
    };

    const editHandlerFactory = (store: TimePhaseStore) => {
        return () => {
            props.onEditTimePhaseAsync(store);
            return Promise.resolve();
        };
    };

    const renderServiceRowButtons = (value: string, row: TimePhaseStore) => {
        return (
            <div className={Style.actionsColumn}>
                <Ui.Button
                    iconName="trash"
                    visualStyle="negative-standard"
                    onClickAsync={deleteHandlerFactory(row)}
                    automationId={row.id.value + "_deleteButton"}
                />
                <Ui.Button
                    iconName="pen"
                    visualStyle="standard"
                    onClickAsync={editHandlerFactory(row)}
                    automationId={row.id.value + "_editButton"}
                />
            </div>
        );
    };

    const renderAddTimePhaseButton = () => {
        return (
            <Ui.Button
                onClickAsync={props.onAddTimePhaseAsync}
                iconName="plus"
                className={Style.addButton}
                text={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.BaseData.AddServiceButton}
                automationId="addTimePhaseButton"
            />
        );
    };

    const renderTimePhaseOccurrence = (value: string, rowData: TimePhaseStore) => {
        return (
            <State.Observer>
                {() => (
                    <div className={Style.timePhaseColumn}>
                        {rowData?.type === TimePhaseType.Recurring && recurrenceDisplayString(rowData?.recurrenceElements)}
                        {rowData?.type === TimePhaseType.Single && rowData?.singleOccurrenceDate?.toUtcDayStartMoment().format("L dddd")}
                    </div>
                )}
            </State.Observer>
        );
    };

    const renderDateRangeFilter = () => {
        return (
            <Ui.DateRangePicker
                value={props.dateFilter}
                onChange={props.onTimePhaseFilterChange}
                hoverOnlyIndicatorIcons
                automationId="dateFilterPicker"
            />
        );
    };

    const renderTimePhaseInterval = (value: string, rowData: TimePhaseStore) => {
        return (
            <State.Observer>
                {() => (
                    <div>
                        {rowData?.interval?.start && rowData?.interval?.end && `${rowData.interval.start.toString()} - ${rowData.interval.end.toString()}`}
                    </div>
                )}
            </State.Observer>
        );
    };

    const renderTimePhaseTypeFilter = () => { // TODO checkBoxGroup?
        return (
            <Ui.Flex>
                <Ui.Flex.Item xs={6} >
                    <Ui.CheckBox
                        align="center"
                        value={props.showRecurring}
                        onChange={props.onFilterRecurringChanged}
                        label={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.TimePhases.RecurringOccurrences}
                        className={Style.filterCheckbox}
                        automationId="showRecurringCheckBox"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={6}>
                    <Ui.CheckBox
                        align="center"
                        value={props.showSingle}
                        onChange={props.onFilterSingleChanged}
                        label={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.TimePhases.SingleOccurrences}
                        className={Style.filterCheckbox}
                        automationId="showSingleCheckBox"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    };

    const emptyState = useMemo(() => {
        return {
            message: props.noItemsMessage
        } as IEmptyStateSettings;
    }, [props.noItemsMessage]);

    const recurrenceDisplayString = (pattern: TimePhaseRecurrenceElement[]) => {
        let displayString: string = null;

        if (pattern?.length > 0) {
            const localizationService = props._dependencies.localizationService;

            const type = pattern[0].recurrenceType;
            const days = _.uniq(pattern.map(p => p.dayOfWeek).sort());

            let weeks: WeekOfMonth[] = null;
            let parity: WeekParity = null;
            switch (type) {
                case RecurrenceType.EvenOdd:
                    parity = pattern[0].isEvenWeek ? WeekParity.Even : WeekParity.Odd;
                    break;
                case RecurrenceType.DayOfWeek:
                    parity = WeekParity.All;
                    break;
                case RecurrenceType.DayOfMonth:
                    weeks = _.uniq(pattern.map(p => p.weekOfMonth).sort());
                    break;
            }

            const parityString = !isNullOrUndefined(parity) ? localizationService.localizeClientEnum(getNameByValue(WeekParity, parity), "WeekParity").Name : "";
            const weeksString = !isNullOrUndefined(weeks) ? weeks.reduce((aggregateString, week, idx) => {
                const weekString = localizationService.localizeEnum(getNameByValue(WeekOfMonth, week), "WeekOfMonth").Name;
                const newAggregate = aggregateString.concat(weekString).concat((idx < weeks.length - 1) ? ", " : "");
                return newAggregate;
            }, "") : "";
            const daysString = days.reduce((aggregateString, day, idx) => {
                const dayString = localizationService.localizeEnum(getNameByValue(DayOfWeek, day), "DayOfWeek").Name;
                const newAggregate = aggregateString.concat(dayString).concat((idx < days.length - 1) ? ", " : "");
                return newAggregate;
            }, "");

            displayString = (type === RecurrenceType.DayOfMonth ? weeksString : parityString) + ": " + daysString;
        }
        return displayString;
    };

    return (
        <Ui.GroupBox title={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.TimePhases.TimePhases}>
            <Ui.DataGrid
                dataSource={props.dataSource}
                generateInitialFilterStore={true}
                changeOnMount
                actionsColumn={false}
                emptyStateSettings={emptyState}
                isSelectable
                onSelectedRowChange={props.onSelectedRowChange}
                automationId="scheduleTimePhasesGrid_dataGrid"
            >
                <DataGridColumn
                    id={0}
                    onRenderCellValue={renderTimePhaseOccurrence}
                    isFilterable
                    width="45%"
                    isObserver
                    onRenderFilter={renderDateRangeFilter}
                    header={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.BaseData.Search}
                />
                <DataGridColumn
                    id={1}
                    width="30%"
                    isObserver
                    isFilterable
                    onRenderCellValue={renderTimePhaseInterval}
                    onRenderFilter={renderTimePhaseTypeFilter}
                />
                <DataGridColumn
                    id={2}
                    onRenderCellValue={renderServiceRowButtons}
                    onRenderFilter={renderAddTimePhaseButton}
                    isFilterable
                    automationId={"Actions"}
                    width="20%"
                />
            </Ui.DataGrid>
        </Ui.GroupBox>
    );
};

export default connect(
    ScheduleTimePhasesGrid,
    new DependencyAdapter<IScheduleTimePhasesGridProps, IScheduleTimePhasesGridDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
