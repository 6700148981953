import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import {IDataGridColumnBaseProps} from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import {DisplayMode} from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";
import React from "react";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiHealthcareProviderId from "@Primitives/EhiHealthcareProviderId.g";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import formatCodeName from "@HisPlatformControls/CodeNameFormatter/CodeNameFormatter";

interface IEhiHealthcareProviderColumnDependencies {
    referenceDataStore: HunEhiCareReferenceDataStore;
}

interface IEhiHealthcareProviderColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IEhiHealthcareProviderColumnDependencies;
    displayMode?: DisplayMode;
    isCodeBold?: boolean;
    fallbackValueRenderer?: (row: any, displayMode: DisplayMode) => React.ReactNode;
    validOn?: LocalDate;
}


const EhiHealthcareProviderColumn: React.FC<IEhiHealthcareProviderColumnProps> = (props: IEhiHealthcareProviderColumnProps) => {
    const valueRenderer = (value: EhiHealthcareProviderId, row: any) => {
        const noItem = isNullOrUndefined(value);
        if (!noItem) {
            const org = props._dependencies.referenceDataStore.ehiHealthcareProviders.get({
                id: value,
                validOn: !isNullOrUndefined(props.validOn) ? props.validOn : LocalDate.today()
            } as IEntityVersionSelector<EhiHealthcareProviderId>);
            if (!isNullOrUndefined(props.displayMode)) {
                switch (props.displayMode) {
                    case "name": return org?.name ?? "";
                    case "shorthand": return org?.code ?? "";
                    case "shorthand-name": return formatCodeName(org?.code, org?.name, props.isCodeBold);
                }
            } else {
                return org?.name ?? "";
            }
        } else if (noItem && props.fallbackValueRenderer) {
            return props.fallbackValueRenderer(row, props.displayMode);
        } else {
            return "";
        }
    };

    return (
        <DataGridColumn
            onRenderCellValue={valueRenderer}
            clientSideFilterableValueGetter={valueRenderer}
            {...props}
        />
    );
};

export default connect(
    EhiHealthcareProviderColumn,
    new DependencyAdapter<IEhiHealthcareProviderColumnProps, IEhiHealthcareProviderColumnDependencies>(c => ({
        referenceDataStore: c.resolve("HunEhiCareReferenceDataStore")
    }))
);