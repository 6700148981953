import React from "react";
import IPatientsCareActivityListInfoExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientsCareActivityListInfoExtensionPointProps";
import EhiCareActivityListInfoPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiPatientsCareActivityListInfoPanel/EhiCareActivityListInfoPanel";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export class PatientsCareActivityListInfoExtension extends React.Component<IPatientsCareActivityListInfoExtensionPointProps> {

    public render() {
        return (
            <EhiCareActivityListInfoPanel
                extensionData={this.props.extensionData}
                isFilterOpen={this.props.isFilterOpen}
            />
        );
    }
}
