import React from "react";
import ScalarProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/ScalarProperty";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import StaticConfigurationResources from "@HisPlatform/BoundedContexts/Configuration/StaticResources/StaticConfigurationResources";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface IBoolPropertyEditorProps {
    row: ScalarProperty;
}

@State.observer
export class BoolPropertyEditor extends React.Component<IBoolPropertyEditorProps> {

    @State.computed private get currentValue() {
        return this.props.row.getValue(this.props.row.isTemporal ? LocalDate.today() : undefined);
    }

    private items: boolean[] = [true, false];

    private displayValueGetter(item: boolean) {
        return item ?
            StaticConfigurationResources.PropertyGroupsPanel.BoolPropertyEditor.On :
            StaticConfigurationResources.PropertyGroupsPanel.BoolPropertyEditor.Off;
    }

    public render() {
        return (
            <Ui.Flex>
                <Ui.Flex.Item sm={12}>
                    <Ui.RadioButtonGroup
                        value={this.currentValue}
                        onChange={this.setCurrentVersionValue}
                        items={this.items}
                        getDisplayValue={this.displayValueGetter}
                        displayType="groupedButtons"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.bound
    private setCurrentVersionValue(newValue: boolean) {
        this.props.row.replaceVersion(newValue, this.props.row.isTemporal ? LocalDate.today() : undefined);
    }
}