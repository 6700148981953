import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import * as Ui from "@CommonControls";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IIdentifierSystemSelectBoxViewProps extends ISelectBoxBaseProps {
    items: Array<ISelectBoxItem<IdentifierSystemId>>;
}

@State.observer
export class IdentifierSystemSelectBoxView extends React.Component<IIdentifierSystemSelectBoxViewProps> {

    constructor(props: IIdentifierSystemSelectBoxViewProps) {
        super(props);
    }

    public render() {
        return (
            <Ui.SelectBox {...this.props} items={this.props.items} searchable={true} onChange={this.props.onChange} valueOnClear={null} value={this.props.value} />
        );
    }
}
