import { RadioButtonGroupFactory } from "@CommonControls/RadioButtonGroup";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import PatientId from "@Primitives/PatientId.g";
import ServiceRequestFilter from "@Primitives/ServiceRequestFilter";
import NDataScreen from "@HisPlatform/BoundedContexts/Productivity/Components/NDataScreen/NDataScreen";
import ShowCareActivityServiceRequestListScreenAction from "@HisPlatform/Packages/ServiceRequest/FrontendActions/ShowCareActivityServiceRequestListScreenAction.g";
import ShowPatientServiceRequestListScreenAction from "@HisPlatform/Packages/ServiceRequest/FrontendActions/ShowPatientServiceRequestListScreenAction.g";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import { ScreenStateContextAdapter, ScreenStateContextStore, useScreenStateContext } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import React, { useMemo, useState } from "react";
import { useCallback } from "react";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import { HisActionDispatcherAdapter } from "@HisPlatform/Common/FrontendActions/HisActionDispatcher";
import ServiceRequestMasterDetailPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestMasterDetailPanel/ServiceRequestMasterDetailPanel";
import { useScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import { getUseCaseAsUrlParam, getUseCaseFromNDataUseCaseStateAsUrlParam, parseUseCaseStateFromUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import { isNullOrUndefinedOrGivenString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";

export interface IOldServiceRequestListScreenProps
    extends IScreenPropsBase<ShowCareActivityServiceRequestListScreenAction | ShowPatientServiceRequestListScreenAction> {
}

function OldServiceRequestListScreen(props: IOldServiceRequestListScreenProps) {

    const navigationContext = useScreenNavigationContext();
    const screenState = useScreenStateContext();

    const [selectedServiceRequestId, useCaseState] = useMemo(() => {

        const parts = isNullOrUndefinedOrGivenString(screenState.screenState, "null") ? ["null", "null"] : screenState.screenState.split("+");
        const selectedId = parts[0] === "null" ? null : ServiceRequestId.fromUrl(parts[0]);
        const useCaseState = parseUseCaseStateFromUrlParam(parts.slice(1).join(";"));
        return [selectedId, useCaseState];

    }, [screenState.screenState]);

    const change = useCallback((id: ServiceRequestId, state: INDataUseCaseState) => {
        
        const serailizedId = id?.toUrl() ?? "null";
        const serailizedUseCaseState = getUseCaseFromNDataUseCaseStateAsUrlParam(state)?.replace(/;/gi, "+") ?? "null";
        
        navigationContext.setPrimaryScreenState(`${serailizedId}+${serailizedUseCaseState}`);

    }, [navigationContext.setPrimaryScreenState]);

    const closeUseCase = useCallback(() => {
        navigationContext.setPrimaryScreenState("null");
    }, [navigationContext.setPrimaryScreenState]);

    const [serviceRequestFilter, setServiceRequestFilter] = useState<ServiceRequestFilter>(ServiceRequestFilter.CareActivity);

    return (
        <ServiceRequestMasterDetailPanel
            onBack={screenState.cancelled}
            selectedId={selectedServiceRequestId}
            useCase={useCaseState}
            onChange={change}
            onCloseUseCase={closeUseCase}
            serviceRequestFilter={serviceRequestFilter}
            onServiceRequestFilterChange={setServiceRequestFilter}
            mode="edit"
        />
    );
}

export default OldServiceRequestListScreen;