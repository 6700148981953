import React from "react";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import EhiEReferralServiceTypeId from "@Primitives/EhiEReferralServiceTypeId.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IUniversalCodeSelectorProps } from "@HisPlatformControls/UniversalCodeSelector";
import * as HisUi from "@HisPlatformControls";
import { IPagingState, IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import { IComplexSearchItemGroup } from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchPanel";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IEhiEReferralServiceTypeVersion from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IEhiEReferralServiceTypeVersion";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";

interface IEhiEReferralServiceTypeSelectorDependencies {
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    ehiCareApiAdapter: EhiCareApiAdapter;
}

interface IEhiEReferralServiceTypeSelectorProps extends ICodeSelectorCommonProps<EhiEReferralServiceTypeId> {
    _dependencies?: IEhiEReferralServiceTypeSelectorDependencies;
    validOn?: LocalDate;
}

class EhiEReferralServiceTypeSelector extends React.Component<IEhiEReferralServiceTypeSelectorProps> {

    private get validOn() {
        return this.props.validOn || LocalDate.today();
    }

    @State.bound
    private async getDisplayValueAsync(value: EhiEReferralServiceTypeId) {
        if (value) {
            const versionSelector = { id: value, validOn: this.validOn };
            await this.props._dependencies.hunEhiCareReferenceDataStore.ehiEReferralServiceTypes.ensureLoadedAsync([versionSelector]);

            const item = this.props._dependencies.hunEhiCareReferenceDataStore.ehiEReferralServiceTypes.get(versionSelector);
            return item.code + " " + item.name;
        }
        return "";
    }

    @State.bound
    private async loadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: IEhiEReferralServiceTypeVersion[], selectedGroup: IComplexSearchItemGroup) {
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;

        const response = await this.props._dependencies.ehiCareApiAdapter.searchEhiEReferralServiceTypes(
            filterText,
            customOrdering,
            paging);

        return {
            totalCount: response.totalCount,
            items: response.items
        };
    }

    @State.bound
    private onComplexSearchSingleSelect(item: IEhiEReferralServiceTypeVersion) {
        this.props.onChange(item.id);
    }

    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            codeGetter: "code",
            nameGetter: "name",
            complexSearchLoadAsync: this.loadAsync,
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            hasComplexSearch: true
        } as IUniversalCodeSelectorProps<EhiEReferralServiceTypeId, IEhiEReferralServiceTypeVersion>;
        return (
            <>
                <HisUi.UniversalCodeSelector
                    {...props}
                    automationId={this.props.automationId}
                />
            </>
        );
    }
}

export default connect(
    EhiEReferralServiceTypeSelector,
    new DependencyAdapter<IEhiEReferralServiceTypeSelectorProps, IEhiEReferralServiceTypeSelectorDependencies>((container) => {
        return {
            hunEhiCareReferenceDataStore: container.resolve<HunEhiCareReferenceDataStore>("HunEhiCareReferenceDataStore"),
            ehiCareApiAdapter: container.resolve<EhiCareApiAdapter>("EhiCareApiAdapter"),
        };
    })
);