import React, { useMemo } from "react";
import Styles from "./OutpatientHeader.less";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import GenderId from "@Primitives/GenderId.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import StaticWebAppResources from "@StaticResources";
import _ from "@HisPlatform/Common/Lodash";
import AllergyIntoleranceCriticality from "@Primitives/AllergyIntoleranceCriticality";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import PatientRegisterReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/PatientRegisterReferenceDataStore";
import PatientAllergyIntoleranceId from "@Primitives/PatientAllergyIntoleranceId.g";
import CareActivityPane, { ICareActivityPaneProps, ICareActivityPaneDependencies } from "@HisPlatformControls/OutpatientHeader/CareActivityPane";
import { ICareActivityMenuProps } from "@HisPlatformControls/OutpatientHeader/CareActivityMenu";
import PatientId from "@Primitives/PatientId.g";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import CareActivityId from "@Primitives/CareActivityId.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import IPatientDocumentFormattingRegistry from "@PluginInterface/PatientDocumentFormatting/IPatientDocumentFormattingRegistry";
import IExtensibleEnumValue from "@Toolkit/CommonWeb/Model/IExtensibleEnumValue";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import PatientAllergyIntoleranceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceStore";
import PatientAllergyIntoleranceHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceHistoryItem";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";
import AddressTypeId from "@Primitives/AddressTypeId.g";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

interface IOutpatientHeaderViewDependencies {
    localizationService: ILocalizationService;
    patientRegisterReferenceDataStore: PatientRegisterReferenceDataStore;
    commonReferenceDataStore: CommonReferenceDataDataStore;
    financeReferenceDataStore: FinanceReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    careReferenceDataStore: CareReferenceDataStore;
    patientDocumentFormattingRegistry: IPatientDocumentFormattingRegistry;
}

export interface IOutpatientHeaderViewProps {
    _dependencies?: IOutpatientHeaderViewDependencies;

    isPatientDataLoading: boolean;
    showPatientEditButton: boolean;
    patient: PatientAdministrativeData;
    patientAllergies: PatientAllergyIntoleranceStore[];

    onEditPatient: () => void;
    onOpenAllergy: (id: PatientAllergyIntoleranceId) => void;

    isCareActivityDataLoading: boolean;
    isCareActivityDataAvailable: boolean;
    isPatientDataAvailable: boolean;
    patientId: PatientId;

    showAsHeader: boolean;
    isInModal: boolean;
    isUnauthorized: boolean;
}

const renderTooltipRow = (label: string, data: string, key: number) => {
    return (
        <Ui.Flex verticalSpacing="none" key={key}>
            <Ui.Flex.Item xs={5}>{label}:</Ui.Flex.Item>
            <Ui.Flex.Item xs={7}><b>{data}</b></Ui.Flex.Item>
        </Ui.Flex>
    );
};

const renderTooltip = (props: IOutpatientHeaderViewProps) => {
    const localizationService = props._dependencies.localizationService;
    const commonReferenceDataStore = props._dependencies.commonReferenceDataStore;

    const citizenshipCountry = commonReferenceDataStore.countryMap.get(props.patient.baseData.citizenshipCountryId);

    let gender: IExtensibleEnumValue<GenderId>;
    if (props.patient.baseData.genderId) {
        gender = commonReferenceDataStore.gender.get(props.patient.baseData.genderId);
    }
    let permanentAddress = "";
    const permanentAddressField = props.patient.addresses && props.patient.addresses.find(i => ValueWrapper.equals(i.addressTypeId, AddressTypeId.Permanent));
    if (permanentAddressField) {
        const country = commonReferenceDataStore.countryMap.get(permanentAddressField.countryId);
        permanentAddress = country && permanentAddressField && `${country.isoAlpha3} ${country.countryName}, ${permanentAddressField.zipCode} ${permanentAddressField.settlement} ${permanentAddressField.addressLine}`;
    }

    const patientInsurerOrganization = props.patient.insurances.length > 0 && props.patient.insurances[0].insurerOrganizationId;
    const insurerOrganization = patientInsurerOrganization && props._dependencies.financeReferenceDataStore.insurerOrganizationMap.get(patientInsurerOrganization);

    let i = 0;

    return (
        <>
            {renderTooltipRow(StaticWebAppResources.SearchPatient.Label.PatientIdentifier, props.patient.identifier, i++)}
            {renderTooltipRow(StaticWebAppResources.Common.Label.Name, localizationService.localizePersonName(props.patient.baseData.name), i++)}
            {renderTooltipRow(StaticWebAppResources.OutpatientWorkflowPage.Label.BirthName, localizationService.localizePersonName(props.patient.baseData.hasBirthName ? props.patient.baseData.birthName : props.patient.baseData.name), i++)}
            {renderTooltipRow(StaticWebAppResources.OutpatientWorkflowPage.Label.BirthPlaceAndTime,
                props.patient.baseData.birthLocationInfo.location + ", " + localizationService.localizeDate(props.patient.baseData.ageInfo.birthDate) + ` (${props.patient.age.value}/${gender && gender.displayValue.Shorthand || ""})`, i++)}
            {citizenshipCountry && renderTooltipRow(StaticWebAppResources.PatientBaseData.Label.Citizenship, citizenshipCountry.isoAlpha3 + " " + citizenshipCountry.citizenshipName, i++)}
            {permanentAddress && renderTooltipRow(StaticWebAppResources.OutpatientWorkflowPage.Label.PermanentAdress, permanentAddress, i++)}
            {props.patient.telecomContactPoints && props.patient.telecomContactPoints.length > 0 && props.patient.telecomContactPoints.map(cp => {
                const contactPointType = commonReferenceDataStore.telecomType.get(cp.telecomTypeId);
                const contactPointUse = commonReferenceDataStore.telecomUse.get(cp.telecomUseId);
                return contactPointType && contactPointUse && renderTooltipRow(`${contactPointType.displayValue.Name} (${contactPointUse.displayValue.Name})`, cp.value, i++);
            })}
            {props.patient.patientDocuments && props.patient.patientDocuments.map(pd => {
                const documentType = props._dependencies.commonReferenceDataStore.identifierSystemMap.getLocalization(pd.identifierSystemId);
                return renderTooltipRow(StaticWebAppResources.OutpatientWorkflowPage.Label.PatientDocument + ` (${documentType && documentType.Name || ""})`, props._dependencies.patientDocumentFormattingRegistry.format(pd.identifierSystemId.value, pd.identifierValue), i++);
            })}
            {insurerOrganization && renderTooltipRow(StaticCareResources.PatientRegister.InsuranceList.Label.InsurerOrganization, insurerOrganization.name, i++)}
        </>
    );
};

const OutpatientHeaderView: React.SFC<IOutpatientHeaderViewProps & ICareActivityPaneProps & ICareActivityMenuProps> = props => {

    const patientGender = useMemo(() => props.patient.baseData.genderId && props._dependencies.localizationService.localizeEnumId(props.patient.baseData.genderId).Shorthand, [props.patient.baseData.genderId]);
    const patientBirthDate = useMemo(() => props.patient.baseData.ageInfo.birthDate && props._dependencies.localizationService.localizeDate(props.patient.baseData.ageInfo.birthDate), [props.patient.baseData.ageInfo.birthDate]);
    const getGenderValue = useMemo(() => {
        if (!props.patient.baseData.genderId) {
            return null;
        }
        switch (props.patient.baseData.genderId.value) {
            case GenderId.Male.value:
                return "male";
            case GenderId.Female.value:
                return "female";
            default:
                return null;
        }
    }, [props.patient.baseData.genderId]);

    if (!props.isPatientDataAvailable) {
        return null;
    }

    if (props.isUnauthorized) {
        return <div className={combineClasses(Styles.container, props.showAsHeader && Styles.headerStyle, Styles.unauthorized)}><UnauthorizedAccessContent showInfoBox={false} /></div>;
    }

    return (
        <div className={combineClasses(Styles.container, props.showAsHeader && Styles.headerStyle)}>
            <div className={Styles.patientData}>
                <LoadingBoundary
                    onRender={renderPatientDataFactory(props, getGenderValue, patientGender, patientBirthDate, props.routingStore, props.careActivityId)} />
            </div>
            {props.isCareActivityDataAvailable &&
                <div className={Styles.careData} style={{ minWidth: props.isCareActivityDataLoading ? "50px" : undefined }}>
                    <LoadingBoundary>
                        {() => (<CareActivityPane {...props} />)}
                    </LoadingBoundary>
                </div>}
        </div>
    );
};

const getInsuranceRelationshipDocumentValue = (props: IOutpatientHeaderViewProps) => {
    const hunSocialSecurityNumber = props.patient.patientDocuments.find(pd => pd.identifierSystemId.value === "HunSocialSecurityNumber");
    if (!isNullOrUndefined(hunSocialSecurityNumber)) {
        return renderDocument(hunSocialSecurityNumber, props);
    }
    const newbornHunSocialSecurityNumber = props.patient.patientDocuments.find(pd => pd.identifierSystemId.value === "NewbornHunSocialSecurityNumber");
    if (!isNullOrUndefined(newbornHunSocialSecurityNumber)) {
        return renderDocument(newbornHunSocialSecurityNumber, props);
    }
    const replacementHunSocialSecurityNumber = props.patient.patientDocuments.find(pd => pd.identifierSystemId.value === "ReplacementHunSocialSecurityNumber");
    if (!isNullOrUndefined(replacementHunSocialSecurityNumber)) {
        return renderDocument(replacementHunSocialSecurityNumber, props);
    }
    const euCard = props.patient.patientDocuments.find(pd => pd.identifierSystemId.value === "EuCard");
    if (!isNullOrUndefined(euCard)) {
        return renderDocument(euCard, props);
    }
    const euCardReplacement = props.patient.patientDocuments.find(pd => pd.identifierSystemId.value === "EuCardReplacement");
    if (!isNullOrUndefined(euCardReplacement)) {
        return renderDocument(euCardReplacement, props);
    }
    const other = props.patient.patientDocuments.find(pd => pd.identifierSystemId.value === "Other");
    if (!isNullOrUndefined(other)) {
        return renderDocument(other, props);
    }
    return props.patient.identifier;
};

const renderDocument = (store: {identifierSystemId: IdentifierSystemId, identifierValue: string}, props: IOutpatientHeaderViewProps) => {
    const documentType = props._dependencies.commonReferenceDataStore.identifierSystemMap.getLocalization(store.identifierSystemId);
    return (
        <>
            <span>
                {documentType?.Name ?? "?"}:&nbsp;
            </span>
            <span className={Styles.textBold}>
                {props._dependencies.patientDocumentFormattingRegistry.format(store.identifierSystemId.value, store.identifierValue)}
            </span>
        </>
    );
};

const renderPatientDataFactory = (props: IOutpatientHeaderViewProps, genderValue: "male" | "female", patientGender: string, patientBirthDate: string, routingStore: GlobalRoutingStore, careActivityId: CareActivityId) => () => (
    <Popper
        wrapperElementType="div"
        className={Styles.popperContainer}
        tooltipContent={!props.isPatientDataLoading && renderTooltip(props)}
        tooltipPlacement="bottom-start"
        tooltipClassName={Styles.tooltip}
    >

        {props.showPatientEditButton &&

            <div className={Styles.patientAvatar}>
                <Ui.Avatar gender={genderValue} />
            </div>
        }

        <div className={Styles.column}>
            <div
                className={Styles.patientName}
                onClick={props.onEditPatient}>
                <Ui.PersonNameLabel personName={props.patient.baseData.name} />
            </div>
            <div className={Styles.patientBirthName}>
                <span>
                    {StaticWebAppResources.OutpatientWorkflowPage.Label.BirthName}:&nbsp;
                </span>
                <Ui.PersonNameLabel personName={props.patient.baseData.hasBirthName ? props.patient.baseData.birthName : props.patient.baseData.name} className={Styles.textBold} />
            </div>
        </div>

        <div className={Styles.columnWithSeparator}>
            <div>
                {getInsuranceRelationshipDocumentValue(props)}
            </div>
            <div className={Styles.bottomRow}>
                <span>
                    {StaticWebAppResources.OutpatientWorkflow.OutpatientHeader.BirthDate}:&nbsp;
                </span>
                <span className={Styles.textBold}>
                    {patientBirthDate}&nbsp;
                </span>
                <span className={Styles.textBold}>
                    ({props.patient.age.value && props.patient.age.case({ fulfilled: (value) => (value) })}/{patientGender})
                </span>
            </div>
        </div>

        <div className={Styles.allergyColumn} style={{ width: "100%" }}>
            {props.patientAllergies &&
                <Ui.AlertCardContainer>
                    {props.isInModal ? <HisUi.HisHeaderPortal.Host /> : <></>}
                    {props.patientAllergies.map(renderPatientAllergyIntoleranceAlertCard(props._dependencies.patientRegisterReferenceDataStore, props.onOpenAllergy))}
                </Ui.AlertCardContainer>}
        </div>
    </Popper>
);

const renderPatientAllergyIntoleranceAlertCard = (patientRegisterReferenceDataStore: PatientRegisterReferenceDataStore, onOpenAllergy: (id: PatientAllergyIntoleranceId) => void) => (patientAllergyIntolerance: PatientAllergyIntoleranceStore, index: number) => {
    const currentItem = (patientAllergyIntolerance.latestHistoryItem as PatientAllergyIntoleranceHistoryItem);

    let lowerText = currentItem.reactions[0].description;

    lowerText = _.truncate(lowerText, { length: 17 });

    if (currentItem.reactions.length > 1) {
        lowerText += " (" + currentItem.reactions.length + ")";
    }

    let toolTip = currentItem.reactions.map(x => x.description).join("\n");
    if (!!currentItem.historyItemDetail.notes) {
        toolTip += "\n\n" + currentItem.historyItemDetail.notes;
    }

    const currentVisualStyle = currentItem.criticality === AllergyIntoleranceCriticality.Low ? "warning" : "error";

    return (
        <Ui.AlertCard
            label=""
            innerLabel={{ lowerText: lowerText, upperText: patientAllergyIntolerance.label }}
            visualStyle={currentVisualStyle} background="white" key={index}
            onClick={onOpenAllergy.bind(null, patientAllergyIntolerance.id)}
            tooltipContent={toolTip}
            tooltipTextAlign={"left"}
            tooltipPosition="bottom" />
    );
};

export default connect(
    OutpatientHeaderView,
    new DependencyAdapter<IOutpatientHeaderViewProps & ICareActivityPaneProps & ICareActivityMenuProps, IOutpatientHeaderViewDependencies & ICareActivityPaneDependencies>(container => {
        return {
            localizationService: container.resolve("ILocalizationService"),
            patientRegisterReferenceDataStore: container.resolve("PatientRegisterReferenceDataStore"),
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            commonReferenceDataStore: container.resolve("CommonReferenceDataDataStore"),
            financeReferenceDataStore: container.resolve("FinanceReferenceDataStore"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            patientDocumentFormattingRegistry: container.resolve<IPatientDocumentFormattingRegistry>("IPatientDocumentFormattingRegistry")
        };
    })
);
