import * as React from "react";
import MultiAccordionStore from "@CommonControls/MultiAccordion/MultiAccordionStore";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import Styles from "@CommonControls/SingleAccordion/SingleAccordion.less";
import Icon from "@CommonControls/Icon";
import Flex from "@CommonControls/Flex";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IAccordionPaneProps {
    title: React.ReactNode;
    index?: number;
    activeIndex?: number;
    onClick?: (index: number) => void;
    automationId?: string;
}

@State.observer
export default class AccordionPane extends React.Component<IAccordionPaneProps, undefined> {

    private store: MultiAccordionStore;

    constructor(props: IAccordionPaneProps) {
        super(props);
        this.store = new MultiAccordionStore();
    }

    @State.bound
    private clickHandler() {
        if (this.props.onClick) {
            this.props.onClick(this.props.index);
        }
    }

    public render() {

        const isOpen = this.props.activeIndex === this.props.index;
        const classes = new CompositeClassName(Styles.header);
        const caretClasses = new CompositeClassName(Styles.caretIcon);
        caretClasses.addIf(isOpen, Styles.caretIconRotated);

        return (
            <>
                <div onClick={this.clickHandler} className={classes.classNames} data-automation-id={this.props.automationId || undefined}>
                    <Flex verticalSpacing="none" innerSpacing="none">
                        <Flex.Item>
                            <Icon iconName="caretRight" className={caretClasses.classNames}/>
                        </Flex.Item>
                        <Flex.Item className={Styles.headerTitle}>{this.props.title}</Flex.Item>
                    </Flex>
                </div>
                {isOpen &&
                    <div>
                        {this.props.children}
                    </div>
                }
            </>
        );
    }
}