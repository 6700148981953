import ModalServiceRegistry from "@Toolkit/ReactClient/Components/ModalService/ModalServiceRegistry";
import StatusChangeReasonDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/StatusChangeReasonDialog/StatusChangeReasonDialogParams";
import StatusChangeReasonDialog from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/StatusChangeReasonDialog/StatusChangeReasonDialog";
import CareActivityStatusChangeNotPossibleDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityStatusChangeNotPossiblePanel/CareActivityStatusChangeNotPossibleDialogParams";
import CareActivityStatusChangeNotPossiblePanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityStatusChangeNotPossiblePanel";
import AccessControlModalParams from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/AccessControlModal/AccessControlModalParams";
import AccessControlModal from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/AccessControlModal/AccessControlModal";
import ServiceRequestDefinitionDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestDefinitionDialog/ServiceRequestDefinitionDialogParams";
import ServiceRequestDefinitionDialog from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestDefinitionDialog/ServiceRequestDefinitionDialog";
import RoleEditorDialogParams from "@HisPlatform/BoundedContexts/UserManagement/Components/Controls/PointOfCareRoleEditor/RoleEditorDialogParams";
import RoleEditorDialog from "@HisPlatform/BoundedContexts/UserManagement/Components/Controls/PointOfCareRoleEditor/RoleEditorDialog";
import CopyPointOfCareRolesModalParams from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserPanel/Modals/CopyPointOfCareRolesModal/CopyPointOfCareRolesModalParams";
import CopyPointOfCareRolesModal from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserPanel/Modals/CopyPointOfCareRolesModal/CopyPointOfCareRolesModal";
import CopyUserGroupModal from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserPanel/Modals/CopyUserGroupModal/CopyUserGroupModal";
import CopyUserGroupModalParams from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserPanel/Modals/CopyUserGroupModal/CopyUserGroupModalParams";
import AddTextBlockDialog from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/AddTextBlockDialog/AddTextBlockDialog";
import AddTextBlockDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/AddTextBlockDialog/AddTextBlockDialogParams";
import InsurerOrganizationDetailPanelModalParams from "@HisPlatform/BoundedContexts/Finance/Components/InsurerOrganizationModal/InsurerOrganizationDetailPanelModalParams";
import InsurerOrganizationDetailPanelModal from "@HisPlatform/BoundedContexts/Finance/Components/InsurerOrganizationModal/InsurerOrganizationDetailPanelModal";
import PatientsCareActivityDetailsModalParams from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/EHRWidget/PatientsCareActivityDetailsModalParams";
import PatientsCareActivityDetailsModal from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/EHRWidget/PatientsCareActivityDetailsModal";
import CreateNewDocumentDialogParams from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/CreateNewDocumentDialog/CreateNewDocumentDialogParams";
import CreateNewDocumentDialog from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/CreateNewDocumentDialog/CreateNewDocumentDialog";
import TextBlockHistoryDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/TextBlockHistoryDialog/TextBlockHistoryDialogParams";
import TextBlockHistoryDialog from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/TextBlockHistoryDialog/TextBlockHistoryDialog";
import PreviousTextBlocksDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/PreviousTextBlocksDialog/PreviousTextBlocksDialogParams";
import PreviousTextBlocksDialog from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/PreviousTextBlocksDialog/PreviousTextBlocksDialog";
import DocumentSnippetrSelectorDialog from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/DocumentSnippetSelectorDialog/DocumentSnippetSelectorDialog";
import DocumentSnippetSelectorDialogParams from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/DocumentSnippetSelectorDialog/DocumentSnippetSelectorDialogParams";
import DocumentPreviewModalParams from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Modals/DocumentPreviewModal/DocumentPreviewModalParams";
import DocumentPreviewModal from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Modals/DocumentPreviewModal/DocumentPreviewModal";
import TemplateInfoDialogParams from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TemplateInfoDialog/TemplateInfoDialogParams";
import TemplateInfoDialog from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TemplateInfoDialog/TemplateInfoDialog";
import DocumentHistoryModal, { DocumentHistoryModalParams } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Modals/DocumentHistoryModal/DocumentHistoryModal";
import AppointmentCancellationDialogParams from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/AppointmentCancellationDialog/AppointmentCancellationDialogParams";
import AppointmentCancellationDialog from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/AppointmentCancellationDialog/AppointmentCancellationDialog";
import BookSlotDialog from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/BookAppointmentDialog/BookSlotDialog";
import BookSlotDialogParams from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/BookAppointmentDialog/BookSlotDialogParams";
import ServiceRequestAppointmentModalParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestAppointmentModal/ServiceRequestAppointmentModalParams";
import ServiceRequestAppointmentModal from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestAppointmentModal/ServiceRequestAppointmentModal";
import UserMultiSelectDialogParams from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/AccessControlModal/Modals/UserMultiSelectDialog/UserMultiSelectDialogParams";
import UserMultiSelectDialog from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/AccessControlModal/Modals/UserMultiSelectDialog/UserMultiSelectDialog";
import UserGroupMultiSelectDialogParams from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/AccessControlModal/Modals/UserGroupMultiSelectDialog/UserGroupMultiSelectDialogParams";
import UserGroupMultiSelectDialog from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/AccessControlModal/Modals/UserGroupMultiSelectDialog/UserGroupMultiSelectDialog";
import AutomaticInsertTextBlockContentDialog from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/AutomaticInsertTextBlockContentDialog/AutomaticInsertTextBlockContentDialog";
import AutomaticInsertTextBlockContentDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/AutomaticInsertTextBlockContentDialog/AutomaticInsertTextBlockContentDialogParams";
import InsertServiceRequestResultDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/InsertServiceRequestReuslt/InsertServiceRequestResultDialogParams";
import InsertServiceRequestResultDialog from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/InsertServiceRequestReuslt/InsertServiceRequestResultDialog";
import TimePhaseEditorDialogParams from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/TimePhaseEditorDialog/TimePhaseEditorDialogParams";
import TimePhaseEditorDialog from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/TimePhaseEditorDialog/TimePhaseEditorDialog";
import AppointmentScheduleDefinitionBaseDataModalParams from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/CreateNewItemModal/AppointmentScheduleDefinitionBaseDataModalParams";
import AppointmentScheduleDefinitionBaseDataModal from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/CreateNewItemModal/AppointmentScheduleDefinitionBaseDataModal";
import EditPlanningPeriodModalParams from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/EditPlanningPeriodModal/EditPlanningPeriodModalParams";
import EditPlanningPeriodModal from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/EditPlanningPeriodModal/EditPlanningPeriodModal";
import SchedulingServiceEditorDialogParams from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/SchedulingServiceEditorDialog/SchedulingServiceEditorDialogParams";
import SchedulingServiceEditorDialog from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/SchedulingServiceEditorDialog/SchedulingServiceEditorDialog";
import ResourceBlockingEditorDialogParams from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/ResourceBlockingEditorDialog/ResourceBlockingEditorDialogParams";
import ResourceBlockingEditorDialog from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/ResourceBlockingEditorDialog/ResourceBlockingEditorDialog";
import CantCloseCareActivityDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CantCloseCareActivityPanel/CantCloseCareActivityDialogParams";
import CantCloseCareActivityPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CantCloseCareActivityPanel/CantCloseCareActivityPanel";
import ServiceRequestAppointmentWarningDialogParams from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/PractitionerAppointmentsPanel/ServiceRequestAppointmentWarningDialog/ServiceRequestAppointmentWarningDialogParams";
import ServiceRequestAppointmentWarningDialog from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/PractitionerAppointmentsPanel/ServiceRequestAppointmentWarningDialog/ServiceRequestAppointmentWarningDialog";
import PropertyVersionsModalParams from "@HisPlatform/BoundedContexts/Organization/Components/Panels/PropertyGroupsPanel/PropertyVesrionsModal/PropertyVersionsModalParams";
import PropertyVersionModal from "@HisPlatform/BoundedContexts/Organization/Components/Panels/PropertyGroupsPanel/PropertyVesrionsModal/PropertyVersionModal";
import FormFieldModal from "@HisPlatform/BoundedContexts/Productivity/Components/Panels/WorklistDefinitionMasterDetailPanel/FormFieldModal";
import FormFieldModalParams from "@HisPlatform/BoundedContexts/Productivity/Components/Panels/WorklistDefinitionMasterDetailPanel/FormFieldModalParams";
import AutoLogoutDialog from "@HisPlatform/Model/DomainModel/UserContext/ActiveSessionManager/AutoLogoutDialog";
import AutoLogoutDialogParams from "@HisPlatform/Model/DomainModel/UserContext/ActiveSessionManager/AutoLogoutDialogParams";
import RoutingFrameCaseRegistry from "@HisPlatform/Services/Implementation/RoutingFrameCaseRegistry/RoutingFrameCaseRegistry";
import OrganizationUnitDefinitionSelectorDialogParams from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/OrganizationUnitDefinitionSelectorDialogParams";
import OrganizationUnitDefinitionSelectorDialog from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/OrganizationUnitDefinitionSelectorDialog";

export default function configureHisPlatformModals(registry: ModalServiceRegistry) {
    // TODO:HISP-6528
    registry.register(StatusChangeReasonDialogParams.type, StatusChangeReasonDialog);
    registry.register(CareActivityStatusChangeNotPossibleDialogParams.type, CareActivityStatusChangeNotPossiblePanel);
    registry.register(CantCloseCareActivityDialogParams.type, CantCloseCareActivityPanel);
    registry.register(AccessControlModalParams.type, AccessControlModal);
    registry.register(ServiceRequestDefinitionDialogParams.type, ServiceRequestDefinitionDialog);
    registry.register(RoleEditorDialogParams.type, RoleEditorDialog);
    registry.register(CopyPointOfCareRolesModalParams.type, CopyPointOfCareRolesModal);
    registry.register(CopyUserGroupModalParams.type, CopyUserGroupModal);
    registry.register(CreateNewDocumentDialogParams.type, CreateNewDocumentDialog);
    registry.register(AddTextBlockDialogParams.type, AddTextBlockDialog);
    registry.register(InsurerOrganizationDetailPanelModalParams.type, InsurerOrganizationDetailPanelModal);
    registry.register(PatientsCareActivityDetailsModalParams.type, PatientsCareActivityDetailsModal);
    registry.register(TextBlockHistoryDialogParams.type, TextBlockHistoryDialog);
    registry.register(PreviousTextBlocksDialogParams.type, PreviousTextBlocksDialog);
    registry.register(DocumentSnippetSelectorDialogParams.type, DocumentSnippetrSelectorDialog);
    registry.register(DocumentPreviewModalParams.type, DocumentPreviewModal);
    registry.register(TemplateInfoDialogParams.type, TemplateInfoDialog);
    registry.register(DocumentHistoryModalParams.type, DocumentHistoryModal);
    registry.register(AppointmentCancellationDialogParams.type, AppointmentCancellationDialog);
    registry.register(BookSlotDialogParams.type, BookSlotDialog);
    registry.register(ServiceRequestAppointmentModalParams.type, ServiceRequestAppointmentModal);
    registry.register(UserMultiSelectDialogParams.type, UserMultiSelectDialog);
    registry.register(UserGroupMultiSelectDialogParams.type, UserGroupMultiSelectDialog);
    registry.register(AutomaticInsertTextBlockContentDialogParams.type,  AutomaticInsertTextBlockContentDialog);
    registry.register(InsertServiceRequestResultDialogParams.type,  InsertServiceRequestResultDialog);
    registry.register(AppointmentScheduleDefinitionBaseDataModalParams.type,  AppointmentScheduleDefinitionBaseDataModal);
    registry.register(TimePhaseEditorDialogParams.type, TimePhaseEditorDialog);
    registry.register(EditPlanningPeriodModalParams.type, EditPlanningPeriodModal);
    registry.register(SchedulingServiceEditorDialogParams.type, SchedulingServiceEditorDialog);
    registry.register(ResourceBlockingEditorDialogParams.type, ResourceBlockingEditorDialog);
    registry.register(ServiceRequestAppointmentWarningDialogParams.type, ServiceRequestAppointmentWarningDialog);
    registry.register(PropertyVersionsModalParams.type, PropertyVersionModal);
    registry.register(FormFieldModalParams.type, FormFieldModal);
    registry.register(AutoLogoutDialogParams.type, AutoLogoutDialog);
    registry.register(OrganizationUnitDefinitionSelectorDialogParams.type, OrganizationUnitDefinitionSelectorDialog);
}
