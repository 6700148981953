import { ISidebarPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import PatientMainMenu from "./PatientMainMenu";
import React from "react";
import ShowNewPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowNewPatientScreenAction.g";

export default function PatientMainMenuSideBar(props: ISidebarPropsBase) {
    return (
        <PatientMainMenu
            {...props}
            isNew={props.action?.id === ShowNewPatientScreenAction.id}
            currentlyScrolledElementId={null}
        />
    );
}