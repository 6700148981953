import State from "@Toolkit/ReactClient/Common/StateManaging";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import ScopeIdentifier from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ScopeIdentifier";
import Base64Converter from "@Toolkit/CommonWeb/Base64";

export default class NewBinaryDocument {
    @State.observable.ref public type: DocumentTypeId = null;
    @State.observable.ref public name = "";
    @State.observable.ref public description = "";
    @State.observable.ref public isUploaded = false;
    public readonly contentType: string;

    constructor(
        public readonly scopeIdentifiers: ScopeIdentifier[],
        public readonly file: File
    ) {
        this.contentType = file.type;

        const extensionDotIndex = file.name.lastIndexOf(".");
        this.name = extensionDotIndex > 0 ? file.name.substring(0, extensionDotIndex) : file.name;
    }

    @State.action.bound
    public setType(value: DocumentTypeId) {
        this.type = value;
    }

    @State.action.bound
    public setName(value: string) {
        this.name = value;
    }

    @State.action.bound
    public setDescription(value: string) {
        this.description = value;
    }

    public async getContentAsBase64Async() {
        const bytes = await this.file.toUint8ArrayAsync();
        return Base64Converter.fromByteArray(bytes);
    }

    @State.action
    public uploadedSuccessfully() {
        this.isUploaded = true;
    }
}