import * as Ui from "@CommonControls";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import Styles from "./Dashboard.less";
import { iconNameType } from "@CommonControls/Icon";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { DashboardContextAdapter } from "@CommonControls/Dashboard/DashboardContext";
import { DashboardMode } from "@CommonControls/Dashboard/DashboardMode";

export interface IDashboardWidgetProps {
    _mode?: DashboardMode;
    _isOpen?: boolean;
    _onOpenStateChanged?: (widgetName: string, isOpen: boolean) => void;

    title: string;
    isCollapsible?: boolean;
    icon: iconNameType | React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    isCloseByDefault: boolean;
    name: string;
    containerHeight?: number;
    automationId?: string;
    iconStyle?: React.CSSProperties;
    children?: React.ReactNode;

    onHeaderClick?: () => void;
    toolbar?: React.ReactNode;

    smallContentStyle?: React.CSSProperties;
    smallContent?: React.ReactNode;

    colorStyle?: "dark" | "light";
}
@State.observer
class DashboardWidget extends React.Component<IDashboardWidgetProps, undefined> {

    public static defaultProps: Partial<IDashboardWidgetProps> = {
        colorStyle: "dark"
    };

    @State.action.bound
    private isOpenClickHandler() {
        this.props._onOpenStateChanged(this.props.name, !this.props._isOpen);
    }

    public getContainerClass = () => {
        const classes = new CompositeClassName(this.props.className);
        classes.addIf(this.props.isCollapsible, Styles.headerCollapsable);
        classes.addIf(this.props._isOpen && this.props.isCollapsible, Styles.headerCollapsableRotateIcon);
        classes.add(this.props._mode && this.props._mode === "small" ? Styles.dashboardWidgetSmall : Styles.dashboardWidget);
        classes.addIf(this.props.colorStyle === "light", Styles.colorStyleLight);
        return classes.classNames;
    };

    private renderHeader() {
        const headerAutomationId = this.props.automationId ? `${this.props.automationId}_Header` : undefined;
        return (
            <div className={Styles.header} role="header" data-automation-id={headerAutomationId} onClick={this.props.onHeaderClick} style={{ cursor: this.props.onHeaderClick ? "pointer" : undefined }}>
                <div className={Styles.smallContent}>
                    {this.renderIcon()}
                </div>
                <div className={Styles.title} title={this.props.title}>
                    {this.props.title}
                </div>
                {this.props.toolbar && (
                    <div className={Styles.toolbar}>{this.props.toolbar}</div>
                )}
                {this.props.isCollapsible &&
                    <div className={Styles.iconContainer} onClick={this.props.isCollapsible ? this.isOpenClickHandler : null} data-automation-id={this.props.automationId ? `${this.props.automationId}_OpenButton` : undefined}>
                        <Ui.Icon visualStyle="white" iconName="chevronDown" automationId={`${headerAutomationId}_chevronDown`} />
                    </div>}
            </div>
        );
    }

    public render() {
        const containerAutomationId = this.props.automationId ? `${this.props.automationId}_Container` : undefined;

        if (this.props._mode === "normal") {
            return (
                <div className={this.getContainerClass()} data-automation-id={containerAutomationId}>
                    {this.renderHeader()}
                    {this.renderContent()}
                </div>
            );
        } else {
            return (
                <div
                    className={this.getContainerClass()}
                    data-automation-id={containerAutomationId}
                    onClick={this.props.onHeaderClick}
                    style={{ ...this.props.smallContentStyle, cursor: this.props.onHeaderClick ? "pointer" : undefined }}
                >
                    <Popper
                        fade={true}
                        wrapperElementType="div"
                        className={Styles.smallContent}
                        tooltipContent={(
                            <div className={Styles.dashboardWidget}>
                                <div className={Styles.header} >
                                    <div className={Styles.title}>
                                        {this.props.title}
                                    </div>
                                </div>
                                <div className={Styles.body}>{this.renderContent()}</div>
                            </div>
                        )}>
                        {this.props.smallContent ?? this.renderIcon()}
                    </Popper>
                </div >
            );
        }
    }

    private renderContent() {
        const contentAutomationId = this.props.automationId ? `${this.props.automationId}_Content` : undefined;
        
        if (this.props.isCollapsible) {
            return (
                (this.props._isOpen || this.props._mode === "small") && (
                    <div className={Styles.body} data-automation-id={contentAutomationId}>
                        <div className={Styles.content}>
                            {this.props.children}
                        </div>
                    </div>
                )
            );
        } else {
            return (
                <div className={Styles.body} data-automation-id={contentAutomationId}>
                    <div className={Styles.content}>
                        {this.props.children}
                    </div>
                </div>
            );
        }
    }

    public renderIcon() {
        const headerAutomationId = this.props.automationId ? `${this.props.automationId}_Header` : undefined;
        return typeof this.props.icon === "string" ? (
            <Ui.Icon size="normal" visualStyle="white" iconName={this.props.icon as iconNameType} automationId={`${headerAutomationId}_${this.props.icon}`} style={this.props.iconStyle} />
        ) : (
            <>
                {this.props.icon}
            </>
        );
    }
}

export default connect(
    DashboardWidget,
    new DashboardContextAdapter<IDashboardWidgetProps>((d, props) => ({
        _mode: d.mode,
        _isOpen: d.isOpen(props.name),
        _onOpenStateChanged: d.setOpenState
    })),
);