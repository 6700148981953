import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import NDataScreenStore from "./NDataScreenStore";


const { 
    ContextComponent: NDataScreenStoreContext, 
    StoreProvider: NDataScreenStoreProvider, 
    useStore: useNDataScreenStore,
    provideStore: provideNDataScreenStore,
} = createPanelStoreProvider(NDataScreenStore);

export {
    NDataScreenStoreContext,
    NDataScreenStoreProvider,
    useNDataScreenStore,
    provideNDataScreenStore
};