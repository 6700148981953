
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import EnumReferenceEditorDialogStore from "./EnumReferenceEditorDialogStore";

const { 
    ContextComponent: EnumReferenceEditorDialogStoreContext, 
    StoreProvider: EnumReferenceEditorDialogStoreProvider, 
    useStore: useEnumReferenceEditorDialogStore,
    provideStore: provideEnumReferenceEditorDialogStore,
} = createPanelStoreProvider(EnumReferenceEditorDialogStore);

export {
    EnumReferenceEditorDialogStoreContext,
    EnumReferenceEditorDialogStoreProvider,
    useEnumReferenceEditorDialogStore,
    provideEnumReferenceEditorDialogStore
};