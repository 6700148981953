import ContagiousPatientReportType from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReportType";
import ContagiousPatientReportState from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReportState";
import ConditionId from "@Primitives/ConditionId.g";
import ContagiousPatientReportId from "@Primitives/ContagiousPatientReportId.g";
import PatientId from "@Primitives/PatientId.g";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import LockingEntityStoreBase from "@Toolkit/CommonWeb/Model/LockingEntityStoreBase";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityId from "@Primitives/CareActivityId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ActionIdentifier from "@Primitives/ActionIdentifier.g";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import OnsetReportData from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientOnsetReport";
import EndReportData from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientEndReportData";

export default class ContagiousPatientReport extends LockingEntityStoreBase<ContagiousPatientReportId> implements IDirtyChecked {

    @State.observable public patientId: PatientId;
    @State.observable public conditionId: ConditionId;
    @State.observable public recordingCareActivityId: CareActivityId;
    @State.observable public recordingPointOfCareId: PointOfCareId;
    @State.observable.ref public type: ContagiousPatientReportType = ContagiousPatientReportType.OnsetReport;
    @State.observable.ref public ehiErrors: EhiError[] = [];
    @State.observable public state: ContagiousPatientReportState = ContagiousPatientReportState.UnderRecording;
    @State.observable.ref public onsetData: OnsetReportData = new OnsetReportData();
    @State.observable.ref public endReportData: EndReportData = new EndReportData();
    public possibleActions: ActionIdentifier[];

    private _dirtyChecker: StoreDirtyChecker<ContagiousPatientReport> = new StoreDirtyChecker<ContagiousPatientReport>();
    public get propertyNamesExcludedFromDirtyCheck(): string[] {
        return this.vPropertyNamesExcludedFromDirtyCheck;
    }

    protected get vPropertyNamesExcludedFromDirtyCheck() {
        return [
            "_dirtyChecker",
            "countryId"
        ];
    }

    public constructor(
        isNew: boolean = false
    ) {
        super(isNew);
        this.validationResults = [];
    }

    @State.bound
    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    @State.bound
    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }

    @State.action.bound
    public setType(value: ContagiousPatientReportType) {
        this.type = value;
    }

    @State.action.bound
    public setOnsetData(value: OnsetReportData) {
        this.onsetData = value;
    }

    @State.action.bound
    public setEndData(value: EndReportData) {
        this.endReportData = value;
    }
}
