import React from "react";
import { useDrop } from "react-dnd";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import Styles from "./FormLayoutEditor.less";
import { useFormLayoutEditorStore } from "./FormLayoutEditorStoreProvider";
import IDragData from "./IDragData";
import EditableLayoutColumnStore from "./Model/EditableLayoutColumnStore";
import { useFormLayoutContext } from "@Toolkit/FormEngine/Panels/FormLayoutContext";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IColumnDropZoneProps {
    collection: IObservableArray<EditableLayoutColumnStore>;
    indexToInsert: number;
    isFirst?: boolean;
}

function ColumnDropZone(props: IColumnDropZoneProps) {

    const store = useFormLayoutEditorStore();
    const editorContext = useFormLayoutContext();

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ["column", "editor", "block", "fragment"],
        drop: (item: IDragData, monitor) => {
            store.moveIntoColumnCollection(item, props.collection, props.indexToInsert, isNullOrUndefined(editorContext?.compositeDataReferences) ? null : editorContext?.compositeDataReferences);
        },
        canDrop: (item, monitor) => {
            return true;
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    }, [props.collection, props.indexToInsert, editorContext?.compositeDataReferences]);

    const isActive = isOver && canDrop;
    const isHighlighted = store.areDropZonesHighlighted;

    return (
        <div
            className={combineClasses(
                Styles.dropzone,
                Styles.column,
                props.isFirst && Styles.firstColumn,
                isActive && Styles.active,
                isHighlighted && Styles.highlight
            )}
            ref={drop}
        />
    );

}

export default State.observer(ColumnDropZone);