exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StandaloneUnregisteredPatientAppointmentPage_toolbar-working-area_1d0Z3 {\n  overflow: auto;\n}\n", ""]);

// exports
exports.locals = {
	"toolbar-working-area": "StandaloneUnregisteredPatientAppointmentPage_toolbar-working-area_1d0Z3",
	"toolbarWorkingArea": "StandaloneUnregisteredPatientAppointmentPage_toolbar-working-area_1d0Z3"
};