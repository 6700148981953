import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";

interface IInsurerOrganizationTypeSelectBoxDependencies {
    financeReferenceDataStore: FinanceReferenceDataStore;
}

interface IInsurerOrganizationTypeSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IInsurerOrganizationTypeSelectBoxDependencies;
}

@State.observer
class InsurerOrganizationTypeSelectBox extends React.Component<IInsurerOrganizationTypeSelectBoxProps> {

    private get dependencies() { return this.props._dependencies; }
    private get extensibleEnumStore() { return this.dependencies.financeReferenceDataStore.insurerOrganizationTypes; }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    InsurerOrganizationTypeSelectBox,
    new DependencyAdapter<IInsurerOrganizationTypeSelectBoxProps, IInsurerOrganizationTypeSelectBoxDependencies>(container => {
        return {
            financeReferenceDataStore: container.resolve("FinanceReferenceDataStore")
        };
    })
);