exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProfileManagementPanel_root_165tM {\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n}\n.ProfileManagementPanel_page-panel_3mMZH {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n", ""]);

// exports
exports.locals = {
	"root": "ProfileManagementPanel_root_165tM",
	"page-panel": "ProfileManagementPanel_page-panel_3mMZH",
	"pagePanel": "ProfileManagementPanel_page-panel_3mMZH"
};