import MedicationSubsidyOptionId from "@Primitives/MedicationSubsidyOptionId.g";
import SubsidyOptionRestriction from "./SubsidyOptionRestriction";
import ConditionId from "@Primitives/ConditionId.g";

export default class MedicationSubsidyOption {
    constructor(
        public id: MedicationSubsidyOptionId,
        public code: string,
        public description: string,
        public name: string,
        public indicationText: string,
        public restrictionConditions: ConditionId[],
        public optionRestrictions: SubsidyOptionRestriction[]
    ) { }
}