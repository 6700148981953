import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";


export interface IFormDefinitionImportDialogParams extends IModalParams {
}

// Result interface for dialogs only:
export interface IFormDefinitionImportDialogResult {
    dataToImport: string;
}

export default class FormDefinitionImportDialogParams implements IFormDefinitionImportDialogParams {
 
    public static type = "FormDefinitionImportDialog";
    public get type() { return FormDefinitionImportDialogParams.type; }
}