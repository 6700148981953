import * as Proxy from "@HisPlatform/BoundedContexts/Surgery/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import Form from "@Toolkit/FormEngine/Model/Form";
import { restoreCustomFormDataStoreAsync, mapToFormInstanceContentDto } from "@HisPlatform/BoundedContexts/FormEngine/ApplicationLogic/ApiAdapter/FormEngineMappers";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SurgeryTypeDefinitionId from "@Primitives/SurgeryTypeDefinitionId.g";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import LockAcquirerOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockAcquirerOperationInfo";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import ISurgeryTypeDefinition from "@HisPlatform/BoundedContexts/Surgery/ApplicationLogic/Model/ISurgeryTypeDefinition";
import SurgeryId from "@Primitives/SurgeryId.g";
import PatientId from "@Primitives/PatientId.g";
import SearchSurgeryTypeDefinitionsQueryOrderingFields from "@HisPlatform/BoundedContexts/Surgery/Api/SurgeryTypes/Enum/SearchSurgeryTypeDefinitionsQueryOrderingFields.g";

@Di.injectable()
export default class SurgeryApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("ISurgeryTypesClient") private readonly typeApiClient: Proxy.ISurgeryTypesClient,
        @Di.inject("ISurgeryCoreClient") private readonly coreApiClient: Proxy.ISurgeryCoreClient,
        @Di.inject("IFormEngineReferenceDataStore") private readonly formEngineReferenceDataStore: IFormEngineReferenceDataStore
    ) {
        super();
    }

    @State.bound
    public getNewSurgeryTypeDefinitionAsync() {
        return this.processOperationAsync(
            new SimpleStore<IForm>(),
            async target => {
                const response = await this.typeApiClient.getNewSurgeryTypeDefinitionCommandAsync(CreateRequestId(), new Proxy.GetNewSurgeryTypeDefinitionControllerDto());

                const definition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(response.surgeryTypeData!.formDefinitionId);

                target.operationInfo = createOperationInfo(response);

                const form = new Form(
                    SurgeryTypeDefinitionId.new,
                    RowVersion.initial,
                    response.surgeryTypeData!.formDefinitionId!,
                    await restoreCustomFormDataStoreAsync(response.surgeryTypeData!.formData as any, definition, this.formEngineReferenceDataStore),
                    mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult)
                );

                target.value = form;
            });
    }

    @State.bound
    public createSurgeryTypeDefinitionAsync(form: IForm, isValidateOnly: boolean) {
        return this.processOperationAsync(
            new SimpleStore<IForm>(),
            async target => {

                const formInstanceContentDto = mapToFormInstanceContentDto(form.data);

                const request = new Proxy.CreateSurgeryTypeDefinitionControllerDto({
                    isValidateOnly: isValidateOnly,
                    formData: formInstanceContentDto.data as any
                });
                const response = await this.typeApiClient.createSurgeryTypeDefinitionCommandAsync(CreateRequestId(), request);

                const definition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(response.surgeryTypeData!.formDefinitionId);

                target.operationInfo = createOperationInfo(response);

                // TODO what if no data due to error???
                const responseForm = new Form(
                    response.surgeryTypeData!.surgeryTypeDefinitionId,
                    response.rowVersion,
                    response.surgeryTypeData!.formDefinitionId,
                    await restoreCustomFormDataStoreAsync(response.surgeryTypeData!.formData as any, definition, this.formEngineReferenceDataStore),
                    mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult)
                );

                responseForm.lockInfo = (target.operationInfo as LockAcquirerOperationInfo)?.lockInfo;
                target.value = responseForm;
            });
    }

    @State.bound
    public quickSearchSurgeryTypeDefinitionAsync(freeText: string, maxResultCount: number) {
        return this.processOperationAsync(
            new SimpleStore<SurgeryTypeDefinitionId[]>(),
            async target => {
                const response = await this.typeApiClient.quickSearchSurgeryTypeDefinitionsQueryAsync(
                    CreateRequestId(),
                    maxResultCount.toString(),
                    freeText
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.results;
            }
        );
    }

    @State.bound
    public searchSurgeryTypeDefinitionAsync(filterText: string, ordering: IOrderingState, paging: IPagingState)
        : Promise<PagedItemStore<SurgeryTypeDefinitionId>> {

        return this.processOperationAsync(
            new PagedItemStore<SurgeryTypeDefinitionId>(),
            async (target) => {
                const columnName = ordering && ordering.columnId as string;
                const response = await this.typeApiClient.searchSurgeryTypeDefinitionsQueryAsync(
                    CreateRequestId(),
                    new Proxy.SearchSurgeryTypeDefinitionsControllerDto({
                        searchText: filterText,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfSearchSurgeryTypeDefinitionsQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfSearchSurgeryTypeDefinitionsQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: SearchSurgeryTypeDefinitionsQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.totalCount = response.results && response.results.totalCount;
                target.items = response.results && response.results.values;
            }
        );
    }

    @State.bound
    public loadSurgeryTypeDefinitionsAsync(ids: SurgeryTypeDefinitionId[]): Promise<SimpleStore<ISurgeryTypeDefinition[]>> {
        return this.processOperationAsync(
            new SimpleStore<ISurgeryTypeDefinition[]>(),
            async (target) => {
                const response = await this.typeApiClient.getSurgeryTypeDefinitionsByIdsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetSurgeryTypeDefinitionsByIdsControllerDto({ surgeryTypeDefinitionIds: ids })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.surgeryTypeDefinitions.map(this.mapSurgeryTypeDefinition);
            });
    }

    private mapSurgeryTypeDefinition(dto: Proxy.SurgeryTypeDefinitionDecomposedDto): ISurgeryTypeDefinition {
        return {
            id: dto.surgeryTypeDefinitionId,
            code: dto.code,
            name: dto.name
        } as ISurgeryTypeDefinition;
    }

    @State.bound
    public getAllIdsAsync(): Promise<SimpleStore<SurgeryTypeDefinitionId[]>> {
        return this.processOperationAsync(
            new SimpleStore<SurgeryTypeDefinitionId[]>(),
            async target => {
                const response = await this.typeApiClient.getAllSurgeryTypeDefinitionIdsQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.surgeryTypeDefinitionIds.map(id => id);
            }
        );
    }

    @State.bound
    public getSurgeryTypeDefinitionAsync(id: SurgeryTypeDefinitionId) {
        return this.processOperationAsync(
            new SimpleStore<IForm>(),
            async target => {

                const request = new Proxy.GetSurgeryTypeDefinitionControllerDto({
                    surgeryTypeDefinitionId: id
                });

                const response = await this.typeApiClient.getSurgeryTypeDefinitionCommandAsync(CreateRequestId(), request);

                const definition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(response.surgeryTypeData!.formDefinitionId);

                target.operationInfo = createOperationInfo(response);

                const form = new Form(
                    SurgeryTypeDefinitionId.new,
                    RowVersion.initial,
                    response.surgeryTypeData!.formDefinitionId!,
                    await restoreCustomFormDataStoreAsync(response.surgeryTypeData!.formData as any, definition, this.formEngineReferenceDataStore),
                    mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult)
                );

                form.lockInfo = (target.operationInfo as LockAcquirerOperationInfo)?.lockInfo;

                target.value = form;
            });
    }

    @State.bound
    public updateSurgeryTypeDefinitionAsync(id: SurgeryTypeDefinitionId, form: IForm, isValidateOnly: boolean) {
        return this.processOperationAsync(
            new SimpleStore<IForm>(),
            async target => {

                const formInstanceContentDto = mapToFormInstanceContentDto(form.data);

                const request = new Proxy.UpdateSurgeryTypeDefinitionControllerDto({
                    rowVersion: form.rowVersion,
                    surgeryTypeDefinitionId: id,
                    isValidateOnly: isValidateOnly,
                    formData: formInstanceContentDto.data as any
                });
                const response = await this.typeApiClient.updateSurgeryTypeDefinitionCommandAsync(CreateRequestId(), request);

                const definition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(response.surgeryTypeData!.formDefinitionId);

                target.operationInfo = createOperationInfo(response);

                // TODO what if no data due to error???
                const responseForm = new Form(
                    response.surgeryTypeData!.surgeryTypeDefinitionId,
                    response.rowVersion,
                    response.surgeryTypeData!.formDefinitionId,
                    await restoreCustomFormDataStoreAsync(response.surgeryTypeData!.formData as any, definition, this.formEngineReferenceDataStore),
                    mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult)
                );

                responseForm.lockInfo = form.lockInfo;
                target.value = responseForm;
            });
    }

    @State.bound
    public getNewSurgeryAsync(surgeryTypeDefinitionId: SurgeryTypeDefinitionId, patientId: PatientId, isUrgent?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<IForm>(),
            async target => {
                const response = await this.coreApiClient.getNewSurgeryCommandAsync(CreateRequestId(), new Proxy.GetNewSurgeryControllerDto({
                    surgeryTypeDefinitionId: surgeryTypeDefinitionId,
                    patientId: patientId,
                    isUrgent: isUrgent
                }));

                const definition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(response.surgeryData!.formDefinitionId);

                target.operationInfo = createOperationInfo(response);

                const form = new Form(
                    SurgeryTypeDefinitionId.new,
                    RowVersion.initial,
                    response.surgeryData!.formDefinitionId!,
                    await restoreCustomFormDataStoreAsync(response.surgeryData!.formData as any, definition, this.formEngineReferenceDataStore),
                    mapValidationResults(response.validationResult as unknown as IServerCompositeValidationResult)
                );

                target.value = form;
            });
    }

    @State.bound
    public createSurgeryAsync(form: IForm, surgeryTypeDefinitionId: SurgeryTypeDefinitionId, requestLock: boolean, isValidateOnly: boolean) {
        return this.processOperationAsync(
            new SimpleStore<IForm>(),
            async target => {

                const formInstanceContentDto = mapToFormInstanceContentDto(form.data);

                const request = new Proxy.CreateSurgeryControllerDto({
                    requestLock: requestLock,
                    isValidateOnly: isValidateOnly,
                    formData: formInstanceContentDto.data as any,
                    surgeryTypeDefinitionId: surgeryTypeDefinitionId
                });
                const response = await this.coreApiClient.createSurgeryCommandAsync(CreateRequestId(), request);

                const definition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(response.surgeryData!.formDefinitionId);

                target.operationInfo = createOperationInfo(response);

                // TODO what if no data due to error???
                const responseForm = new Form(
                    response.surgeryData!.id,
                    response.rowVersion,
                    response.surgeryData!.formDefinitionId,
                    await restoreCustomFormDataStoreAsync(response.surgeryData!.formData as any, definition, this.formEngineReferenceDataStore),
                    mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult)
                );

                responseForm.lockInfo = (target.operationInfo as LockAcquirerOperationInfo)?.lockInfo;
                target.value = responseForm;
            });
    }

    @State.bound
    public getSurgeryAsync(id: SurgeryId) {
        return this.processOperationAsync(
            new SimpleStore<IForm>(),
            async target => {

                const request = new Proxy.GetSurgeryControllerDto({
                    requestLock: true,
                    surgeryId: id
                });

                const response = await this.coreApiClient.getSurgeryCommandAsync(CreateRequestId(), request);

                const definition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(response.surgeryData!.formDefinitionId);

                target.operationInfo = createOperationInfo(response);

                const form = new Form(
                    SurgeryTypeDefinitionId.new,
                    RowVersion.initial,
                    response.surgeryData!.formDefinitionId!,
                    await restoreCustomFormDataStoreAsync(response.surgeryData!.formData as any, definition, this.formEngineReferenceDataStore),
                    mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult)
                );

                form.lockInfo = (target.operationInfo as LockAcquirerOperationInfo)?.lockInfo;

                target.value = form;
            });
    }

    @State.bound
    public updateSurgeryAsync(id: SurgeryId, form: IForm, releaseLockIfSuccessful: boolean, isValidateOnly: boolean) {
        return this.processOperationAsync(
            new SimpleStore<IForm>(),
            async target => {

                const formInstanceContentDto = mapToFormInstanceContentDto(form.data);

                const request = new Proxy.UpdateSurgeryControllerDto({
                    releaseLockIfSuccessful: releaseLockIfSuccessful,
                    rowVersion: form.rowVersion,
                    surgeryId: id,
                    lockId: form.lockInfo?.lockId,
                    isValidateOnly: isValidateOnly,
                    formData: formInstanceContentDto.data as any
                });
                const response = await this.coreApiClient.updateSurgeryCommandAsync(CreateRequestId(), request);

                const definition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(response.surgeryData!.formDefinitionId);

                target.operationInfo = createOperationInfo(response);

                // TODO what if no data due to error???
                const responseForm = new Form(
                    response.surgeryData!.surgeryTypeDefinitionId,
                    response.rowVersion,
                    response.surgeryData!.formDefinitionId,
                    await restoreCustomFormDataStoreAsync(response.surgeryData!.formData as any, definition, this.formEngineReferenceDataStore),
                    mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult)
                );

                responseForm.lockInfo = form.lockInfo;
                target.value = responseForm;
            });
    }

}
