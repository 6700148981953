import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EuPerformanceStatementId from "@Primitives/EuPerformanceStatementId.g";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";
import LargeDataToken from "@Primitives/LargeDataToken.g";
import PerformanceStatementType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementType.g";

export default class EuPerformanceStatement extends EntityStoreBase<EuPerformanceStatementId> implements IDirtyChecked {

    @State.observable.ref public transactionCode?: string = null;
    @State.observable.ref public type: PerformanceStatementType = null;
    @State.observable.ref public reportValidityDate?: YearMonth = null;
    @State.observable.ref public largeDataIds: LargeDataToken[] = null;

    @State.action.bound
    public onLargeDataIdsChange(newValue: LargeDataToken[]) {
        this.largeDataIds = newValue;
    }

    private _dirtyChecker = new StoreDirtyChecker();

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "operationInfo"
    ];

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }
}