import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import { createAsyncErrorDispatcher, dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import DynamicValueConverter from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ValueConverters/DynamicValueConverter";
import _ from "@HisPlatform/Common/Lodash";
import ErrorListWorklistPanelView from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/ErrorListWorklistPanel/ErrorListWorklistPanelView";
import HunWorklistApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/HunWorklistApiAdapter";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import ModalServiceAdapter from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAdapter";
import NeakPerformanceStatementApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/NeakPerformanceStatementApiAdapter";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import ErrorListFilterStore from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/ErrorListWorklistPanel/Filters/ErrorListFilterStore";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import NeakPerformanceStatementId from "@Primitives/NeakPerformanceStatementId.g";
import EuPerformanceStatementId from "@Primitives/EuPerformanceStatementId.g";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import CantCloseCareActivityError from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CantCloseCareActivityError";
import CantCloseCareActivityDialogParams, { CantCloseCareActivityDialogNavigateTo, ICantCloseCareActivityDialogResult } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CantCloseCareActivityPanel/CantCloseCareActivityDialogParams";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import CareActivityId from "@Primitives/CareActivityId.g";
import InputDataClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/InputDataClientSideAction";
import StatusChangeReasonTypeId from "@Primitives/StatusChangeReasonTypeId.g";
import StatusChangeReasonId from "@Primitives/StatusChangeReasonId.g";
import StatusChangeReasonDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/StatusChangeReasonDialog/StatusChangeReasonDialogParams";
import StatusChangeReasonArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/StatusChangeReasonArgument";
import * as Ui from "@CommonControls";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import EuPerformanceStatementApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/EuPerformanceStatementApiAdapter";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import InputFormType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/InputFormType.g";
import PerformanceStatementType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementType.g";

interface IErrorListWorklistPanelDependencies {
    applicationContext: ApplicationContext;
    worklistApiAdapter: HunWorklistApiAdapter;
    useCaseRegistry: IUseCaseRegistry;
    dynamicValueConverter: DynamicValueConverter;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    dialogService: IDialogService;
    notificationService: INotificationService;
    neakPerformanceStatementApiAdapter: NeakPerformanceStatementApiAdapter;
    euPerformanceStatementApiAdapter: EuPerformanceStatementApiAdapter;
    toolkitLocalizationService: IToolkitLocalizationService;
    localizationService: ILocalizationService;
    dateTimeFormatProvider: IDateTimeFormatProvider;
}

export interface IErrorListWorklistPanelProps extends IRoutingFrameContentProps {
    _dependencies?: IErrorListWorklistPanelDependencies;
    _modalService?: IModalService;
    selectedPerformanceStatementRowId: string;
    selectedRowId: string;
    useCaseState: INDataUseCaseState;
    onChange: (rowId: string, useCaseState: INDataUseCaseState) => void;
    onBack?: () => void;
    onCantCloseCareActivityNavigateByAction: (careActivityId: CareActivityId, navigateTo: CantCloseCareActivityDialogNavigateTo) => void;
}

@State.observer
class ErrorListWorklistPanel extends React.Component<IErrorListWorklistPanelProps> {

    private runAsync = createAsyncErrorDispatcher(this);
    @State.observable.ref private worklistDefinition: IWorklistDefinition = null;
    @State.observable.ref private filterStore: ErrorListFilterStore = null;
    @State.observable.ref private title: string = null;

    private readonly currentUseCaseIdentifier = new UseCaseIdentifier("PerformanceStatement_ErrorList");

    public componentDidMount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(this.currentUseCaseIdentifier);
        this.runAsync(this.initializeAsync());
    }

    public componentWillUnmount() {
        if (this.filterStore) {
            this.filterStore.clearAllFilters();
        }
        this.props._dependencies.applicationContext.setCurrentUseCase(null);
    }

    private readonly initializeAsync = createInitialPanelLoader(this._initializeAsync);

    @State.bound
    private async _initializeAsync() {
        const [worklistDefinition] = await Promise.all(
            [
                this.props._dependencies.worklistApiAdapter.getErrorListBoundWorklistDefinitionAsync()
            ]);
        State.runInAction(() => {
            this.worklistDefinition = worklistDefinition.value;
        });
    }

    @State.action.bound
    private async onFilterStoreCreatedAsync(filterStore: IFilterStore) {
        this.filterStore = new ErrorListFilterStore(filterStore);

        const splitList = this.props.selectedPerformanceStatementRowId.split("_");

        const neakPerformanceStatementId = new NeakPerformanceStatementId(splitList[1].substring(4));
        const euPerformanceStatementId = new EuPerformanceStatementId(splitList[2].substring(2));

        if (neakPerformanceStatementId.value !== "0") {
            const neakSetter = filterStore["set_ErrorListBased_NeakPerformanceStatementId"];
            if (neakSetter) {
                neakSetter(neakPerformanceStatementId);
                const result = await this.props._dependencies.neakPerformanceStatementApiAdapter.getNeakPerformanceStatementByIdAsync(neakPerformanceStatementId);
                const title = this.formatTitle(result.type, result.reportValidityDate, result.transactionCode);
                this.setTitle(title);
            }
        } else {
            const euSetter = filterStore["set_ErrorListBased_EuPerformanceStatementId"];
            if (euSetter) {
                euSetter(euPerformanceStatementId);
                const result = await this.props._dependencies.euPerformanceStatementApiAdapter.getEuPerformanceStatementByIdAsync(euPerformanceStatementId);
                const title = this.formatTitle(result.type, result.reportValidityDate, result.transactionCode);
                this.setTitle(title);
            }
        }
    }

    @State.bound
    private formatTitle(type: PerformanceStatementType, reportValidityDate: YearMonth, transactionCode: string): string {
        const performanceStatementTypeLocalized = isNullOrUndefined(type) ? null : this.props._dependencies.localizationService.localizeEnum(PerformanceStatementType[type], "PerformanceStatementType");
        const performanceStatementType = isNullOrUndefined(performanceStatementTypeLocalized) ? null : performanceStatementTypeLocalized.Name;

        const titleDetail = [
            this.worklistDefinition.name,
            reportValidityDate.toMoment().format(this.props._dependencies.dateTimeFormatProvider.getMonthFormat()),
            performanceStatementType,
            transactionCode];

        return titleDetail.join(" | ");
    }

    @State.action.bound
    public setTitle(value: string) {
        this.title = value;
    }

    @State.bound
    private getExtendedFilterDescriptors() {
        return ErrorListFilterStore.getFilterDescriptors();
    }

    @State.bound
    private handleCantCloseCareActivityError(err: CantCloseCareActivityError) {
        dispatchAsyncErrors(this.props._modalService.showDialogAsync<ICantCloseCareActivityDialogResult>(
            new CantCloseCareActivityDialogParams(
                err.careActivityId,
                err.validationResult
            )
        ).then(result => {
            if (!isNullOrUndefined(result) && !isNullOrUndefined(result.navigateTo)) {
                this.props.onCantCloseCareActivityNavigateByAction(err.careActivityId, result.navigateTo);
            }
        }), this);

        return true;
    }

    @State.action.bound
    private async processClientSideActionAsync(clientSideAction: ClientSideActionDto) {
        if (clientSideAction instanceof InputDataClientSideAction) {
            return await this.processInputDataClientSideActionAsync(clientSideAction);
        } else {
            throw new Error(`Cannot process client side action of type ${clientSideAction.constructor.name}`);
        }
    }

    private async processInputDataClientSideActionAsync(clientSideAction: InputDataClientSideAction) {
        if (clientSideAction.inputFormType === InputFormType.GetStatusChangeReason) {
            const reasonType = clientSideAction.useCaseArguments.find(a => a.value instanceof StatusChangeReasonTypeId);
            if (isNullOrUndefined(reasonType)) {
                throw new Error(`ConfirmCareActivityDelete requires argument of type StatusChangeReasonTypeId`);
            }

            const dialogResult =
                await this.props._modalService.showDialogAsync<{ statusChangeReasonId: StatusChangeReasonId, additionalText: string }>(new StatusChangeReasonDialogParams(
                    reasonType.value,
                    null,
                    <Ui.InfoBox iconName="info_with_circle" iconVisualStyle="primary" />
                ));

            if (dialogResult && dialogResult.statusChangeReasonId) {
                return new StatusChangeReasonArgument(dialogResult.statusChangeReasonId, dialogResult.additionalText);
            } else {
                return null;
            }
        } else {
            throw new Error(`Cannot process inputFormType ${clientSideAction.inputFormType}`);
        }
    }

    public render() {

        if (this.initializeAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title="" />;
        }

        return (
            <>
                <ErrorListWorklistPanelView
                    worklistDefinition={this.worklistDefinition}
                    selectedRowId={this.props.selectedRowId}
                    useCaseState={this.props.useCaseState}
                    onChange={this.props.onChange}
                    onFilterStoreCreatedAsync={this.onFilterStoreCreatedAsync}
                    onGetExtendedFilterDescriptors={this.getExtendedFilterDescriptors}
                    onBack={this.props.onBack}
                    onPerformClientSideActionAsync={this.processClientSideActionAsync}
                    title={this.title}
                />
                <BusinessErrorHandler.Register businessErrorName="CantCloseCareActivityError" handler={this.handleCantCloseCareActivityError} />
            </>
        );
    }
}

export default connect(
    withHisErrorBoundary(ErrorListWorklistPanel),
    new DependencyAdapter<IErrorListWorklistPanelProps, IErrorListWorklistPanelDependencies>(c => {
        return {
            applicationContext: c.resolve("ApplicationContext"),
            worklistApiAdapter: c.resolve("HunWorklistApiAdapter"),
            useCaseRegistry: c.resolve("IUseCaseRegistry"),
            dynamicValueConverter: c.resolve("DynamicValueConverter"),
            organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
            dialogService: c.resolve("IDialogService"),
            notificationService: c.resolve("INotificationService"),
            neakPerformanceStatementApiAdapter: c.resolve("NeakPerformanceStatementApiAdapter"),
            euPerformanceStatementApiAdapter: c.resolve("EuPerformanceStatementApiAdapter"),
            toolkitLocalizationService: c.resolve("IToolkitLocalizationService"),
            localizationService: c.resolve("ILocalizationService"),
            dateTimeFormatProvider: c.resolve("IDateTimeFormatProvider")
        };
    }),
    new ModalServiceAdapter()
);
