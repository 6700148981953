import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React, { useEffect, useMemo, useState } from "react";
import * as Ui from "@CommonControls";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import PatientDocumentTypeSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/PatientRegister/PatientDocumentTypeSelectBox";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import { useErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import { IPatientIdentifierDependencies, IPatientIdentifierProps } from "@HisPlatform/Packages/Patients/Components/PatientIdentifier/PatientIdentifier";

export interface IPatientAdditionalHunSocialSecurityIdentifierDependencies extends IPatientIdentifierDependencies {
}

export interface IPatientAdditionalHunSocialSecurityIdentifierProps extends IPatientIdentifierProps {
    notes: string;
    onNotesChange: (value: string) => void;
}

function PatientAdditionalHunSocialSecurityIdentifier(props: IPatientAdditionalHunSocialSecurityIdentifierProps) {
    const errorDispatcher = useErrorDispatcher();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        props._dependencies.careReferenceDataStore.patientDocumentType.ensureAllLoadedAsync()
            .catch(errorDispatcher)
            .then(() => setIsLoaded(true));
    }, []);

    const patientIdentifierTypeIdentifierSystem = useMemo(() => {
        const patientDocumentType = props._dependencies.careReferenceDataStore.patientDocumentType.items.find(i => i.Entity.id.value === props.identifierTypeId?.value);
        return patientDocumentType?.Entity.identifierSystemId.value;
    }, [isLoaded, props.identifierTypeId]);

    const isReadOnly = useMemo(() => {
        return patientIdentifierTypeIdentifierSystem === "ReplacementHunSocialSecurityNumber";
    }, [patientIdentifierTypeIdentifierSystem]);

    return (
        <>
            <Ui.Flex>
                <Ui.Flex.Item xs={4}>
                    <Ui.TextBox
                        isReadOnly={isReadOnly}
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.Value}
                        value={props.identifierValue}
                        onChange={props.onIdentifierValueChange}
                        propertyIdentifier="IdentifierValue"
                        automationId="DocumentNumber"
                        mask="111 111 111"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={4}>
                    <PatientDocumentTypeSelectBox
                        includeNotSelectable={isReadOnly}
                        isReadOnly={isReadOnly}
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.DocumentType}
                        value={props.identifierTypeId}
                        onChange={props.onIdentifierTypeIdChange}
                        propertyIdentifier="DocumentTypeId"
                        automationId="DocumentTypeId"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex>
                <Ui.Flex.Item xs={4}>
                    <Ui.DatePicker
                        isReadOnly={isReadOnly}
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.ValidFrom}
                        value={props.validFrom}
                        onChange={props.onValidFromChange}
                        propertyIdentifier="ValidFrom"
                        automationId="ValidFrom"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={4}>
                    <Ui.DatePicker
                        isReadOnly={isReadOnly}
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.ValidTo}
                        value={props.validTo}
                        onChange={props.onValidToChange}
                        propertyIdentifier="ValidTo"
                        automationId="ValidTo"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
            {
                !isReadOnly && (
                    <Ui.Flex>
                        <Ui.Flex.Item xs={10}>
                            <Ui.TextBox
                                label={StaticCareResources.PatientRegister.PatientDocuments.Labels.Notes}
                                value={props.notes}
                                onChange={props.onNotesChange}
                                propertyIdentifier="Notes"
                                automationId="Notes"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                )
            }
        </>
    );
}

export default connect<IPatientAdditionalHunSocialSecurityIdentifierProps>(
    PatientAdditionalHunSocialSecurityIdentifier,
    new DependencyAdapter<IPatientAdditionalHunSocialSecurityIdentifierProps, IPatientAdditionalHunSocialSecurityIdentifierDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore")
        };
    }),
    new HisModalServiceAdapter());