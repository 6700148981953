import React, { useMemo } from "react";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Styles from "./MedicationSubsidyIcon.less";
import Badge from "@CommonControls/Badge";

interface IMedicationSubsidyIconProps {
    state: string;
    tooltipContent?: string | HTMLElement;
}

function MedicationSubsidyIcon(props: IMedicationSubsidyIconProps): React.ReactElement {

    if (isNullOrUndefined(props.state)) {
        return null;
    }

    const className = useMemo(() => {
        return Styles.stateEmpty;
    }, [props.state]);
    return (
        <Badge
            text={props.state}
            className={className}
            tooltipContent={props.tooltipContent}
        />
    );
}

export default MedicationSubsidyIcon;