import "./Documents/ApiAdapterConfiguration";
import "./ReferenceData/ApiAdapterConfiguration";
import "./ApiClientConfiguration";
import "./StoreConfiguration";
import "./DocumentSnippetSelectorDialog/ApiAdapterConfiguration";
import { ITokenLockService } from "@HisPlatform/BoundedContexts/DocumentManagement/Services/Definition/ITokenLockService";
import TokenLockService from "@HisPlatform/BoundedContexts/DocumentManagement/Services/Implementation/TokenLockService";
import DependencyContainer from "@DiContainer";

DependencyContainer
    .bind("ITokenLockService")
    .to(TokenLockService)
    .inSingletonScope();