import Di from "@Di";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import InvoiceId from "@Primitives/InvoiceId.g";
import LargeDataToken from "@Primitives/LargeDataToken.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

@Di.injectable()
export default class InvoicPdfScreenApiAdapter extends ApiAdapterBase2 {
    
    constructor(
        @Di.inject("IPatientsClient") private readonly apiClient: Proxy.IPatientsClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader) {
        super(referenceDataLoader);
    }

    @State.bound
    public getInvoicePdfAsync(
        invoiceId: InvoiceId
    ) {
        return this.executeOperationAsync<{ token:LargeDataToken, name:string }, Proxy.GetInvoicePdfQueryResponse>(
            () => this.apiClient.getInvoicePdfQueryAsync(CreateRequestId(), new Proxy.GetInvoicePdfControllerDto({
                invoiceId: invoiceId
            })),
            response => {
                if (isNullOrUndefined(response.pdfToken))
                    return null;
                return {
                    token: response.pdfToken,
                    name: response.name
                };
            }
        );
    }
}