import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import FormEnumStore, { IFormEnumValue } from "@Toolkit/FormEngine/Model/Schema/FormEnumStore";
import _ from "@HisPlatform/Common/Lodash";
import EditableFormEnumValueStore from "./EditableFormEnumValueStore";
import EditableMultiLingualLabel from "@Toolkit/FormEngine/Model/Layout/EditableMultiLingualLabel";
import { mapFromEditableMultiLingualLabel, mapToEditableMultiLingualLabel } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorMapper";

export default class EditableFormEnumStore {

    public readonly values: IObservableArray<EditableFormEnumValueStore>;
    @State.computed public get activeValues() {
        return this.values.filter(v => !v.isDeleted);
    }

    constructor(
        public readonly name: string,
        values: EditableFormEnumValueStore[],
        public readonly currentCultureCode: string
    ) {
        this.values = State.createObservableShallowArray(values);
    }

    public static createFromFormEnumStore(store: FormEnumStore, currentCultureCode: string, activeValues?: number[]) {
        return new EditableFormEnumStore(store.name, store.values.map(v => new EditableFormEnumValueStore(v.value, mapToEditableMultiLingualLabel(v.MultiLingualDisplayValue), activeValues ? activeValues.includes(v.value) : true, v.isDeleted, currentCultureCode)), currentCultureCode);
    }

    public toFormEnumStore() {
        return new FormEnumStore(this.name, this.values.map(v => ({ value: v.value, MultiLingualDisplayValue: mapFromEditableMultiLingualLabel(v.multiLingualDisplayValues), isDeleted: v.isDeleted } as IFormEnumValue)));
    }

    public createNewItem() {
        return new EditableFormEnumValueStore(
            this.values.length > 0 ? (_.orderBy(Array.from(this.values), v => v.value, "desc")[0].value + 1) : 1,
            null,
            true,
            false,
            this.currentCultureCode
        );
    }

    @State.action.bound
    public addNewItem(item: EditableFormEnumValueStore) {
        this.values.push(item);
    }

    @State.action.bound
    public removeValue(item: EditableFormEnumValueStore) {
        item.isDeleted = true;
    }
}