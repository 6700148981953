import React, { useMemo } from "react";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Styles from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityStatusTypeIcon/PerformanceStatementCareActivityStatusTypeIcon.less";
import EnumBadge from "@CommonControls/EnumBadge";
import PerformanceStatementCareActivityStatusType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementCareActivityStatusType.g";

interface IPerformanceStatementCareActivityStatusTypeIconProps {
    state: PerformanceStatementCareActivityStatusType;
    isIndex?: boolean;
    hasBorder?: boolean;
}

function PerformanceStatementCareActivityStatusTypeIcon(props: IPerformanceStatementCareActivityStatusTypeIconProps): React.ReactElement {

    if (isNullOrUndefined(props.state)) {
        return null;
    }

    const className = useMemo(() => {
        switch (props.state) {
            case PerformanceStatementCareActivityStatusType.Empty:
                return Styles.stateEmpty;
            case PerformanceStatementCareActivityStatusType.Reportable:
                return Styles.stateReportable;
            case PerformanceStatementCareActivityStatusType.ComplementReportable:
                return Styles.stateComplementReportable;
            case PerformanceStatementCareActivityStatusType.RevisionReportable:
                return Styles.stateRevisionReportable;
            case PerformanceStatementCareActivityStatusType.Deletable:
                return Styles.stateDeletable;
            case PerformanceStatementCareActivityStatusType.OnErrorList:
                return Styles.stateOnErrorList;
            case PerformanceStatementCareActivityStatusType.Corrected:
                return Styles.stateCorrected;
            case PerformanceStatementCareActivityStatusType.Reported:
                return Styles.stateReported;
            case PerformanceStatementCareActivityStatusType.ComplementReported:
                return Styles.stateComplementReported;
            case PerformanceStatementCareActivityStatusType.RevisionReported:
                return Styles.stateRevisionReported;
            case PerformanceStatementCareActivityStatusType.Deleted:
                return Styles.stateDeleted;
            default:
                return null;
        }
    }, [props.state]);

    return (
        <EnumBadge
            enumType={PerformanceStatementCareActivityStatusType}
            enumName={"PerformanceStatementCareActivityStatusType"}
            value={props.state}
            className={className}
            isIndex={!!props.isIndex}
            bordered={!!props.hasBorder} />
    );
}

export default PerformanceStatementCareActivityStatusTypeIcon;