import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import DataGridExtensibleEnumColumn, { IExtensibleEnumColumnProps } from "@CommonControls/DataGrid/Column/DataGridExtensibleEnumColumn";
import CareRegisterReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareRegisterReferenceDataStore";

interface IFinancingClassColumnDependencies {
    careRegisterReferenceDataStore: CareRegisterReferenceDataStore;
}

interface IFinancingClassColumnProps extends IExtensibleEnumColumnProps {
    _dependencies?: IFinancingClassColumnDependencies;
}


const FinancingClassColumn: React.SFC<IFinancingClassColumnProps> = (props: IFinancingClassColumnProps) => {
    return (
        <DataGridExtensibleEnumColumn
            extensibleEnumStore={props._dependencies.careRegisterReferenceDataStore.financingClass}
            header={"FinancingClass"}
            {...props}
            displayMode="both"
        />
    );
};

export default connect(
    FinancingClassColumn,
    new DependencyAdapter<IFinancingClassColumnProps, IFinancingClassColumnDependencies>(c => ({
        careRegisterReferenceDataStore: c.resolve("CareRegisterReferenceDataStore")
    }))
);