import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import IPerformedServiceExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPerformedServiceExtensionPointProps";
import WellKnownReferenceCodes from "@HunSocialSecurityPlugin/Common/WellKnownReferenceCodes";

@Di.injectable()
export default class PerformedServiceExtensionComponentService implements IExtensionComponentService<IPerformedServiceExtensionPointProps> {

    public isVisible(props: IPerformedServiceExtensionPointProps) {
        return props.insurerOrganizationCode === WellKnownReferenceCodes.HunSocialSecurityInsuranceCode;
    }

    public shouldRerunIsVisible(props: IPerformedServiceExtensionPointProps, prevProps: IPerformedServiceExtensionPointProps) {
        return props.insurerOrganizationCode !== prevProps?.insurerOrganizationCode;
    }
}