import IPlatformPlugin from "@PluginInterface/IPlatformPlugin";
import { Container } from "inversify";
import IExtensionComponentRegistry from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import { ExtensionPointType } from "@PluginInterface/ExtensionPoint/ExtensionPointType";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import IFormExtensionRegistry from "@PluginInterface/FormExtension/IFormExtensionRegistry";
import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import AssecoRoomsCallControlExtension from "@AssecoRoomsPlugin/Packages/Care/Extensions/AssecoRoomsCallExtension/AssecoRoomsCallControlExtension";
import AssecoRoomsCallControlExtensionComponentService from "@AssecoRoomsPlugin/Packages/Care/Extensions/AssecoRoomsCallExtension/AssecoRoomsCallControlExtensionComponentService";
import AssecoRoomsCallFloatingPanelExtension from "@AssecoRoomsPlugin/Packages/Care/Extensions/AssecoRoomsCallFloatingPanelExtension/AssecoRoomsCallFloatingPanelExtension";
import "./DependencyConfiguration/AssecoRoomsDependencyConfiguration";
import AlwaysVisibleExtensionComponentService from "@PluginInterface/ExtensionPoint/AlwaysVisibleExtensionComponentService";

class AssecoRoomsPlugin implements IPlatformPlugin {
    public name: string = "AssecoRoomsPlugin";

    public async configureAsync(container: Container) {
        this.configureExtensionComponentServices(container);
        await Promise.resolve();
    }

    public configureExtensionComponents(registry: IExtensionComponentRegistry<ExtensionPointType>) {
        registry.register("telecommunicationCallControlExtension", AssecoRoomsCallControlExtension, "AssecoRoomsCallControlExtensionComponentService");
        registry.register("rootApplicationExtension", AssecoRoomsCallFloatingPanelExtension, "AlwaysVisibleExtensionComponentService");
    }

    private configureExtensionComponentServices(container: Container) {
        container.bind("AssecoRoomsCallControlExtensionComponentService")
            .to(AssecoRoomsCallControlExtensionComponentService);
    }

    public configureUseCases(useCaseRegistry: IUseCaseRegistry): void {

    }

    public configureFormExtensions(formExtensionRegistry: IFormExtensionRegistry): void {
        return;
    }

    public configurePermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry): void {
        return;
    }

    public configureActivities(activityRegistry: IActivityRegistry) {

    }

    public configureNData(configurator: INDataAttributeConfigurator): void {
        return;
    }

    public configureMapper(configurator: IMapperConfigurator): void {
        return;
    }

    public configureFormEngine(registry: FormEditorRegistry): void {
        return;
    }
}

export default new AssecoRoomsPlugin();
