import PerformanceReportScorePlanId from "@Primitives/PerformanceReportScorePlanId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import _ from "@HisPlatform/Common/Lodash";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PerformanceReportScorePlanStore from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportScorePlanStore";
import PerformanceReportScorePlanApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/PerformanceReportScorePlanApiAdapter";
import PerformanceReportScorePlanDetailPanelView from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Panels/PerformanceReportScorePlanWorklistPanel/PerformanceReportScorePlanDetailPanelView";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import PlannedScoreItemStore from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PlannedScoreItemStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import StaticHunSocialSecurityReportingResources from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/StaticResources/StaticHunSocialSecurityReportingResources";
import { IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";

interface IPerformanceReportScorePlanDetailPanelDependencies {
    performanceReportScorePlanApiAdapter: PerformanceReportScorePlanApiAdapter;
    structureApiAdapter: StructureApiAdapter;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    notificationService: INotificationService;
    dialogService: IDialogService;
}

export interface IPerformanceReportScorePlanDetailPanelProps {
    _dependencies?: IPerformanceReportScorePlanDetailPanelDependencies;
    performanceReportScorePlanId: PerformanceReportScorePlanId;
    onPerformanceReportScorePlanCreatedAsync?: (performanceReportScorePlanId: PerformanceReportScorePlanId) => Promise<void>;
    onDeletePerformanceReportScorePlanAsync?: () => Promise<void>;
}

/** @screen */
@State.observer
class PerformanceReportScorePlanDetailPanel extends React.Component<IPerformanceReportScorePlanDetailPanelProps> {

    private get performanceReportScorePlanId() { return this.props.performanceReportScorePlanId; }
    private get performanceReportScorePlanApiAdapter() { return this.props._dependencies.performanceReportScorePlanApiAdapter; }
    private get structureApiAdapter() { return this.props._dependencies.structureApiAdapter; }
    private get organizationReferenceDataStore() { return this.props._dependencies.organizationReferenceDataStore; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get dialogService() { return this.props._dependencies.dialogService; }

    @State.observable.ref private store: PerformanceReportScorePlanStore = null;
    @State.observable.ref private selectedPlannedScoreItem: PlannedScoreItemStore = null;
    @State.observable.ref private dataSource: InMemoryDataGridDataSource = new InMemoryDataGridDataSource(() => this.store.plannedScoreItems, { columnId: "PointOfCareId", direction: "asc" } as IOrderingState);

    @State.computed private get isNew() { return this.performanceReportScorePlanId.value === "new"; }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IPerformanceReportScorePlanDetailPanelProps) {
        if (prevProps.performanceReportScorePlanId.value !== this.props.performanceReportScorePlanId.value) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.action.bound
    private async loadAsync() {
        await this.organizationReferenceDataStore.allPointsOfCareMap.ensureAllLoadedAsync();

        let store: PerformanceReportScorePlanStore = null;
        if (this.isNew) {
            store = new PerformanceReportScorePlanStore(true);
            store.id = new PerformanceReportScorePlanId("new");

            const pointOfCareIds = await this.structureApiAdapter.getAllPointOfCareIdsAsync();

            store.plannedScoreItems = pointOfCareIds.value.map(i => {
                const plannedScoreItem = new PlannedScoreItemStore();
                plannedScoreItem.pointOfCareId = i;
                return plannedScoreItem;
            });
        } else {
            store = await this.performanceReportScorePlanApiAdapter.getByIdAsync(this.performanceReportScorePlanId);
        }
        this.setStore(store);
    }

    @State.action.bound
    private setStore(newValue: PerformanceReportScorePlanStore) {
        this.store = newValue;
        this.store.takeSnapshot();
    }

    @State.action.bound
    private setSelectedPlannedScoreItem(newValue: PlannedScoreItemStore) {
        this.selectedPlannedScoreItem = newValue;
    }

    @State.bound
    private isUnsavedOrDirty() {
        return !this.store.isPersistedByOperationInfo || this.store.isDirty();
    }

    @State.bound
    private async navigateAwayAsync() {
        if (this.isUnsavedOrDirty()) {
            const dialogResult = await this.dialogService.confirmIfNotSaved(StaticCareResources.Common.Dialog.ConfirmationTitle, StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);
            if (dialogResult.resultCode === DialogResultCode.Yes) {
                await this.saveStoreAsync(false);
            } else if (dialogResult.resultCode === DialogResultCode.No) {
                return true;
            }
            return false;
        }
        return true;
    }

    @State.action.bound
    private async validateAsync(): Promise<IClientValidationResult[]> {
        if (!this.store) {
            return [];
        }

        const result = await this.saveStoreAsync(true);

        return result.validationResults;
    }

    @State.action.bound
    private async saveStoreAsync(isValidateOnly: boolean) {
        let result: PerformanceReportScorePlanStore = null;

        if (this.isNew) {
            result = await this.performanceReportScorePlanApiAdapter.createPerformanceReportScorePlanAsync(this.store, isValidateOnly);
        } else {
            result = await this.performanceReportScorePlanApiAdapter.updatePerformanceReportScorePlanAsync(this.store, isValidateOnly);
        }

        if (!isValidateOnly) {
            this.replaceStore(result);

            if (result.operationWasSuccessful && result.isPersistedByOperationInfo && this.isNew) {
                await this.props.onPerformanceReportScorePlanCreatedAsync?.(result.id);
                return result;
            }
        }

        return result;
    }

    @State.action.bound
    private async deleteAsync() {
        const dialogResult = await this.dialogService.yesNo(StaticWebAppResources.Common.DialogTitle.ConfirmationTitle,
            StaticHunSocialSecurityReportingResources.PerformanceReportScorePlanDetailPanel.Dialog.DeleteConfirmationMessage);
        if (dialogResult.resultCode === DialogResultCode.Yes) {
            const response = await this.performanceReportScorePlanApiAdapter.deletePerformanceReportScorePlanAsync(this.store.id, this.store.rowVersion);

            if (response.operationWasSuccessful && response.isPersistedByOperationInfo) {
                this.props.onDeletePerformanceReportScorePlanAsync?.();
            }
        }
    }

    @State.action.bound
    private replaceStore(newStore: PerformanceReportScorePlanStore) {
        if (newStore.operationWasSuccessful) {
            if (newStore.isPersistedByOperationInfo) {
                this.setStore(newStore);
                this.notificationService.showSavedSuccessfully();
            } else {
                State.runInAction(() => {
                    this.store.validationResults = newStore.validationResults;
                });
            }
        }
    }

    @State.bound
    private async saveAsync() {
        await this.saveStoreAsync(false);
    }

    public render() {
        if (!this.store) {
            return null;
        }

        return (
            <PerformanceReportScorePlanDetailPanelView
                store={this.store}
                onSaveAsync={this.saveAsync}
                onDeleteAsync={this.deleteAsync}
                onValidateAsync={this.validateAsync}
                onNavigateAwayAsync={this.navigateAwayAsync}
                dataSource={this.dataSource}
                selectedPlannedScoreItem={this.selectedPlannedScoreItem}
                setSelectedPlannedScoreItem={this.setSelectedPlannedScoreItem}
            />
        );
    }
}

export default connect(
    PerformanceReportScorePlanDetailPanel,
    new DependencyAdapter<IPerformanceReportScorePlanDetailPanelProps, IPerformanceReportScorePlanDetailPanelDependencies>(c => ({
        performanceReportScorePlanApiAdapter: c.resolve<PerformanceReportScorePlanApiAdapter>("PerformanceReportScorePlanApiAdapter"),
        structureApiAdapter: c.resolve("StructureApiAdapter"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        notificationService: c.resolve<INotificationService>("INotificationService"),
        dialogService: c.resolve<IDialogService>("IDialogService"),
    }))
);