import React from "react";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import moment from "moment";
import { nullFunction } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PointOfCareSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareSelectBox";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { CareLocation } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/CareLocation";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import ExternalLocationCodeSelector from "@HisPlatform/BoundedContexts/Organization/Components/Controls/ExternalLocationCodeSelector";
import PractitionerId from "@Primitives/PractitionerId.g";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";

export interface IReferralDataPageBoxProps {
    requesterDoctorId: PractitionerId;
    serviceRequestDefinitionDirection: ServiceRequestDirection;
    onRequesterDoctorIdChange: (newValue: PractitionerId | PractitionerId[]) => void;
    requesterPointOfCareId?: PointOfCareId;
    referralCreationDateTime: moment.Moment;
    careIdentifier: string;
    referral: CareLocation;
}

// tslint:disable-next-line:variable-name
const ReferralDataPageBox: React.FC<IReferralDataPageBoxProps> = props => {
    const requesterPointOfCareIdFilter: FilterBase[] = props.requesterPointOfCareId
        ? [new ExplicitIdentifierFilter("PointOfCare", props.requesterPointOfCareId.value)]
        : [];

    return <>
        <Ui.Flex>
            <Ui.Flex.Item xs={6}>
                {props.serviceRequestDefinitionDirection === ServiceRequestDirection.ExternalToInternal && (
                    <HisUi.DoctorCodeSelector
                        label={StaticWebAppResources.NewServiceRequestPage.ReferralDataLabels.RequesterDoctor}
                        value={props.requesterDoctorId}
                        onChange={props.onRequesterDoctorIdChange}
                        propertyIdentifier="RequesterDoctorId"
                        automationId="__requestor"
                    />
                )}
                {props.serviceRequestDefinitionDirection !== ServiceRequestDirection.ExternalToInternal && (
                    <HisUi.DoctorCodeSelector
                        label={StaticWebAppResources.NewServiceRequestPage.ReferralDataLabels.RequesterDoctor}
                        value={props.requesterDoctorId}
                        onChange={props.onRequesterDoctorIdChange}
                        filters={requesterPointOfCareIdFilter}
                        propertyIdentifier="RequesterDoctorId"
                        automationId="__requestor"
                    />
                )}
            </Ui.Flex.Item>
            <Ui.Flex.Item xs={6}>
                {!props.referral && (
                    <PointOfCareSelectBox
                        label={StaticWebAppResources.NewServiceRequestPage.ReferralDataLabels.RequesterPointOfCare}
                        value={props.requesterPointOfCareId}
                        onChange={nullFunction}
                        isReadOnly
                        propertyIdentifier="RequesterPointOfCareId"
                        automationId="__pointOfCare"
                    />
                )}
                {props.referral && props.referral instanceof ExternalCareLocation && (
                    <ExternalLocationCodeSelector
                        label={StaticWebAppResources.NewServiceRequestPage.ReferralDataLabels.Requester}
                        value={props.referral.externalLocationSelector.id}
                        validOn={props.referral.externalLocationSelector.validOn}
                        externalLocationIds={[props.referral.externalLocationSelector.id]}
                        onChange={nullFunction}
                        isReadOnly
                        automationId="__externalLocation"
                    />
                )}
                {props.referral && props.referral instanceof InternalCareLocation && (
                    <PointOfCareSelectBox
                        label={StaticWebAppResources.NewServiceRequestPage.ReferralDataLabels.Requester}
                        value={props.referral.pointOfCareId}
                        onChange={nullFunction}
                        isReadOnly
                        automationId="__pointOfCare"
                    />
                )}
            </Ui.Flex.Item>
        </Ui.Flex>
        <Ui.Flex>
            <Ui.Flex.Item xs={6}>
                <Ui.DateTimePicker
                    label={StaticWebAppResources.NewServiceRequestPage.ReferralDataLabels.ReferralCreationDate}
                    value={props.referralCreationDateTime}
                    isReadOnly
                    propertyIdentifier="ReferralCreationDate"
                    automationId="__referralCreationDate"
                />
            </Ui.Flex.Item>
            <Ui.Flex.Item xs={6}>
                <Ui.TextBox
                    label={StaticWebAppResources.NewServiceRequestPage.ReferralDataLabels.CareIdentifier}
                    value={props.careIdentifier}
                    isReadOnly
                    propertyIdentifier="CareIdentifier"
                    automationId="__careIdentifier"
                />
            </Ui.Flex.Item>
        </Ui.Flex>
    </>;
};

export default ReferralDataPageBox;
