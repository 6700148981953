import React from "react";
import AppointmentSchedulerEvent from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduler/AppointmentSchedulerEvent";
import moment from "moment";
import IAppointmentSchedulerBaseProps from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduler/AppointmentSchedulerBaseProps";
import AppointmentScheduler from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduler/AppointmentScheduler";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ICalendarDayStyle from "@CommonControls/DateTimePicker/ICalendarDayStyle";
import _ from "@HisPlatform/Common/Lodash";
import Style from "./AppointmentCreationSchedule.less";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import IRect from "@CommonControls/Scheduler/IRect";
import { ISchedulerEvent } from "@CommonControls/Scheduler/ISchedulerProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import NewEvent from "@CommonControls/Scheduler/NewEvent";
import InvalidEvent from "@CommonControls/Scheduler/InvalidEvent";
import SchedulerViewMode from "@CommonControls/Scheduler/SchedulerViewMode";
import SlotStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/SlotStatus.g";

interface IAppointmentCreationScheduleProps extends IAppointmentSchedulerBaseProps {
    events: AppointmentSchedulerEvent[];
    invalidEvents: InvalidEvent[];
    onRenderCalendar?: (calendar: React.ReactNode) => React.ReactNode;
    isCurrentEntry?: (event: ISchedulerEvent) => boolean;
}

@State.observer
export default class AppointmentCreationSchedule extends React.Component<IAppointmentCreationScheduleProps> {

    @State.observable private readonly currentEntryBorders = { top: 1, right: 1, bottom: 1, left: 6 };
    @State.observable private readonly freeEntryBorders = { top: 1, right: 1, bottom: 1, left: 6 };
    @State.observable private readonly bookedEntryBorders = { top: 1, right: 1, bottom: 1, left: 1 };

    @State.computed private get events() {
        return this.props.events;
    }

    @State.computed private get invalidEvents() {
        return this.props.invalidEvents;
    }

    @State.computed
    private get calendarStyles() {
        const groupedEntries = _.groupBy(this.events, (event) => event.startTime.format("L"));
        const res: ICalendarDayStyle[] = [];
        _.forIn(groupedEntries, (value, key) => {
            const className = value.every(e => e.state === SlotStatus.Free) ?
                Style.calendarEntryLow : value.every(e => e.state === SlotStatus.Busy || e.state === SlotStatus.BookingInProgress) ?
                    Style.calendarEntryHigh : Style.calendarEntryMedium;

            res.push({
                className: className,
                day: moment(key, "L")
            } as ICalendarDayStyle);
        });
        return res;
    }

    @State.bound
    private getEntryClassName(event: AppointmentSchedulerEvent, isPlaceable: boolean): string {
        if (this.props.isCurrentEntry(event)) {
            return Style.entryCurrent;
        }
        switch (event.state) {
            case SlotStatus.BookingInProgress:
                return Style.entryPending;
            case SlotStatus.Busy:
                return Style.entryDone;
            case SlotStatus.Free:
                return Style.entryFree;
        }
    }

    @State.bound
    private getInvalidEntryClassName(event: InvalidEvent, viewMode: SchedulerViewMode): string {
        switch (viewMode) {
            case SchedulerViewMode.Calendar:
                return Style.entryInvalidCalendar;
            case SchedulerViewMode.Timeline:
                return Style.entryInvalidTimeline;
            case SchedulerViewMode.List:
            default:
                return null;
        }
    }

    @State.bound
    private getEntryAutomationIdPrefix(event: ISchedulerEvent) {
        if (event instanceof AppointmentSchedulerEvent) {
            return this.props.isCurrentEntry(event)
                ? `current`
                : `${SlotStatus[event.state]?.toLowerCase()}`;
        }
        return null;
    }

    @State.bound
    private getNewEntryClassName(entry: AppointmentSchedulerEvent, isPlaceable: boolean) {
        if (isPlaceable === false) {
            return combineClasses(Style.entryNew, Style.notFit);
        }
        return Style.entryNew;
    }

    @State.bound
    private getNewEntryIconName(event: ISchedulerEvent) {
        return this.isOverCurrentEntry(event)
            ? "pen"
            : "plus";
    }

    @State.bound
    private isOverCurrentEntry(event: ISchedulerEvent) {
        if (isNullOrUndefined(event)) {
            return false;
        }
        return event instanceof NewEvent && this.events.some(e => this.props.isCurrentEntry(e) && e.startTime === event.startTime);
    }

    @State.bound
    private getNewEntryOffSet(event: AppointmentSchedulerEvent) {
        const rect: IRect = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        };

        if (this.props.isCurrentEntry(event)) {
            rect.x -= this.currentEntryBorders.left;                                        // offset for covering entry's left border with new entry
            rect.y -= 0;                                                                    // subtract top border to cover it; height calculation in NewEventEntry needs adjustment then
            rect.width += this.currentEntryBorders.left;                                    // add right border to cover it
            rect.height += this.currentEntryBorders.top + this.currentEntryBorders.bottom;  // both needed for proper new entry height calculation in NewEventEntry
            return rect;
        }

        switch (event.state) {
            case SlotStatus.BookingInProgress:
            case SlotStatus.Busy:
                rect.x -= this.bookedEntryBorders.left;
                rect.y -= 0;
                rect.width += this.bookedEntryBorders.left;
                rect.height += this.bookedEntryBorders.top + this.bookedEntryBorders.bottom;
                return rect;
            case SlotStatus.Free:
                rect.x -= this.freeEntryBorders.left;
                rect.y -= 0;
                rect.width += this.freeEntryBorders.left;
                rect.height += this.freeEntryBorders.top + this.freeEntryBorders.bottom;
                return rect;
            default: return rect;
        }
    }

    @State.computed private get currentMoment() {
        return this.props.currentMoment;
    }

    @State.computed private get scrollToTargets() {
        const res = _.uniqBy(this.events
            .filter(e => e.state === SlotStatus.Free && e.startTime.isSame(this.currentMoment, "day"))
            .map(e => new TimeOfDay(e.startTime.hour(), 0)), e => e.hours).sort((a, b) => a.hours - b.hours);
        return res;
    }

    @State.bound
    private onRenderTooltip(event: ISchedulerEvent) {
        return this.props.isCurrentEntry?.(event)
            ? this.props.onRenderTooltip(event)
            : null;
    }

    public render() {
        return (
            <AppointmentScheduler
                {...this.props}
                onRenderTooltip={this.onRenderTooltip}
                calendarStyles={this.calendarStyles}
                onGetEntryClassName={this.getEntryClassName}
                onGetInvalidEntryClassName={this.getInvalidEntryClassName}
                onGetEntryAutomationIdPrefix={this.getEntryAutomationIdPrefix}
                onGetNewEntryClassName={this.getNewEntryClassName}
                onGetNewEntryIconName={this.getNewEntryIconName}
                onGetNewEntryOffset={this.getNewEntryOffSet}
                scrollToTargets={this.scrollToTargets}
                leftSidebarClassName={Style.sideBar}
                hideUnavailableWorkHours
                events={this.events}
                invalidEvents={this.invalidEvents}
                currentMoment={this.currentMoment}
                hideCalendar={false}
                onRenderCalendar={this.props.onRenderCalendar}
                showNewEntry
            />
        );
    }
}
