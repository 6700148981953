import "./ApiClientConfiguration";

import "./CommonReferenceData/ApiAdapterConfiguration";
import "./CommonReferenceData/StoreConfiguration";
import DependencyContainer from "@DiContainer";
import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import CommonReferenceDataBoundedContext from "@HisPlatform/BoundedContexts/CommonReferenceData/CommonReferenceDataBoundedContext";

DependencyContainer
    .bind("IBoundedContext")
    .to(CommonReferenceDataBoundedContext);