import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import { GetTypeNameWithPrefix } from "@HunSocialSecurityPlugin/PluginHelper";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Proxy.g";
import Di from "@Di";
import _ from "@HisPlatform/Common/Lodash";
import PerformanceReportScorePlanStoreMapper from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/PerformanceReportScorePlanStroreMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PerformanceReportScorePlanId from "@Primitives/PerformanceReportScorePlanId.g";
import PerformanceReportScorePlanStore from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportScorePlanStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import ReportDefinitionIdentifier from "@Primitives/ReportDefinitionIdentifier.g";

/* auto-inject-disable */
@Di.injectable()
export default class PerformanceReportScorePlanApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("IReportingClient")) private readonly apiClient: Proxy.IReportingClient,
        @Di.inject("PerformanceReportScorePlanStoreMapper") private readonly storeMapper: PerformanceReportScorePlanStoreMapper
    ) {
        super();
    }

    private _identifier = new ReportDefinitionIdentifier("HunSocialSecurity_PerformanceReport");

    @State.bound
    public getByIdAsync(performanceReportScorePlanId: PerformanceReportScorePlanId, isPermissionCheckOnly = false): Promise<PerformanceReportScorePlanStore> {
        return this.processOperationAsync(
            new PerformanceReportScorePlanStore(false),
            async target => {
                const response = await this.apiClient.getPerformanceReportScorePlanByIdQueryAsync(
                    CreateRequestId(),
                    performanceReportScorePlanId.value,
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public createPerformanceReportScorePlanAsync(store: PerformanceReportScorePlanStore, isValidateOnly: boolean = false, isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new PerformanceReportScorePlanStore(false),
            async target => {
                const response = await this.apiClient.createPerformanceReportScorePlanCommandAsync(
                    CreateRequestId(),
                    new Proxy.CreatePerformanceReportScorePlanControllerDto({
                        planDate: store.planDate,
                        financingClassId: store.financingClassId,
                        plannedScoreItems: store.plannedScoreItems.map(i => new Proxy.PlannedScoreItemDto({
                            organizationUnitId: new OrganizationUnitId(i.pointOfCareId.value),
                            plannedScore: i.plannedScore
                        })),
                        isValidateOnly: isValidateOnly
                    }),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public updatePerformanceReportScorePlanAsync(store: PerformanceReportScorePlanStore, isValidateOnly: boolean = false, isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new PerformanceReportScorePlanStore(false),
            async target => {
                const response = await this.apiClient.updatePerformanceReportScorePlanCommandAsync(
                    CreateRequestId(),
                    new Proxy.UpdatePerformanceReportScorePlanControllerDto({
                        id: store.id,
                        rowVersion: store.rowVersion,
                        planDate: store.planDate,
                        financingClassId: store.financingClassId,
                        plannedScoreItems: store.plannedScoreItems.map(i => new Proxy.PlannedScoreItemDto({
                            organizationUnitId: new OrganizationUnitId(i.pointOfCareId.value),
                            plannedScore: i.plannedScore
                        })),
                        isValidateOnly: isValidateOnly
                    }),
                    isPermissionCheckOnly
                );
                
                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public deletePerformanceReportScorePlanAsync(id: PerformanceReportScorePlanId, rowVersion: RowVersion, isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new SimpleStore<any>(),
            async target => {
                const response = await this.apiClient.deletePerformanceReportScorePlanCommandAsync(
                    CreateRequestId(),
                    new Proxy.DeletePerformanceReportScorePlanControllerDto({
                        id: id,
                        rowVersion: rowVersion
                    }),
                    isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}