import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class MultiAccordionStore {
    @State.observable public activeIndex: number = null;

    @State.action.bound
    public setActiveIndex(index: number) {
        if (this.activeIndex === index) {
            this.activeIndex = null;
        } else {
            this.activeIndex = index;
        }
    }

    @State.action.bound
    public paneClickHandler(index: number) {
        this.activeIndex = index;
    }

}