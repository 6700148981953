import Di from "@Di";
import PatientAllergyIntoleranceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceStore";
import PatientAllergyIntoleranceHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceHistoryItem";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import PatientAllergyIntoleranceId from "@Primitives/PatientAllergyIntoleranceId.g";
import moment from "moment";
import ReferencedAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/ReferencedAllergyIntoleranceAssignment";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import AllergyIntoleranceReactionAssignment from "./AllergyIntoleranceReactionAssignment";

@Di.injectable()
export default class PatientAllergyIntoleranceFactory {

    public createNewStore() {
        const store = new PatientAllergyIntoleranceStore(true);
        store.id = new PatientAllergyIntoleranceId("new");

        const assignment = new ReferencedAllergyIntoleranceAssignment();
        assignment.setAllergyIntolerance(null);
        store.assignment = assignment;

        store.historyItems.push(this.createNewHistoryItem());
        store.currentItemIndex = 0;

        return store;
    }

    private createNewHistoryItem() {
        const historyItem = new PatientAllergyIntoleranceHistoryItem();
        const allergyIntoleranceReactionAssignment = new AllergyIntoleranceReactionAssignment({ id: null, validOn: LocalDate.createFromMoment(moment(moment.now())) }, "");

        historyItem.isNew = true;
        historyItem.versionNumber = 1;
        historyItem.index = 0;
        historyItem.createdAt = moment(moment.now());
        historyItem.historyItemDetail = new MedicalConditionHistoryItemDetail();
        historyItem.setReactions([allergyIntoleranceReactionAssignment]);        

        return historyItem;
    }
}
