exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FileUpload_required-star_1gINZ {\n  color: #CE5374;\n  position: relative;\n  top: 2px;\n  left: 2px;\n  font-size: 19px;\n}\n.FileUpload_required-star_1gINZ::after {\n  content: \"*\";\n}\n.FileUpload_container_10fZO {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 8px;\n  border-width: 2px;\n  border-radius: 2px;\n  border-color: #eeeeee;\n  border-style: dashed;\n  background-color: #fafafa;\n  color: #bdbdbd;\n  outline: none;\n  transition: border 0.24s ease-in-out;\n  margin: 0 4px;\n}\n.FileUpload_container_10fZO aside {\n  color: #004358;\n}\n.FileUpload_label_f2ilf {\n  width: 100%;\n  font-family: PragatiNarrow;\n  font-size: 14px;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 1.14;\n  letter-spacing: normal;\n  text-align: left;\n  color: #676a6c;\n  margin: 0 5px;\n}\n", ""]);

// exports
exports.locals = {
	"required-star": "FileUpload_required-star_1gINZ",
	"requiredStar": "FileUpload_required-star_1gINZ",
	"container": "FileUpload_container_10fZO",
	"label": "FileUpload_label_f2ilf"
};