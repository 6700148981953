import React from "react";
import ReactHtmlParser from "react-html-parser";

interface IHighlightableLabelViewProps {
    label: string;
    filterValue: string;
    automationId?: string;
    displayMode?: "inline-block" | "inline";
}


const HighlightableLabelView: React.FC<IHighlightableLabelViewProps> = props => { 
    const label = props.label;
    const filterValue = props.filterValue;

    if (label == null || label === undefined || filterValue == null || filterValue === undefined) {
        return <div style={{display: props.displayMode ?? "inline-block"}} data-automation-id={props.automationId || undefined}>{props.label}</div>;
    }

    let content = "";
    if (filterValue === "") {
        content = label;
    } else {
        const index = label.toLowerCase().indexOf(filterValue.toLowerCase());
        if (index >= 0) {
            content = label.substring(0, index) +
                "<span style=\"background:yellow\">" + label.substring(index, index + filterValue.length) + "</span>" +
                label.substring(index + filterValue.length);
        } else {
            content = label;
        }
    }

    return <div style={{display: props.displayMode ?? "inline-block"}} data-automation-id={props.automationId || undefined}>{ReactHtmlParser(content)}</div>;
};

export default HighlightableLabelView;