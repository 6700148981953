import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import AssecoRoomsCallContext from "@AssecoRoomsPlugin/AssecoRoomsCallContext";
import IRootApplicationExtensionPointProps from "@PluginInterface/BoundedContexts/RootApplication/IRootApplicationExtensionPointProps";
import FloatingPanel from "@Toolkit/ReactClient/Components/FloatingPanel/FloatingPanel";
import StaticTelecommunicationResources from "@HisPlatform/BoundedContexts/Telecommunication/StaticResources/StaticTelecommunicationResources";
import config from "@Config";
import {Icon} from "@CommonControls";

interface IAssecoRoomsCallFloatingPanelExtensionDependencies {
    assecoRoomsCallContext: AssecoRoomsCallContext;
}

interface IAssecoRoomsCallFloatingPanelExtensionProps extends IRootApplicationExtensionPointProps {
    _dependencies?: IAssecoRoomsCallFloatingPanelExtensionDependencies;
}

@State.observer
class AssecoRoomsCallFloatingPanelExtension extends React.Component<IAssecoRoomsCallFloatingPanelExtensionProps> {

    private get assecoRoomsCallContext() {
        return this.props._dependencies.assecoRoomsCallContext;
    }

    @State.bound
    private renderMissingPermissionsIcon() {
        return this.assecoRoomsCallContext.hasMissingPermission && (
            <Icon
                iconName={"warning"}
                size={"normal"}
                visualStyle={"warning"}
                style={{marginLeft: "4px"}}
                tooltipPosition={"bottom"}
                tooltipTextAlign={"center"}
                tooltipContent={StaticTelecommunicationResources.TelecommunicationCallControl.MissingPermissionsDialogMessage}
            />
        );
    }

    public render() {
        return this.assecoRoomsCallContext.assecoRoomsIsVisible && (
            <FloatingPanel title={StaticTelecommunicationResources.TelecommunicationCallControl.CallTitleButton}
                           onClose={this.assecoRoomsCallContext.hideAssecoRooms}
                           extraIcons={this.renderMissingPermissionsIcon()}
            >
                <iframe
                    src={`${config.assecoRoomsAddress.roomUrl}/${this.assecoRoomsCallContext.organizerIdentifier}`}
                    frameBorder="0"
                    style={{ height: "100%", width: "100%" }}
                    sandbox="allow-same-origin allow-forms allow-scripts allow-popups allow-popups-to-escape-sandbox"
                    allow="camera *; microphone *; display-capture *"
                />
            </FloatingPanel>
        );
    }

}

export default connect(
    AssecoRoomsCallFloatingPanelExtension,
    new DependencyAdapter<IAssecoRoomsCallFloatingPanelExtensionProps, IAssecoRoomsCallFloatingPanelExtensionDependencies>(c => {
        return {
            assecoRoomsCallContext: c.resolve("AssecoRoomsCallContext"),
        };
    })
);