import React from "react";
import * as Ui from "@CommonControls";
import { ITextBoxProps } from "@CommonControls/TextBox";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";


export interface INameSelectBoxProps extends ITextBoxProps {
}

export default class NameTextBox extends React.PureComponent<INameSelectBoxProps> {

    public render() {
        return <Ui.TextBox {...this.props} value={this.props.value} onChange={this.emitFormattedValue} onBlur={this.emitRightTrimmedValue} />;
    }

    @State.bound
    private emitRightTrimmedValue() {
        if (this.props.onChange && this.props.value) {
            const newValue = this.formatNameFinal(this.props.value);
            if (this.props.value !== newValue) {
                this.props.onChange(newValue);
            }
        }
    }

    @State.bound
    private emitFormattedValue(newValue: string) {
        if (this.props.onChange) {
            this.props.onChange(this.formatNameTyping(newValue));
        }
    }

    private formatNameTyping(raw: string): string {

        if (isNullOrUndefined(raw)) {
            return raw;
        }

        return raw
            .replace(/\d/g, "")
            .replace(/\s{2,}/g, " ");
    }

    private formatNameFinal(raw: string): string {
        return this.formatNameTyping(raw).trim();
    }
}