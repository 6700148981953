import FormLayoutDataElementEditorStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutDataElementEditorStore";
import FormLayoutGroupBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutGroupBlockStore";
import FormLayoutRowBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutRowBlockStore";
import IFormLayoutBlockStore from "@Toolkit/FormEngine/Model/Layout/IFormLayoutBlockStore";
import _ from "@HisPlatform/Common/Lodash";
import EditableDataElementEditorStore from "./Model/EditableDataElementEditorStore";
import EditableLayoutGroupBlockStore from "./Model/EditableLayoutGroupBlockStore";
import EditableLayoutRowBlockStore from "./Model/EditableLayoutRowBlockStore";
import IEditableLayoutBlockStore from "./Model/IEditableLayoutBlockStore";

export function getAllEditors(blocks: IFormLayoutBlockStore[]) {
    const editors: Array<EditableDataElementEditorStore | FormLayoutDataElementEditorStore> = [];
    blocks.forEach(visitBlock);
    return editors;

    function visitBlock(block: IEditableLayoutBlockStore | IFormLayoutBlockStore) {
        if (block instanceof EditableLayoutGroupBlockStore) {
            block.contentBlocks.forEach(visitBlock);

        } else if (block instanceof FormLayoutGroupBlockStore) {
            block.content.forEach(visitBlock);

        } else if (block instanceof EditableLayoutRowBlockStore) {
            block.content.forEach(col => {
                if (col.editor) {
                    editors.push(col.editor);

                } else {
                    col.contentBlocks.forEach(visitBlock);

                }
            });
        } else if (block instanceof FormLayoutRowBlockStore) {
            block.content.forEach(col => {
                if (col.content instanceof FormLayoutDataElementEditorStore) {
                    editors.push(col.content);

                } else {
                    col.content.forEach(visitBlock);

                }
            });
        }
    }
}

export function getAllEditorsAndGroups(blocks: IFormLayoutBlockStore[]) {
    const editorsAndGroups: Array<EditableDataElementEditorStore | FormLayoutDataElementEditorStore | EditableLayoutGroupBlockStore | FormLayoutGroupBlockStore> = [];
    blocks.forEach(visitBlock);
    return editorsAndGroups;

    function visitBlock(block: IEditableLayoutBlockStore | IFormLayoutBlockStore) {
        if (block instanceof EditableLayoutGroupBlockStore) {
            editorsAndGroups.push(block);
            block.contentBlocks.forEach(visitBlock);

        } else if (block instanceof FormLayoutGroupBlockStore) {
            editorsAndGroups.push(block);
            block.content.forEach(visitBlock);

        } else if (block instanceof EditableLayoutRowBlockStore) {
            block.content.forEach(col => {
                if (col.editor) {
                    editorsAndGroups.push(col.editor);

                } else {
                    col.contentBlocks.forEach(visitBlock);

                }
            });
        } else if (block instanceof FormLayoutRowBlockStore) {
            block.content.forEach(col => {
                if (col.content instanceof FormLayoutDataElementEditorStore) {
                    editorsAndGroups.push(col.content);

                } else {
                    col.content.forEach(visitBlock);

                }
            });
        }
    }
}