import AutonomyDisabilityId from "@Primitives/AutonomyDisabilityId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import AutonomyDisabilityAssignmentBase from "./AutonomyDisabilityAssignmentBase";

export default class ReferencedAutonomyDisabilityAssignment extends AutonomyDisabilityAssignmentBase  {
    @State.observable.ref public id: AutonomyDisabilityId = null;

    constructor(id: AutonomyDisabilityId) {
        super();
        this.id = id;
    }
}