import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { DisplayMode } from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import EhiHealthcareProviderOrganizationUnitId from "@Primitives/EhiHealthcareProviderOrganizationUnitId.g";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import formatCodeName from "@HisPlatformControls/CodeNameFormatter/CodeNameFormatter";

interface IEhiHealthcareProviderOrganizationUnitColumnDependencies {
    referenceDataStore: HunEhiCareReferenceDataStore;
}

interface IEhiHealthcareProviderOrganizationUnitColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IEhiHealthcareProviderOrganizationUnitColumnDependencies;
    displayMode?: DisplayMode;
    isCodeBold?: boolean;
    fallbackValueRenderer?: (row: any, displayMode: DisplayMode) => React.ReactNode;
}


const EhiHealthcareProviderOrganizationUnitColumn: React.FC<IEhiHealthcareProviderOrganizationUnitColumnProps> = (props: IEhiHealthcareProviderOrganizationUnitColumnProps) => {
    const valueRenderer = (value: EhiHealthcareProviderOrganizationUnitId, row: any) => {
        const noItem = isNullOrUndefined(value);
        if (!noItem) {
            const orgUnit = props._dependencies.referenceDataStore.ehiHealthcareProviderOrganizationUnits.get(value);
            if (!isNullOrUndefined(props.displayMode)) {
                switch (props.displayMode) {
                    case "name": return orgUnit?.name ?? "";
                    case "shorthand": return orgUnit?.code ?? "";
                    case "shorthand-name": return formatCodeName(orgUnit?.code, orgUnit?.name, props.isCodeBold);
                }
            } else {
                return orgUnit?.name ?? "";
            }
        } else if (noItem && props.fallbackValueRenderer) {
            return props.fallbackValueRenderer(row, props.displayMode);
        } else {
            return "";
        }
    };

    return (
        <DataGridColumn
            onRenderCellValue={valueRenderer}
            clientSideFilterableValueGetter={valueRenderer}
            {...props}
        />
    );
};

export default connect(
    EhiHealthcareProviderOrganizationUnitColumn,
    new DependencyAdapter<IEhiHealthcareProviderOrganizationUnitColumnProps, IEhiHealthcareProviderOrganizationUnitColumnDependencies>(c => ({
        referenceDataStore: c.resolve("HunEhiCareReferenceDataStore")
    }))
);
