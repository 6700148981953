import EpisodeOfCare from "@HisPlatform/Packages/Care/Model/EpisodeOfCare";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import Di from "@Di";
import { mapProxyFormFieldDataAsync } from "@HisPlatform/BoundedContexts/FormEngine/ApplicationLogic/ApiAdapter/FormEngineMappers";
import PatientId from "@Primitives/PatientId.g";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";

@Di.injectable()
export default class EpisodeOfCareSelectBoxApiAdapter extends ApiAdapterBase2 {

    constructor(
        @Di.inject("ICareClient") private readonly apiClient: Proxy.ICareClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("IFormEngineReferenceDataStore") private formEngineReferenceDataStore: IFormEngineReferenceDataStore
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public getEpisodesOfCareForPatientAsync(patientId: PatientId) {
        return this.executeOperationAsync<EpisodeOfCare[], Proxy.GetEpisodesOfCareForPatientQueryResponse>(
            () => {
                return this.apiClient.getEpisodesOfCareForPatientQueryAsync(CreateRequestId(), patientId.value);
            },
            async i => {

                const episodesOfCare: EpisodeOfCare[] = [];
                for (const dto of i.episodesOfCare) {
                    const mappedFormFieldData: FormFieldDataBase[] = [];
                    await mapProxyFormFieldDataAsync(dto.data as any, mappedFormFieldData, this.formEngineReferenceDataStore);

                    episodesOfCare.push(new EpisodeOfCare(mappedFormFieldData));
                }

                return episodesOfCare;
            });
    }
}