import React from "react";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import WorklistDefinitionId from "@Primitives/WorklistDefinitionId.g";
import { useWorklistDefinitionMasterDetailPanelStore, provideWorklistDefinitionMasterDetailPanelStore } from "./WorklistDefinitionMasterDetailPanelStoreProvider";
import MasterDetailLayout from "@CommonControls/Layout/MasterDetailLayout";
import WorklistDefinitionList from "./WorklistDefinitionList";
import WorklistDefinitionDetail from "./WorklistDefinitionDetail";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";

export interface IWorklistDefinitionMasterDetailPanelProps {
    selectedId: WorklistDefinitionId | null;
    onSelectedIdChange: (selectedId: WorklistDefinitionId | null) => void;
    _modalService?: IModalService;
}

function WorklistDefinitionMasterDetailPanel(props: IWorklistDefinitionMasterDetailPanelProps) {

    const store = useWorklistDefinitionMasterDetailPanelStore();

    return (
        <PermissionCheckContextProvider operationsToCheck={store.operationsToChecks}>
            <SingleLayout>
                <MasterDetailLayout
                    selectedItem={store.selectedId}
                    onSelectedItemChange={props.onSelectedIdChange}
                    master={<WorklistDefinitionList />}
                    detail={<WorklistDefinitionDetail />}
                />
            </SingleLayout>
        </PermissionCheckContextProvider>
    );
}

export default connect<IWorklistDefinitionMasterDetailPanelProps>(
    provideWorklistDefinitionMasterDetailPanelStore(WorklistDefinitionMasterDetailPanel),
    new HisModalServiceAdapter()
);
