import ObjectPermission from "@Primitives/ObjectPermission";
import AccessControlSubjectBase from "./AccessControlSubjectBase";

export default class AccessControlEntry {
    public permission: ObjectPermission;
    public subject: AccessControlSubjectBase;

    constructor(permission?: string, subject?: AccessControlSubjectBase) {
        this.permission = new ObjectPermission(permission);
        this.subject = subject;
    }
}