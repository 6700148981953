import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import _ from "@HisPlatform/Common/Lodash";
import Log from "@Log";
import IHostRoutingAdapter from "@Toolkit/ReactClient/Routing/Abstractions/IHostRoutingAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import config from "@Config";

export interface ITraceLoggerConfig {
    fetch: {
        responseIgnoredFromUrls: string[]
    };
    storage: {
        keysIgnored: string[]
    };
}

export default class TraceLogger {
    private static enabledKey = "tracingEnabled";
    private static isIgnored = false;

    private logQueue: string[] = [];

    constructor(
        logEvent: TypedEvent<string>,
        private readonly hostRoutingAdapter: IHostRoutingAdapter,
        private readonly storage: Storage = sessionStorage,
        private readonly loggerConfig: ITraceLoggerConfig = {
            fetch: {
                responseIgnoredFromUrls: [
                    "/api/Localization/Localization/GetResourceGroupQuery",
                    "/connect/token",
                    "/api/DocumentManagement/DocumentManagement/GetContentQuery",
                    "/api/Productivity/Worklist/GetWorklistQuery",
                    "releaseinfo.json"
                ]
            },
            storage: {
                keysIgnored: ["log", "access_token"]
            }
        }
    ) {
        logEvent.on(logEntry => {
            if (TraceLogger.isIgnored) {
                return;
            }

            this.logQueue.push(logEntry);
            this.debouncedLogPersister();
        });

        this.initFetchTracing();
        this.initRouteTracing();
    }

    public static ignore() {
        TraceLogger.isIgnored = true;
    }

    public static disableIgnore() {
        TraceLogger.isIgnored = false;
    }

    public static setEnabled(isEnabled: boolean) {
        const traceLogIsEnabled = !!config.traceLogEnabled ? config.traceLogEnabled : config.traceLogEnabledByDefault;
        if (traceLogIsEnabled) {
            if (isEnabled) {
                localStorage.setItem(TraceLogger.enabledKey, "true");
            } else {
                localStorage.setItem(TraceLogger.enabledKey, "false");
            }
        }
    }

    public static isEnabled() {
        const traceLogIsEnabled = !!config.traceLogEnabled ? config.traceLogEnabled : config.traceLogEnabledByDefault;
        if (traceLogIsEnabled) {
            const saved = localStorage.getItem(TraceLogger.enabledKey);
            if (isNullOrUndefined(saved)) {
                return config.traceLogEnabledByDefault;
            }
            return saved === "true";
        }
        return !!config.traceLogEnabled;
    }

    public clearLog() {
        return this.storage.removeItem("log");
    }

    public getLogContent() {
        const logs: string[] = JSON.parse(this.storage.getItem("log"));
        return logs.join("\r\n");
    }

    public getLogAsBlob() {
        return new Blob([this.getLogContent()], {
            type: "text/plain"
        });
    }

    public getLogReportAsBlob() {
        return new Blob([this.getLogReportContent()], {
            type: "text/html"
        });
    }

    public getLogReportContent() {
        const logs: string[] = JSON.parse(this.storage.getItem("log"));

        const logList = logs.map((logLine, idx) => {
            const [timestamp, browser, severity, currentRoute, userName, message] = logLine.split(" | ");
            return `{ recid: ${idx}, timestamp: "${timestamp}", browser: "${browser}", severity: "${severity}", currentRoute: "${currentRoute}", userName: "${userName}", message: '${message.replace(/\r|\n/gi, " ").replace(/'/gi, "\"")}' }`;
        });

        return `<!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8" />
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
    <script type="text/javascript" src="https://rawgit.com/vitmalina/w2ui/master/dist/w2ui.min.js"></script>
    <link rel="stylesheet" type="text/css" href="https://rawgit.com/vitmalina/w2ui/master/dist/w2ui.min.css" />
</head>
<body>
    <div id="grid" style="width: 100%; height: 90vh;"></div>
</body>
<script>
$(function () {
    const records = [
        ${logList.join(",")}
    ];
    $('#grid').w2grid({
        name: 'grid',
        header: 'MedWorkS log',
        show: {
            toolbar: true,
            footer: true
        },
        columns: [
            { field: 'timestamp', caption: 'Timestamp', size: '150px', sortable: true },
            { field: 'browser', caption: 'Browser', size: '100px', sortable: true },
            { field: 'severity', caption: 'Severity', size: '100px', sortable: true },
            { field: 'currentRoute', caption: 'Current route', size: '150px', sortable: true },
            { field: 'userName', caption: 'User', size: '100px', sortable: true },
            { field: 'message', caption: 'Message', size: '100%', sortable: true }
        ],
        searches: [
            { field: 'timestamp', caption: 'Timestamp', type: 'text' },
            { field: 'severity', caption: 'Severity', type: 'list', options: { items: ['trace', 'debug', 'info', 'warn', 'error', 'fatal'] } },
            { field: 'currentRoute', caption: 'Current route', type: 'text' },
            { field: 'message', caption: 'message', type: 'text' },
        ],
        sortData: [{ field: 'timestamp', direction: 'DESC' }],
        records
    });
    w2ui.grid.on('*', (event) => {
        if (event.type === "select") {
            const msg = records[event.detail.clicked.recid].message;
            window.lastMsg = msg;
            w2popup.open({
                width: 900,
                title: 'Message',
                text: "<div style=\\"text-align: left;overflow: auto;\\"><pre>" + msg + "</pre></div><button onClick=\\"navigator.clipboard.writeText(window.lastMsg);\\">Copy to clipboard</button>"
            });
            console.log(msg);
        }
    });
});
</script>
</html>
`;
    }

    private initRouteTracing() {
        this.hostRoutingAdapter.addNavigationEventHandler((info: { pathname: string }) => {
            Log.trace(`navigating to ${info && info.pathname}`);
        });
    }

    private initFetchTracing() {

        (window as any).stdFetch = window.fetch;

        let correlationIndex = 0;

        window.fetch = async (input: RequestInfo, init?: RequestInit) => {

            const myCorrelationIndex = correlationIndex++;
            const requestId = init?.headers?.["Request-Id"] ?? "N/A";

            Log.trace(`fetch(${myCorrelationIndex}) url: ${input.toString()} request-id: ${requestId}`);

            const start = performance.now();
            const result: Response = await (window as any).stdFetch(input, init);
            const end = performance.now();
            const elapsed = `${(Math.round(end - start))} ms`;

            if (result) {
                if (result.status === 400 || result.status === 500) {
                    try {
                        const clonedResult = result.clone();
                        const bodyText = await clonedResult.text();
                        Log.trace(`fetch(${myCorrelationIndex}) elapsed: ${elapsed}, response code: ${result.status} (${result.statusText}), response: ${bodyText} request-id: ${requestId}`);
                    } catch {
                        Log.trace(`fetch(${myCorrelationIndex}) elapsed: ${elapsed}, response code: ${result.status} (${result.statusText}), request-id: ${requestId}`);
                    }

                } else {
                    Log.trace(`fetch(${myCorrelationIndex}) elapsed: ${elapsed}, response code: ${result.status} (${result.statusText}), request-id: ${requestId}`);
                }

            } else {
                Log.trace(`fetch(${myCorrelationIndex}) elapsed: ${elapsed}, no response, request-id: ${requestId}`);
            }

            return result;
        };
    }

    private debouncedLogPersister = _.debounce(() => {
        const persistedLog: string[] = JSON.parse(this.storage.getItem("log") || "[]");
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const logEntry = this.logQueue.shift();
            if (!logEntry) { break; }
            persistedLog.push(logEntry);
        }
        const logToPersist = persistedLog.length > config.traceLogEntryLimit ? persistedLog.slice(persistedLog.length - config.traceLogEntryLimit, persistedLog.length) : persistedLog;

        try {
            this.storage.setItem("log", JSON.stringify(logToPersist));
        } catch (err) {
            this.storage.setItem("log", JSON.stringify([(err as Error).message]));
        }

    }, 1000);
}
