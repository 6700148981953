import Di from "@Di";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import moment from "moment";
import PatientBloodTypeStore from "./PatientBloodTypeStore";
import PatientBloodTypeId from "@Primitives/PatientBloodTypeId.g";
import PatientBloodTypeHistoryItem from "./PatientBloodTypeHistoryItem";

@Di.injectable()
export default class PatientBloodTypeFactory {

    public createNewStore() {
        const store = new PatientBloodTypeStore(true);
        store.id = new PatientBloodTypeId("new");
        store.categoryObservation = null;                
        store.historyItems.push(this.createNewHistoryItem());
        store.currentItemIndex = 0;
        return store;
    }

    private createNewHistoryItem() {
        const historyItem = new PatientBloodTypeHistoryItem();

        historyItem.isNew = true;
        historyItem.versionNumber = 1;
        historyItem.index = 0;
        historyItem.createdAt = moment(moment.now());        
        historyItem.historyItemDetail = new MedicalConditionHistoryItemDetail();
        historyItem.textualObservation = null;        
        return historyItem;
    }
}
