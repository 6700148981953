import Di from "@Di";
import IFrontendListDataWithDefinition from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/IFrontendListDataWithDefinition";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import IMapperService from "@HisPlatform/Services/Definition/MapperService/IMapperService";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class GlobalEpisodeOfCareListScreenApiAdapter extends ApiAdapterBase2 {
    
    constructor(
        @Di.inject("ICareClient") private readonly apiClient: Proxy.ICareClient,
        @Di.inject("IMapperService") private readonly mapperService: IMapperService,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader) {
        super(referenceDataLoader);
    }

    @State.bound
    public getGlobalEpisodeOfCareListAsync(
        frontendListParameters: FrontendListParameters
    ) {
        return this.executeOperationAsync<IFrontendListDataWithDefinition, Proxy.GetGlobalEpisodeOfCareListQueryResponse>(
            () => this.apiClient.getGlobalEpisodeOfCareListQueryAsync(CreateRequestId(), new Proxy.GetGlobalEpisodeOfCareListControllerDto({
                filters: this.mapperService.mapByName("FilterDtoArray", frontendListParameters),
                pagingAndOrderings: this.mapperService.mapByName("QueryPagingsAndOrderings", frontendListParameters),
                requestMode: frontendListParameters.requestMode
            })),
            response => {
                return this.mapperService.map<Proxy.FrontendListDto, IFrontendListDataWithDefinition>(response.frontendList);
            }
        );
    }
}