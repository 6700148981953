import DependencyContainer from "@DiContainer";

import "./ApiClientConfiguration";

import "./CareRegister/ApiAdapterConfiguration";
import "./CareRegister/StoreConfiguration";

import "./PatientRegister/ApiAdapterConfiguration";
import "./PatientRegister/StoreConfiguration";

import "./ReferenceData/ApiAdapterConfiguration";
import "./ReferenceData/StoreConfiguration";

import "./Worklist/ApiAdapterConfiguration";

import "./ServiceRequestManagement/ApiAdapterConfiguration";
import "./ServiceRequestManagement/OperationProcessingConfiguration";

import "./Actions/ApiAdapterConfiguration";
import "./Actions/StoreConfiguration";

import "./MedicalCondition/ApiAdapterConfiguration";
import "./MedicalCondition/StoreConfiguration";

import INewPatientBaseDataRepository from "@HisPlatform/BoundedContexts/Care/Services/Definition/NewPatientBaseDataRepository/INewPatientBaseDataRepository";
import NewPatientBaseDataRepository from "@HisPlatform/BoundedContexts/Care/Services/Implementation/NewPatientBaseDataRepository/NewPatientBaseDataRepository";
DependencyContainer
    .bind("INewPatientBaseDataRepository")
    .to(NewPatientBaseDataRepository)
    .inSingletonScope();

import IMedicalConditionReferenceDataResolver from "@HisPlatform/BoundedContexts/Care/Services/Definition/MedicalConditionReferenceDataResolver/IMedicalConditionReferenceDataResolver";
import MedicalConditionReferenceDataResolver from "@HisPlatform/BoundedContexts/Care/Services/Implementation/MedicalConditionReferenceDataResolver/MedicalConditionReferenceDataResolver";
DependencyContainer
    .bind("IMedicalConditionReferenceDataResolver")
    .to(MedicalConditionReferenceDataResolver)
    .inSingletonScope();

import IPatientCareActivitiesTabRegistry from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabRegistry";
import PatientCareActivitiesTabRegistry from "@HisPlatform/BoundedContexts/Care/Services/Implementation/PatientCareActivitiesTabRegistry/PatientCareActivitiesTabRegistry";
DependencyContainer
    .bind("IPatientCareActivitiesTabRegistry")
    .to(PatientCareActivitiesTabRegistry)
    .inSingletonScope();

import IServiceRequestDataService from "@HisPlatform/BoundedContexts/Care/Services/Definition/ServiceRequestManagement/IServiceRequestDataService";
import ServiceRequestDataService from "@HisPlatform/BoundedContexts/Care/Services/Implementation/ServiceRequestManagement/ServiceRequestDataService";
import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import CareBoundedContext from "@HisPlatform/BoundedContexts/Care/CareBoundedContext";
import EmbeddedFormStoreFactory from "@HisPlatform/BoundedContexts/Care/Services/Implementation/EmbeddedForm/EmbeddedFormStoreFactory";

DependencyContainer
    .bind("IServiceRequestDataService")
    .to(ServiceRequestDataService)
    .inSingletonScope();

DependencyContainer
    .bind("IBoundedContext")
    .to(CareBoundedContext);

DependencyContainer
    .bind("EmbeddedFormStoreFactory")
    .to(EmbeddedFormStoreFactory)
    .inSingletonScope();

import ICareActivitySourceRegistry from "@HisPlatform/BoundedContexts/Care/Services/Definition/CareActivitySourceRegistry/ICareActivitySourceRegistry";
import CareActivitySourceRegistry from "@HisPlatform/BoundedContexts/Care/Services/Implementation/CareActivitySourceRegistry/CareActivitySourceRegistry";

DependencyContainer
    .bind("ICareActivitySourceRegistry")
    .to(CareActivitySourceRegistry)
    .inSingletonScope();