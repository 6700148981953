exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MasterDetailView_no-text-selection_2Jlsq {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.MasterDetailView_outer-div_2PApZ {\n  min-height: 100%;\n  max-width: 1200px;\n  margin: 0 auto;\n}\n@media (min-width: 1900px) {\n  .MasterDetailView_outer-div_2PApZ {\n    max-width: 1550px;\n  }\n}\n.MasterDetailView_inner-second-div_2oWUf {\n  min-height: 100%;\n  max-width: 1200px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n}\n@media (min-width: 1900px) {\n  .MasterDetailView_inner-second-div_2oWUf {\n    max-width: 1550px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "MasterDetailView_no-text-selection_2Jlsq",
	"noTextSelection": "MasterDetailView_no-text-selection_2Jlsq",
	"outer-div": "MasterDetailView_outer-div_2PApZ",
	"outerDiv": "MasterDetailView_outer-div_2PApZ",
	"inner-second-div": "MasterDetailView_inner-second-div_2oWUf",
	"innerSecondDiv": "MasterDetailView_inner-second-div_2oWUf"
};