import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import BinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/BinaryDocument";
import DocumentInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentInfo";
import ScopeIdentifier from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ScopeIdentifier";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import DocumentBase from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentBase";
import TemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TemplateBasedDocument";
import DocumentFile from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentFile";
import DocumentChange from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentChange";
import { getTokenLockInfo } from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Templating/TemplateStoreMappers";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";

export function applyToDocument(target: DocumentBase, response: Proxy.GetDocumentCommandResponse): void {
    target.info = getDocumentInfo(response.document);
    target.info.lockInfo = getLockInfo(response);
    target.largeDataToken = response.largeDataToken;
    target.downloadFileName = response.downloadFileName;
    if (target instanceof BinaryDocument) {
        target.content = new Blob([Base64Converter.toByteArray(response.content)], { type: response.contentType });
    } else if (target instanceof TemplateBasedDocument) {
        target.templateContentToken = response.templateContentToken;
        const documentFile = DocumentFile.create(response.content);
        target.documentFile = documentFile;
        target.dataChangedSinceLastRefresh = response.changes.map(it => getChangeInfo(it));
        target.tokenLockInfos = response.editableTokensUsed.map(it => getTokenLockInfo(it));
    }
}

export function getChangeInfo(dto: Proxy.ChangedTemplateTokenDto) {
    return new DocumentChange(
        dto.tokenReference,
        dto.symbol,
        dto.nameResourceId,
        dto.isTwoWay
    );
}

export function getDocumentInfo(dto: Proxy.DocumentInfoDto) {
    const resp = new DocumentInfo(
        false,
        dto.id,
        dto.scopeIdentifiers?.map(si => new ScopeIdentifier(si.scope, si.identifier)),
        dto.documentTypeId ? new DocumentTypeId(dto.documentTypeId.value.toString()) : null,
        dto.documentKind,
        dto.createdById,
        dto.createdAt,
        dto.lastModifiedById,
        dto.lastModifiedAt,
        dto.documentState,
        dto.name,
        dto.possibleActions,
        dto.isPrimary,
        dto.metaProperties as any,
        dto.extensionData);

    resp.rowVersion = dto.rowVersion;
    return resp;
}

function getLockInfo(response: any): LockInfo {
    if ("wasLockRequested" in response) {
        return new LockInfo(
            response.lockState as unknown as Proxy.EntityLockState,
            response.heldLockId,
            response.wasLockRequested,
            response.hasUpdatePermission,
            response.preventingLock?.owner?.name,
            response.preventingLock?.id,
            response.preventingLock?.owner?.userId
        );
    } else {
        return null;
    }
}
