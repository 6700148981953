import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import PatientId from "@Primitives/PatientId.g";
import AppointmentNDataPanelFilterStore from "./AppointmentNDataPanelFilterStore";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import { IDefaultDetailPanelButtonsProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/Common/DefaultDetailPanelButtons";
import * as HisUi from "@HisPlatformControls";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import ScrollView from "@CommonControls/ScrollView/ScrollView";
import Styles from "./AppointmentsMasterDetailPanel.less";
import AppointmentsMasterDetailCalendarPortal from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/AppointmentsMasterDetailCalendarPortal";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import InputDataClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/InputDataClientSideAction";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import InputFormType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/InputFormType.g";
import AppointmentCancellationDialogParams, { IAppointmentCancellationDialogResult } from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/AppointmentCancellationDialog/AppointmentCancellationDialogParams";
import AppointmentCancellationReasonArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/AppointmentCancellationReasonArgument";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import ModalServiceAdapter from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAdapter";

interface IRegisteredPatientAppointmentsMasterDetailPanelDependencies {
    worklistApiAdapter: WorklistApiAdapter;
}

interface IRegisteredPatientNDataPanelProps {
    _dependencies?: IRegisteredPatientAppointmentsMasterDetailPanelDependencies;
    _patientId?: PatientId;
    onBack: () => void;
    onAdmitPatient: (appointmentId: AppointmentScheduleEntryId) => void;
    onSelectedAppointmentIdChange: (appointmentId: AppointmentScheduleEntryId) => void;
    isAdmitPatientAllowed: boolean;
    useCaseState: INDataUseCaseState;
    selectedRowId: string;
    onChange: (selectedRowId: string, useCaseState: INDataUseCaseState) => void;

    onCloseDetail: () => void;
    buttons?: React.ReactElement<IDefaultDetailPanelButtonsProps>;
    _modalService?: IModalService;
}

@State.observer
class RegisteredPatientNDataPanel extends React.Component<IRegisteredPatientNDataPanelProps> {
    @State.observable.ref private worklistDefinition: IWorklistDefinition = null;
    @State.computed private get hasNewRow() { return this.props.selectedRowId === "new"; }
    @State.computed private get isDetailOpen() { return !!this.props.selectedRowId; }

    private readonly refreshListEvent = new TypedAsyncEvent();

    public componentDidMount() {
        dispatchAsyncErrors(this.initializePanelAsync(), this);
    }

    @State.bound
    private async initializePanelAsync() {
        const resp = await this.props._dependencies.worklistApiAdapter.getAppointmentScheduleEntryBoundWorklistDefinition();
        State.runInAction(() => {
            this.worklistDefinition = resp.value;
        });
    }

    @State.action.bound
    private setPanelProps(useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) {
        return {
            onAdmitPatient: this.props.onAdmitPatient,
            onCancelAppointment: this.props.onCloseDetail,
            onAppointmentCreatedAsync: this.appointmentCreatedAsync,
            isAdmitPatientAllowed: this.props.isAdmitPatientAllowed,
            onRenderCalendar: this.renderCalendar,
            onScheduledAppointmentsCreated: this.scheduledAppointmentsCreatedAsync,
            onSave: this.appointmentUpdatedAsync
        };
    }

    @State.bound
    private getExtendedFilterDescriptors() {
        return AppointmentNDataPanelFilterStore.getFilterDescriptors();
    }

    @State.bound
    private renderPageButtons() {
        if (this.props.buttons) {
            return (
                <HisUi.HisPanelButtonPortal>
                    {this.props.buttons}
                </HisUi.HisPanelButtonPortal>
            );
        }
        return null;
    }

    @State.action.bound
    private setPatientFilterAsync(filterStore: any) {
        filterStore["AppointmentScheduleEntryBased_PatientId"] = this.props._patientId;
        return Promise.resolve();
    }

    @State.action.bound
    private async processInputDataClientSideActionAsync(clientSideAction: ClientSideActionDto) {
        if (clientSideAction instanceof InputDataClientSideAction) {
            if (clientSideAction.inputFormType === InputFormType.AppointmentCancellation) {
                const appointmentScheduleEntryId = clientSideAction.useCaseArguments[0].value.value;

                const dialogResult =
                    await this.props._modalService
                        .showDialogAsync<IAppointmentCancellationDialogResult>(
                            new AppointmentCancellationDialogParams(
                                new AppointmentScheduleEntryId(appointmentScheduleEntryId),
                                false
                            )
                        );

                if (dialogResult && dialogResult.appointmentDeleted) {
                    return new AppointmentCancellationReasonArgument(dialogResult.dialogData.appointmentCancellationReasonId, dialogResult.dialogData.additionalText);
                } else {
                    return null;
                }
            }
        }

        return false;
    }
    public render() {
        return (
            <>
                <SingleLayout>
                    <NDataPanel
                        onGetPanelProps={this.setPanelProps}
                        definition={this.worklistDefinition}
                        onChange={this.props.onChange}
                        useCaseState={this.props.useCaseState}
                        selectedRowId={this.props.selectedRowId}
                        onPerformClientSideActionAsync={this.processInputDataClientSideActionAsync}
                        hasNewRow={this.hasNewRow}
                        onFilterStoreCreatedAsync={this.setPatientFilterAsync}
                        onGetExtendedFilterDescriptors={this.getExtendedFilterDescriptors}
                        iconName="appointments"
                        disableDetailStrictMode
                        onRenderMasterList={this.renderMasterList}
                        refreshListEvent={this.refreshListEvent}
                    />
                </SingleLayout>
                {this.renderPageButtons()}
            </>
        );
    }

    @State.bound
    public renderMasterList(list: React.ReactNode) {
        if (this.isDetailOpen) {
            return (
                <>
                    <ScrollView height="50%" className={Styles.additionalContentContainer}>
                        <AppointmentsMasterDetailCalendarPortal.Host />
                    </ScrollView>
                    <div className={Styles.masterContainer}>
                        {list}
                    </div>
                </>
            );
        }
        return list;
    }

    @State.bound
    public renderCalendar(calendar: React.ReactNode) {
        return (
            <AppointmentsMasterDetailCalendarPortal>
                {calendar}
            </AppointmentsMasterDetailCalendarPortal>
        );
    }

    @State.bound
    private async appointmentCreatedAsync(id: AppointmentScheduleEntryId) {
        await this.refreshListEvent.emitAsync();
        this.props.onSelectedAppointmentIdChange?.(id);
    }

    @State.bound
    private async scheduledAppointmentsCreatedAsync() {
        await this.refreshListEvent.emitAsync();
    }

    @State.bound
    private async appointmentUpdatedAsync(type: "update" | "cancel") {
        if (type === "update") {
            await this.refreshListEvent.emitAsync();
        }
    }
}

export default connect(
    RegisteredPatientNDataPanel,
    new DependencyAdapter<IRegisteredPatientNDataPanelProps, IRegisteredPatientAppointmentsMasterDetailPanelDependencies>(c => ({
        worklistApiAdapter: c.resolve("WorklistApiAdapter")
    })),
    new PatientContextAdapter<IRegisteredPatientNDataPanelProps>(c => ({
        _patientId: c.patientId,
    })),
    new ModalServiceAdapter()
);
