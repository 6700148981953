import PatientAllergyIntoleranceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceStore";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import IPatientDocumentFormattingRegistry from "@PluginInterface/PatientDocumentFormatting/IPatientDocumentFormattingRegistry";
import AddressTypeId from "@Primitives/AddressTypeId.g";
import GenderId from "@Primitives/GenderId.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IExtensibleEnumValue from "@Toolkit/CommonWeb/Model/IExtensibleEnumValue";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import React from "react";
import * as Ui from "@CommonControls";
import Styles from "./PatientWidget.less";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";

interface IPatientTooltipProps {
    patient: PatientAdministrativeData;
    patientAllergies: PatientAllergyIntoleranceStore[];
}

export default function PatientTooltip(props: React.PropsWithChildren<IPatientTooltipProps>) {

    const { localizationService, commonReferenceDataStore, financeReferenceDataStore, patientDocumentFormattingRegistry } = useDependencies(c => ({
        localizationService: c.resolve<ILocalizationService>("ILocalizationService"),
        commonReferenceDataStore: c.resolve<CommonReferenceDataDataStore>("CommonReferenceDataDataStore"),
        financeReferenceDataStore: c.resolve<FinanceReferenceDataStore>("FinanceReferenceDataStore"),
        patientDocumentFormattingRegistry: c.resolve<IPatientDocumentFormattingRegistry>("IPatientDocumentFormattingRegistry"),
    }));

    const citizenshipCountry = commonReferenceDataStore.countryMap.get(props.patient.baseData.citizenshipCountryId);

    let gender: IExtensibleEnumValue<GenderId>;
    if (props.patient.baseData.genderId) {
        gender = commonReferenceDataStore.gender.get(props.patient.baseData.genderId);
    }
    let permanentAddress = "";
    const permanentAddressField = props.patient.addresses && props.patient.addresses.find(i => ValueWrapper.equals(i.addressTypeId, AddressTypeId.Permanent));
    if (permanentAddressField) {
        const country = commonReferenceDataStore.countryMap.get(permanentAddressField.countryId);
        permanentAddress = country && permanentAddressField && `${country.isoAlpha3} ${country.countryName}, ${permanentAddressField.zipCode} ${permanentAddressField.settlement} ${permanentAddressField.addressLine}`;
    }

    const patientInsurerOrganization = props.patient.insurances.length > 0 && props.patient.insurances[0].insurerOrganizationId;
    const insurerOrganization = patientInsurerOrganization && financeReferenceDataStore.insurerOrganizationMap.get(patientInsurerOrganization);

    let i = 0;

    return (
        <Popper
            arrowStyle={{ display: "none" }}
            wrapperElementType="div"
            tooltipContent={(
                <>
                    {renderTooltipRow(StaticWebAppResources.SearchPatient.Label.PatientIdentifier, props.patient.identifier, i++)}
                    {renderTooltipRow(StaticWebAppResources.Common.Label.Name, localizationService.localizePersonName(props.patient.baseData.name), i++)}
                    {renderTooltipRow(StaticWebAppResources.OutpatientWorkflowPage.Label.BirthName, localizationService.localizePersonName(props.patient.baseData.hasBirthName ? props.patient.baseData.birthName : props.patient.baseData.name), i++)}
                    {renderTooltipRow(StaticWebAppResources.OutpatientWorkflowPage.Label.BirthPlaceAndTime,
                        props.patient.baseData.birthLocationInfo.location + ", " + localizationService.localizeDate(props.patient.baseData.ageInfo.birthDate) + ` (${props.patient.age.value}/${gender && gender.displayValue.Shorthand || ""})`, i++)}
                    {citizenshipCountry && renderTooltipRow(StaticWebAppResources.PatientBaseData.Label.Citizenship, citizenshipCountry.isoAlpha3 + " " + citizenshipCountry.citizenshipName, i++)}
                    {permanentAddress && renderTooltipRow(StaticWebAppResources.OutpatientWorkflowPage.Label.PermanentAdress, permanentAddress, i++)}
                    {props.patient.telecomContactPoints && props.patient.telecomContactPoints.length > 0 && props.patient.telecomContactPoints.map(cp => {
                        const contactPointType = commonReferenceDataStore.telecomType.get(cp.telecomTypeId);
                        const contactPointUse = commonReferenceDataStore.telecomUse.get(cp.telecomUseId);
                        return contactPointType && contactPointUse && renderTooltipRow(`${contactPointType.displayValue.Name} (${contactPointUse.displayValue.Name})`, cp.value, i++);
                    })}
                    {props.patient.patientDocuments && props.patient.patientDocuments.map(pd => {
                        const documentType = commonReferenceDataStore.identifierSystemMap.getLocalization(pd.identifierSystemId);
                        return renderTooltipRow(StaticWebAppResources.OutpatientWorkflowPage.Label.PatientDocument + ` (${documentType && documentType.Name || ""})`, patientDocumentFormattingRegistry.format(pd.identifierSystemId.value, pd.identifierValue), i++);
                    })}
                    {insurerOrganization && renderTooltipRow(StaticCareResources.PatientRegister.InsuranceList.Label.InsurerOrganization, insurerOrganization.name, i++)}
                </>
            )}
            tooltipPlacement="right-start"
            tooltipClassName={Styles.tooltip}
        >
            {props.children}
        </Popper>
    );
}

const renderTooltipRow = (label: string, data: string, key: number) => {
    return (
        <Ui.Flex verticalSpacing="none" key={key}>
            <Ui.Flex.Item xs={5}>{label}:</Ui.Flex.Item>
            <Ui.Flex.Item xs={7}><b>{data}</b></Ui.Flex.Item>
        </Ui.Flex>
    );
};
