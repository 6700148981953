import React from "react";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IAccessControlModalParams } from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/AccessControlModal/AccessControlModalParams";
import EntityPermissionsApiAdapter from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/DataAccessControl/EntityPermissionsApiAdapter";
import UsersApiAdapter from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Users/UsersApiAdapter";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import UserGroupsApiAdapter from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Groups/UserGroupsApiAdapter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { EntityProtectionLevel } from "@HisPlatform/BoundedContexts/Authorization/Api/Proxy.g";
import EntityPermissionStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/DataAccessControl/EntityPermissionStore";
import UserListStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/UserListStore";
import UserGroupListStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroupListStore";
import _ from "@HisPlatform/Common/Lodash";
import ValidationResultsBuilder from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationResultsBuilder";
import AccessControlModalView from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/AccessControlModal/AccessControlModalView";
import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import RequestStatus from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/RequestStatus";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessModal from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessModal";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ApiBusinessError from "@Toolkit/CommonWeb/ApiAdapter/ApiBusinessError";
import UnauthorizedOperationBusinessError from "@Toolkit/CommonWeb/Model/UnauthorizedOperationBusinessError";

interface IAccessControlModalDependencies {
    permissionsApiAdapter: EntityPermissionsApiAdapter;
    usersApiAdapter: UsersApiAdapter;
    userContext: UserContext;
    userGroupsApiAdapter: UserGroupsApiAdapter;
    notificationService: INotificationService;
    dialogService: IDialogService;
}

export interface IAccessControlModalProps extends IModalComponentParams<void>, IAccessControlModalParams {
    _dependencies?: IAccessControlModalDependencies;
}

/** @screen */
@State.observer
class AccessControlModal extends React.Component<IAccessControlModalProps> {

    private get permissionsApiAdapter() { return this.props._dependencies.permissionsApiAdapter; }
    private get usersApiAdapter() { return this.props._dependencies.usersApiAdapter; }
    private get userContext() { return this.props._dependencies.userContext; }
    private get userGroupsApiAdapter() { return this.props._dependencies.userGroupsApiAdapter; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get dialogService() { return this.props._dependencies.dialogService; }

    @State.observable public entityProtectionLevel: EntityProtectionLevel = null;
    @State.observable.ref public permissionStore = new EntityPermissionStore();
    @State.observable.ref public originalEntityPermissionStore = new EntityPermissionStore();
    @State.observable.ref public usersStore = new UserListStore();
    @State.observable.ref public userGroupsStore = new UserGroupListStore();
    @State.observable public userHasOperationPermission: boolean = false;

    @State.computed public get otherValidationProblems() {
        const result = this.permissionStore.validationResults?.find(vr => vr.entityName === "AccessControlList");
        return result?.problems.filter(p => !p.propertyPath) ?? [];
    }

    @State.action.bound
    private setPermissionStore(permissionStore: EntityPermissionStore) {
        this.permissionStore = permissionStore;
    }

    @State.action.bound
    private setOriginalPermissionStore(permissionStore: EntityPermissionStore) {
        this.originalEntityPermissionStore = permissionStore;
    }

    @State.action.bound
    private setUsersStore(listStore: UserListStore) {
        this.usersStore = listStore;
    }

    @State.action.bound
    private setUserGroupsStore(listStore: UserGroupListStore) {
        this.userGroupsStore = listStore;
    }

    public get isDisabled() {
        return !(
            this.permissionStore.isCurrentUserAllowedToSetPermissions &&
            this.userHasOperationPermission
        );
    }

    public get isOriginalEmpty() {
        return this.originalEntityPermissionStore.accessControlList.isEmpty();
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);
    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.bound
    public async loadAsync() {
        const permissionsStore = await this.permissionsApiAdapter.getEntityPermissionsAsync(this.props.entityType, this.props.entityId);
        this.setPermissionStore(permissionsStore);

        try {
            await this.permissionsApiAdapter.setEntityPermissionsAsync(permissionsStore, this.props.entityType, this.props.entityId, true);
            State.runInAction(() => { this.userHasOperationPermission = true; });
        } catch (err) {
            if (err instanceof ApiBusinessError && err.error instanceof UnauthorizedOperationBusinessError) {
                State.runInAction(() => { this.userHasOperationPermission = false; });
            }
        }

        const usersStore = await this.usersApiAdapter.loadAllUsersAsync();
        this.setUsersStore(usersStore);
        const userGroupsStore = await this.userGroupsApiAdapter.getAllUserGroupsAsync();
        this.setUserGroupsStore(userGroupsStore);

        this.setOriginalPermissionStore(_.cloneDeep(permissionsStore));

        State.reaction(() => this.permissionStore.accessControlList.entityProtectionLevel, level => {
            if (!this.isDisabled) {
                this.addCurrentUserToLists();
            }
        });
    }

    @State.bound
    public hasModification() {
        return !this.permissionStore.accessControlList.equals(this.originalEntityPermissionStore.accessControlList);
    }

    @State.bound
    private addCurrentUserToLists() {
        const currentUserId = this.userContext.id;
        if (this.permissionStore.isCurrentUserAllowedToSetPermissions) {
            if (this.permissionStore.accessControlList.hasEntries("Access") === false) {
                this.permissionStore.addUserIdIfNotContains("Access", currentUserId);
            }
            if (this.permissionStore.accessControlList.hasEntries("SetPermissions") === false) {
                this.permissionStore.addUserIdIfNotContains("SetPermissions", currentUserId);
            }
        }
    }

    @State.action.bound
    public validate(): boolean {

        const builder = new ValidationResultsBuilder();

        let valid = true;
        if (this.permissionStore.accessControlList.entityProtectionLevel === null) {
            builder.addProblem({
                entityName: "AccessControlList",
                entityPath: "EntityProtectionLevel"
            }, "MissingEntityProtectionLevel", "error", null, "WebApp.Common.ValidationMessage.AccessControlList");

            valid = false;
        }
        if (
            this.permissionStore.accessControlList.hasEntries("Access") === false ||
            this.permissionStore.accessControlList.hasEntries("SetPermissions") === false
        ) {
            builder.addProblem({
                entityName: "AccessControlList"
            }, "MissingPermissionEntries", "error", null, "WebApp.Common.ValidationMessage.AccessControlList");

            valid = false;
        }

        this.permissionStore.validationResults = builder.getValidationResults();

        return valid;
    }

    @State.bound
    private async saveAsync() {
        if (this.validate()) {
            const result = await this.permissionsApiAdapter.setEntityPermissionsAsync(
                this.permissionStore, this.props.entityType, this.props.entityId);

            this.notificationService.showSaveResult(result.isPersistedByOperationInfo);
            this.props.onClose();
        } else {
            this.notificationService.showCannotSaveBecauseOfErrors();
        }
    }

    @State.bound
    private async cancelAsync() {
        if (this.hasModification() && !this.isDisabled) {
            const answer = await this.dialogService.yesNoCancel(StaticAuthorizationResources.Common.Dialog.ConfirmationTitle, StaticAuthorizationResources.Common.Dialog.LeaveWithoutSavingConfirmationMessage);
            if (answer.resultCode === DialogResultCode.Yes) {
                await this.saveAsync();
            } else if (answer.resultCode === DialogResultCode.No) {
                this.props.onClose();
            }
        } else {
            this.props.onClose();
        }
    }

    @State.bound
    private async disableAccessControlAsync() {
        const answer = await this.dialogService.yesNo(StaticAuthorizationResources.Common.Dialog.ConfirmationTitle, StaticAuthorizationResources.AccessControlList.Dialog.DisableAccessControlConfirmation);
        if (answer.resultCode === DialogResultCode.Yes) {
            const result = await this.permissionsApiAdapter.clearEntityPermissionsAsync(
                this.permissionStore, this.props.entityType, this.props.entityId
            );

            if (result.operationInfo.requestStatus === RequestStatus.Success) {
                this.props.onClose();
                this.notificationService.success(StaticAuthorizationResources.AccessControlList.ToastMessages.AccessControlSuccesfullyDisabled);
            }
        }
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessModal title={StaticWebAppResources.DailyPatientList.DataAccessControlListTitle} />;
        }

        return (
            <AccessControlModalView
                currentUserId={this.userContext.id}
                isDisabled={this.isDisabled}
                isOriginalEmpty={this.isOriginalEmpty}
                permissionStore={this.permissionStore}
                otherValidationProblems={this.otherValidationProblems}
                usersStore={this.usersStore}
                userGroupsStore={this.userGroupsStore}
                onSaveAsync={this.saveAsync}
                onCancelAsync={this.cancelAsync}
                onDisableAccessControlAsync={this.disableAccessControlAsync}
            />
        );
    }
}

export default connect(
    AccessControlModal,
    new DependencyAdapter<IAccessControlModalProps, IAccessControlModalDependencies>(c => ({
        permissionsApiAdapter: c.resolve("EntityPermissionsApiAdapter"),
        usersApiAdapter: c.resolve("UsersApiAdapter"),
        userContext: c.resolve("UserContext"),
        userGroupsApiAdapter: c.resolve("UserGroupsApiAdapter"),
        notificationService: c.resolve("INotificationService"),
        dialogService: c.resolve("IDialogService")
    }))
);