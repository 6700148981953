import React from "react";
import FinancedServiceMinimumTimeReportPanelView from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Panels/ReportingPanel/FinancedServiceMinimumTimeReportPanel/FinancedServiceMinimumTimeReportPanelView";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { IReportDefinition } from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/Model/IReportDefinition";
import ReportingApiAdapter from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/ReportingApiAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IFinancedServiceMinimumTimeReportFilters } from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Panels/ReportingPanel/FinancedServiceMinimumTimeReportPanel/IFinancedServiceMinimumTimeReportFilters";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import DocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/DocumentApiAdapter";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface IFinancedServiceMinimumTimeReportPanelDependencies {
    apiAdapter: ReportingApiAdapter;
    fileSaverService: IFileSaverService;
    notificationService: INotificationService;
    documentApiAdapter: DocumentApiAdapter;
}

interface IFinancedServiceMinimumTimeReportPanelProps {
    _dependencies?: IFinancedServiceMinimumTimeReportPanelDependencies;
    definition: IReportDefinition;
}

/** @screen */
@State.observer
class FinancedServiceMinimumTimeReportPanel extends React.Component<IFinancedServiceMinimumTimeReportPanelProps> {

    private get apiAdapter() { return this.props._dependencies.apiAdapter; }
    private get fileSaverService() { return this.props._dependencies.fileSaverService; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get documentApiAdapter() { return this.props._dependencies.documentApiAdapter; }

    @State.observable.ref private pointOfCares: PointOfCareId[] = [];
    @State.observable.ref private reportDate: YearMonth = null;

    private readonly loadAsync = createInitialPanelLoader(this._loadAsync);

    @State.action.bound
    private onReportDateChange(newValue: YearMonth) {
        this.reportDate = newValue;
    }

    @State.action.bound
    public onPointOfCareChange(newValue: PointOfCareId, checkedValue: boolean) {
        if (this.pointOfCares) {
            if (checkedValue === false) {
                this.pointOfCares = this.pointOfCares.filter(x => x.value !== newValue.value);
            } else if (!this.pointOfCares.some(x => x.value === newValue.value)) {
                this.pointOfCares = [...this.pointOfCares, newValue];
            }
        }
    }

    @State.action.bound
    private async onCreateAsync() {
        const filters = {
            reportDate: LocalDate.fromYearMonth(this.reportDate),
            organizationUnitIds: this.pointOfCares
        } as IFinancedServiceMinimumTimeReportFilters;

        if (!this.isInvalid(filters)) {
            const parameterString = JSON.stringify(filters);
            const response = await this.apiAdapter.runReportAsync(this.props.definition.reportDefinitionIdentifier, JSON.parse(parameterString));
            const documentResponse = await this.documentApiAdapter.getDocumentContentAsync(response.value.largeDataIds[0]);
            const contentBytes = Base64Converter.toByteArray(documentResponse.value.content);
            this.fileSaverService.saveAs(new Blob([contentBytes], { type: documentResponse.value.mediaType }), documentResponse.value.fileName);
        }
    }

    @State.action
    private isInvalid(filters: IFinancedServiceMinimumTimeReportFilters): boolean {
        let i = 0;

        if (isNullOrUndefined(filters.reportDate)) {
            this.notificationService.error("Jelentési hónap megadása kötelező!");
            i++;
        } else if (isNullOrUndefined(filters.organizationUnitIds) || filters.organizationUnitIds.length === 0) {
            this.notificationService.error("Legalább egy munkahely választása kötelező!");
            i++;
        }

        return i > 0;
    }

    @State.bound
    private async _loadAsync() {
        // Check permission
        await this.apiAdapter.runReportAsync(this.props.definition.reportDefinitionIdentifier, {} as JSON, true);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }
    public render() {
        if (this.loadAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }

        return (
            <FinancedServiceMinimumTimeReportPanelView
                definition={this.props.definition}
                reportDate={this.reportDate}
                pointOfCares={this.pointOfCares}
                onReportDateChange={this.onReportDateChange}
                onPointOfCareChange={this.onPointOfCareChange}
                onCreateAsync={this.onCreateAsync}
            />
        );
    }
}

export default connect(
    FinancedServiceMinimumTimeReportPanel,
    new DependencyAdapter<IFinancedServiceMinimumTimeReportPanelProps, IFinancedServiceMinimumTimeReportPanelDependencies>(c => {
        return {
            apiAdapter: c.resolve("ReportingApiAdapter"),
            fileSaverService: c.resolve("IFileSaverService"),
            notificationService: c.resolve("INotificationService"),
            documentApiAdapter: c.resolve("DocumentApiAdapter")
        };
    })
);