import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ValidationResultSummaryView from "./ValidationResultSummaryView";
import IValidationResultSummaryLocalizator from "./IValidationResultSummaryLocalizator";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import ValidationBoundaryAdapter from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryAdapter";
import _ from "@HisPlatform/Common/Lodash";

interface IValidationResultSummaryDependencies {
    localizationService: IToolkitLocalizationService;
}

interface IValidationResultSummaryProps {
    _dependencies?: IValidationResultSummaryDependencies;
    _validationBoundary?: IValidationBoundaryStore;

    results?: IClientValidationProblem[];
    displayMode?: "summary" | "list";
    displayCount?: number;
    maxDisplayCount?: number;
    localizator?: IValidationResultSummaryLocalizator;
    validText?: string;
    propertyPath?: string;

    highlightedId?: IClientValidationProblem;
    setHighlightedId?: (id: IClientValidationProblem) => void;
    highlightableRuleIds?: string[];
    infoRenderer?: (id: IClientValidationProblem) => React.ReactNode;
    distinctByRuleId?: boolean;
    excludedRuleIds?: string;
}

@State.observer
class ValidationResultSummary extends React.Component<IValidationResultSummaryProps> {

    @State.observable public isOpen: boolean = false;
    @State.observable.ref public selectedTabIndex: number = 0;

    public static defaultProps: Partial<IValidationResultSummaryProps> = {
        displayMode: "summary",
        displayCount: 3,
        maxDisplayCount: 100
    };

    private get localizator() {
        return this.props.localizator || this.props._dependencies.localizationService.getValidationResultSummaryLocalizator();
    }

    @State.computed private get results() {
        let results = this.props.results ?? this.props._validationBoundary?.getValidationProblems(this.props.propertyPath, false, true);

        if (!!this.props.distinctByRuleId) {
            return _.uniqBy(results, e => e.ruleId);
        }

        if (this.props.excludedRuleIds) {
            const excludedRuleIdsArray = this.props.excludedRuleIds.split(",");
            results = results.filter(item => !excludedRuleIdsArray.includes(item.ruleId));
        }

        return results;
    }

    @State.computed private get errorCount() {
        return this.results?.filter((r: any) => r.severity === "error")?.length || 0;
    }

    @State.computed private get warningCount() {
        return this.results?.filter((r: any) => r.severity === "warning")?.length || 0;
    }

    constructor(props: IValidationResultSummaryProps) {
        super(props);
        if (props.maxDisplayCount < 0 || props.maxDisplayCount > 100) {
            throw Error("Property 'maxDisplayCount' value should be between 0 and 100.");
        }
        if (props.displayCount < 0 || props.displayCount > 100) {
            throw Error("Property 'displayCount' value should be between 0 and 100.");
        }
    }

    @State.action.bound
    private setIsOpen() {
        this.isOpen = !this.isOpen;
    }

    @State.action.bound
    private setSelectedTabIndex(newValue: number) {
        this.selectedTabIndex = newValue;
    }

    public render() {
        return (
            <ValidationResultSummaryView
                results={this.results}
                title={this.localizator.getTitle(this.errorCount, this.warningCount, this.props.validText)}
                displayCount={this.props.displayCount}
                maxDisplayCount={this.props.maxDisplayCount}
                messageLocalizator={this.localizator.getValidationMessage}
                moreButtonShowLabel={this.localizator.moreButtonShowLabel}
                moreButtonHideLabel={this.localizator.moreButtonHideLabel}
                errorCount={this.errorCount}
                warningCount={this.warningCount}
                isOpen={this.isOpen}
                setIsOpen={this.setIsOpen}
                displayMode={this.props.displayMode}
                validText={this.props.validText}
                highlightedProblem={this.props.highlightedId}
                setHighlightedProblem={this.props.setHighlightedId}
                selectedTabIndex={this.selectedTabIndex}
                setSelectedTabIndex={this.setSelectedTabIndex}
                infoRenderer={this.props.infoRenderer}
                highlightableRules={this.props.highlightableRuleIds}
            />
        );
    }
}

export default connect(
    ValidationResultSummary,
    new DependencyAdapter<IValidationResultSummaryProps, IValidationResultSummaryDependencies>(c => ({
        localizationService: c.resolve("IToolkitLocalizationService")
    })),
    new ValidationBoundaryAdapter()
);
