import PractitionerId from "@Primitives/PractitionerId.g";
import StoreBase from "@Toolkit/CommonWeb/Model/StoreBase";
import PractitionerIdentifierStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerIdentifierStore";

export default class UserInfoStore extends StoreBase {
    public practitionerId?: PractitionerId;
    public displayName?: string;
    public identifiers? :PractitionerIdentifierStore[];

    constructor() {
        super();
    }
}