import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import CoverageEligibilityCheckResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CheckCoverageEligibility/CoverageEligibilityCheckResult";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import _ from "@HisPlatform/Common/Lodash";

export default class CoverageCheckListPanelFilterStore {
    @State.observable.ref public checkedAtRange: LocalDateRange = new LocalDateRange();
    @State.observable public patientName: string = "";
    @State.observable public patientIdentifier: string = "";
    @State.observable.ref public patientBirthDate: LocalDateRange = new LocalDateRange();
    @State.observable.ref public coverageCheckResult: CoverageEligibilityCheckResult = null;
    @State.observable public errorCode: string = "";
    @State.observable public transactionCode: string = "";
    @State.observable.ref public pointOfCareIds: PointOfCareId[] = [];

    private debounceChange = _.debounce(() => {
        this.onChange();
    }, 1000);

    constructor(private onChange: () => void) {

    }

    @State.action.bound
    public setCheckedAtRange(checkedAtRange: LocalDateRange) {
        this.checkedAtRange = checkedAtRange ?? new LocalDateRange(null, null);
        this.debounceChange();
    }

    @State.action.bound
    public setPatientName(patientName: string) {
        this.patientName = patientName;
        this.debounceChange();
    }

    @State.action.bound
    public setPatientIdentifier(patientIdentifier: string) {
        this.patientIdentifier = patientIdentifier;
        this.debounceChange();
    }

    @State.action.bound
    public setPatientBirthDate(patientBirthDate: LocalDateRange) {
        this.patientBirthDate = patientBirthDate ?? new LocalDateRange(null, null);
        this.debounceChange();
    }

    @State.action.bound
    public setCoverageCheckResult(coverageCheckResult: CoverageEligibilityCheckResult) {
        this.coverageCheckResult = coverageCheckResult;
        this.debounceChange();
    }

    @State.action.bound
    public setErrorCode(errorCode: string) {
        this.errorCode = errorCode;
        this.debounceChange();
    }

    @State.action.bound
    public setTransactionCode(transactionCode: string) {
        this.transactionCode = transactionCode;
        this.debounceChange();
    }

    @State.action.bound
    public setPointOfCareIds(pointOfCareIds: PointOfCareId[]) {
        this.pointOfCareIds = pointOfCareIds;
        this.debounceChange();
    }

    //#region Getters for datagrid
    @State.bound
    public getCheckedAtRange() {
        return this.checkedAtRange;
    }

    @State.bound
    public getPatientName() {
        return this.patientName;
    }

    @State.bound
    public getPatientIdentifier() {
        return this.patientIdentifier;
    }

    @State.bound
    public getPatientBirthDate() {
        return this.patientBirthDate;
    }

    @State.bound
    public getCoverageCheckResult() {
        return this.coverageCheckResult;
    }

    @State.bound
    public getErrorCode() {
        return this.errorCode;
    }

    @State.bound
    public getTransactionCode() {
        return this.transactionCode;
    }

    @State.bound
    public getPointOfCareIds() {
        return this.pointOfCareIds;
    }
    //#endregion
}