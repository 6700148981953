import Di from "@Di";
import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import FinanceApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/Finance/FinanceApiAdapter";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import InsurerOrganizationTypeId from "@Primitives/InsurerOrganizationTypeId.g";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import TemporalReferenceDataStore from "@Toolkit/CommonWeb/TemporalData/TemporalReferenceDataStore";
import FinancedServiceApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/Finance/FinancedServiceApiAdapter";
import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import FinancedService from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinancedService";
import InvoiceTypeId from "@Primitives/InvoiceTypeId.g";
import VendorId from "@Primitives/VendorId.g";
import PreloadedReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/PreloadedReferenceDataStore";
import VatRateId from "@Primitives/VatRateId.g";
import IVendor from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/IVendor";
import IVatRate from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/IVatRate";
import VendorApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/Finance/VendorApiAdapter";
import VatRateApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/Finance/VatRateApiAdapter";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

@Di.injectable()
export default class FinanceReferenceDataStore extends ReferenceDataStoreBase {

    public insurerOrganizationMap = new ReferenceDataStore(this.financeApiAdapter.getInsurerOrganizationsByIdsAsync, this.financeApiAdapter.getAllInsurerOrganizationIdsAsync);
    public insurancePlanMap = new ReferenceDataStore(this.financeApiAdapter.getInsurancePlansByIdsAsync);
    public insurerOrganizationTypes = new ExtensibleEnumStore<InsurerOrganizationTypeId>(this.financeApiAdapter.loadInsurerOrganizationTypesAsync, this.localizationService.localizeEnumId);
    public financedServiceMap = new TemporalReferenceDataStore<FinancedServiceId, FinancedService>(this.financedServiceApiAdapter.loadFinancedServicesAsync);
    public invoiceTypeMap = new ExtensibleEnumStore<InvoiceTypeId>(this.financeApiAdapter.loadInvoiceTypesAsync, this.localizationService.localizeEnumId);
    public paymentTypeMap = new ExtensibleEnumStore<InvoiceTypeId>(this.financeApiAdapter.loadPaymentTypesAsync, this.localizationService.localizeEnumId);
    public vendorMap = new PreloadedReferenceDataStore<VendorId, IVendor>(async () => (await this.vendorApiAdapter.getAllProviderAsync()).result);
    public vatRateMap = new PreloadedReferenceDataStore<VatRateId, IVatRate>(async () => (await this.vatRateApiAdapter.getAllVatRatesAsync()).result);

    constructor(
        @Di.inject("FinanceApiAdapter") private readonly financeApiAdapter: FinanceApiAdapter,
        @Di.inject("FinancedServiceApiAdapter") private readonly financedServiceApiAdapter: FinancedServiceApiAdapter,
        @Di.inject("VendorApiAdapter") private readonly vendorApiAdapter: VendorApiAdapter,
        @Di.inject("VatRateApiAdapter") private readonly vatRateApiAdapter: VatRateApiAdapter,
        @Di.inject("ILocalizationService") private readonly localizationService: ILocalizationService,
        @Di.inject("IReferenceDataLoader") private readonly referenceDataLoader: IReferenceDataLoader,
    ) {
        super();
        referenceDataLoader.register<FinancedServiceId>(FinancedServiceId.typeName, (refs) => this.financedServiceMap.ensureLoadedAsync(refs.map(i => ({ id: i, validOn: LocalDate.today() }))));
    }

    public async getInsurancePlanIdsByInsurerOrganizationIdAsync(insurerOrganizationId: InsurerOrganizationId) {
        return await this.financeApiAdapter.getInsurancePlanIdsByInsurerOrganizationIdAsync(insurerOrganizationId);
    }
}
