/* auto-inject-disable */
import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import Di from "@Di";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import WorkingStatusId from "@Primitives/WorkingStatusId.g";
import CommonReferenceDataExtensibleEnumApiAdapter from "@AssecoMedPlugin/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/CommonReferenceDataExtensibleEnumApiAdapter";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import { GetTypeNameWithPrefix } from "@AssecoMedPlugin/PluginHelper";
import WorkplaceTypeId from "@Primitives/WorkplaceTypeId.g";

@Di.injectable()
export default class CommonReferenceDataDataStore extends ReferenceDataStoreBase {

    public workingStatus = new ExtensibleEnumStore<WorkingStatusId>(this.apiAdapter.loadWorkingStatusIdsAsync, this.localizationService.localizeEnumId);

    public workplaceType = new ExtensibleEnumStore<WorkplaceTypeId>(this.apiAdapter.loadWorkplaceTypeIdsAsync, this.localizationService.localizeEnumId);

    constructor(
        @Di.inject(GetTypeNameWithPrefix("CommonReferenceDataExtensibleEnumApiAdapter")) private readonly apiAdapter: CommonReferenceDataExtensibleEnumApiAdapter,
        @Di.inject("ILocalizationService") private readonly localizationService: ILocalizationService,
        @Di.inject("IReferenceDataLoader") private readonly referenceDataLoader: IReferenceDataLoader
    ) {
        super();
        referenceDataLoader.register(WorkingStatusId.typeName, () => this.workingStatus.ensureLoadedAsync());
        referenceDataLoader.register(WorkplaceTypeId.typeName, () => this.workplaceType.ensureLoadedAsync());
    }
}
