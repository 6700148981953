import React from "react";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import { components } from "react-select";
import SelectBox, { SelectBoxCore, inputChangeActionType } from "@CommonControls/SelectBox";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { TextBox, Button, SideMenu, Icon } from "@CommonControls";
import Styles from "./DateRangePicker.less";
import Calendar from "@CommonControls/DateTimePicker/Calendar";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import SideMenuItem from "@CommonControls/SideMenu/SideMenuItem";
import moment from "moment";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ReadOnlyContextAdapter from "@Toolkit/ReactClient/Components/ReadOnlyContext/ReadOnlyContextAdapter";
import IDateRangeDisplayText from "@CommonControls/DateRangePicker/IDateRangeDisplayText";
import { stateActivityCompleted } from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ServiceRequestStateBadge/ServiceRequestStateBadge.less";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

// Keep this in sync with it's localizations in ReferenceData
export enum IntervalOption {
    Today,
    Yesterday,
    TodayAndYesterday,
    Last7Days,
    Last30Days,
    ThisMonth,
    LastMonth,
    ThisWeek,
    LastWeek,
    ThisYear,
    LastYear,
    Last5Years
}

export interface IDateRangePickerDependencies {
    dateTimeFormatProvider: IDateTimeFormatProvider;
    toolkitLocalizationService: IToolkitLocalizationService;
    dialogService: IDialogService;
}

interface IDateRangePickerProps extends ISelectBoxBaseProps {
    _dependencies?: IDateRangePickerDependencies;

    value?: LocalDateRange;
    onChange?: (newValue: LocalDateRange) => void;

    forwardedRef?: React.Ref<SelectBoxCore>;
    intervalOptions?: IntervalOption[]; // IToolkitStaticResources.dateRangePicker
    initialSelectionState?: "from" | "to" | "allDay";
}

const valueOnClear = { __clear: true };
const dummyItems = [{} as any];

@State.observer
class DateRangePicker extends React.Component<IDateRangePickerProps> {

    private get dateTimeFormatProvider() { return this.props._dependencies.dateTimeFormatProvider; }
    private get toolkitLocalizationService() { return this.props._dependencies.toolkitLocalizationService; }
    private get dialogService() { return this.props._dependencies.dialogService; }
    private get staticResources() { return this.toolkitLocalizationService.staticResources; }

    public static defaultProps: Partial<IDateRangePickerProps> = {
        clearable: true,
        intervalOptions: [
            IntervalOption.Today,
            IntervalOption.Yesterday,
            IntervalOption.TodayAndYesterday,
            IntervalOption.Last7Days,
            IntervalOption.Last30Days,
            IntervalOption.ThisMonth,
            IntervalOption.LastMonth
        ]
    };

    private isDefaultFlow = true;

    @State.observable.ref private menuIsOpen = false;
    @State.observable.ref private inputIsFocused = false;
    @State.observable.ref private internalFromValue: LocalDate = null;
    @State.observable.ref private internalToValue: LocalDate = null;
    @State.observable.ref private selectionState: "from" | "to" | "allDay" = this.props.initialSelectionState ? this.props.initialSelectionState : "allDay";
    @State.observable.ref private textEditState: "enter" | "clear" = "enter";
    @State.observable.ref private nonParsableInputText: IDateRangeDisplayText = null;

    @State.computed
    private get internalValue(): LocalDateRange {
        if (!this.internalFromValue && !this.internalToValue) {
            return null;
        }
        return new LocalDateRange(this.internalFromValue, this.internalToValue);
    }

    @State.computed
    private get displayValue() {
        const value = this.inputIsFocused ? this.internalValue : this.props.value;

        return !isNullOrUndefined(value) ? value : new LocalDateRange();
    }

    @State.computed
    private get displayTextValue() {
        const dateFormat = this.dateTimeFormatProvider.getDateFormat();

        const formattedFrom = isNullOrUndefined(this.displayValue.from) || this.displayValue.from.isEmpty() ? "" : this.displayValue.from.toUtcDayStartMoment().format(dateFormat);
        const formattedTo = isNullOrUndefined(this.displayValue.to) || this.displayValue.to.isEmpty() ? "" : this.displayValue.to.toUtcDayStartMoment().format(dateFormat);

        return { from: formattedFrom, to: formattedTo } as IDateRangeDisplayText;
    }

    @State.computed
    private get menuText() {
        if (this.displayTextValue.from === "" || this.selectionState === "allDay") {
            return this.displayTextValue.from;
        }
        return `${this.displayTextValue.from} - ${this.displayTextValue.to}`;
    }

    @State.computed
    private get selectBoxText() {
        const fromText = (!isNullOrUndefined(this.nonParsableInputText?.from)) ? this.nonParsableInputText.from : this.displayTextValue.from;
        const toText = (!isNullOrUndefined(this.nonParsableInputText?.to)) ? this.nonParsableInputText.to : this.displayTextValue.to;

        if (fromText === "" && toText === "") {
            return "";
        }
        if (fromText !== "" && toText !== "" && fromText === toText) {
            return fromText;
        }
        if (toText === "") {
            if (fromText.length < 10) {
                return `${fromText}`;
            }
            return `${fromText} -`;
        }
        return `${fromText} - ${toText}`;
    }

    constructor(props: IDateRangePickerProps) {
        super(props);
    }

    public componentDidUpdate(prevProps: IDateRangePickerProps) {
        if (prevProps.value !== this.props.value) {
            this.refreshInternalState();
        }
    }

    @State.action
    private resetInternalState() {
        this.internalFromValue = null;
        this.internalToValue = null;
        this.nonParsableInputText = { from: null, to: null } as IDateRangeDisplayText;
        this.selectionState = this.selectionState === "allDay" ? "allDay" :
            (this.props.value?.from === null || this.props.value?.to !== null ? "from" : "to");
        this.isDefaultFlow = true;
    }

    @State.action
    private refreshInternalState() {
        if (!!this.props.value) {
            this.internalFromValue = this.props.value.from;
            this.internalToValue = this.props.value.to;
        }
        this.nonParsableInputText = { from: null, to: null } as IDateRangeDisplayText;
        this.selectionState = this.selectionState === "allDay" ? "allDay" :
            (this.props.value?.from === null || this.props.value?.to !== null ? "from" : "to");
        this.isDefaultFlow = true;
    }

    @State.action.bound
    private focusInput() {
        this.inputIsFocused = true;
        this.refreshInternalState();
    }

    @State.action.bound
    private blurInput() {
        this.inputIsFocused = false;
        this.resetInternalState();
    }

    @State.action.bound
    private openMenu() {
        this.menuIsOpen = true;
    }

    @State.action.bound
    private closeMenu() {
        this.menuIsOpen = false;
    }

    @State.action.bound
    private setFromDateValue(newValue: LocalDate) {
        this.internalFromValue = newValue;
        if (this.isDefaultFlow) {
            this.selectionState = "to";
        } else {
            this.commitValueAndClose();
        }
    }

    @State.action.bound
    private setToDateValue(newValue: LocalDate) {
        this.internalToValue = newValue;
        this.commitValueAndClose();
    }
    @State.action.bound
    private setCurrentDateValueToMoment(newValue?: moment.Moment) {
        const localDateValue = LocalDate.createFromMoment(newValue);
        this.internalFromValue = localDateValue;
        this.internalToValue = localDateValue;
        this.commitValueAndClose();
    }

    @State.action.bound
    private setAllDayValue(newValue: LocalDate) {
        this.internalFromValue = newValue;
        this.internalToValue = newValue;
        this.commitValueAndClose();
    }

    @State.action.bound
    private SetDateRangeValueFromText() {
        if (this.selectionState === "allDay") {
            this.internalToValue = this.internalFromValue;
        }
        this.commitValueAndClose();
    }

    private isInvalidRange() {
        return this.internalToValue && !this.internalToValue.isEmpty()
            && this.internalFromValue && !this.internalFromValue.isEmpty()
            && this.internalFromValue.toUnix() > this.internalToValue.toUnix();
    }

    @State.action.bound
    private commitValueAndClose() {
        this.commitValue();
        this.closeMenu();
    }

    @State.action.bound
    private commitValue() {
        if (this.isInvalidRange()) {
            this.dialogService.ok(
                this.staticResources.common.error,
                this.staticResources.dateRangePicker.messages.validToIsBeforeValidFromError
            );
        } else {
            this.props.onChange(this.internalValue);
        }
    }

    @State.action.bound
    private clearValueAndClose() {
        this.props.onChange(null);
        this.closeMenu();
    }

    @State.action.bound
    private setTextValue(text: string, action?: inputChangeActionType) {
        if (action === "input-change") {
            this.nonParsableInputText = { from: null, to: null } as IDateRangeDisplayText;
            this.selectionState = "allDay";

            const formatsToParse = this.dateTimeFormatProvider.getDateFormatsToParse();

            // eslint-disable-next-line no-useless-escape
            const filteredTextArray = text.replace(/([^\d\.\,\-]+)/g, "").replace(/\,/g, ".").replace(/\-/, "|").replace(/\-/g, "").replace(/\|/, "-").split("-");
            const parseableTextArray = filteredTextArray.map(d => d.replace(/\./g, ""));

            const fromDateParsedValue = parseableTextArray[0] === "" ? null : moment(parseableTextArray[0], formatsToParse, true);
            const isFromDateValid = fromDateParsedValue === null || fromDateParsedValue.isValid();

            if (isFromDateValid === true) {
                const localFromDate = LocalDate.createFromMoment(fromDateParsedValue);

                if (this.textEditState === "enter" ||
                    (this.displayValue.from !== null && !this.displayValue.from.equals(localFromDate)) ||
                    (this.displayValue.from === null && localFromDate !== null)) {
                    this.internalFromValue = localFromDate;

                } else {
                    this.nonParsableInputText.from = filteredTextArray[0];
                }

                if (filteredTextArray.length > 1) {
                    this.selectionState = "to";

                    const toDateParsedValue = parseableTextArray[1] === "" ? null : moment(parseableTextArray[1], formatsToParse, true);
                    const isToDateValid = toDateParsedValue === null || toDateParsedValue.isValid();

                    if (isToDateValid === true) {
                        const localToDate = LocalDate.createFromMoment(toDateParsedValue);

                        if (this.textEditState === "enter" ||
                            (this.displayValue.to !== null && !this.displayValue.to.equals(localToDate)) ||
                            (this.displayValue.to === null && localToDate !== null)) {
                            this.internalToValue = localToDate;

                            if (!isNullOrUndefined(localToDate)) {
                                this.commitValueAndClose();
                            }
                        } else {
                            this.nonParsableInputText.to = filteredTextArray[1];
                        }
                    } else {
                        this.nonParsableInputText.to = filteredTextArray[1];
                    }
                }
            } else {
                this.nonParsableInputText.from = filteredTextArray[0];
            }
        }
    }

    @State.action.bound
    private keyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Backspace" || e.key === "Delete") {
            this.textEditState = "clear";
        } else {
            this.textEditState = "enter";
        }
        if (e.key === "Enter") {
            e.preventDefault();
            this.SetDateRangeValueFromText();
        }
    }
    @State.action.bound
    private setCurrentDateTime() {
        this.setCurrentDateValueToMoment(DateTimeService.now());
    }

    private dummyOptionIsSelected() {
        return true;
    }

    @State.bound
    private selectBoxChangeHandler(newValue: any) {
        if (newValue === valueOnClear) {
            this.clearValueAndClose();
        }
    }

    @State.bound
    private textValueGetter() {
        return this.selectBoxText;
    }

    @State.action.bound
    private pickingFromDate() {
        this.isDefaultFlow = this.selectionState === "allDay" || isNullOrUndefined(this.selectionState) ? true : false;
        this.selectionState = "from";
    }

    @State.action.bound
    private pickingToDate() {
        this.isDefaultFlow = false;
        this.selectionState = "to";
    }

    @State.action.bound
    private pickingAllDay() {
        this.isDefaultFlow = false;
        this.selectionState = "allDay";
    }

    public render() {
        if (this.props.isReadOnly) {
            return (
                <TextBox
                    className={this.props.className}
                    style={this.props.style}
                    label={this.props.label}
                    id={this.props.id}
                    automationId={this.props.automationId}
                    disabled={this.props.disabled}
                    name={this.props.name}
                    tabIndex={this.props.tabIndex}
                    value={this.menuText}
                    isReadOnly
                />
            );
        }
        return (
            <SelectBox
                {...this.props}
                onFocus={this.focusInput}
                onBlur={this.blurInput}
                onChange={this.selectBoxChangeHandler}
                valueOnClear={valueOnClear}
                value={this.props.value ?? null}
                items={dummyItems}
                onKeyDown={this.keyDown}
                onRenderMenu={this.renderMenu}
                menuIsOpen={this.menuIsOpen}
                onMenuOpen={this.openMenu}
                onMenuClose={this.closeMenu}
                getOptionText={this.textValueGetter}
                inputValue={this.selectBoxText}
                onInputChange={this.setTextValue}
                isOptionSelected={this.dummyOptionIsSelected}
                dropdownIconName="none"
                ref={this.props.forwardedRef}
            >
                <Icon
                    iconName="clock"
                    stopClickPropagation
                    size="compact"
                    onClick={this.setCurrentDateTime}
                    visualStyle="secondary"
                    style={{ marginRight: 8, marginLeft: 4 }}
                    automationId="_nowIcon"
                />
            </SelectBox>
        );
    }

    private getDateRange(interval: IntervalOption): LocalDateRange {
        switch (interval) {
            case IntervalOption.Today:
                return new LocalDateRange(LocalDate.today(), LocalDate.today());
            case IntervalOption.Yesterday:
                return new LocalDateRange(LocalDate.createFromMoment(DateTimeService.now().add(-1, "days")), LocalDate.createFromMoment(DateTimeService.now().add(-1, "days")));
            case IntervalOption.TodayAndYesterday:
                return new LocalDateRange(LocalDate.createFromMoment(DateTimeService.now().add(-1, "days")), LocalDate.today());
            case IntervalOption.Last7Days:
                return new LocalDateRange(LocalDate.createFromMoment(DateTimeService.now().add(-7, "days")), LocalDate.today());
            case IntervalOption.Last30Days:
                return new LocalDateRange(LocalDate.createFromMoment(DateTimeService.now().add(-1, "months")), LocalDate.today());
            case IntervalOption.ThisMonth:
                return new LocalDateRange(LocalDate.createFromMoment(DateTimeService.now().startOf("month")), LocalDate.createFromMoment(DateTimeService.now().endOf("month")));
            case IntervalOption.LastMonth:
                return new LocalDateRange(LocalDate.createFromMoment(DateTimeService.now().add(-1, "months").startOf("month")), LocalDate.createFromMoment(DateTimeService.now().add(-1, "months").endOf("month")));
            case IntervalOption.ThisWeek:
                return new LocalDateRange(LocalDate.createFromMoment(DateTimeService.now().startOf("week")), LocalDate.createFromMoment(DateTimeService.now().endOf("week")));
            case IntervalOption.LastWeek:
                return new LocalDateRange(LocalDate.createFromMoment(DateTimeService.now().add(-1, "week").startOf("week")), LocalDate.createFromMoment(DateTimeService.now().add(-1, "week").endOf("week")));
            case IntervalOption.ThisYear:
                return new LocalDateRange(LocalDate.createFromMoment(DateTimeService.now().startOf("year")), LocalDate.createFromMoment(DateTimeService.now().endOf("year")));
            case IntervalOption.LastYear:
                return new LocalDateRange(LocalDate.createFromMoment(DateTimeService.now().add(-1, "year")), LocalDate.today());
            case IntervalOption.Last5Years:
                return new LocalDateRange(LocalDate.createFromMoment(DateTimeService.now().add(-5, "year")), LocalDate.today());
            default:
                throw new Error("Unhandled IntervalOption value in DateRangePicker.getClickHandler");
        }
    }

    @State.bound
    private clickHandlerFactory(option: IntervalOption) {
        return () => {
            const dateRange = this.getDateRange(option);
            State.runInAction(() => {
                this.internalFromValue = dateRange.from;
                this.internalToValue = dateRange.to;
            });
            this.commitValueAndClose();
        };
    }

    private renderMenu = State.observer((menuProps: any) => {

        const maxWidthStyles = this.props.maxMenuWidth && {
            style: {
                width: this.props.maxMenuWidth,
                maxWidth: "max-content"
            }
        } || undefined;

        const props = {
            ...menuProps,
            innerProps: {
                ...menuProps.innerProps,
                ...maxWidthStyles
            }
        };

        if (this.props.visualMode === "dark") {
            props.className += " __dark-mode";
        }

        return (
            <>
                <components.Menu {...props}>
                    <div className={Styles.menuValueHeader}>{this.menuText}</div>
                    <div className={Styles.calendarSelectBoxMenuContainer}>
                        <div>
                            <div style={{ display: "flex" }}>
                                <Button
                                    text={this.toolkitLocalizationService.staticResources.dateRangePicker.labels.allDay}
                                    visualStyle={this.selectionState === "allDay" ? "primary" : "standard"}
                                    fluid
                                    size="compact"
                                    onClick={this.pickingAllDay}
                                    automationId="__allDay"
                                    visualMode={this.props.visualMode}
                                />
                            </div>
                            <div style={{ display: "flex" }}>
                                <Button
                                    text={this.toolkitLocalizationService.staticResources.dateRangePicker.labels.from}
                                    visualStyle={this.selectionState === "from" ? "primary" : "standard"}
                                    fluid
                                    size="compact"
                                    onClick={this.pickingFromDate}
                                    automationId="__from"
                                    visualMode={this.props.visualMode}
                                />
                                <Button
                                    text={this.toolkitLocalizationService.staticResources.dateRangePicker.labels.to}
                                    visualStyle={this.selectionState === "to" ? "primary" : "standard"}
                                    fluid
                                    size="compact"
                                    onClick={this.pickingToDate}
                                    automationId="__to"
                                    disabled={isNullOrUndefined(this.internalFromValue)}
                                    visualMode={this.props.visualMode}
                                />
                            </div>
                            {this.renderCalendar()}
                        </div>
                        <SideMenu
                            visualMode={this.props.visualMode === "dark" ? "dark" : "light"}
                            className={Styles.presetMenu}
                        >
                            {this.props.intervalOptions.map((option, index) => {
                                return <SideMenuItem
                                    key={index}
                                    text={this.toolkitLocalizationService.localizeEnum(IntervalOption[option], "IntervalOption").Name}
                                    onClick={this.clickHandlerFactory(option)}
                                    automationId={IntervalOption[option]}
                                />;
                            })}
                        </SideMenu>
                    </div>
                </components.Menu>
            </>
        );
    });

    private renderCalendar() {
        switch (this.selectionState) {
            case "from":
                return (
                    <Calendar
                        key="from"
                        value={this.internalFromValue}
                        onChange={this.setFromDateValue}
                        visualMode={this.props.visualMode}
                    />
                );
            case "to":
                return (
                    <Calendar
                        key="to"
                        value={this.internalToValue}
                        onChange={this.setToDateValue}
                        visualMode={this.props.visualMode}
                    />
                );
            case "allDay": {
                return (
                    <Calendar
                        key="allDay"
                        value={this.internalFromValue}
                        onChange={this.setAllDayValue}
                        visualMode={this.props.visualMode}
                    />
                );
            }
        }
        return null;
    }
}

export default connect(
    DateRangePicker,
    new DependencyAdapter<IDateRangePickerProps, IDateRangePickerDependencies>(c => ({
        dateTimeFormatProvider: c.resolve("IDateTimeFormatProvider"),
        toolkitLocalizationService: c.resolve("IToolkitLocalizationService"),
        dialogService: c.resolve("IDialogService"),
    })),
    new ReadOnlyContextAdapter((props, isReadOnly) => ({ isReadOnly: props.isReadOnly || isReadOnly }))
);