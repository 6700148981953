import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";

interface IDiagnosisRoleSelectBoxDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IDiagnosisRoleSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IDiagnosisRoleSelectBoxDependencies;
}


@State.observer
class DiagnosisRoleSelectBox extends React.Component<IDiagnosisRoleSelectBoxProps> {
    private get dependencies() { return this.props._dependencies; }
    private get extensibleEnumStore() { return this.dependencies.careReferenceDataStore.diagnosisRole; }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                maxMenuWidth={commonMenuMaxWidth}
                {...this.props}
                clearable={false}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    DiagnosisRoleSelectBox,
    new DependencyAdapter<IDiagnosisRoleSelectBoxProps, IDiagnosisRoleSelectBoxDependencies>( (containerService) => {
        return {
            careReferenceDataStore: containerService.resolve("CareReferenceDataStore")
        };
    })
);
