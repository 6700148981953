import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StaticHunSocialSecurityReportingResources from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/StaticResources/StaticHunSocialSecurityReportingResources";

interface IPerformanceReportListToolbarProps {
    onNavigateToPerformanceReportScorePlans: () => void;
    onAddPerformanceReportAsync: () => Promise<void>;
    isPerformanceReportSelected: boolean;
}


const PerformanceReportListToolbar: React.FC<IPerformanceReportListToolbarProps> = (props) => {
    return (
        <>
            {!props.isPerformanceReportSelected &&
                <Ui.Button
                    onClick={props.onNavigateToPerformanceReportScorePlans}
                    automationId="navigateToPerformanceReportScorePlansButton"
                    text={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.PerformanceReportScorePlans}
                    iconName="plan_score"
                />
            }
            <Ui.AddButton
                onClickAsync={props.onAddPerformanceReportAsync}
                automationId="addButton"
            />
        </>
    );
};

export default State.observer(PerformanceReportListToolbar);
