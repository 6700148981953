import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export default class ExtensionStore {
    private readonly extensionStores = new Map<string, any>();

    public getOrAddExtensionStore(extensionStoreName: string, extensionStoreFactory: () => any) {
        let existing = this.extensionStores.get(extensionStoreName);
        if (isNullOrUndefined(existing)) {
            existing = extensionStoreFactory();
            this.extensionStores.set(extensionStoreName, existing);
        }
        return existing;
    }
}