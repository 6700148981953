import StatusChangeReasonTypeId from "@Primitives/StatusChangeReasonTypeId.g";
import { ReactNode } from "react";
import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";

export interface IStatusChangeReasonDialogParams extends IModalParams {
    statusChangeReasonTypeId: StatusChangeReasonTypeId;
    dialogBodyRenderer?: () => React.ReactNode;
    additionalComponent?: ReactNode;
    additionalTitle?: string;
}

export default class StatusChangeReasonDialogParams implements IStatusChangeReasonDialogParams {

    public static type = "StatusChangeReasonDialog";
    public get type() { return StatusChangeReasonDialogParams.type; }

    constructor(
        public readonly statusChangeReasonTypeId: StatusChangeReasonTypeId,
        public readonly dialogBodyRenderer?: () => React.ReactNode,
        public readonly additionalComponent?: ReactNode,
        public readonly additionalTitle?: string,
    ) {}
}