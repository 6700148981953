import React from "react";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import FavoriteDataGridColumn from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/FavoriteDataGridColumn";
import ComplexSearchFilterStore from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchFilterStore";
import { DataGridLoadType, IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import IColumnDescriptor from "./IColumnDescriptor";

interface IComplexSearchSingleSelectProps<TRecord> {
    dataSource: IPagedItems<TRecord>;
    filterStore: ComplexSearchFilterStore;
    onRowClick: (row: TRecord) => void;
    automationId: string;
    groupName: string;

    codeGetter: string;
    nameGetter: string;
    complexSearchRenderNameCell: (value: any, row: TRecord) => React.ReactNode;
    onComplexSearchClose: () => void;

    paging: IPagingState;
    ordering: IOrderingState;
    onChangeAsync: (type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState | IOrderingState[], filter: any, columns: IDataGridColumnProps[]) => Promise<void>;
    isLoading: boolean;

    showFavoritesColumn: boolean;
    isFavorite: (value: TRecord) => boolean;
    setFavorite: (value: TRecord) => void;
    columnDescriptors?: IColumnDescriptor[];
}

export default class ComplexSearchSingleSelect<TRecord> extends React.Component<IComplexSearchSingleSelectProps<TRecord>> {
    public render() {
        return (
            <>
                <Ui.DataGrid
                    dataSource={this.props.dataSource}
                    onRowClick={this.props.onRowClick}
                    rowHeight="34px"
                    paging={this.props.paging}
                    onChangeAsync={this.props.onChangeAsync}
                    changeOnMount
                    automationId={this.props.automationId + "__component"}
                    key={this.props.groupName}
                    fixedLayout
                    fixedHeight="100%"
                    showLoadingIndicator
                    isLoading={this.props.isLoading}
                    hasHeader={false}
                    hasBackButton={true}
                    backButtonText={StaticWebAppResources.Common.Button.Cancel}
                    onBack={this.props.onComplexSearchClose}
                >
                    {this.props.codeGetter &&
                        <DataGridColumn
                            id="Code"
                            dataGetter={this.props.codeGetter}
                            width={"100px"}
                            cellTextAlign="center"
                        />
                    }
                    <DataGridColumn
                        id="Name"
                        dataGetter={this.props.nameGetter}
                        onRenderCellValue={this.props.complexSearchRenderNameCell}
                    />
                    {this.props.columnDescriptors && this.props.columnDescriptors.map(c => {
                        return (
                            <DataGridColumn
                                key={c.columnName}
                                dataGetter={c.columnName}
                                onRenderCellValue={c.valueRenderer}
                            />
                        );
                    })
                    }
                    {
                        this.props.showFavoritesColumn &&
                        <FavoriteDataGridColumn
                            isFavorite={this.props.isFavorite}
                            onFavoriteIconClick={this.props.setFavorite}
                        />
                    }
                </Ui.DataGrid>
            </>

        );
    }
}
