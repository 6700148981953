import Di from "@Di";
import React from "react";
import { IAdmitPatientAndCareActivityBaseDataScreenProps } from "./AdmitPatientAndCareActivityBaseDataScreen";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import AdmitPatientAndCareActivityBaseDataScreenApiAdapter from "./AdmitPatientAndCareActivityBaseDataScreenApiAdapter";
import LockAcquirerOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockAcquirerOperationInfo";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import DateTimeFormFieldData from "@Toolkit/FormEngine/Model/Data/DateTimeFormFieldData";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import AdmitPatientAction from "@HisPlatform/Packages/Care/FrontendActions/AdmitPatientAction.g";
import ShowCareActivityBaseDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityBaseDataScreenAction.g";
import ShowEditPatientAdmissionDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEditPatientAdmissionDataScreenAction.g";
import ShowEhrCareActivityBaseDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEhrCareActivityBaseDataScreenAction.g";
import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import ShowReadOnlyCareActivityBaseDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyCareActivityBaseDataScreenAction.g";
import SaveCareActivityBaseDataAction from "@HisPlatform/Packages/Care/FrontendActions/SaveCareActivityBaseDataAction.g";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import HisPermissionScopes from "@HisPlatform/Common/FrontendActions/HisPermissionScopes";
import IFormLogicRegistry, { IFormLogicExecutor } from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import IOperationResult from "@Toolkit/CommonWeb/ApiAdapter/IOperationResult";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import IFormExtensionRegistry from "@PluginInterface/FormExtension/IFormExtensionRegistry";
import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import _ from "@HisPlatform/Common/Lodash";
import IAdmitPatientAndCareActivityBaseDataScreenInfoBoxExtension from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IAdmitPatientAndCareActivityBaseDataScreenInfoBoxExtension";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";
import { ChangeAction } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";

@Di.injectable()
export default class AdmitPatientAndCareActivityBaseDataScreenStore extends EditorScreenPanelStoreBase<IAdmitPatientAndCareActivityBaseDataScreenProps> implements ILoadablePanelStore {
    @State.observable.ref public form: IForm = null;
    @State.observable.ref private infoBoxExtensionData: { [key: string]: any } = null;
    private formEngineDefinition: IFormDefinition;
    private formLogicExecutor: IFormLogicExecutor = null;

    @State.computed
    public get infoBoxExtensionProps(): IAdmitPatientAndCareActivityBaseDataScreenInfoBoxExtension {
        return {
            extensionData: this.infoBoxExtensionData
        } as IAdmitPatientAndCareActivityBaseDataScreenInfoBoxExtension;
    }

    @State.computed
    public get editCareActivityBaseDataAction() {
        if (!this.form) {
            return ActionDescriptor.fromAction(new SaveCareActivityBaseDataAction(), HisPermissionScopes.pointOfCare(this.props._pointOfCareId?.value));
        }

        const pointOfCareIdRawValue = getField<ReferencedEntityFormFieldData>(this.form?.data.Content, "PointOfCareId")?.value;
        return ActionDescriptor.fromAction(new SaveCareActivityBaseDataAction(), HisPermissionScopes.pointOfCare(pointOfCareIdRawValue?.toString()));
    }

    @State.computed
    public get admitPatientAction() {
        if (!this.form) {
            return ActionDescriptor.fromAction(new AdmitPatientAction(), HisPermissionScopes.pointOfCare(this.props._pointOfCareId?.value));
        }

        const pointOfCareIdRawValue = getField<ReferencedEntityFormFieldData>(this.form?.data.Content, "PointOfCareId")?.value;
        return ActionDescriptor.fromAction(new AdmitPatientAction(), HisPermissionScopes.pointOfCare(pointOfCareIdRawValue?.toString()));
    }

    @State.computed
    public get vIsReadOnly() {
        return this.canAcquireLock
            || (this.isCareActivityBaseDataScreen && !this.hasPermissionForCareActivityBaseDataEdit)
            || (this.isEditPatientAdmissionDataScreen && !this.hasPermissionForAdmitPatient)
            || (this.isNewAdmitPatientScreen && !this.hasPermissionForAdmitPatient)
            || this.isReadOnlyCareActivityBaseDataScreen
            || this.isEhrCareActivityBaseDataScreen;
    }

    @State.computed
    public get showScreenAction() {
        return this.props.action;
    }

    @State.computed
    public get hasPermissionForCareActivityBaseDataEdit() {
        return this.authorizationService.hasPermissionForDescriptor(this.editCareActivityBaseDataAction);
    }

    @State.computed
    public get hasPermissionForAdmitPatient() {
        return this.authorizationService.hasPermissionForDescriptor(this.admitPatientAction);
    }

    @State.computed
    public get isCareActivityBaseDataScreen() {
        return this.props.action instanceof ShowCareActivityBaseDataScreenAction;
    }

    @State.computed
    public get isNewAdmitPatientScreen() {
        return this.props.action instanceof ShowNewAdmitPatientScreenAction;
    }

    @State.computed
    public get isEditPatientAdmissionDataScreen() {
        return this.props.action instanceof ShowEditPatientAdmissionDataScreenAction;
    }

    @State.computed
    public get isReadOnlyCareActivityBaseDataScreen() {
        return this.props.action instanceof ShowReadOnlyCareActivityBaseDataScreenAction;
    }

    @State.computed
    public get isEhrCareActivityBaseDataScreen() {
        return this.props.action instanceof ShowEhrCareActivityBaseDataScreenAction;
    }

    @State.computed
    public get formDefinitionId() {
        return this.form?.definitionId;
    }

    @State.computed
    protected get contentToDirtyCheck() {
        return [this.form?.data.Content];
    }

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("ApplicationContext") private applicationContext: ApplicationContext,
        @Di.inject("AdmitPatientAndCareActivityBaseDataScreenApiAdapter") private admitPatientAndCareActivityBaseDataScreenApiAdapter: AdmitPatientAndCareActivityBaseDataScreenApiAdapter,
        @Di.inject("OrganizationReferenceDataStore") private organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("AuthorizationService") private authorizationService: AuthorizationService,
        @Di.inject("IFormLogicRegistry") private formLogicRegistry: IFormLogicRegistry,
        @Di.inject("IFormEngineReferenceDataStore") private formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("IContainerService") private containerService: IContainerService,
        @Di.inject("IFormExtensionRegistry") private formExtensionRegistry: IFormExtensionRegistry) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    @State.bound
    public async loadCoreAsync() {
        const orgId = this.applicationContext.contextualOrganizationUnitId;

        if (this.props.action instanceof ShowNewAdmitPatientScreenAction) {
            const response = await this.admitPatientAndCareActivityBaseDataScreenApiAdapter.getNewAdmitPatientScreenDataAsync(
                this.props.action.patientId,
                this.props.action.appointmentScheduleEntryId,
                this.props.action.serviceRequestId,
                orgId);

            this.setForm(response.result.form);
            this.setInfoBoxExtensionData(response.result.extensionData);
        } else {
            const requestLock = (this.isCareActivityBaseDataScreen && this.hasPermissionForCareActivityBaseDataEdit)
                || (this.isEditPatientAdmissionDataScreen && this.hasPermissionForAdmitPatient);
            let response: IOperationResult<{ form: IForm, extensionData: { [key: string]: any; } }>;
            if (this.isEditPatientAdmissionDataScreen) {
                response = await this.admitPatientAndCareActivityBaseDataScreenApiAdapter.getAdmitPatientAScreenDataAsync(this.props.action.careActivityId, requestLock);
            } else {
                response = await this.admitPatientAndCareActivityBaseDataScreenApiAdapter.getCareActivityBaseDataScreenDataAsync(this.props.action.careActivityId, requestLock);
            }
            if (response.operationInfo instanceof LockAcquirerOperationInfo) {
                State.runInAction(() => this.lockInfo = (response.operationInfo as LockAcquirerOperationInfo).lockInfo);
            }

            if (!this.isReadOnlyCareActivityBaseDataScreen && !this.isEhrCareActivityBaseDataScreen) {
                this.vSetValidationResults(response.result.form.validationResults);
            }

            this.setForm(response.result.form);
            this.setInfoBoxExtensionData(response.result.extensionData);
        }

        this.formEngineDefinition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(this.form.definitionId);

        const loadedSignals: string[] = [];
        const formExtensions = this.formExtensionRegistry.resolveAll(this.containerService, "AdmitPatientAndCareActivityBaseDataScreen");
        formExtensions.forEach(i => {
            const items = (i.invokeCallback("GetLoadedSignals", {}) as string[]);
            loadedSignals.push(..._.flatten(items));
        });

        this.formLogicExecutor = this.formLogicRegistry.getExecutorForForm(this.form, this.showScreenAction, this.formEngineDefinition.name, this.formEngineDefinition);
        await this.formLogicExecutor.formLoadedAsync();

        return {
            loadedSignals: loadedSignals
        };
    }

    @State.computed
    public get actionForSaveButton() {
        return (this.isNewAdmitPatientScreen || this.isEditPatientAdmissionDataScreen) ? this.admitPatientAction : this.editCareActivityBaseDataAction;
    }

    @State.bound
    public async onSaveAsync(): Promise<any> {
        if (this.isNewAdmitPatientScreen) {
            return await this.onAdmitAsync();
        } else {
            return await this.onSaveBaseDataAsync();
        }
    }

    @State.bound
    public async onSaveBaseDataAsync(): Promise<any> {
        const saveResult =
            await this.handleSaveResultAsync<IForm>(() => this.admitPatientAndCareActivityBaseDataScreenApiAdapter.updateCareActivityBaseDataAsync(this.form, true, this.lockInfo));

        if (saveResult.isPersisted) {
            State.runInAction(() => this.lockInfo = null);

            // HACK - need to remove later
            if (this.props.action.displayMode != ScreenDisplayMode.WithoutSideBar) {
                this.props._screenState.savedExisting();
            }
        }

        return saveResult.isPersisted;
    }

    @State.bound
    public async onAdmitAsync(): Promise<any> {
        const saveResult = await this.handleSaveResultAsync(
            () => this.admitPatientAndCareActivityBaseDataScreenApiAdapter.admitPatientAsync(this.form));

        if (saveResult.isPersisted) {
            this.props._screenState.savedNew(saveResult.result.id);
        }

        return saveResult.isPersisted;
    }

    @State.bound
    public async onDeleteAsync(): Promise<any> {

        if (this.props.action instanceof ShowNewAdmitPatientScreenAction) {
            return;
        }

        const careActivityId = this.props.action.careActivityId;
        const canDeleteAnswer = await this.dialogService.yesNo(StaticCareResources.Common.Dialog.ConfirmationTitle, this.renderDeleteConfirmation());

        if (canDeleteAnswer.resultCode === DialogResultCode.Yes) {
            const saveResult =
                await this.handleSaveResultAsync(() => this.admitPatientAndCareActivityBaseDataScreenApiAdapter.deleteAdmissionAsync(careActivityId, null, ""));

            if (saveResult.isPersisted) {
                this.props._screenState.cancelled();
            }
        }
    }

    @State.bound
    public onCancelAsync(): Promise<any> {
        this.props._screenState.cancelled();
        return Promise.resolve();
    }

    @State.bound
    public async onValidateAsync(): Promise<any> {
        if (!this.form || this.isReadOnlyCareActivityBaseDataScreen || this.isEhrCareActivityBaseDataScreen) {
            return;
        }

        let formValidationResult: IForm;
        if (this.isNewAdmitPatientScreen) {
            const operationResult = await this.admitPatientAndCareActivityBaseDataScreenApiAdapter.admitPatientAsync(this.form, true);
            formValidationResult = operationResult.result;
        } else {
            const operationResult = await this.admitPatientAndCareActivityBaseDataScreenApiAdapter.updateCareActivityBaseDataAsync(this.form, false, this.lockInfo, true);
            formValidationResult = operationResult.result;
        }

        return formValidationResult.validationResults;
    }

    public readonly onDataChange = this.async(async (propertyIdentifier: string, value: any, action: ChangeAction) => {
        this.formLogicExecutor.fieldChangedAsync(action, propertyIdentifier, value);

        if (!(this.props.action instanceof ShowNewAdmitPatientScreenAction))
            return;

        const showNewAdmitPatientScreenAction = this.props.action;

        if (propertyIdentifier !== "PointOfCareId") {
            return;
        }

        if (isNullOrUndefined(value)) {
            return;
        }

        const pointOfCareId = new PointOfCareId(value.toString());

        const response =
            await this.admitPatientAndCareActivityBaseDataScreenApiAdapter.getAdmitPatientAndCareActivityBaseDataScreenFormDefinitionAsync(pointOfCareId);

        //TODO Ha változott a pointOfCare Id akkor le kell resetelni az összes olyan mezőt, ahol filterként szerepel (pl. orvosok)

        if (response.result?.value === this.form.definitionId.value) {
            return;
        }

        const resetResponse = await this.admitPatientAndCareActivityBaseDataScreenApiAdapter.resetAdmitPatientScreenDataAsync(
            showNewAdmitPatientScreenAction.patientId,
            pointOfCareId,
            showNewAdmitPatientScreenAction.appointmentScheduleEntryId,
            showNewAdmitPatientScreenAction.serviceRequestId
        );

        this.setForm(resetResponse.result);
        await this.formLogicExecutor.formLoadedAsync();

    });

    @State.action.bound
    private setForm(form: IForm) {
        this.form = form;
    }
    
    @State.action.bound
    private setInfoBoxExtensionData(value: { [key: string]: string }): void {
        this.infoBoxExtensionData = value;
    }

    private renderDeleteConfirmation() {
        const pointOfCareRawValue = getField<ReferencedEntityFormFieldData>(this.form.data.Content, "PointOfCareId")?.value;
        const pointOfCareId = isNullOrUndefined(pointOfCareRawValue) ? null : new PointOfCareId(pointOfCareRawValue.toString());
        const pointOfCare = pointOfCareId && this.organizationReferenceDataStore.allPointsOfCareMap.get(pointOfCareId);
        const pocString = pointOfCare && `${pointOfCare.shorthand} - ${pointOfCare.name}`;

        const careIdentifier = getField<TextFormFieldData>(this.form.data.Content, "CareActivityIdentifier")?.value;
        const admittedAt = getField<DateTimeFormFieldData>(this.form.data.Content, "AdmittedAt")?.value;

        return (
            <>
                <p>{StaticCareResources.PatientAdmission.Dialog.DeleteConfirmationData}</p>
                {careIdentifier && (`${StaticCareResources.OutpatientWorkflow.CareActivityBaseDataStep.CareIdentifier}: ${careIdentifier}`)}
                {careIdentifier && (<br />)}
                {admittedAt && (`${StaticCareResources.OutpatientWorkflow.CareActivityBaseDataStep.AdmittedAt}: ${this.localizationService.localizeDateTime(admittedAt)}`)}
                {admittedAt && (<br />)}
                {pointOfCare && (`${StaticCareResources.OutpatientWorkflow.CareActivityBaseDataStep.TargetPointOfCare}: ${pocString}`)}
                {pointOfCare && (<br />)}
                <p>{StaticCareResources.PatientAdmission.Dialog.DeleteConfirmationWarning}</p>
                <p>{StaticCareResources.PatientAdmission.Dialog.DeleteConfirmationMessage}</p>
            </>
        );
    }

    protected saveFunction: () => Promise<boolean> = this.onSaveAsync;
}