import React from "react";
import { LoadingIndicator } from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LoadingContext from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingContext";
import LoadingContextStore from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingContextStore";

interface ILoadingBoundaryProps {
    children?: React.ReactNode;
    onRender?: () => React.ReactNode;
    onRenderLoader?: () => React.ReactNode;
}

@State.observer
export default class LoadingBoundary extends React.Component<ILoadingBoundaryProps> {
    @State.observable public loadingContextStore: LoadingContextStore = new LoadingContextStore();

    public static defaultProps: Partial<ILoadingBoundaryProps> = {
        onRenderLoader: () => <LoadingIndicator mode="parent" />
    };

    @State.computed private get isFunctionChild() {
        return typeof this.props.children === "function";
    }

    public render() {
        return (
            <LoadingContext.Provider value={this.loadingContextStore}>
                {this.renderContent()}
                {this.loadingContextStore?.isLoading && this.props.onRenderLoader()}
            </LoadingContext.Provider>
        );
    }

    private renderContent() {
        if (this.props.onRender) {
            return this.props.onRender();
        }
        return this.isFunctionChild ? (this.props.children as any)() : this.props.children;
    }
}

export function withLoadingBoundary<TProps>(Component: React.ComponentClass<TProps> | React.FC<TProps>) {
    return (props: TProps) => (
        <LoadingBoundary>
            <Component {...props} />
        </LoadingBoundary>
    );
}
