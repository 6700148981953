import { visualStyleType } from "@CommonControls/Button";

enum ActionVisualStyle {
    /// <summary>
    /// The action is presented in the default style.
    /// </summary>
    Default,
    /// <summary>
    /// The action is presented in a highlighted style, suggesting a primary user goal.
    /// </summary>
    Primary,
    /// <summary>
    /// The action is presented with a positive visual style, eg. a green button for an Accept action.
    /// </summary>
    Positive,
    /// <summary>
    /// The action is presented with a negative visual style, eg. a red button for a Delete action.
    /// </summary>
    Negative,
}

export default ActionVisualStyle;

export function actionVisualStyleToButton(style: ActionVisualStyle): visualStyleType {
    switch (style) {            
        case ActionVisualStyle.Primary:
            return "primary";
        case ActionVisualStyle.Positive:
            return "positive";
        case ActionVisualStyle.Negative:
            return "negative";
        default:
            return "standard";
    }
}