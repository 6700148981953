import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import Di from "@Di";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";
import DateFormFieldData from "@Toolkit/FormEngine/Model/Data/DateFormFieldData";
import ReferencedEntityArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityArrayFormFieldData";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import ConditionId from "@Primitives/ConditionId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import BooleanFormFieldData from "@Toolkit/FormEngine/Model/Data/BooleanFormFieldData";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import IForm from "@Toolkit/FormEngine/Model/IForm";

@Di.injectable()
class EpisodeOfCareScreenFormBaseFormLogic implements IFormLogic {

    constructor(@Di.inject("CareReferenceDataStore") private careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("IDateTimeFormatProvider") private dateTimeFormatProvider: IDateTimeFormatProvider) { }

    @State.bound
    public async executeAsync(form: IForm): Promise<void> {        
        const formData = form.data.Content;
        const isFirstRunField = getField<BooleanFormFieldData>(formData, "IsFirstRun");
        const episodeOfCareIdField = getField<ReferencedEntityFormFieldData>(formData, "EpisodeOfCareId");

        if (isFirstRunField.value || episodeOfCareIdField?.value) {
            return;
        }

        const nameField = getField<TextFormFieldData>(formData, "Name");
        if (!isNullOrUndefined(nameField) && isNullOrUndefined(nameField?.value)) {
            const startField = getField<DateFormFieldData>(formData, "Start");
            const diagnosesField = getField<ReferencedEntityArrayFormFieldData>(formData, "Diagnoses");
            if ((!isNullOrUndefined(nameField) && isNullOrUndefined(nameField?.value)) &&
                (!isNullOrUndefined(diagnosesField) && isNullOrUndefined(diagnosesField?.value))) {
                return;
            }

            if (startField.value && diagnosesField?.value?.length > 0) {
                const item = await this.careReferenceDataStore.condition.getOrLoadAsync({ id: new ConditionId(diagnosesField.value[0].toString()), validOn: LocalDate.today() });
                const formattedDate = startField.value.toUtcDayStartMoment().format(this.dateTimeFormatProvider.getDateFormat());

                State.runInAction(() => {
                    nameField.value = item.name + " (" + formattedDate + ")";
                    isFirstRunField.value = true;
                });
            }
        }
    }
}

export default EpisodeOfCareScreenFormBaseFormLogic;
