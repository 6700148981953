import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import ScalarProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/ScalarProperty";
import BoolProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/BoolProperty";
import NumericProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/NumericProperty";
import NumericRangeProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/NumericRangeProperty";
import { BoolPropertyEditor } from "@HisPlatform/BoundedContexts/Configuration/Components/Controls/PropertyGroupEditableColumn/BoolPropertyEditor";
import { ScalarPropertyEditor } from "@HisPlatform/BoundedContexts/Configuration/Components/Controls/PropertyGroupEditableColumn/ScalarPropertyEditor";
import { NumericPropertyEditor } from "@HisPlatform/BoundedContexts/Configuration/Components/Controls/PropertyGroupEditableColumn/NumericPropertyEditor";
import { NumericRangePropertyEditor } from "@HisPlatform/BoundedContexts/Configuration/Components/Controls/PropertyGroupEditableColumn/NumericRangePropertyEditor";


interface IPropertyGroupEditableColumnProps extends IDataGridColumnBaseProps {
}


const PropertyGroupEditableColumn: React.FC<IPropertyGroupEditableColumnProps> = (props: IPropertyGroupEditableColumnProps) => {

    const cellValueRenderer = useCallback((_: any, row: ScalarProperty) => {
        if (row instanceof BoolProperty) {
            return (
                <BoolPropertyEditor row={row} />
            );
        } else if (row instanceof NumericProperty) {
            return (
                <NumericPropertyEditor row={row} />
            );
        } else if (row instanceof NumericRangeProperty) {
            return (
                <NumericRangePropertyEditor row={row} />
            );
        } else {
            return (
                <ScalarPropertyEditor row={row} />
            );
        }
    }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={cellValueRenderer}
        />
    );
};

export default PropertyGroupEditableColumn;