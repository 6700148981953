/* tslint:disable */
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import Reflection from "@Toolkit/CommonWeb/Reflection/Reflection";

export default class FrontendActionId extends StringEntityId implements IStringEntityId {
    public static readonly typeName: string = "FrontendActionId";
    public get typeName() { return FrontendActionId.typeName; }
    
    public static fromJS(data: any) { 
        if (data === undefined || data === null) {
            return null;
        }
        return new FrontendActionId(data["Value"] + "");
    }
    
    public toJSON(target?: any) {
        target = typeof target === "object" ? target : {};
        target["Value"] = parseInt(this.value, 10);
        return target;
    }
    public toUrl(): string { return this.value?.toString() ?? null; }
    public static fromUrl(url: string) { return new FrontendActionId(url); }
    
    public static readonly new = new FrontendActionId("new");
    
    public static isNew(value: FrontendActionId) { return value?.value === FrontendActionId.new.value || parseInt(value?.value, 10) < 0; }
}

Reflection.registerType(FrontendActionId, "FrontendActionId", "Primitive");