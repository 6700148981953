import _ from "@HisPlatform/Common/Lodash";

export function objectToMap<TValue>(original: { [key: string]: TValue; }): Map<string, TValue> {
    const result = new Map<string, TValue>();

    _.forIn(original, (value: TValue, key: string) => {
        result.set(key, value);
    });

    return result;
}