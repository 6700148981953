import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import EhiEReferralListQuerySettingsStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralListQuerySettingsStore";
import EhiEReferralListQueryScenarioCodeSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralListQueryScenarioCodeSelectBox/EhiEReferralListQueryScenarioCodeSelectBox";
import InstituteSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/InstituteSelectBox/InstituteSelectBox";
import EhiEReferralQueryScenarioCodeId from "@Primitives/EhiEReferralQueryScenarioCodeId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

interface IEhiEReferralListQuerySettingsPanelProps {
    store: EhiEReferralListQuerySettingsStore;
    isCompactView: boolean;
}


const EhiEReferralListQuerySettingsPanel: React.FC<IEhiEReferralListQuerySettingsPanelProps> = (props) => {
    const resources = StaticHunEHealthInfrastructureCareResources.EReferralListPanel.Query;
    const width = props.isCompactView ? 6 : 4;

    return (
        <>
            <Ui.Flex>
                <Ui.Flex.Item xs={width}>
                    <EhiEReferralListQueryScenarioCodeSelectBox
                        label={resources.ScenarioCode}
                        value={props.store.scenarioCode}
                        onChange={props.store.setScenarioCode}
                        limitScenarioCodes={"executingSide"}
                        automationId="scenarioCodeSelectBox"
                    />
                </Ui.Flex.Item>
                {ValueWrapper.equals(props.store.scenarioCode, EhiEReferralQueryScenarioCodeId[0]) &&
                    <Ui.Flex.Item xs={width}>
                        <Ui.DateRangePicker
                            label={resources.Interval}
                            value={props.store.interval}
                            onChange={props.store.setInterval}
                            clearable
                            automationId="intervalPicker"
                        />
                    </Ui.Flex.Item>}
            </Ui.Flex>
            <Ui.Flex>
                <Ui.Flex.Item xs={width}>
                    <InstituteSelectBox
                        label={resources.Institute}
                        value={props.store.institute}
                        onChange={props.store.setInstitute}
                        shouldSelectOnlyItem
                        automationId="instituteSelectBox"
                        clearable={false}
                        shouldDefaultToFirstItem
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={width}>
                    <Ui.CheckBox
                        label={resources.BreakGlass}
                        value={props.store.breakGlass}
                        onChange={props.store.setBreakGlass}
                        verticalAlign={"inlineInput"}
                        automationId="breakGlassCheckBox"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Separator />
        </>
    );
};

export default State.observer(EhiEReferralListQuerySettingsPanel);
