import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export default class Identifier {

    @State.observable.ref public identifierSystemId: IdentifierSystemId;
    @State.observable public value: string;

    constructor(
        identifierSystemId: IdentifierSystemId = null,
        value: string = null
    ) {
        this.identifierSystemId = identifierSystemId;
        this.value = value;
    }

    @State.action.bound
    public setIdentifierSystemId(newValue: IdentifierSystemId) {
        this.identifierSystemId = newValue;
    }

    @State.action.bound
    public setValue(newValue: string) {
        this.value = newValue;
    }

    public toJSON() {
        return { IdentifierSystemId: this.identifierSystemId.toJSON(), Value: this.value };
    }

    public static fromJS(data: object): Identifier {
        if (isNullOrUndefined(data) || isNullOrUndefined(data["IdentifierSystemId"])) {
            return null;
        }
        return new Identifier(IdentifierSystemId.fromJS(data["IdentifierSystemId"]), data["Value"]);
    }
}