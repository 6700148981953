import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { mapToFormAsync } from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/FormMappers";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import PatientId from "@Primitives/PatientId.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import { mapFormFieldDataToProxy } from "@HisPlatform/BoundedContexts/FormEngine/ApplicationLogic/ApiAdapter/FormEngineMappers";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";

@Di.injectable()
export default class EpisodeOfCareScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("ICareClient") private readonly apiClient: Proxy.ICareClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("IFormEngineReferenceDataStore") private formEngineReferenceDataStore: IFormEngineReferenceDataStore) {
        super(referenceDataLoader);
    }

    @State.bound
    public getNewEpisodeOfCareScreenDataAsync(patientId: PatientId) {

        return this.executeOperationAsync<IForm, Proxy.GetNewEpisodeOfCareScreenDataQueryResponse>(
            () => this.apiClient.getNewEpisodeOfCareScreenDataQueryAsync(CreateRequestId(), new Proxy.GetNewAdmitPatientScreenDataControllerDto({
                patientId: patientId
            })),
            async response => {
                const form = await mapToFormAsync(this.formEngineReferenceDataStore, response.data, response.formDefinitionId, [] as any, response.formLogic);
                return form;
            }
        );
    }

    @State.bound
    public getEpisodeOfCareScreenDataAsync(episodeOfCareId: EpisodeOfCareId, requestLock: boolean) {

        return this.executeOperationAsync<IForm, Proxy.GetEpisodeOfCareScreenDataCommandResponse>(
            () => this.apiClient.getEpisodeOfCareScreenDataCommandAsync(CreateRequestId(), new Proxy.GetEpisodeOfCareScreenDataControllerDto({
                episodeOfCareId: episodeOfCareId,
                requestLock: requestLock
            })),
            async response => {
                const form = await mapToFormAsync(this.formEngineReferenceDataStore, response.data, response.formDefinitionId, response.compositeValidationResult, response.formLogic, response.rowVersions);
                return form;
            }
        );
    }

    @State.bound
    public createEpisodeOfCareAsync(form: IForm, isValidateOnly: boolean = false) {
        return this.executeOperationAsync<IForm, Proxy.CreateEpisodeOfCareCommandResponse>(
            () => {
                const mappedFormFieldData: Proxy.FormFieldData[] = [];

                mapFormFieldDataToProxy(form.data.Content, mappedFormFieldData as any);

                return this.apiClient.createEpisodeOfCareCommandAsync(CreateRequestId(), new Proxy.CreateEpisodeOfCareControllerDto({
                    data: mappedFormFieldData,
                    isValidateOnly: isValidateOnly,
                }));
            },
            async response => {
                const rowVersions: { [key: string]: RowVersion } = {};
                rowVersions["EpisodeOfCare"] = response.episodeOfCare?.rowVersion;


                const resultForm =
                    await mapToFormAsync(this.formEngineReferenceDataStore, response.episodeOfCare?.data, form.definitionId, response.compositeValidationResult, form.formLogic, rowVersions, form.data);
                return resultForm;
            }
        );
    }

    @State.bound
    public updateEpisodeOfCareAsync(form: IForm, releaseLockIfSuccessful: boolean, lockInfo: LockInfo, isValidateOnly: boolean = false) {
        return this.executeOperationAsync<IForm, Proxy.UpdateEpisodeOfCareCommandResponse>(
            () => {
                const mappedFormFieldData: Proxy.FormFieldData[] = [];
                mapFormFieldDataToProxy(form.data.Content, mappedFormFieldData as any);

                return this.apiClient.updateEpisodeOfCareCommandAsync(CreateRequestId(), new Proxy.UpdateEpisodeOfCareControllerDto({
                    data: mappedFormFieldData,
                    rowVersion: form.rowVersions.get("EpisodeOfCare"),
                    releaseLockIfSuccessful: releaseLockIfSuccessful,
                    lockId: lockInfo.lockId,
                    isValidateOnly: isValidateOnly
                }));
            },
            async response => {
                const rowVersions: { [key: string]: RowVersion } = {};
                rowVersions["EpisodeOfCare"] = response.episodeOfCare?.rowVersion;


                const resultForm =
                    await mapToFormAsync(this.formEngineReferenceDataStore, response.episodeOfCare?.data, form.definitionId, response.compositeValidationResult, form.formLogic, rowVersions, form.data);
                return resultForm;
            }
        );
    }

    public deleteAsync(episodeOfCareId: EpisodeOfCareId, form: IForm, lockInfo: LockInfo) {
        return this.executeOperationAsync<any, Proxy.DeleteEpisodeOfCareCommandResponse>(
            () => {
                return this.apiClient.deleteEpisodeOfCareCommandAsync(CreateRequestId(), new Proxy.DeleteEpisodeOfCareControllerDto({
                    episodeOfCareId: episodeOfCareId,
                    rowVersion: form.rowVersions.get("EpisodeOfCare"),
                    releaseLockIfSuccessful: true,
                    lockId: lockInfo.lockId
                }));
            },
            i => {
                return;
            });
    }
}