exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ValidationResultBody_row-body_WIeJr {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  padding-bottom: 7px;\n  background: #F4F7FC;\n  display: block;\n  width: fit-content;\n  padding: 7px;\n  border-radius: 3px;\n  margin: 5px 0 3px 0;\n}\n.ValidationResultBody_row-body_WIeJr span {\n  white-space: normal;\n}\n", ""]);

// exports
exports.locals = {
	"row-body": "ValidationResultBody_row-body_WIeJr",
	"rowBody": "ValidationResultBody_row-body_WIeJr"
};