import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React, { useEffect, useMemo, useState } from "react";
import * as Ui from "@CommonControls";
import InsurerOrganizationSelectBox from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Components/Controls/InsurerOrganizationSelectBox";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import PatientDocumentTypeSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/PatientRegister/PatientDocumentTypeSelectBox";
import PatientDocumentTypeId from "@Primitives/PatientDocumentTypeId.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import InsurerOrganizationDetailPanelModalParams, { IInsurerOrganizationDetailPanelModalResult } from "@HisPlatform/BoundedContexts/Finance/Components/InsurerOrganizationModal/InsurerOrganizationDetailPanelModalParams";
import { useErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import PatientDocumentCategory from "@HisPlatform/BoundedContexts/Care/Api/PatientRegister/Enum/PatientDocumentCategory.g";

export interface IPatientIdentifierDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

export interface IPatientIdentifierProps {
    _dependencies?: IPatientIdentifierDependencies;
    _modalService?: IModalService;

    identifierTypeId: PatientDocumentTypeId;
    onIdentifierTypeIdChange: (id: PatientDocumentTypeId) => void;

    identifierValue: string;
    onIdentifierValueChange: (value: string) => void;

    issuer?: string;
    onIssuerChange?: (value: string) => void;

    insurerOrganizationId?: InsurerOrganizationId;
    onInsurerOrganizationIdChange?: (id: InsurerOrganizationId) => void;

    validFrom?: LocalDate;
    onValidFromChange?: (value: LocalDate) => void;

    validTo?: LocalDate;
    onValidToChange?: (value: LocalDate) => void;
}

function PatientIdentifier(props: IPatientIdentifierProps) {
    const errorDispatcher = useErrorDispatcher();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        props._dependencies.careReferenceDataStore.patientDocumentType.ensureAllLoadedAsync()
            .catch(errorDispatcher)
            .then(() => setIsLoaded(true));
    }, []); 

    const patientIdentifierCategory = useMemo(() => {
        const patientDocumentType = props._dependencies.careReferenceDataStore.patientDocumentType.items.find(i => i.Entity.id.value === props.identifierTypeId?.value);
        return patientDocumentType?.Entity.category;
    }, [isLoaded, props.identifierTypeId]);

    return (
        <>
            <Ui.Flex>
                <Ui.Flex.Item xs={3}>
                    <Ui.TextBox
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.Value}
                        value={props.identifierValue}
                        onChange={props.onIdentifierValueChange}
                        propertyIdentifier="IdentifierValue"
                        automationId="DocumentNumber"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={3}>
                    <PatientDocumentTypeSelectBox
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.DocumentType}
                        value={props.identifierTypeId}
                        onChange={props.onIdentifierTypeIdChange}
                        propertyIdentifier="DocumentTypeId"
                        automationId="DocumentTypeId"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
            {renderInsurerSelector(props, patientIdentifierCategory)}
            <Ui.Flex>
                <Ui.Flex.Item xs={3}>
                    <Ui.DatePicker
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.ValidFrom}
                        value={props.validFrom}
                        onChange={props.onValidFromChange}
                        propertyIdentifier="ValidFrom"
                        automationId="ValidFrom"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={3}>
                    <Ui.DatePicker
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.ValidTo}
                        value={props.validTo}
                        onChange={props.onValidToChange}
                        propertyIdentifier="ValidTo"
                        automationId="ValidTo"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={4}>
                    {renderIssuer(props, patientIdentifierCategory)}
                </Ui.Flex.Item>
            </Ui.Flex>
        </>
    );
}

function renderInsurerSelector(props: IPatientIdentifierProps, category: PatientDocumentCategory) {
    if (category === PatientDocumentCategory.InsuranceRelationship) {
        return (
            <Ui.Flex>
                <Ui.Flex.Item xs={8}>
                    <InsurerOrganizationSelectBox
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.Organization}
                        value={props.insurerOrganizationId}
                        onChange={props.onInsurerOrganizationIdChange}
                        propertyIdentifier="InsurerOrganizationId"
                        automationId="InsurerOrganizationId"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={4}>
                    <Ui.Button
                        text={StaticCareResources.PatientRegister.PatientDocuments.Labels.NewInsurer}
                        onClickAsync={() => showInsurerOrganizationDetailPanelModalAsync(props)}
                        automationId="__newInsurerButton"
                        verticalAlign="input"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }
    return (<></>);
}

function renderIssuer(props: IPatientIdentifierProps, category: PatientDocumentCategory) {
    if (category === PatientDocumentCategory.PersonalIdentity) {
        return (
            <Ui.TextBox
                label={StaticCareResources.PatientRegister.PatientDocuments.Labels.Issuer}
                value={props.issuer}
                onChange={props.onIssuerChange}
                automationId="__issuer"
            />);
    }
    return (<></>);
}

async function showInsurerOrganizationDetailPanelModalAsync(props: IPatientIdentifierProps) {
    const result = await props._modalService.showDialogAsync<IInsurerOrganizationDetailPanelModalResult>(new InsurerOrganizationDetailPanelModalParams());

    if (result) {
        this.props.patientDocumentStore.setOrganizationId(result.insurerOrganizationId);
    }
}

export default connect<IPatientIdentifierProps>(
    PatientIdentifier,
    new DependencyAdapter<IPatientIdentifierProps, IPatientIdentifierDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore")
        };
    }),
    new HisModalServiceAdapter());