import { IValidationProblemParameterMapper } from "@Toolkit/CommonWeb/ApiAdapter/IValidationProblemParameterMapper";
import Di from "@Di";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import AddressTypeId from "@Primitives/AddressTypeId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

@Di.injectable()
export default class PatientAdministrativeDataValidationProblemMapper implements IValidationProblemParameterMapper {
    constructor(@Di.inject("CommonReferenceDataDataStore") private commonReferenceDataDataStore: CommonReferenceDataDataStore) {
    }

    public collectValidationProblemParameterReferenceData(vp: IClientValidationProblem) {
        return;
    }

    @State.bound
    public async loadCollectedValidationProblemParameterReferenceDataAsync(): Promise<void> {
        await this.commonReferenceDataDataStore.addressType.ensureLoadedAsync();
    }

    @State.bound
    public resolveValidationProblemParameters(vp: IClientValidationProblem) {
        let results = {};
        if (!isNullOrUndefined(vp.parameters?.AddressTypeId)) {
            const addressType = this.commonReferenceDataDataStore.addressType.get(new AddressTypeId(vp.parameters.AddressTypeId.Value.toString()));
            results = {
                ...results,
                AddressType: addressType.displayValue.Name
            };
        }
        return results;
    }
}