import React from "react";
import { IPanelWithButtonPortalProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/IPanelWithButtonPortalProps";
import { ServiceRequestMasterDetailPanelMode } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestMasterDetailPanel/ServiceRequestMasterDetailPanelMode";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ServiceRequestMasterDetailPanel from "./ServiceRequestMasterDetailPanel";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import ServiceRequestFilter from "@Primitives/ServiceRequestFilter";

interface IUncontrolledServiceRequestMasterDetailPanelProps extends IPanelWithButtonPortalProps {
    mode: ServiceRequestMasterDetailPanelMode;
    showServiceRequestFilter: boolean;
    defaultServiceRequestFilter?: ServiceRequestFilter;
    isCompact?: boolean;

    onBack: () => void;

    /** Manually sets the PageBox's height. Do NOT use the elsewhere this is a temporary solution.
     * @obsolete
     * @deprecated
     */
    manualHeight?: string;
}

@State.observer
export default class UncontrolledServiceRequestMasterDetailPanel extends React.Component<IUncontrolledServiceRequestMasterDetailPanelProps> {

    public static defaultProps: Partial<IUncontrolledServiceRequestMasterDetailPanelProps> = {
        showServiceRequestFilter: true,
        defaultServiceRequestFilter: ServiceRequestFilter.CareActivity
    };

    @State.observable.ref private selectedId: ServiceRequestId = null;
    @State.observable.ref private useCase: INDataUseCaseState = null;
    @State.observable.ref private serviceRequestFilter = this.props.defaultServiceRequestFilter;

    public render() {
        return (
            <ServiceRequestMasterDetailPanel 
                mode={this.props.mode}
                onBack={this.props.onBack}
                showServiceRequestFilter={this.props.showServiceRequestFilter}
                buttons={this.props.buttons}
                hidePortalButtons={this.props.hidePortalButtons}
                manualHeight={this.props.manualHeight}

                selectedId={this.selectedId}
                useCase={this.useCase}
                onCloseUseCase={this.clearUseCase}
                onChange={this.setUseCase}
                serviceRequestFilter={this.serviceRequestFilter}
                onServiceRequestFilterChange={this.setServiceRequestFilter}
            />
        );
    }

    @State.action.bound
    private clearUseCase() {
        this.useCase = null;
        this.selectedId = null;
    }

    @State.action.bound
    private setUseCase(id: ServiceRequestId | null, state: INDataUseCaseState) {
        this.selectedId = id;
        this.useCase = state;
    }

    @State.action.bound
    private setServiceRequestFilter(filter: ServiceRequestFilter) {
        this.serviceRequestFilter = filter;
    }

}