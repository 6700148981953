import React from "react";
import { observer } from "mobx-react";
import { reaction } from "mobx";
import State from "./StateManaging";

interface IReactionComponentProps<T> {
    inspector: () => T;
    reaction: (state: T) => void;
    fireOnMount?: boolean;
    trace?: boolean;
    debounceDelay?: number;
}

@observer
export default class ReactionComponent<T> extends React.Component<IReactionComponentProps<T>> {

    private _reaction: () => void = null;

    public componentDidMount() {
        this._reaction = reaction(
            this.props.inspector,
            this.props.reaction,
            {
                fireImmediately: this.props.fireOnMount,
                delay: this.props.debounceDelay
            }
        );

        if (this.props.trace) {
            State.trace(this._reaction, true);
        }
    }

    public componentWillUnmount() {
        this._reaction?.();
    }

    public render(): React.ReactNode { return null; }
}