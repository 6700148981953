import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import HunCareApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/HunCareApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import RegionalCareObligationStatus from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/RegionalCareObligationStatus";
import BadgeWithIcon from "@CommonControls/BadgeWithIcon/BadgeWithIcon";
import StaticHunSocialSecurityCareResources from "@HunSocialSecurityPlugin/BoundedContexts/Care/StaticResources/StaticHunSocialSecurityCareResources";
import IRegionalCareObligationStatusExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IRegionalCareObligationStatusExtensionPointProps";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface IRegionalCareObligationStatusExtensionDependencies {
    hunCareApiAdapter: HunCareApiAdapter;
}

interface IRegionalCareObligationStatusExtensionProps extends IRegionalCareObligationStatusExtensionPointProps {
    _dependencies?: IRegionalCareObligationStatusExtensionDependencies;
}

@State.observer
class RegionalCareObligationStatusExtension extends React.Component<IRegionalCareObligationStatusExtensionProps> {

    @State.observable private regionalCareObligationStatus?: RegionalCareObligationStatus = null;

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IRegionalCareObligationStatusExtensionProps) {
        if (this.props.patientId !== prevProps.patientId
            || this.props.pointOfCareId !== prevProps.pointOfCareId
            || this.props.validOn !== prevProps.validOn
            || this.props.insurerOrganizationCode !== prevProps.insurerOrganizationCode) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    private async loadAsync() {
        const response = await this.props._dependencies.hunCareApiAdapter.getRegionalCareObligationStatusAsync(
            this.props.patientId,
            this.props.pointOfCareId,
            this.props.validOn ?? LocalDate.today()
        );

        State.runInAction(() => {
            this.regionalCareObligationStatus = response.value;
        });
    }

    public render() {
        switch (this.regionalCareObligationStatus) {
            case RegionalCareObligationStatus.WithinTheRegion:
                return (
                    <BadgeWithIcon
                        iconName="street_view_solid"
                        text={StaticHunSocialSecurityCareResources.CareRegister.RegionalCareObligationStatus.WithinTheRegion}
                        badgeVisualStyleType="blue" />
                );
            case RegionalCareObligationStatus.OutsideTheRegion:
                return (
                    <BadgeWithIcon
                        iconName="location_out"
                        text={StaticHunSocialSecurityCareResources.CareRegister.RegionalCareObligationStatus.OutsideTheRegion}
                        badgeVisualStyleType="red" />
                );
            case RegionalCareObligationStatus.Unknown:
                return (
                    <BadgeWithIcon
                        iconName="mapMarkerAltSolid"
                        text={StaticHunSocialSecurityCareResources.CareRegister.RegionalCareObligationStatus.Unknown}
                        badgeVisualStyleType="gray" />
                );
            default:
                return null;
        }
    }
}

export default connect(
    RegionalCareObligationStatusExtension,
    new DependencyAdapter<IRegionalCareObligationStatusExtensionProps, IRegionalCareObligationStatusExtensionDependencies>(c => ({
        hunCareApiAdapter: c.resolve("HunCareApiAdapter")
    }))
);