import StoreMapper from "@Toolkit/CommonWeb/ApiAdapter/StoreMapper";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import Di from "@Di";
import { mapPatientCareActivityDataDtoToStore } from "./PatientCareActivityDataDtoMapper";

@Di.injectable()
export default class PatientsCareActivitiesStoreMapper extends StoreMapper<SimpleStore<{isFiltered: boolean, results: PatientsCareActivityStore[], pageIndex: number, extensionData: any}>, Proxy.ISearchCareActivitiesOfPatientQueryResponse> {

    protected applyToStoreCore(target: SimpleStore<{isFiltered: boolean, totalCount: number, results: PatientsCareActivityStore[], pageIndex: number, extensionData: any}>, response: Proxy.ISearchCareActivitiesOfPatientQueryResponse) {
        target.value = {
            results: response.results.values.map(mapPatientCareActivityDataDtoToStore),
            isFiltered: response.results.someItemsAreHidden,
            totalCount: response.results.totalCount,
            pageIndex: response.results.pageIndex,
            extensionData: response.extensionData
        };
    }
}
