import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import Di from "@Di";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import IObservationDefinitionsForMedicalService from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServices/IObservationDefinitionsForMedicalService";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IScalarObservationDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServices/IScalarObservationDefinition";
import Unit from "@Toolkit/CommonWeb/Model/Unit";
import ITextualObservationDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServices/ITextualObservationDefinition";
import ICategoryObservationDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServices/ICategoryObservationDefinition";
import { IObservationDefinition } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServices/IObservationDefinition";
import ObservationDefinitionId from "@Primitives/ObservationDefinitionId.g";
import Category from "@Toolkit/CommonWeb/Model/Category";
import ICategoryHintItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IVaccineHintItem";
import ICategory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/ICategory";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import ComplexSearchCategoriesQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalServices/Enum/ComplexSearchCategoriesQueryOrderingFields.g";

@Di.injectable()
export default class ObservationDefinitionApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IMedicalServicesClient") private readonly apiClient: Proxy.IMedicalServicesClient
    ) {
        super();
    }

    public loadObservationDefinitionsForMedicalServicesAsync(medicalServices: Array<IEntityVersionSelector<MedicalServiceId>>) {
        return this.processOperationAsync(
            new SimpleStore<IObservationDefinitionsForMedicalService[]>(),
            async target => {
                const response = await this.apiClient.getObservationDefinitionsForMedicalServicesQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetObservationDefinitionsForMedicalServicesControllerDto({
                        medicalServiceIds: medicalServices.map(s => new Proxy.EntityVersionSelectorOfMedicalServiceId({
                            entityId: s.id,
                            validOn: s.validOn
                        }))
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.observationDefinitionsForMedicalServices.map(s => ({
                    medicalService: {
                        id: s.medicalServiceId.entityId,
                        validOn: s.medicalServiceId.validOn
                    } as IEntityVersionSelector<MedicalServiceId>,
                    observationDefinitions: s.observationDefinitions.map(this.mapObservationDefinition).filter(d => !!d)
                } as IObservationDefinitionsForMedicalService));
            }
        );
    }

    @State.bound
    public loadObservationDefinitionsByIdsAsync(observationDefinitionIds: ObservationDefinitionId[]) {
        return this.processOperationAsync(
            new SimpleStore<IObservationDefinition[]>(),
            async target => {
                const response = await this.apiClient.getObservationDefinitionsQueryAsync(CreateRequestId(), new Proxy.GetObservationDefinitionsControllerDto({
                    observationDefinitionIds: observationDefinitionIds
                }));

                target.operationInfo = createOperationInfo(response);
                target.value = response.observationDefinitions.map(this.mapObservationDefinition);
            }
        );
    }  
    
    @State.bound
    public loadObservationDefinitionByCodeAsync(observationDefinitionCode: string) {
        return this.processOperationAsync(
            new SimpleStore<IObservationDefinition>(),
            async target => {
                const response = await this.apiClient.getObservationDefinitionByCodeQueryAsync(CreateRequestId(), new Proxy.GetObservationDefinitionByCodeControllerDto({
                    code: observationDefinitionCode
                }));

                target.operationInfo = createOperationInfo(response);
                target.value = this.mapObservationDefinition(response.observationDefinition);
            }
        );
    }  

    @State.bound
    private mapObservationDefinition(def: Proxy.ObservationDefinitionDto) {
        if (def instanceof Proxy.ScalarObservationDefinitionDto) {
            return {
                type: "scalar",
                id: def.id,
                code: def.code,
                description: def.description,
                name: def.name,
                units: def.units ? new Unit(def.units.name, def.units.shortCode) : null,
                highReferenceBound: def.highReferenceBound ? def.highReferenceBound.value : null,
                lowReferenceBound: def.lowReferenceBound ? def.lowReferenceBound.value : null,
            } as IScalarObservationDefinition;
        } else if (def instanceof Proxy.TextualObservationDefinitionDto) {
            return {
                type: "textual",
                id: def.id,
                code: def.code,
                description: def.description,
                name: def.name,
                sectionName: def.sectionName,
                isMandatory: def.isMandatory
            } as ITextualObservationDefinition;
        } else if (def instanceof Proxy.CategoryObservationDefinitionDto) {
            return {
                type: "category",
                id: def.id,
                code: def.code,
                description: def.description,
                name: def.name,
                categories: def.categories.map(c => new Category(c.key, c.value)),
                isMultiSelect: def.isMultiSelect
            } as ICategoryObservationDefinition;
        }

        throw new Error("Unknown ObservationDefinitionDto derived type");
    }

    public searchCategories(observationDefinitionCode: string, freeText: string, maxResultCount: number) {
        return this.processOperationAsync(
            new SimpleStore<ICategoryHintItem[]>(),
            async target => {
                const response = await this.apiClient.searchCategoriesQueryAsync(
                    CreateRequestId(),
                    freeText,
                    maxResultCount.toString(),
                    observationDefinitionCode
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.categories.map(ai => {
                    return {                        
                        code: ai.code,
                        name: ai.name
                    } as ICategoryHintItem;
                });
            }
        );
    }

    private mapCategory(ai: Proxy.CategoryDto): ICategory {
        return {
            code: ai.code,
            name: ai.name
        } as ICategory;
    }

    @State.bound
    public complexSearchCategories(
        freeText: string,
        ordering: IOrderingState,
        paging: IPagingState,
        observationDefinitionCode: string) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<ICategory>>(),
            async (target) => {
                const response = await this.apiClient.complexSearchCategoriesQueryAsync(
                    CreateRequestId(),
                    new Proxy.ComplexSearchCategoriesControllerDto({
                        freeText: freeText,
                        observationDefinitionCode: observationDefinitionCode,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfComplexSearchCategoriesQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfComplexSearchCategoriesQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: ComplexSearchCategoriesQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results && response.results.values.map(this.mapCategory)
                };

            }
        );
    }
}