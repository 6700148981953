import React from "react";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import NavBarLogo from "@HisPlatform/Application/DynamicNavigation/NavBarLogo";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";

interface IApplicationInfoModalMenuDependencies {
    applicationContext: ApplicationContext;
}

interface IApplicationInfoModalMenuProps {
    _dependencies?: IApplicationInfoModalMenuDependencies;
}

class ApplicationInfoModalMenu extends React.Component<IApplicationInfoModalMenuProps> {
    public render() {
        return (
            <Ui.NavBar.Item noPadding onClick={this.props._dependencies.applicationContext.openApplicationInformationModal} automationId="applicationInformation">
                <NavBarLogo />
            </Ui.NavBar.Item>
        );
    }
}

export default connect(
    ApplicationInfoModalMenu,
    new DependencyAdapter<IApplicationInfoModalMenuProps, IApplicationInfoModalMenuDependencies>(c => ({
        applicationContext: c.resolve("ApplicationContext")
    }))
);
