import React from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import {iconNameType} from "@CommonControls/Icon";
import {Icon} from "@CommonControls";
import Styles from "./ComplexSearchPanelView.less";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IFavoriteDataGridColumnProps {
    isFavorite: (value: any) => boolean;
    onFavoriteIconClick: (value: any) => void;
}

export default class FavoriteDataGridColumn extends React.Component<IFavoriteDataGridColumnProps> {
    @State.bound
    private onClick(item: any) {
        return (event: React.MouseEvent) => {
            event.stopPropagation();
            this.props.onFavoriteIconClick(item);
        };
    }

    @State.bound
    private renderRow(row: any, value: any) {
        const icon = (this.props.isFavorite(value) ? "star_filled" : "star_outline") as iconNameType;
        return (
            <div className={Styles.favoritesIconContainer} onClick={this.onClick(value)}>
                <Icon iconName={icon} size={"normal"} style={{paddingLeft: "1px", fill: "white"}}/>
            </div>
        );
    }

    public render() {

        return (
            <DataGridColumn
                onRenderCellValue={this.renderRow}
                width={"39px"}
                id="favorite"
            />
        );
    }
}
