import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import AvailablePointOfCareListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Actions/AvailablePointOfCareListStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import CareActivityId from "@Primitives/CareActivityId.g";
import PatientId from "@Primitives/PatientId.g";
import ReferralStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/ReferralStore";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import _ from "@HisPlatform/Common/Lodash";
import AppointmentStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentStatus.g";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import AppointmentStatusColumnData from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentStatusColumn/AppointmentStatusColumnData";
import * as Ui from "@CommonControls";
import { iconNameType } from "@CommonControls/Icon";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import AppointmentStatusColumn from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentStatusColumn/AppointmentStatusColumn";
import PointOfCareColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";
import SchedulingServiceColumn from "@HisPlatform/BoundedContexts/Scheduling/Components/Controls/SchedulingServiceColumn";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import PractitionerColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerColumn/PractitionerColumn";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { LoadedSignalAdapter } from "@Toolkit/CommonWeb/PanelStore/LoadedSignal";

interface IAppointmentsPanelCoreDependencies {
    apiAdapter: SchedulingApiAdapter;
    availablePointOfCareListStore: AvailablePointOfCareListStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
}

interface IAppointmentsPanelCoreProps {
    _dependencies?: IAppointmentsPanelCoreDependencies;
    _careActivityId?: CareActivityId;
    _patientId?: PatientId;

    showOnlySelectedAppointment?: boolean;
    hasServiceRequest?: boolean;
    initialAppointmentScheduleEntryId?: AppointmentScheduleEntryId;
    onSelectAppointment: (value: Appointment) => void;
    onSetReferralData?: (value: ReferralStore) => void;
    _onLoaded?: () => void;
}

/** @screen */
@State.observer
class AppointmentsPanelCore extends React.Component<IAppointmentsPanelCoreProps> {
    @State.observable.ref private appointments: Appointment[] = [];
    @State.observable.ref private selectedAppointment: Appointment = null;

    private get availablePointOfCareListStore() {
        return this.props._dependencies.availablePointOfCareListStore;
    }

    private readonly loadAsync = createInitialPanelLoader(this._loadAsync);

    private get resources() {
        return StaticCareResources.CareRegister.AppointmentsPanel;
    }

    @State.computed
    private get dataSource() {
        if (this.props.showOnlySelectedAppointment && !!this.selectedAppointment) {
            return [this.selectedAppointment];
        }
        return this.appointments;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.bound
    private async _loadAsync() {
        await this.availablePointOfCareListStore.loadAvailablePointOfCareListAsync();
        const appointments = await this.loadAppointmentsAsync();
        const practitionerIds = _.uniq(_.flatten(appointments.map(item => item.practitionerIds)));

        await this.props._dependencies.organizationReferenceDataStore.doctorMap.ensureLoadedAsync(practitionerIds);
        await this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleDefinitions.ensureAllLoadedAsync();

        const schedulingServiceIds = appointments // TODO workaround until joint subject medical service/service request column is implemented
            .map(item => item.schedulingServiceId)
            .filter(schedulingServiceId => !!schedulingServiceId);
        await this.props._dependencies.schedulingReferenceDataStore.schedulingServices.ensureLoadedAsync(schedulingServiceIds);

        const pointOfCareIds = appointments.map(item => item.pointOfCareId).filter(poc => !isNullOrUndefined(poc?.value));
        await this.loadHealthCareProfessionsAsync(pointOfCareIds);

        this.setAppointments(appointments);
        if ((!this.props._careActivityId || this.props._careActivityId?.value === "new") && this.props.initialAppointmentScheduleEntryId) {
            const appointment = appointments.find(item => wrappedValuesAreEquals(item.id, this.props.initialAppointmentScheduleEntryId));
            State.runInAction(() => this.selectedAppointment = appointment);
            this.props.onSelectAppointment(appointment);
            this.props.onSetReferralData?.(appointment?.referral);
        } else if (this.props._careActivityId) {
            const appointment = appointments.find(item => item.careActivityId && wrappedValuesAreEquals(item.careActivityId, this.props._careActivityId));
            State.runInAction(() => this.selectedAppointment = appointment);
            this.props.onSelectAppointment(appointment);
        }

        this.props._onLoaded?.();
    }

    @State.bound
    private async loadAppointmentsAsync() {
        const response = await this.props._dependencies.apiAdapter.getAppointmentsForRegisteredPatientAsync(this.props._patientId, false, [AppointmentStatus.Booked, AppointmentStatus.Fulfilled]);
        const appointments = response.value.filter(item => !item.pointOfCareId || this.availablePointOfCareListStore.pointOfCareIdList.some(poc => wrappedValuesAreEquals(poc, item.pointOfCareId))
            && (item.status === AppointmentStatus.Booked || (item.careActivityId && this.props._careActivityId && wrappedValuesAreEquals(item.careActivityId, this.props._careActivityId))));
        return _.sortBy(appointments, item => item.intervalFrom.format());
    }

    @State.bound
    private async loadHealthCareProfessionsAsync(pointOfCareIds: PointOfCareId[]) {
        await this.props._dependencies.organizationReferenceDataStore.pointOfCareMap.ensureLoadedAsync(pointOfCareIds);
        const ids = _.flatten(pointOfCareIds.map(this.props._dependencies.organizationReferenceDataStore.pointOfCareMap.get).map(item => item.healthcareProfessionIds));
        const uniqIds = _.uniqBy(ids, item => item.value);
        await this.props._dependencies.organizationReferenceDataStore.healthCareProfession.ensureLoadedAsync(uniqIds);

    }

    @State.action.bound
    private setAppointments(appointments: Appointment[]) {
        this.appointments = appointments;
    }

    private renderTitle() {
        return (
            <>
                {this.resources.Title + " "}
                <Ui.InfoButton position="baseline"
                    tooltipContent={this.resources.HasAppointmentHint} />
            </>
        );
    }

    @State.bound
    private renderButton(value: any, row: Appointment) {
        let onClick: () => void;
        let iconName: iconNameType;
        let automationId = "linkAppointmentButton_";

        const isLinked = row === this.selectedAppointment;

        if (isLinked) {
            onClick = () => {
                State.runInAction(() => this.selectedAppointment = null);
                this.props.onSelectAppointment(null);
            };
            iconName = "link";
            automationId += "linked";
        } else {
            onClick = () => {
                State.runInAction(() => this.selectedAppointment = row);
                this.props.onSelectAppointment(row);
                this.props.onSetReferralData?.(row?.referral);
            };
            iconName = "unlink";
            automationId += "unlinked";
        }
        return (
            <Ui.Button
                onClick={onClick}
                size="compact"
                iconName={iconName}
                automationId={automationId}
            />
        );
    }

    @State.bound
    private getAppointmentStatusData(appointment: Appointment) {
        return new AppointmentStatusColumnData(appointment.status, null);
    }

    public render() {
        if (this.loadAsync.isUnauthorizedAccess) {
            return (
                <>
                    <Ui.GroupBox title={this.renderTitle()} style={{ padding: "0px 4px" }}>
                        <UnauthorizedAccessContent />
                    </Ui.GroupBox>
                    <Ui.Separator />
                </>
            );
        }

        if (!this.appointments.length) {
            return (<></>);
        }

        if (this.props.showOnlySelectedAppointment && !this.selectedAppointment) {
            return (<></>);
        }

        if (this.props.hasServiceRequest) {
            return (<></>);
        }

        return (
            <>
                <Ui.GroupBox title={this.renderTitle()} style={{ padding: "0px 4px" }}>
                    <Ui.DataGrid
                        hidePager
                        dataSource={this.dataSource}
                        fixedLayout
                        isSelectable
                        selectedRow={this.selectedAppointment}
                        rowHeight="40px"
                        automationId="appointmentsGrid"
                        footer={this.props.showOnlySelectedAppointment ? null : undefined}
                    >
                        <AppointmentStatusColumn
                            header={this.resources.Columns.Status}
                            dataGetter={this.getAppointmentStatusData}
                            id="Status"
                            width="60px"
                        />
                        <PointOfCareColumn
                            header={this.resources.Columns.PointOfCare}
                            dataGetter={"pointOfCareId"}
                            id="PointOfCareId"
                            width="20%"
                        />
                        <SchedulingServiceColumn
                            header={this.resources.Columns.SchedulingService}
                            dataGetter={"schedulingServiceId"} // TODO workaround until joint subject medical service/service request column is implemented
                            id="MedicalServiceId"
                            width="30%"
                        />
                        <DataGridDateTimeColumn
                            header={this.resources.Columns.DateTime}
                            dataGetter={"intervalFrom"}
                            id="intervalFrom"
                            width="20%"
                        />
                        <PractitionerColumn
                            header={this.resources.Columns.Practitioner}
                            dataGetter={"practitionerIds"}
                            isCodeBold
                            id="30%"
                        />
                        <DataGridColumn
                            onRenderCellValue={this.renderButton}
                            id="Button"
                            width="60px"
                            cellTextAlign="center"
                        />
                    </Ui.DataGrid>
                </Ui.GroupBox>
            </>
        );
    }
}

export default connect(
    AppointmentsPanelCore,
    new DependencyAdapter<IAppointmentsPanelCoreProps, IAppointmentsPanelCoreDependencies>(container => {
        return {
            apiAdapter: container.resolve("SchedulingApiAdapter"),
            availablePointOfCareListStore: container.resolve("AvailablePointOfCareListStore"),
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            schedulingReferenceDataStore: container.resolve("SchedulingReferenceDataStore")
        };
    }),
    new PatientContextAdapter<IAppointmentsPanelCoreProps>(p => ({
        _patientId: p.patientId,
    })),
    new CareActivityContextAdapter<IAppointmentsPanelCoreProps>(p => ({
        _careActivityId: p.careActivityId
    })),
    new LoadedSignalAdapter("CustomBlock:AppointmentsPanel"),
);