import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import IExternalPrescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IExternalPrescription";

export interface IExternalPrescriptionDetailModalParams extends IModalParams {
    externalPrescription: IExternalPrescription;
}

export default class ExternalPrescriptionDetailModalParams implements IExternalPrescriptionDetailModalParams {
    public static type = "ExternalMedicationDetailModalParams";
    public get type() { return ExternalPrescriptionDetailModalParams.type; }

    constructor(public externalPrescription: IExternalPrescription) { }
}
