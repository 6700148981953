import React from "react";
import * as Ui from "@CommonControls";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { FeedbackType } from "@HisPlatform/BoundedContexts/Diagnostics/ApplicationLogic/Model/FeedbackType";

interface IFeedbackDialogViewProps {
    isOpen: boolean;
    summary: string;
    description: string;
    isLoading: boolean;
    feedbackTypes: Array<ISelectBoxItem<string>>;
    selectedFeedbackType: FeedbackType;
    isBugOnly: boolean;

    onClose: () => void;
    onSummaryChange: (newValue: string) => void;
    onDescriptionChange: (newValue: string) => void;
    onSendAsync: () => Promise<void>;
    onChangeAttachments: (files: File[]) => void;
    onIssueTypeChange: (issueType: FeedbackType) => void;
}


const FeedbackDialogView: React.FC<IFeedbackDialogViewProps> = props => (
    <Ui.Modal isOpen={props.isOpen} onClose={props.onClose} title="Visszajelzés küldése" size="compact" closeOnOutsideClick={false} automationId="feedbackDialog" index={4}>
        {!props.isLoading ?
            <Ui.Modal.Body>
                <>
                    <Ui.Flex>
                        {!props.isBugOnly && <Ui.Flex.Item xs={12}>
                            <Ui.SelectBox
                                label="Visszajelzés típusa:"
                                items={props.feedbackTypes}
                                value={props.selectedFeedbackType}
                                onChange={props.onIssueTypeChange}
                                required
                                automationId="feedbackTypesSelectBox"
                            />
                        </Ui.Flex.Item>}
                        <Ui.Flex.Item xs={12}>
                            <Ui.TextBox
                                label="Összegzés:"
                                value={props.summary}
                                onChange={props.onSummaryChange}
                                maxLength={128}
                                propertyIdentifier="Summary"
                                required
                                automationId="summaryTextBox"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <Ui.TextBox
                                label="Részletes leírás:"
                                value={props.description}
                                onChange={props.onDescriptionChange}
                                multiline
                                multilineMinLineCount={6}
                                automationId="descriptionTextBox"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <Ui.FileUpload
                                id="FeedbackDialogUpload"
                                label="Egyéb csatolmányok (képernyőképet és a LOG-okat már csatoltuk):"
                                onChange={props.onChangeAttachments}
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </>
            </Ui.Modal.Body> : <></>}
        <Ui.Modal.Footer>
            <Ui.Flex xsJustify="end">
                <Ui.Flex.Item>
                    <Ui.Button
                        text="Küldés"
                        visualStyle="primary"
                        onClickAsync={props.onSendAsync}
                        automationId="sendButton"
                    />
                    <Ui.Button
                        text="Mégsem"
                        onClick={props.onClose}
                        automationId="closeButton"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>

        </Ui.Modal.Footer>
    </Ui.Modal>
);

export default FeedbackDialogView;