import React from "react";
import * as Ui from "@CommonControls";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiEReferralCovidData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralCovidData";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import EhiStructuredDocumentContactStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiStructuredDocumentContactStore";
import ContactView from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/EhiEReferralExtension/CovidData/ContactView";

const renderPractitionerContact = (item: EhiStructuredDocumentContactStore, index: number) => <ContactView contactStore={item} index={index} contactPerson="practitioner" />;
const renderPatientContact = (item: EhiStructuredDocumentContactStore, index: number) => <ContactView contactStore={item} index={index} contactPerson="patient" />;

interface IContactsViewProps {
    store: EhiEReferralCovidData;
    onDeleteContactConfirmationAsync: (contact: EhiStructuredDocumentContactStore) => Promise<boolean>;
    createContact: () => Promise<EhiStructuredDocumentContactStore>;
}


const ContactsView: React.FC<IContactsViewProps> = (props: IContactsViewProps) => {
    if (!props.store) {
        return null;
    }
    return (
        <Ui.Flex>
            <Ui.Flex.Item sm={12}>
                <ListPanel<EhiStructuredDocumentContactStore>
                    title={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.PractitionerContacts}
                    alwaysEdit
                    items={props.store.practitionerContacts}
                    renderItemEditor={renderPractitionerContact}
                    onDeleteItemConfirmationAsync={props.onDeleteContactConfirmationAsync}
                    onCreateNewAsync={props.createContact}
                    propertyIdentifier="PractitionerContacts"
                    isCompactEmptyState
                    automationId="practitionerContacts"
                    onAddNewItem={props.store.addPractitionerContact}
                    onRemoveItem={props.store.removePractitionerContact}
                />
            </Ui.Flex.Item>
            <Ui.Flex.Item sm={12}>
                <ListPanel<EhiStructuredDocumentContactStore>
                    title={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.PatientContacts}
                    alwaysEdit
                    items={props.store.patientContacts}
                    renderItemEditor={renderPatientContact}
                    onDeleteItemConfirmationAsync={props.onDeleteContactConfirmationAsync}
                    onCreateNewAsync={props.createContact}
                    propertyIdentifier="PatientContacts"
                    isCompactEmptyState
                    automationId="patientContacts"
                    onAddNewItem={props.store.addPatientContact}
                    onRemoveItem={props.store.removePatientContact}
                />
            </Ui.Flex.Item>
        </Ui.Flex>
    );
};

export default State.observer(ContactsView);
