import React, { CSSProperties } from "react";
import * as Ui from "@CommonControls";
import AgeRange from "@CommonControls/AgeFilter/AgeRange";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IAgeFilterProps {
    value?: AgeRange;
    onChange: (newValue: AgeRange) => void;
    automationId?: string;
    ageLabel?: string;
    ageEpsilonLabel?: string;
}

export default class AgeFilter extends React.PureComponent<IAgeFilterProps> {

    public static defaultProps: Partial<IAgeFilterProps> = {
        value: null
    };

    public render() {
        const plusminusStyle = {
            float: "right"
        } as CSSProperties;

        if (this.props.ageLabel) {
            plusminusStyle.marginTop = "20px";
        }

        return (
            <div data-automation-id={this.props.automationId || undefined}>
                <Ui.Flex verticalSpacing="none" outerSpacing="none">
                    <Ui.Flex.Item sm={6}>
                        <Ui.NumberBox
                            label={this.props.ageLabel}
                            value={this.props.value && this.props.value.age}
                            onChange={this.onAgeChange}
                            automationId={"__age"}
                            integerOnly
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={1} style={{ paddingTop: "6px" }}>
                        <label style={plusminusStyle}>±</label>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={5}>
                        <Ui.NumberBox
                            label={this.props.ageEpsilonLabel || this.props.ageLabel ? " " : undefined}
                            value={this.props.value && this.props.value.ageEpsilon}
                            onChange={this.onEpsilonChange}
                            automationId={"__ageEpsilon"}
                            integerOnly
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </div>
        );
    }

    @State.bound
    private onAgeChange(newValue: number) {
        this.props.onChange(new AgeRange(newValue, this.props.value && this.props.value.ageEpsilon || null));
    }

    @State.bound
    private onEpsilonChange(newValue: number) {
        if (this.props.value) {
            this.props.onChange(new AgeRange(this.props.value.age, newValue));
        }
    }
}