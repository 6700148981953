import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import InsuranceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Insurance/InsuranceStore";

export function mapInsurance(dto: Proxy.InsuranceDto) {
    const newInsurance = new InsuranceStore();
    newInsurance.id = dto.id;
    newInsurance.insurancePlanId = dto.insurancePlanId;
    newInsurance.insurerOrganizationId = dto.insurerOrganizationId;
    newInsurance.validFrom = dto.validity.from;
    newInsurance.validTo = dto.validity.to;
    newInsurance.comment = dto.comment;
    newInsurance.isActive = dto.isActive;
    newInsurance.isDefault = dto.isDefault;
    return newInsurance;
}