export default class CompositeClassName {
    private _classNames: string[];

    constructor(...classNames: string[]) {
        this._classNames = classNames || [];
    }

    public get classNames() {
        return this._classNames.join(" ");
    }

    public add(className: string) {
        if (className) {
            this._classNames.push(className);
        }
    }

    public addIf(condition: boolean, className: string, elseClassName?: string) {
        if (condition) {
            this.add(className);
        } else if (elseClassName) {
            this.add(elseClassName);
        }
    }
}

export function combineClasses(...classes: string[]) {
    return classes.filter(c => !!c).join(" ");
}