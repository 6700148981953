import DependencyContainer from "@DiContainer";
import ReportingApiAdapter from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/ReportingApiAdapter";
import StatisticApiAdapter from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/StatisticApiAdapter";

DependencyContainer
    .bind("ReportingApiAdapter")
    .to(ReportingApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("StatisticApiAdapter")
    .to(StatisticApiAdapter)
    .inSingletonScope();
