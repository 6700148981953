import React from "react";
import { LoadingIndicator } from "@CommonControls";

export default function createLazyLoader<TProps>(loader: () => Promise<any>) {
    let component: any = null;

    class LazyLoader extends React.Component<TProps & { forwardedRef: any }, { component: any }> {
        constructor(props: TProps & { forwardedRef: any }) {
            super(props);

            this.state = {
                component,
            };
        }

        public componentDidMount() {
            LazyLoader.loadAsync().then(this.updateState);
        }

        private updateState = () => {
            if (this.state.component !== component) {
                this.setState({
                    component,
                });
            }
        };

        public static async loadAsync() {
            const result = await loader();
            component = result.default || result;
        }

        public render() {
            
            const { component: ComponentFromState } = this.state;
            const { forwardedRef, ...rest } = this.props;
            if (ComponentFromState) {
                return <ComponentFromState ref={forwardedRef} {...rest} />;
            }

            return <LoadingIndicator mode="parent" />;
        }
    }

    return React.forwardRef<unknown, TProps>((props, ref) => {
        return <LazyLoader {...props} forwardedRef={ref} />;
    });
}