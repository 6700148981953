import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalConditionItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionItemBase";
import PatientBloodTypeId from "@Primitives/PatientBloodTypeId.g";
import CategoryObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/CategoryObservation";
import { CategoryObservationCodeSelectorItem } from "@HisPlatformControls/CategoryObservationCodeSelector/CategoryObservationCodeSelectorItem";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

export default class PatientBloodTypeStore extends MedicalConditionItemBase<PatientBloodTypeId> {

    @State.observable.ref public categoryObservation: CategoryObservation;
    @State.observable public observedAt: LocalDate;

    constructor(isNew: boolean) {
        super(isNew);
    }

    @State.computed public get bloodTypeCodeSelectorItem() {
        if (!this.categoryObservation) {
            return new CategoryObservationCodeSelectorItem({ name: "" });
        }

        return new CategoryObservationCodeSelectorItem({ code: this.categoryObservation?.singleCategory?.key, name: this.categoryObservation?.singleCategory?.value });

    }

    @State.action.bound
    public setCategoryObservation(newValue: CategoryObservation) {
        this.categoryObservation = newValue;
    }

    @State.action.bound
    public setObservedAt(newValue: LocalDate) {
        this.observedAt = newValue;
    }
}