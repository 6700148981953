import React from "react";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import _ from "@HisPlatform/Common/Lodash";
import MedicalServiceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicalServiceApiAdapter";
import IMedicalServiceCategory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalServiceCategory";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import RequestedServiceGroup from "@HisPlatformControls/RequestedServiceListPanel/RequestedServiceGroup";
import RequestedServiceItem from "@HisPlatformControls/RequestedServiceListPanel/RequestedServiceItem";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IMedicalServicePanel from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServices/IMedicalServicePanel";
import MedicalServicePanelId from "@Primitives/MedicalServicePanelId.g";
import MedicalServiceCategoryId from "@Primitives/MedicalServiceCategoryId.g";
import WebAppResources from "@StaticResources";
import GenderId from "@Primitives/GenderId.g";
import FinancedServiceApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/Finance/FinancedServiceApiAdapter";

interface IRequestedServiceListPanelDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    medicalServiceApiAdapter: MedicalServiceApiAdapter;
    financedServiceApiAdapter: FinancedServiceApiAdapter;
}

interface IRequestedServiceListPanelProps {
    _dependencies?: IRequestedServiceListPanelDependencies;
    selectedItems: IObservableArray<RequestedServiceStore>;
    availableMedicalServices: Array<IEntityVersionSelector<MedicalServiceId>>;
    availablePanels: MedicalServicePanelId[];
    selectedPanels: IObservableArray<MedicalServicePanelId>;
    isAvailable: boolean;
    currentPatientGenderId: GenderId;
    onTotalNetPriceChanged?: (newValue: number) => void;
    onPanelSelectionChanged?: (panelId: MedicalServicePanelId, isSelected: boolean) => void;
}

interface IPanelizedItems {
    panel: IMedicalServicePanel;
    items: RequestedServiceItem[];
}

interface ICategorizedItems {
    category: IMedicalServiceCategory;
    items: RequestedServiceItem[];
}

interface IMedicalServiceVersionSelectorWithOrigin {
    panel: IMedicalServicePanel;
    medicalServiceVersionSelector: IEntityVersionSelector<MedicalServiceId>;
}

@State.observer
class RequestedServiceListPanel extends React.Component<IRequestedServiceListPanelProps> {

    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get apiAdapter() { return this.props._dependencies.medicalServiceApiAdapter; }

    @State.observable.ref private itemsColumns: ICategorizedItems[][] = null;
    @State.observable.ref private panels: IPanelizedItems[] = null;

    private isInitializing = false;
    @State.observable.ref private isLoading = false;

    private readonly uncategorizedCategory: IMedicalServiceCategory = {
        id: new MedicalServiceCategoryId("none"),
        code: "none",
        name: WebAppResources.RequestedServiceListPanel.UncategorizedServices,
        order: 10000,
        shortName: WebAppResources.RequestedServiceListPanel.UncategorizedServices
    };

    @State.computed private get hasPanels() {
        return !arrayIsNullOrEmpty(this.panels);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IRequestedServiceListPanelProps) {
        if (
            (prevProps.isAvailable !== this.props.isAvailable) ||
            prevProps.selectedItems !== this.props.selectedItems ||
            prevProps.availableMedicalServices !== this.props.availableMedicalServices
        ) {
            dispatchAsyncErrors(this.loadPanelAsync(), this);
        }
    }

    @State.action
    private async loadPanelAsync() {
        if ((arrayIsNullOrEmpty(this.props.availableMedicalServices) && arrayIsNullOrEmpty(this.props.availablePanels)) || this.isInitializing) {
            return;
        }

        this.isInitializing = true;
        this.isLoading = true;

        const availableMedicalServicesWithOrigins = this.getAvailableMedicalServicesWithOrigins();
        const availablePanelMedicalServicesWithOrigins = await this.getPanelMedicalServicesWithOriginsAsync();

        const allAvailableMedicalServices = availableMedicalServicesWithOrigins.concat(availablePanelMedicalServicesWithOrigins);

        const allMedicalServiceSelectors = allAvailableMedicalServices.map(s => s.medicalServiceVersionSelector);
        await this.loadReferenceDataAsync(allMedicalServiceSelectors);

        const categoryMap = await this.loadCategoryMapAsync(allAvailableMedicalServices);

        const categorizedItemsMap = new Map<string, ICategorizedItems>();
        const panelizedItemsMap = new Map<string, IPanelizedItems>();

        allAvailableMedicalServices.forEach(service => {

            let requestedService = this.props.selectedItems.find(rs => EntityVersionSelector.areIdEquals(rs.medicalServiceVersionSelector, service.medicalServiceVersionSelector));
            const requestedServiceIndex = this.props.selectedItems.indexOf(requestedService);

            if (!requestedService) {
                // not selected, create empty
                requestedService = new RequestedServiceStore(true);
                requestedService.medicalServiceVersionSelector = service.medicalServiceVersionSelector;
            }

            const medicalService = this.careReferenceDataStore.medicalService.get(service.medicalServiceVersionSelector);
            const category = categoryMap.get(medicalService.id.value);

            let serviceItem = new RequestedServiceItem(
                requestedService,
                medicalService,
                this.careReferenceDataStore.medicalService.getAll(medicalService.connectedServiceIds.map(id =>
                    new EntityVersionSelector<MedicalServiceId>(id, LocalDate.today()) // todo: connectedMedicalServiceIds should be version selectors!!!
                )),
                category,
                service.panel,
                !(isNullOrUndefined(medicalService.allowedGender) || ValueWrapper.equals(medicalService.allowedGender, this.props.currentPatientGenderId)),
                requestedServiceIndex
            );

            if (service.panel && service.panel.isAtomic) {
                serviceItem.behavior = "panel-bound";
            }

            let categorizedItems: ICategorizedItems = categorizedItemsMap.get(category.id.value);

            if (!categorizedItems) {
                categorizedItems = {
                    category,
                    items: [serviceItem]
                };
                categorizedItemsMap.set(category.id.value, categorizedItems);
            } else {

                const alreadyAddedItem = categorizedItems.items.find(ci => ci.medicalService === serviceItem.medicalService);

                if (alreadyAddedItem) {
                    serviceItem = alreadyAddedItem;

                    if (!service.panel || !service.panel.isAtomic) {
                        alreadyAddedItem.behavior = "weak-panel-bound";
                    }
                } else {
                    categorizedItems.items.push(serviceItem);
                }
            }

            if (service.panel) {

                let panelizedItems = panelizedItemsMap.get(service.panel.code);
                if (!panelizedItems) {
                    panelizedItems = {
                        panel: service.panel,
                        items: [serviceItem]
                    } as IPanelizedItems;
                    panelizedItemsMap.set(service.panel.code, panelizedItems);
                } else {
                    panelizedItems.items.push(serviceItem);
                }
            }
        });

        this.initializePanel(categorizedItemsMap, panelizedItemsMap);
        await this.refreshTotalNetPriceAsync();

        State.runInAction(() => {
            this.isInitializing = false;
            this.isLoading = false;
        });
    }

    private getAvailableMedicalServicesWithOrigins() {
        return this.props.availableMedicalServices.map(medicalServiceVersionSelector => ({
            panel: null,
            medicalServiceVersionSelector
        } as IMedicalServiceVersionSelectorWithOrigin));
    }

    private async getPanelMedicalServicesWithOriginsAsync() {
        if (arrayIsNullOrEmpty(this.props.availablePanels)) {
            return [] as IMedicalServiceVersionSelectorWithOrigin[];
        }

        const panelsResult = await this.apiAdapter.getMedicalServicePanelsByIdsAsync(this.props.availablePanels);
        const panels = panelsResult.value;
        const panelMedicalServiceIds = _.flatten(
            panels.map(p => p.medicalServices.map(ms => ({ panel: p, medicalService: ms })))
        );

        const panelMedicalServiceVersionSelectors = panelMedicalServiceIds.map(s => ({
            panel: s.panel,
            medicalServiceVersionSelector: new EntityVersionSelector(s.medicalService, LocalDate.today()) // todo: this should be version selectors
        } as IMedicalServiceVersionSelectorWithOrigin));

        return panelMedicalServiceVersionSelectors;
    }

    private async loadReferenceDataAsync(services: Array<IEntityVersionSelector<MedicalServiceId>>) {
        await this.careReferenceDataStore.medicalService.ensureLoadedAsync(services);
        await this.loadConnectedMedicalServicesAsync(services);
    }

    private async loadConnectedMedicalServicesAsync(services: Array<IEntityVersionSelector<MedicalServiceId>>) {
        // todo: this should be version selectors
        const connectedMedicalServiceIds: MedicalServiceId[] = [];

        services.map(service => {
            const medicalService = this.careReferenceDataStore.medicalService.get(service);

            if (!arrayIsNullOrEmpty(medicalService.connectedServiceIds)) {
                connectedMedicalServiceIds.push(...medicalService.connectedServiceIds);
            }
        });

        await this.careReferenceDataStore.medicalService.ensureLoadedAsync(connectedMedicalServiceIds.map(id =>
            new EntityVersionSelector<MedicalServiceId>(id, LocalDate.today()) // todo: connectedMedicalServiceIds should be version selectors!!!
        ));
    }

    private async loadCategoryMapAsync(services: IMedicalServiceVersionSelectorWithOrigin[]) {
        const serviceVersions = services.map(service => this.careReferenceDataStore.medicalService.get(service.medicalServiceVersionSelector));

        const categoryIds =
            _.uniqBy(
                serviceVersions
                    .filter(medicalService => !!medicalService.category)
                    .map(medicalService => medicalService.category.medicalServiceCategoryId),
                categoryId => categoryId && categoryId.value
            );

        // todo: modify getMedicalServiceCategoriesByIdsAsync query to return category-to-medicalService pairs
        const categories = await this.apiAdapter.getMedicalServiceCategoriesByIdsAsync(categoryIds);

        return new Map<string, IMedicalServiceCategory>(
            serviceVersions
                .map(medicalService => {
                    const categoryId = medicalService.category?.medicalServiceCategoryId;
                    const category = isNullOrUndefined(categoryId)
                        ? this.uncategorizedCategory
                        : categories.value.find(c => ValueWrapper.equals(c.id, categoryId));
                    return [medicalService.id.value, category];
                }) as [[string, IMedicalServiceCategory]]
        );
    }

    @State.action
    private initializePanel(categorizedItemsMap: Map<string, ICategorizedItems>, panelizedItemsMap: Map<string, IPanelizedItems>) {
        this.panels = Array.from(panelizedItemsMap.values());
        this.itemsColumns = this.explodeItemsToColumns(categorizedItemsMap);
    }

    private explodeItemsToColumns(categorizedItemsMap: Map<string, ICategorizedItems>) {
        const categorizedItemsColumns: ICategorizedItems[][] = [];

        let columnIndex = 0;
        const columnCount = 3;

        const categorizedItemsSet = Array.from(categorizedItemsMap.values());

        _.orderBy(categorizedItemsSet, [(c: ICategorizedItems) => c.category.order])
            .forEach(categorizedItems => {
                const targetColumn = categorizedItemsColumns[columnIndex];
                if (targetColumn) {
                    targetColumn.push(categorizedItems);
                } else {
                    categorizedItemsColumns[columnIndex] = [categorizedItems];
                }

                if (++columnIndex === columnCount) {
                    columnIndex = 0;
                }
            });

        return categorizedItemsColumns;
    }

    @State.action.bound
    private deselectPanel(panel: IMedicalServicePanel) {
        this.panels.find(p => p.panel === panel).items.forEach(i => this.setRowSelectionState(false, i));
        this.props.onPanelSelectionChanged?.(panel.id, false);
    }

    public render() {
        const columnSize = this.hasPanels ? 3 : 4;

        if (this.props.isAvailable === false) {
            return (
                <Ui.Message type="info">
                    {StaticWebAppResources.RequestedServiceListPanel.RequiredPerformingLocationAndPriorityMessage}
                </Ui.Message>
            );
        }

        return (
            !(this.isLoading || this.isInitializing) ? (
                <>
                    {this.itemsColumns && (
                        <>
                            <Ui.Flex innerSpacing="none" automationId="__requestedServicesPanel">
                                <Ui.Flex.Item xs={columnSize} style={{ display: "flex", flexDirection: "column" }}>
                                    {this.renderMedicalServiceCategories(this.itemsColumns[0])}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={columnSize} style={{ display: "flex", flexDirection: "column" }}>
                                    {this.renderMedicalServiceCategories(this.itemsColumns[1])}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={columnSize} style={{ display: "flex", flexDirection: "column" }}>
                                    {this.renderMedicalServiceCategories(this.itemsColumns[2])}
                                </Ui.Flex.Item>
                                {this.hasPanels &&
                                    <Ui.Flex.Item xs={columnSize} style={{ display: "flex", flexDirection: "column" }}>
                                        {this.renderMedicalServicePanels(this.panels)}
                                    </Ui.Flex.Item>
                                }
                            </Ui.Flex>
                        </>
                    )}
                </>
            ) : <></>
        );

    }

    @State.action.bound
    private setRowSelectionState(isChecked: boolean, row: RequestedServiceItem) {
        if (isChecked) {
            if (!this.props.selectedItems.some(i => i === row.store)) {
                this.props.selectedItems.push(row.store);
            }
        } else {
            this.props.selectedItems.remove(row.store);
        }
        this.setSelectionIndexes();
        this.refreshTotalNetPriceAsyncDebounced();
    }

    @State.action.bound
    private setSelectionIndexes() {
        this.panels.forEach(p => {
            p.items.forEach(i => this.setSelectionIndex(i));
            const isAllItemsSelected = p.items.every(i => i.isSelected);
            const isPanelSelected = this.props.selectedPanels.some(i => ValueWrapper.equals(i, p.panel.id));
            if (isAllItemsSelected && !isPanelSelected) {
                this.props.onPanelSelectionChanged?.(p.panel.id, true);
            } else if (!isAllItemsSelected && isPanelSelected) {
                this.props.onPanelSelectionChanged?.(p.panel.id, false);
            }
        });
        this.itemsColumns.forEach(c => c.forEach(g => g.items.forEach(i => this.setSelectionIndex(i))));
    }

    @State.action.bound
    private setSelectionIndex(item: RequestedServiceItem) {
        const requestedServiceIndex = this.props.selectedItems.indexOf(item.store);

        item.setSelectionIndex(requestedServiceIndex);
    }

    private async refreshTotalNetPriceAsync() {
        if (!this.props.onTotalNetPriceChanged) {
            return;
        }

        const medicalServicePanelIds: MedicalServicePanelId[] = [];
        const alreadyAddedMedicalServiceIds = new Set<string>();
        for (const panel of this.panels) {
            if (!panel.items.every(item => item.isSelected)) {
                continue;
            }

            medicalServicePanelIds.push(panel.panel.id);
            for (const item of panel.items) {
                if (!alreadyAddedMedicalServiceIds.has(item.medicalService.id.value)) {
                    alreadyAddedMedicalServiceIds.add(item.medicalService.id.value);
                }
            }
        }

        const medicalServiceIds: MedicalServiceId[] = this.itemsColumns
            .flatMap(column => column)
            .flatMap(group => group.items)
            .filter(item => item.isSelected && !alreadyAddedMedicalServiceIds.has(item.medicalService.id.value))
            .map(item => item.medicalService.id);
        const result = await this.props._dependencies.financedServiceApiAdapter.getMedicalServicePricesAsync(
            medicalServicePanelIds,
            medicalServiceIds,
            LocalDate.today()
        );

        this.props.onTotalNetPriceChanged(result.value);
    }

    private refreshTotalNetPriceAsyncDebounced = _.debounce(() => {
        return dispatchAsyncErrors(this.refreshTotalNetPriceAsync(), this, false);
    }, 250);

    @State.bound
    private renderMedicalServicePanels(panelizedItemsSet: IPanelizedItems[]) {
        return panelizedItemsSet && panelizedItemsSet.map(panelizedItems => {
            if (panelizedItems.items.length === 0) {
                return null;
            }

            return (
                <RequestedServiceGroup
                    isPanel={true}
                    name={panelizedItems.panel.name}
                    code={panelizedItems.panel.code}
                    items={panelizedItems.items}
                    key={panelizedItems.panel.code}
                    selectedItems={this.props.selectedItems}
                    onSetRowSelectionState={this.setRowSelectionState}
                    onDeselectPanel={this.deselectPanel}
                />
            );
        });
    }

    @State.bound
    private renderMedicalServiceCategories(categorizedItemsSet: ICategorizedItems[]) {
        return categorizedItemsSet && categorizedItemsSet.map(categorizedItems => {
            if (categorizedItems.items.length === 0) {
                return null;
            }
            return (
                <RequestedServiceGroup
                    isPanel={false}
                    name={categorizedItems.category.name}
                    items={categorizedItems.items}
                    key={categorizedItems.category.code}
                    selectedItems={this.props.selectedItems}
                    onSetRowSelectionState={this.setRowSelectionState}
                    onDeselectPanel={this.deselectPanel}
                />
            );
        });
    }
}

export default connect(
    RequestedServiceListPanel,
    new DependencyAdapter<IRequestedServiceListPanelProps, IRequestedServiceListPanelDependencies>(c => ({
        careReferenceDataStore: c.resolve<CareReferenceDataStore>("CareReferenceDataStore"),
        medicalServiceApiAdapter: c.resolve<MedicalServiceApiAdapter>("MedicalServiceApiAdapter"),
        financedServiceApiAdapter: c.resolve<FinancedServiceApiAdapter>("FinancedServiceApiAdapter")
    }))
);