import BusinessErrorBase from "@HisPlatform/Model/DomainModel/BusinessError/BusinessErrorBase";

export default class TextualBusinessError extends BusinessErrorBase {

    public get name(): string { return "TextualBusinessError"; }
    public message: string;

    constructor(message: string) {
        super();
        this.message = message;
    }
}