import React from "react";
import * as Ui from "@CommonControls";
import StaticWebAppResources from "@StaticResources";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IEmptyStateSettings, RowId, IRowBody } from "@CommonControls/DataGrid/IDataGridProps";
import ExtendedPatientFilter from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/PatientWorklist/Filters/ExtendedPatientFilter";
import PatientFilterStore from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/PatientWorklist/Filters/PatientFilterStore";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import IWorklistItemDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistItemDefinition";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import INDataRow from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataRow";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import IWorklistData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistData";
import IDynamicListDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IDynamicListDefinition";
import { ICondition } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistFilterCondition";
import FrontendListRequestMode from "@HisPlatform/BoundedContexts/Productivity/Components/NDataGrid/FrontendListRequestMode";
import NDataGrid from "@HisPlatform/BoundedContexts/Productivity/Components/NDataGrid/NDataGrid";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import ActionBoundButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundButton";
import ShowNewPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowNewPatientScreenAction.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";

interface IPatientWorklistPanelView {
    handleNewPatientClick: () => void;
    handleNewAppointmentClick: () => void;
    worklistDefinition: IWorklistDefinition;
    navigateToUseCase: (rowId: RowId, useCaseState: INDataUseCaseState) => void;
    filterStore: PatientFilterStore;
    isExtendedFilterVisible?: boolean;
    onExtraFilterVisibilityChanged?: (newValue: boolean) => void;
    onClearFilter: () => void;
    onFilterStoreCreatedAsync?: (filterStore: any) => Promise<void>;
    onGetExtendedFilterDescriptors?: () => IDataGridFilterDescriptor[];
    onRenderRowBody?: (row: INDataRow, rowId: RowId, rowIndex: number, rowBodyItemDefinition: IWorklistItemDefinition) => IRowBody;
    onGetDynamicListAsync?: (requestMode: FrontendListRequestMode, pageIndex: number, pageSize: number, orderBy: { fieldName: string, ascending: boolean }, filter: { [fieldName: string]: ICondition }) =>
        Promise<{ data: IWorklistData, definition: IDynamicListDefinition }>;
    mode: "List" | "Selector";
    onPerformClientSideActionAsync: (clientSideAction: ClientSideActionDto, rowId: RowId) => Promise<WorkListArgument | boolean>;
}


const PatientWorklistPanelView: React.FC<IPatientWorklistPanelView> = (props) => {
    const actionDescriptor = new ActionDescriptor(
        new ShowNewPatientScreenAction(
            ScreenDisplayMode.Full),
        null,
        []
    );

    const emptyStateSettings = {
        title: StaticWebAppResources.SearchPatient.Label.NoResultsFoundDescription,
        content: (
            <ActionBoundButton
                iconName="add_patient"
                actionDescriptor={actionDescriptor}
                text={StaticWebAppResources.SearchPatient.Button.NewPatient}
                visualStyle="primary"
                automationId="addNewPatientButton" />
        )
    } as IEmptyStateSettings;

    return props.worklistDefinition && (
        <>
            {props.mode === "List" ? (
                <SingleLayout>
                    <NDataPanel
                        definition={props.worklistDefinition}
                        onChange={props.navigateToUseCase}
                        hasRefreshButton
                        hasExtraFilterButton
                        hasFilterClearButton
                        emptyStateSettings={emptyStateSettings}
                        extraFilter={(
                            <ExtendedPatientFilter
                                filterStore={props.filterStore}
                            />
                        )}
                        alwaysVisibleExtraFilter={(
                            <div style={{ margin: "0px 4px 4px" }}>
                                <Ui.TextBox
                                    label={StaticWebAppResources.SearchPatient.Label.GeneralPatientSearch}
                                    value={props.filterStore?.generalSearch}
                                    onChange={props.filterStore?.setGeneralSearch}
                                    automationId="generalSearchTextBox"
                                />
                            </div>
                        )}
                        onClearFilter={props.onClearFilter}
                        iconName="find_patient"
                        onFilterStoreCreatedAsync={props.onFilterStoreCreatedAsync}
                        onGetExtendedFilterDescriptors={props.onGetExtendedFilterDescriptors}
                        onRenderRowBody={props.onRenderRowBody}
                    />
                </SingleLayout>
            ) : (
                <NDataGrid
                    definition={props.worklistDefinition}
                    onChange={props.navigateToUseCase}
                    hasRefreshButton
                    hasExtraFilterButton
                    hasFilterClearButton
                    emptyStateSettings={emptyStateSettings}
                    extraFilter={(
                        <ExtendedPatientFilter
                            filterStore={props.filterStore}
                        />
                    )}
                    alwaysVisibleExtraFilter={(
                        <div style={{ margin: "0px 4px 4px" }}>
                            <Ui.TextBox
                                label={StaticWebAppResources.SearchPatient.Label.GeneralPatientSearch}
                                value={props.filterStore?.generalSearch}
                                onChange={props.filterStore?.setGeneralSearch}
                                automationId="generalSearchTextBox"
                            />
                        </div>
                    )}
                    onClearFilter={props.onClearFilter}
                    onFilterStoreCreatedAsync={props.onFilterStoreCreatedAsync}
                    onGetExtendedFilterDescriptors={props.onGetExtendedFilterDescriptors}
                    onRenderRowBody={props.onRenderRowBody}
                    onPerformClientSideActionAsync={props.onPerformClientSideActionAsync}
                />
            )}
        </>
    );
};

export default State.observer(PatientWorklistPanelView);
