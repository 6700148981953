import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientId from "@Primitives/PatientId.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { parseUseCaseStateFromUrlParam, getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import IMedicationStatementRouteParams from "@HisPlatform/Application/Routes/IMedicationStatementRouteParams";
import MedicationStatementId from "@Primitives/MedicationStatementId.g";
import MedicationStatementListPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicationStatement/MedicationStatementListPanel";
import PatientRoutes from "@HisPlatform/Components/Pages/Patient/PatientRoutes";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";


interface IMedicationStatementPageProps extends IRoutingFrameContentProps {
}

@State.observer
class MedicationStatementPage extends React.Component<IMedicationStatementPageProps> {

    @State.computed private get routeParams(): IMedicationStatementRouteParams {
        return this.props.routingController.currentRoute.parameters as IMedicationStatementRouteParams;
    }

    @State.computed private get selectedMedicationStatementId(): MedicationStatementId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments[0].value;
        }

        if (this.useCaseState?.useCase.value === UseCases.medicationStatementDetail) {
            return new MedicationStatementId("new");
        }

        return null;
    }

    @State.computed
    private get patientId() {
        return new PatientId(this.routeParams?.patientId);
    }

    @State.computed private get useCaseState() {
        const useCaseState = this.routeParams?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.bound
    private setUseCaseState(selectedMedicationStatementId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.replace(
            PatientRoutes.medicationStatement.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            })
        );
    }

    @State.bound
    private navigateToMedicationStatement(medicationStatementId: MedicationStatementId) {
        this.props.routingController.replace(
            PatientRoutes.medicationStatement.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.medicationStatementDetail),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(medicationStatementId, "medicationStatementId")])
            })
        );
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(
            PatientRoutes.medicationStatement.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    null,
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument(new MedicationStatementId("null"), "medicationStatementId")])
            })
        );
    }

    public render() {
        return (
            <LoadingBoundary>
                <MedicationStatementListPanel
                    _patientId={this.patientId}
                    onChange={this.setUseCaseState}
                    useCaseState={this.useCaseState}
                    selectedRowId={this.selectedMedicationStatementId?.value}
                    onSelectedMedicationStatementIdChange={this.navigateToMedicationStatement}
                    onBack={this.goBack}
                    onCloseDetail={this.setClosedState}
                />
            </LoadingBoundary>
        );
    }
}

export default connect(
    MedicationStatementPage
);
