import React from "react";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import Styles from "./Panel.less";

interface IPanelProps {
    inverted?: boolean;
}

export default class Panel extends React.PureComponent<IPanelProps> {
    public render() {
        const classes = new CompositeClassName();

        classes.addIf(this.props.inverted, Styles.inverted);
        
        return <div className={classes.classNames}>{this.props.children}</div>;
    }
}