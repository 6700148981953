import FrontendActionId from "@Toolkit/ReactClient/ActionProcessing/FrontendActionId";
import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";
import { PermittedFrontendActionPermissionScopeSet } from "./PermittedFrontendActionPermissionScopeSet";

export class PermittedFrontendAction {
    constructor(
        public readonly actionId: FrontendActionId,
        public readonly scopeSets: PermittedFrontendActionPermissionScopeSet[]
    ) { }

    public areScopesFit(scopes: FrontendActionPermissionScope[]) {

        if (this.scopeSets.length === 0) {
            return true;
        }

        return this.scopeSets.some(expectedScopeSet => {
            return expectedScopeSet.areScopesFit(scopes);
        });
    }
}
