
import { IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import { arrayIsNullOrEmpty, isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IFilterStore } from "./IFilterStore";

export interface IDataGridFilterDescriptor {
    id?: string | number;
    isFilterable?: boolean;
    defaultFilterValue?: any;
    filterValueSerializer?: IDataGridFilterValueSerializer<any>;
    type?: WorklistConditionType;
    customConverter?: any;
}

const emptyFilterStore: any = {};

export function generateFilterStore<TFilterStore extends IFilterStore = any>(
    descriptors: IDataGridFilterDescriptor[]
): TFilterStore {

    if (arrayIsNullOrEmpty(descriptors)) {
        return emptyFilterStore;
    }

    const filterStore = {} as TFilterStore;
    const searchParameterInitializer: { [id: string]: any } = {};

    descriptors.forEach((descriptor: IDataGridFilterDescriptor) => {
        if (descriptor.isFilterable === true) {
            searchParameterInitializer[descriptor.id] = descriptor.defaultFilterValue;

            filterStore["set_" + descriptor.id] = (newValue: any) => {
                State.runInAction(() => filterStore[descriptor.id] = newValue);
            };
        }
    });

    filterStore["__restore"] = (function(filter: object) {
            descriptors.forEach((descriptor: IDataGridFilterDescriptor) => {
            const stringValue = filter[descriptor.id];
            if (!isNullOrUndefined(stringValue) && !!descriptor.filterValueSerializer) {
                State.runInAction(() => this[descriptor.id] = descriptor.filterValueSerializer.deserialize(stringValue));
            }
        });
    }).bind(filterStore);

    filterStore["__serialize"] = (function() {
        return descriptors.reduce((serialized: any, descriptor: IDataGridFilterDescriptor) => {
            const value = filterStore[descriptor.id];

            if (!isNullOrUndefined(value) && !!descriptor.filterValueSerializer) {
                serialized[descriptor.id] = descriptor.filterValueSerializer.serialize(value);
            } else {
                serialized[descriptor.id] = null;
            }

            return serialized;
        }, {});
    }).bind(filterStore);

    filterStore["__reset"] = (function() {
        descriptors.forEach((descriptor: IDataGridFilterDescriptor) => {
            State.runInAction(() => this[descriptor.id] = descriptor.defaultFilterValue);
        });
    }).bind(filterStore);

    filterStore["__isAnyFilled"] = (function() {
        return descriptors.some(i => this[i.id] !== i.defaultFilterValue && !isNullOrUndefined(this[i.id]) && !isNullOrEmptyString(this[i.id]));
    }).bind(filterStore);

    State.extendObservable(filterStore, searchParameterInitializer);

    return filterStore;
}