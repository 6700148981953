import React from "react";
import * as Ui from "@CommonControls";
import { SettlementAndZipCodeSelector } from "@HisPlatformControls";
import ConditionCodeSelector from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ConditionCodeSelector";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ContagiousPatientReportType from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReportType";
import NursingLocationTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/NursingLocationTypeSelectBox";
import PointOfCareSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareSelectBox";
import OccupationSelectBox from "@HisPlatform/BoundedContexts/CommonReferenceData/Components/PatientRegister/OccupationSelectBox/OccupationSelectBox";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import ContagiousPatientReport from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReport";
import StaticHunSocialSecurityCareResources from "@HunSocialSecurityPlugin/BoundedContexts/Care/StaticResources/StaticHunSocialSecurityCareResources";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import CheckBoxGroup from "@CommonControls/CheckBox/CheckBoxGroup";
import CheckBoxGroupItemStore from "@CommonControls/CheckBox/CheckBoxGroupItemStore";
import CheckBoxGroupStore from "@CommonControls/CheckBox/CheckBoxGroupStore";
import ActionIdentifier from "@Primitives/ActionIdentifier.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ResourceId from "@Primitives/ResourceId.g";
import EhiHealthcareProviderSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiHealthcareProviderSelector";
import ContagiousPatientReportOutcomeTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/ContagiousPatientReportOutcomeTypeSelectBox";
import EhiErrorSummary from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiErrorSummary";
import * as HisUi from "@HisPlatformControls";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";

interface IContagiousPatientReportDetailPanelViewDependencies {
    localizationService: ILocalizationService;
}

interface IContagiousPatientReportDetailPanelViewProps {
    _dependencies?: IContagiousPatientReportDetailPanelViewDependencies;
    report: ContagiousPatientReport;
    isReadonly: boolean;
    onValidateAsync: () => Promise<IClientValidationResult[]>;
    action: ActionIdentifier;
    onActionChange: (key: string, newSelectionState: boolean | null) => void;
    onReloadAsync: () => Promise<void>;
    onReportTypeChange: (type: ContagiousPatientReportType) => void;
    reportTypeSelectorDisabled: boolean;
    isLoading: boolean;
    onSave: () => Promise<any>;
    shouldDisplayStandaloneButtons: boolean;
    onDeleteAsync: () => Promise<any>;
}

@State.observer
class ContagiousPatientReportDetailPanelView extends React.Component<IContagiousPatientReportDetailPanelViewProps> {
    private relevantActions = [
        "ContagiousPatientReport_AskForApproval",
        "ContagiousPatientReport_Approve"
    ];

    private deleteAction = "ContagiousPatientReport_Delete";

    @State.computed
    private get canDelete() {
        return this.props.report.possibleActions.some(a => a.value === this.deleteAction);
    }

    @State.computed
    private get actionsToCheckBoxGroupItems() {
        const items = this.props.report.possibleActions
            .filter(item => this.relevantActions.includes(item.value))
            .map(item =>
                new CheckBoxGroupItemStore<ActionIdentifier>(item, this.props.action?.value === item.value)
            );

        return new CheckBoxGroupStore<ActionIdentifier>(items);
    }

    @State.bound
    private getActionDisplayValue(item: ActionIdentifier) {
        return this.props._dependencies.localizationService.localizeReferenceData(new ResourceId(`ContagiousPatientReportAction.${item.value}`));
    }

    @State.computed private get onsetData() {
        return this.props.report.onsetData;
    }

    @State.computed private get endReportData() {
        return this.props.report.endReportData;
    }

    @State.bound
    private renderEndReportForm() {
        return (
            <>
                <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        <ContagiousPatientReportOutcomeTypeSelectBox
                            label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.EndReportOutcomeType}
                            value={this.endReportData.outcomeTypeId}
                            onChange={this.endReportData.setOutcomeTypeId}
                            automationId="endReportOutcomeEnumSelectBox"
                            propertyIdentifier="Item.EndReportData.EndOutcomeTypeId"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.DatePicker
                            label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.EndReportDate}
                            value={this.endReportData.endDate}
                            onChange={this.endReportData.setEndDate}
                            automationId="endReportDateDatePicker"
                            propertyIdentifier="Item.EndReportData.EndDate"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        <ConditionCodeSelector
                            label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.EndReportCondition}
                            automationId="endConditionCodeSelector"
                            value={this.endReportData.endDiagnosisId}
                            onChange={this.endReportData.setEndDiagnosisId}
                            propertyIdentifier="Item.EndReportData.EndDiagnosisId"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </>
        );
    }

    private renderDetailToolbar() {

        return (
            <>
                <Ui.Flex.Item>
                    <Ui.SaveButton
                        disabled={!this.props.report?.isMutable || this.props.isReadonly}
                        onClickAsync={this.props.onSave}
                        text={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.Save}
                        automationId="saveButton"
                    />
                    {!this.props.isReadonly && this.canDelete && <Ui.Button iconName="trash"
                        disabled={false}
                        visualStyle="negative-standard"
                        text={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.Delete}
                        automationId="deleteButton"
                        onClickAsync={this.props.onDeleteAsync}
                    />}
                </Ui.Flex.Item>
                <Ui.Flex.Item grow>
                    {!this.props.report?.isMutable && <HisUi.LockIndicatorComponent
                        locked={!this.props.report?.isMutable}
                        lockedBy={this.props.report?.lockInfo?.preventingLockUserId}
                        style={{ alignContent: "right" }}
                        onEditClickedAsync={this.props.onReloadAsync}
                        permissionCheckOperationNames="Save"
                    />}
                </Ui.Flex.Item>
            </>
        );
    }

    @State.bound
    private renderOnsetForm() {
        const recordingPointOfCareIdFilter: FilterBase[] = this.props.report.recordingPointOfCareId
            ? [new ExplicitIdentifierFilter("PointOfCare", this.props.report.recordingPointOfCareId.value)]
            : [];
            
        return (
            <>
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <Ui.UniversalEnumSelector
                            enumName={"ContagiousPatientReportType"}
                            enumOrigin="server"
                            enumType={ContagiousPatientReportType}
                            displayMode="groupedRadioButtons"
                            automationId="reportTypeEnumSelector"
                            value={this.props.report.type}
                            onChange={this.props.onReportTypeChange}
                            label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.Type}
                            propertyIdentifier="Type"
                            disabled={this.props.reportTypeSelectorDisabled}
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={8}>
                        <CheckBoxGroup
                            verticalAlign="inlineInput"
                            store={this.actionsToCheckBoxGroupItems}
                            onChange={this.props.onActionChange}
                            getDisplayValue={this.getActionDisplayValue}
                            automationId="actionsCheckBoxGroup"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        <ConditionCodeSelector
                            label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.Condition}
                            automationId="conditionCodeSelector"
                            isReadOnly
                            value={this.props.report.conditionId}
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.GroupBox
                    automationId="ContagiousPatientReportTypeSelectBox"
                    title={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.RecordingPlaceAndTime}
                >
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <SettlementAndZipCodeSelector
                                countryId={this.onsetData.onsetLocation.countryId}
                                settlement={this.onsetData.onsetLocation.settlement}
                                setSettlement={this.onsetData.onsetLocation.setSettlement}
                                zipCode={this.onsetData.onsetLocation.zipCode}
                                setZipCode={this.onsetData.onsetLocation.setZipCode}
                                automationIdPrefix="onsetLocationSettlementAndZipCodeSelector"
                                propertyIdentifierPrefix="Item.OnsetReportData.OnsetLocation"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <Ui.TextBox
                                label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.AddressLine}
                                value={this.onsetData.onsetLocation.addressLine}
                                onChange={this.onsetData.onsetLocation.setAddressLine}
                                automationId="onsetLocationAddressLineTextBox"
                                propertyIdentifier="Item.OnsetReportData.OnsetLocation.AddressLine"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            <Ui.DatePicker
                                label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.OnsetDate}
                                value={this.onsetData.onsetDate}
                                onChange={this.onsetData.setOnsetDate}
                                automationId="onsetLocationDateDatePicker"
                                propertyIdentifier="Item.OnsetReportData.OnsetDate"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.GroupBox>
                <Ui.GroupBox>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <NursingLocationTypeSelectBox
                                label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.OnsetLocation}
                                value={this.onsetData.nursingLocationTypeId}
                                onChange={this.onsetData.setNursingLocationType}
                                automationId="onsetNursingLocationEnumSelectBox"
                                propertyIdentifier="Item.OnsetReportData.NursingLocationTypeId"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <Ui.DatePicker
                                label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.HospitalizationDate}
                                value={this.onsetData.hospitalizationDate}
                                onChange={this.onsetData.setHospitalizationDate}
                                automationId="hospitalizationDateDatePicker"
                                propertyIdentifier="Item.OnsetReportData.HospitalizationDate"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            <EhiHealthcareProviderSelector
                                label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.HospitalId}
                                value={this.onsetData.hospitalId}
                                onChange={this.onsetData.setHospitalId}
                                automationId="hospitalCodeSelector"
                                propertyIdentifier="Item.OnsetReportData.HospitalId"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    {this.props.report.type === ContagiousPatientReportType.EndReport && this.renderEndReportForm()}
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <PointOfCareSelectBox
                                label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.RecordingPointOfCare}
                                value={this.props.report.recordingPointOfCareId}
                                automationId="recordingPointOfCareSelectBox"
                                isReadOnly
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            <HisUi.DoctorCodeSelector
                                label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.RecordingPractitioner}
                                automationId="practitionerCodeSelector"
                                value={this.onsetData.recordingPractitionerId}
                                onChange={this.onsetData.setRecordingPractitionerId}
                                filters={recordingPointOfCareIdFilter}
                                propertyIdentifier="Item.OnsetReportData.RecordingPractitionerId"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.GroupBox>
                <Ui.GroupBox title={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.WorkplaceData}>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <OccupationSelectBox
                                label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.Occupation}
                                value={this.onsetData.occupationId}
                                onChange={this.onsetData.setOccupationId}
                                automationId="occupationSelectBox"
                                propertyIdentifier="Item.OnsetReportData.OccupationId"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            <Ui.TextBox
                                label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.WorkplaceName}
                                value={this.onsetData.workPlaceName}
                                onChange={this.onsetData.setWorkplaceName}
                                automationId="workplaceNameTextBox"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <SettlementAndZipCodeSelector
                                countryId={this.onsetData.workPlaceLocation.countryId}
                                settlement={this.onsetData.workPlaceLocation.settlement}
                                setSettlement={this.onsetData.workPlaceLocation.setSettlement}
                                zipCode={this.onsetData.workPlaceLocation.zipCode}
                                setZipCode={this.onsetData.workPlaceLocation.setZipCode}
                                automationIdPrefix="workplaceLocationSettlementAndZipCodeSelector"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <Ui.TextBox
                                label={StaticHunSocialSecurityCareResources.ContagiousPatientReport.DetailPanel.WorkplaceAddressLine}
                                value={this.onsetData.workPlaceLocation.addressLine}
                                onChange={this.onsetData.workPlaceLocation.setAddressLine}
                                automationId="workplaceLocationAddressLineTextBox"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.GroupBox>
            </>
        );
    }
    public render() {
        return (
            <UseCaseFrame toolbar={this.renderDetailToolbar()}>
                {(this.props.report && !this.props.isLoading) ?
                    <ReadOnlyContext.Provider value={this.props.isReadonly}>
                        <ValidationBoundary
                            validateOnMount
                            validationResults={this.props.report.validationResults}
                            onValidateAsync={this.props.onValidateAsync}
                            entityTypeName={"ContagiousPatientReport"}
                        >
                            <EhiErrorSummary errors={this.props.report.ehiErrors} />
                            {this.renderOnsetForm()}
                        </ValidationBoundary>
                    </ReadOnlyContext.Provider> : <></>}
            </UseCaseFrame>
        );
    }
}

export default connect(
    ContagiousPatientReportDetailPanelView,
    new DependencyAdapter<IContagiousPatientReportDetailPanelViewProps, IContagiousPatientReportDetailPanelViewDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService")
    }))
);
