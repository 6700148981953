import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class TemporaryPrescriptionPermission {
    @State.observable.ref public validTo: LocalDate;
    @State.observable.ref public validFrom: LocalDate;
    @State.observable.ref public permissionIdentifier: string;

    @State.action.bound
    public setValidTo(validTo: LocalDate) {
        this.validTo = validTo;
    }

    @State.action.bound
    public setValidFrom(validFrom: LocalDate) {
        this.validFrom = validFrom;
    }

    @State.action.bound
    public setPermissionIdentifier(permissionIdentifier: string) {
        this.permissionIdentifier = permissionIdentifier;
    }
}