import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import NotificationHandler from "@Toolkit/CommonWeb/Notifications/NotificationHandler";
import CoverageCheckResultNotification from "./CoverageCheckResultNotification";

interface ICoverageEligibilityCheckResultNotificationHandlerProps {
    onMessageReceived: (notification: CoverageCheckResultNotification) => void;
}

@State.observer
export default class CoverageEligibilityCheckResultNotificationHandler extends React.Component<ICoverageEligibilityCheckResultNotificationHandlerProps> {

    public render() {
        return (
            <NotificationHandler groupId="CoverageEligibilityCheckNotificationGroup" onReceiveMessage={this.onReceiveMessage} />
        );
    }

    @State.bound
    private onReceiveMessage(message: any) {
        const notification = CoverageCheckResultNotification.fromJS(message);
        this.props.onMessageReceived(notification);
    }

}