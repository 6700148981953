import React from "react";
import * as Ui from "@CommonControls";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import OncologyDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/OncologyDataStore";
import OncologyRegisterExaminationStateSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/OncologyRegisterExaminationStateSelectBox";
import OncologyRegisterFurtherTreatmentSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/OncologyRegisterFurtherTreatmentSelectBox";
import CareActivityMedicalServiceMultiSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/CareActivityMedicalServiceMultiSelectBox";
import OncologyRegisterTumorStatusSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/OncologyRegisterTumorStatusSelectBox";
import CancerClassificationDataSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/CancerClassificationDataSelectBox";
import OncologyRegisterExtensionSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/OncologyRegisterExtensionSelectBox";
import OncologyRegisterLateralitySelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/OncologyRegisterLateralitySelectBox";
import ConditionCodeSelector from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ConditionCodeSelector";
import IConditionVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IConditionVersion";
import CareActivityId from "@Primitives/CareActivityId.g";
import CancerClassificationDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CancerClassificationDataStore";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import IncludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierSystemIdFilter";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";

interface IOncologyDetailsPanelProps {
    dataStore: OncologyDataStore;
    condition: IConditionVersion;
    careActivityId: CareActivityId;
    cancerClassificationDataStore: CancerClassificationDataStore;
    cancerClassificationStoreLabel: string;
    morphologyIdentifierSystemId: IdentifierSystemId;
    isReadOnly?: boolean;
    medicalServiceIdFilter: MedicalServiceId[];
}

/** @screen */

const OncologyDetailsPanel: React.FC<IOncologyDetailsPanelProps> = (props) => {
    const filters: FilterBase[] = [new IncludeIdentifierSystemIdFilter(props.morphologyIdentifierSystemId)];

    return (
        <ReadOnlyContext.Provider value={props.isReadOnly || false}>
            <Ui.GroupBox title={StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.BaseData}>
                <div style={{ marginLeft: "4px" }}>
                    {StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.Diagnosis}: <b>{props.condition?.code}</b> {props.condition?.name}
                </div>
                <Ui.Flex>
                    <Ui.Flex.Item sm={6}>
                        <Ui.DatePicker
                            value={props.dataStore.dateOfDiagnosis}
                            onChange={props.dataStore.setDateOfDiagnosis}
                            label={StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.DateOfDiagnosis}
                            propertyIdentifier="DateOfDiagnosis"
                            automationId="dateOfDiagnosis"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={6}>
                        <OncologyRegisterExaminationStateSelectBox
                            value={props.dataStore.oncologyRegisterExaminationModeId}
                            onChange={props.dataStore.setExaminationModeId}
                            label={StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.ExaminationMode}
                            propertyIdentifier="OncologyRegisterExaminationModeId"
                            automationId="oncologyRegisterExaminationModeId"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>
            <Ui.GroupBox title={StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.TreatmentData}>
                <Ui.Flex>
                    <Ui.Flex.Item sm={6}>
                        <OncologyRegisterFurtherTreatmentSelectBox
                            value={props.dataStore.oncologyRegisterFurtherTreatmentId}
                            onChange={props.dataStore.setFurtherTreatmentId}
                            label={StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.FurtherTreatment}
                            propertyIdentifier="OncologyRegisterFurtherTreatmentId"
                            automationId="oncologyRegisterFurtherTreatmentId"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <CareActivityMedicalServiceMultiSelectBox
                    filterIdSet={props.medicalServiceIdFilter}
                    selectedIds={props.dataStore.relatedMedicalServices}
                    onChange={props.dataStore.setRelatedMedicalServices}
                    careActivityId={props.careActivityId}
                    hidePager
                    automationId="relatedMedicalServices"
                />
            </Ui.GroupBox>
            <Ui.GroupBox title={StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.TumorStatus}>
                <Ui.Flex>
                    <Ui.Flex.Item sm={6}>
                        <OncologyRegisterTumorStatusSelectBox value={props.dataStore.oncologyRegisterTumorStatusId}
                            onChange={props.dataStore.setOncologyRegisterTumorStatusId}
                            label={StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.State}
                            propertyIdentifier="OncologyRegisterTumorStatusId"
                            automationId="oncologyRegisterTumorStatusId"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={6}>
                        <div style={{ marginTop: "16px", marginLeft: "4px" }}>
                            {StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.TnmLabel}: <b>{props.cancerClassificationStoreLabel}</b>
                        </div>
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item sm={6}>
                        <Ui.DatePicker
                            value={props.dataStore.dateOfStatus}
                            onChange={props.dataStore.setDateOfStatus}
                            label={StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.DateOfStatus}
                            propertyIdentifier={"StatusDate"}
                            automationId="dateOfStatus"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={6}>
                        <CancerClassificationDataSelectBox allItems={props.cancerClassificationDataStore.tumorList}
                            value={props.dataStore.cancerClassificationTumorId}
                            onChange={props.dataStore.setCancerClassificationTumorId}
                            label={StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.TnmTumor}
                            automationId="cancerClassificationTumorId"
                        />
                        <></>
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item sm={6}>
                        <OncologyRegisterExtensionSelectBox value={props.dataStore.oncologyRegisterExtensionId}
                            onChange={props.dataStore.setOncologyRegisterExtensionId}
                            label={StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.Extension}
                            automationId="oncologyRegisterExtensionId" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={6}>
                        <CancerClassificationDataSelectBox allItems={props.cancerClassificationDataStore.nodeList}
                            value={props.dataStore.cancerClassificationNodeId}
                            onChange={props.dataStore.setCancerClassificationNodeId}
                            label={StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.TnmNode}
                            automationId="cancerClassificationNodeId"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item sm={6}>
                        <OncologyRegisterLateralitySelectBox value={props.dataStore.oncologyRegisterLateralityId}
                            onChange={props.dataStore.setOncologyRegisterLateralityId}
                            label={StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.Laterality}
                            propertyIdentifier="OncologyRegisterLateralityId"
                            automationId="oncologyRegisterLateralityId"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={6}>
                        <CancerClassificationDataSelectBox allItems={props.cancerClassificationDataStore.metastasisList}
                            value={props.dataStore.cancerClassificationMetastasisId}
                            onChange={props.dataStore.setCancerClassificationMetastasisId}
                            label={StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.TnmMetastasis}
                            automationId="cancerClassificationMetastasisId"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item sm={6}>
                        <></>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={6}>
                        <ConditionCodeSelector value={props.dataStore.morphology}
                            onChange={props.dataStore.setMorphology}
                            label={StaticHunEHealthInfrastructureCareResources.OncologyData.Labels.Morphology}
                            filters={filters}
                            automationId="morphology"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>
        </ReadOnlyContext.Provider>
    );
};

export default State.observer(OncologyDetailsPanel);