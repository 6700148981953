export default class MedicationNameColumnData {
    constructor(
        public readonly name: string,
        public readonly isDeleted: boolean,
        public readonly modificationType: string,
        public readonly pharmacyType: string,
        public readonly isValid: boolean
    ) {

    }
}