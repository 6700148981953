import DependencyContainer from "@DiContainer";
import ServiceRequestManagementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestManagementApiAdapter";
import ServiceRequestsListStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestsListStoreMapper";
import ServiceRequestStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestStoreMapper";
import ServiceRequestDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestDocument/ServiceRequestDocumentApiAdapter";

DependencyContainer
    .bind("ServiceRequestManagementApiAdapter")
    .to(ServiceRequestManagementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("ServiceRequestsListStoreMapper")
    .to(ServiceRequestsListStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("ServiceRequestStoreMapper")
    .to(ServiceRequestStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("ServiceRequestDocumentApiAdapter")
    .to(ServiceRequestDocumentApiAdapter)
    .inSingletonScope();