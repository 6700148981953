import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import Di from "@Di";
import { ITemporaryDataClient, AddTemporaryDataControllerDto, TemporaryDataId, DeleteTemporaryDataControllerDto } from "@HisPlatform/BoundedContexts/Productivity/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import ITemporaryData from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/TemporaryData/ITemporaryData";

@Di.injectable()
export default class TemporaryDataApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("ITemporaryDataClient") private readonly temporaryDataClient: ITemporaryDataClient
    ) {
        super();
    }

    public addAsync(id: string, content: string) {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.temporaryDataClient.addTemporaryDataCommandAsync(
                    CreateRequestId(),
                    new AddTemporaryDataControllerDto({
                        id: new TemporaryDataId({ value: id }),
                        content
                    })
                );
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public getAsync(id: string) {
        return this.processOperationAsync(
            new SimpleStore<ITemporaryData>(),
            async target => {
                const response = await this.temporaryDataClient.getTemporaryDataQueryAsync(
                    CreateRequestId(),
                    id
                );
                target.operationInfo = createOperationInfo(response);
                target.value = {
                    id: response.id.value,
                    content: response.content,
                    createdAt: response.createdAt
                };
            }
        );
    }

    public deleteAsync(id: string) {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.temporaryDataClient.deleteTemporaryDataCommandAsync(
                    CreateRequestId(),
                    new DeleteTemporaryDataControllerDto({
                        id: new TemporaryDataId({ value: id })
                    })
                );
                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}
