import { IFileResponse } from "@Primitives/FileResponse";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import BusinessErrorOperationInfo from "./OperationInfo/BusinessErrorOperationInfo";
import TechnicalErrorOperationInfo from "./OperationInfo/TechnicalErrorOperationInfo";
import IStore from "@Toolkit/CommonWeb/Model/IStore";
import RequestStatus from "./OperationInfo/RequestStatus";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IOperationInfo from "./OperationInfo/IOperationInfo";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import ApiTechnicalError from "@Toolkit/CommonWeb/ApiAdapter/ApiTechnicalError";
import ApiBusinessError from "@Toolkit/CommonWeb/ApiAdapter/ApiBusinessError";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import { IFile } from "@Primitives/File";
import HttpStatusCode from "./HttpStatusCode";
import UnauthenticatedUserBusinessError from "@Toolkit/CommonWeb/Model/UnauthenticatedUserBusinessError";
import ServiceUnavailableError from "@Toolkit/CommonWeb/Model/ServiceUnavailableError";

type OperationEventHandler = (operationInfo: IOperationInfo, nextHandler: () => void) => void;

export default abstract class ApiAdapterBase {

    private operationEventStack: OperationEventHandler[] = [];
    private operationEvent = new TypedEvent<IOperationInfo>();
    private operationCompletionHook = new TypedAsyncEvent<IOperationInfo>();

    protected unwrapValueWrappers<T>(valueWrappers: Array<{ value?: T }>) {
        return valueWrappers.map((i) => i.value);
    }

    protected getFileResponseAsStringAsync(action: () => Promise<IFileResponse>, mediaType: string) {
        const fileReader = new FileReader();

        return new Promise<string>((resolve, reject) => {
            fileReader.onerror = () => {
                fileReader.abort();
                reject("Error while reading file");
            };

            fileReader.onload = () => {
                resolve(fileReader.result as string);
            };

            action()
                .then(response => {
                    fileReader.readAsText(response.data);
                })
                .catch(reject);
        });
    }

    protected async getFileResponseAsBlobAsync(action: () => Promise<IFileResponse>) {
        const response = await action();
        return response?.data;
    }

    protected async getFileResponseAsFileAsync(action: () => Promise<IFileResponse>): Promise<IFile> {
        const response = await action();
        return {
            data: response.data,
            fileName: response.fileName
        };
    }

    protected async getFileResponseAsByteArrayAsync(action: () => Promise<IFileResponse>) {
        const blob = await this.getFileResponseAsBlobAsync(action);
        const bytes = blob && await blob.toUint8ArrayAsync();
        return bytes;
    }

    @State.action
    protected async processOperationAsync<TTarget extends IStore>(
        target: TTarget,
        fetchAndMapToTargetAsync: (target?: TTarget) => Promise<void>,
    ): Promise<TTarget> {

        try {
            await fetchAndMapToTargetAsync(target);
        } catch (error) {

            if ("businessError" in error) {

                this.setOperationInfo(target,
                    error.businessError ? new BusinessErrorOperationInfo(error.businessError) :
                        { businessError: { name: "unknown" }, hasTechnicalError: false, requestStatus: RequestStatus.BusinessError, isPersisted: false }
                );

                throw new ApiBusinessError(error.businessError._discriminator, error.businessError);

            } else if ("status" in error && error.status === HttpStatusCode.InternalServerError) {

                this.setOperationInfo(target, new TechnicalErrorOperationInfo());
                throw new ApiTechnicalError(error.message);

            } else if ("status" in error && error.status === HttpStatusCode.Unauthorized) {

                this.setOperationInfo(target, new BusinessErrorOperationInfo(new UnauthenticatedUserBusinessError()));
                throw new ApiBusinessError(UnauthenticatedUserBusinessError.businessErrorName, new UnauthenticatedUserBusinessError());

            } else if ("status" in error && error.status === HttpStatusCode.ServiceUnavailable) {
                
                this.setOperationInfo(target, new BusinessErrorOperationInfo(new ServiceUnavailableError()));
                throw new ApiBusinessError(ServiceUnavailableError.businessErrorName, new ServiceUnavailableError());

            } else {
                throw error;
            }
        }

        if (target.operationInfo === undefined || target.operationInfo === null) {
            throw new Error("processOperationAsync: target.operationInfo cannot be null or undefined");
        }

        await this.operationCompletionHook.emitAsync(target.operationInfo);
        this.emitOperationEvent(target.operationInfo);

        return target;
    }

    @State.action
    private setOperationInfo(store: IStore, operationInfo: IOperationInfo) {
        store.operationInfo = operationInfo;
        this.emitOperationEvent(operationInfo);
    }

    private emitOperationEvent(operationInfo: IOperationInfo, index: number = 0) {
        const handler = this.operationEventStack.length > index && this.operationEventStack[index];
        if (handler) {
            handler(operationInfo, () => this.emitOperationEvent(operationInfo, index + 1));
        }
        this.operationEvent.emit(operationInfo);
    }

    public registerOperationEventHandler(handler: OperationEventHandler): IDisposable {
        this.operationEventStack.unshift(handler);
        return {
            dispose: () => {
                const index = this.operationEventStack.indexOf(handler);
                if (index > -1) {
                    this.operationEventStack.splice(index, 1);
                }
            }
        };
    }

    public registerOperationEventWatcher(watcher: (operationInfo: IOperationInfo) => void) {
        return this.operationEvent.on(watcher);
    }

    public registerOperationCompletionHook(hook: (operationInfo: IOperationInfo) => Promise<void>) {
        return this.operationCompletionHook.on(hook);
    }

    protected loadExtensibleEnumAsync<TExtEnumId, TResponse>(
        apiClientOperation: (requestId: string, validOn: string, isPermissionCheckOnly?: boolean) => Promise<TResponse>,
        idAccessor: (response: TResponse) => any[]
    ) {
        return this.processOperationAsync(
            new SimpleStore<TExtEnumId[]>(),
            async target => {
                const response = await apiClientOperation(CreateRequestId(), "");
                target.operationInfo = createOperationInfo(response);
                target.value = idAccessor(response);
            }
        );
    }
}