import {ValueConverter} from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ValueConverter";
import NameStore from "@Primitives/NameStore";

export default {
    PersonName: rawValue => NameStore.create({
        familyName: rawValue.FamilyName,
        givenName1: rawValue.GivenName1,
        givenName2: rawValue.GivenName2,
        postfix: rawValue.Postfix,
        prefix: rawValue.Prefix
    })
} as ValueConverter;
