import RouteDefinition from "./RouteDefinition";

export function createNestedRoutes<T extends { [id: string]: RouteDefinition<any> }>(parentRoute: RouteDefinition<any>, routes: T): T {

    parentRoute.setHasChild();

    return Object.getOwnPropertyNames(routes).reduce((ret, key) => {
        ret[key] = new RouteDefinition(parentRoute.pathname + routes[key].pathname, parentRoute);
        return ret;
    }, {} as { [id: string]: RouteDefinition<any> }) as T;

}