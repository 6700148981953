import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import { ITreeGridFooterProps } from "./ITreeGridProps";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import Styles from "./TreeGrid.less";
import * as Ui from "@CommonControls";

interface ITreeGridDefaultFooterDependencies {
    toolkitLocalizationService: IToolkitLocalizationService;
}

type ITreeGridDefaultFooterProps = ITreeGridFooterProps & { _dependencies?: ITreeGridDefaultFooterDependencies };


const TreeGridDefaultFooter: React.FC<ITreeGridDefaultFooterProps> = props => (
    <tr>
        <td colSpan={props.colSpan} className={Styles.defaultFooter}>
            <Ui.Flex
                xsJustify={props.hasBackButton ? "between" : "end"}
                spacing="none"
                innerSpacing="none"
                outerSpacing="none"
                verticalSpacing="none">
                {props.hasBackButton && <Ui.Flex.Item>
                    <Ui.Button
                        size="compact"
                        text={props.backButtonText || props._dependencies.toolkitLocalizationService.staticResources.button.back}
                        visualStyle="link" onClick={props.onBack}
                        automationId="treeGridBackButton" />
                </Ui.Flex.Item>}
            </Ui.Flex>
        </td>
    </tr>
);

export default connect(
    TreeGridDefaultFooter,
    new DependencyAdapter<ITreeGridDefaultFooterProps, ITreeGridDefaultFooterDependencies>(c => ({
        toolkitLocalizationService: c.resolve("IToolkitLocalizationService")
    }))
);