// tslint:disable:variable-name
import DocumentListScreenStore from "./DocumentListScreenStore";
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import { editorScreenRenderFunc } from "@Toolkit/CommonWeb/PanelStore/EditorScreenRenderFunc";

const {
    ContextComponent: DocumentListScreenStoreContext, 
    StoreProvider: DocumentListScreenStoreProvider, 
    useStore: useDocumentListScreenStore,
    provideStore: provideDocumentListScreenStore,
} = createPanelStoreProvider<DocumentListScreenStore>(DocumentListScreenStore, null, editorScreenRenderFunc);

export {
    DocumentListScreenStoreContext,
    DocumentListScreenStoreProvider,
    useDocumentListScreenStore,
    provideDocumentListScreenStore
};