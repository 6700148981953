import { useFormEntityIdField, useFormTextField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { ICustomBlockRegistryItem, IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormLayoutContext } from "@Toolkit/FormEngine/Panels/FormLayoutContext";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import FinancedServiceCodeSelector from "@HisPlatform/Components/HisPlatformControls/FinancedServiceCodeSelector/FinancedServiceCodeSelector";
import { FinancedServiceCodeSelectorItem } from "@HisPlatform/Components/HisPlatformControls/FinancedServiceCodeSelector/FinancedServiceCodeSelectorItem";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import { getCompositeArrayFields, getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";

interface IFinancedServiceCodeSelectorFormCustomBlockProps extends IFormCustomBlockComponentProps {
}

function _FinancedServiceCodeSelectorFormCustomBlock(props: IFinancedServiceCodeSelectorFormCustomBlockProps) {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext.compositeDataReferences?.join(".");

    const fields = getCompositeArrayFields(props.form.data.Content, compositeFieldName);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        addFinancedServiceCodeSelectorFormCustomBlock(props.form.data.Content, fields, compositeFieldName);
        setIsLoaded(true);
    }, []);

    return isLoaded && (
        <FinancedServiceCodeSelectorFormCustomBlockCore {...props} />
    );
}

const FinancedServiceCodeSelectorFormCustomBlockCore = State.observer((props: IFinancedServiceCodeSelectorFormCustomBlockProps) => {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext?.compositeDataReferences?.join(".");
    const resolveEntityPath = useCallback((relativePath: string): string => {
        return isNullOrUndefined(compositeFieldName)
            ? relativePath
            : `${compositeFieldName}.${relativePath}`;
    }, []);

    const [financedServiceId, setFinancedServiceId] = useFormEntityIdField<FinancedServiceId>(props.form.data.Content, resolveEntityPath("FinancedServiceId"), FinancedServiceId);
    const [financedServiceName, setFinancedServiceName] = useFormTextField(props.form.data.Content, resolveEntityPath("FinancedServiceName"));

    const value = useMemo(() => new FinancedServiceCodeSelectorItem({
        id: financedServiceId,
        name: financedServiceName
    }), [financedServiceId, financedServiceName]);

    const valueSetter = useCallback(State.action((newValue: FinancedServiceCodeSelectorItem | FinancedServiceCodeSelectorItem[]) => {
        if (!newValue || Array.isArray(newValue)) {
            setFinancedServiceId(null);
            setFinancedServiceName("");
            return;
        }
        if (newValue.id) {
            setFinancedServiceId(newValue.id);
        }
        if (newValue.name) {
            setFinancedServiceName(newValue.name);
        }
    }), [setFinancedServiceId, setFinancedServiceName]);

    return (
        <FinancedServiceCodeSelector
            label={StaticFinanceResources.InvoiceScreen.InvoiceItems.FinancedServiceCodeSelector.Title}
            onChange={valueSetter}
            value={value}
            automationId="financedServiceCodeSelector"
            propertyIdentifier="FinancedServiceName"
            style={{ width: '100%' }}
        />
    );
});

export function addFinancedServiceCodeSelectorFormCustomBlock(formFields: FormFieldDataBase[], array: IObservableArray<FormFieldDataBase>, compositeFieldName: string) {
    State.runInAction(() => {
        const financedServiceId = getField<ReferencedEntityFormFieldData>(formFields, isNullOrEmptyString(compositeFieldName) ? "FinancedServiceId" : compositeFieldName + ".FinancedServiceId");
        if (!financedServiceId) {
            const newFinancedServiceId = new ReferencedEntityFormFieldData("FinancedServiceId", false, true, null, null);

            if (array) {
                array.push(newFinancedServiceId);
            } else {
                formFields.push(newFinancedServiceId);
            }
        }

        const settlementField = getField<TextFormFieldData>(formFields, isNullOrEmptyString(compositeFieldName) ? "FinancedServiceName" : compositeFieldName + ".FinancedServiceName");
        if (!settlementField) {
            const newSettlementField = new TextFormFieldData("FinancedServiceName", false, true, null);

            if (array) {
                array.push(newSettlementField);
            } else {
                formFields.push(newSettlementField);
            }
        }
    });
}

const FinancedServiceCodeSelectorFormCustomBlock = connect(_FinancedServiceCodeSelectorFormCustomBlock);

export const FinancedServiceCodeSelectorRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Financed service code selector",
    dataElementsFactory: () => [],
    componentType: State.observer(FinancedServiceCodeSelectorFormCustomBlock)
};

export default FinancedServiceCodeSelectorFormCustomBlock;
