import CareActivityTextBlock from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/CareActivityTextBlock";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import Di from "@Di";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import {formatStringWithObjectParams} from "@Toolkit/CommonWeb/Formatters";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import {IModalService} from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import ShowCareActivityTextBlockScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityTextBlockScreenAction.g";
import {ICareActivityTextBlockScreenProps} from "@HisPlatform/Packages/Care/Screens/CareActivityTextBlockScreen/CareActivityTextBlockScreen";
import _ from "@HisPlatform/Common/Lodash";
import PatientId from "@Primitives/PatientId.g";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import {TypedAsyncEvent} from "@Toolkit/CommonWeb/TypedAsyncEvent";
import TextBlockHistoryDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/TextBlockHistoryDialog/TextBlockHistoryDialogParams";
import IDocumentFragment from "@CommonControls/DocumentEditor/IDocumentFragment";
import PreviousTextBlocksDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/PreviousTextBlocksDialog/PreviousTextBlocksDialogParams";
import InsertServiceRequestResultDialogParams, {IInsertServiceRequestResultDialogResult} from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/InsertServiceRequestReuslt/InsertServiceRequestResultDialogParams";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import DocumentSnippetSelectorDialogParams, {IDocumentSnippetSelectorDialogResult} from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/DocumentSnippetSelectorDialog/DocumentSnippetSelectorDialogParams";
import AutomaticInsertTextBlockContentDialogParams, {IAutomaticInsertTextBlockContentDialogResult} from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/AutomaticInsertTextBlockContentDialog/AutomaticInsertTextBlockContentDialogParams";
import CareActivityTextBlockScreenApiAdapter from "./CareActivityTextBlockScreenApiAdapter";
import IAutomaticFillDocumentContent from "./IAutomaticFillDocumentContent";
import LockAcquirerOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockAcquirerOperationInfo";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import IOperationResult from "@Toolkit/CommonWeb/ApiAdapter/IOperationResult";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";

@Di.injectable()
export default class CareActivityTextBlockScreenStore extends EditorScreenPanelStoreBase<ICareActivityTextBlockScreenProps> implements ILoadablePanelStore {
    @State.observable.ref public textBlock: CareActivityTextBlock = null;
    public readonly successfullySavedEvent = new TypedAsyncEvent();
    public readonly leaveWithoutSavingEvent = new TypedAsyncEvent();

    protected saveFunction: () => Promise<boolean> = this.async(() => this.saveAsync(false));

    protected onNavigatingAwayCallback: (isSaved: boolean) => Promise<void> = this.async(
        (isSaved) => isSaved ? this.successfullySavedEvent.emitAsync() : this.leaveWithoutSavingEvent.emitAsync()
    );

    private readonly saveAsync: (releaseLock: boolean) => Promise<boolean> = this.async(async (releaseLock) => {
        const isNewTextBlock = this.textBlock.isNew;
        let hasError = false;

        // if (isNewTextBlock || this.dirtyChecker.isDirty(this.contentToDirtyCheck)) {
        //    const response = await this.handleSaveResultAsync(() => this.saveTextBlockAsync(releaseLock));
        //     hasError = !response.result.isPersistedByOperationInfo && response.result.hasValidationError;
        //     this.setTextBlock(response.result);
        // } else {
        //     this.notificationService.showSaveResult(true, false);
        // }

        const response = await this.handleSaveResultAsync(() => this.saveTextBlockAsync(releaseLock));
        hasError = !response.result.isPersistedByOperationInfo && response.result.hasValidationError;
        this.setTextBlock(response.result);

        if (!hasError) {
            await this.successfullySavedEvent.emitAsync();
            if (isNewTextBlock && this.showScreenAction.displayMode !== ScreenDisplayMode.Full) {
                this.props._screenState.savedNew(this.textBlock.id, this.showScreenAction);
            } else {
                this.props._screenState.savedExisting();
            }
        }

        return !hasError;
    });

    private readonly forceReleaseLockAsync: () => Promise<void> = this.async(async () => {
        if (this.textBlock?.lockInfo?.preventingLockId) {
            await this.lockingApiAdapter.forceReleaseLockAsync(this.textBlock.lockInfo.preventingLockId);
        }
    });

    private readonly loadReferenceDataAsync: () => Promise<void> = this.async(async () => {
        await this.careReferenceDataStore.textBlockType.ensureLoadedAsync();
        await this.userManagementReferenceDataStore.users.ensureAllLoadedAsync();
    });

    private readonly reloadAsync: (forceReleaseLock: boolean) => Promise<void> = this.async(async (forceReleaseLock) => {
        await this.loadReferenceDataAsync();

        if (forceReleaseLock) {
            await this.forceReleaseLockAsync();
        }

        if (this.textBlockTypeId) {
            const response = await this.apiAdapter.getCareActivityTextBlockAsync(this.careActivityId, this.textBlockTypeId, this.props._careActivityState !== CareActivityState.Closed);
            this.setTextBlock(response.result.textBlock);
            if (response.operationInfo instanceof LockAcquirerOperationInfo) {
                State.runInAction(() => this.lockInfo = (response.operationInfo as LockAcquirerOperationInfo).lockInfo);
            }

            const contentToDirtyCheck = await this.textBlock?.contentStore.getContentAsync();
            this.dirtyChecker.takeSnapshot([contentToDirtyCheck]);

            if (!this.vIsReadOnly) {
                await this.tryShowPreviousContentOfTextBlockAsync(response.result.automaticFillDocumentContent);
            }
        }
    });

    private readonly tryShowPreviousContentOfTextBlockAsync: (previousTextBlock: IAutomaticFillDocumentContent) => Promise<void> = this.async(async (previousTextBlock) => {
        if (this.textBlock?.isMutable !== true
            || isNullOrUndefined(previousTextBlock?.lastModifiedAt)
            || isNullOrUndefined(previousTextBlock.lastModifiedBy)
            || isNullOrUndefined(previousTextBlock.pointOfCareName)) {
                return;
        }

        const result = await this.modalService.showDialogAsync<IAutomaticInsertTextBlockContentDialogResult>(new AutomaticInsertTextBlockContentDialogParams(previousTextBlock, this.textBlockTypeId));
        if (!isNullOrUndefined(result)) {
            const fragment = result.actualContent;
            await this.textBlock.contentStore.appendFragmentAsync(fragment);
        }
    });

    private readonly saveTextBlockAsync: (releaseLock: boolean) => Promise<IOperationResult<CareActivityTextBlock>> = this.async(async (releaseLock) => {
        const result = this.textBlock.isNew
            ? await this.apiAdapter.createCareActivityTextBlockAsync(this.textBlock, this.lockInfo?.lockId, !releaseLock)
            : await this.apiAdapter.saveCareActivityTextBlockAsync(this.textBlock, releaseLock);
        if (result.operationInfo instanceof LockAcquirerOperationInfo) {
            State.runInAction(() => this.lockInfo = (result.operationInfo as LockAcquirerOperationInfo).lockInfo);
        }

        return result;
    });

    @State.computed
    public get careActivityId(): CareActivityId {
        return this.props.action.careActivityId;
    }

    @State.computed
    public get isSaveable(): boolean {
        return this.props.action instanceof ShowCareActivityTextBlockScreenAction
               && !!this.textBlock;
    }

    @State.computed
    public get patientId(): PatientId {
        return this.props.action.patientId;
    }

    @State.computed
    public get screenTitle(): string {
        return this.textBlockTypeId
            ? this.careReferenceDataStore.textBlockType.get(this.textBlockTypeId)?.displayValue?.Name
            : "";
    }

    @State.computed
    public get screenSubtitle(): string {
        if (this.textBlock?.lastModifiedAt && this.textBlock?.lastModifiedBy) {
            const user = this.userManagementReferenceDataStore.users.get(this.textBlock.lastModifiedBy);

            return formatStringWithObjectParams(StaticCareResources.CareRegister.CareActivityTextBlockListPanel.LastModifiedTemplate, {
                Name: user.displayName,
                LastModifiedAt: this.localizationService.localizeDateTime(this.textBlock.lastModifiedAt)
            });
        }
        return undefined;
    }

    @State.computed
    public get temporaryDataId() {
        return `${this.userContext.id.value}_${this.patientId ? this.patientId.value : ""}_${this.careActivityId ? this.careActivityId.value : ""}_${this.screenTitle}`;
    }

    @State.computed
    public get textBlockTypeId(): TextBlockTypeId {
        return this.props.action.textBlockTypeId;
    }

    @State.computed
    public get validationProblems() {
        return _.flatten(this.textBlock?.validationResults?.map(i => i.problems));
    }

    @State.computed
    public get vIsReadOnly(): boolean {
        return this.textBlock?.isMutable !== true || this.canAcquireLock;
    }

    @State.computed
    protected get contentToDirtyCheck(): any[] {
        return this.vIsReadOnly ? [] : [this.textBlock?.contentStore.getContent()];
    }

    @State.computed
    protected get showScreenAction(): ShowCareActivityTextBlockScreenAction {
        return this.props.action;
    }

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("CareActivityTextBlockScreenApiAdapter") public readonly apiAdapter: CareActivityTextBlockScreenApiAdapter,
        @Di.inject("CareReferenceDataStore") public readonly careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("IModalService") public readonly modalService: IModalService,
        @Di.inject("UserContext") public readonly userContext: UserContext,
        @Di.inject("UserManagementDataProviderStore") public readonly userManagementReferenceDataStore: UserManagementDataProviderStore) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    public getReloadTriggerProps(props: ICareActivityTextBlockScreenProps) {
        return [props.action];
    }

    public loadCoreAsync(): Promise<any> {
        return this.reloadAsync(false);
    }

    @State.bound
    public async onForceReleaseLockAsync() {
        await this.reloadAsync(true);
    }

    @State.bound
    public async saveWithoutReleaseLockAsync() {
        return await this.saveAsync(false);
    }

    @State.bound
    public async showPreviousTextBlocksDialogAsync(): Promise<any> {
        const result = await this.modalService.showDialogAsync<IDocumentFragment>(new PreviousTextBlocksDialogParams(this.textBlock.textBlockTypeId, this.careActivityId, this.patientId));

        if (result != null) {
            await this.textBlock.contentStore.appendFragmentAsync(result);
        }
    }

    @State.bound
    public async showServiceRequestInsertDialogAsync(): Promise<any> {
        const result = await this.modalService.showDialogAsync<IInsertServiceRequestResultDialogResult>(new InsertServiceRequestResultDialogParams(this.careActivityId, "modal"));
        if (!isNullOrUndefined(result)) {
            const fragment = result.selected;
            await this.textBlock.contentStore.appendFragmentAsync(fragment);
        }
    }

    @State.bound
    public async showSnippetSelectorDialogAsync(): Promise<any> {
        const result = await this.modalService.showDialogAsync<IDocumentSnippetSelectorDialogResult>(new DocumentSnippetSelectorDialogParams("modal", this.textBlock.textBlockTypeId));
        if (!isNullOrUndefined(result)) {
            const fragment = result.selected;
            await this.textBlock.contentStore.appendFragmentAsync(fragment);
        }
    }

    @State.bound
    public showTextBlockHistoryDialogAsync(): Promise<any> {
        return this.modalService.showDialogAsync(new TextBlockHistoryDialogParams(this.textBlock.id));
    }

    @State.action.bound
    private setTextBlock(textBlock: CareActivityTextBlock) {
        this.textBlock = textBlock;
    }
}