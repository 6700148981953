import Role from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/Role";
import * as Proxy from "@HisPlatform/BoundedContexts/Authorization/Api/Proxy.g";
import IPermissionDefinition from "@PluginInterface/OperationAccessControl/IPermissionDefinition";
import IPermissionScope from "@PluginInterface/OperationAccessControl/IPermissionScope";
import RoleId from "@Primitives/RoleId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";

export function getAddRoleDto(role: Role, isValidateOnly: boolean) {
    return {
        description: role.description,
        name: role.name,
        permissions: getPermissionDto(role.permissions.getPermissionsWithUnitedScopes()),
        shortName: role.shortName,
        isValidateOnly: isValidateOnly
    } as Proxy.AddRoleControllerDto;
}

export function getUpdateRoleDto(role: Role, isValidateOnly: boolean) {
    const dto = new Proxy.UpdateRoleControllerDto({
        role: new Proxy.RoleDto({
            id: role.id,
            description: role.description,
            name: role.name,
            permissions: getPermissionDto(role.permissions.getPermissionsWithUnitedScopes()),
            shortName: role.shortName,
            rowVersion: role.rowVersion,
        }),
        isValidateOnly: isValidateOnly
    });
    return dto;
}

export function getUpdateRolePermissionCheckDto() {
    return new Proxy.UpdateRoleControllerDto({
        role: new Proxy.RoleDto({
            id: new RoleId("-1"),
            rowVersion: new RowVersion(0),
            permissions: []
        })
    });
}

function getPermissionDto(permissions: IPermissionDefinition[]) {
    const res: Proxy.Permission[] = [];
    permissions.forEach(permission => {
        const scopes = permission.scopes.map(mapScope);
        const dto = new Proxy.Permission({
            id: permission.ids[0],
            scopes: scopes
        });

        res.push(dto);
    });

    return res;
}

function mapScope(permissionScope: IPermissionScope) {
    return new Proxy.PermissionScope({
        type: new Proxy.PermissionScopeType({ value: permissionScope.type }),
        values: new Proxy.SetSpecificationOfString({
            mode: permissionScope.mode === "positive" ? Proxy.SetSpecificationMode.Positive : Proxy.SetSpecificationMode.Negative,
            includedItems: permissionScope.mode === "positive" ? permissionScope.includedValues : null,
            excludedItems: permissionScope.mode === "negative" ? permissionScope.excludedValues : null,
        })
    });
}