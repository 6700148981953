import React from "react";
import * as Ui from "@CommonControls";
import OncologyDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/OncologyDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ConditionId from "@Primitives/ConditionId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import CareActivityId from "@Primitives/CareActivityId.g";
import CancerClassificationDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CancerClassificationDataStore";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import _ from "@HisPlatform/Common/Lodash";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import WellKnownReferenceCodes from "@HunEHealthInfrastructurePlugin/Common/WellKnownReferenceCodes";
import OncologyDetailsPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/DiagnosisExtension/DiagnosisExtension/OncologyDetailsPanel";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";

interface IOncologyPanelViewDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IOncologyPanelViewProps {
    _dependencies?: IOncologyPanelViewDependencies;
    dataStore: OncologyDataStore;
    conditionId: IEntityVersionSelector<ConditionId>;
    careActivityId: CareActivityId;
    cancerClassificationDataStore: CancerClassificationDataStore;
    cancerClassificationStoreLabel: string;
    validationResults: IClientValidationResult[];
    validateAsync: (dirtyFields?: string[]) => Promise<IClientValidationResult[]>;
    validationPathPrefix: string;
    readonly: boolean;
    medicalServiceIdFilter: MedicalServiceId[];
}


const OncologyPanelView: React.FC<IOncologyPanelViewProps> = (props) => {
    if (!props.dataStore) {
        return <></>;
    }
    const condition = props._dependencies.careReferenceDataStore.condition.get(props.conditionId);
    const morphologyIdentifierSystemId = new IdentifierSystemId(WellKnownReferenceCodes.MorphologyIdentifier);

    return (
        <ValidationBoundary validationResults={props.validationResults || []}
            entityTypeName={"DiagnosisList"}
            pathPrefix={props.validationPathPrefix}
            onValidateAsync={props.validateAsync}
            validateOnMount
        >
            <Ui.ValidationResultSummary excludedRuleIds="ShouldBeFilled" propertyPath={props.validationPathPrefix} results={_.flatten(props.validationResults?.map(i => i.problems)).filter(i => i.propertyPath?.includes(props.validationPathPrefix))} />
            <OncologyDetailsPanel
                cancerClassificationDataStore={props.cancerClassificationDataStore}
                cancerClassificationStoreLabel={props.cancerClassificationStoreLabel}
                careActivityId={props.careActivityId}
                medicalServiceIdFilter={props.medicalServiceIdFilter}
                condition={condition}
                morphologyIdentifierSystemId={morphologyIdentifierSystemId}
                dataStore={props.dataStore}
                isReadOnly={props.readonly}
            />
        </ValidationBoundary>
    );
};

export default connect(
    State.observer(OncologyPanelView),
    new DependencyAdapter<IOncologyPanelViewProps, IOncologyPanelViewDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore")
        };
    })
);
