import EpisodeOfCare from "@HisPlatform/Packages/Care/Model/EpisodeOfCare";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import Di from "@Di";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EpisodeOfCareDialogParams, { IEpisodeOfCareDialogResult } from "@HisPlatform/Packages/Care/Screens/EpisodeOfCareScreen/Modal/EpisodeOfCareDialogParams";
import { IEpisodeOfCareSelectBoxProps } from "./EpisodeOfCareSelectBox";
import EpisodeOfCareSelectBoxApiAdapter from "./EpisodeOfCareSelectBoxApiAdapter";

@Di.injectable()
export default class EpisodeOfCareSelectBoxStore extends PanelStoreBase<IEpisodeOfCareSelectBoxProps> {
    @State.observable.ref public selectableEpisodesOfCare: ISelectBoxItem[] = [];

    constructor(
        @Di.inject("EpisodeOfCareSelectBoxApiAdapter") private episodeOfCareSelectBoxApiAdapter: EpisodeOfCareSelectBoxApiAdapter) {
        super();
    }

    public readonly loadAsync = this.async(async () => {
        const response = await this.episodeOfCareSelectBoxApiAdapter.getEpisodesOfCareForPatientAsync(this.props.patientId);
        this.setEpisodesOfCare(response.result);
    });

    @State.bound
    public async onAddNewEpisodeAsync() {
        const dialogResult = await this.modalService.showDialogAsync<IEpisodeOfCareDialogResult>(new EpisodeOfCareDialogParams(this.props.patientId, this.props.careActivityId));
        
        if(dialogResult) {
            const response = await this.episodeOfCareSelectBoxApiAdapter.getEpisodesOfCareForPatientAsync(this.props.patientId);
            this.setEpisodesOfCare(response.result);

            this.props.onChange([...this.props.value, dialogResult.newId]);
        }
    }

    @State.action.bound
    private setEpisodesOfCare(episodesOfCare: EpisodeOfCare[]) {
        this.selectableEpisodesOfCare = episodesOfCare?.map(d => ({ text: d.name, value: d.id } as ISelectBoxItem));
    }
}