import React from "react";

interface IPageBox2WrapperProps {
    className: string;
}


const PageBox2Wrapper: React.FC<IPageBox2WrapperProps> = props => {
    return (
        <div className={props.className}>
            {props.children}
        </div>
    );
};

export default PageBox2Wrapper;