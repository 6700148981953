import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import React from "react";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { ScreenStateContextStore, ScreenStateContextAdapter } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { provideCustomerScreenStore, useCustomerScreenStore } from "./CustomerScreenStoreProvider";
import { LockIndicatorComponent } from "@HisPlatformControls";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import * as Ui from "@CommonControls";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import FormPanel from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import ShowCreateNewCustomerScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowCreateNewCustomerScreenAction.g";
import ShowCustomerScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowCustomerScreenAction.g";

export interface ICustomerScreenProps extends IScreenPropsBase<ShowCreateNewCustomerScreenAction | ShowCustomerScreenAction> {
    _screenState?: ScreenStateContextStore;
}

function CustomerScreen(props: ICustomerScreenProps) {

    const store = useCustomerScreenStore();

    let title;
    if (store.isCreateNewCustomerScreen) {
        title = StaticFinanceResources.Customers.Titles.NewCustomer;
    } else if (store.isCustomerScreen) {
        title = StaticFinanceResources.Customers.Titles.Customer;
    }

    return (
        <Screen
            title={title}
            toolbar={(
                <>
                    {
                        store.canAcquireLock && (
                            <LockIndicatorComponent locked={true} onLoadClickedAsync={store.loadAsync} lockedBy={store.lockInfo?.preventingLockUserId} preventingLockId={store.lockInfo?.preventingLockId} />
                        )
                    }
                    {
                        store.isCustomerScreen && !store.canAcquireLock && (
                            <>
                                <Ui.Button text={StaticWebAppResources.Common.Button.Delete} visualStyle="negative-standard" onClickAsync={store.onDeleteAsync} automationId="__delete" />
                            </>
                        )
                    }
                    {
                        !store.canAcquireLock && (
                            <>
                                <Ui.SaveButton onClickAsync={store.onSaveAsync} automationId="__save" />
                            </>
                        )
                    }
                </>
            )}
            rightFooter={(
                <Ui.Button visualStyle="primary" text={StaticWebAppResources.Common.Button.Save} onClickAsync={store.onSaveAsync} automationId="__externalSave" />
            )}>
            <ValidationBoundary
                validationResults={store.validationResults}
                entityTypeName="Customer"
                validateOnMount
                onValidateAsync={store.onValidateAsync}
            >
                <FormPanel form={store.form} onChange={store.onDataChangeAsync.fireAndForget} definitionId={store.form?.definitionId} isReadOnly={store.vIsReadOnly} />
            </ValidationBoundary>
            <NavigateAwayHook isEnabled onNavigateAwayAsync={store.navigateAwayAsync} />
        </Screen>
    );
}

export default connect<ICustomerScreenProps>(provideCustomerScreenStore(CustomerScreen),
    new ScreenStateContextAdapter());
