import * as React from "react";
import * as HisUi from "@HisPlatformControls";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IPagingState, IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import CustomerApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/Invoicing/CustomerApiAdapter";
import CustomerId from "@Primitives/CustomerId.g";
import CustomerReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Invoicing/CustomerReferenceDataStore";
import ICustomer from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Invoicing/ICustomer";

interface ICustomerSelectorDependencies {
    customerApiAdapter: CustomerApiAdapter;
    customerReferenceDataStore: CustomerReferenceDataStore;
}

interface ICustomerSelectorProps extends ICodeSelectorCommonProps<CustomerId> {
    _dependencies?: ICustomerSelectorDependencies;
}

@State.observer
class CustomerSelector extends React.Component<ICustomerSelectorProps> {

    private get dependencies() { return this.props._dependencies; }
    private get apiAdapter() { return this.dependencies.customerApiAdapter; }
    private get customerReferenceData() { return this.dependencies.customerReferenceDataStore.customers; }

    @State.bound
    private getDisplayValue(value: CustomerId) {
        if (isNullOrUndefined(value)) { return null; }
        const item = this.customerReferenceData.get(value);
        return (<>{item?.customerName}</>);
    }

    @State.bound
    private async getDisplayValueAsync(value: CustomerId) {
        if (isNullOrUndefined(value)) { return null; }
        const item = await this.customerReferenceData.getOrLoadAsync(value);
        return <>{` ${item.customerName}`}</>;
    }

    @State.bound
    private async getTextValueAsync(value: CustomerId) {
        if (isNullOrUndefined(value)) { return null; }
        const item = await this.customerReferenceData.getOrLoadAsync(value);
        return `${item.customerName}`;
    }

    @State.bound
    private async quickSearchAsync(text: string): Promise<CustomerId[]> {
        const results = await this.apiAdapter.quickSearchCustomerAsync(text, 10);
        return results.value;
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: ICustomer[])
        : Promise<IPagedItems<ICustomer>> {
        const customOrdering = {
            direction: "asc",
            columnId: "customerName"
        } as IOrderingState;
        const result = await this.apiAdapter.searchCustomerAsync(
            filterText,
            customOrdering,
            paging);
        await this.customerReferenceData.ensureLoadedAsync(result.items);
        const values = this.customerReferenceData.multiGet(result.items);
        return ({
            items: values ?? [],
            totalCount: result.totalCount
        });
    }

    @State.bound
    private onComplexSearchSingleSelect(item: ICustomer) {
        this.props.onChange(item.id);
    }

    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            getDisplayValue: this.getDisplayValue,
            getTextValueAsync: this.getTextValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            multiSelect: false,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticFinanceResources.Customers.CustomerSelector.Title,
            nameGetter: "customerName",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            hideAllgroup: true
        };
        return (
            <HisUi.UniversalCodeSelector
                {...props}
            />
        );
    }
}

export default connect(
    CustomerSelector,
    new DependencyAdapter<ICustomerSelectorProps, ICustomerSelectorDependencies>((container) => {
        return {
            customerApiAdapter: container.resolve("CustomerApiAdapter"),
            customerReferenceDataStore: container.resolve("CustomerReferenceDataStore"),
        };
    })
);
