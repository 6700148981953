import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IEducationLevelSelectBoxDependencies {
    commonReferenceDataDataStore: CommonReferenceDataDataStore;
}

interface IEducationLevelSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IEducationLevelSelectBoxDependencies;
}


@State.observer
class EducationLevelSelectBox extends React.Component<IEducationLevelSelectBoxProps> {
    private get dependencies() { return this.props._dependencies; }
    private get extensibleEnumStore() { return this.dependencies.commonReferenceDataDataStore.educationLevel; }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    EducationLevelSelectBox,
    new DependencyAdapter<IEducationLevelSelectBoxProps, IEducationLevelSelectBoxDependencies>( (containerService) => {
        return {
            commonReferenceDataDataStore: containerService.resolve("CommonReferenceDataDataStore")
        };
    })
);
