import React, { useMemo, useCallback } from "react";
import ContextMenu from "@CommonControls/ContextMenu";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import ResourceId from "@Primitives/ResourceId.g";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import WorklistActionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistActionType.g";

interface INDataSecondaryMenuItemProps {
    _activityRegistry?: IActivityRegistry;
    textResourceId: ResourceId;
    isGroup: boolean;
    isDisabled: boolean;
    
    onActionAsync?: (rowId: string, actionToken: string, argumentItems: { useCaseDisplayMode?: UseCaseDisplayMode }, worklistActionType: WorklistActionType, activityReference: string, clientSideAction?: ClientSideActionDto) => Promise<void>;
    rowId?: string;
    commandToken?: string;
    useCaseDisplayMode?: UseCaseDisplayMode;
    clientSideAction?: ClientSideActionDto;
    activityReference?: string;
    actionType?: WorklistActionType;
}


const NDataSecondaryMenuItem: React.FC<INDataSecondaryMenuItemProps> = props => {

    const dependencies = useDependencies(c => ({
        localizationService: c.resolve<ILocalizationService>("ILocalizationService"),
    }));

    const title = useMemo(() => {
        const localizedTitle = dependencies.localizationService.localizeReferenceData(props.textResourceId);
        return isNullOrUndefined(localizedTitle) || localizedTitle === "" ? props.textResourceId.value : localizedTitle;
    }, [props.textResourceId?.value]);

    const clickAsync = useCallback(async () =>
        await props.onActionAsync(props.rowId, props.commandToken, {useCaseDisplayMode: props.useCaseDisplayMode}, props.actionType, props.activityReference, props.clientSideAction),
        [props.commandToken, props.onActionAsync]
    );

    const icon = useMemo(() => !props.isGroup ? props._activityRegistry?.getByReference(props.activityReference)?.iconName : null, [props.activityReference, props.isGroup]);
    const groupIcon = useMemo(() => props.isGroup ? props._activityRegistry?.getGroupByName(props.textResourceId?.value)?.iconName : null, [props.textResourceId?.value, props.isGroup]);

    if (props.isGroup) {
        return (
            <ContextMenu.SubMenuItem
                label={(
                    <SpanWithIcon iconName={groupIcon} automationId={title}>{title}</SpanWithIcon>
                )}
            >
                {props.children}
            </ContextMenu.SubMenuItem>
        );
    }

    return (
        <ContextMenu.Item disabled={props.isDisabled} onClickAsync={clickAsync}>
            <SpanWithIcon iconName={icon} automationId={props.activityReference}>{title}</SpanWithIcon>
        </ContextMenu.Item>
    );
};

export default NDataSecondaryMenuItem;