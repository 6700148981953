import React, { useCallback } from "react";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import CheckBox from "@CommonControls/CheckBox";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";

interface IDataGridEnumColumnDependencies {
    localizationService: ILocalizationService;
}

type IDataGridEnumColumnProps = IDataGridColumnBaseProps & {
    _dependencies?: IDataGridEnumColumnDependencies;
    enumName: string;
    enumType: any;
    enumOrigin: "client" | "server";
    displayMode: "selectBox" | "radio" | "radioButtons" | "groupedRadioButtons" | "checkBox" | "checkBoxSwitch" | "checkBoxButton";
};


const DataGridEnumColumn: React.FC<IDataGridEnumColumnProps> = props => {

    const { children, ...columnProps } = props;

    const renderCellValue = useCallback((value: any, row: any, rowId: any, rowIndex: number, isUnderEditing: boolean, dataSetter: (newValue: any) => void) => {
        if (isUnderEditing) {
            const automationId = `universalEnumSelector_column_${props.id}_row_${rowId}`;
            return <UniversalEnumSelector
                enumName={props.enumName}
                enumType={props.enumType}
                displayMode={props.displayMode}
                enumOrigin={props.enumOrigin}
                value={value}
                onChange={dataSetter}
                automationId={automationId}
            />;
        } else {
            switch (props.enumOrigin) {
                case "client":
                    return props._dependencies.localizationService.localizeClientEnum(value, props.enumName);
                case "server":
                    return props._dependencies.localizationService.localizeEnum(value, props.enumName);
            }
        }
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<boolean | null>) => {
        return <CheckBox {...filterProps} canBeNull />;
    }, []);

    return (
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={renderCellValue}
            onRenderFilter={filterRenderer}
            showHint={false}
        />
    );
};

export default connect(
    DataGridEnumColumn,
    new DependencyAdapter<IDataGridEnumColumnProps, IDataGridEnumColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);