import * as Proxy from "@HisPlatform/BoundedContexts/UserManagement/Api/Proxy.g";
import UserGroup from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroup";
import UserGroupId from "@Primitives/UserGroupId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";

export function getAddUserGroupControllerDto(userGroup: UserGroup, isValidateOnly: boolean = false) {
    return new Proxy.AddUserGroupControllerDto({
        shortName: userGroup.shortName,
        name: userGroup.name,
        description: userGroup.description,
        userIds: userGroup.userIds.map(item => new Proxy.UserId2({value: item.value})),
        isValidateOnly: isValidateOnly
    });
}

export function getUpdateUserGroupControllerDto(userGroup: UserGroup, isValidateOnly: boolean = false) {
    return new Proxy.UpdateUserGroupControllerDto({
        userGroup: new Proxy.UserGroupDto({
            id: userGroup.id,
            shortName: userGroup.shortName,
            name: userGroup.name,
            description: userGroup.description,
            userIds: userGroup.userIds.map(item => new Proxy.UserId2({value: item.value})),
            rowVersion: userGroup.rowVersion
        }),
        isValidateOnly: isValidateOnly
    });
}

export function getUpdateUserGroupPermissionCheckDto() {
    return new Proxy.UpdateUserGroupControllerDto({
        userGroup: new Proxy.UserGroupDto({
            id: new UserGroupId("-1"),
            userIds: [],
            rowVersion: new RowVersion(0)
        })
    });
}
