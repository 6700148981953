exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LateralityColumn_inline-input-container_3oKPu {\n  height: 44px;\n  line-height: 26px;\n  padding: 6px 0;\n}\n", ""]);

// exports
exports.locals = {
	"inline-input-container": "LateralityColumn_inline-input-container_3oKPu",
	"inlineInputContainer": "LateralityColumn_inline-input-container_3oKPu"
};