import CareActivityId from "@Primitives/CareActivityId.g";
import ApiAdapterBase2 from "./ApiAdapterBase2";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import CareActivityDischargeDataStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDischargeData/CareActivityDischargeDataStoreMapper";
import CareActivityDischargeDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityDischargeData/CareActivityDischargeDataStore";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import CareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CareActivityStore";
import CareActivityStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityStoreMapper";

import PatientId from "@Primitives/PatientId.g";
import PatientAllergyIntoleranceStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceStoreMapper";
import PatientAllergyIntoleranceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceStore";
import PatientAllergyIntoleranceListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceListStore";
import CareActivityBaseData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareActivityBaseData";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import { mapProxyFormFieldDataAsync } from "@HisPlatform/BoundedContexts/FormEngine/ApplicationLogic/ApiAdapter/FormEngineMappers";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import CareParticipantSettings from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareParticipantSettings";
import CareIdentifierSettings from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareIdentifierSettings";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";
import IAgeCalculationService from "@HisPlatform/Services/Definition/AgeCalculation/IAgeCalculationService";
import CareAndPatientContextData from "@HisPlatform/Packages/Care/Model/CareAndPatientContextData";
import PatientContextData from "@HisPlatform/Packages/Care/Model/PatientContextData";

@Di.injectable()
export default class ContextHeaderApiAdapter extends ApiAdapterBase2 {

    constructor(
        @Di.inject("ICareClient") private readonly apiClient: Proxy.ICareClient,
        @Di.inject("CareActivityDischargeDataStoreMapper") private readonly careActivityDischargeDataStoreMapper: CareActivityDischargeDataStoreMapper,
        @Di.inject("CareActivityStoreMapper") private readonly careActivityStoreMapper: CareActivityStoreMapper,
        @Di.inject("PatientAllergyIntoleranceStoreMapper") private readonly patientAllergyStoreMapper: PatientAllergyIntoleranceStoreMapper,
        @Di.inject("IFormEngineReferenceDataStore") private readonly formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("IAgeCalculationService") private readonly ageCalculationService: IAgeCalculationService,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader
    ) {
        super(referenceDataLoader);
    }

    public getCareAndPatientContextAsync(careActivityId: CareActivityId) {
        return this.executeOperationAsync(
            () => this.apiClient.getCareAndPatientContextHeaderQueryAsync(CreateRequestId(), careActivityId.value),
            async response => {
                const mappedPatientFormFieldData: FormFieldDataBase[] = [];
                await mapProxyFormFieldDataAsync(response.patient.data as any, mappedPatientFormFieldData, this.formEngineReferenceDataStore);

                const allergies = new PatientAllergyIntoleranceListStore();
                response.patientAllergyIntolerances.map(x => {
                    const item = new PatientAllergyIntoleranceStore(false);
                    this.patientAllergyStoreMapper.setItem(item, x as any);
                    allergies.patientAllergyIntolerances.push(item);
                });
                await this.patientAllergyStoreMapper.resolveAllergyIntolerancesAsync(allergies);

                const careActivity = new CareActivityStore();
                this.careActivityStoreMapper.applyToStore(careActivity, response.careActivity);

                const mappedFormFieldData: FormFieldDataBase[] = [];
                await mapProxyFormFieldDataAsync(response.careActivityBaseData.data as any, mappedFormFieldData, this.formEngineReferenceDataStore);

                const baseData = new CareActivityBaseData(
                    mappedFormFieldData,
                    response.careActivityBaseData.rowVersion,
                    new CareParticipantSettings(
                        response.careParticipantSettings.availableParticipants.map(i => {
                            return { id: i.careParticipantTypeId, role: i.role };
                        }), response.careParticipantSettings.primaryParticipant),
                    new CareIdentifierSettings(response.careIdentifierSettings.availableIdentifiers, response.careIdentifierSettings.primaryCareIdentifier));

                const dischargeData = new CareActivityDischargeDataStore();
                this.careActivityDischargeDataStoreMapper.applyToStore(dischargeData, { careActivityDischargeData: response.careActivityDischargeData } as any);

                return new CareAndPatientContextData(
                    new PatientAdministrativeData(mappedPatientFormFieldData, response.patient.rowVersion, this.ageCalculationService),
                    allergies,
                    careActivity,
                    baseData,
                    dischargeData
                );
            }
        );
    }

    public getPatientContextAsync(patientId: PatientId) {
        return this.executeOperationAsync(
            () => this.apiClient.getPatientContextHeaderQueryAsync(CreateRequestId(), patientId.value),
            async response => {

                const mappedPatientFormFieldData: FormFieldDataBase[] = [];
                await mapProxyFormFieldDataAsync(response.patient.data as any, mappedPatientFormFieldData, this.formEngineReferenceDataStore);

                const allergies = new PatientAllergyIntoleranceListStore();
                response.patientAllergyIntolerances.map(x => {
                    const item = new PatientAllergyIntoleranceStore(false);
                    this.patientAllergyStoreMapper.setItem(item, x as any);
                    allergies.patientAllergyIntolerances.push(item);
                });
                await this.patientAllergyStoreMapper.resolveAllergyIntolerancesAsync(allergies);

                return new PatientContextData(
                    new PatientAdministrativeData(mappedPatientFormFieldData, response.patient.rowVersion, this.ageCalculationService),
                    allergies
                );
            }
        );
    }

}
