import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IEnumReferenceEditorDialogResult, IEnumReferenceEditorDialogParams } from "./EnumReferenceEditorDialogParams";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import EditableFormEnumStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableFormEnumStore";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import Di from "@Di";
import ISelectBoxGroup from "@CommonControls/SelectBox/ISelectBoxGroup";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import IFormEnumsInDefinition, { INamedFormEnum } from "@Toolkit/FormEngine/Model/IFormEnumsInDefinition";
import StaticFormEditingResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import FormEnumReference from "@Toolkit/FormEngine/Model/Schema/FormEnumReference";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";

export interface IFormEnumOption {
    formEnum: EditableFormEnumStore;
    formDefinitionId: FormDefinitionId;
    getHashCode: () => string;
}

@Di.injectable()
export default class EnumReferenceEditorDialogStore extends PanelStoreBase<IModalComponentParams<IEnumReferenceEditorDialogResult> & IEnumReferenceEditorDialogParams> {

    @State.observable.ref public options: Array<ISelectBoxGroup<IFormEnumOption>> = [];
    @State.observable.ref public selected: IFormEnumOption = null;
    private wasMyEnum: boolean = false;

    @State.computed public get isMyEnum() {
        return this.selected?.formDefinitionId === null && this.selected?.formEnum.name === this.props.enumFormDataElement.name;
    }

    constructor(
        @Di.inject("IFormEngineReferenceDataStore") private readonly formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("ICurrentCultureProvider") private readonly cultureCodeProvider: ICurrentCultureProvider
    ) {
        super();
    }

    public readonly loadAsync = this.async(async () => {
        const availableFormEnums = await this.formEngineReferenceDataStore.getOrLoadAllFormEnumsAsync();
        const formDefinitionIds = availableFormEnums.map(e => e.formDefinitionId);
        await this.formEngineReferenceDataStore.getOrLoadDefinitionsByIdsAsync(formDefinitionIds);

        this.setLoadedState(availableFormEnums);
    });

    @State.action.bound
    public select(e: IFormEnumOption) {
        this.selected = e;
    }

    @State.action
    private setLoadedState(externalFormEnums: IFormEnumsInDefinition[]) {
        const localFormEnumOptions = this.props.localEnums.map(this.mapExistingLocalFormEnumToOption);
        const externalFormEnumOptions = new Map<string, ISelectBoxItem<IFormEnumOption>>();

        this.options = [
            {
                label: StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Enum.ThisFormOptionGroup,
                options: localFormEnumOptions
            } as ISelectBoxGroup<IFormEnumOption>,
            ...externalFormEnums.filter(ef => !ValueWrapper.equals(ef.formDefinitionId, this.props.formDefinitionId)).map(i => this.mapExtenalFormEnumToOption(i, externalFormEnumOptions))
        ];

        if (this.props.enumFormDataElement.enumReference) {
            if (this.props.enumFormDataElement.enumReference.isLocal) {
                this.selected = localFormEnumOptions.find(leo => leo.value.formEnum.name === this.props.enumFormDataElement.enumReference.enumName).value;
            } else {
                this.selected = externalFormEnumOptions.get(`${this.props.enumFormDataElement.enumReference.formDefinitionId.value}_${this.props.enumFormDataElement.enumReference.enumName}`).value;
            }
        }

        this.wasMyEnum = this.isMyEnum;
        if (!this.isMyEnum) {
            localFormEnumOptions.unshift({
                text: this.props.editor.multiLingualLabel.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode),
                value: {
                    formDefinitionId: null,
                    formEnum: new EditableFormEnumStore(this.props.enumFormDataElement.name, [], this.cultureCodeProvider.cultureCode),
                    getHashCode: () => `self_${this.props.enumFormDataElement.name}`
                },
                longDisplayValue: `${StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Enum.ThisFormOptionGroup}: ${this.props.editor.multiLingualLabel.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode)}`
            });
        }
    }

    @State.bound
    public save() {
        this.props.onClose({
            enumReference: new FormEnumReference(this.selected.formEnum.name, this.selected.formDefinitionId ?? null),
            formEnum: this.selected.formEnum.toFormEnumStore(),
            activeValues: this.selected.formEnum.values.filter(v => v.isActive).map(v => v.value) ?? null,
            isMyEnum: this.isMyEnum,
            wasMyEnum: this.wasMyEnum
        });
    }

    @State.bound
    private mapExistingLocalFormEnumToOption(e: INamedFormEnum) {
        return {
            text: e.displayName.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode) ?? e.enum.name,
            value: {
                formDefinitionId: null,
                formEnum: EditableFormEnumStore.createFromFormEnumStore(e.enum, this.cultureCodeProvider.cultureCode),
                getHashCode: () => `self_${e.enum.name}`
            },
            longDisplayValue: `${StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Enum.ThisFormOptionGroup}: ${e.displayName.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode) ?? e.enum.name}`,
        } as ISelectBoxItem<IFormEnumOption>;
    }

    @State.bound
    private mapExtenalFormEnumToOption(fe: IFormEnumsInDefinition, map: Map<string, ISelectBoxItem<IFormEnumOption>>) {
        const formDefinitionDisplayName = this.formEngineReferenceDataStore.getDefinitionById(fe.formDefinitionId).multiLingualDisplayName.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode);

        return {
            label: formDefinitionDisplayName,
            options: fe.enums.map(e => {
                const item = {
                    text: e.displayName.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode) ?? e.enum.name,
                    value: {
                        formDefinitionId: fe.formDefinitionId,
                        formEnum: EditableFormEnumStore.createFromFormEnumStore(e.enum, this.cultureCodeProvider.cultureCode, this.isCurrentEnum(fe.formDefinitionId, e) ? this.props.enumFormDataElement.activeValues : null),
                        getHashCode: () => `${fe.formDefinitionId.value}_${e.enum.name}`
                    },
                    longDisplayValue: `${formDefinitionDisplayName}: ${e.displayName.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode) ?? e.enum.name}`
                } as ISelectBoxItem<IFormEnumOption>;

                map.set(`${fe.formDefinitionId.value}_${e.enum.name}`, item);

                return item;
            })
        } as ISelectBoxGroup<IFormEnumOption>;
    }

    private isCurrentEnum(formDefinitionId: FormDefinitionId, e: INamedFormEnum) {
        return this.props.enumFormDataElement.enumReference
            && ValueWrapper.equals(this.props.enumFormDataElement.enumReference.formDefinitionId, formDefinitionId)
            && this.props.enumFormDataElement.enumReference.enumName === e.enum.name;
    }
}