import DependencyContainer from "@DiContainer";
import ImmunizationApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/Immunization/ImmunizationApiAdapter";
import ImmunizationStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/Immunization/ImmunizationStoreMapper";
import ProcedureStatementStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ProcedureStatement/ProcedureStatementStoreMapper";
import ProcedureStatementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ProcedureStatement/ProcedureStatementApiAdapter";
import AutonomyDisabilityStatementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/AutonomyDisabilityStatement/AutonomyDisabilityStatementApiAdapter";
import AutonomyDisabilityStatementStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/AutonomyDisabilityStatement/AutonomyDisabilityStatementStoreMapper";
import ConditionStatementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ConditionStatement/ConditionStatementApiAdapter";
import ConditionStatementStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ConditionStatement/ConditionStatementStoreMapper";
import DeviceUseStatementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/DeviceUseStatement/DeviceUseStatementApiAdapter";
import DeviceUseStatementStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/DeviceUseStatement/DeviceUseStatementStoreMapper";
import PregnancyStatementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/PregnancyStatement/PregnancyStatementApiAdapter";
import PregnancyStatementStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/PregnancyStatement/PregnancyStatementStoreMapper";
import MedicationStatementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicationStatement/MedicationStatementApiAdapter";
import MedicationStatementStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicationStatement/MedicationStatementStoreMapper";
import RiskAssessmentStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/RiskAssessment/RiskAssessmentStoreMapper";
import RiskAssessmentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/RiskAssessment/RiskAssessmentApiAdapter";
import PatientAllergyIntoleranceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceApiAdapter";
import PatientAllergyIntoleranceStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceStoreMapper";
import MedicalAlertStatementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalAlertStatement/MedicalAlertStatementApiAdapter";
import MedicalAlertStatementStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalAlertStatement/MedicalAlertStatementStoreMapper";

DependencyContainer
    .bind("ImmunizationApiAdapter")
    .to(ImmunizationApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("ImmunizationStoreMapper")
    .to(ImmunizationStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("ProcedureStatementApiAdapter")
    .to(ProcedureStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("ProcedureStatementStoreMapper")
    .to(ProcedureStatementStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("AutonomyDisabilityStatementApiAdapter")
    .to(AutonomyDisabilityStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("AutonomyDisabilityStatementStoreMapper")
    .to(AutonomyDisabilityStatementStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("ConditionStatementApiAdapter")
    .to(ConditionStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("ConditionStatementStoreMapper")
    .to(ConditionStatementStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("DeviceUseStatementApiAdapter")
    .to(DeviceUseStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("DeviceUseStatementStoreMapper")
    .to(DeviceUseStatementStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("PregnancyStatementApiAdapter")
    .to(PregnancyStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PregnancyStatementStoreMapper")
    .to(PregnancyStatementStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("MedicationStatementApiAdapter")
    .to(MedicationStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("MedicationStatementStoreMapper")
    .to(MedicationStatementStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("RiskAssessmentApiAdapter")
    .to(RiskAssessmentApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("RiskAssessmentStoreMapper")
    .to(RiskAssessmentStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("PatientAllergyIntoleranceApiAdapter")
    .to(PatientAllergyIntoleranceApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PatientAllergyIntoleranceStoreMapper")
    .to(PatientAllergyIntoleranceStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("MedicalAlertStatementApiAdapter")
    .to(MedicalAlertStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("MedicalAlertStatementStoreMapper")
    .to(MedicalAlertStatementStoreMapper)
    .inSingletonScope();