import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import OrganizationUnitDefinitionId from "@Primitives/OrganizationUnitDefinitionId.g";
import OrganizationUnitBaseData from "./OrganizationUnitBaseData";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";

export default class OrganizationUnit extends EntityStoreBase<OrganizationUnitId> implements IDirtyChecked {

    private _dirtyChecker = new StoreDirtyChecker();

    public organizationUnitDefinitionId: OrganizationUnitDefinitionId = null;
    public parentId: OrganizationUnitId = null;
    public baseData: OrganizationUnitBaseData = new OrganizationUnitBaseData();
    @State.observable.ref public validity: LocalDateRange = new LocalDateRange();
    @State.observable public code: string = "";

    public constructor(isNew?: boolean) {
        super(isNew);
        this.validationResults = [];
    }

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "operationInfo",
        "organizationUnitDefinitionId",
        "parentId",
        "validationResults"
    ];

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }
    
    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }

    @State.action.bound
    public setValidity(dateRange: LocalDateRange) {
        this.validity = dateRange;
    }
    
    @State.action.bound
    public setCode(code: string) {
        this.code = code;
    }
}