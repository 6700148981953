import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import ActionIdentifier from "@Primitives/ActionIdentifier.g";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import IOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/IOperationInfo";
import OperationProblem from "./OperationProblem";

export default class ServiceRequestWithAppointment {
    public serviceRequest: ServiceRequestStore | null;
    public appointment: Appointment | null;
    public possibleServiceRequestManagementActions: ActionIdentifier[] | null;
    public validationResults: IClientValidationResult[] | null;
    public problems: OperationProblem[] | null;

    public setValidationResults(validationResult: IServerCompositeValidationResult) {
        this.validationResults = mapValidationResults(validationResult as unknown as IServerCompositeValidationResult);
        this.appointment.validationResults = this.validationResults; // .filter(vr => vr.entityName === "Appointment");
        this.serviceRequest.validationResults = this.validationResults; // .filter(vr => vr.entityName === "ServiceRequest");
    }

    public setOperationInfo(operationInfo: IOperationInfo) {
        this.appointment.operationInfo = operationInfo;
        this.serviceRequest.operationInfo = operationInfo;
    }
}
