import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DosageInfoColumnData from "./DosageInfoColumnData";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PermanentPrescriptionId from "@Primitives/PermanentPrescriptionId.g";
import PermanentPrescriptionReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PermanentPrescriptionReferenceDataStore";
import IDosageLocalizationService from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/IDosageLocalizationService";

interface IDosageInfoColumnDependencies {
    dosageLocalizationService: IDosageLocalizationService;
    permanentPrescriptionReferenceDataStore: PermanentPrescriptionReferenceDataStore;
}

interface IDosageInfoColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IDosageInfoColumnDependencies;
}


const DosageInfoColumn: React.FC<IDosageInfoColumnProps> = (props: IDosageInfoColumnProps) => {
    const valueRenderer = useCallback((value: DosageInfoColumnData) => {
        return (
            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ flexBasis: "auto", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                    {getDisplayText(value.permanentPrescriptionId)}
                </div>
            </div>
        );
    }, []);

    const hintRenderer = useCallback((value: DosageInfoColumnData) => getDisplayText(value.permanentPrescriptionId), []);

    const getDisplayText = (permanentPrescriptionId: PermanentPrescriptionId) => {
        const data = props._dependencies.permanentPrescriptionReferenceDataStore.dosages.get(permanentPrescriptionId);
        return props._dependencies.dosageLocalizationService.localize(data?.dosage);
    };

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderHintValue={hintRenderer} />
    );
};

export default connect(
    DosageInfoColumn,
    new DependencyAdapter<IDosageInfoColumnProps, IDosageInfoColumnDependencies>(container => ({
        dosageLocalizationService: container.resolve("IDosageLocalizationService"),
        permanentPrescriptionReferenceDataStore: container.resolve("PermanentPrescriptionReferenceDataStore")
    }))
);