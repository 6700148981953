import React from "react";
import OutpatientActivityLogPanelView from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Panels/ReportingPanel/OutpatientActivityLogPanel/OutpatientActivityLogPanelView";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { IReportDefinition } from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/Model/IReportDefinition";
import ReportingApiAdapter from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/ReportingApiAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IOutpatientActivityLogFilters } from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Panels/ReportingPanel/OutpatientActivityLogPanel/IOutpatientActivityLogFIlters";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

interface IOutpatientActivityLogPanelDependencies {
    referenceDataStore: OrganizationReferenceDataStore;
    structureApiAdapter: StructureApiAdapter;
    apiAdapter: ReportingApiAdapter;
    fileSaverService: IFileSaverService;
    notificationService: INotificationService;
}

interface IOutpatientActivityLogPanelProps {
    _dependencies?: IOutpatientActivityLogPanelDependencies;
    definition: IReportDefinition;
}

/** @screen */
@State.observer
class OutpatientActivityLogPanel extends React.Component<IOutpatientActivityLogPanelProps> {

    private get apiAdapter() { return this.props._dependencies.apiAdapter; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get fileSaverService() { return this.props._dependencies.fileSaverService; }

    @State.observable.ref private pointOfCares: PointOfCareId[] = [];
    @State.observable.ref private dateRange: LocalDateRange = null;

    private readonly loadAsync = createInitialPanelLoader(this._loadAsync);

    @State.action.bound
    private onDateRangeChange(newValue: LocalDateRange) {
        this.dateRange = newValue;
    }

    @State.action.bound
    public onPointOfCareChange(newValue: PointOfCareId, checkedValue: boolean) {
        if (this.pointOfCares) {
            if (checkedValue === false) {
                this.pointOfCares = this.pointOfCares.filter(x => x.value !== newValue.value);
            } else if (!this.pointOfCares.some(x => x.value === newValue.value)) {
                this.pointOfCares = [...this.pointOfCares, newValue];
            }
        }
    }

    @State.action.bound
    private async onCreateAsync() {
        const filters = {
            startDate: this.dateRange && this.dateRange.from,
            endDate: this.dateRange && this.dateRange.to,
            organizationUnitIds: this.pointOfCares && this.pointOfCares,
        } as IOutpatientActivityLogFilters;

        // TODO: temporal solution, replace this with validation
        if (!this.isInvalid(filters)) {
            const filtersAsString = JSON.stringify(filters);
            const response = await this.apiAdapter.runReportAsync(this.props.definition.reportDefinitionIdentifier, JSON.parse(filtersAsString));
            const documentResponse = await this.apiAdapter.getContentAsBlobAsync(response.value.largeDataIds[0]);
            this.fileSaverService.saveAs(documentResponse.value, "OutpatientActivityLogReport.pdf");
        }
    }

    // TODO: remove this, and move logic to validation
    @State.action
    private isInvalid(filters: IOutpatientActivityLogFilters): boolean {
        let i = 0;

        if (isNullOrUndefined(filters.startDate) && isNullOrUndefined(filters.endDate)) {
            this.notificationService.error(StaticWebAppResources.Reporting.OutpatientActivityLogPanel.ErrorMessages.ReportDateShouldBeFilled);
            i++;
        } else if (isNullOrUndefined(filters.startDate) || isNullOrUndefined(filters.endDate)) {
            this.notificationService.error(StaticWebAppResources.Reporting.OutpatientActivityLogPanel.ErrorMessages.ReportDateShouldBeValid);
            i++;
        }
        
        if (filters.organizationUnitIds.length === 0) {
            this.notificationService.error(StaticWebAppResources.Reporting.OutpatientActivityLogPanel.ErrorMessages.OrganizationUnitIdsShouldBeFilled);
            i++;
        }

        return i > 0;
    }

    @State.bound
    private async _loadAsync() {
        await this.apiAdapter.runReportAsync(this.props.definition.reportDefinitionIdentifier, {} as JSON, true);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }
    public render() {
        if (this.loadAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }

        return (
            <LoadingBoundary>
                <OutpatientActivityLogPanelView
                    definition={this.props.definition}
                    dateRange={this.dateRange}
                    pointOfCares={this.pointOfCares}
                    onDateRangeChange={this.onDateRangeChange}
                    onPointOfCareChange={this.onPointOfCareChange}
                    onCreateAsync={this.onCreateAsync}
                />
            </LoadingBoundary>
        );
    }
}

export default connect(
    OutpatientActivityLogPanel,
    new DependencyAdapter<IOutpatientActivityLogPanelProps, IOutpatientActivityLogPanelDependencies>(c => {
        return {
            referenceDataStore: c.resolve("OrganizationReferenceDataStore"),
            structureApiAdapter: c.resolve("StructureApiAdapter"),
            apiAdapter: c.resolve("ReportingApiAdapter"),
            fileSaverService: c.resolve("IFileSaverService"),
            notificationService: c.resolve("INotificationService")
        };
    })
);