import moment from "moment";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import StoreBase from "@Toolkit/CommonWeb/Model/StoreBase";
import UserId from "@Primitives/UserId.g";

export default class EhiToken extends StoreBase {
    public validFrom: moment.Moment = null;
    public validTo: moment.Moment = null;
    public organizationUnitId: OrganizationUnitId = null;
    public userId: UserId = null;
    public ehiUserIdentifierValue: string = null;
    public tokenValue: string = null;
}