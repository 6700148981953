import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import HunSocialSecurityUseCases from "@HunSocialSecurityPlugin/UseCases/HunSocialSecurityUseCases";
import IUseCaseDescriptor2 from "@PluginInterface/UseCases/IUseCaseDescriptor2";
import OutpatientNeakReportPanel, { IOutpatientNeakReportPanelProps } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/OutpatientNeakReportPanel/OutpatientNeakReportPanel";
import PerformanceStatementCareActivityDetailPanel, { IPerformanceStatementCareActivityDetailPanelProps } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityDetailPanel/PerformanceStatementCareActivityDetailPanel";
import CareActivityId from "@Primitives/CareActivityId.g";
import NeakPerformanceStatementTransactionCodeDialog, { INeakPerformanceStatementTransactionCodeDialogProps } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/NeakPerformanceStatementTransactionCodeDialog/NeakPerformanceStatementTransactionCodeDialog";
import NeakPerformanceStatementId from "@Primitives/NeakPerformanceStatementId.g";
import EuReportPanel, { IEuReportPanelProps } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/EuReportPanel/EuReportPanel";
import PerformanceStatementCareActivityStatusDialog, { IPerformanceStatementCareActivityStatusDialogProps } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityStatusDialog/PerformanceStatementCareActivityStatusDialog";
import EuPerformanceStatementTransactionCodeDialog, { IEuPerformanceStatementTransactionCodeDialogProps } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/EuPerformanceStatementTransactionCodeDialog/EuPerformanceStatementTransactionCodeDialog";
import EuPerformanceStatementId from "@Primitives/EuPerformanceStatementId.g";
import CareRelatedEntitiesDetailPanel, { ICareRelatedEntitiesDetailPanelProps } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/CareRelatedEntitiesDetailPanel/CareRelatedEntitiesDetailPanel";
import PerformanceStatementCareActivityEuStatusDialog, { IPerformanceStatementCareActivityEuStatusDialogProps } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityEuStatusDialog/PerformanceStatementCareActivityEuStatusDialog";
import PerformanceStatementCareActivityNeakStatusAndMonthDialog, { IPerformanceStatementCareActivityNeakStatusAndMonthDialogProps } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityNeakStatusAndMonthDialog/PerformanceStatementCareActivityNeakStatusAndMonthDialog";
import SelectPerformanceStatementForCreationDialog, { ISelectPerformanceStatementForCreationDialogProps } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/SelectPerformanceStatementForCreationDialog/SelectPerformanceStatementForCreationDialog";
import ErrorListProcessLogDialog, { IErrorListProcessLogDialogProps } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/ErrorListProcessLogDialog/ErrorListProcessLogDialog";
import CloseCareActivitiesDialog, { ICloseCareActivitiesDialogProps } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/CloseCareActivitiesDialog/CloseCareActivitiesDialog";
import PerformanceStatementCareActivityBatchUpdatePanel, { IPerformanceStatementCareActivityBatchUpdatePanelProps } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityBatchUpdateConfigurationPanel/PerformanceStatementCareActivityBatchUpdateConfigurationPanel";
import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";

export default function configurePerformanceStatementUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2<ISelectPerformanceStatementForCreationDialogProps>(
        {
            identifiers: [HunSocialSecurityUseCases.selectPerformanceStatementForCreation],
            component: SelectPerformanceStatementForCreationDialog
        } as IUseCaseDescriptor2<ISelectPerformanceStatementForCreationDialogProps>
    );

    useCaseRegistry.add2<IOutpatientNeakReportPanelProps>(
        {
            identifiers: [HunSocialSecurityUseCases.newNeakPerformanceStatement],
            component: OutpatientNeakReportPanel
        } as IUseCaseDescriptor2<IOutpatientNeakReportPanelProps>
    );

    useCaseRegistry.add2<IPerformanceStatementCareActivityDetailPanelProps>(
        {
            identifiers: [HunSocialSecurityUseCases.viewPerformanceStatementCareActivityOutpatientDataSheet],
            component: PerformanceStatementCareActivityDetailPanel,
            mapUseCaseArgumentsToProps: args => {
                const careActivityId = args.find(a => a.value instanceof CareActivityId).value;
                
                return {
                   careActivityId: careActivityId,
                   initiallyActiveTabName: "PerformanceStatementCareActivityPdf"
                };
            }
        } as IUseCaseDescriptor2<IPerformanceStatementCareActivityDetailPanelProps>
    );

    useCaseRegistry.add2<IPerformanceStatementCareActivityDetailPanelProps>(
        {
            identifiers: [HunSocialSecurityUseCases.viewPerformanceStatementCareActivityStatusHistory],
            component: PerformanceStatementCareActivityDetailPanel,
            mapUseCaseArgumentsToProps: args => {
                const careActivityId = args.find(a => a.value instanceof CareActivityId).value;
                
                return {
                   careActivityId: careActivityId,
                   initiallyActiveTabName: "PerformanceStatementCareActivityStatusHistory"
                };
            }
        } as IUseCaseDescriptor2<IPerformanceStatementCareActivityDetailPanelProps>
    );

    useCaseRegistry.add2<IOutpatientNeakReportPanelProps>(
        {
            identifiers: [HunSocialSecurityUseCases.newNeakRevisionPerformanceStatement],
            component: OutpatientNeakReportPanel
        } as IUseCaseDescriptor2<IOutpatientNeakReportPanelProps>
    );

    useCaseRegistry.add2<INeakPerformanceStatementTransactionCodeDialogProps>(
        {
            identifiers: [HunSocialSecurityUseCases.setPerformanceStatementTransactionCode],
            component: NeakPerformanceStatementTransactionCodeDialog,
            mapUseCaseArgumentsToProps: args => {
                const neakPerformanceStatementId = args.find(a => a.value instanceof NeakPerformanceStatementId).value;
                
                return {
                   neakPerformanceStatementId
                };
            }
        } as IUseCaseDescriptor2<INeakPerformanceStatementTransactionCodeDialogProps>
    );

    useCaseRegistry.add2<IPerformanceStatementCareActivityStatusDialogProps>(
        {
            identifiers: [HunSocialSecurityUseCases.setPerformanceStatementCareActivityManualStatus],
            component: PerformanceStatementCareActivityStatusDialog,
            mapUseCaseArgumentsToProps: args => {
                const careActivityId = args.find(a => a.value instanceof CareActivityId).value;
                
                return {
                    careActivityId
                };
            }
        } as IUseCaseDescriptor2<IPerformanceStatementCareActivityStatusDialogProps>
    );

    useCaseRegistry.add2<IPerformanceStatementCareActivityEuStatusDialogProps>(
        {
            identifiers: [HunSocialSecurityUseCases.setPerformanceStatementCareActivityManualEuStatus],
            component: PerformanceStatementCareActivityEuStatusDialog,
            mapUseCaseArgumentsToProps: args => {
                const careActivityId = args.find(a => a.value instanceof CareActivityId).value;
                
                return {
                    careActivityId
                };
            }
        } as IUseCaseDescriptor2<IPerformanceStatementCareActivityEuStatusDialogProps>
    );

    useCaseRegistry.add2<IPerformanceStatementCareActivityNeakStatusAndMonthDialogProps>(
        {
            identifiers: [HunSocialSecurityUseCases.setPerformanceStatementCareActivityManualNeakStatusAndMonth],
            component: PerformanceStatementCareActivityNeakStatusAndMonthDialog,
            mapUseCaseArgumentsToProps: args => {
                const careActivityId = args.find(a => a.value instanceof CareActivityId).value;
                
                return {
                    careActivityId
                };
            }
        } as IUseCaseDescriptor2<IPerformanceStatementCareActivityNeakStatusAndMonthDialogProps>
    );

    useCaseRegistry.add2<IPerformanceStatementCareActivityBatchUpdatePanelProps>(
        {
            identifiers: [HunSocialSecurityUseCases.configureBatchUpdatePerformanceStatementCareActivityManualNeakStatusAndMonth],
            component: PerformanceStatementCareActivityBatchUpdatePanel
        } as IUseCaseDescriptor2<IPerformanceStatementCareActivityBatchUpdatePanelProps>
    );

    useCaseRegistry.add2<IEuReportPanelProps>(
        {
            identifiers: [HunSocialSecurityUseCases.newEuPerformanceStatement],
            component: EuReportPanel
        } as IUseCaseDescriptor2<IEuReportPanelProps>
    );

    useCaseRegistry.add2<IEuPerformanceStatementTransactionCodeDialogProps>(
        {
            identifiers: [HunSocialSecurityUseCases.setEuPerformanceStatementTransactionCode],
            component: EuPerformanceStatementTransactionCodeDialog,
            mapUseCaseArgumentsToProps: args => {
                const euPerformanceStatementId = args.find(a => a.value instanceof EuPerformanceStatementId).value;
                
                return {
                    euPerformanceStatementId
                };
            }
        } as IUseCaseDescriptor2<IEuPerformanceStatementTransactionCodeDialogProps>
    );

    useCaseRegistry.add2<IErrorListProcessLogDialogProps>(
        {
            identifiers: [HunSocialSecurityUseCases.neakErrorListProcessLog],
            component: ErrorListProcessLogDialog,
            mapUseCaseArgumentsToProps: args => {
                const neakPerformanceStatementId = args.find(a => a.value instanceof NeakPerformanceStatementId).value;
                
                return {
                    neakPerformanceStatementId: neakPerformanceStatementId
                };
            }
        } as IUseCaseDescriptor2<IErrorListProcessLogDialogProps>
    );

    useCaseRegistry.add2<IErrorListProcessLogDialogProps>(
        {
            identifiers: [HunSocialSecurityUseCases.euErrorListProcessLog],
            component: ErrorListProcessLogDialog,
            mapUseCaseArgumentsToProps: args => {
                const euPerformanceStatementId = args.find(a => a.value instanceof EuPerformanceStatementId).value;
                
                return {
                    euPerformanceStatementId: euPerformanceStatementId
                };
            }
        } as IUseCaseDescriptor2<IErrorListProcessLogDialogProps>
    );

    useCaseRegistry.add2<ICareRelatedEntitiesDetailPanelProps>(
        {
            identifiers: [HunSocialSecurityUseCases.editCareRelatedEntities],
            component: CareRelatedEntitiesDetailPanel,
            mapUseCaseArgumentsToProps: args => {
                const careActivityId = args.find(a => a.value instanceof CareActivityId).value;
                
                return {
                    careActivityId
                };
            }
        }
    );

    useCaseRegistry.add2<ICloseCareActivitiesDialogProps>(
        {
            identifiers: [HunSocialSecurityUseCases.closeCareActivities],
            component: CloseCareActivitiesDialog
        } as IUseCaseDescriptor2<ICloseCareActivitiesDialogProps>
    );
}
