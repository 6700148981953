import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PatientId from "@Primitives/PatientId.g";
import EhiDocumentListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiDocumentListItem";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import DataGridExtensibleEnumColumn from "@CommonControls/DataGrid/Column/DataGridExtensibleEnumColumn";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PractitionerColumn, { PractitionerColumnDisplayMode } from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerColumn/PractitionerColumn";
import * as HisUi from "@HisPlatformControls";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import EhiErrorSeverity from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiErrorSeverity";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import EhiCareService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareService";
import EhiServiceCallStatus from "@HunEHealthInfrastructurePlugin/Model/EhiServiceCallStatus";

interface IEhiDocumentListPanelDependencies {
    ehiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    localizationService: ILocalizationService;
}

interface IEhiDocumentListPanelProps {
    _dependencies?: IEhiDocumentListPanelDependencies;
    ehiCareService: EhiCareService;
    ehiEventCatalogIdentifier: string;
    patientId: PatientId;
    isEmergency: boolean;
    emergencyReason: string;
}

/** @screen */
@State.observer
class EhiDocumentListPanel extends React.Component<IEhiDocumentListPanelProps> {

    @State.observable.ref private documents: EhiDocumentListItem[] = [];
    @State.observable.ref private errors: EhiError[] = [];

    @State.observable private isLoading: boolean = false;
    @State.observable private isDocumentLoading: boolean = false;

    @State.observable.ref private selectedDocumentId: string = null;
    private selectedDocumentVersion: string = null;
    @State.observable.ref private documentContent: Uint8Array = null;

    @State.action.bound
    private setDocuments(newValue: EhiDocumentListItem[]) {
        this.documents = newValue;
    }

    @State.action.bound
    private setDocumentContent(newValue: Uint8Array) {
        this.documentContent = newValue;
    }

    @State.action.bound
    private setSelectedDocumentId(newValue: string) {
        this.selectedDocumentId = newValue;
    }

    @State.action.bound
    private setErrors(newValue: EhiError[]) {
        this.errors = newValue;
    }

    @State.action.bound
    private unsetSelectedDocumentId() {
        this.selectedDocumentId = null;
        this.setDocumentContent(null);
    }

    @State.boundLoadingState("isLoading")
    private async loadAsync() {
        await this.props._dependencies.ehiCareReferenceDataStore.ehiDocumentTypes.ensureLoadedAsync();
        if (this.props.patientId) {
            const ehiServiceCallResult = await this.props.ehiCareService.getEhiDocumentsAsync(
                this.props.ehiEventCatalogIdentifier,
                this.props.patientId,
                this.props.isEmergency,
                this.props.emergencyReason);

            if (ehiServiceCallResult.ehiServiceCallStatus === EhiServiceCallStatus.Success &&
                ehiServiceCallResult.result &&
                ehiServiceCallResult.result.operationWasSuccessful) {

                const documentsEhiResult = ehiServiceCallResult.result.value;
                this.setDocuments(documentsEhiResult.value);
                this.setErrors(documentsEhiResult.errors);
            }
        }
    }

    @State.loadingState("isDocumentLoading")
    private async loadDocumentAsync() {
        if (this.selectedDocumentId) {
            const ehiServiceCallResult = await this.props.ehiCareService.loadEhiCareDocumentAsync(
                this.props.patientId,
                this.selectedDocumentId,
                this.props.isEmergency,
                this.props.emergencyReason);

            if (ehiServiceCallResult.ehiServiceCallStatus === EhiServiceCallStatus.Success &&
                ehiServiceCallResult.result &&
                ehiServiceCallResult.result.operationWasSuccessful) {

                const documentEhiResult = ehiServiceCallResult.result.value;
                this.setDocumentContent(documentEhiResult.value);
            }
        } else {
            this.unsetSelectedDocumentId();
            this.setDocumentContent(null);
        }
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IEhiDocumentListPanelProps) {
        if (this.props.ehiEventCatalogIdentifier !== prevProps.ehiEventCatalogIdentifier || this.props.patientId !== prevProps.patientId) {
            dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        }
    }

    @State.bound
    private selectDocument(row: EhiDocumentListItem) {
        this.setSelectedDocumentId(row.ehiId);
        this.selectedDocumentVersion = row.version;
        dispatchAsyncErrors(this.loadDocumentAsync(), this);
    }

    @State.bound
    private onViewDocumentClickHandlerFactory(row: EhiDocumentListItem) {
        return () => {
            this.selectDocument(row);
        };
    }

    @State.bound
    private renderButtons(value: any, row: EhiDocumentListItem) {
        return (
            <Ui.Button
                onClick={this.onViewDocumentClickHandlerFactory(row)}
                size="compact"
                iconName="document"
                float="right"
                automationId={`${row.ehiId}_viewDocumentButton`}
            />
        );
    }

    @State.bound
    private renderError(e: EhiError, key: number) {
        const localizedSeverity = e.severity != null ? this.props._dependencies.localizationService.localizeEnum(EhiErrorSeverity[e.severity], "EhiErrorSeverity") : { Name: "" };

        let message = `${localizedSeverity?.Name}: ${e.message}`;

        if (e.moduleName) {
            message = message + ` (${e.moduleName})`;
        }

        return <li key={key}>{message}</li>;
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }
        
        if (this.isLoading) {
            return <></>;
        }

        return (
            <>
                {this.errors?.length > 0 &&
                    <Ui.Message type="warning">
                        <ul>{this.errors.map(this.renderError)}</ul>
                    </Ui.Message>
                }
                <Ui.DataGrid dataSource={this.documents} fixedLayout automationId="ehiDocumentListPanel_dataGrid">
                    <DataGridDateTimeColumn dataGetter={"timeStamp"} header={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Documents.Labels.Timestamp} />
                    <DataGridExtensibleEnumColumn dataGetter={"ehiDocumentTypeId"} extensibleEnumStore={this.props._dependencies.ehiCareReferenceDataStore.ehiDocumentTypes} header={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Documents.Labels.DocumentType} />
                    <DataGridColumn dataGetter={"version"} header={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Documents.Labels.Version} width="80px" />
                    <PractitionerColumn dataGetter={"doctorId"} fallbackValueRenderer={this.doctorFallbackValueRenderer} header={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Documents.Labels.CareDoctor} isCodeBold />
                    <DataGridColumn dataGetter={"ehiId"} header={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Documents.Labels.DocumentIdentifier} />
                    <DataGridColumn onRenderCellValue={this.renderButtons} width="30px" />
                </Ui.DataGrid>
                <HisUi.ContextAwareModal isOpen={!!this.selectedDocumentId} onClose={this.unsetSelectedDocumentId}>
                    <Ui.Modal.Body noGap>
                        <Ui.ScrollView>
                            <Ui.PdfViewer binaryPdf={this.documentContent} />
                        </Ui.ScrollView>
                    </Ui.Modal.Body>
                </HisUi.ContextAwareModal>
            </>
        );
    }

    @State.bound
    private doctorFallbackValueRenderer(row: EhiDocumentListItem, displayMode: PractitionerColumnDisplayMode, isCodeBold: boolean) {
        switch (displayMode) {
            case "code":
                return isCodeBold ? <b>{row?.ehiDoctor?.stampCode}</b> : row?.ehiDoctor?.stampCode;
            case "name":
                return this.props._dependencies.localizationService.localizePersonName(row?.ehiDoctor?.name);
            case "code-name":
            default:
                return <>{isCodeBold ? <b>{row?.ehiDoctor?.stampCode}</b> : row?.ehiDoctor?.stampCode} - {this.props._dependencies.localizationService.localizePersonName(row?.ehiDoctor?.name)}</>;
        }
    }
}

export default connect(
    EhiDocumentListPanel,
    new DependencyAdapter<IEhiDocumentListPanelProps, IEhiDocumentListPanelDependencies>(container => {
        return {
            ehiCareReferenceDataStore: container.resolve("HunEhiCareReferenceDataStore"),
            localizationService: container.resolve("ILocalizationService")
        };
    })
);