import RequestStatus from "./RequestStatus";
import IOperationInfo from "./IOperationInfo";
import IBusinessError from "@Toolkit/CommonWeb/Model/IBusinessError";

export default class BusinessErrorOperationInfo implements IOperationInfo {

    public get requestStatus(): RequestStatus { return RequestStatus.BusinessError; }
    public get hasTechnicalError(): boolean { return false; }
    public get isPersisted(): boolean { return false; }

    constructor(
        public readonly businessError: IBusinessError,
        public readonly operationName?: string,
        public readonly operationId?: string
    ) {}
}