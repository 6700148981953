import IFormFieldReference from "@HisPlatform/BoundedContexts/FormEngine/Components/FormFieldSelector/IFormFieldReference";
import IToken from "@HisPlatform/BoundedContexts/TokenBasedDataGathering/ApplicationLogic/Model/Token/IToken";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class FormLogicTokenStore {
    @State.observable public id: string;
    @State.observable public token: IToken;
    @State.observable public settings: IFormFieldReference;

    constructor(
        id: string,
        token: IToken,
        settings: IFormFieldReference,
    ) {
        this.id = id;
        this.token = token;
        this.settings = settings;
    }

    @State.action.bound
    public setToken(token: IToken) {
        this.token = token;
    }

    @State.action.bound
    public setSettings(settings: IFormFieldReference) {
        this.settings = settings;
    }
}