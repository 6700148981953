import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import AllergyIntoleranceId from "@Primitives/AllergyIntoleranceId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import _ from "@HisPlatform/Common/Lodash";
import AllergyIntoleranceAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/AllergyIntoleranceAssignmentBase";
import IAllergyIntoleranceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/IAllergyIntoleranceVersion";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class ReferencedAllergyIntoleranceAssignment extends AllergyIntoleranceAssignmentBase {

    @State.observable.ref public allergyIntoleranceVersionSelector: IEntityVersionSelector<AllergyIntoleranceId> = null;
    @State.observable.ref public allergyIntoleranceVersion: IAllergyIntoleranceVersion = null;

    @State.action.bound
    public setAllergyIntolerance(newValue: AllergyIntoleranceId) {
        this.initializeCreatedAtIfEmpty();
        this.allergyIntoleranceVersionSelector = { id: newValue, validOn: LocalDate.createFromMoment(this.createdAt) };
    }
}