import React from "react";
import * as Ui from "@CommonControls";
import PerformanceStatementCareActivityStatusTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityStatusTypeSelectBox/PerformanceStatementCareActivityStatusTypeSelectBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";
import BatchUpdatePerformanceStatementCareActivityManualNeakStatusAndMonthArgument from "@HunSocialSecurityPlugin/BoundedContexts/Productivity/ApplicationLogic/Model/BatchUpdatePerformanceStatementCareActivityManualNeakStatusAndMonthArgument";
import { INDataBatchActionPanelProps } from "@HisPlatform/BoundedContexts/Productivity/Components/NDataGrid/INDataBatchActionPanelProps";
import DefaultNDataBatchActionInProgressPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataGrid/DefaultNDataBatchActionInProgressPanel";
import StaticHunSocialSecurityPerformanceStatementResources from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/StaticResources/StaticHunSocialSecurityPerformanceStatementResources";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";
import ClientSideValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidationBoundary";
import PerformanceStatementCareActivityStatusType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementCareActivityStatusType.g";

export interface IPerformanceStatementCareActivityBatchUpdatePanelProps extends INDataBatchActionPanelProps {

}

@State.observer
export default class PerformanceStatementCareActivityBatchUpdatePanel extends React.Component<IPerformanceStatementCareActivityBatchUpdatePanelProps> {

    @State.observable.ref private newValidity: YearMonth = null;
    @State.observable.ref private newType: PerformanceStatementCareActivityStatusType;

    private clientSideValidator = new ClientSideValidator({
        BatchUpdatePerformanceStatementCareActivityManualNeakStatusAndMonthArgument: {
            Type: { isRequired: true },
            ValidAt: { isRequired: true }
        }
    }, {
        shouldBeFilled: StaticHunSocialSecurityPerformanceStatementResources.GenericValidationMessages.Required
    });

    @State.computed private get showOnlySelectedLabel() {
        return formatStringWithObjectParams(
            StaticHunSocialSecurityPerformanceStatementResources.PerformanceStatementCareActivityWorkListPanel.ReportStatusBatchUpdatePanel.ShowOnlySelected,
            {
                numberOfSelectedItems: this.props.selectedActionByRowIds.size.toString()
            }
        );
    }

    public componentDidMount() {
        this.props.onConfigure(
            "PerformanceStatement_BatchUpdatePerformanceStatementCareActivityManualNeakStatusAndMonth",
            row => row["CareActivityBased_CareIdentifier"]);
    }

    public render() {
        const resources = StaticHunSocialSecurityPerformanceStatementResources.PerformanceStatementCareActivityWorkListPanel.ReportStatusBatchUpdatePanel;

        if (!!this.props.runningTask) {
            return (
                <DefaultNDataBatchActionInProgressPanel
                    {...this.props}
                    title={resources.InProgressStateTitle}
                    errorDetailTitle="Hibás esetek"
                    errorDetailTableNameColumnHeader="Naplósorszám"
                />
            );
        }

        return (
            <Ui.Flex xsJustify="center">
                <Ui.Flex.Item md={8} xs={12}>
                    <Ui.GroupBox title={resources.ConfigureStateTitle} style={{ margin: 4 }}>
                        <ClientSideValidationBoundary validator={this.clientSideValidator} entityTypeName="BatchUpdatePerformanceStatementCareActivityManualNeakStatusAndMonthArgument">
                            <Ui.Flex>
                                <Ui.Flex.Item sm={4}>
                                    <PerformanceStatementCareActivityStatusTypeSelectBox
                                        label={resources.NewReportStatus}
                                        value={this.newType}
                                        onChange={this.setNewType}
                                        propertyIdentifier="Type"
                                        clearable={false}
                                        required
                                        automationId="newTypeStatusSelectBox"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={4}>
                                    <Ui.MonthPicker
                                        label={resources.NewPeriod}
                                        value={this.newValidity}
                                        onChange={this.setNewValidity}
                                        propertyIdentifier="ValidAt"
                                        required
                                        automationId="newValidityPicker"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={4} horizontalContentAlignment="right">
                                    <Ui.Button
                                        text={resources.Execute}
                                        visualStyle="primary"
                                        verticalAlign="input"
                                        onClickAsync={this.executeAsync}
                                        automationId="executeButton"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.CheckBox
                                value={this.props.showOnlySelected}
                                onChange={this.props.onShowOnlySelectedChange}
                                label={this.showOnlySelectedLabel}
                                automationId="showOnlySelectedCheckBox"
                            />
                        </ClientSideValidationBoundary>
                    </Ui.GroupBox>
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.action.bound
    private setNewValidity(newValidity: YearMonth) {
        this.newValidity = newValidity;
    }

    @State.action.bound
    private setNewType(newType: PerformanceStatementCareActivityStatusType) {
        this.newType = newType;
    }

    @State.bound
    private async executeAsync() {

        if (!this.clientSideValidator.isValid()) {
            return;
        }

        await this.props.onStartTaskAsync(new BatchUpdatePerformanceStatementCareActivityManualNeakStatusAndMonthArgument(
            this.newValidity,
            this.newType
        ));
    }
}