import * as React from "react";
import * as HisUi from "@HisPlatformControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import AllergyIntoleranceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/ReferenceData/AllergyIntoleranceApiAdapter";
import PatientRegisterReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/PatientRegisterReferenceDataStore";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import AllergyIntoleranceReactionId from "@Primitives/AllergyIntoleranceReactionId.g";
import IAllergyIntoleranceReactionVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/IAllergyIntoleranceReactionVersion";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import {IUniversalCodeSelectorProps} from "@HisPlatformControls/UniversalCodeSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import {IOrderingState, IPagingState} from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";

interface IAllergyIntoleranceReactionCodeSelectorDependencies {
    patientRegisterReferenceDataStore: PatientRegisterReferenceDataStore;
    allergyIntoleranceApiAdapter: AllergyIntoleranceApiAdapter;
}

interface IAllergyIntoleranceReactionCodeSelectorProps extends ICodeSelectorCommonProps<AllergyIntoleranceReactionId> {
    _dependencies?: IAllergyIntoleranceReactionCodeSelectorDependencies;
}

@State.observer
class AllergyIntoleranceReactionCodeSelector extends React.Component<IAllergyIntoleranceReactionCodeSelectorProps> {

    private get dependencies() {
        return this.props._dependencies;
    }

    @State.bound
    private async getDisplayValueAsync(value: AllergyIntoleranceReactionId) {

        const item = await this.dependencies.patientRegisterReferenceDataStore.allergyIntoleranceReaction.getOrLoadAsync({
                id: value,
                validOn: LocalDate.today()
            });

        return item.name;
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const result = await this.dependencies.allergyIntoleranceApiAdapter.searchAllergyIntoleranceReactionsByFreeTextAsync(
            text,
            10,
            LocalDate.today()
        );

        if (result.operationWasSuccessful) {
            const ids = result.value.map(r => r.id);

            await this.dependencies.patientRegisterReferenceDataStore.allergyIntoleranceReaction.ensureLoadedAsync(ids.map(id => ({
                id,
                validOn: LocalDate.today()
            } as IEntityVersionSelector<AllergyIntoleranceReactionId>)));

            return ids;
        }

        return null;
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: IAllergyIntoleranceReactionVersion[]): Promise<IPagedItems<IAllergyIntoleranceReactionVersion>> {

        const today = LocalDate.today();
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;

        const results = await this.props._dependencies.allergyIntoleranceApiAdapter.searchAllergyIntoleranceReaction(
            filterText,
            today,
            customOrdering,
            paging);

        return results.value;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: IAllergyIntoleranceReactionVersion) {
        this.props.onChange(item.id);
    }

    @State.bound
    private onComplexSearchMultiSelect(items: IAllergyIntoleranceReactionVersion[]) {
        this.props.onChange(items.map(item => item.id));
    }

    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticCareResources.PatientRegister.PatientAllergyIntoleranceList.Title,
            codeGetter: "code",
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect
        } as IUniversalCodeSelectorProps<AllergyIntoleranceReactionId, IAllergyIntoleranceReactionVersion>;

        return (
            <>
                <HisUi.UniversalCodeSelector
                    {...props}
                    automationId={this.props.automationId}
                />
            </>
        );
    }

}

export default connect(
    AllergyIntoleranceReactionCodeSelector,
    new DependencyAdapter<IAllergyIntoleranceReactionCodeSelectorProps, IAllergyIntoleranceReactionCodeSelectorDependencies>((container) => {
        return {
            patientRegisterReferenceDataStore: container.resolve("PatientRegisterReferenceDataStore"),
            allergyIntoleranceApiAdapter: container.resolve("AllergyIntoleranceApiAdapter")
        };
    })
);
