import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import { getNavigationMenuItems } from "./NavigationMenuItems";

class CarePackageInitializer implements IPackageInitializer {

    public configureDynamicNavigation(registry: IDynamicNavigationRegistry): void {
        getNavigationMenuItems().forEach(navigationItem => registry.register(navigationItem));
    }
}

export default new CarePackageInitializer();