import React from "react";
import Styles from "./PageBox.less";
import ScrollView from "@CommonControls/ScrollView/ScrollView";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";

interface IPageBox2BodyProps {
    hasSidePadding?: boolean;
    hasVerticalPadding?: boolean;

    className?: string;
    style?: React.CSSProperties;
    splitContent?: React.ReactNode;
    splitContentSize?: number | string;
    splitContentAlign?: "left" | "right";
    sidebarContainerId?: string;
}

const getPadding = (hasSidePadding: boolean, hasVerticalPadding: boolean) => {
    if (hasSidePadding && hasVerticalPadding) {
        return Styles.hasBothPadding;
    } else if (hasSidePadding) {
        return Styles.hasSidePadding;
    } else if (hasVerticalPadding) {
        return Styles.hasVerticalPadding;
    }
    return "";
};

const getCssLength = (value: number | string) => {
    return typeof value === "number" ? `${value}px` : value;
};


const PageBox2Body: React.FC<IPageBox2BodyProps> = props => {

    const content = (
        <ScrollView height="1px" innerClassName={getPadding(props.hasSidePadding, props.hasVerticalPadding)}>
            {props.children}
        </ScrollView>
    );

    return (
        <div className={combineClasses(props.className, Styles.body)} style={props.style}>
            {!!props.splitContent ? (
                <div className={Styles.bodyWithSidebarWrapper}>
                    {props.splitContentAlign === "left" && <div className={Styles.bodyWithSidebarSidebar} style={{ "--size": getCssLength(props.splitContentSize) } as any} id={props.sidebarContainerId}>{props.splitContent}</div>}
                    <div className={Styles.bodyWithSidebarContent}>{content}</div>
                    {props.splitContentAlign === "right" && <div className={Styles.bodyWithSidebarSidebar} style={{ "--size": getCssLength(props.splitContentSize) } as any} id={props.sidebarContainerId}>{props.splitContent}</div>}
                </div>
            ) : content}
        </div>
    );
};

PageBox2Body.defaultProps = {
    splitContentSize: 90,
    splitContentAlign: "left"
};

export default PageBox2Body;