import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import { PageBoxContainer } from "@CommonControls";
import { HisErrorBoundary } from "@HisPlatformControls";
import EhiEReferralListPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiEReferralListPanel/EhiEReferralListPanel";

interface IEhiEReferralListPageDependencies {
    userContext: UserContext;
    routingStore: GlobalRoutingStore;
    applicationContext: ApplicationContext;
}

interface IEhiEReferralListPageProps extends IRoutingFrameContentProps {
    _dependencies?: IEhiEReferralListPageDependencies;
}

@State.observer
class EhiEReferralListPage extends React.Component<IEhiEReferralListPageProps> {

    public componentWillMount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(new UseCaseIdentifier("EhiEReferral_ReferralList"));
    }

    public componentWillUnmount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(null);
    }

    public render() {
        return (
            <div>
                <PageBoxContainer>
                    <HisErrorBoundary>
                        <SingleLayout>
                            <EhiEReferralListPanel
                                routingController={this.props.routingController}
                            />
                        </SingleLayout>
                    </HisErrorBoundary>
                </PageBoxContainer>
            </div>
        );
    }
}

export default connect(
    withHisErrorBoundary(EhiEReferralListPage),
    new DependencyAdapter<IEhiEReferralListPageProps, IEhiEReferralListPageDependencies>(container => {
        return {
            userContext: container.resolve("UserContext"),
            routingStore: container.resolve("GlobalRoutingStore"),
            applicationContext: container.resolve("ApplicationContext")
        };
    })
);
