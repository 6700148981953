import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import UserGroupId from "@Primitives/UserGroupId.g";

export interface IUserGroupMultiSelectDialogParams extends IModalParams {
    alreadySelectedIds: UserGroupId[];
    title: string;
}

export interface IUserGroupMultiSelectDialogResult {
    selectedUserGroupIds: UserGroupId[];
}

export default class UserGroupMultiSelectDialogParams implements IUserGroupMultiSelectDialogParams {

    public static type = "UserGroupMultiSelectDialog";
    public get type() { return UserGroupMultiSelectDialogParams.type; }

    constructor(
        public alreadySelectedIds: UserGroupId[] = [],
        public title: string = ""
    ) {}
}