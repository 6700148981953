import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import MedicalServiceFinancingStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/MedicalServiceFinancingStore";
import { IValidationProblemProcessor } from "@Toolkit/CommonWeb/Validation/ValidationProblemProcessorService";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import FinancedServiceWithQuantityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/FinancedServiceWithQuantityStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import _ from "@HisPlatform/Common/Lodash";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";

export class TogetherRuleValidationProblemProcessor implements IValidationProblemProcessor<MedicalServiceFinancingStore> {
    public ruleId = "ShouldBeCompliantWithFinancedServiceTogetherRules";
    public isRuleHighlightable = true;

    constructor(
        private financeReferenceDataStore: FinanceReferenceDataStore
    ) {}

    public mapValidationProblem(problem: IClientValidationProblem, items: MedicalServiceFinancingStore[]) {
        const serviceId = this.getServiceId(problem);

        if (serviceId) {
            return items.filter(i => i.financedServicesWithQuantities.some((j: FinancedServiceWithQuantityStore) => {
                return ValueWrapper.equals(serviceId, j.financedServiceId.id);
            }));
        }
        return [];
    }

    public renderInfo(problem: IClientValidationProblem) {
        const missingServiceIds = this.getMissingServiceIds(problem);

        const serviceCodes: string[] = [];
        missingServiceIds.forEach(i => {
            const service = this.financeReferenceDataStore.financedServiceMap.get({id: i, validOn: null});
            serviceCodes.push(service?.code.value);
        });

        return formatStringWithObjectParams(StaticCareResources.OutpatientWorkflow.PerformedServicesStep.TogetherRuleInfoFormat, {
            ServiceCodes: serviceCodes.join("; ")
        });
    }

    private getMissingServiceIds(problem: IClientValidationProblem) {
        const ids = _.get(problem.parameters, "PerformedServiceTogetherRuleViolationParametersDto.MissingServiceIds") as any[];
        return ids?.map(i => new FinancedServiceId(i?.Value?.toString())) || [];
    }

    private getServiceId(problem: IClientValidationProblem) {
        const serviceIdValue = _.get(problem.parameters, "PerformedServiceTogetherRuleViolationParametersDto.ServiceId.Value");
        return serviceIdValue ? new FinancedServiceId(serviceIdValue.toString()) : null;
    }
}