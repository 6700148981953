import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalConditionItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionItemBase";
import ReferencedAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/ReferencedAllergyIntoleranceAssignment";
import { AllergyIntoleranceCodeSelectorItem } from "@HisPlatformControls/AllergyIntoleranceCodeSelector/AllergyIntoleranceCodeSelectorItem";
import CustomAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/CustomAllergyIntoleranceAssignment";
import MedicationAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/MedicationAllergyIntoleranceAssignment";
import SubstanceAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/SubstanceAllergyIntoleranceAssignment";
import AllergyIntoleranceAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/AllergyIntoleranceAssignmentBase";
import PatientAllergyIntoleranceId from "@Primitives/PatientAllergyIntoleranceId.g";
import PatientAllergyIntoleranceHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceHistoryItem";
import InternationalNonproprietaryNameAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/InternationalNonproprietaryNameAllergyIntoleranceAssignment";

export default class PatientAllergyIntoleranceStore extends MedicalConditionItemBase<PatientAllergyIntoleranceId> {

    @State.observable.ref public assignment: AllergyIntoleranceAssignmentBase;
    @State.observable public name: string;

    constructor(isNew: boolean) {
        super(isNew);
    }

    @State.computed public get label() {
        if (!this.assignment) {
            return "";
        }

        if (this.assignment instanceof ReferencedAllergyIntoleranceAssignment) {
            return !this.assignment.allergyIntoleranceVersion ? "" : this.assignment.allergyIntoleranceVersion.name;
        } else if (this.assignment instanceof CustomAllergyIntoleranceAssignment) {
            return this.assignment.name;
        } else if (this.assignment instanceof MedicationAllergyIntoleranceAssignment) {
            return !this.assignment.medicationVersion ? "" : this.assignment.medicationVersion.name;
        } else if (this.assignment instanceof SubstanceAllergyIntoleranceAssignment) {
            return !this.assignment.substanceVersion ? "" : this.assignment.substanceVersion.name;
        } else if (this.assignment instanceof InternationalNonproprietaryNameAllergyIntoleranceAssignment) {
            return !this.assignment.internationalNonproprietaryName ? "" : this.assignment.internationalNonproprietaryName.name;
        }
        return "";
    }

    @State.computed public get allergyIntoleranceCodeSelectorItem() {
        if (!this.assignment) {
            return null;
        }
        if (this.assignment instanceof ReferencedAllergyIntoleranceAssignment) {
            return new AllergyIntoleranceCodeSelectorItem({ id: this.assignment.allergyIntoleranceVersionSelector.id });
        } else if (this.assignment instanceof MedicationAllergyIntoleranceAssignment) {
            return new AllergyIntoleranceCodeSelectorItem({ id: this.assignment.medicationVersionSelector.id });
        } else if (this.assignment instanceof SubstanceAllergyIntoleranceAssignment) {
            return new AllergyIntoleranceCodeSelectorItem({ id: this.assignment.substanceVersionSelector.id });
        } else if (this.assignment instanceof InternationalNonproprietaryNameAllergyIntoleranceAssignment) {
            return new AllergyIntoleranceCodeSelectorItem({ id: this.assignment.id });
        } else if (this.assignment instanceof CustomAllergyIntoleranceAssignment) {
            return new AllergyIntoleranceCodeSelectorItem({ text: this.assignment.name });
        }
        return null;
    }

    @State.action.bound
    public setAssignment(newValue: AllergyIntoleranceAssignmentBase) {
        this.assignment = newValue;
    }

    @State.action.bound
    public setName(newValue: string) {
        this.name = newValue;
    }

    @State.action.bound
    public addNewVersionTypedChanges() {
        const typedHistoryItem = this.currentHistoryItem as PatientAllergyIntoleranceHistoryItem;
        typedHistoryItem.setReactions(typedHistoryItem.reactions);
    }
}