import Di from "@Di";
import IFilterService from "@HisPlatform/Services/Definition/FilterService/IFilterService";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IncludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierFilter";
import ExcludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierFilter";

@Di.injectable()
export default class FilterService implements IFilterService {

    public filterByCode<TSource extends { code: string; }>(source: TSource[], filters?: FilterBase[]): TSource[] {
        if (arrayIsNullOrEmpty(filters)) { return source; }

        const [includeCodes, excludeCodes] = this.processFilters(filters);

        if (includeCodes.length > 0) {
            return source.filter(it => includeCodes.includes(it.code));
        }

        if (excludeCodes.length > 0) {
            return source.filter(it => !excludeCodes.includes(it.code));
        }

        return source;
    }

    public filterBySelector<TSource>(source: TSource[], selectorFunc: (item: TSource) => string, filters?: FilterBase[]): TSource[] {
        if (arrayIsNullOrEmpty(filters)) { return source; }

        return source;
    }

    private processFilters(filters: FilterBase[]): [string[], string[]] {
        
        const includeValues: string[] = [];
        const excludeValues: string[] = [];

        filters.forEach(filter => {
                if (filter instanceof IncludeIdentifierFilter) {
                    includeValues.push(filter.value.value);
                } else if (filter instanceof ExcludeIdentifierFilter) {
                    excludeValues.push(filter.value.value);
                } else {
                    throw new Error(`Unsupported filter: ${typeof filter}`);
                }
            });

        if (includeValues.length > 0 && excludeValues.length > 0) {
            throw new Error("Include and exclude filters cannot be specified at the same time.");
        }

        return [includeValues, excludeValues];
    }

}