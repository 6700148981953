import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IPractitionSpecialtySceletBoxDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IPractitionSpecialtySceletBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IPractitionSpecialtySceletBoxDependencies;
}

@State.observer
class PractitionSpecialtySceletBox extends React.Component<IPractitionSpecialtySceletBoxProps> {
    private get dependencies() { return this.props._dependencies; }
    private get extensibleEnumStore() { return this.dependencies.careReferenceDataStore.practitionSpecialty; }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    PractitionSpecialtySceletBox,
    new DependencyAdapter<IPractitionSpecialtySceletBoxProps, IPractitionSpecialtySceletBoxDependencies>( (containerService) => {
        return {
            careReferenceDataStore: containerService.resolve("CareReferenceDataStore")
        };
    })
);
