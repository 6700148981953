import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/TokenBasedDataGathering/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import IToken from "@HisPlatform/BoundedContexts/TokenBasedDataGathering/ApplicationLogic/Model/Token/IToken";

@Di.injectable()
export default class TokenApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("ITokenClient") private readonly apiClient: Proxy.ITokenClient,
    ) {
        super();
    }

    public getTokenByNameAsync(name: string) {
        return this.processOperationAsync(
            new SimpleStore<IToken>(),
            async target => {
                const response = await this.apiClient.getTokenByNameQueryAsync(CreateRequestId(), name);

                target.operationInfo = createOperationInfo(response);
                target.value = this.mapToken(response.token);
            }
        );
    }

    public getAllTokensAsync() {
        return this.processOperationAsync(
            new SimpleStore<IToken[]>(),
            async target => {
                const response = await this.apiClient.getAllTokensQueryAsync(CreateRequestId());

                target.operationInfo = createOperationInfo(response);
                target.value = response.tokens.map(this.mapToken);
            }
        );
    }

    private mapToken(dto: Proxy.TokenDto): IToken {
        return {
            name: dto.name,
            description: dto.description,
            providersName: dto.providersName,
            tokenValueProviderAddress: dto.tokenValueProviderAddress,
            providersParameter: dto.providerParameterConfiguration
        };
    }

}