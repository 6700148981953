import React from "react";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { ICreateNewDocumentDialogParams, ICreateNewDocumentDialogResult } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/CreateNewDocumentDialog/CreateNewDocumentDialogParams";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CreateNewDocumentDialogView from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/CreateNewDocumentDialog/CreateNewDocumentDialogView";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DocumentKind from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentKind.g";

interface ICreateNewDocumentDialogProps extends IModalComponentParams<ICreateNewDocumentDialogResult>, ICreateNewDocumentDialogParams {
    // this is usually empty, or contains only a _dependencies prop
}

/** @screen */
@State.observer
class CreateNewDocumentDialog extends React.Component<ICreateNewDocumentDialogProps> {

    @State.observable.ref private kind: DocumentKind = DocumentKind.TemplateBased;

    public render() {
        return (
            <CreateNewDocumentDialogView
                kind={this.kind}
                onClose={this.cancel}
                onKindChange={this.setKind}
                onSuccess={this.onSuccess}
                scope={this.props.scope}
                patientId={this.props.patientId}
            />
        );
    }

    @State.bound
    private cancel() {
        this.props.onClose(null);
    }

    @State.bound
    private onSuccess(dialogResult: ICreateNewDocumentDialogResult) {
        this.props.onClose(dialogResult);
    }

    @State.action.bound
    private setKind(value: DocumentKind) {
        this.kind = value;
    }
}

export default connect(
    CreateNewDocumentDialog
);