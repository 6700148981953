import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import UseCases from "@Primitives/UseCases";
import PatientEHRRoutes from "@HisPlatform/Components/Pages/Patient/PatientEHRRoutes";
import PatientsCareActivitiesPage from "@HisPlatform/Components/Pages/Patient/PatientsCareActivitiesPage/PatientsCareActivitiesPage";
import PatientsDocumentsPage from "@HisPlatform/Components/Pages/Patient/PatientsDocumentsPage/PatientsDocumentsPage";
import PatientsServiceRequestsPage from "@HisPlatform/Components/Pages/Patient/PatientsServiceRequestsPage/PatientsServiceRequestsPage";

export default function configureEHRUseCases(useCaseRegistry: IUseCaseRegistry) {
    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            UseCases.careRegisterViewPatientServiceRequests,
            builder => {
                builder.standalone(b => b
                    .routeDefinition(PatientEHRRoutes.serviceRequests).additionalRouteParams({ mode: "read-only" })
                    .component(PatientsServiceRequestsPage));
            }
        )
    );

    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            UseCases.careRegisterViewPatientDocuments,
            builder => {
                builder.standalone(b => b
                    .routeDefinition(PatientEHRRoutes.documents).additionalRouteParams({ mode: "read-only" })
                    .component(PatientsDocumentsPage));
            }
        )
    );

    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            UseCases.careRegisterViewPatientCareActivities,
            builder => {
                builder.standalone(b => b
                    .routeDefinition(PatientEHRRoutes.careActivities).additionalRouteParams({mode: "read-only"})
                    .component(PatientsCareActivitiesPage));
            }
        )
    );
}