import VaccineId from "@Primitives/VaccineId.g";

export class VaccineCodeSelectorItem {
    public id: VaccineId;
    public name?: string;
    public code?: string;
    public text?: string;

    constructor({ id = null, name = "", code = "",  text = "" }: { id?: VaccineId, name?: string, code?: string, text?: string }) {
        this.id = id;
        this.name = name;
        this.text = text;
        this.code = code;
    }
}