import IOperationInfo from "./IOperationInfo";
import CommandOperationInfo from "./CommandOperationInfo";
import LockAcquirerOperationInfo from "./LockAcquirerOperationInfo";
import RequestStatus from "./RequestStatus";
import BusinessErrorOperationInfo from "./BusinessErrorOperationInfo";
import TechnicalErrorOperationInfo from "./TechnicalErrorOperationInfo";
import QueryOperationInfo from "./QueryOperationInfo";
import LockInfo from "./LockInfo";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export function createOperationInfo(response: any): IOperationInfo {
    if (isNullOrUndefined(response)) {
        return null;
    } else if ("wasLockRequested" in response) {
        return new LockAcquirerOperationInfo(
            response.isPersisted ?? true,
            new LockInfo(
                response.lockState as unknown as EntityLockState,
                response.heldLockId,
                response.wasLockRequested,
                response.hasUpdatePermission,
                response.preventingLock?.owner?.name,
                response.preventingLock?.id,
                response.preventingLock?.owner?.userId
            ),
            response.operationId
        );
    } else if ("isPersisted" in response) {
        return new CommandOperationInfo(response.isPersisted, null, null, response.operationId);
    } else if (response.status === RequestStatus.BusinessError) {
        return new BusinessErrorOperationInfo(response.businessError, response.operationId);
    } else if (response.status === RequestStatus.TechnicalError) {
        return new TechnicalErrorOperationInfo(response.operationId);
    } else {
        return new QueryOperationInfo(response.operationId);
    }
}

// streaming file responses can be null
export function createOperationInfoForFileResponse(response: any) {
    if (isNullOrUndefined(response)) {
        return new QueryOperationInfo(null, null);
    } else {
        return createOperationInfo(response);
    }
}

export function createOperationInfoWithValidationResult(request: any, hasValidationError: boolean, hasValidationWarning: boolean): IOperationInfo {
    if (!!request && "isPersisted" in request) {
        return new CommandOperationInfo(request.isPersisted, hasValidationError, hasValidationWarning, request.operationId);
    }
    return createOperationInfo(request);
}
