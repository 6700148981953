import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import NameStore from "@Primitives/NameStore";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";

interface IPersonNameColumnDependencies {
    localizationService: ILocalizationService;
}

interface IPersonNameColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IPersonNameColumnDependencies;
}


const PersonNameColumn: React.SFC<IPersonNameColumnProps> = (props: IPersonNameColumnProps) => {

    const valueRenderer = useCallback((value: NameStore) => {
        return isNullOrUndefined(value) ? null : props._dependencies.localizationService.localizePersonName(value);
    }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
        />
    );
};

export default connect(
    PersonNameColumn,
    new DependencyAdapter<IPersonNameColumnProps, IPersonNameColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);