import config from "@Config";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EditableMultiLingualLabel from "@Toolkit/FormEngine/Model/Layout/EditableMultiLingualLabel";

enum Language {
    Hungarian,
    English,
    Slovak,
    Czech,
}

export function convertLanguageToCultureCode(language: Language) {
    if (language === Language.English) {
        return "en-US";
    } else if (language === Language.Slovak) {
        return "sk-SK";
    } else if (language === Language.Czech) {
        return "cs-CZ";
    } else {
        return config.defaultCultureCode;
    }
}

export function convertCultureCodeToLanguage(cultureCode: string) {
    if (cultureCode === "en-US") {
        return Language.English;
    } else if (cultureCode === "sk-SK") {
        return Language.Slovak;
    } else if (cultureCode === "cs-CZ") {
        return Language.Czech;
    } else {
        return Language.Hungarian;
    }
}

export function getCurrentOrDefaultCultureCode(currentCultureCode: string, multiLingualLabel: EditableMultiLingualLabel) {
    if (isNullOrEmptyString(multiLingualLabel.getLocalizedLabel(currentCultureCode))) {
        if (isNullOrEmptyString(multiLingualLabel.getLocalizedLabel(config.defaultCultureCode))) {
            return convertCultureCodeToLanguage(Array.from(multiLingualLabel.localizedLabels.keys())[0]);
        }
        return convertCultureCodeToLanguage(config.defaultCultureCode);
    }
    return convertCultureCodeToLanguage(currentCultureCode);
}

export default Language;