import React from "react";
import DashboardLayoutView from "@HisPlatformControls/DashboardLayout/DashboardLayoutView";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IDashboardLayoutProps {
    style?: React.CSSProperties;
}

@State.observer
export default class DashboardLayout extends React.Component<IDashboardLayoutProps> {
    public render() {
        return (
            <DashboardLayoutView style={this.props.style}>
                {this.props.children}
            </DashboardLayoutView>
        );
    }

}