import { IPrescriptionsToBeCreatedListItem } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionsToBeCreated/IPrescriptionsToBeCreatedListItem";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import PermanentPrescriptionId from "@Primitives/PermanentPrescriptionId.g";

export class PermanentPrescriptionBasedPrescriptionsToBeCreatedListItem implements IPrescriptionsToBeCreatedListItem {
    constructor(
        public readonly permanentPrescriptionId: PermanentPrescriptionId,
        public readonly name: string,
        public readonly claimType: MedicationSubsidyClaimTypeId
    ) {
    }
}
