import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import ConditionStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/ConditionStatementStore";
import ConditionStatementId from "@Primitives/ConditionStatementId.g";
import ReferencedConditionAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/ReferencedConditionAssignment";
import CustomConditionAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/CustomConditionAssignment";
import ConditionStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/ConditionStatementHistoryItem";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import FormerConditionDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/FormerConditionDetail";

type ResponseType = Proxy.GetConditionStatementByIdCommandResponse | Proxy.AddConditionStatementCommandResponse | Proxy.UpdateConditionStatementCommandResponse | Proxy.DeleteConditionStatementHistoryItemCommandResponse;

@Di.injectable()
export default class ConditionStatementStoreMapper extends LockingEntityStoreMapper<Proxy.ConditionStatementDto, ConditionStatementStore, ResponseType> {

    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    protected storeEntityIdType = ConditionStatementId;

    protected entityDtoSelector = (response: ResponseType) => {
        return response.conditionStatementDto;
    };

    protected applyToStoreCore(target: ConditionStatementStore, response: any) {
        const dto = this.entityDtoSelector(response);
        if (dto != null) {
            target.id = dto.id;
            target.conditionAssignment = this.getConditionAssignment(dto);
            target.clinicalStatus = dto.clinicalStatus;

            target.historyItems = State.observable(this.getHistoryItems(dto.historyItems));
            target.currentItemIndex = target.historyItems.length - 1;
            target.patientId = dto.patientId;
            target.extensionData = dto.extensionData;
        }
    }

    private getHistoryItems(dtoArray: Proxy.ConditionStatementHistoryItemDto[]): ConditionStatementHistoryItem[] {
        return dtoArray.map(dto => this.getHistoryItem(dto)).sort((a, b) => a.versionNumber - b.versionNumber);
    }

    private getHistoryItem(dto: Proxy.ConditionStatementHistoryItemDto): ConditionStatementHistoryItem {
        const returnItem = new ConditionStatementHistoryItem();

        returnItem.onsetDate = dto.onsetDate;
        returnItem.description = dto.description;
        returnItem.clinicalStatus = dto.clinicalStatus;
        returnItem.historyItemDetail = this.getHistoryItemDetail(dto.historyItemDetail);
        returnItem.formerConditionDetail = this.getFormerConditionDetail(dto);
        returnItem.versionNumber = dto.versionNumber;
        returnItem.index = dto.index;
        returnItem.createdAt = dto.createdAt;
        returnItem.extensionData = dto.extensionData;

        return returnItem;
    }

    private getHistoryItemDetail(dto: Proxy.MedicalConditionHistoryItemDetailDto): MedicalConditionHistoryItemDetail {
        const returnItem = new MedicalConditionHistoryItemDetail();

        returnItem.asserter = dto.asserter;
        returnItem.asserterType = dto.asserterType;
        returnItem.notes = dto.notes;
        returnItem.verifier = dto.verifier;

        return returnItem;
    }

    private getConditionAssignment(dto: Proxy.ConditionStatementDto) {
        let conditionAssignment;

        if (dto.conditionAssignment instanceof Proxy.ReferencedConditionAssignmentDto) {
            conditionAssignment = new ReferencedConditionAssignment(dto.conditionAssignment.conditionId);
        } else if (dto.conditionAssignment instanceof Proxy.CustomConditionAssignmentDto) {
            conditionAssignment = new CustomConditionAssignment(dto.conditionAssignment.name);
        }

        return conditionAssignment;
    }

    private getFormerConditionDetail(dto: Proxy.ConditionStatementHistoryItemDto) {
        return new FormerConditionDetail(
            dto.formerConditionDetail.inactivationDescription,
            dto.formerConditionDetail.inactivationDate);
    }
}
