import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Authorization/Api/Proxy.g";
import AccessRuleStoreMapper from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/OperationAccessControl/AccessRuleStoreMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import UserGroupId from "@Primitives/UserGroupId.g";
import UserId from "@Primitives/UserId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import RoleAccessRule from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/RoleAccessRule";
import { createOperationInfo, createOperationInfoWithValidationResult } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { getAccessRuleDto, getDeleteAccessRuleDto, getSetAccessRuleDto } from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/OperationAccessControl/AccessRuleDtoMapper";
import AccessRuleBase from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/AccessRuleBase";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

@Di.injectable()
export default class AccessRuleApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IOperationAccessControlClient") private readonly apiClient: Proxy.IOperationAccessControlClient,
        @Di.inject("AccessRuleStoreMapper") private readonly accessRuleStoreMapper: AccessRuleStoreMapper
    ) {
        super();
    }

    @State.bound
    public getAccessRulesAsync(entityId: UserGroupId | UserId): Promise<SimpleStore<RoleAccessRule[]>> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.getAccessRulesQueryAsync(
                    CreateRequestId(),
                    getAccessRuleDto(entityId)
                );

                this.accessRuleStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public setAccessRulesAsync(accessRules: AccessRuleBase[]): Promise<SimpleStore<AccessRuleBase[]>> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.setAccessRulesCommandAsync(
                    CreateRequestId(),
                    getSetAccessRuleDto(accessRules)
                );
                this.accessRuleStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public validateAccessRules(accessRules: AccessRuleBase[]) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response = await this.apiClient.setAccessRulesCommandAsync(
                    CreateRequestId(),
                    getSetAccessRuleDto(accessRules, true),
                );

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    @State.bound
    public deleteAccessRuleAsync(accessRule: AccessRuleBase) {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.deleteAccessRulesCommandAsync(
                    CreateRequestId(),
                    getDeleteAccessRuleDto(accessRule)
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}