import PatientId from "@Primitives/PatientId.g";
import * as CareProxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import RiskAssessmentStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/RiskAssessmentStore";
import ReferencedLifeStyleRiskAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/ReferencedLifeStyleRiskAssignment";
import CustomRiskAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/CustomRiskAssignment";
import RiskAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/RiskAssignmentBase";
import RiskAssessmentHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/RiskAssessmentHistoryItem";
import { getHistoryItemDetailDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalConditionDtoMapper";
import RiskDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/RiskDetail";
import LockId from "@Toolkit/CommonWeb/Model/LockId";

export function getAddRiskAssessmentControllerDto(source: RiskAssessmentStore, patientId: PatientId, isValidateOnly: boolean) {
    return new CareProxy.AddRiskAssessmentControllerDto({
        patientId: patientId,
        historyItemDtos: [getRiskAssessmentHistoryItemDto(source.latestHistoryItem as RiskAssessmentHistoryItem)],
        riskAssignmentBaseDto:  getRiskAssignmentDto(source.riskAssignment),
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData
    });
}

export function getUpdateRiskAssessmentControllerDto(source: RiskAssessmentStore, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.UpdateRiskAssessmentControllerDto({
        riskAssessmentId: source.id,
        historyItemDtos: [getRiskAssessmentHistoryItemDto(source.latestHistoryItem as RiskAssessmentHistoryItem)],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteRiskAssessmentHistoryItemControllerDto(source: RiskAssessmentStore, versionNumber: number, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.DeleteRiskAssessmentHistoryItemControllerDto({
        riskAssessmentId: source.id,
        versionNumbers: [versionNumber],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteRiskAssessmentControllerDto(store: RiskAssessmentStore) {
    return new CareProxy.DeleteRiskAssessmentControllerDto({
        riskAssessmentId: store.id,
        lockId: store.lockInfo?.lockId,
        extensionData: store.extensionData,
        releaseLockIfSuccessful: true
    });
}

function getRiskAssignmentDto(source: RiskAssignmentBase) {
    let dto;

    if (source instanceof ReferencedLifeStyleRiskAssignment) {
        dto = new CareProxy.ReferencedLifestyleRiskAssignmentDto({ lifeStyleRiskId: source.lifeStyleRiskId });
    } else if (source instanceof CustomRiskAssignment) {
        dto = new CareProxy.CustomRiskAssignmentDto({ name: source.name });
    }

    return dto;
}

function getRiskAssessmentHistoryItemDto(source: RiskAssessmentHistoryItem) {
    return new CareProxy.RiskAssessmentHistoryItemDto({
        versionNumber: source.versionNumber,
        index: source.index,
        createdAt: source.createdAt,
        historyItemDetail: getHistoryItemDetailDto(source.historyItemDetail),        
        riskDetail: getRiskDetailDto(source.riskDetail),
        extensionData: source.extensionData        
    });
}

function getRiskDetailDto(source: RiskDetail) {
    return new CareProxy.RiskDetailDto({
        description: source.description,
        periodDescription: source.periodDescription
    });
}
