import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import PrescriptionId from "@Primitives/PrescriptionId.g";

export interface IPrintPrescriptionDialogParams extends IModalParams {
    prescriptionId: PrescriptionId;
}

export interface IPrintPrescriptionDialogResult {
    wasPrinted: boolean;
}

export default class PrintPrescriptionDialogParams implements IPrintPrescriptionDialogParams {
    public static type = "PrintPrescriptionDialog";
    public get type() { return PrintPrescriptionDialogParams.type; }

    constructor(public prescriptionId: PrescriptionId) { }
}