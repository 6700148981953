import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IEhiMedicalConditionReferenceDataResolver from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Services/Definition/EhiMedicalConditionDataResolver/IEhiMedicalConditionReferenceDataResolver";
import TherapyId from "@Primitives/TherapyId.g";
import { TherapyCodeSelectorItem } from "@HunEHealthInfrastructurePlugin/Common/Components/TherapyCodeSelector/TherapyCodeSelectorItem";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";

@Di.injectable()
export default class EhiMedicalConditionReferenceDataResolver implements IEhiMedicalConditionReferenceDataResolver {
    constructor(
        @Di.inject("HunEhiCareReferenceDataStore") private ehiCareReferenceDataStore: HunEhiCareReferenceDataStore
    ) { }

    @State.action.bound
    public async getTherapyNameAsync(value: TherapyCodeSelectorItem) {
        let item;
        if (value.id instanceof TherapyId) {
            if (!value.text) {
                item = await this.ehiCareReferenceDataStore.therapies.getOrLoadAsync(value.id);
            } else {
                item = { name: value.text };
            }
        } else {
            item = { name: value.text };
        }
        return item.name;
    }
}