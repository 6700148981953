import FormDataElementBase from "./FormDataElementBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class ReferencedExtensibleEnumFormDataElement extends FormDataElementBase {

    @State.observable.ref public extensibleEnum: string;

    constructor(
        name: string,
        isArray: boolean,
        defaultValue: string,
        isReadOnly: boolean,
        isVisible: boolean,
        extensibleEnum?: string
        
    ) {
        super(name, isArray, defaultValue, isReadOnly, isVisible);
        this.extensibleEnum = extensibleEnum;
    }

    @State.action.bound
    public setExtensibleEnum(extensibleEnum: string) {
        this.extensibleEnum = extensibleEnum;
    }

    public formDataElementType = "ReferencedExtensibleEnum";
}