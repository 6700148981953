import { ValueConverter } from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ValueConverter";
import Device from "@AssecoMedPlugin/BoundedContexts/Care/ApplicationLogic/Model/Worklist/Device";
import Measurement from "@AssecoMedPlugin/BoundedContexts/Care/ApplicationLogic/Model/Worklist/Measurement";
import moment from "moment";

export default {
    DeviceListDto: rawValue => Object.keys(rawValue.Devices).map(d => new Device(
        rawValue.Devices[d].Name,
        rawValue.Devices[d].DeviceType,
        moment(rawValue.Devices[d].LastMeasuredAt),
        Object.keys(rawValue.Devices[d].Measurements).map(m => new Measurement(
            rawValue.Devices[d].Measurements[m].Name,
            rawValue.Devices[d].Measurements[m].Value,
            rawValue.Devices[d].Measurements[m].Metric,
            rawValue.Devices[d].Measurements[m].EffectiveDateTime
        ))
    ))
} as ValueConverter;