import React from "react";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import IPatientCareActivitiesDataSource from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientCareActivitiesDataSource";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ResourceId from "@Primitives/ResourceId.g";
import IDoctor from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IDoctor";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import * as Styles from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PatientsCareActivitiesPanel/PatientCareActivitiesMasterDetailPanel.less";
import * as Ui from "@CommonControls";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import PatientId from "@Primitives/PatientId.g";
import PatientCareActivitiesListView from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PatientsCareActivitiesPanel/PatientCareActivitiesListView";
import DocumentId from "@Primitives/DocumentId.g";
import CareActivityContextProvider from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextProvider";
import PatientContextProvider from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextProvider";
import ViewContextProvider from "@Toolkit/ReactClient/Components/ViewContext/ViewContextProvider";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import IPatientCareActivitiesTabRegistry from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabRegistry";

interface IPatientCareActivitiesMasterDetailPanelViewProps {
    _dependencies?: IPatientCareActivitiesMasterDetailPanelViewDependencies;

    availableStepTypes: string[];
    activeTabName: string;
    setActiveTabName: (newValue: string) => void;
    setActiveExtensionTabName: (newValue: string) => void;
    isDetailLoading: boolean;

    selectedCareActivity: PatientsCareActivityStore;
    selectCareActivityAsync: (row: PatientsCareActivityStore) => Promise<void>;
    showPrimaryDocumentAsync: (row: PatientsCareActivityStore) => Promise<void>;
    resetSelectedCareActivity: () => void;

    dataSource: IPatientCareActivitiesDataSource;
    isUnauthorizedAccess: boolean;
    isFilterOpen: boolean;
    clearFilters: () => void;
    openOrCloseFilters: () => void;

    patientId: PatientId;

    popperReferenceElement: HTMLElement;
    tooltipContent: React.ReactNode;
    isTooltipOpen: boolean;
    hasScroller?: boolean;

    selectedDocumentId: DocumentId;
    onSelectedDocumentIdChange: (newId: DocumentId) => void;

    title?: string;
}

interface IPatientCareActivitiesMasterDetailPanelViewDependencies {
    localizationService: ILocalizationService;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    authorizationService: AuthorizationService;
    patientCareActivitiesTabRegistry: IPatientCareActivitiesTabRegistry;
}

@State.observer
class PatientCareActivitiesMasterDetailPanelView extends React.Component<IPatientCareActivitiesMasterDetailPanelViewProps> {
    private get filterStore() {
        return this.props.dataSource.filterStore;
    }

    private get localizationService() {
        return this.props._dependencies.localizationService;
    }

    private get organizationReferenceDataStore() {
        return this.props._dependencies.organizationReferenceDataStore;
    }

    @State.bound
    private getFilterText() {
        const parts: string[] = [];

        const labels = StaticCareResources.EHR.PatientsCareActivities.Labels;

        if (this.filterStore.careIdentifier) {
            parts.push(labels.CareIdentifier + ": " + this.filterStore.careIdentifier);
        }

        if (this.filterStore.careActivitySource) {
            parts.push(labels.CareActivitySource + ": "
                + this.localizationService.localizeReferenceData(new ResourceId("CareRegister.CareActivitySource." + this.filterStore.careActivitySource.value))); // TODO: this should be generated to some degree
        }

        if (this.filterStore.careActivityStates && this.filterStore.careActivityStates.length > 0) {
            const stateParts = this.filterStore.careActivityStates.map(state =>
                this.localizationService.localizeEnum(CareActivityState[state], "CareActivityState").Name);
            parts.push(labels.CareActivityState + ": " + stateParts.join(", "));
        }

        if (this.filterStore.careTypeIds && this.filterStore.careTypeIds.length > 0) {
            const typeParts = this.filterStore.careTypeIds.map(type =>
                this.localizationService.localizeEnumId(type).Name);
            parts.push(labels.CareType + ": " + typeParts.join(", "));
        }

        if (this.filterStore.pointOfCareIds && this.filterStore.pointOfCareIds.length > 0) {
            const pointOfCareParts = this.filterStore.pointOfCareIds.map(pocId =>
                this.organizationReferenceDataStore.allPointsOfCareMap.get(pocId).shorthand);
            parts.push(labels.PointOfCare + ": " + pointOfCareParts.join(", "));
        }

        if (this.filterStore.dateRange && !this.filterStore.dateRange.isUnbound()) {
            parts.push(labels.DateRange + ": "
                + this.localizationService.localizeDate(this.filterStore.dateRange.from)
                + " - " + this.localizationService.localizeDate(this.filterStore.dateRange.to));
        }

        if (this.filterStore.practitionerIds && this.filterStore.practitionerIds.length > 0) {
            const practitioners = this.filterStore.practitionerIds.map((practitionerId) => this.organizationReferenceDataStore.doctorMap.get(practitionerId));
            if (practitioners) {
                parts.push(`${labels.LeadDoctor}: ${this.getDoctorNames(practitioners)}`);
            }
        }

        return (parts && parts.length > 0) ? (labels.Filters + ": " + parts.join(" | ")) : "";
    }

    @State.bound
    private getDoctorNames(practitioners: IDoctor[]) {
        return practitioners.map((practitioner) => practitioner.code + " " + this.localizationService.localizePersonName(practitioner.name)).join(", ");
    }

    @State.bound
    private renderMasterToolbar() {
        const buttonClassName = new CompositeClassName();
        buttonClassName.addIf(this.props.isFilterOpen, Styles.flipIcon);

        return (
            <Ui.Flex xsVerticalAlign={"middle"} verticalSpacing={"none"}>
                <Ui.Flex.Item>
                    {!this.props.selectedCareActivity && <Ui.Button
                        text={StaticCareResources.EHR.PatientsCareActivities.Buttons.ClearFilters}
                        onClick={this.props.clearFilters}
                        size="compact"
                        stopPropagation={{ leftClick: true }}
                        automationId="clearFilters"
                    />}
                    {!this.props.selectedCareActivity && <Ui.Button
                        iconName={"chevronDown"}
                        text={StaticCareResources.EHR.PatientsCareActivities.Labels.ShowFilters}
                        onClick={this.props.openOrCloseFilters}
                        size="compact"
                        stopPropagation={{ leftClick: true }}
                        className={buttonClassName.classNames}
                        automationId="showFilters"
                    />}
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.bound
    private localizeStepTypeName(stepType: string) {
        return this.localizationService.localizeReferenceData(new ResourceId("PatientCareActivities.TabName." + stepType));
    }

    @State.bound
    private renderTab(stepType: string, key: number, content: React.ReactNode) {

        return (
            <MasterDetail.Tab
                key={key}
                title={""}
                tabName={stepType}
                tabTitle={this.localizeStepTypeName(stepType)}
                automationId={stepType}
            >
                <CareActivityContextProvider careActivityId={this.props.selectedCareActivity?.careActivityId}>
                    <PatientContextProvider>
                        <ViewContextProvider totalHeightLoss={149}>
                            {content}
                        </ViewContextProvider>
                    </PatientContextProvider>
                </CareActivityContextProvider>
            </MasterDetail.Tab>
        );
    }

    @State.bound
    private renderInHouseTabs() {
        const tabs: React.ReactNode[] = [];

        if (this.props.isDetailLoading) {
            return <></>;
        }

        const components = this.props._dependencies.patientCareActivitiesTabRegistry.getAllComponents(this.props.selectedCareActivity.careActivityId, 
            this.props.selectedDocumentId, 
            this.props.selectedCareActivity.beginningOfCare, 
            this.props.selectedCareActivity.dischargedAt,
            false);

        this.props.availableStepTypes.map((type, key) => {
            tabs.push(this.renderTab(type, key, components.find(i => i.tabName === type).content));
        });

        return (
            <MasterDetail.Detail>
                {tabs}
            </MasterDetail.Detail>
        );
    }

    private renderExtensionTabs() {
        if (this.props.isDetailLoading) {
            return <></>;
        }

        return (
            <HisPlatformExtensionPoint
                extensionProps={{
                    careActivitySource: this.props.selectedCareActivity?.careActivitySource,
                    extensionData: this.props.selectedCareActivity?.extensionData,
                    patientId: this.props.patientId,
                    careActivityId: this.props.selectedCareActivity?.careActivityId,
                    listExtensionData: this.filterStore?.getExtensionData?.(),
                    activeTabName: this.props.activeTabName,
                    setActiveTabName: this.props.setActiveExtensionTabName,
                    dataSource: this.props.dataSource,
                    beginningOfCare: this.props.selectedCareActivity?.beginningOfCare,
                    dischargedAt: this.props.selectedCareActivity?.dischargedAt,
                    careTypeId: this.props.selectedCareActivity?.careTypeId
                }}
                type="patientsCareActivityDetail">
                <></>
            </HisPlatformExtensionPoint>
        );
    }

    private renderList() {
        return (
            <MasterDetail.Master
                title={this.props.title ?? StaticCareResources.EHR.PatientsCareActivities.Title}
                iconName={"patientLife"}
                subTitle={this.getFilterText()}
                toolbar={this.renderMasterToolbar()}
            >
                <PatientCareActivitiesListView
                    selectedCareActivity={this.props.selectedCareActivity}
                    selectCareActivityAsync={this.props.selectCareActivityAsync}
                    isFilterOpen={this.props.isFilterOpen}
                    filterStore={this.filterStore}
                    dataSource={this.props.dataSource}
                    showSomeItemsAreHiddenMessage={this.props.dataSource.isPermissionFiltered}
                    resetSelectedCareActivity={this.props.resetSelectedCareActivity}
                    showPrimaryDocumentAsync={this.props.showPrimaryDocumentAsync}
                    patientId={this.props.patientId}
                    tooltipReferenceElement={this.props.popperReferenceElement}
                    tooltipContent={this.props.tooltipContent}
                    isTooltipOpen={this.props.isTooltipOpen}
                />
            </MasterDetail.Master>
        );
    }

    public render() {
        if (this.props.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticCareResources.EHR.PatientsCareActivities.Title} />;
        }

        return (
            <MasterDetailLayout
                selectedItem={this.props.selectedCareActivity}
                onSelectedItemChange={this.props.selectCareActivityAsync} // todo fix async problem
                activeTabName={this.props.activeTabName}
                onActiveTabNameChange={this.props.selectedCareActivity?.careActivitySource?.value === "InHouse" ? this.props.setActiveTabName : this.props.setActiveExtensionTabName} // todo fix async problem
                hasTabs
                hideDetailHeader
                style={{ padding: "8px 8px" }}
                master={this.renderList()}
                detail={this.props.selectedCareActivity?.careActivitySource?.value === "InHouse" ? this.renderInHouseTabs() : this.renderExtensionTabs()}
            />
        );
    }
}

export default connect(
    PatientCareActivitiesMasterDetailPanelView,
    new DependencyAdapter<IPatientCareActivitiesMasterDetailPanelViewProps, IPatientCareActivitiesMasterDetailPanelViewDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        authorizationService: c.resolve("AuthorizationService"),
        patientCareActivitiesTabRegistry: c.resolve("IPatientCareActivitiesTabRegistry")
    }))
);
