import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import ViewSubmittedPrescriptionsStandaloneHost from "@HunEHealthInfrastructurePlugin/UseCases/ViewSubmittedPrescriptionsUseCase/ViewSubmittedPrescriptionsStandaloneHost";
import PatientEHRRoutes from "@HisPlatform/Components/Pages/Patient/PatientEHRRoutes";

export default UseCaseDescriptorBuilder.create(
    HunEHealthInfrastructureUseCases.viewSubmittedPrescriptions,
    builder => {
        builder.standalone(b => b
            .routeDefinition(PatientEHRRoutes.prescriptions)
            .additionalRouteParams({ mode: "read-only" })
            .component(ViewSubmittedPrescriptionsStandaloneHost)
        );
    }
);