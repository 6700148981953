import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";

interface IPaymentTypeSelectBoxDependencies {
    financeReferenceDataDataStore: FinanceReferenceDataStore;
}

interface IPaymentTypeSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IPaymentTypeSelectBoxDependencies;
}


@State.observer
class PaymentTypeSelectBox extends React.Component<IPaymentTypeSelectBoxProps> {
    private get dependencies() { return this.props._dependencies; }
    private get extensibleEnumStore() { return this.dependencies.financeReferenceDataDataStore.paymentTypeMap; }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    PaymentTypeSelectBox,
    new DependencyAdapter<IPaymentTypeSelectBoxProps, IPaymentTypeSelectBoxDependencies>((containerService) => {
        return {
            financeReferenceDataDataStore: containerService.resolve("FinanceReferenceDataStore")
        };
    })
);
