import NDataPanel, { INDataPanelProps, INDataPanelDependencies } from "./NDataPanel";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import { ICondition } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistFilterCondition";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";

export interface IWorklistTypeBoundNDataPanelProps extends Omit<INDataPanelProps, "definition"> {
    worklistDefinitionReference: string;
    cacheKey?: string;
    dynamicFilters?: { [fieldName: string]: ICondition };
}

@State.observer
class WorklistTypeBoundNDataPanel extends React.Component<IWorklistTypeBoundNDataPanelProps> {
    @State.observable.ref private worklistDefinition: IWorklistDefinition;

    private get worklistApiAdapter() {
        return this.props._dependencies.worklistApiAdapter;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IWorklistTypeBoundNDataPanelProps) {
        if (this.props.dynamicFilters !== prevProps.dynamicFilters) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    protected async loadAsync() {
        const worklistDefinition = await this.worklistApiAdapter.getBoundWorklistDefinition(this.props.worklistDefinitionReference, this.props.cacheKey, this.props.dynamicFilters);

        State.runInAction(() => this.worklistDefinition = worklistDefinition.value);
    }

    public render() {
        if (!this.worklistDefinition) {
            return null; }
        return (
            <NDataPanel hasRefreshButton {...this.props} definition={this.worklistDefinition} />
        );
    }
}

export default connect(
    WorklistTypeBoundNDataPanel,
    new DependencyAdapter<IWorklistTypeBoundNDataPanelProps, INDataPanelDependencies>(c => ({
        worklistApiAdapter: c.resolve("WorklistApiAdapter"),
        notificationService: c.resolve("INotificationService"),
        localizationService: c.resolve("ILocalizationService"),
        fileSaverService: c.resolve("IFileSaverService"),
        activityRegistry: c.resolve("IActivityRegistry"),
        cultureCodeProvider: c.resolve("ICurrentCultureProvider"),
    }))
);
