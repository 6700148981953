import Di from "@Di";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import ReferenceDataCollector from "@HisPlatform/Services/Definition/ReferenceDataLoader/ReferenceDataCollector";
import INDataAttributeService from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/INDataAttributeService";

@Di.injectable()
export default class DynamicValueConverter {

    private collectors = new Map<string, ReferenceDataCollector>();

    constructor(
        @Di.inject("IReferenceDataLoader") private readonly referenceDataLoader: IReferenceDataLoader,
        @Di.inject("INDataAttributeService") private readonly nDataAttributeService: INDataAttributeService
    ) { }

    public convert(value: any, attributeType: string, attributeName: string) {

        if (isNullOrUndefined(value)) {
            return null;
        }

        const typedValue = this.nDataAttributeService.convertValue(attributeType, attributeName, value);

        let collector = this.collectors.get(attributeType);
        if (!collector) {
            collector = this.referenceDataLoader.tryCreateCollector(attributeType);

            if (!!collector) {
                this.collectors.set(attributeType, collector);
            }
        }

        collector?.add(typedValue);

        return typedValue;
    }

    public async loadReferenceDataAsync() {
        await Promise.all(Array.from(this.collectors.values()).map(collector => this.referenceDataLoader.loadCollectedAsync(collector)));
        this.collectors.clear();
    }
}
