import Prescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Prescription";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import Dosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Dosage";
import FrequencyBasedDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/FrequencyBasedDosage";
import ScheduledDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/ScheduledDosage";
import TextualDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/TextualDosage";
import SingleMedicationReference from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/SingleMedicationReference";
import MedicationReferenceBase from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationReferenceBase";
import MedicationEquipmentList from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationEquipmentList";
import TextualPrescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/TextualPrescription";
import MedicationFormulaList from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationFormulaList";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { EMedicationDosage, TextualEMedicationDosage, FrequencyBasedEMedicationDosage } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/EMedicationDosage";
import ScheduleTimeOfDay from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/ScheduleTimeOfDay.g";

function getBaseProps(prescription: Prescription) {
    return {
        careActivityId: prescription.careActivityId,
        conditionId: prescription.conditionId,
        instruction: prescription.instruction,
        insubstitutableReason: prescription.insubstitutableReason,
        isEmergency: prescription.isEmergency,
        isForcedPrescription: prescription.isForcedPrescription,
        isSubstitutable: prescription.isSubstitubable,
        notes: prescription.notes,
        suppliedMonths: prescription.suppliedMonths,
        usableFrom: prescription.usableFrom,
        prescribedOn: prescription.prescribedOn,
        temporaryPermission: new Proxy.TemporaryPrescriptionPermissionDto({
            permissionIdentifier: prescription.temporaryPermission.permissionIdentifier,
            validTo: prescription.temporaryPermission.validTo,
            validFrom: prescription.temporaryPermission.validFrom
        }),
        medicationRequestCoverage: new Proxy.MedicationRequestCoverageDto({
            medicationSubsidyClaimTypeId: prescription.medicationRequestCoverage?.claimTypeId,
            medicationSubsidyId: prescription.medicationRequestCoverage?.subsidyId,
            medicationSubsidyOptionId: prescription.medicationRequestCoverage?.optionId
        }),
        medicationReferenceBase: getMedicationReferenceBaseDto(prescription.medicationReferenceBase),
        registryNumber: prescription.registryNumber,
        prescriptionPractitionerRecommendation: new Proxy.PrescriptionPractitionerRecommendationDto({
            practitionerId: prescription.prescriptionPractitionerRecommendation?.practitionerId,
            careIdentifier: prescription.prescriptionPractitionerRecommendation?.careIdentifier,
            issuedOn: prescription.prescriptionPractitionerRecommendation?.issuedOn,
            ehiIdentifier: prescription.prescriptionPractitionerRecommendation?.ehiIdentifier
        }),
        prescriber: prescription.prescriber,
        approvedDesignation: prescription.approvedDesignation
    };
}

export function getMedicationReferenceBaseDto(medicationReferenceBase: MedicationReferenceBase) {
    if (!medicationReferenceBase) {
        return null;
    }

    if (medicationReferenceBase instanceof SingleMedicationReference) {
        return new Proxy.SingleMedicationReferenceDto({
            
            medicationVersionSelector: new Proxy.EntityVersionSelectorOfMedicationId({ entityId: medicationReferenceBase.medicationVersionSelector.id, validOn: medicationReferenceBase.medicationVersionSelector.validOn }),
            amount: medicationReferenceBase.amount,
            daysSupplied: medicationReferenceBase.daysSupplied,
            isDaysSuppliedFilledManually: medicationReferenceBase.isDaysSuppliedFilledManually,
            isOverdose: medicationReferenceBase.isOverdose,
            isUnpackaged: medicationReferenceBase.isUnpackaged,
            dosage: getDosageDto(medicationReferenceBase.dosage),
            packagingUnitId: medicationReferenceBase.packagingUnitId
        });
    } else if (medicationReferenceBase instanceof MedicationEquipmentList) {
        return new Proxy.MedicationEquipmentListDto({
            
            countersignMandatory: medicationReferenceBase.countersignMandatory,
            countersignApprovalNumber: medicationReferenceBase.countersignApprovalNumber,
            countersignValidFrom: medicationReferenceBase.countersignValidFrom,
            prescribeToIsoGroup: medicationReferenceBase.prescribeToIsoGroup,
            sizeChangeDescription: medicationReferenceBase.sizeChangeDescription,
            stateChangeDescription: medicationReferenceBase.stateChangeDescription,
            patientStatementAvailable: medicationReferenceBase.patientStatementAvailable,
            represcribeWithinSupportPeriod: medicationReferenceBase.represcribeWithinSupportPeriod,
            type: medicationReferenceBase.type,
            items: medicationReferenceBase.items.map(i => (new Proxy.MedicationEquipmentListItemDto({
                amount: i.amount?.toString(),
                equipmentPositionId: i.equipmentPositionId,
                medicationVersionSelector: new Proxy.EntityVersionSelectorOfMedicationId({ entityId: i.medicationVersionSelector.id, validOn: i.medicationVersionSelector.validOn }),
                size: i.size,
                amountReason: i.amountReason,
                bodyPart: i.bodyPart,
                instruction: i.instruction,
                instructionForDispenser: i.instructionForDispenser,
                isInsubstitutable: i.isInsubstitutable,
                insubstitutableReason: i.insubstitutableReason,
                dosage: getEMedicationDosageDto(i.dosage),
                faultDescription: i.faultDescription,
                serialNumber: i.serialNumber,
                supportPeriodMonths: i.supportPeriodMonths
            })))
        });
    } else if (medicationReferenceBase instanceof TextualPrescription) {
        return new Proxy.TextualPrescriptionDto({
            
            amount: medicationReferenceBase.amount,
            content: medicationReferenceBase.content,
            preparation: medicationReferenceBase.preparation,
            displayName: medicationReferenceBase.displayName,
            dosage: getDosageDto(medicationReferenceBase.dosage),
            name: medicationReferenceBase.name,
            textualPrescriptionTemplateId: medicationReferenceBase.textualPrescriptionTemplateId,
            type: medicationReferenceBase.type,
            packagingUnitId: medicationReferenceBase.packagingUnitId
        });
    } else if (medicationReferenceBase instanceof MedicationFormulaList) {
        return new Proxy.MedicationFormulaListDto({
            
            amount: medicationReferenceBase.amount,
            dosage: getDosageDto(medicationReferenceBase.dosage),
            name: medicationReferenceBase.name,
            instruction: medicationReferenceBase.instruction,
            packagingUnitId: medicationReferenceBase.packagingUnitId,
            items: medicationReferenceBase.medicationFormulaListItems.map(i => (new Proxy.MedicationFormulaListItemDto({
                amount: i.amount,
                unit: i.unit,
                medicationVersionSelector: i.medicationVersionSelector ?
                    new Proxy.EntityVersionSelectorOfMedicationId({ entityId: i.medicationVersionSelector?.id, validOn: i.medicationVersionSelector?.validOn }) :
                    new Proxy.EntityVersionSelectorOfMedicationId({ validOn: LocalDate.today() }),
            })))
        });
    } else {
        throw new Error("MedicationReferenceBase descendant not recognized.");
    }
}

export function getEMedicationDosageDto(emedicationDosage: EMedicationDosage) {
    if (!emedicationDosage) {
        return null;
    }

    if (emedicationDosage instanceof TextualEMedicationDosage) {
        return new Proxy.TextualEMedicationDosageDto({ instruction: emedicationDosage.instruction});
    } else if (emedicationDosage instanceof FrequencyBasedEMedicationDosage) {
        return new Proxy.FrequencyBasedEMedicationDosageDto({ amount: emedicationDosage.amount, frequency: emedicationDosage.frequency, unit: emedicationDosage.unit});
    } else {
        throw new Error("EMedicationDosage descendant not recognized.");
    }
}

export function getDosageDto(dosage: Dosage): Proxy.DosageDto {
    if (!dosage) {
        return null;
    }
    if (dosage instanceof FrequencyBasedDosage) {
        return new Proxy.FrequencyBasedDosageDto({
            
            amount: dosage.amount,
            dailyAmount: dosage.dailyAmount,
            frequency: dosage.frequency,
            dosageInterval: dosage.interval,
            reason: dosage.reason,
            dosageMode: dosage.dosageMode,
            isForPractitioner: dosage.isForPractitioner,
            intervalMultiplier: dosage.intervalMultiplier
        });
    } else if (dosage instanceof ScheduledDosage) {
        return new Proxy.ScheduledDosageDto({
            
            dailyAmount: dosage.dailyAmount,
            reason: dosage.reason,
            schedule: [
                new Proxy.DosageScheduleItemDto({ amount: dosage.morningAmount, scheduleTimeOfDay: ScheduleTimeOfDay.Morning }),
                new Proxy.DosageScheduleItemDto({ amount: dosage.noonAmount, scheduleTimeOfDay: ScheduleTimeOfDay.Noon }),
                new Proxy.DosageScheduleItemDto({ amount: dosage.eveningAmount, scheduleTimeOfDay: ScheduleTimeOfDay.Evening }),
                new Proxy.DosageScheduleItemDto({ amount: dosage.beforeSleepAmount, scheduleTimeOfDay: ScheduleTimeOfDay.BeforeSleep })
            ],
            dosageMode: dosage.dosageMode,
            isForPractitioner: dosage.isForPractitioner
        });
    } else if (dosage instanceof TextualDosage) {
        return new Proxy.TextualDosageDto({
            
            dailyAmount: dosage.dailyAmount,
            reason: dosage.reason,
            dosageInstructions: dosage.dosageInstructions,
            dosageMode: dosage.dosageMode,
            isForPractitioner: dosage.isForPractitioner
        });
    } else {
        throw new Error("Dosage descendant not recognized.");
    }
}

export function getPrescriptionDto(prescription: Prescription) {
    return new Proxy.PrescriptionDto({
        ...getBaseProps(prescription),
        id: prescription.id,
        rowVersion: prescription.rowVersion,
        status: prescription.status,
        extensionData: prescription.extensionData
    });
}

export function getCreatePrescriptionDto(prescription: Prescription, requestLock: boolean, isValidateOnly = false) {
    return new Proxy.CreatePrescriptionControllerDto({
        ...getBaseProps(prescription),
        requestLock: requestLock,
        isValidateOnly
    });
}

export function getUpdatePrescriptionDto(prescription: Prescription, releaseLockIfSuccessful: boolean, lockId: LockId, isValidateOnly = false) {
    return new Proxy.UpdatePrescriptionControllerDto({
        ...getBaseProps(prescription),
        releaseLockIfSuccessful,
        lockId,
        isValidateOnly,
        prescriptionId: prescription.id,
        rowVersion: prescription.rowVersion
    });
}