import PerformedServiceListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/PerformedServiceListStore";
import ShowPerformedServicesScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowPerformedServicesScreenAction.g";
import ShowReadOnlyPerformedServicesScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyPerformedServicesScreenAction.g";
import Di from "@Di";
import _ from "@HisPlatform/Common/Lodash";
import React from "react";
import IInsurerOrganization from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/IInsurerOrganization";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import ActionIdentifiers from "@Primitives/ActionIdentifiers";
import CareActivityId from "@Primitives/CareActivityId.g";
import InsuranceId from "@Primitives/InsuranceId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { IPerformedServicesScreenProps } from "./PerformedServicesScreen";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import MedicalServiceFinancingStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/MedicalServiceFinancingStore";
import PerformedServiceValidationProblemProcessorService from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PerformedServicesPanel/PerformedServiceValidationProblemProcessorService";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import PerformedServicesNewRow from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PerformedServicesPanel/PerformedServicesNewRow";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PerformedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/PerformedServiceStore";
import IPerformedServiceFirstRowExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPerformedServiceFirstRowExtensionPointProps";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StaticResources from "@StaticResources";
import PerformedServicesScreenApiAdapter from "./PerformedServicesScreenApiAdapter";
import LockAcquirerOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockAcquirerOperationInfo";
import IPerformedServiceExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPerformedServiceExtensionPointProps";
import SavePerformedServicesAction from "@HisPlatform/Packages/Care/FrontendActions/SavePerformedServicesAction.g";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import HisPermissionScopes from "@HisPlatform/Common/FrontendActions/HisPermissionScopes";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";

@Di.injectable()
export default class PerformedServicesScreenStore extends EditorScreenPanelStoreBase<IPerformedServicesScreenProps> implements ILoadablePanelStore {
    @State.observable public showFinanceData: boolean = false;
    @State.observable public isReadOnlyByEntityAction: boolean = false;
    @State.observable.ref public editedService: MedicalServiceFinancingStore = null;
    @State.observable.ref public highlightedValidationProblem: IClientValidationProblem = null;
    @State.observable.ref public performedServices: PerformedServiceListStore = new PerformedServiceListStore(false);
    @State.observable.ref public readonly newPerformedService: PerformedServiceStore = new PerformedServiceStore();

    @State.observable.ref private insuranceId: InsuranceId = null;
    @State.observable.ref private insurerOrganization: IInsurerOrganization = null;
    private isDirty: boolean;

    public addNewServicesAsync = this.async(async () => {
        if (!this.newPerformedService.selectedIds || this.newPerformedService.selectedIds.length === 0) {
            return;
        }

        for (const medicalServiceId of this.newPerformedService.selectedIds) {
            const performedService = new PerformedServiceStore();

            performedService.setLaterality(this.newPerformedService.lateralityId);
            performedService.setMedicalServiceQuantity(this.newPerformedService.medicalServiceQuantity || 1);
            performedService.setMedicalService(medicalServiceId);
            performedService.setPerformedAt(this.newPerformedService.performedAt || DateTimeService.now());
            performedService.setExtensionData(this.newPerformedService.extensionData);

            this.performedServices.addPerformedService(performedService);
        }

        await this.arrangePerformedServiceListAsync();
    });

    public deleteFinancingAsync = this.async(async (store: MedicalServiceFinancingStore) => {
        const medicalService = this.careReferenceDataStore.medicalService.get(store.performedServices[0].medicalServiceId);
        const message =
            formatString(
                StaticCareResources.OutpatientWorkflow.PerformedServicesStep.DeleteConfirmationQuestion,
                medicalService && medicalService.code && medicalService.code.value);

        const dialogResult =
            await this.dialogService.yesNo(StaticResources.Common.DialogTitle.ConfirmationTitle, message);
        if (dialogResult.resultCode === DialogResultCode.Yes) {
            this.performedServices.deleteFinancing(store);

            await this.arrangePerformedServiceListAsync();
        }
    });

    public readonly onCancelAsync = this.async(() => {
        this.props._screenState.cancelled();
        return Promise.resolve();
    });

    public readonly takeLockAsync = this.async(() => this.loadPerformedServicesAsync(true));

    public readonly updateServiceAsync = this.async(async (store: MedicalServiceFinancingStore) => {
        this.performedServices.updatePerformedService(store);
        await this.arrangePerformedServiceListAsync();
        this.setEditedService(null);
    });

    protected saveFunction: () => Promise<boolean> = this.async(() => this.saveAsync(false));

    private readonly validationProcessorService = new PerformedServiceValidationProblemProcessorService(
        this.financeReferenceDataStore,
        this.organizationReferenceDataStore,
        this.careReferenceDataStore,
        this.clientLocalizationService,
    );

    private readonly arrangePerformedServiceListAsync = this.async(async () => {
        const result = await this.apiAdapter.arrangePerformedServicesAsync(this.performedServices);
        await this.loadReferenceDataAsync(result.result);

        this.setPerformedServices(result.result);
        this.newPerformedService.reset();
        this.isDirty = true;
    });

    private readonly loadPerformedServicesAsync = this.async(async (forceAcquireLock: boolean) => {
        const response = await this.apiAdapter.getPerformedServicesAsync(this.careActivityId, !this.vIsReadOnly || forceAcquireLock, forceAcquireLock);
        if (response.operationInfo instanceof LockAcquirerOperationInfo) {
            State.runInAction(() => this.lockInfo = (response.operationInfo as LockAcquirerOperationInfo).lockInfo);
        }

        this.setIsReadOnlyByEntityAction(response.result.possibleActions[0].actions.every(i => i.value !== ActionIdentifiers.recordPerformedServices));
        this.setInsuranceId(response.result.insuranceId);

        State.runInAction(() => {
            this.insurerOrganization = this.getInsurerOrganization();
        });

        await this.loadReferenceDataAsync(response.result.performedServices);
        this.setPerformedServices(response.result.performedServices);
    });

    private readonly loadReferenceDataAsync = this.async(async (list: PerformedServiceListStore) => {
        const medicalServiceSelectors = list.getAllPerformedServices.map(item => item.medicalServiceId);
        await this.financeReferenceDataStore.financedServiceMap.ensureLoadedAsync(
            _.flatten(list.financings.map(i => i.financedServicesWithQuantities.map(j => j.financedServiceId)))
        );
        await this.careReferenceDataStore.medicalService.ensureLoadedAsync(medicalServiceSelectors);
        await this.careReferenceDataStore.laterality.ensureLoadedAsync();
    });

    private readonly saveAsync: (releaseLock: boolean) => Promise<boolean> = this.async(async (releaseLock) => {
        if (!this.performedServices) {
            return true;
        }

        const result = await this.handleSaveResultAsync<PerformedServiceListStore>(() => this.apiAdapter.updatePerformedServicesAsync(this.performedServices, releaseLock));
        if (result.isPersisted) {
            this.setPerformedServices(result.result);
            this.isDirty = false;
        }

        return result.isPersisted;
    });

    @State.computed
    public get extraPerformedServiceColumnExtensionPointProps(): IPerformedServiceExtensionPointProps {
        return {
            insurerOrganizationCode: this.insurerOrganization?.code,
            editedService: this.editedService,
            careActivityId: this.careActivityId,
            showFinanceData: this.showFinanceData
        };
    }

    @State.computed
    public get hasPermissionForEditingServices() {
        return this.authorizationService.hasPermissionForDescriptor(this.savePerformedServicesAction);
    }

    @State.computed
    public get highlightableRuleIds(): string[] {
        return this.validationProcessorService.highlightableRuleIds;
    }

    @State.computed
    public get insurerName(): string {
        return StaticCareResources.OutpatientWorkflow.PerformedServicesStep.Financer + ": " + (this.insurerOrganization === null
            ? StaticCareResources.OutpatientWorkflow.PerformedServicesStep.SelfPayment
            : this.insurerOrganization?.name);
    }

    @State.computed
    public get isAllowedToRequestLock(): boolean {
        return !this.isReadOnlyByEntityAction
               && !(this.showScreenAction instanceof ShowReadOnlyPerformedServicesScreenAction);
    }

    @State.computed
    public get savePerformedServicesAction() {
        return ActionDescriptor.fromAction(
            new SavePerformedServicesAction(),
            HisPermissionScopes.pointOfCare(this.pointOfCareId?.value)
        );
    }

    @State.computed
    public get vIsReadOnly(): boolean {
        return this.canAcquireLock
               || this.isReadOnlyByEntityAction
               || this.showScreenAction instanceof ShowReadOnlyPerformedServicesScreenAction
               || !this.hasPermissionForEditingServices;
    }

    protected get contentToDirtyCheck(): any[] {
        return [this.isDirty];
    }

    protected get showScreenAction(): ShowPerformedServicesScreenAction | ShowReadOnlyPerformedServicesScreenAction {
        return this.props.action;
    }

    private get careActivityId(): CareActivityId {
        return this.showScreenAction.careActivityId;
    }

    private get patient(): PatientAdministrativeData {
        return this.props._patient;
    }

    @State.computed
    private get performedServiceFirstRowExtensionPointProps(): IPerformedServiceFirstRowExtensionPointProps {
        return {
            careActivityId: this.careActivityId,
            insurerOrganizationCode: this.insurerOrganization?.code,
            newPerformedService: this.newPerformedService,
            readonly: this.vIsReadOnly
        };
    }

    private get pointOfCareId(): PointOfCareId {
        return this.props._pointOfCareId;
    }

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("PerformedServicesScreenApiAdapter") public readonly apiAdapter: PerformedServicesScreenApiAdapter,
        @Di.inject("AuthorizationService") public readonly authorizationService: AuthorizationService,
        @Di.inject("CareReferenceDataStore") public readonly careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("FinanceReferenceDataStore") public readonly financeReferenceDataStore: FinanceReferenceDataStore,
        @Di.inject("ILocalizationService") public readonly clientLocalizationService: ILocalizationService,
        @Di.inject("OrganizationReferenceDataStore") public readonly organizationReferenceDataStore: OrganizationReferenceDataStore) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    @State.bound
    public filterValidationProblems(problem: IClientValidationProblem) {
        return problem === this.highlightedValidationProblem;
    }

    public loadCoreAsync(): Promise<any> {
        return this.loadPerformedServicesAsync(false);
    }

    @State.bound
    public mapValidationProblems(validationProblem: IClientValidationProblem): any[] {
        return this.validationProcessorService.mapValidationProblems(validationProblem, this.performedServices.financings);
    }

    @State.bound
    public onSaveAsync(action: SavePerformedServicesAction): Promise<any> {
        return this.saveAsync(false);
    }

    @State.bound
    public renderFinancedServices(value: any, store: MedicalServiceFinancingStore) {
        const services = this.financeReferenceDataStore.financedServiceMap.getAll(
            store.financedServicesWithQuantities.map(i => i.financedServiceId));

        return (
            <>
                {services.map((item) => item.name).join(", ")}
            </>
        );
    }

    @State.bound
    public renderNewRow() {
        return (
            <>
                {!this.vIsReadOnly && <PerformedServicesNewRow
                    onAddAsync={this.addNewServicesAsync}
                    performedServiceFirstRowExtensionPointProps={this.performedServiceFirstRowExtensionPointProps}
                    showFinanceData={this.showFinanceData}
                    store={this.newPerformedService}
                    pointOfCareId={this.pointOfCareId}
                    readonly={this.vIsReadOnly}
                    hasInsurerOrganization={this.insurerOrganization !== null}
                />}
            </>
        );
    }

    @State.bound
    public renderValidationInfo(problem: IClientValidationProblem): React.ReactNode {
        return this.validationProcessorService.renderInfo(problem);
    }

    @State.action.bound
    public setEditedService(newValue: MedicalServiceFinancingStore) {
        this.editedService = newValue;
    }

    @State.action.bound
    public setHighlightedValidationProblem(highlightedValidationProblem: IClientValidationProblem): void {
        this.highlightedValidationProblem = highlightedValidationProblem;
    }

    @State.action.bound
    public setShowFinanceData(value: boolean): void {
        this.showFinanceData = value;
    }

    @State.bound
    private getInsurerOrganization(): IInsurerOrganization {
        if (isNullOrUndefined(this.insuranceId)) {
            return null;
        }

        const insurance = this.insuranceId
            && this.patient?.insurances?.find(i => ValueWrapper.equals(i.id, this.insuranceId));

        const organization = insurance && this.financeReferenceDataStore.insurerOrganizationMap.get(insurance.insurerOrganizationId);
        return organization;
    }

    @State.action.bound
    private setInsuranceId(newValue: InsuranceId) {
        this.insuranceId = newValue;
    }

    @State.action.bound
    private setIsReadOnlyByEntityAction(newValue: boolean) {
        this.isReadOnlyByEntityAction = newValue;
    }

    @State.action.bound
    private setPerformedServices(newValue: PerformedServiceListStore) {
        this.performedServices = newValue;
    }
}