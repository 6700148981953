import PermissionId from "@Primitives/PermissionId.g";
import PermissionScope from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/PermissionScope";
import IPermissionDefinition from "@PluginInterface/OperationAccessControl/IPermissionDefinition";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";

export default class Permission implements IPermissionDefinition {

    @State.observable public ids: PermissionId[];
    @State.observable public scopes: PermissionScope[];
    @State.observable public alias?: string;
    public scopeTypesForIdsMap: Map<string, string[]> = new Map<string, string[]>();
    public aliasedScopeTypes: string[] = [];

    @State.computed public get aliasedId() {
        if (this.alias) {
            return new PermissionId(`${this.ids[0].value}.${this.alias}`);
        }

        return this.ids?.[0];
    }

    constructor(ids: PermissionId[], scopes: PermissionScope[], alias?: string) {
        this.ids = ids;
        this.setPermissionScopesForAllIds(...scopes);
        this.alias = alias;
    }

    public clone() {
        const permission = new Permission(this.ids, this.scopes, this.alias);
        permission.scopeTypesForIdsMap = new Map(this.scopeTypesForIdsMap);
        permission.aliasedScopeTypes = this.aliasedScopeTypes;
        return permission;
    }
    
    public allowsScope(scope: PermissionScope): boolean {
        const scopesWithSameType = this.scopes.find(s => s.type === scope.type);
        return scopesWithSameType?.containsScope(scope) ?? false;
    }

    public includesPermission(permission: Permission): boolean {
        return permission.scopes.every(s => this.allowsScope(s));
    }

    public union(permission: Permission): void {
        return this.scopes.forEach(s => s.union(permission.scopes.find(ps => ps.type === s.type)));
    }
    
    @State.action.bound
    public setAlias(alias: string, ...aliasedScopeTypes: string[]) {
        this.alias = alias;
        this.aliasedScopeTypes.push(...aliasedScopeTypes);
    }

    @State.action.bound
    public setPermissionScopesForId(permissionId: PermissionId, ...permissionScopes: PermissionScope[]) {
        if (this.scopeTypesForIdsMap.has(permissionId.value)) {
            const currentValues = this.scopeTypesForIdsMap.get(permissionId.value);
            const newValues = permissionScopes.map(s => s.type);
            this.scopeTypesForIdsMap.set(permissionId.value, _.union(currentValues, newValues));
        } else {
            this.scopeTypesForIdsMap.set(permissionId.value, permissionScopes.map(s => s.type));
        }

        this.scopes.push(...permissionScopes);
    }

    @State.action.bound
    public setPermissionScopesForAllIds(...permissionScopes: PermissionScope[]) {
        this.setScopes(permissionScopes);
        this.ids?.forEach(id => {
            if (this.scopeTypesForIdsMap.has(id.value)) {
                const currentValues = this.scopeTypesForIdsMap.get(id.value);
                const newValues = permissionScopes.map(s => s.type);
                this.scopeTypesForIdsMap.set(id.value, _.union(currentValues, newValues));
            } else {
                this.scopeTypesForIdsMap.set(id.value, permissionScopes.map(s => s.type));
            }
        });
    }

    @State.action.bound
    public setScopes(permissionScopes: PermissionScope[]) {
        this.scopes = permissionScopes;
    }
 }
