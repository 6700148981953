import MedicalServiceFinancingStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/MedicalServiceFinancingStore";
import Di from "@Di";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { HealthCareProfessionRuleValidationProcessor } from "./ValidationProblemProcessors/HealthCareProfessionRuleValidationProblemProcessor";
import ValidationProblemProcessorService from "@Toolkit/CommonWeb/Validation/ValidationProblemProcessorService";
import { ExcludeRuleValidationProblemProcessor } from "./ValidationProblemProcessors/ExcludeRuleValidationProblemProcessor";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { ConditionalLimitRuleValidationProblemProcessor } from "./ValidationProblemProcessors/ConditionalLimitRuleValidationProblemProcessor";
import { RepeatRuleValidationProblemProcessor } from "./ValidationProblemProcessors/RepeatRuleValidationProblemProcessor";
import QuantityRuleValidationProblemProcessor from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PerformedServicesPanel/ValidationProblemProcessors/QuantityRuleValidationProblemProcessor";
import { ThreeRuleValidationProblemProcessor } from "./ValidationProblemProcessors/ThreeRuleValidationProblemProcessor";
import { TogetherRuleValidationProblemProcessor } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PerformedServicesPanel/ValidationProblemProcessors/TogetherRuleValidationProblemProcessor";
import { DiagnosisRuleValidationProblemProcessor } from "./ValidationProblemProcessors/DiagnosisRuleValidationProblemProcessor";
import { AgeRuleValidationProblemProcessor } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PerformedServicesPanel/ValidationProblemProcessors/AgeRuleValidationProblemProcessor";
import { GeneralPractitionerRuleValidationProblemProcessor } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PerformedServicesPanel/ValidationProblemProcessors/GeneralPractitionerValidationProblemProcessor";
import { PerformedAtRuleValidationProblemProcessor } from "./ValidationProblemProcessors/PerformedAtRuleValidationProblemProcessor";
import { UnprocessedRuleValidationProblemProcessor } from "./ValidationProblemProcessors/UnprocessedRuleValidationProblemProcessor";
import { IndexRuleValidationProblemProcessor } from "./ValidationProblemProcessors/IndexRuleValidationProblemProcessor";

@Di.injectable()
export default class PerformedServiceValidationProblemProcessorService extends ValidationProblemProcessorService<MedicalServiceFinancingStore> {
    constructor(
        @Di.inject("FinanceReferenceDataStore") private financeReferenceDataStore: FinanceReferenceDataStore,
        @Di.inject("OrganizationReferenceDataStore") private organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("CareReferenceDataStore") private careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("ILocalizationService") private localizationService: ILocalizationService,
    ) {
        super(
            [
                new HealthCareProfessionRuleValidationProcessor(),
                new ExcludeRuleValidationProblemProcessor(financeReferenceDataStore, organizationReferenceDataStore, localizationService),
                new ConditionalLimitRuleValidationProblemProcessor(financeReferenceDataStore, localizationService, organizationReferenceDataStore),
                new RepeatRuleValidationProblemProcessor(financeReferenceDataStore, localizationService, organizationReferenceDataStore),
                new QuantityRuleValidationProblemProcessor(),
                new ThreeRuleValidationProblemProcessor(financeReferenceDataStore, localizationService, organizationReferenceDataStore),
                new TogetherRuleValidationProblemProcessor(financeReferenceDataStore),
                new DiagnosisRuleValidationProblemProcessor(financeReferenceDataStore, localizationService, organizationReferenceDataStore, careReferenceDataStore),
                new AgeRuleValidationProblemProcessor(),
                new GeneralPractitionerRuleValidationProblemProcessor(),
                new UnprocessedRuleValidationProblemProcessor(),
                new PerformedAtRuleValidationProblemProcessor("ShouldBeNotLaterThanOneDay"),
                new PerformedAtRuleValidationProblemProcessor("ShouldBeBetweenLimits"),
                new IndexRuleValidationProblemProcessor()
            ]
        );
    }
}
