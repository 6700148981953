import * as DiContainer from "@DiContainer";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ILogger, { defaultLoggingConfiguration, ILoggingConfiguration } from "./ILogger";

export default class Log {

    @DiContainer.lazyResolve("ILogger") private static readonly logger: ILogger;

    public static get logEvent() {
        return Log.logger.logEvent;
    }

    public static get isTraceLogEnabled(): boolean {
        return Log.logger.configuration.isTraceLogEnabled ?? defaultLoggingConfiguration.isTraceLogEnabled;
    }

    public static get isDebugLogEnabled(): boolean {
        return Log.logger.configuration.isDebugLogEnabled ?? defaultLoggingConfiguration.isDebugLogEnabled;
    }

    public static get isInfoLogEnabled(): boolean {
        return Log.logger.configuration.isInfoLogEnabled ?? defaultLoggingConfiguration.isInfoLogEnabled;
    }

    public static get isWarnLogEnabled(): boolean {
        return Log.logger.configuration.isWarnLogEnabled ?? defaultLoggingConfiguration.isWarnLogEnabled;
    }

    public static get isErrorLogEnabled(): boolean {
        return Log.logger.configuration.isErrorLogEnabled ?? defaultLoggingConfiguration.isErrorLogEnabled;
    }

    public static get isFatalLogEnabled(): boolean {
        return Log.logger.configuration.isFatalLogEnabled ?? defaultLoggingConfiguration.isFatalLogEnabled;
    }

    public static trace(value: any, additionalMessage?: string): void {
        if (Log.isTraceLogEnabled) {
            Log.logger.log(value, "trace", additionalMessage);
        }
    }

    public static debug(value: any, additionalMessage?: string): void {
        if (Log.isDebugLogEnabled) {
            Log.logger.log(value, "debug", additionalMessage);
        }
    }

    public static info(value: any, additionalMessage?: string): void {
        if (Log.isInfoLogEnabled) {
            Log.logger.log(value, "info", additionalMessage);
        }
    }

    public static warn(value: any, additionalMessage?: string): void {
        if (Log.isWarnLogEnabled) {
            Log.logger.log(value, "warn", additionalMessage);
        }
    }

    public static error(value: any, additionalMessage?: string): void {
        if (Log.isErrorLogEnabled) {
            Log.logger.log(value, "error", additionalMessage);
        }
    }

    public static fatal(value: any, additionalMessage?: string): void {
        if (Log.isFatalLogEnabled) {
            Log.logger.log(value, "fatal", additionalMessage);
        }
    }

    public static configure(logConfig: ILoggingConfiguration) {
        this.logger.configure(logConfig);
    }
}