import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import IContainerService, { ServiceIdentifier } from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import IFormExtensionRegistry from "@PluginInterface/FormExtension/IFormExtensionRegistry";
import Di from "@Di";

@Di.injectable()
export default class FormExtensionRegistry implements IFormExtensionRegistry {
    private readonly map = new Map<string, ServiceIdentifier[]>();

    public register(formName: string, formExtensionDiIdentifier: ServiceIdentifier) {
        if (this.map.has(formName)) {
            this.map.get(formName).push(formExtensionDiIdentifier);
        } else {
            this.map.set(formName, [formExtensionDiIdentifier]);
        }
    }

    public resolveAll<TFormData>(container: IContainerService, formName: string): Array<IFormExtension<TFormData>> {
        const extensionIdentifiers = this.map.get(formName);
        return extensionIdentifiers?.map(e => container.resolve<IFormExtension<TFormData>>(e)) ?? [];
    }
}