import WorkflowStepsIndicator from "@CommonControls/WorkflowStepsIndicator";
import { provideCareActivityGuidanceHeaderStore, useCareActivityGuidanceHeaderStore } from "./CareActivityGuidanceHeaderStoreProvider";
import { IToolbarPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";

export interface ICareActivityGuidanceHeaderProps extends IToolbarPropsBase {
    _careActivityId?: CareActivityId;
}

function CareActivityGuidanceHeader(_props: ICareActivityGuidanceHeaderProps) {

    const store = useCareActivityGuidanceHeaderStore();

    return arrayIsNullOrEmpty(store.steps) ? null : (
        <WorkflowStepsIndicator steps={store.steps} selectedStep={store.currentStep} onStepSelected={store.selectStepAsync.fireAndForget} />
    );
}

export default connect(
    provideCareActivityGuidanceHeaderStore(CareActivityGuidanceHeader),
    new CareActivityContextAdapter<ICareActivityGuidanceHeaderProps>((c) => ({
        _careActivityId: c.careActivityId
    }))
);