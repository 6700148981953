import PatientId from "@Primitives/PatientId.g";
import * as CareProxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import PatientAllergyIntoleranceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceStore";
import PatientAllergyIntoleranceHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceHistoryItem";
import { getHistoryItemDetailDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalConditionDtoMapper";
import AllergyIntoleranceAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/AllergyIntoleranceAssignmentBase";
import ReferencedAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/ReferencedAllergyIntoleranceAssignment";
import CustomAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/CustomAllergyIntoleranceAssignment";
import MedicationAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/MedicationAllergyIntoleranceAssignment";
import SubstanceAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/SubstanceAllergyIntoleranceAssignment";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import InternationalNonproprietaryNameAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/InternationalNonproprietaryNameAllergyIntoleranceAssignment";
import AllergyIntoleranceType from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/AllergiesAndIntolerances/Enum/AllergyIntoleranceType.g";

export function getAddPatientAllergyIntoleranceControllerDto(source: PatientAllergyIntoleranceStore, patientId: PatientId, isValidateOnly: boolean) {
    return new CareProxy.AddPatientAllergyIntoleranceControllerDto({
        patientId: patientId,
        historyItemDtos: [getPatientAllergyIntoleranceHistoryItemDto(source.latestHistoryItem as PatientAllergyIntoleranceHistoryItem)],
        allergyIntoleranceAssignmentBaseDto: getAssignmentDto(source.assignment),
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
    });
}

export function getUpdatePatientAllergyIntoleranceControllerDto(source: PatientAllergyIntoleranceStore, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.UpdatePatientAllergyIntoleranceControllerDto({
        patientAllergyIntoleranceId: source.id,
        historyItemDtos: [getPatientAllergyIntoleranceHistoryItemDto(source.latestHistoryItem as PatientAllergyIntoleranceHistoryItem)],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeletePatientAllergyIntoleranceHistoryItemControllerDto(source: PatientAllergyIntoleranceStore, versionNumber: number, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.DeletePatientAllergyIntoleranceHistoryItemControllerDto({
        patientAllergyIntoleranceId: source.id,
        versionNumbers: [versionNumber],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeletePatientAllergyIntoleranceControllerDto(store: PatientAllergyIntoleranceStore) {
    return new CareProxy.DeletePatientAllergyIntoleranceControllerDto({
        patientAllergyIntoleranceId: store.id,
        lockId: store.lockInfo?.lockId,
        extensionData: store.extensionData,
        isValidateOnly: false,
        releaseLockIfSuccessful: true
    });
}

function getAssignmentDto(source: AllergyIntoleranceAssignmentBase) {
    let dto;

    if (source instanceof ReferencedAllergyIntoleranceAssignment) {
        dto = new CareProxy.ReferencedAllergyIntoleranceAssignmentDto({
            allergyIntoleranceVersionSelector: new CareProxy.EntityVersionSelectorOfAllergyIntoleranceId({
                entityId: source.allergyIntoleranceVersionSelector.id,
                validOn: source.allergyIntoleranceVersionSelector.validOn
            } as CareProxy.IEntityVersionSelectorOfAllergyIntoleranceId)
        });
    } else if (source instanceof MedicationAllergyIntoleranceAssignment) {
        dto = new CareProxy.MedicationReferenceAllergyIntoleranceAssignmentDto({
            medicationVersionSelector: new CareProxy.EntityVersionSelectorOfMedicationId({
                entityId: source.medicationVersionSelector.id,
                validOn: source.medicationVersionSelector.validOn
            } as CareProxy.IEntityVersionSelectorOfMedicationId)
        });
    } else if (source instanceof SubstanceAllergyIntoleranceAssignment) {
        dto = new CareProxy.SubstanceReferenceAllergyIntoleranceAssignmentDto({
            substanceVersionSelector: new CareProxy.EntityVersionSelectorOfSubstanceId({
                entityId: source.substanceVersionSelector.id,
                validOn: source.substanceVersionSelector.validOn
            } as CareProxy.IEntityVersionSelectorOfSubstanceId)
        });
    } else if (source instanceof InternationalNonproprietaryNameAllergyIntoleranceAssignment) {
        dto = new CareProxy.InternationalNonproprietaryNameReferencedAllergyIntoleranceAssignmentDto({
            internationalNonproprietaryNameId: source.id
        } as CareProxy.IInternationalNonproprietaryNameReferencedAllergyIntoleranceAssignmentDto);
    } else if (source instanceof CustomAllergyIntoleranceAssignment) {
        dto = new CareProxy.CustomAllergyIntoleranceAssignmentDto({ name: source.name, type: AllergyIntoleranceType.Allergy, categoryId: source.categoryId, description: source.description } as CareProxy.ICustomAllergyIntoleranceAssignmentDto);
    }

    return dto;
}

function getPatientAllergyIntoleranceHistoryItemDto(source: PatientAllergyIntoleranceHistoryItem) {
    const allergyIntoleranceReactionAssignments = source.reactions.map(x => new CareProxy.AllergyIntoleranceReactionAssignmentDto({
        allergyIntoleranceReactionId: new CareProxy.EntityVersionSelectorOfAllergyIntoleranceReactionId({
            entityId: x.allergyIntoleranceReactionId.id,
            validOn: x.allergyIntoleranceReactionId.validOn
        } as CareProxy.IEntityVersionSelectorOfAllergyIntoleranceReactionId),
        description: x.description
    } as CareProxy.IAllergyIntoleranceReactionAssignmentDto));

    return new CareProxy.PatientAllergyIntoleranceHistoryItemDto({
        versionNumber: source.versionNumber,
        index: source.index,
        createdAt: source.createdAt,
        historyItemDetail: getHistoryItemDetailDto(source.historyItemDetail),
        extensionData: source.extensionData,
        lastOccurrence: source.lastOccurrence,
        clinicalStatus: source.clinicalStatus,
        criticality: source.criticality,
        allergyIntoleranceReactionAssignments: allergyIntoleranceReactionAssignments
    } as CareProxy.IPatientAllergyIntoleranceHistoryItemDto);
}
