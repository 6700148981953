import Log from "@Log";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import IMapperService from "@HisPlatform/Services/Definition/MapperService/IMapperService";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import UnknownFrontendAction from "@Toolkit/ReactClient/ActionProcessing/UnknownFrontendAction";

export default class FrontendActionApiResult {

    public static mapperServiceLocator: () => IMapperService;

    public static setMapperServiceLocator(mapperServiceLocator: () => IMapperService & IMapperConfigurator) {
        FrontendActionApiResult.mapperServiceLocator = mapperServiceLocator;
    }

    public static fromJS(data: any): FrontendActionApiResult {
        const mapperService = FrontendActionApiResult.mapperServiceLocator();
        const nameMappingResult = mapperService.tryMapByName<any, FrontendActionBase>(data._discriminator, data);

        if (!nameMappingResult.mapperFound) {
            Log.warn(`Cannot find FrontendAction mapper for '${data._discriminator}'. Fallback to UnknownFrontendAction.`);
            nameMappingResult.result = new UnknownFrontendAction(data._discriminator);
        }

        const result = new FrontendActionApiResult();
        result.frontendAction = nameMappingResult.result;
        return result;
    }

    public frontendAction: FrontendActionBase | null = null;

    constructor(_?: any) {
        return;
    }

    public toJSON(data?: any): object {
        return data;
    }

    public init(_?: any) {
        return;
    }
}