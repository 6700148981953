import Flex from "@CommonControls/Flex";
import Icon, { iconNameType } from "@CommonControls/Icon";
import { ITabPaneProps } from "@CommonControls/TabPane";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import React, { useCallback, useMemo } from "react";
import Styles from "./Tab.less";

interface ITabbarProps {
    automationid?: string;
    headerTextAlign?: "left" | "center" | "right";
    tabsGrow?: boolean;
    style?: React.CSSProperties;
    className?: string;
    visualStyle?: "normal" | "pageBox" | "menu";
    tabs: ITab[];

    selectedTab?: ITab | null;
    onSelect?: (tab: ITab, index: number) => void;
}

export interface ITab {
    title: string;
    iconName?: iconNameType;
    isDisabled?: boolean;
    automationId?: string;
}

export default function Tabbar(props: React.PropsWithChildren<ITabbarProps>) {

    const tabContainerClassName = new CompositeClassName(Styles.tabContainer, props.className);
    tabContainerClassName.addIf(props.visualStyle && props.visualStyle === "pageBox", Styles.tabContainerAlternate);
    tabContainerClassName.addIf(props.visualStyle && props.visualStyle === "menu", Styles.tabContainerMenu);

    return (
        <div
            className={tabContainerClassName.classNames}
            data-automation-id={props.automationid || undefined}
            style={props.style}
        >
            <Flex innerSpacing="none" outerSpacing="none" verticalSpacing="none" noWrap style={{
                width: "100%",
                textAlign: props.headerTextAlign
            }}>
                {props.tabs.filter(Boolean).map((tab: ITab, index: number) => (
                    <TabView
                        key={`${index}_${tab.title}`}
                        tab={tab}
                        isSelected={tab === props.selectedTab}
                        visualStyle={props.visualStyle}
                        tabsGrow={props.tabsGrow}
                        onSelect={props.onSelect}
                        index={index}
                    />
                ))}
            </Flex>
        </div>
    );
}

function TabView(props: {
    tab: ITab,
    isSelected: boolean,
    visualStyle?: "normal" | "pageBox" | "menu",
    tabsGrow?: boolean,
    onSelect?: (tab: ITab, index: number) => void;
    index: number;
}) {

    const { isSelected, onSelect, tab, visualStyle, index } = props;
    const className = useMemo(() => {

        const builder = new CompositeClassName();

        if (isSelected) {
            builder.add(Styles.tabSelected);
            builder.addIf(visualStyle && visualStyle === "pageBox", Styles.tabSelectedAlternate);
            builder.addIf(visualStyle && visualStyle === "menu", Styles.tabSelectedMenu);
            // builder.addIf(visualStyle && visualStyle === "pageBox", Styles.tabAlternateLast);
        } else {
            builder.add(Styles.tab);
            builder.addIf(visualStyle && visualStyle === "pageBox", Styles.tabAlternate);
            builder.addIf(visualStyle && visualStyle === "menu", Styles.tabMenu);
            // builder.addIf(visualStyle && visualStyle === "pageBox", Styles.tabAlternateLast);
        }

        builder.addIf(tab.isDisabled, Styles.tabDisabled);

        return builder.classNames;


    }, [isSelected, tab, visualStyle]);

    const click = useCallback(() => {
        onSelect?.(tab, index);
    }, [onSelect, tab]);

    return (
        <Flex.Item grow={props.tabsGrow} onClick={click}>
            <div
                className={className}
                data-automation-id={props.tab.automationId || undefined}>
                {props.tab.iconName && (
                    <Icon iconName={props.tab.iconName} className={Styles.icon} />
                )}
                {props.tab.title}
            </div>
        </Flex.Item>
    );
}