import State from "@Toolkit/ReactClient/Common/StateManaging";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import AppointmentScheduleSlotSeriesId from "@Primitives/AppointmentScheduleSlotSeriesId.g";
import MomentRange from "@Toolkit/CommonWeb/Model/MomentRange";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import OrganizationUnitResource from "./OrganizationUnitResource";
import PractitionerResource from "./PractitionerResource";
import TimePhaseId from "@Primitives/TimePhaseId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import TimePhaseInterval from "./TimePhaseInterval";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import TimePhaseRecurrenceElement from "./TimePhaseRecurrenceElement";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import TimePhaseType from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseType";
import RecurrenceType from "./RecurrenceType";
import DayOfWeek from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/DayOfWeek.g";
import WeekOfMonth from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/WeekOfMonth.g";

export default class TimePhaseStore extends EntityStoreBase<TimePhaseId> implements IDirtyChecked {

    private _dirtyChecker = new StoreDirtyChecker();
    public createdAppointmentScheduleSlotSeries: AppointmentScheduleSlotSeriesId[] = [];
    @State.observable public allowedSchedulingServices: SchedulingServiceId[] = [];
    @State.observable public allowedServiceRequestDefinitions: Array<IEntityVersionSelector<ServiceRequestDefinitionId>> = [];
    @State.observable public interval: TimePhaseInterval = null;
    @State.observable public type: TimePhaseType = null;
    @State.observable public singleOccurrenceDate: LocalDate = null;
    @State.observable public recurrenceElements: TimePhaseRecurrenceElement[] = [];
    @State.observable public parallelCapacity: number = null;
    @State.observable public slotSizeInMinutes: number = null;
    @State.observable public organizationUnitResources: OrganizationUnitResource[] = [];
    @State.observable public practitionerResources: PractitionerResource[] = [];
    @State.observable public isDeleted: boolean = false;

    public isUnderEdit = false;

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker"
    ];

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }

    @State.action.bound
    public delete() {
        this.isDeleted = true;
    }
    @State.action.bound public setAllowedSchedulingServices(newValues: SchedulingServiceId[]) {
        this.allowedSchedulingServices = newValues;
    }
    @State.action.bound public setAllowedServiceRequestDefinitions(newValues: Array<IEntityVersionSelector<ServiceRequestDefinitionId>>) {
        this.allowedServiceRequestDefinitions = newValues;
    }

    @State.action.bound public setInterval(from: TimeOfDay, to: TimeOfDay) {
        const newInterval = new TimePhaseInterval();
        newInterval.setStart(from);
        newInterval.setEnd(to);
        this.interval = newInterval;
    }

    @State.action.bound public setType(newValue: TimePhaseType) {
        this.type = newValue;
    }

    @State.action.bound public setSingleOccurrenceDate(newValue: LocalDate) {
        this.singleOccurrenceDate = newValue;
    }

    @State.action.bound public setRecurrenceElements(newValue: TimePhaseRecurrenceElement[]) {
        this.recurrenceElements = newValue;
    }

    @State.action.bound public setParallelCapacity(newValue: number) {
        this.parallelCapacity = newValue;
    }

    @State.action.bound public setSlotSizeInMinutes(newValue: number) {
        this.slotSizeInMinutes = newValue;
    }

    @State.action.bound public setOrganizationUnitResources(newValue: OrganizationUnitResource[]) {
        this.organizationUnitResources = newValue;
    }

    @State.action.bound public setPractitionerResources(newValue: PractitionerResource[]) {
        this.practitionerResources = newValue;
    }

    @State.action.bound
    public copyValuesFrom(store: TimePhaseStore) {
        this.setAllowedSchedulingServices(store.allowedSchedulingServices);
        this.setAllowedServiceRequestDefinitions(store.allowedServiceRequestDefinitions);
        this.setInterval(store.interval.start, store.interval.end);
        this.setType(store.type);
        this.setSingleOccurrenceDate(store.singleOccurrenceDate);
        this.setRecurrenceElements(store.recurrenceElements);
        this.setParallelCapacity(store.parallelCapacity);
        this.setSlotSizeInMinutes(store.slotSizeInMinutes);
        this.setOrganizationUnitResources(store.organizationUnitResources);
        this.setPractitionerResources(store.practitionerResources);
        if (store.isDeleted) {
            this.delete();
        }
    }

    @State.computed public get slotCount() {
        if (this.interval) {
            return Math.ceil(this.interval.lengthInMinutes / this.slotSizeInMinutes);
        }
        return null;
    }

    @State.computed public get singleOccurrenceInterval() {
        if (!this.singleOccurrenceDate) {
            return null;
        }

        const from = this.interval?.start
            ? this.singleOccurrenceDate.toUtcDayStartMoment().hour(this.interval.start.hours).minute(this.interval.start.minutes)
            : null;

        const to = this.interval?.end
            ? this.singleOccurrenceDate.toUtcDayStartMoment().hour(this.interval.end.hours).minute(this.interval.end.minutes)
            : null;

        return new MomentRange(from, to);
    }

    public static createNew() {
        const store = new TimePhaseStore(true);

        const element = new TimePhaseRecurrenceElement();
        element.setRecurrenceType(RecurrenceType.DayOfWeek);
        element.setDayOfWeek(DayOfWeek.Monday);
        element.setEvenWeek(true);
        element.setWeekOfMonth(WeekOfMonth.First);

        store.setRecurrenceElements([element]);
        store.setType(TimePhaseType.Recurring);
        store.setInterval(null, null);

        return store;
    }

    @State.action.bound public resetRecurrenceElements() {
        const element = new TimePhaseRecurrenceElement();
        element.setRecurrenceType(RecurrenceType.DayOfWeek);
        element.setDayOfWeek(DayOfWeek.Monday);
        element.setEvenWeek(true);
        element.setWeekOfMonth(WeekOfMonth.First);

        this.setRecurrenceElements([element]);
    }

    public static createNewWithId(id: TimePhaseId) {
        const store = TimePhaseStore.createNew();
        store.id = id;
        return store;
    }
}