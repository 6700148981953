import React from "react";
import IWidgetComponentProps from "@PluginInterface/Dashboard/IWidgetComponentProps";
import ComorbidityWidgetApiAdapter from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/ComorbidityWidget/ComorbidityWidgetApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { IGetComorbidityRequestData } from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/ComorbidityWidget/IGetComorbidityRequestData";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ConditionsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ConditionsApiAdapter";
import ConditionId from "@Primitives/ConditionId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IConditionDisplayData from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/ComorbidityWidget/IConditionDisplayData";
import * as Ui from "@CommonControls";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PatientId from "@Primitives/PatientId.g";

import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import { VoidSyncEvent } from "ts-events";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import ReportingWidgetApiAdapter from "./ReportingWidgetApiAdapter";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";

interface IComorbidityWidgetDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    conditionsApiAdapter: ConditionsApiAdapter;
    reportingApiAdapter: ReportingWidgetApiAdapter;
}

interface IComorbidityWidgetProps extends IWidgetComponentProps {
    _dependencies?: IComorbidityWidgetDependencies;
    _patientId?: PatientId;
    _patient?: PatientAdministrativeData;
    _diagnosisListChangedEvent?: VoidSyncEvent;
}

/** @screen */
@State.observer
class ComorbidityWidget extends React.Component<IComorbidityWidgetProps> {

    private get referenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get conditionsApiAdapter() { return this.props._dependencies.conditionsApiAdapter; }
    private get reportingApiAdapter() { return this.props._dependencies.reportingApiAdapter; }

    private apiAdapter = new ComorbidityWidgetApiAdapter();
    @State.observable.ref private displayData: IConditionDisplayData[] = null;

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);

    public componentDidMount() {
        this.loadSync();
        this.props._diagnosisListChangedEvent?.attach(this.loadSync);
    }

    public componentWillUnmount() {
        this.props._diagnosisListChangedEvent?.detach(this.loadSync);
    }

    @State.bound
    private loadSync() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.action.bound
    private async loadAsync() {
        await State.when(() => !!this.props._patient && !!this.props._patientId);

        const diagnosisResponse = await this.reportingApiAdapter.getDiagnosesFrequencyByPatientIdAsync(this.props._patientId.value);
        const reqData = {
            gender: this.props._patient.baseData.genderId.value,
            age: this.props._patient.baseData.ageInfo.birthDate?.stringify(),
            conditions: diagnosisResponse.value
        } as IGetComorbidityRequestData;

        const response = await this.apiAdapter.getDataAsync(reqData);

        if (response[0].body.results === "Not found") {
            return;
        }

        const results = Object.values(response[0].body.results);
        const data = results.map((r: any) => {
            return {
                antecedents: r.antecedents.map((a: any) => a),
                confidence: Math.round(r.confidence * 100),
                consequence: r.consequence
            } as IComorbidityData;
        });

        const sortedData = data.sort((a, b) => {
            return b.confidence - a.confidence;
        });

        const versions = await this.getConditionVersionsAsync(sortedData.map(d => d.consequence));

        State.runInAction(() => {
            this.displayData = sortedData.map(d => {
                const currentVersion = versions.find(v => v.code === d.consequence);
                const versionIsNullOrUndefined = isNullOrUndefined(currentVersion);
                return {
                    code: versionIsNullOrUndefined ? d.consequence : currentVersion.code,
                    name: versionIsNullOrUndefined ? "" : currentVersion.name,
                    confidence: d.confidence + "%"
                } as IConditionDisplayData;
            });
        });
    }

    @State.bound
    private async getConditionVersionsAsync(codes: string[]) {
        const response = await this.conditionsApiAdapter.getConditionIdsByCodesAsync(codes);
        const versionSelectors = response.value.map(c => {
            return {
                id: c.value,
                validOn: LocalDate.today()
            } as IEntityVersionSelector<ConditionId>;
        });
        await this.referenceDataStore.condition.ensureLoadedAsync(versionSelectors);
        return this.referenceDataStore.condition.getAll(versionSelectors);
    }

    @State.bound
    private renderCells() {
        return this.displayData.map(d => {
            return (
                <tr key={d.code + "_" + d.confidence}>
                    <td>{d.code}</td>
                    <td>{d.name}</td>
                    <td>{d.confidence}</td>
                </tr>
            );
        });
    }

    @State.bound
    private renderWidgetContent() {
        if (isNullOrUndefined(this.displayData) || this.displayData.length === 0) {
            return (
                <div style={{ textAlign: "center" }}>
                    {StaticProductivityResources.Widgets.ComorbidityWidget.ComorbidityNotFoundMessage}
                </div>
            );
        }
        return (
            <Ui.Table visualStyle="dark" style={{ width: "98%", padding: "3px" }} automationId="comorbidityWidgetTable">
                <thead>
                    <tr>
                        <th>{StaticProductivityResources.Widgets.ComorbidityWidget.IdentifierName}</th>
                        <th>{StaticProductivityResources.Widgets.ComorbidityWidget.Name}</th>
                        <th>{StaticProductivityResources.Widgets.ComorbidityWidget.Confidence}</th>
                    </tr>
                    {this.displayData && this.renderCells()}
                </thead>
            </Ui.Table>
        );
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return null;
        }

        return (
            <Ui.Dashboard.Widget
                name={this.props.name}
                isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                icon={"notesMedicalSolid"}
                isCollapsible
                title={StaticProductivityResources.Widgets.ComorbidityWidget.Title}
                containerHeight={this.props.configuration.InitialLayout.Height}
                automationId="ComorbidityWidget"
            >
                <>
                    {this.renderWidgetContent()}
                </>
            </Ui.Dashboard.Widget>
        );
    }
}

export default connect(
    withLoadingBoundary(ComorbidityWidget),
    new DependencyAdapter<IComorbidityWidgetProps, IComorbidityWidgetDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        conditionsApiAdapter: c.resolve("ConditionsApiAdapter"),
        reportingApiAdapter: c.resolve("ReportingWidgetApiAdapter")
    })),
    new PatientContextAdapter<IComorbidityWidgetProps>(c => ({
        _patientId: c.patientId,
        _patient: c.patient
    })),
    new CareActivityContextAdapter((c, props) => ({
        _diagnosisListChangedEvent: c.diagnosisListChangedEvent
    }))
);