exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PropertyList_table_-zms6 {\n  line-height: 1.3;\n  width: 100%;\n}\n.PropertyList_table_-zms6 > tbody > tr th {\n  font-family: PragatiNarrow;\n  font-weight: normal;\n  color: #8695a7;\n  vertical-align: text-top;\n  text-align: left;\n}\n.PropertyList_table_-zms6 > tbody > tr td {\n  font-weight: normal;\n  color: #4f5b72;\n  vertical-align: text-top;\n  text-align: left;\n}\n", ""]);

// exports
exports.locals = {
	"table": "PropertyList_table_-zms6"
};