import React from "react";
import * as Ui from "@CommonControls";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IServiceRequestAppointmentWarningDialogViewProps {
    identifier: string;
    onClose: () => void;
}

@State.observer
export default class ServiceRequestAppointmentWarningDialogView extends React.Component<IServiceRequestAppointmentWarningDialogViewProps> {
    public render() {
        return (
            <Ui.Modal
                title={StaticSchedulingResources.PractitionerAppointments.ServiceRequestAppointmentWarningDialog.Title}
                isOpen
                onClose={this.props.onClose}
                size="compact"
                closeButton
                closeOnOutsideClick={true}
                closeOnEscape={true}>
                <Ui.Modal.Body>
                    <Ui.Message type="primary">
                        {StaticSchedulingResources.PractitionerAppointments.ServiceRequestAppointmentWarningDialog.Message}
                        <b>{this.props.identifier}</b>
                    </Ui.Message>
                </Ui.Modal.Body>
            </Ui.Modal>
        );
    }
}