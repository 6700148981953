import React from "react";
import { UniversalEnumSelector } from "@CommonControls";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import RequestedServiceBatchSelection from "./RequestedServiceBatchSelection";

interface IBatchSelectionSelectBoxProps extends ISelectBoxBaseProps {
    value: RequestedServiceBatchSelection;
    onChange: (newValue: RequestedServiceBatchSelection) => void;
}


const BatchSelectionSelectBox: React.FC<IBatchSelectionSelectBoxProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"RequestedServiceBatchSelection"}
        enumType={RequestedServiceBatchSelection}
        enumOrigin="client"
        displayMode="selectBox"
    />
);

export default BatchSelectionSelectBox;