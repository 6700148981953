import React, { useCallback } from "react";
import { iconNameType } from "@CommonControls/Icon";
import Button from "@CommonControls/Button";
import { IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import Styles from "./PagerButton.less";

interface IPagerButtonProps {
    targetPage: number;
    pagingState: IPagingState;
    iconName?: iconNameType;
    automationId?: string;
    isDisabled?: boolean;

    onPagingStateChange?: (pagingState: IPagingState) => void;
}


const PagerButton: React.FC<IPagerButtonProps> = props => {

    const click = useCallback(() => {
        if (props.onPagingStateChange) {
            props.onPagingStateChange({
                ...props.pagingState,
                currentPage: props.targetPage
            });
        }
    }, [props.onPagingStateChange, props.targetPage, props.pagingState]);

    const isActive = !props.isDisabled && props.pagingState.currentPage === props.targetPage;

    return (
        <Button
            visualStyle={!!props.iconName ? "standard" : "flat"}
            key={props.targetPage}
            size="compact"
            text={!!props.iconName ? undefined : `${props.targetPage + 1}`}
            iconName={props.iconName}
            active={isActive}
            onClick={click}
            automationId={props.automationId || (isActive ? "__page" + props.targetPage + "__current" : "__page" + props.targetPage)}
            disabled={props.isDisabled}
            className={!props.iconName && !isActive && Styles.notActive}
        />
    );
};

export default PagerButton;