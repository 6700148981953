import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import React from "react";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { IAddTextBlockDialogResult, IAddTextBlockDialogParams } from "./AddTextBlockDialogParams";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import TextBlockTypeSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/TextBlockTypeSelectBox";
import { ContextAwareModal } from "@HisPlatformControls";

interface IAddTextBlockDialogDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IAddTextBlockDialogProps extends IModalComponentParams<IAddTextBlockDialogResult>, IAddTextBlockDialogParams {
    _dependencies: IAddTextBlockDialogDependencies;
}

/** @screen */
@State.observer
class AddTextBlockDialog extends React.Component<IAddTextBlockDialogProps> {

    @State.observable.ref public textBlockTypeId: TextBlockTypeId = null;
    
    @State.action.bound
    public setTextBlockTypeId(textBlockTypeId: TextBlockTypeId) {
        this.textBlockTypeId = textBlockTypeId;
    }

    private get idFilter() {
        return this.props._dependencies.careReferenceDataStore.textBlockType?.items?.filter(i => !this.props.alreadyExistingTextBlockTypes.some(j => ValueWrapper.equals(i.id, j))).map(i => i.id) || [];
    }

    public render() {
        return (
            <ContextAwareModal isOpen onClose={this.cancel} title={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.AddTextBlockModalTitle} size="tiny">
                <Ui.Modal.Body>
                    <TextBlockTypeSelectBox
                        idFilter={this.idFilter}
                        value={this.textBlockTypeId}
                        onChange={this.setTextBlockTypeId}
                        label={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.Columns.TextBlockType}
                        automationId="textBlockTypeSelectBox"
                    />
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Button onClick={this.cancel} text={StaticWebAppResources.Common.Button.Cancel} float="left" automationId="addTextBlockDialogCancelButton" />
                    <Ui.Button onClick={this.ok} text={StaticWebAppResources.Common.Button.Add} visualStyle="primary" float="right" automationId="addTextBlockDialogOkButton" />
                </Ui.Modal.Footer>
            </ContextAwareModal>
        );
    }

    @State.bound
    private cancel() {
        this.props.onClose(null);
    }
 
    @State.bound
    private ok() {
        this.props.onClose({textBlockTypeId: this.textBlockTypeId});
    }
}

export default connect(
    AddTextBlockDialog,
    new DependencyAdapter<IAddTextBlockDialogProps, IAddTextBlockDialogDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore")
    }))
);