import React, { useContext, useMemo } from "react";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

class FormLayoutContextStore {
    constructor(public readonly compositeDataReferences: string[]) {
    }
}

const FormLayoutReactContext = React.createContext<FormLayoutContextStore | null>(null);

export function useFormLayoutContext() {
    return useContext(FormLayoutReactContext);
}

export function FormLayoutContextProvider(props: React.PropsWithChildren<{ dataReference: string }>) {
    const parentContext = useFormLayoutContext();

    const store = useMemo(() => isNullOrUndefined(props.dataReference)
        ? null
        : new FormLayoutContextStore(parentContext
            ? [...parentContext.compositeDataReferences, props.dataReference]
            : [props.dataReference]),
        [props.dataReference, parentContext]);

    if (isNullOrUndefined(props.dataReference)) {
        return (
            <>
                {props.children}
            </>
        );
    }

    return (
        <FormLayoutReactContext.Provider value={store}>
            {props.children}
        </FormLayoutReactContext.Provider>
    );
}