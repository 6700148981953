import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import * as Ui from "@CommonControls";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import PregnancyStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/PregnancyStatementStore";
import PregnancyStatementId from "@Primitives/PregnancyStatementId.g";
import PregnancyStatementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/PregnancyStatement/PregnancyStatementApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import MedicalConditionHistoryItemDetailPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicalConditionHistoryItemDetailPanel";
import PregnancyStatementFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/PregnancyStatementFactory";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import PatientId from "@Primitives/PatientId.g";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import PregnancyStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/PregnancyStatementHistoryItem";
import MedicalConditionItemHeaderPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicalConditionItemHeaderPanel";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import { EntityLockState } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import PregnancyOutcomeAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/PregnancyOutcomeAssignmentBase";
import CustomPregnancyOutcomeAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/CustomPregnancyOutcomeAssignment";
import ObservationId from "@Primitives/ObservationId.g";
import CategoryObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/CategoryObservation";
import ReferencedPregnancyOutcomeAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/ReferencedPregnancyOutcomeAssignment";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import { CategoryObservationCodeSelector } from "@HisPlatformControls";
import { CategoryObservationCodeSelectorItem } from "@HisPlatformControls/CategoryObservationCodeSelector/CategoryObservationCodeSelectorItem";
import TextualObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/TextualObservation";
import ObservationDefinitionId from "@Primitives/ObservationDefinitionId.g";
import Category from "@Toolkit/CommonWeb/Model/Category";
import ObservationDefinitionApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ObservationDefinitionApiAdapter";
import ObservationsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/Observations/ObservationsApiAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IServiceRequestHeaderExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestHeaderExtensionPointProps";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import FormExtensionRegistryAdapter from "@PluginInterface/FormExtension/FormExtensionRegistryAdapter";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";

interface IPregnancyStatementPanelDependencies {
    dialogService: IDialogService;
    notificationService: INotificationService;
    pregnancyStatementApiAdapter: PregnancyStatementApiAdapter;
    pregnancyStatementFactory: PregnancyStatementFactory;
    lockingApiAdapter: LockingApiAdapter;
    observationDefinitionApiAdapter: ObservationDefinitionApiAdapter;
    observationsApiAdapter: ObservationsApiAdapter;
}

interface IPregnancyStatementPanelProps extends IRoutingFrameContentProps {
    _dependencies?: IPregnancyStatementPanelDependencies;
    _patientId?: PatientId;
    _formExtension?: IFormExtension<PatientId>;

    id: PregnancyStatementId;
    onPregnancyStatementCreatedAsync?: (pregnancyStatementId: PregnancyStatementId) => Promise<void>;
    onCloseDetailAsync: () => Promise<void>;
    onRefreshListAsync: () => Promise<void>;
}

@State.observer
class PregnancyStatementPanel extends React.Component<IPregnancyStatementPanelProps> {

    private get pregnancyStatementId() { return this.props.id; }
    private get patientId() { return this.props._patientId; }
    @State.observable.ref private store: PregnancyStatementStore = this.pregnancyStatementFactory.createNewStore();
    @State.computed private get currentHistoryItem() { return this.store.currentHistoryItem as PregnancyStatementHistoryItem; }
    @State.computed private get isNew() { return this.pregnancyStatementId.value === "new"; }
    @State.observable.ref private categoryObservationDefinitionId: ObservationDefinitionId = null;
    @State.observable.ref private textualObservationDefinitionId: ObservationDefinitionId = null;

    private get dialogService() { return this.props._dependencies.dialogService; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get pregnancyStatementApiAdapter() { return this.props._dependencies.pregnancyStatementApiAdapter; }
    private get pregnancyStatementFactory() { return this.props._dependencies.pregnancyStatementFactory; }
    private get lockingApiAdapter() { return this.props._dependencies.lockingApiAdapter; }
    private get observationDefinitionApiAdapter() { return this.props._dependencies.observationDefinitionApiAdapter; }
    private get observationsApiAdapter() { return this.props._dependencies.observationsApiAdapter; }

    private get resources() { return StaticCareResources.MedicalCondition.PregnancyStatements; }
    private get medicalConditionResources() { return StaticCareResources.MedicalCondition; }
    private get labels() { return this.resources.Label; }

    private get pregnancyStatementObservationDefinitionCode() { return "PregnancyOutcome"; }
    private get customPregnancyStatementObservationDefinitionCode() { return "CustomPregnancyOutcome"; }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(
        this.loadAsync,
        this.isNew
            ? async () => await this.props._dependencies.pregnancyStatementApiAdapter.checkPermissionForAddNewItemAsync(this.pregnancyStatementFactory.createNewStore(), new PatientId("1"))
            : () => Promise.resolve([])
    );

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IPregnancyStatementPanelProps) {
        if (prevProps.id.value !== this.props.id.value) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.action.bound
    private async loadAsync(forceReleaseLockNeeded: boolean = false) {
        if (forceReleaseLockNeeded && !!this.store?.lockInfo?.preventingLockId) {
            await this.lockingApiAdapter.forceReleaseLockAsync(this.store.lockInfo.preventingLockId);
        }

        this.setCategoryObservationDefinitionId((await this.observationDefinitionApiAdapter.loadObservationDefinitionByCodeAsync(this.pregnancyStatementObservationDefinitionCode)).value.id);
        this.setTextualObservationDefinitionId((await this.observationDefinitionApiAdapter.loadObservationDefinitionByCodeAsync(this.customPregnancyStatementObservationDefinitionCode)).value.id);

        let store = null;
        if (this.isNew) {
            store = this.pregnancyStatementFactory.createNewStore();
        } else {
            store = await this.pregnancyStatementApiAdapter.getByIdAsync(this.pregnancyStatementId, true);
        }

        this.store.setExtensionData(store.extensionData);
        await this.initializeContentAsync(store);
        this.setStore(store);
    }

    @State.action.bound
    private async initializeContentAsync(value: PregnancyStatementStore) {
        const contentLoaderPromises = value.historyItems
            .filter(pms =>
                pms instanceof PregnancyStatementHistoryItem &&
                pms.pregnancyOutcomeAssignment instanceof CustomPregnancyOutcomeAssignment &&
                !isNullOrUndefined(pms.pregnancyOutcomeAssignment.textualObservation.careContentId))
            .map(async pms => {
                const historyItem = pms as PregnancyStatementHistoryItem;
                const customAssignment = historyItem.pregnancyOutcomeAssignment as CustomPregnancyOutcomeAssignment;

                customAssignment.textualObservation.startContentLoading();
                const contentResponse = await this.observationsApiAdapter.getTextualObservationContentAsync(customAssignment.textualObservation.careContentId);
                customAssignment.textualObservation.initializeContent(contentResponse.value);
            });

        await Promise.all(contentLoaderPromises);
    }

    @State.action.bound
    private setCategoryObservationDefinitionId(newValue: ObservationId) {
        this.categoryObservationDefinitionId = newValue;
    }

    @State.action.bound
    private setTextualObservationDefinitionId(newValue: ObservationId) {
        this.textualObservationDefinitionId = newValue;
    }

    @State.action.bound
    private setStore(newValue: PregnancyStatementStore) {
        this.store = newValue;
        this.store.takeSnapshot();
    }

    @State.bound
    private async navigateAwayAsync() {
        if (this.store?.isMutable === true && this.isUnsavedOrDirty()) {
            const dialogResult = await this.dialogService.confirmIfNotSaved(StaticCareResources.Common.Dialog.ConfirmationTitle, StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);
            if (dialogResult.resultCode === DialogResultCode.Yes) {
                await this.saveStoreAsync();
                await this.releaseLockAsync();
            } else if (dialogResult.resultCode === DialogResultCode.No) {
                await this.releaseLockAsync();
                return true;
            }
            return false;
        }
        await this.releaseLockAsync();
        return true;
    }

    @State.action.bound
    private async releaseLockAsync() {
        if (this.store.isMutable && this.store.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld) {
            await this.lockingApiAdapter.releaseLockAsync(this.store.lockInfo.lockId);
            this.store.releaseLock();
            return true;
        }
        return false;
    }

    private isUnsavedOrDirty() {
        return this.store.isDirty() && this.currentHistoryItem.isNew;
    }

    @State.action.bound
    private async validateAsync(): Promise<IClientValidationResult[]> {
        if (!this.store || !this.store.isMutable || !this.currentHistoryItem.isNew) {
            return [];
        }
        const validationResultResponse = await this.pregnancyStatementApiAdapter.validateAsync(this.store, this.patientId);
        return validationResultResponse.value;
    }

    @State.action.bound
    private async validateBeforeSaveAsync() {
        const newValue = await this.validateAsync();
        this.store.setValidationResults(newValue);
    }

    @State.action.bound
    private async saveStoreAsync() {
        await this.validateBeforeSaveAsync();

        if (this.store.hasValidationError) {
            return false;
        }

        await this.setExtensionDataAsync();

        let newStore = null;

        if (this.isNew) {
            newStore = await this.pregnancyStatementApiAdapter.addPregnancyStatementAsync(this.store, this.patientId);
        } else {
            newStore = await this.pregnancyStatementApiAdapter.updatePregnancyStatementAsync(this.store);
        }

        if (newStore.operationWasSuccessful && newStore.isPersistedByOperationInfo && this.isNew) {
            this.store.setExtensionData(newStore.extensionData);
            this.store.takeSnapshot();
            await this.props.onPregnancyStatementCreatedAsync?.(newStore.id);
            return true;
        }

        await this.replaceStoreAsync(newStore);
        await this.props.onRefreshListAsync();
        return true;
    }

    @State.action.bound
    private async setExtensionDataAsync() {
        const extensionDataArray = await this.props._formExtension.invokeCallbackAsync<any>("ExtendStore", this.props._patientId);

        let extensionDataForStore = this.store.extensionData;
        for (const extensionData of extensionDataArray) {
            extensionDataForStore = { ...extensionData, ...extensionDataForStore };
        }

        this.store.setExtensionData(extensionDataForStore);
    }

    @State.action.bound
    private async replaceStoreAsync(newStore: PregnancyStatementStore) {
        if (newStore.operationWasSuccessful) {
            if (newStore.isPersistedByOperationInfo) {
                newStore.lockInfo = this.store.lockInfo;
                await this.initializeContentAsync(newStore);
                this.setStore(newStore);
                this.notificationService.showSavedSuccessfully();
            } else {
                State.runInAction(() => {
                    this.store.validationResults = newStore.validationResults;
                });
            }
        }
    }

    @State.action.bound
    private async deleteHistoryItemAsync() {
        const message = formatString(this.resources.Dialog.DeleteVersionConfirmationMessage, (this.store.currentItemIndex + 1).toString());
        const dialogResult = await this.dialogService.yesNo(StaticCareResources.Common.Dialog.ConfirmationTitle, message);

        if (dialogResult.resultCode === DialogResultCode.No) {
            return false;
        }

        await this.setExtensionDataAsync();

        if (this.store.historyItems.length === 1) {
            await this.pregnancyStatementApiAdapter.deletePregnancyStatementAsync(this.store);
            this.store.releaseLock();
            this.props.onCloseDetailAsync();
            return true;
        } else {
            const newStore = await this.pregnancyStatementApiAdapter.deletePregnancyStatementHistoryItemAsync(this.store, this.store.currentHistoryItem.versionNumber);

            await this.replaceStoreAsync(newStore);
            return true;
        }
    }

    @State.action.bound
    private async forceLoadAsync() {
        await this.loadAsync(true);
    }

    @State.computed
    private get errorExtensionProps() {
        return {
            extensionData: this.store.currentHistoryItem.extensionData
        } as IServiceRequestHeaderExtensionPointProps;
    }

    @State.bound
    private showNoEhiCompatibleIdentifierFoundError() {
        this.notificationService.error(this.medicalConditionResources.Common.Messages.NoEhiCompatibleIdentifierFoundErrorMessage);
        return true;
    }

    @State.computed
    public get renderErrorExtension() {
        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }

        return (
            <HisPlatformExtensionPoint
                type="ServiceRequestHeader"
                extensionProps={this.errorExtensionProps}>
                <></>
            </HisPlatformExtensionPoint>
        );
    }

    public render() {
        const prefix = this.store.validationPropertyPrefix;
        const expectedAtValue = this.store.isNew ? StaticCareResources.MedicalCondition.Common.Label.NewEntry : this.store.expectedAt.stringify();

        return (
            <PermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                <BusinessErrorHandler.Register businessErrorName="NoEhiCompatibleIdentifierFoundError" handler={this.showNoEhiCompatibleIdentifierFoundError} />
                <UseCaseFrame
                    toolbar={(
                        <MedicalConditionItemHeaderPanel
                            store={this.store}
                            onDeleteHistoryItemAsync={this.deleteHistoryItemAsync}
                            onSaveStoreAsync={this.saveStoreAsync}
                            onLockEditClickedAsync={this.forceLoadAsync} />
                    )}
                    title={expectedAtValue}>
                    {this.initialLoadPanelAsync.isUnauthorizedAccess ? <UnauthorizedAccessContent /> :
                        <>
                            {this.renderErrorExtension}
                            <ValidationBoundary
                                validationResults={this.store.validationResults}
                                entityTypeName="PregnancyStatement"
                                onValidateAsync={this.validateAsync}
                                validateOnMount
                            >
                                <ReadOnlyContext.Provider value={!this.store.isMutable || !this.currentHistoryItem.isNew}>
                                    <Ui.Flex>
                                        <Ui.Flex.Item sm={4}>
                                            <Ui.DatePicker
                                                label={this.labels.ExpectedAt}
                                                value={this.store.expectedAt}
                                                onChange={this.store.setExpectedAt}
                                                propertyIdentifier="ExpectedAt"
                                                automationId="__expectedAt"
                                                isReadOnly={!this.store.isNew}
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item sm={8}>
                                            <CategoryObservationCodeSelector
                                                label={this.labels.PregnancyOutcomeAssignment}
                                                title={StaticCareResources.MedicalCondition.PregnancyOutcomeAssignments.Title}
                                                value={this.currentHistoryItem.pregnancyOutcomeCodeSelectorItem}
                                                categoryObservationDefinitionCode={this.pregnancyStatementObservationDefinitionCode}
                                                onChange={this.setPregnancyOutcomeAssignment}
                                                propertyIdentifier="PregnancyOutcomeAssignmentValue"
                                                automationId="__pregnancyOutcomeCode"
                                            />
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                    <br />
                                    <MedicalConditionHistoryItemDetailPanel store={this.store} prefix={prefix} />
                                </ReadOnlyContext.Provider>
                            </ValidationBoundary>
                            <NavigateAwayHook onNavigateAwayAsync={this.navigateAwayAsync} />
                        </>
                    }
                </UseCaseFrame>
            </PermissionCheckContextProvider>
        );
    }

    @State.computed private get permissionCheckedOperations() {
        const res = {};

        const store = this.pregnancyStatementFactory.createNewStore();
        store.id = new PregnancyStatementId("1");

        if (this.props.id) {
            res["AddNewVersion"] = async () => await this.props._dependencies.pregnancyStatementApiAdapter.checkPermissionForUpdateItemAsync(store);
            res["DeleteVersion"] = async () => await this.props._dependencies.pregnancyStatementApiAdapter.checkPermissionForDeleteItemAsync(store);
        }

        return res;
    }

    @State.bound
    private setPregnancyOutcomeAssignment(newValue: CategoryObservationCodeSelectorItem) {

        let pregnancyOutcomeAssignment: PregnancyOutcomeAssignmentBase;
        if (!newValue) {
            pregnancyOutcomeAssignment = null;
        } else if (!newValue.code) {
            const textual = new TextualObservation(null, this.textualObservationDefinitionId, null, this.patientId, null, null);
            textual.initializeContent(newValue.name);
            pregnancyOutcomeAssignment = new CustomPregnancyOutcomeAssignment(textual);
        } else {
            const category = new CategoryObservation(null, this.categoryObservationDefinitionId, null, this.patientId, null, [new Category(newValue.code, newValue.name)]);
            pregnancyOutcomeAssignment = new ReferencedPregnancyOutcomeAssignment(category);
        }

        this.currentHistoryItem.setPregnancyOutcomeAssignment(pregnancyOutcomeAssignment);
    }
}

export default connect(
    PregnancyStatementPanel,
    new DependencyAdapter<IPregnancyStatementPanelProps, IPregnancyStatementPanelDependencies>(c => ({
        dialogService: c.resolve<IDialogService>("IDialogService"),
        notificationService: c.resolve<INotificationService>("INotificationService"),
        pregnancyStatementApiAdapter: c.resolve<PregnancyStatementApiAdapter>("PregnancyStatementApiAdapter"),
        pregnancyStatementFactory: c.resolve<PregnancyStatementFactory>("PregnancyStatementFactory"),
        lockingApiAdapter: c.resolve<LockingApiAdapter>("LockingApiAdapter"),
        observationDefinitionApiAdapter: c.resolve<ObservationDefinitionApiAdapter>("ObservationDefinitionApiAdapter"),
        observationsApiAdapter: c.resolve<ObservationsApiAdapter>("ObservationsApiAdapter")
    })),
    new PatientContextAdapter<IPregnancyStatementPanelProps>(c => ({
        _patientId: c.patientId
    })),
    new FormExtensionRegistryAdapter("ManageMedicalCondition")
);
