import AppointmentScheduleDefinitionId from "@Primitives/AppointmentScheduleDefinitionId.g";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IAppointmentSubjectStore } from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/IAppointmentSubjectStore";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import AppointmentParticipantOptions from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/AppointmentParticipantOptions.g";
import { hasFlags as hasFlag } from "@Toolkit/CommonWeb/EnumHelpers";

export default class SchedulingServiceSubjectStore extends EntityStoreBase<SchedulingServiceId> implements IAppointmentSubjectStore {
    @State.observable public name: string = null;
    @State.observable public code: string = null;
    @State.observable public isTelemedicineConsultation: boolean = false;
    @State.observable public durationInMinutes?: number = null;
    @State.observable public careLocationId: OrganizationUnitId = null;
    @State.observable public careLocationDescription: string = null;
    @State.observable public participantOptions: AppointmentParticipantOptions = AppointmentParticipantOptions.None;

    @State.computed
    public get isAdditionalParticipantsAllowed(): boolean {
        return hasFlag(this.participantOptions, AppointmentParticipantOptions.AdditionalParticipantsAllowed);
    }

    @State.computed
    public get isAdditionalParticipantNotificationAllowed(): boolean {
        return hasFlag(this.participantOptions, AppointmentParticipantOptions.AdditionalParticipantNotificationAllowed);
    }

    @State.computed
    public get isParticipantNotificationAllowed(): boolean {
        return hasFlag(this.participantOptions, AppointmentParticipantOptions.ParticipantNotificationAllowed);
    }

    @State.computed
    public get isPatientNotificationAllowed(): boolean {
        return hasFlag(this.participantOptions, AppointmentParticipantOptions.PatientNotificationAllowed);
    }

    public owner: AppointmentScheduleDefinitionId;

    constructor(isNew?: boolean) {
        super(isNew);
        this.validationResults = [];
    }

    @State.computed
    public get codeValue() {
        return this.code;
    }

    @State.action.bound public setName(newValue: string) {
        this.name = newValue;
    }

    @State.action.bound public setCode(newValue: string) {
        this.code = newValue;
    }

    @State.action.bound public setIsTelemedicineConsultation(newValue: boolean) {
        this.isTelemedicineConsultation = newValue;
    }

    @State.action.bound public setDurationInMinutes(newValue: number) {
        this.durationInMinutes = newValue;
    }

    @State.action.bound public setCareLocationId(newValue: OrganizationUnitId) {
        this.careLocationId = newValue;
    }

    @State.action.bound public setCareLocationDescription(newValue: string) {
        this.careLocationDescription = newValue;
    }

    @State.action.bound
    public setParticipantOptions(value: AppointmentParticipantOptions): void {
        this.participantOptions = value;
    }
}