import StatusChangeReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/StatusChangeReason";
import StatusChangeReasonTypeId from "@Primitives/StatusChangeReasonTypeId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StatusChangeReasonApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/StatusChangeReasonApiAdapter";
import Di from "@Di";

@Di.injectable()
export default class StatusChangeReasonStore {
    private readonly reasonsByTypes = new Map<string, StatusChangeReason[]>();
    
    constructor(
        @Di.inject("StatusChangeReasonApiAdapter") private readonly apiAdapter: StatusChangeReasonApiAdapter
    ) {}

    public async ensureLoadedAsync(typeId: StatusChangeReasonTypeId) {
        if (isNullOrUndefined(typeId)) {
            return;
        }

        if (this.reasonsByTypes.has(typeId.value)) {
            return;
        }

        const result = await this.apiAdapter.loadStatusChangeReasonsAsync(typeId);
        
        if (result.operationWasSuccessful) {
            this.reasonsByTypes.set(typeId.value, result.value);
        }
    }

    public async getOrLoadByTypeIdAsync(typeId: StatusChangeReasonTypeId) {
        if (isNullOrUndefined(typeId)) {
            return null;
        }

        await this.ensureLoadedAsync(typeId);
        return this.reasonsByTypes.get(typeId.value);
    }

    public getByTypeId(typeId: StatusChangeReasonTypeId) {
        if (isNullOrUndefined(typeId)) {
            return null;
        }
        
        return this.reasonsByTypes.get(typeId.value);
    }
}