import LateralityId from "@Primitives/LateralityId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";
import ConditionId from "@Primitives/ConditionId.g";
import DiagnosisId from "@Primitives/DiagnosisId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class DiagnosisStore {
    public id: DiagnosisId = null;
    @State.observable.ref public use: DiagnosisRoleId = DiagnosisRoleId.Discharge;
    @State.observable.ref public lateralityId: LateralityId = LateralityId.NotApplicable;
    @State.observable.ref public conditionVersionSelector: IEntityVersionSelector<ConditionId> = null;
    public isNew: boolean;
    public extensionData: any = null;

    constructor(isNew: boolean = false) {
        this.isNew = isNew;
    }

    @State.action.bound
    public setUse(use: DiagnosisRoleId) {
        this.use = use;
    }

    @State.action.bound
    public setLateralityId(lateralityId: LateralityId) {
        this.lateralityId = lateralityId;
    }

    @State.action.bound
    public setCondition(conditionId: ConditionId, validOn?: LocalDate) {
        this.conditionVersionSelector = { id: conditionId, validOn: validOn };
    }

    @State.action.bound
    public reset(use?: DiagnosisRoleId) {
        this.use = use;
        this.conditionVersionSelector = null;
        this.lateralityId = LateralityId.NotApplicable;
    }

    public setExtensionData(newValue: any) {
        this.extensionData = newValue;
    }
}
