import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import EpisodeOfCareSelectBoxStore from "./EpisodeOfCareSelectBoxStore";

const { 
    ContextComponent: EpisodeOfCareSelectBoxStoreContext, 
    StoreProvider: EpisodeOfCareSelectBoxStoreProvider, 
    useStore: useEpisodeOfCareSelectBoxStore,
    provideStore: provideEpisodeOfCareSelectBoxStore,
} = createPanelStoreProvider<EpisodeOfCareSelectBoxStore>(EpisodeOfCareSelectBoxStore);

export {
    EpisodeOfCareSelectBoxStoreContext,
    EpisodeOfCareSelectBoxStoreProvider,
    useEpisodeOfCareSelectBoxStore,
    provideEpisodeOfCareSelectBoxStore
};