import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import CareActivitySource from "@Primitives/CareActivitySource.g";
import * as Ui from "@CommonControls";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ResourceId from "@Primitives/ResourceId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface ICareActivitySourceRadioButtonGroupDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    localizationService: ILocalizationService;
}

interface ICareActivitySourceRadioButtonGroupProps {
    _dependencies?: ICareActivitySourceRadioButtonGroupDependencies;
    items: CareActivitySource[];
    value: CareActivitySource;
    onChange: (newValue: CareActivitySource) => void;
    label?: string;
}

@State.observer
class CareActivitySourceRadioButtonGroup extends React.Component<ICareActivitySourceRadioButtonGroupProps> {

    private referenceDataGroup: string = "CareRegister.CareActivitySource.";

    @State.bound
    private getDisplayValue(source: CareActivitySource) {
        if (!source) {
            return StaticWebAppResources.Common.Label.All;
        } else {
            return this.props._dependencies.localizationService.localizeReferenceData(new ResourceId(this.referenceDataGroup + source.value));
        }
    }

    public render() {
        return (
            <Ui.RadioButtonGroup
                label={this.props.label}
                value={this.props.value}
                onChange={this.props.onChange}
                items={this.props.items}
                getDisplayValue={this.getDisplayValue}
                displayType="groupedButtons"
                automationId="careActivitySource"
            />
        );
    }
}

export default connect(
    CareActivitySourceRadioButtonGroup,
    new DependencyAdapter<ICareActivitySourceRadioButtonGroupProps, ICareActivitySourceRadioButtonGroupDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            localizationService: container.resolve("ILocalizationService")
        };
    })
);