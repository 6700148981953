import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import * as Ui from "@CommonControls";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import _ from "@HisPlatform/Common/Lodash";
import PerformanceReportScorePlanStore from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportScorePlanStore";
import FinancingClassSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/FinancingClassSelectBox";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import StaticHunSocialSecurityReportingResources from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/StaticResources/StaticHunSocialSecurityReportingResources";
import PlannedScoreItemStore from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PlannedScoreItemStore";
import PointOfCareColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";
import DataGridNumberColumn from "@CommonControls/DataGrid/Column/DataGridNumberColumn";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";

interface IPerformanceReportScorePlanDetailPanelViewProps {
    store: PerformanceReportScorePlanStore;
    onSaveAsync: () => Promise<void>;
    onDeleteAsync: () => Promise<void>;
    onValidateAsync?: (dirtyFields?: string[]) => Promise<IClientValidationResult[]>;
    onNavigateAwayAsync: () => Promise<boolean>;

    dataSource: InMemoryDataGridDataSource<PlannedScoreItemStore>;
    setSelectedPlannedScoreItem: (plannedScoreItemStore: PlannedScoreItemStore) => void;
    selectedPlannedScoreItem: PlannedScoreItemStore;
}

@State.observer
export default class PerformanceReportScorePlanDetailPanelView extends React.Component<IPerformanceReportScorePlanDetailPanelViewProps> {

    private get resources() { return StaticHunSocialSecurityReportingResources.PerformanceReportScorePlanDetailPanel; }

    @State.bound
    private renderDetailToolbar() {
        return (
            <>
                {
                    !this.props.store.isNew &&
                    <Ui.Button
                        onClickAsync={this.props.onDeleteAsync}
                        iconName="trash"
                        visualStyle="negative-standard"
                        automationId="deleteButton"
                        text={StaticWebAppResources.Common.Button.Delete}
                    />
                }
                <Ui.SaveButton
                    onClickAsync={this.props.onSaveAsync}
                    visualStyle="primary"
                    automationId="saveButton"
                />
            </>
        );
    }

    public render() {
        return (
            <UseCaseFrame
                toolbar={this.renderDetailToolbar()}
                title={this.resources.Title}>
                <>
                    <ValidationBoundary
                        validationResults={this.props.store.validationResults}
                        entityTypeName="PerformanceReportScorePlan"
                        onValidateAsync={this.props.onValidateAsync}
                        validateOnMount
                    >
                        <Ui.Flex>
                            <Ui.Flex.Item xs={4}>
                                <Ui.MonthPicker
                                    label={this.resources.PlanDate}
                                    value={this.props.store.planDate}
                                    onChange={this.props.store.setPlanDate}
                                    propertyIdentifier="PlanDate"
                                    automationId="planDate" />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={8}>
                                <FinancingClassSelectBox
                                    label={this.resources.FinancingClassId}
                                    value={this.props.store.financingClassId}
                                    onChange={this.props.store.setFinancingClassId}
                                    displayMode="multiselectLongValues"
                                    propertyIdentifier="FinancingClassId"
                                    automationId="financingClass" />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <Ui.DataGrid
                            dataSource={this.props.dataSource}
                            hasHeader={true}
                            fixedLayout
                            automationId="plannedScoreItemsGrid"
                            isSelectable
                            selectedRow={this.props.selectedPlannedScoreItem}
                            rowUnderEditing={this.props.selectedPlannedScoreItem}
                            onSelectedRowChange={this.props.setSelectedPlannedScoreItem}
                            generateInitialFilterStore
                            changeOnMount
                        >
                            <PointOfCareColumn
                                dataGetter={"pointOfCareId"}
                                id="PointOfCareId"
                                header={this.resources.OrganizationUnitColumn}
                                displayMode="shorthand-name"
                                isOrderable
                                isFilterable
                            />
                            <DataGridNumberColumn
                                width={150}
                                header={this.resources.PlannedScoreColumn}
                                automationId="plannedScore"
                                integerColumn
                                dataGetter={"plannedScore"}
                                dataSetter={"setPlannedScore"}
                                isObserver
                                nullValue={0}
                                isOrderable
                                isFilterable
                            />
                        </Ui.DataGrid>
                    </ValidationBoundary>
                    <NavigateAwayHook onNavigateAwayAsync={this.props.onNavigateAwayAsync} />
                </>
            </UseCaseFrame>
        );
    }
}
