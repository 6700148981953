import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class CheckBoxGroupItemStore<TItem> {

    constructor(
        private _value: TItem,
        private _selectionState: boolean | null
    ) { }

    public get value() { return this._value; }
    public get selectionState() { return this._selectionState; }

    @State.action.bound
    public setValue(value: TItem) {
        this._value = value;
    }

    @State.action.bound
    public setSelectionState(value: boolean | null) {
        this._selectionState = value;
    }
}