import React, { useContext } from "react";
import IComponentAdapter from "./IComponentAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";


export default function connect<TProps>(
    TargetComponent: React.ComponentType<TProps> | React.ForwardRefExoticComponent<TProps & React.RefAttributes<unknown>>,
    ...adapters: Array<IComponentAdapter<TProps>>
) {

    const contextTypes = new Set<React.Context<any>>();

    adapters.forEach(a => {
        const adapterContexts = a.usedContexts;

        if (adapterContexts) {
            adapterContexts.forEach(ac => {
                contextTypes.add(ac);
            });
        }
    });

    return React.forwardRef((props: TProps, ref) => {
        const contextValues = new Map(Array.from(contextTypes).map(ct => [ct, useContext(ct)]));

        return (
            <ConnectedWrapper
                adapters={adapters}
                contextValues={contextValues}
                wrappedComponentType={TargetComponent}
                wrappedRef={ref}
                wrappedProps={props}
            />
        );
    });
}

interface IConnectedWrapperProps<TProps> {
    contextValues: Map<React.Context<any>, any>;
    adapters: Array<IComponentAdapter<TProps>>;
    wrappedRef: React.Ref<unknown>;
    wrappedProps: TProps;
    wrappedComponentType: React.ComponentType<TProps>;
}

@State.observer
class ConnectedWrapper<TProps> extends React.Component<IConnectedWrapperProps<TProps>> {

    private memoizedAdaptersState: Map<IComponentAdapter<TProps>, any> = null;

    public constructor(props: IConnectedWrapperProps<TProps>) {
        super(props);

        this.memoizedAdaptersState = new Map<IComponentAdapter<TProps>, any>(
            this.props.adapters.map(a => [a, a.getMemoizedState && a.getMemoizedState(this.props.wrappedProps, this.props.contextValues)])
        );
    }

    public componentWillUnmount() {
        this.props.adapters.forEach(adapter => {
            adapter.dispose?.(this.props.wrappedProps, this.props.contextValues, this.memoizedAdaptersState.get(adapter));
        });
    }

    public render() {
        const WrappedComponentType = this.props.wrappedComponentType;

        const mappedProps = this.props.adapters.reduce((mp, a) => {
            return {
                ...mp,
                ...a.getMappedProps(
                    mp,
                    this.props.contextValues,
                    this.memoizedAdaptersState.get(a)
                )
            };
        }, this.props.wrappedProps);

        return <WrappedComponentType {...this.props.wrappedProps} {...mappedProps} ref={this.props.wrappedRef} />;
    }
}