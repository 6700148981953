import BoolProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/BoolProperty";
import NumericProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/NumericProperty";
import NumericRangeProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/NumericRangeProperty";
import StringProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/StringProperty";
import * as Proxy from "@HisPlatform/BoundedContexts/Configuration/Api/Proxy.g";
import NumericRange from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/NumericRange";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PropertyValueVersion from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyValueVersion";

export default function convertPropertyValue(propDto: Proxy.PropertyDto) {

    const valueForType = propDto.versions?.length > 0 ? propDto.versions[0].value : propDto.defaultValue;

    if (isNullOrUndefined(valueForType)) {
        return null;
    }

    if (valueForType instanceof Proxy.BoolPropertyValue) {
        return new BoolProperty(
            mapVersions<boolean>(propDto.versions),
            propDto.name,
            propDto.propertyDefinitionId,
            propDto.isTemporal,
            propDto.defaultValue && (propDto.defaultValue as Proxy.BoolPropertyValue).value
        );
    } else if (valueForType instanceof Proxy.NumericPropertyValue) {
        return new NumericProperty(
            mapVersions<number>(propDto.versions),
            propDto.name,
            propDto.propertyDefinitionId,
            propDto.isTemporal,
            propDto.defaultValue && (propDto.defaultValue as Proxy.NumericPropertyValue).value
        );
    } else if (valueForType instanceof Proxy.NumericRangePropertyValue) {
        return new NumericRangeProperty(
            mapVersions<NumericRange>(propDto.versions, value => convertToNumericRange((value as Proxy.NumericRangePropertyValue).value)),
            propDto.name,
            propDto.propertyDefinitionId,
            propDto.isTemporal,
            propDto.defaultValue && convertToNumericRange((propDto.defaultValue as Proxy.NumericRangePropertyValue).value)
        );
    } else if (valueForType instanceof Proxy.StringPropertyValue) {
        return new StringProperty(
            mapVersions<string>(propDto.versions),
            propDto.name,
            propDto.propertyDefinitionId,
            propDto.isTemporal,
            propDto.defaultValue && (propDto.defaultValue as Proxy.StringPropertyValue).value
        );
    } else {
        throw new Error(`Unsupported property type.`);
    }
}

function mapVersions<T>(versions: Proxy.PropertyVersionDto[], valueConverter?: (value: Proxy.PropertyValueBase) => T) {
    return versions.map(v => new PropertyValueVersion(valueConverter ? valueConverter(v.value) : (v.value as any).value, v.validFrom, v.validTo));
}

function convertToNumericRange(proxyValue: Proxy.NumericRange): NumericRange {
    return proxyValue ? new NumericRange(proxyValue.from, proxyValue.to) : new NumericRange(null, null);
}
