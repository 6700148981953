import FormFieldData from "./FormFieldData";

export function generateFormFieldDependencyLogic(sourceFieldPath: string, targetFieldData: FormFieldData, targetFieldValues: string[]) {
    return `
        //${JSON.stringify(targetFieldData)}
        //${targetFieldValues.join("---")}

        module.exports = async (arg1) => {
            const json = JSON.parse(arg1);
            const sourceField = getField(json.FormFieldData, "${sourceFieldPath}");
            const targetField = getField(json.FormFieldData, "${targetFieldData.fullyQualifiedDataReferencePath}");
            const targetFieldValues = ${JSON.stringify(targetFieldValues)};

            if (sourceField && targetField) {
                const comparedValue = typeof targetField.Value === "string" ? targetField.Value : JSON.stringify(targetField.Value);
                sourceField.IsVisible = targetField.IsVisible && targetFieldValues.includes(comparedValue);
            }

            const jsonInString = JSON.stringify(json);
            return jsonInString;
        };

        function getField(formFields, path) {
            const pathParts = path.split(".");
            let actualFields = formFields;
            let resultField = null;
        
            for (let i = 0; i < pathParts.length; i++) {
                const pathPart = pathParts[i];
                let pathPartToCheck = pathPart;
                let arrayIndex = null;
        
                if (pathPart.endsWith("]")) {
                    const indexOfStart = pathPart.indexOf("[");
                    const indexOfEnd = pathPart.indexOf("]");
                    arrayIndex = pathPart.substring(indexOfStart + 1, indexOfEnd);
                    pathPartToCheck = pathPart.substring(0, indexOfStart);
                }
        
                resultField = actualFields.find(i => i.FieldName === pathPartToCheck);
                if (i < pathParts.length - 1) {
                    if (arrayIndex !== null) {
                        actualFields = resultField.value[arrayIndex];
                    }
                    else {
                        actualFields = resultField.value;
                    }
                }
            }
        
            return resultField;
        }`;
}