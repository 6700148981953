import State from "@Toolkit/ReactClient/Common/StateManaging";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import AllergyIntoleranceReactionId from "@Primitives/AllergyIntoleranceReactionId.g";

export default class AllergyIntoleranceReactionAssignment {
    @State.observable.ref public allergyIntoleranceReactionId: IEntityVersionSelector<AllergyIntoleranceReactionId>;
    @State.observable.ref public description: string;

    constructor(allergyIntoleranceReactionId: IEntityVersionSelector<AllergyIntoleranceReactionId>, description: string) {
        this.allergyIntoleranceReactionId = allergyIntoleranceReactionId;
        this.description = description;
    }

    @State.action.bound
    public setAllergyIntoleranceReactionId(newValue: IEntityVersionSelector<AllergyIntoleranceReactionId>) {
        this.allergyIntoleranceReactionId = newValue;
    }

    @State.action.bound
    public setDescription(newValue: string) {
        this.description = newValue;
    }
}
