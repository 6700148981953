import { Container } from "@DiContainer";
import { GuidanceClient, IGuidanceClient } from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import GuidanceApiAdapter from "@HisPlatform/Packages/Guidance/ApiAdapter/GuidanceApiAdapter";

class GuidancePackageInitializer implements IPackageInitializer {
    public configureDependencies(container: Container): void {
        container.bind("IGuidanceClient").to(GuidanceClient).inSingletonScope();
        container.bind("GuidanceApiAdapter").to(GuidanceApiAdapter).inSingletonScope();
    }
}

export default new GuidancePackageInitializer();