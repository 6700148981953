import React from "react";
import * as Ui from "@CommonControls";
import MedicationEquipmentListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationEquipmentListItem";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import EquipmentPositionSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/EquipmentPositionSelectBox/EquipmentPositionSelectBox";
import EMedicationDosageSelector from "./EMedicationDosageSelector";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicationVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/MedicationVersion";
import IMedicationSubsidyClaimType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IMedicationSubsidyClaimType";
import MedicationPricingAndSubsidies from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationPricingAndSubsidies";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import MedicationEquipmentList from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationEquipmentList";

interface IMedicationEquipmentItemDetailProps {
    equipmentList: MedicationEquipmentList;
    item: MedicationEquipmentListItem;
    medication: MedicationVersion;
    pricingAndSubsidies: MedicationPricingAndSubsidies;
    selectedClaimType: IMedicationSubsidyClaimType;
    index: number;
    isRepair: boolean;
    isClaimTypeTemporaryPermission: boolean;
}

@State.observer
export default class MedicationEquipmentItemDetail extends React.Component<IMedicationEquipmentItemDetailProps> {

    @State.computed
    private get selectedSubsidy() {
        if (!this.props.selectedClaimType || !this.props.pricingAndSubsidies) {
            return null;
        }
        return this.props.pricingAndSubsidies?.subsidies?.find(i => ValueWrapper.equals(i.pricingType, this.props.selectedClaimType?.medicationSubsidizedPricingTypeId));
    }

    @State.computed
    private get isInsubtitutableLocked() {
        return this.props.medication?.uniqueSizing === true || this.props.isClaimTypeTemporaryPermission === true || (this.props.equipmentList.countersignMandatory === true && this.props.medication?.isLint === false);
    }

    public render() {
        const resources = StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail;
        return (
            <>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.TextBox
                            value={this.props.medication?.name}
                            automationId={`items[${this.props.index}].medicationName`}
                            propertyIdentifier="MedicationId"
                            forceShowValidationResults
                            showMultipleValidationProblems
                            isReadOnly
                            disabled
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3} xsOffset={3}>
                        <Ui.CheckBox
                            value={this.props.item.isInsubstitutable}
                            onChange={this.props.item.setIsInsubstitutable}
                            automationId={`items[${this.props.index}].isInsubstitutableCheckbox`}
                            label={resources.Labels.IsSubstitubable}
                            disabled={this.isInsubtitutableLocked}
                            propertyIdentifier="IsInsubstitutable"
                            forceShowValidationsResults
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                {this.props.item.isInsubstitutable && <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        <Ui.TextBox
                            value={this.props.item.insubstitutableReason}
                            onChange={this.props.item.setInsubstitutableReason}
                            automationId={`items[${this.props.index}].insubstitutableReasonTextbox`}
                            label={resources.Labels.InsubstitutableReason}
                            propertyIdentifier="InsubstitutableReason"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>}
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <Ui.NumberBox
                            value={this.props.item.amount}
                            onChange={this.props.item.setAmount}
                            automationId={`items[${this.props.index}].amountNumberbox`}
                            label={resources.Labels.Amount}
                            integerOnly
                            propertyIdentifier="Amount"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <Ui.TextBox
                            value={this.props.medication?.textAmount}
                            automationId={`items[${this.props.index}].textAmountTextbox`}
                            label={resources.Labels.TextAmount}
                            isReadOnly
                            disabled
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        {!this.props.isClaimTypeTemporaryPermission &&
                            <Ui.TextBox
                                isReadOnly
                                disabled
                                value={this.selectedSubsidy?.supportPeriodMonths?.toString()}
                                automationId={`items[${this.props.index}].supportPeriodMonthsTextbox`}
                                label={resources.Labels.SupportPeriodMonths}
                                propertyIdentifier="SupportPeriodMonths"
                            />
                        }
                        {this.props.isClaimTypeTemporaryPermission &&
                            <Ui.NumberBox
                                value={this.props.item.supportPeriodMonths}
                                onChange={this.props.item.setSupportPeriodMonths}
                                automationId={`items[${this.props.index}].supportPeriodMonthsTextbox`}
                                label={resources.Labels.SupportPeriodMonths}
                                propertyIdentifier="SupportPeriodMonths"
                                integerOnly
                            />
                        }
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        <Ui.TextBox
                            value={this.props.item.amountReason}
                            onChange={this.props.item.setAmountReason}
                            automationId={`items[${this.props.index}].amountReasonTextbox`}
                            label={resources.Labels.AmountReason}
                            propertyIdentifier="AmountReason"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <EquipmentPositionSelectBox
                            value={this.props.item.equipmentPositionId}
                            onChange={this.props.item.setEquipmentPositionId}
                            label={resources.Labels.EquipmentPosition}
                            automationId={`items[${this.props.index}].equipmentPositionSelectBox`}
                            propertyIdentifier="EquipmentPositionId"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <Ui.TextBox
                            value={this.props.item.bodyPart}
                            onChange={this.props.item.setBodyPart}
                            automationId={`items[${this.props.index}].bodyPartTextbox`}
                            label={resources.Labels.BodyPart}
                            propertyIdentifier="Bodypart"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <Ui.TextBox
                            value={this.props.item.size}
                            onChange={this.props.item.setSize}
                            automationId={`items[${this.props.index}].sizeTextbox`}
                            label={resources.Labels.Size}
                            propertyIdentifier="Size"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <EMedicationDosageSelector
                    value={this.props.item.dosage}
                    onChange={this.props.item.setDosage}
                    automationIdPrefix={`items[${this.props.index}]`}
                />
                {this.props.isRepair && (
                    <Ui.GroupBox title={resources.GroupTitles.RepairData}>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={4}>
                                <Ui.TextBox
                                    value={this.props.item.serialNumber}
                                    onChange={this.props.item.setSerialNumber}
                                    automationId={`items[${this.props.index}].serialNumberTextbox`}
                                    label={resources.Labels.SerialNumber}
                                    propertyIdentifier="SerialNumber"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={12}>
                                <Ui.TextBox
                                    value={this.props.item.faultDescription}
                                    onChange={this.props.item.setFaultDescription}
                                    automationId={`items[${this.props.index}].faultDescriptionTextbox`}
                                    label={resources.Labels.FaultDescription}
                                    propertyIdentifier="FaultDescription"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.GroupBox>
                )}
                <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        <Ui.TextBox
                            value={this.props.item.instruction}
                            onChange={this.props.item.setInstruction}
                            automationId={`items[${this.props.index}].instructionTextbox`}
                            label={resources.Labels.Instruction}
                            multiline
                            propertyIdentifier="Instruction"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        <Ui.TextBox
                            value={this.props.item.instructionForDispenser}
                            onChange={this.props.item.setInstructionForDispenser}
                            automationId={`items[${this.props.index}].instructionForDispenserTextbox`}
                            label={resources.Labels.InstructionForDispenser}
                            multiline
                            propertyIdentifier="InstructionForDispenser"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </>
        );
    }
}