exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PageBoxContainer_single-layout_397RD {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  padding: 8px;\n  overflow: hidden;\n}\n.PageBoxContainer_single-layout_397RD .PageBoxContainer_page-box-container_3lF5m {\n  flex-basis: 1200px;\n  max-width: 1200px;\n}\n", ""]);

// exports
exports.locals = {
	"single-layout": "PageBoxContainer_single-layout_397RD",
	"singleLayout": "PageBoxContainer_single-layout_397RD",
	"page-box-container": "PageBoxContainer_page-box-container_3lF5m",
	"pageBoxContainer": "PageBoxContainer_page-box-container_3lF5m"
};