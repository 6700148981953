import React, { useMemo, useCallback } from "react";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import FormLayoutDataElementEditorStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutDataElementEditorStore";
import { useFormPanelStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanelStoreProvider";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import ReferencedEntityFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEntityFormDataElement";
import { useFormLayoutContext } from "@Toolkit/FormEngine/Panels/FormLayoutContext";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { findParentDataElementCollectionFromArray } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/DataElementHelpers";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";

import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import ReferencedEntityArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityArrayFormFieldData";
import ReferencedExtensibleEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedExtensibleEnumFormDataElement";
import FormSchemaReference from "@Toolkit/FormEngine/Model/Schema/FormSchemaReference";

interface IFormLayoutDataElementEditor {
    editor: FormLayoutDataElementEditorStore;
    schemaReference: FormSchemaReference;
}

function FormLayoutDataElementEditor(props: IFormLayoutDataElementEditor) {

    const store = useFormPanelStore();
    const layoutContext = useFormLayoutContext();
    const definition = store.definition.get();

    const compositeDataReferences = isNullOrUndefined(layoutContext?.compositeDataReferences) ? null : layoutContext?.compositeDataReferences;
    const compositeDataReference = isNullOrUndefined(compositeDataReferences)
        ? props.editor.dataReference
        : compositeDataReferences.join(".") + "." + props.editor.dataReference;

    const dataElement = useMemo(() => {
        const parentDataElementCollection =
            findParentDataElementCollectionFromArray(definition.rootSchema.getVersionOrNull(props.schemaReference.versionNumber)?.dataElements, compositeDataReferences);
        return parentDataElementCollection.find(de => de.name === props.editor.dataReference);
    }, [definition]);

    const listPanelItemEditor = useCallback((_, index) => {
        const field = getField(store.props.form.data.Content, compositeDataReference);

        return (
            <SingleFormLayoutDataElementEditor
                label={props.editor.multiLingualLabel.getWithCurrentCultureCodeOrWithDefaultCultureCode(store.cultureCodeProvider.cultureCode)}
                propertyIdentifier="Value"
                dataElement={dataElement}
                editorComponent={editorComponent}
                editor={props.editor}
                value={(field as any).value[index]}
                onChange={State.action((newValue) => {
                    (field as any).value[index] = newValue;
                })}
                isReadOnly={field.isReadOnly}
                filters={field instanceof ReferencedEntityFormFieldData || field instanceof ReferencedEntityArrayFormFieldData ? field.filters : null} />
        );
    }, [props.editor, definition, dataElement]);

    if (!props.editor) {
        return null;
    }

    const editorComponent = store.formEditorRegistry.tryGetEditorComponent(props.editor.editorType);

    if (!editorComponent) {
        return <><b>{props.editor.multiLingualLabel.getWithCurrentCultureCodeOrWithDefaultCultureCode(store.cultureCodeProvider.cultureCode)}:</b> Missing editor type: {props.editor.editorType}</>;
    }

    const createNewItem = useCallback(() => {
        store.props.onChange(compositeDataReference, null, "add");
        return Promise.resolve(null);
    }, []);

    const field = getField(store.props.form.data.Content, compositeDataReference);

    const onRemoveItem = useCallback((item: any) => {
        State.runInAction(() => {
            ((field as any).value as unknown as IObservableArray<any>).remove(item);
        });
        store.props.onChange(compositeDataReference, null, "remove");
    }, []);

    if (dataElement.isArray && !(dataElement instanceof ReferencedEntityFormDataElement || dataElement instanceof ReferencedExtensibleEnumFormDataElement)) {
        return (
            <ListPanel
                onCreateNewAsync={createNewItem}
                renderItemEditor={listPanelItemEditor}
                actionButtonsOrientation="horizontal"
                items={(field as any).value as unknown as IObservableArray<any>}
                onDeleteItemConfirmationAsync={() => Promise.resolve(true)}
                noItemsMessage=""
                alwaysEdit
                propertyIdentifier={dataElement.name}
                isCompactEmptyState
                allowNullItem
                onRemoveItem={onRemoveItem}
            />
        );
    }

    const localOnChange = useCallback(State.action((newValue: any) => {
        (field as any).value = newValue;
    }), [field]);

    return (
        <SingleFormLayoutDataElementEditor
            label={props.editor.multiLingualLabel.getWithCurrentCultureCodeOrWithDefaultCultureCode(store.cultureCodeProvider.cultureCode)}
            propertyIdentifier={props.editor.dataReference}
            dataElement={dataElement}
            editorComponent={editorComponent}
            editor={props.editor}
            value={(field as any).value}
            onChange={localOnChange}
            isReadOnly={field.isReadOnly}
            filters={field instanceof ReferencedEntityFormFieldData || field instanceof ReferencedEntityArrayFormFieldData ? field.filters : null}
            definitionId={definition.id}
        />
    );
}

const SingleFormLayoutDataElementEditor = State.observer(props => {
    const store = useFormPanelStore();
    const layoutContext = useFormLayoutContext();
    const fullPropertyIdentifier = useMemo(
        () => layoutContext?.compositeDataReferences ? [...layoutContext.compositeDataReferences, props.propertyIdentifier].join(".") : props.propertyIdentifier, 
        [layoutContext?.compositeDataReferences, props.propertyIdentifier]);

    const onChange = useCallback((value: any) => {
        props.onChange(value);
        store.props.onChange?.(fullPropertyIdentifier, value, "set");
    }, [props.onChange, store.props.onChange, fullPropertyIdentifier]);

    return React.createElement(props.editorComponent.componentType, {
        ...props.editorComponent.props,
        label: props.label,
        value: props.value,
        onChange: onChange,
        automationId: props.editor.dataReference,
        propertyIdentifier: props.propertyIdentifier,
        dataElement: props.dataElement,
        isReadOnly: props.isReadOnly,
        filters: props.filters,
        definitionId: props.definitionId
    });
});

export default State.observer(FormLayoutDataElementEditor);