import React from "react";
import Styles from "./Layout.less";
import ViewContextAdapter from "@Toolkit/ReactClient/Components/ViewContext/ViewContextAdapter";
import IToolkitViewContextSettings from "@Toolkit/ReactClient/Components/ViewContext/IToolkitViewContextSettings";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";

interface ISingleLayoutProps {
    _totalHeightLoss?: number;
    children?: React.ReactNode;
    style?: React.CSSProperties;
}


const SingleLayout: React.FC<ISingleLayoutProps> = props => (
    <div className={Styles.singleLayout} style={{ height: `calc(100vh - ${props._totalHeightLoss}px)`, ...props.style }}>
        <div className={Styles.pageBoxContainer}>
            {props.children}
        </div>
    </div>
);

export default connect(
    SingleLayout,
    new ViewContextAdapter<ISingleLayoutProps, IToolkitViewContextSettings>((props, ctx) => ({
        _totalHeightLoss: ctx.totalHeightLoss
    }))
);