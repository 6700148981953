import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Reporting/Api/Proxy.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import {CreateRequestId} from "@HisPlatform/Common/RequestHelper";
import {createOperationInfo} from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ConditionId from "@Primitives/ConditionId.g";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import Di from "@Di";
import PractitionerId from "@Primitives/PractitionerId.g";

@Di.injectable()
export default class StatisticApiAdapter extends ApiAdapterBase {
    constructor(@Di.inject("IStatisticClient") private readonly apiClient: Proxy.IStatisticClient) {
        super();
    }

    @State.bound
    public getMostUsedConditionIds(pointOfCareId: PointOfCareId, count: number) {
        return this.processOperationAsync(
            new SimpleStore<ConditionId[]>(),
            async target => {
                const response = await this.apiClient.getMostUsedConditionIdsQueryAsync(CreateRequestId(), count.toString(), pointOfCareId.value);

                target.operationInfo = createOperationInfo(response);
                target.value = response.idList;
            }
        );
    }

    @State.bound
    public getMostUsedExternalLocationIds(pointOfCareId: PointOfCareId, count: number) {
        return this.processOperationAsync(
            new SimpleStore<ExternalLocationId[]>(),
            async target => {
                const response = await this.apiClient.getMostUsedExternalLocationIdsQueryAsync(CreateRequestId(), count.toString(), pointOfCareId.value);

                target.operationInfo = createOperationInfo(response);
                target.value = response.idList;
            }
        );
    }

    @State.bound
    public getMostUsedMedicalServiceIds(pointOfCareId: PointOfCareId, count: number) {
        return this.processOperationAsync(
            new SimpleStore<MedicalServiceId[]>(),
            async target => {
                const response = await this.apiClient.getMostUsedMedicalServiceIdsQueryAsync(CreateRequestId(), count.toString(), pointOfCareId.value);

                target.operationInfo = createOperationInfo(response);
                target.value = response.idList;
            }
        );
    }

    @State.bound
    public getMostUsedPractitionerIds(pointOfCareId: PointOfCareId, count: number) {
        return this.processOperationAsync(
            new SimpleStore<PractitionerId[]>(),
            async target => {
                const response = await this.apiClient.getMostUsedPractitionerIdsQueryAsync(CreateRequestId(), count.toString(), pointOfCareId.value);

                target.operationInfo = createOperationInfo(response);
                target.value = response.idList;
            }
        );
    }
}
