import DependencyContainer from "@DiContainer";
import * as CareProxy from "@HunSocialSecurityPlugin/BoundedContexts/Care/Api/Proxy.g";
import * as HunCareProxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import HunMedicationRequestValidationParameterMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/HunMedicationRequestValidationParameterMapper";
import HunCareContagiousPatientReportApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ContagiousPatientReport/HunCareContagiousPatientReportApiAdapter";
import HunCareContagiousPatientReportStoreMapper from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ContagiousPatientReport/HunCareContagiousPatientReportStoreMapper";
import HunCareApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/HunCareApiAdapter";
import HunCareDiagnosisListValidationParameterMapper from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/HunCareDiagnosisListValidationParameterMapper";
import HunCarePerformedServiceListValidationParameterMapper from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/HunCarePerformedServiceListValidationParameterMapper";
import { GetTypeNameWithPrefix } from "@HunSocialSecurityPlugin/PluginHelper";
import HunSocialSecurityMedicationRequestValidationParameterMapper from "@HunSocialSecurityPlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/HunSocialSecurityMedicationRequestValidationParameterMapper";

DependencyContainer
    .bind("HunCareApiAdapter")
    .to(HunCareApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind(GetTypeNameWithPrefix("ICareRegisterClient"))
    .to(CareProxy.CareRegisterClient)
    .inSingletonScope();

DependencyContainer
    .bind(GetTypeNameWithPrefix("IHunReferenceDataClient"))
    .to(HunCareProxy.HunReferenceDataClient)
    .inSingletonScope();

DependencyContainer
    .bind(GetTypeNameWithPrefix("IContagiousPatientReportClient"))
    .to(CareProxy.ContagiousPatientReportClient)
    .inSingletonScope();

DependencyContainer
    .bind("HunCareDiagnosisListValidationParameterMapper")
    .to(HunCareDiagnosisListValidationParameterMapper);

DependencyContainer
    .bind("HunCarePerformedServiceListValidationParameterMapper")
    .to(HunCarePerformedServiceListValidationParameterMapper);

DependencyContainer // TODO: Move to EHI
    .bind("HunMedicationRequestValidationParameterMapper")
    .to(HunMedicationRequestValidationParameterMapper);

DependencyContainer
    .bind("HunCareContagiousPatientReportApiAdapter")
    .to(HunCareContagiousPatientReportApiAdapter);

DependencyContainer
    .bind("HunCareContagiousPatientReportStoreMapper")
    .to(HunCareContagiousPatientReportStoreMapper);

DependencyContainer
    .bind("HunSocialSecurityMedicationRequestValidationParameterMapper")
    .to(HunSocialSecurityMedicationRequestValidationParameterMapper);
