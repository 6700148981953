import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import BinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/BinaryDocument";
import { getDocumentInfo } from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/DocumentStoreMapper";
import DocumentInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentInfo";
import DocumentAction from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentAction.g";

@Di.injectable()
export default class BinaryDocumentApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IDocumentsClient") private readonly apiClient: Proxy.IDocumentsClient
    ) {
        super();
    }

    public updateBinaryDocumentAsync(binaryDocument: BinaryDocument, releaseLockIfSuccessful: boolean, documentAction: DocumentAction) {
        return this.processOperationAsync(
            new SimpleStore<DocumentInfo>(),
            async target => {
                const response = await this.apiClient.updateBinaryDocumentCommandAsync(
                    CreateRequestId(),
                    new Proxy.UpdateBinaryDocumentControllerDto({
                        documentId: binaryDocument.id,
                        lockId: binaryDocument.lockInfo?.lockId,
                        rowVersion: binaryDocument.rowVersion,
                        releaseLockIfSuccessful: releaseLockIfSuccessful,
                        documentAction: documentAction
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = getDocumentInfo(response.documentInfo);
            }
        );
    }
}