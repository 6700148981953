import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import IServiceRequestReferralExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestReferralExtensionPointProps";
import EhiEReferralExtensionPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/EhiEReferralExtension/EhiEReferralExtensionPanel";
import { IsCovidDataNeeded } from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/EhiEReferralExtension/EhiEReferralExtensionHelper";

@State.observer
export default class EhiEReferralExtension extends React.Component<IServiceRequestReferralExtensionPointProps> {
    
    @State.computed
    private get isCovidDataNeeded() {
        const code = this.props.serviceRequestDefinition?.code.value;
        return IsCovidDataNeeded(this.props.requestingPointOfCareDynamicProperties, code);
    }

    public render() {
        return <EhiEReferralExtensionPanel {...this.props} isCovidDataNeeded={this.isCovidDataNeeded} />;
    }
}
