import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { MedicalServiceCodeSelector } from "@HisPlatformControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import RequestedServicesFilterStore from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/CareActivityWorklist/Filters/RequestedServicesFilterStore";
import { isNullOrUndefined, emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import CareTypeId from "@Primitives/CareTypeId.g";

interface IRequestedServicesFilterDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    localizationService: ILocalizationService;
}

interface IRequestedServicesFilterProps {
    _dependencies?: IRequestedServicesFilterDependencies;
    filterStore: RequestedServicesFilterStore;
}

@State.observer
class RequestedServicesFilter extends React.Component<IRequestedServicesFilterProps> {
    private get allIds() {
        return this.props._dependencies.careReferenceDataStore.medicalService
            && this.props._dependencies.careReferenceDataStore.medicalService.getValidItems().map(i => new EntityVersionSelector<MedicalServiceId>(i.id, LocalDate.today()));
    }

    private get items() {
        if (isNullOrUndefined(this.props.filterStore)) {
            return emptyArray;
        }
        return this.props.filterStore.medicalServiceIds.map(item => {
            return {
                id: item,
                validOn: LocalDate.today()
            } as IEntityVersionSelector<MedicalServiceId>;
        });
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.bound
    private async loadAsync() {
        await this.props._dependencies.careReferenceDataStore.medicalService.ensureAllLoadedAsync();
    }

    public render() {
        return (
            <MedicalServiceCodeSelector
                label={StaticCareResources.ServiceRequestManagement.RequestedServices.MedicalService}
                value={this.items}
                onChange={this.props.filterStore?.setMedicalServiceIds}
                multiSelect={true}
                useAlternativeName
                style={{marginBottom: "5px"}}
                automationId="medicalServiceCodeSelector"
                careTypeId={CareTypeId.Outpatient}
            />
        );
    }
}

export default connect(
    RequestedServicesFilter,
    new DependencyAdapter<IRequestedServicesFilterProps, IRequestedServicesFilterDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            localizationService: container.resolve("ILocalizationService")
        };
    })
);
