import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import {ScreenStateContextAdapter, ScreenStateContextStore} from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import React from "react";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import {Flex} from "@CommonControls";
import ActionBoundButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundButton";
import {HisErrorBoundary} from "@HisPlatformControls";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import ShowCombinePatientsScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowCombinePatientsScreenAction.g";
import { provideCombinePatientsScreenStore, useCombinePatientsScreenStore } from "./CombinePatientsScreenStoreProvider";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import PatientSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/PatientRegister/PatientSelectBox/PatientSelectBox";
import CombinePatientsListItem from "./CombinePatientsListItem";
import {HisActionDispatcherAdapter} from "@HisPlatform/Common/FrontendActions/HisActionDispatcher";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";

export interface ICombinePatientsScreenProps extends IScreenPropsBase<ShowCombinePatientsScreenAction> {
    _screenState?: ScreenStateContextStore;
    _actionDispatcher?: IActionDispatcher;
}

function CombinePatientsScreen(props: ICombinePatientsScreenProps) {
    const store = useCombinePatientsScreenStore();

    const listItemNames = StaticCareResources.PatientRegister.CombinePatientsScreen.ListItemNames;

    return (
        <HisErrorBoundary>
            <LoadingBoundary>
                <Screen
                    title={StaticCareResources.PatientRegister.CombinePatientsScreen.Title}
                    toolbar={<ActionBoundButton
                        actionDescriptor={store.combinePatientsAction}
                        onBeforeExecuteAsync={store.onCombineAsync}
                        automationId={"combinePatientsButton"}
                        disabled={!store.patientToKeepId || !store.patientToDeleteId}
                        text={StaticCareResources.PatientRegister.CombinePatientsScreen.Buttons.CombinePatients}
                    />}
                >
                    <BusinessErrorHandler.Register businessErrorName="InvalidPatientError" handler={store.handleInvalidPatientError} />
                    <BusinessErrorHandler.Register businessErrorName="OverlappedInPatientCareActivitiesError" handler={store.handleOverlappedInPatientCareActivitiesError} />
                    <BusinessErrorHandler.Register businessErrorName="PatientToBeKeptCannotBeSameAsPatientToBeDeletedError" handler={store.handlePatientToBeKeptCannotBeTheSameAsPatientToBeDeletedError} />
                    <Flex>
                        <Flex.Item xs={3} />
                        <Flex.Item xs={3}>
                            <PatientSelectBox
                                value={store.patientToKeepId}
                                onChange={store.setPatientToKeepId}
                                modalTitle={StaticCareResources.PatientRegister.CombinePatientsScreen.PatientToKeepModalTitle}
                                label={StaticCareResources.PatientRegister.CombinePatientsScreen.Labels.PatientToKeep}
                                automationId={"patientToKeepSelectBox"}
                            />
                        </Flex.Item>
                        <Flex.Item xs={3}>
                            <PatientSelectBox
                                value={store.patientToDeleteId}
                                onChange={store.setPatientToDeleteId}
                                modalTitle={StaticCareResources.PatientRegister.CombinePatientsScreen.PatientToDeleteModalTitle}
                                label={StaticCareResources.PatientRegister.CombinePatientsScreen.Labels.PatientToDelete}
                                automationId={"patientToDeleteSelectBox"}
                            />
                        </Flex.Item>
                        <Flex.Item xs={3} />
                    </Flex>
                    <Flex>
                        <Flex.Item xs={3}>
                            {StaticCareResources.PatientRegister.CombinePatientsScreen.Header.PatientDataName}
                        </Flex.Item>
                        <Flex.Item xs={3} horizontalContentAlignment={"center"}>
                            {StaticCareResources.PatientRegister.CombinePatientsScreen.Header.PatientToKeepData}
                        </Flex.Item>
                        <Flex.Item xs={3} horizontalContentAlignment={"center"}>
                            {StaticCareResources.PatientRegister.CombinePatientsScreen.Header.PatientToDeleteData}
                        </Flex.Item>
                    </Flex>
                    <hr />
                    <CombinePatientsListItem
                        listItemName={listItemNames.PatientIdentifier}
                        patientToKeepData={store.patientToKeep?.identifier}
                        patientToDeleteData={store.patientToDelete?.identifier}
                    />
                    <CombinePatientsListItem
                        listItemName={listItemNames.Prefix}
                        patientToKeepData={store.patientToKeep?.baseData?.name?.prefix}
                        patientToDeleteData={store.patientToDelete?.baseData?.name?.prefix}
                    />
                    <CombinePatientsListItem
                        listItemName={listItemNames.FamilyName}
                        patientToKeepData={store.patientToKeep?.baseData?.name?.familyName}
                        patientToDeleteData={store.patientToDelete?.baseData?.name?.familyName}
                    />
                    <CombinePatientsListItem
                        listItemName={listItemNames.GivenName1}
                        patientToKeepData={store.patientToKeep?.baseData?.name?.givenName1}
                        patientToDeleteData={store.patientToDelete?.baseData?.name?.givenName1}
                    />
                    <CombinePatientsListItem
                        listItemName={listItemNames.GivenName2}
                        patientToKeepData={store.patientToKeep?.baseData?.name?.givenName2}
                        patientToDeleteData={store.patientToDelete?.baseData?.name?.givenName2}
                    />
                    <CombinePatientsListItem
                        listItemName={listItemNames.MothersFamilyName}
                        patientToKeepData={store.patientToKeep?.baseData?.mothersName?.familyName}
                        patientToDeleteData={store.patientToDelete?.baseData?.mothersName?.familyName}
                    />
                    <CombinePatientsListItem
                        listItemName={listItemNames.MothersGivenName}
                        patientToKeepData={store.patientToKeep?.baseData?.mothersName?.givenName1}
                        patientToDeleteData={store.patientToDelete?.baseData?.mothersName?.givenName1}
                    />
                    <CombinePatientsListItem
                        listItemName={listItemNames.Citizenship}
                        patientToKeepData={store.patientToKeepCitizenship}
                        patientToDeleteData={store.patientToDeleteCitizenship}
                    />
                    <CombinePatientsListItem
                        listItemName={listItemNames.PermanentAddressCountry}
                        patientToKeepData={store.patientToKeepPermanentAddressCountry}
                        patientToDeleteData={store.patientToDeletePermanentAddressCountry}
                    />
                    <CombinePatientsListItem
                        listItemName={listItemNames.PermanentAddressZipCode}
                        patientToKeepData={store.patientToKeepPermanentAddress?.zipCode}
                        patientToDeleteData={store.patientToDeletePermanentAddress?.zipCode}
                    />
                    <CombinePatientsListItem
                        listItemName={listItemNames.PermanentAddressSettlement}
                        patientToKeepData={store.patientToKeepPermanentAddress?.settlement}
                        patientToDeleteData={store.patientToDeletePermanentAddress?.settlement}
                    />
                    <CombinePatientsListItem
                        listItemName={listItemNames.PermanentAddressAddressLine}
                        patientToKeepData={store.patientToKeepPermanentAddress?.addressLine}
                        patientToDeleteData={store.patientToDeletePermanentAddress?.addressLine}
                    />
                    <CombinePatientsListItem
                        listItemName={listItemNames.Documents}
                        patientToKeepData={store.patientToKeepDocuments}
                        patientToDeleteData={store.patientToDeleteDocuments}
                    />
                    <CombinePatientsListItem
                        listItemName={listItemNames.Insurances}
                        patientToKeepData={store.patientToKeepInsurers}
                        patientToDeleteData={store.patientToDeleteInsurers}
                    />
                    <CombinePatientsListItem
                        listItemName={listItemNames.PrimaryPhoneContact}
                        patientToKeepData={store.patientToKeepPrimaryPhone}
                        patientToDeleteData={store.patientToDeletePrimaryPhone}
                    />
                    <CombinePatientsListItem
                        listItemName={listItemNames.PrimaryEmailContact}
                        patientToKeepData={store.patientToKeepPrimaryEmail}
                        patientToDeleteData={store.patientToDeletePrimaryEmail}
                    />
                </Screen>
            </LoadingBoundary>
        </HisErrorBoundary>
    );
}

export default connect<ICombinePatientsScreenProps>(
    provideCombinePatientsScreenStore(CombinePatientsScreen),
    new ScreenStateContextAdapter(),
    new HisActionDispatcherAdapter()
);