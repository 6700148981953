import React, { useMemo, useCallback } from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IWorklistDefinitionRouteParams from "@HisPlatform/Application/Routes/IWorklistDefinitionRouteParams";
import WorklistDefinitionMasterDetailPanel from "@HisPlatform/BoundedContexts/Productivity/Components/Panels/WorklistDefinitionMasterDetailPanel/WorklistDefinitionMasterDetailPanel";
import WorklistDefinitionId from "@Primitives/WorklistDefinitionId.g";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

export default function WorklistDefinitionEditorPage(props: IRoutingFrameContentProps<IWorklistDefinitionRouteParams>) {

    const selectedId = useMemo(() => {
        const routeParameters = props.routingController.currentRoute.parameters;
        return isNullOrUndefined(routeParameters.selectedId) ? null : new WorklistDefinitionId(routeParameters.selectedId);
    }, [props.routingController.currentRoute.parameters.selectedId]);

    const setSelectedId = useCallback((id: WorklistDefinitionId | null) => {
        props.routingController.replace(props.routingController.currentRoute.definition.makeRoute({
            selectedId: id?.value ?? undefined
        }));
    }, [props.routingController]);

    return (
        <LoadingBoundary>
            <WorklistDefinitionMasterDetailPanel
                selectedId={selectedId}
                onSelectedIdChange={setSelectedId}
            />
        </LoadingBoundary>
    );
    
}