import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import IWorklistRouteParams from "@HisPlatform/Application/Routes/IWorklistRouteParams";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import CareActivityWorklistPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/CareActivityWorklist/CareActivityWorklistPanel";
import CareActivityId from "@Primitives/CareActivityId.g";
import { CantCloseCareActivityDialogNavigateTo } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CantCloseCareActivityPanel/CantCloseCareActivityDialogParams";
import StateChangeBlockingData from "@Primitives/StateChangeBlockingData.g";
import IStateChangeBlockingRegistry from "@PluginInterface/BoundedContexts/Care/CareRegister/StateChange/IStateChangeBlockingRegistry";
import { RowId } from "@CommonControls/DataGrid/IDataGridProps";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { getUseCaseAsUrlParam, parseUseCaseStateFromUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import { ActionDispatcherAdapter } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { ScreenNavigationContextAdapter, ScreenNavigationContextStore } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import { ICareActivityWorklistCommonService } from "@HisPlatform/Services/Definition/CareActivityWorklistCommonService/ICareActivityWorklistCommonService";
import WorklistDefinitionReferenceDataStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/WorklistDefinition/WorklistDefinitionReferenceDataStore";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

interface IWorklistPageDependencies {
    userContext: UserContext;
    stateChangeBlockingRegistry: IStateChangeBlockingRegistry;
    useCaseRegistry: IUseCaseRegistry;
    careActivityWorklistCommonService: ICareActivityWorklistCommonService;
    worklistDefinitionReferenceDataStore: WorklistDefinitionReferenceDataStore;
}

interface IWorklistPageProps extends IRoutingFrameContentProps<IWorklistRouteParams> {
    _dependencies?: IWorklistPageDependencies;
    _modalService?: IModalService;
    _actionDispatcher?: IActionDispatcher;
    _screenNavigationContext?: ScreenNavigationContextStore;
}

@State.observer
class WorklistPage extends React.Component<IWorklistPageProps> {

    private get useCaseRegistry() { return this.props._dependencies.useCaseRegistry; }
    private get routingController(): IRoutingController<IWorklistRouteParams> { return this.props.routingController; }

    @State.computed private get useCase() {
        return parseUseCaseStateFromUrlParam(this.props.routingController.currentRoute.parameters.useCase);
    }

    @State.computed private get selectedRowId() {
        return this.routingController.currentRoute.parameters.selectedRowId;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.props._dependencies.worklistDefinitionReferenceDataStore.initializeAsync(), this);
    }

    public render() {

        if (!this.props._dependencies.worklistDefinitionReferenceDataStore.isLoaded || !this.props.routingController.currentRoute.parameters.worklistDefinitionId) {
            return null;
        }

        return (
            <LoadingBoundary>
                <CareActivityWorklistPanel
                    worklistLocalId={this.props.routingController.currentRoute.parameters.worklistDefinitionId}
                    selectedRowId={this.selectedRowId}
                    useCase={this.useCase}
                    onUseCaseChange={this.setUseCase}
                    navigateByAction={this.navigateByStateChangeBlockingData}
                    cantCloseCareActivityNavigateByAction={this.cantCloseCareActivityNavigateByActions}
                />
            </LoadingBoundary>
        );
    }

    @State.bound
    private setUseCase(rowId: RowId, useCaseState: INDataUseCaseState) {
        this.props._dependencies.careActivityWorklistCommonService.setUseCase(rowId, useCaseState,
            this.props.routingController,
            this.props._actionDispatcher,
            this.props._screenNavigationContext,
            this.props._modalService);
    }

    @State.bound
    private navigateByStateChangeBlockingData(careActivityId: CareActivityId, navigateTo: StateChangeBlockingData) {
        this.props._dependencies.stateChangeBlockingRegistry.navigateTo(this.routingController, null, navigateTo, careActivityId);
    }

    @State.bound
    private cantCloseCareActivityNavigateByActions(careActivityId: CareActivityId, navigateTo: CantCloseCareActivityDialogNavigateTo) {
        this.props._dependencies.careActivityWorklistCommonService.cantCloseCareActivityNavigateByActions(
            careActivityId,
            navigateTo,
            this.props.routingController,
            this.props._actionDispatcher,
            this.props._screenNavigationContext,
            this.props._modalService);
    }
}

export default connect(
    WorklistPage,
    new DependencyAdapter<IWorklistPageProps, IWorklistPageDependencies>(c => ({
        userContext: c.resolve("UserContext"),
        stateChangeBlockingRegistry: c.resolve("IStateChangeBlockingRegistry"),
        useCaseRegistry: c.resolve("IUseCaseRegistry"),
        careActivityWorklistCommonService: c.resolve("ICareActivityWorklistCommonService"),
        worklistDefinitionReferenceDataStore: c.resolve("WorklistDefinitionReferenceDataStore"),
    })),
    new HisModalServiceAdapter(),
    new ActionDispatcherAdapter(),
    new ScreenNavigationContextAdapter()
);
