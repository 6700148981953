import PregnancyRiskFormDetailPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/PregnancyRiskFormDetailPanel/PregnancyRiskFormDetailPanel";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import standaloneRouteDefinitions from "@HunEHealthInfrastructurePlugin/UseCases/CareUseCase/CareUseCaseRoutes";
import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import getFormInstanceBasedUseCaseArguments from "@Toolkit/FormEngine/Configuration/FormUseCaseConfigurationHelper";
import PregnancyRiskFormCareActivityPage from "@HunEHealthInfrastructurePlugin/UseCases/CareUseCase/PregnancyRiskForm/PregnancyRiskFormCareActivityPage";

export default function configurePregnancyRiskFormUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add(UseCaseDescriptorBuilder.create(
        HunEHealthInfrastructureUseCases.pregnancyRiskForms,
        builder => {
            builder.standalone(b => b
                .routeDefinition(standaloneRouteDefinitions.pregnancyRiskForms)
                .component(PregnancyRiskFormCareActivityPage));
        }, false
    ));

    useCaseRegistry.add(UseCaseDescriptorBuilder.create(
        HunEHealthInfrastructureUseCases.readOnlyPregnancyRiskForms,
        builder => {
            builder.standalone(b => b
                .routeDefinition(standaloneRouteDefinitions.pregnancyRiskForms).additionalQueryParams({ isReadOnly: true })
                .component(PregnancyRiskFormCareActivityPage));
        }, false
    ));

    useCaseRegistry.add2({
        identifiers: [HunEHealthInfrastructureUseCases.editablePregnancyRiskFormDetail],
        component: PregnancyRiskFormDetailPanel,
        mapUseCaseArgumentsToProps: args => { 
            return getFormInstanceBasedUseCaseArguments(args, false);
        }
    });

    useCaseRegistry.add2({
        identifiers: [HunEHealthInfrastructureUseCases.readOnlyPregnancyRiskFormDetail],
        component: PregnancyRiskFormDetailPanel,
        mapUseCaseArgumentsToProps: args => { 
            return getFormInstanceBasedUseCaseArguments(args, true);
        }
    });
}