import React from "react";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import IDiagnosisExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IDiagnosisExtensionPointProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import OncologyPanelView from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/DiagnosisExtension/DiagnosisExtension/OncologyPanelView";
import OncologyDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/OncologyDataStore";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import DiagnosisStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisStore";
import CancerClassificationDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CancerClassificationDataStore";
import OncologyApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/OncologyApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import SaveButton from "@CommonControls/SaveButton";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import OncologyDataId from "@Primitives/OncologyDataId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";

interface IDiagnosisExtensionPanelDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    oncologyApiAdapter: OncologyApiAdapter;
    ehiCareReferenceDataStore: HunEhiCareReferenceDataStore;
}

interface IDiagnosisExtensionPanelProps extends IDiagnosisExtensionPointProps {
    _dependencies?: IDiagnosisExtensionPanelDependencies;
}

@State.observer
class DiagnosisExtensionPanel extends React.Component<IDiagnosisExtensionPanelProps> {

    @State.observable.ref private dataStore: OncologyDataStore;
    @State.observable.ref private cancerClassificationDataStore: CancerClassificationDataStore = new CancerClassificationDataStore();
    @State.observable.ref private filter: MedicalServiceId[] = [];
    private diagnosisStore: DiagnosisStore;
    private disposers: IDisposable[] = [];
    private get ehiCareReferenceDataStore() { return this.props._dependencies.ehiCareReferenceDataStore; }

    public componentDidMount() {
        this.setDataStore();
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IDiagnosisExtensionPanelProps) {
        if (this.props.diagnosisStore && this.props.diagnosisStore !== prevProps.diagnosisStore) {
            this.disposers.forEach(d => d.dispose());
            this.disposers = [];
            this.setDataStore();
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.action.bound
    private async loadAsync() {
        await this.reloadCancerClassificationDataStoreAsync();
        const response = await this.getOncologyRelevantMedicalServiceIdsAsync();
        State.runInAction(() => {
            this.filter = response;
        });
    }

    public componentWillUnmount() {
        this.disposers.forEach(d => d.dispose());
    }

    @State.bound
    private async reloadCancerClassificationDataStoreAsync() {
        if (!this.props.diagnosisStore?.conditionVersionSelector.id) {
            return;
        }

        const response = await this.props._dependencies.oncologyApiAdapter.getCancerClassificationIdByConditionAsync(this.props.diagnosisStore.conditionVersionSelector.id);
        await this.ehiCareReferenceDataStore.cancerClassifications.ensureLoadedAsync([response.value]);
        const cancerClassification = this.ehiCareReferenceDataStore.cancerClassifications.get(response.value);

        this.setCancerClassificationDataStore(cancerClassification);
    }

    @State.bound
    private async getOncologyRelevantMedicalServiceIdsAsync() {
        if (!this.props.careActivityId) {
            return null;
        }
        const response = await this.props._dependencies.oncologyApiAdapter.getOncologyRelevantMedicalServiceIdsAsync(this.props.careActivityId);
        return response.value;
    }

    @State.action.bound
    private setCancerClassificationDataStore(newValue: CancerClassificationDataStore) {
        this.cancerClassificationDataStore = newValue;
        this.dataStore.setCancerClassificationId(newValue.id);
        this.dataStore.takeSnapshot();
    }

    @State.computed
    public get tnmLabel() {
        if (!this.dataStore) {
            return "";
        }
        const tumor = this.dataStore.cancerClassificationTumorId && this.cancerClassificationDataStore.tumorList.find(item => wrappedValuesAreEquals(this.dataStore.cancerClassificationTumorId, item.id));
        const node = this.dataStore.cancerClassificationNodeId && this.cancerClassificationDataStore.nodeList.find(item => wrappedValuesAreEquals(this.dataStore.cancerClassificationNodeId, item.id));
        const metastasis = this.dataStore.cancerClassificationMetastasisId && this.cancerClassificationDataStore.metastasisList.find(item => wrappedValuesAreEquals(this.dataStore.cancerClassificationMetastasisId, item.id));
        return (tumor?.code || "") + (node?.code || "") + (metastasis?.code || "") || "n/a";
    }

    @State.action.bound
    private setDataStore() {
        if (!this.props.diagnosisStore?.extensionData) {
            this.props.initializeExtensionData();
        }
        this.diagnosisStore = this.props.diagnosisStore;
        this.dataStore = OncologyDataStore.createFromExtensionDto(this.diagnosisStore?.extensionData);
        this.dataStore.takeSnapshot();
        this.disposers.push({
            dispose: State.autorun(() => {
                if (this.diagnosisStore) {
                    this.diagnosisStore.extensionData = {
                        ...this.props.diagnosisStore.extensionData,
                        ["HunEHealthInfrastructure_OncologyData"]: {
                            Id: isNullOrUndefined(this.dataStore.id)
                                ? new OncologyDataId("-1").toJSON()
                                : this.dataStore.id.toJSON(),
                            OncologyRegisterExaminationModeId: isNullOrUndefined(this.dataStore.oncologyRegisterExaminationModeId)
                                ? null
                                : this.dataStore.oncologyRegisterExaminationModeId.toJSON(),
                            DateOfDiagnosis: isNullOrUndefined(this.dataStore.dateOfDiagnosis)
                                ? null
                                : this.dataStore.dateOfDiagnosis.toJSON(),
                            OncologyRegisterFurtherTreatmentId: isNullOrUndefined(this.dataStore.oncologyRegisterFurtherTreatmentId)
                                ? null
                                : this.dataStore.oncologyRegisterFurtherTreatmentId.toJSON(),
                            RelatedMedicalServices: isNullOrUndefined(this.dataStore.relatedMedicalServices)
                                ? []
                                : this.dataStore.relatedMedicalServices.map(item => item.toJSON()),
                            OncologyRegisterTumorStatusId: isNullOrUndefined(this.dataStore.oncologyRegisterTumorStatusId)
                                ? null
                                : this.dataStore.oncologyRegisterTumorStatusId.toJSON(),
                            StatusDate: isNullOrUndefined(this.dataStore.dateOfStatus)
                                ? null
                                : this.dataStore.dateOfStatus.toJSON(),
                            OncologyRegisterExtensionId: isNullOrUndefined(this.dataStore.oncologyRegisterExtensionId)
                                ? null
                                : this.dataStore.oncologyRegisterExtensionId.toJSON(),
                            OncologyRegisterLateralityId: isNullOrUndefined(this.dataStore.oncologyRegisterLateralityId)
                                ? null
                                : this.dataStore.oncologyRegisterLateralityId.toJSON(),
                            Morphology: isNullOrUndefined(this.dataStore.morphology)
                                ? null
                                : this.dataStore.morphology.toJSON(),
                            CancerClassificationData: {
                                CancerClassificationTumorId: isNullOrUndefined(this.dataStore.cancerClassificationTumorId)
                                    ? null
                                    : this.dataStore.cancerClassificationTumorId.toJSON(),
                                CancerClassificationNodeId: isNullOrUndefined(this.dataStore.cancerClassificationNodeId)
                                    ? null
                                    : this.dataStore.cancerClassificationNodeId.toJSON(),
                                CancerClassificationMetastasisId: isNullOrUndefined(this.dataStore.cancerClassificationMetastasisId)
                                    ? null
                                    : this.dataStore.cancerClassificationMetastasisId.toJSON(),
                                CancerClassificationId: isNullOrUndefined(this.dataStore.cancerClassificationId)
                                    ? null
                                    : this.dataStore.cancerClassificationId.toJSON()
                            },
                            CareActivityId: isNullOrUndefined(this.dataStore.careActivityId)
                                    ? null
                                    : this.dataStore.careActivityId.toJSON(),
                            RowVersion: isNullOrUndefined(this.dataStore.rowVersion)
                                ? new RowVersion(0).toJSON()
                                : this.dataStore.rowVersion.toJSON()
                        }
                    };
                }
            })
        });

        this.disposers.push(
            this.props.extensionController.setIsDirtyHook(this.isDirty)
        );
    }

    @State.bound
    private isDirty() {
        return this.dataStore.isDirty();
    }

    private get condition() {
        return this.props.diagnosisStore?.conditionVersionSelector &&
            this.props._dependencies.careReferenceDataStore.condition.get(this.props.diagnosisStore.conditionVersionSelector);
    }

    private get getTitle() {
        return `${this.condition?.code} - ${StaticHunEHealthInfrastructureCareResources.OncologyData.Title}`;
    }

    private get getSubTitle() {
        return this.condition?.name;
    }

    @State.bound
    private renderSaveButton() {
        if (!this.props.saveAsync || this.props.readonly) {
            return <></>;
        }
        return <SaveButton visualStyle="standard" onClickAsync={this.props.saveAsync} automationId="saveButton" />;
    }

    @State.bound
    private validationPathPrefix() {
        if (!this.props.diagnosisListStore.validationResults || !this.props.diagnosisStore) {
            return "";
        }
        const index = this.props.diagnosisListStore.diagnoses.indexOf(this.props.diagnosisStore);
        return `Diagnoses[${index}]`;
    }

    public render() {
        return (
            <MasterDetail.Detail
                hasSidePadding
                title={this.getTitle}
                subTitle={this.getSubTitle}
                iconName="oncology"
                toolbar={this.renderSaveButton()}
                {...this.props}
            >
                <OncologyPanelView dataStore={this.dataStore}
                    conditionId={this.props.diagnosisStore?.conditionVersionSelector}
                    careActivityId={this.props.careActivityId}
                    cancerClassificationDataStore={this.cancerClassificationDataStore}
                    cancerClassificationStoreLabel={this.tnmLabel}
                    medicalServiceIdFilter={this.filter}
                    validateAsync={this.props.validateAsync}
                    validationResults={this.props.diagnosisListStore.validationResults || []}
                    validationPathPrefix={this.validationPathPrefix()}
                    readonly={this.props.readonly}
                />
            </MasterDetail.Detail>
        );
    }
}

export default connect(
    DiagnosisExtensionPanel,
    new DependencyAdapter<IDiagnosisExtensionPanelProps, IDiagnosisExtensionPanelDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            oncologyApiAdapter: container.resolve("OncologyApiAdapter"),
            ehiCareReferenceDataStore: container.resolve("HunEhiCareReferenceDataStore")
        };
    })
);
