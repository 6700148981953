import DependencyContainer from "@DiContainer";
import * as PerformanceStatementProxy from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/Proxy.g";
import { GetTypeNameWithPrefix } from "@HunSocialSecurityPlugin/PluginHelper";
import PerformanceStatementCareActivityApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/PerformanceStatementCareActivityApiAdapter";
import NeakPerformanceStatementApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/NeakPerformanceStatementApiAdapter";
import NeakPerformanceStatementStoreMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/NeakPerformanceStatementStoreMapper";
import NeakPerformanceStatementDtoMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/NeakPerformanceStatementDtoMapper";
import PerformanceStatementCareActivityStoreMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/PerformanceStatementCareActivityStoreMapper";
import EuPerformanceStatementApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/EuPerformanceStatementApiAdapter";
import EuPerformanceStatementStoreMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/EuPerformanceStatementStoreMapper";
import EuPerformanceStatementDtoMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/EuPerformanceStatementDtoMapper";
import EuErrorListDtoMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/EuErrorListDtoMapper";
import EuErrorListStoreMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/EuErrorListStoreMapper";
import NeakErrorListStoreMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/NeakErrorListStoreMapper";
import NeakErrorListDtoMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/NeakErrorListDtoMapper";

DependencyContainer
    .bind(GetTypeNameWithPrefix("IPerformanceStatementClient"))
    .to(PerformanceStatementProxy.PerformanceStatementClient)
    .inSingletonScope();

DependencyContainer
    .bind("PerformanceStatementCareActivityApiAdapter")
    .to(PerformanceStatementCareActivityApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PerformanceStatementCareActivityStoreMapper")
    .to(PerformanceStatementCareActivityStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("NeakPerformanceStatementApiAdapter")
    .to(NeakPerformanceStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("NeakPerformanceStatementStoreMapper")
    .to(NeakPerformanceStatementStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("NeakPerformanceStatementDtoMapper")
    .to(NeakPerformanceStatementDtoMapper)
    .inSingletonScope();

DependencyContainer
    .bind("EuPerformanceStatementApiAdapter")
    .to(EuPerformanceStatementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EuPerformanceStatementStoreMapper")
    .to(EuPerformanceStatementStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("EuPerformanceStatementDtoMapper")
    .to(EuPerformanceStatementDtoMapper)
    .inSingletonScope();

DependencyContainer
    .bind("NeakErrorListStoreMapper")
    .to(NeakErrorListStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("NeakErrorListDtoMapper")
    .to(NeakErrorListDtoMapper)
    .inSingletonScope();

DependencyContainer
    .bind("EuErrorListStoreMapper")
    .to(EuErrorListStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("EuErrorListDtoMapper")
    .to(EuErrorListDtoMapper)
    .inSingletonScope();