import State from "@Toolkit/ReactClient/Common/StateManaging";
import IFormLayoutBlockStore from "@Toolkit/FormEngine/Model/Layout/IFormLayoutBlockStore";
import Guid from "@Toolkit/CommonWeb/Guid";
import EditableLayoutColumnStore from "./EditableLayoutColumnStore";
import IEditableLayoutBlockStore from "./IEditableLayoutBlockStore";

export default class EditableLayoutRowBlockStore implements IFormLayoutBlockStore, IEditableLayoutBlockStore {

    public readonly id: string;
    public readonly content = State.createObservableShallowArray<EditableLayoutColumnStore>([]);

    constructor() {
        this.id = Guid.newGuid();
    }
}