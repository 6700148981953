import React from "react";
import Styles from "./LoadingIndicator.less";
import { ICommonControlProps, getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import AppStatusStore from "@Toolkit/CommonWeb/AppStatusStore";

interface ILoadingIndicatorProps extends ICommonControlProps {
    mode?: "standalone" | "parent" | "fullscreen" | "fullscreen-overlay";
    size?: "large" | "medium" | "small";
    overlay?: boolean;
}

let loadingIndicatorIndex = 1;

export default class LoadingIndicator extends React.PureComponent<ILoadingIndicatorProps> {

    private readonly index = loadingIndicatorIndex++;
    public static defaultProps: Partial<ILoadingIndicatorProps> = {
        mode: "parent",
        size: "medium",
        overlay: true
    };

    private getContainerClassName(): CompositeClassName {
        switch (this.props.mode) {
            case "standalone":
                const classes = new CompositeClassName(Styles.standaloneContainer);
                classes.addIf(this.props.size === "large", Styles.standaloneContainerLarge);
                classes.addIf(this.props.size === "medium", Styles.standaloneContainerMedium);
                classes.addIf(this.props.size === "small", Styles.standaloneContainerSmall);
                return classes;
            case "parent":
                const parentContainerClasses = new CompositeClassName(Styles.parentOverlay);
                parentContainerClasses.addIf(!this.props.overlay, Styles.transparentOverlay);
                return parentContainerClasses;
            case "fullscreen":
                return new CompositeClassName("global-loading-container");
            case "fullscreen-overlay":
                return new CompositeClassName("global-loading-container global-loading-container--overlay");
        }
    }

    private getInnerContainerClassName() {
        switch (this.props.size) {
            case "large":
                return Styles.parentLargeContainer;
            case "medium":
                return Styles.parentMediumContainer;
            case "small":
                return Styles.parentSmallContainer;
        }
        throw new Error("Size is out of range");
    }

    private renderCore() {
        switch (this.props.mode) {
            case "standalone":
                return <div className={Styles.loadingIndicator} />;
            case "parent":
                return (
                    <div className={this.getInnerContainerClassName()}>
                        <div className={Styles.loadingIndicator} />
                    </div>
                );
            default:
                return <div className="global-loading-indicator" />;
        }
    }

    public componentDidMount() {
        AppStatusStore.instance.setLoadingState(`LI:${this.index}`);
    }

    public componentWillUnmount() {
        AppStatusStore.instance.clearLoadingState(`LI:${this.index}`);
    }

    public render() {
        const htmlProps = getStandardHtmlProps(this.props);
        const classes = this.getContainerClassName();
        classes.add(htmlProps.className);
        htmlProps.className = classes.classNames;

        return (
            <div data-automation-id="loading-indicator" {...htmlProps}>
                {this.renderCore()}
            </div>
        );
    }
}