import IEhiError from "@HunEHealthInfrastructurePlugin/Common/Model/IEhiError";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class EhiMedicalConditionSynchronizationExtensionStore {
    @State.observable.ref private ehiErrors: IEhiError[] = null;

    @State.action.bound
    public setEhiErrors(errors: IEhiError[]) {
        this.ehiErrors = errors;
    }

    @State.bound
    public getEhiErrors() {
        return this.ehiErrors;
    }
}