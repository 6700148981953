import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import PractitionerRecommendationStoreMapper from "./PractitionerRecommendationStoreMapper";
import PractitionerRecommendationId from "@Primitives/PractitionerRecommendationId.g";
import { getDosageDto } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionDtoMapper";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import MedicationPractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/MedicationPractitionerRecommendation";
import EquipmentPractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/EquipmentPractitionerRecommendation";
import { RecommendationType } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/RecommendationType";
import EquipmentSupportTypeId from "@Primitives/EquipmentSupportTypeId.g";
import PractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/PractitionerRecommendation";

@Di.injectable()
export default class PractitionerRecommendationApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IPractitionerRecommendationClient") private apiClient: Proxy.IPractitionerRecommendationClient,
        @Di.inject("PractitionerRecommendationStoreMapper") private storeMapper: PractitionerRecommendationStoreMapper
    ) {
        super();
    }

    @State.bound
    public getPractitionerRecommendationListAsync(careActivityId: CareActivityId, dateRange: LocalDateRange, includeDeleted: boolean, includeExpired: boolean, pointOfCareIds: PointOfCareId[], isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new SimpleStore<PractitionerRecommendation[]>(),
            async target => {
                const response = await this.apiClient.getPractitionerRecommendationListQueryAsync(CreateRequestId(), new Proxy.GetPractitionerRecommendationListControllerDto({
                    includeDeleted: includeDeleted,
                    includeExpired: includeExpired,
                    careActivityId: careActivityId,
                    dateRange: dateRange,
                    pointOfCareIds: pointOfCareIds,
                    professionalExamId: null
                }), isPermissionCheckOnly);
                if (response) {
                    target.operationInfo = createOperationInfo(response);
                }
                target.value = response?.practitionerRecommendations?.map(i => {
                    let newItem;
                    if (i instanceof Proxy.MedicationPractitionerRecommendationDto) {
                        newItem = new MedicationPractitionerRecommendation();
                    } else if (i instanceof Proxy.EquipmentPractitionerRecommendationDto) {
                        newItem = new EquipmentPractitionerRecommendation();
                    }
                    newItem.extensionData = i.extensionData;
                    this.storeMapper.applyToStore(newItem, i);
                    return newItem;
                });
            }
        );
    }

    @State.bound
    public getPractitionerRecommendationAsync(id: PractitionerRecommendationId, requestLock: boolean = true, isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new SimpleStore<PractitionerRecommendation>(),
            async target => {
                const response = await this.apiClient.getPractitionerRecommendationCommandAsync(CreateRequestId(), new Proxy.GetPractitionerRecommendationControllerDto({
                    requestLock: requestLock,
                    practitionerRecommendationId: id
                }), isPermissionCheckOnly);
                target.value = this.getTargetType(response);
                target.operationInfo = createOperationInfo(response);
                if (target.value) {
                    this.storeMapper.applyToStore(target.value, response, isPermissionCheckOnly);
                }
            }
        );
    }

    private getTargetType(response: Proxy.GetPractitionerRecommendationCommandResponse | Proxy.AddPractitionerRecommendationCommandResponse | Proxy.UpdatePractitionerRecommendationCommandResponse | Proxy.RevokePractitionerRecommendationFinalizationCommandResponse) {
        if (response.practitionerRecommendation instanceof Proxy.MedicationPractitionerRecommendationDto) {
            return new MedicationPractitionerRecommendation();
        }
        if (response.practitionerRecommendation instanceof Proxy.EquipmentPractitionerRecommendationDto) {
            return new EquipmentPractitionerRecommendation();
        }
        return null;
    }

    private getCreationDtoByType(store: PractitionerRecommendation) {
        switch (store.type) {
            case RecommendationType.GY:
                const medicationStore = store as MedicationPractitionerRecommendation;
                return new Proxy.MedicationPractitionerRecommendationCreationDto({
                    careActivityId: medicationStore.careActivityId,
                    recordedOn: medicationStore.recordedOn || LocalDate.today(),
                    useMedicationName: medicationStore.useMedicationName,
                    claimTypeId: medicationStore.claimTypeId,
                    conditionId: medicationStore.conditionId,
                    dosage: getDosageDto(medicationStore.dosage),
                    medicationFormId: medicationStore.medicationFormId,
                    medicationVersionSelector: medicationStore.medicationVersionSelector ? new Proxy.EntityVersionSelectorOfMedicationId({ entityId: medicationStore.medicationVersionSelector?.id, validOn: medicationStore.medicationVersionSelector?.validOn }) : new Proxy.EntityVersionSelectorOfMedicationId({ validOn: LocalDate.today() }),
                    medicationSubsidyId: medicationStore.medicationSubsidyId,
                    subsidyOptionId: medicationStore.subsidyOptionId,
                    validityDurationInMonths: medicationStore.validityDurationInMonths
                } as Proxy.IMedicationPractitionerRecommendationCreationDto);
            case RecommendationType.GYSE:
                const equipmentStore = store as EquipmentPractitionerRecommendation;
                return new Proxy.EquipmentPractitionerRecommendationCreationDto({
                    careActivityId: equipmentStore.careActivityId,
                    amount: equipmentStore.amount ?? 0,
                    recordedOn: equipmentStore.recordedOn || LocalDate.today(),
                    validTo: equipmentStore.validTo || LocalDate.createEmpty(),
                    claimTypeId: equipmentStore.claimTypeId,
                    conditionId: equipmentStore.conditionId,
                    furtherConditions: equipmentStore.furtherConditions,
                    professionalExamId: equipmentStore.professionalExamId,
                    notes: equipmentStore.notes,
                    recommendedEquipment1: equipmentStore.recommendedEquipment1 ? new Proxy.EntityVersionSelectorOfMedicationEquipmentClassificationId({ entityId: equipmentStore.recommendedEquipment1.id, validOn: equipmentStore.recommendedEquipment1.validOn }) : new Proxy.EntityVersionSelectorOfMedicationEquipmentClassificationId({ validOn: LocalDate.today() }),
                    recommendedEquipment2: equipmentStore.recommendedEquipment2 ? new Proxy.EntityVersionSelectorOfMedicationEquipmentClassificationId({ entityId: equipmentStore.recommendedEquipment2.id, validOn: equipmentStore.recommendedEquipment2.validOn }) : new Proxy.EntityVersionSelectorOfMedicationEquipmentClassificationId({ validOn: LocalDate.today() }),
                    equipmentSupportTypeId: equipmentStore.equipmentSupportTypeId
                } as Proxy.IEquipmentPractitionerRecommendationCreationDto);
        }
    }

    private getUpdateDtoByType(store: PractitionerRecommendation) {
        switch (store.type) {
            case RecommendationType.GY:
                const medicationStore = store as MedicationPractitionerRecommendation;
                return new Proxy.MedicationPractitionerRecommendationUpdateDto({
                    
                    id: medicationStore.id,
                    recordedOn: medicationStore.recordedOn || LocalDate.today(),
                    rowVersion: medicationStore.rowVersion,
                    useMedicationName: medicationStore.useMedicationName,
                    claimTypeId: medicationStore.claimTypeId,
                    conditionId: medicationStore.conditionId,
                    dosage: getDosageDto(medicationStore.dosage),
                    medicationFormId: medicationStore.medicationFormId,
                    medicationVersionSelector: medicationStore.medicationVersionSelector ? new Proxy.EntityVersionSelectorOfMedicationId({ entityId: medicationStore.medicationVersionSelector?.id, validOn: medicationStore.medicationVersionSelector?.validOn }) : new Proxy.EntityVersionSelectorOfMedicationId({ validOn: LocalDate.today() }),
                    medicationSubsidyId: medicationStore.medicationSubsidyId,
                    subsidyOptionId: medicationStore.subsidyOptionId,
                    validityDurationInMonths: medicationStore.validityDurationInMonths,
                } as Proxy.IMedicationPractitionerRecommendationUpdateDto);
            case RecommendationType.GYSE:
                const equipmentStore = store as EquipmentPractitionerRecommendation;
                return new Proxy.EquipmentPractitionerRecommendationUpdateDto({
                    
                    id: equipmentStore.id,
                    rowVersion: equipmentStore.rowVersion,
                    careActivityId: equipmentStore.careActivityId,
                    amount: equipmentStore.amount ?? 0,
                    recordedOn: equipmentStore.recordedOn || LocalDate.today(),
                    validTo: equipmentStore.validTo,
                    claimTypeId: equipmentStore.claimTypeId,
                    conditionId: equipmentStore.conditionId,
                    furtherConditions: equipmentStore.furtherConditions,
                    professionalExamId: equipmentStore.professionalExamId,
                    notes: equipmentStore.notes,
                    recommendedEquipment1: new Proxy.EntityVersionSelectorOfMedicationEquipmentClassificationId({ entityId: equipmentStore.recommendedEquipment1.id, validOn: equipmentStore.recommendedEquipment1.validOn }),
                    recommendedEquipment2: new Proxy.EntityVersionSelectorOfMedicationEquipmentClassificationId({ entityId: equipmentStore.recommendedEquipment2.id, validOn: equipmentStore.recommendedEquipment2.validOn }),
                    equipmentSupportTypeId: equipmentStore.equipmentSupportTypeId
                } as Proxy.IEquipmentPractitionerRecommendationUpdateDto);
        }
    }

    @State.bound
    public addPractitionerRecommendationAsync(store: PractitionerRecommendation, requestLock: boolean, isValidateOnly: boolean = false, isPermissionCheckOnly = false) {
        if (isNullOrUndefined(store)) {
            return null;
        }
        return this.processOperationAsync(
            new SimpleStore<PractitionerRecommendation>(),
            async target => {
                const response = await this.apiClient.addPractitionerRecommendationCommandAsync(CreateRequestId(), new Proxy.AddPractitionerRecommendationControllerDto({
                    creationDto: this.getCreationDtoByType(store),
                    requestLock: requestLock,
                    isValidateOnly: isValidateOnly
                }), isPermissionCheckOnly);
                target.value = this.getTargetType(response);
                target.operationInfo = createOperationInfo(response);
                if (target.value) {
                    this.storeMapper.applyToStore(target.value, response, isPermissionCheckOnly);
                }
            }
        );
    }

    @State.bound
    public updatePractitionerRecommendationAsync(store: PractitionerRecommendation, releaseLock: boolean, currentCareActivityId: CareActivityId, isValidateOnly: boolean = false, isPermissionCheckOnly = false) {
        if (isNullOrUndefined(store) || isNullOrUndefined(store.lockInfo)) {
            return null;
        }
        return this.processOperationAsync(
            new SimpleStore<PractitionerRecommendation>(),
            async target => {
                const response = await this.apiClient.updatePractitionerRecommendationCommandAsync(CreateRequestId(), new Proxy.UpdatePractitionerRecommendationControllerDto({
                    lockId: store.lockInfo.lockId,
                    updateDto: this.getUpdateDtoByType(store),
                    releaseLockIfSuccessful: releaseLock,
                    currentCareActivityId: currentCareActivityId,
                    rowVersion: store.rowVersion,
                    isValidateOnly: isValidateOnly
                }), isPermissionCheckOnly);
                target.value = this.getTargetType(response);
                target.operationInfo = createOperationInfo(response);
                if (target.value) {
                    this.storeMapper.applyToStoreWithExistingLock(target.value, response, store.lockInfo, releaseLock, isPermissionCheckOnly);
                }
            }
        );
    }

    @State.bound
    public removePractitionerRecommendation(id: PractitionerRecommendationId, currentCareActivityId: CareActivityId, isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.removePractitionerRecommendationCommandAsync(CreateRequestId(), new Proxy.RemovePractitionerRecommendationControllerDto({
                    practitionerRecommendationId: id,
                    currentCareActivityId: currentCareActivityId
                }), isPermissionCheckOnly);
                if (response) {
                    target.operationInfo = createOperationInfo(response);
                }
            }
        );
    }

    @State.bound
    public finalizePractitionerRecommendationAsync(store: PractitionerRecommendation, releaseLock: boolean, currentCareActivityId: CareActivityId, isValidateOnly: boolean = false, isPermissionCheckOnly = false) {
        if (isNullOrUndefined(store) || isNullOrUndefined(store.lockInfo)) {
            return null;
        }

        return this.processOperationAsync(
            new SimpleStore<PractitionerRecommendation>(),
            async target => {
                const response = await this.apiClient.finalizePractitionerRecommendationCommandAsync(CreateRequestId(), new Proxy.FinalizePractitionerRecommendationControllerDto({
                    lockId: store.lockInfo.lockId,
                    practitionerRecommendation: this.getUpdateDtoByType(store),
                    releaseLockIfSuccessful: releaseLock,
                    currentCareActivityId: currentCareActivityId,
                    rowVersion: store.rowVersion,
                    isValidateOnly: isValidateOnly
                }), isPermissionCheckOnly);
                target.value = this.getTargetType(response);
                target.operationInfo = createOperationInfo(response);
                if (target.value) {
                    this.storeMapper.applyToStoreWithExistingLock(target.value, response, store.lockInfo, releaseLock, isPermissionCheckOnly);
                }
            }
        );
    }

    @State.bound
    public revokePractitionerRecommendationFinalization(id: PractitionerRecommendationId, currentCareActivityId: CareActivityId, requestLock: boolean, isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new SimpleStore<PractitionerRecommendation>(),
            async target => {
                const response = await this.apiClient.revokePractitionerRecommendationFinalizationCommandAsync(CreateRequestId(), new Proxy.RevokePractitionerRecommendationFinalizationControllerDto({
                    practitionerRecommendationId: id,
                    currentCareActivityId: currentCareActivityId,
                    requestLock: requestLock
                }), isPermissionCheckOnly);
                target.value = this.getTargetType(response);
                target.operationInfo = createOperationInfo(response);
                if (target.value) {
                    this.storeMapper.applyToStore(target.value, response, isPermissionCheckOnly);
                }
            }
        );
    }

    @State.bound
    public loadEquipmentSupportTypeIdsAsync(): Promise<SimpleStore<EquipmentSupportTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getEquipmentSupportTypeIdsQueryAsync(...args),
            response => response.equipmentSupportTypeIds
        );
    }
}