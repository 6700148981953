import FormLayoutId from "@Toolkit/FormEngine/Model/Primitives/FormLayoutId.g";

export default class FormLayoutReference {
    constructor(
        public readonly formLayoutId: FormLayoutId,
        public readonly versionNumber: number
    ) {}

    public toString() {
        return `FormLayoutReference:${this.formLayoutId?.value}:${this.versionNumber}`;
    }

    public toJSON() {
        return {
            FormLayoutId: this.formLayoutId,
            VersionNumber: this.versionNumber
        };
    }

    public static fromJs(raw: any) {
        return new FormLayoutReference(raw.FormLayoutId, raw.VersionNumber);
    }
}