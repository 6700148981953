import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import { IEHRRouteParams } from "@HisPlatform/Application/Routes/IEHRRouteParams";
import PatientId from "@Primitives/PatientId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientCareActivitiesMasterDetailPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PatientsCareActivitiesPanel/PatientCareActivitiesMasterDetailPanel";

@State.observer
export default class PatientsCareActivitiesPage extends React.Component<IRoutingFrameContentProps> {

    private get patientId() {
        const patientIdValue = (this.props.routingController.currentRoute.parameters as IEHRRouteParams).patientId;
        if (patientIdValue) {
            return new PatientId(patientIdValue);
        }
        return null;
    }

    public render() {
        return (
            // <PatientCareActivitiesPanel patientId={this.patientId} />
            <PatientCareActivitiesMasterDetailPanel patientId={this.patientId} />
        );
    }
}