import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import ITherapyHintItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/ITherapyHintItem";
import ITherapy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/ITherapy";
import TherapyId from "@Primitives/TherapyId.g";
import { GetTypeNameWithPrefix } from "@HunEHealthInfrastructurePlugin/PluginHelper";
import { QueryPaging } from "@HisPlatform/BoundedContexts/CommonReferenceData/Api/Proxy.g";
import ComplexSearchTherapiesQueryOrderingFields from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/MedicalCondition/Therapies/Enum/ComplexSearchTherapiesQueryOrderingFields.g";

/* auto-inject-disable */
@Di.injectable()
export default class TherapiesApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("ITherapiesClient")) private readonly apiClient: Proxy.ITherapiesClient        
    ) {
        super();
    }

    public searchTherapies(freeText: string, maxResultCount: number) {
        return this.processOperationAsync(
            new SimpleStore<ITherapyHintItem[]>(),
            async target => {
                const response = await this.apiClient.searchTherapiesQueryAsync(
                    CreateRequestId(),
                    freeText,
                    maxResultCount.toString()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.therapies.map(ai => {
                    return {
                        id: ai.id,
                        code: ai.code,
                        name: ai.name
                    } as ITherapyHintItem;
                });
            }
        );
    }

    public getAllIdsAsync(): Promise<SimpleStore<TherapyId[]>> {
        return this.processOperationAsync(
            new SimpleStore<TherapyId[]>(),
            async target => {
                const response = await this.apiClient.getTherapyIdsQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.ids.map(id => id);
            }
        );
    }

    @State.bound
    public loadTherapiesAsync(ids: TherapyId[]): Promise<SimpleStore<ITherapy[]>> {
        return this.processOperationAsync(
            new SimpleStore<ITherapy[]>(),
            async (target) => {
                const response = await this.apiClient.getTherapiesByIdsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetTherapiesByIdsControllerDto({ ids: ids })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.therapies.map(this.mapTherapy);
            }
        );
    }

    private mapTherapy(ai: Proxy.TherapyDto): ITherapy {
        return {
            id: ai.id,
            code: ai.code,
            name: ai.name
        } as ITherapy;
    }

    @State.bound
    public complexSearchTherapies(
        freeText: string,
        ordering: IOrderingState,
        paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<ITherapy>>(),
            async (target) => {
                const response = await this.apiClient.complexSearchTherapiesQueryAsync(
                    CreateRequestId(),
                    new Proxy.ComplexSearchTherapiesControllerDto({
                        freeText: freeText,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfComplexSearchTherapiesQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfComplexSearchTherapiesQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: ComplexSearchTherapiesQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results && response.results.values.map(this.mapTherapy)
                };

            }
        );
    }
}
