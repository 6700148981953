import DependencyContainer from "@DiContainer";
import TelemetrySessionScreenFormBaseFormLogic from "@AssecoMedPlugin/Packages/Care/FormLogics/TelemetrySessionScreenFormBaseFormLogic";
import DecisionSupportScreenFormLogic from "@AssecoMedPlugin/Packages/Care/FormLogics/DecisionSupportScreenFormLogic";

DependencyContainer
    .bind("TelemetrySessionScreenFormBaseFormLogic")
    .to(TelemetrySessionScreenFormBaseFormLogic)
    .inTransientScope();

DependencyContainer
    .bind("DecisionSupportScreenFormLogic")
    .to(DecisionSupportScreenFormLogic)
    .inTransientScope();