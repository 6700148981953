import CommandProcessorBase from "@Toolkit/CommonWeb/CommandProcessing/Definition/CommandProcessorBase";
import Command from "@Toolkit/CommonWeb/CommandProcessing/Definition/Command";

export default class CallbackCommandProcessor<TCommand extends Command> extends CommandProcessorBase<TCommand> {
    public get commandType(): string {
        return this._commandType;
    }

    constructor(
        private readonly _commandType: string,
        private readonly _callback: (command: TCommand) => Promise<void>
    ) {
        super();
    }

    /** {@inheritdoc} */
    public processAsync(command: TCommand): Promise<void> {
        return this._callback(command);
    }
}