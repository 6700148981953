import React, { useMemo, useCallback } from "react";
import _ from "@HisPlatform/Common/Lodash";
import { CheckBox } from "@CommonControls";
import { RowId, IRowCheckState } from "@CommonControls/DataGrid/IDataGridProps";
import { IRowData } from "@CommonControls/DataGrid/ViewModel";

interface IDataGridCheckCellProps {
    row: IRowData;
    rowIndex: number;
    isRowBody: boolean;

    checkState: IRowCheckState;
    onRowChecked: (isChecked: boolean, row: any, rowId: RowId, rowIndex: number) => void;
}


const DataGridCheckCell: React.FC<IDataGridCheckCellProps> = props => {

    const handleCheck = useCallback((isChecked: boolean) => {
        if (props.onRowChecked) {
            props.onRowChecked(isChecked, props.row.data, props.row.id, props.rowIndex);
        }
    }, [props.onRowChecked, props.row, props.rowIndex]);

    return (
        <td role="rowCheck">
            {(props.isRowBody ? props.checkState.isRowBodyVisible : props.checkState.isVisible) && (
                <CheckBox
                    value={props.checkState.isChecked}
                    disabled={props.checkState.isDisabled}
                    onChange={handleCheck}
                    verticalAlign="noPadding"
                    automationId={props.checkState.checkBoxAutomationId ?? `dataGrid_row_checkbox_${props.row.id}`}
                />
            )}
        </td>
    );
};

export default DataGridCheckCell;