import React, { ReactNode } from "react";
import * as Ui from "@CommonControls";
import StatusChangeReasonSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/StatusChangeReasonSelectBox/StatusChangeReasonSelectBox";
import StatusChangeReasonId from "@Primitives/StatusChangeReasonId.g";
import StatusChangeReasonTypeId from "@Primitives/StatusChangeReasonTypeId.g";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import * as HisUi from "@HisPlatformControls";

interface IStatusChangeReasonDialogViewProps {
    statusChangeReasonId: StatusChangeReasonId;
    statusChangeReasonTypeId: StatusChangeReasonTypeId;
    additionalText: string;
    additionalTextRequired: boolean;
    additionalTitle?: string;
    additionalComponent?: ReactNode;

    setStatusChangeReasonId: (newValue: StatusChangeReasonId) => void;
    setAdditionalText: (newValue: string) => void;
    save: () => void;
    onClose: () => void;

    dialogBodyRenderer: () => React.ReactNode;
}

const getTitle = (props: IStatusChangeReasonDialogViewProps) => {
    if (props.additionalTitle) {
        return `${StaticCareResources.ReferenceData.StatusChangeReasonDialog.Title} - ${props.additionalTitle}`;
    }
    return StaticCareResources.ReferenceData.StatusChangeReasonDialog.Title;
};


const StatusChangeReasonDialogView: React.FC<IStatusChangeReasonDialogViewProps> = props => (
    <HisUi.ContextAwareModal
        title={getTitle(props)}
        closeButton
        isOpen
        onClose={props.onClose}
        size="compact"
        closeOnOutsideClick={false}
        closeOnEscape={false}
        automationId="__statusChangeReasonDialog"
        iconName="circleArrowCcw">
        <Ui.Modal.Body>
            <div style={{marginBottom: 8}}>
                {props.dialogBodyRenderer && props.dialogBodyRenderer()}
                <Ui.Flex>
                    <Ui.Flex.Item sm={10} smOffset={1}>
                    {props.additionalComponent}
                        <StatusChangeReasonSelectBox
                            label={StaticCareResources.ReferenceData.StatusChangeReasonDialog.Label.Reason}
                            value={props.statusChangeReasonId}
                            onChange={props.setStatusChangeReasonId}
                            statusChangeReasonTypeId={props.statusChangeReasonTypeId}
                            required
                            automationId="__statusChangeReasonSelectBox"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item sm={10} smOffset={1}>
                        <Ui.TextBox
                            label={StaticCareResources.ReferenceData.StatusChangeReasonDialog.Label.AdditionalText}
                            value={props.additionalText}
                            onChange={props.setAdditionalText}
                            required={props.additionalTextRequired}
                            automationId="__statusChangeReasonAddendum"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </div>
        </Ui.Modal.Body>
        <Ui.Modal.Footer>
            <Ui.Flex xsJustify="end">
                <Ui.Flex.Item>
                    <Ui.SaveButton
                        disabled={!props.statusChangeReasonId || (props.additionalTextRequired && props.additionalText.length === 0)}
                        visualStyle="primary"
                        onClick={props.save}
                        automationId="__save"
                    />
                    <Ui.Button
                        text={StaticCareResources.Common.Button.Cancel}
                        onClick={props.onClose}
                        automationId="__cancel"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </Ui.Modal.Footer>
    </HisUi.ContextAwareModal>
);

export default StatusChangeReasonDialogView;
