import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiSyncStatusLabel from "@HunEHealthInfrastructurePlugin/Common/Components/EhiSyncStatusLabel";
import IPractitionerRecommendationListNameExtensionPointProps from "@PluginInterface/BoundedContexts/MedicationRequest/ExtensionPoints/IPractitionerRecommendationListNameExtensionPointProps";

@State.observer
export default class EhiPractitionerRecommendationListNameExtension extends React.Component<IPractitionerRecommendationListNameExtensionPointProps> {

    public render() {
        if (!isNullOrUndefined(this.props.extensionData.PractitionerRecommendationSyncStatus)) {
            return <EhiSyncStatusLabel value={this.props.extensionData.PractitionerRecommendationSyncStatus} upperIndex />;
        } else {
            return null;
        }
    }
}