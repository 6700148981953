import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import TherapyRecommendationId from "@Primitives/TherapyRecommendationId.g";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import TherapyRecommendationPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/MedicalCondition/TherapyRecommendation/TherapyRecommendationPanel";

export default function configureTherapyRecommendationUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2({
        identifiers: [HunEHealthInfrastructureUseCases.therapyRecommendationDetail],
        component: TherapyRecommendationPanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));
            const id = map.get("therapyRecommendationId") ?? new TherapyRecommendationId("new");
            return {
                id: id,
            };
        }
    });
}
