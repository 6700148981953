import ProfessionalExamId from "@Primitives/ProfessionalExamId.g";
import OrganizationRestrictionTypeId from "@Primitives/OrganizationRestrictionTypeId.g";
import MedicationSubsidyOptionRestrictionId from "@Primitives/MedicationSubsidyOptionRestrictionId.g";
import DesignatedOrganizationId from "@Primitives/DesignatedOrganizationId.g";
import PrescriptionRestrictionType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/ReferenceData/Prescription/Enum/PrescriptionRestrictionType.g";

export default class SubsidyOptionRestriction {
    constructor(
        public id: MedicationSubsidyOptionRestrictionId,
        public practitionerProfessionalExamRestriction: ProfessionalExamId,
        public prescriptionRestrictionType: PrescriptionRestrictionType,
        public organizationRestrictionTypeId: OrganizationRestrictionTypeId,
        public practitionerPrescriptionValidityDurationInMonths: number,
        public designatedOrganizationId: DesignatedOrganizationId
    ) { }

    public getHashCode() {
        return `${this.organizationRestrictionTypeId?.value}${this.practitionerProfessionalExamRestriction?.value}${this.prescriptionRestrictionType}${this.practitionerPrescriptionValidityDurationInMonths}`;
    }
}