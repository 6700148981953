import PractitionerRecommendation from "./PractitionerRecommendation";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Dosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Dosage";
import FrequencyBasedDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/FrequencyBasedDosage";
import MedicationFormId from "@Primitives/MedicationFormId.g";
import MedicationId from "@Primitives/MedicationId.g";
import MedicationSubsidyOptionId from "@Primitives/MedicationSubsidyOptionId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import { RecommendationType } from "./RecommendationType";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import MedicationSubsidyId from "@Primitives/MedicationSubsidyId.g";

export default class MedicationPractitionerRecommendation extends PractitionerRecommendation {

    constructor(isNew?: boolean) {
        super(isNew);
        this.type = RecommendationType.GY;
    }

    @State.observable.ref public dosage: Dosage = new FrequencyBasedDosage();
    @State.observable.ref public medicationFormId: MedicationFormId;
    @State.observable.ref public medicationVersionSelector: IEntityVersionSelector<MedicationId>;
    @State.observable public validityDurationInMonths: number = null;
    @State.observable.ref public medicationSubsidyId: MedicationSubsidyId = null;
    @State.observable.ref public subsidyOptionId: MedicationSubsidyOptionId = null;
    @State.observable public useMedicationName: boolean = false;

    @State.computed public get validTo(): LocalDate {
        return this.recordedOn.plusMonths(this.validityDurationInMonths);
    }

    @State.action.bound
    public setUseMedicationName(useMedicationName: boolean) {
        this.useMedicationName = useMedicationName;
    }
    
    @State.action.bound
    public setClaimTypeId(claimTypeId: MedicationSubsidyClaimTypeId) {
        this.claimTypeId = claimTypeId;
        this.subsidyOptionId = null;
    }

    @State.action.bound
    public setDosage(dosage: Dosage) {
        this.dosage = dosage;
    }

    @State.action.bound
    public setMedicationFormId(medicationFormId: MedicationFormId) {
        this.medicationFormId = medicationFormId;
    }

    @State.action.bound
    public setMedicationId(medicationId: MedicationId) {
        this.medicationVersionSelector = {id: medicationId, validOn: this.recordedOn};
    }

    @State.action.bound
    public setValidityDurationInMonths(validityDurationInMonths: number) {
        this.validityDurationInMonths = validityDurationInMonths;
    }

    @State.action.bound
    public setMedicationSubsidyId(medicationSubsidyId: MedicationSubsidyId) {
        this.medicationSubsidyId = medicationSubsidyId;
    }

    @State.action.bound
    public setSubsidyOptionId(subsidyOptionId: MedicationSubsidyOptionId) {
        this.subsidyOptionId = subsidyOptionId;
    }
}