import IDynamicListDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IDynamicListDefinition";
import Di from "@Di";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { isNullOrUndefinedOrGivenString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import moment from "moment";
import { INDataScreenProps } from "./NDataScreen";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";

@Di.injectable()
export default class NDataScreenStore extends PanelStoreBase<INDataScreenProps> {

    @State.observable.ref private listDefinition: IDynamicListDefinition = null;
    @State.observable.ref private lastUpdatedAt: moment.Moment = null;
    @State.observable.ref public isUnauthorized = false;

    @State.computed
    public get refreshListEvent() {
        return this.props.refreshListEvent ?? this._refreshListEvent;
    }
    
    private _refreshListEvent = new TypedAsyncEvent();

    @State.computed public get title() {
        return this.listDefinition?.name ?? this.props.defaultPageTitle;
    }

    @State.observable.ref public globalActions: ActionDescriptor[] = [];

    @State.computed public get subtitle() {
        const lastUpdateStr = this.lastUpdatedAt ? this.localizationService.localizeDateTime(this.lastUpdatedAt, true, true) : "";
        return `${StaticWebAppResources.Worklist.Label.LastUpdated}: ${lastUpdateStr}`;
    }

    @State.computed public get selectedRowId() {
        const state = this.props._screenNavigationContext.currentPrimaryScreen.screenState;
        return isNullOrUndefinedOrGivenString(state, "null") ? null : state;
    }

    constructor(
        @Di.inject("ILocalizationService") private readonly localizationService: ILocalizationService,
        @Di.inject("ICurrentCultureProvider") private readonly cultureCodeProvider: ICurrentCultureProvider
    ) {
        super();
    }

    @State.action.bound
    public setListDefinition(listDefinition: IDynamicListDefinition) {
        this.listDefinition = listDefinition;
    }

    @State.action.bound
    public setDataLoaded(_: any, globalFrontendActions: ActionDescriptor[]) {
        this.lastUpdatedAt = DateTimeService.now();
        this.globalActions = globalFrontendActions;
    }

    public readonly refreshList = this.async(async () => {
        await this.refreshListEvent.emitAsync();
        return;
    });

    @State.action.bound
    public setUnauthorized() {
        this.isUnauthorized = true;
    }
}