import moment from "moment";

export default class YearMonth {

    public constructor(
        public readonly year: number = null,
        public readonly month: number = null
    ) {
    }

    public static createFromMoment(value: moment.Moment): YearMonth {
        if (!value) { return null; }
        return new YearMonth(value.year(), value.month() + 1);
    }

    public stringify(): string {
        if (this.isEmpty()) {
            return "";
        }
        return `${this.year.toString().padStart(4, "0")}-${this.month.toString().padStart(2, "0")}-01`;
    }

    public static parse(value: string): YearMonth {
        if (!value || value === "null" || value === "undefined") {
            return null;
        }

        const regexMatch = /(\d{4})-(\d{2})/g.exec(value);

        if (value.length < 7 || !regexMatch || regexMatch.length < 3) {
            throw new Error("Cannot parse date string: " + value);
        }

        return new YearMonth(parseInt(regexMatch[1], 10), parseInt(regexMatch[2], 10));
    }

    public toJSON() {
        if (this.isEmpty()) {
            return null;
        }

        return {
            Year: this.year,
            Month: this.month
        };
    }

    public static fromJS(data: any) {
        return data ? new YearMonth(data.Year, data.Month) : this.createEmpty();
    }

    public toMoment(): moment.Moment {
        if (this.isEmpty()) {
            return null;
        }
        return moment(this.stringify());
    }

    public static createEmpty() {
        return new YearMonth(null, null);
    }

    public isEmpty(): boolean {
        return this.year === null || this.year === undefined || this.month === null || this.month === undefined;
    }
}
