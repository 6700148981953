import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import ProcedureStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ProcedureStatement/ProcedureStatementStore";
import ProcedureStatementId from "@Primitives/ProcedureStatementId.g";
import ProcedureStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ProcedureStatement/ProcedureStatementHistoryItem";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import CustomProcedureAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ProcedureStatement/CustomProcedureAssignment";
import ReferencedMedicalServiceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ProcedureStatement/ReferencedMedicalServiceAssignment";

type ResponseType = Proxy.GetProcedureStatementByIdCommandResponse | Proxy.AddProcedureStatementCommandResponse | Proxy.UpdateProcedureStatementCommandResponse | Proxy.DeleteProcedureStatementHistoryItemCommandResponse;

@Di.injectable()
export default class ProcedureStatementStoreMapper extends LockingEntityStoreMapper<Proxy.ProcedureStatementDto, ProcedureStatementStore, ResponseType> {

    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    protected storeEntityIdType = ProcedureStatementId;

    protected entityDtoSelector = (response: ResponseType) => {
        return response.procedureStatementDto;
    };

    protected applyToStoreCore(target: ProcedureStatementStore, response: any) {
        const dto = this.entityDtoSelector(response);
        if (dto != null) {
            target.id = dto.id;
            target.procedureAssignment = this.getProcedureAssignment(dto);

            target.historyItems = State.observable(this.getHistoryItems(dto.historyItems));
            target.currentItemIndex = target.historyItems.length - 1;
            target.extensionData = dto.extensionData;
        }
    }

    private getHistoryItems(dtoArray: Proxy.ProcedureStatementHistoryItemDto[]): ProcedureStatementHistoryItem[] {
        return dtoArray.map(dto => this.getHistoryItem(dto)).sort((a, b) => a.versionNumber - b.versionNumber);
    }

    private getHistoryItem(dto: Proxy.ProcedureStatementHistoryItemDto): ProcedureStatementHistoryItem {
        const returnItem = new ProcedureStatementHistoryItem();

        returnItem.description = dto.description;
        returnItem.performedAt = dto.performedAt;
        returnItem.historyItemDetail = this.getHistoryItemDetail(dto.historyItemDetail);
        returnItem.versionNumber = dto.versionNumber;
        returnItem.index = dto.index;
        returnItem.createdAt = dto.createdAt;
        returnItem.extensionData = dto.extensionData;

        return returnItem;
    }

    private getHistoryItemDetail(dto: Proxy.MedicalConditionHistoryItemDetailDto): MedicalConditionHistoryItemDetail {
        const returnItem = new MedicalConditionHistoryItemDetail();

        returnItem.asserter = dto.asserter;
        returnItem.asserterType = dto.asserterType;
        returnItem.notes = dto.notes;
        returnItem.verifier = dto.verifier;

        return returnItem;
    }

    private getProcedureAssignment(dto: Proxy.ProcedureStatementDto) {
        let procedureAssignment;

        if (dto.procedureAssignment instanceof Proxy.ReferencedMedicalServiceAssignmentDto) {
            procedureAssignment = new ReferencedMedicalServiceAssignment(dto.procedureAssignment.medicalServiceId);
        } else if (dto.procedureAssignment instanceof Proxy.CustomProcedureAssignmentDto) {
            procedureAssignment = new CustomProcedureAssignment(dto.procedureAssignment.name);
        }

        return procedureAssignment;
    }
}