import React from "react";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import ServiceRequestState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestState.g";

const ServiceRequestStateSelectBox: React.FC<IUniversalEnumSelectorBaseProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumOrigin="server"
        enumName={"ServiceRequestState"}
        enumType={ServiceRequestState}
        displayMode="selectBox"
    />
);

export default ServiceRequestStateSelectBox;