import Flex from "@CommonControls/Flex";
import StaticResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import SettlementTextbox from "@HisPlatform/BoundedContexts/CommonReferenceData/Components/Controls/SettlementTextbox";
import CountryId from "@Primitives/CountryId.g";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import { useFormEntityIdField, useFormTextField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { getCompositeArrayFields, getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import { useFormLayoutContext } from "@Toolkit/FormEngine/Panels/FormLayoutContext";
import { ICustomBlockRegistryItem, IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React from "react";
import { useEffect, useState } from "react";

interface IPatientBirthPlaceFormCustomBlockProps extends IFormCustomBlockComponentProps {
}

function _PatientBirthPlaceFormCustomBlock(props: IPatientBirthPlaceFormCustomBlockProps) {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext.compositeDataReferences?.join(".");

    const fields = getCompositeArrayFields(props.form.data.Content, compositeFieldName);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        addFieldsForPatientBirthPlace(props.form.data.Content, fields, compositeFieldName);
        setIsLoaded(true);
    }, []);

    return isLoaded && (
        <PatientBirthPlaceFormCustomBlockCore {...props} />
    );
}

const PatientBirthPlaceFormCustomBlockCore = State.observer((props: IPatientBirthPlaceFormCustomBlockProps) => {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext.compositeDataReferences?.join(".");

    const [birthPlace, setBirthPlace] = useFormTextField(props.form.data.Content, isNullOrEmptyString(compositeFieldName) ? "BirthPlace" : compositeFieldName + ".BirthPlace");
    const [birthCountryId, setBirthCountryId] = useFormEntityIdField<CountryId>(props.form.data.Content, isNullOrEmptyString(compositeFieldName) ? "BirthCountryId" : compositeFieldName + ".BirthCountryId", CountryId);

    return (
        <Flex.Item xs={12}>
            <SettlementTextbox
                label={StaticResources.PatientRegister.PatientBaseData.Label.BirthPlace}
                value={birthPlace}
                onChange={setBirthPlace}
                countryId={birthCountryId}
                propertyIdentifier="BirthPlace"
                automationId="BirthPlace"
                capitalize
            />
        </Flex.Item>
    );
});

export function addFieldsForPatientBirthPlace(formFields: FormFieldDataBase[], array: IObservableArray<FormFieldDataBase>, compositeFieldName: string) {
    State.runInAction(() => {
        const birthPlaceField = getField<TextFormFieldData>(formFields, isNullOrEmptyString(compositeFieldName) ? "BirthPlace" : compositeFieldName + ".BirthPlace");
        if (!birthPlaceField) {
            const newBirthPlaceField = new TextFormFieldData("BirthPlace", false, true, null);

            if (array) {
                array.push(newBirthPlaceField);
            } else {
                formFields.push(newBirthPlaceField);
            }
        }
    });
}

const PatientBirthPlaceFormCustomBlock = connect(_PatientBirthPlaceFormCustomBlock);

export const PatientBirthPlaceRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Patient birth place",
    dataElementsFactory: () => [
    ],
    componentType: PatientBirthPlaceFormCustomBlock
};

export default PatientBirthPlaceFormCustomBlock;
