import React from "react";
import { IDataGridFooterProps } from "@CommonControls/DataGrid/IDataGridProps";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import Styles from "./DataGrid.less";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";

interface IDataGridCompactFooterDependencies {
    toolkitLocalizationService: IToolkitLocalizationService;
}

type IDataGridCompactFooterProps = IDataGridFooterProps & { _dependencies?: IDataGridCompactFooterDependencies };


const DataGridCompactFooter: React.FC<IDataGridCompactFooterProps> = props => (
    <tr>
        <td colSpan={props.colSpan} className={combineClasses(Styles.defaultFooter, Styles.compactSize)}>
            <Popper tooltipContent={formatString(props._dependencies.toolkitLocalizationService.staticResources.dataGrid.numberOfRows, props.totalRowCount)}>
                {props.pager}
            </Popper>
        </td>
    </tr>
);

export default connect(
    DataGridCompactFooter,
    new DependencyAdapter<IDataGridCompactFooterProps, IDataGridCompactFooterDependencies>(c => ({
        toolkitLocalizationService: c.resolve("IToolkitLocalizationService"),
    }))
);