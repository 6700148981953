import * as Proxy from "@HisPlatform/BoundedContexts/Finance/Api/Proxy.g";
import InsurerOrganization from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/InsurerOrganizations/InsurerOrganization";

export function getUpdateInsurerOrganizationDto(insurerOrganization: InsurerOrganization): Proxy.UpdateInsurerOrganizationControllerDto {
    return new Proxy.UpdateInsurerOrganizationControllerDto({
        address: new Proxy.AddressDto({
            addressLine: insurerOrganization.address.addressLine,
            countryId: insurerOrganization.address.countryId,
            settlement: insurerOrganization.address.settlement,
            zipCode: insurerOrganization.address.zipCode
        }),
        code: insurerOrganization.code,
        displayCode: insurerOrganization.displayCode,
        insurerOrganizationId: insurerOrganization.id,
        insurerOrganizationType: insurerOrganization.insurerOrganizationTypeId,
        isActive: insurerOrganization.isActive,
        name: insurerOrganization.name,
        rowVersion: insurerOrganization.rowVersion
    });
}

export function getCreateInsurerOrganizationDto(insurerOrganization: InsurerOrganization): Proxy.CreateInsurerOrganizationControllerDto {
    return new Proxy.CreateInsurerOrganizationControllerDto({
        address: new Proxy.AddressDto({
            addressLine: insurerOrganization.address.addressLine,
            countryId: insurerOrganization.address.countryId,
            settlement: insurerOrganization.address.settlement,
            zipCode: insurerOrganization.address.zipCode
        }),
        code: insurerOrganization.code,
        displayCode: insurerOrganization.displayCode,
        insurerOrganizationType: insurerOrganization.insurerOrganizationTypeId,
        isActive: insurerOrganization.isActive,
        name: insurerOrganization.name
    });
}
