import React from "react";
import * as Ui from "@CommonControls";
import Button from "@CommonControls/Button";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import StaticWebAppResources from "@StaticResources";
import UserSelectBox from "@HisPlatform/BoundedContexts/UserManagement/Components/Controls/Users/UserSelectBox";
import UserId from "@Primitives/UserId.g";
import * as Styles from "./CopyPointOfCareRolesModal.less";
import PointOfCareRoles from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Actions/PointOfCareRoles";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { iconNameType } from "@CommonControls/Icon";
import { getIconNameByHealthcareProfessions } from "@HisPlatform/BoundedContexts/Organization/Components/Helpers/IconTypeNameHelper";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import UserHeader from "@HisPlatform/BoundedContexts/UserManagement/Components/Controls/UserHeader";
import RoleId from "@Primitives/RoleId.g";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import PointOfCare from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/PointOfCare";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface ICopyPointOFCareRolesModalViewDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface ICopyPointOFCareRolesModalViewProps {
    _dependencies?: ICopyPointOFCareRolesModalViewDependencies;
    closeModal: () => void;
    setUserId: (id: UserId) => void;
    save: () => void;
    selectedUserId: UserId;
    user: User;
    pointOfCareMap: ReferenceDataStore<PointOfCareId, PointOfCare>;
    pointOfCareRoles: PointOfCareRoles[];
    practitioner: PractitionerStore;
}


const CopyPointOFCareRolesModalView: React.SFC<ICopyPointOFCareRolesModalViewProps> = props => {

    const renderHeader = () => {
        return (
            <UserHeader user={props.user} practitioner={props.practitioner} />
        );
    };

    const renderGrid = () => {
        return (
            <Ui.ScrollView height={250}>
                <Ui.DataGrid dataSource={props.pointOfCareRoles || []} rowHeight={40} automationId="copyPointOfCareRolesModalView_dataGrid">
                    <DataGridColumn
                        id="name"
                        key={0}
                        dataGetter={"id"}
                        onRenderCellValue={renderPointOfCare}
                        header={StaticUserManagementResources.UserManagementPanel.CopyRolePanel.PointOfCare}
                        width="60px"
                        leftPadding="small"
                        automationId={"PointOfCare".split(".").pop()}
                    />
                    <DataGridColumn
                        id="roles"
                        key={1}
                        dataGetter={"roleIds"}
                        onRenderCellValue={renderRoles}
                        header={StaticUserManagementResources.UserManagementPanel.CopyRolePanel.Roles}
                        automationId={"Roles".split(".").pop()}
                        width="60px"
                        leftPadding="small"
                    />
                </Ui.DataGrid>
            </Ui.ScrollView>
        );
    };
    const renderPointOfCare = (value: PointOfCareId) => {
        const pointOfCare = props.pointOfCareMap.get(value);
        const iconName: iconNameType = getIconNameByHealthcareProfessions(pointOfCare.healthcareProfessionIds.map(props._dependencies.organizationReferenceDataStore.healthCareProfession.get));
        const displayValue = <><b>{pointOfCare.shorthand}</b>{` - ${pointOfCare.name}`}</>;
        return (
            <>
                {iconName &&
                    <Ui.Icon
                        iconName={iconName}
                        style={{ marginRight: 5 }}
                    />}
                {displayValue}
            </>
        );
    };

    const renderRoles = (value: RoleId[]) => {
        const roles = value.map(item => props.pointOfCareMap.get(item));
        return roles.map((item, key) => (<div key={key}>{item?.name}<br /></div>));
    };

    return (
        <Ui.Modal
            isOpen
            title={StaticUserManagementResources.UserManagementPanel.CopyRolePanel.Title}
            closeOnOutsideClick={false}
            renderContextHeader={renderHeader}
            size="compact"
        >
            <Ui.Modal.Body>
                <UserSelectBox onChange={props.setUserId}
                    value={props.selectedUserId}
                    label={StaticUserManagementResources.UserManagementPanel.CopyRolePanel.User}
                    clearable
                    orderItems
                    excludedIds={[props.user?.id]}
                    excludeUsersWithoutPractitioners
                    className={Styles.userSelectBoxMargin}
                    automationId="userSelectBox"
                />
                {renderGrid()}
            </Ui.Modal.Body>
            <Ui.Modal.Footer>
                <Ui.Flex xsJustify="between" verticalSpacing="none">
                    <Ui.Flex.Item>
                        <Button text={StaticWebAppResources.Common.Button.Cancel}
                            onClick={props.closeModal}
                            visualStyle="link"
                            automationId="closeButton"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item>
                        <Button text={StaticUserManagementResources.UserManagementPanel.CopyRolePanel.Save}
                            visualStyle="primary"
                            onClick={props.save}
                            disabled={!props.selectedUserId}
                            automationId="saveButton"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.Modal.Footer>
        </Ui.Modal>
    );
};

export default connect(
    CopyPointOFCareRolesModalView,
    new DependencyAdapter<ICopyPointOFCareRolesModalViewProps, ICopyPointOFCareRolesModalViewDependencies>(container => {
        return {
            organizationReferenceDataStore: container.resolve<OrganizationReferenceDataStore>("OrganizationReferenceDataStore")
        };
    })
);
