import React from "react";
import * as Ui from "@CommonControls";
import PatientDocumentsDataSource from "./PatientDocumentsDataSource";

interface IPatientDocumentsDataSourceSelectorProps {
    value: PatientDocumentsDataSource,
    onChange: (newValue: PatientDocumentsDataSource) => void;
    label: string
}

export default class PatientDocumentsDataSourceSelector extends React.Component<IPatientDocumentsDataSourceSelectorProps> {
    public render() {
        return (
            <Ui.UniversalEnumSelector
                enumName={"PatientDocumentsDataSource"}
                enumOrigin={"client"}
                value={this.props.value}
                onChange={this.props.onChange}
                enumType={PatientDocumentsDataSource}
                displayMode={"groupedRadioButtons"}
                label={this.props.label}
                automationId="patientDocumentsDataSourceSelector"
            />
        );
    }
}