import DocumentSnippetSelectorDialogApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Dialog/DocumentSnippetSelectorDialogApiAdapter";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IDocumentSnippetSelectorDialogParams, IDocumentSnippetSelectorDialogResult } from "./DocumentSnippetSelectorDialogParams";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import IDocumentSnippet from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentSnippet/IDocumentSnippet";
import DocumentSnippetSelectorDialogView from "./DocumentSnippetSelectorDialogView";
import DocumentSnippetId from "@Primitives/DocumentSnippetId.g";
import DocumentContentStore, { IDocumentContentStore } from "@CommonControls/DocumentEditor/DocumentContentStore";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessModal from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessModal";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IDocumentSnippetSelectorDialogDependencies {
    documentSnippetApiAdapter: DocumentSnippetSelectorDialogApiAdapter;
}

interface IDocumentSnippetSelectorDialogProps extends IModalComponentParams<IDocumentSnippetSelectorDialogResult>, IDocumentSnippetSelectorDialogParams {
    _dependencies?: IDocumentSnippetSelectorDialogDependencies;
}

/** @screen */
@State.observer
class DocumentSnippetSelectorDialog extends React.Component<IDocumentSnippetSelectorDialogProps> {
    public static defaultProps: Partial<IDocumentSnippetSelectorDialogProps> = {
        displayMode: "inline"
    };
    private get dependencies() { return this.props._dependencies; }
    private get apiAdapter() { return this.dependencies.documentSnippetApiAdapter; }

    private snippetContents = new Map<string, IDocumentContentStore>();

    @State.observable.ref private documentSnippets: IDocumentSnippet[] = null;
    @State.observable.ref private selected: IDocumentSnippet = null;
    @State.observable.ref private selectedContent: IDocumentContentStore = null;

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);
    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.action.bound
    public setSelected(row: IDocumentSnippet) {
        this.selected = row;
        if (!isNullOrUndefined(row)) {
            dispatchAsyncErrors(this.rowSelectedAsync(row), this);
        }
    }

    @State.action.bound
    private async loadAsync() {
        if (this.props.documentSnippetCategoryId) {
            const result = await this.apiAdapter.getDocumentSnippetsByDocumentSnippetCategoryIdAsync(this.props.documentSnippetCategoryId);
            if (result.operationWasSuccessful) {
                State.runInAction(() =>
                    this.documentSnippets = (result.value)
                );
            }
        }
    }

    @State.action.bound
    private async loadSnippetContentAsync(id: DocumentSnippetId) {
        const cachedContent = this.snippetContents.get(id.value);

        if (cachedContent) {
            return cachedContent;
        } else {
            const result = await this.apiAdapter.getDocumentSnippetContentByIdAsync(id);
            if (result.operationWasSuccessful) {
                const newStore = DocumentContentStore.create();
                newStore.setContent(result.value);
                this.snippetContents.set(id.value, newStore);
                return newStore;
            }
        }
        return null;
    }

    @State.bound
    public async rowSelectedAsync(row: IDocumentSnippet) {
        const content = await this.loadSnippetContentAsync(row.id);
        State.runInAction(() => {
            this.selectedContent = content;
        });
    }

    @State.bound
    private async onSaveAsync() {
        if (this.selectedContent) {
            const isSelected = await this.selectedContent.isTextSelectedAsync();
            const result: IDocumentSnippetSelectorDialogResult = {
                selected: isSelected ?
                    await this.selectedContent.getSelectedContentAsDocumentFragmentAsync() :
                    await this.selectedContent.getContentAsDocumentFragmentAsync()
            };
            this.props.onClose(result);
        } else {
            this.props.onClose(null);
        }
    }

    @State.bound
    private onClose() {
        this.props.onClose(null);
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessModal title={StaticDocumentManagementResources.DocumentSnippetSelectorModal.Title} />;
        }

        return (
            <DocumentSnippetSelectorDialogView
                documentSnippets={this.documentSnippets}
                onSaveAsync={this.onSaveAsync}
                onClose={this.onClose}
                displayMode={this.props.displayMode}
                selected={this.selected}
                setSelected={this.setSelected}
                content={this.selectedContent}
            />
        );
    }
}

export default connect(
    DocumentSnippetSelectorDialog,
    new DependencyAdapter<IDocumentSnippetSelectorDialogProps, IDocumentSnippetSelectorDialogDependencies>(container => {
        return {
            documentSnippetApiAdapter: container.resolve<DocumentSnippetSelectorDialogApiAdapter>("DocumentSnippetSelectorDialogApiAdapter")
        };
    })
);