import React from "react";
import * as Ui from "@CommonControls";
import MedicationFormulaList from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationFormulaList";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import MedicationFormulaListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationFormulaListItem";
import MedicationFormulaListItemPanel from "./MedicationFormulaListItemPanel";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import PackagingUnitSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PackagingUnitSelector/PackagingUnitSelector";
import Prescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Prescription";
import ExtendedDosageEditor from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DosageEditor/ExtendedDosageEditor";

interface IMedicationFormulaPanelProps {
    prescription: Prescription;
}


const MedicationFormulaPanel: React.FC<IMedicationFormulaPanelProps> = props => {
    const resources = StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail;
    const medicationFormula = props.prescription.medicationReferenceBase as MedicationFormulaList;

    const renderMedicationFormulaListItem = (item: MedicationFormulaListItem, index: number) =>
        <MedicationFormulaListItemPanel validOn={props.prescription.prescribedOn} medicationFormulaListItem={item} index={index} />;

    const medicationFormulaListItemFactoryAsync = () => Promise.resolve(new MedicationFormulaListItem());

    return (
        <>
            <Ui.Flex>
                <Ui.Flex.Item xs={5}>
                    <Ui.TextBox
                        value={medicationFormula.name}
                        label={resources.Labels.Name}
                        onChange={medicationFormula.setName}
                        automationId="nameTextBox"
                        propertyIdentifier="MedicationReference.Name" />
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex>
                <Ui.Flex.Item xs={12}>
                    <ListPanel<MedicationFormulaListItem>
                        title={resources.MedicationFormula.Title}
                        alwaysEdit
                        items={medicationFormula.medicationFormulaListItems}
                        renderItemEditor={renderMedicationFormulaListItem}
                        onRemoveItem={medicationFormula.removeMedicationFormulaListItem}
                        onCreateNewAsync={medicationFormulaListItemFactoryAsync}
                        noItemsMessage={resources.MedicationFormula.NoItemMessage}
                        automationId="__medicationFormulaListItemPanel"
                        propertyIdentifier="MedicationReference.MedicationFormulaListItems"
                        isCompactEmptyState
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={12}>
                    <Ui.TextBox
                        value={medicationFormula.instruction}
                        label={resources.MedicationFormula.InstructionLabel}
                        onChange={medicationFormula.setInstruction}
                        automationId="preparationTextBox"
                        multiline
                        propertyIdentifier={"MedicationReference.Instruction"} />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={4}>
                    <PackagingUnitSelector
                        value={medicationFormula.packagingUnitId}
                        onChange={medicationFormula.setPackagingUnitId}
                        label={resources.Labels.PackagingUnit}
                        propertyIdentifier="MedicationReference.PackagingUnitId"
                        automationId="packagingUnitSelector"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={4}>
                    <Ui.NumberBox
                        value={medicationFormula.amount}
                        onChange={medicationFormula.setAmount}
                        label={resources.Labels.Amount}
                        propertyIdentifier="MedicationReference.Amount"
                        automationId="amount"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item style={{ alignSelf: "center" }} xs={4} >
                    <Ui.CheckBox
                        value={!props.prescription.isSubstitubable}
                        onChange={props.prescription.setIsSubstitubable}
                        label={resources.Labels.IsSubstitubable}
                        labelPosition="right"
                        automationId="isSubstitubableCheckBox" />
                </Ui.Flex.Item>
                {!props.prescription.isSubstitubable &&
                    <Ui.Flex.Item xs={12}>
                        <Ui.TextBox
                            value={props.prescription.insubstitutableReason}
                            onChange={props.prescription.setInsubstitutableReason}
                            label={resources.Labels.InsubstitutableReason}
                            isReadOnly={props.prescription.isSubstitubable}
                            propertyIdentifier="InsubstitutableReason"
                            automationId="insubstitutableReasonTextBox" />
                    </Ui.Flex.Item>}
            </Ui.Flex>
            <Ui.GroupBox title={resources.GroupTitles.Dosage}>
                <ExtendedDosageEditor
                    dosage={medicationFormula.dosage}
                    setDosage={medicationFormula.setDosage}
                    inSubstitutableReason={props.prescription.insubstitutableReason}
                    setInSubstitutableReason={props.prescription.setInsubstitutableReason}
                    instruction={props.prescription.instruction}
                    setInstruction={props.prescription.setInstruction}
                    isSubstitutable={props.prescription.isSubstitubable}
                    setIsSubstitutable={props.prescription.setIsSubstitubable}
                    showReason={false}
                    showEquippedAndTextualDosage={false}
                />
            </Ui.GroupBox>
        </>
    );
};
export default State.observer(MedicationFormulaPanel);