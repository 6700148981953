import * as Inversify from "inversify";
import getDecorators from "inversify-inject-decorators";


const DependencyContainer = new Inversify.Container({
    skipBaseClassChecks: true
});

const { lazyInject: lazyResolve } = getDecorators(DependencyContainer);

export { lazyResolve };
export default DependencyContainer;
export type Container = Inversify.Container;