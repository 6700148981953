import State from "@Toolkit/ReactClient/Common/StateManaging";
import Property from "./Property";
import PropertyDefinitionId from "@Primitives/PropertyDefinitionId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import {isInInterval} from "@Toolkit/CommonWeb/TemporalData/TemporalUtils";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import IPropertyValueVersion from "@HisPlatform/Model/DomainModel/Common/IPropertyValueVersion";
import PropertyValueVersion from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/PropertyValueVersion";

export default abstract class ScalarProperty<T = any> extends Property {
    @State.observable.ref private valueVersions: Array<IPropertyValueVersion<T>>;
    @State.observable.ref public isVisible: boolean = true;

    public get versions() {
        return this.valueVersions;
    }

    constructor(
        valueVersions: Array<IPropertyValueVersion<T>>,
        name: string,
        definitionId: PropertyDefinitionId,
        isTemporal: boolean,
        public readonly defaultValue: T
    ) {
        super(definitionId, name, isTemporal);
        this.valueVersions = valueVersions;
    }

    public propertyNamesExcludedFromDirtyCheck = [
        "isVisible",
        "definitionId"
    ];

    public getVersion(validOn?: LocalDate) {
        if (this.isTemporal) {
            if (isNullOrUndefined(validOn)) {
                throw new Error(`ScalarProperty.getValue: validOn must be specified when the property is temporal.`);
            }
            return this.getVersionCore(validOn);
        } else {
            if (!isNullOrUndefined(validOn)) {
                throw new Error(`ScalarProperty.getValue: validOn must NOT be specified when the property is temporal.`);
            }
            return this.getVersionCore(LocalDate.today());
        }
    }

    public getValue(validOn?: LocalDate) {
        if (this.valueVersions?.length > 0) {
            return this.getVersion(validOn)?.value;
        }
        return this.defaultValue;
    }

    private getVersionCore(validOn: LocalDate) {
        return this.valueVersions.find(version => isInInterval(new LocalDateRange(version.validFrom, version.validTo), validOn));
    }

    @State.action.bound
    public replaceAllVersion(valueVersions: Array<IPropertyValueVersion<T>>) {
        this.valueVersions = valueVersions;
    }

    @State.action.bound
    public replaceVersion(value: T, validOn?: LocalDate) {
        if (this.valueVersions?.length > 0) {
            const versionToReplace = this.getVersion(validOn);
            this.valueVersions = [
                ...this.valueVersions.map(version => {
                    if (version === versionToReplace) {
                        return new PropertyValueVersion(value, version.validFrom, version.validTo);
                    } else {
                        return version;
                    }
                })
            ];
        } else {
            this.valueVersions = [new PropertyValueVersion(value, null, null)];
        }
    }
}
