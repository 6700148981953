import React from "react";
import * as Ui from "@CommonControls";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import MedicationEquipmentClassificationTreeGrid from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationEquipmentClassificationTreeGrid/MedicationEquipmentClassificationTreeGrid";
import MedicationEquipmentClassificationTreeNode from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationEquipmentClassificationTreeGrid/MedicationEquipmentClassificationTreeNode";
import SubsidyClaimTypeSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/SubsidyClaimTypeSelector/SubsidyClaimTypeSelector";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import { PrescriptionType } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationSelectorDialog/MedicationSelectorDialog";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import { ExtensibleEnumSelectBox } from "@HisPlatformControls";
import MedicationTypeId from "@Primitives/MedicationTypeId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IExtendedMedicationFilterProps {
    generalSearch: string;
    onGeneralSearchChange: (newValue: string) => void;
    onIsTypeGySeChange: (newValue: boolean) => void;
    medicationEquipmentClassificationStructure: MedicationEquipmentClassificationTreeNode[];
    setMedicationEquipmentClassificationStructure: (newValue: MedicationEquipmentClassificationTreeNode[]) => void;
    onNodeCheck: (node: MedicationEquipmentClassificationTreeNode) => void;
    isMedicationEquipmentClassificationTreeGridOpen: boolean;
    setIsMedicationEquipmentClassificationTreeGridOpen: (newValue: boolean) => void;
    medicationSubsidyClaimTypeId: MedicationSubsidyClaimTypeId;
    onMedicationSubsidyClaimTypeIdChange: (newValue: MedicationSubsidyClaimTypeId) => void;
    prescriptionType?: PrescriptionType;
    medicationTypeStore?: ExtensibleEnumStore;
    setMedicationType?: (medicationTypeId: MedicationTypeId) => void;
    MedicationType?: MedicationTypeId;
}

export default class ExtendedMedicationFilter extends React.Component<IExtendedMedicationFilterProps> {
    public componentDidUpdate(prevProps: IExtendedMedicationFilterProps) {
        if (this.props.prescriptionType !== prevProps.prescriptionType) {
            this.props.onIsTypeGySeChange(this.props.prescriptionType === PrescriptionType.Equipment);
        }
    }

    public componentDidMount() {
        this.props.onIsTypeGySeChange(this.props.prescriptionType === PrescriptionType.Equipment);
    }
    public render() {
        return (
            <>
                <Ui.Flex>
                    <Ui.Flex.Item xs={!isNullOrUndefined(this.props.prescriptionType) ? 4 : 8}>
                        <Ui.TextBox
                            label={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Labels.Search}
                            value={this.props.generalSearch}
                            onChange={this.props.onGeneralSearchChange}
                            automationId="generalSearch"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <SubsidyClaimTypeSelector
                            label={StaticHunSocialSecurityMedicationRequestResources.Medication.MedicationSubsidyClaimType}
                            value={this.props.medicationSubsidyClaimTypeId}
                            onChange={this.props.onMedicationSubsidyClaimTypeIdChange}
                            automationId="subsidyClaimTypeFilterSelectBox"
                        />
                    </Ui.Flex.Item>

                    {this.props.prescriptionType === PrescriptionType.Equipment &&
                        <Ui.Flex.Item xs={4}>
                            <MedicationEquipmentClassificationTreeGrid
                                onCheckNode={this.props.onNodeCheck}
                                isOpen={this.props.isMedicationEquipmentClassificationTreeGridOpen}
                                setIsOpen={this.props.setIsMedicationEquipmentClassificationTreeGridOpen}
                                medicationEquipmentClassificationStructure={this.props.medicationEquipmentClassificationStructure}
                                setMedicationEquipmentClassificationStructure={this.props.setMedicationEquipmentClassificationStructure}
                            />

                        </Ui.Flex.Item>
                    }
                    {
                        this.props.prescriptionType === PrescriptionType.Medication &&
                        <Ui.Flex.Item xs={4}>
                            <ExtensibleEnumSelectBox
                                value={this.props.MedicationType}
                                onChange={this.props.setMedicationType}
                                extensibleEnumStore={this.props.medicationTypeStore}
                                excludedValues={["S"]}
                                label={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Labels.Type}
                            />
                        </Ui.Flex.Item>

                    }
                </Ui.Flex>
            </>
        );
    }
}