import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

export default class UsageDetail {
    @State.observable.ref public endOfUse: LocalDate;
    @State.observable.ref public startOfUse: LocalDate;
    
    constructor(endOfUse: LocalDate, startOfUse: LocalDate) {
        this.endOfUse = endOfUse;
        this.startOfUse = startOfUse;
    }

    @State.action.bound
    public setEndOfUse(newValue: LocalDate) {
        this.endOfUse = newValue;
    }

    @State.action.bound
    public setStartOfUse(newValue: LocalDate) {
        this.startOfUse = newValue;
    }
}
