import React, { useContext, useMemo, useEffect, useState, useCallback } from "react";
import PanelStoreBase from "./PanelStoreBase";
import { useErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import ContainerContext from "@Toolkit/ReactClient/Components/DependencyInjection/ContainerContext";
import { ConstructorType } from "@Toolkit/CommonWeb/Reflection/Reflection";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ILoadablePanelStore from "./ILoadablePanelStore";
import ApiBusinessError from "@Toolkit/CommonWeb/ApiAdapter/ApiBusinessError";
import UnauthorizedOperationBusinessError from "@Toolkit/CommonWeb/Model/UnauthorizedOperationBusinessError";
import LoadingContext from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingContext";
import ModalServiceReactContext from "@Toolkit/ReactClient/Components/ModalService/ModalServiceContext";
import ModalContextStore from "@Toolkit/ReactClient/Components/ModalService/ModalContextStore";
import { useActionDispatcher } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";

const panelStoreProviderGlobalSettings = {
    useActionDispatcherHook: useActionDispatcher
};

export {
    panelStoreProviderGlobalSettings
};

export interface IStoreProviderSettings {
    unauthorizedPageTitle?: string;
}

export default function createPanelStoreProvider<TStore extends PanelStoreBase<TProps>, TProps = unknown>(
    storeClass: ConstructorType<TStore>,
    settingsFactory?: (props: TProps) => IStoreProviderSettings,
    renderFunc: (store: TStore, child: React.ReactNode) => React.ReactNode = (_, c) => c
) {


    const ContextComponent = React.createContext<TStore | null>(null);
    const useStore = () => {
        const store = useContext(ContextComponent);
        if (store == null) {
            throw new Error("PanelStore not found. Maybe this component is bound to another PanelStore or the screen is not exported with provide...Store wrapper.");
        }
        return store;
    };

    function StoreProvider(props: React.PropsWithChildren<TProps> & { onUnauthorized?: () => void }) {
        const dispatchError = useErrorDispatcher();
        const container = useContext(ContainerContext) as IContainerService;
        const loadingBoundaryStore = useContext(LoadingContext);
        const modalService = useContext(ModalServiceReactContext) as ModalContextStore;
        const actionDispatcher = panelStoreProviderGlobalSettings.useActionDispatcherHook();
        const [arePropsInitialized, setPropsInitialized] = useState(false);

        const store = useMemo(() => {
            const subContainer = container.createScope(c => {
                c.bind(storeClass).toSelf();
            });

            const storeRet = subContainer.resolve<TStore>(storeClass);
            storeRet.setContextualServices(
                dispatchError,
                loadingBoundaryStore,
                modalService,
                actionDispatcher
            );
            return storeRet;

        }, []);

        useEffect(State.action(() => {

            Object.keys(props).forEach(key => {
                if (key === "children") { return; }

                const newValue = props[key];

                if (key in store.props) {
                    if (store.props[key] !== newValue) {
                        store.props[key] = newValue;
                    }
                } else {
                    const newValueIsFunction = typeof (newValue) === "function";
                    State.extendObservable(store.props, { [key]: newValue }, newValueIsFunction ? null : { [key]: State.observable.ref }, { deep: false });
                }
            });

            setPropsInitialized(true);

        }), [props]);

        useEffect(() => {
            if (!arePropsInitialized) {
                return;
            }

            if ("initialize" in store) {
                (store as unknown as ILoadablePanelStore).initialize();
            }
        }, [arePropsInitialized]);

        const loadAsyncDependencies = "getReloadTriggerProps" in store ? (store as unknown as ILoadablePanelStore).getReloadTriggerProps?.(props) : [];
        const errorDispatcher = useErrorDispatcher();
        useEffect(() => {

            if (!arePropsInitialized) {
                return;
            }

            if ("loadAsync" in store) {

                const loadableStore = store as unknown as ILoadablePanelStore;
                loadableStore.loadAsync().catch(err => {
                    if (err instanceof ApiBusinessError && err.error instanceof UnauthorizedOperationBusinessError) {
                        props.onUnauthorized();
                    } else {
                        errorDispatcher(err);
                    }
                });
            }
        }, [...loadAsyncDependencies, arePropsInitialized]);

        useEffect(() => () => store.unload(), []);

        return (
            <ContextComponent.Provider value={store}>
                {arePropsInitialized && renderFunc(store, props.children)}
            </ContextComponent.Provider>
        );
    }


    function provideStore<TProps2>(TargetComponent: React.ComponentType<TProps2>) {

        const TargetObserverComponent = State.observer(TargetComponent);
        return React.forwardRef((props: TProps2, ref) => {

            const [isUnauthorized, setUnauthorized] = useState(false);
            const onUnauthorized = useCallback(() => setUnauthorized(true), [setUnauthorized]);

            if (isUnauthorized) {
                return (
                    <UnauthorizedAccessPageBox title={settingsFactory?.(props as any).unauthorizedPageTitle ?? ""} />
                );
            }

            return (
                <StoreProvider {...props as any} onUnauthorized={onUnauthorized}>
                    <TargetObserverComponent
                        {...props}
                        ref={ref}
                    />
                </StoreProvider>
            );
        });
    }

    return {
        ContextComponent,
        useStore,
        StoreProvider,
        provideStore
    };
}
