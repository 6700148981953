import React from "react";
import Di from "@Di";
import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import UserColumn from "@HisPlatformControls/DataGridColumns/UserColumn";

@Di.injectable()
export default class UserManagementBoundedContext implements IBoundedContext {

    public initializeAsync(): Promise<void> {
        return Promise.resolve();
    }

    public configureNData?(configurator: INDataAttributeConfigurator): void {
        configurator.registerColumn("UserId", <UserColumn />);
    }
}