import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IMaritalStatusSelectBoxDependencies {
    commonReferenceDataDataStore: CommonReferenceDataDataStore;
}

interface IMaritalStatusSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IMaritalStatusSelectBoxDependencies;
}

@State.observer
class MaritalStatusSelectBox extends React.Component<IMaritalStatusSelectBoxProps> {
    private get dependencies() { return this.props._dependencies; }
    private get extensibleEnumStore() { return this.dependencies.commonReferenceDataDataStore.maritalStatus; }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    MaritalStatusSelectBox,
    new DependencyAdapter<IMaritalStatusSelectBoxProps, IMaritalStatusSelectBoxDependencies>( (containerService) => {
        return {
            commonReferenceDataDataStore: containerService.resolve("CommonReferenceDataDataStore")
        };
    })
);
