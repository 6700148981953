import CodeEditorStore from "@CommonControls/CodeEditor/CodeEditorStore";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import Di from "@Di";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import Guid from "@Toolkit/CommonWeb/Guid";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import FormLogic from "@Toolkit/FormEngine/Model/FormLogic";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IFormLogicEditorDialogParams, IFormLogicEditorDialogResult } from "./FormLogicEditorDialogParams";
import FormLogicListItemStore from "./FormLogicListItemStore";

@Di.injectable()
export default class FormLogicEditorDialogStore extends PanelStoreBase<IModalComponentParams<IFormLogicEditorDialogResult> & IFormLogicEditorDialogParams> {

    @State.observable.ref public formLogics = State.createObservableShallowArray<FormLogicListItemStore>([]);
    @State.observable.ref public codeEditorStore: CodeEditorStore = new CodeEditorStore();
    @State.observable.ref public selectedRow: FormLogicListItemStore = null;
    public dataSource = new InMemoryDataGridDataSource(() => this.formLogics);

    @State.action.bound
    public readonly initialize = () => {
        this.props.formLogics.forEach((flt, idx) => {
            this.formLogics.push(new FormLogicListItemStore(
                Guid.newGuid(),
                idx + 1,
                new FormLogic(flt.logicName, Base64Converter.toString(flt.content))
            ));
        });
    };

    @State.action.bound
    public select(id: string) {
        return () => {
            if (this.selectedRow) {
                this.selectedRow.setFormLogicContent(this.codeEditorStore.getValue());
            }
            this.selectedRow = this.dataSource.data.items.find(f => f.id === id);
            if (this.selectedRow) {
                this.codeEditorStore.setValue(this.selectedRow.formLogic.content, "javascript");
            }
        };
    }

    @State.action.bound
    public deleteSelectedRow() {
        this.formLogics.remove(this.selectedRow);
        this.onBack();
    }

    @State.action.bound
    public onBack() {
        this.select(null)();
    }

    @State.action.bound
    public createNewFormLogic() {
        const orders = arrayIsNullOrEmpty(this.formLogics) ? [0] : this.formLogics.map(wid => wid.order);
        const newFormLogic = new FormLogicListItemStore(
            Guid.newGuid(),
            Math.max(...orders) + 1,
            new FormLogic("", ""));
        this.formLogics.push(newFormLogic);
    }

    @State.bound
    public save() {
        if (this.selectedRow) {
            this.selectedRow.setFormLogicContent(this.codeEditorStore.getValue());
        }
        const sortedList = this.dataSource.data.items.sort((fid, fid2) => fid.order - fid2.order);
        this.props.onClose({
            formLogics: sortedList.map(flt => new FormLogic(flt.formLogic.logicName, Base64Converter.fromString(flt.formLogic.content))),
        });
    }

}