import React from "react";
import ICardioFrameApiAdapter from "./ICardioFrameApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PatientId from "@Primitives/PatientId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import config from "@Config";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import IMessagingSubscriptionManager from "@Toolkit/CommonWeb/CommandProcessing/Definition/IMessagingSubscriptionManager";
import IMessagingSubscription from "@Toolkit/CommonWeb/CommandProcessing/Definition/IMessagingSubscription";
import CreatePrescriptionCommand from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/OperationProcessing/CreatePrescriptionCommand";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";
import CreateServiceRequestCommand from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/UseCases/ServiceRequestManagement/CreateServiceRequestCommand";
import CreatePatientAppointmentCommand from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/UseCases/CreatePatientAppointmentCommand";
import SendInitializationPayloadCommand from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/ExternalDecisionSupport/Commands/SendInitializationPayloadCommand";
import IExternalDecisionSupportAccessor from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/ExternalDecisionSupport/IExternalDecisionSupportAccessor";

interface IICardioFrameDependencies {
    externalDecisionSupportAccessor: IExternalDecisionSupportAccessor;
    messagingSubscriptionManager: IMessagingSubscriptionManager;
}

interface IICardioFrameProps {
    _dependencies?: IICardioFrameDependencies;
    username: string;
    name: string;
    stampcode: string;
    mode: "patient" | "user";
    hisCareActivityId: CareActivityId;
    hisPatientId: PatientId;
    diagnosisCodes: string[];
}

type CareActivityBasedCommand =
    | CreatePrescriptionCommand
    | CreateServiceRequestCommand
    | CreatePatientAppointmentCommand;

/** @screen */
@State.observer
class ICardioFrame extends React.Component<IICardioFrameProps> {
    private _messagingSubscription?: IMessagingSubscription = null;
    private iframeRef: React.RefObject<HTMLIFrameElement> = React.createRef();

    private get messagingSubscriptionManager() { return this.props._dependencies.messagingSubscriptionManager; }

    public componentDidMount() {
        this._messagingSubscription = this.messagingSubscriptionManager.subscribeWithAccessor(() => this.iframeRef.current.contentWindow);
        this._messagingSubscription.register<CreatePrescriptionCommand>("CreatePrescriptionCommand", this.attachCareActivityData.bind(this));
        this._messagingSubscription.register<CreateServiceRequestCommand>("CreateServiceRequestCommand", this.attachCareActivityData.bind(this));
        this._messagingSubscription.register<CreatePatientAppointmentCommand>("CreatePatientAppointmentCommand", this.attachAppointmentData.bind(this));
        this._messagingSubscription.registerCallback<SendInitializationPayloadCommand>("SendInitializationPayloadCommand", this.sendInitializationPayloadAsync);
    }

    public componentWillUnmount() {
        if (!!this._messagingSubscription) {
            this._messagingSubscription.dispose();
        }
        if (this.iframeRef.current) {
            this.iframeRef.current.onload = null;
        }
    }

    @State.bound
    private async sendInitializationPayloadAsync(): Promise<void> {
        const payload = await this.props._dependencies.externalDecisionSupportAccessor.getInitializationPayloadAsync(
            this.props.hisPatientId,
            this.props.diagnosisCodes,
            null,
            this.props.mode,
            true,
            false
        );

        if (this.iframeRef.current && this.iframeRef.current.contentWindow !== null) {
            this.iframeRef.current.contentWindow.postMessage(JSON.stringify(payload), "*");
        }
    }

    private attachCareActivityData(command: CareActivityBasedCommand): void {
        command.careActivityId = this.props.hisCareActivityId;
    }

    private attachAppointmentData(command: CreatePatientAppointmentCommand): void {
        this.attachCareActivityData(command);
        command.patientId = this.props.hisPatientId;
    }

    public render() {
        return (
            <>
                <iframe
                    id={"ICardioFrame"}
                    src={config.cardioIntegration.webAddress || undefined}
                    ref={this.iframeRef}
                    style={{ width: "100%", height: "100%", border: "none" }}
                />
            </>
        );
    }
}

export default connect(
    ICardioFrame,
    new DependencyAdapter<IICardioFrameProps, IICardioFrameDependencies>(c => ({
        externalDecisionSupportAccessor: c.resolve("IExternalDecisionSupportAccessor"),
        messagingSubscriptionManager: c.resolve("IMessagingSubscriptionManager")
    }))
);