import LockingEntityStoreBase from "@Toolkit/CommonWeb/Model/LockingEntityStoreBase";
import CareActivityId from "@Primitives/CareActivityId.g";
import ConditionId from "@Primitives/ConditionId.g";
import moment from "moment";
import UserId from "@Primitives/UserId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PrescriptionId from "@Primitives/PrescriptionId.g";
import MedicationRequestCoverage from "./MedicationRequestCoverage";
import TemporaryPrescriptionPermission from "./TemporaryPrescriptionPermission";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import MedicationReferenceBase from "./MedicationReferenceBase";
import PrescriptionPractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionPractitionerRecommendation";
import PractitionerId from "@Primitives/PractitionerId.g";
import PrescriptionStatus from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/PrescriptionStatus.g";

export default class Prescription extends LockingEntityStoreBase<PrescriptionId> {
    public careActivityId: CareActivityId;
    @State.observable.ref public conditionId: ConditionId;
    public createdAt: moment.Moment;
    public createdBy: UserId;
    @State.observable.ref public instruction: string;
    @State.observable.ref public insubstitutableReason: string;
    @State.observable public isEmergency: boolean = false;
    @State.observable public isForcedPrescription: boolean = false;
    @State.observable public isSubstitubable: boolean = true;
    @State.observable.ref public notes: string;
    @State.observable.ref public usableFrom: LocalDate = LocalDate.today();
    @State.observable.ref public prescribedOn: LocalDate = LocalDate.today();
    @State.observable public suppliedMonths: number | null;
    public status: PrescriptionStatus;
    public temporaryPermission: TemporaryPrescriptionPermission = new TemporaryPrescriptionPermission();
    public medicationRequestCoverage: MedicationRequestCoverage = new MedicationRequestCoverage();
    public prescriptionPractitionerRecommendation: PrescriptionPractitionerRecommendation = new PrescriptionPractitionerRecommendation();
    @State.observable.ref public medicationReferenceBase: MedicationReferenceBase;
    @State.observable.ref public registryNumber: string;
    @State.observable.ref public digitalPrescriptionIdentifier: string;
    @State.observable.ref public prescriber: PractitionerId;
    @State.observable public approvedDesignation: boolean = false;

    public extensionData: any;
    private _dirtyChecker = new StoreDirtyChecker();

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "operationInfo",
        "approvedDesignation"
    ];

    @State.action.bound
    public setInstruction(instruction: string) {
        this.instruction = instruction;
    }
    @State.action.bound
    public setSuppliedMonths(suppliedMonths: number | null) {
        this.suppliedMonths = suppliedMonths;
    }

    @State.action.bound
    public setInsubstitutableReason(insubstitutableReason: string) {
        this.insubstitutableReason = insubstitutableReason;
    }

    @State.action.bound
    public setIsEmergency(isEmergency: boolean) {
        this.isEmergency = isEmergency;
    }

    @State.action.bound
    public setIsForcedPrescription(isForcedPrescription: boolean) {
        this.isForcedPrescription = isForcedPrescription;
    }

    @State.action.bound
    public setPrescriber(prescriber: PractitionerId) {
        this.prescriber = prescriber;
    }

    @State.action.bound
    public setIsSubstitubable(inSubstitubable: boolean) {
        this.isSubstitubable = !inSubstitubable; // negated because we display the negation of it
    }

    @State.action.bound
    public setNotes(notes: string) {
        this.notes = notes;
    }

    @State.action.bound
    public setUsableFrom(usableFrom: LocalDate) {
        this.usableFrom = usableFrom;
    }

    @State.action.bound
    public setPrescribedOn(prescribedOn: LocalDate) {
        this.prescribedOn = prescribedOn;
    }

    @State.action.bound
    public setConditionId(conditionId: ConditionId) {
        this.conditionId = conditionId;
    }

    @State.action.bound
    public setMedicationReferenceBase(medicationReferenceBase: MedicationReferenceBase) {
        this.medicationReferenceBase = medicationReferenceBase;
    }

    @State.action.bound
    public setRegistryNumber(registryNumber: string) {
        this.registryNumber = registryNumber;
    }

    @State.action.bound
    public setApprovedDesignation(value: boolean) {
        this.approvedDesignation = value;
    }

    @State.action.bound
    public setExtensionData(key: string, newValue: any) {
        this.extensionData = {
            ...this.extensionData,
            [key]: newValue
        };
    }
}