import React from "react";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import CareActivitySource from "@Primitives/CareActivitySource.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiHealthcareProviderOrganizationUnitId from "@Primitives/EhiHealthcareProviderOrganizationUnitId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import {getIconNameByHealthcareProfessions} from "@HisPlatform/BoundedContexts/Organization/Components/Helpers/IconTypeNameHelper";
import * as Ui from "@CommonControls";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";

interface IPatientsCareActivityListCareOrganizationUnitColumnProps extends IDataGridColumnProps {
    _dependencies?: IPatientsCareActivityListCareOrganizationUnitColumnDependencies;
}

interface IPatientsCareActivityListCareOrganizationUnitColumnDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
}

@State.observer
class PatientsCareActivityListCareOrganizationUnitColumn extends React.Component<IPatientsCareActivityListCareOrganizationUnitColumnProps> {
    private get organizationReferenceDataStore() {
        return this.props._dependencies.organizationReferenceDataStore;
    }
    
    private get hunEhiCareReferenceDataStore() {
        return this.props._dependencies.hunEhiCareReferenceDataStore;
    }

    private isCareActivityFromEhi(row: PatientsCareActivityStore) {
        return ValueWrapper.equals(row.careActivitySource, new CareActivitySource("HunEHealthInfrastructure"));
    }

    @State.bound
    private dataGetter(row: PatientsCareActivityStore) {
        if (isNullOrUndefined(row)) {
            return null;
        } else {
            if (this.isCareActivityFromEhi(row)) {
                if (isNullOrUndefined(row.extensionData?.CareOrganizationUnitId)) {
                    return row.pointOfCareCode;
                } else {
                    return EhiHealthcareProviderOrganizationUnitId.fromJS(row.extensionData.CareOrganizationUnitId);
                }
            } else {
                if (isNullOrUndefined(row.organizationUnitId)) {
                    return row.pointOfCareCode;
                } else {
                    return row.organizationUnitId;
                }
            }
        }
    }

    @State.bound
    private cellValueRenderer(value: string | PointOfCareId | EhiHealthcareProviderOrganizationUnitId, row: PatientsCareActivityStore): React.ReactNode {
        const noItem = isNullOrUndefined(value);
        if (!noItem) {
            if (value instanceof EhiHealthcareProviderOrganizationUnitId) {
                return this.ehiHealthcareProviderOrganizationUnitValueRenderer(value, row);
            } else if (value instanceof PointOfCareId) {
                return this.pointOfCareValueRenderer(value, row);
            } else if (typeof(value) === "string") {
                return value;
            }
        }

        return "";
    }

    @State.bound
    private ehiHealthcareProviderOrganizationUnitValueRenderer(value: EhiHealthcareProviderOrganizationUnitId, row: PatientsCareActivityStore) {
        const orgUnit = this.hunEhiCareReferenceDataStore.ehiHealthcareProviderOrganizationUnits.get(value);
        if (isNullOrUndefined(orgUnit)) {
            return row.pointOfCareCode;
        }

        return (
            <>
                {orgUnit.name}
                {<br />}
                {orgUnit.code}
            </>
        );
    }

    @State.bound
    private pointOfCareValueRenderer(value: PointOfCareId, row: PatientsCareActivityStore) {

        const poc = this.organizationReferenceDataStore.allPointsOfCareMap.get(value);

        if (isNullOrUndefined(poc)) {
            return row.pointOfCareCode;
        }

        const iconName = getIconNameByHealthcareProfessions(poc.healthcareProfessionIds.map(this.organizationReferenceDataStore.healthCareProfession.get));

        return (
            <>
                {iconName &&
                <Ui.Icon
                    iconName={iconName}
                    style={{ marginRight: 5 }}
                />}
                {poc.shorthand}
            </>
        );
    }

    @State.bound
    private hintValueRenderer(value: string | PointOfCareId | EhiHealthcareProviderOrganizationUnitId): string {
        const noItem = isNullOrUndefined(value);
        if (!noItem) {
            if (value instanceof PointOfCareId) {
                const poc = this.organizationReferenceDataStore.allPointsOfCareMap.get(value);
                return poc?.name;
            }
        }

        return undefined;
    }
    
    public render() {
        return (
            <DataGridColumn
                dataGetter={this.dataGetter}
                onRenderCellValue={this.cellValueRenderer}
                onRenderHintValue={this.hintValueRenderer}
                {...this.props}
            />
        );
    }
}

export default connect(
    PatientsCareActivityListCareOrganizationUnitColumn,
    new DependencyAdapter<IPatientsCareActivityListCareOrganizationUnitColumnProps, IPatientsCareActivityListCareOrganizationUnitColumnDependencies>(c => ({
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        hunEhiCareReferenceDataStore: c.resolve("HunEhiCareReferenceDataStore")
    }))
);