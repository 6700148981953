import IPlatformPlugin from "@PluginInterface/IPlatformPlugin";
import { Container } from "inversify";
import IExtensionComponentRegistry from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import { ExtensionPointType } from "@PluginInterface/ExtensionPoint/ExtensionPointType";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import IFormExtensionRegistry from "@PluginInterface/FormExtension/IFormExtensionRegistry";
import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import IResourceFetcher from "@Toolkit/CommonWeb/Abstractions/Localization/IResourceFetcher";
import { loadStaticAssecoMedWebAppResources } from "@AssecoMedPlugin/StaticResources/StaticAssecoMedWebAppResources";
import IFormLogicRegistry from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";
import TelemetrySessionScreenFormBaseFormLogic from "./Packages/Care/FormLogics/TelemetrySessionScreenFormBaseFormLogic";
import "./DependencyConfiguration/AssecoMedDependencyConfiguration";
import "./DependencyConfiguration/FormLogicConfiguration";
import StaticSAssecoMedCommonReferenceDataResources, { loadStaticCommonReferenceDataResources } from "./BoundedContexts/CommonReferenceData/StaticResources/StaticAssecoMedCommonReferenceDataResources";
import WorkingStatusId from "@Primitives/WorkingStatusId.g";
import DependencyContainer from "@DiContainer";
import CommonReferenceDataExtensibleEnumApiAdapter from "./BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import WorkingStatusSelectBox from "./BoundedContexts/CommonReferenceData/Components/Controls/WorkingStatusSelectBox";
import { GetTypeNameWithPrefix } from "./PluginHelper";
import PatientAdministrativeDataAssecoMedFormLogic from "./Packages/Patients/FormLogics/PatientAdministrativeDataAssecoMedFormLogic";
import WorkplaceTypeId from "@Primitives/WorkplaceTypeId.g";
import WorkplaceTypeSelectBox from "./BoundedContexts/CommonReferenceData/Components/Controls/WorkplaceTypeSelectBox";
import AlwaysVisibleExtensionComponentService from "@PluginInterface/ExtensionPoint/AlwaysVisibleExtensionComponentService";
import CareActivitiesForEpisodeOfCareScreenExtension from "./Packages/Care/Extensions/CareActivitiesForEpisodeOfCareScreenExtension/CareActivitiesForEpisodeOfCareScreenExtension";
import DeviceListDtoValueConverter from "@AssecoMedPlugin/BoundedContexts/Productivity/Components/Worklist/ValueConverters/DeviceListDto";
import DecisionSupportScreenFormLogic from "@AssecoMedPlugin/Packages/Care/FormLogics/DecisionSupportScreenFormLogic";

class AssecoMedPlugin implements IPlatformPlugin {
    public name: string = "AssecoMedPlugin";

    public async configureAsync(container: Container) {
        const applicationContext = container.get<ICurrentCultureProvider>("ICurrentCultureProvider");
        const resourceFetcher = container.get<IResourceFetcher>("IResourceFetcher");
        await resourceFetcher.fetchResourceGroupAsync("AssecoMedValidationMessages", "ValidationMessages", applicationContext.cultureCode);
        await resourceFetcher.fetchResourceGroupAsync("AssecoMedReferenceData", "ReferenceData", applicationContext.cultureCode);
        await resourceFetcher.fetchResourceGroupAsync("AssecoMedWebApp", "WebApp", applicationContext.cultureCode);

        container.bind("PatientAdministrativeDataAssecoMedFormLogic")
            .to(PatientAdministrativeDataAssecoMedFormLogic);

        loadStaticAssecoMedWebAppResources();
        loadStaticCommonReferenceDataResources();

        this.configureExtensionComponentServices(container);
        await Promise.resolve();
    }

    public configureExtensionComponents(registry: IExtensionComponentRegistry<ExtensionPointType>) {
        registry.register("careActivitiesForEpisodeOfCareScreenExtension", CareActivitiesForEpisodeOfCareScreenExtension, "AlwaysVisibleExtensionComponentService");
    }

    private configureExtensionComponentServices(container: Container) {
        return;
    }

    public configureUseCases(useCaseRegistry: IUseCaseRegistry): void {
        return;
    }

    public configureFormExtensions(formExtensionRegistry: IFormExtensionRegistry): void {
        return;
    }

    public configurePermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry): void {
        return;
    }

    public configureActivities(activityRegistry: IActivityRegistry) {
        return;
    }

    public configureNData(configurator: INDataAttributeConfigurator): void {
        configurator.registerValueConverter("DeviceListDto", DeviceListDtoValueConverter.DeviceListDto);
    }

    public configureMapper(mapper: IMapperConfigurator) {
    }

    public configureFormEngine(registry: FormEditorRegistry): void {
        registry.registerExtensibleEnumEditor(
            "WorkingStatus",
            {
                displayName: StaticSAssecoMedCommonReferenceDataResources.ReferenceData.FormEngineExtensibleEnumDisplayName.WorkingStatus,
                componentType: WorkingStatusSelectBox,
                entityIdCtor: WorkingStatusId,
                loadReferenceDataAsync: () => {
                    return DependencyContainer.get<CommonReferenceDataExtensibleEnumApiAdapter>(GetTypeNameWithPrefix("CommonReferenceDataExtensibleEnumApiAdapter")).workingStatus.ensureLoadedAsync();
                }
            }
        );

        registry.registerExtensibleEnumEditor(
            "WorkplaceType",
            {
                displayName: StaticSAssecoMedCommonReferenceDataResources.ReferenceData.FormEngineExtensibleEnumDisplayName.WorkplaceType,
                componentType: WorkplaceTypeSelectBox,
                entityIdCtor: WorkplaceTypeId,
                loadReferenceDataAsync: () => {
                    return DependencyContainer.get<CommonReferenceDataExtensibleEnumApiAdapter>(GetTypeNameWithPrefix("CommonReferenceDataExtensibleEnumApiAdapter")).workplaceType.ensureLoadedAsync();
                }
            }
        );
    }

    public configureFormLogics(registry: IFormLogicRegistry): void {
        registry.register("TelemetrySessionScreenForm", "TelemetrySessionScreenFormBaseFormLogic");
        registry.register("PatientAdministrativeDataForm", "PatientAdministrativeDataAssecoMedFormLogic");
        registry.register("DecisionSupportScreenForm", "DecisionSupportScreenFormLogic");
    }
}

export default new AssecoMedPlugin();
