import React from "react";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IAppointmentScheduleDefinitionInfoExtensionPoint from "@PluginInterface/BoundedContexts/Scheduling/ExtensionPoints/IAppointmentScheduleDefinitionInfoExtensionPointProps";
import AppointmentScheduleDefinition from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentScheduleDefinition";
import ExtensionController from "@HisPlatform/Components/HisPlatformExtensionPoint/ExtensionController";

interface IAppointmentScheduleDefinitionInfoExtensionDependencies {
}

interface IAppointmentScheduleDefinitionInfoExtensionProps {
    store: AppointmentScheduleDefinition,
    extensionController: ExtensionController
}

@State.observer
class AppointmentScheduleDefinitionInfoExtension extends React.Component<IAppointmentScheduleDefinitionInfoExtensionProps> {

    @State.computed
    private get extensionPointProps(): IAppointmentScheduleDefinitionInfoExtensionPoint {
        const result = {
            store: this.props.store,
            extensionController: this.props.extensionController
        } as IAppointmentScheduleDefinitionInfoExtensionPoint;
        return result;
    }

    public render() {
        return (
            <HisPlatformExtensionPoint extensionProps={this.extensionPointProps}
                type="appointmentScheduleDefinitionInfo">
                <></>
            </HisPlatformExtensionPoint>
        );
    }
}

export default connect(
    AppointmentScheduleDefinitionInfoExtension,
    new DependencyAdapter<IAppointmentScheduleDefinitionInfoExtensionProps, IAppointmentScheduleDefinitionInfoExtensionDependencies>(c => ({
    }))
);
