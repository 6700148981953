import DependencyContainer from "@DiContainer";
import * as ProductivityProxy from "@HunSocialSecurityPlugin/BoundedContexts/Productivity/Api/Proxy.g";
import HunWorklistApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/HunWorklistApiAdapter";

DependencyContainer
    .bind("IHunWorklistPackageClient")
    .to(ProductivityProxy.HunWorklistPackageClient)
    .inSingletonScope();

DependencyContainer
    .bind("HunWorklistApiAdapter")
    .to(HunWorklistApiAdapter)
    .inSingletonScope();
