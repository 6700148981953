import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IAppointmentScheduleDefinitionInfoExtensionPointProps from "@PluginInterface/BoundedContexts/Scheduling/ExtensionPoints/IAppointmentScheduleDefinitionInfoExtensionPointProps";
import AppointmentScheduleDefinitionInfo from "@MedioPlugin/BoundedContexts/Scheduling/ApplicationLogic/Model/AppointmentScheduleDefinitionInfo/AppointmentScheduleDefinitionInfo";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticMedioWebAppResources from "@MedioPlugin/StaticResources/StaticMedioWebAppResources";

interface IAppointmentScheduleDefinitionInfoPanelDependencies {
}

interface IAppointmentScheduleDefinitionInfoPanelProps extends IAppointmentScheduleDefinitionInfoExtensionPointProps {
    _dependencies?: IAppointmentScheduleDefinitionInfoPanelDependencies;
}

@State.observer
export class AppointmentScheduleDefinitionInfoPanel extends React.Component<IAppointmentScheduleDefinitionInfoPanelProps> {

    @State.observable.ref private dataStore: AppointmentScheduleDefinitionInfo;
    private disposers: IDisposable[] = [];

    public componentDidMount() {
        this.setDataStore();
    }

    public componentDidUpdate(prevProps: IAppointmentScheduleDefinitionInfoPanelProps) {
        if (this.props.store && this.props.store !== prevProps.store) {
            this.disposers.forEach(d => d.dispose());
            this.disposers = [];
            this.setDataStore();
        }
    }

    public componentWillUnmount() {
        this.disposers.forEach(d => d.dispose());
    }

    @State.action.bound 
    private updateIsMedioRelevant(newValue: boolean) {
        this.dataStore.setIsMedioRelevant(newValue);
        this.updateStore();
    }

    @State.action.bound
    private updateStore() {
        if (this.props.store) {
            this.props.store.extensionData = {
                ...this.props.store.extensionData,
                ["Medio_AppointmentScheduleDefinitionInfo"]: {
                    Id: this.dataStore.id.toJSON(),
                    AppointmentScheduleDefinitionId: this.dataStore.appointmentScheduleDefinitionId.toJSON(),
                    IsMedioRelevant: this.dataStore.isMedioRelevant,
                    RowVersion: this.dataStore.rowVersion
                }
            };
        }
    }

    @State.action.bound
    private setDataStore() {
        this.dataStore = AppointmentScheduleDefinitionInfo.createFromExtensionDto(this.props.store.extensionData);
        this.dataStore.takeSnapshot();
        
        if (this.dataStore.isNew) {
            this.updateStore();
        }

        this.disposers.push(
            this.props.extensionController.setIsDirtyHook(this.isDirty)
        );
    }

    @State.bound
    private isDirty() {
        return this.dataStore.isDirty();
    }


    public render() {
        if (isNullOrUndefined(this.dataStore))
            return <></>;

        return (
            <Ui.CheckBox
                label={StaticMedioWebAppResources.AppointmentScheduleDefinitionInfoPanel.Labels.IsMedioRelevant}
                value={this.dataStore.isMedioRelevant}
                onChange={this.updateIsMedioRelevant}
                labelPosition="left"
                style={{paddingLeft: 10}}
                automationId="isMedioRelevant" />
        );
    }
}

export default connect(
    AppointmentScheduleDefinitionInfoPanel,
    new DependencyAdapter<IAppointmentScheduleDefinitionInfoPanelProps, IAppointmentScheduleDefinitionInfoPanelDependencies>(c => {
        return {
        };
    })
);