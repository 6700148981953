import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import React, { useCallback, useMemo, useState } from "react";
import Styles from "./FloatingPanel.less";

interface IFloatingPanelProps {
    title?: string;
    onClose?: () => void;
    defaultSize?: number;
    extraIcons?: React.ReactNode;
}

const sizes = [
    { width: "25vw", height: "45vh" },
    { width: "45vw", height: "80vh" },
    { width: "calc(100vw - 20px)", height: "calc(100vh - 20px)" },
];

export default function FloatingPanel(props: React.PropsWithChildren<IFloatingPanelProps>) {

    const [sizeIndex, setSizeIndex] = useState<number>(isNullOrUndefined(props.defaultSize) ? 1 : props.defaultSize);
    const [verticalPos, setVerticalPos] = useState<"top" | "bottom">("bottom");
    const [horizontalPos, setHorizontalPos] = useState<"left" | "right">("right");

    const increaseSize = useCallback(() => {
        const newIndex = sizeIndex + 1;
        if (newIndex === sizes.length) {
            return;
        }
        setSizeIndex(newIndex);
    }, [sizeIndex, setSizeIndex]);

    const decreaseSize = useCallback(() => {
        const newIndex = sizeIndex - 1;
        if (newIndex === -1) {
            return;
        }
        setSizeIndex(newIndex);
    }, [sizeIndex, setSizeIndex]);

    const moveLeft = useCallback(() => {
        setHorizontalPos("left");
    }, [setHorizontalPos]);

    const moveRight = useCallback(() => {
        setHorizontalPos("right");
    }, [setHorizontalPos]);

    const moveTop = useCallback(() => {
        setVerticalPos("top");
    }, [setVerticalPos]);

    const moveBottom = useCallback(() => {
        setVerticalPos("bottom");
    }, [setVerticalPos]);

    const left = useMemo(() => {
        switch (horizontalPos) {
            case "left":
                return 10;
            case "right":
                return `calc(100vw - ${sizes[sizeIndex].width} - 10px)`;
        }
    }, [sizeIndex, horizontalPos]);

    const top = useMemo(() => {
        switch (verticalPos) {
            case "top":
                return 10;
            case "bottom":
                return `calc(100vh - ${sizes[sizeIndex].height} - 10px)`;
        }
    }, [sizeIndex, verticalPos]);

    const close = () => {
        props.onClose?.();
    };



    return (
        <div className={combineClasses(Styles.panel, sizeIndex === sizes.length - 1 && Styles.maximized)} style={{ left, top, ...sizes[sizeIndex] }}>
            <div className={Styles.header}>
                <div>
                    {props.title}
                    {props.extraIcons}
                </div>
                <div>
                    <button onClick={increaseSize}>⤢</button>
                    <button onClick={decreaseSize}>🗕</button>
                    <button onClick={moveLeft}>◧</button>
                    <button onClick={moveRight}>◨</button>
                    <button onClick={moveTop}>⬒</button>
                    <button onClick={moveBottom}>⬓</button>
                    <button onClick={close}>🗙</button>
                </div>
            </div>
            <div className={Styles.body}>
                {props.children}
            </div>
        </div>
    );
}