import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import Di from "@Di";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import CustomerId from "@Primitives/CustomerId.g";
import ICustomer from "./ICustomer";
import CustomerApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/Invoicing/CustomerApiAdapter";

@Di.injectable()
export default class CustomerReferenceDataStore extends ReferenceDataStoreBase {

    constructor(
        @Di.inject("CustomerApiAdapter") private customerApiAdapter: CustomerApiAdapter,
    ) {
        super();
    }

    public customers = new ReferenceDataStore<CustomerId, ICustomer>(this.customerApiAdapter.loadCustomersAsync);
}