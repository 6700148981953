import PermissionScope from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/PermissionScope";
import PointOfCareScopeSelectorDialog, { IPointOfCareScopeSelectorDialogProps } from "@HisPlatform/BoundedContexts/Authorization/Components/Controls/PointOfCareScopeSelector/PointOfCareScopeSelectorDialog";
import { UniversalCodeSelector } from "@HisPlatformControls";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import IPermissionScope from "@PluginInterface/OperationAccessControl/IPermissionScope";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";

interface IPointOfCareScopeSelectorDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IPointOfCareScopeSelectorProps {
    _dependencies?: IPointOfCareScopeSelectorDependencies;
    onValueChange: (newValue: IPermissionScope) => void;
    value: IPermissionScope;
    automationId?: string;
}

@State.observer
class PointOfCareScopeSelector extends React.Component<IPointOfCareScopeSelectorProps> {

    private readonly allPointOfCareValue = new PointOfCareId("ALL");
    private get pointsOfCareMap() {
        return this.props._dependencies.organizationReferenceDataStore.allPointsOfCareMap;
    }

    private get allValue() {
        return StaticAuthorizationResources.PermissionConfiguration.PointOfCareScopeSelector.AllValue;
    }
    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.bound
    private async loadAsync() {
        await this.pointsOfCareMap.ensureAllLoadedAsync();
    }

    @State.bound
    private async getDisplayValueAsync(value: PointOfCareId) {
        if (value.value === "ALL") {
            return this.allValue;
        }
        const PoC = await this.pointsOfCareMap.getOrLoadAsync(value);
        return PoC?.name;
    }

    @State.bound
    private async getTextValueAsync(value: PointOfCareId) {
        if (value.value === "ALL") {
            return this.allValue;
        }
        const PoC = await this.pointsOfCareMap.getOrLoadAsync(value);
        return PoC?.shorthand;
    }

    @State.bound
    private quickSearchAsync(searchText: string) {
        return Promise.resolve(this.pointsOfCareMap.items
            .filter(p =>
                p.name.toLowerCase().includes(searchText.toLowerCase()) ||
                p.shorthand.toLowerCase().includes(searchText.toLowerCase()))
            .map(p => p.id));
    }


    @State.bound
    private setPointOfCareFromQuickSearch(value: PointOfCareId[]) {
        if (!value) {
            this.props.onValueChange(null);
            return;
        }
        const filteredNewValue = value.filter(p => p.value !== "ALL").map(p => p.value);
        const scope = PermissionScope.createPositive(PermissionScopeType.pointOfCare, filteredNewValue);
        this.props.onValueChange(scope);
    }

    @State.computed private get pointOfCareValues() {
        if (this.props.value?.isAll) {
            return [this.allPointOfCareValue];
        }
        return this.props.value?.includedValues.map(v => new PointOfCareId(v));
    }

    @State.computed
    private get PointOfCareScopeSelectorDialogProps() {
        return {
            value: this.props.value,
            onSave: this.props.onValueChange
        } as Partial<IPointOfCareScopeSelectorDialogProps>;
    }

    @State.bound
    private getCustomValue(value: any) {
        return <><b>{value.data.text}</b> - {value.data.longDisplayValue}</>;
    }

    private getOptionText(value: any) {
        return value.text;
    }
    public render() {
        return (
            <UniversalCodeSelector
                customContentComponentType={PointOfCareScopeSelectorDialog}
                customContentComponentProps={this.PointOfCareScopeSelectorDialogProps}
                getDisplayValueAsync={this.getDisplayValueAsync}
                onQuickSearchAsync={this.quickSearchAsync}
                onChange={this.setPointOfCareFromQuickSearch}
                hasComplexSearch
                multiSelect
                value={this.pointOfCareValues}
                automationId={this.props.automationId}
                getTextValueAsync={this.getTextValueAsync}
                getOptionValue={this.getOptionText}
                getCustomValue={this.getCustomValue}
                style={{width: "200px"}}
            />
        );
    }
}

export default connect(
    PointOfCareScopeSelector,
    new DependencyAdapter<IPointOfCareScopeSelectorProps, IPointOfCareScopeSelectorDependencies>(c => ({
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore")
    }))
);