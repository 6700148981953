import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";

interface IExternalLocationTypeSelectBoxDependencies {
    referenceDataStore: OrganizationReferenceDataStore;
}

interface IExternalLocationTypeSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IExternalLocationTypeSelectBoxDependencies;
}

@State.observer
class ExternalLocationTypeSelectBox extends React.Component<IExternalLocationTypeSelectBoxProps> {

    private get dependencies() {
        return this.props._dependencies;
    }

    private get extensibleEnumStore() {
        return this.dependencies.referenceDataStore.externalLocationTypes;
    }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    ExternalLocationTypeSelectBox,
    new DependencyAdapter<IExternalLocationTypeSelectBoxProps, IExternalLocationTypeSelectBoxDependencies>(container => {
        return {
            referenceDataStore: container.resolve("OrganizationReferenceDataStore")
        };
    })
);