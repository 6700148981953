import React from "react";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import AppointmentStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentStatus.g";

function AppointmentStatusSelectBox(props: IUniversalEnumSelectorBaseProps): React.ReactElement {
    return (
        <UniversalEnumSelector
            {...props}
            enumOrigin="server"
            enumName={"AppointmentStatus"} 
            enumType={AppointmentStatus}
            multiSelect
            clearable
        />
    );
}

(AppointmentStatusSelectBox as React.FC<IUniversalEnumSelectorBaseProps>).defaultProps = {
    displayMode: "groupedRadioButtons"
};

export default AppointmentStatusSelectBox;