import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCases from "@Primitives/UseCases";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import CareActivityBasedFormDetailPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareFormDetailPanel/CareActivityBasedFormDetailPanel";
import getFormInstanceBasedUseCaseArguments from "@Toolkit/FormEngine/Configuration/FormUseCaseConfigurationHelper";

export default function configureFormUseCases(useCaseRegistry: IUseCaseRegistry) {
    
    useCaseRegistry.add2({
        identifiers: [UseCases.editableFormInstanceDetail],
        component: CareActivityBasedFormDetailPanel,
        mapUseCaseArgumentsToProps: args => { 
            return getFormInstanceBasedUseCaseArguments(args, false);
        }
    });

    useCaseRegistry.add2({
        identifiers: [UseCases.readonlyFormInstanceDetail],
        component: CareActivityBasedFormDetailPanel,
        mapUseCaseArgumentsToProps: args => { 
            return getFormInstanceBasedUseCaseArguments(args, true);
        }
    });

}