import config from "@Config";
import Language, { convertCultureCodeToLanguage, convertLanguageToCultureCode, getCurrentOrDefaultCultureCode } from "@Primitives/Language";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EditableMultiLingualLabel from "@Toolkit/FormEngine/Model/Layout/EditableMultiLingualLabel";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class EditableFormEnumValueStore {
    @State.observable.ref public value: number;
    @State.observable.ref public multiLingualDisplayValues: EditableMultiLingualLabel;
    @State.observable public currentLabelLanguage: Language;
    @State.observable.ref public isDeleted: boolean;
    @State.observable.ref public isActive: boolean;

    constructor(value: number, multiLingualDisplayValues: EditableMultiLingualLabel, isActive: boolean, isDeleted: boolean, currentCultureCode: string) {
        this.value = value;

        if (!isNullOrUndefined(multiLingualDisplayValues)) {
            this.multiLingualDisplayValues = multiLingualDisplayValues;
            this.setCurrentLabelLanguage(getCurrentOrDefaultCultureCode(currentCultureCode, this.multiLingualDisplayValues));
        } else {
            this.multiLingualDisplayValues = new EditableMultiLingualLabel();
            this.currentLabelLanguage = convertCultureCodeToLanguage(currentCultureCode);
            this.multiLingualDisplayValues.setLocalizedLabel(convertLanguageToCultureCode(this.currentLabelLanguage), "");
        }
        this.isActive = isActive;
        this.isDeleted = isDeleted;
    }

    @State.action.bound
    public setLocalizedValue(label: string) {
        this.multiLingualDisplayValues.setLocalizedLabel(convertLanguageToCultureCode(this.currentLabelLanguage), label);
    }

    @State.computed
    public get localizedDisplayValue() {
        return this.multiLingualDisplayValues.getLocalizedLabel(convertLanguageToCultureCode(this.currentLabelLanguage));
    }

    @State.action.bound
    public setCurrentLabelLanguage(currentLabelLanguage: Language) {
        this.currentLabelLanguage = currentLabelLanguage;
    }

    @State.action.bound
    public setValue(value: number) {
        this.value = value;
    }

    @State.action.bound
    public delete() {
        this.isDeleted = true;
    }

    @State.action.bound
    public setActive(value: boolean) {
        this.isActive = value;
    }

    public getHashCode() {
        return this.value?.toString();
    }
}