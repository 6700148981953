import IShowScreenActionCallContextParams from "@HisPlatform/Services/Definition/ActionProcessing/IShowScreenActionCallContextParams";
import IActionProcessor from "@Toolkit/ReactClient/ActionProcessing/IActionProcessor";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";

export default class ShowScreenActionProcessor<TAction extends ShowScreenFrontendActionBase> implements IActionProcessor<TAction, IShowScreenActionCallContextParams> {

    public processAsync(action: TAction, callContextParams: IShowScreenActionCallContextParams): Promise<void> {

        if (!callContextParams?.navigationContext) {
            throw new Error(`Cannot execute '${action.id.value}' action, because callContextParams.navigationContext is null. Please fill call context params (${"IShowScreenActionCallContextParams"}) when dispaching a ShowScreenAction.`);
        }

        if (!callContextParams?.modalService) {
            throw new Error(`Cannot execute '${action.id.value}' action, because callContextParams.modalService is null. Please fill call context params (${"IShowScreenActionCallContextParams"}) when dispaching a ShowScreenAction.`);
        }

        switch (action.displayMode) {
            case ScreenDisplayMode.Full:
                callContextParams.navigationContext.showAbsolutePrimary(action, callContextParams.primaryScreenState);
                break;

            case ScreenDisplayMode.Detail:
            case ScreenDisplayMode.Modal:
                callContextParams.navigationContext.showSecondary(action, { primaryScreenState: callContextParams.primaryScreenState, secondaryScreenState: callContextParams.secondaryScreenState });
                break;
        }

        return Promise.resolve();

    }

}