import PersonNameColumn from "@HisPlatformControls/DataGridColumns/PersonNameColumn";
import DataGridDateColumn from "@CommonControls/DataGrid/Column/DataGridDateColumn";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import DataGridBooleanColumn from "@CommonControls/DataGrid/Column/DataGridBooleanColumn";
import IdentifierColumn from "@HisPlatform/BoundedContexts/Care/Components/Controls/DataGridColumns/IdentifierColumn";
import React from "react";
import AppointmentDateColumn from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentDateColumn/AppointmentDateColumn";
import DataGridMoneyColumn from "@CommonControls/DataGrid/Column/DataGridMoneyColumn";
import DataGridMonthDateColumn from "@CommonControls/DataGrid/Column/DataGridMonthDateColumn";
import DataGridDateTimeRangeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeRangeColumn";

// Toolkit columns only! Put BoundedContext specific columns to the corresponding context initializer (IBoundedContext derived classes) OR plugin initializer
export function getColumnsByAttributeTypes() {
    return {
        "PersonName": <PersonNameColumn />,
        "Date": <DataGridDateColumn />,
        "DateTimeOffset": <DataGridDateTimeColumn />,
        "Boolean": <DataGridBooleanColumn />,
        "DateTimeIntervalDto": <DataGridDateTimeRangeColumn />,
        "Identifier": <IdentifierColumn isOrderable={false} hideOverflow />,
        "Money": <DataGridMoneyColumn />,
        "YearMonthDto": <DataGridMonthDateColumn />,
    };
}
