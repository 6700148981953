import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import PermissionDefinitionBuilder from "@PluginInterface/OperationAccessControl/PermissionDefinitionBuilder";
import { PermissionDefinitions } from "@HisPlatform/PermissionDefinitions/PermissionDefinitions.g";
import { HunSocialSecurityPermissionDefinitions } from "@HunSocialSecurityPlugin/PermissionDefinitions/HunSocialSecurityPermissionDefinitions.g";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";

export default function configureCarePermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry) {

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder
            .create(PermissionDefinitions.Productivity.Worklist.ViewWorklistsWithSubtype)
            .withPermissionScopes(
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType, ["ContagiousPatientReportBased"]),
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistSubtype, ["ForPatient"]))
            .withAlias("ViewPatientContagiousPatientReports", PermissionScopeType.worklistType, PermissionScopeType.worklistSubtype)
            .build()
    );
    
    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder
            .create(PermissionDefinitions.Productivity.Worklist.ViewWorklistsWithSubtype)
            .withPermissionScopes(
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType, ["ContagiousPatientReportBased"]),
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistSubtype, ["ForInstitute"]))
            .withAlias("ViewInstituteContagiousPatientReports", PermissionScopeType.worklistType, PermissionScopeType.worklistSubtype)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder
            .create(HunSocialSecurityPermissionDefinitions.Care.ContagiousPatientReport.ContagiousPatientReportElevatedPermission)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder
            .create(HunSocialSecurityPermissionDefinitions.Care.ContagiousPatientReport.EditInstituteContagiousPatientReports)
            .build()
    );
}