exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PrintPrescriptionDialog_required-star_3hCwt {\n  color: #CE5374;\n  position: relative;\n  top: 2px;\n  left: 2px;\n  font-size: 19px;\n}\n.PrintPrescriptionDialog_required-star_3hCwt::after {\n  content: \"*\";\n}\n.PrintPrescriptionDialog_no-text-selection_3mj0k {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.PrintPrescriptionDialog_label_3_CQg {\n  width: 100%;\n  font-family: PragatiNarrow;\n  font-size: 16px;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 1.14;\n  letter-spacing: normal;\n  text-align: left;\n  color: #8695a7;\n  margin-bottom: 1px;\n  margin-left: 3px;\n}\n.PrintPrescriptionDialog_titleText_DR7pN {\n  color: #00b4ec;\n  font-family: TitilliumWebBold;\n  font-size: 16px;\n  text-transform: uppercase;\n  margin: 0;\n  height: 48px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  flex-grow: 0;\n  flex-shrink: 0;\n  padding: 0 12px;\n}\n.PrintPrescriptionDialog_separator_36qqg {\n  width: 100%;\n  height: 1px;\n  border: 0;\n  background-color: rgba(0, 0, 0, 0.08);\n  margin: 0px;\n  flex: 0 0 2px;\n}\n", ""]);

// exports
exports.locals = {
	"required-star": "PrintPrescriptionDialog_required-star_3hCwt",
	"requiredStar": "PrintPrescriptionDialog_required-star_3hCwt",
	"no-text-selection": "PrintPrescriptionDialog_no-text-selection_3mj0k",
	"noTextSelection": "PrintPrescriptionDialog_no-text-selection_3mj0k",
	"label": "PrintPrescriptionDialog_label_3_CQg",
	"titleText": "PrintPrescriptionDialog_titleText_DR7pN",
	"separator": "PrintPrescriptionDialog_separator_36qqg"
};