import Address from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/Address";
import EhiHealthcareProviderId from "@Primitives/EhiHealthcareProviderId.g";
import NursingLocationTypeId from "@Primitives/NursingLocationTypeId.g";
import OccupationId from "@Primitives/OccupationId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class OnsetReportData {
    @State.observable public hospitalId: EhiHealthcareProviderId = null;
    @State.observable.ref public hospitalizationDate: LocalDate = null;
    @State.observable public nursingLocationTypeId: NursingLocationTypeId = null;
    @State.observable public occupationId: OccupationId = null;
    @State.observable.ref public onsetDate: LocalDate = null;
    @State.observable.ref public onsetLocation: Address = new Address();
    @State.observable public recordingPointOfCareId: PointOfCareId = null;
    @State.observable public recordingPractitionerId: PractitionerId = null;
    @State.observable public workPlaceName: string = null;
    @State.observable.ref public workPlaceLocation: Address = new Address();

    @State.action.bound
    public setHospitalId(value: EhiHealthcareProviderId) {
        this.hospitalId = value;
    }

    @State.action.bound
    public setHospitalizationDate(value: LocalDate) {
        this.hospitalizationDate = value;
    }

    @State.action.bound
    public setNursingLocationType(value: NursingLocationTypeId) {
        this.nursingLocationTypeId = value;
    }

    @State.action.bound
    public setOccupationId(value: OccupationId) {
        this.occupationId = value;
    }

    @State.action.bound
    public setOnsetDate(value: LocalDate) {
        this.onsetDate = value;
    }

    @State.action.bound
    public setRecordingPractitionerId(value: PractitionerId) {
        this.recordingPractitionerId = value;
    }

    @State.action.bound
    public setWorkplaceName(value: string) {
        this.workPlaceName = value;
    }

    @State.action.bound
    public setWorkplaceLocation(value: Address) {
        this.workPlaceLocation = value;
    }
}