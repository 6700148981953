import React from "react";
import AppStatusStore from "@Toolkit/CommonWeb/AppStatusStore";

let loadingIndicatorIndex = 1;

export default class HiddenLoadingIndicator extends React.PureComponent {

    private readonly index = loadingIndicatorIndex++;

    public componentDidMount() {
        AppStatusStore.instance.setLoadingState(`HLI:${this.index}`);
    }

    public componentWillUnmount() {
        AppStatusStore.instance.clearLoadingState(`HLI:${this.index}`);
    }

    public render() {
        return null as React.ReactNode;
    }
}