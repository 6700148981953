import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { IReportingClient } from "@HisPlatform/BoundedContexts/Reporting/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import Di from "@Di";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import { IIntegerIntervalWithCount } from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/CareActivitiesByDurationWidget/IIntegerIntervalWithCount";

@Di.injectable()
export default class CareActivitiesByDurationWidgetApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IReportingClient") private readonly reportingClient: IReportingClient,
        @Di.inject("StructureApiAdapter") private readonly structureApiAdapter: StructureApiAdapter
    ) {
        super();
    }

    public getCareActivityCountsByLengthAsync(
        from: LocalDate,
        to: LocalDate,
        intervalsInMinutes: number[],
        organizationUnitId: OrganizationUnitId) {
        return this.processOperationAsync(
            new SimpleStore<IIntegerIntervalWithCount[]>(),
            async target => {
                const getChildResponse = await this.structureApiAdapter.getChildrenByParentIdAsync(organizationUnitId);
                const children = getChildResponse.value;
                const hasChildren = children.length > 0;
                const response = await this.reportingClient.getCareActivityCountsByLengthQueryAsync(
                    CreateRequestId(),
                    from.stringify(),
                    intervalsInMinutes.map(i => i.toString()).join(";"),
                    hasChildren ? children.map(o => o.value).join(";") : organizationUnitId.value,
                    to.stringify());

                target.operationInfo = createOperationInfo(response);
                target.value = response.intervalsWithCounts.map(i => {
                    return {
                        from: i.from,
                        to: i.to,
                        count: i.count
                    } as IIntegerIntervalWithCount;
                });
            }
        );
    }
}