import PractitionerId from "@Primitives/PractitionerId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import moment from "moment";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";

export default class AppointmentBaseData {

    public serviceRequestDefinitionId: IEntityVersionSelector<ServiceRequestDefinitionId> = null;
    public serviceRequestIdentifier: string = "";
    constructor(
        public readonly practitionerId: PractitionerId,
        public readonly pointOfCareId: PointOfCareId,
        public readonly subject: SchedulingServiceId | IEntityVersionSelector<ServiceRequestId>,
        public readonly subjectType: "ServiceRequest" | "SchedulingService",
        public readonly from: moment.Moment,
        public readonly to: moment.Moment
    ) {

    }
}
