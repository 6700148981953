import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import CommonReferenceDataDataStore from "@AssecoMedPlugin/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { GetTypeNameWithPrefix } from "@AssecoMedPlugin/PluginHelper";

interface IWorkplaceTypeSelectBoxDependencies {
    commonReferenceDataDataStore: CommonReferenceDataDataStore;
}

interface IWorkplaceTypeSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IWorkplaceTypeSelectBoxDependencies;
}

@State.observer
class WorkplaceTypeSelectBox extends React.Component<IWorkplaceTypeSelectBoxProps> {
    private get dependencies() { return this.props._dependencies; }
    private get extensibleEnumStore() { return this.dependencies.commonReferenceDataDataStore.workplaceType; }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    WorkplaceTypeSelectBox,
    new DependencyAdapter<IWorkplaceTypeSelectBoxProps, IWorkplaceTypeSelectBoxDependencies>( (containerService) => {
        return {
            commonReferenceDataDataStore: containerService.resolve(GetTypeNameWithPrefix("CommonReferenceDataDataStore"))
        };
    })
);
