import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import React, { useContext } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PermissionCheckReactContext from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckReactContext";
import _ from "@HisPlatform/Common/Lodash";
import PermissionCheckContext, { IPermissionCheckOperation } from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContext";

interface IPermissionCheckContextProps {
    operationsToCheck: IPermissionCheckOperation;
}

interface IPermissionCheckContextInternalProps {
    parentContext?: PermissionCheckContext;
    operationsToCheck: IPermissionCheckOperation;
}

@State.observer
export default class PermissionCheckContextProvider extends React.Component<IPermissionCheckContextInternalProps> {
    @State.observable private permissionCheckContext: PermissionCheckContext = new PermissionCheckContext();
    @State.observable private isLoading = false;

    public componentDidMount() {
        this.addChecksFromParent();
        dispatchAsyncErrors(this.checkPermissionsAsync(), this);
    }

    public componentDidUpdate(prevProps: IPermissionCheckContextInternalProps) {
        if (!_.isEqual(Object.keys(prevProps.operationsToCheck), Object.keys(this.props.operationsToCheck))) {
            dispatchAsyncErrors(this.checkPermissionsAsync(), this);
        }

        if (prevProps.parentContext !== this.props.parentContext) {
            this.addChecksFromParent();
        }
    }

    private addChecksFromParent() {
        if (this.props.parentContext) {
            this.permissionCheckContext.setAlreadyCheckedPermissions(this.props.parentContext.checkedPermissions);
        }
    }

    @State.boundLoadingState()
    private async checkPermissionsAsync() {
        await this.permissionCheckContext.checkPermissionsAsync(this.props.operationsToCheck);
    }

    public render() {
        return (
            <PermissionCheckReactContext.Provider value={this.permissionCheckContext}>
                {!this.isLoading && this.props.children}
            </PermissionCheckReactContext.Provider>
        );

    }
}

export function NestedPermissionCheckContextProvider(props: React.PropsWithChildren<IPermissionCheckContextProps>) {
    const parentContext = useContext(PermissionCheckReactContext);

    return (
        <PermissionCheckContextProvider
            operationsToCheck={props.operationsToCheck}
            parentContext={parentContext}
            children={props.children}
        />
    );
}