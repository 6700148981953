import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import * as Proxy from "@HisPlatform/BoundedContexts/Scheduling/Api/Proxy.g";
import {CareLocation} from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/CareLocation";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import SuspectedDiagnosis from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/SuspectedDiagnosis";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import {AppointmentCreationDto} from "./SchedulingDtoMapper";
import CareActivityId from "@Primitives/CareActivityId.g";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import ServiceRequestCreationType from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestCreationType.g";

export function CreateServiceRequestWithAppointmentControllerDto(
    serviceRequest: ServiceRequestStore,
    shouldSubmitServiceRequest: boolean,
    requestLock: boolean,
    appointment: Appointment,
    isValidateOnly: boolean,
    isEmpty: boolean) {
    return new Proxy.CreateServiceRequestWithAppointmentControllerDto({
        serviceRequest: ServiceRequestCreationDto(serviceRequest, shouldSubmitServiceRequest, isEmpty),
        appointment: AppointmentCreationDto(appointment),
        requestLock: requestLock,
        isValidateOnly: isValidateOnly
    } as Proxy.ICreateServiceRequestWithAppointmentControllerDto);
}

export function ModifyServiceRequestWithCreateAppointmentControllerDto(
    careActivityId: CareActivityId | null,
    serviceRequestStore: ServiceRequestStore | null,
    shouldSubmitServiceRequest: boolean | null,
    appointmentStore: Appointment | null,
    lockId: LockId | null,
    releaseLockIfSuccessful: boolean,
    isValidateOnly: boolean | null,
    extensionData?: any | null
) {
    return new Proxy.ModifyServiceRequestWithCreateAppointmentControllerDto({
        activeCareActivityId: careActivityId,
        serviceRequest: new Proxy.ServiceRequestModificationDto({
            clinicalQuestion: serviceRequestStore.clinicalQuestion,
            remark: serviceRequestStore.remark,
            requestedServices: getProxyRequestedServices(serviceRequestStore.requestedServices),
            requesterDoctorId: serviceRequestStore.requesterDoctorId,
            starterCareActivityId: serviceRequestStore.starterCareActivityId,
            suspectedDiagnosisList: getProxySuspectedDiganoses(serviceRequestStore.suspectedDiagnosisList),
            targetCareLocation: serviceRequestStore.targetCareLocation && getProxyCareLocationDto(serviceRequestStore.targetCareLocation),
            targetDoctorId: serviceRequestStore.targetDoctorId,
            shouldSubmitServiceRequest: shouldSubmitServiceRequest,
            rowVersion: serviceRequestStore.rowVersion,
            serviceRequestId: serviceRequestStore.id,
            extensionData: serviceRequestStore.extensionData,
            selectedMedicalServicePanelIds: serviceRequestStore.selectedMedicalServicePanelIds
        }),
        appointment: AppointmentCreationDto(appointmentStore),
        extensionData: extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful,
        isValidateOnly: isValidateOnly
    } as Proxy.IModifyServiceRequestWithCreateAppointmentControllerDto);
}

export function ModifyServiceRequestWithUpdateAppointmentControllerDto(
    careActivityId: CareActivityId | null,
    serviceRequestStore: ServiceRequestStore | null,
    shouldSubmitServiceRequest: boolean | null,
    appointmentScheduleEntryId: AppointmentScheduleEntryId | null,
    appointmentStore: Appointment | null,
    rowVersion: RowVersion | null,
    lockId: LockId | null,
    releaseLockIfSuccessful: boolean,
    isValidateOnly: boolean | null,
    extensionData?: any | null
) {
    return new Proxy.ModifyServiceRequestWithUpdateAppointmentControllerDto({
        activeCareActivityId: careActivityId,
        serviceRequest: new Proxy.ServiceRequestModificationDto({
            clinicalQuestion: serviceRequestStore.clinicalQuestion,
            remark: serviceRequestStore.remark,
            requestedServices: getProxyRequestedServices(serviceRequestStore.requestedServices),
            requesterDoctorId: serviceRequestStore.requesterDoctorId,
            starterCareActivityId: serviceRequestStore.starterCareActivityId,
            suspectedDiagnosisList: getProxySuspectedDiganoses(serviceRequestStore.suspectedDiagnosisList),
            targetCareLocation: serviceRequestStore.targetCareLocation && getProxyCareLocationDto(serviceRequestStore.targetCareLocation),
            targetDoctorId: serviceRequestStore.targetDoctorId,
            shouldSubmitServiceRequest: shouldSubmitServiceRequest,
            rowVersion: serviceRequestStore.rowVersion,
            serviceRequestId: serviceRequestStore.id,
            extensionData: serviceRequestStore.extensionData,
            selectedMedicalServicePanelIds: serviceRequestStore.selectedMedicalServicePanelIds
        }),
        appointmentScheduleEntryId: appointmentScheduleEntryId,
        appointment: AppointmentCreationDto(appointmentStore),
        rowVersion: rowVersion,
        extensionData: extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful,
        isValidateOnly: isValidateOnly
    } as Proxy.IModifyServiceRequestWithUpdateAppointmentControllerDto);
}

export function ModifyServiceRequestWithDeleteAppointmentControllerDto(
    careActivityId: CareActivityId | null,
    serviceRequestStore: ServiceRequestStore | null,
    shouldSubmitServiceRequest: boolean | null,
    appointmentScheduleEntryId: AppointmentScheduleEntryId | null,
    rowVersion: RowVersion | null,
    lockId: LockId | null,
    releaseLockIfSuccessful: boolean,
    isValidateOnly: boolean | null,
    extensionData?: any | null
) {
    return new Proxy.ModifyServiceRequestWithDeleteAppointmentControllerDto({
        activeCareActivityId: careActivityId,
        serviceRequest: new Proxy.ServiceRequestModificationDto({
            clinicalQuestion: serviceRequestStore.clinicalQuestion,
            remark: serviceRequestStore.remark,
            requestedServices: getProxyRequestedServices(serviceRequestStore.requestedServices),
            requesterDoctorId: serviceRequestStore.requesterDoctorId,
            starterCareActivityId: serviceRequestStore.starterCareActivityId,
            suspectedDiagnosisList: getProxySuspectedDiganoses(serviceRequestStore.suspectedDiagnosisList),
            targetCareLocation: serviceRequestStore.targetCareLocation && getProxyCareLocationDto(serviceRequestStore.targetCareLocation),
            targetDoctorId: serviceRequestStore.targetDoctorId,
            shouldSubmitServiceRequest: shouldSubmitServiceRequest,
            rowVersion: serviceRequestStore.rowVersion,
            serviceRequestId: serviceRequestStore.id,
            extensionData: serviceRequestStore.extensionData,
            selectedMedicalServicePanelIds: serviceRequestStore.selectedMedicalServicePanelIds
        }),
        appointmentScheduleEntryId: appointmentScheduleEntryId,
        rowVersion: rowVersion,
        extensionData: extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful,
        isValidateOnly: isValidateOnly
    } as Proxy.IModifyServiceRequestWithDeleteAppointmentControllerDto);
}

export function DeleteDraftServiceRequestWithAppointmentControllerDto(
    appointmentScheduleEntryId: AppointmentScheduleEntryId | null,
    appointmentRowVersion: RowVersion | null,
    serviceRequestId: ServiceRequestId | null,
    activeCareActivityId: CareActivityId | null,
    serviceRequestRowVersion: RowVersion | null,
    lockId: LockId | null,
    releaseLockIfSuccessful: boolean
) {
    return new Proxy.DeleteDraftServiceRequestWithAppointmentControllerDto({
        appointmentScheduleEntryId: appointmentScheduleEntryId,
        appointmentRowVersion: appointmentRowVersion,
        serviceRequestId: serviceRequestId,
        activeCareActivityId: activeCareActivityId,
        serviceRequestRowVersion: serviceRequestRowVersion,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

function ServiceRequestCreationDto(serviceRequest: ServiceRequestStore, shouldSubmitServiceRequest: boolean, emptyServiceRequest: boolean) {
    return new Proxy.ServiceRequestCreationDto({
        clinicalQuestion: serviceRequest.clinicalQuestion,
        remark: serviceRequest.remark,
        requestedServices: getProxyRequestedServices(serviceRequest.requestedServices),
        requesterDoctorId: serviceRequest.requesterDoctorId,
        serviceRequestDefinitionId: serviceRequest.serviceRequestDefinitionVersion && serviceRequest.serviceRequestDefinitionVersion.id,
        starterCareActivityId: serviceRequest.starterCareActivityId,
        suspectedDiagnosisList: getProxySuspectedDiganoses(serviceRequest.suspectedDiagnosisList),
        targetCareLocation: serviceRequest.targetCareLocation && getProxyCareLocationDto(serviceRequest.targetCareLocation),
        targetDoctorId: serviceRequest.targetDoctorId,
        shouldSubmitServiceRequest: shouldSubmitServiceRequest,
        direction: serviceRequest.direction,
        expectedCompletionTime: emptyServiceRequest && serviceRequest.expectedCompletionTime,
        priority: emptyServiceRequest && serviceRequest.medicalServicePriority,
        serviceRequestCreationType: emptyServiceRequest ? ServiceRequestCreationType.Empty : ServiceRequestCreationType.WithRequestedServices,
        extensionData: serviceRequest.extensionData,
        selectedMedicalServicePanelIds: serviceRequest.selectedMedicalServicePanelIds
    });
}

function getProxyCareLocationDto(careLocation: CareLocation): Proxy.CareLocationDto {
    if (careLocation instanceof ExternalCareLocation) {
        return new Proxy.ExternalCareLocationDto({
            
            externalLocationSelector: new Proxy.EntityVersionSelectorOfExternalLocationId({
                entityId: careLocation.externalLocationSelector.id,
                validOn: careLocation.externalLocationSelector.validOn
            })
        });
    } else if (careLocation instanceof InternalCareLocation) {
        return new Proxy.InternalCareLocationDto({ 
            pointOfCareId: careLocation.pointOfCareId
        });
    }
    throw new Error("Unknown CareLocation type.");
}

function getProxyRequestedServices(items: RequestedServiceStore[]): Proxy.RequestedServiceCreationDto[] {
    return items.map(s => {
        return new Proxy.RequestedServiceCreationDto({
            serviceId: s.medicalServiceVersionSelector.id,
            expectedCompletionTime: s.expectedCompletionTime,
            lateralityId: s.lateralityId,
            priority: s.priority
        });
    });
}

function getProxySuspectedDiganoses(items: SuspectedDiagnosis[]): Proxy.SuspectedDiagnosisCreationDto[] {
    return items.map(d => {
        return new Proxy.SuspectedDiagnosisCreationDto({
            conditionId: d.conditionId,
            lateralityId: d.lateralityId
        });
    });
}
