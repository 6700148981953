import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import PerformanceReport from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReport";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import PerformanceReportListToolbar from "./Master/PerformanceReportListToolbar";
import PerformanceReportList from "./Master/PerformanceReportList";
import PerformanceReportDetail from "./Detail/PerformanceReportDetail";
import { IReportDefinition } from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/Model/IReportDefinition";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import StaticHunSocialSecurityReportingResources from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/StaticResources/StaticHunSocialSecurityReportingResources";

interface IPerformanceReportPanelViewProps {
    definition: IReportDefinition;
    performanceReport: PerformanceReport;
    pointOfCareIds: PointOfCareId[];
    onSelectedItemChangeAsync: (selectedPerformanceReportId: string) => Promise<void>;
    onCancel: () => void;
    onCreateAsync: () => Promise<void>;
    validationResults: IClientValidationResult[];
    onValidateAsync: (dirtyFields: string[]) => Promise<IClientValidationResult[]>;
    isLoading: boolean;
    dataSource: InMemoryDataGridDataSource<PerformanceReport>;
    addNewPerformanceReportAsync: () => Promise<void>;
    savePerformanceReportAsync: () => Promise<boolean>;
    deletePerformanceReportAsync: () => Promise<void>;
    onNavigateTo: () => void;
}

@State.observer
class PerformanceReportPanelView extends React.Component<IPerformanceReportPanelViewProps> {

    public render() {
        return (
            <SingleLayout>
                <MasterDetailLayout
                    isCompact={false}
                    selectedItem={this.props.performanceReport?.id?.value}
                    onSelectedItemChange={this.props.onSelectedItemChangeAsync}
                    isLoading={this.props.isLoading}
                >
                    <MasterDetail.Master
                        title={this.props.definition.name}
                        toolbar={(
                            <PerformanceReportListToolbar
                                onAddPerformanceReportAsync={this.props.addNewPerformanceReportAsync}
                                onNavigateToPerformanceReportScorePlans={this.props.onNavigateTo}
                                isPerformanceReportSelected={!!this.props.performanceReport?.id?.value}
                            />)}
                    >
                        <PerformanceReportList
                            dataSource={this.props.dataSource}
                            onBack={this.props.onCancel}
                        />
                    </MasterDetail.Master>
                    <MasterDetail.Detail
                        hasSidePadding
                        title={this.title}
                        toolbar={this.renderDetailToolbar()}
                    >
                        <PerformanceReportDetail
                            performanceReport={this.props.performanceReport}
                            pointOfCareIds={this.props.pointOfCareIds}
                            validationResults={this.props.validationResults}
                            onValidateAsync={this.props.onValidateAsync}
                        />
                    </MasterDetail.Detail>
                </MasterDetailLayout>
            </SingleLayout>
        );
    }

    @State.computed get title() {
        if (!this.props.performanceReport) {
            return "";
        }

        return this.props.performanceReport.isNew ?
        StaticHunSocialSecurityReportingResources.PerformanceReportPanel.NewPerformanceReportTitle :
            this.props.performanceReport.name;
    }

    @State.bound
    private renderDetailToolbar() {
        return (
            <div style={{ textAlign: "right" }}>
                <Ui.Button
                    visualStyle="primary"
                    text={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.Create}
                    onClickAsync={this.props.onCreateAsync}
                    automationId="createButton"
                />
                <Ui.SaveButton
                    onClickAsync={this.props.savePerformanceReportAsync}
                    visualStyle="standard"
                    automationId="saveButton"
                />
                {this.props.performanceReport && !this.props.performanceReport.isNew &&
                    <Ui.Button
                        text={StaticWebAppResources.Common.Button.Delete}
                        onClickAsync={this.props.deletePerformanceReportAsync}
                        visualStyle="standard"
                        automationId="deleteButton"
                    />}
            </div>
        );
    }
}

export default PerformanceReportPanelView;