import React, { useCallback } from "react";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import { ScreenStateContextAdapter, ScreenStateContextStore } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import StaticAssecoMedWebAppResources from "@AssecoMedPlugin/StaticResources/StaticAssecoMedWebAppResources";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import * as Ui from "@CommonControls";
import NameStore from "@Primitives/NameStore";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import { provideTelemedicineScreenStore, useTelemedicineScreenStore } from "./TelemedicineScreenStoreProvider";
import ShowEditTelemedicineScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowEditTelemedicineScreenAction.g";
import ToolbarButtons from "@Toolkit/ReactClient/Components/ToolbarButtons/ToolbarButtons";
import { Button } from "@CommonControls";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import PatientId from "@Primitives/PatientId.g";

export interface ITelemedicineScreenProps extends IScreenPropsBase<ShowEditTelemedicineScreenAction> {
    _screenState?: ScreenStateContextStore;
    _modalService?: IModalService;
    _patientName?: NameStore;
    _patientId?: PatientId;
}

function TelemedicineScreen(props: ITelemedicineScreenProps) {

    const renderTooltipRow = useCallback((label: string, data: string, code?: string) => {
        return (
            <Ui.Flex verticalSpacing="none">
                <Ui.Flex.Item xs={5}>{label}:</Ui.Flex.Item>
                <Ui.Flex.Item xs={7}>{!!code && <b>{code} </b>}{data}</Ui.Flex.Item>
            </Ui.Flex>
        );
    }, []);

    const renderPractitionerRows = useCallback((practitionerData: Array<{ name: string, code: string }>) => {
        return !!practitionerData.length && (
            <>
                {practitionerData.map(d => renderTooltipRow(StaticSchedulingResources.BookSlotDialog.Practitioner, d.name ?? "", d.code ?? ""))}
            </>
        );
    }, []);

    const store = useTelemedicineScreenStore();

    return (
        <Screen
            title={StaticAssecoMedWebAppResources.TelemedicineScreen.Title}
            toolbar={
                <>
                    <ToolbarButtons
                        betweenButtons={store.isShowEditTelemedicineScreenAction && (
                            <>
                                <Button
                                    text={StaticAssecoMedWebAppResources.TelemedicineScreen.Buttons.Edit}
                                    onClickAsync={store.onEditTelemedicineAppointmentAsync}
                                    automationId="_editTelemedicineButton"
                                    visualStyle="secondary" />

                                <Ui.Button
                                    iconName="trash"
                                    visualStyle="negative-standard"
                                    text={StaticAssecoMedWebAppResources.TelemedicineScreen.Buttons.Delete}
                                    onClickAsync={store.onDeleteTelemedicineAppointmentAsync}
                                    automationId="_deleteTelemedicineButton" />
                            </>
                        )}
                        cancelButtonVisible={false}
                    />
                </>
            }>
            {store?.appointment && (
                <div>
                    {renderTooltipRow(StaticSchedulingResources.AppointmentParticipantPanel.SubjectService, store.schedulingService?.name ?? "", store.schedulingService?.code ?? "")}
                    {renderTooltipRow(StaticSchedulingResources.BookSlotDialog.WorkPlace, store.pointOfCare?.name ?? "", store.pointOfCare?.shorthand)}
                    {renderPractitionerRows(store.practitionerData)}
                    {renderTooltipRow(StaticSchedulingResources.PractitionerAppointments.PatientName, store.localizedPatientName)}
                    {renderTooltipRow(StaticSchedulingResources.RegisteredPatientAppointmentListPanel.IntervalColumn, store.interval)}
                    {renderTooltipRow(StaticSchedulingResources.PractitionerAppointments.Note, store.appointment.description)}
                </div>
            )}
            <BusinessErrorHandler.Register businessErrorName="CantChangeCareActivityStateError" handler={store.handleCantChangeCareActivityStateError} />
        </Screen>
    );
}

export default connect<ITelemedicineScreenProps>(
    provideTelemedicineScreenStore(TelemedicineScreen),
    new ScreenStateContextAdapter(),
    new HisModalServiceAdapter(),
    new PatientContextAdapter<ITelemedicineScreenProps>(c => ({
        _patientName: c.patient?.baseData?.name,
        _patientId: c.patient.id
    }))
);