import State from "@Toolkit/ReactClient/Common/StateManaging";
import ColumnId from "@Primitives/ColumnId.g";

export default class TableTokenValueFormatterSettingStore {

    public id: ColumnId = null;
    public configHeading: string = null;
    public tokenFormatter: string = null;
    public tokenFormatterConfiguration: string = null;
    public tokenFormatterSettingsType: string = null;
    @State.observable.ref public tokenFormatterSettings: object = null;
    @State.observable.ref public heading: string = null;
    @State.observable.ref public order: number = null;
    @State.observable.ref public visible: boolean = null;
    @State.observable.ref public columnWidth: string = null;
    public onSetFormatValue: () => void;

    @State.action.bound
    public setHeading(newValue: string) {
        this.heading = newValue;
        this.onSetFormatValue();
    }

    @State.action.bound
    public setOrder(newValue: number) {
        this.order = newValue;
        this.onSetFormatValue();
    }

    @State.action.bound
    public setVisible(newValue: boolean) {
        this.visible = newValue;
        this.onSetFormatValue();
    }

    @State.action.bound
    public setColumnWidth(newValue: string) {
        this.columnWidth = newValue;
        this.onSetFormatValue();
    }

    @State.action.bound
    public setTokenFormatterSettings(newValue: object) {
        this.tokenFormatterSettings = newValue;
        this.onSetFormatValue();
    }
}
