import React from "react";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import HasValidationError from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/HasValidationError.g";

function CareActivityBasedWorklistHasValidationErrorSelectBox(props: IUniversalEnumSelectorBaseProps): React.ReactElement {
    
    return (
        <UniversalEnumSelector
            {...props}
            enumName={"HasValidationError"}
            enumOrigin="server"
            enumType={HasValidationError}
            displayMode="selectBox"
            clearable
            searchable={false}
            maxMenuWidth={commonMenuMaxWidth}
        />
    );
}

export default CareActivityBasedWorklistHasValidationErrorSelectBox;
