import React from "react";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import MasterDetailLayout from "@CommonControls/Layout/MasterDetailLayout";
import { useFormDefinitionMasterDetailPanelStore, provideFormDefinitionMasterDetailPanelStore } from "./FormDefinitionMasterDetailPanelStoreProvider";
import FormDefinitionList from "./FormDefinitionList";
import FormDefinitionDetail from "./FormDefinitionDetail";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";

export interface IFormDefinitionMasterDetailPanelProps {
    selectedId: FormDefinitionId | null;
    onSelectedIdChange: (selectedId: FormDefinitionId | null) => void;
}

function FormDefinitionMasterDetailPanel(props: IFormDefinitionMasterDetailPanelProps) {

    const store = useFormDefinitionMasterDetailPanelStore();

    return (
        <SingleLayout>
            <MasterDetailLayout
                selectedItem={store.selectedId}
                onSelectedItemChange={props.onSelectedIdChange}
                master={<FormDefinitionList />}
                detail={<FormDefinitionDetail />}
                isSeparatedMode
            />
        </SingleLayout>
    );
}

export default provideFormDefinitionMasterDetailPanelStore(FormDefinitionMasterDetailPanel);