import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import CareActivityDischargeDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityDischargeData/CareActivityDischargeDataStore";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import CareActivityId from "@Primitives/CareActivityId.g";
import Di from "@Di";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import * as WebAppBackendProxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";


type ResponseType =
    Proxy.GetCareActivityDischargeDataCommandResponse |
    Proxy.UpdateAndDischargePatientCommandResponse |
    WebAppBackendProxy.GetCareActivityDischargeDataCompositeCommandResponse |
    WebAppBackendProxy.UpdateAndDischargePatientCommandResponse |
    WebAppBackendProxy.UpdateDischargePatientDataCommandResponse;

@Di.injectable()
export default class CareActivityDischargeDataStoreMapper extends LockingEntityStoreMapper<Proxy.CareActivityDischargeDataDto, CareActivityDischargeDataStore, ResponseType> {
    protected storeEntityIdType = CareActivityId;

    protected entityDtoSelector(response: ResponseType) {
        return response.careActivityDischargeData;
    }

    protected applyToStoreCore(target: CareActivityDischargeDataStore, response: ResponseType) {
        const dto = this.entityDtoSelector(response);

        target.dischargedAt = dto.dischargedAt;
        target.dischargeReasonId = dto.dischargeReasonId;
        target.receivingInstitute = new EntityVersionSelector<ExternalLocationId>(dto.receivingInstituteId, LocalDate.createFromMoment(dto.dischargedAt));
        target.rowVersion = response.careActivityDischargeData.rowVersion;

        if (!isNullOrUndefined(dto.extensionData)) {
            target.extensionData = dto.extensionData;
        }
    }
}
