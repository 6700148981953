import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";

interface IOncologyRegisterExtensionSelectBoxDependencies {
    careReferenceDataStore: HunEhiCareReferenceDataStore;
}

interface IOncologyRegisterExtensionSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IOncologyRegisterExtensionSelectBoxDependencies;
}

@State.observer
class OncologyRegisterExtensionSelectBox extends React.Component<IOncologyRegisterExtensionSelectBoxProps> {

    private get dependencies() {
        return this.props._dependencies;
    }

    private get extensibleEnumStore() {
        return this.dependencies.careReferenceDataStore.oncologyRegisterExtension;
    }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    OncologyRegisterExtensionSelectBox,
    new DependencyAdapter<IOncologyRegisterExtensionSelectBoxProps, IOncologyRegisterExtensionSelectBoxDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("HunEhiCareReferenceDataStore")
        };
    })
);
