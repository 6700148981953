import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import BinaryDocumentViewer from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/BinaryDocumentViewer/BinaryDocumentViewer";
import BinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/BinaryDocument";
import DocumentBase from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentBase";
import TemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TemplateBasedDocument";
import ImageViewer, { ValidMimeTypesForImageAndPdfViewer } from "@CommonControls/ImageViewer/ImageViewer";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import { HisDocumentEditor } from "@HisPlatformControls";

interface IDocumentManagementDetailPanelDependencies {

}

interface IDocumentManagementDetailPanelProps extends IHasMasterDetailState {
    _dependencies?: IDocumentManagementDetailPanelDependencies;

    document: DocumentBase;
    isReadOnly?: boolean;
    printPermissionCheckOperationName?: string;
    permissionCheckOperationNames?: string;
    permissionDeniedStyle?: "disabled" | "invisible";
    documentEditorCacheKey?: number;
}

/** @screen */
@State.observer
class DocumentManagementDetailPanel extends React.Component<IDocumentManagementDetailPanelProps> {
    public render() {

        if (this.props.document instanceof BinaryDocument) {
            if (this.props.document.content.type !== ValidMimeTypesForImageAndPdfViewer.Pdf) {
                return (
                    <ImageViewer
                        alt={StaticDocumentManagementResources.DocumentManagementMasterDetailPanel.AttachedImage.Alt}
                        data={(this.props.document as BinaryDocument)?.content}
                    />
                );
            }
            return (
                <BinaryDocumentViewer
                    documentPath={this.props.document.largeDataToken}
                    downloadFileName={this.props.document.downloadFileName}
                    printPermissionCheckOperationName={this.props.printPermissionCheckOperationName}
                />
            );
        } else if (this.props.document instanceof TemplateBasedDocument) {
            return (
                <HisDocumentEditor
                    key={this.props.documentEditorCacheKey}
                    isReadOnly={this.props.isReadOnly}
                    mode="Document"
                    contentStore={this.props.document.documentFile.contentStore.forEditor}
                />
            );
        } else {
            return null;
        }
    }
}

export default connect(
    DocumentManagementDetailPanel,
    new DependencyAdapter<IDocumentManagementDetailPanelProps, IDocumentManagementDetailPanelDependencies>(c => ({

    }))
);
