import State from "@Toolkit/ReactClient/Common/StateManaging";
import AutonomyDisabilityAssignmentBase from "./AutonomyDisabilityAssignmentBase";

export default class CustomAutonomyDisabilityAssignment extends AutonomyDisabilityAssignmentBase  {
    @State.observable.ref public name: string;

    constructor(name: string) {
        super();
        this.name = name;
    }

    @State.action.bound
    public setName(newValue: string) {
        return this.name = newValue;
    }
}