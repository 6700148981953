/* tslint:disable */
import ContentReference from "@Primitives/ContentReference.g";
import ValueWrapper from "./ValueWrapper";

export default class RowVersion extends ValueWrapper<number> {
    public static fromJS(data: any) { 
        if (data === undefined || data === null) {
            return null;
        }
        return new RowVersion(data["Value"]);
    }
    
    public toJSON(target?: any) {
        target = typeof target === "object" ? target : {};
        target["Value"] = this.value;
        return target;
    }

    public toUrl(): string { return this.value?.toString() ?? "null"; }

    public static fromUrl(url: string) { return url === null || url === undefined || url === "null" ? null : new ContentReference(parseInt(url, 10)); }

    public static initial: RowVersion = new RowVersion(0);
}