import React from "react";
import _ from "@HisPlatform/Common/Lodash";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import { MedicalConditionHistoryItemSelectorView } from "@HisPlatform/BoundedContexts/Care/Components/Controls/MedicalCondition/MedicalConditionHistoryItemVersionSelector/MedicalConditionHistoryItemSelectorView";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import MedicalConditionHistoryItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemBase";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";

interface IMedicalConditionHistoryItemSelectorDependencies {
    localizationService: ILocalizationService;
}
interface IMedicalConditionHistoryItemSelectorProps extends ISelectBoxBaseProps {
    _dependencies?: IMedicalConditionHistoryItemSelectorDependencies;
    items: MedicalConditionHistoryItemBase[];
    renderSelfDeterminationExtension: () => JSX.Element;
    renderVersionExtension: () => JSX.Element;
}

@State.observer
class MedicalConditionHistoryItemSelector extends React.Component<IMedicalConditionHistoryItemSelectorProps> {

    @State.observable public items: IObservableArray<ISelectBoxItem<number>> = State.observable([]);

    constructor(props: IMedicalConditionHistoryItemSelectorProps) {
        super(props);
    }

    private get labels() { return StaticCareResources.MedicalCondition.Common.Label; }
    private get localizationService() { return this.props._dependencies.localizationService; }

    public render() {
        return (
            <MedicalConditionHistoryItemSelectorView
                {...this.props}
                renderSelfDeterminationExtension={this.props.renderSelfDeterminationExtension}
                renderVersionExtension={this.props.renderVersionExtension}
                items={this.items}
                label={this.labels.Versions} />
        );
    }

    public componentDidMount() {
        this.orderAndSetItems();
    }

    public componentDidUpdate(prevProps: IMedicalConditionHistoryItemSelectorProps) {
        if ([...prevProps.items] !== [...this.props.items]) {
            this.orderAndSetItems();
        }
    }

    @State.bound
    public orderAndSetItems() {
        const orderedItems = _.orderBy(this.props.items, x => x.index, "desc");
        this.setItems(orderedItems.map(x => {
            return {
                value: x.index,
                text: formatString(StaticCareResources.MedicalCondition.Common.Label.Version, x.index + 1, this.localizationService.localizeDateTime(x.createdAt, false))
            } as ISelectBoxItem<number>;
        }));
    }

    @State.action.bound
    private setItems(items: Array<ISelectBoxItem<number>>) {
        this.items.replace(items);
    }
}

export default connect(
    MedicalConditionHistoryItemSelector,
    new DependencyAdapter<IMedicalConditionHistoryItemSelectorProps, IMedicalConditionHistoryItemSelectorDependencies>((container) => {
        return {
            localizationService: container.resolve("ILocalizationService"),
        };
    })
);
