exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AppointmentStatusBadgeStyles_state-booked_3QVJB {\n  background-color: #ddc00d;\n  color: white;\n}\n.AppointmentStatusBadgeStyles_state-fulfilled_39P2- {\n  background-color: #21c313;\n  color: white;\n}\n.AppointmentStatusBadgeStyles_state-canceled_3K1fY {\n  background-color: #ce5374;\n  color: white;\n}\n", ""]);

// exports
exports.locals = {
	"state-booked": "AppointmentStatusBadgeStyles_state-booked_3QVJB",
	"stateBooked": "AppointmentStatusBadgeStyles_state-booked_3QVJB",
	"state-fulfilled": "AppointmentStatusBadgeStyles_state-fulfilled_39P2-",
	"stateFulfilled": "AppointmentStatusBadgeStyles_state-fulfilled_39P2-",
	"state-canceled": "AppointmentStatusBadgeStyles_state-canceled_3K1fY",
	"stateCanceled": "AppointmentStatusBadgeStyles_state-canceled_3K1fY"
};