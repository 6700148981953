import Di from "@Di";
import Command from "@Toolkit/CommonWeb/CommandProcessing/Definition/Command";
import CommandMessage from "@Toolkit/CommonWeb/CommandProcessing/Definition/CommandMessage";
import ICommandFactory from "@Toolkit/CommonWeb/CommandProcessing/Definition/ICommandFactory";
import CreateServiceRequestCommand from "./CreateServiceRequestCommand";

@Di.injectable()
export default class CreateServiceRequestCommandFactory implements ICommandFactory {
    get commandType(): string {
        return "CreateServiceRequestCommand";
    }

    /** {@inheritdoc} */
    public createFromMessage(message: CommandMessage): Command {
        return new CreateServiceRequestCommand(
            message.originator,
            message.data.serviceCode);
    }
}