import { Container } from "@DiContainer";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import UserValidationProblemMapper from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Users/UserValidationProblemMapper";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import CareActivityTextBlock from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/CareActivityTextBlock";
import CareActivityTextBlockValidationProblemMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityTextBlock/CareActivityTextBlockValidationProblemMapper";
import CareActivityDocumentValidationProblemMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentValidationProblemMapper";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import UnauthorizedOperationBusinessError from "@Toolkit/CommonWeb/Model/UnauthorizedOperationBusinessError";
import UnauthorizedDataAccessError from "@Toolkit/CommonWeb/Model/UnauthorizedDataAccessError";
import LockAcquisitionError from "@Toolkit/CommonWeb/Model/LockAcquisitionError";
import LockDoesNotExistsError from "@Toolkit/CommonWeb/Model/LockDoesNotExistsError";
import TextualBusinessError from "@Toolkit/CommonWeb/Model/TextualBusinessError";
import UnknownBusinessError from "@HisPlatform/Model/DomainModel/BusinessError/UnknownBusinessError";
import AdmitPatientAndCareActivityBaseDataValidationProblemMapper from "@HisPlatform/Packages/Care/Screens/AdmitPatientAndCareActivityBaseDataScreen/AdmitPatientAndCareActivityBaseDataValidationProblemMapper";
import AdmitPatientAndCareActivityBaseDataScreenApiAdapter from "@HisPlatform/Packages/Care/Screens/AdmitPatientAndCareActivityBaseDataScreen/AdmitPatientAndCareActivityBaseDataScreenApiAdapter";
import PatientAdministrativeDataValidationProblemMapper from "@HisPlatform/Packages/Patients/Screens/PatientAdministrativeDataScreen/PatientAdministrativeDataValidationProblemMapper";
import PatientAdministrativeDataScreenApiAdapter from "@HisPlatform/Packages/Patients/Screens/PatientAdministrativeDataScreen/PatientAdministrativeDataScreenApiAdapter";
import LockId from "@Toolkit/CommonWeb/Model/LockId";

export function configureValidationProblemMapping(container: Container) {
    const validationProblemMapperService = container.get<ValidationProblemParameterMapperService>("ValidationProblemParameterMapperService");

    const userMapper = container.get<UserValidationProblemMapper>("UserValidationProblemMapper");
    validationProblemMapperService.registerMapper("User", userMapper);

    const careActivityTextBlockMapper = container.get<CareActivityTextBlockValidationProblemMapper>("CareActivityTextBlockValidationProblemMapper");
    validationProblemMapperService.registerMapper("CareActivityTextBlock", careActivityTextBlockMapper);

    const careActivityDocumentMapper = container.get<CareActivityDocumentValidationProblemMapper>("CareActivityDocumentValidationProblemMapper");
    validationProblemMapperService.registerMapper("CareActivityDocument", careActivityDocumentMapper);

    const admitAndBaseDataMapper = container.get<AdmitPatientAndCareActivityBaseDataValidationProblemMapper>("AdmitPatientAndCareActivityBaseDataValidationProblemMapper");
    validationProblemMapperService.registerMapper("AdmitPatientAndCareActivityBaseDataScreenApiAdapter", admitAndBaseDataMapper);

    const patientAdministrativeDataMapper = container.get<PatientAdministrativeDataValidationProblemMapper>("PatientAdministrativeDataValidationProblemMapper");
    validationProblemMapperService.registerMapper("PatientAdministrativeDataScreenApiAdapter", patientAdministrativeDataMapper);
}

export function configureMapper(mapper: IMapperConfigurator) {
    mapper.registerByName<any, UnknownBusinessError>("UnknownBusinessError", rawBusinessError => new UnknownBusinessError(rawBusinessError._discriminator));
    mapper.registerByName("UnauthorizedOperationError", (rawBusinessError: any) => new UnauthorizedOperationBusinessError(rawBusinessError.OperationName));
    mapper.registerByName("UnauthorizedDataAccessError", (rawBusinessError: any) => new UnauthorizedDataAccessError(rawBusinessError.EntityId, rawBusinessError.EntityType));
    mapper.registerByName("LockAcquisitionError", (rawBusinessError: any) => new LockAcquisitionError(
        rawBusinessError.CurrentLockOwner?.UserId?.Value, 
        rawBusinessError.CurrentLockId ? LockId.fromJS(rawBusinessError.CurrentLockId) : null));
    mapper.registerByName("LockDoesNotExistsError", _ => new LockDoesNotExistsError());
    mapper.registerByName("TextualBusinessError", (rawBusinessError: any) => new TextualBusinessError(rawBusinessError.Message));
}
