import { IDataGridColumnChildProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import React, { useCallback } from "react";
import { PatientServiceListItem } from "./PatientServiceList";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import InfoButton from "@CommonControls/InfoButton";
import IMedicalServiceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalServiceVersion";
import moment from "moment";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";

type displayModeType = "name" | "alternativeName" | "code-name" | "code-alternativeName";

export interface IServiceRequestDisplayData {
    serviceRequestIdentifier: string;
    createdAt: moment.Moment;
}

interface IBillableServiceColumnCell extends IDataGridColumnChildProps<any, any> {
    dataGetter: (row: PatientServiceListItem) => MedicalServiceId | IServiceRequestDisplayData;
    medicalServiceDisplayMode?: displayModeType;
}

const BillableServiceColumnCell: React.FC<IBillableServiceColumnCell> = (props: IBillableServiceColumnCell) => {
    const { careReferenceDataStore, localizationService } = useDependencies(c => ({
        careReferenceDataStore: c.resolve<CareReferenceDataStore>("CareReferenceDataStore"),
        localizationService: c.resolve<ILocalizationService>("ILocalizationService")
    }));

    const renderMedicalServiceCell = useCallback((medicalServiceId: MedicalServiceId) => {
        const id = getVersionedId(medicalServiceId);
        const service = careReferenceDataStore.medicalService.get(id);

        return service && (
            <div>
                {getDisplayValue(props.medicalServiceDisplayMode, service)}&nbsp;
                {service.description && <InfoButton iconName="infoCircle" tooltipContent={service.description} position="baseline" />}
            </div>
        );
    }, [props.medicalServiceDisplayMode]);

    const data = props.dataGetter(props.row);
    if (data instanceof MedicalServiceId) {
        return renderMedicalServiceCell(data);
    }

    return <><b>{data.serviceRequestIdentifier}</b> - {StaticFinanceResources.InvoiceScreen.InvoiceItems.BillableServiceColumn.ServiceRequestName} ({localizationService.localizeDateTime(data.createdAt)})</>;
};

function getVersionedId(medicalServiceId: MedicalServiceId): IEntityVersionSelector<MedicalServiceId> {
    return new EntityVersionSelector(medicalServiceId, LocalDate.today());
}

function getDisplayValue(displayMode: displayModeType, service: IMedicalServiceVersion): JSX.Element | string {
    switch (displayMode) {
        case "name":
            return service.name;
        case "alternativeName":
            return service.alternativeName;
        case "code-alternativeName":
            return <><b>{service.code.value}</b>{` - ${service.alternativeName}`}</>;
        case "code-name":
        default:
            return <><b>{service.code.value}</b>{` - ${service.name}`}</>;
    }
}

export default BillableServiceColumnCell;