import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import {
    IEditPlanningPeriodModalParams,
    IEditPlanningPeriodModalResult
} from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/EditPlanningPeriodModal/EditPlanningPeriodModalParams";
import React from "react";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import AppointmentScheduleDefinition from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentScheduleDefinition";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import _ from "@HisPlatform/Common/Lodash";

interface IEditPlanningPeriodModalProps extends IModalComponentParams<IEditPlanningPeriodModalResult>, IEditPlanningPeriodModalParams {

}

@State.observer
export default class EditPlanningPeriodModal extends React.Component<IEditPlanningPeriodModalProps> {
    private pathPrefix: string = "PlanningPeriods";

    @State.bound
    private onCancel() {
        this.props.onClose({ result: "cancel" });
    }

    @State.bound
    private async onOkAsync() {
        const result = await this.props.onValidateAsync();

        const problems = (_.flatten(result.map(r => r.problems))).filter(p => p.propertyPath?.includes(this.pathPrefix));

        if (!problems.length) {
            this.props.onClose({ result: "save" });
        }
    }

    @State.bound
    private onDelete() {
        this.props.onClose({ result: "delete" });
    }

    public render() {
        const resources = StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.PlanningPeriod;

        return (
            <Ui.Modal
                automationId="__editPlanningPeriodModal"
                title={this.props.isCopy ? resources.ModalCopyTitle : this.props.store.id ? resources.ModalEditTitle : resources.ModalAddTitle}
                isOpen
                onClose={this.onCancel}
                size={"tiny"}
                closeButton
                closeOnOutsideClick={false}
            >
                <ValidationBoundary
                    entityTypeName={"AppointmentScheduleDefinition"}
                    onValidateAsync={this.props.onValidateAsync}
                    validateOnMount
                    validationResults={this.props.validationResults}
                >
                    <Ui.Modal.Body>
                        <Ui.DateRangePicker
                            label={resources.Interval}
                            value={this.props.store.interval}
                            onChange={this.props.store.setInterval}
                            automationId="planningPeriodDateRangePicker"
                            propertyIdentifier={this.pathPrefix + ";" + this.pathPrefix + ".Interval"}
                            initialSelectionState="from"
                        />
                    </Ui.Modal.Body>
                    <Ui.Modal.Footer>
                        <Ui.Modal.Button
                            text={resources.Cancel}
                            onClick={this.onCancel}
                            visualStyle="link"
                            automationId="editPlanningPeriodModalCancelButton"
                        />
                        <Ui.ValidationControlledButton
                            text={this.props.isCopy ? resources.CopyAction : this.props.store.id ? resources.Modify : resources.Add}
                            onClickAsync={this.onOkAsync}
                            visualStyle="primary" float="right"
                            automationId="editPlanningPeriodModalSaveButton"
                            propertyPath="*"
                            disableOnSeverity="error"
                        />
                        {
                            this.props.store.id &&
                            <Ui.Modal.Button
                                text={resources.Delete}
                                onClick={this.onDelete}
                                iconName="trash" visualStyle="negative-standard" float="right"
                                automationId="editPlanningPeriodModalDeleteButton"
                            />
                        }
                    </Ui.Modal.Footer>
                </ValidationBoundary>
            </Ui.Modal>
        );
    }
}
