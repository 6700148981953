import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import PermissionDefinitionBuilder from "@PluginInterface/OperationAccessControl/PermissionDefinitionBuilder";
import { PermissionDefinitions } from "@HisPlatform/PermissionDefinitions/PermissionDefinitions.g";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";

export default function configureSchedulingPermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry) {
    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.Scheduling.Configuration.EditAppointmentSchedules, PermissionDefinitions.Productivity.Worklist.ViewWorklists)
            .withPermissionScopesFor(
                PermissionDefinitions.Productivity.Worklist.ViewWorklists,
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType, ["AppointmentScheduleDefinitionBased"])
            )
            .withAlias(null, PermissionScopeType.worklistType)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.Scheduling.Scheduling.EditAppointments, PermissionDefinitions.Productivity.Worklist.ViewWorklists)
            .withPermissionScopesFor(
                PermissionDefinitions.Productivity.Worklist.ViewWorklists,
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType, ["AppointmentScheduleEntryBased"])
            )
            .withAlias(null, PermissionScopeType.worklistType)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.Scheduling.Scheduling.EditPractitionerEntries, PermissionDefinitions.Organization.ResourceManagement.EditPractitionerEntries).build()
    );
}