import React from "react";
import { IFormEditorComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import PointOfCareSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareSelectBox";
import IncludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierFilter";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";


interface IFormPointOfCareSelectBoxProps extends IFormEditorComponentProps {

}

function FormPointOfCareSelectBox(props: IFormPointOfCareSelectBoxProps) {

    const ids = props.filters?.map(filter => {
        if (filter instanceof IncludeIdentifierFilter) {
            return new PointOfCareId(filter.value.value);
        } else {
            throw new Error(`Unsupported filter: ${typeof filter}`);
        }
    });

    return (
        <PointOfCareSelectBox
            {...props}
            explicitIds={arrayIsNullOrEmpty(ids) ? null : ids}
            shouldSelectOnlyItem={true}
        />
    ); 
}

export default FormPointOfCareSelectBox;