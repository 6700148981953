import Di from "@Di";
import { IGetComorbidityRequestData } from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/ComorbidityWidget/IGetComorbidityRequestData";

const apiBaseUrl = `${window.location.protocol}//${window.location.host}/comorbidity/`;

@Di.injectable()
export default class ComorbidityWidgetApiAdapter {
    public async getDataAsync(data: IGetComorbidityRequestData) {
        const response = await fetch(`${apiBaseUrl}getComorbidity`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/fhir+json"
            }
        });
        const result = await response.json();
        return result;
    }
}