import _ from "@HisPlatform/Common/Lodash";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import PerformedServiceListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/PerformedServiceListStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import PerformedServicesStoreMapper from "./PerformedServicesStoreMapper";
import Di from "@Di";

@Di.injectable()
export default class PerformedServicesApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("ICareRegisterClient") private readonly apiClient: Proxy.ICareRegisterClient,
        @Di.inject("PerformedServicesStoreMapper") private readonly performedServicesStoreMapper: PerformedServicesStoreMapper
    ) {
        super();
    }

    public loadByIdAsync(careActivityId: CareActivityId, requestLock: boolean): Promise<PerformedServiceListStore> {
        return this.processOperationAsync(
            new PerformedServiceListStore(),
            async target => {
                const response = await this.apiClient.getPerformedServiceListCommandAsync(
                    CreateRequestId(),
                    new Proxy.GetPerformedServiceListControllerDto({
                        careActivityId: careActivityId,
                        requestLock,
                        skipValidation: false,
                        forceAcquireLock: false
                    })
                );

                await this.performedServicesStoreMapper.applyToStoreAndResolveValidationProblemsAsync(target, response);
            }
        );
    }
}
