import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareRegisterReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareRegisterReferenceDataStore";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IAdmissionReasonSelectBoxDependencies {
    careRegisterReferenceDataStore: CareRegisterReferenceDataStore;
}

interface IAdmissionReasonSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IAdmissionReasonSelectBoxDependencies;
}


@State.observer
class AdmissionReasonSelectBox extends React.Component<IAdmissionReasonSelectBoxProps> {
    private get dependencies() { return this.props._dependencies; }
    private get extensibleEnumStore() { return this.dependencies.careRegisterReferenceDataStore.admissionReason; }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    AdmissionReasonSelectBox,
    new DependencyAdapter<IAdmissionReasonSelectBoxProps, IAdmissionReasonSelectBoxDependencies>( (containerService) => {
        return {
            careRegisterReferenceDataStore: containerService.resolve("CareRegisterReferenceDataStore")
        };
    })
);
