import React from "react";
import {Separator} from "react-contexify";
import Styles from "./MenuSeparator.less";

interface IMenuSeparatorProps {
    separatorText?: string;
    onlyText?: boolean;
}

export default function MenuSeparator(props: IMenuSeparatorProps) {
    return (
        <>
            {
                !props.onlyText && <Separator/>
            }
            {
                props.separatorText &&
                <div className={Styles.menuSeparator}>
                    {props.separatorText}
                </div>
            }
        </>
    );
}
