import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import { mapIdentifier, mapNameStore, mapProfessions as mapProfessionalExams } from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/StoreMappers";
import PractitionerAddressStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerAddressStore";
import PractitionerTelecomStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerTelecomStore";
import PractitionerId from "@Primitives/PractitionerId.g";
import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class PractitionerStoreMapper extends EntityStoreMapper<Proxy.PractitionerDto, PractitionerStore> {
    protected storeEntityIdType = PractitionerId;

    protected applyToStoreCore(target: PractitionerStore, dto: Proxy.PractitionerDto) {
        target.userId = dto.userId;
        target.baseData.name = mapNameStore(dto.baseData.name);
        target.baseData.birthName = mapNameStore(dto.baseData.birthName);
        target.baseData.genderId = dto.baseData.genderId;
        target.baseData.mothersName = dto.baseData.mothersName;
        target.baseData.notes = dto.baseData.notes;
        target.jobPositionId = dto.jobPositionId;

        if (dto instanceof Proxy.DoctorDto) {
            target.code = dto.code;
        }

        target.addresses = State.observable(dto.addresses.map(x => {
            const entity = new PractitionerAddressStore();
            entity.addressLine = x.address.addressLine;
            entity.countryId = x.address.countryId;
            entity.settlement = x.address.settlement;
            entity.zipCode = x.address.zipCode;
            entity.addressTypeId = x.addressTypeId;
            entity.validFrom = x.validity.from;
            entity.validTo = x.validity.to;
            return entity;
        }));

        target.telecoms = State.createObservableShallowArray(dto.telecoms.map(t => this.createPractitionerTelecom(t)));

        target.identifiers = State.observable(
            dto.identifiers.map(mapIdentifier));

        target.professionalExams = State.observable(
            dto.practitionerProfessionalExams.map(mapProfessionalExams)
        );
    }

    private createPractitionerTelecom(x: Proxy.PractitionerTelecomDto) {
        const entity = new PractitionerTelecomStore();
        entity.telecomTypeId = x.telecomTypeId;
        entity.telecomUseId = x.telecomUseId;
        entity.value = x.value;
        return entity;
    }
}
