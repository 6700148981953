import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import { CareLocation } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/CareLocation";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IMedicalServiceExecutionCapability from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalServiceExecutionCapability";
import ServiceProviderProfile from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/ServiceProviderProfile";
import Di from "@Di";

@Di.injectable()
export default class ServiceProviderProfileApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IMedicalServicesClient") private readonly apiClient: Proxy.IMedicalServicesClient
    ) {
        super();
    }

    public getServiceProviderProfileByLocationAsync(location: CareLocation) {
        return this.processOperationAsync(
            new SimpleStore<ServiceProviderProfile>(),
            async target => {
                const response = await this.apiClient.getServiceProviderProfileVersionByLocationQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetServiceProviderProfileVersionByLocationControllerDto({
                        careLocation: this.mapCareLocation(location),
                        validOn: location instanceof ExternalCareLocation ? location.externalLocationSelector.validOn : LocalDate.today()
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = new ServiceProviderProfile(
                    response.serviceProviderProfile.id,
                    response.serviceProviderProfile.medicalServiceExecutionCapabilities.map(this.mapMedicalServiceAvailability)
                );
            }
        );
    }

    @State.bound
    private mapMedicalServiceAvailability(a: Proxy.IMedicalServiceExecutionCapabilityDto) {
        return {
            medicalServiceId: a.medicalServiceId,
            priorities: a.priorities,
            targetDoctorSelectionAllowed: a.targetDoctorSelectionAllowed,
            durationInMinutes: a.durationInMinutes,
            appointmentNeeded: a.appointmentNeeded
        } as IMedicalServiceExecutionCapability;
    }

    private mapCareLocation(location: CareLocation) {
        if (location instanceof ExternalCareLocation) {
            return new Proxy.ExternalCareLocationDto({
                externalLocationSelector: new Proxy.EntityVersionSelectorOfExternalLocationId({
                    entityId: location.externalLocationSelector.id,
                    validOn: location.externalLocationSelector.validOn
                })
            });
        } else if (location instanceof InternalCareLocation) {
            return new Proxy.InternalCareLocationDto({
                pointOfCareId: location.pointOfCareId
            });
        }

        throw new Error("Cannot map care location (unknown concrete type)");
    }

}