import React from "react";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import { ScreenStateContextAdapter, ScreenStateContextStore } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import { provideTelemetrySessionScreenStore, useTelemetrySessionScreenStore } from "./TelemetrySessionScreenStoreProvider";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import FormPanel from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";
import ToolbarButtons from "@Toolkit/ReactClient/Components/ToolbarButtons/ToolbarButtons";
import { LockIndicatorComponent } from "@HisPlatform/Components/HisPlatformControls";
import StaticAssecoMedWebAppResources from "@AssecoMedPlugin/StaticResources/StaticAssecoMedWebAppResources";
import ActionBoundButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundButton";
import ShowCreateNewTelemetrySessionScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowCreateNewTelemetrySessionScreenAction.g";
import ShowEditTelemetrySessionScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowEditTelemetrySessionScreenAction.g";
import ShowReadOnlyTelemetrySessionScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowReadOnlyTelemetrySessionScreenAction.g";

export interface ITelemetrySessionScreenProps extends IScreenPropsBase<ShowCreateNewTelemetrySessionScreenAction | ShowEditTelemetrySessionScreenAction | ShowReadOnlyTelemetrySessionScreenAction> {
    _screenState?: ScreenStateContextStore;
    _modalService?: IModalService;
}

function TelemetrySessionScreen(props: ITelemetrySessionScreenProps) {
    const store = useTelemetrySessionScreenStore();

    return (
        <Screen
            title={StaticAssecoMedWebAppResources.TelemetrySessionScreen.Title}
            toolbar={
                <>
                    <ToolbarButtons
                        beforeButtons={store.isEditTelemetrySessionScreen && store.canAcquireLock && (
                            <LockIndicatorComponent locked={true} onLoadClickedAsync={store.loadAsync} lockedBy={store.lockInfo?.preventingLockUserId} preventingLockId={store.lockInfo?.preventingLockId} />
                        )}
                        onCancelAsync={store.onCancelAsync}
                        betweenButtons={<>
                            {!store.canAcquireLock && store.isEditTelemetrySessionScreen && (
                                <>
                                    <ActionBoundButton actionDescriptor={store.saveTelemetrySessionAction} onExecuteAsync={store.onCloseAsync} disabled={store.vIsReadOnly} text={StaticAssecoMedWebAppResources.TelemetrySessionScreen.Buttons.CloseTelemetrySession} iconName="lock" automationId="__close"/>
                                </>
                            )}
                        </>}
                        saveButtonVisible={(!store.canAcquireLock && store.isEditTelemetrySessionScreen) || store.isNewTelemetrySessionScreen}
                        saveActionsDescriptor={store.saveTelemetrySessionAction}
                        onExecuteSaveAsync={store.onSaveAsync}
                    />
                </>
            }>
            <ValidationBoundary
                entityTypeName="CareActivityBaseData"
                validationResults={store.validationResults}
                onValidateAsync={store.onValidateAsync}
                validateOnMount>
                <FormPanel definitionId={store.formDefinitionId} form={store.form} onChange={store.onDataChangeAsync.fireAndForget} isReadOnly={store.vIsReadOnly} />
            </ValidationBoundary>
        </Screen>
    );
}

export default connect<ITelemetrySessionScreenProps>(
    provideTelemetrySessionScreenStore(TelemetrySessionScreen),
    new ScreenStateContextAdapter(),
    new HisModalServiceAdapter()
);