import React from "react";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import AppointmentCancellationDialogView from "./AppointmentCancellationDialogView";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IAppointmentCancellationDialogParams } from "./AppointmentCancellationDialogParams";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import AppointmentCancellationReasonId from "@Primitives/AppointmentCancellationReasonId.g";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

interface IAppointmentCancellationDialogDependencies {
    schedulingApiAdapter: SchedulingApiAdapter;
}

interface IAppointmentCancellationDialogProps extends IModalComponentParams, IAppointmentCancellationDialogParams {
    _dependencies?: IAppointmentCancellationDialogDependencies;
}

/** @screen */
@State.observer
class AppointmentCancellationDialog extends React.Component<IAppointmentCancellationDialogProps> {

    @State.observable public additionalText: string = null;
    @State.observable public appointmentCancellationReasonId: AppointmentCancellationReasonId = null;
    @State.observable public validationResults: IClientValidationResult[];

    constructor(props: IAppointmentCancellationDialogProps) {
        super(props);
        this.validationResults = [];
    }

    private get apiAdapter() {
        return this.props._dependencies.schedulingApiAdapter;
    }

    @State.action.bound
    private setAdditionalText(value: string) {
        this.additionalText = value;
    }

    @State.action.bound
    private setAppointmentCancellationReasonId(value: AppointmentCancellationReasonId) {
        this.appointmentCancellationReasonId = value;
    }

    @State.action.bound
    private close() {
        this.props.onClose({
            appointmentDeleted: false
        });
    }

    @State.action.bound
    public async onValidateAsync() {
        const result = await this.apiAdapter.cancelAppointment(
            this.props.appointmentScheduleId,
            this.appointmentCancellationReasonId,
            this.additionalText,
            true);

        return result.value;
    }

    @State.action.bound
    private async saveAsync() {
        const result = await this.apiAdapter.cancelAppointment(
            this.props.appointmentScheduleId,
            this.appointmentCancellationReasonId,
            this.additionalText,
            this.props.shouldExecuteDelete ? false : true);

        if (result.value.some(i => i.problems && i.problems.length)) {
            this.setValidationResults(result.value);
        } else {
            this.props.onClose({
                appointmentDeleted: true,
                dialogData: {
                    appointmentCancellationReasonId: this.appointmentCancellationReasonId,
                    additionalText: this.additionalText
                }
            });
        }
    }

    @State.action.bound
    private setValidationResults(value: IClientValidationResult[]) {
        this.validationResults = value;
    }

    public render() {
        return (
            <AppointmentCancellationDialogView
                additionalText={this.additionalText}
                additionalTextRequired={false}
                appointmentCancellationReasonId={this.appointmentCancellationReasonId}
                setAdditionalText={this.setAdditionalText}
                setAppointmentCancellationReasonId={this.setAppointmentCancellationReasonId}
                onClose={this.close}
                save={this.saveAsync}
                validationResults={this.validationResults}
                onValidateAsync={this.onValidateAsync}
            />
        );
    }
}

export default connect(
    AppointmentCancellationDialog,
    new DependencyAdapter<IAppointmentCancellationDialogProps, IAppointmentCancellationDialogDependencies>(c => {
        return {
            schedulingApiAdapter: c.resolve("SchedulingApiAdapter")
        };
    })
);
