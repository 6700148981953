import React from "react";
import ExtensionPoint from "@Toolkit/ReactClient/Extensibility/ExtensionPoint/ExtensionPoint";
import { ExtensionPointType } from "@PluginInterface/ExtensionPoint/ExtensionPointType";
import { IExtensionPointProps } from "@PluginInterface/ExtensionPoint/IExtensionPointProps";

interface IHisPlatformExtensionPointProps {
    type: ExtensionPointType;
    extensionProps: IExtensionPointProps;
    children?: React.ReactNode;
}


const HisPlatformExtensionPoint: React.FC<IHisPlatformExtensionPointProps> = props => (
    <ExtensionPoint
        {...props}
    />
);

export default HisPlatformExtensionPoint;