import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IMedicalConditionReferenceDataResolver from "@HisPlatform/BoundedContexts/Care/Services/Definition/MedicalConditionReferenceDataResolver/IMedicalConditionReferenceDataResolver";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import { VaccineCodeSelectorItem } from "@HisPlatformControls/VaccineCodeSelector/VaccineCodeSelectorItem";
import VaccineId from "@Primitives/VaccineId.g";
import { MedicalServiceExtensibleCodeSelectorItem } from "@HisPlatformControls/MedicalServiceExtensibleCodeSelector/MedicalServiceExtensibleCodeSelectorItem";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { AutonomyDisabilityCodeSelectorItem } from "@HisPlatformControls/AutonomyDisabilityCodeSelector/AutonomyDisabilityCodeSelectorItem";
import AutonomyDisabilityId from "@Primitives/AutonomyDisabilityId.g";
import { ConditionExtendedCodeSelectorItem } from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ConditionExtendedCodeSelector/ConditionExtendedCodeSelectorItem";
import ConditionId from "@Primitives/ConditionId.g";
import { DeviceCodeSelectorItem } from "@HisPlatformControls/DeviceCodeSelector/DeviceCodeSelectorItem";
import DeviceId from "@Primitives/DeviceId.g";
import { MedicationStatementSubstanceCodeSelectorItem } from "@HisPlatformControls/MedicationStatementSubstanceCodeSelector/MedicationStatementSubstanceCodeSelectorItem";
import InternationalNonproprietaryNameId from "@Primitives/InternationalNonproprietaryNameId.g";
import MedicationId from "@Primitives/MedicationId.g";
import SubstanceId from "@Primitives/SubstanceId.g";
import { LifeStyleRiskCodeSelectorItem } from "@HisPlatformControls/LifeStyleRiskCodeSelector/LifeStyleRiskCodeSelectorItem";
import LifeStyleRiskId from "@Primitives/LifeStyleRiskId.g";
import { AllergyIntoleranceCodeSelectorItem } from "@HisPlatformControls/AllergyIntoleranceCodeSelector/AllergyIntoleranceCodeSelectorItem";
import AllergyIntoleranceId from "@Primitives/AllergyIntoleranceId.g";
import { MedicalAlertCodeSelectorItem } from "@HisPlatformControls/MedicalAlertCodeSelector/MedicalAlertCodeSelectorItem";
import MedicalAlertId from "@Primitives/MedicalAlertId.g";

@Di.injectable()
export default class MedicalConditionReferenceDataResolver implements IMedicalConditionReferenceDataResolver {
    constructor(
        @Di.inject("CareReferenceDataStore") private careReferenceDataStore: CareReferenceDataStore
    ) { }

    @State.action.bound
    public async getVaccineNameAsync(value: VaccineCodeSelectorItem) {        
        let item;
        if (value.id instanceof VaccineId) {
            if (!value.text) {
                item = await this.careReferenceDataStore.vaccines.getOrLoadAsync(value.id);
            } else {
                item = { name: value.text };
            }
        } else {
            item = { name: value.text };
        }
        return item.name;
    } 

    @State.action.bound
    public async getMedicalServiceNameAsync(value: MedicalServiceExtensibleCodeSelectorItem) {        
        let item;
        if (value.id instanceof MedicalServiceId) {
            if (!value.text) {
                item = await this.careReferenceDataStore.medicalService.getOrLoadAsync({id: value.id, validOn: LocalDate.today() });
            } else {
                item = { name: value.text };
            }
        } else {
            item = { name: value.text };
        }
        return item.name;
    } 

    @State.action.bound
    public async getAutonomyDisabilityNameAsync(value: AutonomyDisabilityCodeSelectorItem): Promise<string> {
        let item;
        if (value.id instanceof AutonomyDisabilityId) {
            if (!value.text) {
                item = await this.careReferenceDataStore.autonomyDisabilities.getOrLoadAsync(value.id);
            } else {
                item = { name: value.text };
            }
        } else {
            item = { name: value.text };
        }
        return item.name;
    }
    
    @State.action.bound
    public async getConditionNameAsync(value: ConditionExtendedCodeSelectorItem): Promise<string> {
        let item;
        if (value.id instanceof ConditionId) {
            if (!value.text) {
                item = await this.careReferenceDataStore.condition.getOrLoadAsync({id: value.id, validOn: LocalDate.today() });
            } else {
                item = { name: value.text };
            }
        } else {
            item = { name: value.text };
        }
        return item.name;
    }

    @State.action.bound
    public async getDeviceNameAsync(value: DeviceCodeSelectorItem) {        
        let item;
        if (value.id instanceof DeviceId) {
            if (!value.text) {
                item = await this.careReferenceDataStore.devices.getOrLoadAsync(value.id);
            } else {
                item = { name: value.text };
            }
        } else {
            item = { name: value.text };
        }
        return item.name;
    } 
    
    @State.action.bound
    public async getMedicationStatementSubstanceNameAsync(value: MedicationStatementSubstanceCodeSelectorItem, validOn: LocalDate) {        
        let item;
        if (value.id instanceof InternationalNonproprietaryNameId) {
            if (!value.text) {
                item = await this.careReferenceDataStore.internationalNonproprietaryNames.getOrLoadAsync(value.id);
            } else {
                item = { name: value.text };
            }
        } else if (value.id instanceof MedicationId) {
            if (!value.text) {
                item = await this.careReferenceDataStore.medications.getOrLoadAsync({
                    id: value.id,
                    validOn: validOn
                });
            } else {
                item = { name: value.text };
            }
        } else if (value.id instanceof SubstanceId) {
            if (!value.text) {
                item = await this.careReferenceDataStore.substances.getOrLoadAsync({
                    id: value.id,
                    validOn: validOn
                });
            } else {
                item = { name: value.text };
            }
        } else {
            item = { name: value.text };
        }
        return item.name;
    } 

    @State.action.bound
    public async getLifeStyleRiskNameAsync(value: LifeStyleRiskCodeSelectorItem) {        
        let item;
        if (value.id instanceof LifeStyleRiskId) {
            if (!value.text) {
                item = await this.careReferenceDataStore.lifeStyleRisks.getOrLoadAsync(value.id);
            } else {
                item = { name: value.text };
            }
        } else {
            item = { name: value.text };
        }
        return item.name;
    }
    
    @State.action.bound
    public async getAllergyIntoleranceNameAsync(value: AllergyIntoleranceCodeSelectorItem) {        
        let item;
        if (value.id instanceof AllergyIntoleranceId) {
            if (!value.text) {
                item = await this.careReferenceDataStore.allergyIntolerances.getOrLoadAsync({id: value.id, validOn: LocalDate.today()});
            } else {
                item = { name: value.text };
            }
        } else if (value.id instanceof MedicationId) {
            if (!value.text) {
                item = await this.careReferenceDataStore.medications.getOrLoadAsync({id: value.id, validOn: LocalDate.today()});
            } else {
                item = { name: value.text };
            }
        } else if (value.id instanceof SubstanceId) {
            if (!value.text) {
                item = await this.careReferenceDataStore.substances.getOrLoadAsync({id: value.id, validOn: LocalDate.today()});
            } else {
                item = { name: value.text };
            }
        } else {
            item = { name: value.text };
        }
        return item.name;
    }
    
    @State.action.bound
    public async getMedicalAlertNameAsync(value: MedicalAlertCodeSelectorItem): Promise<string> {
        let item;
        if (value.id instanceof MedicalAlertId) {
            if (!value.text) {
                item = await this.careReferenceDataStore.medicalAlerts.getOrLoadAsync(value.id);
            } else {
                item = { name: value.text };
            }
        } else {
            item = { name: value.text };
        }
        return item.name;
    }
}