import React from "react";
import IPlatformPlugin from "@PluginInterface/IPlatformPlugin";
import DependencyContainer, { Container } from "@DiContainer";
import { ExtensionPointType } from "@PluginInterface/ExtensionPoint/ExtensionPointType";
import IExtensionComponentRegistry from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import IResourceFetcher from "@Toolkit/CommonWeb/Abstractions/Localization/IResourceFetcher";
import "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/DependencyConfiguration/HunEHealthInfrastructureUserManagementDependencyConfiguration";
import "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/DependencyConfiguration/HunEHealthInfrastructureCareDependencyConfiguration";
import "./DependencyConfiguration/HunEHealthInfraStructureDependencyConfiguration";
import "@HunEHealthInfrastructurePlugin/BoundedContexts/Productivity/DependencyConfiguration/ProductivityDependencyConfiguration";
import "@HunEHealthInfrastructurePlugin/BoundedContexts/EesztIntegration/DependencyConfiguration/EesztIntegrationDependencyConfiguration";
import "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/DependencyConfiguration/HunEHealthInfrastructureMedicationRequestDependencyConfiguration";
import "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/DependencyConfiguration/MedicationRequestDependencyConfiguration";
import MedicationSelectorDialog from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationSelectorDialog/MedicationSelectorDialog";
import MedicationSelectorDialogParams from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationSelectorDialog/MedicationSelectorDialogParams";
import PrintPrescriptionDialogParams from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrintPrescriptionDialog/PrintPrescriptionDialogParams";
import PrintPrescriptionDialog from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrintPrescriptionDialog/PrintPrescriptionDialog";
import MedicationSubsidyColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/MedicationSubsidyColumn";
import OrderTypeColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/OrderTypeColumn";
import TargetValueColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/TargetValueColumn";
import MedicationNameColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/MedicationNameColumn";
import ExternalPrescriptionDetailModalParams from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/ExternalMedicationDetailModal/ExternalPrescriptionDetailModalParams";
import ExternalPrescriptionDetailModal from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/ExternalMedicationDetailModal/ExternalPrescriptionDetailModal";
import PreferredMedicationNameColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/PreferredMedicationNameColumn";
import PreferredMedicationNameColumnData from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/PreferredMedicationNameColumnData";
import DosageInfoColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/DosageInfoColumn";
import DosageInfoColumnData from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/DosageInfoColumnData";
import MedicationEquipmentSelectorDialogParams from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationEquipmentSelectorDialog/MedicationEquipmentSelectorDialogParams";
import MedicationEquipmentSelectorDialog from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationEquipmentSelectorDialog/MedicationEquipmentSelectorDialog";
import ViewSubmittedPrescriptionsUseCaseDescriptor from "@HunEHealthInfrastructurePlugin/UseCases/ViewSubmittedPrescriptionsUseCase/ViewSubmittedPrescriptionsUseCaseDescriptor";
import configureMedicationUseCases from "./UseCases/MedicationsUseCase/MedicationUseCaseConfiguration";
import PrescriptionsUseCaseDescriptor from "./UseCases/PrescriptionsUseCase/PrescriptionsUseCaseDescriptor";
import PractitionerRecommendationUseCaseDescriptor from "./UseCases/PractitionerRecommendationUseCase/PractitionerRecommendationUseCaseDescriptor";
import configureFamilyDoctorDocumentUseCases from "./UseCases/FamilyDoctorDocumentUseCase/FamilyDoctorDocumentUseCaseConfiguration";
import FamilyDoctorDocumentUseCaseDescriptor from "./UseCases/FamilyDoctorDocumentUseCase/FamilyDoctorDocumentUseCaseDescriptor";
import CreatePrescriptionCommandFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/OperationProcessing/CreatePrescriptionCommandFactory";
import CreatePrescriptionCommandProcessor from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/OperationProcessing/CreatePrescriptionCommandProcessor";
import ConditionColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/ConditionColumn";
import PermanentPrescriptionsUseCaseDescriptor from "./UseCases/PermanentPrescriptionsUseCase/PermanentPrescriptionsUseCaseDescriptor";
import HunMedicationRequestValidationParameterMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/HunMedicationRequestValidationParameterMapper";
import Prescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Prescription";
import configureMedicationRequestPermissionDefinitions from "@HunEHealthInfrastructurePlugin/PermissionDefinitions/MedicationRequestPermissionDefinitionConfiguration/MedicationRequestPermissionDefinitionsConfiguration";
import HunEHealthInfrastructureReportingExtension from "./BoundedContexts/Reporting/Extensions/HunEHealthInfrastructureReportingExtension";
import IFormExtensionRegistry from "@PluginInterface/FormExtension/IFormExtensionRegistry";
import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import ModalServiceRegistry from "@Toolkit/ReactClient/Components/ModalService/ModalServiceRegistry";
import EhiEReferralDetailDialogParams from "./BoundedContexts/Care/Components/Controls/EhiEReferralDetailDialog/EhiEReferralDetailDialogParams";
import EhiEReferralDetailDialog from "./BoundedContexts/Care/Components/Controls/EhiEReferralDetailDialog/EhiEReferralDetailDialog";
import ManagePrescriptionsExtension from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/FormExtensions/ManagePrescriptionsExtension";
import PrescriptionToEhiDialog from "./BoundedContexts/MedicationRequest/Components/PrescriptionToEhiDialog";
import PrescriptionToEhiDialogParams from "./BoundedContexts/MedicationRequest/Components/PrescriptionToEhiDialog/PrescriptionToEhiDialogParams";
import EhiEmergencyDialogParams from "./Common/Components/EhiEmergencyDialog/EhiEmergencyDialogParams";
import EhiEmergencyDialog from "./Common/Components/EhiEmergencyDialog";
import { loadStaticHunEHealthInfrastructureWebAppResources } from "./StaticResources/StaticHunEHealthInfrastructureWebAppResources";
import configureEhiCovidStatusReportUseCases from "@HunEHealthInfrastructurePlugin/UseCases/CareUseCase/EhiCovidStatusReport/EhiCovidStatusReportUseCaseConfiguration";
import configureCarePermissionDefinitions from "@HunEHealthInfrastructurePlugin/PermissionDefinitions/CarePermissionDefinitionsConfiguration";
import PatientDocumentsExtension from "./BoundedContexts/Care/Extensions/PatientDocumentsExtension/PatientDocumentsExtension";
import EhiDocumentNameExtension from "./BoundedContexts/DocumentManagement/Extensions/EhiDocumentNameExtension/EhiDocumentNameExtension";
import EhiPatientCareActivitiesExtension from "./BoundedContexts/Care/FormExtensions/PatientCareActivities/EhiPatientCareActivitiesExtension";
import EhiSyncStatusColumn from "./BoundedContexts/Care/Components/Controls/EhiSyncStatusColumn/EhiSyncStatusColumn";
import EhiPrescriptionListNameExtension from "./BoundedContexts/MedicationRequest/Extensions/EhiPrescriptionListNameExtension/EhiPrescriptionListNameExtension";
import PatientsCareActivityEhiExtensionComponentService from "./BoundedContexts/Care/Extensions/PatientsCareActivityDetailExtension/PatientsCareActivityEhiExtensionComponentService";
import PatientsCareActivityListButtonsExtensionComponentService from "./BoundedContexts/Care/Extensions/PatientsCareActivityListButtonsExtension/PatientsCareActivityListButtonsExtensionComponentService";
import PatientsCareActivityListInfoExtensionComponentService from "./BoundedContexts/Care/Extensions/PatientsCareActivityListInfoExtension/PatientsCareActivityListInfoExtensionComponentService";
import PatientsCareActivityListWarningExtensionComponentService from "./BoundedContexts/Care/Extensions/PatientsCareActivityListWarningExtension/PatientsCareActivityListWarningExtensionComponentService";
import DiagnosisAdditionalActionsPanel from "./BoundedContexts/Care/Extensions/DiagnosisExtension/DiagnosisAdditionalActions/DiagnosisAdditionalActionsPanel";
import DiagnosisExtensionComponentService from "./BoundedContexts/Care/Extensions/DiagnosisExtension/DiagnosisExtension/DiagnosisExtensionComponentService";
import DiagnosisExtensionPanel from "./BoundedContexts/Care/Extensions/DiagnosisExtension/DiagnosisExtension/DiagnosisExtensionPanel";
import HunEHealthInfrastructureReportingExtensionComponentService from "./BoundedContexts/Reporting/Extensions/HunEHealthInfrastructureReportingExtensionComponentService";
import EhiEReferralExtensionComponentService from "./BoundedContexts/Care/Extensions/EhiEReferralExtension/EhiEReferralExtensionComponentService";
import EhiEReferralClinicalQuestionExtensionComponentService from "./BoundedContexts/Care/Extensions/EhiEReferralClinicalQuestionExtension/EhiEReferralClinicalQuestionExtensionComponentService";
import EhiEReferralClinicalQuestionExtensionPanel from "./BoundedContexts/Care/Extensions/EhiEReferralClinicalQuestionExtension/EhiEReferralClinicalQuestionExtensionPanel";
import EhiErrorSummaryExtensionComponentService from "./BoundedContexts/Care/Extensions/EhiErrorSummaryExtension/EhiErrorSummaryExtensionComponentService";
import EhiErrorSummaryExtensionPanel from "./BoundedContexts/Care/Extensions/EhiErrorSummaryExtension/EhiErrorSummaryExtensionPanel";
import EhiDocumentExtensionComponentService from "./BoundedContexts/DocumentManagement/Extensions/EhiDocumentNameExtension/EhiDocumentExtensionComponentService";
import DiagnosisAdditionalActionsExtensionComponentService from "./BoundedContexts/Care/Extensions/DiagnosisExtension/DiagnosisAdditionalActions/DiagnosisAdditionalActionsExtensionComponentService";
import EhiPrescriptionListNameExtensionComponentService from "./BoundedContexts/MedicationRequest/Extensions/EhiPrescriptionListNameExtension/EhiPrescriptionListNameExtensionComponentService";
import AlwaysVisibleExtensionComponentService from "@PluginInterface/ExtensionPoint/AlwaysVisibleExtensionComponentService";
import EhiCovidSymptomsSeverityColumn from "./BoundedContexts/Care/Components/Controls/EhiCovidSymptomsSeverityColumn/EhiCovidSymptomsSeverityColumn";
import EhiCovidVentilationStatusColumn from "./BoundedContexts/Care/Components/Controls/EhiCovidVentilationStatusColumn/EhiCovidVentilationStatusColumn";
import EhiSelfDeterminationPanel from "./BoundedContexts/Care/Extensions/EhiSelfDeterminationExtension/EhiSelfDeterminationPanel";
import EhiMedicalConditionVersionPanel from "./BoundedContexts/Care/Extensions/EhiMedicalConditionVersionExtension/EhiMedicalConditionVersionPanel";
import EhiPractitionerRecommendationListNameExtension from "./BoundedContexts/MedicationRequest/Extensions/EhiPractitionerRecommendationListNameExtension/EhiPractitionerRecommendationListNameExtension";
import EhiPractitionerRecommendationListNameExtensionComponentService from "./BoundedContexts/MedicationRequest/Extensions/EhiPractitionerRecommendationListNameExtension/EhiPractitionerRecommendationListNameExtensionComponentService";
import EhiOrganizationUnitSelectorDialog from "./BoundedContexts/Organization/Components/Controls/EhiOrganizationUnitSelectorDialog/EhiOrganizationUnitSelectorDialog";
import EhiOrganizationUnitSelectorDialogParams from "./BoundedContexts/Organization/Components/Controls/EhiOrganizationUnitSelectorDialog/EhiOrganizationUnitSelectorDialogParams";
import EhiMedicalConditionMenuItemExtension from "./BoundedContexts/Care/Extensions/EhiMedicalConditionMenuItemExtension/EhiMedicalConditionMenuItemExtension";
import configureTherapyRecommendationUseCases from "./UseCases/CareUseCase/TherapyRecommendation/TherapyRecommendationUseCaseConfiguration";
import EhiMedicalConditionSynchronizationExtensionPanel from "./BoundedContexts/Care/Extensions/EhiMedicalConditionSynchronizationExtension/EhiMedicalConditionSynchronizationExtensionPanel";
import PrescriptionEhiErrorSummaryExtensionComponentService from "./BoundedContexts/MedicationRequest/Extensions/EhiErrorSummaryExtension/PrescriptionEhiErrorSummaryExtensionComponentService";
import { PrescriptionEhiErrorSummaryExtensionPanel } from "./BoundedContexts/MedicationRequest/Extensions/EhiErrorSummaryExtension/PrescriptionEhiErrorSummaryExtensionPanel";
import EhiGlobalBusinessErrorHandlers from "./Common/Components/EhiGlobalErrorHandlers/EhiGlobalBusinessErrorHandlers";
import EhiSamlError from "./Model/EhiSamlError";
import configurePatientBloodTypeUseCases from "./UseCases/CareUseCase/PatientBloodType/PatientBloodTypeUseCaseConfiguration";
import { loadStaticHunEHealthInfrastructureEesztIntegrationResources } from "./BoundedContexts/EesztIntegration/StaticResources/StaticHunEHealthInfrastructureEesztIntegrationResources";
import EhiMedicalConditionSynchronizationErrorHandlerExtension from "./BoundedContexts/Care/Extensions/EhiMedicalConditionSynchronizationExtension/EhiMedicalConditionSynchronizationErrorHandlerExtension";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import RatDeviceCodeSelector from "./Common/Components/RatDeviceCodeSelector/RatDeviceCodeSelector";
import RatDeviceId from "@Primitives/RatDeviceId.g";
import configureRatFormUseCases from "@HunEHealthInfrastructurePlugin/UseCases/CareUseCase/RatForm/RatFormUseCaseConfiguration";
import EhiSyncStatus from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiSyncStatus";
import configurePregnancyRiskFormUseCases from "@HunEHealthInfrastructurePlugin/UseCases/CareUseCase/PregnancyRiskForm/PregnancyRiskFormUseCaseConfiguration";
import { CareActivityEhiEReferralPanelRegistryItem } from "./BoundedContexts/Care/Components/Panels/CareActivityEhiEReferralPanel/CareActivityEhiEReferralPanelFormCustomBlock";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import PermanentPrescriptionId from "@Primitives/PermanentPrescriptionId.g";
import ICommandFactory from "@Toolkit/CommonWeb/CommandProcessing/Definition/ICommandFactory";
import IGenericCommandProcessor from "@Toolkit/CommonWeb/CommandProcessing/Definition/IGenericCommandProcessor";
import IStateChangeBlockingRegistry from "@PluginInterface/BoundedContexts/Care/CareRegister/StateChange/IStateChangeBlockingRegistry";
import configureStateChangeBlockings from "./Configuration/StateChangeBlocking/ConfigureStateChangeBlockings";
import configurePrescriptionUseCases from "./UseCases/PrescriptionsUseCase/PrescriptionUseCaseConfiguration";
import IWidgetRegistry from "@PluginInterface/Dashboard/IWidgetRegistry";
import WidgetName from "@Primitives/WidgetName.g";
import OncologyWidget from "./Common/Components/Widgets/OncologyWidget/OncologyWidget";
import OncologyDataModalParams from "./Common/Components/Widgets/OncologyWidget/OncologyDataModalParams";
import OncologyDataModal from "./Common/Components/Widgets/OncologyWidget/OncologyDataModal";
import StaticHunEHealthInfrastructureCareResources, { loadStaticHunEHealthInfrastructureCareResources } from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import IEhiCovidStatusReportStatusData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IEhiCovidStatusReportStatusData";
import EhiCovidStatusReportStatusColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiCovidStatusReportStatusColumn/EhiCovidStatusReportStatusColumn";
import EhiSyncStatusSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiSyncStatusSelectBox/EhiSyncStatusSelectBox";
import RatDeviceColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/RatDeviceColumn/RatDeviceColumn";
import EhiEReferralExtension from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/EhiEReferralExtension/EhiEReferralExtension";
import EhiMedicalConditionLastSynchronizationSubtitleExtension from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/EhiMedicalConditionLastSynchronizationSubtitleExtension/EhiMedicalConditionLastSynchronizationSubtitleExtension";
import PatientsCareActivityEhiExtension from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/PatientsCareActivityDetailExtension/PatientsCareActivityEhiExtension";
import { PatientsCareActivityListButtonsExtension } from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/PatientsCareActivityListButtonsExtension/PatientsCareActivityListButtonsExtension";
import PatientsCareActivityListCareOrganizationUnitColumnExtension from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/PatientsCareActivityListCareOrganizationUnitColumnExtension/PatientsCareActivityListCareOrganizationUnitColumnExtension";
import PatientsCareActivityListConditionColumnExtension from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/PatientsCareActivityListConditionColumnExtension/PatientsCareActivityListConditionColumnExtension";
import { PatientsCareActivityListInfoExtension } from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/PatientsCareActivityListInfoExtension/PatientsCareActivityListInfoExtension";
import PatientsCareActivityListReferralOrganizationUnitColumnExtension from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/PatientsCareActivityListReferralOrganizationUnitColumnExtension/PatientsCareActivityListReferralOrganizationUnitColumnExtension";
import PatientsCareActivityListExtraFilterExtension from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/PatientsCareActivityListWarningExtension/PatientsCareActivityListWarningExtension";
import ManageMedicalConditionExtension from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/FormExtensions/ManageMedicalConditionExtension";
import ManageServiceRequestExtension from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/FormExtensions/ManageServiceRequestExtension";
import CommunicationLogDownloaderModal from "@HunEHealthInfrastructurePlugin/BoundedContexts/EesztIntegration/Components/Panels/CommunicationLogDownloaderModal";
import CommunicationLogDownloaderModalParams from "@HunEHealthInfrastructurePlugin/BoundedContexts/EesztIntegration/Components/Panels/CommunicationLogDownloaderModal/CommunicationLogDownloaderModalParams";
import EhiLoginDialog from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/Extensions/PractitionerEhiLoginExtension/EhiLoginDialog";
import EhiLoginDialogParams from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/Extensions/PractitionerEhiLoginExtension/EhiLoginDialogParams";
import { loadStaticHunEHealthInfrastructureUserManagementResources } from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/StaticResources/StaticHunEHealthInfrastructureUserManagementResources";
import IRoutingFrameCaseRegistry from "@HisPlatform/Services/Definition/RoutingFrameCaseRegistry/IRoutingFrameCaseRegistry";
import MedicationsPage from "./Components/Pages/MedicationsPage/MedicationsPage";
import medicationStandaloneRouteDefinitions from "./UseCases/MedicationsUseCase/MedicationRoutes";
import NoEhiCompatibleIdentifierFoundError from "./Model/NoEhiCompatibleIdentifierFoundError";
import MedicationInfoModalParams from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/MedicationInfoModal/MedicationInfoModalParams";
import MedicationInfoModal from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/MedicationInfoModal/MedicationInfoModal";
import IPatientCareActivitiesTabRegistry from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabRegistry";
import PrescriptionTabComponentService from "./BoundedContexts/MedicationRequest/Components/Panels/PrescriptionListPanel/PrescriptionTabComponentService";
import WrappedEhrPrescriptionMasterDetailPanel from "./BoundedContexts/MedicationRequest/Components/Panels/PrescriptionListPanel/WrappedEhrPrescriptionMasterDetailPanel";
import TextualPrescriptionTemplatePage from "./BoundedContexts/MedicationRequest/Pages/TextualPrescriptionTemplatePage";
import textualPrescriptionTemplateStandaloneRouteDefinitions from "./UseCases/PrescriptionsUseCase/TextualPrescriptionTemplateRoutes";
import EhiCovidTestResultsPage from "./BoundedContexts/Care/Components/Pages/EhiCovidTestResultsPage/EhiCovidTestResultsPage";
import RatFormPage from "./BoundedContexts/Care/Components/Pages/RatForm/RatFormPage";
import PatientBloodTypePage from "./UseCases/CareUseCase/PatientBloodType/PatientBloodTypePage";
import TherapyRecommendationPage from "./UseCases/CareUseCase/TherapyRecommendation/TherapyRecommendationPage";
import HunPatientRoutes from "./UseCases/PatientMainPageUseCase/HunPatientRoutes";
import EhiEReferralListPage from "./Components/Pages/EhiEReferralListPage/EhiEReferralListPage";
import ehiStandaloneRouteDefinitions from "./UseCases/EhiUseCase/EhiRoutes";
import CovidVaccinationsListPage from "./Components/Pages/CovidVaccinationsListPage/CovidVaccinationsListPage";
import covidVaccinationStandaloneRouteDefinitions from "./UseCases/CovidVaccinationUseCase/CovidVaccinationRoutes";
import { PatientEUCardIdentifierRegistryItem } from "@HunEHealthInfrastructurePlugin/Packages/Patients/Components/PatientEUCardIdentifier/PatientEUCardIdentifierFormCustomBlock";
import { PatientHunSocialSecurityIdentifierRegistryItem } from "@HunEHealthInfrastructurePlugin/Packages/Patients/Components/PatientHunSocialSecurityIdentifier/PatientHunSocialSecurityIdentifierFormCustomBlock";
import { PatientAdditionalHunSocialSecurityIdentifierRegistryItem } from "@HunEHealthInfrastructurePlugin/Packages/Patients/Components/PatientAdditionalHunSocialSecurityIdentifier/PatientAdditionalHunSocialSecurityIdentifierFormCustomBlock";
import PatientAdministrativeDataHunEHealthInfrastructureFormLogic from "./Packages/Patients/FormLogics/PatientAdministrativeDataHunEHealthInfrastructureFormLogic";
import PatientAdministrativeDataHunEHealthInfrastructureDocumentFormLogic from "./Packages/Patients/FormLogics/PatientAdministrativeDataHunEHealthInfrastructureDocumentFormLogic";
import IFormLogicRegistry from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";
import configureEesztIntegrationPermissionDefinitions from "@HunEHealthInfrastructurePlugin/PermissionDefinitions/EesztIntegrationPermissionDefinitionsConfiguration";
import EhiErrorNotificationHandler from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Notifications/EhiErrorNotificationHandler";
import HunSocialSecurityIdentifierDisplay from "./BoundedContexts/Care/Extensions/HunSocialSecurityIdentifierDisplayExtension/HunSocialSecurityIdentifierDisplay";
import HunSocialSecurityIdentifierDisplayExtensionComponentService from "./BoundedContexts/Care/Extensions/HunSocialSecurityIdentifierDisplayExtension/HunSocialSecurityIdentifierDisplayExtensionComponentService";
import CurativeSupplyPassAlertCard from "./BoundedContexts/Care/Extensions/OutpatientHeaderExtension/CurativeSupplyPassAlertCard";
import PatientWidgetExtension from "./BoundedContexts/Care/Extensions/PatientWidgetExtension/PatientWidgetExtension";
import AdmitPatientAndCareActivityBaseDataScreenInfoBoxExtension from "./BoundedContexts/Care/Extensions/AdmitPatientAndCareActivityBaseDataScreenInfoBoxExtension/AdmitPatientAndCareActivityBaseDataScreenInfoBoxExtension";
import PrescriptionsSideMenuItemExtension from "./BoundedContexts/Care/Extensions/PrescriptionsSideMenuItemExtension/PrescriptionsSideMenuItemExtension";
import ICareActivitySourceProvider from "@HisPlatform/BoundedContexts/Care/Services/Definition/CareActivitySourceRegistry/ICareActivitySourceProvider";
import EhiCareActivitySourceProvider from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Services/Implementation/EhiCareActivitySourceProvider/EhiCareActivitySourceProvider";
import CoverageCheckListPage from "@HunSocialSecurityPlugin/BoundedContexts/Care/Pages/CoverageCheckListPage";
import coverageCheckListStandaloneRouteDefinitions from "@HunEHealthInfrastructurePlugin/UseCases/CoverageCheckListUseCase/CoverageCheckListRoutes";
import { loadStaticMedicationRequestResources } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import PatientInvoicesPatientMainMenuItemExtension from "./BoundedContexts/Care/Extensions/PatientInvoicesPatientMainMenuItemExtension/PatientInvoicesPatientMainMenuItemExtension";

class HunEHealthInfrastructurePlugin implements IPlatformPlugin {
    public name: string = "HunEHealthInfrastructurePlugin";

    public async configureAsync(container: Container): Promise<void> {
        const applicationContext = container.get<ICurrentCultureProvider>("ICurrentCultureProvider");
        const resourceFetcher = container.get<IResourceFetcher>("IResourceFetcher");

        const medicationRequestValidationParameterMapper = container.get<HunMedicationRequestValidationParameterMapper>("HunMedicationRequestValidationParameterMapper");

        const validationParameterMapperService = container.get<ValidationProblemParameterMapperService>("ValidationProblemParameterMapperService");
        validationParameterMapperService.registerMapper("Prescription", medicationRequestValidationParameterMapper);

        await resourceFetcher.fetchResourceGroupAsync("HunEHealthInfrastructureValidationMessages", "ValidationMessages", applicationContext.cultureCode);
        await resourceFetcher.fetchResourceGroupAsync("HunEHealthInfrastructureWebApp", "WebApp", applicationContext.cultureCode);
        await resourceFetcher.fetchResourceGroupAsync("HunEHealthInfrastructureReferenceData", "ReferenceData", applicationContext.cultureCode);

        loadStaticMedicationRequestResources();
        loadStaticHunEHealthInfrastructureUserManagementResources();
        loadStaticHunEHealthInfrastructureCareResources();
        loadStaticHunEHealthInfrastructureWebAppResources();
        loadStaticHunEHealthInfrastructureEesztIntegrationResources();
        container.bind("ManagePrescriptionsExtension").to(ManagePrescriptionsExtension);
        container.bind("ManageServiceRequestExtension").to(ManageServiceRequestExtension);
        container.bind("ManageMedicalConditionExtension").to(ManageMedicalConditionExtension);
        container.bind("EhiPatientCareActivitiesExtension").to(EhiPatientCareActivitiesExtension);

        this.configureExtensionComponentServices(container);
        this.configureOperationProcessing(container);

        const registry = container.get<IPatientCareActivitiesTabRegistry>("IPatientCareActivitiesTabRegistry");

        registry.register("Prescriptions", 61, "PrescriptionTabComponentService", WrappedEhrPrescriptionMasterDetailPanel);

        container.bind("PrescriptionTabComponentService").to(PrescriptionTabComponentService);

        container.bind("PatientAdministrativeDataHunEHealthInfrastructureFormLogic")
            .to(PatientAdministrativeDataHunEHealthInfrastructureFormLogic);

        container.bind("PatientAdministrativeDataHunEHealthInfrastructureDocumentFormLogic")
            .to(PatientAdministrativeDataHunEHealthInfrastructureDocumentFormLogic);
    }

    public configureExtensionComponents(registry: IExtensionComponentRegistry<ExtensionPointType>): void {
        registry.register("patientsCareActivityDetail", PatientsCareActivityEhiExtension, "PatientsCareActivityEhiExtensionComponentService");
        registry.register("patientsCareActivityListButtons", PatientsCareActivityListButtonsExtension, "PatientsCareActivityListButtonsExtensionComponentService");
        registry.register("patientsCareActivityListExtraFilter", PatientsCareActivityListExtraFilterExtension, "PatientsCareActivityListWarningExtensionComponentService");
        registry.register("patientsCareActivityListInfo", PatientsCareActivityListInfoExtension, "PatientsCareActivityListInfoExtensionComponentService");
        registry.register("prescriptionsSideMenuItem", PrescriptionsSideMenuItemExtension, "AlwaysVisibleExtensionComponentService");
        registry.register("diagnosisAdditionalActions", DiagnosisAdditionalActionsPanel, "DiagnosisAdditionalActionsExtensionComponentService");
        registry.register("diagnosisExtension", DiagnosisExtensionPanel, "DiagnosisExtensionComponentService");
        registry.register("reportingExtension", HunEHealthInfrastructureReportingExtension, "HunEHealthInfrastructureReportingExtensionComponentService");
        registry.register("ServiceRequestReferralData", EhiEReferralExtension, "EhiEReferralExtensionComponentService");
        registry.register("ServiceRequestClinicalQuestion", EhiEReferralClinicalQuestionExtensionPanel, "EhiEReferralClinicalQuestionExtensionComponentService");
        registry.register("ServiceRequestHeader", EhiErrorSummaryExtensionPanel, "EhiErrorSummaryExtensionComponentService");
        registry.register("prescriptionHeader", PrescriptionEhiErrorSummaryExtensionPanel, "PrescriptionEhiErrorSummaryExtensionComponentService");
        registry.register("documentListName", EhiDocumentNameExtension, "EhiDocumentExtensionComponentService");
        registry.register("prescriptionListName", EhiPrescriptionListNameExtension, "EhiPrescriptionListNameExtensionComponentService");
        registry.register("selfDeterminationExtension", EhiSelfDeterminationPanel, "AlwaysVisibleExtensionComponentService");
        registry.register("medicalConditionSynchronizationExtension", EhiMedicalConditionSynchronizationExtensionPanel, "AlwaysVisibleExtensionComponentService");
        registry.register("medicalConditionSynchronizationErrorHandlerExtension", EhiMedicalConditionSynchronizationErrorHandlerExtension, "AlwaysVisibleExtensionComponentService");
        registry.register("medicalConditionLastSynchronizationSubtitleExtension", EhiMedicalConditionLastSynchronizationSubtitleExtension, "AlwaysVisibleExtensionComponentService");
        registry.register("medicalConditionVersionExtension", EhiMedicalConditionVersionPanel, "AlwaysVisibleExtensionComponentService");
        registry.register("patientsCareActivityListConditionColumn", PatientsCareActivityListConditionColumnExtension, "AlwaysVisibleExtensionComponentService");
        registry.register("patientsCareActivityListCareOrganizationUnitColumn", PatientsCareActivityListCareOrganizationUnitColumnExtension, "AlwaysVisibleExtensionComponentService");
        registry.register("patientsCareActivityListReferralOrganizationUnitColumn", PatientsCareActivityListReferralOrganizationUnitColumnExtension, "AlwaysVisibleExtensionComponentService");
        registry.register("practitionerRecommendationListName", EhiPractitionerRecommendationListNameExtension, "EhiPractitionerRecommendationListNameExtensionComponentService");
        registry.register("ehrDocumentsExtension", PatientDocumentsExtension, "AlwaysVisibleExtensionComponentService");
        registry.register("medicalConditionMenuItemExtension", EhiMedicalConditionMenuItemExtension, "AlwaysVisibleExtensionComponentService");
        registry.register("globalErrorHandler", EhiGlobalBusinessErrorHandlers, "AlwaysVisibleExtensionComponentService");
        registry.register("rootApplicationExtension", EhiErrorNotificationHandler, "AlwaysVisibleExtensionComponentService");
        registry.register("identifierDisplay", HunSocialSecurityIdentifierDisplay, "HunSocialSecurityIdentifierDisplayExtensionComponentService");
        registry.register("curativeSupplyPassInfoExtension", CurativeSupplyPassAlertCard, "AlwaysVisibleExtensionComponentService");
        registry.register("patientWidgetContent", PatientWidgetExtension, "AlwaysVisibleExtensionComponentService");
        registry.register("admitPatientAndCareActivityBaseDataScreenInfoBoxExtension", AdmitPatientAndCareActivityBaseDataScreenInfoBoxExtension, "AlwaysVisibleExtensionComponentService");
        registry.register("patientInvoicesPatientMainMenuItem", PatientInvoicesPatientMainMenuItemExtension, "AlwaysVisibleExtensionComponentService");
    }

    private configureExtensionComponentServices(container: Container) {
        container.bind("PatientsCareActivityEhiExtensionComponentService")
            .to(PatientsCareActivityEhiExtensionComponentService);
        container.bind("PatientsCareActivityListButtonsExtensionComponentService")
            .to(PatientsCareActivityListButtonsExtensionComponentService);
        container.bind("PatientsCareActivityListWarningExtensionComponentService")
            .to(PatientsCareActivityListWarningExtensionComponentService);
        container.bind("PatientsCareActivityListInfoExtensionComponentService")
            .to(PatientsCareActivityListInfoExtensionComponentService);
        container.bind("DiagnosisAdditionalActionsExtensionComponentService")
            .to(DiagnosisAdditionalActionsExtensionComponentService);
        container.bind("DiagnosisExtensionComponentService")
            .to(DiagnosisExtensionComponentService);
        container.bind("HunEHealthInfrastructureReportingExtensionComponentService")
            .to(HunEHealthInfrastructureReportingExtensionComponentService);
        container.bind("EhiEReferralExtensionComponentService")
            .to(EhiEReferralExtensionComponentService);
        container.bind("EhiEReferralClinicalQuestionExtensionComponentService")
            .to(EhiEReferralClinicalQuestionExtensionComponentService);
        container.bind("EhiErrorSummaryExtensionComponentService")
            .to(EhiErrorSummaryExtensionComponentService);
        container.bind("PrescriptionEhiErrorSummaryExtensionComponentService")
            .to(PrescriptionEhiErrorSummaryExtensionComponentService);
        container.bind("EhiDocumentExtensionComponentService")
            .to(EhiDocumentExtensionComponentService);
        container.bind("EhiPrescriptionListNameExtensionComponentService")
            .to(EhiPrescriptionListNameExtensionComponentService);
        container.bind("EhiPractitionerRecommendationListNameExtensionComponentService")
            .to(EhiPractitionerRecommendationListNameExtensionComponentService);
        container.bind("HunSocialSecurityIdentifierDisplayExtensionComponentService")
            .to(HunSocialSecurityIdentifierDisplayExtensionComponentService);
        container
            .bind("ICareActivitySourceProvider")
            .to(EhiCareActivitySourceProvider)
            .inSingletonScope();
    }

    public configureStateChangeBlockings(stateChangeBlockingRegistry: IStateChangeBlockingRegistry) {
        configureStateChangeBlockings(stateChangeBlockingRegistry);
    }

    public configureUseCases(useCaseRegistry: IUseCaseRegistry): void {
        useCaseRegistry.add(PrescriptionsUseCaseDescriptor);
        useCaseRegistry.add(PractitionerRecommendationUseCaseDescriptor);
        useCaseRegistry.add(ViewSubmittedPrescriptionsUseCaseDescriptor);
        useCaseRegistry.add(FamilyDoctorDocumentUseCaseDescriptor);
        useCaseRegistry.add(PermanentPrescriptionsUseCaseDescriptor);

        configureEhiCovidStatusReportUseCases(useCaseRegistry);
        configureTherapyRecommendationUseCases(useCaseRegistry);
        configurePatientBloodTypeUseCases(useCaseRegistry);
        configureRatFormUseCases(useCaseRegistry);
        configurePregnancyRiskFormUseCases(useCaseRegistry);
        configureMedicationUseCases(useCaseRegistry);
        configureFamilyDoctorDocumentUseCases(useCaseRegistry);
        configurePrescriptionUseCases(useCaseRegistry);
    }

    public configureWidgets(widgetRegistry: IWidgetRegistry): void {
        widgetRegistry.register(new WidgetName("OncologyWidget"), OncologyWidget);
    }

    public configureModals(modalRegistry: ModalServiceRegistry) {
        modalRegistry.register(MedicationInfoModalParams.type, MedicationInfoModal);
        modalRegistry.register(EhiLoginDialogParams.type, EhiLoginDialog);
        modalRegistry.register(EhiEReferralDetailDialogParams.type, EhiEReferralDetailDialog);
        modalRegistry.register(PrescriptionToEhiDialogParams.type, PrescriptionToEhiDialog);
        modalRegistry.register(EhiEmergencyDialogParams.type, EhiEmergencyDialog);
        modalRegistry.register(EhiOrganizationUnitSelectorDialogParams.type, EhiOrganizationUnitSelectorDialog);
        modalRegistry.register(CommunicationLogDownloaderModalParams.type, CommunicationLogDownloaderModal);
        modalRegistry.register(MedicationSelectorDialogParams.type, MedicationSelectorDialog);
        modalRegistry.register(MedicationEquipmentSelectorDialogParams.type, MedicationEquipmentSelectorDialog);
        modalRegistry.register(PrintPrescriptionDialogParams.type, PrintPrescriptionDialog);
        modalRegistry.register(ExternalPrescriptionDetailModalParams.type, ExternalPrescriptionDetailModal);
        modalRegistry.register(OncologyDataModalParams.type, OncologyDataModal);
    }

    public configureFormExtensions(formExtensionRegistry: IFormExtensionRegistry): void {
        formExtensionRegistry.register("ManagePrescriptions", "ManagePrescriptionsExtension");
        formExtensionRegistry.register("ManageServiceRequest", "ManageServiceRequestExtension");
        formExtensionRegistry.register("ManageMedicalCondition", "ManageMedicalConditionExtension");
        formExtensionRegistry.register("PatientCareActivities", "EhiPatientCareActivitiesExtension");
    }

    public configureFormLogics(formLogicRegistry: IFormLogicRegistry): void {
        formLogicRegistry.register("PatientAdministrativeDataForm",
            "PatientAdministrativeDataHunEHealthInfrastructureFormLogic");
     
        formLogicRegistry.register("PatientAdministrativeDataForm",
            "PatientAdministrativeDataHunEHealthInfrastructureDocumentFormLogic");
    }

    public configurePermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry): void {
        configureCarePermissionDefinitions(permissionDefinitionRegistry);
        configureMedicationRequestPermissionDefinitions(permissionDefinitionRegistry);
        configureEesztIntegrationPermissionDefinitions(permissionDefinitionRegistry);
    }

    public configureActivities(activityRegistry: IActivityRegistry) {
        activityRegistry.register({ reference: "Care_EditEhiCovidStatusReports", iconName: "covid" });
        activityRegistry.register({ reference: "Care_ViewReadOnlyEhiCovidStatusReports", iconName: "covid" });
        activityRegistry.register({ reference: "Care_EditRatForms", iconName: "covid" });
        activityRegistry.register({ reference: "Care_ViewReadOnlyRatForms", iconName: "covid" });
        activityRegistry.register({ reference: "Care_EditPregnancyRiskForms", iconName: "pregnant" });
        activityRegistry.register({ reference: "Care_ViewReadOnlyPregnancyRiskForms", iconName: "pregnant" });
        activityRegistry.register({ reference: "AddEhiCovidStatusReport", iconName: "plus" });
        activityRegistry.register({ reference: "MedicalCondition_TherapyRecommendation_AddTherapyRecommendation", iconName: "plus" });
        activityRegistry.register({ reference: "MedicalCondition_TherapyRecommendation_EditTherapyRecommendation", iconName: "angleRight" });
        activityRegistry.register({ reference: "MedicalCondition_TherapyRecommendation_DeleteTherapyRecommendation", iconName: "remove" });
        activityRegistry.register({ reference: "MedicalCondition_PatientBloodType_AddPatientBloodType", iconName: "plus" });
        activityRegistry.register({ reference: "MedicalCondition_PatientBloodType_EditPatientBloodType", iconName: "angleRight" });
        activityRegistry.register({ reference: "MedicalCondition_PatientBloodType_DeletePatientBloodType", iconName: "remove" });
        activityRegistry.register({ reference: "RatForm_AddRatForm", iconName: "plus" });
        activityRegistry.register({ reference: "RatForm_EditRatForm", iconName: "pen" });
        activityRegistry.register({ reference: "RatForm_DeleteRatForm", iconName: "remove" });
        activityRegistry.register({ reference: "RatForm_ViewRatForm", iconName: "angleRight" });
        activityRegistry.register({ reference: "PregnancyRiskForm_AddPregnancyRiskForm", iconName: "plus" });
        activityRegistry.register({ reference: "PregnancyRiskForm_EditPregnancyRiskForm", iconName: "pen" });
        activityRegistry.register({ reference: "PregnancyRiskForm_DeletePregnancyRiskForm", iconName: "remove" });
        activityRegistry.register({ reference: "PregnancyRiskForm_ViewPregnancyRiskForm", iconName: "angleRight" });
        return;
    }

    public configureNData(configurator: INDataAttributeConfigurator): void {
        configurator.registerValueConverter("EhiCovidStatusReportStatusDto", rawValue => ({
            ehiCovidStatusReportStatus: rawValue.EhiCovidStatusReportStatus,
            ehiSyncStatus: rawValue.EhiSyncStatus
        } as IEhiCovidStatusReportStatusData));

        configurator.registerColumn("MedicationSubsidyClaimTypeId", <MedicationSubsidyColumn displayMode="badge" isOrderable={false} />);
        configurator.registerColumn("OrderTypeId", <OrderTypeColumn />);
        configurator.registerColumn("TargetValueId", <TargetValueColumn />);
        configurator.registerColumn("MedicationNameDto", <MedicationNameColumn />);
        configurator.registerColumn("EhiCovidStatusReportStatusDto", <EhiCovidStatusReportStatusColumn />);
        configurator.registerColumn("EhiCovidSymptomsSeverityId", <EhiCovidSymptomsSeverityColumn />);
        configurator.registerColumn("EhiCovidVentilationStatusId", <EhiCovidVentilationStatusColumn />);
        configurator.registerColumn("RatDeviceId", <RatDeviceColumn />);
        configurator.registerColumn("EhiSyncStatus", <EhiSyncStatusColumn />);
        configurator.registerColumn("MedicationSubsidyClassificationDataDto", null as React.ReactElement);

        configurator.registerValueConverter("MedicationSubsidyClassificationDataDto", rawValue => rawValue?.Classification);

        this.configureNDataForPermanentPrescriptions(configurator);
    }

    private configureNDataForPermanentPrescriptions(configurator: INDataAttributeConfigurator) {
        configurator.registerValueConverter("PreferredMedicationNameDto", (rawValue: any) => {
            return new PreferredMedicationNameColumnData(rawValue.Name, rawValue.PreferredName);
        });
        configurator.registerValueConverter("DosageInfoDto", (rawValue: any) => {
            return new DosageInfoColumnData(new PermanentPrescriptionId(rawValue.Value.toString()));
        });

        configurator.registerColumn("PreferredMedicationNameDto", <PreferredMedicationNameColumn />);
        configurator.registerColumn("DosageInfoDto", <DosageInfoColumn />);
        configurator.registerColumn("ConditionId", <ConditionColumn />);
    }

    public configureMapper(configurator: IMapperConfigurator): void {
        configurator.registerByName("EhiSamlError", _ => new EhiSamlError());
        configurator.registerByName("NoEhiCompatibleIdentifierFoundError", _ => new NoEhiCompatibleIdentifierFoundError());
        return;
    }

    private configureOperationProcessing(container: Container): void {
        container.bind("ICommandFactory").to(CreatePrescriptionCommandFactory);
        container.bind("IGenericCommandProcessor").to(CreatePrescriptionCommandProcessor);
    }

    public configureFormEngine(registry: FormEditorRegistry): void {
        registry.registerCustomBlock("PatientEuCardIdentifier", PatientEUCardIdentifierRegistryItem);
        registry.registerCustomBlock("PatientHunSocialSecurityIdentifier", PatientHunSocialSecurityIdentifierRegistryItem);
        registry.registerCustomBlock("PatientAdditionalHunSocialSecurityIdentifier", PatientAdditionalHunSocialSecurityIdentifierRegistryItem);

        registry.registerEntityReferenceEditor(
            "RatDevice",
            {
                displayName: StaticHunEHealthInfrastructureCareResources.RatDevice.FormEngineEntityReferenceDisplayName,
                componentType: RatDeviceCodeSelector,
                entityIdCtor: RatDeviceId,
                loadReferenceDataAsync: (id: RatDeviceId | RatDeviceId[]) => {
                    if (Array.isArray(id)) {
                        DependencyContainer.get<HunEhiCareReferenceDataStore>("HunEhiCareReferenceDataStore").ratDevices.ensureLoadedAsync(id);
                    } else {
                        DependencyContainer.get<HunEhiCareReferenceDataStore>("HunEhiCareReferenceDataStore").ratDevices.ensureLoadedAsync([id]);
                    }
                    return Promise.resolve();
                }
            }
        );

        registry.registerEnumEditor(
            "EhiSyncStatus",
            {
                displayName: StaticHunEHealthInfrastructureCareResources.EhiSyncStatus.Label,
                componentType: EhiSyncStatusSelectBox,
                enumType: EhiSyncStatus
            }
        );

        registry.registerCustomBlock("EReferralPanel", CareActivityEhiEReferralPanelRegistryItem);
    }

    public configureRoutingFrameCases(registry: IRoutingFrameCaseRegistry) {
        registry.register("Application", MedicationsPage, medicationStandaloneRouteDefinitions.medications, "medications");
        registry.register("Application", TextualPrescriptionTemplatePage, textualPrescriptionTemplateStandaloneRouteDefinitions.textualPrescriptionTemplates, "textualPrescriptionTemplates");
        registry.register("Application", EhiEReferralListPage, ehiStandaloneRouteDefinitions.eReferrals, "eReferrals");
        registry.register("Application", CovidVaccinationsListPage, covidVaccinationStandaloneRouteDefinitions.covidVaccinations, "covidVaccinations");
        registry.register("Application", CoverageCheckListPage, coverageCheckListStandaloneRouteDefinitions.coverageCheckList, "coverageCheckList");

        registry.register("PatientMainPage", TherapyRecommendationPage, HunPatientRoutes.therapyRecommendation, "therapyRecommendation");
        registry.register("PatientMainPage", PatientBloodTypePage, HunPatientRoutes.patientBloodType, "patientBloodType");
        registry.register("PatientMainPage", EhiCovidTestResultsPage, HunPatientRoutes.ehiCovidTestResults, "ehiCovidTestResults");
        registry.register("PatientMainPage", RatFormPage, HunPatientRoutes.ratFormPatientBased, "ratForm");
    }
}

export default new HunEHealthInfrastructurePlugin();