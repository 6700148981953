import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { getUseCaseAsUrlParam, parseUseCaseStateFromUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCases from "@Primitives/UseCases";
import IPerformanceStatementCareActivityRouteParams from "@HunSocialSecurityPlugin/UseCases/PerformanceStatementUseCase/IPerformanceStatementCareActivityDetailRouteParams";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import { ActionDispatcherAdapter } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import ShowPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientScreenAction.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import { ScreenNavigationContextAdapter, ScreenNavigationContextStore } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import performanceStatementStandaloneRouteDefinitions from "@HunSocialSecurityPlugin/UseCases/PerformanceStatementUseCase/PerformanceStatementRoutes";
import { HisErrorBoundary } from "@HisPlatformControls";
import PageBoxContainer from "@CommonControls/PageBoxContainer";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import PerformanceStatementCareActivityWorklistPanel from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityWorklistPanel/PerformanceStatementCareActivityWorklistPanel";

interface IPerformanceStatementCareActivityPageDependencies {
    routingStore: GlobalRoutingStore;
    applicationContext: ApplicationContext;
}

interface IPerformanceStatementCareActivityPageProps extends IRoutingFrameContentProps<IPerformanceStatementCareActivityRouteParams> {
    _dependencies?: IPerformanceStatementCareActivityPageDependencies;
    _actionDispatcher?: IActionDispatcher;
    _screenNavigationContext?: ScreenNavigationContextStore;
    _modalService?: IModalService;
}

@State.observer
class PerformanceStatementCareActivityPage extends React.Component<IPerformanceStatementCareActivityPageProps> {

    private get routingController(): IRoutingController<IPerformanceStatementCareActivityRouteParams> {
        return this.props.routingController;
    }

    public componentWillMount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(new UseCaseIdentifier("PerformanceStatement_CareActivities"));
    }

    public componentWillUnmount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(null);
    }

    @State.bound
    private setUseCaseState(rowId: string, useCaseState: INDataUseCaseState) {
        if (useCaseState?.useCase?.value === UseCases.patientRegisterPatientData) {
            const patientId = useCaseState.useCaseArguments[0].value;

            this.props._actionDispatcher.dispatchAsync(
                new ShowPatientScreenAction(ScreenDisplayMode.Full, patientId, null),
                { navigationContext: this.props._screenNavigationContext, modalService: this.props._modalService });
        } else {
            this.routingController.replace(performanceStatementStandaloneRouteDefinitions.performanceStatementCareActivity.makeRoute({
                selectedRowId: rowId ?? "null",
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            }));
        }
    }

    @State.computed
    private get useCaseState() {
        if (!this.props.routingController.currentRoute.parameters.useCase) {
            return null;
        }
        const useCaseState = this.props.routingController.currentRoute.parameters.useCase;

        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.computed
    private get selectedRowId(): string {
        return this.props.routingController.currentRoute.parameters.selectedRowId;
    }

    public render() {
        return (
            <PageBoxContainer>
                <HisErrorBoundary>
                    <SingleLayout>
                        <PerformanceStatementCareActivityWorklistPanel
                            onChange={this.setUseCaseState}
                            useCaseState={this.useCaseState}
                            selectedRowId={this.selectedRowId}
                        />
                    </SingleLayout>
                </HisErrorBoundary>
            </PageBoxContainer>
        );
    }
}

export default connect(
    withHisErrorBoundary(PerformanceStatementCareActivityPage),
    new DependencyAdapter<IPerformanceStatementCareActivityPageProps, IPerformanceStatementCareActivityPageDependencies>(container => {
        return {
            routingStore: container.resolve("GlobalRoutingStore"),
            applicationContext: container.resolve("ApplicationContext")
        };
    }),
    new ActionDispatcherAdapter(),
    new ScreenNavigationContextAdapter(),
    new HisModalServiceAdapter()
);
