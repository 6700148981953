import StoreMapper from "@Toolkit/CommonWeb/ApiAdapter/StoreMapper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import * as BffProxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import PropertyGroup from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyGroup";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import convertPropertyValue from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/DynamicProperties/PropertyValueHelper";
import Di from "@Di";

type ResponseType = Proxy.GetPropertyGroupsForEditingQueryResponse
                    | Proxy.UpdatePropertyGroupsCommandResponse
                    | BffProxy.GetServiceRequestScreenDataCommandResponse;

@Di.injectable()
export default class PropertyGroupStoreMapper extends StoreMapper<SimpleStore<PropertyGroup[]>, ResponseType> {

    protected applyToStoreCore(target: SimpleStore<PropertyGroup[]>, response: ResponseType) {
        const propertyGroups = PropertyGroupStoreMapper.getPropertyGroups(response) ?? [];
        target.value = propertyGroups.map(pg => {
            const propertyGroup = new PropertyGroup();

            propertyGroup.definitionId = pg.propertyGroupDefinitionId;
            propertyGroup.name = pg.name;
            propertyGroup.properties = pg.properties
                .map(prop => convertPropertyValue(prop))
                .filter(Boolean);

            return propertyGroup;
        });

        target.operationInfo = createOperationInfo(response);
    }

    private static getPropertyGroups(response: ResponseType) {
        return response instanceof BffProxy.GetServiceRequestScreenDataCommandResponse
            ? response.dynamicProperties
            : response.propertyGroups;
    }
}