import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import NameStore from "@Primitives/NameStore";
import ServiceRequestSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ServiceRequestSubjectStore";

export interface IServiceRequestAppointmentModalParams {
    appointment: Appointment;

    serviceRequestSubject: ServiceRequestSubjectStore;
    serviceRequestPointOfCareId: PointOfCareId;
    patientNameStore: NameStore;
    
    onValidateAsync: () => Promise<IClientValidationResult[]>;
    onSetAppointment: (appointment: Appointment) => void;
    onSetPractitioner: () => void;
}

export default class ServiceRequestAppointmentModalParams implements IServiceRequestAppointmentModalParams {
    public static type = "ServiceRequestAppointmentModal";
    public get type() { return ServiceRequestAppointmentModalParams.type; }

    constructor(
        public readonly appointment: Appointment,
        public readonly serviceRequestSubject: ServiceRequestSubjectStore,
        public readonly serviceRequestPointOfCareId: PointOfCareId,
        public readonly patientNameStore: NameStore,
        public readonly onValidateAsync: () => Promise<IClientValidationResult[]>,
        public readonly onSetAppointment: (appointment: Appointment) => void,
        public readonly onSetPractitioner: () => void
        ) {

    }
}