import React from "react";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import * as Ui from "@CommonControls";
import * as Styles from "@HisPlatform/BoundedContexts/UserManagement/Components/Controls/UserHeader/UserHeader.less";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import _ from "@HisPlatform/Common/Lodash";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import PractitionerIdentifierStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerIdentifierStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IUserHeaderDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    commonReferenceDataDataStore: CommonReferenceDataDataStore;
}

interface IUserHeaderProps {
    _dependencies?: IUserHeaderDependencies;
    user: User;
    practitioner: PractitionerStore;
}

class UserHeader extends React.Component<IUserHeaderProps> {

    @State.bound
    private getIdentifierText(identifier: PractitionerIdentifierStore) {
        const systemName = this.props._dependencies.commonReferenceDataDataStore.identifierSystemMap.getLocalization(identifier.identifier.identifierSystemId);
        return `${identifier.identifier.value} (${systemName.Name})`;
    }

    public render() {
        const jobPosition = this.props.practitioner?.jobPositionId && this.props._dependencies.organizationReferenceDataStore.jobPositions.get(this.props.practitioner.jobPositionId);
        const identifiersText = this.props.practitioner && _.join(this.props.practitioner.identifiers.map(this.getIdentifierText), ", ");
        return (
            <Ui.Flex className={Styles.headerContainer}>
                <Ui.Flex.Item>
                    {this.props.user?.displayName}
                    {!this.props.user.isNew && jobPosition &&
                    <div>
                        {jobPosition.displayValue.Name}
                    </div>
                    }
                </Ui.Flex.Item>
                {this.props.practitioner &&
                <Ui.Flex.Item className={Styles.identifierMargin}>
                    {StaticUserManagementResources.UserGroupManagementPanel.RolePanel.Identifiers}: {identifiersText}
                </Ui.Flex.Item>
                }
            </Ui.Flex>
        );
    }
}

export default connect(
    UserHeader,
    new DependencyAdapter<IUserHeaderProps, IUserHeaderDependencies>((container) => {
        return {
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            commonReferenceDataDataStore: container.resolve("CommonReferenceDataDataStore")
        };
    })
);
