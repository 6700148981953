import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IMultiEntitySelectorPublicProps } from "@CommonControls/MultiEntitySelectorBase";
import UserId from "@Primitives/UserId.g";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import UsersApiAdapter from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Users/UsersApiAdapter";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";

interface IUserMultiSelectorDependencies {
    userApiAdapter: UsersApiAdapter;
    dialogService: IDialogService;
}

interface IUserMultiSelectorProps extends IMultiEntitySelectorPublicProps<UserId, User> {
    _dependencies?: IUserMultiSelectorDependencies;
    currentUserId?: UserId; // If given, the components prompts whether it should remove the current user.
    selectedIds: UserId[];
    onChange: (userIds: UserId[]) => void;
}

@State.observer
class UserMultiSelector extends React.Component<IUserMultiSelectorProps> {

    @State.observable.ref private allUsersDataSource = new InMemoryDataGridDataSource(() => this.allUsers);
    @State.observable.ref private selectedUsersDataSource = new InMemoryDataGridDataSource(() => this.selectedUsers);

    @State.observable.ref private allUsers: User[] = [];
    @State.observable.ref private selectedUsers: User[] = [];

    @State.action.bound
    private setDataSource(users: User[]) {
        this.allUsers = users;
    }

    @State.action.bound
    private setSelectedDataSource(users: User[]) {
        this.selectedUsers = users;
    }

    private get dependencies() {
        return this.props._dependencies;
    }

    constructor(props: IUserMultiSelectorProps) {
        super(props);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.reloadAsync(), this);
    }

    public componentDidUpdate() {
        dispatchAsyncErrors(this.reloadAsync(), this);
    }

    @State.bound
    private getFilteredValue(value: string) {
        const user = this.allUsers.find(u => {
            return u.displayName === value;
        });
        return user.displayName;
    }

    @State.action.bound
    private addNewItem(item: User) {
        this.selectedUsers = [...this.selectedUsers, item];
        this.props.onChange(this.selectedUsers.map(i => i.id));
    }
    @State.action.bound
    private removeItem(item: User) {
        this.selectedUsers = [...this.selectedUsers].filter(i => i !== item);
        this.props.onChange(this.selectedUsers.map(i => i.id));
    }

    public render() {
        return (
            <Ui.MultiEntitySelectorBase
                {...this.props}
                orientation="horizontal"
                pageSize={10}
                renderColumns={this.renderAllItemsColumn}
                renderSelectedColumns={this.renderChosenItemsColumn}
                allItemsDataSource={this.allUsersDataSource}
                selectedItemsDataSource={this.selectedUsersDataSource}
                onAdded={this.addNewItem}
                onRemoved={this.removeItem}
                canRemove={this.canRemoveAsync}
                generateInitialFilterStore
                changeOnMount
                automationId="__multiEntitySelector"
            />
        );
    }

    @State.bound
    private async canRemoveAsync(id: UserId) {
        if (this.props.currentUserId && this.props.currentUserId.value === id.value) {
            const answer = await this.dependencies.dialogService.yesNo(StaticUserManagementResources.UserMultiSelector.Dialog.ConfirmationTitle, StaticUserManagementResources.UserMultiSelector.Dialog.RemoveCurrentUserFromPermissionsConfirmation);
            if (answer.resultCode === DialogResultCode.Yes) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    @State.bound
    private async reloadAsync() {
        const selectedUserIds = this.props.selectedIds;
        const userList = await this.dependencies.userApiAdapter.loadAllUsersAsync();
        const selectedUsers = userList.users.filter(i => selectedUserIds?.some(j => ValueWrapper.equals(i.id, j)));

        this.setDataSource(userList.users);
        this.setSelectedDataSource(selectedUsers);
    }

    @State.action.bound
    private renderAllItemsColumn() {
        return this.renderColumns(StaticWebAppResources.Common.Label.All ? StaticWebAppResources.Common.Label.All : "");
    }

    @State.action.bound
    private renderChosenItemsColumn() {
        return this.renderColumns(StaticWebAppResources.Common.Label.SelectedValue ? StaticWebAppResources.Common.Label.SelectedValue : "");
    }

    private renderColumns(header: string) {
        return (
            <DataGridColumn
                dataGetter={"displayName"}
                clientSideOrderableValueGetter={"displayName"}
                clientSideFilterableValueGetter={this.getFilteredValue}
                header={header}
                automationId="Name"
                isOrderable
                isFilterable />
        );
    }
}

export default connect(
    UserMultiSelector,
    new DependencyAdapter<IUserMultiSelectorProps, IUserMultiSelectorDependencies>(container => {
        return {
            userApiAdapter: container.resolve("UsersApiAdapter"),
            dialogService: container.resolve("IDialogService")
        };
    })
);
