import React from "react";
import State, { IObservableValue } from "@Toolkit/ReactClient/Common/StateManaging";
import OrganizationUnitIcon from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/OrganizationUnitIcon";
import { HighlightableLabel } from "@CommonControls";
import OrganizationUnitTreeNode from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnitTreeNode";

interface IOrganizationUnitTreeNodeProps {
    node: OrganizationUnitTreeNode;
}

interface INodeNameProps extends IOrganizationUnitTreeNodeProps {
    filterValueBox?: IObservableValue<string>;
}


const OrganizationUnitName: React.FC<INodeNameProps> = State.observer(props => {
    return (
        <>
            <OrganizationUnitIcon
                definitionCode={props.node.definitionCode}
                healthcareProfessionIds={props.node.healthcareProfessionIds}
            />
            <HighlightableLabel
                label={props.node.label}
                filterValue={props.filterValueBox && props.filterValueBox.get()}
                automationId={props.node.code}
                displayMode={"inline"}
            />
        </>
    );
});

export {
    OrganizationUnitName
};
