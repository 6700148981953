import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import HunReportingApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/HunReportingApiAdapter";

@Di.injectable()
export default class ReportingDataProviderStore {

    @State.observable public performanceReports = new ReferenceDataStore(this.reportingApiAdapter.getPerformanceReportsByIdsAsync, this.reportingApiAdapter.getAllPerformanceReportId, "UserGroups");
   
    constructor(
        @Di.inject("HunReportingApiAdapter") private readonly reportingApiAdapter: HunReportingApiAdapter
    ) { }
}
