import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import CoverageCheckListPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/CoverageCheckListPanel/CoverageCheckListPanel";

interface ICoverageCheckListPageDependencies {
}

interface ICoverageCheckListPageProps extends IRoutingFrameContentProps {
    _dependencies?: ICoverageCheckListPageDependencies;
}

@State.observer
class CoverageCheckListPage extends React.Component<ICoverageCheckListPageProps> {

    public render() {
        return (
            <CoverageCheckListPanel />
        );
    }
}

export default connect(
    withHisErrorBoundary(CoverageCheckListPage),
    new DependencyAdapter<ICoverageCheckListPageProps, ICoverageCheckListPageDependencies>(container => {
        return {};
    })
);
