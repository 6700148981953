import React from "react";
import * as Ui from "@CommonControls";
import SubsidyClaimTypeSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/SubsidyClaimTypeSelector/SubsidyClaimTypeSelector";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";

interface IExtendedPermanentPrescriptionFilterProps {
    generalSearchValue: string;
    medicationSubsidyClaimTypeId: MedicationSubsidyClaimTypeId;
    onGeneralSearchValueChanged: (newValue: string) => void;
    onMedicationSubsidyClaimTypeIdChanged: (newValue: MedicationSubsidyClaimTypeId) => void;
}

export default class ExtendedPermanentPrescriptionFilter extends React.Component<IExtendedPermanentPrescriptionFilterProps> {
    public render() {
        return (
            <>
                <Ui.Flex>
                    <Ui.Flex.Item xs={8}>
                        <Ui.TextBox
                            label={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Labels.Search}
                            value={this.props.generalSearchValue}
                            onChange={this.props.onGeneralSearchValueChanged}
                            automationId="generalSearchFilterTextBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <SubsidyClaimTypeSelector
                            label={StaticHunSocialSecurityMedicationRequestResources.Medication.MedicationSubsidyClaimType}
                            value={this.props.medicationSubsidyClaimTypeId}
                            onChange={this.props.onMedicationSubsidyClaimTypeIdChanged}
                            automationId="subsidyClaimTypeFilterSelectBox"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </>
        );
    }
}