import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import _ from "@HisPlatform/Common/Lodash";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import PractitionerId from "@Primitives/PractitionerId.g";
import HealthcareProfessionId from "@Primitives/HealthcareProfessionId.g";
import PrescriptionSearchBase from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionSearchBase";
import PrescriptionDataSource from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionDataSource";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EhiPrescriptionSearchMode from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/EhiPrescriptionSearchMode";
import EhiPrescriptionStatusId from "@Primitives/EhiPrescriptionStatusId.g";
import PrescriptionStatus from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/PrescriptionStatus.g";

export default class PrescriptionFilterStore {
    @State.observable.ref public dateRangeFilter: LocalDateRange = null;
    @State.observable public medicationNameFilter: string = "";
    @State.observable.ref public statuses: PrescriptionStatus[] = [PrescriptionStatus.UnderRecording, PrescriptionStatus.Submitted, PrescriptionStatus.WaitingForDelete, PrescriptionStatus.WaitingForSubmissionCancellation];
    @State.observable.ref public pointOfCareIds: PointOfCareId[] = [];
    @State.observable.ref public healthCareProfessionIds: HealthcareProfessionId[] = [];
    @State.observable.ref public practitionerId: PractitionerId = null;
    @State.observable.ref public prescriptionSearchBase: PrescriptionSearchBase = PrescriptionSearchBase.CareActivity;
    @State.observable public includeDeleted: boolean = false;
    @State.observable public prescriptionDataSource: PrescriptionDataSource = PrescriptionDataSource.His;

    // TODO: separate ehi
    @State.observable.ref public ehiPrescriptionSearchStartDate: LocalDate = LocalDate.today();
    @State.observable public ehiPrescriptionSearchMonths: number = 1;
    @State.observable public ehiPrescriptionIdentifier: string = "";
    @State.observable public ehiPrescriptionSearchMode: EhiPrescriptionSearchMode = EhiPrescriptionSearchMode.AllPrescriptions;
    @State.observable public isEmergency: boolean = false;
    @State.observable public emergencyReason: string = "";
    @State.observable.ref public ehiPrescriptionStatuses: EhiPrescriptionStatusId[] = [];

    constructor(private onFilterChanged: () => void, private setShowFilter: (newValue: boolean) => void, private onClearList: () => void) {
    }

    private onChangeDebounced = _.debounce(this.onFilterChanged, 2000);

    @State.action.bound
    public setStatuses(statuses: PrescriptionStatus[]) {
        this.statuses = statuses;
        if (this.statuses.some(i => i === PrescriptionStatus.Deleted) && this.includeDeleted === false) {
            this.includeDeleted = true;
        } else if (!this.statuses.some(i => i === PrescriptionStatus.Deleted) && this.includeDeleted === true) {
            this.includeDeleted = false;
        }
        this.onChangeDebounced();
    }

    @State.action.bound
    public setDateRangeFilter(dateRangeFilter: LocalDateRange) {
        this.dateRangeFilter = dateRangeFilter;
        this.onChangeDebounced();
    }

    @State.action.bound
    public setMedicationNameFilter(medicationNameFilter: string) {
        this.medicationNameFilter = medicationNameFilter;
        if (this.prescriptionDataSource === PrescriptionDataSource.His) {
            this.onChangeDebounced();
        }
    }

    @State.action.bound
    public setIncludeDeleted(newValue: boolean, isInitial?: boolean) {
        this.includeDeleted = newValue;
        if (this.statuses === null || this.statuses === undefined) {
            this.statuses = [];
        }
        if (this.includeDeleted === true) {
            if (!this.statuses.some(i => i === PrescriptionStatus.Deleted)) {
                this.statuses.push(PrescriptionStatus.Deleted);
                if (!isInitial) {
                    this.onChangeDebounced();
                }
            }
        } else {
            if (this.statuses.some(i => i === PrescriptionStatus.Deleted)) {
                this.statuses = this.statuses.filter(i => i !== PrescriptionStatus.Deleted);
                if (!isInitial) {
                    this.onChangeDebounced();
                }
            }
        }
    }

    @State.action.bound
    public setHealthCareProfessionIds(healthcareProfessionIds: HealthcareProfessionId[]) {
        this.healthCareProfessionIds = healthcareProfessionIds;
        this.onChangeDebounced();
    }

    @State.action.bound
    public setPointOfCareIds(pointOfCareId: PointOfCareId, isChecked: boolean) {
        if (isChecked) {
            if (!this.pointOfCareIds?.some(i => ValueWrapper.equals(i, pointOfCareId))) {
                this.pointOfCareIds.push(pointOfCareId);
                this.onChangeDebounced();
            }
        } else {
            if (this.pointOfCareIds?.some(i => ValueWrapper.equals(i, pointOfCareId))) {
                this.pointOfCareIds = this.pointOfCareIds.filter(i => !ValueWrapper.equals(i, pointOfCareId));
                this.onChangeDebounced();
            }
        }
    }

    @State.action.bound
    public setPractitionerId(practitionerId: PractitionerId) {
        this.practitionerId = practitionerId;
        this.onChangeDebounced();
    }

    @State.action.bound
    public setPrescriptionSearchBase(prescriptionSearchBase: PrescriptionSearchBase, isInitial?: boolean) {
        this.prescriptionSearchBase = prescriptionSearchBase;
        this.setShowFilter(this.prescriptionSearchBase === PrescriptionSearchBase.Patient);
        if (this.prescriptionSearchBase === PrescriptionSearchBase.CareActivity) {
            this.prescriptionDataSource = PrescriptionDataSource.His;
            this.onClearList();
        }
        if (!isInitial) {
            this.onFilterChanged();
        }
    }

    @State.action.bound
    public setPrescriptionDataSource(prescriptionDataSource: PrescriptionDataSource, isInitial?: boolean) {
        this.prescriptionDataSource = prescriptionDataSource;

        if (this.prescriptionDataSource === PrescriptionDataSource.His) {
            this.onClearList();
            if (!isInitial) {
                this.onChangeDebounced();
            }
        } else {
            this.onClearList();
        }
    }

    @State.action.bound
    public setEhiPrescriptionSearchStartDate(ehiPrescriptionSearchStartDate: LocalDate) {
        this.ehiPrescriptionSearchStartDate = ehiPrescriptionSearchStartDate;
    }

    @State.action.bound
    public setEhiPrescriptionSearchMonths(ehiPrescriptionSearchMonths: number) {
        this.ehiPrescriptionSearchMonths = ehiPrescriptionSearchMonths;
    }

    @State.action.bound
    public setEhiPrescriptionIdentifier(ehiPrescriptionIdentifier: string) {
        this.ehiPrescriptionIdentifier = ehiPrescriptionIdentifier;
    }

    @State.action.bound
    public setEhiPrescriptionSearchMode(ehiPrescriptionSearchMode: EhiPrescriptionSearchMode) {
        this.ehiPrescriptionSearchMode = ehiPrescriptionSearchMode;
    }

    @State.action.bound
    public setIsEmergency(isEmergency: boolean) {
        this.isEmergency = isEmergency;
    }

    @State.action.bound
    public setEhiPrescriptionStatuses(statuses: EhiPrescriptionStatusId[]) {
        this.ehiPrescriptionStatuses = statuses;
    }

    @State.action.bound
    public setEmergencyReason(newValue: string) {
        this.emergencyReason = newValue;
    }
    
    public resetFilters() {
        this.setDateRangeFilter(null);
        this.setMedicationNameFilter("");
        this.setStatuses([PrescriptionStatus.UnderRecording, PrescriptionStatus.Submitted, PrescriptionStatus.WaitingForDelete, PrescriptionStatus.WaitingForSubmissionCancellation]);
        this.resetPointOfCareIds();
        this.setHealthCareProfessionIds([]);
        this.setPractitionerId(null);
        this.setIncludeDeleted(false);
    }

    @State.action.bound
    private resetPointOfCareIds() {
        this.pointOfCareIds = [];
    }
}