import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import Log from "@Log";

export default abstract class ExtendedFilterStoreBase {

    protected filterStore: IFilterStore = null;

    constructor(
        filterStore: IFilterStore
    ) {
        this.filterStore = filterStore;
    }

    @State.action.bound
    protected setFilter(identifier: string, value: any) {
        const mutator = this.filterStore["set_" + identifier];
        mutator?.apply(this.filterStore, [value]);
    }

    @State.bound
    protected getFilter(identifier: string) {
        return this.filterStore[identifier];
    }
}
