import Di from "@Di";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import { IDischargePatientScreenProps } from "./DischargePatientScreen";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import DischargePatientScreenApiAdapter from "./DischargePatientScreenApiAdapter";
import LockAcquirerOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockAcquirerOperationInfo";
import CareActivityDischargeDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityDischargeData/CareActivityDischargeDataStore";
import DischargePatientAction from "@HisPlatform/Packages/Care/FrontendActions/DischargePatientAction.g";
import ShowDischargePatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowDischargePatientScreenAction.g";
import ShowReadOnlyDischargePatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyDischargePatientScreenAction.g";
import ExtensionController from "@HisPlatform/Components/HisPlatformExtensionPoint/ExtensionController";
import ActionIdentifiers from "@Primitives/ActionIdentifiers";
import ICareActivityDischargeDataExtensionPointProps from "@PluginInterface/BoundedContexts/Care/PatientRegister/ExtensionPoints/ICareActivityDischargeDataExtensionPointProps";
import ShowEhrDischargePatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEhrDischargePatientScreenAction.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import HisPermissionScopes from "@HisPlatform/Common/FrontendActions/HisPermissionScopes";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import SaveDischargePatientDataAction from "@HisPlatform/Packages/Care/FrontendActions/SaveDischargePatientDataAction.g";
import CantChangeCareActivityStateError from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CantChangeCareActivityStateError";
import CareActivityStatusChangeNotPossibleDialogParams, { ICareActivityStatusChangeNotPossibleDialogResult } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityStatusChangeNotPossiblePanel/CareActivityStatusChangeNotPossibleDialogParams";
import ResourceId from "@Primitives/ResourceId.g";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import IStateChangeBlockingRegistry from "@PluginInterface/BoundedContexts/Care/CareRegister/StateChange/IStateChangeBlockingRegistry";

@Di.injectable()
export default class DischargePatientScreenStore extends EditorScreenPanelStoreBase<IDischargePatientScreenProps> implements ILoadablePanelStore {

    @State.observable public careActivityDischargeDataStore: CareActivityDischargeDataStore = null;
    @State.observable public extensionController = new ExtensionController<any>();
    @State.observable private isReadOnly: boolean = false;
    @State.observable public isDischargeAllowed: boolean = false;

    private readonly saveAsync: (releaseLockIfSuccessful: boolean) => Promise<boolean> = this.namedAsync("saveAsync", async (releaseLockIfSuccessful) => {
        if (this.careActivityDischargeDataStore?.isMutable && this.isDischargeAllowed) {
            const releaseLock = releaseLockIfSuccessful && !isNullOrUndefined(this.careActivityDischargeDataStore.dischargedAt) ? true : false;
            const saveResult = await this.handleSaveResultAsync<CareActivityDischargeDataStore>(() => this.hasPermissionForDischargePatient ?
                this.dischargePatientScreenApiAdapter.dischargePatientAsync(
                    false,
                    this.props.action.careActivityId,
                    this.careActivityDischargeDataStore,
                    releaseLock,
                    this.lockInfo) :
                this.dischargePatientScreenApiAdapter.updateDischargePatientDataAsync(
                    false,
                    this.props.action.careActivityId,
                    this.careActivityDischargeDataStore,
                    releaseLock,
                    this.lockInfo));

            if (saveResult.isPersisted) {

                State.runInAction(() => {
                    this.lockInfo = saveResult.result.lockInfo;
                    this.setCareActivityDischargeDataStore(saveResult.result);
                    if (!isNullOrUndefined(saveResult.result.dischargedAt)) {
                        this.isReadOnly = true;
                    }
                });

                // HACK - need to remove later
                if (this.props.action.displayMode === ScreenDisplayMode.WithoutSideBar) {
                    this.props._screenState.cancelled();
                }

                return saveResult.isPersisted;
            }

            this.vSetValidationResults(saveResult.result.validationResults);
            this.setCareActivityDischargeDataStore(saveResult.result);
        }
        return false;
    });

    @State.computed
    public get showDischargeScreenAction() {
        return new ShowDischargePatientScreenAction(
            this.props.action.displayMode,
            this.props.action.careActivityId
        );
    }

    @State.computed
    public get dischargePatientAction() {
        return ActionDescriptor.fromAction(
            new DischargePatientAction(),
            HisPermissionScopes.feature("Normal"),
            HisPermissionScopes.pointOfCare(this.props._pointOfCareId?.value)
        );
    }

    @State.computed
    public get saveDischargePatientDataAction() {
        return ActionDescriptor.fromAction(
            new SaveDischargePatientDataAction(),
            HisPermissionScopes.pointOfCare(this.props._pointOfCareId?.value)
        );
    }

    @State.computed
    public get hasPermissionForSaveDischargePatientData() {
        return this.authorizationService.hasPermissionForDescriptor(this.saveDischargePatientDataAction);
    }

    @State.computed
    public get actionForSaveButton() {
        return this.hasPermissionForDischargePatient ? this.dischargePatientAction : this.saveDischargePatientDataAction;
    }

    @State.computed
    public get vIsReadOnly(): boolean {
        return !this.careActivityDischargeDataStore?.isMutable ||
            this.isReadOnly ||
            this.isReadonlyDischargePatientScreen ||
            this.isEhrDischargePatientScreen ||
            this.canAcquireLock;
    }

    @State.computed
    public get extensionPointProps(): ICareActivityDischargeDataExtensionPointProps {
        return {
            careActivityId: this.careActivityDischargeDataStore?.id,
            patientId: this.props._patientId,
            extensionController: this.extensionController,
            extensionData: this.careActivityDischargeDataStore?.extensionData,
            onExtensionDataChange: this.careActivityDischargeDataStore?.setExtensionData
        };
    }

    @State.computed
    public get isDischargePatientScreen() {
        return this.props.action instanceof ShowDischargePatientScreenAction;
    }

    @State.computed
    public get isReadonlyDischargePatientScreen() {
        return this.props.action instanceof ShowReadOnlyDischargePatientScreenAction;
    }

    @State.computed
    public get isEhrDischargePatientScreen() {
        return this.props.action instanceof ShowEhrDischargePatientScreenAction;
    }

    @State.computed
    protected get showScreenAction() {
        return this.props.action;
    }

    protected vGetReloadTriggerProps(props: IDischargePatientScreenProps) {
        return [this.extensionPointProps];
    }

    @State.computed
    public get hasPermissionForDischargePatient() {
        return this.authorizationService.hasPermissionForDescriptor(this.dischargePatientAction);
    }

    @State.computed
    protected get contentToDirtyCheck() {
        return [this.careActivityDischargeDataStore?.dischargeReasonId?.value,
        this.careActivityDischargeDataStore?.dischargedAt,
        this.careActivityDischargeDataStore?.receivingInstitute?.id,
        this.careActivityDischargeDataStore.extensionData];
    }

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("DischargePatientScreenApiAdapter") private readonly dischargePatientScreenApiAdapter: DischargePatientScreenApiAdapter,
        @Di.inject("AuthorizationService") private readonly authorizationService: AuthorizationService,
        @Di.inject("CareReferenceDataStore") private readonly careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("IStateChangeBlockingRegistry") private readonly stateChangeBlockingRegistry: IStateChangeBlockingRegistry,
        @Di.inject("GlobalRoutingStore") private readonly globalRoutingStore: GlobalRoutingStore) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    @State.action.bound
    private calculateAllowedActions() {
        this.isReadOnly = this.careActivityDischargeDataStore.possibleAction[0].actions
            .every(i => i.value !== ActionIdentifiers.recordDischargeData);

        this.isDischargeAllowed = this.careActivityDischargeDataStore.possibleAction[0].actions
            .some(i => i.value === ActionIdentifiers.dischargePatient);
    }

    private readonly loadReferenceDataAsync: () => Promise<void> = this.async(async () => {
        await this.careReferenceDataStore.forwardingNeededAtDischarge.ensureLoadedAsync();
    });

    public loadCoreAsync: () => Promise<void> = this.namedAsync("loadCoreAsync", async () => {
        await this.loadReferenceDataAsync();
        const requestLock = (this.isDischargePatientScreen && (this.hasPermissionForSaveDischargePatientData || this.hasPermissionForDischargePatient));
        const response = await this.dischargePatientScreenApiAdapter.getDischargePatientScreenDataAsync(this.props.action.careActivityId, requestLock);
        if (!this.vIsReadOnly) {
            this.vSetValidationResults(response.result.validationResults);
        }
        if (response.operationInfo instanceof LockAcquirerOperationInfo) {
            State.runInAction(() => this.lockInfo = (response.operationInfo as LockAcquirerOperationInfo).lockInfo);
        }

        this.setCareActivityDischargeDataStore(response.result);

        this.calculateAllowedActions();
    });

    @State.bound
    public async onSaveAsync() {
        await this.saveAsync(true);
        this.dirtyChecker.takeSnapshot(this.contentToDirtyCheck);
    }

    @State.action.bound
    private setCareActivityDischargeDataStore(careActivityDischargeDataStore: CareActivityDischargeDataStore) {
        this.careActivityDischargeDataStore = careActivityDischargeDataStore;
    }

    public onValidateAsync: () => Promise<IClientValidationResult[]> = this.namedAsync("onValidateAsync", async () => {
        if (!this.careActivityDischargeDataStore || this.isReadonlyDischargePatientScreen || this.isEhrDischargePatientScreen) {
            return [];
        }
        const operationResult = await this.dischargePatientScreenApiAdapter.validateAsync(
            this.props.action.careActivityId,
            this.careActivityDischargeDataStore,
            this.hasPermissionForDischargePatient,
            this.lockInfo);

        const careActivityDischargeDataStoreValidationResult = operationResult.result;

        this.vSetValidationResults(careActivityDischargeDataStoreValidationResult);
        return careActivityDischargeDataStoreValidationResult;
    });

    @State.bound
    public onCancelAsync(): Promise<any> {
        this.props._screenState.cancelled();
        return Promise.resolve();
    }

    protected saveFunction: () => Promise<boolean> = this.async(() => this.saveAsync(false));

    @State.bound
    public handleCantChangeCareActivityStateError(err: CantChangeCareActivityStateError) {
        this.showCareActivityStatusChangeNotPossibleDialogAsync.fireAndForget(err);
        return true;
    }

    private readonly showCareActivityStatusChangeNotPossibleDialogAsync = this.backgroundAsync(async (err: CantChangeCareActivityStateError) => {
        const result = await this.modalService.showDialogAsync<ICareActivityStatusChangeNotPossibleDialogResult>(
            new CareActivityStatusChangeNotPossibleDialogParams(
                err.careActivityId,
                err.activityReferenceResourceId,
                err.reasons,
                new ResourceId("Worklist.CareActivity.WarningTitle")
            )
        );

        if (!!result?.navigateTo) {
            this.stateChangeBlockingRegistry.navigateTo(this.globalRoutingStore, this.actionDispatcher, result.navigateTo, err.careActivityId);
        }
    });
}
