import { Moment } from "moment";

export default class Measurement {
    constructor(
        public readonly name: string,
        public readonly value: string,
        public readonly metric: string,
        public readonly effectiveDateTime: Moment) {
    }
}
