import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import TextualPrescriptionTemplateId from "@Primitives/TextualPrescriptionTemplateId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PrescriptionTemplateType from "./PrescriptionTemplateType";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";

export default class TextualPrescriptionTemplate extends EntityStoreBase<TextualPrescriptionTemplateId> {
    @State.observable public name: string = "";
    @State.observable public displayName: string = "";
    @State.observable public content: string = "";
    @State.observable public preparation: string = "";
    @State.observable public type: PrescriptionTemplateType = PrescriptionTemplateType.Other;
    
    private _dirtyChecker = new StoreDirtyChecker();

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "operationInfo"
    ];

    @State.action.bound
    public setName(name: string) {
        this.name = name;
    }
    
    @State.action.bound
    public setDisplayName(displayName: string) {
        this.displayName = displayName;
    }
    
    @State.action.bound
    public setContent(content: string) {
        this.content = content;
    }

    @State.action.bound
    public setPreparation(preparation: string) {
        this.preparation = preparation;
    }
    
    @State.action.bound
    public setType(type: PrescriptionTemplateType) {
        this.type = type;
    }
}