import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import * as Ui from "@CommonControls";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import StaticWebAppResources from "@StaticResources";
import PerformanceReport from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReport";
import StaticHunSocialSecurityReportingResources from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/StaticResources/StaticHunSocialSecurityReportingResources";

interface IPerformanceReportListProps extends IHasMasterDetailState {
    dataSource: InMemoryDataGridDataSource<PerformanceReport>;
    onBack(): void;
}

/** @screen */

const PerformanceReportList: React.FC<IPerformanceReportListProps> = (props) => {
    const rowId = (row: PerformanceReport) => row.id.value;
    const nameOrder = (value: PerformanceReport) => value.name;

    return (
        <Ui.DataGrid
            dataSource={props.dataSource}
            generateInitialFilterStore
            changeOnMount
            isSelectable
            fixedHeight="100%"
            rowHeight={"39px"}
            onSelectedRowChange={props._masterDetailState.onSelectedItemChange}
            selectedRow={props._masterDetailState.selectedItem}
            rowId={rowId}
            rowComparer="rowId"
            hasBackButton
            backButtonText={StaticWebAppResources.Common.Button.Back}
            onBack={props.onBack}
            automationId="performanceReportList_dataGrid"
        >
            <DataGridColumn
                id={0}
                dataGetter={"name"}
                header={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.Name}
                width="100%"
                isFilterable
                isOrderable
                clientSideOrderableValueGetter={nameOrder}
            />
        </Ui.DataGrid>
    );
};

export default State.observer(PerformanceReportList);
