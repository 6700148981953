/* auto-inject-disable */
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import { GetTypeNameWithPrefix } from "@HunSocialSecurityPlugin/PluginHelper";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Proxy.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import PerformanceReportId from "@Primitives/PerformanceReportId.g";
import PerformanceReport from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReport";
import { buildQueryStringArray } from "@Toolkit/CommonWeb/QueryStringBuilder";
import PerformanceReportStoreMapper from "./PerformanceReportStoreMapper";
import _ from "@HisPlatform/Common/Lodash";
import PerformanceReportDtoMapper from "./PerformanceReportDtoMapper";
import HealthcareProfession from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/HealthcareProfession";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";

@Di.injectable()
export default class HunReportingApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("IReportingClient")) private readonly apiClient: Proxy.IReportingClient,
        @Di.inject("PerformanceReportStoreMapper") private readonly performanceReportStoreMapper: PerformanceReportStoreMapper
    ) {
        super();
    }

    @State.bound
    public getAllPerformanceReportId(): Promise<SimpleStore<PerformanceReportId[]>> {
        return this.processOperationAsync(
            new SimpleStore<PerformanceReportId[]>(),
            async target => {
                const response = await this.apiClient.getAllPerformanceReportIdsQueryAsync(
                    CreateRequestId(),
                    null
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.ids;
            }
        );
    }

    @State.bound
    public getPerformanceReportsByIdsAsync(ids: PerformanceReportId[]): Promise<SimpleStore<PerformanceReport[]>> {
        return this.processOperationAsync(
            new SimpleStore<PerformanceReport[]>(),
            async target => {
                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value));
                const response = await this.apiClient.getPerformanceReportsByIdsQueryAsync(CreateRequestId(),
                    buildQueryStringArray(normalizedIds));

                target.operationInfo = createOperationInfo(response);
                target.value = response.performanceReports.map((item) => {
                    const newStore = new PerformanceReport();
                    this.performanceReportStoreMapper.applyToStore(newStore, item);
                    return newStore;
                });
            }
        );
    }

    @State.bound
    public addPerformanceReportAsync(performanceReport: PerformanceReport, professions: HealthcareProfession[], validateOnly: boolean = false) {
        const dto = new PerformanceReportDtoMapper().getAddPerformanceReportDto(performanceReport, professions, validateOnly);

        return this.processOperationAsync(
            new PerformanceReport(),
            async target => {
                const response = await this.apiClient.addPerformanceReportCommandAsync(
                    CreateRequestId(),
                    dto
                );

                if (response.validatedResult) {
                    this.performanceReportStoreMapper.applyToStore(target, response.validatedResult);
                }
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public updatePerformanceReportAsync(performanceReport: PerformanceReport, professions: HealthcareProfession[], validateOnly: boolean = false) {
        const dto = new PerformanceReportDtoMapper().getUpdatePerformanceReportDto(performanceReport, professions, validateOnly);

        return this.processOperationAsync(
            new PerformanceReport(),
            async target => {
                const response = await this.apiClient.updatePerformanceReportCommandAsync(
                    CreateRequestId(),
                    dto
                );

                if (response.validatedResult) {
                    this.performanceReportStoreMapper.applyToStore(target, response.validatedResult);
                }
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }
    
    @State.bound
    public deletePerformanceReportAsync(performanceReportId: PerformanceReportId) {
        const dto = new PerformanceReportDtoMapper().getDeletePerformanceReportDto(performanceReportId);

        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.deletePerformanceReportCommandAsync(
                    CreateRequestId(),
                    dto
                );
                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}