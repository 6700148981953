import AgeDataDto from "./AgeDataDto";
import PersonName from "./PersonName";
import Date from "./Date";
import DateTimeOffset from "./DateTimeOffset";
import PatientNameDto from "./PatientNameDto";
import Identifier from "./IdentifierDto";
import MomentRange from "./MomentRange";
import Money from "./Money";
import YearMonthDto from "./YearMonthDto";

// Toolkit converters only! Put BoundedContext specific converters to the corresponding context initializer (IBoundedContext derived classes) OR plugin initializer
export default {
    ...AgeDataDto,
    ...PersonName,
    ...Date,
    ...DateTimeOffset,
    ...PatientNameDto,
    ...Identifier,
    ...MomentRange,
    ...Money,
    ...YearMonthDto,
};
