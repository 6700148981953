import State from "@Toolkit/ReactClient/Common/StateManaging";
import IIdentifierDisplayExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IIdentifierDisplayExtensionPointProps";
import React from "react";

@State.observer
export default class HunSocialSecurityIdentifierDisplay extends React.Component<IIdentifierDisplayExtensionPointProps> {

    public render() {
        const part1 = this.props.identifier.value.substring(0, 3);
        const part2 = this.props.identifier.value.substring(3, 6);
        const part3 = this.props.identifier.value.substring(6, 9);
        return (
            <>
                {`${part1} ${part2} ${part3}`}
            </>
        );
    }
}