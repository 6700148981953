import IFormValidationRule, { IFormValidationRuleParameters } from "@Toolkit/FormEngine/Model/Schema/Validation/IFormValidationRule";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import RuleTypeId from "@Primitives/RuleTypeId.g";

export default class EditableShouldMatchPatternValidationRule implements IFormValidationRule {
    public get ruleType() { return RuleTypeId.ShouldMatchPattern.value; }

    @State.observable.ref public pattern: string;
    
    public get ruleParameters() {
        return {
            pattern: this.pattern
        };
    }

    constructor(
        public entityPath: string,
        ruleParameters: IFormValidationRuleParameters & { pattern: string }
    ) {
        this.pattern = ruleParameters.pattern;
    }

    @State.action.bound
    public setPattern(value: string) {
        this.pattern = value;
    }
}