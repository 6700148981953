/**
 * Represents a message coming from an external source that represents a command.
 */
export default class CommandMessage {
    /**
     * Initializes a new instance of {@link CommandMessage}.
     * @param commandType The type of the command.
     * @param originator The name of the originator.
     * @param data Additional data for the command.
     */
    constructor(
        public readonly commandType: string,
        public readonly originator: string,
        public readonly data: any
    ) {
    }
}