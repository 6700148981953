import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class RiskDetail {
    @State.observable.ref public description: string;
    @State.observable.ref public periodDescription: string;

    constructor(description: string, periodDescription: string) {
        this.description = description;
        this.periodDescription = periodDescription;
    }

    @State.action.bound
    public setDescription(newValue: string) {
        this.description = newValue;
    }

    @State.action.bound
    public setPeriodDescription(newValue: string) {
        this.periodDescription = newValue;
    }
}
