import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import * as Ui from "@CommonControls";
import { HisDocumentEditor } from "@HisPlatformControls";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import ModalServiceAdapter from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAdapter";
import ITreeViewNode from "@CommonControls/TreeView/ITreeViewNode";
import _ from "@HisPlatform/Common/Lodash";
import TokenUsageStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TokenUsageStore";
import Styles from "./DocumentTemplateManagement.less";
import { buildTokenTree, TokenTreeItem, BaseTokenTreeItem, TokenGroupTreeItem } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TokenTreeItems";
import ITemplateToken from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateToken";
import ITokenGroup from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITokenGroup";
import TemplateStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TemplateStore";
import UsedTokenList from "./UsedTokenList";
import TokenUsageSettings from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TokenUsageSettings";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import { filterTree, checkMatchForNode } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TokenTreeFilter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";

interface IDocumentTemplateManagementDetailPanelDependencies {
    dialogService: IDialogService;
    notificationService: INotificationService;
}

interface IDocumentTemplateManagementDetailPanelProps extends IHasMasterDetailState {
    _dependencies?: IDocumentTemplateManagementDetailPanelDependencies;
    _modalService?: IModalService;

    currentTemplate: TemplateStore;
    tokens: Map<string, ITemplateToken>;
    tokenGroups: ITokenGroup[];
    // onSetLastContent: (content: string) => void;
}

/** @screen */
@State.observer
class DocumentTemplateManagementDetailPanel extends React.Component<IDocumentTemplateManagementDetailPanelProps> {

    private get modalService() { return this.props._modalService!; }
    private get dialogService() { return this.props._dependencies.dialogService; }
    private get notificationService() { return this.props._dependencies.notificationService; }

    @State.observable.ref private tokenTree: BaseTokenTreeItem[] = [];
    @State.observable.ref private selectedTokenUsage: TokenUsageStore = null;
    @State.observable.ref private tokenFilterValue: string = "";
    @State.observable.ref private debouncedTokenFilterValue: string = "";

    @State.computed private get filteredTokenTree() {
        if (!!this.debouncedTokenFilterValue) {
            return filterTree(this.debouncedTokenFilterValue, this.tokenTree);
        }
        return this.tokenTree;
    }

    @State.computed private get selectedToken(): ITemplateToken {
        return this.selectedTokenUsage ? this.props.tokens.get(this.selectedTokenUsage.symbol) : null;
    }

    public render() {
        if (!this.props.currentTemplate) {
            return <></>;
        }

        if (this.props.currentTemplate.info.isDeleted) {
            return (
                <Ui.SidebarLayout rightSidebarClassName={Styles.detailSidebar} rightSidebar={(
                    <>
                        <Ui.GroupBox className={Styles.toolbox}/>
                    </>)}>
                    <HisDocumentEditor
                        mode="Template"
                        contentStore={this.props.currentTemplate.documentContent.forEditor}
                        onSelectBookmarks={this.selectTokenUsageByBookmarks}
                        isReadOnly={this.props.currentTemplate.info.isDeleted}
                    />
                </Ui.SidebarLayout>);
        }

        return (
            <Ui.SidebarLayout rightSidebarClassName={Styles.detailSidebar} rightSidebar={(
                <>
                    <Ui.GroupBox title={StaticDocumentManagementResources.TemplateManagement.Tokens} className={Styles.toolbox} isScrollable>
                        <Ui.TextBox onChange={this.setTokenFilterValue} value={this.tokenFilterValue} valueOnClear={""} automationId="tokenFilterTextBox" />
                        <Ui.TreeView
                            data={this.filteredTokenTree}
                        />
                    </Ui.GroupBox>
                    <Ui.GroupBox
                        title={this.selectedToken ? this.selectedToken.displayName : StaticDocumentManagementResources.TemplateManagement.UsedTokens}
                        className={Styles.tokenUsages}
                        isScrollable
                    >
                        {this.selectedTokenUsage ?
                            <TokenUsageSettings tokenUsage={this.selectedTokenUsage} token={this.selectedToken} onRemoveTokenAsync={this.removeTokenAsync} onUpdateTokenDisplayName={this.updateTokenDisplayName} /> :
                            <UsedTokenList onSelectToken={this.selectTokenByUsage} tokenUsages={this.props.currentTemplate.tokenUsages} tokenMap={this.props.tokens} />
                        }
                    </Ui.GroupBox>
                </>
            )}>
                <HisDocumentEditor
                    mode="Template"
                    contentStore={this.props.currentTemplate.documentContent.forEditor}
                    onSelectBookmarks={this.selectTokenUsageByBookmarks}
                    isReadOnly={this.props.currentTemplate.info.isDeleted}
                />
            </Ui.SidebarLayout>
        );
    }

    public componentDidMount() {
        this.loadPanel();
    }

    public componentDidUpdate(prevProps: IDocumentTemplateManagementDetailPanelProps) {
        if (prevProps.tokens !== this.props.tokens || prevProps.tokenGroups !== this.props.tokenGroups) {
            this.buildTokenTree();
        }
    }

    private loadPanel() {
        this.buildTokenTree();
    }

    @State.bound
    private updateTokenDisplayName(tokenUsage: TokenUsageStore, newDisplayName: string) {
        this.props.currentTemplate.documentContent.updateTokenDisplayNameAsync(tokenUsage.tokenId, newDisplayName);
    }

    @State.action
    private buildTokenTree() {
        this.tokenTree = buildTokenTree(this.props.tokenGroups, this.props.tokens ? Array.from(this.props.tokens.values()) : null, this.useTokenAsync);
    }

    @State.action.bound
    public setTokenFilterValue(value: string) {
        this.tokenFilterValue = value;
        this.setDebouncedTokenFilterValue();
    }

    private setDebouncedTokenFilterValue = _.debounce(() => {
        State.runInAction(() => {
            this.debouncedTokenFilterValue = this.tokenFilterValue;

            if (!this.tokenFilterValue) {
                this.tokenTree.forEach(x => this.closeAllNodes(x));
            } else {
                this.tokenTree.forEach(x => this.openFilteredTree(x));
            }
        });
    }, 500);

    @State.action.bound
    private openFilteredTree(node: ITreeViewNode): boolean {
        if (node instanceof BaseTokenTreeItem) {
            let shouldBeVisible = false;
            if (node.children) {
                for (const child of node.children) {
                    shouldBeVisible = this.openFilteredTree(child) || shouldBeVisible;
                }
            }
            if (node instanceof TokenGroupTreeItem) {
                node.setIsOpen(shouldBeVisible);
            }
            node.setFilterValue(this.tokenFilterValue);
            return shouldBeVisible || checkMatchForNode(this.debouncedTokenFilterValue, node);
        }
        return false;
    }

    @State.bound
    private closeAllNodes(node: ITreeViewNode) {
        if (node instanceof BaseTokenTreeItem) {
            if (node instanceof TokenGroupTreeItem) {
                node.setIsOpen(false);
                if (!!node.children) {
                    node.children.forEach(x => this.closeAllNodes(x));
                }
            }
            node.setFilterValue(null);
        }
    }

    @State.bound
    private async useTokenAsync(tokenItem: TokenTreeItem) {
        if (!!this.selectedToken) {
            this.notificationService.error(StaticDocumentManagementResources.TemplateManagement.Message.CannotPlaceTokenInsideAnotherToken);
            return;
        }
        const tokenUsage = this.props.currentTemplate.tokenUsages.useToken(tokenItem.symbol);
        await this.props.currentTemplate.documentContent.appendTokenAsync(tokenUsage.tokenId, tokenUsage.symbol);
    }

    @State.bound
    private async removeTokenAsync() {
        const dialogResult = await this.dialogService.yesNoCancel(StaticDocumentManagementResources.Common.Dialog.ConfirmationTitle,
            StaticDocumentManagementResources.TemplateManagement.Message.DeleteTokenConfirmationMessage);

        if (dialogResult.resultCode === DialogResultCode.Yes) {
            this.props.currentTemplate.tokenUsages.delete(this.selectedTokenUsage.tokenId);
            await this.props.currentTemplate.documentContent.removeTokenAsync(this.selectedTokenUsage.tokenId);
            this.setSelectedTokenUsage(null);
        }
    }

    @State.action.bound
    public setSelectedTokenUsage(selectedTokenUsage: TokenUsageStore) {
        this.selectedTokenUsage = selectedTokenUsage;
    }

    @State.action.bound
    private selectTokenUsageByBookmarks(bookmarks: string[]) {
        if (!bookmarks || bookmarks.length !== 1) {
            this.selectedTokenUsage = null;
            return;
        }

        this.selectedTokenUsage = this.props.currentTemplate.tokenUsages.get(bookmarks[0]);
    }

    @State.bound
    private selectTokenByUsage(tokenUsage: TokenUsageStore) {
        dispatchAsyncErrors(this.props.currentTemplate.documentContent.selectTokenAsync(tokenUsage.tokenId), this);
    }
}

export default connect(
    DocumentTemplateManagementDetailPanel,
    new ModalServiceAdapter(),
    new DependencyAdapter<IDocumentTemplateManagementDetailPanelProps, IDocumentTemplateManagementDetailPanelDependencies>(c => ({
        dialogService: c.resolve("IDialogService"),
        notificationService: c.resolve("INotificationService"),
    }))
);