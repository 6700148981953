import "./ApiClientConfiguration";
import "./Scheduling/ApiAdapterConfiguration";
import "./Scheduling/StoreConfiguration";
import DependencyContainer from "@DiContainer";
import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import SchedulingBoundedContext from "@HisPlatform/BoundedContexts/Scheduling/SchedulingBoundedContext";
import ICommandFactory from "@Toolkit/CommonWeb/CommandProcessing/Definition/ICommandFactory";
import CreatePatientAppointmentCommandFactory from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/UseCases/CreatePatientAppointmentCommandFactory";
import IGenericCommandProcessor from "@Toolkit/CommonWeb/CommandProcessing/Definition/IGenericCommandProcessor";
import CreatePatientAppointmentCommandProcessor from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/UseCases/CreatePatientAppointmentCommandProcessor";

DependencyContainer
    .bind("IBoundedContext")
    .to(SchedulingBoundedContext);

DependencyContainer
    .bind("ICommandFactory")
    .to(CreatePatientAppointmentCommandFactory);

DependencyContainer
    .bind("IGenericCommandProcessor")
    .to(CreatePatientAppointmentCommandProcessor);