import React from "react";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import * as Ui from "@CommonControls";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiErrorSummary from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiErrorSummary";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { provideEhiCareActivityDataPanelStore, useEhiCareActivityDataPanelStore } from "./EhiCareActivityDataPanelStoreProvider";
import EhiCareService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareService";
import PatientId from "@Primitives/PatientId.g";

export interface IEhiCareActivityDataPanelViewProps {
    ehiCareService: EhiCareService;
    ehiId: string;
    isEmergency: boolean;
    emergencyReason: string;
    patientId: PatientId;
}


const EhiCareActivityDataPanelView: React.FC<IEhiCareActivityDataPanelViewProps> = props => {
    const store = useEhiCareActivityDataPanelStore();

    return (
        <Ui.ScrollView height="">
            <EhiErrorSummary errors={store.errors} />
            {isNullOrUndefined(store.data) && <Ui.Button automationId={"reloadButton"} text={StaticWebAppResources.Common.Button.Reload} iconName="sync" onClickAsync={store.loadAsync} />}
            {!isNullOrUndefined(store.data) && (
                <ReadOnlyContext.Provider value>
                    <Ui.Flex xsJustify="end">
                        <div style={{ width: "100%", height: "100%" }}>
                            <pre>{store.data}</pre>
                        </div>
                    </Ui.Flex>
                </ReadOnlyContext.Provider>
            )}
        </Ui.ScrollView>
    );
};

export default provideEhiCareActivityDataPanelStore(EhiCareActivityDataPanelView);


