export default class HunSocialSecurityUseCases {
    public static readonly careActivityFinanceData = "CareRegister_CareActivityFinanceData";

    public static readonly selectPerformanceStatementForCreation = "PerformanceStatement_SelectPerformanceStatementForCreation";
    public static readonly newNeakPerformanceStatement = "PerformanceStatement_NewNeakPerformanceStatement";
    public static readonly newNeakRevisionPerformanceStatement = "PerformanceStatement_NewNeakRevisionPerformanceStatement";
    public static readonly newEuPerformanceStatement = "PerformanceStatement_NewEuPerformanceStatement";

    public static readonly viewPerformanceStatementCareActivityOutpatientDataSheet = "PerformanceStatement_ViewPerformanceStatementCareActivityOutpatientDataSheet";
    public static readonly viewPerformanceStatementCareActivityStatusHistory = "PerformanceStatement_ViewPerformanceStatementCareActivityStatusHistory";
    
    public static readonly errorList = "PerformanceStatement_ErrorList";
    public static readonly editCareRelatedEntities = "PerformanceStatement_EditCareRelatedEntities";

    public static readonly neakErrorListProcessLog = "PerformanceStatement_ViewErrorListProcessLog";
    public static readonly euErrorListProcessLog = "PerformanceStatement_ViewEuPerformanceStatementErrorListProcessLog";

    public static readonly setPerformanceStatementTransactionCode = "PerformanceStatement_SetTransactionCode";
    public static readonly setPerformanceStatementCareActivityManualStatus = "PerformanceStatement_SetPerformanceStatementCareActivityManualStatus";
    public static readonly setEuPerformanceStatementTransactionCode = "PerformanceStatement_SetEuPerformanceStatementTransactionCode";
    public static readonly setPerformanceStatementCareActivityManualEuStatus = "PerformanceStatement_SetPerformanceStatementCareActivityManualEuStatus";
    public static readonly setPerformanceStatementCareActivityManualNeakStatusAndMonth = "PerformanceStatement_SetPerformanceStatementCareActivityManualNeakStatusAndMonth";
    public static readonly configureBatchUpdatePerformanceStatementCareActivityManualNeakStatusAndMonth = "PerformanceStatement_ConfigureBatchUpdatePerformanceStatementCareActivityManualNeakStatusAndMonth";

    public static readonly closeCareActivities = "PerformanceStatement_CloseCareActivities";
    public static readonly editContagiousPatientReport = "ContagiousPatientReport_Modify";
    public static readonly viewContagiousPatientReport = "ContagiousPatientReport_View";

    public static readonly performanceReportScorePlanDetail = "PerformanceReportScorePlanDetail";
}
