import React, {  } from "react";
import Styles from "./PageBox.less";
import ViewContextProvider from "@Toolkit/ReactClient/Components/ViewContext/ViewContextProvider";

export interface IPageBox2FooterProps {
    leftToolbar?: React.ReactNode;
    rightToolbar?: React.ReactNode;
}


const PageBox2Footer: React.FC<IPageBox2FooterProps> = props => {

    return (
        <>
            <hr className={Styles.separator} />
            <div className={Styles.footer}>
                {props.children ?? (
                    <ViewContextProvider
                        compactControlHeight
                    >
                        <div className={Styles.leftToolbar}>{props.leftToolbar}</div>
                        <div className={Styles.rightToolbar}>{props.rightToolbar}</div>
                    </ViewContextProvider>
                )}
            </div>
        </>
    );
};

export default PageBox2Footer;