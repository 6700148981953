import * as Proxy from "@HisPlatform/BoundedContexts/Finance/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CustomerId from "@Primitives/CustomerId.g";
import ICustomer from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Invoicing/ICustomer";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import SearchCustomersQueryOrderingFields from "@HisPlatform/BoundedContexts/Finance/Api/Invoicing/Enum/SearchCustomersQueryOrderingFields.g";

@Di.injectable()
export default class CustomerApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IInvoicingClient") private readonly apiClient: Proxy.IInvoicingClient
    ) {
        super();
    }

    @State.bound
    public loadCustomersAsync(ids: CustomerId[]): Promise<SimpleStore<ICustomer[]>> {
        return this.processOperationAsync(
            new SimpleStore<ICustomer[]>(),
            async (target) => {
                const response = await this.apiClient.getCustomersDataByIdsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetCustomersDataByIdsControllerDto({ customerIds: ids })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.customers.map(this.mapCustomers);
            });
    }

    @State.bound
    public quickSearchCustomerAsync(freeText: string, maxResultCount: number) {
        return this.processOperationAsync(
            new SimpleStore<CustomerId[]>(),
            async target => {
                const response = await this.apiClient.quickSearchCustomersQueryAsync(
                    CreateRequestId(),
                    maxResultCount.toString(),
                    freeText
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.results;
            }
        );
    }

    @State.bound
    public searchCustomerAsync(filterText: string, ordering: IOrderingState, paging: IPagingState)
        : Promise<PagedItemStore<CustomerId>> {

        return this.processOperationAsync(
            new PagedItemStore<CustomerId>(),
            async (target) => {
                const columnName = ordering && ordering.columnId as string;
                const response = await this.apiClient.searchCustomersQueryAsync(
                    CreateRequestId(),
                    new Proxy.SearchCustomersControllerDto({
                        searchText: filterText,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfSearchCustomersQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfSearchCustomersQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: SearchCustomersQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.totalCount = response.results && response.results.totalCount;
                target.items = response.results && response.results.values;
            }
        );
    }

    private mapCustomers(dto: Proxy.CustomerBaseDataDto): ICustomer {
        return {
            id: dto.customerId,
            customerName: dto.customerName
        } as ICustomer;
    }
}
