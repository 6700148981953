import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import ILifeStyleRiskHintItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/ILifeStyleRiskHintItem";
import LifeStyleRiskId from "@Primitives/LifeStyleRiskId.g";
import ILifeStyleRisk from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/ILifeStyleRisk";
import ComplexSearchLifeStyleRisksQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/LifeStyleRisks/Enum/ComplexSearchLifeStyleRisksQueryOrderingFields.g";

@Di.injectable()
export default class LifeStyleRisksApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("ILifeStyleRisksClient") private readonly apiClient: Proxy.ILifeStyleRisksClient
    ) {
        super();
    }

    public searchLifeStyleRisks(freeText: string, maxResultCount: number) {
        return this.processOperationAsync(
            new SimpleStore<ILifeStyleRiskHintItem[]>(),
            async target => {
                const response = await this.apiClient.searchLifeStyleRisksQueryAsync(
                    CreateRequestId(),
                    freeText,
                    maxResultCount.toString()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.lifeStyleRisks.map(ai => {
                    return {
                        id: ai.id,
                        code: ai.code,
                        name: ai.name
                    } as ILifeStyleRiskHintItem;
                });
            }
        );
    }

    public getAllIdsAsync(): Promise<SimpleStore<LifeStyleRiskId[]>> {
        return this.processOperationAsync(
            new SimpleStore<LifeStyleRiskId[]>(),
            async target => {
                const response = await this.apiClient.getLifeStyleRiskIdsQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.ids.map(id => id);
            }
        );
    }

    @State.bound
    public loadLifeStyleRisksAsync(ids: LifeStyleRiskId[]): Promise<SimpleStore<ILifeStyleRisk[]>> {
        return this.processOperationAsync(
            new SimpleStore<ILifeStyleRisk[]>(),
            async (target) => {
                const response = await this.apiClient.getLifeStyleRisksByIdsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetLifeStyleRisksByIdsControllerDto({ ids: ids })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.lifeStyleRisks.map(this.mapLifeStyleRisk);
            }
        );
    }

    private mapLifeStyleRisk(ai: Proxy.LifeStyleRiskDto): ILifeStyleRisk {
        return {
            id: ai.id,
            code: ai.code,
            name: ai.name
        } as ILifeStyleRisk;
    }

    @State.bound
    public complexSearchLifeStyleRisks(
        freeText: string,
        ordering: IOrderingState,
        paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<ILifeStyleRisk>>(),
            async (target) => {
                const response = await this.apiClient.complexSearchLifeStyleRisksQueryAsync(
                    CreateRequestId(),
                    new Proxy.ComplexSearchLifeStyleRisksControllerDto({
                        freeText: freeText,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfComplexSearchLifeStyleRisksQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfComplexSearchLifeStyleRisksQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: ComplexSearchLifeStyleRisksQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results && response.results.values.map(this.mapLifeStyleRisk)
                };

            }
        );
    }
}
