import PatientId from "@Primitives/PatientId.g";
import * as CareProxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import MedicalAlertStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalAlertStatement/MedicalAlertStatementStore";
import MedicalAlertStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalAlertStatement/MedicalAlertStatementHistoryItem";
import { getHistoryItemDetailDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalConditionDtoMapper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";

export function getAddMedicalAlertStatementControllerDto(source: MedicalAlertStatementStore, patientId: PatientId, isValidateOnly: boolean) {
    return new CareProxy.AddMedicalAlertStatementControllerDto({
        patientId: patientId,
        historyItemDtos: [getMedicalAlertStatementHistoryItemDto(source.latestHistoryItem as MedicalAlertStatementHistoryItem)],
        name: source.name,
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
    });
}

export function getUpdateMedicalAlertStatementControllerDto(source: MedicalAlertStatementStore, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.UpdateMedicalAlertStatementControllerDto({
        medicalAlertStatementId: source.id,
        historyItemDtos: [getMedicalAlertStatementHistoryItemDto(source.latestHistoryItem as MedicalAlertStatementHistoryItem)],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteMedicalAlertStatementHistoryItemControllerDto(source: MedicalAlertStatementStore, versionNumber: number, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.DeleteMedicalAlertStatementHistoryItemControllerDto({
        medicalAlertStatementId: source.id,
        versionNumbers: [versionNumber],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteMedicalAlertStatementControllerDto(store: MedicalAlertStatementStore) {
    return new CareProxy.DeleteMedicalAlertStatementControllerDto({
        medicalAlertStatementId: store.id,
        lockId: store.lockInfo?.lockId,
        extensionData: store.extensionData,
        isValidateOnly: false,
        releaseLockIfSuccessful: true
    });
}

function getMedicalAlertStatementHistoryItemDto(source: MedicalAlertStatementHistoryItem) {
    return new CareProxy.MedicalAlertStatementHistoryItemDto({
        versionNumber: source.versionNumber,
        index: source.index,
        createdAt: source.createdAt,
        historyItemDetail: getHistoryItemDetailDto(source.historyItemDetail),
        extensionData: source.extensionData,
        description: source.description,
        medicalAlertId: source.medicalAlertId,
        statementDate: source.statementDate
    });
}
