import React from "react";
import Flex from "@CommonControls/Flex";
import FormLayoutAnyBlockElement from "./FormLayoutAnyBlock";
import FormLayoutDataElementEditor from "./FormLayoutDataElementEditor";
import FormLayoutRowColumnStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutRowColumnStore";
import { useFormPanelStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanelStoreProvider";
import { useFormLayoutContext } from "@Toolkit/FormEngine/Panels/FormLayoutContext";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { hasVisibleItemInTreeBelow } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FormSchemaReference from "@Toolkit/FormEngine/Model/Schema/FormSchemaReference";

function FormLayoutRowColumn(props: { column: FormLayoutRowColumnStore, cultureCode: string, schemaReference: FormSchemaReference }) {
    const store = useFormPanelStore();
    const layoutContext = useFormLayoutContext();

    const compositeDataReferences = isNullOrUndefined(layoutContext?.compositeDataReferences) ? null : layoutContext?.compositeDataReferences;
    const parentCompositeDataReference = isNullOrUndefined(compositeDataReferences) ? null : compositeDataReferences.join(".");

    const isVisible = hasVisibleItemInTreeBelow(props.column, store.props.form.data.Content, parentCompositeDataReference);
    if (!isVisible) {
        return (
            <></>
        );
    }

    return (
        <Flex.Item
            xs={props.column.size.xs}
            sm={props.column.size.sm}
            md={props.column.size.md}
            lg={props.column.size.lg}
            verticalContentAlignment="top"

        >
            {Array.isArray(props.column.content) ?
                props.column.content.map((b, idx) => <FormLayoutAnyBlockElement cultureCode={props.cultureCode} block={b} key={idx} schemaReference={props.schemaReference} />) :
                <div style={{ flex: "1 1 0", overflow: "hidden" }}>
                    <FormLayoutDataElementEditor editor={props.column.content} schemaReference={props.schemaReference} />
                </div>}
        </Flex.Item>
    );
}

export default State.observer(FormLayoutRowColumn);