import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface ILateralitySelectBoxDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface ILateralitySelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: ILateralitySelectBoxDependencies;
}


@State.observer
class LateralitySelectBox extends React.Component<ILateralitySelectBoxProps> {
    private get dependencies() { return this.props._dependencies; }
    private get extensibleEnumStore() { return this.dependencies.careReferenceDataStore.laterality; }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                clearable={false}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    LateralitySelectBox,
    new DependencyAdapter<ILateralitySelectBoxProps, ILateralitySelectBoxDependencies>((containerService) => {
        return {
            careReferenceDataStore: containerService.resolve("CareReferenceDataStore")
        };
    })
);
