import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as FinanceProxy from "@HisPlatform/BoundedContexts/Finance/Api/Proxy.g";
import InsurerOrganization from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/InsurerOrganizations/InsurerOrganization";
import InsurerAddress from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/InsurerOrganizations/InsurerAddress";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import Di from "@Di";

@Di.injectable()
export default class InsurerOrganizationStoreMapper extends EntityStoreMapper<FinanceProxy.InsurerOrganizationDto, InsurerOrganization> {
    protected storeEntityIdType = InsurerOrganizationId;

    protected applyToStoreCore(target: InsurerOrganization, dto: FinanceProxy.InsurerOrganizationDto) {
        target.address = new InsurerAddress(dto.address.countryId, dto.address.settlement, dto.address.zipCode, dto.address.addressLine);
        target.code = dto.code;
        target.displayCode = dto.displayCode,
        target.insurerOrganizationTypeId = dto.insurerOrganizationTypeId;
        target.isActive = dto.isActive;
        target.isClaimSettlementPartner = dto.isClaimSettlementPartner;
        target.name = dto.name;
        target.rowVersion = dto.rowVersion;
        target.hasPatientData = dto.hasPatientData;
    }
}
