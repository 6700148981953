exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MenuSection_menu-section-container_31R9y {\n  width: 100%;\n  border-top: 1px solid #e0e4e9;\n  padding: 8px 12px;\n  color: #8695a7;\n}\n.MenuSection_menu-section-item_3r3fj {\n  width: 100%;\n}\n.MenuSection_menu-section-item_3r3fj:hover {\n  background-color: #f4f7fc;\n}\n.MenuSection_show-more-container_1VFyL {\n  padding-left: 8px;\n  margin-bottom: 10px;\n  cursor: default;\n  color: #00b4ec;\n}\n.MenuSection_extra-functions-container_M0c1M {\n  width: 100%;\n  border-top: 1px solid #e0e4e9;\n}\n.MenuSection_extra-function-item_1y9se {\n  padding-left: 20px;\n  cursor: default;\n  color: #00b4ec;\n  width: 100%;\n  height: 34px;\n  padding-top: 6px;\n}\n.MenuSection_extra-function-item_1y9se:hover {\n  background-color: #f4f7fc;\n}\n", ""]);

// exports
exports.locals = {
	"menu-section-container": "MenuSection_menu-section-container_31R9y",
	"menuSectionContainer": "MenuSection_menu-section-container_31R9y",
	"menu-section-item": "MenuSection_menu-section-item_3r3fj",
	"menuSectionItem": "MenuSection_menu-section-item_3r3fj",
	"show-more-container": "MenuSection_show-more-container_1VFyL",
	"showMoreContainer": "MenuSection_show-more-container_1VFyL",
	"extra-functions-container": "MenuSection_extra-functions-container_M0c1M",
	"extraFunctionsContainer": "MenuSection_extra-functions-container_M0c1M",
	"extra-function-item": "MenuSection_extra-function-item_1y9se",
	"extraFunctionItem": "MenuSection_extra-function-item_1y9se"
};