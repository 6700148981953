import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { getUseCaseAsUrlParam, parseUseCaseStateFromUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCases from "@Primitives/UseCases";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import CareActivityId from "@Primitives/CareActivityId.g";
import { CantCloseCareActivityDialogNavigateTo } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CantCloseCareActivityPanel/CantCloseCareActivityDialogParams";
import OutpatientTreatmentRoutes from "@HisPlatform/Components/Pages/OutpatientTreatment/OutpatientTreatmentRoutes";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import { ScreenNavigationContextAdapter, ScreenNavigationContextStore } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { ActionDispatcherAdapter } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import ShowPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientScreenAction.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import { HisErrorBoundary } from "@HisPlatformControls";
import PageBoxContainer from "@CommonControls/PageBoxContainer";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import ErrorListWorklistPanel from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/ErrorListWorklistPanel/ErrorListWorklistPanel";
import performanceStatementStandaloneRouteDefinitions from "@HunSocialSecurityPlugin/UseCases/PerformanceStatementUseCase/PerformanceStatementRoutes";
import HunSocialSecurityUseCases from "@HunSocialSecurityPlugin/UseCases/HunSocialSecurityUseCases";

interface IErrorListPageDependencies {
    routingStore: GlobalRoutingStore;
    applicationContext: ApplicationContext;
    useCaseRegistry: IUseCaseRegistry;
}

interface IErrorListPageProps extends IRoutingFrameContentProps {
    _dependencies?: IErrorListPageDependencies;
    _actionDispatcher?: IActionDispatcher;
    _screenNavigationContext?: ScreenNavigationContextStore;
    _modalService?: IModalService;
}

@State.observer
class ErrorListPage extends React.Component<IErrorListPageProps> {

    private get routingController(): IRoutingController {
        return this.props.routingController;
    }

    public componentWillMount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(new UseCaseIdentifier(HunSocialSecurityUseCases.errorList));
    }

    public componentWillUnmount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(null);
    }

    @State.computed
    private get selectedRowId(): string {
        if (this.routingController.currentRoute.parameters.selectedRowId) {
            return this.routingController.currentRoute.parameters.selectedRowId;
        }
        return null;
    }

    @State.computed
    private get selectedPerformanceStatementRowId(): string {
        if (this.routingController.currentRoute.parameters.selectedPerformanceStatementRowId) {
            return this.routingController.currentRoute.parameters.selectedPerformanceStatementRowId;
        }
        return null;
    }

    @State.computed
    private get useCaseState() {
        if (!this.props.routingController.currentRoute.parameters.useCase) {
            return null;
        }
        const useCaseState = this.props.routingController.currentRoute.parameters.useCase;

        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.bound
    private onBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setUseCaseState(rowId: string, useCaseState: INDataUseCaseState) {
        if (useCaseState?.useCase?.value === UseCases.patientRegisterPatientData) {
            const patientId = useCaseState.useCaseArguments[0].value;

            this.props._actionDispatcher.dispatchAsync(
                new ShowPatientScreenAction(ScreenDisplayMode.Full, patientId, null),
                { navigationContext: this.props._screenNavigationContext, modalService: this.props._modalService });
        } else {
            this.props.routingController.push(performanceStatementStandaloneRouteDefinitions.errorList.makeRoute({
                ...this.props.routingController.currentRoute.parameters,
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments),
                selectedRowId: rowId,
                selectedPerformanceStatementRowId: this.selectedPerformanceStatementRowId
            }));
        }
    }

    @State.bound
    private cantCloseCareActivityNavigateByActions(careActivityId: CareActivityId, navigateTo: CantCloseCareActivityDialogNavigateTo) {
        switch (navigateTo) {
            case CantCloseCareActivityDialogNavigateTo.CareDocuments:
                this.routingController.push(OutpatientTreatmentRoutes.documentList.makeRoute({
                    careActivityId: careActivityId.value,
                    selectedDocumentId: null
                }));
                break;
            case CantCloseCareActivityDialogNavigateTo.TextBlocks:
                this.routingController.push(OutpatientTreatmentRoutes.textBlocks.makeRoute({ careActivityId: careActivityId.value, textBlockTypeId: null }));
                break;
            case CantCloseCareActivityDialogNavigateTo.ClinicalData:
                this.routingController.push(OutpatientTreatmentRoutes.baseData.makeRoute({ careActivityId: careActivityId.value }));
                break;
            case CantCloseCareActivityDialogNavigateTo.DischargeData:
                this.routingController.push(OutpatientTreatmentRoutes.discharge.makeRoute({ careActivityId: careActivityId.value }));
                break;
            case CantCloseCareActivityDialogNavigateTo.PerformedServices:
                this.routingController.push(OutpatientTreatmentRoutes.performedServiceList.makeRoute({ careActivityId: careActivityId.value }));
                break;
            case CantCloseCareActivityDialogNavigateTo.Diagnoses:
                this.routingController.push(OutpatientTreatmentRoutes.diagnosisList.makeRoute({ careActivityId: careActivityId.value }));
                break;
            case CantCloseCareActivityDialogNavigateTo.ServiceRequests:
            case CantCloseCareActivityDialogNavigateTo.ServiceRequestObservations:
                this.routingController.push(OutpatientTreatmentRoutes.servicesRequests.makeRoute({
                    careActivityId: careActivityId.value,
                    filter: "CareActivity",
                    mode: "edit"
                }));
                break;
            default:
                throw new Error("CareActivityStatusChangeReason value not supported.");
        }
    }

    public render() {
        return (
            <div>
                <PageBoxContainer>
                    <HisErrorBoundary>
                        <SingleLayout>
                            <ErrorListWorklistPanel
                                useCaseState={this.useCaseState}
                                onChange={this.setUseCaseState}
                                selectedRowId={this.selectedRowId}
                                selectedPerformanceStatementRowId={this.selectedPerformanceStatementRowId}
                                onBack={this.onBack}
                                onCantCloseCareActivityNavigateByAction={this.cantCloseCareActivityNavigateByActions}
                            />
                        </SingleLayout>
                    </HisErrorBoundary>
                </PageBoxContainer>
            </div>
        );
    }
}

export default connect(
    withHisErrorBoundary(ErrorListPage),
    new DependencyAdapter<IErrorListPageProps, IErrorListPageDependencies>(container => {
        return {
            routingStore: container.resolve("GlobalRoutingStore"),
            applicationContext: container.resolve("ApplicationContext"),
            useCaseRegistry: container.resolve("IUseCaseRegistry")
        };
    }),
    new ActionDispatcherAdapter(),
    new ScreenNavigationContextAdapter(),
    new HisModalServiceAdapter()
);
