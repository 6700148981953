import React from "react";
import { IFormEditorComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import { DoctorCodeSelector } from "@HisPlatformControls";
import ReferencedEntityFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEntityFormDataElement";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";

// tslint:disable-next-line: no-empty-interface
interface IFormDoctorCodeSelectorProps extends IFormEditorComponentProps {

}

function FormDoctorCodeSelector(props: IFormDoctorCodeSelectorProps) {
    
    const dataElement = props.dataElement as ReferencedEntityFormDataElement;

    const isExternalString = dataElement.customParams?.["IsExternal"];
    const isExternal = ConvertStringToBool(isExternalString);

    return (
        <DoctorCodeSelector
            {...props}
            isExternal={isExternal}         
        />
    ); 
}

function ConvertStringToBool(isExternalString: string) :boolean {
    if (isNullOrEmptyString(isExternalString))
        return null;
    if (isExternalString.toLowerCase() === "true")
        return true;
    return false;
}

export default FormDoctorCodeSelector;