import PlanningPeriodId from "@Primitives/PlanningPeriodId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import TimePhaseStore from "./TimePhaseStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";

export default class PlanningPeriod implements IDirtyChecked {
    public id: PlanningPeriodId;
    @State.observable.ref public interval: LocalDateRange;
    @State.observable public isDeleted: boolean;
    @State.observable.ref public timePhases: TimePhaseStore[] = [];

    @State.action.bound
    public setInterval(value: LocalDateRange) {
        this.interval = value;
    }

    @State.action.bound
    public delete() {
        this.isDeleted = true;
    }

    private _dirtyChecker = new StoreDirtyChecker();

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker"
    ];

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this) || this.timePhases.some(t => t.isDirty());
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }

    @State.action.bound
    public setTimePhases(value: TimePhaseStore[]) {
        this.timePhases = value;
    }

    @State.action.bound
    public addTimePhase(value: TimePhaseStore) {
        this.timePhases = this.timePhases.concat([value]);
    }

    @State.action.bound 
    public removeTimePhase(value: TimePhaseStore) {
        this.timePhases = this.timePhases.filter(t => !ValueWrapper.equals(value.id, t.id));
    }

}
