// tslint:disable:variable-name
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import TabbedScreenStore from "./TabbedScreenStore";

const { 
    ContextComponent: TabbedScreenStoreContext, 
    StoreProvider: TabbedScreenStoreProvider, 
    useStore: useTabbedScreenStore,
    provideStore: provideTabbedScreenStore,
} = createPanelStoreProvider(TabbedScreenStore);

export {
    TabbedScreenStoreContext,
    TabbedScreenStoreProvider,
    useTabbedScreenStore,
    provideTabbedScreenStore
};