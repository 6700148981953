import ExtendedFilterStoreBase from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ExtendedFilterStoreBase";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import { IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import { createFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterHelpers";
import CareActivityId from "@Primitives/CareActivityId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";

export default class EhiCovidStatusReportFilterStore extends ExtendedFilterStoreBase {
    constructor(
        filterStore: IFilterStore
    ) {
        super(filterStore);
    }

    @State.action.bound
    public clearFilters() {
        this.setCareActivityId(null);
        this.setIncludeDeleted(false);
        this.setDateInterval(null);
    }

    @State.action.bound
    public clearAllFilters() {
        this.clearFilters();
        this.filterStore.__reset();
    }

    @State.observable.ref public careActivityId: CareActivityId = null;
    @State.observable public includeDeleted: boolean = false;
    @State.observable.ref public dateInterval: LocalDateRange = null;

    @State.action.bound
    public setCareActivityId(value: CareActivityId) {
        this.careActivityId = value;
        this.setFilter("EhiCovidStatusReportBased_CareActivityId", value);
    }

    @State.action.bound
    public setIncludeDeleted(value: boolean) {
        this.includeDeleted = value;
        this.setFilter("EhiCovidStatusReportBased_IncludeDeletedFilter", value);
    }

    @State.action.bound
    public setDateInterval(value: LocalDateRange) {
        this.dateInterval = value;
        this.setFilter("EhiCovidStatusReportBased_StatusReportAtFilter", value);
    }

    public static getFilterDescriptors() {
        const descriptors: IDataGridFilterDescriptor[] = [];

        const careActivityIdFilterDescriptor = createFilterDescriptor("EhiCovidStatusReportBased_CareActivityId", {
            serialize: (value: CareActivityId) => value.value,
            deserialize: (value: string) => new CareActivityId(value)
        } as IDataGridFilterValueSerializer<CareActivityId>,
            WorklistConditionType.EntityId);
        descriptors.push(careActivityIdFilterDescriptor);

        const includeDeletedFilterDescriptor = createFilterDescriptor("EhiCovidStatusReportBased_IncludeDeletedFilter", {
            serialize: (value: boolean) => value.toString(),
            deserialize: (value: string) => value === "true" ? true : false
        } as IDataGridFilterValueSerializer<boolean>,
            WorklistConditionType.Boolean);
        descriptors.push(includeDeletedFilterDescriptor);

        const dateIntervalFilterDescriptor = createFilterDescriptor("EhiCovidStatusReportBased_StatusReportAtFilter", {
            serialize: (value: LocalDateRange) => JSON.stringify(value),
            deserialize: (value: string) => LocalDateRange.parse(value)
        } as IDataGridFilterValueSerializer<LocalDateRange>,
            WorklistConditionType.Date);
        descriptors.push(dateIntervalFilterDescriptor);

        return descriptors;
    }
}