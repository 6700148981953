export function stringifyAndOrderByKey(object: any) {
    const keyOrdered = Object.keys(object).sort().reduce(
        (obj, key) => {
            obj[key] = object[key];
            return obj;
        },
        {}
    );

    return JSON.stringify(keyOrdered);
}