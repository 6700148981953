import Di from "@Di";
import Command from "@Toolkit/CommonWeb/CommandProcessing/Definition/Command";
import CommandMessage from "@Toolkit/CommonWeb/CommandProcessing/Definition/CommandMessage";
import ICommandFactory from "@Toolkit/CommonWeb/CommandProcessing/Definition/ICommandFactory";

export default class SendInitializationPayloadCommand extends Command {
    constructor(originator: string) {
        super("SendInitializationPayloadCommand", originator);
    }
}

@Di.injectable()
export class SendInitializationPayloadCommandFactory implements ICommandFactory {
    get commandType(): string {
        return "SendInitializationPayloadCommand";
    }

    /** {@inheritdoc} */
    public createFromMessage(message: CommandMessage): Command {
        return new SendInitializationPayloadCommand(message.originator);
    }
}