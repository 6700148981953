import FormLogic from "@Toolkit/FormEngine/Model/FormLogic";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class FormLogicListItemStore {
    @State.observable public order: number;
    @State.observable public id: string;
    @State.observable public formLogic: FormLogic;

    constructor(
        id: string,
        order: number,
        formLogic: FormLogic,
    ) {
        this.id = id;
        this.order = order;
        this.formLogic = formLogic;
    }

    @State.action.bound
    public setOrder(order: number) {
        this.order = order;
    }

    @State.action.bound
    public setFormLogicContent(formLogicContent: string) {
        this.formLogic.content = formLogicContent;
    }
}