import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IAllergyIntoleranceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/IAllergyIntoleranceVersion";
import AllergyIntoleranceId from "@Primitives/AllergyIntoleranceId.g";
import IAllergyIntoleranceHintItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/IAllergyIntoleranceHintItem";
import AllergyIntoleranceCategoryId from "@Primitives/AllergyIntoleranceCategoryId.g";
import AllergyIntoleranceClassificationTypeId from "@Primitives/AllergyIntoleranceClassificationTypeId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import { stringifyVersionSelectors } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ReferenceDataHelpers";
import AllergyIntoleranceReactionId from "@Primitives/AllergyIntoleranceReactionId.g";
import IAllergyIntoleranceReactionVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/IAllergyIntoleranceReactionVersion";
import IAllergyIntoleranceReactionHintItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/IAllergyIntoleranceReactionHintItem";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IAllergyIntoleranceHintItemExtended from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/IAllergyIntoleranceHintItemExtended";
import AllergyIntoleranceReactionSelectorQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/AllergiesAndIntolerances/Enum/AllergyIntoleranceReactionSelectorQueryOrderingFields.g";
import AllergyIntoleranceSelectorQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/AllergiesAndIntolerances/Enum/AllergyIntoleranceSelectorQueryOrderingFields.g";

@Di.injectable()
export default class AllergyIntoleranceApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IAllergiesAndIntolerancesClient") private readonly apiClient: Proxy.IAllergiesAndIntolerancesClient
    ) {
        super();
    }

    public searchAllergyIntolerancesByFreeTextAsync(searchString: string, maxResultCount: number, validOn?: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<IAllergyIntoleranceHintItem[]>(),
            async target => {
                const response = await this.apiClient.searchAllergyIntolerancesQueryAsync(
                    CreateRequestId(),
                    searchString,
                    maxResultCount.toString(),
                    validOn ? validOn.stringify() : ""
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.allergyIntolerances.map(ai => {
                    return {
                        id: ai.id,
                        code: ai.code,
                        name: ai.name
                    } as IAllergyIntoleranceHintItem;
                });
            }
        );
    }
    public searchAllergyIntolerancesExtendedByFreeTextAsync(searchString: string, maxResultCount: number, validOn?: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<IAllergyIntoleranceHintItemExtended[]>(),
            async target => {
                const response = await this.apiClient.searchAllergyIntolerancesExtendedQueryAsync(
                    CreateRequestId(),
                    searchString,
                    maxResultCount.toString(),
                    validOn ? validOn.stringify() : ""
                );
                target.operationInfo = createOperationInfo(response);
                target.value = [];
                for (let index = 0; index < maxResultCount; index++) { 
                    if (index < response.allergyIntolerances.length && target.value.length <= maxResultCount) {
                        target.value.push({
                            allergyIntoleranceId: response.allergyIntolerances[index].id,
                            code: response.allergyIntolerances[index].code,
                            name: response.allergyIntolerances[index].name,
                            categoryId: response.allergyIntolerances[index].categoryId
                        } as IAllergyIntoleranceHintItemExtended);
                    }
                    if (index < response.medications.length && target.value.length <= maxResultCount) {
                        target.value.push({
                            medicationId: response.medications[index].id,
                            code: response.medications[index].identifiers[0].value,
                            name: response.medications[index].name,
                            categoryId: AllergyIntoleranceCategoryId.medication
                        } as IAllergyIntoleranceHintItemExtended);
                    }
                    if (index < response.substances.length && target.value.length <= maxResultCount) {
                        target.value.push({
                            substanceId: response.substances[index].id,
                            name: response.substances[index].name,
                            code: response.substances[index].identifier.value,
                            categoryId: AllergyIntoleranceCategoryId.medication
                        } as IAllergyIntoleranceHintItemExtended);
                    }
                    if (index < response.internationalNonproprietaryNames.length && target.value.length <= maxResultCount) {
                        target.value.push({
                            internationalNonproprietaryNameId: response.internationalNonproprietaryNames[index].id,
                            name: response.internationalNonproprietaryNames[index].name,
                            code: response.internationalNonproprietaryNames[index].code,
                            categoryId: AllergyIntoleranceCategoryId.medication
                        } as IAllergyIntoleranceHintItemExtended);
                    }
                    if (index > response.allergyIntolerances.length && index > response.substances.length && index > response.medications.length || target.value.length >= maxResultCount) {
                        return;
                    }
                }
            }
        );
    }

    public getAllergyIntolerancesAsync(): Promise<SimpleStore<AllergyIntoleranceId[]>> {
        return this.processOperationAsync(
            new SimpleStore<AllergyIntoleranceId[]>(),
            async target => {
                const response = await this.apiClient.getAllergyIntoleranceIdsQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.allergyIntoleranceIds.map(id => id);
            }
        );
    }

    private mapAllergyIntoleranceVersion(ai: Proxy.AllergyIntoleranceDto): IAllergyIntoleranceVersion {
        return {
            id: ai.id,
            categoryId: ai.categoryId,
            classificationTypeId: ai.classificationTypeId,
            code: ai.code,
            type: ai.type,
            name: ai.name,
            description: ai.description,
            validity: ai.validity
        } as IAllergyIntoleranceVersion;
    }

    @State.bound
    public loadVersionsAsync(versionSelectors: Array<IEntityVersionSelector<AllergyIntoleranceId>>): Promise<SimpleStore<IAllergyIntoleranceVersion[]>> {
        return this.processOperationAsync(
            new SimpleStore<IAllergyIntoleranceVersion[]>(),
            async (target) => {
                const versionSelectorsString = stringifyVersionSelectors(versionSelectors);
                const response = await this.apiClient.getAllergyIntoleranceVersionsBySelectorsQueryAsync(
                    CreateRequestId(),
                    versionSelectorsString
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.allergyIntoleranceVersions.map(this.mapAllergyIntoleranceVersion);
            }
        );
    }

    @State.bound
    public loadAllergyIntoleranceClassificationTypeIdsAsync(): Promise<SimpleStore<AllergyIntoleranceClassificationTypeId[]>> {
        return this.processOperationAsync(
            new SimpleStore<AllergyIntoleranceClassificationTypeId[]>(),
            async target => {
                const response = await this.apiClient.getAllergyIntoleranceClassificationTypeIdsQueryAsync(CreateRequestId(), "");
                target.operationInfo = createOperationInfo(response);
                target.value = response.allergyIntoleranceClassificationTypeIds;
            }
        );
    }

    @State.bound
    public loadAllergyIntoleranceCategoryIdsAsync(): Promise<SimpleStore<AllergyIntoleranceCategoryId[]>> {
        return this.processOperationAsync(
            new SimpleStore<AllergyIntoleranceCategoryId[]>(),
            async target => {
                const response = await this.apiClient.getAllergyIntoleranceCategoryIdsQueryAsync(CreateRequestId(), "");
                target.operationInfo = createOperationInfo(response);
                target.value = response.allergyIntoleranceCategoryIds;
            }
        );
    }

    @State.bound
    public loadReactionVersionsAsync(versionSelectors: Array<IEntityVersionSelector<AllergyIntoleranceReactionId>>): Promise<SimpleStore<IAllergyIntoleranceReactionVersion[]>> {
        return this.processOperationAsync(
            new SimpleStore<IAllergyIntoleranceReactionVersion[]>(),
            async (target) => {
                const versionSelectorsString = stringifyVersionSelectors(versionSelectors);
                const response = await this.apiClient.getAllergyIntoleranceReactionVersionsBySelectorsQueryAsync(
                    CreateRequestId(),
                    versionSelectorsString
                );
                target.operationInfo = createOperationInfo(response);
                target.value = response.allergyIntoleranceReactionVersions.map(this.mapAllergyIntoleranceReactionVersion);

            }
        );
    }

    public getAllergyIntoleranceReactionsAsync(): Promise<SimpleStore<AllergyIntoleranceReactionId[]>> {
        return this.processOperationAsync(
            new SimpleStore<AllergyIntoleranceReactionId[]>(),
            async target => {
                const response = await this.apiClient.getAllergyIntoleranceReactionIdsQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.allergyIntoleranceReactionIds;
            }
        );
    }

    public searchAllergyIntoleranceReactionsByFreeTextAsync(searchString: string, maxResultCount: number, validOn?: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<IAllergyIntoleranceReactionHintItem[]>(),
            async target => {
                const response = await this.apiClient.searchAllergyIntoleranceReactionsQueryAsync(
                    CreateRequestId(),
                    searchString,
                    maxResultCount.toString(),
                    validOn ? validOn.stringify() : ""
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.allergyIntoleranceReactions.map(ai => {
                    return {
                        id: ai.id,
                        code: ai.code,
                        name: ai.name
                    } as IAllergyIntoleranceReactionHintItem;
                });
            }
        );
    }

    @State.bound
    public searchAllergyIntolerance(
        name: string,
        validOn: LocalDate,
        ordering: IOrderingState,
        paging: IPagingState,
        allergyIntoleranceCategory: AllergyIntoleranceCategoryId) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<IAllergyIntoleranceVersion>>(),
            async (target) => {
                const response = await this.apiClient.allergyIntoleranceSelectorQueryAsync(
                    CreateRequestId(),
                    new Proxy.AllergyIntoleranceSelectorControllerDto({
                        name: name,
                        allergyIntoleranceCategory: allergyIntoleranceCategory,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfAllergyIntoleranceSelectorQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfAllergyIntoleranceSelectorQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: AllergyIntoleranceSelectorQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        }),
                        validOn: validOn
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results && response.results.values.map(this.mapAllergyIntoleranceVersion)
                };

            }
        );
    }
    private mapAllergyIntoleranceReactionVersion(ai: Proxy.AllergyIntoleranceReactionDto): IAllergyIntoleranceReactionVersion {
        return {
            id: ai.id,
            code: ai.code,
            name: ai.name,
            description: ai.description,
            validity: ai.validity
        } as IAllergyIntoleranceReactionVersion;
    }

    @State.bound
    public searchAllergyIntoleranceReaction(filter: string, validOn: LocalDate, ordering: IOrderingState, paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<IAllergyIntoleranceReactionVersion>>(),
            async (target) => {
                const response = await this.apiClient.allergyIntoleranceReactionSelectorQueryAsync(
                    CreateRequestId(),
                    new Proxy.AllergyIntoleranceReactionSelectorControllerDto({
                        filterText: filter,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfAllergyIntoleranceReactionSelectorQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfAllergyIntoleranceReactionSelectorQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: AllergyIntoleranceReactionSelectorQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        }),
                        validOn: validOn
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results && response.results.values.map(this.mapAllergyIntoleranceReactionVersion)
                };
            }
        );
    }
}
