import React, { useMemo } from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";

interface IRequestedServiceCommentBoxProps {
    row?: RequestedServiceStore;
}

export default function RequestedServiceCommentBox(props: IRequestedServiceCommentBoxProps): React.ReactElement {
    const scalarObservation = props.row.scalarObservation;

    const dependencies = useDependencies(c => ({
        careReferenceDataStore: c.resolve<CareReferenceDataStore>("CareReferenceDataStore"),
    }));

    const service = useMemo(() => {
        return dependencies.careReferenceDataStore.medicalService.get(props.row.medicalServiceVersionSelector);
    }, [dependencies.careReferenceDataStore, props.row]);

    if (!scalarObservation) {
        return null;
    }

    return (
        <State.Observer>
            {() => (
                <Ui.TextBox
                    value={scalarObservation.remarks}
                    onChange={scalarObservation.setRemarks}
                    isReadOnly={!props.row.isCompleteDiagnosticReportAllowed}
                    style={{ marginRight: "6px" }}
                    automationId={`service_comment_${service.code.value}`}
                    stopClickPropagation
                />
            )}
        </State.Observer>
    );
}