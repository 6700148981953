import UserId from "@Primitives/UserId.g";
import AccessControlSubjectBase from "./AccessControlSubjectBase";

export default class UserSubject extends AccessControlSubjectBase {
    public userId: UserId;

    constructor(userId?: UserId) {
        super();
        this.userId = userId;
    }

    public equals(other: AccessControlSubjectBase): boolean {
        return other && other instanceof UserSubject && other.userId.value === this.userId.value;
    }
}