import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IDocumentPreviewModalParams } from "./DocumentPreviewModalParams";
import * as Ui from "@CommonControls";
import DocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/DocumentApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import TemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TemplateBasedDocument";
import BinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/BinaryDocument";
import { ContextAwareModal } from "@HisPlatformControls";
import TemplateApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Templating/TemplateApiAdapter";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import DocumentManagementReferenceDataStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentManagementReferenceDataStore";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import LargeDataToken from "@Primitives/LargeDataToken.g";

interface IDocumentPreviewModalDependencies {
    documentApiAdapter: DocumentApiAdapter;
    templateApiAdapter: TemplateApiAdapter;
    fileSaverService: IFileSaverService;
    documentReferenceDataStore: DocumentManagementReferenceDataStore;
}

interface IDocumentPreviewModalProps extends IModalComponentParams, IDocumentPreviewModalParams {
    _dependencies?: IDocumentPreviewModalDependencies;
}

/** @screen */
@State.observer
class DocumentPreviewModal extends React.Component<IDocumentPreviewModalProps> {

    @State.observable.ref private documentPath: LargeDataToken = null;
    @State.observable.ref private downloadFileName: string = "";
    @State.observable private title: string = null;
    private get documentTypeStore() {
        return this.props._dependencies.documentReferenceDataStore.documentTypeMap;
    }

    @State.action.bound
    private setDocumentPath(documentPath: LargeDataToken) {
        this.documentPath = documentPath;
    }

    @State.action.bound
    private setDownloadFileName(downloadFileName: string) {
        this.downloadFileName = downloadFileName;
    }

    public render() {
        return (
            <ContextAwareModal isOpen onClose={this.close} size="fullscreen" sideGaps={0} closeButton title={this.title}>
                <Ui.Modal.Body noGap>
                    {this.loadPanelAsync.isUnauthorizedAccess ?
                        <UnauthorizedAccessContent /> :
                        <Ui.PdfViewer2 documentPath={this.documentPath} downloadFileName={this.downloadFileName} height="100%" />
                    }
                </Ui.Modal.Body>
            </ContextAwareModal>
        );
    }

    private loadPanelAsync = createInitialPanelLoader(this.loadAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.loadPanelAsync(), this);
        dispatchAsyncErrors(this.loadReferenceDataAsync(), this);
    }

    @State.bound
    private async loadReferenceDataAsync() {
        await this.props._dependencies.documentReferenceDataStore.documentTypeMap.ensureAllLoadedAsync();
    }

    @State.action private setTitle(title: string) {
        this.title = title;
    }

    @State.bound
    private async loadAsync() {
        if (this.props.documentId) {
            const result = await this.props._dependencies.documentApiAdapter.getDocumentByIdAsync(this.props.documentId);

            if (result.value instanceof BinaryDocument) {
                if (result.value.content.type === "application/pdf") {
                    this.setDocumentPath(result.value.largeDataToken);
                    this.setDownloadFileName(result.value.downloadFileName);
                }
            } else if (result.value instanceof TemplateBasedDocument) {
                this.setTitle(result.value.info.name);
                const previewResponse = await this.props._dependencies.documentApiAdapter.getDocumentPreviewAsync(this.props.documentId);
                this.setDocumentPath(previewResponse.value.token);
                this.setDownloadFileName(result.value.downloadFileName);
            }
        }
    }

    @State.bound
    private close() {
        this.props.onClose(null);
    }
}

export default connect(
    DocumentPreviewModal,
    new DependencyAdapter<IDocumentPreviewModalProps, IDocumentPreviewModalDependencies>(c => ({
        documentApiAdapter: c.resolve("DocumentApiAdapter"),
        templateApiAdapter: c.resolve("TemplateApiAdapter"),
        fileSaverService: c.resolve("IFileSaverService"),
        documentReferenceDataStore: c.resolve("DocumentManagementReferenceDataStore")
    }))
);