import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import TargetValueId from "@Primitives/TargetValueId.g";

interface ITargetValueColumnDependencies {
    referenceDataStore: MedicationRequestReferenceDataStore;
}

interface ITargetValueColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: ITargetValueColumnDependencies;
}


const TargetValueColumn: React.FC<ITargetValueColumnProps> = (props: ITargetValueColumnProps) => {

    const valueRenderer = (value: TargetValueId) => {
        if (value) {
            const targetValue = props._dependencies.referenceDataStore.targetValues.get(value);
            return targetValue?.displayValue?.Name ?? "";
        }
        return "";
    };

    return (
        <DataGridColumn
            onRenderCellValue={valueRenderer}
            {...props}
        />
    );
};

export default connect(
    TargetValueColumn,
    new DependencyAdapter<ITargetValueColumnProps, ITargetValueColumnDependencies>(c => ({
        referenceDataStore: c.resolve("MedicationRequestReferenceDataStore")
    }))
);