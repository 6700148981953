import React from "react";
import { lazyResolve } from "@DiContainer";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import InsurancePlanId from "@Primitives/InsurancePlanId.g";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IInsurancePlan from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/IInsurancePlan";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";

interface IInsurancePlanSelectBoxDependencies {
    financeReferenceDataStore: FinanceReferenceDataStore;
}

interface IInsurancePlanSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IInsurancePlanSelectBoxDependencies;
    insurerOrganizationId?: InsurerOrganizationId;
    onChange: (newValue: InsurancePlanId) => void;
    value: InsurancePlanId;
}

@State.observer
class InsurancePlanSelectBox extends React.Component<IInsurancePlanSelectBoxProps> {

    private get referenceDataStore() {
        return this.props._dependencies.financeReferenceDataStore;
    }

    @State.observable.ref private items: Array<ISelectBoxItem<InsurancePlanId>> = null;
    @State.observable.ref private disabled: boolean = true;

    constructor(props: IInsurancePlanSelectBoxProps) {
        super(props);
    }

    public render() {
        return (
            <Ui.SelectBox
                {...this.props}
                items={this.items}
                searchable={true}
                onChange={this.props.onChange}
                valueOnClear={null}
                disabled={this.disabled}
                clearable={false}
            />
        );
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.fetchItemsAsync(this.props.insurerOrganizationId), this);
    }

    public componentDidUpdate(prevProps: IInsurancePlanSelectBoxProps) {
        if (this.props.insurerOrganizationId !== prevProps.insurerOrganizationId) {
            dispatchAsyncErrors(this.fetchItemsAsync(this.props.insurerOrganizationId), this);
        }
    }

    @State.bound
    public async fetchItemsAsync(insurerOrganizationId: InsurerOrganizationId) {
        if (insurerOrganizationId && insurerOrganizationId.value) {
            const ids = await this.referenceDataStore.getInsurancePlanIdsByInsurerOrganizationIdAsync(insurerOrganizationId);
            await this.referenceDataStore.insurancePlanMap.ensureLoadedAsync(ids.value);

            if (ids.value.length > 0) {
                this.setItems(insurerOrganizationId);
            } else {
                this.disableTextBox();
            }
        }

        if (!this.items || this.items.length === 0) {
            this.disableTextBox();
        }
    }

    @State.action.bound
    public setItems(insurerOrganizationId: InsurerOrganizationId) {
        this.disabled = false;
        this.items = this.referenceDataStore.insurancePlanMap.items.filter(x => x.insurerOrganizationId.value === insurerOrganizationId.value).map(this.toSelectBoxItem);
        if (this.props.onChange && !this.props.value || !this.items.find(item => item.value.value === this.props.value.value)) {
            this.props.onChange(this.items.sort((a, b) => a.text.localeCompare(b.text))[0].value);
        }
    }

    @State.action.bound
    public disableTextBox() {
        this.disabled = true;
        if (this.props.onChange) {
            this.props.onChange(null);
        }
        this.items = [{ text: "", value: null }];
    }

    @State.bound
    private toSelectBoxItem(item: IInsurancePlan) {
        const currentItem = this.referenceDataStore.insurancePlanMap.get(item.id);
        return {
            text: currentItem.name,
            value: item.id
        } as ISelectBoxItem<InsurancePlanId>;
    }
}

export default connect(
    InsurancePlanSelectBox,
    new DependencyAdapter<IInsurancePlanSelectBoxProps, IInsurancePlanSelectBoxDependencies>((container) => {
        return {
            financeReferenceDataStore: container.resolve("FinanceReferenceDataStore")
        };
    })
);
