import React from "react";
import config from "@Config";
import ErrorPage from "@Toolkit/ReactClient/Components/ErrorPage/ErrorPage";

export default function UnderMaintenancePage() {
    return <ErrorPage
        title={config.serviceUnavailableMessage.title}
        message={config.serviceUnavailableMessage.message}
        isRefreshButton={true}
        refreshButtonText={config.serviceUnavailableMessage.refreshButtonText} />;
}