import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import InvoiceTypeId from "@Primitives/InvoiceTypeId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { ExtensibleEnumSelectBox } from "@HisPlatform/Components/HisPlatformControls";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";

interface IInvoiceTypeIdColumnDependencies {
    financeReferenceDataStore: FinanceReferenceDataStore;
}

interface IInvoiceTypeIdColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IInvoiceTypeIdColumnDependencies;
}

const filterValueSerializer: IDataGridFilterValueSerializer<InvoiceTypeId> = {
    deserialize: (value: string) => {
        if (!value) {
            return null;
        }
        
        return new InvoiceTypeId(value);
    },
    serialize: (value: InvoiceTypeId) => {
        return value.value;
    }
};

const InvoiceTypeIdColumn: React.FC<IInvoiceTypeIdColumnProps> = (props: IInvoiceTypeIdColumnProps) => {
    const valueRenderer = useCallback((value: InvoiceTypeId) => {
        const item = isNullOrUndefined(value) ? null : props._dependencies.financeReferenceDataStore.invoiceTypeMap.get(value);
        if (isNullOrUndefined(item)) {
            return null;
        }

        return item.displayValue.Name;
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<InvoiceTypeId>) => {
        return (
            <ExtensibleEnumSelectBox
                {...filterProps}
                maxMenuWidth={commonMenuMaxWidth}
                extensibleEnumStore={props._dependencies.financeReferenceDataStore.invoiceTypeMap}
                hoverOnlyIndicatorIcons />
        );
    }, []);

    return (
        <DataGridColumn
            width={50}
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
        />
    );
};

export default connect(
    InvoiceTypeIdColumn,
    new DependencyAdapter<IInvoiceTypeIdColumnProps, IInvoiceTypeIdColumnDependencies>( c => ({
        financeReferenceDataStore: c.resolve("FinanceReferenceDataStore")
    }))
);