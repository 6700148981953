exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EnumBadge_main_3u6Ts {\n  display: inline-block;\n  width: 22px;\n  height: 22px;\n  padding: 0;\n  line-height: 22px;\n}\n.EnumBadge_main-rectangle_2DA5y {\n  display: inline-block;\n  width: 22px;\n  height: 16px;\n  padding: 0;\n  line-height: 16px;\n}\n.EnumBadge_upper-index_2nAaN {\n  position: relative;\n  width: 16px;\n  height: 16px;\n  top: -12px;\n  left: -4px;\n  line-height: 16px;\n}\n.EnumBadge_border_c_R8W {\n  border: 1px solid white;\n}\n", ""]);

// exports
exports.locals = {
	"main": "EnumBadge_main_3u6Ts",
	"main-rectangle": "EnumBadge_main-rectangle_2DA5y",
	"mainRectangle": "EnumBadge_main-rectangle_2DA5y",
	"upper-index": "EnumBadge_upper-index_2nAaN",
	"upperIndex": "EnumBadge_upper-index_2nAaN",
	"border": "EnumBadge_border_c_R8W"
};