exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Monogram_circle_1otSQ {\n  width: 33px;\n  height: 33px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 1px 0 0 2px;\n}\n.Monogram_circle_1otSQ span {\n  font-size: 18px;\n}\n.Monogram_circle_1otSQ.Monogram_small_HbaKm {\n  width: 24px;\n  height: 24px;\n}\n.Monogram_circle_1otSQ.Monogram_small_HbaKm span {\n  font-size: 12px;\n}\n", ""]);

// exports
exports.locals = {
	"circle": "Monogram_circle_1otSQ",
	"small": "Monogram_small_HbaKm"
};