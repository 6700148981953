import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import FrequencyBasedDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/FrequencyBasedDosage";
import Dosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Dosage";
import ScheduledDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/ScheduledDosage";
import DosageInterval from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/DosageInterval";
import TextualDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/TextualDosage";
import SingleMedicationReference from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/SingleMedicationReference";
import MedicationReferenceBase from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationReferenceBase";
import MedicationEquipmentList from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationEquipmentList";
import MedicationEquipmentListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationEquipmentListItem";
import TextualPrescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/TextualPrescription";
import IEhiSingleMedication from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IEhiSingleMedication";
import IEhiMedicationBase from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IEhiMedicationBase";
import IEhiMedicationFormula from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IEhiMedicationFormula";
import IEhiMedicationEquipment from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IEhiMedicationEquipment";
import MedicationFormulaList from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationFormulaList";
import MedicationFormulaListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationFormulaListItem";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicationId from "@Primitives/MedicationId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import FamilyDoctorDocumentSingleMedication from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentSingleMedication";
import FamilyDoctorDocumentMedicationReference from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentMedicationReference";
import FamilyDoctorDocumentMedicationFormula from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentMedicationFormula";
import FamilyDoctorDocumentMedicationFormulaItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentMediactionFormulaItem";
import SpaService from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/SpaService";
import CurativeItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/CurativeItem";
import FamilyDoctorDocumentEquipmentReference from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentEquipmentReference";
import { TextualEMedicationDosage, FrequencyBasedEMedicationDosage } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/EMedicationDosage";
import ScheduleTimeOfDay from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/ScheduleTimeOfDay.g";

export function mapDosage(dosageDto: Proxy.DosageDto) {
    if (!dosageDto) {
        return null;
    } else {
        let dosage: Dosage = null;
        if (dosageDto instanceof Proxy.FrequencyBasedDosageDto) {
            const frequencyBasedDosage = new FrequencyBasedDosage();

            frequencyBasedDosage.amount = dosageDto.amount;
            frequencyBasedDosage.frequency = dosageDto.frequency;
            frequencyBasedDosage.interval = dosageDto.dosageInterval as unknown as DosageInterval;
            frequencyBasedDosage.intervalMultiplier = dosageDto.intervalMultiplier;

            dosage = frequencyBasedDosage;
        } else if (dosageDto instanceof Proxy.ScheduledDosageDto) {
            const morningDosage = dosageDto.schedule?.find(i => i.scheduleTimeOfDay === ScheduleTimeOfDay.Morning);
            const noonDosage = dosageDto.schedule?.find(i => i.scheduleTimeOfDay === ScheduleTimeOfDay.Noon);
            const eveningDosage = dosageDto.schedule?.find(i => i.scheduleTimeOfDay === ScheduleTimeOfDay.Evening);
            const beforeSleepDosage = dosageDto.schedule?.find(i => i.scheduleTimeOfDay === ScheduleTimeOfDay.BeforeSleep);

            const scheduledDosage = new ScheduledDosage();

            scheduledDosage.morningAmount = morningDosage?.amount || 0;
            scheduledDosage.noonAmount = noonDosage?.amount || 0;
            scheduledDosage.eveningAmount = eveningDosage?.amount || 0;
            scheduledDosage.beforeSleepAmount = beforeSleepDosage?.amount || 0;

            dosage = scheduledDosage;
        } else if (dosageDto instanceof Proxy.TextualDosageDto) {
            const textualDosage = new TextualDosage();
            textualDosage.dosageInstructions = dosageDto.dosageInstructions;
            dosage = textualDosage;
        } else {
            throw new Error("Dosage descendant not recognized.");
        }

        dosage.reason = dosageDto.reason;
        dosage.dailyAmount = dosageDto.dailyAmount;
        dosage.dosageMode = dosageDto.dosageMode;
        dosage.isForPractitioner = dosageDto.isForPractitioner;
        return dosage;
    }
}

export function mapEMedicationDosage(dosage: Proxy.EMedicationDosageDto) {
    if (!dosage) {
        return null;
    }

    if (dosage instanceof Proxy.TextualEMedicationDosageDto) {
        const textualDosage = new TextualEMedicationDosage();
        textualDosage.instruction = dosage.instruction;
        return textualDosage;
    }
    
    if (dosage instanceof Proxy.FrequencyBasedEMedicationDosageDto) {
        const frequencyBasedDosage = new FrequencyBasedEMedicationDosage();
        frequencyBasedDosage.amount = dosage.amount;
        frequencyBasedDosage.frequency = dosage.frequency;
        frequencyBasedDosage.unit = dosage.unit;
        return frequencyBasedDosage;
    }

    throw new Error("Unknown type of eMedication dosage.");
}

export function mapMedicationReferenceBase(dto: Proxy.MedicationReferenceBaseDto) {
    if (!dto) {
        return new SingleMedicationReference();
    } else {
        let medicationReferenceBase: MedicationReferenceBase = null;
        if (dto instanceof Proxy.SingleMedicationReferenceDto) {
            const singleMedicationReference = new SingleMedicationReference();

            singleMedicationReference.medicationVersionSelector = { id: dto.medicationVersionSelector.entityId, validOn: dto.medicationVersionSelector.validOn };
            singleMedicationReference.amount = dto.amount;
            singleMedicationReference.daysSupplied = dto.daysSupplied;
            singleMedicationReference.isDaysSuppliedFilledManually = dto.isDaysSuppliedFilledManually;
            singleMedicationReference.dosage = mapDosage(dto.dosage);
            singleMedicationReference.isOverdose = dto.isOverdose;
            singleMedicationReference.isUnpackaged = dto.isUnpackaged;
            singleMedicationReference.packagingUnitId = dto.packagingUnitId;

            medicationReferenceBase = singleMedicationReference;
        } else if (dto instanceof Proxy.MedicationEquipmentListDto) {
            const medicationEquipmentList = new MedicationEquipmentList();
            medicationEquipmentList.countersignMandatory = dto.countersignMandatory;
            medicationEquipmentList.countersignApprovalNumber = dto.countersignApprovalNumber;
            medicationEquipmentList.countersignValidFrom = dto.countersignValidFrom;
            
            medicationEquipmentList.stateChangeDescription = dto.stateChangeDescription;
            medicationEquipmentList.sizeChangeDescription = dto.sizeChangeDescription;
            medicationEquipmentList.patientStatementAvailable = dto.patientStatementAvailable;
            medicationEquipmentList.represcribeWithinSupportPeriod = dto.represcribeWithinSupportPeriod;
            
            medicationEquipmentList.prescribeToIsoGroup = dto.prescribeToIsoGroup;
            medicationEquipmentList.type = dto.type;
            medicationEquipmentList.items = State.observable(dto.items.map(i => {
                const item = new MedicationEquipmentListItem();
                item.amount = !!i.amount && i.amount !== "" ? Number(i.amount) : null;
                item.equipmentPositionId = i.equipmentPositionId;
                item.size = i.size;
                item.medicationVersionSelector = { id: i.medicationVersionSelector.entityId, validOn: i.medicationVersionSelector.validOn };
                item.amountReason = i.amountReason;
                item.bodyPart = i.bodyPart;
                item.instruction = i.instruction;
                item.instructionForDispenser = i.instructionForDispenser;
                item.isInsubstitutable = i.isInsubstitutable;
                item.insubstitutableReason = i.insubstitutableReason;
                item.faultDescription = i.faultDescription;
                item.serialNumber = i.serialNumber;
                item.supportPeriodMonths = i.supportPeriodMonths;
                item.dosage = mapEMedicationDosage(i.dosage);
                return item;
            }));

            medicationReferenceBase = medicationEquipmentList;
        } else if (dto instanceof Proxy.TextualPrescriptionDto) {
            const textualPrescription = new TextualPrescription();
            textualPrescription.amount = dto.amount;
            textualPrescription.content = dto.content;
            textualPrescription.preparation = dto.preparation;
            textualPrescription.displayName = dto.displayName;
            textualPrescription.dosage = mapDosage(dto.dosage);
            textualPrescription.name = dto.name;
            textualPrescription.textualPrescriptionTemplateId = dto.textualPrescriptionTemplateId;
            textualPrescription.type = dto.type;
            textualPrescription.packagingUnitId = dto.packagingUnitId;
            medicationReferenceBase = textualPrescription;
        } else if (dto instanceof Proxy.MedicationFormulaListDto) {
            const medicationFormulaList = new MedicationFormulaList();
            medicationFormulaList.name = dto.name;
            medicationFormulaList.amount = dto.amount;
            medicationFormulaList.dosage = mapDosage(dto.dosage);
            medicationFormulaList.packagingUnitId = dto.packagingUnitId;
            medicationFormulaList.instruction = dto.instruction;
            medicationFormulaList.medicationFormulaListItems = State.observable(dto.items.map(i => {
                const item = new MedicationFormulaListItem();
                item.amount = i.amount;
                item.unit = i.unit;
                item.medicationVersionSelector = new EntityVersionSelector<MedicationId>(i.medicationVersionSelector.entityId, i.medicationVersionSelector.validOn);
                return item;
            }));

            medicationReferenceBase = medicationFormulaList;
        } else {
            throw new Error("MedicationReferenceBase descendant not recognized.");
        }

        return medicationReferenceBase;
    }
}

export function mapEhiMedicationBase(dto: Proxy.EhiMedicationBaseDto): IEhiMedicationBase {
    if (!dto) {
        return {} as IEhiSingleMedication;
    }

    let ehiMedicationBase: IEhiMedicationBase = null;
    if (dto instanceof Proxy.EhiSingleMedicationDto) {
        ehiMedicationBase = {
            amount: dto.amount,
            daysSupplied: dto.daysSupplied,
            dosageDto: mapDosage(dto.dosageDto),
            efficacy: dto.efficacy,
            ehiDosageTypeId: dto.ehiDosageTypeId,
            ehiMedicationSubsidyClaimTypeId: dto.ehiMedicationSubsidyClaimTypeId,
            ehiMedicationTypeId: dto.ehiMedicationTypeId,
            instruction: dto.instruction,
            isOverdose: dto.isOverdose,
            isSubstitutable: dto.isSubstitutable,
            medicationName: dto.medicationName,
            substanceCode: dto.substanceCode,
            substanceName: dto.substanceName,
            textAmount: dto.textAmount,
            unsubstitutableReason: dto.unsubstitutableReason,
            notes: dto.notes,
            productId: dto.productId
                    
        } as IEhiSingleMedication;
    } else if (dto instanceof Proxy.EhiMedicationFormulaDto) {
        ehiMedicationBase = {
            textAmount: dto.textAmount,
            substanceCode: dto.substanceCode,
            substanceName: dto.substanceName,
            medicationName: dto.medicationName,
            isSubstitutable: dto.isSubstitutable,
            isOverdose: dto.isOverdose,
            dosageDto: mapDosage(dto.dosageDto),
            instruction: dto.instruction,
            ehiMedicationSubsidyClaimTypeId: dto.ehiMedicationSubsidyClaimTypeId,
            ehiMedicationTypeId: dto.ehiMedicationTypeId,
            ehiDosageTypeId: dto.ehiDosageTypeId,
            efficacy: dto.efficacy,
            daysSupplied: dto.daysSupplied,
            amount: dto.amount,
            content: dto.content,
            formulaName: dto.formulaName,
            practitionerInstruction: dto.practitionerInstruction,
            preparation: dto.preparation,
            unsubstitutableReason: dto.unsubstitutableReason,
            notes: dto.notes,
            productId: dto.productId
        } as IEhiMedicationFormula;
    } else if (dto instanceof Proxy.EhiMedicationEquipmentDto) {
        ehiMedicationBase = {
            amount: dto.amount,
            amountReason: dto.amountReason,
            bodyArea: dto.applicationArea?.bodyArea,
            bodySide: dto.applicationArea?.side,
            daysSupplied: dto.daysSupplied,
            dispenserInstruction: dto.dispenserInstruction,
            dosage: mapEMedicationDosage(dto.dosage),
            efficacy: dto.efficacy,
            ehiDosageTypeId: dto.ehiDosageTypeId,
            ehiMedicationSubsidyClaimTypeId: dto.ehiMedicationSubsidyClaimTypeId,
            ehiMedicationTypeId: dto.ehiMedicationTypeId,
            faultDescription: dto.faultDescription,
            instruction: dto.instruction,
            isCustomMade: dto.isCustomMade,
            isoIdentifier: dto.isoIdentifier,
            isOverdose: dto.isOverdose,
            isSubstitutable: dto.isSubstitutable,
            lifespan: dto.lifespan,
            medicationName: dto.medicationName,
            size: dto.size,
            substanceCode: dto.substanceCode,
            substanceName: dto.substanceName,
            textAmount: dto.textAmount,
            practitionerInstruction: dto.practitionerInstruction,
            tttIdentifier: dto.tttIdentifier,
            unsubstitutableReason: dto.unsubstitutableReason,
            notes: dto.notes,
            productId: dto.productId
        } as IEhiMedicationEquipment;
    } else {
        throw new Error("EhiMedicationBase descendant not recognized.");
    }

    return ehiMedicationBase;
}
export function mapFamilyDoctorDocumentMedicationReference(dto: Proxy.FamilyDoctorDocumentMedicationReferenceDto) {
    if (!dto) {
        return new FamilyDoctorDocumentMedicationReference();
    } else {
        const familyDoctorDocumentMedicationReferenceBase: FamilyDoctorDocumentMedicationReference = new FamilyDoctorDocumentMedicationReference();
        if (dto instanceof Proxy.FamilyDoctorDocumentSingleMedicationReferenceDto) {
            const familyDoctorDocumentSingleMedication = new FamilyDoctorDocumentSingleMedication();

            familyDoctorDocumentSingleMedication.medicationVersionSelector = new EntityVersionSelector<MedicationId>(dto.medicationVersionSelector.entityId, dto.medicationVersionSelector.validOn);
            familyDoctorDocumentMedicationReferenceBase.conditionId = dto.conditionId;
            familyDoctorDocumentMedicationReferenceBase.dailyDosage = dto.dailyDosage;
            familyDoctorDocumentMedicationReferenceBase.isMagistral = false;
            familyDoctorDocumentMedicationReferenceBase.medicationDosageForm = dto.medicationDosageForm;
            familyDoctorDocumentSingleMedication.medicationSubsidyClaimTypeId = dto.medicationSubsidyClaimTypeId;
            familyDoctorDocumentMedicationReferenceBase.notes = dto.notes;
            familyDoctorDocumentSingleMedication.substanceDailyAmount = dto.substanceDailyAmount;
            familyDoctorDocumentSingleMedication.substanceName = dto.substanceName;
            familyDoctorDocumentSingleMedication.practitionerId = dto.practitionerId;
            familyDoctorDocumentSingleMedication.medicationSubsidyId = dto.medicationSubsidyId;
            familyDoctorDocumentSingleMedication.subsidyOptionId = dto.subsidyOptionId;

            familyDoctorDocumentMedicationReferenceBase.detail = familyDoctorDocumentSingleMedication;
        } else if (dto instanceof Proxy.FamilyDoctorDocumentMedicationFormulaListDto) {
            const familyDoctorDocumentMedicationFormula = new FamilyDoctorDocumentMedicationFormula();
            familyDoctorDocumentMedicationReferenceBase.conditionId = dto?.conditionId;
            familyDoctorDocumentMedicationReferenceBase.dailyDosage = dto.dailyDosage;
            familyDoctorDocumentMedicationReferenceBase.medicationDosageForm = dto.medicationDosageForm;
            familyDoctorDocumentMedicationReferenceBase.notes = dto.notes;
            familyDoctorDocumentMedicationReferenceBase.isMagistral = true;
            familyDoctorDocumentMedicationFormula.items = State.observable(dto.items.map(i => {
                const item = new FamilyDoctorDocumentMedicationFormulaItem();
                item.medicationVersionSelector = new EntityVersionSelector<MedicationId>(i.medicationVersionSelector.entityId, i.medicationVersionSelector.validOn);
                item.substanceAmount = i.substanceAmount;
                return item;
            }));

            familyDoctorDocumentMedicationReferenceBase.detail = familyDoctorDocumentMedicationFormula;
        } else {
            throw new Error("FamilyDoctorDocumentMedicationReferenceBase descendant not recognized.");
        }

        return familyDoctorDocumentMedicationReferenceBase;
    }
}
export function mapCurativeItem(dto: Proxy.CurativeItemDto) {
    if (!dto) {
        return new CurativeItem();
    } else {
        const curativeItem: CurativeItem = new CurativeItem();
        if (dto instanceof Proxy.SpaServiceDto) {
            const spaService = new SpaService();

            curativeItem.conditionId = dto.conditionId;
            curativeItem.monthlyAmount = dto.monthlyAmount;
            curativeItem.practitionerId = dto.practitionerId;
            curativeItem.isMedicationEquipment = false;
            spaService.serviceCode = dto.serviceCode;
            spaService.serviceName = dto.serviceName;

            curativeItem.detail = spaService;
        } else if (dto instanceof Proxy.FamilyDoctorDocumentEquipmentReferenceDto) {
            const familyDoctorDocumentEquipmentReference = new FamilyDoctorDocumentEquipmentReference();

            curativeItem.conditionId = dto.conditionId;
            curativeItem.monthlyAmount = dto.monthlyAmount;
            curativeItem.practitionerId = dto.practitionerId;
            curativeItem.isMedicationEquipment = true;
            familyDoctorDocumentEquipmentReference.medicationVersionSelector = new EntityVersionSelector<MedicationId>(dto.medicationVersionSelector.entityId, dto.medicationVersionSelector.validOn);

            curativeItem.detail = familyDoctorDocumentEquipmentReference;
        } else {
            throw new Error("CurativeItem descendant not recognized.");
        }

        return curativeItem;
    }
}