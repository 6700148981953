import React, { useCallback } from "react";
import EditableDataElementEditorStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableDataElementEditorStore";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import { nullFunction } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import EditableLayoutColumnStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableLayoutColumnStore";
import Styles from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditor.less";
import { convertLanguageToCultureCode } from "@Primitives/Language";

interface IFormLayoutDataElementEditorProps {
    columnParentCollection: IObservableArray<EditableLayoutColumnStore>;
    column: EditableLayoutColumnStore;
    editor: EditableDataElementEditorStore;
}

function FormLayoutDataElementEditor(props: IFormLayoutDataElementEditorProps) {

    const store = useFormLayoutEditorStore();
    const editorComponent = store.formEditorRegistry.tryGetPlaceholderEditorComponent(props.editor.editorType);
    const dataElement = store.dataElements.find(de => de.name === props.editor.dataReference);

    const select = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (dataElement) {
            props.editor.setDefaultValue(dataElement.defaultValue);
        }
        store.selectEditor(props.columnParentCollection, props.column, props.editor);

        event.stopPropagation();
    }, [props.columnParentCollection, props.column, props.editor, dataElement]);

    if (!editorComponent) {
        return <><b>{props.editor.multiLingualLabel.getLocalizedLabel(convertLanguageToCultureCode(props.editor.currentLabelLanguage))}:</b> Missing editor type: {props.editor.editorType}</>;
    }

    return (
        <div onClick={select} className={Styles.elementEditor} >
            {
                React.createElement(editorComponent.componentType, {
                    ...editorComponent.props,
                    style: { userSelect: "none", pointerEvents: "none" } as React.CSSProperties,
                    label: props.editor.multiLingualLabel.getLocalizedLabel(convertLanguageToCultureCode(props.editor.currentLabelLanguage)),
                    value: null,
                    onChange: nullFunction,
                    automationId: props.editor.dataReference,
                    propertyIdentifier: props.editor.dataReference,
                    dataElement
                })
            }
        </div>
    );
}

export default State.observer(FormLayoutDataElementEditor);