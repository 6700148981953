import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import EhiHealthcareProviderId from "@Primitives/EhiHealthcareProviderId.g";
import EhiHealthcareProviderOrganizationUnitId from "@Primitives/EhiHealthcareProviderOrganizationUnitId.g";
import EhiEReferralServiceTypeId from "@Primitives/EhiEReferralServiceTypeId.g";
import EhiEReferralReasonId from "@Primitives/EhiEReferralReasonId.g";
import EhiEReferralTravelExpenseTypeId from "@Primitives/EhiEReferralTravelExpenseTypeId.g";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiEReferralCovidData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralCovidData";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

export default class EhiEReferralData extends EntityStoreBase<ServiceRequestId> implements IDirtyChecked {
    @State.observable.ref public ehiHealthcareProviderId: EhiHealthcareProviderId;
    @State.observable.ref public ehiHealthcareProviderOrganizationUnitId: EhiHealthcareProviderOrganizationUnitId;
    @State.observable.ref public ehiEReferralServiceTypeId: EhiEReferralServiceTypeId;
    @State.observable.ref public ehiEReferralReasonId: EhiEReferralReasonId;
    @State.observable.ref public ehiEReferralTravelExpenseTypeId: EhiEReferralTravelExpenseTypeId;
    @State.observable public anamnesis: string;
    @State.observable public isInAreaCare: boolean;
    @State.observable public doctorNotification: boolean;
    @State.observable public patientNotification: boolean;
    @State.observable public sickAllowanceIdentifier: string;
    @State.observable public travelVoucherIdentifier: string;
    @State.observable public concilium: string;
    @State.observable public isOutOfOrder: boolean;
    @State.observable public sicknessInDaysSinceStart: number;
    @State.observable public referralMedicalServiceId: IEntityVersionSelector<MedicalServiceId>;
    @State.observable public covidData: EhiEReferralCovidData;
    private _dirtyChecker = new StoreDirtyChecker();

    constructor() {
        super();
        this.covidData = new EhiEReferralCovidData();
    }
    @State.action.bound
    public setEhiHealthcareProviderId(newValue: EhiHealthcareProviderId) {
        this.ehiHealthcareProviderId = newValue;
    }

    @State.action.bound
    public setEhiHealthcareProviderOrganizationUnitId(newValue: EhiHealthcareProviderOrganizationUnitId) {
        this.ehiHealthcareProviderOrganizationUnitId = newValue;
    }

    @State.action.bound
    public setEhiEReferralServiceTypeId(newValue: EhiEReferralServiceTypeId) {
        this.ehiEReferralServiceTypeId = newValue;
    }

    @State.action.bound
    public setEhiEReferralReasonId(newValue: EhiEReferralReasonId) {
        this.ehiEReferralReasonId = newValue;
    }

    @State.action.bound
    public setEhiEReferralTravelExpenseTypeId(newValue: EhiEReferralTravelExpenseTypeId) {
        this.ehiEReferralTravelExpenseTypeId = newValue;
    }

    @State.action.bound
    public setAnamnesis(newValue: string) {
        this.anamnesis = newValue;
    }

    @State.action.bound
    public setIsInAreaCare(newValue: boolean) {
        this.isInAreaCare = newValue;
    }

    @State.action.bound
    public setDoctorNotification(newValue: boolean) {
        this.doctorNotification = newValue;
    }

    @State.action.bound
    public setPatientNotification(newValue: boolean) {
        this.patientNotification = newValue;
    }

    @State.action.bound
    public setSickAllowanceIdentifier(newValue: string) {
        this.sickAllowanceIdentifier = newValue;
    }

    @State.action.bound
    public setTravelVoucherIdentifier(newValue: string) {
        this.travelVoucherIdentifier = newValue;
    }

    @State.action.bound
    public setConcilium(newValue: string) {
        this.concilium = newValue;
    }

    @State.action.bound
    public setIsOutOfOrder(newValue: boolean) {
        this.isOutOfOrder = newValue;
    }

    @State.action.bound
    public setSicknessInDaysSinceStart(newValue: number) {
        this.sicknessInDaysSinceStart = newValue;
    }

    @State.action.bound
    public setReferralMedicalServiceIdiceId(newValue: IEntityVersionSelector<MedicalServiceId>) {
        this.referralMedicalServiceId = newValue;
    }

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "operationInfo"
    ];

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }

    public static createFromExtensionDto(source: any, hasCovidData: boolean) {
        let result: EhiEReferralData;
        if (!isNullOrUndefined(source)) {
            const serviceRequestExtension = source["HunEHealthInfrastructure_EhiEReferralData"];

            if (!isNullOrUndefined(serviceRequestExtension)) {
                result = new EhiEReferralData();
                result.id = !isNullOrUndefined(serviceRequestExtension.Id)
                    ? new ServiceRequestId(serviceRequestExtension.Id.Value.toString())
                    : null;
                result.ehiHealthcareProviderId = !isNullOrUndefined(serviceRequestExtension.EhiHealthcareProviderId)
                    ? new EhiHealthcareProviderId(serviceRequestExtension.EhiHealthcareProviderId.Value.toString())
                    : null;
                result.ehiHealthcareProviderOrganizationUnitId = !isNullOrUndefined(serviceRequestExtension.EhiHealthcareProviderOrganizationUnitId)
                    ? new EhiHealthcareProviderOrganizationUnitId(serviceRequestExtension.EhiHealthcareProviderOrganizationUnitId.Value.toString())
                    : null;
                result.ehiEReferralServiceTypeId = !isNullOrUndefined(serviceRequestExtension.EhiEReferralServiceTypeId)
                    ? new EhiEReferralServiceTypeId(serviceRequestExtension.EhiEReferralServiceTypeId.Value.toString())
                    : null;
                result.ehiEReferralReasonId = !isNullOrUndefined(serviceRequestExtension.EhiEReferralReasonId)
                    ? new EhiEReferralReasonId(serviceRequestExtension.EhiEReferralReasonId.Value.toString())
                    : null;
                result.ehiEReferralTravelExpenseTypeId = !isNullOrUndefined(serviceRequestExtension.EhiEReferralTravelExpenseTypeId)
                    ? new EhiEReferralTravelExpenseTypeId(serviceRequestExtension.EhiEReferralTravelExpenseTypeId.Value.toString())
                    : null;
                result.anamnesis = !isNullOrUndefined(serviceRequestExtension.Anamnesis)
                    ? serviceRequestExtension.Anamnesis
                    : null;
                result.isInAreaCare = !isNullOrUndefined(serviceRequestExtension.IsInAreaCare)
                    ? serviceRequestExtension.IsInAreaCare
                    : null;
                result.doctorNotification = !isNullOrUndefined(serviceRequestExtension.DoctorNotification)
                    ? serviceRequestExtension.DoctorNotification
                    : null;
                result.patientNotification = !isNullOrUndefined(serviceRequestExtension.PatientNotification)
                    ? serviceRequestExtension.PatientNotification
                    : null;
                result.sickAllowanceIdentifier = !isNullOrUndefined(serviceRequestExtension.SickAllowanceIdentifier)
                    ? serviceRequestExtension.SickAllowanceIdentifier
                    : null;
                result.travelVoucherIdentifier = !isNullOrUndefined(serviceRequestExtension.TravelVoucherIdentifier)
                    ? serviceRequestExtension.TravelVoucherIdentifier
                    : null;
                result.concilium = !isNullOrUndefined(serviceRequestExtension.Concilium)
                    ? serviceRequestExtension.Concilium
                    : null;
                result.isOutOfOrder = !isNullOrUndefined(serviceRequestExtension.IsOutOfOrder)
                    ? serviceRequestExtension.IsOutOfOrder
                    : null;
                result.sicknessInDaysSinceStart = !isNullOrUndefined(serviceRequestExtension.SicknessInDaysSinceStart)
                    ? serviceRequestExtension.SicknessInDaysSinceStart
                    : null;
                result.referralMedicalServiceId = !isNullOrUndefined(serviceRequestExtension.ReferralMedicalServiceId)
                    ? {
                        id: new MedicalServiceId(serviceRequestExtension.ReferralMedicalServiceId.Value.toString()),
                        validOn: LocalDate.today()
                    } as IEntityVersionSelector<MedicalServiceId>
                    : null;
                result.covidData = hasCovidData
                    ? EhiEReferralCovidData.createFromExtensionDto(serviceRequestExtension.CovidData)
                    : null;
            } else {
                result = new EhiEReferralData();
            }
        } else {
            result = new EhiEReferralData();
        }
        return result;
    }
}
