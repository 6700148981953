import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IAutoLogoutDialogParams, IAutoLogoutDialogResult } from "./AutoLogoutDialogParams";
import React from "react";
import Modal from "@CommonControls/Modal";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import { Button } from "@CommonControls";

interface IAutoLogoutDialogProps extends IModalComponentParams<IAutoLogoutDialogResult>, IAutoLogoutDialogParams {
    // this is usually empty, or contains only a _dependencies prop
}

export default class AutoLogoutDialog extends React.Component<IAutoLogoutDialogProps> {

    private logoutTimeout: any = null;

    public componentDidMount() {
        this.logoutTimeout = window.setTimeout(this.userIsInactive, this.props.logoutTimeoutInMs);
    }

    public componentWillUnmount() {
        this.clearLogoutTimeout();
    }

    private clearLogoutTimeout() {
        if (this.logoutTimeout !== null) {
            window.clearTimeout(this.logoutTimeout);
            this.logoutTimeout = null;
        }
    }

    public render() {
        return (
            <Modal 
            isOpen 
            onClose={this.userIsInactive} 
            closeOnEscape={false} 
            closeOnOutsideClick={false} 
            size="tiny" 
            title={StaticUserManagementResources.Common.Dialog.UserSessionWillBeExpiredSoon.Title}
            iconName="lock"
            >
                <Modal.Body>
                    {StaticUserManagementResources.Common.Dialog.UserSessionWillBeExpiredSoon.Message}
                </Modal.Body>
                <Modal.Footer right={(
                    <Button
                        automationId="continueWork_Button"
                        text={StaticUserManagementResources.Common.Dialog.UserSessionWillBeExpiredSoon.ContinueWorkButtonLabel}
                        onClick={this.userIsActive}
                        visualStyle="primary"
                    />
                )} />
            </Modal>
        );
    }

    @State.bound
    private userIsActive() {
        this.clearLogoutTimeout();
        this.props.onClose({ doAutoLogout: false });
    }

    @State.bound
    private userIsInactive() {
        this.props.onClose({ doAutoLogout: true });
    }
}