exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ExtendedPerformanceStatementCareActivityFilter_search-container_2KdDa {\n  background: #f4f7fc;\n  border: solid 1px #00b4ec;\n  padding: 5px 4px 8px;\n  margin: 8px 8px 0px;\n}\n.ExtendedPerformanceStatementCareActivityFilter_search-item_1gKfU {\n  padding: 0px 4px 4px;\n}\n.ExtendedPerformanceStatementCareActivityFilter_outer-container_Cd30K {\n  margin-top: 10px;\n}\n.ExtendedPerformanceStatementCareActivityFilter_outer-container_Cd30K label {\n  margin: 0px 4px;\n  font-family: PragatiNarrow;\n  font-size: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"search-container": "ExtendedPerformanceStatementCareActivityFilter_search-container_2KdDa",
	"searchContainer": "ExtendedPerformanceStatementCareActivityFilter_search-container_2KdDa",
	"search-item": "ExtendedPerformanceStatementCareActivityFilter_search-item_1gKfU",
	"searchItem": "ExtendedPerformanceStatementCareActivityFilter_search-item_1gKfU",
	"outer-container": "ExtendedPerformanceStatementCareActivityFilter_outer-container_Cd30K",
	"outerContainer": "ExtendedPerformanceStatementCareActivityFilter_outer-container_Cd30K"
};