import "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import CoverageEligibilityCheckResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CheckCoverageEligibility/CoverageEligibilityCheckResult";
import CoverageEligibilityCheckAvailability from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CheckCoverageEligibility/CoverageEligibilityCheckAvailability";
import moment from "moment";

export default class CoverageCheckResultNotification {
    
    public coverageEligibilityCheckResult: CoverageEligibilityCheckResult;
    public coverageEligibilityCheckAvailability: CoverageEligibilityCheckAvailability;
    public checkedAt: moment.Moment;
    public patientIdentifier: string;

    constructor(data: ICoverageCheckResultNotification) {
        this.coverageEligibilityCheckResult = data.coverageEligibilityCheckResult;
        this.coverageEligibilityCheckAvailability = data.coverageEligibilityCheckAvailability;
        this.checkedAt = data.checkedAt;
        this.patientIdentifier = data.patientIdentifier;
    }

    public static fromJS(data: any): CoverageCheckResultNotification {
        return new CoverageCheckResultNotification({
            checkedAt: data["CheckedAt"] ? moment(data["CheckedAt"].toString()) : null,
            patientIdentifier: data["PatientIdentifier"] !== undefined ? data["PatientIdentifier"] : null,
            coverageEligibilityCheckResult: data["CoverageEligibilityCheckResult"] !== undefined ? data["CoverageEligibilityCheckResult"] : null,
            coverageEligibilityCheckAvailability: data["CoverageEligibilityCheckAvailability"] !== undefined ? data["CoverageEligibilityCheckAvailability"] : null
        });
    }
}

interface ICoverageCheckResultNotification {
    coverageEligibilityCheckResult: CoverageEligibilityCheckResult;
    coverageEligibilityCheckAvailability: CoverageEligibilityCheckAvailability;
    checkedAt: moment.Moment;
    patientIdentifier: string;
}