import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";

export interface IMedicationRouteParams {
    useCase: string;
    selectedRowId?: string;
}

const medicationStandaloneRouteDefinitions = {
    medications: new RouteDefinition<IMedicationRouteParams>("/medications/:useCase/:selectedRowId?"),
};

export default medicationStandaloneRouteDefinitions;