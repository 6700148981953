
import StaticFormEditingResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import FormDefinitionMasterDetailPanelStore from "./FormDefinitionMasterDetailPanelStore";

const { 
    ContextComponent: FormDefinitionMasterDetailPanelStoreContext, 
    StoreProvider: FormDefinitionMasterDetailPanelStoreProvider, 
    useStore: useFormDefinitionMasterDetailPanelStore,
    provideStore: provideFormDefinitionMasterDetailPanelStore,
} = createPanelStoreProvider(FormDefinitionMasterDetailPanelStore, () => ({
    unauthorizedPageTitle: StaticFormEditingResources.FormDefinitionList.Title
}));

export {
    FormDefinitionMasterDetailPanelStoreContext,
    FormDefinitionMasterDetailPanelStoreProvider,
    useFormDefinitionMasterDetailPanelStore,
    provideFormDefinitionMasterDetailPanelStore
};