import EntityCollectionId from "@Primitives/EntityCollectionId.g";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";

export type ClientEntityCollectionType = "List" | "Group" | "MostUsed";

export default class EntityCollectionStore {
    constructor(
        public readonly id: EntityCollectionId,
        public readonly idList: IStringEntityId[],
        public readonly type: ClientEntityCollectionType,
        public readonly code: string,
        public readonly name: string
    ) {
    }
}
