import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as HisUi from "@HisPlatformControls";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import ICategory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/ICategory";
import ObservationDefinitionApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ObservationDefinitionApiAdapter";
import { CategoryObservationCodeSelectorItem } from "./CategoryObservationCodeSelectorItem";

interface ICategoryObservationCodeSelectorDependencies {
    observationDefinitionApiAdapter: ObservationDefinitionApiAdapter;
}

interface ICategoryObservationCodeSelectorProps extends ICodeSelectorCommonProps<CategoryObservationCodeSelectorItem> {
    _dependencies?: ICategoryObservationCodeSelectorDependencies;
    title: string;
    categoryObservationDefinitionCode: string;
}

@State.observer
class CategoryObservationCodeSelector extends React.Component<ICategoryObservationCodeSelectorProps> {

    private get dependencies() { return this.props._dependencies; }
    private get apiAdapter() { return this.dependencies.observationDefinitionApiAdapter; }

    @State.bound
    public getDisplayValueAsync(value: CategoryObservationCodeSelectorItem) {
        return Promise.resolve(value.name);
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const results = await this.apiAdapter.searchCategories(
            this.props.categoryObservationDefinitionCode,
            text,
            10
        );

        if (results.operationWasSuccessful) {
            const categories = results.value.map(x => {
                const textOfQuickSearch = `${x.name} (${x.code})`;
                if (x.code) {
                    return new CategoryObservationCodeSelectorItem({
                        code: x.code,
                        name: textOfQuickSearch
                    });
                }
                return null;
            });

            return categories;
        }
        return null;
    }

    @State.bound
    private addNewItem(text: string): CategoryObservationCodeSelectorItem {
        return new CategoryObservationCodeSelectorItem({ name: text });
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState) {
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;

        const results = await this.apiAdapter.complexSearchCategories(
            filterText,
            customOrdering,
            paging,
            this.props.categoryObservationDefinitionCode);

        return results.value;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: CategoryObservationCodeSelectorItem) {
        this.props.onChange({ code: item.code, name: item.name });
    }

    @State.bound
    private onComplexSearchMultiSelect(items: ICategory[]) {
        this.props.onChange(items.map(item => ({ code: item.code, name: item.name })));
    }
    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: this.props.title,
            codeGetter: "code",
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect,
            hideAllgroup: true
        };
        return (
            <HisUi.UniversalCodeSelector
                {...props}
                addNewItem={this.addNewItem}
            />
        );
    }
}

export default connect(
    CategoryObservationCodeSelector,
    new DependencyAdapter<ICategoryObservationCodeSelectorProps, ICategoryObservationCodeSelectorDependencies>((container) => {
        return {
            observationDefinitionApiAdapter: container.resolve("ObservationDefinitionApiAdapter")
        };
    })
);
