import StoreMapper from "@Toolkit/CommonWeb/ApiAdapter/StoreMapper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import * as ConfigurationProxy from "@HisPlatform/BoundedContexts/Configuration/Api/Proxy.g";
import PropertyGroup from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/PropertyGroup";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import convertPropertyValue from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/ApiAdapter/DynamicProperties/PropertyValueHelper";
import Di from "@Di";

type ResponseType = ConfigurationProxy.GetAllPropertyGroupsQueryResponse | ConfigurationProxy.UpdatePropertyGroupsCommandResponse;

@Di.injectable()
export default class ConfigurationPropertyGroupStoreMapper extends StoreMapper<SimpleStore<PropertyGroup[]>, ResponseType> {

    protected applyToStoreCore(target: SimpleStore<PropertyGroup[]>, response: ResponseType) {
        target.value = response.propertyGroups.map(pg => {
            const propertyGroup = new PropertyGroup();

            propertyGroup.definitionId = pg.propertyGroupDefinitionId;
            propertyGroup.name = pg.name;
            propertyGroup.properties = pg.properties
                .map(prop => convertPropertyValue(prop))
                .filter(Boolean);

            return propertyGroup;
        });

        target.operationInfo = createOperationInfo(response);
    }
}