import React from "react";
import RouteDefinition from "./RouteDefinition";
import RoutingFrameStore from "./RoutingFrameStore";
import IHostRoutingAdapter from "./Abstractions/IHostRoutingAdapter";
import { IRoutingController } from "./Abstractions/IRoutingController";
import GlobalRoutingStore from "./Abstractions/GlobalRoutingStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import Route from "@Toolkit/ReactClient/Routing/Abstractions/Route";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import RoutingFrameContext from "@Toolkit/ReactClient/Routing/RoutingFrameContext";

interface IRoutingFrameDependencies {
    currentRouteProviderStore: GlobalRoutingStore;
    hostRoutingAdapter: IHostRoutingAdapter;
}

interface IRoutingFrameProps {
    _dependencies?: IRoutingFrameDependencies;
    routeDefinitions: { [id: string]: RouteDefinition };
    fallbackRoute?: Route | RouteDefinition;
    parentRouteDefinition?: RouteDefinition;
    onRoutingControllerChanged?: (c: IRoutingController) => void;
    onRouteChanging?: (route: Route) => boolean;
}

interface IRoutingFrameCaseProps {
    route: RouteDefinition;
    isDisabled?: boolean;
    children?: (routingController: IRoutingController) => React.ReactNode;
    component?: React.ComponentType<{ routingController?: IRoutingController }>;
}

@State.observer
class RoutingFrameCore extends React.Component<IRoutingFrameProps> {

    private store: RoutingFrameStore = new RoutingFrameStore(
        this.props._dependencies.hostRoutingAdapter,
        () => this.props.routeDefinitions,
        this.props._dependencies.currentRouteProviderStore,
        this.props.onRoutingControllerChanged,
        this.props.onRouteChanging,
        this.props.fallbackRoute,
        this.props.parentRouteDefinition
    );

    public componentDidMount() {
        this.store.initialize();
    }

    public componentWillUnmount() {
        this.store.dispose();
        this.store = null;
    }

    private renderCore() {

        if (!this.store || !this.store.currentRoute || !this.props.children) {
            return null;
        }

        const children = React.Children.toArray(this.props.children);

        const item = children.find(
            (i: React.ReactElement<IRoutingFrameCaseProps>) => i.props?.route?.isHierarchyMatch(this.store.currentRoute.definition)
        ) as React.ReactElement<IRoutingFrameCaseProps>;

        if (item) {

            if (item.props.isDisabled === true && !!this.props.fallbackRoute) {
                this.store.currentController.replace(this.props.fallbackRoute instanceof Route ? this.props.fallbackRoute : this.props.fallbackRoute.makeRoute());
                return null;
            }

            if (item.props.children) {
                return item.props.children(this.store.currentController);
            }

            if (item.props.component) {
                
                const Component = item.props.component;
                return <Component routingController={this.store.currentController} />;
            }
        } else {
            if (!!this.props.fallbackRoute) {
                this.store.currentController.replace(this.props.fallbackRoute instanceof Route ? this.props.fallbackRoute : this.props.fallbackRoute.makeRoute({
                    redirectTo: this.props._dependencies!.hostRoutingAdapter.currentPathname
                }));
            }
        }

        return null;
    }

    public render() {
        return (
            <RoutingFrameContext.Provider value={this.store.currentController}>
                {this.renderCore()}
            </RoutingFrameContext.Provider>
        );
    }
}


const InjectedRoutingFrame = connect(
    RoutingFrameCore,
    new DependencyAdapter<IRoutingFrameProps, IRoutingFrameDependencies>(container => {
        return {
            currentRouteProviderStore: container.resolve<GlobalRoutingStore>("GlobalRoutingStore"),
            hostRoutingAdapter: container.resolve<IHostRoutingAdapter>("IHostRoutingAdapter")
        };
    })
);


export default class RoutingFrame extends React.PureComponent<IRoutingFrameProps> {

    
    
    public static Case = class extends React.PureComponent<IRoutingFrameCaseProps> { };
    

    public render() {
        return <InjectedRoutingFrame {...this.props} />;
    }
}
