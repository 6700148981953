exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Message_message_cKnH2 {\n  position: relative;\n  padding: 8px;\n  margin-bottom: 1rem;\n  margin-top: 1rem;\n  border: 1px solid transparent;\n  border-radius: 0.25rem;\n}\n.Message_message-primary_2GCFX {\n  color: #006d91;\n  background-color: #cef3ff;\n  border-color: #baeeff;\n}\n.Message_message-secondary_3BonE {\n  color: #393939;\n  background-color: #efefef;\n  border-color: #e0e0e0;\n}\n.Message_message-success_2KKBV {\n  color: #145647;\n  background-color: #d3ede7;\n  border-color: #c3e5dd;\n}\n.Message_message-danger_3q_Pa {\n  color: #721c36;\n  background-color: #f7d4de;\n  border-color: #f7c5d4;\n}\n.Message_message-warning_2s2Ql {\n  color: #875604;\n  background-color: #ffeccc;\n  border-color: #ffe3b2;\n}\n.Message_message-info_2kEdO {\n  color: #00B4EC;\n  background-color: rgba(130, 212, 237, 0.1);\n  border-color: #00B4EC;\n}\n.Message_message-light_3CdEg {\n  color: #818182;\n  background-color: #fefefe;\n  border-color: #fdfdfe;\n}\n.Message_message-dark_2YiPp {\n  color: #1b1e21;\n  background-color: #d6d8d9;\n  border-color: #c6c8ca;\n}\n.Message_message_cKnH2 a,\n.Message_message_cKnH2 a:visited {\n  font-family: PragatiNarrowBold;\n  text-decoration: none;\n}\n.Message_message_cKnH2 a:hover {\n  text-decoration: underline;\n}\n.Message_message-primary_2GCFX a {\n  color: #003d51;\n}\n.Message_message-secondary_3BonE a {\n  color: #252525;\n}\n.Message_message-success_2KKBV a {\n  color: #0b2d25;\n}\n.Message_message-danger_3q_Pa a {\n  color: #441020;\n}\n.Message_message-warning_2s2Ql a {\n  color: #593802;\n}\n.Message_message-info_2kEdO a {\n  color: #00B4EC;\n}\n.Message_message-light_3CdEg a {\n  color: #686868;\n}\n.Message_message-dark_2YiPp a {\n  color: #040505;\n}\n", ""]);

// exports
exports.locals = {
	"message": "Message_message_cKnH2",
	"message-primary": "Message_message-primary_2GCFX",
	"messagePrimary": "Message_message-primary_2GCFX",
	"message-secondary": "Message_message-secondary_3BonE",
	"messageSecondary": "Message_message-secondary_3BonE",
	"message-success": "Message_message-success_2KKBV",
	"messageSuccess": "Message_message-success_2KKBV",
	"message-danger": "Message_message-danger_3q_Pa",
	"messageDanger": "Message_message-danger_3q_Pa",
	"message-warning": "Message_message-warning_2s2Ql",
	"messageWarning": "Message_message-warning_2s2Ql",
	"message-info": "Message_message-info_2kEdO",
	"messageInfo": "Message_message-info_2kEdO",
	"message-light": "Message_message-light_3CdEg",
	"messageLight": "Message_message-light_3CdEg",
	"message-dark": "Message_message-dark_2YiPp",
	"messageDark": "Message_message-dark_2YiPp"
};