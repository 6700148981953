import React, { useCallback } from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import CareTypeSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/CareTypeSelectBox";
import CareTypeId from "@Primitives/CareTypeId.g";

interface ICareTypeColumnDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface ICareTypeColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: ICareTypeColumnDependencies;    
}


const CareTypeColumn: React.SFC<ICareTypeColumnProps> = (props: ICareTypeColumnProps) => {

    const valueRenderer = (value: any, row: any, rowId: React.ReactText, rowIndex: number, isUnderEditing: boolean, dataSetter: (newValue: any) => void) => {
        if (value) {
            if (Array.isArray(value)) {
                const careTypes = value?.map(i => props._dependencies.careReferenceDataStore.careType.get(i)?.displayValue?.Name);
                return careTypes.join(", ");
            }
            const careType = props._dependencies.careReferenceDataStore.careType.get(value)?.displayValue?.Name;
            return careType;
        }
        return "";
    };

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<CareTypeId>) => {
        return <CareTypeSelectBox {...filterProps} maxMenuWidth={commonMenuMaxWidth} hoverOnlyIndicatorIcons />;
    }, []);

    return (
        <DataGridColumn
            header={"CareType"}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            {...props}
            hideOverflow
        />
    );
};

export default connect(
    CareTypeColumn,
    new DependencyAdapter<ICareTypeColumnProps, ICareTypeColumnDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore")
    }))
);