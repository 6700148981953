import React from "react";
import * as Ui from "@CommonControls";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import TimePhaseInterval from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseInterval";

interface ISingleTimePhasePanelProps {
    date: LocalDate;
    onSetDate: (date: LocalDate) => void;
    interval: TimePhaseInterval;
    onSetTimePhaseInterval: (from: TimeOfDay, to: TimeOfDay, isFinal: boolean) => void;
}


const SingleTimePhasePanel: React.FC<ISingleTimePhasePanelProps> = props => {
    const resources = StaticSchedulingResources.TimePhaseEditorDialog.SingleTimePhasePanel;
    return (
        <ValidationBoundary
            pathPrefix="StandaloneOccurrence"
            validateOnMount
        >
            <Ui.Flex>
                <Ui.Flex.Item xs={6}>
                    <Ui.DatePicker
                        label={resources.Date}
                        value={props.date}
                        onChange={props.onSetDate}
                        propertyIdentifier="Interval"
                        automationId="singleOccuranceDatePicker"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={6}>
                    <Ui.TimeRangePicker
                        label={resources.Interval}
                        onTimePickerChange={props.onSetTimePhaseInterval}
                        valueFrom={props.interval.start}
                        valueTo={props.interval.end}
                        propertyIdentifier="Interval"
                        automationId="timePhaseIntervalPicker"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </ValidationBoundary>
    );
};

export default SingleTimePhasePanel;