exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OutpatientTreatmentPage_no-text-selection_yDMMf {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.OutpatientTreatmentPage_working-area_3U_V5 {\n  flex-basis: 100%;\n  display: flex;\n  flex-direction: column;\n  height: calc(100vh - 84px);\n  min-width: 0;\n  flex: 1;\n}\n.OutpatientTreatmentPage_top-toolbar_3-6La {\n  background-color: #0082aa;\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "OutpatientTreatmentPage_no-text-selection_yDMMf",
	"noTextSelection": "OutpatientTreatmentPage_no-text-selection_yDMMf",
	"working-area": "OutpatientTreatmentPage_working-area_3U_V5",
	"workingArea": "OutpatientTreatmentPage_working-area_3U_V5",
	"top-toolbar": "OutpatientTreatmentPage_top-toolbar_3-6La",
	"topToolbar": "OutpatientTreatmentPage_top-toolbar_3-6La"
};