import EhiErrorSeverity from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiErrorSeverity";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IEhiError from "./IEhiError";

export default class EhiErrors {
    public static createFromExtensionDto(source: any) {
        const result: IEhiError[] = [];

        if (!isNullOrUndefined(source)) {
            const ehiErrorsRaw = source["HunEHealthInfrastructure_EhiErrors"];

            if (!isNullOrUndefined(ehiErrorsRaw)) {
                for (const ehiError of ehiErrorsRaw) {
                    result.push({
                        code: ehiError.Code,
                        message: ehiError.Message,
                        moduleName: ehiError.ModuleName,
                        severity: ehiError.EhiErrorSeverity as unknown as EhiErrorSeverity
                    });
                }
            }
        }

        return result;
    }
}