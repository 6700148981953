import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";

interface IAppointmentCancellationSelectBoxDependencies {
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
}

interface IAppointmentCancellationSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IAppointmentCancellationSelectBoxDependencies;
}


@State.observer
class AppointmentCancellationSelectBox extends React.Component<IAppointmentCancellationSelectBoxProps> {
    private get dependencies() { return this.props._dependencies; }
    private get extensibleEnumStore() { return this.dependencies.schedulingReferenceDataStore.appointmentCancellationReasons; }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    AppointmentCancellationSelectBox,
    new DependencyAdapter<IAppointmentCancellationSelectBoxProps, IAppointmentCancellationSelectBoxDependencies>((c) => {
        return {
            schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore")
        };
    })
);
