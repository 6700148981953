import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import PractitionerId from "@Primitives/PractitionerId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IUserManagementExtensionPointProps from "@PluginInterface/BoundedContexts/UserManagement/ExtensionPoints/IUserManagementExtensionPointProps";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import * as Styles from "./ProfileManagementPanel.less";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import UserId from "@Primitives/UserId.g";

interface IProfileManagementPanelDependencies {
    practitionerApiAdapter: PractitionerApiAdapter;
}

interface IProfileManagementPanelProps {
    _dependencies?: IProfileManagementPanelDependencies;
    userId: UserId;
}

/** @screen */
@State.observer
class ProfileManagementPanel extends React.Component<IProfileManagementPanelProps> {

    @State.observable.ref private userPractitionerId: PractitionerId = null;

    @State.action.bound
    private setUserPractitionerId(newValue: PractitionerId) {
        this.userPractitionerId = newValue;
    }

    private getExtensionPointDiscriminator(props: IUserManagementExtensionPointProps) {
        return [props.userId, props.userPractitionerId];
    }

    @State.bound
    private async loadAsync() {
        const practitionerIdResponse = await this.props._dependencies.practitionerApiAdapter.tryGetPractitionerIdOfCurrentUserAsync();
        if (practitionerIdResponse.operationWasSuccessful) {
            if (practitionerIdResponse.value) {
                this.setUserPractitionerId(practitionerIdResponse.value);
            }
        }
    }

    @State.bound
    private navigateToDefault() {
        console.log("TODO: scroll to default");
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public render() {
        return (
            <div className={Styles.root}>
                <Ui.SideMenu collapsable>
                    <Ui.SideMenu.Item text="Default" onClick={this.navigateToDefault} iconName="patient" />
                </Ui.SideMenu>
                <div className={Styles.pagePanel}>
                    <Ui.ScrollView>
                        <Ui.PageBox title="Platform user page" fullHeight={false}>
                            <Ui.PageBox.Body>
                                <p>I'm a default user editor!</p>
                            </Ui.PageBox.Body>
                        </Ui.PageBox>
                    </Ui.ScrollView>
                </div>
            </div>
        );
    }
}

export default connect(
    ProfileManagementPanel,
    new DependencyAdapter<IProfileManagementPanelProps, IProfileManagementPanelDependencies>(container => {
        return {
            practitionerApiAdapter: container.resolve("PractitionerApiAdapter") // TODO: nameof is not defined
        };
    })
);