import PropertyBase from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyBase";
import React from "react";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";

interface IPropertyVersionValidityDependencies {
    localizationService: ILocalizationService;
}
interface IPropertyVersionValidityProps {
    _dependencies?: IPropertyVersionValidityDependencies;
    property: PropertyBase;
    useEffectiveValue?: boolean;
}


const PropertyVersionValidity: React.FC<IPropertyVersionValidityProps> = (props: IPropertyVersionValidityProps) => {
    let currentValue;
    const date = props.property.isTemporal ? LocalDate.today() : null;
    if (props.useEffectiveValue)  {
        currentValue = props.property.effectiveVersions?.length > 1 ? props.property.getEffectiveVersion(date) : null;
    } else {
        currentValue = props.property.versions?.length > 1 ? props.property.getVersion(date) : null;

    }
    if (!currentValue) {
        return null;
    }
    const validFrom = currentValue.validFrom ? props._dependencies.localizationService.localizeDate(currentValue.validFrom) : "";
    const validTo = currentValue.validTo ? props._dependencies.localizationService.localizeDate(currentValue.validTo) : "";
    return (
        <>
            {currentValue && `${validFrom} - ${validTo}`}
        </>
    );
};

export default connect(
    PropertyVersionValidity,
    new DependencyAdapter<IPropertyVersionValidityProps, IPropertyVersionValidityDependencies>(container => {
        return {
            localizationService: container.resolve("ILocalizationService")
        };
    })
);
