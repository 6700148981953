import React, { useCallback, useMemo } from "react";
import Styles from "./Icon.less";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import * as svgs from "./SvgImports";
import {ICommonControlProps} from "@Toolkit/ReactClient/Common/CommonControlProps";
import {Tooltip} from "@Toolkit/ReactClient/Components/Tooltip";

export type iconNameType =
    "trash" |
    "pills" |
    "pen" |
    "infoCircle" |
    "info" |
    "heartbeat" |
    "heartSolid" |
    "filter" |
    "exclamation" |
    "cog" |
    "chevronDown" |
    "check" |
    "circleAdd" |
    "calendar" |
    "lock" |
    "unlock" |
    "angleLeft" |
    "angleRight" |
    "addressCardSolid" |
    "caretUp" |
    "caretDown" |
    "caretRight" |
    "plus" |
    "minus" |
    "search" |
    "sync" |
    "ban" |
    "briefcaseSolid" |
    "money" |
    "remove" |
    "star" |
    "chevronUp" |
    "desktop" |
    "print" |
    "enter" |
    "female" |
    "male" |
    "mapMarkerAltSolid" |
    "more" |
    "notesMedicalSolid" |
    "patient" |
    "patientLife" |
    "person" |
    "paperclipSolid" |
    "file" |
    "file_ecg" |
    "document" |
    "clock" |
    "angleDoubleLeft" |
    "angleDoubleRight" |
    "shield" |
    "bars" |
    "link" |
    "unlink" |
    "allergy" |
    "feedback" |
    "intolerance" |
    "home" |
    "observationSheet" |
    "validated" |
    "stethoscope" |
    "empty" |
    "patientWalking" |
    "patientInBed" |
    "doorOpen" |
    "checkCircle" |
    "enterStrong" |
    "circleArrowCcw" |
    "exit" |
    "minusCircle" |
    "pause" |
    "editDiagnosis" |
    "editService" |
    "editDocument" |
    "road" |
    "expand" |
    "calendarClose" |
    "pin" |
    "ultrasound" |
    "psychiatry" |
    "laboratory_medicine" |
    "radiology" |
    "urology" |
    "dermatology" |
    "ophthalmology" |
    "pulmonology" |
    "cardiology" |
    "otorhinolaryngology" |
    "obstetrics_and_gynaecology" |
    "paediatrics" |
    "anesthesiology" |
    "surgery" |
    "internal_medicine" |
    "sites" |
    "institution" |
    "conservator" |
    "snow" |
    "cloudy" |
    "wind" |
    "rain" |
    "fog" |
    "clearnight" |
    "clearday" |
    "partlycloudynight" |
    "partlycloudyday" |
    "careType_dentistry" |
    "careType_familyDoctor" |
    "careType_radiology" |
    "careType_emergency" |
    "careType_ambulance" |
    "calculator" |
    "chart" |
    "dev" |
    "language" |
    "eeszt" |
    "patient_woman" |
    "patient_man" |
    "patient_unknown" |
    "find_patient" |
    "document_statistics" |
    "lessen" |
    "show" |
    "hashtag" |
    "checkDouble" |
    "documentsPlus" |
    "id_card" |
    "checkedBox" |
    "uncheckedBox" |
    "rss" |
    "clear_filter" |
    "add_patient" |
    "ribbon" |
    "edit_patient" |
    "healthcare" |
    "add_healthcare" |
    "save" |
    "oncology" |
    "appointments" |
    "new_appointment" |
    "appointment_config" |
    "history" |
    "attachment" |
    "fileSolid" |
    "fileInvoiceSolid" |
    "export" |
    "doctype_ambulant" |
    "doctype_care" |
    "doctype_medicalcertificate" |
    "doctype_report" |
    "doctype_surgeries" |
    "document_attached" |
    "document_empty" |
    "document_history" |
    "document_template" |
    "textblock_anamnesis" |
    "textblock_diagnostic" |
    "textblock_epicrisis" |
    "textblock_family" |
    "textblock_lab" |
    "textblock_macro" |
    "textblock_micro" |
    "textblock_status" |
    "textblock_terapy" |
    "align_center" |
    "align_justify" |
    "align_left" |
    "align_right" |
    "align_bottom" |
    "align_top" |
    "align_center_vertical" |
    "bold" |
    "border_all" |
    "border_bottom" |
    "border_center_h" |
    "border_center_v" |
    "border_color" |
    "border_inner" |
    "border_left" |
    "border_outer" |
    "border_right" |
    "border_top" |
    "border_none" |
    "insert_column" |
    "insert_row" |
    "delete_row" |
    "fill_drip " |
    "font_color" |
    "image" |
    "italic" |
    "list_ol" |
    "list_ul" |
    "merge_table" |
    "redo" |
    "table" |
    "text_template" |
    "text_template_past" |
    "text_template_result" |
    "underline" |
    "undo" |
    "status" |
    "crown" |
    "layer_group" |
    "star_filled" |
    "star_outline" |
    "sum" |
    "practitioner_textbubble" |
    "info_with_circle" |
    "warning" |
    "error" |
    "close_x" |
    "check_circle_regular" |
    "calendar_edit" |
    "invalid_outlined" |
    "re_appointment" |
    "invalid_solid" |
    "invalid_outlined_circle" |
    "prescription_other_textual" |
    "prescription_formula" |
    "paper_plane" |
    "scroll_solid" |
    "acupuncture" |
    "bathrobe" | 
    "commander" |
    "dead_body" |
    "doctor" |
    "graduation_cap_solid" |
    "house_user_solid" |
    "kidneys" |
    "pregnant" |
    "rehabilitation" |
    "vaccine" |
    "rotate_right" |
    "rotate_left" |
    "portrait_solid" |
    "landscape_solid" |
    "compress_arrows_alt_solid" |
    "covid" |
    "eeszt_not_uploaded" |
    "eeszt_uploaded" |
    "covid_vaccination" |
    "disease" |
    "in" |
    "out" |
    "employee" |
    "user_alt_solid" |
    "user_cog_solid" |
    "user_shield_solid" |
    "users_solid" |
    "covid_rapid" |
    "emergency_care" |
    "emergency_triage" |
    "mlabor" |
    "pathology" |
    "location_out" |
    "street_view_solid" |
    "plan_score" |
    "episode_of_care" |
    "telemedicine" |
    "autopsy" |
    "prescription" |
    "prescription_magistral" |
    "prescription_equipment" |
    "prescription_medicine" |
    "prescription_permanent" |
    "prescription_template" |
    "recommendation_equipment" |
    "recommendation_medicine" |
    "search_medicine" |
    "sk_tc_device_10001" |
    "sk_tc_device_10002" |
    "sk_tc_device_10003" |
    "sk_tc_device_10004" |
    "sk_tc_device_10005" |
    "sk_tc_device_10006" |
    "sk_tc_device_10009" |
    "phone_call" |
    "end_call";

export type iconVisualStyleType = "white" | "primary" | "secondary" | "error" | "warning" | "dark" | "primaryBadge" | "slate" | "original";

export interface IIconProps extends ICommonControlProps {
    visualStyle?: iconVisualStyleType;
    iconName: iconNameType;
    size?: "mini" | "compact" | "normal" | "largeish" | "large" | "extra large" | "giant";
    id?: string;
    name?: string;
    style?: React.CSSProperties;
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
    automationId?: string;
    stopClickPropagation?: boolean;
    svgRef?: React.LegacyRef<SVGSVGElement>;
}

const fillClassMap = {
    white: Styles.white,
    primary: Styles.primary,
    secondary: Styles.secondary,
    error: Styles.error,
    warning: Styles.warning,
    dark: Styles.dark,
    primaryBadge: Styles.primaryBadge,
    slate: Styles.slate
};

const strokeClassMap = {
    white: Styles.whiteStroke,
    primary: Styles.primaryStroke,
    secondary: Styles.secondaryStroke,
    error: Styles.errorStroke,
    warning: Styles.warningStroke,
    primaryBadge: Styles.primaryBadgeStroke,
    dark: Styles.darkStroke,
    slate: Styles.slateStroke
};

const getClassName = (props: IIconProps) => {
    let classes: CompositeClassName;

    switch (props.iconName) {
        case "validated":
            classes = new CompositeClassName(strokeClassMap[props.visualStyle], fillClassMap[props.visualStyle]);
            break;
        case "remove":
            classes = new CompositeClassName(strokeClassMap[props.visualStyle]);
            break;
        default:
            classes = new CompositeClassName(fillClassMap[props.visualStyle]);
            break;
    }

    classes.addIf(props.size === "compact", Styles.compactSize);
    classes.addIf(props.size !== "compact", Styles.normalSize);

    classes.addIf(props.disabled, Styles.disabledFill);

    classes.addIf(!props.disabled && props.onClick !== undefined, Styles.clickable);
    classes.add(props.className);

    return classes.classNames;
};

const getSvg = (iconName: iconNameType) => {
    return svgs[iconName];
};

const getSize = (size: string) => {
    switch (size) {
        case "mini":
            return 8;
        case "compact":
            return 12;
        case "normal":
            return 16;
        case "largeish":
            return 20;
        case "large":
            return 28;
        case "extra large":
            return 60;
        case "giant":
            return 80;
    }
    throw new Error("Size is out of range.");
};

const getRole = (props: IIconProps) => {
    if (props.iconName === "checkedBox" || props.iconName === "uncheckedBox") {
        return "checkbox";
    }
    return undefined;
};


function Icon(props: IIconProps) {

    const svg = getSvg(props.iconName);
    if (!svg) {
        return <>?</>;
    }

    const size = getSize(props.size);

    const clickHandler = useCallback((e: React.MouseEvent<SVGElement>) => {
        if (props.stopClickPropagation) {
            e.stopPropagation();
        }
        if (props.onClick) {
            props.onClick();
        }
    }, [props.stopClickPropagation, props.onClick]);

    const mouseDownHandler = useCallback((e: React.MouseEvent<SVGElement>) => {
        if (props.stopClickPropagation) {
            e.stopPropagation();
        }
    }, [props.stopClickPropagation]);

    const getAriaCheckedValue = useMemo(() => {
        if (props.iconName === "checkedBox") {
            return "true";
        } else if (props.iconName === "uncheckedBox") {
            return "false";
        } else {
            return undefined;
        }
    }, [props.iconName]);

    return (
        <Tooltip
            content={props.tooltipContent}
            placement={props.tooltipPosition}
            contentAlignment={props.tooltipTextAlign}
        >
            <svg
                ref={props.svgRef}
                dangerouslySetInnerHTML={{ __html: svg.content }}
                viewBox={svg.attributes.viewBox}
                preserveAspectRatio="xMidYMid meet"
                width={size}
                height={size}
                className={getClassName(props)}
                id={props.id}
                name={props.name}
                style={props.style}
                onClick={clickHandler}
                data-automation-id={props.automationId || props.iconName}
                onMouseDown={mouseDownHandler}
                role={getRole(props)}
                aria-checked={getAriaCheckedValue}
            />
        </Tooltip>
    );
}

Icon.defaultProps = {
    size: "normal",
    visualStyle: "primary",
    disabled: false
};

export default Icon;
