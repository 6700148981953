import React, { useCallback } from "react";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ColumnStyles from "./DataGridColumn.less";
import { RowId } from "@CommonControls/DataGrid/IDataGridProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import NumberBox from "@CommonControls/NumberBox";

interface IDataGridNumberColumnProps extends IDataGridColumnBaseProps {
    integerColumn?: boolean;
    nullValue?: number;
    clearable?: boolean;
}


const DataGridNumberColumn: React.FC<IDataGridNumberColumnProps> = props => {

    const { children, ...columnProps } = props;

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<number>) => {
        return <NumberBox {...filterProps} />;
    }, []);

    const renderValue = useCallback((value: number | number[], row: any, rowId: RowId, rowIndex: number, isUnderEditing: boolean, dataSetter: (newValue: number, rowIndex?: number, index?: number) => void) => {
        if (isNullOrUndefined(value)) { return null; }

        const isArray = Array.isArray(value);

        if (!isArray) {
            if (isUnderEditing) {
                return <NumberBox clearable={props.clearable} value={value as number} integerOnly={props.integerColumn} onChange={dataSetter} nullValue={props.nullValue} />;
            } else {
                return `${value}`;
            }
        } else {
            return (value as number[]).map((singleValue, index) => {
                const changeHandler = (newValue: number) => {
                    dataSetter?.(newValue, index);
                };

                return (
                    <div key={index} className={isUnderEditing ? ColumnStyles.inlineInputContainer : undefined}>
                        {!isUnderEditing ? `${singleValue}` : <NumberBox value={singleValue} integerOnly={props.integerColumn ?? false} onChange={changeHandler} nullValue={props.nullValue} />}
                    </div>
                );
            });
        }
    }, []);

    const renderHint = useCallback((value: any) => {
        const isArray = Array.isArray(value);
        if (!isArray) {
            return `${value}`;
        }
        const values: number[] = [];
        (value as number[]).map(n => {
            values.push(n);
        });

        return values.join(", ");
    }, []);

    return (
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={renderValue}
            onRenderHintValue={renderHint}
            onRenderFilter={filterRenderer}
        />
    );
};

export default DataGridNumberColumn;