exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DateRangePicker_calendar__select-box-menu-container_3Vkj8 {\n  width: min-content;\n  margin: 0 auto;\n  min-width: 210px;\n  display: flex;\n  flex-direction: row;\n}\n.__dark-mode .DateRangePicker_calendar__select-box-menu-container_3Vkj8 {\n  background-color: #2d3542;\n}\n.DateRangePicker_preset-menu_2c-98 {\n  margin-left: 3px;\n  min-width: 150px;\n  border-left: 2px solid rgba(43, 41, 51, 0.1);\n}\n.DateRangePicker_menu-value-header_fIufo {\n  min-height: 28px;\n  padding: 2px 8px;\n  text-align: center;\n  border-bottom: 2px solid rgba(43, 41, 51, 0.1);\n}\n", ""]);

// exports
exports.locals = {
	"calendar__select-box-menu-container": "DateRangePicker_calendar__select-box-menu-container_3Vkj8",
	"calendarSelectBoxMenuContainer": "DateRangePicker_calendar__select-box-menu-container_3Vkj8",
	"__dark-mode": "__dark-mode",
	"darkMode": "__dark-mode",
	"preset-menu": "DateRangePicker_preset-menu_2c-98",
	"presetMenu": "DateRangePicker_preset-menu_2c-98",
	"menu-value-header": "DateRangePicker_menu-value-header_fIufo",
	"menuValueHeader": "DateRangePicker_menu-value-header_fIufo"
};