import * as Proxy from "@HisPlatform/BoundedContexts/Scheduling/Api/Proxy.g";
import Di from "@Di";
import AppointmentScheduleDefinition from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentScheduleDefinition";
import AppointmentScheduleDefinitionId from "@Primitives/AppointmentScheduleDefinitionId.g";
import PlanningPeriod from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/PlanningPeriod";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import TimePhaseStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseStore";
import PractitionerResource from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/PractitionerResource";
import OrganizationUnitResource from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/OrganizationUnitResource";
import TimePhaseInterval from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseInterval";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import TimePhaseRecurrenceElement from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseRecurrenceElement";
import TimePhaseType from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseType";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import RecurrenceType from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/RecurrenceType";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";

type ResponseType =
    Proxy.AppointmentScheduleDefinitionDto
    | Proxy.CreateAppointmentScheduleDefinitionCommandResponse
    | Proxy.GetAppointmentScheduleDefinitionCommandResponse
    | Proxy.UpdateAppointmentScheduleDefinitionCommandResponse;

@Di.injectable()
export default class ScheduleDefinitionStoreMapper extends LockingEntityStoreMapper<Proxy.AppointmentScheduleDefinitionDto, AppointmentScheduleDefinition, ResponseType> {
    protected entityDtoSelector(response: ResponseType) {
        if (response instanceof Proxy.AppointmentScheduleDefinitionDto) {
            return response;
        } else if (
            response instanceof Proxy.CreateAppointmentScheduleDefinitionCommandResponse || Proxy.GetAppointmentScheduleDefinitionCommandResponse || Proxy.UpdateAppointmentScheduleDefinitionCommandResponse) {
            return response.appointmentScheduleDefinition;
        }
        return null;
    }

    protected applyToStoreCore(target: AppointmentScheduleDefinition, response: any): void {
        const dto = this.entityDtoSelector(response);
        if (!dto) {
            return;
        }

        target.id = dto.id;
        target.description = dto.description;
        target.name = dto.name;
        target.rowVersion = dto.rowVersion;
        target.ownedSchedulingServices = dto.ownedServices;
        target.planningPeriods = dto.planningPeriods.map(p => this.mapPlanningPeriod(p));
        target.extensionData = dto.extensionData;
    }

    private mapPlanningPeriod(dto: Proxy.PlanningPeriodDto) {
        const item = new PlanningPeriod();
        item.id = dto.id;
        item.interval = new LocalDateRange(dto.interval?.from, dto.interval?.to);
        item.timePhases = dto.timePhases.map(t => this.mapTimePhase(t));
        item.takeSnapshot();
        return item;
    }

    private mapTimePhase(dto: Proxy.TimePhaseDto) {
        const item = new TimePhaseStore(false);
        item.id = dto.id;
        item.allowedSchedulingServices = dto.allowedServices;
        item.allowedServiceRequestDefinitions = dto.allowedServiceRequestDefinitions.map(srd => new EntityVersionSelector(srd, LocalDate.today()));
        item.slotSizeInMinutes = dto.slotSizeInMinutes;
        item.createdAppointmentScheduleSlotSeries = dto.createdAppointmentScheduleSlotSeries;
        item.parallelCapacity = dto.parallelCapacity;
        item.practitionerResources = dto.resources.filter(r => 
            r instanceof Proxy.PractitionerResource
        ).map(r => new PractitionerResource((r as Proxy.PractitionerResource).practitionerId));
        item.organizationUnitResources = dto.resources.filter(r => 
            r instanceof Proxy.OrganizationUnitResource
        ).map(r => new OrganizationUnitResource((r as Proxy.OrganizationUnitResource).organizationUnitId));
        if (dto.occurrence instanceof Proxy.RecurringOccurrenceDto) {
            item.recurrenceElements = this.mapRecurrenceElement(dto.occurrence.element);
            item.interval = this.mapInterval(dto.occurrence.element);
            item.type = TimePhaseType.Recurring;
        } else if (dto.occurrence instanceof Proxy.StandaloneOccurrenceDto) {
            item.singleOccurrenceDate = LocalDate.createFromMoment(dto.occurrence.interval.from);
            item.interval = TimePhaseInterval.createFromMoments(dto.occurrence.interval.from, dto.occurrence.interval.to);
            item.type = TimePhaseType.Single;
        }
        item.takeSnapshot();
        return item;
    }

    private mapInterval(dto: Proxy.RecurrenceElementDto) {
        const intervals = this.getRecurrenceTypeDtos(dto).map(t => this.mapTimePhaseInterval(t));
        this.checkIntervals(intervals);
        return intervals[0];
    }

    private mapTimePhaseInterval(dto: Proxy.RecurrenceTypeDto) {
        const interval = new TimePhaseInterval();
        if (!isNullOrUndefined(dto.interval.from)) {
            interval.setStart(this.mapTimeOfDay(dto.interval.from));
        }
        if (!isNullOrUndefined(dto.interval.to)) {
            interval.setEnd(this.mapTimeOfDay(dto.interval.to));
        }
        return interval;
    }

    private mapTimeOfDay(dto: Proxy.LocalTimeDto) {
        return new TimeOfDay(dto.hour, dto.minute);
    }

    private mapRecurrenceElement(dto: Proxy.RecurrenceElementDto) {
        const elements = this.getRecurrenceTypeDtos(dto).map(t => this.mapRecurrenceType(t));
        this.checkRecurrenceElements(elements);
        return elements;
    }

    private getRecurrenceTypeDtos(dto: Proxy.RecurrenceElementDto) {
        if (dto instanceof Proxy.RecurrenceTypeDto) {
            return [dto];
        } else if (dto instanceof Proxy.RecurrenceOperationDto) {
            return this.getRecurrenceTypeDtosFromOperationDto(dto);
        }
        throw new Error(`Recurrence elment must be a derived type of ${"RecurrenceElementDto"}.`);
    }

    private getRecurrenceTypeDtosFromOperationDto(dto: Proxy.RecurrenceOperationDto) {
        this.checkRecurrenceOperation(dto);
        const mappedTypes = dto.elements.map(e => (e as Proxy.RecurrenceTypeDto));
        return mappedTypes;
    }

    private mapRecurrenceType(dto: Proxy.RecurrenceTypeDto) {
        const type = new TimePhaseRecurrenceElement();

        if (dto instanceof Proxy.EvenOddRecurrenceTypeDto) {
            type.setRecurrenceType(RecurrenceType.EvenOdd);
            type.setEvenWeek(dto.isEvenWeek);
            type.setDayOfWeek(dto.day);
            return type;
        } else if (dto instanceof Proxy.DayOfMonthRecurrenceTypeDto) {
            type.setRecurrenceType(RecurrenceType.DayOfMonth);
            type.setWeekOfMonth(dto.week);
            type.setDayOfWeek(dto.day);
            return type;
        } else if (dto instanceof Proxy.DayOfWeekRecurrenceTypeDto) {
            type.setRecurrenceType(RecurrenceType.DayOfWeek);
            type.setDayOfWeek(dto.day);
            return type;
        }
        throw new Error(`Recurrence type must be a derived type of ${"RecurrenceTypeDto"}.`);
    }

    private checkRecurrenceOperation(dto: Proxy.RecurrenceOperationDto) {
        if (!(dto instanceof Proxy.UnionRecurrenceOperationDto)) {
            throw new Error(`Client only supports ${"UnionRecurrenceOperationDto"} operation.`);
        }
        if (dto.elements.some(e => e instanceof Proxy.RecurrenceOperationDto) || dto.elements.some(e => typeof e !== typeof dto.elements[0])) {
            throw new Error(
                `Client only supports ${"UnionRecurrenceOperationDto"} operation ` +
                `of elements of identical type derived from ${"RecurrenceTypeDto"}.`);
        }
    }

    private checkRecurrenceElements(elements: TimePhaseRecurrenceElement[]) {
        if (elements.some(e => e.isEvenWeek !== elements[0].isEvenWeek)) {
            throw new Error(`In one time phase, client only supports ${"RecurrenceTypeDto"} types with the same isEvenWeek value.`);
        }
    }

    private checkIntervals(intervals: TimePhaseInterval[]) {
        if (intervals.some(i => !TimePhaseInterval.areEquals(intervals[0], i))) {
            throw new Error(`In one time phase, client only supports ${"RecurrenceTypeDto"} types with the same interval value.`);
        }
    }

    protected storeEntityIdType = AppointmentScheduleDefinitionId;
}
