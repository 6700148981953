/* auto-inject-disable */
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import { GetTypeNameWithPrefix } from "@HunSocialSecurityPlugin/PluginHelper";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/Proxy.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import _ from "@HisPlatform/Common/Lodash";
import NeakPerformanceStatement from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/NeakPerformanceStatement";
import NeakPerformanceStatementStoreMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/NeakPerformanceStatementStoreMapper";
import NeakPerformanceStatementDtoMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/NeakPerformanceStatementDtoMapper";
import { IOutpatientNeakReportFilter } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/OutpatientNeakReportPanel/IOutpatientNeakReportFilter";
import CareActivityId from "@Primitives/CareActivityId.g";
import INeakPerformanceStatementListItem from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/INeakPerformanceStatementListItem";
import NeakPerformanceStatementId from "@Primitives/NeakPerformanceStatementId.g";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import NeakErrorListStoreMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/NeakErrorListStoreMapper";
import NeakErrorListDtoMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/NeakErrorListDtoMapper";
import NeakErrorList from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/NeakErrorList";

@Di.injectable()
export default class NeakPerformanceStatementApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("IPerformanceStatementClient")) private readonly apiClient: Proxy.IPerformanceStatementClient,
        @Di.inject("NeakPerformanceStatementStoreMapper") private readonly neakPerformanceStatementStoreMapper: NeakPerformanceStatementStoreMapper,
        @Di.inject("NeakPerformanceStatementDtoMapper") private readonly neakPerformanceStatementDtoMapper: NeakPerformanceStatementDtoMapper,
        @Di.inject("NeakErrorListStoreMapper") private readonly neakErrorListStoreMapper: NeakErrorListStoreMapper,
        @Di.inject("NeakErrorListDtoMapper") private readonly neakErrorListDtoMapper: NeakErrorListDtoMapper    
    ) {
        super();
    }

    @State.bound
    public createNeakPerformanceStatement(filter: IOutpatientNeakReportFilter) {
        const dto = this.neakPerformanceStatementDtoMapper.getCreateNeakPerformanceReportDto(filter);
        return this.processOperationAsync(
            new NeakPerformanceStatement(),
            async target => {
                const response = await this.apiClient.createNeakPerformanceStatementCommandAsync(
                    CreateRequestId(),
                    dto
                );

                this.neakPerformanceStatementStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public hasFinalizedNeakPerformanceStatementForMonthAsync(filter: IOutpatientNeakReportFilter): Promise<SimpleStore<boolean>> {
        const dto = this.neakPerformanceStatementDtoMapper.hasFinalizedNeakPerformanceStatementForMonthDto(filter);
        return this.processOperationAsync(
            new SimpleStore<boolean>(),
            async target => {
                const response = await this.apiClient.hasFinalizedNeakPerformanceStatementForMonthQueryAsync(
                    CreateRequestId(),
                    dto
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.hasFinalizedNeakPerformanceStatement;
            }
        );
    }

    @State.bound
    public getNeakPerformanceStatementByIdAsync(id: NeakPerformanceStatementId) {
        return this.processOperationAsync(
            new NeakPerformanceStatement(),
            async target => {
                const response = await this.apiClient.getNeakPerformanceStatementByIdQueryAsync(
                    CreateRequestId(),
                    id.value
                );

                this.neakPerformanceStatementStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public setTransactionCodeAsync(id: NeakPerformanceStatementId, rowVersion: RowVersion, transactionCode: string, isValidateOnly: boolean = false) {
        return this.processOperationAsync(
            new NeakPerformanceStatement(),
            async target => {
                const response = await this.apiClient.setPerformanceStatementTransactionCodeCommandAsync(
                    CreateRequestId(),
                    new Proxy.SetPerformanceStatementTransactionCodeControllerDto({
                        neakPerformanceStatementId: id,
                        rowVersion: rowVersion,
                        transactionCode: transactionCode,
                        isValidateOnly: isValidateOnly
                    })
                );

                this.neakPerformanceStatementStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public createNeakRevisionPerformanceStatement(filter: IOutpatientNeakReportFilter) {
        const dto = this.neakPerformanceStatementDtoMapper.getCreateNeakPerformanceReportDto(filter);
        return this.processOperationAsync(
            new NeakPerformanceStatement(),
            async target => {
                const response = await this.apiClient.createNeakRevisionPerformanceStatementCommandAsync(
                    CreateRequestId(),
                    dto
                );

                this.neakPerformanceStatementStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public hasFinalizedNeakRevisionPerformanceStatementForMonthAsync(filter: IOutpatientNeakReportFilter): Promise<SimpleStore<boolean>> {
        const dto = this.neakPerformanceStatementDtoMapper.hasFinalizedNeakPerformanceStatementForMonthDto(filter);
        return this.processOperationAsync(
            new SimpleStore<boolean>(),
            async target => {
                const response = await this.apiClient.hasFinalizedNeakRevisionPerformanceStatementForMonthQueryAsync(
                    CreateRequestId(),
                    dto
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.hasFinalizedNeakRevisionPerformanceStatement;
            }
        );
    }

    @State.bound
    public getPerformanceStatementsByCareActivityIdAsync(careActivityId: CareActivityId) {
        return this.processOperationAsync(
            new SimpleStore<INeakPerformanceStatementListItem[]>(),
            async target => {
                const response = await this.apiClient.getNeakPerformanceStatementsByCareActivityIdQueryAsync(
                    CreateRequestId(),
                    careActivityId.value
                );

                target.value = response.neakPerformanceStatements.map(this.mapNeakPerformanceStatementListItem);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public setPerformanceStatementErrorListAsync(neakPerformanceStatement: NeakPerformanceStatement, fileName: string, binaryFile: Uint8Array) {
        return this.processOperationAsync(
            new NeakPerformanceStatement(),
            async target => {
                const response = await this.apiClient.setPerformanceStatementErrorListCommandAsync(
                    CreateRequestId(),
                    new Proxy.SetPerformanceStatementErrorListControllerDto({
                        neakPerformanceStatementId: neakPerformanceStatement.id,
                        fileName: fileName,
                        errorList: Base64Converter.fromByteArray(binaryFile),
                        rowVersion: neakPerformanceStatement.rowVersion
                    })
                );

                this.neakPerformanceStatementStoreMapper.applyToStore(target, response);
            }
        );
    }
    
    @State.bound
    public getNeakErrorListById(neakPerformanceStatementId: NeakPerformanceStatementId) {
        return this.processOperationAsync(
            new NeakErrorList(),
            async target => {
                const response = await this.apiClient.getNeakErrorListByIdQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetNeakErrorListByIdControllerDto({
                        id: neakPerformanceStatementId
                    })
                );

                this.neakErrorListStoreMapper.applyToStore(target, response.neakErrorListDto);

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    private mapNeakPerformanceStatementListItem(i: Proxy.NeakPerformanceStatementListItemDto): INeakPerformanceStatementListItem {
        return {
            finalizedAt: i.finalizedAt,
            reportValidityDate: i.reportValidityDate,
            leadDoctor: i.leadDoctor,
            referralWorkplace: i.referralWorkplace,
            referralDoctor: i.referralDoctor,
            pointOfCare: i.pointOfCare,
            referralCareIdentifier: i.referralCareIdentifier,
            referralCreationDate: i.referralCreationDate,
            careIdentifier: i.careIdentifier,
            wentUnderCareAtDate: i.wentUnderCareAtDate,
            wentUnderCareAtHour: i.wentUnderCareAtHour,
            citizenshipCountryCode: i.citizenshipCountryCode,
            identifierValue: i.identifierValue,
            identifierTypeId: i.identifierTypeId,
            genderId: i.genderId,
            birthDate: i.birthDate,
            zipCode: i.zipCode,
            financingClassId: i.financingClassId,
            partiallyReimbursedCost: i.partiallyReimbursedCost,
            admissionReasonId: i.admissionReasonId,
            diagnoses: i.diagnoses,
            services: i.services,
            dischargeReasonId: i.dischargeReasonId,
            laboratoryExaminationRequestTypeId: i.laboratoryExaminationRequestTypeId,
            imagingExaminationRequestTypeId: i.imagingExaminationRequestTypeId,
            ctMrExaminationRequestTypeId: i.ctMrExaminationRequestTypeId,
            physiotherapyExaminationRequestTypeId: i.physiotherapyExaminationRequestTypeId,
            earningCapacityConsiderationTypeId: i.earningCapacityConsiderationTypeId,
            travelExpenseClaimTypeId: i.travelExpenseClaimTypeId,
            accidentTypeId: i.accidentTypeId,
            numberOfPrescriptions: i.numberOfPrescriptions,
            numberOfGySePrescriptions: i.numberOfGySePrescriptions,
            numberOfTherapyPrescriptions: i.numberOfTherapyPrescriptions,
            financeStatement: i.financeStatement,
            reimbursementFree: i.reimbursementFree,
            reimbursementType: i.reimbursementType,
        } as INeakPerformanceStatementListItem;
    }
}