import {
    IDataGridColumnBaseProps,
    IDataGridColumnFilterProps,
} from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import React from "react";
import CareActivityBasedWorklistEntityType from "@Primitives/CareActivityBasedWorklistEntityType";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareActivityBasedWorklistHasValidationErrorSelectBox from "./CareActivityBasedWorklistHasValidationErrorSelectBox";
import HasValidationError from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/HasValidationError.g";

export interface ICareActivityBasedWorklistHasValidationError {
    value: HasValidationError;
}

interface ICareActivityBasedWorklistHasValidationErrorColumnDependencies {
    localizationService: ILocalizationService;
}

interface ICareActivityBasedWorklistHasValidationErrorColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: ICareActivityBasedWorklistHasValidationErrorColumnDependencies;
}

class CareActivityBasedWorklistHasValidationErrorColumn extends React.Component<ICareActivityBasedWorklistHasValidationErrorColumnProps> {    
    @State.bound
    private valueRenderer(value: HasValidationError) {
        const localized = isNullOrUndefined(value) ? null : this.props._dependencies.localizationService.localizeEnum(HasValidationError[value], "HasValidationError");
        return isNullOrUndefined(localized) ? null : localized.Name;
    }

    public render() {
        const {children, ...columnProps} = this.props;
        return (
            <DataGridColumn
                onRenderCellValue={this.valueRenderer}
                onRenderFilter={this.filterRenderer}
                showHint={false}
                {...columnProps}
            />
        );
    }

    private filterRenderer(filterProps: IDataGridColumnFilterProps<CareActivityBasedWorklistEntityType | CareActivityBasedWorklistEntityType[]>) {
        return (
            <CareActivityBasedWorklistHasValidationErrorSelectBox
                {...filterProps}
                displayMode="selectBox"
                multiSelect
                hoverOnlyIndicatorIcons
            />
        );
    }
}

export default connect(
    CareActivityBasedWorklistHasValidationErrorColumn,
    new DependencyAdapter<ICareActivityBasedWorklistHasValidationErrorColumnProps, ICareActivityBasedWorklistHasValidationErrorColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
