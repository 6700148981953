import { IServiceRequestAppointmentModalParams as IServiceRequestAppointmentModalParams } from "./ServiceRequestAppointmentModalParams";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import ServiceRequestAppointmentModalView from "./ServiceRequestAppointmentModalView";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";

interface IServiceRequestAppointmentModalProps extends IModalComponentParams, IServiceRequestAppointmentModalParams {
}

@State.observer
export default class ServiceRequestAppointmentModal extends React.Component<IServiceRequestAppointmentModalProps> {

    constructor(props: IServiceRequestAppointmentModalProps) {
        super(props);
    }

    public componentDidMount() {
        this.props.appointment.setSubjectService(this.props.serviceRequestSubject.id);
        this.props.appointment.setPointOfCareId(this.props.serviceRequestPointOfCareId);
    }

    @State.bound
    private close(updatedAppointment: Appointment) {
        if (updatedAppointment) {
            this.props.onSetAppointment(updatedAppointment);
            this.props.onSetPractitioner();
        }

        this.props.onClose(null);
    }

    @State.bound
    private closeAsync(updatedAppointment?: Appointment) {
        return new Promise<boolean>(() => {
            this.close(updatedAppointment);
            return true;
        });
    }

    public render() {
        return (
            <ServiceRequestAppointmentModalView
                appointment={this.props.appointment}
                serviceRequestSubject={this.props.serviceRequestSubject}
                onValidateAsync={this.props.onValidateAsync}
                onSaveAsync={this.closeAsync}
                onClose={this.close}
            />
        );
    }
}
