import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface ITelecomUseSelectBoxDependencies {
    commonReferenceDataStore: CommonReferenceDataDataStore;
}

interface ITelecomUseSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: ITelecomUseSelectBoxDependencies;
}

@State.observer
class TelecomUseSelectBox extends React.Component<ITelecomUseSelectBoxProps> {
    private get dependencies() { return this.props._dependencies; }
    private get extensibleEnumStore() { return this.dependencies.commonReferenceDataStore.telecomUse; }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    TelecomUseSelectBox,
    new DependencyAdapter<ITelecomUseSelectBoxProps, ITelecomUseSelectBoxDependencies>(container => {
        return {
            commonReferenceDataStore: container.resolve("CommonReferenceDataDataStore")
        };
    })
);