import { IModalData, IModalParams, IModalService } from "./ModalServiceAbstractions";
import ModalServiceRegistry from "@Toolkit/ReactClient/Components/ModalService/ModalServiceRegistry";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IHostRoutingAdapter from "@Toolkit/ReactClient/Routing/Abstractions/IHostRoutingAdapter";
import Di from "@Di";

@Di.injectable()
export default class ModalContextStore implements IModalService {

    private static modalUniqueId = 0;
    public readonly activeModals = State.observable.map<number, IModalData>([], { deep: false });

    @State.observable private pathName: string = null;

    constructor(
        @Di.inject("ModalServiceRegistry") private readonly modalServiceRegistry: ModalServiceRegistry,
        @Di.inject("IHostRoutingAdapter") private readonly hostRoutingAdapter: IHostRoutingAdapter
    ) {
        this.registerNavigateCloseHandler();
    }

    private registerNavigateCloseHandler() {
        this.pathName = this.hostRoutingAdapter.currentPathname;
        this.hostRoutingAdapter.addNavigationEventHandler(({ pathname }) => {
            if (this.pathName !== pathname) {
                this.pathName = pathname;
                this.activeModals.forEach(m => {
                    m.onClose(null);
                });
            }
        });
    }

    public showDialogAsync<TResult>(params: IModalParams): Promise<TResult> {
        return new Promise((resolve: (result: TResult) => void) => {
            if (!params) {
                resolve(null);
            }

            const id = this.getNewId();
            this._showModal({
                id,
                params,
                component: this.modalServiceRegistry.getComponentType(params),
                onClose: result => {
                    this.activeModals.delete(id);
                    resolve(result);
                }
            });
        });
    }

    public showModalAsync(params: IModalParams): Promise<void> {
        return new Promise((resolve: () => void) => {

            if (!params) {
                resolve();
            }

            const id = this.getNewId();
            this._showModal({
                id,
                params,
                component: this.modalServiceRegistry.getComponentType(params),
                onClose: () => {
                    this.activeModals.delete(id);
                    resolve();
                }
            });
        });
    }

    public showModal(params: IModalParams): void {
        if (!params) {
            return;
        }

        const id = this.getNewId();
        this._showModal({
            id,
            params,
            component: this.modalServiceRegistry.getComponentType(params),
            onClose: () => {
                this.activeModals.delete(id);
            }
        });
    }

    @State.action
    private _showModal(modalData: IModalData) {
        this.activeModals.set(modalData.id, modalData);
    }

    private getNewId() {
        return ModalContextStore.modalUniqueId++;
    }
}