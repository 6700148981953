import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import LateralityId from "@Primitives/LateralityId.g";
import SuspectedDiagnosisId from "@Primitives/SuspectedDiagnosisId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ConditionId from "@Primitives/ConditionId.g";

export default class SuspectedDiagnosis extends EntityStoreBase<SuspectedDiagnosisId> {
    @State.observable.ref public conditionId: ConditionId = null;
    @State.observable.ref public lateralityId: LateralityId = LateralityId.NotApplicable;

    @State.action.bound
    public setConditionId(newValue: ConditionId) {
        this.conditionId = newValue;
    }

    @State.action.bound
    public setLateralityId(newValue: LateralityId) {
        this.lateralityId = newValue;
    }

    @State.bound
    public isInInitialState() {
        return isNullOrUndefined(this.conditionId);
    }
}