import React, { useCallback, useState, useContext } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { INewSurgerytDialogParams, INewSurgerytDialogResult } from "./NewSurgeryDialogParams";
import SurgeryTypeDefinitionId from "@Primitives/SurgeryTypeDefinitionId.g";
import { SurgeryTypeDefinitionSelector } from "@HisPlatformControls";
import StaticSurgeryResources from "@HisPlatform/BoundedContexts/Surgery/StaticResources/StaticSurgeryResources";
import { PatientReactContext } from "@HisPlatform/Model/DomainModel/PatientContext/PatientContext";

interface INewSurgeryDialogProps extends IModalComponentParams<INewSurgerytDialogResult>, INewSurgerytDialogParams {

}

function NewSurgeryDialog(props: INewSurgeryDialogProps) {

    const cancel = useCallback(() => props.onClose(null), [props.onClose]);
    const [id, setId] = useState<SurgeryTypeDefinitionId | null>(null);
    const [isUrgent, setIsUrgent] = useState<boolean | null>(null);
    const ok = useCallback(() => props.onClose({selectedId: id, isUrgent: isUrgent}), [props.onClose, id, isUrgent]);

    return (
        <Ui.Modal isOpen onClose={cancel} size="compact" title={StaticSurgeryResources.NewSurgeryDialog.Title} closeButton>
            <Ui.Modal.Body>
                <SurgeryTypeDefinitionSelector
                                            label={StaticSurgeryResources.NewSurgeryDialog.SelectorLabel}
                                            value={id}
                                            onChange={setId as any}
                                            propertyIdentifier={"id"}
                                            automationId="__surgeryTypeDefinitionId"
                                        />
            </Ui.Modal.Body>
            <Ui.Modal.Footer
                left={(
                    <Ui.Button visualStyle="link" text={StaticSurgeryResources.NewSurgeryDialog.CancelButton} onClick={cancel} automationId="cancel" />
                )}
                right={(
                    <Ui.Button visualStyle="primary" text={StaticSurgeryResources.NewSurgeryDialog.SelectButton} onClick={ok} automationId="ok" />
                )} />
        </Ui.Modal>
    );
}

export default State.observer(NewSurgeryDialog);
