import Di from "@Di";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import PersonalizationApiAdapter from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/Personalization/PersonalizationApiAdapter";
import StatisticApiAdapter from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/StatisticApiAdapter";
import ConditionId from "@Primitives/ConditionId.g";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import EntityCollectionStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/Personalization/EntityCollectionStore";
import IEntityCollectionsService from "@HisPlatform/Services/Definition/EntityCollectionService/IEntityCollectionsService";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import UserId from "@Primitives/UserId.g";
import _ from "@HisPlatform/Common/Lodash";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import EntityCollectionOwner from "@HisPlatform/BoundedContexts/Productivity/Api/Personalization/Enum/EntityCollectionOwner.g";

type supportedEntities = "ExternalLocation" | "MedicalService" | "Condition" | "Practitioner";

@Di.injectable()
export default class EntityCollectionsService implements IEntityCollectionsService {

    constructor(
        @Di.inject("PersonalizationApiAdapter") private readonly personalizationApiAdapterApiAdapter: PersonalizationApiAdapter,
        @Di.inject("StatisticApiAdapter") private readonly statisticApiAdapter: StatisticApiAdapter,
        @Di.inject("CareReferenceDataStore") private readonly careReferenceDateStore: CareReferenceDataStore,
        @Di.inject("OrganizationReferenceDataStore") private readonly organizationReferenceDateStore: OrganizationReferenceDataStore
    ) {
    }

    public async getEntityCollectionsAsync(entityName: string, ownerId: PointOfCareId | UserId, ownerType: EntityCollectionOwner): Promise<EntityCollectionStore[]> {
        const response = await this.personalizationApiAdapterApiAdapter.getEntityCollections(entityName, ownerId, ownerType, this.getIdFactory(entityName as supportedEntities));
        await this.loadReferenceDataAsync(entityName as supportedEntities, response.value);
        return response.value;
    }

    public async setEntityCollectionAsync(store: EntityCollectionStore, entityName: string, entityCollectionOwner: EntityCollectionOwner, ownerId: IStringEntityId): Promise<EntityCollectionStore> {
        const response = await this.personalizationApiAdapterApiAdapter.setEntityCollectionAsync(store.id, entityName, entityCollectionOwner, ownerId, store.idList, this.getIdFactory(entityName as supportedEntities));
        await this.loadReferenceDataAsync(entityName as supportedEntities, [response.value]);
        return response.value;

    }

    public async getMostUsedEntityIdsAsync(entityName: string, pointOfCareId: PointOfCareId, count: number): Promise<IStringEntityId[]> {
        switch (entityName) {
            case "Condition":
                const conditionIds = await this.statisticApiAdapter.getMostUsedConditionIds(pointOfCareId, count);
                await this.loadConditionIdsAsync(conditionIds.value);
                return conditionIds.value;
            case "ExternalLocation":
                const externalLocationIds = await this.statisticApiAdapter.getMostUsedExternalLocationIds(pointOfCareId, count);
                await this.loadExternalLocationIdsAsync(externalLocationIds.value);
                return externalLocationIds.value;
            case "MedicalService":
                const medicalServiceIds = await this.statisticApiAdapter.getMostUsedMedicalServiceIds(pointOfCareId, count);
                await this.loadMedicalServiceIdsAsync(medicalServiceIds.value);
                return medicalServiceIds.value;
            case "Practitioner":
                const practitionerIds = await this.statisticApiAdapter.getMostUsedPractitionerIds(pointOfCareId, count);
                await this.loadPractitionerIdsAsync(practitionerIds.value);
                return practitionerIds.value;
            default:
                throw new Error(`Unsupported Entity type: ${entityName}`);
        }
    }

    private async loadConditionIdsAsync(ids: ConditionId[]) {
        const date = LocalDate.today();
        const selectors = ids.map(item => {
            return {
                id: item,
                validOn: date
            } as IEntityVersionSelector<ConditionId>;
        });
        await this.careReferenceDateStore.condition.ensureLoadedAsync(selectors);
    }

    private async loadMedicalServiceIdsAsync(ids: MedicalServiceId[]) {
        const date = LocalDate.today();
        const selectors = ids.map(item => {
            return {
                id: item,
                validOn: date
            } as IEntityVersionSelector<MedicalServiceId>;
        });
        await this.careReferenceDateStore.medicalService.ensureLoadedAsync(selectors);
    }

    private async loadExternalLocationIdsAsync(ids: ExternalLocationId[]) {
        const date = LocalDate.today();
        const selectors = ids.map(item => {
            return {
                id: item,
                validOn: date
            } as IEntityVersionSelector<ExternalLocationId>;
        });
        await this.organizationReferenceDateStore.externalLocationStore.ensureLoadedAsync(selectors);
    }

    private async loadPractitionerIdsAsync(ids: PractitionerId[]) {
        await this.organizationReferenceDateStore.doctorMap.ensureLoadedAsync(ids);
    }

    private getIdFactory(entityName: supportedEntities): (value: string) => IStringEntityId {
        switch (entityName) {
            case "Condition":
                return (value: string) => new ConditionId(value);
            case "ExternalLocation":
                return (value: string) => new ExternalLocationId(value);
            case "MedicalService":
                return (value: string) => new MedicalServiceId(value);
            case "Practitioner":
                return (value: string) => new PractitionerId(value);
            default:
                throw new Error(`Unsupported Entity type: ${entityName}`);
        }
    }

    private async loadReferenceDataAsync(entityName: supportedEntities, collections: EntityCollectionStore[]) {
        const flattenedIds = _.flatten(collections.map(item => item.idList));
        const ids = _.uniqBy(flattenedIds, item => item.value);
        switch (entityName) {
            case "Condition":
                await this.loadConditionIdsAsync(ids as ConditionId[]);
                break;
            case "MedicalService":
                await this.loadMedicalServiceIdsAsync(ids as MedicalServiceId[]);
                break;
            case "ExternalLocation":
                await this.loadExternalLocationIdsAsync(ids as ExternalLocationId[]);
                break;
            case "Practitioner":
                await this.loadPractitionerIdsAsync(ids as PractitionerId[]);
                break;
            default:
                throw new Error(`Unsupported Entity type: ${entityName}`);
        }
    }
}
