import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import ImmunizationId from "@Primitives/ImmunizationId.g";
import ImmunizationStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ImmunizationStore";
import ImmunizationStoreMapper from "./ImmunizationStoreMapper";
import { getAddImmunizationControllerDto, getUpdateImmunizationControllerDto, getDeleteImmunizationControllerDto, getDeleteImmunizationHistoryItemControllerDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/Immunization/ImmunizationDtoMapper";
import PatientId from "@Primitives/PatientId.g";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import IOperationResult from "@Toolkit/CommonWeb/ApiAdapter/IOperationResult";

@Di.injectable()
export default class ImmunizationApiAdapter extends ApiAdapterBase2 {

    constructor(
        @Di.inject("IPatientsClient") private readonly apiClient: Proxy.IPatientsClient,
        @Di.inject("ImmunizationStoreMapper") private readonly storeMapper: ImmunizationStoreMapper,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public getByIdAsync(immunizationId: ImmunizationId, requestLock: boolean): Promise<IOperationResult<ImmunizationStore>> {
        return this.executeOperationAsync<ImmunizationStore, Proxy.GetImmunizationByIdCommandResponse>(

            () => this.apiClient.getImmunizationByIdCommandAsync(
                CreateRequestId(),
                new Proxy.GetImmunizationByIdControllerDto({ immunizationId: immunizationId, requestLock: requestLock })
            ),

            response => {
                const entity = new ImmunizationStore(false);
                this.storeMapper.applyToStore(entity, response);
                return Promise.resolve(entity);
            }
        );
    }

    @State.bound
    public addImmunizationAsync(store: ImmunizationStore, patientId: PatientId, isPermissionCheckOnly?: boolean): Promise<IOperationResult<ImmunizationStore>> {
        return this.executeOperationAsync<ImmunizationStore, Proxy.AddImmunizationCommandResponse>(

            () => this.apiClient.addImmunizationCommandAsync(
                CreateRequestId(),
                getAddImmunizationControllerDto(store, patientId, false),
                isPermissionCheckOnly
            ),

            response => {
                const entity = new ImmunizationStore(false);
                this.storeMapper.applyToStore(entity, response);
                return Promise.resolve(entity);
            }
        );
    }

    @State.bound
    public updateImmunizationAsync(store: ImmunizationStore, isPermissionCheckOnly?: boolean): Promise<IOperationResult<ImmunizationStore>> {
        return this.executeOperationAsync<ImmunizationStore, Proxy.UpdateImmunizationCommandResponse>(

            () => this.apiClient.updateImmunizationCommandAsync(
                CreateRequestId(),
                getUpdateImmunizationControllerDto(store, false, store.lockInfo?.lockId, false),
                isPermissionCheckOnly
            ),

            response => {
                const entity = new ImmunizationStore(false);
                this.storeMapper.applyToStore(entity, response);
                return Promise.resolve(entity);
            }
        );
    }

    @State.bound
    public deleteImmunizationHistoryItemAsync(store: ImmunizationStore, versionNumber: number, isPermissionCheckOnly?: boolean): Promise<IOperationResult<ImmunizationStore>> {
        return this.executeOperationAsync<ImmunizationStore, Proxy.DeleteImmunizationHistoryItemCommandResponse>(

            () => this.apiClient.deleteImmunizationHistoryItemCommandAsync(
                CreateRequestId(),
                getDeleteImmunizationHistoryItemControllerDto(store, versionNumber, false, store.lockInfo?.lockId, false),
                isPermissionCheckOnly
            ),

            response => {
                const entity = new ImmunizationStore(false);
                this.storeMapper.applyToStore(entity, response);
                return Promise.resolve(entity);
            }
        );
    }

    @State.bound
    public deleteImmunizationAsync(store: ImmunizationStore, isPermissionCheckOnly?: boolean): Promise<IOperationResult<any>> {
        return this.executeOperationAsync<ImmunizationStore, Proxy.DeleteImmunizationCommandResponse>(

            () => this.apiClient.deleteImmunizationCommandAsync(
                CreateRequestId(),
                getDeleteImmunizationControllerDto(store),
                isPermissionCheckOnly
            ),

            response => {
                return Promise.resolve(null);
            }
        );
    }

    @State.bound
    public validateNewItemAsync(store: ImmunizationStore, patientId: PatientId): Promise<IOperationResult<IClientValidationResult[]>> {
        return this.executeOperationAsync<IClientValidationResult[], Proxy.AddImmunizationCommandResponse>(

            () => this.apiClient.addImmunizationCommandAsync(CreateRequestId(), getAddImmunizationControllerDto(store, patientId, true)),

            response => {
                const a = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                return Promise.resolve(a);
            }
        );
    }

    @State.bound
    public validateExistingItemAsync(store: ImmunizationStore): Promise<IOperationResult<IClientValidationResult[]>> {
        return this.executeOperationAsync<IClientValidationResult[], Proxy.AddImmunizationCommandResponse>(

            () => this.apiClient.updateImmunizationCommandAsync(CreateRequestId(), getUpdateImmunizationControllerDto(store, true, store.lockInfo?.lockId, false)),

            response => {
                const a = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                return Promise.resolve(a);
            }
        );
    }
}
