import React, {useMemo} from "react";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import Styles from "./AppointmentStatusBadgeStyles.less";
import EnumBadge from "@CommonControls/EnumBadge";
import AppointmentStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentStatus.g";

interface IAppointmentBadgeProps {
    status: AppointmentStatus;
    cancellationReasonNote: string;
}


const AppointmentStatusBadge: React.FC<IAppointmentBadgeProps> = (props) => {
    if (isNullOrUndefined(props.status)) {
        return null;
    }
    const className = useMemo(() => {
        switch (props.status) {
            case AppointmentStatus.Booked:
                return Styles.stateBooked;
            case AppointmentStatus.Fulfilled:
                return Styles.stateFulfilled;
            case AppointmentStatus.EnteredInError:
            case AppointmentStatus.CanceledWithOtherReason:
            case AppointmentStatus.CancelledByProvider:
            case AppointmentStatus.CancelledByPatient:
            case AppointmentStatus.NoShow:
                return Styles.stateCanceled;
            default:
                return null;
        }
    }, [props.status]);

    return (
        <EnumBadge
            enumType={AppointmentStatus}
            enumName={"AppointmentStatus"}
            value={props.status}
            className={className}
            rectangle
            additionalTooltipNote={props.cancellationReasonNote}
        />
    );

};

export default AppointmentStatusBadge;
