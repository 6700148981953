import React, { useCallback } from "react";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import RequestedServiceStateBadge from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/RequestedServiceStateBadge/RequestedServiceStateBadge";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import RequestedServiceState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceState.g";

interface IRequestedServiceStateColumnProps extends IDataGridColumnBaseProps {
    visualMode: "text" | "badge";
}

const RequestedServiceStateColumn: React.FC<IRequestedServiceStateColumnProps> = props => {
    const { children, ...columnProps } = props;
    const { localizationService } = useDependencies(c => ({
        localizationService: c.resolve<ILocalizationService>("ILocalizationService")
    }));

    const valueRenderer = useCallback((value: any, row: any) => {
        if (!isNullOrUndefined(value)) {
            if (props.visualMode === "text") {
                const localizedText = localizationService.localizeEnum(RequestedServiceState[value], "RequestedServiceState").Name;
                return localizedText;
            } else {
                return <RequestedServiceStateBadge state={value} />;
            }
        }
        return "";
    }, []);

    return (
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={valueRenderer}
        />
    );
};

export default RequestedServiceStateColumn;