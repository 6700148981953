import CareActivityId from "@Primitives/CareActivityId.g";
import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import MedicalServiceFinancingId from "@Primitives/MedicalServiceFinancingId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import PerformedServiceDataId from "@Primitives/PerformedServiceDataId.g";
import PerformedServiceClassificationId from "@Primitives/PerformedServiceClassificationId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class PerformedServiceDataStore extends EntityStoreBase<PerformedServiceDataId> {
    @State.observable.ref public medicalServiceFinancingId: MedicalServiceFinancingId;
    @State.observable.ref public careActivityId: CareActivityId;
    @State.observable.ref public classification: PerformedServiceClassificationId;
    @State.observable.ref public financedServiceId: FinancedServiceId;

    public static CreateFromJsonObject(source: any): PerformedServiceDataStore {
        const result = new PerformedServiceDataStore();

        if (!isNullOrUndefined(source)) {
            const performedServiceDataRaw = source["HunSocialSecurity_PerformedServiceData"];

            if (!isNullOrUndefined(performedServiceDataRaw)) {
                result.id = PerformedServiceDataId.fromJS(performedServiceDataRaw.Id);
                result.medicalServiceFinancingId = MedicalServiceFinancingId.fromJS(performedServiceDataRaw.MedicalServiceFinancingId);
                result.financedServiceId = FinancedServiceId.fromJS(performedServiceDataRaw.FinancedServiceId);   
                result.careActivityId = CareActivityId.fromJS(performedServiceDataRaw.CareActivityId);
                result.classification = PerformedServiceClassificationId.fromJS(performedServiceDataRaw.PerformedServiceClassificationId);
                result.rowVersion = RowVersion.fromJS(performedServiceDataRaw.RowVersion);
            }
        }

        return result;
    }
}