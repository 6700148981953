import React from "react";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import { ICommonControlProps, getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import { Tooltip } from "@Toolkit/ReactClient/Components/Tooltip";
import Styles from "./Badge.less";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";

export interface IBadgeProps extends ICommonControlProps {
    text?: string;
    visualStyle?: "positive" | "danger" | "warning" | "secondary" | "slate" | "light-blue";
    shape?: "rounded-rect" | "pill" | "circle";
    size?: "normal" | "small" | "mini";
    className?: string;
    fluid?: boolean;
    onClick?: () => void;
    upperCase?: boolean;
}
export default class Badge extends React.PureComponent<IBadgeProps> {

    public static defaultProps: Partial<IBadgeProps> = {
        shape: "rounded-rect",
        upperCase: true,
        size: "normal"
    };

    private getClassNames() {
        const classes = new CompositeClassName(Styles.label, Styles.container, this.props.className);

        classes.addIf(this.props.size === "small", Styles.containerSmall);
        classes.addIf(this.props.size === "mini", Styles.containerMini);

        switch (this.props.visualStyle) {
            case "positive":
                classes.add(Styles.success);
                break;
            case "danger":
                classes.add(Styles.danger);
                break;
            case "warning":
                classes.add(Styles.warning);
                break;
            case "slate":
                classes.add(Styles.slate);
                break;
            case "light-blue":
                classes.add(Styles.lightBlue);
                break;
            case "secondary":
            default:
                classes.add(Styles.secondary);
                break;
        }

        classes.addIf(this.props.fluid, Styles.containerFluid);
        classes.addIf(this.props.shape === "pill", Styles.containerShapePill);
        classes.addIf(this.props.shape === "circle", Styles.containerShapeCircle);

        return classes.classNames;
    }

    public render() {
        const htmlProps = getStandardHtmlProps(this.props, this.getClassNames());
        return (
            <Popper
                tooltipContent={this.props.tooltipContent}
                tooltipPlacement={this.props.tooltipPosition}
                arrowStyle={{ display: "none" }}
            >
                <div {...htmlProps} onClick={this.props.onClick}>
                    <span>
                        {this.props.upperCase ? (this.props.text && this.props.text.toUpperCase()) : this.props.text}
                        {this.props.children}
                    </span>
                </div>
            </Popper>
        );
    }
}