import State from "@Toolkit/ReactClient/Common/StateManaging";

export default abstract class Dosage {
    @State.observable public dailyAmount: number = 0;
    @State.observable.ref public reason: string;
    @State.observable.ref public dosageMode: string;
    @State.observable public isForPractitioner: boolean = false;

    @State.action.bound
    public setDailyAmount(amount: number) {
        this.dailyAmount = amount;
    }

    @State.action.bound
    public setReason(reason: string) {
        this.reason = reason;
    }

    @State.action.bound
    public setDosageMode(dosageMode: string) {
        this.dosageMode = dosageMode;
    }

    @State.action.bound
    public setIsForPractitioner(isForPractitioner: boolean) {
        this.isForPractitioner = isForPractitioner;
    }
}