import config from "@Config";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IMultiLingualLabel from "./IMultiLingualLabel";

export default class MultiLingualLabel implements IMultiLingualLabel {
    constructor(
        public readonly localizedLabels: Map<string, string>,
    ) { }

    @State.bound
    public getWithCurrentCultureCodeOrWithDefaultCultureCode(cultureCode: string) {
        return isNullOrUndefined(this.localizedLabels.get(cultureCode)) ?
            (isNullOrUndefined(this.localizedLabels.get(config.defaultCultureCode)) ?
                Array.from(this.localizedLabels.values())[0] :
                this.localizedLabels.get(config.defaultCultureCode)) :
            this.localizedLabels.get(cultureCode);
    }
}