import React, { useCallback } from "react";
import MasterDetailDetail from "@CommonControls/Layout/MasterDetailDetail";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { useWorklistDefinitionMasterDetailPanelStore } from "./WorklistDefinitionMasterDetailPanelStoreProvider";
import { Button, DataGrid, SaveButton } from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import WorklistItemDefinitionStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/WorklistDefinition/WorklistItemDefinitionStore";
import DataGridNumberColumn from "@CommonControls/DataGrid/Column/DataGridNumberColumn";
import DataGridBooleanColumn from "@CommonControls/DataGrid/Column/DataGridBooleanColumn";
import DataGridStringColumn from "@CommonControls/DataGrid/Column/DataGridStringColumn";
import DataGridEnumColumn from "@CommonControls/DataGrid/Column/DataGridEnumColumn";
import ItemVisibility from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/ItemVisibility.g";
import ClientSideValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidationBoundary";
import { RowId } from "@CommonControls/DataGrid/IDataGridProps";

/** @screen */
function WorklistDefinitionDetail() {

    const store = useWorklistDefinitionMasterDetailPanelStore();

    const getPropertyIdentifierForRow = function (value: any, row: any, rowId: RowId, rowIndex: number, isUnderEditing: boolean) {
        return `row_${rowId}_ColumnWidth`;
    };

    return (
        <ClientSideValidationBoundary validator={store.defaultColumnWidthValidator} entityTypeName="WorklistDefinitionDetail">
            <MasterDetailDetail
                title={store.title}
                passStateToChild={false}
                toolbar={(
                    <>
                        <Button automationId="showAddFormFieldModalButton"
                            text={StaticProductivityResources.Worklist.Definition.Columns.AddColumn}
                            onClickAsync={store.showAddFormFieldAsync} />
                        <SaveButton
                            automationId="worklistDefinitionDetail_saveButton"
                            onClickAsync={store.saveAsync}
                            permissionCheckOperationNames="UpdateWorklistDefinition" />
                    </>
                )}
            >
                <DataGrid
                    dataSource={store.selectedDetail.worklistItemDefinitionsDisplay}
                    fixedHeight="100%"
                    rowCheckState="checkState"
                    onRowChecked={store.selectedDetail.checkRow}
                    automationId="worklistDefinitionDetail_dataGrid"
                    rowUnderEditing="all"
                    rowId={"value"}
                >
                    <DataGridColumn header={StaticProductivityResources.Worklist.Definition.Columns.Name}
                        headerTextAlign="center"
                        dataGetter={"columnName"}
                        hideOverflow />
                    <DataGridNumberColumn header={StaticProductivityResources.Worklist.Definition.Columns.Order}
                        headerTextAlign="center"
                        isObserver
                        width="70px"
                        dataGetter={"order"}
                        dataSetter={"setOrder"} />
                    <DataGridEnumColumn header={StaticProductivityResources.Worklist.Definition.Columns.ItemVisibility}
                        headerTextAlign="center"
                        isObserver
                        displayMode="selectBox"
                        enumName={"ItemVisibility"}
                        enumType={ItemVisibility}
                        enumOrigin="server"
                        dataGetter={"itemVisibility"}
                        dataSetter={"setItemVisibility"} />
                    <DataGridStringColumn header={StaticProductivityResources.Worklist.Definition.Columns.DefaultColumnWidth}
                        headerTextAlign="center"
                        isObserver
                        width="100px"
                        dataGetter={"defaultColumnWidth"}
                        dataSetter={"setDefaultColumnWidth"}
                        propertyIdentifierFactory={getPropertyIdentifierForRow} />
                    <DataGridBooleanColumn header={StaticProductivityResources.Worklist.Definition.Columns.IsFiltered}
                        headerTextAlign="center"
                        cellTextAlign="center"
                        isObserver
                        width="70px"
                        dataGetter={"isFiltered"}
                        dataSetter={"setFilterMode"} />
                    <DataGridBooleanColumn header={StaticProductivityResources.Worklist.Definition.Columns.IsSortingEnabled}
                        headerTextAlign="center"
                        cellTextAlign="center"
                        isObserver
                        width="70px"
                        dataGetter={"isSortingEnabled"}
                        dataSetter={"setSortingMode"} />
                    <DataGridBooleanColumn header={StaticProductivityResources.Worklist.Definition.Columns.EllipsisOnOverflow}
                        headerTextAlign="center"
                        cellTextAlign="center"
                        isObserver
                        width="70px"
                        dataGetter={"ellipsisOnOverflow"}
                        dataSetter={"setEllipsisOnOverflow"} />
                </DataGrid>
            </MasterDetailDetail>
        </ClientSideValidationBoundary>
    );
}

export default State.observer(WorklistDefinitionDetail);