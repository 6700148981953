import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Reporting/Api/Proxy.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IReportInfo from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/Model/IReportInfo";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { IReportDefinition } from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/Model/IReportDefinition";
import ReportDefinitionIdentifier from "@Primitives/ReportDefinitionIdentifier.g";
import { emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import LargeDataToken from "@Primitives/LargeDataToken.g";

@Di.injectable()
export default class ReportingApiAdapter extends ApiAdapterBase {

    constructor(@Di.inject("IReportingClient") private readonly apiClient: Proxy.IReportingClient) {
        super();
    }

    @State.bound
    public getAllReportsAsync() {
        return this.processOperationAsync(
            new SimpleStore<IReportInfo[]>(),
            async target => {
                const response = await this.apiClient.getAllReportsQueryAsync(CreateRequestId());

                target.operationInfo = createOperationInfo(response);
                target.value = response && response.reports.map(r => this.mapReportInfo(r));
            }
        );
    }

    private mapReportInfo(r: Proxy.ReportInfoDto): IReportInfo {
        return {
            reportId: r.reportId,
            largeDataIds: r.largeDataIds ?? emptyArray,
            createdAt: r.createdAt,
            createdBy: r.createdBy,
            reportName: r.reportName,
            reportStatus: r.reportStatus
        } as IReportInfo;
    }

    @State.bound
    public getReportDefinitionsAsync() {
        return this.processOperationAsync(
            new SimpleStore<IReportDefinition[]>(),
            async target => {
                const response = await this.apiClient.getReportDefinitionsQueryAsync(CreateRequestId());

                target.operationInfo = createOperationInfo(response);
                target.value = response && response.reportDefinitions.map(r => {
                    return {
                        reportDefinitionIdentifier: r.reportDefinitionIdentifier,
                        name: r.name,
                        description: r.description
                    } as IReportDefinition;
                });
            }
        );
    }

    @State.bound
    public runReportAsync(reportDefinitionIdentifier: ReportDefinitionIdentifier, parameters: JSON, isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new SimpleStore<IReportInfo>(),
            async target => {
                const response = await this.apiClient.runReportCommandAsync(
                    CreateRequestId(),
                    new Proxy.RunReportControllerDto({
                        reportDefinitionIdentifier: reportDefinitionIdentifier,
                        parameters: parameters
                    }),
                    isPermissionCheckOnly);

                target.operationInfo = createOperationInfo(response);

                if (!isPermissionCheckOnly) {
                    target.value = response && this.mapReportInfo(response.reportInfoDto);
                }
            }
        );
    }

    @State.bound
    public getContentAsBlobAsync(largeDataId: LargeDataToken, isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new SimpleStore<Blob>(),
            async target => {
                target.value = await this.getFileResponseAsBlobAsync(async () => {
                    const response = await this.apiClient.getDocumentContentByIdAsync(CreateRequestId(), largeDataId.value + "");
                    target.operationInfo = createOperationInfo(response);
                    return response;
                });
            }
        );
    }

    @State.bound
    public getDiagnosesFrequencyByPatientIdQueryAsync(patientId: string) {
        return this.processOperationAsync(
            new SimpleStore<Proxy.PatientDiagnosesFrequencyDto>(),
            async target => {
                const response = await this.apiClient.getDiagnosesFrequencyByPatientIdQueryAsync(CreateRequestId(), patientId);
                target.operationInfo = createOperationInfo(response);
                target.value = response.patientDiagnosesFrequencyDto;
            }
        );
    }
}