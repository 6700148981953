import React from "react";
import IServiceRequestClinicalQuestionExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestClinicalQuestionExtensionPointProps";
import * as Ui from "@CommonControls";
import StaticWebAppResources from "@StaticResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";


const EhiEReferralClinicalQuestionExtensionPanel: React.FC<IServiceRequestClinicalQuestionExtensionPointProps> = (props: IServiceRequestClinicalQuestionExtensionPointProps) => {
    return (
        <Ui.TextBox
            label={StaticWebAppResources.NewServiceRequestPage.ExecutionNotesLabels.ClinicalQuestion}
            value={props.serviceRequest.clinicalQuestion}
            onChange={props.serviceRequest.setClinicalQuestion}
            autoResize
            multiline
            multilineMaxLineCount={6}
            propertyIdentifier="ClinicalQuestion"
            automationId="__clinicalQuestion"
            autoResizeHeight={props.textBoxHeight}
            onHeightChange={props.onTextBoxHeightChange}
        />
    );
};

export default State.observer(EhiEReferralClinicalQuestionExtensionPanel);
