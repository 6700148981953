import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { getEntryNames } from "@Toolkit/CommonWeb/EnumHelpers";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PerformanceReportGroupingType from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportGroupingType";
import StaticHunSocialSecurityReportingResources from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/StaticResources/StaticHunSocialSecurityReportingResources";
import AmbulanceGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/AmbulanceGroupingField.g";
import CareAssistantGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/CareAssistantGroupingField.g";
import CareDoctorGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/CareDoctorGroupingField.g";
import FinancingClassGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/FinancingClassGroupingField.g";
import InstituteGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/InstituteGroupingField.g";
import PointOfCareGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/PointOfCareGroupingField.g";
import ReferralWorkplaceGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/ReferralWorkplaceGroupingField.g";
import ServiceGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/ServiceGroupingField.g";
import SiteGroupingField from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/SiteGroupingField.g";

interface IPerformanceReportEnumBasedGroupingControlDependencies {
    localizationService: ILocalizationService;
}

interface IPerformanceReportEnumBasedGroupingControlProps {
    _dependencies?: IPerformanceReportEnumBasedGroupingControlDependencies;
    type: PerformanceReportGroupingType;
    columnValues: boolean[];
    onColumnValueChange: (newValue: boolean, index: number) => void;
    orderValue: any | any[];
    onOrderValueChange: (newValue: any | any[]) => void;
}

@State.observer
class PerformanceReportEnumBasedGroupingControl extends React.Component<IPerformanceReportEnumBasedGroupingControlProps> {

    public render() {
        let enumType;
        let enumName = "";

        switch (this.props.type) {
            case PerformanceReportGroupingType.Institute:
                enumType = InstituteGroupingField;
                enumName = "InstituteGroupingField";
                break;
            case PerformanceReportGroupingType.Site:
                enumType = SiteGroupingField;
                enumName = "SiteGroupingField";
                break;
            case PerformanceReportGroupingType.Ambulance:
                enumType = AmbulanceGroupingField;
                enumName = "AmbulanceGroupingField";
                break;
            case PerformanceReportGroupingType.PointOfCare:
                enumType = PointOfCareGroupingField;
                enumName = "PointOfCareGroupingField";
                break;
            case PerformanceReportGroupingType.FinancingClass:
                enumType = FinancingClassGroupingField;
                enumName = "FinancingClassGroupingField";
                break;
            case PerformanceReportGroupingType.CareDoctor:
                enumType = CareDoctorGroupingField;
                enumName = "CareDoctorGroupingField";
                break;
            case PerformanceReportGroupingType.CareAssistant:
                enumType = CareAssistantGroupingField;
                enumName = "CareAssistantGroupingField";
                break;
            case PerformanceReportGroupingType.ReferralWorkplace:
                enumType = ReferralWorkplaceGroupingField;
                enumName = "ReferralWorkplaceGroupingField";
                break;
            case PerformanceReportGroupingType.Service:
                enumType = ServiceGroupingField;
                enumName = "ServiceGroupingField";
                break;
            default:
                break;
        }

        const entryNames = getEntryNames(enumType);
        return (
            <>
                <Ui.Flex xsJustify={"start"} style={{ marginTop: "0px" }}>
                    <Ui.Flex.Item xs={9}>
                        <Ui.Flex>
                            {entryNames.map((x, index) => this.renderCheckBox(x, index, enumName))}
                        </Ui.Flex>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        {this.renderSelectBox(enumName, enumType)}
                    </Ui.Flex.Item>
                </Ui.Flex>

            </>
        );
    }

    @State.bound
    public renderCheckBox(key: string, index: number, enumName: string) {

        const onChange = (newValue: boolean) => {
            this.props.onColumnValueChange(newValue, index);
        };

        const localizedLabel = this.props._dependencies.localizationService.localizeEnum(key, enumName).Name;

        return (
            <Ui.Flex.Item xs={4}>
                <Ui.CheckBox
                    style={{ paddingTop: "25px" }}
                    label={localizedLabel}
                    value={this.props.columnValues[index]}
                    onChange={onChange}
                    propertyIdentifier={index === 0 ? "GroupingFields" : ""}
                    labelPosition="right"
                    automationId={localizedLabel + "checkBox"} />
            </Ui.Flex.Item>
        );
    }

    @State.bound
    public renderSelectBox(enumName: string, enumType: any) {
        return (
            <Ui.UniversalEnumSelector
                value={this.props.orderValue}
                onChange={this.props.onOrderValueChange}
                enumName={enumName}
                enumOrigin="server"
                enumType={enumType}
                displayMode="selectBox"
                label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.GroupingOrderLabel}
                propertyIdentifier={"OrderingField"}
                required
                automationId={enumName + "selector"}
            />
        );
    }
}

export default connect(
    PerformanceReportEnumBasedGroupingControl,
    new DependencyAdapter<IPerformanceReportEnumBasedGroupingControlProps, IPerformanceReportEnumBasedGroupingControlDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);