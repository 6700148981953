exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StandaloneContagiousPatientReportPanel_deleted_2wC4V {\n  background-color: #CE5374;\n}\n.StandaloneContagiousPatientReportPanel_approved_2Mj7J {\n  background-color: #21C313;\n}\n.StandaloneContagiousPatientReportPanel_under-recording_2YyPP {\n  background-color: #8695A7;\n}\n.StandaloneContagiousPatientReportPanel_waiting-for-approval_1a3hu {\n  background-color: #DDC00D;\n}\nmaster-container-style {\n  height: calc(100% - 9px);\n  margin-top: 9px;\n}\n", ""]);

// exports
exports.locals = {
	"deleted": "StandaloneContagiousPatientReportPanel_deleted_2wC4V",
	"approved": "StandaloneContagiousPatientReportPanel_approved_2Mj7J",
	"under-recording": "StandaloneContagiousPatientReportPanel_under-recording_2YyPP",
	"underRecording": "StandaloneContagiousPatientReportPanel_under-recording_2YyPP",
	"waiting-for-approval": "StandaloneContagiousPatientReportPanel_waiting-for-approval_1a3hu",
	"waitingForApproval": "StandaloneContagiousPatientReportPanel_waiting-for-approval_1a3hu"
};