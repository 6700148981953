import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import AutonomyDisabilityStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/AutonomyDisabilityStatementStore";
import AutonomyDisabilityStatementId from "@Primitives/AutonomyDisabilityStatementId.g";
import ReferencedAutonomyDisabilityAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/ReferencedAutonomyDisabilityAssignment";
import CustomAutonomyDisabilityAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/CustomAutonomyDisabilityAssignment";
import AutonomyDisabilityStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/AutonomyDisabilityStatementHistoryItem";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";

type ResponseType = Proxy.GetAutonomyDisabilityStatementByIdCommandResponse | Proxy.AddAutonomyDisabilityStatementCommandResponse | Proxy.UpdateAutonomyDisabilityStatementCommandResponse | Proxy.DeleteAutonomyDisabilityStatementHistoryItemCommandResponse;

@Di.injectable()
export default class AutonomyDisabilityStatementStoreMapper extends LockingEntityStoreMapper<Proxy.AutonomyDisabilityStatementDto, AutonomyDisabilityStatementStore, ResponseType> {

    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    protected storeEntityIdType = AutonomyDisabilityStatementId;

    protected entityDtoSelector = (response: ResponseType) => {
        return response.autonomyDisabilityStatementDto;
    };

    protected applyToStoreCore(target: AutonomyDisabilityStatementStore, response: any) {
        const dto = this.entityDtoSelector(response);
        if (dto != null) {
            target.id = dto.id;
            target.autonomyDisabilityAssignment = this.getAutonomyDisabilityAssignment(dto);

            target.historyItems = State.observable(this.getHistoryItems(dto.historyItems));
            target.currentItemIndex = target.historyItems.length - 1;
            target.patientId = dto.patientId;
            target.extensionData = dto.extensionData;
        }
    }

    private getHistoryItems(dtoArray: Proxy.AutonomyDisabilityStatementHistoryItemDto[]): AutonomyDisabilityStatementHistoryItem[] {
        return dtoArray.map(dto => this.getHistoryItem(dto)).sort((a, b) => a.versionNumber - b.versionNumber);
    }

    private getHistoryItem(dto: Proxy.AutonomyDisabilityStatementHistoryItemDto): AutonomyDisabilityStatementHistoryItem {
        const returnItem = new AutonomyDisabilityStatementHistoryItem();

        returnItem.description = dto.description;
        returnItem.historyItemDetail = this.getHistoryItemDetail(dto.historyItemDetail);
        returnItem.versionNumber = dto.versionNumber;
        returnItem.index = dto.index;
        returnItem.createdAt = dto.createdAt;
        returnItem.extensionData = dto.extensionData;

        return returnItem;
    }

    private getHistoryItemDetail(dto: Proxy.MedicalConditionHistoryItemDetailDto): MedicalConditionHistoryItemDetail {
        const returnItem = new MedicalConditionHistoryItemDetail();

        returnItem.asserter = dto.asserter;
        returnItem.asserterType = dto.asserterType;
        returnItem.notes = dto.notes;
        returnItem.verifier = dto.verifier;

        return returnItem;
    }

    private getAutonomyDisabilityAssignment(dto: Proxy.AutonomyDisabilityStatementDto) {
        let autonomyDisabilityAssignment;

        if (dto.autonomyDisabilityAssignment instanceof Proxy.ReferencedAutonomyDisabilityAssignmentDto) {
            autonomyDisabilityAssignment = new ReferencedAutonomyDisabilityAssignment(dto.autonomyDisabilityAssignment.autonomyDisabilityId);
        } else if (dto.autonomyDisabilityAssignment instanceof Proxy.CustomAutonomyDisabilityAssignmentDto) {
            autonomyDisabilityAssignment = new CustomAutonomyDisabilityAssignment(dto.autonomyDisabilityAssignment.name);
        }

        return autonomyDisabilityAssignment;
    }
}