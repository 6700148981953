import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Finance/Api/Proxy.g";
import IVatRate from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/IVatRate";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class VatRateApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("IInvoicingClient") private readonly invoicingApiClient: Proxy.IInvoicingClient,
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public getAllVatRatesAsync() {
        return this.executeOperationAsync(
            () => this.invoicingApiClient.getAllVatRatesQueryAsync(CreateRequestId()),
            response => {
                return response.vatRates?.map<IVatRate>(v => ({
                    id: v.id,
                    code: v.code,
                    name: v.name,
                    grossCalculationRate: v.grossCalculationRate
                }));
            }
        );
    }
}