import DependencyContainer from "@DiContainer";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";

DependencyContainer
    .bind("ApplicationContext")
    .to(ApplicationContext)
    .inSingletonScope();

DependencyContainer
    .bind("UserContext")
    .to(UserContext)
    .inSingletonScope();

DependencyContainer
    .bind("ICurrentCultureProvider")
    .toDynamicValue(() => DependencyContainer.get<ICurrentCultureProvider>("ApplicationContext"));