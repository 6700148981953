import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import { getAddPatientBloodTypeControllerDto, getUpdatePatientBloodTypeControllerDto, getDeletePatientBloodTypeControllerDto, getDeletePatientBloodTypeHistoryItemControllerDto } from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/PatientBloodTypeDtoMapper";
import PatientId from "@Primitives/PatientId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import PatientBloodTypeStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/PatientBloodTypeStoreMapper";
import PatientBloodTypeId from "@Primitives/PatientBloodTypeId.g";
import PatientBloodTypeStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/PatientBloodType/PatientBloodTypeStore";
import { GetTypeNameWithPrefix } from "@HunEHealthInfrastructurePlugin/PluginHelper";

/* auto-inject-disable */
@Di.injectable()
export default class PatientBloodTypeApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("IPatientBloodTypeClient")) private apiClient: Proxy.IPatientBloodTypeClient,
        @Di.inject("PatientBloodTypeStoreMapper") private readonly storeMapper: PatientBloodTypeStoreMapper
    ) {
        super();
    }

    public getByIdAsync(patientBloodTypeId: PatientBloodTypeId, requestLock: boolean): Promise<PatientBloodTypeStore> {
        return this.processOperationAsync(
            new PatientBloodTypeStore(false),
            async target => {
                const response = await this.apiClient.getPatientBloodTypeByIdCommandAsync(
                    CreateRequestId(),
                    new Proxy.GetPatientBloodTypeByIdControllerDto({ patientBloodTypeId: patientBloodTypeId, requestLock: requestLock })
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public addPatientBloodTypeAsync(store: PatientBloodTypeStore, patientId: PatientId, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new PatientBloodTypeStore(false),
            async target => {
                const response = await this.apiClient.addPatientBloodTypeCommandAsync(
                    CreateRequestId(),
                    getAddPatientBloodTypeControllerDto(store, patientId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public updatePatientBloodTypeAsync(store: PatientBloodTypeStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new PatientBloodTypeStore(false),
            async target => {
                const response = await this.apiClient.updatePatientBloodTypeCommandAsync(
                    CreateRequestId(),
                    getUpdatePatientBloodTypeControllerDto(store, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deletePatientBloodTypeHistoryItemAsync(store: PatientBloodTypeStore, versionNumber: number, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new PatientBloodTypeStore(false),
            async target => {
                const response = await this.apiClient.deletePatientBloodTypeHistoryItemCommandAsync(
                    CreateRequestId(),
                    getDeletePatientBloodTypeHistoryItemControllerDto(store, versionNumber, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deletePatientBloodTypeAsync(store: PatientBloodTypeStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<any>(),
            async target => {
                const response = await this.apiClient.deletePatientBloodTypeCommandAsync(
                    CreateRequestId(),
                    getDeletePatientBloodTypeControllerDto(store),
                    isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public validateAsync(store: PatientBloodTypeStore, patientId: PatientId) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response =
                    store.isNew ?
                        await this.apiClient.addPatientBloodTypeCommandAsync(CreateRequestId(), getAddPatientBloodTypeControllerDto(store, patientId, true)) :
                        await this.apiClient.updatePatientBloodTypeCommandAsync(CreateRequestId(), getUpdatePatientBloodTypeControllerDto(store, true, store.lockInfo?.lockId, false));

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    public async checkPermissionForAddNewItemAsync(store: PatientBloodTypeStore, patientId: PatientId): Promise<PatientBloodTypeStore> {
        return await this.addPatientBloodTypeAsync(store, patientId, true);        
    }

    public async checkPermissionForUpdateItemAsync(store: PatientBloodTypeStore): Promise<PatientBloodTypeStore> {
        return await this.updatePatientBloodTypeAsync(store, true);
    }

    public async checkPermissionForDeleteItemAsync(store: PatientBloodTypeStore): Promise<SimpleStore> {
        return await this.deletePatientBloodTypeAsync(store, true);
    }
}
