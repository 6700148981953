import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import PatientId from "@Primitives/PatientId.g";
import ReferencedEntityFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEntityFormDataElement";
import { useFormEntityIdArrayField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { ICustomBlockRegistryItem, IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React from "react";
import EpisodeOfCareSelectBox from "./EpisodeOfCareSelectBox";

interface IEpisodeOfCareSelectBoxFormCustomBlockProps extends IFormCustomBlockComponentProps {
    _patientId?: PatientId;
    _careActivityId?: CareActivityId;
}

function _EpisodeOfCareSelectBoxFormCustomBlock(props: IEpisodeOfCareSelectBoxFormCustomBlockProps) {
    const [episodeOfCareIds, setEpisodeOfCareIds] = useFormEntityIdArrayField<EpisodeOfCareId>(props.form.data.Content, "EpisodeOfCareIds", EpisodeOfCareId);

    return (
        <EpisodeOfCareSelectBox
            hasAddNew={true}
            patientId={props._patientId}
            careActivityId={props._careActivityId}
            value={episodeOfCareIds}
            onChange={setEpisodeOfCareIds}
            multiSelect
            automationId="__episodeOfCareSelectBox" />
    );
}

const EpisodeOfCareSelectBoxFormCustomBlock = connect(
    State.observer(_EpisodeOfCareSelectBoxFormCustomBlock),
    new PatientContextAdapter<IEpisodeOfCareSelectBoxFormCustomBlockProps>(p => ({
        _patientId: p.patientId
    })),
    new CareActivityContextAdapter<IEpisodeOfCareSelectBoxFormCustomBlockProps>(p => ({
        _careActivityId: p.careActivityId
    }))
);

export const EpisodeOfCareSelectBoxRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Episode of care select box",
    dataElementsFactory: () => [
        new ReferencedEntityFormDataElement("EpisodeOfCareIds", true, null, false, false, "EpisodeOfCare")
    ],
    componentType: EpisodeOfCareSelectBoxFormCustomBlock
};

export default EpisodeOfCareSelectBoxFormCustomBlock;
