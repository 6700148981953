import StaticFormEditingResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import React from "react";
import { IFilterDialogParams, IFilterDialogResult } from "./FilterDialogParams";
import FilterStore from "./FilterStore";
import * as Ui from "@CommonControls";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import IncludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierFilter";
import ExcludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierFilter";
import IncludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierSystemIdFilter";
import ExcludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierSystemIdFilter";
import CodeStartsWithFilter from "@Toolkit/CommonWeb/Model/Filtering/CodeStartsWithFilter";
import CodeDoesNotStartWithFilter from "@Toolkit/CommonWeb/Model/Filtering/CodeDoesNotStartWithFilter";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";

interface IFilterDialogProps extends IModalComponentParams<IFilterDialogResult>, IFilterDialogParams { }

export default class FilterDialog extends React.Component<IFilterDialogProps> {

    private filters = State.createObservableShallowArray<FilterStore>(this.convertFiltersToStores());
    private readonly filterSeparator = "||";

    public render() {
        return (
            <Ui.Modal isOpen onClose={this.cancel} title={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersModal.Title}>
                <Ui.Modal.Body>
                    <ListPanel<FilterStore>
                        renderItemEditor={this.renderFilterEditor}
                        onCreateNewAsync={this.createNewFilterAsync}
                        actionButtonsOrientation="vertical"
                        items={this.filters}
                        noItemsMessage="No filters found."
                        alwaysEdit
                        onRemoveItem={this.removeFilter}
                    />
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Button
                        onClick={this.cancel}
                        text={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersModal.CloseButton}
                        float="left"
                        automationId="EntityReference_Filters_Modal_Close_Button" />
                    <Ui.Button
                        onClick={this.ok}
                        text="Ok"
                        visualStyle="primary"
                        float="right"
                        automationId="EntityReference_Filters_Modal_Ok_Button" />
                </Ui.Modal.Footer>
            </Ui.Modal>
        );
    }

    @State.bound
    private renderFilterEditor(item: FilterStore, index: number) {
        return (
            <State.Observer>{() => (
                <>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <Ui.SelectBox
                                label={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersModal.FilterTypeLabel}
                                value={item.filterType}
                                onChange={item.setFilterType}
                                getOptionValue={this.getOptionValue}
                                items={item.preloadedOptions}
                                propertyIdentifier="Type"
                                automationId={`filter_type_${index}`}
                                tooltipContent={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersModal.FilterTypeTooltip}
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            <Ui.TextBox
                                label={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersModal.FilterIdentifiersLabel}
                                value={item.identifiers}
                                onChange={item.setFilterIdentifiers}
                                propertyIdentifier="Identifiers"
                                automationId={`filter_identifiers_${index}`}
                                tooltipContent={this.getFilterIdentifiersTooltipContent(item)}
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </>
            )}</State.Observer>
        );
    }

    private createNewFilterAsync() {
        return Promise.resolve(new FilterStore());
    }

    @State.bound
    private removeFilter(item: FilterStore) {
        this.filters.remove(item);
    }

    private getFilterIdentifiersTooltipContent(item: FilterStore): string {
        switch (item.filterType) {
            case "IncludeIdentifierFilter":
            case "ExcludeIdentifierFilter":
                return StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersModal.FilterIdentifiersTooltip1;
            case "IncludeIdentifierSystemIdFilter":
            case "ExcludeIdentifierSystemIdFilter":
                return StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersModal.FilterIdentifiersTooltip2;
            case "CodeStartsWithFilter":
            case "CodeDoesNotStartWithFilter":
                return StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersModal.FilterIdentifiersTooltip3;
            default:
                return "";
        }
    }

    private getOptionValue(value: ISelectBoxItem<FilterStore>) {
        if (!value.value) {
            return null;
        }

        return `${value.value}`;
    }

    private convertFiltersToStores(): FilterStore[] {
        return this.props.existingFilters.map(filter => {
            if (filter instanceof IncludeIdentifierFilter) {
                return new FilterStore("IncludeIdentifierFilter", this.stringifyIdentifier(filter.value));
            }
            else if (filter instanceof ExcludeIdentifierFilter) {
                return new FilterStore("ExcludeIdentifierFilter", this.stringifyIdentifier(filter.value));
            }
            else if (filter instanceof IncludeIdentifierSystemIdFilter) {
                return new FilterStore("IncludeIdentifierSystemIdFilter", filter.value.value);
            }
            else if (filter instanceof ExcludeIdentifierSystemIdFilter) {
                return new FilterStore("ExcludeIdentifierSystemIdFilter", filter.value.value);
            }
            else if (filter instanceof CodeStartsWithFilter) {
                return new FilterStore("CodeStartsWithFilter", filter.value);
            }
            else if (filter instanceof CodeDoesNotStartWithFilter) {
                return new FilterStore("CodeDoesNotStartWithFilter", filter.value);
            }
            else if (filter instanceof ExplicitIdentifierFilter) {
                return new FilterStore("ExplicitIdentifierFilter", this.stringifyExplicitIdentifierFilterData(filter.type, filter.value));
            }
            throw new Error("Not supported filter type.");
        });
    }

    private convertStoresToFilters(): FilterBase[] {
        const result: FilterBase[] = [];

        this.filters.filter(filter => filter.filterType && filter.identifiers).forEach(filter => {
            switch (filter.filterType) {
                case "IncludeIdentifierFilter":
                    for (const identifier of this.parseIdentifiers(filter.identifiers)) {
                        result.push(new IncludeIdentifierFilter(identifier));
                    }
                    break;
                case "ExcludeIdentifierFilter":
                    for (const identifier of this.parseIdentifiers(filter.identifiers)) {
                        result.push(new ExcludeIdentifierFilter(identifier));
                    }
                    break;
                case "IncludeIdentifierSystemIdFilter":
                    for (const identifier of this.parseIdentifierSystemIds(filter.identifiers)) {
                        result.push(new IncludeIdentifierSystemIdFilter(identifier));
                    }
                    break;
                case "ExcludeIdentifierSystemIdFilter":
                    for (const identifier of this.parseIdentifierSystemIds(filter.identifiers)) {
                        result.push(new ExcludeIdentifierSystemIdFilter(identifier));
                    }
                    break;
                case "CodeStartsWithFilter":
                    for (const identifier of this.parseCodes(filter.identifiers)) {
                        result.push(new CodeStartsWithFilter(identifier));
                    }
                    break;
                case "CodeDoesNotStartWithFilter":
                    for (const identifier of this.parseCodes(filter.identifiers)) {
                        result.push(new CodeDoesNotStartWithFilter(identifier));
                    }
                    break;
                case "ExplicitIdentifierFilter":
                    const explicitIdentifierData = this.parseExplicitIdentifierData(filter.identifiers);
                    result.push(new ExplicitIdentifierFilter(explicitIdentifierData[0], explicitIdentifierData[1]));
                    break;
                default:
                    throw new Error("Not supported filter type.");
            }
        });

        return result;
    }

    private stringifyIdentifier(identifier: Identifier): string {
        return `${identifier.identifierSystemId.value} && ${identifier.value}`;
    }

    private stringifyExplicitIdentifierFilterData(type: string, value: string) {
        const data = {
            type: type,
            value: value
        };
        return JSON.stringify(data);
    }

    private parseIdentifiers(identifiers: string): Identifier[] {
        return identifiers.split(this.filterSeparator).map(identifier => {
            const data = identifier.trim().split('&&');
            const identifierSystemId = new IdentifierSystemId(data.length > 0 ? data[0].trim() : "");
            const value = data.length > 1 ? data[1].trim() : "";
            return new Identifier(identifierSystemId, value);
        });
    }

    private parseIdentifierSystemIds(identifierSystemIds: string): IdentifierSystemId[] {
        return identifierSystemIds.split(this.filterSeparator).map(identifierSystemId => new IdentifierSystemId(identifierSystemId.trim()));
    }

    private parseCodes(codes: string): string[] {
        return codes.split(this.filterSeparator).map(code => code.trim());
    }

    private parseExplicitIdentifierData(data: string): string[] {
        const dataObject = JSON.parse(data);
        return [dataObject.type, dataObject.value];
    }

    @State.bound
    private cancel() {
        this.props.onClose(null);
    }

    @State.bound
    private ok() {
        this.props.onClose({ resultFilters: this.convertStoresToFilters() });
    }
}