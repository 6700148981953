import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import ShowUserGroupsScreenAction from "@HisPlatform/Packages/UserManagement/FrontendActions/ShowUserGroupsScreenAction.g";
import ShowUsersScreenAction from "@HisPlatform/Packages/UserManagement/FrontendActions/ShowUsersScreenAction.g";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import NavMenuActionItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuActionItem";

export function getNavigationMenuItems(): INavMenuItem[] {
    
    const leftTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const rightTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const childMenuItems: INavMenuItem[] = [
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "Users",
            "Settings",
            new ShowUsersScreenAction(),
            "UpdateUsers",
            {
                automationId: "userManagement",
                text: StaticUserManagementResources.UserManagementPanel.Title,
                order: 5
            }
        ),
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "UserGroups",
            "Settings",
            new ShowUserGroupsScreenAction(),
            "UpdateUserGroups",
            {
                automationId: "userGroupManagement",
                text: StaticUserManagementResources.UserGroupManagementPanel.Title,
                order: 6
            }
        )
    ];

    return [...leftTopLevelMenuItems, ...rightTopLevelMenuItems , ...childMenuItems];
}