import React from "react";
import { ITokenSettingsPanelPropsBase } from "@PluginInterface/BoundedContexts/DocumentManagement/TokenSettingsPanelRegistry/ITokenSettingsPanelRegistry";
import TextBox from "@CommonControls/TextBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import * as Ui from "@CommonControls";

interface IDateTokenFormatterSettingsPanelProps extends ITokenSettingsPanelPropsBase<Proxy.DateTokenValueFormatterSettingsDto> {
}

/** @screen */
export default class DateTokenFormatterSettingsPanel extends React.Component<IDateTokenFormatterSettingsPanelProps> {
    public render() {
        return (
            <TextBox
                label={StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.DateFormat}
                infoLabel={this.renderInfo()} value={this.props.tokenFormatterSettings?.formatString ?? ""}
                placeholder="yyyy-MM-dd"
                onChange={this.setFormatString}
                automationId="dateFormatTextBox" />
        );
    }

    private renderInfo() {
        return (
            <Ui.Table automationId="dateTokenFormatterSettingsPanel_table">
                <thead>
                    <tr>
                        <th>{StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.DateFormat}</th>
                        <th>{StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.Examples}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>"d"</td>
                        <td>2009-06-01T13:45:30 -{">"} 1</td>
                    </tr>
                    <tr>
                        <td>"yyyy"</td>
                        <td>2009-06-15T13:45:30 -{">"} 2009</td>
                    </tr>
                    <tr>
                        <td>"yy.MM"</td>
                        <td>2009-06-15T13:45:30 -{">"} 09.06</td>
                    </tr>
                </tbody>
            </Ui.Table>
        );
    }

    @State.bound
    private setFormatString(value: string) {
        this.props.onSettingsChange(new Proxy.DateTokenValueFormatterSettingsDto({
            formatString: value
        }), null);
    }
}