exports = module.exports = require("../../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RolePermissionConfigurationPanel_new-element-panel_3unwf {\n  background-color: #82d4ed;\n  width: 100%;\n  margin: 0;\n  height: 58px;\n}\n.RolePermissionConfigurationPanel_new-element-panel_3unwf th:first-child {\n  padding-left: 16px;\n}\n.RolePermissionConfigurationPanel_margin-right_XUHZt {\n  margin-right: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"new-element-panel": "RolePermissionConfigurationPanel_new-element-panel_3unwf",
	"newElementPanel": "RolePermissionConfigurationPanel_new-element-panel_3unwf",
	"margin-right": "RolePermissionConfigurationPanel_margin-right_XUHZt",
	"marginRight": "RolePermissionConfigurationPanel_margin-right_XUHZt"
};