import { assertNotNull } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ValueWrapper from "./ValueWrapper";

export default class ValueWrapperMap<TKey extends ValueWrapper<string | number>, TValue> {
    private readonly map = new Map<string | number, TValue>();

    public get(key: TKey) {
        const item = this.getOrNull(key);
        
        if (item === null) {
            throw new Error(`Wrapped key not found '${key.value}'.`);
        }

        return item;
    }

    public getOrNull(key: TKey) {
        assertNotNull(key, "key cannot be null");

        return this.map.get(key.value) ?? null;
    }

    public set(key: TKey, value: TValue) {
        assertNotNull(key, "key cannot be null");

        this.map.set(key.value, value);
    }

    public remove(key: TKey) {
        assertNotNull(key, "key cannot be null");
        
        this.map.delete(key.value);
    }
}