exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PagerButton_not-active_3G4zZ {\n  min-width: auto;\n  padding-left: 0;\n  padding-right: 0;\n}\n", ""]);

// exports
exports.locals = {
	"not-active": "PagerButton_not-active_3G4zZ",
	"notActive": "PagerButton_not-active_3G4zZ"
};