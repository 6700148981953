import React, { useContext, useMemo } from "react";
import ViewReactContext from "@Toolkit/ReactClient/Components/ViewContext/ViewReactContext";
import ViewContext from "@Toolkit/ReactClient/Components/ViewContext/ViewContext";
import IToolkitViewContextSettings from "@Toolkit/ReactClient/Components/ViewContext/IToolkitViewContextSettings";

function ViewContextProvider<TProps extends IToolkitViewContextSettings = IToolkitViewContextSettings>(props: React.PropsWithChildren<TProps>) {
    const parent = useContext(ViewReactContext);
    const {children, ...rest} = props;
    const value = useMemo(() => new ViewContext(parent, rest), [parent, Object.values(rest)]);
    return (
        <ViewReactContext.Provider value={value}>
            {props.children}
        </ViewReactContext.Provider>
    );
}

export default ViewContextProvider;