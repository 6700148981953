import SelectBox from "@CommonControls/SelectBox";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import React, { useCallback, useMemo } from "react";
import { components } from "react-select";
import Styles from "./ColorSelector.less";

interface IColorSelectorProps extends ISelectBoxBaseProps<string> {
    items: IColorSelectorItem[];
    customSelectedValueRenderer?: (props: any) => React.ReactNode;
}

export interface IColorSelectorItem extends ISelectBoxItem {
    cssColor: string;
}

function ColorSelector(props: IColorSelectorProps) {

    return (
        <SelectBox
            {...props}
            onRenderMenu={menuProps => <ColorSelectorMenu {...menuProps} onChange={props.onChange} value={props.value} maxMenuWidth={props.maxMenuWidth} />}
            customSelectedValueRenderer={props.customSelectedValueRenderer ?? ColorSelectorSelectedItem}
            searchable={false}
            dropdownIconName="none"
            orderItems={false}
        />
    );
}

function ColorSelectorSelectedItem(props: { data: IColorSelectorItem }) {
    return (
        <div className={combineClasses(Styles.item, Styles.selected)} color={props.data.cssColor}>
            <div className={Styles.itemColor} style={{ backgroundColor: props.data.cssColor }} />
        </div>
    );
}

function ColorSelectorMenu(props: { style: any, maxMenuWidth: string, innerProps: any, options: IColorSelectorItem[], setValue: (value: string) => void, onChange: (value: string) => void, value: any }) {

    const setValue = useCallback((value: string) => {
        props.setValue(value);
        props.onChange(value);
    }, [props.setValue, props.onChange]);

    const menuProps = {
        ...props,
        innerProps: {
            ...props.innerProps,
            style: {
                width: "max-content",
                maxWidth: props.maxMenuWidth
            }
        }
    };

    return (
        <components.Menu {...menuProps}>
            <div className={Styles.colorTable}>
                {props.options.map(o => (
                    <ColorSelectorMenuItem key={o.value} option={o} setValue={setValue} value={props.value} />
                ))}
            </div>
        </components.Menu>
    );
}

function ColorSelectorMenuItem(props: { option: IColorSelectorItem, setValue: (value: string) => void, value: any }) {
    const select = useCallback(() => {
        props.setValue(props.option.value);
    }, [props.setValue, props.option]);

    return (
        <div className={combineClasses(Styles.item, props.value === props.option.value && Styles.active)} color={props.option.cssColor} onClick={select}>
            <div className={Styles.itemColor} style={{ backgroundColor: props.option.cssColor }} />
        </div>
    );
}

export default ColorSelector;