import React from "react";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import * as Ui from "@CommonControls";
import { IFormLogicEditorDialogParams, IFormLogicEditorDialogResult } from "./FormLogicEditorDialogParams";
import { provideFormLogicEditorDialogStore, useFormLogicEditorDialogStore } from "./FormLogicEditorDialogStoreProvider";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MasterDetailDetail from "@CommonControls/Layout/MasterDetailDetail";
import MasterDetailMaster from "@CommonControls/Layout/MasterDetailMaster";
import FormLogicListItemStore from "./FormLogicListItemStore";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import DataGridNumberColumn from "@CommonControls/DataGrid/Column/DataGridNumberColumn";
import DataGridStringColumn from "@CommonControls/DataGrid/Column/DataGridStringColumn";
import { RowId } from "@CommonControls/DataGrid/IDataGridProps";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";

function FormLogicEditorDialog(props: IModalComponentParams<IFormLogicEditorDialogResult> & IFormLogicEditorDialogParams) {

    const store = useFormLogicEditorDialogStore();
    return (
        <Ui.Modal
            isOpen
            onClose={props.onClose as any}
            size="fullscreen"
            closeOnOutsideClick={false}
        >
            <Ui.Modal.Body>
                <MasterDetailLayout
                    selectedItem={store.selectedRow}
                    onClose={store.onBack}
                    master={<FormLogicList />}
                    detail={<FormLogicEditor />}
                />
            </Ui.Modal.Body>
            <Ui.Modal.Footer
                left={(
                    <Ui.Modal.Button automationId="FormLogicCancel_Button" closeModalOnClick text={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.CancelButtonLabel} visualStyle="link" />
                )}
                right={(
                    <Ui.Modal.Button automationId="FormLogicSave_Button" text={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.OkButtonLabel} onClick={store.save} />
                )} />
        </Ui.Modal >
    );
}


const FormLogicEditor = State.observer(() => {
    const store = useFormLogicEditorDialogStore();
    return (
        <MasterDetailDetail
            title={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.FormLogic.FormLogicEditor.DetailTitle}
            passStateToChild={false}
            toolbar={(
                <>

                    <Ui.Button
                        iconName="trash"
                        visualStyle="negative-standard"
                        onClick={store.deleteSelectedRow}
                        automationId="deleteFormLogic"
                    />
                </>
            )}
        >
            <Ui.CodeEditor schema={{ uri: `FormLogic_${store.selectedRow?.id}`, schema: null }} store={store.codeEditorStore} />
        </MasterDetailDetail>
    );
});


const FormLogicList = State.observer(() => {
    const store = useFormLogicEditorDialogStore();

    const renderValue = (value: string) => {
        return (
            <Ui.Flex style={{ justifyContent: "flex-end" }}>
                <Ui.Flex.Item >
                    <Ui.Button iconName="angleRight" automationId="formLogicEditor_selectButton" onClick={store.select(value)} />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    };
    const getPropertyIdentifierForRow = (value: any, row: any, rowId: RowId, rowIndex: number, isUnderEditing: boolean) => {
        return `row_${rowId}_ColumnWidth`;
    };

    return (
        <MasterDetailMaster
            title={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.FormLogic.FormLogicEditor.MasterTitle}
            passStateToChild={false}
            toolbar={(
                <>
                    <Ui.Button visualStyle="primary" text={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.FormLogic.FormLogicEditor.AddNewFormLogicLabel} iconName="plus" automationId="addNewFormLogic" onClick={store.createNewFormLogic} />
                </>
            )}
        >
            <Ui.DataGrid
                automationId="formLogic_Datagrid"
                rowUnderEditing="all"
                rowId={"id"}
                dataSource={store.dataSource}
                hidePager={true}
                isSelectable
                selectedRow={store.selectedRow}
            >
                <DataGridStringColumn
                    nullValue={""}
                    width="200px"
                    clearable={false}
                    header={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.FormLogic.FormLogicEditor.NameColumnTitle}
                    isObserver
                    dataGetter={"formLogic.logicName"}
                    dataSetter={"formLogic.setFormLogicName"}
                    propertyIdentifierFactory={getPropertyIdentifierForRow}
                />
                <DataGridNumberColumn
                    nullValue={0}
                    width="70px"
                    clearable={false}
                    header={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.FormLogic.FormLogicEditor.OrderColumnTitle}
                    isObserver
                    dataGetter={"order"}
                    dataSetter={"setOrder"}
                />
                <DataGridColumn
                    dataGetter={"id"}
                    onRenderCellValue={renderValue}
                />
            </Ui.DataGrid>
        </MasterDetailMaster>
    );
});

export default provideFormLogicEditorDialogStore(FormLogicEditorDialog);
