import ToolbarLayout from "@CommonControls/ToolbarLayout/ToolbarLayout";
import OutpatientHeader from "@HisPlatformControls/OutpatientHeader";
import CareActivityContextProvider from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextProvider";
import PatientContextProvider from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextProvider";
import CareScreenContextData from "@HisPlatform/Services/Definition/ActionProcessing/CareScreenContextData";
import PatientScreenContextData from "@HisPlatform/Services/Definition/ActionProcessing/PatientScreenContextData";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import React, { useContext } from "react";
import ScreenSidebarWrapper from "./ScreenSidebarWrapper";
import { useScreenStateContext } from "./ScreenStateContext";
import { PatientReactContext } from "@HisPlatform/Model/DomainModel/PatientContext/PatientContext";
import { CareActivityReactContext } from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContext";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { ISidebarPropsBase, IToolbarPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import ScreenToolbarWrapper from "@Toolkit/ReactClient/Components/Screen/ScreenToolbarWrapper";

interface IScreenContextWrapperProps {
    sidebarComponent?: React.ComponentType<ISidebarPropsBase<ShowScreenFrontendActionBase>>;
    topToolbarComponent?: React.ComponentType<IToolbarPropsBase<ShowScreenFrontendActionBase>>;
}

export default function ScreenContextWrapper(props: React.PropsWithChildren<IScreenContextWrapperProps>) {
    const store = useScreenStateContext();
    const parentPatientContext = useContext(PatientReactContext);
    const parentCareActivityContext = useContext(CareActivityReactContext);

    if (store?.action?.displayMode === ScreenDisplayMode.Modal) {

        if (!store?.screenContextData) {
            return (
                <>
                    {props.children}
                </>
            );
        }

        if (store?.screenContextData instanceof PatientScreenContextData) {
            return (
                <PatientContextProvider patientId={store.screenContextData.patientId}>
                    {props.children}
                </PatientContextProvider>
            );
        }

        if (store?.screenContextData instanceof CareScreenContextData) {
            return (
                <CareActivityContextProvider careActivityId={store.screenContextData.careActivityId}>
                    <PatientContextProvider>
                        {props.children}
                    </PatientContextProvider>
                </CareActivityContextProvider>
            );
        }

        throw new Error("Unkown ScreenContextData");
    }

    if (!store?.screenContextData) {
        return (
            <ScreenSidebarWrapper sidebarComponent={props.sidebarComponent}>
                <ScreenToolbarWrapper topToolbarComponent={props.topToolbarComponent}>
                    {props.children}
                </ScreenToolbarWrapper>
            </ScreenSidebarWrapper>
        );
    }

    if (store?.screenContextData instanceof PatientScreenContextData) {
        if (parentPatientContext && ValueWrapper.equals(store?.screenContextData.patientId, parentPatientContext.patientId)) {
            return (
                <ScreenSidebarWrapper sidebarComponent={props.sidebarComponent}>
                    <ScreenToolbarWrapper topToolbarComponent={props.topToolbarComponent}>
                        {props.children}
                    </ScreenToolbarWrapper>
                </ScreenSidebarWrapper>
            );
        }

        return (
            <PatientContextProvider patientId={store.screenContextData.patientId}>
                <ScreenSidebarWrapper sidebarComponent={props.sidebarComponent}>
                    <ScreenToolbarWrapper topToolbarComponent={props.topToolbarComponent}>
                        {props.children}
                    </ScreenToolbarWrapper>
                </ScreenSidebarWrapper>
            </PatientContextProvider>
        );
    }

    if (store?.screenContextData instanceof CareScreenContextData) {

        if (parentPatientContext && parentCareActivityContext && ValueWrapper.equals(store?.screenContextData.careActivityId, parentCareActivityContext.careActivityId)) {
            return (
                <ScreenSidebarWrapper sidebarComponent={props.sidebarComponent}>
                    <ScreenToolbarWrapper topToolbarComponent={props.topToolbarComponent}>
                        {props.children}
                    </ScreenToolbarWrapper>
                </ScreenSidebarWrapper>
            );
        }

        return (
            <CareActivityContextProvider careActivityId={store.screenContextData.careActivityId}>
                <PatientContextProvider>
                    <ScreenSidebarWrapper sidebarComponent={props.sidebarComponent}>
                        <ScreenToolbarWrapper topToolbarComponent={props.topToolbarComponent}>
                            {props.children}
                        </ScreenToolbarWrapper>
                    </ScreenSidebarWrapper>
                </PatientContextProvider>
            </CareActivityContextProvider>
        );
    }

    throw new Error("Unkown ScreenContextData");
}