import EhiCovidStatusReportApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCovidStatusReportApiAdapter";
import EhiCovidStatusReportStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiCovidStatusReportStore";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import EhiCovidStatusReportId from "@Primitives/EhiCovidStatusReportId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import _ from "@HisPlatform/Common/Lodash";
import EhiCovidStatusReportDetailPanelView from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidStatusReportWorklistPanel/EhiCovidStatusReportDetailPanelView";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

interface IEhiCovidStatusReportDetailPanelDependencies {
    ehiCovidStatusReportApiAdapter: EhiCovidStatusReportApiAdapter;
    lockingApiAdapter: LockingApiAdapter;
    dialogService: IDialogService;
    notificationService: INotificationService;
}

export interface IEhiCovidStatusReportDetailPanelProps {
    _dependencies?: IEhiCovidStatusReportDetailPanelDependencies;
    _careActivityId?: CareActivityId;
    ehiCovidStatusReportId: EhiCovidStatusReportId;
    readOnly: boolean;
    onEhiCovidStatusReportCreatedAsync?: (ehiCovidStatusReportId: EhiCovidStatusReportId) => Promise<void>;
    onDeleteEhiCovidStatusReport?: () => void;
}

/** @screen */
@State.observer
class EhiCovidStatusReportDetailPanel extends React.Component<IEhiCovidStatusReportDetailPanelProps> {

    private get ehiCovidStatusReportId() { return this.props.ehiCovidStatusReportId; }
    private get careActivityId() { return this.props._careActivityId; }
    private get ehiCovidStatusReportApiAdapter() { return this.props._dependencies.ehiCovidStatusReportApiAdapter; }
    private get lockingApiAdapter() { return this.props._dependencies.lockingApiAdapter; }
    private get dialogService() { return this.props._dependencies.dialogService; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get resources() { return StaticHunEHealthInfrastructureCareResources.EhiCovidStatusReportDetailPanel; }

    @State.observable.ref private store: EhiCovidStatusReportStore = null;

    @State.computed private get isNew() { return this.ehiCovidStatusReportId.value === "new"; }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IEhiCovidStatusReportDetailPanelProps) {
        if (prevProps.ehiCovidStatusReportId.value !== this.props.ehiCovidStatusReportId.value) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.action.bound
    private async loadAsync(forceReleaseLockNeeded: boolean = false) {
        if (forceReleaseLockNeeded && !!this.store?.lockInfo?.preventingLockId) {
            await this.lockingApiAdapter.forceReleaseLockAsync(this.store.lockInfo.preventingLockId);
        }

        let store: EhiCovidStatusReportStore = null;
        if (this.isNew) {
            store = new EhiCovidStatusReportStore(true);
            store.id = new EhiCovidStatusReportId("new");
            store.statusReportAt = DateTimeService.now();
        } else {
            store = await this.ehiCovidStatusReportApiAdapter.getByIdAsync(this.ehiCovidStatusReportId, true);
        }
        this.setStore(store);
    }

    @State.action.bound
    private setStore(newValue: EhiCovidStatusReportStore) {
        this.store = newValue;
        this.store.takeSnapshot();
    }

    @State.bound
    private async navigateAwayAsync() {
        if (this.store?.isMutable === true && this.isUnsavedOrDirty()) {
            const dialogResult = await this.dialogService.confirmIfNotSaved(StaticCareResources.Common.Dialog.ConfirmationTitle, StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);
            if (dialogResult.resultCode === DialogResultCode.Yes) {
                await this.saveStoreAsync(false);
                await this.releaseLockAsync();
            } else if (dialogResult.resultCode === DialogResultCode.No) {
                await this.releaseLockAsync();
                return true;
            }
            return false;
        }
        await this.releaseLockAsync();
        return true;
    }

    @State.action.bound
    private async releaseLockAsync() {
        if (this.store.isMutable && this.store.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld) {
            await this.lockingApiAdapter.releaseLockAsync(this.store.lockInfo.lockId);
            this.store.releaseLock();
            return true;
        }
        return false;
    }

    @State.bound
    private isUnsavedOrDirty() {
        return !this.store.isPersistedByOperationInfo || this.store.isDirty();
    }

    @State.action.bound
    private async validateAsync(): Promise<IClientValidationResult[]> {
        if (!this.store || !this.store.isMutable) {
            return [];
        }

        const result = await this.saveStoreAsync(true);

        return result.validationResults;
    }

    @State.action.bound
    private async saveStoreAsync(isValidateOnly: boolean) {
        let result: EhiCovidStatusReportStore = null;

        if (this.isNew) {
            result = await this.ehiCovidStatusReportApiAdapter.createEhiCovidStatusReportAsync(this.store, this.careActivityId, isValidateOnly);
        } else {
            result = await this.ehiCovidStatusReportApiAdapter.updateEhiCovidStatusReportAsync(this.store, isValidateOnly);
        }

        if (!isValidateOnly) {

            const validationResult = _.flatten(result.validationResults?.map(i => i.problems));
            const customValidationResults = validationResult?.filter(i => i.ruleId === "AtLeastOnePropertyShouldBeFilledInAdditionToStatusReportAt");

            if (customValidationResults?.length > 0) {
                await this.dialogService.ok(this.resources.Dialog.ValidationDialogTitle, this.resources.Dialog.ValidationDialogMessage);
            }

            this.replaceStore(result);

            if (result.operationWasSuccessful && result.isPersistedByOperationInfo && this.isNew) {
                await this.props.onEhiCovidStatusReportCreatedAsync?.(result.id);
                return result;
            }
        }

        return result;
    }

    @State.action.bound
    private async deleteAsync() {
        const dialogResult = await this.dialogService.yesNo(StaticWebAppResources.Common.DialogTitle.ConfirmationTitle, this.resources.Dialog.DeleteConfirmationMessage);
        if (dialogResult.resultCode === DialogResultCode.Yes) {
            const response = await this.ehiCovidStatusReportApiAdapter.deleteEhiCovidStatusReportAsync(this.store.id, this.store.rowVersion, this.store.lockInfo?.lockId);

            if (response.operationWasSuccessful && response.isPersistedByOperationInfo && this.props.onDeleteEhiCovidStatusReport) {
                this.props.onDeleteEhiCovidStatusReport();
            }
        }
    }

    @State.action.bound
    private replaceStore(newStore: EhiCovidStatusReportStore) {
        if (newStore.operationWasSuccessful) {
            if (newStore.isPersistedByOperationInfo) {
                newStore.lockInfo = this.store.lockInfo;
                this.setStore(newStore);
                this.notificationService.showSavedSuccessfully();
            } else {
                State.runInAction(() => {
                    this.store.validationResults = newStore.validationResults;
                });
            }
        }
    }

    @State.action.bound
    private async forceLoadAsync() {
        await this.loadAsync(true);
    }

    @State.bound
    private async onSaveAsync() {
        await this.saveStoreAsync(false);
    }

    @State.computed
    private get operationsToChecks() {
        const res = {};

        res["SaveEhiCovidStatusReport"] = async () => {
            if (this.isNew) {
                await this.ehiCovidStatusReportApiAdapter.createEhiCovidStatusReportAsync(this.store, this.careActivityId, false, true);
            } else {
                await this.ehiCovidStatusReportApiAdapter.updateEhiCovidStatusReportAsync(this.store, false, true);
            }
        };

        if (this.store.id && this.store.id.value !== "new") {
            res["DeleteEhiCovidStatusReport"] = async () => {
                await this.ehiCovidStatusReportApiAdapter.deleteEhiCovidStatusReportAsync(this.store.id, this.store.rowVersion, this.store.lockInfo?.lockId, true);
            };
        }

        return res;
    }

    public render() {
        if (!this.store) {
            return null;
        }

        return (
            <EhiCovidStatusReportDetailPanelView
                store={this.store}
                operationsToChecks={this.operationsToChecks}
                onSaveAsync={this.onSaveAsync}
                onDeleteAsync={this.deleteAsync}
                onLockEditClickedAsync={this.forceLoadAsync}
                onValidateAsync={this.validateAsync}
                onNavigateAwayAsync={this.navigateAwayAsync}
                readonly={this.props.readOnly}
            />
        );
    }
}

export default connect(
    EhiCovidStatusReportDetailPanel,
    new DependencyAdapter<IEhiCovidStatusReportDetailPanelProps, IEhiCovidStatusReportDetailPanelDependencies>(c => ({
        ehiCovidStatusReportApiAdapter: c.resolve<EhiCovidStatusReportApiAdapter>("EhiCovidStatusReportApiAdapter"),
        lockingApiAdapter: c.resolve<LockingApiAdapter>("LockingApiAdapter"),
        dialogService: c.resolve<IDialogService>("IDialogService"),
        notificationService: c.resolve<INotificationService>("INotificationService"),
    })),
    new CareActivityContextAdapter<IEhiCovidStatusReportDetailPanelProps>(c => ({
        _careActivityId: c.careActivityId
    }))
);