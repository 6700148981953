enum StatusChangeBlockingData {
    Diagnoses = 0,
    PerformedServices = 1,
    CareDocuments = 2,
    ClinicalData = 3,
    DischargeData = 4,
    ServiceRequests = 5,
    ServiceRequestObservations = 6,
    DocumentTypes = 7,
    Prescriptions = 8,
    PractitionerRecommendations = 9
}

export default StatusChangeBlockingData;