import React from "react";
import IWidgetComponentProps from "@PluginInterface/Dashboard/IWidgetComponentProps";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import OncologyApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/OncologyApiAdapter";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityId from "@Primitives/CareActivityId.g";
import IOncologyDataInfo from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IOncologyDataInfo";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import IOncologyWidgetDisplayData from "@HunEHealthInfrastructurePlugin/Common/Components/Widgets/OncologyWidget/IOncologyWidgetDisplayData";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ConditionId from "@Primitives/ConditionId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import OncologyDataContainer from "@HunEHealthInfrastructurePlugin/Common/Components/Widgets/OncologyWidget/OncologyDataContainer";
import OncologyDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/OncologyDataStore";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import OncologyDataModalParams from "@HunEHealthInfrastructurePlugin/Common/Components/Widgets/OncologyWidget/OncologyDataModalParams";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import CancerClassificationDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CancerClassificationDataStore";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import { VoidSyncEvent } from "ts-events";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import IConditionVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IConditionVersion";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import { withHisErrorBoundary } from "@HisPlatform/Components/HisPlatformControls/HisErrorBoundary/HisErrorBoundary";

interface IOncologyWidgetDependencies {
    apiAdapter: OncologyApiAdapter;
    referenceDataStore: CareReferenceDataStore;
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    localizationService: ILocalizationService;
}

interface IOncologyWidgetProps extends IWidgetComponentProps {
    _dependencies?: IOncologyWidgetDependencies;
    _careActivityId?: CareActivityId;
    _modalService?: IModalService;
    _diagnosisListChangedEvent?: VoidSyncEvent;
}

/** @screen */
@State.observer
class OncologyWidget extends React.Component<IOncologyWidgetProps> {
    private get modalService() { return this.props._modalService!; }
    private get apiAdapter() { return this.props._dependencies.apiAdapter; }
    private get referenceDataStore() { return this.props._dependencies.referenceDataStore; }
    private get hunEhiCareReferenceDataStore() { return this.props._dependencies.hunEhiCareReferenceDataStore; }
    private get localizationService() { return this.props._dependencies.localizationService; }
    @State.observable.ref private data: IOncologyDataInfo[] = null;
    @State.observable.ref private displayData: IOncologyWidgetDisplayData[] = null;
    @State.observable.ref private oncologyDataStore: OncologyDataStore = null;
    @State.observable.ref private condition: IConditionVersion = null;
    @State.observable.ref private cancerClassificationDataStore: CancerClassificationDataStore = null;
    @State.observable private cancerClassificationStoreLabel: string = null;
    @State.observable.ref private isWidgetVisible: boolean = false;
    @State.observable.ref private medicalServiceIdFilter: MedicalServiceId[] = null;

    @State.action.bound
    private async loadAsync() {
        const oncologyDataResponse = await this.apiAdapter.getOncologyInfoDataListAsync(this.props._careActivityId);
        this.data = oncologyDataResponse.value;

        const medicalServiceFilterResponse = await this.apiAdapter.getOncologyRelevantMedicalServiceIdsAsync(this.props._careActivityId);
        this.medicalServiceIdFilter = medicalServiceFilterResponse.value;

        State.runInAction(() => {
            if (this.data.length > 0) {
                this.isWidgetVisible = true;
            }
        });

        if (!!this.isWidgetVisible) {
            await this.referenceDataStore.condition.ensureLoadedAsync(this.data.map(d => {
                return {
                    id: d.conditionId,
                    validOn: LocalDate.today()
                } as IEntityVersionSelector<ConditionId>;
            }));
            const getOncologyDataResponse = await this.apiAdapter.getOncologyDataByIdsAsync(this.data.map(d => d.id));
            const oncologyData = getOncologyDataResponse.value;
            await this.referenceDataStore.condition.ensureLoadedAsync(oncologyData.map(d => {
                return {
                    id: d.morphology,
                    validOn: LocalDate.today()
                } as IEntityVersionSelector<ConditionId>;
            }));
            await this.hunEhiCareReferenceDataStore.cancerClassifications.ensureLoadedAsync(oncologyData.map(d => {
                return d.cancerClassificationId;
            }));

            State.runInAction(() => {
                this.displayData = this.data.map(d => {
                    const oncologyDataStore = oncologyData.find(store => wrappedValuesAreEquals(d.id, store.id));
                    const cancerClassificationDataStore = this.hunEhiCareReferenceDataStore.cancerClassifications.get(oncologyDataStore.cancerClassificationId);
                    const conditionVersion = this.referenceDataStore.condition.get({ id: d.conditionId, validOn: LocalDate.today() } as IEntityVersionSelector<ConditionId>);
                    return {
                        condition: conditionVersion,
                        oncologyDataStore: oncologyDataStore,
                        cancerClassificationDataStore: cancerClassificationDataStore,
                        diagnosisDate: isNullOrUndefined(d.dateOfDiagnosis) ? "N/A" : this.localizationService.localizeDate(d.dateOfDiagnosis)
                    } as IOncologyWidgetDisplayData;
                });
            });
        }

    }

    public componentDidMount() {
        this.loadSync();
        this.props._diagnosisListChangedEvent?.attach(this.loadSync);
    }

    public componentWillUnmount() {
        this.props._diagnosisListChangedEvent?.detach(this.loadSync);
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);

    @State.bound
    private loadSync() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.action.bound
    private showModal(dataStore: OncologyDataStore, condition: IConditionVersion, cancerClassificationDataStore: CancerClassificationDataStore) {
        this.oncologyDataStore = dataStore;
        this.condition = condition;
        this.cancerClassificationDataStore = cancerClassificationDataStore;
        this.cancerClassificationStoreLabel = this.getTnmLabel(this.oncologyDataStore);
        this.modalService.showModal(new OncologyDataModalParams(
            this.oncologyDataStore,
            this.condition,
            this.oncologyDataStore.careActivityId,
            this.cancerClassificationDataStore,
            this.cancerClassificationStoreLabel,
            this.medicalServiceIdFilter
        ));
    }

    private getTnmLabel(dataStore: OncologyDataStore) {
        if (!dataStore) {
            return "";
        }
        const tumor = dataStore.cancerClassificationTumorId && this.cancerClassificationDataStore.tumorList.find(item => wrappedValuesAreEquals(dataStore.cancerClassificationTumorId, item.id));
        const node = dataStore.cancerClassificationNodeId && this.cancerClassificationDataStore.nodeList.find(item => wrappedValuesAreEquals(dataStore.cancerClassificationNodeId, item.id));
        const metastasis = dataStore.cancerClassificationMetastasisId && this.cancerClassificationDataStore.metastasisList.find(item => wrappedValuesAreEquals(dataStore.cancerClassificationMetastasisId, item.id));
        return (tumor?.code || "") + (node?.code || "") + (metastasis?.code || "") || "n/a";
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return null;
        }

        if (!this.isWidgetVisible) {
            return null;
        }

        return (
            <Ui.Dashboard.Widget
                name={this.props.name}
                title={StaticProductivityResources.Widgets.OncologyWidget.Title}
                isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                isCollapsible
                containerHeight={this.props.configuration.InitialLayout.Height}
                icon={"oncology"}
                automationId="OncologyWidget"
            >
                <>
                    {this.displayData && this.displayData.map(d =>
                        <OncologyDataContainer
                            key={d.condition.code}
                            data={d}
                            openModal={this.showModal}
                        />
                    )}
                </>
            </Ui.Dashboard.Widget>
        );
    }
}

export default connect(
    withLoadingBoundary(withHisErrorBoundary(OncologyWidget)),
    new DependencyAdapter<IOncologyWidgetProps, IOncologyWidgetDependencies>(c => ({
        apiAdapter: c.resolve("OncologyApiAdapter"),
        referenceDataStore: c.resolve("CareReferenceDataStore"),
        hunEhiCareReferenceDataStore: c.resolve("HunEhiCareReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService")
    })),
    new CareActivityContextAdapter<IOncologyWidgetProps>(c => ({
        _careActivityId: c.careActivityId
    })),
    new HisModalServiceAdapter(),
    new CareActivityContextAdapter((c, props) => ({
        _diagnosisListChangedEvent: c.diagnosisListChangedEvent
    }))
);