import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { IEntityConstraints } from "@Toolkit/ReactClient/Components/ValidationBoundary/IEntityConstraints";
import { isNullOrUndefined, emptyArray, arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ValidationResultsBuilder, { IPropertyIdentifiers } from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationResultsBuilder";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientSideValidator";
import _ from "@HisPlatform/Common/Lodash";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";

export interface IValidatorMessages {
    shouldBeFilled?: string;
    shouldNotBeShorterThan?: string;
    shouldNotBeLongerThan?: string;
}

export default class ClientSideValidator implements IClientSideValidator {

    @State.observable.ref public validationResults: IClientValidationResult[] = emptyArray;
    public validateAllEvent = new TypedEvent();

    private readonly formValues = new Map<string, any>();

    constructor(
        private readonly constraints: IEntityConstraints,
        private readonly messages: IValidatorMessages = null,
        private readonly validateOnChange: boolean = false
    ) { }

    @State.bound
    public validateAsync(): Promise<IClientValidationResult[]> {
        return Promise.resolve(this.validate());
    }

    @State.bound
    public changeProperty(fullPropertyPath: string, value: any) {
        this.formValues.set(fullPropertyPath, value);

        if (this.validateOnChange) {
            this.validateDebounced();
        }
    }

    public validateDebounced = _.debounce(() => {
        this.validate();
    }, 500);

    @State.bound
    public isRequired(fullPropertyPath: string) {
        const constraints = this.getPropertyConstraints(fullPropertyPath);
        return constraints && constraints.isRequired === true;
    }

    @State.action.bound
    public isValid() {
        this.validateAllEvent.emit();
        this.validationResults = this.validate();
        return this.validationResults.length === 0;
    }

    private validate() {
        const validationResultsBuilder = new ValidationResultsBuilder();

        Array.from(this.formValues.keys()).forEach(fullPropertyPath => {
            const propertyConstraints = this.getPropertyConstraints(fullPropertyPath);
            const propertyPathParts = fullPropertyPath.split(".");
            const entityName = propertyPathParts[0];
            const remainingPropertyPath = propertyPathParts.slice(1).join(".");
            const value = this.formValues.get(fullPropertyPath);

            const propertyIdentifiers: IPropertyIdentifiers = {
                entityName: entityName,
                entityPath: remainingPropertyPath
            };

            if (propertyConstraints?.isRequired === true) {
                if (isNullOrUndefined(value) || value === "") {
                    validationResultsBuilder.addProblem(
                        propertyIdentifiers,
                        "ShouldBeFilled",
                        "error",
                        null,
                        null,
                        this.messages?.shouldBeFilled
                    );
                }
            }

            if (!isNullOrUndefined(propertyConstraints?.minLength)) {
                if (!isNullOrUndefined(value) && value !== "" && (value as string).length < propertyConstraints!.minLength) {
                    validationResultsBuilder.addProblem(
                        propertyIdentifiers,
                        "ShouldNotBeShorterThan",
                        "error",
                        null,
                        null,
                        formatStringWithObjectParams(this.messages?.shouldNotBeShorterThan, { MinLength: propertyConstraints!.minLength.toString() })
                    );
                }
            }

            if (!isNullOrUndefined(propertyConstraints?.maxLength)) {
                if (!isNullOrUndefined(value) && value !== "" && (value as string).length > propertyConstraints!.maxLength) {
                    validationResultsBuilder.addProblem(
                        propertyIdentifiers,
                        "ShouldNotBeLongerThan",
                        "error",
                        null,
                        null,
                        formatStringWithObjectParams(this.messages?.shouldNotBeLongerThan, { MaxLength: propertyConstraints!.maxLength.toString() })
                    );
                }
            }

            if (!arrayIsNullOrEmpty(propertyConstraints?.customEvaluators)) {
                propertyConstraints.customEvaluators.forEach(evaluator => {
                    evaluator(validationResultsBuilder, propertyIdentifiers, value);
                });
            }
        });

        return validationResultsBuilder.getValidationResults();
    }

    private getPropertyConstraints(fullPropertyPath: string) {
        if (!!this.constraints && !!fullPropertyPath) {
            const propertyPathParts = fullPropertyPath.split(".");

            if (propertyPathParts.length >= 2) {
                const entityName = propertyPathParts[0];
                const remainingPropertyPath = propertyPathParts.slice(1).join(".").replace(/\[\d+\]/, "");

                return this.constraints[entityName][remainingPropertyPath];
            }
        }
        return null;
    }
}
