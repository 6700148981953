exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DocumentTemplateManagementMasterDetailPanel_header-container_1s4wN {\n  height: 44px;\n  vertical-align: middle;\n}\n.DocumentTemplateManagementMasterDetailPanel_header-container_1s4wN .DocumentTemplateManagementMasterDetailPanel_header-button_333YL {\n  margin: 10px 4px;\n}\n", ""]);

// exports
exports.locals = {
	"header-container": "DocumentTemplateManagementMasterDetailPanel_header-container_1s4wN",
	"headerContainer": "DocumentTemplateManagementMasterDetailPanel_header-container_1s4wN",
	"header-button": "DocumentTemplateManagementMasterDetailPanel_header-button_333YL",
	"headerButton": "DocumentTemplateManagementMasterDetailPanel_header-button_333YL"
};