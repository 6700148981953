import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalServiceFinancingId from "@Primitives/MedicalServiceFinancingId.g";

export default class FinancedServiceWithQuantityStore {
    @State.observable.ref public extensionData: any = {};
    @State.observable public quantity: number = 1;

    constructor(
        public financingId: MedicalServiceFinancingId,
        public financedServiceId: EntityVersionSelector<FinancedServiceId>,
        quantity: number,
        extensionData: any
    ) {
        this.extensionData = extensionData;
        this.quantity = quantity;
    }

    @State.action.bound
    public setQuantity(newValue: number) {
        this.quantity = newValue;
    }
}
