import PatientId from "@Primitives/PatientId.g";
import * as CareProxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import ConditionStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/ConditionStatementStore";
import ReferencedConditionAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/ReferencedConditionAssignment";
import CustomConditionAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/CustomConditionAssignment";
import ConditionAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/ConditionAssignmentBase";
import ConditionStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/ConditionStatementHistoryItem";
import { getHistoryItemDetailDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalConditionDtoMapper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import FormerConditionDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/FormerConditionDetail";

export function getAddConditionStatementControllerDto(source: ConditionStatementStore, patientId: PatientId, isValidateOnly: boolean) {
    return new CareProxy.AddConditionStatementControllerDto({
        patientId: patientId,
        historyItemDtos: [getConditionStatementHistoryItemDto(source.latestHistoryItem as ConditionStatementHistoryItem)],
        conditionAssignment:  getConditionAssignmentDto(source.conditionAssignment),
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData
    });
}

export function getUpdateConditionStatementControllerDto(source: ConditionStatementStore, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.UpdateConditionStatementControllerDto({
        conditionStatementId: source.id,
        historyItemDtos: [getConditionStatementHistoryItemDto(source.latestHistoryItem as ConditionStatementHistoryItem)],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteConditionStatementHistoryItemControllerDto(source: ConditionStatementStore, versionNumber: number, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.DeleteConditionStatementHistoryItemControllerDto({
        conditionStatementId: source.id,
        versionNumbers: [versionNumber],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteConditionStatementControllerDto(store: ConditionStatementStore) {
    return new CareProxy.DeleteConditionStatementControllerDto({
        conditionStatementId: store.id,
        lockId: store.lockInfo?.lockId,
        extensionData: store.extensionData,
        isValidateOnly: false,
        releaseLockIfSuccessful: true
    });
}

function getConditionAssignmentDto(source: ConditionAssignmentBase) {
    let dto;

    if (source instanceof ReferencedConditionAssignment) {
        dto = new CareProxy.ReferencedConditionAssignmentDto({ conditionId: source.conditionId });
    } else if (source instanceof CustomConditionAssignment) {
        dto = new CareProxy.CustomConditionAssignmentDto({ name: source.name });
    }

    return dto;
}

function getConditionStatementHistoryItemDto(source: ConditionStatementHistoryItem) {
    return new CareProxy.ConditionStatementHistoryItemDto({
        versionNumber: source.versionNumber,
        index: source.index,
        createdAt: source.createdAt,
        historyItemDetail: getHistoryItemDetailDto(source.historyItemDetail),
        onsetDate: source.onsetDate,
        clinicalStatus: source.clinicalStatus,
        description: source.description,
        formerConditionDetail: getFormerConditionDetailDto(source.formerConditionDetail),
        extensionData: source.extensionData
    });
}

function getFormerConditionDetailDto(source: FormerConditionDetail) {
    return new CareProxy.FormerConditionDetailDto({
        inactivationDate: source.inactivationDate,
        inactivationDescription: source.inactivationDescription
    });
}
