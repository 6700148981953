import StoreBase from "@Toolkit/CommonWeb/Model/StoreBase";
import IOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/IOperationInfo";

export default class SimpleStore<TValue = any> extends StoreBase {
    constructor(
        public value: TValue = null,
        operationInfo: IOperationInfo = null
    ) { 
        super();
        if (operationInfo) {
            this.operationInfo = operationInfo;
        }
    }
}