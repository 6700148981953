import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import * as Ui from "@CommonControls";
import { IDataGridColumnFilterProps, IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import ServiceRequestStatusSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/ServiceRequestManagement/ServiceRequestStatusSelectBox/ServiceRequestStatusSelectBox";
import ServiceRequestStateBadge from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ServiceRequestStateBadge/ServiceRequestStateBadge";
import ServiceRequestState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestState.g";

interface IServiceRequestStatusColumn2Dependencies {
    localizationService: ILocalizationService;
}

interface IServiceRequestStatusColumn2Props extends IDataGridColumnBaseProps {
    _dependencies?: IServiceRequestStatusColumn2Dependencies;
    nonMatchingChildrenInfoFieldName?: string;
    displayMode?: "name" | "badge";
}


const ServiceRequestStatusColumn2: React.FC<IServiceRequestStatusColumn2Props> = (props) => {

    const { children, ...columnProps } = props;

    const renderNonMatchingChildStatusIndicator = useCallback((row: any) => {
        if (props.nonMatchingChildrenInfoFieldName && row[props.nonMatchingChildrenInfoFieldName]) {
            const nonMatchInfo = row[props.nonMatchingChildrenInfoFieldName] as { all: number, nonMatchingCount: number };
            if (nonMatchInfo.nonMatchingCount !== 0) {
                return (
                    <Popper
                        tooltipContent={StaticCareResources.ServiceRequestManagement.ServiceRequestsList.CalculatedStateHint + (` (${nonMatchInfo.all - nonMatchInfo.nonMatchingCount}/${nonMatchInfo.all})`)}
                    ><Ui.Icon iconName="exclamation" /></Popper>
                );
            }
            return undefined;
        }
        return undefined;
    }, [props]);

    const valueRenderer = useCallback((value: any, row: any) => {

        const stateDisplayValue = (() => {
            switch (props.displayMode) {
                case "name":
                    const localized = isNullOrUndefined(value) ? null : props._dependencies.localizationService.localizeEnum(ServiceRequestState[value], "ServiceRequestState");
                    return isNullOrUndefined(localized) ? null : localized.Name;
                case "badge":
                default:
                    return <ServiceRequestStateBadge state={value} />;
            }
        })();

        return (
            <>
                {stateDisplayValue}
                {renderNonMatchingChildStatusIndicator(row)}
            </>
        );
    }, [props]);

    const hintRenderer = useCallback((value: any) => {
        const localizedText = isNullOrUndefined(value) ? null : props._dependencies.localizationService.localizeEnum(ServiceRequestState[value], "ServiceRequestState");
        return localizedText?.Name;
    }, [props]);

    return (
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            onRenderHintValue={hintRenderer}
        />
    );
};

const filterRenderer = (filterProps: IDataGridColumnFilterProps) => {
    return <ServiceRequestStatusSelectBox {...filterProps} hoverOnlyIndicatorIcons />;
};

export default connect(
    ServiceRequestStatusColumn2,
    new DependencyAdapter<IServiceRequestStatusColumn2Props, IServiceRequestStatusColumn2Dependencies>(c => ({
        localizationService: c.resolve("ILocalizationService")
    }))
);