exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AppointmentsMasterDetailPanel_master-container_1tFR- {\n  height: 50%;\n}\n.AppointmentsMasterDetailPanel_additional-content-container_rkAG3 {\n  border-bottom: 8px solid #f3f3f4;\n  padding-top: 6px;\n  padding-left: 6px;\n}\n", ""]);

// exports
exports.locals = {
	"master-container": "AppointmentsMasterDetailPanel_master-container_1tFR-",
	"masterContainer": "AppointmentsMasterDetailPanel_master-container_1tFR-",
	"additional-content-container": "AppointmentsMasterDetailPanel_additional-content-container_rkAG3",
	"additionalContentContainer": "AppointmentsMasterDetailPanel_additional-content-container_rkAG3"
};