import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as HisUi from "@HisPlatformControls";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IRatDevice from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IRatDevice";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import RatDeviceApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/RatDeviceApiAdapter";
import IRatDeviceReferenceDataResolver from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Services/Definition/EhiMedicalConditionDataResolver/IRatDeviceReferenceDataResolver";
import RatDeviceId from "@Primitives/RatDeviceId.g";

interface IRatDeviceCodeSelectorDependencies {
    ratDeviceApiAdapter: RatDeviceApiAdapter;
    ratDeviceReferenceDataResolver: IRatDeviceReferenceDataResolver;
}

interface IRatDeviceCodeSelectorProps extends ICodeSelectorCommonProps<RatDeviceId> {
    _dependencies?: IRatDeviceCodeSelectorDependencies;
}

@State.observer
class RatDeviceCodeSelector extends React.Component<IRatDeviceCodeSelectorProps> {

    private get dependencies() { return this.props._dependencies; }
    private get apiAdapter() { return this.dependencies.ratDeviceApiAdapter; }
    private get ratDeviceReferenceDataResolver() { return this.dependencies.ratDeviceReferenceDataResolver; }

    @State.bound
    public async getDisplayValueAsync(value: RatDeviceId) {
        return await this.ratDeviceReferenceDataResolver.getNameAsync(value);
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const results = await this.apiAdapter.searchRatDevices(
            text,
            10
        );

        if (results.operationWasSuccessful) {
            const ratDevices = results.value.map(x => {
                return x.id;
            });

            await this.ratDeviceReferenceDataResolver.ensureLoadedAsync(ratDevices);
            return ratDevices;
        }
        return null;
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState) {
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;

        const results = await this.apiAdapter.complexSearchRatDevices(
            filterText,
            customOrdering,
            paging);

        await this.ratDeviceReferenceDataResolver.ensureLoadedAsync(results.value.items.map(value => value.id));

        return results.value;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: IRatDevice) {
        this.props.onChange(item.id);
    }

    @State.bound
    private onComplexSearchMultiSelect(items: IRatDevice[]) {
        this.props.onChange(items.map(item => item.id));
    }
    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticHunEHealthInfrastructureCareResources.RatDevice.Title,
            codeGetter: "code",
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect,
            hideAllgroup: true
        };
        return (
            <HisUi.UniversalCodeSelector
                {...props}
            />
        );
    }
}

export default connect(
    RatDeviceCodeSelector,
    new DependencyAdapter<IRatDeviceCodeSelectorProps, IRatDeviceCodeSelectorDependencies>((container) => {
        return {
            ratDeviceApiAdapter: container.resolve("RatDeviceApiAdapter"),
            ratDeviceReferenceDataResolver: container.resolve("IRatDeviceReferenceDataResolver"),
        };
    })
);
