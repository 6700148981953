import ApiBusinessError from "@Toolkit/CommonWeb/ApiAdapter/ApiBusinessError";
import UnauthorizedOperationBusinessError from "@Toolkit/CommonWeb/Model/UnauthorizedOperationBusinessError";
import IPermissionCheckContextModel from "@Toolkit/ReactClient/Components/PermissionCheckContext/IPermissionCheckContextModel";
import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export interface IPermissionCheckOperation {
    [operation: string]: (...args: any) => Promise<any>;
}

class PermissionCheckContext {
    @State.observable private _parentCheckedPermissions: IPermissionCheckContextModel[] = [];
    @State.observable private _checkedPermissions: IPermissionCheckContextModel[] = [];

    @State.computed public get checkedPermissions() {
        const result = new Map<string, IPermissionCheckContextModel>();
        this._parentCheckedPermissions.forEach(p => result.set(p.operationName, p));
        this._checkedPermissions.forEach(p => result.set(p.operationName, p));
        return Array.from(result.values());
    }

    @State.action public setAlreadyCheckedPermissions(alreadyCheckedPermissions: IPermissionCheckContextModel[]) {
        this._parentCheckedPermissions = [...alreadyCheckedPermissions];
    }

    @State.action private setCheckedPermissions(checkedPermissions: IPermissionCheckContextModel[]) {
        this._checkedPermissions = checkedPermissions;
    }

    @State.action
    public async checkPermissionsAsync(operationsToCheck: IPermissionCheckOperation) {
        const res = [];
        for (const operation of Object.keys(operationsToCheck)) {
            try {
                const alreadyChecked = this.checkedPermissions.find(p => p.operationName === operation);
                if (alreadyChecked) {
                    res.push(alreadyChecked);
                    continue;
                }

                await operationsToCheck[operation]();
                res.push({ operationName: operation, isAuthorized: true });
            } catch (err) {
                if (err instanceof ApiBusinessError && err.error instanceof UnauthorizedOperationBusinessError) {
                    res.push({ operationName: operation, isAuthorized: false });
                } else {
                    throw err;
                }
            }
        }

        this.setCheckedPermissions(res);
    }
}

export default PermissionCheckContext;