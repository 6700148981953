import ICareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/ICareActivityStore";
import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import * as BffProxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import Di from "@Di";

type ResponseType = Proxy.GetCareActivityQueryResponse
                    | Proxy.CareActivityDto
                    | BffProxy.GetServiceRequestScreenDataCommandResponse;

@Di.injectable()
export default class CareActivityStoreMapper extends EntityStoreMapper<Proxy.CareActivityDto, ICareActivityStore> {
    protected storeEntityIdType = CareActivityId;

    protected applyToStoreCore(target: ICareActivityStore, response: ResponseType) {
        if (!response) {
            return;
        }

        let careActivity = null;
        if (response instanceof Proxy.GetCareActivityQueryResponse) {
            careActivity = response.careActivity;
        } else if (response instanceof BffProxy.GetServiceRequestScreenDataCommandResponse) {
            careActivity = response.starterCareActivity;
        }

        if (!!careActivity) {
            target.id = careActivity.id;
            target.patientId = careActivity.patientId;
            target.pointOfCareId = careActivity.pointOfCareId;
            target.state = careActivity.state;
        } else if (response instanceof Proxy.CareActivityDto || response instanceof BffProxy.CareActivityDto) {
            target.id = response.id;
            target.patientId = response.patientId;
            target.pointOfCareId = response.pointOfCareId;
            target.state = response.state;
        }
    }
}