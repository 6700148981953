import React from "react";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrWhiteSpace } from "@Toolkit/CommonWeb/NullCheckHelpers";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";

interface ISearchPatientMenuDependencies {
    globalRoutingStore: GlobalRoutingStore;
}

interface ISearchPatientMenuProps {
    _dependencies?: ISearchPatientMenuDependencies;
}

@State.observer
class SearchPatientMenu extends React.Component<ISearchPatientMenuProps> {

    private get globalRoutingStore() { return this.props._dependencies.globalRoutingStore; }

    @State.observable.ref private searchBoxValue: string = null;

    @State.action.bound
    private onSearchBoxChange(value: string) {
        this.searchBoxValue = value;
    }

    @State.action.bound
    private search() {
        this.navigateAndSearch("false");
    }
    @State.action.bound
    private luckySearch() {
        this.navigateAndSearch("true");
    }

    @State.action.bound
    private navigateAndSearch(isLucky: string) {
        const params = {
            restoreFilter: "false"
        };

        if (!isNullOrWhiteSpace(this.searchBoxValue)) {
            params["quickSearchValue"] = this.searchBoxValue;
            params["luckyMode"] = isLucky;
        }

        this.globalRoutingStore.push(ApplicationRoutes.searchPatient.makeRoute(null, params));
        this.searchBoxValue = null;
    }

    public render() {
        return (
            <Ui.NavBar.Item automationId="searchPatient" permissionCheckOperationNames="PatientSearch" permissionDeniedStyle="invisible">
                <Ui.SearchBox.Controlled
                    value={this.searchBoxValue}
                    onChange={this.onSearchBoxChange}
                    onEnterKeyDown={this.luckySearch}
                    onIconClick={this.search}
                    placeholder={StaticWebAppResources.Navbar.SearchBox.Placeholder}
                    automationId="searchPatient"
                    style={{ marginTop: -2 }}
                    stopClickPropagation={true}
                    iconName="find_patient"
                    iconVisualStyle="white"
                    size="compact"
                    isRequired
                />
            </Ui.NavBar.Item>
        );
    }
}

export default connect(
    SearchPatientMenu,
    new DependencyAdapter<ISearchPatientMenuProps, ISearchPatientMenuDependencies>(c => ({
        globalRoutingStore: c.resolve("GlobalRoutingStore")
    }))
);
