import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { ExtensibleEnumSelectBox } from "@HisPlatform/Components/HisPlatformControls";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatform/Components/HisPlatformControls/ExtensibleEnumSelectBox";

interface IEquipmentSupportTypeSelectBoxDependencies {
    medicationRequestReferenceDataStore: MedicationRequestReferenceDataStore;
}

interface IEquipmentSupportTypeSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IEquipmentSupportTypeSelectBoxDependencies;
}

@State.observer
class EquipmentSupportTypeSelectBox extends React.Component<IEquipmentSupportTypeSelectBoxProps> {

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.props._dependencies.medicationRequestReferenceDataStore.equipmentSupportType}
            />
        );
    }
}

export default connect(
    EquipmentSupportTypeSelectBox,
    new DependencyAdapter<IEquipmentSupportTypeSelectBoxProps, IEquipmentSupportTypeSelectBoxDependencies>(c => ({
        medicationRequestReferenceDataStore: c.resolve("MedicationRequestReferenceDataStore")
    }))
);