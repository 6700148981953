exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".UrgencyColumn_main_Gwdsd {\n  display: inline-block;\n  width: 22px;\n  height: 22px;\n  padding: 0;\n  line-height: 22px;\n}\n.UrgencyColumn_normal_XZhdi {\n  background-color: #e8e8e8;\n}\n.UrgencyColumn_urgent_TbNPO {\n  background-color: #ddc00d;\n  color: white;\n}\n.UrgencyColumn_emergency_1XE9n {\n  background-color: #ce5374;\n  color: white;\n}\n", ""]);

// exports
exports.locals = {
	"main": "UrgencyColumn_main_Gwdsd",
	"normal": "UrgencyColumn_normal_XZhdi",
	"urgent": "UrgencyColumn_urgent_TbNPO",
	"emergency": "UrgencyColumn_emergency_1XE9n"
};