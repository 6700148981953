import React from "react";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import CareActivityBasedWorklistEntityType from "@Primitives/CareActivityBasedWorklistEntityType";

function CareActivityBasedWorklistEntityTypeSelectBox(props: IUniversalEnumSelectorBaseProps): React.ReactElement {
    return (
        <UniversalEnumSelector
            {...props}
            enumOrigin="server"
            enumName={"CareActivityBasedWorklistEntityType"} 
            enumType={CareActivityBasedWorklistEntityType}
        />
    );
}

(CareActivityBasedWorklistEntityTypeSelectBox as React.FC<IUniversalEnumSelectorBaseProps>).defaultProps = {
    displayMode: "groupedRadioButtons"
};

export default CareActivityBasedWorklistEntityTypeSelectBox;