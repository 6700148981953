import React from "react";
import PrescriptionToEhiDialogView from "./PrescriptionToEhiDialogView";
import { IModalComponentParams, IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IPrescriptionToEhiDialogParams } from "./PrescriptionToEhiDialogParams";

interface IPrescriptionToEhiDialogProps extends IModalComponentParams, IPrescriptionToEhiDialogParams {
}

@State.observer
export default class PrescriptionToEhiDialog extends React.Component<IPrescriptionToEhiDialogProps> {

    @State.bound
    private chooseDigital() {
        this.props.onClose("digital");
    }

    @State.bound
    private choosePaper() {
        this.props.onClose("paper");
    }

    public render() {
        return (
            <PrescriptionToEhiDialogView
                ehiPrescriptionError={this.props.ehiPrescriptionError}
                onChoosePaper={this.choosePaper}
                onChooseDigital={this.chooseDigital}
            />
        );
    }
}