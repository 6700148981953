import IStatusChangeBlockingReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/IStatusChangeBlockingReason";
import OutpatientTreatmentRoutes from "@HisPlatform/Components/Pages/OutpatientTreatment/OutpatientTreatmentRoutes";
import IStateChangeBlockingRegistry from "@PluginInterface/BoundedContexts/Care/CareRegister/StateChange/IStateChangeBlockingRegistry";
import CareActivityId from "@Primitives/CareActivityId.g";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import ResourceId from "@Primitives/ResourceId.g";
import StateChangeBlockingData from "@Primitives/StateChangeBlockingData.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import DocumentManagementReferenceDataStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentManagementReferenceDataStore";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import ShowDiagnosisScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowDiagnosisScreenAction.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";

export default function configureStateChangeBlockings(stateChangeBlockingRegistry: IStateChangeBlockingRegistry, documentReferenceDataStore: DocumentManagementReferenceDataStore) {
    stateChangeBlockingRegistry.register(
        StateChangeBlockings.careDocuments,
        (routingController: IRoutingController<any>, ad: IActionDispatcher, careActivityId: CareActivityId) => navigateToDocumentTypes(routingController, careActivityId),
        (localizationService: ILocalizationService) => getTitle(localizationService, StateChangeBlockings.careDocuments),
        (localizationService: ILocalizationService, reason: IStatusChangeBlockingReason) => getDocumentTypesMessage(localizationService, reason, documentReferenceDataStore));

    stateChangeBlockingRegistry.register(
        StateChangeBlockings.clinicalData,
        (routingController: IRoutingController<any>, ad: IActionDispatcher, careActivityId: CareActivityId) => navigateToClinicalData(routingController, careActivityId),
        (localizationService: ILocalizationService) => getTitle(localizationService, StateChangeBlockings.careDocuments),
        (localizationService: ILocalizationService, reason: IStatusChangeBlockingReason) => getGenericMessage(localizationService, reason));

    stateChangeBlockingRegistry.register(
        StateChangeBlockings.diagnoses,
        (routingController: IRoutingController<any>, ad: IActionDispatcher, careActivityId: CareActivityId) => navigateToDiagnosesScreen(ad, careActivityId),
        (localizationService: ILocalizationService) => getTitle(localizationService, StateChangeBlockings.diagnoses),
        (localizationService: ILocalizationService, reason: IStatusChangeBlockingReason) => getGenericMessage(localizationService, reason));

    stateChangeBlockingRegistry.register(
        StateChangeBlockings.dischargeDiagnosis,
        (routingController: IRoutingController<any>, ad: IActionDispatcher, careActivityId: CareActivityId) => navigateToDiagnosesScreen(ad, careActivityId),
        (localizationService: ILocalizationService) => getTitle(localizationService, StateChangeBlockings.dischargeDiagnosis),
        (localizationService: ILocalizationService, reason: IStatusChangeBlockingReason) => getGenericMessage(localizationService, reason));

    stateChangeBlockingRegistry.register(
        StateChangeBlockings.dischargeData,
        (routingController: IRoutingController<any>, ad: IActionDispatcher, careActivityId: CareActivityId) => navigateToDischargeData(routingController, careActivityId),
        (localizationService: ILocalizationService) => getTitle(localizationService, StateChangeBlockings.dischargeData),
        (localizationService: ILocalizationService, reason: IStatusChangeBlockingReason) => getGenericMessage(localizationService, reason));

    stateChangeBlockingRegistry.register(
        StateChangeBlockings.performedServices,
        (routingController: IRoutingController<any>, ad: IActionDispatcher, careActivityId: CareActivityId) => navigateToPerformedServices(routingController, careActivityId),
        (localizationService: ILocalizationService) => getTitle(localizationService, StateChangeBlockings.performedServices),
        (localizationService: ILocalizationService, reason: IStatusChangeBlockingReason) => getGenericMessage(localizationService, reason));

    stateChangeBlockingRegistry.register(
        StateChangeBlockings.serviceRequestObservations,
        (routingController: IRoutingController<any>, ad: IActionDispatcher, careActivityId: CareActivityId) => navigateToServiceRequests(routingController, careActivityId),
        (localizationService: ILocalizationService) => getTitle(localizationService, StateChangeBlockings.serviceRequestObservations),
        (localizationService: ILocalizationService, reason: IStatusChangeBlockingReason) => getGenericMessage(localizationService, reason));

    stateChangeBlockingRegistry.register(
        StateChangeBlockings.serviceRequests,
        (routingController: IRoutingController<any>, ad: IActionDispatcher, careActivityId: CareActivityId) => navigateToServiceRequests(routingController, careActivityId),
        (localizationService: ILocalizationService) => getTitle(localizationService, StateChangeBlockings.serviceRequests),
        (localizationService: ILocalizationService, reason: IStatusChangeBlockingReason) => getGenericMessage(localizationService, reason));
}

function getTitle(localizationService: ILocalizationService, stateChangeBlockingData: StateChangeBlockingData) {
    return localizationService.localizeReferenceData(new ResourceId("StatusChangeBlockingData." + stateChangeBlockingData.value));
}

function getGenericMessage(localizationService: ILocalizationService, reason: IStatusChangeBlockingReason) {
    if (isNullOrUndefined(reason) || isNullOrUndefined(reason.resourceId)) {
        return null;
    }

    return localizationService.localizeReferenceData(reason.resourceId);
}

function getDocumentTypesMessage(localizationService: ILocalizationService, reason: IStatusChangeBlockingReason, documentReferenceDataStore: DocumentManagementReferenceDataStore) {
    const documentTypeIds = reason.messsageParameters.find(p => p.key === "DocumentTypeIds").parameter.map((i: any) => i.Value.toString());
    const documentTypes = documentTypeIds.map((i: any) => documentReferenceDataStore.documentTypeMap.get(new DocumentTypeId(i))) as DocumentType[];

    const message = formatStringWithObjectParams(localizationService.localizeReferenceData(reason.resourceId), {
        DocumentTypes: documentTypes.map(d => d.name).join(", ").toLowerCase()
    });
    return message;
}

function navigateToDocumentTypes(routingController: IRoutingController<any>, careActivityId: CareActivityId) {
    routingController.push(OutpatientTreatmentRoutes.documentList.makeRoute({
        careActivityId: careActivityId.value,
        selectedDocumentId: null
    }));
}

function navigateToClinicalData(routingController: IRoutingController<any>, careActivityId: CareActivityId) {
    routingController.push(OutpatientTreatmentRoutes.baseData.makeRoute({ careActivityId: careActivityId.value }));
}

function navigateToDiagnosesScreen(actionDispatcher: IActionDispatcher, careActivityId: CareActivityId) {
    actionDispatcher.dispatchAsync(new ShowDiagnosisScreenAction(ScreenDisplayMode.Full, careActivityId));
}

function navigateToDischargeData(routingController: IRoutingController<any>, careActivityId: CareActivityId) {
    routingController.push(OutpatientTreatmentRoutes.discharge.makeRoute({ careActivityId: careActivityId.value }));
}

function navigateToPerformedServices(routingController: IRoutingController<any>, careActivityId: CareActivityId) {
    routingController.push(OutpatientTreatmentRoutes.performedServiceList.makeRoute({ careActivityId: careActivityId.value }));
}

function navigateToServiceRequests(routingController: IRoutingController<any>, careActivityId: CareActivityId) {
    routingController.push(OutpatientTreatmentRoutes.servicesRequests.makeRoute({
        careActivityId: careActivityId.value,
        filter: "CareActivity",
        mode: "edit"
    }));
}

class StateChangeBlockings {
    public static readonly diagnoses = new StateChangeBlockingData("Diagnoses");
    public static readonly dischargeDiagnosis = new StateChangeBlockingData("DischargeDiagnosis");
    public static readonly performedServices = new StateChangeBlockingData("PerformedServices");
    public static readonly careDocuments = new StateChangeBlockingData("CareDocuments");
    public static readonly clinicalData = new StateChangeBlockingData("ClinicalData");
    public static readonly dischargeData = new StateChangeBlockingData("DischargeData");
    public static readonly serviceRequests = new StateChangeBlockingData("ServiceRequests");
    public static readonly serviceRequestObservations = new StateChangeBlockingData("ServiceRequestObservations");
    public static readonly documentTypes = new StateChangeBlockingData("DocumentTypes");
}