import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as HisUi from "@HisPlatformControls";
import HisUseCaseIdentifierSetter from "@HisPlatform/Components/HisUseCaseIdentifierSetter";
import PractitionerId from "@Primitives/PractitionerId.g";
import IPractitionerAppointmentsRouteParams from "./IPractitionerAppointmentsRouteParams";
import PractitionerAppointmentsPanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/PractitionerAppointmentsPanel/PractitionerAppointmentsPanel";
import StandaloneAppointmentRoutes from "./StandaloneAppointmentRoutes";
import * as Ui from "@CommonControls";
import { getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import PatientId from "@Primitives/PatientId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import IUseCaseNavigatorParameters from "@PluginInterface/UseCases/IUseCaseNavigatorParameters";
import Route from "@Toolkit/ReactClient/Routing/Abstractions/Route";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import OutpatientTreatmentRoutes from "@HisPlatform/Components/Pages/OutpatientTreatment/OutpatientTreatmentRoutes";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";


interface IStandAlonePractitionerAppointmentsPageProps extends IRoutingFrameContentProps {

}

@State.observer
class StandAlonePractitionerAppointmentsPage extends React.Component<IStandAlonePractitionerAppointmentsPageProps> {

    private get practitionerId() {
        const practitionerId = (this.props.routingController.currentRoute.parameters as IPractitionerAppointmentsRouteParams)?.practitionerId;

        if (practitionerId) {
            return new PractitionerId(practitionerId);
        }

        return null;

    }

    private get routingController(): IRoutingController<IPractitionerAppointmentsRouteParams> {
        return this.props.routingController;
    }

    @State.bound
    private setPractitionerId(id: PractitionerId) {
        this.props.routingController.replace(StandaloneAppointmentRoutes.practitionerAppointments.makeRoute({
            practitionerId: id?.value
        }));
    }

    @State.bound
    private editRegisteredPatientAppointment(patientId: PatientId, careActivityId: CareActivityId, appointmentId: AppointmentScheduleEntryId) {
        this.props.routingController.push(
            StandaloneAppointmentRoutes.registeredPatientAppointments.makeRoute({
                patientId: patientId.value.toString(),
                careActivityId: careActivityId?.value,
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.editAppointmentFromList),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(appointmentId, "appointmentId")])
            })
        );
    }

    @State.bound
    private editUnregisteredPatientAppointment(appointmentId: AppointmentScheduleEntryId) {
        this.props.routingController.push(
            StandaloneAppointmentRoutes.unregisteredPatientAppointments.makeRoute({
                appointmentId: appointmentId.value
            })
        );
    }

    @State.bound
    private openRegisteredPatientServiceRequest(serviceReqestId: ServiceRequestId, careActivityId: CareActivityId) {
        this.props.routingController.push(
            OutpatientTreatmentRoutes.servicesRequests.makeRoute({
                filter: "Patient",
                selectedId: serviceReqestId.value,
                careActivityId: careActivityId.value,
                mode: "read-only"
            })
        );
    }

    @State.bound
    private navigate(
        useCase: UseCaseIdentifier,
        useCaseArguments: UseCaseArgument[],
        displayMode: UseCaseDisplayMode,
        routeFactory: (params: IUseCaseNavigatorParameters) => Route) {
        const route = routeFactory({
            useCase,
            useCaseArguments,
            displayMode,
            currentRoute: this.routingController.currentRoute
        });
        this.routingController.push(route, route.queryString);
    }

    public render() {
        return (
            <HisUi.DashboardLayout>
                <HisUi.HisErrorBoundary>
                    <LoadingBoundary>
                        <HisUseCaseIdentifierSetter value={"UnregisteredPatientAppointment"} />
                        <Ui.ToolbarLayout
                            body={
                                <PractitionerAppointmentsPanel
                                    practitionerId={this.practitionerId}
                                    onPractitionerIdChanged={this.setPractitionerId}
                                    onEditRegisteredPatientAppointment={this.editRegisteredPatientAppointment}
                                    onEditUnegisteredPatientAppointment={this.editUnregisteredPatientAppointment}
                                    onOpenRegisteredPatientServiceRequest={this.openRegisteredPatientServiceRequest}
                                    navigate={this.navigate}
                                />}
                            bottomToolbar={<HisUi.HisPanelButtonPortal.Host style={{ width: "100%" }} />}
                        />
                    </LoadingBoundary>
                </HisUi.HisErrorBoundary>
            </HisUi.DashboardLayout>
        );
    }
}

export default connect(
    StandAlonePractitionerAppointmentsPage
);
