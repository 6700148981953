exports = module.exports = require("../../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AppointmentScheduleDefinitionScheduler_entry_2noUP {\n  color: #4f5b72 !important;\n  background-color: #DEF6DC !important;\n  min-width: 100% !important;\n  border-left: 6px solid #21C313 !important;\n  border-bottom: 1px solid rgba(103, 106, 108, 0.2) !important;\n}\n.AppointmentScheduleDefinitionScheduler_scheduler_1OuHb {\n  max-height: calc(50vh);\n}\n.AppointmentScheduleDefinitionScheduler_calendar_-DryN {\n  float: left;\n  padding-left: 42px;\n}\n.AppointmentScheduleDefinitionScheduler_edit-icon_2mE4z {\n  fill: #4f5b72 !important;\n}\n", ""]);

// exports
exports.locals = {
	"entry": "AppointmentScheduleDefinitionScheduler_entry_2noUP",
	"scheduler": "AppointmentScheduleDefinitionScheduler_scheduler_1OuHb",
	"calendar": "AppointmentScheduleDefinitionScheduler_calendar_-DryN",
	"edit-icon": "AppointmentScheduleDefinitionScheduler_edit-icon_2mE4z",
	"editIcon": "AppointmentScheduleDefinitionScheduler_edit-icon_2mE4z"
};