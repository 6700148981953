import LockingEntityStoreBase from "@Toolkit/CommonWeb/Model/LockingEntityStoreBase";
import PractitionerRecommendationId from "@Primitives/PractitionerRecommendationId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import ConditionId from "@Primitives/ConditionId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PractitionerRecommendationStatus from "./PractitionerRecommendationStatus";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { RecommendationType } from "./RecommendationType";
import IPractitionerRecommendationStatusColumnData from "./IPractitionerRecommendationStatusColumnData";

export default class PractitionerRecommendation extends LockingEntityStoreBase<PractitionerRecommendationId> {
    public careActivityId: CareActivityId;
    public type: RecommendationType;
    @State.observable.ref public pointOfCareId: PointOfCareId;
    @State.observable.ref public claimTypeId: MedicationSubsidyClaimTypeId;
    @State.observable.ref public conditionId: ConditionId;
    @State.observable.ref public recordedOn: LocalDate = LocalDate.today();
    @State.observable.ref public statusColumn: IPractitionerRecommendationStatusColumnData = null;
    public extensionData: any = null;
    private _dirtyChecker = new StoreDirtyChecker();

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "operationInfo",
        "lockInfo",
        "extensionData"
    ];

    @State.action.bound
    public setConditionId(conditionId: ConditionId) {
        this.conditionId = conditionId;
    }

    @State.action.bound
    public setRecordedOn(recordedOn: LocalDate) {
        this.recordedOn = recordedOn;
    }

    @State.action.bound
    public setStatus(status: PractitionerRecommendationStatus) {
        this.statusColumn.status = status;
    }

    @State.action.bound
    public setClaimTypeId(claimTypeId: MedicationSubsidyClaimTypeId) {
        this.claimTypeId = claimTypeId;
    }
}