import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Scheduling/Api/Proxy.g";
import AppointmentScheduleDefinition from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentScheduleDefinition";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import {
    CreateCreateAppointmentScheduleDefinitionControllerDto,
    CreateCreateSchedulingServiceControllerDto,
    CreateDeleteSchedulingServiceControllerDto,
    CreateDeleteAppointmentScheduleDefinitionControllerDto,
    CreateGetAppointmentScheduleDefinitionControllerDto,
    CreateUpdateAppointmentScheduleDefinitionControllerDto,
    CreateUpdateSchedulingServiceControllerDto, CreateGetMaterializedTimePhases
} from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingConfigurationDtoMapper";
import ScheduleDefinitionStoreMapper from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/ScheduleDefinitionStoreMapper";
import AppointmentScheduleDefinitionId from "@Primitives/AppointmentScheduleDefinitionId.g";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import _ from "@HisPlatform/Common/Lodash";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import { buildQueryStringArray } from "@Toolkit/CommonWeb/QueryStringBuilder";
import SchedulingServiceStoreMapper from "./SchedulingServiceStoreMapper";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import PlanningPeriod from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/PlanningPeriod";
import MaterializedTimePhase from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/MaterializedTimePhase";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IUpdateSchedulingServiceResponse } from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/IUpdateSchedulingServiceResponse";
import SchedulingServiceSelectorQueryOrderingFields from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/SchedulingServiceSelectorQueryOrderingFields.g";

interface IUpdateAppointmentScheduleDefinitionResponse {
    store: AppointmentScheduleDefinition;
    invalidAppointmentCount: number;
}

@Di.injectable()
export default class SchedulingConfigurationApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IConfigurationClient") private apiClient: Proxy.IConfigurationClient,
        @Di.inject("ScheduleDefinitionStoreMapper") private storeMapper: ScheduleDefinitionStoreMapper,
        @Di.inject("SchedulingServiceStoreMapper") private schedulingServiceStoreMapper: SchedulingServiceStoreMapper
    ) {
        super();
    }

    public async getAppointmentScheduleDefinitionByIdAsync(id: AppointmentScheduleDefinitionId) {
        const dto = CreateGetAppointmentScheduleDefinitionControllerDto(id);
        return await this.processOperationAsync(
            new AppointmentScheduleDefinition(),
            async target => {
                const response = await this.apiClient.getAppointmentScheduleDefinitionCommandAsync(CreateRequestId(), dto);

                this.storeMapper.applyToStore(target, response);
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    @State.bound
    public async getAppointmentScheduleDefinitionsByIdsAsync(ids: AppointmentScheduleDefinitionId[]) {
        return await this.processOperationAsync(
            new SimpleStore<AppointmentScheduleDefinition[]>(),
            async target => {
                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value));
                const response = await this.apiClient.getAppointmentScheduleDefinitionsByIdsQueryAsync(
                    CreateRequestId(),
                    buildQueryStringArray(normalizedIds)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.appointmentScheduleDefinitions.map(definition => {
                    const res = new AppointmentScheduleDefinition();
                    this.storeMapper.applyToStore(res, definition);
                    return res;
                });
            }
        );
    }

    @State.bound
    public async getAllAppointmentScheduleDefinitionIdsAsync() {
        return await this.processOperationAsync(
            new SimpleStore<AppointmentScheduleDefinitionId[]>(),
            async target => {
                const response = await this.apiClient.getAllAppointmentScheduleDefinitionIdsQueryAsync(
                    CreateRequestId()
                );
                target.operationInfo = createOperationInfo(response);
                target.value = response.appointmentScheduleDefinitionIds;
            }
        );
    }

    @State.bound
    public async getAllSchedulingServiceIdsAsync() {
        return await this.processOperationAsync(
            new SimpleStore<SchedulingServiceId[]>(),
            async target => {
                const response = await this.apiClient.getAllSchedulingServiceIdsQueryAsync(
                    CreateRequestId()
                );
                target.operationInfo = createOperationInfo(response);
                target.value = response.schedulingServiceIds;
            }
        );
    }

    public async validateAppointmentScheduleDefinitionAsync(store: AppointmentScheduleDefinition) {
        const dto = CreateUpdateAppointmentScheduleDefinitionControllerDto(store, true, false);
        return await this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response = await this.apiClient.updateAppointmentScheduleDefinitionCommandAsync(CreateRequestId(), dto);

                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public async validateAppointmentScheduleDefinitionBaseDataAsync(store: AppointmentScheduleDefinition) {
        const dto = CreateCreateAppointmentScheduleDefinitionControllerDto(store, true);
        return await this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response = await this.apiClient.createAppointmentScheduleDefinitionCommandAsync(CreateRequestId(), dto);

                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public async updateAppointmentScheduleDefinitionAsync(store: AppointmentScheduleDefinition, releaseLock: boolean) {
        const dto = CreateUpdateAppointmentScheduleDefinitionControllerDto(store, false, releaseLock);
        return await this.processOperationAsync(
            new SimpleStore<IUpdateAppointmentScheduleDefinitionResponse>(),
            async target => {
                const response = await this.apiClient.updateAppointmentScheduleDefinitionCommandAsync(CreateRequestId(), dto);
                target.value = {
                    invalidAppointmentCount: response.invalidAppointmentCount,
                    store: new AppointmentScheduleDefinition()
                };
                this.storeMapper.applyToStore(target.value.store, response);
                target.value.store.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public async deleteAppointmentScheduleDefinitionAsync(store: AppointmentScheduleDefinition) {
        const dto = CreateDeleteAppointmentScheduleDefinitionControllerDto(store);
        return await this.processOperationAsync(
            new SimpleStore<number>(),
            async target => {
                const response = await this.apiClient.deleteAppointmentScheduleDefinitionCommandAsync(CreateRequestId(), dto);
                target.operationInfo = createOperationInfo(response);
                target.value = response.invalidAppointmentCount;
            }
        );
    }

    @State.bound
    public async loadSchedulingServiceByIdsAsync(ids: SchedulingServiceId[]) {
        return await this.processOperationAsync(
            new SimpleStore<SchedulingServiceSubjectStore[]>(),
            async target => {
                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value));
                const response = await this.apiClient.getSchedulingServicesByIdsQueryAsync(
                    CreateRequestId(),
                    buildQueryStringArray(normalizedIds)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.schedulingServices.map(service => {
                    const res = new SchedulingServiceSubjectStore();
                    this.schedulingServiceStoreMapper.applyToStore(res, service);
                    return res;
                });
            }
        );
    }

    public async createSchedulingServiceAsync(store: SchedulingServiceSubjectStore, lockInfo: LockInfo) {
        const dto = CreateCreateSchedulingServiceControllerDto(store, lockInfo);
        return await this.processOperationAsync(
            new SimpleStore<IUpdateSchedulingServiceResponse>(),
            async target => {
                const response = await this.apiClient.createSchedulingServiceCommandAsync(CreateRequestId(), dto);
                target.value = {
                    store: new SchedulingServiceSubjectStore(true)
                };
                this.schedulingServiceStoreMapper.applyToStore(target.value.store, response);
                target.value.store.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.value.store.isNew = !target.value.store.isPersistedByOperationInfo;
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public async validateSchedulingServiceAsync(store: SchedulingServiceSubjectStore, lockInfo: LockInfo) {
        return await this.processOperationAsync(
            new SchedulingServiceSubjectStore(true),
            async target => {
                let response = null;

                if (store.isNew) {
                    const dto = CreateCreateSchedulingServiceControllerDto(store, lockInfo, true);
                    response = await this.apiClient.createSchedulingServiceCommandAsync(CreateRequestId(), dto);
                } else {
                    const dto = CreateUpdateSchedulingServiceControllerDto(store, lockInfo, true);
                    response = await this.apiClient.updateSchedulingServiceCommandAsync(CreateRequestId(), dto);
                }

                this.schedulingServiceStoreMapper.applyToStore(target, response);
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public async updateSchedulingServiceAsync(store: SchedulingServiceSubjectStore, lockInfo: LockInfo) {
        const dto = CreateUpdateSchedulingServiceControllerDto(store, lockInfo);
        return await this.processOperationAsync(
            new SimpleStore<IUpdateSchedulingServiceResponse>(),
            async target => {
                const response = await this.apiClient.updateSchedulingServiceCommandAsync(CreateRequestId(), dto);
                target.value = {
                    invalidAppointmentCount: response.invalidAppointmentCount,
                    store: new SchedulingServiceSubjectStore(true)
                };

                this.schedulingServiceStoreMapper.applyToStore(target.value.store, response);
                target.value.store.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.value.store.isNew = !target.value.store.isPersistedByOperationInfo;
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public async deleteSchedulingServiceAsync(store: SchedulingServiceSubjectStore, lockInfo: LockInfo) {
        const dto = CreateDeleteSchedulingServiceControllerDto(store, lockInfo);
        return await this.processOperationAsync(
            new SimpleStore<number>(),
            async target => {
                const response = await this.apiClient.deleteSchedulingServiceCommandAsync(CreateRequestId(), dto);
                target.operationInfo = createOperationInfo(response);
                target.value = response.invalidAppointmentCount;
            }
        );
    }

    public async checkSchedulingServiceUsageAsync(serviceId: SchedulingServiceId) {
        return await this.processOperationAsync(
            new SimpleStore<boolean>(),
            async target => {
                const response = await this.apiClient.checkSchedulingServiceUsageQueryAsync(CreateRequestId(), serviceId.value);
                target.operationInfo = createOperationInfo(response);
                target.value = response.isUsedInTimeSlot;
            }
        );
    }

    public async getMaterializedTimePhasesAsync(store: PlanningPeriod) {
        const dto = CreateGetMaterializedTimePhases(store);
        return await this.processOperationAsync(
            new SimpleStore<MaterializedTimePhase[]>(),
            async target => {
                const response = await this.apiClient.getMaterializedTimePhasesQueryAsync(CreateRequestId(), dto);
                target.operationInfo = createOperationInfo(response);

                const orederedTimePhases = _.orderBy(response.materializedTimePhases, i => i.interval.from, "asc");

                target.value = orederedTimePhases.map((t, i) => {
                    return new MaterializedTimePhase(i, t.id, t.interval.from, t.interval.to);
                });
            }
        );
    }

    public searchSchedulingServicesAsync(searchString: string, maxResultCount: number) {
        return this.processOperationAsync(
            new SimpleStore<SchedulingServiceId[]>(),
            async target => {
                const response = await this.apiClient.searchSchedulingServiceQueryAsync(
                    CreateRequestId(),
                    maxResultCount.toString(),
                    searchString);

                target.operationInfo = createOperationInfo(response);
                target.value = response.schedulingServiceIds;
            }
        );
    }

    public querySchedulingServicesAsync(filterText: string, ordering: IOrderingState, paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new PagedItemStore<SchedulingServiceSubjectStore>([], 0),
            async target => {
                const response = await this.apiClient.schedulingServiceSelectorQueryAsync(
                    CreateRequestId(),
                    new Proxy.SchedulingServiceSelectorControllerDto({
                        filterText: filterText,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfSchedulingServiceSelectorQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfSchedulingServiceSelectorQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: SchedulingServiceSelectorQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({ pageIndex: paging.currentPage || 0, pageSize: paging.pageSize || 20 })
                        })
                    }));

                target.operationInfo = createOperationInfo(response);
                target.items = response.results.values.map(m => {
                    const service = new SchedulingServiceSubjectStore(false);
                    service.id = m.id;
                    service.code = m.code;
                    service.isTelemedicineConsultation = m.isTelemedicineConsultation;
                    service.name = m.name;
                    service.durationInMinutes = m.durationInMinutes;
                    service.careLocationId = m.careLocationId;
                    service.careLocationDescription = m.careLocationDescription;
                    return service;
                });
                target.totalCount = response.results.totalCount;
            }
        );
    }
}
