
import DependencyContainer from "@DiContainer";
import TherapyRecommendationFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/TherapyRecommendationFactory";
import PatientBloodTypeFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/PatientBloodType/PatientBloodTypeFactory";

DependencyContainer
    .bind("TherapyRecommendationFactory")
    .to(TherapyRecommendationFactory)
    .inSingletonScope();

DependencyContainer
    .bind("PatientBloodTypeFactory")
    .to(PatientBloodTypeFactory)
    .inSingletonScope();
