import IForm from "./IForm";
import FormInstanceId from "./Primitives/FormInstanceId.g";
import FormDefinitionId from "./Primitives/FormDefinitionId.g";
import { IFormDataStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormDataStore";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import LockingEntityStoreBase from "@Toolkit/CommonWeb/Model/LockingEntityStoreBase";
import UserId from "@Primitives/UserId.g";
import moment from "moment";

export default class Form extends LockingEntityStoreBase<FormInstanceId> implements IForm {
    public rowVersions: Map<string, RowVersion>;

    constructor(
        id: FormInstanceId,
        rowVersion: RowVersion,
        public readonly definitionId: FormDefinitionId,
        public readonly data: IFormDataStore,
        public readonly validationResults: IClientValidationResult[],
        public readonly createdAt?: moment.Moment,
        public readonly createById?: UserId | null,
        public readonly formLogic?: string
    ) { 
        super(id.value === "new");
        this.id = id;
        this.rowVersion = rowVersion;

        this.rowVersions = new Map<string, RowVersion>();
    }
}