import IDataGridDataSource from "@CommonControls/DataGrid/DataSource/IDataGridDataSource";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IMultiSelectorProps } from "@CommonControls/MultiSelector/index";

export default class MultiSelectorStore {
    @State.observable private allItemsPageCount: number = 1;
    @State.observable private selectedItemsPageCount: number = 1;
    @State.observable.ref public allItemsDataSource: IDataGridDataSource;
    @State.observable.ref public selectedItemsDataSource: IDataGridDataSource;

    @State.action.bound
    public reInitDataSources(props: IMultiSelectorProps) {
        this.allItemsDataSource = props.allItemsDataSource;
        this.selectedItemsDataSource = props.selectedItemsDataSource;
    }

    @State.bound
    public isItemSelected(item: any) {
        const dataSource = this.selectedItemsDataSource;
        if (dataSource.data) {
            if (Array.isArray(dataSource.data)) {
                return dataSource.data.some(entity => entity === item);
            }
            return dataSource.data.items.some(entity => entity === item);
        }

        return false;
    }

    @State.action.bound
    public setAllItemsPageCount(count: number) {
        this.allItemsPageCount = count;
    }

    @State.action.bound
    public setSelectedItemsPageCount(count: number) {
        this.selectedItemsPageCount = count;
    }

    @State.computed public get forceShowPagers() {
        return this.allItemsPageCount > 1 || this.selectedItemsPageCount > 1;
    }

    @State.bound
    public async reloadAsync() {
        const dataSource = this.allItemsDataSource;
        if (dataSource.onChangeAsync) {
            await dataSource.reloadAsync();
        }
    }
}
