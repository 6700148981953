import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import PatientId from "@Primitives/PatientId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";

export interface ITelemedicineAppointmentDialogParams extends IModalParams {
    patientId?: PatientId;
    episodeOfCareId?: EpisodeOfCareId;
    appointmentId?: AppointmentScheduleEntryId;
}

export interface ITelemedicineAppointmentDialogResult {
    careActivityId: CareActivityId;
    rowVersion?: RowVersion;
}

export default class TelemedicineAppointmentDialogParams implements ITelemedicineAppointmentDialogParams {
 
    public static type = "TelemedicineAppointmentDialogParams";
    public get type() { return TelemedicineAppointmentDialogParams.type; }
 
    constructor(
        public readonly patientId?: PatientId,
        public readonly episodeOfCareId?: EpisodeOfCareId,
        public readonly appointmentId?: AppointmentScheduleEntryId
    ) {}
}