import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import IEhiCovidSelfExaminationResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidSelfExaminationResultPanel/IEhiCovidSelfExaminationResult";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiCovidSelfExaminationStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidSelfExaminationResultPanel/EhiCovidSelfExaminationStoreMapper";
import EhiCovidSelfExaminationResultPanelView from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidSelfExaminationResultPanel/EhiCovidSelfExaminationResultPanelView";

interface IEhiCovidSelfExaminationResultPanelDependencies {
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    ehiCovidSelfExaminationStoreMapper: EhiCovidSelfExaminationStoreMapper;
}

interface IEhiCovidSelfExaminationResultPanelProps {
    _dependencies?: IEhiCovidSelfExaminationResultPanelDependencies;
    extensionData: any;
}

@State.observer
class EhiCovidSelfExaminationResultPanel extends React.Component<IEhiCovidSelfExaminationResultPanelProps>  {

    @State.observable.ref private store: IEhiCovidSelfExaminationResult;

    public componentDidMount(): void {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }

    private async initializeAsync() {
        await this.loadReferenceDataAsync();

        State.runInAction(() => {
            this.store = this.props._dependencies.ehiCovidSelfExaminationStoreMapper.mapExtensionData(this.props.extensionData as any);
        });
    }

    private async loadReferenceDataAsync() {
        await Promise.all([
            this.props._dependencies.hunEhiCareReferenceDataStore.ehiCovidSelfExaminationTimeCodes.ensureLoadedAsync(),
            this.props._dependencies.hunEhiCareReferenceDataStore.ehiCovidSelfExaminationGeneralStatusIds.ensureLoadedAsync(),
            this.props._dependencies.hunEhiCareReferenceDataStore.ehiCovidBreathingStatuses.ensureLoadedAsync(),
            this.props._dependencies.hunEhiCareReferenceDataStore.ehiCovidSymptomStatuses.ensureLoadedAsync(),
            this.props._dependencies.hunEhiCareReferenceDataStore.ehiCovidUrineAmounts.ensureLoadedAsync()
        ]);
    }

    public render() {
        return (
            <EhiCovidSelfExaminationResultPanelView
                store={this.store}
            />
        );
    }
}

export default connect(EhiCovidSelfExaminationResultPanel,
    new DependencyAdapter<IEhiCovidSelfExaminationResultPanelProps, IEhiCovidSelfExaminationResultPanelDependencies>((c) => {
        return {
            hunEhiCareReferenceDataStore: c.resolve<HunEhiCareReferenceDataStore>("HunEhiCareReferenceDataStore"),
            ehiCovidSelfExaminationStoreMapper: c.resolve<EhiCovidSelfExaminationStoreMapper>("EhiCovidSelfExaminationStoreMapper")
        };
    })
);
