import "./ApiClientConfiguration";

import DependencyContainer from "@DiContainer";
import HunEHealthInfrastructureUserManagementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/HunEHealthInfrastructureUserManagementApiAdapter";
import EidAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/EidAdapter/EidAdapter";
import IEhiTokenRepository from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/Repositories/IEhiTokenRepository";
import EhiTokenRepository from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/Repositories/EhiTokenRepository";
import ILogoutHandlerExtension from "@PluginInterface/BoundedContexts/UserManagement/ILogoutHandlerExtension";
import LogoutHandlerExtension from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/Extensions/LogoutHandlerExtension";
import EhiTokenStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/EhiTokenStoreMapper";
import { GetTypeNameWithPrefix } from "@HunEHealthInfrastructurePlugin/PluginHelper";
import IPrescriptionExtensionDataProvider from "@PluginInterface/BoundedContexts/MedicationRequest/Prescription/IPrescriptionExtensionDataProvider";
import EhiTokenProvider from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Services/EhiTokenProvider/EhiTokenProvider";

DependencyContainer
    .bind("HunEHealthInfrastructureUserManagementApiAdapter")
    .to(HunEHealthInfrastructureUserManagementApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EidAdapter")
    .to(EidAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("IEhiTokenRepository")
    .to(EhiTokenRepository)
    .inSingletonScope();

DependencyContainer
    .bind("ILogoutHandlerExtension")
    .to(LogoutHandlerExtension);

DependencyContainer
    .bind(GetTypeNameWithPrefix("EhiTokenStoreMapper"))
    .to(EhiTokenStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("EhiTokenProvider")
    .to(EhiTokenProvider);
