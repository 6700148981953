import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import EhiSelfDeterminationSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiSelfDeterminationSelectBox";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import IEhiSelfDeterminationExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IEhiSelfDeterminationExtensionPointProps";
import EhiMedicalConditionHistoryItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/EhiMedicalConditionHistoryItem";
import EhiSelfDeterminationId from "@Primitives/EhiSelfDeterminationId.g";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";


interface IEhiSelfDeterminationPanelDependencies {
}

interface IEhiSelfDeterminationPanelProps extends IEhiSelfDeterminationExtensionPointProps {
    _dependencies?: IEhiSelfDeterminationPanelDependencies;
}

@State.observer
export class EhiSelfDeterminationPanel extends React.Component<IEhiSelfDeterminationPanelProps> {
    @State.observable.ref private historyItem: EhiMedicalConditionHistoryItem = null;

    public componentDidMount() {
        this.setDataStore();
    }

    public componentDidUpdate(prevProps: IEhiSelfDeterminationPanelProps) {
        if (this.props.store && this.props.store.index !== prevProps.store.index) {
            this.setDataStore();
        }
    }

    @State.action.bound
    private setDataStore() {
        this.historyItem = EhiMedicalConditionHistoryItem.createFromExtensionDto(this.props.store?.extensionData);

        if (this.props.store.isNew) {
            this.historyItem.ehiVersion = null;
        }
        this.setExtensionData();
    }

    @State.action.bound
    private setSelfDetermination(newValue: EhiSelfDeterminationId) {
        this.historyItem.setSelfDetermination(newValue);
        this.setExtensionData();
    }

    @State.action.bound
    private setExtensionData() {
        this.props.store.setExtensionData({
            ...this.props.store.extensionData,
            ["HunEHealthInfrastructure_EhiMedicalConditionInfo"]: {
                VersionNumber: this.historyItem.versionNumber,
                SelfDetermination: this.historyItem.selfDetermination?.toJSON(),
                EhiVersion: this.historyItem.ehiVersion,
                IsDeleted: this.historyItem.isDeleted
            }
        });
    }

    public render() {
        return (
            <>
                {this.historyItem &&
                    <ReadOnlyContext.Provider value={!this.props.store.isNew}>
                        <Ui.Flex.Item sm={6}>
                            <EhiSelfDeterminationSelectBox
                                value={this.historyItem.selfDetermination}
                                onChange={this.setSelfDetermination}
                                label={StaticHunEHealthInfrastructureCareResources.EhiSelfDeterminationPanel.SelfDetermination}
                                propertyIdentifier="SelfDetermination"
                                automationId="__selfDetermination"
                            />
                        </Ui.Flex.Item>
                    </ReadOnlyContext.Provider>
                    || <></>
                }
            </>
        );
    }
}

export default connect(
    EhiSelfDeterminationPanel,
    new DependencyAdapter<IEhiSelfDeterminationPanelProps, IEhiSelfDeterminationPanelDependencies>(c => {
        return {
        };
    })
);