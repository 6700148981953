import React, { useContext, useCallback } from "react";
import { arrayIsNullOrEmpty, emptyObject } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";

export class LoadedSignalContextStore {
    private readonly loadedSignals = new Map<string, boolean>();
    private resolve: (() => void) | null = null;
    private signalsToCheck: string[] | null = null;

    public clearAllSignals() {
        this.loadedSignals.clear();
    }

    public waitForAllSignalsAsync(signals: string[]) {

        if (arrayIsNullOrEmpty(signals)) {
            return Promise.resolve();
        }

        return new Promise<void>((resolve: () => void, reject: () => void) => {
            this.signalsToCheck = signals;
            this.resolve = resolve;

            if (!this.checkSignals()) {
                // todo: timeout!
            }
        });
    }

    public setSignal(signalName: string) {
        this.loadedSignals.set(signalName, true);
        this.checkSignals();
    }

    public clearSignal(signalName: string) {
        this.loadedSignals.set(signalName, false);
    }

    private checkSignals() {
        if (this.signalsToCheck && this.resolve) {
            const missingLoadedSignals =
                this.signalsToCheck.filter(i => !(Array.from(this.loadedSignals.keys()).includes(i)));
            missingLoadedSignals.forEach(i => this.loadedSignals.set(i, false));

            const signalsToCheck = Array.from(this.loadedSignals.entries()).filter(s => this.signalsToCheck.includes(s[0]));
            if (signalsToCheck.length > 0 && signalsToCheck.every(s => s[1] === true)) {
                this.resolve();
                return true;
            }
        }
        return false;
    }
}

export const LoadedSignalReactContext = React.createContext<LoadedSignalContextStore | null>(null);

export function useLoadedSignal(signalName: string) {
    const context = useContext(LoadedSignalReactContext);
    return useCallback(() => {
        context?.setSignal(signalName);
    }, [signalName, context]);
}

export class LoadedSignalAdapter<TProps = { _onLoaded: () => void }> implements IComponentAdapter<TProps> {
    public get usedContexts(): Array<React.Context<any>> {
        return [LoadedSignalReactContext];
    }

    constructor(
        private readonly signalName: string,
        private readonly mapToProps: (onLoaded: () => void, props: TProps) => Partial<TProps> = (onLoaded) => ({ _onLoaded: onLoaded } as any)
    ) { }

    public getMappedProps(props: TProps, contextValues: Map<React.Context<any>, any>): Partial<TProps> {
        const context = contextValues.get(LoadedSignalReactContext) as LoadedSignalContextStore;
        if (!context) {
            return emptyObject;
        }
        return this.mapToProps(() => context.setSignal(this.signalName), props);
    }
}