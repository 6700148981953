import React, { useContext } from "react";
import { iconNameType } from "@CommonControls/Icon";
import PageBox2 from "@CommonControls/PageBox2/PageBox2";
import IMasterDetailState from "@CommonControls/Layout/IMasterDetailState";
import ViewContextProvider from "@Toolkit/ReactClient/Components/ViewContext/ViewContextProvider";
import Button from "@CommonControls/Button";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { MasterDetailContext } from "@CommonControls/Layout/MasterDetailContext";
import Styles from "./Layout.less";

export interface IMasterDetailMasterProps {
    _masterDetailState?: IMasterDetailState;
    _detailChildren?: React.ReactNode;
    _compactListSize?: string | number;

    iconName?: iconNameType;
    title: string;
    subTitle?: React.ReactNode;
    toolbar?: React.ReactNode;
    detailOpenToolbar?: React.ReactNode;
    detailClosedToolbar?: React.ReactNode;
    hasSidePadding?: boolean;

    onRenderList?: (content: React.ReactNode) => React.ReactNode;
    hideHeader?: boolean;
    passStateToChild?: boolean;
}

@State.observer
class MasterDetailMasterCore extends React.Component<IMasterDetailMasterProps> {

    public static defaultProps: Partial<IMasterDetailMasterProps> = {
        passStateToChild: true
    };

    private get getToolbar() {
        if (this.props._masterDetailState.isDetailOpen) {
            return this.props.detailOpenToolbar ? this.props.detailOpenToolbar : this.props.toolbar;
        } else {
            return this.props.detailClosedToolbar ? this.props.detailClosedToolbar : this.props.toolbar;
        }
    }

    private get masterContent() {
        return this.props.passStateToChild
            ? React.cloneElement(React.Children.only(this.props.children) as React.ReactElement, {
                _masterDetailState: this.props._masterDetailState
            })
            : this.props.children;
    }

    private get content() {
        return this.props.onRenderList ? this.props.onRenderList(this.masterContent) : this.masterContent;
    }

    public render() {

        const showSplitDetail = this.props._masterDetailState!.isCompact && this.props._masterDetailState!.isDetailOpen;
        const splitContent = showSplitDetail ? (
            <>
                {this.props._detailChildren}
                {this.props._masterDetailState!.showCompactModeSwitcher && (
                    <Button automationId="expandButton" iconName="expand" className={Styles.masterDetailExpandButton} size="compact" onClick={this.props._masterDetailState!.onExpand} />
                )}
            </>
        ) : (
            <div style={{ display: "none" }}></div>
        );

        return (
            <ViewContextProvider
                isDataGridColumnEllipsisEnabled={this.props._masterDetailState!.isDetailOpen}
                compactControlWidth={this.props._masterDetailState!.isCompact}
            >
                <PageBox2 fullHeight>
                    {!this.props.hideHeader && (
                        <PageBox2.Header
                            title={this.props.title}
                            iconName={this.props.iconName}
                            subTitle={this.props.subTitle}
                            automationId="master"
                            toolbar={(
                                <>
                                    {this.props._masterDetailState!.isDetailOpen && this.props._masterDetailState!.showCompactModeSwitcher && (
                                        <Button
                                            iconName="lessen"
                                            onClick={this.props._masterDetailState!.onLessen}
                                            automationId="lessenButton"
                                        />
                                    )}
                                    <ViewContextProvider compactControlWidth={this.props._masterDetailState!.isDetailOpen}>
                                        {this.getToolbar}
                                    </ViewContextProvider>
                                </>
                            )}
                        />
                    )}
                    <PageBox2.Body
                        hasSidePadding={this.props.hasSidePadding}
                        splitContentSize={showSplitDetail ? this.props._compactListSize : "0"}
                        splitContent={splitContent}
                        splitContentAlign="right"
                    >
                        {this.content}
                    </PageBox2.Body>
                </PageBox2>
            </ViewContextProvider>

        );
    }
}


const MasterDetailMaster: React.FC<IMasterDetailMasterProps> = props => {

    const ctx = useContext(MasterDetailContext);

    if (ctx == null) {
        return null;
    }

    return <MasterDetailMasterCore {...props} _masterDetailState={ctx.state} _detailChildren={ctx.detailChildren} _compactListSize={ctx.compactListSize} />;
};

export default MasterDetailMaster;
