import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import DocumentId from "@Primitives/DocumentId.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import TemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TemplateBasedDocument";
import ScopeIdentifier from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ScopeIdentifier";
import { getScopeIdentifiersDto } from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/DtoMappers";
import ITemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ITemplateBasedDocument";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import { getDocumentInfo } from "./DocumentStoreMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import TemplateId from "@Primitives/TemplateId.g";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import _ from "@HisPlatform/Common/Lodash";
import CareActivityTextBlock from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/CareActivityTextBlock";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import DocumentAction from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentAction.g";

@Di.injectable()
export default class TemplateBasedDocumentApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IDocumentsClient") private readonly apiClient: Proxy.IDocumentsClient,
        @Di.inject("ValidationProblemParameterMapperService") private readonly validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super();
    }

    public updateTemplateBasedDocumentAsync(templateBasedDocument: TemplateBasedDocument, releaseLockIfSuccessful: boolean, documentAction?: DocumentAction) {
        return this.processOperationAsync(
            new SimpleStore<ITemplateBasedDocument>(),
            async target => {

                const contentBase64 = templateBasedDocument.documentFile.getBase64();

                const response = await this.apiClient.updateTemplateBasedDocumentCommandAsync(
                    CreateRequestId(),
                    new Proxy.UpdateTemplateBasedDocumentControllerDto({
                        documentId: templateBasedDocument.id,
                        content: contentBase64,
                        lockId: templateBasedDocument.lockInfo?.lockId,
                        rowVersion: templateBasedDocument.rowVersion,
                        releaseLockIfSuccessful: releaseLockIfSuccessful,
                        documentAction: documentAction
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    documentInfo: getDocumentInfo(response.documentInfo),
                    validationResult: mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult)
                } as ITemplateBasedDocument;

                const validationProblems = _.flatten(target.value.validationResult?.map(i => i.problems));
                await this.validationProblemParameterMappingService.loadCollectedValidationProblemParameterReferenceDataAsync("CareActivityTextBlock");
                validationProblems.map(i => this.validationProblemParameterMappingService.resolveValidationProblemParameters("CareActivityTextBlock", i));
            }
        );
    }

    @State.bound
    public updateTemplateBasedDocumentPermissionCheckAsync(documentId: DocumentId, documentAction: DocumentAction) {
        return this.processOperationAsync(
            new SimpleStore<ITemplateBasedDocument>(),
            async target => {
                const response = await this.apiClient.updateTemplateBasedDocumentCommandAsync(
                    CreateRequestId(),
                    new Proxy.UpdateTemplateBasedDocumentControllerDto({
                        documentId: documentId,
                        content: "",
                        lockId: null,
                        rowVersion: new RowVersion(-1),
                        releaseLockIfSuccessful: false,
                        documentAction: documentAction
                    }),
                    true
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getNotAvailableTemplateIdsForCareActivityAsync(organizationUnitId: OrganizationUnitId, scopeIdentifiers: ScopeIdentifier[]) {
        return this.processOperationAsync(
            new SimpleStore<TemplateId[]>(),
            async target => {
                const response = await this.apiClient.
                    getNotAvailableTemplateIdsForScopeIdentifierQueryAsync(
                        CreateRequestId(),
                        new Proxy.GetNotAvailableTemplateIdsForScopeIdentifierControllerDto({
                            organizationUnitId: organizationUnitId,
                            scopeIdentifiers: getScopeIdentifiersDto(scopeIdentifiers)
                        })
                    );

                target.operationInfo = createOperationInfo(response);
                target.value = response && response.notAvailableTemplateIds;
            }
        );
    }
}