import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, {IExtensibleEnumSelectBoxPropsBase} from "@HisPlatformControls/ExtensibleEnumSelectBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";

interface IEhiEReferralCovidQuickTestResultSelectBoxDependencies {
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
}

interface IEhiEReferralCovidQuickTestResultSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IEhiEReferralCovidQuickTestResultSelectBoxDependencies;
}

@State.observer
class EhiEReferralCovidQuickTestResultSelectBox extends React.Component<IEhiEReferralCovidQuickTestResultSelectBoxProps> {
    private get dependencies() {
        return this.props._dependencies;
    }

    private get extensibleEnumStore() {
        return this.dependencies.hunEhiCareReferenceDataStore.ehiEReferralCovidQuickTestResults;
    }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    EhiEReferralCovidQuickTestResultSelectBox,
    new DependencyAdapter<IEhiEReferralCovidQuickTestResultSelectBoxProps, IEhiEReferralCovidQuickTestResultSelectBoxDependencies>((containerService) => {
        return {
            hunEhiCareReferenceDataStore: containerService.resolve("HunEhiCareReferenceDataStore")
        };
    })
);
