import React from "react";
import * as Ui from "@CommonControls";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import { ContextAwareModal } from "@HisPlatformControls";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import IDocumentSnippet from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentSnippet/IDocumentSnippet";
import DocumentSnippetSelectorGrid from "./DocumentSnippetSelectorGrid";
import { IDocumentContentStore } from "@CommonControls/DocumentEditor/DocumentContentStore";
import DocumentSnippetDetailPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/DocumentSnippetSelectorDialog/DocumentSnippetDetailPanel";

interface IDocumentSnippetDialogViewer {
    onSaveAsync: () => Promise<void>;
    onClose: () => void;
    displayMode?: "inline" | "modal";
    documentSnippets: IDocumentSnippet[];
    selected: IDocumentSnippet;
    content: IDocumentContentStore;
    setSelected: (row: IDocumentSnippet) => void;
}

export default class DocumentSnippetSelectorDialogView extends React.PureComponent<IDocumentSnippetDialogViewer> {

    public render() {
        return (
            <ContextAwareModal
                onClose={this.props.onClose}
                isOpen
                title={StaticDocumentManagementResources.DocumentSnippetSelectorModal.Title}
                size="fullscreen">
                {this.renderModal()}
            </ContextAwareModal>
        );
    }

    private renderCore() {
        const resources = StaticDocumentManagementResources.DocumentSnippetSelectorModal;
        return (
            <MasterDetailLayout onSelectedItemChange={this.props.setSelected} selectedItem={this.props.selected} >
                <MasterDetail.Master title={resources.Labels.Searcher}>
                    <DocumentSnippetSelectorGrid
                        documentSnippets={this.props.documentSnippets}
                    />
                </MasterDetail.Master>
                <MasterDetail.Detail title={resources.Labels.Content} >
                    <DocumentSnippetDetailPanel contentStore={this.props.content} />
                </MasterDetail.Detail>
            </MasterDetailLayout>
        );
    }

    private renderButton() {
        return (
            <Ui.Flex xsJustify="between" verticalSpacing="none" >
                <Ui.Flex.Item>
                    <Ui.Button
                        text={StaticDocumentManagementResources.Common.Buttons.Close}
                        onClick={this.props.onClose}
                        visualStyle="standard"
                        automationId="closeButton"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item>
                    <Ui.Button
                        visualStyle="primary"
                        onClickAsync={this.props.onSaveAsync}
                        text={StaticDocumentManagementResources.Common.Buttons.Insert}
                        automationId="saveButton"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    private renderModal() {
        if (this.props.displayMode === "modal") {
            return (
                <>

                    <Ui.Modal.Body>
                        {this.renderCore()}
                    </Ui.Modal.Body>

                    <Ui.Modal.Footer>
                        {this.renderButton()}
                    </Ui.Modal.Footer>

                </>
            );
        } else {
            return (
                <div>
                    {this.renderCore()}
                    {this.renderButton()}
                </div>
            );
        }
    }
}