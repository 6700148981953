import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface IMultiLingualColumnDependencies {
    cultureCodeProvider: ICurrentCultureProvider;
}

interface IMultiLingualColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IMultiLingualColumnDependencies;
}


const MultiLingualColumn: React.FC<IMultiLingualColumnProps> = (props: IMultiLingualColumnProps) => {

    const valueRenderer = useCallback((value: MultiLingualLabel) => {
        return value.getWithCurrentCultureCodeOrWithDefaultCultureCode(props._dependencies.cultureCodeProvider.cultureCode);
    }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderHintValue={valueRenderer}
        />
    );
};

export default connect(
    MultiLingualColumn,
    new DependencyAdapter<IMultiLingualColumnProps, IMultiLingualColumnDependencies>(c => ({
        cultureCodeProvider: c.resolve<ICurrentCultureProvider>("ICurrentCultureProvider"),
    }))
);