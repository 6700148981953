import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IDeviceHintItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IDeviceHintItem";
import DeviceId from "@Primitives/DeviceId.g";
import IDevice from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IDevice";
import ComplexSearchDevicesQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/Devices/Enum/ComplexSearchDevicesQueryOrderingFields.g";

@Di.injectable()
export default class DevicesApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IDevicesClient") private readonly apiClient: Proxy.IDevicesClient
    ) {
        super();
    }

    public searchDevices(freeText: string, maxResultCount: number) {
        return this.processOperationAsync(
            new SimpleStore<IDeviceHintItem[]>(),
            async target => {
                const response = await this.apiClient.searchDevicesQueryAsync(
                    CreateRequestId(),
                    freeText,
                    maxResultCount.toString()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.devices.map(ai => {
                    return {
                        id: ai.id,
                        code: ai.code,
                        name: ai.name
                    } as IDeviceHintItem;
                });
            }
        );
    }

    public getAllIdsAsync(): Promise<SimpleStore<DeviceId[]>> {
        return this.processOperationAsync(
            new SimpleStore<DeviceId[]>(),
            async target => {
                const response = await this.apiClient.getDeviceIdsQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.ids.map(id => id);
            }
        );
    }

    @State.bound
    public loadDevicesAsync(ids: DeviceId[]): Promise<SimpleStore<IDevice[]>> {
        return this.processOperationAsync(
            new SimpleStore<IDevice[]>(),
            async (target) => {
                const response = await this.apiClient.getDevicesByIdsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetDevicesByIdsControllerDto({ ids: ids })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.devices.map(this.mapDevice);
            }
        );
    }

    private mapDevice(ai: Proxy.DeviceDto): IDevice {
        return {
            id: ai.id,
            code: ai.code,
            name: ai.name
        } as IDevice;
    }

    @State.bound
    public complexSearchDevices(
        freeText: string,
        ordering: IOrderingState,
        paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<IDevice>>(),
            async (target) => {
                const response = await this.apiClient.complexSearchDevicesQueryAsync(
                    CreateRequestId(),
                    new Proxy.ComplexSearchDevicesControllerDto({
                        freeText: freeText,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfComplexSearchDevicesQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfComplexSearchDevicesQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: ComplexSearchDevicesQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results && response.results.values.map(this.mapDevice)
                };

            }
        );
    }
}
