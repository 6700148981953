import Button, { ISimpleButtonProps } from "@Toolkit/ReactClient/Components/CommonControls/Button";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface IChangeHistoryButtonDependencies {
    localizationService: IToolkitLocalizationService;
}
class ChangeHistoryButton extends React.PureComponent<ISimpleButtonProps> {
    public render() {
        return <Button {...this.props} iconName="history" text={this.props._dependencies.localizationService.staticResources.button.changeHistory}/>;
    }
}

export default connect(
    ChangeHistoryButton,
    new DependencyAdapter<ISimpleButtonProps, IChangeHistoryButtonDependencies>(c => ({
        localizationService: c.resolve("IToolkitLocalizationService"),
    }))
);
