import Di from "@Di";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import IWorklistDefinitionGroup from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinitionGroup";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import _ from "@HisPlatform/Common/Lodash";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class WorklistDefinitionReferenceDataStore extends ReferenceDataStoreBase {
    private worklistDefinitions: IWorklistDefinition[] = [];
    @State.observable.ref public isLoaded = false;
    private loadingPromise: Promise<void> | null = null;

    constructor(
        @Di.inject("WorklistApiAdapter") private readonly worklistApiAdapter: WorklistApiAdapter,
        @Di.inject("OrganizationReferenceDataStore") private readonly organizationReferenceDataStore: OrganizationReferenceDataStore,
    ) {
        super();
    }

    public async initializeAsync() {
        if (this.isLoaded) return;
        if (this.loadingPromise) {
            await this.loadingPromise;
            return;
        }

        this.loadingPromise = (async () => {
            const definitionListStore = await this.worklistApiAdapter.getWorklistDefinitionList();
            const definitions = definitionListStore.value.flatMap(d => d.definitions);
            await this.loadReferenceDataAsync(definitions);
            this.setState(definitions);
        })();

        await this.loadingPromise;
    }

    private async loadReferenceDataAsync(worklistDefinitions: IWorklistDefinition[]) {
        const ids = _.uniqBy(_.flatten(worklistDefinitions.map(item => item.healthcareProfessionIds)), item => item.value);
        if (ids.length > 0) {
            await this.organizationReferenceDataStore.healthCareProfession.ensureLoadedAsync(ids);
        }
    }

    @State.action
    private setState(worklistDefinitions: IWorklistDefinition[]) {
        if (!worklistDefinitions) {
            return;
        }

        this.worklistDefinitions = worklistDefinitions;
        this.isLoaded = true;
    }

    public getLocalIdByToken(token: string): string | null {
        return this.worklistDefinitions.find(d => d.worklistToken === token)?.localId ?? null;
    }

    public getByLocalId(localId: string) {
        return this.worklistDefinitions.find(d => d.localId === localId);
    }
}