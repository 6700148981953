import React, { useMemo } from "react";
import _ from "@HisPlatform/Common/Lodash";
import IDataGridColumnProps, { IDataGridColumnChildProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { IRowData } from "@CommonControls/DataGrid/ViewModel";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Styles from "@CommonControls/DataGrid/DataGrid.less";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";

interface IDataGridCellProps {
    columnProps: IDataGridColumnProps;
    row: IRowData;
    rowIndex: number;
    isUnderEditing: boolean;
    hasLeftPadding: boolean;

    value?: any;
    isValueProvided?: boolean;
}


const DataGridCell: React.FC<IDataGridCellProps> = props => {
    const value = props.isValueProvided ? props.value : getCellValue(props.columnProps, props.row, props.rowIndex, props.isUnderEditing);
    const dataSetter = getDataSetter(props.columnProps, props.row, props.rowIndex);

    const propsOfChildren = [...Object.values(props.columnProps.children && props.columnProps.children.props || {})];

    return useMemo(() => {
        const displayValue = (() => {
            if (props.columnProps.children) {
                return React.cloneElement(props.columnProps.children, {
                    row: props.row.data,
                    rowId: props.row.id,
                    rowIndex: props.rowIndex,
                    value: value,
                    isUnderEditing: props.isUnderEditing
                });
            }

            return props.columnProps.onRenderCellValue(value, props.row.data, props.row.id, props.rowIndex, props.isUnderEditing, dataSetter);
        })();

        return props.columnProps.onRenderCell({
            style: { textAlign: props.columnProps.cellTextAlign },
            className: combineClasses(
                props.columnProps.leftPadding === "small" && Styles.leftPaddingSmall,
                props.columnProps.rightPadding === "small" && Styles.rightPaddingSmall,
                props.hasLeftPadding && Styles.leftPaddingTiny
            )
        }, displayValue, value, props.row.data, props.row.id, props.rowIndex, props.columnProps, props.isUnderEditing, dataSetter);
    }, [props.columnProps, props.row, props.rowIndex, value, propsOfChildren]);
};

function getCellValue(columnProps: IDataGridColumnProps, row: IRowData, rowIndex: number, isUnderEditing: boolean) {
    return typeof columnProps.dataGetter === "function" ? columnProps.dataGetter(row.data, row.id, rowIndex, isUnderEditing) : _.get(row.data, columnProps.dataGetter);
}

function getDataSetter(columnProps: IDataGridColumnProps, row: IRowData, rowIndex: number) {
    return typeof columnProps.dataSetter === "function" ? columnProps.dataSetter(row.data, row.id, rowIndex) : _.get(row.data, columnProps.dataSetter);
}


const ObserverDataGridCell: React.FC<IDataGridCellProps> = props => (
    <State.Observer>
        {() => {
            const value = getCellValue(props.columnProps, props.row, props.rowIndex, props.isUnderEditing);
            return <DataGridCell {...props} value={value} isValueProvided />;
        }}
    </State.Observer>
);

export {
    DataGridCell,
    ObserverDataGridCell
};
