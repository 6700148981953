import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React, { useEffect, useMemo, useState } from "react";
import * as Ui from "@CommonControls";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { useErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import EuCardReplacementTypeId from "@Primitives/EuCardReplacementTypeId.g";
import CountryId from "@Primitives/CountryId.g";
import PatientDocumentCategory from "@HisPlatform/BoundedContexts/Care/Api/PatientRegister/Enum/PatientDocumentCategory.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import PatientDocumentTypeSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/PatientRegister/PatientDocumentTypeSelectBox";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import InsurerOrganizationSelectBox from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Components/Controls/InsurerOrganizationSelectBox";
import InsurerOrganizationDetailPanelModalParams, { IInsurerOrganizationDetailPanelModalResult } from "@HisPlatform/BoundedContexts/Finance/Components/InsurerOrganizationModal/InsurerOrganizationDetailPanelModalParams";
import { CountrySelector } from "@HisPlatform/Components/HisPlatformControls";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import { IPatientIdentifierDependencies, IPatientIdentifierProps } from "@HisPlatform/Packages/Patients/Components/PatientIdentifier/PatientIdentifier";
import EuCardReplacementTypeSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EuCardReplacementTypeSelectoBox";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";

export interface IPatientEUCardIdentifierDependencies extends IPatientIdentifierDependencies {
}

export interface IPatientEUCardIdentifierProps extends IPatientIdentifierProps {
    euCardReplacementTypeId: EuCardReplacementTypeId;
    onEuCardReplacementTypeIdChange: (id: EuCardReplacementTypeId) => void;

    insuranceCountryId: CountryId;
    onInsuranceCountryIdChange: (id: CountryId) => void;

    insuranceNumber: string;
    onInsuranceNumberChange: (value: string) => void;

    notes: string;
    onNotesChange: (value: string) => void;
}

function PatientEUCardIdentifier(props: IPatientEUCardIdentifierProps) {
    const errorDispatcher = useErrorDispatcher();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        props._dependencies.careReferenceDataStore.patientDocumentType.ensureAllLoadedAsync()
            .catch(errorDispatcher)
            .then(() => setIsLoaded(true));
    }, []);

    const patientIdentifier = useMemo(() => {
        const patientDocumentType = props._dependencies.careReferenceDataStore.patientDocumentType.items.find(i => i.Entity.id.value === props.identifierTypeId?.value);
        return patientDocumentType?.Entity;
    }, [isLoaded, props.identifierTypeId]);

    return (
        <>
            <Ui.Flex>
                {
                    patientIdentifier.identifierSystemId.value === "EuCardReplacement"
                        ?
                        <Ui.Flex.Item xs={4}>
                            <EuCardReplacementTypeSelectBox
                                label={StaticHunEHealthInfrastructureCareResources.PatientRegister.PatientDocumentPanel.Type}
                                value={props.euCardReplacementTypeId}
                                onChange={props.onEuCardReplacementTypeIdChange}
                                propertyIdentifier="EuCardReplacementTypeId"
                                automationId="EuCardReplacementTypeId"
                            />
                        </Ui.Flex.Item>
                        :
                        <Ui.Flex.Item xs={4}>
                            <Ui.TextBox
                                label={StaticCareResources.PatientRegister.PatientDocuments.Labels.Value}
                                value={props.identifierValue}
                                onChange={props.onIdentifierValueChange}
                                propertyIdentifier="IdentifierValue"
                                automationId="DocumentNumber"
                            />
                        </Ui.Flex.Item>
                }
                <Ui.Flex.Item xs={4}>
                    <PatientDocumentTypeSelectBox
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.DocumentType}
                        value={props.identifierTypeId}
                        onChange={props.onIdentifierTypeIdChange}
                        propertyIdentifier="DocumentTypeId"
                        automationId="DocumentTypeId"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={4}>
                    <></>
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex>
                <Ui.Flex.Item xs={4}>
                    <CountrySelector
                        label={StaticHunEHealthInfrastructureCareResources.PatientRegister.PatientDocumentPanel.InsuranceCountryLabel}
                        value={props.insuranceCountryId}
                        onChange={props.onInsuranceCountryIdChange}
                        propertyIdentifier="InsuranceCountryId"
                        automationId="InsuranceCountryId"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={4}>
                    <Ui.TextBox
                        label={StaticHunEHealthInfrastructureCareResources.PatientRegister.PatientDocumentPanel.InsuranceNumberLabel}
                        value={props.insuranceNumber}
                        onChange={props.onInsuranceNumberChange}
                        propertyIdentifier="InsuranceNumber"
                        automationId="InsuranceNumber"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={4}>
                    <></>
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex>
                <Ui.Flex.Item xs={8}>
                    <InsurerOrganizationSelectBox
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.Organization}
                        value={props.insurerOrganizationId}
                        onChange={props.onInsurerOrganizationIdChange}
                        propertyIdentifier="InsurerOrganizationId"
                        automationId="InsurerOrganizationId"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={4}>
                    {
                        !!props.insurerOrganizationId &&
                        <Ui.Button
                            text={StaticCareResources.PatientRegister.PatientDocuments.Labels.EditInsurer}
                            onClickAsync={() => showEditInsurerOrganizationDetailPanelAsync(props)}
                            permissionDeniedStyle="disabled"
                            permissionCheckOperationNames="EditInsurerOrganizations"
                            automationId="__editInsurerButton"
                            verticalAlign="input"
                        />
                    }
                    <Ui.Button
                        text={StaticCareResources.PatientRegister.PatientDocuments.Labels.NewInsurer}
                        onClickAsync={() => showNewInsurerOrganizationDetailPanelAsync(props)}
                        permissionDeniedStyle="disabled"
                        permissionCheckOperationNames="EditInsurerOrganizations"
                        automationId="__newInsurerButton"
                        verticalAlign="input"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex>
                <Ui.Flex.Item xs={4}>
                    <Ui.DatePicker
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.ValidFrom}
                        value={props.validFrom}
                        onChange={props.onValidFromChange}
                        propertyIdentifier="ValidFrom"
                        automationId="ValidFrom"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={4}>
                    <Ui.DatePicker
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.ValidTo}
                        value={props.validTo}
                        onChange={props.onValidToChange}
                        propertyIdentifier="ValidTo"
                        automationId="ValidTo"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={4}>
                    {renderIssuer(props, patientIdentifier.category)}
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex>
                <Ui.Flex.Item xs={10}>
                    <Ui.TextBox
                        label={StaticCareResources.PatientRegister.PatientDocuments.Labels.Notes}
                        value={props.notes}
                        onChange={props.onNotesChange}
                        propertyIdentifier="Notes"
                        automationId="Notes"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </>
    );
}

function renderIssuer(props: IPatientEUCardIdentifierProps, category: PatientDocumentCategory) {
    if (category === PatientDocumentCategory.PersonalIdentity) {
        return (
            <Ui.TextBox
                label={StaticCareResources.PatientRegister.PatientDocuments.Labels.Issuer}
                value={props.issuer}
                onChange={props.onIssuerChange}
                automationId="__issuer"
            />);
    }
    return (<></>);
}

async function showNewInsurerOrganizationDetailPanelAsync(props: IPatientEUCardIdentifierProps) {
    const result = await props._modalService.showDialogAsync<IInsurerOrganizationDetailPanelModalResult>(new InsurerOrganizationDetailPanelModalParams());
    if (!!result?.operationWasSuccesful) {
        props.onInsurerOrganizationIdChange(result?.insurerOrganizationId);
    }
}

async function showEditInsurerOrganizationDetailPanelAsync(props: IPatientEUCardIdentifierProps) {
    const result = await props._modalService.showDialogAsync<IInsurerOrganizationDetailPanelModalResult>(new InsurerOrganizationDetailPanelModalParams(props.insurerOrganizationId));
    if (!!result?.operationWasSuccesful) {
        this.props._validationBoundary.changed("InsurerOrganizationId");
    }
}

export default connect<IPatientEUCardIdentifierProps>(
    PatientEUCardIdentifier,
    new DependencyAdapter<IPatientEUCardIdentifierProps, IPatientEUCardIdentifierDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore")
        };
    }),
    new HisModalServiceAdapter());