import Di from "@Di";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import { IImmunizationScreenProps } from "./ImmunizationScreen";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import VaccineId from "@Primitives/VaccineId.g";
import ImmunizationApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/Immunization/ImmunizationApiAdapter";
import CustomTargetDiseaseAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/CustomTargetDiseaseAssignment";
import CustomVaccineAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/CustomVaccineAssignment";
import ImmunizationFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ImmunizationFactory";
import ImmunizationHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ImmunizationHistoryItem";
import ReferencedVaccineAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ReferencedVaccineAssignment";
import TargetDiseaseAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/TargetDiseaseAssignmentBase";
import VaccineAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/VaccineAssignmentBase";
import IMedicalConditionReferenceDataResolver from "@HisPlatform/BoundedContexts/Care/Services/Definition/MedicalConditionReferenceDataResolver/IMedicalConditionReferenceDataResolver";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import { VaccineCodeSelectorItem } from "@HisPlatform/Components/HisPlatformControls/VaccineCodeSelector/VaccineCodeSelectorItem";
import ShowCreateNewImmunizationScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowCreateNewImmunizationScreenAction.g";
import ShowImmunizationScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowImmunizationScreenAction.g";
import ImmunizationStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ImmunizationStore";
import CreateImmunizationAction from "@HisPlatform/Packages/Patients/FrontendActions/CreateImmunizationAction.g";
import DeleteImmunizationHistoryItemAction from "@HisPlatform/Packages/Patients/FrontendActions/DeleteImmunizationHistoryItemAction.g";
import EditImmunizationAction from "@HisPlatform/Packages/Patients/FrontendActions/EditImmunizationAction.g";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";

@Di.injectable()
export default class ImmunizationScreenStore extends EditorScreenPanelStoreBase<IImmunizationScreenProps> {

    @State.observable.ref public entity: ImmunizationStore = null;
    @State.computed public get currentHistoryItem() { return this.entity.currentHistoryItem as ImmunizationHistoryItem; }
    @State.computed public get isNew() { return this.props.action instanceof ShowCreateNewImmunizationScreenAction; }

    @State.computed protected get showScreenAction() { return this.props.action; }
    @State.computed protected get contentToDirtyCheck() { return [this.entity]; }
    @State.computed public get vIsReadOnly() { return this.canAcquireLock; }
    @State.computed public get isCreateNewImmunizationScreen() { return this.props.action instanceof ShowCreateNewImmunizationScreenAction; }
    @State.computed public get isImmunizationScreen() { return this.props.action instanceof ShowImmunizationScreenAction; }

    private get medicalConditionResources() { return StaticCareResources.MedicalCondition; }
    public get resources() { return StaticCareResources.MedicalCondition.Immunizations; }
    public get labels() { return this.resources.Label; }

    private get immunizationId() {
        if (this.props.action instanceof ShowImmunizationScreenAction)
            return this.props.action.immunizationId;

        throw new Error("Action is not ShowImmunizationScreenAction.");
    }

    private get patientId() {
        return this.props.action.patientId;
    }

    @State.computed
    public get createAction() {
        return ActionDescriptor.fromAction(new CreateImmunizationAction());
    }

    @State.computed
    public get editAction() {
        return ActionDescriptor.fromAction(new EditImmunizationAction());
    }

    @State.computed
    public get deleteHistoryItemAction() {
        return ActionDescriptor.fromAction(new DeleteImmunizationHistoryItemAction());
    }

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("ImmunizationApiAdapter") private readonly immunizationApiAdapter: ImmunizationApiAdapter,
        @Di.inject("ImmunizationFactory") private readonly immunizationFactory: ImmunizationFactory,
        @Di.inject("IMedicalConditionReferenceDataResolver") private readonly medicalConditionReferenceDataResolver: IMedicalConditionReferenceDataResolver
    ) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    protected saveFunction: () => Promise<boolean> = this.onSaveAsync;

    @State.bound
    public async loadCoreAsync(forceReleaseLockNeeded: boolean = false) {

        if (forceReleaseLockNeeded && !!this.entity?.lockInfo?.preventingLockId) {
            await this.lockingApiAdapter.forceReleaseLockAsync(this.entity.lockInfo.preventingLockId);
        }

        let entity = null;
        if (this.isNew) {
            entity = this.immunizationFactory.createNewStore();
        } else {
            entity = (await this.immunizationApiAdapter.getByIdAsync(this.immunizationId, true)).result;
        }
        await this.setEntityAsync(entity);
    }

    @State.action.bound
    private async setEntityAsync(newValue: ImmunizationStore) {
        this.entity = newValue;
        const vaccineName = await this.medicalConditionReferenceDataResolver.getVaccineNameAsync(this.entity.vaccineCodeSelectorItem);
        this.entity.setVaccineName(vaccineName);
        this.vSetValidationResults(this.entity.validationResults);
        this.lockInfo = this.entity.lockInfo;
    }

    @State.bound
    public async onSaveAsync(): Promise<any> {
        await this.saveEntityAsync();
    }

    @State.action.bound
    public async deleteHistoryItemAsync() {
        const message = formatString(this.resources.Dialog.DeleteVersionConfirmationMessage, (this.entity.currentItemIndex + 1).toString());
        const dialogResult = await this.dialogService.yesNo(StaticCareResources.Common.Dialog.ConfirmationTitle, message);

        if (dialogResult.resultCode === DialogResultCode.No) {
            return;
        }

        await this.setExtensionDataAsync();

        if (this.entity.historyItems.length === 1) {
            await this.handleSaveResultAsync(() => this.immunizationApiAdapter.deleteImmunizationAsync(this.entity));
            this.entity.releaseLock();
            this.releaseLock();
            this.props._screenState.deleted();
        } else {
            const response = await this.handleSaveResultAsync(() => this.immunizationApiAdapter.deleteImmunizationHistoryItemAsync(this.entity, this.entity.currentHistoryItem.versionNumber));
            this.props._screenState.savedExisting();
            await this.replaceEntityAsync(response.result);
        }
    }

    @State.action.bound
    private async setExtensionDataAsync() {

        const extensionDataArray = await this.props._formExtension.invokeCallbackAsync<any>("ExtendStore", this.props.action.patientId);

        let extensionDataForStore = this.entity.extensionData;
        for (const extensionData of extensionDataArray) {
            extensionDataForStore = { ...extensionData, ...extensionDataForStore };
        }

        this.entity.setExtensionData(extensionDataForStore);
    }

    @State.action.bound
    private async replaceEntityAsync(newEntity: ImmunizationStore) {
        if (newEntity.operationWasSuccessful) {
            if (newEntity.isPersistedByOperationInfo) {
                newEntity.lockInfo = this.entity.lockInfo;
                await this.setEntityAsync(newEntity);
            } else {
                State.runInAction(() => {
                    this.vSetValidationResults(newEntity.validationResults);
                    this.entity.validationResults = newEntity.validationResults;
                });
            }
        }
    }

    @State.action.bound
    public async validateAsync(): Promise<IClientValidationResult[]> {
        if (!this.entity || !this.entity.isMutable || !this.currentHistoryItem.isNew) {
            return [];
        }

        const operationResult = this.entity.isNew
            ? await this.immunizationApiAdapter.validateNewItemAsync(this.entity, this.patientId)
            : await this.immunizationApiAdapter.validateExistingItemAsync(this.entity);

        return operationResult.result;
    }

    @State.action.bound
    public async saveEntityAsync() {
        await this.setExtensionDataAsync();

        const operationResult = this.isNew
            ? await this.handleSaveResultAsync(() => this.immunizationApiAdapter.addImmunizationAsync(this.entity, this.patientId))
            : await this.handleSaveResultAsync(() => this.immunizationApiAdapter.updateImmunizationAsync(this.entity));

        if (!operationResult.isPersisted)
            return false;

        if (this.isNew) {
            this.props._screenState.savedNew(operationResult.result.id, new ShowImmunizationScreenAction(this.props.action.displayMode, this.props.action.patientId, operationResult.result.id));
        } else {
            this.props._screenState.savedExisting();
        }

        await this.replaceEntityAsync(operationResult.result);
        return true;
    }

    @State.action.bound
    public async forceLoadAsync() {
        await this.loadCoreAsync(true);
    }

    @State.bound
    public setVaccineAssignment(newValue: VaccineCodeSelectorItem) {
        let vaccineAssignment: VaccineAssignmentBase;
        if (!newValue) {
            vaccineAssignment = new ReferencedVaccineAssignment(null);
        } else if (newValue.id instanceof VaccineId) {
            vaccineAssignment = new ReferencedVaccineAssignment(newValue.id);
        } else {
            vaccineAssignment = new CustomVaccineAssignment(newValue.text);
        }
        this.entity.setVaccineAssignment(vaccineAssignment);
    }

    @State.bound
    public setTargetDiseaseAssignment(newValue: string) {
        let targetDiseaseAssignment: TargetDiseaseAssignmentBase;
        if (!newValue) {
            targetDiseaseAssignment = new CustomTargetDiseaseAssignment(null);
        } else {
            targetDiseaseAssignment = new CustomTargetDiseaseAssignment(newValue);
        }
        this.currentHistoryItem.setTargetDiseaseAssignment(targetDiseaseAssignment);
    }

    @State.bound
    public showNoEhiCompatibleIdentifierFoundError() {
        this.notificationService.error(this.medicalConditionResources.Common.Messages.NoEhiCompatibleIdentifierFoundErrorMessage);
        return true;
    }
}