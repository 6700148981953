import React, { useContext, useLayoutEffect, useCallback } from "react";
import { Modal, PageBox2 } from "@CommonControls";
import { UseCaseFrameContext } from "@HisPlatform/Components/UseCaseFrame/UseCaseFrameContext";
import { ModalSize } from "@CommonControls/Modal";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import { ContextAwareModal } from "@HisPlatformControls";
import MasterDetailDetail from "@CommonControls/Layout/MasterDetailDetail";
import { iconNameType } from "@CommonControls/Icon";
import UseCaseFrameType from "@HisPlatform/Components/UseCaseFrame/UseCaseFrameType";
import { IUseCaseFrameTabProps } from "@HisPlatform/Components/UseCaseFrame/UseCaseFrameTab";
import MasterDetailDetailTab from "@CommonControls/Layout/MasterDetailDetailTab";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

export interface IUseCaseFrameProps {
    /** PageBox, MD-Detail or Modal title */
    title?: string;

    /** PageBox, MD-Detail or Modal subtitle */
    subTitle?: string;

    /** PageBox, MD-Detail or Modal icon */
    iconName?: iconNameType;

    /** PageBox, MD-Detail toolbar */
    toolbar?: React.ReactNode;

    /** PageBox, MD-Detail or Modal footer */
    rightFooter?: React.ReactNode;

    /** PageBox, MD-Detail or Modal footer */
    leftFooter?: React.ReactNode;

    /** Show PageBox, MD-Detail or Modal tabs */
    hasTabs?: boolean;
    initiallyActiveTabName?: string;

    modalSize?: ModalSize;
    modalCloseEvent?: TypedEvent<boolean>;
    modalOnClose?: (wasSuccessful: boolean) => void;
    modalMaxHeight?: number | string;

    fallbackFrameType?: UseCaseFrameType;
    isLoading?: boolean;
}

const UseCaseFrame: React.FC<IUseCaseFrameProps> = props => {

    const frameContext = useContext(UseCaseFrameContext);

    useLayoutEffect(() => props.modalCloseEvent?.on(wasSuccessful => {
        frameContext?.onClose?.(wasSuccessful);
        props.modalOnClose?.(wasSuccessful);
    }).dispose, [props.modalCloseEvent, frameContext?.onClose, props.modalOnClose]);

    const cancel = useCallback(() => {
        frameContext?.onClose?.(false);
        props.modalOnClose?.(false);
    }, [frameContext?.onClose, props.modalOnClose]);

    switch (frameContext?.frameType ?? props.fallbackFrameType ?? "None") {
        case "Modal":
            return (
                <ContextAwareModal
                    title={props.title}
                    iconName={props.iconName}
                    isOpen
                    closeButton
                    onClose={cancel}
                    size={props.modalSize}
                    maxHeight={props.modalMaxHeight}
                >
                    <LoadingBoundary {...props}>
                        <Modal.Body>{props.children}</Modal.Body>
                        {(props.leftFooter || props.rightFooter) && (
                            <Modal.Footer left={props.leftFooter} right={props.rightFooter} />
                        )}
                    </LoadingBoundary>
                </ContextAwareModal>
            );
        case "MasterDetailDetail":

            if (props.hasTabs) {
                return (
                    <MasterDetailDetail
                        title={props.title}
                        subTitle={props.subTitle}
                        iconName={props.iconName}
                        toolbar={props.toolbar}
                        hasSidePadding
                        initiallyActiveTabName={props.initiallyActiveTabName}
                    >
                        {renderMasterDetailDetailTabs(props)}
                    </MasterDetailDetail>
                );
            }

            return (
                <MasterDetailDetail
                    title={props.title}
                    subTitle={props.subTitle}
                    iconName={props.iconName}
                    toolbar={props.toolbar}
                    hasSidePadding
                    footerLeftToolbar={props.leftFooter}
                    footerRightToolbar={props.rightFooter}
                >
                    {props.children}
                </MasterDetailDetail>
            );
        case "PageBox":

            if (props.hasTabs) {
                return (
                    <PageBox2 hasTabs initiallyActiveTabName={props.initiallyActiveTabName}>
                        {renderPageBoxTabs(props)}
                    </PageBox2>
                );
            } else {
                return (
                    <PageBox2>
                        <PageBox2.Header
                            title={props.title}
                            subTitle={props.subTitle}
                            iconName={props.iconName}
                            toolbar={props.toolbar}
                        />
                        <PageBox2.Body hasSidePadding>
                            {props.children}
                        </PageBox2.Body>
                        {(props.leftFooter || props.rightFooter) && (
                            <PageBox2.Footer leftToolbar={props.leftFooter} rightToolbar={props.rightFooter} />
                        )}
                    </PageBox2 >
                );
            }
    }

    return <>{props.children}</>;
};

function renderPageBoxTabs(props: React.PropsWithChildren<IUseCaseFrameProps>) {
    return React.Children.map(props.children, (tab: React.ReactElement<React.PropsWithChildren<IUseCaseFrameTabProps>>) => (
        <PageBox2.Tab tabName={tab.props.tabName} tabTitle={tab.props.tabTitle}>
            <PageBox2.Header
                iconName={tab.props.iconName}
                title={tab.props.title}
                subTitle={tab.props.subTitle}
                toolbar={tab.props.toolbar}
            />
            <PageBox2.Body>
                {tab.props.children}
            </PageBox2.Body>
        </PageBox2.Tab>
    ));
}

function renderMasterDetailDetailTabs(props: React.PropsWithChildren<IUseCaseFrameProps>) {
    return React.Children.map(props.children, (tab: React.ReactElement<React.PropsWithChildren<IUseCaseFrameTabProps>>) => (
        <MasterDetailDetailTab
            tabName={tab.props.tabName}
            tabTitle={tab.props.tabTitle}
            iconName={tab.props.iconName}
            title={tab.props.title}
            subTitle={tab.props.subTitle}
            toolbar={tab.props.toolbar}
            footerLeftToolbar={props.leftFooter}
            footerRightToolbar={props.rightFooter}
            automationId={`calendar_${tab.props.tabName}_tab`}
            propertyPathRegexPattern={tab.props.propertyPathRegexPattern}
        >
            {tab.props.children}
        </MasterDetailDetailTab>
    ));
}

export default UseCaseFrame;
