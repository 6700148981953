import CareActivityId from "@Primitives/CareActivityId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import MasterDetailLayout from "@CommonControls/Layout/MasterDetailLayout";
import MasterDetailMaster from "@CommonControls/Layout/MasterDetailMaster";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { DataGridLoadType, IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import { IPermanentPrescriptionListItem } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IPermanentPrescriptionListItem";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import PermanentPrescriptionListView from "./PermanentPrescriptionListView";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import PatientId from "@Primitives/PatientId.g";
import PermanentPrescriptionFilterStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/PermanentPrescriptionListPanel/Filter/PermanentPrescriptionFilterStore";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import HealthcareProfessionId from "@Primitives/HealthcareProfessionId.g";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import PermanentPrescriptionReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PermanentPrescriptionReferenceDataStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import _ from "@HisPlatform/Common/Lodash";

interface IPermanentPrescriptionMasterDetailPanelDependencies {
    careActivityApiAdapter: CareActivityApiAdapter2;
    prescriptionApiAdapter: PrescriptionApiAdapter;
    structureApiAdapter: StructureApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
    medicationRequestReferenceDataStore: MedicationRequestReferenceDataStore;
    permanentPrescriptionReferenceDataStore: PermanentPrescriptionReferenceDataStore;
}

interface IPermanentPrescriptionMasterDetailPanelProps {
    _dependencies?: IPermanentPrescriptionMasterDetailPanelDependencies;
    careActivityId: CareActivityId;
    onBack: () => void;
    _patientId?: PatientId;
}

@State.observer
class PermanentPrescriptionMasterDetailPanel extends React.Component<IPermanentPrescriptionMasterDetailPanelProps> {
    @State.observable.ref private permanentPrescriptions: PagedItemStore<IPermanentPrescriptionListItem> = new PagedItemStore([], 0);
    @State.observable public paging: IPagingState = { currentPage: 0, pageSize: 20 };
    @State.observable public ordering: IOrderingState = { columnId: "CreatedAt", direction: "desc" };
    @State.observable private isLoading: boolean = false;
    @State.observable.ref private currentOrganizationUnitId: OrganizationUnitId = null;
    @State.observable.ref private currentHealthcareProfessionIds: HealthcareProfessionId[] = [];
    @State.observable.ref private relatedOrganizationUnitIds: OrganizationUnitId[] = [];
    private isInitialized: boolean = false;
    private readonly filterStore = new PermanentPrescriptionFilterStore(this.loadAsync);
    private readonly initializePanelAsync = createInitialPanelLoader(this.initializeAsync);

    @State.computed
    private get managedOrganizationUnitIds(): OrganizationUnitId[] {
        return !!this.currentOrganizationUnitId ? [this.currentOrganizationUnitId] : [];
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.initializePanelAsync(), this);
    }

    public render() {
        return (
            <SingleLayout>
                <MasterDetailLayout>
                    <MasterDetailMaster
                            title={StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionListPanel.MasterTitle}
                            iconName="pills">
                        {(this.initializePanelAsync.isUnauthorizedAccess) ? <UnauthorizedAccessContent /> : this.renderMaster()}
                    </MasterDetailMaster>
                </MasterDetailLayout>
            </SingleLayout>
        );
    }

    @State.bound
    private renderMaster() {
        return (
            <PermanentPrescriptionListView
                filterStore={this.filterStore}
                isLoading={this.isLoading}
                permanentPrescriptions={this.permanentPrescriptions}
                managedOrganizationUnitIds={this.managedOrganizationUnitIds}
                paging={this.paging}
                ordering={this.ordering}
                onGridChangeAsync={this.onGridChangedAsync}
                onBack={this.props.onBack}
                onSelectOrganizationUnitByPointOfCare={this.selectOrganizationUnitByPointOfCare}
                onSelectOrganizationUnitByProfession={this.selectOrganizationUnitByProfession}
                onPermanentPrescriptionDeleted={() => this.loadAsync()} />
        );
    }

    @State.bound
    private async initializeAsync() {
        await this.loadOrganizationDataAsync();
        this.selectOrganizationUnitByPointOfCare();
        await this.loadAsync(true);
        this.isInitialized = true;
    }

    @State.boundLoadingState("isLoading")
    private async loadAsync(isForced: boolean = false) {
        if (!this.isInitialized && !isForced)
            return;

        const permanentPrescriptions = await this.props._dependencies.prescriptionApiAdapter.getPermanentPrescriptionsAsync(
            this.props.careActivityId,
            this.props._patientId,
            this.paging,
            this.ordering,
            this.filterStore.organizationUnitIds
        );
        await this.props._dependencies.careReferenceDataStore.condition.ensureLoadedAsync(permanentPrescriptions.items.map(i => new EntityVersionSelector(i.conditionId, i.createdAt)));
        await this.props._dependencies.medicationRequestReferenceDataStore.medicationSubsidyClaimTypes.ensureLoadedAsync(permanentPrescriptions.items.map(i => i.claimTypeId));
        await this.props._dependencies.permanentPrescriptionReferenceDataStore.dosages.ensureLoadedAsync(permanentPrescriptions.items.map(i => i.id));
        this.setPermanentPrescriptions(permanentPrescriptions);
    }

    @State.action.bound
    private async loadOrganizationDataAsync() {
        this.setCurrentOrganizationUnitId(null);
        this.setCurrentHealthcareProfessionIds(null);
        this.setRelatedOrganizationUnitIds([]);

        if (!this.props.careActivityId)
            return;

        const careActivity = await this.props._dependencies.careActivityApiAdapter.loadByIdAsync(this.props.careActivityId);
        if (!careActivity)
            return;

        this.setCurrentOrganizationUnitId(careActivity.pointOfCareId);
        if (this.currentOrganizationUnitId) {
            const healthcareProfessionIdsStore = await this.props._dependencies.structureApiAdapter.getHealthCareProfessionIdsByOrganizationUnitIdAsync(this.currentOrganizationUnitId);
            if (healthcareProfessionIdsStore)
                this.setCurrentHealthcareProfessionIds(healthcareProfessionIdsStore.value);
        }

        const organizationUnitIdsStore = await this.props._dependencies.structureApiAdapter.getPointOfCareIdsByHealthCareProfessionIdsQueryAsync(this.currentHealthcareProfessionIds);
        this.setRelatedOrganizationUnitIds(organizationUnitIdsStore?.value ?? []);
    }

    @State.action.bound
    private setPermanentPrescriptions(value: PagedItemStore<IPermanentPrescriptionListItem>): void {
        this.permanentPrescriptions = value;
    }

    @State.action.bound
    private setCurrentOrganizationUnitId(value: OrganizationUnitId): void {
        this.currentOrganizationUnitId = value;
    }

    @State.action.bound
    private setCurrentHealthcareProfessionIds(value: HealthcareProfessionId[]): void {
        this.currentHealthcareProfessionIds = value;
    }

    @State.action.bound
    private setRelatedOrganizationUnitIds(value: OrganizationUnitId[]): void {
        this.relatedOrganizationUnitIds = value;
    }

    @State.action.bound
    private selectOrganizationUnitByPointOfCare(): void {
        if (!this.filterStore)
            return;

        this.filterStore.setOrganizationUnitIds([this.currentOrganizationUnitId]);
    }

    @State.action.bound
    private selectOrganizationUnitByProfession(): void {
        if (!this.filterStore)
            return;

        this.filterStore.setOrganizationUnitIds(this.relatedOrganizationUnitIds);
    }

    @State.action.bound
    private async onGridChangedAsync(type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState | IOrderingState[]) {
        if (type === "changed") {
            this.paging = paging;
            this.ordering = _.isArray(ordering) ? ordering[0] : ordering as IOrderingState;
            await this.loadAsync();
        }
    }
}

export default connect(
    PermanentPrescriptionMasterDetailPanel,
    new DependencyAdapter<IPermanentPrescriptionMasterDetailPanelProps, IPermanentPrescriptionMasterDetailPanelDependencies>(container => ({
        careActivityApiAdapter: container.resolve("CareActivityApiAdapter2"),
        prescriptionApiAdapter: container.resolve("PrescriptionApiAdapter"),
        structureApiAdapter: container.resolve("StructureApiAdapter"),
        careReferenceDataStore: container.resolve("CareReferenceDataStore"),
        medicationRequestReferenceDataStore: container.resolve("MedicationRequestReferenceDataStore"),
        permanentPrescriptionReferenceDataStore: container.resolve("PermanentPrescriptionReferenceDataStore")
    })),
    new PatientContextAdapter<IPermanentPrescriptionMasterDetailPanelProps>(patientContext => ({
        _patientId: patientContext.patientId
    }))
);