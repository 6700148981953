import React from "react";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import EhiUserContext from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiUserContext";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ModalServiceAdapter from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiTokenProvider from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Services/EhiTokenProvider/EhiTokenProvider";

interface IEhiGlobalBusinessErrorHandlersDependencies {
    notificationService: INotificationService;
    ehiUserContext: EhiUserContext;
    userContext: UserContext;
    ehiTokenProvider: EhiTokenProvider;
}

interface IEhiGlobalBusinessErrorHandlersProps {
    _dependencies?: IEhiGlobalBusinessErrorHandlersDependencies;
    _modalService?: IModalService;
}


class EhiGlobalBusinessErrorHandlers extends React.Component<IEhiGlobalBusinessErrorHandlersProps> {

    @State.bound
    private handleEhiSamlError() {
        this.props._dependencies.ehiUserContext.clearToken();

        dispatchAsyncErrors(this.handleEhiSamlErrorAsync(), this);

        return true;
    }

    @State.bound
    private async handleEhiSamlErrorAsync() {
        await this.props._dependencies.ehiTokenProvider.loginAsync(this.props._dependencies.userContext.practitionerId);
    }

    public render() {
        return (
            <>
                <BusinessErrorHandler.Register businessErrorName="EhiSamlError" handler={this.handleEhiSamlError} />
            </>
        );
    }
}

export default connect(
    EhiGlobalBusinessErrorHandlers,
    new DependencyAdapter(c => ({
        notificationService: c.resolve("INotificationService"),
        ehiUserContext: c.resolve("EhiUserContext"),
        userContext: c.resolve("UserContext"),
        ehiTokenProvider: c.resolve("EhiTokenProvider")
    })),
    new ModalServiceAdapter()
);