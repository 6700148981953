import DependencyContainer from "@DiContainer";
import DashboardsApiAdapter from "@HisPlatform/BoundedContexts/Dashboards/ApplicationLogic/ApiAdapter/Dashboards/DashboardsApiAdapter";
import CareActivitiesByInsurerOrganizationWidgetApiAdapter from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/CareActivitiesByInsurerOrganizationWidget/CareActivitiesByInsurerOrganizationWidgetApiAdapter";
import CareActivitiesByDurationWidgetApiAdapter from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/CareActivitiesByDurationWidget/CareActivitiesByDurationWidgetApiAdapter";
import GrossNeakIncomeWidgetApiAdapter from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/GrossNeakIncomeWidget/GrossNeakIncomeWidgetApiAdapter";
import RelevantServicesWidgetApiAdapter from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/RelevantServicesWidget/RelevantServicesWidgetApiAdapter";
import ScalarValueChartWidgetApiAdapter from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/ScalarValueChartWidget/ScalarValueChartWidgetApiAdapter";
import ReportingWidgetApiAdapter from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/ComorbidityWidget/ReportingWidgetApiAdapter";

DependencyContainer
    .bind("DashboardsApiAdapter")
    .to(DashboardsApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("CareActivitiesByInsurerOrganizationWidgetApiAdapter")
    .to(CareActivitiesByInsurerOrganizationWidgetApiAdapter);

DependencyContainer
    .bind("CareActivitiesByDurationWidgetApiAdapter")
    .to(CareActivitiesByDurationWidgetApiAdapter);

DependencyContainer
    .bind("GrossNeakIncomeWidgetApiAdapter")
    .to(GrossNeakIncomeWidgetApiAdapter);

DependencyContainer
    .bind("RelevantServicesWidgetApiAdapter")
    .to(RelevantServicesWidgetApiAdapter);

DependencyContainer
    .bind("ScalarValueChartWidgetApiAdapter")
    .to(ScalarValueChartWidgetApiAdapter);

DependencyContainer
    .bind("ReportingWidgetApiAdapter")
    .to(ReportingWidgetApiAdapter);
