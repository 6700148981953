import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Styles from "@CommonControls/CheckBox//CheckBox.less";
import { FieldValidationResult } from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ReadOnlyContextAdapter from "@Toolkit/ReactClient/Components/ReadOnlyContext/ReadOnlyContextAdapter";
import ValidationBoundaryAdapter from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryAdapter";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import { arrayIsNullOrEmpty, emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EventHandler from "@Toolkit/ReactClient/Components/EventHandler/EventHandler";
import { IButtonProps } from "@CommonControls/Button";
import CheckBoxCore from "@CommonControls/CheckBox/CheckBoxCore";
import CheckBoxGroupStore from "./CheckBoxGroupStore";
import CheckBoxGroupItemStore from "./CheckBoxGroupItemStore";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";

export interface ICheckBoxGroupBaseProps<TItem> {
    isRequired?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    automationId?: string;

    onChange?: (key: string, newSelectionState: boolean | null) => void;
    getDisplayValue?: (item: TItem) => string;
    getToggleButtonProps?: (value: TItem) => IButtonProps;

    label?: string;
    inline?: boolean;

    direction?: "row" | "column";
    displayType?: "check" | "switch" | "toggle-button";
    verticalAlign?: "noPadding" | "normal" | "inlineInput";

    alignItems?: "left" | "right" | "center";
    justifyItems?: "around" | "between" | "evenly";

    itemLabelPosition?: "left" | "right";
    itemVerticalSpacing?: "normal" | "noPadding";

    itemCanBeNull?: ((item: TItem) => boolean) | boolean;
    stopClickPropagation?: boolean;

    visualMode?: "dark" | "light";

    propertyIdentifier?: string;
    showMultipleValidationProblems?: boolean;
}

export interface ICheckBoxGroupProps<TItem> extends ICheckBoxGroupBaseProps<TItem> {
    store: CheckBoxGroupStore<TItem>;
}

interface ICheckBoxGroupCoreProps<TItem> extends ICheckBoxGroupProps<TItem> {
    validationContext: IValidationBoundaryStore;
}

let defaultAutomationId = 0;

@State.observer
class CheckBoxGroupCore<TItem> extends React.Component<ICheckBoxGroupCoreProps<TItem>> {

    public static defaultProps: Partial<ICheckBoxGroupProps<any>> = {
        itemCanBeNull: item => false,
        getDisplayValue: item => item,
        getToggleButtonProps: item => undefined,

        direction: "row",
        displayType: "check",
        verticalAlign: "noPadding",
    
        itemLabelPosition: "left",
        itemVerticalSpacing: "noPadding",

        visualMode: "light"
    };

    public componentDidUpdate(prevProps: ICheckBoxGroupCoreProps<TItem>) {
        if (prevProps.store !== this.props.store) {
            this.validate();
        }
    }

    private getClassName() {
        const classes = this.props.direction === "row" ? new CompositeClassName(Styles.groupContainerRow) : new CompositeClassName(Styles.groupContainerColumn);

        if (this.props.direction === "row") {
            if (this.props.alignItems !== null && this.props.alignItems !== undefined) {
                classes.addIf(this.props.alignItems === "left", Styles.justifyContentStart);
                classes.addIf(this.props.alignItems === "center", Styles.justifyContentCenter);
                classes.addIf(this.props.alignItems === "right", Styles.justifyContentEnd);
            } else if (this.props.justifyItems !== null && this.props.justifyItems !== undefined) {
                classes.addIf(this.props.justifyItems === "around", Styles.justifyContentAround);
                classes.addIf(this.props.justifyItems === "between", Styles.justifyContentBetween);
                classes.addIf(this.props.justifyItems === "evenly", Styles.justifyContentEvenly);
            }
        }

        if (this.props.verticalAlign !== null && this.props.verticalAlign !== undefined) {
            classes.addIf(this.props.verticalAlign === "normal", Styles.normalTopPadding);
            classes.addIf(this.props.verticalAlign === "noPadding", Styles.noTopPadding);
            classes.addIf(this.props.verticalAlign === "inlineInput", Styles.inlineInputTopPadding);
        }

        return classes.classNames;
    }

    @State.computed
    private get validationProblems() {
        if (this.props.validationContext) {
            const problems = this.props.validationContext.getValidationProblems(this.props.propertyIdentifier);
            return arrayIsNullOrEmpty(problems) ? emptyArray : problems;
        }
        return emptyArray;
    }

    @State.computed
    private get validationResult() {
        return this.validationProblems.length === 0 ? null : this.validationProblems[0];
    }

    @State.computed private get isRequired() {
        return this.props.isRequired || (
            this.props.validationContext && this.props.validationContext.isRequired(this.props.propertyIdentifier)
        );
    }

    @State.action.bound
    private internalCheckHandler(key: string) {
        return (newSelectionState: boolean) => {
            this.props.onChange(key, newSelectionState);
        };
    }

    private renderValidationResults(): React.ReactNode {
        if (!this.props.disabled) {
            return <FieldValidationResult problems={this.validationProblems} showMultipleProblems={this.props.showMultipleValidationProblems} />;
        }
        return null;
    }

    @State.bound
    private validate() {
        if (this.props.validationContext) {
            this.props.validationContext.changed(this.props.propertyIdentifier, this.props.store);
        }
    }

    public render() {
        const keys = this.props.store ? this.props.store.getKeys() : [];

        const automationId = this.props.automationId ? this.props.automationId : `radio_${defaultAutomationId++}`;

        return (
            <div style={this.props.inline ? { display: "inline-block" } : undefined} data-automation-id={automationId}>
                {this.props.label && <label className={Styles.label}>{this.props.label}{this.isRequired && <span className={Styles.requiredStar} />}</label>}
                <div className={this.getClassName()}>
                    {keys.map(key =>  {
                        return (
                            <CheckBoxCore
                                key={key}
                                label={this.props.getDisplayValue(this.props.store.get(key).value)}
                                disabled={this.props.disabled}
                                value={this.props.store.get(key).selectionState}
                                onChange={this.internalCheckHandler(key)}
                                canBeNull={typeof(this.props.itemCanBeNull) === "boolean" ? (this.props.itemCanBeNull as boolean) : (this.props.itemCanBeNull as ((item: TItem) => boolean))(this.props.store.get(key).value)}
                                align={this.props.alignItems}
                                automationId={automationId}
                                verticalAlign={this.props.itemVerticalSpacing}
                                containerStyle={"grouped"}
                                stopClickPropagation={this.props.stopClickPropagation}
                                displayMode={this.props.displayType}
                                labelPosition={this.props.itemLabelPosition}
                                visualMode={this.props.visualMode}
                                isReadOnly={this.props.readOnly}
                                toggleButtonProps={this.props.getToggleButtonProps(this.props.store.get(key).value)}
                                validationProblemSeverity={this.validationResult ? this.validationResult.severity : undefined}
                            />
                        );
                    })}
                </div>
                {this.renderValidationResults()}
                <EventHandler event={this.props.validationContext?.validateAllEvent} onFired={this.validate} />
            </div>
        );
    }
}


const CheckBoxGroup = connect(
    CheckBoxGroupCore,
    new ReadOnlyContextAdapter<ICheckBoxGroupCoreProps<any>>((props, isReadOnly) => ({ readOnly: props.readOnly || isReadOnly })),
    new ValidationBoundaryAdapter<ICheckBoxGroupCoreProps<any>>((props, validationStore) => ({ validationContext: validationStore }))
);

export default CheckBoxGroup;

export function CheckBoxGroupFactory<T>() {
    return CheckBoxGroup as React.ComponentType<ICheckBoxGroupProps<T>>;
}