import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import moment from "moment";

export default class TimePhaseInterval {
    @State.observable.ref public start: TimeOfDay = null;
    @State.observable.ref public end: TimeOfDay = null;

    public static createFromString(str: string) {
        if (!!str) {
            const split = str.split("-");
            if (split.length === 2) {
                const interval = new TimePhaseInterval();
                interval.setInterval(TimeOfDay.createFromString(split[0]), TimeOfDay.createFromString(split[1]));
                return interval;
            }
        }
        return null;
    }

    public static areEquals(a: TimePhaseInterval, b: TimePhaseInterval) {
        if (a === b) {
            return true;
        }

        if (!a && !b) {
            return true;
        }

        if (a && b) {
            return TimeOfDay.areEquals(a.start, b.start) && TimeOfDay.areEquals(a.end, b.end);
        }

        return false;
    }

    @State.action.bound public setInterval(from: TimeOfDay, to: TimeOfDay) {
        this.setStart(from);
        this.setEnd(to);
    }

    @State.action.bound public setStart(from: TimeOfDay) {
        this.start = from;
    }

    @State.action.bound public setEnd(to: TimeOfDay) {
        this.end = to;
    }

    @State.computed
    public get lengthInMinutes() {
        if (!!this.start && !! this.end) {
            return this.end.valueInMinutes - this.start.valueInMinutes;
        }
        return null;
    }

    public static createFromMoments(from: moment.Moment, to: moment.Moment) {
        if (!!from && !!to) {
            const interval = new TimePhaseInterval();
            interval.setInterval(TimeOfDay.createFromMoment(from), TimeOfDay.createFromMoment(to));
            return interval;
        }
        return null;
    }
}