exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Led_label_2Ckdq {\n  width: 100%;\n  font-family: PragatiNarrow;\n  font-size: 16px;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 1.14;\n  letter-spacing: normal;\n  text-align: left;\n  color: #8695a7;\n  margin: 0 5px;\n}\n.Led_led-container_FBe85 {\n  height: 32px;\n  margin: 0 4px;\n  display: flex;\n  align-items: center;\n}\n.Led_led_20mtx {\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n}\n.Led_inner-label_2pC9C {\n  margin-left: 5px;\n  color: #004358;\n}\n.Led_inner-label-dark_1EE72 {\n  color: white;\n}\n.Led_border_6u1pd {\n  border: 1px solid black;\n}\n", ""]);

// exports
exports.locals = {
	"label": "Led_label_2Ckdq",
	"led-container": "Led_led-container_FBe85",
	"ledContainer": "Led_led-container_FBe85",
	"led": "Led_led_20mtx",
	"inner-label": "Led_inner-label_2pC9C",
	"innerLabel": "Led_inner-label_2pC9C",
	"inner-label-dark": "Led_inner-label-dark_1EE72",
	"innerLabelDark": "Led_inner-label-dark_1EE72",
	"border": "Led_border_6u1pd"
};