import React from "react";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { providePatientAgeStore, usePatientAgeStore } from "./PatientAgeStoreProvider";
import Styles from "@HisPlatform/BoundedContexts/Care/Components/Panels/PatientRegister/PatientAdministrativeDataPanel/PatientAdministrativeDataPanel.less";
import StaticResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import Flex from "@CommonControls/Flex";
import FlexItem from "@CommonControls/Flex/FlexItem";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";


export interface IPatientAgeProps {
    birthDate: LocalDate,
    deathDate?: LocalDate
}

function PatientAge(props: IPatientAgeProps) {
    const store = usePatientAgeStore();

    return (
        <Flex>
            <FlexItem xs={3}>
                <>
                    <label className={Styles.estimatedLabel}>{StaticResources.PatientRegister.PatientBaseData.Label.Age}</label> <br />
                    <b className={Styles.estimatedValue}>{isNullOrUndefined(store.age) ? null : store.age.get()}</b>
                </>
            </FlexItem>
        </Flex>
    );
}

export default providePatientAgeStore(PatientAge);