import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import PractitionerRecommendationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/PractitionerRecommendation/PractitionerRecommendationApiAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/PractitionerRecommendation";
import PractitionerRecommendationListView from "./PractitionerRecommendationListView";
import PractitionerRecommendationDetailView from "./PractitionerRecommendationDetailView";
import * as Ui from "@CommonControls";
import PractitionerRecommendationId from "@Primitives/PractitionerRecommendationId.g";
import PanelController from "@Toolkit/ReactClient/Components/PanelController";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import { isNullOrUndefined, nullFunction } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import PractitionerRecommendationFilterStore from "./PractitionerRecommendationFilterStore";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import MedicationPricingAndSubsidies from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationPricingAndSubsidies";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import MedicationId from "@Primitives/MedicationId.g";
import ReferenceDataApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/ReferenceDataApiAdapter";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import { LockIndicatorComponent } from "@HisPlatform/Components/HisPlatformControls";
import PractitionerRecommendationStatus from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/PractitionerRecommendationStatus";
import DocumentPreviewModalParams from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Modals/DocumentPreviewModal/DocumentPreviewModalParams";
import _ from "@HisPlatform/Common/Lodash";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import MedicationSubsidyOptionId from "@Primitives/MedicationSubsidyOptionId.g";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import MedicationPractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/MedicationPractitionerRecommendation";
import EquipmentPractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/EquipmentPractitionerRecommendation";
import { RecommendationType } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/RecommendationType";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import MedicationSubsidyId from "@Primitives/MedicationSubsidyId.g";
import DiagnosisListApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/DiagnosisList/DiagnosisListApiAdapter";
import LateralityId from "@Primitives/LateralityId.g";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";
import DiagnosisStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisStore";
import PractitionerRecommendationDocumentApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/PractitionerRecommendationDocument/PractitionerRecommendationDocumentApiAdapter";
import PricingAndSubsidyLoader from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/PricingAndSubsidyLoader";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import ApiBusinessError from "@Toolkit/CommonWeb/ApiAdapter/ApiBusinessError";
import UnauthorizedOperationBusinessError from "@Toolkit/CommonWeb/Model/UnauthorizedOperationBusinessError";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";

interface IPractitionerRecommendationMasterDetailPanelDependencies {
    apiAdapter: PractitionerRecommendationApiAdapter;
    referenceDataStore: MedicationRequestReferenceDataStore;
    careReferenceDataStore: CareReferenceDataStore;
    notificationService: INotificationService;
    referenceApiAdapter: ReferenceDataApiAdapter;
    lockingApiAdapter: LockingApiAdapter;
    dialogService: IDialogService;
    documentApiAdapter: PractitionerRecommendationDocumentApiAdapter;
    careActivityApiAdapter: CareActivityApiAdapter2;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    diagnosisListApiAdapter: DiagnosisListApiAdapter;
    pricingAndSubsidyLoader: PricingAndSubsidyLoader;
}

interface IPractitionerRecommendationMasterDetailPanelProps {
    _dependencies?: IPractitionerRecommendationMasterDetailPanelDependencies;
    _careActivityId?: CareActivityId;
    _modalService?: IModalService;
    _pointOfCareId?: PointOfCareId;
    _careActivityState?: CareActivityState;
    practitionerRecommendationId: PractitionerRecommendationId;
    recommendationType: RecommendationType;
    readonly: boolean;
    onPractitionerRecommendationSelected: (id: PractitionerRecommendationId, type: RecommendationType, readonly: boolean) => void;
}

@State.observer
class PractitionerRecommendationMasterDetailPanel extends React.Component<IPractitionerRecommendationMasterDetailPanelProps> {

    private get diagnosisListApiAdapter() { return this.props._dependencies.diagnosisListApiAdapter; }
    private get dialogService() { return this.props._dependencies!.dialogService; }
    private get pricingAndSubsidyLoader() { return this.props._dependencies.pricingAndSubsidyLoader; }

    @State.observable.ref public practitionerRecommendationList: PractitionerRecommendation[] = null;
    @State.observable public isLoading: boolean = false;
    @State.observable public showFilter: boolean = null;
    @State.observable.ref public recommendation: PractitionerRecommendation = null;
    @State.observable.ref public pricingAndSubsidies: MedicationPricingAndSubsidies = null;
    @State.observable.ref private recommendationPointOfCareId: PointOfCareId = null;

    private detailPanelController = new PanelController();
    private filterStore = new PractitionerRecommendationFilterStore(this.loadListSync);

    private validationOperation: "save" | "submit" = "save";

    private get Resources() {
        return StaticHunSocialSecurityMedicationRequestResources.PractitionerRecommendationList;
    }

    @State.computed private get possibleSubsidyByPricingType() {
        if (isNullOrUndefined(this.recommendation?.claimTypeId)) {
            return null;
        }
        const claimType = this.props._dependencies.referenceDataStore.medicationSubsidyClaimTypes.get(this.recommendation.claimTypeId);
        if (isNullOrUndefined(claimType?.medicationSubsidizedPricingTypeId)) {
            return null;
        }
        const subsidy = this.pricingAndSubsidies?.subsidies?.find(i => ValueWrapper.equals(i.pricingType, claimType.medicationSubsidizedPricingTypeId));
        return subsidy;
    }

    @State.action.bound
    public setRecommendation(recommendation: PractitionerRecommendation) {
        this.recommendation = recommendation;
    }

    @State.action.bound
    public setRecommendationPointOfCareId(recommendationPointOfCareId: PointOfCareId) {
        this.recommendationPointOfCareId = recommendationPointOfCareId;
    }

    @State.action.bound
    public setPricingAndSubsidies(pricingAndSubsidies: MedicationPricingAndSubsidies) {
        this.pricingAndSubsidies = pricingAndSubsidies;
    }

    @State.action.bound
    public setPractitionerRecommendationList(practitionerRecommendationList: PractitionerRecommendation[]) {
        this.practitionerRecommendationList = practitionerRecommendationList;
    }

    @State.action.bound
    public setShowFilter(showFilter: boolean) {
        this.showFilter = showFilter;
    }

    @State.computed
    private get isReadonly() {
        return this.props._careActivityState === CareActivityState.Closed;
    }

    @State.computed
    private get recommendationPointOfCareMatches() {
        return this.recommendationPointOfCareId && ValueWrapper.equals(this.recommendationPointOfCareId, this.props._pointOfCareId);
    }

    @State.action.bound
    private async setDefaultFiltersAndLoadAsync() {
        if (this.props._pointOfCareId) {
            this.filterStore.pointOfCareIds.push(this.props._pointOfCareId);
            await this.loadListAsync();
        }
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.setDefaultFiltersAndLoadAsync);

    public componentDidMount() {
        dispatchAsyncErrors((async () => {
                await this.initialLoadPanelAsync();
                if (this.props.practitionerRecommendationId) {
                    await this.loadRecommendationAsync(!this.props.readonly);
                }
            })(), this);
    }

    public componentDidUpdate(prevProps: IPractitionerRecommendationMasterDetailPanelProps) {
        dispatchAsyncErrors((async () => {
            if (this.props._careActivityId !== prevProps._careActivityId) {
                await this.loadListAsync();
            }
            if (this.props.practitionerRecommendationId !== prevProps.practitionerRecommendationId) {
                await this.loadRecommendationAsync(!this.props.readonly);
            }
        })(), this);
    }

    @State.bound
    private loadListSync() {
        dispatchAsyncErrors(this.loadListAsync(), this);
    }

    @State.boundLoadingState("isLoading")
    private async loadListAsync() {
        if (!this.props._careActivityId) {
            return;
        }

        const list = await this.props._dependencies.apiAdapter.getPractitionerRecommendationListAsync(
            this.props._careActivityId,
            this.filterStore.dateRangeFilter,
            this.filterStore.includeDeleted,
            this.filterStore.includeExpired,
            this.filterStore.pointOfCareIds
        );
        await this.loadReferenceDataAsync(list.value);
        this.setPractitionerRecommendationList(list.value);
    }

    @State.bound
    private async loadReferenceDataAsync(list: PractitionerRecommendation[]) {
        const medicationVersionSelectors = list.map(i => i instanceof MedicationPractitionerRecommendation && i.medicationVersionSelector);
        await this.loadOrganizationDataAsync(list);

        await this.props._dependencies.careReferenceDataStore.medications.ensureLoadedAsync(medicationVersionSelectors);
        await this.props._dependencies.careReferenceDataStore.medicationType.ensureLoadedAsync();
        await this.props._dependencies.referenceDataStore.medicationSubsidyClaimTypes.ensureAllLoadedAsync();

        const substanceIds = _.flatten(list.map(i => {
            if (i instanceof MedicationPractitionerRecommendation) {
                const medication = this.props._dependencies.careReferenceDataStore.medications.get(i.medicationVersionSelector);
                return medication?.substanceIds.map(j => ({ id: j, validOn: i.recordedOn }));
            }
            return null;
        }).filter(i => !isNullOrUndefined(i)));

        await this.props._dependencies.careReferenceDataStore.substances.ensureLoadedAsync(substanceIds);

        const subsidyVersionSelectors = list.map(i => {
            if (i instanceof MedicationPractitionerRecommendation && i.medicationSubsidyId) {
                return { id: i.medicationSubsidyId, validOn: i.recordedOn };
            }
            return null;
        }).filter(i => !isNullOrUndefined(i));

        await this.props._dependencies.referenceDataStore.medicationSubsidies.ensureLoadedAsync(subsidyVersionSelectors);
    }

    @State.action.bound
    private async loadOrganizationDataAsync(list: PractitionerRecommendation[]) {
        const careActivityIds = _.uniq(list.map(i => i.careActivityId));
        if (careActivityIds?.length > 0) {
            const careActivities = (await this.props._dependencies.careActivityApiAdapter.loadByIdsAsync(careActivityIds)).value;

            const pointOfCareIds = _.uniq(list.map(i => {
                const careActivity = careActivities.find(j => ValueWrapper.equals(j.id, i.careActivityId));
                i.pointOfCareId = careActivity?.pointOfCareId;
                return i.pointOfCareId;
            }).filter(i => !isNullOrUndefined(i)));

            await this.props._dependencies.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync(pointOfCareIds);

            const healthCareProfessionIds = _.flatten(pointOfCareIds.map(i => {
                const pointOfCare = this.props._dependencies.organizationReferenceDataStore.allPointsOfCareMap.get(i);
                return pointOfCare?.healthcareProfessionIds;
            }).filter(i => !isNullOrUndefined(i)));

            await this.props._dependencies.organizationReferenceDataStore.healthCareProfession.ensureLoadedAsync(healthCareProfessionIds);
        }
    }

    @State.boundLoadingState("isLoading")
    private async loadRecommendationAsync(requestLock = true) {
        let recommendation: PractitionerRecommendation = null;
        if (this.props.practitionerRecommendationId && this.props.practitionerRecommendationId.value !== "new") {
            const response = await this.props._dependencies.apiAdapter.getPractitionerRecommendationAsync(this.props.practitionerRecommendationId, requestLock);
            recommendation = response.value;
            recommendation.takeSnapshot();

        } else if (this.props.practitionerRecommendationId && this.props.practitionerRecommendationId.value === "new") {
            this.setPricingAndSubsidies(null);
            recommendation = this.props.recommendationType === RecommendationType.GY ? new MedicationPractitionerRecommendation(true) : new EquipmentPractitionerRecommendation(true);
            recommendation.id = new PractitionerRecommendationId("new");
            recommendation.careActivityId = this.props._careActivityId;
            recommendation.takeSnapshot();
        }

        if (recommendation && recommendation instanceof MedicationPractitionerRecommendation && recommendation.medicationVersionSelector) {
            await this.loadMedicationPricingAndSubsidiesAsync(recommendation.medicationVersionSelector.id, recommendation.recordedOn);
        }

        await this.loadRecommendationReferenceDataAsync(recommendation);

        this.setRecommendation(recommendation);
    }

    @State.bound
    private async loadRecommendationReferenceDataAsync(recommendation: PractitionerRecommendation) {
        await this.props._dependencies.referenceDataStore.medicationSubsidyClaimTypes.ensureAllLoadedAsync();
        if (recommendation?.careActivityId) {
            const careActivity = await this.props._dependencies.careActivityApiAdapter.loadByIdAsync(recommendation.careActivityId);
            this.setRecommendationPointOfCareId(careActivity?.pointOfCareId);
        }
    }

    @State.bound
    private async loadMedicationPricingAndSubsidiesAsync(id: MedicationId, validOn: LocalDate) {
        const pricingAndSubsidies = await this.props._dependencies.referenceApiAdapter.getMedicationPricingAndSubsidiesAsync(id, validOn);
        this.setPricingAndSubsidies(pricingAndSubsidies.value);
        await this.pricingAndSubsidyLoader.loadReferenceDataAsync(pricingAndSubsidies.value, validOn);
    }

    @State.bound
    private async loadSubstancesAsync(id: IEntityVersionSelector<MedicationId>) {
        const medication = this.props._dependencies.careReferenceDataStore.medications.get(id);
        if (medication.substanceIds && medication.substanceIds.length > 0) {
            const substanceVersionSelectors = medication.substanceIds.map(i => ({ id: i, validOn: id.validOn }));
            await this.props._dependencies.careReferenceDataStore.substances.ensureLoadedAsync(substanceVersionSelectors);
        }
    }

    @State.bound
    private async setMedicationAsync(value: MedicationId) {
        const recommendation = this.recommendation as MedicationPractitionerRecommendation;
        this.setPricingAndSubsidies(null);
        recommendation.setMedicationId(value);
        if (!isNullOrUndefined(value)) {
            await this.loadMedicationPricingAndSubsidiesAsync(value, recommendation.recordedOn);
            const versionSelector = { id: value, validOn: this.recommendation.recordedOn };
            await this.props._dependencies.careReferenceDataStore.medications.ensureLoadedAsync([versionSelector]);
            await this.loadSubstancesAsync(versionSelector);

            const medication = this.props._dependencies.careReferenceDataStore.medications.get(versionSelector);

            recommendation.setMedicationFormId(medication.medicationFormId);
        }
    }

    @State.bound
    private setSubsidyAndOptionId(newValue: { subsidyId: MedicationSubsidyId, optionId: MedicationSubsidyOptionId }) {
        if (this.recommendation && this.recommendation instanceof MedicationPractitionerRecommendation) {
            const isReadonly = this.recommendation.isMutable === false ||
                (this.isReadonly || !this.recommendationPointOfCareMatches) ||
                (!this.recommendation.isNew && this.recommendation.statusColumn.status !== PractitionerRecommendationStatus.UnderRecording);

            if (!ValueWrapper.equals(this.recommendation.medicationSubsidyId, newValue?.subsidyId) && !isReadonly) {
                this.recommendation.setMedicationSubsidyId(newValue?.subsidyId);
            }
            if (!ValueWrapper.equals(this.recommendation.subsidyOptionId, newValue?.optionId) && !isReadonly) {
                this.recommendation.setSubsidyOptionId(newValue?.optionId);
                if (!isNullOrUndefined(newValue?.optionId) &&
                    isNullOrUndefined(this.recommendation.validityDurationInMonths)) {
                    const subsidyOption =
                        this.possibleSubsidyByPricingType?.options?.find(
                            i => ValueWrapper.equals(i.id, newValue?.optionId));
                    if (subsidyOption?.optionRestrictions?.length) {
                        const restrictionsWithValidityDurationInMonths = subsidyOption.optionRestrictions.filter(i =>
                            !isNullOrUndefined(i.practitionerPrescriptionValidityDurationInMonths) &&
                            i.practitionerPrescriptionValidityDurationInMonths !== 0 &&
                            i.practitionerPrescriptionValidityDurationInMonths !== 999);
                        if (restrictionsWithValidityDurationInMonths?.length) {
                            const highestValidityDuration = _.orderBy(restrictionsWithValidityDurationInMonths,
                                i => i.practitionerPrescriptionValidityDurationInMonths,
                                "desc")[0];
                            this.recommendation.setValidityDurationInMonths(highestValidityDuration
                                .practitionerPrescriptionValidityDurationInMonths);
                        }
                    }
                }
            }
        }
    }

    @State.bound
    private async saveAsync(releaseLock: boolean, showResult = true, isValidateOnly = false, navigateToNewRecommendation = true) {
        let result: PractitionerRecommendation = null;
        if (this.recommendation.isNew) {
            const response = await this.props._dependencies.apiAdapter.addPractitionerRecommendationAsync(this.recommendation, releaseLock, isValidateOnly);
            result = response.value;
        } else {
            const response = await this.props._dependencies.apiAdapter.updatePractitionerRecommendationAsync(this.recommendation, releaseLock, this.props._careActivityId, isValidateOnly);
            result = response.value;
        }

        if (!isValidateOnly && navigateToNewRecommendation) {
            this.setRecommendation(result);
            result.takeSnapshot();

            if (result.operationWasSuccessful) {
                this.loadListSync();
                this.props.onPractitionerRecommendationSelected(result.id, result.type, false);
            }
        }

        if (!navigateToNewRecommendation && result.operationWasSuccessful) {
            this.loadListSync();
        }

        if (showResult) {
            this.props._dependencies.notificationService.showSaveResult(result.isPersistedByOperationInfo);
        }
        return result;
    }

    @State.bound
    private async saveAndReleaseLockAsync() {
        return await this.saveAsync(true, true, false, false);
    }

    @State.bound
    private async onSaveClickedAsync() {
        this.validationOperation = "save";
        await this.saveAsync(false);
    }

    @State.bound
    private async onFinalizeClickedAsync() {
        this.validationOperation = "submit";
        await this.tryAddDiagnosisAsync();
        const result = await this.finalizeRecommendationAsync();
        this.props._dependencies.notificationService.showSaveResult(result.isPersistedByOperationInfo, result.hasValidationWarning);
    }

    // If diagnosis related permission is not granted and selected diagnosis
    // 1. exists -> no UI validation error (+ ignored unauthorized error)
    // 2. does not exist -> UI validation error that blocks finalization (+ ignored unauthorized error)
    private async tryAddDiagnosisAsync() {
        try {
            await this.addDiagnosisIfNecessaryAsync();
        } catch (err) {
            if (!(err instanceof ApiBusinessError && err.error instanceof UnauthorizedOperationBusinessError)) {
                throw err;
            }
        }
    }

    private async addDiagnosisIfNecessaryAsync() {
        const careActivityDiagnosisList = await this.diagnosisListApiAdapter.getDiagnosisListAsync(this.recommendation.careActivityId, true);
        const careActivityContainsSelectedCondition = careActivityDiagnosisList.diagnoses?.some(diagnosis =>
            diagnosis.conditionVersionSelector.id.value === this.recommendation.conditionId?.value &&
            (diagnosis.use.value === DiagnosisRoleId.Comorbity.value || diagnosis.use.value === DiagnosisRoleId.Discharge.value));

        if (this.recommendation.conditionId && !careActivityContainsSelectedCondition) {
            const dialogResult = await this.dialogService.yesNo(
                StaticWebAppResources.Common.DialogTitle.ConfirmationTitle,
                StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.AddConditionToCareActivityDialog.Text);

            if (dialogResult.resultCode === DialogResultCode.Yes) {
                const newDiagnosis = new DiagnosisStore(true);
                newDiagnosis.setCondition(this.recommendation.conditionId);
                newDiagnosis.setUse(careActivityDiagnosisList.diagnoses.length === 0 ? DiagnosisRoleId.Discharge : DiagnosisRoleId.Comorbity);
                newDiagnosis.setLateralityId(LateralityId.NotApplicable);
                careActivityDiagnosisList.addDiagnosis(newDiagnosis);

                await this.diagnosisListApiAdapter.updateDiagnosisListWithoutExistingLockAsync(careActivityDiagnosisList, true, true);
            }
        }
    }

    @State.bound
    private async releaseLockAsync() {
        if (this.recommendation?.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld && this.recommendation.lockInfo.lockId) {
            await this.props._dependencies.lockingApiAdapter.releaseLockAsync(this.recommendation.lockInfo.lockId);
            this.recommendation.releaseLock();
        }
    }

    @State.bound
    private async forceReleaseLockAndLoadAsync() {
        if (this.recommendation?.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockNotHeld) {
            await this.props._dependencies.lockingApiAdapter.forceReleaseLockAsync(this.recommendation.lockInfo.preventingLockId);
            await this.loadRecommendationAsync(true);
        }
    }

    @State.bound
    private addNewMedicationRecommendation() {
        this.validationOperation = "save";
        this.props.onPractitionerRecommendationSelected(new PractitionerRecommendationId("new"), RecommendationType.GY, false);
    }

    @State.bound
    private addNewEquipmentRecommendation() {
        this.validationOperation = "save";
        this.props.onPractitionerRecommendationSelected(new PractitionerRecommendationId("new"), RecommendationType.GYSE, false);
    }

    @State.bound
    private async validateAsync(): Promise<IClientValidationResult[]> {
        if (!this.recommendation) {
            return [];
        }

        let result: PractitionerRecommendation = null;
        if (this.validationOperation === "save") {
            result = await this.saveAsync(false, false, true);
        } else { // === "submit"            
            result = await this.finalizeRecommendationAsync(true);
        }
        return result.validationResults;
    }

    @State.bound
    private async navigatingAwayAsync() {
        if (this.recommendation.isNew || this.recommendation.isDirty()) {
            const dialogResult = await this.props._dependencies.dialogService.confirmIfNotSaved(StaticWebAppResources.Common.DialogTitle.ConfirmationTitle, StaticWebAppResources.Common.DialogMessage.OkToNavigateAndLoseChanges);

            if (dialogResult.resultCode === DialogResultCode.Yes) {
                const result = await this.saveAndReleaseLockAsync();
                return result.operationWasSuccessful;
            } else if (dialogResult.resultCode === DialogResultCode.No) {
                await this.releaseLockAsync();
                return true;
            } else {
                return false;
            }
        }
        await this.releaseLockAsync();
        return true;
    }

    @State.bound
    private renderDetailClosedListToolbar() {
        return (
            <Ui.Flex>
                {!this.props.practitionerRecommendationId &&
                    <Ui.Flex.Item>
                        <Ui.CheckBox
                            displayMode="toggle-button"
                            value={this.showFilter}
                            onChange={this.setShowFilter}
                            label={StaticWebAppResources.Common.Label.DetailedSearch}
                            toggleButtonProps={{ iconName: "filter", automationId: "showFilterToggleButton" }}
                            verticalAlign="noPadding"
                            automationId="showFilterCheckBox"
                        />
                    </Ui.Flex.Item>
                }
                {!this.isReadonly &&
                    <Ui.Flex.Item>
                        <Ui.AddButton
                            permissionCheckOperationNames="AddPractitionerRecommendation"
                            permissionDeniedStyle="disabled"
                            visualStyle="primary"
                            text={this.Resources.Buttons.AddNewMedicationButton}
                            onClick={this.addNewMedicationRecommendation}
                            automationId="addNewMedicationRecommendationButton" />
                        <Ui.AddButton
                            permissionCheckOperationNames="AddPractitionerRecommendation"
                            permissionDeniedStyle="disabled"
                            visualStyle="primary"
                            text={this.Resources.Buttons.AddNewEquipmentButton}
                            onClick={this.addNewEquipmentRecommendation}
                            automationId="addNewEquipmentRecommendationButton" />
                    </Ui.Flex.Item>
                }
            </Ui.Flex>
        );
    }

    @State.bound
    private async finalizeRecommendationAsync(isValidateOnly: boolean = false) {

        if (!this.props.practitionerRecommendationId) {
            return null;
        }

        let recommendationToFinalize;
        if (this.recommendation.isNew || this.recommendation.isDirty()) {
            if (this.recommendation.isNew) {
                const saveResponse = await this.props._dependencies.apiAdapter.addPractitionerRecommendationAsync(this.recommendation, false, isValidateOnly);
                recommendationToFinalize = saveResponse.value;
            } else {
                const saveResponse = await this.props._dependencies.apiAdapter.updatePractitionerRecommendationAsync(this.recommendation, false, this.props._careActivityId, isValidateOnly);
                recommendationToFinalize = saveResponse.value;
            }
        } else {
            recommendationToFinalize = this.recommendation;
        }

        const response = await this.props._dependencies.apiAdapter.finalizePractitionerRecommendationAsync(recommendationToFinalize, true, this.props._careActivityId, isValidateOnly);
        const result = response.value;

        if (!isValidateOnly) {
            this.setRecommendation(result);
            result.takeSnapshot();

            if (result.operationWasSuccessful) {
                this.loadListSync();
                this.props.onPractitionerRecommendationSelected(result.id, result.type, false);
            }
        }

        return result;
    }

    @State.bound
    public async revokeRecommendationFinalizationAsync() {
        if (!this.props.practitionerRecommendationId) {
            return;
        }
        const result = await this.props._dependencies.apiAdapter.revokePractitionerRecommendationFinalization(
            this.props.practitionerRecommendationId,
            this.props._careActivityId,
            true);
        this.props._dependencies.notificationService.showSaveResult(result.isPersistedByOperationInfo);
        if (result.operationWasSuccessful) {
            this.loadListSync();
            this.setRecommendation(result.value);
            result.value.takeSnapshot();

        }
    }

    @State.action.bound
    private async openPractitionerRecommendationDocumentAsync() {
        const doc = await this.props._dependencies.documentApiAdapter
            .getSingeDocumentForPractitionerRecommendationAsync(this.props.practitionerRecommendationId, "PractitionerRecommendation", null, true);
        if (!doc.value) {
            this.props._dependencies.notificationService.info(StaticWebAppResources.NewServiceRequestPage.DocumentDoesNotExist);
        } else {
            await this.props._modalService.showModalAsync(new DocumentPreviewModalParams(doc.value.id));
        }
    }

    @State.bound
    private renderDetailToolbar() {
        const statusEqualWithUnderRecordingOrNull = isNullOrUndefined(this.recommendation?.statusColumn) || this.recommendation?.statusColumn?.status === PractitionerRecommendationStatus.UnderRecording;
        const actionsEnabled = !this.isReadonly && this.recommendationPointOfCareMatches;
        const shouldBeLocked = this.recommendation?.isMutable === false && statusEqualWithUnderRecordingOrNull;
        const saveEnabled = !shouldBeLocked && actionsEnabled;

        return (
            <Ui.Flex>
                <LockIndicatorComponent
                    onEditClickedAsync={this.forceReleaseLockAndLoadAsync}
                    locked={shouldBeLocked}
                    lockedBy={this.recommendation?.lockInfo?.preventingLockUserId}
                    permissionCheckOperationNames="AddPractitionerRecommendation" />
                {actionsEnabled && (this.recommendation?.statusColumn?.status === PractitionerRecommendationStatus.Final) &&
                    <Ui.Flex.Item>
                        <Ui.Button
                            permissionCheckOperationNames="RevokePractitionerRecommendation"
                            permissionDeniedStyle="disabled"
                            text={StaticHunSocialSecurityMedicationRequestResources.PractitionerRecommendationList.Buttons.RevokeFinalization}
                            iconName="circleArrowCcw"
                            onClickAsync={this.revokeRecommendationFinalizationAsync}
                            automationId="revokeRecommendationFinalizationButton"
                            disabled={shouldBeLocked}
                        />
                    </Ui.Flex.Item>}
                {this.recommendation?.isNew === false &&
                    !isNullOrUndefined(this.recommendation?.statusColumn?.status) &&
                    this.recommendation.statusColumn.status !== PractitionerRecommendationStatus.Deleted &&
                    < Ui.Flex.Item >
                        <Ui.Button
                            text={StaticWebAppResources.Common.Button.PrintPreview}
                            iconName="print"
                            onClickAsync={this.openPractitionerRecommendationDocumentAsync}
                            automationId="openPractitionerRecommendationDocumentButton"
                        />
                    </Ui.Flex.Item>}
                {saveEnabled && statusEqualWithUnderRecordingOrNull &&
                    <Ui.Flex.Item>
                        <Ui.SaveButton
                            permissionCheckOperationNames="UpdatePractitionerRecommendation"
                            permissionDeniedStyle="disabled"
                            onClickAsync={this.onSaveClickedAsync}
                            visualStyle="standard"
                            automationId="saveButton"
                            disabled={shouldBeLocked}
                        />
                    </Ui.Flex.Item>}
                {actionsEnabled && statusEqualWithUnderRecordingOrNull &&
                    <Ui.Flex.Item>
                        <Ui.Button
                            permissionCheckOperationNames="FinalizePractitionerRecommendation"
                            permissionDeniedStyle="disabled"
                            text={StaticHunSocialSecurityMedicationRequestResources.PractitionerRecommendationList.Buttons.Finalize}
                            iconName="check"
                            onClickAsync={this.onFinalizeClickedAsync}
                            visualStyle="primary"
                            automationId="finalizeButton"
                            disabled={shouldBeLocked}
                        />
                    </Ui.Flex.Item>}
                {actionsEnabled && this.recommendation?.isNew === false && <Ui.Flex.Item>
                        <Ui.ContextMenu.Provider id="practitioner_recommendation_actions_menu" event="onClick" style={{ width: 20, display: "unset" }}>
                            <Ui.Button
                                iconName="more"
                                onClick={nullFunction}
                                tooltipContent={StaticWebAppResources.Common.Button.FurtherActions}
                                tooltipPosition="bottom"
                                disabled={false}
                                automationId="moreButton"
                            />

                        </Ui.ContextMenu.Provider>
                        {this.renderContextMenu()}
                    </Ui.Flex.Item>}
            </Ui.Flex >
        );
    }

    @State.bound
    public async removeRecommendationAsync(id: PractitionerRecommendationId) {
        await this.props._dependencies.apiAdapter.removePractitionerRecommendation(id, this.props._careActivityId);
    }

    @State.bound
    public async onRemoveClickedAsync() {
        let subject = "";

        if (this.recommendation instanceof MedicationPractitionerRecommendation) {
            const medication = this.recommendation.medicationVersionSelector && this.props._dependencies.careReferenceDataStore.medications.get(this.recommendation.medicationVersionSelector);
            subject = medication?.name;
        } else if (this.recommendation instanceof EquipmentPractitionerRecommendation) {
            const equipment1 = this.recommendation.recommendedEquipment1?.id && this.props._dependencies.careReferenceDataStore.medicationEquipmentClassifications.get(this.recommendation.recommendedEquipment1);
            const equipment2 = this.recommendation.recommendedEquipment2?.id && this.props._dependencies.careReferenceDataStore.medicationEquipmentClassifications.get(this.recommendation.recommendedEquipment2);
            const equipmentNames: string[] = [];
            if (!isNullOrUndefined(equipment1)) { equipmentNames.push(equipment1.name); }
            if (!isNullOrUndefined(equipment2)) { equipmentNames.push(equipment2.name); }
            subject = equipmentNames.join(", ");
        }

        if (!subject) {
            subject = "N/A";
        }

        const dialogResult = await this.props._dependencies.dialogService.yesNo(
            StaticWebAppResources.Common.DialogTitle.ConfirmationTitle,
            formatString(
                StaticHunSocialSecurityMedicationRequestResources.PractitionerRecommendationList.Messages.DeleteConfirmationMessage,
                subject
            )
        );
        if (dialogResult.resultCode === DialogResultCode.Yes) {
            await this.removeRecommendationAsync(this.recommendation.id);
            this.props.onPractitionerRecommendationSelected(null, null, false);
            await this.loadListAsync();
        }
    }

    private renderContextMenu() {
        if (!isNullOrUndefined(this.recommendation?.statusColumn?.status) && this.recommendation.statusColumn.status === PractitionerRecommendationStatus.Deleted) {
            return null;
        }

        return (
            <Ui.ContextMenu id="practitioner_recommendation_actions_menu">
                <Ui.ContextMenu.Item onClickAsync={this.onRemoveClickedAsync} permissionCheckOperationNames="RemovePractitionerRecommendation" permissionDeniedStyle="disabled">
                    <SpanWithIcon iconName="trash" visualStyle="error">{StaticHunSocialSecurityMedicationRequestResources.PractitionerRecommendationList.Actions.Remove}</SpanWithIcon>
                </Ui.ContextMenu.Item>
            </Ui.ContextMenu>
        );
    }

    @State.bound
    private handlePointOfCareError() {
        // Seeing this error should not be possible, because the controls are disabled.
        this.props._dependencies.notificationService.error("Don't do it!");
        return true;
    }

    @State.bound
    private onMasterDetailSelectedItemChanged(value: any) {
        // only used for nulls for back button, the other types on change are handled by onPractitionerRecommendationSelected
        if (!value) {
            this.props.onPractitionerRecommendationSelected(null, null, false);
        }
    }

    @State.computed
    private get operationsToChecks() {
        const res = {};

        res["GetPractitionerRecommendationMaster"] = async () => {
            await this.props._dependencies.apiAdapter.getPractitionerRecommendationListAsync(
                this.props._careActivityId,
                this.filterStore.dateRangeFilter,
                this.filterStore.includeDeleted,
                this.filterStore.includeExpired,
                this.filterStore.pointOfCareIds,
                true
            );
        };

        res["AddPractitionerRecommendation"] = async () => {
            const practitionerRecommendation = new MedicationPractitionerRecommendation();
            practitionerRecommendation.careActivityId = this.props._careActivityId;
            await this.props._dependencies.apiAdapter.addPractitionerRecommendationAsync(practitionerRecommendation, false, false, true);
        };

        if (!isNullOrUndefined(this.recommendation) && this.recommendation?.isNew === false) {
            res["UpdatePractitionerRecommendation"] = async () => {
                await this.props._dependencies.apiAdapter.updatePractitionerRecommendationAsync(this.recommendation, false, this.props._careActivityId, false, true);
            };

            res["RemovePractitionerRecommendation"] = async () => {
                if (!isNullOrUndefined(this.recommendation?.id)) {
                    await this.props._dependencies.apiAdapter.removePractitionerRecommendation(this.recommendation.id, this.props._careActivityId, true);
                }
            };

            res["FinalizePractitionerRecommendation"] = async () => {
                await this.props._dependencies.apiAdapter.finalizePractitionerRecommendationAsync(this.recommendation, true, this.props._careActivityId, false, true);
            };

            if (!isNullOrUndefined(this.recommendation?.statusColumn?.status) && this.recommendation.statusColumn.status === PractitionerRecommendationStatus.Final) {
                res["RevokePractitionerRecommendation"] = async () => {
                    await this.props._dependencies.apiAdapter.revokePractitionerRecommendationFinalization(this.recommendation.id, this.props._careActivityId, false, true);
                };
            }
        }

        return res;
    }

    public render() {
        const resources = StaticHunSocialSecurityMedicationRequestResources.PractitionerRecommendationList;

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={resources.ListTitle} />;
        }

        return (
            <PermissionCheckContextProvider operationsToCheck={this.operationsToChecks}>
                <SingleLayout>
                    <BusinessErrorHandler.Register businessErrorName="CannotModifyPractitionerRecommendationOfOtherPointOfCareError" handler={this.handlePointOfCareError} />
                    <MasterDetailLayout
                        selectedItem={this.recommendation}
                        onSelectedItemChange={this.onMasterDetailSelectedItemChanged} // only used for nulls
                        isLoading={this.isLoading}
                    >
                        <MasterDetail.Master
                            title={resources.ListTitle}
                            detailClosedToolbar={this.renderDetailClosedListToolbar()}
                            iconName="practitioner_textbubble">
                            <PractitionerRecommendationListView
                                careActivityId={this.props._careActivityId}
                                practitionerRecommendationList={this.practitionerRecommendationList}
                                filterStore={this.filterStore}
                                showFilter={this.showFilter}
                                onOperationSuccessful={this.loadListSync}
                                onPractitionerRecommendationSelected={this.props.onPractitionerRecommendationSelected}
                                pointOfCareId={this.props._pointOfCareId}
                            />
                        </MasterDetail.Master>
                        <MasterDetail.Detail
                            title={resources.DetailTitle}
                            hasSidePadding
                            toolbar={this.renderDetailToolbar()}>
                            <PractitionerRecommendationDetailView
                                recommendation={this.recommendation}
                                controller={this.detailPanelController}
                                careActivityId={this.props._careActivityId}
                                navigatingAwayAsync={this.navigatingAwayAsync}
                                pricingAndSubsidies={this.pricingAndSubsidies}
                                possibleSubsidyByPricingType={this.possibleSubsidyByPricingType}
                                setMedicationAsync={this.setMedicationAsync}
                                setSubsidyAndOptionId={this.setSubsidyAndOptionId}
                                readonly={this.isReadonly || !this.recommendationPointOfCareMatches}
                                onValidateAsync={this.validateAsync}
                            />
                        </MasterDetail.Detail>
                    </MasterDetailLayout>
                </SingleLayout>
            </PermissionCheckContextProvider>
        );
    }
}

export default connect(
    PractitionerRecommendationMasterDetailPanel,
    new DependencyAdapter<IPractitionerRecommendationMasterDetailPanelProps, IPractitionerRecommendationMasterDetailPanelDependencies>(c => ({
        apiAdapter: c.resolve("PractitionerRecommendationApiAdapter"),
        referenceDataStore: c.resolve("MedicationRequestReferenceDataStore"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        notificationService: c.resolve("INotificationService"),
        referenceApiAdapter: c.resolve("ReferenceDataApiAdapter"),
        lockingApiAdapter: c.resolve("LockingApiAdapter"),
        dialogService: c.resolve("IDialogService"),
        documentApiAdapter: c.resolve("PractitionerRecommendationDocumentApiAdapter"),
        careActivityApiAdapter: c.resolve("CareActivityApiAdapter2"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        diagnosisListApiAdapter: c.resolve("DiagnosisListApiAdapter"),
        pricingAndSubsidyLoader: c.resolve("PricingAndSubsidyLoader"),
    })),
    new CareActivityContextAdapter<IPractitionerRecommendationMasterDetailPanelProps>(c => ({
        _careActivityId: c.careActivityId,
        _pointOfCareId: c.careActivity?.pointOfCareId,
        _careActivityState: c.careActivity?.state
    })),
    new HisModalServiceAdapter()
);