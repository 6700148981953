import ContagiousPatientReportDetailPanel, { IContagiousPatientReportDetailPanelProps } from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Panels/ContagiousPatientReportPanels/ContagiousPatientReportDetailPanel";
import HunSocialSecurityUseCases from "@HunSocialSecurityPlugin/UseCases/HunSocialSecurityUseCases";
import IUseCaseDescriptor2 from "@PluginInterface/UseCases/IUseCaseDescriptor2";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import ContagiousPatientReportId from "@Primitives/ContagiousPatientReportId.g";

export default function configureContagiousPatientReportUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2<IContagiousPatientReportDetailPanelProps>(
        {
            identifiers: [HunSocialSecurityUseCases.editContagiousPatientReport],
            component: ContagiousPatientReportDetailPanel,
            mapUseCaseArgumentsToProps: args => {
                const contagiousPatientReportId = args.find(a => a.value instanceof ContagiousPatientReportId).value;
                const isNew = args.find(a => a.name === "isNew")?.value;
                return {
                    contagiousPatientReportId: contagiousPatientReportId,
                    isNew: isNew
                };
            }
        } as IUseCaseDescriptor2<IContagiousPatientReportDetailPanelProps>
    );

    useCaseRegistry.add2<IContagiousPatientReportDetailPanelProps>(
        {
            identifiers: [HunSocialSecurityUseCases.viewContagiousPatientReport],
            component: ContagiousPatientReportDetailPanel,
            mapUseCaseArgumentsToProps: args => {
                const contagiousPatientReportId = args.find(a => a.value instanceof ContagiousPatientReportId).value;

                return {
                    contagiousPatientReportId: contagiousPatientReportId,
                    isReadonly: true
                };
            }
        } as IUseCaseDescriptor2<IContagiousPatientReportDetailPanelProps>
    );
}