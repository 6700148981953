import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import Di from "@Di";
import ResourceBlocking from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ResourceBlocking";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import { CreateCreateResourceBlockingControllerDto, CreateModifyResourceBlockingControllerDto, CreateGetResourceBlockingsControllerDto } from "./ResourceBlockingDtoMapper";
import ResourceBlockingStoreMapper from "./ResourceBlockingStoreMapper";
import PractitionerId from "@Primitives/PractitionerId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

@Di.injectable()
export default class ResourceManagementApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IResourceManagementClient") private apiClient: Proxy.IResourceManagementClient,
        @Di.inject("ResourceBlockingStoreMapper") private resourceBlockingStoreMapper: ResourceBlockingStoreMapper
    ) {
        super();
    }

    @State.bound
    public async validateResourceBlockingAsync(resourceBlocking: ResourceBlocking) {
        const dto = CreateCreateResourceBlockingControllerDto(resourceBlocking, true);

        return await this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response = await this.apiClient.createResourceBlockingCommandAsync(CreateRequestId(), dto);

                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public async updateResourceBlockingAsync(resourceBlocking: ResourceBlocking) {
        const dto = CreateModifyResourceBlockingControllerDto(resourceBlocking);

        return await this.processOperationAsync(
            new ResourceBlocking(true),
            async target => {
                const response = await this.apiClient.modifyResourceBlockingCommandAsync(CreateRequestId(), dto);

                this.resourceBlockingStoreMapper.applyToStore(target, response);
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);

                target.isNew = !target.isPersistedByOperationInfo;
            }
        );
    }

    @State.bound
    public async createResourceBlockingAsync(resourceBlocking: ResourceBlocking) {
        const dto = CreateCreateResourceBlockingControllerDto(resourceBlocking, false);

        return await this.processOperationAsync(
            new ResourceBlocking(true),
            async target => {
                const response = await this.apiClient.createResourceBlockingCommandAsync(CreateRequestId(), dto);

                this.resourceBlockingStoreMapper.applyToStore(target, response);
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);

                target.isNew = !target.isPersistedByOperationInfo;
            }
        );
    }

    @State.bound
    public async getResourceBlockingsForPractitionerAsync(practitionerId: PractitionerId, dateRange: LocalDateRange) {
        const dto = CreateGetResourceBlockingsControllerDto(practitionerId, dateRange);

        return await this.processOperationAsync(
            new SimpleStore<ResourceBlocking[]>(),
            async target => {
                const response = await this.apiClient.getResourceBlockingsForResourceQueryAsync(CreateRequestId(), dto);

                const resourceBlockings = response.resourceBlockings.map(i => {
                    const resourceBlocking = new ResourceBlocking();
                    this.resourceBlockingStoreMapper.applyToStore(resourceBlocking, i);

                    return resourceBlocking;
                });

                target.value = resourceBlockings;
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public async getResourceBlockingsForPractitionerPermissionCheckAsync() {
        const dto = CreateGetResourceBlockingsControllerDto(
            new PractitionerId("-1"), 
            new LocalDateRange(LocalDate.today(), LocalDate.today())
            );

        return await this.processOperationAsync(
            new SimpleStore<ResourceBlocking[]>(),
            async target => {
                const response = await this.apiClient.getResourceBlockingsForResourceQueryAsync(
                    CreateRequestId(),
                    dto,
                    true);

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public async deleteResourceBlockingAsync(resourceBlocking: ResourceBlocking) {
        return await this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.deleteResourceBlockingCommandAsync(CreateRequestId(), new Proxy.DeleteResourceBlockingControllerDto({
                    resourceBlockingId: resourceBlocking.id,
                    rowVersion: resourceBlocking.rowVersion
                }));
                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}