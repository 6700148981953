import Di from "@Di";
import IRoutingFrameCaseRegistry from "@HisPlatform/Services/Definition/RoutingFrameCaseRegistry/IRoutingFrameCaseRegistry";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import RoutingFrame from "@Toolkit/ReactClient/Routing/RoutingFrame";
import React from "react";

@Di.injectable()
export default class RoutingFrameCaseRegistry implements IRoutingFrameCaseRegistry {
    private readonly routingFrameCaseMap =
        new Map<string, {
            component: React.ComponentClass<{ routingController?: IRoutingController }> | React.FC<{ routingController?: IRoutingController }>,
            routeDefinition: RouteDefinition<any>,
            routeName: string
        }[]>();

    public register(
        identifier: string,
        component: React.ComponentClass<{ routingController?: IRoutingController }> | React.FC<{ routingController?: IRoutingController }>,
        routeDefinition: RouteDefinition<any>,
        routeName: string): void {

        if (this.routingFrameCaseMap.has(identifier)) {
            this.routingFrameCaseMap.get(identifier).push({ component: component, routeDefinition: routeDefinition, routeName: routeName });
        } else {
            this.routingFrameCaseMap.set(identifier, [{ component: component, routeDefinition: routeDefinition, routeName: routeName }]);
        }
    }

    public getAll(identifier: string): React.ReactNode[] {
        const mapItem = this.routingFrameCaseMap.get(identifier) ?? [];

        return mapItem.map((i, index) => {
            return (
                <RoutingFrame.Case route={i.routeDefinition} key={"routingFrameCaseRegistry" + index} component={i.component} />
            );
        });
    }

    public getRouteDefinitions(identifier: string): { [key: string]: RouteDefinition } {
        const mapItem = this.routingFrameCaseMap.get(identifier) ?? [];

        const result = {};
        mapItem.forEach(i => result[i.routeName] = i.routeDefinition);

        return result;
    }
}