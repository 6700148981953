import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ConditionId from "@Primitives/ConditionId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import _ from "@HisPlatform/Common/Lodash";

interface IConditionColumnDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IConditionColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IConditionColumnDependencies;
    validOnGetter?: string | ((row: any) => LocalDate);
    badgeRenderer?: (row: any) => React.ReactNode;
}


const ConditionColumn: React.FC<IConditionColumnProps> = props => {

    const validOnGetter = useCallback((row: any) => {
        let validOn: LocalDate = LocalDate.today();
        if (props.validOnGetter) {
            if (typeof(props.validOnGetter) === "string") {
                validOn = _.get(row, props.validOnGetter) as LocalDate;
            } else {
                validOn = props.validOnGetter(row);
            }
        }
        return validOn;
    }, []);

    const valueRenderer = useCallback((value: string | ConditionId, row: any) => {
        const validOn = validOnGetter(row);
        let code: string;
        if (value instanceof ConditionId) {
            const localized = isNullOrUndefined(value) ? null : props._dependencies.careReferenceDataStore.condition.get({id: value, validOn: validOn});
            code = isNullOrUndefined(localized) ? null : localized.code;
        } else {
            code = value;
        }

        return <>{code}{props.badgeRenderer && props.badgeRenderer(row)}</>;
    }, []);
    
    const hintRenderer = useCallback((value: string | ConditionId, row: any) => {
        const validOn = validOnGetter(row);
        if (value instanceof ConditionId) {
            const localized = isNullOrUndefined(value) ? null : props._dependencies.careReferenceDataStore.condition.get({id: value, validOn: validOn});
            return isNullOrUndefined(localized) ? null : localized.name;
        } else {
            return null;
        }
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<ConditionId | ConditionId[]>) => {
        return null;
    }, []);

    return (
        <DataGridColumn
            {...props as IDataGridColumnBaseProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            onRenderHintValue={hintRenderer}
        />
    );
};

export default connect(
    ConditionColumn,
    new DependencyAdapter<IConditionColumnProps, IConditionColumnDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
    }))
);