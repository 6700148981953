import React from "react";

export type ElementsType = React.ReactElement | React.ReactElement[];
export function flattenFragments(children: ElementsType): ElementsType {
    return React.Children.toArray(children).reduce((flatChildren, child) => {
        if (child.type === React.Fragment) {
            return flatChildren.concat(flattenFragments(child.props.children));
        }
        flatChildren.push(child);
        return flatChildren;
    }, []);
}