import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import {dispatchAsyncErrors} from "@Toolkit/CommonWeb/AsyncHelpers";
import * as Ui from "@CommonControls";
import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import AppointmentScheduleDefinition from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentScheduleDefinition";
import AppointmentScheduleDefinitionId from "@Primitives/AppointmentScheduleDefinitionId.g";

interface IAppointmentScheduleDefinitionSelectBoxDependencies {
    schedulingReferenceDataStore: SchedulingReferenceDataStore;

}

interface IAppointmentScheduleDefinitionSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IAppointmentScheduleDefinitionSelectBoxDependencies;
}

@State.observer
class AppointmentScheduleDefinitionSelectBox extends React.Component<IAppointmentScheduleDefinitionSelectBoxProps> {

    @State.computed
    private get items(): Array<ISelectBoxItem<AppointmentScheduleDefinitionId>> {
        const items = this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleDefinitions.items;
        return items.map(this.toSelectBoxItem);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private async loadAsync() {
        await this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleDefinitions.ensureAllLoadedAsync();
    }

    @State.bound
    private toSelectBoxItem(item: AppointmentScheduleDefinition) {
        return {
            text: item.name,
            value: item.id
        } as ISelectBoxItem<AppointmentScheduleDefinitionId>;
    }

    public render() {
        return (
            <Ui.SelectBox
                {...this.props}
                loading={!this.items}
                items={this.items}
            />
        );
    }
}

export default connect(
    AppointmentScheduleDefinitionSelectBox,
    new DependencyAdapter<IAppointmentScheduleDefinitionSelectBoxProps, IAppointmentScheduleDefinitionSelectBoxDependencies>(container => {
        return {
            schedulingReferenceDataStore: container.resolve("SchedulingReferenceDataStore")
        };
    })
);
