import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import ActionBoundNavButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundNavButton";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import ShowNewCareWorklistScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewCareWorklistScreenAction.g";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";

interface IDevCareWorklistMenuDependencies {
    applicationContext: ApplicationContext;
}

interface IDevCareWorklistMenuProps {
    _dependencies?: IDevCareWorklistMenuDependencies;
}

class DevCareWorklistMenu extends React.Component<IDevCareWorklistMenuProps> {

    private get actionDescriptor() {
        if (!this.props._dependencies.applicationContext.contextualOrganizationUnitId) {
            return null;
        }

        return ActionDescriptor.fromAction(
            new ShowNewCareWorklistScreenAction(ScreenDisplayMode.Full, new PointOfCareId(this.props._dependencies.applicationContext.contextualOrganizationUnitId.value), "AMBPL"));
    }

    public render() {
        return this.actionDescriptor && (
            <ActionBoundNavButton
                actionDescriptor={this.actionDescriptor}
                automationId="careWorklist2"
                text="DEV: Esetlista screen" />
        );
    }

}

export default connect(
    DevCareWorklistMenu,
    new DependencyAdapter<IDevCareWorklistMenuProps, IDevCareWorklistMenuDependencies>(c => ({
        applicationContext: c.resolve("ApplicationContext"),
    }))
);
