import State, { IObservableArray, IObservableValue } from "@Toolkit/ReactClient/Common/StateManaging";
import IEditableLayoutBlockStore from "./Model/IEditableLayoutBlockStore";
import { IFormDefinitionVersion } from "./FormLayoutEditorHandle";
import FormSchemaVersion from "@Toolkit/FormEngine/Model/Schema/FormSchemaVersion";
import FormLayoutVersionStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutVersionStore";
import EditableLayoutGroupBlockStore from "./Model/EditableLayoutGroupBlockStore";
import FormLayoutGroupBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutGroupBlockStore";
import EditableLayoutRowBlockStore from "./Model/EditableLayoutRowBlockStore";
import FormLayoutRowBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutRowBlockStore";
import IFormLayoutBlockStore from "@Toolkit/FormEngine/Model/Layout/IFormLayoutBlockStore";
import EditableLayoutColumnStore from "./Model/EditableLayoutColumnStore";
import FormLayoutRowColumnStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutRowColumnStore";
import EditableDataElementEditorStore from "./Model/EditableDataElementEditorStore";
import FormLayoutDataElementEditorStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutDataElementEditorStore";
import IEditableLayoutStore from "./Model/IEditableLayoutStore";
import IFormValidationRule from "@Toolkit/FormEngine/Model/Schema/Validation/IFormValidationRule";
import EditableShouldNotBeLongerThanValidationRule from "./Model/Validation/EditableShouldNotBeLongerThanValidationRule";
import RuleTypeId from "@Primitives/RuleTypeId.g";
import EditableShouldNotBeShorterThanValidationRule from "./Model/Validation/EditableShouldNotBeShorterThanValidationRule";
import EditableShouldMatchPatternValidationRule from "./Model/Validation/EditableShouldMatchPatternValidationRule";
import EditableShouldBeWithinRangeValidationRule from "./Model/Validation/EditableShouldBeWithinRangeValidationRule";
import FormEnumStore from "@Toolkit/FormEngine/Model/Schema/FormEnumStore";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import EditableLayoutCustomBlockStore from "./Model/EditableLayoutCustomBlockStore";
import FormEditBehavior from "@Toolkit/FormEngine/Model/FormEditBehavior";
import FormLogicType from "@Primitives/FormLogicType";
import FormLayoutCustomBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutCustomBlockStore";
import FormLogicToken from "@Toolkit/FormEngine/Model/FormLogicToken";
import FormLogic from "@Toolkit/FormEngine/Model/FormLogic";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";
import EditableMultiLingualLabel from "@Toolkit/FormEngine/Model/Layout/EditableMultiLingualLabel";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { findParentCompositeDataElement } from "./DataElementHelpers";

export function mapFromEditable(
    id: FormDefinitionId | null,
    name: string,
    multiLingualDisplayName: EditableMultiLingualLabel,
    baseEntityType: string,
    layoutBlocks: IObservableArray<IEditableLayoutBlockStore>,
    dataElements: IObservableArray<FormDataElementBase>,
    validationRules: IObservableArray<IFormValidationRule>,
    enums: IObservableArray<FormEnumStore>,
    formEditBehavior: FormEditBehavior,
    formLogicType: FormLogicType,
    formLogics: FormLogic[],
    formLogicTokens: FormLogicToken[],
    withoutDataHandling: boolean,
    isUserForm: boolean,
    instanceLimit: number): IFormDefinitionVersion {
    return {
        id,
        name,
        multiLingualDisplayName: mapFromEditableMultiLingualLabel(multiLingualDisplayName),
        baseEntityType,
        rootSchemaVersion: new FormSchemaVersion(-1, "Main", Array.from(dataElements), Array.from(validationRules)),
        rootLayoutVersion: new FormLayoutVersionStore(-1, layoutBlocks.map(mapLayoutBlock)),
        enums: Array.from(enums),
        formEditBehavior: formEditBehavior,
        formLogics,
        formLogicType,
        formLogicTokens,
        withoutDataHandling,
        isUserForm,
        instanceLimit
    };

    function mapLayoutBlock(block: IEditableLayoutBlockStore): IFormLayoutBlockStore {
        if (block instanceof EditableLayoutGroupBlockStore) {
            return new FormLayoutGroupBlockStore(mapFromEditableMultiLingualLabel(block.multiLingualDisplayName), block.dataReference, block.contentBlocks.map(mapLayoutBlock));

        } else if (block instanceof EditableLayoutRowBlockStore) {
            return new FormLayoutRowBlockStore(block.content.map(mapColumn));

        } else if (block instanceof EditableLayoutCustomBlockStore) {
            return new FormLayoutCustomBlockStore(block.customBlockIdentifier, block.settings?.clone());
        }

        throw new Error("Unknown block type.");
    }

    function mapColumn(col: EditableLayoutColumnStore): FormLayoutRowColumnStore {
        return new FormLayoutRowColumnStore(
            col.editor ? mapEditor(col.editor) : col.contentBlocks.map(mapLayoutBlock),
            col.size
        );
    }

    function mapEditor(editor: EditableDataElementEditorStore): FormLayoutDataElementEditorStore {
        return new FormLayoutDataElementEditorStore(editor.editorType, editor.dataReference, mapFromEditableMultiLingualLabel(editor.multiLingualLabel));
    }
}

export function mapFromEditableMultiLingualLabel(editableMultiLingualLabel: EditableMultiLingualLabel): MultiLingualLabel {
    const localizedLabelMap = new Map<string, string>();

    editableMultiLingualLabel.localizedLabels.forEach((value: IObservableValue<string>, key: string) => {
        if (!isNullOrEmptyString(value.get())) {
            localizedLabelMap.set(key, value.get());
        }
    });
    return new MultiLingualLabel(localizedLabelMap);
}

export function mapToEditableMultiLingualLabel(multiLingualLabel: MultiLingualLabel): EditableMultiLingualLabel {
    const editableMultiLingualLabel = new EditableMultiLingualLabel();
    multiLingualLabel.localizedLabels.forEach((value: string, key: string) => editableMultiLingualLabel.setLocalizedLabel(key, value));
    return editableMultiLingualLabel;
}

export interface IEditableFormDefinitionVersion {
    multiLingualDisplayName: EditableMultiLingualLabel;
    baseEntityType: string;
    layoutBlocks: IEditableLayoutBlockStore[];
    dataElements: FormDataElementBase[];
    validationRules: IFormValidationRule[];
    enums: FormEnumStore[];
    formEditBehavior: FormEditBehavior;
    formLogicType: FormLogicType;
    formLogics: FormLogic[];
    formLogicTokens: FormLogicToken[];
    withoutDataHandling: boolean;
    isUserForm: boolean;
    instanceLimit: number;
}

export function mapToEditable(definitionVersion: IFormDefinitionVersion, cultureCode: string): IEditableFormDefinitionVersion {
    return {
        multiLingualDisplayName: mapToEditableMultiLingualLabel(definitionVersion.multiLingualDisplayName),
        baseEntityType: definitionVersion.baseEntityType,
        dataElements: definitionVersion.rootSchemaVersion.dataElements,
        layoutBlocks: definitionVersion.rootLayoutVersion.content.map(b => mapBlockToEditable(b, cultureCode, [], definitionVersion.rootSchemaVersion.dataElements)),
        validationRules: definitionVersion.rootSchemaVersion.validationRules.map(mapValidationRuleToEditable),
        enums: definitionVersion.enums,
        formEditBehavior: definitionVersion.formEditBehavior,
        formLogics: definitionVersion.formLogics,
        formLogicType: definitionVersion.formLogicType,
        formLogicTokens: definitionVersion.formLogicTokens,
        withoutDataHandling: definitionVersion.withoutDataHandling,
        isUserForm: definitionVersion.isUserForm,
        instanceLimit: definitionVersion.instanceLimit
    };
}

function mapValidationRuleToEditable(rule: IFormValidationRule) {
    switch (rule.ruleType) {
        case RuleTypeId.ShouldNotBeShorterThan.value:
            return new EditableShouldNotBeShorterThanValidationRule(rule.entityPath, {
                minLength: rule.ruleParameters?.minLength ?? 0
            });
        case RuleTypeId.ShouldNotBeLongerThan.value:
            return new EditableShouldNotBeLongerThanValidationRule(rule.entityPath, {
                maxLength: rule.ruleParameters?.maxLength ?? 0
            });
        case RuleTypeId.ShouldMatchPattern.value:
            return new EditableShouldMatchPatternValidationRule(rule.entityPath, {
                pattern: rule.ruleParameters?.pattern ?? ""
            });
        case RuleTypeId.ShouldBeWithinRange.value:
            return new EditableShouldBeWithinRangeValidationRule(rule.entityPath, {
                minValue: rule.ruleParameters?.minValue ?? null,
                maxValue: rule.ruleParameters?.maxValue ?? null
            });
        default:
            return rule;
    }
}

export function mapBlockToEditable(
    block: IFormLayoutBlockStore,
    cultureCode: string,
    parentDataReferences: string[] | null,
    formDataElements: FormDataElementBase[],
    editorMappedCallback?: (store: EditableDataElementEditorStore) => void): IEditableLayoutStore | null {

    if (block instanceof FormLayoutRowBlockStore) {
        const editableBlock = new EditableLayoutRowBlockStore();
        editableBlock.content.replace(block.content.map(mapCol));
        return editableBlock;
    }

    if (block instanceof FormLayoutGroupBlockStore) {
        let isArray: boolean = false;
        if (!isNullOrUndefined(block.dataReference)) {
            const localParentDataReferences = isNullOrUndefined(parentDataReferences) ? [] : parentDataReferences;
            const parentDataElement = findParentCompositeDataElement(State.createObservableShallowArray(formDataElements), [...localParentDataReferences, block.dataReference]);

            isArray = parentDataElement.isArray;
        }

        const editableBlock = new EditableLayoutGroupBlockStore(cultureCode, block.dataReference, parentDataReferences, isArray, mapToEditableMultiLingualLabel(block.multiLingualDisplayName));

        const childParentDataReferences = [...parentDataReferences];
        if (!isNullOrUndefined(block.dataReference)) {
            childParentDataReferences.push(block.dataReference);
        }

        editableBlock.contentBlocks.replace(block.content.map(b => mapBlockToEditable(b, cultureCode, childParentDataReferences, formDataElements, editorMappedCallback)));
        return editableBlock;
    }

    if (block instanceof FormLayoutCustomBlockStore) {
        const editableBlock = new EditableLayoutCustomBlockStore(block.customBlockIdentifier, block.settings?.clone());
        return editableBlock;
    }

    return null;

    function mapCol(col: FormLayoutRowColumnStore) {
        const editableColumn = new EditableLayoutColumnStore(col.size);
        if (col.content instanceof FormLayoutDataElementEditorStore) {
            editableColumn.editor = mapEditorToEditable(col.content, cultureCode, parentDataReferences, editorMappedCallback);
        } else {
            editableColumn.contentBlocks.replace(col.content.map(b => mapBlockToEditable(b, cultureCode, parentDataReferences, formDataElements, editorMappedCallback)));
        }
        return editableColumn;
    }
}

export function mapEditorToEditable(
    store: FormLayoutDataElementEditorStore,
    cultureCode: string,
    parentDataReferences: string[] | null,
    editorMappedCallback?: (store: EditableDataElementEditorStore) => void) {

    const editor = new EditableDataElementEditorStore(store.editorType, cultureCode, mapToEditableMultiLingualLabel(store.multiLingualLabel));
    editor.dataReference = store.dataReference;
    editor.parentDataReferences = isNullOrUndefined(parentDataReferences) ? null : [...parentDataReferences];
    editorMappedCallback?.(editor);
    return editor;
}
