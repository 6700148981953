import DependencyContainer from "@DiContainer";
import PerformedServicesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/PerformedServices/PerformedServicesApiAdapter";
import CareActivityDetailApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityDetailApiAdapter";
import CareActivityBaseDataApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityBaseData/CareActivityBaseDataApiAdapter";
import CareActivityOperationApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityOperationApiAdapter";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import ObservationsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/Observations/ObservationsApiAdapter";
import PatientsCareActivitiesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/PatientCareActivities/PatientsCareActivitiesApiAdapter";
import FinancedServiceApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/Finance/FinancedServiceApiAdapter";
import DiagnosisListApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/DiagnosisList/DiagnosisListApiAdapter";
import CareActivityDetailStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityDetailStoreMapper";
import CareActivityStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityStoreMapper";
import CareActivityDischargeDataStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDischargeData/CareActivityDischargeDataStoreMapper";
import PatientsCareActivitiesStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/PatientCareActivities/PatientsCareActivitiesStoreMapper";
import PerformedServicesStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/PerformedServices/PerformedServicesStoreMapper";
import DiagnosisListStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/DiagnosisList/DiagnosisListStoreMapper";
import CareActivityTextBlockApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityTextBlock/CareActivityTextBlockApiAdapter";
import CareActivityTextBlockStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityTextBlock/CareActivityTextBlockStoreMapper";
import CareActivityDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentApiAdapter";

DependencyContainer
    .bind("CareActivityOperationApiAdapter")
    .to(CareActivityOperationApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("CareActivityDetailApiAdapter")
    .to(CareActivityDetailApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("CareActivityBaseDataApiAdapter")
    .to(CareActivityBaseDataApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PerformedServicesApiAdapter")
    .to(PerformedServicesApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("CareActivityApiAdapter2")
    .to(CareActivityApiAdapter2)
    .inSingletonScope();

DependencyContainer
    .bind("ObservationsApiAdapter")
    .to(ObservationsApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PatientsCareActivitiesApiAdapter")
    .to(PatientsCareActivitiesApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("FinancedServiceApiAdapter")
    .to(FinancedServiceApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("DiagnosisListApiAdapter")
    .to(DiagnosisListApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("CareActivityDetailStoreMapper")
    .to(CareActivityDetailStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("CareActivityStoreMapper")
    .to(CareActivityStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("CareActivityDischargeDataStoreMapper")
    .to(CareActivityDischargeDataStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("PatientsCareActivitiesStoreMapper")
    .to(PatientsCareActivitiesStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("PerformedServicesStoreMapper")
    .to(PerformedServicesStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("DiagnosisListStoreMapper")
    .to(DiagnosisListStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("CareActivityTextBlockApiAdapter")
    .to(CareActivityTextBlockApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("CareActivityTextBlockStoreMapper")
    .to(CareActivityTextBlockStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("CareActivityDocumentApiAdapter")
    .to(CareActivityDocumentApiAdapter)
    .inSingletonScope();
