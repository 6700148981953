import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiCovidStatusReportStatus from "@Primitives/EhiCovidStatusReportStatus";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import IEhiCovidStatusReportStatusData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IEhiCovidStatusReportStatusData";
import EhiSyncStatusLabel from "@HunEHealthInfrastructurePlugin/Common/Components/EhiSyncStatusLabel";

interface IEhiCovidStatusReportStatusColumnDependencies {
    localizationService: ILocalizationService;
}

interface IEhiCovidStatusReportStatusColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IEhiCovidStatusReportStatusColumnDependencies;
}


const EhiCovidStatusReportStatusColumn: React.FC<IEhiCovidStatusReportStatusColumnProps> = (props: IEhiCovidStatusReportStatusColumnProps) => {

    const valueRenderer = useCallback((value: IEhiCovidStatusReportStatusData) => {
        const localized = isNullOrUndefined(value) ? null : props._dependencies.localizationService.localizeEnum(EhiCovidStatusReportStatus[value.ehiCovidStatusReportStatus], "EhiCovidStatusReportStatus");
        return isNullOrUndefined(localized) ? null : <> {localized.Name} <EhiSyncStatusLabel value={value.ehiSyncStatus} upperIndex /></>;
    }, []);

    return (
        <DataGridColumn
            width={undefined}
            {...props}
            onRenderCellValue={valueRenderer}
            showHint={false}
        />
    );
};

export default connect(
    EhiCovidStatusReportStatusColumn,
    new DependencyAdapter<IEhiCovidStatusReportStatusColumnProps, IEhiCovidStatusReportStatusColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
