import Di from "@Di";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import IFormLayoutEditorProps from "./IFormLayoutEditorProps";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import IDragData from "./IDragData";
import EditableLayoutRowBlockStore from "./Model/EditableLayoutRowBlockStore";
import EditableLayoutColumnStore from "./Model/EditableLayoutColumnStore";
import IEditableLayoutBlockStore from "./Model/IEditableLayoutBlockStore";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import EditableDataElementEditorStore from "./Model/EditableDataElementEditorStore";
import EditableLayoutGroupBlockStore from "./Model/EditableLayoutGroupBlockStore";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import { IFormDefinitionVersion } from "./FormLayoutEditorHandle";
import { mapFromEditable, mapToEditable, mapEditorToEditable, mapBlockToEditable } from "./FormLayoutEditorMapper";
import { resizeColumnsForAddingNew } from "./RowColumnLayoutHelper";
import IFormFieldNameFactory from "./IFormFieldNameFactory";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import IFormToken from "./Model/IFormToken";
import FormLayoutFragment from "./Model/FormLayoutFragment";
import FormLayoutDataElementEditorStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutDataElementEditorStore";
import IFormLayoutBlockStore from "@Toolkit/FormEngine/Model/Layout/IFormLayoutBlockStore";
import TokenDefaultValue from "@Toolkit/FormEngine/Model/Layout/TokenDefaultValue";
import IFormValidationRule from "@Toolkit/FormEngine/Model/Schema/Validation/IFormValidationRule";
import EnumReferenceEditorDialogParams, { IEnumReferenceEditorDialogResult } from "./PropertyPanels/EnumReferenceEditorDialog/EnumReferenceEditorDialogParams";
import FormEnumStore from "@Toolkit/FormEngine/Model/Schema/FormEnumStore";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import { INamedFormEnum } from "@Toolkit/FormEngine/Model/IFormEnumsInDefinition";
import IFormFieldReference from "@HisPlatform/BoundedContexts/FormEngine/Components/FormFieldSelector/IFormFieldReference";
import NumberFormDataElement from "@Toolkit/FormEngine/Model/Schema/NumberFormDataElement";
import EditableLayoutCustomBlockStore from "./Model/EditableLayoutCustomBlockStore";
import { getAllEditors, getAllEditorsAndGroups } from "./GetAllEditors";
import FormEditorChange from "./Model/FormEditorChange";
import FormEditBehavior from "@Toolkit/FormEngine/Model/FormEditBehavior";
import FormLogicType from "@Primitives/FormLogicType";
import EnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/EnumFormDataElement";
import ReferencedEntityFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEntityFormDataElement";
import StringFormDataElement from "@Toolkit/FormEngine/Model/Schema/StringFormDataElement";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import FilterDialogParams, { IFilterDialogResult } from "./PropertyPanels/Filters/FilterDialogParams";
import FormLogicEditorDialogParams, { IFormLogicEditorDialogResult } from "./PropertyPanels/FormLogicEditorDialog/FormLogicEditorDialogParams";
import FormLogicToken from "@Toolkit/FormEngine/Model/FormLogicToken";
import FormLogicTokenEditorDialogParams, { IFormLogicTokenEditorDialogResult } from "./PropertyPanels/FormLogicTokenEditorDialog/FormLogicTokenEditorDialogParams";
import FormLogic from "@Toolkit/FormEngine/Model/FormLogic";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import ReferencedExtensibleEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedExtensibleEnumFormDataElement";
import ReferencedEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEnumFormDataElement";
import EditableMultiLingualLabel from "@Toolkit/FormEngine/Model/Layout/EditableMultiLingualLabel";
import Language, { convertCultureCodeToLanguage, convertLanguageToCultureCode, getCurrentOrDefaultCultureCode } from "@Primitives/Language";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import CompositeFormDataElement from "@Toolkit/FormEngine/Model/Schema/CompositeFormDataElement";
import { findParentCompositeDataElement, findParentDataElementCollection } from "./DataElementHelpers";
import FormFieldDependencyDialogParams, { IFormFieldDependencyDialogResult } from "./PropertyPanels/FormFieldDependencyDialog/FormFieldDependencyDialogParams";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import FormFieldData from "./PropertyPanels/FormFieldDependencyDialog/FormFieldData";
import { composeFullyQualifiedName } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/ComposeFullyQualifiedName";
import FormPropertyChange from "@HisPlatform/BoundedContexts/FormEngine/Api/FormEditing/Enum/FormPropertyChange.g";
import FormEditorAction from "@HisPlatform/BoundedContexts/FormEngine/Api/FormEditing/Enum/FormEditorAction.g";

export interface ITokenDefaultValueOption {
    symbol: string;
    displayAttributePath: string;
    editorType: string;
    displayName: string;
}

@Di.injectable()
export default class FormLayoutEditorStore extends PanelStoreBase<IFormLayoutEditorProps> implements ILoadablePanelStore {

    @State.observable.ref public areDropZonesHighlighted = false;
    @State.observable.ref public selectedColumn: EditableLayoutColumnStore | null = null;
    @State.observable.ref public selectedColumnParentCollection: IObservableArray<EditableLayoutColumnStore> | null = null;
    @State.observable.ref public selectedEditor: EditableDataElementEditorStore | null = null;
    @State.observable.ref public selectedGroup: EditableLayoutGroupBlockStore | null = null;
    @State.observable.ref public selectedCustomBlock: EditableLayoutCustomBlockStore | null = null;

    @State.computed public get isSomethingSelected() {
        return !!this.selectedColumn || !!this.selectedEditor || !!this.selectedGroup || !!this.selectedCustomBlock;
    }

    @State.computed public get selectedDataElement() {
        if (!this.selectedEditor) {
            return null;
        }

        const parentElementCollection = findParentDataElementCollection(this.dataElements, this.selectedEditor.parentDataReferences);
        return parentElementCollection.find(de => de.name === this.selectedEditor.dataReference);
    }

    @State.computed public get selectedDataElementByGroup() {
        if (!this.selectedGroup || !this.selectedGroup.hasDataReference) {
            return null;
        }

        const parentElementCollection = findParentDataElementCollection(this.dataElements, this.selectedGroup.parentDataReferences);
        return parentElementCollection.find(de => de.name === this.selectedGroup.dataReference);
    }

    @State.observable.ref public id: FormDefinitionId | null = null;
    @State.observable.ref public name: string | null = null;
    @State.observable.ref public multiLingualDisplayName: EditableMultiLingualLabel | null = this.createNewEditableMultiLingualLabel();
    @State.observable.ref public formLogicType: FormLogicType | null = null;
    @State.observable.ref public formLogics: FormLogic[] | null = null;
    @State.observable.ref public formLogicTokens: FormLogicToken[] | null = null;
    @State.observable.ref public baseEntityType: string | null = null;
    @State.observable.ref public formEditBehavior: FormEditBehavior = FormEditBehavior.ImmediatelyEditable;
    @State.observable.ref public withoutDataHandling: boolean = false;
    @State.observable.ref public isUserForm: boolean = false;
    @State.observable public currentLabelLanguage: Language | null = null;
    @State.observable.ref public instanceLimit: number = null;

    @State.observable.ref public tokens: IFormToken[] | null = null;

    private editorChanges: FormEditorChange[] = [];

    private readonly dynamicFormDataElementItem: ITokenDefaultValueOption = {
        displayName: StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.DefaultValueFromAnotherFormItemName,
        editorType: null,
        symbol: "DynamicFormDataElement",
        displayAttributePath: "Value"
    };

    @State.computed public get tokenDefaultValues(): ITokenDefaultValueOption[] {
        if (!this.tokens) {
            return null;
        }

        const tokens = this.tokens.flatMap(token => {
            if (token.tokenLayout.content instanceof FormLayoutDataElementEditorStore) {
                const dataElement = token.dataElements.find(x => x.name === (token.tokenLayout.content as FormLayoutDataElementEditorStore).dataReference);
                if (dataElement.defaultValue instanceof TokenDefaultValue) {
                    return [{
                        symbol: dataElement.defaultValue.symbol,
                        displayAttributePath: dataElement.defaultValue.displayAttributePath,
                        editorType: token.tokenLayout.content.editorType,
                        displayName: token.tokenLayout.content.multiLingualLabel.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode)
                    } as ITokenDefaultValueOption];
                }

                return null;
            } else {

                const editors = getAllEditors(token.tokenLayout.content);

                return editors.map(e => {
                    const tokenDefaultValue = token.dataElements.find(x => x.name === e.dataReference)?.defaultValue as TokenDefaultValue;
                    return {
                        symbol: tokenDefaultValue?.symbol,
                        displayAttributePath: tokenDefaultValue?.displayAttributePath,
                        editorType: e.editorType,
                        displayName: e.multiLingualLabel.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode)
                    } as ITokenDefaultValueOption;
                }).filter(Boolean);
            }
        }).filter(x => Boolean(x) && x.symbol !== "DynamicFormDataElement");

        tokens.unshift(this.dynamicFormDataElementItem);

        return tokens;
    }

    public readonly rootBlocks = State.createObservableShallowArray<IEditableLayoutBlockStore>([]);
    public readonly dataElements = State.createObservableShallowArray<FormDataElementBase>([]);
    public readonly validationRules = State.createObservableShallowArray<IFormValidationRule>([]);
    public readonly enums = State.createObservableShallowArray<FormEnumStore>([]);

    @State.computed public get propertyReferences() {
        return this.dataElements.map(de => de.name) ?? [];
    }

    @State.computed public get selectedEditorValidationRules() {
        let parentPath = null;
        if (!isNullOrUndefined(this.selectedEditor.parentDataReferences)) {
            parentPath = this.selectedEditor.parentDataReferences.join(".");
        }

        const fullPath = isNullOrEmptyString(parentPath) ? this.selectedEditor.dataReference : parentPath + "." + this.selectedEditor.dataReference;

        return !!this.selectedEditor && this.validationRules.filter(vr => vr.entityPath === fullPath);
    }

    @State.computed public get isLongText() {
        return (this.selectedDataElement instanceof StringFormDataElement && this.selectedDataElement?.isLong) ?? false;
    }

    @State.computed public get isIntegerNumber() {
        return (this.selectedDataElement instanceof NumberFormDataElement && this.selectedDataElement?.isInteger) ?? false;
    }

    constructor(
        @Di.inject("FormEditorRegistry") public readonly formEditorRegistry: FormEditorRegistry,
        @Di.inject("IDialogService") private readonly dialogService: IDialogService,
        @Di.inject("IFormFieldNameFactory") private readonly formFieldNameFactory: IFormFieldNameFactory,
        @Di.inject("IFormEngineReferenceDataStore") private readonly formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("ICurrentCultureProvider") public readonly cultureCodeProvider: ICurrentCultureProvider
    ) {
        super();
    }

    public readonly loadAsync = this.backgroundAsync(async () => {
        await this.formFieldNameFactory.initializeAsync();

        const tokens = await this.formEngineReferenceDataStore.getOrLoadAllTokensAsync();
        State.runInAction(() => { this.tokens = tokens; });
    });

    @State.bound
    private createNewEditableMultiLingualLabel() {
        const editableMultiLingualLabel = new EditableMultiLingualLabel();
        const newTitleLabel = StaticFormEngineResources.FormDefinitionEditor.Default.NewFormTitle;
        editableMultiLingualLabel.setLocalizedLabel(this.cultureCodeProvider.cultureCode, newTitleLabel);
        return editableMultiLingualLabel;
    }

    @State.action.bound
    public setDefinition(def: IFormDefinitionVersion) {
        if (!def) {
            this.rootBlocks.clear();
            this.dataElements.clear();
            this.validationRules.clear();
            this.enums.clear();
            this.id = null;
            this.name = null;
            this.multiLingualDisplayName = this.createNewEditableMultiLingualLabel();
            this.currentLabelLanguage = convertCultureCodeToLanguage(this.cultureCodeProvider.cultureCode);
            this.baseEntityType = null;
            this.formEditBehavior = FormEditBehavior.ImmediatelyEditable;
            this.formLogics = null;
            this.formLogicTokens = null;
            this.formLogicType = null;
            this.withoutDataHandling = false;
            this.isUserForm = true;
            this.instanceLimit = null;
        } else {
            const mapped = mapToEditable(def, this.cultureCodeProvider.cultureCode);
            this.multiLingualDisplayName = mapped.multiLingualDisplayName;
            this.setCurrentLabelLanguage(getCurrentOrDefaultCultureCode(this.cultureCodeProvider.cultureCode, this.multiLingualDisplayName));

            this.id = def.id;
            this.name = def.name;
            this.baseEntityType = mapped.baseEntityType;
            this.rootBlocks.replace(mapped.layoutBlocks);
            this.dataElements.replace(mapped.dataElements);
            this.validationRules.replace(mapped.validationRules);
            this.enums.replace(mapped.enums);
            this.formEditBehavior = mapped.formEditBehavior;
            this.formLogicType = mapped.formLogicType;
            this.formLogics = mapped.formLogics;
            this.formLogicTokens = mapped.formLogicTokens;
            this.withoutDataHandling = mapped.withoutDataHandling;
            this.isUserForm = mapped.isUserForm;
            this.instanceLimit = mapped.instanceLimit;
        }

    }

    @State.action.bound
    public getDefinition(): IFormDefinitionVersion {

        return mapFromEditable(
            this.id,
            this.name,
            this.multiLingualDisplayName,
            this.baseEntityType,
            this.rootBlocks,
            this.dataElements,
            this.validationRules,
            this.enums,
            this.formEditBehavior,
            this.formLogicType,
            this.formLogics,
            this.formLogicTokens,
            this.withoutDataHandling,
            this.isUserForm,
            this.instanceLimit
        );
    }

    @State.action.bound
    public getEditorChanges(): FormEditorChange[] {
        return this.editorChanges;
    }

    @State.action.bound
    public resetEditorChanges() {
        this.editorChanges = [];
    }

    @State.action
    public moveIntoBlockCollection(item: IDragData, collection: IObservableArray<IEditableLayoutBlockStore>, indexToInsert: number, parentDataReferences: string[] | null) {

        let elements: IEditableLayoutBlockStore[] | IEditableLayoutBlockStore;

        switch (item.dragItemType) {
            case "editor":
                elements = this.createBlockForEditor(item.store as EditableDataElementEditorStore, parentDataReferences);

                if (!item.isNew) {
                    item.sourceCollection?.remove(item.store as EditableLayoutColumnStore);
                }
                break;

            case "column":
                elements = this.createBlockLayoutElement({ isNew: true, dragItemType: "block", type: "row" }, parentDataReferences);

                if (item.isNew) {
                    throw new Error("Not supported");
                } else {
                    const columnToMove = item.store as EditableLayoutColumnStore;

                    item.sourceCollection?.remove(columnToMove);
                    (elements as EditableLayoutRowBlockStore).content.push(columnToMove);

                    this.handleDataElementChangesOnColumnDrag(columnToMove.editor, parentDataReferences);
                    columnToMove.editor.parentDataReferences = isNullOrUndefined(parentDataReferences) ? null : [...parentDataReferences];
                }
                break;

            case "fragment":
                const fragment = item.store as FormLayoutFragment;
                if (fragment.content instanceof FormLayoutDataElementEditorStore) {
                    elements =
                        this.createBlockForEditor(mapEditorToEditable(fragment.content, this.cultureCodeProvider.cultureCode, parentDataReferences), parentDataReferences);
                } else {
                    elements = (fragment.content as IFormLayoutBlockStore[])
                        .map(b =>
                            mapBlockToEditable(
                                b,
                                this.cultureCodeProvider.cultureCode,
                                parentDataReferences,
                                this.dataElements,
                                this.createNewDataElement));
                }
                break;

            case "block":
                if (item.isNew) {

                    elements = this.createBlockLayoutElement(item, parentDataReferences);

                    if (elements instanceof EditableLayoutGroupBlockStore) {
                        const childParentDataReferences = isNullOrUndefined(parentDataReferences) ? [] : [...parentDataReferences];
                        if (!isNullOrUndefined(elements.dataReference)) {
                            childParentDataReferences.push(elements.dataReference);
                        }

                        elements = this.createBlockLayoutElement({ isNew: true, dragItemType: "block", type: "row" }, childParentDataReferences);
                        const rowElement = (elements as EditableLayoutRowBlockStore);

                        const column = new EditableLayoutColumnStore({ xs: 12 });
                        rowElement.content.push(column);

                        column.contentBlocks.push(this.createBlockLayoutElement({ isNew: true, dragItemType: "block", type: "group" }, childParentDataReferences));
                    }
                } else {
                    elements = item.store!;
                    item.sourceCollection?.remove(item.store!);

                    if (item.store instanceof EditableLayoutRowBlockStore) {
                        item.store.content.forEach(i => {
                            if (i instanceof EditableLayoutColumnStore) {
                                i.contentBlocks.forEach(j => {
                                    if (j instanceof EditableDataElementEditorStore || (j instanceof EditableLayoutGroupBlockStore && j.hasDataReference)) {
                                        this.handleDataElementChangesOnColumnDrag(j, parentDataReferences);
                                        j.parentDataReferences = isNullOrUndefined(parentDataReferences) ? null : [...parentDataReferences];
                                    }
                                });
                            }
                        });
                    }
                }
        }

        if (Array.isArray(elements)) {
            collection?.splice(indexToInsert, 0, ...elements);
        } else {
            collection?.splice(indexToInsert, 0, elements);
        }
    }

    @State.action
    public moveIntoColumnCollection(item: IDragData, collection: IObservableArray<EditableLayoutColumnStore>, indexToInsert: number, parentDataReferences: string[] | null) {

        let columnToMove: EditableLayoutColumnStore;

        switch (item.dragItemType) {
            case "editor":
                if (item.isNew) {
                    columnToMove = new EditableLayoutColumnStore({ xs: 12 });
                    columnToMove.editor = this.getOrCloneEditorStore(item.store as EditableDataElementEditorStore, parentDataReferences);
                } else {
                    item.sourceCollection?.remove(item.store!);
                    columnToMove = item.store! as EditableLayoutColumnStore;
                }
                break;

            case "column":
                if (item.isNew) {
                    throw new Error("Not supported");
                } else {
                    item.sourceCollection?.remove(item.store!);
                    columnToMove = item.store! as EditableLayoutColumnStore;

                    this.handleDataElementChangesOnColumnDrag(columnToMove.editor, parentDataReferences);
                    columnToMove.editor.parentDataReferences = isNullOrUndefined(parentDataReferences) ? null : [...parentDataReferences];
                }
                break;

            case "fragment":
                const fragment = item.store as FormLayoutFragment;
                columnToMove = new EditableLayoutColumnStore({ xs: 12 });

                if (fragment.content instanceof FormLayoutDataElementEditorStore) {
                    columnToMove.editor = this.getOrCloneEditorStore(mapEditorToEditable(fragment.content, this.cultureCodeProvider.cultureCode, parentDataReferences), parentDataReferences);
                } else {
                    columnToMove.contentBlocks
                        .replace((fragment.content as IFormLayoutBlockStore[])
                            .map(b => mapBlockToEditable(b, this.cultureCodeProvider.cultureCode, parentDataReferences, this.dataElements, this.createNewDataElement)));
                }
                break;

            case "block":
                if (item.isNew) {
                    columnToMove = new EditableLayoutColumnStore({ xs: 12 });
                    columnToMove.contentBlocks.push(this.createBlockLayoutElement(item, parentDataReferences));
                } else {
                    columnToMove = new EditableLayoutColumnStore({ xs: 12 });

                    item.sourceCollection?.remove(item.store!);
                    columnToMove.contentBlocks.push(item.store! as IEditableLayoutBlockStore);
                }

                if (item.store instanceof EditableLayoutRowBlockStore) {
                    item.store.content.forEach(i => {
                        if (i instanceof EditableLayoutColumnStore) {
                            i.contentBlocks.forEach(j => {
                                if (j instanceof EditableDataElementEditorStore || (j instanceof EditableLayoutGroupBlockStore && j.hasDataReference)) {
                                    this.handleDataElementChangesOnColumnDrag(j, parentDataReferences);
                                    j.parentDataReferences = isNullOrUndefined(parentDataReferences) ? null : [...parentDataReferences];
                                }
                            });
                        }
                    });
                }
        }

        resizeColumnsForAddingNew(columnToMove, collection);
        collection?.splice(indexToInsert, 0, columnToMove);
    }

    private handleDataElementChangesOnColumnDrag(store: EditableDataElementEditorStore | EditableLayoutGroupBlockStore, parentDataReferences: string[] | null) {

        const oldParentDataElementCollection = findParentDataElementCollection(this.dataElements, store.parentDataReferences);
        const newParentDataElementCollection = findParentDataElementCollection(this.dataElements, parentDataReferences);

        const dataElement = oldParentDataElementCollection.find(i => i.name === store.dataReference);
        oldParentDataElementCollection.remove(dataElement);
        newParentDataElementCollection.push(dataElement);
    }

    private createBlockForEditor(editorStore: EditableDataElementEditorStore, parentDataReferences: string[] | null): IEditableLayoutBlockStore {
        const block = this.createBlockLayoutElement({ isNew: true, dragItemType: "block", type: "row" }, parentDataReferences);
        const column = new EditableLayoutColumnStore({ xs: 12 });
        column.editor = this.getOrCloneEditorStore(editorStore, parentDataReferences);
        (block as EditableLayoutRowBlockStore).content.push(column);
        return block;
    }

    @State.action
    public removeBlock(collection: IObservableArray<IEditableLayoutBlockStore>, item: IEditableLayoutBlockStore) {
        if (item instanceof EditableLayoutRowBlockStore && item.content?.length > 0) {
            this.dialogService
                .yesNo(
                    StaticFormEngineResources.FormDefinitionEditor.Dialog.AreYouSureToDeleteNotEmptyRowQuestionTitle,
                    StaticFormEngineResources.FormDefinitionEditor.Dialog.AreYouSureToDeleteNotEmptyRowQuestion
                )
                .then(State.action(result => {
                    if (result.resultCode === DialogResultCode.Yes) {
                        this.removeBlockDataElements(item);
                        collection?.remove(item);
                    }
                }));
        } else if (item instanceof EditableLayoutGroupBlockStore && item.contentBlocks?.length > 0) {
            this.dialogService
                .yesNo(
                    StaticFormEngineResources.FormDefinitionEditor.Dialog.AreYouSureToDeleteNotEmptyGroupQuestionTitle,
                    StaticFormEngineResources.FormDefinitionEditor.Dialog.AreYouSureToDeleteNotEmptyGroupQuestion
                )
                .then(State.action(result => {
                    if (result.resultCode === DialogResultCode.Yes) {
                        this.removeBlockDataElements(item);
                        collection?.remove(item);
                    }
                }));
        } else {
            this.removeBlockDataElements(item);
            collection?.remove(item);
        }
    }

    private removeBlockDataElements(block: IEditableLayoutBlockStore) {
        const editors = getAllEditorsAndGroups([block]);
        editors.forEach(this.removeEditorDataElement);
    }

    @State.bound
    private removeEditorDataElement(editor: EditableDataElementEditorStore | EditableLayoutGroupBlockStore) {
        const parentElementCollection = findParentDataElementCollection(this.dataElements, editor.parentDataReferences);
        const dataElementToRemove = parentElementCollection.find(de => de.name === editor.dataReference);
        parentElementCollection.remove(dataElementToRemove);
        this.handleEditorChangesForDelete(dataElementToRemove.name);
        this.handleFormFieldDependencyDelete(editor);

        const validationRules = this.validationRules.filter(vr => vr.entityPath === dataElementToRemove.name);
        if (validationRules.length > 0) {
            validationRules.forEach(vr => {
                this.validationRules.remove(vr);
            });
        }

        if (dataElementToRemove instanceof EnumFormDataElement && dataElementToRemove.enumReference?.isLocal) {
            const enumToRemove = this.enums.find(e => e.name === (dataElementToRemove as EnumFormDataElement).enumReference!.enumName);
            this.enums.remove(enumToRemove);
        }
    }

    @State.action
    public startDraggingElement() {
        this.areDropZonesHighlighted = true;
    }

    @State.action
    public stopDraggingElement() {
        this.areDropZonesHighlighted = false;
    }

    private createBlockLayoutElement(dragData: IDragData, parentDataReferences: string[] | null) {

        if (!!dragData.store) {
            const newStore = dragData.store.clone();

            if (newStore instanceof EditableLayoutCustomBlockStore) {
                const settings = this.formEditorRegistry.tryCreateCustomBlockSettings(newStore.customBlockIdentifier);
                newStore.settings = settings;

                const dataElements = this.formEditorRegistry.tryCreateCustomBlockDataElements(newStore.customBlockIdentifier);

                if (dataElements) {
                    const parentDataElementCollection = findParentDataElementCollection(this.dataElements, parentDataReferences);
                    parentDataElementCollection.push(...dataElements);

                    this.handleEditorChangesForAdd(...dataElements.map(de => de.name));
                }
            }

            return newStore;
        }

        switch (dragData.type) {
            case "row": return new EditableLayoutRowBlockStore();
            case "group":
                const store = new EditableLayoutGroupBlockStore(this.cultureCodeProvider.cultureCode, null, isNullOrUndefined(parentDataReferences) ? null : [...parentDataReferences], false);
                return store;
        }

        throw new Error(`Unknown block layout element to create: ${dragData?.type}`);
    }

    @State.action.bound
    public selectFormFieldDefaultValueReference(value: IFormFieldReference) {
        const settings = value ? {
            FormDefinitionName: value.formDefinitionName,
            FormDataElementName: value.formDataElementName,
        } : null;

        this.selectedEditor.setDefaultValue(new TokenDefaultValue("DynamicFormDataElement", "Value", settings));
    }

    @State.action
    public selectColumn(parentCollection: IObservableArray<EditableLayoutColumnStore>, col: EditableLayoutColumnStore) {
        this.selectedColumnParentCollection = parentCollection;
        this.selectedColumn = col;
        this.selectedEditor = null;
    }

    @State.action.bound
    public clearSelection() {
        this.selectedColumnParentCollection = null;
        this.selectedColumn = null;
        this.selectedEditor = null;
        this.selectedGroup = null;
        this.selectedCustomBlock = null;
    }

    @State.action
    public selectEditor(parentCollection: IObservableArray<EditableLayoutColumnStore>, col: EditableLayoutColumnStore, editor: EditableDataElementEditorStore) {
        this.selectedColumnParentCollection = parentCollection;
        this.selectedColumn = col;
        this.selectedEditor = editor;
    }

    @State.action
    public selectGroup(group: EditableLayoutGroupBlockStore) {
        this.selectedGroup = group;
    }

    @State.action
    public selectCustomBlock(block: EditableLayoutCustomBlockStore) {
        this.selectedCustomBlock = block;
    }

    @State.action.bound
    public deleteSelectedColumn() {
        if (this.selectedColumn.editor) {
            this.removeEditorDataElement(this.selectedColumn.editor);
        }
        this.selectedColumnParentCollection.remove(this.selectedColumn);
        this.clearSelection();
    }

    @State.action.bound
    public getOrCloneEditorStore(store: EditableDataElementEditorStore, parentDataReferences: string[] | null): EditableDataElementEditorStore {

        if (store.dataReference !== null) {
            return store;
        }
        const newStore = store.clone();
        newStore.parentDataReferences = isNullOrUndefined(parentDataReferences) ? null : [...parentDataReferences];

        this.createNewDataElement(newStore);
        return newStore;
    }

    private createNewDataElement(store: EditableDataElementEditorStore) {
        const propertyName = this.formFieldNameFactory.getNew();

        State.runInAction(() => {
            const dataElement = this.formEditorRegistry.tryCreateDataElement(store.editorType, propertyName);

            if (!dataElement) {
                throw new Error(`Cannot create data element for editor type: ${store.editorType}.`);
            }

            const parentDataElementCollection = findParentDataElementCollection(this.dataElements, store.parentDataReferences);
            parentDataElementCollection.push(dataElement);

            store.setDataReference(propertyName);

            this.handleEditorChangesForAdd(propertyName);
        });
    }

    private createNewCompositeDataElement(store: EditableLayoutGroupBlockStore) {
        const propertyName = this.formFieldNameFactory.getNew();

        State.runInAction(() => {
            const dataElement = new CompositeFormDataElement(propertyName, false, false, true, []);

            const parentDataElementCollection = findParentDataElementCollection(this.dataElements, store.parentDataReferences);
            parentDataElementCollection.push(dataElement);

            store.setDataReference(propertyName);
        });
    }

    @State.action.bound
    public setLocalizedLabel(label: string) {
        this.multiLingualDisplayName.setLocalizedLabel(convertLanguageToCultureCode(this.currentLabelLanguage), label);
    }

    @State.computed
    public get localizedLabel() {
        return this.multiLingualDisplayName.getLocalizedLabel(convertLanguageToCultureCode(this.currentLabelLanguage));
    }

    @State.action.bound
    public setCurrentLabelLanguage(currentLabelLanguage: Language) {
        this.currentLabelLanguage = currentLabelLanguage;
    }

    @State.action.bound
    public setFormEditBehavior(newValue: FormEditBehavior) {
        this.formEditBehavior = newValue;
    }

    @State.action.bound
    public setWithoutDataHandling(newValue: boolean) {
        this.withoutDataHandling = newValue;
    }

    @State.action.bound
    public setIsUserForm(newValue: boolean) {
        this.isUserForm = newValue;
    }

    @State.action.bound
    public setInstanceLimit(newValue: number) {
        this.instanceLimit = newValue;
    }

    @State.action.bound
    public setFormLogicType(formLogicType: FormLogicType) {
        this.formLogicType = formLogicType;
    }

    @State.action.bound
    public setBaseEntityType(baseEntityType: string) {
        this.baseEntityType = baseEntityType;
    }

    @State.action.bound
    public addItemToForm(dragData: IDragData) {
        this.moveIntoBlockCollection(dragData, this.rootBlocks, this.rootBlocks.length, []);
    }

    @State.action
    public addValidationRule(rule: IFormValidationRule, parentDataReferences: string[] | null) {
        let parentPath = null;
        if (!isNullOrUndefined(parentDataReferences)) {
            parentPath = parentDataReferences.join(".");
        }
        rule.entityPath = isNullOrEmptyString(parentPath) ? rule.entityPath : parentPath + "." + rule.entityPath;
        this.validationRules.push(rule);
    }

    @State.action
    public removeValidationRule(rule: IFormValidationRule) {
        this.validationRules.remove(rule);
    }

    @State.action.bound
    public setEditorDataElementName(name: string) {
        const oldSourceFieldPath = composeFullyQualifiedName(this.selectedEditor);
        this.handleEditorChangesForDelete(this.selectedEditor.dataReference);
        this.selectedDataElement.setName(name);
        this.selectedEditor.setDataReference(name);
        this.handleEditorChangesForAdd(name);
        this.handleFormFieldDependencyUpdate(oldSourceFieldPath);
    }

    @State.action.bound
    public setEditorType(editorType: string) {
        this.selectedEditor.setEditorType(editorType);
        this.handleEditorChangesForModify(this.selectedEditor.dataReference);

        const dataElement = this.formEditorRegistry.tryCreateDataElement(editorType, this.selectedEditor.dataReference);

        const parentElementCollection = findParentDataElementCollection(this.dataElements, this.selectedEditor.parentDataReferences);
        const elementIndex = parentElementCollection.findIndex(i => i.name === this.selectedEditor.dataReference);

        this.dataElements[elementIndex] = dataElement;
    }

    public readonly showFormLogicTextDialogAsync = this.backgroundAsync(async () => {
        const result = await this.props._modalService.showDialogAsync<IFormLogicEditorDialogResult>(new FormLogicEditorDialogParams(this.formLogics));
        if (result?.formLogics) {
            this.formLogics = result.formLogics;
        }

    });

    public readonly showFormLogicTokenDialogAsync = this.backgroundAsync(async () => {
        const result = await this.props._modalService.showDialogAsync<IFormLogicTokenEditorDialogResult>(new FormLogicTokenEditorDialogParams(this.formLogicTokens));
        if (result?.formLogicTokens) {
            this.formLogicTokens = result.formLogicTokens;
        }
    });

    public readonly editFormEnumReferenceAsync = this.backgroundAsync(async () => {

        if (!(this.selectedDataElement instanceof EnumFormDataElement)) {
            throw new Error("selectedDataElement is not an EnumFormDataElement");
        }

        const result = await this.props._modalService.showDialogAsync<IEnumReferenceEditorDialogResult>(new EnumReferenceEditorDialogParams(
            this.selectedDataElement,
            this.selectedEditor,
            getAllEditors(this.rootBlocks).map(editor => {
                const parentElementCollection = findParentDataElementCollection(this.dataElements, this.selectedEditor.parentDataReferences);
                const dataElement = parentElementCollection.find(de => de.name === editor.dataReference);

                if (!(dataElement instanceof EnumFormDataElement) || !dataElement.enumReference) {
                    return null;
                }

                if (dataElement.enumReference.isExternal) {
                    return null;
                }

                return {
                    displayName: editor.multiLingualLabel,
                    enum: this.enums.find(e => e.name === (dataElement as EnumFormDataElement).enumReference.enumName)
                } as INamedFormEnum;

            }).filter(Boolean),
            this.id
        ));

        if (result) {
            State.runInAction(() => {
                const updatedDataElement = new EnumFormDataElement(
                    this.selectedDataElement.name,
                    this.selectedDataElement.isArray,
                    this.selectedEditor.defaultValue as number,
                    this.selectedDataElement.isReadOnly,
                    this.selectedDataElement.isVisible,
                    result.enumReference,
                    result.activeValues
                );

                const parentDataElementCollection = findParentDataElementCollection(this.dataElements, this.selectedEditor.parentDataReferences);
                parentDataElementCollection.remove(this.selectedDataElement);
                parentDataElementCollection.push(updatedDataElement);

                if (result.isMyEnum || result.wasMyEnum) {
                    const existingEnum = this.enums.find(e => e.name === result.formEnum.name);
                    if (existingEnum) {
                        this.enums.remove(existingEnum);
                    }
                }

                if (result.isMyEnum) {
                    this.enums.push(result.formEnum);
                }
            });
        }

    });

    @State.action.bound
    public setEntityReference(entityName: string) {

        if (!(this.selectedDataElement instanceof ReferencedEntityFormDataElement)) {
            throw new Error("selectedDataElement is not a ReferencedEntityFormDataElement");
        }

        this.selectedDataElement.setReferencedEntity(entityName);
    }

    @State.action.bound
    public setExtensibleEnum(entityName: string) {

        if (!(this.selectedDataElement instanceof ReferencedExtensibleEnumFormDataElement)) {
            throw new Error("selectedDataElement is not a ReferencedExtensibleEnumFormDataElement");
        }

        this.selectedDataElement.setExtensibleEnum(entityName);
    }

    @State.action.bound
    public setEnum(entityName: string) {

        if (!(this.selectedDataElement instanceof ReferencedEnumFormDataElement)) {
            throw new Error("selectedDataElement is not a ReferencedEnumFormDataElement");
        }

        this.selectedDataElement.setEnum(entityName);
    }

    @State.action.bound
    public async openFiltersModalAsync() {
        if (!(this.selectedDataElement instanceof ReferencedEntityFormDataElement)) {
            throw new Error("selectedDataElement is not a ReferencedEntityFormDataElement");
        }
        const dialogResult = await this.modalService.showDialogAsync<IFilterDialogResult>(new FilterDialogParams(this.selectedDataElement.filters));
        if (dialogResult) {
            this.selectedDataElement.setFilters(dialogResult.resultFilters);
        }
    }

    @State.action.bound
    public async openFormFieldDependencyModalAsync() {
        if (!this.selectedEditor) {
            return;
        }

        const sourceFieldPath = composeFullyQualifiedName(this.selectedEditor);
        const storedFormLogic = this.formLogics.find(formLogic => formLogic.logicName === `FormFieldDependency_${sourceFieldPath}`);
        let targetFieldData: FormFieldData = null;
        let targetFieldValues: string[];

        if (storedFormLogic) {
            const content = Base64Converter.toString(storedFormLogic.content);
            const contentLines = content.split("\n");
            const firstLine = contentLines[1].trimStart().slice(2);
            const secondLine = contentLines[2].trimStart().slice(2);
            targetFieldData = JSON.parse(firstLine) as FormFieldData;
            targetFieldValues = secondLine.split("---");
        }

        const params = new FormFieldDependencyDialogParams(this.selectedEditor, this.rootBlocks, this.dataElements, targetFieldData, targetFieldValues, this.getDefinition().id);
        const dialogResult = await this.modalService.showDialogAsync<IFormFieldDependencyDialogResult>(params);

        if (dialogResult) {
            if (storedFormLogic) {
                this.formLogics = this.formLogics.filter(formLogic => formLogic.logicName !== `FormFieldDependency_${sourceFieldPath}`);
            }
            this.formLogics.push(dialogResult.formLogic);
        }
    }

    @State.action.bound
    public setLongText(isLong: boolean) {

        if (!(this.selectedDataElement instanceof StringFormDataElement)) {
            throw new Error("selectedDataElement is not a StringFormDataElement");
        }

        this.selectedDataElement.setIsLong(isLong);

        this.handleEditorChangesForModifyProperty(this.selectedEditor.dataReference, FormPropertyChange.IsLong);
    }

    @State.action.bound
    public setIntegerNumber(isInteger: boolean) {

        if (!(this.selectedDataElement instanceof NumberFormDataElement)) {
            throw new Error("selectedDataElement is not a NumberFormDataElement");
        }

        this.selectedDataElement.setIsInteger(isInteger);
    }

    @State.action.bound
    public setSelectedGroupDataElementName(value: string) {
        const editors = getAllEditorsAndGroups(this.selectedGroup.contentBlocks);
        editors.forEach(i => {
            if (i instanceof EditableDataElementEditorStore || i instanceof EditableLayoutGroupBlockStore) {
                const index = i.parentDataReferences.findIndex(j => j === this.selectedGroup.dataReference);
                i.parentDataReferences[index] = value;
            }
        });

        this.selectedDataElementByGroup.setName(value);
        this.selectedGroup.setDataReference(value);
    }

    @State.action.bound
    public setSelectedGroupHasDataReference(value: boolean) {
        const parentDataReferences = isNullOrUndefined(this.selectedGroup.parentDataReferences) ? [] : this.selectedGroup.parentDataReferences;

        if (value) {
            this.createNewCompositeDataElement(this.selectedGroup);
            const editors = getAllEditorsAndGroups(this.selectedGroup.contentBlocks);
            const newDataElement = findParentCompositeDataElement(this.dataElements, [...parentDataReferences, this.selectedGroup.dataReference]);

            editors.forEach(i => {
                if (i instanceof EditableDataElementEditorStore || (i instanceof EditableLayoutGroupBlockStore && i.hasDataReference)) {
                    const parentDataElementCollection = findParentDataElementCollection(this.dataElements, i.parentDataReferences);
                    const editorDataElement = parentDataElementCollection.find(j => j.name === i.dataReference);

                    parentDataElementCollection.remove(editorDataElement);
                    newDataElement?.formDataElements.push(editorDataElement);
                    i.addParentDataReference(newDataElement.name);
                } else if (i instanceof EditableLayoutGroupBlockStore) {
                    i.addParentDataReference(newDataElement.name);
                }
            });
        } else {
            const compositeDataElement = findParentCompositeDataElement(this.dataElements, [...parentDataReferences, this.selectedGroup.dataReference]);
            const parentDataElementCollection = findParentDataElementCollection(this.dataElements, [...parentDataReferences]);

            compositeDataElement.formDataElements.forEach(i => {
                parentDataElementCollection.push(i);
            });
            parentDataElementCollection.remove(compositeDataElement);

            this.selectedGroup.setDataReference(null);

            const editors = getAllEditorsAndGroups(this.selectedGroup.contentBlocks);
            editors.forEach(i => {
                if (i instanceof EditableDataElementEditorStore || i instanceof EditableLayoutGroupBlockStore) {
                    i.parentDataReferences.pop();
                }
            });
        }

        this.selectedGroup.setHasDataReference(value);
    }

    @State.action.bound
    public setSelectedGroupIsArray(value: boolean) {
        const compositeDataElement = findParentCompositeDataElement(this.dataElements, [...this.selectedGroup.parentDataReferences, this.selectedGroup.dataReference]);
        compositeDataElement.setIsArray(value);

        this.selectedGroup.setIsArray(value);
    }

    private handleEditorChangesForAdd(...dataReferences: string[]) {
        this.editorChanges.push(...dataReferences.map(dataReference => new FormEditorChange(dataReference, FormEditorAction.Add)));
    }

    private handleEditorChangesForDelete(dataReference: string) {
        const foundChanges = this.editorChanges.find(i => i.dataReference === dataReference);

        if (foundChanges) {
            this.editorChanges.splice(this.editorChanges.indexOf(foundChanges), 1);
        }

        if (foundChanges?.action === FormEditorAction.Add) {
            return;
        } else {
            this.editorChanges.push(new FormEditorChange(dataReference, FormEditorAction.Delete));
        }
    }

    private handleEditorChangesForModify(dataReference: string) {
        const foundChanges = this.editorChanges.find(i => i.dataReference === dataReference);
        if (foundChanges?.action === FormEditorAction.Modify) {
            return;
        }

        if (foundChanges?.action !== FormEditorAction.Add) {
            this.editorChanges.push(new FormEditorChange(dataReference, FormEditorAction.Modify));
        }
    }

    private handleEditorChangesForModifyProperty(dataReference: string, propertyChanged: FormPropertyChange) {
        const foundChanges = this.editorChanges.find(i => i.dataReference === dataReference);
        if (foundChanges?.action === FormEditorAction.ModifyProperty && foundChanges?.propertyChanged === propertyChanged) {
            return;
        }

        if (foundChanges?.action !== FormEditorAction.Add && foundChanges?.action !== FormEditorAction.Modify) {
            this.editorChanges.push(new FormEditorChange(dataReference, FormEditorAction.ModifyProperty, propertyChanged));
        }
    }

    private handleFormFieldDependencyDelete(editor: EditableDataElementEditorStore | EditableLayoutGroupBlockStore) {
        const sourceFieldPath = composeFullyQualifiedName(editor);
        this.formLogics = this.formLogics
            .filter(formLogic => {
                if (!formLogic.logicName.startsWith("FormFieldDependency")) {
                    return true;
                }

                if (formLogic.logicName === `FormFieldDependency_${sourceFieldPath}`) {
                    return false;
                }

                const content = Base64Converter.toString(formLogic.content);
                const firstLine = content.split("\n")[1].trimStart().slice(2);
                const targetFieldData = JSON.parse(firstLine) as FormFieldData;
                return targetFieldData.fullyQualifiedDataReferencePath !== sourceFieldPath;
            });
    }

    private handleFormFieldDependencyUpdate(oldSourceFieldPath: string) {
        const newSourceFieldPath = composeFullyQualifiedName(this.selectedEditor);
        const allFormLogic = this.formLogics.filter(formLogic => formLogic.logicName.startsWith("FormFieldDependency"));
        const formLogicWithOldSource = allFormLogic.find(formLogic => formLogic.logicName === `FormFieldDependency_${oldSourceFieldPath}`);

        if (formLogicWithOldSource) {
            this.updateSourceDependency(formLogicWithOldSource, oldSourceFieldPath, newSourceFieldPath);
        }

        this.updateTargetDependency(allFormLogic, oldSourceFieldPath, newSourceFieldPath);
    }

    private updateSourceDependency(formLogic: FormLogic, oldSourcePath: string, newSourcePath: string) {
        formLogic.logicName = `FormFieldDependency_${newSourcePath}`;
        let content = Base64Converter.toString(formLogic.content);
        content = this.replaceAll(content, `"${oldSourcePath}"`, `"${newSourcePath}"`);
        formLogic.content = Base64Converter.fromString(content);
    }

    private updateTargetDependency(allFormLogic: FormLogic[], oldTargetPath: string, newTargetPath: string) {
        allFormLogic.forEach(formLogic => {
            let content = Base64Converter.toString(formLogic.content);
            const firstLine = content.split("\n")[1].trimStart().slice(2);
            const targetFieldData = JSON.parse(firstLine) as FormFieldData;

            if (targetFieldData.fullyQualifiedDataReferencePath === oldTargetPath) {
                content = this.replaceAll(content, `"${oldTargetPath}"`, `"${newTargetPath}"`);
                formLogic.content = Base64Converter.fromString(content);
            }
        });
    }

    private replaceAll(str: string, find: string, replace: string): string {
        return str.replace(new RegExp(find, "g"), replace);
    }
}
