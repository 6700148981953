import React from "react";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import FamilyDoctorDocumentId from "@Primitives/FamilyDoctorDocumentId.g";
import PatientId from "@Primitives/PatientId.g";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import InputDataClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/InputDataClientSideAction";
import FamilyDoctorDocumentApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/FamilyDoctorDocument/FamilyDoctorDocumentApiAdapter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import ILockingNotificationService from "@HisPlatform/BoundedContexts/Locking/Services/Definition/ILockingNotificationService";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import InputFormType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/InputFormType.g";

interface IFamilyDoctorDocumentListPanelDependencies {
    apiAdapter: WorklistApiAdapter;
    dialogService: IDialogService;
    notificationService: INotificationService;
    familyDoctorDocumentApiAdapter: FamilyDoctorDocumentApiAdapter;
    lockingNotificationService: ILockingNotificationService;
}

export interface IFamilyDoctorDocumentListPanelProps {
    _dependencies?: IFamilyDoctorDocumentListPanelDependencies;
    _patientId?: PatientId;
    _isCareBaseDataUnauthorized?: boolean;
    useCaseState: INDataUseCaseState;
    selectedRowId: string;
    onBack: () => void;
    onSelectedFamilyDoctorDocumentIdChange: (familyDoctorDocumentId: FamilyDoctorDocumentId) => void;
    onChange: (selectedRowId: string, useCaseState: INDataUseCaseState) => void;
    onCloseDetail: () => void;
}
@State.observer
class FamilyDoctorDocumentListPanel extends React.Component<IFamilyDoctorDocumentListPanelProps> {
    @State.observable.ref private definition: IWorklistDefinition = null;
    private readonly refreshListEvent = new TypedAsyncEvent();
    private get patientId() { return this.props._patientId; }
    private get dialogService() { return this.props._dependencies.dialogService; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get lockingNotificationService() { return this.props._dependencies.lockingNotificationService; }
    private get familyDoctorDocumentApiAdapter() { return this.props._dependencies.familyDoctorDocumentApiAdapter; }

    @State.computed private get hasNewRow() { return this.props.selectedRowId === "new"; }

    private get apiAdapter() { return this.props._dependencies.apiAdapter; }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadPanelAsync, this.props._isCareBaseDataUnauthorized);

    public componentDidMount() {
        dispatchAsyncErrors(this.loadPanelAsync(), this);
    }

    @State.action.bound
    private async loadPanelAsync() {
        await this.loadDefinitionAsync();
    }

    @State.action.bound
    private async loadDefinitionAsync() {
        const response: SimpleStore<IWorklistDefinition> = await this.apiAdapter.getFamilyDoctorDocumentBoundWorklistDefinition(this.patientId);
        State.runInAction(() => {
            this.definition = response.value;
        });
    }
    @State.action.bound
    private setPanelProps(useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) {
        return {
            onFamilyDoctorDocumentCreatedAsync: this.familyDoctorDocumentCreatedAsync,
            onCloseDetail: this.props.onCloseDetail
        };
    }

    @State.action.bound
    private async familyDoctorDocumentCreatedAsync(id: FamilyDoctorDocumentId) {
        await this.refreshListAsync();
        this.props.onSelectedFamilyDoctorDocumentIdChange?.(id);
    }

    @State.action.bound
    private async refreshListAsync() {
        await this.refreshListEvent.emitAsync();
    }
    @State.action.bound
    private async performClientSideActionAsync(action: ClientSideActionDto): Promise<WorkListArgument | boolean> {
        if (action instanceof InputDataClientSideAction) {
            if (action.inputFormType === InputFormType.DeleteFamilyDoctorDocument) {
                const id = new FamilyDoctorDocumentId(action.useCaseArguments[0].value.value);

                const store = await this.familyDoctorDocumentApiAdapter.getFamilyDoctorDocumentByIdAsync(id, true);
                const message = StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Dialog.DeleteConfirmationMessage;
                if (store.isMutable) {
                    const dialogResult = await this.dialogService.yesNo(StaticCareResources.Common.Dialog.ConfirmationTitle, message);
                    if (dialogResult.resultCode === DialogResultCode.No) {
                        return true;
                    }

                    const response = await this.familyDoctorDocumentApiAdapter.removeFamilyDoctorDocument(store);

                    if (response.operationWasSuccessful && response.isPersistedByOperationInfo) {
                        this.notificationService.showSavedSuccessfully();
                    } else {
                        this.notificationService.showCannotSaveBecauseOfErrors();
                    }
                    await this.refreshListAsync();
                    this.props.onCloseDetail?.();
                } else {
                    this.lockingNotificationService.displayWarningNotificationAsync(store.lockInfo);
                }
            }
        }
        return null;
    }

    public render() {
        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Detail.Title} />;
        }

        return (
            <NDataPanel
                definition={this.definition}
                hasPrintButton={false}
                onChange={this.props.onChange}
                useCaseState={this.props.useCaseState}
                selectedRowId={this.props.selectedRowId}
                onPerformClientSideActionAsync={this.performClientSideActionAsync}
                refreshListEvent={this.refreshListEvent}
                onGetPanelProps={this.setPanelProps}
                hasNewRow={this.hasNewRow}
                disableDetailStrictMode
                masterTitle={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Title}
            />);
    }
}

export default connect(
    FamilyDoctorDocumentListPanel,
    new DependencyAdapter<IFamilyDoctorDocumentListPanelProps, IFamilyDoctorDocumentListPanelDependencies>(c => {
        return {
            apiAdapter: c.resolve("WorklistApiAdapter"),
            dialogService: c.resolve<IDialogService>("IDialogService"),
            notificationService: c.resolve<INotificationService>("INotificationService"),
            familyDoctorDocumentApiAdapter: c.resolve<FamilyDoctorDocumentApiAdapter>("FamilyDoctorDocumentApiAdapter"),
            lockingNotificationService: c.resolve<ILockingNotificationService>("LockingApiAdapter")
        };
    }),
    new PatientContextAdapter<IFamilyDoctorDocumentListPanelProps>(c => ({
        _patientId: c.patientId,
    })),
    new CareActivityContextAdapter<IFamilyDoctorDocumentListPanelProps>(c => ({
        _isCareBaseDataUnauthorized: !c.isAuthorizedForCareActivityBaseData
    }))
);