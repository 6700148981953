import React, { useMemo, useCallback } from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import { isNullOrUndefinedOrGivenString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import WorklistTypeBoundNDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/WorklistTypeBoundNDataPanel";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { getUseCaseFromNDataUseCaseStateAsUrlParam, parseUseCaseStateFromUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import SurgeryId from "@Primitives/SurgeryId.g";
import UseCases from "@Primitives/UseCases";
import ISurgeryForPatientRouteParams from "@HisPlatform/Application/Routes/ISurgeryForPatientRouteParams";
import PatientId from "@Primitives/PatientId.g";
import FilterConditionConverters from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/FilterConditionConverters";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import config from "@Config";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";

function SurgeryForPatientPage(props: IRoutingFrameContentProps<ISurgeryForPatientRouteParams>) {

    const [selectedId, patientId, useCaseState] = useMemo(() => {
        const routeParameters = props.routingController.currentRoute.parameters;
        return [isNullOrUndefinedOrGivenString(routeParameters.selectedId, "null") ? null : new SurgeryId(routeParameters.selectedId),
                isNullOrUndefinedOrGivenString(routeParameters.patientId, "null") ? null : new PatientId(routeParameters.patientId),
            parseUseCaseStateFromUrlParam(routeParameters.useCase)];
    }, [props.routingController.currentRoute.parameters.selectedId, props.routingController.currentRoute.parameters.useCase]);

    const setState = useCallback((id: string, newUseCaseState: INDataUseCaseState) => {
        let useCaseId = id;
        if (newUseCaseState?.useCase?.value === UseCases.surgeryDetail) {
            useCaseId = newUseCaseState.useCaseArguments.find(it => it.name === "surgeryId")?.value?.value ?? "new";
        }
        props.routingController.replace(props.routingController.currentRoute.definition.makeRoute({
            selectedId: useCaseId ?? "null",
            patientId: patientId?.value ?? "null",
            appointmentId: "null",
            mode: "read-write",
            useCase: getUseCaseFromNDataUseCaseStateAsUrlParam(newUseCaseState)
        }));
    }, [props.routingController]);

    const dynamicFilters = useMemo(() => {
        const filters = {};
        const conditionConverter = FilterConditionConverters[WorklistConditionType.EntityId];
        filters["AggregateAttribute_Surgery.PatientId"] = conditionConverter(patientId);
        return filters;
    }, [patientId]);

    if (!config.testFeatures.surgeryEnabled) {
        return <UnauthorizedAccessContent />;
    }

    return (
        <SingleLayout>
            <WorklistTypeBoundNDataPanel
                worklistDefinitionReference="SurgeryPatient"
                onChange={setState}
                useCaseState={useCaseState}
                cacheKey={`SurgeryForPatientPage_id${patientId.value}`}
                dynamicFilters={dynamicFilters}
                selectedRowId={selectedId?.value}
                disableDetailStrictMode
                hasPrintButton={false}
            />
        </SingleLayout>
    );
}
export default withLoadingBoundary(SurgeryForPatientPage);