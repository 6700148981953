import AppointmentScheduleSlotSeriesId from "@Primitives/AppointmentScheduleSlotSeriesId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import SlotStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SlotStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import moment from "moment";

export default class ScheduledAppointmentStore {

    public scheduleItemId: number = 0;
    public from: moment.Moment = null;
    public schedulingServiceId: SchedulingServiceId = null;
    public organizationUnitId: OrganizationUnitId = null;
    public practitionerId: PractitionerId = null;
    public appointmentScheduleSlotSeriesId: AppointmentScheduleSlotSeriesId = null;
    public slotsToBook: SlotStore[] = [];
    public isBooked: boolean = false;

    @State.observable public isSelectedForCreation: boolean = false;
}