import React, { useContext } from "react";
import IBusinessError from "@Toolkit/CommonWeb/Model/IBusinessError";
import { BusinessErrorHandlerContext, IBusinessErrorHandler } from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";

export interface IHandlerRegistratorProps {
    businessErrorName: string;
    handler?: (err: IBusinessError) => boolean;
}


class HandlerRegistrator extends React.PureComponent<IHandlerRegistratorProps & { context: IBusinessErrorHandler }> {

    public static defaultProps: Partial<IHandlerRegistratorProps> = {
        handler: err => true
    };

    public componentDidMount() {
        this.props.context.registerHandler(this.props.businessErrorName, this.props.handler);
    }

    public componentWillUnmount() {
        this.props.context.removeHandler(this.props.businessErrorName, this.props.handler);
    }

    public componentDidUpdate(prevProps: IHandlerRegistratorProps) {
        if (prevProps.handler !== this.props.handler) {
            this.props.context.removeHandler(this.props.businessErrorName, prevProps.handler);
            this.props.context.registerHandler(this.props.businessErrorName, this.props.handler);
        }
    }

    public render() {
        return null as React.ReactNode;
    }
}


const ConnectedHandlerRegistrator: React.FC<IHandlerRegistratorProps> = props => {
    const context = useContext(BusinessErrorHandlerContext);
    return <HandlerRegistrator {...props} context={context} />;
};

export default ConnectedHandlerRegistrator;