import AccessRuleBase from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/AccessRuleBase";
import RoleAccessRule from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/RoleAccessRule";
import RolePermissionConfigurationPanel from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/RoleManagement/PermissionConfigurationPanel/RolePermissionConfigurationPanel/RolePermissionConfigurationPanel";
import UserGroupId from "@Primitives/UserGroupId.g";
import UserId from "@Primitives/UserId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import * as Ui from "@CommonControls";
import UniquePermissionConfigurationPanel from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/RoleManagement/PermissionConfigurationPanel/UniquePermissionConfigurationPanel/UniquePermissionConfigurationPanel";
import PermissionAssignmentAccessRule from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/PermissionAssignmentAccessRule";
import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";

interface IPermissionConfigurationPanelProps {
    accessRules: AccessRuleBase[];
    onAccessRulesChange: (newValue: AccessRuleBase[]) => void;
    subjectId: UserId | UserGroupId;
}

/** @screen */
@State.observer
export default class PermissionConfigurationPanel extends React.Component<IPermissionConfigurationPanelProps> {

    @State.computed
    private get roleAccessRules() {
        return this.props.accessRules.map(a => {
            if (a instanceof RoleAccessRule) {
                return a;
            }
            return undefined;
        }).filter(a => !!a);
    }

    @State.computed
    private get assignmentAccessRules() {
        return this.props.accessRules.map(a => {
            if (a instanceof PermissionAssignmentAccessRule) {
                return a;
            }
            return undefined;
        }).filter(a => !!a);
    }

    @State.computed
    private get prohibitionAccessRule() {
        return this.assignmentAccessRules.find(a => a.isProhibition);
    }

    @State.computed
    private get assignmentAccessRule() {
        return this.assignmentAccessRules.find(a => !a.isProhibition);
    }

    @State.action.bound
    private setAssignmentAccessRule(newValue: PermissionAssignmentAccessRule) {
        const newAccessRules: AccessRuleBase[] = [...this.roleAccessRules, newValue];
        if (this.prohibitionAccessRule) {
            newAccessRules.push(this.prohibitionAccessRule);
        }
        this.props.onAccessRulesChange(newAccessRules);
    }

    @State.action.bound
    private setProhibitionAccessRule(newValue: PermissionAssignmentAccessRule) {
        const newAccessRules: AccessRuleBase[] = [...this.roleAccessRules, newValue];
        if (this.assignmentAccessRule) {
            newAccessRules.push(this.assignmentAccessRule);
        }
        this.props.onAccessRulesChange(newAccessRules);
    }

    @State.bound
    private setRoleAccessRules(newValue: RoleAccessRule[]) {
        const newAccessRules: AccessRuleBase[] = [...newValue];
        if (this.prohibitionAccessRule) {
            newAccessRules.push(this.prohibitionAccessRule);
        }

        if (this.assignmentAccessRule) {
            newAccessRules.push(this.assignmentAccessRule);
        }

        this.props.onAccessRulesChange(newAccessRules);
    }
    public render() {
        return (
            <>
                <Ui.GroupBox title={StaticAuthorizationResources.PermissionConfiguration.RolePermissionConfigurationTitle}>
                    <RolePermissionConfigurationPanel
                        subjectId={this.props.subjectId}
                        accessRules={this.roleAccessRules}
                        onAccessRulesChange={this.setRoleAccessRules}
                    />
                </Ui.GroupBox>
                <Ui.GroupBox title={StaticAuthorizationResources.PermissionConfiguration.UniquePermissionConfigurationTitle}>
                    <UniquePermissionConfigurationPanel
                        subjectId={this.props.subjectId}
                        assignmentAccessRule={this.assignmentAccessRule}
                        prohibitionAccessRule={this.prohibitionAccessRule}
                        onAssignmentRuleChange={this.setAssignmentAccessRule}
                        onProhibitionRuleChange={this.setProhibitionAccessRule}
                    />
                </Ui.GroupBox>
            </>
        );
    }
}