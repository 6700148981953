/* auto-inject-disable */
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/EesztIntegration/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { GetTypeNameWithPrefix } from "@HunEHealthInfrastructurePlugin/PluginHelper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import Base64Converter from "@Toolkit/CommonWeb/Base64";

@Di.injectable()
export default class EesztIntegrationApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject(GetTypeNameWithPrefix("ICommunicationLogsClient")) private readonly communicationLogsClient: Proxy.ICommunicationLogsClient
    ) {
        super();
    }

    @State.bound
    public getCommunicationLogsFileAsync(startDate: LocalDate, endDate: LocalDate, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<{ content: string, mediaType: string, fileName: string }>(),
            async target => {
                const response = await this.communicationLogsClient.getCommunicationLogsQueryAsync(
                    CreateRequestId(),
                    endDate.stringify(),
                    startDate.stringify(),
                    isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);
                if (!isPermissionCheckOnly) {
                    const decodedContent = Base64Converter.toString(response.file.content);
                    target.value = { content: decodedContent, mediaType: response.file.mediaType, fileName: response.file.fileName };
                }
            }
        );
    }
}