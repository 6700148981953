import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ReleaseInfoStore, { ITfsChange } from "@HisPlatform/Application/Store/ReleaseInfoStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import moment from "moment";

/** @screen */
@State.observer
export default class TfsInfoPanel extends React.Component {

    @State.observable.ref private items: ITfsChange[] = null;
    private dataSource = new InMemoryDataGridDataSource(() => this.items);

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private async loadAsync() {
        const releaseInfo = await ReleaseInfoStore.getReleaseInfoAsync();

        this.setItems(releaseInfo.TfsChanges ?
            (Array.isArray(releaseInfo.TfsChanges) ?
                releaseInfo.TfsChanges : [releaseInfo.TfsChanges]) : []
        );
    }

    @State.action
    private setItems(items: ITfsChange[]) {
        this.items = items;
    }

    public render() {
        return (
            <Ui.DataGrid
                dataSource={this.dataSource}
                changeOnMount
                generateInitialFilterStore
                isSelectable
                isLoading={this.items === null}
                automationId="tsInfoPanel_dataGrid"
            >
                <DataGridColumn
                    header="Timestamp"
                    isFilterable
                    isOrderable
                    dataGetter="timestamp"
                    onRenderCellValue={this.renderRawDateTime} />
                <DataGridColumn
                    header="Message"
                    isFilterable
                    isOrderable
                    dataGetter="message" />
                <DataGridColumn
                    header="Name"
                    isFilterable
                    isOrderable
                    dataGetter="author.displayName" />
                <DataGridColumn
                    header="Id"
                    isFilterable
                    isOrderable
                    dataGetter="id"
                    onRenderCellValue={this.renderTfsChangeId} />
            </Ui.DataGrid>
        );
    }

    private renderTfsChangeId(value: string) {
        return value ? value.slice(0, 8) : "";
    }

    private renderRawDateTime(value: string) {
        return value ? moment(value).format("YYYY.MM.DD. HH:mm:ss") : "";
    }
}