import React from "react";
import { IDocumentEditorBaseProps } from "@CommonControls/DocumentEditor/IDocumentEditorProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import TemporaryDataApiAdapter from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/TemporaryData/TemporaryDataApiAdapter";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import config from "@Config";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import EventHandler from "@Toolkit/ReactClient/Components/EventHandler/EventHandler";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Encoding from "@Toolkit/CommonWeb/Encoding";
import { DocumentEditor } from "@CommonControls";
import HisErrorBoundary from "@HisPlatform/Components/HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import Log from "@HisPlatform/Services/Definition/Logger/Log";

interface IHisDocumentEditorDependencies {
    temporaryDataApiAdapter: TemporaryDataApiAdapter;
    dialogService: IDialogService;
    localizationService: ILocalizationService;
}

interface IHisDocumentEditorProps extends IDocumentEditorBaseProps {
    _dependencies?: IHisDocumentEditorDependencies;
    successfullySavedEvent?: TypedAsyncEvent;
    leaveWithoutSavingEvent?: TypedAsyncEvent;
    temporaryDataId?: string;
}

class HisDocumentEditor extends React.Component<IHisDocumentEditorProps> {

    private get temporaryDataApiAdapter() { return this.props._dependencies!.temporaryDataApiAdapter; }
    private get dialogService() { return this.props._dependencies!.dialogService; }
    private get localizationService() { return this.props._dependencies!.localizationService; }

    private autoSaveIntervalHandler: number = null;
    private lastAutoSavedContent: string = null;
    private storedContent: string = null;

    private retryBackoffCounter = 0;

    public componentDidMount() {
        this.storedContent = this.props.contentStore.getContent();
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IHisDocumentEditorProps) {
        if (prevProps.temporaryDataId !== this.props.temporaryDataId) {
            dispatchAsyncErrors(this.temporaryDataIdChangedAsync(), this);
        }
    }

    private async temporaryDataIdChangedAsync() {
        this.clearAutoSaveInterval();
        await this.loadAsync();
    }
    @State.computed private get isReadOnly() {
        return this.props.isReadOnly;
    }
    private async loadAsync() {
        try {
            if (!this.isReadOnly && !!this.props.temporaryDataId) {
                const result = await this.temporaryDataApiAdapter.getAsync(this.props.temporaryDataId);

                if (!!result.value.content) {
                    const contentBytes = Base64Converter.toByteArray(result.value.content);
                    this.lastAutoSavedContent = Encoding.UTF8.getString(contentBytes);

                    const content = this.props.contentStore.getContent();
                    if (this.lastAutoSavedContent !== content) {

                        const answer = await this.dialogService.yesNo(StaticWebAppResources.DocumentEditor2.ResumeDraftDialogTitle,
                            formatStringWithObjectParams(StaticWebAppResources.DocumentEditor2.ResumeDraftDialogMessage, {
                                CreatedAt: this.localizationService.localizeDateTime(result.value.createdAt, true, false, true)
                            }));

                        if (answer.resultCode === DialogResultCode.Yes) {
                            this.props.contentStore.setContent(this.lastAutoSavedContent);
                        } else if (answer.resultCode === DialogResultCode.No) {
                            await this.temporaryDataApiAdapter.deleteAsync(this.props.temporaryDataId);
                        }
                    }
                }

                this.autoSaveIntervalHandler = window.setInterval(() => {
                    dispatchAsyncErrors(this.autoSaveAsync(), this, false);
                }, config.documentEditor.autoSavePeriodInMs);
            }
        } catch (e) {
            Log.error(e, `Failed to load temporary document content. temporaryDataId=${this.props.temporaryDataId}`);
        }
    }

    public componentWillUnmount() {
        this.clearAutoSaveInterval();
    }

    private clearAutoSaveInterval() {
        if (!!this.autoSaveIntervalHandler) {
            try {
                window.clearInterval(this.autoSaveIntervalHandler);
            } catch {
                // dontcare
            }
        }
    }

    private async autoSaveAsync() {

        if (--this.retryBackoffCounter > 0) {
            return;
        }
        this.retryBackoffCounter = 0;

        try {
            const currentContent = this.props.contentStore.getContent();

            if (this.storedContent !== currentContent && currentContent !== this.lastAutoSavedContent) {
                const contentBytes = Encoding.UTF8.getBytes(currentContent);
                const contentBase64 = Base64Converter.fromByteArray(contentBytes);

                await this.temporaryDataApiAdapter.addAsync(this.props.temporaryDataId, contentBase64);
                this.lastAutoSavedContent = currentContent;
            }
        } catch (e) {
            Log.error(e, `Failed to save temporary document content. temporaryDataId=${this.props.temporaryDataId}`);
            this.retryBackoffCounter = 3;
        }

    }

    @State.bound
    private async clearDraftAsync() {

        if (--this.retryBackoffCounter > 0) {
            return;
        }
        this.retryBackoffCounter = 0;

        try {
            await this.temporaryDataApiAdapter.deleteAsync(this.props.temporaryDataId);
        } catch (e) {
            Log.error(e, `Failed to clear temporary document content. temporaryDataId=${this.props.temporaryDataId}`);
            this.retryBackoffCounter = 3;
        }
    }

    public render() {
        const props = this.props as IDocumentEditorBaseProps;

        return (
            <HisErrorBoundary>
                <DocumentEditor {...props} isReadOnly={this.isReadOnly} />
                <EventHandler event={this.props.successfullySavedEvent} onFiredAsync={this.clearDraftAsync} />
                <EventHandler event={this.props.leaveWithoutSavingEvent} onFiredAsync={this.clearDraftAsync} />
            </HisErrorBoundary>
        );
    }
}

export default connect(
    HisDocumentEditor,
    new DependencyAdapter<IHisDocumentEditorProps, IHisDocumentEditorDependencies>(c => ({
        temporaryDataApiAdapter: c.resolve("TemporaryDataApiAdapter"),
        dialogService: c.resolve("IDialogService"),
        localizationService: c.resolve("ILocalizationService"),
    }))
);