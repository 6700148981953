import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import DiagnosisListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisListStore";

export function getUpdateDiagnosisListDto(diagnosisList: DiagnosisListStore, releaseLockIfSuccessful: boolean, validateOnly: boolean = false, updateWithoutValidation: boolean = false) {
    return new Proxy.UpdateDiagnosisListControllerDto({
        careActivityId: diagnosisList.id,
        lockId: diagnosisList.lockInfo && diagnosisList.lockInfo.lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful,
        isValidateOnly: validateOnly,        
        diagnoses: diagnosisList.diagnoses.map(i => new Proxy.DiagnosisUpdateDto({
            conditionId: i.conditionVersionSelector.id,
            diagnosisRoleId: i.use,
            lateralityId: i.lateralityId,
            diagnosisId: i.id,
            extensionData: i.extensionData
        })),
        updateWithoutValidation: updateWithoutValidation
    });
}
