import React from "react";
import PropertyGroup from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyGroup";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import PropertyBase from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyBase";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import PropertyGroupEditableColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PropertyGroupEditableColumn/PropertyGroupEditableColumn";
import NumericRange from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/NumericRange";
import OrganizationUnit from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnit";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import Style from "./PropertyGroupGrid.less";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import PropertyVersionsModalParams, { IPropertyVersionsModalResult } from "@HisPlatform/BoundedContexts/Organization/Components/Panels/PropertyGroupsPanel/PropertyVesrionsModal/PropertyVersionsModalParams";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import PropertyVersionValidity from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PropertyGroupEditableColumn/PropertyVersionValidity";
import DynamicPropertyInputType from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/DynamicPropertyInputType";

interface IPropertyGroupsGridDependencies {
    localizationService: ILocalizationService;
}

interface IPropertyGroupsGridProps {
    propertyGroup: PropertyGroup;
    filterValue: string;
    originatingOrganizationUnits: SimpleStore<OrganizationUnit[]>;
    currentOrganizationUnitId: OrganizationUnitId;
    validateVersionsAsync: (property: PropertyBase, propertyGroup: PropertyGroup) => Promise<IClientValidationResult[]>;
    _dependencies?: IPropertyGroupsGridDependencies;
    _modalService?: IModalService;
}

@State.observer
class PropertyGroupGrid extends React.Component<IPropertyGroupsGridProps> {

    private get originatingOrganizationUnits() {
        return this.props.originatingOrganizationUnits.value;
    }

    private get currentOrganizationUnitId() {
        return this.props.currentOrganizationUnitId;
    }

    private get filterValue() {
        return this.props.filterValue;
    }

    private get localizationService() {
        return this.props._dependencies.localizationService;
    }

    private get modalService() {
        return this.props._modalService;
    }

    @State.computed
    private get visibleProperties() {
        return this.props.propertyGroup.properties.filter(x => x.isVisible);
    }

    private renderEffectiveValue(value: any, row: PropertyBase) {
        let result = row.inputType === DynamicPropertyInputType.File
            ? StaticOrganizationResources.PropertyGroupsPanel.FileInputTypeValue
            : value;
        if (value instanceof NumericRange) {
            result = `${value.from} - ${value.to}`;
        }
        if (typeof value === "boolean") {
            result = value ?
                StaticOrganizationResources.PropertyGroupsPanel.BoolPropertyValue.True :
                StaticOrganizationResources.PropertyGroupsPanel.BoolPropertyValue.False;
        }
        return (
            <>
                {result} <br />
                <PropertyVersionValidity property={row} useEffectiveValue />
            </>
        );
    }

    private renderAsString(value: any, row: PropertyBase) {
        if (row.inputType === DynamicPropertyInputType.File) {
            return StaticOrganizationResources.PropertyGroupsPanel.FileInputTypeValue;
        }

        if (value instanceof NumericRange) {
            return `${value.from} - ${value.to}`;
        }
        if (typeof value === "boolean") {
            return value ?
                StaticOrganizationResources.PropertyGroupsPanel.BoolPropertyValue.True :
                StaticOrganizationResources.PropertyGroupsPanel.BoolPropertyValue.False;
        }
        return `${value}`;
    }

    @State.bound
    private renderOriginatingOrganizationUnit(originatingOrganizationUnitId: OrganizationUnitId) {

        if (!originatingOrganizationUnitId || (originatingOrganizationUnitId.value === this.currentOrganizationUnitId.value)) {
            return "";
        }

        const originatingOrganizationUnit = this.originatingOrganizationUnits.filter(x => x.id.value === originatingOrganizationUnitId.value)[0];
        return `${originatingOrganizationUnit.code}`;
    }

    @State.bound
    private renderPropertyName(_: any, row: PropertyBase) {
        const rowNameSelector = `${this.props.propertyGroup.name}.${row.name}`;
        let localizedName = this.localizationService.localizeProperty(rowNameSelector);

        if (!localizedName) {
            localizedName = rowNameSelector;
        }

        if (localizedName === rowNameSelector) {
            localizedName = localizedName.slice(localizedName.lastIndexOf(".") + 1);
        }

        const infoSelector = `${this.props.propertyGroup.name}.${row.name}_Info`;
        const localizedInfo = this.localizationService.localizeProperty(infoSelector);
        const showInfo = localizedInfo !== infoSelector && localizedInfo;

        return (
            <div className={Style.container}>
                <div className={Style.labelContainer}>
                    <Ui.HighlightableLabel
                        label={localizedName}
                        filterValue={this.filterValue}
                    />
                </div>
                <div className={Style.iconContainer}>
                    {showInfo && <Ui.InfoButton tooltipContent={localizedInfo} />}
                </div>
            </div>
        );
    }

    @State.bound
    private renderPropertyNameHint(value: any, row: any) {
        const rowNameSelector = `${this.props.propertyGroup.name}.${row.name}`;
        let localizedName = this.localizationService.localizeProperty(rowNameSelector);

        if (!localizedName) {
            localizedName = rowNameSelector;
        }

        if (localizedName === rowNameSelector) {
            localizedName = localizedName.slice(localizedName.lastIndexOf(".") + 1);
        }
        return localizedName;
    }

    private getPropertyGroupName() {
        const name = this.localizationService.localizeProperty(this.props.propertyGroup.name);
        return name ? name : this.props.propertyGroup.name;
    }

    @State.bound
    private async validatePropertyVersionAsync(property: PropertyBase) {
        return await this.props.validateVersionsAsync(property, this.props.propertyGroup);
    }

    @State.bound
    private async editPropertyVersionsAsync(property: PropertyBase) {
        const result = await this.modalService.showDialogAsync<IPropertyVersionsModalResult>(new PropertyVersionsModalParams(property, this.props.propertyGroup, this.validatePropertyVersionAsync));
        if (result?.updatedProperty) {
            this.props.propertyGroup.updateProperty(result.updatedProperty);
        }
    }

    @State.bound
    private renderButton(value: any, row: PropertyBase) {
        const clickHandler = () => this.editPropertyVersionsAsync(row);
        return (
            <Ui.Button
                iconName="history"
                onClick={clickHandler}
                visualStyle={row.versions?.length ? "primary" : "standard"}
                automationId={row.name + "_editButton"}
            />);
    }

    public render() {
        return (
            <>
                <h3>{this.getPropertyGroupName()}</h3>
                <Ui.DataGrid
                    dataSource={this.visibleProperties}
                    footer={null}
                    rowId="name"
                    rowHeight={55}
                    actionsColumn={false}
                    fixedLayout
                    automationId={`${this.props.propertyGroup.name}_dataGrid`}
                >
                    <DataGridColumn
                        dataGetter={"name"}
                        onRenderCellValue={this.renderPropertyName}
                        onRenderHintValue={this.renderPropertyNameHint}
                        header={StaticOrganizationResources.PropertyGroupsPanel.ColumnHeaders.Name}
                        width="40%"
                        showHint={false}
                        automationId="_name"
                    />
                    <DataGridColumn
                        isObserver
                        dataGetter={this.getEffectiveValue}
                        onRenderCellValue={this.renderEffectiveValue}
                        header={StaticOrganizationResources.PropertyGroupsPanel.ColumnHeaders.EffectiveValue}
                        width="25%"
                        hideOverflow
                        onRenderHintValue={this.renderAsString}
                        automationId="_effectiveValue"
                    />
                    <DataGridColumn
                        isObserver
                        dataGetter={"originatingOrganizationUnitId"}
                        onRenderCellValue={this.renderOriginatingOrganizationUnit}
                        header={StaticOrganizationResources.PropertyGroupsPanel.ColumnHeaders.Originator}
                        width="7%"
                        automationId="_originator" />
                    <PropertyGroupEditableColumn
                        dataGetter={this.getValue}
                        header={StaticOrganizationResources.PropertyGroupsPanel.ColumnHeaders.Value}
                        width="20%"
                        showHint={false}
                        automationId="_value" />
                    <DataGridColumn
                        onRenderCellValue={this.renderButton}
                        width="8%"
                        automationId="_icon"
                    />
                </Ui.DataGrid>
            </>
        );
    }

    private getEffectiveValue(row: PropertyBase) {
        return row.isTemporal ?
            row.getEffectiveValue(LocalDate.today()) :
            row.getEffectiveValue();
    }

    private getValue(row: PropertyBase) {
        return row.isTemporal ?
            row.getValue(LocalDate.today()) :
            row.getValue();
    }
}

export default connect(
    PropertyGroupGrid,
    new DependencyAdapter<IPropertyGroupsGridProps, IPropertyGroupsGridDependencies>(container => {
        return {
            localizationService: container.resolve("ILocalizationService")
        };
    }),
    new HisModalServiceAdapter()
);
