// tslint:disable:variable-name
import EpisodeOfCareScreenStore from "./EpisodeOfCareScreenStore";
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import { editorScreenRenderFunc } from "@Toolkit/CommonWeb/PanelStore/EditorScreenRenderFunc";

const {
    ContextComponent: EpisodeOfCareScreenStoreContext, 
    StoreProvider: EpisodeOfCareScreenStoreProvider, 
    useStore: useEpisodeOfCareScreenStore,
    provideStore: provideEpisodeOfCareScreenStore,
} = createPanelStoreProvider<EpisodeOfCareScreenStore>(EpisodeOfCareScreenStore, null, editorScreenRenderFunc);

export {
    EpisodeOfCareScreenStoreContext,
    EpisodeOfCareScreenStoreProvider,
    useEpisodeOfCareScreenStore,
    provideEpisodeOfCareScreenStore
};