import State from "@Toolkit/ReactClient/Common/StateManaging";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import * as Ui from "@CommonControls";
import PatientId from "@Primitives/PatientId.g";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import EhiDocumentsListPanel from "./EhiDocumentsListPanel";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import EhiDocumentListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiDocumentListItem";
import EhiDocumentTypeSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiDocumentTypeSelectBox/index";
import EhiDocumentTypeId from "@Primitives/EhiDocumentTypeId.g";
import EhiQueryButtonWithEmergencyCheckBox from "@HunEHealthInfrastructurePlugin/Common/Components/EhiQueryButtonWithEmergencyCheckBox";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ApiBusinessError from "@Toolkit/CommonWeb/ApiAdapter/ApiBusinessError";
import UnauthorizedOperationBusinessError from "@Toolkit/CommonWeb/Model/UnauthorizedOperationBusinessError";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import EhiCareServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareServiceFactory";
import EhiCareService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareService";
import EhiServiceCallStatus from "@HunEHealthInfrastructurePlugin/Model/EhiServiceCallStatus";
import PermissionCheckReactContext from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckReactContext";
import PermissionCheckContext from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContext";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import NoEhiCompatibleIdentifierFoundError from "@HunEHealthInfrastructurePlugin/Model/NoEhiCompatibleIdentifierFoundError";

interface IEhiDocumentsMasterDetailPanelDependencies {
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    localizationService: ILocalizationService;
    ehiCareServiceFactory: EhiCareServiceFactory;
    notificationService: INotificationService;
}

interface IEhiDocumentsMasterDetailPanelProps {
    _dependencies?: IEhiDocumentsMasterDetailPanelDependencies;
    patientId?: PatientId;
    isCompact?: boolean;
    commonFilter: React.ReactNode;
    filterStore: IEhiDocumentsFilterStore;
}

export interface IEhiDocumentsFilterStore {
    ehiDocumentTypeId: EhiDocumentTypeId;
    setEhiDocumentTypeId: (newId: EhiDocumentTypeId) => void;
    isEmergency: boolean;
    setIsEmergency: (newValue: boolean) => void;
    emergencyReason?: string;
    setEmergencyReason: (reason: string) => void;
    dateRange: LocalDateRange;
    setDateRange: (newValue: LocalDateRange) => void;
}

@State.observer
class EhiDocumentsMasterDetailPanel extends React.Component<IEhiDocumentsMasterDetailPanelProps> {

    @State.observable.ref private isLoading = false;
    @State.observable.ref private isUnauthorizedAccess = false;
    @State.observable.ref private isDocumentLoading = false;
    @State.observable.ref private documents: EhiDocumentListItem[];
    @State.observable.ref private dataSource = new InMemoryDataGridDataSource(() => this.documents || []);
    @State.observable.ref private ehiErrors: EhiError[] = [];
    @State.observable.ref private documentContent: Uint8Array = null;
    @State.observable.ref private selectedDocument: EhiDocumentListItem = null;

    private ehiCareService: EhiCareService = null;

    private get hunEhiCareReferenceDataStore() { return this.props._dependencies!.hunEhiCareReferenceDataStore; }
    private get organizationReferenceDataStore() { return this.props._dependencies!.organizationReferenceDataStore; }
    private get localizationService() { return this.props._dependencies!.localizationService; }
    private get ehiCareServiceFactory() { return this.props._dependencies!.ehiCareServiceFactory; }

    private get resources() {
        return StaticHunEHealthInfrastructureCareResources.EhiDocumentMasterDetailPanel;
    }

    public componentDidMount() {
        this.ehiCareService = this.ehiCareServiceFactory.getEhiCareService();
    }

    @State.action.bound
    private setDocuments(documents: EhiDocumentListItem[]) {
        this.documents = documents;
        this.dataSource = new InMemoryDataGridDataSource(() => documents || []);
        this.dataSource.paging = { currentPage: 0, pageSize: 20 };
        this.dataSource.ordering = { columnId: "timeStamp", direction: "desc" };
    }

    @State.action.bound
    private setEhiErrors(newValue: EhiError[]) {
        this.ehiErrors = newValue;
    }

    @State.action.bound
    private setIsUnauthorizedAccess(newValue: boolean) {
        this.isUnauthorizedAccess = newValue;
    }

    @State.boundLoadingState("isLoading")
    private async loadAsync() {
        try {
            const ehiServiceCallResult = await this.ehiCareService.getEhiDocumentsForPatientAsync(
                this.props.patientId,
                this.props.filterStore.isEmergency ?? false,
                this.props.filterStore.dateRange,
                this.props.filterStore.ehiDocumentTypeId,
                this.props.filterStore.emergencyReason);

            if (ehiServiceCallResult.ehiServiceCallStatus === EhiServiceCallStatus.Success &&
                ehiServiceCallResult.result &&
                ehiServiceCallResult.result.operationWasSuccessful) {

                this.setIsUnauthorizedAccess(false);
                this.setDocuments(ehiServiceCallResult?.result?.value.value ?? []);
                this.setEhiErrors(ehiServiceCallResult?.result?.value.errors ?? []);
            }
        } catch (err) {
            if (err instanceof ApiBusinessError && err.error instanceof UnauthorizedOperationBusinessError) {
                this.setIsUnauthorizedAccess(true);
            } else if (err instanceof ApiBusinessError && err.error instanceof NoEhiCompatibleIdentifierFoundError) {
                this.props._dependencies.notificationService.error(StaticHunEHealthInfrastructureCareResources.CommonEhiMessage.NoEhiCompatibleIdentifierFoundError);
            } else {
                throw err;
            }
        }
    }

    private get isDetailOpen() {
        return !!this.selectedDocument;
    }

    @State.action.bound
    private setDocumentContent(newValue: Uint8Array) {
        this.documentContent = newValue;
    }

    @State.action.bound
    private setSelectedDocument(selectedDocument: EhiDocumentListItem) {
        if (isNullOrUndefined(selectedDocument)) {
            this.selectedDocument = null;
        } else {
            this.selectedDocument = selectedDocument;
            dispatchAsyncErrors(this.ensureRequiredReferenceDataIsLoadedAsync(), this);
            dispatchAsyncErrors(this.loadDetailAsync(), this);
        }
    }

    @State.boundLoadingState("isLoading")
    private async ensureRequiredReferenceDataIsLoadedAsync() {
        await this.hunEhiCareReferenceDataStore.ehiDocumentTypes.ensureLoadedAsync();
        if (this.selectedDocument?.doctorId) {
            await this.organizationReferenceDataStore.doctorMap.ensureLoadedAsync([this.selectedDocument.doctorId]);
        }
        if (this.selectedDocument?.organizationUnitId) {
            await this.hunEhiCareReferenceDataStore.ehiHealthcareProviderOrganizationUnits.ensureLoadedAsync([this.selectedDocument.organizationUnitId]);
        }

    }

    @State.boundLoadingState("isDocumentLoading")
    private async loadDetailAsync() {
        if (!!this.selectedDocument) {
            const ehiServiceCallResult = await this.ehiCareService.loadEhiCareDocumentAsync(
                this.props.patientId,
                this.selectedDocument.ehiId,
                this.props.filterStore.isEmergency ?? false,
                this.props.filterStore.emergencyReason);

            if (ehiServiceCallResult.ehiServiceCallStatus === EhiServiceCallStatus.Success &&
                ehiServiceCallResult.result &&
                ehiServiceCallResult.result.operationWasSuccessful) {

                this.setDocumentContent(ehiServiceCallResult.result.value.value);
            }
        } else {
            this.setDocumentContent(null);
        }
    }

    @State.bound
    private renderFilter() {
        return (
            <Ui.Flex outerSpacing={"regular"}>
                <Ui.Flex.Item xs={12}>
                    <Ui.GroupBox hasBorder
                        visualStyle={"primary"}
                        style={{ borderLeft: "1px solid #00B4EC", backgroundColor: "#F4F7FC" }}>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={3}>
                                {this.props.commonFilter}
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={3}>
                                <Ui.DateRangePicker value={this.props.filterStore.dateRange}
                                    onChange={this.props.filterStore.setDateRange}
                                    label={this.resources.Filters.DateRangeLabel}
                                    automationId="dateRangeFilterPicker"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={3}>
                                <EhiDocumentTypeSelectBox
                                    value={this.props.filterStore.ehiDocumentTypeId}
                                    onChange={this.props.filterStore.setEhiDocumentTypeId}
                                    label={this.resources.Filters.DocumentTypeLabel}
                                    propertyIdentifier="EhiDocumentTypeId"
                                    automationId="ehiDocumentTypeSelectbox"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={3} style={{ marginTop: "18px", marginLeft: "auto" }}>
                                <EhiQueryButtonWithEmergencyCheckBox
                                    onQueryAsync={this.loadAsync}
                                    isEmergency={this.props.filterStore.isEmergency}
                                    onIsEmergencyChange={this.props.filterStore.setIsEmergency}
                                    onReasonChange={this.props.filterStore.setEmergencyReason}
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.GroupBox>
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.computed
    private get documentTitle() {
        const creationDate = isNullOrUndefined(this.selectedDocument?.timeStamp) ? "" : this.localizationService.localizeDateTime(this.selectedDocument?.timeStamp);
        let documentTypeName = "";
        if (this.selectedDocument?.ehiDocumentTypeId) {
            const documentType = this.hunEhiCareReferenceDataStore.ehiDocumentTypes.get(this.selectedDocument.ehiDocumentTypeId)?.displayValue;
            if (documentType) {
                documentTypeName = documentType.Name;
            }
        }
        return `${documentTypeName} [${StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Documents.Labels.Timestamp}: ${creationDate}]`;
    }

    @State.computed
    private get documentSubTitle() {
        return `${StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Documents.Labels.Version}: ${this.selectedDocument?.version}, ${StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Documents.Labels.DocumentIdentifier}: ${this.selectedDocument?.ehiId}]`;
    }

    public render() {

        if (this.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticHunEHealthInfrastructureCareResources.PatientDocumentMasterDetailPanel.Title} />;
        }

        return (
            <MasterDetailLayout
                isCompact={false}
                selectedItem={this.selectedDocument?.ehiId}
                onSelectedItemChange={this.setSelectedDocument}
            >
                <MasterDetail.Master
                    title={StaticHunEHealthInfrastructureCareResources.PatientDocumentMasterDetailPanel.Title}
                    iconName="editDocument"
                >
                    <EhiDocumentsListPanel
                        extraFilter={this.renderFilter()}
                        isExtraFilterVisible={!this.isDetailOpen}
                        dataSource={this.dataSource}
                        errorSource={this.ehiErrors}
                        selectedDocumentId={this.selectedDocument?.ehiId}
                        onSelectedDocumentIdChange={this.setSelectedDocument}
                        isLoading={this.isLoading}
                    />
                </MasterDetail.Master>
                <MasterDetail.Detail
                    title={this.documentTitle}
                    subTitle={this.documentSubTitle}
                >
                    {!this.isDocumentLoading ?
                        <Ui.ScrollView>
                            <Ui.PdfViewer binaryPdf={this.documentContent} />
                        </Ui.ScrollView> : <></>}
                </MasterDetail.Detail>
            </MasterDetailLayout>
        );
    }
}

export default connect(
    EhiDocumentsMasterDetailPanel,
    new DependencyAdapter<IEhiDocumentsMasterDetailPanelProps, IEhiDocumentsMasterDetailPanelDependencies>(c => ({
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService"),
        hunEhiCareReferenceDataStore: c.resolve("HunEhiCareReferenceDataStore"),
        ehiCareServiceFactory: c.resolve("EhiCareServiceFactory"),
        notificationService: c.resolve("INotificationService")
    }))
);