import React from "react";
import { ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import ListPanelView from "@Toolkit/ReactClient/Components/ListPanel/ListPanelView";
import { nullFunction, isNullOrUndefined, arrayIsNullOrEmpty, emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IIndexedItem from "@Toolkit/ReactClient/Components/ListPanel/IIndexedItem";
import ValidationBoundaryAdapter from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryAdapter";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import ReadOnlyContextAdapter from "@Toolkit/ReactClient/Components/ReadOnlyContext/ReadOnlyContextAdapter";

interface IListPanelDependencies {
    toolkitLocalizationService: IToolkitLocalizationService;
    notificationService: INotificationService;
}

interface IListPanelProps<TItem> extends ICommonControlProps {
    _dependencies?: IListPanelDependencies;
    _validationBoundary?: IValidationBoundaryStore;

    // Extendability / behavior
    alwaysEdit?: boolean;
    isReadOnly?: boolean;
    allowCreatingNew?: boolean;
    observerItems?: boolean;

    onCreateNewAsync?: () => Promise<TItem>;
    onAddNewItem?: (item: TItem) => void;
    onUpdateItem?: (item: TItem) => void;
    onRemoveItem?: (item: TItem) => void;
    items: IObservableArray<TItem> | TItem[];
    filter?: (item: TItem) => boolean;

    onDropChangesConfirmationAsync?: (item: TItem) => Promise<boolean>;
    onDeleteItemConfirmationAsync?: (item: TItem) => Promise<boolean>;

    takeItemSnapshot?: (item: TItem) => void;
    isItemDirty?: (item: TItem) => boolean;
    isItemInInitialState?: (item: TItem) => boolean;
    revertItemToSnapshot?: (item: TItem) => void;
    getItemKey?: (item: any, index: number) => string | number;

    renderItemViewer?: (item: TItem, index: number) => React.ReactNode;
    renderItemEditor: (item: TItem, index: number) => React.ReactNode;
    allowNullItem?: boolean;

    // View

    /** Default is horizontal */
    actionButtonsOrientation?: "horizontal" | "vertical";
    actionButtonsLabeledInputOffset?: number;
    addButtonText?: string;
    noItemsMessage?: string;
    automationId?: string;
    propertyIdentifier?: string;
    title?: string;
    isCompactEmptyState?: boolean;
}

@State.observer
class ListPanelCore<TItem> extends React.Component<IListPanelProps<TItem>> {

    private get notificationService() { return this.props._dependencies.notificationService; }
    private get toolkitLocalizationService() { return this.props._dependencies.toolkitLocalizationService; }

    public static defaultProps: Partial<IListPanelProps<any>> = {
        actionButtonsOrientation: "horizontal",
        actionButtonsLabeledInputOffset: 29,
        alwaysEdit: false,
        allowCreatingNew: true,
        isItemDirty: (item: any) => item.IsDirty && item.IsDirty(),
        isItemInInitialState: (item: any) => ListPanelCore.defaultIsInitial(item),
        takeItemSnapshot: (item: any) => item.takeSnapshot && item.takeSnapshot(),
        revertItemToSnapshot: (item: any) => item.revertToSnapshot && item.revertToSnapshot(),
        onUpdateItem: nullFunction,
        getItemKey: (_item: any, index: number) => index
    };

    private static defaultIsInitial(item: any) {
        if (item) {
            if (!isNullOrUndefined(item.isInInitialState)) {
                return item.isInInitialState();
            }
            if (!isNullOrUndefined(item.isEmpty)) {
                return typeof item.isEmpty === "function" ? item.isEmpty() : item.isEmpty;
            }
        }
        return isNullOrUndefined(item);
    }

    @State.observable.ref private newItem: TItem = null;
    @State.observable.ref private itemUnderEdit: TItem = null;

    @State.computed private get items(): Array<IIndexedItem<TItem>> {
        let itemArray: TItem[];

        if (State.isObservableArray(this.props.items)) {
            itemArray = Array.from(this.props.items);
        } else {
            itemArray = this.props.items;
        }

        if (arrayIsNullOrEmpty(itemArray)) {
            return emptyArray;
        }

        const mappedItems = itemArray.map((i, idx) => ({
            item: i,
            index: idx
        } as IIndexedItem<TItem>));

        if (this.props.filter) {

            return mappedItems.filter(i => this.props.filter(i.item));
        }

        return mappedItems;
    }

    @State.computed
    private get validationResults() {
        if (this.props._validationBoundary) {
            const problems = this.props._validationBoundary.getValidationProblems(this.props.propertyIdentifier, undefined, true);
            return problems;
        }
        return null;
    }

    public componentDidUpdate(prevProps: IListPanelProps<TItem>) {
        if (prevProps.items !== this.props.items) {
            this.itemsChanged();
        }
    }

    @State.action.bound
    private itemsChanged() {
        this.newItem = null;
        this.itemUnderEdit = null;
        this.changed();
    }

    @State.bound
    private async tryCreateItemAsync() {
        const shouldChangeItemUnderEdit = await this.shouldChangeItemUnderEditAsync();
        if (shouldChangeItemUnderEdit) {
            const newItem = await this.props.onCreateNewAsync();
            if (!this.props.allowNullItem && isNullOrUndefined(newItem)) {
                return;
            }
            if (!this.props.alwaysEdit) {
                this.props.takeItemSnapshot(newItem);
                this.setNewItem(newItem);
            } else {
                if (this.items.length !== 0) {
                    const lastItem = this.items[this.items.length - 1];
                    const isLastItemInInitialState = this.props.isItemInInitialState(lastItem.item);
                    if (!isLastItemInInitialState) {
                        this.addNewItem(newItem);
                    } else {
                        this.notificationService.warning(StaticWebAppResources.ListPanel.LastItemIsInInitialStateMessage);
                    }
                } else {
                    this.addNewItem(newItem);
                }
            }
        }
    }

    @State.action
    private setNewItem(item: TItem) {
        this.itemUnderEdit = null;
        this.newItem = item;
    }

    @State.bound
    private addNewItem(itemToAdd: TItem) {
        if (this.props.onAddNewItem) {
            this.props.onAddNewItem(itemToAdd);
        } else {
            this.defaultOnAddNewItem(itemToAdd);
        }

        this.clearNewItem();
        this.changed();
    }

    @State.action
    private defaultOnAddNewItem(itemToAdd: TItem) {
        if (!State.isObservableArray(this.props.items)) {
            throw new Error("Default onAddItem requires observable items.");
        }
        this.props.items.push(itemToAdd);
    }

    @State.bound
    private async tryClearNewItemAsync() {
        if (this.props.isItemDirty(this.newItem)) {
            const confirmationResult = await this.props.onDropChangesConfirmationAsync(this.newItem);
            if (confirmationResult === true) {
                this.clearNewItem();
            }
        } else {
            this.clearNewItem();
        }
    }

    @State.action.bound
    private clearNewItem() {
        this.newItem = null;
        this.changed();
    }

    private changed() {
        if (this.props._validationBoundary) {
            this.props._validationBoundary.changed(this.props.propertyIdentifier, []);
        }
    }

    @State.bound
    private async tryEditItemAsync(item: TItem) {
        const shouldChangeItemUnderEdit = await this.shouldChangeItemUnderEditAsync();
        if (shouldChangeItemUnderEdit) {
            State.runInAction(() => {
                this.itemUnderEdit = item;
                this.props.takeItemSnapshot(item);
            });
        }
    }

    @State.bound
    private updateItem() {
        this.props.onUpdateItem(this.itemUnderEdit);
        this.clearItemUnderEdit();
    }

    @State.action.bound
    private cancelModifyItem() {
        this.props.revertItemToSnapshot(this.itemUnderEdit);
        this.clearItemUnderEdit();
    }

    @State.action
    private clearItemUnderEdit() {
        this.itemUnderEdit = null;
    }

    private async shouldChangeItemUnderEditAsync() {
        if (this.itemUnderEdit && this.props.isItemDirty(this.itemUnderEdit)) {
            const confirmationResult = await this.props.onDropChangesConfirmationAsync(this.itemUnderEdit);
            if (confirmationResult === true) {
                this.props.revertItemToSnapshot(this.itemUnderEdit);
                return true;
            }
            return false;
        }
        return true;
    }

    @State.bound
    private async tryRemoveItemAsync(item: IIndexedItem<TItem>) {
        const confirmationResult = this.props.onDeleteItemConfirmationAsync ?
            await this.props.onDeleteItemConfirmationAsync(item.item) :
            true;

        if (confirmationResult === true) {

            const allPropertyIdentifiers = this.props.items.map((i, idx) => `${this.props.propertyIdentifier}[${idx}]`);

            if(this.props._validationBoundary) {
                this.props._validationBoundary.removeItemFromList(
                    `${this.props.propertyIdentifier}[${item.index}]`,
                    allPropertyIdentifiers.slice(item.index + 1)
                );
            }

            if (this.props.onRemoveItem) {
                this.props.onRemoveItem(item.item);
            } else {
                this.defaultOnRemoveItem(item);
            }
            this.clearItemUnderEdit();
            this.changed();
        }
    }

    @State.action
    private defaultOnRemoveItem(item: IIndexedItem<TItem>) {
        if (!State.isObservableArray(this.props.items)) {
            throw new Error("Default onRemoveItem requires observable items.");
        }
        this.props.items.remove(item.item);
    }

    public render() {
        return (
            <ListPanelView
                allowCreatingNew={this.props.allowCreatingNew}
                items={this.items}
                renderItemViewer={this.props.renderItemViewer}
                renderItemEditor={this.props.renderItemEditor}
                actionButtonsVerticalLayout={this.props.actionButtonsOrientation === "vertical"}
                actionButtonsLabeledInputOffset={this.props.actionButtonsLabeledInputOffset}
                onCreateItemAsync={this.tryCreateItemAsync}
                newItem={this.newItem}
                onConfirmNewItemAddition={this.addNewItem}
                onCancelNewItemAdditionAsync={this.tryClearNewItemAsync}
                onEditItemAsync={this.tryEditItemAsync}
                itemUnderEdit={this.itemUnderEdit}
                onConfirmItemUpdate={this.updateItem}
                onCancelItemUpdate={this.cancelModifyItem}
                onRemoveItemAsync={this.tryRemoveItemAsync}
                addButtonText={this.props.addButtonText || this.toolkitLocalizationService.staticResources.button.addNew}
                noItemsMessage={this.props.noItemsMessage}
                alwaysEdit={this.props.alwaysEdit}
                automationId={this.props.automationId}
                validationResults={this.validationResults}
                title={this.props.title}
                isCompactEmptyState={this.props.isCompactEmptyState}
                propertyIdentifier={this.props.propertyIdentifier}
                observerItems={this.props.observerItems}
                getItemKey={this.props.getItemKey}
                isReadOnly={this.props.isReadOnly ?? false}
                hasValidationBoundary={!!this.props._validationBoundary}
                style={this.props.style}
            />
        );
    }
}


const InjectedListPanel = connect(
    ListPanelCore,
    new DependencyAdapter<IListPanelProps<any>, IListPanelDependencies>(c => ({
        toolkitLocalizationService: c.resolve("IToolkitLocalizationService"),
        notificationService: c.resolve("INotificationService"),
    })),
    new ValidationBoundaryAdapter<IListPanelProps<any>>((props, store) => ({ _validationBoundary: store })),
    new ReadOnlyContextAdapter()
);


export default class ListPanel<TItem> extends React.PureComponent<IListPanelProps<TItem>> {
    public render() {
        return (
            <InjectedListPanel {...this.props} />
        );
    }
}