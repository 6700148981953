import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import ProfessionalExamId from "@Primitives/ProfessionalExamId.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import ConditionId from "@Primitives/ConditionId.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import MedicationSubsidy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationSubsidy";
import Styles from "./MedicationSubsidyOptionList.less";
import SubsidyOptionCheckResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/SubsidyOptionCheckResult";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ReferenceDataApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/ReferenceDataApiAdapter";
import MedicationId from "@Primitives/MedicationId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PractitionerId from "@Primitives/PractitionerId.g";
import OrganizationRestrictionTypeId from "@Primitives/OrganizationRestrictionTypeId.g";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import MedicationSubsidizedPricingTypeId from "@Primitives/MedicationSubsidizedPricingTypeId.g";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import SubsidyOptionRestriction from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/SubsidyOptionRestriction";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import _ from "@HisPlatform/Common/Lodash";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import DesignatedOrganizationId from "@Primitives/DesignatedOrganizationId.g";
import MedicationSubsidyOptionCheckType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/ReferenceData/Prescription/Enum/MedicationSubsidyOptionCheckType.g";
import PrescriptionRestrictionType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/ReferenceData/Prescription/Enum/PrescriptionRestrictionType.g";

interface IMedicationSubsidyOptionListDependencies {
    medicationRequestReferenceDataStore: MedicationRequestReferenceDataStore;
    localizationService: ILocalizationService;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    careReferenceDataStore: CareReferenceDataStore;
    referenceDataApiAdapter: ReferenceDataApiAdapter;
}

interface IMedicationSubsidyOptionListProps {
    _dependencies?: IMedicationSubsidyOptionListDependencies;
    subsidy: MedicationSubsidy;
    medicationId: MedicationId;
    careActivityId: CareActivityId;
    checkType: MedicationSubsidyOptionCheckType;
    optionCheckResults: SubsidyOptionCheckResult[];
    claimTypeId: MedicationSubsidyClaimTypeId;
    setOptionCheckResults: (optionCheckResults: SubsidyOptionCheckResult[]) => void;
    prescriber?: PractitionerId;
}

@State.observer
class MedicationSubsidyOptionList extends React.Component<IMedicationSubsidyOptionListProps> {

    private get hunSocialSecurityReferenceDataStore() { return this.props._dependencies.medicationRequestReferenceDataStore; }
    private get localizationService() { return this.props._dependencies.localizationService; }
    private get organizationReferenceDataStore() { return this.props._dependencies.organizationReferenceDataStore; }
    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get referenceDataApiAdapter() { return this.props._dependencies.referenceDataApiAdapter; }

    @State.observable private isValid: boolean = false;

    @State.bound
    private async loadAsync() {
        let pricingType: MedicationSubsidizedPricingTypeId = null;
        if (this.props.claimTypeId) {
            await this.hunSocialSecurityReferenceDataStore.medicationSubsidyClaimTypes.ensureLoadedAsync([this.props.claimTypeId]);
            pricingType = this.hunSocialSecurityReferenceDataStore.medicationSubsidyClaimTypes.get(this.props.claimTypeId).medicationSubsidizedPricingTypeId;
        }

        if (this.props.careActivityId && this.props.medicationId) {
            const results = await this.referenceDataApiAdapter.checkMedicationSubsidyOptionAsync(
                this.props.medicationId,
                this.props.careActivityId,
                this.props.checkType,
                pricingType,
                this.props.prescriber
            );
            this.props.setOptionCheckResults(results.value);
        }
        const designatedOrganizationIds = _.flatten(this.props.subsidy.options.map(o => o.optionRestrictions)).map(or => or.designatedOrganizationId);
        const designatedOrganizationVersionSelectors = designatedOrganizationIds.map(d => {
            return {
                id: d,
                validOn: LocalDate.today()
            } as IEntityVersionSelector<DesignatedOrganizationId>;
        });
        await this.props._dependencies.medicationRequestReferenceDataStore.designatedOrganizations.ensureLoadedAsync(designatedOrganizationVersionSelectors);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IMedicationSubsidyOptionListProps) {
        if ((!ValueWrapper.equals(prevProps.careActivityId, this.props.careActivityId))
            || (!ValueWrapper.equals(prevProps.medicationId, this.props.medicationId))
            || (!ValueWrapper.equals(prevProps.claimTypeId, this.props.claimTypeId))
            || (!ValueWrapper.equals(prevProps.prescriber, this.props.prescriber))) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.bound
    private renderProfessionalExams(professionalExamIds: ProfessionalExamId) {
        return this.organizationReferenceDataStore.professionalExamMap.get(professionalExamIds)?.description || "";
    }

    @State.bound
    private renderConditions(conditionIds: ConditionId[]) {
        return conditionIds.map(i => this.careReferenceDataStore.conditionsWithoutVersion.get(i)?.code)?.join(", ") || "";
    }

    @State.bound
    private renderOrganizationRestrictionType(organizationRestrictionTypeId: OrganizationRestrictionTypeId) {
        return this.hunSocialSecurityReferenceDataStore.organizationRestrictionTypes.get(organizationRestrictionTypeId)?.displayValue?.Name;
    }
    @State.bound
    private renderPrescriptionRestrictionTypeAndValidityDurationInMonths(prescriptionRestrictionType: PrescriptionRestrictionType, validityDurationInMonths: number) {
        if (validityDurationInMonths > 0 && validityDurationInMonths < 999) {
            return (`${this.localizationService.localizeEnum(PrescriptionRestrictionType[prescriptionRestrictionType], "PrescriptionRestrictionType")?.Name} (${formatString(StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.PractitionerPrescriptionValidityDurationInMonths, validityDurationInMonths)})`);
        } else if (validityDurationInMonths === 999) {
            return (`${this.localizationService.localizeEnum(PrescriptionRestrictionType[prescriptionRestrictionType], "PrescriptionRestrictionType")?.Name} (${StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.PractitionerPrescriptionValidityUnlimitedDuration}) `);
        } else {
            return (this.localizationService.localizeEnum(PrescriptionRestrictionType[prescriptionRestrictionType], "PrescriptionRestrictionType")?.Name);
        }
    }

    private getClassNameByValidationState(valid: boolean) {
        if (isNullOrUndefined(valid)) {
            return "";
        } else if (valid) {
            return Styles.valid;
        } else {
            return Styles.invalid;
        }
    }

    private renderIcon(valid: boolean) {
        if (isNullOrUndefined(valid)) {
            return "";
        } else if (valid) {
            return <Ui.Icon iconName="checkCircle" className={Styles.validIcon} />;
        } else {
            return <Ui.Icon iconName="remove" visualStyle="error" />;
        }
    }

    public render() {
        return this.props.subsidy.options.map(option => {
            const claimType = this.hunSocialSecurityReferenceDataStore.medicationSubsidyClaimTypes.items.find(i => i.medicationSubsidizedPricingTypeId && ValueWrapper.equals(i.medicationSubsidizedPricingTypeId, this.props.subsidy.pricingType));
            const optionResult = this.props.optionCheckResults?.find(i => ValueWrapper.equals(i.medicationSubsidyOptionId, option.id));
            const grouppedSubsidyOptions = option.optionRestrictions.reduce((restrictions, current) => {
                const currentHashCode = current.getHashCode();
                if (restrictions.has(currentHashCode)) {
                    restrictions.set(currentHashCode, [current, ...restrictions.get(currentHashCode)]);
                } else {
                    restrictions.set(currentHashCode, [current]);
                }

                return restrictions;
            }, new Map<string, SubsidyOptionRestriction[]>());

            return (
                <Ui.SingleAccordion
                    titleClassName={this.getClassNameByValidationState(optionResult?.successful)}
                    info={option.indicationText || undefined}
                    title={`${option.code} ${claimType?.name} (${this.props.subsidy.supportPercentage}%)`}
                    key={option.id.value}>
                    <Ui.Table fixedLayout automationId="medicationSubsidyOptionList_table">
                        <tbody>
                            <tr>
                                <td colSpan={3}>{StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.ConditionRestrictrions}: {this.renderConditions(option.restrictionConditions)}</td>
                                {this.props.careActivityId && <td className={Styles.iconColumn}>{this.renderIcon(optionResult?.conditionRestrictionsMatch)}</td>}
                            </tr>
                            {Array.from(grouppedSubsidyOptions.keys()).map((key, i) => {
                                const subsidyOptionsForKey = grouppedSubsidyOptions.get(key);
                                const designatedOrganizationIds = subsidyOptionsForKey.filter(r => r.organizationRestrictionTypeId.value === "3" || r.organizationRestrictionTypeId.value === "6").map(r => r.designatedOrganizationId);
                                const designatedOrganizationNames = this.getDesignatedOrganizationNamesString(designatedOrganizationIds);
                                const r = subsidyOptionsForKey[0];
                                const isAnySuccessfulRestrictionResult = optionResult?.optionRestrictionResults?.filter(j =>
                                    subsidyOptionsForKey.some(i =>
                                        ValueWrapper.equals(j.subsidyOptionRestrictionId, i.id)
                                    )
                                ).some(o => o.successful);
                                return (
                                    <tr key={i}>
                                        <td>{this.renderOrganizationRestrictionType(r.organizationRestrictionTypeId)} {this.renderInfoByRestrictionType(r, designatedOrganizationNames)}</td>
                                        <td>{this.renderProfessionalExams(r.practitionerProfessionalExamRestriction)}</td>
                                        <td>{this.renderPrescriptionRestrictionTypeAndValidityDurationInMonths(r.prescriptionRestrictionType, r.practitionerPrescriptionValidityDurationInMonths)}</td>
                                        {this.props.careActivityId && <td className={Styles.iconColumn}>{this.renderIcon(isAnySuccessfulRestrictionResult)}</td>}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Ui.Table>
                </Ui.SingleAccordion>
            );
        });
    }

    @State.bound
    private getDesignatedOrganizationNamesString(ids: DesignatedOrganizationId[]) {
        const designatedOrganizations = ids.map(i => this.props._dependencies.medicationRequestReferenceDataStore.designatedOrganizations.get({ id: i, validOn: LocalDate.today() }));
        const names = designatedOrganizations.map(d => d?.instituteName);
        return names.join(", ");
    }

    @State.bound
    private renderInfoByRestrictionType(restriction: SubsidyOptionRestriction, designatedOrganizationNamesString: string) {
        switch (restriction.organizationRestrictionTypeId.value) {
            case "3":
            case "6":
                return (
                    <Ui.InfoButton position="baseline" iconName="infoCircle" tooltipContent={designatedOrganizationNamesString} />
                );
            default:
                return <></>;
        }
    }
}

export default connect(
    MedicationSubsidyOptionList,
    new DependencyAdapter<IMedicationSubsidyOptionListProps, IMedicationSubsidyOptionListDependencies>(c => ({
        medicationRequestReferenceDataStore: c.resolve("MedicationRequestReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        referenceDataApiAdapter: c.resolve("ReferenceDataApiAdapter")
    }))
);