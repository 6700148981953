import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import React from "react";
import UserGroupMultiSelector from "@HisPlatform/BoundedContexts/UserManagement/Components/Controls/UserGroupMultiSelector";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";

interface IUserGroupsView {
    user: User;
}


const UserGroupsView: React.FC<IUserGroupsView> = props => {
    if (!props.user) {
        return <></>;
    }

    return State.useObserver(() => (
        <Ui.GroupBox>
            <UserGroupMultiSelector
                selectedIds={props.user.userGroupIds}
                allItemsTableTitle={StaticUserManagementResources.UserManagementPanel.UserGroupPanel.AllItemsTableTitle}
                chosenItemsTableTitle={StaticUserManagementResources.UserManagementPanel.UserGroupPanel.ChosenItemsTableTitle}
                onChange={props.user.setUserGroupIds}
                fixedHeight="calc(100vh - 177px)"/>
        </Ui.GroupBox>
    ));
};

export default UserGroupsView;
