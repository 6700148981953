exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".UserHeader_header-container_3l19K {\n  margin-top: 6px;\n  margin-left: 17px;\n}\n.UserHeader_identifier-margin_2O3RN {\n  margin-left: 20px;\n}\n", ""]);

// exports
exports.locals = {
	"header-container": "UserHeader_header-container_3l19K",
	"headerContainer": "UserHeader_header-container_3l19K",
	"identifier-margin": "UserHeader_identifier-margin_2O3RN",
	"identifierMargin": "UserHeader_identifier-margin_2O3RN"
};