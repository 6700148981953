import { IValidationProblemParameterMapper } from "@Toolkit/CommonWeb/ApiAdapter/IValidationProblemParameterMapper";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import Di from "@Di";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import DocumentManagementReferenceDataStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentManagementReferenceDataStore";

@Di.injectable()
export default class CareActivityDocumentValidationProblemMapper implements IValidationProblemParameterMapper {

    constructor(
        @Di.inject("DocumentManagementReferenceDataStore") private referenceDataStore: DocumentManagementReferenceDataStore
    ) { }

    public collectValidationProblemParameterReferenceData(vp: IClientValidationProblem) {
        return;
    }
    
    @State.bound
    public async loadCollectedValidationProblemParameterReferenceDataAsync(): Promise<void> {
        await this.referenceDataStore.documentTypeMap.ensureAllLoadedAsync();
    }
    
    @State.bound
    public resolveValidationProblemParameters(vp: IClientValidationProblem): { [id: string]: string; } {
        const results: { [id: string]: string; } = {};
        for (const key in vp.parameters) {
            if (key) {
                switch (key) {
                    case "DocumentTypeRuleOutputDto":
                        const rawIds = _.get(vp.parameters, key + ".DocumentTypeIds") as any[];
                        const typeIds = rawIds?.map(i => new TextBlockTypeId(i?.Value?.toString())) || [];

                        const localizedValues: string[] = [];
                        typeIds.forEach(i => {
                            const localizedValue = this.referenceDataStore.documentTypeMap.get(i);
                            localizedValues.push(localizedValue.name);
                        });

                        results.DocumentTypes = localizedValues.join(", ");
                        break;
                    default:
                        break;
                }
            }
        }

        return results;
    }
}