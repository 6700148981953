import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import IMedicationSubsidyClaimType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IMedicationSubsidyClaimType";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import MedicationSubsidyIcon from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/MedicationSubsidyIcon/MedicationSubsidyIcon";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";

interface IMedicationSubsidyColumnDependencies {
    hunReferenceDataStore: MedicationRequestReferenceDataStore;
}

interface IMedicationSubsidyColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IMedicationSubsidyColumnDependencies;
    displayMode?: "name" | "badge";
}


const MedicationSubsidyColumn: React.FC<IMedicationSubsidyColumnProps> = props => {
    const subsidyClaimTypeIdTooltipContent = (value: string) => {
        switch (value) {
            case MedicationSubsidyClaimTypeId.UB.value:
                return StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.ToolTips.AccidentAtWork;
            case MedicationSubsidyClaimTypeId.KGY.value:
                return StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.ToolTips.GeneralMedicine;
            case MedicationSubsidyClaimTypeId.HM.value:
            default:
                return StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.ToolTips.HomeDefense;
        }
    };

    const valueRenderer = useCallback((value: MedicationSubsidyClaimTypeId[]) => {
        switch (props.displayMode) {
            case "name":
                if (Array.isArray(value)) {
                    const medicationSubsidyClaimTypes = value.map(i => props._dependencies.hunReferenceDataStore.medicationSubsidyClaimTypes.get(i));
                    return filterClaimTypes(medicationSubsidyClaimTypes).map(i => i?.name).join(", ");
                }
                const localized = isNullOrUndefined(value) ? null : props._dependencies.hunReferenceDataStore.medicationSubsidyClaimTypes.get(value);
                return isNullOrUndefined(localized) ? null : localized.name;
            case "badge":
            default:
                if (Array.isArray(value)) {
                    const medicationSubsidyClaimTypes = value.map(i => props._dependencies.hunReferenceDataStore.medicationSubsidyClaimTypes.get(i));
                    return filterClaimTypes(medicationSubsidyClaimTypes).map(i => <MedicationSubsidyIcon tooltipContent={subsidyClaimTypeIdTooltipContent(i?.code)} key={i?.code} state={i?.code} />);
                } else {
                    const medicationSubsidyClaimType = isNullOrUndefined(value) ? null : props._dependencies.hunReferenceDataStore.medicationSubsidyClaimTypes.get(value);
                    return <MedicationSubsidyIcon tooltipContent={subsidyClaimTypeIdTooltipContent(medicationSubsidyClaimType?.code)} state={medicationSubsidyClaimType?.code} />;
                }
        }
    }, [props._dependencies.hunReferenceDataStore.medicationSubsidyClaimTypes]);

    const filterClaimTypes = (values: IMedicationSubsidyClaimType[]) => {
        const filteredClaimTypes = [];
        for (const claimType of values) {
            if (claimType.code === "HM" || claimType.code === "KGY" || claimType.code === "ÜB") {
                filteredClaimTypes[filteredClaimTypes.length] = claimType;
            }
        }
        return filteredClaimTypes;
    };

    const filterRenderer = useCallback(() => {
        return null;
    }, []);

    return (
        <DataGridColumn
            {...props as IDataGridColumnBaseProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            showHint={false}
        />
    );
};

export default connect(
    MedicationSubsidyColumn,
    new DependencyAdapter<IMedicationSubsidyColumnProps, IMedicationSubsidyColumnDependencies>(c => ({
        hunReferenceDataStore: c.resolve("MedicationRequestReferenceDataStore")
    }))
);