import React, { useCallback } from "react";
import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import { IPatientCareActivitiesInputParams } from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientCareActivitiesInputParams";
import PatientId from "@Primitives/PatientId.g";
import PatientsCareActivitiesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/PatientCareActivities/PatientsCareActivitiesApiAdapter";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import PerformedServicesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/PerformedServices/PerformedServicesApiAdapter";
import CareActivityDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentApiAdapter";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import FormExtensionRegistryAdapter from "@PluginInterface/FormExtension/FormExtensionRegistryAdapter";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import DocumentId from "@Primitives/DocumentId.g";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import AccessLevel from "@Primitives/AccessLevel";
import IPatientCareActivitiesDataSource from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientCareActivitiesDataSource";
import PatientsCareActivitiesDataSource from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PatientsCareActivitiesPanel/PatientsCareActivitiesDataSource";
import PatientCareActivitiesMasterDetailPanelView from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PatientsCareActivitiesPanel/PatientCareActivitiesMasterDetailPanelView";
import CareActivityBaseDataApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityBaseData/CareActivityBaseDataApiAdapter";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import PatientApiAdapter2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/PatientApiAdapter2";
import IPatientCareActivitiesTabRegistry from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabRegistry";
import _ from "@HisPlatform/Common/Lodash";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import DataLoadError from "@CommonControls/DataGrid/DataSource/DataLoadError";

interface IPatientCareActivitiesMasterDetailPanelDependencies {
    patientApiAdapter: PatientApiAdapter2;
    patientsCareActivitiesApiAdapter: PatientsCareActivitiesApiAdapter;
    careActivityApiAdapter: CareActivityApiAdapter2;
    careActivityBaseDataApiAdapter: CareActivityBaseDataApiAdapter;
    performedServicesApiAdapter: PerformedServicesApiAdapter;
    careDocumentApiAdapter: CareActivityDocumentApiAdapter;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    userContext: UserContext;
    financeReferenceDataStore: FinanceReferenceDataStore;
    careReferenceDataStore: CareReferenceDataStore;
    authorizationService: AuthorizationService;
    patientCareActivitiesTabRegistry: IPatientCareActivitiesTabRegistry;
    notificationService: INotificationService;
}

interface IPatientCareActivitiesMasterDetailPanelProps {
    _dependencies?: IPatientCareActivitiesMasterDetailPanelDependencies;
    _formExtension?: IFormExtension<IPatientCareActivitiesInputParams>;
    patientId: PatientId;
    episodeOfCareId?: EpisodeOfCareId;
    title?: string;
    isFilterOpen?: boolean;
}

@State.observer
class PatientCareActivitiesMasterDetailPanel extends React.Component<IPatientCareActivitiesMasterDetailPanelProps> {

    @State.observable private isFilterOpen = true;

    @State.observable.ref private selectedCareActivity: PatientsCareActivityStore = null;

    @State.observable.ref private availableStepTypes: string[] = [];
    @State.observable private activeTab: string = null;

    @State.observable private activeExtensionTabName: string = null;

    @State.observable.ref private dataSource = this.createDataSource(
        {
            patientsCareActivitiesApiAdapter: this.props._dependencies.patientsCareActivitiesApiAdapter,
            careDocumentApiAdapter: this.props._dependencies.careDocumentApiAdapter,
            organizationReferenceDataStore: this.props._dependencies.organizationReferenceDataStore,
            practitionerId: this.props._dependencies.userContext.practitionerId,
            careReferenceDataStore: this.props._dependencies.careReferenceDataStore,
            onDataLoadErrorAsync: this.onDataLoadErrorAsync
        } as IPatientCareActivitiesInputParams
    );

    @State.observable.ref private popperReferenceElement: HTMLElement = null;
    @State.observable private tooltipContent: React.ReactNode = "";
    @State.observable private isTooltipOpen: boolean = false;

    @State.observable.ref private selectedDocumentId: DocumentId = null;
    @State.observable private isDetailLoading: boolean = false;

    private get filterStore() {
        return this.dataSource.filterStore;
    }

    @State.computed
    private get activeTabName() {
        if (isNullOrUndefined(this.selectedCareActivity?.careActivitySource?.value)) {
            if (!isNullOrUndefined(this.activeTab)) {
                return this.activeTab;
            }
            if (!isNullOrUndefined(this.activeExtensionTabName)) {
                return this.activeExtensionTabName;
            }
            return null;
        }

        if (this.selectedCareActivity.careActivitySource.value === "InHouse") {
            return !isNullOrUndefined(this.activeTab) ? this.activeTab : null;
        }

        return !isNullOrUndefined(this.activeExtensionTabName) ? this.activeExtensionTabName : null;
    }

    @State.action.bound
    private openOrCloseFilters() {
        this.isFilterOpen = !this.isFilterOpen;
    }

    @State.action.bound
    private setAvailableStepTypes(newValues: string[]) {
        this.availableStepTypes = newValues;
    }

    @State.action.bound
    private setSelectedDocumentId(newValue: DocumentId) {
        this.selectedDocumentId = newValue;
    }

    @State.action.bound
    private clearFilters() {
        this.filterStore.__reset();
    }

    @State.action.bound
    private setActiveExtensionTabName(value: string) {
        this.activeExtensionTabName = value;
    }

    @State.action.bound
    public selectTab(newValue: string) {
        this.activeTab = newValue;
    }

    @State.action.bound
    public setActiveTabName(newValue: string) {
        this.activeTab = newValue;
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.dataSource.reloadAsync);

    public componentDidMount() {
        this.dataSource.patientId = this.props.patientId;
        if (!isNullOrUndefined(this.props.episodeOfCareId)) {
            this.dataSource.filterStore.setEpisodeOfCareIds([this.props.episodeOfCareId]);
        }

        State.runInAction(() => {
            this.isFilterOpen = this.props.isFilterOpen ?? true;
        });

        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IPatientCareActivitiesMasterDetailPanelProps) {
        if (prevProps.patientId !== this.props.patientId) {
            this.dataSource.patientId = this.props.patientId;
            dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        }
    }

    @State.bound
    private async selectCareActivityAsync(row: PatientsCareActivityStore) {

        this.setSelectedDocumentId(null);

        if (!row || row.accessLevel !== AccessLevel.Full) {
            return;
        }

        if (row === this.selectedCareActivity) {
            this.resetSelectedCareActivity();
            return;
        }

        this.setSelectedCareActivity(row);
        await this.setAvailableStepTypesAsync();

        if (row.careActivityId) {
            this.selectPreviouslySelectedTab();
        }
    }

    @State.action.bound
    private selectPreviouslySelectedTab() {
        if (this.availableStepTypes?.some(i => i === this.activeTab)) {
            this.selectTab(this.activeTab);
        } else if (this.availableStepTypes?.length > 0) {
            this.selectTab(this.availableStepTypes[0]);
        } else {
            this.selectTab(null);
        }
    }

    @State.action.bound
    private async setAvailableStepTypesAsync() {
        const availableStepTypes = await this.queryAvailableStepTypesByPermissionsAsync();
        this.setAvailableStepTypes(availableStepTypes);
    }

    @State.action.bound
    private async queryAvailableStepTypesByPermissionsAsync() {
        if (!this.selectedCareActivity.careActivityId) {
            return [];
        }

        const componentServices = this.props._dependencies.patientCareActivitiesTabRegistry.getAllComponentServices();

        const availableStepTypes: { tabName: string, order: number }[] = [];

        for (const item of componentServices) {
            const isAllowed = await item.service.isTabAllowedAsync(this.selectedCareActivity.careActivityId, this.selectedCareActivity?.organizationUnitId);
            if (isAllowed) {
                availableStepTypes.push({ tabName: item.tabName, order: item.order });
            }
        }

        return _.orderBy(availableStepTypes, i => i.order).map(i => i.tabName) ;
    }

    @State.bound
    private async showPrimaryDocumentAsync(row: PatientsCareActivityStore) {
        if (!row || row.accessLevel !== AccessLevel.Full) {
            return;
        }

        this.setSelectedCareActivity(row);
        await this.setAvailableStepTypesAsync();

        this.loadPrimaryDocument();
    }

    @State.bound
    private resetSelectedCareActivity() {
        this.setSelectedCareActivity(null);
    }

    @State.action.bound
    private setSelectedCareActivity(row: PatientsCareActivityStore) {
        this.selectedCareActivity = row;
    }

    @State.bound
    private loadPrimaryDocument() {
        if (this.availableStepTypes?.some(i => i === "Documents")) {
            this.selectTab("Documents");
            if (this.selectedCareActivity?.primaryDocumentId) {
                this.setSelectedDocumentId(this.selectedCareActivity.primaryDocumentId);
            }
        }
    }

    @State.bound
    private createDataSource(inputParams: IPatientCareActivitiesInputParams): IPatientCareActivitiesDataSource {
        const dataSources = this.props._formExtension?.invokeCallback<IPatientCareActivitiesDataSource>("createDataSource", inputParams);

        if (arrayIsNullOrEmpty(dataSources)) {
            return new PatientsCareActivitiesDataSource(
                inputParams.patientsCareActivitiesApiAdapter,
                inputParams.careDocumentApiAdapter,
                inputParams.organizationReferenceDataStore,
                this.onDataLoadErrorAsync
            );
        }

        return dataSources[dataSources.length - 1];
    }

    @State.bound
    private onDataLoadErrorAsync(error: DataLoadError): Promise<void> {
        if (isNullOrUndefined(error)) {
            return Promise.resolve();
        }

        this.props._dependencies.notificationService.error(error.localizedError ?? error.name);
        return Promise.resolve();
    }

    public render() {
        return (
            <PatientCareActivitiesMasterDetailPanelView
                title={this.props.title}
                availableStepTypes={this.availableStepTypes}
                activeTabName={this.activeTabName}
                setActiveTabName={this.setActiveTabName}
                setActiveExtensionTabName={this.setActiveExtensionTabName}
                isDetailLoading={this.isDetailLoading}

                selectedCareActivity={this.selectedCareActivity}
                selectCareActivityAsync={this.selectCareActivityAsync}
                showPrimaryDocumentAsync={this.showPrimaryDocumentAsync}
                resetSelectedCareActivity={this.resetSelectedCareActivity}

                dataSource={this.dataSource}

                isUnauthorizedAccess={this.initialLoadPanelAsync.isUnauthorizedAccess}

                isFilterOpen={this.isFilterOpen}
                clearFilters={this.clearFilters}
                openOrCloseFilters={this.openOrCloseFilters}
                patientId={this.props.patientId}

                popperReferenceElement={this.popperReferenceElement}
                tooltipContent={this.tooltipContent}
                isTooltipOpen={this.isTooltipOpen}
                hasScroller

                selectedDocumentId={this.selectedDocumentId}
                onSelectedDocumentIdChange={this.setSelectedDocumentId}
            />
        );
    }
}

export default connect(
    PatientCareActivitiesMasterDetailPanel,
    new FormExtensionRegistryAdapter("PatientCareActivities"),
    new DependencyAdapter<IPatientCareActivitiesMasterDetailPanelProps, IPatientCareActivitiesMasterDetailPanelDependencies>(container => {
        return {
            patientsCareActivitiesApiAdapter: container.resolve("PatientsCareActivitiesApiAdapter"),
            careActivityApiAdapter: container.resolve("CareActivityApiAdapter2"),
            careActivityBaseDataApiAdapter: container.resolve("CareActivityBaseDataApiAdapter"),
            performedServicesApiAdapter: container.resolve("PerformedServicesApiAdapter"),
            careDocumentApiAdapter: container.resolve("CareActivityDocumentApiAdapter"),
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            userContext: container.resolve("UserContext"),
            financeReferenceDataStore: container.resolve("FinanceReferenceDataStore"),
            patientApiAdapter: container.resolve("PatientApiAdapter2"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            authorizationService: container.resolve("AuthorizationService"),
            patientCareActivitiesTabRegistry: container.resolve("IPatientCareActivitiesTabRegistry"),
            notificationService: container.resolve("INotificationService")
        };
    })
);