import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import DocumentState from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentState.g";

interface IDocumentStateMultiSelectProps extends ISelectBoxBaseProps {
    value?: DocumentState | DocumentState[];
    onChange?: (newValue: DocumentState | DocumentState[]) => void;
}


const DocumentStateSelectBox: React.FC<IDocumentStateMultiSelectProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"DocumentState"}
        enumOrigin="server"
        enumType={DocumentState}
        displayMode="selectBox"
    />
);

export default DocumentStateSelectBox;