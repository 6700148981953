import Di from "@Di";
import IContainerService, { ServiceIdentifier } from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";
import IFormLogicRegistry, { IFormLogicExecutor } from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";
import FormLogicExecutor from "@HisPlatform/Services/Implementation/FormLogicRegistry/FormLogicExecutor";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";

@Di.injectable()
export default class FormLogicRegistry implements IFormLogicRegistry {
    private readonly formLogicMap = new Map<string, ServiceIdentifier[]>();

    constructor(
        @Di.inject("IContainerService") private readonly containerService: IContainerService
    ) { }

    public register(formDefinitionName: string, formLogicDiIdentifier: ServiceIdentifier) {
        if (this.formLogicMap.has(formDefinitionName)) {
            this.formLogicMap.get(formDefinitionName).push(formLogicDiIdentifier);
        } else {
            this.formLogicMap.set(formDefinitionName, [formLogicDiIdentifier]);
        }
    }

    public getAll(formName: string): IFormLogic[] {
        const formLogics = this.formLogicMap.get(formName);
        return formLogics?.map(e => this.containerService.resolve<IFormLogic>(e)) ?? [];
    }

    public get(formName: string, formLogicDiIdentifier: ServiceIdentifier): IFormLogic {
        const formLogics = this.formLogicMap.get(formName);
        const formLogic = formLogics?.find(flDiIdentifier => flDiIdentifier === formLogicDiIdentifier);
        return this.containerService.resolve<IFormLogic>(formLogic);
    }

    public getExecutorForForm(
        form: IForm,
        showScreenAction: ShowScreenFrontendActionBase,
        formName: string,
        formDefinition: IFormDefinition,
        onLogicStarted?: () => void,
        onLogicFinished?: () => void): IFormLogicExecutor {
        return new FormLogicExecutor(
            form, 
            showScreenAction, 
            this.getAll(formName), 
            formDefinition.rootSchema.getLatestVersionOrNull().dataElements,
            onLogicStarted,
            onLogicFinished);
    }
}