import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiCovidVaccinationsListPanelView from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidVaccinationsListPanel/EhiCovidVaccinationsListPanelView";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import EhiCareServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareServiceFactory";
import IEhiError from "@HunEHealthInfrastructurePlugin/Common/Model/IEhiError";
import IEhiCovidVaccinationListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IEhiCovidVaccinationListItem";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";
import StaticHunEHealthInfrastructureWebAppResources from "@HunEHealthInfrastructurePlugin/StaticResources/StaticHunEHealthInfrastructureWebAppResources";
import EhiServiceCallStatus from "@HunEHealthInfrastructurePlugin/Model/EhiServiceCallStatus";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import EhiHealthcareProviderId from "@Primitives/EhiHealthcareProviderId.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import EhiHealthcareProviderOrganizationUnitId from "@Primitives/EhiHealthcareProviderOrganizationUnitId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";

interface IEhiCovidVaccinationsListPanelProps {
    _dependencies?: IEhiCovidVaccinationsListPanelDependencies;
    routingController: IRoutingController<any>;
}

interface IEhiCovidVaccinationsListPanelDependencies {
    ehiCareServiceFactory: EhiCareServiceFactory;
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    localizationService: ILocalizationService;
    notificationService: INotificationService;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

@State.observer
class EhiCovidVaccinationsListPanel extends React.Component<IEhiCovidVaccinationsListPanelProps> {
    
    private get ehiCareServiceFactory() { return this.props._dependencies!.ehiCareServiceFactory; }
    private get hunEhiCareReferenceDataStore() { return this.props._dependencies!.hunEhiCareReferenceDataStore; }
    private get localizationService() { return this.props._dependencies!.localizationService; }
    private get notificationService() { return this.props._dependencies!.notificationService; }
    private get organizationReferenceDataStore() { return this.props._dependencies!.organizationReferenceDataStore; }
    
    private readonly ehiCareService = this.ehiCareServiceFactory.getEhiCareService();
    
    private extraFilterValidator = new ClientSideValidator({
        ExtraFilter: {
            SelectedDate: {
                isRequired: true
            },
            SelectedPointOfCare: {
                isRequired: true
            }
        }
    }, {shouldBeFilled: StaticHunEHealthInfrastructureWebAppResources.Validator.ShouldBeFilled}, true);

    @State.observable.ref private dataSource = new InMemoryDataGridDataSource<IEhiCovidVaccinationListItem>(() => this.listItems);
    
    @State.observable.ref private listItems: IEhiCovidVaccinationListItem[] = [];
    @State.observable.ref private ehiErrors: IEhiError[] = [];
    @State.observable.ref private selectedDate: LocalDate = LocalDate.today();
    @State.observable.ref private selectedPointOfCareId: PointOfCareId = null;
    @State.observable.ref private documentContent: Uint8Array = null;
    @State.observable private isLoading: boolean = false;
    
    @State.action.bound
    private setListItems(newValue: IEhiCovidVaccinationListItem[]) {
        this.listItems = newValue;
    }
        
    @State.action.bound
    private setEhiErrors(newValue: IEhiError[]) {
        this.ehiErrors = newValue;
    }
    
    @State.action.bound
    private setSelectedDate(newValue: LocalDate) {
        this.selectedDate = newValue;
    }
    
    @State.action.bound
    private setSelectedPointOfCareId(newValue: PointOfCareId) {
        this.selectedPointOfCareId = newValue;
    }

    @State.action.bound
    private setDocumentContent(newValue: Uint8Array) {
        this.documentContent = newValue;
    }

    @State.action.bound
    private unsetDocumentContent() {
        this.documentContent = null;
    }
    
    @State.boundLoadingState("isLoading")
    private async getCovidVaccinationsAsync() {
        if (this.extraFilterValidator.isValid()) {
            const ehiServiceCallResult = await this.ehiCareService.getEhiCovidVaccinationEventEntriesAsync(this.selectedDate, this.selectedPointOfCareId);
            
            if (ehiServiceCallResult?.ehiServiceCallStatus === EhiServiceCallStatus.Success &&
                ehiServiceCallResult.result?.operationWasSuccessful &&
                ehiServiceCallResult.result.value) {
                const ehiResult = ehiServiceCallResult.result.value;

                if (ehiResult.value) {
                    const listItems = ehiResult.value;

                    if (listItems.length) {
                        await this.loadReferenceDataAsync(listItems);
                    }

                    this.setListItems(ehiResult.value);
                }
                
                if (ehiResult.errors) {
                    this.setEhiErrors(ehiResult.errors);
                }
                
            } else {
                this.notificationService.error(StaticHunEHealthInfrastructureCareResources.EhiCovidVaccinationList.UnableToLoadList);
                if (ehiServiceCallResult?.result?.value?.errors?.length) {
                    this.setEhiErrors(ehiServiceCallResult.result.value.errors);
                }
            }
        }
    }

    @State.bound
    private async loadReferenceDataAsync(listItems: IEhiCovidVaccinationListItem[]) {
        const careOrganizationUnitIds: EhiHealthcareProviderOrganizationUnitId[] = [];
        const careOrganizationEntityVersionSelectors: Array<IEntityVersionSelector<EhiHealthcareProviderId>> = [];
        const practitionerIds: PractitionerId[] = [];

        for (const listItem of listItems) {
            if (listItem.careOrganizationUnitId) {
                careOrganizationUnitIds.push(listItem.careOrganizationUnitId);
            }
            if (listItem.careOrganizationId) {
                careOrganizationEntityVersionSelectors.push({
                    id: listItem.careOrganizationId,
                    validOn: this.selectedDate
                });
            }
            if (listItem.careDoctorId) {
                practitionerIds.push(listItem.careDoctorId);
            }
        }

        await Promise.all([
            this.hunEhiCareReferenceDataStore.ehiHealthcareProviderOrganizationUnits.ensureLoadedAsync(careOrganizationUnitIds),
            this.hunEhiCareReferenceDataStore.ehiHealthcareProviders.ensureLoadedAsync(careOrganizationEntityVersionSelectors),
            this.organizationReferenceDataStore.practitionerMap.ensureLoadedAsync(practitionerIds)
        ]);
    }

    @State.boundLoadingState("isLoading")
    private async onShowDocumentAsync(row: IEhiCovidVaccinationListItem) {
        if (this.extraFilterValidator.isValid()) {
            const ehiServiceCallResult = await this.ehiCareService.loadEhiCareDocumentWithSpecifiedPointOfCareIdAsync(
                row.ehiDocumentIdentifier,
                this.selectedPointOfCareId,
                false,
                ""
            );

            if (ehiServiceCallResult?.ehiServiceCallStatus === EhiServiceCallStatus.Success &&
                ehiServiceCallResult.result?.operationWasSuccessful &&
                ehiServiceCallResult.result.value) {
                const ehiResult = ehiServiceCallResult.result.value;

                if (ehiResult.value) {
                    this.setDocumentContent(ehiResult.value);
                }
                if (ehiResult.errors) {
                    this.setEhiErrors(ehiResult.errors);
                }
            } else {
                this.notificationService.error(StaticHunEHealthInfrastructureWebAppResources.UnableToLoadDocument);
                if (ehiServiceCallResult?.result?.value?.errors?.length) {
                    this.setEhiErrors(ehiServiceCallResult.result.value.errors);
                }
            }
        }
    }
    
    @State.bound
    private onBack() {
        this.props.routingController.goBack();
    }

    public render() {
        return (
            <EhiCovidVaccinationsListPanelView 
                ehiErrors={this.ehiErrors}
                dataSource={this.dataSource}
                selectedDate={this.selectedDate}
                onSelectedDateChange={this.setSelectedDate}
                selectedPointOfCareId={this.selectedPointOfCareId}
                onSelectedPointOfCareIdChange={this.setSelectedPointOfCareId}
                isLoading={this.isLoading}
                onQueryButtonClickedAsync={this.getCovidVaccinationsAsync}
                onBack={this.onBack}
                extraFilterValidator={this.extraFilterValidator}
                localizationService={this.localizationService}
                documentContent={this.documentContent}
                unsetDocumentContent={this.unsetDocumentContent}
                onShowDocumentAsync={this.onShowDocumentAsync}
            />
        );
    }
}

export default connect(
    EhiCovidVaccinationsListPanel,
    new DependencyAdapter<IEhiCovidVaccinationsListPanelProps, IEhiCovidVaccinationsListPanelDependencies>(c => ({
        ehiCareServiceFactory: c.resolve("EhiCareServiceFactory"),
        hunEhiCareReferenceDataStore: c.resolve("HunEhiCareReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService"),
        notificationService: c.resolve("INotificationService"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore")
    }))
);