import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import IPatientsCareActivityListWarningExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientsCareActivityListWarningExtensionPointProps";

@Di.injectable()
export default class PatientsCareActivityListWarningExtensionComponentService implements IExtensionComponentService<IPatientsCareActivityListWarningExtensionPointProps> {

    public isVisible(props: IPatientsCareActivityListWarningExtensionPointProps) {
        return props.careActivitySource === null || (props.careActivitySource && props.careActivitySource.value === "HunEHealthInfrastructure");
    }

    public shouldRerunIsVisible(props: IPatientsCareActivityListWarningExtensionPointProps, prevProps: IPatientsCareActivityListWarningExtensionPointProps) {
        return props.careActivitySource?.value !== prevProps?.careActivitySource?.value || props.isFilterOpen !== prevProps?.isFilterOpen;
    }
}