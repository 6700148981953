import State from "@Toolkit/ReactClient/Common/StateManaging";
import ApiBusinessError from "@Toolkit/CommonWeb/ApiAdapter/ApiBusinessError";
import UnauthorizedOperationBusinessError from "@Toolkit/CommonWeb/Model/UnauthorizedOperationBusinessError";

type permissionToCheck = (() => Promise<any>) | boolean;

export function createInitialPanelLoader<TArgs extends any[]>(loadAsync: (...args: TArgs) => Promise<void>, ...permissionChecksAsync: permissionToCheck[]) {

    const isUnauthorizedAccess = State.observable.box(false);

    const loaderFunctionAsync = async (...args: TArgs) => {
        try {
            for (const permissionCheck of permissionChecksAsync) {
                if (typeof (permissionCheck) === "boolean") {
                    if (permissionCheck === true) {
                        State.runInAction(() => {
                            isUnauthorizedAccess.set(true);
                        });
                        return;
                    }
                } else {
                    await permissionCheck();
                }
            }
            await loadAsync(...args);

        } catch (err) {
            if (err instanceof ApiBusinessError && err.error instanceof UnauthorizedOperationBusinessError) {
                State.runInAction(() => {
                    isUnauthorizedAccess.set(true);
                });
            } else {
                throw err;
            }
        }
    };

    Object.defineProperty(loaderFunctionAsync, "isUnauthorizedAccess", {
        enumerable: true,
        get: () => isUnauthorizedAccess.get()
    });

    return loaderFunctionAsync as {
        (...args: TArgs): Promise<void>;
        readonly isUnauthorizedAccess: boolean;
    };
}