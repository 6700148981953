import DiagnosisStore from "./DiagnosisStore";
import CareActivityId from "@Primitives/CareActivityId.g";
import LockingEntityStoreBase from "@Toolkit/CommonWeb/Model/LockingEntityStoreBase";
import _ from "@HisPlatform/Common/Lodash";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

export default class DiagnosisListStore extends LockingEntityStoreBase<CareActivityId> implements IDirtyChecked {

    private _dirtyChecker: StoreDirtyChecker<DiagnosisListStore> = new StoreDirtyChecker<DiagnosisListStore>();

    @State.bound
    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    @State.bound
    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }

    public propertyNamesExcludedFromDirtyCheck: string[] = ["_dirtyChecker"];

    @State.observable.ref public diagnoses: DiagnosisStore[] = [];

    @State.observable public validated: boolean = false;

    @State.action.bound
    public addDiagnosis(newDiagnosis: DiagnosisStore) {
        const clone = _.cloneDeep(newDiagnosis);
        clone.conditionVersionSelector.validOn = LocalDate.createFromMoment(DateTimeService.now());
        this.diagnoses = this.diagnoses.concat([clone]);
    }

    @State.action.bound
    public removeDiagnosis(removedDiagnosis: DiagnosisStore) {
        this.diagnoses = this.diagnoses.filter(r => r !== removedDiagnosis);
    }
}