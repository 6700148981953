import React from "react";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import EhiUserContext from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiUserContext";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import StaticHunEHealthInfrastructureUserManagementResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/StaticResources/StaticHunEHealthInfrastructureUserManagementResources";
import config from "@Config";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

interface IEhiTokenCheckerDependencies {
    dialogService: IDialogService;
    ehiUserContext: EhiUserContext;
}

interface IEhiTokenCheckerProps {
    _dependencies?: IEhiTokenCheckerDependencies;
    loginAsync?: () => Promise<void>;
}

@State.observer
class EhiTokenChecker extends React.Component<IEhiTokenCheckerProps> {

    private tokenCheckerInterval: NodeJS.Timeout = null;

    private get ehiUserContext() { return this.props._dependencies.ehiUserContext; }
    private get dialogService() { return this.props._dependencies.dialogService; }

    private isRunning = false;

    public componentDidMount() {
        dispatchAsyncErrors(this.checkTokenExpirationAsync(), this);
        this.tokenCheckerInterval = setInterval(() => {
            dispatchAsyncErrors(this.checkTokenExpirationAsync(), this);
        }, config.ehiTokenChecker.intervalInMs);
    }

    public componentWillUnmount() {
        clearInterval(this.tokenCheckerInterval);
    }

    @State.bound
    private async checkTokenExpirationAsync() {
        if (this.isRunning) {
            return;
        }
        try {
            this.isRunning = true;
            const now = DateTimeService.now();
            const specifiedTimeBeforeExpiration = now.isAfter(this.ehiUserContext.token.validTo.clone().add(config.ehiTokenChecker.minsBeforeExpiration, "minutes"));
            const expired = this.ehiUserContext.token.validTo.isBefore(now);
            if (specifiedTimeBeforeExpiration || expired) {
                const dialogResult = await this.dialogService.yesNo(
                    StaticHunEHealthInfrastructureUserManagementResources.TokenChecker.Title,
                    StaticHunEHealthInfrastructureUserManagementResources.TokenChecker.Message);
                if (dialogResult.resultCode === DialogResultCode.Yes) {
                    await this.props.loginAsync();
                } else {
                    this.ehiUserContext.clearToken();
                }
            }
        } finally {
            this.isRunning = false;
        }
    }

    public render() {
        return (
            <></>
        );
    }

}

export default connect(
    EhiTokenChecker,
    new DependencyAdapter<IEhiTokenCheckerProps, IEhiTokenCheckerDependencies>(c => ({
        ehiUserContext: c.resolve("EhiUserContext"),
        dialogService: c.resolve("IDialogService")
    }))
);