import * as React from "react";
import ReactDOM from "react-dom";
import PopperJS from "popper.js";
import { ICommonControlProps, getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";

interface IPopupProps extends ICommonControlProps {
    position?:
    "top" | "right" | "bottom" | "left" |
    "top-start" | "right-start" | "bottom-start" | "left-start" |
    "top-end" | "right-end" | "bottom-end" | "left-end";

    popperModifiers?: any;
    parentId?: string;
}

export default class Popup extends React.PureComponent<IPopupProps, undefined> {

    private popper: PopperJS = null;

    private initPopper(parent: Element, target: Element) {
        
        this.popper = new PopperJS(parent, target, {
            placement: this.props.position,
            modifiers: this.props.popperModifiers,
        } as PopperJS.PopperOptions);
    }

    public componentDidMount() {

        const target = ReactDOM.findDOMNode(this) as Element;
        const parent = this.props.parentId ? document.getElementById(this.props.parentId) : target.parentElement;

        this.initPopper(
            parent,
            target
        );
    }

    public componentDidUpdate() {
        if (this.popper) {
            this.popper.update();
        }
    }

    public componentWillUnmount() {
        if (this.popper) {
            this.popper.destroy();
        }
    }

    public render() {
        const htmlProps = getStandardHtmlProps(this.props);
        return (
            <div {...htmlProps}>{this.props.children}</div>
        );
    }
}