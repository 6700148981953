import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import ITherapyRecommendationRouteParams from "@HunEHealthInfrastructurePlugin/UseCases/CareUseCase/TherapyRecommendation/ITherapyRecommendationRouteParams";
import { createNestedRoutes } from "@Toolkit/ReactClient/Routing/CreateNestedRoutes";
import IPatientBloodTypeRouteParams from "@HunEHealthInfrastructurePlugin/UseCases/CareUseCase/PatientBloodType/IPatientBloodTypeRouteParams";
import IPatientRouteParams from "@HisPlatform/Application/Routes/IPatientRouteParams";
import IRatFormPatientBasedRouteParams from "@HunEHealthInfrastructurePlugin/UseCases/CareUseCase/RatForm/IRatFormRouteParams";


const HunPatientRoutes = createNestedRoutes(ApplicationRoutes.patient, {
    therapyRecommendation: new RouteDefinition<ITherapyRecommendationRouteParams>("/therapyRecommendation/:useCase?"),
    patientBloodType: new RouteDefinition<IPatientBloodTypeRouteParams>("/patientBloodType/:useCase?"),
    ehiCovidTestResults: new RouteDefinition<IPatientRouteParams>("/ehiCovidTestResults"),
    ratFormPatientBased: new RouteDefinition<IRatFormPatientBasedRouteParams>("/ratForm/:selectedId?/:useCase?")
});

export default HunPatientRoutes;