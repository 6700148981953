import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalConditionHistoryItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemBase";
import PregnancyOutcomeAssignmentBase from "./PregnancyOutcomeAssignmentBase";
import CustomPregnancyOutcomeAssignment from "./CustomPregnancyOutcomeAssignment";
import ReferencedPregnancyOutcomeAssignment from "./ReferencedPregnancyOutcomeAssignment";
import { CategoryObservationCodeSelectorItem } from "@HisPlatformControls/CategoryObservationCodeSelector/CategoryObservationCodeSelectorItem";

export default class PregnancyStatementHistoryItem extends MedicalConditionHistoryItemBase {
    @State.observable.ref public pregnancyOutcomeAssignment: PregnancyOutcomeAssignmentBase;

    @State.computed public get pregnancyOutcomeCodeSelectorItem() {
        if (!this.pregnancyOutcomeAssignment) {
            return new CategoryObservationCodeSelectorItem({name: ""});
        }
        if (this.pregnancyOutcomeAssignment instanceof ReferencedPregnancyOutcomeAssignment) {
            return new CategoryObservationCodeSelectorItem({ code: this.pregnancyOutcomeAssignment.categoryObservation?.singleCategory?.key, name: this.pregnancyOutcomeAssignment.categoryObservation?.singleCategory?.value });
        } else if (this.pregnancyOutcomeAssignment instanceof CustomPregnancyOutcomeAssignment) {
            return new CategoryObservationCodeSelectorItem({ name: this.pregnancyOutcomeAssignment.textualObservation.contentStore.getContent()});
        }
        return null;
    }   

    @State.action.bound
    public setPregnancyOutcomeAssignment(newValue: PregnancyOutcomeAssignmentBase) {
        this.pregnancyOutcomeAssignment = newValue;
    }
}
