import FormEditorAction from "@HisPlatform/BoundedContexts/FormEngine/Api/FormEditing/Enum/FormEditorAction.g";
import FormPropertyChange from "@HisPlatform/BoundedContexts/FormEngine/Api/FormEditing/Enum/FormPropertyChange.g";

export default class FormEditorChange {

    constructor(
        public dataReference: string,
        public action: FormEditorAction,
        public propertyChanged?: FormPropertyChange) {
    }
}