import HisDashboardCore from "@HisPlatformControls/HisDashboard/HisDashboardCore";
import { ISidebarPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import React from "react";

export default function createHisDashboardScreenSidebar(useCaseIdentifier: string) {
    return function (props: ISidebarPropsBase) {
        return (
            <HisDashboardCore
                {...props}
                currentUseCaseIdentifier={useCaseIdentifier}
            />
        );
    };
}