import State from "@Toolkit/ReactClient/Common/StateManaging";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import IMedicalServiceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalServiceVersion";
import IMedicalServicePanel from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServices/IMedicalServicePanel";
import IMedicalServiceCategory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalServiceCategory";

export type RequestedServiceItemBehavior = "normal" | "panel-bound" | "weak-panel-bound";

export default class RequestedServiceItem {
    @State.observable.ref public selectionIndex: number = -1;
    public behavior: RequestedServiceItemBehavior = "normal";

    constructor(
        public readonly store: RequestedServiceStore,
        public readonly medicalService: IMedicalServiceVersion,
        public readonly connectedServices: IMedicalServiceVersion[],
        public readonly category: IMedicalServiceCategory,
        public readonly panel: IMedicalServicePanel,
        public readonly isDisabledByGenderDependence: boolean,
        selectionIndex: number,
    ) {
        this.selectionIndex = selectionIndex;
    }

    @State.action.bound
    public setSelectionIndex(selectionIndex: number) {
        this.selectionIndex = selectionIndex;
    }

    @State.computed
    public get isSelected() {
        return this.selectionIndex !== -1;
    }
}