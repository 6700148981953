import React, { useMemo } from "react";
import _ from "@HisPlatform/Common/Lodash";
import { IDataGridColumnChildProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import ActionPlacement from "@Primitives/ActionPlacement";
import NDataPrimaryActionButton from "./NDataPrimaryActionButton";
import INDataRow from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataRow";
import INDataAction from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataAction";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import NDataRowFrontendActionButton from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/NDataRowFrontendActionButton";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import IListItemActionPresentation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/FrontendActions/IListItemActionPresentation";
import ListItemActionPlacement from "@HisPlatform/BoundedContexts/WebAppBackend/Api/FrontendLists/Enum/ListItemActionPlacement.g";
import WorklistActionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistActionType.g";

interface INDataRowActions extends IDataGridColumnChildProps<INDataRow> {
    _activityRegistry?: IActivityRegistry;
    currentBatchActivityReference: string;
    onActionAsync: (rowId: string, actionToken: string, argumentItems: { useCaseDisplayMode?: UseCaseDisplayMode }, worklistActionType: WorklistActionType, activityReference: string, clientSideAction?: ClientSideActionDto) => Promise<void>;
}


const NDataRowActions: React.FC<INDataRowActions> = props => {
    const orderedPrimaryActions = useMemo(
        () => _.sortBy(props.row.__actions?.filter(val => val.placement === ActionPlacement.Primary || val.placement === ActionPlacement.Both) ?? [], (t: INDataAction) => t.order),
        [props.row.__actions]
    );

    const orderedPrimaryFrontendActions = useMemo(
        () => _.sortBy(props.row.__actionDescriptors?.filter(val =>
            val.presentation.placement === ListItemActionPlacement.InRow ||
            val.presentation.placement === ListItemActionPlacement.InRowAndContextMenu
        ) ?? [], (t: ActionDescriptor<IListItemActionPresentation>) => t.presentation.order),
        [props.row.__actionDescriptors]
    );

    return (
        <div style={{ paddingLeft: 1 }}>
            {orderedPrimaryActions?.map(it => (
                <NDataPrimaryActionButton
                    _activityRegistry={props._activityRegistry}
                    currentBatchActivityReference={props.currentBatchActivityReference}
                    disabled={!it.isEnabled}
                    key={it.titleResourceId.value}
                    titleResourceId={it.titleResourceId}
                    clientSideAction={it.clientSideAction}
                    commandToken={it.commandToken}
                    onActionAsync={props.onActionAsync}
                    activityReference={it.activityReference}
                    useCaseDisplayMode={it.useCaseDisplayMode}
                    cellMode
                    rowId={props.row.__id}
                    worklistActionType={it.worklistActionType}
                />
            ))}
            {orderedPrimaryFrontendActions?.map((desc, idx) => (
                <NDataRowFrontendActionButton
                    key={`${desc.action.id.value}_${idx}`}
                    descriptor={desc}
                    automationId={desc.action.id.value}
                    rowId={props.row.__id}
                />
            ))}
        </div>
    );
};

export default NDataRowActions;
