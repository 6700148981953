import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import FrontendActionId from "@Toolkit/ReactClient/ActionProcessing/FrontendActionId";

export default class UnknownFrontendAction extends FrontendActionBase {
    public static id = new FrontendActionId("Toolkit.UnknownFrontendAction");

    constructor(
        public readonly unknownType: string,
    ) {
        super(UnknownFrontendAction.id);
    }
}