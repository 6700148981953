
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import FormLogicEditorDialogStore from "./FormLogicEditorDialogStore";

const { 
    ContextComponent: FormLogicEditorDialogStoreContext, 
    StoreProvider: FormLogicEditorDialogStoreProvider, 
    useStore: useFormLogicEditorDialogStore,
    provideStore: provideFormLogicEditorDialogStore,
} = createPanelStoreProvider(FormLogicEditorDialogStore);

export {
    FormLogicEditorDialogStoreContext,
    FormLogicEditorDialogStoreProvider,
    useFormLogicEditorDialogStore,
    provideFormLogicEditorDialogStore
};