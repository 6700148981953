import NDataScreen from "@HisPlatform/BoundedContexts/Productivity/Components/NDataScreen/NDataScreen";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import React, { useCallback } from "react";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import CustomerListScreenApiAdapter from "./CustomerListScreenApiAdapter";
import ShowCustomerListScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowCustomerListScreenAction.g";

export default function CustomerListScreen(props: IScreenPropsBase<ShowCustomerListScreenAction>) {

    const { customerListScreenApiAdapter } = useDependencies(c => ({
        customerListScreenApiAdapter: c.resolve<CustomerListScreenApiAdapter>("CustomerListScreenApiAdapter")
    }));

    const getDynamicListAsync = useCallback(async (args: FrontendListParameters) => {
        const response = await customerListScreenApiAdapter.getCustomerListAsync(args);
        return response.result;
    }, []);

    return (
        <NDataScreen
            onGetDynamicListAsync={getDynamicListAsync}
            hasPrintButton={false}
            hasRefreshButton={false}
        />
    );
}
