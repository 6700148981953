import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import {
    IDataGridColumnBaseProps, IDataGridColumnFilterProps,
    IDataGridFilterValueSerializer
} from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import React from "react";
import AppointmentInvalidationReason from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentInvalidationReason.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import AppointmentScheduleDefinitionId from "@Primitives/AppointmentScheduleDefinitionId.g";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import AppointmentScheduleDefinitionSelectBox from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentScheduleDefinitionSelectBox/AppointmentScheduleDefinitionSelectBox";

interface IAppointmentScheduleDefinitionColumnDependencies {
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
}

interface IAppointmentScheduleDefinitionColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IAppointmentScheduleDefinitionColumnDependencies;
}

class AppointmentScheduleDefinitionColumn extends React.Component<IAppointmentScheduleDefinitionColumnProps> {
    private filterValueSerializer: IDataGridFilterValueSerializer<AppointmentInvalidationReason | AppointmentInvalidationReason[]> = {
        serialize: value => JSON.stringify(value),
        deserialize: value => JSON.parse(value)
    };

    @State.bound
    private filterRenderer(filterProps: IDataGridColumnFilterProps<AppointmentScheduleDefinitionId | AppointmentScheduleDefinitionId[]>) {
        return (
            <AppointmentScheduleDefinitionSelectBox
                {...filterProps}
                maxMenuWidth="300px"
                hoverOnlyIndicatorIcons
            />
        );
    }

    @State.bound
    private renderValue(value: AppointmentScheduleDefinitionId) {
        const definition = this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleDefinitions.get(value);
        return definition?.name;
    }

    public render() {
        const {children, ...columnProps} = this.props;
        return (
            <DataGridColumn
                {...columnProps}
                onRenderFilter={this.filterRenderer}
                filterValueSerializer={this.filterValueSerializer}
                onRenderCellValue={this.renderValue}
            />
        );
    }
}

export default connect(
    AppointmentScheduleDefinitionColumn,
    new DependencyAdapter<IAppointmentScheduleDefinitionColumnProps, IAppointmentScheduleDefinitionColumnDependencies>(c => {
        return {
            schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore")
        };
    })
);
