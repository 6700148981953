import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import UrgencySelectBox from "@HisPlatformControls/UrgencySelectBox";
import UrgencyBadge from "@HisPlatformControls/UrgencyBadge";
import Urgency from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/Urgency.g";


interface IUrgencyColumnProps extends IDataGridColumnBaseProps {

}


const UrgencyColumn: React.FC<IUrgencyColumnProps> = props => {

    const { children, ...columnProps } = props;

    const valueRenderer = useCallback((value: Urgency) => {
        return <UrgencyBadge value={value} />;
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<Urgency | Urgency[]>) => {
        return <UrgencySelectBox {...filterProps} displayMode="selectBox" hoverOnlyIndicatorIcons />;
    }, []);

    return (
        <DataGridColumn
            width={50}
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
            showHint={false}
        />
    );
};
const filterValueSerializer: IDataGridFilterValueSerializer<Urgency | Urgency[]> = {
    serialize: value => JSON.stringify(value),
    deserialize: value => JSON.parse(value)
};

export default UrgencyColumn;
