import StatusChangeReasonTypeId from "@Primitives/StatusChangeReasonTypeId.g";
import StatusChangeReasonId from "@Primitives/StatusChangeReasonId.g";

export default class StatusChangeReason {
    constructor(
        public readonly id: StatusChangeReasonId,
        public readonly name: string,
        public readonly type: StatusChangeReasonTypeId,
        public readonly hasAdditionalText: boolean,
    ) { }
}