import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import EhiCovidStatusReportFilterStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidStatusReportWorklistPanel/EhiCovidStatusReportFilterStore";
import EhiCovidStatusReportWorklistPanelView from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidStatusReportWorklistPanel/EhiCovidStatusReportWorklistPanelView";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import HunEHealthWorklistApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/HunEHealthWorklistApiAdapter";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import CareActivityId from "@Primitives/CareActivityId.g";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import EhiCovidStatusReportId from "@Primitives/EhiCovidStatusReportId.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";

interface IEhiCovidStatusReportWorklistPanelDependencies {
    applicationContext: ApplicationContext;
    worklistApiAdapter: HunEHealthWorklistApiAdapter;
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
}

export interface IEhiCovidStatusReportWorklistPanelProps {
    _dependencies?: IEhiCovidStatusReportWorklistPanelDependencies;
    _careActivityId?: CareActivityId;
    onBack: () => void;
    useCaseState: INDataUseCaseState;
    selectedRowId: string;
    onChange: (selectedRowId: string, useCaseState: INDataUseCaseState) => void;
    onCloseDetail: () => void;
    onSelectedEhiCovidStatusReportIdChange: (ehiCovidStatusReportId: EhiCovidStatusReportId) => void;
    hideMasterToolbar: boolean;
}

/** @screen */
@State.observer
class EhiCovidStatusReportWorklistPanel extends React.Component<IEhiCovidStatusReportWorklistPanelProps> {

    @State.observable.ref private worklistDefinition: IWorklistDefinition = null;
    @State.observable.ref private filterStore: EhiCovidStatusReportFilterStore = null;

    private readonly refreshListEvent = new TypedAsyncEvent();

    @State.computed private get hasNewRow() { return this.props.selectedRowId === "new"; }

    public componentDidMount() {
        dispatchAsyncErrors(this.initializePanelAsync(), this);
    }

    @State.bound
    private async initializePanelAsync() {
        const [worklistDefinition] = await Promise.all(
            [
                this.props._dependencies.worklistApiAdapter.getEhiCovidStatusReportBoundWorklistDefinitionAsync()
            ]);

        State.runInAction(() => {
            this.worklistDefinition = worklistDefinition.value;
        });

        await this.props._dependencies.hunEhiCareReferenceDataStore.ehiCovidSymptomsSeverities.ensureLoadedAsync();
        await this.props._dependencies.hunEhiCareReferenceDataStore.ehiCovidVentilationStatuses.ensureLoadedAsync();
    }

    @State.action.bound
    private setPanelProps(useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) {
        return {
            onEhiCovidStatusReportCreatedAsync: this.ehiCovidStatusReportCreatedAsync,
            onDeleteEhiCovidStatusReportAsync: this.onClosedDetailAsync,
        };
    }

    @State.action.bound
    private async onClosedDetailAsync() {
        this.props.onCloseDetail();
        await this.refreshListEvent.emitAsync();
    }

    @State.action.bound
    private onFilterStoreCreatedAsync(filterStore: IFilterStore) {
        this.filterStore = new EhiCovidStatusReportFilterStore(filterStore);
        this.filterStore.setCareActivityId(this.props._careActivityId);
        this.filterStore.setIncludeDeleted(false);
        return Promise.resolve();
    }

    @State.bound
    private getExtendedFilterDescriptors() {
        return EhiCovidStatusReportFilterStore.getFilterDescriptors();
    }

    @State.action.bound
    private async ehiCovidStatusReportCreatedAsync(id: EhiCovidStatusReportId) {
        await this.refreshListEvent.emitAsync();
        this.props.onSelectedEhiCovidStatusReportIdChange?.(id);
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.initializePanelAsync);

    public render() {
        const title = StaticHunEHealthInfrastructureCareResources.EhiCovidStatusReportWorklistPanel.Title;

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={title} />;
        }

        return (
            <>
                <EhiCovidStatusReportWorklistPanelView
                    filterStore={this.filterStore}
                    worklistDefinition={this.worklistDefinition}
                    selectedRowId={this.props.selectedRowId}
                    useCaseState={this.props.useCaseState}
                    onChange={this.props.onChange}
                    onFilterStoreCreatedAsync={this.onFilterStoreCreatedAsync}
                    onGetExtendedFilterDescriptors={this.getExtendedFilterDescriptors}
                    onBack={this.props.onBack}
                    title={title}
                    hasNewRow={this.hasNewRow}
                    onGetPanelProps={this.setPanelProps}
                    refreshListEvent={this.refreshListEvent}
                    hideMasterToolbar={this.props.hideMasterToolbar}
                />
            </>
        );
    }
}

export default connect(
    withHisErrorBoundary(EhiCovidStatusReportWorklistPanel),
    new DependencyAdapter<IEhiCovidStatusReportWorklistPanelProps, IEhiCovidStatusReportWorklistPanelDependencies>(c => {
        return {
            applicationContext: c.resolve("ApplicationContext"),
            worklistApiAdapter: c.resolve("HunEHealthWorklistApiAdapter"),
            hunEhiCareReferenceDataStore: c.resolve("HunEhiCareReferenceDataStore"),
        };
    })
);