export default class InvalidationInfo {

    constructor(
        public appointmentScheduleDefinition?: string,
        public schedulingService?: string,
        public organizationUnit?: string,
        public practitioner?: string
    ) {

    }
}
