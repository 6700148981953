import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import CareActivityId from "@Primitives/CareActivityId.g";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";
import ServiceRequestCreationType from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestCreationType.g";
import ServiceRequestSubject from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ServiceRequestSubject";
import SchedulingServiceSubject from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubject";
import SlotStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SlotStore";
import UnregisteredPatientStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/UnregisteredPatientStore";
import ReferralStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/ReferralStore";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import SuspectedDiagnosis from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/SuspectedDiagnosis";
import { CareLocation } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/CareLocation";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import { mapToNameDto } from "@HisPlatform/Common/PersonNameMapper";
import TelecomTypeId from "@Primitives/TelecomTypeId.g";
import TelecomUseId from "@Primitives/TelecomUseId.g";

export function CreateAppointmentDto(appointment: Appointment, isValidateOnly: boolean) {
    return new Proxy.CreateAppointmentControllerDto({
        appointment: new Proxy.AppointmentCreationDto({
            appointmentScheduleSlotSeriesId: appointment.appointmentScheduleSlotSeriesId,
            subject: getSubject(appointment),
            slotsToBook: GetSlotsToBookDto(appointment.slotsToBook),
            participants: GetAppointmentParticipantDto(appointment),
            referral: mapReferral(appointment.referral, null),
            description: appointment.description
        }),
        isValidateOnly: isValidateOnly
    });
}

export function CreateServiceRequestWithAppointmentControllerDto(
    serviceRequest: ServiceRequestStore,
    appointment: Appointment,
    shouldSubmitServiceRequest: boolean,
    requestLock: boolean,
    isValidateOnly: boolean,
    isEmpty: boolean) {
    return new Proxy.CreateServiceRequestWithAppointmentControllerDto({
        serviceRequest: ServiceRequestCreationDto(serviceRequest, shouldSubmitServiceRequest, isEmpty),
        appointment: AppointmentCreationDto(appointment),
        requestLock: requestLock,
        isValidateOnly: isValidateOnly
    } as Proxy.ICreateServiceRequestWithAppointmentControllerDto);
}

export function AppointmentCreationDto(appointment: Appointment) {
    return new Proxy.AppointmentCreationDto({
        appointmentScheduleSlotSeriesId: appointment.appointmentScheduleSlotSeriesId,
        subject: getSubject(appointment),
        slotsToBook: GetSlotsToBookDto(appointment.slotsToBook),
        participants: GetAppointmentParticipantDto(appointment),
        referral: mapReferral(appointment.referral, null),
        description: appointment.description,
        episodeOfCareIds: appointment.episodeOfCareIds
    });
}

export function ModifyServiceRequestWithCreateAppointmentControllerDto(
    careActivityId: CareActivityId,
    serviceRequestStore: ServiceRequestStore,
    shouldSubmitServiceRequest: boolean,
    appointmentStore: Appointment,
    lockId: LockId,
    releaseLockIfSuccessful: boolean,
    isValidateOnly: boolean,
    extensionData?: any
) {
    return new Proxy.ModifyServiceRequestWithCreateAppointmentControllerDto({
        activeCareActivityId: careActivityId,
        serviceRequest: new Proxy.ServiceRequestModificationDto({
            clinicalQuestion: serviceRequestStore.clinicalQuestion,
            remark: serviceRequestStore.remark,
            requestedServices: getProxyRequestedServices(serviceRequestStore.requestedServices),
            requesterDoctorId: serviceRequestStore.requesterDoctorId,
            starterCareActivityId: serviceRequestStore.starterCareActivityId,
            suspectedDiagnosisList: getProxySuspectedDiganoses(serviceRequestStore.suspectedDiagnosisList),
            targetCareLocation: serviceRequestStore.targetCareLocation && getProxyCareLocationDto(serviceRequestStore.targetCareLocation),
            targetDoctorId: serviceRequestStore.targetDoctorId,
            shouldSubmitServiceRequest: shouldSubmitServiceRequest,
            rowVersion: serviceRequestStore.rowVersion,
            serviceRequestId: serviceRequestStore.id,
            extensionData: serviceRequestStore.extensionData,
            selectedMedicalServicePanelIds: serviceRequestStore.selectedMedicalServicePanelIds
        }),
        appointment: AppointmentCreationDto(appointmentStore),
        extensionData: extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful,
        isValidateOnly: isValidateOnly
    } as Proxy.IModifyServiceRequestWithCreateAppointmentControllerDto);
}

export function ModifyServiceRequestWithDeleteAppointmentControllerDto(
    careActivityId: CareActivityId,
    serviceRequestStore: ServiceRequestStore,
    shouldSubmitServiceRequest: boolean,
    appointmentScheduleEntryId: AppointmentScheduleEntryId,
    rowVersion: RowVersion,
    lockId: LockId,
    releaseLockIfSuccessful: boolean,
    isValidateOnly: boolean,
    extensionData?: any
) {
    return new Proxy.ModifyServiceRequestWithDeleteAppointmentControllerDto({
        activeCareActivityId: careActivityId,
        serviceRequest: new Proxy.ServiceRequestModificationDto({
            clinicalQuestion: serviceRequestStore.clinicalQuestion,
            remark: serviceRequestStore.remark,
            requestedServices: getProxyRequestedServices(serviceRequestStore.requestedServices),
            requesterDoctorId: serviceRequestStore.requesterDoctorId,
            starterCareActivityId: serviceRequestStore.starterCareActivityId,
            suspectedDiagnosisList: getProxySuspectedDiganoses(serviceRequestStore.suspectedDiagnosisList),
            targetCareLocation: serviceRequestStore.targetCareLocation && getProxyCareLocationDto(serviceRequestStore.targetCareLocation),
            targetDoctorId: serviceRequestStore.targetDoctorId,
            shouldSubmitServiceRequest: shouldSubmitServiceRequest,
            rowVersion: serviceRequestStore.rowVersion,
            serviceRequestId: serviceRequestStore.id,
            extensionData: serviceRequestStore.extensionData,
            selectedMedicalServicePanelIds: serviceRequestStore.selectedMedicalServicePanelIds
        }),
        appointmentScheduleEntryId: appointmentScheduleEntryId,
        rowVersion: rowVersion,
        extensionData: extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful,
        isValidateOnly: isValidateOnly
    } as Proxy.IModifyServiceRequestWithDeleteAppointmentControllerDto);
}

export function ModifyServiceRequestWithUpdateAppointmentControllerDto(
    careActivityId: CareActivityId,
    serviceRequestStore: ServiceRequestStore,
    shouldSubmitServiceRequest: boolean,
    appointmentScheduleEntryId: AppointmentScheduleEntryId,
    appointmentStore: Appointment,
    rowVersion: RowVersion,
    lockId: LockId,
    releaseLockIfSuccessful: boolean,
    isValidateOnly: boolean,
    extensionData?: any
) {
    return new Proxy.ModifyServiceRequestWithUpdateAppointmentControllerDto({
        activeCareActivityId: careActivityId,
        serviceRequest: new Proxy.ServiceRequestModificationDto({
            clinicalQuestion: serviceRequestStore.clinicalQuestion,
            remark: serviceRequestStore.remark,
            requestedServices: getProxyRequestedServices(serviceRequestStore.requestedServices),
            requesterDoctorId: serviceRequestStore.requesterDoctorId,
            starterCareActivityId: serviceRequestStore.starterCareActivityId,
            suspectedDiagnosisList: getProxySuspectedDiganoses(serviceRequestStore.suspectedDiagnosisList),
            targetCareLocation: serviceRequestStore.targetCareLocation && getProxyCareLocationDto(serviceRequestStore.targetCareLocation),
            targetDoctorId: serviceRequestStore.targetDoctorId,
            shouldSubmitServiceRequest: shouldSubmitServiceRequest,
            rowVersion: serviceRequestStore.rowVersion,
            serviceRequestId: serviceRequestStore.id,
            extensionData: serviceRequestStore.extensionData,
            selectedMedicalServicePanelIds: serviceRequestStore.selectedMedicalServicePanelIds
        }),
        appointmentScheduleEntryId: appointmentScheduleEntryId,
        appointment: AppointmentCreationDto(appointmentStore),
        rowVersion: rowVersion,
        extensionData: extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful,
        isValidateOnly: isValidateOnly
    } as Proxy.IModifyServiceRequestWithUpdateAppointmentControllerDto);
}

export function DeleteDraftServiceRequestWithAppointmentControllerDto(
    appointmentScheduleEntryId: AppointmentScheduleEntryId,
    appointmentRowVersion: RowVersion,
    serviceRequestId: ServiceRequestId,
    activeCareActivityId: CareActivityId,
    serviceRequestRowVersion: RowVersion,
    lockId: LockId,
    releaseLockIfSuccessful: boolean
) {
    return new Proxy.DeleteDraftServiceRequestWithAppointmentControllerDto({
        appointmentScheduleEntryId: appointmentScheduleEntryId,
        appointmentRowVersion: appointmentRowVersion,
        serviceRequestId: serviceRequestId,
        activeCareActivityId: activeCareActivityId,
        serviceRequestRowVersion: serviceRequestRowVersion,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getModifyServiceRequestCommandDto(store: ServiceRequestStore, shouldSubmitServiceRequest: boolean, careActivityId: CareActivityId, lockId: LockId, releaseLockIfSuccessful: boolean, emptyServiceRequest: boolean) {
    return new Proxy.ModifyServiceRequestControllerDto({
        activeCareActivityId: careActivityId,
        serviceRequest: new Proxy.ServiceRequestModificationDto({
            clinicalQuestion: store.clinicalQuestion,
            remark: store.remark,
            requestedServices: getProxyRequestedServices(store.requestedServices),
            requesterDoctorId: store.requesterDoctorId,
            starterCareActivityId: store.starterCareActivityId,
            suspectedDiagnosisList: getProxySuspectedDiganoses(store.suspectedDiagnosisList),
            targetCareLocation: store.targetCareLocation && getProxyCareLocationDto(store.targetCareLocation),
            targetDoctorId: store.targetDoctorId,
            shouldSubmitServiceRequest: shouldSubmitServiceRequest,
            rowVersion: store.rowVersion,
            serviceRequestId: store.id,
            extensionData: store.extensionData,
            expectedCompletionTime: emptyServiceRequest && store.expectedCompletionTime,
            priority: emptyServiceRequest && store.medicalServicePriority,
            selectedMedicalServicePanelIds: store.selectedMedicalServicePanelIds
        }),
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    } as Proxy.IModifyServiceRequestControllerDto);
}

export function getCreateServiceRequestCommandDto(store: ServiceRequestStore, shouldSubmitServiceRequest: boolean, requestLock: boolean, isValidateOnly: boolean, emptyServiceRequest: boolean) {
    return new Proxy.CreateServiceRequestControllerDto({
        serviceRequest: new Proxy.ServiceRequestCreationDto({
            clinicalQuestion: store.clinicalQuestion,
            remark: store.remark,
            requestedServices: getProxyRequestedServices(store.requestedServices),
            requesterDoctorId: store.requesterDoctorId,
            serviceRequestDefinitionId: store.serviceRequestDefinitionVersion && store.serviceRequestDefinitionVersion.id,
            starterCareActivityId: store.starterCareActivityId,
            suspectedDiagnosisList: getProxySuspectedDiganoses(store.suspectedDiagnosisList),
            targetCareLocation: store.targetCareLocation && getProxyCareLocationDto(store.targetCareLocation),
            targetDoctorId: store.targetDoctorId,
            shouldSubmitServiceRequest: shouldSubmitServiceRequest,
            direction: store.direction as unknown as ServiceRequestDirection,
            extensionData: store.extensionData,
            expectedCompletionTime: emptyServiceRequest && store.expectedCompletionTime,
            priority: emptyServiceRequest && store.medicalServicePriority,
            serviceRequestCreationType: emptyServiceRequest ? ServiceRequestCreationType.Empty : ServiceRequestCreationType.WithRequestedServices,
            selectedMedicalServicePanelIds: store.selectedMedicalServicePanelIds
        }),
        requestLock: requestLock,
        isValidateOnly: isValidateOnly
    } as Proxy.ICreateServiceRequestControllerDto);
}

function getSubject(appointment: Appointment) {
    let subject: Proxy.AppointmentSubjectBaseDto;
    if (appointment.subjectService instanceof ServiceRequestSubject) {
        subject = new Proxy.ServiceRequestSubjectDto({ serviceRequestId: appointment.subjectService.versionedId.id });
    } else if (appointment.subjectService instanceof SchedulingServiceSubject) {
        subject = new Proxy.SchedulingServiceSubjectDto({ schedulingServiceId: appointment.subjectService.schedulingServiceId });
    }
    return subject;
}

function GetSlotsToBookDto(usedSlotIds: SlotStore[]) {
    return usedSlotIds
        .map(s => new Proxy.AppointmentScheduleSlotBookingItemDto({
            id: s.id,
            rowVersion: s.rowVersion
        }));
}

function GetAppointmentParticipantDto(appointment: Appointment) {
    const result: Proxy.AppointmentParticipantDto[] = [];

    if (appointment.pointOfCareId && appointment.pointOfCareId.value) {
        result.push(new Proxy.LocationParticipantDto({
            isExternal: false,
            organizationUnitId: appointment.pointOfCareId
        }));
    }

    if (!!appointment?.practitionerIds?.length) {
        const dtos = appointment.practitionerIds
            .filter(p => !!p.value)
            .map(i => new Proxy.PractitionerParticipantDto({ practitionerId: i, isExternal: false }));
        result.push(...dtos);
    }

    if (!!appointment?.additionalPractitionerIds?.length) {
        const dtos = appointment.additionalPractitionerIds
            .filter(p => !!p.value)
            .map(i => new Proxy.PractitionerParticipantDto({ practitionerId: i, isExternal: true }));
        result.push(...dtos);
    }

    if (appointment.patientId?.value) {
        let patientParticipant: Proxy.AppointmentParticipantDto;
        if (appointment.patientId.value === "unregistered") {
            patientParticipant = GetUnregisteredPatientParticipantDto(appointment.unregisteredPatient);
        } else {
            patientParticipant = new Proxy.RegisteredPatientParticipantDto({

                patientId: appointment.patientId,
                patientName: null,
                isExternal: false
            });
        }

        result.push(patientParticipant);
    }
    return result;
}

function GetUnregisteredPatientParticipantDto(unregisteredPatientStore: UnregisteredPatientStore) {
    const telecoms = new Array<Proxy.TelecomDto>();

    if (!isNullOrEmptyString(unregisteredPatientStore?.phoneNumber)) {
        telecoms.push(getUnregisteredPatientTelecom(unregisteredPatientStore.phoneNumber, TelecomTypeId.Phone));
    }

    if (!isNullOrEmptyString(unregisteredPatientStore?.email)) {
        telecoms.push(getUnregisteredPatientTelecom(unregisteredPatientStore.email, TelecomTypeId.Email));
    }

    return new Proxy.UnregisteredPatientParticipantDto({
        name: mapToNameDto(Proxy.PersonName, unregisteredPatientStore?.nameStore),
        dateOfBirth: unregisteredPatientStore?.birthDate,
        telecoms: telecoms,
        gender: unregisteredPatientStore?.genderId,
        insuranceDocumentNumber: unregisteredPatientStore?.insuranceDocumentNumber,
        mailingAddress: unregisteredPatientStore?.address,
        note: unregisteredPatientStore?.note,
        isExternal: false
    });
}

function getUnregisteredPatientTelecom(value: string, typeId: TelecomTypeId) {
    return new Proxy.TelecomDto({
        telecomTypeId: typeId,
        telecomUseId: new TelecomUseId("Private"),
        value: value,
        isPrimary: false
    });
}

function mapReferral(store: ReferralStore, referralExtensionData: any) {
    return new Proxy.ReferralDto({
        referralCareIdentifier: store.referralCareIdentifier,
        referralCreationDate: store.referralCreationDate,
        referralDoctorId: store.referralDoctorId,
        referralWorkplace: createEntityVersionSelectorOfExternalLocationId(store.referralWorkplace, store.referralCreationDate),
        referralDiagnosisId1: store.referralDiagnosisId1,
        referralDiagnosisLateralityId1: store.referralDiagnosisLateralityId1,
        referralDiagnosisId2: store.referralDiagnosisId2,
        referralDiagnosisLateralityId2: store.referralDiagnosisLateralityId2,
        extensionData: referralExtensionData
    });
}

export function createEntityVersionSelectorOfExternalLocationId(referral: EntityVersionSelector<ExternalLocationId>, referralCreationDate: LocalDate) {
    let validOn: LocalDate;

    if (!referral?.id) {
        return null;
    }

    if (isNullOrUndefined(referral) || referral.validOn?.isEmpty() === true) {
        validOn = referralCreationDate ?? LocalDate.today();
    } else {
        validOn = referral.validOn;
    }

    const ret = new Proxy.EntityVersionSelectorOfExternalLocationId({
        entityId: referral?.id,
        validOn
    });

    return ret;
}

function ServiceRequestCreationDto(serviceRequest: ServiceRequestStore, shouldSubmitServiceRequest: boolean, emptyServiceRequest: boolean) {
    return new Proxy.ServiceRequestCreationDto({
        clinicalQuestion: serviceRequest.clinicalQuestion,
        remark: serviceRequest.remark,
        requestedServices: getProxyRequestedServices(serviceRequest.requestedServices),
        requesterDoctorId: serviceRequest.requesterDoctorId,
        serviceRequestDefinitionId: serviceRequest.serviceRequestDefinitionVersion && serviceRequest.serviceRequestDefinitionVersion.id,
        starterCareActivityId: serviceRequest.starterCareActivityId,
        suspectedDiagnosisList: getProxySuspectedDiganoses(serviceRequest.suspectedDiagnosisList),
        targetCareLocation: serviceRequest.targetCareLocation && getProxyCareLocationDto(serviceRequest.targetCareLocation),
        targetDoctorId: serviceRequest.targetDoctorId,
        shouldSubmitServiceRequest: shouldSubmitServiceRequest,
        direction: serviceRequest.direction as unknown as ServiceRequestDirection,
        expectedCompletionTime: emptyServiceRequest && serviceRequest.expectedCompletionTime,
        priority: emptyServiceRequest && serviceRequest.medicalServicePriority,
        serviceRequestCreationType: emptyServiceRequest ? ServiceRequestCreationType.Empty : ServiceRequestCreationType.WithRequestedServices,
        extensionData: serviceRequest.extensionData,
        selectedMedicalServicePanelIds: serviceRequest.selectedMedicalServicePanelIds
    });
}

function getProxyRequestedServices(items: RequestedServiceStore[]): Proxy.RequestedServiceCreationDto[] {
    return items.map(s => {
        return new Proxy.RequestedServiceCreationDto({
            serviceId: s.medicalServiceVersionSelector.id,
            expectedCompletionTime: s.expectedCompletionTime,
            lateralityId: s.lateralityId,
            priority: s.priority
        });
    });
}

function getProxySuspectedDiganoses(items: SuspectedDiagnosis[]): Proxy.SuspectedDiagnosisCreationDto[] {
    return items.map(d => {
        return new Proxy.SuspectedDiagnosisCreationDto({
            conditionId: d.conditionId,
            lateralityId: d.lateralityId
        });
    });
}

function getProxyCareLocationDto(careLocation: CareLocation): Proxy.CareLocationDto {
    if (careLocation instanceof ExternalCareLocation) {
        return new Proxy.ExternalCareLocationDto({

            externalLocationSelector: new Proxy.EntityVersionSelectorOfExternalLocationId({
                entityId: careLocation.externalLocationSelector.id,
                validOn: careLocation.externalLocationSelector.validOn
            })
        });
    } else if (careLocation instanceof InternalCareLocation) {
        return new Proxy.InternalCareLocationDto({
            pointOfCareId: careLocation.pointOfCareId
        });
    }
    throw new Error("Unknown CareLocation type.");
}