import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";

export function configureHisPlatformActivities(activityRegistry: IActivityRegistry) {

    activityRegistry.registerGroup({ name: "Worklist.Actions.Groups.EHR", iconName: "file_ecg" });

    activityRegistry.registerAll(
        { reference: "CareRegister_PatientArrived", iconName: "checkCircle" },
        { reference: "CareRegister_RevokePatientArrived", iconName: "circleArrowCcw" },
        { reference: "CareRegister_RevokeStartCare", iconName: "circleArrowCcw" },
        { reference: "CareRegister_RevokeDischargePatient", iconName: "circleArrowCcw" },
        { reference: "CareRegister_RevokeDidNotAttend", iconName: "circleArrowCcw" },
        { reference: "CareRegister_RevokeDelete", iconName: "circleArrowCcw" },
        { reference: "CareRegister_RevokeDeleteAdmission", iconName: "circleArrowCcw" },
        { reference: "CareRegister_ReopenCareActivity", iconName: "circleArrowCcw" },
        { reference: "CareRegister_CallPatient", iconName: "enterStrong" },
        { reference: "CareRegister_PatientDidNotAttend", iconName: "minusCircle" },
        { reference: "CareRegister_SuspendCareActivity", iconName: "pause" },
        { reference: "CareRegister_RecordDischargeData", iconName: "exit" },
        { reference: "CareRegister_RecordProvidedServices", iconName: "stethoscope" },
        { reference: "CareRegister_DischargePatient", iconName: "exit" },
        { reference: "CareRegister_CloseCareActivity", iconName: "lock" },
        { reference: "CareRegister_Delete", iconName: "remove" },
        { reference: "CareRegister_DeleteAdmission", iconName: "remove" },
        { reference: "CareRegister_RecordCareBaseData", iconName: "pen" },
        { reference: "CareRegister_RecordDiagnosis", iconName: "editDiagnosis" },
        { reference: "CareRegister_RecordProvidedServices", iconName: "editService" },
        { reference: "CareRegister_EditDocuments", iconName: "editDocument" },
        { reference: "CareRegister_EditTextBlocks", iconName: "document" },
        { reference: "CareRegister_EditCareActivitySecurity", iconName: "cog" },
        { reference: "CareRegister_EditAdmissionData", iconName: "pen" },
        { reference: "CareRegister_ContinueCareActivity", iconName: "enterStrong" },
        { reference: "CareRegister_ViewReadOnlyDocuments", iconName: "document" },
        { reference: "CareRegister_ViewReadOnlyCareBaseData", iconName: "pen" },
        { reference: "CareRegister_ViewReadOnlyDiagnosis", iconName: "editDiagnosis" },
        { reference: "CareRegister_ViewReadOnlyProvidedServices", iconName: "editService" },
        { reference: "CareRegister_ViewReadOnlyDischargeData", iconName: "exit" },
        { reference: "CareRegister_ViewCareActivityDocuments", iconName: "document" },
        { reference: "CareRegister_CreateServiceRequests", iconName: "stethoscope" },
        { reference: "CareRegister_CreateServiceRequests2", iconName: "stethoscope" },
        { reference: "CareRegister_ViewServiceRequests", iconName: "stethoscope" },
        { reference: "CareRegister_ViewServiceRequests2", iconName: "stethoscope" },
        { reference: "CareRegister_EditCareActivityFinanceData", iconName: "exit" },
        { reference: "CareRegister_ViewCareActivityFinanceData", iconName: "exit" },

        { reference: "CareActivityMenuGroup_1", iconName: "add_healthcare" },
        { reference: "CareActivityMenuGroup_2", iconName: "edit_patient" },

        { reference: "PatientRegister_EditPatientData", iconName: "edit_patient" },
        { reference: "PatientRegister_FixPatientData", iconName: "add_healthcare" },
        { reference: "PatientRegister_AdmitPatient", iconName: "add_healthcare" },
        { reference: "PatientRegister_ViewEpisodesOfCare", iconName: "episode_of_care" },
        { reference: "PatientRegister_ViewPatientRelatedDocuments", iconName: "document" },
        { reference: "PatientRegister_AdmitUnregisteredPatient", iconName: "add_healthcare" },
        { reference: "PatientRegister_ViewPatientsDocuments", iconName: "editDocument" },
        { reference: "PatientRegister_ViewReadOnlyDocuments", iconName: "document" },
        { reference: "PatientRegister_ViewPatientsServiceRequests", iconName: "stethoscope" },
        { reference: "PatientRegister_ViewAppointments", iconName: "appointments" },
        { reference: "PatientRegister_CreateNewAppointment", iconName: "new_appointment" },
        { reference: "PatientRegister_ViewReadOnlyDocuments", iconName: "document" },
        { reference: "PatientRegister_ViewPatientsCareActivities", iconName: "road" },
        { reference: "ServiceRequestManagement_AdministrateServiceRequest", iconName: "stethoscope" },
        { reference: "ServiceRequestManagement_AdministrateSingleServiceRequest", iconName: "stethoscope" },
        { reference: "ServiceRequestManagement_ViewDiagnosticReport", iconName: "document" },
        { reference: "ServiceRequestManagement_ViewDiagnosticRequest", iconName: "document" },
        { reference: "ServiceRequestManagement_ViewServiceRequest", iconName: "notesMedicalSolid" },
        { reference: "ServiceRequestManagement_ViewServiceRequest2", iconName: "notesMedicalSolid" },
        { reference: "ServiceRequestManagement_DeleteForRequesterForRequestedServices", iconName: "remove" },
        { reference: "ServiceRequestManagement_DeleteForExecutorBeforeCompletionForRequestedServices", iconName: "remove" },
        { reference: "ServiceRequestManagement_DeleteForExecutorAfterCompletionForRequestedServices", iconName: "remove" },
        { reference: "ServiceRequestManagement_RegisterAsReceivedForRequestedServices", iconName: "enter" },
        { reference: "ServiceRequestManagement_CompleteActivityForRequestedServices", iconName: "check" },
        { reference: "ServiceRequestManagement_ValidateDiagnosticReportForRequestedServices", iconName: "validated" },
        { reference: "ServiceRequestManagement_AdmitPatientWithServiceRequest", iconName: "add_healthcare" },
        { reference: "ServiceRequestManagement_DeleteStandaloneServiceRequest", iconName: "remove" },
        { reference: "Scheduling_AdmitPatientWithAppointment", iconName: "add_healthcare" },
        { reference: "Scheduling_DeleteAppointment", iconName: "remove" },
        { reference: "Scheduling_EditAppointment", iconName: "pen" },
        { reference: "Scheduling_EditAppointmentFromList", iconName: "pen" },
        { reference: "Scheduling_EditAppointmentFromPractitionerList", iconName: "pen" },
        { reference: "Scheduling_CreateAppointment", iconName: "plus" },
        { reference: "Scheduling_EditAppointmentScheduleDefinition", iconName: "pen" },
        { reference: "Scheduling_CreateAppointmentScheduleDefinition", iconName: "plus" },
        { reference: "Scheduling_NewAppointment", iconName: "circleAdd" },
        { reference: "Scheduling_ScheduleAppointments", iconName: "appointments" },
        { reference: "Scheduling_ComplexScheduleAppointments", iconName: "appointments" },
        { reference: "PerformanceStatement_EditCareRelatedEntities", iconName: "pen" },
        { reference: "Prescription_EditPrescriptions", iconName: "pills" },
        { reference: "Prescription_SelectPermanentPrescription", iconName: "plus" },
        { reference: "Prescription_ViewSubmittedPrescriptions", iconName: "pills" },
        { reference: "PractitionerRecommendation_EditPractitionerRecommendations", iconName: "practitioner_textbubble" },
        { reference: "ReferenceData_ViewMedicationInfo", iconName: "info" },
        { reference: "ReferenceData_SelectMedication", iconName: "plus" },
        { reference: "ResourceManagement_EditResourceBlockingFromList", iconName: "pen" },
        { reference: "PatientRegister_CreateNewPatient", iconName: "add_patient" },
        { reference: "PatientRegister_SearchPatient", iconName: "find_patient" },
        { reference: "Scheduling_CreateUnregisteredPatientAppointment", iconName: "new_appointment" },
        { reference: "MedicalCondition_Immunization_AddImmunization", iconName: "plus" },
        { reference: "MedicalCondition_Immunization_EditImmunization", iconName: "angleRight" },
        { reference: "MedicalCondition_ProcedureStatement_AddProcedureStatement", iconName: "plus" },
        { reference: "MedicalCondition_ProcedureStatement_EditProcedureStatement", iconName: "angleRight" },
        { reference: "MedicalCondition_AutonomyDisabilityStatement_AddAutonomyDisabilityStatement", iconName: "plus" },
        { reference: "MedicalCondition_AutonomyDisabilityStatement_EditAutonomyDisabilityStatement", iconName: "angleRight" },
        { reference: "MedicalCondition_ConditionStatement_AddConditionStatement", iconName: "plus" },
        { reference: "MedicalCondition_ConditionStatement_EditConditionStatement", iconName: "angleRight" },
        { reference: "MedicalCondition_DeviceUseStatement_AddDeviceUseStatement", iconName: "plus" },
        { reference: "MedicalCondition_DeviceUseStatement_EditDeviceUseStatement", iconName: "angleRight" },
        { reference: "MedicalCondition_PregnancyStatement_AddPregnancyStatement", iconName: "plus" },
        { reference: "MedicalCondition_PregnancyStatement_EditPregnancyStatement", iconName: "angleRight" },
        { reference: "MedicalCondition_MedicationStatement_AddMedicationStatement", iconName: "plus" },
        { reference: "MedicalCondition_MedicationStatement_EditMedicationStatement", iconName: "angleRight" },
        { reference: "MedicalCondition_RiskAssessment_AddRiskAssessment", iconName: "plus" },
        { reference: "MedicalCondition_RiskAssessment_EditRiskAssessment", iconName: "angleRight" },
        { reference: "MedicalCondition_PatientAllergyIntolerance_AddPatientAllergyIntolerance", iconName: "plus" },
        { reference: "MedicalCondition_PatientAllergyIntolerance_EditPatientAllergyIntolerance", iconName: "angleRight" },
        { reference: "MedicalCondition_MedicalAlertStatement_AddMedicalAlertStatement", iconName: "plus" },
        { reference: "MedicalCondition_MedicalAlertStatement_EditMedicalAlertStatement", iconName: "angleRight" },
        { reference: "ContagiousPatientReport_View", iconName: "show" },
        { reference: "ContagiousPatientReport_CreateEndReport", iconName: "out" },
        { reference: "ContagiousPatientReport_EhiResend", iconName: "redo" },
        { reference: "FamilyDoctorDocument_View", iconName: "document" },
        { reference: "FamilyDoctorDocument_Create", iconName: "plus" },
        { reference: "FamilyDoctorDocument_Modify", iconName: "angleRight" },
        { reference: "FamilyDoctorDocument_Delete", iconName: "remove" },
        { reference: "External_AddExternalLocation", iconName: "plus" },
        { reference: "External_EditExternalLocation", iconName: "pen" },
        { reference: "SurgeryTypes_SurgeryTypeDefinitionModify", iconName: "pen" },
        { reference: "SurgeryCore_SurgeryModify", iconName: "pen" },
        { reference: "FormInstanceHandling_FormInstanceCreate", iconName: "plus" },
        { reference: "Invoicing_InvoiceList", iconName: "fileInvoiceSolid" },
    );
}
