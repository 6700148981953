import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { ExtensibleEnumSelectBox } from "@HisPlatform/Components/HisPlatformControls";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatform/Components/HisPlatformControls/ExtensibleEnumSelectBox";

interface IEhiPrescriptionStatusSelectorDependencies {
    medicationRequestReferenceDataStore: MedicationRequestReferenceDataStore;
}

interface IEhiPrescriptionStatusSelectorProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IEhiPrescriptionStatusSelectorDependencies;
}

@State.observer
class EhiPrescriptionStatusSelector extends React.Component<IEhiPrescriptionStatusSelectorProps> {
    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.props._dependencies.medicationRequestReferenceDataStore.ehiPrescriptionStatuses}
                multiSelect
            />
        );
    }
}

export default connect(
    EhiPrescriptionStatusSelector,
    new DependencyAdapter<IEhiPrescriptionStatusSelectorProps, IEhiPrescriptionStatusSelectorDependencies>(c => ({
        medicationRequestReferenceDataStore: c.resolve("MedicationRequestReferenceDataStore")
    }))
);