import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import OrganizationSelector from "@HisPlatform/BoundedContexts/Organization/Components/OrganizationSelector";
import AppointmentParticipantOptions from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/AppointmentParticipantOptions.g";

interface ISchedulingServiceDialogViewProps {
    schedulingService: SchedulingServiceSubjectStore;
    onClose: () => void;
    saveAsync: () => Promise<void>;
    onValidateAsync: () => Promise<IClientValidationResult[]>;
    isTelemedicineConsultationFeatureEnabled: boolean;
}

@State.observer
export default class SchedulingServiceDialogView extends React.Component<ISchedulingServiceDialogViewProps> {
    @State.bound
    private setParticipantOption(option: AppointmentParticipantOptions, isEnabled: boolean): void {
        const participantOptions = isEnabled
            ? this.props.schedulingService.participantOptions | option
            : this.props.schedulingService.participantOptions & ~option;
        this.props.schedulingService.setParticipantOptions(participantOptions);
    }

    @State.action.bound
    private setAdditionalParticipantsAllowed(isChecked: boolean): void {
        return this.setParticipantOption(AppointmentParticipantOptions.AdditionalParticipantsAllowed, isChecked);
    }

    @State.action.bound
    private setAdditionalParticipantNotificationAllowed(isChecked: boolean): void {
        return this.setParticipantOption(AppointmentParticipantOptions.AdditionalParticipantNotificationAllowed, isChecked);
    }

    @State.action.bound
    private setParticipantNotificationAllowed(isChecked: boolean): void {
        return this.setParticipantOption(AppointmentParticipantOptions.ParticipantNotificationAllowed, isChecked);
    }

    @State.action.bound
    private setPatientNotificationAllowed(isChecked: boolean): void {
        return this.setParticipantOption(AppointmentParticipantOptions.PatientNotificationAllowed, isChecked);
    }

    public render() {
        return (
            <ValidationBoundary
                validationResults={this.props.schedulingService.validationResults}
                entityTypeName={"SchedulingService"}
                onValidateAsync={this.props.onValidateAsync}
                validateOnMount
            >
                <Ui.Modal
                    title={this.props.schedulingService.isNew ?
                        StaticSchedulingResources.SchedulingServiceEditorDialog.AddSchedulingService : StaticSchedulingResources.SchedulingServiceEditorDialog.EditSchedulingService}
                    isOpen
                    onClose={this.props.onClose}
                    size="compact"
                    closeButton
                    closeOnOutsideClick={false}
                    closeOnEscape={true}>
                    <Ui.Modal.Body>
                        <Ui.GroupBox title={StaticSchedulingResources.SchedulingServiceEditorDialog.BaseData}>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={6}>
                                    <Ui.TextBox
                                        label={StaticSchedulingResources.SchedulingServiceEditorDialog.Code}
                                        value={this.props.schedulingService.code}
                                        onChange={this.props.schedulingService.setCode}
                                        propertyIdentifier="Code"
                                        automationId="codeTextBox"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={6}>
                                    <Ui.TextBox
                                        label={StaticSchedulingResources.SchedulingServiceEditorDialog.Name}
                                        value={this.props.schedulingService.name}
                                        onChange={this.props.schedulingService.setName}
                                        propertyIdentifier="Name"
                                        automationId="nameTextBox"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            {
                                this.props.isTelemedicineConsultationFeatureEnabled && 
                                <Ui.Flex>
                                    <Ui.Flex.Item xs={6}>
                                        <Ui.CheckBox
                                            label={StaticSchedulingResources.SchedulingServiceEditorDialog.IsTelemedicineConsultation}
                                            value={this.props.schedulingService.isTelemedicineConsultation}
                                            onChange={this.props.schedulingService.setIsTelemedicineConsultation}
                                            propertyIdentifier="IsTelemedicineConsultation"
                                            automationId="isTelemedicineConsultation"
                                        />
                                    </Ui.Flex.Item>
                                </Ui.Flex>
                            }
                        </Ui.GroupBox>
                        {
                            this.props.isTelemedicineConsultationFeatureEnabled && <Ui.GroupBox title={StaticSchedulingResources.SchedulingServiceEditorDialog.ParticipantsAndNotifications}>
                                <Ui.Flex>
                                    <Ui.Flex.Item xs={6}>
                                        <Ui.CheckBox
                                            label={StaticSchedulingResources.SchedulingServiceEditorDialog.IsAdditionalParticipantsAllowed}
                                            value={this.props.schedulingService.isAdditionalParticipantsAllowed}
                                            onChange={this.setAdditionalParticipantsAllowed}
                                            propertyIdentifier="IsAdditionalParticipantsAllowed"
                                            automationId="isAdditionalParticipantsAllowed"
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={6}>
                                        <Ui.CheckBox
                                            label={StaticSchedulingResources.SchedulingServiceEditorDialog.IsAdditionalParticipantNotificationAllowed}
                                            value={this.props.schedulingService.isAdditionalParticipantNotificationAllowed}
                                            onChange={this.setAdditionalParticipantNotificationAllowed}
                                            propertyIdentifier="IsAdditionalParticipantNotificationAllowed"
                                            automationId="isAdditionalParticipantNotificationAllowed"
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={6}>
                                        <Ui.CheckBox
                                            label={StaticSchedulingResources.SchedulingServiceEditorDialog.IsParticipantNotificationAllowed}
                                            value={this.props.schedulingService.isParticipantNotificationAllowed}
                                            onChange={this.setParticipantNotificationAllowed}
                                            propertyIdentifier="IsParticipantNotificationAllowed"
                                            automationId="isParticipantNotificationAllowed"
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={6}>
                                        <Ui.CheckBox
                                            label={StaticSchedulingResources.SchedulingServiceEditorDialog.IsPatientNotificationAllowed}
                                            value={this.props.schedulingService.isPatientNotificationAllowed}
                                            onChange={this.setPatientNotificationAllowed}
                                            propertyIdentifier="IsPatientNotificationAllowed"
                                            automationId="isPatientNotificationAllowed"
                                        />
                                    </Ui.Flex.Item>
                                </Ui.Flex>
                            </Ui.GroupBox>
                        }
                        <Ui.GroupBox title={StaticSchedulingResources.SchedulingServiceEditorDialog.AppointmentSettings}>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={6}>
                                    <Ui.NumberBox
                                        label={StaticSchedulingResources.SchedulingServiceEditorDialog.ServiceDuration}
                                        value={this.props.schedulingService.durationInMinutes}
                                        onChange={this.props.schedulingService.setDurationInMinutes}
                                        propertyIdentifier="DurationInMinutes"
                                        automationId="durationInMinutesNumberBox"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </Ui.GroupBox>
                        <Ui.GroupBox title={StaticSchedulingResources.SchedulingServiceEditorDialog.CareLocation}>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={12}>
                                    <OrganizationSelector
                                        label={StaticSchedulingResources.SchedulingServiceEditorDialog.CareLocationId}
                                        value={this.props.schedulingService.careLocationId ? [this.props.schedulingService.careLocationId] : []}
                                        onChange={this.props.schedulingService.setCareLocationId}
                                        propertyIdentifier="CareLocationId"
                                        automationId="careLocationId"
                                        rootOrganizationUnitIsNotSelectable
                                        pointOfCareMode={false}
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={6}>
                                    <Ui.TextBox
                                        label={StaticSchedulingResources.SchedulingServiceEditorDialog.CareLocationDescription}
                                        value={this.props.schedulingService.careLocationDescription}
                                        onChange={this.props.schedulingService.setCareLocationDescription}
                                        propertyIdentifier="CareLocationDescription"
                                        automationId="careLocationDescriptionTextBox"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </Ui.GroupBox>

                    </Ui.Modal.Body>
                    <Ui.Modal.Footer>
                        <Ui.Flex xsJustify="between" verticalSpacing="none" >
                            <Ui.Flex.Item>
                                <Ui.Button
                                    text={StaticSchedulingResources.SchedulingServiceEditorDialog.Cancel}
                                    onClick={this.props.onClose}
                                    visualStyle="link"
                                    automationId="closeButton"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item>
                                <Ui.Button
                                    text={this.props.schedulingService.isNew ?
                                        StaticSchedulingResources.SchedulingServiceEditorDialog.AddButton : StaticSchedulingResources.SchedulingServiceEditorDialog.SaveButton}
                                    visualStyle="primary"
                                    onClickAsync={this.props.saveAsync}
                                    automationId="saveButton"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Modal.Footer>
                </Ui.Modal>
            </ValidationBoundary>
        );
    }
}