import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import HunWorklistApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/HunWorklistApiAdapter";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import ContagiousPatientReportFilterStore from "./Filters/ContagiousPatientReportFilterStore";
import ContagiousPatientReportFilter from "./Filters/ContagiousPatientReportFilter";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import Styles from "./StandaloneContagiousPatientReportPanel.less";
import { IRowIndicatorStyle } from "@CommonControls/DataGrid/IDataGridProps";
import ContagiousPatientReportState from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReportState";
import ShowEhiErrorsClientSideAction from "@HunSocialSecurityPlugin/BoundedContexts/Productivity/ApplicationLogic/Model/ShowEhiErrorsClientSideAction";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import EhiErrorSummary from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiErrorSummary";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";

interface IStandaloneContagiousPatientReportPanelDependencies {
    worklistApiAdapter: HunWorklistApiAdapter;
}

interface IStandaloneContagiousPatientReportPanelProps {
    _dependencies?: IStandaloneContagiousPatientReportPanelDependencies;
    selectedRowId: string;
    useCaseState: INDataUseCaseState;
    onChange: (rowId: string, useCaseState: INDataUseCaseState) => void;
}

@State.observer
class StandaloneContagiousPatientReportPanel extends React.Component<IStandaloneContagiousPatientReportPanelProps> {

    @State.observable.ref private definition: IWorklistDefinition = null;
    @State.observable.ref private filterStore: ContagiousPatientReportFilterStore = null;
    @State.observable.ref private ehiErrors: EhiError[] = [];
    @State.observable private isLoading: boolean = false;
    private refreshListEvent = new TypedAsyncEvent();

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.loadingState("isLoading")
    private async loadAsync() {
        await this.loadDefinitionAsync();
    }

    @State.bound
    private async loadDefinitionAsync() {
        const response = await this.props._dependencies.worklistApiAdapter.getContagiousPatientReportForInstituteBoundWorklistDefinitionAsync();

        State.runInAction(() => {
            this.definition = response.value;
        });
    }

    @State.action.bound
    private onFilterStoreCreatedAsync(filterStore: IFilterStore) {
        this.filterStore = new ContagiousPatientReportFilterStore(filterStore);
        return Promise.resolve();
    }

    @State.bound
    private getExtendedFilterDescriptors() {
        return ContagiousPatientReportFilterStore.getFilterDescriptors();
    }

    private renderExtendedSearch = () => {
        return (
            <ContagiousPatientReportFilter
                filterStore={this.filterStore}
                isVisible={true}
            />
        );
    };

    @State.action.bound
    private async performClientSideActionAsync(action: ClientSideActionDto): Promise<WorkListArgument | boolean> {
        if (action instanceof ShowEhiErrorsClientSideAction) {
            this.ehiErrors = action.ehiErrors;
        }

        await this.refreshListEvent.emitAsync();

        return null;
    }

    private getRowIndicatorStyles(row: any, rowId: string, rowIndex: number) {
        let style = Styles.underRecording;

        switch (row.ContagiousPatientReportBased_State as ContagiousPatientReportState) {
            case ContagiousPatientReportState.Approved:
                style = Styles.approved; break;
            case ContagiousPatientReportState.Deleted:
                style = Styles.deleted; break;
            case ContagiousPatientReportState.WaitingForApproval:
                style = Styles.waitingForApproval; break;
            default: break;
        }

        return {
            className: style
        } as IRowIndicatorStyle;
    }

    @State.action.bound
    private setPanelProps(useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) {
        return {};
    }

    public render() {
        if (this.isLoading) {
            return <></>;
        }
        return (
            <>
                <EhiErrorSummary errors={this.ehiErrors} notConfiguredCodesDisplayMode="dialog" />
                {<NDataPanel
                    definition={this.definition}
                    selectedRowId={this.props.selectedRowId}
                    useCaseState={this.props.useCaseState}
                    onChange={this.props.onChange}
                    extraFilter={this.filterStore && this.props.useCaseState?.displayMode !== UseCaseDisplayMode.MasterDetail && this.renderExtendedSearch()}
                    defaultExtraFilterVisibility
                    onFilterStoreCreatedAsync={this.onFilterStoreCreatedAsync}
                    onGetExtendedFilterDescriptors={this.getExtendedFilterDescriptors}
                    onPerformClientSideActionAsync={this.performClientSideActionAsync}
                    hasRefreshButton
                    disableDetailStrictMode
                    refreshListEvent={this.refreshListEvent}
                    getRowIndicatorStyle={this.getRowIndicatorStyles}
                    onGetPanelProps={this.setPanelProps}
                />}
            </>
        );
    }
}

export default connect(
    StandaloneContagiousPatientReportPanel,
    new DependencyAdapter<IStandaloneContagiousPatientReportPanelProps, IStandaloneContagiousPatientReportPanelDependencies>(container => {
        return {
            worklistApiAdapter: container.resolve("HunWorklistApiAdapter"),
        };
    })
);
