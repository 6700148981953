import React, { useCallback, useState } from "react";
import { IDataGridColumnChildProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import MedicalServiceFinancingStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/MedicalServiceFinancingStore";
import Button from "@CommonControls/Button";

interface IPerformedServiceActionsCellProps extends IDataGridColumnChildProps<MedicalServiceFinancingStore, any> {
    onDeleteAsync: (store: MedicalServiceFinancingStore) => Promise<void>;
    onEdit: (store: MedicalServiceFinancingStore) => void;
    onSaveChangesAsync: (store: MedicalServiceFinancingStore) => Promise<void>;
    readonly: boolean;
}


const PerformedServiceActionsCell: React.FC<IPerformedServiceActionsCellProps> = props => {

    const [isLoading, setIsLoading] = useState(false);

    const deleteAsync = useCallback(() => {
        setIsLoading(true);
        const ret = props.onDeleteAsync(props.row);
        setIsLoading(false);
        return ret;
    }, [props.onDeleteAsync, props.row, setIsLoading]);

    const edit = useCallback(() => props.onEdit(props.row), [props.onEdit, props.row]);
    const saveChangesAsync = useCallback(() => {
        setIsLoading(true);
        return props.onSaveChangesAsync(props.row).finally(() => setIsLoading(false));
    }, [props.onSaveChangesAsync, props.row, setIsLoading]);

    if (!props.isUnderEditing) {
        return (
            <>
                <Button key={"edit"} onClick={edit} disabled={props.readonly || isLoading} iconName="pen" visualStyle="standard" size="compact" automationId={props.rowId.toString() + "_editButton"} />
                <Button key={"delete"} onClickAsync={deleteAsync} disabled={props.readonly || isLoading} iconName="trash" visualStyle="negative-standard" size="compact" automationId={props.rowId.toString() + "_deleteButton"} />
            </>
        );
    } else {
        return (
            <Button key={"save"} onClickAsync={saveChangesAsync} disabled={props.readonly || isLoading} iconName="check" visualStyle="standard" size="compact" automationId="__saveModifications" />
        );
    }
};

export default PerformedServiceActionsCell;
