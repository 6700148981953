import AppointmentScheduleDefinitionId from "@Primitives/AppointmentScheduleDefinitionId.g";
import AppointmentScheduleDefinitionInfoId from "@Primitives/AppointmentScheduleDefinitionInfoId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class AppointmentScheduleDefinitionInfo extends EntityStoreBase<AppointmentScheduleDefinitionInfoId> {
    @State.observable public appointmentScheduleDefinitionId: AppointmentScheduleDefinitionId = null;
    @State.observable public isMedioRelevant: boolean;

    constructor(
    ) {
        super(true);
        this.id = new AppointmentScheduleDefinitionInfoId("-1");
        this.appointmentScheduleDefinitionId = new AppointmentScheduleDefinitionInfoId("-1");
        this.isMedioRelevant = false;
    }
    
    @State.action.bound
    public setIsMedioRelevant(newValue: boolean) {
        this.isMedioRelevant = newValue;
    }

    public static createFromExtensionDto(source: any) {
        const result = new AppointmentScheduleDefinitionInfo();
        if (!isNullOrUndefined(source)) {
            const extensionData = source["Medio_AppointmentScheduleDefinitionInfo"];

            if (!isNullOrUndefined(extensionData)) {
                result.isMedioRelevant =  !isNullOrUndefined(extensionData.IsMedioRelevant) ? extensionData.IsMedioRelevant : false;
                result.rowVersion = extensionData.RowVersion;
                result.id = AppointmentScheduleDefinitionInfoId.fromJS(extensionData.Id);
                result.appointmentScheduleDefinitionId = AppointmentScheduleDefinitionId.fromJS(extensionData.AppointmentScheduleDefinitionId);
                result.isNew = false;
            }
        }
        return result;
    }

    private _dirtyChecker = new StoreDirtyChecker();

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "operationInfo",
        "id",
        "appointmentScheduleDefinitionId"
    ];

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }
}
