import Di from "@Di";
import Command from "@Toolkit/CommonWeb/CommandProcessing/Definition/Command";
import CommandMessage from "@Toolkit/CommonWeb/CommandProcessing/Definition/CommandMessage";
import ICommandFactory from "@Toolkit/CommonWeb/CommandProcessing/Definition/ICommandFactory";
import CloseTelemetrySessionMeasurementsDialogCommand from "./CloseTelemetrySessionMeasurementsDialogCommand";

@Di.injectable()
export default class CloseTelemetrySessionMeasurementsDialogCommandFactory implements ICommandFactory {
    get commandType(): string {
        return "CloseTelemetrySessionView";
    }

    /** {@inheritdoc} */
    public createFromMessage(message: CommandMessage): Command {
        return new CloseTelemetrySessionMeasurementsDialogCommand(
            message?.originator ?? null,
            message.data.isCancelled);
    }
}