import AppointmentScheduleDefinitionId from "@Primitives/AppointmentScheduleDefinitionId.g";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { IUpdateSchedulingServiceResponse } from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/IUpdateSchedulingServiceResponse";

export interface ISchedulingServiceEditorDialogParams {
    schedulingServiceId?: SchedulingServiceId;
    scheduleDefinitionId: AppointmentScheduleDefinitionId;
    lockInfo: LockInfo;
}

export interface ISchedulingServiceEditorDialogResult {    
    schedulingService: SimpleStore<IUpdateSchedulingServiceResponse>;
}

export default class SchedulingServiceEditorDialogParams implements ISchedulingServiceEditorDialogParams {
    public static type = "SchedulingServiceEditorDialog";

    public get type() { return SchedulingServiceEditorDialogParams.type; }

    constructor(
        public readonly scheduleDefinitionId: AppointmentScheduleDefinitionId,
        public readonly lockInfo: LockInfo,
        public readonly schedulingServiceId?: SchedulingServiceId
    ) {

    }
}
