import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import {IModalComponentParams} from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import PropertyBase from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyBase";
import _ from "@HisPlatform/Common/Lodash";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IPropertyValueVersion from "@HisPlatform/Model/DomainModel/Common/IPropertyValueVersion";
import PropertyValueVersion from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyValueVersion";
import {IPropertyVersionsModalParams, IPropertyVersionsModalResult} from "@HisPlatform/BoundedContexts/Organization/Components/Panels/PropertyGroupsPanel/PropertyVesrionsModal/PropertyVersionsModalParams";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import PropertyVersionModalView from "@HisPlatform/BoundedContexts/Organization/Components/Panels/PropertyGroupsPanel/PropertyVesrionsModal/PropertyVersionModalView";
import BoolProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/BoolProperty";

interface IPropertyVersionModalDependencies {
    localizationService: ILocalizationService;
}

interface IPropertyVersionModalProps extends IModalComponentParams<IPropertyVersionsModalResult>, IPropertyVersionsModalParams {
    _dependencies?: IPropertyVersionModalDependencies;
}

@State.observer
class PropertyVersionModal extends React.Component<IPropertyVersionModalProps> {
    @State.observable.ref private property: PropertyBase;
    @State.observable.ref private newVersion: IPropertyValueVersion<any>;
    @State.observable.ref private validationResults: IClientValidationResult[] = [];

    private get localizationService() {
        return this.props._dependencies.localizationService;
    }

    public componentDidMount() {
        this.init();
    }

    @State.action.bound
    private init() {
        this.property = _.cloneDeep(this.props.property);
        this.initNewVersion();
    }

    @State.action.bound
    private initNewVersion() {
        let value;
        if (this.props.property instanceof BoolProperty) {
            value = false;
        }
        this.newVersion = new PropertyValueVersion(value, null, null);
    }

    @State.action.bound
    private close() {
        this.props.onClose(null);
    }

    @State.bound
    private getName() {
        const rowNameSelector = `${this.props.propertyGroup.name}.${this.props.propertyGroup.name}`;
        let localizedName = this.localizationService.localizeProperty(rowNameSelector);

        if (!localizedName) {
            localizedName = rowNameSelector;
        }

        if (localizedName === rowNameSelector) {
            localizedName = localizedName.slice(localizedName.lastIndexOf(".") + 1);
        }
        return localizedName;
    }

    @State.bound
    private onAdd() {
        this.property.addVersion(this.newVersion);
        this.initNewVersion();
    }

    @State.bound
    private async validateAsync() {
        const result = await this.props.validateVersionsAsync(this.property);
        State.runInAction(() => {
            this.validationResults = result;
        });
        return result;
    }

    @State.bound
    private closeModal() {
        this.props.onClose(null);
    }

    @State.bound
    private async trySaveAndCloseAsync() {
        await this.validateAsync();
        if (!this.validationResults.some(item => item.problems.some(i => i.severity === "error"))) {
            this.props.onClose({
                updatedProperty: this.property
            });
        }
    }

    public render() {
        return (
            <PropertyVersionModalView property={this.property}
                                      close={this.close}
                                      closeModal={this.closeModal}
                                      newVersion={this.newVersion}
                                      onAdd={this.onAdd}
                                      trySaveAndCloseAsync={this.trySaveAndCloseAsync}
                                      validationResults={this.validationResults}/>
        );
    }
}

export default connect(
    PropertyVersionModal,
    new DependencyAdapter<IPropertyVersionModalProps, IPropertyVersionModalDependencies>(container => {
        return {
            localizationService: container.resolve("ILocalizationService")
        };
    })
);
