import ServiceProviderProfileId from "@Primitives/ServiceProviderProfileId.g";
import IMedicalServiceExecutionCapability from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalServiceExecutionCapability";
import _ from "@HisPlatform/Common/Lodash";
import MedicalServicePriority from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalServices/Enum/MedicalServicePriority.g";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";

export default class ServiceProviderProfile {
     constructor(
          private readonly id: ServiceProviderProfileId,
          private readonly medicalServiceExecutionCapabilities: IMedicalServiceExecutionCapability[]
     ) { }

     public getAvailablePriorities() {
          const allPriorities = _.flatten(this.medicalServiceExecutionCapabilities.map(a => a.priorities));
          return _.uniq(allPriorities);
     }

     public isDoctorSelectionAllowed() {
          return this.medicalServiceExecutionCapabilities.every(a => a.targetDoctorSelectionAllowed === true);
     }

     public getMedicalServiceIdsByPriority(priority: MedicalServicePriority) {
          return this.medicalServiceExecutionCapabilities
          .filter(m => m.priorities.some(p => p === priority))
          .map(s => s.medicalServiceId);
     }

     public getMedicalServiceIds() {
          return this.medicalServiceExecutionCapabilities
          .map(c => c.medicalServiceId);
     }

     public GetMedicalServiceExecutionCapabilityByMedicalServiceId(id: MedicalServiceId) {
          return this.medicalServiceExecutionCapabilities
          .find(c => c.medicalServiceId.value === id?.value);
     }
}