/* auto-inject-disable */
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/Finance/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import {CreateRequestId} from "@HisPlatform/Common/RequestHelper";
import CareActivityId from "@Primitives/CareActivityId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import {createOperationInfo} from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import {GetTypeNameWithPrefix} from "@HunSocialSecurityPlugin/PluginHelper";
import PossibleAction from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/Model/PossibleAction";

@Di.injectable()
export default class HunFinanceApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("IFinanceClient")) private readonly financeApiClient: Proxy.IFinanceClient,
        @Di.inject(GetTypeNameWithPrefix("IActionsClient")) private readonly actionsClient: Proxy.IActionsClient
    ) {
        super();
    }

    public getPossibleActionsAsync(ids: CareActivityId[]) {
        return this.processOperationAsync(
            new SimpleStore<PossibleAction[]>(),
            async target => {
                const response = await this.actionsClient.getPossibleActionsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetPossibleActionsControllerDto({
                        careActivityIds: ids
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.possibleActions.map(i => new PossibleAction(i.careActivityId, i.actions));
            }
        );
    }
}
