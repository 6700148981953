exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MedicationEquivalenceListNDataPanel_message_13I6x {\n  margin-left: 4px;\n}\n.MedicationEquivalenceListNDataPanel_row-bold_3CfNC {\n  font-weight: 600;\n}\n", ""]);

// exports
exports.locals = {
	"message": "MedicationEquivalenceListNDataPanel_message_13I6x",
	"row-bold": "MedicationEquivalenceListNDataPanel_row-bold_3CfNC",
	"rowBold": "MedicationEquivalenceListNDataPanel_row-bold_3CfNC"
};