import Di from "@Di";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { PermittedFrontendActionPermissionScope } from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/PermittedFrontendActionPermissionScope";
import { PermittedFrontendActionPermissionScopeSet } from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/PermittedFrontendActionPermissionScopeSet";
import { PermittedFrontendAction } from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/PermittedFrontendAction";
import { IActionPermissionProvider } from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/IActionPermissionProvider";

@Di.injectable()
export default class PermittedActionsApiAdapter extends ApiAdapterBase2 implements IActionPermissionProvider {
    constructor(
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("IAuthorizationClient") private readonly apiClient: Proxy.IAuthorizationClient
    ) {
        super(referenceDataLoader);
    }

    public getPermittedActionsForCurrentUserAsync() {
        return this.executeOperationAsync(
            () => this.apiClient.getPermittedActionsForCurrentUserQueryAsync(CreateRequestId()),
            response => ({
                isUnresctrictedUser: response.isUnrestrictedUser,
                permittedActions: response.permittedActions?.map(pa => new PermittedFrontendAction(pa.id, pa.requiresAnyOf?.map(this.mapScopeSet) ?? [])) ?? []
            })
        );
    }

    public async getFrontendActionPermissionsAsync(): Promise<{ isUnresctrictedUser: boolean, permittedActions: PermittedFrontendAction[] }> {
        const response = await this.getPermittedActionsForCurrentUserAsync();
        return response.result;
    }

    private mapScopeSet(scopeSet: Proxy.PermissionScopeSetDto) {
        return new PermittedFrontendActionPermissionScopeSet(scopeSet.scopes!.map((s: Proxy.PermissionScope) =>
            s.values!.mode === Proxy.SetSpecificationMode.Positive
                ? PermittedFrontendActionPermissionScope.createPositive(s.type.value, s.values!.includedItems)
                : PermittedFrontendActionPermissionScope.createNegative(s.type.value, s.values!.excludedItems)
        ));
    }
}