import UserId from "@Primitives/UserId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import PractitionerBaseDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerBaseDataStore";
import PractitionerAddressStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerAddressStore";
import PractitionerTelecomStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerTelecomStore";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import PractitionerIdentifierStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerIdentifierStore";
import JobPositionId from "@Primitives/JobPositionId.g";
import CloneableLockingEntityStoreBase from "@Toolkit/CommonWeb/Model/CloneableLockingEntityStoreBase";
import PractitionerProfessionalExamStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerProfessionalExamStore";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";

export default class PractitionerStore extends CloneableLockingEntityStoreBase<PractitionerStore, PractitionerId> implements IDirtyChecked {
    
    protected get vPropertyNamesExcludedFromDirtyCheck() {
        return [
            ...super.vPropertyNamesExcludedFromDirtyCheck,
            "userId",
            "validationResults"
        ];
    }

    public userId: UserId;
    public baseData: PractitionerBaseDataStore;
    public addresses: IObservableArray<PractitionerAddressStore>;
    public telecoms: IObservableArray<PractitionerTelecomStore>;
    public identifiers: IObservableArray<PractitionerIdentifierStore>;
    public professionalExams: IObservableArray<PractitionerProfessionalExamStore>;
    public code: string;
    @State.observable public jobPositionId: JobPositionId;

    constructor(
        isNew: boolean = false
    ) {
        super(isNew);
        this.baseData = new PractitionerBaseDataStore();
        this.addresses = State.observable([]);
        this.telecoms = State.observable([]);
        this.identifiers = State.observable([]);
        this.professionalExams = State.observable([]);
    }

    @State.action.bound
    public setBaseData(newValue: PractitionerBaseDataStore) {
        this.baseData = newValue;
    }

    @State.action.bound
    public setUserId(newValue: UserId) {
        this.userId = newValue;
    }

    @State.action.bound
    public removeAddress(address: PractitionerAddressStore) {
        this.addresses.remove(address);
    }

    @State.action.bound
    public setJobPosition(newValue: JobPositionId) {
        this.jobPositionId = newValue;
    }

    @State.action.bound
    public removeIdentifier(identifier: PractitionerIdentifierStore) {
        this.identifiers.remove(identifier);
    }

    @State.action.bound
    public removeProfession(professions: PractitionerProfessionalExamStore) {
        this.professionalExams.remove(professions);
    }

    @State.computed
    public get displayName() {
        let name = this.baseData.name.familyName && this.baseData.name.givenName1
            ? this.baseData.name.familyName + " " + this.baseData.name.givenName1
            : this.identifiers.length && this.identifiers[0].name;

        if (this.baseData.name.prefix !== null) {
            name = this.baseData.name.prefix + " " + name;
        }

        return name;
    }

    @State.computed
    public get identifiersString() {
        return this.identifiers && this.identifiers.map(x => x.identifier.value).join(", ");
    }

    @State.computed
    public get iconName() {
        if (this.baseData && this.baseData.genderId) {
            if (this.baseData.genderId.value === "Male") {
                return "patient_man";
            } else {
                return "patient_woman";
            }
        }
        return "patient_unknown";
    }
}
