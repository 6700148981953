import FormDataElementBase from "./FormDataElementBase";

export default class TimeFormDataElement extends FormDataElementBase {

    constructor(
        name: string,
        isArray: boolean,
        defaultValue: number,
        isReadOnly: boolean,
        isVisible: boolean
    ) {
        super(name, isArray, defaultValue, isReadOnly, isVisible);
    }

    public formDataElementType = "Time";
}