import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import CareActivityId from "@Primitives/CareActivityId.g";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityDischargeDataStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDischargeData/CareActivityDischargeDataStoreMapper";
import CareActivityDischargeDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityDischargeData/CareActivityDischargeDataStore";
import PossibleAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PossibleAction";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";

@Di.injectable()
export default class DischargePatientScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("ICareClient") private readonly apiClient: Proxy.ICareClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("CareReferenceDataStore") private readonly careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("CareActivityDischargeDataStoreMapper") private careActivityDischargeDataStoreMapper: CareActivityDischargeDataStoreMapper
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public getDischargePatientScreenDataAsync(id: CareActivityId, requestLock: boolean) {
        return this.executeOperationAsync<CareActivityDischargeDataStore, Proxy.GetCareActivityDischargeDataCompositeCommandResponse>(
            () => this.apiClient.getCareActivityDischargeDataCompositeCommandAsync(CreateRequestId(), new Proxy.GetCareActivityDischargeDataCompositeControllerDto({
                careActivityId: id,
                requestLock: requestLock,
                skipValidation: false
            })),
            response => {
                const target = new CareActivityDischargeDataStore(this.careReferenceDataStore.forwardingNeededAtDischarge.map);
                this.careActivityDischargeDataStoreMapper.applyToStore(target, response);
                target.possibleAction = response.possibleActions.map(i => new PossibleAction(i.identifier.careActivityId, i.identifier.serviceRequestId, i.actions));
                return target;
            }
        );
    }

    @State.bound
    public dischargePatientAsync(
        isValidateOnly: boolean,
        careActivityId: CareActivityId,
        careActivityDischargeDataStore: CareActivityDischargeDataStore,
        releaseLockIfSuccessful: boolean,
        lockInfo?: LockInfo) {
        return this.executeOperationAsync<CareActivityDischargeDataStore, Proxy.UpdateAndDischargePatientCommandResponse>(
            () => {
                return this.apiClient.updateAndDischargePatientCommandAsync(CreateRequestId(), new Proxy.UpdateAndDischargePatientControllerDto({
                    isValidateOnly: isValidateOnly,
                    id: careActivityId,
                    dischargedAt: careActivityDischargeDataStore.dischargedAt,
                    dischargeReasonId: careActivityDischargeDataStore.dischargeReasonId,
                    receivingInstituteId: careActivityDischargeDataStore.receivingInstitute?.id,
                    rowVersion: careActivityDischargeDataStore.rowVersion,
                    releaseLockIfSuccessful: releaseLockIfSuccessful,
                    lockId: lockInfo?.lockId,
                    extensionData: careActivityDischargeDataStore.extensionData
                }));
            },
            response => {
                const target = new CareActivityDischargeDataStore(this.careReferenceDataStore.forwardingNeededAtDischarge.map);
                this.careActivityDischargeDataStoreMapper.applyToStoreWithExistingLock(target, response, careActivityDischargeDataStore.lockInfo, releaseLockIfSuccessful);
                return target;
            }
        );
    }

    @State.bound
    public updateDischargePatientDataAsync(
        isValidateOnly: boolean,
        careActivityId: CareActivityId,
        careActivityDischargeDataStore: CareActivityDischargeDataStore,
        releaseLockIfSuccessful: boolean,
        lockInfo?: LockInfo) {
        return this.executeOperationAsync<CareActivityDischargeDataStore, Proxy.UpdateDischargePatientDataCommandResponse>(
            () => {
                return this.apiClient.updateDischargePatientDataCommandAsync(CreateRequestId(), new Proxy.UpdateDischargePatientDataControllerDto({
                    isValidateOnly: isValidateOnly,
                    id: careActivityId,
                    dischargeReasonId: careActivityDischargeDataStore.dischargeReasonId,
                    receivingInstituteId: careActivityDischargeDataStore.receivingInstitute?.id,
                    rowVersion: careActivityDischargeDataStore.rowVersion,
                    releaseLockIfSuccessful: releaseLockIfSuccessful,
                    lockId: lockInfo?.lockId,
                    extensionData: careActivityDischargeDataStore.extensionData
                }));
            },
            response => {
                const target = new CareActivityDischargeDataStore(this.careReferenceDataStore.forwardingNeededAtDischarge.map);
                this.careActivityDischargeDataStoreMapper.applyToStoreWithExistingLock(target, response, careActivityDischargeDataStore.lockInfo, releaseLockIfSuccessful);
                return target;
            }
        );
    }

    public validateAsync(
        careActivityId: CareActivityId,
        careActivityDischargeDataStore: CareActivityDischargeDataStore,
        hasPermissionForDischargePatient: boolean,
        lockInfo: LockInfo) {
        return this.executeOperationAsync<IClientValidationResult[], Proxy.UpdateAndDischargePatientCommandResponse | Proxy.UpdateDischargePatientDataCommandResponse>(
            () => {
                return hasPermissionForDischargePatient ? this.apiClient.updateAndDischargePatientCommandAsync(CreateRequestId(), new Proxy.UpdateAndDischargePatientControllerDto({
                    isValidateOnly: true,
                    id: careActivityId,
                    dischargedAt: careActivityDischargeDataStore.dischargedAt,
                    dischargeReasonId: careActivityDischargeDataStore.dischargeReasonId,
                    receivingInstituteId: careActivityDischargeDataStore.receivingInstitute.id,
                    rowVersion: careActivityDischargeDataStore.rowVersion,
                    releaseLockIfSuccessful: false,
                    lockId: lockInfo?.lockId,
                    extensionData: careActivityDischargeDataStore.extensionData
                })) : this.apiClient.updateDischargePatientDataCommandAsync(CreateRequestId(), new Proxy.UpdateDischargePatientDataControllerDto({
                    isValidateOnly: true,
                    id: careActivityId,
                    dischargeReasonId: careActivityDischargeDataStore.dischargeReasonId,
                    receivingInstituteId: careActivityDischargeDataStore.receivingInstitute.id,
                    rowVersion: careActivityDischargeDataStore.rowVersion,
                    releaseLockIfSuccessful: false,
                    lockId: lockInfo?.lockId,
                    extensionData: careActivityDischargeDataStore.extensionData
                }));
            },
            response => {

                return mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }
}