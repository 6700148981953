import {IFilterStore} from "@CommonControls/DataGrid/Filter/IFilterStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";

export default class ComplexSearchFilterStore implements IFilterStore {

    @State.observable.ref public commonFilterText: string;
    @State.observable.ref public selectedFilterText: string;

    constructor(private readonly refresh: () => Promise<void>,
                private readonly setDefaultPaging: () => void) {

    }

    @State.action.bound
    public setCommonFilterText(newValue: string, refresh: boolean = true) {
        this.setDefaultPaging();
        this.commonFilterText = newValue;
        if (refresh) {
            this.refreshDebounce();
        }
    }

    @State.action.bound
    public setSelectedCommonFilterText(newValue: string) {
        this.selectedFilterText = newValue;
    }

    public __reset(refresh: boolean = true): void {
        this.setCommonFilterText(null, refresh);
    }

    private refreshDebounce = _.debounce(() => {
        this.refresh();
    }, 1000);

}
