import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import { createNestedRoutes } from "@Toolkit/ReactClient/Routing/CreateNestedRoutes";
import { IRegisteredPatientAppointmentListRouteParams } from "@HisPlatform/Components/Pages/AppointmentPages/IRegisteredPatientAppointmentListRouteParams";
import IUnregisteredPatientAppointmentRouteParams from "@HisPlatform/Components/Pages/AppointmentPages/IUnregisteredPatientAppointmentRouteParams";
import IPractitionerAppointmentsRouteParams from "./IPractitionerAppointmentsRouteParams";
import IInvalidAppointmentsRouteParams from "@HisPlatform/Components/Pages/AppointmentPages/IInvalidAppointmentsRouteParams";


const StandaloneAppointmentRoutes = createNestedRoutes(ApplicationRoutes.appointments, {
    registeredPatientAppointments: new RouteDefinition<IRegisteredPatientAppointmentListRouteParams>("/registeredpatient/:patientId/:useCase/:careActivityId?"),
    unregisteredPatientAppointments: new RouteDefinition<IUnregisteredPatientAppointmentRouteParams>("/unregisteredPatient/:appointmentId?"),
    practitionerAppointments: new RouteDefinition<IPractitionerAppointmentsRouteParams>("/practitionerAppointments/:practitionerId?"),
    invalidAppointments: new RouteDefinition<IInvalidAppointmentsRouteParams>("/invalidAppointments/:useCase?/")
});

export default StandaloneAppointmentRoutes;
