import React from "react";
import * as Ui from "@CommonControls";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IPrescriptionToEhiDialogViewProps {
    onChoosePaper: () => void;
    onChooseDigital: () => void;
    ehiPrescriptionError?: string;
}


const PrescriptionToEhiDialogView: React.FC<IPrescriptionToEhiDialogViewProps> = props => (
    <Ui.Modal
        isOpen
        title={StaticHunSocialSecurityMedicationRequestResources.PrescriptionToEhiDialog.DialogTitle}
        size="tiny"
        closeOnOutsideClick={false}
        isHeaderPrimary
    >
        <Ui.Modal.Body>
            <div>
                {!isNullOrUndefined(props.ehiPrescriptionError) && <div> {props.ehiPrescriptionError}</div>}
                {StaticHunSocialSecurityMedicationRequestResources.PrescriptionToEhiDialog.SingleMedicationDialogMessage}
            </div>
        </Ui.Modal.Body>
        <Ui.Modal.Footer>
            {renderButtons(props)}
        </Ui.Modal.Footer>

    </Ui.Modal>
);
const renderButtons = (props: IPrescriptionToEhiDialogViewProps) => {
    return (
        <Ui.Flex xsJustify="between">
            <Ui.Flex.Item>
                <Ui.Button onClick={props.onChooseDigital} text={StaticHunSocialSecurityMedicationRequestResources.PrescriptionToEhiDialog.NoButton} automationId="chooseDigitalButton" />
                <Ui.Button onClick={props.onChoosePaper} text={StaticHunSocialSecurityMedicationRequestResources.PrescriptionToEhiDialog.YesButton} visualStyle="primary" automationId="choosePaperButton" />
            </Ui.Flex.Item>
        </Ui.Flex>
    );
};

export default PrescriptionToEhiDialogView;
