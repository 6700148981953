export default class AgeRange {

    constructor(age: number, epsilon: number) {
        this.age = age;
        this.ageEpsilon = epsilon;
    }

    public get ageFrom(): number {
        if (!this.age) {
            return null;
        }
        return this.age - (this.ageEpsilon || 0);
    }

    public get ageTo(): number {
        if (!this.age) {
            return null;
        }
        return this.age + (this.ageEpsilon || 0);
    }

    public age: number;
    public ageEpsilon: number;
}