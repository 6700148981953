import PatientId from "@Primitives/PatientId.g";
import { ScreenContextDataBase } from "./ScreenContextData";

export default class PatientScreenContextData extends ScreenContextDataBase {
    constructor(public readonly patientId?: PatientId, public readonly isReducedDataSet: boolean = false) {
        super();
    }

    public getHashValue(): string {
        return `PatientContext_${this.patientId?.value ?? 'NEW'}${this.isReducedDataSet}`;
    }
}
