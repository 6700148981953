import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";

export default class PanelController {
    private unmountAsyncHook: () => Promise<boolean> = null;
    private saveContentAsync: () => Promise<boolean> = null;

    public setUnmountAsyncHook(hook: () => Promise<boolean>): IDisposable {
        this.unmountAsyncHook = hook;
        return {
            dispose: () => {
                if (this.unmountAsyncHook === hook) {
                    this.unmountAsyncHook = null;
                }
            }
        };
    }

    public setSaveContentAsyncHook(save: () => Promise<boolean>): IDisposable {
        this.saveContentAsync = save;
        return {
            dispose: () => {
                if (this.saveContentAsync === save) {
                    this.saveContentAsync = null;
                }
            }
        };
    }

    @State.bound
    public unmountAsync() {
        if (this.unmountAsyncHook) {
            return this.unmountAsyncHook();
        }
        return Promise.resolve(true);
    }

    @State.bound
    public saveAsync() {
        if (this.saveContentAsync) {
            return this.saveContentAsync();
        }
        return Promise.resolve(true);
    }

    public static createFromRoutingController(routingController: IRoutingController) {
        const controller = new PanelController();

        routingController.navigatingAwayHookAsync = () => {
            return controller.unmountAsync();
        };

        return controller;
    }
}