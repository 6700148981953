
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import FormDetailPanelStore from "./FormDetailPanelStore";

const { 
    ContextComponent: FormDetailPanelStoreContext, 
    StoreProvider: FormDetailPanelStoreProvider, 
    useStore: useFormDetailPanelStore,
    provideStore: provideFormDetailPanelStore,
} = createPanelStoreProvider(FormDetailPanelStore);

export {
    FormDetailPanelStoreContext,
    FormDetailPanelStoreProvider,
    useFormDetailPanelStore,
    provideFormDetailPanelStore
};