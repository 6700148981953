import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import PatientAllergyIntoleranceId from "@Primitives/PatientAllergyIntoleranceId.g";
import PatientAllergyIntoleranceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceStore";
import PatientAllergyIntoleranceStoreMapper from "./PatientAllergyIntoleranceStoreMapper";
import { getAddPatientAllergyIntoleranceControllerDto, getUpdatePatientAllergyIntoleranceControllerDto, getDeletePatientAllergyIntoleranceControllerDto, getDeletePatientAllergyIntoleranceHistoryItemControllerDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceDtoMapper";
import PatientId from "@Primitives/PatientId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import PatientAllergyIntoleranceListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceListStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class PatientAllergyIntoleranceApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IPatientAllergyIntoleranceClient") private readonly apiClient: Proxy.IPatientAllergyIntoleranceClient,
        @Di.inject("PatientAllergyIntoleranceStoreMapper") private readonly storeMapper: PatientAllergyIntoleranceStoreMapper
    ) {
        super();
    }

    public getByIdAsync(patientAllergyIntoleranceId: PatientAllergyIntoleranceId, requestLock: boolean): Promise<PatientAllergyIntoleranceStore> {
        return this.processOperationAsync(
            new PatientAllergyIntoleranceStore(false),
            async target => {
                const response = await this.apiClient.getPatientAllergyIntoleranceByIdCommandAsync(
                    CreateRequestId(),
                    new Proxy.GetPatientAllergyIntoleranceByIdControllerDto({ patientAllergyIntoleranceId: patientAllergyIntoleranceId, requestLock: requestLock })
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public getByPatientIdAsync(patientId: PatientId): Promise<PatientAllergyIntoleranceListStore> {
        return this.processOperationAsync(
            new PatientAllergyIntoleranceListStore(),
            async target => {
                const response = await this.apiClient.getPatientAllergyIntolerancesByPatientIdQueryAsync(
                    CreateRequestId(),
                    patientId.value
                );

                target.patientAllergyIntolerances = State.observable([]);

                response.items.map(x => {
                    const item = new PatientAllergyIntoleranceStore(false);
                    this.storeMapper.setItem(item, x);
                    target.patientAllergyIntolerances.push(item);
                });

                target.operationInfo = createOperationInfo(response);
                await this.storeMapper.resolveAllergyIntolerancesAsync(target);
            }
        );
    }

    public addPatientAllergyIntoleranceAsync(store: PatientAllergyIntoleranceStore, patientId: PatientId, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new PatientAllergyIntoleranceStore(false),
            async target => {
                const response = await this.apiClient.addPatientAllergyIntoleranceCommandAsync(
                    CreateRequestId(),
                    getAddPatientAllergyIntoleranceControllerDto(store, patientId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public updatePatientAllergyIntoleranceAsync(store: PatientAllergyIntoleranceStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new PatientAllergyIntoleranceStore(false),
            async target => {
                const response = await this.apiClient.updatePatientAllergyIntoleranceCommandAsync(
                    CreateRequestId(),
                    getUpdatePatientAllergyIntoleranceControllerDto(store, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deletePatientAllergyIntoleranceHistoryItemAsync(store: PatientAllergyIntoleranceStore, versionNumber: number, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new PatientAllergyIntoleranceStore(false),
            async target => {
                const response = await this.apiClient.deletePatientAllergyIntoleranceHistoryItemCommandAsync(
                    CreateRequestId(),
                    getDeletePatientAllergyIntoleranceHistoryItemControllerDto(store, versionNumber, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deletePatientAllergyIntoleranceAsync(store: PatientAllergyIntoleranceStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<any>(),
            async target => {
                const response = await this.apiClient.deletePatientAllergyIntoleranceCommandAsync(
                    CreateRequestId(),
                    getDeletePatientAllergyIntoleranceControllerDto(store),
                    isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public validateAsync(store: PatientAllergyIntoleranceStore, patientId: PatientId) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response =
                    store.isNew ?
                        await this.apiClient.addPatientAllergyIntoleranceCommandAsync(CreateRequestId(), getAddPatientAllergyIntoleranceControllerDto(store, patientId, true)) :
                        await this.apiClient.updatePatientAllergyIntoleranceCommandAsync(CreateRequestId(), getUpdatePatientAllergyIntoleranceControllerDto(store, true, store.lockInfo?.lockId, false));

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    public async checkPermissionForAddNewItemAsync(store: PatientAllergyIntoleranceStore, patientId: PatientId): Promise<PatientAllergyIntoleranceStore> {
        return await this.addPatientAllergyIntoleranceAsync(store, patientId, true);        
    }

    public async checkPermissionForUpdateItemAsync(store: PatientAllergyIntoleranceStore): Promise<PatientAllergyIntoleranceStore> {
        return await this.updatePatientAllergyIntoleranceAsync(store, true);
    }

    public async checkPermissionForDeleteItemAsync(store: PatientAllergyIntoleranceStore): Promise<SimpleStore> {
        return await this.deletePatientAllergyIntoleranceAsync(store, true);
    }
}
