import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IEhiEReferralDetailDialogParams, IEhiEReferralDetailDialogResult } from "./EhiEReferralDetailDialogParams";
import _ from "@HisPlatform/Common/Lodash";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import CareActivityEReferralStore from "@HunEHealthInfrastructurePlugin/Model/CareActivityEReferralStore";
import EhiEReferralDetailPanel from "./EhiEReferralDetailPanel";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";


interface IEhiEReferralDetailDialogDependencies {
    notificationService: INotificationService;
}

interface IEhiEReferralDetailDialogProps extends IModalComponentParams<IEhiEReferralDetailDialogResult>, IEhiEReferralDetailDialogParams {
    _dependencies?: IEhiEReferralDetailDialogDependencies;
    careActivityEReferralStore: CareActivityEReferralStore;
}

/** @screen */
@State.observer
class EhiEReferralDetailDialog extends React.Component<IEhiEReferralDetailDialogProps> {

    public render() {
        const commonResource = StaticWebAppResources.Common.Button;
        return (
            <>
                <HisUi.ContextAwareModal
                    title={StaticHunEHealthInfrastructureCareResources.CareActivityEhiEReferral.DetailDialog.Title}
                    isOpen
                    onClose={this.cancel}
                    size="normal">
                    <Ui.Modal.Body>
                        <EhiEReferralDetailPanel careActivityEReferralStore={this.props.careActivityEReferralStore} wrapToColumns/>
                    </Ui.Modal.Body>
                    <Ui.Modal.Footer>
                        <Ui.Button onClick={this.cancel} visualStyle={"flat"} text={commonResource.Back} float="right" automationId="cancelButton" />
                    </Ui.Modal.Footer>
                </HisUi.ContextAwareModal>
            </>
        );
    }

    @State.bound
    private cancel() {
        this.props.onClose(null);
    }
}

export default connect(
    EhiEReferralDetailDialog,
    new DependencyAdapter<IEhiEReferralDetailDialogProps, IEhiEReferralDetailDialogDependencies>(c => ({
        notificationService: c.resolve("INotificationService")
    }))
);
