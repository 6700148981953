import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import IErrorListRouteParams from "./IErrorListRouteParams";
import IPerformanceStatementCareActivityRouteParams from "./IPerformanceStatementCareActivityDetailRouteParams";

const performanceStatementStandaloneRouteDefinitions = {
    createEuPerformanceStatement: new RouteDefinition("/createEuPerformanceStatement"),
    createNeakPerformanceStatement: new RouteDefinition("/createNeakPerformanceStatement"),
    createNeakRevisionPerformanceStatement: new RouteDefinition("/createNeakRevisionPerformanceStatement"),
    errorList: new RouteDefinition<IErrorListRouteParams>("/errorList/:selectedPerformanceStatementRowId/:useCase?/:selectedRowId?"),
    neakPerformanceStatement: new RouteDefinition("/neakPerformanceStatement/:useCase?/:selectedRowId?"),
    performanceStatementCareActivity: new RouteDefinition<IPerformanceStatementCareActivityRouteParams>("/performanceStatementCareActivity/:selectedRowId?/:useCase?/"),
};

export default performanceStatementStandaloneRouteDefinitions;