import React, { useCallback } from "react";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import moment from "moment";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import DateRangePicker from "@CommonControls/DateRangePicker/DateRangePicker";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { RowId } from "@CommonControls/DataGrid/IDataGridProps";
import DateTimePicker from "@CommonControls/DateTimePicker/DateTimePicker";
import ColumnStyles from "./DataGridColumn.less";

interface IDataGridDateTimeColumnDependencies {
    toolkitLocalizationService: IToolkitLocalizationService;
}

type IDataGridDateTimeColumnProps = IDataGridColumnBaseProps & {
    _dependencies?: IDataGridDateTimeColumnDependencies;
    isClearable?: boolean;
    showSeconds?: boolean;
    nullValue?: string;
};


const DataGridDateTimeColumn: React.FC<IDataGridDateTimeColumnProps> = props => {

    const valueRenderer = useCallback((value: moment.Moment | moment.Moment[], row: any, rowId: RowId, rowIndex: number, isUnderEditing: boolean, dataSetter: (newValue: moment.Moment, index?: number) => void) => {
        if (isNullOrUndefined(value)) { return isNullOrUndefined(props.nullValue) ? null : props.nullValue; }
        const localizer = props._dependencies.toolkitLocalizationService;

        const isArray = Array.isArray(value);

        if (!isArray) {
            if (isUnderEditing) {
                return <DateTimePicker automationId="editableDateTimePicker" value={value as moment.Moment} onChange={dataSetter} clearable={props.isClearable} />;
            } else {
                return localizer.localizeDateTime(value as moment.Moment);
            }
        } else {
            return (value as moment.Moment[]).map((singleValue, index) => {
                const changeHandler = (newValue: moment.Moment) => {
                    dataSetter?.(newValue, index);
                };

                return (
                    <div key={index} className={isUnderEditing ? ColumnStyles.inlineInputContainer : undefined}>
                        {!isUnderEditing ? localizer.localizeDateTime(singleValue, props.showSeconds) : <DateTimePicker automationId="editableDateTimePicker" value={singleValue} onChange={changeHandler} clearable={props.isClearable} />}
                    </div>
                );
            });
        }

    }, []);

    const hintRenderer = useCallback((value: any) => {
        const isArray = Array.isArray(value);
        if (isArray) {
            return props._dependencies.toolkitLocalizationService.localizeDateTime(value[0] as moment.Moment);
        }
        return props._dependencies.toolkitLocalizationService.localizeDateTime(value as moment.Moment);
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<LocalDateRange>) => {
        return <DateRangePicker {...filterProps} hoverOnlyIndicatorIcons />;
    }, []);

    const { children, ...columnProps } = props;

    return (
        <DataGridColumn
            width={120}
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
            onRenderHintValue={hintRenderer}
        />
    );
};

const filterValueSerializer: IDataGridFilterValueSerializer<LocalDateRange> = {
    serialize: value => value.stringify(),
    deserialize: value => LocalDateRange.parse(value)
};

export default connect(
    DataGridDateTimeColumn,
    new DependencyAdapter<IDataGridDateTimeColumnProps, IDataGridDateTimeColumnDependencies>(c => ({
        toolkitLocalizationService: c.resolve("IToolkitLocalizationService"),
    }))
);