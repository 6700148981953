import State from "@Toolkit/ReactClient/Common/StateManaging";
import InsurerAddress from "./InsurerAddress";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import InsurerOrganizationTypeId from "@Primitives/InsurerOrganizationTypeId.g";

export default class InsurerOrganization extends EntityStoreBase<InsurerOrganizationId> {
    @State.observable public code: string = "";
    @State.observable public displayCode: string = "";
    @State.observable public name: string = "";
    @State.observable.ref public insurerOrganizationTypeId: InsurerOrganizationTypeId = null;
    @State.observable.ref public address: InsurerAddress = new InsurerAddress();
    @State.observable public isActive: boolean = true;
    @State.observable public isClaimSettlementPartner: boolean = false;
    @State.observable public hasPatientData: boolean = false;

    @State.action.bound
    public setCode(newValue: string) {
        this.code = newValue;
    }

    @State.action.bound
    public setDisplayCode(newValue: string) {
        this.displayCode = newValue;
    }

    @State.action.bound
    public setName(newValue: string) {
        this.name = newValue;
    }

    @State.action.bound
    public setInsurerOrganizationTypeId(newValue: InsurerOrganizationTypeId) {
        this.insurerOrganizationTypeId = newValue;
    }

    @State.action.bound
    public setAddress(newValue: InsurerAddress) {
        this.address = newValue;
    }

    @State.action.bound
    public setIsActive(newValue: boolean) {
        this.isActive = newValue;
    }

    @State.action.bound
    public setIsClaimSettlementPartner(newValue: boolean) {
        this.isClaimSettlementPartner = newValue;
    }
}
