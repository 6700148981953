import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IReportingExtensionProps from "@PluginInterface/BoundedContexts/Reporting/IReportingExtensionProps";
import OncologyDataReportPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Reporting/Components/Panels/ReportingPanel/OncologyDataReportPanel/OncologyDataReportPanel";

@State.observer
export default class HunEHealthInfrastructureReportingExtension extends React.Component<IReportingExtensionProps> {
    private renderContentByReportDefinitionId() {
        const idString = this.props.definition.reportDefinitionIdentifier.value;
        switch (idString) {
            case "HunEHealthInfrastructure_OncologyDataReport":
                return (<OncologyDataReportPanel definition={this.props.definition} />);
            default:
                return null;
        }
    }

    public render() {
        return (
            <>
                {this.props.definition && this.renderContentByReportDefinitionId()}
            </>
        );
    }
}
