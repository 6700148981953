import PointOfCareId from "@Primitives/PointOfCareId.g";
import RoleId from "@Primitives/RoleId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";

export default class PointOfCareRoleAssignmentRule {
    constructor(
        public readonly roleId: RoleId,
        public readonly pointOfCareIds: PointOfCareId[],
        public readonly rowVersion: RowVersion
    ) {

    }
}
