import ITreeNodeStore from "@CommonControls/TreeGrid/ITreeNodeStore";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import OrganizationUnitDefinitionId from "@Primitives/OrganizationUnitDefinitionId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import Address from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/Address";
import PractitionerId from "@Primitives/PractitionerId.g";
import HealthcareProfessionId from "@Primitives/HealthcareProfessionId.g";
import _ from "@HisPlatform/Common/Lodash";

export default class OrganizationUnitTreeNode implements ITreeNodeStore {
    @State.observable.ref public key?: string = null;
    public readonly displayValue: React.ReactNode = null;
    public children?: OrganizationUnitTreeNode[] = [];
    @State.observable public isOpen?: boolean = false;
    @State.observable public isActive?: boolean = false;
    @State.observable public isLoading?: boolean = false;
    @State.observable public code: string = "";
    @State.observable public name: string = "";
    @State.observable public isChecked?: boolean = false;
    @State.observable public filterableValue?: string = "";
    @State.observable public tags: string[] = [];

    @State.computed
    public get label() {
        return this.code + " - " + this.name;
    }

    @State.action.bound
    public setIsOpen(newValue: boolean) {
        this.isOpen = newValue;
    }

    @State.action.bound
    public setIsActive(newValue: boolean) {
        this.isActive = newValue;
    }

    @State.action.bound
    public setIsChecked(newValue: boolean) {
        this.isChecked = newValue;
    }

    public getHealthCareProfessionIds() {
        return this.getChildHealthcareProfessionIds(this);
    }

    private getChildHealthcareProfessionIds(node: OrganizationUnitTreeNode) {
        const ids = node.healthcareProfessionIds;
        if (!node.children?.length) {
            return ids;
        }
        const childrenIds = _.flatten(node.children.map(item => this.getChildHealthcareProfessionIds(item)));
        ids.push(...childrenIds);
        return _.uniqBy(ids, item => item.value);
    }

    constructor(
        public organizationUnitId: OrganizationUnitId,
        public parentId: OrganizationUnitId,
        code: string,
        name: string,
        public healthcareProfessionIds: HealthcareProfessionId[],
        public definitionId: OrganizationUnitDefinitionId,
        public isLeaf: boolean,
        public rowVersion: RowVersion,
        public definitionCode: string,
        public readonly address: Address,
        public readonly managerId: PractitionerId,
        isOpen?: boolean,
        children?: OrganizationUnitTreeNode[],
        tags?: string[] | null
    ) {
        this.code = code;
        this.name = name;
        this.children = children && State.observable(children, {deep: false});
        this.key = organizationUnitId && organizationUnitId.value;
        this.isOpen = isOpen === true;
        this.filterableValue = `${code} - ${name}`;
        this.tags = tags;
    }

    public static create(initializer?: Partial<OrganizationUnitTreeNode>) {
        const ret = new OrganizationUnitTreeNode(null, null, null, null, null, null, null, null, null, null, null, null, null);
        if (initializer) {
            Object.assign(ret, initializer);
        }
        return ret;
    }
}
