import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import ICareActivityTextBlockHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/ICareActivityTextBlockHistoryItem";
import UserColumn from "@HisPlatformControls/DataGridColumns/UserColumn";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import IMasterDetailState from "@CommonControls/Layout/IMasterDetailState";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";

interface ITextBlockHistoryGridProps extends IHasMasterDetailState {
    history: ICareActivityTextBlockHistoryItem[];
}

@State.observer
export default class TextBlockHistoryGrid extends React.Component<ITextBlockHistoryGridProps> {

    @State.observable.ref private dataSource = new InMemoryDataGridDataSource<ICareActivityTextBlockHistoryItem>(() => this.props.history, {columnId: "version", direction: "desc"});

    @State.computed
    private get masterDetailState() { return this.props._masterDetailState as IMasterDetailState<ICareActivityTextBlockHistoryItem>; }

    @State.computed
    private get selectedId() { return this.masterDetailState.selectedItem; }

    @State.bound
    private setSelectedId(item: ICareActivityTextBlockHistoryItem) {
        return this.masterDetailState?.onSelectedItemChange(item);
    }

    private getVersionDisplayValue(value: any, row: any, rowId: React.ReactText, rowIndex: number, isUnderEditing: boolean, dataSetter: (newValue: any) => void) {
        return value + 1;
    }

    public render() {
        return (
            <Ui.DataGrid
                dataSource={this.dataSource}
                actionsColumn={false}
                rowHeight={50}
                isSelectable
                selectedRow={this.selectedId}
                onSelectedRowChange={this.setSelectedId}
                fixedLayout
                fixedHeight="100%"
                changeOnMount
                automationId="textBlockHistoryGrid_dataGrid">
                <DataGridColumn id="version"
                    dataGetter={"version"}
                    onRenderCellValue={this.getVersionDisplayValue}
                    clientSideOrderableValueGetter={"version"}
                    isOrderable
                    header={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.HistoryModal.Columns.Version}
                    width={70}
                />
                <UserColumn
                    dataGetter={"createdById"}
                    header={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.HistoryModal.Columns.CreatedBy}
                />
                <DataGridDateTimeColumn
                    dataGetter={"createdAt"}
                    clientSideOrderableValueGetter={"createdAt"}
                    isOrderable
                    header={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.HistoryModal.Columns.CreatedAt}
                />
            </Ui.DataGrid>
        );
    }
}