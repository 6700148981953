import ILogger, { defaultLoggingConfiguration, ILoggingConfiguration, logLevel } from "@HisPlatform/Services/Definition/Logger/ILogger";
import Di from "@Di";
import bowser from "bowser";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import { mapStackAsync } from "@Toolkit/CommonWeb/StackTraceMapper";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import AsyncMessageQueue from "@Toolkit/CommonWeb/AsyncMessageQueue";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import { ILogEntry } from "@HisPlatform/Services/Implementation/WebWorkerLogger/WebWorkerLogger";

@Di.injectable()
export default class ConsoleLogger implements ILogger {

    public logEvent = new TypedEvent<string>();
    private readonly messageQueue = new AsyncMessageQueue<ILogEntry>(this.mapEntryAndWriteLogAsync);

    public configuration: ILoggingConfiguration = defaultLoggingConfiguration;

    constructor(
        @Di.inject("UserContext") private readonly userContext: UserContext,
    ) {
    }

    public configure(newConfig: ILoggingConfiguration) {
        if (newConfig) {
            this.configuration = newConfig;
        }
    }

    public log(value: any, logLevel: logLevel, additionalMessage?: string) {
        const timestamp = DateTimeService.now().toISOString();
        const currentRoute = window.location.toString();
        const browser = bowser.name + " v" + bowser.version;
        const userName = this.userContext.isAuthenticated ? this.userContext.loginName : "Anonymous";

        this.messageQueue.enqueueAndProcessAsync({
            timestamp,
            currentRoute,
            browser,
            userName,
            severity: logLevel,
            value,
            additionalMessage
        }).catch(err => {
            const internalErrorEntry = `${timestamp} | Internal error in ConsoleLogger: ${(err as Error).name}: ${(err as Error).message}`;
            console.error(internalErrorEntry);
            this.logEvent.emit(internalErrorEntry);
        });
    }

    @State.bound
    private async mapEntryAndWriteLogAsync(entry: ILogEntry) {
        const valueAsString = await this.getValueAsStringAsync(entry.value);

        const dataToLog = [
            entry.timestamp,
            entry.browser,
            entry.severity,
            entry.currentRoute,
            entry.userName,
            !isNullOrUndefined(entry.additionalMessage) ? `${entry.additionalMessage} ${valueAsString}` : valueAsString
        ];

        const logEntry = dataToLog.join(" | ");

        switch (entry.severity) {
            case "error":
                console.error(logEntry);
                break;
            case "warn":
                console.warn(logEntry);
                break;
            case "trace":
                console.trace(logEntry);
                break;
            default:
                console.log(logEntry);
        }

        this.logEvent.emit(logEntry);
    }

    private async getValueAsStringAsync(value: any): Promise<string> {
        try {
            if (value === null || value === undefined) {
                return "N/A";
            } else if (typeof (value.toISOString) === "function") {
                // date
                return value.toISOString();
            } else if (typeof (value) === "object") {
                // error
                if (value instanceof Error) {
                    try {
                        const mappedStack = await mapStackAsync(value);
                        return this.formatError(value.name, value.message, mappedStack);
                    } catch (mappingError) {
                        return this.formatError(value.name, value.message, value.stack);
                    }
                }

                // any object
                return JSON.stringify(value);
            } else {
                return value.toString();
            }
        } catch (formattingError) {
            return `Log formatter error, fallback to JSON: ${JSON.stringify(value)}`;
        }
    }

    private formatError(name: string, message: string, stack?: string) {
        if (stack) {
            return `${name}
${message}

${stack}`;
        } else {
            return `${name}
${message}`;
        }
    }
}