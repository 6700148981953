import State from "@Toolkit/ReactClient/Common/StateManaging";
import PointOfCareId from "@Primitives/PointOfCareId.g";

export default class PlannedScoreItemStore {

    @State.observable.ref public pointOfCareId: PointOfCareId = null;
    @State.observable public plannedScore: number = 0;

    @State.action.bound
    public setPlannedScore(newValue: number) {
        this.plannedScore = newValue;
    }
}