import React from "react";
import _ from "@HisPlatform/Common/Lodash";
import Styles from "./PageBox.less";
import PageBoxHeader, { IPageBoxHeaderProps } from "@CommonControls/PageBox/PageBoxHeader";
import PageBoxBody, { IPageBoxBodyProps } from "@CommonControls/PageBox/PageBoxBody";
import { ICommonControlProps, getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import CompositeClassName, { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import { Icon, Drawer, Button } from "@CommonControls";
import { iconVisualStyleType } from "@CommonControls/Icon";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IDrawerProps } from "@CommonControls/Drawer";
import PageBoxFooter from "@CommonControls/PageBox/PageBoxFooter";

export interface IPageBoxProps extends ICommonControlProps {
    title?: string | React.ReactNode;
    isLoading?: boolean;
    isCollapsible?: boolean;
    hasNoVerticalLine?: boolean;
    iconVisualStyle?: iconVisualStyleType;
    onIsOpenChanged?: (isOpen: boolean) => void;
    isOpen?: boolean;
    isOpenByDefault?: boolean;
    noFadeIn?: boolean;
    noMargin?: boolean;
    drawerProps?: IDrawerProps;
    automationId?: string;
    fullHeight?: boolean;
    widthMode?: "limited" | "unlimited" | "fullwidth";
    isCenterAligned?: boolean;

    headerSize?: "normal" | "large";
    headerNoPadding?: boolean;

    withoutBoxes?: boolean;
}

@State.observer
export default class PageBox extends React.Component<IPageBoxProps> {

    @State.observable private isOpen = this.props.isOpen === undefined ? this.props.isOpenByDefault : this.props.isOpen;

    @State.observable private isLoading = false;
    private isLoadingTimeout: number;

    
    public static Header = PageBoxHeader;
    public static Body = PageBoxBody;
    public static Footer = PageBoxFooter;
    

    private getOpenState = () => this.props.isOpen;

    constructor(props: IPageBoxProps) {
        super(props);

        if (props.isOpen === undefined) {
            this.getOpenState = () => this.isOpen;
        }
    }

    public static defaultProps: Partial<IPageBoxProps> = {
        isLoading: false,
        isCollapsible: false,
        isOpenByDefault: true,
        hasNoVerticalLine: false,
        fullHeight: true,
        widthMode: "limited",
        isCenterAligned: true
    };

    public componentDidMount() {
        if (this.props.isLoading === true) {
            this.setLoadingTimeout();
        }
    }

    public componentDidUpdate(prevProps: IPageBoxProps) {
        if (prevProps.isLoading && !this.props.isLoading) {
            this.clearLoadingTimeout();
        } else if (!prevProps.isLoading && this.props.isLoading) {
            this.setLoadingTimeout();
        }
    }

    private setLoadingTimeout() {
        this.isLoadingTimeout = window.setTimeout(() => {
            this.setIsLoading(true);
        }, 600);
    }

    private clearLoadingTimeout() {
        if (this.isLoadingTimeout) {
            window.clearTimeout(this.isLoadingTimeout);
        }
        this.setIsLoading(false);
    }

    @State.action
    private setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @State.action.bound
    private clickHandler() {
        if (!this.props.isCollapsible) {
            return;
        }

        this.isOpen = !this.getOpenState();

        if (this.props.onIsOpenChanged) {
            this.props.onIsOpenChanged(this.isOpen);
        }
    }

    private renderHeader() {

        const headerStyle = this.props.isCollapsible ? combineClasses(Styles.header, Styles.clickable) : Styles.header;

        const headerClassNames = new CompositeClassName(headerStyle);
        headerClassNames.addIf(this.props.headerSize && this.props.headerSize === "large", Styles.headerLarge);
        headerClassNames.addIf(this.props.headerNoPadding, Styles.headerNopadding);

        if (this.props.title) {
            return (
                <>
                    <div className={headerClassNames.classNames} onClick={this.clickHandler}>
                        <div className={Styles.title}>
                            {typeof this.props.title === "string" && <h1>{this.props.title}</h1>}
                            {typeof this.props.title !== "string" && this.props.title}
                        </div>
                        {this.props.isCollapsible && <div className={Styles.toolbar}><Icon iconName="chevronDown" automationId="pagebox_title_chevronDown"/></div>}
                    </div>
                    {!this.props.hasNoVerticalLine && <hr className={Styles.headerLine} />}
                </>
            );
        }

        if (this.props.children && Array.isArray(this.props.children) && this.props.children[0]) {
            const firstChild = this.props.children[0] as React.ReactElement<any>;
            if (firstChild.type === PageBoxHeader) {
                return (
                    <>
                        <div className={headerClassNames.classNames} onClick={this.clickHandler}>
                            <div className={Styles.title}>{firstChild}</div>
                            {this.props.isCollapsible && <div className={Styles.toolbar}><Icon iconName="chevronDown" automationId="pagebox_child_chevronDown"/></div>}
                        </div>
                        {!this.props.hasNoVerticalLine && <hr className={Styles.headerLine} />}
                    </>
                );
            }
        }

        return null;
    }

    private renderFooter() {
        if (this.props.children && Array.isArray(this.props.children) && this.props.children[0]) {

            const lastChild = this.props.children.find(i => {
                const element = (i as React.ReactElement<any>);
                return element && element.type === PageBoxFooter;
            });

            if (lastChild) {
                return (
                    <>
                        <hr className={Styles.headerLine} />
                        {lastChild}
                    </>
                );
            }
        }
        return null;
    }

    private renderContent() {
        const children = this.props.children;
        if (children && Array.isArray(children) && children[0]) {
            const firstChild = children[0] as React.ReactElement<any>;
            const beginIndex = firstChild.type === PageBoxHeader ? 1 : 0;

            return children.slice(beginIndex).filter(i => {
                const item = i && i as React.ReactElement<any>;
                return !(item && item.type === PageBoxFooter);
            });
        }

        return children;
    }

    public render() {

        const classes = new CompositeClassName();

        if (!this.props.withoutBoxes) {
            classes.add(Styles.box);
            classes.addIf(this.isLoading, Styles.loadingBox);
            classes.addIf(!this.props.noFadeIn, Styles.fadeIn);
            classes.addIf(!!this.props.noMargin, Styles.noMargin);
            classes.addIf(this.props.widthMode === "limited", Styles.limitedWidth);
            classes.addIf(this.props.widthMode === "fullwidth", Styles.fullWidth);
            classes.addIf(this.props.fullHeight, Styles.fullHeightBox);
            classes.addIf(this.props.isCenterAligned === false, Styles.noMarginAuto);
        }

        const htmlProps = getStandardHtmlProps(
            this.props,
            classes.classNames,
        );

        if (this.props.drawerProps) {
            return (
                <div {...htmlProps} data-automation-id={this.props.automationId || undefined}>
                    <Drawer {...this.props.drawerProps}>
                        {this.renderHeader()}
                        {this.getOpenState() && this.renderContent()}
                        {this.getOpenState() && this.renderFooter()}
                    </Drawer>
                </div>
            );
        }

        return (
            <div {...htmlProps} data-automation-id={this.props.automationId || undefined}>
                {this.renderHeader()}
                {this.getOpenState() && this.renderContent()}
                {this.getOpenState() && this.renderFooter()}
            </div>
        );
    }
}