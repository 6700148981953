import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import PermissionDefinitionBuilder from "@PluginInterface/OperationAccessControl/PermissionDefinitionBuilder";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";
import { PermissionDefinitions } from "@HisPlatform/PermissionDefinitions/PermissionDefinitions.g";

export default function configureProductivityPermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry) {
    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Productivity.Worklist.ViewWorklists,
            PermissionDefinitions.WebAppBackend.Patients.SearchPatients
        )
            .withPermissionScopesFor(PermissionDefinitions.Productivity.Worklist.ViewWorklists,
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType, ["PatientBased"]),
            ).withAlias("PatientSearch", PermissionScopeType.worklistType)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.Productivity.Worklist.ViewWorklists)
            .withPermissionScopes(
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType, ["MedicationBased"])
            ).withAlias("ViewMedications", PermissionScopeType.worklistType)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Productivity.Worklist.ViewWorklists,
            PermissionDefinitions.WebAppBackend.Care.ViewCareWorklists
        )
            .withPermissionScopesFor(PermissionDefinitions.Productivity.Worklist.ViewWorklists,
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType, ["CareActivityBased"])
            ).withAlias("ViewCareActivities", PermissionScopeType.worklistType)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Productivity.Worklist.ViewWorklists,
            PermissionDefinitions.WebAppBackend.Patients.ViewMedicalConditions
        )
            .withPermissionScopesFor(PermissionDefinitions.Productivity.Worklist.ViewWorklists,
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType,
                    ["AutonomyDisabilityStatementBased", "CurrentConditionStatementBased", "DeviceUseStatementBased", "FormerConditionStatementBased",
                        "ImmunizationBased", "MedicalAlertStatementBased", "MedicationStatementBased", "PregnancyStatementBased",
                        "ProcedureStatementBased", "RiskAssessmentBased", "TherapyRecommendationBased", "PatientBloodTypeBased"])
            ).withAlias("MedicalConditionBased", PermissionScopeType.worklistType)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.Productivity.Worklist.ViewWorklists)
            .withPermissionScopes(
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType,
                    ["PatientAllergyIntoleranceBased"])
            ).withAlias("PatientAllergyIntoleranceBased", PermissionScopeType.worklistType)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder
            .create(PermissionDefinitions.Productivity.Worklist.EditDefinitions)
            .build());
}
