exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BillableServicesPanel_care-activity-block_Xn7b1 {\n  display: flex;\n  flex-direction: column;\n  border: solid 1px #f4f7fc;\n  border-left: 4px solid #ebebeb;\n  border-left-color: #00b4ec;\n  margin: 8px 4px;\n  border-radius: 2px;\n  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);\n  padding: 0 2px;\n}\n.BillableServicesPanel_care-activity-block_Xn7b1 .BillableServicesPanel_title-row_3aTqn {\n  font-family: TitilliumWebBold;\n  font-size: 14px;\n  flex: 0 0 auto;\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n  justify-content: start;\n  margin-top: 0.5rem;\n}\n.BillableServicesPanel_care-activity-block_Xn7b1 .BillableServicesPanel_title-row_3aTqn span {\n  display: block;\n}\n.BillableServicesPanel_care-activity-block_Xn7b1 .BillableServicesPanel_services-grid_1sKjv {\n  flex: 1;\n}\n.BillableServicesPanel_billed-icon_1mv_j {\n  margin-right: 3px;\n}\n", ""]);

// exports
exports.locals = {
	"care-activity-block": "BillableServicesPanel_care-activity-block_Xn7b1",
	"careActivityBlock": "BillableServicesPanel_care-activity-block_Xn7b1",
	"title-row": "BillableServicesPanel_title-row_3aTqn",
	"titleRow": "BillableServicesPanel_title-row_3aTqn",
	"services-grid": "BillableServicesPanel_services-grid_1sKjv",
	"servicesGrid": "BillableServicesPanel_services-grid_1sKjv",
	"billed-icon": "BillableServicesPanel_billed-icon_1mv_j",
	"billedIcon": "BillableServicesPanel_billed-icon_1mv_j"
};