import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FinancingClassId from "@Primitives/FinancingClassId.g";
import PerformanceReportScorePlanId from "@Primitives/PerformanceReportScorePlanId.g";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";
import PlannedScoreItemStore from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PlannedScoreItemStore";

export default class PerformanceReportScorePlanStore extends EntityStoreBase<PerformanceReportScorePlanId> implements IDirtyChecked {

    @State.observable.ref public planDate: YearMonth = null;
    @State.observable.ref public financingClassId: FinancingClassId = null;
    @State.observable.ref public plannedScoreItems: PlannedScoreItemStore[] = [];

    @State.action.bound
    public setFinancingClassId(newValue: FinancingClassId) {
        this.financingClassId = newValue;
    }

    @State.action.bound
    public setPlanDate(newValue: YearMonth) {
        this.planDate = newValue;
    }

    private _dirtyChecker = new StoreDirtyChecker();

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "operationInfo"
    ];

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }
}