import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import HealthcareProfession from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/HealthcareProfession";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import HealthcareProfessionId from "@Primitives/HealthcareProfessionId.g";
import Di from "@Di";

@Di.injectable()
export default class HealthcareProfessionStoreMapper extends EntityStoreMapper<Proxy.HealthCareProfessionDto, HealthcareProfession> {
    protected storeEntityIdType = HealthcareProfessionId;

    protected applyToStoreCore(target: HealthcareProfession, dto: Proxy.HealthCareProfessionDto) {
        target.name = dto.name;
        target.codes = dto.identifiers.map(x => new Identifier(x.identifierSystemId, x.value));
    }
}
