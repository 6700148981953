import FinancedServiceId from "@Primitives/FinancedServiceId.g";

export class FinancedServiceCodeSelectorItem {
    public id: FinancedServiceId;
    public name: string;
    public code: string;

    constructor({ id = null, name = "", code = "" }: { id?: FinancedServiceId, name?: string, code?: string }) {
        this.id = id;
        this.name = name;
        this.code = code;
    }

    public getHashCode() {
        return `${this.id?.value ?? "null"}_${this.code}`;
    }
}