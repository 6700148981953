import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import AppointmentStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentStatus.g";
import AppointmentStatusSelectBox from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentStatusSelectBox";
import AppointmentStatusBadge from "@HisPlatform/BoundedContexts/Scheduling/Components/Controls/AppointmentStatusBadge";
import AppointmentStatusColumnData from "./AppointmentStatusColumnData";


interface IAppointmentStatusColumnProps extends IDataGridColumnBaseProps {

}


const AppointmentStatusColumn: React.FC<IAppointmentStatusColumnProps> = props => {

    const { children, ...columnProps } = props;

    const valueRenderer = useCallback((value: AppointmentStatusColumnData) => {
        return <AppointmentStatusBadge status={value?.appointmentStatus} cancellationReasonNote={value?.cancellationReasonNote}/>;
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<AppointmentStatus | AppointmentStatus[]>) => {
        return (
            <AppointmentStatusSelectBox
                {...filterProps}
                maxMenuWidth="300px"
                displayMode="selectBox"
                hoverOnlyIndicatorIcons
            />
        );
    }, []);

    return (
        <DataGridColumn
            width={50}
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
            showHint={false}
        />
    );
};
const filterValueSerializer: IDataGridFilterValueSerializer<AppointmentStatus | AppointmentStatus[]> = {
    serialize: value => JSON.stringify(value),
    deserialize: value => JSON.parse(value)
};

export default AppointmentStatusColumn;
