import { IValidationProblemParameterMapper } from "@Toolkit/CommonWeb/ApiAdapter/IValidationProblemParameterMapper";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import Di from "@Di";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import _ from "@HisPlatform/Common/Lodash";
import GenderId from "@Primitives/GenderId.g";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";

@Di.injectable()
export default class HunMedicationRequestValidationParameterMapper implements IValidationProblemParameterMapper {

    constructor(
        @Di.inject("CommonReferenceDataDataStore") private commonReferenceDataStore: CommonReferenceDataDataStore,
    ) { }

    public collectValidationProblemParameterReferenceData(vp: IClientValidationProblem) {
        return;
    }

    public async loadCollectedValidationProblemParameterReferenceDataAsync(): Promise<void> {
        await this.commonReferenceDataStore.gender.ensureLoadedAsync();
    }

    public resolveValidationProblemParameters(vp: IClientValidationProblem): { [id: string]: string; } {
        let results = {};
        if (!isNullOrUndefined(vp.parameters?.MaxPrescribableAmount)) {
            results = {
                ...results,
                MaxPrescribableAmount: vp.parameters.MaxPrescribableAmount
            };
        }
        if (!isNullOrUndefined(vp.parameters?.GenderRestriction)) {
            const genderIdValue = _.get(vp.parameters, "GenderRestriction.Value");
            if (genderIdValue) {
                const genderId = new GenderId(genderIdValue);
                const localizedValue = this.commonReferenceDataStore.gender.get(genderId).displayValue.Name;
                results = {
                    ...results,
                    GenderRestriction: localizedValue
                };
            }
        } 
        if (!isNullOrUndefined(vp.parameters?.AgeMax)) {
            const ageMaxValue = _.get(vp.parameters, "AgeMax");
            results = {
                ...results,
                AgeMax: ageMaxValue + " " + StaticWebAppResources.Common.Label.Year
            };
        } else {
            results = {
                ...results,
                AgeMax: StaticWebAppResources.Common.Label.None
            };
        }
        if (!isNullOrUndefined(vp.parameters?.AgeMin)) {
            const ageMinValue = _.get(vp.parameters, "AgeMin");
            results = {
                ...results,
                AgeMin: ageMinValue + " " + StaticWebAppResources.Common.Label.Year
            };
        } else {
            results = {
                ...results,
                AgeMin: StaticWebAppResources.Common.Label.None
            };
        }
        return results;
    }
}