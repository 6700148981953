import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IEhiError from "@HunEHealthInfrastructurePlugin/Common/Model/IEhiError";
import EhiErrorSummary from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiErrorSummary";
import EhiErrorSeverity from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiErrorSeverity";

interface IEhiCareActivityListInfoPanelProps {
    extensionData: any;
    isFilterOpen: boolean;
}

/** @screen */
@State.observer
export default class EhiCareActivityListInfoPanel extends React.Component<IEhiCareActivityListInfoPanelProps> {

    private codesToRender = [
        "KAT-1010",
        "DOR-1017"
    ];

    private get ehiErrors(): IEhiError[] {
        return this.props.extensionData && this.props.extensionData.EhiErrors?.map((i: any) => ({
            // mapping extensionData from original type EhiErrorDto (server side serialized, not from API)
            code: i.Code,
            message: i.Message,
            moduleName: i.ModuleName,
            severity: i.EhiErrorSeverity as any as EhiErrorSeverity
        } as IEhiError)) || [];
    }

    public render() {
        return (
            <>
                <EhiErrorSummary
                    isFilterOpen={this.props.isFilterOpen}
                    errors={this.ehiErrors}
                    codeFilter={this.codesToRender}
                    notConfiguredCodesDisplayMode="toast"
                />
            </>
        );
    }
}