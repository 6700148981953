import moment from "moment";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import DayOfWeek from "@CommonControls/Scheduler/DayOfWeek";
import IRect from "@CommonControls/Scheduler/IRect";
import SchedulerScale from "./SchedulerScale";
import SchedulerViewMode from "./SchedulerViewMode";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";

export interface ISchedulerDependencies {
    currentCultureProvider: ICurrentCultureProvider;
    dateTimeFormatProvider: IDateTimeFormatProvider;
}

export default interface ISchedulerProps {
    _dependencies?: ISchedulerDependencies;
    className?: string;
    events?: ISchedulerEvent[];
    blockingEvents?: ISchedulerEvent[];
    invalidEvents?: ISchedulerEvent[];
    highlightFreeSpace?: boolean;
    disableFreeSpace?: boolean;
    timeSlotMajorMinutes?: number;
    timeSlotMinorCount?: number;
    listView?: React.ReactNode;
    currentMoment?: moment.Moment;
    workHoursStart?: TimeOfDay;
    workHoursEnd?: TimeOfDay;
    workDaysOfWeek?: DayOfWeek[];
    showFreeSpaceValue?: boolean;
    hideMajorTime?: boolean;
    isReadOnly?: boolean;
    entryComponent?: React.ComponentType<{ event: ISchedulerEvent, handlers: ISchedulerEntryEventHandlers, isPlaceable?: boolean, isReadOnly? :boolean}>;
    newEntryComponent?: React.ComponentType<{ event: ISchedulerEvent, handlers: ISchedulerEntryEventHandlers, isPlaceable?: boolean, isReadOnly?: boolean}>;
    onGetEntryClassName?: (event: ISchedulerEvent, isPlaceable?: boolean) => string;
    onGetBlockClassName?: (block: ISchedulerEvent) => string;
    onGetInvalidClassName?: (invalid: ISchedulerEvent, viewMode: SchedulerViewMode) => string;
    onGetNewEntryClassName?: (entry: ISchedulerEvent, isPlaceable?: boolean) => string;
    onGetNewEntryOffset?: (entry: ISchedulerEvent) => IRect;
    onEntryChange?: (event: ISchedulerEvent, newStart: moment.Moment, newEnd: moment.Moment) => void;
    
    isNewEntryPlaceable?: ((slotStart: moment.Moment) => boolean) | boolean;
    onPlaceNewEntry?: (slotStart: moment.Moment) => void;
    onCancelNewEntry?: () => void;
    
    isEntryEditable?: ((event: ISchedulerEvent) => boolean) | boolean;
    onEditEntry?: (event: ISchedulerEvent) => void;
    onCancelEntry?: (event: ISchedulerEvent) => void;

    onSchedulerScaleChange?: (scale: SchedulerScale) => void;
    onScheduleViewModeChange?: (viewMode: SchedulerViewMode) => void;
    useExplicitToolbar?: boolean;
    noItemsMessage?: string;
    scale?: SchedulerScale;
    viewMode?: SchedulerViewMode;
    onRenderAdditionToolbarComponent?: () => React.ReactNode;
    useSelectBoxForScale?: boolean;
    showNewEntryOn?: ShowNewEntryOn;
    newEntryDuration?: number;
}

export interface ISchedulerEvent {
    readonly id: number;
    startTime: moment.Moment;
    endTime: moment.Moment;
    getDurationInMinutes(): number;
    readonly isReadOnly?: boolean;
}

export interface IEntryData {
    event: ISchedulerEvent;
    offset: IRect;
}

export interface INewEntryData extends IEntryData {
    hoveredElementRect: IRect;
    hoveredElementDuration: number;
}

export interface ISchedulerEntryEventHandlers {
    onMouseEnter?: (event: ISchedulerEvent, e: React.MouseEvent<HTMLElement>) => void;
    onMouseLeave?: (event: ISchedulerEvent, e: React.MouseEvent<HTMLElement>) => void;
    onClick?: (event: ISchedulerEvent, e: React.MouseEvent) => void;
}

export enum ShowNewEntryOn {
    Cells,
    Entries,
    CellsAndEntries
}

export enum EntryType {
    Entry,
    Block,
    Invalid
}
