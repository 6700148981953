import { iconNameType } from "@CommonControls/Icon";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import IChildItemDisplaySettings from "@Toolkit/ReactClient/DynamicNavigation/IChildItemDisplaySettings";
import ITopLevelDisplaySettings from "@Toolkit/ReactClient/DynamicNavigation/ITopLevelItemDisplaySettings";

export default class NavMenuActionItem implements INavMenuItem {
    private constructor(
        public readonly identifier: string,
        public readonly parentIdentifier: string,
        public readonly frontendAction: FrontendActionBase,
        public readonly permissionCheckOperationNames: string,
        public readonly automationId: string,
        public readonly side?: "left" | "right",
        public readonly text?: string,
        public readonly iconName?: iconNameType,
        public readonly order?: number
    ) {
    }

    public static createChild (
        identifier: string,
        parentIdentifier: string,
        frontendAction: FrontendActionBase,
        displaySettings: IChildItemDisplaySettings
    ) {
        return new NavMenuActionItem(identifier, parentIdentifier, frontendAction, null, displaySettings.automationId, null, displaySettings.text, displaySettings.iconNameType, displaySettings.order);
    }

    public static createChildWithPermissionOperationCheck (
        identifier: string,
        parentIdentifier: string,
        frontendAction: FrontendActionBase,
        permissionCheckOperationNames: string,
        displaySettings: IChildItemDisplaySettings
    ) {
        return new NavMenuActionItem(identifier, parentIdentifier, frontendAction, permissionCheckOperationNames, displaySettings.automationId, null, displaySettings.text, displaySettings.iconNameType, displaySettings.order);
    }

    public static createTopLevel (
        identifier: string,
        frontendAction: FrontendActionBase,
        displaySettings: ITopLevelDisplaySettings
    ) {
        return new NavMenuActionItem(identifier, null, frontendAction, null, displaySettings.automationId, displaySettings.side, displaySettings.text, displaySettings.iconNameType, displaySettings.order);
    }
}
