import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import { parseUseCaseStateFromUrlParam, getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import ContagiousPatientReportId from "@Primitives/ContagiousPatientReportId.g";
import { HisErrorBoundary } from "@HisPlatformControls";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import StandaloneContagiousPatientReportPanel from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Panels/ContagiousPatientReportPanels/StandaloneContagiousPatientReportPanel";
import contagiousPatientReportStandaloneRouteDefinitions from "@HunSocialSecurityPlugin/UseCases/ContagiousPatientReportsUseCase/ContagiousPatientReportRoutes";


interface IContagiousPatientReportPageDependencies {
    applicationContext: ApplicationContext;
}

interface IContagiousPatientReportPageProps extends IRoutingFrameContentProps {
    _dependencies?: IContagiousPatientReportPageDependencies;
}

@State.observer
class ContagiousPatientReportPage extends React.Component<IContagiousPatientReportPageProps> {

    public componentWillMount() {
    }

    public componentWillUnmount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(null);
    }

    @State.computed
    private get selectedRowId(): string {
        if (this.props.routingController.currentRoute.parameters.selectedRowId) {
            return this.props.routingController.currentRoute.parameters.selectedRowId;
        }
        return null;
    }

    @State.computed
    private get useCaseState() {
        if (!this.props.routingController.currentRoute.parameters.useCase) {
            return null;
        }
        const useCaseState = this.props.routingController.currentRoute.parameters.useCase;

        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.bound
    private setUseCaseState(rowId: string, useCaseState: INDataUseCaseState) {
        const contagiousPatientReportId = useCaseState?.useCaseArguments?.find(a => a.value instanceof ContagiousPatientReportId)?.value;

        this.props.routingController.push(contagiousPatientReportStandaloneRouteDefinitions.contagiousPatientReports.makeRoute({
            ...this.props.routingController.currentRoute.parameters,
            useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments),
            selectedRowId: contagiousPatientReportId?.value ?? rowId
        }));
    }

    public render() {
        return (
            <div>
                <HisErrorBoundary>
                    <SingleLayout>
                        <StandaloneContagiousPatientReportPanel
                            useCaseState={this.useCaseState}
                            onChange={this.setUseCaseState}
                            selectedRowId={this.selectedRowId} />
                    </SingleLayout>
                </HisErrorBoundary>
            </div>
        );
    }
}

export default connect(
    withHisErrorBoundary(ContagiousPatientReportPage),
    new DependencyAdapter<IContagiousPatientReportPageProps, IContagiousPatientReportPageDependencies>(container => {
        return {
            applicationContext: container.resolve("ApplicationContext")
        };
    })
);
