import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import ITextualPrescriptionTemplateListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/ITextualPrescriptionTemplateListItem";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import PrescriptionTemplateType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionTemplateType";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import UserColumn from "@HisPlatform/Components/HisPlatformControls/DataGridColumns/UserColumn";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import { IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import PrescriptionsToBeCreated from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionsToBeCreated/PrescriptionsToBeCreated";
import { PrescriptionType } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationSelectorDialog/MedicationSelectorDialog";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import { IPrescriptionsToBeCreatedListItem } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionsToBeCreated/IPrescriptionsToBeCreatedListItem";

interface ITextualPrescriptionTemplateListDependencies {
    localizationService: ILocalizationService;
}

interface ITextualPrescriptionTemplateListProps extends IHasMasterDetailState {
    _dependencies?: ITextualPrescriptionTemplateListDependencies;
    list: ITextualPrescriptionTemplateListItem[];
    onBack: () => void;
    isTypeFilterable?: boolean;
    variant?: "prescription" | "templateList";
    onSelect?: (item: ITextualPrescriptionTemplateListItem) => void;
    prescriptionType?: PrescriptionType;
    setPrescriptionType?: (prescriptionType: PrescriptionType) => void;
    selectedMedications?: IPrescriptionsToBeCreatedListItem[];
    onRemoveMedicationFromSelectedMedications?: (row: IPrescriptionsToBeCreatedListItem) => void;
    medicationSubsidyClaimTypeId?: MedicationSubsidyClaimTypeId;
    onMedicationSubsidyClaimTypeIdChange?: (newValue: MedicationSubsidyClaimTypeId) => void;
    generalSearchValue?: string;
    setGeneralSearch?: (newValue: string) => void;
    addMedicationFormulaPrescription?: () => void;
}

/** @screen */
@State.observer
class TextualPrescriptionTemplateList extends React.Component<ITextualPrescriptionTemplateListProps> {

    public static defaultProps: Partial<ITextualPrescriptionTemplateListProps> = {
        isTypeFilterable: true,
        variant: "templateList"
    };

    private dataSource = new InMemoryDataGridDataSource(() => (this.props.list));

    @State.observable.ref public filterStore: any = null;

    @State.action.bound
    public setFilterStore(filterStore: any) {
        this.filterStore = filterStore;
    }

    @State.bound
    private onSelectClickedFactory(listItem: ITextualPrescriptionTemplateListItem) {
        return this.props._masterDetailState?.onSelectedItemChange ? () => {
            this.props._masterDetailState.onSelectedItemChange(listItem.id);
        } : () => {
            this.props.onSelect(listItem);
        };
    }

    public renderTypeIcon(value: PrescriptionTemplateType) {
        switch (value) {
            case PrescriptionTemplateType.Formula:
                return <Ui.Icon iconName="prescription_formula" />;
            case PrescriptionTemplateType.Other:
                return <Ui.Icon iconName="prescription_other_textual" />;
        }
    }

    @State.bound
    public renderTypeText(value: PrescriptionTemplateType) {
        return this.props._dependencies.localizationService.localizeEnum(PrescriptionTemplateType[value], "PrescriptionTemplateType")?.Name || "";
    }

    @State.bound
    public renderActions(value: any, row: ITextualPrescriptionTemplateListItem) {
        return (
            <Ui.Button
                iconName={this.props.variant === "templateList" ? "pen" : "plus"}
                visualStyle={this.props.variant === "templateList" ? undefined : "standard"}
                onClick={this.onSelectClickedFactory(row)} float="left"
                automationId={row.name + "_actionButton"}
            />
        );
    }

    private selectId(row: ITextualPrescriptionTemplateListItem) {
        return row?.id?.value || "";
    }

    private renderIcon(value: string, row: ITextualPrescriptionTemplateListItem) {
        return <Ui.Icon size="large" iconName={row.type === PrescriptionTemplateType.Formula ? "prescription_formula" : "prescription_other_textual"} />;
    }

    private renderExtraFilter() {
        if (this.props.variant === "prescription") {
            return (
                < Ui.Flex >
                    <Ui.Flex.Item xs={8}>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={8}>
                                <Ui.UniversalEnumSelector
                                    enumOrigin="server"
                                    displayMode="groupedRadioButtons"
                                    enumName={"PrescriptionType"}
                                    enumType={PrescriptionType}
                                    value={this.props.prescriptionType}
                                    onChange={this.props.setPrescriptionType}
                                    automationId="prescriptionTypeSelector"
                                />

                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <Ui.AddButton
                                    size={"compact"}
                                    style={{ margin: "0", padding: "4px 3px 4px 3px", height: "100%" }}
                                    automationId={"addMedicationFormulaPrescription"}
                                    onClick={this.props.addMedicationFormulaPrescription}
                                    text={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Buttons.AddMedicationFormula} />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Flex.Item>

                    <Ui.Flex.Item xs={4}>
                        <PrescriptionsToBeCreated items={this.props.selectedMedications} removeMedicationItem={this.props.onRemoveMedicationFromSelectedMedications} />
                    </Ui.Flex.Item>
                </Ui.Flex >
            );
        }
        return null;
    }
    public render() {
        return (
            <>
                {this.renderExtraFilter()}
                <Ui.DataGrid
                    dataSource={this.dataSource}
                    rowId={this.selectId}
                    rowComparer="rowId"
                    selectedRow={this.props._masterDetailState?.selectedItem?.id?.value || null}
                    isSelectable
                    fixedLayout
                    actionsColumn
                    onBack={this.props.onBack}
                    hasBackButton={!!this.props.onBack}
                    generateInitialFilterStore
                    changeOnMount
                    automationId="textualPrescriptionTemplateList_dataGrid"
                >
                    {this.props.variant === "prescription" && <DataGridColumn onRenderCellValue={this.renderActions} width="60px" />}
                    <DataGridColumn onRenderCellValue={this.renderIcon} width="32px" />
                    <DataGridColumn
                        id={"name"}
                        header={StaticHunSocialSecurityMedicationRequestResources.TextualPrescriptionTemplatesPanel.Labels.Name}
                        dataGetter={"name"}
                        leftPadding="small"
                        isFilterable
                        isOrderable
                    />
                    <UserColumn
                        id={"lastModifiedBy"}
                        header={StaticHunSocialSecurityMedicationRequestResources.TextualPrescriptionTemplatesPanel.Labels.LastModifiedBy}
                        dataGetter={"lastModifiedBy"}
                        isFilterable
                        isOrderable
                        isVisible={!this.props._masterDetailState?.isDetailOpen || this.props.variant === "prescription"}
                    />
                    <DataGridDateTimeColumn
                        id={"lastModifiedAt"}
                        header={StaticHunSocialSecurityMedicationRequestResources.TextualPrescriptionTemplatesPanel.Labels.LastModifiedAt}
                        dataGetter={"lastModifiedAt"}
                        isFilterable
                        isOrderable
                        isVisible={!this.props._masterDetailState?.isDetailOpen || this.props.variant === "prescription"}
                    />
                    {this.props.variant === "templateList" && <DataGridColumn onRenderCellValue={this.renderActions} width="60px" />}
                </Ui.DataGrid>
            </>
        );
    }
}

export default connect(
    TextualPrescriptionTemplateList,
    new DependencyAdapter<ITextualPrescriptionTemplateListProps, ITextualPrescriptionTemplateListDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService")
    }))
);