import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import CareActivityId from "@Primitives/CareActivityId.g";
import IDocumentFragment from "@CommonControls/DocumentEditor/IDocumentFragment";

export interface IInsertServiceRequestResultDialogParams extends IModalParams {
    careActivityId: CareActivityId;
    displayMode?: "inline" | "modal";
}


export interface IInsertServiceRequestResultDialogResult {
    selected: IDocumentFragment;
}

export default class InsertServiceRequestResultDialogParams implements IModalParams {
    public static type = "InsertServiceRequestReusltDialog";
    public get type() { return InsertServiceRequestResultDialogParams.type; }

    constructor(
        public careActivityId: CareActivityId,
        public displayMode?: "inline" | "modal"
    ) { }
}