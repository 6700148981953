import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { mapInsurance } from "./PatientStoreMapper";
import PatientId from "@Primitives/PatientId.g";
import Di from "@Di";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import InsuranceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Insurance/InsuranceStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";

@Di.injectable()
export default class PatientApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IPatientRegisterClient") private apiClient: Proxy.IPatientRegisterClient
    ) {
        super();
    }

    public loadInsurancesAsync(id: PatientId) {
        if (!id) {
            throw new Error("PatientId needed for getPatientsInsurancesQuery.");
        }
        return this.processOperationAsync(
            new SimpleStore<InsuranceStore[]>(),
            async target => {
                const result = await this.apiClient.getPatientsInsurancesQueryAsync(CreateRequestId(), id.value);
                target.operationInfo = createOperationInfo(result);
                target.value = result.insurances.map((dto: Proxy.InsuranceDto) => mapInsurance(dto));
            }
        );
    }
}