import AppointmentCancellationReasonId from "@Primitives/AppointmentCancellationReasonId.g";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";


export interface IAppointmentCancellationDialogParams {
    appointmentScheduleId: AppointmentScheduleEntryId;
    shouldExecuteDelete: boolean;
}

export interface IAppointmentCancellationDialogResult {
    appointmentDeleted: boolean;
    dialogData: {appointmentCancellationReasonId: AppointmentCancellationReasonId, additionalText: string};
}

export default class AppointmentCancellationDialogParams implements IAppointmentCancellationDialogParams {
    
    public static type = "AppointmentCancellationDialog";
    public get type() { return AppointmentCancellationDialogParams.type; }

    constructor(public readonly appointmentScheduleId: AppointmentScheduleEntryId, public readonly shouldExecuteDelete: boolean) {
    }
}