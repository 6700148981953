import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import {SelectBox} from "@CommonControls";
import {dispatchAsyncErrors} from "@Toolkit/CommonWeb/AsyncHelpers";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import InsuranceId from "@Primitives/InsuranceId.g";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import PatientId from "@Primitives/PatientId.g";
import PatientApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/Patient/PatientApiAdapter";
import CoverageType from "@Primitives/CoverageType";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IInsurerOrganization from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/IInsurerOrganization";
import IInsurancePlan from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/IInsurancePlan";

interface IPatientInsuranceSelectBoxDependencies {
    financeStore: FinanceReferenceDataStore;
    patientApiAdapter: PatientApiAdapter;
    localizationService: ILocalizationService;
}

interface IPatientInsuranceSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IPatientInsuranceSelectBoxDependencies;
    patientId: PatientId;
}

@State.observer
class PatientInsuranceSelectBox extends React.Component<IPatientInsuranceSelectBoxProps> {

    @State.observable.ref public items: IPatientInsuranceSelectBoxItem[] = [];

    @State.action.bound
    private setItems(newItems: IPatientInsuranceSelectBoxItem[]) {
        this.items = newItems;
    }

    private getText(org: IInsurerOrganization, plan: IInsurancePlan) {
        if (plan) {
            return `${org.name} - ${plan.name}`;
        }
        return org ? org.name : "";
    }

    @State.bound
    private renderCustomValue(props: any): React.ReactNode {
        const selectBoxItem = this.items.find(i => i.value === props.data.value);
        
        return selectBoxItem.longDisplayValue ?? selectBoxItem.text;
    }

    private getDisplayValue(org: IInsurerOrganization, plan: IInsurancePlan) {
        if (plan) {
            return <><b>{org.name}</b>{` - ${plan.name}`}</>;
        }
        return org ? org.name : "";
    }

    public render() {
        return (
            <SelectBox
                {...this.props}
                items={this.items}
                customValueRenderer={this.renderCustomValue}
            />
        );
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IPatientInsuranceSelectBoxProps) {
        if (prevProps.patientId !== this.props.patientId) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
        if (prevProps.disabled !== this.props.disabled) {
            this.setValueIfOneOptionExists();
        }
    }

    @State.bound
    private async loadAsync() {
        if (this.props.patientId) {
            const insurances = await this.props._dependencies.patientApiAdapter.loadInsurancesAsync(this.props.patientId);
            if (insurances.operationWasSuccessful) {
                const orgIds = insurances.value.map(item => item.insurerOrganizationId);
                const planIds = insurances.value.map(item => item.insurancePlanId).filter(item => item !== null);

                await this.props._dependencies.financeStore.insurerOrganizationMap.ensureLoadedAsync(orgIds);
                await this.props._dependencies.financeStore.insurancePlanMap.ensureLoadedAsync(planIds);

                this.setItems(insurances.value.map(i => {
                    const org = this.props._dependencies.financeStore.insurerOrganizationMap.get(i.insurerOrganizationId);
                    const plan = i.insurancePlanId && this.props._dependencies.financeStore.insurancePlanMap.get(i.insurancePlanId);
                    return {
                        value: i.id,
                        text: this.getText(org, plan),
                        longDisplayValue: this.getDisplayValue(org, plan)
                    } as IPatientInsuranceSelectBoxItem;
                }).concat([{
                    value: null,
                    text: this.props._dependencies.localizationService.localizeEnum(CoverageType[CoverageType.SelfPay], "CoverageType").Name
                }]));

                this.setValueIfOneOptionExists();
            }
        }
    }

    private setValueIfOneOptionExists() {
        const nonNullItems = this.items.filter(i => !!i.value);
        if (this.props.onChange &&
            !this.props.disabled &&
            nonNullItems &&
            nonNullItems.length === 1 &&
            this.props.value === undefined // null signifies coverageType === SelfPay
        ) {
            this.props.onChange(nonNullItems[0].value);
        }
    }
}

interface IPatientInsuranceSelectBoxItem extends ISelectBoxItem<InsuranceId> {

}

export default connect(
    PatientInsuranceSelectBox,
    new DependencyAdapter<IPatientInsuranceSelectBoxProps, IPatientInsuranceSelectBoxDependencies>(container => {
        return {
            financeStore: container.resolve("FinanceReferenceDataStore"),
            patientApiAdapter: container.resolve("PatientApiAdapter"),
            localizationService: container.resolve("ILocalizationService")
        };
    })
);
