import React from "react";
import MasterDetailDetail from "@CommonControls/Layout/MasterDetailDetail";
import { SaveButton, Button, CheckBox } from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { useFormDefinitionMasterDetailPanelStore } from "./FormDefinitionMasterDetailPanelStoreProvider";
import FormPanel from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";
import FormLayoutEditor from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditor";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import Form from "@Toolkit/FormEngine/Model/Form";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";

/** @screen */
function FormDefinitionDetail() {

    const store = useFormDefinitionMasterDetailPanelStore();
    return (
        <MasterDetailDetail
            title={store.selectedDetail?.multiLingualDisplayName.getWithCurrentCultureCodeOrWithDefaultCultureCode(store.cultureCodeProvider.cultureCode)}
            passStateToChild={false}
            toolbar={(
                <>
                    <CheckBox displayMode="switch" label="Előnézet" value={store.isPreviewMode} onChange={store.setPreviewMode} automationId="PreviewMode_CheckBox" style={{ width: "unset", paddingTop: 2 }} />
                    <Button automationId="exportFormDefinition_button" onClick={store.export} iconName="align_bottom" />
                    <Button automationId="importFormDefinition_button" onClickAsync={store.importAsync} iconName="align_top" />
                    <SaveButton automationId="saveFormDefinition_button" onClickAsync={store.saveAsync} />
                </>
            )}
        >
            {store.isPreviewMode ? (
                <FormPanel
                    form={store.previewForm}
                    definitionId={store.selectedId}
                    formDefinition={store.previewFormDefinition}
                />
            ) : (
                <FormLayoutEditor handle={store.editorHandle} />
            )}

        </MasterDetailDetail>
    );
}

export default State.observer(FormDefinitionDetail);
