import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import AllergyIntoleranceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/ReferenceData/AllergyIntoleranceApiAdapter";
import AllergyIntoleranceId from "@Primitives/AllergyIntoleranceId.g";
import PatientRegisterReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/PatientRegisterReferenceDataStore";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import { AllergyIntoleranceCodeSelectorItem } from "@HisPlatformControls/AllergyIntoleranceCodeSelector/AllergyIntoleranceCodeSelectorItem";
import * as HisUi from "@HisPlatformControls";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IAllergyIntoleranceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/IAllergyIntoleranceVersion";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import AllergyIntoleranceCategoryId from "@Primitives/AllergyIntoleranceCategoryId.g";
import { IComplexSearchItemGroup } from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchPanel";
import MedicationsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicationsApiAdapter";
import SubstanceId from "@Primitives/SubstanceId.g";
import MedicationId from "@Primitives/MedicationId.g";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import InternationalNonproprietaryNameId from "@Primitives/InternationalNonproprietaryNameId.g";
import InternationalNonproprietaryNamesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/InternationalNonproprietaryNamesApiAdapter";

interface IAllergyIntoleranceCodeSelectorDependencies {
    patientRegisterReferenceDataStore: PatientRegisterReferenceDataStore;
    allergyIntoleranceApiAdapter: AllergyIntoleranceApiAdapter;
    medicationsApiAdapter: MedicationsApiAdapter;
    internationalNonproprietaryNamesApiAdapter: InternationalNonproprietaryNamesApiAdapter;
    localizationService: IToolkitLocalizationService;
    careReferenceDataStore: CareReferenceDataStore;
}

interface IAllergyIntoleranceCodeSelectorProps extends ICodeSelectorCommonProps<AllergyIntoleranceCodeSelectorItem> {
    _dependencies?: IAllergyIntoleranceCodeSelectorDependencies;
}

@State.observer
class AllergyIntoleranceCodeSelector extends React.Component<IAllergyIntoleranceCodeSelectorProps> {
    private get itemGroups(): IComplexSearchItemGroup[] {
        return ([
            {
                name: "Food",
                localizedName: StaticCareResources.PatientRegister.PatientAllergyIntoleranceRegister.ComplexSearchTabs.FoodTab,
                icon: "intolerance"
            },
            {
                name: "Environment",
                localizedName: StaticCareResources.PatientRegister.PatientAllergyIntoleranceRegister.ComplexSearchTabs.EnvironmentTab,
                icon: "language"
            },
            {
                name: "Medication",
                localizedName: StaticCareResources.PatientRegister.PatientAllergyIntoleranceRegister.ComplexSearchTabs.MedicationTab,
                icon: "pills"
            },
            {
                name: "Substance",
                localizedName: StaticCareResources.PatientRegister.PatientAllergyIntoleranceRegister.ComplexSearchTabs.SubstanceTab,
                icon: "laboratory_medicine"
            },
            {
                name: "InternationalNonproprietaryName",
                localizedName: StaticCareResources.PatientRegister.MedicationStatementRegister.ComplexSearchTabs.InternationalNonproprietaryNameTab,
                icon: "language"
            }
        ]);
    }

    private get dependencies() {
        return this.props._dependencies;
    }

    @State.bound
    private async getDisplayValueAsync(value: AllergyIntoleranceCodeSelectorItem) {

        let item;
        if (value.id instanceof AllergyIntoleranceId) {
            if (!value.text) {
                item = await this.dependencies.careReferenceDataStore.allergyIntolerances.getOrLoadAsync({
                    id: value.id,
                    validOn: this.props.validOn ? this.props.validOn : LocalDate.today()
                });
            } else {
                item = { name: value.text };
            }
        } else if (value.id instanceof MedicationId) {
            if (!value.text) {
                item = await this.dependencies.careReferenceDataStore.medications.getOrLoadAsync({
                    id: value.id,
                    validOn: this.props.validOn ? this.props.validOn : LocalDate.today()
                });
            } else {
                item = { name: value.text };
            }
        } else if (value.id instanceof SubstanceId) {
            if (!value.text) {
                item = await this.dependencies.careReferenceDataStore.substances.getOrLoadAsync({
                    id: value.id,
                    validOn: this.props.validOn ? this.props.validOn : LocalDate.today()
                });
            } else {
                item = { name: value.text };
            }
        } else if (value.id instanceof InternationalNonproprietaryNameId) {
            if (!value.text) {
                item = await this.dependencies.careReferenceDataStore.internationalNonproprietaryNames.getOrLoadAsync(value.id);
            } else {
                item = { name: value.text };
            }
        } else {
            item = { name: value.text };
        }
        return item.name;
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const results = await this.dependencies.allergyIntoleranceApiAdapter.searchAllergyIntolerancesExtendedByFreeTextAsync(
            text,
            10,
            this.props.validOn ? this.props.validOn : LocalDate.today()
        );

        if (results.operationWasSuccessful) {

            const allergies = results.value.map(x => {
                const allergyIntoleranceCategory = this.dependencies.patientRegisterReferenceDataStore.allergyIntoleranceCategory.get(x.categoryId);
                let textOfQuickSearch = `${x.name}`;
                if (!isNullOrUndefined(allergyIntoleranceCategory)) {
                    textOfQuickSearch += ` (${allergyIntoleranceCategory?.displayValue?.Name})`;
                }
                
                if (x.allergyIntoleranceId) {
                    return new AllergyIntoleranceCodeSelectorItem({
                        id: x.allergyIntoleranceId,
                        name: x.name,
                        code: x.code,
                        text: textOfQuickSearch
                    });
                }
                if (x.medicationId) {
                    return new AllergyIntoleranceCodeSelectorItem({
                        id: x.medicationId,
                        name: x.name,
                        code: x.code,
                        text: textOfQuickSearch
                    });
                }
                if (x.substanceId) {
                    return new AllergyIntoleranceCodeSelectorItem({
                        id: x.substanceId,
                        name: x.name,
                        code: x.code,
                        text: textOfQuickSearch
                    });
                }
                if (x.internationalNonproprietaryNameId) {
                    return new AllergyIntoleranceCodeSelectorItem({
                        id: x.internationalNonproprietaryNameId,
                        name: x.name,
                        code: x.code,
                        text: textOfQuickSearch
                    });
                }
                return null;
            });

            const referenceAllergyIntolerances = allergies.filter(allergy => allergy.id instanceof AllergyIntoleranceId);
            const substances = allergies.filter(allergy => allergy.id instanceof SubstanceId);
            const medications = allergies.filter(allergy => allergy.id instanceof MedicationId);
            const internationalNonproprietaryNames = allergies.filter(allergy => allergy.id instanceof InternationalNonproprietaryNameId);
            await this.dependencies.careReferenceDataStore.allergyIntolerances.ensureLoadedAsync(referenceAllergyIntolerances.map(allergy => ({
                id: allergy.id,
                validOn: this.props.validOn ? this.props.validOn : LocalDate.today()
            } as IEntityVersionSelector<AllergyIntoleranceId>)));
            await this.dependencies.careReferenceDataStore.substances.ensureLoadedAsync(substances.map(allergy => ({
                id: allergy.id,
                validOn: this.props.validOn ? this.props.validOn : LocalDate.today()
            } as IEntityVersionSelector<AllergyIntoleranceId>)));
            await this.dependencies.careReferenceDataStore.medications.ensureLoadedAsync(medications.map(allergy => ({
                id: allergy.id,
                validOn: this.props.validOn ? this.props.validOn : LocalDate.today()
            } as IEntityVersionSelector<AllergyIntoleranceId>)));
            await this.dependencies.careReferenceDataStore.internationalNonproprietaryNames.ensureLoadedAsync(internationalNonproprietaryNames.map(allergy => allergy.id));
            return allergies;
        }
        return null;
    }

    @State.bound
    private addNewItem(text: string): AllergyIntoleranceCodeSelectorItem {
        return new AllergyIntoleranceCodeSelectorItem({ text: text });
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: IAllergyIntoleranceVersion[], group: IComplexSearchItemGroup) {
        const validOn = this.props.validOn ? this.props.validOn : LocalDate.today();
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;
        let results;
        switch (group.name) {
            case "Food":
                results = await this.props._dependencies.allergyIntoleranceApiAdapter.searchAllergyIntolerance(
                    filterText,
                    validOn,
                    customOrdering,
                    paging,
                    AllergyIntoleranceCategoryId.food);
                await this.dependencies.careReferenceDataStore.allergyIntolerances.ensureLoadedAsync(results.value.items.map(value => ({
                    id: value.id,
                    validOn
                } as IEntityVersionSelector<AllergyIntoleranceId>)));
                return results.value;
            case "Environment":
                results = await this.props._dependencies.allergyIntoleranceApiAdapter.searchAllergyIntolerance(
                    filterText,
                    validOn,
                    customOrdering,
                    paging,
                    AllergyIntoleranceCategoryId.environment);
                await this.dependencies.careReferenceDataStore.allergyIntolerances.ensureLoadedAsync(results.value.items.map(value => ({
                    id: value.id,
                    validOn
                } as IEntityVersionSelector<AllergyIntoleranceId>)));
                return results.value;
            case "Medication":
                results = await this.props._dependencies.medicationsApiAdapter.medicationSelectorQueryAsync(
                    filterText,
                    customOrdering,
                    paging);
                await this.dependencies.careReferenceDataStore.medications.ensureLoadedAsync(results.value.items.map(value => ({
                    id: value.id,
                    validOn
                } as IEntityVersionSelector<MedicationId>)));
                return results.value;
            case "Substance":
                results = await this.props._dependencies.medicationsApiAdapter.substanceSelectorQueryAsync(
                    filterText,
                    customOrdering,
                    paging);
                await this.dependencies.careReferenceDataStore.substances.ensureLoadedAsync(results.value.items.map(value => ({
                    id: value.id,
                    validOn
                } as IEntityVersionSelector<SubstanceId>)));
                return results.value;
                case "InternationalNonproprietaryName":
                    results = await this.props._dependencies.internationalNonproprietaryNamesApiAdapter.complexSearchInternationalNonproprietaryNames(
                        filterText,
                        customOrdering,
                        paging);
                    await this.dependencies.careReferenceDataStore.internationalNonproprietaryNames.ensureLoadedAsync(results.value.items.map(value => value.id));
                    return results.value;
        }
        return null;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: AllergyIntoleranceCodeSelectorItem) {
        this.props.onChange({ id: item.id });
    }

    @State.bound
    private onComplexSearchMultiSelect(items: IAllergyIntoleranceVersion[]) {
        this.props.onChange(items.map(item => ({ id: item.id, text: item.code })));
    }
    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticCareResources.PatientRegister.PatientAllergyIntoleranceList.Title,
            codeGetter: "code",
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect,
            complexSearchItemGroups: this.itemGroups,
            hideAllgroup: true
        };
        return (
            <HisUi.UniversalCodeSelector
                {...props}
                isCreatable
                addNewItem={this.addNewItem}
            />
        );
    }
}

export default connect(
    AllergyIntoleranceCodeSelector,
    new DependencyAdapter<IAllergyIntoleranceCodeSelectorProps, IAllergyIntoleranceCodeSelectorDependencies>((container) => {
        return {
            patientRegisterReferenceDataStore: container.resolve("PatientRegisterReferenceDataStore"),
            allergyIntoleranceApiAdapter: container.resolve("AllergyIntoleranceApiAdapter"),
            medicationsApiAdapter: container.resolve("MedicationsApiAdapter"),
            internationalNonproprietaryNamesApiAdapter: container.resolve("InternationalNonproprietaryNamesApiAdapter"),
            localizationService: container.resolve("IToolkitLocalizationService"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
        };
    })
);
