import React from "react";
import { LoadedSignalReactContext } from "./LoadedSignal";
import EditorScreenPanelStoreBase from "./EditorScreenPanelStoreBase";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";

export function editorScreenRenderFunc(store: EditorScreenPanelStoreBase<IScreenPropsBase>, child: React.ReactNode) {
    return (
        <LoadedSignalReactContext.Provider value={store.loadedSignalStore}>
            {child}
        </LoadedSignalReactContext.Provider>
    );
}