import User from "./User";
import StoreBase from "@Toolkit/CommonWeb/Model/StoreBase";
import UserId from "@Primitives/UserId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class UserListStore extends StoreBase {
    @State.observable.ref public users: User[] = null;

    @State.action.bound
    public setUsers(users: User[]) {
        this.users = users;
    }

    @State.bound
    public getUsersByIds(userIds: UserId[]) {
        return this.users && this.users.filter(u => userIds.some(id => id.value === u.id.value)) || [];
    }

    @State.bound
    public getUserById(userId: UserId) {
        return this.users && this.users.find(u => u.id.value === userId.value);
    }
}