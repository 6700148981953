import { emptyObject } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";
import IPermissionCheckContextModel from "@Toolkit/ReactClient/Components/PermissionCheckContext/IPermissionCheckContextModel";
import PermissionCheckContext from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContext";
import PermissionCheckReactContext from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckReactContext";

export default class PermissionCheckContextAdapter<TProps> implements IComponentAdapter<TProps> {
    public get usedContexts(): Array<React.Context<any>> {
        return [PermissionCheckReactContext];
    }

    public constructor(
        private readonly mapToProps: (props: TProps, unauthorizedOperations: IPermissionCheckContextModel[]) => Partial<TProps> =
            (props: any, unauthorizedOperations) => ({
                _permissionDenied: props.permissionCheckOperationNames &&
                    unauthorizedOperations.some(permission => !permission.isAuthorized && props.permissionCheckOperationNames.includes(permission.operationName))
            } as any)
    ) { }

    public getMappedProps(props: TProps, contextValues?: Map<React.Context<any>, any>): Partial<TProps> {
        const contextValue: PermissionCheckContext = contextValues.get(PermissionCheckReactContext);
        if (!contextValue) {
            return emptyObject;
        }
        return this.mapToProps(props, contextValue.checkedPermissions);
    }
}