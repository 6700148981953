import React from "react";
import { ITokenSettingsPanelPropsBase } from "@PluginInterface/BoundedContexts/DocumentManagement/TokenSettingsPanelRegistry/ITokenSettingsPanelRegistry";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";

interface IDoctorTokenFormatterSettingsPanelProps extends ITokenSettingsPanelPropsBase<Proxy.DoctorTokenValueFormatterSettingsDto> {
}

/** @screen */
export default class DoctorTokenFormatterSettingsPanel extends React.Component<IDoctorTokenFormatterSettingsPanelProps> {
    public render() {
        return (
            <UniversalEnumSelector
                displayMode="radio"
                direction="column"
                value={this.props.tokenFormatterSettings?.formatMode}
                enumName={"CodeAndDoctorFormatMode2"}
                enumOrigin="server"
                enumType={Proxy.CodeAndDoctorFormatMode2}
                onChange={this.setFormatValue}
                clearable={false}
                automationId="doctorTokenFormatSelector"
            />

        );
    }

    @State.bound
    private setFormatValue(value: Proxy.CodeAndDoctorFormatMode2) {
        this.props.onSettingsChange(new Proxy.DoctorTokenValueFormatterSettingsDto({
            formatMode: value
        }), null);
    }
}