import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";

class HisPermissionScopes {
    public pointOfCare(...values: string[]) {
        return new FrontendActionPermissionScope("PointOfCare", values);
    }
    public feature(...values: string[]) {
        return new FrontendActionPermissionScope("Feature", values);
    }
}

export default new HisPermissionScopes();