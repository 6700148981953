import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import ProfessionalExamStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/ProfessionalExamStore";
import ProfessionalExamId from "@Primitives/ProfessionalExamId.g";

export default class ProfessionalExamsStoreMapper extends EntityStoreMapper<Proxy.ProfessionalExamDto, ProfessionalExamStore> {
    protected storeEntityIdType = ProfessionalExamId;

    protected applyToStoreCore(target: ProfessionalExamStore, response: Proxy.ProfessionalExamDto): void {
        target.code = response.code;
        target.description = response.description;
    }
}
