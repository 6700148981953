import { ICodeEditorStore, CodeLanguage, ICodeEditorAdapter } from "./ICodeEditorStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class CodeEditorStore implements ICodeEditorStore {

    @State.observable.ref public language: CodeLanguage | null = null;
    @State.observable.ref public value: string | null = null;

    private editor: ICodeEditorAdapter | null = null;

    @State.action.bound
    public setValue(value: string, language: CodeLanguage): void {
        this.language = language;
        this.value = value;
    }

    public getValue(): string {
        if (!this.editor) {
            return this.value;
        }

        return this.editor.getValue();
    }

    public mountEditor(editor: ICodeEditorAdapter): void {
        this.editor = editor;
    }

    public unmountEditor(): void {
        this.editor = null;
    }

    @State.action.bound
    public onChange(newValue: string): void {
        this.value = newValue;
    }
}
