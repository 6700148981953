import LockingEntityStoreBase from "./LockingEntityStoreBase";
import ICloneable from "./ICloneable";
import _ from "@HisPlatform/Common/Lodash";
import IDirtyChecked from "./IDirtyChecked";
import StoreDirtyChecker from "./StoreDirtyChecker";
import ILockingEntityStore from "./ILockingEntityStore";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";

export default abstract class CloneableLockingEntityStoreBase<TStore extends ILockingEntityStore, TEntityId extends IStringEntityId>
    extends LockingEntityStoreBase<TEntityId>
    implements ICloneable<TStore>, IDirtyChecked {

    protected readonly _dirtyChecker = new StoreDirtyChecker();

    public get propertyNamesExcludedFromDirtyCheck(): string[] {
        return this.vPropertyNamesExcludedFromDirtyCheck;
    }

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }

    public clone(): TStore {
        const clone = _.cloneDeepWith(this, this.cloneCustomizer) as any;
        return clone;
    }

    @State.bound
    protected cloneCustomizer(value: any, key: number | string, object: object, stack: any): any {
        if (State.isObservableArray(value)) {
            return State.observable((value as IObservableArray<any>).slice());
        }
        return undefined;
    }

    protected get vPropertyNamesExcludedFromDirtyCheck(): string[] {
        return [
            "_dirtyChecker",
            "operationInfo",
            "hasValidationError",
            "hasValidationWarning",
            "propertyNamesExcludedFromDirtyCheck",
        ];
    }
}
