import WorkflowStepStore from "@CommonControls/WorkflowStepsIndicator/WorkflowStepStore";
import Di from "@Di";
import GuidanceApiAdapter from "@HisPlatform/Packages/Guidance/ApiAdapter/GuidanceApiAdapter";
import { ICareActivityGuidanceHeaderProps } from "@HisPlatform/Packages/Guidance/Components/CareActivityGuidanceHeader/CareActivityGuidanceHeader";
import { IGuidanceStep } from "@HisPlatform/Packages/Guidance/Model/IGuidance";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";

@Di.injectable()
export default class CareActivityGuidanceHeaderStore extends PanelStoreBase<ICareActivityGuidanceHeaderProps> implements ILoadablePanelStore<ICareActivityGuidanceHeaderProps> {
    @State.observable.ref private _steps: { store: WorkflowStepStore, step: IGuidanceStep }[] = [];

    @State.computed public get steps() {
        return this._steps.map(s => s.store);
    }

    @State.computed public get currentStep() {
        if (!this.props.action) {
            return this.tryGetLegacyStep();
        }
        
        const step = this._steps.find(s => s.step.actionDescriptor.action.id.value === this.props.action.id.value && _.isEqual(s.step.actionDescriptor.action, this.props.action))?.store;
        return step ?? this.tryGetLegacyStep();
    }

    constructor(
        @Di.inject("GuidanceApiAdapter") private readonly guidanceApiAdapter: GuidanceApiAdapter,
        @Di.inject("ICurrentCultureProvider") private readonly cultureCodeProvider: ICurrentCultureProvider,
        @Di.inject("ApplicationContext") private readonly applicationContext: ApplicationContext
    ) {
        super();
    }

    public readonly loadAsync = this.async(async () => {
        if (!this.props._careActivityId) {
            return;
        }

        const response = await this.guidanceApiAdapter.getGuidanceForCareActivityAsync(this.props._careActivityId!);

        State.runInAction(() => {
            this._steps = response.result?.steps.map(s => ({
                store: new WorkflowStepStore(
                    s.stepId,
                    s.localizedDisplayName.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode),
                    false,
                    null,
                    null
                ),
                step: s
            })) ?? [];
        });
    });

    public getReloadTriggerProps(props: ICareActivityGuidanceHeaderProps) { return [props._careActivityId?.value]; }

    public readonly selectStepAsync = this.async(async (store: WorkflowStepStore) => {
        const step = this._steps.find(s => s.store === store).step;
        await this.actionDispatcher.dispatchAsync(step.actionDescriptor.action);
    });

    private tryGetLegacyStep() {
        return this._steps.find(s => s.step.legacy_userCaseIdentifiers?.includes(this.applicationContext.currentUseCaseIdentifier?.value) === true)?.store;
    }
}