import DependencyContainer from "@DiContainer";
import * as SchedulingProxy from "@HisPlatform/BoundedContexts/Scheduling/Api/Proxy.g";
import * as OrganizationProxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";

DependencyContainer
    .bind("ISchedulingClient")
    .to(SchedulingProxy.SchedulingClient);

DependencyContainer
    .bind("IConfigurationClient")
    .to(SchedulingProxy.ConfigurationClient);

DependencyContainer
    .bind("IResourceManagementClient")
    .to(OrganizationProxy.ResourceManagementClient);
