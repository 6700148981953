import DependencyContainer from "@DiContainer";
import * as AuthProxy from "@HisPlatform/BoundedContexts/Authorization/Api/Proxy.g";

DependencyContainer
    .bind("IOperationAccessControlClient")
    .to(AuthProxy.OperationAccessControlClient)
    .inSingletonScope();

DependencyContainer
    .bind("IDataAccessControlClient")
    .to(AuthProxy.DataAccessControlClient);
