import { IRowIndicatorStyle } from "@CommonControls/DataGrid/IDataGridProps";

export function getMedicationRowStyle(classification: number, boldClassName: string) {
    const rowIndicatorStyle = {
        className: null,
        color: null,
        rowBackgroundColor: null,
        rowBackgroundHoverColor: null,
        rowBackgroundSelectedColor: null
    } as IRowIndicatorStyle;

    switch (classification) {
        case 1:
            rowIndicatorStyle.className = boldClassName;
            rowIndicatorStyle.color = "#21C313";
            rowIndicatorStyle.rowBackgroundColor = "#E9F9E7";
            rowIndicatorStyle.rowBackgroundColorHover = "#D3F3D0";
            rowIndicatorStyle.rowBackgroundColorSelected = "#BEEDD3";
            rowIndicatorStyle.rowBackgroundColorSelectedHover = "#BEEDD3";
            break;
        case 2:
            rowIndicatorStyle.color = "#21C313";
            rowIndicatorStyle.rowBackgroundColor = "#E9F9E7";
            rowIndicatorStyle.rowBackgroundColorHover = "#D3F3D0";
            rowIndicatorStyle.rowBackgroundColorSelected = "#BEEDD3";
            rowIndicatorStyle.rowBackgroundColorSelectedHover = "#BEEDD3";
            break;
        case 3:
            rowIndicatorStyle.color = "#DDC00D";
            rowIndicatorStyle.rowBackgroundColor = "#FCF9E7";
            rowIndicatorStyle.rowBackgroundColorHover = "#F8F2CF";
            rowIndicatorStyle.rowBackgroundColorSelected = "#EEDF86";
            rowIndicatorStyle.rowBackgroundColorSelectedHover = "#EEDF86";
            break;
        case 4:
            rowIndicatorStyle.color = "#CE5374";
            rowIndicatorStyle.rowBackgroundColor = "rgba(206, 83, 116, 0.1)";
            rowIndicatorStyle.rowBackgroundColorHover = "#F5DDE3";
            rowIndicatorStyle.rowBackgroundColorSelected = "#F8C6E3";
            rowIndicatorStyle.rowBackgroundColorSelectedHover = "#F8C6E3";
            break;
        case 5:
            rowIndicatorStyle.rowBackgroundColor = "white";
            break;
        case 6:
            rowIndicatorStyle.color = "#8695A7";
            rowIndicatorStyle.rowBackgroundColor = "#F3F5F8";
            rowIndicatorStyle.rowBackgroundColorHover = "#D7D9DB";
            rowIndicatorStyle.rowBackgroundColorSelected = "#D7D9DB";
            rowIndicatorStyle.rowBackgroundColorSelectedHover = "#D7D9DB";
            break;
        case 7:
            rowIndicatorStyle.rowBackgroundColor = "white";
            break;
    }

    return rowIndicatorStyle;
}
