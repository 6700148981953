import * as React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import * as HisUi from "@HisPlatformControls";
import {IOrderingState, IPagingState} from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import {IUniversalCodeSelectorProps} from "@HisPlatformControls/UniversalCodeSelector";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import EhiHealthcareProviderOrganizationUnit from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiHealthcareProviderOrganizationUnit";
import EhiHealthcareProviderOrganizationUnitId from "@Primitives/EhiHealthcareProviderOrganizationUnitId.g";
import EhiHealthcareProviderId from "@Primitives/EhiHealthcareProviderId.g";

interface IEhiHealthcareProviderOrganizationUnitSelectorDependencies {
    referenceDataStore: HunEhiCareReferenceDataStore;
    apiAdapter: EhiCareApiAdapter;
}

interface IEhiHealthcareProviderOrganizationUnitSelectorProps extends ICodeSelectorCommonProps<EhiHealthcareProviderOrganizationUnitId> {
    _dependencies?: IEhiHealthcareProviderOrganizationUnitSelectorDependencies;
    ehiHealthcareProviderId: EhiHealthcareProviderId;
}

@State.observer
class EhiHealthcareProviderOrganizationUnitSelector extends React.Component<IEhiHealthcareProviderOrganizationUnitSelectorProps> {
    public static defaultProps: Partial<IEhiHealthcareProviderOrganizationUnitSelectorProps> = {
        maxResultCount: 10
    };

    @State.bound
    private async getDisplayValueAsync(value: EhiHealthcareProviderOrganizationUnitId) {
        const item = await this.dependencies.referenceDataStore.ehiHealthcareProviderOrganizationUnits.getOrLoadAsync(value);
        return <><b>{item.code}</b>{` ${item.name}`}</>;
    }

    @State.bound
    private async getTextValueAsync(value: EhiHealthcareProviderOrganizationUnitId) {
        const item = await this.dependencies.referenceDataStore.ehiHealthcareProviderOrganizationUnits.getOrLoadAsync(value);
        return `${item.code} ${item.name}`;
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const result = await this.dependencies.apiAdapter.searchEhiHealthcareProviderOrganizationUnitIdsAsync(
            text,
            this.props.maxResultCount,
            this.props.ehiHealthcareProviderId
        );

        if (result.operationWasSuccessful) {
            await this.dependencies.referenceDataStore.ehiHealthcareProviderOrganizationUnits.ensureLoadedAsync(result.value);
            return result.value;
        }

        return null;
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: EhiHealthcareProviderOrganizationUnit[]): Promise<IPagedItems<EhiHealthcareProviderOrganizationUnit>> {
        const customOrdering = {
            columnId: "code",
            direction: "asc"
        } as IOrderingState;
        const results = await this.dependencies.apiAdapter.searchEhiHealthcareProviderOrganizationUnitsAsync(filterText, customOrdering, paging, this.props.ehiHealthcareProviderId);

        return results.value;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: EhiHealthcareProviderOrganizationUnit) {
        this.props.onChange(item.id);
    }

    @State.bound
    private onComplexSearchMultiSelect(items: EhiHealthcareProviderOrganizationUnit[]) {
        this.props.onChange(items.map(item => item.id));
    }

    public render() {

        const props = {
            ...this.props,
            quickSearchMinimumCharacters: 2,
            getDisplayValueAsync: this.getDisplayValueAsync,
            getTextValueAsync: this.getTextValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            codeGetter: "code",
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect,
        } as IUniversalCodeSelectorProps<EhiHealthcareProviderOrganizationUnitId, EhiHealthcareProviderOrganizationUnit>;

        return (
            <HisUi.UniversalCodeSelector
                {...props}
                automationId={this.props.automationId}
            />
        );
    }

    private get dependencies() {
        return this.props._dependencies;
    }
}

export default connect(
    EhiHealthcareProviderOrganizationUnitSelector,
    new DependencyAdapter<IEhiHealthcareProviderOrganizationUnitSelectorProps, IEhiHealthcareProviderOrganizationUnitSelectorDependencies>((container) => {
        return {
            apiAdapter: container.resolve("EhiCareApiAdapter"),
            referenceDataStore: container.resolve("HunEhiCareReferenceDataStore"),
        };
    })
);
