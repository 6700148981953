import React, { useCallback } from "react";
import * as Ui from "@CommonControls";
import CareActivityEReferralStore from "@HunEHealthInfrastructurePlugin/Model/CareActivityEReferralStore";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";

interface IEhiEReferralListButtonsProps {
    row?: CareActivityEReferralStore;
    onShowDetail: (row: CareActivityEReferralStore) => void;
}


const EhiEReferralListButtons: React.FC<IEhiEReferralListButtonsProps> = props => {
    const resources = StaticHunEHealthInfrastructureCareResources.EReferralListPanel.List;

    const onClickShowDetail = useCallback(() => {
        props.onShowDetail(props.row);
    }, [props.onShowDetail, props.row]);

    return (
        <>
            <Ui.Button
                onClick={onClickShowDetail}
                size="compact"
                iconName={"ophthalmology"}
                tooltipContent={resources.DetailButtonTooltip}
                automationId="showDetailButton"
            />
        </>
    );
};

export default EhiEReferralListButtons;
