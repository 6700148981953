import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import ShowCreateNewTelemetrySessionScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowCreateNewTelemetrySessionScreenAction.g";
import TelemetrySessionScreenApiAdapter from "@AssecoMedPlugin/Packages/Care/Screens/TelemetrySessionScreen/TelemetrySessionScreenApiAdapter";
import IncludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierFilter";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";

@Di.injectable()
class TelemetrySessionScreenFormBaseFormLogic implements IFormLogic {
    constructor(@Di.inject("TelemetrySessionScreenApiAdapter") private readonly apiAdapter: TelemetrySessionScreenApiAdapter) {
    }

    public async onFormLoadedAsync(
        form: IForm,
        showScreenAction: ShowScreenFrontendActionBase,
        dataElements: FormDataElementBase[]
    ): Promise<void> {
        const formFields = form.data.Content;

        await this.filterPointsOfCareAsync(formFields);
        this.setReadOnlyFields(formFields, showScreenAction);
    }

    public async executeAsync(
        form: IForm,
        showScreenAction: ShowScreenFrontendActionBase,
        dataElements: FormDataElementBase[]
    ): Promise<void> {
        const formFields = form.data.Content;

        await this.filterPointsOfCareAsync(formFields);
        this.setReadOnlyFields(formFields, showScreenAction);
    }

    async filterPointsOfCareAsync(formFields: FormFieldDataBase[]): Promise<void> {
        const field = getField<ReferencedEntityFormFieldData>(formFields, "PointOfCareId");
        if (isNullOrUndefined(field)) {
            return;
        }

        const pointOfCareIds = await this.apiAdapter.getTelemetrySessionPointOfCareIdsByUserAsync();
        const filters = State.createObservableShallowArray(pointOfCareIds.result.map(pointOfCareId => new IncludeIdentifierFilter(new Identifier(new IdentifierSystemId("UNUSED"), pointOfCareId.value))));
        State.runInAction(() => {
            field.filters = filters;
            field.value = parseInt(pointOfCareIds.result[0].value);
        });
    }

    setReadOnlyFields(formFields: FormFieldDataBase[], showScreenAction: ShowScreenFrontendActionBase): void {
        this.setFieldAsReadOnly(formFields, "WentUnderCareAt");
        this.setFieldAsReadOnly(formFields, "DischargedAt");

        if (!isNullOrUndefined(showScreenAction) && !(showScreenAction instanceof ShowCreateNewTelemetrySessionScreenAction)) {
            this.setFieldAsReadOnly(formFields, "PointOfCareId");
        }
    }

    setFieldAsReadOnly(formFields: FormFieldDataBase[], name: string): void {
        const field = getField(formFields, name);
        if (isNullOrUndefined(field)) {
            return;
        }

        State.runInAction(() => field.isReadOnly = true);
    }
}

export default TelemetrySessionScreenFormBaseFormLogic;