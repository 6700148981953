import React, { useRef, useEffect, useCallback, Fragment } from "react";
import Styles from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditor.less";
import EditableLayoutColumnStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableLayoutColumnStore";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import { useDrag } from "react-dnd";
import IDragData from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/IDragData";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import FormLayoutDataElementEditor from "./FormLayoutDataElementEditor";
import EditableLayoutAnyBlock from "./EditableLayoutAnyBlock";
import BlockDropZone from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/BlockDropZone";

function EditableLayoutRowColumn(props: { column: EditableLayoutColumnStore, parentCollection: IObservableArray<EditableLayoutColumnStore> }) {

    const store = useFormLayoutEditorStore();
    const ref = useRef(null);

    const [{ opacity, isDragging }, drag] = useDrag({
        type: "column",
        item: {
            isNew: false,
            type: "column",
            dragItemType: "column",
            store: props.column,
            sourceCollection: props.parentCollection
        } as IDragData,
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
            isDragging: monitor.isDragging()
        })
    }, [props.column, props.parentCollection]);

    useEffect(() => {
        if (isDragging) {
            store.startDraggingElement();
        } else {
            store.stopDraggingElement();
        }
    }, [isDragging]);

    const select = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        store.selectColumn(props.parentCollection, props.column);
        event.stopPropagation();
    }, [props.column, props.parentCollection]);

    drag(ref);

    return (
        <div
            onClick={select}
            ref={ref}
            style={{ opacity }}
            className={combineClasses(
                Styles.elementRowItem,
                store.selectedColumn === props.column && Styles.selected,
                props.column.size.xs === 1 && Styles.colXs1,
                props.column.size.xs === 2 && Styles.colXs2,
                props.column.size.xs === 3 && Styles.colXs3,
                props.column.size.xs === 4 && Styles.colXs4,
                props.column.size.xs === 5 && Styles.colXs5,
                props.column.size.xs === 6 && Styles.colXs6,
                props.column.size.xs === 7 && Styles.colXs7,
                props.column.size.xs === 8 && Styles.colXs8,
                props.column.size.xs === 9 && Styles.colXs9,
                props.column.size.xs === 10 && Styles.colXs10,
                props.column.size.xs === 11 && Styles.colXs11,
                props.column.size.xs === 12 && Styles.colXs12,
                props.column.editor && Styles.editorContent
            )}
        >

            {props.column.editor && <FormLayoutDataElementEditor columnParentCollection={props.parentCollection} column={props.column} editor={props.column.editor} />}
            {props.column.contentBlocks && props.column.contentBlocks.map((b, idx) => (
                <Fragment key={b.id}>
                    <EditableLayoutAnyBlock block={b} parentCollection={props.column.contentBlocks} />
                    <BlockDropZone collection={props.column.contentBlocks} indexToInsert={idx + 1} />
                </Fragment>
            ))}
        </div>
    );
}

export default State.observer(EditableLayoutRowColumn);