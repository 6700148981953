import React from "react";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import Styles from "./PatientMainPage.less";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import RoutingFrame from "@Toolkit/ReactClient/Routing/RoutingFrame";
import PatientRoutes from "@HisPlatform/Components/Pages/Patient/PatientRoutes";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import PatientEHRRoutes from "@HisPlatform/Components/Pages/Patient/PatientEHRRoutes";
import Route from "@Toolkit/ReactClient/Routing/Abstractions/Route";
import IPatientRouteParams from "@HisPlatform/Application/Routes/IPatientRouteParams";
import PatientId from "@Primitives/PatientId.g";
import PatientContextProvider from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextProvider";
import PatientMainMenu from "@HisPlatform/Components/Pages/Patient/PatientMainPage/View/PatientMainMenu";
import PatientAppointmentsPage from "@HisPlatform/Components/Pages/AppointmentPages/PatientAppointmentsPage";
import PatientAppointmentRoutes from "@HisPlatform/Components/Pages/Patient/PatientAppointmentRoutes";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PatientsCareActivitiesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/PatientCareActivities/PatientsCareActivitiesApiAdapter";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import ProcedureStatementPage from "@HisPlatform/Components/Pages/Patient/ProcedureStatement/ProcedureStatementPage";
import AutonomyDisabilityStatementPage from "@HisPlatform/Components/Pages/Patient/AutonomyDisabilityStatement/AutonomyDisabilityStatementPage";
import ConditionStatementPage from "@HisPlatform/Components/Pages/Patient/ConditionStatement/ConditionStatementPage";
import DeviceUseStatementPage from "@HisPlatform/Components/Pages/Patient/DeviceUseStatement/DeviceUseStatementPage";
import PregnancyStatementPage from "@HisPlatform/Components/Pages/Patient/PregnancyStatement/PregnancyStatementPage";
import MedicationStatementPage from "@HisPlatform/Components/Pages/Patient/MedicationStatement/MedicationStatementPage";
import RiskAssessmentPage from "@HisPlatform/Components/Pages/Patient/RiskAssessment/RiskAssessmentPage";
import PatientAllergyIntolerancePage from "@HisPlatform/Components/Pages/Patient/PatientAllergyIntolerance/PatientAllergyIntolerancePage";
import PatientApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/Patient/PatientApiAdapter";
import SurgeryForPatientPage from "@HisPlatform/Components/Pages/SurgeryPages/SurgeryForPatientPage";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import { ActionDispatcherAdapter } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import MedicalAlertStatementPage from "@HisPlatform/Components/Pages/Patient/MedicalAlertStatement/MedicalAlertStatementPage";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import IRoutingFrameCaseRegistry from "@HisPlatform/Services/Definition/RoutingFrameCaseRegistry/IRoutingFrameCaseRegistry";
import HunPatientRoutes from "@HunEHealthInfrastructurePlugin/UseCases/PatientMainPageUseCase/HunPatientRoutes";
import config from "@Config";



interface IPatientMainPageDependencies {
    useCaseRegistry: IUseCaseRegistry;
    patientsCareActivitiesApiAdapter: PatientsCareActivitiesApiAdapter;
    worklistApiAdapter: WorklistApiAdapter;
    patientApiAdapter: PatientApiAdapter;
    userContext: UserContext;
    routingFrameCaseRegistry: IRoutingFrameCaseRegistry;
}

interface IPatientMainPageProps extends IRoutingFrameContentProps {
    _dependencies: IPatientMainPageDependencies;
}

@State.observer
class PatientMainPage extends React.Component<IPatientMainPageProps> {

    @State.observable.ref private scrolledPanelId = ""; // For controlling scrolling
    @State.observable.ref private currentlyScrolledElementId = ""; // For receiving scrolled element

    private get useCaseRegistry() { return this.props._dependencies.useCaseRegistry; }
    private get userContext() { return this.props._dependencies.userContext; }
    private get routingFrameCaseRegistry() { return this.props._dependencies.routingFrameCaseRegistry; }

    private get operationsToCheck() {
        const res = {};

        res["ViewPatientCareActivities"] = async () => {
            await this.props._dependencies.patientsCareActivitiesApiAdapter.searchPatientCareActivitiesPermissionCheckAsync();
        };

        res["ViewPatientAppointments"] = async () => {
            const definition = await this.props._dependencies.worklistApiAdapter.getAppointmentScheduleEntryBoundWorklistDefinition();
            await this.props._dependencies.worklistApiAdapter.getWorklistDataByDefinitionIdPermissionCheck(definition.value);
        };

        res["ViewPatientMedicalConditions"] = async () => {
            const definition = await this.props._dependencies.worklistApiAdapter.getImmunizationBoundWorklistDefinition(new PatientId("1"), this.userContext.id);
            await this.props._dependencies.worklistApiAdapter.getWorklistDataByDefinitionIdPermissionCheck(definition.value);
        };

        res["ViewPatientAllergyIntolerances"] = async () => {
            const definition = await this.props._dependencies.worklistApiAdapter.getPatientAllergyIntoleranceBoundWorklistDefinition(new PatientId("1"), this.userContext.id);
            await this.props._dependencies.worklistApiAdapter.getWorklistDataByDefinitionIdPermissionCheck(definition.value);
        };

        return res;
    }

    
    private readonly routeDefinitions = {
        ...PatientRoutes,
        ...this.routingFrameCaseRegistry.getRouteDefinitions("PatientMainPage")
    };

    @State.computed.valueWrapper public get patientId() {
        if (this.isNew) {
            return null;
        }

        const route = this.props.routingController.currentRoute as Route<IPatientRouteParams>;
        return new PatientId(route.parameters.patientId);
    }

    @State.computed public get isNew() {
        const route = this.props.routingController.currentRoute as Route<IPatientRouteParams>;
        return route.parameters.patientId === "new";
    }

    public render() {
        return (
            <PermissionCheckContextProvider operationsToCheck={this.operationsToCheck}>
                <PatientContextProvider patientId={this.patientId}>
                    <div className={combineClasses(Styles.root, this.isNew && Styles.rootNew)}>
                        <Ui.SidebarLayout
                            leftSidebar={(
                                <HisUi.HisErrorBoundary>
                                    <PatientMainMenu
                                        currentlyScrolledElementId={this.currentlyScrolledElementId}
                                        isNew={this.isNew}
                                    />
                                </HisUi.HisErrorBoundary>
                            )}>
                            <HisUi.HisErrorBoundary>
                                <RoutingFrame
                                    routeDefinitions={this.routeDefinitions}
                                    fallbackRoute={PatientRoutes.appointmentList}
                                    parentRouteDefinition={ApplicationRoutes.patient}
                                >

                                    <RoutingFrame.Case route={PatientRoutes.procedureStatement} component={ProcedureStatementPage} />
                                    <RoutingFrame.Case route={PatientRoutes.autonomyDisabilityStatement} component={AutonomyDisabilityStatementPage} />
                                    <RoutingFrame.Case route={PatientRoutes.conditionStatement} component={ConditionStatementPage} />
                                    <RoutingFrame.Case route={PatientRoutes.deviceUseStatement} component={DeviceUseStatementPage} />
                                    <RoutingFrame.Case route={PatientRoutes.pregnancyStatement} component={PregnancyStatementPage} />
                                    <RoutingFrame.Case route={PatientRoutes.medicationStatement} component={MedicationStatementPage} />
                                    <RoutingFrame.Case route={PatientRoutes.riskAssessment} component={RiskAssessmentPage} />
                                    <RoutingFrame.Case route={PatientRoutes.patientAllergyIntolerance} component={PatientAllergyIntolerancePage} />
                                    <RoutingFrame.Case route={PatientRoutes.medicalAlertStatement} component={MedicalAlertStatementPage} />
                                    <RoutingFrame.Case route={PatientRoutes.appointmentList}>
                                        {() =>
                                            <Ui.ScrollView>
                                                <RoutingFrame
                                                    routeDefinitions={PatientAppointmentRoutes}
                                                    fallbackRoute={PatientAppointmentRoutes.appointmentList}
                                                    parentRouteDefinition={PatientRoutes.appointmentList}
                                                >
                                                    <RoutingFrame.Case route={PatientAppointmentRoutes.appointmentList} component={PatientAppointmentsPage} />
                                                </RoutingFrame>
                                            </Ui.ScrollView>
                                        }
                                    </RoutingFrame.Case>
                                    <RoutingFrame.Case route={PatientRoutes.patientEhr}>
                                        {() =>
                                            <RoutingFrame
                                                routeDefinitions={PatientEHRRoutes}
                                                fallbackRoute={PatientEHRRoutes.careActivities}
                                                parentRouteDefinition={PatientRoutes.patientEhr}
                                            >
                                                {this.useCaseRegistry.renderStandaloneRoutingCasesByParentRoute(PatientRoutes.patientEhr)}
                                            </RoutingFrame>
                                        }
                                    </RoutingFrame.Case>
                                    <RoutingFrame.Case route={PatientRoutes.surgeryForPatient} component={SurgeryForPatientPage} />
                                    {this.routingFrameCaseRegistry.getAll("PatientMainPage")}
                                </RoutingFrame>                  
                            </HisUi.HisErrorBoundary>
                        </Ui.SidebarLayout>
                    </div>
                </PatientContextProvider>
            </PermissionCheckContextProvider >
        );
    }
}

export default connect(
    withLoadingBoundary(PatientMainPage),
    new DependencyAdapter<IPatientMainPageProps, IPatientMainPageDependencies>(c => ({
        useCaseRegistry: c.resolve("IUseCaseRegistry"),
        patientsCareActivitiesApiAdapter: c.resolve("PatientsCareActivitiesApiAdapter"),
        worklistApiAdapter: c.resolve("WorklistApiAdapter"),
        patientApiAdapter: c.resolve("PatientApiAdapter"),
        userContext: c.resolve("UserContext"),
        routingFrameCaseRegistry: c.resolve("IRoutingFrameCaseRegistry")
    })),
    new ActionDispatcherAdapter()
);
