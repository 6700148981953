import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import ErrorEntryRecordType from "@Primitives/ErrorEntryRecordType";

interface IErrorEntryRecordTypeMultiSelectProps extends ISelectBoxBaseProps {
    value?: ErrorEntryRecordType | ErrorEntryRecordType[];
    onChange?: (newValue: ErrorEntryRecordType | ErrorEntryRecordType[]) => void;
}


const ErrorEntryRecordTypeSelectBox: React.FC<IErrorEntryRecordTypeMultiSelectProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"ErrorEntryRecordType"}
        enumOrigin="server"
        enumType={ErrorEntryRecordType}
        displayMode="selectBox"
    />
);

export default ErrorEntryRecordTypeSelectBox;