import React from "react";
import RadioButton from "./RadioButton";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Styles from "./RadioButton.less";
import { getHashValue } from "@Toolkit/CommonWeb/EqualityHelper";
import { iconNameType } from "@CommonControls/Icon";
import { FieldValidationResult } from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ReadOnlyContextAdapter from "@Toolkit/ReactClient/Components/ReadOnlyContext/ReadOnlyContextAdapter";
import ValidationBoundaryAdapter from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryAdapter";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import { arrayIsNullOrEmpty, emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EventHandler from "@Toolkit/ReactClient/Components/EventHandler/EventHandler";
import IToolkitViewContextSettings from "@Toolkit/ReactClient/Components/ViewContext/IToolkitViewContextSettings";
import ViewContextAdapter from "@Toolkit/ReactClient/Components/ViewContext/ViewContextAdapter";

export interface IRadioGroupBaseProps<TItem> {
    direction?: "row" | "column";
    value?: TItem;
    onChange?: (selectedItem: TItem) => void;
    getDisplayValue?: (item: TItem) => string;
    getIconName?: (value: TItem) => iconNameType;
    getIconStyle?: (value: TItem) => React.CSSProperties;
    displayType?: "buttons" | "groupedButtons";
    disabled?: boolean;
    propertyIdentifier?: string;
    label?: string;
    style?: any;
    isRequired?: boolean;
    readOnly?: boolean;
    automationId?: string;
    inline?: boolean;
    addNullItem?: boolean;
    showMultipleValidationProblems?: boolean;
    size?: "standard" | "compact";
}

export interface IRadioGroupProps<TItem> extends IRadioGroupBaseProps<TItem> {
    items: TItem[];
}

interface IRadioGroupCoreProps<TItem> extends IRadioGroupProps<TItem> {
    validationContext: IValidationBoundaryStore;
}

let defaultAutomationId = 0;

@State.observer
class RadioGroupCore<TItem> extends React.Component<IRadioGroupCoreProps<TItem>> {

    @State.observable.ref private itemMap: Map<string, TItem>;

    public static defaultProps: Partial<IRadioGroupProps<any>> = {
        getDisplayValue: val => val,
        getIconName: val => undefined,
        direction: "row"
    };

    public componentDidMount() {
        this.mapItems();
    }

    public componentDidUpdate(prevProps: IRadioGroupProps<TItem>) {
        if (prevProps.items !== this.props.items) {
            this.mapItems();
        }

        if (this.props.value !== prevProps.value) {
            this.validate();
        }
    }

    @State.action
    private mapItems() {
        const itemMapSet = new Map<string, TItem>();
        if (this.props.items) {
            this.props.items.forEach(i => {
                itemMapSet.set(getHashValue(i), i);
            });
        }
        this.itemMap = itemMapSet;
    }

    private getClassName(): string {
        if (this.props.direction === "column") {
            return Styles.groupColumn;
        } else {
            return Styles.groupRow;
        }
    }

    @State.computed
    private get validationProblems() {
        if (this.props.validationContext) {
            const problems = this.props.validationContext.getValidationProblems(this.props.propertyIdentifier);
            return arrayIsNullOrEmpty(problems) ? emptyArray : problems;
        }
        return emptyArray;
    }

    @State.computed
    private get validationResult() {
        return this.validationProblems.length === 0 ? null : this.validationProblems[0];
    }

    @State.computed private get isRequired() {
        return this.props.isRequired || (
            this.props.validationContext && this.props.validationContext.isRequired(this.props.propertyIdentifier)
        );
    }

    @State.action.bound
    private internalCheckHandler(value: string) {
        const item = this.getItem(value);
        this.props.onChange(item);
    }

    @State.bound
    private getItem(value: string) {
        if (value === "null") {
            return null;
        }
        return this.itemMap.get(value);
    }

    private renderValidationResults(): React.ReactNode {
        if (!this.props.disabled) {
            return <FieldValidationResult problems={this.validationProblems} showMultipleProblems={this.props.showMultipleValidationProblems} />;
        }
        return null;
    }

    @State.bound
    private validate() {
        if (this.props.validationContext) {
            this.props.validationContext.changed(this.props.propertyIdentifier, this.props.value);
        }
    }

    public render() {
        const items = this.itemMap ? Array.from(this.itemMap.values()) : [];
        if (this.props.addNullItem) {
            items.push(null);
        }

        const automationId = this.props.automationId ? this.props.automationId : `radio_${defaultAutomationId++}`;

        return (
            <div style={this.props.inline ? { display: "inline-block" } : undefined} data-automation-id={automationId}>
                {this.props.label && <label className={Styles.label}>{this.props.label}{this.isRequired && <span className={Styles.requiredStar} />}</label>}
                <div className={this.getClassName()}>
                    {items.map(i => getHashValue(i)).map(val => {
                        return (
                            <RadioButton
                                style={this.props.getIconStyle ? this.props.getIconStyle(this.getItem(val)) : this.props.style}
                                key={val}
                                text={this.props.getDisplayValue(this.getItem(val))}
                                value={val}
                                isChecked={getHashValue(this.props.value) === val}
                                onChanged={this.internalCheckHandler}
                                displayType={this.props.displayType}
                                iconName={this.props.getIconName(this.getItem(val))}
                                disabled={this.props.disabled || this.props.readOnly}
                                validationResultSeverity={this.validationResult ? this.validationResult.severity : undefined}
                                automationId={automationId}
                                size={this.props.size}
                            />
                        );
                    })}
                </div>
                {this.renderValidationResults()}
                <EventHandler event={this.props.validationContext?.validateAllEvent} onFired={this.validate} />
            </div>
        );
    }
}


const RadioButtonGroup = connect(
    RadioGroupCore,
    new ReadOnlyContextAdapter<IRadioGroupCoreProps<any>>((props, isReadOnly) => ({ readOnly: props.readOnly || isReadOnly })),
    new ValidationBoundaryAdapter<IRadioGroupCoreProps<any>>((props, validationStore) => ({ validationContext: validationStore })),
    new ViewContextAdapter<IRadioGroupCoreProps<any>, IToolkitViewContextSettings>((props, ctx) => ({
        size: ctx.compactControlHeight ? "compact" : props.size,
    }))
);

export default RadioButtonGroup;

export function RadioButtonGroupFactory<T>() {
    return RadioButtonGroup as React.ComponentType<IRadioGroupProps<T>>;
}

const StringRadioButtonGroup = RadioButtonGroupFactory<string>();
const NumberRadioButtonGroup = RadioButtonGroupFactory<number>();

export {
    StringRadioButtonGroup,
    NumberRadioButtonGroup
};