import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import Di from "@Di";
import IPractitionerRecommendationListNameExtensionPointProps from "@PluginInterface/BoundedContexts/MedicationRequest/ExtensionPoints/IPractitionerRecommendationListNameExtensionPointProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

@Di.injectable()
export default class EhiPractitionerRecommendationListNameExtensionComponentService implements IExtensionComponentService<IPractitionerRecommendationListNameExtensionPointProps>  {

    public isVisible(props: IPractitionerRecommendationListNameExtensionPointProps) {
        return !isNullOrUndefined(props.extensionData?.PractitionerRecommendationSyncStatus);
    }

    public shouldRerunIsVisible(props: IPractitionerRecommendationListNameExtensionPointProps, prevProps: IPractitionerRecommendationListNameExtensionPointProps) {
        return props.extensionData !== prevProps?.extensionData;
    }
}