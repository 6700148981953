import { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import EditableLayoutColumnStore from "./Model/EditableLayoutColumnStore";
import { width } from "@CommonControls/Flex/FlexItem";
import _ from "@HisPlatform/Common/Lodash";

export function resizeColumnsForAddingNew(newColumn: EditableLayoutColumnStore, collection: IObservableArray<EditableLayoutColumnStore>) {

    if (collection.length > 0) {

        if (collection.length > 12) {
            // TODO: notify user
            return;
        }

        const columnWidthsSum = _.sum(collection.map(c => c.size.xs));

        if (columnWidthsSum >= 12) {

            // need to shrink cols
            const areAllTheSameSize = collection.every(c => c.size.xs === collection[0].size.xs);
            if (areAllTheSameSize && collection.length < 12) {
                const newSize = Math.floor(12 / (collection.length + 1));
                const newSizeParam = { xs: newSize as width };

                collection.forEach(c => c.setSize(newSizeParam));
                newColumn.setSize(newSizeParam);
            }

        } else {
            newColumn.setSize({ xs: (12 - columnWidthsSum) as width });
        }
    }

}