import React, { useCallback } from "react";
import * as Ui from "@CommonControls";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import { emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IFormFieldDependencyDialogParams, IFormFieldDependencyDialogResult } from "./FormFieldDependencyDialogParams";
import { provideFormFieldDependencyDialogStore, useFormFieldDependencyDialogStore } from "./FormFieldDependencyDialogStoreProvider";
import FormFieldDependencyStore from "@Toolkit/FormEngine/Panels/FormPanel/FormFieldDependencyStore";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import FormFieldData from "./FormFieldData";
import StaticFormEditingResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";

function FormFieldDependencyDialog(props: IModalComponentParams<IFormFieldDependencyDialogResult> & IFormFieldDependencyDialogParams) {
    const store = useFormFieldDependencyDialogStore();

    const renderDependencyEditor = useCallback((item: FormFieldDependencyStore, index: number) => <FormFieldDependencyEditor item={item} index={index} definitionId={props.definitionId} />, [store]);

    const dataElementEditorTypeInfoList = store.formFieldList.map(formField => ({
        text: formField.label,
        value: formField
    } as ISelectBoxItem<FormFieldData>));

    return (
        <Ui.Modal
            isOpen
            onClose={props.onClose as any}
            title={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FormFieldDependencyModal.Title}
            size="compact"
        >
            <Ui.Modal.Body>
                <ValidationBoundary validationResults={emptyArray}>
                    <Ui.SelectBox
                        automationId="FormFieldDependencyDialog_TargetDependencySelector"
                        onChange={store.setTargetFieldData}
                        value={store.targetFieldData}
                        items={dataElementEditorTypeInfoList}
                    />
                    {store.targetFieldData && (
                        <ListPanel
                            alwaysEdit
                            items={store.targetFieldValues}
                            getItemKey={getItemKey}
                            renderItemEditor={renderDependencyEditor}
                            onCreateNewAsync={store.createNewDependencyAsync}
                            onRemoveItem={store.removeDependency}
                        />
                    )}
                </ValidationBoundary>
            </Ui.Modal.Body>
            <Ui.Modal.Footer
                left={(
                    <Ui.Modal.Button
                        automationId="FormFieldDependencyDialog_Cancel_Button"
                        closeModalOnClick
                        text={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FormFieldDependencyModal.CloseButton}
                        visualStyle="link" />
                )}
                right={(
                    <Ui.Modal.Button
                        automationId="FormFieldDependencyDialog_Ok_Button"
                        text="Ok"
                        onClick={store.saveDependency}
                        visualStyle="primary" />
                )} />
        </Ui.Modal>
    );
}

const FormFieldDependencyEditor = State.observer((props: { item: FormFieldDependencyStore, index: number, definitionId: FormDefinitionId }) => {
    const store = useFormFieldDependencyDialogStore();
    const dependencyData = store.getDependencyData;
    const properties = { ...dependencyData.props, definitionId: props.definitionId };
    
    return React.createElement(dependencyData.componentType, {
        ...dependencyData.props,
        ...properties,
        value: props.item.value,
        onChange: props.item.setValue,
        automationId: `FormFieldDependencyEditor_Condition_${props.index}`,
        propertyIdentifier: dependencyData.dataElement.name,
        dataElement: dependencyData.dataElement,
        label: `${StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FormFieldDependencyModal.Condition} #${props.index + 1}`
    });
});

function getItemKey(_: FormFieldDependencyStore, index: number) {
    return `FormFieldDependencyDialog_${index}`;
}

export default provideFormFieldDependencyDialogStore(FormFieldDependencyDialog);