import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as Proxy from "@HisPlatform/BoundedContexts/Authorization/Api/Proxy.g";
import Di from "@Di";
import Role from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/Role";
import RoleId from "@Primitives/RoleId.g";
import Permission from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/Permission";
import PermissionScope from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/PermissionScope";

type ResponseType = Proxy.UpdateRoleCommandResponse | Proxy.AddRoleCommandResponse | Proxy.RoleDto;

@Di.injectable()
export default class UserGroupStoreMapper extends EntityStoreMapper<Proxy.RoleDto, Role, ResponseType> {
    protected storeEntityIdType = RoleId;

    protected entityDtoSelector(response: ResponseType) {
        if (response instanceof Proxy.RoleDto) {
            return response;
        } else {
            return response.validatedResult;
        }
    }

    public applyToStoreCore(target: Role, response: Proxy.RoleDto) {
        const entity = response;

        target.shortName = entity.shortName;
        target.name = entity.name;
        target.description = entity.description;
        target.rowVersion = entity.rowVersion;
        target.setDisplayName();
        target.permissions.definitions = entity.permissions.map(item => new Permission([item.id],
            item.scopes.map(scope => scope.values?.mode === Proxy.SetSpecificationMode.Positive
                ? PermissionScope.createPositive(scope.type.value, scope.values?.includedItems)
                : PermissionScope.createNegative(scope.type.value, scope.values?.excludedItems)
            ))
        );
    }
}
