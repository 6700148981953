import React from "react";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { ICommonControlProps, getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { Moment } from "moment";

interface IMomentLabelDependencies {
    localizationService: ILocalizationService;
}

interface IMomentLabelProps extends ICommonControlProps {
    from: Moment;
    to?: Moment;
    showSeconds?: boolean;
    useNbsp?: boolean;
    useTodayString?: boolean;
    _dependencies?: IMomentLabelDependencies;
}

class MomentLabel extends React.PureComponent<IMomentLabelProps> {
    public static defaultProps: Partial<IMomentLabelProps> = {
        showSeconds: false,
        useNbsp: false,
        useTodayString: false
    };

    private get momentLabel() {
        const from = this.props.from ? 
        this.props._dependencies.localizationService.localizeDateTime(this.props.from, this.props.showSeconds, this.props.useNbsp, this.props.useTodayString) : "";
        let res = from;

        if (this.props.to) {
            const to = this.props.to.format("LT");
            res += ` - ${to}`;
        }

        return res;
    }

    public render() {
        const htmlProps = getStandardHtmlProps(this.props);
       
        return (
            <p title={this.momentLabel} {...htmlProps} style={{ display: "inline" }}>{this.momentLabel}</p>
        );
    }
}

export default connect(
    MomentLabel,
    new DependencyAdapter<IMomentLabelProps, IMomentLabelDependencies>((container) => {
        return {
            localizationService: container.resolve<ILocalizationService>("ILocalizationService")
        };
    })
);