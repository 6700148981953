import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import BooleanFormFieldData from "@Toolkit/FormEngine/Model/Data/BooleanFormFieldData";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import InvoiceScreenApiAdapter from "@HisPlatform/Packages/Patients/Screens/InvoiceScreen/InvoiceScreenApiAdapter";
import ShowCreateNewInvoiceScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowCreateNewInvoiceScreenAction.g";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import PatientId from "@Primitives/PatientId.g";
import ICustomerData from "@HisPlatform/Packages/Patients/Screens/InvoiceScreen/ICustomerData";
import CustomerId from "@Primitives/CustomerId.g";
import FinancedServiceApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/Finance/FinancedServiceApiAdapter";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import CompositeArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/CompositeArrayFormFieldData";
import NumberFormFieldData from "@Toolkit/FormEngine/Model/Data/NumberFormFieldData";
import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import VatRateId from "@Primitives/VatRateId.g";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import CareActivityId from "@Primitives/CareActivityId.g";
import MedicalServiceFinancingId from "@Primitives/MedicalServiceFinancingId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import InvoiceTypeId from "@Primitives/InvoiceTypeId.g";
import { ChangeAction } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";
import ReferencedExtensibleEnumFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedExtensibleEnumFormFieldData";
import BillableServiceTypeId from "@Primitives/BillableServiceTypeId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";

@Di.injectable()
class InvoiceScreenFormLogic implements IFormLogic {
    private readonly versionDate: LocalDate;
    private previousCustomerId: number = 0;
    private onceAddedServiceHashes = new Set<string>();

    constructor(
        @Di.inject("InvoiceScreenApiAdapter") private readonly apiAdapter: InvoiceScreenApiAdapter,
        @Di.inject("FinancedServiceApiAdapter") private readonly financedServiceApiAdapter: FinancedServiceApiAdapter,
        @Di.inject("FinanceReferenceDataStore") private readonly financedServiceReferenceData: FinanceReferenceDataStore
    ) {
        this.versionDate = DateTimeService.today();
    }

    public async onFormLoadedAsync(form: IForm, showScreenAction: ShowScreenFrontendActionBase, dataElements: FormDataElementBase[]): Promise<void> {
        const formFields = form.data.Content;
        const patientIdField = getField<ReferencedEntityFormFieldData>(formFields, "CustomerData.PatientId");
        if (showScreenAction instanceof ShowCreateNewInvoiceScreenAction && !patientIdField.value) {
            State.runInAction(() => { patientIdField.value = +showScreenAction.patientId.value; });
        }

        const customerIdField = getField<ReferencedEntityFormFieldData>(form.data.Content, "CustomerData.CustomerId");
        if (!customerIdField.value && this.previousCustomerId) {
            State.runInAction(() => { customerIdField.value = this.previousCustomerId; });
        }

        await this.financedServiceReferenceData.vatRateMap.ensureLoadedAsync();

        if (!customerIdField.value) {
            await this.fillCustomerDataAsync(formFields);
        }

        this.handleInvoiceItems(formFields);
        this.initializeServiceHashes(formFields);
    }

    public executeAsync(form: IForm, showScreenAction: ShowScreenFrontendActionBase, dataElements: FormDataElementBase[]): Promise<void> {
        const formFields = form.data.Content;
        const patientIdField = getField<ReferencedEntityFormFieldData>(formFields, "CustomerData.PatientId");
        if (showScreenAction instanceof ShowCreateNewInvoiceScreenAction && !patientIdField.value) {
            State.runInAction(() => { patientIdField.value = +showScreenAction.patientId.value; });
        }

        this.handleInvoiceItems(formFields);
        return Promise.resolve();
    }

    public async onFieldChangedAsync(
        form: IForm,
        showScreenAction: ShowScreenFrontendActionBase,
        dataElements: FormDataElementBase[],
        propertyIdentifier: string,
        newValue: any,
        action: ChangeAction
    ): Promise<void> {
        switch (propertyIdentifier) {
            case "BilledPerformedServiceList":
                await this.updateItemsByPerformedServicesAsync(form.data.Content);
                break;
            case "BaseData.InvoiceTypeId":
                this.invoiceTypeIdChanged(form.data.Content, newValue);
                break;
            case "CustomerData.CustomerId":
                const customerIdField = getField<ReferencedEntityFormFieldData>(form.data.Content, "CustomerData.CustomerId");
                if (!isNullOrUndefined(customerIdField.value)) {
                    State.runInAction(() => this.previousCustomerId = customerIdField.value);
                }
                await this.fillCustomerDataAsync(form.data.Content);
                break;
            case "CustomerData.IsCustomerSameAsPatient":
                await this.fillCustomerDataAsync(form.data.Content);
                break;
        }

        if (action === "add" && propertyIdentifier === "InvoiceItemListData.InvoiceItemsData") {
            const quantityField = getField<NumberFormFieldData>(newValue, "Quantity");
            State.runInAction(() => { 
                quantityField.value = 1;
                newValue.unshift(new ReferencedExtensibleEnumFormFieldData("BillableServiceTypeId", false, true, BillableServiceTypeId.PerformedService.value));
             });
        }

        const financedServiceIdChangeRegexMatch = /^InvoiceItemListData\.InvoiceItemsData\[(\d+)\]\.FinancedServiceId/gi.exec(propertyIdentifier);
        if (financedServiceIdChangeRegexMatch?.length >= 2) {
            await this.handleInvoiceItemFinancedServiceIdChangedAsync(form.data.Content, parseInt(financedServiceIdChangeRegexMatch[1]));
        }

        const invoiceItemChangeRegexMatch = /^InvoiceItemListData\.InvoiceItemsData\[(\d+)\]\./gi.exec(propertyIdentifier);
        if (invoiceItemChangeRegexMatch?.length >= 2) {
            await this.calculateInvoiceItemVatDataAsync(form.data.Content, parseInt(invoiceItemChangeRegexMatch[1]));
        }
    }

    @State.action
    private invoiceTypeIdChanged(formFields: FormFieldDataBase[], id: string | null): void {
        const advanceReferenceField = getField<ReferencedEntityFormFieldData>(formFields, "BaseData.AdvanceInvoiceReference");
        advanceReferenceField.isVisible = id === InvoiceTypeId.Final.value;
    }

    private async handleInvoiceItemFinancedServiceIdChangedAsync(formFields: FormFieldDataBase[], invoiceItemIndex: number) {
        const invoiceItems = getField<CompositeArrayFormFieldData>(formFields, "InvoiceItemListData.InvoiceItemsData");
        const invoiceItem = invoiceItems.value[invoiceItemIndex];

        const billableServiceTypeField = getField<ReferencedExtensibleEnumFormFieldData>(invoiceItem, "BillableServiceTypeId");
        const financedServiceId = getField<ReferencedEntityFormFieldData>(invoiceItem, "FinancedServiceId").value;
        const quantityField = getField<NumberFormFieldData>(invoiceItem, "Quantity");
        const grossUnitPriceField = getField<NumberFormFieldData>(invoiceItem, "GrossUnitPrice");
        const vatRateIdField = getField<ReferencedEntityFormFieldData>(invoiceItem, "VatRateId");

        State.runInAction(() => billableServiceTypeField.value = BillableServiceTypeId.PerformedService.value);
        if (!isNullOrUndefined(financedServiceId) && financedServiceId > 0) {
            const priceData = await this.financedServiceApiAdapter.getFinancedServicePriceDataAsync(new FinancedServiceId(financedServiceId.toString()), this.versionDate);
            const vatRateId = this.getVatRateWithFallback(priceData.value.vatRateId, vatRateIdField?.value);
            const grossCalculationRate = await this.getGrossCalculationRateAsync(vatRateId);
            const vatValue = this.calculateVatValueFromNet(grossCalculationRate, priceData.value.netUnitPrice.amount);
            const grossUnitPrice = priceData.value.netUnitPrice.amount + vatValue;

            State.runInAction(() => {
                grossUnitPriceField.value = grossUnitPrice;
                vatRateIdField.value = parseInt(vatRateId.value);
            });
        } else {
            State.runInAction(() => {
                quantityField.value = 1;
                grossUnitPriceField.value = 0;
                vatRateIdField.value = parseInt(this.getVatRateWithFallback(null, vatRateIdField?.value).value);
            });
        }
    }

    private getVatRateWithFallback(vatRateId: VatRateId | null, vatRateIdFromForm: number | null): VatRateId {
        if (!isNullOrUndefined(vatRateId)) {
            return vatRateId;
        }

        if (!isNullOrUndefined(vatRateIdFromForm)) {
            return new VatRateId(vatRateIdFromForm.toString());
        }

        return this.financedServiceReferenceData.vatRateMap.items[0].id;
    }

    private async calculateInvoiceItemVatDataAsync(formFields: FormFieldDataBase[], invoiceItemIndex: number) {
        const invoiceItems = getField<CompositeArrayFormFieldData>(formFields, "InvoiceItemListData.InvoiceItemsData");
        const invoiceItem = invoiceItems.value[invoiceItemIndex];

        const quantityField = getField<NumberFormFieldData>(invoiceItem, "Quantity");
        const netUnitPriceField = getField<NumberFormFieldData>(invoiceItem, "NetUnitPrice");
        const grossUnitPriceField = getField<NumberFormFieldData>(invoiceItem, "GrossUnitPrice");
        const vatRateIdField = getField<ReferencedEntityFormFieldData>(invoiceItem, "VatRateId");
        const grossPriceField = getField<NumberFormFieldData>(invoiceItem, "GrossPrice");

        if (isNullOrUndefined(vatRateIdField?.value) || vatRateIdField.value === 0) {
            return;
        }

        const grossCalculationRate = await this.getGrossCalculationRateAsync(new VatRateId(vatRateIdField.value.toString()));
        const vatValue = this.calculateVatValueFromGross(grossCalculationRate, grossUnitPriceField.value);
        const netUnitPrice = grossUnitPriceField.value - vatValue;
        const grossValue = grossUnitPriceField.value * quantityField.value;

        State.runInAction(() => {
            netUnitPriceField.value = netUnitPrice;
            netUnitPriceField.isVisible = false;
            grossPriceField.value = grossValue;
            grossPriceField.isReadOnly = true;
        });
    }

    private calculateVatValueFromGross(grossCalculationRate: number, grossValue: number) {
        const temp = grossCalculationRate * 100;
        return Math.round(grossValue / temp * (temp - 100));
    }

    private calculateVatValueFromNet(grossCalculationRate: number, netValue: number) {
        return Math.round(netValue * (grossCalculationRate - 1));
    }

    private async tryGetCustomerDataAsync(formFields: FormFieldDataBase[]): Promise<ICustomerData> {
        const isCustomerSameAsPatientField = getField<BooleanFormFieldData>(formFields, "CustomerData.IsCustomerSameAsPatient");
        if (isCustomerSameAsPatientField.value) {
            const patientIdField = getField<ReferencedEntityFormFieldData>(formFields, "CustomerData.PatientId");
            if (!patientIdField) {
                return null;
            }

            return await this.tryGetCustomerDataByPatientIdAsync(new PatientId(`${patientIdField.value}`));
        }

        const customerIdField = getField<ReferencedEntityFormFieldData>(formFields, "CustomerData.CustomerId");
        if (!customerIdField) {
            return null;
        }

        let customerId = customerIdField.value ? new CustomerId(`${customerIdField.value}`) : null;
        if (customerIdField.value) {
            customerId = new CustomerId(`${customerIdField.value}`);
        } else if (this.previousCustomerId) {
            customerId = new CustomerId(`${this.previousCustomerId}`);
        }

        if (!customerId) {
            return null;
        }

        const customerData = await this.apiAdapter.getCustomerDataAsync(customerId, true);

        return customerData.result;
    }

    @State.action
    private async fillCustomerDataAsync(formFields: FormFieldDataBase[]): Promise<void> {
        const isCustomerSameAsPatientField = getField<BooleanFormFieldData>(formFields, "CustomerData.IsCustomerSameAsPatient");
        if (isCustomerSameAsPatientField.value) {
            this.hideCustomerSpecificFields(formFields);
        } else {
            this.hidePatientSpecificFields(formFields);
        }

        const customerData = await this.tryGetCustomerDataAsync(formFields);
        if (isNullOrUndefined(customerData)) {
            return;
        }

        // The country needs to be set first so that the ZipCodeAndSettlementFormCustomBlock
        // does not reset the zip code and settlement fields.
        State.runInAction(() => {
            const countryIdField = getField<ReferencedEntityFormFieldData>(formFields, "CustomerData.CountryId");
            countryIdField.value = +customerData.countryId.value;
        });

        const customerIdField = getField<ReferencedEntityFormFieldData>(formFields, "CustomerData.CustomerId");
        const customerNameField = getField<TextFormFieldData>(formFields, "CustomerData.CustomerName");
        const zipCodeField = getField<TextFormFieldData>(formFields, "CustomerData.ZipCode");
        const settlementField = getField<TextFormFieldData>(formFields, "CustomerData.Settlement");
        const addressLineField = getField<TextFormFieldData>(formFields, "CustomerData.AddressLine");
        const emailAddressField = getField<TextFormFieldData>(formFields, "CustomerData.EmailAddress");
        const phoneNumberField = getField<TextFormFieldData>(formFields, "CustomerData.PhoneNumber");
        const taxPayerInfoTypeField = getField<TextFormFieldData>(formFields, "CustomerData.TaxPayerInfoType");
        const taxNumberField = getField<TextFormFieldData>(formFields, "CustomerData.TaxNumber");
        const groupIdentifierField = getField<TextFormFieldData>(formFields, "CustomerData.GroupIdentifier");
        const groupMemberTaxNumberField = getField<TextFormFieldData>(formFields, "CustomerData.GroupMemberTaxNumber");
        const communityTaxNumberField = getField<TextFormFieldData>(formFields, "CustomerData.CommunityTaxNumber");

        State.runInAction(() => {
            if (customerData.customerId) {
                customerIdField.value = +customerData.customerId.value;
            }

            customerNameField.value = customerData.customerName;
            zipCodeField.value = customerData.zipCode;
            settlementField.value = customerData.settlement;
            addressLineField.value = customerData.addressLine;
            emailAddressField.value = customerData.emailAddress;
            phoneNumberField.value = customerData.phoneNumber;

            if (customerData.taxPayerInfoType) {
                taxPayerInfoTypeField.value = customerData.taxPayerInfoType;
                taxNumberField.value = customerData.taxNumber;
                groupIdentifierField.value = customerData.groupIdentifier;
                groupMemberTaxNumberField.value = customerData.groupMemberTaxNumber;
                communityTaxNumberField.value = customerData.communityTaxNumber;
            }
        });
    }

    private async tryGetCustomerDataByPatientIdAsync(patientId: PatientId): Promise<ICustomerData> {
        let customerData = await this.apiAdapter.getCustomerDataByPatientIdAsync(patientId, true);
        if (customerData.result) {
            return customerData.result;
        }

        customerData = await this.apiAdapter.getNewCustomerDataAsync(patientId);
        return customerData.result;
    }

    @State.action
    private hidePatientSpecificFields(formFields: FormFieldDataBase[]): void {
        const customerIdField = getField<ReferencedEntityFormFieldData>(formFields, "CustomerData.CustomerId");
        customerIdField.isVisible = true;
    }

    @State.action
    private hideCustomerSpecificFields(formFields: FormFieldDataBase[]): void {
        const customerIdField = getField<ReferencedEntityFormFieldData>(formFields, "CustomerData.CustomerId");
        customerIdField.isVisible = false;
        customerIdField.value = null;
        this.previousCustomerId = 0;
    }

    private initializeServiceHashes(formFields: FormFieldDataBase[]) {
        const billedPerformedServices = getField<CompositeArrayFormFieldData>(formFields, "BilledPerformedServiceList");

        for (const billedService of billedPerformedServices.value) {
            const billableServiceTypeField = getField<ReferencedExtensibleEnumFormFieldData>(billedService, "BillableServiceTypeId");
            if (!isNullOrUndefined(billableServiceTypeField) && billableServiceTypeField.value === BillableServiceTypeId.ServiceRequest.value) {
                const hash = this.getBilledServiceRequestHash(
                    new CareActivityId(getField<ReferencedEntityFormFieldData>(billedService, "CareActivityId").value.toString()),
                    new ServiceRequestId(getField<ReferencedEntityFormFieldData>(billedService, "ServiceRequestId").value.toString())
                );
                this.onceAddedServiceHashes.add(hash);
                continue;
            }

            const financedServiceIdValue = getField<ReferencedEntityFormFieldData>(billedService, "FinancedServiceId");
            const financedServiceId = new FinancedServiceId(financedServiceIdValue.value.toString());

            const hash = this.getBilledPerformedServiceHash(
                new CareActivityId(getField<ReferencedEntityFormFieldData>(billedService, "CareActivityId").value.toString()),
                new MedicalServiceFinancingId(getField<ReferencedEntityFormFieldData>(billedService, "MedicalServiceFinancingId").value.toString()),
                financedServiceId
            );

            this.onceAddedServiceHashes.add(hash);
        }
    }

    private async updateItemsByPerformedServicesAsync(formFields: FormFieldDataBase[]) {
        const billedPerformedServicesField = getField<CompositeArrayFormFieldData>(formFields, "BilledPerformedServiceList");
        const invoiceItems = getField<CompositeArrayFormFieldData>(formFields, "InvoiceItemListData.InvoiceItemsData");

        for (const billedService of billedPerformedServicesField.value) {
            const billableServiceTypeField = getField<ReferencedExtensibleEnumFormFieldData>(billedService, "BillableServiceTypeId");
            if (!isNullOrUndefined(billableServiceTypeField) && billableServiceTypeField.value === BillableServiceTypeId.ServiceRequest.value) {
                await this.tryAddBilledServiceRequestAsync(invoiceItems, billedService);
                continue;
            }

            const financedServiceIdValue = getField<ReferencedEntityFormFieldData>(billedService, "FinancedServiceId");
            const financedServiceId = new FinancedServiceId(financedServiceIdValue.value.toString());

            const hash = this.getBilledPerformedServiceHash(
                new CareActivityId(getField<ReferencedEntityFormFieldData>(billedService, "CareActivityId").value.toString()),
                new MedicalServiceFinancingId(getField<ReferencedEntityFormFieldData>(billedService, "MedicalServiceFinancingId").value.toString()),
                financedServiceId
            );

            if (this.onceAddedServiceHashes.has(hash)) {
                continue;
            }

            const itemExists = invoiceItems.value.some(invoiceItem => {
                const itemFinancedServiceId = getField<ReferencedEntityFormFieldData>(invoiceItem, "FinancedServiceId");
                return financedServiceIdValue.value === itemFinancedServiceId?.value;
            });

            if (!itemExists) {
                const performedServiceQuantity = getField<NumberFormFieldData>(billedService, "Quantity");
                const financedService = await this.financedServiceReferenceData.financedServiceMap.getOrLoadAsync(new EntityVersionSelector(financedServiceId, LocalDate.today()));
                const priceData = await this.financedServiceApiAdapter.getFinancedServicePriceDataAsync(financedServiceId, this.versionDate);
                const netUnitPrice = priceData.value.netUnitPrice.amount;
                const vatRateId = priceData.value.vatRateId ?? this.financedServiceReferenceData.vatRateMap.items[0].id;

                const grossCalculationRate = await this.getGrossCalculationRateAsync(vatRateId);
                const grossUnitPrice = Math.round(netUnitPrice * grossCalculationRate);
                const grossValue = grossUnitPrice * performedServiceQuantity.value;

                State.runInAction(() => {
                    invoiceItems.value.unshift(State.createObservableShallowArray([
                        new ReferencedExtensibleEnumFormFieldData("BillableServiceTypeId", false, true, BillableServiceTypeId.PerformedService.value),
                        new ReferencedEntityFormFieldData("FinancedServiceId", false, true, null, financedServiceIdValue.value),
                        new TextFormFieldData("FinancedServiceName", false, true, financedService.name),
                        new NumberFormFieldData("Quantity", false, true, performedServiceQuantity.value),
                        new NumberFormFieldData("NetUnitPrice", false, true, netUnitPrice),
                        new NumberFormFieldData("GrossUnitPrice", false, true, grossUnitPrice),
                        new ReferencedEntityFormFieldData("VatRateId", false, true, null, parseInt(vatRateId.value)),
                        new NumberFormFieldData("GrossPrice", false, true, grossValue),
                        new TextFormFieldData("Comment", false, true, "")
                    ]));
                });

                this.onceAddedServiceHashes.add(hash);
            }
        }
    }

    private async tryAddBilledServiceRequestAsync(
        invoiceItems: CompositeArrayFormFieldData,
        serviceRequest: FormFieldDataBase[]
    ): Promise<void> {
        const serviceRequestIdField = getField<ReferencedEntityFormFieldData>(serviceRequest, "ServiceRequestId");
        const careActivityIdField = getField<ReferencedEntityFormFieldData>(serviceRequest, "CareActivityId");
        const quantityField = getField<NumberFormFieldData>(serviceRequest, "Quantity");
        const totalNetPriceField = getField<NumberFormFieldData>(serviceRequest, "TotalNetPrice");
        const serviceRequestNameField = getField<TextFormFieldData>(serviceRequest, "ServiceRequestName");
        const serviceRequestId = new ServiceRequestId(serviceRequestIdField.value.toString());
        const careActivityId = new CareActivityId(careActivityIdField.value.toString());
        const hash = this.getBilledServiceRequestHash(careActivityId, serviceRequestId);
        if (this.onceAddedServiceHashes.has(hash)) {
            return;
        }

        const itemExists = invoiceItems.value.some(invoiceItem => {
            const existingServiceRequestIdField = getField<ReferencedEntityFormFieldData>(invoiceItem, "ServiceRequestId");
            return !isNullOrUndefined(existingServiceRequestIdField) && existingServiceRequestIdField.value === serviceRequestIdField.value;
        });
        if (itemExists) {
            return;
        }

        const vatRate = this.financedServiceReferenceData.vatRateMap.items[0];
        if (isNullOrUndefined(vatRate)) {
            return;
        }

        const grossCalculationRate = await this.getGrossCalculationRateAsync(vatRate.id);
        const grossUnitPrice = Math.round(totalNetPriceField.value * grossCalculationRate);
        const grossValue = grossUnitPrice * quantityField.value;

        State.runInAction(() => {
            invoiceItems.value.unshift(State.createObservableShallowArray([
                new ReferencedExtensibleEnumFormFieldData("BillableServiceTypeId", false, true, BillableServiceTypeId.ServiceRequest.value),
                new ReferencedEntityFormFieldData("ServiceRequestId", false, true, null, serviceRequestIdField.value),
                new TextFormFieldData("FinancedServiceName", false, true, serviceRequestNameField.value),
                new NumberFormFieldData("Quantity", false, true, quantityField.value),
                new NumberFormFieldData("NetUnitPrice", false, true, totalNetPriceField.value),
                new NumberFormFieldData("GrossUnitPrice", false, true, grossUnitPrice),
                new ReferencedEntityFormFieldData("VatRateId", false, true, null, parseInt(vatRate.id.value)),
                new NumberFormFieldData("GrossPrice", false, true, grossValue),
            ]));
        });

        this.onceAddedServiceHashes.add(hash);
    }

    @State.action
    private handleInvoiceItems(formFields: FormFieldDataBase[]): void {
        const invoiceItems = getField<CompositeArrayFormFieldData>(formFields, "InvoiceItemListData.InvoiceItemsData");
        let totalGrossValue: number = 0;
        let totalNetValue: number = 0;

        for (let i = 0; i < invoiceItems.value.length; i++) {
            const invoiceItem = invoiceItems.value[i];
            const quantityField = getField<NumberFormFieldData>(invoiceItem, "Quantity");
            const netUnitPriceField = getField<NumberFormFieldData>(invoiceItem, "NetUnitPrice");
            const grossPriceField = getField<NumberFormFieldData>(invoiceItem, "GrossPrice");

            totalGrossValue += grossPriceField.value;
            totalNetValue += (netUnitPriceField.value * quantityField.value);

            grossPriceField.isReadOnly = true;
        }

        const totalNetPriceField = getField<NumberFormFieldData>(formFields, `InvoiceItemListData.TotalNetPrice`);
        const totalGrossPriceField = getField<NumberFormFieldData>(formFields, `InvoiceItemListData.TotalGrossPrice`);

        totalNetPriceField.value = totalNetValue;
        totalNetPriceField.isReadOnly = true;
        totalGrossPriceField.value = totalGrossValue;
        totalGrossPriceField.isReadOnly = true;
    }

    private getBilledPerformedServiceHash(caid: CareActivityId, msfid: MedicalServiceFinancingId, fsid: FinancedServiceId) {
        return `${caid.value}_${msfid.value}_${fsid.value}`;
    }

    private getBilledServiceRequestHash(careActivityId: CareActivityId, serviceRequestId: ServiceRequestId): string {
        return `CA${careActivityId.value}_SR${serviceRequestId.value}`;
    }

    private async getGrossCalculationRateAsync(vatRateId: VatRateId): Promise<number> {
        const grossCalculationRate = (await this.financedServiceReferenceData.vatRateMap.getByIdAsync(vatRateId)).grossCalculationRate;
        return grossCalculationRate == 0 ? 1 : grossCalculationRate + 1;
    }
}

export default InvoiceScreenFormLogic;