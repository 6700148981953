import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import IMedicationSubsidyClaimType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IMedicationSubsidyClaimType";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface ISubsidyClaimTypeSelectorDependencies {
    referenceDataStore: MedicationRequestReferenceDataStore;
}

interface ISubsidyClaimTypeSelectorProps extends ISelectBoxBaseProps {
    _dependencies?: ISubsidyClaimTypeSelectorDependencies;
    idFilter?: MedicationSubsidyClaimTypeId[];
}

/** @screen */
@State.observer
class SubsidyClaimTypeSelector extends React.Component<ISubsidyClaimTypeSelectorProps> {

    private get claimTypeStore() { return this.props._dependencies.referenceDataStore.medicationSubsidyClaimTypes; }

    @State.observable.ref private items: Array<ISelectBoxItem<MedicationSubsidyClaimTypeId>> = [];

    @State.action.bound
    private setItems(items: Array<ISelectBoxItem<MedicationSubsidyClaimTypeId>>) {
        this.items = items;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }

    public componentDidUpdate(prevProps: ISubsidyClaimTypeSelectorProps) {
        if (this.props.idFilter !== prevProps.idFilter) {
            this.setItemsBasedOnStore();
        }
    }

    private filterItems(source: IMedicationSubsidyClaimType[]) {
        if (isNullOrUndefined(this.props.idFilter)) {
            return source;
        }
        return source.filter(i => this.props.idFilter.some(j => ValueWrapper.equals(i.id, j)));
    }

    @State.bound
    private async initializeAsync() {
        await this.claimTypeStore.ensureAllLoadedAsync();
        this.setItemsBasedOnStore();
    }

    @State.bound
    private setItemsBasedOnStore() {
        this.setItems(this.filterItems(this.claimTypeStore.items).map(i => ({
            text: i.name,
            value: i.id,
            longDisplayValue: i.code + " - " + i.name
        } as ISelectBoxItem<MedicationSubsidyClaimTypeId>)));
    }

    public render() {
        return (
            <Ui.SelectBox items={this.items} {...this.props} />
        );
    }
}

export default connect(
    SubsidyClaimTypeSelector,
    new DependencyAdapter<ISubsidyClaimTypeSelectorProps, ISubsidyClaimTypeSelectorDependencies>(c => ({
        referenceDataStore: c.resolve("MedicationRequestReferenceDataStore")
    }))
);