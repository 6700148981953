import { IValidationProblemProcessor } from "@Toolkit/CommonWeb/Validation/ValidationProblemProcessorService";
import MedicalServiceFinancingStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/MedicalServiceFinancingStore";
import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import _ from "@HisPlatform/Common/Lodash";
import FinancedServiceWithQuantityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/FinancedServiceWithQuantityStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { FinancedServiceRuleValidationProblemProcessorBase, IRelatedCareActivity, IFinancedServiceRuleValidationProblemReferenceDataCollection } from "./FinancedServiceRuleValidationProblemProcessorBase";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import ConditionId from "@Primitives/ConditionId.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";

export class DiagnosisRuleValidationProblemProcessor extends FinancedServiceRuleValidationProblemProcessorBase implements  IValidationProblemProcessor<MedicalServiceFinancingStore>  {
    public getRawRelatedCareActivities(problem: IClientValidationProblem): IRelatedCareActivity[] {
        return [];
    }

    public ruleId = "ShouldBeCompliantWithFinanceServiceDiagnosisRules";

    constructor(
        financeReferenceDataStore: FinanceReferenceDataStore,
        localizationService: ILocalizationService,
        organizationReferenceDataStore: OrganizationReferenceDataStore,
        private careReferenceDataStore: CareReferenceDataStore
    ) {
        super(localizationService, organizationReferenceDataStore, financeReferenceDataStore);
    }

    public getServiceId(problem: IClientValidationProblem) {
        const serviceIdValue = _.get(problem.parameters, "PerformedServiceDiagnosisRuleViolationParametersDto.ServiceId.Value");
        return serviceIdValue ? new FinancedServiceId(serviceIdValue.toString()) : null;
    }

    private getConditionIds(problem: IClientValidationProblem) {
        const ids = _.get(problem.parameters, "PerformedServiceDiagnosisRuleViolationParametersDto.Conditions") as any[];
        return ids?.map(i => new ConditionId(i?.Value?.toString())) || [];
    }

    public mapValidationProblem(problem: IClientValidationProblem, items: MedicalServiceFinancingStore[]) {
        const serviceId = this.getServiceId(problem);
        if (serviceId) {
            return items.filter(i => i.financedServicesWithQuantities.some((j: FinancedServiceWithQuantityStore) => {
                return ValueWrapper.equals(serviceId, j.financedServiceId.id);
            }));
        }
        return [];
    }

    public renderInfo = (problem: IClientValidationProblem) => {
        const conditionIds = this.getConditionIds(problem);

        const conditionsCodes = conditionIds.map(conditionId => this.careReferenceDataStore.condition.get({id: conditionId, validOn: LocalDate.today()})?.code);

        return formatStringWithObjectParams(StaticCareResources.OutpatientWorkflow.PerformedServicesStep.DiagnosisRuleInfoFormat, {
            ConditionCodes: conditionsCodes.join(", ")
        });
    };
}