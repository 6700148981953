import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import { getNavigationMenuItems } from "@HisPlatform/Packages/Authorization/Configuration/NavigationMenuItems";
import ShowRolesScreenAction from "@HisPlatform/Packages/Authorization/FrontendActions/ShowRolesScreenAction.g";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import NavigateToRouteActionProcessor from "@HisPlatform/Services/Implementation/ActionProcessing/NavigateToRouteActionProcessor";
import GlobalActionRegistry from "@Toolkit/ReactClient/ActionProcessing/GlobalActionRegistry";

class AuthorizationPackageInitializer implements IPackageInitializer {

    public configureActionProcessor(registry: GlobalActionRegistry) {

        registry.register(
            ShowRolesScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => ApplicationRoutes.roleManagement.makeRoute()));
    }

    public configureDynamicNavigation(registry: IDynamicNavigationRegistry): void {
        getNavigationMenuItems().forEach(navigationItem => registry.register(navigationItem));
    }
}

export default new AuthorizationPackageInitializer();