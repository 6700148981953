import Di from "@Di";
import ConditionStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/ConditionStatementHistoryItem";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import ConditionStatementId from "@Primitives/ConditionStatementId.g";
import moment from "moment";
import ReferencedConditionAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/ReferencedConditionAssignment";
import FormerConditionDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/FormerConditionDetail";
import ConditionStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/ConditionStatementStore";
import ConditionStatementClinicalStatus from "@HisPlatform/BoundedContexts/Care/Api/MedicalCondition/ConditionStatement/Enum/ConditionStatementClinicalStatus.g";

@Di.injectable()
export default class ConditionStatementFactory {

    public createNewStore(clinicalStatus: ConditionStatementClinicalStatus = ConditionStatementClinicalStatus.Active) {
        const store = new ConditionStatementStore(true);
        store.id = new ConditionStatementId("new");
        store.conditionAssignment = new ReferencedConditionAssignment(null);
        store.clinicalStatus = clinicalStatus;
        store.historyItems.push(this.createNewHistoryItem(clinicalStatus));
        store.currentItemIndex = 0;

        return store;
    }

    private createNewHistoryItem(clinicalStatus: ConditionStatementClinicalStatus) {
        const historyItem = new ConditionStatementHistoryItem();

        historyItem.isNew = true;
        historyItem.versionNumber = 1;
        historyItem.index = 0;
        historyItem.createdAt = moment(moment.now());
        historyItem.onsetDate = LocalDate.today();
        historyItem.clinicalStatus = clinicalStatus;
        historyItem.historyItemDetail = new MedicalConditionHistoryItemDetail();

        const inactivationDate = clinicalStatus === ConditionStatementClinicalStatus.Active ? null : LocalDate.today();
        historyItem.formerConditionDetail = new FormerConditionDetail("", inactivationDate);

        return historyItem;
    }
}
