import React from "react";
import * as Ui from "@CommonControls";
import IWidgetComponentProps from "@PluginInterface/Dashboard/IWidgetComponentProps";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import GrossNeakIncomeWidgetApiAdapter from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/GrossNeakIncomeWidget/GrossNeakIncomeWidgetApiAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IGrossNeakIncomeDisplayData from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/GrossNeakIncomeWidget/IGrossNeakIncomeDisplayData";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";

interface IGrossNeakIncomeWidgetDependencies {
    apiAdapter: GrossNeakIncomeWidgetApiAdapter;
    applicationContext: ApplicationContext;
}

interface IGrossNeakIncomeWidgetProps extends IWidgetComponentProps {
    _dependencies?: IGrossNeakIncomeWidgetDependencies;
}

/** @screen */
@State.observer
class GrossNeakIncomeWidget extends React.Component<IGrossNeakIncomeWidgetProps> {

    private get apiAdapter() { return this.props._dependencies.apiAdapter; }

    @State.observable.ref private dateRange: LocalDateRange = null;
    @State.observable.ref private displayData: IGrossNeakIncomeDisplayData[] = null;
    @State.observable private currencyCode: string = null;
    @State.observable private insurerOrganizationCode: string = null;

    @State.action.bound
    private setDateRange(newValue: LocalDateRange) {
        this.dateRange = newValue;
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.initializeAsync);
    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.action.bound
    private async initializeAsync() {
        this.setInitialValues();
        await this.loadAsync();
    }

    @State.action.bound
    private setInitialValues() {
        this.dateRange = new LocalDateRange(LocalDate.createFromMoment(DateTimeService.now().startOf("month")), LocalDate.createFromMoment(DateTimeService.now().endOf("month")));
        this.currencyCode = this.props.configuration.CurrencyCode;
        this.insurerOrganizationCode = this.props.configuration.InsurerOrganizationCode;
    }

    @State.action.bound
    private async loadAsync() {

        if (!this.props._dependencies.applicationContext.contextualOrganizationUnitId) {
            return;
        }

        const response = await this.apiAdapter.getAsync(
            this.currencyCode,
            this.dateRange.from.stringify(),
            this.dateRange.to.stringify(),
            this.insurerOrganizationCode,
            this.props._dependencies.applicationContext.contextualOrganizationUnitId);

        State.runInAction(() => {
            this.displayData = response.value.map(v => {
                return {
                    serviceCode: v.serviceCode,
                    serviceName: v.serviceName,
                    count: v.count,
                    originalCurrencyUnitPrice: v.originalCurrencyUnitPrice.amount.toString(),
                    originalCurrencySumPrice: v.originalCurrencySumPrice.amount.toString(),
                    convertedCurrencySumPrice: Math.round(v.convertedCurrencySumPrice.amount).toString()
                } as IGrossNeakIncomeDisplayData;
            });
        });
    }

    private renderFilters() {
        return (
            <Ui.DateRangePicker
                visualMode="dark"
                label={StaticProductivityResources.Widgets.GrossNeakIncomeWidget.DateRange}
                value={this.dateRange}
                onChange={this.setDateRange}
                clearable={false}
                automationId="grossNeakIncomeWidgetDateRangePicker"
            />
        );
    }

    @State.bound
    private renderWidgetContent() {
        if (isNullOrUndefined(this.displayData) || this.displayData.length === 0) {
            return (
                <div style={{ textAlign: "center" }}>
                    {StaticProductivityResources.Widgets.GrossNeakIncomeWidget.NoDataMessage}
                </div>
            );
        }

        return (
            <Ui.Table visualStyle="dark" style={{ width: "98%", padding: "3px", margin: "auto", marginTop: "10px" }} automationId="grossNeakIncomeWidgetContentTable">
                <thead>
                    <tr>
                        <th>{StaticProductivityResources.Widgets.GrossNeakIncomeWidget.NumberColumn}</th>
                        <th>{StaticProductivityResources.Widgets.GrossNeakIncomeWidget.CodeColumn}</th>
                        <th>{StaticProductivityResources.Widgets.GrossNeakIncomeWidget.NameColumn}</th>
                        <th>{StaticProductivityResources.Widgets.GrossNeakIncomeWidget.CountColumn}</th>
                        <th>{StaticProductivityResources.Widgets.GrossNeakIncomeWidget.OriginalCurrencyUnitPriceColumn}</th>
                        <th>{StaticProductivityResources.Widgets.GrossNeakIncomeWidget.OriginalCurrencySumPriceColumn}</th>
                        <th>{StaticProductivityResources.Widgets.GrossNeakIncomeWidget.ConvertedCurrencySumPriceColumn}</th>
                    </tr>
                    {this.displayData && this.renderCells()}
                </thead>
            </Ui.Table>
        );
    }

    @State.bound
    private renderCells() {
        let number = 0;
        return this.displayData.map(d => {
            number++;
            return (
                <tr key={d.serviceCode}>
                    <td>{number}</td>
                    <td>{d.serviceCode}</td>
                    <td>{d.serviceName}</td>
                    <td>{d.count}</td>
                    <td>{d.originalCurrencyUnitPrice}</td>
                    <td>{d.originalCurrencySumPrice}</td>
                    <td>{d.convertedCurrencySumPrice}</td>
                </tr>
            );
        });
    }

    public render() {
        return (
            <Ui.Dashboard.Widget
                name={this.props.name}
                containerHeight={this.props.configuration.InitialLayout.Height}
                title={StaticProductivityResources.Widgets.GrossNeakIncomeWidget.Title}
                icon={"chart"}
                isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                isCollapsible
                automationId="GrossNeakIncomeWidget"
            >
                {this.initialLoadPanelAsync.isUnauthorizedAccess ?
                    <UnauthorizedAccessContent visualStyle="dark" /> :
                    <>
                        {this.renderFilters()}
                        {this.renderWidgetContent()}
                    </>
                }
                <State.Reaction inspector={this.getOrganizationUnitId} reaction={this.reload} />
            </Ui.Dashboard.Widget >
        );
    }

    @State.bound
    private getOrganizationUnitId() {
        return this.props._dependencies!.applicationContext.contextualOrganizationUnitId;
    }

    @State.bound
    private reload() {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }
}

export default connect(
    withLoadingBoundary(GrossNeakIncomeWidget),
    new DependencyAdapter<IGrossNeakIncomeWidgetProps, IGrossNeakIncomeWidgetDependencies>(c => ({
        apiAdapter: c.resolve("GrossNeakIncomeWidgetApiAdapter"),
        applicationContext: c.resolve("ApplicationContext"),
    }))
);