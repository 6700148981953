import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import CheckBox from "@CommonControls/CheckBox";
import NumericRangeProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/NumericRangeProperty";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import NumericRange from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/NumericRange";
import PropertyVersionValidity from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PropertyGroupEditableColumn/PropertyVersionValidity";

interface INumericRangePropertyEditorProps {
    row: NumericRangeProperty;
}

@State.observer
export class NumericRangePropertyEditor extends React.Component<INumericRangePropertyEditorProps> {

    @State.computed private get currentValue() {
        return this.props.row.getValue(this.props.row.isTemporal ? LocalDate.today() : undefined);
    }

    private get hasValue() {
        return this.props.row.hasValue;
    }

    @State.action.bound
    private setEnabled(isEnabled: boolean) {
        if (isEnabled) {
            this.setCurrentVersionValue(this.currentValue ?? new NumericRange(0, 0));
        } else {
            this.props.row.clearValue();
        }
    }

    public render() {
        return (
            <Ui.Flex>
                <Ui.Flex.Item sm={2}>
                    <CheckBox value={this.hasValue} onChange={this.setEnabled} automationId={this.props.row.name + "_checkBox"} />
                </Ui.Flex.Item>
                <Ui.Flex.Item sm={5}>
                    <Ui.NumberBox value={this.currentValue?.from ?? 0} onChange={this.setCurrentVersionFromValue} disabled={!this.hasValue} automationId={this.props.row.name + "_fromNumberBox"} />
                    <PropertyVersionValidity property={this.props.row} />
                </Ui.Flex.Item>
                <Ui.Flex.Item sm={5}>
                    <Ui.NumberBox value={this.currentValue?.to ?? 0} onChange={this.setCurrentVersionToValue} disabled={!this.hasValue} automationId={this.props.row.name + "_toNumberBox"} />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.bound
    private setCurrentVersionValue(newValue: NumericRange) {
        this.props.row.replaceVersion(newValue, this.props.row.isTemporal ? LocalDate.today() : undefined);
    }

    @State.bound
    private setCurrentVersionFromValue(from: number) {
        this.props.row.replaceVersion(new NumericRange(from, this.currentValue.to), this.props.row.isTemporal ? LocalDate.today() : undefined);
    }

    @State.bound
    private setCurrentVersionToValue(to: number) {
        this.props.row.replaceVersion(new NumericRange(this.currentValue.from, to), this.props.row.isTemporal ? LocalDate.today() : undefined);
    }
}
