import React from "react";
import AccordionPane from "@CommonControls/MultiAccordion/AccordionPane";
import MultiAccordionStore from "@CommonControls/MultiAccordion/MultiAccordionStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IMultiAccordionProps {
    title?: string;
    selectedIndex?: number;
    onChange?: (newIndex: number) => void;
}

@State.observer
export default class MultiAccordion extends React.Component<IMultiAccordionProps, undefined> {
    private store: MultiAccordionStore;

    
    public static Pane = AccordionPane;

    private activeIndex = () => this.props.selectedIndex;

    constructor(props: IMultiAccordionProps) {
        super(props);

        if (props.selectedIndex === undefined) {
            this.store = new MultiAccordionStore();
            this.activeIndex = () => this.store.activeIndex;
        }
    }

    @State.bound
    private clickHandler(index: number) {
        if (this.store) {
            this.store.setActiveIndex(index);
        }

        if (this.props.onChange) {
            this.props.onChange(index);
        }
    }

    public render() {
        return (
            <div>
                {React.Children.map(this.props.children, (child: any, index: number) => {
                    if (child.props.index === undefined) {
                        return React.cloneElement(child, {
                            onClick: this.clickHandler,
                            activeIndex: this.activeIndex(),
                            index: index
                        });
                    }
                    return React.cloneElement(child, {
                        onClick: this.clickHandler,
                        activeIndex: this.activeIndex()
                    });
                })}
            </div>
        );
    }
}
