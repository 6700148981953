/** Creates an URL query string from the given object. */
export function buildQueryString(valueObject: any) {
    if (!valueObject || !Object.keys(valueObject).length) {
        return "";
    }

    const params = new URLSearchParams();

    for (const [key, value] of Object.entries(valueObject)) {
        if (value === null || value === undefined || (typeof value === "string" && value.length === 0)) {
            continue;
        }

        params.set(key, value.toString());
    }

    return params.toString();
}

export function buildQueryStringUrl(valueObject: any) {
    return "?" + buildQueryString(valueObject);
}

export function buildQueryStringArray(array: string[]): string {
    return array.join(";");
}