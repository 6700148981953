import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";

export default class FormDataElementDescriptor {
    constructor(
        public formDataElementName: string,
        public formDataElementType: string,
        public multiLingualLabel: MultiLingualLabel,
        public isArray: boolean,
        public isLongString: boolean) {
    }
}