import DocumentBase from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentBase";
import TemplateId from "@Primitives/TemplateId.g";
import DocumentFile from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentFile";
import DocumentChange from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentChange";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import TokenLockInfo from "./TokenLockInfo";
import { EntityLockState } from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import LargeDataToken from "@Primitives/LargeDataToken.g";

export default class TemplateBasedDocument extends DocumentBase {
    @State.observable.ref public documentFile: DocumentFile;
    @State.observable.ref public tokenLockInfos: TokenLockInfo[];
    public templateId: TemplateId;
    public templateContentToken: LargeDataToken;
    public cultureCode: string;
    @State.observable.ref public dataChangedSinceLastRefresh: DocumentChange[];

    public get isMutable() {
        return this.isDocumentMutable && this.areTokensMutable;
    }

    private get areTokensMutable() {
        return !this.tokenLockInfos.some(it => it.acquiredLockInfo?.lockState === EntityLockState.LockingRequiredAndLockNotHeld);
    }

    private get isDocumentMutable() {
        return this.isNew === true || (this.lockInfo && this.lockInfo.lockState !== EntityLockState.LockingRequiredAndLockNotHeld);
    }

}
