import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IUserGroupMultiSelectDialogParams, IUserGroupMultiSelectDialogResult } from "./UserGroupMultiSelectDialogParams";
import UserGroupId from "@Primitives/UserGroupId.g";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import UserGroupMultiSelector from "@HisPlatform/BoundedContexts/UserManagement/Components/Controls/UserGroupMultiSelector";

interface IUserGroupMultiSelectDialogProps extends IModalComponentParams<IUserGroupMultiSelectDialogResult>, IUserGroupMultiSelectDialogParams {

}

/** @screen */
@State.observer
export default class UserGroupMultiSelectDialog extends React.Component<IUserGroupMultiSelectDialogProps> {

    @State.observable.ref public userGroupIds: UserGroupId[] = [];
    
    @State.action.bound
    public setUserGroupIds(userGroupIds: UserGroupId[]) {
        this.userGroupIds = userGroupIds;
    }

    public componentDidMount() {
        this.setUserGroupIds(this.props.alreadySelectedIds);
    }

    public componentDidUpdate(prevProps: IUserGroupMultiSelectDialogProps) {
        if (prevProps.alreadySelectedIds !== this.props.alreadySelectedIds) {
            this.setUserGroupIds(this.props.alreadySelectedIds);
        }
    }

    @State.bound
    private onClose() {
        this.props.onClose({selectedUserGroupIds: this.userGroupIds});
    }

    public render() {
        return (
            <HisUi.ContextAwareModal
                closeOnOutsideClick
                title={this.props.title}
                automationId="__selectUserGroupsModal"
                onClose={this.onClose}
                isOpen
            >
                <Ui.Modal.Body>
                    <UserGroupMultiSelector
                        selectedIds={this.userGroupIds}
                        onChange={this.setUserGroupIds}
                        addButtonText={StaticAuthorizationResources.Common.Button.Select}
                        removeButtonText={StaticAuthorizationResources.Common.Button.Remove}
                    />
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Modal.Button text={StaticAuthorizationResources.Common.Button.Close} closeModalOnClick float="right" automationId="__closeButton" />
                </Ui.Modal.Footer>
            </HisUi.ContextAwareModal>
        );
    }
}