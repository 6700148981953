import State from "@Toolkit/ReactClient/Common/StateManaging";
import TherapyAssignmentBase from "./TherapyAssignmentBase";

export default class CustomTherapyAssignment extends TherapyAssignmentBase  {
    @State.observable.ref public name: string;

    constructor(name: string) {
        super();
        this.name = name;
    }

    @State.action.bound
    public setName(newValue: string) {
        return this.name = newValue;
    }
}