import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import UserId from "@Primitives/UserId.g";
import {dispatchAsyncErrors} from "@Toolkit/CommonWeb/AsyncHelpers";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { ICopyUserGroupModalParams } from "./CopyUserGroupModalParams";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import CopyUserGroupModalView from "./CopyUserGroupModalView";

interface ICopyUserGroupModalDependencies {
    userManagementDataProviderStore: UserManagementDataProviderStore;
}

interface ICopyUserGroupModalProps extends IModalComponentParams<void>, ICopyUserGroupModalParams {
    _dependencies?: ICopyUserGroupModalDependencies;
}

/** @screen */
@State.observer
class CopyUserGroupModal extends React.Component<ICopyUserGroupModalProps> {
    @State.observable.ref private selectedUser: User = null;

    private get usersStore() {
        return this.props._dependencies.userManagementDataProviderStore.users;
    }

    private get userGroupsStore() {
        return this.props._dependencies.userManagementDataProviderStore.userGroups;
    }

    private async loadAsync(id: UserId) {
        await this.usersStore.ensureLoadedAsync([id]);
        const user = this.usersStore.get(id);
        await this.userGroupsStore.ensureLoadedAsync(user.userGroupIds);
        this.setUser(user);
    }

    @State.action.bound
    private setUser(user: User) {
        this.selectedUser = user;
    }

    @State.action.bound
    private setUserId(id: UserId) {
        dispatchAsyncErrors(this.loadAsync(id), this);
    }

    @State.action.bound
    private closeModal() {
        this.selectedUser = null;
        this.props.onClose(null);
    }

    @State.bound
    private save() {
        this.props.copyUserGroups(this.selectedUser.userGroupIds);
        this.closeModal();
    }

    public render() {
        return (
            <CopyUserGroupModalView
            {...this.props}
             closeModal={this.closeModal}
             selectedUser={this.selectedUser}
             user={this.props.user}
             userGroupsStore={this.userGroupsStore}
             save={this.save}
             setUserId={this.setUserId}/>
        );
    }

}

export default connect(
    CopyUserGroupModal,
    new DependencyAdapter<ICopyUserGroupModalProps, ICopyUserGroupModalDependencies>((container) => {
        return {
            userManagementDataProviderStore: container.resolve("UserManagementDataProviderStore")
        };
    })
);
