import "./Finance/ApiAdapterConfiguration";
import "./Finance/StoreConfiguration";
import "./InsurerOrganizations/InsurerOrganizationsConfiguration";
import "./Invoicing/ApiAdapterConfiguration";
import "./Invoicing/StoreConfiguration";
import "./ApiClientConfiguration";
import DependencyContainer from "@DiContainer";
import FinanceBoundedContext from "@HisPlatform/BoundedContexts/Finance/FinanceBoundedContext";
import IBoundedContext from "@HisPlatform/Common/IBoundedContext";

DependencyContainer
    .bind("IBoundedContext")
    .to(FinanceBoundedContext);