import Di from "@Di";
import { DiagnosisFrequencyDto, IReportingClient } from "@HisPlatform/BoundedContexts/Reporting/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { IConditionData } from "./IConditionData";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ConditionId from "@Primitives/ConditionId.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";

@Di.injectable()
export default class ReportingWidgetApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IReportingClient") private readonly apiClient: IReportingClient,
        @Di.inject("CareReferenceDataStore") private readonly careReferenceDataStore: CareReferenceDataStore) {
        super();
    }

    @State.bound
    public getDiagnosesFrequencyByPatientIdAsync(patientId: string): Promise<SimpleStore<IConditionData[]>> {
        return this.processOperationAsync(
            new SimpleStore<IConditionData[]>(),
            async target => {
                const response = await this.apiClient.getDiagnosesFrequencyByPatientIdQueryAsync(CreateRequestId(), patientId);

                target.operationInfo = createOperationInfo(response);
                target.value = this.mapDtoToConditionData(response.patientDiagnosesFrequencyDto.dtos);
            }
        );
    }

    private mapDtoToConditionData(dtos: DiagnosisFrequencyDto[]): IConditionData[] {
        return dtos.map(d => {
            const version = this.careReferenceDataStore.condition.get(
                { id: d.diagnosisId, validOn: LocalDate.today() } as IEntityVersionSelector<ConditionId>);
            return {
                ICD: version && version.code,
                lastDate: LocalDate.createFromMoment(d.lastOccurrenceAt).stringify(),
            } as IConditionData;
        });
    }
}