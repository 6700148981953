import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import _ from "@HisPlatform/Common/Lodash";
import * as Ui from "@CommonControls";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import EhiEReferralServiceTypeId from "@Primitives/EhiEReferralServiceTypeId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiEReferralPatientIdentifierTypeId from "@Primitives/EhiEReferralPatientIdentifierTypeId.g";
import EhiEReferralProfessionCodeId from "@Primitives/EhiEReferralProfessionCodeId.g";
import EhiEReferralReasonId from "@Primitives/EhiEReferralReasonId.g";
import EhiEReferralTypeId from "@Primitives/EhiEReferralTypeId.g";
import EhiEReferralOrganizationTypeId from "@Primitives/EhiEReferralOrganizationTypeId.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import EhiEReferralTravelExpenseTypeId from "@Primitives/EhiEReferralTravelExpenseTypeId.g";
import EhiEReferralStatusId from "@Primitives/EhiEReferralStatusId.g";
import CareActivityEReferralStore from "@HunEHealthInfrastructurePlugin/Model/CareActivityEReferralStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { Moment } from "moment";
import NameStore from "@Primitives/NameStore";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";

interface IEhiEReferralDetailPanelDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    commonReferenceDataStore: CommonReferenceDataDataStore;
    localizationService: ILocalizationService;
}

interface IEhiEReferralDetailPanelProps {
    _dependencies?: IEhiEReferralDetailPanelDependencies;
    careActivityEReferralStore: CareActivityEReferralStore;
    wrapToColumns?: boolean;
}

/** @screen */
@State.observer
class EhiEReferralDetailPanel extends React.Component<IEhiEReferralDetailPanelProps> {

    private get separator() { return " - "; }

    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get hunEhiCareReferenceDataStore() { return this.props._dependencies.hunEhiCareReferenceDataStore; }
    private get commonReferenceDataStore() { return this.props._dependencies.commonReferenceDataStore; }
    private get localizationService() { return this.props._dependencies.localizationService; }

    private get store() { return this.props.careActivityEReferralStore; }

    @State.computed
    private get validAt() { return this.store.createdAt; }

    private get flexItemSize() { return (this.props.wrapToColumns ? 4 : 12); }
    private get flexStyle() { return { padding: "0px 0px 0px 20px", margin: this.props.wrapToColumns ? undefined : 0 }; }

    public componentDidMount(): void {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }

    private async initializeAsync() {
        await Promise.all([
            this.hunEhiCareReferenceDataStore.ehiEReferralProfessionCodes.ensureAllLoadedAsync(),
            this.hunEhiCareReferenceDataStore.ehiEReferralPatientIdentifierTypes.ensureAllLoadedAsync(),
            this.hunEhiCareReferenceDataStore.ehiEReferralServiceTypes.ensureAllLoadedAsync(),
            this.hunEhiCareReferenceDataStore.ehiEReferralReasons.ensureLoadedAsync(),
            this.hunEhiCareReferenceDataStore.ehiEReferralOrganizationTypes.ensureLoadedAsync(),
            this.hunEhiCareReferenceDataStore.ehiEReferralScheduleStatuses.ensureLoadedAsync(),
            this.hunEhiCareReferenceDataStore.ehiEReferralStatuses.ensureLoadedAsync(),
            this.hunEhiCareReferenceDataStore.ehiEReferralTravelExpenseTypes.ensureLoadedAsync(),
            this.hunEhiCareReferenceDataStore.ehiEReferralTypes.ensureLoadedAsync(),
            this.careReferenceDataStore.medicalService.ensureAllLoadedAsync(),
            !isNullOrUndefined(this.store.countryId) && this.commonReferenceDataStore.countryMap.debouncedEnsureLoaded([this.store.countryId]),
        ]);
    }

    @State.computed private get country() {
        if (isNullOrUndefined(this.store?.countryId)) {
            return this.store?.country;
        }

        const possibleCountry = this.commonReferenceDataStore.countryMap.get(this.store.countryId);
        return isNullOrUndefined(possibleCountry) ? this.store.country : possibleCountry.countryName;
    }

    private resolveServiceType(id: EhiEReferralServiceTypeId): string {
        if (isNullOrUndefined(id) || id.value === "") {
            return "";
        }

        const version = this.hunEhiCareReferenceDataStore.ehiEReferralServiceTypes.get(new EntityVersionSelector<EhiEReferralServiceTypeId>(id, this.validAt));
        return isNullOrUndefined(version) ? "" : this.separator + version.name;
    }

    private resolvePatientIdentifierType(id: EhiEReferralPatientIdentifierTypeId): string {
        if (isNullOrUndefined(id) || id.value === "") {
            return "";
        }

        const version = this.hunEhiCareReferenceDataStore.ehiEReferralPatientIdentifierTypes.get(new EntityVersionSelector<EhiEReferralPatientIdentifierTypeId>(id, this.validAt));
        return isNullOrUndefined(version) ? "" : this.separator + version.name;
    }

    private resolveProfessionCode(id: EhiEReferralProfessionCodeId): string {
        if (isNullOrUndefined(id) || id.value === "") {
            return "";
        }

        const version = this.hunEhiCareReferenceDataStore.ehiEReferralProfessionCodes.get(new EntityVersionSelector<EhiEReferralProfessionCodeId>(id, this.validAt));
        return isNullOrUndefined(version) ? "" : this.separator + version.name;
    }

    private resolveReason(id: EhiEReferralReasonId) {
        if (isNullOrUndefined(id) || id.value === "") {
            return "";
        }
        const item = this.hunEhiCareReferenceDataStore.ehiEReferralReasons.get(id);

        return isNullOrUndefined(item) ? "" : this.separator + item.displayValue.Name;
    }

    private resolveService(id: MedicalServiceId): string {
        if (isNullOrUndefined(id) || id.value === "") {
            return "";
        }

        const version = this.careReferenceDataStore.medicalService.get(new EntityVersionSelector<MedicalServiceId>(id, this.validAt));
        return isNullOrUndefined(version) ? "" : this.separator + version.name;
    }

    private resolveType(id: EhiEReferralTypeId) {
        if (isNullOrUndefined(id) || id.value === "") {
            return "";
        }
        const item = this.hunEhiCareReferenceDataStore.ehiEReferralTypes.get(id);

        return isNullOrUndefined(item) ? "" : this.separator + item.displayValue.Name;
    }

    private resolveOrganizationType(id: EhiEReferralOrganizationTypeId) {
        if (isNullOrUndefined(id) || id.value === "") {
            return "";
        }
        const item = this.hunEhiCareReferenceDataStore.ehiEReferralOrganizationTypes.get(id);

        return isNullOrUndefined(item) ? "" : this.separator + item.displayValue.Name;
    }

    private resolveTravelExpenseType(id: EhiEReferralTravelExpenseTypeId) {
        if (isNullOrUndefined(id) || id.value === "") {
            return "";
        }
        const item = this.hunEhiCareReferenceDataStore.ehiEReferralTravelExpenseTypes.get(id);

        return isNullOrUndefined(item) ? "" : this.separator + item.displayValue.Name;
    }

    private resolveStatus(id: EhiEReferralStatusId) {
        if (isNullOrUndefined(id) || id.value === "") {
            return "";
        }
        const item = this.hunEhiCareReferenceDataStore.ehiEReferralStatuses.get(id);

        return isNullOrUndefined(item) ? "" : this.separator + item.displayValue.Name;
    }

    private resolveBoolean(value: boolean) {
        return value ? StaticWebAppResources.Common.DialogButton.Yes : StaticWebAppResources.Common.DialogButton.No;
    }

    private resolveDate(value: LocalDate) {
        if (isNullOrUndefined(value)) {
            return "";
        }
        return this.localizationService.localizeDate(value);
    }

    private resolveMoment(value: Moment) {
        if (isNullOrUndefined(value)) {
            return "";
        }
        return this.localizationService.localizeDateTime(value, false);
    }

    private resolveName(name: NameStore) {
        return <Ui.PersonNameLabel personName={name} />;
    }

    public render() {
        const detailDialog = StaticHunEHealthInfrastructureCareResources.EReferralDetailPanel;
        const patientDetail = detailDialog.PatientDetail;
        const instituteDetail = detailDialog.InstituteDetail;
        const referralDetail = detailDialog.ReferralDetail;
        const ehiDetail = detailDialog.EhiDetail;

        return (
            <>
                {this.store &&
                    <>
                        <Ui.GroupBox title={patientDetail.Title}>
                            <Ui.Flex style={this.flexStyle}>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {patientDetail.Name}: {this.resolveName(this.store.name)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {patientDetail.BirthDate}: {this.resolveDate(this.store.birthDate)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {patientDetail.MothersName}: {this.resolveName(this.store.mothersName)}
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.Flex style={this.flexStyle}>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {patientDetail.PatientIdentifierType}: <b>{this.store.patientIdentifierTypeCode}</b>{this.resolvePatientIdentifierType(this.store.patientIdentifierTypeId)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {patientDetail.PatientIdentifierValue}: {this.store.patientIdentifierValue}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {patientDetail.Address}: ({this.country}) {this.store.city}, {this.store.street}
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </Ui.GroupBox>
                        <Ui.GroupBox title={instituteDetail.ReferralTitle}>
                            <Ui.Flex style={this.flexStyle}>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {instituteDetail.Institute}: <b>{this.store.referralInstituteCode}</b> - {this.store.referralInstituteName}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {instituteDetail.Workplace}: <b>{this.store.referralLocationCode}</b> - {this.store.referralLocationName}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {instituteDetail.ReferralDoctor}: <b>{this.store.referralDoctorCode}</b> {this.resolveName(this.store.referralDoctorName)}
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </Ui.GroupBox>
                        <Ui.GroupBox title={instituteDetail.CareTitle}>
                            <Ui.Flex style={this.flexStyle}>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {instituteDetail.Institute}: <b>{this.store.careInstituteCode}</b> - {this.store.careInstituteName}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {instituteDetail.Workplace}: <b>{this.store.careLocationCode}</b> - {this.store.careLocationName}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize} />
                            </Ui.Flex>
                        </Ui.GroupBox>
                        <Ui.GroupBox title={referralDetail.Title}>
                            <Ui.Flex style={this.flexStyle}>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.CreatedAt}: {this.resolveDate(this.store.createdAt)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.ValidAt}: {this.resolveDate(this.store.validAt)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.ReferralNote}: {this.store.referralNote}
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.Flex style={this.flexStyle}>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.ReferralServiceType}: <b>{this.store.referralServiceTypeCode}</b>{this.resolveServiceType(this.store.referralServiceTypeId)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.ReferralServiceReason}: <b>{this.store.referralReasonCode}</b>{this.resolveReason(this.store.referralReasonId)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.ReferralDiagnosis}: <b>{this.store.referralDiagnosisCode}</b> - {this.store.referralDiagnosisDescription}
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.Flex style={this.flexStyle}>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.ReferralType}: <b>{this.store.referralTypeCode}</b>{this.resolveType(this.store.referralTypeId)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.ReferralInstituteType}: <b>{this.store.referralInstituteTypeCode}</b>{this.resolveOrganizationType(this.store.referralInstituteTypeId)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.IsInAreaCare}: {this.resolveBoolean(this.store.isInAreaCare)}
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.Flex style={this.flexStyle}>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.IsUrgentReferral}: {this.resolveBoolean(this.store.isUrgentReferral)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.IsPostponedInstituteChoice}: {this.resolveBoolean(this.store.isPostponedInstituteChoice)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.IsPaperBased}: {this.resolveBoolean(this.store.isPaperBased)}
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.Flex style={this.flexStyle}>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.IsOutOfOrder}: {this.resolveBoolean(this.store.isOutOfOrder)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.Identifier}: {this.store.referralCareIdentifier}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.SickAllowanceIdentifier}: {this.store.sickAllowanceIdentifier}
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.Flex style={this.flexStyle}>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.Anamnesis}: {this.store.anamnesis}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.Concilium}: {this.store.concilium}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.ProfessionCode}: <b>{this.store.professionCode}</b>{this.resolveProfessionCode(this.store.professionCodeId)}
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.Flex style={this.flexStyle}>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.ReferralService}: <b>{this.store.referralServiceCode}</b>{this.resolveService(this.store.referralServiceId)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.SicknessInDaysSinceStart}: {this.store.sicknessInDaysSinceStart}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize} />
                            </Ui.Flex>
                            <Ui.Flex style={this.flexStyle}>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.TravelExpenseType}: <b>{this.store.travelExpenseTypeCode}</b>{this.resolveTravelExpenseType(this.store.travelExpenseTypeId)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.TravelVoucherIdentifier}: {this.store.travelVoucherIdentifier}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize} />
                            </Ui.Flex>
                            <Ui.Flex style={this.flexStyle}>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.DoctorNotification}: <b>{this.store.doctorNotification}</b>{this.resolveBoolean(this.store.doctorNotification)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {referralDetail.PatientNotification}: <b>{this.store.patientNotification}</b>{this.resolveBoolean(this.store.patientNotification)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize} />
                            </Ui.Flex>
                        </Ui.GroupBox>
                        <Ui.GroupBox title={ehiDetail.Title}>
                            <Ui.Flex style={this.flexStyle}>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {ehiDetail.EhiIdentifier}: {this.store.ehiIdentifier}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {ehiDetail.ReportedAt}: {this.resolveMoment(this.store.reportedAt)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize} />
                            </Ui.Flex>
                            <Ui.Flex style={this.flexStyle}>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {ehiDetail.ReferralStatus}: <b>{this.store.referralStatusCode}</b>{this.resolveStatus(this.store.referralStatusId)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize}>
                                    {ehiDetail.LastChangeAt}: {this.resolveMoment(this.store.lastChangeAt)}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item sm={this.flexItemSize} />
                            </Ui.Flex>
                        </Ui.GroupBox>
                    </>
                }
            </>
        );
    }
}

export default connect(
    EhiEReferralDetailPanel,
    new DependencyAdapter<IEhiEReferralDetailPanelProps, IEhiEReferralDetailPanelDependencies>((c) => {
        return {
            careReferenceDataStore: c.resolve<CareReferenceDataStore>("CareReferenceDataStore"),
            hunEhiCareReferenceDataStore: c.resolve<HunEhiCareReferenceDataStore>("HunEhiCareReferenceDataStore"),
            commonReferenceDataStore: c.resolve<CommonReferenceDataDataStore>("CommonReferenceDataDataStore"),
            localizationService: c.resolve("ILocalizationService")
        };
    })
);
