import SettlementAndZipCodeSelector from "@HisPlatformControls/SettlementAndZipCodeSelector";
import CountryId from "@Primitives/CountryId.g";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import { useFormEntityIdField, useFormTextField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { getCompositeArrayFields, getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import { useFormLayoutContext } from "@Toolkit/FormEngine/Panels/FormLayoutContext";
import { ICustomBlockRegistryItem, IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React from "react";
import { useEffect, useState } from "react";

interface IZipCodeAndSettlementFormCustomBlockProps extends IFormCustomBlockComponentProps {
}

function _ZipCodeAndSettlementFormCustomBlock(props: IZipCodeAndSettlementFormCustomBlockProps) {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext.compositeDataReferences?.join(".");

    const fields = getCompositeArrayFields(props.form.data.Content, compositeFieldName);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        addFieldsForZipCodeAndSettlement(props.form.data.Content, fields, compositeFieldName);
        setIsLoaded(true);
    }, []);

    return isLoaded && (
        <ZipCodeAndSettlementFormCustomBlockCore {...props} />
    );
}

const ZipCodeAndSettlementFormCustomBlockCore = State.observer((props: IZipCodeAndSettlementFormCustomBlockProps) => {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext.compositeDataReferences?.join(".");

    const [zipCode, setZipCode] = useFormTextField(props.form.data.Content, isNullOrEmptyString(compositeFieldName) ? "ZipCode" : compositeFieldName + ".ZipCode");
    const [settlement, setSettlement] = useFormTextField(props.form.data.Content, isNullOrEmptyString(compositeFieldName) ? "Settlement" : compositeFieldName + ".Settlement");
    const [countryId, setCountryId] = useFormEntityIdField<CountryId>(props.form.data.Content, isNullOrEmptyString(compositeFieldName) ? "CountryId" : compositeFieldName + ".CountryId", CountryId);

    return (
        <SettlementAndZipCodeSelector
            key={props.form.id.value}
            countryId={countryId}
            settlement={settlement}
            setSettlement={setSettlement}
            zipCode={zipCode}
            setZipCode={setZipCode}
            automationIdPrefix="__settlementAndZipCodeSelector"
        />
    );
});

export function addFieldsForZipCodeAndSettlement(formFields: FormFieldDataBase[], array: IObservableArray<FormFieldDataBase>, compositeFieldName: string) {
    State.runInAction(() => {
        const zipCodeField = getField<TextFormFieldData>(formFields, isNullOrEmptyString(compositeFieldName) ? "ZipCode" : compositeFieldName + ".ZipCode");
        if (!zipCodeField) {
            const newZipCodeField = new TextFormFieldData("ZipCode", false, true, null);

            if (array) {
                array.push(newZipCodeField);
            } else {
                formFields.push(newZipCodeField);
            }
        }

        const settlementField = getField<TextFormFieldData>(formFields, isNullOrEmptyString(compositeFieldName) ? "Settlement" : compositeFieldName + ".Settlement");
        if (!settlementField) {
            const newSettlementField = new TextFormFieldData("Settlement", false, true, null);

            if (array) {
                array.push(newSettlementField);
            } else {
                formFields.push(newSettlementField);
            }
        }
    });
}

const ZipCodeAndSettlementFormCustomBlock = connect(_ZipCodeAndSettlementFormCustomBlock);

export const ZipCodeAndSettlementRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Zip code and settlement",
    dataElementsFactory: () => [
    ],
    componentType: ZipCodeAndSettlementFormCustomBlock
};

export default ZipCodeAndSettlementFormCustomBlock;
