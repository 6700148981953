import State from "@Toolkit/ReactClient/Common/StateManaging";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import MedworksDocumentsMasterDetailPanel from "./MedworksDocumentsMasterDetailPanel";
import PatientId from "@Primitives/PatientId.g";
import DocumentId from "@Primitives/DocumentId.g";
import PatientDocumentsDataSource from "./PatientDocumentsDataSource";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import PatientDocumentsDataSourceSelector from "./PatientDocumentDataSourceSelector";
import EhiDocumentsMasterDetailPanel from "./EhiDocumentsMasterDetailPanel";
import PatientDocumentsFilterStore from "./PatientDocumentsFilterStore";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";


interface IPatientDocumentsMasterDetailPanelDependencies {
}

interface IPatientDocumentsMasterDetailPanelProps {
    _dependencies?: IPatientDocumentsMasterDetailPanelDependencies;
    patientId: PatientId;
    selectedDocumentId: string;
    onSelectedDocumentIdChange: (newId: string) => void;
}

@State.observer
class PatientDocumentsMasterDetailPanel extends React.Component<IPatientDocumentsMasterDetailPanelProps> {

    @State.observable.ref private dataSourceType: PatientDocumentsDataSource = PatientDocumentsDataSource.His;
    @State.observable.ref private filterStore: PatientDocumentsFilterStore = new PatientDocumentsFilterStore();

    private get resources() {
        return StaticHunEHealthInfrastructureCareResources.PatientDocumentMasterDetailPanel;
    }

    @State.action.bound
    private setDataSourceType(newType: PatientDocumentsDataSource) {
        this.dataSourceType = newType;
    }

    @State.bound
    private renderCommonFilter() {
        return (
            <PatientDocumentsDataSourceSelector
                value={this.dataSourceType}
                onChange={this.setDataSourceType}
                label={this.resources.DocumentSelector.Label} />
        );
    }

    @State.computed
    private get selectedHisDocumentId() {
        if (isNullOrUndefined(this.props.selectedDocumentId)) {
            return null;
        }

        return this.props.selectedDocumentId?.charAt(0) === "H" ? new DocumentId(this.props.selectedDocumentId.slice(1)) : null;
    }

    @State.computed
    private get selectedEhiDocumentId() {
        if (isNullOrUndefined(this.props.selectedDocumentId)) {
            return null;
        }

        return this.props.selectedDocumentId?.charAt(0) === "E" ? this.props.selectedDocumentId.slice(1) : null;
    }

    @State.bound
    private onSelectedHisDocumentIdChange(newId: DocumentId) {
        const createdId = newId ? `H${newId.value}` : null;
        this.props.onSelectedDocumentIdChange(createdId);
    }

    @State.bound
    private onSelectedEhiDocumentIdChange(newId: string) {
        const createdId = newId ? "E" + newId : null;
        this.props.onSelectedDocumentIdChange(createdId);
    }

    public render() {
        return (
            <>
                <SingleLayout>
                    {this.dataSourceType === PatientDocumentsDataSource.His && (
                        <MedworksDocumentsMasterDetailPanel
                            patientId={this.props.patientId}
                            selectedDocumentId={this.selectedHisDocumentId}
                            filterStore={this.filterStore}
                            commonFilter={this.renderCommonFilter()}
                            onSelectedDocumentIdChange={this.onSelectedHisDocumentIdChange} />
                    )}
                    {this.dataSourceType === PatientDocumentsDataSource.Ehi && (
                        <EhiDocumentsMasterDetailPanel
                            patientId={this.props.patientId}
                            filterStore={this.filterStore}
                            commonFilter={this.renderCommonFilter()} />
                    )}
                </SingleLayout>
            </>
        );
    }

}

export default connect(
    PatientDocumentsMasterDetailPanel,
    new DependencyAdapter<IPatientDocumentsMasterDetailPanelProps, IPatientDocumentsMasterDetailPanelDependencies>(c => ({
    }))
);