import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import SettlementApiAdapter from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/SettlementApiAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as HisUi from "@HisPlatformControls";
import MedicationFormId from "@Primitives/MedicationFormId.g";
import CareExtensibleEnumApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/CareExtensibleEnumApiAdapter";

interface IMedicationFormTextboxDependencies {
    apiAdapter: CareExtensibleEnumApiAdapter;
}

interface IMedicationFormTextboxProps extends ICodeSelectorCommonProps<MedicationFormId> {
    _dependencies?: IMedicationFormTextboxDependencies;
}

@State.observer
class MedicationFormTextBox extends React.Component<IMedicationFormTextboxProps> {

    public render() {
        return (
            <HisUi.UniversalCodeSelector
                {...this.props}
                onQuickSearchAsync={this.searchAsync}
                getDisplayValueAsync={this.getDisplayValueAsync}
                onChange={this.props.onChange}
                hasComplexSearch={false}
            />
        );
    }

    public getDisplayValueAsync(item: MedicationFormId) {
        return Promise.resolve(item.value);
    }

    @State.bound
    public async searchAsync(text: string) {
        const medicationFormIds = await this.props._dependencies.apiAdapter.searchMedicationFormIdsAsync(text);

        if (medicationFormIds?.value?.findIndex(i => i.value === text) === -1) {
            medicationFormIds.value.push(new MedicationFormId(text));
        }

        return medicationFormIds.value;
    }
}

export default connect(
    MedicationFormTextBox,
    new DependencyAdapter<IMedicationFormTextboxProps, IMedicationFormTextboxDependencies>(container => {
        return {
            apiAdapter: container.resolve("CareExtensibleEnumApiAdapter")
        };
    })
);
