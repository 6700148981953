import MedicalServiceId from "@Primitives/MedicalServiceId.g";

export class MedicalServiceExtensibleCodeSelectorItem {
    public id: MedicalServiceId;
    public name?: string;
    public code?: string;
    public text?: string;

    constructor({ id = null, name = "", code = "",  text = "" }: { id?: MedicalServiceId,  name?: string, code?: string, text?: string }) {
        this.id = id;
        this.name = name;
        this.text = text;
        this.code = code;
    }
}