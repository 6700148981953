import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";


export interface IEhiOrganizationUnitSelectorDialogParams extends IModalParams {
}

export interface IEhiOrganizationUnitSelectorDialogResult {
    organizationUnitId?: OrganizationUnitId;
}

export default class EhiOrganizationUnitSelectorDialogParams implements IEhiOrganizationUnitSelectorDialogParams {
    public static type = "EhiOrganizationUnitSelectorDialog";
    public get type() { return EhiOrganizationUnitSelectorDialogParams.type; }
}
