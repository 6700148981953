import Address from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/Address";
import OrganizationUnitNaturalIdentifier from "./OrganizationUnitNaturalIdentifier";
import Contact from "./Contact";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import PractitionerId from "@Primitives/PractitionerId.g";

export default class OrganizationUnitBaseData {
    public address: Address = new Address();
    public naturalIdentifier: OrganizationUnitNaturalIdentifier = new OrganizationUnitNaturalIdentifier();
    public contacts: IObservableArray<Contact> = State.observable([]);
    
    @State.observable.ref public managerId: PractitionerId = null;
    
    @State.action.bound
    public setManagerId(managerId: PractitionerId) {
        this.managerId = managerId;
    }
}
