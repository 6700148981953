import DependencyContainer from "@DiContainer";
import CommonReferenceDataExtensibleEnumApiAdapter from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/CommonReferenceDataExtensibleEnumApiAdapter";
import CountryApiAdapter from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/CountryApiAdapter";
import SettlementApiAdapter from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/SettlementApiAdapter";
import IdentifierSystemApiAdapter from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/IdentifierSystemApiAdapter";

DependencyContainer
    .bind("CommonReferenceDataExtensibleEnumApiAdapter")
    .to(CommonReferenceDataExtensibleEnumApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("CountryApiAdapter")
    .to(CountryApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("SettlementApiAdapter")
    .to(SettlementApiAdapter)
    .inSingletonScope();
    
DependencyContainer
    .bind("IdentifierSystemApiAdapter")
    .to(IdentifierSystemApiAdapter)
    .inSingletonScope();