import Di from "@Di";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import IMapperService from "@HisPlatform/Services/Definition/MapperService/IMapperService";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientId from "@Primitives/PatientId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import IFrontendListDataWithDefinition from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/IFrontendListDataWithDefinition";
import {CreateRequestId} from "@HisPlatform/Common/RequestHelper";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";

@Di.injectable()
export default class PatientMovedCareActivitiesScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("ICareClient") private readonly apiClient: Proxy.ICareClient,
        @Di.inject("IMapperService") private readonly mapperService: IMapperService,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader) {
        super(referenceDataLoader);
    }

    @State.bound
    public getPatientMovedCareActivitiesListAsync(patientId: PatientId, careActivityIds: CareActivityId[], frontendListParameters: FrontendListParameters) {
        return this.executeOperationAsync<IFrontendListDataWithDefinition, Proxy.GetCareWorklistQueryResponse>(
            () => this.apiClient.getCareWorklistQueryAsync(CreateRequestId(), new Proxy.GetCareWorklistControllerDto({
                patientId: patientId,
                careActivityIds: careActivityIds,
                worklistCode: "MOVEDCAREACTIVITIES",
                filters: this.mapperService.mapByName("FilterDtoArray", frontendListParameters),
                pagingAndOrderings: this.mapperService.mapByName("QueryPagingsAndOrderings", frontendListParameters),
                requestMode: frontendListParameters.requestMode,
            })),
            response => {
                return this.mapperService.map<Proxy.FrontendListDto, IFrontendListDataWithDefinition>(response.frontendList);
            }
        );
    }

    @State.bound
    public getPatientMovedCareActivitiesScreenDataAsync(keptPatientId: PatientId, deletedPatientId: PatientId) {
        return this.executeOperationAsync<CareActivityId[], Proxy.GetPatientMovedCareActivitiesScreenDataQueryResponse>(
            () => this.apiClient.getPatientMovedCareActivitiesScreenDataQueryAsync(CreateRequestId(), new Proxy.GetPatientMovedCareActivitiesScreenDataControllerDto({
                keptPatientId: keptPatientId,
                deletedPatientId: deletedPatientId
            })),
            response => {
                return response.careActivityIds;
            }
        );
    }
}