import Di from "@Di";
import IAgeCalculationService from "@HisPlatform/Services/Definition/AgeCalculation/IAgeCalculationService";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IPatientAgeProps } from "./PatientAge";

@Di.injectable()
export default class PatientAgeStore extends PanelStoreBase<IPatientAgeProps> {

    constructor(
        @Di.inject("IAgeCalculationService") private ageCalculationService: IAgeCalculationService) {
        super();
    }

    public readonly age = this.promisedComputed<string>(null, async () => {
        if (!this.props.birthDate) {
            return null;
        }
        return await this.ageCalculationService.calculateAgeAsync(this.props.birthDate, this.props.deathDate);
    });
}