import { IValidationProblemProcessor } from "@Toolkit/CommonWeb/Validation/ValidationProblemProcessorService";
import MedicalServiceFinancingStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/MedicalServiceFinancingStore";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import _ from "@HisPlatform/Common/Lodash";
import FinancedServiceWithQuantityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/FinancedServiceWithQuantityStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { FinancedServiceRuleValidationProblemProcessorBase } from "./FinancedServiceRuleValidationProblemProcessorBase";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import FinancedServiceId from "@Primitives/FinancedServiceId.g";

export class ThreeRuleValidationProblemProcessor extends FinancedServiceRuleValidationProblemProcessorBase implements IValidationProblemProcessor<MedicalServiceFinancingStore>  {
    public getRawRelatedCareActivities(problem: IClientValidationProblem) {
        return _.get(problem?.parameters || {}, "PerformedServiceThreeRuleViolationParametersDto.RelatedCareActivities") || [];
    }

    public ruleId = "ShouldBeCompliantWithFinancedServiceThreeRules";

    constructor(
        financeReferenceDataStore: FinanceReferenceDataStore,
        localizationService: ILocalizationService,
        organizationReferenceDataStore: OrganizationReferenceDataStore
    ) {
        super(localizationService, organizationReferenceDataStore, financeReferenceDataStore);
    }

    public getServiceId = (problem: IClientValidationProblem) => null as any;

    private getServiceIds(problem: IClientValidationProblem) {
        const ids = _.get(problem.parameters, "PerformedServiceThreeRuleViolationParametersDto.ServiceIds") as any[];
        return ids?.map(i => new FinancedServiceId(i?.Value?.toString())) || [];
    }

    public mapValidationProblem(problem: IClientValidationProblem, items: MedicalServiceFinancingStore[]) {
        const serviceIds = this.getServiceIds(problem);
        if (serviceIds.length > 0) {
            return items.filter(i => i.financedServicesWithQuantities.some((j: FinancedServiceWithQuantityStore) => {
                return serviceIds.some(k => ValueWrapper.equals(k, j.financedServiceId.id));
            }));
        }
        return [];
    }

    public renderInfo = (problem: IClientValidationProblem) => {
        const relatedCareActivities = this.getRelatedCareActivities(problem);
        if (relatedCareActivities?.length > 0) {
            return this.renderRelatedCareActivitiesInfo(problem);
        } else {
            return undefined;
        }
    };
}