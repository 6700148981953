import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FamilyDoctorDocumentId from "@Primitives/FamilyDoctorDocumentId.g";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import FamilyDoctorDocumentApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/FamilyDoctorDocument/FamilyDoctorDocumentApiAdapter";
import PatientId from "@Primitives/PatientId.g";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import FamilyDoctorDocumentStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import FamilyDoctorDocumentHeaderPanel from "./FamilyDoctorDocumentHeaderPanel";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import FamilyDoctorDocumentDetailView from "./FamilyDoctorDocumentDetailView";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import FamilyDoctorDocumentMedicationReference from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentMedicationReference";
import CurativeItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/CurativeItem";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import DocumentPreviewModalParams from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Modals/DocumentPreviewModal/DocumentPreviewModalParams";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import CareActivityBaseData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareActivityBaseData";

interface IFamilyDoctorDocumentDetailPanelDependecies {
    dialogService: IDialogService;
    notificationService: INotificationService;
    familyDoctorDocumentApiAdapter: FamilyDoctorDocumentApiAdapter;
    lockingApiAdapter: LockingApiAdapter;
    referenceDataStore: MedicationRequestReferenceDataStore;

}

export interface IFamilyDoctorDocumentDetailPanelProps {
    familyDoctorDocumentId: FamilyDoctorDocumentId;
    _dependencies?: IFamilyDoctorDocumentDetailPanelDependecies;
    _patientId?: PatientId;
    _pointOfCareId?: PointOfCareId;
    _modalService?: IModalService;
    _careActivityId?: CareActivityId;
    _careActivityBaseData?: CareActivityBaseData;
    onSaveAsync?: () => Promise<void>;
    onCloseDetail?: () => void;

    onFamilyDoctorDocumentCreatedAsync?: (familyDoctorDocumentId: FamilyDoctorDocumentId) => Promise<void>;
}

@State.observer
class FamilyDoctorDocumentDetailPanel extends React.Component<IFamilyDoctorDocumentDetailPanelProps> {

    @State.observable.ref private store: FamilyDoctorDocumentStore = null;

    private get familyDoctorDocumentId() { return this.props.familyDoctorDocumentId; }
    private get patientId() { return this.props._patientId; }
    private get pointOfCareId() { return this.props._pointOfCareId; }
    private get dialogService() { return this.props._dependencies!.dialogService; }
    private get careActivityId() { return this.props._careActivityId; }
    private get careActivityBaseData() { return this.props._careActivityBaseData; }

    @State.computed private get isNew() { return this.familyDoctorDocumentId.value === "new"; }

    private get notificationService() { return this.props._dependencies.notificationService; }
    private get familyDoctorDocumentApiAdapter() { return this.props._dependencies.familyDoctorDocumentApiAdapter; }
    private get lockingApiAdapter() { return this.props._dependencies.lockingApiAdapter; }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IFamilyDoctorDocumentDetailPanelProps) {
        if (prevProps.familyDoctorDocumentId.value !== this.props.familyDoctorDocumentId.value) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.action.bound
    private async loadAsync(forceReleaseLockNeeded: boolean = false) {
        await this.props._dependencies.referenceDataStore.medicationSubsidyClaimTypes.ensureAllLoadedAsync();

        if (forceReleaseLockNeeded && !!this.store?.lockInfo?.preventingLockId) {
            await this.lockingApiAdapter.forceReleaseLockAsync(this.store.lockInfo.preventingLockId);
        }

        let store = null;
        if (this.isNew) {
            store = new FamilyDoctorDocumentStore(true);
            store.id = this.props.familyDoctorDocumentId;
            store.pointOfCareId = this.pointOfCareId;
            store.patientId = this.patientId;
            store.createdBy = this.careActivityBaseData.primaryParticipant;
            store.careActivityId = this.careActivityId;
            this.setStore(store);
        } else {
            store = await this.familyDoctorDocumentApiAdapter.getFamilyDoctorDocumentByIdAsync(this.familyDoctorDocumentId, true);
            this.setStore(store);
        }

    }

    @State.action.bound
    private setStore(newValue: FamilyDoctorDocumentStore) {
        this.store = newValue;
        this.store.takeSnapshot();
    }

    @State.bound
    private async navigateAwayAsync() {
        if (this.store?.isMutable === true && this.isUnsavedOrDirty()) {
            const dialogResult = await this.dialogService.confirmIfNotSaved(StaticCareResources.Common.Dialog.ConfirmationTitle,
                StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);
            if (dialogResult.resultCode === DialogResultCode.Yes) {
                await this.saveStoreAsync();
                await this.releaseLockAsync();
                return false;
            } else if (dialogResult.resultCode === DialogResultCode.No) {
                await this.releaseLockAsync();
                return true;
            }
        }
        await this.releaseLockAsync();
        return true;
    }

    @State.action.bound
    private async releaseLockAsync() {
        if (this.store.isMutable && this.store.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld) {
            await this.lockingApiAdapter.releaseLockAsync(this.store.lockInfo.lockId);
            this.store.releaseLock();
            return true;
        }
        return false;
    }

    private isUnsavedOrDirty() {
        return this.store.isDirty() && this.store.isNew;
    }

    @State.action.bound
    private async validateAsync(): Promise<IClientValidationResult[]> {
        if (!this.store || !this.store.isMutable) {
            return [];
        }
        const validationResultResponse = await this.familyDoctorDocumentApiAdapter.validateAsync(this.store);
        return validationResultResponse.value;
    }

    @State.action.bound
    private async saveStoreAsync() {
        let newStore: FamilyDoctorDocumentStore = null;

        if (this.isNew) {
            newStore = await this.familyDoctorDocumentApiAdapter.createFamilyDoctorDocumentAsync(this.store, true);
        } else {
            newStore = await this.familyDoctorDocumentApiAdapter.updateFamilyDoctorDocumentAsync(this.store, false);
        }

        if (newStore.operationWasSuccessful && newStore.isPersistedByOperationInfo) {
            if (this.isNew) {
                this.store.takeSnapshot();
                await this.props.onFamilyDoctorDocumentCreatedAsync?.(newStore.id);
            } else {
                this.setStore(newStore);
            }
        } else {
            State.runInAction(() => {
                this.store.validationResults = newStore.validationResults;
            });
        }

        this.notificationService.showSaveResult(newStore.isPersistedByOperationInfo);
        return newStore.isPersistedByOperationInfo;
    }

    @State.action.bound
    private async deleteFamilyDoctorDocumentAsync() {
        const message = StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Dialog.DeleteConfirmationMessage;
        const dialogResult = await this.dialogService.yesNo(StaticCareResources.Common.Dialog.ConfirmationTitle, message);

        if (dialogResult.resultCode === DialogResultCode.No) {
            return false;
        }

        const result = await this.familyDoctorDocumentApiAdapter.removeFamilyDoctorDocument(this.store);
        if (result.isPersistedByOperationInfo) {
            this.notificationService.showSavedSuccessfully();

            await this.props.onSaveAsync();

            this.store.releaseLock();

            this.props.onCloseDetail?.();
        }
        return true;
    }

    @State.action.bound
    private async forceLoadAsync() {
        await this.loadAsync(true);
    }

    @State.bound
    private curativeItemListItemFactory() {
        return Promise.resolve(new CurativeItem());
    }

    @State.bound
    private familyDoctorDocumentMedicationReferenceListItemFactory() {
        return Promise.resolve(new FamilyDoctorDocumentMedicationReference());
    }

    @State.action.bound
    private async openFamilyDoctorDocumentDocumentAsync() {
        const doc = await this.props._dependencies.familyDoctorDocumentApiAdapter
            .getSingeDocumentForFamilyDoctorDocumentAsync(this.props.familyDoctorDocumentId, "FamilyDoctorDocument", null, true);
        if (!doc.value) {
            this.props._dependencies.notificationService.info(StaticWebAppResources.NewServiceRequestPage.DocumentDoesNotExist);
        } else {
            await this.props._modalService.showModalAsync(new DocumentPreviewModalParams(doc.value.id));
        }
    }

    @State.computed
    private get operationsToChecks() {
        const res = {};

        res["GetFamilyDoctorDocuments"] = async () => {
            await this.familyDoctorDocumentApiAdapter.getFamilyDoctorDocumentByIdAsync(new FamilyDoctorDocumentId("0"), true, true);
        };

        res["SaveFamilyDoctorDocument"] = async () => {
            if (this.isNew) {
                await this.familyDoctorDocumentApiAdapter.createFamilyDoctorDocumentAsync(this.store, true, false, true);
            } else {
                await this.familyDoctorDocumentApiAdapter.updateFamilyDoctorDocumentAsync(this.store, false, false, true);
            }
        };

        res["RemoveFamilyDoctorDocument"] = async () => {
            if (!this.isNew && this.store.isMutable) {
                await this.familyDoctorDocumentApiAdapter.removeFamilyDoctorDocument(this.store, true);
            }
        };

        return res;
    }

    public render() {

        if (!this.store) {
            return null;
        }

        return (
            <UseCaseFrame
                toolbar={(
                    <FamilyDoctorDocumentHeaderPanel
                        store={this.store}
                        operationsToChecks={this.operationsToChecks}
                        onDeleteFamilyDoctorDocumentAsync={this.deleteFamilyDoctorDocumentAsync}
                        onSaveStoreAsync={this.saveStoreAsync}
                        onLockEditClickedAsync={this.forceLoadAsync}
                        openFamilyDoctorDocumentDocumentAsync={this.openFamilyDoctorDocumentDocumentAsync} />
                )}
                title={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Detail.Title}>
                <>
                    <ValidationBoundary
                        validationResults={this.store.validationResults}
                        entityTypeName="FamilyDoctorDocument"
                        onValidateAsync={this.validateAsync}
                        validateOnMount>
                        <ReadOnlyContext.Provider value={!this.store?.isMutable}>
                            <FamilyDoctorDocumentDetailView
                                familyDoctorDocument={this.store}
                                familyDoctorDocumentMedicationReferenceListItemFactory={this.familyDoctorDocumentMedicationReferenceListItemFactory}
                                curativeItemListItemFactory={this.curativeItemListItemFactory}
                            />
                        </ReadOnlyContext.Provider>
                    </ValidationBoundary >
                    <NavigateAwayHook onNavigateAwayAsync={this.navigateAwayAsync} />
                </>
            </UseCaseFrame>
        );
    }
}
export default connect(
    FamilyDoctorDocumentDetailPanel,
    new DependencyAdapter<IFamilyDoctorDocumentDetailPanelProps, IFamilyDoctorDocumentDetailPanelDependecies>(c => ({
        dialogService: c.resolve<IDialogService>("IDialogService"),
        notificationService: c.resolve<INotificationService>("INotificationService"),
        familyDoctorDocumentApiAdapter: c.resolve<FamilyDoctorDocumentApiAdapter>("FamilyDoctorDocumentApiAdapter"),
        lockingApiAdapter: c.resolve<LockingApiAdapter>("LockingApiAdapter"),
        referenceDataStore: c.resolve<MedicationRequestReferenceDataStore>("MedicationRequestReferenceDataStore")
    })),
    new PatientContextAdapter<IFamilyDoctorDocumentDetailPanelProps>(c => ({
        _patientId: c.patientId
    })),
    new CareActivityContextAdapter<IFamilyDoctorDocumentDetailPanelProps>(c => ({
        _pointOfCareId: c.careActivity?.pointOfCareId,
        _careActivityId: c.careActivity?.id,
        _careActivityBaseData: c.baseData
    })),
    new HisModalServiceAdapter()
);
