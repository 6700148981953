import React from "react";
import StaticWebAppResources from "@StaticResources";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IUserGroupListToolbarProps {
    onAddUserGroup: () => void;
}


const UserGroupListToolbar: React.FC<IUserGroupListToolbarProps> = (props) => {
    return (
        <Ui.AddButton
            onClick={props.onAddUserGroup}
            automationId="addUserGroupButton"
        />
    );
};

export default State.observer(UserGroupListToolbar);
