exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DiagnosesFrequencyWidget_data-container_1Wgso {\n  background-color: #505c71;\n  padding: 6px 0 6px 15px;\n  font-family: PragatiNarrow;\n  font-size: 14px;\n  color: #f4f7fc;\n  line-height: 1.2;\n  margin-bottom: 2px;\n}\n.DiagnosesFrequencyWidget_data-container_1Wgso b {\n  font-family: PragatiNarrowBold;\n  font-size: 15px;\n  margin: 0;\n  line-height: 1.1;\n}\n", ""]);

// exports
exports.locals = {
	"data-container": "DiagnosesFrequencyWidget_data-container_1Wgso",
	"dataContainer": "DiagnosesFrequencyWidget_data-container_1Wgso"
};