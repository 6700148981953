import Di from "@Di";
import GenderId from "@Primitives/GenderId.g";
import NameStore from "@Primitives/NameStore";
import IPractitionerBaseDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IPractitionerBaseDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class PractitionerBaseDataStore implements IPractitionerBaseDataStore {

    @State.observable public hasBirthName = false;

    @State.observable.ref public name = new NameStore();
    @State.observable.ref public birthName: NameStore = new NameStore();
    @State.observable.ref public genderId: GenderId = null;
    @State.observable public mothersName: string = null;
    @State.observable public notes: string = null;

    @State.action.bound
    public setGenderId(newValue: GenderId) {
        this.genderId = newValue;
    }

    @State.action.bound
    public setBirthNameSameAsName(newValue: boolean) {
        this.hasBirthName = !newValue;
        if (newValue === true) {
            this.birthName.setFamilyName("");
            this.birthName.setGivenName1("");
            this.birthName.setNamePrefix("");
        }
    }

    @State.action.bound
    public setMothersName(newValue: string) {
        this.mothersName = newValue;
    }

    @State.action.bound
    public setNotes(newValue: string) {
        this.notes = newValue;
    }
}
