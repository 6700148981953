import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import OncologyDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/OncologyDataStore";
import CareActivityId from "@Primitives/CareActivityId.g";
import IConditionVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IConditionVersion";
import CancerClassificationDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CancerClassificationDataStore";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";

export interface IOncologyDataModalParams extends IModalParams {
    store: OncologyDataStore;
    condition: IConditionVersion;
    careActivityId: CareActivityId;
    cancerClassificationDataStore: CancerClassificationDataStore;
    cancerClassificationStoreLabel: string;
    medicalServiceIdFilter: MedicalServiceId[];
}

export default class OncologyDataModalParams implements IOncologyDataModalParams {

    public static type = "OncologyDataModalParams";
    public get type() { return OncologyDataModalParams.type; }

    constructor(
        public readonly store: OncologyDataStore,
        public readonly condition: IConditionVersion,
        public readonly careActivityId: CareActivityId,
        public readonly cancerClassificationDataStore: CancerClassificationDataStore,
        public readonly cancerClassificationStoreLabel: string,
        public readonly medicalServiceIdFilter: MedicalServiceId[]
    ) { }

}