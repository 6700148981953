import React, { useCallback } from "react";
import * as Ui from "@CommonControls";
import HisDashboard from "@HisPlatformControls/HisDashboard/HisDashboard";
import HisErrorBoundary, { renderError } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import Styles from "./DashboardLayout.less";

interface IDashboardLayoutViewProps {
    style?: React.CSSProperties;
}

const renderDashboardError = (err: Error, reload: () => void) => (
    <Ui.Dashboard orientation="vertical" mode="normal">
        {renderError(err, reload)}
    </Ui.Dashboard>
);


const DashboardLayoutView: React.SFC<IDashboardLayoutViewProps> = (props) => {
    return (
        <Ui.SidebarLayout
            style={props.style}
            leftSidebarClassName={Styles.sidebar}
            leftSidebar={(
                <HisErrorBoundary onRenderError={renderDashboardError}>
                    <HisDashboard />
                </HisErrorBoundary>
            )}>
            {props.children}
        </Ui.SidebarLayout>
    );
};

export default DashboardLayoutView;
