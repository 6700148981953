import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import _ from "@HisPlatform/Common/Lodash";
import PropertyValueVersion from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyValueVersion";
import * as Styles from "@HisPlatform/BoundedContexts/Organization/Components/Panels/PropertyGroupsPanel/PropertyVesrionsModal/PropertyVersionModal.less";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import PropertyVersionColumn from "@HisPlatform/BoundedContexts/Organization/Components/Panels/PropertyGroupsPanel/PropertyVesrionsModal/PropertyVersionColumn";
import PropertyVersionValueEditor from "@HisPlatform/BoundedContexts/Organization/Components/Panels/PropertyGroupsPanel/PropertyVesrionsModal/PropertyVersionValueEditor";
import IPropertyValueVersion from "@HisPlatform/Model/DomainModel/Common/IPropertyValueVersion";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PropertyBase from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyBase";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

interface IPropertyVersionModalViewDependencies {
    localizationService: ILocalizationService;
}

interface IPropertyVersionModalViewProps {
    _dependencies?: IPropertyVersionModalViewDependencies;
    newVersion: IPropertyValueVersion<any>;
    property: PropertyBase;
    onAdd: () => void;
    close: () => void;
    validationResults: IClientValidationResult[];
    closeModal: () => void;
    trySaveAndCloseAsync: () => Promise<void>;
}

@State.observer
class PropertyVersionModalView extends React.Component<IPropertyVersionModalViewProps> {

    @State.bound
    private renderNewRow() {
        return (
            <div>
                <Ui.Flex className={Styles.firstRow}>
                    <Ui.Flex.Item grow>
                        <Ui.Flex>
                            <Ui.Flex.Item sm={4}>
                                <Ui.DatePicker value={this.props.newVersion.validFrom}
                                    onChange={this.props.newVersion.setValidFrom}
                                    automationId="__validFrom"
                                    clearable
                                    placeholder={StaticOrganizationResources.PropertyVersionModal.ValidFrom}
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item sm={4}>
                                <Ui.DatePicker value={this.props.newVersion.validTo}
                                    onChange={this.props.newVersion.setValidTo}
                                    automationId="__validTo"
                                    clearable
                                    placeholder={StaticOrganizationResources.PropertyVersionModal.ValidTo}
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item sm={4}>
                                <PropertyVersionValueEditor property={this.props.property}
                                    version={this.props.newVersion} />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item>
                        <Ui.Button
                            onClick={this.props.onAdd}
                            iconName="check"
                            size="standard"
                            float="right"
                            visualMode="inverted"
                            automationId="__addNewService"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </div>
        );
    }

    @State.bound
    private renderActions(value: any, row: IPropertyValueVersion<any>) {
        const deleteHandler = () => {
            this.props.property.removeVersion(row);
        };
        return (
            <Ui.Button iconName="trash" visualStyle="negative" visualMode="inverted" onClick={deleteHandler} automationId={row.validFrom?.stringify() + "-" + row.validTo?.stringify() + "_deleteButton"} />
        );
    }

    @State.bound
    private renderValidityColumn(value: any, row: PropertyValueVersion<any>) {
        return (
            <State.Observer>
                {() =>
                    (
                        <Ui.Flex>
                            <Ui.Flex.Item sm={6}>
                                <Ui.DatePicker value={row.validFrom}
                                    onChange={row.setValidFrom}
                                    automationId="__validFrom"
                                    clearable
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item sm={6}>
                                <Ui.DatePicker value={row.validTo}
                                    onChange={row.setValidTo}
                                    automationId="__validTo"
                                    clearable
                                /> </Ui.Flex.Item>
                        </Ui.Flex>
                    )
                }

            </State.Observer>);
    }

    @State.bound
    private renderGrid() {
        return (this.props.property?.versions &&
            (
                <Ui.DataGrid
                    dataSource={this.props.property.versions}
                    isSelectable={false}
                    hasHeader
                    footer={null}
                    fixedLayout
                    propertyIdentifier=".*"
                    automationId="__propertyVersionList"
                >

                    <DataGridColumn
                        header={StaticOrganizationResources.PropertyVersionModal.Validity}
                        automationId="Validity"
                        onRenderCellValue={this.renderValidityColumn}
                        width="240px"
                        isObserver
                    />
                    <PropertyVersionColumn property={this.props.property} />
                    <DataGridColumn
                        automationId="Actions"
                        onRenderCellValue={this.renderActions}
                        width="40px"
                    />
                </Ui.DataGrid>
            )
        );
    }

    public render() {
        if (!this.props.newVersion) {
            return null;
        }
        return (
            <Ui.Modal
                title={StaticOrganizationResources.PropertyVersionModal.Title}
                closeButton
                onClose={this.props.close}
                isOpen
                size="tiny"
                closeOnOutsideClick={false}
                closeOnEscape={false}
            >
                <Ui.Modal.Body>
                    <ValidationBoundary
                        validationResults={this.props.validationResults}
                        entityTypeName="ScalarProperty"
                    >
                        <Ui.ValidationResultSummary
                            results={_.flatten(this.props.validationResults?.map(i => i.problems))}
                        />
                        {this.renderNewRow()}
                        {this.renderGrid()}
                    </ValidationBoundary>
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Flex>
                        <Ui.Flex.Item sm={6}>
                            <Ui.Button
                                onClick={this.props.closeModal}
                                visualStyle="link"
                                text={StaticOrganizationResources.PropertyVersionModal.Cancel}
                                automationId="closeButton"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item sm={6}>
                            <Ui.Button
                                onClickAsync={this.props.trySaveAndCloseAsync}
                                visualStyle="primary"
                                text={StaticOrganizationResources.PropertyVersionModal.Ok}
                                float="right"
                                automationId="saveAndCloseButton"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.Modal.Footer>
            </Ui.Modal>
        );
    }
}

export default connect(
    PropertyVersionModalView,
    new DependencyAdapter<IPropertyVersionModalViewProps, IPropertyVersionModalViewDependencies>(container => {
        return {
            localizationService: container.resolve("ILocalizationService")
        };
    })
);
