import * as Proxy from "@HisPlatform/BoundedContexts/UserManagement/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import IProfileItem from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Profiles/IProfileItem";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";

@Di.injectable()
export default class ProfileApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IProfilesClient") private readonly apiClient: Proxy.IProfilesClient
    ) {
        super();
    }

    public loadByIdsAsync(...keys: string[]) {
        return this.processOperationAsync(
            new SimpleStore<IProfileItem[]>(),
            async target => {
                const param = keys.join(";");
                const response = await this.apiClient.getProfileItemsQueryAsync(CreateRequestId(), param);

                target.operationInfo = createOperationInfo(response);
                target.value = response.userProfile.map((item) => {
                    return {
                        key: item.key,
                        value: item.value
                    } as IProfileItem;
                });
            }
        );
    }

    public setProfileItemsAsync(...profileItems: IProfileItem[]) {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const dto = new Proxy.SetProfileItemsControllerDto(
                    {
                        profileItems: profileItems.map((item) => {
                            return new Proxy.KeyValuePairOfStringAndString({
                                key: item.key,
                                value: item.value
                            });
                        })
                    }
                );

                const response = await this.apiClient.setProfileItemsCommandAsync(CreateRequestId(), dto);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}