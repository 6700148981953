import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import PractitionerRecommendationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/PractitionerRecommendation/PractitionerRecommendationApiAdapter";
import PractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/PractitionerRecommendation";
import * as Ui from "@CommonControls";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import SubsidyClaimTypeSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/SubsidyClaimTypeSelector/SubsidyClaimTypeSelector";
import ReferenceDataApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/ReferenceDataApiAdapter";
import MedicationPricingAndSubsidies from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationPricingAndSubsidies";
import SubsidyOptionSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/SubsidyOptionSelector/SubsidyOptionSelector";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import PanelController from "@Toolkit/ReactClient/Components/PanelController";
import CareActivityId from "@Primitives/CareActivityId.g";
import DosageEditor from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DosageEditor/DosageEditor";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import ConditionCodeSelector from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ConditionCodeSelector";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import MedicationSubsidyOptionId from "@Primitives/MedicationSubsidyOptionId.g";
import MedicationFormTextBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationFormTextBox/MedicationFormTextBox";
import MedicationPractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/MedicationPractitionerRecommendation";
import EquipmentPractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/EquipmentPractitionerRecommendation";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import MedicationEquipmentClassificationCodeSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationEquipmentClassificationCodeSelector";
import EquipmentSupportTypeSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/EquipmentSupportTypeSelectBox/EquipmentSupportTypeSelectBox";
import ProfessionalExamSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/ProfessionalExamSelectBox/ProfessionalExamSelectBox";
import Styles from "./PractitionerRecommendationList.less";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PractitionerId from "@Primitives/PractitionerId.g";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import MedicationCodeSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationCodeSelector";
import MedicationId from "@Primitives/MedicationId.g";
import MedicationSubsidy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationSubsidy";
import MedicationSubsidyId from "@Primitives/MedicationSubsidyId.g";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import MedicationSubsidyOptionList from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/PrescriptionListPanel/MedicationSubsidyOptionList";
import SubsidyOptionCheckResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/SubsidyOptionCheckResult";
import PractitionerRecommendationStatus from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/PractitionerRecommendationStatus";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import ExcludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierSystemIdFilter";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import MedicationSubsidyOptionCheckType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/ReferenceData/Prescription/Enum/MedicationSubsidyOptionCheckType.g";
import WellKnownReferenceCodes from "@HunEHealthInfrastructurePlugin/Common/WellKnownReferenceCodes";
import MedicationTypeId from "@Primitives/MedicationTypeId.g";

interface IPractitionerRecommendationDetailViewDependencies {
    apiAdapter: PractitionerRecommendationApiAdapter;
    referenceApiAdapter: ReferenceDataApiAdapter;
    referenceDataStore: MedicationRequestReferenceDataStore;
    careReferenceDataStore: CareReferenceDataStore;
    notificationService: INotificationService;
    lockingApiAdapter: LockingApiAdapter;
    dialogService: IDialogService;
    practitionerApiAdapter: PractitionerApiAdapter;
}

interface IPractitionerRecommendationDetailViewProps extends IHasMasterDetailState {
    _dependencies?: IPractitionerRecommendationDetailViewDependencies;
    _modalService?: IModalService;
    primaryParticipantId?: PractitionerId;
    controller: PanelController;
    careActivityId: CareActivityId;
    setMedicationAsync: (value: MedicationId) => Promise<void>;
    pricingAndSubsidies: MedicationPricingAndSubsidies;
    possibleSubsidyByPricingType: MedicationSubsidy;
    navigatingAwayAsync: () => Promise<boolean>;
    setSubsidyAndOptionId: (newValue: { subsidyId: MedicationSubsidyId, optionId: MedicationSubsidyOptionId }) => void;
    recommendation: PractitionerRecommendation;
    readonly?: boolean;
    onValidateAsync: (dirtyFields?: string[]) => Promise<IClientValidationResult[]>;
}

/** @screen */
@State.observer
class PractitionerRecommendationDetailView extends React.Component<IPractitionerRecommendationDetailViewProps> {

    @State.observable public isLoading: boolean = false;

    @State.observable.ref private subsidyOptionCheckResults: SubsidyOptionCheckResult[] = [];

    @State.action.bound
    private setSubsidyOptionCheckResults(optionCheckResults: SubsidyOptionCheckResult[]) {
        this.subsidyOptionCheckResults = optionCheckResults;
    }

    private get practitionerApiAdapter() {
        return this.props._dependencies.practitionerApiAdapter;
    }

    @State.observable private practitioner: PractitionerStore = null;
    @State.observable.ref public medicationReferenceData: MedicationPricingAndSubsidies = null;

    public componentDidMount() {
        dispatchAsyncErrors(this.getPractitionerByIdAsync(), this);
    }

    @State.boundLoadingState("isLoading")
    private async getPractitionerByIdAsync() {
        if (!isNullOrUndefined(this.props.primaryParticipantId)) {
            const practitioner = await this.practitionerApiAdapter.getPractitionerByIdAsync(this.props.primaryParticipantId);
            State.runInAction(() => {
                this.practitioner = practitioner;
            });
        }
    }

    @State.bound
    private renderMedicationPractitionerRecommendation(filters: FilterBase[]) {
        const resources = StaticHunSocialSecurityMedicationRequestResources.PractitionerRecommendationList;
        const recommendation = this.props.recommendation as MedicationPractitionerRecommendation;        
        if (this.isLoading) {
            return <></>;
        }
        return (
            <ValidationBoundary
                validationResults={this.props.recommendation.validationResults}
                entityTypeName="PractitionerRecommendation"
                onValidateAsync={this.props.onValidateAsync}
            >
                <Ui.ValidationResultSummary propertyPath="General.*" />
                {this.props.recommendation && (
                    <ReadOnlyContext.Provider value={this.props.recommendation?.isMutable === false || this.props.readonly || (!this.props.recommendation.isNew && this.props.recommendation.statusColumn.status !== PractitionerRecommendationStatus.UnderRecording)}>
                        <Ui.Flex style={{ marginTop: "10px" }}>
                            <Ui.Flex.Item xs={6}>
                                <Ui.Flex>
                                    <Ui.Flex.Item xs={12}>
                                        <MedicationCodeSelector
                                            label={resources.Labels.Medication}
                                            value={recommendation.medicationVersionSelector?.id}
                                            validOn={recommendation.recordedOn}
                                            onChange={this.props.setMedicationAsync}
                                            propertyIdentifier="MedicationId"
                                            automationId="medicationSelector"
                                            medicationTypes={
                                                [
                                                    MedicationTypeId.G1.value,
                                                    MedicationTypeId.G3.value,
                                                    MedicationTypeId.G4.value,
                                                    MedicationTypeId.G5.value,
                                                    MedicationTypeId.G6.value,
                                                    MedicationTypeId.G7.value,
                                                    MedicationTypeId.H1.value,
                                                    MedicationTypeId.T1.value
                                                ]
                                            }
                                            originalMedicationTypes={["OO"]}
                                        />
                                    </Ui.Flex.Item>
                                </Ui.Flex>
                                <Ui.Flex>
                                    <Ui.Flex.Item xs={6}>
                                        <Ui.CheckBox
                                            value={recommendation.useMedicationName}
                                            onChange={recommendation.setUseMedicationName}
                                            label={resources.Labels.UseMedicationName}
                                            automationId="useMedicationNameCheckBox"
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={6}>
                                        <ConditionCodeSelector
                                            value={recommendation.conditionId}
                                            onChange={recommendation.setConditionId}
                                            filters={filters}
                                            label={resources.Labels.Condition}
                                            propertyIdentifier="ConditionId"
                                            automationId="conditionCodeSelector"
                                        />
                                    </Ui.Flex.Item>
                                </Ui.Flex>
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={6}>
                                <DosageEditor dosage={recommendation.dosage} setDosage={recommendation.setDosage} />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={3}>
                                <SubsidyClaimTypeSelector
                                    value={recommendation.claimTypeId}
                                    onChange={recommendation.setClaimTypeId}
                                    idFilter={this.props.pricingAndSubsidies?.pricing?.claimTypes}
                                    label={resources.Labels.ClaimType}
                                    propertyIdentifier="SubsidyClaimType"
                                    automationId="subsidyClaimTypeSelector"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={3}>
                                <SubsidyOptionSelector
                                    subsidy={this.props.possibleSubsidyByPricingType}
                                    value={!isNullOrUndefined(recommendation.medicationSubsidyId) ? ({ subsidyId: recommendation.medicationSubsidyId, optionId: recommendation.subsidyOptionId }) : null}
                                    onChange={this.props.setSubsidyAndOptionId}
                                    label={resources.Labels.SubsidyOption}
                                    propertyIdentifier="SubsidyOption"
                                    automationId="subsidyAndOptionIdSelector"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={2}>
                                <MedicationFormTextBox
                                    value={recommendation.medicationFormId}
                                    onChange={recommendation.setMedicationFormId}
                                    label={resources.Labels.MedicationForm}
                                    propertyIdentifier="MedicationForm"
                                    automationId="medicationFormIdSelector"

                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={2}>
                                <Ui.NumberBox
                                    value={recommendation.validityDurationInMonths}
                                    onChange={recommendation.setValidityDurationInMonths}
                                    label={resources.Labels.ValidityDurationInMonths}
                                    propertyIdentifier="ValidityDurationInMonths"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={2}>
                                <Ui.DatePicker
                                    value={recommendation.recordedOn}
                                    onChange={recommendation.setRecordedOn}
                                    label={resources.Labels.RecordedOn}
                                    propertyIdentifier="RecordedOn"
                                    automationId="recordedOnPicker"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </ReadOnlyContext.Provider>
                )}
                <Ui.GroupBox title={resources.Labels.SubsidyOptionRestrictions}>
                    {this.props.pricingAndSubsidies?.subsidies?.map(s => this.renderSubsidy(s))}
                </Ui.GroupBox>
                <NavigateAwayHook onNavigateAwayAsync={this.props.navigatingAwayAsync} />
            </ValidationBoundary>
        );
    }

    @State.bound
    private renderEquipmentPractitionerRecommendation(filters: FilterBase[]) {
        const resources = StaticHunSocialSecurityMedicationRequestResources.PractitionerRecommendationList;
        const recommendation = this.props.recommendation as EquipmentPractitionerRecommendation;
        if (this.isLoading) {
            return <></>;
        }
        return (
            <>
                <ValidationBoundary
                    validationResults={this.props.recommendation.validationResults}
                    entityTypeName="PractitionerRecommendation"
                    onValidateAsync={this.props.onValidateAsync}
                >
                    {this.props.recommendation && (
                        <ReadOnlyContext.Provider value={this.props.recommendation?.isMutable === false || this.props.readonly || (!this.props.recommendation.isNew && this.props.recommendation.statusColumn.status !== PractitionerRecommendationStatus.UnderRecording)}>
                            <Ui.Flex style={{ marginTop: "15px" }}>
                                <label className={Styles.controlTitle}>{resources.Labels.RecommendedEquipments}:</label>
                                <Ui.Flex.Item xs={12}>
                                    <MedicationEquipmentClassificationCodeSelector
                                        value={recommendation.recommendedEquipment1?.id}
                                        onChange={recommendation.setRecommendedEquipment1}
                                        validOn={recommendation.recordedOn}
                                        propertyIdentifier="RecommendedEquipment1"
                                        automationId="recommendedEquipment1Selector"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={12}>
                                    <MedicationEquipmentClassificationCodeSelector
                                        value={recommendation.recommendedEquipment2?.id}
                                        onChange={recommendation.setRecommendedEquipment2}
                                        validOn={recommendation.recordedOn}
                                        propertyIdentifier="RecommendedEquipment2"
                                        automationId="recommendedEquipment2Selector"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={4}>
                                    <SubsidyClaimTypeSelector
                                        value={recommendation.claimTypeId}
                                        onChange={recommendation.setClaimTypeId}
                                        idFilter={this.props.pricingAndSubsidies?.pricing?.claimTypes}
                                        label={resources.Labels.ClaimType}
                                        propertyIdentifier="SubsidyClaimType"
                                        automationId="subsidyClaimTypeSelector"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={1}>
                                    <EquipmentSupportTypeSelectBox
                                        value={recommendation.equipmentSupportTypeId}
                                        onChange={recommendation.setSupportTypeId}
                                        label={resources.Labels.SupportType}
                                        propertyIdentifier="SupportType"
                                        automationId="equipmentSupportTypeSelectBox"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={1}>
                                    <Ui.NumberBox
                                        value={recommendation.amount}
                                        onChange={recommendation.setAmount}
                                        label={resources.Labels.Amount}
                                        propertyIdentifier="Amount"
                                        automationId="amountNumberBox"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={3}>
                                    <Ui.DatePicker
                                        value={recommendation.recordedOn}
                                        onChange={recommendation.setRecordedOn}
                                        label={resources.Labels.RecordedOn}
                                        propertyIdentifier="RecordedOn"
                                        automationId="recordedOnPicker"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={3}>
                                    <Ui.DatePicker
                                        value={recommendation.validTo}
                                        onChange={recommendation.setValidTo}
                                        label={resources.Labels.ValidTo}
                                        propertyIdentifier="ValidTo"
                                        automationId="validToPicker"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={6}>
                                    <ConditionCodeSelector
                                        value={recommendation.conditionId}
                                        onChange={recommendation.setConditionId}
                                        filters={filters}
                                        label={resources.Labels.Condition}
                                        propertyIdentifier="ConditionId"
                                        automationId="conditionCodeSelector"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={6}>
                                    <ProfessionalExamSelectBox
                                        value={recommendation.professionalExamId}
                                        onChange={recommendation.setProfessionalExamId}
                                        label={resources.Labels.Profession}
                                        filteredIds={this.practitioner?.professionalExams.map(i => !i.isExpired && i.professionalExamId)}
                                        propertyIdentifier="ProfessionalExamId"
                                        automationId="professionalExamSelectBox"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={12}>
                                    <ConditionCodeSelector
                                        value={recommendation.furtherConditions}
                                        onChange={recommendation.setFurtherConditions}
                                        multiSelect
                                        filters={filters}
                                        label={resources.Labels.FurtherConditions}
                                        automationId="furtherConditionsSelector"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={12}>
                                    <Ui.TextBox
                                        value={recommendation.notes}
                                        onChange={recommendation.setNotes}
                                        label={resources.Labels.Notes}
                                        automationId="notesTextBox"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </ReadOnlyContext.Provider>
                    )}
                    <NavigateAwayHook onNavigateAwayAsync={this.props.navigatingAwayAsync} />
                </ValidationBoundary>
            </>
        );
    }

    public render() {
        const morphologyIdentifierSystemId = new IdentifierSystemId(WellKnownReferenceCodes.MorphologyIdentifier);
        const filters: FilterBase[] = [new ExcludeIdentifierSystemIdFilter(morphologyIdentifierSystemId)];

        if (this.props.recommendation instanceof MedicationPractitionerRecommendation) {
            return this.renderMedicationPractitionerRecommendation(filters);
        }
        if (this.props.recommendation instanceof EquipmentPractitionerRecommendation) {
            return this.renderEquipmentPractitionerRecommendation(filters);
        }
        return null;
    }

    @State.bound
    public renderSubsidy(subsidy: MedicationSubsidy) {
        if (this.props.recommendation instanceof MedicationPractitionerRecommendation) {
            return (
                <MedicationSubsidyOptionList
                    subsidy={subsidy}
                    careActivityId={this.props.recommendation?.careActivityId}
                    medicationId={this.props.recommendation.medicationVersionSelector?.id}
                    checkType={MedicationSubsidyOptionCheckType.Recommendation}
                    optionCheckResults={this.subsidyOptionCheckResults}
                    setOptionCheckResults={this.setSubsidyOptionCheckResults}
                    claimTypeId={this.props.recommendation?.claimTypeId}
                />
            );
        } else {
            return (<></>);
        }
    }
}

export default connect(
    PractitionerRecommendationDetailView,
    new DependencyAdapter<IPractitionerRecommendationDetailViewProps, IPractitionerRecommendationDetailViewDependencies>(c => ({
        apiAdapter: c.resolve("PractitionerRecommendationApiAdapter"),
        referenceApiAdapter: c.resolve("ReferenceDataApiAdapter"),
        referenceDataStore: c.resolve("MedicationRequestReferenceDataStore"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        notificationService: c.resolve("INotificationService"),
        lockingApiAdapter: c.resolve("LockingApiAdapter"),
        dialogService: c.resolve("IDialogService"),
        practitionerApiAdapter: c.resolve("PractitionerApiAdapter")
    })),
    new HisModalServiceAdapter<IPractitionerRecommendationDetailViewProps>(),
    new CareActivityContextAdapter<IPractitionerRecommendationDetailViewProps>(c => ({
        primaryParticipantId: c.baseData?.primaryParticipant
    }))
);
