import React from "react";
import { Localized } from "@CommonControls";
import Styles from "./RssReader.less";

interface IFeedItemProps {
    title: string;
    creator: string;
    dateTime: string;
    link: string;
}


const FeedItem: React.FC<IFeedItemProps> = props => {
    return (
        <div className={Styles.item}>
            <a href={props.link} target="_blank">{props.title}</a>
            {props.creator} - <Localized.DateTime value={props.dateTime} showSeconds={false}/>
        </div>
    );
};

export default FeedItem;