import React, { useCallback } from "react";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ServiceRequestDefinitionSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ServiceRequestDefinitionSelectBox";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";

interface IServiceRequestDefinitionColumn2Dependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IServiceRequestDefinitionColumn2Props extends IDataGridColumnBaseProps {
    _dependencies?: IServiceRequestDefinitionColumn2Dependencies;
    onLinkClicked?: (row: any) => void;
    align?: "left" | "center" | "right";
}


const ServiceRequestDefinitionColumn2: React.FC<IServiceRequestDefinitionColumn2Props> = (props) => {

    const { children, ...columnProps } = props;
    const style = { textAlign: props.align ? props.align : "left" };

    const valueRenderer = useCallback((value: any, row: any) => {
        const localized = getLocalizedValue(props, value);
        if (props.onLinkClicked) {
            return <a style={style} onClick={onLinkClickHandler(props, row)}>{localized}</a>;
        }
        return <p style={style}>{localized}</p>;
    }, []);

    const hintRenderer = useCallback((value: any) => {

        return getLocalizedValue(props, value);
    }, []);

    return (
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            onRenderHintValue={hintRenderer}
        />
    );
};

const filterRenderer = (filterProps: IDataGridColumnFilterProps) => {
    return (
        <ServiceRequestDefinitionSelectBox
            maxMenuWidth={commonMenuMaxWidth}
            value={filterProps.value}
            onChange={filterProps.onChange}
            automationId={filterProps.automationId + "_editor" || undefined}
            hoverOnlyIndicatorIcons
        />
    );
};

const getLocalizedValue = (props: IServiceRequestDefinitionColumn2Props, value: any) => {
    if (!value) {
        return "";
    }
    const definition = props._dependencies.careReferenceDataStore.serviceRequestDefinition.get(value);
    return definition ? definition.shortName : "N/A";
};

const onLinkClickHandler = (props: IServiceRequestDefinitionColumn2Props, row: any) => {
    return (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        props.onLinkClicked(row);
    };
};

export default connect(
    ServiceRequestDefinitionColumn2,
    new DependencyAdapter<IServiceRequestDefinitionColumn2Props, IServiceRequestDefinitionColumn2Dependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore")
    }))
);