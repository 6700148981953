import State from "@Toolkit/ReactClient/Common/StateManaging";
import OncologyRegisterExaminationModeId from "@Primitives/OncologyRegisterExaminationModeId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import OncologyDataId from "@Primitives/OncologyDataId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import OncologyRegisterFurtherTreatmentId from "@Primitives/OncologyRegisterFurtherTreatmentId.g";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import OncologyRegisterTumorStatusId from "@Primitives/OncologyRegisterTumorStatusId.g";
import OncologyRegisterExtensionId from "@Primitives/OncologyRegisterExtensionId.g";
import OncologyRegisterLateralityId from "@Primitives/OncologyRegisterLateralityId.g";
import ConditionId from "@Primitives/ConditionId.g";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import CancerClassificationNodeId from "@Primitives/CancerClassificationNodeId.g";
import CancerClassificationMetastasisId from "@Primitives/CancerClassificationMetastasisId.g";
import CancerClassificationId from "@Primitives/CancerClassificationId.g";
import CancerClassificationTumorId from "@Primitives/CancerClassificationTumorId.g";
import { OncologyDataDto } from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";

export default class OncologyDataStore extends EntityStoreBase<OncologyDataId> {
    @State.observable.ref public oncologyRegisterExaminationModeId: OncologyRegisterExaminationModeId;
    @State.observable.ref public dateOfDiagnosis: LocalDate;
    @State.observable.ref public oncologyRegisterFurtherTreatmentId: OncologyRegisterFurtherTreatmentId;
    @State.observable.ref public relatedMedicalServices: MedicalServiceId[] = [];
    @State.observable.ref public oncologyRegisterTumorStatusId: OncologyRegisterTumorStatusId;
    @State.observable.ref public dateOfStatus: LocalDate;
    @State.observable.ref public oncologyRegisterExtensionId: OncologyRegisterExtensionId;
    @State.observable.ref public oncologyRegisterLateralityId: OncologyRegisterLateralityId;
    @State.observable.ref public cancerClassificationTumorId: CancerClassificationTumorId;
    @State.observable.ref public cancerClassificationNodeId: CancerClassificationNodeId;
    @State.observable.ref public cancerClassificationMetastasisId: CancerClassificationMetastasisId;
    @State.observable.ref public morphology: ConditionId;
    @State.observable.ref public cancerClassificationId: CancerClassificationId;
    @State.observable.ref public careActivityId: CareActivityId;

    @State.action.bound
    public setExaminationModeId(newValue: OncologyRegisterExaminationModeId) {
        this.oncologyRegisterExaminationModeId = newValue;
    }

    @State.action.bound
    public setDateOfDiagnosis(newValue: LocalDate) {
        this.dateOfDiagnosis = newValue;
    }

    @State.action.bound
    public setFurtherTreatmentId(newValue: OncologyRegisterFurtherTreatmentId) {
        this.oncologyRegisterFurtherTreatmentId = newValue;
    }

    @State.action.bound
    public setRelatedMedicalServices(newValue: MedicalServiceId[]) {
        this.relatedMedicalServices = newValue;
    }

    @State.action.bound
    public setOncologyRegisterTumorStatusId(newValue: OncologyRegisterTumorStatusId) {
        this.oncologyRegisterTumorStatusId = newValue;
    }

    @State.action.bound
    public setDateOfStatus(newValue: LocalDate) {
        this.dateOfStatus = newValue;
    }

    @State.action.bound
    public setOncologyRegisterExtensionId(newValue: OncologyRegisterExtensionId) {
        this.oncologyRegisterExtensionId = newValue;
    }

    @State.action.bound
    public setOncologyRegisterLateralityId(newValue: OncologyRegisterLateralityId) {
        this.oncologyRegisterLateralityId = newValue;
    }

    @State.action.bound
    public setCancerClassificationTumorId(newValue: CancerClassificationTumorId) {
        this.cancerClassificationTumorId = newValue;
    }

    @State.action.bound
    public setCancerClassificationNodeId(newValue: CancerClassificationNodeId) {
        this.cancerClassificationNodeId = newValue;
    }

    @State.action.bound
    public setCancerClassificationMetastasisId(newValue: CancerClassificationMetastasisId) {
        this.cancerClassificationMetastasisId = newValue;
    }

    @State.action.bound
    public setMorphology(newValue: ConditionId) {
        this.morphology = newValue;
    }

    @State.action.bound
    public setCancerClassificationId(newValue: CancerClassificationId) {
        this.cancerClassificationId = newValue;
    }

    @State.action.bound
    public setCareActivityId(newValue: CareActivityId) {
        this.careActivityId = newValue;
    }

    public static createFromDto(source: OncologyDataDto) {
        let result: OncologyDataStore;
        if (!isNullOrUndefined(source)) {
            result = new OncologyDataStore();
            result.id = !isNullOrUndefined(source.id)
                ? new OncologyDataId(source.id.value.toString())
                : null;
            result.oncologyRegisterExaminationModeId = !isNullOrUndefined(source.oncologyRegisterExaminationModeId)
                ? new OncologyRegisterExaminationModeId(source.oncologyRegisterExaminationModeId.value.toString())
                : null;
            result.dateOfDiagnosis = !isNullOrUndefined(source.dateOfDiagnosis)
                ? source.dateOfDiagnosis
                : null;
            result.oncologyRegisterFurtherTreatmentId = !isNullOrUndefined(source.oncologyRegisterFurtherTreatmentId)
                ? new OncologyRegisterFurtherTreatmentId(source.oncologyRegisterFurtherTreatmentId.value.toString())
                : null;
            result.relatedMedicalServices = !isNullOrUndefined(source.relatedMedicalServices)
                ? source.relatedMedicalServices.map((item: any) => new MedicalServiceId(item.value.toString()))
                : [];
            result.oncologyRegisterTumorStatusId = !isNullOrUndefined(source.oncologyRegisterTumorStatusId)
                ? new OncologyRegisterTumorStatusId(source.oncologyRegisterTumorStatusId.value.toString())
                : null;
            result.dateOfStatus = !isNullOrUndefined(source.statusDate)
                ? source.statusDate
                : null;
            result.oncologyRegisterExtensionId = !isNullOrUndefined(source.oncologyRegisterExtensionId)
                ? new OncologyRegisterExtensionId(source.oncologyRegisterExtensionId.value.toString())
                : null;
            result.oncologyRegisterLateralityId = !isNullOrUndefined(source.oncologyRegisterLateralityId)
                ? new OncologyRegisterLateralityId(source.oncologyRegisterLateralityId.value.toString())
                : null;
            result.cancerClassificationTumorId = !isNullOrUndefined(source.cancerClassificationData?.cancerClassificationTumorId)
                ? new CancerClassificationTumorId(source.cancerClassificationData.cancerClassificationTumorId.value.toString())
                : null;
            result.cancerClassificationNodeId = !isNullOrUndefined(source.cancerClassificationData?.cancerClassificationNodeId)
                ? new CancerClassificationNodeId(source.cancerClassificationData.cancerClassificationNodeId.value.toString())
                : null;
            result.cancerClassificationMetastasisId = !isNullOrUndefined(source.cancerClassificationData?.cancerClassificationMetastasisId)
                ? new CancerClassificationMetastasisId(source.cancerClassificationData.cancerClassificationMetastasisId.value.toString())
                : null;
            result.cancerClassificationId = !isNullOrUndefined(source.cancerClassificationData?.cancerClassificationId)
                ? new CancerClassificationId(source.cancerClassificationData.cancerClassificationId.value.toString())
                : null;
            result.morphology = !isNullOrUndefined(source.morphology)
                ? new ConditionId(source.morphology.value.toString())
                : null;
            result.careActivityId = !isNullOrUndefined(source.careActivityId)
                ? new CareActivityId(source.careActivityId.value)
                : null;
        } else {
            result = new OncologyDataStore();
        }
        return result;
    }
    public static createFromExtensionDto(source: any) {
        let result: OncologyDataStore;
        if (!isNullOrUndefined(source)) {
            const oncologyExtension = source["HunEHealthInfrastructure_OncologyData"];

            if (!isNullOrUndefined(oncologyExtension)) {
                result = new OncologyDataStore();
                result.id = !isNullOrUndefined(oncologyExtension.Id)
                    ? new OncologyDataId(oncologyExtension.Id.Value.toString())
                    : null;
                result.oncologyRegisterExaminationModeId = !isNullOrUndefined(oncologyExtension.OncologyRegisterExaminationModeId)
                    ? new OncologyRegisterExaminationModeId(oncologyExtension.OncologyRegisterExaminationModeId.Value.toString())
                    : null;
                result.dateOfDiagnosis = !isNullOrUndefined(oncologyExtension.DateOfDiagnosis)
                    ? LocalDate.create(oncologyExtension.DateOfDiagnosis.Year, oncologyExtension.DateOfDiagnosis.Month, oncologyExtension.DateOfDiagnosis.Day)
                    : null;
                result.oncologyRegisterFurtherTreatmentId = !isNullOrUndefined(oncologyExtension.OncologyRegisterFurtherTreatmentId)
                    ? new OncologyRegisterFurtherTreatmentId(oncologyExtension.OncologyRegisterFurtherTreatmentId.Value.toString())
                    : null;
                result.relatedMedicalServices = !isNullOrUndefined(oncologyExtension.RelatedMedicalServices)
                    ? oncologyExtension.RelatedMedicalServices.map((item: any) => new MedicalServiceId(item.Value.toString()))
                    : [];
                result.oncologyRegisterTumorStatusId = !isNullOrUndefined(oncologyExtension.OncologyRegisterTumorStatusId)
                    ? new OncologyRegisterTumorStatusId(oncologyExtension.OncologyRegisterTumorStatusId.Value.toString())
                    : null;
                result.dateOfStatus = !isNullOrUndefined(oncologyExtension.StatusDate)
                    ? LocalDate.create(oncologyExtension.StatusDate.Year, oncologyExtension.StatusDate.Month, oncologyExtension.StatusDate.Day)
                    : null;
                result.oncologyRegisterExtensionId = !isNullOrUndefined(oncologyExtension.OncologyRegisterExtensionId)
                    ? new OncologyRegisterExtensionId(oncologyExtension.OncologyRegisterExtensionId.Value.toString())
                    : null;
                result.oncologyRegisterLateralityId = !isNullOrUndefined(oncologyExtension.OncologyRegisterLateralityId)
                    ? new OncologyRegisterLateralityId(oncologyExtension.OncologyRegisterLateralityId.Value.toString())
                    : null;
                result.cancerClassificationTumorId = !isNullOrUndefined(oncologyExtension.CancerClassificationData?.CancerClassificationTumorId)
                    ? new CancerClassificationTumorId(oncologyExtension.CancerClassificationData.CancerClassificationTumorId.Value.toString())
                    : null;
                result.cancerClassificationNodeId = !isNullOrUndefined(oncologyExtension.CancerClassificationData?.CancerClassificationNodeId)
                    ? new CancerClassificationNodeId(oncologyExtension.CancerClassificationData.CancerClassificationNodeId.Value.toString())
                    : null;
                result.cancerClassificationMetastasisId = !isNullOrUndefined(oncologyExtension.CancerClassificationData?.CancerClassificationMetastasisId)
                    ? new CancerClassificationMetastasisId(oncologyExtension.CancerClassificationData.CancerClassificationMetastasisId.Value.toString())
                    : null;
                result.cancerClassificationId = !isNullOrUndefined(oncologyExtension.CancerClassificationData?.CancerClassificationClassificationId)
                    ? new CancerClassificationId(oncologyExtension.CancerClassificationData.CancerClassificationClassificationId.Value.toString())
                    : null;
                result.morphology = !isNullOrUndefined(oncologyExtension.Morphology)
                    ? new ConditionId(oncologyExtension.Morphology.Value.toString())
                    : null;
                result.careActivityId = !isNullOrUndefined(oncologyExtension.CareActivityId)
                    ? new CareActivityId(oncologyExtension.CareActivityId.Value)
                    : null;
                result.rowVersion = !isNullOrUndefined(oncologyExtension.RowVersion)
                    ? new RowVersion(oncologyExtension.RowVersion.Value)
                    : null;
            } else {
                result = new OncologyDataStore();
            }
        } else {
            result = new OncologyDataStore();
        }
        return result;
    }

    private _dirtyChecker = new StoreDirtyChecker();

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "operationInfo"
    ];

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }
}
