import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IOrganizationResources from "./IOrganizationResources";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";


const StaticOrganizationResources = createStaticResourceDictionary<IOrganizationResources>();

export function loadStaticOrganizationResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    StaticOrganizationResources.load(localizationService.getResourceGroup("WebApp").Organization);
}

export default StaticOrganizationResources;