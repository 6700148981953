import { IValidationProblemParameterMapper } from "@Toolkit/CommonWeb/ApiAdapter/IValidationProblemParameterMapper";
import Di from "@Di";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import PointOfCareId from "@Primitives/PointOfCareId.g";

@Di.injectable()
export default class AdmitPatientAndCareActivityBaseDataValidationProblemMapper implements IValidationProblemParameterMapper {
    constructor(@Di.inject("OrganizationReferenceDataStore") private organizationReferenceDataStore: OrganizationReferenceDataStore) {
    }

    public collectValidationProblemParameterReferenceData(vp: IClientValidationProblem) {
        const rawId = _.get(vp.parameters, "ExistingCareActivityRuleOutputDto.PointOfCareId.Value");
        const pointOfCareId = rawId ? new PointOfCareId(rawId.toString()) : null;

        if (pointOfCareId) {
            this.organizationReferenceDataStore.pointOfCareMap.collectId(pointOfCareId);
        }
    }

    @State.bound
    public async loadCollectedValidationProblemParameterReferenceDataAsync(): Promise<void> {
        await this.organizationReferenceDataStore.pointOfCareMap.loadCollectedAsync();
    }

    @State.bound
    public resolveValidationProblemParameters(vp: IClientValidationProblem): { [id: string]: string; } {
        const results: { [id: string]: string; } = {};
        for (const key in vp.parameters) {
            if (key) {
                switch (key) {
                    case "ExistingCareActivityRuleOutputDto":
                        const pointOfCare = this.organizationReferenceDataStore.pointOfCareMap.get(new PointOfCareId(vp.parameters.ExistingCareActivityRuleOutputDto.PointOfCareId.Value.toString()));
                        if (pointOfCare) {
                            results.PointOfCareName = pointOfCare.name;
                            results.PointOfCareCode = pointOfCare.shorthand;
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        return results;
    }
}