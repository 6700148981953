import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";
import ITelecommunicationResources from "./ITelecommunicationResources";


const StaticTelecommunicationResources = createStaticResourceDictionary<ITelecommunicationResources>();

export function loadStaticTelecommunicationResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    StaticTelecommunicationResources.load(localizationService.getResourceGroup("WebApp").Telecommunication);
}

export default StaticTelecommunicationResources;