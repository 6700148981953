import PatientId from "@Primitives/PatientId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import EhiOrganizationUnitProviderService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Organization/Services/Implementation/EhiOrganizationUnitProviderService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiOrganizationUnitSelectorDialogParams, { IEhiOrganizationUnitSelectorDialogResult } from "@HunEHealthInfrastructurePlugin/BoundedContexts/Organization/Components/Controls/EhiOrganizationUnitSelectorDialog/EhiOrganizationUnitSelectorDialogParams";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import EhiServiceCallStatus from "@HunEHealthInfrastructurePlugin/Model/EhiServiceCallStatus";
import EhiUserType from "@HunEHealthInfrastructurePlugin/Model/EhiUserType";
import { EhiServiceCallResult } from "@HunEHealthInfrastructurePlugin/Model/IEhiServiceCallResult";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import IEhiResult from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiResult";
import { IEhiServiceStateStore, EhiServiceStateStore } from "./EhiServiceStateStore";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import EhiToken from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiToken";
import EhiTokenProvider from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Services/EhiTokenProvider/EhiTokenProvider";
import { NoEhiId } from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Services/EhiTokenProvider/NoEhiId";
import { NoEhiToken } from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Services/EhiTokenProvider/NoEhiToken";
import { NoPractitionerId } from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Services/EhiTokenProvider/NoPractitionerId";

export default abstract class EhiServiceBase {

    private stateStore: IEhiServiceStateStore;
    private loginToEhiWithAnotherUserConfirmationDialogTitle = StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.LoginToEhiWithPrescriberConfirmationDialog.Title;
    private loginToEhiWithAnotherUserConfirmationDialogMessage = StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.LoginToEhiWithPrescriberConfirmationDialog.Message;

    protected get ehiToken() { return this.stateStore.ehiToken; }
    protected get organizationUnitId() { return this.stateStore.organizationUnitId; }

    constructor(
        private readonly ehiTokenProvider: EhiTokenProvider,
        private readonly ehiOrganizationUnitProviderService: EhiOrganizationUnitProviderService,
        private readonly userContext: UserContext,
        private readonly modalService: IModalService
    ) {
        this.stateStore = new EhiServiceStateStore();
    }

    public setCommonStore(stateStore: IEhiServiceStateStore) {
        this.stateStore = stateStore;
    }

    public hasDataInStateStore() {
        return this.stateStore.organizationUnitId || this.stateStore.ehiToken;
    }

    public getStateStore() {
        return this.stateStore;
    }

    @State.bound
    protected async performEhiOperationAsync<TResult>(
        patientId: PatientId,
        ehiUserType: EhiUserType,
        action: () => Promise<SimpleStore<IEhiResult<TResult>>>,
        ehiServiceCallPractitionerId?: PractitionerId) {

        const ehiServiceCallStatus = await this.ensureEhiAccessInitializedAsync(ehiUserType, patientId, ehiServiceCallPractitionerId);

        if (ehiServiceCallStatus !== EhiServiceCallStatus.Success) {
            return { ehiServiceCallStatus: ehiServiceCallStatus } as EhiServiceCallResult<TResult>;
        }

        const response = await action();

        return {
            ehiServiceCallStatus: ehiServiceCallStatus,
            result: response
        } as EhiServiceCallResult<TResult>;
    }

    @State.bound
    protected async performEhiOperationWithSpecifiedPointOfCareIdAsync<TResult>(
        ehiUserType: EhiUserType,
        action: () => Promise<SimpleStore<IEhiResult<TResult>>>,
        ehiServiceCallPointOfCareId: PointOfCareId,
        ehiServiceCallPractitionerId?: PractitionerId) {

        const ehiServiceCallStatus = await this.ensureEhiAccessWithSpecifiedPointOfCareIdInitializedAsync(ehiUserType, ehiServiceCallPointOfCareId, ehiServiceCallPractitionerId);

        if (ehiServiceCallStatus !== EhiServiceCallStatus.Success) {
            return { ehiServiceCallStatus: ehiServiceCallStatus } as EhiServiceCallResult<TResult>;
        }

        const response = await action();

        return {
            ehiServiceCallStatus: ehiServiceCallStatus,
            result: response
        } as EhiServiceCallResult<TResult>;
    }

    @State.bound
    private async ensureEhiAccessInitializedAsync(
        ehiUserType: EhiUserType,
        patientId: PatientId,
        ehiServiceCallPractitionerId?: PractitionerId): Promise<EhiServiceCallStatus> {

        let ehiServiceCallStatus = await this.ensureEhiTokenInitializedAsync(ehiUserType, ehiServiceCallPractitionerId);

        if (ehiServiceCallStatus === EhiServiceCallStatus.Success) {
            ehiServiceCallStatus = await this.ensureOrganizationUnitInitializedAsync(patientId);
        }

        return ehiServiceCallStatus;
    }

    @State.bound
    private async ensureEhiAccessWithSpecifiedPointOfCareIdInitializedAsync(
        ehiUserType: EhiUserType,
        ehiServiceCallPointOfCareId: PointOfCareId,
        ehiServiceCallPractitionerId?: PractitionerId): Promise<EhiServiceCallStatus> {

        const ehiServiceCallStatus = await this.ensureEhiTokenInitializedAsync(ehiUserType, ehiServiceCallPractitionerId);

        if (ehiServiceCallStatus === EhiServiceCallStatus.Success) {
            if (!!ehiServiceCallPointOfCareId) {
                this.stateStore.setOrganizationUnitId(new OrganizationUnitId(ehiServiceCallPointOfCareId.value));
            } else {
                this.stateStore.setOrganizationUnitId(null);
            }
        }

        return ehiServiceCallStatus;
    }

    private async ensureEhiTokenInitializedAsync(ehiUserType: EhiUserType, ehiServiceCallPractitionerId?: PractitionerId): Promise<EhiServiceCallStatus> {

        if (ehiUserType === EhiUserType.Doctor) {
            const tokenOrError = !!ehiServiceCallPractitionerId
                ? await this.ehiTokenProvider.loginAsync(ehiServiceCallPractitionerId, this.loginToEhiWithAnotherUserConfirmationDialogTitle, this.loginToEhiWithAnotherUserConfirmationDialogMessage)
                : await this.ehiTokenProvider.getCurrentTokenOrLoginAsync();

            if (tokenOrError === NoEhiToken) {
                return EhiServiceCallStatus.NoEhiToken;
            } else if (tokenOrError === NoEhiId || tokenOrError === NoPractitionerId) {
                return EhiServiceCallStatus.NoEhiId;
            }

            this.stateStore.setEhiToken(tokenOrError as EhiToken);
        }

        return EhiServiceCallStatus.Success;
    }

    private async ensureOrganizationUnitInitializedAsync(patientId: PatientId): Promise<EhiServiceCallStatus> {

        if (isNullOrUndefined(this.organizationUnitId) && !!this.userContext.practitionerId) {
            let organizationUnitId = await this.ehiOrganizationUnitProviderService.getOrganizationUnitIdAsync(this.userContext.practitionerId, patientId);
            if (isNullOrUndefined(organizationUnitId)) {
                const result = await this.modalService.showDialogAsync<IEhiOrganizationUnitSelectorDialogResult>(new EhiOrganizationUnitSelectorDialogParams());
                organizationUnitId = isNullOrUndefined(result) ? null : result.organizationUnitId;
            }
            this.stateStore.setOrganizationUnitId(organizationUnitId);
        }

        if (!this.organizationUnitId) {
            return EhiServiceCallStatus.NoOrganizationUnit;
        }
        return EhiServiceCallStatus.Success;
    }

    @State.bound
    public async setEhiAccessDataToExtensionDataAsync(
        patientId: PatientId,
        extensionData: object,
        ehiUserType: EhiUserType,
        ehiServiceCallPractitionerId?: PractitionerId): Promise<EhiServiceCallStatus> {

        if (isNullOrUndefined(extensionData)) {
            throw new Error("The parameter 'extensionData' must be defined.");
        }

        const result = await this.ensureEhiAccessInitializedAsync(ehiUserType, patientId, ehiServiceCallPractitionerId);

        if (result === EhiServiceCallStatus.Success) {
            extensionData["OrganizationUnitId"] = this.organizationUnitId;

            if (ehiUserType === EhiUserType.Doctor) {
                extensionData["EhiToken"] = this.ehiToken;
            }
        }

        return result;
    }

    @State.bound
    public async setEhiAccessDataWithoutOrganizationUnitCheckToExtensionDataAsync(
        extensionData: object,
        ehiUserType: EhiUserType,
        ehiServiceCallPointOfCareId: PointOfCareId,
        ehiServiceCallPractitionerId?: PractitionerId): Promise<EhiServiceCallStatus> {

        if (isNullOrUndefined(extensionData)) {
            throw new Error("The parameter 'extensionData' must be defined.");
        }

        const result = await this.ensureEhiAccessWithSpecifiedPointOfCareIdInitializedAsync(ehiUserType, ehiServiceCallPointOfCareId, ehiServiceCallPractitionerId);

        if (!!extensionData && result === EhiServiceCallStatus.Success) {
            extensionData["OrganizationUnitId"] = this.organizationUnitId;

            if (ehiUserType === EhiUserType.Doctor) {
                extensionData["EhiToken"] = this.ehiToken;
            }
        }

        return result;
    }

    @State.bound
    public setLoginToEhiWithAnotherUserConfirmationDialogText(title: string, message: string) {
        this.loginToEhiWithAnotherUserConfirmationDialogTitle = title;
        this.loginToEhiWithAnotherUserConfirmationDialogMessage = message;
    }
}