import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import IWidgetComponentProps from "@PluginInterface/Dashboard/IWidgetComponentProps";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import * as Ui from "@CommonControls";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { ICardioProcessItem } from "./ICardioProcessItem";
import Styles from "./IcardioProcessWidget.less";
import ICommandProcessor from "@Toolkit/CommonWeb/CommandProcessing/Definition/ICommandProcessor";
import IMessagingSubscriptionManager from "@Toolkit/CommonWeb/CommandProcessing/Definition/IMessagingSubscriptionManager";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IMessagingSubscription from "@Toolkit/CommonWeb/CommandProcessing/Definition/IMessagingSubscription";
import Command from "@Toolkit/CommonWeb/CommandProcessing/Definition/Command";
import UnspecifiedCommand from "@Toolkit/CommonWeb/CommandProcessing/Definition/UnspecifiedCommand";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import IICardioData from "@ICardioIntegrationPlugin/Model/IICardioData";

interface ICardioProcessWidgetDependencies {
    messagingSubscriptionManager: IMessagingSubscriptionManager;
}

interface ICardioProcessWidgetProps extends IWidgetComponentProps {
    _dependencies?: ICardioProcessWidgetDependencies;
    store?: {
        iCardioProcessWidgetState: IICardioData;
        setWidgetState: (widgetState: IICardioData) => void;
    };
}

@State.observer
class ICardioProcessWidget extends React.Component<ICardioProcessWidgetProps> implements ICommandProcessor<UnspecifiedCommand> {
    private _messagingSubscription?: IMessagingSubscription = null;

    public get commandType() {
        return "UnspecifiedCommand";
    }

    private get messagingSubscriptionManager() { return this.props._dependencies.messagingSubscriptionManager; }

    public componentDidMount() {
        this._messagingSubscription = this.messagingSubscriptionManager.subscribeWithAccessor(this.getICardioFrameWindow);
        this._messagingSubscription.register<UnspecifiedCommand>("UnspecifiedCommand", null, this);
    }

    public componentWillUnmount() {
        if (!!this._messagingSubscription) {
            this._messagingSubscription.dispose();
        }
    }

    public processAsync(command: UnspecifiedCommand): Promise<void> {
        if (!!command.data) {
            this.props.store.setWidgetState(command.data as IICardioData);
        }

        return Promise.resolve();
    }

    public processGenericAsync(command: Command): Promise<void> {
        if (command instanceof UnspecifiedCommand) {
            return this.processAsync(command);
        }

        throw new Error(`Expected a command of type '${"UnspecifiedCommand"}', but got '${command.commandType}'.`);
    }

    private getICardioFrameWindow(): Window {
        if (isNullOrUndefined(document.getElementById("ICardioFrame"))) {
            return null;
        }

        return document.getElementsByTagName("iframe")[0].contentWindow;
    }

    private renderContent() {
        return (
            <Ui.Dashboard.Widget
                name={this.props.name}
                isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                isCollapsible
                title={StaticProductivityResources.Widgets.IcardioProcess.Title}
                icon={"cardiology"}
                automationId="ICardioProcessWidget"
            >
                <>
                    {this.props.store.iCardioProcessWidgetState && <>
                        <h3 className={Styles.headerText}>{StaticProductivityResources.Widgets.IcardioProcess.Process}</h3>
                        {
                            this.props.store.iCardioProcessWidgetState.WorkflowForms.map((workflowForm) => {
                                return (
                                    <ICardioProcessItem
                                        key={workflowForm.FormId ? workflowForm.FormId : ""}
                                        workflowForm={workflowForm}
                                    />);
                            })
                        }
                        <Ui.Flex className={Styles.noMarginTop} spacing="none" verticalSpacing="none">
                            <Ui.Flex.Item className={Styles.orderContainer} xs={3}>
                                <div className={Styles.currentStepFormOrder}>
                                    {this.props.store.iCardioProcessWidgetState.WorkflowForms.length + 1}
                                </div>
                            </Ui.Flex.Item >
                            <Ui.Flex.Item xs={9}>
                                <p className={Styles.lowerMarginBottom}>{this.props.store.iCardioProcessWidgetState.CurrentStep}</p>
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </>}
                </>
            </Ui.Dashboard.Widget >);
    }

    public render() {
        return (
            this.renderContent()
        );
    }
}

export default connect(
    withLoadingBoundary(ICardioProcessWidget),
    new DependencyAdapter<ICardioProcessWidgetProps, ICardioProcessWidgetDependencies>(c => ({
        messagingSubscriptionManager: c.resolve("IMessagingSubscriptionManager")
    }))
);