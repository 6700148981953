import EditableDataElementEditorStore from "./EditableDataElementEditorStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IFormLayoutRowColumnSize from "@Toolkit/FormEngine/Model/Layout/IFormLayoutRowColumnSize";
import Guid from "@Toolkit/CommonWeb/Guid";
import IEditableLayoutStore from "./IEditableLayoutStore";
import IEditableLayoutBlockStore from "./IEditableLayoutBlockStore";

export default class EditableLayoutColumnStore implements IEditableLayoutStore {

    public readonly id: string;
    public readonly contentBlocks = State.createObservableShallowArray<IEditableLayoutBlockStore>([]);
    public editor: EditableDataElementEditorStore;

    @State.observable.ref public size: IFormLayoutRowColumnSize;

    constructor(
        size: IFormLayoutRowColumnSize
    ) {
        this.id = Guid.newGuid();
        this.size = size;
    }

    @State.action
    public setSize(size: IFormLayoutRowColumnSize) {
        this.size = size;
    }
}