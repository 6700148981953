import React from "react";
import ContainerContext from "@Toolkit/ReactClient/Components/DependencyInjection/ContainerContext";
import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import config from "@Config";
import ErrorPage from "@Toolkit/ReactClient/Components/ErrorPage/ErrorPage";
import ApiBusinessError from "@Toolkit/CommonWeb/ApiAdapter/ApiBusinessError";
import UnderMaintenancePage from "@Toolkit/ReactClient/Components/UnderMaintenancePage/UnderMaintenancePage";

interface IApplicationWrapperProps {
    startupAsync: () => Promise<void>;
    developerMode?: boolean;
    containerService?: IContainerService;
}

interface IApplicationWrapperState {
    isStartedUp: boolean;
    startupError: Error;
    isUnderMaintenance: boolean;
}

export default class ApplicationWrapper extends React.Component<IApplicationWrapperProps, IApplicationWrapperState> {

    constructor(props: undefined) {
        super(props);

        this.state = {
            isStartedUp: false,
            startupError: null,
            isUnderMaintenance: false
        };
    }

    public componentDidMount() {
        this.loadAsync().catch(err => {
            if (err instanceof ApiBusinessError && err.error.name === "ServiceUnavailableError") {
                this.setState({ isUnderMaintenance: true});
            }
            this.setState({ startupError: err });
            (window as any).application?.hideLoader?.();
            console.error(err);
        });
    }

    private async loadAsync() {
        await this.props.startupAsync();
        this.setState({ isStartedUp: true });
    }

    public render() {

        if (this.state.isUnderMaintenance) {
            return <UnderMaintenancePage />;
        }

        if (this.state.startupError !== null) {
            return <ErrorPage title={config.fatalErrorMessage.title} message={config.fatalErrorMessage.message} />;
        }

        if (this.state.isStartedUp === true) {
            return (
                <ContainerContext.Provider value={this.props.containerService || null}>
                    {this.props.children}
                </ContainerContext.Provider>
            );
        }

        return null;
    }
}