import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Authorization/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import RoleId from "@Primitives/RoleId.g";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import _ from "@HisPlatform/Common/Lodash";
import { buildQueryStringArray } from "@Toolkit/CommonWeb/QueryStringBuilder";
import Role from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/Role";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import RoleStoreMapper from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/OperationAccessControl/RoleStoreMapper";
import {
    getAddRoleDto,
    getUpdateRoleDto,
    getUpdateRolePermissionCheckDto
} from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/OperationAccessControl/RoleDtoMapper";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

@Di.injectable()
export default class RoleApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IOperationAccessControlClient") private readonly apiClient: Proxy.IOperationAccessControlClient,
        @Di.inject("RoleStoreMapper") private readonly roleStoreMapper: RoleStoreMapper
    ) {
        super();
    }

    @State.bound
    public getAllRoleIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<RoleId[]>(),
            async target => {
                const response = await this.apiClient.getAllRoleIdsQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.roleIds;
            }
        );
    }

    @State.bound
    public getRolesByIdsAsync(ids: RoleId[]): Promise<SimpleStore<Role[]>> {
        return this.processOperationAsync(
            new SimpleStore<Role[]>(),
            async target => {

                if (arrayIsNullOrEmpty(ids)) {
                    target.value = [];
                }

                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value.toString()));

                const response = await this.apiClient.getRolesByIdsQueryAsync(
                    CreateRequestId(),
                    buildQueryStringArray(normalizedIds)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.roles.map((item) => {
                    const newStore = new Role();
                    this.roleStoreMapper.applyToStore(newStore, item);
                    return newStore;
                });
            }
        );
    }

    @State.bound
    public getRoleById(id: RoleId): Promise<Role> {
        return this.processOperationAsync(
            new Role(),
            async target => {
                const response = await this.apiClient.getRoleByIdQueryAsync(
                    CreateRequestId(),
                    id.value
                );

                target.operationInfo = createOperationInfo(response);
                this.roleStoreMapper.applyToStore(target, response.validatedResult);
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    @State.bound
    public addRole(role: Role): Promise<Role> {
        return this.processOperationAsync(
            new Role(),
            async target => {
                const response = await this.apiClient.addRoleCommandAsync(
                    CreateRequestId(),
                    getAddRoleDto(role, false)
                );

                if (response.validatedResult) {
                    this.roleStoreMapper.applyToStore(target, response.validatedResult);
                }
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public updateRole(role: Role): Promise<Role> {
        const dto = getUpdateRoleDto(role, false);
        return this.processOperationAsync(
            new Role(),
            async target => {
                const response = await this.apiClient.updateRoleCommandAsync(
                    CreateRequestId(),
                    dto
                );

                if (response.validatedResult) {
                    this.roleStoreMapper.applyToStore(target, response.validatedResult);
                }
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public updateRolePermissionCheckAsync() {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.updateRoleCommandAsync(
                    CreateRequestId(),
                    getUpdateRolePermissionCheckDto(),
                    true
                );
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public validateAsync(role: Role): Promise<SimpleStore<IClientValidationResult[]>> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = role.isNew
                    ? await this.apiClient.addRoleCommandAsync(CreateRequestId(), getAddRoleDto(role, true))
                    : await this.apiClient.updateRoleCommandAsync(CreateRequestId(), getUpdateRoleDto(role, true));

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }
}
