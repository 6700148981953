import MedicalServiceFinancingStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/MedicalServiceFinancingStore";
import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import _ from "@HisPlatform/Common/Lodash";
import FinancedServiceWithQuantityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/FinancedServiceWithQuantityStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import React from "react";
import { IValidationProblemProcessor } from "@Toolkit/CommonWeb/Validation/ValidationProblemProcessorService";

export class HealthCareProfessionRuleValidationProcessor implements IValidationProblemProcessor<MedicalServiceFinancingStore> {
    public ruleId = "ShouldBeCompliantWithFinancedServiceHealthCareProfessionRules";
    public isRuleHighlightable = true;

    private getServiceId(problem: IClientValidationProblem) {
        const serviceIdValue = _.get(problem.parameters, "PerformedServiceHealthCareProfessionRuleViolationParametersDto.ServiceId.Value");
        return serviceIdValue ? new FinancedServiceId(serviceIdValue.toString()) : null;
    }

    public mapValidationProblem(problem: IClientValidationProblem, items: MedicalServiceFinancingStore[]) {
        const serviceId = this.getServiceId(problem);
        if (serviceId) {
            return items.filter(i => i.financedServicesWithQuantities.some((j: FinancedServiceWithQuantityStore) => {
                return ValueWrapper.equals(serviceId, j.financedServiceId.id);
            }));
        }
        return [];
    }

    public renderInfo = (problem: IClientValidationProblem) => undefined as React.ReactNode;
}