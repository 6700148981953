import BusinessErrorBase from "@HisPlatform/Model/DomainModel/BusinessError/BusinessErrorBase";

export default class UnauthorizedOperationBusinessError extends BusinessErrorBase {

    public static businessErrorName = "UnauthorizedOperationError";
    public get name(): string { return UnauthorizedOperationBusinessError.businessErrorName; }

    constructor(
        public readonly permittedOperationName: string,
    ) { 
        super();
    }
}