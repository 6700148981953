import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";
import ContainerContext from "@Toolkit/ReactClient/Components/DependencyInjection/ContainerContext";
import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import {
    BusinessErrorHandlerContext,
    IBusinessErrorHandler
} from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import LoadingContextStore from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingContextStore";
import LoadingContext from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingContext";

export default class DependencyAdapter<TProps, TDependencies> implements IComponentAdapter<TProps, TDependencies> {
    public get usedContexts(): Array<React.Context<any>> {
        return [ContainerContext, BusinessErrorHandlerContext, LoadingContext];
    }

    constructor(
        private readonly dependencyResolverAction: (containerService: IContainerService) => TDependencies,
        private readonly mapToProps: (dependencies: TDependencies) => Partial<TProps> = (dependencies) => ({ _dependencies: dependencies } as any)) {

    }

    public getMemoizedState(props: TProps, contextValues?: Map<React.Context<any>, any>) {
        return this.dependencyResolverAction(contextValues.get(ContainerContext));
    }

    public getMappedProps(props: TProps, contextValues: Map<React.Context<any>, any>, readonlyState: TDependencies): Partial<TProps> {
        return this.mapToProps({
            ...readonlyState,
            _businessErrorHandler: contextValues.get(BusinessErrorHandlerContext) as IBusinessErrorHandler,
            _loadingContextStore: contextValues.get(LoadingContext) as LoadingContextStore
        });
    }
}
