import Button, { ISimpleButtonProps } from "@Toolkit/ReactClient/Components/CommonControls/Button";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface ICancelButtonDependencies {
    localizationService: IToolkitLocalizationService;
}
class CancelButton extends React.PureComponent<ISimpleButtonProps> {
    public static defaultProps: Partial<ISimpleButtonProps> = {
        visualStyle: "standard",
    };

    public render() {
        return <Button text={this.props._dependencies.localizationService.staticResources.button.cancel} {...this.props} />;
    }
}

export default connect(
    CancelButton,
    new DependencyAdapter<ISimpleButtonProps, ICancelButtonDependencies>(c => ({
        localizationService: c.resolve("IToolkitLocalizationService"),
    }))
);
