exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DashboardLayout_hide_2XKby {\n  display: none;\n}\n.DashboardLayout_sidebar_3iPez {\n  position: relative;\n}\n", ""]);

// exports
exports.locals = {
	"hide": "DashboardLayout_hide_2XKby",
	"sidebar": "DashboardLayout_sidebar_3iPez"
};