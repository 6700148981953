import FormLayoutVersionStore from "./FormLayoutVersionStore";
import FormLayoutId from "@Toolkit/FormEngine/Model/Primitives/FormLayoutId.g";
import _ from "@HisPlatform/Common/Lodash";

export default class FormLayoutStore {

    public readonly versions: FormLayoutVersionStore[];

    constructor(
        public readonly id: FormLayoutId,
        versions: FormLayoutVersionStore[]
    ) { 
        this.versions = _.orderBy(versions, v => v.versionNumber);
    }

    public getVersion(versionNumber: number) {
        const result = this.versions.find(v => v.versionNumber === versionNumber);
        if (!result) { throw new Error(`FormLayout version ${versionNumber} not found.`); }
        return result;
    }

    public getLatestVersionOrNull() {
        return this.versions.length === 0 ? null : this.versions.slice(-1)[0];
    }
}