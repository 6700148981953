import React from "react";
import { useFormDateField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { ICustomBlockRegistryItem, IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientAge from "./PatientAge";
import { useFormLayoutContext } from "@Toolkit/FormEngine/Panels/FormLayoutContext";


function PatientAgeFormCustomBlock(props: IFormCustomBlockComponentProps) {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext.compositeDataReferences.join(".");
    
    const [birthDate, setBirthDate] = useFormDateField(props.form.data.Content, compositeFieldName + ".BirthDate");
    const [deathDate, setDeathDate] = useFormDateField(props.form.data.Content, compositeFieldName + ".DeathDate");

    return (
        <PatientAge birthDate={birthDate} deathDate={deathDate} />
    );
}

export const PatientAgeRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Patient Age",
    dataElementsFactory: () => [
    ],
    componentType: State.observer(PatientAgeFormCustomBlock)
};

export default PatientAgeFormCustomBlock;