import React from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import {IDataGridColumnBaseProps} from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import AppointmentBaseData from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentBaseData";
import * as Ui from "@CommonControls";
import MomentLabel from "@CommonControls/MomentLabel";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Styles from "./AppointmentBaseDataColumn.less";
import {getIconNameByHealthcareProfessions} from "@HisPlatform/BoundedContexts/Organization/Components/Helpers/IconTypeNameHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";

interface IAppointmentBaseDataColumnDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    careReferenceDataStore: CareReferenceDataStore;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
}

interface IAppointmentBaseDataColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IAppointmentBaseDataColumnDependencies;
}

class AppointmentBaseDataColumn extends React.Component<IAppointmentBaseDataColumnProps> {
    private getLocation(value: PointOfCareId) {
        return !isNullOrUndefined(value?.value) ? this.props._dependencies.organizationReferenceDataStore.pointOfCareMap.get(value) : null;
    }

    @State.action.bound
    private getHealthCareProfession(value: PointOfCareId) {
        const location = this.getLocation(value);
        return location.healthcareProfessionIds.map(this.props._dependencies.organizationReferenceDataStore.healthCareProfession.get);
    }

    @State.action.bound
    private getSchedulingService(value: SchedulingServiceId) {
        return this.props._dependencies.schedulingReferenceDataStore.schedulingServices.get(value);
    }

    @State.action.bound
    private getPractitioner(value: PractitionerId) {
        return !isNullOrUndefined(value?.value) ? this.props._dependencies.organizationReferenceDataStore.practitionerMap.get(value) : null;
    }

    @State.action.bound
    private getServiceRequest(value: IEntityVersionSelector<ServiceRequestDefinitionId>) {
        return this.props._dependencies.careReferenceDataStore.serviceRequestDefinition.get(value);
    }

    @State.action.bound
    public valueRenderer(value: AppointmentBaseData) {
        return (
            <>
                {value &&
                <div className={Styles.container}>
                    <Ui.Flex>
                        {value.subjectType === "SchedulingService" &&
                        <Ui.Flex.Item xs={12}>
                            <div className={Styles.serviceField}>
                                <Ui.Icon className={Styles.icon} iconName={"editService"} size="normal"
                                         visualStyle="primary"/>
                                <p className={Styles.doctorCode}>{this.getSchedulingService(value.subject as SchedulingServiceId)?.code}</p>&nbsp;
                                <p title={this.getSchedulingService(value.subject as SchedulingServiceId)?.name}
                                   className={Styles.serviceText}>{this.getSchedulingService(value.subject as SchedulingServiceId)?.name}</p>
                            </div>
                        </Ui.Flex.Item>
                        }
                        {value.subjectType === "ServiceRequest" &&
                        <Ui.Flex.Item xs={12}>
                            <div className={Styles.serviceField}>
                                <Ui.Icon className={Styles.icon} iconName={"stethoscope"} size="normal"
                                         visualStyle="primary"/>
                                <p className={Styles.doctorCode}>{value.serviceRequestIdentifier}</p>&nbsp;
                                <p title={this.getServiceRequest(value.serviceRequestDefinitionId)?.shortName}
                                   className={Styles.serviceText}>{this.getServiceRequest(value.serviceRequestDefinitionId)?.shortName}</p>
                            </div>
                        </Ui.Flex.Item>
                        }

                        <Ui.Flex.Item xs={6} className={Styles.locationField}>
                            {!isNullOrUndefined(value.pointOfCareId?.value) &&
                            <Ui.Icon className={Styles.icon}
                                     iconName={getIconNameByHealthcareProfessions(this.getHealthCareProfession(value.pointOfCareId))}
                                     size="normal" visualStyle="primary"/>}
                            <p title={this.getLocation(value.pointOfCareId)?.name}>{this.getLocation(value.pointOfCareId)?.shorthand}</p>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6} className={Styles.practitionerField}>
                            <Ui.Icon className={Styles.icon} iconName={"careType_familyDoctor"} size="normal"
                                     visualStyle="primary"/>
                            <p className={Styles.doctorCode}>{this.getPractitioner(value.practitionerId)?.code}</p>&nbsp;
                            <Ui.PersonNameLabel personName={this.getPractitioner(value.practitionerId)?.baseData.name}/>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <div key={value.from.unix()}>
                        <div className={Styles.dateField}>
                            <Ui.Icon className={Styles.icon} iconName="appointments" size="normal"
                                     visualStyle="primary"/>
                            <MomentLabel from={value.from} to={value.to}/>
                        </div>
                    </div>
                </div>
                }
            </>
        );
    }

    public render() {
        const {children, ...columnProps} = this.props;
        return (
            <DataGridColumn
                {...columnProps}
                onRenderCellValue={this.valueRenderer}
                showHint={false}
                hideOverflow
            />
        );
    }

}

export default connect(
    AppointmentBaseDataColumn,
    new DependencyAdapter<IAppointmentBaseDataColumnProps, IAppointmentBaseDataColumnDependencies>(c => {
        return {
            organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
            careReferenceDataStore: c.resolve("CareReferenceDataStore"),
            schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore")
        };
    })
);
