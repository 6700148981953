import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import FamilyDoctorDocumentMedicationFormulaItem from "./FamilyDoctorDocumentMediactionFormulaItem";
import FamilyDoctorDocumentMedicationReference from "./FamilyDoctorDocumentMedicationReference";

export default class FamilyDoctorDocumentMedicationFormula {
    @State.observable.ref public items: IObservableArray<FamilyDoctorDocumentMedicationFormulaItem> = State.observable([]);

    @State.action.bound
    public removeFamilyDoctorDocumentMedicationFormulaListItem(familyDoctorDocumentMedicationFormula: FamilyDoctorDocumentMedicationFormulaItem) {
        this.items = State.observable(this.items.filter(f => f !== familyDoctorDocumentMedicationFormula));
    }
}