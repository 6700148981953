import SelectBox from "@CommonControls/SelectBox";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import VendorId from "@Primitives/VendorId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { useAsyncMemo } from "@Toolkit/ReactClient/Common/Hooks";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import React from "react";

interface IVendorSelectorProps extends ISelectBoxBaseProps<VendorId> {
    autoSelectSingleItem?: boolean;
}

export default function VendorSelector(props: IVendorSelectorProps) {

    const { financeReferenceDataStore } = useDependencies(c => ({
        financeReferenceDataStore: c.resolve<FinanceReferenceDataStore>("FinanceReferenceDataStore"),
    }));
    
    const { value: options, isLoading } = useAsyncMemo<Array<ISelectBoxItem<VendorId>>>([], async () => {
        await financeReferenceDataStore.vendorMap.ensureLoadedAsync();
        return financeReferenceDataStore.vendorMap.items.map<ISelectBoxItem<VendorId>>(vi => ({
            value: vi.id,
            text: vi.name,
        }));
    }, []);

    return (
        <SelectBox 
            {...props}
            items={options}
            equalityComparer={ValueWrapper.equals}
            loading={isLoading}
            autoSelectSingleItem={props.autoSelectSingleItem}
        />
    );
}