import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import IWorklistRouteParams from "@HisPlatform/Application/Routes/IWorklistRouteParams";
import IUseCaseNavigatorParameters from "@PluginInterface/UseCases/IUseCaseNavigatorParameters";
import Route from "@Toolkit/ReactClient/Routing/Abstractions/Route";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import DashboardLayout from "@HisPlatformControls/DashboardLayout/DashboardLayout";
import StandaloneAppointmentRoutes from "@HisPlatform/Components/Pages/AppointmentPages/StandaloneAppointmentRoutes";
import { getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCases from "@Primitives/UseCases";
import OutpatientTreatmentRoutes from "@HisPlatform/Components/Pages/OutpatientTreatment/OutpatientTreatmentRoutes";
import PatientWorklistPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/PatientWorklist/PatientWorklistPanel";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

interface ISearchPatientPageDependencies {
    routingStore: GlobalRoutingStore;
    applicationContext: ApplicationContext;
}

interface ISearchPatientPageProps extends IRoutingFrameContentProps {
    _dependencies?: ISearchPatientPageDependencies;
}

@State.observer
class SearchPatientPage extends React.Component<ISearchPatientPageProps> {

    private routeReactionDisposer: () => void;
    @State.observable private quickSearchString: string = null;
    @State.observable private isLucky: boolean = false;
    @State.observable private restoreFilter: boolean = true;

    private clearFiltersEvent: TypedEvent = new TypedEvent();

    private get routingController(): IRoutingController<IWorklistRouteParams> {
        return this.props.routingController;
    }

    private get routingStore() {
        return this.props._dependencies.routingStore;
    }

    @State.action.bound
    private setQuickSearchString(newValue: string) {
        this.quickSearchString = newValue;
    }

    @State.action.bound
    private setIsLucky(newValue: boolean) {
        this.isLucky = newValue;
    }

    @State.action.bound
    private setRestoreFilter(newValue: boolean) {
        this.restoreFilter = newValue;
    }

    public componentDidMount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(new UseCaseIdentifier("PatientRegister_SearchPatient"));
        this.routeReactionDisposer = State.reaction(() => this.routingStore.currentQueryString, () => {
            this.setSmartSearch();
        }, {
            fireImmediately: true
        });
    }

    public componentWillUnmount() {
        this.routeReactionDisposer();
    }

    private setSmartSearch() {
        this.setQuickSearchString(null);
        if (this.routingStore.currentQueryString) {
            const params = new URLSearchParams(this.routingStore.currentQueryString);
            const searchValue = params.get("quickSearchValue");
            const isLuckyValue = params.get("luckyMode") === "true" ? true : false;
            const restoreFilter = params.get("restoreFilter") === "false" ? false : true;
            this.setQuickSearchString(searchValue);
            this.setIsLucky(isLuckyValue);
            this.setRestoreFilter(restoreFilter);
        }
    }

    @State.bound
    private onFilterStoreCreated() {
        this.clearFiltersEvent.emit();
    }

    @State.bound
    private navigate(useCase: UseCaseIdentifier,
        useCaseArguments: UseCaseArgument[],
        displayMode: UseCaseDisplayMode,
        routeFactory: (params: IUseCaseNavigatorParameters) => Route) {
        const route = routeFactory({
            useCase,
            useCaseArguments,
            displayMode,
            currentRoute: this.routingController.currentRoute
        });

        this.routingController.push(route);
    }

    @State.bound
    private navigateToNewPatient() {
        this.routingController.push(ApplicationRoutes.patient.makeRoute({
            patientId: "new",
            mode: "read-write",
            appointmentId: "null"
        }));
    }

    @State.bound
    public navigateToCreateNewAppointment() {
        this.routingController.push(
            StandaloneAppointmentRoutes.unregisteredPatientAppointments.makeRoute({
                appointmentId: "new"
            })
        );
    }

    @State.bound
    private navigateToEditUnregisteredPatientAppointment(appointment: any) {
        this.props.routingController.push(
            StandaloneAppointmentRoutes.unregisteredPatientAppointments.makeRoute({
                appointmentId: appointment.id.value
            })
        );
    }

    @State.bound
    private navigateToEditPatientAppointment(appointment: any) {
        this.props.routingController.push(
            StandaloneAppointmentRoutes.registeredPatientAppointments.makeRoute({
                patientId: appointment.patientId.value.toString(),
                careActivityId: appointment.careActivityId?.value,
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.editAppointmentFromList),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(appointment.id, "appointmentId")])
            }));
    }

    @State.bound
    private navigateToEditServiceRequest(appointment: any) {
        this.props.routingController.push(
            OutpatientTreatmentRoutes.servicesRequests.makeRoute({
                careActivityId: appointment.careActivityId,
                filter: appointment.filter,
                selectedId: appointment.serviceRequestId,
                mode: "read-only",
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.serviceRequestManagementViewServiceRequest),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(new ServiceRequestId(appointment.serviceRequestId), "serviceRequestId")])
            }));
    }

    public render() {
        return (
            <DashboardLayout>
                <LoadingBoundary>
                    <PatientWorklistPanel
                        navigate={this.navigate}
                        navigateToNewPatient={this.navigateToNewPatient}
                        smartSearch={this.quickSearchString}
                        luckyMode={this.isLucky}
                        restoreFilter={this.restoreFilter}
                        navigateToNewAppointment={this.navigateToCreateNewAppointment}
                        editPatientAppointment={this.navigateToEditPatientAppointment}
                        editUnregisteredPatientAppointment={this.navigateToEditUnregisteredPatientAppointment}
                        editPatientServiceRequest={this.navigateToEditServiceRequest}
                        mode={"List"}
                        clearFiltersEvent={this.clearFiltersEvent}
                        onFilterStoreCreated={this.onFilterStoreCreated}
                    />
                </LoadingBoundary>
            </DashboardLayout>
        );
    }
}

export default connect(
    withHisErrorBoundary(SearchPatientPage),
    new DependencyAdapter<ISearchPatientPageProps, ISearchPatientPageDependencies>(container => {
        return {
            routingStore: container.resolve("GlobalRoutingStore"),
            applicationContext: container.resolve("ApplicationContext")
        };
    })
);
