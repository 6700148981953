import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import PatientsCareActivityDetailView from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PatientsCareActivitiesPanel/PatientsCareActivityDetailView";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import PerformedServicesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/PerformedServices/PerformedServicesApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { nullFunction } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IPatientsCareActivityDetailsModalParams } from "./PatientsCareActivityDetailsModalParams";
import DocumentId from "@Primitives/DocumentId.g";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import IPatientCareActivitiesDataSource from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientCareActivitiesDataSource";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import CareActivityBaseDataApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityBaseData/CareActivityBaseDataApiAdapter";
import PatientApiAdapter2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/PatientApiAdapter2";
import ResourceId from "@Primitives/ResourceId.g";
import IPatientCareActivitiesTabRegistry from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabRegistry";
import _ from "@HisPlatform/Common/Lodash";

interface IPatientsCareActivityDetailsModalDependencies {
    localizationService: ILocalizationService;
    careActivityApiAdapter: CareActivityApiAdapter2;
    careActivityBaseDataApiAdapter: CareActivityBaseDataApiAdapter;
    performedServicesApiAdapter: PerformedServicesApiAdapter;
    patientApiAdapter: PatientApiAdapter2;
    financeReferenceDataStore: FinanceReferenceDataStore;
    authorizationService: AuthorizationService;
    patientCareActivitiesTabRegistry: IPatientCareActivitiesTabRegistry;
}

interface IPatientsCareActivityDetailsModalProps extends IModalComponentParams<void>, IPatientsCareActivityDetailsModalParams {
    _dependencies?: IPatientsCareActivityDetailsModalDependencies;
    dataSource: IPatientCareActivitiesDataSource;
}

@State.observer
class PatientsCareActivityDetailsModal extends React.Component<IPatientsCareActivityDetailsModalProps> {

    @State.observable private isModalDataLoading = false;
    @State.observable private index = 0;
    @State.observable.ref private availableStepTypes: string[] = [];
    @State.observable.ref private selectedDocumentId: DocumentId = null;

    @State.bound
    private localizeStepTypeName(stepType: string) {
        return this.props._dependencies.localizationService.localizeReferenceData(new ResourceId("PatientCareActivities.TabName." + stepType));
    }

    @State.action.bound
    private async loadAsync() {

        if (!this.props.selectedPatientsCareActivityStore?.careActivityId) {
            return;
        }

        this.isModalDataLoading = true;

        const loadStepTypesPromise = this.getAvailableStepTypesAsync(this.props.selectedPatientsCareActivityStore);

        const [types] = await Promise.all([
            loadStepTypesPromise
        ]);

        State.runInAction(() => {
            this.availableStepTypes = types;

            this.index = 0;
            this.isModalDataLoading = false;
        });
    }

    @State.action.bound
    private async getAvailableStepTypesAsync(store: PatientsCareActivityStore) {
        if (!store.careActivityId) {
            return [];
        }

        const componentServices = this.props._dependencies.patientCareActivitiesTabRegistry.getAllComponentServices();

        const availableStepTypes: { tabName: string, order: number }[] = [];

        for (const item of componentServices) {
            const isAllowed = await item.service.isTabAllowedAsync(store.careActivityId, store.organizationUnitId);
            if (isAllowed) {
                availableStepTypes.push({ tabName: item.tabName, order: item.order });
            }
        }

        return _.orderBy(availableStepTypes, i => i.order).map(i => i.tabName) ;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }

    @State.bound
    private async initializeAsync() {
        await this.loadAsync();

        if (this.props.showPrimaryDocument) {
            this.loadPrimaryDocument();
        }
    }

    @State.bound
    private loadPrimaryDocument() {
        const indexOfDocumentStep = this.availableStepTypes.indexOf("Documents");

        if (this.props.selectedPatientsCareActivityStore.primaryDocumentId) {
            this.setIndex(indexOfDocumentStep);
            if (this.props.selectedPatientsCareActivityStore && this.props.selectedPatientsCareActivityStore.primaryDocumentId) {
                this.setSelectedDocumentId(this.props.selectedPatientsCareActivityStore.primaryDocumentId);
            }
        }
    }

    @State.action.bound
    public componentDidUpdate(prevProps: Readonly<IPatientsCareActivityDetailsModalProps>): void {
        if (!prevProps.selectedPatientsCareActivityStore && this.props.selectedPatientsCareActivityStore && this.props.selectedPatientsCareActivityStore.careActivityId) {
            dispatchAsyncErrors(this.loadAsync(), this);
        } else if (prevProps.selectedPatientsCareActivityStore && prevProps.selectedPatientsCareActivityStore.careActivityId.value !== this.props.selectedPatientsCareActivityStore.careActivityId.value) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.action.bound
    private setIndex(index: number) {
        this.index = index;
    }

    @State.action.bound
    private setSelectedDocumentId(newValue: DocumentId) {
        this.selectedDocumentId = newValue;
    }

    @State.action.bound
    private onModalClose() {
        this.props.onClose(null);
    }

    public render() {
        return (
            <Ui.Modal
                title={StaticProductivityResources.Widgets.EHRWidget.Title}
                isOpen
                size="fullscreen"
                closeButton
                onClose={this.onModalClose}>
                <Ui.Modal.Body>
                    <PatientsCareActivityDetailView
                        patientId={this.props.patientId}
                        activeIndex={this.index}
                        availableStepTypes={this.availableStepTypes}
                        isDetailLoading={this.isModalDataLoading}
                        localizeStepTypeName={this.localizeStepTypeName}
                        onIndexSelected={this.setIndex}
                        resetSelectedCareActivity={nullFunction}
                        selectedCareActivity={this.props.selectedPatientsCareActivityStore}

                        selectedDocumentId={this.selectedDocumentId}
                        onSelectedDocumentIdChange={this.setSelectedDocumentId}

                        dataSource={this.props.dataSource}
                    />
                </Ui.Modal.Body>
            </Ui.Modal>
        );
    }
}

export default connect(
    PatientsCareActivityDetailsModal,
    new DependencyAdapter<IPatientsCareActivityDetailsModalProps, IPatientsCareActivityDetailsModalDependencies>(c => ({
        careActivityApiAdapter: c.resolve("CareActivityApiAdapter2"),
        careActivityBaseDataApiAdapter: c.resolve("CareActivityBaseDataApiAdapter"),
        localizationService: c.resolve("ILocalizationService"),
        performedServicesApiAdapter: c.resolve("PerformedServicesApiAdapter"),
        financeReferenceDataStore: c.resolve("FinanceReferenceDataStore"),
        patientApiAdapter: c.resolve("PatientApiAdapter2"),
        authorizationService: c.resolve("AuthorizationService"),
        patientCareActivitiesTabRegistry: c.resolve("IPatientCareActivitiesTabRegistry")
    }))
);
