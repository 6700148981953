import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import * as Ui from "@CommonControls";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import {dispatchAsyncErrors} from "@Toolkit/CommonWeb/AsyncHelpers";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import ProfessionalExamStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/ProfessionalExamStore";
import ProfessionalExamId from "@Primitives/ProfessionalExamId.g";

interface IProfessionalExamSelectBoxDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IProfessionalExamSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IProfessionalExamSelectBoxDependencies;
    filteredIds?: ProfessionalExamId[];
}

@State.observer
class ProfessionSelectBox extends React.Component<IProfessionalExamSelectBoxProps> {
    @State.computed
    private get items(): Array<ISelectBoxItem<ProfessionalExamId>> {
        const items = this.props._dependencies.organizationReferenceDataStore.professionalExamMap.items;

        if (this.props.filteredIds && this.props.filteredIds.length > 0) {
            const filteredIdValues = this.props.filteredIds.map(i => i.value);
            const filteredItems = items.filter(i => filteredIdValues.includes(i.id.value));
            return filteredItems.map(this.toSelectBoxItem);
        }

        return items.map(this.toSelectBoxItem);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private async loadAsync() {
        await this.props._dependencies.organizationReferenceDataStore.professionalExamMap.ensureAllLoadedAsync();
    }

    @State.bound
    private toSelectBoxItem(item: ProfessionalExamStore) {
        return {
            text: item.description,
            value: item.id
        } as ISelectBoxItem<ProfessionalExamId>;
    }

    public render() {
        return (
            <Ui.SelectBox
                {...this.props}
                loading={!this.items}
                items={this.items}
            />
        );
    }
}

export default connect(
    ProfessionSelectBox,
    new DependencyAdapter<IProfessionalExamSelectBoxProps, IProfessionalExamSelectBoxDependencies>(container => {
        return {
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore")
        };
    })
);
