import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import IUserGroupManagementRouteParams from "@HisPlatform/Application/Routes/IUserGroupManagementRouteParams";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import UserGroupId from "@Primitives/UserGroupId.g";
import UserGroupManagementPanel from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserGroupManagementPanel/UserGroupManagementPanel";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";


interface IUserGroupManagementPageDependencies {
}

interface IUserGroupManagementPageProps extends IRoutingFrameContentProps {
    _dependencies?: IUserGroupManagementPageDependencies;
}

@State.observer
class UserGroupManagementPage extends React.Component<IUserGroupManagementPageProps> {
    private get routeParams(): IUserGroupManagementRouteParams {
        return (this.props.routingController.currentRoute.parameters as IUserGroupManagementRouteParams);
    }

    @State.bound
    private updateRoute(userGroupId: string, newTab: string) {
        this.props.routingController.replace(ApplicationRoutes.userGroupManagement.makeRoute({userGroupId: userGroupId, tab: newTab}));
    }

    @State.bound
    private backToPreviousPage() {
        this.props.routingController.goBack();
    }

    public render() {
        return (
            <LoadingBoundary>
                <UserGroupManagementPanel
                    activeTab={this.routeParams?.tab}
                    onNavigationChanged={this.updateRoute}
                    userGroupId={this.routeParams?.userGroupId && new UserGroupId(this.routeParams.userGroupId)}
                    backToPreviousPage={this.backToPreviousPage}
                />
            </LoadingBoundary>
        );
    }
}

export default UserGroupManagementPage;
