import State from "@Toolkit/ReactClient/Common/StateManaging";
import PractitionerId from "@Primitives/PractitionerId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";

export default class AppointmentFilterStore {
    
    @State.observable.ref public doctorIds: PractitionerId[] = null;
    @State.observable.ref public pointOfCareIds: PointOfCareId[] = null;
    @State.observable.ref public schedulingServiceId: SchedulingServiceId = null;
    
    @State.action.bound
    public setDoctorIds(doctorIds: PractitionerId[]) {
        this.doctorIds = doctorIds;
    }

    @State.action.bound
    public setPointOfCareIds(pointOfCareIds: PointOfCareId[]) {
        this.pointOfCareIds = pointOfCareIds;
    }
   
    @State.action.bound
    public setSchedulingServiceId(schedulingServiceId: SchedulingServiceId) {
        this.schedulingServiceId = schedulingServiceId;
    }
}
