import { IValidationProblemParameterMapper } from "@Toolkit/CommonWeb/ApiAdapter/IValidationProblemParameterMapper";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import PasswordComplexityProblemType from "@Primitives/PasswordComplexityProblemType";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import Di from "@Di";

@Di.injectable()
export default class UserValidationProblemMapper implements IValidationProblemParameterMapper {

    constructor(
        @Di.inject("ILocalizationService") private localizationService: ILocalizationService
    ) { }

    public collectValidationProblemParameterReferenceData(vp: IClientValidationProblem) {
        return;
    }
    
    public loadCollectedValidationProblemParameterReferenceDataAsync(): Promise<void> {
        return Promise.resolve();
    }
    
    public resolveValidationProblemParameters(vp: IClientValidationProblem): { [id: string]: string; } {
        const passwordComplexityProblemType = vp.parameters?.PasswordComplexityProblemType as unknown as PasswordComplexityProblemType;
        return {
            PasswordComplexityProblemType: this.localizationService.localizeEnum(PasswordComplexityProblemType[passwordComplexityProblemType], "PasswordComplexityProblemType")?.Name
        };
    }
}