import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import NumericRangeProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/NumericRangeProperty";
import NumericRange from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/NumericRange";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface INumericRangePropertyEditorProps {
    row: NumericRangeProperty;
}

@State.observer
export class NumericRangePropertyEditor extends React.Component<INumericRangePropertyEditorProps> {

    @State.computed private get currentValue() {
        return this.props.row.getValue(this.props.row.isTemporal ? LocalDate.today() : undefined);
    }

    public render() {
        return (
            <Ui.Flex>
                <Ui.Flex.Item sm={6}>
                    <Ui.NumberBox
                    value={this.currentValue?.from}
                    onChange={this.setCurrentVersionFromValue}
                    automationId={this.props.row.name + "_fromValue"} />
                </Ui.Flex.Item>
                <Ui.Flex.Item sm={6}>
                    <Ui.NumberBox                    
                    value={this.currentValue?.to}
                    onChange={this.setCurrentVersionToValue}
                    automationId={this.props.row.name + "_toValue"} />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.bound
    private setCurrentVersionFromValue(from: number) {
        this.props.row.replaceVersion(new NumericRange(from, this.currentValue.to), this.props.row.isTemporal ? LocalDate.today() : undefined);
    }

    @State.bound
    private setCurrentVersionToValue(to: number) {
        this.props.row.replaceVersion(new NumericRange(this.currentValue.from, to), this.props.row.isTemporal ? LocalDate.today() : undefined);
    }
}