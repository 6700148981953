import React, { CSSProperties } from "react";
import Styles from "./Modal.less";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
import ModalStore from "@CommonControls/Modal/ModalStore";
import ModalButton from "@CommonControls/Modal/ModalButton";
import ModalContext from "./ModalContext";
import ModalLabel from "./ModalLabel";
import * as Ui from "@CommonControls";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Icon, { iconNameType } from "@CommonControls/Icon";
import ReactDOM from "react-dom";

export type ModalSize = "fullscreen" | "normal" | "compact" | "tiny";

export interface IModalProps {
    closeOnEscape?: boolean;
    closeOnOutsideClick?: boolean;
    title?: React.ReactNode;
    size?: ModalSize;
    isOpen?: boolean;
    onClose?: () => void;
    onOpen?: () => void;
    triggerElement?: React.ReactElement<any>;
    automationId?: string;
    closeButton?: boolean;
    sideGaps?: number;
    renderHeaderLabel?: () => React.ReactNode;
    renderContextHeader?: () => React.ReactNode;
    isHeaderPrimary?: boolean;
    fixedHeight?: number | string;
    maxHeight?: number | string;
    iconName?: iconNameType;
    toolbar?: () => React.ReactNode;
    index?: number;
    topMost?: boolean;
}

@State.observer
export default class Modal extends React.Component<IModalProps, undefined> {

    
    public static Body = ModalBody;
    public static Footer = ModalFooter;
    public static Button = ModalButton;
    public static Label = ModalLabel;
    

    private readonly store: ModalStore;
    private containerRef = React.createRef<HTMLDivElement>();

    public static defaultProps: Partial<IModalProps> = {
        closeOnEscape: true,
        closeOnOutsideClick: true,
        size: "normal"
    };

    constructor(props: IModalProps) {
        super(props);

        this.store = new ModalStore(
            () => {
                if (this.props.onOpen) {
                    this.props.onOpen();
                }
            },
            () => {
                if (this.props.onClose) {
                    this.props.onClose();
                }
            });

        if (this.props.isOpen === undefined) {
            this.getOpenState = () => this.store.isOpen;
        }
    }

    public componentDidMount() {
        this.containerRef.current?.focus();
    }

    public componentDidUpdate(prevProps: IModalProps) {
        if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
            this.containerRef.current?.focus();
        }
    }

    private getOpenState = () => this.props.isOpen;

    @State.bound
    private suppressClickHandler(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
    }

    @State.bound
    public outsideClickHandler(e: React.MouseEvent<HTMLDivElement>) {
        if (this.props.closeOnOutsideClick === true) {
            this.store.close();
        }
        e.stopPropagation();
    }

    @State.bound
    private closeButtonHandler() {
        this.store.close();
    }

    @State.bound
    private onKeyDownHandler(event: React.KeyboardEvent<HTMLDivElement>) {
        if (this.props.closeOnEscape === true && event.key === "Escape") {
            this.store.close();
        }
        event.stopPropagation();
    }

    private getContentClass() {
        let style = "";
        switch (this.props.size) {
            case "normal":
                style = Styles.modalContentNormal; break;
            case "fullscreen":
                style = Styles.modalContentFullscreen; break;
            case "compact":
                style = Styles.modalContentCompact; break;
            case "tiny":
                style = Styles.modalContentTiny; break;
        }

        return combineClasses(style, !!this.props.index && Styles.nested, !!this.props.topMost && Styles.topMost);
    }

    private getCloseButtonVisualStyle() {
        if (this.props.isHeaderPrimary && this.props.isHeaderPrimary === true) {
            return "primary";
        }
        return "flat";
    }

    @State.computed private get commonClassName() {
        return combineClasses(
            this.props.isHeaderPrimary ? Styles.modalHeaderPrimary : Styles.modalHeader,
            this.props.renderContextHeader ? Styles.modalHeaderWithContextHeader : undefined,
        );
    }

    @State.computed private get containerClassName() {
        return combineClasses(Styles.modal, !!this.props.index && Styles.nested, !!this.props.topMost && Styles.topMost);
    }

    public render() {
        return (
            <>
                {this.props.triggerElement && React.cloneElement(this.props.triggerElement, {
                    onClick: this.store.open
                })}
                {this.getOpenState() && ReactDOM.createPortal(
                    <>
                        <div
                            ref={this.containerRef}
                            data-automation-id={this.props.automationId || "modal"}
                            role="modal"
                            className={this.containerClassName}
                            tabIndex={0}
                            onKeyDown={this.onKeyDownHandler}
                            onClick={this.outsideClickHandler}
                            style={{ padding: this.props.sideGaps ? `0 ${this.props.sideGaps}px` : undefined }}
                            data-index={this.props.index}
                        />
                        <div
                            className={this.getContentClass()}
                            onClick={this.suppressClickHandler}
                            style={{ height: this.props.fixedHeight, maxHeight: this.props.maxHeight }}
                            data-index={this.props.index}>
                            <ModalContext.Provider value={this.store.context}>
                                {this.props.renderContextHeader &&
                                    <div className={Styles.contextHeaderContainer} data-automation-id="__modalHeader">
                                        <div className={Styles.contextHeader} >{this.props.renderContextHeader()}</div>
                                        <div className={Styles.closeButton} data-automation-id="__contextHeaderCloseButton"><Ui.Icon iconName="remove" size="normal" visualStyle="white" onClick={this.closeButtonHandler} /></div>
                                    </div>}
                                {this.props.title !== undefined && (
                                    <div className={this.commonClassName} data-automation-id="__modalHeader">
                                        <div className={Styles.title}>
                                            <div className={Styles.titleAndLabels}>
                                                <div className={Styles.onlyTitle}>
                                                    {this.props.iconName ? <div className={Styles.titleAndIcon}><Ui.Icon iconName={this.props.iconName} /></div> : undefined}
                                                    {this.props.title}
                                                </div>
                                                {this.props.renderHeaderLabel &&
                                                    <div className={Styles.labelContainer}>
                                                        {this.props.renderHeaderLabel()}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {this.props?.toolbar && this.props.toolbar()}
                                        <div className={Styles.separator} />
                                        {this.props.closeButton && <ModalButton visualStyle={this.getCloseButtonVisualStyle()} closeModalOnClick iconName="remove" size="compact" automationId="__closeModalButton" />}
                                    </div>
                                )}
                                {this.props.children}
                            </ModalContext.Provider>
                        </div>
                    </>
                    , document.getElementById("__modal-portal"))}
            </>
        );
    }
}