import moment from "moment";
import Measurement from "./Measurement";

export default class Device {
    constructor(
        public readonly name: string,
        public readonly deviceType: string,
        public readonly lastMeasuredAt: moment.Moment,
        public readonly measurements: Measurement[]) {
    }
}
