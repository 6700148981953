import RequestStatus from "./RequestStatus";
import IOperationInfo from "./IOperationInfo";
import IBusinessError from "@Toolkit/CommonWeb/Model/IBusinessError";

export default class TechnicalErrorOperationInfo implements IOperationInfo {

    public get requestStatus(): RequestStatus { return RequestStatus.TechnicalError; }
    public get hasTechnicalError(): boolean { return true; }
    public get businessError(): IBusinessError { return null; }
    public get isPersisted(): boolean { return false; }

    constructor(
        public readonly operationName?: string,
        public readonly operationId?: string
    ) {}

}