import React from "react";
import OrganizationUnitDefinitionId from "@Primitives/OrganizationUnitDefinitionId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";
import OrganizationUnitDefinitionSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/OrganizationUnitDefinitionSelectBox/OrganizationUnitDefinitionSelectBox";
import StaticWebAppResources from "@StaticResources";
import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";
import ClientSideValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidationBoundary";
import { IOrganizationUnitDefinitionSelectorDialogParams, IOrganizationUnitDefinitionSelectorDialogResult } from "./OrganizationUnitDefinitionSelectorDialogParams";
import {IModalComponentParams} from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";

interface IOrganizationUnitDefinitionSelectorDialogProps extends IModalComponentParams<IOrganizationUnitDefinitionSelectorDialogResult>, IOrganizationUnitDefinitionSelectorDialogParams {
}

@State.observer
export default class OrganizationUnitDefinitionSelectorDialog extends React.Component<IOrganizationUnitDefinitionSelectorDialogProps> {
    @State.observable.ref private value: OrganizationUnitDefinitionId = null;

    private readonly clientSideValidator = new ClientSideValidator({
        OrganizationUnitDefinitionSelector: {
            OrganizationUnitDefinitionSelectBox: {
                isRequired: true
            }
        }}, {
        shouldBeFilled: StaticOrganizationResources.Validation.Required
    });

    @State.action.bound
    private setValue(value: OrganizationUnitDefinitionId) {
        this.value = value;
    }

    @State.action.bound
    private onCancel() {
        this.props.onClose({result: null});
    }

    @State.action.bound
    private onOk() {
        if (this.clientSideValidator.isValid()) {
            this.props.onClose({result: this.value});
        }
    }

    @State.bound
    private renderCancelButton() {
        return (
            <Ui.Button visualStyle="link" onClick={this.onCancel} automationId="cancelButton">{StaticWebAppResources.Common.DialogButton.Cancel}</Ui.Button>
        );
    }

    @State.bound
    private renderOkButton() {
        return (
            <Ui.Button visualStyle="primary" onClick={this.onOk} automationId="okButton">{StaticWebAppResources.Common.DialogButton.Ok}</Ui.Button>
        );
    }

    public render() {
        return (
            <ClientSideValidationBoundary validator={this.clientSideValidator} entityTypeName={"OrganizationUnitDefinitionSelector"}>
                <Ui.Modal
                    isOpen
                    title={StaticOrganizationResources.OrganizationUnitPanel.ChooseDefinitionModalTitle}
                    isHeaderPrimary
                    closeOnOutsideClick={false}
                    closeOnEscape={false}
                    onClose={this.onCancel}
                >
                    <Ui.Modal.Body>
                        <p>{StaticOrganizationResources.OrganizationUnitPanel.Messages.ChooseDefinitionMessage}</p>
                        <OrganizationUnitDefinitionSelectBox
                            definitionIds={this.props.organizationUnitDefinitionIds}
                            value={this.value}
                            onChange={this.setValue}
                            propertyIdentifier={"OrganizationUnitDefinitionSelectBox"}
                        />
                    </Ui.Modal.Body>
                    <Ui.Modal.Footer
                        left={this.renderCancelButton()}
                        right={this.renderOkButton()}
                    />
                </Ui.Modal>
            </ClientSideValidationBoundary>
        );
    }
}