import React from "react";
import Styles from "./ListPanel.less";
import { Button, NoItemsMessage, FieldValidationResult } from "@CommonControls";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import IIndexedItem from "@Toolkit/ReactClient/Components/ListPanel/IIndexedItem";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IListPanelViewProps {
    propertyIdentifier: string;

    items: Array<IIndexedItem<any>>;
    itemUnderEdit: any;
    renderItemViewer: (item: any, index: number) => React.ReactNode;
    renderItemEditor: (item: any, index: number) => React.ReactNode;
    getItemKey: (item: any, index: number) => string | number;
    onCreateItemAsync: () => Promise<void>;
    onConfirmNewItemAddition: (item: any) => void;
    onCancelNewItemAdditionAsync: () => Promise<void>;

    onEditItemAsync: (item: any) => Promise<void>;
    onConfirmItemUpdate: () => void;
    onCancelItemUpdate: () => void;
    newItem: any;

    onRemoveItemAsync: (item: any) => Promise<void>;

    actionButtonsVerticalLayout: boolean;
    actionButtonsLabeledInputOffset: number;

    addButtonText: string;
    noItemsMessage: string;
    alwaysEdit: boolean;
    automationId?: string;
    validationResults?: IClientValidationProblem[];
    title?: string;
    isCompactEmptyState?: boolean;
    allowCreatingNew: boolean;
    observerItems?: boolean;
    isReadOnly: boolean;
    hasValidationBoundary: boolean;
    style: React.CSSProperties;
}

const confirmNewItemHandlerFactory = (item: any, props: IListPanelViewProps) => {
    return () => props.onConfirmNewItemAddition(item);
};

const editItemHandlerFactory = (item: any, props: IListPanelViewProps) => {
    return () => props.onEditItemAsync(item);
};

const removeItemHandlerFactory = (item: any, props: IListPanelViewProps) => {
    return () => props.onRemoveItemAsync(item);
};

const renderRowButtons = (props: IListPanelViewProps, item: any) => {
    if (props.alwaysEdit) {
        return (
            <>
                <Button iconName="trash" visualStyle="negative" size="compact" onClickAsync={removeItemHandlerFactory(item, props)} automationId="__deleteItem" />
            </>
        );
    }

    if (props.itemUnderEdit === item) {
        return (
            <>
                <Button iconName="check" visualStyle="positive" size="compact" onClick={props.onConfirmItemUpdate} automationId="__confirmEdit" />
                <Button iconName="remove" visualStyle="negative" size="compact" onClick={props.onCancelItemUpdate} automationId="__cancelEdit" />
                <Button iconName="trash" visualStyle="negative" size="compact" onClickAsync={removeItemHandlerFactory(item, props)} automationId="__deleteItem" />
            </>
        );
    }

    if (props.newItem) {
        return null;
    }

    return (
        <>
            <Button iconName="pen" onClickAsync={editItemHandlerFactory(item, props)} visualStyle="secondary" size="compact" automationId="__editItem" />
            <Button iconName="trash" visualStyle="negative" size="compact" onClickAsync={removeItemHandlerFactory(item, props)} automationId="__deleteItem" />
        </>
    );
};

const getRowClassName = (isUnderEdit: boolean, alwaysEdit: boolean) => {
    if (isUnderEdit) {
        return combineClasses(Styles.row, Styles.itemUnderEditRow);
    } else if (alwaysEdit) {
        return combineClasses(Styles.row, Styles.itemUnderEditRow);
    }
    return Styles.row;
};

const renderItemViewerOrReadonlyEditor = (props: IListPanelViewProps, item: any, index: number) => {
    if (props.renderItemViewer) {
        return props.renderItemViewer(item, index);
    } else {
        return (
            <ReadOnlyContext.Provider value={true}>
                {props.renderItemEditor(item, index)}
            </ReadOnlyContext.Provider>
        );
    }
};

const renderItemBody = (props: IListPanelViewProps, bodyRenderer: () => React.ReactNode, indexedItem: IIndexedItem<any>) => {
    if (props.hasValidationBoundary) {
        return (
            <ValidationBoundary pathPrefix={`${props.propertyIdentifier}[${indexedItem.index}]`}>
                {props.observerItems ? (
                    <State.Observer>
                        {bodyRenderer as any}
                    </State.Observer>
                ) : bodyRenderer()}
            </ValidationBoundary>
        );
    }

    return (
        <>
            {props.observerItems ? (
                <State.Observer>
                    {bodyRenderer as any}
                </State.Observer>
            ) : bodyRenderer()}
        </>
    );
};

const renderItems = (props: IListPanelViewProps) => (
    props.items.map((indexedItem, displayIndex) => {
        const bodyRenderer = () => (props.itemUnderEdit === indexedItem || props.alwaysEdit) ?
            props.renderItemEditor(indexedItem.item, indexedItem.index) :
            renderItemViewerOrReadonlyEditor(props, indexedItem.item, indexedItem.index);

        return bodyRenderer && (
            <div className={getRowClassName(props.itemUnderEdit === indexedItem, props.alwaysEdit)} key={props.getItemKey(indexedItem.item, displayIndex)} data-automation-id={"__listItemPanel__" + displayIndex}>
                <div className={Styles.itemContainer}>
                    {renderItemBody(props, bodyRenderer, indexedItem)}
                </div>
                <div
                    className={props.actionButtonsVerticalLayout ? `${Styles.actionContainer} ${Styles.verticalActions}` : Styles.actionContainer}
                    style={{ paddingTop: props.actionButtonsVerticalLayout ? undefined : props.actionButtonsLabeledInputOffset }}
                >
                    {!props.isReadOnly && renderRowButtons(props, indexedItem)}
                </div>
            </div>
        );
    })
);

const hasItem = (props: IListPanelViewProps) => {
    return props.items && props.items.length > 0;
};

const renderAddCircleButton = (props: IListPanelViewProps) => props.allowCreatingNew && (
    <Button
        iconName={hasItem(props) ? "circleAdd" : "plus"}
        visualStyle={hasItem(props) ? "link" : "primary"}
        onClickAsync={props.onCreateItemAsync}
        text={props.addButtonText}
        automationId="__addNewItemButton"
    />
);

const renderNoItems = (props: IListPanelViewProps) => (
    !props.newItem && !props.isCompactEmptyState && (
        <NoItemsMessage message={props.noItemsMessage}>
            {props.validationResults && (
                <FieldValidationResult showMultipleProblems problems={props.validationResults} />
            )}
            {!props.isReadOnly && renderAddCircleButton(props)}
        </NoItemsMessage>
    ));

const renderCompactNoItems = (props: IListPanelViewProps) => (
    !props.newItem && props.isCompactEmptyState && (
        <Button
            iconName="circleAdd"
            visualStyle="link"
            onClickAsync={props.onCreateItemAsync}
            text={!props.title && props.addButtonText}
            automationId="__addNewItemCompactButton"
        />
    )
);


const ListPanelView: React.SFC<IListPanelViewProps> = props => {
    const hasItems = hasItem(props);
    return (
        <div className={Styles.container} data-automation-id={props.automationId || undefined} style={props.style}>
            {props.title && <h4 className={Styles.title}>{props.title}{!hasItems && renderCompactNoItems(props)}</h4>}
            {!props.title && props.isCompactEmptyState && !hasItems && renderCompactNoItems(props)}
            {hasItems ? renderItems(props) : renderNoItems(props)}
            {props.newItem && (
                <div className={combineClasses(Styles.row, Styles.newItemRow)} key="new_item">
                    <div className={Styles.itemContainer}>
                        {props.renderItemEditor(props.newItem, props.items.length)}
                    </div>
                    <div
                        className={props.actionButtonsVerticalLayout ? `${Styles.actionContainer} ${Styles.verticalActions}` : Styles.actionContainer}
                        style={{ paddingTop: props.actionButtonsVerticalLayout ? undefined : props.actionButtonsLabeledInputOffset }}
                    >
                        {!props.alwaysEdit && !props.isReadOnly && (
                            <>
                                <Button iconName="check" visualStyle="positive" size="compact" onClick={confirmNewItemHandlerFactory(props.newItem, props)} automationId="addNewItemButton" />
                                <Button iconName="remove" visualStyle="negative" size="compact" onClickAsync={props.onCancelNewItemAdditionAsync} automationId="cancelAddNewItemButton" />
                            </>
                        )}
                    </div>
                </div>
            )}
            {(!props.newItem || props.alwaysEdit) && !props.isReadOnly && (
                <div className={Styles.addButtonContainer}>
                    {(hasItems) && renderAddCircleButton(props)}
                </div>
            )}
            {props.validationResults && (
                <FieldValidationResult showMultipleProblems problems={props.validationResults} />
            )}
        </div>
    );
};

export default ListPanelView;
