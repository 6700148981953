import State from "@Toolkit/ReactClient/Common/StateManaging";
import NameStore from "@Primitives/NameStore";
import GenderId from "@Primitives/GenderId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

export default class UnregisteredPatientStore {
    @State.observable public nameStore: NameStore = new NameStore();
    @State.observable public phoneNumber: string = null;
    @State.observable public email: string = null;
    @State.observable public address: string = null;
    @State.observable public insuranceDocumentNumber: string = null;
    @State.observable public note: string = null;
    @State.observable public genderId: GenderId = null;
    @State.observable public birthDate: LocalDate = null;
    constructor() {
        this.nameStore = new NameStore();
    }

    @State.action.bound
    public setPhoneNumber(value: string) {
        this.phoneNumber = value;
    }

    @State.action.bound
    public setEmail(value: string) {
        this.email = value;
    }

    @State.action.bound
    public setAddress(value: string) {
        this.address = value;
    }

    @State.action.bound
    public setInsuranceDocumentNumber(value: string) {
        this.insuranceDocumentNumber = value;
    }

    @State.action.bound
    public setNote(value: string) {
        this.note = value;
    }
    
    @State.action.bound
    public setGender(value: GenderId) {
        this.genderId = value;
    }
    @State.action.bound
    public setBirthDate(value: LocalDate) {
        this.birthDate = value;
    }
}