import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import PatientId from "@Primitives/PatientId.g";
import { IEHRRouteParams } from "@HisPlatform/Application/Routes/IEHRRouteParams";
import Route from "@Toolkit/ReactClient/Routing/Abstractions/Route";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DocumentId from "@Primitives/DocumentId.g";
import DocumentManagementMasterDetailPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/DocumentManagementMasterDetailPanel/DocumentManagementMasterDetailPanel";
import PatientEHRRoutes, { IPatientEhrDocumentListRouteParams } from "@HisPlatform/Components/Pages/Patient/PatientEHRRoutes";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import { isNullOrUndefined, nullFunction } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { ScreenStateContextProvider } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowPatientRelatedDocumentListScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientRelatedDocumentListScreenAction.g";
import PatientScreenContextData from "@HisPlatform/Services/Definition/ActionProcessing/PatientScreenContextData";
import ScreenContextWrapper from "@Toolkit/ReactClient/Components/Screen/ScreenContextWrapper";

@State.observer
export default class PatientsDocumentsPage extends React.Component<IRoutingFrameContentProps> {

    private get patientId() {
        return new PatientId((this.props.routingController.currentRoute.parameters as IEHRRouteParams).patientId);
    }

    private get selectedDocumentId() {
        const rawId = (this.props.routingController.currentRoute as Route<IPatientEhrDocumentListRouteParams>)?.parameters?.selectedDocumentId;
        return rawId;
    }

    @State.bound
    private selectedDocumentChanged(documentId: string) {
        this.props.routingController.replace(PatientEHRRoutes.documents.makeRoute({
            patientId: this.patientId.value,
            selectedDocumentId: documentId,
            mode: "read-write",
            appointmentId: "null"
        }));
    }

    private getExtensionProps(): any {
        return {
            patientId: this.patientId,
            selectedDocumentId: this.selectedDocumentId,
            onSelectedDocumentIdChange: this.selectedDocumentChanged
        };
    }

    @State.computed
    private get selectedHisDocumentId() {
        if (isNullOrUndefined(this.selectedDocumentId)) {
            return null;
        }

        return this.selectedDocumentId?.charAt(0) === "E" ? null : new DocumentId(this.selectedDocumentId.slice(1));
    }

    @State.bound
    private onSelectedHisDocumentIdChange(newId: DocumentId) {
        const createdId = newId ? `H${newId.value}` : null;
        this.selectedDocumentChanged(createdId);
    }

    private action = new ShowPatientRelatedDocumentListScreenAction(
        ScreenDisplayMode.Full,
        this.patientId,
        false);

    private screenContextData = new PatientScreenContextData(this.patientId);

    public render() {
        return (
            <HisPlatformExtensionPoint extensionProps={this.getExtensionProps()}
                type="ehrDocumentsExtension"
            >
                <ScreenStateContextProvider
                    action={this.action}
                    screenState={null}
                    displayMode={this.action.displayMode}
                    screenContextData={this.screenContextData}
                    key="none"
                    onCancelled={nullFunction}
                    onSavedNew={nullFunction}
                >
                    <ScreenContextWrapper>
                        <DocumentManagementMasterDetailPanel
                            selectedDocumentId={this.selectedHisDocumentId}
                            onSelectedDocumentIdChange={this.onSelectedHisDocumentIdChange}
                            readOnly
                            careActivityRelatedDataOnly={false}
                            isCareActivityRelated={true}
                        />
                    </ScreenContextWrapper>
                </ScreenStateContextProvider>
            </HisPlatformExtensionPoint>
        );
    }
}