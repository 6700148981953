import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/Productivity/Api/Proxy.g";
import ConditionId from "@Primitives/ConditionId.g";
import PatientId from "@Primitives/PatientId.g";
import WorklistDefinitionCache from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/Worklist/WorklistDefinitionCache";
import { IResponseBase, BoundWorklistDefinitionDto } from "@HisPlatform/BoundedContexts/Productivity/Api/Proxy.g";

@Di.injectable()
export default class HunWorklistApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IHunWorklistPackageClient") private readonly apiClient: Proxy.IHunWorklistPackageClient,
        @Di.inject("WorklistDefinitionCache") private readonly worklistDefinitionCache: WorklistDefinitionCache
    ) {
        super();
    }

    private getWorklistDefinition(key: string, getter: () => Promise<IResponseBase & { definition?: BoundWorklistDefinitionDto }>) {
        return this.worklistDefinitionCache.getWorklistDefinitionAsync(key, getter);
    }

    public getContagiousPatientReportForPatientBoundWorklistDefinitionAsync(conditionId: ConditionId, patientId: PatientId) {
        return this.getWorklistDefinition(
            `ContagiousPatientReportForPatientBoundWorklist_${patientId.value}_${conditionId.value}`,
            () => this.apiClient.getContagiousPatientReportForPatientBoundWorklistDefinitionQueryAsync(CreateRequestId(), conditionId.value, patientId.value));
    }

    public getContagiousPatientReportForInstituteBoundWorklistDefinitionAsync() {
        return this.getWorklistDefinition(
            "ContagiousPatientReportForInstituteBoundWorklist",
            () => this.apiClient.getContagiousPatientReportForInstituteBoundWorklistDefinitionQueryAsync(CreateRequestId()));
    }

    public getPerformanceStatementCareActivityBoundWorklistDefinitionAsync() {
        return this.getWorklistDefinition(
            "PerformanceStatementCareActivityBoundWorklist",
            () => this.apiClient.getPerformanceStatementCareActivityBoundWorklistDefinitionQueryAsync(CreateRequestId()));
    }

    public getNeakPerformanceStatementBoundWorklistDefinitionAsync() {
        return this.getWorklistDefinition(
            "NeakPerformanceStatementBoundWorklist",
            () => this.apiClient.getPerformanceStatementBoundWorklistDefinitionQueryAsync(CreateRequestId()));
    }

    public getErrorListBoundWorklistDefinitionAsync() {
        return this.getWorklistDefinition(
            "ErrorListBoundWorklist",
            () => this.apiClient.getErrorListBoundWorklistDefinitionQueryAsync(CreateRequestId()));
    }

    public getPerformanceReportScorePlanBoundWorklistDefinitionAsync(isPermissionCheckOnly: boolean = false) {
        return this.getWorklistDefinition(
            "PerformanceReportScorePlanBoundWorklist",
            () => this.apiClient.getPerformanceReportScorePlanBoundWorklistDefinitionQueryAsync(CreateRequestId(), isPermissionCheckOnly));
    }
}