import DocumentManagementMasterDetailPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/DocumentManagementMasterDetailPanel/DocumentManagementMasterDetailPanel";
import MasterDetailMode from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Care/Enum/MasterDetailMode.g";
import { useScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import ShowCareActivityDocumentListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityDocumentListScreenAction.g";
import ShowReadOnlyCareActivityDocumentListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyCareActivityDocumentListScreenAction.g";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import DocumentId from "@Primitives/DocumentId.g";
import { useScreenStateContext } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import React, { useCallback, useMemo } from "react";

export default function CareActivityDocumentListScreen(props: IScreenPropsBase<ShowCareActivityDocumentListScreenAction | ShowReadOnlyCareActivityDocumentListScreenAction>) {

    const navigationContext = useScreenNavigationContext();
    const screenState = useScreenStateContext();

    const selectedDocumentId = useMemo(() => screenState.screenState === "null" ? null : DocumentId.fromUrl(screenState.screenState), [screenState.screenState]);
    const selectDocument = useCallback((newId: DocumentId) => {
        navigationContext.setPrimaryScreenState(newId?.toUrl() ?? "null");
    }, [navigationContext.setPrimaryScreenState]);

    return (
        <DocumentManagementMasterDetailPanel
            careActivityRelatedDataOnly={true}
            isCareActivityRelated={true}
            selectedDocumentId={selectedDocumentId}
            onSelectedDocumentIdChange={selectDocument}
            readOnly={props.action instanceof ShowReadOnlyCareActivityDocumentListScreenAction}
            hiddenMasterMode={props.action instanceof ShowCareActivityDocumentListScreenAction && props.action.masterDetailMode === MasterDetailMode.HiddenMaster}
        />
    );
}