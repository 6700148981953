import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import { Manager, Reference, Popper as ReactPopper } from "react-popper";
import { Placement } from "@Toolkit/ReactClient/Components/Tooltip";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import { Modifiers } from "popper.js";

export type Position = "top" | "right" | "bottom" | "left";

interface IPopperProps {
    style?: React.CSSProperties;
    className?: string;
    fade?: boolean;
    wrapperElementType?: keyof React.ReactHTML;
    tooltipContent?: React.ReactNode;
    tooltipPlacement?: Placement;
    showTimeout?: number;
    hideTimeout?: number;
    referenceElement?: IReferenceObject;
    isOpen?: boolean;
    tooltipStyle?: React.CSSProperties;
    arrowStyle?: React.CSSProperties;
    tooltipClassName?: string;
    flipBehavior?: Position[];

    onMouseEnter?: (e: React.MouseEvent<HTMLElement>) => void;
    onMouseLeave?: (e: React.MouseEvent<HTMLElement>) => void;
    onClick?: (e: React.MouseEvent) => void;
}

export interface IReferenceObject {
    clientHeight: number;
    clientWidth: number;

    getBoundingClientRect(): ClientRect;
}

interface IPopperState {
    isOpen: boolean;
    isLocked: boolean;
}

export default class Popper extends React.Component<IPopperProps, IPopperState> {

    public static defaultProps: Partial<IPopperProps> = {
        wrapperElementType: "span",
        tooltipPlacement: "bottom",
        showTimeout: 300,
        hideTimeout: 100,
        flipBehavior: ["top", "bottom", "left", "right"]
    };

    private modifiers: Modifiers = {
        preventOverflow: {
            boundariesElement: "viewport"
        },
        flip: {
            behavior: this.props.flipBehavior
        }
    };

    private showTimeout: number = null;
    private hideTimeout: number = null;

    constructor(props: IPopperProps) {
        super(props);

        this.state = {
            isOpen: isNullOrUndefined(props.isOpen) ? false : props.isOpen,
            isLocked: false
        };
    }

    public getContainerClass = () => {
        const classes = new CompositeClassName("tooltip");
        classes.addIf(this.props.fade, "fade");
        classes.add(this.props.tooltipClassName);
        return classes.classNames;
    };

    public componentDidUpdate(prevProps: IPopperProps) {
        if (!!prevProps.isOpen && !this.props.isOpen) {
            this.scheduleHide();
        } else if (!prevProps.isOpen && !!this.props.isOpen) {
            this.scheduleShow();
        }
    }

    public componentWillUnmount() {
        this.clearTimeouts();
    }

    @State.bound
    private mouseEnter(e: React.MouseEvent<HTMLElement>) {
        this.scheduleShow();
        this.props.onMouseEnter?.(e);
    }

    @State.bound
    private mouseLeave(e: React.MouseEvent<HTMLElement>) {
        this.scheduleHide();
        this.props.onMouseLeave?.(e);
    }

    @State.bound
    private click(e: React.MouseEvent) {
        this.props.onClick?.(e);
    }

    @State.bound
    private tooltipMouseEnter() {
        this.clearTimeouts();
        this.setState({
            isOpen: true,
            isLocked: true
        });
    }

    @State.bound
    private tooltipMouseLeave() {
        this.clearTimeouts();
        this.setState({
            isOpen: false,
            isLocked: false
        });
    }

    private clearTimeouts() {
        clearTimeout(this.showTimeout);
        clearTimeout(this.hideTimeout);
    }

    private scheduleShow() {
        if (this.state.isLocked) {
            return;
        }

        this.clearTimeouts();
        this.showTimeout = window.setTimeout(() => {
            this.setState({
                isOpen: true
            });
        }, this.props.showTimeout);
    }

    private scheduleHide() {
        if (this.state.isLocked) {
            return;
        }

        this.clearTimeouts();
        this.hideTimeout = window.setTimeout(() => {
            this.setState({
                isOpen: false
            });
        }, this.props.hideTimeout);
    }

    public render() {
        return (
            <Manager>
                {!this.props.referenceElement && (
                    <Reference>
                        {({ ref }) =>
                            React.createElement(this.props.wrapperElementType, {
                                style: this.props.style,
                                className: this.props.className,
                                ref: ref,
                                onMouseEnter: this.mouseEnter,
                                onMouseLeave: this.mouseLeave,
                                onClick: this.click,
                            }, this.props.children)}
                    </Reference>
                )}
                {ReactDOM.createPortal(
                    <ReactPopper
                        placement={this.props.tooltipPlacement}
                        referenceElement={this.props.referenceElement}
                        modifiers={this.modifiers}
                    >
                        {({ ref, style, placement, arrowProps, scheduleUpdate }) => (
                            <div
                                ref={ref}
                                style={this.state.isOpen ? { ...style, ...this.props.tooltipStyle } : { display: "none" }}
                                className={this.getContainerClass()}
                                data-placement={this.state.isOpen ? placement : undefined}
                                onMouseEnter={this.tooltipMouseEnter}
                                onMouseLeave={this.tooltipMouseLeave}
                            >
                                {this.state.isOpen && this.props.tooltipContent}
                                <div
                                    ref={arrowProps.ref}
                                    style={this.state.isOpen && { ...arrowProps.style, ...this.props.arrowStyle } || undefined}
                                    data-placement={this.state.isOpen && placement || undefined}
                                    className="tooltip-arrow"
                                />
                                {this.state.isOpen && <PositionCorrector scheduleUpdate={scheduleUpdate} />}
                            </div>
                        )}
                    </ReactPopper>,
                    document.body
                )}
            </Manager>
        );
    }
}


const PositionCorrector: React.FC<{ scheduleUpdate: () => void }> = props => {
    useLayoutEffect(() => {
        props.scheduleUpdate();
    });
    return null;
};