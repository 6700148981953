import React, { useCallback } from "react";
import * as Ui from "@CommonControls";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { provideFormLogicTokenEditorDialogStore, useFormLogicTokenEditorDialogStore } from "./FormLogicTokenEditorDialogStoreProvider";
import { IFormLogicTokenEditorDialogParams, IFormLogicTokenEditorDialogResult } from "./FormLogicTokenEditorDialogParams";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import FormLogicTokenStore from "./FormLogicTokenStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import { emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormFieldSelector from "@HisPlatform/BoundedContexts/FormEngine/Components/FormFieldSelector/FormFieldSelector";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";

function getItemKey(item: FormLogicTokenStore, index: number) {
    return `${item.id}_${index}`;
}

function FormLogicTokenEditorDialog(props: IModalComponentParams<IFormLogicTokenEditorDialogResult> & IFormLogicTokenEditorDialogParams) {
    const store = useFormLogicTokenEditorDialogStore();

    const renderItemEditor = useCallback((item: FormLogicTokenStore, _index: number) => <FormLogicTokenEditor item={item} />, [store.formLogicTokensStore]);

    const createNewEnumValueAsync = useCallback(() => Promise.resolve(
        store.createNewItem()
    ), [store]);

    return (
        <Ui.Modal
            isOpen
            onClose={props.onClose as any}
            title={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.FormLogic.FormLogicTokenEditor.ModalTitle}
            size="compact"
        >
            <Ui.Modal.Body>
                <ValidationBoundary validationResults={emptyArray}>
                    <ListPanel
                        title={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.FormLogic.FormLogicTokenEditor.ListPanelLabel}
                        alwaysEdit
                        items={store.formLogicTokensStore}
                        getItemKey={getItemKey}
                        renderItemEditor={renderItemEditor}
                        onCreateNewAsync={createNewEnumValueAsync}
                        onRemoveItem={store.removeValue}
                    />
                </ValidationBoundary>
            </Ui.Modal.Body>
            <Ui.Modal.Footer
                left={(
                    <Ui.Modal.Button automationId="FormLogicCancel_Button" closeModalOnClick text={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.CancelButtonLabel} visualStyle="link" />
                )}
                right={(
                    <Ui.Modal.Button automationId="FormLogicTokenOk_Button" text={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.OkButtonLabel} onClick={store.save} />
                )} />
        </Ui.Modal>
    );
}


const FormLogicTokenEditor = State.observer((props: { item: FormLogicTokenStore }) => {
    const store = useFormLogicTokenEditorDialogStore();
    return (
        <Ui.Flex>
            <Ui.Flex.Item>
                <Ui.SelectBox
                    label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.FormLogic.FormLogicTokenEditor.FormTokenSelector}
                    automationId="TokenReference_SelectBox"
                    items={store.tokens}
                    value={props.item.token}
                    onChange={props.item.setToken}
                    clearable={false}
                />
            </Ui.Flex.Item>
            <Ui.Flex.Item>
                {props.item.token?.name === "DynamicFormDataElement" && (
                    <FormFieldSelector
                        label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.FormLogic.FormLogicTokenEditor.FormFieldSelector}
                        clearable={false}
                        automationId="SelectedFDynamicFormDataElement_SelectBox"
                        onChange={props.item.setSettings}
                        value={props.item.settings}
                    />
                )}
            </Ui.Flex.Item>
        </Ui.Flex>
    );
});

export default provideFormLogicTokenEditorDialogStore(FormLogicTokenEditorDialog);