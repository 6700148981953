import PatientId from "@Primitives/PatientId.g";
import * as CareProxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import { getHistoryItemDetailDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalConditionDtoMapper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import TherapyRecommendationStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/TherapyRecommendationStore";
import TherapyRecommendationHistoryItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/TherapyRecommendationHistoryItem";
import TherapyAssignmentBase from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/TherapyAssignmentBase";
import ReferencedTherapyAssignment from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/ReferencedTherapyAssignment";
import CustomTherapyAssignment from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/CustomTherapyAssignment";

export function getAddTherapyRecommendationControllerDto(source: TherapyRecommendationStore, patientId: PatientId, isValidateOnly: boolean) {
    return new CareProxy.AddTherapyRecommendationControllerDto({
        patientId: patientId,
        historyItemDtos: [getTherapyRecommendationHistoryItemDto(source.latestHistoryItem as TherapyRecommendationHistoryItem)],
        therapyAssignmentBaseDto: getTherapyAssignmentDto(source.therapyAssignment),
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData
    });
}

export function getUpdateTherapyRecommendationControllerDto(source: TherapyRecommendationStore, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.UpdateTherapyRecommendationControllerDto({
        therapyRecommendationId: source.id,
        historyItemDtos: [getTherapyRecommendationHistoryItemDto(source.latestHistoryItem as TherapyRecommendationHistoryItem)],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteTherapyRecommendationHistoryItemControllerDto(source: TherapyRecommendationStore, versionNumber: number, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.DeleteTherapyRecommendationHistoryItemControllerDto({
        therapyRecommendationId: source.id,
        versionNumbers: [versionNumber],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteTherapyRecommendationControllerDto(store: TherapyRecommendationStore) {
    return new CareProxy.DeleteTherapyRecommendationControllerDto({
        therapyRecommendationId: store.id,
        lockId: store.lockInfo?.lockId,
        extensionData: store.extensionData,
        isValidateOnly: false,
        releaseLockIfSuccessful: true
    });
}

function getTherapyAssignmentDto(source: TherapyAssignmentBase) {
    let dto;

    if (source instanceof ReferencedTherapyAssignment) {
        dto = new CareProxy.ReferencedTherapyAssignmentDto({  therapyId: source.therapyId });
    } else if (source instanceof CustomTherapyAssignment) {
        dto = new CareProxy.CustomTherapyAssignmentDto({  name: source.name });
    }

    return dto;
}

function getTherapyRecommendationHistoryItemDto(source: TherapyRecommendationHistoryItem) {
    return new CareProxy.TherapyRecommendationHistoryItemDto({
        
        versionNumber: source.versionNumber,
        index: source.index,
        createdAt: source.createdAt,
        historyItemDetail: getHistoryItemDetailDto(source.historyItemDetail),
        description: source.description,
        endDate: source.endDate,
        startDate: source.startDate,
        extensionData: source.extensionData
    });
}