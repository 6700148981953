exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Application_pageContainer_3OYYq {\r\n    overflow-y: hidden;\r\n    overflow-x: hidden;\r\n    position: relative;\r\n    width: 100%;\r\n    height: calc(100vh - 30px);\r\n}\r\n\r\n.Application_pageContainer2_3dB6m {\r\n    overflow-y: hidden;\r\n    overflow-x: hidden;\r\n    position: relative;\r\n    width: 100%;\r\n    height: calc(100vh);\r\n}", ""]);

// exports
exports.locals = {
	"pageContainer": "Application_pageContainer_3OYYq",
	"pageContainer2": "Application_pageContainer2_3dB6m"
};