import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";
import { RequiredPermissionScope } from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";

export function mapPermissionScopesFromDto(responseScopes: RequiredPermissionScope[]): FrontendActionPermissionScope[] {

    if (!responseScopes) {
        return null;
    }

    function *_map() {
        for (const scope of responseScopes) {
            yield new FrontendActionPermissionScope(scope.type.value, scope.values);
        }
    }

    return Array.from(_map());
}