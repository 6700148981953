import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import StaticFormEditingResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { FilterType } from "./FilterType";

export default class FilterStore {
    @State.observable.ref public filterType: FilterType;
    @State.observable.ref public identifiers: string = "";

    constructor(filterType?: FilterType, identifiers?: string) {
        this.filterType = filterType;
        this.identifiers = identifiers;
    }

    @State.action.bound
    public setFilterType(newFilterType: FilterType) {
        this.identifiers = this.copyIdentifiersIfPossible(newFilterType);
        this.filterType = newFilterType;
    }

    @State.action.bound
    public setFilterIdentifiers(newIdentifiers: string) {
        this.identifiers = newIdentifiers;
    }

    @State.observable.ref
    public preloadedOptions: Array<ISelectBoxItem<FilterType>> = this.getOptions();

    private getOptions() {
        return [
            { text: StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersModal.IncludeIdentifierFilter, value: "IncludeIdentifierFilter" } as ISelectBoxItem<FilterType>,
            { text: StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersModal.ExcludeIdentifierFilter, value: "ExcludeIdentifierFilter" } as ISelectBoxItem<FilterType>,
            { text: StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersModal.IncludeIdentifierSystemIdFilter, value: "IncludeIdentifierSystemIdFilter" } as ISelectBoxItem<FilterType>,
            { text: StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersModal.ExcludeIdentifierSystemIdFilter, value: "ExcludeIdentifierSystemIdFilter" } as ISelectBoxItem<FilterType>,
            { text: StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersModal.CodeStartsWithFilter, value: "CodeStartsWithFilter" } as ISelectBoxItem<FilterType>,
            { text: StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersModal.CodeDoesNotStartWithFilter, value: "CodeDoesNotStartWithFilter" } as ISelectBoxItem<FilterType>,
            { text: StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersModal.ExplicitIdentifierFilter, value: "ExplicitIdentifierFilter" } as ISelectBoxItem<FilterType>
        ];
    }

    private copyIdentifiersIfPossible(newFilterType: FilterType): string {
        if (this.filterType === "IncludeIdentifierFilter" || this.filterType === "ExcludeIdentifierFilter") {
            if (newFilterType === "IncludeIdentifierFilter" || newFilterType === "ExcludeIdentifierFilter") {
                return this.identifiers;
            }
        } else if (this.filterType === "IncludeIdentifierSystemIdFilter" || this.filterType === "ExcludeIdentifierSystemIdFilter") {
            if (newFilterType === "IncludeIdentifierSystemIdFilter" || newFilterType === "ExcludeIdentifierSystemIdFilter") {
                return this.identifiers;
            }
        } else if (this.filterType === "CodeStartsWithFilter" || this.filterType === "CodeDoesNotStartWithFilter") {
            if (newFilterType === "CodeStartsWithFilter" || newFilterType === "CodeDoesNotStartWithFilter") {
                return this.identifiers;
            }
        }
        return "";
    }
}
