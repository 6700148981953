import CareActivityId from "@Primitives/CareActivityId.g";
import PatientId from "@Primitives/PatientId.g";
import Command from "@Toolkit/CommonWeb/CommandProcessing/Definition/Command";

export default class CreatePatientAppointmentCommand extends Command {
    public careActivityId: CareActivityId;
    public patientId: PatientId;

    constructor(
        originator: string,
        public readonly serviceCode: string | undefined,
        public readonly afterDate: number | undefined) {
        super("CreatePatientAppointmentCommand", originator);
    }
}