import { createNestedRoutes } from "@Toolkit/ReactClient/Routing/CreateNestedRoutes";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import ICardioStandaloneHost from "./ICardioStandaloneHost";

const standaloneRouteDefinitions = createNestedRoutes(ApplicationRoutes.careActivity, {
    testUseCase: new RouteDefinition("/icardio")
});

export default UseCaseDescriptorBuilder.create(
    "ICardioUseCase",
    builder => {
        builder.standalone(b => b
            .routeDefinition(standaloneRouteDefinitions.testUseCase)
            .component(ICardioStandaloneHost)
        );
        builder.hideFooter();
    }
);