import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareActivityEReferralStore from "@HunEHealthInfrastructurePlugin/Model/CareActivityEReferralStore";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import _ from "@HisPlatform/Common/Lodash";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import EhiEReferralListPanelView from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiEReferralListPanel/EhiEReferralListPanelView";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import EhiEReferralListQuerySettingsStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralListQuerySettingsStore";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import EhiCareServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareServiceFactory";
import EhiCareService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareService";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";

interface IEhiEReferralListPanelDependencies {
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    careApiAdapter: EhiCareApiAdapter;
    localizationService: ILocalizationService;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    ehiCareServiceFactory: EhiCareServiceFactory;
}

interface IEhiEReferralListPanelProps {
    _dependencies?: IEhiEReferralListPanelDependencies;
    _modalService?: IModalService;
    routingController: IRoutingController<any>;
}

/** @screen */
@State.observer
class EhiEReferralListPanel extends React.Component<IEhiEReferralListPanelProps> {
    @State.observable private isLoading: boolean = false;
    @State.observable private isLoaded: boolean = false;

    @State.observable.ref private querySettings = new EhiEReferralListQuerySettingsStore();
    @State.observable.ref private eReferrals: CareActivityEReferralStore[] = [];
    @State.observable.ref private selectedEReferral: CareActivityEReferralStore = null;
    @State.observable.ref private ehiErrors: EhiError[] = [];

    @State.observable.ref private dataSource = new InMemoryDataGridDataSource<CareActivityEReferralStore>(() => this.eReferrals);

    private ehiCareService: EhiCareService = null;

    @State.computed
    private get isQueryDisabled() {
        return !this.querySettings.interval?.from || !this.querySettings.interval?.to || !this.querySettings.institute;
    }

    private get dependencies() {
        return this.props._dependencies;
    }

    @State.action.bound
    private setEhiErrors(newValue: EhiError[]) {
        this.ehiErrors = newValue;
    }

    @State.action.bound
    private setSelectedEReferral(newValue: CareActivityEReferralStore) {
        this.selectedEReferral = newValue;
    }

    private readonly initializeAsync = createInitialPanelLoader(
        this._initializeAsync,
        this.dependencies.careApiAdapter.getEReferralsForOrganizationUnitPermissionCheckAsync
    );

    public componentDidMount(): void {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }

    public componentDidUpdate(prevProps: IEhiEReferralListPanelProps) {
        if (this.props !== prevProps) {
            dispatchAsyncErrors(this._initializeAsync(), this);
        }
    }

    @State.bound
    private async _initializeAsync() {
        await Promise.all([
            this.dependencies.hunEhiCareReferenceDataStore.ehiEReferralServiceTypes.ensureAllLoadedAsync(),
        ]);

        this.ehiCareService = this.props._dependencies.ehiCareServiceFactory.getEhiCareService();

        State.runInAction(() => {
            this.isLoaded = true;
        });
    }

    @State.boundLoadingState()
    private async queryEReferralsAsync() {
        State.runInAction(() => {
            this.isLoading = true;
        });

        let eReferrals: CareActivityEReferralStore[] = [];

        const ehiServiceCallResult = await this.ehiCareService.getEReferralsForOrganizationUnitAsync(this.querySettings);

        this.setEhiErrors(ehiServiceCallResult.result.value.errors);

        ehiServiceCallResult.result.value.value?.forEach(i => {
            eReferrals.push(i);
        });

        if (!!this.selectedEReferral && !this.selectedEReferral.isNew && !eReferrals.some(i => ValueWrapper.equals(i.ehiEReferralId, this.selectedEReferral.ehiEReferralId))) {
            eReferrals.push(this.selectedEReferral);
        }

        eReferrals = _.sortBy(eReferrals, item => item.createdAt);

        State.runInAction(() => {
            this.eReferrals = eReferrals;
            this.isLoading = true;
        });
    }

    public render() {
        return (this.initializeAsync.isUnauthorizedAccess
            ? (
                <UnauthorizedAccessPageBox title={StaticHunEHealthInfrastructureCareResources.EReferralListPanel.List.Title} />
            ) : this.isLoaded && (
                <>
                    <EhiEReferralListPanelView
                        ehiErrors={this.ehiErrors}
                        dataSource={this.dataSource}
                        selectedEReferral={this.selectedEReferral}
                        isLoading={this.isLoading}
                        backToPreviousPage={this.props.routingController.goBack}
                        onSelectEReferral={this.setSelectedEReferral}
                        querySettingsStore={this.querySettings}
                        onQueryAsync={this.queryEReferralsAsync}
                        queryDisabled={this.isQueryDisabled}
                        localizationService={this.props._dependencies.localizationService}
                    />
                </>
            )
        );
    }
}

export default connect(
    EhiEReferralListPanel,
    new DependencyAdapter<IEhiEReferralListPanelProps, IEhiEReferralListPanelDependencies>((container) => {
        return {
            hunEhiCareReferenceDataStore: container.resolve<HunEhiCareReferenceDataStore>("HunEhiCareReferenceDataStore"),
            careApiAdapter: container.resolve<EhiCareApiAdapter>("EhiCareApiAdapter"),
            localizationService: container.resolve<ILocalizationService>("ILocalizationService"),
            organizationReferenceDataStore: container.resolve<OrganizationReferenceDataStore>("OrganizationReferenceDataStore"),
            ehiCareServiceFactory:  container.resolve<EhiCareServiceFactory>("EhiCareServiceFactory"),
        };
    }),
    new HisModalServiceAdapter<IEhiEReferralListPanelProps>()
);
