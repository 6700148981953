import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import WellKnownReferenceCodes from "@HunSocialSecurityPlugin/Common/WellKnownReferenceCodes";
import { PatientDocumentSourceType } from "@Toolkit/CommonWeb/Model/PatientDocumentSourceType";
import IIdentifierWithSourceTypeDisplayExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IIdentifierWithSourceTypeDisplayExtensionPointProps";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";

@State.observer
export default class HunSocialSecurityIdentifierWithSourceTypeDisplay extends React.Component<IIdentifierWithSourceTypeDisplayExtensionPointProps> {

    @State.computed private get identifierFromCareActivitySource() { return this.props.identifiersWithSourceTypes?.find(i => i.sourceType === PatientDocumentSourceType.CareActivity)?.identifier; }
    @State.computed private get hunSocialSecurityIdentifier() { return this.props.identifiersWithSourceTypes?.find(i => i.identifier.identifierSystemId.value === WellKnownReferenceCodes.HunSocialSecurityNumberIdentifier)?.identifier; }
    @State.computed private get passport() { return this.props.identifiersWithSourceTypes?.find(i => i.identifier.identifierSystemId.value === WellKnownReferenceCodes.PassportIdentifier)?.identifier; }
    @State.computed private get internalIdentifier() { return this.props.identifiersWithSourceTypes?.find(i => i.identifier.identifierSystemId.value === "InternalIdentifier")?.identifier; }

    private renderIdentifierValue(identifier: Identifier) {
        if (isNullOrUndefined(identifier)) {
            return null;
        }
        if (identifier.identifierSystemId.value === WellKnownReferenceCodes.HunSocialSecurityNumberIdentifier) {
            return this.renderHunSocialSecurityIdentifier(identifier);
        }
        return identifier.value;
    }

    private renderHunSocialSecurityIdentifier(identifier: Identifier) {
        const part1 = identifier.value.substring(0, 3);
        const part2 = identifier.value.substring(3, 6);
        const part3 = identifier.value.substring(6, 9);
        return (
            <>
                {`${part1} ${part2} ${part3}`}
            </>
        );
    }

    public render() {
        //Prioritized list for the most important documents
        if (!isNullOrUndefined(this.identifierFromCareActivitySource)) {
            return this.renderIdentifierValue(this.identifierFromCareActivitySource);
        }
        if (!isNullOrUndefined(this.hunSocialSecurityIdentifier)) {
            return this.renderIdentifierValue(this.hunSocialSecurityIdentifier);
        }
        if (!isNullOrUndefined(this.passport)) {
            return this.renderIdentifierValue(this.passport);
        } 

        //If none of the documents above are in the list, return the first document, if there is any
        const documents = this.props.identifiersWithSourceTypes?.filter(x => x.identifier.identifierSystemId.value !== "InternalIdentifier");
        if (documents?.length > 0) {
            return this.renderIdentifierValue(documents[0].identifier);
        } 
        
        //If none of the patient documents are represented, return with the internal identifier
        return this.renderIdentifierValue(this.internalIdentifier);
    }
}