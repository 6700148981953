import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import CompositeFormDataElement from "@Toolkit/FormEngine/Model/Schema/CompositeFormDataElement";
import { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";

export function findParentCompositeDataElement(formDataElements: FormDataElementBase[], parentDataReferences: string[] | null): CompositeFormDataElement | null {
    if (parentDataReferences === null) {
        return null;
    }

    let actualFormDataElements = formDataElements;
    let parentElement: CompositeFormDataElement = null;
    for (const dataReference of parentDataReferences) {
        let dataReferencePartToCheck = dataReference;
        if (dataReference.endsWith("]")) {
            const indexOfStart = dataReference.indexOf("[");
            dataReferencePartToCheck = dataReference.substring(0, indexOfStart);
        }

        parentElement = actualFormDataElements.find(i => i.name === dataReferencePartToCheck) as CompositeFormDataElement;
        actualFormDataElements = parentElement.formDataElements;
    }

    return parentElement;
}

export function findParentDataElementCollection(formDataElements: IObservableArray<FormDataElementBase>, parentDataReferences: string[] | null): IObservableArray<FormDataElementBase> {
    return _findParentDataElementCollection(formDataElements, parentDataReferences) as IObservableArray<FormDataElementBase>;
}

export function findParentDataElementCollectionFromArray(formDataElements: FormDataElementBase[], parentDataReferences: string[] | null): FormDataElementBase[] {
    return _findParentDataElementCollection(formDataElements, parentDataReferences) as FormDataElementBase[];
}

function _findParentDataElementCollection(formDataElements: FormDataElementBase[] | IObservableArray<FormDataElementBase>, parentDataReferences: string[] | null): FormDataElementBase[] | IObservableArray<FormDataElementBase> {
    if (parentDataReferences === null) {
        return formDataElements;
    }

    let actualFormDataElements = formDataElements;
    let parentElement: CompositeFormDataElement = null;
    for (const dataReference of parentDataReferences) {
        let dataReferencePartToCheck = dataReference;
        if (dataReference.endsWith("]")) {
            const indexOfStart = dataReference.indexOf("[");
            dataReferencePartToCheck = dataReference.substring(0, indexOfStart);
        }

        parentElement = actualFormDataElements.find(i => i.name === dataReferencePartToCheck) as CompositeFormDataElement;
        actualFormDataElements = parentElement.formDataElements;
    }

    return actualFormDataElements;
}