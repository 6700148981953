export function getNameByValue(enumType: any, enumValue: number): string {
    return enumType[enumValue];
}

export function getEntryValues<TItem>(enumType: any): TItem[] {
    return Object.keys(enumType).filter((k) => typeof enumType[k as any] === "number").map((a) => enumType[a]);
}

export function getEntryNames(enumType: any): string[] {
    return Object.keys(enumType).filter((k) => typeof enumType[k as any] === "number");
}

export function hasFlags(value: number, flag: number): boolean {
    return (value & flag) === flag;
}