import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import { PageBox, PageBoxContainer } from "@CommonControls";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";
import OrganizationUnitTreePanel from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/OrganizationUnitTreePanel";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import Route from "@Toolkit/ReactClient/Routing/Abstractions/Route";
import IOrganizationEditorRouteParams from "@HisPlatform/Application/Routes/IOrganizationEditorRouteParams";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import { OrganizationUnitTreePanelDetailMode } from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/OrganizationUnitTreePanelDetailMode";
import { HisErrorBoundary } from "@HisPlatformControls";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";


interface IOrganizationEditorPageDependencies {

}

interface IOrganizationEditorPageProps extends IRoutingFrameContentProps {
    _dependencies?: IOrganizationEditorPageDependencies;
}

@State.observer
class OrganizationEditorPage extends React.Component<IOrganizationEditorPageProps> {

    private get currentRoute(): Route<IOrganizationEditorRouteParams> { return this.props.routingController.currentRoute; }

    @State.computed.valueWrapper private get selectedId() {
        const newIdValue = this.currentRoute.parameters.organizationUnitId;

        if (newIdValue) {
            return new OrganizationUnitId(newIdValue);
        }

        return null;
    }

    @State.computed private get detail() {
        return this.currentRoute.parameters.detail;
    }

    @State.bound
    private select(id: OrganizationUnitId, detailMode: OrganizationUnitTreePanelDetailMode) {
        this.props.routingController.push(ApplicationRoutes.organizationEditor.makeRoute({
            organizationUnitId: id && id.value,
            detail: detailMode
        }));
    }

    public render() {
        return (
            <PageBoxContainer>
                <HisErrorBoundary>
                    <LoadingBoundary>
                        <OrganizationUnitTreePanel
                            onChange={this.select}
                            selectedId={this.selectedId}
                            detail={this.detail}
                        />
                    </LoadingBoundary>
                </HisErrorBoundary>
            </PageBoxContainer>
        );
    }
}

export default OrganizationEditorPage;