import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareActivityFinanceData from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/CareActivityFinanceData";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import CareActivityFinanceDataPanelView from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/CareActivityFinanceDataPanel/CareActivityFinanceDataPanelView";
import ICareActivityDischargeDataExtensionPointProps from "@PluginInterface/BoundedContexts/Care/PatientRegister/ExtensionPoints/ICareActivityDischargeDataExtensionPointProps";
import HunCareReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/HunCareReferenceDataStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface ICareActivityFinanceDataPanelDependencies {
    hunCareReferenceDataStore: HunCareReferenceDataStore;
}

interface ICareActivityFinanceDataPanelProps extends ICareActivityDischargeDataExtensionPointProps {
    _dependencies?: ICareActivityFinanceDataPanelDependencies;
}

/** @screen */
@State.observer
class CareActivityFinanceDataPanel extends React.Component<ICareActivityFinanceDataPanelProps> {

    private get dependencies() {
        return this.props._dependencies;
    }
    @State.observable private isLoaded: boolean = false;
    @State.observable.ref private store: CareActivityFinanceData = new CareActivityFinanceData();
    private disposers: IDisposable[] = [];

    public componentDidMount(): void {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }

    public componentDidUpdate(prevProps: ICareActivityFinanceDataPanelProps) {
        if (
            !ValueWrapper.equals(this.props.patientId, prevProps.patientId) ||
            !ValueWrapper.equals(this.props.careActivityId, prevProps.careActivityId) ||
            JSON.stringify(this.props.extensionData) !== JSON.stringify(prevProps.extensionData) ||
            this.props.onExtensionDataChange !== prevProps.onExtensionDataChange
        ) {
            this.dispose();
            dispatchAsyncErrors(this.initializeStoreAsync(), this);
        }
    }

    private async initializeAsync() {
        await this.dependencies.hunCareReferenceDataStore.ctMrExaminationRequestTypes.ensureLoadedAsync();
        await this.dependencies.hunCareReferenceDataStore.earningCapacityConsiderationTypes.ensureLoadedAsync();
        await this.dependencies.hunCareReferenceDataStore.laboratoryExaminationRequestTypes.ensureLoadedAsync();
        await this.dependencies.hunCareReferenceDataStore.physiotherapyExaminationRequestTypes.ensureLoadedAsync();
        await this.dependencies.hunCareReferenceDataStore.travelExpenseClaimTypes.ensureLoadedAsync();
        await this.dependencies.hunCareReferenceDataStore.imagingExaminationRequestTypes.ensureLoadedAsync();

        await this.initializeStoreAsync();

        State.runInAction(() => {
            this.isLoaded = true;
        });
    }

    @State.action.bound
    private initializeStoreAsync() {
        if (this.props.extensionData) {
            const newStore = CareActivityFinanceData.CreateFromJsonObject(this.props.extensionData);
            if (newStore) {
                this.store = newStore;
            }
        }

        if (!this.store) {
            this.store = new CareActivityFinanceData();
        }

        const { onExtensionDataChange } = this.props;
        this.disposers.push({
            dispose: State.autorun(() => {
                if (this.store) {
                    onExtensionDataChange("HunSocialSecurity_CareActivityFinanceData", {
                        RowVersion: this.store.rowVersion?.toJSON(),
                        Id: { Value: this.store.careActivityId.value },
                        CtMrExaminationRequestTypeId: this.store.ctrExaminationRequestTypeId?.toJSON(),
                        EarningCapacityConsiderationTypeId: this.store.earningCapacityConsiderationTypeId?.toJSON(),
                        LaboratoryExaminationRequestTypeId: this.store.laboratoryExaminationRequestTypeId?.toJSON(),
                        PhysiotherapyExaminationRequestTypeId: this.store.physiotherapyExaminationRequestTypeId?.toJSON(),
                        TravelExpenseClaimTypeId: this.store.travelExpenseClaimTypeId?.toJSON(),
                        ImagingExaminationRequestTypeId: this.store.imagingExaminationRequestTypeId?.toJSON(),
                        NumberOfGySePrescriptions: this.store.numberOfGySePrescriptions,
                        NumberOfPrescriptions: this.store.numberOfPrescriptions,
                        NumberOfTherapyPrescriptions: this.store.numberOfTherapyPrescriptions,
                        PartiallyReimbursedCost: !isNullOrUndefined(this.store.partiallyReimbursedCost?.amount) ? {
                            Amount: this.store.partiallyReimbursedCost.amount,
                            CurrencyCode: this.store.partiallyReimbursedCost.currencyCode
                        } : "",
                        ExtensionData: null,
                    });
                }
            })
        });

        this.disposers.push(
            this.props.extensionController.setIsDirtyHook(this.isDirty)
        );

        return Promise.resolve();
    }

    @State.bound
    private dispose() {
        this.disposers.forEach(d => d.dispose());
    }

    @State.bound
    private isDirty() {
        return this.store.isDirty();
    }

    public render() {
        return (
            <CareActivityFinanceDataPanelView
                financeData={this.store}
            />
        );
    }
}

export default connect(
    CareActivityFinanceDataPanel,
    new DependencyAdapter<ICareActivityFinanceDataPanelProps, ICareActivityFinanceDataPanelDependencies>(container => {
        return {
            hunCareReferenceDataStore: container.resolve("HunCareReferenceDataStore")
        };
    })
);
