
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import TelemetrySessionScreenStore from "./TelemetrySessionScreenStore";
import { editorScreenRenderFunc } from "@Toolkit/CommonWeb/PanelStore/EditorScreenRenderFunc";
import { ITelemetrySessionScreenProps } from "./TelemetrySessionScreen";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ShowEditPatientAdmissionDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEditPatientAdmissionDataScreenAction.g";
import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";

const {
    ContextComponent: TelemetrySessionScreenStoreContext,
    StoreProvider: TelemetrySessionScreenStoreProvider,
    useStore: useTelemetrySessionScreenStore,
    provideStore: provideTelemetrySessionScreenStore,
} = createPanelStoreProvider<TelemetrySessionScreenStore>(TelemetrySessionScreenStore, (props: ITelemetrySessionScreenProps) => ({
    unauthorizedPageTitle: (props.action instanceof ShowNewAdmitPatientScreenAction || props.action instanceof ShowEditPatientAdmissionDataScreenAction) ?
        StaticCareResources.PatientAdmission.Titles.Admission :
        StaticWebAppResources.OutpatientWorkflow.CareActivityBaseDataStep.Title
}), editorScreenRenderFunc);

export {
    TelemetrySessionScreenStoreContext,
    TelemetrySessionScreenStoreProvider,
    useTelemetrySessionScreenStore,
    provideTelemetrySessionScreenStore
};