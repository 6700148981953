import Di from "@Di";
import MedicationPricingAndSubsidies from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationPricingAndSubsidies";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import _ from "@HisPlatform/Common/Lodash";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import DesignatedOrganizationId from "@Primitives/DesignatedOrganizationId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

@Di.injectable()
export default class PricingAndSubsidyLoader {

    constructor(
        @Di.inject("CareReferenceDataStore") private readonly careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("OrganizationReferenceDataStore") private readonly organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("MedicationRequestReferenceDataStore") private readonly medicationRequestReferenceDataStore: MedicationRequestReferenceDataStore
    ) { }

    public async loadReferenceDataAsync(pricingAndSubsidies: MedicationPricingAndSubsidies, validOn: LocalDate) {
        const subsidies = pricingAndSubsidies?.subsidies;
        const options = _.flatten(subsidies.map(s => s.options));
        const restrictionConditionIds = _.flatten(options?.map(o => o.restrictionConditions));
        const optionRestrictions = _.flatten(options?.map(o => o.optionRestrictions));
        const professionalExamId = optionRestrictions?.map(r => r.practitionerProfessionalExamRestriction);
        const designatedOrganizationIds = optionRestrictions.map(o => o.designatedOrganizationId);
        const designatedOrganizationVersionSelectors = designatedOrganizationIds?.map(d => {
            return { id: d, validOn: validOn } as IEntityVersionSelector<DesignatedOrganizationId>;
        });

        await this.careReferenceDataStore.conditionsWithoutVersion.ensureLoadedAsync(restrictionConditionIds);
        await this.medicationRequestReferenceDataStore.organizationRestrictionTypes.ensureLoadedAsync();
        await this.organizationReferenceDataStore.professionalExamMap.ensureLoadedAsync(professionalExamId);
        await this.medicationRequestReferenceDataStore.designatedOrganizations.ensureLoadedAsync(designatedOrganizationVersionSelectors);
    }
}