import ResourceId from "@Primitives/ResourceId.g";
import UserId from "@Primitives/UserId.g";

export default class EhiErrorNotification {
    
    public userId: UserId;
    public errorMessageResourceId: ResourceId;
    public parameters: any;

    constructor(data: IEhiErrorNotification) {
        this.userId = data.userId;
        this.errorMessageResourceId = data.errorMessageResourceId;
        this.parameters = data.parameters;
    }

    public static fromJS(data: any): EhiErrorNotification {
        return new EhiErrorNotification({
            userId: data["UserId"] !== undefined ? new UserId(data["UserId"].Value) : null,
            errorMessageResourceId: data["ErrorMessageResourceId"] !== undefined ? new ResourceId(data["ErrorMessageResourceId"].Value) : null,
            parameters: data["Parameters"] !== undefined ? data["Parameters"] : null
        });
    }
}

interface IEhiErrorNotification {
    userId: UserId;
    errorMessageResourceId: ResourceId;
    parameters: any;
}