import React from "react";
import PropertyBase from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import CheckBox from "@CommonControls/CheckBox";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import PropertyVersionValidity from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PropertyGroupEditableColumn/PropertyVersionValidity";
import DynamicPropertyInputType from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/DynamicPropertyInputType";
import Log from "@HisPlatform/Services/Definition/Logger/Log";
import Encoding from "@Toolkit/CommonWeb/Encoding";

interface IScalarPropertyEditorProps {
    row: PropertyBase<string>;
}

@State.observer
export class ScalarPropertyEditor extends React.Component<IScalarPropertyEditorProps> {
    private previousEnabledValue: string = null;
    private readonly inputOpenFileRef = React.createRef<HTMLInputElement>();

    @State.computed private get currentValue() {
        return this.props.row.hasValue ? this.props.row.getValue(this.props.row.isTemporal ? LocalDate.today() : undefined) : null;
    }

    private get hasValue() {
        return this.props.row.hasValue;
    }

    @State.action.bound
    private setEnabled(isEnabled: boolean) {
        if (isEnabled) {
            this.setCurrentVersionValue(this.previousEnabledValue ?? this.currentValue ?? "");
        } else {
            this.previousEnabledValue = this.currentValue;
            this.props.row.clearValue();
        }
    }

    @State.bound
    private async setPropertyValueAsync(event: React.ChangeEvent<HTMLInputElement>) {
        event.persist();

        const file = event.target.files[0];

        try {
            const bytes = await file.toUint8ArrayAsync();
            const content = Encoding.UTF8.getString(bytes);
            this.setCurrentVersionValue(content);
        } catch (error) {
            Log.error(error);
        }
    }

    @State.bound
    private showOpenFileDialog() {
        this.inputOpenFileRef.current.click();
    }

    private renderInput() {
        if (this.props.row.inputType === DynamicPropertyInputType.File) {
            return (
                <>
                    <Ui.Button iconName="align_top" onClick={this.showOpenFileDialog} automationId={this.props.row.name + "_openFileButton"} disabled={!this.hasValue} />
                    <input type="file" onChange={this.setPropertyValueAsync} ref={this.inputOpenFileRef} style={{ display: "none" }} disabled={!this.hasValue} />
                </>
            );
        }

        return (
            <Ui.TextBox value={this.currentValue} onChange={this.setCurrentVersionValue} disabled={!this.hasValue} automationId={this.props.row.name + "_textBox"} />
        );
    }

    public render() {
        return (
            <Ui.Flex>
                <Ui.Flex.Item sm={2}>
                    <CheckBox value={this.hasValue} onChange={this.setEnabled} automationId={this.props.row.name + "_checkBox"} />
                </Ui.Flex.Item>
                <Ui.Flex.Item sm={10}>
                    {this.renderInput()}
                    <PropertyVersionValidity property={this.props.row} />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.bound
    private setCurrentVersionValue(newValue: string) {
        this.props.row.replaceVersion(newValue, this.props.row.isTemporal ? LocalDate.today() : undefined);
    }
}
