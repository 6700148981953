import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React from "react";
import IPatientCareActivitiesTabProps from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabProps";
import UncontrolledServiceRequestMasterDetailPanel from "./UncontrolledServiceRequestMasterDetailPanel";
import { nullFunction } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ServiceRequestFilter from "@Primitives/ServiceRequestFilter";

function WrappedEhrServiceRequestMasterDetailPanel(props: IPatientCareActivitiesTabProps) {

    return (
        <UncontrolledServiceRequestMasterDetailPanel
            mode="read-only"
            defaultServiceRequestFilter={ServiceRequestFilter.CareActivity}
            showServiceRequestFilter={false}
            onBack={nullFunction}
            isCompact
            manualHeight={props.inModalView ? "calc(100vh - 153px)" : null} // do not use this elsewhere this is a temporary solution
        />
    );
}

export default connect<IPatientCareActivitiesTabProps>(
    WrappedEhrServiceRequestMasterDetailPanel
);