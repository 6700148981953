import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import React from "react";
import { ScreenStateContextStore, ScreenStateContextAdapter } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { provideImmunizationScreenStore, useImmunizationScreenStore } from "./ImmunizationScreenStoreProvider";
import * as Ui from "@CommonControls";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import IServiceRequestHeaderExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestHeaderExtensionPointProps";
import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import FormExtensionRegistryAdapter from "@PluginInterface/FormExtension/FormExtensionRegistryAdapter";
import MedicalConditionHistoryItemDetailPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicalConditionHistoryItemDetailPanel";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { VaccineCodeSelector } from "@HisPlatform/Components/HisPlatformControls";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import ShowCreateNewImmunizationScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowCreateNewImmunizationScreenAction.g";
import ShowImmunizationScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowImmunizationScreenAction.g";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import MedicalConditionScreenToolbar from "@HisPlatform/Packages/Patients/Screens/ImmunizationScreen/MedicalConditionScreenToolbar";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";

export interface IImmunizationScreenProps extends IScreenPropsBase<ShowCreateNewImmunizationScreenAction | ShowImmunizationScreenAction> {
    _screenState?: ScreenStateContextStore;
    _formExtension?: IFormExtension<any>;
}

function ImmunizationScreen(props: IImmunizationScreenProps) {

    const store = useImmunizationScreenStore();

    if (!store.entity) {
        return null;
    }

    const prefix = store.entity.validationPropertyPrefix;

    let title;
    if (store.isCreateNewImmunizationScreen) {
        title = StaticCareResources.MedicalCondition.Common.Label.NewEntry;
    } else if (store.isImmunizationScreen) {
        title = store.entity.vaccineName;
    }

    return (
        <Screen
            title={title}
            toolbar={(
                <MedicalConditionScreenToolbar
                    store={store.entity}
                    onDeleteHistoryItemAsync={store.deleteHistoryItemAsync}
                    onSaveStoreAsync={store.onSaveAsync}
                    onLockEditClickedAsync={store.forceLoadAsync}
                    saveAction={store.isNew ? store.createAction : store.editAction}
                    deleteAction={store.deleteHistoryItemAction} />
            )}
        >
            <NavigateAwayHook isEnabled onNavigateAwayAsync={store.navigateAwayAsync} />
            <BusinessErrorHandler.Register businessErrorName="NoEhiCompatibleIdentifierFoundError" handler={store.showNoEhiCompatibleIdentifierFoundError} />

            <HisPlatformExtensionPoint
                type="ServiceRequestHeader"
                extensionProps={{ extensionData: store.entity.extensionData } as IServiceRequestHeaderExtensionPointProps}>
                <></>
            </HisPlatformExtensionPoint>
            <ValidationBoundary
                validationResults={store.validationResults}
                entityTypeName="Immunization"
                onValidateAsync={store.validateAsync}
                validateOnMount
            >
                <ReadOnlyContext.Provider value={!store.entity.isMutable || !store.entity.currentHistoryItem.isNew}>
                    <Ui.Flex>
                        <Ui.Flex.Item sm={6}>
                            <VaccineCodeSelector
                                label={store.labels.VaccineAssignment}
                                value={store.entity.vaccineCodeSelectorItem}
                                onChange={store.setVaccineAssignment}
                                isReadOnly={!store.entity.isNew}
                                propertyIdentifier="VaccineIdOrName"
                                automationId="__vaccineId"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item sm={6}>
                            <Ui.TextBox
                                label={store.labels.TargetDiseaseAssignment}
                                value={store.currentHistoryItem.targetDiseaseValue}
                                onChange={store.setTargetDiseaseAssignment}
                                propertyIdentifier={`${prefix}.TargetDiseaseName`}
                                automationId="__targetDiseaseName"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item sm={4}>
                            <Ui.TextBox
                                label={store.labels.VaccineBrand}
                                value={store.currentHistoryItem.vaccineDetail.brand}
                                onChange={store.currentHistoryItem.vaccineDetail.setBrand}
                                propertyIdentifier={`${prefix}.VaccineDetail.Brand`}
                                automationId="__vaccineBrand"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item sm={4}>
                            <Ui.TextBox
                                label={store.labels.VaccineSerialNumber}
                                value={store.currentHistoryItem.vaccineDetail.serialNumber}
                                onChange={store.currentHistoryItem.vaccineDetail.setSerialNumber}
                                propertyIdentifier={`${prefix}.VaccineDetail.SerialNumber`}
                                automationId="__VaccineSerialNumber"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item sm={4}>
                            <Ui.DatePicker
                                label={store.labels.ImmunizationDate}
                                value={store.currentHistoryItem.immunizationDate}
                                onChange={store.currentHistoryItem.setImmunizationDate}
                                propertyIdentifier={`${prefix}.ImmunizationDate`}
                                automationId="__immunizationDate"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <br />
                    <MedicalConditionHistoryItemDetailPanel store={store.entity} prefix={prefix} />
                </ReadOnlyContext.Provider>
            </ValidationBoundary>
        </Screen>
    );
}

export default connect<IImmunizationScreenProps>(
    provideImmunizationScreenStore(ImmunizationScreen),
    new ScreenStateContextAdapter(),
    new FormExtensionRegistryAdapter("ManageMedicalCondition")
);
