import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import PatientBaseDataNameRow from "@HisPlatform/BoundedContexts/Care/Components/Panels/PatientRegister/PatientAdministrativeDataPanel/PatientBaseDataPanel/PatientBaseDataNameRow";
import GenderRadioButtonGroup from "@HisPlatform/BoundedContexts/Care/Components/Controls/PatientRegister/GenderRadioButtonGroup";
import AppointmentSchedulePanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentSchedulePanel/AppointmentSchedulePanel";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";

interface IUnregisteredPatientAppointmentPanelViewProps {
    appointment?: Appointment;
    selectedSchedulingService?: SchedulingServiceSubjectStore;

    isPatientFormOpen?: boolean;
    changeDetailOpenState?: (isOpen: boolean) => void;

    onBack?: () => void;

    setSelectedSchedulingService?: (id: SchedulingServiceId) => void;
    validateAsync?: (dirtyFields?: string[]) => Promise<IClientValidationResult[]>;
    unloadAsync?: () => Promise<boolean>;
    onSaveAppointmentAsync: (updatedAppointment: Appointment) => Promise<boolean>;
}


const UnregisteredPatientAppointmentPanelView: React.FC<IUnregisteredPatientAppointmentPanelViewProps> = (props) => {

    const headerSubTitleText = `${props.selectedSchedulingService?.code ?? ""} ${props.selectedSchedulingService?.name ?? ""}`;

    const unregisteredPatient = props.appointment?.unregisteredPatient;

    const patientData = (
        <>
            <PatientBaseDataNameRow
                nameStore={unregisteredPatient?.nameStore}
                isUnknown={false}
                hasMothersName={false}
                displayProportions={[3, 5, 4]}
            />
            <Ui.Flex>
                <Ui.Flex.Item xs={3}>
                    <Ui.DatePicker
                        label={StaticSchedulingResources.UnregisteredPatientPanel.BirthDate}
                        value={unregisteredPatient?.birthDate}
                        onChange={unregisteredPatient?.setBirthDate}
                        propertyIdentifier="DateOfBirth"
                        automationId="unregisteredPatientBirthDatePicker"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={3}>
                    <GenderRadioButtonGroup
                        label={StaticSchedulingResources.UnregisteredPatientPanel.Gender}
                        value={unregisteredPatient?.genderId}
                        onChange={unregisteredPatient?.setGender}
                        propertyIdentifier="GenderId"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex>
                <Ui.Flex.Item xs={3}>
                    <Ui.TextBox
                        label={StaticSchedulingResources.UnregisteredPatientPanel.PhoneNumber}
                        value={unregisteredPatient?.phoneNumber}
                        onChange={unregisteredPatient?.setPhoneNumber}
                        propertyIdentifier="PhoneNumber"
                        automationId="unregisteredPatientPhoneNumberTextBox"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={3}>
                    <Ui.TextBox
                        propertyIdentifier="Email"
                        label={StaticSchedulingResources.UnregisteredPatientPanel.Email}
                        value={unregisteredPatient?.email}
                        onChange={unregisteredPatient?.setEmail}
                        automationId="unregisteredPatientEmailTextBox"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={6}>
                    <Ui.TextBox
                        label={StaticSchedulingResources.UnregisteredPatientPanel.MailingAddress}
                        value={unregisteredPatient?.address}
                        onChange={unregisteredPatient?.setAddress}
                        automationId="unregisteredPatientAddressTextBox"
                        propertyIdentifier="MailingAddress"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex>
                <Ui.Flex.Item xs={3}>
                    <Ui.TextBox
                        label={StaticSchedulingResources.UnregisteredPatientPanel.InsuranceDocumentNumber}
                        value={unregisteredPatient?.insuranceDocumentNumber}
                        onChange={unregisteredPatient?.setInsuranceDocumentNumber}
                        propertyIdentifier="InsuranceDocumentNumber"
                        automationId="insucranceDocumentNumberTextBox"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={9}>
                    <Ui.TextBox
                        label={StaticSchedulingResources.UnregisteredPatientPanel.Note}
                        value={unregisteredPatient?.note}
                        onChange={unregisteredPatient?.setNote}
                        clearable={true}
                        automationId="unregisteredPatientNoteTextBox"
                        propertyIdentifier="Note"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </>
    );

    const patientDataForm =
        (
            <ValidationBoundary
                entityTypeName="CreateAppointmentCommand"
                pathPrefix="Appointment.Participants[0]"
            >
                <Ui.GroupBox
                    title={StaticSchedulingResources.UnregisteredPatientPanel.Patient}
                    style={{ marginRight: 10, marginLeft: 10 }}
                    isCollapsible={true}
                    isOpen={props.isPatientFormOpen}
                    onOpenStateChanged={props.changeDetailOpenState}
                >
                    {props.appointment && patientData}
                </Ui.GroupBox>
            </ValidationBoundary>
        );

    const appointmentDetails = (
        <ValidationBoundary
            entityTypeName="Appointment"
        >
            <Ui.GroupBox
                title={StaticSchedulingResources.RegisteredPatientAppointmentsMasterDetailPanel.SelectMedicalService}
                style={{ marginTop: 10, marginRight: 10, marginLeft: 10 }}>
                <AppointmentSchedulePanel
                    appointment={props.appointment}
                    subjectService={props.selectedSchedulingService}
                    _patientName={unregisteredPatient?.nameStore}

                    onValidateAsync={props.validateAsync}
                    onSaveAsync={props.onSaveAppointmentAsync}
                    readOnly={false}

                    onSetSelectedService={props.setSelectedSchedulingService}
                />
            </Ui.GroupBox>
            <NavigateAwayHook onNavigateAwayAsync={props.unloadAsync} />
        </ValidationBoundary>
    );

    const renderCore =
        (!props.appointment) ?
            (<></>) :
            (
                <ValidationBoundary
                    onValidateAsync={props.validateAsync}
                    validationResults={props.appointment?.validationResults || []}
                    validateOnMount
                >
                    {patientDataForm}
                    {appointmentDetails}
                </ValidationBoundary>
            );

    const renderFooterToolbar =
        (
            <HisUi.HisPanelButtonPortal>
                <Ui.Flex smJustify="end" verticalSpacing="none">
                    <Ui.Flex.Item>
                        <Ui.Button
                            text={StaticSchedulingResources.UnregisteredPatientPanel.Back}
                            visualStyle="primary"
                            onClick={props.onBack}
                            automationId="backButton" />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </HisUi.HisPanelButtonPortal>
        );

    return (
        <ReadOnlyContext.Consumer>
            {(readOnly) =>
                <>
                    <Ui.PageBox fullHeight>
                        <Ui.PageBox.Header>
                            <Ui.HeaderWithIcon
                                title={StaticSchedulingResources.UnregisteredPatientPanel.Appointment}
                                iconName="appointments"
                                subTitle={headerSubTitleText}
                            />
                        </Ui.PageBox.Header>
                        <Ui.PageBox.Body>
                            {renderCore}
                        </Ui.PageBox.Body>
                    </Ui.PageBox>
                    {renderFooterToolbar}
                </>
            }
        </ReadOnlyContext.Consumer>
    );
};

export default State.observer(UnregisteredPatientAppointmentPanelView);
