import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import NavigateToRouteActionProcessor from "@HisPlatform/Services/Implementation/ActionProcessing/NavigateToRouteActionProcessor";
import ShowMedicationsScreenAction from "@HunEHealthInfrastructurePlugin/Packages/MedicationRequest/FrontendActions/ShowMedicationsScreenAction.g";
import { getNavigationMenuItems } from "@HunEHealthInfrastructurePlugin/Packages/MedicationRequest/Configuration/NavigationMenuItems";
import GlobalActionRegistry from "@Toolkit/ReactClient/ActionProcessing/GlobalActionRegistry";
import medicationStandaloneRouteDefinitions from "@HunEHealthInfrastructurePlugin/UseCases/MedicationsUseCase/MedicationRoutes";

class MedicationRequestPackageInitializer implements IPackageInitializer {

    public configureActionProcessor(registry: GlobalActionRegistry) {

        registry.register(
            ShowMedicationsScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => medicationStandaloneRouteDefinitions.medications.makeRoute({ useCase: "null" })));
    }

    public configureDynamicNavigation(registry: IDynamicNavigationRegistry): void {
        getNavigationMenuItems().forEach(navigationItem => registry.register(navigationItem));
    }
}

export default new MedicationRequestPackageInitializer();