import DependencyContainer from "@DiContainer";

// DEVELOPER PAGE

import ToastStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/ToastStore";
DependencyContainer
    .bind("ToastStore")
    .to(ToastStore);

import DialogStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/DialogStore";
DependencyContainer
    .bind("DialogStore")
    .to(DialogStore);

import ModalExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/ModalExampleStore";
DependencyContainer
    .bind("ModalExampleStore")
    .to(ModalExampleStore);

import DocumentViewerModalExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/DocumentViewerModalExampleStore";
DependencyContainer
    .bind("DocumentViewerModalExampleStore")
    .to(DocumentViewerModalExampleStore);

import SelectorExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/SelectorExampleStore";
DependencyContainer
    .bind("SelectorExampleStore")
    .to(SelectorExampleStore);

import TextBoxExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/TextBoxExampleStore";
DependencyContainer
    .bind("TextBoxExampleStore")
    .to(TextBoxExampleStore);

import SelectBoxExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/SelectBoxExampleStore";
DependencyContainer
    .bind("SelectBoxExampleStore")
    .to(SelectBoxExampleStore);

import MasterDetailExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/MasterDetailExampleStore";
DependencyContainer
    .bind("MasterDetailExampleStore")
    .to(MasterDetailExampleStore);

import DatePickerExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/DatePickerExampleStore";
DependencyContainer
    .bind("DatePickerExampleStore")
    .to(DatePickerExampleStore);

import AccordionExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/AccordionExampleStore";
DependencyContainer
    .bind("AccordionExampleStore")
    .to(AccordionExampleStore);

import ButtonExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/ButtonExampleStore";
DependencyContainer
    .bind("ButtonExampleStore")
    .to(ButtonExampleStore);

import CheckBoxExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/CheckBoxExampleStore";
DependencyContainer
    .bind("CheckBoxExampleStore")
    .to(CheckBoxExampleStore);

import CheckBoxGroupExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/CheckBoxGroupExampleStore";
DependencyContainer
    .bind("CheckBoxGroupExampleStore")
    .to(CheckBoxGroupExampleStore);
   
import WorkflowStepsIndicatorExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/WorkflowStepsIndicatorExampleStore";
DependencyContainer
    .bind("WorkflowStepsIndicatorExampleStore")
    .to(WorkflowStepsIndicatorExampleStore);

import NumberBoxExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/NumberBoxExampleStore";
DependencyContainer
    .bind("NumberBoxExampleStore")
    .to(NumberBoxExampleStore);

import ContextMenuExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/ContextMenuExampleStore";
DependencyContainer
    .bind("ContextMenuExampleStore")
    .to(ContextMenuExampleStore);

import DateRangePickerExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/DateRangePickerExampleStore";
DependencyContainer
    .bind("DateRangePickerExampleStore")
    .to(DateRangePickerExampleStore);

import SidebarExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/SidebarExampleStore";
DependencyContainer
    .bind("SidebarExampleStore")
    .to(SidebarExampleStore);

import UniversalCodeSelectorExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/UniversalCodeSelectorExampleStore";
DependencyContainer
    .bind("UniversalCodeSelectorExampleStore")
    .to(UniversalCodeSelectorExampleStore);

import TabControlExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/TabControlExampleStore";
DependencyContainer
    .bind("TabControlExampleStore")
    .to(TabControlExampleStore);

import TooltipExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/TooltipExampleStore";
DependencyContainer
    .bind("TooltipExampleStore")
    .to(TooltipExampleStore);

import MultiSelectorExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/MultiSelectorExampleStore";
DependencyContainer
    .bind("MultiSelectorExampleStore")
    .to(MultiSelectorExampleStore);

import DrawerExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/DrawerExampleStore";
DependencyContainer
    .bind("DrawerExampleStore")
    .to(DrawerExampleStore);

import RadioButtonExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/RadioButtonExampleStore";
DependencyContainer
    .bind("RadioButtonExampleStore")
    .to(RadioButtonExampleStore);

import SettlementTextboxExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/SettlementTextboxExampleStore";
DependencyContainer
    .bind("SettlementTextboxExampleStore")
    .to(SettlementTextboxExampleStore);
