import React from "react";
import * as Styles from "./EhiSyncStatusLabel.less";
import EhiSyncStatus from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiSyncStatus";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import Icon from "@CommonControls/Icon";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import { Tooltip } from "@Toolkit/ReactClient/Components/Tooltip";
import StaticHunEHealthInfrastructureWebAppResources from "@HunEHealthInfrastructurePlugin/StaticResources/StaticHunEHealthInfrastructureWebAppResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IEhiSyncStatusLabelProps extends ICommonControlProps {
    value: EhiSyncStatus;
    upperIndex?: boolean;
    size?: "mini" | "extra large";
}

@State.observer
export default class EhiSyncStatusLabel extends React.Component<IEhiSyncStatusLabelProps> {
    @State.bound
    private getTooltipContent() {
        if (!isNullOrUndefined(this.props.tooltipContent)) {
            return this.props.tooltipContent;
        } else {
            if (this.props.value === EhiSyncStatus.InSync) {
                return StaticHunEHealthInfrastructureWebAppResources.EhiSyncStatusTexts.InSync;
            } else {
                return StaticHunEHealthInfrastructureWebAppResources.EhiSyncStatusTexts.NotInSync;
            }
        }
    }

    private arePropsValid(props: IEhiSyncStatusLabelProps) {
        return !isNullOrUndefined(props.value) && props.value !== EhiSyncStatus.NotSent;
    }

    public render() {
        if (isNullOrUndefined(this.props.value) || this.props.value === EhiSyncStatus.NotSent) {
            return null;
        }

        const mainContainerClassName = new CompositeClassName(Styles.mainContainer);
        mainContainerClassName.addIf(this.props.value === EhiSyncStatus.InSync, Styles.inSync);
        mainContainerClassName.addIf(this.props.value === EhiSyncStatus.NotInSync, Styles.notInSync);
        mainContainerClassName.addIf(this.props.upperIndex, Styles.upperIndex);

        const iconContainerClassName = new CompositeClassName(Styles.iconContainer);
        iconContainerClassName.addIf(this.props.value === EhiSyncStatus.InSync, Styles.iconInSync);
        iconContainerClassName.addIf(this.props.value === EhiSyncStatus.NotInSync, Styles.iconNotInSync);

        return (
            <Tooltip
                content={this.getTooltipContent()}
                contentAlignment={this.props.tooltipTextAlign}
                placement={this.props.tooltipPosition}>
                {
                    this.props.size === "extra large" ?
                    <div>
                        <Icon iconName={this.props.value === EhiSyncStatus.InSync ? "eeszt_uploaded" : "eeszt_not_uploaded"} visualStyle="original" size="extra large" />
                    </div>
                    : <div className={mainContainerClassName.classNames}>
                        <div className={iconContainerClassName.classNames}>
                            <Icon iconName={this.props.value === EhiSyncStatus.InSync ? "check" : "minus"} size="mini" visualStyle="primary" />
                        </div>
                        {StaticHunEHealthInfrastructureWebAppResources.EHI}
                    </div>
                }
            </Tooltip>
        );
    }
}