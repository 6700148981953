import React from "react";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import * as Ui from "@CommonControls";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import AppointmentCancellationDialogParams, { IAppointmentCancellationDialogResult } from "./AppointmentCancellationDialog/AppointmentCancellationDialogParams";
import PatientId from "@Primitives/PatientId.g";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import CareActivityId from "@Primitives/CareActivityId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import CareActivityBaseDataApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityBaseData/CareActivityBaseDataApiAdapter";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import PatientApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/Patient/PatientApiAdapter";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import AppointmentSchedulePanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentSchedulePanel/AppointmentSchedulePanel";
import moment from "moment";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import CareActivityBaseData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareActivityBaseData";
import ActionBoundButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundButton";
import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import AppointmentStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentStatus.g";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ShowPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientScreenAction.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";

interface IAppointmentDetailPanelDependencies {
    schedulingApiAdapter: SchedulingApiAdapter;
    notificationService: INotificationService;
    careActivityBaseDataApiAdapter: CareActivityBaseDataApiAdapter;
    careActivityApiAdapter: CareActivityApiAdapter2;
    structureApiAdapter: StructureApiAdapter;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
}

export interface IAppointmentDetailPanelProps {
    _dependencies?: IAppointmentDetailPanelDependencies;
    _modalService?: IModalService;
    appointmentId: AppointmentScheduleEntryId;
    serviceId?: SchedulingServiceId;
    afterDate?: moment.Moment;
    proposeAfterDate?: boolean;
    _patientId?: PatientId;
    _patient?: PatientAdministrativeData;
    _careActivityId?: CareActivityId;
    isAdmitPatientAllowed: boolean;
    onSave?: (type: "update" | "cancel", rowVersion?: RowVersion) => void;
    onCancelAppointment?: () => void;
    onAppointmentCreatedAsync: (id: AppointmentScheduleEntryId) => Promise<void>;

    onRenderCalendar?: (calendar: React.ReactNode) => React.ReactNode;
}

@State.observer
export class AppointmentDetailPanel extends React.Component<IAppointmentDetailPanelProps> {

    @State.observable.ref private appointment: Appointment = null;
    @State.observable.ref private isAdmitPatientAllowed: boolean = false;
    @State.observable.ref private selectedSchedulingService: SchedulingServiceSubjectStore = null;

    @State.computed
    private get isReadOnly() {
        return this.appointment?.id?.value !== "new" && this.appointment?.status !== AppointmentStatus.Booked;
    }

    @State.computed
    private get headerSubTitleText() {
        return `${this.selectedSchedulingService?.code ?? ""} ${this.selectedSchedulingService?.name ?? ""}`;
    }

    private careActivityBaseData: CareActivityBaseData;
    private referralExternalLocation: ExternalLocationId;

    private readonly panelResources = StaticSchedulingResources.RegisteredPatientAppointmentsMasterDetailPanel;

    private get schedulingApiAdapter() {
        return this.props._dependencies.schedulingApiAdapter;
    }

    private get notificationService() {
        return this.props._dependencies.notificationService;
    }

    private get modalService() {
        return this.props._modalService;
    }

    private get careActivityBaseDataApiAdapter() {
        return this.props._dependencies.careActivityBaseDataApiAdapter;
    }

    private get careActivityApiAdapter() {
        return this.props._dependencies.careActivityApiAdapter;
    }

    private get structureApiAdapter() {
        return this.props._dependencies.structureApiAdapter;
    }

    private get schedulingReferenceDataStore() {
        return this.props._dependencies.schedulingReferenceDataStore;
    }

    @State.computed
    public get showNewAdmitPatientScreenAction() {
        return ActionDescriptor.fromAction(
            new ShowNewAdmitPatientScreenAction(ScreenDisplayMode.Full, this.props._patientId, this.props.appointmentId, null)
        );
    }

    @State.computed
    public get showPatientScreenAction() {
        return ActionDescriptor.fromAction(
            new ShowPatientScreenAction(ScreenDisplayMode.Full, this.props._patientId, this.props.appointmentId)
        );
    }

    @State.action.bound
    private setValidationResult(validationResults: IClientValidationResult[]) {
        this.appointment.validationResults = validationResults;
    }

    @State.action.bound
    private setSelectedSchedulingService(id: SchedulingServiceId) {
        this.appointment.setSubjectService(id);
        this.appointment.pointOfCareId = null;
        this.appointment.practitionerIds = [];
        this.loadSelectedService();
    }

    @State.action.bound
    private loadSelectedService() {
        dispatchAsyncErrors(this.loadSchedulingServiceAsync(), this);
    }

    @State.action.bound
    private async loadSchedulingServiceAsync() {
        if (!!this.appointment.schedulingServiceId) {
            const item = await this.schedulingReferenceDataStore.schedulingServices.getOrLoadAsync(this.appointment.schedulingServiceId);
            State.runInAction(() => {
                this.selectedSchedulingService = item;
            });
        } else {
            State.runInAction(() => {
                this.selectedSchedulingService = null as SchedulingServiceSubjectStore;
            });
        }
    }

    @State.action.bound
    public async saveAsync(appointment: Appointment) {
        const newStore = appointment?.id?.value === "new"
            ? await this.schedulingApiAdapter.createAppointment(appointment)
            : await this.schedulingApiAdapter.updateAppointmentAsync(appointment);

        this.notificationService.showSaveResult(newStore.isPersistedByOperationInfo);

        if (newStore.hasValidationError) {
            this.setValidationResult(newStore.validationResults);
            return false;
        } else {
            if (this.appointment?.id?.value === "new") {
                await this.props.onAppointmentCreatedAsync(newStore.id);
            } else {
                this.props.onSave?.("update", newStore.rowVersion);
            }

            State.runInAction(() => {
                this.appointment = newStore;
            });
            return true;
        }
    }

    @State.bound
    private async getWorklistDataPermissionCheckAsync() {
        if (!this.props.appointmentId || this.props.appointmentId?.value === "new") {
            await this.schedulingApiAdapter.createAppointment(new Appointment(true), true);
        } else {
            await this.schedulingApiAdapter.getAppointmentByIdPermissionCheckAsync();
        }
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadPanelAsync, this.getWorklistDataPermissionCheckAsync);

    @State.bound
    private async validateAsync() {
        if (!this.appointment.isInvalid) {
            const result = await this.schedulingApiAdapter.validateAsync(this.appointment);
            return result.value;
        }

        return [];
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }
    public componentDidUpdate(prevProps: IAppointmentDetailPanelProps) {
        if (this.props !== prevProps && !ValueWrapper.equals(this.props.appointmentId, prevProps.appointmentId)) {
            dispatchAsyncErrors(this.loadPanelAsync(), this);
        }
    }
    private get renderDetailToolbar() {
        const admitActionDescriptor = !isNullOrUndefined(this.props._patient) && this.props._patient.isDataCleansingNeeded ? this.showPatientScreenAction : this.showNewAdmitPatientScreenAction;

        return (
            <>
                {this.appointment?.id?.value !== "new" &&
                    <>
                        {this.appointment?.status === AppointmentStatus.Booked &&
                            <Ui.Button iconName="trash"
                                visualStyle="negative-standard"
                                text={this.panelResources.CancelAppointment}
                                onClickAsync={this.cancelAppointmentAsync}
                                automationId="cancelAppointmentButton"
                            />
                        }
                        {this.appointment?.status === AppointmentStatus.Booked && this.isAdmitPatientAllowed && 
                            <ActionBoundButton iconName="add_healthcare" text={this.panelResources.AdmitPatient} actionDescriptor={admitActionDescriptor} automationId="admitPatientWithSelectedAppointmentButton" />
                        }
                    </>
                }
            </>
        );
    }

    private getNewAppointment(schedulingServiceId?: SchedulingServiceId, afterDate?: moment.Moment, proposeAfterDate?: boolean) {
        const newAppointment = new Appointment(true);
        newAppointment.id = new AppointmentScheduleEntryId("new");
        newAppointment.patientId = this.props._patientId;
        newAppointment.validationResults = [];

        if (this.careActivityBaseData) {
            newAppointment.referral.setCreatedAt(LocalDate.today());
            newAppointment.referral.setDoctorId(this.careActivityBaseData.primaryParticipant);
            newAppointment.referral.setReferralCareIdentifier(this.careActivityBaseData.primaryCareIdentifier);
            newAppointment.referral.setWorkPlace(this.referralExternalLocation);
        }

        if (!!schedulingServiceId) {
            newAppointment.setSubjectService(schedulingServiceId);
        }

        if (!!afterDate) {
            newAppointment.setIntervalFrom(afterDate);
        }

        return newAppointment;
    }

    @State.action.bound
    private async cancelAppointmentAsync() {
        const dialogResult = await this.modalService.showDialogAsync<IAppointmentCancellationDialogResult>(new AppointmentCancellationDialogParams(this.appointment?.id, true));

        if (dialogResult.appointmentDeleted) {
            this.notificationService.success(this.panelResources.AppointmentCancelled);
            this.props.onSave?.("cancel");
            this.props.onCancelAppointment?.();
        }
    }

    @State.action.bound
    private createNewAppointment(schedulingServiceId?: SchedulingServiceId, afterDate?: moment.Moment, proposeAfterDate?: boolean) {
        this.appointment = this.getNewAppointment(schedulingServiceId, afterDate, proposeAfterDate);
    }

    @State.bound
    private async loadCareActivityReferralDataAsync() {
        if (!this.props._careActivityId) {
            return;
        }
        const [baseDataResponse, careActivity] = await Promise.all([
            this.careActivityBaseDataApiAdapter.loadByIdAsync(this.props._careActivityId),
            this.careActivityApiAdapter.loadByIdAsync(this.props._careActivityId)
        ]);
        const externalUnitId = await this.structureApiAdapter.getExternalLocationsOfOrganizationUnitsAsync([new OrganizationUnitId(careActivity.pointOfCareId.value)], LocalDate.today());
        this.careActivityBaseData = baseDataResponse.result;
        this.referralExternalLocation = externalUnitId.value.length ? externalUnitId.value[0] : null;
    }

    @State.boundLoadingState()
    private async loadPanelAsync() {
        await this.loadCareActivityReferralDataAsync();
        if (!this.props.appointmentId || this.props.appointmentId?.value === "new") {
            this.createNewAppointment(this.props.serviceId, this.props.afterDate, this.props.proposeAfterDate);
        } else {
            const appointment = await this.schedulingApiAdapter.getAppointmentByIdAsync(this.props.appointmentId);
            State.runInAction(() => {
                this.appointment = appointment;
            });
        }

        State.runInAction(() => {
            this.isAdmitPatientAllowed = this.props.isAdmitPatientAllowed;
        });

        await this.loadSchedulingServiceAsync();
    }

    public render() {
        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }

        return this.appointment && (
            <UseCaseFrame
                title={this.appointment.id?.value === "new" ? this.panelResources.NewAppointment : this.panelResources.Appointment}
                toolbar={this.renderDetailToolbar}
                subTitle={this.headerSubTitleText}
            >
                <ValidationBoundary
                    validationResults={this.appointment.validationResults}
                    entityTypeName={"Appointment"}
                    onValidateAsync={this.validateAsync}
                    validateOnMount
                >
                    <AppointmentSchedulePanel
                        appointment={this.appointment}
                        subjectService={this.selectedSchedulingService}
                        onValidateAsync={this.validateAsync}
                        onSaveAsync={this.saveAsync}
                        readOnly={this.isReadOnly}
                        onRenderCalendar={this.props.onRenderCalendar}
                        onSetSelectedService={this.setSelectedSchedulingService}
                        proposeAfterDate={this.props.proposeAfterDate}
                    />
                </ValidationBoundary>
            </UseCaseFrame>
        );
    }
}

export default connect(
    AppointmentDetailPanel,
    new DependencyAdapter<IAppointmentDetailPanelProps, IAppointmentDetailPanelDependencies>(c => ({
        schedulingApiAdapter: c.resolve("SchedulingApiAdapter"),
        notificationService: c.resolve("INotificationService"),
        careActivityApiAdapter: c.resolve("CareActivityApiAdapter2"),
        careActivityBaseDataApiAdapter: c.resolve("CareActivityBaseDataApiAdapter"),
        schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore"),
        structureApiAdapter: c.resolve("StructureApiAdapter"),
        patientApiAdapter: c.resolve("PatientApiAdapter"),
        localizationService: c.resolve("ILocalizationService")
    })),
    new PatientContextAdapter<IAppointmentDetailPanelProps>(c => ({
        _patientId: c.patientId,
        _patient: c.patient
    })),
    new CareActivityContextAdapter<IAppointmentDetailPanelProps>(c => ({
        _careActivityId: c.careActivityId
    })),
    new HisModalServiceAdapter()
);
