import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import IMedicalServiceData from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/RelevantServicesWidget/IMedicalServiceData";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import PatientId from "@Primitives/PatientId.g";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import * as Proxy from "@HisPlatform/BoundedContexts/Reporting/Api/Proxy.g";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";

@Di.injectable()
export default class RelevantServicesWidgetApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IReportingClient") private readonly reportingClient: Proxy.IReportingClient
    ) {
        super();
    }

    public getDataAsync(dateRange: LocalDateRange, patientId: PatientId, filters: Identifier[]) {
        return this.processOperationAsync(
            new SimpleStore<IMedicalServiceData[]>(),
            async target => {
                const response = await this.reportingClient.getRelevantServicesQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetRelevantServicesControllerDto({
                        from: dateRange.from.toLocalDayStartMoment(),
                        to: dateRange.to.toLocalDayStartMoment(),
                        patientId: patientId,
                        serviceFilters: filters
                    } as Proxy.IGetRelevantServicesControllerDto)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.medicalServices.map(m => {
                    return {
                        service: { id: m.service.entityId, validOn: m.service.validOn } as IEntityVersionSelector<MedicalServiceId>,
                        pointOfCareId: m.pointOfCareId,
                        careActivityId: m.careActivityId,
                        createdAt: m.createdAt
                    } as IMedicalServiceData;
                });
            }
        );
    }
}