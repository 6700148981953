import { UniversalEnumSelector } from "@CommonControls";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import RecurrenceFrequency from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/RecurrenceFrequency";
import React from "react";

interface IRecurrenceFrequencySelectorProps extends IUniversalEnumSelectorBaseProps {
    value?: RecurrenceFrequency;
    onChange?: (newValue: RecurrenceFrequency) => void;
}


const RecurrenceFrequencySelector: React.FC<IRecurrenceFrequencySelectorProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumOrigin="client"
        enumName={"RecurrenceFrequency"}
        enumType={RecurrenceFrequency}
    />
);

export default RecurrenceFrequencySelector;