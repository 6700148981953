import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import CareActivityId from "@Primitives/CareActivityId.g";
import CareActivityDetailStoreMapper from "./CareActivityDetailStoreMapper";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import IPatientInCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/IPatientInCareActivityStore";

@Di.injectable()
export default class CareActivityDetailApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("ICareRegisterClient") private readonly apiClient: Proxy.ICareRegisterClient,
        @Di.inject("CareActivityDetailStoreMapper") private readonly careActivityDetailStoreMapper: CareActivityDetailStoreMapper
    ) {
        super();
    }

    public loadDetailsAsync(id: CareActivityId): Promise<IPatientInCareActivityStore> {
        return this.processOperationAsync(
            {
                hasTechnicalError: false,
                businessError: null,
                patientBaseData: null,
                patientId: null,
                operationInfo: null,
                isPersistedByOperationInfo: false,
                operationWasSuccessful: false,
            } as IPatientInCareActivityStore,
            async target => {
                const response = await this.apiClient.getCareActivityDetailsQueryAsync(
                    CreateRequestId(),
                    id.value
                );

                this.careActivityDetailStoreMapper.applyToStore(target, response);
            }
        );
    }
}