exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DocumentEditor_container_2QhC_ {\n  padding: 0;\n}\n.DocumentEditor_top-toolbar_3TXP- {\n  background-color: rgba(232, 235, 242, 0.5);\n  border: solid 1px #c3cad3;\n  border-bottom: none;\n}\n.DocumentEditor_toolbar-layout-body_1Hbrq {\n  border: solid 1px #c3cad3;\n}\n.DocumentEditor_column_335CT {\n  padding: 0 3px;\n}\n.DocumentEditor_column-separator_21Cxn {\n  border-left: solid 1px #c3cad3;\n  margin: 0 4px;\n  height: 32px;\n}\n.DocumentEditor_pager_QqWhu {\n  display: flex;\n  align-items: center;\n  padding: 0 5px;\n}\n.DocumentEditor_current-page_1m5Ra {\n  width: 40px;\n}\n.DocumentEditor_color-picker-container_1L1e5 {\n  width: 42px;\n}\n.DocumentEditor_style-select-container_2QvQ0,\n.DocumentEditor_zoom-factor-select-container_2tOKb,\n.DocumentEditor_border-line-width-select-container_1_cI7 {\n  width: 80px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "DocumentEditor_container_2QhC_",
	"top-toolbar": "DocumentEditor_top-toolbar_3TXP-",
	"topToolbar": "DocumentEditor_top-toolbar_3TXP-",
	"toolbar-layout-body": "DocumentEditor_toolbar-layout-body_1Hbrq",
	"toolbarLayoutBody": "DocumentEditor_toolbar-layout-body_1Hbrq",
	"column": "DocumentEditor_column_335CT",
	"column-separator": "DocumentEditor_column-separator_21Cxn",
	"columnSeparator": "DocumentEditor_column-separator_21Cxn",
	"pager": "DocumentEditor_pager_QqWhu",
	"current-page": "DocumentEditor_current-page_1m5Ra",
	"currentPage": "DocumentEditor_current-page_1m5Ra",
	"color-picker-container": "DocumentEditor_color-picker-container_1L1e5",
	"colorPickerContainer": "DocumentEditor_color-picker-container_1L1e5",
	"style-select-container": "DocumentEditor_style-select-container_2QvQ0",
	"styleSelectContainer": "DocumentEditor_style-select-container_2QvQ0",
	"zoom-factor-select-container": "DocumentEditor_zoom-factor-select-container_2tOKb",
	"zoomFactorSelectContainer": "DocumentEditor_zoom-factor-select-container_2tOKb",
	"border-line-width-select-container": "DocumentEditor_border-line-width-select-container_1_cI7",
	"borderLineWidthSelectContainer": "DocumentEditor_border-line-width-select-container_1_cI7"
};