import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import PointOfCareSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareSelectBox";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";

interface IOrganizationUnitsColumnDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IOrganizationUnitsColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IOrganizationUnitsColumnDependencies;
    displayMode?: "shorthand" | "name" | "shorthand-name";
    filterRenderer?: (filterProps: IDataGridColumnFilterProps) => React.ReactNode;
    filterIsMultiSelect?: boolean;
}


const OrganizationUnitsColumn: React.SFC<IOrganizationUnitsColumnProps> = (props: IOrganizationUnitsColumnProps) => {
    const filterValueSerializer: IDataGridFilterValueSerializer<OrganizationUnitId | OrganizationUnitId[]> = {
        deserialize: (value: string) => {
            if (!value) {
                return null;
            }
            const items = value.split(",");
            if (items.length === 1 && !props.filterIsMultiSelect) {
                return new OrganizationUnitId(items[0]);
            }

            return items.map(i => new OrganizationUnitId(i));
        },
        serialize: (value: OrganizationUnitId | OrganizationUnitId[]) => {
            return Array.isArray(value) ? value.map(v => v.value).join(",") : value.value;
        }
    };

    const valueRenderer = useCallback((value: OrganizationUnitId | OrganizationUnitId[]) => {
        if (!value) {
            return null;
        }

        if (Array.isArray(value)) {
            const usages = value.map(orgUnitId => {
                const orgUnit = props._dependencies.organizationReferenceDataStore.organizationUnitMap.get(orgUnitId);
                return orgUnit.code;
            }).join(", ");

            return (
                <Popper tooltipContent={usages} tooltipPlacement="bottom-start">
                    {usages}
                </Popper>
            );
        } else {
            const item = props._dependencies.organizationReferenceDataStore.organizationUnitMap.get(value);
            let text: React.ReactNode;

            switch (props.displayMode) {
                case "name":
                    text = item.baseData.naturalIdentifier.name;
                    break;
                case "shorthand":
                    text = item.baseData.naturalIdentifier.shortName;
                    break;
                case "shorthand-name":
                default:
                    text = <><b>{item.baseData.naturalIdentifier.shortName}</b>{` - ${item.baseData.naturalIdentifier.name}`}</>;
            }

            return text;
        }
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<OrganizationUnitId | OrganizationUnitId[]>) => {
        return <PointOfCareSelectBox {...filterProps} multiSelect hoverOnlyIndicatorIcons />;
    }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={props.filterRenderer ?? filterRenderer}
            filterValueSerializer={filterValueSerializer}
        />
    );
};

OrganizationUnitsColumn.defaultProps = {
    displayMode: "shorthand"
};

export default connect(
    OrganizationUnitsColumn,
    new DependencyAdapter<IOrganizationUnitsColumnProps, IOrganizationUnitsColumnDependencies>(c => ({
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
    }))
);
