import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import * as Ui from "@CommonControls";
import RoleAccessRule from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/RoleAccessRule";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Styles from "./RolePermissionConfigurationPanel.less";
import RoleSelectBox from "@HisPlatform/BoundedContexts/Authorization/Components/Controls/RoleSelectBox";
import AuthorizationReferenceDataStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/AuthorizationReferenceDataStore";
import RoleId from "@Primitives/RoleId.g";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";
import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";
import PointOfCareScopeSelector from "@HisPlatform/BoundedContexts/Authorization/Components/Controls/PointOfCareScopeSelector/PointOfCareScopeSelector";
import IPermissionScope from "@PluginInterface/OperationAccessControl/IPermissionScope";

interface IRolePermissionConfigurationPanelViewDependencies {
    authorizationReferenceDataStore: AuthorizationReferenceDataStore;
}

interface IRolePermissionConfigurationPanelViewProps {
    _dependencies?: IRolePermissionConfigurationPanelViewDependencies;
    accessRules: RoleAccessRule[];
    accessRuleToAdd: RoleAccessRule;
    addAccessRule: () => void;
    getPointOfCareScopeValue: (row: RoleAccessRule) => IPermissionScope;
    isRoleUsingScopeType: (id: RoleId, scopeType: string) => boolean;
    getPointOfCareNames: (ids: IPermissionScope) => string;
}

@State.observer
class RolePermissionConfigurationPanelView extends React.Component<IRolePermissionConfigurationPanelViewProps> {
    private get authorizationReferenceDataStore() {
        return this.props._dependencies.authorizationReferenceDataStore;
    }

    @State.computed
    private get alreadyAssignedRoles() {
        return this.props.accessRules.map(a => a.roleId);
    }

    @State.bound
    private getRoleName(row: RoleAccessRule) {
        return this.authorizationReferenceDataStore.roleMap.get(row.roleId)?.name;
    }

    @State.bound
    private renderFirstRow() {
        return (
            <tr className={Styles.newElementPanel}>
                <th>
                    <RoleSelectBox
                        value={this.props.accessRuleToAdd?.roleId}
                        onChange={this.props.accessRuleToAdd?.setRoleId}
                        excludedIds={this.alreadyAssignedRoles}
                        automationId="__newRoleSelectBox"
                    />
                </th>
                <th>
                    {this.renderPointOfCareSelector()}
                </th>
                <th>
                    <Ui.Button
                        onClick={this.props.addAccessRule}
                        iconName="check"
                        size="compact"
                        float="right"
                        visualMode="inverted"
                        className={Styles.marginRight}
                        automationId="__addNewRoleButton"
                    />
                </th>
            </tr>
        );
    }

    @State.bound
    private renderPointOfCareTypeColumn(value: IPermissionScope, row: RoleAccessRule, rowId: any, rowIndex: number) {
        // if the role is updated with a permission that introduced a new scope type
        // the value will be null so we have to explicitly check whether the role uses the pointOfCare scopeType or not
        const roleUsingPointOfCareScope = this.props.isRoleUsingScopeType(row.roleId, PermissionScopeType.pointOfCare);
        return (
            <>
                {(!!value || roleUsingPointOfCareScope) ? <PointOfCareScopeSelector
                    value={value}
                    onValueChange={row.assignPointOfCareTypeScope}
                    automationId={`__rolePointOfCareScopeSelector${rowIndex}`}
                /> : <i>n/a</i>}
            </>
        );
    }

    @State.bound
    private renderPointOfCareSelector() {
        const roleUsingPointOfCareScope = !this.props.accessRuleToAdd?.roleId ||
            this.props.isRoleUsingScopeType(this.props.accessRuleToAdd?.roleId, PermissionScopeType.pointOfCare);

        return (
            <>
                {roleUsingPointOfCareScope && <PointOfCareScopeSelector
                    value={this.props.accessRuleToAdd?.pointOfCareScope}
                    onValueChange={this.props.accessRuleToAdd?.assignPointOfCareTypeScope}
                    automationId="_newRolePointOfCareScopeSelector"
                />}
            </>
        );
    }

    @State.bound
    private renderRowButtons(value: any, row: RoleAccessRule, rowId: any, rowIndex: number) {
        return (
            <Ui.Button
                visualStyle="negative-standard"
                iconName="trash"
                size="compact"
                onClick={row.setIsDeleted}
                className={Styles.marginRight}
                automationId={`__deleteRoleButton${rowIndex}`}
            />
        );
    }

    @State.bound
    private renderPointOfCareHint(value: IPermissionScope) {
        return this.props.getPointOfCareNames(value);
    }
    public render() {
        return (
            <Ui.DataGrid
                dataSource={this.props.accessRules}
                onRenderHeader={this.renderFirstRow}
                rowHeight={44}
                fixedHeight="50%"
                propertyIdentifier=".*"
                automationId="RolePermissionsGrid"
            >
                <DataGridColumn
                    header={StaticAuthorizationResources.PermissionConfiguration.RolePermissionConfiguration.Role}
                    dataGetter={this.getRoleName}
                    width="45%"
                />
                <DataGridColumn
                    isObserver
                    header={StaticAuthorizationResources.PermissionConfiguration.RolePermissionConfiguration.PointOfCares}
                    dataGetter={this.props.getPointOfCareScopeValue}
                    onRenderCellValue={this.renderPointOfCareTypeColumn}
                    onRenderHintValue={this.renderPointOfCareHint}
                    width="45%"
                />
                <DataGridColumn
                    cellTextAlign="right"
                    width="10%"
                    onRenderCellValue={this.renderRowButtons}
                />
            </Ui.DataGrid>
        );
    }
}

export default connect(
    RolePermissionConfigurationPanelView,
    new DependencyAdapter<IRolePermissionConfigurationPanelViewProps, IRolePermissionConfigurationPanelViewDependencies>(c => ({
        authorizationReferenceDataStore: c.resolve("AuthorizationReferenceDataStore")
    }))
);