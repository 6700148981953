import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import PointOfCareRoles from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Actions/PointOfCareRoles";
import RoleId from "@Primitives/RoleId.g";
import UserGroup from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroup";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";

export interface IRoleEditorDialogParams extends IModalParams {
    onSave: (values: RoleId[]) => void;
    onCancel: () => void;
    pointOfCareRoles: PointOfCareRoles;
    relatedEntity: UserGroup | User;
    isNew: boolean;
    practitioner?: PractitionerStore;
    
}
export interface IRoleEditorDialogResult {
    selectedIds: RoleId[];
}

export default class RoleEditorDialogParams implements IRoleEditorDialogParams {

    public static type = "RoleEditorDialogParams";
    public get type() { return RoleEditorDialogParams.type; }

    constructor(
        public readonly onSave: (values: RoleId[]) => void,
        public readonly onCancel: () => void,
        public readonly pointOfCareRoles: PointOfCareRoles,
        public readonly relatedEntity: UserGroup | User,
        public readonly isNew: boolean, 
        public readonly practitioner?: PractitionerStore,
       
    ) { }

}