import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import _ from "@HisPlatform/Common/Lodash";
import AllergyIntoleranceAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/AllergyIntoleranceAssignmentBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicationId from "@Primitives/MedicationId.g";
import AllergyIntoleranceCategoryId from "@Primitives/AllergyIntoleranceCategoryId.g";
import MedicationVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/MedicationVersion";
import AllergyIntoleranceType from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/AllergiesAndIntolerances/Enum/AllergyIntoleranceType.g";

export default class MedicationAllergyIntoleranceAssignment extends AllergyIntoleranceAssignmentBase {

    @State.observable.ref public medicationVersionSelector: IEntityVersionSelector<MedicationId> = null;
    @State.observable.ref public type: AllergyIntoleranceType = AllergyIntoleranceType.Allergy;
    @State.observable.ref public categoryId: AllergyIntoleranceCategoryId = AllergyIntoleranceCategoryId.medication;
    @State.observable.ref public medicationVersion: MedicationVersion = null;

    @State.action.bound
    public setMedication(medicationId: MedicationId) {
        this.initializeCreatedAtIfEmpty();
        this.medicationVersionSelector = {id: medicationId, validOn: LocalDate.createFromMoment(this.createdAt)};
    }

    @State.action.bound
    public setType(newValue: AllergyIntoleranceType) {
        this.type = newValue;
    }
    @State.action.bound
    public setCategoryId(newValue: AllergyIntoleranceCategoryId) {
        this.categoryId = newValue;
    }
}