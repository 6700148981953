import React from "react";
import * as Ui from "@CommonControls";
import TraceLogger from "@HisPlatform/Common/TraceLogger";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import * as DiContainer from "@DiContainer";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";


interface ITraceLogPanelProps {
}

@State.observer
export default class TraceLogPanel extends React.Component<ITraceLogPanelProps> {

    @State.observable private isEnabled = TraceLogger.isEnabled();

    // This should be lazyResolve, because DI container context is not initialized at this point
    @DiContainer.lazyResolve("IFileSaverService") private fileSaverService: IFileSaverService;
    @DiContainer.lazyResolve("IDialogService") private dialogService: IDialogService;

    private readonly now = DateTimeService.now().format("YYYY-MM-DD_HH-mm-ss");

    private get traceLogger() {
        return global["tracer"] as TraceLogger;
    }

    @State.action.bound
    private setIsEnabled(isEnabled: boolean) {
        this.isEnabled = isEnabled;
        TraceLogger.setEnabled(isEnabled);
        window.location.reload();
    }

    @State.bound
    public downloadLog() {
        if (this.traceLogger) {
            this.fileSaverService.saveAs(this.traceLogger.getLogAsBlob(), `log_${this.now}.txt`);
        }
    }

    @State.bound
    public downloadLogReport() {
        if (this.traceLogger) {
            this.fileSaverService.saveAs(this.traceLogger.getLogReportAsBlob(), `log_${this.now}.html`);
        }
    }

    @State.bound
    public removeLog() {
        if (this.traceLogger) {
            const answer = confirm("Naozaj chcete odstrániť záznam sledovania?");
            if (answer === true) {
                this.traceLogger.clearLog();
            }
        }
    }

    public render() {
        return (
            <>
                <Ui.Flex>
                    <Ui.Flex.Item>
                        <Ui.CheckBox value={this.isEnabled} onChange={this.setIsEnabled} label="Tracelog povolený" automationId="isEnabledCheckBox" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item>
                        <Ui.Button onClick={this.downloadLog} automationId="downloadApplicationErrorLogButton">Stiahnite si súbor denníka</Ui.Button>
                        <Ui.Button onClick={this.downloadLogReport} automationId="downloadApplicationErrorLogReportButton" visualStyle="primary">Stiahnite si správu denníka</Ui.Button>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item>
                        <Ui.Button onClick={this.removeLog} automationId="removeLogButton">Odstrániť denníky</Ui.Button>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </>
        );
    }
}