import React from "react";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IPropertyListItem from "@CommonControls/PropertyList/IPropertyListItem";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import LateralityId from "@Primitives/LateralityId.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import RequestedServiceAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceAction.g";

interface ITextualObservationBodyDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    localizationService: ILocalizationService;
}

interface ITextualObservationBodyProps {
    _dependencies?: ITextualObservationBodyDependencies;
    row?: RequestedServiceStore;
    rowIndex?: number;
    onSetAdministrativeActionAsync: (item: RequestedServiceStore, newAction: RequestedServiceAction) => Promise<void>;
}

@State.observer
class TextualObservationBody extends React.Component<ITextualObservationBodyProps> {

    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get localizationService() { return this.props._dependencies.localizationService; }

    @State.computed private get properties(): IPropertyListItem[] {

        const observation = this.careReferenceDataStore.observationDefinition.get(this.props.row.textualObservation.observationDefinitionId);

        return [
            {
                name: StaticCareResources.ServiceRequestManagement.Administration.Label.MedicalService,
                value: observation.code + " - " + observation.name
            },
            this.props.row.lateralityId && !ValueWrapper.equals(this.props.row.lateralityId, LateralityId.NotApplicable) && {
                name: StaticCareResources.ServiceRequestManagement.Administration.Label.Laterality,
                value: this.localizationService.localizeEnumId(this.props.row.lateralityId).getShorthandAndName()
            } || null,
        ].filter(Boolean);
    }

    public render() {
        const actualObservation = this.props.row.textualObservation;
        if (actualObservation.isContentLoading) {
            return <></>;
        }
        return (
            <div style={{ padding: 4, width: "100%" }}>
                <Ui.PropertyList properties={this.properties} hasColonAfterNames boldValues />
                <ValidationBoundary
                    entityTypeName="Observation"
                    entityId={actualObservation?.observationId?.value}
                >
                    <HisUi.HisDocumentEditor
                        documentEditorHeight="200px"
                        mode="TextBlock"
                        contentStore={this.props.row.textualObservation.contentStore.forEditor}
                        isReadOnly={!this.props.row.isCompleteDiagnosticReportAllowed}
                    />
                </ValidationBoundary>

            </div>
        );
    }
}

export default connect(
    TextualObservationBody,
    new DependencyAdapter<ITextualObservationBodyProps, ITextualObservationBodyDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService"),
    }))
);