exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ServiceRequestStateBadge_state-under-recording_HkqQ7 {\n  background-color: #E8E8E8;\n  color: #4F5B72;\n  border: 2px dotted #8695a7;\n  line-height: 1.4;\n}\n.ServiceRequestStateBadge_state-submitted_14X-K {\n  background-color: #E8E8E8;\n  color: #4F5B72;\n}\n.ServiceRequestStateBadge_state-registered-as-received_2tVxU {\n  background-color: #82d4ed;\n  color: #fff;\n}\n.ServiceRequestStateBadge_state-activity-completed_32J11 {\n  background-color: #ddc00d;\n  color: #fff;\n}\n.ServiceRequestStateBadge_state-non-validated-completed_2rQOr {\n  background-color: #fe8443;\n  color: #fff;\n}\n.ServiceRequestStateBadge_state-validated-completed_2ScjD {\n  background-color: #21c313;\n  color: #fff;\n}\n.ServiceRequestStateBadge_state-deleted_wm9II {\n  background-color: #ce5374;\n  color: #fff;\n}\n", ""]);

// exports
exports.locals = {
	"state-under-recording": "ServiceRequestStateBadge_state-under-recording_HkqQ7",
	"stateUnderRecording": "ServiceRequestStateBadge_state-under-recording_HkqQ7",
	"state-submitted": "ServiceRequestStateBadge_state-submitted_14X-K",
	"stateSubmitted": "ServiceRequestStateBadge_state-submitted_14X-K",
	"state-registered-as-received": "ServiceRequestStateBadge_state-registered-as-received_2tVxU",
	"stateRegisteredAsReceived": "ServiceRequestStateBadge_state-registered-as-received_2tVxU",
	"state-activity-completed": "ServiceRequestStateBadge_state-activity-completed_32J11",
	"stateActivityCompleted": "ServiceRequestStateBadge_state-activity-completed_32J11",
	"state-non-validated-completed": "ServiceRequestStateBadge_state-non-validated-completed_2rQOr",
	"stateNonValidatedCompleted": "ServiceRequestStateBadge_state-non-validated-completed_2rQOr",
	"state-validated-completed": "ServiceRequestStateBadge_state-validated-completed_2ScjD",
	"stateValidatedCompleted": "ServiceRequestStateBadge_state-validated-completed_2ScjD",
	"state-deleted": "ServiceRequestStateBadge_state-deleted_wm9II",
	"stateDeleted": "ServiceRequestStateBadge_state-deleted_wm9II"
};