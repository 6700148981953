import React, { useCallback, useState } from "react";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IFormDefinitionSelectorDialogParams, IFormDefinitionSelectorDialogResult } from "./FormDefinitionSelectorDialogParams";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { Modal, Button } from "@CommonControls";
import FormDefinitionSelectBox from "@Toolkit/FormEngine/Components/FormDefinitionSelectBox";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";

interface IFormDefinitionSelectorDialogProps extends IModalComponentParams<IFormDefinitionSelectorDialogResult>, IFormDefinitionSelectorDialogParams {
}

function FormDefinitionSelectorDialog(props: IFormDefinitionSelectorDialogProps) {

    const cancel = useCallback(() => props.onClose({ formDefinitionId: null }), [props.onClose]);
    const [id, setId] = useState<FormDefinitionId | null>(null);
    const ok = useCallback(() => props.onClose({ formDefinitionId: id }), [props.onClose, id]);

    return (
        <Modal isOpen onClose={cancel} size="compact" title={StaticFormEngineResources.FormDefinitionSelector.Title} closeButton>
            <Modal.Body>
                <FormDefinitionSelectBox
                    cultureCodeProvider={props.cultureCodeProvider}
                    value={id}
                    onChange={setId}
                    apiAdapter={props.apiAdapter}
                    baseEntityType={props.baseEntityType}
                    formDefinitions={props.formDefinitions}
                    automationId="formDefinition_selectBox" />
            </Modal.Body>
            <Modal.Footer
                left={(
                    <Button visualStyle="link" text={StaticFormEngineResources.FormDefinitionSelector.CancelButtonLabel} onClick={cancel} automationId="cancel" />
                )}
                right={(
                    <Button visualStyle="primary" text={StaticFormEngineResources.FormDefinitionSelector.OkButtonLabel} onClick={ok} automationId="ok" />
                )} />
        </Modal>
    );
}

export default State.observer(FormDefinitionSelectorDialog);