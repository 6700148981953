import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import ContagiousPatientReportOnsetDateWithTypeData from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/ContagiousPatientReportTypeWithOnsetColumn/ContagiousPatientReportTypeWithOnsetData";
import * as Ui from "@CommonControls";
import ContagiousPatientReportType from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReportType";
import { iconNameType } from "@CommonControls/Icon";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface IContagiousPatientReportTypeWithOnsetColumnDependencies {
    localizationService: ILocalizationService;
}
interface IContagiousPatientReportTypeWithOnsetColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IContagiousPatientReportTypeWithOnsetColumnDependencies;
}


const ContagiousPatientReportTypeWithOnsetColumn: React.FC<IContagiousPatientReportTypeWithOnsetColumnProps> = (props: IContagiousPatientReportTypeWithOnsetColumnProps) => {
    const valueRenderer = useCallback((value: ContagiousPatientReportOnsetDateWithTypeData) => {
        const iconName: iconNameType =
            value.type === ContagiousPatientReportType.OnsetReport ? "in" : "out";
        
        const formattedDateValue = !value.onsetDate ? "N/A" : props._dependencies.localizationService.localizeDate(value.onsetDate);
        return (
            <>
                <label style={{marginRight: "10px"}}>{formattedDateValue}</label><Ui.Icon iconName={iconName} />
            </>
        );
    }, []);

    return (
        <DataGridColumn
            width={undefined}
            {...props}
            onRenderCellValue={valueRenderer}
            isFilterable={false}
            isOrderable={false}
            showHint={false}
            isObserver
        />
    );
};

export default connect(
    ContagiousPatientReportTypeWithOnsetColumn,
    new DependencyAdapter<IContagiousPatientReportTypeWithOnsetColumnProps, IContagiousPatientReportTypeWithOnsetColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService")
    })
));