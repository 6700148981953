import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import MedicationSubsidy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationSubsidy";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import MedicationSubsidyId from "@Primitives/MedicationSubsidyId.g";
import MedicationSubsidyOptionId from "@Primitives/MedicationSubsidyOptionId.g";

interface ISubsidyOptionSelectorProps extends ISelectBoxBaseProps {
    subsidy: MedicationSubsidy;
}

/** @screen */
@State.observer
export default class SubsidyOptionSelector extends React.Component<ISubsidyOptionSelectorProps> {

    @State.computed get options() {
        return this.props.subsidy?.options || [];
    }

    @State.computed get items() {
        return this.options.map(i => ({
            value: {subsidyId: this.props.subsidy?.id, optionId: i.id},
            text: i.code,
            longDisplayValue: i.description
        } as ISelectBoxItem));
    }

    private equalityComparer(one: {subsidyId: MedicationSubsidyId, optionId: MedicationSubsidyOptionId}, other: {subsidyId: MedicationSubsidyId, optionId: MedicationSubsidyOptionId}) {
        return ValueWrapper.equals(one?.subsidyId, other?. subsidyId) && ValueWrapper.equals(one?.optionId, other?.optionId);
    }

    public render() {
        return (
            <Ui.SelectBox
                {...this.props}
                items={this.items}
                autoSelectSingleItem
                equalityComparer={this.equalityComparer}
            />
        );
    }
}