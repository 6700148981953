
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import FilterBase from "./FilterBase";

export default class IncludeIdentifierFilter extends FilterBase {
    private _value: Identifier;

    constructor(value: Identifier) {
        super();
        this._value = value;
    }

    public get value(): Identifier {
        return this._value;
    }

    public setValue(identifier: Identifier) {
        this._value = identifier;
    }
}