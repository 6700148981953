type AsyncAction<T extends (...args: any[]) => Promise<any>> = (...argsIn: Parameters<T>) => Promise<void>;

interface IManualPromise<T extends (...args: any[]) => Promise<any>, TResult = any> extends AsyncAction<T> {
    resolve: (result?: TResult) => void;
}

const nullResolver = (result?: any) => { /* nop */ };

export function createManualPromise<T extends (...args: any[]) => Promise<any>, TResult = any>(
    asyncAction: T,
    timeoutInMs: number = 10000
): IManualPromise<T, TResult> {
    
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const target = this;
    const me = (...args: Parameters<T>): Promise<void> => {
        if (me.resolve !== nullResolver) {
            throw new Error("Manual promise does not support concurrent calls. Maybe you have not resolved the manualPromise function, call 'this.manualPromiseFunction.resolve();'.");
        }

        return new Promise((resolve: (result?: any) => void, reject: (e: any) => void) => {

            const timeout = timeoutInMs === 0 ? null : setTimeout(() => {
                if (timeout !== null) {
                    clearTimeout(timeout);
                }
                me.resolve = nullResolver;
                reject("Timeout");
            }, timeoutInMs);

            me.resolve = (result: any) => {
                if (timeout !== null) {
                    clearTimeout(timeout);
                }
                me.resolve = nullResolver;
                resolve(result);
            };

            asyncAction
                .apply(target, args)
                .catch((e: any) => {
                    if (timeout !== null) {
                        clearTimeout(timeout);
                    }
                    me.resolve = nullResolver;
                    reject(e);
                });
        });
    };
    me.resolve = nullResolver;
    return me as IManualPromise<T>;
}