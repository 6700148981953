import React from "react";
import AppointmentScheduleDefinition from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentScheduleDefinition";
import * as Ui from "@CommonControls";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import AppointmentScheduleDefinitionInfoExtension from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentScheduleDefinitionInfoExtension/AppointmentScheduleDefinitionInfoExtension";
import ExtensionController from "@HisPlatform/Components/HisPlatformExtensionPoint/ExtensionController";

interface IAppointmentScheduleDefinitionBaseDataPanelViewProps {
    store: AppointmentScheduleDefinition;
    onValidateAsync: () => Promise<IClientValidationResult[]>;
    handlePlanningPeriod?: boolean;
    extensionController?: ExtensionController,
}

const AppointmentScheduleDefinitionBaseDataPanelView: React.FC<IAppointmentScheduleDefinitionBaseDataPanelViewProps> = props => {

    if (!props.store) {
        return (<></>);
    }
    return (
        <ValidationBoundary
            validationResults={props.store?.validationResults || []}
            entityTypeName={"AppointmentScheduleDefinition"}
            onValidateAsync={props.onValidateAsync}
            validateOnMount
        >
            <Ui.Flex>
                <Ui.Flex.Item xs={8}>
                    <Ui.TextBox
                        label={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.BaseData.Name}
                        value={props.store.name}
                        onChange={props.store.setName}
                        propertyIdentifier="Name"
                        automationId="name"
                    />
                </Ui.Flex.Item>
                {props.extensionController &&
                <Ui.Flex.Item xs={4}>
                    <AppointmentScheduleDefinitionInfoExtension 
                        store={props.store}
                        extensionController={props.extensionController}
                    />
                </Ui.Flex.Item>}
            </Ui.Flex>
            <Ui.Flex>
                <Ui.Flex.Item xs={12}>
                    <Ui.TextBox
                        label={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.BaseData.Description}
                        value={props.store.description}
                        onChange={props.store.setDescription}
                        propertyIdentifier="Description"
                        automationId="description"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
            {
                props.handlePlanningPeriod && (
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <Ui.DateRangePicker
                                label={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.PlanningPeriod.Interval}
                                automationId="__planningPeriodInterval"
                                value={props.store.selectedPlanningPeriod.interval}
                                onChange={props.store.selectedPlanningPeriod.setInterval}
                                propertyIdentifier="PlanningPeriodInterval"
                                initialSelectionState="from"
                            ></Ui.DateRangePicker>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                )
            }
        </ValidationBoundary>
    );
};

export default State.observer(AppointmentScheduleDefinitionBaseDataPanelView);
