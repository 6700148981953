import State, { IObservableValue, IReactionDisposer } from "@Toolkit/ReactClient/Common/StateManaging";
import LoadingContextStore from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingContextStore";

let loadingStateIndex = 0;
export default class LoadingStateStore {
    private loadingStates = State.createObservableShallowArray<IObservableValue<boolean>>([]);
    private loadingStateKey = `LoadingStateStore_${loadingStateIndex++}`;
    private loadingStateReactionDisposer: IReactionDisposer = null;

    // constructor() {
    //     State.autorun(() => {
    //         console.log(JSON.stringify(this.loadingStates.map(it => `${(it as any).name}=${it.get()}`), null, "    "));
    //     });
    // }

    @State.computed public get isLoading() {
        return this.loadingStates.some(ls => ls.get());
    }

    @State.action.bound
    public add(loadingState: IObservableValue<boolean>): void {
        this.loadingStates.push(loadingState);
    }

    @State.action.bound
    public cleanup(): void {
        this.loadingStateReactionDisposer?.();
        this._loadingContextStore?.clearLoadingState(this.loadingStateKey);
        this.loadingStates.replace([]);
    }

    private _loadingContextStore: LoadingContextStore;

    public setLoadingBoundaryStore(loadingContextStore: LoadingContextStore) {
        this._loadingContextStore = loadingContextStore;
        this.loadingStateReactionDisposer?.();
        this.loadingStateReactionDisposer = State.reaction(() => this.isLoading, isLoading => {
            if (isLoading) {
                loadingContextStore?.setLoadingState(this.loadingStateKey);
            } else {
                loadingContextStore?.clearLoadingState(this.loadingStateKey);
            }
        });
    }
}