import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import * as Ui from "@CommonControls";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import _ from "@HisPlatform/Common/Lodash";
import EditableExternalLocationStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/External/EditableExternalLocationStore";
import ExternalLocationTypeSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/ExternalLocationTypeSelectBox";
import ExternalLocationIdentifierView from "@HisPlatform/BoundedContexts/Organization/Components/Panels/ExternalLocation/ExternalLocationIdentifierView";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";

const renderIdentifiers = (item: Identifier, index: number) => <ExternalLocationIdentifierView identifier={item} index={index} />;

interface IExternalLocationPanelViewProps {
    store: EditableExternalLocationStore;
    onSaveAsync: () => Promise<void>;
    onValidateAsync?: (dirtyFields?: string[]) => Promise<IClientValidationResult[]>;
    onNavigateAwayAsync: () => Promise<boolean>;
    createIdentifier: () => Promise<Identifier>;
    onDeleteIdentifierConfirmationAsync: (identifier: Identifier) => Promise<boolean>;
}

@State.observer
export default class ExternalLocationPanelView extends React.Component<IExternalLocationPanelViewProps> {

    private get resources() { return StaticOrganizationResources.ExternalLocationPanel; }

    @State.bound
    private renderDetailToolbar() {
        return (
            <>
                {
                    <Ui.SaveButton
                        onClickAsync={this.props.onSaveAsync}
                        visualStyle="primary"
                        automationId="saveButton"
                    />
                }
            </>
        );
    }

    public render() {
        return (
            <UseCaseFrame
                toolbar={this.renderDetailToolbar()}
                title={this.resources.Labels.DetailTitle}
            >
                <>
                    <ValidationBoundary
                        validationResults={this.props.store.validationResults}
                        entityTypeName="ExternalLocation"
                        onValidateAsync={this.props.onValidateAsync}
                        validateOnMount
                    >
                        <Ui.TextBox
                            label={this.resources.Labels.ShortName}
                            value={this.props.store.shortName}
                            onChange={this.props.store.setShortName}
                            propertyIdentifier="ShortName"
                            automationId="shortName"
                        />
                        <Ui.TextBox
                            label={this.resources.Labels.Name}
                            value={this.props.store.name}
                            onChange={this.props.store.setName}
                            propertyIdentifier="Name"
                            automationId="name"
                        />
                        <ExternalLocationTypeSelectBox
                            value={this.props.store.externalLocationTypes}
                            onChange={this.props.store.setExternalLocationTypes}
                            multiSelect
                            label={this.resources.Labels.ExternalLocationTypes}
                            propertyIdentifier="ExternalLocationTypes"
                            automationId="externalLocationTypes"
                        />
                        <ListPanel<Identifier>
                            title={this.resources.Labels.Identifiers}
                            alwaysEdit
                            items={this.props.store.identifiers}
                            renderItemEditor={renderIdentifiers}
                            onDeleteItemConfirmationAsync={this.props.onDeleteIdentifierConfirmationAsync}
                            onRemoveItem={this.props.store.removeIdentifier}
                            onCreateNewAsync={this.props.createIdentifier}
                            propertyIdentifier="Identifiers"
                            isCompactEmptyState
                            automationId="identifiers"
                        />
                    </ValidationBoundary>
                    <NavigateAwayHook onNavigateAwayAsync={this.props.onNavigateAwayAsync} />
                </>
            </UseCaseFrame>
        );
    }
}