import React from "react";
import PointOfCareSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareSelectBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import {dispatchAsyncErrors} from "@Toolkit/CommonWeb/AsyncHelpers";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import {DisplayMode} from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";

interface IEhiOrganizationUnitSelectorProps {
    value: PointOfCareId;
    onChange: (newValue: PointOfCareId) => void;
    label?: string;
    displayMode?: DisplayMode;
    automationId: string;
    propertyIdentifier?: string;
    _dependencies?: IEhiOrganizationUnitSelectorDependencies;
}

interface IEhiOrganizationUnitSelectorDependencies {
    practitionerApiAdapter: PractitionerApiAdapter;
    userContext: UserContext;
}

@State.observer 
class EhiOrganizationUnitSelector extends React.Component<IEhiOrganizationUnitSelectorProps> {
    private get practitionerApiAdapter() { return this.props._dependencies.practitionerApiAdapter; }
    private get userContext() { return this.props._dependencies.userContext; }
    
    @State.observable.ref private organizationAssignments: PointOfCareId[] = null;
    @State.observable private isLoading: boolean = false;
    
    @State.action.bound
    private setOrganizationAssignments(newValue: PointOfCareId[]) {
        this.organizationAssignments = newValue;
    }
    
    @State.boundLoadingState("isLoading")
    private async getDoctorsOrganizationAssignmentsAsync() {
        const practitionerId = this.userContext.practitionerId;
        const response = await this.practitionerApiAdapter.getPointOfCareIdsByPractitionerIdsAsync([practitionerId]);
        this.setOrganizationAssignments(response.value);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.getDoctorsOrganizationAssignmentsAsync(), this);
    }
    
    public render() {
        return (
            <PointOfCareSelectBox
                label={this.props.label}
                value={this.props.value}
                onChange={this.props.onChange}
                explicitIds={this.organizationAssignments}
                displayMode={this.props.displayMode}
                automationId={this.props.automationId}
                propertyIdentifier={this.props.propertyIdentifier}
                isLoading={this.isLoading}
            />
        );
    }
}

export default connect(
    EhiOrganizationUnitSelector,
    new DependencyAdapter<IEhiOrganizationUnitSelectorProps, IEhiOrganizationUnitSelectorDependencies>(c => ({
        practitionerApiAdapter: c.resolve("PractitionerApiAdapter"),
        userContext: c.resolve("UserContext")
    }))
);