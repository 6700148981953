import DependencyContainer from "@DiContainer";
import ConfigurationDynamicPropertiesApiAdapter from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/ApiAdapter/DynamicProperties/ConfigurationDynamicPropertiesApiAdapter";
import ConfigurationPropertyGroupStoreMapper from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/ApiAdapter/DynamicProperties/ConfigurationPropertyGroupStoreMapper";
import ConfigurationStatusApiAdapter from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/ApiAdapter/Status/ConfigurationStatusApiAdapter";

DependencyContainer
    .bind("ConfigurationDynamicPropertiesApiAdapter")
    .to(ConfigurationDynamicPropertiesApiAdapter)
    .inSingletonScope();
    
DependencyContainer
    .bind("ConfigurationPropertyGroupStoreMapper")
    .to(ConfigurationPropertyGroupStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("ConfigurationStatusApiAdapter")
    .to(ConfigurationStatusApiAdapter)
    .inSingletonScope();
