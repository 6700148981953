import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import DataGridExtensibleEnumColumn, { IExtensibleEnumColumnProps } from "@CommonControls/DataGrid/Column/DataGridExtensibleEnumColumn";

interface IExternalLocationTypesColumnDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IExternalLocationTypesColumnProps extends IExtensibleEnumColumnProps {
    _dependencies?: IExternalLocationTypesColumnDependencies;
}


const ExternalLocationTypesColumn: React.SFC<IExternalLocationTypesColumnProps> = (props: IExternalLocationTypesColumnProps) => {
    return (
        <DataGridExtensibleEnumColumn
            extensibleEnumStore={props._dependencies.organizationReferenceDataStore.externalLocationTypes}
            header={"ExternalLocationTypes"}
            {...props}
        />
    );
};

export default connect(
    ExternalLocationTypesColumn,
    new DependencyAdapter<IExternalLocationTypesColumnProps, IExternalLocationTypesColumnDependencies>(c => ({
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore")
    }))
);