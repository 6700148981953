exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PerformedServicesPanel_margin-right_30tUn {\n  margin-right: 16px;\n}\n.PerformedServicesPanel_first-row_15MZo {\n  background-color: #82d4ed;\n  width: 100%;\n  margin: 0;\n  height: 58px;\n}\n.PerformedServicesPanel_first-row_15MZo th:first-child {\n  padding-left: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"margin-right": "PerformedServicesPanel_margin-right_30tUn",
	"marginRight": "PerformedServicesPanel_margin-right_30tUn",
	"first-row": "PerformedServicesPanel_first-row_15MZo",
	"firstRow": "PerformedServicesPanel_first-row_15MZo"
};