exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DocumentViewer_content_3Oe5_ {\n  margin: 8px;\n  width: calc(100% - 16px);\n  border: 1px solid #cdcdcd;\n}\n", ""]);

// exports
exports.locals = {
	"content": "DocumentViewer_content_3Oe5_"
};