import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import Di from "@Di";
import FormExtensionBase from "@PluginInterface/FormExtension/FormExtensionBase";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiEReferralData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralData";
import EhiErrors from "@HunEHealthInfrastructurePlugin/Common/Model/EhiErrors";
import EhiCareServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareServiceFactory";
import EhiCareService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareService";
import EhiUserType from "@HunEHealthInfrastructurePlugin/Model/EhiUserType";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";

@Di.injectable()
export default class ManageServiceRequestExtension extends FormExtensionBase<ServiceRequestStore> {

    private ehiCareService: EhiCareService = null;

    constructor(
        @Di.inject("EhiCareServiceFactory") private readonly ehiCareServiceFactory: EhiCareServiceFactory
    ) {
        super();

        this.registerAsyncCallback<any>("ExtendServiceRequestForDelete", this.getExtensionDataForDeleteAsync);
        this.registerCallback<boolean>("CheckServiceRequestForErrors", this.checkExtensionDataForErrors);
        this.ehiCareService = this.ehiCareServiceFactory.getEhiCareService();
    }

    @State.action.bound
    private checkExtensionDataForErrors(serviceRequest: ServiceRequestStore) {
        const ehiErrors = EhiErrors.createFromExtensionDto(serviceRequest.extensionData);
        return ehiErrors.length > 0;
    }

    @State.action.bound
    private async getExtensionDataForDeleteAsync(serviceRequest: ServiceRequestStore) {
        const ehiEReferralData = EhiEReferralData.createFromExtensionDto(serviceRequest.extensionData, false);

        if (isNullOrUndefined(ehiEReferralData?.id)) {
            return null;
        }

        const extensionData: any = {};

        this.ehiCareService.setLoginToEhiWithAnotherUserConfirmationDialogText(
            StaticHunEHealthInfrastructureCareResources.EReferralPanel.LoginToEhiWithRequesterConfirmationDialog.Title,
            StaticHunEHealthInfrastructureCareResources.EReferralPanel.LoginToEhiWithRequesterConfirmationDialog.Message);
        await this.ehiCareService.setEhiAccessDataWithoutOrganizationUnitCheckToExtensionDataAsync(extensionData, EhiUserType.Doctor, null, serviceRequest.requesterDoctorId);

        return extensionData;
    }
}