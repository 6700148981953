import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import Di from "@Di";
import CommonReferenceDataExtensibleEnumApiAdapter from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/CommonReferenceDataExtensibleEnumApiAdapter";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import EducationLevelId from "@Primitives/EducationLevelId.g";
import MaritalStatusId from "@Primitives/MaritalStatusId.g";
import GenderId from "@Primitives/GenderId.g";
import ReligionId from "@Primitives/ReligionId.g";
import CountryApiAdapter from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/CountryApiAdapter";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import SettlementApiAdapter from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/SettlementApiAdapter";
import IdentifierSystemApiAdapter from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/IdentifierSystemApiAdapter";
import AddressTypeId from "@Primitives/AddressTypeId.g";
import TelecomUseId from "@Primitives/TelecomUseId.g";
import LocalizedReferenceDatStore from "@Toolkit/CommonWeb/ReferenceData/LocalizedReferenceDataStore";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import IdentifierSystem from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/IdentifierSystem";
import TelecomTypeId from "@Primitives/TelecomTypeId.g";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";

@Di.injectable()
export default class CommonReferenceDataDataStore extends ReferenceDataStoreBase {

    public educationLevel = new ExtensibleEnumStore<EducationLevelId>(this.apiAdapter.loadEducationLevelIdsAsync, this.localizationService.localizeEnumId);
    public maritalStatus = new ExtensibleEnumStore<MaritalStatusId>(this.apiAdapter.loadMaritalStatusIdsAsync, this.localizationService.localizeEnumId);
    public gender = new ExtensibleEnumStore<GenderId>(this.apiAdapter.loadGenderIdsAsync, this.localizationService.localizeEnumId);
    public religion = new ExtensibleEnumStore<ReligionId>(this.apiAdapter.loadReligionIdsAsync, this.localizationService.localizeEnumId);
    public occupation = new ExtensibleEnumStore<ReligionId>(this.apiAdapter.loadOccupationIdsAsync, this.localizationService.localizeEnumId);
    
    public countryMap = new ReferenceDataStore(this.countryApiAdapter.getCountriesByIdsAsync);
    public settlementMap = new ReferenceDataStore(this.settlementApiAdapter.getSettlementsByIdsAsync, this.settlementApiAdapter.getAllSettlementIdsAsync);
    public identifierSystemMap = new LocalizedReferenceDatStore<IdentifierSystemId, IdentifierSystem>(this.identifierSystemApiAdapter.getAllIdentifierSystemsAsync,
        this.localizationService.localizeEnum, (item) => item.id.value, "IdentifierSystem", this.identifierSystemApiAdapter.getAllIdentifierSystemIdsAsync);

    public addressType = new ExtensibleEnumStore<AddressTypeId>(this.apiAdapter.loadAddressTypesAsync, this.localizationService.localizeEnumId);
    public telecomUse = new ExtensibleEnumStore<TelecomUseId>(this.apiAdapter.loadTelecomUsesAsync, this.localizationService.localizeEnumId);
    public telecomType = new ExtensibleEnumStore<TelecomTypeId>(this.apiAdapter.loadTelecomTypesAsync, this.localizationService.localizeEnumId);

    constructor(
        @Di.inject("CommonReferenceDataExtensibleEnumApiAdapter") private readonly apiAdapter: CommonReferenceDataExtensibleEnumApiAdapter,
        @Di.inject("CountryApiAdapter") private readonly countryApiAdapter: CountryApiAdapter,
        @Di.inject("ILocalizationService") private readonly localizationService: ILocalizationService,
        @Di.inject("SettlementApiAdapter") private readonly settlementApiAdapter: SettlementApiAdapter,
        @Di.inject("IdentifierSystemApiAdapter") private readonly identifierSystemApiAdapter: IdentifierSystemApiAdapter,
        @Di.inject("IReferenceDataLoader") private readonly referenceDataLoader: IReferenceDataLoader
    ) {
        super();

        referenceDataLoader.register<GenderId>(GenderId.typeName, () => this.gender.ensureLoadedAsync());
        referenceDataLoader.register<IdentifierSystemId>(IdentifierSystemId.typeName, refs => this.identifierSystemMap.ensureLoadedAsync(refs));
        referenceDataLoader.register("PatientPhoneNumbersDto", () => this.telecomUse.ensureLoadedAsync());
    }
}
