import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { StringRadioButtonGroup } from "@CommonControls/RadioButtonGroup";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";

interface ILocationClassRadioButtonGroupDependencies {
    localizationService: ILocalizationService;
}

interface ILocationClassRadioButtonGroupProps {
    _dependencies?: ILocationClassRadioButtonGroupDependencies;
    value?: boolean;
    onChange?: (newValue: boolean) => void;
    label?: string;
}

@State.observer
class LocationClassRadioButtonGroup extends React.Component<ILocationClassRadioButtonGroupProps> {

    @State.observable private items = [
        StaticWebAppResources.Reporting.LocationClass.All,
        StaticWebAppResources.Reporting.LocationClass.External,
        StaticWebAppResources.Reporting.LocationClass.Internal];
    
    @State.computed private get selectedValue() {
        if (this.props.value !== null) {
            return this.props.value ? StaticWebAppResources.Reporting.LocationClass.External : StaticWebAppResources.Reporting.LocationClass.Internal;
        }
        return StaticWebAppResources.Reporting.LocationClass.All;
    }
    
    @State.action.bound
    public setSelectedValue(newValue: string) {
        this.props.onChange(newValue === StaticWebAppResources.Reporting.LocationClass.All ? null : 
            newValue === StaticWebAppResources.Reporting.LocationClass.External ? true : false);
    }

    public render() {
        return (
            <StringRadioButtonGroup
                        label={this.props.label}
                        direction="row"
                        displayType="buttons"
                        items={this.items}
                        value={this.selectedValue}
                        onChange={this.setSelectedValue} />
        );
    }
}

export default connect(
    LocationClassRadioButtonGroup,
    new DependencyAdapter<ILocationClassRadioButtonGroupProps, ILocationClassRadioButtonGroupDependencies>(container => {
        return { 
            localizationService: container.resolve("ILocalizationService")
        };
    })
);