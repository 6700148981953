import { iconNameType } from "@CommonControls/Icon";
import React from "react";

export interface IPageBox2TabBodyProps {
    tabName: string;
    tabTitle: React.ReactNode;
    disabled?: boolean;
    disablingTooltip?: string;
    propertyPathRegexPattern?: string;
    automationId?: string;
    iconName?: iconNameType;
}


const PageBox2Tab: React.FC<IPageBox2TabBodyProps> = () => {
    return null;
};

export default PageBox2Tab;