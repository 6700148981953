exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RoleEditorDialog_header-container_24-2S {\n  margin-top: 16px;\n  margin-left: 16px;\n}\n.RoleEditorDialog_modal-huge-title_2bJC2 {\n  font-family: PragatiNarrowBold;\n  font-size: 17px;\n  letter-spacing: 0.23;\n}\n", ""]);

// exports
exports.locals = {
	"header-container": "RoleEditorDialog_header-container_24-2S",
	"headerContainer": "RoleEditorDialog_header-container_24-2S",
	"modal-huge-title": "RoleEditorDialog_modal-huge-title_2bJC2",
	"modalHugeTitle": "RoleEditorDialog_modal-huge-title_2bJC2"
};