import LoadingIndicator from "@CommonControls/LoadingIndicator";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React, { useEffect } from "react";

interface ILogoutDependencies {
    globalRoutingStore: GlobalRoutingStore;
    userContext: UserContext;
}

interface ILogoutProps extends IRoutingFrameContentProps {
    _dependencies?: ILogoutDependencies;
}

function LogoutPage(props: ILogoutProps) {

    useEffect(() => {
        setTimeout(() => {
            props._dependencies.userContext.logoutAsync();
        }, 250);
    }, []);

    return <LoadingIndicator mode="fullscreen" />;
}

export default connect(
    LogoutPage,
    new DependencyAdapter<ILogoutProps, ILogoutDependencies>(c => ({
        userContext: c.resolve("UserContext"),
        globalRoutingStore: c.resolve("GlobalRoutingStore")
    }))
);