import OrganizationUnitDefinitionId from "@Primitives/OrganizationUnitDefinitionId.g";

export default class OrganizationUnitDefinition {
    constructor(
        public id: OrganizationUnitDefinitionId = null,
        public parentId: OrganizationUnitDefinitionId = null,
        public code: string = "",
        public name: string = "",
        public tags: string[] = []
    ) { }
}