import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { ITelemedicineAppointmentDialogParams, ITelemedicineAppointmentDialogResult } from "./TelemedicineAppointmentDialogParams";
import Modal from "@CommonControls/Modal";
import React, { useCallback } from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import StaticAssecoMeddWebAppResources from "@AssecoMedPlugin/StaticResources/StaticAssecoMedWebAppResources";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import AppointmentDetailPanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/AppointmentDetailPanel";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import TelemedicineScreenApiAdapter from "@AssecoMedPlugin/Packages/Care/Screens/TelemedicineScreen/TelemedicineScreenApiAdapter";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";

interface ITelemedicineAppointmentDialogProps extends IModalComponentParams<ITelemedicineAppointmentDialogResult>, ITelemedicineAppointmentDialogParams { }

function TelemedicineAppointmentDialog(props: ITelemedicineAppointmentDialogProps) {

    const { telemedicineApiAdapter } = useDependencies(c => ({
        telemedicineApiAdapter: c.resolve<TelemedicineScreenApiAdapter>("TelemedicineScreenApiAdapter")
    }));

    const onAppointmentCreatedAsync = useCallback(async (id: AppointmentScheduleEntryId) => {
        const response = await telemedicineApiAdapter.createTelemedicineAsync(props.episodeOfCareId, id);
        props.onClose({
            careActivityId: response.result
        } as ITelemedicineAppointmentDialogResult);
    }, []);

    const onAppointmentUpdatedAAsync = useCallback((type: "update" | "cancel", rowVersion: RowVersion) => {
        props.onClose({
            careActivityId: null,
            rowVersion: rowVersion
        } as ITelemedicineAppointmentDialogResult);
    }, []);

    const onClose = useCallback(() => {
        props.onClose({
            careActivityId: null
        } as ITelemedicineAppointmentDialogResult);
    }, []);

    return (
        <Modal isOpen title={StaticAssecoMeddWebAppResources.TelemedicineScreen.Title} closeButton onClose={onClose} size="fullscreen">
            <AppointmentDetailPanel
                appointmentId={props.appointmentId}
                onAppointmentCreatedAsync={onAppointmentCreatedAsync}
                onSave={onAppointmentUpdatedAAsync}
                isAdmitPatientAllowed
                _patientId={props.patientId}
            />
        </Modal>
    );
}

export default connect<ITelemedicineAppointmentDialogProps>(
    TelemedicineAppointmentDialog
);