import IExternalPrescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IExternalPrescription";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import ExternalMedicationEquipmentBasedPrescriptionDetailView from "./ExternalMedicationEquipmentBasedPrescriptionDetailView";
import ExternalPrescriptionDetailView from "./ExternalPrescriptionDetailView";

interface IExternalPrescriptionDetailViewSelectorProps {
    externalPrescription: IExternalPrescription;
}

@State.observer
export default class ExternalPrescriptionDetailViewSelector extends React.Component<IExternalPrescriptionDetailViewSelectorProps> {
    public render() {
        if (!this.props.externalPrescription) {
            return <></>;
        }

        return this.props.externalPrescription.ehiProductType?.startsWith("GYSE") === true
            ? <ExternalMedicationEquipmentBasedPrescriptionDetailView externalPrescription={this.props.externalPrescription} />
            : <ExternalPrescriptionDetailView externalPrescription={this.props.externalPrescription} />;
    }
}