import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as Proxy from "@HisPlatform/BoundedContexts/UserManagement/Api/Proxy.g";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import UserId from "@Primitives/UserId.g";
import Di from "@Di";

type ResponseType = Proxy.UpdateUserCommandResponse | Proxy.GetUserByIdQueryResponse | Proxy.ChangeUserPasswordCommandResponse | Proxy.AddUserCommandResponse | Proxy.UserDto;

@Di.injectable()
export default class UserStoreMapper extends EntityStoreMapper<Proxy.UserDto, User, ResponseType> {
    protected storeEntityIdType = UserId;

    protected vGetStoreIdentifier() {
        return "User";
    }

    protected entityDtoSelector(response: ResponseType) {
        if (response instanceof Proxy.UserDto) {
            return response;
        } else {
            return response.user;
        }
    }

    public applyToStoreCore(target: User, response: ResponseType) { 
        const entity = this.entityDtoSelector(response);
        target.displayName = entity.displayName;
        target.loginName = entity.loginName;
        target.isEnabled = entity.isEnabled;
        target.isPasswordExpirable = entity.isPasswordExpirable;
        target.userGroupIds = entity.userGroupIds;
        target.rowVersion = entity.rowVersion;
    }
}
