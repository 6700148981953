class UTF8Encoding {

    private readonly encoder = new TextEncoder();
    private readonly decoder = new TextDecoder("utf8", { ignoreBOM: true });

    public getString(bytes: Uint8Array): string {
        return this.decoder.decode(bytes);
    }

    public async getStringAsync(blob: Blob): Promise<string> {
        return this.decoder.decode(await blob.toUint8ArrayAsync());
    }

    public getBytes(value: string): Uint8Array {
        return this.encoder.encode(value);
    }
}

const Encoding = {
    UTF8: new UTF8Encoding()
};

export default Encoding;