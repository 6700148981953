import _ from "@HisPlatform/Common/Lodash";
import AllergyIntoleranceAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/AllergyIntoleranceAssignmentBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import AllergyIntoleranceCategoryId from "@Primitives/AllergyIntoleranceCategoryId.g";
import InternationalNonproprietaryNameId from "@Primitives/InternationalNonproprietaryNameId.g";
import IInternationalNonproprietaryName from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IInternationalNonproprietaryName";
import AllergyIntoleranceType from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/AllergiesAndIntolerances/Enum/AllergyIntoleranceType.g";

export default class InternationalNonproprietaryNameAllergyIntoleranceAssignment extends AllergyIntoleranceAssignmentBase {

    @State.observable.ref public id: InternationalNonproprietaryNameId = null;
    @State.observable.ref public internationalNonproprietaryName: IInternationalNonproprietaryName = null;
    @State.observable.ref public type: AllergyIntoleranceType = AllergyIntoleranceType.Allergy;
    @State.observable.ref public categoryId: AllergyIntoleranceCategoryId = AllergyIntoleranceCategoryId.medication;

    @State.action.bound
    public setId(id: InternationalNonproprietaryNameId) {
        this.initializeCreatedAtIfEmpty();
        this.id = id;
    }

    @State.action.bound
    public setType(newValue: AllergyIntoleranceType) {
        this.type = newValue;
    }
}