import React from "react";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import ContagiousPatientReportType from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReportType";


const ContagiousPatientReportTypeSelectBox: React.FC<IUniversalEnumSelectorBaseProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumOrigin="server"
        enumName={"ContagiousPatientReportType"}
        enumType={ContagiousPatientReportType}
        displayMode="selectBox"
    />
);

export default ContagiousPatientReportTypeSelectBox;