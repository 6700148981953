import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import CloneableLockingEntityStoreBase from "@Toolkit/CommonWeb/Model/CloneableLockingEntityStoreBase";
import _ from "@HisPlatform/Common/Lodash";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalConditionHistoryItemBase from "./MedicalConditionHistoryItemBase";
import PatientId from "@Primitives/PatientId.g";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

export default abstract class MedicalConditionItemBase<TId> extends CloneableLockingEntityStoreBase<MedicalConditionItemBase<TId>, TId> implements IDirtyChecked {

    @State.observable.ref public historyItems: IObservableArray<MedicalConditionHistoryItemBase> = State.observable([]);
    @State.observable public currentItemIndex: number;
    @State.observable.ref public extensionData: any = {};
    @State.observable public patientId: PatientId;

    @State.computed public get validationPropertyPrefix() {
        return `HistoryItems[${this.currentItemIndex}]`;
    }

    protected get vPropertyNamesExcludedFromDirtyCheck() {
        return [
            ...super.vPropertyNamesExcludedFromDirtyCheck,
            "currentItemIndex",
            "checkedRules",
            "validationResults",
            "extensionData",
            "historyItems"
        ];
    }

    constructor(isNew: boolean) {
        super(isNew);
    }

    @State.computed public get hasNewVersion() {
        return this.historyItems.some(x => x.isNew);
    }

    @State.action.bound
    public setCurrentItemIndex(newValue: number) {
        this.currentItemIndex = newValue;
    }

    @State.action.bound
    public setExtensionData(newValue: any) {
        this.extensionData = newValue;
    }

    @State.computed public get currentHistoryItem() {
        return this.historyItems[this.currentItemIndex];
    }

    @State.computed public get latestHistoryItem() {
        return this.historyItems[this.historyItems.length - 1];
    }

    @State.computed public get isLatestItemSelected() {
        return this.currentItemIndex === this.historyItems.length - 1;
    }

    @State.action.bound
    public addNewVersion() {
        const newItem = _.cloneDeep(this.latestHistoryItem);
        const newIndex = this.currentItemIndex + 1;
        newItem.markAsNewVersion(newIndex);

        this.historyItems.push(newItem);
        this.setCurrentItemIndex(newIndex);

        this.addNewVersionTypedChanges();
    }

    @State.action.bound
    
    public addNewVersionTypedChanges() { }

    @State.action.bound
    public removeNewVersion() {
        const newItem = this.historyItems.filter(x => x.isNew)[0];
        this.historyItems.remove(newItem);
        this.setCurrentItemIndex(this.currentItemIndex === 0 ? 0 : this.currentItemIndex - 1);
    }

    @State.action.bound
    public setValidationResults(newValue: IClientValidationResult[]) {
        this.validationResults = newValue;
    }
}
