import { PageBox2, ToolbarLayout } from "@CommonControls";
import { iconNameType } from "@CommonControls/Icon";
import MasterDetailDetail from "@CommonControls/Layout/MasterDetailDetail";
import Modal, { ModalSize } from "@CommonControls/Modal";
import { ContextAwareModal } from "@HisPlatformControls";
import React from "react";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import { useScreenStateContext } from "./ScreenStateContext";
import useWindowTitle from "@Toolkit/ReactClient/Components/WindowTitleHook";
import PageBoxContainer from "@CommonControls/PageBox2/PageBoxContainer";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import ModalSecondaryScreenRenderer from "./ModalSecondaryScreenRenderer";
import Tabbar, { ITab } from "@CommonControls/Tab/Tabbar";

interface IScreenProps {
    /** PageBox, MD-Detail or Modal title */
    title?: string;

    /** PageBox, MD-Detail or Modal subtitle */
    subTitle?: string;

    /** PageBox, MD-Detail or Modal icon */
    iconName?: iconNameType;

    /** PageBox, MD-Detail toolbar */
    toolbar?: React.ReactNode;

    /** PageBox, MD-Detail or Modal footer */
    rightFooter?: React.ReactNode;

    /** PageBox, MD-Detail or Modal footer */
    leftFooter?: React.ReactNode;

    tabs?: ITab[];
    selectedTab?: ITab;
    onTabSelect?: (tab: ITab, index: number) => void;

    /** Modal only */
    modalSize?: ModalSize;
    modalMaxHeight?: number | string;

    onSecondaryCancelled?: () => void;
    onSecondarySavedExisting?: () => void;
    onSecondarySavedNew?: (newId: StringEntityId) => void;
    onSecondaryDeleted?: () => void;
}

function Screen(props: React.PropsWithChildren<IScreenProps>) {

    const store = useScreenStateContext();

    useWindowTitle(props.title);

    switch (store.displayMode) {
        case ScreenDisplayMode.Modal:
            return (
                <>
                    <ContextAwareModal
                        title={props.title}
                        iconName={props.iconName}
                        isOpen
                        closeButton
                        onClose={store.cancelled}
                        size={props.modalSize}
                        maxHeight={props.modalMaxHeight}
                    >
                        <LoadingBoundary>
                            <Modal.Body>{props.children}</Modal.Body>
                            {(props.leftFooter || props.rightFooter) && (
                                <Modal.Footer left={props.leftFooter} right={props.rightFooter} />
                            )}
                        </LoadingBoundary>
                    </ContextAwareModal>
                    <ModalSecondaryScreenRenderer
                        onSavedNew={props.onSecondarySavedNew}
                        onSavedExisting={props.onSecondarySavedExisting}
                        onCancelled={props.onSecondaryCancelled}
                        onDeleted={props.onSecondaryDeleted}
                    />
                </>
            );
        case ScreenDisplayMode.Detail:
            return (
                <>
                    {
                        props.tabs ?
                            (
                                <ToolbarLayout
                                    topToolbar={(
                                        <Tabbar
                                            tabs={props.tabs}
                                            tabsGrow={false}
                                            visualStyle="pageBox"
                                            onSelect={props.onTabSelect}
                                            selectedTab={props.selectedTab}
                                        />
                                    )}
                                    topToolbarHeight="auto"
                                    body={props.children}
                                />
                            ) : (
                                <MasterDetailDetail
                                    title={props.title}
                                    subTitle={props.subTitle}
                                    iconName={props.iconName}
                                    toolbar={props.toolbar}
                                    hasSidePadding
                                    footerLeftToolbar={props.leftFooter}
                                    footerRightToolbar={props.rightFooter}
                                    passStateToChild={false}
                                    onClose={store.cancelled}
                                >
                                    {props.children}
                                </MasterDetailDetail>
                            )
                    }

                    <ModalSecondaryScreenRenderer
                        onSavedNew={props.onSecondarySavedNew}
                        onSavedExisting={props.onSecondarySavedExisting}
                        onCancelled={props.onSecondaryCancelled}
                        onDeleted={props.onSecondaryDeleted}
                    />
                </>
            );
        case ScreenDisplayMode.Full:
        case ScreenDisplayMode.WithoutSideBar:
            return (
                <>
                    <PageBoxContainer>
                        <PageBox2>
                            <PageBox2.Header
                                title={props.title}
                                subTitle={props.subTitle}
                                iconName={props.iconName}
                                toolbar={props.toolbar}
                            />
                            <PageBox2.Body hasSidePadding>
                                {props.children}
                            </PageBox2.Body>
                            {(props.leftFooter || props.rightFooter) && (
                                <PageBox2.Footer leftToolbar={props.leftFooter} rightToolbar={props.rightFooter} />
                            )}
                        </PageBox2 >
                    </PageBoxContainer>
                    <ModalSecondaryScreenRenderer
                        onSavedNew={props.onSecondarySavedNew}
                        onSavedExisting={props.onSecondarySavedExisting}
                        onCancelled={props.onSecondaryCancelled}
                        onDeleted={props.onSecondaryDeleted}
                    />
                </>
            );
    }

    return <>{props.children}</>;
}

export default Screen;