import Di from "@Di";
import prescriptionStandaloneRouteDefinitions from "@HunEHealthInfrastructurePlugin/UseCases/PrescriptionsUseCase/PrescriptionRoutes";
import CommandProcessorBase from "@Toolkit/CommonWeb/CommandProcessing/Definition/CommandProcessorBase";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import CreatePrescriptionCommand from "./CreatePrescriptionCommand";

@Di.injectable()
export default class CreatePrescriptionCommandProcessor extends CommandProcessorBase<CreatePrescriptionCommand> {
    public get commandType(): string {
        return "CreatePrescriptionCommand";
    }

    constructor(
        @Di.inject("GlobalRoutingStore") private readonly _globalRoutingStore: GlobalRoutingStore
    ) {
        super();
    }

    /** {@inheritdoc} */
    public processAsync(command: CreatePrescriptionCommand): Promise<void> {
        const queryParameters = {
            startNewPrescription: true
        };

        if (!!command.substanceName) {
            queryParameters["searchTerm"] = command.substanceName;
        }

        this._globalRoutingStore.push(prescriptionStandaloneRouteDefinitions.prescriptions.makeRoute(
            {
                careActivityId: command.careActivityId.value,
                prescriptionId: null
            },
            queryParameters));

        return Promise.resolve();
    }
}