import _ from "@HisPlatform/Common/Lodash";
import Di from "@Di";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import IFormExtensionRegistry from "@PluginInterface/FormExtension/IFormExtensionRegistry";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { ITelemetrySessionScreenProps } from "./TelemetrySessionScreen";
import TelemetrySessionScreenApiAdapter from "./TelemetrySessionScreenApiAdapter";
import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import IFormLogicRegistry, { IFormLogicExecutor } from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import HisPermissionScopes from "@HisPlatform/Common/FrontendActions/HisPermissionScopes";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import SaveTelemetrySessionAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/SaveTelemetrySessionAction.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import PatientId from "@Primitives/PatientId.g";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import ShowCreateNewTelemetrySessionScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowCreateNewTelemetrySessionScreenAction.g";
import ShowEditTelemetrySessionScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowEditTelemetrySessionScreenAction.g";
import ShowReadOnlyTelemetrySessionScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowReadOnlyTelemetrySessionScreenAction.g";
import LockAcquirerOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockAcquirerOperationInfo";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import TelemetrySessionMeasurementsDialogParams, { ITelemetrySessionMeasurementsDialogResult } from "@AssecoMedPlugin/Packages/Care/Screens/TelemetrySessionMeasurementsDialog/TelemetrySessionMeasurementsDialogParams";
import config from "@Config";
import { ChangeAction } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";

@Di.injectable()
export default class TelemetrySessionScreenStore extends EditorScreenPanelStoreBase<ITelemetrySessionScreenProps> implements ILoadablePanelStore {
    @State.observable.ref public form: IForm = null;
    private formEngineDefinition: IFormDefinition;
    private formLogicExecutor: IFormLogicExecutor = null;

    @State.computed
    public get hasPermissionForSaveTelemetrySession() {
        return this.authorizationService.hasPermissionForDescriptor(this.saveTelemetrySessionAction);
    }

    @State.computed
    public get formDefinitionId() {
        return this.form?.definitionId;
    }

    @State.computed
    public get isNewTelemetrySessionScreen() {
        return this.showScreenAction instanceof ShowCreateNewTelemetrySessionScreenAction;
    }

    @State.computed
    public get isEditTelemetrySessionScreen() {
        return this.showScreenAction instanceof ShowEditTelemetrySessionScreenAction;
    }

    @State.computed
    public get isReadOnlyTelemetrySessionScreen() {
        return this.showScreenAction instanceof ShowReadOnlyTelemetrySessionScreenAction;
    }

    @State.computed
    public get saveTelemetrySessionAction() {
        if (!this.form) {
            return ActionDescriptor.fromAction(new SaveTelemetrySessionAction());
        }

        const pointOfCareIdRawValue = getField<ReferencedEntityFormFieldData>(this.form.data.Content, "PointOfCareId")?.value;
        return ActionDescriptor.fromAction(new SaveTelemetrySessionAction(), HisPermissionScopes.pointOfCare(pointOfCareIdRawValue?.toString()));
    }

    @State.computed
    public get vIsReadOnly() {
        return this.canAcquireLock
            || (this.isNewTelemetrySessionScreen && !this.hasPermissionForSaveTelemetrySession)
            || (this.isEditTelemetrySessionScreen && !this.hasPermissionForSaveTelemetrySession)
            || this.isReadOnlyTelemetrySessionScreen;
    }

    @State.computed
    protected get contentToDirtyCheck() {
        return [this.form?.data.Content];
    }

    protected get showScreenAction(): ShowScreenFrontendActionBase {
        return this.props.action;
    }

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("TelemetrySessionScreenApiAdapter") private apiAdapter: TelemetrySessionScreenApiAdapter,
        @Di.inject("AuthorizationService") private authorizationService: AuthorizationService,
        @Di.inject("IFormLogicRegistry") private formLogicRegistry: IFormLogicRegistry,
        @Di.inject("IFormEngineReferenceDataStore") private formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("IContainerService") private containerService: IContainerService,
        @Di.inject("IFormExtensionRegistry") private formExtensionRegistry: IFormExtensionRegistry) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    public async loadCoreAsync(): Promise<void | { loadedSignals?: string[]; }> {
        if (this.props.action instanceof ShowCreateNewTelemetrySessionScreenAction) {
            const response = await this.apiAdapter.getNewTelemetrySessionDataAsync(this.props.action.patientId, this.props.action.episodeOfCareId);
            this.setForm(response.result.form);
        } else if (this.props.action instanceof ShowEditTelemetrySessionScreenAction || this.props.action instanceof ShowReadOnlyTelemetrySessionScreenAction) {
            const response = await this.apiAdapter.getTelemetrySessionScreenDataAsync(this.props.action.careActivityId, this.isEditTelemetrySessionScreen);
            if (response.operationInfo instanceof LockAcquirerOperationInfo) {
                State.runInAction(() => this.lockInfo = (response.operationInfo as LockAcquirerOperationInfo).lockInfo);
            }

            if (this.isReadOnlyTelemetrySessionScreen) {
                this.vSetValidationResults(response.result.form.validationResults);
            }

            this.setForm(response.result.form);
        } else {
            return;
        }

        this.formEngineDefinition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(this.form.definitionId);

        const loadedSignals: string[] = [];
        const formExtensions = this.formExtensionRegistry.resolveAll(this.containerService, "TelemetrySessionScreen");
        formExtensions.forEach(i => {
            const items = (i.invokeCallback("GetLoadedSignals", {}) as string[]);
            loadedSignals.push(..._.flatten(items));
        });

        this.formLogicExecutor = this.formLogicRegistry.getExecutorForForm(
            this.form, 
            this.showScreenAction, 
            this.formEngineDefinition.name, 
            this.formEngineDefinition);

        await this.formLogicExecutor.formLoadedAsync();

        return {
            loadedSignals: loadedSignals
        };
    }

    public readonly onDataChangeAsync = this.async(async (propertyIdentifier: string, value: any, action: ChangeAction) => {
        const showScreenAction = this.props.action;
        if (showScreenAction instanceof ShowCreateNewTelemetrySessionScreenAction) {
            switch (propertyIdentifier) {
                case "PointOfCareId":
                    if (isNullOrUndefined(value) || !await this.tryReloadFormAsync(showScreenAction.patientId, new PointOfCareId(value.toString()), showScreenAction.episodeOfCareId)) {
                        return;
                    }
                    break;
                default:
                    return;
            }
        }

        await this.formLogicExecutor.fieldChangedAsync(action, propertyIdentifier, value);
    });

    public onValidateAsync: () => Promise<IClientValidationResult[]> = this.async(async () => {
        if (!this.form || this.vIsReadOnly) {
            return [];
        }

        const pointOfCareIdRawValue = getField<ReferencedEntityFormFieldData>(this.form.data.Content, "PointOfCareId")?.value;
        if (isNullOrUndefined(pointOfCareIdRawValue)) {
            return [];
        }

        const formValidationResult = await this.apiAdapter.createTelemetrySessionAsync(this.form, true);
        return formValidationResult.result.form.validationResults;
    });

    @State.bound
    public onCancelAsync(): Promise<any> {
        this.props._screenState.cancelled();
        return Promise.resolve();
    }

    @State.bound
    public async onSaveAsync(): Promise<any> {
        if (this.showScreenAction instanceof ShowCreateNewTelemetrySessionScreenAction) {
            const dialogResult = await this.modalService.showDialogAsync<ITelemetrySessionMeasurementsDialogResult>(new TelemetrySessionMeasurementsDialogParams(
                this.showScreenAction.externalPatientId,
                config.telemetrySession.startSession));

            if (dialogResult.isCancelled) {
                 return false;
            }

            const result = await this.handleSaveResultAsync(() => this.apiAdapter.createTelemetrySessionAsync(this.form));
            if (result.isPersisted) {
                this.setForm(result.result.form);
                this.props._screenState.savedNew(result.result.form.id);
            }

            return result.isPersisted;
        }

        if (!(this.showScreenAction instanceof ShowEditTelemetrySessionScreenAction)) {
            return false;
        }

        const result = await this.handleSaveResultAsync(() => this.apiAdapter.updateTelemetrySessionAsync(
            (this.showScreenAction as ShowEditTelemetrySessionScreenAction).careActivityId,
            this.form,
            this.lockInfo?.lockId,
            false));

        if (result.isPersisted) {
            this.setForm(result.result);
            this.props._screenState.savedExisting();
        }

        return result.isPersisted;
    }

    @State.bound
    public async onCloseAsync(): Promise<any> {
        if (!(this.props.action instanceof ShowEditTelemetrySessionScreenAction)) {
            return;
        }

        const careActivityId = this.props.action.careActivityId;
        const result = await this.handleSaveResultAsync(() => this.apiAdapter.closeTelemetrySessionAsync(careActivityId, this.form, this.lockInfo?.lockId));
        this.props._screenState.savedExisting();
        this.props._screenState.cancelled();

        return result.isPersisted;
    }

    protected saveFunction: () => Promise<boolean> = this.onSaveAsync;

    private readonly tryReloadFormAsync = this.async(async (patientId: PatientId, pointOfCareId: PointOfCareId, episodeOfCareId: EpisodeOfCareId): Promise<boolean> => {
        const formDefinitionResponse = await this.apiAdapter.getTelemetrySessionScreenFormDefinitionAsync(pointOfCareId);
        if (ValueWrapper.equals(formDefinitionResponse.result, this.form.definitionId)) {
            return false;
        }

        const formResponse = await this.apiAdapter.getNewTelemetrySessionDataAsync(patientId, episodeOfCareId);
        this.setForm(formResponse.result.form);

        return true;
    });

    @State.action.bound
    private setForm(form: IForm) {
        this.form = form;
    }
}