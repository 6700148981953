import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React, { useCallback } from "react";
import OrganizationUnitsColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/OrganizationUnitsColumn/OrganizationUnitsColumn";
import InstituteSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/InstituteSelectBox/InstituteSelectBox";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";

interface IInstituteColumnDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IInstituteColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IInstituteColumnDependencies;
}


const InstituteColumn: React.FC<IInstituteColumnProps> = (props: IInstituteColumnProps) => {
    
    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<OrganizationUnitId | OrganizationUnitId[]>) => {
        return <InstituteSelectBox {...filterProps} multiSelect hoverOnlyIndicatorIcons />;
    }, []);

    return (
        <OrganizationUnitsColumn
            {...props}
            filterRenderer={filterRenderer}
            filterIsMultiSelect
        />
    );
};

export default connect(
    InstituteColumn,
    new DependencyAdapter<IInstituteColumnProps, IInstituteColumnDependencies>(c => ({
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
    }))
);
