import BillableServicesPanel from "@HisPlatform/Packages/Patients/Components/BillableServicesPanel/BillableServicesPanel";
import { PatientPerformedServiceToBill, PatientServiceRequestToBill, PatientServiceToBill } from "@HisPlatform/Packages/Patients/Components/BillableServicesPanel/PatientServiceToBill";
import CareActivityId from "@Primitives/CareActivityId.g";
import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import InvoiceId from "@Primitives/InvoiceId.g";
import MedicalServiceFinancingId from "@Primitives/MedicalServiceFinancingId.g";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import Money from "@Toolkit/CommonWeb/Model/Money";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import NumberFormFieldData from "@Toolkit/FormEngine/Model/Data/NumberFormFieldData";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import ReferencedExtensibleEnumFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedExtensibleEnumFormFieldData";
import { useFormCompositeArrayField, useFormEntityIdField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import { ICustomBlockRegistryItem, IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import { useFormPanelStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanelStoreProvider";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import BillableServiceTypeId from "@Primitives/BillableServiceTypeId.g";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";

function BillableServicesCustomBlockCore(props: IFormCustomBlockComponentProps) {

    const [id, _] = useFormEntityIdField<InvoiceId>(props.form.data.Content, "InvoiceId", InvoiceId);
    const billedServices = useFormCompositeArrayField(props.form.data.Content, "BilledPerformedServiceList");
    const store = useFormPanelStore();

    return (
        <BillableServicesPanel
            key={id?.value ?? "new"}
            invoiceId={id}
            value={billedServices.map(fromFromData)}
            onChange={State.action((items) => {
                billedServices.replace(items.map(toFormData));
                store.props.onChange?.("BilledPerformedServiceList", null, "set");
            })}
        />
    );
}

const BillableServicesCustomBlock = State.observer(BillableServicesCustomBlockCore);

function fromFromData(item: FormFieldDataBase[]): PatientServiceToBill {
    const serviceTypeField = getField<ReferencedExtensibleEnumFormFieldData>(item, "BillableServiceTypeId");
    if (isNullOrUndefined(serviceTypeField) || serviceTypeField.value === BillableServiceTypeId.PerformedService.value) {
        return new PatientPerformedServiceToBill(
            new CareActivityId((item.find(i => i.fieldName === "CareActivityId") as ReferencedEntityFormFieldData).value.toString()),
            (item.find(i => i.fieldName === "Quantity") as NumberFormFieldData).value,
            new MedicalServiceFinancingId((item.find(i => i.fieldName === "MedicalServiceFinancingId") as ReferencedEntityFormFieldData).value.toString()),
            new MedicalServiceId((item.find(i => i.fieldName === "MedicalServiceId") as ReferencedEntityFormFieldData).value.toString()),
            new FinancedServiceId((item.find(i => i.fieldName === "FinancedServiceId") as ReferencedEntityFormFieldData).value.toString())
        );
    }

    if (serviceTypeField.value === BillableServiceTypeId.ServiceRequest.value) {
        return new PatientServiceRequestToBill(
            new CareActivityId((item.find(i => i.fieldName === "CareActivityId") as ReferencedEntityFormFieldData).value.toString()),
            (item.find(i => i.fieldName === "Quantity") as NumberFormFieldData).value,
            new ServiceRequestId((item.find(i => i.fieldName === "ServiceRequestId") as ReferencedEntityFormFieldData).value.toString()),
            (item.find(i => i.fieldName === "TotalNetPrice") as NumberFormFieldData)?.value ?? 0,
            (item.find(i => i.fieldName === "ServiceRequestName") as TextFormFieldData)?.value ?? ""
        );
    }

    throw new Error(`Unknown type of patient related billable service '${serviceTypeField.value}'.`);
}

function toFormData(item: PatientServiceToBill) {
    if (item instanceof PatientPerformedServiceToBill) {
        return [
            new ReferencedExtensibleEnumFormFieldData("BillableServiceTypeId", false, true, BillableServiceTypeId.PerformedService.value),
            new ReferencedEntityFormFieldData("CareActivityId", false, true, null, parseInt(item.careActivityId.value)),
            new ReferencedEntityFormFieldData("MedicalServiceFinancingId", false, true, null, parseInt(item.medicalServiceFinancingId.value)),
            new ReferencedEntityFormFieldData("FinancedServiceId", false, true, null, parseInt(item.financedServiceId.value)),
            new ReferencedEntityFormFieldData("MedicalServiceId", false, true, null, parseInt(item.medicalServiceId.value)),
            new NumberFormFieldData("Quantity", false, true, item.quantity)
        ];
    }

    if (item instanceof PatientServiceRequestToBill) {
        return [
            new ReferencedExtensibleEnumFormFieldData("BillableServiceTypeId", false, true, BillableServiceTypeId.ServiceRequest.value),
            new ReferencedEntityFormFieldData("CareActivityId", false, true, null, parseInt(item.careActivityId.value)),
            new ReferencedEntityFormFieldData("ServiceRequestId", false, true, null, parseInt(item.serviceRequestId.value)),
            new NumberFormFieldData("Quantity", false, true, item.quantity),
            new NumberFormFieldData("TotalNetPrice", false, true, item.totalNetPrice),
            new TextFormFieldData("ServiceRequestName", false, true, item.serviceName)
        ];
    }

    throw new Error("Unknown type of patient related billable service.");
}

export const BillableServicesPanelRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Billable services",
    dataElementsFactory: () => [
    ],
    componentType: BillableServicesCustomBlock
};

export default BillableServicesCustomBlock;