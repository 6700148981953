import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Styles from "./ExtendedPerformanceStatementCareActivityFilter.less";
import PerformanceStatementCareActivityFilterStore from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityWorklistPanel/Filters/PerformanceStatementCareActivityFilterStore";
import StaticHunSocialSecurityPerformanceStatementResources from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/StaticResources/StaticHunSocialSecurityPerformanceStatementResources";
import { FinancingClassSelectBox } from "@HisPlatform/BoundedContexts/Care/Components";
import OrganizationSelector from "@HisPlatform/BoundedContexts/Organization/Components/OrganizationSelector";

interface IExtendedPerformanceStatementCareActivityFilterProps {
    filterStore: PerformanceStatementCareActivityFilterStore;
    isVisible: boolean;
}


const ExtendedPerformanceStatementCareActivityFilter: React.FC<IExtendedPerformanceStatementCareActivityFilterProps> = (props) => (
    <>
        <div className={Styles.outerContainer}>
            <Ui.Flex>
                <Ui.Flex.Item xs={12} className={Styles.searchItem}>
                    <FinancingClassSelectBox
                        automationId="financingClassFilter"
                        label={StaticHunSocialSecurityPerformanceStatementResources.PerformanceStatementCareActivityWorkListPanel.Label.FinancingClassIds}
                        value={props.filterStore && props.filterStore.financingClassIds}
                        onChange={props.filterStore && props.filterStore.setFinancingClassIds}
                        displayMode="multiselectLongValues"
                        clearable
                        multiSelect />
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex>
                <Ui.Flex.Item xs={12} className={Styles.searchItem}>
                    <OrganizationSelector
                        label={StaticHunSocialSecurityPerformanceStatementResources.PerformanceStatementCareActivityWorkListPanel.Label.PointOfCareIds}
                        value={props.filterStore && props.filterStore.pointOfCareIds}
                        onChange={props.filterStore && props.filterStore.onPointOfCareChange}
                        filteringPropertyGroup={"HunMandatoryReportsSettings"}
                        filteringPropertyName={"PerformanceStatementEnabled"}
                        filteringPropertyValue={true}
                        pointOfCareMode={true}
                        automationId="organization"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </div>
    </>
);

export default State.observer(ExtendedPerformanceStatementCareActivityFilter);
