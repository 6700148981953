import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import { isNullOrEmptyString, arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { EntityLockState } from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

export default class TokenLockInfo {

    public tokenReference: string;
    public tokenName: string;
    public sourceId: string;
    public sourceRowVersion: RowVersion;
    @State.observable.ref public acquiredLockInfo: LockInfo;
    public acquiredRowVersion: RowVersion;
    public sourceDescription: string;
    @State.observable.ref public validationResults: IClientValidationResult[] = null; 

    constructor(tokenReference: string, tokenName: string, sourceId: string, sourceRowVersion: RowVersion) {
        this.tokenReference = tokenReference;
        this.tokenName = tokenName;
        this.sourceId = sourceId;
        this.sourceRowVersion = sourceRowVersion;
    }

    public get isNew(): boolean { return isNullOrEmptyString(this.sourceId); }

    @State.action
    public releaseLock() {
        if (this.acquiredLockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld) {
            this.acquiredLockInfo = new LockInfo(EntityLockState.LockingRequiredAndLockNotHeld, null, false, false, null, null, null);
        }
    }

    public get hasValidationError() {
        return !arrayIsNullOrEmpty(this.validationResults) && this.validationResults.some(
            r => r.problems && r.problems.some(p => p.severity === "error"));
    }

    public get hasValidationWarning() {
        return !arrayIsNullOrEmpty(this.validationResults) && this.validationResults.some(
            r => r.problems && r.problems.some(p => p.severity === "warning"));
    }

    public get isValid() {
        return arrayIsNullOrEmpty(this.validationResults) || this.validationResults.every(
            r => arrayIsNullOrEmpty(r.problems));
    }
}