import React, { useMemo } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { SelectBox } from "@CommonControls";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import StaticFormEditingResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import ReferencedExtensibleEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedExtensibleEnumFormDataElement";

function ReferencedExtensibleEnumEditor() {

    const store = useFormLayoutEditorStore();

    const options = useMemo(() => {
        return store.formEditorRegistry.getExtensibleEnumEditors().map(i => ({
            text: i.displayName,
            value: i.entityName
        } as ISelectBoxItem<string>));
    }, []);

    return (
        <div>
            <SelectBox
                automationId="ReferencedExtensibleEnum_SelectBox"
                items={options}
                value={(store.selectedDataElement as ReferencedExtensibleEnumFormDataElement)?.extensibleEnum}
                onChange={store.setExtensibleEnum}
                clearable
                valueOnClear={null}
                label={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.EntityReferenceLabel}
            />            
        </div>
    );
}

export default State.observer(ReferencedExtensibleEnumEditor);