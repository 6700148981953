import React, { ReactElement } from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import CareActivityId from "@Primitives/CareActivityId.g";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import Styles from "./OutpatientHeader.less";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import UseCaseNavigationAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/UseCaseNavigationAction";
import Log from "@Log";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import IWorklistAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistAction";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import _ from "@HisPlatform/Common/Lodash";
import ResourceId from "@Primitives/ResourceId.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import NavigateToUseCaseArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/NavigateToUseCaseArgument";
import SecondaryActionMenuItem from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/SecondaryActionMenuItem";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import IShowScreenActionCallContextParams from "@HisPlatform/Services/Definition/ActionProcessing/IShowScreenActionCallContextParams";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import { ScreenNavigationContextStore, ScreenNavigationContextAdapter } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import { ActionDispatcherAdapter } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import Guid from "@Toolkit/CommonWeb/Guid";

interface ICareActivityMenuDependencies {
    worklistApiAdapter: WorklistApiAdapter;
    useCaseRegistry: IUseCaseRegistry;
    localizationService: ILocalizationService;
    activityRegistry: IActivityRegistry;
}

export interface ICareActivityMenuProps {
    _dependencies?: ICareActivityMenuDependencies;
    _modalService?: IModalService;
    _actionDispatcher?: IActionDispatcher;
    _screenNavigationContext?: ScreenNavigationContextStore;

    routingStore: GlobalRoutingStore;
    careActivityId: CareActivityId;
    isStandaloneMode: boolean;
    documentTypeId: DocumentTypeId;

    trigger?: React.ReactNode;
}

@State.observer
class CareActivityMenu extends React.Component<ICareActivityMenuProps> {

    @State.observable.ref private actions: IWorklistAction[] = null;
    private menuIdentifier = Guid.newGuid();

    public componentDidMount() {
        dispatchAsyncErrors(this.loadMenuAsync(), this);
    }

    public componentDidUpdate(prevProps: ICareActivityMenuProps) {
        if (prevProps.careActivityId !== this.props.careActivityId) {
            dispatchAsyncErrors(this.loadMenuAsync(), this);
        }
    }

    private async loadMenuAsync() {
        if (!isNullOrUndefined(this.props.careActivityId)) {
            const actions = await this.worklistApiAdapter.getCareActivityActionsAsync(this.props.careActivityId);
            State.runInAction(() => this.actions = actions.value);
        }
    }

    private get useCaseRegistry() {
        return this.props._dependencies.useCaseRegistry;
    }

    private get worklistApiAdapter() {
        return this.props._dependencies.worklistApiAdapter;
    }

    private get localizationService() {
        return this.props._dependencies.localizationService;
    }

    @State.action.bound
    private navigateToUseCase(useCase: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) {

        const converter = this.useCaseRegistry.tryGetScreenAdapter(useCase);

        if (converter) {
            const action = converter(useCase, useCaseArguments);
            dispatchAsyncErrors(this.props._actionDispatcher!.dispatchAsync<IShowScreenActionCallContextParams>(
                action.action,
                { navigationContext: this.props._screenNavigationContext, modalService: this.props._modalService }
            ), this);
            return;
        }

        // ---

        const useCaseDescriptor = this.useCaseRegistry.get(useCase.value);

        if (!useCaseDescriptor) {
            throw new Error(`Cannot find use case: ${useCase.value}`);
        }

        const routeFactory = useCaseDescriptor.standaloneRouteFactory;
        const route = routeFactory({
            currentRoute: this.props.routingStore.currentRoute,
            useCase: useCase,
            useCaseArguments: useCaseArguments,
            displayMode: UseCaseDisplayMode.Full
        });

        this.props.routingStore.push(route);
    }

    @State.bound
    private async processActionTokenAsync(rowId: string, actionToken: string) {
        try {
            const argument = new NavigateToUseCaseArgument(UseCaseDisplayMode.Full);
            const action = (await this.worklistApiAdapter.executeActionTokenAsync(actionToken, argument)).value;
            if (action instanceof UseCaseNavigationAction) {

                this.navigateToUseCase(action.useCase, action.useCaseArguments);
                return;
            }
        } catch (error) {
            Log.error(error.message);
        }
    }

    public render() {
        return (
            <>
                {!arrayIsNullOrEmpty(this.actions) &&
                    <Ui.ContextMenu id={this.menuIdentifier}>
                        {this.renderActions(this.actions)}
                    </Ui.ContextMenu>}
                <Ui.ContextMenu.Provider id={this.menuIdentifier} event="onClick">
                    {this.props.trigger ?? (
                        <Ui.Button
                            automationId="standaloneFunctionsMenuOpenButton"
                            size="compact"
                            visualStyle="primary"
                            iconName="chevronDown"
                            className={Styles.editButton}
                            iconTextOrder="text-icon"
                        >
                            <Ui.Icon iconName="healthcare" style={{ top: 2 }} />
                        </Ui.Button>
                    )}
                </Ui.ContextMenu.Provider>
            </>
        );
    }

    @State.bound
    private renderActions(actions: IWorklistAction[]) {
        const orderedActions = _.orderBy(actions, [i => i.groupId]);
        const groupedOrderedActions = _.groupBy<IWorklistAction>(orderedActions, i => i.groupId);

        const actionMenuItems: ReactElement[] = [];
        Object.keys(groupedOrderedActions).forEach((key, idx) => {
            const currentGroup = groupedOrderedActions[key];
            const orderedActionsInGroup = _.orderBy<IWorklistAction>(currentGroup, [i => i.order]);

            const separatorTextGroupId = orderedActionsInGroup.length && orderedActionsInGroup[0].groupTitleResourceId;
            const separatorText = separatorTextGroupId ? this.resolveResourceId(separatorTextGroupId) : null;
            actionMenuItems.push(
                <Ui.ContextMenu.Separator key={"separator" + idx} separatorText={separatorText} onlyText={idx === 0} />
            );

            orderedActionsInGroup.forEach((it, innerIdx) => {
                actionMenuItems.push(
                    <SecondaryActionMenuItem
                        disabled={!it.isEnabled}
                        key={`${idx}_${innerIdx}`}
                        title={this.resolveTitle(it)}
                        commandToken={it.commandToken}
                        clientSideAction={it.clientSideAction}
                        onActionAsync={this.processActionTokenAsync}
                        activityReference={it.activityReference}
                        cellMode={false}
                        automationId={it.activityReference}
                        useCaseDisplayMode={it.useCaseDisplayMode}
                        rowId={null}
                        worklistActionType={it.worklistActionType}
                        _activityRegistry={this.props._dependencies!.activityRegistry}
                    />
                );
            });
        });

        return actionMenuItems;
    }

    private resolveResourceId(resourceId: ResourceId): string {
        const localizedTitle = this.localizationService.localizeReferenceData(resourceId);
        return isNullOrUndefined(localizedTitle) || localizedTitle === "" ? resourceId.value : localizedTitle;
    }

    @State.bound
    private resolveTitle(actionItem: IWorklistAction): string {

        if (isNullOrUndefined(actionItem.titleResourceId)) {
            return actionItem.title;
        }

        if (!isNullOrUndefined(actionItem.title) && actionItem.title !== "") {
            return actionItem.title;
        }

        return this.resolveResourceId(actionItem.titleResourceId);
    }
}

export default connect(
    CareActivityMenu,
    new DependencyAdapter<ICareActivityMenuProps, ICareActivityMenuDependencies>((container) => {
        return {
            worklistApiAdapter: container.resolve("WorklistApiAdapter"),
            useCaseRegistry: container.resolve("IUseCaseRegistry"),
            localizationService: container.resolve("ILocalizationService"),
            activityRegistry: container.resolve("IActivityRegistry"),
        };
    }),
    new HisModalServiceAdapter(),
    new ActionDispatcherAdapter(),
    new ScreenNavigationContextAdapter()
);
