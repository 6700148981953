import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import DataGridExtensibleEnumColumn, { IExtensibleEnumColumnProps } from "@CommonControls/DataGrid/Column/DataGridExtensibleEnumColumn";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";

interface IMedicationFormColumnDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IMedicationFormColumnProps extends IExtensibleEnumColumnProps {
    _dependencies?: IMedicationFormColumnDependencies;
}


const MedicationFormColumn: React.SFC<IMedicationFormColumnProps> = (props: IMedicationFormColumnProps) => {
    return (
        <DataGridExtensibleEnumColumn
            extensibleEnumStore={props._dependencies.careReferenceDataStore.medicationForm}
            header={"MedicationForm"}
            {...props}
        />
    );
};

export default connect(
    MedicationFormColumn,
    new DependencyAdapter<IMedicationFormColumnProps, IMedicationFormColumnDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore")
    }))
);