import React from "react";
import PropertyBase from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import CheckBox from "@CommonControls/CheckBox";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import PropertyVersionValidity from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PropertyGroupEditableColumn/PropertyVersionValidity";

interface IBoolPropertyEditorProps {
    row: PropertyBase;
}

@State.observer
export class BoolPropertyEditor extends React.Component<IBoolPropertyEditorProps> {

    @State.computed private get currentValue() {
        return this.props.row.getValue(this.props.row.isTemporal ? LocalDate.today() : undefined);
    }

    private get hasValue() {
        return this.props.row.hasValue;
    }

    @State.action.bound
    private setEnabled(isEnabled: boolean) {
        if (isEnabled) {
            this.setCurrentVersionValue(this.currentValue ?? true);
        } else {
            this.props.row.clearValue();
        }
    }

    private items: boolean[] = [true, false];

    private displayValueGetter(item: boolean) {
        return item ?
            StaticOrganizationResources.PropertyGroupsPanel.BoolPropertyEditor.On :
            StaticOrganizationResources.PropertyGroupsPanel.BoolPropertyEditor.Off;
    }

    public render() {
        return (
            <Ui.Flex>
                <Ui.Flex.Item sm={2}>
                    <CheckBox value={this.hasValue} onChange={this.setEnabled} automationId={this.props.row.name + "_checkBox"} />
                </Ui.Flex.Item>
                <Ui.Flex.Item sm={10}>
                    <Ui.RadioButtonGroup
                        value={this.currentValue}
                        onChange={this.setCurrentVersionValue}
                        disabled={!this.hasValue}
                        items={this.items}
                        getDisplayValue={this.displayValueGetter}
                        displayType="groupedButtons"
                    />
                    <PropertyVersionValidity property={this.props.row} />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.bound
    private setCurrentVersionValue(newValue: boolean) {
        this.props.row.replaceVersion(newValue, this.props.row.isTemporal ? LocalDate.today() : undefined);
    }
}
