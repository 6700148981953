import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { isNullOrUndefined, emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import MostRelevantServicesFilterStore from "./MostRelevantServicesFilterStore";
import MostRelevantServiceCodeSelector from "./MostRelevantServiceCodeSelector";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";

interface IMostRelevantServicesFilterDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
    localizationService: ILocalizationService;
}

interface IMostRelevantServicesFilterProps {
    _dependencies?: IMostRelevantServicesFilterDependencies;
    filterStore: MostRelevantServicesFilterStore;
}

@State.observer
class MostRelevantServicesFilter extends React.Component<IMostRelevantServicesFilterProps> {
    private get items() {
        if (isNullOrUndefined(this.props.filterStore)) {
            return emptyArray;
        }

        return this.props.filterStore.entityIds;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.bound
    private async loadAsync() {
        await this.props._dependencies.careReferenceDataStore.medicalService.ensureAllLoadedAsync();
        await this.props._dependencies.schedulingReferenceDataStore.schedulingServices.ensureAllLoadedAsync();
    }

    public render() {
        return (
            <MostRelevantServiceCodeSelector
                label={StaticCareResources.ServiceRequestManagement.RequestedServices.MedicalService}
                value={this.items}
                onChange={this.props.filterStore?.setEntityIds}
                multiSelect={true}
                useAlternativeName
                style={{marginBottom: "5px"}}
                automationId="mostRelevantServiceCodeSelector"
            />
        );
    }
}

export default connect(
    MostRelevantServicesFilter,
    new DependencyAdapter<IMostRelevantServicesFilterProps, IMostRelevantServicesFilterDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            localizationService: container.resolve("ILocalizationService"),
            schedulingReferenceDataStore: container.resolve("SchedulingReferenceDataStore")
        };
    })
);
