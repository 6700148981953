import React from "react";
import { Button, SaveButton, ValidationResultSummary } from "@CommonControls";
import FormPanel from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import FormEngineValidationProblemMessages from "@Toolkit/FormEngine/StaticResources/FormEngineValidationProblemMessages";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import { provideFormDetailPanelStore, useFormDetailPanelStore } from "@Toolkit/FormEngine/Panels/FormDetailPanel/FormDetailPanelStoreProvider";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import IFormScopes from "@Toolkit/FormEngine/Panels/FormPanel/IFormScopes";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import CancelButton from "@CommonControls/CancelButton/CancelButton";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";

export interface IFormDetailPanelProps extends IScreenPropsBase {
    selectedId: FormInstanceId | null;
    onSaveAsync: () => Promise<void>;
    onUseCaseChange: (selectedId: string, useCaseState: INDataUseCaseState) => void;
    isReadOnly: boolean;
    title: string;
    onClose: () => void;

    useCaseIdentifier: string;

    baseEntityType: string;
    baseEntityId: StringEntityId;
    formDefinitionType: string;
    scopes: IFormScopes;
    formDefinitionDescriptors?: IFormDefinition[];
}

/** @screen */
function FormDetailPanel(props: IFormDetailPanelProps) {

    const store = useFormDetailPanelStore();

    if (isNullOrUndefined(store.selectedDetailForm)) {
        return null;
    }

    return (
        <>
            <UseCaseFrame
                title={props.title}
                toolbar={(
                    <>
                        {!props.isReadOnly &&
                            <>
                                {store.isTwoStepEditable && store.isReadOnly && <Button
                                    automationId={"editForm"}
                                    onClick={store.onEditButtonClicked}
                                    iconName={"pen"}
                                    visualStyle={"primary"}
                                    text={store.localizationService.staticResources.button.edit}
                                />}
                                {!store.isReadOnly &&
                                    <>
                                        <SaveButton automationId="saveForm" onClickAsync={store.saveAsync} />
                                        {!store.isNewDetail && <CancelButton automationId="cancelForm" onClick={store.cancel} />}
                                    </>}
                            </>
                        }
                    </>
                )}
            >
                <ValidationBoundary
                    validationResults={store.selectedDetailForm.validationResults}
                    entityTypeName="Form"
                    validateOnMount
                    onValidateAsync={store.validateAsync}
                    getProblemMessage={FormEngineValidationProblemMessages.getMessageByValidationProblem}
                >
                    <ValidationResultSummary displayMode="list" results={store.formValidationErrors} />
                    <FormPanel
                        definitionId={store.selectedDetailForm.definitionId}
                        form={store.selectedDetailForm}
                        isReadOnly={props.isReadOnly || store.isReadOnly}
                    />
                </ValidationBoundary>
            </UseCaseFrame>
            <NavigateAwayHook isEnabled onNavigateAwayAsync={store.navigateAwayAsync} />
        </>
    );
}

export default provideFormDetailPanelStore(FormDetailPanel);
