import HighlightableLabelView from "@CommonControls/HighlightableLabel/HighlightableLabel";
import Icon, { iconNameType } from "@CommonControls/Icon";
import TextBox from "@CommonControls/TextBox";
import React, { useCallback, useState } from "react";
import Styles from "./LargeMenu.less";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";

interface ILargeMenuProps {
    headerContent?: React.ReactNode;

    itemGroups: ILargeMenuItemGroup[];
    onItemSelected: (item: ILargeMenuItem) => void;
    onEscapeKeyDown?: () => void;

    automationId: string;
    hideGroupHeaders?: boolean;
}

export interface ILargeMenuItem {
    icon: iconNameType | React.ReactNode;
    title: string;
    payload?: any;
    key: string | number;
    automationId: string;
}

export interface ILargeMenuItemGroup {
    icon: iconNameType | React.ReactNode;
    title: string;
    items: ILargeMenuItem[];
    key: string | number;
}

function LargeMenuItem(props: {
    item: ILargeMenuItem
    onItemSelected: (item: ILargeMenuItem) => void;
    filterValue: string;
    isHighlighted: boolean;
}) {

    const click = useCallback(() => {
        props.onItemSelected(props.item);
    }, [props.onItemSelected, props.item]);

    return (
        <li onClick={click} className={props.isHighlighted ? Styles.highlighted : undefined}>
            {typeof props.item.icon === "string" ? <Icon iconName={props.item.icon as iconNameType} /> : props.item.icon}
            <span data-automation-id={props.item.automationId}>
                <HighlightableLabelView label={props.item.title} filterValue={props.filterValue} />
            </span>
        </li>
    );
}

export default function LargeMenu(props: ILargeMenuProps) {

    const [filterValue, _setFilterValue] = useState("");
    const [keyboardSelectionEnabled, setKeyboardSelectionEnabled] = useState(true);
    const [keyboardSelectionGroupIndex, setKeyboardSelectionGroupIndex] = useState(0);
    const [keyboardSelectionInGroupIndex, setKeyboardSelectionInGroupIndex] = useState(0);

    const enableKeyboardSelection = useCallback(() => setKeyboardSelectionEnabled(true), []);
    const disableKeyboardSelection = useCallback(() => setKeyboardSelectionEnabled(false), []);

    const filteredGroupsAndItems = props.itemGroups.map(ig => ({
        ...ig,
        items: ig.items.filter(i => isNullOrEmptyString(filterValue) || i.title.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1)
    }));

    const setFilterValue = (newValue: string) => {
        _setFilterValue(newValue);
        setKeyboardSelectionGroupIndex(0);
        setKeyboardSelectionInGroupIndex(0);
    };

    const keyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {

        switch (event.key) {
            case "Enter":
                const item = filteredGroupsAndItems[keyboardSelectionGroupIndex].items[keyboardSelectionInGroupIndex];
                if (item) { props.onItemSelected(item); }
                break;
            case "ArrowDown":
                if (keyboardSelectionInGroupIndex < filteredGroupsAndItems[keyboardSelectionGroupIndex].items.length - 1) {
                    setKeyboardSelectionInGroupIndex(keyboardSelectionInGroupIndex + 1);
                }
                break;
            case "ArrowUp":
                if (keyboardSelectionInGroupIndex > 0) {
                    setKeyboardSelectionInGroupIndex(keyboardSelectionInGroupIndex - 1);
                }
                break;
            case "ArrowRight":
                if (keyboardSelectionGroupIndex < filteredGroupsAndItems.length - 1) {
                    const newGroupIndex = keyboardSelectionGroupIndex + 1;
                    setKeyboardSelectionGroupIndex(newGroupIndex);

                    if (keyboardSelectionInGroupIndex > filteredGroupsAndItems[newGroupIndex].items.length - 1) {
                        setKeyboardSelectionInGroupIndex(filteredGroupsAndItems[newGroupIndex].items.length - 1);
                    }
                }
                break;
            case "ArrowLeft":
                if (keyboardSelectionGroupIndex > 0) {
                    const newGroupIndex = keyboardSelectionGroupIndex - 1;
                    setKeyboardSelectionGroupIndex(newGroupIndex);

                    if (keyboardSelectionInGroupIndex > filteredGroupsAndItems[newGroupIndex].items.length - 1) {
                        setKeyboardSelectionInGroupIndex(filteredGroupsAndItems[newGroupIndex].items.length - 1);
                    }
                }
                break;
            case "Escape":
                props.onEscapeKeyDown?.();
                break;
        }

    };

    const { toolkitLocalizationService } = useDependencies(c => ({
        toolkitLocalizationService: c.resolve<IToolkitLocalizationService>("IToolkitLocalizationService"),
    }));

    return (
        <div className={Styles.container}>
            <div className={Styles.header}>
                {props.headerContent}
            </div>

            <div className={Styles.searchboxContainer}>
                <TextBox
                    automationId={`${props.automationId}_SearchBox`}
                    value={filterValue}
                    onChange={setFilterValue}
                    focusOnMount
                    onKeyDown={keyDown}
                    onFocus={enableKeyboardSelection}
                    onBlur={disableKeyboardSelection}
                    placeholder={toolkitLocalizationService.staticResources.largeMenu.searchboxPlaceholder}
                />
            </div>

            <div className={Styles.itemGroupContainer}>
                {filteredGroupsAndItems.map((ig, igIndex) => (
                    <div className={Styles.itemGroup} key={ig.key}>

                        {(isNullOrUndefined(props.hideGroupHeaders) || props.hideGroupHeaders === false) && (
                            <div className={Styles.headerLine}>
                                <div className={Styles.iconContainer}>
                                    {typeof ig.icon === "string" ? <Icon iconName={ig.icon as iconNameType} /> : ig.icon}
                                </div>
                                <h3>{ig.title}</h3>
                            </div>
                        )}

                        <ul>
                            {ig.items.map((i, iIndex) => (
                                <LargeMenuItem
                                    item={i}
                                    key={i.key}
                                    onItemSelected={props.onItemSelected}
                                    filterValue={filterValue}
                                    isHighlighted={keyboardSelectionEnabled && igIndex === keyboardSelectionGroupIndex && iIndex === keyboardSelectionInGroupIndex}
                                />
                            ))}
                        </ul>

                    </div>
                ))}
            </div>
        </div>
    );
}