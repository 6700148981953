import MedicationRequestReferenceDataStore from "./MedicationRequestReferenceDataStore";
import MedicationSubsidy from "./ReferenceData/MedicationSubsidy";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

export function getSubsidyByClaimTypeId(referenceDataStore: MedicationRequestReferenceDataStore, subsidies: MedicationSubsidy[], claimTypeId: MedicationSubsidyClaimTypeId) {
    if (claimTypeId) {
        const claimTypePricingType = referenceDataStore.medicationSubsidyClaimTypes.items
            .find(i => ValueWrapper.equals(i.id, claimTypeId)).medicationSubsidizedPricingTypeId;
        return subsidies?.find(i => ValueWrapper.equals(i.pricingType, claimTypePricingType));
    }
    return null;
}