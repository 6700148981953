import ITreeNodeStore from "@CommonControls/TreeGrid/ITreeNodeStore";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicationEquipmentClassificationId from "@Primitives/MedicationEquipmentClassificationId.g";

export default class MedicationEquipmentClassificationTreeNode implements ITreeNodeStore {
    @State.observable.ref public key?: string = null;
    public readonly displayValue: React.ReactNode = null;
    public children?: MedicationEquipmentClassificationTreeNode[] = null;
    @State.observable public isOpen?: boolean = false;
    @State.observable public isActive?: boolean = false;
    @State.observable public isLoading?: boolean = false;
    @State.observable public code: string = "";
    @State.observable public name: string = "";
    @State.observable public isChecked?: boolean = false;
    @State.observable public filterableValue?: string = "";

    @State.computed
    public get label() {
        return this.code + " - " + this.name;
    }

    @State.action.bound
    public setIsOpen(newValue: boolean) {
        this.isOpen = newValue;
    }

    @State.action.bound
    public setIsActive(newValue: boolean) {
        this.isActive = newValue;
    }

    constructor(
        public medicationEquipmentClassificationId: MedicationEquipmentClassificationId,
        code: string,
        name: string,
        public isLeaf: boolean,
        children?: MedicationEquipmentClassificationTreeNode[]
    ) {
        this.code = code;
        this.name = name;
        this.children = children && State.observable(children, {deep: false});
        this.key = medicationEquipmentClassificationId && medicationEquipmentClassificationId.value.toString();
        this.filterableValue = `${code} - ${name}`;
    }

    public static create(initializer?: Partial<MedicationEquipmentClassificationTreeNode>) {
        const ret = new MedicationEquipmentClassificationTreeNode(null, null, null, null, null);
        if (initializer) {
            Object.assign(ret, initializer);
        }
        return ret;
    }
}
