class UseCases {

    public static readonly careRegisterDailyPatientList = "CareRegister_DailyPatientList";
    public static readonly careRegisterRecordDiagnosis = "CareRegister_RecordDiagnosis";
    public static readonly careRegisterRecordProvidedServices = "CareRegister_RecordProvidedServices";
    public static readonly careRegisterRecordCareBaseData = "CareRegister_RecordCareBaseData";
    public static readonly careRegisterEditDocumentChapter = "CareRegister_EditDocumentChapter";
    public static readonly careRegisterDischargePatient = "CareRegister_DischargePatient";
    public static readonly careRegisterViewReadOnlyDischargePatient = "CareRegister_ViewReadonlyDischargePatient";
    public static readonly careRegisterEditDocuments = "CareRegister_EditDocuments";
    public static readonly careRegisterCreateServiceRequests = "CareRegister_CreateServiceRequests";
    public static readonly careRegisterCreateServiceRequests2 = "CareRegister_CreateServiceRequests2";
    public static readonly careRegisterViewServiceRequests = "CareRegister_ViewServiceRequests";
    public static readonly careRegisterViewPatientServiceRequests = "CareRegister_ViewPatientServiceRequests";
    public static readonly careRegisterViewPatientDocuments = "CareRegister_ViewPatientDocuments";
    public static readonly careRegisterViewPatientCareActivities = "CareRegister_ViewPatientCareActivities";
    public static readonly careRegisterEditAdmissionData = "CareRegister_EditAdmissionData";
    public static readonly careRegisterEditTextBlocks = "CareRegister_EditTextBlocks";
    public static readonly careRegisterViewReadOnlyCareBaseData = "CareRegister_ViewReadOnlyCareBaseData";

    public static readonly patientRegisterPatientData = "PatientRegister_PatientData";
    public static readonly patientRegisterNewPatient = "PatientRegister_NewPatient";
    public static readonly patientRegisterSearchPatient = "PatientRegister_SearchPatient";
    public static readonly patientRegisterAdmitPatient = "PatientRegister_AdmitPatient";
    public static readonly patientRegisterViewEpisodesOfCare = "PatientRegister_ViewEpisodesOfCare";
    public static readonly patientRegisterViewAppointments = "PatientRegister_ViewAppointments";
    public static readonly patientRegisterCreateNewAppointment = "PatientRegister_CreateNewAppointment";
    public static readonly patientRegisterViewPatientRelatedDocuments = "PatientRegister_ViewPatientRelatedDocuments";

    public static readonly serviceRequestManagementViewPatientServiceRequests = "ServiceRequestManagement_ViewPatientServiceRequests";
    public static readonly serviceRequestManagementViewPatientServiceRequests2 = "ServiceRequestManagement_ViewPatientServiceRequests2";
    public static readonly serviceRequestManagementViewServiceRequest = "ServiceRequestManagement_ViewServiceRequest";
    public static readonly serviceRequestManagementAdministrateServiceRequest = "ServiceRequestManagement_AdministrateServiceRequest";
    public static readonly serviceRequestManagementModifyServiceRequest = "ServiceRequestManagement_ModifyServiceRequest";
    public static readonly serviceRequestManagementRenewServiceRequest = "ServiceRequestManagement_RenewServiceRequest";
    public static readonly serviceRequestManagementSubmitServiceRequest = "ServiceRequestManagement_SubmitServiceRequest";
    public static readonly serviceRequestManagementDeleteDraftServiceRequest = "ServiceRequestManagement_DeleteDraftServiceRequest";

    public static readonly serviceRequestManagementAdmitPatientWithServiceRequest = "ServiceRequestManagement_AdmitPatientWithServiceRequest";

    public static readonly schedulingAdmitPatientWithAppointment = "Scheduling_AdmitPatientWithAppointment";
    public static readonly editAppointment = "Scheduling_EditAppointment";
    public static readonly editAppointmentFromList = "Scheduling_EditAppointmentFromList";
    public static readonly editAppointmentFromPractitionerList = "Scheduling_EditAppointmentFromPractitionerList";
    public static readonly newAppointment = "Scheduling_NewAppointment";
    public static readonly newUnregisteredPatientAppointment = "Scheduling_NewUnregisteredPatientAppointment";
    public static readonly scheduleAppointments = "Scheduling_ScheduleAppointments";
    public static readonly complexScheduleAppointments = "Scheduling_ComplexScheduleAppointments";

    public static readonly editAppointmentScheduleDefinition = "Scheduling_EditAppointmentScheduleDefinition";

    public static readonly recordPrescription = "Prescriptions_RecordPrescription";

    public static readonly editResourceBlockingFromList = "ResourceManagement_EditResourceBlockingFromList";

    public static readonly immunizationDetail = "MedicalCondition_Immunization_ImmunizationDetail";
    public static readonly procedureStatementDetail = "MedicalCondition_ProcedureStatement_ProcedureStatementDetail";
    public static readonly autonomyDisabilityStatementDetail = "MedicalCondition_AutonomyDisabilityStatement_AutonomyDisabilityStatementDetail";
    public static readonly conditionStatementDetail = "MedicalCondition_ConditionStatement_ConditionStatementDetail";
    public static readonly deviceUseStatementDetail = "MedicalCondition_DeviceUseStatement_DeviceUseStatementDetail";
    public static readonly pregnancyStatementDetail = "MedicalCondition_PregnancyStatement_PregnancyStatementDetail";
    public static readonly medicationStatementDetail = "MedicalCondition_MedicationStatement_MedicationStatementDetail";
    public static readonly riskAssessmentDetail = "MedicalCondition_RiskAssessment_RiskAssessmentDetail";
    public static readonly patientAllergyIntoleranceDetail = "MedicalCondition_PatientAllergyIntolerance_PatientAllergyIntoleranceDetail";
    public static readonly medicalAlertStatementDetail = "MedicalCondition_MedicalAlertStatement_MedicalAlertStatementDetail";
    public static readonly externalLocationDetail = "External_ExternalLocationDetail";

    public static readonly surgeryTypeDefinitionDetail = "SurgeryTypes_SurgeryTypeDefinitionDetail";
    public static readonly surgeryDetail = "SurgeryCore_SurgeryDetail";
    public static readonly surgeryForPatientDetail = "SurgeryCore_PatientSurgeryView";

    public static readonly editableFormInstanceDetail = "FormInstanceHandling_EditableFormInstanceDetail";
    public static readonly readonlyFormInstanceDetail = "FormInstanceHandling_ReadonlyFormInstanceDetail";
    
    public static readonly editableRatFormDetail = "RatForm_EditableRatFormDetail";
    public static readonly editablePregnancyRiskFormDetail = "PregnancyRiskForm_EditablePregnancyRiskFormDetail";

    public static readonly formInstanceDetail = "FormInstanceHandling_FormInstanceDetail";

    public static readonly financeInvoiceList = "Invoicing_InvoiceList";
}

export default UseCases;
