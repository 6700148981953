
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import FormLayoutEditorStore from "./FormLayoutEditorStore";

const { 
    ContextComponent: FormLayoutEditorStoreContext, 
    StoreProvider: FormLayoutEditorStoreProvider, 
    useStore: useFormLayoutEditorStore,
    provideStore: provideFormLayoutEditorStore,
} = createPanelStoreProvider(FormLayoutEditorStore);

export {
    FormLayoutEditorStoreContext,
    FormLayoutEditorStoreProvider,
    useFormLayoutEditorStore,
    provideFormLayoutEditorStore
};