import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import FamilyDoctorDocumentStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentStore";
import FamilyDoctorDocumentMedicationReference from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentMedicationReference";
import FamilyDoctorDocumentSingleMedication from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentSingleMedication";
import FamilyDoctorDocumentMedicationFormula from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentMedicationFormula";
import CurativeItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/CurativeItem";
import SpaService from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/SpaService";
import FamilyDoctorDocumentEquipmentReference from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentEquipmentReference";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import FamilyDoctorDocumentId from "@Primitives/FamilyDoctorDocumentId.g";

function getBaseProps(familyDoctorDocument: FamilyDoctorDocumentStore) {
    return {
        careActivityId: familyDoctorDocument.careActivityId,
        patientId: familyDoctorDocument.patientId,
        pointOfCareId: familyDoctorDocument.pointOfCareId,
        medications: familyDoctorDocument.familyDoctorDocumentMedicationReferences.map(medicationReferencesItem =>
            getFamilyDoctorDocumentMedicationReferenceDto(medicationReferencesItem)),
        curativeItems: familyDoctorDocument.curativeItems.map(curativeItem =>
            getCurativeItemDto(curativeItem))
    };
}

export function getFamilyDoctorDocumentMedicationReferenceDto(familyDoctorDocumentMedicationReferenceBase: FamilyDoctorDocumentMedicationReference) {
    if (!familyDoctorDocumentMedicationReferenceBase) {
        return null;
    }

    if (familyDoctorDocumentMedicationReferenceBase.detail instanceof FamilyDoctorDocumentSingleMedication) {
        return new Proxy.FamilyDoctorDocumentSingleMedicationReferenceDto({
            
            medicationVersionSelector: familyDoctorDocumentMedicationReferenceBase.detail.medicationVersionSelector ?
                new Proxy.EntityVersionSelectorOfMedicationId({
                    entityId: familyDoctorDocumentMedicationReferenceBase.detail.medicationVersionSelector?.id,
                    validOn: familyDoctorDocumentMedicationReferenceBase.detail.medicationVersionSelector?.validOn
                }) :
                new Proxy.EntityVersionSelectorOfMedicationId({ validOn: LocalDate.today() }),
            medicationDosageForm: familyDoctorDocumentMedicationReferenceBase.medicationDosageForm,
            conditionId: familyDoctorDocumentMedicationReferenceBase.conditionId,
            dailyDosage: familyDoctorDocumentMedicationReferenceBase.dailyDosage,
            medicationSubsidyClaimTypeId: familyDoctorDocumentMedicationReferenceBase.detail.medicationSubsidyClaimTypeId,
            notes: familyDoctorDocumentMedicationReferenceBase.notes,
            substanceDailyAmount: familyDoctorDocumentMedicationReferenceBase.detail.substanceDailyAmount,
            substanceName: familyDoctorDocumentMedicationReferenceBase.detail.substanceName,
            practitionerId: familyDoctorDocumentMedicationReferenceBase.detail.practitionerId,
            subsidyOptionId: familyDoctorDocumentMedicationReferenceBase.detail.subsidyOptionId,
            medicationSubsidyId: familyDoctorDocumentMedicationReferenceBase.detail.medicationSubsidyId
        });
    } else if (familyDoctorDocumentMedicationReferenceBase.detail instanceof FamilyDoctorDocumentMedicationFormula) {
        return new Proxy.FamilyDoctorDocumentMedicationFormulaListDto({
            
            medicationDosageForm: familyDoctorDocumentMedicationReferenceBase.medicationDosageForm,
            conditionId: familyDoctorDocumentMedicationReferenceBase.conditionId,
            dailyDosage: familyDoctorDocumentMedicationReferenceBase.dailyDosage,
            notes: familyDoctorDocumentMedicationReferenceBase.notes,
            items: familyDoctorDocumentMedicationReferenceBase.detail.items.map(i => (new Proxy.FamilyDoctorDocumentMedicationFormulaListItemDto({
                medicationVersionSelector: i.medicationVersionSelector ?
                    new Proxy.EntityVersionSelectorOfMedicationId({
                        entityId: i.medicationVersionSelector?.id,
                        validOn: i.medicationVersionSelector?.validOn
                    }) :
                    new Proxy.EntityVersionSelectorOfMedicationId({ validOn: LocalDate.today() }),
                substanceAmount: i.substanceAmount
            })))
        });
    } else {
        throw new Error("FamilyDoctorDocumentMedicationReferenceBase descendant not recognized.");
    }

}

export function getCurativeItemDto(curativeItem: CurativeItem) {
    if (!curativeItem) {
        return null;
    }

    if (curativeItem.detail instanceof SpaService) {
        return new Proxy.SpaServiceDto({
            
            practitionerId: curativeItem.practitionerId,
            conditionId: curativeItem.conditionId,
            monthlyAmount: curativeItem.monthlyAmount,
            serviceCode: curativeItem.detail.serviceCode,
            serviceName: curativeItem.detail.serviceName,
        });
    } else if (curativeItem.detail instanceof FamilyDoctorDocumentEquipmentReference) {
        return new Proxy.FamilyDoctorDocumentEquipmentReferenceDto({
            
            practitionerId: curativeItem.practitionerId,
            conditionId: curativeItem.conditionId,
            monthlyAmount: curativeItem.monthlyAmount,
            medicationVersionSelector: curativeItem.detail.medicationVersionSelector ?
                new Proxy.EntityVersionSelectorOfMedicationId({
                    entityId: curativeItem.detail.medicationVersionSelector?.id,
                    validOn: curativeItem.detail.medicationVersionSelector?.validOn
                }) :
                new Proxy.EntityVersionSelectorOfMedicationId({ validOn: LocalDate.today() }),
        });
    } else {
        throw new Error("FamilyDoctorDocumentMedicationReferenceBase descendant not recognized.");
    }

}

export function getFamilyDoctorDocumentDto(familyDoctorDocument: FamilyDoctorDocumentStore) {
    return new Proxy.FamilyDoctorDocumentDto({
        ...getBaseProps(familyDoctorDocument),
        id: familyDoctorDocument.id,
        rowVersion: familyDoctorDocument.rowVersion,
        subject: familyDoctorDocument.familyDoctorDocumentSubject
    });
}

export function getCreateFamilyDoctorDocumentDto(familyDoctorDocument: FamilyDoctorDocumentStore, requestLock: boolean, isValidateOnly = false) {
    return new Proxy.CreateFamilyDoctorDocumentControllerDto({
        ...getBaseProps(familyDoctorDocument),
        requestLock: requestLock,
        isValidateOnly: isValidateOnly,
        subject: familyDoctorDocument.familyDoctorDocumentSubject,
        extensionData: null,
        practitionerId: familyDoctorDocument.createdBy
    });
}

export function getUpdateFamilyDoctorDocumentDto(familyDoctorDocument: FamilyDoctorDocumentStore, releaseLockIfSuccessful: boolean, lockId: LockId, isValidateOnly = false) {
    return new Proxy.UpdateFamilyDoctorDocumentControllerDto({
        ...getBaseProps(familyDoctorDocument),
        releaseLockIfSuccessful,
        lockId,
        isValidateOnly,
        familyDoctorDocumentId: familyDoctorDocument.id,
        rowVersion: familyDoctorDocument.rowVersion,
        subject: familyDoctorDocument.familyDoctorDocumentSubject
    });
}

export function getFamilyDoctorDocumentDocumentDto(
    familyDoctorDocumentId: FamilyDoctorDocumentId,
    documentTypeCode?: string,
    isPrimary?: boolean,
    isAutomatic?: boolean
) {
    return new Proxy.GetSingleDocumentForFamilyDoctorDocumentControllerDto({
        documentTypeCode: documentTypeCode,
        familyDoctorDocumentId: familyDoctorDocumentId,
        isPrimary: isPrimary,
        isAutomatic: isAutomatic
    });
}