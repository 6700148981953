import FormDataElementBase from "./FormDataElementBase";

export default class BooleanFormDataElement extends FormDataElementBase {

    constructor(
        name: string,
        isArray: boolean,
        defaultValue: boolean,
        isReadOnly: boolean,
        isVisible: boolean,
    ) {
        super(name, isArray, defaultValue, isReadOnly, isVisible);
    }

    public formDataElementType = "Boolean";
}