import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCases from "@Primitives/UseCases";
import MedicationStatementPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicationStatement/MedicationStatementPanel";
import MedicationStatementId from "@Primitives/MedicationStatementId.g";

export default function configureMedicationStatementUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2({
        identifiers: [UseCases.medicationStatementDetail],
        component: MedicationStatementPanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));
            const id = map.get("medicationStatementId") ?? new MedicationStatementId("new");
            return {
                id: id,
            };
        }
    });
}
