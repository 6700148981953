import DependencyContainer from "@DiContainer";
import TelemetrySessionScreenApiAdapter from "@AssecoMedPlugin/Packages/Care/Screens/TelemetrySessionScreen/TelemetrySessionScreenApiAdapter";
import * as WebAppBackendProxy from "@AssecoMedPlugin/BoundedContexts/WebAppBackend/Api/Proxy.g";
import { GetTypeNameWithPrefix } from "@AssecoMedPlugin/PluginHelper";
import "@AssecoMedPlugin/BoundedContexts/CommonReferenceData/DependencyConfiguration/CommonReferenceData/DependencyConfiguration";
import ICommandFactory from "@Toolkit/CommonWeb/CommandProcessing/Definition/ICommandFactory";
import CloseTelemetrySessionMeasurementsDialogCommandFactory from "@AssecoMedPlugin/Packages/Care/OperationProcessing/CloseTelemetrySessionMeasurementsDialogCommandFactory";
import CareActivitiesForEpisodeOfCareScreenExtensionApiAdapter from "@AssecoMedPlugin/Packages/Care/Extensions/CareActivitiesForEpisodeOfCareScreenExtension/CareActivitiesForEpisodeOfCareScreenExtensionApiAdapter";
import TelemetrySessionListScreenApiAdapter from "@AssecoMedPlugin/Packages/Care/Screens/TelemetrySessionListScreen/TelemetrySessionListScreenApiAdapter";
import DecisionSupportScreenApiAdapter from "@AssecoMedPlugin/Packages/Care/Screens/DecisionSupportScreen/DecisionSupportScreenApiAdapter";
import TelemedicineScreenApiAdapter from "@AssecoMedPlugin/Packages/Care/Screens/TelemedicineScreen/TelemedicineScreenApiAdapter";

DependencyContainer
    .bind(GetTypeNameWithPrefix("ICareClient"))
    .to(WebAppBackendProxy.CareClient);
    
DependencyContainer
    .bind("TelemetrySessionScreenApiAdapter")
    .to(TelemetrySessionScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("TelemedicineScreenApiAdapter")
    .to(TelemedicineScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("TelemetrySessionListScreenApiAdapter")
    .to(TelemetrySessionListScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("ICommandFactory")
    .to(CloseTelemetrySessionMeasurementsDialogCommandFactory);

DependencyContainer
    .bind("CareActivitiesForEpisodeOfCareScreenExtensionApiAdapter")
    .to(CareActivitiesForEpisodeOfCareScreenExtensionApiAdapter)
    .inSingletonScope();
    
DependencyContainer
    .bind("DecisionSupportScreenApiAdapter")
    .to(DecisionSupportScreenApiAdapter)
    .inSingletonScope();
