import IRegionalCareObligationStatusExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IRegionalCareObligationStatusExtensionPointProps";
import WellKnownReferenceCodes from "@HunSocialSecurityPlugin/Common/WellKnownReferenceCodes";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import Di from "@Di";

@Di.injectable()
export default class RegionalCareObligationStatusExtensionComponentService implements IExtensionComponentService<IRegionalCareObligationStatusExtensionPointProps>  {
   
    public isVisible(props: IRegionalCareObligationStatusExtensionPointProps) {
        return props.insurerOrganizationCode === WellKnownReferenceCodes.HunSocialSecurityInsuranceCode;
    }

    public shouldRerunIsVisible(props: IRegionalCareObligationStatusExtensionPointProps, prevProps: IRegionalCareObligationStatusExtensionPointProps) {
        return props.insurerOrganizationCode !== prevProps?.insurerOrganizationCode;
    }
}