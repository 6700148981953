/* tslint:disable */
import IActionProcessor from "@Toolkit/ReactClient/ActionProcessing/IActionProcessor";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import BinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/BinaryDocument";
import DownloadPatientRelatedDocumentAction from "@HisPlatform/Packages/Patients/FrontendActions/DownloadPatientRelatedDocumentAction.g";
import DocumentScreenApiAdapter from "@HisPlatform/Packages/Patients/Screens/DocumentScreen/DocumentScreenApiAdapter";

// Client side action factory
export function downloadPatientRelatedDocumentActionProcessorFactory(c: IContainerService) {
    return new DownloadPatientRelatedDocumentActionProcessor(
        c.resolve<DocumentScreenApiAdapter>("DocumentScreenApiAdapter"),
        c.resolve<IFileSaverService>("IFileSaverService"));
}

export class DownloadPatientRelatedDocumentActionProcessor implements IActionProcessor<DownloadPatientRelatedDocumentAction> {

    constructor(
        private readonly documentScreenApiAdapter: DocumentScreenApiAdapter,
        private readonly fileSaverService: IFileSaverService
    ) { }

    public async processAsync(action: DownloadPatientRelatedDocumentAction): Promise<void> {
        const response = await this.documentScreenApiAdapter.getPatientRelatedDocumentById(action.documentId, false);

        if (response.result instanceof BinaryDocument) {
            return this.fileSaverService.saveAs((response.result as BinaryDocument).content, response.result.downloadFileName);
        }
    }
}