import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import React from "react";
import AppointmentScheduleDefinitionScheduler from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/AppointmentScheduleDefinitionScheduler/AppointmentScheduleDefinitionScheduler";
import ScheduleTimePhasesGrid from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/ScheduleTimePhasesGrid/ScheduleTimePhasesGrid";
import PlanningPeriod from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/PlanningPeriod";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import MaterializedTimePhase from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/MaterializedTimePhase";
import moment from "moment";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import TimePhaseStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseStore";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import TimePhaseEditorDialogParams, { ITimePhaseEditorDialogResult } from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/TimePhaseEditorDialog/TimePhaseEditorDialogParams";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import SchedulingConfigurationApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingConfigurationApiAdapter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import TimePhaseId from "@Primitives/TimePhaseId.g";
import TimePhaseType from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseType";
import _ from "@HisPlatform/Common/Lodash";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

interface IAppointmentScheduleDefinitionTimePhasesPanelDependencies {
    apiAdapter: SchedulingConfigurationApiAdapter;
    notificationService: INotificationService;
    dialogService: IDialogService;
}

interface IAppointmentScheduleDefinitionTimePhasesPanelProps {
    _dependencies?: IAppointmentScheduleDefinitionTimePhasesPanelDependencies;
    _modalService?: IModalService;
    selectedPlanningPeriod: PlanningPeriod;
    schedulingServices: SchedulingServiceSubjectStore[];
    validateAsync: () => Promise<IClientValidationResult[]>;
    validationResults: IClientValidationResult[];
    isReadOnly: boolean;
}

@State.observer
class AppointmentScheduleDefinitionTimePhasesPanel extends React.Component<IAppointmentScheduleDefinitionTimePhasesPanelProps> {
    @State.observable.ref private timePhasesDataSource = new InMemoryDataGridDataSource(() => this.filteredTimePhases);
    @State.observable.ref private selectedMoment = DateTimeService.now();
    @State.observable.ref private schedulerEvents: MaterializedTimePhase[] = [];
    @State.observable private showSingleTimePhases = true;
    @State.observable private showRecurringTimePhases = true;
    @State.observable.ref private timePhaseDateFilter: LocalDateRange = null;

    private temporaryTimePhaseId = -1;
    private temporaryTimePhaseStore: TimePhaseStore = null;

    public componentDidMount() {
        dispatchAsyncErrors(this.loadMaterializedTimePhasesAsync(), this);
    }
    
    @State.bound private async addTimePhaseAsync() {
        const store = this.addNewTimePhaseToStore();

        const saved = await this.showTimePhaseModalAsync(store, true);

        if (!!saved) {
            await this.loadMaterializedTimePhasesAsync();
        } else {
            State.runInAction(() => {
                this.props.selectedPlanningPeriod?.removeTimePhase(store);
            });
        }
    }

    @State.action.bound private async editTimePhaseAsync(store: TimePhaseStore) {
        this.storeOriginalTimePhase(store);

        const saved = await this.showTimePhaseModalAsync(store, false);

        if (!!saved) {
            await this.loadMaterializedTimePhasesAsync();
        } else {
            State.runInAction(() => {
                this.restoreTimePhase(store);
            });
        }
    }

    @State.action.bound
    private addNewTimePhaseToStore() {
        const id = new TimePhaseId((this.temporaryTimePhaseId--).toString());
        const timePhase = TimePhaseStore.createNewWithId(id);
        this.props.selectedPlanningPeriod?.addTimePhase(timePhase);
        return timePhase;
    }

    @State.action.bound
    private storeOriginalTimePhase(timePhase: TimePhaseStore) {
        this.temporaryTimePhaseStore = _.cloneDeep(timePhase);
    }

    @State.action.bound
    private restoreTimePhase(timePhase: TimePhaseStore) {
        timePhase.copyValuesFrom(this.temporaryTimePhaseStore);
        timePhase.takeSnapshot();
    }

    private async showTimePhaseModalAsync(store: TimePhaseStore, isNew: boolean) {
        const result: ITimePhaseEditorDialogResult = await this.props._modalService.showDialogAsync<ITimePhaseEditorDialogResult>(
            new TimePhaseEditorDialogParams(this.props.schedulingServices, store, this.props.validateAsync, this.props.validationResults, isNew)
        );

        return result.saved;
    }

    @State.bound private async deleteTimePhaseAsync(store: TimePhaseStore) {
        const dialogResult = await this.props._dependencies.dialogService.yesNoCancel(
            StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.TimePhases.DeleteTimePhaseTitle,
            StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.TimePhases.DeleteTimePhase);

        if (dialogResult.resultCode === DialogResultCode.Yes) {
            this.props.selectedPlanningPeriod.timePhases.filter(t => ValueWrapper.equals(store.id, t.id))[0].delete();
        }

        await this.loadMaterializedTimePhasesAsync();
    }
    @State.bound
    private async loadMaterializedTimePhasesAsync() {
        if (this.props.selectedPlanningPeriod?.timePhases.length) {
            const res = await this.props._dependencies.apiAdapter.getMaterializedTimePhasesAsync(this.props.selectedPlanningPeriod);

            State.runInAction(() => {
                this.schedulerEvents = res.value;
            });
        }
    }

    @State.bound private entryClick(event: MaterializedTimePhase) {
        const store = this.props.selectedPlanningPeriod.timePhases.find(s => s.id?.value === event.timePhaseId.toString());
        this.editTimePhaseAsync(store);
    }

    @State.action.bound private setSelectedMoment(value: moment.Moment) {
        this.selectedMoment = value;
    }
    
    @State.computed private get noItemsMessage() {
        return !this.props.selectedPlanningPeriod ? StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.Panel.SelectPlanningPeriodMessage : null;
    }

    @State.computed private get filteredSchedulerEntries() {
        return this.schedulerEvents.filter(s => this.filteredTimePhases.some(f => !f.id || f.id.value === s.timePhaseId.toString()));
    }

    @State.computed private get filteredTimePhases() {
        if (this.props.selectedPlanningPeriod?.timePhases) {
            return this.props.selectedPlanningPeriod.timePhases.filter(t => {
                const basePredicate = !t.isDeleted && ((this.showSingleTimePhases && t.type === TimePhaseType.Single) || (this.showRecurringTimePhases && t.type === TimePhaseType.Recurring));
                if (t.type === TimePhaseType.Single && t.singleOccurrenceInterval?.from && this.timePhaseDateFilter) {
                    const rowDateRange = new LocalDateRange(LocalDate.createFromMoment(t.singleOccurrenceInterval.from), LocalDate.createFromMoment(t.singleOccurrenceInterval.to));
                    return this.timePhaseDateFilter.overlaps(rowDateRange, { touchingIsOverlap: true }) && basePredicate;
                }

                return basePredicate;
            });
        }

        return [];
    }

    @State.action.bound private filterRecurringTimePhases(newValue: boolean) {
        this.showRecurringTimePhases = !this.showRecurringTimePhases;
    }

    @State.action.bound private filterSingleTimePhases(newValue: boolean) {
        this.showSingleTimePhases = !this.showSingleTimePhases;
    }

    @State.bound private scrollToSelectedRow(row: TimePhaseStore) {
        if (row.type === TimePhaseType.Single && row.singleOccurrenceInterval.from) {
            this.setSelectedMoment(row.singleOccurrenceInterval.from);
        }

        // TODO: scroll to first occurrence of recurring event
    }

    @State.bound private setTimePhaseFilter(newValue: LocalDateRange) {
        this.timePhaseDateFilter = newValue;
    }
    
    @State.bound private observeSelectedPlanningPeriod() {
        return this.props.selectedPlanningPeriod;
    }

    @State.bound private reloadSchedule() {
        dispatchAsyncErrors(this.loadMaterializedTimePhasesAsync(), this);
    }

    public render() {
        return (
            <>
                <State.Reaction inspector={this.observeSelectedPlanningPeriod} reaction={this.reloadSchedule} />
                <ReadOnlyContext.Provider value={this.props.isReadOnly || !this.props.selectedPlanningPeriod}>
                    <ScheduleTimePhasesGrid
                        dataSource={this.timePhasesDataSource}
                        onAddTimePhaseAsync={this.addTimePhaseAsync}
                        onEditTimePhaseAsync={this.editTimePhaseAsync}
                        onDeleteTimePhaseAsync={this.deleteTimePhaseAsync}
                        noItemsMessage={this.noItemsMessage}
                        showRecurring={this.showRecurringTimePhases}
                        showSingle={this.showSingleTimePhases}
                        dateFilter={this.timePhaseDateFilter}
                        onFilterSingleChanged={this.filterSingleTimePhases}
                        onFilterRecurringChanged={this.filterRecurringTimePhases}
                        onTimePhaseFilterChange={this.setTimePhaseFilter}
                        onSelectedRowChange={this.scrollToSelectedRow}
                    />
                    {!!this.props.selectedPlanningPeriod &&
                        <AppointmentScheduleDefinitionScheduler
                            events={this.filteredSchedulerEntries}
                            listView={null}
                            newEventDuration={0}
                            onEditEntry={this.entryClick}
                            currentMoment={this.selectedMoment}
                            setSelectedMoment={this.setSelectedMoment}
                        />
                    }
                </ReadOnlyContext.Provider>
            </>
        );
    }
}

export default connect(
    AppointmentScheduleDefinitionTimePhasesPanel,
    new DependencyAdapter<IAppointmentScheduleDefinitionTimePhasesPanelProps, IAppointmentScheduleDefinitionTimePhasesPanelDependencies>(c => ({
        apiAdapter: c.resolve("SchedulingConfigurationApiAdapter"),
        notificationService: c.resolve("INotificationService"),
        dialogService: c.resolve("IDialogService"),
    })),
    new HisModalServiceAdapter()
);