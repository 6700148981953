import React, { useCallback } from "react";
import { Button } from "@CommonControls";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import CareActivityId from "@Primitives/CareActivityId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import ServiceRequestStateBadge from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ServiceRequestStateBadge/ServiceRequestStateBadge";
import ServiceRequestAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestAction.g";

interface IServiceRequestActionsProps {
    row?: ServiceRequestStore;
    currentCareActivityId: CareActivityId;
    isDetailOpen: boolean;

    onOpenRequest: (id: ServiceRequestId) => void;
    onOpenRequestDocumentAsync: (row: ServiceRequestStore) => Promise<void>;

    onOpenResults: (id: ServiceRequestId) => void;
    onOpenAdministration: (id: ServiceRequestId) => void;
    onOpenFinalDocumentAsync: (row: ServiceRequestStore) => Promise<void>;

    mininalButtonSet?: boolean;
}

const stopLeftClickPropagationSetting = {
    leftClick: true
};


const ServiceRequestActions: React.SFC<IServiceRequestActionsProps> = props => {

    const openAdministration = useCallback((e: React.MouseEvent) => {
        props.onOpenAdministration(props.row.id);
    }, [props.row, props.onOpenAdministration]);

    const openResults = useCallback((e: React.MouseEvent) => {
        props.onOpenResults(props.row.id);
    }, [props.row, props.onOpenResults]);

    const openRequestDocumentAsync = useCallback(async (e: React.MouseEvent) => {
        await props.onOpenRequestDocumentAsync(props.row);
    }, [props.row, props.onOpenRequestDocumentAsync]);

    const openFinalDocumentAsync = useCallback(async (e: React.MouseEvent) => {
        await props.onOpenFinalDocumentAsync(props.row);
    }, [props.row, props.onOpenFinalDocumentAsync]);

    const openRequest = useCallback((e: React.MouseEvent) => {
        props.onOpenRequest(props.row.id);
    }, [props.row, props.onOpenRequest]);

    const isViewResultsAllowed = props.row.possibleActions && props.row.possibleActions.some(j => j === ServiceRequestAction.ViewDiagnosticReport);
    const isViewDiagnosticRequestAllowed = props.row.possibleActions && props.row.possibleActions.some(j => j === ServiceRequestAction.ViewDiagnosticRequest);
    const isViewServiceRequestAllowed = props.row.possibleActions && props.row.possibleActions.some(i => i === ServiceRequestAction.View);
    const isAdministrationAllowed = props.row.possibleActions && props.row.possibleActions.some(i => i === ServiceRequestAction.Administrate);

    if (props.row.isNew) {
        return null;
    }

    return (
        <>
            {!props.isDetailOpen && !props.mininalButtonSet && (
                <Button
                    onClick={openRequest}
                    iconName="pen"
                    tooltipContent="Kérőlap"
                    tooltipPosition="bottom"
                    stopPropagation={stopLeftClickPropagationSetting}
                    disabled={!isViewServiceRequestAllowed}
                    size="compact"
                    automationId="__openRequest"
                />
            )}
            {!props.isDetailOpen && !props.mininalButtonSet && (
                <Button
                    onClickAsync={openRequestDocumentAsync}
                    iconName="document"
                    tooltipContent="Kérőlap dokumentum"
                    tooltipPosition="bottom"
                    stopPropagation={stopLeftClickPropagationSetting}
                    disabled={!isViewDiagnosticRequestAllowed}
                    size="compact"
                    automationId="__viewRequestDocument"
                />
            )}
            {(!isAdministrationAllowed ? (
                <>
                    <Button
                        onClick={openResults}
                        iconName="observationSheet"
                        disabled={!isViewResultsAllowed}
                        tooltipContent="Lelet"
                        tooltipPosition="bottom"
                        stopPropagation={stopLeftClickPropagationSetting}
                        size="compact"
                        automationId="__openResults"
                        visualStyle="primary"
                    />
                    <ServiceRequestStateBadge state={props.row.state} isIndex />
                </>
            ) : (
                    <>
                        <Button
                            onClick={openAdministration}
                            iconName="observationSheet"
                            tooltipContent="Eredmények rögzítése"
                            tooltipPosition="bottom"
                            stopPropagation={stopLeftClickPropagationSetting}
                            size="compact"
                            automationId="__editResults"
                            visualStyle="primary"
                        />
                        <ServiceRequestStateBadge state={props.row.state} isIndex />
                    </>
                )
            )}
            {!props.isDetailOpen && !props.mininalButtonSet && (
                <Button
                    style={{ marginLeft: props.mininalButtonSet ? undefined : -12 }}
                    onClick={openFinalDocumentAsync}
                    iconName="document"
                    tooltipContent="Lelet dokumentum"
                    tooltipPosition="bottom"
                    stopPropagation={stopLeftClickPropagationSetting}
                    // TODO:HISP-6531 disabled={!props.row.finalDocument}
                    size="compact"
                    automationId="__viewResultsDocument"
                />
            )}
        </>
    );
};

export default ServiceRequestActions;
