import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCases from "@Primitives/UseCases";
import AutonomyDisabilityStatementId from "@Primitives/AutonomyDisabilityStatementId.g";
import AutonomyDisabilityStatementPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/AutonomyDisabilityStatement/AutonomyDisabilityStatementPanel";

export default function configureAutonomyDisabilityStatementUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2({
        identifiers: [UseCases.autonomyDisabilityStatementDetail],
        component: AutonomyDisabilityStatementPanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));
            const id = map.get("autonomyDisabilityStatementId") ?? new AutonomyDisabilityStatementId("new");
            return {
                id: id,
            };
        }
    });
}
