import PatientId from "@Primitives/PatientId.g";
import * as CareProxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import AutonomyDisabilityStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/AutonomyDisabilityStatementStore";
import ReferencedAutonomyDisabilityAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/ReferencedAutonomyDisabilityAssignment";
import CustomAutonomyDisabilityAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/CustomAutonomyDisabilityAssignment";
import AutonomyDisabilityAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/AutonomyDisabilityAssignmentBase";
import AutonomyDisabilityStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/AutonomyDisabilityStatementHistoryItem";
import { getHistoryItemDetailDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalConditionDtoMapper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";

export function getAddAutonomyDisabilityStatementControllerDto(source: AutonomyDisabilityStatementStore, patientId: PatientId, isValidateOnly: boolean) {
    return new CareProxy.AddAutonomyDisabilityStatementControllerDto({
        patientId: patientId,
        historyItemDtos: [getAutonomyDisabilityStatementHistoryItemDto(source.latestHistoryItem as AutonomyDisabilityStatementHistoryItem)],
        autonomyDisabilityAssignmentBaseDto:  getAutonomyDisabilityAssignmentDto(source.autonomyDisabilityAssignment),
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
    });
}

export function getUpdateAutonomyDisabilityStatementControllerDto(source: AutonomyDisabilityStatementStore, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.UpdateAutonomyDisabilityStatementControllerDto({
        autonomyDisabilityStatementId: source.id,
        historyItemDtos: [getAutonomyDisabilityStatementHistoryItemDto(source.latestHistoryItem as AutonomyDisabilityStatementHistoryItem)],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteAutonomyDisabilityStatementHistoryItemControllerDto(source: AutonomyDisabilityStatementStore, versionNumber: number, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.DeleteAutonomyDisabilityStatementHistoryItemControllerDto({
        autonomyDisabilityStatementId: source.id,
        versionNumbers: [versionNumber],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteAutonomyDisabilityStatementControllerDto(store: AutonomyDisabilityStatementStore) {
    return new CareProxy.DeleteAutonomyDisabilityStatementControllerDto({
        autonomyDisabilityStatementId: store.id,
        lockId: store.lockInfo?.lockId,
        extensionData: store.extensionData,
        isValidateOnly: false,
        releaseLockIfSuccessful: true
    });
}

function getAutonomyDisabilityAssignmentDto(source: AutonomyDisabilityAssignmentBase) {
    let dto;

    if (source instanceof ReferencedAutonomyDisabilityAssignment) {
        dto = new CareProxy.ReferencedAutonomyDisabilityAssignmentDto({ autonomyDisabilityId: source.id });
    } else if (source instanceof CustomAutonomyDisabilityAssignment) {
        dto = new CareProxy.CustomAutonomyDisabilityAssignmentDto({ name: source.name });
    }

    return dto;
}

function getAutonomyDisabilityStatementHistoryItemDto(source: AutonomyDisabilityStatementHistoryItem) {
    return new CareProxy.AutonomyDisabilityStatementHistoryItemDto({
        versionNumber: source.versionNumber,
        index: source.index,
        createdAt: source.createdAt,
        historyItemDetail: getHistoryItemDetailDto(source.historyItemDetail),
        description: source.description,
        extensionData: source.extensionData
    });
}