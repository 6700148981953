import React from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";

interface IComponentPrinterProps {
    componentRefToPrint: () => React.ReactInstance;
    triggerComponent?: <T>() => React.ReactElement<ITriggerComponentProps<T>>;
    onBeforePrint?: () => void;
    onAfterPrint?: () => void;
    marginSize?: string;
    orientation?: "portrait" | "landscape";
}

interface ITriggerComponentProps<T> {
    onClick: () => void;
    ref: (v: T) => void;
}

export default class ComponentPrinter extends React.Component<IComponentPrinterProps> {

    public static defaultProps: Partial<IComponentPrinterProps> = {
        marginSize: "19.05mm",
        orientation: "landscape"
    };

    private ref = React.createRef<ReactToPrint>();

    public render() {
        return (
            <ReactToPrint
                ref={this.ref}
                content={this.props.componentRefToPrint}
                trigger={this.props.triggerComponent}
                onBeforePrint={this.props.onBeforePrint}
                onAfterPrint={this.props.onAfterPrint}
                pageStyle={`@page { size: ${this.props.orientation}; margin: ${this.props.marginSize}; } }`}
            >
                {this.props.children}
            </ReactToPrint>
        );
    }

    public print() {
        this.ref.current.handlePrint();
    }
}

export function printComponent(props: IComponentPrinterProps) {
    return useReactToPrint({
        content: props.componentRefToPrint,
        onBeforePrint: props.onBeforePrint,
        onAfterPrint: props.onAfterPrint
    });
}