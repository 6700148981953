import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import IDecisionSupportModalParams from "./IDecisionSupportDialogParams";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Modal from "@CommonControls/Modal";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import IMessagingSubscription from "@Toolkit/CommonWeb/CommandProcessing/Definition/IMessagingSubscription";
import IMessagingSubscriptionManager from "@Toolkit/CommonWeb/CommandProcessing/Definition/IMessagingSubscriptionManager";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import StaticAssecoMedWebAppResources from "@AssecoMedPlugin/StaticResources/StaticAssecoMedWebAppResources";
import SendInitializationPayloadCommand from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/ExternalDecisionSupport/Commands/SendInitializationPayloadCommand";

interface IDecisionSupportModalProps extends IModalComponentParams<void>, IDecisionSupportModalParams {
}

function DecisionSupportModal(props: IDecisionSupportModalProps) {
    const { messagingSubscriptionManager } = useDependencies(c => ({
        messagingSubscriptionManager: c.resolve<IMessagingSubscriptionManager>("IMessagingSubscriptionManager")
    }));

    const iFrameRef = useRef<HTMLIFrameElement>(null);

    const [messagingSubscription, setMessagingSubscription] = useState<IMessagingSubscription>(null);

    const formattedUrl = useMemo(() => formatStringWithObjectParams(
        props.url,
        {
            patientId: props.patientId.toString(),
            careActivityId: props.careActivityId.toString()
        }
    ), [props.url]);

    const onClose = useCallback(() => props.onClose(), [props.onClose]);

    const sendInitializationPayload = useCallback(() => {
        if (iFrameRef.current && iFrameRef.current.contentWindow !== null) {
            iFrameRef.current.contentWindow.postMessage(JSON.stringify(props.initializationPayload), "*");
        }
    }, [iFrameRef, props.initializationPayload]);

    const disposeSubscription = useCallback(() => {
        if (!!messagingSubscription) {
            messagingSubscription.dispose();
            setMessagingSubscription(null);
        }

        if (!!iFrameRef.current) {
            iFrameRef.current.onload = null;
        }
    }, [iFrameRef, messagingSubscription]);

    useEffect(() => {
        disposeSubscription();

        if (iFrameRef.current) {
            const contentWindow = iFrameRef.current.contentWindow;
            const subscription = messagingSubscriptionManager.subscribeWithAccessor(() => contentWindow);
            subscription.registerCallback("SendInitializationPayloadCommand", _ => Promise.resolve(sendInitializationPayload()));
            props.registerEventHandlers(subscription);
            setMessagingSubscription(subscription);
        }

        return () => disposeSubscription();
    }, [iFrameRef]);

    return (
        <Modal isOpen title={StaticAssecoMedWebAppResources.DecisionSupportModal.Title} closeButton onClose={onClose} size="fullscreen">
            <iframe
                id={"EmbeddedDecisionSupportFrame"}
                src={formattedUrl}
                ref={iFrameRef}
                style={{ width: "100%", height: "100%", border: "none" }}
                allow="*" />
        </Modal>
    );
}

export default State.observer(DecisionSupportModal);