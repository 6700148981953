import React from "react";
import * as Ui from "@CommonControls";
import Styles from "./IcardioProcessWidget.less";
import IWorkflowForm from "@ICardioIntegrationPlugin/Model/IWorkflowForm";

interface ICardioProcessItemProps {
    workflowForm: IWorkflowForm;
}

export const ICardioProcessItem: React.FC<ICardioProcessItemProps> = props => {
    const answersKeys = Object.keys(props.workflowForm.Answers);
    return (
        <Ui.Flex className={Styles.noMarginTop}>
            <Ui.Flex.Item className={Styles.orderContainer} xs={3}>
                <div className={Styles.doneFormOrder}>
                    {props.workflowForm.Order}
                </div>
            </Ui.Flex.Item >
            <Ui.Flex.Item xs={9}>
                <p className={Styles.lowerMarginBottom}>{props.workflowForm.FormTitle}</p>
                <hr />
                {
                    answersKeys.map((key) => {
                        return (
                                <div key={key}>
                                    <p className={Styles.lowerMarginBottom}>{key}</p>
                                    <p className={Styles.formTextValue}>{props.workflowForm.Answers[key]}</p><Ui.Icon size="normal" visualStyle="primary" iconName={"check"} />
                                </div>
                        );
                    })
                }
            </Ui.Flex.Item>
        </Ui.Flex>
    );
};

export default ICardioProcessItem;