import { createFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterHelpers";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import { IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import ExtendedFilterStoreBase from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ExtendedFilterStoreBase";
import { isNullOrWhiteSpace } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import PatientId from "@Primitives/PatientId.g";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";

export default class ExtendedPermanentPrescriptionFilterStore extends ExtendedFilterStoreBase {
    private static readonly generalMedicationSearchFilterName: string = "PermanentPrescriptionBased_GeneralMedicationSearchFilter";
    private static readonly medicationSubsidyClaimTypeIdName: string = "PermanentPrescriptionBased_ClaimTypeId";
    private static readonly organizationUnitIdName: string = "PermanentPrescriptionBased_OrganizationUnitId";
    private static readonly isDeletedName: string = "PermanentPrescriptionBased_IsDeleted";
    private static readonly patientIdName: string = "PermanentPrescriptionBased_PatientId";

    @State.observable.ref private _organizationUnitIds: OrganizationUnitId[] = [];

    @State.computed
    public get generalSearchValue(): string {
        return this.getFilter(ExtendedPermanentPrescriptionFilterStore.generalMedicationSearchFilterName);
    }

    @State.computed
    public get medicationSubsidyClaimTypeId(): MedicationSubsidyClaimTypeId {
        return this.getFilter(ExtendedPermanentPrescriptionFilterStore.medicationSubsidyClaimTypeIdName);
    }

    @State.computed
    public get organizationUnitIds(): OrganizationUnitId[] {
        return this._organizationUnitIds;
    }

    @State.computed
    public get patientId(): PatientId {
        return this.getFilter(ExtendedPermanentPrescriptionFilterStore.patientIdName);
    }

    constructor(filterStore: IFilterStore) {
        super(filterStore);
        this.setFilter(ExtendedPermanentPrescriptionFilterStore.isDeletedName, false);
    }

    public static getFilterDescriptors() {
        return [
            createFilterDescriptor(
                ExtendedPermanentPrescriptionFilterStore.generalMedicationSearchFilterName,
                {
                    serialize: (value: string) => value,
                    deserialize: (value: string) => value
                } as IDataGridFilterValueSerializer<string>,
                WorklistConditionType.String
            ),
            createFilterDescriptor(
                ExtendedPermanentPrescriptionFilterStore.medicationSubsidyClaimTypeIdName,
                null,
                WorklistConditionType.EntityId
            ),
            createFilterDescriptor(
                ExtendedPermanentPrescriptionFilterStore.organizationUnitIdName,
                {
                    serialize: ExtendedPermanentPrescriptionFilterStore.serializeOrganizationUnitIds,
                    deserialize: ExtendedPermanentPrescriptionFilterStore.deserializeOrganizationUnitIds,
                } as IDataGridFilterValueSerializer<OrganizationUnitId | OrganizationUnitId[]>,
                WorklistConditionType.EntityId
            ),
            createFilterDescriptor(
                ExtendedPermanentPrescriptionFilterStore.isDeletedName,
                null,
                WorklistConditionType.Boolean
            ),
            createFilterDescriptor(
                ExtendedPermanentPrescriptionFilterStore.patientIdName,
                null,
                WorklistConditionType.EntityId
            )
        ] as IDataGridFilterDescriptor[];
    }
    
    @State.action.bound
    public setGeneralSearchValue(newValue: string) {
        const filterValue = isNullOrWhiteSpace(newValue) ? "" : newValue;
        this.setFilter(ExtendedPermanentPrescriptionFilterStore.generalMedicationSearchFilterName, filterValue);
    }

    @State.action.bound
    public setMedicationSubsidyClaimTypeId(newValue: MedicationSubsidyClaimTypeId) {
        this.setFilter(ExtendedPermanentPrescriptionFilterStore.medicationSubsidyClaimTypeIdName, newValue);
    }

    @State.action.bound
    public addOrganizationUnitId(newValue: OrganizationUnitId) {
        if (this._organizationUnitIds.some(id => id.value === newValue.value)) {
            return;
        }

        this._organizationUnitIds = [...this._organizationUnitIds, newValue];
        this.setFilter(ExtendedPermanentPrescriptionFilterStore.organizationUnitIdName, this._organizationUnitIds);
    }

    @State.action.bound
    public removeOrganizationUnitId(value: OrganizationUnitId) {
        if (!this._organizationUnitIds.some(id => id.value === value.value)) {
            return;
        }

        this._organizationUnitIds = this._organizationUnitIds.filter(id => id.value !== value.value);
        this.setFilter(ExtendedPermanentPrescriptionFilterStore.organizationUnitIdName, this._organizationUnitIds);
    }

    @State.action.bound
    public setOrganizationUnitIds(organizationUnitIds: OrganizationUnitId[]) {
        this._organizationUnitIds = organizationUnitIds;
        this.setFilter(ExtendedPermanentPrescriptionFilterStore.organizationUnitIdName, this._organizationUnitIds);
    }

    @State.action.bound
    public setPatientId(patientId: PatientId) {
        this.setFilter(ExtendedPermanentPrescriptionFilterStore.patientIdName, patientId);
    }

    @State.action.bound
    public clearAllFilters() {
        this.setGeneralSearchValue("");
        this.setMedicationSubsidyClaimTypeId(null);
        this.setOrganizationUnitIds([]);
        this.setPatientId(null);
        this.filterStore.__reset();
    }

    private static serializeOrganizationUnitIds(value: OrganizationUnitId | OrganizationUnitId[]): string {
        return Array.isArray(value)
            ? value.map(v => v.value).join(",")
            : value.value;
    }

    private static deserializeOrganizationUnitIds(value: string): OrganizationUnitId[] {
        return value?.split(",").map(i => new OrganizationUnitId(i));
    }
}