import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import PermissionDefinitionBuilder from "@PluginInterface/OperationAccessControl/PermissionDefinitionBuilder";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";
import { PermissionDefinitions } from "@HisPlatform/PermissionDefinitions/PermissionDefinitions.g";
import { HunEHealthInfrastructurePermissionDefinitions } from "@HunEHealthInfrastructurePlugin/PermissionDefinitions/HunEHealthInfrastructurePermissionDefinitions.g";

export default function configureCarePermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry) {
    const pointOfCareScope = PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare);

    // careRegister
    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Care.CareRegister.SetPatientGuidanceCareActivityStates,
            PermissionDefinitions.WebAppBackend.Care.SetPatientGuidanceCareActivityStates
        )
            .withPermissionScope(pointOfCareScope)
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Care.CareRegister.ViewCareActivityBaseData,
            PermissionDefinitions.WebAppBackend.Care.ViewCareActivityBaseData
        )
            .withPermissionScope(pointOfCareScope)
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Care.CareRegister.ViewCareActivityDischargeData,
            PermissionDefinitions.WebAppBackend.Care.ViewCareActivityDischargeData
        )
            .withPermissionScope(pointOfCareScope)
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder
            .create(
                PermissionDefinitions.Care.CareRegister.ViewDiagnoses,
                PermissionDefinitions.WebAppBackend.Care.ViewDiagnoses
            )
            .withPermissionScopes(pointOfCareScope)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Care.CareRegister.ViewPerformedServices,
            PermissionDefinitions.WebAppBackend.Care.ViewPerformedServices
        )
            .withPermissionScope(pointOfCareScope)
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Care.CareRegister.EditCareActivityBaseData,
            PermissionDefinitions.WebAppBackend.Care.EditCareActivityBaseData
        )
            .withPermissionScope(pointOfCareScope)
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Care.CareRegister.EditCareActivityDischargeData,
            PermissionDefinitions.WebAppBackend.Care.EditCareActivityDischargeData
        )
            .withPermissionScope(pointOfCareScope)
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder
            .create(
                PermissionDefinitions.Care.CareRegister.EditDiagnoses,
                PermissionDefinitions.WebAppBackend.Care.EditDiagnoses
            )
            .withPermissionScopes(pointOfCareScope)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Care.CareRegister.EditPerformedServices,
            PermissionDefinitions.WebAppBackend.Care.EditPerformedServices
        )
            .withPermissionScope(pointOfCareScope)
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Care.CareRegister.ViewDocument,
            PermissionDefinitions.DocumentManagement.Documents.View,
            PermissionDefinitions.Care.ServiceRequestManagement.ViewDocument,
            PermissionDefinitions.WebAppBackend.Care.ViewDocument
        )
            .withPermissionScope(pointOfCareScope)
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Care.CareRegister.EditDocument,
            PermissionDefinitions.DocumentManagement.Documents.Edit,
            PermissionDefinitions.WebAppBackend.Care.EditDocument,
            PermissionDefinitions.WebAppBackend.Care.EditCareActivityRelatedDocuments
        )
            .withPermissionScope(pointOfCareScope)
            .withPermissionScopesFor(
                PermissionDefinitions.DocumentManagement.Documents.Edit,
                PermissionDefinitionBuilder.createNegativePermissionScope(PermissionScopeType.documentAction, ["Delete", "Publish", "CancelPublish", "Finalize", "Edit"])
            )
            .withAlias(null, PermissionScopeType.documentAction)
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Care.CareRegister.SetCareRelatedCareActivityStates,
            PermissionDefinitions.WebAppBackend.Care.SetCareRelatedCareActivityStates
        )
            .withPermissionScope(pointOfCareScope)
            .withPermissionScope(PermissionDefinitionBuilder.createNegativePermissionScope(PermissionScopeType.feature, ["Reported"]))
            .build());

    permissionDefinitionRegistry.register(
        // WARNING: no BFF permission configured yet!
        PermissionDefinitionBuilder.create(PermissionDefinitions.Care.CareRegister.SetCareRelatedCareActivityStates)
            .withPermissionScopes(
                pointOfCareScope,
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.feature, ["Reported"])
            )
            .withAlias("Reported", PermissionScopeType.feature)
            .build());

    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(
        PermissionDefinitions.Care.ServiceRequestManagement.ViewCareActivityServiceRequests,
        PermissionDefinitions.WebAppBackend.ServiceRequest.ViewCareActivityServiceRequests
    )
        .withPermissionScope(PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare)).build());

    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(
        PermissionDefinitions.Care.ServiceRequestManagement.ViewPatientServiceRequests,
        PermissionDefinitions.WebAppBackend.ServiceRequest.ViewPatientServiceRequests
    ).build());

    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(
        PermissionDefinitions.Care.ServiceRequestManagement.EditServiceRequests,
        PermissionDefinitions.WebAppBackend.ServiceRequest.EditServiceRequests
    )
        .withPermissionScope(PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare)).build());

    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(PermissionDefinitions.Care.ServiceRequestManagement.EditRequestedServices)
        .withPermissionScope(PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare)).build());

    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(PermissionDefinitions.Care.ServiceRequestManagement.ValidateRequestedServices)
        .withPermissionScope(PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare)).build());

    // EHR
    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Care.CareRegister.ViewPatientHealthRecords,
            PermissionDefinitions.Reporting.Reporting.ViewPatientHealthRecords,
            PermissionDefinitions.Care.ServiceRequestManagement.ViewPatientHealthRecords,
            PermissionDefinitions.WebAppBackend.Care.ViewPatientHealthRecords,
            HunEHealthInfrastructurePermissionDefinitions.MedicationRequest.Prescription.ViewPatientHealthRecords)
            .build());

    // patientRegister
    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(
        PermissionDefinitions.WebAppBackend.Patients.CreatePatient
    ).build());

    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(
        PermissionDefinitions.WebAppBackend.Patients.EditPatient
    ).build());

    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(
        PermissionDefinitions.WebAppBackend.Patients.ViewPatient
    ).build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Care.MedicalCondition.CreateMedicalConditions,
            PermissionDefinitions.WebAppBackend.Patients.CreateMedicalConditions
        ).build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Care.MedicalCondition.EditMedicalConditions,
            PermissionDefinitions.WebAppBackend.Patients.EditMedicalConditions
        ).build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(
            PermissionDefinitions.Care.MedicalCondition.DeleteMedicalConditions,
            PermissionDefinitions.WebAppBackend.Patients.DeleteMedicalConditions
        ).build()
    );
}