import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import EhiCovidSymptomsSeverityId from "@Primitives/EhiCovidSymptomsSeverityId.g";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";

interface IEhiCovidSymptomsSeverityColumnDependencies {
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
}

interface IEhiCovidSymptomsSeverityColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IEhiCovidSymptomsSeverityColumnDependencies;
}


const EhiCovidSymptomsSeverityColumn: React.FC<IEhiCovidSymptomsSeverityColumnProps> = (props: IEhiCovidSymptomsSeverityColumnProps) => {

    const valueRenderer = (value: EhiCovidSymptomsSeverityId) => {
        if (value) {
            const ehiCovidSymptomsSeverity = props._dependencies.hunEhiCareReferenceDataStore.ehiCovidSymptomsSeverities.get(value);
            return ehiCovidSymptomsSeverity?.displayValue?.Name ?? "";
        }
        return "";
    };

    return (
        <DataGridColumn
            onRenderCellValue={valueRenderer}
            {...props}
        />
    );
};

export default connect(
    EhiCovidSymptomsSeverityColumn,
    new DependencyAdapter<IEhiCovidSymptomsSeverityColumnProps, IEhiCovidSymptomsSeverityColumnDependencies>(c => ({
        hunEhiCareReferenceDataStore: c.resolve("HunEhiCareReferenceDataStore")
    }))
);