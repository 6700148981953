import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class FormEnumReference {
    @State.observable.ref public enumName: string;
    @State.observable.ref public formDefinitionId: FormDefinitionId;
    
    constructor(
        enumName: string,
        formDefinitionId?: FormDefinitionId
    ) { 
      this.enumName = enumName;
      this.formDefinitionId = formDefinitionId;  
    }

    public get isLocal() { return isNullOrUndefined(this.formDefinitionId); }
    public get isExternal() { return !this.isLocal; }
}