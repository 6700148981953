import * as Proxy from "@HisPlatform/BoundedContexts/Scheduling/Api/Proxy.g";
import * as BffProxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import UnregisteredPatientStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/UnregisteredPatientStore";
import NameStore from "@Primitives/NameStore";
import PatientId from "@Primitives/PatientId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import ServiceRequestSubject from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ServiceRequestSubject";
import SchedulingServiceSubject from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubject";
import { IAppointmentSubject } from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/IAppointmentSubject";
import InvalidationInfo from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/InvalidationInfo";
import TelecomTypeId from "@Primitives/TelecomTypeId.g";

type ResponseType = Proxy.UpdateAppointmentCommandResponse |
    Proxy.CreateAppointmentCommandResponse |
    Proxy.CreateServiceRequestWithAppointmentCommandResponse |
    Proxy.ModifyServiceRequestWithCreateAppointmentCommandResponse |
    Proxy.ModifyServiceRequestWithUpdateAppointmentCommandResponse |
    Proxy.GetAppointmentByIdQueryResponse |
    Proxy.GetAppointmentByIdForPractitionerScheduleQueryResponse |
    Proxy.TryGetAppointmentBySubjectServiceRequestIdQueryResponse |
    Proxy.AppointmentDto |
    BffProxy.GetServiceRequestScreenDataCommandResponse;

type EntityDtoType = Proxy.AppointmentDto | BffProxy.AppointmentDto;

@Di.injectable()
export default class SchedulingStoreMapper extends EntityStoreMapper<EntityDtoType, Appointment, ResponseType> {
    protected storeEntityIdType = AppointmentScheduleEntryId;

    protected entityDtoSelector(response: ResponseType) {
        if (response instanceof Proxy.UpdateAppointmentCommandResponse ||
            response instanceof Proxy.CreateAppointmentCommandResponse ||
            response instanceof Proxy.CreateServiceRequestWithAppointmentCommandResponse ||
            response instanceof Proxy.ModifyServiceRequestWithCreateAppointmentCommandResponse ||
            response instanceof Proxy.ModifyServiceRequestWithUpdateAppointmentCommandResponse ||
            response instanceof BffProxy.GetServiceRequestScreenDataCommandResponse) {
            return response.appointment;
        } else if (
            response instanceof Proxy.GetAppointmentByIdQueryResponse ||
            response instanceof Proxy.GetAppointmentByIdForPractitionerScheduleQueryResponse ||
            response instanceof Proxy.TryGetAppointmentBySubjectServiceRequestIdQueryResponse) {
            return response.appointmentDto;
        } else if (response instanceof Proxy.AppointmentDto) {
            return response;
        }
        return null;
    }

    protected applyToStoreCore(target: Appointment, response: any): void {
        let subjectService: IAppointmentSubject;

        const dto = this.entityDtoSelector(response);

        if (dto === null) {
            return;
        }

        if (dto.subject instanceof Proxy.ServiceRequestSubjectDto || dto.subject instanceof BffProxy.ServiceRequestSubjectDto) {
            subjectService = new ServiceRequestSubject();
            (subjectService as ServiceRequestSubject).versionedId = new EntityVersionSelector<ServiceRequestId>((dto.subject as Proxy.ServiceRequestSubjectDto).serviceRequestId, LocalDate.today());
        } else if (dto.subject instanceof Proxy.SchedulingServiceSubjectDto || dto.subject instanceof BffProxy.SchedulingServiceSubjectDto) {
            subjectService = new SchedulingServiceSubject();
            (subjectService as SchedulingServiceSubject).schedulingServiceId = (dto.subject as Proxy.SchedulingServiceSubjectDto).schedulingServiceId;
        }

        target.id = dto.id;
        target.appointmentScheduleSlotSeriesId = dto.appointmentScheduleSlotSeriesId;
        target.subjectService = subjectService;
        target.intervalFrom = dto.interval?.from;
        target.intervalTo = dto.interval?.to;
        target.usedSlotIds = dto.usedSlotIds;
        target.careActivityId = dto.createdCareActivityId;
        target.description = dto.description;
        target.isInvalid = dto.isInvalid;
        target.invalidationReason = dto.invalidationReason;
        target.episodeOfCareIds = dto.episodeOfCareIds;
        target.additionalParticipants = dto.additionalParticipantAddresses;

        if (!!dto.invalidationInfo) {
            target.invalidationInfo = new InvalidationInfo(
                dto.invalidationInfo.appointmentScheduleDefinition,
                dto.invalidationInfo.schedulingService,
                dto.invalidationInfo.organizationUnit,
                dto.invalidationInfo.practitioner);
        }

        if (target instanceof Appointment) {
            (target as Appointment).status = dto.status;
        }

        dto.participants?.forEach(item => {
            if (item instanceof Proxy.LocationParticipantDto || item instanceof BffProxy.LocationParticipantDto) {
                target.pointOfCareId = new PointOfCareId(item.organizationUnitId?.value);
            }
            if (item instanceof Proxy.PractitionerParticipantDto || item instanceof BffProxy.PractitionerParticipantDto) {
                if (item.isExternal) {
                    target.additionalPractitionerIds.push(item.practitionerId);
                } else {
                    target.practitionerIds.push(item.practitionerId);
                }
            }
            if (item instanceof Proxy.RegisteredPatientParticipantDto || item instanceof BffProxy.RegisteredPatientParticipantDto) {
                target.patientId = item.patientId;
                target.patientName = NameStore.create(item.patientName);
            }
            if (item instanceof Proxy.UnregisteredPatientParticipantDto || item instanceof BffProxy.UnregisteredPatientParticipantDto) {
                const store = new UnregisteredPatientStore();
                store.address = item.mailingAddress;
                store.birthDate = item.dateOfBirth;
                store.email = item.telecoms?.filter(x => x.telecomTypeId === TelecomTypeId.Email).shift()?.value;
                store.genderId = item.gender;
                store.insuranceDocumentNumber = item.insuranceDocumentNumber;
                store.nameStore = NameStore.create(item.name);
                store.note = item.note;
                store.phoneNumber = item.telecoms?.filter(x => x.telecomTypeId === TelecomTypeId.Phone).shift()?.value;
                target.unregisteredPatient = store;
            }
        });

        if (!target.patientId) {
            target.patientId = new PatientId("unregistered");
        }

        if (dto.referral) {
            target.referral.referralCareIdentifier = dto.referral.referralCareIdentifier;
            target.referral.referralCreationDate = dto.referral.referralCreationDate;
            target.referral.referralDoctorId = dto.referral.referralDoctorId;

            target.referral.referralWorkplace = new EntityVersionSelector<ExternalLocationId>(
                dto.referral.referralWorkplace?.entityId,
                dto.referral.referralWorkplace?.validOn ?? dto.referral.referralCreationDate);

            target.referral.referralDiagnosisId1 = dto.referral.referralDiagnosisId1;
            target.referral.referralDiagnosisLateralityId1 = dto.referral.referralDiagnosisLateralityId1;
            target.referral.referralDiagnosisId2 = dto.referral.referralDiagnosisId2;
            target.referral.referralDiagnosisLateralityId2 = dto.referral.referralDiagnosisLateralityId2;
        }

        target.rowVersion = dto.rowVersion;
    }
}
