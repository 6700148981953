import IListQueryOrderBy from "./IListQueryOrderBy";

export default class ListQueryOptions {

    public pageSize: number;
    public currentPage: number;
    public orderBy: IListQueryOrderBy[];
    public searchParameters: any;

    public static create(
        initializer: {
            pageSize?: number,
            currentPage?: number,
            orderBy?: IListQueryOrderBy[],
            searchParameters?: any
        }
    ) {
        const ret = new ListQueryOptions();
        ret.pageSize = initializer.pageSize;
        ret.currentPage = initializer.currentPage;
        ret.orderBy = initializer.orderBy;
        ret.searchParameters = initializer.searchParameters;
        return ret;
    }

    public getPagingQueryString(): string {
        return this.pageSize + ";" + this.currentPage;
    }

    public getOrderingQueryStrings(): string[] {
        const ret: string[] = [];

        if (this.orderBy && Array.isArray(this.orderBy)) {
            this.orderBy.forEach((o: IListQueryOrderBy) => {
                ret.push(o.fieldName + ";" + (o.ascending === true ? "asc" : "desc"));
            });
        }

        return ret;
    }
}