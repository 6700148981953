import FormDataElementBase from "./FormDataElementBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FormEnumReference from "./FormEnumReference";

export default class EnumFormDataElement extends FormDataElementBase {

    @State.observable.ref public enumReference: FormEnumReference;
    @State.observable.ref public activeValues: number[];

    constructor(
        name: string,
        isArray: boolean,
        defaultValue: number,
        isReadOnly: boolean,
        isVisible: boolean,
        enumReference?: FormEnumReference,
        activeValues?: number[]
    ) {
        super(name, isArray, defaultValue, isReadOnly, isVisible);
        this.enumReference = enumReference;
        this.activeValues = activeValues;
    }

    @State.action.bound
    public setEnumReference(enumReference: FormEnumReference) {
        this.enumReference = enumReference;
    }

    @State.action.bound
    public setActiveValues(activeValues: number[]) {
        this.activeValues = activeValues;
    }

    public formDataElementType = "Enum";
}