import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/IObservation";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import CareContentId from "@Primitives/CareContentId.g";
import { getObservation } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/Observations/ObservationStoreMapper";

@Di.injectable()
export default class ObservationsApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("ICareRegisterClient") private readonly apiClient: Proxy.ICareRegisterClient
    ) {
        super();
    }

    public getObservationsForCareActivityAsync(careActivityId: CareActivityId): Promise<SimpleStore<IObservation[]>> {
        return this.processOperationAsync(
            new SimpleStore<IObservation[]>(),
            async target => {
                const response = await this.apiClient.getObservationsForCareActivityQueryAsync(CreateRequestId(), careActivityId.value);

                target.operationInfo = createOperationInfo(response);
                target.value = response.observations.map(i => getObservation(i));
            }
        );
    }

    public getTextualObservationContentAsync(contentId: CareContentId): Promise<SimpleStore<string>> {
        return this.processOperationAsync(
            new SimpleStore<string>(),
            async target => {
                target.value = await this.getFileResponseAsStringAsync(async () => {
                    const response = await this.apiClient.getTextualObservationContentQueryAsync(CreateRequestId(), contentId.value);
                    target.operationInfo = createOperationInfo(response);
                    return  response;
                }, "text/html");
            }
        );
    }
}