import State from "@Toolkit/ReactClient/Common/StateManaging";
import CountryId from "@Primitives/CountryId.g";

export default class Address {
    @State.observable.ref public countryId: CountryId = null;
    
    @State.action.bound
    public setCountryId(countryId: CountryId) {
        this.countryId = countryId;
    }

    @State.observable.ref public settlement: string = null;
    @State.observable.ref public zipCode: string = null;

    @State.action.bound
    public setSettlement(settlement: string) {
        this.settlement = settlement;
    }

    @State.action.bound
    public setZipCode(zipCode: string) {
        this.zipCode = zipCode;
    }

    @State.observable public addressLine: string = "";
    
    @State.action.bound
    public setAddressLine(addressLine: string) {
        this.addressLine = addressLine;
    }

    constructor(countryId?: CountryId, settlement?: string, zipCode?: string, addressLine?: string) {
        this.countryId = countryId;
        this.settlement = settlement;
        this.zipCode = zipCode;
        this.addressLine = addressLine;
    }
}
