import PermanentPrescriptionId from "@Primitives/PermanentPrescriptionId.g";
import StoreBase from "@Toolkit/CommonWeb/Model/StoreBase";
import Dosage from "./Dosage";

export default class PermanentPrescriptionDosage extends StoreBase {
    public id: PermanentPrescriptionId;
    public dosage: Dosage;

    constructor(id: PermanentPrescriptionId, dosage: Dosage) {
        super();
        this.id = id;
        this.dosage = dosage;
    }
}