import IDiagnosisAdditionalActionsProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IDiagnosisAdditionalActionsProps";
import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import HunCareContagiousPatientReportApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ContagiousPatientReport/HunCareContagiousPatientReportApiAdapter";

@Di.injectable()
export default class DiagnosisAdditionalActionsExtensionComponentService implements IExtensionComponentService<IDiagnosisAdditionalActionsProps> {

    constructor(
        @Di.inject("HunCareContagiousPatientReportApiAdapter") private contagiousPatientReportApiAdapter: HunCareContagiousPatientReportApiAdapter) {
    }

    public async isVisibleAsync(props: IDiagnosisAdditionalActionsProps) {
        const isContagiousPatientReportNeeded =
            await this.contagiousPatientReportApiAdapter.isContagiousPatientReportNeededQueryAsync(
                props.patientId,
                props.careActivityId,
                props.diagnosisStore?.conditionVersionSelector?.id,
                props.diagnosisStore?.use,
                props.pointOfCareId);

        return isContagiousPatientReportNeeded;
    }

    public shouldRerunIsVisible(props: IDiagnosisAdditionalActionsProps, prevProps: IDiagnosisAdditionalActionsProps) {
        return !ValueWrapper.equals(props.diagnosisStore?.conditionVersionSelector?.id, prevProps?.diagnosisStore?.conditionVersionSelector?.id);
    }
}
