import React from "react";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import IScreenshotService from "@HisPlatform/Services/Definition/ScreenshotService/IScreenshotService";

interface IFeedbackMenuDependencies {
    applicationContext: ApplicationContext;
    screenshotService: IScreenshotService;
}

interface IFeedbackMenuProps {
    _dependencies?: IFeedbackMenuDependencies;
}

class FeedbackMenu extends React.Component<IFeedbackMenuProps> {

    private get applicationContext() { return this.props._dependencies.applicationContext; }
    private get screenshotService() { return this.props._dependencies.screenshotService; }

    @State.bound
    private async openFeedbackDialogAsync() {
        await this.screenshotService.takeScreenshotAsync();
        this.applicationContext.openFeedbackDialog();
    }

    public render() {
        return (
            <Ui.NavBar.Item onClickAsync={this.openFeedbackDialogAsync}><Ui.Icon iconName="feedback" visualStyle="white" automationId="feedback" /></Ui.NavBar.Item>
        );
    }
}

export default connect(
    FeedbackMenu,
    new DependencyAdapter<IFeedbackMenuProps, IFeedbackMenuDependencies>(c => ({
        applicationContext: c.resolve("ApplicationContext"),
        screenshotService: c.resolve("IScreenshotService"),
    }))
);
