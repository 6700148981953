import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";

export default class AppointmentSubjectColumnData {
    public serviceRequestDefinitionId: IEntityVersionSelector<ServiceRequestDefinitionId> = null;
    public serviceRequestIdentifier: string = "";
    constructor(

        public readonly subject: SchedulingServiceId | IEntityVersionSelector<ServiceRequestId>,
        public readonly subjectType: "ServiceRequest" | "SchedulingService"
    ) {

    }
}
