import Di from "@Di";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import ShowPatientRelatedDocumentListScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientRelatedDocumentListScreenAction.g";
import { IDocumentListScreenProps } from "@HisPlatform/Packages/Patients/Screens/DocumentListScreen/DocumentListScreen";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import DocumentListScreenApiAdapter from "./DocumentListScreenApiAdapter";

@Di.injectable()
export default class DocumentListScreenStore
    extends EditorScreenPanelStoreBase<IDocumentListScreenProps> implements ILoadablePanelStore {

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("DocumentListScreenApiAdapter") public readonly apiAdapter: DocumentListScreenApiAdapter) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    @State.computed
    protected get contentToDirtyCheck(): any[] {
        return [];
    }

    @State.computed
    protected get showScreenAction(): ShowPatientRelatedDocumentListScreenAction {
        return this.props.action;
    }

    @State.computed
    public get showIsDeleted(): boolean {
        return this.showScreenAction.showIsDeleted;
    }

    public readonly setShowIsDeletedFilterAsync = this.async(async (filter: boolean) => {
        await this.props._actionDispatcher.dispatchAsync(new ShowPatientRelatedDocumentListScreenAction(ScreenDisplayMode.Full,
            this.showScreenAction.patientId,
            filter));

        await this.refreshListEvent.emitAsync();
    });

    @State.bound
    public async dynamicListAsync(args: FrontendListParameters) {
        if (this.showScreenAction instanceof ShowPatientRelatedDocumentListScreenAction) {
            const documentList = await this.apiAdapter.getPatientRelatedDocumentListAsync(this.showScreenAction.patientId, false, args);
            return documentList.result;
        }

        return null;
    }
    
    @State.bound
    public async dynamicListWithDeletedAsync(args: FrontendListParameters) {
        if (this.showScreenAction instanceof ShowPatientRelatedDocumentListScreenAction) {
            const documentList = await this.apiAdapter.getPatientRelatedDocumentListAsync(this.showScreenAction.patientId, true, args);
            return documentList.result;
        }

        return null;
    }

    public loadCoreAsync(): Promise<void | { loadedSignals?: string[]; }> {
        return Promise.resolve();
    }

    protected saveFunction: () => Promise<boolean> = () => Promise.resolve(true);

    public refreshListEvent = new TypedAsyncEvent();
}