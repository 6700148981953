import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import PermissionDefinitionBuilder from "@PluginInterface/OperationAccessControl/PermissionDefinitionBuilder";
import { PermissionDefinitions } from "@HisPlatform/PermissionDefinitions/PermissionDefinitions.g";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";

export default function configureOrganizationPermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry) {
    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(PermissionDefinitions.Organization.Structure.Edit).build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.Organization.External.Edit, PermissionDefinitions.Productivity.Worklist.ViewWorklists)
            .withPermissionScopesFor(
                PermissionDefinitions.Productivity.Worklist.ViewWorklists,
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType, ["ExternalLocationBased"])
            )
            .withAlias(null, PermissionScopeType.worklistType)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder
            .create(PermissionDefinitions.Organization.Practitioners.CreatePractitioner)
            .build());
}