import IStore from "./IStore";
import IOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/IOperationInfo";
import QueryOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/QueryOperationInfo";

export default class PagedItemStore<TItem = any> implements IStore {

    public isPersistedByOperationInfo = true;
    public operationWasSuccessful = true;

    constructor(
        public items: TItem[] = [],
        public totalCount: number = 0,
        public operationInfo: IOperationInfo = new QueryOperationInfo(),
        public extensionData?: any
    ) {}

    public static createEmpty(operationInfo: IOperationInfo = new QueryOperationInfo()) {
        return new PagedItemStore([], 0, operationInfo);
    }
}
