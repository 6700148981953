import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import AppointmentBaseData from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentBaseData";
import PractitionerId from "@Primitives/PractitionerId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import moment from "moment";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export function appointmentBaseDataDtoConverter(rawValue: any) {
    let subjectService: SchedulingServiceId | IEntityVersionSelector<ServiceRequestId>;
    let subjectType: "ServiceRequest" | "SchedulingService";
    if (rawValue.Subject.ServiceRequestId) {
        subjectService = new EntityVersionSelector<ServiceRequestId>(new ServiceRequestId(rawValue.Subject.ServiceRequestId.Value.toString()), LocalDate.today());
        subjectType = "ServiceRequest";
    } else  {
        subjectService = new SchedulingServiceId(rawValue.Subject.SchedulingServiceId.Value.toString());
        subjectType = "SchedulingService";
    }

    return new AppointmentBaseData(
        !isNullOrUndefined(rawValue.PractitionerId?.Value) ? new PractitionerId(rawValue.PractitionerId.Value.toString()) : null,
        !isNullOrUndefined(rawValue.PointOfCareId?.Value) ? new PointOfCareId(rawValue.PointOfCareId.Value.toString()) : null,
        subjectService,
        subjectType,
        moment(rawValue.Interval.From),
        moment(rawValue.Interval.To));
} 