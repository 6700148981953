import ValueWrapper from "./ValueWrapper";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export default abstract class StringEntityId extends ValueWrapper<string> {

    public abstract get typeName(): string;

    constructor(value: string) {
        super(value);

        if (isNullOrUndefined(value)) {
            throw new Error("StringEntityId.value cannot be null or undefined.");
        }
    }
}