import { ScreenContextDataBase } from "@HisPlatform/Services/Definition/ActionProcessing/ScreenContextData";
import React, { useContext, useMemo } from "react";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import { nullFunction, emptyObject } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";

export class ScreenStateContextStore {
    constructor(
        public readonly action: ShowScreenFrontendActionBase,
        public readonly screenState: string,
        public readonly displayMode: ScreenDisplayMode,
        public readonly screenContextData: ScreenContextDataBase | null,

        public readonly savedExisting: () => void,
        public readonly savedNew: (newId: StringEntityId, showCreatedEntityScreenAction?: ShowScreenFrontendActionBase) => void,
        public readonly cancelled: () => void,
        public readonly deleted: () => void
    ) { }
}

const ScreenStateReactContext = React.createContext<ScreenStateContextStore | null>(null);

export function useScreenStateContext() {
    return useContext(ScreenStateReactContext);
}

export function ScreenStateContextProvider(props: React.PropsWithChildren<{
    action: ShowScreenFrontendActionBase,
    screenState: string,
    displayMode: ScreenDisplayMode,
    screenContextData: ScreenContextDataBase | null,
    onSavedExisting?: () => void;
    onSavedNew?: (newId: StringEntityId, showCreatedEntityScreenAction?: ShowScreenFrontendActionBase) => void;
    onCancelled?: () => void;
    onDeleted?: () => void;
}>) {
    const store = useMemo(() => new ScreenStateContextStore(
        props.action,
        props.screenState,
        props.displayMode,
        props.screenContextData,
        props.onSavedExisting ?? nullFunction,
        props.onSavedNew ?? nullFunction,
        props.onCancelled ?? nullFunction,
        props.onDeleted ?? nullFunction
    ), [
        props.action,
        props.screenState,
        props.displayMode,
        props.screenContextData,
        props.onSavedExisting,
        props.onSavedNew,
        props.onCancelled,
        props.onDeleted
    ]);

    return (
        <ScreenStateReactContext.Provider value={store}>
            {props.children}
        </ScreenStateReactContext.Provider>
    );
}

export class ScreenStateContextAdapter<TProps = { _screenState: ScreenStateContextStore }> implements IComponentAdapter<TProps> {
    public get usedContexts(): Array<React.Context<any>> {
        return [ScreenStateReactContext];
    }

    constructor(
        private readonly mapToProps: (screenState: ScreenStateContextStore, props: TProps) => Partial<TProps> = (screenState) => ({ _screenState: screenState } as any)
    ) { }

    public getMappedProps(props: TProps, contextValues: Map<React.Context<any>, any>): Partial<TProps> {
        const context = contextValues.get(ScreenStateReactContext) as ScreenStateContextStore;
        if (!context) {
            return emptyObject;
        }
        return this.mapToProps(context, props);
    }
}