import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import { components } from "react-select";
import SelectBox, { inputChangeActionType } from "@CommonControls/SelectBox";
import { emptyArray, isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import TimeEditor from "@CommonControls/DateTimePicker/TimeEditor";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import moment from "moment";
import TextBox from "@CommonControls/TextBox";

interface ITimePickerDependencies {
    dateTimeFormatProvider: IDateTimeFormatProvider;
}

interface ITimePickerProps extends ISelectBoxBaseProps {
    _dependencies?: ITimePickerDependencies;

    value?: TimeOfDay;
    onChange?: (newValue?: TimeOfDay) => void;

    valueOnClear?: TimeOfDay | null;
}

@State.observer
class TimePicker extends React.Component<ITimePickerProps> {

    @State.observable.ref private menuIsOpen = false;
    @State.observable.ref private text: string = null;
    @State.observable.ref private preParsedTime: TimeOfDay | null = null;

    @State.computed private get displayedText() {
        if (this.text === null) {
            return this.props.value?.toMoment().format("LT");
        }

        return this.text;
    }

    @State.computed private get value() {
        if (this.preParsedTime === null) {
            return this.props.value;
        }
        return this.preParsedTime;
    }

    public render() {

        if (this.props.isReadOnly) {
            return (
                <TextBox
                    label={this.props.label}
                    id={this.props.id}
                    automationId={this.props.automationId}
                    disabled={this.props.disabled}
                    name={this.props.name}
                    tabIndex={this.props.tabIndex}
                    value={this.displayedText}
                    isReadOnly
                />
            );
        }

        return (
            <SelectBox
                {...this.props}
                value={this.props.value}
                items={emptyArray}
                onRenderMenu={this.renderMenu}
                menuIsOpen={this.menuIsOpen}
                onMenuOpen={this.openMenu}
                onMenuClose={this.closeMenu}
                onInputChange={this.setText}
                inputValue={this.displayedText}
                isOptionSelected={this.dummyOptionIsSelected}
            />
        );
    }

    private renderMenu = State.observer((menuProps: any) => {
        const props = {
            ...menuProps,
            innerProps: {
                ...menuProps.innerProps,
            }
        };
        if (this.props.visualMode === "dark") {
            props.className += " __dark-mode";
        }
        return (
            <components.Menu {...props}>
                <TimeEditor
                    variant="hour-minute"
                    value={this.value}
                    onChange={this.setValue}
                />
            </components.Menu>
        );
    });

    @State.bound
    private setValue(value: TimeOfDay) {
        this.text = null;
        this.preParsedTime = null;
        this.props.onChange?.(value);
    }

    @State.action.bound private openMenu() {
        this.menuIsOpen = true;
        this.props.onMenuOpen?.();
    }

    @State.action.bound private closeMenu() {
        this.menuIsOpen = false;
        this.props.onMenuClose?.();
    }

    @State.action.bound
    private setText(text: string, action: inputChangeActionType) {
        switch (action) {
            case "input-change":
                this.text = text;

                const { success: preParseSuccess, time: preParsedTime } = this.tryParseTime(text);
                if (preParseSuccess) {
                    this.preParsedTime = preParsedTime;
                } else {
                    this.preParsedTime = null;
                }
                break;
            case "input-blur":
                const { success, time } = this.tryParseTime(this.text);
                if (success && !TimeOfDay.areEquals(time, this.props.value)) {
                    this.props.onChange?.(time);
                }
                this.text = null;
                break;
        }
    }

    private tryParseTime(text: string): { success: boolean, time?: TimeOfDay } {
        if (!isNullOrEmptyString(text)) {
            const parsed = moment(text, this.props._dependencies!.dateTimeFormatProvider.getTimeFormatsToParse());
            if (parsed.isValid()) {
                const time = TimeOfDay.createFromMoment(parsed);
                return { success: true, time };
            }
        }
        return { success: false };
    }

    private dummyOptionIsSelected() {
        return true;
    }
}

export default connect(
    TimePicker,
    new DependencyAdapter<ITimePickerProps, ITimePickerDependencies>(c => ({
        dateTimeFormatProvider: c.resolve<IDateTimeFormatProvider>("IDateTimeFormatProvider"),
    })),
);