import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";

export default class FormEnumStore {
    constructor(
        public readonly name: string,
        public readonly values: IFormEnumValue[]
    ) {}
}

export interface IFormEnumValue {
    readonly value: number;
    readonly MultiLingualDisplayValue: MultiLingualLabel;
    readonly isDeleted: boolean;
}