import moment from "moment";
import CallScopeIdentifier from "@HisPlatform/Packages/Care/Model/CallScopeIdentifier";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import CallId from "@Primitives/CallId.g";
import CallState from "@HisPlatform/BoundedContexts/Telecommunication/Api/Telecommunication/Enum/CallState.g";

export default class Call {
    constructor(
        public readonly id: CallId,
        public readonly callScopeIdentifiers: CallScopeIdentifier[],
        public readonly callIdentifier: string,
        public readonly password: string,
        public readonly organizerIdentifier: string,
        public readonly joinUri: string,
        public readonly callState: CallState,
        public readonly organizerName: string,
        public readonly timeStart: moment.Moment,
        public readonly timeEnd: moment.Moment,
        public readonly rowVersion: RowVersion
    ) {}
}
