import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IPagingState, IOrderingState, DataGridLoadType } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import DataLoadError from "./DataLoadError";

export default abstract class DataGridDataSourceBase<TRecord = any, TFilterStore extends IFilterStore = any> {
    @State.observable.ref public paging: IPagingState = null;
    @State.observable.ref public ordering: IOrderingState | IOrderingState[] = null;
    @State.observable.ref public filterStore: TFilterStore = null;
    @State.observable.ref public data: TRecord[] | IPagedItems<TRecord> = null;

    constructor(
        public readonly onErrorAsync: (error: DataLoadError) => Promise<void> = null
    ) {
    }

    @State.bound
    public reloadAsync(): Promise<void> {
        return this.onChangeAsync("changed", this.paging, this.ordering, this.filterStore, []);
    }

    @State.bound
    public async onChangeAsync(
        type: DataGridLoadType,
        paging: IPagingState,
        ordering: IOrderingState | IOrderingState[],
        filterStore: TFilterStore,
        columns: IDataGridColumnProps[]
    ): Promise<void> {
        try {
            await this.onChangeCoreAsync(type, paging, ordering, filterStore, columns);
        } catch (err) {
            if (err instanceof DataLoadError) {
                await this.onErrorAsync?.(err);
                return;
            }

            throw err;
        }
    }

    @State.action.bound
    public setState(
        data: TRecord[] | IPagedItems<TRecord>,
        paging?: IPagingState,
        ordering?: IOrderingState | IOrderingState[],
        filterStore?: TFilterStore
    ) {
        this.data = data;

        if (paging !== undefined) {
            this.paging = paging;
        }

        if (ordering !== undefined) {
            this.ordering = ordering;
        }

        if (filterStore !== undefined) {
            this.filterStore = filterStore;
        }
    }

    protected abstract onChangeCoreAsync(
        type: DataGridLoadType,
        paging: IPagingState,
        ordering: IOrderingState | IOrderingState[],
        filterStore: TFilterStore,
        columns: IDataGridColumnProps[]
    ): Promise<void>;
}