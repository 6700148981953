import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import standaloneRouteDefinitions from "./PermanentPrescriptionRoutes";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import PermanentPrescriptionsStandaloneHost from "./PermanentPrescriptionsStandaloneHost";

export default UseCaseDescriptorBuilder.create(
    HunEHealthInfrastructureUseCases.permanentPrescriptions,
    builder => {
        builder.standalone(b => b
            .routeDefinition(standaloneRouteDefinitions.permanentPrescriptions)
            .component(PermanentPrescriptionsStandaloneHost)
        );
    }, false
);