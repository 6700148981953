import State from "@Toolkit/ReactClient/Common/StateManaging";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import React from "react";
import {
    IDataGridColumnBaseProps,
    IDataGridFilterValueSerializer
} from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PatientPhoneNumber from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/PatientPhoneNumber";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import AppointmentInvalidationReason from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentInvalidationReason.g";

interface IPatientPhoneNumbersColumnDependencies {
    commonReferenceDataStore: CommonReferenceDataDataStore;
}

interface IPatientPhoneNumbersColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IPatientPhoneNumbersColumnDependencies;
}
@State.observer
class PatientPhoneNumbersColumn extends React.Component<IPatientPhoneNumbersColumnProps> {
    private filterValueSerializer: IDataGridFilterValueSerializer<AppointmentInvalidationReason | AppointmentInvalidationReason[]> = {
        serialize: value => JSON.stringify(value),
        deserialize: value => JSON.parse(value)
    };

    @State.bound
    private renderValue(value: PatientPhoneNumber[]) {
        return value.filter(item => !isNullOrUndefined(item)).map((item: PatientPhoneNumber, index: number) => {
            const telecomUse = !!item.useId ? this.props._dependencies.commonReferenceDataStore.telecomUse.get(item.useId) : null;
            return (<span key={index}>{item.phoneNumber} {telecomUse && `(${telecomUse?.displayValue.Name})`}<br /></span>);
        });
    }

    public render() {
        const {children, ...columnProps} = this.props;
        return (
            <DataGridColumn
                {...columnProps}
                // onRenderFilter={this.filterRenderer}
                filterValueSerializer={this.filterValueSerializer}
                onRenderCellValue={this.renderValue}
            />
        );
    }
}

export default connect(
    PatientPhoneNumbersColumn,
    new DependencyAdapter<IPatientPhoneNumbersColumnProps, IPatientPhoneNumbersColumnDependencies>(c => {
        return {
            commonReferenceDataStore: c.resolve("CommonReferenceDataDataStore")
        };
    })
);
