import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import AuthorizationReferenceDataStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/AuthorizationReferenceDataStore";
import UserId from "@Primitives/UserId.g";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PointOfCareRoles from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Actions/PointOfCareRoles";
import PointOfCareRoleApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Actions/PointOfCareRoleApiAdapter";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import PointOfCareRoleAssignmentRule from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Actions/PointOfCareRoleAssignmentRule";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import CopyPointOFCareRolesModalView from "./CopyPointOfCareRolesModalView";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { ICopyPointOfCareRolesModalParams } from "./CopyPointOfCareRolesModalParams";
import _ from "@HisPlatform/Common/Lodash";

interface ICopyPointOfCareRolesModalDependencies {
    pointOfCareRoleApiAdapter: PointOfCareRoleApiAdapter;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    authorizationReferenceDataStore: AuthorizationReferenceDataStore;
}

interface ICopyPointOfCareRolesModalProps extends IModalComponentParams<void>, ICopyPointOfCareRolesModalParams {
    _dependencies?: ICopyPointOfCareRolesModalDependencies;

}

/** @screen */
@State.observer
class CopyPointOfCareRolesModal extends React.Component<ICopyPointOfCareRolesModalProps> {
    @State.observable.ref private pointOfCareRoles: PointOfCareRoles[] = null;
    @State.observable.ref private selectedUserId: UserId = null;

    private async loadAsync() {
        const newItems = await this.props._dependencies.pointOfCareRoleApiAdapter.getRoleAssignmentRuleAsync(this.selectedUserId);
        let professionIds =  _.flatten(newItems.value.map(item => item.pointOfCareIds));
        professionIds = _.uniqBy(professionIds, item => item.value);
        await this.props._dependencies.organizationReferenceDataStore.healthCareProfession.ensureLoadedAsync(professionIds);
        this.setPointOfCareRoles(newItems);
    }

    @State.action.bound
    private setPointOfCareRoles(items: SimpleStore<PointOfCareRoleAssignmentRule[]>) {
        this.pointOfCareRoles = PointOfCareRoles.mapToPointOfCareRoles(items.value);
    }

    @State.action.bound
    private setUserId(id: UserId) {
        this.selectedUserId = id;
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.action.bound
    private closeModal() {
        this.selectedUserId = null;
        this.pointOfCareRoles = [];
        this.props.onClose(null);
    }

    @State.bound
    private save() {
        this.props.copyPointOfCareRoles(this.pointOfCareRoles);
        this.closeModal();
        this.props.onClose(null);
    }

    public render() {
        return (
            <CopyPointOFCareRolesModalView
                closeModal={this.closeModal}
                setUserId={this.setUserId}
                selectedUserId={this.selectedUserId}
                user={this.props.user}
                save={this.save}
                pointOfCareMap={this.props._dependencies.organizationReferenceDataStore.pointOfCareMap}
                pointOfCareRoles={this.pointOfCareRoles}
                practitioner={this.props.practitioner}
            />
        );
    }

    @State.bound
    private getPointOfCareName(value: PointOfCareId) {
        const pointOfCare = this.props._dependencies.organizationReferenceDataStore.pointOfCareMap.get(value);
        return `${pointOfCare?.shorthand}${pointOfCare?.name}`;
    }
}

export default connect(
    CopyPointOfCareRolesModal,
    new DependencyAdapter<ICopyPointOfCareRolesModalProps, ICopyPointOfCareRolesModalDependencies>((container) => {
        return {
            pointOfCareRoleApiAdapter: container.resolve("PointOfCareRoleApiAdapter"),
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            authorizationReferenceDataStore: container.resolve("AuthorizationReferenceDataStore")
        };
    })
);
