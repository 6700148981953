import EhiOrganizationUnitProviderService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Organization/Services/Implementation/EhiOrganizationUnitProviderService";
import EhiServiceBase from "@HunEHealthInfrastructurePlugin/Services/EhiServiceBase";
import EhiTokenProvider from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Services/EhiTokenProvider/EhiTokenProvider";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityId from "@Primitives/CareActivityId.g";
import EhiUserType from "@HunEHealthInfrastructurePlugin/Model/EhiUserType";
import PatientId from "@Primitives/PatientId.g";
import PrescriptionItemType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/PrescriptionItemType.g";

export default class EhiPrescriptionService extends EhiServiceBase {

    constructor(
        private readonly prescriptionApiAdapter: PrescriptionApiAdapter,
        ehiTokenProvider: EhiTokenProvider,
        ehiOrganizationUnitProviderService: EhiOrganizationUnitProviderService,
        userContext: UserContext,
        modalService: IModalService
    ) {
        super(ehiTokenProvider,
            ehiOrganizationUnitProviderService,
            userContext,
            modalService);
    }

    @State.bound
    public async getExternalPrescriptionDataAsync(
        patientId: PatientId,
        identifier: string,
        careActivityId: CareActivityId,
        isEmergency: boolean,
        emergencyReason: string,
        itemType: PrescriptionItemType,
        extensionData: any) {
            return await this.performEhiOperationAsync(patientId, EhiUserType.Doctor, async () => {
                return await this.prescriptionApiAdapter.getExternalPrescriptionDataAsync(
                    identifier,
                    careActivityId,
                    isEmergency,
                    emergencyReason,
                    itemType,
                    extensionData
                );
            });
    }
}
