import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import HunSocialSecurityUseCases from "@HunSocialSecurityPlugin/UseCases/HunSocialSecurityUseCases";
import EuReportPanel from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/EuReportPanel/EuReportPanel";

interface ICreateEuPerformanceStatementPageDependencies {
    applicationContext: ApplicationContext;
}

interface ICreateEuPerformanceStatementPageProps extends IRoutingFrameContentProps {
    _dependencies?: ICreateEuPerformanceStatementPageDependencies;
}

@State.observer
class CreateEuPerformanceStatementPage extends React.Component<ICreateEuPerformanceStatementPageProps> {

    public componentWillMount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(new UseCaseIdentifier(HunSocialSecurityUseCases.newEuPerformanceStatement));
    }

    public componentWillUnmount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(null);
    }

    public render() {
        return (
            <div>
                <EuReportPanel />
            </div>
        );
    }
}

export default connect(
    withHisErrorBoundary(CreateEuPerformanceStatementPage),
    new DependencyAdapter<ICreateEuPerformanceStatementPageProps, ICreateEuPerformanceStatementPageDependencies>(container => {
        return {
            applicationContext: container.resolve("ApplicationContext")
        };
    })
);
