import Di from "@Di";
import { IFormEditingClient, GetNewFormFieldNameControllerDto } from "@HisPlatform/BoundedContexts/FormEngine/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IFormFieldNameFactory from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/IFormFieldNameFactory";

@Di.injectable()
export default class FormFieldNameFactory extends ApiAdapterBase implements IFormFieldNameFactory {
    private _highValue: string;
    private _index: number = 0;

    constructor(
        @Di.inject("IFormEditingClient") private readonly apiClient: IFormEditingClient
    ) {
        super();
    }

    public async initializeAsync(): Promise<void> {
        const result = await this.processOperationAsync(
            new SimpleStore<string>(),
            async target => {
                const response = await this.apiClient.getNewFormFieldNameCommandAsync(CreateRequestId(), new GetNewFormFieldNameControllerDto());

                target.operationInfo = createOperationInfo(response);
                target.value = response.newFormFieldName;
            }
        );

        this._highValue = result.value;
    }

    public getNew(): string {
        const newValue = this._highValue + this._index;
        this._index++;

        return newValue;
    }
}