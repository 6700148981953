import React from "react";
import Icon, { iconNameType } from "@CommonControls/Icon";
import Styles from "./SideMenu.less";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import { ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { SmallValidationBadge } from "@CommonControls";
import IValidationState from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationState";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PermissionCheckContextAdapter from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextAdapter";
import { DashboardMode } from "@CommonControls/Dashboard/DashboardMode";
import { DashboardContextAdapter } from "@CommonControls/Dashboard/DashboardContext";

interface ISideMenuItemProps extends ICommonControlProps {
    _permissionDenied?: boolean;
    _dashboardMode?: DashboardMode;

    text?: string;
    iconName?: iconNameType;
    isActive?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    automationId?: string;
    propertyPathRegexPattern?: string;
    validationState?: IValidationState;

    isOpenByDefault?: boolean;
    children?: React.ReactNode;

    permissionCheckOperationNames?: string;
    permissionDeniedStyle?: "disabled" | "invisible";
}

@State.observer
class SideMenuItem extends React.Component<ISideMenuItemProps> {

    @State.observable private isOpen: boolean = true;

    constructor(props: ISideMenuItemProps) {
        super(props);
        this.props.isOpenByDefault ? this.open() : this.close();
        if (this.props.onClick && this.props.children) {
            console.warn("Using both onClick and children on SideMenuItem, onClick won't be triggered.");
        }
    }

    public static defaultProps: Partial<ISideMenuItemProps> = {
        isOpenByDefault: true
    };

    @State.action.bound
    private open() {
        this.isOpen = true;
    }

    @State.action.bound
    private close() {
        this.isOpen = false;
    }

    @State.action.bound
    private openOrClose() {
        this.isOpen = !this.isOpen;
    }

    @State.action.bound
    private getClickHandler() {
        if (this.props.disabled || this.props._permissionDenied) {
            return undefined;
        } else {
            return !!this.props.children ? this.openOrClose : this.props.onClick || undefined;
        }
    }

    private getContainerClassName(props: ISideMenuItemProps) {
        const disabled = props.disabled || (props._permissionDenied && props.permissionDeniedStyle === "disabled");
        const classes = new CompositeClassName(props.className);
        classes.addIf(!disabled, Styles.itemContainer);
        classes.addIf(disabled, Styles.itemContainerDisabled);
        classes.addIf(props.isActive && !disabled, Styles.itemContainerActive);
        classes.addIf(this.isSmallDashboardMode() && !this.props.children, Styles.smallSidebar);
        return classes.classNames;
    }

    private getChildrenContainerClassName(isOpen: boolean) {
        const classes = new CompositeClassName(Styles.itemChildContainer);
        classes.addIf(isOpen === false, Styles.itemChildContainerClosed);
        return classes.classNames;
    }

    private getOpenIconClassName(isOpen: boolean) {
        const classes = new CompositeClassName(Styles.openIcon, Styles.icon);
        classes.addIf(isOpen === false, Styles.openIconClosed);
        return classes.classNames;
    }

    public render() {
        const props = this.props;
        const hasChildren = !!this.props.children;
        const clickHandler = this.getClickHandler();
        return (!!props._permissionDenied && props.permissionDeniedStyle === "invisible")
            ? <></>
            : (
                <>
                    <div
                        className={this.getContainerClassName(props)}
                        onClick={clickHandler}
                        data-automation-id={props.automationId || undefined}
                        title={props.text}
                    >
                        <div style={{ flex: "1" }}>
                            {props.iconName && (
                                <Icon
                                    iconName={props.iconName}
                                    visualStyle="white"
                                    size="compact"
                                    className={Styles.mainIcon} />
                            )}
                            {!this.isSmallDashboardMode() && (
                                <label>{props.text}</label>
                            )}
                        </div>
                        <div>
                            {props.propertyPathRegexPattern && (
                                <SmallValidationBadge
                                    hideIfNoErrors
                                    propertyPathRegexPattern={props.propertyPathRegexPattern}
                                    className={Styles.validationBadge} />
                            )}
                            {props.validationState && (
                                <SmallValidationBadge
                                    hideIfNoErrors
                                    severity={props.validationState.severity}
                                    numberOfProblems={props.validationState.numberOfProblems}
                                    className={Styles.validationBadge} />
                            )}
                            {hasChildren && (
                                <Icon
                                    iconName="chevronDown"
                                    className={this.getOpenIconClassName(this.isOpen)}
                                    visualStyle="white"
                                    size="compact" />
                            )}
                        </div>
                    </div>
                    {hasChildren && (
                        <div className={this.getChildrenContainerClassName(this.isOpen)}>
                            {this.props.children}
                        </div>
                    )}
                </>
            );
    }

    private isSmallDashboardMode() {
        return this.props._dashboardMode === "small";
    }
}

export default connect(
    SideMenuItem,
    new PermissionCheckContextAdapter(),
    new DashboardContextAdapter<ISideMenuItemProps>(d => ({
        _dashboardMode: d.mode
    })),
);