import DependencyContainer from "@DiContainer";
import DynamicPropertiesApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/DynamicProperties/DynamicPropertiesApiAdapter";
import ExternalApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/External/ExternalApiAdapter";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import OrganizationExtensibleEnumApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/ReferenceData/OrganizationExtensibleEnumApiAdapter";
import PropertyGroupStoreMapper from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/DynamicProperties/PropertyGroupStoreMapper";
import PractitionerStoreMapper from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerStoreMapper";
import OrganizationUnitStoreMapper from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/OrganizationUnitStoreMapper";
import OrganizationUnitsStoreMapper from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/OrganizationUnitsStoreMapper";
import OrganizationReferenceDataApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/ReferenceData/OrganizationReferenceDataApiAdapter";
import ProfessionalExamsStoreMapper from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/ReferenceData/ProfessionalExamsStoreMapper";
import HealthcareProfessionStoreMapper from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/HealthcareProfessionStoreMapper";
import HealthcareProfessionsStoreMapper from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/HealthcareProfessionsStoreMapper";
import EditableExternalLocationStoreMapper from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/External/EditableExternalLocationStoreMapper";

DependencyContainer
    .bind("DynamicPropertiesApiAdapter")
    .to(DynamicPropertiesApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("ExternalApiAdapter")
    .to(ExternalApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PractitionerApiAdapter")
    .to(PractitionerApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("StructureApiAdapter")
    .to(StructureApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("OrganizationExtensibleEnumApiAdapter")
    .to(OrganizationExtensibleEnumApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PropertyGroupStoreMapper")
    .to(PropertyGroupStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("PractitionerStoreMapper")
    .to(PractitionerStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("OrganizationUnitStoreMapper")
    .to(OrganizationUnitStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("OrganizationUnitsStoreMapper")
    .to(OrganizationUnitsStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("HealthcareProfessionStoreMapper")
    .to(HealthcareProfessionStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("HealthcareProfessionsStoreMapper")
    .to(HealthcareProfessionsStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("OrganizationReferenceDataApiAdapter")
    .to(OrganizationReferenceDataApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("ProfessionalExamsStoreMapper")
    .to(ProfessionalExamsStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("EditableExternalLocationStoreMapper")
    .to(EditableExternalLocationStoreMapper)
    .inSingletonScope();