import UserId from "@Primitives/UserId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import User from "./User";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class UserWithIdentifiers extends EntityStoreBase<UserId> {
    @State.observable.ref public user: User;
    @State.observable public iconName: string;
    @State.observable public identifiers: string[];

    constructor(id: UserId, user: User, iconName: string, identifiers: string[]) {
        super();
        this.id = id;
        this.user = user;
        this.iconName = iconName;
        this.identifiers = identifiers;
    }

    @State.computed get displayName() {
        return this.user && this.user.displayName;
    }

    @State.computed get loginName() {
        return this.user && this.user.loginName;
    }

    @State.computed get identifiersWithLoginNameString() {
        return [this.loginName, this.identifiersString].join(", ");
    }

    @State.computed get identifiersString() {
        return this.identifiers.join(", ");
    }
}