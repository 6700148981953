import Di from "@Di";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import SurgeryApiAdapter from "@HisPlatform/BoundedContexts/Surgery/ApplicationLogic/ApiAdapter/SurgeryApiAdapter";
import SurgeryTypeDefinitionId from "@Primitives/SurgeryTypeDefinitionId.g";
import { ISurgeryPanelProps } from "./SurgeryPanel";
import NewSurgerytDialogParams, { INewSurgerytDialogResult } from "@HisPlatform/BoundedContexts/Surgery/Components/Dialogs/NewSurgeryDialog/NewSurgeryDialogParams";
import SurgeryId from "@Primitives/SurgeryId.g";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";

@Di.injectable()
export default class SurgeryPanelStore
    extends PanelStoreBase<ISurgeryPanelProps> implements ILoadablePanelStore {

    @State.observable.ref public form: IForm = null;
    @State.observable.ref public newSurgeryTypeDefinitionId: SurgeryTypeDefinitionId = null;

    constructor(
        @Di.inject("SurgeryApiAdapter") private readonly apiAdapter: SurgeryApiAdapter
    ) {
        super();
    }

    public getReloadTriggerProps() {
        return [this.props.selectedId?.value];
    }

    public readonly loadAsync = this.namedAsync("loadingSurgery", async () => {
        if (SurgeryId.isNew(this.props.selectedId)) {
            if (this.newSurgeryTypeDefinitionId === null) {
                const dialogResult = await this.modalService.showDialogAsync(new NewSurgerytDialogParams()) as INewSurgerytDialogResult;
                if (dialogResult === null) {
                    this.props.onClose();
                    return;
                }

                const result = await this.apiAdapter.getNewSurgeryAsync(dialogResult.selectedId, this.props._patientId, dialogResult.isUrgent);

                State.runInAction(() => {
                    this.newSurgeryTypeDefinitionId = dialogResult.selectedId;
                    this.form = result.value;
                });
            }
        } else {
            const result = await this.apiAdapter.getSurgeryAsync(this.props.selectedId);
            State.runInAction(() => {
                this.form = result.value;
            });
        }
    });

    public readonly saveAsync = this.async(async () => {
        if (SurgeryId.isNew(this.props.selectedId)) {
            const result = await this.apiAdapter.createSurgeryAsync(this.form, this.newSurgeryTypeDefinitionId, true, false);

            // if (!saveResult.isPersisted) {
            //     if (saveResult.validationResults) {
            //         this.setValidationResult(saveResult.validationResults);
            //     }
            //     return false;
            // }

            await this.closeOnPersistedAsync(result.isPersistedByOperationInfo);
        } else {
            const result = await this.apiAdapter.updateSurgeryAsync(this.props.selectedId, this.form, false, false);

            await this.closeOnPersistedAsync(result.isPersistedByOperationInfo);
        }
    });

    private async closeOnPersistedAsync(isPersisted: boolean) {
        if (isPersisted) {
            await this.props.onSaveAsync();
            this.props.onClose();
        }
    }
}