import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import ImmunizationStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ImmunizationStore";
import ImmunizationId from "@Primitives/ImmunizationId.g";
import ReferencedVaccineAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ReferencedVaccineAssignment";
import CustomVaccineAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/CustomVaccineAssignment";
import ImmunizationHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ImmunizationHistoryItem";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import CustomTargetDiseaseAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/CustomTargetDiseaseAssignment";
import VaccineDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/VaccineDetail";

type ResponseType = Proxy.GetImmunizationByIdCommandResponse | Proxy.AddImmunizationCommandResponse | Proxy.UpdateImmunizationCommandResponse | Proxy.DeleteImmunizationHistoryItemCommandResponse;

@Di.injectable()
export default class ImmunizationStoreMapper extends LockingEntityStoreMapper<Proxy.ImmunizationDto, ImmunizationStore, ResponseType> {

    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    protected storeEntityIdType = ImmunizationId;

    protected entityDtoSelector = (response: ResponseType) => {
        return response.immunizationDto;
    };

    protected applyToStoreCore(target: ImmunizationStore, response: any) {
        const dto = this.entityDtoSelector(response);
        if (dto != null) {
            target.id = dto.id;
            target.vaccineAssignment = this.getVaccineAssignment(dto);

            target.historyItems = State.observable(this.getHistoryItems(dto.historyItems));
            target.currentItemIndex = target.historyItems.length - 1;
            target.patientId = dto.patientId;
            target.extensionData = dto.extensionData;
        }
    }

    private getHistoryItems(dtoArray: Proxy.ImmunizationHistoryItemDto[]): ImmunizationHistoryItem[] {
        return dtoArray.map(dto => this.getHistoryItem(dto)).sort((a, b) => a.versionNumber - b.versionNumber);
    }

    private getHistoryItem(dto: Proxy.ImmunizationHistoryItemDto): ImmunizationHistoryItem {
        const returnItem = new ImmunizationHistoryItem();

        returnItem.immunizationDate = dto.immunizationDate;
        returnItem.historyItemDetail = this.getHistoryItemDetail(dto.historyItemDetail);
        returnItem.targetDiseaseAssignment = this.getTargetDiseaseAssignment(dto);
        returnItem.vaccineDetail = this.getVaccineDetail(dto);
        returnItem.versionNumber = dto.versionNumber;
        returnItem.index = dto.index;
        returnItem.createdAt = dto.createdAt;
        returnItem.extensionData = dto.extensionData;

        return returnItem;
    }

    private getHistoryItemDetail(dto: Proxy.MedicalConditionHistoryItemDetailDto): MedicalConditionHistoryItemDetail {
        const returnItem = new MedicalConditionHistoryItemDetail();

        returnItem.asserter = dto.asserter;
        returnItem.asserterType = dto.asserterType;
        returnItem.notes = dto.notes;
        returnItem.verifier = dto.verifier;

        return returnItem;
    }

    private getVaccineAssignment(dto: Proxy.ImmunizationDto) {
        let vaccineAssignment;

        if (dto.vaccineAssignment instanceof Proxy.ReferencedVaccineAssignmentDto) {
            vaccineAssignment = new ReferencedVaccineAssignment(dto.vaccineAssignment.vaccineId);
        } else if (dto.vaccineAssignment instanceof Proxy.CustomVaccineAssignmentDto) {
            vaccineAssignment = new CustomVaccineAssignment(dto.vaccineAssignment.name);
        }

        return vaccineAssignment;
    }

    private getTargetDiseaseAssignment(dto: Proxy.ImmunizationHistoryItemDto) {
        let targetDiseaseAssignment;

        if (dto.targetDiseaseAssignment instanceof Proxy.CustomTargetDiseaseAssignmentDto) {
            targetDiseaseAssignment = new CustomTargetDiseaseAssignment(dto.targetDiseaseAssignment.name);
        }

        return targetDiseaseAssignment;
    }

    private getVaccineDetail(dto: Proxy.ImmunizationHistoryItemDto) {
        return new VaccineDetail(dto.vaccineDetail.brand, dto.vaccineDetail.serialNumber);
    }
}
