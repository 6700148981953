import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import * as Proxy from "@HisPlatform/BoundedContexts/Scheduling/Api/Proxy.g";
import SlotStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SlotStore";
import { mapToNameDto } from "@HisPlatform/Common/PersonNameMapper";
import UnregisteredPatientStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/UnregisteredPatientStore";
import ReferralStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/ReferralStore";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import ServiceRequestSubject from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ServiceRequestSubject";
import SchedulingServiceSubject from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubject";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import AppointmentCancellationReasonId from "@Primitives/AppointmentCancellationReasonId.g";
import PatientId from "@Primitives/PatientId.g";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import AppointmentFilterStore from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/DoctorPointOfCareListPanel/AppointmentFilterStore";
import SchedulePlan from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulePlan";
import ScheduledAppointmentStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ScheduledAppointmentStore";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ComplexSchedulePlan from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ComplexSchedulePlan";
import { TelecomDto } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import TelecomTypeId from "@Primitives/TelecomTypeId.g";
import TelecomUseId from "@Primitives/TelecomUseId.g";

export function GetSlotsDto(filterStore: AppointmentFilterStore, dateRange: LocalDateRange, subject: SchedulingServiceId | ServiceRequestDefinitionId) {
    return new Proxy.GetAppointmentScheduleSlotsControllerDto({
        interval: new Proxy.DateIntervalDto({
            // should come from schedule
            from: dateRange.from, to: dateRange.to
        }),
        filter: GetSlotFilters(filterStore, subject)
    });
}

function GetSlotFilters(filterStore: AppointmentFilterStore, subject: SchedulingServiceId | ServiceRequestDefinitionId) {
    return new Proxy.SlotFilterDto({
        serviceRequestDefinitionId: subject instanceof ServiceRequestDefinitionId && subject,
        schedulingServiceId: subject instanceof SchedulingServiceId && subject,
        organizationUnitIds: filterStore.pointOfCareIds,
        practitionerIds: filterStore.doctorIds
    });
}

export function GetBookSlotDto(appointment: Appointment, slotsToBook: SlotStore[]) {
    return new Proxy.BookSlotsControllerDto({
        subject: getSubject(appointment),
        slotsToBook: slotsToBook.map(s =>
            new Proxy.AppointmentScheduleSlotBookingItemDto({
                id: s.id,
                rowVersion: s.rowVersion
            }))
    });
}

export function GetCancelSlotDto(appointment: Appointment, slots: SlotStore[]) {
    return new Proxy.CancelSlotBookingsControllerDto({
        subject: getSubject(appointment),
        slotsToCancel: slots.map(s =>
            new Proxy.AppointmentScheduleSlotBookingItemDto({
                id: s.id,
                rowVersion: s.rowVersion
            }))
    });
}

function GetSlotsToBookDto(usedSlotIds: SlotStore[]) {
    return usedSlotIds
        .map(s => new Proxy.AppointmentScheduleSlotBookingItemDto({
            id: s.id,
            rowVersion: s.rowVersion
        }));
}

export function CreateAppointmentDto(appointment: Appointment, isValidateOnly: boolean) {
    return new Proxy.CreateAppointmentControllerDto({
        appointment: new Proxy.AppointmentCreationDto({
            appointmentScheduleSlotSeriesId: appointment.appointmentScheduleSlotSeriesId,
            subject: getSubject(appointment),
            slotsToBook: GetSlotsToBookDto(appointment.slotsToBook),
            participants: GetAppointmentParticipantDto(appointment),
            referral: mapReferral(appointment.referral, null),
            description: appointment.description,
            episodeOfCareIds: appointment.episodeOfCareIds,
            additionalParticipantAddresses: appointment.additionalParticipants
        }),
        isValidateOnly: isValidateOnly
    });
}

export function AppointmentCreationDto(appointment: Appointment) {
    return new Proxy.AppointmentCreationDto({
        appointmentScheduleSlotSeriesId: appointment.appointmentScheduleSlotSeriesId,
        subject: getSubject(appointment),
        slotsToBook: GetSlotsToBookDto(appointment.slotsToBook),
        participants: GetAppointmentParticipantDto(appointment),
        referral: mapReferral(appointment.referral, null),
        description: appointment.description,
        episodeOfCareIds: appointment.episodeOfCareIds,
        additionalParticipantAddresses: appointment.additionalParticipants
    });
}

export function UpdateAppointmentDto(appointment: Appointment) {
    return new Proxy.UpdateAppointmentControllerDto({
        appointmentScheduleEntryId: appointment.id ?? new AppointmentScheduleEntryId("-1"),
        appointment: new Proxy.AppointmentCreationDto({
            subject: getSubject(appointment),
            slotsToBook: GetSlotsToBookDto(appointment.slotsToBook),
            participants: GetAppointmentParticipantDto(appointment),
            referral: mapReferral(appointment.referral, null),
            appointmentScheduleSlotSeriesId: appointment.appointmentScheduleSlotSeriesId,
            description: appointment.description,
            episodeOfCareIds: appointment.episodeOfCareIds,
            additionalParticipantAddresses: appointment.additionalParticipants
        }),
        rowVersion: appointment.rowVersion ?? new RowVersion(-1)
    });
}

export function CancelAppointmentDto(
    cancellationReasonId: AppointmentCancellationReasonId,
    appointmentId: AppointmentScheduleEntryId,
    note: string,
    validateOnly: boolean) {
    return new Proxy.CancelAppointmentControllerDto({
        appointmentCancellationReasonId: cancellationReasonId,
        appointmentScheduleEntryId: appointmentId,
        note: note,
        isValidateOnly: validateOnly
    });
}

function getSubject(appointment: Appointment) {
    let subject: Proxy.AppointmentSubjectBaseDto;
    if (appointment.subjectService instanceof ServiceRequestSubject) {
        subject = new Proxy.ServiceRequestSubjectDto({ serviceRequestId: appointment.subjectService.versionedId.id });
    } else if (appointment.subjectService instanceof SchedulingServiceSubject) {
        subject = new Proxy.SchedulingServiceSubjectDto({ schedulingServiceId: appointment.subjectService.schedulingServiceId });
    }
    return subject;
}

function GetAppointmentParticipantDto(appointment: Appointment) {
    const result: Proxy.AppointmentParticipantDto[] = [];

    if (appointment.pointOfCareId && appointment.pointOfCareId.value) {
        result.push(new Proxy.LocationParticipantDto({
            organizationUnitId: appointment.pointOfCareId,
            isExternal: false
        }));
    }

    if (!!appointment?.practitionerIds?.length) {
        const dtos = appointment.practitionerIds
            .filter(p => !!p.value)
            .map(i => new Proxy.PractitionerParticipantDto({ practitionerId: i, isExternal: false }));
        result.push(...dtos);
    }

    if (!!appointment?.additionalPractitionerIds?.length) {
        const dtos = appointment.additionalPractitionerIds
            .filter(p => !!p.value)
            .map(i => new Proxy.PractitionerParticipantDto({ practitionerId: i, isExternal: true }));
        result.push(...dtos);
    }

    if (appointment.patientId?.value) {
        let patientParticipant: Proxy.AppointmentParticipantDto;
        if (appointment.patientId.value === "unregistered") {
            patientParticipant = GetUnregisteredPatientParticipantDto(appointment.unregisteredPatient);
        } else {
            patientParticipant = new Proxy.RegisteredPatientParticipantDto({

                patientId: appointment.patientId,
                patientName: null,
                isExternal: false
            });
        }

        result.push(patientParticipant);
    }
    return result;
}

function GetUnregisteredPatientParticipantDto(unregisteredPatientStore: UnregisteredPatientStore) {
    const telecoms = new Array<TelecomDto>();

    if (!isNullOrEmptyString(unregisteredPatientStore?.phoneNumber)){
        telecoms.push(getUnregisteredPatientTelecom(unregisteredPatientStore.phoneNumber, TelecomTypeId.Phone));
    }

    if (!isNullOrEmptyString(unregisteredPatientStore?.email)){
        telecoms.push(getUnregisteredPatientTelecom(unregisteredPatientStore.email, TelecomTypeId.Email));
    }

    return new Proxy.UnregisteredPatientParticipantDto({

        name: mapToNameDto(Proxy.PersonName, unregisteredPatientStore?.nameStore),
        dateOfBirth: unregisteredPatientStore?.birthDate,
        telecoms: telecoms,
        gender: unregisteredPatientStore?.genderId,
        insuranceDocumentNumber: unregisteredPatientStore?.insuranceDocumentNumber,
        mailingAddress: unregisteredPatientStore?.address,
        note: unregisteredPatientStore?.note,
        isExternal: false
    });
}

function getUnregisteredPatientTelecom(value: string, typeId: TelecomTypeId) {
    return new Proxy.TelecomDto({
        telecomTypeId: typeId,
        telecomUseId: new TelecomUseId("Private"),
        value: value,
        isPrimary: false
    });
}

export function createEntityVersionSelectorOfExternalLocationId(referral: EntityVersionSelector<ExternalLocationId>, referralCreationDate: LocalDate) {
    let validOn: LocalDate;

    if (!referral?.id) {
        return null;
    }

    if (isNullOrUndefined(referral) || referral.validOn?.isEmpty() === true) {
        validOn = referralCreationDate ?? LocalDate.today();
    } else {
        validOn = referral.validOn;
    }

    const ret = new Proxy.EntityVersionSelectorOfExternalLocationId({
        entityId: referral?.id,
        validOn
    });

    return ret;
}

function mapReferral(store: ReferralStore, referralExtensionData: any) {
    return new Proxy.ReferralDto({
        referralCareIdentifier: store.referralCareIdentifier,
        referralCreationDate: store.referralCreationDate,
        referralDoctorId: store.referralDoctorId,
        referralWorkplace: createEntityVersionSelectorOfExternalLocationId(store.referralWorkplace, store.referralCreationDate),
        referralDiagnosisId1: store.referralDiagnosisId1,
        referralDiagnosisLateralityId1: store.referralDiagnosisLateralityId1,
        referralDiagnosisId2: store.referralDiagnosisId2,
        referralDiagnosisLateralityId2: store.referralDiagnosisLateralityId2,
        extensionData: referralExtensionData
    });
}

export function ScheduleAppointmentsDto(schedulePlan: SchedulePlan, isValidateOnly: boolean) {
    return new Proxy.ScheduleAppointmentsControllerDto({
        from: schedulePlan.from,
        to: schedulePlan.to,
        schedulingServiceFilters: schedulePlan.schedulingServiceFilters.map(i => new Proxy.SchedulingServiceFilterDto({
            schedulingServiceId: i.schedulingServiceId,
            organizationUnitId: i.pointOfCareId,
            organizationUnitSchedulingServiceFilterType: i.pointOfCareFilterType,
            practitionerId: i.practitionerId,
            practitionerSchedulingServiceFilterType: i.practitionerFilterType
        })),
        isValidateOnly: isValidateOnly
    });
}

export function ComplexScheduleAppointmentsDto(complexSchedulePlan: ComplexSchedulePlan, isValidateOnly: boolean) {
    return new Proxy.ComplexScheduleAppointmentsControllerDto({
        from: complexSchedulePlan.from,
        to: complexSchedulePlan.to,
        scheduleMode: complexSchedulePlan.scheduleMode,
        complexSchedulingServiceFilters: complexSchedulePlan.schedulingServiceFilters.map(i => new Proxy.ComplexSchedulingServiceFilterDto({
            schedulingServiceId: i.schedulingServiceId,
            pointOfCareId: i.pointOfCareId,
            pointOfCareSchedulingServiceFilterType: i.pointOfCareFilterType,
            practitionerId: i.practitionerId,
            practitionerSchedulingServiceFilterType: i.practitionerFilterType,
            isFirst: i.isFirst,
            isLast: i.isLast,
            recurring: i.recurring,
            numberOfRepetitions: i.numberOfRepetitions,
            minimumTimeBetweenRepetitions: i.minimumTimeBetweenRepetitions,
            maximumTimeBetweenRepetitions: i.maximumTimeBetweenRepetitions,
            maxRepetitionPeriodInDays: i.maxRepetitionPeriodInDays,
            intraDayTimePeriodConstraintStart: i.intraDayTimePeriodConstraintStart.toString(),
            intraDayTimePeriodConstraintEnd: i.intraDayTimePeriodConstraintEnd.toString(),
            slots: i.preDesignatedAppointmentSlots.map(slotArray => slotArray.map(slot => new Proxy.AppointmentScheduleSlotBookingItemDto({
                id: slot.id,
                rowVersion: slot.rowVersion
            })))
        })),
        isValidateOnly: isValidateOnly
    });
}

export function GetCancelScheduledAppointmentsSlotBookingsDto(scheduledAppointments: ScheduledAppointmentStore[]) {
    return new Proxy.CancelScheduledAppointmentsSlotBookingsControllerDto({
        scheduledAppointmentsForCancellation: scheduledAppointments.map(i => new Proxy.ScheduledAppointmentCancellationDto({
            schedulingServiceId: i.schedulingServiceId,
            slots: i.slotsToBook.map(s => new Proxy.AppointmentScheduleSlotBookingItemDto({
                id: s.id,
                rowVersion: s.rowVersion
            }))
        }))
    });
}

export function GetCreateScheduledAppointmentsDto(
    scheduledAppointmentsForCreation: ScheduledAppointmentStore[],
    scheduledAppointmentsForCancellation: ScheduledAppointmentStore[],
    patientId: PatientId) {
    return new Proxy.CreateScheduledAppointmentsControllerDto({
        scheduledAppointmentsForCreation: scheduledAppointmentsForCreation.map(i => new Proxy.ScheduledAppointmentCreationDto({
            schedulingServiceId: i.schedulingServiceId,
            patientId: patientId,
            organizationUnitId: i.organizationUnitId,
            practitionerId: i.practitionerId,
            appointmentScheduleSlotSeriesId: i.appointmentScheduleSlotSeriesId,
            slots: i.slotsToBook.map(s => new Proxy.AppointmentScheduleSlotBookingItemDto({
                id: s.id,
                rowVersion: s.rowVersion
            })),
        })),
        scheduledAppointmentsForCancellation: scheduledAppointmentsForCancellation.map(i => new Proxy.ScheduledAppointmentCancellationDto({
            schedulingServiceId: i.schedulingServiceId,
            slots: i.slotsToBook.map(s => new Proxy.AppointmentScheduleSlotBookingItemDto({
                id: s.id,
                rowVersion: s.rowVersion
            }))
        }))
    });
}