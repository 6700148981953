import FormSchemaVersion from "@Toolkit/FormEngine/Model/Schema/FormSchemaVersion";
import FormLayoutVersionStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutVersionStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FormEnumStore from "@Toolkit/FormEngine/Model/Schema/FormEnumStore";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import FormEditorChange from "./Model/FormEditorChange";
import FormEditBehavior from "@Toolkit/FormEngine/Model/FormEditBehavior";
import FormLogicType from "@Primitives/FormLogicType";
import FormLogicToken from "@Toolkit/FormEngine/Model/FormLogicToken";
import FormLogic from "@Toolkit/FormEngine/Model/FormLogic";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";

export interface IFormDefinitionVersion {
    id: FormDefinitionId | null;
    name: string;
    multiLingualDisplayName: MultiLingualLabel;
    baseEntityType: string;
    rootSchemaVersion: FormSchemaVersion;
    rootLayoutVersion: FormLayoutVersionStore;
    enums: FormEnumStore[];
    formEditBehavior: FormEditBehavior;
    formLogics: FormLogic[];
    formLogicType: FormLogicType;
    formLogicTokens: FormLogicToken[];
    withoutDataHandling: boolean;
    isUserForm: boolean;
    instanceLimit: number;
}

export default class FormLayoutEditorHandle {

    private def: IFormDefinitionVersion;
    private isAttached = false;
    private readonly internalGetDefinition = () => this.def;
    private readonly internalSetDefinition = (d: IFormDefinitionVersion) => this.def = d;

    public getDefinition: () => IFormDefinitionVersion = this.internalGetDefinition;
    public setDefinition: (def: IFormDefinitionVersion) => void = this.internalSetDefinition;
    public getEditorChanges: () => FormEditorChange[] = FormEditorChange[0];
    public resetEditorChanges: () => void;

    public attach(
        getDefinition: () => IFormDefinitionVersion,
        setDefinition: (def: IFormDefinitionVersion) => void,
        getEditorChanges: () => FormEditorChange[],
        resetEditorChanges: () => void
    ) {
        if (this.isAttached) {
            throw new Error("FormLayoutEditor already attached to handle.");
        }

        this.getDefinition = getDefinition;
        this.setDefinition = setDefinition;
        this.getEditorChanges = getEditorChanges;
        this.resetEditorChanges = resetEditorChanges;
        this.isAttached = true;

        this.setDefinition(this.def);
    }

    @State.bound
    public detach() {
        this.def = this.getDefinition?.();
        this.getDefinition = this.internalGetDefinition;
        this.setDefinition = this.internalSetDefinition;
        this.getEditorChanges = () => [];
        this.resetEditorChanges = () => { return; };
        this.isAttached = false;
    }
}