import { EhiServiceStateStore, IEhiServiceStateStore } from "./EhiServiceStateStore";
import EhiServiceBase from "./EhiServiceBase";

export default class MultiEhiService<T extends { [name: string]: EhiServiceBase }> {

    private readonly ehiStateStore = new EhiServiceStateStore();
    
    constructor(
        public readonly ehiServices: T
    ) {
        let existingStore: IEhiServiceStateStore = null;
        Object.keys(ehiServices).forEach(name => {
            if (!existingStore && ehiServices[name].hasDataInStateStore()) {
                existingStore = ehiServices[name].getStateStore();
            }
        });

        Object.keys(ehiServices).forEach(name => {
            ehiServices[name].setCommonStore(existingStore || this.ehiStateStore);
        });
    }
}