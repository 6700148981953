import config from "@Config";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State, { IObservableValue } from "@Toolkit/ReactClient/Common/StateManaging";
import IMultiLingualLabel from "@Toolkit/CommonWeb/IMultiLingualLabel";

export default class EditableMultiLingualLabel implements IMultiLingualLabel {
    public readonly localizedLabels = State.createObservableShallowMap<string, IObservableValue<string>>();

    @State.bound
    public getLocalizedLabel(cultureCode: string) {
        return this.localizedLabels.get(cultureCode)?.get();
    }

    @State.bound
    public getWithCurrentCultureCodeOrWithDefaultCultureCode(cultureCode: string) {
        return isNullOrUndefined(this.localizedLabels.get(cultureCode)?.get()) ?
            (isNullOrUndefined(this.localizedLabels.get(config.defaultCultureCode)?.get()) ?
                Array.from(this.localizedLabels.values())[0]?.get() :
                this.localizedLabels.get(config.defaultCultureCode)?.get()) :
            this.localizedLabels.get(cultureCode)?.get();
    }

    @State.action.bound
    public setLocalizedLabel(cultureCode: string | null, label: string) {
        const labels = this.localizedLabels.get(cultureCode);
        if (!!labels) {
            labels.set(label);
        } else {
            this.localizedLabels.set(cultureCode, State.observable.box(label));
        }

    }
}