import React from "react";
import Style from "./AlertCard.less";
import Icon, { iconNameType } from "@CommonControls/Icon";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import { ICommonControlProps, getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import { Tooltip } from "@Toolkit/ReactClient/Components/Tooltip";

export interface IAlertCardProps extends ICommonControlProps {
    visualStyle: "error" | "warning" | "info";
    innerLabel?: { upperText: string, lowerText: string };
    label: string;
    background?: "white";
    collapsed?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    innerIconName?: iconNameType;
}

export default class AlertCard extends React.PureComponent<IAlertCardProps> {

    private getStyledClassName(baseClassName: string) {
        switch (this.props.visualStyle) {
            case "error":
                return `${baseClassName} ${Style.error}`;
            case "warning":
                return `${baseClassName} ${Style.warning}`;
            case "info":
                return `${baseClassName}`;
            default:
                throw new Error("Invalid visual style");
        }
    }

    private getIconContainerClassName() {
        const classes = new CompositeClassName(this.getStyledClassName(Style.iconContainer));
        classes.addIf(!this.props.collapsed, Style.iconContainerNotCollapsed);
        return classes.classNames;
    }

    public render() {
        const htmlProps = getStandardHtmlProps(this.props, Style.container);
        return (
            <Tooltip
                content={this.props.tooltipContent}
                placement={this.props.tooltipPosition}
                contentAlignment={this.props.tooltipTextAlign}>
                <div {...htmlProps} onClick={this.props.onClick}>
                    {!this.props.collapsed &&
                        <>
                            {this.props.innerIconName && <Icon className={Style.innerIcon} iconName={this.props.innerIconName} />}
                            {this.props.label && <label className={Style.label}>{this.props.label}</label>}
                            {this.props.innerLabel &&
                                <div className={Style.innerLabelContainer}>
                                    <label className={this.getStyledClassName(Style.innerLabelUpper)}>
                                        {this.props.innerLabel.upperText}</label>
                                    <label className={this.getStyledClassName(Style.innerLabelLower)}>
                                        {this.props.innerLabel.lowerText}</label>
                                        <Icon iconName="expand" />
                                </div>
                            }
                        </>
                    }
                    <div className={this.getIconContainerClassName()}>
                        <Icon iconName="exclamation" />
                    </div>
                </div>
            </Tooltip>
        );
    }
}