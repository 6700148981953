exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ActionButton_action-button_kMiCE {\n  cursor: pointer;\n  border: 1px solid transparent;\n  border-radius: 2px;\n  vertical-align: middle;\n  text-align: center;\n  margin: 2px;\n  outline: 0;\n}\n.ActionButton_action-button_kMiCE > .ActionButton_icon_20TN2 {\n  left: -2px;\n  top: 2px;\n}\n.ActionButton_action-button_kMiCE.ActionButton_action-register-as-received_1Opuv {\n  background-color: #82d4ed;\n}\n.ActionButton_action-button_kMiCE.ActionButton_action-complete-activity_3Miep {\n  background-color: #ddc00d;\n}\n.ActionButton_action-button_kMiCE.ActionButton_action-validate-diagnostic-report_1suVm {\n  background-color: #21c313;\n}\n.ActionButton_action-button_kMiCE.ActionButton_action-delete_31OdM {\n  background-color: #ce5374;\n}\n", ""]);

// exports
exports.locals = {
	"action-button": "ActionButton_action-button_kMiCE",
	"actionButton": "ActionButton_action-button_kMiCE",
	"icon": "ActionButton_icon_20TN2",
	"action-register-as-received": "ActionButton_action-register-as-received_1Opuv",
	"actionRegisterAsReceived": "ActionButton_action-register-as-received_1Opuv",
	"action-complete-activity": "ActionButton_action-complete-activity_3Miep",
	"actionCompleteActivity": "ActionButton_action-complete-activity_3Miep",
	"action-validate-diagnostic-report": "ActionButton_action-validate-diagnostic-report_1suVm",
	"actionValidateDiagnosticReport": "ActionButton_action-validate-diagnostic-report_1suVm",
	"action-delete": "ActionButton_action-delete_31OdM",
	"actionDelete": "ActionButton_action-delete_31OdM"
};