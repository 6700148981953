import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IServiceRequestReferralExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestReferralExtensionPointProps";
import EhiEReferralData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralData";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import EhiEReferralExtensionPanelView from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/EhiEReferralExtension/EhiEReferralExtensionPanelView";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import EhiHealthcareProviderId from "@Primitives/EhiHealthcareProviderId.g";
import EhiHealthcareProviderOrganizationUnitId from "@Primitives/EhiHealthcareProviderOrganizationUnitId.g";
import EhiEReferralReasonId from "@Primitives/EhiEReferralReasonId.g";
import EhiEReferralServiceTypeId from "@Primitives/EhiEReferralServiceTypeId.g";
import ConditionsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ConditionsApiAdapter";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import ConditionId from "@Primitives/ConditionId.g";
import SuspectedDiagnosis from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/SuspectedDiagnosis";
import { IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import EhiEReferralTravelExpenseTypeId from "@Primitives/EhiEReferralTravelExpenseTypeId.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import EhiCareServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareServiceFactory";
import EhiCareService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareService";
import EhiUserType from "@HunEHealthInfrastructurePlugin/Model/EhiUserType";
import EhiStructuredDocumentContactStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiStructuredDocumentContactStore";
import EhiStructuredDocumentContactTypeId from "@Primitives/EhiStructuredDocumentContactTypeId.g";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StaticResources from "@StaticResources";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import IncludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierSystemIdFilter";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";

interface IEhiEReferralExtensionPanelDependencies {
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    ehiCareApiAdapter: EhiCareApiAdapter;
    conditionsApiAdapter: ConditionsApiAdapter;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    ehiCareServiceFactory: EhiCareServiceFactory;
    dialogService: IDialogService;
}

interface IEhiEReferralExtensionPanelProps extends IServiceRequestReferralExtensionPointProps {
    _dependencies?: IEhiEReferralExtensionPanelDependencies;
    isCovidDataNeeded: boolean;
}

@State.observer
class EhiEReferralExtensionPanel extends React.Component<IEhiEReferralExtensionPanelProps> {

    @State.observable.ref private dataStore: EhiEReferralData;
    @State.observable private isLoading = false;
    private disposers: IDisposable[] = [];
    private readonly covidConditionCode = "U0720";
    private readonly covidConditionIdentifierSystemId = new IdentifierSystemId("ICD10");
    private covidConditionId: ConditionId;
    private readonly covidEhiEReferralServiceTypeCode = "C190";
    private covidEhiEReferralServiceTypeId: EhiEReferralServiceTypeId;
    private ehiCareService: EhiCareService = null;

    private get serviceRequest() {
        return this.props.serviceRequest;
    }

    private get ehiCareApiAdapter() {
        return this.props._dependencies.ehiCareApiAdapter;
    }

    private get organizationReferenceDataStore() {
        return this.props._dependencies.organizationReferenceDataStore;
    }

    private get ehiCareServiceFactory() { return this.props._dependencies.ehiCareServiceFactory; }

    public componentDidMount() {
        dispatchAsyncErrors(this.initAsync(), this);
    }

    public componentDidUpdate(prevProps: IEhiEReferralExtensionPanelProps) {
        if (this.props.serviceRequest && this.props.serviceRequest !== prevProps.serviceRequest) {
            this.disposers.forEach(d => d.dispose());
            this.disposers = [];
            dispatchAsyncErrors(this.setDataStoreAsync(), this);
        }

        if (this.props.externalLocationId?.value !== prevProps.externalLocationId?.value) {
            dispatchAsyncErrors(this.tryLoadExternalLocationRelatedDataAsync(), this);
        }

        if (this.props.executingPointOfCareId?.value !== prevProps.executingPointOfCareId?.value) {
            dispatchAsyncErrors(this.tryLoadPointOfCareRelatedDataAsync(), this);
        }

    }

    public async initAsync() {
        this.ehiCareService = this.ehiCareServiceFactory.getEhiCareService();
        State.runInAction(() => this.isLoading = true);
        await this.loadAsync();
        await this.setDataStoreAsync();
        State.runInAction(() => this.isLoading = false);
    }

    public async loadAsync() {
        await Promise.all([
            this.props._dependencies.hunEhiCareReferenceDataStore.ehiEReferralTravelExpenseTypes.ensureLoadedAsync(),
            this.props._dependencies.hunEhiCareReferenceDataStore.ehiEReferralReasons.ensureLoadedAsync(),
            this.loadCovidConditionIdAsync(),
            this.loadCovidEhiEReferralServiceTypeIdAsync()]);
    }

    @State.action.bound
    private async loadCovidConditionIdAsync() {
        const filters: FilterBase[] = [new IncludeIdentifierSystemIdFilter(this.covidConditionIdentifierSystemId)];
        const result = await this.props._dependencies.conditionsApiAdapter.searchConditionsByCode(this.covidConditionCode, 1, LocalDate.today(), filters);
        const id = result.value?.length && result.value[0].id;
        this.covidConditionId = id;
    }

    @State.action.bound
    private async loadCovidEhiEReferralServiceTypeIdAsync() {
        const ordering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;
        const paging = { currentPage: 0, pageSize: 1 };
        const result = await this.ehiCareApiAdapter.searchEhiEReferralServiceTypes(this.covidEhiEReferralServiceTypeCode, ordering, paging);
        const id = result.items?.length && result.items[0].id;
        this.covidEhiEReferralServiceTypeId = id;
    }

    @State.action.bound
    private setProviderId(id: EhiHealthcareProviderId) {
        this.dataStore.setEhiHealthcareProviderId(id);
        this.dataStore.setEhiHealthcareProviderOrganizationUnitId(null);
    }

    @State.bound
    private setProviderOrganizationUnitId(id: EhiHealthcareProviderOrganizationUnitId) {
        dispatchAsyncErrors(this.setProviderOrganizationUnitIdAsync(id), this);
    }

    @State.bound
    private async setProviderOrganizationUnitIdAsync(id: EhiHealthcareProviderOrganizationUnitId) {
        const ehiHealthcareProviderOrganizationUnit = await this.props._dependencies.hunEhiCareReferenceDataStore.ehiHealthcareProviderOrganizationUnits.getOrLoadAsync(id);

        if (!isNullOrUndefined(ehiHealthcareProviderOrganizationUnit)) {
            for (const healthcareProfessionId of ehiHealthcareProviderOrganizationUnit.healthcareProfessionIds) {
                const serviceTypeId = await this.ehiCareApiAdapter.tryGetEhiEReferralServiceTypeIdByHealthcareProfessionIdAsync(healthcareProfessionId);

                if (!isNullOrUndefined(serviceTypeId?.value)) {
                    this.dataStore.setEhiEReferralServiceTypeId(serviceTypeId.value);
                    break;
                }
            }
            this.dataStore.setEhiHealthcareProviderId(ehiHealthcareProviderOrganizationUnit.ehiHealthcareProviderId);
        }

        this.dataStore.setEhiHealthcareProviderOrganizationUnitId(id);
    }

    @State.bound
    private async tryLoadExternalLocationRelatedDataAsync() {
        if (this.serviceRequest.isNew
            && this.serviceRequest.direction === ServiceRequestDirection.InternalToExternal
            && this.props.externalLocationId) {
            const response = await this.ehiCareApiAdapter.getEhiEReferralHealthCareProviderOrganizationUnitAsync(this.props.externalLocationId);
            if (response.value) {
                State.runInAction(() => {
                    this.dataStore.setEhiHealthcareProviderOrganizationUnitId(response.value.id);
                    this.dataStore.setEhiHealthcareProviderId(response.value.ehiHealthcareProviderId);
                });
                if (!this.props.isCovidDataNeeded) {
                    const orgUnit = await this.props._dependencies.hunEhiCareReferenceDataStore.ehiHealthcareProviderOrganizationUnits.getOrLoadAsync(response.value.id);

                    for (const healthcareProfessionId of orgUnit.healthcareProfessionIds) {
                        const serviceTypeId = await this.ehiCareApiAdapter.tryGetEhiEReferralServiceTypeIdByHealthcareProfessionIdAsync(healthcareProfessionId);

                        if (!this.props.isCovidDataNeeded && serviceTypeId.value) {
                            State.runInAction(() => {
                                this.dataStore.setEhiEReferralServiceTypeId(serviceTypeId.value);
                            });
                            break;
                        }
                    }
                }
            }
        }
    }

    @State.bound
    private async tryLoadPointOfCareRelatedDataAsync() {
        if (this.serviceRequest.isNew
            && this.serviceRequest.direction === ServiceRequestDirection.InternalToInternal
            && this.props.executingPointOfCareId
            && !this.props.isCovidDataNeeded) {

            const serviceTypeId = await this.ehiCareApiAdapter.tryGetEhiEReferralServiceTypeIdByPointOfCareIdAsync(this.props.executingPointOfCareId);

            if (this.dataStore && !isNullOrUndefined(serviceTypeId?.value)) {
                this.dataStore.setEhiEReferralServiceTypeId(serviceTypeId.value);
            }
        }
    }

    public componentWillUnmount() {
        this.disposers.forEach(d => d.dispose());
    }

    @State.action.bound
    private async setDataStoreAsync() {
        if (!this.props.serviceRequest?.extensionData) {
            this.props.initializeExtensionData();
        }
        this.dataStore = EhiEReferralData.createFromExtensionDto(this.serviceRequest?.extensionData, this.props.isCovidDataNeeded);
        if (this.serviceRequest.isNew) {
            await this.setInitValuesAsync();
        }
        this.props.serviceRequest.setSuspectedDiagnosisListReadOnly(this.props.isCovidDataNeeded);
        this.props.serviceRequest.takeSnapshot();
        this.dataStore.takeSnapshot();

        const { onExtensionDataChange } = this.props;
        const extensionData = this.props.serviceRequest.extensionData;
        await this.ehiCareService.setEhiAccessDataWithoutOrganizationUnitCheckToExtensionDataAsync(extensionData, EhiUserType.Technical, null);
        this.disposers.push({
            dispose: State.autorun(() => {
                if (this.serviceRequest) {
                    onExtensionDataChange({
                        ...extensionData,
                        ["HunEHealthInfrastructure_EhiEReferralData"]: {
                            Id: isNullOrUndefined(this.dataStore.id)
                                ? null
                                : this.dataStore.id.toJSON(),
                            EhiHealthcareProviderId: isNullOrUndefined(this.dataStore.ehiHealthcareProviderId)
                                ? null
                                : this.dataStore.ehiHealthcareProviderId.toJSON(),
                            EhiHealthcareProviderOrganizationUnitId: isNullOrUndefined(this.dataStore.ehiHealthcareProviderOrganizationUnitId)
                                ? null
                                : this.dataStore.ehiHealthcareProviderOrganizationUnitId.toJSON(),
                            EhiEReferralServiceTypeId: isNullOrUndefined(this.dataStore.ehiEReferralServiceTypeId)
                                ? null
                                : this.dataStore.ehiEReferralServiceTypeId.toJSON(),
                            EhiEReferralReasonId: isNullOrUndefined(this.dataStore.ehiEReferralReasonId)
                                ? null
                                : this.dataStore.ehiEReferralReasonId.toJSON(),
                            EhiEReferralTravelExpenseTypeId: isNullOrUndefined(this.dataStore.ehiEReferralTravelExpenseTypeId)
                                ? null
                                : this.dataStore.ehiEReferralTravelExpenseTypeId.toJSON(),
                            Anamnesis: isNullOrUndefined(this.dataStore.anamnesis)
                                ? null
                                : this.dataStore.anamnesis,
                            IsInAreaCare: isNullOrUndefined(this.dataStore.isInAreaCare)
                                ? false
                                : this.dataStore.isInAreaCare,
                            DoctorNotification: isNullOrUndefined(this.dataStore.doctorNotification)
                                ? false
                                : this.dataStore.doctorNotification,
                            PatientNotification: isNullOrUndefined(this.dataStore.patientNotification)
                                ? false
                                : this.dataStore.patientNotification,
                            SickAllowanceIdentifier: isNullOrUndefined(this.dataStore.sickAllowanceIdentifier)
                                ? null
                                : this.dataStore.sickAllowanceIdentifier,
                            TravelVoucherIdentifier: isNullOrUndefined(this.dataStore.travelVoucherIdentifier)
                                ? null
                                : this.dataStore.travelVoucherIdentifier,
                            Concilium: isNullOrUndefined(this.dataStore.concilium)
                                ? null
                                : this.dataStore.concilium,
                            IsOutOfOrder: isNullOrUndefined(this.dataStore.isOutOfOrder)
                                ? false
                                : this.dataStore.isOutOfOrder,
                            SicknessInDaysSinceStart: isNullOrUndefined(this.dataStore.sicknessInDaysSinceStart)
                                ? null
                                : this.dataStore.sicknessInDaysSinceStart,
                            ReferralMedicalServiceId: isNullOrUndefined(this.dataStore.referralMedicalServiceId)
                                ? null
                                : this.dataStore.referralMedicalServiceId.id.toJSON(),
                            CovidData: this.props.isCovidDataNeeded ? this.getCovidDataDto() : null
                        }
                    });
                }
            })
        });

        this.disposers.push(
            this.props.extensionController.setIsDirtyHook(this.isDirty)
        );
    }

    @State.bound
    private async setInitValuesAsync() {
        if (this.props.isCovidDataNeeded) {
            this.dataStore.setEhiEReferralReasonId(EhiEReferralReasonId["2"]);
            this.dataStore.setEhiEReferralServiceTypeId(this.covidEhiEReferralServiceTypeId);
            if (this.covidConditionId) {
                const condition = new SuspectedDiagnosis();
                condition.conditionId = this.covidConditionId;
                this.props.serviceRequest.setSuspectedDiagnosisList(State.observable<SuspectedDiagnosis>([condition]));
            }

            if (this.serviceRequest.isNew) {

                if (!!this.props.serviceRequest.requesterDoctorId) {
                    const practitionerContactsResponse = await this.ehiCareApiAdapter.getEhiStructuredDocumentContactsForPractitionerAsync(this.props.serviceRequest.requesterDoctorId);

                    if (!!practitionerContactsResponse?.value) {
                        this.dataStore.covidData.setPractitionerContacts(practitionerContactsResponse.value);
                    } else {
                        this.dataStore.covidData.addPractitionerContact(new EhiStructuredDocumentContactStore(EhiStructuredDocumentContactTypeId.Mobile, null));
                    }
                }

                if (!!this.props.patientId) {
                    const patientContactsResponse = await this.ehiCareApiAdapter.getEhiStructuredDocumentContactsForPatientAsync(this.props.patientId);

                    if (!!patientContactsResponse?.value) {
                        this.dataStore.covidData.setPatientContacts(patientContactsResponse.value);
                    } else {
                        this.dataStore.covidData.addPatientContact(new EhiStructuredDocumentContactStore(EhiStructuredDocumentContactTypeId.Mobile, null));
                    }
                }
            }
        } else {
            this.dataStore.setEhiEReferralReasonId(EhiEReferralReasonId["1"]);
            this.dataStore.setEhiEReferralTravelExpenseTypeId(EhiEReferralTravelExpenseTypeId["1"]);
            this.dataStore.setIsInAreaCare(true);
        }
    }

    @State.bound
    private getCovidDataDto() {
        return this.props.isCovidDataNeeded && this.dataStore.covidData && {
            ExaminationSampleIdentifier: this.dataStore.covidData.examinationSampleIdentifier,
            ExaminationSampleTypeId: isNullOrUndefined(this.dataStore.covidData.examinationSampleTypeId)
                ? null
                : this.dataStore.covidData.examinationSampleTypeId.toJSON(),
            SamplingDate: this.dataStore.covidData.samplingDate,
            ExaminationTypeId: isNullOrUndefined(this.dataStore.covidData.examinationTypeId)
                ? null
                : this.dataStore.covidData.examinationTypeId.toJSON(),
            SicknessStartFrom: this.dataStore.covidData.sicknessStartFrom,
            ContactExaminationReasonId: isNullOrUndefined(this.dataStore.covidData.contactExaminationReasonId)
                ? null
                : this.dataStore.covidData.contactExaminationReasonId.toJSON(),
            ContactExaminationOtherReasonJustification: this.dataStore.covidData.contactExaminationOtherReasonJustification,
            ScreeningExaminationReasonId: isNullOrUndefined(this.dataStore.covidData.screeningExaminationReasonId)
                ? null
                : this.dataStore.covidData.screeningExaminationReasonId.toJSON(),
            ScreeningExaminationOtherReasonJustification: this.dataStore.covidData.screeningExaminationOtherReasonJustification,
            StayAbroadInTheLastTwoWeeks: this.dataStore.covidData.stayAbroadInTheLastTwoWeeks,
            CountryOfResidenceAbroad: isNullOrUndefined(this.dataStore.covidData.countryOfResidenceAbroad)
                ? null
                : this.dataStore.covidData.countryOfResidenceAbroad.toJSON(),
            SymptomsSeverityId: isNullOrUndefined(this.dataStore.covidData.symptomsSeverityId)
                ? null
                : this.dataStore.covidData.symptomsSeverityId.toJSON(),
            SymptomsDescription: this.dataStore.covidData.symptomsDescription,
            IsReExamination: this.dataStore.covidData.isReExamination,
            PreviousResultId: isNullOrUndefined(this.dataStore.covidData.previousResultId)
                ? null
                : this.dataStore.covidData.previousResultId.toJSON(),
            QuickTestResultId: isNullOrUndefined(this.dataStore.covidData.quickTestResultId)
                ? null
                : this.dataStore.covidData.quickTestResultId.toJSON(),
            QuickTestDate: this.dataStore.covidData.quickTestDate,
            PractitionerContacts: this.dataStore.covidData.practitionerContacts.map(c => ({
                Value: c.value,
                EhiStructuredDocumentContactTypeId: c.contactTypeId
            })),
            PatientContacts: this.dataStore.covidData.patientContacts.map(c => ({
                Value: c.value,
                EhiStructuredDocumentContactTypeId: c.contactTypeId
            }))
        };
    }

    @State.bound
    private isDirty() {
        return this.dataStore.isDirty();
    }

    private get useEhiHealthcareProviderData() {
        return this.props.serviceRequest?.direction === ServiceRequestDirection.InternalToExternal;
    }

    public render() {
        return !this.isLoading && (
            <EhiEReferralExtensionPanelView store={this.dataStore}
                useEhiHealthcareProviderData={this.useEhiHealthcareProviderData}
                validationResults={this.serviceRequest.validationResults}
                setProviderId={this.setProviderId}
                setProviderOrganizationUnitId={this.setProviderOrganizationUnitId}
                isCovidDataNeeded={this.props.isCovidDataNeeded}
                createContactAsync={this.createContactAsync}
                onDeleteContactConfirmationAsync={this.onDeleteContactConfirmationAsync}
            />
        );
    }

    @State.bound
    public async onDeleteContactConfirmationAsync(contact: EhiStructuredDocumentContactStore) {
        if (contact.isEmpty) {
            return true;
        } else {
            const dialogResult = await this.props._dependencies.dialogService.yesNo(
                StaticResources.Common.DialogTitle.ConfirmationTitle,
                StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.ContactDeleteConfirmationMessage);
            return dialogResult.resultCode === DialogResultCode.Yes;
        }
    }

    private createContactAsync = () => Promise.resolve(new EhiStructuredDocumentContactStore(EhiStructuredDocumentContactTypeId.Mobile, null));
}

export default connect(
    EhiEReferralExtensionPanel,
    new DependencyAdapter<IEhiEReferralExtensionPanelProps, IEhiEReferralExtensionPanelDependencies>((containerService) => {
        return {
            hunEhiCareReferenceDataStore: containerService.resolve("HunEhiCareReferenceDataStore"),
            ehiCareApiAdapter: containerService.resolve("EhiCareApiAdapter"),
            conditionsApiAdapter: containerService.resolve("ConditionsApiAdapter"),
            organizationReferenceDataStore: containerService.resolve("OrganizationReferenceDataStore"),
            ehiCareServiceFactory: containerService.resolve("EhiCareServiceFactory"),
            dialogService: containerService.resolve("IDialogService"),
        };
    })
);
