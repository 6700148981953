import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";

@Di.injectable()
class CustomerScreenFormLogic implements IFormLogic {
    private zipCodeId: number;
    private settlement: string;

    executeAsync(form: IForm, showScreenAction?: ShowScreenFrontendActionBase, dataElements?: FormDataElementBase[]): Promise<void> {
        State.runInAction(() => {
            const formFields = form.data.Content;
            const zipCodeField = getField<ReferencedEntityFormFieldData>(formFields, "Address.ZipCode");
            const settlementField = getField<TextFormFieldData>(formFields, "Address.Settlement");

            const zipCodeId = zipCodeField.value;
            const settlement = settlementField.value;

            if (!this.zipCodeId && !this.settlement) {
                this.zipCodeId = zipCodeId;
                this.settlement = settlementField.value;
            } else if (!zipCodeId && !settlement) {
                zipCodeField.value = this.zipCodeId;
                settlementField.value = this.settlement;
                this.zipCodeId = undefined;
                this.settlement = undefined;
            }
        });
        return Promise.resolve();
    }
}

export default CustomerScreenFormLogic;