import ILockingEntityStore from "./ILockingEntityStore";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import EntityStoreBase from "./EntityStoreBase";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default abstract class LockingEntityStoreBase<TEntityId extends IStringEntityId> extends EntityStoreBase<TEntityId> implements ILockingEntityStore {

    public lockInfo: LockInfo = null;

    public get isMutable() {
        return this.isNew === true || (this.lockInfo && this.lockInfo.lockState !== EntityLockState.LockingRequiredAndLockNotHeld);
    }

    @State.action
    public releaseLock() {
        if (this.lockInfo.lockState === EntityLockState.LockingRequiredAndLockHeld) {
            this.lockInfo = new LockInfo(EntityLockState.LockingRequiredAndLockNotHeld, null, false, false, null, null, null);
        }
    }

    public get hasUpdatePermission() {
        return this.lockInfo && this.lockInfo.hasUpdatePermission;
    }

    constructor(
        isNew: boolean = false
    ) {
        super(isNew);
    }
}