import PermissionScopeType from "@Primitives/PermissionScopeTypes";

// Provides default values for the system until there is no real scope value set for the user
// In most cases the value should be something that theoretically shouldn't exist -> Default
// In case we want to default a scope value to and already existing one we can do it here
const defaultPermissionScopeTypeValues  = new Map<PermissionScopeType, string>(
    [
        [PermissionScopeType.pointOfCare, "Default"]
    ]
);

export { defaultPermissionScopeTypeValues };
