import { CheckBox, DateTimePicker, DatePicker, TimePicker } from "@CommonControls";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import ModalServiceRegistry from "@Toolkit/ReactClient/Components/ModalService/ModalServiceRegistry";
import FormDefinitionSelectorDialog from "@Toolkit/FormEngine/Components/Dialogs/FormDefinitionSelectorDialog/FormDefinitionSelectorDialog";
import { INumberBoxProps } from "@CommonControls/NumberBox";
import StaticFormEditingResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import FormEnumSelector from "@Toolkit/FormEngine/Panels/FormPanel/LayoutElements/FormEnumSelector/FormEnumSelector";
import FormEntitySelector from "@Toolkit/FormEngine/Panels/FormPanel/LayoutElements/FormEntitySelector/FormEntitySelector";
import SelectBox, { ISelectBoxProps } from "@CommonControls/SelectBox";
import { emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormTextBox from "@Toolkit/FormEngine/Panels/FormPanel/LayoutElements/FormTextBox/FormTextBox";
import FormNumberBox from "@Toolkit/FormEngine/Panels/FormPanel/LayoutElements/FormNumberBox/FormNumberBox";
import BooleanFormDataElement from "@Toolkit/FormEngine/Model/Schema/BooleanFormDataElement";
import StringFormDataElement from "@Toolkit/FormEngine/Model/Schema/StringFormDataElement";
import NumberFormDataElement from "@Toolkit/FormEngine/Model/Schema/NumberFormDataElement";
import DateFormDataElement from "@Toolkit/FormEngine/Model/Schema/DateFormDataElement";
import TimeFormDataElement from "@Toolkit/FormEngine/Model/Schema/TimeFormDataElement";
import DateTimeFormDataElement from "@Toolkit/FormEngine/Model/Schema/DateTimeFormDataElement";
import EnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/EnumFormDataElement";
import ReferencedEntityFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEntityFormDataElement";
import FilterDialogParams from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/PropertyPanels/Filters/FilterDialogParams";
import FilterDialog from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/PropertyPanels/Filters/FilterDialog";
import ReferencedExtensibleEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedExtensibleEnumFormDataElement";
import FormExtensibleEnumSelector from "@Toolkit/FormEngine/Panels/FormPanel/LayoutElements/FormExtensibleEnumSelector/FormExtensibleEnumSelector";
import { FormDefinitionSelectorDialogParams } from "@Toolkit/FormEngine/Components/Dialogs/FormDefinitionSelectorDialog/FormDefinitionSelectorDialogParams";
import ReferencedEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema//ReferencedEnumFormDataElement";
import FormReferencedEnumSelector from "@Toolkit/FormEngine/Panels/FormPanel/LayoutElements/FormReferencedEnumSelector/FormReferencedEnumSelector";
import FormEmbeddedFormBlock from "@Toolkit/FormEngine/Panels/FormPanel/LayoutElements/FormEmbeddedFormBlock/FormEmbeddedFormBlock";
import EmbeddedFormBlockPropertyPanel from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/PropertyPanels/EmbeddedFormBlockPropertyPanel";
import EmbeddedFormBlockSettings from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EmbeddedFormBlockSettings";
import FormFieldDependencyDialogParams from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/PropertyPanels/FormFieldDependencyDialog/FormFieldDependencyDialogParams";
import FormFieldDependencyDialog from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/PropertyPanels/FormFieldDependencyDialog/FormFieldDependencyDialog";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { Moment } from "moment";
import FormFieldDependencyStore from "@Toolkit/FormEngine/Panels/FormPanel/FormFieldDependencyStore";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import FormHufMoneyBox from "@Toolkit/FormEngine/Panels/FormPanel/LayoutElements/FormMoneyBox/FormHufMoneyBox";

export function configureFormEngineCommonEditors(registry: FormEditorRegistry) {

    registry.registerEditor(
        "CheckBox",
        {
            componentType: CheckBox,
            toolboxNewItemDisplayName: StaticFormEditingResources.FormDefinitionEditor.SideToolbox.Editors.NewCheckBoxLabel,
            dataElementFactory: propertyName => new BooleanFormDataElement(propertyName, false, false, false, true),
            dependencyStoreFactory: () => new FormFieldDependencyStore<boolean>()
        }
    );

    registry.registerEditor(
        "TextBox",
        {
            componentType: FormTextBox,
            toolboxNewItemDisplayName: StaticFormEditingResources.FormDefinitionEditor.SideToolbox.Editors.NewTextBoxLabel,
            dataElementFactory: propertyName => new StringFormDataElement(propertyName, false, null, false, true, false),
            dependencyStoreFactory: () => new FormFieldDependencyStore<string>()
        }
    );

    registry.registerEditor<INumberBoxProps>(
        "NumberBox",
        {
            componentType: FormNumberBox,
            toolboxNewItemDisplayName: StaticFormEditingResources.FormDefinitionEditor.SideToolbox.Editors.NewNumberBoxLabel,
            dataElementFactory: propertyName => new NumberFormDataElement(propertyName, false, null, false, false, true),
            dependencyStoreFactory: () => new FormFieldDependencyStore<number>()
        }
    );

    registry.registerEditor<INumberBoxProps>(
        "HufMoneyBox",
        {
            componentType: FormHufMoneyBox,
            toolboxNewItemDisplayName: StaticFormEditingResources.FormDefinitionEditor.SideToolbox.Editors.NewHufMoneyBoxLabel,
            dataElementFactory: propertyName => new NumberFormDataElement(propertyName, false, 0, false, false, true),
            dependencyStoreFactory: () => new FormFieldDependencyStore<number>()
        }
    );

    registry.registerEditor(
        "DatePicker",
        {
            componentType: DatePicker,
            toolboxNewItemDisplayName: StaticFormEditingResources.FormDefinitionEditor.SideToolbox.Editors.NewDatePickerLabel,
            dataElementFactory: propertyName => new DateFormDataElement(propertyName, false, null, false, true),
            dependencyStoreFactory: () => new FormFieldDependencyStore<LocalDate>()
        }
    );

    registry.registerEditor(
        "TimePicker",
        {
            componentType: TimePicker as any,
            toolboxNewItemDisplayName: StaticFormEditingResources.FormDefinitionEditor.SideToolbox.Editors.NewTimePickerLabel,
            dataElementFactory: propertyName => new TimeFormDataElement(propertyName, false, null, false, true),
            dependencyStoreFactory: () => new FormFieldDependencyStore<TimeOfDay>()
        }
    );

    registry.registerEditor(
        "DateTimePicker",
        {
            componentType: DateTimePicker,
            toolboxNewItemDisplayName: StaticFormEditingResources.FormDefinitionEditor.SideToolbox.Editors.NewDateTimePickerLabel,
            dataElementFactory: propertyName => new DateTimeFormDataElement(propertyName, false, null, false, true),
            dependencyStoreFactory: () => new FormFieldDependencyStore<Moment>()
        }
    );

    registry.registerEditor<ISelectBoxProps, ISelectBoxProps>(
        "EnumSelector",
        {
            componentType: FormEnumSelector,
            toolboxNewItemDisplayName: StaticFormEditingResources.FormDefinitionEditor.SideToolbox.Editors.NewEnumSelectorLabel,
            dataElementFactory: propertyName => new EnumFormDataElement(propertyName, false, null, false, true),
            dependencyStoreFactory: () => new FormFieldDependencyStore<number>(),
            placeholderComponentType: SelectBox,
            placeholderProps: { items: emptyArray }
        }
    );

    registry.registerEditor<ISelectBoxProps, ISelectBoxProps>(
        "EntitySelector",
        {
            componentType: FormEntitySelector,
            toolboxNewItemDisplayName: StaticFormEditingResources.FormDefinitionEditor.SideToolbox.Editors.NewEntitySelectorLabel,
            dataElementFactory: propertyName => new ReferencedEntityFormDataElement(propertyName, false, null, false, true),
            dependencyStoreFactory: () => new FormFieldDependencyStore<number>(),
            placeholderComponentType: SelectBox,
            placeholderProps: { items: emptyArray }
        }
    );

    registry.registerEditor<ISelectBoxProps, ISelectBoxProps>(
        "ReferencedExtensibleEnumSelector",
        {
            componentType: FormExtensibleEnumSelector,
            toolboxNewItemDisplayName: StaticFormEditingResources.FormDefinitionEditor.SideToolbox.Editors.NewExtensibleEnumLabel,
            dataElementFactory: propertyName => new ReferencedExtensibleEnumFormDataElement(propertyName, false, null, false, true),
            dependencyStoreFactory: () => new FormFieldDependencyStore<string>(),
            placeholderComponentType: SelectBox,
            placeholderProps: { items: emptyArray }
        }
    );

    registry.registerEditor<ISelectBoxProps, ISelectBoxProps>(
        "ReferencedEnumSelector",
        {
            componentType: FormReferencedEnumSelector,
            toolboxNewItemDisplayName: StaticFormEditingResources.FormDefinitionEditor.SideToolbox.Editors.NewReferencedEnumLabel,
            dataElementFactory: propertyName => new ReferencedEnumFormDataElement(propertyName, false, null, false, true),
            dependencyStoreFactory: () => new FormFieldDependencyStore<number>(),
            placeholderComponentType: SelectBox,
            placeholderProps: { items: emptyArray }
        }
    );

    registry.registerCustomBlock(
        "EmbeddedFormBlock",
        {
            componentType: FormEmbeddedFormBlock,
            toolboxNewItemDisplayName: StaticFormEditingResources.FormDefinitionEditor.SideToolbox.Editors.EmbeddedFormBlockLabel,
            dataElementsFactory: () => [],
            properyPanelComponentType: EmbeddedFormBlockPropertyPanel,
            settingsFactory: () => new EmbeddedFormBlockSettings()
        }
    );
}

export function configureFormEngineModals(modalServiceRegistry: ModalServiceRegistry) {
    modalServiceRegistry.register(FormDefinitionSelectorDialogParams.type, FormDefinitionSelectorDialog);
    modalServiceRegistry.register(FilterDialogParams.type, FilterDialog);
    modalServiceRegistry.register(FormFieldDependencyDialogParams.type, FormFieldDependencyDialog);
}
