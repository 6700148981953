import Di from "@Di";
import { IFormDetailPanelProps } from "./FormDetailPanel";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import ISaveResult from "@Toolkit/CommonWeb/Model/PanelStore/ISaveResult";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import Form from "@Toolkit/FormEngine/Model/Form";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IFormEngineApiAdapter from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import FormEditBehavior from "@Toolkit/FormEngine/Model/FormEditBehavior";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import StaticFormEditingResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import { FormDefinitionSelectorDialogParams, IFormDefinitionSelectorDialogResult } from "@Toolkit/FormEngine/Components/Dialogs/FormDefinitionSelectorDialog/FormDefinitionSelectorDialogParams";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import { replaceDetailContent } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";

@Di.injectable()
export default class FormDetailPanelStore extends EditorScreenPanelStoreBase<IFormDetailPanelProps> implements ILoadablePanelStore<IFormDetailPanelProps> {

    @State.observable.ref public formValidationErrors: IObservableArray<IClientValidationProblem> = State.observable([]);

    private readonly isTwoStepEditablePromise = this.promisedComputed<boolean>(null, async () => {
        if (!this.selectedDetailForm?.definitionId) {
            return null;
        }

        const definition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(this.selectedDetailForm.definitionId);
        return definition.formEditBehavior === FormEditBehavior.TwoStepEditable;
    });

    @State.computed protected get contentToDirtyCheck() {
        return [this.selectedDetailForm?.data.Content];
    }

    @State.computed public get isTwoStepEditable() {
        return this.isTwoStepEditablePromise.get();
    }

    @State.computed public get showScreenAction(): null { 
        return null;
    }

    @State.observable private isReadOnlyInternal: boolean = null;

    @State.computed public get isReadOnly(): boolean {
        return this.isTwoStepEditable
            ? isNullOrUndefined(this.isReadOnlyInternal)
                ? !this.isNewDetail
                : this.isReadOnlyInternal
            : false;
    }

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("ICurrentCultureProvider") private readonly cultureCodeProvider: ICurrentCultureProvider,
        @Di.inject("IFormEngineApiAdapter") private readonly formEngineApiAdapter: IFormEngineApiAdapter,
        @Di.inject("IFormEngineReferenceDataStore") private readonly formEngineReferenceDataStore: IFormEngineReferenceDataStore,
    ) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    private getNewId(): FormInstanceId { return new FormInstanceId("new"); }

    private async getNewDetailItemAsync(formDefinitionId: FormDefinitionId = null): Promise<IForm> {
        const definitionId = formDefinitionId ?? await this.getDefinitionIdAsync();

        if (isNullOrUndefined(definitionId)) {
            this.props.onClose();
            return null;
        }

        const newFormResponse = await this.formEngineApiAdapter.getNewFormInstanceAsync(
            this.props.baseEntityType,
            this.props.baseEntityId,
            definitionId,
            this.props.scopes);

        const form = new Form(
            this.getNewId(),
            RowVersion.initial,
            definitionId,
            newFormResponse.value.data,
            []
        );

        return form;
    }

    @State.action.bound
    private async getDefinitionIdAsync(): Promise<FormDefinitionId> {
        if (!this.props.formDefinitionType) {
            return null;
        }

        const result = await this.formEngineApiAdapter.getFormDefinitionListAsync(this.props.baseEntityType, false);
        return result.value.items?.find(x => x.name === this.props.formDefinitionType)?.id;
    }

    @State.action.bound
    private async saveFormAsync(): Promise<ISaveResult<FormInstanceId, IForm>> {
        let result: SimpleStore<IForm>;

        if (this.isNewDetail) {
            result = await this.formEngineApiAdapter.createFormInstanceAsync(
                this.props.baseEntityType,
                this.props.baseEntityId,
                this.selectedDetailForm.definitionId,
                this.selectedDetailForm.data,
                this.props.scopes
            );
        } else {
            const detailFromServer = await this.formEngineApiAdapter.getFormInstanceDetailAsync(this.selectedId);

            result = await this.formEngineApiAdapter.updateFormInstanceAsync(
                this.selectedId,
                this.selectedDetailForm.definitionId,
                this.selectedDetailForm.data,
                this.props.scopes,
                detailFromServer.value.rowVersion
            );
        }

        if (result?.value &&
            !result.value.validationResults?.some(i => i?.problems?.some(j => j?.severity === "error")) &&
            this.isTwoStepEditable) {
            this.setIsReadOnlyInternal(true);
        }

        return {
            id: result.value?.id,
            isPersisted: result.isPersistedByOperationInfo,
            returnedStore: result.value,
            validationResults: result.value.validationResults
        };
    }

    public vGetReloadTriggerProps(props: IFormDetailPanelProps) {
        return [props.baseEntityId?.value, props.baseEntityType, props.selectedId?.value];
    }

    @State.action.bound
    public async validateAsync() {
        let result = null;
        if (this.isNewDetail) {
            result = await this.formEngineApiAdapter.validateNewFormInstanceAsync(
                this.props.baseEntityType,
                this.props.baseEntityId,
                this.selectedDetailForm.definitionId,
                this.selectedDetailForm.data,
                this.props.scopes
            );
        } else {
            result = await this.formEngineApiAdapter.validateExistingFormInstanceAsync(
                this.selectedId,
                this.selectedDetailForm.definitionId,
                this.selectedDetailForm.data,
                this.props.scopes,
                this.selectedDetailForm.rowVersion                
            );
        }

        replaceDetailContent(this.selectedDetailForm.data.Content, result.value.data.Content);

        this.setFormValidationErrors(result.value.validationResults);

        return result.value.validationResults;
    }

    @State.action.bound
    private setIsReadOnlyInternal(newValue: boolean) {
        this.isReadOnlyInternal = newValue;
    }

    @State.action.bound
    public onEditButtonClicked() {
        if (this.isTwoStepEditable) {
            this.setIsReadOnlyInternal(false);
        }
    }

    @State.observable.ref public selectedDetailForm: IForm | null;

    @State.computed public get selectedId(): FormInstanceId | null {
        return this.selectedDetailForm ? this.selectedDetailForm.id : null;
    }

    @State.computed public get isNewDetail(): boolean {
        return ValueWrapper.equals(this.selectedId, this.getNewId());
    }

    @State.bound
    public async loadCoreAsync() {
        if (isNullOrUndefined(this.props.selectedId)) {
            this.setDetailLoadedState(null);
            return;
        }

        if (!isNullOrUndefined(this.props.formDefinitionType) &&
            !isNullOrUndefined(this.props.baseEntityId) &&
            FormInstanceId.isNew(this.props.selectedId)) {
            const hasCreatableFormInstanceLeft = await this.formEngineApiAdapter.hasCreatableFormInstanceLeftAsync(this.props.formDefinitionType, this.props.baseEntityId.value);

            if (!hasCreatableFormInstanceLeft.value) {
                await this.dialogService.ok(StaticWebAppResources.Common.DialogTitle.WarningTitle, StaticFormEditingResources.FormDetailPanel.NoMoreInstanceLeftErrorMessage);
                this.props.onClose();
                return;
            }
        }

        if (FormInstanceId.isNew(this.props.selectedId)) {
            if (isNullOrUndefined(this.props.formDefinitionType)) {
                const definitionSelectorResult = await this.modalService.showDialogAsync<IFormDefinitionSelectorDialogResult>(new FormDefinitionSelectorDialogParams(
                    this.cultureCodeProvider,
                    this.formEngineApiAdapter,
                    this.props.baseEntityType,
                    this.props.formDefinitionDescriptors
                ));

                const definitionId = definitionSelectorResult?.formDefinitionId;

                if (isNullOrUndefined(definitionId)) {
                    this.props.onClose();
                    return;
                }

                this.setDetailLoadedState(await this.getNewDetailItemAsync(definitionId));
                return;
            }

            this.setDetailLoadedState(await this.getNewDetailItemAsync());
            return;
        }

        const detail = await this.formEngineApiAdapter.getFormInstanceDetailAsync(this.props.selectedId);
        this.setDetailLoadedState(detail.value);
    }

    @State.action.bound
    protected setDetailLoadedState(detail: IForm) {
        this.setSelectedDetailForm(detail);
        this.setIsReadOnlyInternal(null);

        if (!this.props.isReadOnly) {
            this.dirtyChecker.takeSnapshot(this.contentToDirtyCheck);
        }
    }

    @State.action.bound
    private setSelectedDetailForm(detail: IForm) {
        this.selectedDetailForm = detail;
    }

    @State.action.bound
    private setValidationResult(validationResults: IClientValidationResult[]) {
        this.selectedDetailForm.validationResults = validationResults;
    }

    @State.action.bound
    public readonly saveAsync = this.async(async () => {
        const saveResult = await this.saveFormAsync();
        this.notificationService.showSaveResult(saveResult.isPersisted, saveResult.hasWarning);

        if (!saveResult.isPersisted) {
            if (saveResult.validationResults) {
                this.setValidationResult(saveResult.validationResults);
            }
            return false;
        }

        this.dirtyChecker.setPersisted();
        this.props.onClose();

        await this.props.onSaveAsync();

        return true;
    });

    protected saveFunction: () => Promise<boolean> = this.saveAsync;

    @State.action.bound
    public readonly cancel = () => {
        this.setIsReadOnlyInternal(true);
    };

    @State.action.bound
    public setFormValidationErrors(validationResults: IClientValidationResult[]) {
        const selectedResults = validationResults[0]?.problems.filter(x => x.propertyPath.includes("FormValidationError")) ?? [];

        this.formValidationErrors = State.observable(selectedResults);
    }

    @State.action.bound
    public readonly deleteAsync = this.async(async () => {
        const message = StaticFormEditingResources.FormDetailPanel.DeleteConfirmationMessage;
        const title = StaticFormEditingResources.FormDetailPanel.ConfirmationTitle;
        const dialogResult = await this.dialogService.yesNo(title, message);

        if (dialogResult.resultCode === DialogResultCode.No) {
            return false;
        }

        await this.formEngineApiAdapter.deleteFormInstanceAsync(this.selectedId, this.selectedDetailForm.rowVersion);
        await this.props.onSaveAsync();
        return true;
    });
}
