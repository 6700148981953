import React from "react";
import IWidgetComponentProps from "@PluginInterface/Dashboard/IWidgetComponentProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import PatientsCareActivitiesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/PatientCareActivities/PatientsCareActivitiesApiAdapter";
import PatientId from "@Primitives/PatientId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PatientCareActivity from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/EHRWidget/PatientCareActivity";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PointOfCareSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareSelectBox";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import Styles from "./EHRWidget.less";
import CareActivitySource from "@Primitives/CareActivitySource.g";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import ConditionId from "@Primitives/ConditionId.g";
import ShowMoreOrLessItemsButton from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/RssReaderWidget/ShowMoreOrLessItemsButton";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import PatientsCareActivityDetailsModalParams from "./PatientsCareActivityDetailsModalParams";
import ListQueryOptions from "@Toolkit/CommonWeb/Model/ListQueryOptions";
import IListQueryOrderBy from "@Toolkit/CommonWeb/Model/IListQueryOrderBy";
import ScopeIdentifier from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ScopeIdentifier";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import CareActivityDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentApiAdapter";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import { DashboardContextAdapter } from "@CommonControls/Dashboard/DashboardContext";
import { DashboardMode } from "@CommonControls/Dashboard/DashboardMode";

interface IEHRWidgetDependencies {
    apiAdapter: PatientsCareActivitiesApiAdapter;
    careDocumentApiAdapter: CareActivityDocumentApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IEHRWidgetProps extends IWidgetComponentProps {
    _dependencies?: IEHRWidgetDependencies;
    _patientId?: PatientId;
    _modalService?: IModalService;
}

const defaultLimit = 3;

/** @screen */
@State.observer
class EHRWidget extends React.Component<IEHRWidgetProps> {

    private get modalService() { return this.props._modalService!; }

    @State.observable.ref private patientId: PatientId;
    @State.observable.ref private searchRange: LocalDateRange = LocalDateRange.Unbound;
    @State.observable.ref private pointOfCareId: PointOfCareId;
    @State.observable.ref private patientsCareActivityStores: PatientsCareActivityStore[] = [];
    @State.observable.ref private selectedCareActivity: PatientsCareActivityStore;
    private limit = defaultLimit;

    @State.bound
    private async loadReferenceDataAsync(conditionVersionSelectors: Array<IEntityVersionSelector<ConditionId>>) {
        await this.props._dependencies.careReferenceDataStore.condition.ensureLoadedAsync(conditionVersionSelectors);
        await this.props._dependencies.organizationReferenceDataStore.allPointsOfCareMap.ensureAllLoadedAsync();
    }

    @State.action.bound
    private async loadAsync() {
        if (!this.patientId) {
            await State.when(() => !!this.props._patientId);
            State.runInAction(() => {
                this.patientId = this.props._patientId;
            });
        }
        const pointOfCareArray = [];
        if (this.pointOfCareId) {
            pointOfCareArray.push(this.pointOfCareId);
        }
        const response = await this.props._dependencies.apiAdapter.searchPatientCareActivities(this.patientId,
            null,
            null,
            pointOfCareArray,
            this.searchRange,
            CareActivitySource.InHouse,
            [],
            [],
            "",
            ListQueryOptions.create({
                pageSize: this.limit,
                currentPage: 0,
                orderBy: [{ ascending: false, fieldName: "carePeriod" } as IListQueryOrderBy],
            }));

        const conditionsIds = response.value.results.map(item => {
            return item.diagnoses && item.diagnoses.map(diagnosis => {
                return diagnosis.conditionVersionSelector;
            }) || [];
        });

        let ids = [] as Array<IEntityVersionSelector<ConditionId>>;
        conditionsIds.forEach(item => {
            ids = ids.concat(item);
        });

        await this.loadReferenceDataAsync(ids);
        await this.loadPrimaryDocumentsInfoAsync(response.value.results);

        State.runInAction(() => {
            this.patientsCareActivityStores = response.value.results;
        });
    }

    @State.bound
    private async loadPrimaryDocumentsInfoAsync(items: PatientsCareActivityStore[]) {
        for (const i of items) {
            const doc = await this.props._dependencies.careDocumentApiAdapter.getSingleDocumentForCareActivityAsync(i.careActivityId, null, true, false);

            if (doc.value?.id) {
                i.primaryDocumentId = doc.value.id;
            }
        }
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);
    public componentDidMount(): void {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.action.bound
    private showPatientsCareActivityDetailsModal(selected: PatientsCareActivityStore, showPrimaryDocument: boolean = false) {
        this.selectedCareActivity = selected;
        this.modalService.showModal(new PatientsCareActivityDetailsModalParams(
            this.selectedCareActivity,
            this.patientId,
            showPrimaryDocument
        ));
    }

    @State.bound
    private openPrimaryDocument(selected: PatientsCareActivityStore) {
        this.showPatientsCareActivityDetailsModal(selected, true);
    }

    @State.action.bound
    private showMoreItems() {
        this.limit += 10;
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.action.bound
    private setRange(newValue: LocalDateRange) {
        if (newValue) {
            this.searchRange = newValue;
        } else {
            this.searchRange = LocalDateRange.Unbound;
        }
    }

    @State.action.bound
    private setPointOfCare(newValue: PointOfCareId) {
        this.pointOfCareId = newValue;
        this.limit = defaultLimit;
        this.loadAsync();
    }

    public renderContent() {
        return (
            <>
                {this.props._mode === "normal" && (
                    <Ui.Flex noWrap={true} className={Styles.reloadButtonFlex}>
                        <PointOfCareSelectBox
                            label={StaticProductivityResources.Widgets.EHRWidget.PointOfCare}
                            value={this.pointOfCareId}
                            onChange={this.setPointOfCare}
                            visualMode="dark"
                            size="standard"
                            automationId="pointOfCareSelectBox"
                        />
                        <Ui.Flex.Item className={Styles.dateRangeFlex}>
                            <Ui.DateRangePicker
                                size="standard"
                                value={this.searchRange}
                                onChange={this.setRange}
                                label={StaticProductivityResources.Widgets.EHRWidget.SearchRange}
                                visualMode="dark"
                                clearable={false}
                                automationId="searchRangeDateRangePicker"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item className={Styles.reloadButtonFlex}>
                            <Ui.Button
                                onClickAsync={this.loadAsync}
                                iconName={"sync"}
                                className={Styles.reloadButton}
                                visualStyle="secondary"
                                visualMode="dark"
                                automationId="reloadButton"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                )}
                {
                    this.patientsCareActivityStores &&
                    this.patientsCareActivityStores.map((item, key) => {
                        return (
                            <PatientCareActivity
                                key={key}
                                item={item}
                                careReferenceStore={this.props._dependencies.careReferenceDataStore}
                                organizationReferenceStore={this.props._dependencies.organizationReferenceDataStore}
                                openModal={this.showPatientsCareActivityDetailsModal}
                                openPrimaryDocument={this.openPrimaryDocument} />);
                    })
                }
                {
                    this.limit <= this.patientsCareActivityStores.length && (
                        <ShowMoreOrLessItemsButton onClick={this.showMoreItems}
                            content={<Ui.Icon iconName="chevronDown" visualStyle="white" />}
                            className={Styles.moreButton}
                        />)
                }
            </>
        );
    }

    public render() {
        return (
            <Ui.Dashboard.Widget
                name={this.props.name}
                title={StaticProductivityResources.Widgets.EHRWidget.Title}
                isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                isCollapsible
                containerHeight={this.props.configuration.InitialLayout.Height}
                icon={"road"}
                automationId="EHRWidget"
            >
                <>
                    {this.initialLoadPanelAsync.isUnauthorizedAccess ?
                        <UnauthorizedAccessContent visualStyle="dark" /> :
                        this.renderContent()
                    }
                </>
            </Ui.Dashboard.Widget>
        );
    }
}

export default connect(
    withLoadingBoundary(withHisErrorBoundary(EHRWidget)),
    new DependencyAdapter<IEHRWidgetProps, IEHRWidgetDependencies>(c => ({
        apiAdapter: c.resolve("PatientsCareActivitiesApiAdapter"),
        careDocumentApiAdapter: c.resolve("CareActivityDocumentApiAdapter"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
    })),
    new PatientContextAdapter<IEHRWidgetProps>(c => ({
        _patientId: c.patientId
    })),
    new HisModalServiceAdapter(),
    new DashboardContextAdapter<IEHRWidgetProps>(d => ({
        _mode: d.mode
    })),
);
