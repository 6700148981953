import Badge from "@CommonControls/Badge";
import NameStore from "@Primitives/NameStore";
import IName from "@Toolkit/CommonWeb/Model/IName";
import { ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import React, { useMemo } from "react";
import Styles from "./Monogram.less";

interface IMonogramProps extends ICommonControlProps {
    value: string | IName | NameStore;
    gender: "male" | "female";
    size?: "normal" | "small"
}

export default function Monogram(props: IMonogramProps) {
    const { toolkitLocalizationService } = useDependencies(c => ({
        toolkitLocalizationService: c.resolve<IToolkitLocalizationService>("IToolkitLocalizationService"),
    }));

    const text = useMemo(() => {
        if (typeof props.value === "string") {
            return props.value;
        }

        if (typeof props.value === "object") {
            return toolkitLocalizationService.localizeNameMonogram(props.value);
        }

        return "";

    }, [props.value]);

    const className = useMemo(() => {
        const classes = new CompositeClassName(Styles.circle, props.className);
        classes.addIf(props.size === "small", Styles.small);
        return classes.classNames;
    }, [props.size, props.className]);

    return (
        <Badge
            {...props}
            shape="circle"
            text={text}
            className={className}
            style={{ ...props.style, backgroundColor: props.gender === "male" ? "#00B4EC" : "#E3A0B2" }}
            upperCase
        />
    );
}