import Di from "@Di";
import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import HunFinanceExtensibleEnumAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/FinanceExtensibleEnumAdapter";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import WellKnownReferenceCodes from "@HunSocialSecurityPlugin/Common/WellKnownReferenceCodes";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";

@Di.injectable()
export default class HunFinanceReferenceDataStore extends ReferenceDataStoreBase {

    public accidentTypeMap = new ExtensibleEnumStore(this.financeApiAdapter.getAccidentTypeIdsAsync, this.localizationService.localizeEnumId);
    public financingClassMap = new ExtensibleEnumStore(this.financeApiAdapter.getFinancingClassIdsAsync, this.localizationService.localizeEnumId);
    public identifierTypeMap = new ExtensibleEnumStore(this.financeApiAdapter.getIdentifierTypeIdsAsync, this.localizationService.localizeEnumId);

    public financedServiceLimitCondition = new ExtensibleEnumStore(this.financeApiAdapter.getFinancedServiceLimitConditionIdsAsync, this.localizationService.localizeEnumId);
    
    public hunSocialSecurityInsuranceCodeHealthcareProfessions = new ReferenceDataStore(this.structureApiAdapter.getHealthCareProfessionsByIdsAsync, () => this.structureApiAdapter.getHealthcareProfessionIdsByIdentifierSystemIdAsync(new IdentifierSystemId(WellKnownReferenceCodes.HunSocialSecurityInsuranceCode)));

    constructor(
        @Di.inject("HunFinanceExtensibleEnumAdapter") private readonly financeApiAdapter: HunFinanceExtensibleEnumAdapter,
        @Di.inject("StructureApiAdapter") private readonly structureApiAdapter: StructureApiAdapter,
        @Di.inject("ILocalizationService") private localizationService: ILocalizationService,
    ) {
        super();
    }
}
