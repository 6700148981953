import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React from "react";
import IPatientCareActivitiesTabProps from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabProps";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import DetachedScreenRenderer from "@HisPlatform/Components/ShowScreenAction/DetachedScreenRenderer";
import ShowReadOnlyCareActivityDocumentListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyCareActivityDocumentListScreenAction.g";

function WrappedEhrDocumentManagementMasterDetailPanel(props: IPatientCareActivitiesTabProps) {

    const action =
        new ShowReadOnlyCareActivityDocumentListScreenAction(
            ScreenDisplayMode.ContentOnly,
            props.careActivityId);

    return (
        <DetachedScreenRenderer
            primaryAction={action}
            defaultPrimaryState="null"
        />
    );
}

export default connect<IPatientCareActivitiesTabProps>(
    WrappedEhrDocumentManagementMasterDetailPanel
);