import PointOfCareId from "@Primitives/PointOfCareId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StoreBase from "@Toolkit/CommonWeb/Model/StoreBase";
import CareActivityBaseData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareActivityBaseData";

export default class AdjustedCareActivityBaseDataStore extends StoreBase {
    @State.observable.ref public originalPointOfCareId: PointOfCareId;
    @State.observable.ref public adjustedCareActivityBaseData: CareActivityBaseData;

    @State.action.bound
    public setOriginalPointOfCareId(value: PointOfCareId) {
        this.originalPointOfCareId = value;
    }

    @State.action.bound
    public setAdjustedCareActivityBaseData(value: CareActivityBaseData) {
        this.adjustedCareActivityBaseData = value;
    }
}
