import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ConditionsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ConditionsApiAdapter";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import ConditionId from "@Primitives/ConditionId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import * as HisUi from "@HisPlatformControls";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import { IConditionListItem } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IConditionListItem";
import { IUniversalCodeSelectorProps } from "@HisPlatformControls/UniversalCodeSelector";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import EntityCollectionOwner from "@HisPlatform/BoundedContexts/Productivity/Api/Personalization/Enum/EntityCollectionOwner.g";

interface IConditionCodeSelectorDependencies {
    conditionsApiAdapter: ConditionsApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
}

interface IConditionCodeSelectorProps extends ICodeSelectorCommonProps<ConditionId> {
    _dependencies?: IConditionCodeSelectorDependencies;
    filters?: FilterBase[];
    showFavoritesAndGroup?: boolean;
    pointOfCareId?: PointOfCareId;
}

@State.observer
class ConditionCodeSelector extends React.Component<IConditionCodeSelectorProps> {

    private get dependencies() {
        return this.props._dependencies;
    }

    @State.bound
    private async getDisplayValueAsync(value: ConditionId) {
        const item = await this.dependencies.careReferenceDataStore.condition.getOrLoadAsync({
            id: value,
            validOn: this.props.validOn ?? LocalDate.today()
        }, true);

        return <><b>{item.code}</b>{` ${item.name}`}</>;
    }

    @State.bound
    private async getTextValueAsync(value: ConditionId) {
        const item = await this.dependencies.careReferenceDataStore.condition.getOrLoadAsync({
            id: value,
            validOn: this.props.validOn ?? LocalDate.today()
        }, true);

        return `${item.code} ${item.name}`;
    }

    @State.bound
    private getDisplayValue(value: ConditionId) {
        const item = this.dependencies.careReferenceDataStore.condition.get({
            id: value,
            validOn: this.props.validOn ?? LocalDate.today()
        }, true);

        return (<> <b>{item?.code}</b> {item?.name}</>);
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const result = await this.dependencies.conditionsApiAdapter.searchConditionsByCode(
            text,
            10,
            this.props.validOn ?? LocalDate.today(),
            this.props.filters
        );

        if (result.operationWasSuccessful) {
            const ids = result.value.map(r => r.id);

            await this.dependencies.careReferenceDataStore.condition.ensureLoadedAsync(ids.map(id => ({
                id,
                validOn: this.props.validOn ?? LocalDate.today()
            } as IEntityVersionSelector<ConditionId>)));

            return ids;
        }

        return null;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: IConditionListItem) {
        this.props.onChange(item.id, this.props.validOn);
    }

    @State.bound
    private onComplexSearchMultiSelect(items: IConditionListItem[]) {
        this.props.onChange(items.map(item => item.id));
    }

    @State.bound
    private getComplexSearchEntitiesByIds(ids: ConditionId[]): IConditionListItem[] {
        const today = this.props.validOn ?? LocalDate.today();
        const selectors = ids.map(item => {
            return { id: item, validOn: today } as IEntityVersionSelector<ConditionId>;
        });
        return this.dependencies.careReferenceDataStore.condition.getAll(selectors);
    }

    private get entityCollectionProps(): Partial<IUniversalCodeSelectorProps<ConditionId, IConditionListItem>> {
        return this.props.showFavoritesAndGroup && {
            entityName: "Condition",
            ownerType: EntityCollectionOwner.PointOfCare,
            ownerId: this.props.pointOfCareId,
            currentPointOfCareId: this.props.pointOfCareId,
            getEntityDisplayValue: this.getDisplayValue,
            getEntityId: (id: ConditionId) => id
        };
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: IConditionListItem[]): Promise<IPagedItems<IConditionListItem>> {
        const today = this.props.validOn ?? LocalDate.today();

        const results = await this.dependencies.conditionsApiAdapter.searchConditions(
            filterText,
            today,
            ordering,
            paging,
            this.props.filters
        );

        return {
            items: results.value.values,
            totalCount: results.value.totalCount
        } as IPagedItems<IConditionListItem>;
    }

    public render() {
        return (
            <HisUi.UniversalCodeSelector
                {...this.props}
                {...this.entityCollectionProps}
                getDisplayValueAsync={this.getDisplayValueAsync}
                getTextValueAsync={this.getTextValueAsync}
                onQuickSearchAsync={this.quickSearchAsync}
                hasComplexSearch
                complexSearchLoadAsync={this.complexSearchLoadAsync}
                complexSearchModalTitle={StaticCareResources.ReferenceData.Conditions.CodeSelectorComplexSearchTitle}
                codeGetter={"code"}
                nameGetter={"name"}
                getComplexSearchEntitiesByIds={this.getComplexSearchEntitiesByIds}
                onComplexSearchSingleSelect={this.onComplexSearchSingleSelect}
                onComplexSearchMultiSelect={this.onComplexSearchMultiSelect}
                showMenuSections={this.props.showFavoritesAndGroup}
            />
        );
    }
}

export default connect(
    ConditionCodeSelector,
    new DependencyAdapter<IConditionCodeSelectorProps, IConditionCodeSelectorDependencies>(container => {
        return {
            conditionsApiAdapter: container.resolve("ConditionsApiAdapter"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore")
        };
    })
);
