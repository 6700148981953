import FormFieldDataBase from "./FormFieldDataBase";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

export default class DateArrayFormFieldData extends FormFieldDataBase {
    public isArray: boolean = true;
    @State.observable.ref public value: IObservableArray<LocalDate> = State.createObservableShallowArray([]);

    constructor(fieldName: string, isReadonly: boolean, isVisible: boolean, value: IObservableArray<LocalDate>) {
        super(fieldName, isReadonly, isVisible);

        this.value = value;
    }
}