import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { buildQueryStringArray } from "@Toolkit/CommonWeb/QueryStringBuilder";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import _ from "@HisPlatform/Common/Lodash";
import ProfessionalExamStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/ProfessionalExamStore";
import ProfessionalExamsStoreMapper from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/ReferenceData/ProfessionalExamsStoreMapper";
import ProfessionalExamId from "@Primitives/ProfessionalExamId.g";

@Di.injectable()
export default class OrganizationReferenceDataApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IOrganizationReferenceDataClient") private readonly apiClient: Proxy.IOrganizationReferenceDataClient,
        @Di.inject("ProfessionalExamsStoreMapper") private readonly professionsStoreMapper: ProfessionalExamsStoreMapper
    ) {
        super();
    }

    @State.bound
    public getAllProfessionalExamIds(): Promise<SimpleStore<ProfessionalExamId[]>> {
        return this.processOperationAsync(
            new SimpleStore<ProfessionalExamId[]>(),
            async target => {
                const response = await this.apiClient.getAllProfessionalExamIdsQueryAsync(
                    CreateRequestId(),
                    null
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.professionalExamIds;
            }
        );
    }

    @State.bound
    public getProfessionalExamsByIdsAsync(ids: ProfessionalExamId[]): Promise<SimpleStore<ProfessionalExamStore[]>> {
        return this.processOperationAsync(
            new SimpleStore<ProfessionalExamStore[]>(),
            async target => {
                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value));
                const response = await this.apiClient.getProfessionalExamsByIdsQueryAsync(CreateRequestId(),
                    buildQueryStringArray(normalizedIds));

                target.operationInfo = createOperationInfo(response);
                target.value = response.professionalExams.map((item) => {
                    const newStore = new ProfessionalExamStore();
                    this.professionsStoreMapper.applyToStore(newStore, item);
                    return newStore;
                });
            }
        );
    }
}
