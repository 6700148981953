import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IDocumentManagementResources from "./IDocumentManagementResources";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";

const resources = createStaticResourceDictionary<IDocumentManagementResources>();

export function loadStaticDocumentManagementResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    resources.load(localizationService.getResourceGroup("WebApp").DocumentManagement);
}

export default resources;