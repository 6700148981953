import AppointmentScheduleDefinition from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentScheduleDefinition";
import * as Proxy from "@HisPlatform/BoundedContexts/Scheduling/Api/Proxy.g";
import AppointmentScheduleDefinitionId from "@Primitives/AppointmentScheduleDefinitionId.g";
import PlanningPeriod from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/PlanningPeriod";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import TimePhaseStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseStore";
import TimePhaseType from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseType";
import RecurrenceType from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/RecurrenceType";
import TimePhaseInterval from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseInterval";
import TimePhaseRecurrenceElement from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseRecurrenceElement";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

export function CreateCreateAppointmentScheduleDefinitionControllerDto(store: AppointmentScheduleDefinition, isValidateOnly: boolean) {
    return new Proxy.CreateAppointmentScheduleDefinitionControllerDto({
        appointmentScheduleDefinitionCreationItem: new Proxy.AppointmentScheduleDefinitionCreationItemDto({
            description: store.description,
            name: store.name,
            planningPeriodInterval: new Proxy.DateIntervalDto({
                from: store.selectedPlanningPeriod?.interval?.from,
                to: store.selectedPlanningPeriod?.interval?.to
            })
        }),
        extensionData: store.extensionData,
        isValidateOnly: isValidateOnly
    });
}

export function CreateUpdateAppointmentScheduleDefinitionControllerDto(store: AppointmentScheduleDefinition, isValidateOnly: boolean, releaseLock: boolean) {
    return new Proxy.UpdateAppointmentScheduleDefinitionControllerDto({
        appointmentScheduleDefinitionToUpdate: new Proxy.AppointmentScheduleDefinitionUpdateItemDto({
            description: store.description,
            name: store.name,
            id: store.id,
            rowVersion: store.rowVersion,
            planningPeriodsToAdd: store.planningPeriods.filter(item => !item.id && !item.isDeleted).map(mapCreatePlanningPeriod) || [],
            planningPeriodsToDelete: store.planningPeriods.filter(item => item.isDeleted && item.id).map(item => item.id) || [],
            planningPeriodsToUpdate: store.planningPeriods.filter(item => !item.isDeleted && item.id && item.isDirty()).map(mapUpdatePlanningPeriod) || [],
            planningPeriodsUnchanged: store.planningPeriods.filter(item => !item.isDeleted && item.id && !item.isDirty()).map(mapPlanningPeriod) || []
        }),
        extensionData: store.extensionData,
        isValidateOnly: isValidateOnly,
        lockId: store.lockInfo.lockId,
        releaseLockIfSuccessful: releaseLock
    });
}

function mapCreatePlanningPeriod(item: PlanningPeriod) {
    return new Proxy.PlanningPeriodCreationItemDto({
        interval: new Proxy.DateIntervalDto({
            from: item.interval?.from,
            to: item.interval?.to
        }),
        timePhasesToAdd: item.timePhases.map(mapCreateTimePhase) || []
    });
}

function mapUpdatePlanningPeriod(item: PlanningPeriod) {
    return new Proxy.PlanningPeriodUpdateItemDto({
        interval: new Proxy.DateIntervalDto({
            from: item.interval?.from,
            to: item.interval?.to
        }),
        timePhasesToAdd: item.timePhases.filter(timePhase => timePhase.isNew && !timePhase.isDeleted).map(mapCreateTimePhase) || [],
        id: item.id,
        timePhasesToDelete: item.timePhases.filter(timePhase => timePhase.isDeleted && !timePhase.isNew).map(timePhase => timePhase.id) || [],
        timePhasesToUpdate: item.timePhases.filter(timePhase => !timePhase.isDeleted && !timePhase.isNew && item.isDirty()).map(mapUpdateTimePhase) || []
    });
}


function mapPlanningPeriod(item: PlanningPeriod) {
    return new Proxy.PlanningPeriodDto({
        interval: new Proxy.DateIntervalDto({
            from: item.interval?.from,
            to: item.interval?.to
        }),
        timePhases: item.timePhases.map(mapTimePhase) || [],
        id: item.id
    });
}

function mapCreateTimePhase(item: TimePhaseStore) {
    const resources = mapResources(item);
    return new Proxy.TimePhaseCreationItemDto({
        allowedServiceRequestDefinitions: item.allowedServiceRequestDefinitions?.map(srd => srd.id) ?? [],
        allowedServices: isNullOrUndefined(item.allowedSchedulingServices) ? [] : item.allowedSchedulingServices,
        resources: resources,
        parallelCapacity: item.parallelCapacity,
        slotSizeInMinutes: item.slotSizeInMinutes,
        occurrence: mapTimePhaseOccurrence(item)
    });
}

function mapUpdateTimePhase(item: TimePhaseStore) {
    const resources = mapResources(item);
    return new Proxy.TimePhaseUpdateItemDto({
        id: item.id,
        allowedServiceRequestDefinitions: item.allowedServiceRequestDefinitions?.map(srd => srd.id) ?? [],
        allowedServices: isNullOrUndefined(item.allowedSchedulingServices) ? [] : item.allowedSchedulingServices,
        resources: resources,
        parallelCapacity: item.parallelCapacity,
        slotSizeInMinutes: item.slotSizeInMinutes,
        occurrence: mapTimePhaseOccurrence(item)
    });
}

function mapTimePhase(item: TimePhaseStore) {
    const resources = mapResources(item);
    return new Proxy.TimePhaseDto({
        id: item.id,
        allowedServiceRequestDefinitions: item.allowedServiceRequestDefinitions?.map(srd => srd.id) ?? [],
        allowedServices: isNullOrUndefined(item.allowedSchedulingServices) ? [] : item.allowedSchedulingServices,
        resources: resources,
        parallelCapacity: item.parallelCapacity,
        slotSizeInMinutes: item.slotSizeInMinutes,
        occurrence: mapTimePhaseOccurrence(item),
        createdAt: DateTimeService.now()
    });
}

function mapTimePhaseOccurrence(item: TimePhaseStore) {
    if (item.type === TimePhaseType.Single) {
        return new Proxy.StandaloneOccurrenceDto({
            
            interval: item.singleOccurrenceInterval
                ? new Proxy.OccurrenceIntervalDto({
                    from: item.singleOccurrenceInterval.from,
                    to: item.singleOccurrenceInterval.to
                })
                : null
        });
    }

    if (item.type === TimePhaseType.Recurring) {
        return new Proxy.RecurringOccurrenceDto({
            
            element: getRecurrenceElementDto(item)
        });
    }

    return null;
}

function getRecurrenceElementDto(store: TimePhaseStore) {
    const intervalDto = getTimePhaseIntervalDto(store.interval);
    const types = store.recurrenceElements.map(e => getRecurrenceTypeDto(e, intervalDto));
    if (types.length === 1) {
        return types[0];
    }
    if (types.length > 1) {
        return new Proxy.UnionRecurrenceOperationDto({  elements: types });
    }
    throw new Error(`${"TimePhaseStore"} store has no recurrence elements.`);
}

function getTimePhaseIntervalDto(interval: TimePhaseInterval) {
    return new Proxy.SemiOpenLocalTimeIntervalDto({
        from: !!interval?.start ? new Proxy.LocalTimeDto({ hour: interval.start.hours, minute: interval.start.minutes }) : null,
        to: !!interval?.end ? new Proxy.LocalTimeDto({ hour: interval.end.hours, minute: interval.end.minutes }) : null
    });
}

function getRecurrenceTypeDto(element: TimePhaseRecurrenceElement, intervalDto: Proxy.SemiOpenLocalTimeIntervalDto) {
    switch (element.recurrenceType) {
        case RecurrenceType.EvenOdd:
            return new Proxy.EvenOddRecurrenceTypeDto({
                
                isEvenWeek: element.isEvenWeek,
                day: element.dayOfWeek,
                interval: intervalDto
            });
        case RecurrenceType.DayOfMonth:
            return new Proxy.DayOfMonthRecurrenceTypeDto({
                
                week: element.weekOfMonth,
                day: element.dayOfWeek,
                interval: intervalDto
            });
        case RecurrenceType.DayOfWeek:
            return new Proxy.DayOfWeekRecurrenceTypeDto({
                
                day: element.dayOfWeek,
                interval: intervalDto
            });
    }
}

function mapResources(item: TimePhaseStore) {
    const result: Proxy.Resource[] = [];

    if (item.organizationUnitResources && item.organizationUnitResources.length) {
        result.push(...item.organizationUnitResources.map(o => new Proxy.OrganizationUnitResource({
            
            organizationUnitId: o.id
        })));
    }

    if (item.practitionerResources && item.practitionerResources.length) {
        result.push(...item.practitionerResources.map(p => new Proxy.PractitionerResource({
            
            practitionerId: p.id
        })));
    }

    return result;
}

export function CreateDeleteAppointmentScheduleDefinitionControllerDto(store: AppointmentScheduleDefinition) {
    return new Proxy.DeleteAppointmentScheduleDefinitionControllerDto({
        appointmentScheduleDefinitionId: store.id,
        rowVersion: store.rowVersion,
        lockId: store.lockInfo.lockId,
        releaseLockIfSuccessful: false,
    });
}

export function CreateGetAppointmentScheduleDefinitionControllerDto(id: AppointmentScheduleDefinitionId) {
    return new Proxy.GetAppointmentScheduleDefinitionControllerDto({
        appointmentScheduleDefinitionId: id,
        requestLock: true
    });
}

export function CreateCreateSchedulingServiceControllerDto(store: SchedulingServiceSubjectStore, lockInfo: LockInfo, isValidateOnly?: boolean) {
    return new Proxy.CreateSchedulingServiceControllerDto({
        owner: store.owner,
        schedulingServiceToAdd: new Proxy.SchedulingServiceCreationItemDto({
            name: store.name,
            code: store.code,
            isTelemedicineConsultation: store.isTelemedicineConsultation,
            durationInMinutes: store.durationInMinutes,
            careLocationId: store.careLocationId,
            careLocationDescription: store.careLocationDescription,
            participantOptions: store.participantOptions
        }),
        isValidateOnly: isValidateOnly,
        lockId: lockInfo.lockId,
        releaseLockIfSuccessful: false
    });
}

export function CreateUpdateSchedulingServiceControllerDto(store: SchedulingServiceSubjectStore, lockInfo: LockInfo, isValidateOnly?: boolean) {
    return new Proxy.UpdateSchedulingServiceControllerDto({
        owner: store.owner,
        schedulingServiceToUpdate: new Proxy.SchedulingServiceDto({
            id: store.id,
            name: store.name,
            code: store.code,
            isTelemedicineConsultation: store.isTelemedicineConsultation,
            durationInMinutes: store.durationInMinutes,
            careLocationId: store.careLocationId,
            careLocationDescription: store.careLocationDescription,
            rowVersion: store.rowVersion,
            participantOptions: store.participantOptions
        }),
        isValidateOnly: isValidateOnly,
        lockId: lockInfo.lockId,
        releaseLockIfSuccessful: false
    });
}

export function CreateDeleteSchedulingServiceControllerDto(store: SchedulingServiceSubjectStore, lockInfo: LockInfo) {
    return new Proxy.DeleteSchedulingServiceControllerDto({
        owner: store.owner,
        schedulingServiceToDelete: store.id,
        rowVersion: store.rowVersion,
        releaseLockIfSuccessful: false,
        lockId: lockInfo.lockId
    });
}

export function CreateGetMaterializedTimePhases(store: PlanningPeriod) {
    return new Proxy.GetMaterializedTimePhasesControllerDto({
        interval: new Proxy.DateIntervalDto({
            from: store.interval?.from,
            to: store.interval?.to
        }),
        materializedTimePhaseQueryItems: store.timePhases.filter(item => !item.isDeleted).map((item, index) => materializedTimePhaseQueryItems(item, index))
    });
}

function materializedTimePhaseQueryItems(item: TimePhaseStore, index: number) {
    return new Proxy.MaterializedTimePhaseQueryItemDto({
        id: item.id ? parseInt(item.id.value, 10) : -1,
        occurrence: mapTimePhaseOccurrence(item)
    });
}