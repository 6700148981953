import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";

interface IAbnormalityIndicatorProps {
    row?: RequestedServiceStore;
}

function AbnormalityIndicator(props: IAbnormalityIndicatorProps): React.ReactElement {
    const scalarObservation = props.row.scalarObservation;
    const scalarObservationDefinition = props.row.scalarObservationDefinition;

    if (
        scalarObservation &&
        scalarObservationDefinition &&
        scalarObservation.quantity &&
        !isNullOrUndefined(scalarObservation.quantity.amount)
    ) {
        const high = isNullOrUndefined(scalarObservationDefinition.highReferenceBound) ? Number.MAX_VALUE : scalarObservationDefinition.highReferenceBound;
        const low = isNullOrUndefined(scalarObservationDefinition.lowReferenceBound) ? Number.MIN_VALUE : scalarObservationDefinition.lowReferenceBound;

        return (
            scalarObservation.quantity.amount > high ||
            scalarObservation.quantity.amount < low
        ) ? <>+</> : null;
    }

    return null;
}

export default State.observer(AbnormalityIndicator);