import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import Di from "@Di";
import MomentRange from "@Toolkit/CommonWeb/Model/MomentRange";
import DateWithTimeRange from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/DateWithTimeRange";
import ResourceBlocking from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ResourceBlocking";
import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import ResourceBlockingId from "@Primitives/ResourceBlockingId.g";

type ResponseType =
    Proxy.CreateResourceBlockingControllerDto
    | Proxy.CreateResourceBlockingCommandResponse
    | Proxy.ModifyResourceBlockingCommandResponse;

@Di.injectable()
export default class ResourceBlockingStoreMapper extends EntityStoreMapper<Proxy.ResourceBlockingDto, ResourceBlocking, ResponseType> {
    protected entityDtoSelector(response: ResponseType) {
        if (response instanceof Proxy.ResourceBlockingDto) {
            return response;
        } else if (response instanceof Proxy.CreateResourceBlockingCommandResponse) {
            return response.resourceBlocking;
        } else if (response instanceof Proxy.ModifyResourceBlockingCommandResponse) {
            return response.resourceBlocking;
        }
        return null;
    }

    protected applyToStoreCore(target: ResourceBlocking, response: any): void {
        const dto = this.entityDtoSelector(response);
        if (!dto) {
            return;
        }

        target.id = dto.id;
        target.rowVersion = dto.rowVersion;
        target.resource = this.mapResource(dto.resource);

        target.description = dto.description;
        target.dateWithTimeRange = new DateWithTimeRange(new MomentRange(dto.blockingInterval.from, dto.blockingInterval.to));
    }

    private mapResource(resource: Proxy.Resource) {
        if (resource instanceof Proxy.OrganizationUnitResource) {
            return resource.organizationUnitId;
        } else if (resource instanceof Proxy.PractitionerResource) {
            return resource.practitionerId;
        }

        return null;
    }

    protected storeEntityIdType = ResourceBlockingId;
}
