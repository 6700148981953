import FormDataElementBase from "./FormDataElementBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class ReferencedEnumFormDataElement extends FormDataElementBase {

    @State.observable.ref public enumName: string;

    constructor(
        name: string,
        isArray: boolean,
        defaultValue: number,
        isReadOnly: boolean,
        isActive: boolean,
        enumName?: string
        
    ) {
        super(name, isArray, defaultValue, isReadOnly, isActive);
        this.enumName = enumName;
    }

    @State.action.bound
    public setEnum(enumName: string) {
        this.enumName = enumName;
    }

    public formDataElementType = "ReferencedEnum";
}