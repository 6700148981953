import React from "react";
import Flex from "@CommonControls/Flex";
import FormLayoutRowColumn from "./FormLayoutRowColumn";
import FormLayoutRowBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutRowBlockStore";
import { useFormPanelStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanelStoreProvider";
import { useFormLayoutContext } from "@Toolkit/FormEngine/Panels/FormLayoutContext";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { hasVisibleItemInTreeBelow } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FormSchemaReference from "@Toolkit/FormEngine/Model/Schema/FormSchemaReference";

function FormLayoutRowBlock(props: { block: FormLayoutRowBlockStore, cultureCode: string, schemaReference: FormSchemaReference }) {
    const store = useFormPanelStore();
    const layoutContext = useFormLayoutContext();

    const compositeDataReferences = isNullOrUndefined(layoutContext?.compositeDataReferences) ? null : layoutContext?.compositeDataReferences;
    const parentCompositeDataReference = isNullOrUndefined(compositeDataReferences) ? null : compositeDataReferences.join(".");

    const isVisible = hasVisibleItemInTreeBelow(props.block, store.props.form.data.Content, parentCompositeDataReference);
    if (!isVisible) {
        return (
            <></>
        );
    }

    return (
        <Flex>
            {props.block.content.map((c, idx) => <FormLayoutRowColumn cultureCode={props.cultureCode} column={c} key={idx} schemaReference={props.schemaReference} />)}
        </Flex>
    );
}

export default State.observer(FormLayoutRowBlock);