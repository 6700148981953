import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import { IStandaloneDocumentListRouteParams } from "@HisPlatform/Components/Pages/OutpatientTreatment/IStandaloneDocumentRouteParams";
import CareDocumentId from "@Primitives/CareDocumentId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import OutpatientTreatmentRoutes from "@HisPlatform/Components/Pages/OutpatientTreatment/OutpatientTreatmentRoutes";
import DocumentManagementMasterDetailPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/DocumentManagementMasterDetailPanel/DocumentManagementMasterDetailPanel";


interface IDocumentListStandalonePageProps extends IRoutingFrameContentProps {
}

@State.observer
class DocumentListStandalonePage extends React.Component<IDocumentListStandalonePageProps> {

    private get selectedDocumentId() {
        const selectedDocumentId = (this.props.routingController.currentRoute.parameters as IStandaloneDocumentListRouteParams)?.selectedDocumentId;
        if (!!selectedDocumentId) {
            return new CareDocumentId(selectedDocumentId);
        }
        return null;
    }

    private get careActivityId() {
        return new CareActivityId((this.props.routingController.currentRoute.parameters as IStandaloneDocumentListRouteParams).careActivityId);
    }

    @State.bound
    private navigateToDocument(careDocumentId: CareDocumentId) {
        this.props.routingController.replace(
            OutpatientTreatmentRoutes.documentList.makeRoute({ careActivityId: this.careActivityId.value, selectedDocumentId: careDocumentId?.value })
        );
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    public render() {
        return (
            <DocumentManagementMasterDetailPanel
                selectedDocumentId={this.selectedDocumentId}
                onSelectedDocumentIdChange={this.navigateToDocument}
                careActivityRelatedDataOnly
            />
        );
    }
}

export default connect(
    DocumentListStandalonePage
);