import React, { useMemo } from "react";
import Styles from "./PropertyList.less";
import IPropertyListItem from "./IPropertyListItem";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import * as Ui from "@CommonControls";
import IPropertyListItemColumn from "@CommonControls/PropertyList/IPropertyListItemColumn";

interface IPropertyListProps {
    properties: IPropertyListItem[] | IPropertyListItemColumn[];
    nameWidth?: string | number;
    valueWidth?: string | number;
    hasColonAfterNames?: boolean;
    hideIfEmpty?: boolean;
    hidePropertyIfNoValue?: boolean;
    title?: string;
    boldValues?: boolean;
}

export default function PropertyList(props: IPropertyListProps): React.ReactElement {
    const propertyListItemColumns = (props.properties as unknown as IPropertyListItemColumn[]);

    let propertyListResult: JSX.Element = null;

    if (propertyListItemColumns[0].properties) {
        propertyListResult = (
            <Ui.Flex>
                {propertyListItemColumns.map((column, pIndex) => {
                    const properties = column.properties;
                    return (
                        <Ui.Flex.Item {...column.flexItemProps} key={pIndex}>
                            <PropertyListCore
                                properties={properties}
                                nameWidth={props.nameWidth}
                                valueWidth={props.valueWidth}
                                hasColonAfterNames={props.hasColonAfterNames}
                                hideIfEmpty={props.hideIfEmpty}
                                hidePropertyIfNoValue={props.hidePropertyIfNoValue}
                                boldValues={props.boldValues}
                            />
                        </Ui.Flex.Item>
                    );
                })}
            </Ui.Flex>
        );
    } else {
        propertyListResult = (
            <PropertyListCore
                properties={props.properties}
                nameWidth={props.nameWidth}
                valueWidth={props.valueWidth}
                hasColonAfterNames={props.hasColonAfterNames}
                hideIfEmpty={props.hideIfEmpty}
                hidePropertyIfNoValue={props.hidePropertyIfNoValue}
                boldValues={props.boldValues}
            />
        );
    }

    if (!isNullOrEmptyString(props.title)) {
        return (
            <Ui.GroupBox title={props.title}>
                {propertyListResult}
            </Ui.GroupBox>
        );
    }

    return propertyListResult;
}

function PropertyListCore(props: IPropertyListProps): React.ReactElement {

    const properties = useMemo(() => {

        return (props.properties as IPropertyListItem[]).map((p, pIndex) => (Array.isArray(p.value) ? p.value : [p.value]).map((v, vIndex) => {
            if (props.hidePropertyIfNoValue && isNullOrUndefined(p.value)) {
                return null;
            }

            return (
                <tr key={pIndex + "_" + vIndex}>

                    {vIndex === 0 ? (
                        <th style={{ width: props.nameWidth }}>{p.name}{props.hasColonAfterNames && ":"}</th>
                    ) : <th />}

                    <td style={{ width: props.valueWidth, fontFamily: props.boldValues ? "PragatiNarrowBold" : "inherit" }}
                        data-automation-id={p.automationId ? (Array.isArray(p.value) ? `${p.automationId}_${vIndex}` : p.automationId) : undefined}>
                        {v}
                    </td>
                </tr>
            );
        }
        ));

    }, [props.properties, props.hasColonAfterNames, props.nameWidth, props.valueWidth, props.hidePropertyIfNoValue]);

    if (props.hideIfEmpty && properties.every(property => property.every(value => isNullOrUndefined(value)))) {
        return null;
    }

    const propertyListTable = (
        <table className={Styles.table}>
            <tbody>
                {properties}
            </tbody>
        </table>
    );

    return propertyListTable;
}