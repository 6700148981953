import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import * as Ui from "@CommonControls";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import IPointOfCare from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/IPointOfCare";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { emptyObject, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { DisplayMode } from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";
import AsyncMessageQueue from "@Toolkit/CommonWeb/AsyncMessageQueue";
import _ from "@HisPlatform/Common/Lodash";
import OrganizationUnitIcon from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/OrganizationUnitIcon";
import { getIconNameByHealthcareProfessions, getOrganizationUnitTypeIconNameByTag } from "@HisPlatform/BoundedContexts/Organization/Components/Helpers/IconTypeNameHelper";
import ViewContextProvider from "@Toolkit/ReactClient/Components/ViewContext/ViewContextProvider";
import PointOfCare from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/PointOfCare";
import HealthcareProfession from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/HealthcareProfession";
import ValidationBoundaryAdapter from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryAdapter";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";

interface IPointOfCareSelectBoxDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IPointOfCareSelectBoxProps extends ISelectBoxBaseProps {
    _validationContext?: IValidationBoundaryStore;
    _dependencies?: IPointOfCareSelectBoxDependencies;
    explicitIds?: PointOfCareId[];
    shouldSelectOnlyItem?: boolean;
    displayMode?: DisplayMode;
    isLoading?: boolean;
}

@State.observer
class PointOfCareSelectBox extends React.Component<IPointOfCareSelectBoxProps> {
    private readonly loadQueue = new AsyncMessageQueue(this.loadAsync);

    private get referenceDataStore() {
        return this.props._dependencies.organizationReferenceDataStore;
    }

    @State.observable.ref private items: Array<ISelectBoxItem<PointOfCareId>> = null;
    @State.observable.ref private isLoaded = false;

    public componentDidMount() {
        dispatchAsyncErrors(this.loadQueue.enqueueAndProcessAsync(emptyObject), this);
    }

    public componentDidUpdate(prevProps: IPointOfCareSelectBoxProps) {
        if (!_.isEqual(prevProps.explicitIds, this.props.explicitIds)) {
            dispatchAsyncErrors(this.loadQueue.enqueueAndProcessAsync(emptyObject), this);
        }
    }

    @State.bound
    private async loadAsync() {
        if (!isNullOrUndefined(this.props.explicitIds)) {
            await this.referenceDataStore.pointOfCareMap.ensureLoadedAsync(this.props.explicitIds);
            await this.referenceDataStore.organizationUnitMap.ensureLoadedAsync(this.props.explicitIds);
            await this.referenceDataStore.organizationUnitDefinitionMap.ensureLoadedAsync(this.referenceDataStore.organizationUnitMap.items.map(ou => ou.organizationUnitDefinitionId));
            this.initializeExplicitItems();
        } else {
            await this.referenceDataStore.pointOfCareMap.ensureAllLoadedAsync();
            await this.referenceDataStore.organizationUnitMap.ensureAllLoadedAsync();
            await this.referenceDataStore.organizationUnitDefinitionMap.ensureLoadedAsync(this.referenceDataStore.organizationUnitMap.items.map(ou => ou.organizationUnitDefinitionId));
            await this.referenceDataStore.organizationUnitDefinitionMap.ensureLoadedAsync(this.referenceDataStore.organizationUnitMap.items.map(ou => ou.organizationUnitDefinitionId));
            this.initializeAllItems();
        }
        await this.referenceDataStore.healthCareProfession.ensureAllLoadedAsync();

        State.runInAction(() => {
            this.isLoaded = true;
        });
    }

    @State.action
    private initializeExplicitItems() {
        this.items = this.referenceDataStore.pointOfCareMap.items
            .filter(i => this.props.explicitIds.some(s => ValueWrapper.equals(s, i.id)))
            .map(this.toSelectBoxItem);
    }

    @State.action
    private initializeAllItems() {
        this.items = this.referenceDataStore.pointOfCareMap.items
            .map(this.toSelectBoxItem);
    }

    @State.bound
    private toSelectBoxItem(item: IPointOfCare) {
        return {
            text: this.getItemText(item),
            value: item.id
        } as ISelectBoxItem<PointOfCareId>;
    }

    @State.bound
    private getItemText(item: IPointOfCare) {
        switch (this.props.displayMode) {
            case "name":
                return item.name;
            case "shorthand-name":
                return `${item.shorthand} - ${item.name}`;
            case "shorthand":
            default:
                return item.shorthand;
        }
    }

    @State.bound
    private pointOfCareValueCustomRenderer(props: any) {
        const selectedPointOfCareId = props.data.value as PointOfCareId;

        return this.pointOfCareCustomRenderer(selectedPointOfCareId);
    }

    @State.bound
    private pointOfCareCustomRenderer(pointOfCareId: PointOfCareId) {
        const selectedPointOfCare = this.referenceDataStore.pointOfCareMap.get(pointOfCareId);
        const healthCareProfessions = this.referenceDataStore.healthCareProfession.multiGet(selectedPointOfCare.healthcareProfessionIds);
        const organizationUnit = this.referenceDataStore.organizationUnitMap.get(pointOfCareId);
        const organizationDefinition = this.referenceDataStore.organizationUnitDefinitionMap.get(organizationUnit.organizationUnitDefinitionId);
        const healthCareProfessionIconName = getIconNameByHealthcareProfessions(healthCareProfessions);

        const organizationUnitTypeIconName = getOrganizationUnitTypeIconNameByTag(organizationDefinition?.tags);
        return (
            <Ui.Flex style={{ flexWrap: "nowrap" }}>
                {!isNullOrUndefined(healthCareProfessionIconName) &&
                    <Ui.Flex.Item>
                        <Ui.Icon iconName={healthCareProfessionIconName} />
                    </Ui.Flex.Item>}
                <Ui.Flex.Item style={{ paddingLeft: "5px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                    <b>{selectedPointOfCare.shorthand}</b>{" - "}<span>{selectedPointOfCare.name}</span>
                </Ui.Flex.Item>
                {!isNullOrUndefined(organizationUnitTypeIconName) &&
                    <Ui.Flex.Item style={{ paddingLeft: "5px", marginLeft: "auto" }}>
                        <Ui.Icon iconName={organizationUnitTypeIconName} />
                    </Ui.Flex.Item>}
            </Ui.Flex>
        );
    }

    public render() {
        return this.isLoaded && (
            <Ui.SelectBox
                {...this.props}
                clearable={!this.isRequired}
                loading={!this.items || this.props.isLoading}
                items={this.items}
                customValueRenderer={this.pointOfCareValueCustomRenderer}
                autoSelectSingleItem={this.props.shouldSelectOnlyItem}
            />
        );
    }

    private get isRequired() {
        return !!(this.props.required || this.props._validationContext && this.props._validationContext.isRequired(this.props.propertyIdentifier));
    }

}

export default connect(
    PointOfCareSelectBox,
    new ValidationBoundaryAdapter<IPointOfCareSelectBoxProps>((props, validationStore) => ({ _validationContext: validationStore })),
    new DependencyAdapter<IPointOfCareSelectBoxProps, IPointOfCareSelectBoxDependencies>(container => {
        return {
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore")
        };
    })
);
