import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import Modal from "@CommonControls/Modal";
import Button from "@CommonControls/Button";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import NewBinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/Creation/NewBinaryDocument";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import FileUpload from "@CommonControls/FileUpload/FileUpload";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import { emptyArray, arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ScrollView from "@CommonControls/ScrollView/ScrollView";
import Styles from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/CreateNewDocumentDialog/CreateNewDocumentDialog.css";
import TextBox from "@CommonControls/TextBox";
import DocumentTypeSelectBox from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Controls/DocumentTypeSelectBox/DocumentTypeSelectBox";
import BinaryDocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/BinaryDocumentApiAdapter";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import Log from "@Log";
import ScopeIdentifier from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ScopeIdentifier";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";
import PatientId from "@Primitives/PatientId.g";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ClientSideValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidationBoundary";
import CareActivityDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentApiAdapter";
import { Documents } from "@HisPlatform/BoundedContexts/DocumentManagement/Api/ApiConstraints.g";
import DocumentKind from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentKind.g";
import { ICreateNewDocumentDialogResult } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/CreateNewDocumentDialog/CreateNewDocumentDialogParams";
import DocumentId from "@Primitives/DocumentId.g";

interface INewBinaryDocumentPanelDependencies {
    careActivityDocumentApiAdapter: CareActivityDocumentApiAdapter;
    notificationService: INotificationService;
    dialogService: IDialogService;
}

interface INewBinaryDocumentPanelProps {
    _dependencies?: INewBinaryDocumentPanelDependencies;
    _careActivityId?: CareActivityId;
    _careActivityPointOfCareId?: PointOfCareId;
    patientId?: PatientId;
    scope?: string;

    onCancel: () => void;
    onSuccess: (dialogResult: ICreateNewDocumentDialogResult) => void;
}

@State.observer
class NewBinaryDocumentPanel extends React.Component<INewBinaryDocumentPanelProps> {

    private get careActivityDocumentApiAdapter() { return this.props._dependencies!.careActivityDocumentApiAdapter; }
    private get notificationService() { return this.props._dependencies!.notificationService; }
    private get dialogService() { return this.props._dependencies!.dialogService; }

    private readonly newDocuments = State.createObservableShallowArray<NewBinaryDocument>([]);
    private readonly modalResources = StaticDocumentManagementResources.DocumentManagementMasterDetailPanel.CreateNewDocumentDialog;

    private validators = new Map<NewBinaryDocument, ClientSideValidator>();

    @State.observable.ref private isLoading = false;

    public render() {
        return (
            <>
                <Modal.Body>
                    <>
                        <ValidationBoundary validationResults={emptyArray}>
                            {this.newDocuments.length > 0 && (
                                <ScrollView className={Styles.binaryFileList}>
                                    <ListPanel
                                        items={this.newDocuments}
                                        allowCreatingNew={false}
                                        renderItemEditor={this.renderItem}
                                        observerItems
                                        alwaysEdit
                                        actionButtonsOrientation="vertical"
                                        isCompactEmptyState
                                        onRemoveItem={this.removeItem}
                                    />
                                </ScrollView>
                            )}
                            <FileUpload id="NewBinaryDocumentUpload" onChange={this.handleFiles} showFiles={false} />
                        </ValidationBoundary>
                    </>
                </Modal.Body>
                <Modal.Footer left={(
                    <Button text={StaticDocumentManagementResources.Common.Cancel} onClick={this.props.onCancel} automationId="cancelButton" />
                )} right={(
                    <Button visualStyle="primary" text={this.modalResources.Create} onClickAsync={this.createAsync} automationId="createNewBinaryDocumentButton" />
                )} />
            </>
        );
    }

    @State.bound
    private removeItem(item: NewBinaryDocument) {
        this.newDocuments.remove(item);
        this.validators.delete(item);
    }

    @State.bound
    private renderItem(item: NewBinaryDocument, index: number) {
        return (
            <ClientSideValidationBoundary validator={this.validators.get(item)} entityTypeName="SaveNewBinaryDocument">
                {item.isUploaded && <SpanWithIcon iconName="check">{this.modalResources.SuccessfullyUploaded}</SpanWithIcon>}
                <ReadOnlyContext.Provider value={item.isUploaded}>
                    <TextBox
                        label={formatStringWithObjectParams(this.modalResources.AttachmentName, { index: (index + 1).toString() })}
                        value={item.name}
                        onChange={item.setName}
                        propertyIdentifier="Name"
                        automationId="nameTextBox"
                    />
                    <DocumentTypeSelectBox
                        label={this.modalResources.AttachmentType}
                        value={item.type}
                        onChange={item.setType}
                        propertyIdentifier="DocumentTypeId"
                        automationId="documentTypeSelectBox"
                    />
                    <TextBox
                        label={this.modalResources.AttachmentDescription}
                        multiline
                        multilineMinLineCount={2}
                        multilineMaxLineCount={3}
                        value={item.description}
                        onChange={item.setDescription}
                        automationId="descriptionTextBox"
                    />
                </ReadOnlyContext.Provider>
            </ClientSideValidationBoundary>
        );
    }

    @State.action.bound
    private handleFiles(files: File[]) {
        const scopes = new Array<ScopeIdentifier>();

        if (!isNullOrUndefined(this.props._careActivityId)) {
            scopes.push(ScopeIdentifier.createCareActivityScopeIdentifier(this.props._careActivityId!));
        }
        if (!isNullOrUndefined(this.props.patientId)) {
            scopes.push(ScopeIdentifier.createPatientScopeIdentifier(this.props.patientId!));
        }
        if (!isNullOrUndefined(this.props._careActivityPointOfCareId)) {
            scopes.push(ScopeIdentifier.createOrganizationScopeIdentifier(this.props._careActivityPointOfCareId!));
        }

        files.forEach(file => {
            if (!!file) {
                const newDoc = new NewBinaryDocument(scopes, file);
                this.newDocuments.push(newDoc);

                this.validators.set(newDoc, new ClientSideValidator(Documents, {
                    shouldBeFilled: StaticFinanceResources.Validation.Required
                }));
            }
        });
    }

    @State.boundLoadingState()
    private async createAsync() {
        let firstDocumentId: DocumentId | null =  null;
        for (const doc of Array.from(this.newDocuments.filter(d => !d.isUploaded))) {
            try {
                const validator = this.validators.get(doc);
                if (!validator.isValid()) {
                    continue;
                }

                let result = null;
                if (this.props.scope === "Patient" && !isNullOrUndefined(this.props.patientId)) {
                    result = await this.careActivityDocumentApiAdapter.saveNewPatientRelatedBinaryDocumentCommandAsync(doc, this.props.patientId);
                } else if (!isNullOrUndefined(this.props._careActivityId)) {
                    result = await this.careActivityDocumentApiAdapter.saveNewCareActivityRelatedBinaryDocumentCommandAsync(doc, this.props._careActivityId);
                } 

                if (result != null && result.isPersistedByOperationInfo) {
                    firstDocumentId = result.value;
                    doc.uploadedSuccessfully();
                }
            } catch (e) {
                Log.error(e);
            }
        }

        if (this.newDocuments.filter(d => !d.isUploaded).length === 0) {
            this.notificationService.showSavedSuccessfully();
            this.props.onSuccess(
                {
                    templateId: null,
                    documentKind: DocumentKind.Binary,
                    description: null,
                    newId: firstDocumentId,
                } as ICreateNewDocumentDialogResult);
        } else {
            this.dialogService.ok(StaticDocumentManagementResources.Common.Error, this.modalResources.SomeAttachmentsHaveFailedToUpload);
        }
    }
}

export default connect(
    NewBinaryDocumentPanel,
    new DependencyAdapter<INewBinaryDocumentPanelProps, INewBinaryDocumentPanelDependencies>(c => ({
        careActivityDocumentApiAdapter: c.resolve("CareActivityDocumentApiAdapter"),
        notificationService: c.resolve("INotificationService"),
        dialogService: c.resolve("IDialogService"),
    })),
    new CareActivityContextAdapter<INewBinaryDocumentPanelProps>(c => ({
        _careActivityId: c.careActivityId,
        _careActivityPointOfCareId: c.careActivity?.pointOfCareId
    }))
);