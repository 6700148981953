import HunSocialSecurityReportingToolbar from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Panels/ReportingToolbar/HunSocialSecurityReportingToolbar";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import NavMenuCustomItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuCustomItem";

export function getNavigationMenuItems(): INavMenuItem[] {
    const leftTopLevelMenuItems: INavMenuItem[] = [
    ];

    const rightTopLevelMenuItems: INavMenuItem[] = [
    ];

    const childMenuItems: INavMenuItem[] = [
        NavMenuCustomItem.createChild("HunSocialSecurityReportingItems", "ReportingToolbar", HunSocialSecurityReportingToolbar, 2),
    ];

    return [...leftTopLevelMenuItems, ...rightTopLevelMenuItems, ...childMenuItems];
}