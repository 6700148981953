import SelectBox from "@CommonControls/SelectBox";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import VendorId from "@Primitives/VendorId.g";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { useFormTextField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { useFormLayoutContext } from "@Toolkit/FormEngine/Panels/FormLayoutContext";
import { ICustomBlockRegistryItem, IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import { useAsyncMemo } from "@Toolkit/ReactClient/Common/Hooks";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import React, { useCallback, useMemo } from "react";

interface IVendorBankAccountFormCustomBlockProps extends IFormCustomBlockComponentProps {

}

function VendorBankAccountFormCustomBlock(props: IVendorBankAccountFormCustomBlockProps) {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext.compositeDataReferences?.join(".");

    const [vendorId, _] = useFormTextField(props.form.data.Content, isNullOrEmptyString(compositeFieldName) ? "VendorId" : compositeFieldName + ".VendorId");
    const [bankAccountName, setBankAccountName] = useFormTextField(props.form.data.Content, isNullOrEmptyString(compositeFieldName) ? "BankAccountName" : compositeFieldName + ".BankAccountName");
    const [bankAccountBankName, setBankAccountBankName] = useFormTextField(props.form.data.Content, isNullOrEmptyString(compositeFieldName) ? "BankAccountBankName" : compositeFieldName + ".BankAccountBankName");
    const [bankAccountNumber, setBankAccountNumber] = useFormTextField(props.form.data.Content, isNullOrEmptyString(compositeFieldName) ? "BankAccountNumber" : compositeFieldName + ".BankAccountNumber");

    const value: IBankAccountValue = useMemo(() => isNullOrUndefined(bankAccountNumber) ? null : ({
        bankName: bankAccountBankName,
        accountNumber: bankAccountNumber,
        name: bankAccountName,
    }), [bankAccountBankName, bankAccountNumber]);

    const { financeReferenceDataStore } = useDependencies((c) => ({
        financeReferenceDataStore: c.resolve<FinanceReferenceDataStore>("FinanceReferenceDataStore"),
    }));

    const { value: availableBankAccounts, isLoading } = useAsyncMemo<Array<ISelectBoxItem<IBankAccountValue>>>([], async () => {
        if (isNullOrEmptyString(vendorId)) return [];

        const selectedVendor = await financeReferenceDataStore.vendorMap.getByIdAsync(new VendorId(vendorId.toString()));
        if (isNullOrUndefined(selectedVendor)) return [];

        const items = selectedVendor.bankAccounts.map<ISelectBoxItem<IBankAccountValue>>(a => ({
            text: `${a.name} (${a.bankName} ${a.bankAccountNumber})`,
            value: {
                name: a.name,
                bankName: a.bankName,
                accountNumber: a.bankAccountNumber,
            }
        }));

        if (isNullOrUndefined(bankAccountNumber)) {
            if (items.length === 1) {
                setBankAccountName(items[0].value.name);
                setBankAccountBankName(items[0].value.bankName);
                setBankAccountNumber(items[0].value.accountNumber);
            } else {
                setBankAccountName(null);
                setBankAccountBankName(null);
                setBankAccountNumber(null);
            }
        }

        return items;
    }, [vendorId]);

    const setValue = useCallback((value: IBankAccountValue) => {
        if (value) {
            setBankAccountName(value.name);
            setBankAccountBankName(value.bankName);
            setBankAccountNumber(value.accountNumber);
        } else {
            setBankAccountName(null);
            setBankAccountBankName(null);
            setBankAccountNumber(null);
        }
    }, []);

    return (
        <div style={{ flex: "1 1 0px", overflow: "hidden" }}>
            <SelectBox
                label={StaticFinanceResources.InvoiceScreen.BankAccountLabel}
                automationId="bank_account"
                value={value}
                equalityComparer={areEquals}
                getOptionValue={getOptionValue}
                items={availableBankAccounts}
                loading={isLoading}
                onChange={setValue}
                style={{ width: "100%" }}
            />
        </div>
    );
}

interface IBankAccountValue {
    name: string;
    bankName: string;
    accountNumber: string;
}

function getOptionValue(a: ISelectBoxItem<IBankAccountValue>) {
    return a?.value?.accountNumber ?? null;
}

function areEquals(a: ISelectBoxItem<IBankAccountValue>, b: ISelectBoxItem<IBankAccountValue>) {
    return a?.value?.accountNumber === b?.value?.accountNumber;
}

export const VendorBankAccountFormCustomBlockRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Vendor bank account selector",
    dataElementsFactory: () => [
    ],
    componentType: State.observer(VendorBankAccountFormCustomBlock)
};