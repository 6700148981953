import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import EhiUserContext from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiUserContext";
import { CheckBox, Button, Flex } from "@CommonControls";
import { ICheckBoxBaseProps } from "@CommonControls/CheckBox/CheckBoxCore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import ModalServiceAdapter from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAdapter";
import EhiEmergencyDialogParams from "@HunEHealthInfrastructurePlugin/Common/Components/EhiEmergencyDialog/EhiEmergencyDialogParams";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticHunEHealthInfrastructureWebAppResources from "@HunEHealthInfrastructurePlugin/StaticResources/StaticHunEHealthInfrastructureWebAppResources";
import { ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";

interface IEhiQueryButtonWithEmergencyCheckBoxDependencies {
    ehiUserContext: EhiUserContext;
}

interface IEhiQueryButtonWithEmergencyCheckBoxProps extends ICommonControlProps {
    _dependencies?: IEhiQueryButtonWithEmergencyCheckBoxDependencies;
    _modalService?: IModalService;
    onReasonChange: (newValue: string) => void;
    isEmergency: boolean;
    isFullWidth?: boolean;
    onIsEmergencyChange: (newValue: boolean) => void;
    onQueryAsync: () => Promise<void>;
}

class EhiQueryButtonWithEmergencyCheckBox extends React.Component<IEhiQueryButtonWithEmergencyCheckBoxProps> {

    @State.bound
    private async queryAsync() {
        if (this.props.isEmergency === true) {
            const dialogResult = await this.props._modalService.showDialogAsync<string>(new EhiEmergencyDialogParams());
            if (!isNullOrEmptyString(dialogResult)) {
                this.props.onIsEmergencyChange(true);
                this.props.onReasonChange(dialogResult);
                await this.props.onQueryAsync();
            }
        } else {
            this.props.onIsEmergencyChange(false);
            this.props.onReasonChange("");
            await this.props.onQueryAsync();
        }
    }

    public render() {
        return (
            <Flex outerSpacing="none" verticalSpacing="none" spacing="none">
                <Flex.Item>
                    <CheckBox
                        {...this.props}
                        label={StaticHunEHealthInfrastructureWebAppResources.EmergencyCheckBoxLabel}
                        onChange={this.props.onIsEmergencyChange}
                        value={this.props.isEmergency}
                        automationId="isEmergencyCheckBox"
                    />
                </Flex.Item>
                <Flex.Item style={{ flexGrow: this.props.isFullWidth ? 1 : 0 }}>
                    <Button
                        text={StaticHunEHealthInfrastructureWebAppResources.EhiQueryButtonText}
                        onClickAsync={this.queryAsync}
                        automationId="queryButton"
                        fluid={this.props.isFullWidth}
                        visualStyle="primary"
                    />
                </Flex.Item>
            </Flex>
        );
    }
}

export default connect(
    EhiQueryButtonWithEmergencyCheckBox,
    new DependencyAdapter<IEhiQueryButtonWithEmergencyCheckBoxProps, IEhiQueryButtonWithEmergencyCheckBoxDependencies>(c => ({
        ehiUserContext: c.resolve("EhiUserContext")
    })),
    new ModalServiceAdapter()
);