import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { CareLocation } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/CareLocation";
import { SelectBox } from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import { ExternalLocationVersionSelector } from "@Primitives/ExternalLocationId.g";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";

interface ICareLocationColumn2FilterDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface ICareLocationColumn2FilterProps {
    _dependencies?: ICareLocationColumn2FilterDependencies;
    value: CareLocation;
    onChange: (newValue: CareLocation) => void;
    externalLocationVersionSelectors: ExternalLocationVersionSelector[];
    automationId?: string;
    hoverOnlyIndicatorIcons?: boolean;
}

@State.observer
class CareLocationColumn2Filter extends React.Component<ICareLocationColumn2FilterProps> {

    private get dependencies() { return this.props._dependencies; }

    @State.observable.ref private items: Array<ISelectBoxItem<CareLocation>>;

    constructor(props: ICareLocationColumn2FilterProps) {
        super(props);
        this.initializeItems();
    }

    public componentDidUpdate(prevProps: ICareLocationColumn2FilterProps) {
        if (prevProps.externalLocationVersionSelectors !== this.props.externalLocationVersionSelectors) {
            this.initializeItems();
        }
    }

    @State.action
    private initializeItems() {
        const pointOfCareMap = this.dependencies.organizationReferenceDataStore.allPointsOfCareMap;
        const externalLocations = this.dependencies.organizationReferenceDataStore.externalLocationStore;

        const externals = this.props.externalLocationVersionSelectors ? this.props.externalLocationVersionSelectors.map(item => ({
            text: externalLocations.get(item).name,
            value: new ExternalCareLocation(item)
        } as ISelectBoxItem<CareLocation>)) : [];

        this.items = pointOfCareMap.items.map(item => ({
            text: pointOfCareMap.get(item.id).shorthand,
            value: new InternalCareLocation(item.id)
        } as ISelectBoxItem<CareLocation>)).concat(externals);
    }

    public render() {
        return (
            <SelectBox
                value={this.props.value}
                onChange={this.props.onChange}
                items={this.items}
                automationId={this.props.automationId}
                hoverOnlyIndicatorIcons={this.props.hoverOnlyIndicatorIcons}
            />
        );
    }
}

export default connect(
    CareLocationColumn2Filter,
    new DependencyAdapter<ICareLocationColumn2FilterProps, ICareLocationColumn2FilterDependencies>(c => ({
        organizationReferenceDataStore: c.resolve<OrganizationReferenceDataStore>("OrganizationReferenceDataStore"),
    }))
);
