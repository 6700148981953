import StoreMapper from "@Toolkit/CommonWeb/ApiAdapter/StoreMapper";
import { MedicationPricingVersionDto } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import MedicationPricing from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationPricing";
import Di from "@Di";

@Di.injectable()
export default class MedicationPricingStoreMapper extends StoreMapper<MedicationPricing, MedicationPricingVersionDto> {
    protected applyToStoreCore(target: MedicationPricing, response: MedicationPricingVersionDto): void {
        target.id = response.id;
        target.medicationId =  response.medicationId;
        target.curativeLimit = response.curativeLimit;
        target.claimTypes = response.claimTypes;
        target.dailyTherapyCost = response.dailyTherapyCost;
        target.grossPrice = response.grossPrice;
        target.netPrice = response.netPrice;
        target.substanceBasedRestrictionId = response.substanceBasedRestrictionId;
        target.orderTypeId = response.orderTypeId;
        target.claimTypes = response.claimTypes;
        target.validity = response.validity;
        target.counterSignDesiredValue = response.countersignDesiredValue;
    }
}