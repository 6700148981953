import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import DocumentId from "@Primitives/DocumentId.g";
import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import BinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/BinaryDocument";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import DocumentBase from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentBase";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import TemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TemplateBasedDocument";
import { applyToDocument } from "./DocumentStoreMapper";
import IDocumentHistoryItem from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/IDocumentHistoryItem";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LargeDataToken from "@Primitives/LargeDataToken.g";
import DocumentKind from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentKind.g";

@Di.injectable()
export default class DocumentApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IDocumentsClient") private readonly apiClient: Proxy.IDocumentsClient,
    ) {
        super();
    }

    public getDocumentByIdAsync(id: DocumentId, requestLock: boolean = false) {
        return this.processOperationAsync(
            new SimpleStore<DocumentBase>(),
            async target => {
                const response = await this.apiClient.getDocumentCommandAsync(
                    CreateRequestId(),
                    new Proxy.GetDocumentControllerDto({
                        requestLock: requestLock,
                        documentId: id
                    })
                );

                if (response.document.documentKind === DocumentKind.Binary) {
                    target.value = new BinaryDocument();
                } else if (response.document.documentKind === DocumentKind.TemplateBased) {
                    target.value = new TemplateBasedDocument();
                }

                applyToDocument(target.value, response);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public getDocumentPreviewAsync(documentId: DocumentId, isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new SimpleStore<{token: LargeDataToken, downloadFileName: string, type: string}>(),
            async target => {
                const request = new Proxy.GetDocumentPrintPreviewControllerDto({documentId: documentId});

                const response = await this.apiClient.getDocumentPrintPreviewCommandAsync(CreateRequestId(), request, isPermissionCheckOnly);

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    token: response.contentToken,
                    downloadFileName: response.downloadFileName,
                    type: response.contentType
                };
            }
        );
    }

    @State.bound
    public getDocumentPreviewPermissionCheckAsync() {
        return this.processOperationAsync(
            new SimpleStore<{id: LargeDataToken, type: string}>(),
            async target => {
                const request = new Proxy.GetDocumentPrintPreviewControllerDto({documentId: new DocumentId("-1")});

                const response = await this.apiClient.getDocumentPrintPreviewCommandAsync(CreateRequestId(), request, true);

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public getDocumentHistoryAsync(documentId: DocumentId) {
        return this.processOperationAsync(
            new SimpleStore<IDocumentHistoryItem[]>(),
            async target => {
                const response = await this.apiClient.getDocumentHistoryQueryAsync(CreateRequestId(), documentId.value);

                target.value = response.history?.map(h => ({
                    contentVersion: h.contentVersion,
                    createdAt: h.createdAt,
                    createdBy: h.createdBy,
                    documentAction: h.documentAction,
                    documentState: h.documentState,
                    documentVersion: h.documentVersion,
                    contentId: h.contentId
                } as IDocumentHistoryItem)) || [];
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public getDocumentContentAsync(largeDataId: LargeDataToken, documentId: DocumentId = null) {
        return this.processOperationAsync(
            new SimpleStore<{content: string, mediaType: string, fileName: string}>(),
            async target => {
                const response = await this.apiClient.getDocumentContentByIdQueryAsync(CreateRequestId(), documentId?.value ?? "", largeDataId.value);

                target.value = { mediaType: response.documentContentDto.mediaType, content: response.documentContentDto.content, fileName: response.documentContentDto.fileName };
                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}
