import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import StaticHunSocialSecurityFinanceResources from "@HunSocialSecurityPlugin/BoundedContexts/Finance/StaticResources/StaticHunSocialSecurityFinanceResources";
import PatientFinancingClassSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Finance/Components/Controls/PatientFinancingClassSelectBox";
import AccidentTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Finance/Components/Controls/AccidentTypeSelectBox";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import IFinancingClassGroup from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/Model/IFinancingClassGroup";
import HunFinanceReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/Model/HunFinanceReferenceDataStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import PatientId from "@Primitives/PatientId.g";
import CoverageEligibilityChecker from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/CoverageEligibilityCheckLed/CoverageEligibilityChecker";
import CareActivityId from "@Primitives/CareActivityId.g";
import HunFinancingClassId from "@Primitives/HunFinancingClassId.g";
import AccidentTypeId from "@Primitives/AccidentTypeId.g";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import RegionalCareObligationStatusExtension from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/RegionalCareObligationStatusExtension/RegionalCareObligationStatusExtension";
import PointOfCareId from "@Primitives/PointOfCareId.g";

interface ICareActivityCoverageDataPanelViewProps {
    patientId: PatientId;
    careActivityId: CareActivityId;
    pointOfCareId: PointOfCareId;

    possibleHunFinancingClassGroups: IFinancingClassGroup[];
    selectedHunFinancingClassGroup: IFinancingClassGroup;
    setFinancingGroup: (newValue: IFinancingClassGroup) => void;

    hunFinanceReferenceDataStore: HunFinanceReferenceDataStore;
    careReferenceDataStore: CareReferenceDataStore;

    financingClassId: HunFinancingClassId;
    onFinancingClassIdChange: (id: HunFinancingClassId) => void;

    identifier: Identifier;

    accidentTypeId: AccidentTypeId;
    onAccidentTypeIdChange: (id: AccidentTypeId) => void;
}

const getIdentifierTypes = (possibleHunFinancingClassGroups: IFinancingClassGroup[], store: HunFinanceReferenceDataStore) => {
    return possibleHunFinancingClassGroups.map(item => {
        return {
            value: item,
            longDisplayValue: store.identifierTypeMap.get(item.IdentifierTypeId).displayValue.Name,
            text: store.identifierTypeMap.get(item.IdentifierTypeId).displayValue.Name
        } as ISelectBoxItem<IFinancingClassGroup>;
    });
};

const getDocument = (identifier: Identifier, careReferenceDataStore: CareReferenceDataStore) => {
    if (!identifier) {
        return "";
    }
    const document = careReferenceDataStore.patientDocumentType.items.find(doc =>
        doc.Entity.identifierSystemId.value === identifier.identifierSystemId.value);
    return document && `${document.Localization.Name} - ${identifier.value}`;
};

const getDocumentValue = (identifier: Identifier, careReferenceDataStore: CareReferenceDataStore) => {
    if (!identifier || identifier.identifierSystemId.value !== "HunSocialSecurityNumber") {
        return "";
    }
    const document = careReferenceDataStore.patientDocumentType.items.find(doc =>
        doc.Entity.identifierSystemId.value === identifier.identifierSystemId.value);
    return document && identifier.value;
};

const getOptionValue = (option: ISelectBoxItem<IFinancingClassGroup>) => option.value &&
    `${option.value.IdentifierTypeId && option.value.IdentifierTypeId.value}-${option.value.Identifier && option.value.Identifier.value}`;


const CarActivityCoverageDataPanelView: React.FC<ICareActivityCoverageDataPanelViewProps> = props => {
    return (
        <>
            <Ui.Flex.Item xs={4}>
                <Ui.SelectBox
                    label={StaticHunSocialSecurityFinanceResources.CareActivityCoverageData.Label.IdentifierType}
                    items={getIdentifierTypes(props.possibleHunFinancingClassGroups, props.hunFinanceReferenceDataStore)}
                    onChange={props.setFinancingGroup}
                    value={props.selectedHunFinancingClassGroup}
                    getOptionValue={getOptionValue}
                    propertyIdentifier="IdentifierTypeId"
                    automationId="IdentifierTypeId"
                />
            </Ui.Flex.Item>

            <Ui.Flex.Item xs={4}>
                <PatientFinancingClassSelectBox
                    value={props.financingClassId}
                    onChange={props.onFinancingClassIdChange}
                    patientId={props.patientId}
                    financeClassIds={props.selectedHunFinancingClassGroup && props.selectedHunFinancingClassGroup.PossibleFinancingClassIds}
                    label={StaticHunSocialSecurityFinanceResources.CareActivityCoverageData.Label.FinancingGroup}
                    propertyIdentifier="FinancingClassId"
                    automationId="FinancingClassId"
                />
            </Ui.Flex.Item>

            <Ui.Flex.Item xs={4}>
                <Ui.TextBox
                    label={StaticHunSocialSecurityFinanceResources.CareActivityCoverageData.Label.PatientDocumentValue}
                    value={getDocument(props.identifier, props.careReferenceDataStore)}
                    isReadOnly={true}
                    automationId="__patientDocumentValue"
                />
            </Ui.Flex.Item>

            <Ui.Flex.Item xs={2}>
                <CoverageEligibilityChecker
                    patientId={props.patientId}
                    careActivityId={props.careActivityId}
                    identifierValue={getDocumentValue(props.identifier, props.careReferenceDataStore)}
                    label={StaticHunSocialSecurityFinanceResources.CareActivityCoverageData.Label.CoverageEligibilityCheck}
                />
            </Ui.Flex.Item>
            <Ui.Flex.Item xs={2} style={{ paddingTop: 27, paddingLeft: 4 }}>
                <RegionalCareObligationStatusExtension
                    patientId={props.patientId}
                    pointOfCareId={props.pointOfCareId}
                />
            </Ui.Flex.Item>

            <Ui.Flex.Item xs={4}>
                <AccidentTypeSelectBox
                    value={props.accidentTypeId}
                    onChange={props.onAccidentTypeIdChange}
                    label={StaticHunSocialSecurityFinanceResources.CareActivityCoverageData.Label.AccidentType}
                    propertyIdentifier="AccidentTypeId"
                    automationId="AccidentTypeId"
                />
            </Ui.Flex.Item>

        </>
    );
};

export default State.observer(CarActivityCoverageDataPanelView);
