import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { SelectBox } from "@CommonControls";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import OrganizationUnitDefinitionId from "@Primitives/OrganizationUnitDefinitionId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";

interface IOrganizationUnitDefinitionSelectBoxDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IOrganizationUnitDefinitionSelectBoxProps {
    _dependencies?: IOrganizationUnitDefinitionSelectBoxDependencies;
    definitionIds: OrganizationUnitDefinitionId[];
    value: OrganizationUnitDefinitionId;
    onChange: (newValue: OrganizationUnitDefinitionId) => void;
    propertyIdentifier?: string;
}

@State.observer
class OrganizationUnitDefinitionSelectBox extends React.Component<IOrganizationUnitDefinitionSelectBoxProps> {

    @State.observable.ref private items: Array<ISelectBoxItem<OrganizationUnitDefinitionId>> = [];

    private get definitionStore() {
        return this.props._dependencies.organizationReferenceDataStore.organizationUnitDefinitionMap;
    }

    private async loadAsync() {
        if (this.props.definitionIds) {
            await this.definitionStore.ensureLoadedAsync(this.props.definitionIds);
            this.setItems(this.definitionStore.multiGet(this.props.definitionIds).map(ds => {
                return {
                    value: ds.id,
                    text: ds.name
                } as ISelectBoxItem<OrganizationUnitDefinitionId>;
            }));
        } else {
            throw new Error("OrganizationUnitDefinitionSelectBox should have specified Ids.");
        }
    }

    @State.action.bound
    private setItems(items: Array<ISelectBoxItem<OrganizationUnitDefinitionId>>) {
        this.items = items;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IOrganizationUnitDefinitionSelectBoxProps) {
        if (this.props.definitionIds !== prevProps.definitionIds) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    public render() {
        return (
            <SelectBox
                items={this.items}
                value={this.props.value}
                onChange={this.props.onChange}
                automationId="organizationUnitDefinitionSelectBox"
                propertyIdentifier={this.props.propertyIdentifier}
            />
        );
    }
}

export default connect(
    OrganizationUnitDefinitionSelectBox,
    new DependencyAdapter<IOrganizationUnitDefinitionSelectBoxProps, IOrganizationUnitDefinitionSelectBoxDependencies>(container => {
        return {
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore")
        };
    })
);