import IPlatformPlugin from "@PluginInterface/IPlatformPlugin";
import { Container } from "inversify";
import IExtensionComponentRegistry from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import { ExtensionPointType } from "@PluginInterface/ExtensionPoint/ExtensionPointType";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import ICardioUseCaseDescriptor from "./UseCases/ICardioUseCase/ICardioUseCaseDescriptor";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import IResourceFetcher from "@Toolkit/CommonWeb/Abstractions/Localization/IResourceFetcher";
import IFormExtensionRegistry from "@PluginInterface/FormExtension/IFormExtensionRegistry";
import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import "./DependencyConfiguration/ICardioDependencyConfigurations";
import ICommandFactory from "@Toolkit/CommonWeb/CommandProcessing/Definition/ICommandFactory";
import { SendInitializationPayloadCommandFactory } from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/ExternalDecisionSupport/Commands/SendInitializationPayloadCommand";

class ICardioIntegrationPlugin implements IPlatformPlugin {
    public name: string = "ICardioIntegrationPlugin";

    public async configureAsync(container: Container) {
        const applicationContext = container.get<ICurrentCultureProvider>("ICurrentCultureProvider");
        const resourceFetcher = container.get<IResourceFetcher>("IResourceFetcher");
        await resourceFetcher.fetchResourceGroupAsync("ICardioIntegrationReferenceData", "ReferenceData", applicationContext.cultureCode);
        
        this.configureOperationProcessing(container);
    }

    public configureExtensionComponents(registry: IExtensionComponentRegistry<ExtensionPointType>) {
        // none
    }

    public configureUseCases(useCaseRegistry: IUseCaseRegistry): void {
        useCaseRegistry.add(ICardioUseCaseDescriptor);
    }

    public configureFormExtensions(formExtensionRegistry: IFormExtensionRegistry): void {
        return;
    }

    public configurePermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry): void {
        return;
    }

    public configureActivities(activityRegistry: IActivityRegistry) {
        activityRegistry.register({ reference: "CardioIntegration_StartWorkflow", iconName: "heartbeat" });
    }

    public configureNData(configurator: INDataAttributeConfigurator): void {
        return;
    }

    public configureMapper(configurator: IMapperConfigurator): void {
        return;
    }

    public configureFormEngine(registry: FormEditorRegistry): void {
        return;
    }

    private configureOperationProcessing(container: Container): void {
        container.bind("ICommandFactory").to(SendInitializationPayloadCommandFactory);
    }
}

export default new ICardioIntegrationPlugin();
