import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Proxy.g";
import Di from "@Di";
import PerformanceReport from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReport";
import PerformanceReportId from "@Primitives/PerformanceReportId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import PerformanceReportIntervalGrouping from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportIntervalGrouping";
import PerformanceReportGroupingStore from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportGroupingStore";
import PerformanceReportEnumBasedGrouping from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportEnumBasedGrouping";
import PerformanceReportOutputColumnStore from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportOutputColumnStore";
import PerformanceReportGroupingType from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportGroupingType";
import PerformanceReportOutputColumnType from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/PerformanceReportOutputColumnType.g";

@Di.injectable()
export default class PerformanceReportStoreMapper extends EntityStoreMapper<Proxy.PerformanceReportDto, PerformanceReport> {
    protected storeEntityIdType = PerformanceReportId;

    public applyToStoreCore(target: PerformanceReport, response: Proxy.PerformanceReportDto) {
        const entity = response;
        const filters = response.parameters.filters;
        const groupings = response.parameters.groupings;
        const outputColumns = response.parameters.outputColumns;

        target.name = entity.name;

        const wentUnderCareAtFilters = filters.filter(x => x instanceof Proxy.PerformanceReportWentUnderCareAtDateFilterDto);
        if (wentUnderCareAtFilters && wentUnderCareAtFilters.length > 0) {
            const wentUnderCareAtFilter = (wentUnderCareAtFilters[0] as Proxy.PerformanceReportWentUnderCareAtDateFilterDto);
            target.wentUnderCareAtDateRange = new LocalDateRange(wentUnderCareAtFilter.from, wentUnderCareAtFilter.to);
        }

        const pointOfCareFilters = filters.filter(x => x instanceof Proxy.PerformanceReportPointOfCareFilterDto);
        if (pointOfCareFilters && pointOfCareFilters.length > 0) {
            const pointOfCareFilter = (pointOfCareFilters[0] as Proxy.PerformanceReportPointOfCareFilterDto);
            target.pointOfCares = pointOfCareFilter.pointOfCareIds;
        }

        const financingClassFilters = filters.filter(x => x instanceof Proxy.PerformanceReportFinancingClassFilterDto);
        if (financingClassFilters && financingClassFilters.length > 0) {
            const financingClassFilter = (financingClassFilters[0] as Proxy.PerformanceReportFinancingClassFilterDto);
            target.financingClasses = financingClassFilter.financingClassIds;
        }

        const healthcareProfessionFilters = filters.filter(x => x instanceof Proxy.PerformanceReportProfessionCodeFilterDto);
        if (healthcareProfessionFilters && healthcareProfessionFilters.length > 0) {
            const healthcareProfessionFilter = (healthcareProfessionFilters[0] as Proxy.PerformanceReportProfessionCodeFilterDto);
            target.healthcareProfessions = healthcareProfessionFilter.healthCareProfessionIds;
        }

        const referralWorkplaceTypeFilters = filters.filter(x => x instanceof Proxy.PerformanceReportReferralWorkplaceTypeFilterDto);
        if (referralWorkplaceTypeFilters && referralWorkplaceTypeFilters.length > 0) {
            const referralWorkplaceTypeFilter = (referralWorkplaceTypeFilters[0] as Proxy.PerformanceReportReferralWorkplaceTypeFilterDto);
            target.referralWorkPlaceTypes = referralWorkplaceTypeFilter.ids;
        }

        const insurerOrganizationFilters = filters.filter(x => x instanceof Proxy.PerformanceReportInsurerOrganizationFilterDto);
        if (insurerOrganizationFilters && insurerOrganizationFilters.length > 0) {
            const insurerOrganizationFilter = (insurerOrganizationFilters[0] as Proxy.PerformanceReportInsurerOrganizationFilterDto);
            target.insurerOrganization = insurerOrganizationFilter.insurerOrganizationId;
        }

        const doctorFilters = filters.filter(x => x instanceof Proxy.PerformanceReportCareDoctorFilterDto);
        if (doctorFilters && doctorFilters.length > 0) {
            const doctorFilter = (doctorFilters[0] as Proxy.PerformanceReportCareDoctorFilterDto);
            target.doctors = doctorFilter.doctorIds;
        }

        const assistantFilters = filters.filter(x => x instanceof Proxy.PerformanceReportCareAssistantFilterDto);
        if (assistantFilters && assistantFilters.length > 0) {
            const assistantFilter = (assistantFilters[0] as Proxy.PerformanceReportCareAssistantFilterDto);
            target.assistants = assistantFilter.assistantIds;
        }

        const referralWorkplaceFilters = filters.filter(x => x instanceof Proxy.PerformanceReportReferralWorkplaceFilterDto);
        if (referralWorkplaceFilters && referralWorkplaceFilters.length > 0) {
            const referralWorkplaceFilter = (referralWorkplaceFilters[0] as Proxy.PerformanceReportReferralWorkplaceFilterDto);
            target.referralWorkplaces = referralWorkplaceFilter.referralWorkPlaceIds;
        }
        
        const workplaceLocationFilters = filters.filter(x => x instanceof Proxy.PerformanceReportReferralWorkplaceLocationFilterDto);
        if (workplaceLocationFilters && workplaceLocationFilters.length > 0) {
            const workplaceLocationFilter = (workplaceLocationFilters[0] as Proxy.PerformanceReportReferralWorkplaceLocationFilterDto);
            target.isExternal = workplaceLocationFilter.onlyExternal;
        }

        const careActivityStateFilters = filters.filter(x => x instanceof Proxy.PerformanceReportCareActivityStateFilterDto);
        if (careActivityStateFilters && careActivityStateFilters.length > 0) {
            const careActivityStateFilter = (careActivityStateFilters[0] as Proxy.PerformanceReportCareActivityStateFilterDto);
            target.careActivityStates = careActivityStateFilter.careActivityStates;
        }

        const financedServicesFilters = filters.filter(x => x instanceof Proxy.PerformanceReportFinancedServicesFilterDto);
        if (financedServicesFilters && financedServicesFilters.length > 0) {
            const financedServicesFilter = (financedServicesFilters[0] as Proxy.PerformanceReportFinancedServicesFilterDto);
            target.isWithoutFinancedServices = financedServicesFilter.withoutFinancedServices;
        } 

        for (const currentItem of groupings) {
            const groupingStore = new PerformanceReportGroupingStore();
            groupingStore.isSummaryNeeded = currentItem.isSummaryNeeded;

            if (currentItem instanceof Proxy.PerformanceReportIntervalGroupingDto) {
                const intervalGrouping = new PerformanceReportIntervalGrouping();
                intervalGrouping.intervalGroupingResolution = currentItem.intervalGroupingResolution;
                groupingStore.grouping = intervalGrouping;
                groupingStore.type = PerformanceReportGroupingType.Interval;
            } else {
                const enumBasedGrouping = new PerformanceReportEnumBasedGrouping();
                if (currentItem instanceof Proxy.PerformanceReportInstituteLevelGroupingDto) {
                    enumBasedGrouping.selectedColumns = this.getSelectedColumns(currentItem.groupingFields);
                    enumBasedGrouping.selectedOrdering = currentItem.orderingField;
                    groupingStore.type = PerformanceReportGroupingType.Institute;
                } else if (currentItem instanceof Proxy.PerformanceReportSiteLevelGroupingDto) {
                    enumBasedGrouping.selectedColumns = this.getSelectedColumns(currentItem.groupingFields);
                    enumBasedGrouping.selectedOrdering = currentItem.orderingField;
                    groupingStore.type = PerformanceReportGroupingType.Site;
                } else if (currentItem instanceof Proxy.PerformanceReportAmbulanceLevelGroupingDto) {
                    enumBasedGrouping.selectedColumns = this.getSelectedColumns(currentItem.groupingFields);
                    enumBasedGrouping.selectedOrdering = currentItem.orderingField;
                    groupingStore.type = PerformanceReportGroupingType.Ambulance;
                } else if (currentItem instanceof Proxy.PerformanceReportPointOfCareLevelGroupingDto) {
                    enumBasedGrouping.selectedColumns = this.getSelectedColumns(currentItem.groupingFields);
                    enumBasedGrouping.selectedOrdering = currentItem.orderingField;
                    groupingStore.type = PerformanceReportGroupingType.PointOfCare;
                } else if (currentItem instanceof Proxy.PerformanceReportFinancingClassGroupingDto) {
                    enumBasedGrouping.selectedColumns = this.getSelectedColumns(currentItem.groupingFields);
                    enumBasedGrouping.selectedOrdering = currentItem.orderingField;
                    groupingStore.type = PerformanceReportGroupingType.FinancingClass;
                } else if (currentItem instanceof Proxy.PerformanceReportCareDoctorGroupingDto) {
                    enumBasedGrouping.selectedColumns = this.getSelectedColumns(currentItem.groupingFields);
                    enumBasedGrouping.selectedOrdering = currentItem.orderingField;
                    groupingStore.type = PerformanceReportGroupingType.CareDoctor;
                } else if (currentItem instanceof Proxy.PerformanceReportCareAssistantGroupingDto) {
                    enumBasedGrouping.selectedColumns = this.getSelectedColumns(currentItem.groupingFields);
                    enumBasedGrouping.selectedOrdering = currentItem.orderingField;
                    groupingStore.type = PerformanceReportGroupingType.CareAssistant;
                } else if (currentItem instanceof Proxy.PerformanceReportReferralWorkplaceGroupingDto) {
                    enumBasedGrouping.selectedColumns = this.getSelectedColumns(currentItem.groupingFields);
                    enumBasedGrouping.selectedOrdering = currentItem.orderingField;
                    groupingStore.type = PerformanceReportGroupingType.ReferralWorkplace;
                } else if (currentItem instanceof Proxy.PerformanceReportServiceGroupingDto) {
                    enumBasedGrouping.selectedColumns = this.getSelectedColumns(currentItem.groupingFields);
                    enumBasedGrouping.selectedOrdering = currentItem.orderingField;
                    groupingStore.type = PerformanceReportGroupingType.Service;
                }
                groupingStore.grouping = enumBasedGrouping;
            }

            target.groupings.push(groupingStore);
        }

        for (const currentItem of outputColumns) {
            if (currentItem instanceof Proxy.PerformanceReportOutputColumnDto) {
                const outputColumn = new PerformanceReportOutputColumnStore();
                outputColumn.type = currentItem.columnType as PerformanceReportOutputColumnType;
                target.outputColumns.push(outputColumn);
            }
        }
    }

    private getSelectedColumns(items: any[]) {
        const selectedColumns: boolean[] = [false, false, false];

        for (const item of items) {
            selectedColumns[item] = true;
        }

        return selectedColumns;
    }
}
