import IDiagnosisExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IDiagnosisExtensionPointProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import StaticHunSocialSecurityCareResources from "@HunSocialSecurityPlugin/BoundedContexts/Care/StaticResources/StaticHunSocialSecurityCareResources";
import Button from "@CommonControls/Button";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import HunWorklistApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/HunWorklistApiAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import ContagiousPatientReportId from "@Primitives/ContagiousPatientReportId.g";
import { IRowIndicatorStyle, RowId } from "@CommonControls/DataGrid/IDataGridProps";
import HunSocialSecurityUseCases from "@HunSocialSecurityPlugin/UseCases/HunSocialSecurityUseCases";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import HunCareContagiousPatientReportApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ContagiousPatientReport/HunCareContagiousPatientReportApiAdapter";
import Styles from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Panels/ContagiousPatientReportPanels/StandaloneContagiousPatientReportPanel.less";
import ContagiousPatientReportState from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReportState";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";

interface IDiagnosisExtensionPanelDependencies {
    worklistApiAdapter: HunWorklistApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
    contagiousPatientReportApiAdapter: HunCareContagiousPatientReportApiAdapter;
}

interface IDiagnosisExtensionPanelProps extends IDiagnosisExtensionPointProps {
    _dependencies?: IDiagnosisExtensionPanelDependencies;
}

@State.observer
class DiagnosisExtensionPanel extends React.Component<IDiagnosisExtensionPanelProps> {

    @State.observable private definition: IWorklistDefinition = null;
    @State.observable private isLoading: boolean = false;
    @State.observable private useCaseState: INDataUseCaseState = null;
    @State.observable private selectedRowId: string = null;
    private refreshListEvent = new TypedAsyncEvent();

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.loadingState("isLoading")
    private async loadAsync() {
        await this.loadDefinitionAsync();
    }

    @State.bound
    private async loadDefinitionAsync() {
        const response =
            await this.props._dependencies.worklistApiAdapter.getContagiousPatientReportForPatientBoundWorklistDefinitionAsync(
                this.props.diagnosisStore.conditionVersionSelector.id,
                this.props.patientId);
        State.runInAction(() => {
            this.definition = response.value;
        });
    }

    private get condition() {
        return this.props.diagnosisStore?.conditionVersionSelector &&
            this.props._dependencies.careReferenceDataStore.condition.get(this.props.diagnosisStore.conditionVersionSelector);
    }

    private get getSubTitle() {
        return `${this.condition?.code} - ${this.condition?.name}`;
    }

    @State.bound
    private renderAddButton() {
        return (
            <Button
                visualStyle="primary"
                text={StaticHunSocialSecurityCareResources.ContagiousPatientReport.AddContagiousPatientReport}
                iconName="plus"
                onClickAsync={this.addAsync}
                automationId="addContagiousPatientReportButton"
                permissionCheckOperationNames="Add"
                permissionDeniedStyle="disabled"
            />
        );
    }

    @State.bound
    private async addAsync() {
        const resp = await this.props._dependencies.contagiousPatientReportApiAdapter.createEmptyContagiousPatientOnsetReportAsync(
            this.props.patientId,
            this.props.diagnosisStore.conditionVersionSelector.id,
            this.props.pointOfCareId,
            this.props.careActivityId,
            false
        );

        this.setEditNewUseCaseState(resp.value);
        await this.refreshListEvent.emitAsync();
    }

    @State.action.bound
    private setEditNewUseCaseState(id: ContagiousPatientReportId) {
        this.selectedRowId = id.value;
        this.useCaseState = {
            displayMode: UseCaseDisplayMode.MasterDetail,
            useCase: new UseCaseIdentifier(HunSocialSecurityUseCases.editContagiousPatientReport),
            useCaseArguments: [new UseCaseArgument(id, "contagiousPatientReportId"), new UseCaseArgument(true, "isNew")]
        } as INDataUseCaseState;
    }

    @State.action.bound
    private setUseCaseState(id: RowId, useCase: INDataUseCaseState) {
        const contagiousPatientReportId = useCase?.useCaseArguments?.find(a => a.value instanceof ContagiousPatientReportId)?.value;

        this.useCaseState = useCase;
        this.selectedRowId = contagiousPatientReportId?.value ?? id?.toString();
    }

    @State.bound
    private setDetailPanelProps(useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) {
        return {
            extensionController: this.props.extensionController
        };
    }
    private getRowIndicatorStyles(row: any, rowId: string, rowIndex: number) {
        let style = Styles.underRecording;

        switch (row.ContagiousPatientReportBased_State as ContagiousPatientReportState) {
            case ContagiousPatientReportState.Approved:
                style = Styles.approved; break;
            case ContagiousPatientReportState.Deleted:
                style = Styles.deleted; break;
            case ContagiousPatientReportState.WaitingForApproval:
                style = Styles.waitingForApproval; break;
            default: break;
        }

        return {
            className: style
        } as IRowIndicatorStyle;
    }

    @State.computed private get masterHasDefaultButtons() {
        return this.useCaseState?.displayMode !== UseCaseDisplayMode.MasterDetail;
    }

    private get masterContainerStyle(): React.CSSProperties {
        return { height: "calc(100% - 9px)", marginTop: "9px" };
    }

    @State.bound
    private operationsToCheck() {
        const result = {};

        result["Add"] = async () => {
            await this.props._dependencies.contagiousPatientReportApiAdapter.createEmptyContagiousPatientOnsetReportAsync(
                this.props.patientId,
                this.props.diagnosisStore.conditionVersionSelector.id,
                this.props.pointOfCareId,
                this.props.careActivityId,
                true
            );
        };

        return result;
    }

    public render() {
        return (
            <PermissionCheckContextProvider operationsToCheck={this.operationsToCheck()}>
                <MasterDetail.Detail
                    title={StaticHunSocialSecurityCareResources.ContagiousPatientReport.Title}
                    subTitle={this.getSubTitle}
                    iconName="disease"
                    toolbar={this.renderAddButton()}
                    {...this.props}
                >
                    {!this.isLoading ?
                        <NDataPanel
                            definition={this.definition}
                            hasRefreshButton={this.masterHasDefaultButtons}
                            refreshListEvent={this.refreshListEvent}
                            useCaseState={this.useCaseState}
                            onChange={this.setUseCaseState}
                            selectedRowId={this.selectedRowId}
                            disableDetailStrictMode
                            hideMasterHeader
                            onGetPanelProps={this.setDetailPanelProps}
                            getRowIndicatorStyle={this.getRowIndicatorStyles}
                            hasPrintButton={this.masterHasDefaultButtons}
                            noInnerSpacing
                            gridContainerStyle={this.masterContainerStyle}
                        /> :
                        <></>}
                </MasterDetail.Detail>
            </PermissionCheckContextProvider>
        );
    }
}

export default connect(
    DiagnosisExtensionPanel,
    new DependencyAdapter<IDiagnosisExtensionPanelProps, IDiagnosisExtensionPanelDependencies>(container => {
        return {
            worklistApiAdapter: container.resolve("HunWorklistApiAdapter"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            contagiousPatientReportApiAdapter: container.resolve("HunCareContagiousPatientReportApiAdapter")
        };
    })
);