import BusinessErrorBase from "@HisPlatform/Model/DomainModel/BusinessError/BusinessErrorBase";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";

export default class CannotDeleteAppointmentScheduleDefinitionError extends BusinessErrorBase {
    constructor(public schedulingServiceIdsInUse: SchedulingServiceId[]) {
        super();
    }

    public get name(): string {
        return "CannotDeleteAppointmentScheduleDefinitionError";
    }
}