import Di from "@Di";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import EhiUserContext from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiUserContext";
import EhiToken from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiToken";
import { NoEhiToken } from "./NoEhiToken";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import PractitionerIdentifierStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerIdentifierStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import PractitionerId from "@Primitives/PractitionerId.g";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import { NoEhiId } from "./NoEhiId";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import StaticHunEHealthInfrastructureUserManagementResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/StaticResources/StaticHunEHealthInfrastructureUserManagementResources";
import { NoPractitionerId } from "./NoPractitionerId";

@Di.injectable()
export default class EhiTokenProvider {
    constructor(
        @Di.inject("UserContext") private readonly userContext: UserContext,
        @Di.inject("EhiUserContext") private readonly ehiUserContext: EhiUserContext,
        @Di.inject("PractitionerApiAdapter") private readonly practitionerApiAdapter: PractitionerApiAdapter,
        @Di.inject("OrganizationReferenceDataStore") private readonly organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("IDialogService") private readonly dialogService: IDialogService
    ) { }

    private readonly ehiIdentifierSystemId = new IdentifierSystemId("HunEHealthInfrastructure");

    public async getCurrentTokenOrLoginAsync(): Promise<EhiToken | typeof NoEhiToken | typeof NoEhiId | typeof NoPractitionerId> {
        if (this.ehiUserContext.isLoggedIn) {
            return this.ehiUserContext.token;
        }

        if (isNullOrUndefined(this.userContext?.practitionerId) || !this.ehiUserContext.hasEhiId) {
            await this.dialogService.ok(StaticWebAppResources.Common.DialogTitle.WarningTitle, StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.UserHasNoEhiIdMessage);
            return NoEhiId;
        }

        return await this.loginAsync(this.userContext.practitionerId);
    }

    public async loginAsync(
        ehiServiceCallPractitionerId: PractitionerId,
        loginToEhiWithAnotherUserConfirmationDialogTitle?: string,
        loginToEhiWithAnotherUserConfirmationDialogMessage?: string): Promise<EhiToken | typeof NoEhiToken | typeof NoEhiId | typeof NoPractitionerId> {
        if (!ehiServiceCallPractitionerId) {
            await this.dialogService.ok(StaticWebAppResources.Common.DialogTitle.WarningTitle, StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.UserHasNoPractitionerIdMessage);
            return NoPractitionerId;
        }

        const practitionerIdentifiers = await this.practitionerApiAdapter.getIdentifiersOfPractitionerAsync(ehiServiceCallPractitionerId);
        const practitionerEhiIdentifier = this.getEhiIdentifier(practitionerIdentifiers.value);

        if (isNullOrUndefined(practitionerEhiIdentifier)) {
            await this.dialogService.ok(StaticWebAppResources.Common.DialogTitle.WarningTitle, StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.UserHasNoEhiIdMessage);
            return NoEhiId;
        }

        const practitioner = await this.organizationReferenceDataStore.doctorMap.getOrLoadAsync(ehiServiceCallPractitionerId);
        if (isNullOrUndefined(practitioner)) {
            await this.dialogService.ok(StaticWebAppResources.Common.DialogTitle.WarningTitle, StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Messages.UserHasNoPractitionerDataMessage);
            return NoPractitionerId;
        }

        const loggedInEhiUserEqualsPractitioner = this.ehiUserContext.isLoggedIn && this.ehiUserContext.token.ehiUserIdentifierValue === practitionerEhiIdentifier.identifier.value;
        const practitionerName = `${practitioner.name.prefix} ${practitioner.name.familyName} ${practitioner.name.givenName1}`;

        if (this.ehiUserContext.isLoggedIn) {
            if (loggedInEhiUserEqualsPractitioner) {
                return this.ehiUserContext.token;
            } else {
                const dialogResult = await this.dialogService.yesNo(loginToEhiWithAnotherUserConfirmationDialogTitle, loginToEhiWithAnotherUserConfirmationDialogMessage);
                if (dialogResult.resultCode === DialogResultCode.Yes) {
                    await this.ehiUserContext.logoutAsync();
                    const token = (await this.ehiUserContext.loginAsync(practitionerEhiIdentifier.identifier.value, practitionerName))?.token;
                    return this.getTokenResult(token);
                } else {
                    return NoEhiToken;
                }
            }
        } else {
            const token = (await this.ehiUserContext.loginAsync(practitionerEhiIdentifier.identifier.value, practitionerName))?.token;
            return this.getTokenResult(token);
        }
    }

    private getEhiIdentifier(identifiers: PractitionerIdentifierStore[]) {
        return identifiers && identifiers.find(ia => ValueWrapper.equals(this.ehiIdentifierSystemId, ia.identifier.identifierSystemId));
    }

    private getTokenResult(token: any) {
        if (!isNullOrUndefined(token)) {
            return token;
        } else {
            return NoEhiToken;
        }
    }
}