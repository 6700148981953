import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import PractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/PractitionerRecommendation";
import PractitionerRecommendationId from "@Primitives/PractitionerRecommendationId.g";
import { mapDosage } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/MedicationRequestMapperHelpers";
import PractitionerRecommendationStatus from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/PractitionerRecommendationStatus";
import Di from "@Di";
import MedicationPractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/MedicationPractitionerRecommendation";
import EquipmentPractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/EquipmentPractitionerRecommendation";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IPractitionerRecommendationStatusColumnData from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/IPractitionerRecommendationStatusColumnData";

type ResponseType = Proxy.GetPractitionerRecommendationCommandResponse | Proxy.UpdatePractitionerRecommendationCommandResponse | Proxy.AddPractitionerRecommendationCommandResponse | Proxy.PractitionerRecommendationDto | Proxy.RevokePractitionerRecommendationFinalizationCommandResponse;

@Di.injectable()
export default class PractitionerRecommendationStoreMapper extends LockingEntityStoreMapper<Proxy.PractitionerRecommendationDto, PractitionerRecommendation, ResponseType> {
    protected storeEntityIdType = PractitionerRecommendationId;

    protected entityDtoSelector(response: ResponseType) {
        if (response instanceof Proxy.PractitionerRecommendationDto) {
            return response;
        } else if (response instanceof Proxy.GetPractitionerRecommendationCommandResponse ||
            response instanceof Proxy.UpdatePractitionerRecommendationCommandResponse ||
            response instanceof Proxy.FinalizePractitionerRecommendationCommandResponse ||
            response instanceof Proxy.AddPractitionerRecommendationCommandResponse ||
            response instanceof Proxy.RevokePractitionerRecommendationFinalizationCommandResponse) {
            return response.practitionerRecommendation;
        }
        return null;
    }

    protected applyToStoreCore(target: PractitionerRecommendation, response: any): void {
        if (target instanceof MedicationPractitionerRecommendation) {
            const dto = this.entityDtoSelector(response) as Proxy.MedicationPractitionerRecommendationDto;
            target.careActivityId = dto.careActivityId;
            target.claimTypeId = dto.claimTypeId;
            target.conditionId = dto.conditionId;
            target.dosage = mapDosage(dto.dosage);
            target.medicationFormId = dto.medicationFormId;
            target.medicationVersionSelector = !isNullOrUndefined(dto.medicationVersionSelector?.entityId) ? { id: dto.medicationVersionSelector?.entityId, validOn: dto.medicationVersionSelector?.validOn } : null;
            target.recordedOn = dto.recordedOn;
            target.medicationSubsidyId = dto.medicationSubsidyId;
            target.subsidyOptionId = dto.subsidyOptionId;
            target.validityDurationInMonths = dto.validityDurationInMonths;
            target.useMedicationName = dto.useMedicationName;
            target.statusColumn = {
                digitalPractitionerRecommendationIdentifier: response.digitalPractitionerRecommendationIdentifier,
                status: dto.status as any as PractitionerRecommendationStatus
            } as IPractitionerRecommendationStatusColumnData;
        } else if (target instanceof EquipmentPractitionerRecommendation) {
            const dto = this.entityDtoSelector(response) as Proxy.EquipmentPractitionerRecommendationDto;
            target.careActivityId = dto.careActivityId;
            target.claimTypeId = dto.claimTypeId;
            target.conditionId = dto.conditionId;
            target.furtherConditions = dto.furtherConditions;
            target.amount = dto.amount;
            target.notes = dto.notes;
            target.professionalExamId = dto.professionalExamId;
            target.recommendedEquipment1 = { id: dto.recommendedEquipment1?.entityId, validOn: dto.recommendedEquipment1?.validOn };
            target.recommendedEquipment2 = { id: dto.recommendedEquipment2?.entityId, validOn: dto.recommendedEquipment2?.validOn };
            target.validTo = dto.validTo;
            target.recordedOn = dto.recordedOn;
            target.equipmentSupportTypeId = dto.equipmentSupportTypeId;
            target.statusColumn = {
                digitalPractitionerRecommendationIdentifier: response.digitalPractitionerRecommendationIdentifier,
                status: dto.status as any as PractitionerRecommendationStatus
            } as IPractitionerRecommendationStatusColumnData;
        }
    }
}