import IFormValidationRule, { IFormValidationRuleParameters } from "@Toolkit/FormEngine/Model/Schema/Validation/IFormValidationRule";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import RuleTypeId from "@Primitives/RuleTypeId.g";

export default class EditableShouldNotBeLongerThanValidationRule implements IFormValidationRule {
    public get ruleType() { return RuleTypeId.ShouldNotBeLongerThan.value; }

    @State.observable.ref public maxLength: number;
    
    public get ruleParameters() {
        return {
            maxLength: this.maxLength
        };
    }

    constructor(
        public entityPath: string,
        ruleParameters: IFormValidationRuleParameters & { maxLength: number }
    ) {
        this.maxLength = ruleParameters.maxLength;
    }

    @State.action.bound
    public setMaxLength(value: number) {
        this.maxLength = value;
    }
}