import React from "react";
import { ITokenSettingsPanelPropsBase } from "@PluginInterface/BoundedContexts/DocumentManagement/TokenSettingsPanelRegistry/ITokenSettingsPanelRegistry";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import { UniversalEnumSelector } from "@CommonControls";

interface IExtensibleEnumTokenFormatterSettingsPanelProps extends ITokenSettingsPanelPropsBase<Proxy.ExtensibleEnumTokenValueFormatterSettingsDto> {
}

/** @screen */
export default class ExtensibleEnumTokenFormatterSettingsPanel extends React.Component<IExtensibleEnumTokenFormatterSettingsPanelProps> {

    public render() {
        return (
            <UniversalEnumSelector
                displayMode="radio"
                direction="column"
                value={this.props.tokenFormatterSettings?.formatMode}
                enumName={"CodeAndNameFormatMode2"}
                enumOrigin="server"
                enumType={Proxy.CodeAndNameFormatMode2}
                onChange={this.setFormatValue}
                clearable={false}
                automationId="extensibleEnumTokenFormatSelector"
            />
        );
    }

    @State.bound
    private setFormatValue(value: Proxy.CodeAndNameFormatMode2) {
        this.props.onSettingsChange(new Proxy.ExtensibleEnumTokenValueFormatterSettingsDto({
            formatMode: value
        }), null);
    }
}