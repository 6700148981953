import React, { useCallback } from "react";
import Styles from "./DataGrid.less";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import Icon from "@CommonControls/Icon";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";

interface IDataGridHeaderProps {
    hasCheckCell: boolean;
    hasRowIndicator: boolean;
    columns: IDataGridColumnProps[];
    onClick: (column: IDataGridColumnProps) => void;
    orderingMap: Map<string | number, IOrderingState>;
    columnHeaderRef: React.RefObject<HTMLTableRowElement>;
}

interface IHeaderCellProps {
    columnProps: IDataGridColumnProps;
    onClick: (column: IDataGridColumnProps) => void;
    orderingMap: Map<string | number, IOrderingState>;
}


const HeaderCell: React.FC<IHeaderCellProps> = props => {
    const click = useCallback(() => {
        props.onClick(props.columnProps);
    }, [props.onClick, props.columnProps]);

    const ordering = props.orderingMap.get(props.columnProps.id);

    const orderingArrow = ordering && (ordering.direction === "asc" ? <Icon iconName="caretUp" automationId={`${props.columnProps.id}_caretUp`} /> : <Icon iconName="caretDown" automationId={`${props.columnProps.id}_caretDown`} />);

    const orderingState = ordering ? (ordering.direction === "asc" ? "ascending" : "descending") : "none";

    const headerStyle = { textAlign: props.columnProps.headerTextAlign };

    return (
        <th className={combineClasses(props.columnProps.leftPadding === "small" && Styles.leftPaddingSmall, props.columnProps.isOrderable === true && Styles.orderable)} style={headerStyle} onClick={click} aria-sort={orderingState}>{props.columnProps.header}{orderingArrow}</th>
    );
};


const DataGridHeader: React.FC<IDataGridHeaderProps> = props => {
    return (
        <tr className={Styles.columnHeaderRow} ref={props.columnHeaderRef}>
            {props.hasRowIndicator && <th />}
            {props.hasCheckCell && <th />}
            {props.columns.map(column => (
                <HeaderCell
                    key={column.id}
                    onClick={props.onClick}
                    columnProps={column}
                    orderingMap={props.orderingMap}
                >
                    {column.header}
                </HeaderCell>
            ))}
        </tr>
    );
};

export default DataGridHeader;
