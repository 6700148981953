import ExtendedFilterStoreBase from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ExtendedFilterStoreBase";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import MedicationTypeId from "@Primitives/MedicationTypeId.g";
import { createFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterHelpers";
import { IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import MedicationEquipmentClassificationTreeNode from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationEquipmentClassificationTreeGrid/MedicationEquipmentClassificationTreeNode";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import { isNullOrWhiteSpace } from "@Toolkit/CommonWeb/NullCheckHelpers";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";

export default class MedicationFilterStore extends ExtendedFilterStoreBase {
    private static readonly isValidFilterName: string = "MedicationBased_IsValid";

    private static readonly medicationTypes = [
        MedicationTypeId.G1,
        MedicationTypeId.G3,
        MedicationTypeId.G4,
        MedicationTypeId.G5,
        MedicationTypeId.G6,
        MedicationTypeId.G7,
        MedicationTypeId.H1,
        MedicationTypeId.T1
    ];

    constructor(
        filterStore: IFilterStore
    ) {
        super(filterStore);
        this.setIncludeInvalidatedProducts(false);
    }

    @State.observable.ref public isoCodes: string[] = [];
    @State.observable.ref public medicationSubsidyClaimTypeId: MedicationSubsidyClaimTypeId = null;
    @State.observable.ref public medicationEquipmentClassificationStructure: MedicationEquipmentClassificationTreeNode[] = [];
    @State.observable private isTypeGyse = false;

    @State.action.bound
    public setIsTypeGyse(value: boolean) {
        this.isTypeGyse = value;
        this.setFilter("MedicationBased_MedicationTypeId", value === false ? MedicationFilterStore.medicationTypes : MedicationTypeId.S);
        if (value === false) {
            this.isoCodes = [];
            this.clearMedicationEquipmentClassificationStructure(this.medicationEquipmentClassificationStructure);
        }
    }

    @State.action.bound
    public setMedicationType(medicationTypeId: MedicationTypeId) {
        let medicationTypeIds;
        if (!medicationTypeId) {
            medicationTypeIds = this.isTypeGyse ? MedicationTypeId.S : MedicationFilterStore.medicationTypes;
        } else {
            medicationTypeIds = medicationTypeId;
        }
        this.setFilter("MedicationBased_MedicationTypeId", medicationTypeIds);
    }

    @State.action.bound
    public setIsoCodes(node: MedicationEquipmentClassificationTreeNode) {
        if (node.isChecked === true) {
            const childCodes = this.getChildCodes(node.children);
            this.isoCodes = this.isoCodes.filter(i => !childCodes.includes(i));
            if (!this.isoCodes.includes(node.code)) {
                this.isoCodes.push(node.code);
            }
        } else if (node.isChecked === false) {
            const childCodes = this.getChildCodes(node.children);
            this.isoCodes = this.isoCodes.filter(i => !childCodes.includes(i));
            if (this.isoCodes.includes(node.code)) {
                const index = this.isoCodes.indexOf(node.code);
                this.isoCodes.splice(index, 1);
            }
        } else {
            if (this.isoCodes.includes(node.code)) {
                const index = this.isoCodes.indexOf(node.code);
                this.isoCodes.splice(index, 1);
                const childCodes = this.getCheckedChildCodes(node.children);
                for (const code of childCodes) {
                    if (!this.isoCodes.includes(code)) {
                        this.isoCodes.push(code);
                    }
                }
            }
        }
        this.setFilter("MedicationBased_MedicationEquipmentClassificationIdentifiers", this.isoCodes.join(";"));
    }

    @State.action.bound
    public setMedicationEquipmentClassificationStructure(newValue: MedicationEquipmentClassificationTreeNode[]) {
        this.medicationEquipmentClassificationStructure = newValue;
    }

    @State.action.bound
    public setMedicationSubsidyClaimTypeId(newValue: MedicationSubsidyClaimTypeId) {
        this.medicationSubsidyClaimTypeId = newValue;
        this.setFilter("MedicationBased_MedicationSubsidyClaimTypes", newValue);
        this.setMedicationSubsidyClaimTypeIdForClassification(newValue);
    }

    @State.action.bound
    public setMedicationSubsidyClaimTypeIdForClassification(newValue: MedicationSubsidyClaimTypeId) {
        this.setFilter("MedicationBased_MedicationSubsidyClassification", newValue);
    }

    @State.action.bound
    public setIncludeInvalidatedProducts(value: boolean): void {
        this.setIsValid(!value);
    }

    @State.computed public get medicationSubsidyClaimTypeIdForClassification() {
        return this.getFilter("MedicationBased_MedicationSubsidyClassification");
    }

    @State.computed
    public get isValid() {
        return this.getFilter(MedicationFilterStore.isValidFilterName);
    }

    @State.bound
    private getCheckedChildCodes(children: MedicationEquipmentClassificationTreeNode[]): string[] {
        let childCodes: string[] = [];
        if (children && children.length > 0) {
            for (const child of children) {
                if (child.isChecked === true) {
                    childCodes.push(child.code);
                } else if (child.isChecked === null) {
                    childCodes = childCodes.concat(this.getCheckedChildCodes(child.children));
                }
            }
        }
        return childCodes;
    }

    @State.bound
    private getChildCodes(children: MedicationEquipmentClassificationTreeNode[]): string[] {
        let childCodes: string[] = [];
        if (children && children.length > 0) {
            for (const child of children) {
                childCodes = childCodes.concat(this.getChildCodes(child.children));
                childCodes.push(child.code);
            }
        }
        return childCodes;
    }
    @State.action.bound
    public setGeneralSearch(newValue: string) {
        const filterValue = isNullOrWhiteSpace(newValue) ? "" : newValue;
        this.setFilter("MedicationBased_GeneralMedicationSearchFilter", filterValue);
    }

    @State.action.bound
    public clearFilters() {
        this.setIsTypeGyse(false);
        this.setMedicationSubsidyClaimTypeId(null);
        this.setGeneralSearch(null);
        this.setIncludeInvalidatedProducts(false);
    }

    @State.action.bound
    private clearMedicationEquipmentClassificationStructure(nodes: MedicationEquipmentClassificationTreeNode[]) {
        for (const node of nodes) {
            if (node.children && node.children.length > 0) {
                this.clearMedicationEquipmentClassificationStructure(node.children);
            }
            node.isChecked = false;
            this.setIsoCodes(node);
        }
    }

    @State.action.bound
    public clearAllFilters() {
        this.clearFilters();
        this.filterStore.__reset();
    }

    @State.action.bound
    public setEquivalenceId(value: number) {
        this.setFilter("MedicationBased_EquivalenceId", value);
    }

    @State.action.bound
    public setSupportGroupId(value: number) {
        this.setFilter("MedicationBased_SupportGroupId", value);
    }

    @State.action.bound
    public setIsNormative(value: boolean) {
        this.setFilter("MedicationBased_IsNormative", value);
    }

    @State.action.bound
    public setIsValid(value: boolean) {
        return this.setFilter(MedicationFilterStore.isValidFilterName, value);
    }

    @State.computed
    public get generalSearch() {
        return this.getFilter("MedicationBased_GeneralMedicationSearchFilter");
    }

    @State.computed
    public get medicationType() {
        return this.getFilter("MedicationBased_MedicationTypeId");
    }

    @State.computed
    public get includeInvalidatedProducts() {
        return !this.isValid;
    }

    public static getFilterDescriptors() {
        const descriptors: IDataGridFilterDescriptor[] = [];

        const medicationTypeIdFilterDescriptor = createFilterDescriptor(
            "MedicationBased_MedicationTypeId",
            null,
            WorklistConditionType.EntityId
        );

        const isoCodeFilterDescriptor = createFilterDescriptor(
            "MedicationBased_MedicationEquipmentClassificationIdentifiers",
            {
                serialize: (value: string) => {
                    return value;
                },
                deserialize: (value: string) => {
                    return value;
                }
            } as IDataGridFilterValueSerializer<string>,
            WorklistConditionType.String
        );
        const generalSearchFilterDescriptor = createFilterDescriptor("MedicationBased_GeneralMedicationSearchFilter", {
            serialize: (value: string) => value,
            deserialize: (value: string) => value
        } as IDataGridFilterValueSerializer<string>,
            WorklistConditionType.String);
        descriptors.push(generalSearchFilterDescriptor);

        const equivalenceIdDescriptor = createFilterDescriptor(
            "MedicationBased_EquivalenceId",
            null,
            WorklistConditionType.Numeric
        );

        const supportGroupIdDescriptor = createFilterDescriptor(
            "MedicationBased_SupportGroupId",
            null,
            WorklistConditionType.Numeric
        );

        const isNormativeDescriptor = createFilterDescriptor(
            "MedicationBased_IsNormative",
            null,
            WorklistConditionType.Boolean
        );

        const classificationDescriptor = createFilterDescriptor(
            "MedicationBased_MedicationSubsidyClassification",
            null,
            WorklistConditionType.EntityId
        );

        const isValidDescriptor = createFilterDescriptor(
            MedicationFilterStore.isValidFilterName,
            null,
            WorklistConditionType.Boolean
        );

        descriptors.push(medicationTypeIdFilterDescriptor);
        descriptors.push(isoCodeFilterDescriptor);
        descriptors.push(equivalenceIdDescriptor);
        descriptors.push(supportGroupIdDescriptor);
        descriptors.push(isNormativeDescriptor);
        descriptors.push(classificationDescriptor);
        descriptors.push(isValidDescriptor);

        return descriptors;
    }
}