import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import {ICancerClassificationData} from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CancerClassificationDataStore";
import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";

interface ICancerClassificationDataSelectBox extends ISelectBoxBaseProps {
    allItems: ICancerClassificationData[];
}

@State.observer
export default class CancerClassificationDataSelectBox extends React.Component<ICancerClassificationDataSelectBox> {

    @State.computed private get items(): ISelectBoxItem[] {
        return this.props.allItems.map(this.toSelectBoxItem);
    }

    @State.bound
    private renderCustomValue(props: any) {
        const value = this.props.allItems.find(i => i.id.value === props.data.value.value);
        return <><b>{value.code}</b>{` - ${value.value}`}</>;
    }

    @State.bound
    private toSelectBoxItem(item: ICancerClassificationData) {
        return {
            text: `${item.code} - ${item.value}`,
            value: item.id
        } as ISelectBoxItem;
    }

    public render() {
        return (
            <Ui.SelectBox
                {...this.props}
                loading={!this.items}
                items={this.items}
                customValueRenderer={this.renderCustomValue}
            />
        );
    }
}
