import FormExtensionBase from "@PluginInterface/FormExtension/FormExtensionBase";
import Di from "@Di";
import IPatientsCareActivitiesFilterStore from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientsCareActivitiesFilterStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IPatientCareActivitiesInputParams } from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientCareActivitiesInputParams";
import IDataGridDataSource from "@CommonControls/DataGrid/DataSource/IDataGridDataSource";
import EhiPatientsCareActivitiesDataSource from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiPatientCareActivitiesDataSource/EhiPatientCareActivitiesDataSource";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import EhiCareServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareServiceFactory";

@Di.injectable()
export default class EhiPatientCareActivitiesExtension extends FormExtensionBase<IPatientCareActivitiesInputParams> {
    
    constructor(
        @Di.inject("HunEhiCareReferenceDataStore") private hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore,
        @Di.inject("EhiCareServiceFactory") private ehiCareServiceFactory: EhiCareServiceFactory,
    ) {
        super();

        this.registerCallback("createDataSource", this.createDataSource);
    }

    @State.bound
    private createDataSource(inputParams: IPatientCareActivitiesInputParams): IDataGridDataSource<any, IPatientsCareActivitiesFilterStore> {
        return new EhiPatientsCareActivitiesDataSource(
            inputParams.patientsCareActivitiesApiAdapter,
            inputParams.careDocumentApiAdapter,
            inputParams.organizationReferenceDataStore,
            inputParams.careReferenceDataStore,
            this.hunEhiCareReferenceDataStore,
            this.ehiCareServiceFactory,
            inputParams.onDataLoadErrorAsync
        );
    }
}