import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalConditionHistoryItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemBase";
import { MedicalAlertCodeSelectorItem } from "@HisPlatformControls/MedicalAlertCodeSelector/MedicalAlertCodeSelectorItem";
import MedicalAlertId from "@Primitives/MedicalAlertId.g";

export default class MedicalAlertStatementHistoryItem extends MedicalConditionHistoryItemBase {
    @State.observable.ref public description: string;
    @State.observable.ref public medicalAlertId: MedicalAlertId;
    @State.observable.ref public statementDate: LocalDate;

    @State.computed public get medicalAlertCodeSelectorItem() {
        return new MedicalAlertCodeSelectorItem({ id: this.medicalAlertId });
    }

    @State.action.bound
    public setStatementDate(newValue: LocalDate) {
        this.statementDate = newValue;
    }

    @State.action.bound
    public setDescription(newValue: string) {
        this.description = newValue;
    }

    @State.action.bound
    public setMedicalAlert(newValue: MedicalAlertCodeSelectorItem) {
        this.medicalAlertId = newValue.id;
    }
}
