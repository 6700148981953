import State from "@Toolkit/ReactClient/Common/StateManaging";
import CountryId from "@Primitives/CountryId.g";
import GenderId from "@Primitives/GenderId.g";
import ExtendedFilterStoreBase from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ExtendedFilterStoreBase";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import { IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { createFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterHelpers";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import { isNullOrWhiteSpace } from "@Toolkit/CommonWeb/NullCheckHelpers";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import SearchPatientQueryVitalStatus from "@HisPlatform/BoundedContexts/Care/Api/PatientRegister/Enum/SearchPatientQueryVitalStatus.g";

export default class PatientFilterStore extends ExtendedFilterStoreBase {

    constructor(
        filterStore: IFilterStore
    ) {
        super(filterStore);
    }

    @State.computed public get patientIdentifier() {
        const patientIdentifier = this.getFilter("PatientBased_Identifier");
        return patientIdentifier as string;
    }

    @State.computed public get careIdentifier() {
        const careIdentifier = this.getFilter("PatientBased_CareIdentifierFilter");
        return careIdentifier as string;
    }

    @State.computed public get serviceRequestIdentifier() {
        const serviceRequestIdentifier = this.getFilter("PatientBased_ServiceRequestIdentifierFilter");
        return serviceRequestIdentifier as string;
    }

    @State.computed public get otherIdentifier() {
        const otherIdentifier = this.getFilter("PatientBased_DocumentFilter");
        return otherIdentifier as string;
    }

    @State.computed public get citizenshipCountryId() {
        const citizenshipCountryId = this.getFilter("PatientBased_CitizenshipCountryIdFilter");
        return citizenshipCountryId;
    }
    
    @State.computed public get genderId() {
        const genderId = this.getFilter("PatientBased_GenderId");
        return genderId;
    }

    @State.computed public get vitalStatus(): SearchPatientQueryVitalStatus {
        const vitalStatus = this.getFilter("PatientBased_VitalStatus");
        return vitalStatus;
    }

    @State.computed public get generalSearch() {
        return this.getFilter("PatientBased_GeneralPatientSearchFilter");
    }

    @State.computed public get includeDeleted() {
        const includeDeleted = this.getFilter("PatientBased_IncludeDeletedFilter");
        return includeDeleted;
    }

    @State.action.bound
    public setPatientIdentifier(newValue: string) {
        const filterValue = isNullOrWhiteSpace(newValue) ? "" : newValue;
        this.setFilter("PatientBased_Identifier", filterValue);
    }

    @State.action.bound
    public setCareIdentifier(newValue: string) {
        const filterValue = isNullOrWhiteSpace(newValue) ? "" : newValue;
        this.setFilter("PatientBased_CareIdentifierFilter", filterValue);
    }

    @State.action.bound
    public setServiceRequestIdentifier(newValue: string) {
        const filterValue = isNullOrWhiteSpace(newValue) ? "" : newValue;
        this.setFilter("PatientBased_ServiceRequestIdentifierFilter", filterValue);
    }

    @State.action.bound
    public setDocument(newValue: string) {
        const filterValue = isNullOrWhiteSpace(newValue) ? "" : newValue;
        this.setFilter("PatientBased_DocumentFilter", filterValue);
    }

    @State.action.bound
    public setCitizenshipCountryId(newValue: CountryId) {
        this.setFilter("PatientBased_CitizenshipCountryIdFilter", newValue);
    }

    @State.action.bound
    public setGenderId(newValue: GenderId) {
        this.setFilter("PatientBased_GenderId", newValue);
    }

    @State.action.bound
    public setVitalStatus(newValue: SearchPatientQueryVitalStatus) {
        this.setFilter("PatientBased_VitalStatus", newValue);
    }

    @State.action.bound
    public setGeneralSearch(newValue: string) {
        const filterValue = isNullOrWhiteSpace(newValue) ? "" : newValue;
        this.setFilter("PatientBased_GeneralPatientSearchFilter", filterValue);
    }

    @State.action.bound
    public setIncludeDeleted(value: boolean | null) {
        this.setFilter("PatientBased_IncludeDeletedFilter", value);
    }

    @State.action.bound
    public clearFilters() {
        this.setPatientIdentifier(null);
        this.setCareIdentifier(null);
        this.setServiceRequestIdentifier(null);
        this.setDocument(null);
        this.setCitizenshipCountryId(null);
        this.setGenderId(null);
        this.setVitalStatus(SearchPatientQueryVitalStatus.Unspecified);
        this.setGeneralSearch(null);
    }

    @State.action.bound
    public clearAllFilters() {
        this.clearFilters();
        this.filterStore.__reset();
    }

    public static getFilterDescriptors() {
        const descriptors: IDataGridFilterDescriptor[] = [];

        const identifierFilterDescriptor = createFilterDescriptor("PatientBased_Identifier", {
            serialize: (value: string) => value,
            deserialize: (value: string) => value
            } as IDataGridFilterValueSerializer<string>,
            WorklistConditionType.String);
        descriptors.push(identifierFilterDescriptor);

        const careIdentifierFilterDescriptor = createFilterDescriptor("PatientBased_CareIdentifierFilter", {
            serialize: (value: string) => value,
            deserialize: (value: string) => value
        } as IDataGridFilterValueSerializer<string>,
        WorklistConditionType.String);
        descriptors.push(careIdentifierFilterDescriptor);

        const serviceRequestIdentifierFilterDescriptor = createFilterDescriptor("PatientBased_ServiceRequestIdentifierFilter", {
            serialize: (value: string) => value,
            deserialize: (value: string) => value
        } as IDataGridFilterValueSerializer<string>,
        WorklistConditionType.String);
        descriptors.push(serviceRequestIdentifierFilterDescriptor);

        const documentFilterDescriptor = createFilterDescriptor("PatientBased_DocumentFilter", {
            serialize: (value: string) => value,
            deserialize: (value: string) => value
        } as IDataGridFilterValueSerializer<string>,
        WorklistConditionType.String);
        descriptors.push(documentFilterDescriptor);

        const citizenshipCountryIdFilterDescriptor = createFilterDescriptor("PatientBased_CitizenshipCountryIdFilter", {
            serialize: (value: CountryId) => value.value,
            deserialize: (value: string) => new CountryId(value)
        } as IDataGridFilterValueSerializer<CountryId>,
        WorklistConditionType.EntityId);
        descriptors.push(citizenshipCountryIdFilterDescriptor);

        const genderIdFilterDescriptor = createFilterDescriptor("PatientBased_GenderId", {
            serialize: (value: GenderId) => value.value,
            deserialize: (value: string) => new GenderId(value)
        } as IDataGridFilterValueSerializer<GenderId>,
        WorklistConditionType.EntityId);
        descriptors.push(genderIdFilterDescriptor);

        const searchAmongDeceasedFilterDescriptor = createFilterDescriptor("PatientBased_VitalStatus", {
            serialize: (value: SearchPatientQueryVitalStatus) => value.toString(),
            deserialize: this.deserializeVitalStatus
        } as IDataGridFilterValueSerializer<SearchPatientQueryVitalStatus>,
        WorklistConditionType.IntEnum, SearchPatientQueryVitalStatus.Unspecified);
        descriptors.push(searchAmongDeceasedFilterDescriptor);

        const generalSearchFilterDescriptor = createFilterDescriptor("PatientBased_GeneralPatientSearchFilter",  {
            serialize: (value: string) => value,
            deserialize: (value: string) => value
        } as IDataGridFilterValueSerializer<string>,
        WorklistConditionType.String);
        descriptors.push(generalSearchFilterDescriptor);

        const includeDeletedFilterDescriptor = createFilterDescriptor("PatientBased_IncludeDeletedFilter", {
            serialize: (value: boolean) => value.toString(),
            deserialize: (value: string) => value === "true" ? true : false
        } as IDataGridFilterValueSerializer<boolean>,
            WorklistConditionType.Boolean, false);
        descriptors.push(includeDeletedFilterDescriptor);

        return descriptors;
    }

    private static deserializeVitalStatus(value: string): SearchPatientQueryVitalStatus {
        return SearchPatientQueryVitalStatus.Unspecified;
    }
}
