import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import EuPerformanceStatement from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/EuPerformanceStatement";
import EuPerformanceStatementApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/EuPerformanceStatementApiAdapter";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import EuPerformanceStatementId from "@Primitives/EuPerformanceStatementId.g";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import StaticHunSocialSecurityPerformanceStatementResources from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/StaticResources/StaticHunSocialSecurityPerformanceStatementResources";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessUseCaseFrame from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessUseCaseFrame";

interface IEuPerformanceStatementTransactionCodeDialogDependencies {
    euPerformanceStatementApiAdapter: EuPerformanceStatementApiAdapter;
}

export interface IEuPerformanceStatementTransactionCodeDialogProps {
    _dependencies?: IEuPerformanceStatementTransactionCodeDialogDependencies;
    euPerformanceStatementId: EuPerformanceStatementId;
}

/** @screen */
@State.observer
class EuPerformanceStatementTransactionCodeDialog extends React.Component<IEuPerformanceStatementTransactionCodeDialogProps> {

    @State.observable.ref private euPerformanceStatement: EuPerformanceStatement = null;
    @State.observable.ref private transactionCode: string = null;
    @State.observable.ref private closeEvent = new TypedEvent<boolean>();

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.bound
    private async loadAsync() {
        const result = await this.props._dependencies.euPerformanceStatementApiAdapter.getEuPerformanceStatementByIdAsync(this.props.euPerformanceStatementId);
        this.setEuPerformanceStatement(result);
    }

    @State.action.bound
    public async saveAsync() {
        const result = await this.props._dependencies.euPerformanceStatementApiAdapter.setTransactionCodeAsync(
            this.props.euPerformanceStatementId,
            this.euPerformanceStatement.rowVersion,
            this.transactionCode);

        if (result.isPersistedByOperationInfo) {
            this.closeEvent.emit(true);
        } else {
            State.runInAction(() => {
                this.euPerformanceStatement.validationResults = result.validationResults;
            });
        }
    }

    @State.action.bound
    public async validateAsync() {
        const result = await this.props._dependencies.euPerformanceStatementApiAdapter.setTransactionCodeAsync(
            this.props.euPerformanceStatementId,
            this.euPerformanceStatement.rowVersion,
            this.transactionCode,
            true);
        return result.validationResults;
    }

    @State.action.bound
    private close() {
        this.closeEvent.emit(false);
    }

    @State.action.bound
    private setEuPerformanceStatement(euPerformanceStatement: EuPerformanceStatement) {
        this.euPerformanceStatement = euPerformanceStatement;
    }

    @State.action.bound
    public setTransactionCode(transactionCode: string) {
        this.transactionCode = transactionCode;
    }

    public render() {
        
        const resources = StaticHunSocialSecurityPerformanceStatementResources.NeakPerformanceStatementTransactionCodeDialog;

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessUseCaseFrame title={resources.Title} />;
        }

        return (
            this.euPerformanceStatement && (
                <UseCaseFrame
                    modalCloseEvent={this.closeEvent}
                    title={resources.Title}
                    modalSize="tiny"
                    leftFooter={<Ui.Button text={StaticWebAppResources.Common.Button.Close} onClick={this.close} visualStyle="link" automationId="closeButton" />}
                    rightFooter={<Ui.SaveButton visualStyle="primary" onClickAsync={this.saveAsync} automationId="saveButton" />}
                >
                        <ValidationBoundary
                            validationResults={this.euPerformanceStatement.validationResults}
                            entityTypeName="EuPerformanceStatement"
                            onValidateAsync={this.validateAsync}
                            validateOnMount
                        >
                            <Ui.Flex>
                                <Ui.Flex.Item sm={10}>
                                    <Ui.TextBox
                                        label={resources.TransactionCode}
                                        value={this.transactionCode}
                                        onChange={this.setTransactionCode}
                                        propertyIdentifier="TransactionCode"
                                        automationId="transactionCodeTextBox"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </ValidationBoundary>
                </UseCaseFrame>
            )
        );
    }
}

export default connect(
    EuPerformanceStatementTransactionCodeDialog,
    new DependencyAdapter<IEuPerformanceStatementTransactionCodeDialogProps, IEuPerformanceStatementTransactionCodeDialogDependencies>(c => ({
        euPerformanceStatementApiAdapter: c.resolve("EuPerformanceStatementApiAdapter"),
    }))
);
