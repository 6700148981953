import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/Care/Api/Proxy.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ContagiousPatientReportId from "@Primitives/ContagiousPatientReportId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import ContagiousPatientReport from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReport";
import { getCreateEmptyContagiousPatientReportDto, getGetContagiousPatientReportByIdDto, getUpdateContagiousPatientReportDto } from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ContagiousPatientReport/HunCareContagiousPatientReportDtoMapper";
import HunCareContagiousPatientReportStoreMapper, { applyToRelevantData } from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ContagiousPatientReport/HunCareContagiousPatientReportStoreMapper";
import { GetTypeNameWithPrefix } from "@HunSocialSecurityPlugin/PluginHelper";
import { createOperationInfo, createOperationInfoWithValidationResult } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import NursingLocationTypeId from "@Primitives/NursingLocationTypeId.g";
import PatientId from "@Primitives/PatientId.g";
import ConditionId from "@Primitives/ConditionId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import CareActivityId from "@Primitives/CareActivityId.g";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";
import ActionIdentifier from "@Primitives/ActionIdentifier.g";
import ContagiousPatientReportOutcomeTypeId from "@Primitives/ContagiousPatientReportOutcomeTypeId.g";
import OnsetReportData from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientOnsetReport";
import Address from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/Address";
import LockId from "@Toolkit/CommonWeb/Model/LockId";

/* auto-inject-disable */
@Di.injectable()
export default class HunCareContagiousPatientReportApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("IContagiousPatientReportClient")) private readonly contagiousPatientReportClient: Proxy.IContagiousPatientReportClient,
        @Di.inject("HunCareContagiousPatientReportStoreMapper") private readonly storeMapper: HunCareContagiousPatientReportStoreMapper

    ) {
        super();
    }

    @State.bound
    public getContagiousPatientReportById(contagiousPatientReportId: ContagiousPatientReportId, requestLock: boolean) {
        return this.processOperationAsync(
            new SimpleStore<ContagiousPatientReport>(),
            async target => {
                const response = await this.contagiousPatientReportClient.getContagiousPatientReportByIdCommandAsync(
                    CreateRequestId(),
                    getGetContagiousPatientReportByIdDto(contagiousPatientReportId, requestLock)
                );
                target.value = new ContagiousPatientReport();
                this.storeMapper.applyToStore(target.value, response);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getNursingLocationTypeIdsAsync(): Promise<SimpleStore<NursingLocationTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.contagiousPatientReportClient.getNursingLocationTypeIdsQueryAsync(...args),
            response => response.nursingLocationTypeIds
        );
    }

    @State.bound
    public getContagiousPatientreportOutcomeTypeIdsAsync(): Promise<SimpleStore<ContagiousPatientReportOutcomeTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.contagiousPatientReportClient.getContagiousPatientReportOutcomeTypeIdsQueryAsync(...args),
            response => response.contagiousPatientReportOutcomeTypeIds
        );
    }

    @State.bound
    public async isContagiousPatientReportNeededQueryAsync(
        patientId: PatientId,
        careActivityId: CareActivityId,
        conditionId: ConditionId,
        diagnosisRoleId: DiagnosisRoleId,
        pointOfCareId: PointOfCareId) {
        const response = await this.contagiousPatientReportClient.isContagiousPatientReportNeededQueryAsync(
            CreateRequestId(),
            careActivityId.value,
            conditionId.value,
            diagnosisRoleId.value,
            patientId.value,
            pointOfCareId.value);

        return response.isContagiousPatientReportNeeded;
    }

    @State.bound
    public createEmptyContagiousPatientOnsetReportAsync(patientId: PatientId, conditionId: ConditionId, pointOfCareId: PointOfCareId, careActivityId: CareActivityId, isPermissionCheckOnly: boolean) {
        return this.processOperationAsync(
            new SimpleStore<ContagiousPatientReportId>(),
            async target => {
                const response = await this.contagiousPatientReportClient.createEmptyContagiousPatientOnsetReportCommandAsync(
                    CreateRequestId(),
                    getCreateEmptyContagiousPatientReportDto(patientId, conditionId, pointOfCareId, careActivityId),
                    isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.contagiousPatientReportId;
            });
    }

    @State.bound
    public validateAsync(report: ContagiousPatientReport, action?: ActionIdentifier) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response = await this.contagiousPatientReportClient.updateContagiousPatientReportCommandAsync(
                    CreateRequestId(),
                    getUpdateContagiousPatientReportDto(report, true, action, false)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    @State.bound
    public updateContagiousPatientReportAsync(report: ContagiousPatientReport, action?: ActionIdentifier, releaseLockIfSuccessful: boolean = true, isPermissionCheckOnly: boolean = false) {
        return this.processOperationAsync(
            new ContagiousPatientReport(),
            async target => {
                const response = await this.contagiousPatientReportClient.updateContagiousPatientReportCommandAsync(
                    CreateRequestId(),
                    getUpdateContagiousPatientReportDto(report, false, action, releaseLockIfSuccessful),
                    isPermissionCheckOnly
                );
                this.storeMapper.applyToStoreWithExistingLockAndResolveValidationProblemsAsync(target, response, report.lockInfo, releaseLockIfSuccessful);
                target.operationInfo = createOperationInfoWithValidationResult(response, target.hasValidationError, target.hasValidationWarning);
            }
        );
    }

    @State.bound
    public getMostRelevantOnsetReportForNewEndReportAsync(conditionId: ConditionId, patientId: PatientId) {
        return this.processOperationAsync(
            new SimpleStore<{reportData: OnsetReportData, id?: ContagiousPatientReportId}>(),
            async target => {
                const response = await this.contagiousPatientReportClient.getRelevantContagiousPatientOnsetReportForNewEndReportQueryAsync(
                    CreateRequestId(),
                    conditionId.value,
                    patientId.value
                );
                target.value = {reportData: new OnsetReportData() , id: null};
                const res = new OnsetReportData();
                target.operationInfo = createOperationInfo(response);
                target.value.reportData = res;
                if (!response.onsetReportData) {
                    return;
                }

                applyToRelevantData(target.value, response);
            }
        );
    }

    @State.bound
    public deleteContagiousPatientReportAsync(id: ContagiousPatientReportId, lockId?: LockId) {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.contagiousPatientReportClient.deleteContagiousPatientReportCommandAsync(
                    CreateRequestId(),
                    new Proxy.DeleteContagiousPatientReportControllerDto({
                        releaseLockIfSuccessful: true,
                        contagiousPatientReportId: id,
                        lockId: lockId
                    })
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}
