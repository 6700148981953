// tslint:disable:variable-name
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import DischargePatientScreenStore from "./DischargePatientScreenStore";
import { editorScreenRenderFunc } from "@Toolkit/CommonWeb/PanelStore/EditorScreenRenderFunc";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";

const {
    ContextComponent: DischargePatientScreenStoreContext,
    StoreProvider: DischargePatientScreenStoreProvider,
    useStore: useDischargePatientScreenStore,
    provideStore: provideDischargePatientScreenStore,
} = createPanelStoreProvider<DischargePatientScreenStore>(DischargePatientScreenStore, () => ({
    unauthorizedPageTitle: StaticCareResources.CareRegister.CareActivityDischargeDataPanel.Title
}), editorScreenRenderFunc);

export {
    DischargePatientScreenStoreContext,
    DischargePatientScreenStoreProvider,
    useDischargePatientScreenStore,
    provideDischargePatientScreenStore
};