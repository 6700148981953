import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import IRatDeviceReferenceDataResolver from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Services/Definition/EhiMedicalConditionDataResolver/IRatDeviceReferenceDataResolver";
import RatDeviceId from "@Primitives/RatDeviceId.g";

@Di.injectable()
export default class RatDeviceReferenceDataResolver implements IRatDeviceReferenceDataResolver {
    constructor(
        @Di.inject("HunEhiCareReferenceDataStore") private ehiCareReferenceDataStore: HunEhiCareReferenceDataStore
    ) { }

    @State.action.bound
    public async getNameAsync(value: RatDeviceId) {
        const item = await this.ehiCareReferenceDataStore.ratDevices.getOrLoadAsync(value);
        
        return item.name;
    }

    @State.action.bound
    public async ensureLoadedAsync(ids: RatDeviceId[]): Promise<void> {
        return await this.ehiCareReferenceDataStore.ratDevices.ensureLoadedAsync(ids);
    }
}