exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SeparatedTimeRangePicker_outer-container_32hAZ {\n  display: flex;\n}\n.SeparatedTimeRangePicker_outer-container_32hAZ .SeparatedTimeRangePicker_fromToLabel_2LzZw {\n  font-family: PragatiNarrow;\n  font-size: 16px;\n  color: #8695a7;\n  margin-top: 28px;\n}\n.SeparatedTimeRangePicker_outer-container_32hAZ .SeparatedTimeRangePicker_toTimePicker_160Qs {\n  margin-top: 11px;\n}\n", ""]);

// exports
exports.locals = {
	"outer-container": "SeparatedTimeRangePicker_outer-container_32hAZ",
	"outerContainer": "SeparatedTimeRangePicker_outer-container_32hAZ",
	"fromToLabel": "SeparatedTimeRangePicker_fromToLabel_2LzZw",
	"toTimePicker": "SeparatedTimeRangePicker_toTimePicker_160Qs"
};