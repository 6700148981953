import React, { } from "react";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ShouldNotBeLongerThanValidationRulePropertyPanel from "./Validation/ShouldNotBeLongerThanValidationRulePropertyPanel";
import ShouldNotBeShorterThanValidationRulePropertyPanel from "./Validation/ShouldNotBeShorterThanValidationRulePropertyPanel";
import ShouldMatchPatternValidationRulePropertyPanel from "./Validation/ShouldMatchPatternValidationRulePropertyPanel";
import ShouldBeFilledValidationRulePropertyPanel from "./Validation/ShouldBeFilledValidationRulePropertyPanel";
import ShouldBeWithinRangeValidationRulePropertyPanel from "./Validation/ShouldBeWithinRangeValidationRulePropertyPanel";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import StringFormDataElement from "@Toolkit/FormEngine/Model/Schema/StringFormDataElement";
import NumberFormDataElement from "@Toolkit/FormEngine/Model/Schema/NumberFormDataElement";

function FieldValidationPanel() {

    const store = useFormLayoutEditorStore();

    return (
        <>
            <h4 style={{ marginBottom: 0 }}>{StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.ValidationsTitle}</h4>

            <ShouldBeFilledValidationRulePropertyPanel />

            {store.selectedDataElement instanceof StringFormDataElement && (
                <>
                    <ShouldNotBeShorterThanValidationRulePropertyPanel />
                    <ShouldNotBeLongerThanValidationRulePropertyPanel />
                    <ShouldMatchPatternValidationRulePropertyPanel />
                </>
            )}

            {store.selectedDataElement instanceof NumberFormDataElement && (
                <>
                    <ShouldBeWithinRangeValidationRulePropertyPanel />
                </>
            )}

        </>
    );
}

export default State.observer(FieldValidationPanel);