import React from "react";
import * as Ui from "@CommonControls";
import Button from "@CommonControls/Button";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import StaticWebAppResources from "@StaticResources";
import UserSelectBox from "@HisPlatform/BoundedContexts/UserManagement/Components/Controls/Users/UserSelectBox";
import UserId from "@Primitives/UserId.g";
import * as Styles from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserPanel/Modals/CopyPointOfCareRolesModal/CopyPointOfCareRolesModal.less";
import UserHeader from "@HisPlatform/BoundedContexts/UserManagement/Components/Controls/UserHeader";
import UserGroupId from "@Primitives/UserGroupId.g";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import UserGroup from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroup";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";

interface ICopyPointOFCareRolesModalView {
    closeModal: () => void;
    userGroupsStore: ReferenceDataStore<UserGroupId, UserGroup>;
    save: () => void;
    setUserId: (id: UserId) => void;
    practitioner: PractitionerStore;
    selectedUser: User;
    user: User;
}


const CopyUserGroupModalView: React.SFC<ICopyPointOFCareRolesModalView> = props => {
    const renderHeader = () => {
        return (
            <UserHeader user={props.user} practitioner={props.practitioner} />
        );
    };

    const renderUserGroup = (value: any, row: UserGroupId) => {
        const group = props.userGroupsStore.get(row);
        return group.displayName;
    };
    const renderGrid = () => {
        return (
            <Ui.ScrollView height={250}>
                <Ui.DataGrid dataSource={props.selectedUser?.userGroupIds || []} rowHeight={40} automationId="copyUserGroupModalView_dataGrid">
                    <DataGridColumn
                        id="name"
                        key={0}
                        onRenderCellValue={renderUserGroup}
                        header={StaticUserManagementResources.UserManagementPanel.CopyUserGroupPanel.UserGroups}
                        width="60px"
                        leftPadding="small"
                        automationId={"UserGroups".split(".").pop()}
                    />
                </Ui.DataGrid>
            </Ui.ScrollView>
        );
    };
    return (
        <Ui.Modal
            isOpen
            title={StaticUserManagementResources.UserManagementPanel.CopyUserGroupPanel.Title}
            closeOnOutsideClick={false}
            renderContextHeader={renderHeader}
            size="compact"
        >
            <Ui.Modal.Body>
                <UserSelectBox onChange={props.setUserId}
                    value={props.selectedUser?.id}
                    label={StaticUserManagementResources.UserManagementPanel.CopyUserGroupPanel.User}
                    clearable
                    orderItems
                    excludedIds={[props.user?.id]}
                    excludeUsersWithoutPractitioners
                    className={Styles.userSelectBoxMargin}
                    automationId="userSelectBox"
                />
                {renderGrid()}
            </Ui.Modal.Body>
            <Ui.Modal.Footer>
                <Ui.Flex xsJustify="between" verticalSpacing="none">
                    <Ui.Flex.Item>
                        <Button text={StaticWebAppResources.Common.Button.Cancel}
                            onClick={props.closeModal}
                            visualStyle="link"
                            automationId="closeButton"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item>
                        <Button text={StaticUserManagementResources.UserManagementPanel.CopyUserGroupPanel.Save}
                            visualStyle="primary"
                            onClick={props.save}
                            disabled={!props.selectedUser}
                            automationId="saveButton"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.Modal.Footer>
        </Ui.Modal>
    );
};

export default CopyUserGroupModalView;
