import State from "@Toolkit/ReactClient/Common/StateManaging";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import * as Ui from "@CommonControls";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import DocumentInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentInfo";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import PatientId from "@Primitives/PatientId.g";
import DocumentId from "@Primitives/DocumentId.g";
import CareActivityDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentApiAdapter";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import DocumentManagementReferenceDataStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentManagementReferenceDataStore";
import DocumentManagementDetailPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/DocumentManagementMasterDetailPanel/DocumentManagementDetailPanel";
import DocumentBase from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentBase";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import { VoidSyncEvent } from "ts-events";
import PermissionCheckContext from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContext";
import PermissionCheckReactContext from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckReactContext";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import DocumentPreviewModalParams from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Modals/DocumentPreviewModal/DocumentPreviewModalParams";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import TemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TemplateBasedDocument";
import { DocumentHistoryModalParams } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Modals/DocumentHistoryModal/DocumentHistoryModal";
import MedworksDocumentListPanel from "./MedworksDocumentListPanel";
import DocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/DocumentApiAdapter";
import DocumentState from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentState.g";
import DocumentAction from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentAction.g";

interface IMedworksDocumentsMasterDetailPanelDependencies {
    careDocumentApiAdapter: CareActivityDocumentApiAdapter;
    userManagementDataProviderStore: UserManagementDataProviderStore;
    documentManagementReferenceDataStore: DocumentManagementReferenceDataStore;
    localizationService: ILocalizationService;
    fileSaverService: IFileSaverService;
    documentApiAdapter: DocumentApiAdapter;
}

interface IMedworksDocumentsMasterDetailPanelProps {
    _dependencies?: IMedworksDocumentsMasterDetailPanelDependencies;
    _modalService?: IModalService;
    patientId?: PatientId;
    isCompact?: boolean;
    selectedDocumentId: DocumentId;
    onSelectedDocumentIdChange: (newId: DocumentId) => void;
    commonFilter: React.ReactNode;
    filterStore: IMedworksDocumentsFilterStore;
}

export interface IMedworksDocumentsFilterStore {
    onChangeDebounced: VoidSyncEvent;
    dateRange: LocalDateRange;
    setDateRange: (newValue: LocalDateRange) => void;
}

@State.observer
class MedworksDocumentsMasterDetailPanel extends React.Component<IMedworksDocumentsMasterDetailPanelProps> {

    @State.observable.ref private documentInfoList: IPagedItems<DocumentInfo> = { items: [], totalCount: 0 };
    @State.observable.ref private paging: IPagingState = { currentPage: 0, pageSize: 20 };
    @State.observable.ref private ordering: IOrderingState = { columnId: "LastModifiedAt", direction: "desc" };
    @State.observable.ref private showDeleted: boolean = false;
    @State.observable.ref private currentDocument: DocumentBase = null;
    @State.observable.ref private isListLoading = false;
    @State.observable.ref private isDetailLoading = false;
    @State.observable private permissionCheckContextStore = new PermissionCheckContext();

    @State.computed private get permissionCheckedOperations() {
        const res = {};

        if (!!this.props.selectedDocumentId && this.props.selectedDocumentId?.value !== "new") {
            res["PrintPreview"] = async () => await this.documentApiAdapter.getDocumentPreviewAsync(this.props.selectedDocumentId, true);
        }
        return res;
    }

    private get careDocumentApiAdapter() { return this.props._dependencies!.careDocumentApiAdapter; }
    private get userManagementDataProviderStore() { return this.props._dependencies!.userManagementDataProviderStore; }
    private get localizationService() { return this.props._dependencies!.localizationService; }
    private get modalService() { return this.props._modalService!; }
    private get documentApiAdapter() { return this.props._dependencies!.documentApiAdapter; }

    @State.boundLoadingState("isListLoading")
    private async loadListAsync(ordering: IOrderingState, paging: IPagingState) {
        const result: PagedItemStore<DocumentInfo> = await this.careDocumentApiAdapter.getPatientDocumentListAsync(this.props.patientId, true, ordering, paging, this.showDeleted, this.props.filterStore.dateRange);

        await this.userManagementDataProviderStore.users.ensureLoadedAsync([
            ...result.items.map(i => i.createdById),
            ...result.items.map(i => i.lastModifiedById),
        ]);

        this.setListState(result, ordering, paging);
    }

    @State.action
    private setListState(results: PagedItemStore<DocumentInfo>, ordering: IOrderingState, paging: IPagingState) {
        this.paging = paging;
        this.ordering = ordering;
        this.documentInfoList = results;
    }

    @State.action
    private setDetailState(doc: DocumentBase) {
        this.currentDocument = doc;
    }

    private get documentTypeStore() {
        return this.props._dependencies.documentManagementReferenceDataStore.documentTypeMap;
    }

    private get isDetailOpen() {
        return !!this.props.selectedDocumentId;
    }

    @State.boundLoadingState("isDetailLoading")
    private async loadDetailAsync() {

        await this.permissionCheckContextStore.checkPermissionsAsync(this.permissionCheckedOperations);

        if (!!this.props.selectedDocumentId) {
            const res = await this.careDocumentApiAdapter.getEhrDocumentByIdAsync(this.props.selectedDocumentId, false);
            const doc = res.value;
            this.setDetailState(doc);
        } else {
            this.setDetailState(null);
        }
    }

    @State.bound
    private async loadReferenceDataAsync() {
        await this.documentTypeStore.ensureAllLoadedAsync();
    }

    @State.bound
    private async _initializePanelAsync() {

        this.attachToFilterStoreForRefresh();
        await this.loadReferenceDataAsync();
        await this.loadListAsync(this.ordering, this.paging);
        await this.loadDetailAsync();
    }

    private readonly initializePanelAsync = createInitialPanelLoader(this._initializePanelAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initializePanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IMedworksDocumentsMasterDetailPanelProps) {
        if (!ValueWrapper.equals(this.props.selectedDocumentId, prevProps.selectedDocumentId)) {
            dispatchAsyncErrors(this.loadDetailAsync(), this);
        }
        if (this.props.filterStore !== prevProps.filterStore) {
            this.attachToFilterStoreForRefresh();
        }
    }

    @State.bound
    private attachToFilterStoreForRefresh() {
        this.props.filterStore.onChangeDebounced.attach(() => {
            dispatchAsyncErrors(this.loadListAsync(this.ordering, this.paging), null);
        });
    }

    private get resources() {
        return StaticHunEHealthInfrastructureCareResources.MedworksDocumentMasterDetailPanel;
    }

    @State.computed private get currentDocumentStatusDisplayValue() {
        if (!this.currentDocument) {
            return "";
        } else if (this.currentDocument.isNew) {
            return this.resources.NewDocumentState;
        }

        return this.localizationService.localizeEnum(DocumentState[this.currentDocument.info.state], "DocumentState")?.Name;
    }

    @State.bound
    private async onPreviewAsync() {
        await this.modalService.showModalAsync(new DocumentPreviewModalParams(this.currentDocument.id));
    }

    @State.bound
    private async onDocumentHistoryAsync() {
        await this.modalService.showDialogAsync(new DocumentHistoryModalParams(this.currentDocument));
    }

    @State.bound
    private renderFilter() {
        return (
            <Ui.Flex outerSpacing={"regular"}>
                <Ui.Flex.Item xs={12}>
                    <Ui.GroupBox hasBorder
                        visualStyle={"primary"}
                        style={{ borderLeft: "1px solid #00B4EC", backgroundColor: "#F4F7FC" }}>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={3}>
                                {this.props.commonFilter}
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={3}>
                                <Ui.DateRangePicker value={this.props.filterStore.dateRange}
                                    onChange={this.props.filterStore.setDateRange}
                                    label={this.resources.Filters.DateRangeLabel}
                                    automationId="dateRangeFilterPicker"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={6} />
                        </Ui.Flex>
                    </Ui.GroupBox>
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.bound
    private renderDetailToolbar() {
        return (
            <>
                {(this.currentDocument instanceof TemplateBasedDocument) &&
                    <Ui.Button
                        permissionCheckOperationNames="PrintPreview"
                        permissionDeniedStyle="disabled"
                        iconName="print"
                        text={StaticDocumentManagementResources.DocumentManagementMasterDetailPanel.Button.PrintPreview}
                        onClickAsync={this.onPreviewAsync}
                        disabled={!this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.Print)}
                        automationId="printButton"
                    />
                }
                <Ui.Button
                    iconName="document_history"
                    text={StaticDocumentManagementResources.DocumentManagementMasterDetailPanel.Button.ViewHistory}
                    onClickAsync={this.onDocumentHistoryAsync}
                    disabled={!this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.ViewHistory)}
                    automationId="viewHistoryButton"
                />
            </>
        );
    }

    public render() {

        if (this.initializePanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticHunEHealthInfrastructureCareResources.PatientDocumentMasterDetailPanel.Title} />;
        }

        return (
            <PermissionCheckReactContext.Provider value={this.permissionCheckContextStore}>
                <MasterDetailLayout
                    isCompact={false}
                    selectedItem={this.props.selectedDocumentId}
                    onSelectedItemChange={this.props.onSelectedDocumentIdChange}
                >
                    <MasterDetail.Master
                        title={StaticHunEHealthInfrastructureCareResources.PatientDocumentMasterDetailPanel.Title}
                        iconName="editDocument"
                    >
                        <MedworksDocumentListPanel
                            extraFilter={this.renderFilter()}
                            isExtraFilterVisible={!this.isDetailOpen}
                            documents={this.documentInfoList}
                            onLoadListAsync={this.loadListAsync}
                            selectedId={this.props.selectedDocumentId}
                            onSelect={this.props.onSelectedDocumentIdChange}
                            ordering={this.ordering}
                            paging={this.paging}
                            readOnly={true}
                            isCompact={this.props.isCompact}
                            isLoading={this.isListLoading}
                        />
                    </MasterDetail.Master>
                    <MasterDetail.Detail
                        title={this.currentDocument?.info.name ?? this.resources.Document}
                        subTitle={this.currentDocumentStatusDisplayValue}
                        toolbar={this.renderDetailToolbar()}
                    >
                        {!this.isDetailLoading ?
                            <DocumentManagementDetailPanel
                                printPermissionCheckOperationName="PrintPreview"
                                document={this.currentDocument}
                                isReadOnly={true} /> : <></>
                        }
                    </MasterDetail.Detail>
                </MasterDetailLayout>
            </PermissionCheckReactContext.Provider>
        );
    }

}

export default connect(
    MedworksDocumentsMasterDetailPanel,
    new DependencyAdapter<IMedworksDocumentsMasterDetailPanelProps, IMedworksDocumentsMasterDetailPanelDependencies>(c => ({
        userManagementDataProviderStore: c.resolve("UserManagementDataProviderStore"),
        careDocumentApiAdapter: c.resolve("CareActivityDocumentApiAdapter"),
        localizationService: c.resolve("ILocalizationService"),
        documentManagementReferenceDataStore: c.resolve("DocumentManagementReferenceDataStore"),
        fileSaverService: c.resolve("IFileSaverService"),
        documentApiAdapter: c.resolve("DocumentApiAdapter")
    })),
    new HisModalServiceAdapter()
);