import CareContactType from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareContactType.g";

export function getIconNameForCareContactType(careContactType: CareContactType) {
    switch (careContactType) {
        case CareContactType.Ambulatory:
            return "patientWalking";
        case CareContactType.TelemetrySession:
            return "cardiology";
        case CareContactType.Inpatient:
        default:
            return "patientInBed";
    }
}