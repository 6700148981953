import IOperationInfo from "./IOperationInfo";
import RequestStatus from "./RequestStatus";
import IBusinessError from "@Toolkit/CommonWeb/Model/IBusinessError";

export default class CommandOperationInfo implements IOperationInfo {

    public get requestStatus(): RequestStatus { return RequestStatus.Success; }
    public get hasTechnicalError(): boolean { return false; }
    public get businessError(): IBusinessError { return null; }

    constructor(
        public readonly isPersisted: boolean,
        public readonly hasValidationError: boolean,
        public readonly hasValidationWarning: boolean,
        public readonly operationId?: string
    ) {}

}