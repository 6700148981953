exports = module.exports = require("../../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DiagnosisList_new-element-panel_276kX {\n  background-color: #82d4ed;\n  width: 100%;\n  margin: 0;\n  height: 58px;\n}\n.DiagnosisList_new-element-panel_276kX th:first-child {\n  padding-left: 16px;\n}\n.DiagnosisList_margin-right_3-Ffe {\n  margin-right: 16px;\n}\n.DiagnosisList_info-button-container_O0ZVb {\n  display: inline;\n  margin-left: 5px;\n}\n.DiagnosisList_info-button-container_O0ZVb svg {\n  top: 9px;\n}\n", ""]);

// exports
exports.locals = {
	"new-element-panel": "DiagnosisList_new-element-panel_276kX",
	"newElementPanel": "DiagnosisList_new-element-panel_276kX",
	"margin-right": "DiagnosisList_margin-right_3-Ffe",
	"marginRight": "DiagnosisList_margin-right_3-Ffe",
	"info-button-container": "DiagnosisList_info-button-container_O0ZVb",
	"infoButtonContainer": "DiagnosisList_info-button-container_O0ZVb"
};