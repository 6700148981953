import { createNestedRoutes } from "@Toolkit/ReactClient/Routing/CreateNestedRoutes";
import PatientRoutes from "./PatientRoutes";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import IPatientRouteParams from "@HisPlatform/Application/Routes/IPatientRouteParams";


const PatientEHRRoutes = createNestedRoutes(PatientRoutes.patientEhr, {
    careActivities: new RouteDefinition<IPatientRouteParams>("/careActivities"),
    documents: new RouteDefinition<IPatientEhrDocumentListRouteParams>("/documents/:selectedDocumentId?"),
    serviceRequests: new RouteDefinition<IPatientEHRServiceRequestsRouteParams>("/serviceRequests/:selectedId?/:useCase?"),
    prescriptions: new RouteDefinition<IPatientRouteParams>("/prescriptions")
});

export interface IPatientEHRServiceRequestsRouteParams extends IPatientRouteParams {
    selectedId?: string;
    useCase?: string;
}

export interface IPatientEhrDocumentListRouteParams extends IPatientRouteParams {
    selectedDocumentId?: string;
}

export default PatientEHRRoutes;