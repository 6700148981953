import ConfigurationDynamicPropertiesApiAdapter from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/ApiAdapter/DynamicProperties/ConfigurationDynamicPropertiesApiAdapter";
import DynamicPropertiesApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/DynamicProperties/DynamicPropertiesApiAdapter";
import SchedulingConfigurationApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingConfigurationApiAdapter";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import React from "react";
import SchedulingServiceDialogView from "./SchedulingServiceDialogView";
import { ISchedulingServiceEditorDialogParams } from "./SchedulingServiceEditorDialogParams";
import config from "@Config";

interface ISchedulingServiceEditorDialogDependencies {
    schedulingConfigurationApiAdapter: SchedulingConfigurationApiAdapter;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
    configurationDynamicPropertiesApiAdapter: ConfigurationDynamicPropertiesApiAdapter;
}

interface ISchedulingServiceEditorDialogProps extends IModalComponentParams, ISchedulingServiceEditorDialogParams {
    _dependencies?: ISchedulingServiceEditorDialogDependencies;
}

/** @screen */
@State.observer
class SchedulingServiceEditorDialog extends React.Component<ISchedulingServiceEditorDialogProps> {
    @State.observable private schedulingService: SchedulingServiceSubjectStore = new SchedulingServiceSubjectStore(true);
    @State.observable private isTelemedicineConsultationFeatureEnabled: boolean = false;

    constructor(props: ISchedulingServiceEditorDialogProps) {
        super(props);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadServiceAsync(), this);
    }

    @State.action.bound
    private async saveSchedulingServiceAsync() {
        if (this.schedulingService.isNew) {
            return await this.props._dependencies.schedulingConfigurationApiAdapter.createSchedulingServiceAsync(this.schedulingService, this.props.lockInfo);
        } else {
            return await this.props._dependencies.schedulingConfigurationApiAdapter.updateSchedulingServiceAsync(this.schedulingService, this.props.lockInfo);
        }
    }

    @State.action.bound
    private close() {
        this.props.onClose({
            schedulingService: null
        });
    }

    @State.action.bound
    private async saveAsync() {
        const newService = await this.saveSchedulingServiceAsync();
        if (!newService.value.store.hasValidationError) {
            this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleDefinitions.invalidate();
            newService.value.store.owner = this.props.scheduleDefinitionId;
            return this.props.onClose({
                schedulingService: newService
            });
        } else {
            State.runInAction(() => {
                this.schedulingService.validationResults = newService.value.store.validationResults;
            });
        }
    }

    @State.action.bound
    private async loadServiceAsync() {
        State.runInAction(() => {
            this.isTelemedicineConsultationFeatureEnabled = config.features.telemedicineConsultationEnabled;
        });

        if (this.props.schedulingServiceId) {
            const service = await this.props._dependencies.schedulingConfigurationApiAdapter.loadSchedulingServiceByIdsAsync([this.props.schedulingServiceId]);
            this.setSchedulingService(service.value[0]);
        }

        this.schedulingService.owner = this.props.scheduleDefinitionId;
    }

    @State.action.bound private setSchedulingService(newValue: SchedulingServiceSubjectStore) {
        this.schedulingService = newValue;
    }

    @State.action.bound
    private async validateAsync() {
        const result = await this.props._dependencies.schedulingConfigurationApiAdapter.validateSchedulingServiceAsync(this.schedulingService, this.props.lockInfo);
        return result.validationResults;
    }

    public render() {
        return (
            <>
                <SchedulingServiceDialogView
                    onClose={this.close}
                    saveAsync={this.saveAsync}
                    schedulingService={this.schedulingService}
                    onValidateAsync={this.validateAsync}
                    isTelemedicineConsultationFeatureEnabled={this.isTelemedicineConsultationFeatureEnabled}
                />
            </>
        );
    }
}

export default connect(
    SchedulingServiceEditorDialog,
    new DependencyAdapter<ISchedulingServiceEditorDialogProps, ISchedulingServiceEditorDialogDependencies>(c => ({
        schedulingConfigurationApiAdapter: c.resolve("SchedulingConfigurationApiAdapter"),
        schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore"),
        configurationDynamicPropertiesApiAdapter: c.resolve("ConfigurationDynamicPropertiesApiAdapter")
    }))
);
