import Di from "@Di";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import { ISurgeryTypeDefinitionPanelProps } from "./SurgeryTypeDefinitionPanel";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import SurgeryApiAdapter from "@HisPlatform/BoundedContexts/Surgery/ApplicationLogic/ApiAdapter/SurgeryApiAdapter";
import SurgeryTypeDefinitionId from "@Primitives/SurgeryTypeDefinitionId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ExcludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierFilter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import ReferencedEntityArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityArrayFormFieldData";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

@Di.injectable()
export default class SurgeryTypeDefinitionPanelStore
    extends PanelStoreBase<ISurgeryTypeDefinitionPanelProps> implements ILoadablePanelStore {

    @State.observable.ref public form: IForm = null;

    constructor(
        @Di.inject("SurgeryApiAdapter") private readonly apiAdapter: SurgeryApiAdapter,
        @Di.inject("CareReferenceDataStore") private readonly careReferenceDataStore: CareReferenceDataStore
    ) {
        super();
    }

    public getReloadTriggerProps() {
        return [this.props.selectedId?.value];
    }

    public readonly loadAsync = this.namedAsync("loadingSurgeryTypeDefinition", async () => {
        if (SurgeryTypeDefinitionId.isNew(this.props.selectedId)) {
            const result = await this.apiAdapter.getNewSurgeryTypeDefinitionAsync();
            State.runInAction(() => {
                this.form = result.value;
            });
        } else {
            const result = await this.apiAdapter.getSurgeryTypeDefinitionAsync(this.props.selectedId);
            State.runInAction(() => {
                this.form = result.value;
            });
        }
    });

    public readonly saveAsync = this.namedAsync("savingSurgeryTypeDefinition", async () => {
        if (SurgeryTypeDefinitionId.isNew(this.props.selectedId)) {
            const result = await this.apiAdapter.createSurgeryTypeDefinitionAsync(this.form, true);

            await this.closeOnPersistedAsync(result.isPersistedByOperationInfo);
        } else {
            const result = await this.apiAdapter.updateSurgeryTypeDefinitionAsync(this.props.selectedId, this.form, false);

            await this.closeOnPersistedAsync(result.isPersistedByOperationInfo);
        }
    });

    private async closeOnPersistedAsync(isPersisted: boolean) {
        if (isPersisted) {
            await this.props.onSaveAsync();
            this.props.onClose();
        }
    }

    @State.bound
    public onChange(propertyIdentifier: string, value: any) {
        this.updateFiltersAsync(propertyIdentifier, value).then();
    }

    private async updateFiltersAsync(propertyIdentifier: string, value: any) {
        if (propertyIdentifier === "MainMedicalService") {
            const mainMedicalServiceContent = this.form.data.Content.find(i => i.fieldName === "MainMedicalService") as ReferencedEntityFormFieldData;
            const secondaryFilter = this.form.data.Content.find(i => i.fieldName === "AdditionalMedicalServices") as ReferencedEntityArrayFormFieldData;

            if (!isNullOrUndefined(mainMedicalServiceContent.value)) {
                const versionedMedicalServiceId = new EntityVersionSelector(new MedicalServiceId(mainMedicalServiceContent.value.toString()), LocalDate.today());
                await this.careReferenceDataStore.medicalService.ensureLoadedAsync([versionedMedicalServiceId]);
                const mainMedicalService = this.careReferenceDataStore.medicalService.get(versionedMedicalServiceId);
                const newFilter = new ExcludeIdentifierFilter(mainMedicalService.code);

                secondaryFilter.filters.replace([newFilter]);
            } else if (!isNullOrUndefined(secondaryFilter)) {
                secondaryFilter.filters.replace([]);
            }
        }
    }
}
