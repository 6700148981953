import React from "react";
import Styles from "./MenuSection.less";
import Icon from "@CommonControls/Icon";
import StaticWebAppResources from "@StaticResources";

interface IAllMenuItemSectionProps {
    onShowAllItemClick: () => void;
}


const AllMenuItemSection: React.FC<IAllMenuItemSectionProps> = (props) => {
    const showAllClick = React.useMemo(() => {
        return (event: React.MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();
            props.onShowAllItemClick();
        };
    }, [props.onShowAllItemClick]);

    return (
        <div className={Styles.extraFunctionsContainer}>
            <div className={Styles.extraFunctionItem} onMouseDown={showAllClick}>
                <Icon iconName="search" style={{ marginRight: "4px" }} automationId="showAllItem" />
                {StaticWebAppResources.Selectors.Common.ShowAll}
            </div>
        </div>
    );
};

export default AllMenuItemSection;
