import State from "@Toolkit/ReactClient/Common/StateManaging";
import DeviceDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/DeviceDetail";
import MedicalConditionHistoryItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemBase";
import UsageDetail from "./UsageDetail";

export default class DeviceUseStatementHistoryItem extends MedicalConditionHistoryItemBase {    
    @State.observable.ref public deviceDetail: DeviceDetail;
    @State.observable.ref public usageDetail: UsageDetail;
        
    @State.action.bound
    public setDeviceDetail(newValue: DeviceDetail) {
        this.deviceDetail = newValue;
    }

    @State.action.bound
    public setUsageDetail(newValue: UsageDetail) {
        this.usageDetail = newValue;
    }
}
