import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CoverageEligibilityCheckAvailability from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CheckCoverageEligibility/CoverageEligibilityCheckAvailability";
import CoverageEligibilityCheckResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CheckCoverageEligibility/CoverageEligibilityCheckResult";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import Led from "@CommonControls/Led";

interface ICoverageEligibilityCheckLedDependencies {
    localizationService: ILocalizationService;
}

interface ICoverageEligibilityCheckLedProps {
    coverageEligibilityCheckAvailability: CoverageEligibilityCheckAvailability;
    coverageEligibilityCheckResult: CoverageEligibilityCheckResult;
    checkedAt: LocalDate;
    label?: string;
    automationId?: string;
    _dependencies?: ICoverageEligibilityCheckLedDependencies;
    visualMode?: "normal" | "dark";    
}

@State.observer
class CoverageEligibilityCheckLed extends React.Component<ICoverageEligibilityCheckLedProps> {

    @State.computed private get ledColor() {
        switch (this.props.coverageEligibilityCheckAvailability) {
            case CoverageEligibilityCheckAvailability.Available:
                switch (this.props.coverageEligibilityCheckResult) {
                    case CoverageEligibilityCheckResult.Green:
                        return "#21C313";
                    case CoverageEligibilityCheckResult.Red:
                        return "#CE5374";
                    case CoverageEligibilityCheckResult.Blue:
                        return "#82D4ED";
                    case CoverageEligibilityCheckResult.Yellow:
                        return "#DDC00D";
                    case CoverageEligibilityCheckResult.Brown:
                        return "#978307";
                    case CoverageEligibilityCheckResult.Orange:
                        return "#FFA500";
                }
                break;
            case CoverageEligibilityCheckAvailability.Requested:
                return "white";
            case CoverageEligibilityCheckAvailability.Error:
                return "#4f5b72";
            default:
                return null;
        }
    }

    @State.computed private get ledToolTipText() {
        if (this.props.coverageEligibilityCheckAvailability === CoverageEligibilityCheckAvailability.Available) {
            return this.getCoverageEligibilityCheckResultText(this.props.coverageEligibilityCheckResult);
        } else if (this.props.coverageEligibilityCheckAvailability === CoverageEligibilityCheckAvailability.Requested ||
            this.props.coverageEligibilityCheckAvailability === CoverageEligibilityCheckAvailability.Error) {
            return this.getCoverageEligibilityCheckAvailabilityText(this.props.coverageEligibilityCheckAvailability);
        } else {
            return null;
        }
    }

    public getCoverageEligibilityCheckResultText(value: CoverageEligibilityCheckResult) {
        return this.props._dependencies.localizationService.localizeEnum(CoverageEligibilityCheckResult[value], "CoverageEligibilityCheckResult").Name;
    }

    public getCoverageEligibilityCheckAvailabilityText(value: CoverageEligibilityCheckAvailability) {
        return this.props._dependencies.localizationService.localizeEnum(CoverageEligibilityCheckAvailability[value], "CoverageEligibilityCheckAvailability").Name;
    }

    @State.computed private get ledHasBorder() {
        return this.props.coverageEligibilityCheckAvailability === CoverageEligibilityCheckAvailability.Requested;
    }

    @State.computed private get ledInnerLabel() {
        return this.props._dependencies.localizationService.localizeDate(this.props.checkedAt);
    }

    public render() {
        return (
            <Led
                ledColor={this.ledColor}
                bordered={this.ledHasBorder}
                label={this.props.label}
                innerLabel={this.ledInnerLabel}
                toolTipText={this.ledToolTipText}
                automationId={this.props.automationId} 
                visualMode={this.props.visualMode} />
        );
    }
}

export default connect(
    CoverageEligibilityCheckLed,
    new DependencyAdapter<ICoverageEligibilityCheckLedProps, ICoverageEligibilityCheckLedDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService")
    }))
);