import Di from "@Di";
import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import ModalServiceRegistry from "@Toolkit/ReactClient/Components/ModalService/ModalServiceRegistry";
import NewSurgeryDialog from "./Components/Dialogs/NewSurgeryDialog/NewSurgeryDialog";
import NewSurgerytDialogParams from "./Components/Dialogs/NewSurgeryDialog/NewSurgeryDialogParams";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import StaticSurgeryResources from "./StaticResources/StaticSurgeryResources";
import { SurgeryTypeDefinitionSelector } from "@HisPlatformControls";
import SurgeryTypeDefinitionId from "@Primitives/SurgeryTypeDefinitionId.g";
import DependencyContainer from "@DiContainer";
import SurgeryReferenceDataStore from "./ApplicationLogic/Model/SurgeryReferenceDataStore";

@Di.injectable()
export default class SurgeryBoundedContext implements IBoundedContext {
    public initializeAsync(): Promise<void> {
        return Promise.resolve();
    }

    public configureModals(modalServiceRegistry: ModalServiceRegistry) {
        modalServiceRegistry.register(NewSurgerytDialogParams.type, NewSurgeryDialog);
    }

    public configureFormEngine(registry: FormEditorRegistry): void {
        registry.registerEntityReferenceEditor(
            "SurgeryTypeDefinition",
            {
                displayName: StaticSurgeryResources.ReferenceData.SurgeryTypeDefinitions.FormEngineEntityReferenceDisplayName,
                componentType: SurgeryTypeDefinitionSelector,
                entityIdCtor: SurgeryTypeDefinitionId,
                loadReferenceDataAsync: (id: SurgeryTypeDefinitionId | SurgeryTypeDefinitionId[]) => {
                    if (Array.isArray(id)) {
                        DependencyContainer.get<SurgeryReferenceDataStore>("SurgeryReferenceDataStore").surgeryTypeDefinitions.ensureLoadedAsync(id);
                    } else {
                        DependencyContainer.get<SurgeryReferenceDataStore>("SurgeryReferenceDataStore").surgeryTypeDefinitions.ensureLoadedAsync([id]);
                    }
                    return Promise.resolve();
                }
            }
        );
    }
}