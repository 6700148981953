import React, { Fragment, useEffect } from "react";
import * as Ui from "@CommonControls";
import Styles from "./HomeMenu.less";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import { provideHomeMenuStore, useHomeMenuStore } from "./HomeMenuStoreProvider";
import Shortcut from "@Toolkit/ReactClient/Components/Shortcut/Shortcut";
import config from "@Config";
import HomeControllerStore from "@HisPlatform/Packages/Care/Components/HomeMenu/HomeControllerStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { useHomeControllerStore } from "@HisPlatform/Packages/Care/Components/HomeMenu/HomeControllerProvider";

export interface IHomeMenuProps {
    _homeController?: HomeControllerStore;
}

function _HomeMenu(_: IHomeMenuProps) {

    const store = useHomeMenuStore();

    return (
        <>
            <Ui.NavBar.Item
                onClick={store.openSelectedAsync.fireAndForget}
                style={{ paddingRight: 3 }}
                automationId="navbarHomeMenu"
                content={<SpanWithIcon iconName="home" visualStyle="white" wrapperClassName={Styles.selectedHomeContent}>{store.selectedItemDisplayName}</SpanWithIcon>}
                hasNestedItems
                isDrowdownVisible={store.isOpen}
                onMouseEnter={store.open}
                onMouseLeave={store.close}
            >
                <div className={Styles.container}>
                    <div className={Styles.searchBox}>
                        <Ui.TextBox
                            stopClickPropagation
                            onChange={store.setSearchText}
                            value={store.searchText}
                            style={{ margin: 4 }}
                            automationId="navbarHomeMenu_search_textBox"
                            placeholder="Keresés..."
                            focusEvent={store.searchFocusEvent}
                            onFocus={store.searchFocused}
                            onKeyDown={store.keyDown}
                            disableAutofill
                        />
                    </div>

                    <div className={Styles.items}>

                        {store.filteredItemGroups.map(group => (
                            <Fragment key={group.key}>
                                {group.title}
                                {group.items.map((item) => {
                                    return (
                                        <Ui.NavBar.Item key={item.key} isHovered={item.index === store.hoveredIndex} onClick={store.selectItemFactory(item)}>
                                            <SpanWithIcon iconName={item.iconName}>
                                                <Ui.HighlightableLabel filterValue={store.searchText} label={item.displayValue} />
                                            </SpanWithIcon>
                                        </Ui.NavBar.Item>
                                    );
                                })}
                            </Fragment>
                        ))}

                    </div>
                </div>
            </Ui.NavBar.Item>
            <Shortcut keys={config.keyboardShortcuts.openHomeMenu} onPressed={store.open} />
            <Shortcut keys={config.keyboardShortcuts.goHome} onPressed={store.openSelectedAsync.fireAndForget} />
        </>
    );
}

const _HomeMenuWithStore = provideHomeMenuStore(_HomeMenu);

export default function HomeMenu() {
    const controller = useHomeControllerStore();
    return React.createElement(_HomeMenuWithStore, { _homeController: controller });
}