import AuthorizationReferenceDataStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/AuthorizationReferenceDataStore";
import RoleId from "@Primitives/RoleId.g";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import RoleSelectBox from "@HisPlatform/BoundedContexts/Authorization/Components/Controls/RoleSelectBox";
import Button from "@CommonControls/Button";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import UserGroup from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroup";
import * as Styles from "./RoleEditorDialog.less";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import PointOfCareRoles from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Actions/PointOfCareRoles";
import StaticWebAppResources from "@StaticResources";
import UserHeader from "@HisPlatform/BoundedContexts/UserManagement/Components/Controls/UserHeader";
import { IModalComponentParams, IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PointOfCare from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/PointOfCare";

interface IRoleEditorDialogViewProps {
    onSave: () => void;
    onClose: () => void;
    onRoleIdsChange: (newIds: RoleId[]) => void;
    pointOfCareRoles: PointOfCareRoles;
    relatedEntity: UserGroup | User;
    practitioner?: PractitionerStore;
    isNew: boolean;
    selectedIds: RoleId[];
    pointOfCareMap: ReferenceDataStore<PointOfCareId, PointOfCare>;
}


const RoleEditorDialogView: React.SFC<IRoleEditorDialogViewProps> = props => {

    const renderBody = () => {
        return (
            props.pointOfCareRoles &&
            (
                <>
                    <Ui.Message type="info">
                        {<Ui.Icon iconName={"info"} />}
                        {` ${StaticUserManagementResources.UserGroupManagementPanel.RolePanel.PointOfCare}: `}
                        <b> {props.pointOfCareMap.get(props.pointOfCareRoles.id).name}</b>
                    </Ui.Message>
                    <RoleSelectBox multiSelect={true}
                        value={props.selectedIds}
                        onChange={props.onRoleIdsChange}
                        label={StaticUserManagementResources.UserGroupManagementPanel.RolePanel.Roles}
                        automationId="roleSelectbox"
                    />
                </>
            )
        );
    };

    const renderHeader = () => {
        if (props.relatedEntity instanceof UserGroup) {
            return renderUserGroupHeader();
        }
        return renderUserHeader();
    };

    const renderUserGroupHeader = () => {
        return (
            <div className={Styles.headerContainer}>
                <p className={Styles.modalHugeTitle}>{props.relatedEntity?.displayName}</p>
            </div>
        );
    };

    const renderUserHeader = () => {
        return (
            <UserHeader user={props.relatedEntity as User} practitioner={props.practitioner} />
        );
    };
    return (
        <Ui.Modal
            isOpen
            onClose={props.onClose}
            title={StaticUserManagementResources.UserGroupManagementPanel.RolePanel.RoleModalTile}
            closeOnOutsideClick={false}
            automationId="__roleEditorModal"
            renderContextHeader={renderHeader}
            size={"tiny"}
        >
            <Ui.Modal.Body>
                {renderBody()}
            </Ui.Modal.Body>
            <Ui.Modal.Footer>
                    <Ui.Flex xsJustify="between" verticalSpacing="none" >
                        <Ui.Flex.Item>
                            <Button text={StaticUserManagementResources.UserGroupManagementPanel.RolePanel.Cancel}
                                onClick={props.onClose}
                                visualStyle="link"
                                automationId="cancelButton"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item>
                            {props.isNew ?
                                <Button
                                    text={StaticUserManagementResources.UserGroupManagementPanel.RolePanel.SetRoles}
                                    visualStyle="primary"
                                    onClick={props.onSave}
                                    disabled={!props.selectedIds?.length}
                                    automationId="save"
                                /> :
                                <Ui.SaveButton
                                    visualStyle="primary"
                                    onClick={props.onSave}
                                    disabled={!props.selectedIds?.length}
                                    automationId="save"
                                />}
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.Modal.Footer>
        </Ui.Modal>
    );
};

export default RoleEditorDialogView;
