exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CareActivityTextBlockList_required-star_ZF3zV {\n  color: #CE5374;\n  position: relative;\n  top: 2px;\n  left: 2px;\n  font-size: 19px;\n}\n.CareActivityTextBlockList_required-star_ZF3zV::after {\n  content: \"*\";\n}\n", ""]);

// exports
exports.locals = {
	"required-star": "CareActivityTextBlockList_required-star_ZF3zV",
	"requiredStar": "CareActivityTextBlockList_required-star_ZF3zV"
};