import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import { DataGrid, Icon } from "@CommonControls";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import DocumentTypeColumn from "@HisPlatformControls/DataGridColumns/DocumentTypeColumn";
import ITemplateListItem from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateListItem";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import OrganizationUnitsColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/OrganizationUnitsColumn/OrganizationUnitsColumn";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import _ from "@HisPlatform/Common/Lodash";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";

interface IDocumentTemplateManagementMasterPanelDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IDocumentTemplateManagementMasterPanelProps extends IHasMasterDetailState {
    _dependencies?: IDocumentTemplateManagementMasterPanelDependencies;

    dataSource: InMemoryDataGridDataSource<ITemplateListItem>;
}

/** @screen */
@State.observer
class DocumentTemplateManagementMasterPanel extends React.Component<IDocumentTemplateManagementMasterPanelProps> {

    public render() {
        return (
            <DataGrid
                dataSource={this.props.dataSource}
                rowComparer="rowId"
                rowId={this.getRowId}
                selectedRow={this.props._masterDetailState!.selectedItem}
                onSelectedRowChange={this.props._masterDetailState!.onSelectedItemChange}
                isSelectable
                fixedLayout
                rowHeight={42}
                fixedHeight="100%"
                generateInitialFilterStore
                changeOnMount
                automationId="documentTemplateGrid"
            >
                <DataGridColumn
                    automationId="templateName"
                    header={StaticDocumentManagementResources.TemplateManagement.Columns.TemplateName}
                    onRenderCellValue={this.renderNameCell}
                    width="40%"
                    isFilterable
                    clientSideFilterableValueGetter={this.getNameCellRawValue}
                    clientSideOrderableValueGetter={this.getNameCellRawValue}
                />
                <DocumentTypeColumn
                    automationId="documentType"
                    header={StaticDocumentManagementResources.TemplateManagement.Columns.DocumentType}
                    dataGetter={"documentTypeId"}
                    clientSideFilterableValueGetter={"documentTypeId"}
                    clientSideOrderableValueGetter={"documentTypeId"}
                    width="20%"
                    isFilterable
                />
                <OrganizationUnitsColumn
                    automationId="availableAt"
                    dataGetter={"availableAt"}
                    header={StaticDocumentManagementResources.TemplateManagement.Columns.PointOfCaresWhereAvailable}
                    width="40%"
                    isFilterable
                    clientSideFilterPredicate={this.filterByOrganizationUnits}
                />
                <DataGridColumn
                    automationId="isDeleted"
                    header={StaticDocumentManagementResources.TemplateManagement.Columns.State}
                    onRenderCellValue={this.renderDeletedCell}
                    width={this.props._masterDetailState.isDetailOpen ? 85 : 105}
                />

            </DataGrid>
        );
    }

    @State.bound
    private filterByOrganizationUnits(filterValue: PointOfCareId[], value: OrganizationUnitId[]) {
        if (arrayIsNullOrEmpty(filterValue) || arrayIsNullOrEmpty(value)) {
            return true;
        }

        return _.intersection(filterValue.map(v => v.value), value.map(v => v.value)).length > 0;
    }

    @State.bound
    private getNameCellRawValue(value: void, row: ITemplateListItem) {
        return `${row.code} - ${row.name}`;
    }

    @State.bound
    private renderNameCell(value: void, row: ITemplateListItem) {
        return (
            <SpanWithIcon iconName="document">{row.code} - {row.name}</SpanWithIcon>
        );
    }

    @State.bound
    private renderDeletedCell(value: void, row: ITemplateListItem) {
        if (row.isDeleted) {
            return StaticDocumentManagementResources.TemplateManagement.StateColumnValueDeleted;
        } else {
            return StaticDocumentManagementResources.TemplateManagement.StateColumnValueActive;
        }

    }

    @State.bound
    private getRowId(row: ITemplateListItem) {
        return row.id.value;
    }
}

export default connect(
    DocumentTemplateManagementMasterPanel,
    new DependencyAdapter<IDocumentTemplateManagementMasterPanelProps, IDocumentTemplateManagementMasterPanelDependencies>(c => ({
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
    }))
);