exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".UnknownTokenSettingsPanel_json-editor_2ebpa textarea {\n  font-family: Consolas, 'Courier New';\n}\n", ""]);

// exports
exports.locals = {
	"json-editor": "UnknownTokenSettingsPanel_json-editor_2ebpa",
	"jsonEditor": "UnknownTokenSettingsPanel_json-editor_2ebpa"
};