import { createNestedRoutes } from "@Toolkit/ReactClient/Routing/CreateNestedRoutes";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import IOutpatientTreatmentRouteParams from "@HisPlatform/Application/Routes/IOutpatientTreatmentRouteParams";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import IRatFormCareActivityBasedRouteParams from "@HisPlatform/Components/Pages/OutpatientTreatment/IRatFormCareActivityBasedRouteParams";
import IPregnancyRiskFormCareActivityBasedRouteParams from "@HisPlatform/Components/Pages/OutpatientTreatment/IPregnancyRiskFormCareActivityBasedRouteParams";

export interface ICareUseCaseRouteParams extends IOutpatientTreatmentRouteParams {
    useCase?: string;
}

const standaloneRouteDefinitions = createNestedRoutes(ApplicationRoutes.careActivity, {
    ehiCovidStatusReports: new RouteDefinition<ICareUseCaseRouteParams>("/ehiCovidStatusReports/:useCase?"),
    ratForms: new RouteDefinition<IRatFormCareActivityBasedRouteParams>("/ratForms/:selectedId?/:useCase?"),
    pregnancyRiskForms: new RouteDefinition<IPregnancyRiskFormCareActivityBasedRouteParams>("/pregnancyRiskForms/:selectedId?/:useCase?")
});

export default standaloneRouteDefinitions;