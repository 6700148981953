import moment from "moment";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { ISchedulerEvent } from "@CommonControls/Scheduler/ISchedulerProps";
import SlotStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/SlotStatus.g";

export default class AppointmentSchedulerEvent implements ISchedulerEvent {
    @State.observable.ref public startTime: moment.Moment;
    @State.observable.ref public endTime: moment.Moment;
    public isReadOnly: boolean;

    constructor(
        public readonly id: number,
        startTime: moment.Moment,
        endTime: moment.Moment,
        public readonly state: SlotStatus
    ) {
        this.startTime = startTime;
        this.endTime = endTime;
        this.isReadOnly = state === SlotStatus.Free;
    }

    @State.bound
    public getDurationInMinutes() {
        return this.endTime.clone().diff(this.startTime, "minutes");
    }
}