import PersonNameLabel from "@CommonControls/PersonNameLabel";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import { usePatientContext } from "@HisPlatform/Model/DomainModel/PatientContext/PatientContext";
import PatientDocumentFormattingRegistry from "@HisPlatform/Services/Implementation/PatientDocumentFormattingRegistry/PatientDocumentFormattingRegistry";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import IPatientDocumentFormattingRegistry from "@PluginInterface/PatientDocumentFormatting/IPatientDocumentFormattingRegistry";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import React, { useMemo } from "react";

interface IPatientWidgetExtensionProps {
}

function PatientWidgetExtension(props: IPatientWidgetExtensionProps) {
    const patientContext = usePatientContext();
    const { localizationService } = useDependencies(c => ({
        localizationService: c.resolve<ILocalizationService>("ILocalizationService")
    }));

    const patientBirthDate = useMemo(() => patientContext?.patient?.baseData?.ageInfo?.birthDate && localizationService.localizeDate(patientContext.patient.baseData.ageInfo.birthDate), [patientContext?.patient?.baseData?.ageInfo?.birthDate]);
    const patientGenderCode = useMemo(() => patientContext?.patient?.baseData?.genderId && localizationService.localizeEnumId(patientContext.patient.baseData.genderId).Shorthand, [patientContext?.patient?.baseData?.genderId]);

    if (!patientContext.patient.baseData) {
        return null;
    }

    return (
        <div style={{ margin: "0 6px" }}>
            <section>{StaticWebAppResources.OutpatientWorkflowPage.Label.BirthName}:&nbsp;<b><PersonNameLabel personName={patientContext.patient.baseData.hasBirthName ? patientContext.patient.baseData.birthName : patientContext.patient.baseData.name} /></b></section>
            <section>{getInsuranceRelationshipDocumentValue(patientContext.patient)}</section>
            <section>{StaticWebAppResources.OutpatientWorkflow.OutpatientHeader.BirthDate}:&nbsp;<b>{patientBirthDate} ({patientContext.patient.age.value && patientContext.patient.age.case({ fulfilled: (value) => (value) })}/{patientGenderCode})</b></section>
        </div>
    );
}

const getInsuranceRelationshipDocumentValue = (patient: PatientAdministrativeData) => {

    const identifierSystemsByPriority = [
        "HunSocialSecurityNumber",
        "NewbornHunSocialSecurityNumber",
        "ReplacementHunSocialSecurityNumber",
        "EuCard",
        "EuCardReplacement",
        "Other"
    ];

    for (const identifierSystem of identifierSystemsByPriority) {
        const foundDocument = patient.patientDocuments.find(pd => pd.identifierSystemId.value === identifierSystem);

        if (foundDocument) {
            return <Document identifierSystemId={foundDocument.identifierSystemId} identifierValue={foundDocument.identifierValue} />;
        }
    }

    return <>{patient.identifier}</>;
};

function Document(props: { identifierSystemId: IdentifierSystemId, identifierValue: string }) {

    const { commonReferenceDataStore, patientDocumentFormattingRegistry } = useDependencies(c => ({
        commonReferenceDataStore: c.resolve<CommonReferenceDataDataStore>("CommonReferenceDataDataStore"),
        patientDocumentFormattingRegistry: c.resolve<IPatientDocumentFormattingRegistry>("IPatientDocumentFormattingRegistry"),
    }));

    const documentType = commonReferenceDataStore.identifierSystemMap.getLocalization(props.identifierSystemId);

    return (
        <span>
            {documentType?.Name ?? "?"}:&nbsp;<b>{patientDocumentFormattingRegistry.format(props.identifierSystemId.value, props.identifierValue)}</b>
        </span>
    );
}

export default State.observer(PatientWidgetExtension);