import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import PatientApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/Patient/PatientApiAdapter";
import EhiEvent from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEvent";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PatientId from "@Primitives/PatientId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EhiCareActivityBaseDataPanelView from "./EhiCareActivityBaseDataPanelView";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import EhiCareService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareService";
import EhiServiceCallStatus from "@HunEHealthInfrastructurePlugin/Model/EhiServiceCallStatus";
import PatientApiAdapter2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/PatientApiAdapter2";
import RequestStatus from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/RequestStatus";

interface IEhiCareActivityBaseDataPanelDependencies {
    localizationService: ILocalizationService;
    ehiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    careReferenceDataStore: CareReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    patientApiAdapter: PatientApiAdapter2;
}

interface IEhiCareActivityBaseDataPanelProps {
    _dependencies?: IEhiCareActivityBaseDataPanelDependencies;
    patientId: PatientId;
    ehiCareService: EhiCareService;
    ehiId: string;
    isEmergency: boolean;
    emergencyReason: string;

    onLoaded?: (event: EhiEvent) => void;
}

/** @screen */
@State.observer
class EhiCareActivityBaseDataPanel extends React.Component<IEhiCareActivityBaseDataPanelProps> {

    private get localizationService() { return this.props._dependencies.localizationService; }
    private get ehiCareReferenceDataStore() { return this.props._dependencies.ehiCareReferenceDataStore; }
    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get organizationReferenceDataStore() { return this.props._dependencies.organizationReferenceDataStore; }
    private get patientApiAdapter() { return this.props._dependencies.patientApiAdapter; }

    @State.observable.ref private event: EhiEvent = null;
    @State.observable.ref private errors: EhiError[] = [];
    @State.observable.ref private patientName: string = "";
    @State.observable.ref private isLoading = false;

    @State.action.bound
    private setEvent(newValue: EhiEvent) {
        this.event = newValue;
        this.props.onLoaded?.(newValue);
    }

    @State.action.bound
    private setPatientName(newValue: string) {
        this.patientName = newValue;
    }

    @State.action.bound
    private setErrors(newValue: EhiError[]) {
        this.errors = newValue;
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initializeAsync(), this);
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IEhiCareActivityBaseDataPanelProps) {
        if (this.props.ehiId !== prevProps.ehiId) {
            dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        }
    }

    @State.bound
    private async initializeAsync() {
        await this.ehiCareReferenceDataStore.ehiCareTypes.ensureAllLoadedAsync();
        await this.ehiCareReferenceDataStore.ehiDischargeReasons.ensureLoadedAsync();
        await this.ehiCareReferenceDataStore.ehiEventTypes.ensureLoadedAsync();
        await this.ehiCareReferenceDataStore.ehiPatientIdentifierTypes.ensureLoadedAsync();
        await this.ehiCareReferenceDataStore.ehiInpatientAdmissionTypes.ensureLoadedAsync();
        await this.ehiCareReferenceDataStore.ehiInpatientAdmissionClassifications.ensureLoadedAsync();
        await this.ehiCareReferenceDataStore.ehiPatientFurtherTreatments.ensureLoadedAsync();
        await this.ehiCareReferenceDataStore.ehiEmergencyLevelClaimTypes.ensureLoadedAsync();
        await this.ehiCareReferenceDataStore.ehiEmergencyExaminationClaimTypes.ensureLoadedAsync();
        await this.ehiCareReferenceDataStore.ehiEReferralServiceTypes.ensureAllLoadedAsync();
    }

    @State.boundLoadingState()
    private async loadAsync() {
        if (this.props.ehiId) {
            const ehiServiceCallResult = await this.props.ehiCareService.getEventDetailsAsync(
                this.props.patientId,
                this.props.ehiId,
                this.props.isEmergency,
                this.props.emergencyReason);

            if (ehiServiceCallResult.ehiServiceCallStatus === EhiServiceCallStatus.Success &&
                ehiServiceCallResult.result &&
                ehiServiceCallResult.result.operationWasSuccessful) {

                const event = ehiServiceCallResult.result.value;

                this.setEvent(event.value);
                this.setErrors(event.errors);
                await this.loadReferenceDataAsync(event.value);
                await this.loadPatientNameAsync();
            }
        }
    }

    @State.bound
    private async loadPatientNameAsync() {
        if (!this.props.patientId) {
            return;
        }

        const patientData = await this.patientApiAdapter.loadByIdAsync(this.props.patientId);
        if (patientData && patientData.operationInfo.requestStatus === RequestStatus.Success && patientData.result.baseData.name) {
            this.setPatientName(this.localizationService.localizePersonName(patientData.result.baseData.name));
        }
    }

    @State.bound
    private async loadReferenceDataAsync(ehiEvent: EhiEvent) {
        if (!ehiEvent) {
            return;
        }

        const validOn = ehiEvent.beginningOfCare ? LocalDate.createFromMoment(ehiEvent.beginningOfCare) : LocalDate.today();

        if (ehiEvent.referralDiagnosisId) {
            await this.careReferenceDataStore.condition.ensureLoadedAsync([{ id: ehiEvent.referralDiagnosisId, validOn: validOn }]);
        }
        if (ehiEvent.careDoctorId) {
            await this.organizationReferenceDataStore.doctorMap.ensureLoadedAsync([ehiEvent.careDoctorId]);
        }
        if (ehiEvent.referralDoctorId) {
            await this.organizationReferenceDataStore.doctorMap.ensureLoadedAsync([ehiEvent.referralDoctorId]);
        }
        if (ehiEvent.referralWorkplaceId) {
            await this.ehiCareReferenceDataStore.ehiHealthcareProviderOrganizationUnits.ensureLoadedAsync([ehiEvent.referralWorkplaceId]);
        }
        if (ehiEvent.careOrganizationUnitId) {
            await this.ehiCareReferenceDataStore.ehiHealthcareProviderOrganizationUnits.ensureLoadedAsync([ehiEvent.careOrganizationUnitId]);
        }
        if (ehiEvent.rescueReceivingInstituteId) {
            await this.ehiCareReferenceDataStore.ehiHealthcareProviders.ensureLoadedAsync([{ id: ehiEvent.rescueReceivingInstituteId, validOn: validOn }]);
        }
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }
        if (this.isLoading) {
            return <></>;
        }

        return (
            <EhiCareActivityBaseDataPanelView
                patientName={this.patientName}
                event={this.event}
                errors={this.errors}
                reloadAsync={this.loadAsync}
            />
        );
    }
}

export default connect(
    EhiCareActivityBaseDataPanel,
    new DependencyAdapter<IEhiCareActivityBaseDataPanelProps, IEhiCareActivityBaseDataPanelDependencies>(container => {
        return {
            localizationService: container.resolve("ILocalizationService"),
            ehiCareReferenceDataStore: container.resolve("HunEhiCareReferenceDataStore"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            patientApiAdapter: container.resolve("PatientApiAdapter2")
        };
    })
);
