/**
 * Abstract base class for a command that can be processed an associated command processor.
 */
export default abstract class Command {
    /**
     * Initializes a new instance of {@link Command}.
     * @param commandType The type of the command.
     * @param originator The name of the originator.
     */
    constructor(
        public readonly commandType: string,
        public readonly originator: string
    ) { }
}