import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import CustomerListScreen from "@HisPlatform/Packages/Settings/Screens/Customer/CustomerListScreen";
import CustomerScreen from "@HisPlatform/Packages/Settings/Screens/Customer/CustomerScreen";
import { getNavigationMenuItems } from "@HisPlatform/Packages/Organization/Configuration/NavigationMenuItems";
import ShowDynamicPropertiesScreenAction from "@HisPlatform/Packages/Organization/FrontendActions/ShowDynamicPropertiesScreenAction.g";
import ShowExternalLocationsScreenAction from "@HisPlatform/Packages/Organization/FrontendActions/ShowExternalLocationsScreenAction.g";
import ShowOrganizationStructureScreenAction from "@HisPlatform/Packages/Organization/FrontendActions/ShowOrganizationStructureScreenAction.g";
import IScreenRegistry from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import NavigateToRouteActionProcessor from "@HisPlatform/Services/Implementation/ActionProcessing/NavigateToRouteActionProcessor";
import GlobalActionRegistry from "@Toolkit/ReactClient/ActionProcessing/GlobalActionRegistry";
import ShowCustomerListScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowCustomerListScreenAction.g";
import ShowCustomerScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowCustomerScreenAction.g";

class OrganizationPackageInitializer implements IPackageInitializer {

    public configureActionProcessor(registry: GlobalActionRegistry) {

        registry.register(
            ShowDynamicPropertiesScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => ApplicationRoutes.configurationEditor.makeRoute()));

        registry.register(
            ShowOrganizationStructureScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => ApplicationRoutes.organizationEditor.makeRoute()));

        registry.register(
            ShowExternalLocationsScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => ApplicationRoutes.externalLocations.makeRoute()));
    }

    public configureDynamicNavigation(registry: IDynamicNavigationRegistry): void {
        getNavigationMenuItems().forEach(navigationItem => registry.register(navigationItem));
    }

    public configureScreens(registry: IScreenRegistry): void {
        registry.register(ShowCustomerListScreenAction.id, {
            component: CustomerListScreen,
        });
        
        registry.register(ShowCustomerScreenAction.id, {
            component: CustomerScreen,
        });
    }
}

export default new OrganizationPackageInitializer();