import State from "@Toolkit/ReactClient/Common/StateManaging";
import PractitionerId from "@Primitives/PractitionerId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

export default class PrescriptionPractitionerRecommendation {
    @State.observable.ref public practitionerId: PractitionerId;
    @State.observable.ref public careIdentifier: string;
    @State.observable.ref public issuedOn: LocalDate;
    @State.observable.ref public ehiIdentifier: string = null;
    
    @State.action.bound
    public setEhiIdentifier(ehiIdentifier: string) {
        this.ehiIdentifier = ehiIdentifier;
    }

    @State.action.bound 
    public setPractitionerId(practitionerId: PractitionerId) {
        this.practitionerId = practitionerId;
    }

    @State.action.bound 
    public setCareIdentifier(careIdentifier: string) {
        this.careIdentifier = careIdentifier;
    }

    @State.action.bound 
    public setIssuedOn(issuedOn: LocalDate) {
        this.issuedOn = issuedOn;
    }
}