import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class PerformanceReportEnumBasedGrouping {
    @State.observable public selectedColumns: boolean[];
    @State.observable public selectedOrdering: any;

    @State.action.bound public setSelectedColumns(newValue: boolean[]) {
        this.selectedColumns = newValue;
    }

    @State.action.bound public setSelectedColumn(newValue: boolean, index: number) {
        this.selectedColumns[index] = newValue;
    }

    @State.action.bound public setSelectedOrdering(newValue: any) {
        this.selectedOrdering = newValue;
    }
}