import React from "react";
import Styles from "./Modal.less";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";


const ModalBody: React.SFC<{ noGap?: boolean}> = (props) => { 
    const styles = new CompositeClassName(Styles.modalBody);
    styles.addIf(props.noGap, Styles.modalBodyNoGap);

    return (
        <div className={styles.classNames} data-automation-id={"__modalBody"}> 
            {props.children}
        </div>
    );
};

export default ModalBody;