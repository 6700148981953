import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import React from "react";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IPreviousTextBlocksDialogResult, IPreviousTextBlocksDialogParams } from "./PreviousTextBlocksDialogParams";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import CareActivityTextBlockApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityTextBlock/CareActivityTextBlockApiAdapter";
import ICareActivityPreviousTextBlockItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/ICareActivityPreviousTextBlockItem";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PreviousTextBlocksGrid from "./PreviousTextBlocksGrid";
import { ContextAwareModal, HisDocumentEditor } from "@HisPlatformControls";
import { IPagingState, IOrderingState, DataGridLoadType } from "@CommonControls/DataGrid/IDataGridProps";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import CareActivityTextBlockId from "@Primitives/CareActivityTextBlockId.g";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import DocumentContentStore from "@CommonControls/DocumentEditor/DocumentContentStore";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import _ from "@HisPlatform/Common/Lodash";

interface IPreviousTextBlocksDialogDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    apiAdapter: CareActivityTextBlockApiAdapter;
}

interface IPreviousTextBlocksDialogProps extends IModalComponentParams<IPreviousTextBlocksDialogResult>, IPreviousTextBlocksDialogParams {
    _dependencies: IPreviousTextBlocksDialogDependencies;
}

/** @screen */
@State.observer
class PreviousTextBlocksDialog extends React.Component<IPreviousTextBlocksDialogProps> {
    private get organizationReferenceDataStore() { return this.props._dependencies.organizationReferenceDataStore; }
    private get apiAdapter() { return this.props._dependencies.apiAdapter; }

    @State.observable.ref private results: PagedItemStore<ICareActivityPreviousTextBlockItem> = null;
    @State.observable.ref private selectedItem: ICareActivityPreviousTextBlockItem = null;
    private readonly selectedContent = DocumentContentStore.create();
    @State.observable.ref private filterStore: any = null;
    @State.observable.ref private paging: IPagingState = {
        currentPage: 0,
        pageSize: 10
    };

    @State.observable.ref private ordering: IOrderingState = null;

    @State.action.bound
    public setSelectedItem(selectedItem: ICareActivityPreviousTextBlockItem) {
        this.selectedItem = selectedItem;
        dispatchAsyncErrors(this.loadContentAsync(selectedItem?.id), this);
    }

    @State.action.bound
    private setGridState(
        results: PagedItemStore<ICareActivityPreviousTextBlockItem>,
        paging: IPagingState,
        ordering: IOrderingState,
        filterStore: any
    ) {
        this.results = results;
        this.paging = paging;
        this.ordering = ordering;
        this.filterStore = filterStore;
    }

    @State.bound
    public async onChangeAsync(
        type: DataGridLoadType,
        paging: IPagingState,
        ordering: IOrderingState | IOrderingState[],
        filterStore: any,
        columns: IDataGridColumnProps[]
    ) {
        if (type === "initial") {
            filterStore.set_TextBlockTypeIds([this.props.textBlockTypeId] as TextBlockTypeId[]);
        }

        const singleOrdering = (ordering as IOrderingState[])?.length ? ordering[0] : ordering;
        const result = await this.apiAdapter.searchCareActivityTextBlockAsync(
            this.props.careActivityId,
            this.props.patientId,
            filterStore?.TextBlockTypeIds,
            filterStore?.PointOfCareId,
            filterStore?.SearchDateRange,
            filterStore?.LastModifiedById,
            paging,
            singleOrdering);

        const pointOfCareIds = result.items.map(o => o.pointOfCareId);
        await this.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync(pointOfCareIds);
        await this.loadHealthCareProfessionsAsync(pointOfCareIds);
        this.setGridState(result, paging, singleOrdering, filterStore);
    }

    @State.bound
    private async loadHealthCareProfessionsAsync(pointOfCareIds: PointOfCareId[]) {
        const ids = _.flatten(pointOfCareIds.map(this.props._dependencies.organizationReferenceDataStore.pointOfCareMap.get).map(item => item.healthcareProfessionIds));
        const uniqIds = _.uniqBy(ids, item => item.value);
        await this.props._dependencies.organizationReferenceDataStore.healthCareProfession.ensureLoadedAsync(uniqIds);
    }

    @State.bound
    private async loadContentAsync(id: CareActivityTextBlockId) {
        if (isNullOrUndefined(id)) {
            this.selectedContent.clearContent();
        } else {
            const content = await this.apiAdapter.getCareActivityTextBlockHistoryItemContentAsync(id, -1);
            if (content.operationWasSuccessful) {
                this.selectedContent.setContent(content.value);
            }
        }
    }

    public render() {
        return (
            <ContextAwareModal isOpen onClose={this.cancel} size="normal" fixedHeight={694}>
                <Ui.Modal.Body>
                    <MasterDetailLayout onSelectedItemChange={this.setSelectedItem} selectedItem={this.selectedItem}>
                        <MasterDetail.Master title={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.PreviousCareActivityTextBlocks}>
                            <PreviousTextBlocksGrid
                                results={this.results}
                                paging={this.paging}
                                ordering={this.ordering}
                                filterStore={this.filterStore}
                                onChangeAsync={this.onChangeAsync}
                            />
                        </MasterDetail.Master>
                        <MasterDetail.Detail title={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.Content}>
                            <HisDocumentEditor contentStore={this.selectedContent.forEditor} mode="TextBlock" isReadOnly />
                        </MasterDetail.Detail>
                    </MasterDetailLayout>
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Button onClick={this.cancel} text={StaticWebAppResources.Common.Button.Close} float="left" automationId="previousTextBlockDialogCancelButton" />
                    <Ui.Button onClickAsync={this.okAsync} disabled={this.selectedItem === null} text={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.PreviousCareActivityTextBlocksDialog.InsertTextBlock} visualStyle="primary" float="right" automationId="previousTextBlockDialogOkButton" />
                </Ui.Modal.Footer>
            </ContextAwareModal>
        );
    }

    @State.bound
    private cancel() {
        this.props.onClose(null);
    }

    @State.bound
    private async okAsync() {
        const isTextSelected = await this.selectedContent.isTextSelectedAsync();

        this.props.onClose(isTextSelected ?
            await this.selectedContent.getSelectedContentAsDocumentFragmentAsync() :
            await this.selectedContent.getContentAsDocumentFragmentAsync()
        );
    }
}

export default connect(
    PreviousTextBlocksDialog,
    new DependencyAdapter<IPreviousTextBlocksDialogProps, IPreviousTextBlocksDialogDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        apiAdapter: c.resolve("CareActivityTextBlockApiAdapter")
    }))
);
