import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import WorklistTypeBoundNDataPanel, { IWorklistTypeBoundNDataPanelProps } from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/WorklistTypeBoundNDataPanel";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import InputDataClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/InputDataClientSideAction";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import InputFormType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/InputFormType.g";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import StaticFormEditingResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IFormEngineApiAdapter from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";


interface IFormBoundNDataPanelDependencies {
    notificationService: INotificationService;
    dialogService: IDialogService;
    formEngineApiAdapter: IFormEngineApiAdapter;
}

interface IFormBoundNDataPanelProps extends Omit<IWorklistTypeBoundNDataPanelProps, "_dependencies"> {
    _dependencies?: IFormBoundNDataPanelDependencies;
}

@State.observer
class FormBoundNDataPanel extends React.Component<IFormBoundNDataPanelProps> {

    private readonly refreshListEvent = new TypedAsyncEvent();

    @State.action.bound
    private async refreshListAsync() {
        await this.refreshListEvent.emitAsync();
    }

    @State.action.bound
    private async performClientSideActionAsync(action: ClientSideActionDto): Promise<WorkListArgument | boolean> {
        if (action instanceof InputDataClientSideAction) {
            if (action.inputFormType === InputFormType.DeleteForm) {
                const id = new FormInstanceId(action.useCaseArguments[0].value.value);
                const message = StaticFormEditingResources.FormDetailPanel.DeleteConfirmationMessage;
                const title = StaticFormEditingResources.FormDetailPanel.ConfirmationTitle;
                const dialogResult = await this.props._dependencies.dialogService.yesNo(title, message);

                if (dialogResult.resultCode === DialogResultCode.No) {
                    return false;
                }

                const formInstance = await this.props._dependencies.formEngineApiAdapter.getFormInstanceDetailAsync(id);

                const response = await this.props._dependencies.formEngineApiAdapter.deleteFormInstanceAsync(id, formInstance.value.rowVersion);

                if (response.operationWasSuccessful && response.isPersistedByOperationInfo) {
                    this.props._dependencies.notificationService.showSavedSuccessfully();
                } else {
                    this.props._dependencies.notificationService.showCannotSaveBecauseOfErrors();
                }

                await this.refreshListAsync();
            }
        }
        return null;
    }

    public render() {
        return (
            <WorklistTypeBoundNDataPanel
                worklistDefinitionReference={this.props.worklistDefinitionReference}
                cacheKey={this.props.cacheKey}
                dynamicFilters={this.props.dynamicFilters}
                onChange={this.props.onChange}
                useCaseState={this.props.useCaseState}
                selectedRowId={this.props.selectedRowId}
                disableDetailStrictMode
                hasPrintButton={false}
                refreshListEvent={this.refreshListEvent}
                onPerformClientSideActionAsync={this.performClientSideActionAsync}
                hasRefreshButton
                hideMasterToolbar={this.props.hideMasterToolbar}
            />);
    }
}

export default connect(
    FormBoundNDataPanel,
    new DependencyAdapter<IFormBoundNDataPanelProps, IFormBoundNDataPanelDependencies>(c => ({
        dialogService: c.resolve<IDialogService>("IDialogService"),
        notificationService: c.resolve<INotificationService>("INotificationService"),
        formEngineApiAdapter: c.resolve<IFormEngineApiAdapter>("IFormEngineApiAdapter")
    })),
);
