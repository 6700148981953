import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import { PageBoxContainer } from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { HisErrorBoundary } from "@HisPlatformControls";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import { getUseCaseAsUrlParam, parseUseCaseStateFromUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import MedicationId from "@Primitives/MedicationId.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import { PrescriptionType } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationSelectorDialog/MedicationSelectorDialog";
import MedicationListPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/MedicationListPanel/MedicationListPanel";
import medicationStandaloneRouteDefinitions, { IMedicationRouteParams } from "@HunEHealthInfrastructurePlugin/UseCases/MedicationsUseCase/MedicationRoutes";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface IMedicationsPageDependencies {

}

interface IMedicationsPageProps extends IRoutingFrameContentProps<IMedicationRouteParams> {
    _dependencies?: IMedicationsPageDependencies;
}

@State.observer
class MedicationsPage extends React.Component<IMedicationsPageProps> {
    private validOn = LocalDate.today();

    @State.computed private get useCaseState(): INDataUseCaseState {
        if (!this.props.routingController.currentRoute.parameters.useCase) {
            return null;
        }
        const useCaseState = this.props.routingController.currentRoute.parameters.useCase;

        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.computed private get selectedRow(): MedicationId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments[0].value;
        }

        return null;
    }

    @State.observable private prescriptionType: PrescriptionType = PrescriptionType.Medication;

    @State.bound
    private navigateToEquivalentMedication(medicationId: MedicationId, useCase: UseCaseIdentifier) {
        this.props.routingController.push(medicationStandaloneRouteDefinitions.medications.makeRoute({
            ...this.props.routingController.currentRoute.parameters,
            useCase: getUseCaseAsUrlParam(useCase, UseCaseDisplayMode.MasterDetail, [new UseCaseArgument(medicationId, "medicationId")]),
            selectedRowId: medicationId.value
        }));
    }

    @State.action.bound
    private setPrescriptionType(value: PrescriptionType) {
        this.prescriptionType = value;
    }

    public render() {
        return (
            <PageBoxContainer>
                <HisErrorBoundary>
                    <SingleLayout>
                        <LoadingBoundary>
                            <MedicationListPanel
                                useCaseState={this.useCaseState}
                                onChange={this.setUseCaseState}
                                selectedRowId={this.selectedRow?.value}
                                variant="standalone"
                                navigateToEquivalentMedication={this.navigateToEquivalentMedication}
                                prescriptionType={this.prescriptionType}
                                setPrescriptionType={this.setPrescriptionType}
                                validOn={this.validOn}
                            />
                        </LoadingBoundary>
                    </SingleLayout>
                </HisErrorBoundary>
            </PageBoxContainer>
        );
    }

    @State.bound
    private setUseCaseState(rowId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.push(medicationStandaloneRouteDefinitions.medications.makeRoute({
            ...this.props.routingController.currentRoute.parameters,
            useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments),
            selectedRowId: rowId
        }));
    }
}

export default MedicationsPage;