import { ToolbarLayout } from "@CommonControls";
import { IToolbarPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { DashboardLayout } from "@HisPlatformControls";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import React from "react";
import { useScreenStateContext } from "./ScreenStateContext";

interface IScreenToolbarWrapperProps {
    topToolbarComponent?: React.ComponentType<IToolbarPropsBase<ShowScreenFrontendActionBase>>;
}

export default function ScreenToolbarWrapper(props: React.PropsWithChildren<IScreenToolbarWrapperProps>) {
    const store = useScreenStateContext();

    if (store?.displayMode === ScreenDisplayMode.Full) {
        return (
            <ToolbarLayout
                topToolbar={props.topToolbarComponent ? React.createElement(props.topToolbarComponent, {
                    action: store.action,
                    screenState: store.screenState
                }) : null}
                topToolbarHeight="auto"
            >
                {props.children}
            </ToolbarLayout>
        );
    }

    return (
        <>
            {props.children}
        </>
    );
}