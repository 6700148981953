import React from "react";
import RamonakProgressBar from "@ramonak/react-progress-bar";

interface IProgressBarProps {
    value: number;
}

export default function ProgressBar(props: IProgressBarProps) {
    return (
        <RamonakProgressBar
            completed={props.value}
            bgcolor="#00b4ec"
            borderRadius="3px"
            isLabelVisible={props.value > 5}
        />
    );
}