import Di from "@Di";
import UsersApiAdapter from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Users/UsersApiAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import UserWithIdentifiers from "./Users/UserWithIdentifiers";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import UserId from "@Primitives/UserId.g";
import UserGroupsApiAdapter from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Groups/UserGroupsApiAdapter";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";

@Di.injectable()
export default class UserManagementDataProviderStore {

    @State.observable public users = new ReferenceDataStore(this.usersApiAdapter.getUsersByIdsAsync, this.usersApiAdapter.getAllUserIdsAsync, "Users");
    @State.observable public usersWithIdentifiers = new ReferenceDataStore(this.getUsersWithIdentifiersAsync, this.usersApiAdapter.getAllUserIdsAsync, "UsersWithIdentifiers");
    @State.observable public usersWithIdentifiersOnlyWithPractitioners = new ReferenceDataStore(this.getUsersWithIdentifiersOnlyWithPractitionersAsync, this.usersApiAdapter.getAllUserIdsAsync, "UsersWithIdentifiersOnlyWithPractitioners");
    @State.observable public userGroups = new ReferenceDataStore(this.userGroupsApiAdapter.getUserGroupsByIdsAsync, this.userGroupsApiAdapter.getAllUserGroupId, "UserGroups");

    constructor(
        @Di.inject("UsersApiAdapter") private readonly usersApiAdapter: UsersApiAdapter,
        @Di.inject("OrganizationReferenceDataStore") private readonly organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("UserGroupsApiAdapter") private readonly userGroupsApiAdapter: UserGroupsApiAdapter,
        @Di.inject("IReferenceDataLoader") private readonly referenceDataLoader: IReferenceDataLoader
    ) { 
        referenceDataLoader.register<UserId>(UserId.typeName, ids => this.users.ensureLoadedAsync(ids));
    }

    @State.bound
    public async getUsersWithIdentifiersAsync(ids: UserId[], onlyWithPractitioners?: boolean) {
        this.organizationReferenceDataStore.practitionersWithUserIdMap.invalidate();
        await this.organizationReferenceDataStore.practitionersWithUserIdMap.ensureAllLoadedAsync();
        const users = await this.usersApiAdapter.getUsersByIdsAsync(ids);
        const list = users.value.map(user => {
            let identifierList: string[] = [];
            const matchedPractitioner = this.organizationReferenceDataStore.practitionersWithUserIdMap.items.find(x => x.userId && user.id && x.userId.value === user.id.value);
        
            if (onlyWithPractitioners && (matchedPractitioner === undefined || matchedPractitioner === null)) {
                return null;
            }
            
            if (matchedPractitioner !== undefined && matchedPractitioner !== null) {
                user.displayName = matchedPractitioner.displayName;
                identifierList = identifierList.concat(matchedPractitioner.identifiers.map(x => x.identifier.value));
            }

            const iconName = matchedPractitioner ? matchedPractitioner.iconName : "patient_unknown";

            const userWithIdentifiers = new UserWithIdentifiers(user.id, user, iconName, identifierList);
            return userWithIdentifiers;
        });

        return new SimpleStore<UserWithIdentifiers[]>(list.filter(x => x !== undefined && x !== null), users.operationInfo);
    }

    @State.bound
    public async getUsersWithIdentifiersOnlyWithPractitionersAsync(ids: UserId[]) {
        return await this.getUsersWithIdentifiersAsync(ids, true);
    }
}
