import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ISelectBoxGroup from "@CommonControls/SelectBox/ISelectBoxGroup";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import FormDataElementDescriptor from "@Toolkit/FormEngine/Model/FormDataElementDescriptor";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import IFormFieldReference from "./IFormFieldReference";
import IFormEngineApiAdapter from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import SelectBox from "@CommonControls/SelectBox/SelectBox";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import FormDefinitionDescriptor from "@Toolkit/FormEngine/Model/FormDefinitionDescriptor";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";

interface IFormFieldSelectorDependencies {
    formEngineApiAdapter: IFormEngineApiAdapter;
    cultureCodeProvider: ICurrentCultureProvider;
}

interface IFormFieldSelectorProps extends ISelectBoxBaseProps {
    _dependencies?: IFormFieldSelectorDependencies;
    value?: IFormFieldReference;
    onChange?: (value: IFormFieldReference) => void;
    onLoaded?: (descriptors: FormDefinitionDescriptor[]) => void;
    excludedFormDefinitionIds?: FormDefinitionId[];
}

interface IFormDataElementDescriptorItem {
    dataElement: FormDataElementDescriptor;
    formDefinitionName: string;
    getHashCode: () => string;
}

@State.observer
class FormFieldSelector extends React.Component<IFormFieldSelectorProps> {

    @State.observable.ref private selectBoxOptions: Array<ISelectBoxGroup<IFormDataElementDescriptorItem>> = [];

    @State.computed private get selectedFormDataElement(): IFormDataElementDescriptorItem {
        if (isNullOrUndefined(this.props.value)) {
            return null;
        }

        return this.selectBoxOptions?.flatMap(o => o.options).find(i =>
            i.value.formDefinitionName === this.props.value.formDefinitionName &&
            i.value.dataElement.formDataElementName === this.props.value.formDataElementName)?.value;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }

    private async initializeAsync() {
        const response = await this.props._dependencies.formEngineApiAdapter.getAllFormDefinitionDescriptorsAsync();
        this.setDescriptors(response.value);
    }

    @State.action.bound
    private setDescriptors(descriptors: FormDefinitionDescriptor[]) {
        this.selectBoxOptions = descriptors.filter(this.descriptorFilterPredicate).map(de => {
            return {
                label: de.formDefinitionDisplayName.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.props._dependencies.cultureCodeProvider.cultureCode),
                options: de.formDataElementDescriptors.filter(j => !j.isArray && !j.isLongString).map(c => ({
                    text: c.multiLingualLabel.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.props._dependencies.cultureCodeProvider.cultureCode),
                    value: {
                        dataElement: c,
                        formDefinitionName: de.formDefinitionName,
                        getHashCode: () => `${de.formDefinitionName}.${c.formDataElementName}`
                    }
                } as ISelectBoxItem<IFormDataElementDescriptorItem>))
            } as ISelectBoxGroup<IFormDataElementDescriptorItem>;
        });

        this.props.onLoaded?.(descriptors);
    }

    @State.bound
    private descriptorFilterPredicate(de: FormDefinitionDescriptor) {
        return this.props.excludedFormDefinitionIds === undefined || !this.props.excludedFormDefinitionIds.map(d => d.value).includes(de.formDefinitionId.value);
    }

    @State.action.bound
    private selectFormDataElement(dataElement: IFormDataElementDescriptorItem) {
        this.props.onChange({
            formDefinitionName: dataElement.formDefinitionName,
            formDataElementName: dataElement.dataElement.formDataElementName,
            formDataElementType: dataElement.dataElement.formDataElementType
        });
    }

    public render() {
        return (
            <SelectBox
                {...this.props}
                items={this.selectBoxOptions}
                value={this.selectedFormDataElement}
                onChange={this.selectFormDataElement}
            />
        );
    }
}

export default connect(
    FormFieldSelector,
    new DependencyAdapter<IFormFieldSelectorProps, IFormFieldSelectorDependencies>(c => ({
        formEngineApiAdapter: c.resolve<IFormEngineApiAdapter>("IFormEngineApiAdapter"),
        cultureCodeProvider: c.resolve<ICurrentCultureProvider>("ICurrentCultureProvider")
    }))
);