import moment from "moment";
import LocalDate from "./LocalDate";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

export default class TimeOfDay {

    public static startOfDay = new TimeOfDay(0, 0);
    public static endOfDay = new TimeOfDay(23, 59);

    public get valueInMinutes() {
        return this.hours * 60 + this.minutes;
    }

    constructor(
        public readonly hours: number,
        public readonly minutes: number
    ) {
        if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
            throw new Error(`Invalid TimeOfDay: ${hours}:${minutes}`);
        }
    }

    public static createFromMinutes(minutes: number) {
        return new TimeOfDay(Math.floor(minutes / 60), minutes % 60);
    }

    public static areEquals(a: TimeOfDay, b: TimeOfDay) {
        if (a === b) {
            return true;
        }

        if (!a && !b) {
            return true;
        }

        if (a && b) {
            return a.hours === b.hours && a.minutes === b.minutes;
        }

        return false;
    }

    public withHours(hours: number) {
        return new TimeOfDay(hours, this.minutes);
    }

    public withMinutes(minutes: number) {
        return new TimeOfDay(this.hours, minutes);
    }

    public greaterThan(other: TimeOfDay) {
        return this.valueInMinutes > other.valueInMinutes;
    }

    public greaterThanOrEquals(other: TimeOfDay) {
        return this.valueInMinutes >= other.valueInMinutes;
    }

    public lessThan(other: TimeOfDay) {
        return this.valueInMinutes < other.valueInMinutes;
    }

    public lessThanOrEquals(other: TimeOfDay) {
        return this.valueInMinutes <= other.valueInMinutes;
    }

    public static createFromTotalMinutes(totalMinutes: number) {
        return new TimeOfDay(Math.floor(totalMinutes / 60), totalMinutes % 60);
    }

    public static createFromMoment(momentValue: moment.Moment) {
        if (!momentValue) {
            return null;
        }
        
        return new TimeOfDay(momentValue.hours(), momentValue.minutes());
    }

    public static createFromString(str: string) {
        const split = str.split(":");
        if (split.length === 2 && !isNaN(parseInt(split[0], 10)) && !isNaN(parseInt(split[1], 10))) {
            return new TimeOfDay(parseInt(split[0], 10), parseInt(split[1], 10));
        }
        return null;
    }

    public toString() {
        return `${this.hours.toString()}:${this.minutes.toString().padStart(2, "0")}`;
    }

    public toMoment(day?: LocalDate) {
        const date = day ?? DateTimeService.today();
        return date.toLocalDayStartMoment().hours(this.hours).minutes(this.minutes);
    }

    public isEmpty(): boolean {
        return (this.hours === null || this.hours === undefined) && (this.minutes === null || this.minutes === undefined);
    }
}