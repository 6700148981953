exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ComplexSearchPanelView_tab-pane-container_2iSXv {\n  width: fit-content;\n}\n.ComplexSearchPanelView_tab-pane_22rfD {\n  width: 100px;\n  border-top: none;\n  border-top-right-radius: 0px;\n  border-top-left-radius: 0px;\n  border-bottom: none;\n  border-bottom-right-radius: 0px;\n  border-bottom-left-radius: 0px;\n}\n.ComplexSearchPanelView_search-text-container_1-Gre {\n  margin-top: 10px;\n  background: #82d4ed;\n  height: max-content;\n  width: 100%;\n  padding: 6px 10px;\n}\n.ComplexSearchPanelView_favorites-icon-container_26ms- {\n  width: 32px;\n  height: 30px;\n  padding-top: 6px;\n  padding-left: 5px;\n}\n.ComplexSearchPanelView_flex-container_3dnx7 {\n  margin-top: 10px;\n  border-top: 2px solid #e6e6eb;\n  border-bottom: 2px solid #e6e6eb;\n  background-color: #f4f7fc;\n  width: 100%;\n  display: flex;\n}\n.ComplexSearchPanelView_new-item-container_bqYiY {\n  width: 120px;\n}\n.ComplexSearchPanelView_groups-container_36zYm {\n  width: 100%;\n  overflow-x: auto;\n}\n.ComplexSearchPanelView_groups-container-with-new-item_1VTsO {\n  width: calc(100% - 120px);\n  overflow-x: auto;\n}\n.ComplexSearchPanelView_toolbar_2QbVQ {\n  flex-direction: column;\n}\n", ""]);

// exports
exports.locals = {
	"tab-pane-container": "ComplexSearchPanelView_tab-pane-container_2iSXv",
	"tabPaneContainer": "ComplexSearchPanelView_tab-pane-container_2iSXv",
	"tab-pane": "ComplexSearchPanelView_tab-pane_22rfD",
	"tabPane": "ComplexSearchPanelView_tab-pane_22rfD",
	"search-text-container": "ComplexSearchPanelView_search-text-container_1-Gre",
	"searchTextContainer": "ComplexSearchPanelView_search-text-container_1-Gre",
	"favorites-icon-container": "ComplexSearchPanelView_favorites-icon-container_26ms-",
	"favoritesIconContainer": "ComplexSearchPanelView_favorites-icon-container_26ms-",
	"flex-container": "ComplexSearchPanelView_flex-container_3dnx7",
	"flexContainer": "ComplexSearchPanelView_flex-container_3dnx7",
	"new-item-container": "ComplexSearchPanelView_new-item-container_bqYiY",
	"newItemContainer": "ComplexSearchPanelView_new-item-container_bqYiY",
	"groups-container": "ComplexSearchPanelView_groups-container_36zYm",
	"groupsContainer": "ComplexSearchPanelView_groups-container_36zYm",
	"groups-container-with-new-item": "ComplexSearchPanelView_groups-container-with-new-item_1VTsO",
	"groupsContainerWithNewItem": "ComplexSearchPanelView_groups-container-with-new-item_1VTsO",
	"toolbar": "ComplexSearchPanelView_toolbar_2QbVQ"
};