import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import PropertyGroup from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyGroup";
import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import {CreateRequestId} from "@HisPlatform/Common/RequestHelper";
import PropertyGroupStoreMapper from "./PropertyGroupStoreMapper";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import PropertyBase from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyBase";
import BoolProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/BoolProperty";
import NumericProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/NumericProperty";
import NumericRangeProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/NumericRangeProperty";
import StringProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/StringProperty";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import {mapPropertyVersions} from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/DynamicProperties/PropertyValueHelper";
import {mapValidationResults} from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import {createOperationInfo} from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";

@Di.injectable()
export default class DynamicPropertiesApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IDynamicPropertiesClient") private readonly apiClient: Proxy.IDynamicPropertiesClient,
        @Di.inject("PropertyGroupStoreMapper") private readonly propertyGroupStoreMapper: PropertyGroupStoreMapper
    ) {
        super();
    }

    public getEditablePropertiesOfOrganizationUnitAsync(organizationUnitId: OrganizationUnitId) {
        return this.processOperationAsync(
            new SimpleStore<PropertyGroup[]>(),
            async (target) => {
                const result = await this.apiClient.getPropertyGroupsForEditingQueryAsync(CreateRequestId(), organizationUnitId.value);
                this.propertyGroupStoreMapper.applyToStore(target, result);
            }
        );
    }

    public updatePropertyGroupsOfOrganizationUnitAsync(organizationUnitId: OrganizationUnitId, propertyGroups: PropertyGroup[]) {
        return this.processOperationAsync(
            new SimpleStore<PropertyGroup[]>(),
            async (target) => {
                const result = await this.apiClient.updatePropertyGroupsCommandAsync(CreateRequestId(),
                    new Proxy.UpdatePropertyGroupsControllerDto({
                        organizationUnitId: organizationUnitId,
                        propertyGroups: propertyGroups.map(x => new Proxy.UpdatePropertyGroupDto({
                            propertyGroupDefinitionId: x.definitionId,
                            properties: x.properties.filter(y => this.validItem(y) && y.versions?.length > 0).map(y => new Proxy.UpdatePropertyDto({
                                isOverridable: y.isOverridable,
                                propertyDefinitionId: y.definitionId,
                                valueVersions: y.versions.map(valueVersion => new Proxy.PropertyVersionDto({
                                    validFrom: valueVersion.validFrom,
                                    validTo: valueVersion.validTo,
                                    value: this.getValue(y, valueVersion.value)
                                })) ?? null,
                            }))
                        }))
                    })
                );
                this.propertyGroupStoreMapper.applyToStore(target, result);
            }
        );
    }

    public validatePropertyGroup(organizationUnitId: OrganizationUnitId, propertyGroup: PropertyGroup) {
        return this.processOperationAsync(new SimpleStore<IClientValidationResult[]>(), async (target) => {
            const result = await this.apiClient.updatePropertyGroupsCommandAsync(CreateRequestId(), new Proxy.UpdatePropertyGroupsControllerDto({
                isValidateOnly: true,
                organizationUnitId: organizationUnitId,
                propertyGroups: [new Proxy.UpdatePropertyGroupDto({
                    propertyGroupDefinitionId: propertyGroup.definitionId,
                    properties: propertyGroup.properties.map(y => new Proxy.UpdatePropertyDto({
                        isOverridable: y.isOverridable,
                        propertyDefinitionId: y.definitionId,
                        valueVersions: y.versions.map(valueVersion => new Proxy.PropertyVersionDto({
                            validFrom: valueVersion.validFrom,
                            validTo: valueVersion.validTo,
                            value: this.getValue(y, valueVersion.value)
                        })) ?? null,
                    }))
                })]
            }));
            target.value = mapValidationResults(result.compositeValidationResult as unknown as IServerCompositeValidationResult);
            target.operationInfo =  createOperationInfo(result);
        });
    }

    public validItem(property: PropertyBase) {
        return property.versions?.every(version => {
            if (property instanceof NumericRangeProperty) {
                return !isNullOrUndefined(version.value) && (!isNullOrUndefined(version.value.from) || !isNullOrUndefined(version.value.to));
            }
            return !isNullOrUndefined(version.value);
        }) ?? true;
    }

    public getValue(property: PropertyBase, value: any) {
        if (property instanceof BoolProperty) {
            return new Proxy.BoolPropertyValue({value: value});
        } else if (property instanceof NumericProperty) {
            return new Proxy.NumericPropertyValue({value: value});
        } else if (property instanceof NumericRangeProperty) {
            return new Proxy.NumericRangePropertyValue({
                value: new Proxy.NumericRange({
                    from: value.from,
                    to: value.to,
                    isEmpty: false
                })
            });
        } else if (property instanceof StringProperty) {
            return new Proxy.StringPropertyValue({value: value});
        }
        return null;
    }

    public async getPropertyValueByOrganizationUnitIdAsync(organizationUnitId: OrganizationUnitId, groupName: string, propertyName: string) {
        const response = await this.apiClient.getEffectivePropertyValueQueryAsync(CreateRequestId(), groupName, organizationUnitId.value, propertyName);
        const propDto = response.property;
        const valueForType = propDto.versions?.length > 0 ? propDto.versions[0].value : propDto.defaultValue;

        if (valueForType instanceof Proxy.StringPropertyValue) {
            return new StringProperty(
                [],
                mapPropertyVersions<string>(propDto.versions),
                propDto.isOverridable,
                propDto.isTemporal,
                propDto.name,
                null,
                organizationUnitId,
                null,
                propDto.defaultValue && (propDto.defaultValue as Proxy.StringPropertyValue).value,
                propDto.inputType
            );
        } else if (valueForType instanceof Proxy.BoolPropertyValue) {
            return new BoolProperty(
                [],
                mapPropertyVersions<boolean>(propDto.versions),
                propDto.isOverridable,
                propDto.isTemporal,
                propDto.name,
                null,
                organizationUnitId,
                null,
                propDto.defaultValue && (propDto.defaultValue as Proxy.BoolPropertyValue).value,
                propDto.inputType
            );
        }
        throw new Error(`Unknown PropertyType.`);
    }
}
