exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PrescriptionList_under-recording_1Ruaq {\n  background-color: #8695a7;\n}\n.PrescriptionList_waiting-for-delete_2PpGm {\n  background-color: #fe8443;\n}\n.PrescriptionList_deleted_2QN4e {\n  background-color: #ce5374;\n}\n.PrescriptionList_submitted_3NhJO {\n  background-color: #21c313;\n}\n.PrescriptionList_waiting-for-submission-cancellation_sXjn3 {\n  background-color: #B3B3B3;\n}\n", ""]);

// exports
exports.locals = {
	"under-recording": "PrescriptionList_under-recording_1Ruaq",
	"underRecording": "PrescriptionList_under-recording_1Ruaq",
	"waiting-for-delete": "PrescriptionList_waiting-for-delete_2PpGm",
	"waitingForDelete": "PrescriptionList_waiting-for-delete_2PpGm",
	"deleted": "PrescriptionList_deleted_2QN4e",
	"submitted": "PrescriptionList_submitted_3NhJO",
	"waiting-for-submission-cancellation": "PrescriptionList_waiting-for-submission-cancellation_sXjn3",
	"waitingForSubmissionCancellation": "PrescriptionList_waiting-for-submission-cancellation_sXjn3"
};