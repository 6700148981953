import React from "react";
import { INDataBatchActionPanelProps } from "@HisPlatform/BoundedContexts/Productivity/Components/NDataGrid/INDataBatchActionPanelProps";
import * as Ui from "@CommonControls";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IPropertyListItem from "@CommonControls/PropertyList/IPropertyListItem";
import LocalizedDateTime from "@CommonControls/Localized/LocalizedDateTime";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import { formatReactString } from "@Toolkit/ReactClient/Common/LocalizedStrings";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface IDefaultNDataBatchActionInProgressPanelDependencies {
    dialogService: IDialogService;
}

interface IDefaultNDataBatchActionInProgressPanelProps extends INDataBatchActionPanelProps {
    _dependencies?: IDefaultNDataBatchActionInProgressPanelDependencies;

    title: string;

    errorDetailTitle?: string;
    errorDetailTableNameColumnHeader?: string;
    errorDisplayValueMap?: Map<string, string>;
}

@State.observer
class DefaultNDataBatchActionInProgressPanel extends React.Component<IDefaultNDataBatchActionInProgressPanelProps> {

    private get dialogService() { return this.props._dependencies!.dialogService; }

    @State.computed private get completenessPercent() {
        if (!this.props.runningTask) {
            return 0;
        }

        const processed = this.props.runningTask.numberOfCompletedActions + this.props.runningTask.numberOfFailedActions;
        return Math.round((processed / this.props.runningTask.totalActions) * 100);
    }

    @State.computed private get taskProperties(): IPropertyListItem[] {
        if (!this.props.runningTask) {
            return [];
        }

        const processed = this.props.runningTask.numberOfCompletedActions + this.props.runningTask.numberOfFailedActions;
        const inProgress = this.props.runningTask.totalActions - processed;
        const summaryFormatParameters = {
            totalActions: this.props.runningTask.totalActions,
            numberOfProcessedActions: processed,
            numberOfInProgressActions: inProgress,
            numberOfCompletedActions: this.props.runningTask.numberOfCompletedActions,
            numberOfFailedActions: this.props.runningTask.numberOfFailedActions
        };

        if (this.props.runningTask.isCompleted) {
            return [
                { name: StaticProductivityResources.Worklist.BatchAction.StartOfProcessingLabel, value: <LocalizedDateTime value={this.props.runningTask.createdAt} /> },
                {
                    name: StaticProductivityResources.Worklist.BatchAction.ResultLabel,
                    value: formatReactString(StaticProductivityResources.Worklist.BatchAction.CompletedStatusSummary, summaryFormatParameters)
                }
            ];
        } else {
            return [
                { name: StaticProductivityResources.Worklist.BatchAction.StartOfProcessingLabel, value: <LocalizedDateTime value={this.props.runningTask.createdAt} /> },
                {
                    name: StaticProductivityResources.Worklist.BatchAction.StatusLabel,
                    value: formatReactString(StaticProductivityResources.Worklist.BatchAction.InProgressStatusSummary, summaryFormatParameters)
                }
            ];
        }
    }

    @State.computed private get messageType() {
        if (this.props.runningTask.isCompleted) {
            return this.props.runningTask.numberOfFailedActions > 0 ? "danger" : "success";
        }

        return "info";
    }

    public render() {

        return (
            <Ui.Flex xsJustify="center">
                <Ui.Flex.Item md={8} xs={12}>
                    <Ui.Message type={this.messageType} style={{ margin: 4 }}>
                        <div style={{ marginBottom: 4 }}>
                            <SpanWithIcon iconName="infoCircle"><b>{this.props.title}</b></SpanWithIcon>
                        </div>
                        {!this.props.runningTask.isCompleted && <Ui.ProgressBar value={this.completenessPercent} />}
                        <Ui.Flex>
                            <Ui.Flex.Item xs={8}>
                                <Ui.PropertyList
                                    properties={this.taskProperties}
                                    hasColonAfterNames
                                    boldValues
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4} horizontalContentAlignment="right">
                                {this.props.runningTask.isCompleted && (
                                    <>
                                        {this.props.runningTask.numberOfFailedActions > 0 && (
                                            <Ui.Button
                                                text={StaticProductivityResources.Worklist.BatchAction.ErrorDetailButtonLabel}
                                                iconName="info"
                                                onClick={this.showDetailsModal}
                                                automationId="showDetailsModalButton"
                                            />
                                        )}
                                        <Ui.Button
                                            text={StaticProductivityResources.Worklist.BatchAction.ClearButtonLabel}
                                            iconName="check"
                                            onClickAsync={this.props.onClearCompletedTaskAsync}
                                            automationId="clearCompletedTaskButton"
                                        />
                                    </>
                                )}
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Message>
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.bound
    private showDetailsModal() {
        this.dialogService.ok(this.props.errorDetailTitle ?? StaticProductivityResources.Worklist.BatchAction.ErrorDetailPanelDefaultTitle, (
            <Ui.Table automationId="nDataBatchAction_table">
                <thead>
                    <tr>
                        <th>{this.props.errorDetailTableNameColumnHeader ?? StaticProductivityResources.Worklist.BatchAction.ErrorDetailPanelDefaultItemHeader}</th>
                        <th>{StaticProductivityResources.Worklist.BatchAction.ErrorDetailPanelErrorHeader}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.runningTask.results.filter(r => !r.isSucceeded).map(r => {
                        const errorDisplayValue = this.props.errorDisplayValueMap?.get(r.errorName) ?? StaticProductivityResources.Worklist.BatchAction.UnknownError;
                        return (
                            <tr key={r.rowId}>
                                <td>{r.displayName}</td>
                                <td>{errorDisplayValue}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Ui.Table>
        ));
    }

}

export default connect(
    DefaultNDataBatchActionInProgressPanel,
    new DependencyAdapter<IDefaultNDataBatchActionInProgressPanelProps, IDefaultNDataBatchActionInProgressPanelDependencies>(c => ({
        dialogService: c.resolve("IDialogService"),
    }))
);