exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ColorSelector_color-table_pk3K- {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n.ColorSelector_item_3kXH3 {\n  display: block;\n  margin: 0.3rem;\n  padding: 1px;\n  border: 1px solid transparent;\n  cursor: pointer;\n}\n.ColorSelector_item_3kXH3 .ColorSelector_item-color_EWwOf {\n  width: 1.5rem;\n  height: 1.5rem;\n}\n.ColorSelector_item_3kXH3:not(.ColorSelector_selected_3I01b):hover {\n  border-color: #888;\n}\n.ColorSelector_item_3kXH3.ColorSelector_active_3tnUE {\n  border-color: black;\n}\n", ""]);

// exports
exports.locals = {
	"color-table": "ColorSelector_color-table_pk3K-",
	"colorTable": "ColorSelector_color-table_pk3K-",
	"item": "ColorSelector_item_3kXH3",
	"item-color": "ColorSelector_item-color_EWwOf",
	"itemColor": "ColorSelector_item-color_EWwOf",
	"selected": "ColorSelector_selected_3I01b",
	"active": "ColorSelector_active_3tnUE"
};