import Command from "./Command";
import ICommandProcessor from "./ICommandProcessor";

/**
 * Abstract base class for an {@link ICommandProcessor}.
 */
export default abstract class CommandProcessorBase<TCommand extends Command> implements ICommandProcessor<TCommand> {
    /** {@inheritdoc} */
    public abstract get commandType(): string;

    /** {@inheritdoc} */
    public processGenericAsync(command: Command): Promise<void> {
        if (command.commandType !== this.commandType) {
            throw new Error(`Expected a command of type '${"TCommand"}', but got '${command.commandType}'.`);
        }

        return this.processAsync(command as TCommand);
    }

    /** {@inheritdoc} */
    public abstract processAsync(command: TCommand): Promise<void>;
}