import React from "react";
import * as HisUi from "@HisPlatformControls";
import * as Ui from "@CommonControls";
import AppointmentCancellationReasonId from "@Primitives/AppointmentCancellationReasonId.g";
import AppointmentCancellationSelectBox from "./AppointmentCancellationSelectBox";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IAppointmentCancellationDialogViewProps {
    additionalText: string;
    additionalTextRequired: boolean;
    setAdditionalText: (newValue: string) => void;
    save: () => void;
    onClose: () => void;
    appointmentCancellationReasonId: AppointmentCancellationReasonId;
    setAppointmentCancellationReasonId: (newValue: AppointmentCancellationReasonId) => void;
    validationResults: IClientValidationResult[];
    onValidateAsync: () => Promise<IClientValidationResult[]>;
}

@State.observer
export default class AppointmentCancellationDialogView extends React.Component<IAppointmentCancellationDialogViewProps> {
    private readonly panelResources = StaticSchedulingResources;
    public render() {
        return ( 
            <ValidationBoundary
            entityTypeName={"Appointment"}
            validateOnMount
            validationResults={this.props.validationResults}
            onValidateAsync={this.props.onValidateAsync}
            >
            <HisUi.ContextAwareModal
                title={this.panelResources.RegisteredPatientAppointmentsMasterDetailPanel.CancelAppointment}
                isOpen
                onClose={this.props.onClose}
                size="compact"
                closeOnOutsideClick={false}
                closeOnEscape={false}
                iconName="trash">
                <Ui.Modal.Body>
                    <div style={{marginBottom: 8}}>
                        <Ui.Flex>
                            <Ui.Flex.Item sm={10} smOffset={1}>
                                <AppointmentCancellationSelectBox
                                    label={this.panelResources.CancelAppointmentDialog.CancellationReason}
                                    value={this.props.appointmentCancellationReasonId}
                                    onChange={this.props.setAppointmentCancellationReasonId}
                                    propertyIdentifier="AppointmentCancellationReasonId"
                                    automationId="appointmentCancellationReasonSelectBox"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <Ui.Flex>
                            <Ui.Flex.Item sm={10} smOffset={1}>
                                <Ui.TextBox
                                    label={this.panelResources.CancelAppointmentDialog.Note}
                                    value={this.props.additionalText}
                                    onChange={this.props.setAdditionalText}
                                    propertyIdentifier="Note"
                                    automationId="noteTextBox"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </div>
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Flex xsJustify="end">
                        <Ui.Flex.Item>
                            <Ui.SaveButton
                                disabled={!this.props.appointmentCancellationReasonId || (this.props.additionalTextRequired && !this.props.additionalText)}
                                visualStyle="primary"
                                onClick={this.props.save}
                                automationId="saveButton"
                            />
                            <Ui.Button
                                text="Mégsem"
                                onClick={this.props.onClose}
                                automationId="cancelButton"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.Modal.Footer>
            </HisUi.ContextAwareModal>
            </ValidationBoundary>
            );
    }
}
