import ConditionId from "@Primitives/ConditionId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FamilyDoctorDocumentSingleMedication from "./FamilyDoctorDocumentSingleMedication";
import FamilyDoctorDocumentMedicationFormula from "./FamilyDoctorDocumentMedicationFormula";
import FamilyDoctorDocumentMedicationFormulaItem from "./FamilyDoctorDocumentMediactionFormulaItem";

export default class FamilyDoctorDocumentMedicationReference {
    @State.observable.ref public conditionId: ConditionId;
    @State.observable.ref public dailyDosage: string = "";
    @State.observable.ref public medicationDosageForm: string = "";
    @State.observable.ref public notes: string = "";
    @State.observable.ref public isMagistral: boolean = false;

    @State.observable.ref public detail: FamilyDoctorDocumentSingleMedication | FamilyDoctorDocumentMedicationFormula
        = new FamilyDoctorDocumentSingleMedication();

    @State.action.bound
    public setConditionId(conditionId: ConditionId) {
        this.conditionId = conditionId;
    }

    @State.action.bound
    public setDailyDosage(dailyDosage: string) {
        this.dailyDosage = dailyDosage;
    }

    @State.action.bound
    public setMedicationDosageForm(medicationDosageForm: string) {
        this.medicationDosageForm = medicationDosageForm;
    }
    @State.action.bound
    public setNotes(notes: string) {
        this.notes = notes;
    }
    @State.action.bound
    public setIsMagistral(isMagistral: boolean) {
        this.isMagistral = isMagistral;

        if (isMagistral) {
            if (!(this.detail instanceof FamilyDoctorDocumentMedicationFormula)) {
                this.detail = new FamilyDoctorDocumentMedicationFormula();
                this.detail.items.push(new FamilyDoctorDocumentMedicationFormulaItem());
            }
        } else {
            if (!(this.detail instanceof FamilyDoctorDocumentSingleMedication)) {
                this.detail = new FamilyDoctorDocumentSingleMedication();
            }
        }
    }
}