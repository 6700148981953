import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import { IMultiEntitySelectorPublicProps } from "@CommonControls/MultiEntitySelectorBase";
import UserGroupId from "@Primitives/UserGroupId.g";
import UserGroup from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroup";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";

interface IUserGroupMultiSelectorDependencies {
    userManagementDataProvider: UserManagementDataProviderStore;
}

interface IUserGroupMultiSelectorProps extends IMultiEntitySelectorPublicProps<UserGroupId, UserGroup> {
    _dependencies?: IUserGroupMultiSelectorDependencies;
    fixedHeight?: string | number;
    onChange?: (allIds: UserGroupId[]) => void;
    selectedIds: UserGroupId[];
}

@State.observer
class UserGroupMultiSelector extends React.Component<IUserGroupMultiSelectorProps> {

    @State.observable.ref private allUserGroupsDataSource = new InMemoryDataGridDataSource(() => this.allUserGroups);
    @State.observable.ref private selectedUserGroupsDataSource = new InMemoryDataGridDataSource(() => this.selectedUserGroups);

    @State.observable.ref private allUserGroups: UserGroup[] = [];
    @State.observable.ref private selectedUserGroups: UserGroup[] = [];

    @State.action.bound
    private setDataSource(usergroups: UserGroup[]) {
        this.allUserGroups = usergroups;
    }

    @State.action.bound
    private setSelectedDataSource(usergroups: UserGroup[]) {
        this.selectedUserGroups = usergroups;
    }

    private get dependencies() {
        return this.props._dependencies;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.reloadAsync(), this);
    }

    public componentDidUpdate() {
        dispatchAsyncErrors(this.reloadAsync(), this);
    }

    @State.action.bound
    private addNewItem(item: UserGroup) {
        const ids = [...this.selectedUserGroups, item].map(i => i.id);
        this.props.onChange(ids);
    }

    @State.action.bound
    private removeItem(item: UserGroup) {
        const ids = [...this.selectedUserGroups].filter(i => i !== item).map(i => i.id);
        this.props.onChange(ids);
    }

    public render() {
        return (
            <Ui.MultiEntitySelectorBase
                {...this.props}
                renderColumns={this.renderAllItemsColumn}
                renderSelectedColumns={this.renderChosenItemsColumn}
                allItemsDataSource={this.allUserGroupsDataSource}
                selectedItemsDataSource={this.selectedUserGroupsDataSource}
                onAdded={this.addNewItem}
                onRemoved={this.removeItem}
                automationId="__multiEntitySelector"
                generateInitialFilterStore
                changeOnMount
                fixedHeight={this.props.fixedHeight}
            />
        );
    }

    @State.bound
    private async reloadAsync() {
        const selectedUserGroupIds = this.props.selectedIds || [];
        await this.dependencies.userManagementDataProvider.userGroups.ensureAllLoadedAsync();
        const userGroups = this.dependencies.userManagementDataProvider.userGroups.items;
        const selectedUserGroups = userGroups.filter(i => selectedUserGroupIds.some(j => ValueWrapper.equals(i.id, j)));

        this.setDataSource(userGroups);
        this.setSelectedDataSource(selectedUserGroups);
    }

    @State.action.bound
    private renderAllItemsColumn() {
        return this.renderColumns(StaticWebAppResources.Common.Label.All ? StaticWebAppResources.Common.Label.All : "");
    }

    @State.action.bound
    private renderChosenItemsColumn() {
        return this.renderColumns(StaticWebAppResources.Common.Label.SelectedValue ? StaticWebAppResources.Common.Label.SelectedValue : "");
    }

    private renderCellValue(value: any, row: UserGroup) {
        return <><b>{row.shortName}</b>{` - ${row.name}`}</>;
    }

    @State.bound
    private getFilteredValue(value: string) {
        const user = this.allUserGroups.find(u => {
            return u.displayName === value;
        });
        return user.displayName;
    }

    private renderColumns(header: string) {
        return (
            <DataGridColumn
                dataGetter={"displayName"}
                clientSideOrderableValueGetter={"displayName"}
                clientSideFilterableValueGetter={this.getFilteredValue}
                onRenderCellValue={this.renderCellValue}
                header={header}
                automationId="Name"
                isOrderable
                isFilterable />
        );
    }
}

export default connect(
    UserGroupMultiSelector,
    new DependencyAdapter<IUserGroupMultiSelectorProps, IUserGroupMultiSelectorDependencies>(container => {
        return {
            userManagementDataProvider: container.resolve("UserManagementDataProviderStore")
        };
    })
);
