import React, { useCallback } from "react";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import DateRangePicker from "@CommonControls/DateRangePicker/DateRangePicker";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface IDataGridDateColumnDependencies {
    toolkitLocalizationService: IToolkitLocalizationService;
}

type IDataGridDateColumnProps = IDataGridColumnBaseProps & { _dependencies?: IDataGridDateColumnDependencies; };


const DataGridDateColumn: React.FC<IDataGridDateColumnProps> = props => {

    const valueRenderer = useCallback((value: LocalDate) => {
        const localizer = props._dependencies.toolkitLocalizationService;
        return isNullOrUndefined(value) ? null : localizer.localizeDate(value);
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<LocalDateRange>) => {
        return <DateRangePicker {...filterProps} hoverOnlyIndicatorIcons />;
    }, []);

    const { children, ...columnProps } = props;

    const isDateInFilterRange = (filterValue: LocalDateRange, value: LocalDate) => {
        return (isNullOrUndefined(filterValue?.from) || isNullOrUndefined(filterValue?.to))
            || (!isNullOrUndefined(value) && value.greaterThanOrEquals(filterValue?.from) && value.lessThanOrEquals(filterValue?.to));
    };

    const getOrderableValue = (value: LocalDate) => {
        return value?.toUnix();
    };

    return (
        <DataGridColumn
            width={95}
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
            clientSideOrderableValueGetter={getOrderableValue}
            clientSideFilterPredicate={isDateInFilterRange}
        />
    );
};

const filterValueSerializer: IDataGridFilterValueSerializer<LocalDateRange> = {
    serialize: value => value.stringify(),
    deserialize: value => LocalDateRange.parse(value)
};

export default connect(
    DataGridDateColumn,
    new DependencyAdapter<IDataGridDateColumnProps, IDataGridDateColumnDependencies>(c => ({
        toolkitLocalizationService: c.resolve("IToolkitLocalizationService"),
    }))
);