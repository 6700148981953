import ITransitionStyle from "@CommonControls/SearchBox/ITransitionStyle";
import { iconVisualStyleType, iconNameType } from "@CommonControls/Icon";
import ControlledSearchBox from "@CommonControls/SearchBox/ControlledSearchBox";
import UncontrolledSearchBox from "@CommonControls/SearchBox/UncontrolledSearchBox";

interface ISearchBoxProps {
    focusOnRender?: boolean;
    placeholder?: string;
    visualMode?: "dark" | "normal";
    iconVisualStyle?: iconVisualStyleType;
    iconName?: iconNameType;
    size?: "compact" | "standard";
    automationId?: string;
    style?: React.CSSProperties;
    stopClickPropagation?: boolean;
    isRequired?: boolean;
}

export interface IUncontrolledSearchBoxProps extends ISearchBoxProps {
    onSearch: (value: string) => void;
}

export interface IControlledSearchBoxProps extends ISearchBoxProps {
    value: string;
    onIconClick?: () => void;
    onChange: (value: string) => void;
    onEnterKeyDown?: () => void;
}

export default class SearchBox {
    
    public static Controlled = ControlledSearchBox;
    
    public static Uncontrolled = UncontrolledSearchBox;
}