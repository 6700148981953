import CareActivityId from "@Primitives/CareActivityId.g";
import BusinessErrorBase from "@HisPlatform/Model/DomainModel/BusinessError/BusinessErrorBase";

export default class CantCloseCareActivityError extends BusinessErrorBase {
    constructor(public careActivityId: CareActivityId, public validationResult: any) {
        super();
    }

    public get name(): string {
        return "CantCloseCareActivityError";
    }
}