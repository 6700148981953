import React from "react";
import OrganizationUnitTreeNode from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnitTreeNode";
import * as Ui from "@CommonControls";
import { ITableFrameColumn } from "@CommonControls/TreeGrid/TableFrame";
import { IObservableValue } from "@Toolkit/ReactClient/Common/StateManaging";
import { OrganizationUnitName } from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/TreeColumnRenderers";
import PractitionerView from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/ValueRenderers/PractitionerView";
import AddressView from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/ValueRenderers/AddressView";
import ActionsView from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/ValueRenderers/ActionsView";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";

interface IOrganizationUnitTreeGridProps {
    organizationStructure: OrganizationUnitTreeNode[];
    selectedNode: OrganizationUnitTreeNode;
    onNodeOpenStateChange: (node: OrganizationUnitTreeNode, isOpen: boolean) => void;
    debouncedFilterValueBox: IObservableValue<string>;
    isDetailOpen: boolean;
    addingNew: boolean;

    onEdit: (node: OrganizationUnitTreeNode) => void;
    onDeleteAsync: (node: OrganizationUnitTreeNode) => Promise<void>;
    onAddChildAsync: (node: OrganizationUnitTreeNode) => Promise<void>;
}

/** @screen */

const OrganizationUnitTreeGrid: React.FC<IOrganizationUnitTreeGridProps> = React.memo(props => {

    const columns: ITableFrameColumn[] = [
        {
            title: StaticOrganizationResources.OrganizationUnitPanel.Headers.Name,
            width: props.isDetailOpen ? 100 : 50,
            widthMode: "percent",
            displayValueGetter: (r: OrganizationUnitTreeNode) => (
                <OrganizationUnitName
                    node={r}
                    filterValueBox={props.debouncedFilterValueBox}
                />
            )
        }
    ];

    if (!props.isDetailOpen) {
        columns.push(...[
            {
                title: StaticOrganizationResources.OrganizationUnitPanel.Headers.Address,
                width: 25,
                widthMode: "percent",
                displayValueGetter: (r: OrganizationUnitTreeNode) => r.address && (
                    <AddressView address={r.address} />
                )
            },
            {
                title: StaticOrganizationResources.OrganizationUnitPanel.Headers.Manager,
                width: 25,
                widthMode: "percent",
                displayValueGetter: (r: OrganizationUnitTreeNode) => (
                    <PractitionerView practitionerId={r.managerId} />
                )
            },
        ] as ITableFrameColumn[]);
    }

    columns.push({
        title: "",
        width: 60,
        widthMode: "pixel",
        displayValueGetter: (r: OrganizationUnitTreeNode) => (
            <ActionsView
                node={r}
                onAddChildAsync={props.onAddChildAsync}
                onDeleteAsync={props.onDeleteAsync}
                addingNew={props.addingNew}
            />
        )
    });

    return (
        <Ui.TreeGrid
            columns={columns}
            data={props.organizationStructure}
            fixedHeight="calc(100vh - 175px)"
            selectedNode={props.selectedNode}
            onSelectNode={props.onEdit}
            isSelectionControlled
        />
    );
});

export default OrganizationUnitTreeGrid;