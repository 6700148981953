import IOutpatientTreatmentRouteParams from "@HisPlatform/Application/Routes/IOutpatientTreatmentRouteParams";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import IPatientRouteParams from "@HisPlatform/Application/Routes/IPatientRouteParams";
import IWorklistRouteParams from "@HisPlatform/Application/Routes/IWorklistRouteParams";
import { IProfileManagementRouteParams } from "./IProfileManagementRouteParams";
import IOrganizationEditorRouteParams from "@HisPlatform/Application/Routes/IOrganizationEditorRouteParams";
import IUserManagementRouteParams from "@HisPlatform/Application/Routes/IUserManagementRouteParams";
import IReportingRouteParams from "@HisPlatform/Application/Routes/IReportingRouteParams";
import IAppointmentScheduleDefinitionsRouteParams from "@HisPlatform/Components/Pages/AppointmentScheduleDefinitionPages/IAppointmentScheduleDefinitionsRouteParams";
import IFormDefinitionRouteParams from "./IFormDefinitionRouteParams";
import IWorklistDefinitionRouteParams from "./IWorklistDefinitionRouteParams";
import IExternalLocationsRouteParams from "@HisPlatform/Application/Routes/IExternalLocationsRouteParams";
import ISurgeryTypeDefinitionRouteParams from "./ISurgeryTypeDefinitionRouteParams";
import ISurgeryRouteParams from "./ISurgeryRouteParams";
import IScreenRouteParams from "./IScreenRouteParams";


const ApplicationRoutes = {
    login: new RouteDefinition("/login/:redirectTo?"),
    loggedOut: new RouteDefinition("/logoutCallback"),
    loginCallback: new RouteDefinition("/loginCallback"),
    logout: new RouteDefinition("/logout"),
    home: new RouteDefinition("/home"),
    developer: new RouteDefinition("/developer"),
    searchPatient: new RouteDefinition("/searchPatient"),
    patient: new RouteDefinition<IPatientRouteParams>("/patient/:patientId/:mode/:appointmentId?"),
    careActivity: new RouteDefinition<IOutpatientTreatmentRouteParams>("/careActivity/:careActivityId"),
    appointments: new RouteDefinition("/appointments"),
    insurerOrgs: new RouteDefinition("/insurerOrganizations"),
    configurationEditor: new RouteDefinition("/configurationEditor"),
    organizationEditor: new RouteDefinition<IOrganizationEditorRouteParams>("/propertyGroupEditor/:organizationUnitId?/:detail?"),
    userManagement: new RouteDefinition<IUserManagementRouteParams>("/userManagement/:type?/:id?/:tab?"),
    userGroupManagement: new RouteDefinition("/userGroupManagement/:userGroupId?/:tab?"),
    dynamicWorklist: new RouteDefinition<IWorklistRouteParams>("/dynamicWorklist/:worklistDefinitionId/:selectedRowId?/:useCase?"),
    profileManagement: new RouteDefinition<IProfileManagementRouteParams>("/profileManagement/:userId"),
    reporting: new RouteDefinition<IReportingRouteParams>("/reporting/:reportDefinitionId"),
    roleManagement: new RouteDefinition("/roleManagement/:roleId?"),
    documentTemplate: new RouteDefinition<{templateId?: string}>("/documentTemplate/:templateId?"),
    appointmentScheduleDefinitions: new RouteDefinition<IAppointmentScheduleDefinitionsRouteParams>("/appointmentScheduleDefinitions/:useCase?/"),
    formDefinitions: new RouteDefinition<IFormDefinitionRouteParams>("/formDefinitions/:selectedId?"),
    worklistDefinitions: new RouteDefinition<IWorklistDefinitionRouteParams>("/worklistDefinitions/:selectedId?"),
    surgeryTypeDefinitions: new RouteDefinition<ISurgeryTypeDefinitionRouteParams>("/surgeryTypeDefinitions/:selectedId?/:useCase?"),
    surgery: new RouteDefinition<ISurgeryRouteParams>("/surgery/:selectedId?/:useCase?"),
    externalLocations: new RouteDefinition<IExternalLocationsRouteParams>("/externalLocations/:useCase?"),

    screen: new RouteDefinition<IScreenRouteParams>("/screen/:screen1/:screen2?/:screen3?/:screen4?"),
};

export default ApplicationRoutes;
