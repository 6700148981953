import { useErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import { useEffect, useState } from "react";

export interface IAsyncMemoResult<T> {
    isLoading: boolean;
    value: T;
}

export function useAsyncMemo<T>(initialValue: T, valueFactoryAsync: () => Promise<T>, dependencyList?: any[]): IAsyncMemoResult<T> {
    const [value, setValue] = useState<T>(initialValue);
    const [isLoading, setLoadingState] = useState<boolean>(false);
    const dispatchError = useErrorDispatcher();

    useEffect(() => {

        setLoadingState(true);
        valueFactoryAsync()
            .then((res) => {
                setValue(res);
            })
            .finally(() => {
                setLoadingState(false);
            })
            .catch(dispatchError);

    }, dependencyList);

    return { value, isLoading };
}