// tslint:disable:variable-name
import ImmunizationScreenStore from "./ImmunizationScreenStore";
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import { editorScreenRenderFunc } from "@Toolkit/CommonWeb/PanelStore/EditorScreenRenderFunc";

const {
    ContextComponent: ImmunizationScreenStoreContext, 
    StoreProvider: ImmunizationScreenStoreProvider, 
    useStore: useImmunizationScreenStore,
    provideStore: provideImmunizationScreenStore,
} = createPanelStoreProvider<ImmunizationScreenStore>(ImmunizationScreenStore, null, editorScreenRenderFunc);

export {
    ImmunizationScreenStoreContext,
    ImmunizationScreenStoreProvider,
    useImmunizationScreenStore,
    provideImmunizationScreenStore
};