import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import Di from "@Di";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import DateTimeFormFieldData from "@Toolkit/FormEngine/Model/Data/DateTimeFormFieldData";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import ReferencedExtensibleEnumFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedExtensibleEnumFormFieldData";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import { getField, setExplicitIdentifierFilter } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";
import ShowCareActivityBaseDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityBaseDataScreenAction.g";
import DateFormFieldData from "@Toolkit/FormEngine/Model/Data/DateFormFieldData";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import PractitionerType from "@HisPlatform/BoundedContexts/Organization/Api/Practitioners/Enum/PractitionerType.g";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";

@Di.injectable()
class AdmitPatientAndCareActivityBaseDataScreenFormOutpatientBaseFormLogic implements IFormLogic {
    constructor(
        @Di.inject("PractitionerApiAdapter") private readonly practitionerApiAdapter: PractitionerApiAdapter
    ) {
    }

    public async onFormLoadedAsync(
        form: IForm,
        showScreenAction: ShowScreenFrontendActionBase,
        dataElements: FormDataElementBase[]
    ): Promise<void> {
        if (!(showScreenAction instanceof ShowNewAdmitPatientScreenAction)) {
            await this.executeCommonLogicAsync(form, showScreenAction);
            return;
        }

        const pointOfCareIdField = getField<ReferencedEntityFormFieldData>(form.data.Content, "PointOfCareId");
        if (pointOfCareIdField && !!pointOfCareIdField.value) {
            await this.trySelectLeadPractitionertAsync(form, new PointOfCareId(`${pointOfCareIdField.value}`), PractitionerType.Assistant);
            await this.trySelectLeadPractitionertAsync(form, new PointOfCareId(`${pointOfCareIdField.value}`), PractitionerType.Doctor);
        }

        await this.executeCommonLogicAsync(form, showScreenAction);
    }

    public executeAsync(form: IForm, showScreenAction?: ShowScreenFrontendActionBase): Promise<void> {
        return this.executeCommonLogicAsync(form, showScreenAction);
    }

    public async onFieldChangedAsync(
        form: IForm,
        showScreenAction: ShowScreenFrontendActionBase,
        dataElements: FormDataElementBase[],
        propertyIdentifier: string,
        newValue: any
    ): Promise<void> {
        switch (propertyIdentifier) {
            case "LeadAssistant":
                const careAssistantField = getField<ReferencedEntityFormFieldData>(form.data.Content, "CareAssistant");
                if (careAssistantField && !careAssistantField.value) {
                    State.runInAction(() => careAssistantField.value = newValue);
                }
                break;
            case "PointOfCareId":
                if (!newValue) {
                    break;
                }

                this.resetAssistants(form);
                this.resetDoctors(form);
                await this.trySelectLeadPractitionertAsync(form, new PointOfCareId(`${newValue}`), PractitionerType.Assistant);
                await this.trySelectLeadPractitionertAsync(form, new PointOfCareId(`${newValue}`), PractitionerType.Doctor);
                break;
        }

        await this.executeCommonLogicAsync(form, showScreenAction);
    }

    private executeCommonLogicAsync(form: IForm, showScreenAction?: ShowScreenFrontendActionBase) {
        State.runInAction(() => {
            const formData = form.data.Content;
            getField<TextFormFieldData>(formData, "CareActivityIdentifier").isReadOnly = true;
            getField<DateTimeFormFieldData>(formData, "PlannedStartOfCare").isReadOnly = true;

            const pointOfCareIdField = getField<ReferencedEntityFormFieldData>(formData, "PointOfCareId");

            if (!isNullOrUndefined(showScreenAction) && !(showScreenAction instanceof ShowCareActivityBaseDataScreenAction)) {
                getField(formData, "WentUnderCareAt").isReadOnly = true;
            }

            if (!isNullOrUndefined(showScreenAction) && !(showScreenAction instanceof ShowNewAdmitPatientScreenAction)) {
                pointOfCareIdField.isReadOnly = true;
            }
            else
            {
                pointOfCareIdField.isReadOnly = false;
            }

            const referralDiagnosis1Field = getField<ReferencedEntityFormFieldData>(formData, "Referral.ReferralDiagnosisId1");
            const referralDiagnosis2Field = getField<ReferencedEntityFormFieldData>(formData, "Referral.ReferralDiagnosisId2");

            if (referralDiagnosis1Field?.value === null) {
                referralDiagnosis2Field.value = null;
                referralDiagnosis2Field.isReadOnly = true;
            } else {
                referralDiagnosis2Field.isReadOnly = false;
            }

            const referralCreationDataField = getField<DateFormFieldData>(formData, "Referral.ReferralCreationDate");
            const referralWorkplaceField = getField<ReferencedEntityFormFieldData>(formData, "Referral.ReferralWorkplaceId");

            if (referralCreationDataField?.value === null) {
                referralWorkplaceField.value = null;
                referralWorkplaceField.isReadOnly = true;
            } else {
                referralWorkplaceField.isReadOnly = false;
            }

            const careDoctorField = getField<ReferencedEntityFormFieldData>(formData, "CareDoctor");
            const leadDoctorField = getField<ReferencedEntityFormFieldData>(formData, "LeadDoctor");
            const careAssistantField = getField<ReferencedEntityFormFieldData>(formData, "CareAssistant");
            const leadAssistantField = getField<ReferencedEntityFormFieldData>(formData, "LeadAssistant");
            if (careDoctorField.value === null && leadDoctorField.value !== null) {
                careDoctorField.value = leadDoctorField.value;
            }

            if(!isNullOrUndefined(pointOfCareIdField.value)) {
                setExplicitIdentifierFilter(careDoctorField, "PointOfCare", pointOfCareIdField.value.toString());
                setExplicitIdentifierFilter(leadDoctorField, "PointOfCare", pointOfCareIdField.value.toString());
                setExplicitIdentifierFilter(careAssistantField, "PointOfCare", pointOfCareIdField.value.toString());
                setExplicitIdentifierFilter(leadAssistantField, "PointOfCare", pointOfCareIdField.value.toString());
            }

            const dischargedAtField = getField<DateTimeFormFieldData>(formData, "DischargedAt");
            const dischargeReasonField = getField<ReferencedExtensibleEnumFormFieldData>(formData, "DischargeReasonId");

            dischargedAtField.isReadOnly = true;
            dischargeReasonField.isReadOnly = true;

            if (dischargedAtField?.value === null) {
                dischargedAtField.isVisible = false;
                dischargeReasonField.isVisible = false;
            }
        });
        return Promise.resolve();
    }

    private resetAssistants(form: IForm): void {
        const leadAssistantField = getField<ReferencedEntityFormFieldData>(form.data.Content, "LeadAssistant");
        const careAssistantField = getField<ReferencedEntityFormFieldData>(form.data.Content, "CareAssistant");
        State.runInAction(() => {
            leadAssistantField.value = null;
            careAssistantField.value = null;
        });
    }

    private resetDoctors(form: IForm): void {
        const leadDoctorField = getField<ReferencedEntityFormFieldData>(form.data.Content, "LeadDoctor");
        const careDoctorField = getField<ReferencedEntityFormFieldData>(form.data.Content, "CareDoctor");
        State.runInAction(() => {
            leadDoctorField.value = null;
            careDoctorField.value = null;
        });
    }

    private async tryGetOnlyPractitionerAsync(pointOfCareId: PointOfCareId, practitionerType: PractitionerType): Promise<PractitionerId> {
        const results = await this.practitionerApiAdapter.searchPractitionersAsync(
            practitionerType,
            LocalDate.today(),
            false,
            null,
            null,
            null,
            {
                direction: "asc",
                columnId: "name"
            } as IOrderingState,
            {
                currentPage: 0,
                pageSize: 2
            } as IPagingState,
            null,
            [
                new ExplicitIdentifierFilter("PointOfCare", pointOfCareId.value)
            ]
        );

        if (!results?.value?.values || results.value.values.length !== 1) {
            return null;
        }

        return results.value.values[0].id;
    }

    private async trySelectLeadPractitionertAsync(
        form: IForm,
        pointOfCareId: PointOfCareId,
        practitionerType: PractitionerType
    ): Promise<void> {

        const practitionerTypeString = practitionerType == PractitionerType.Doctor ? "Doctor" : "Assistant";

        const leadPractitionerField = getField<ReferencedEntityFormFieldData>(form.data.Content, `Lead${practitionerTypeString}`);
        const carePractitionerField = getField<ReferencedEntityFormFieldData>(form.data.Content, `Care${practitionerTypeString}`);
        if (!leadPractitionerField || !!leadPractitionerField.value) {
            return;
        }

        const leadPractitionerId = await this.tryGetOnlyPractitionerAsync(pointOfCareId, practitionerType);
        if (!leadPractitionerId) {
            return;
        }

        State.runInAction(() => leadPractitionerField.value = +leadPractitionerId.value);
        if (carePractitionerField && !carePractitionerField.value) {
            State.runInAction(() => carePractitionerField.value = +leadPractitionerId.value);
        }
    }
   
}

export default AdmitPatientAndCareActivityBaseDataScreenFormOutpatientBaseFormLogic;
