import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import DataGridExtensibleEnumColumn, { IExtensibleEnumColumnProps } from "@CommonControls/DataGrid/Column/DataGridExtensibleEnumColumn";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";

interface IMedicationTypeColumnDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IMedicationTypeColumnProps extends IExtensibleEnumColumnProps {
    _dependencies?: IMedicationTypeColumnDependencies;
}


const MedicationTypeColumn: React.SFC<IMedicationTypeColumnProps> = (props: IMedicationTypeColumnProps) => {
    return (
        <DataGridExtensibleEnumColumn
            extensibleEnumStore={props._dependencies.careReferenceDataStore.medicationType}
            excludedFilterValues={["S"]}
            header={"MedicationType"}
            {...props}
        />
    );
};

export default connect(
    MedicationTypeColumn,
    new DependencyAdapter<IMedicationTypeColumnProps, IMedicationTypeColumnDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore")
    }))
);