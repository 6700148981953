import moment from "moment";
import PractitionerId from "@Primitives/PractitionerId.g";
import DiagnosisId from "@Primitives/DiagnosisId.g";
import EhiCareTypeId from "@Primitives/EhiCareTypeId.g";
import EhiEventTypeId from "@Primitives/EhiEventTypeId.g";
import EhiDischargeReasonId from "@Primitives/EhiDischargeReasonId.g";
import EhiPatientIdentifierTypeId from "@Primitives/EhiPatientIdentifierTypeId.g";
import EhiInpatientAdmissionTypeId from "@Primitives/EhiInpatientAdmissionTypeId.g";
import EhiInpatientAdmissionClassificationId from "@Primitives/EhiInpatientAdmissionClassificationId.g";
import EhiPatientFurtherTreatmentId from "@Primitives/EhiPatientFurtherTreatmentId.g";
import EhiEmergencyLevelClaimTypeId from "@Primitives/EhiEmergencyLevelClaimTypeId.g";
import EhiEmergencyExaminationClaimTypeId from "@Primitives/EhiEmergencyExaminationClaimTypeId.g";
import EhiEReferralServiceTypeId from "@Primitives/EhiEReferralServiceTypeId.g";
import EhiHealthcareProviderId from "@Primitives/EhiHealthcareProviderId.g";
import EhiHealthcareProviderOrganizationUnitId from "@Primitives/EhiHealthcareProviderOrganizationUnitId.g";
import ConditionId from "@Primitives/ConditionId.g";

export default class EhiEvent {
    public ehiId: string;
    public patientIdentifier: string;
    public patientIdentifierTypeId: EhiPatientIdentifierTypeId;
    public patientAgeInYears: number;
    public patientZipCode: string;
    public ehiCareTypeId: EhiCareTypeId;
    public ehiDischargeReasonId: EhiDischargeReasonId;
    public ehiInpatientAdmissionTypeId: EhiInpatientAdmissionTypeId;
    public ehiInpatientAdmissionClassificationId: EhiInpatientAdmissionClassificationId;
    public ehiPatientFurtherTreatmentId: EhiPatientFurtherTreatmentId;
    public ehiEmergencyLevelClaimTypeId: EhiEmergencyLevelClaimTypeId;
    public ehiEmergencyExaminationClaimTypeId: EhiEmergencyExaminationClaimTypeId;
    public ehiEventTypeId: EhiEventTypeId;
    public beginningOfCare: moment.Moment;
    public endOfCare: moment.Moment;
    public careOrganizationUnitCode: string;
    public careOrganizationUnitId: EhiHealthcareProviderOrganizationUnitId;
    public ehiEReferralServiceTypeId: EhiEReferralServiceTypeId;
    public careDoctorCode: string;
    public careDoctorId: PractitionerId;
    public careIdentifier: string;
    public registrationNumber: string;
    public nursingNumber: string;
    public mainDiagnosisJustifyingNursing: string;
    public mainDiagnosisJustifyingNursingId: ConditionId;
    public rescuePickUpPlace: string;
    public rescueHandoverPlace: string;
    public rescueDataSheetNumber: string;
    public rescueReceivingInstituteId: EhiHealthcareProviderId;
    public rescueReceivingInstituteCode: string;
    public referralWorkplaceCode: string;
    public referralWorkplaceId: EhiHealthcareProviderOrganizationUnitId;
    public referralDoctorCode: string;
    public referralDoctorId: PractitionerId;
    public referralDiagnosisCode: string;
    public referralDiagnosisId: DiagnosisId;
    public referralCareIdentifier: string;
    public eReferralEhiId: string;
    public version: number;
    public lastModifiedBy: string;
    public lastModifiedAt: moment.Moment;
    public createdAt: moment.Moment;
    public createdBy: string;
    public data: string;
    public mimeType: string;
}