import FamilyDoctorDocumentId from "@Primitives/FamilyDoctorDocumentId.g";
import FamilyDoctorDocumentSubject from "@Primitives/FamilyDoctorDocumentSubject";
import PatientId from "@Primitives/PatientId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import UserId from "@Primitives/UserId.g";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import LockingEntityStoreBase from "@Toolkit/CommonWeb/Model/LockingEntityStoreBase";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import moment from "moment";
import CurativeItem from "./CurativeItem";
import FamilyDoctorDocumentMedicationReference from "./FamilyDoctorDocumentMedicationReference";
import CareActivityId from "@Primitives/CareActivityId.g";

export default class FamilyDoctorDocumentStore extends LockingEntityStoreBase<FamilyDoctorDocumentId> implements IDirtyChecked {

    @State.observable.ref public curativeItems: IObservableArray<CurativeItem> = State.observable([]);
    @State.observable.ref public familyDoctorDocumentSubject: FamilyDoctorDocumentSubject = FamilyDoctorDocumentSubject.CurativeCertificate;
    @State.observable.ref public familyDoctorDocumentMedicationReferences: IObservableArray<FamilyDoctorDocumentMedicationReference> = State.observable([]);
    public createdAt: moment.Moment;
    public careActivityId: CareActivityId;
    public pointOfCareId: PointOfCareId;
    public patientId: PatientId;
    public createdBy: PractitionerId;

    public constructor(isNew: boolean) {
        super(isNew);
        this.createdAt = moment(moment.now());
    }

    @State.action.bound
    public setFamilyDoctorDocumentSubject(familyDoctorDocumentSubject: FamilyDoctorDocumentSubject) {
        this.familyDoctorDocumentSubject = familyDoctorDocumentSubject;
    }

    private _dirtyChecker = new StoreDirtyChecker();

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "operationInfo",
        "validationResults"
    ];

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }

    @State.action.bound
    public removeFamilyDoctorDocumentMedicationReferenceListItem(familyDoctorDocumentMedicationReference: FamilyDoctorDocumentMedicationReference) {
        this.familyDoctorDocumentMedicationReferences = State.observable(this.familyDoctorDocumentMedicationReferences.filter(f => f !== familyDoctorDocumentMedicationReference));
    }
    
    @State.action.bound
    public removeCurativeListItem(curativeItem: CurativeItem) {
        this.curativeItems = State.observable(this.curativeItems.filter(c => c !== curativeItem));
    }

}