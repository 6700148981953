export interface IValueWrapper<T> {
    value?: T;
}

export default abstract class ValueWrapper<T>  {
    constructor(
        public readonly value: T
    ) {}

    public static equals<T>(a: IValueWrapper<T>, b: IValueWrapper<T>) {
        if (!a && !b) {
            return true;
        }

        if (a && b) {
            return a.value === b.value;
        }

        return false;
    }

    public static default(): null {
        return null;
    }
}