import Di from "@Di";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import CareExtensibleEnumApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/CareExtensibleEnumApiAdapter";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import LateralityId from "@Primitives/LateralityId.g";
import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import TemporalReferenceDataStore from "@Toolkit/CommonWeb/TemporalData/TemporalReferenceDataStore";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import ServiceRequestManagementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestManagementApiAdapter";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import MedicalServiceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicalServiceApiAdapter";
import IMedicalServiceVersion from "./IMedicalServiceVersion";
import ServiceRequestDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestDefinition";
import PatientDocumentType from "./PatientDocumentType";
import LocalizedReferenceDatStore from "@Toolkit/CommonWeb/ReferenceData/LocalizedReferenceDataStore";
import PatientRelativeTypeId from "@Primitives/PatientRelativeTypeId.g";
import PatientPractitionerTypeId from "@Primitives/PatientPractitionerTypeId.g";
import PractitionSpecialtyId from "@Primitives/PractitionSpecialtyId.g";
import StatusChangeReasonStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/StatusChangeReasonStore";
import StatusChangeReasonApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/StatusChangeReasonApiAdapter";
import CareTypeId from "@Primitives/CareTypeId.g";
import PatientDocumentTypeId from "@Primitives/PatientDocumentTypeId.g";
import PatientDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/Patient/PatientDocumentApiAdapter";
import ObservationDefinitionApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ObservationDefinitionApiAdapter";
import ObservationDefinitionReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/ObservationDefinitionReferenceDataStore";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";
import ConditionsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ConditionsApiAdapter";
import ConditionId from "@Primitives/ConditionId.g";
import IConditionVersion from "./IConditionVersion";
import CareRegisterReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareRegisterReferenceDataStore";
import ForwardingNeededAtDischargeReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/ForwardingNeededAtDischargeReferenceDataStore";
import ForwardingNeededAtDischargeApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ForwardingNeededAtDischargeApiAdapter";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import MedicationsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicationsApiAdapter";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import SubstanceId from "@Primitives/SubstanceId.g";
import ISubstanceVersion from "./Medications/ISubstanceVersion";
import MedicationId from "@Primitives/MedicationId.g";
import MedicationVersion from "./Medications/MedicationVersion";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import MedicationTypeId from "@Primitives/MedicationTypeId.g";
import MedicationFormId from "@Primitives/MedicationFormId.g";
import DosageModeId from "@Primitives/DosageModeId.g";
import IConditionWithoutVersion from "./IConditionWithoutVersion";
import MedicationEquipmentClassificationId from "@Primitives/MedicationEquipmentClassificationId.g";
import IMedicationEquipmentClassificationVersion from "./Medications/IMedicationEquipmentClassificationVersion";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import VaccineId from "@Primitives/VaccineId.g";
import IVaccine from "./IVaccine";
import VaccinesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/VaccinesApiAdapter";
import AutonomyDisabilitiesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/AutonomyDisabilitiesApiAdapter";
import AutonomyDisabilityId from "@Primitives/AutonomyDisabilityId.g";
import IAutonomyDisability from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IAutonomyDisability";
import DevicesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/DevicesApiAdapter";
import DeviceId from "@Primitives/DeviceId.g";
import IDevice from "./IDevice";
import InternationalNonproprietaryNameId from "@Primitives/InternationalNonproprietaryNameId.g";
import IInternationalNonproprietaryName from "./IInternationalNonproprietaryName";
import InternationalNonproprietaryNamesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/InternationalNonproprietaryNamesApiAdapter";
import ILifeStyleRisk from "./ILifeStyleRisk";
import LifeStyleRiskId from "@Primitives/LifeStyleRiskId.g";
import LifeStyleRisksApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/LifeStyleRisksApiAdapter";
import DosageFormId from "@Primitives/DosageFormId.g";
import AllergyIntoleranceId from "@Primitives/AllergyIntoleranceId.g";
import AllergyIntoleranceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/ReferenceData/AllergyIntoleranceApiAdapter";
import IAllergyIntoleranceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/IAllergyIntoleranceVersion";
import MedicalAlertId from "@Primitives/MedicalAlertId.g";
import IMedicalAlert from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalAlert";
import MedicalAlertsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/MedicalAlertsApiAdapter";
import DischargeReasonId from "@Primitives/DischargeReasonId.g";
import SpecimenTypeId from "@Primitives/SpecimenTypeId.g";

@Di.injectable()
export default class CareReferenceDataStore extends ReferenceDataStoreBase {

    public diagnosisRole = new ExtensibleEnumStore<DiagnosisRoleId>(this.apiAdapter.loadDiagnosisRoleIdsAsync, this.localizationService.localizeEnumId);
    public laterality = new ExtensibleEnumStore<LateralityId>(this.apiAdapter.loadLateralityIdsAsync, this.localizationService.localizeEnumId);
    public patientRelativeType = new ExtensibleEnumStore<PatientRelativeTypeId>(this.apiAdapter.loadPatientRelativeTypeIdsAsync, this.localizationService.localizeEnumId);
    public patientPractitionerType = new ExtensibleEnumStore<PatientPractitionerTypeId>(this.apiAdapter.loadPatientPractitionerTypeIdsAsync, this.localizationService.localizeEnumId);
    public practitionSpecialty = new ExtensibleEnumStore<PractitionSpecialtyId>(this.apiAdapter.loadPractitionSpecialtyIdsAsync, this.localizationService.localizeEnumId);
    public careType = new ExtensibleEnumStore<CareTypeId>(this.apiAdapter.loadCareTypeIdsAsync, this.localizationService.localizeEnumId);
    public dischargeReason = new ExtensibleEnumStore<DischargeReasonId>(this.apiAdapter.loadDischargeReasonIdsAsync, this.localizationService.localizeEnumId);
    public textBlockType = new ExtensibleEnumStore<TextBlockTypeId>(this.apiAdapter.loadTextBlockTypeIdsAsync, this.localizationService.localizeEnumId);
    public medicationForm = new ExtensibleEnumStore<MedicationFormId>(this.apiAdapter.loadMedicationFormIdsAsync, this.localizationService.localizeEnumId);
    public medicationType = new ExtensibleEnumStore<MedicationTypeId>(this.apiAdapter.loadMedicationTypeIdsAsync, this.localizationService.localizeEnumId);
    public dosageMode = new ExtensibleEnumStore<DosageModeId>(this.apiAdapter.loadDosageModeIdsAsync, this.localizationService.localizeEnumId);
    public dosageForm = new ExtensibleEnumStore<DosageFormId>(this.apiAdapter.loadDosageFormIdsAsync, this.localizationService.localizeEnumId);
    public specimentType = new ExtensibleEnumStore<SpecimenTypeId>(this.apiAdapter.loadSpecimenTypeIdsAsync, this.localizationService.localizeEnumId);

    public serviceRequestDefinition = new TemporalReferenceDataStore<ServiceRequestDefinitionId, ServiceRequestDefinition>(this.serviceRequestManagementApiAdapter.getServiceRequestDefinitionsBySelectorsAsync);
    public medicalService = new TemporalReferenceDataStore<MedicalServiceId, IMedicalServiceVersion>(this.medicalServiceApiAdapter.loadVersionsAsync, this.medicalServiceApiAdapter.getAllIdsAsync);

    public condition = new TemporalReferenceDataStore<ConditionId, IConditionVersion>(this.conditionsApiAdapter.loadConditionVersionsAsync);
    public conditionsWithoutVersion = new ReferenceDataStore<ConditionId, IConditionWithoutVersion>(this.conditionsApiAdapter.getConditionsWithoutVersionsAsync);

    public observationDefinition = new ObservationDefinitionReferenceDataStore(this.observationDefinitionApiAdapter);

    public forwardingNeededAtDischarge = new ForwardingNeededAtDischargeReferenceDataStore(
        this.careRegisterReferenceDataStore.dischargeReason,
        this.forwardingNeededAtDischargeApiAdapter
    );

    public patientDocumentType = new LocalizedReferenceDatStore<PatientDocumentTypeId, PatientDocumentType>(this.identifierApiAdapter.loadPatientDocumentTypesAsync, this.localizationService.localizeEnum, (item) => item.identifierSystemId.value, "PatientDocumentType", this.identifierApiAdapter.loadAllPatientDocumentTypeIdsAsync);
    public statusChangeReason = new StatusChangeReasonStore(this.statusChangeReasonApiAdapter);
    
    public allergyIntolerances = new TemporalReferenceDataStore<AllergyIntoleranceId, IAllergyIntoleranceVersion>(this.allergyIntoleranceApiAdapter.loadVersionsAsync);
    public substances = new TemporalReferenceDataStore<SubstanceId, ISubstanceVersion>(this.medicationsApiAdapter.getSubstancesByVersionSelectors);
    public medications = new TemporalReferenceDataStore<MedicationId, MedicationVersion>(this.medicationsApiAdapter.getMedicationVersionsBySelectorsAsync);
    public medicationEquipmentClassifications = new TemporalReferenceDataStore<MedicationEquipmentClassificationId, IMedicationEquipmentClassificationVersion>(this.medicationsApiAdapter.getMedicationEquipmentClassificationsByVersionSelectorsAsync);

    public vaccines = new ReferenceDataStore<VaccineId, IVaccine>(this.vaccinesApiAdapter.loadVaccinesAsync, this.vaccinesApiAdapter.getAllIdsAsync);
    public autonomyDisabilities = new ReferenceDataStore<AutonomyDisabilityId, IAutonomyDisability>(this.autonomyDisabilitiesApiAdapter.loadAutonomyDisabilitiesAsync, this.autonomyDisabilitiesApiAdapter.getAllIdsAsync);
    public devices = new ReferenceDataStore<DeviceId, IDevice>(this.devicesApiAdapter.loadDevicesAsync, this.devicesApiAdapter.getAllIdsAsync);
    public internationalNonproprietaryNames = new ReferenceDataStore<InternationalNonproprietaryNameId, IInternationalNonproprietaryName>(this.internationalNonproprietaryNameApiAdapter.loadInternationalNonproprietaryNamesAsync, this.internationalNonproprietaryNameApiAdapter.getAllIdsAsync);
    public lifeStyleRisks = new ReferenceDataStore<LifeStyleRiskId, ILifeStyleRisk>(this.lifeStyleRisksApiAdapter.loadLifeStyleRisksAsync, this.lifeStyleRisksApiAdapter.getAllIdsAsync);
    public medicalAlerts = new ReferenceDataStore<MedicalAlertId, IMedicalAlert>(this.medicalAlertsApiAdapter.loadMedicalAlertsAsync, this.medicalAlertsApiAdapter.getAllIdsAsync);

    constructor(
        @Di.inject("CareExtensibleEnumApiAdapter") private apiAdapter: CareExtensibleEnumApiAdapter,
        @Di.inject("ILocalizationService") private localizationService: ILocalizationService,
        @Di.inject("ServiceRequestManagementApiAdapter") private serviceRequestManagementApiAdapter: ServiceRequestManagementApiAdapter,
        @Di.inject("MedicalServiceApiAdapter") private medicalServiceApiAdapter: MedicalServiceApiAdapter,
        @Di.inject("PatientDocumentApiAdapter") private identifierApiAdapter: PatientDocumentApiAdapter,
        @Di.inject("StatusChangeReasonApiAdapter") private statusChangeReasonApiAdapter: StatusChangeReasonApiAdapter,
        @Di.inject("ObservationDefinitionApiAdapter") private observationDefinitionApiAdapter: ObservationDefinitionApiAdapter,
        @Di.inject("ConditionsApiAdapter") private conditionsApiAdapter: ConditionsApiAdapter,
        @Di.inject("CareRegisterReferenceDataStore") private careRegisterReferenceDataStore: CareRegisterReferenceDataStore,
        @Di.inject("ForwardingNeededAtDischargeApiAdapter") private forwardingNeededAtDischargeApiAdapter: ForwardingNeededAtDischargeApiAdapter,
        @Di.inject("AllergyIntoleranceApiAdapter") private allergyIntoleranceApiAdapter: AllergyIntoleranceApiAdapter,
        @Di.inject("MedicationsApiAdapter") private medicationsApiAdapter: MedicationsApiAdapter,
        @Di.inject("VaccinesApiAdapter") private vaccinesApiAdapter: VaccinesApiAdapter,
        @Di.inject("AutonomyDisabilitiesApiAdapter") private autonomyDisabilitiesApiAdapter: AutonomyDisabilitiesApiAdapter,
        @Di.inject("DevicesApiAdapter") private devicesApiAdapter: DevicesApiAdapter,
        @Di.inject("IReferenceDataLoader") private referenceDataLoader: IReferenceDataLoader,
        @Di.inject("InternationalNonproprietaryNamesApiAdapter") private internationalNonproprietaryNameApiAdapter: InternationalNonproprietaryNamesApiAdapter,
        @Di.inject("LifeStyleRisksApiAdapter") private lifeStyleRisksApiAdapter: LifeStyleRisksApiAdapter,
        @Di.inject("MedicalAlertsApiAdapter") private medicalAlertsApiAdapter: MedicalAlertsApiAdapter,
    ) {
        super();
        referenceDataLoader.register<MedicationTypeId>(MedicationTypeId.typeName, () => this.medicationType.ensureLoadedAsync());
        referenceDataLoader.register<MedicationFormId>(MedicationFormId.typeName, () => this.medicationForm.ensureLoadedAsync());
        referenceDataLoader.register<AllergyIntoleranceId>(AllergyIntoleranceId.typeName, refs => this.allergyIntolerances.ensureLoadedAsync(refs.map(i => ({ id: i, validOn: LocalDate.today() }))));
        referenceDataLoader.register<MedicationId>(MedicationId.typeName, refs => this.medications.ensureLoadedAsync(refs.map(i => ({ id: i, validOn: LocalDate.today() }))));
        referenceDataLoader.register<SubstanceId>(SubstanceId.typeName, refs => this.substances.ensureLoadedAsync(refs.map(i => ({ id: i, validOn: LocalDate.today() }))));
        referenceDataLoader.register<DosageModeId>(DosageModeId.typeName, () => this.dosageMode.ensureLoadedAsync());
        referenceDataLoader.register<MedicalServiceId>(MedicalServiceId.typeName, (refs) => this.medicalService.ensureLoadedAsync(refs.map(i => ({ id: i, validOn: LocalDate.today() }))));
    }
}
