import React, { Fragment } from "react";
import BlockDropZone from "./BlockDropZone";
import { useFormLayoutEditorStore } from "./FormLayoutEditorStoreProvider";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EditableLayoutAnyBlock from "./LayoutElements/EditableLayoutAnyBlock";

function WorkingArea() {

    const store = useFormLayoutEditorStore();

    return (
        <div onClick={store.clearSelection} style={{ height: "100%", overflowY: "auto" }}>
            <BlockDropZone collection={store.rootBlocks} indexToInsert={0} />
            {store.rootBlocks.map((b, idx) => (
                <Fragment key={b.id}>
                    <EditableLayoutAnyBlock block={b} parentCollection={store.rootBlocks} />
                    <BlockDropZone collection={store.rootBlocks} indexToInsert={idx + 1} />
                </Fragment>
            ))}
        </div>
    );
}

export default State.observer(WorkingArea);