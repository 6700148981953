import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import * as Ui from "@CommonControls";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import StaticWebAppResources from "@StaticResources";
import Role from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/Role";
import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";

interface IRolesListProps extends IHasMasterDetailState {
    dataSource: InMemoryDataGridDataSource;
    onGetAssignedPermissionCountForRole: (role: Role) => number;
    onBack(): void;
}

/** @screen */

const RolesList: React.FC<IRolesListProps> = (props) => {
    const rowId = (row: Role) => row.id.value;
    const numberOfPermissionsOrder = (value: Role) => value.numberOfPermissions;
    const displayNameFilter = (value: string) => value;

    return (
        <Ui.DataGrid
            dataSource={props.dataSource}
            generateInitialFilterStore
            changeOnMount
            isSelectable
            fixedHeight="calc(100vh - 104px)"
            rowHeight={"39px"}
            onSelectedRowChange={props._masterDetailState.onSelectedItemChange}
            selectedRow={props._masterDetailState.selectedItem}
            rowId={rowId}
            rowComparer="rowId"
            hasBackButton
            backButtonText={StaticWebAppResources.Common.Button.Back}
            onBack={props.onBack}
            automationId="roleList_dataGrid"
        >
            <DataGridColumn
                id={0}
                dataGetter={"displayName"}
                header={StaticAuthorizationResources.RoleManagement.Label.Name}
                width="70%"
                isFilterable
                isOrderable
                clientSideFilterableValueGetter={displayNameFilter}
                clientSideOrderableValueGetter={"displayName"}
            />
            <DataGridColumn
                id={1}
                dataGetter={props.onGetAssignedPermissionCountForRole}
                header={StaticAuthorizationResources.RoleManagement.Label.NumberOfPermissions}
                clientSideOrderableValueGetter={numberOfPermissionsOrder}
                width="30%"
                isOrderable/>
        </Ui.DataGrid>
    );
};

export default State.observer(RolesList);
