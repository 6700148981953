import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/Proxy.g";
import Di from "@Di";
import { IEuReportFilter } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/EuReportPanel/IEuReportFilter";

@Di.injectable()
export default class EuPerformanceStatementDtoMapper {

    public getCreateEuPerformanceReportDto(filter: IEuReportFilter) {
        return new Proxy.CreateEuPerformanceStatementControllerDto(
            {
                filter: this.getEuReportFilterDto(filter)
            } as Proxy.ICreateEuPerformanceStatementControllerDto);
    }

    public hasFinalizedEuPerformanceStatementForMonthDto(filter: IEuReportFilter) {
        return new Proxy.HasFinalizedEuPerformanceStatementForMonthControllerDto(
            {
                filter: this.getEuReportFilterDto(filter)
            } as Proxy.IHasFinalizedEuPerformanceStatementForMonthControllerDto);
    }

    public getEuReportFilterDto(filter: IEuReportFilter) {
        return new Proxy.EuReportFilterDto(
            {
                reportValidityDate: filter.reportValidityDate,
                organizationUnitIds: filter.organizationUnitIds,
                containsOnlyClosedCareActivities: filter.containsOnlyClosedCareActivities
            } as Proxy.IEuReportFilterDto);
    }
}