import Di from "@Di";
import FamilyDoctorDocumentStoreMapper from "./FamilyDoctorDocumentStoreMapper";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FamilyDoctorDocumentId from "@Primitives/FamilyDoctorDocumentId.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { getCreateFamilyDoctorDocumentDto, getFamilyDoctorDocumentDocumentDto, getUpdateFamilyDoctorDocumentDto } from "./FamilyDoctorDocumentDtoMapper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import DocumentInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentInfo";
import FamilyDoctorDocumentStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentStore";
import { getDocumentInfo } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Common/DocumentStoreMapper";

@Di.injectable()
export default class FamilyDoctorDocumentApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IFamilyDoctorDocumentClient") private apiClient: Proxy.IFamilyDoctorDocumentClient,
        @Di.inject("FamilyDoctorDocumentStoreMapper") private storeMapper: FamilyDoctorDocumentStoreMapper
    ) {
        super();
    }
    @State.bound
    public getFamilyDoctorDocumentByIdAsync(id: FamilyDoctorDocumentId, requestLock: boolean, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new FamilyDoctorDocumentStore(false),
            async target => {
                const response = await this.apiClient.getFamilyDoctorDocumentByIdCommandAsync(CreateRequestId(), new Proxy.GetFamilyDoctorDocumentByIdControllerDto({
                    requestLock: requestLock,
                    familyDoctorDocumentId: id
                }), isPermissionCheckOnly);
                return this.storeMapper.applyToStore(target, response, isPermissionCheckOnly);
            }
        );
    }

    @State.bound
    public createFamilyDoctorDocumentAsync(familyDoctorDocument: FamilyDoctorDocumentStore, requestLock: boolean, isValidateOnly = false, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new FamilyDoctorDocumentStore(false),
            async target => {
                const response = await this.apiClient.createFamilyDoctorDocumentCommandAsync(
                    CreateRequestId(),
                    getCreateFamilyDoctorDocumentDto(familyDoctorDocument, requestLock, isValidateOnly), isPermissionCheckOnly);
                return this.storeMapper.applyToStore(target, response, isPermissionCheckOnly);
            }
        );
    }
    @State.bound
    public updateFamilyDoctorDocumentAsync(familyDoctorDocument: FamilyDoctorDocumentStore, releaseLock: boolean, isValidateOnly: boolean = false, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new FamilyDoctorDocumentStore(false),
            async target => {
                const response = await this.apiClient.updateFamilyDoctorDocumentCommandAsync(
                    CreateRequestId(),
                    getUpdateFamilyDoctorDocumentDto(
                        familyDoctorDocument,
                        releaseLock,
                        familyDoctorDocument.lockInfo?.lockId,
                        isValidateOnly), isPermissionCheckOnly);
                if (response.isPersisted) {
                    return await this.storeMapper.applyToStoreWithExistingLockAndResolveValidationProblemsAsync(target, response, familyDoctorDocument.lockInfo, releaseLock, isPermissionCheckOnly);
                } else {
                    return this.storeMapper.applyToStore(target, response, isPermissionCheckOnly);
                }
            }
        );
    }
    @State.bound
    public removeFamilyDoctorDocument(familyDoctorDocumentStore: FamilyDoctorDocumentStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.deleteFamilyDoctorDocumentCommandAsync(
                    CreateRequestId(),
                    new Proxy.DeleteFamilyDoctorDocumentControllerDto({
                        familyDoctorDocumentId: familyDoctorDocumentStore.id,
                        lockId: familyDoctorDocumentStore.lockInfo?.lockId,
                        releaseLockIfSuccessful: true,
                        rowVersion: familyDoctorDocumentStore.rowVersion
                    }), isPermissionCheckOnly);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }
    public validateAsync(store: FamilyDoctorDocumentStore) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response =
                    store.isNew ?
                        await this.apiClient.createFamilyDoctorDocumentCommandAsync(CreateRequestId(), getCreateFamilyDoctorDocumentDto(store, false, true)) :
                        await this.apiClient.updateFamilyDoctorDocumentCommandAsync(CreateRequestId(), getUpdateFamilyDoctorDocumentDto(store, false, store.lockInfo.lockId, true));

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    public getSingeDocumentForFamilyDoctorDocumentAsync(
        familyDoctorDocumentId: FamilyDoctorDocumentId,
        documentTypeCode?: string,
        isPrimary?: boolean,
        isAutomatic?: boolean
    ) {
        return this.processOperationAsync(
            new SimpleStore<DocumentInfo>(),
            async target => {
                const response = await this.apiClient.getSingleDocumentForFamilyDoctorDocumentQueryAsync(
                    CreateRequestId(),
                    getFamilyDoctorDocumentDocumentDto(familyDoctorDocumentId, documentTypeCode, isPrimary, isAutomatic)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = getDocumentInfo(response.document);
            }
        );
    }
}