import React from "react";
import * as Ui from "@CommonControls";
import Styles from "./InfoButton.less";

export interface ITextCardProps {
    text?: React.ReactNode;
    moreLabel?: React.ReactNode;
    onClick?: () => void;

}


const TextCard: React.FC<ITextCardProps> = props => (
    <div className={Styles.textCardContainer}>
        <div className={Styles.textCardText}>{props.text}</div>
        {props.moreLabel && (
            <Ui.Flex xsJustify="end">
                <Ui.Flex.Item>
                    <Ui.Button automationId="textCardMoreButton" className={Styles.textCardMoreLabel} visualStyle="link" onClick={props.onClick}>
                        {props.moreLabel}
                    </Ui.Button>
                </Ui.Flex.Item>
            </Ui.Flex>
        )}
    </div>
);

export default TextCard;