import React, { useMemo, useCallback } from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import { isNullOrUndefined, isNullOrUndefinedOrGivenString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import WorklistTypeBoundNDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/WorklistTypeBoundNDataPanel";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { getUseCaseFromNDataUseCaseStateAsUrlParam, parseUseCaseStateFromUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import ISurgeryRouteParams from "@HisPlatform/Application/Routes/ISurgeryRouteParams";
import SurgeryId from "@Primitives/SurgeryId.g";
import UseCases from "@Primitives/UseCases";
import config from "@Config";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";

function SurgeryPage(props: IRoutingFrameContentProps<ISurgeryRouteParams>) {

    const [selectedId, useCaseState] = useMemo(() => {
        const routeParameters = props.routingController.currentRoute.parameters;
        return [isNullOrUndefinedOrGivenString(routeParameters.selectedId, "null") ? null : new SurgeryId(routeParameters.selectedId),
            parseUseCaseStateFromUrlParam(routeParameters.useCase)];
    }, [props.routingController.currentRoute.parameters.selectedId, props.routingController.currentRoute.parameters.useCase]);

    const setState = useCallback((id: string, newUseCaseState: INDataUseCaseState) => {
        let useCaseId = id;
        if (newUseCaseState?.useCase?.value === UseCases.surgeryDetail) {
            useCaseId = newUseCaseState.useCaseArguments.find(it => it.name === "surgeryId")?.value?.value ?? "new";
        }
        props.routingController.replace(props.routingController.currentRoute.definition.makeRoute({
            selectedId: useCaseId ?? "null",
            useCase: getUseCaseFromNDataUseCaseStateAsUrlParam(newUseCaseState)
        }));
    }, [props.routingController]);

    if (!config.testFeatures.surgeryEnabled) {
        return <UnauthorizedAccessContent />;
    }

    return (
        <SingleLayout>
            <WorklistTypeBoundNDataPanel
                worklistDefinitionReference="Surgery"
                onChange={setState}
                useCaseState={useCaseState}
                selectedRowId={selectedId?.value}
                disableDetailStrictMode
                hasPrintButton={false}
            />
        </SingleLayout>
    );
}
export default withLoadingBoundary(SurgeryPage);