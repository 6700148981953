import React from "react";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import * as Ui from "@CommonControls";
import * as Styles from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PatientsCareActivitiesPanel/PatientCareActivitiesMasterDetailPanel.less";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { AccessLevel } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientId from "@Primitives/PatientId.g";
import IPatientCareActivitiesDataSource from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientCareActivitiesDataSource";

interface IPatientsCareActivityGridButtonsProps {
    selectedCareActivity: PatientsCareActivityStore;
    row?: PatientsCareActivityStore;
    patientId: PatientId;
    patientsCareActivityListExtensionData: any;
    selectCareActivityAsync: (ca: PatientsCareActivityStore) => Promise<void>;
    showPrimaryDocumentAsync: (ca: PatientsCareActivityStore) => Promise<void>;
    onBack: () => void;
    dataSource: IPatientCareActivitiesDataSource;
}

const handlerAsyncFactory = (ca: PatientsCareActivityStore, handler: (ca: PatientsCareActivityStore) => Promise<void>) => {
    return async () => await handler(ca);
};

@State.observer
export default class PatientsCareActivityGridButtons extends React.Component<IPatientsCareActivityGridButtonsProps> {

    public render() {
        const row = this.props.row;
        const isRowSelected = this.props.selectedCareActivity === row;
        let idBackup = 0;
        return row.accessLevel === AccessLevel.Full && (
            <Ui.Flex spacing="none" innerSpacing="none" verticalSpacing="none" outerSpacing="none" xsJustify="end">
                <HisPlatformExtensionPoint
                    type="patientsCareActivityListButtons"
                    extensionProps={{
                        row: row, // row your boat
                        patientId: this.props.patientId,
                        careActivityId: row.careActivityId,
                        patientsCareActivityListExtensionData: this.props.patientsCareActivityListExtensionData,
                        dataSource: this.props.dataSource
                    }}>
                    <></>
                </HisPlatformExtensionPoint>
                {!!row.primaryDocumentId &&
                    <Ui.Button
                        size="compact"
                        iconName="document"
                        onClickAsync={handlerAsyncFactory(row, this.props.showPrimaryDocumentAsync)}
                        className={Styles.titleButton}
                        tooltipContent={StaticCareResources.OutpatientWorkflow.DocumentEditor.MainDocument}
                        tooltipPosition="left"
                        stopPropagation={{ leftClick: true }}
                        automationId={row.primaryDocumentId?.value || idBackup++ + "_openDocumentButton"} />
                }
                {isRowSelected && <Ui.Button
                    size="compact"
                    iconName="angleLeft"
                    onClick={this.props.onBack}
                    className={Styles.titleButton}
                    stopPropagation={{ leftClick: true }}
                    automationId={"backButton"} />}
                {!isRowSelected && <Ui.Button
                    size="compact"
                    iconName="angleRight"
                    onClickAsync={handlerAsyncFactory(row, this.props.selectCareActivityAsync)}
                    className={Styles.titleButton}
                    stopPropagation={{ leftClick: true }}
                    automationId={row.careActivityId?.value || idBackup++ + "_selectButton"} />}
            </Ui.Flex>
        );
    }
}
