import Di from "@Di";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import {ICombinePatientsScreenProps} from "@HisPlatform/Packages/Patients/Screens/CombinePatientsScreen/CombinePatientsScreen";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StaticWebAppResources from "@StaticResources";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import PatientId from "@Primitives/PatientId.g";
import ShowCombinePatientsScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowCombinePatientsScreenAction.g";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import CombinePatientsScreenApiAdapter from "@HisPlatform/Packages/Patients/Screens/CombinePatientsScreen/CombinePatientsScreenApiAdapter";
import RequestStatus from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/RequestStatus";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import CombinePatientsAction from "@HisPlatform/Packages/Patients/FrontendActions/CombinePatientsAction.g";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import AddressTypeId from "@Primitives/AddressTypeId.g";
import CountryId from "@Primitives/CountryId.g";
import IPatientDocumentFormattingRegistry from "@PluginInterface/PatientDocumentFormatting/IPatientDocumentFormattingRegistry";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";
import { ActionContinuation } from "@Toolkit/ReactClient/ActionProcessing/ActionContinuation";
import ShowPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientScreenAction.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import PatientApiAdapter2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/PatientApiAdapter2";
import ShowPatientMovedCareActivitiesAction from "@HisPlatform/Packages/Care/FrontendActions/ShowPatientMovedCareActivitiesAction.g";

@Di.injectable()
export default class CombinePatientsScreenStore extends EditorScreenPanelStoreBase<ICombinePatientsScreenProps> implements ILoadablePanelStore {
    protected contentToDirtyCheck: any[];

    @State.observable.ref patientToKeepId: PatientId = null;
    @State.observable.ref patientToDeleteId: PatientId = null;

    public get combinePatientsAction() {
        return ActionDescriptor.fromAction(CombinePatientsAction);
    }

    protected get showScreenAction(): ShowCombinePatientsScreenAction {
        return this.props.action;
    }

    @State.computed public get patientToKeep() {
        return this.patientToKeepPromise.get();
    }

    @State.computed public get patientToDelete() {
        return this.patientToDeletePromise.get();
    }

    @State.computed public get patientToKeepCitizenship() {
        return this.patientToKeepCitizenshipCountryPromise.get();
    }

    @State.computed public get patientToDeleteCitizenship() {
        return this.patientToDeleteCitizenshipCountryPromise.get();
    }

    @State.computed public get patientToKeepPermanentAddress() {
        return this.getPermanentAddress(this.patientToKeep);
    }

    @State.computed public get patientToDeletePermanentAddress() {
        return this.getPermanentAddress(this.patientToDelete);
    }

    @State.computed public get patientToKeepPermanentAddressCountry() {
        return this.patientToKeepPermanentAddressCountryPromise.get();
    }

    @State.computed public get patientToDeletePermanentAddressCountry() {
        return this.patientToDeletePermanentAddressCountryPromise.get();
    }

    @State.computed public get patientToKeepDocuments() {
        return this.patientToKeepDocumentsPromise.get();
    }

    @State.computed public get patientToDeleteDocuments() {
        return this.patientToDeleteDocumentsPromise.get();
    }

    @State.computed public get patientToKeepInsurers() {
        return this.patientToKeepInsurersPromise.get();
    }

    @State.computed public get patientToDeleteInsurers() {
        return this.patientToDeleteInsurersPromise.get();
    }

    @State.computed public get patientToKeepPrimaryPhone() {
        return this.getPatientPrimaryPhoneString(this.patientToKeep);
    }

    @State.computed public get patientToDeletePrimaryPhone() {
        return this.getPatientPrimaryPhoneString(this.patientToDelete);
    }

    @State.computed public get patientToKeepPrimaryEmail() {
        return this.getPatientPrimaryEmailString(this.patientToKeep);
    }

    @State.computed public get patientToDeletePrimaryEmail() {
        return this.getPatientPrimaryEmailString(this.patientToDelete);
    }

    private readonly patientToKeepPromise = this.promisedComputed<PatientAdministrativeData>(null, async () => {
        return await this.getPatientAsync(this.patientToKeepId);
    });

    private readonly patientToDeletePromise = this.promisedComputed<PatientAdministrativeData>(null, async () => {
        return await this.getPatientAsync(this.patientToDeleteId);
    });

    private readonly patientToKeepCitizenshipCountryPromise = this.promisedComputed(null, async () => {
        return await this.getCountryStringAsync(this.patientToKeep?.baseData?.citizenshipCountryId);
    });

    private readonly patientToDeleteCitizenshipCountryPromise = this.promisedComputed(null, async () => {
        return await this.getCountryStringAsync(this.patientToDelete?.baseData?.citizenshipCountryId);
    });

    private readonly patientToKeepPermanentAddressCountryPromise = this.promisedComputed(null, async () => {
        return await this.getCountryStringAsync(this.patientToKeepPermanentAddress?.countryId);
    });

    private readonly patientToDeletePermanentAddressCountryPromise = this.promisedComputed(null, async () => {
        return await this.getCountryStringAsync(this.patientToDeletePermanentAddress?.countryId);
    });

    private readonly patientToKeepDocumentsPromise = this.promisedComputed(null, async () => {
        return await this.getPatientDocumentsStringAsync(this.patientToKeep);
    });

    private readonly patientToDeleteDocumentsPromise = this.promisedComputed(null, async () => {
        return await this.getPatientDocumentsStringAsync(this.patientToDelete);
    });

    private readonly patientToKeepInsurersPromise = this.promisedComputed(null, async () => {
        return await this.getPatientInsurancesStringAsync(this.patientToKeep);
    });

    private readonly patientToDeleteInsurersPromise = this.promisedComputed(null, async () => {
        return await this.getPatientInsurancesStringAsync(this.patientToDelete);
    });

    private readonly handleOverlappedInPatientCareActivitiesErrorPromise = this.async(this.handleOverlappedInPatientCareActivitiesErrorAsync);

    private readonly handleInvalidPatientErrorPromise = this.async(this.handleInvalidPatientErrorAsync);

    private readonly handlePatientToBeKeptCannotBeTheSameAsPatientToBeDeletedErrorPromise = this.async(this.handlePatientToBeKeptCannotBeTheSameAsPatientToBeDeletedErrorAsync);

    private readonly combineAsync = this.async(() => this.apiAdapter.combinePatientsAsync(this.patientToKeepId, this.patientToDeleteId));

    protected saveFunction = (): Promise<boolean> => Promise.resolve(false);

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("CombinePatientsScreenApiAdapter") private readonly apiAdapter: CombinePatientsScreenApiAdapter,
        @Di.inject("PatientApiAdapter2") private readonly patientApiAdapter: PatientApiAdapter2,
        @Di.inject("CommonReferenceDataDataStore") private readonly commonReferenceDataStore: CommonReferenceDataDataStore,
        @Di.inject("IPatientDocumentFormattingRegistry") private readonly patientDocumentFormattingRegistry: IPatientDocumentFormattingRegistry,
        @Di.inject("FinanceReferenceDataStore") private readonly financeReferenceDataStore: FinanceReferenceDataStore) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    loadCoreAsync(): Promise<void | { loadedSignals?: string[] }> {
        return Promise.resolve(undefined);
    }

    @State.bound
    private getPatientPrimaryEmailString(patient: PatientAdministrativeData) {
        const primaryEmail = patient?.telecomContactPoints?.find(i => i.isPrimary && i.isEmail);

        return primaryEmail?.value ?? "";
    }

    @State.bound
    private getPatientPrimaryPhoneString(patient: PatientAdministrativeData) {
        const primaryPhone = patient?.telecomContactPoints?.find(i => i.isPrimary && i.isPhone);

        return primaryPhone?.value ?? "";
    }

    @State.bound
    private async getPatientInsurancesStringAsync(patient: PatientAdministrativeData) {
        const insurerOrganizationIds = patient?.insurances?.map(i => i.insurerOrganizationId);
        const insurancePlanIds = patient?.insurances?.map(i => i.insurancePlanId);
        await Promise.all([
            insurerOrganizationIds?.length && this.financeReferenceDataStore.insurerOrganizationMap.ensureLoadedAsync(insurerOrganizationIds),
            insurancePlanIds?.length && this.financeReferenceDataStore.insurancePlanMap.ensureLoadedAsync(insurancePlanIds)
        ]);

        const insuranceStrings = patient?.insurances?.map(i => {
            const insurerOrganization = this.financeReferenceDataStore.insurerOrganizationMap.get(i.insurerOrganizationId);
            const insurancePlan = this.financeReferenceDataStore.insurancePlanMap.get(i.insurancePlanId);

            return `${insurerOrganization.name} - ${insurancePlan.name}`;
        });

        return insuranceStrings?.join(", ") ?? "";
    }

    @State.bound
    private async getPatientDocumentsStringAsync(patient: PatientAdministrativeData) {
        const identifierSystemIds = patient?.patientDocuments?.map(i => i.identifierSystemId);
        if (identifierSystemIds?.length) {
            await this.commonReferenceDataStore.identifierSystemMap.ensureLoadedAsync(identifierSystemIds);
        }

        const documentStrings = patient?.patientDocuments?.map(i => {
            const documentType = this.commonReferenceDataStore.identifierSystemMap.getLocalization(i.identifierSystemId);
            return `${documentType.Name}: ${this.patientDocumentFormattingRegistry.format(i.identifierSystemId.value, i.identifierValue)}`;
        });

        return documentStrings?.join(", ") ?? "";
    }

    @State.bound
    private getPermanentAddress(patient: PatientAdministrativeData) {
        if (patient) {
            const permanentAddress = patient?.addresses?.find(i => i.addressTypeId.value === AddressTypeId.Permanent.value);
            if (permanentAddress) {
                return permanentAddress;
            }
        }

        return null;
    }

    @State.bound
    private async getCountryStringAsync(countryId: CountryId) {
        if (countryId) {
            const citizenshipCountry = await this.commonReferenceDataStore.countryMap.getOrLoadAsync(countryId);
            return `${citizenshipCountry.isoAlpha3} ${citizenshipCountry.citizenshipName}`;
        }

        return "";
    }

    @State.bound
    private async getPatientAsync(patientId: PatientId) {
        if (patientId) {
            const patient = await this.patientApiAdapter.loadByIdAsync(patientId);
            if (patient.operationInfo.requestStatus === RequestStatus.Success) {
                return patient.result;
            }
        }

        return null;
    }

    @State.bound
    public handleInvalidPatientError() {
        this.handleInvalidPatientErrorPromise.fireAndForget();
        return true;
    }

    @State.bound
    private async handleInvalidPatientErrorAsync() {
        await this.dialogService.ok(StaticWebAppResources.Common.Label.Error, StaticCareResources.PatientRegister.CombinePatientsScreen.Errors.InvalidPatient);
    }

    @State.bound
    public handleOverlappedInPatientCareActivitiesError() {
        this.handleOverlappedInPatientCareActivitiesErrorPromise.fireAndForget();
        return true;
    }

    @State.bound
    private async handleOverlappedInPatientCareActivitiesErrorAsync() {
        await this.dialogService.ok(StaticWebAppResources.Common.Label.Error, StaticCareResources.PatientRegister.CombinePatientsScreen.Errors.OverlappedInPatientCareActivities);
    }

    @State.bound
    public handlePatientToBeKeptCannotBeTheSameAsPatientToBeDeletedError() {
        this.handlePatientToBeKeptCannotBeTheSameAsPatientToBeDeletedErrorPromise.fireAndForget();
        return true;
    }

    @State.bound
    private async handlePatientToBeKeptCannotBeTheSameAsPatientToBeDeletedErrorAsync() {
        await this.dialogService.ok(StaticWebAppResources.Common.Label.Error, StaticCareResources.PatientRegister.CombinePatientsScreen.Errors.PatientToBeKeptCanNotBeTheSameAsPatientToBeDeleted);
    }

    @State.action.bound
    public setPatientToKeepId(newPatient: PatientId) {
        this.patientToKeepId = newPatient;
    }

    @State.action.bound
    public setPatientToDeleteId(newPatient: PatientId) {
        this.patientToDeleteId = newPatient;
    }

    @State.bound
    public async onCombineAsync() : Promise<{ actionContinuation: ActionContinuation, action?: FrontendActionBase }> {
        const response = await this.combineAsync();
        if (response.operationInfo.isPersisted) {
            this.notificationService.success(StaticCareResources.PatientRegister.CombinePatientsScreen.Messages.CombineSuccessfulMessage);
            if (response.result) {
                return {
                    actionContinuation: ActionContinuation.Continue,
                    action: new ShowPatientMovedCareActivitiesAction(ScreenDisplayMode.Modal, this.patientToKeepId, this.patientToDeleteId)
                };
            }
            return {
                actionContinuation: ActionContinuation.Continue,
                action: new ShowPatientScreenAction(ScreenDisplayMode.Full, this.patientToKeepId, null)
            };
        }

        return {
            actionContinuation: ActionContinuation.Break
        };
    }
}