exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PractitionerRecommendationList_under-recording_3p1ok {\n  background-color: #8695a7;\n}\n.PractitionerRecommendationList_deleted_1mzkG {\n  background-color: #ce5374;\n}\n.PractitionerRecommendationList_submitted_1k9UL {\n  background-color: #21c313;\n}\n.PractitionerRecommendationList_search-container_2AxFU {\n  background: #f4f7fc;\n  border: solid 1px #00b4ec;\n  padding: 5px 4px 8px;\n  margin: 8px 8px 0px;\n}\n.PractitionerRecommendationList_control-title_3PVx3 {\n  font-family: PragatiNarrow;\n  font-size: 16px;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  letter-spacing: normal;\n  text-align: left;\n  color: #8695a7;\n  margin-left: 4px;\n}\n", ""]);

// exports
exports.locals = {
	"under-recording": "PractitionerRecommendationList_under-recording_3p1ok",
	"underRecording": "PractitionerRecommendationList_under-recording_3p1ok",
	"deleted": "PractitionerRecommendationList_deleted_1mzkG",
	"submitted": "PractitionerRecommendationList_submitted_1k9UL",
	"search-container": "PractitionerRecommendationList_search-container_2AxFU",
	"searchContainer": "PractitionerRecommendationList_search-container_2AxFU",
	"control-title": "PractitionerRecommendationList_control-title_3PVx3",
	"controlTitle": "PractitionerRecommendationList_control-title_3PVx3"
};