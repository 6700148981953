import IBusinessError from "@Toolkit/CommonWeb/Model/IBusinessError";
import IMapperService from "@HisPlatform/Services/Definition/MapperService/IMapperService";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";

export default abstract class BusinessErrorBase implements IBusinessError {

    private static mapperServiceLocator: () => IMapperService;

    public static setMapperServiceLocator(mapperServiceLocator: () => IMapperService & IMapperConfigurator) {
        BusinessErrorBase.mapperServiceLocator = mapperServiceLocator;
    }

    public static fromJS(data: any): BusinessErrorBase {
        const mapperService = BusinessErrorBase.mapperServiceLocator();
        const nameMappingResult = mapperService.tryMapByName<any, BusinessErrorBase>(data._discriminator, data);
        let mapped: BusinessErrorBase;

        if (nameMappingResult.mapperFound) {
            mapped = nameMappingResult.result;
        } else {
            mapped = mapperService.mapByName<any, BusinessErrorBase>("UnknownBusinessError", data);
        }

        return mapped;
    }

    public get name(): string | null { return null; }

    // Only for the generated proxy:

    constructor(_?: any) {
        return;
    }

    public toJSON(data?: any): object {
        return data;
    }

    public init(_?: any) {
        return;
    }
}