import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PerformanceStatementCareActivityApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/PerformanceStatementCareActivityApiAdapter";
import CareActivityContextProvider from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextProvider";
import PatientContextProvider from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextProvider";
import PerformanceStatementCareActivityStatusTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityStatusTypeSelectBox/PerformanceStatementCareActivityStatusTypeSelectBox";
import PerformanceStatementCareActivity from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/PerformanceStatementCareActivity";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import StaticHunSocialSecurityPerformanceStatementResources from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/StaticResources/StaticHunSocialSecurityPerformanceStatementResources";
import CareActivityId from "@Primitives/CareActivityId.g";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";

interface IPerformanceStatementCareActivityStatusDialogDependencies {
    performanceStatementCareActivityApiAdapter: PerformanceStatementCareActivityApiAdapter;
}

export interface IPerformanceStatementCareActivityStatusDialogProps {
    _dependencies?: IPerformanceStatementCareActivityStatusDialogDependencies;
    careActivityId: CareActivityId;
}

/** @screen */
@State.observer
class PerformanceStatementCareActivityStatusDialog extends React.Component<IPerformanceStatementCareActivityStatusDialogProps> {

    @State.observable.ref private performanceStatementCareActivity: PerformanceStatementCareActivity = null;
    @State.observable.ref private newValidity: YearMonth = null;
    @State.observable.ref private closeEvent = new TypedEvent<boolean>();

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.bound
    private async loadAsync() {
        const result = await this.props._dependencies.performanceStatementCareActivityApiAdapter.getPerformanceStatementCareActivityByIdAsync(this.props.careActivityId);
        this.setPerformanceStatementCareActivity(result);
    }

    @State.action.bound
    public async saveAsync() {
        const result = await this.props._dependencies.performanceStatementCareActivityApiAdapter.setPerformanceStatementCareActivityManualStatusAsync(this.props.careActivityId, this.performanceStatementCareActivity.rowVersion, this.newValidity);
        if (!result.hasValidationError) {
            this.closeEvent.emit(true);
        } else {
            State.runInAction(() => {
                this.performanceStatementCareActivity.validationResults = result.validationResults;
            });
        }
    }

    @State.action.bound
    public async validateAsync() {
        const result = await this.props._dependencies.performanceStatementCareActivityApiAdapter.validatePerformanceStatementCareActivityManualStatusAsync(this.props.careActivityId, this.performanceStatementCareActivity.rowVersion, this.newValidity);
        return result.value;
    }

    @State.bound
    private close() {
        this.closeEvent.emit(false);
    }

    @State.action.bound
    private setPerformanceStatementCareActivity(performanceStatementCareActivity: PerformanceStatementCareActivity) {
        this.performanceStatementCareActivity = performanceStatementCareActivity;
    }

    @State.action.bound
    public setNewValidity(newValidity: YearMonth) {
        this.newValidity = newValidity;
    }

    public render() {
        const resources = StaticHunSocialSecurityPerformanceStatementResources.PerformanceStatementCareActivityStatusDialog;

        return (
            this.performanceStatementCareActivity && (
                <CareActivityContextProvider careActivityId={this.props.careActivityId}>
                    <PatientContextProvider>
                        <UseCaseFrame
                            modalCloseEvent={this.closeEvent}
                            title={resources.Title}
                            modalSize="compact"
                            leftFooter={<Ui.Button automationId="closeButton" text={StaticWebAppResources.Common.Button.Close} onClick={this.close} visualStyle="link" />}
                            rightFooter={<Ui.SaveButton automationId="saveButton" visualStyle="primary" onClickAsync={this.saveAsync} />}
                        >
                            <Ui.GroupBox title={resources.ActualData}>
                                <PerformanceStatementCareActivityStatusTypeSelectBox
                                    label={resources.Type}
                                    value={this.performanceStatementCareActivity.actualNeakStatus.type}
                                    isReadOnly
                                    automationId="actualNeakStatusType"
                                />
                                <Ui.MonthPicker
                                    label={resources.ValidAt}
                                    value={this.performanceStatementCareActivity.actualNeakStatus.validAt}
                                    onChange={null}
                                    isReadOnly
                                    automationId="actualNeakStatusValidAt"
                                />
                            </Ui.GroupBox>
                            <Ui.GroupBox title={resources.NewData}>
                                <ValidationBoundary
                                    validationResults={this.performanceStatementCareActivity.validationResults}
                                    entityTypeName="PerformanceStatementCareActivity"
                                    onValidateAsync={this.validateAsync}
                                    validateOnMount
                                >
                                    <Ui.MonthPicker
                                        label={resources.NewValidAt}
                                        value={this.newValidity}
                                        onChange={this.setNewValidity}
                                        propertyIdentifier="ActualNeakStatus.ValidAt"
                                        automationId="newNeakStatusValidAt"
                                    />
                                </ValidationBoundary>
                            </Ui.GroupBox>
                        </UseCaseFrame>
                    </PatientContextProvider>
                </CareActivityContextProvider>)
        );
    }
}

export default connect(
    PerformanceStatementCareActivityStatusDialog,
    new DependencyAdapter<IPerformanceStatementCareActivityStatusDialogProps, IPerformanceStatementCareActivityStatusDialogDependencies>(c => ({
        performanceStatementCareActivityApiAdapter: c.resolve("PerformanceStatementCareActivityApiAdapter"),
    }))
);