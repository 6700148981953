import * as React from "react";
import SingleAccordionStore from "@CommonControls/SingleAccordion/SingleAccordionStore";
import { Icon } from "@CommonControls";
import Styles from "./SingleAccordion.less";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import InfoButton from "@CommonControls/InfoButton";

interface ISingleAccordionProps {
    multi?: boolean;
    title?: string;
    isOpen?: boolean;
    onClick?: () => void;
    automationId?: string;
    style?: React.CSSProperties;
    isOpenByDefault?: boolean;
    titleClassName?: string;
    info?: React.ReactNode;
}

@State.observer
export default class SingleAccordion extends React.Component<ISingleAccordionProps, undefined> {

    private store: SingleAccordionStore;
    private getOpenState = () => this.props.isOpen;

    constructor(props: ISingleAccordionProps) {
        super(props);

        this.store = new SingleAccordionStore(this.props.isOpenByDefault);

        if (props.isOpen === undefined) {
            this.getOpenState = () => this.store.isOpen;
        }
    }

    @State.bound
    private clickHandler() {
        this.store.paneClickHandler();
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    public render() {
        const isOpen = this.getOpenState();
        const classes = new CompositeClassName(Styles.header);
        const caretClasses = new CompositeClassName(Styles.caretIcon);
        const titleClasses = new CompositeClassName(Styles.title);
        titleClasses.addIf(!!this.props.titleClassName, this.props.titleClassName);
        caretClasses.addIf(isOpen, Styles.caretIconRotated);
        return (
            <div style={this.props.style} data-automation-id={this.props.automationId || undefined}>
                <div onClick={this.clickHandler} className={classes.classNames}>
                    <Icon iconName="caretRight" className={caretClasses.classNames} />
                    <p className={titleClasses.classNames}>{this.props.title}</p> {this.props.info && <InfoButton position="baseline" iconName="infoCircle" tooltipContent={this.props.info} />}
                </div>
                {isOpen &&
                    <div>
                        {this.props.children}
                    </div>
                }
            </div>
        );
    }
}