import React from "react";
import Styles from "./PageTitle.less";

export interface IPageTitleLabel {
    label: string;
}

export default class PageTitleLabel extends React.PureComponent<IPageTitleLabel> {
    public render() {
        return (
            <div className={Styles.label}>
                <label>{this.props.label}</label>
                <p>{this.props.children}</p>
            </div>
        );
    }
}