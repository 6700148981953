import FamilyDoctorDocumentPage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/FamilyDoctorDocumentListPanel/FamilyDoctorDocumentPage";
import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import standaloneRouteDefinitions from "./FamilyDoctorDocumentRoutes";

export default UseCaseDescriptorBuilder.create(
    HunEHealthInfrastructureUseCases.viewFamilyDoctorDocument,
    builder => {
        builder.standalone(b => b
            .routeDefinition(standaloneRouteDefinitions.familyDoctorDocument)
            .component(FamilyDoctorDocumentPage));
    }
    , false
);