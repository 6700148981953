import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import CareActivityId from "@Primitives/CareActivityId.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PrescriptionMasterDetailPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/PrescriptionListPanel/PrescriptionMasterDetailPanel";
import PrescriptionId from "@Primitives/PrescriptionId.g";
import prescriptionStandaloneRouteDefinitions, { IPrescriptionRouteParams } from "./PrescriptionRoutes";
import IOutpatientTreatmentRouteParams from "@HisPlatform/Application/Routes/IOutpatientTreatmentRouteParams";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";


interface IPrescriptionsStandaloneHostDependencies {
}

interface IPrescriptionsStandaloneHostProps extends IRoutingFrameContentProps {
    _dependencies?: IPrescriptionsStandaloneHostDependencies;
    _careActivityId?: CareActivityId;
}

class PrescriptionsStandaloneHost extends React.Component<IPrescriptionsStandaloneHostProps> {

    @State.computed
    private get careActivityId() {
        let careActivityId: CareActivityId = null;

        const careActivityIdString = (this.props.routingController.currentRoute.parameters as IOutpatientTreatmentRouteParams).careActivityId;
        if (careActivityIdString) {
            careActivityId = new CareActivityId(careActivityIdString);
        } else if (this.props._careActivityId) {
            careActivityId = this.props._careActivityId;
        }

        return careActivityId;
    }

    @State.computed
    private get prescriptionId() {
        let prescriptionId: PrescriptionId = null;

        const prescriptionIdString = (this.props.routingController.currentRoute.parameters as IPrescriptionRouteParams).prescriptionId;
        if (prescriptionIdString) {
            prescriptionId = new PrescriptionId(prescriptionIdString);
        }

        return prescriptionId;
    }

    @State.bound
    private onNavigate(prescriptionId: PrescriptionId) {
        this.props.routingController.replace(prescriptionStandaloneRouteDefinitions.prescriptions.makeRoute({careActivityId: this.careActivityId.value, prescriptionId: prescriptionId?.value}));
    }

    public render() {
        return (
            <PrescriptionMasterDetailPanel
                prescriptionId={this.prescriptionId}
                _careActivityId={this.careActivityId}
                onBack={this.props.routingController.goBack}
                onNavigateToPrescription={this.onNavigate}
            />
        );
    }
}

export default connect(
    PrescriptionsStandaloneHost,
    new DependencyAdapter<IPrescriptionsStandaloneHostProps, IPrescriptionsStandaloneHostDependencies>(() => {
        return {
        };
    }),
    new CareActivityContextAdapter<IPrescriptionsStandaloneHostProps>(c => ({
        _careActivityId: c.careActivityId
    }))
);