import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import IDoctor from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IDoctor";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import PractitionerId from "@Primitives/PractitionerId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import MedicalServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServiceStore";
import * as HisUi from "@HisPlatformControls";
import StaticWebAppResources from "@StaticResources";
import { IUniversalCodeSelectorProps } from "@HisPlatformControls/UniversalCodeSelector";
import IPractitioner from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IPractitioner";
import IAssistant from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IAssistant";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";
import EntityCollectionOwner from "@HisPlatform/BoundedContexts/Productivity/Api/Personalization/Enum/EntityCollectionOwner.g";

interface IPractitionerCodeSelectorDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    practitionerApiAdapter: PractitionerApiAdapter;
    localizationService: ILocalizationService;
}

interface IPractitionerCodeSelectorProps extends ICodeSelectorCommonProps<PractitionerId> {
    _dependencies?: IPractitionerCodeSelectorDependencies;
    isExternal?: boolean;
    showFavoritesAndGroup?: boolean;
    favoritesAndGroupPointOfCare?: PointOfCareId;
    filters?: FilterBase[];
}

@State.observer
class PractitionerCodeSelector extends React.Component<IPractitionerCodeSelectorProps> {
    @State.computed
    private get pointOfCareIds() {
        if (!this.props.filters) {
            return null;
        }

        const pointOfCareIdFilters = this.props.filters
            .filter(filter => filter instanceof ExplicitIdentifierFilter && filter.type === "PointOfCare")
            .map(filter => filter as ExplicitIdentifierFilter);

        return pointOfCareIdFilters.map(filter => new PointOfCareId(filter.value));
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.initializePointOfCareAsync(), this);
    }

    public componentDidUpdate(prevProps: IPractitionerCodeSelectorProps) {
        if (!this.props.filters) {
            return;
        }

        if (prevProps.filters !== this.props.filters) {
            dispatchAsyncErrors(this.initializePointOfCareAsync(), this);
        }
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const result = await this.props._dependencies.practitionerApiAdapter.quickSearchPractitionersAsync(
            this.props.isExternal,
            null,
            text,
            5,
            this.props.filters
        );

        if (result.operationWasSuccessful) {
            const ids = result.value.map(r => r);
            await this.props._dependencies.organizationReferenceDataStore.practitionerMap.ensureLoadedAsync(ids);
            return ids;
        }

        return null;
    }

    @State.bound
    private async initializePointOfCareAsync() {
        await this.props._dependencies.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync(this.pointOfCareIds);
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: MedicalServiceStore[]): Promise<IPagedItems<IPractitioner<PractitionerId>>> {

        const today = LocalDate.today();
        const isExternal = this.pointOfCareIds ? false : this.props.isExternal;
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;

        const results = await this.props._dependencies.practitionerApiAdapter.searchPractitionersAsync(
            null,
            today,
            isExternal,
            null,
            null,
            filterText,
            customOrdering,
            paging,
            null,
            this.props.filters
        );

        const practitioners = results?.value?.values;
        const practitionerIds = practitioners?.map(x => x.id);
        let mappedPractitioners = [] as Array<IPractitioner<PractitionerId>>;

        if (practitionerIds && practitionerIds.length > 0) {
            const doctors = (await this.props._dependencies.practitionerApiAdapter.getDoctorsByIdsAsync(practitionerIds)).value;

            mappedPractitioners = practitionerIds.map(x => {
                const doctor = doctors.find(y => y.id.value === x.value);
                if (!isNullOrUndefined(doctor)) {
                    return doctor;
                }
                const assistant = practitioners.find(y => y.id.value === x.value);
                if (!isNullOrUndefined(assistant)) {
                    return {
                        id: assistant.id,
                        name: assistant.baseData.name
                    } as IAssistant;
                }
                return null;
            }).filter(z => !isNullOrUndefined(z));
        }

        return {
            items: mappedPractitioners,
            totalCount: results.value.totalCount
        };
    }

    @State.bound
    private onComplexSearchSingleSelect(item: IPractitioner<PractitionerId>) {
        this.props.onChange(item.id);
    }

    @State.bound
    private onComplexSearchMultiSelect(items: Array<IPractitioner<PractitionerId>>) {
        this.props.onChange(items.map(item => item.id));
    }

    @State.bound
    private getNameValueString(value: any) {
        return this.props._dependencies.localizationService.localizePersonName(value);
    }

    @State.bound
    private async getDisplayValueAsync(value: IStringEntityId) {
        if (value instanceof PractitionerId) {
            const doctor = await this.props._dependencies.organizationReferenceDataStore.doctorMap.getOrLoadAsync(value as PractitionerId);
            if (!isNullOrUndefined(doctor)) {
                return <><b>{doctor.code}</b>{` ${this.props._dependencies.localizationService.localizePersonName(doctor.name)}`}</>;
            }
            const assistant = await this.props._dependencies.organizationReferenceDataStore.assistantMap.getOrLoadAsync(value as PractitionerId);
            if (!isNullOrUndefined(assistant)) {
                return <>{this.props._dependencies.localizationService.localizePersonName(assistant.name)}</>;
            }
            return <></>;
        } else {
            return null;
        }
    }

    @State.bound
    private getDisplayValue(value: PractitionerId) {
        const doctor = this.props._dependencies.organizationReferenceDataStore.doctorMap.get(value as PractitionerId);
        if (!isNullOrUndefined(doctor)) {
            return <><b>{doctor.code}</b>{` ${this.props._dependencies.localizationService.localizePersonName(doctor.name)}`}</>;
        }
        const assistant = this.props._dependencies.organizationReferenceDataStore.assistantMap.get(value as PractitionerId);
        if (!isNullOrUndefined(assistant)) {
            return <>{this.props._dependencies.localizationService.localizePersonName(assistant.name)}</>;
        }
        return <></>;
    }

    @State.bound
    private async getTextValueAsync(value: IStringEntityId) {
        if (value instanceof PractitionerId) {
            const doctor = await this.props._dependencies.organizationReferenceDataStore.doctorMap.getOrLoadAsync(value as PractitionerId);
            if (!isNullOrUndefined(doctor)) {
                return `${doctor.code} ${this.props._dependencies.localizationService.localizePersonName(doctor.name)}`;
            }
            const assistant = await this.props._dependencies.organizationReferenceDataStore.assistantMap.getOrLoadAsync(value as PractitionerId);
            if (!isNullOrUndefined(assistant)) {
                return this.props._dependencies.localizationService.localizePersonName(assistant.name);
            }
            return "";
        } else {
            return null;
        }
    }

    @State.bound
    private getComplexSearchEntitiesByIds(ids: PractitionerId[]) {
        return ids.map(i => {
            const doctor = this.props._dependencies.organizationReferenceDataStore.doctorMap.get(i);
            if (!isNullOrUndefined(doctor)) {
                return doctor;
            }
            const assistant = this.props._dependencies.organizationReferenceDataStore.assistantMap.get(i);
            if (!isNullOrUndefined(assistant)) {
                return assistant;
            }
            return null as IPractitioner<PractitionerId>;
        });
    }

    private get entityCollectionProps(): Partial<IUniversalCodeSelectorProps<PractitionerId, IDoctor>> {
        return this.props.showFavoritesAndGroup && {
            entityName: "Practitioner",
            ownerType: EntityCollectionOwner.PointOfCare,
            ownerId: this.props.favoritesAndGroupPointOfCare,
            currentPointOfCareId: this.props.favoritesAndGroupPointOfCare,
            getEntityDisplayValue: this.getDisplayValue,
            getEntityId: (id: PractitionerId) => id
        };
    }

    public render() {
        return (
            <HisUi.UniversalCodeSelector
                {...this.props}
                getDisplayValueAsync={this.getDisplayValueAsync}
                getTextValueAsync={this.getTextValueAsync}
                onQuickSearchAsync={this.quickSearchAsync}
                hasComplexSearch
                complexSearchLoadAsync={this.complexSearchLoadAsync}
                complexSearchModalTitle={StaticWebAppResources.Selectors.PractitionerSelector.Title}
                codeGetter={"code"}
                nameGetter={"name"}
                getComplexSearchEntitiesByIds={this.getComplexSearchEntitiesByIds}
                onComplexSearchSingleSelect={this.onComplexSearchSingleSelect}
                onComplexSearchMultiSelect={this.onComplexSearchMultiSelect}
                showMenuSections={this.props.showFavoritesAndGroup}
                getNameValueString={this.getNameValueString}

                {...this.entityCollectionProps}
            />
        );
    }
}

export default connect(
    PractitionerCodeSelector,
    new DependencyAdapter<IPractitionerCodeSelectorProps, IPractitionerCodeSelectorDependencies>((container) => {
        return {
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            practitionerApiAdapter: container.resolve("PractitionerApiAdapter"),
            localizationService: container.resolve("ILocalizationService")
        };
    })
);
