import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import { GetTypeNameWithPrefix } from "@HunEHealthInfrastructurePlugin/PluginHelper";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientId from "@Primitives/PatientId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import ICovidTestResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/PatientRegister/Model/ICovidTestResult";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IEhiResult from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiResult";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import EhiErrorSeverity from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiErrorSeverity";
import EhiToken from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiToken";

/* auto-inject-disable */
@Di.injectable()
export default class EhiCovidTestResultsApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("ICareClient")) private apiClient: Proxy.ICareClient,
    ) {
        super();
    }

    @State.bound
    public getCovidTestResultsAsync(ehiToken: EhiToken, patientId: PatientId, organizationUnitId: OrganizationUnitId) {
        return this.processOperationAsync(
            new SimpleStore<IEhiResult<ICovidTestResult[]>>(),
            async target => {
                const response = await this.apiClient.getEhiCovidTestResultsQueryAsync(CreateRequestId(), new Proxy.GetEhiCovidTestResultsControllerDto({
                    patientId,
                    ehiTokenDto: this.mapToken(ehiToken),
                    organizationUnitId: organizationUnitId
                }));

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    value: response.results.map(r => ({
                        covidStatus: r.covidStatus,
                        executedAt: r.executedAt,
                        testType: r.testType
                    } as ICovidTestResult)),
                    errors: response.errors?.map(e => {
                        return new EhiError(e.code, e.moduleName, e.message, e.ehiErrorSeverity as any as EhiErrorSeverity);
                    }) || []
                };
            }
        );
    }

    private mapToken(ehiToken: EhiToken): Proxy.EhiTokenDto {
        return new Proxy.EhiTokenDto({
            tokenValue: ehiToken.tokenValue,
            validFrom: ehiToken.validFrom,
            validTo: ehiToken.validTo
        });
    }
}