import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";

export interface IAddTextBlockDialogParams extends IModalParams {
    alreadyExistingTextBlockTypes: TextBlockTypeId[];
}

export interface IAddTextBlockDialogResult {
    textBlockTypeId: TextBlockTypeId;
}

export default class AddTextBlockDialogParams implements IAddTextBlockDialogParams {
    public static type = "AddTextBlockDialog";
    public get type() { return AddTextBlockDialogParams.type; }

    constructor(public alreadyExistingTextBlockTypes: TextBlockTypeId[]) { }
}
