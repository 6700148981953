import React, { useCallback } from "react";

interface IErrorBoxProps {
    title: string;
    message: string;
    isRefreshButton?: boolean;
    refreshButtonText?: string;
}

export default function ErrorBox(props: IErrorBoxProps) {
    return (
        <div style={{ border: "1px solid #f7c5d4", color: "#721c36", backgroundColor: "#f7d4de", padding: 16, margin: 16 }}>
            <h1>{props.title}</h1>
            <p>{props.message}</p>
            {props.isRefreshButton &&
            <a href="">{props.refreshButtonText}</a>}
        </div>
    );
}