import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { MedicalAlertCodeSelectorItem } from "@HisPlatformControls/MedicalAlertCodeSelector/MedicalAlertCodeSelectorItem";
import * as HisUi from "@HisPlatformControls";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import MedicalAlertsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/MedicalAlertsApiAdapter";
import IMedicalAlert from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalAlert";
import IMedicalConditionReferenceDataResolver from "@HisPlatform/BoundedContexts/Care/Services/Definition/MedicalConditionReferenceDataResolver/IMedicalConditionReferenceDataResolver";

interface IMedicalAlertCodeSelectorDependencies {
    medicalAlertsApiAdapter: MedicalAlertsApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
    medicalConditionReferenceDataResolver: IMedicalConditionReferenceDataResolver;
}

interface IMedicalAlertCodeSelectorProps extends ICodeSelectorCommonProps<MedicalAlertCodeSelectorItem> {
    _dependencies?: IMedicalAlertCodeSelectorDependencies;
}

@State.observer
class MedicalAlertCodeSelector extends React.Component<IMedicalAlertCodeSelectorProps> {

    private get dependencies() { return this.props._dependencies; }
    private get apiAdapter() { return this.dependencies.medicalAlertsApiAdapter; }
    private get referenceDataStore() { return this.dependencies.careReferenceDataStore.medicalAlerts; }
    private get medicalConditionReferenceDataResolver() { return this.dependencies.medicalConditionReferenceDataResolver; }

    @State.bound
    public async getDisplayValueAsync(value: MedicalAlertCodeSelectorItem) {
        return await this.medicalConditionReferenceDataResolver.getMedicalAlertNameAsync(value);
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const results = await this.apiAdapter.searchMedicalAlerts(
            text,
            10
        );

        if (results.operationWasSuccessful) {
            const medicalAlerts = results.value.map(x => {
                const textOfQuickSearch = `${x.name} (${x.code})`;
                if (x.id) {
                    return new MedicalAlertCodeSelectorItem({
                        id: x.id,
                        text: textOfQuickSearch
                    });
                }
                return null;
            });

            await this.referenceDataStore.ensureLoadedAsync(medicalAlerts.map(x => x.id));
            return medicalAlerts;
        }
        return null;
    }

    @State.bound
    private addNewItem(text: string): MedicalAlertCodeSelectorItem {
        return new MedicalAlertCodeSelectorItem({ text: text });
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: IMedicalAlert[]) {
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;

        const results = await this.apiAdapter.complexSearchMedicalAlerts(
            filterText,
            customOrdering,
            paging);

        await this.referenceDataStore.ensureLoadedAsync(results.value.items.map(value => value.id));

        return results.value;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: MedicalAlertCodeSelectorItem) {
        this.props.onChange({ id: item.id });
    }

    @State.bound
    private onComplexSearchMultiSelect(items: IMedicalAlert[]) {
        this.props.onChange(items.map(item => ({ id: item.id, text: item.code })));
    }
    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticCareResources.MedicalCondition.MedicalAlerts.Title,
            codeGetter: "code",
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect,
            hideAllgroup: true
        };
        return (
            <HisUi.UniversalCodeSelector
                {...props}
                isCreatable
                addNewItem={this.addNewItem}
            />
        );
    }
}

export default connect(
    MedicalAlertCodeSelector,
    new DependencyAdapter<IMedicalAlertCodeSelectorProps, IMedicalAlertCodeSelectorDependencies>((container) => {
        return {
            medicalAlertsApiAdapter: container.resolve("MedicalAlertsApiAdapter"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            medicalConditionReferenceDataResolver: container.resolve("IMedicalConditionReferenceDataResolver"),
        };
    })
);
