import State from "@Toolkit/ReactClient/Common/StateManaging";
import moment from "moment";

export default class AllergyIntoleranceAssignmentBase  {
    @State.observable.ref public createdAt: moment.Moment;

    @State.action.bound
    public initializeCreatedAtIfEmpty() {
        if (!this.createdAt) {
            this.createdAt = moment(moment.now());
        }
    }
}