import PerformanceReportOutputColumnType from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/PerformanceReportOutputColumnType.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class PerformanceReportOutputColumnStore {
    @State.observable public type: PerformanceReportOutputColumnType;

    @State.action.bound
    public setType(newValue: PerformanceReportOutputColumnType) {
        this.type = newValue;
    }
}