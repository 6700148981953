import React from "react";
import Icon, { IIconProps } from "@CommonControls/Icon";
import Styles from "./Icon.less";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";


interface ISpanWithIconProps extends IIconProps {
    verticalSpacing?: number;
    iconFillTypeClassName?: string;
    style?: React.CSSProperties;
    wrapperClassName?: string;
}


const SpanWithIcon: React.FC<ISpanWithIconProps> = props => {
    const { children, ...iconProps } = props;
    const spanStyle: React.CSSProperties = { ...props.style, lineHeight: props.verticalSpacing };
    return (
        <span className={combineClasses(props.iconName ? Styles.spanWithIcon : Styles.spanWithoutIcon, props.wrapperClassName)} data-automation-id={props.automationId || undefined} style={spanStyle}>
            {!!props.iconName && <Icon {...iconProps} className={props.iconFillTypeClassName} />}
            {children}
        </span>
    );
};

export default SpanWithIcon;