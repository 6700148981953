import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import PermissionDefinitionBuilder from "@PluginInterface/OperationAccessControl/PermissionDefinitionBuilder";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";
import { PermissionDefinitions } from "@HisPlatform/PermissionDefinitions/PermissionDefinitions.g";
import { HunEHealthInfrastructurePermissionDefinitions } from "./HunEHealthInfrastructurePermissionDefinitions.g";

export default function configureCarePermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry) {

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder
            .create(HunEHealthInfrastructurePermissionDefinitions.Care.CareRegister.ViewHunEligibilityCheckResults)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(HunEHealthInfrastructurePermissionDefinitions.Care.Care.ViewInstituteEReferralList).build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(HunEHealthInfrastructurePermissionDefinitions.Care.Care.ViewCovidStatusReport, PermissionDefinitions.Productivity.Worklist.ViewWorklists)
            .withPermissionScopesFor(
                PermissionDefinitions.Productivity.Worklist.ViewWorklists,
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType, ["EhiCovidStatusReportBased"])
            )
            .withAlias(null, PermissionScopeType.worklistType)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(HunEHealthInfrastructurePermissionDefinitions.Care.Care.EditCovidStatusReport)
            .withPermissionScope(
                PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare)
            )
            .build());
}
