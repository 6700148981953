import AllergyIntoleranceId from "@Primitives/AllergyIntoleranceId.g";
import InternationalNonproprietaryNameId from "@Primitives/InternationalNonproprietaryNameId.g";
import MedicationId from "@Primitives/MedicationId.g";
import SubstanceId from "@Primitives/SubstanceId.g";

export class AllergyIntoleranceCodeSelectorItem {
    public id: AllergyIntoleranceId | MedicationId | SubstanceId | InternationalNonproprietaryNameId;
    public name?: string;
    public code?: string;
    public text?: string;

    constructor({ id = null, name = "", code = "",  text = "" }: { id?: AllergyIntoleranceId | MedicationId | SubstanceId | InternationalNonproprietaryNameId,  name?: string, code?: string, text?: string }) {
        this.id = id;
        this.name = name;
        this.text = text;
        this.code = code;
    }
}