import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import PractitionerId from "@Primitives/PractitionerId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import {dispatchAsyncErrors} from "@Toolkit/CommonWeb/AsyncHelpers";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import {IOrderingState, IPagingState} from "@CommonControls/DataGrid/IDataGridProps";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IAssistant from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IAssistant";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import * as HisUi from "@HisPlatformControls";
import StaticWebAppResources from "@StaticResources";
import {IUniversalCodeSelectorProps} from "@HisPlatformControls/UniversalCodeSelector";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";
import PractitionerType from "@HisPlatform/BoundedContexts/Organization/Api/Practitioners/Enum/PractitionerType.g";

interface IAssistantSelectorDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    practitionerApiAdapter: PractitionerApiAdapter;
    localizationService: ILocalizationService;
}

interface IAssistantSelectorProps extends ICodeSelectorCommonProps<PractitionerId> {
    _dependencies?: IAssistantSelectorDependencies;
    isExternal?: boolean;
    filters?: FilterBase[];
}

@State.observer
class AssistantSelector extends React.Component<IAssistantSelectorProps> {
    @State.computed
    private get pointOfCareIds() {
        if (!this.props.filters) {
            return null;
        }

        const pointOfCareIdFilters = this.props.filters
            .filter(filter => filter instanceof ExplicitIdentifierFilter && filter.type === "PointOfCare")
            .map(filter => filter as ExplicitIdentifierFilter);

        return pointOfCareIdFilters.map(filter => new PointOfCareId(filter.value));
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.initializePointOfCareAsync(), this);
    }

    public componentDidUpdate(prevProps: IAssistantSelectorProps) {
        if (!this.props.filters) {
            return;
        }

        if (prevProps.filters !== this.props.filters) {
            dispatchAsyncErrors(this.initializePointOfCareAsync(), this);
        }
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const result = await this.props._dependencies.practitionerApiAdapter.searchAssistantsAsync(
            this.props.isExternal,
            text,
            5,
            this.props.filters
        );

        if (result.operationWasSuccessful) {
            const ids = result.value.map(r => r);
            await this.props._dependencies.organizationReferenceDataStore.assistantMap.ensureLoadedAsync(ids);
            return ids;
        }

        return null;
    }

    @State.bound
    private async initializePointOfCareAsync() {
        await this.props._dependencies.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync(this.pointOfCareIds);
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: IAssistant[]): Promise<IPagedItems<IAssistant>> {
        const today = LocalDate.today();
        const isExternal = this.pointOfCareIds ? false : this.props.isExternal;
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;

        const results = await this.props._dependencies.practitionerApiAdapter.searchPractitionersAsync(
            PractitionerType.Assistant,
            today,
            isExternal,
            filterText,
            null,
            null,
            customOrdering,
            paging,
            null,
            this.props.filters
        );

        const assistants = results?.value?.values?.map(x => {
            return {
                id: x.id,
                name: x.baseData.name
            } as IAssistant;
        });

        return {
            items: assistants,
            totalCount: results.value.totalCount
        };
    }

    @State.bound
    private onComplexSearchSingleSelect(item: IAssistant) {
        this.props.onChange(item.id);
    }

    @State.bound
    private onComplexSearchMultiSelect(items: IAssistant[]) {
        this.props.onChange(items.map(item => item.id));
    }

    @State.bound
    private getNameValueString(value: any) {
        return this.props._dependencies.localizationService.localizePersonName(value);
    }

    @State.bound
    private async getDisplayValueAsync(value: IStringEntityId) {
        if (value instanceof PractitionerId) {
            const item = await this.props._dependencies.organizationReferenceDataStore.assistantMap.getOrLoadAsync(value as PractitionerId);
            return this.props._dependencies.localizationService.localizePersonName(item.name);
        } else {
            return null;
        }
    }

    @State.bound
    private async getTextValueAsync(value: IStringEntityId) {
        if (value instanceof PractitionerId) {
            const item = await this.props._dependencies.organizationReferenceDataStore.assistantMap.getOrLoadAsync(value as PractitionerId);
            return this.props._dependencies.localizationService.localizePersonName(item.name, true);
        } else {
            return null;
        }
    }

    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            getTextValueAsync: this.getTextValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticWebAppResources.Selectors.AssistantSelector.Title,
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect,
            getNameValueString: this.getNameValueString
        } as IUniversalCodeSelectorProps<PractitionerId, IAssistant>;

        return (
            <HisUi.UniversalCodeSelector
                {...props}
            />
        );
    }
}

export default connect(
    AssistantSelector,
    new DependencyAdapter<IAssistantSelectorProps, IAssistantSelectorDependencies>((container) => {
        return {
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            localizationService: container.resolve("ILocalizationService"),
            practitionerApiAdapter: container.resolve("PractitionerApiAdapter"),
        };
    })
);
