import React, { useCallback } from "react";
import { iconNameType } from "@CommonControls/Icon";
import IMasterDetailState from "@CommonControls/Layout/IMasterDetailState";

export interface IMasterDetailDetailTabProps {
    _masterDetailState?: IMasterDetailState;

    title: string;
    tabName: string;
    tabTitle: React.ReactNode;
    iconName?: iconNameType;
    subTitle?: React.ReactNode;
    toolbar?: React.ReactNode;
    children?: React.ReactNode;
    disabled?: boolean;
    disablingTooltip?: string;

    footerLeftToolbar?: React.ReactNode;
    footerRightToolbar?: React.ReactNode;

    propertyPathRegexPattern?: string;
    automationId?: string;
    
    hideTitle?: boolean;
    tabPaneIconName?: iconNameType;
}


const MasterDetailDetailTab: React.FC<IMasterDetailDetailTabProps> = () => {
    return null;
};

export default MasterDetailDetailTab;