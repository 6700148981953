import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import IPropertyValueVersion from "@HisPlatform/Model/DomainModel/Common/IPropertyValueVersion";

export default class PropertyValueVersion<T> implements IPropertyValueVersion<T> {
    @State.observable.ref public validFrom?: LocalDate;
    @State.observable.ref public validTo?: LocalDate;
    @State.observable.ref public value: T;

    public get validity() {
        return new LocalDateRange(this.validFrom, this.validTo);
    }

    constructor(
        value: T,
        validFrom?: LocalDate,
        validTo?: LocalDate,
    ) {
        this.validFrom = validFrom;
        this.validTo = validTo;
        this.value = value;
    }

    @State.action.bound
    public setValidFrom(value: LocalDate) {
        this.validFrom = value;
    }

    @State.action.bound
    public setValidTo(value: LocalDate) {
        this.validTo = value;
    }

    @State.action.bound
    public setValue(value: T) {
        this.value = value;
    }

    @State.action.bound
    public setValidity(value: LocalDateRange) {
        this.validFrom = value.from;
        this.validTo = value.to;
    }
}
