exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ServiceAdministrationPanel_action-container_2ISku {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.ServiceAdministrationPanel_more-button_2jfj1 > div {\n  background: transparent;\n  border: none;\n}\n.ServiceAdministrationPanel_more-button_2jfj1 {\n  margin-top: 4px;\n}\n.ServiceAdministrationPanel_more-button_2jfj1 > div > div > svg {\n  height: 24px;\n}\n", ""]);

// exports
exports.locals = {
	"action-container": "ServiceAdministrationPanel_action-container_2ISku",
	"actionContainer": "ServiceAdministrationPanel_action-container_2ISku",
	"more-button": "ServiceAdministrationPanel_more-button_2jfj1",
	"moreButton": "ServiceAdministrationPanel_more-button_2jfj1"
};