import React, { useMemo, useCallback } from "react";
import * as Ui from "@CommonControls";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { isNullOrUndefined, arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import LateralityId from "@Primitives/LateralityId.g";
import ScalarObservationValueInput from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceAdministrationPanel/ScalarObservationValueInput";
import AbnormalityIndicator from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceAdministrationPanel/AbnormalityIndicator";
import BatchSelectionSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceAdministrationPanel/BatchSelection/BatchSelectionSelectBox";
import RequestedServiceBatchSelection from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceAdministrationPanel/BatchSelection/RequestedServiceBatchSelection";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ServiceAdministrationAction from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceAdministrationPanel/ServiceAdministrationAction";
import TextualObservationBody from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceAdministrationPanel/TextualObservationBody";
import Styles from "./ServiceObservationsPanel.less";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import RequestedServiceCommentBox from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceAdministrationPanel/RequestedServiceCommentBox";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import { IRowBody, IRowIndicatorStyle, IRowCheckState } from "@CommonControls/DataGrid/IDataGridProps";
import MedicalServiceColumn from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/MedicalServiceColumn/MedicalServiceColumn";
import LateralityColumn from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/LateralityColumn/LateralityColumn";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import RequestedServiceStateColumn from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/RequestedServiceStateColumn/RequestedServiceStateColumn";
import { formatReactString } from "@Toolkit/ReactClient/Common/LocalizedStrings";
import RequestedServiceState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceState.g";
import RequestedServiceAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceAction.g";

interface IServiceObservationsPanelProps {
    requestedServices: RequestedServiceStore[];
    selectedMedicalServiceIdSet: Set<string>;
    isAdministration: boolean;
    setContentSnapshot: (contentSnapshot: string) => void;

    onChecked: (isChecked: boolean, item: RequestedServiceStore) => void;

    batchSelection: RequestedServiceBatchSelection;
    onBatchSelectionChange: (newValue: RequestedServiceBatchSelection) => void;
    onBatchReceiveAsync: () => Promise<void>;
    onBatchCompleteAsync: () => Promise<void>;
    onBatchValidateAsync: () => Promise<void>;
    onSetAdministrativeActionAsync: (item: RequestedServiceStore, newAction: RequestedServiceAction) => Promise<void>;

    onSaveAsync: () => Promise<void>;
    withoutPageBox?: boolean;
    hasNoBottomMargin?: boolean;
    isLoading?: boolean;
}

const getClassNameByState = (row: any): IRowIndicatorStyle => {
    switch (row.state) {
        case RequestedServiceState.UnderRecording: return { className: Styles.created };
        case RequestedServiceState.Submitted: return { className: Styles.requested };
        case RequestedServiceState.RegisteredAsReceived: return { className: Styles.registeredAsReceived };
        case RequestedServiceState.ActivityCompleted: return { className: Styles.activityCompleted };
        case RequestedServiceState.NonValidatedCompleted: return { className: Styles.nonValidatedCompleted };
        case RequestedServiceState.ValidatedCompleted: return { className: Styles.validatedCompleted };
        case RequestedServiceState.DeletedByExecutorSideBeforeCompletion:
        case RequestedServiceState.DeletedByExecutorSideAfterCompletion:
            return { className: Styles.deleted };
        default: return null;
    }
};

function renderScalarObservationUnits(_value: any, row: RequestedServiceStore) {
    if (row.state === RequestedServiceState.DeletedByExecutorSideAfterCompletion ||
        row.state === RequestedServiceState.DeletedByExecutorSideBeforeCompletion) {
        return null;
    }

    const scalarDefinition = row.scalarObservationDefinition;
    if (scalarDefinition) {
        return scalarDefinition.units.shortCode;
    }

    return null;
}

function renderScalarObservationReferenceRange(_value: any, row: RequestedServiceStore) {
    if (row.state === RequestedServiceState.DeletedByExecutorSideAfterCompletion ||
        row.state === RequestedServiceState.DeletedByExecutorSideBeforeCompletion) {
        return null;
    }

    if (row.scalarObservation && row.scalarObservation.customLowerRange && row.scalarObservation.customUpperRange) {
        return `${row.scalarObservation.customLowerRange}-${row.scalarObservation.customUpperRange}`;
    }

    const scalarDefinition = row.scalarObservationDefinition;
    if (scalarDefinition && !isNullOrUndefined(scalarDefinition.lowReferenceBound) && !isNullOrUndefined(scalarDefinition.highReferenceBound)) {
        return `${scalarDefinition.lowReferenceBound}-${scalarDefinition.highReferenceBound}`;
    }

    return null;
}

/** @screen */
function ServiceObservationsPanel(props: IServiceObservationsPanelProps) {
    const resources = StaticCareResources.ServiceRequestManagement;

    const showLateralityColumn = useMemo(() => {
        return !arrayIsNullOrEmpty(props.requestedServices) &&
            props.requestedServices.some(s => !ValueWrapper.equals(s.lateralityId, LateralityId.NotApplicable));
    }, [props.requestedServices]);

    const showScalarObservationColumns = useMemo(() => {
        return !arrayIsNullOrEmpty(props.requestedServices) &&
            props.requestedServices.some(s => !!s.scalarObservationDefinition || !!s.categoryObservationDefinition);
    }, [props.requestedServices]);

    const getRowCheckState = useCallback((row: RequestedServiceStore) => {
        return {
            isChecked: !!props.selectedMedicalServiceIdSet &&
                props.selectedMedicalServiceIdSet.has(row.medicalServiceVersionSelector.id.value),
            isDisabled: false,
            isVisible: props.isAdministration
        } as IRowCheckState;
    }, [props.requestedServices, props.selectedMedicalServiceIdSet, props.isAdministration]);

    const {
        numberOfItemsToRegisterAsReceived,
        numberOfItemsToComplete,
        numberOfItemsToValidate,
        batchRegisterAsReceivedButtonLabel,
        batchCompleteButtonLabel,
        batchValidateButtonLabel
    } = useMemo(() => {
        const selectedItems = props.requestedServices.filter(row => !!props.selectedMedicalServiceIdSet &&
            props.selectedMedicalServiceIdSet.has(row.medicalServiceVersionSelector.id.value));

        const _numberOfItemsToRegisterAsReceived = selectedItems.filter(item => item.possibleActions.includes(RequestedServiceAction.RegisterAsReceived)).length;
        const _numberOfItemsToComplete = selectedItems.filter(item => item.possibleActions.includes(RequestedServiceAction.CompleteActivity)).length;
        const _numberOfItemsToValidate = selectedItems.filter(item => item.possibleActions.includes(RequestedServiceAction.ValidateDiagnosticReport)).length;

        return {
            numberOfItemsToRegisterAsReceived: _numberOfItemsToRegisterAsReceived,
            numberOfItemsToComplete: _numberOfItemsToComplete,
            numberOfItemsToValidate: _numberOfItemsToValidate,
            batchRegisterAsReceivedButtonLabel: formatString(resources.Administration.Button.BatchRegisterAsReceived, _numberOfItemsToRegisterAsReceived),
            batchCompleteButtonLabel: formatString(resources.Administration.Button.BatchPerformAction, _numberOfItemsToComplete),
            batchValidateButtonLabel: formatString(resources.Administration.Button.BatchValidateDocument, _numberOfItemsToValidate),
        };

    }, [props.requestedServices, props.selectedMedicalServiceIdSet]);

    const renderTextualObservationRowBody = useCallback((row: RequestedServiceStore): IRowBody => {
        if (!row.textualObservationDefinition || !row.textualObservation) {
            return null;
        }
        return {
            showCells: true,
            content: (
                <TextualObservationBody
                    row={row}
                    rowIndex={row.index}
                    onSetAdministrativeActionAsync={props.onSetAdministrativeActionAsync}
                />
            )
        } as IRowBody;
    }, [props.onSetAdministrativeActionAsync]);

    return (
        <Ui.GroupBox title={StaticCareResources.ServiceRequestManagement.Administration.Title}>
            {props.isAdministration && (
                <Ui.Flex smJustify="between">
                    <Ui.Flex.Item style={{ display: "flex", alignItems: "center" }}>
                        <BatchSelectionSelectBox
                            value={props.batchSelection}
                            onChange={props.onBatchSelectionChange}
                            automationId="__batchSelector"
                        />
                        <span>&nbsp;{formatReactString(resources.Administration.Label.NumberOfSelectedService, <b>{props.selectedMedicalServiceIdSet.size}</b>)}</span>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item>
                        <Ui.Button
                            text={batchRegisterAsReceivedButtonLabel}
                            onClickAsync={props.onBatchReceiveAsync}
                            disabled={numberOfItemsToRegisterAsReceived === 0}
                            automationId="__registerAsReceived"
                        />
                        <Ui.Button
                            text={batchCompleteButtonLabel}
                            onClickAsync={props.onBatchCompleteAsync}
                            disabled={numberOfItemsToComplete === 0}
                            automationId="__performAction"
                        />
                        <Ui.Button
                            text={batchValidateButtonLabel}
                            onClickAsync={props.onBatchValidateAsync}
                            disabled={numberOfItemsToValidate === 0}
                            automationId="__validateDocument"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            )}

            <Ui.DataGrid
                dataSource={props.requestedServices}
                rowHeight="43px"
                onRowChecked={props.onChecked}
                rowCheckState={getRowCheckState}
                rowBody={renderTextualObservationRowBody}
                getRowIndicatorStyle={getClassNameByState}
                automationId="__requestedServicesGrid"
            >
                <DataGridColumn dataGetter="index" header="#" width="40px" />
                <MedicalServiceColumn
                    dataGetter="medicalServiceVersionSelector"
                    header={resources.Administration.Label.MedicalService}
                    displayMode="alternativeName"
                    width="40%"
                    automationId="MedicalService"
                />
                <LateralityColumn
                    dataGetter="lateralityId"
                    header={resources.Administration.Label.Laterality}
                    isVisible={showLateralityColumn}
                    width="120px"
                    automationId="Laterality"
                />
                <DataGridColumn
                    header={resources.Administration.Label.ObservationValue}
                    width="40%"
                    isVisible={showScalarObservationColumns}
                    automationId="ObservationValue"
                >
                    <ScalarObservationValueInput />
                </DataGridColumn>
                <DataGridColumn
                    header={resources.Administration.Label.ObservationUnits}
                    onRenderCellValue={renderScalarObservationUnits}
                    width="80px"
                    isVisible={showScalarObservationColumns}
                    automationId="ObservationUnits"
                />

                <DataGridColumn
                    header={resources.Administration.Label.ReferenceRange}
                    onRenderCellValue={renderScalarObservationReferenceRange}
                    width="80px"
                    isVisible={showScalarObservationColumns}
                    automationId="ReferenceRange"
                />

                <DataGridColumn
                    header={resources.Administration.Label.Abnormality}
                    width="40px"
                    isVisible={showScalarObservationColumns}
                    automationId="Abnormality"
                >
                    <AbnormalityIndicator />
                </DataGridColumn>

                <DataGridColumn
                    header={resources.Administration.Label.Comment}
                    width="60%"
                    automationId="Comment"
                >
                    <RequestedServiceCommentBox />
                </DataGridColumn>

                <RequestedServiceStateColumn
                    dataGetter="state"
                    header={resources.Administration.Label.Status}
                    width="43px"
                    automationId="State"
                    visualMode="badge"
                />

                <DataGridColumn width="110px" automationId="StateChange">
                    <ServiceAdministrationAction onSetAdministrativeActionAsync={props.onSetAdministrativeActionAsync} />
                </DataGridColumn>
            </Ui.DataGrid>
        </Ui.GroupBox>
    );
}

export default State.observer(ServiceObservationsPanel);
