import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { isNullOrUndefined, arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import _ from "@HisPlatform/Common/Lodash";
import ColumnContainerContext from "@CommonControls/DataGrid/ColumnContainerContext";
import IColumnContainerContext from "@CommonControls/DataGrid/IColumnContainerContext";

interface IColumnContainerProps {
    onColumnsChanged: (columns: IDataGridColumnProps[]) => void;
}

export default class ColumnContainer extends React.PureComponent<IColumnContainerProps> {
    private columnMap = new Map<string, IDataGridColumnProps>();

    @State.bound
    private attachColumn(props: IDataGridColumnProps) {
        const key = `${props.id}`;

        if (!this.columnMap.has(key)) {
            this.columnMap.set(key, props);
            this.debouncedOnColumnsChanged();
        }
    }

    @State.bound
    private detachColumn(props: IDataGridColumnProps) {
        const key = `${props.id}`;

        if (this.columnMap.has(key)) {
            this.columnMap.delete(key);
            this.debouncedOnColumnsChanged();
        }
    }

    private debouncedOnColumnsChanged = _.debounce(() => {
        const orderedColumns = this.getIndexedAndOrderedColumnProps();
        this.props.onColumnsChanged(orderedColumns);
    }, 100);

    private getIndexedAndOrderedColumnProps(): Array<IDataGridColumnProps<any, any, any>> {
        const indexedColumns = Array.from(this.columnMap.values()).map((p, index) => {
            if (isNullOrUndefined(p.displayOrder)) {
                p.displayOrder = (index + 1) * 100;
            }
            return p;
        });
        return _.orderBy(indexedColumns, [i => i.displayOrder], "asc");
    }

    private columnContainerContext = { attach: this.attachColumn, detach: this.detachColumn } as IColumnContainerContext;

    public render() {

        if (isNullOrUndefined(this.props.children) || (Array.isArray(this.props.children) && arrayIsNullOrEmpty(this.props.children))) {
            return null;
        }
        return (
            <ColumnContainerContext.Provider value={this.columnContainerContext}>
                {this.props.children}
            </ColumnContainerContext.Provider>
        );
    }
}