import ConditionId from "@Primitives/ConditionId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import PermanentPrescriptionId from "@Primitives/PermanentPrescriptionId.g";
import LockingEntityStoreBase from "@Toolkit/CommonWeb/Model/LockingEntityStoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicationReferenceBase from "./MedicationReferenceBase";
import MedicationRequestCoverage from "./MedicationRequestCoverage";
import TemporaryPrescriptionPermission from "./TemporaryPrescriptionPermission";

export default class PermanentPrescription extends LockingEntityStoreBase<PermanentPrescriptionId> {
    @State.observable.ref public conditionId: ConditionId;
    @State.observable.ref public instruction: string;
    @State.observable.ref public insubstitutableReason: string;
    @State.observable public isEmergency: boolean = false;
    @State.observable public isForcedPrescription: boolean = false;
    @State.observable public isSubstitubable: boolean = true;
    @State.observable.ref public notes: string;
    @State.observable.ref public organizationUnitId: OrganizationUnitId;
    @State.observable.ref public registryNumber: string;
    public temporaryPermission: TemporaryPrescriptionPermission = new TemporaryPrescriptionPermission();
    public medicationRequestCoverage: MedicationRequestCoverage = new MedicationRequestCoverage();
    @State.observable.ref public medicationReferenceBase: MedicationReferenceBase;
}