import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import { getNavigationMenuItems } from "@HisPlatform/Packages/UserManagement/Configuration/NavigationMenuItems";
import ShowUserGroupsScreenAction from "@HisPlatform/Packages/UserManagement/FrontendActions/ShowUserGroupsScreenAction.g";
import ShowUsersScreenAction from "@HisPlatform/Packages/UserManagement/FrontendActions/ShowUsersScreenAction.g";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import NavigateToRouteActionProcessor from "@HisPlatform/Services/Implementation/ActionProcessing/NavigateToRouteActionProcessor";
import GlobalActionRegistry from "@Toolkit/ReactClient/ActionProcessing/GlobalActionRegistry";

class UserManagementPackageInitializer implements IPackageInitializer {

    public configureActionProcessor(registry: GlobalActionRegistry) {

        registry.register(
            ShowUsersScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => ApplicationRoutes.userManagement.makeRoute()));

        registry.register(
            ShowUserGroupsScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => ApplicationRoutes.userGroupManagement.makeRoute()));
    }

    public configureDynamicNavigation(registry: IDynamicNavigationRegistry): void {
        getNavigationMenuItems().forEach(navigationItem => registry.register(navigationItem));
    }
}

export default new UserManagementPackageInitializer();