import React, { useMemo } from "react";
import Icon, { iconNameType, iconVisualStyleType } from "@CommonControls/Icon";
import Styles from "./FieldValidationResult.less";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ResourceId from "@Primitives/ResourceId.g";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import { emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import _ from "@HisPlatform/Common/Lodash";

interface IFieldValidationResultProps {
    problems: IClientValidationProblem[];
    showMultipleProblems?: boolean;
}

interface IViewData {
    bodyClassName: string;
    iconName: iconNameType;
    iconStyle: iconVisualStyleType;
    message: string;
    originalMessage: string;
    key: string;
    dataAutomationId: string;
}

let uniqueId = 0;


const FieldValidationResult: React.FC<IFieldValidationResultProps> = props => {
    const dependencies = useDependencies(container => ({
        localizationService: container.resolve<ILocalizationService>("ILocalizationService")
    }));
    const localizationService = dependencies.localizationService;

    const viewData: IViewData[] = useMemo(() => {
        if (props.showMultipleProblems) {
            return props.problems.map(problem => mapProblemToViewData(problem, localizationService));
        }
        return props.problems?.length > 0 ? [mapProblemToViewData(props.problems[0], localizationService)] : emptyArray;
    }, [localizationService, props.problems]);

    return (
        <>
            {viewData.map(view => (
                <div className={view.bodyClassName} key={view.key} data-automation-id={view.dataAutomationId}>
                    <Icon iconName={view.iconName} visualStyle={view.iconStyle} size="compact" />
                    &nbsp;{!view.message || view.message === "(missing)" ? view.originalMessage : view.message}
                </div>
            ))}
        </>
    );
};

const mapProblemToViewData = (problem: IClientValidationProblem, localizationService: ILocalizationService) => {
    let message = problem.rawMessage ?? localizationService.localizeValidationMessage(new ResourceId(problem.message));

    const params = { ...problem.parameters, ...problem.resolvedParameters };
    if (!_.isEmpty(params)) {
        message = formatStringWithObjectParams(message, params);
    }

    switch (problem.severity) {
        case "error":
            return {
                bodyClassName: Styles.error,
                iconName: "invalid_outlined_circle",
                iconStyle: "error",
                message,
                originalMessage: problem.message,
                key: `${problem.message}-${uniqueId++}`,
                dataAutomationId: "fieldValidationResult_error"
            };
        case "warning":
            return {
                bodyClassName: Styles.warning,
                iconName: "exclamation",
                iconStyle: "warning",
                message,
                originalMessage: problem.message,
                key: `${problem.message}-${uniqueId++}`,
                dataAutomationId: "fieldValidationResult_warning"
            };
        default:
            throw new Error("Invalid validation problem severity: " + problem.severity);
    }
};

FieldValidationResult.defaultProps = {
    showMultipleProblems: false
};

export default FieldValidationResult;