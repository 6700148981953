import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import PerformedServiceListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/PerformedServiceListStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { getArrangePerformedServicesDto, getUpdatePerformedServicesDto } from "./PerformedServicesDtoMapper";
import PerformedServicesStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/PerformedServices/PerformedServicesStoreMapper";
import CareActivityId from "@Primitives/CareActivityId.g";
import { IPerformedServicesData } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/IPerformedServicesData";
import PossibleAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PossibleAction";
import IOperationResult from "@Toolkit/CommonWeb/ApiAdapter/IOperationResult";

@Di.injectable()
export default class PerformedServicesScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("ICareClient") private readonly apiClient: Proxy.ICareClient,
        @Di.inject("PerformedServicesStoreMapper") private readonly performedServicesStoreMapper: PerformedServicesStoreMapper,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader
    ) {
        super(referenceDataLoader);
    }

    public arrangePerformedServicesAsync(
        performedServices: PerformedServiceListStore
    ): Promise<IOperationResult<PerformedServiceListStore>> {
        return this.executeOperationAsync<PerformedServiceListStore, Proxy.ArrangePerformedServicesCommandResponse>(
            () => this.apiClient.arrangePerformedServicesCommandAsync(
                CreateRequestId(),
                getArrangePerformedServicesDto(performedServices),
                false
            ),
            async response => {
                const result = new PerformedServiceListStore();
                await this.performedServicesStoreMapper.applyToStoreWithExistingLockAndResolveValidationProblemsAsync(result, response, performedServices.lockInfo, false);
                return result;
            }
        );
    }

    public getPerformedServicesAsync(
        careActivityId: CareActivityId,
        requestLock: boolean,
        forceAcquireLock: boolean
    ): Promise<IOperationResult<IPerformedServicesData>> {
        return this.executeOperationAsync<IPerformedServicesData, Proxy.GetPerformedServicesScreenDataCommandResponse>(
            () => this.apiClient.getPerformedServicesScreenDataCommandAsync(
                CreateRequestId(),
                {
                    careActivityId: careActivityId,
                    requestLock: requestLock,
                    forceAcquireLock: forceAcquireLock
                } as Proxy.GetPerformedServicesScreenDataControllerDto,
                false
            ),
            async response => {
                const performedServices = new PerformedServiceListStore();
                await this.performedServicesStoreMapper.applyToStoreAndResolveValidationProblemsAsync(performedServices, response);
                return {
                    insuranceId: response.insuranceId,
                    performedServices: performedServices,
                    possibleActions: response.possibleActions.map(
                        i => new PossibleAction(i.identifier.careActivityId, i.identifier.serviceRequestId, i.actions)
                    )
                } as IPerformedServicesData;
            }
        );
    }

    public updatePerformedServicesAsync(
        performedServices: PerformedServiceListStore,
        releaseLock: boolean
    ): Promise<IOperationResult<PerformedServiceListStore>> {
        return this.executeOperationAsync<PerformedServiceListStore, Proxy.UpdatePerformedServicesCommandResponse>(
            () => this.apiClient.updatePerformedServicesCommandAsync(
                CreateRequestId(),
                getUpdatePerformedServicesDto(performedServices, releaseLock),
                false
            ),
            async response => {
                const result = new PerformedServiceListStore();
                await this.performedServicesStoreMapper.applyToStoreWithExistingLockAndResolveValidationProblemsAsync(result, response, performedServices.lockInfo, releaseLock);
                return result;
            }
        );
    }
}