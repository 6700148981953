import React, { useLayoutEffect, useRef } from "react";
import * as Styles from "./ToolbarLayout.less";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import ResizeObserver from "resize-observer-polyfill";
import ReactDOM from "react-dom";

export interface IToolbarLayoutProps {
    containerClassName?: string;
    containerStyle?: React.CSSProperties;

    body?: React.ReactNode;
    children?: React.ReactNode;
    bodyClassName?: string;

    topToolbar?: React.ReactNode;
    topToolbarHeight?: string | number;
    topToolbarClassName?: string;
    isTopToolbarVisible?: boolean;

    bottomToolbar?: React.ReactNode;
    bottomToolbarHeight?: string | number;
    bottomToolbarClassName?: string;
    isBottomToolbarVisible?: boolean;

    onBodyMeasured?: (width: number, height: number) => void;
}

function ToolbarLayout(props: IToolbarLayoutProps): React.ReactElement {

    const bodyRef = useRef<HTMLDivElement>();
    useLayoutEffect(() => {
        let observer: ResizeObserver = null;
        if (props.onBodyMeasured) {
            
            observer = new ResizeObserver((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
                const bodyDom = ReactDOM.findDOMNode(bodyRef.current);
                const modifiedBody = entries.find(e => e.target == bodyDom);
                if (modifiedBody) {
                    props.onBodyMeasured(modifiedBody.contentRect.width, modifiedBody.contentRect.height);
                }
            });

            observer.observe(ReactDOM.findDOMNode(bodyRef.current) as Element);
        }

        return () => {
            observer?.disconnect();
        };
    });

    return (
        <div className={combineClasses(Styles.layout, props.containerClassName)} style={props.containerStyle}>
            {props.topToolbar && <div className={combineClasses(Styles.topToolbar, props.topToolbarClassName)} style={{ flexBasis: props.topToolbarHeight, display: props.isTopToolbarVisible === false ? "none" : null }}>{props.topToolbar}</div>}
            <div className={combineClasses(Styles.workingArea, props.bodyClassName)} ref={bodyRef}>{props.body || props.children}</div>
            {props.bottomToolbar && <div className={combineClasses(Styles.bottomToolbar, props.bottomToolbarClassName)} style={{ flexBasis: props.bottomToolbarHeight, display: props.isBottomToolbarVisible === false ? "none" : null }}>{props.bottomToolbar}</div>}
        </div>
    );
}

const reactElement = ToolbarLayout as React.FC<IToolbarLayoutProps>;
reactElement.defaultProps = {
    bottomToolbarHeight: 48,
    topToolbarHeight: 48,
    isBottomToolbarVisible: true,
    isTopToolbarVisible: true
};

export default ToolbarLayout;