import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiCovidSymptomsSeverityId from "@Primitives/EhiCovidSymptomsSeverityId.g";
import EhiCovidStatusReportId from "@Primitives/EhiCovidStatusReportId.g";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import CareActivityId from "@Primitives/CareActivityId.g";
import EhiCovidStatusReportStatus from "@Primitives/EhiCovidStatusReportStatus";
import EhiCovidBreathingStatusId from "@Primitives/EhiCovidBreathingStatusId.g";
import EhiCovidVentilationStatusId from "@Primitives/EhiCovidVentilationStatusId.g";
import EhiCovidSymptomStatusId from "@Primitives/EhiCovidSymptomStatusId.g";
import EhiCovidUrineAmountId from "@Primitives/EhiCovidUrineAmountId.g";
import CloneableLockingEntityStoreBase from "@Toolkit/CommonWeb/Model/CloneableLockingEntityStoreBase";
import moment from "moment";

export default class EhiCovidStatusReportStore extends CloneableLockingEntityStoreBase<EhiCovidStatusReportStore, EhiCovidStatusReportId> implements IDirtyChecked {
    public careActivityId: CareActivityId;
    public status: EhiCovidStatusReportStatus;

    @State.observable public bodyTemperature: number = null;
    @State.observable public respiratoryRate: number = null;
    @State.observable.ref public statusReportAt: moment.Moment = null;
    @State.observable public bloodPressureSystolic: number = null;
    @State.observable public bloodPressureDiastolic: number = null;
    @State.observable public pulse: number = null;
    @State.observable public bloodSugar: number = null;
    @State.observable.ref public pneumoniaConfirmedRadiological?: boolean = null;
    @State.observable.ref public pneumoniaConfirmedClinical?: boolean = null;
    @State.observable.ref public symptomsSeverityId: EhiCovidSymptomsSeverityId = null;
    @State.observable.ref public breathingStatusId: EhiCovidBreathingStatusId = null;
    @State.observable.ref public ventilationStatusId: EhiCovidVentilationStatusId = null;
    @State.observable.ref public chillsSymptomStatusId: EhiCovidSymptomStatusId = null;
    @State.observable.ref public generalWeaknessSymptomStatusId: EhiCovidSymptomStatusId = null;
    @State.observable.ref public sputumRemovalSymptomStatusId: EhiCovidSymptomStatusId = null;
    @State.observable.ref public coughSymptomStatusId: EhiCovidSymptomStatusId = null;
    @State.observable.ref public headacheSymptomStatusId: EhiCovidSymptomStatusId = null;
    @State.observable.ref public muscleOrJointPainSymptomStatusId: EhiCovidSymptomStatusId = null;
    @State.observable.ref public vomitSymptomStatusId: EhiCovidSymptomStatusId = null;
    @State.observable.ref public diarrheaSymptomStatusId: EhiCovidSymptomStatusId = null;
    @State.observable.ref public soreThroatSymptomStatusId: EhiCovidSymptomStatusId = null;
    @State.observable.ref public urineAmountId: EhiCovidUrineAmountId = null;
    @State.observable.ref public otherSymptoms: string = null;
    @State.observable.ref public notes: string = null;

    public readonly validationPropertyPrefix = "BaseData";

    @State.action.bound
    public setBodyTemperature(bodyTemperature: number) {
        this.bodyTemperature = bodyTemperature;
    }

    @State.action.bound
    public setRespiratoryRate(respiratoryRate: number) {
        this.respiratoryRate = respiratoryRate;
    }

    @State.action.bound
    public setStatusReportAt(statusReportAt: moment.Moment) {
        this.statusReportAt = statusReportAt;
    }

    @State.action.bound
    public setBloodPressureSystolic(bloodPressureSystolic: number) {
        this.bloodPressureSystolic = bloodPressureSystolic;
    }
    @State.action.bound
    public setBloodPressureDiastolic(bloodPressureDiastolic: number) {
        this.bloodPressureDiastolic = bloodPressureDiastolic;
    }

    @State.action.bound
    public setPulse(pulse: number) {
        this.pulse = pulse;
    }

    @State.action.bound
    public setBloodSugar(bloodSugar: number) {
        this.bloodSugar = bloodSugar;
    }

    @State.action.bound
    public setPneumoniaConfirmedRadiological(pneumoniaConfirmedRadiological: boolean) {
        this.pneumoniaConfirmedRadiological = pneumoniaConfirmedRadiological;
    }

    @State.action.bound
    public setPneumoniaConfirmedClinical(pneumoniaConfirmedClinical: boolean) {
        this.pneumoniaConfirmedClinical = pneumoniaConfirmedClinical;
    }

    @State.action.bound
    public setSymptomsSeverityId(symptomsSeverityId: EhiCovidSymptomStatusId) {
        this.symptomsSeverityId = symptomsSeverityId;
    }

    @State.action.bound
    public setBreathingStatusId(breathingStatusId: EhiCovidBreathingStatusId) {
        this.breathingStatusId = breathingStatusId;
    }

    @State.action.bound
    public setVentilationStatusId(ventilationStatusId: EhiCovidVentilationStatusId) {
        this.ventilationStatusId = ventilationStatusId;
    }

    @State.action.bound
    public setChillsSymptomStatusId(chillsSymptomStatusId: EhiCovidSymptomStatusId) {
        this.chillsSymptomStatusId = chillsSymptomStatusId;
    }

    @State.action.bound
    public setGeneralWeaknessSymptomStatusId(generalWeaknessSymptomStatusId: EhiCovidSymptomStatusId) {
        this.generalWeaknessSymptomStatusId = generalWeaknessSymptomStatusId;
    }

    @State.action.bound
    public setSputumRemovalSymptomStatusId(sputumRemovalSymptomStatusId: EhiCovidSymptomStatusId) {
        this.sputumRemovalSymptomStatusId = sputumRemovalSymptomStatusId;
    }

    @State.action.bound
    public setCoughSymptomStatusId(coughSymptomStatusId: EhiCovidSymptomStatusId) {
        this.coughSymptomStatusId = coughSymptomStatusId;
    }

    @State.action.bound
    public setHeadacheSymptomStatusId(headacheSymptomStatusId: EhiCovidSymptomStatusId) {
        this.headacheSymptomStatusId = headacheSymptomStatusId;
    }

    @State.action.bound
    public setMuscleOrJointPainSymptomStatusId(muscleOrJointPainSymptomStatusId: EhiCovidSymptomStatusId) {
        this.muscleOrJointPainSymptomStatusId = muscleOrJointPainSymptomStatusId;
    }

    @State.action.bound
    public setVomitSymptomStatusId(vomitSymptomStatusId: EhiCovidSymptomStatusId) {
        this.vomitSymptomStatusId = vomitSymptomStatusId;
    }

    @State.action.bound
    public setDiarrheaSymptomStatusId(diarrheaSymptomStatusId: EhiCovidSymptomStatusId) {
        this.diarrheaSymptomStatusId = diarrheaSymptomStatusId;
    }

    @State.action.bound
    public setSoreThroatSymptomStatusId(soreThroatSymptomStatusId: EhiCovidSymptomStatusId) {
        this.soreThroatSymptomStatusId = soreThroatSymptomStatusId;
    }

    @State.action.bound
    public setUrineAmountId(urineAmountId: EhiCovidUrineAmountId) {
        this.urineAmountId = urineAmountId;
    }

    @State.action.bound
    public setOtherSymptoms(otherSymptoms: string) {
        this.otherSymptoms = otherSymptoms;
    }

    @State.action.bound
    public setNotes(notes: string) {
        this.notes = notes;
    }

    protected get vPropertyNamesExcludedFromDirtyCheck(): string[] {
        return ["_dirtyChecker",
        "isNew",
        "operationInfo",
        "validationResults",
        "lockInfo"
        ];
    }
}
