import config from "@Config";
import StaticSurgeryResources from "@HisPlatform/BoundedContexts/Surgery/StaticResources/StaticSurgeryResources";
import { ReportingToolbar } from "@HisPlatform/Components/HisPlatformControls";
import HomeMenu from "@HisPlatform/Packages/Care/Components/HomeMenu/HomeMenu";
import ShowSurgeryListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowSurgeryListScreenAction.g";
import ShowSurgeryTypeDefinitionsScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowSurgeryTypeDefinitionsScreenAction.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import NavMenuActionItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuActionItem";
import NavMenuCustomItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuCustomItem";
import NavMenuGroupItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuGroupItem";

export function getNavigationMenuItems(): INavMenuItem[] {
    
    const leftTopLevelMenuItems: INavMenuItem[] = [
        NavMenuCustomItem.createTopLevel("HomeMenu", HomeMenu, "left", 2),
        NavMenuGroupItem.createTopLevel(
            "ReportingToolbar",
            {
                side: "left",
                automationId: "reporting",
                order: 3,
                iconNameType: "document_statistics"
            }
        )
    ];
    
    const rightTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const childMenuItems: INavMenuItem[] = [
        NavMenuCustomItem.createChild("PlatformReportingItems", "ReportingToolbar", ReportingToolbar, 1)
    ];

    if(config.testFeatures.surgeryEnabled) {
        childMenuItems.push(
            NavMenuActionItem.createChildWithPermissionOperationCheck(
                "SurgeryTypeDefinitions",
                "Settings",
                new ShowSurgeryTypeDefinitionsScreenAction(),
                "SurgeryTypeDefinitions",
                {
                    automationId: "surgeryTypeDefinitions",
                    text: StaticSurgeryResources.SurgeryTypeDefinitionList.Title,
                    order: 13
                }
            )
        );

        leftTopLevelMenuItems.push(
            NavMenuActionItem.createTopLevel(
                "SurgeryList",
                new ShowSurgeryListScreenAction(),
                {
                    automationId: "surgeryList",
                    side: "left",
                    text: StaticWebAppResources.Navbar.ViewSurgeryList,
                    order: 7
                }
            )
        );
    }

    return [...leftTopLevelMenuItems, ...rightTopLevelMenuItems , ...childMenuItems];
}