import Di from "@Di";
import EhiTokenProvider from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Services/EhiTokenProvider/EhiTokenProvider";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import EhiOrganizationUnitProviderService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Organization/Services/Implementation/EhiOrganizationUnitProviderService";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import EhiMedicalConditionService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Services/EhiMedicalConditionService";
import IEhiMedicalConditionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/IEhiMedicalConditionApiAdapter";

@Di.injectable()
export default class EhiMedicalConditionServiceFactory {

    constructor(
        @Di.inject("EhiTokenProvider") private readonly ehiTokenProvider: EhiTokenProvider,
        @Di.inject("EhiOrganizationUnitProviderService") private readonly ehiOrganizationUnitProviderService: EhiOrganizationUnitProviderService,
        @Di.inject("IModalService") private readonly modalService: IModalService,
        @Di.inject("UserContext") private readonly userContext: UserContext
    ) { }

    public getEhiMedicalConditionService(ehiMedicalConditionApiAdapter: IEhiMedicalConditionApiAdapter) {
        return new EhiMedicalConditionService(
            ehiMedicalConditionApiAdapter,
            this.ehiTokenProvider,
            this.ehiOrganizationUnitProviderService,
            this.userContext,
            this.modalService
        );
    }
}
