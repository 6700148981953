import React from "react";
import ITokenSettingsPanelRegistry, { ITokenSettingsPanelPropsBase } from "@PluginInterface/BoundedContexts/DocumentManagement/TokenSettingsPanelRegistry/ITokenSettingsPanelRegistry";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { TableTokenValueFormatterSettingOrderCell } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TokenSettingsPanels/TableTokenValueFormatterSettingOrderCell";
import { TableTokenValueFormatterSettingHeadingCell } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TokenSettingsPanels/TableTokenValueFormatterSettingHeadingCell";
import TableTokenValueFormatterSettingStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TableTokenValueFormatterSettingStore";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import TokenSettingsPanelRegistry, { ITokenSettings } from "@HisPlatform/BoundedContexts/DocumentManagement/Services/TokenSettingsPanelRegistry/TokenSettingsPanelRegistry";
import ITemplateToken from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateToken";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import { IRowCheckState } from "@CommonControls/DataGrid/IDataGridProps";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { TableTokenValueFormatterSettingColumnWidthCell } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TokenSettingsPanels/TableTokenValueFormatterSettingColumnWidthCell";
import { nullFunction } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface ITableTokenValueFormatterSettingsPanelDependencies {
    tokenSettingsPanelRegistry: TokenSettingsPanelRegistry;
}

interface ITableTokenValueFormatterSettingsPanelProps extends ITokenSettingsPanelPropsBase<Proxy.TableTokenValueFormatterSettingsDto> {
    _dependencies?: ITableTokenValueFormatterSettingsPanelDependencies;
}

/** @screen */
@State.observer
class TableTokenValueFormatterSettingsPanel extends React.Component<ITableTokenValueFormatterSettingsPanelProps> {

    @State.observable.ref public isHeaderBold: boolean = true;
    @State.observable.ref public alignment: string = null;
    @State.observable.ref public tableTokenValueFormatterSettings: TableTokenValueFormatterSettingStore[] = [];
    @State.observable.ref private selectedRow: TableTokenValueFormatterSettingStore = null;

    private getAlignmentItems() {
        return [
            {
                text: StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.TextAlignments.Left,
                value: "left"
            } as ISelectBoxItem<string>,
            {
                text: StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.TextAlignments.Right,
                value: "right"
            } as ISelectBoxItem<string>,
            {
                text: StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.TextAlignments.Center,
                value: "center"
            } as ISelectBoxItem<string>
        ];
    }

    public componentDidMount() {
        this.initializePanel();
        if (this.tableTokenValueFormatterSettings.length > 0) {
            this.selectRow(this.tableTokenValueFormatterSettings[0]);
        }
    }

    @State.action
    private initializePanel() {
        const tableConfiguration = Proxy.TableTokenValueFormatterConfigurationDto.fromJS(JSON.parse(this.props.tokenFormatterConfiguration));
        this.tableTokenValueFormatterSettings = tableConfiguration.columnConfigurations.map(i => {
            const currentColumnSetting = this.props.tokenFormatterSettings?.columnSettings?.find(c => c.columnId.value === i.columnId.value);

            const item = new TableTokenValueFormatterSettingStore();
            item.id = i.columnId;
            item.configHeading = i.heading;
            item.heading = !!currentColumnSetting?.heading ? currentColumnSetting?.heading : i.heading;
            item.order = currentColumnSetting?.order ?? i.defaultOrder;
            item.visible = currentColumnSetting?.visible ?? true;
            item.columnWidth = currentColumnSetting?.columnStyle.columnWidth ?? "*";
            item.tokenFormatter = i.tokenFormatter;
            item.tokenFormatterConfiguration = i.tokenFormatterConfiguration;
            item.tokenFormatterSettingsType = i.tokenFormatterSettingsType;
            item.tokenFormatterSettings = currentColumnSetting?.tokenFormatterSettings && Proxy.FormatterSettingBaseDto.fromJS(JSON.parse(currentColumnSetting?.tokenFormatterSettings));
            item.onSetFormatValue = this.setFormatValue;
            return item;
        });

        this.isHeaderBold = this.props.tokenFormatterSettings?.columnSettings?.[0]?.columnStyle.isHeaderBold ?? true;
        this.alignment = this.props.tokenFormatterSettings?.columnSettings?.[0]?.columnStyle.alignment ?? "left";
    }

    @State.action.bound
    private selectRow(row: TableTokenValueFormatterSettingStore) {
        this.selectedRow = row;
    }

    public render() {
        return (
            <>
                <Ui.DataGrid
                    dataSource={this.tableTokenValueFormatterSettings}
                    hasHeader={true}
                    fixedLayout
                    onRowChecked={this.checkRow}
                    rowCheckState={this.rowCheckState}
                    isSelectable
                    onSelectedRowChange={this.selectRow}
                    selectedRow={this.selectedRow}
                    automationId="tableTokenValueFormatterSettingsGrid"
                >
                    <DataGridColumn
                        dataGetter={"order"}
                        header={StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.Order}
                        width={60}
                    >
                        <TableTokenValueFormatterSettingOrderCell />
                    </DataGridColumn>
                    <DataGridColumn
                        dataGetter={"heading"}
                        header={StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.Heading}
                    >
                        <TableTokenValueFormatterSettingHeadingCell />
                    </DataGridColumn>
                    <DataGridColumn
                        dataGetter={"columnWidth"}
                        header={StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.ColumnWidth}
                        width={80}
                    >
                        <TableTokenValueFormatterSettingColumnWidthCell />
                    </DataGridColumn>
                </Ui.DataGrid>

                {this.selectedRow &&
                    <Ui.GroupBox title={this.selectedRow.configHeading} hasBorder>
                        {this.props._dependencies!.tokenSettingsPanelRegistry.render(
                            {
                                tokenFormatterSettings: this.selectedRow.tokenFormatterSettings,
                                providerParameterSettings: null,
                                onSettingsChange: this.selectedRow.setTokenFormatterSettings,
                            } as ITokenSettings,
                            {
                                tokenFormatterSettingsType: this.selectedRow.tokenFormatterSettingsType,
                                tokenFormatter: this.selectedRow.tokenFormatter,
                                tokenFormatterConfiguration: this.selectedRow.tokenFormatterConfiguration,
                                providerParameterConfiguration: null
                            } as ITemplateToken)
                        }
                    </Ui.GroupBox>
                }

                <Ui.CheckBox
                    value={this.isHeaderBold}
                    onChange={this.setIsHeaderBold}
                    label={StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.BoldHeading}
                    automationId="isHeaderBoldCheckBox"
                />
                <Ui.SelectBox
                    value={this.alignment}
                    items={this.getAlignmentItems()}
                    onChange={this.setAlignment}
                    label={StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.TextAlignment}
                    automationId="alignmentSelectBox"
                />

            </>
        );
    }

    @State.action.bound
    public setAlignment(newValue: string) {
        this.alignment = newValue;
        this.setFormatValue();
    }

    @State.action.bound
    public setIsHeaderBold(newValue: boolean) {
        this.isHeaderBold = newValue;
        this.setFormatValue();
    }

    @State.action.bound
    private checkRow(isChecked: boolean, row: TableTokenValueFormatterSettingStore) {
        row.visible = isChecked;
        this.setFormatValue();
    }

    @State.bound
    private rowCheckState(row: TableTokenValueFormatterSettingStore, rowId: React.ReactText, rowIndex: number): IRowCheckState {
        return { isChecked: row.visible, isVisible: true, isDisabled: false } as IRowCheckState;
    }

    @State.bound
    private setFormatValue() {
        const columnSettingsDto: Proxy.ColumnSettingsDto[] = this.tableTokenValueFormatterSettings.map(i => {
            const columnSetting = new Proxy.ColumnSettingsDto();
            columnSetting.columnId = new Proxy.ColumnId2({ value: i.id.value } as Proxy.IColumnId2);
            columnSetting.heading = !!i.heading ? i.heading : null;
            columnSetting.order = i.order;
            columnSetting.visible = i.visible;
            columnSetting.tokenFormatterSettings = i.tokenFormatterSettings && JSON.stringify(i.tokenFormatterSettings);

            const columnStyle = new Proxy.ColumnStyleDto2();
            columnStyle.isHeaderBold = this.isHeaderBold;
            columnStyle.alignment = this.alignment;
            columnStyle.columnWidth = i.columnWidth;
            columnSetting.columnStyle = columnStyle;

            return columnSetting;
        });

        this.props.onSettingsChange(new Proxy.TableTokenValueFormatterSettingsDto({
            columnSettings: columnSettingsDto
        }), null);
    }
}

export default connect(
    TableTokenValueFormatterSettingsPanel,
    new DependencyAdapter<ITableTokenValueFormatterSettingsPanelProps, ITableTokenValueFormatterSettingsPanelDependencies>(c => ({
        tokenSettingsPanelRegistry: c.resolve<TokenSettingsPanelRegistry>("ITokenSettingsPanelRegistry")
    }))
);