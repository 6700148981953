import AccessRuleBase from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/AccessRuleBase";
import PermissionDefinitionsStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/PermissionDefinitionsStore";
import IPermissionDefinition from "@PluginInterface/OperationAccessControl/IPermissionDefinition";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class PermissionAssignmentAccessRule extends AccessRuleBase {
    public isProhibition: boolean;
    @State.observable.ref public permissionStore: PermissionDefinitionsStore;

    public constructor(isNew: boolean) {
        super(isNew);
        this.permissionStore = new PermissionDefinitionsStore();
    }

    @State.action.bound
    public setPermissions(permissions: IPermissionDefinition[]) {
        this.permissionStore.setDefinitions(permissions);
    }

    @State.action.bound
    public addPermissions(permissions: IPermissionDefinition[]) {
        this.permissionStore.addDefinitions(permissions);
    }
}