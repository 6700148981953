import React from "react";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import FormDetailPanel from "@Toolkit/FormEngine/Panels/FormDetailPanel/FormDetailPanel";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { provideCareActivityBasedFormDetailPanelStore, useCareActivityBasedFormDetailPanelStore } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareFormDetailPanel/CareActivityBasedFormDetailPanelStoreProvider";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PatientId from "@Primitives/PatientId.g";

export interface ICareActivityBasedFormDetailPanelProps {
    selectedId: FormInstanceId | null;
    onSaveAsync: () => Promise<void>;
    onUseCaseChange: (selectedId: string, useCaseState: INDataUseCaseState) => void;
    onClose: () => void;
    readonly: boolean;
    title: string;
    useCaseIdentifier: string;

    _careActivityId?: CareActivityId;
    _patientId?: PatientId;
    _pointOfCareId?: PointOfCareId;

    formDefinitionType: string;
}

/** @screen */
function CareActivityBasedFormDetailPanel(props: ICareActivityBasedFormDetailPanelProps) {
    const store = useCareActivityBasedFormDetailPanelStore();

    if (isNullOrUndefined(store.possibleFormDefinitions) || store.possibleFormDefinitions.length === 0) {
        return null;    
    }

    return (
        <FormDetailPanel 
            action={null}
            screenState={null}
            selectedId={props.selectedId}
            onSaveAsync={props.onSaveAsync}
            onUseCaseChange={props.onUseCaseChange}
            isReadOnly={props.readonly}
            title={props.title}
            formDefinitionType={props.formDefinitionType}
            baseEntityId={props._careActivityId}
            baseEntityType="CareActivity"
            scopes={store.scopes}
            useCaseIdentifier={props.useCaseIdentifier}
            onClose={props.onClose} 
            formDefinitionDescriptors={store.possibleFormDefinitions}/>
    );
}

export default connect(
    provideCareActivityBasedFormDetailPanelStore(CareActivityBasedFormDetailPanel), 
    new CareActivityContextAdapter<ICareActivityBasedFormDetailPanelProps>(careActivityContext => ({
        _careActivityId: careActivityContext.careActivityId,
        _patientId: careActivityContext.patientId,
        _pointOfCareId: careActivityContext.careActivity.pointOfCareId
    })));
