import StaticConfigurationResources from "@HisPlatform/BoundedContexts/Configuration/StaticResources/StaticConfigurationResources";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";
import ShowDynamicPropertiesScreenAction from "@HisPlatform/Packages/Organization/FrontendActions/ShowDynamicPropertiesScreenAction.g";
import ShowExternalLocationsScreenAction from "@HisPlatform/Packages/Organization/FrontendActions/ShowExternalLocationsScreenAction.g";
import ShowOrganizationStructureScreenAction from "@HisPlatform/Packages/Organization/FrontendActions/ShowOrganizationStructureScreenAction.g";
import ShowCustomerListScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowCustomerListScreenAction.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import NavMenuActionItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuActionItem";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";

export function getNavigationMenuItems(): INavMenuItem[] {
    
    const leftTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const rightTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const childMenuItems: INavMenuItem[] = [
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "DynamicProperties",
            "Settings",
            new ShowDynamicPropertiesScreenAction(),
            "UpdateDynamicProperties",
            {
                automationId: "configurationManagement",
                text: StaticConfigurationResources.PropertyGroupsPanel.Title,
                order: 2
            }
        ),
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "OrganizationStructure",
            "Settings",
            new ShowOrganizationStructureScreenAction(),
            "UpdateOrganizationStructure",
            {
                automationId: "organizationManagement",
                text: StaticOrganizationResources.OrganizationUnitPanel.Title,
                order: 3
            }
        ),
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "ExternalLocations",
            "Settings",
            new ShowExternalLocationsScreenAction(),
            "UpdateExternalLocations",
            {
                automationId: "externalLocations",
                text: StaticOrganizationResources.ExternalLocationPanel.Title,
                order: 4
            }
        ),
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "Customers",
            "Settings",
            new ShowCustomerListScreenAction(ScreenDisplayMode.Full),
            "Customers",
            {
                automationId: "customers",
                text: StaticFinanceResources.Customers.Titles.Customers,
                order: 5
            }
        )
    ];

    return [...leftTopLevelMenuItems, ...rightTopLevelMenuItems , ...childMenuItems];
}