import { useEffect } from "react";

const prefix = "MedWorkS";

export default function useWindowTitle(title: string) {
    useEffect(() => {
        const prevTitle = window.document.title;
        window.document.title = `${prefix} - ${title}`;

        return () => {
            window.document.title = prevTitle;
        };
    }, [title]);
}