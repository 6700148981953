import React from "react";
import Icon, { iconNameType } from "@CommonControls/Icon";

interface IOrganizationUnitTypeIconProps {
    tags: string[];
}

const iconMap: { [definitionCode: string]: iconNameType } = {
    OutPatient: "patientWalking",
    InPatient: "patientInBed"
};


const OrganizationUniTypeIcon: React.FC<IOrganizationUnitTypeIconProps> = props => {
    let iconName: iconNameType = null;

    props.tags?.forEach(tag => {
        if (tag.includes("InPatient")) {
            iconName = iconMap["InPatient"];
        } else if (tag.includes("OutPatient")) {
            iconName = iconMap["OutPatient"];
        }
    });

    if (!iconName) {
        return <></>;
    }

    return iconName && (
        <Icon
            iconName={iconName}
            style={{ marginRight: 5 }}
        />

    );
};

export default OrganizationUniTypeIcon;
