import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";

export type ReactionFunction<TFormData> = (entity: (TFormData), dirtyFields?: string[]) => void;
export type AsyncCallbackFunction<TFormData, TResult> = (entity: (TFormData)) => Promise<TResult>;
export type CallbackFunction<TFormData, TResult> = (entity: (TFormData)) => TResult;

export interface IReactionParams<TFormData> {
    entity: TFormData;
    dirtyFields?: string[];
}
export interface IReactionDescriptor<TFormData> {
    condition: (entity: TFormData, dirtyFields: string[]) => boolean;
    reaction: ReactionFunction<TFormData>;
    isFired: boolean;
}

export default abstract class FormExtensionBase<TFormData> implements IFormExtension<TFormData> {

    private eventReactions = new Map<string, TypedEvent<IReactionParams<TFormData>>>();
    private asyncEventReactions = new Map<string, TypedAsyncEvent<IReactionParams<TFormData>>>();
    private asyncCallbacks = new Map<string, Array<AsyncCallbackFunction<any, any>>>();
    private callbacks = new Map<string, Array<CallbackFunction<any, any>>>();
    private stateReactions: Array<IReactionDescriptor<TFormData>> = [];

    public entityChanged(entity: TFormData, dirtyFields: string[]): void {

        this.stateReactions.filter(r => r.isFired === false).forEach(r => {
            if (r.condition(entity, dirtyFields) === true) {
                try {
                    r.reaction(entity, dirtyFields);
                } finally {
                    r.isFired = true;
                }
            }
        });
    }

    public async fireEventAsync(eventName: string, entity: TFormData) {
        const eventToFire = this.eventReactions.get(eventName);
        const asyncEventToFire = this.asyncEventReactions.get(eventName);

        if (!isNullOrUndefined(eventToFire)) {
            eventToFire.emit({ entity });
        }

        if (!isNullOrUndefined(asyncEventToFire)) {
            await asyncEventToFire.emitAsync({ entity });
        }

    }

    public invokeCallback<TResult>(callbackName: string, entity: TFormData): TResult[] {
        const callbacksToInvoke = this.callbacks.get(callbackName);

        const results = [];
        if (!isNullOrUndefined(callbacksToInvoke)) {
            for (const callback of callbacksToInvoke) {
                const result = callback(entity);
                results.push(result);
            }
        }

        return results;
    }

    public async invokeCallbackAsync<TResult>(callbackName: string, entity: TFormData): Promise<TResult[]> {
        const asyncCallbacksToInvoke = this.asyncCallbacks.get(callbackName);

        const results = [];
        if (!isNullOrUndefined(asyncCallbacksToInvoke)) {
            for (const callback of asyncCallbacksToInvoke) {
                const result = await callback(entity);
                results.push(result);
            }
        }

        return results;
    }

    protected registerAsyncCallback<TResult>(callbackName: string, callback: AsyncCallbackFunction<TFormData, TResult>) {
        if (this.asyncCallbacks.has(callbackName)) {
            this.asyncCallbacks.get(callbackName).push(callback);
        } else {
            this.asyncCallbacks.set(callbackName, [callback]);
        }
    }

    protected registerCallback<TResult>(callbackName: string, callback: CallbackFunction<TFormData, TResult>) {
        if (this.callbacks.has(callbackName)) {
            this.callbacks.get(callbackName).push(callback);
        } else {
            this.callbacks.set(callbackName, [callback]);
        }
    }

    protected registerReaction(condition: (entity: TFormData, dirtyFields: string[]) => boolean, reaction: ReactionFunction<TFormData>) {
        this.stateReactions.push({ condition, reaction, isFired: false });
    }

    protected registerEvent(eventName: string, reaction: (params: IReactionParams<TFormData>) => void) {
        if (this.eventReactions.has(eventName)) {
            this.eventReactions.get(eventName).on(reaction);
        } else {
            const newEvent = new TypedEvent<IReactionParams<TFormData>>();
            newEvent.on(reaction);
            this.eventReactions.set(eventName, newEvent);
        }
    }

    protected registerAsyncEvent(eventName: string, reaction: (params: IReactionParams<TFormData>) => Promise<void>) {
        if (this.asyncEventReactions.has(eventName)) {
            this.asyncEventReactions.get(eventName).on(reaction);
        } else {
            const newEvent = new TypedAsyncEvent<IReactionParams<TFormData>>();
            newEvent.on(reaction);
            this.asyncEventReactions.set(eventName, newEvent);
        }
    }

}
