import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import FilterBase from "./FilterBase";

export default class IncludeIdentifierSystemIdFilter extends FilterBase {
    private _value: IdentifierSystemId;

    constructor(value: IdentifierSystemId) {
        super();
        this._value = value;
    }

    public get value(): IdentifierSystemId {
        return this._value;
    }

    public setValue(identifier: IdentifierSystemId) {
        this._value = identifier;
    }
}