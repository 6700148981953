import React from "react";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ServiceRequestSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ServiceRequestSubjectStore";
import AppointmentSchedulePanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentSchedulePanel/AppointmentSchedulePanel";
import { nullFunction } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IServiceRequestAppointmentModalViewProps {
    appointment: Appointment;
    serviceRequestSubject: ServiceRequestSubjectStore;

    onValidateAsync: () => Promise<IClientValidationResult[]>;
    onSaveAsync: (updatedAppointment: Appointment) => Promise<boolean>;
    onClose: (updatedAppointment?: Appointment) => void;
}

export default class ServiceRequestAppointmentModalView extends React.Component<IServiceRequestAppointmentModalViewProps> {

    public render() {
        return (
            <HisUi.ContextAwareModal
                isOpen
                size="normal"
                onClose={this.props.onClose}
                closeOnOutsideClick={true}
                closeOnEscape={true}
                title={StaticWebAppResources.NewServiceRequestPage.TargetDataLabels.AppointmentModalTitle}
                automationId="__serviceRequestAppointmentModal"
            >
                <Ui.Modal.Body>
                    <AppointmentSchedulePanel
                        appointment={this.props.appointment}
                        subjectService={this.props.serviceRequestSubject}
                        onSetSelectedService={nullFunction}
                        onValidateAsync={this.props.onValidateAsync}
                        onSaveAsync={this.props.onSaveAsync}
                        referralReadOnly
                    />
                </Ui.Modal.Body>
            </HisUi.ContextAwareModal>
        );
    }
}
