import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";

export default class ReadOnlyContextAdapter<TProps> implements IComponentAdapter<TProps> {
    public get usedContexts(): Array<React.Context<any>> {
        return [ReadOnlyContext];
    }

    constructor(
        private readonly mapToProps: (props: TProps, isReadOnly: boolean) => Partial<TProps> =
            (props: any, isReadOnly) => ({ isReadOnly: props.isReadOnly || isReadOnly } as any)
    ) { }

    public getMappedProps(props: TProps, contextValues?: Map<React.Context<any>, any>): Partial<TProps> {
        const contextValue: boolean = contextValues.get(ReadOnlyContext);
        return this.mapToProps(props, contextValue);
    }
}