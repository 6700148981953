
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import FormPanelStore from "./FormPanelStore";

const { 
    ContextComponent: FormPanelStoreContext, 
    StoreProvider: FormPanelStoreProvider, 
    useStore: useFormPanelStore,
    provideStore: provideFormPanelStore,
} = createPanelStoreProvider(FormPanelStore);

export {
    FormPanelStoreContext,
    FormPanelStoreProvider,
    useFormPanelStore,
    provideFormPanelStore
};