import PerformedServiceStore from "./PerformedServiceStore";
import CareActivityId from "@Primitives/CareActivityId.g";
import LockingEntityStoreBase from "@Toolkit/CommonWeb/Model/LockingEntityStoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import MedicalServiceFinancingStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/MedicalServiceFinancingStore";
import MedicalServiceFinancingId from "@Primitives/MedicalServiceFinancingId.g";
import FinancedServiceWithQuantityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/FinancedServiceWithQuantityStore";

export default class PerformedServiceListStore extends LockingEntityStoreBase<CareActivityId> {

    @State.observable.ref public financings: MedicalServiceFinancingStore[] = [];

    public propertyNamesExcludedFromDirtyCheck: string[] = [];

    public updatedPerformedServices: PerformedServiceStore[] = [];
    public updatedFinancedServices: FinancedServiceWithQuantityStore[] = [];
    public deletedFinancingsIds: MedicalServiceFinancingId[] = [];
    public addedPerformedServices: PerformedServiceStore[] = [];

    @State.computed
    public get getAllPerformedServices() {
        return _.flatten(this.financings.map(i => i.performedServices));
    }

    public takeSnapshot(): void {
        // noop
    }

    @State.action.bound
    public addPerformedService(newService: PerformedServiceStore) {
        const clone = _.clone(newService);
        this.addedPerformedServices = this.addedPerformedServices.concat(clone);
    }

    @State.action.bound
    public deleteFinancing(financing: MedicalServiceFinancingStore) {
        if (financing.id) {
            this.deletedFinancingsIds.push(financing.id);
        }
    }

    @State.action.bound
    public updatePerformedService(financing: MedicalServiceFinancingStore) {
        financing.financedServicesWithQuantities.forEach(i => {
            if (!isNullOrUndefined(i.financedServiceId) && !this.updatedFinancedServices.includes(i)) {
                this.updatedFinancedServices.push(i);
            }
        });

        financing.performedServices.forEach(i => {
            if (!isNullOrUndefined(i.medicalServiceId) && !this.updatedPerformedServices.includes(i)) {
                this.updatedPerformedServices.push(i);
            }
        });
    }
}
