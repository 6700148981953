import React from "react";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import InsurerOrganization from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/InsurerOrganizations/InsurerOrganization";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import * as Ui from "@CommonControls";
import InsurerOrganizationTypeSelectBox from "@HisPlatform/BoundedContexts/Finance/Components/Controls/InsurerOrganizationTypeSelectBox";
import { CountrySelector, SettlementAndZipCodeSelector } from "@HisPlatformControls";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

interface IInsurerOrganizationDetailPanelViewProps {
    insurerOrganization: InsurerOrganization;
    onSaveAsync: () => Promise<void>;
    onClose: () => void;
    onValidateAsync: () => Promise<IClientValidationResult[]>;
    validationResults: IClientValidationResult[];
}


const InsurerOrganizationDetailPanelView: React.FC<IInsurerOrganizationDetailPanelViewProps> = props => {
    const readonly = props.insurerOrganization && props.insurerOrganization.isClaimSettlementPartner;
    return (

        <Ui.Modal
            isOpen
            closeOnOutsideClick
            onClose={props.onClose}
            title={StaticWebAppResources.Common.Button.Edit}>
            <Ui.Modal.Body>
                <ReadOnlyContext.Provider
                    value={readonly}>
                    <ValidationBoundary
                        onValidateAsync={props.onValidateAsync}
                        entityTypeName="InsurerOrganization"
                        validationResults={props.validationResults}
                        validateOnMount>
                        {props.insurerOrganization &&
                            <>
                                <Ui.Flex style={{ padding: "0 10px "}}>
                                    <Ui.Flex.Item xs={6}>
                                        <Ui.TextBox
                                            value={props.insurerOrganization.code}
                                            onChange={props.insurerOrganization.setCode}
                                            label={StaticFinanceResources.InsurerOganizations.Labels.Code}
                                            propertyIdentifier="Code"
                                            automationId="codeTextBox"
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={6}>
                                        <Ui.TextBox
                                            value={props.insurerOrganization.displayCode}
                                            onChange={props.insurerOrganization.setDisplayCode}
                                            label={StaticFinanceResources.InsurerOganizations.Labels.DisplayCode}
                                            propertyIdentifier="DisplayCode"
                                            automationId="displayCodeTextBox"
                                        />
                                    </Ui.Flex.Item>
                                </Ui.Flex>
                                <Ui.Flex style={{ padding: "0 10px" }}>
                                    <Ui.Flex.Item xs={6}>
                                        <Ui.TextBox
                                            value={props.insurerOrganization.name}
                                            onChange={props.insurerOrganization.setName}
                                            label={StaticFinanceResources.InsurerOganizations.Labels.Name}
                                            propertyIdentifier="Name"
                                            automationId="nameTextBox"
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={6}>
                                        <InsurerOrganizationTypeSelectBox
                                            value={props.insurerOrganization.insurerOrganizationTypeId}
                                            onChange={props.insurerOrganization.setInsurerOrganizationTypeId}
                                            label={StaticFinanceResources.InsurerOganizations.Labels.InsurerOrganizationType}
                                            propertyIdentifier="InsurerOrganizationType"
                                            automationId="insurerOrganizationTypeSelectBox"
                                        />
                                    </Ui.Flex.Item>

                                </Ui.Flex>
                                <Ui.Flex style={{ padding: "0px 10px" }}>
                                    <Ui.Flex.Item xs={3}>
                                        <CountrySelector
                                            value={props.insurerOrganization.address.countryId}
                                            onChange={props.insurerOrganization.address.setCountryId}
                                            label={StaticFinanceResources.InsurerOganizations.Labels.Country}
                                            propertyIdentifier="CountryId"
                                            automationId="countrySelector"
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={6}>
                                        <SettlementAndZipCodeSelector
                                            countryId={props.insurerOrganization.address.countryId}
                                            settlement={props.insurerOrganization.address.settlement}
                                            setSettlement={props.insurerOrganization.address.setSettlement}
                                            zipCode={props.insurerOrganization.address.zipCode}
                                            setZipCode={props.insurerOrganization.address.setZipCode}
                                            automationIdPrefix="settlementAndZipCodeSelector"
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            value={props.insurerOrganization.address.addressLine}
                                            onChange={props.insurerOrganization.address.setAddressLine}
                                            label={StaticFinanceResources.InsurerOganizations.Labels.AddressLine}
                                            propertyIdentifier="AddressLine"
                                            automationId="addressLineTextBox"
                                        />
                                    </Ui.Flex.Item>
                                </Ui.Flex>
                                <Ui.Flex style={{ padding: "0px 10px" }}>
                                    <Ui.Flex.Item xs={4}>
                                        <Ui.CheckBox
                                            value={props.insurerOrganization.isActive}
                                            onChange={props.insurerOrganization.setIsActive}
                                            label={StaticFinanceResources.InsurerOganizations.Labels.IsActive}
                                            automationId="isActiveCheckBox"
                                        />
                                    </Ui.Flex.Item>
                                </Ui.Flex>
                            </>
                        }
                    </ValidationBoundary>
                </ReadOnlyContext.Provider>
            </Ui.Modal.Body>
            <Ui.Modal.Footer>
                <Ui.Flex xsJustify="between" verticalSpacing="none">
                    <Ui.Flex.Item>
                        <Ui.Button text={StaticUserManagementResources.UserGroupManagementPanel.RolePanel.Cancel}
                            onClick={props.onClose}
                            visualStyle="link"
                            automationId="cancelButton"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item>
                        <Ui.SaveButton
                            visualStyle="primary"
                            onClickAsync={props.onSaveAsync}
                            automationId="saveButton"
                            disabled={readonly}
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.Modal.Footer>
        </Ui.Modal>
    );
};

export default State.observer(InsurerOrganizationDetailPanelView);
