import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import PatientDocumentTypeId from "@Primitives/PatientDocumentTypeId.g";
import DateFormFieldData from "@Toolkit/FormEngine/Model/Data/DateFormFieldData";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import { useFormDateField, useFormEntityIdField, useFormTextField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { getCompositeArrayFields, getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import { useFormLayoutContext } from "@Toolkit/FormEngine/Panels/FormLayoutContext";
import { ICustomBlockRegistryItem, IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import { useFormPanelStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanelStoreProvider";
import State, { IObservableArray, ObservableSet } from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React, { useEffect, useState } from "react";
import PatientIdentifier from "./PatientIdentifier";
import BooleanFormFieldData from "@Toolkit/FormEngine/Model/Data/BooleanFormFieldData";

interface IPatientIdentifierFormCustomBlockProps extends IFormCustomBlockComponentProps {
}

function _PatientIdentifierFormCustomBlock(props: IPatientIdentifierFormCustomBlockProps) {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext.compositeDataReferences.join(".");

    const fields = getCompositeArrayFields(props.form.data.Content, compositeFieldName);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        addFieldsForPatientIdentifier(props.form.data.Content, fields, compositeFieldName);
        setIsLoaded(true);
    }, []);

    return isLoaded && (
        <PatientIdentifierFormCustomBlockCore {...props} />
    );
}

const PatientIdentifierFormCustomBlockCore = State.observer((props: IPatientIdentifierFormCustomBlockProps) => {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext.compositeDataReferences.join(".");

    const [identifierTypeId, setIdentifierTypeId] = useFormEntityIdField<PatientDocumentTypeId>(props.form.data.Content, compositeFieldName + ".DocumentTypeId", PatientDocumentTypeId);
    const [identifierValue, setIdentifierValue] = useFormTextField(props.form.data.Content, compositeFieldName + ".IdentifierValue");
    const [issuer, setIssuer] = useFormTextField(props.form.data.Content, compositeFieldName + ".Issuer");
    const [insurerOrganizationId, setInsurerOrganizationId] = useFormEntityIdField<InsurerOrganizationId>(props.form.data.Content, compositeFieldName + ".InsurerOrganizationId", InsurerOrganizationId);
    const [validFrom, setValidForm] = useFormDateField(props.form.data.Content, compositeFieldName + ".ValidFrom");
    const [validTo, setValidTo] = useFormDateField(props.form.data.Content, compositeFieldName + ".ValidTo");

    const formStore = useFormPanelStore();
    const context: ObservableSet<string> = formStore.contextData.get(compositeFieldName);

    return (!context || context?.size === 0) && (
        <PatientIdentifier
            identifierTypeId={identifierTypeId}
            onIdentifierTypeIdChange={setIdentifierTypeId}
            identifierValue={identifierValue}
            onIdentifierValueChange={setIdentifierValue}
            issuer={issuer}
            onIssuerChange={setIssuer}
            insurerOrganizationId={insurerOrganizationId}
            onInsurerOrganizationIdChange={setInsurerOrganizationId}
            validFrom={validFrom}
            onValidFromChange={setValidForm}
            validTo={validTo}
            onValidToChange={setValidTo}
        />
    );
});

export function addFieldsForPatientIdentifier(formFields: FormFieldDataBase[], array: IObservableArray<FormFieldDataBase>, compositeFieldName: string) {
    State.runInAction(() => {
        const identifierTypeIdField = getField<ReferencedEntityFormFieldData>(formFields, compositeFieldName + ".DocumentTypeId");
        if(!identifierTypeIdField) {
             array.push(new ReferencedEntityFormFieldData("DocumentTypeId", false, true, State.createObservableShallowArray([]), null));
        }
    
        const identifierValueField = getField<TextFormFieldData>(formFields, compositeFieldName + ".IdentifierValue");
        if(!identifierValueField) {
            array.push(new TextFormFieldData("IdentifierValue", false, true, null));
        }
    
        const issuerField = getField<TextFormFieldData>(formFields, compositeFieldName + ".Issuer");
        if(!issuerField) {
            array.push(new TextFormFieldData("Issuer", false, true, null));
        }
    
        const insurerOrganizationIdField = getField<ReferencedEntityFormFieldData>(formFields, compositeFieldName + ".InsurerOrganizationId");
        if(!insurerOrganizationIdField) {
            array.push(new ReferencedEntityFormFieldData("InsurerOrganizationId", false, true, State.createObservableShallowArray([]), null));
        }
    
        const validFromField = getField<DateFormFieldData>(formFields, compositeFieldName + ".ValidFrom");
        if(!validFromField) {
            array.push(new DateFormFieldData("ValidFrom", false, true, null));
        }
    
        const validToField = getField<DateFormFieldData>(formFields, compositeFieldName + ".ValidTo");
        if(!validToField) {
            array.push(new DateFormFieldData("ValidTo", false, true, null));
        }

        const isNonUniqueIdentifierDialogShownField = getField<BooleanFormFieldData>(formFields, compositeFieldName + ".IsNonUniqueIdentifierDialogShown");
        if (!isNonUniqueIdentifierDialogShownField) {
            array.push(new BooleanFormFieldData("IsNonUniqueIdentifierDialogShown", false, false, false));
        }
    });
}

export function usePatientIdentifierCustomBlockHelper(fieldName: string, patientIdentifierTypeIdentifierSystemId: string, targetPatientIdentifierTypeIdentifierSystemIds: string[], formCustomBlockName: string) {
    const formStore = useFormPanelStore();

    useEffect(() => {
        let context: ObservableSet<string> = formStore.contextData.get(fieldName);
        if (!context) {
            context = State.createObservableShallowSet<string>([]);
            formStore.contextData.set(fieldName, context);
        }
    
        State.runInAction(() => {
            if (targetPatientIdentifierTypeIdentifierSystemIds.some(i => i === patientIdentifierTypeIdentifierSystemId)) {
                context.add(formCustomBlockName);
            } else {
                context.delete(formCustomBlockName);
            }
        });
    }, [fieldName, patientIdentifierTypeIdentifierSystemId]);
}

const PatientIdentifierFormCustomBlock = connect(_PatientIdentifierFormCustomBlock);

export const PatientIdentifierRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Patient identifier",
    dataElementsFactory: () => [
    ],
    componentType: PatientIdentifierFormCustomBlock
};

export default PatientIdentifierFormCustomBlock;