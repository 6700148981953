import IDateTimeFormatConfiguration from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatConfiguration";


const EnDateTimeFormatConfiguration = {
    dateFormat: {
        normal: "MM/DD/YYYY",
        parseFormats: ["MM/DD/YYYY", "MMDDYYYY"],
        partialDateRegex: {
            // eslint-disable-next-line no-useless-escape
            pattern: "^(?<month>[0-9]{2})?\/(?<day>[0-9]{2})?\/(?<year>[0-9]{4})"
        }
    },
    dateTimeFormat: {
        normal: "MM/DD/YYYY HH:mm:ss",
        withoutSeconds: "MM/DD/YYYY HH:mm",
        parseFormats: ["MM/DD/YYYY HH:mm:ss", "MM/DD/YYYY HH:mm", "MMDDYYYYHHmmss", "MMDDYYYYHHmm"]
    },
    timeFormat: {
        normal: "HH:mm:ss",
        withoutSeconds: "HH:mm",
        parseFormats: ["HH:mm:ss", "HH:mm"]
    },
    todayString: "today",
    monthFormat: {
        normal: "MM/YYYY",
        parseFormats: ["MM/YYYY", "MMYYYY"]
    }
} as IDateTimeFormatConfiguration;

export default EnDateTimeFormatConfiguration;