import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import ILockingNotificationService from "@HisPlatform/BoundedContexts/Locking/Services/Definition/ILockingNotificationService";
import LockAcquisitionError from "@Toolkit/CommonWeb/Model/LockAcquisitionError";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import UserId from "@Primitives/UserId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import Di from "@Di";

@Di.injectable()
export default class LockingNotificationService implements ILockingNotificationService {

    constructor(
        @Di.inject("INotificationService") private notificationService: INotificationService,
        @Di.inject("UserManagementDataProviderStore") private userManagementReferenceDataStore: UserManagementDataProviderStore
    ) {
        
    }

    @State.bound
    public async formatNameForLockingMessageAsync(userId: UserId) {
        if (userId) {
            const user = await this.userManagementReferenceDataStore.users.getOrLoadAsync(userId);
            await this.userManagementReferenceDataStore.usersWithIdentifiersOnlyWithPractitioners.ensureLoadedAsync([userId]);
            const isPractitioner = this.userManagementReferenceDataStore.usersWithIdentifiersOnlyWithPractitioners.has(userId);
            return isPractitioner ? `${user.displayName} (${user.loginName})` : user.loginName;
        }
        return "";
    }

    @State.bound
    public async getLockingMessageAsync(userId: UserId) {
        const formattedUserName = await this.formatNameForLockingMessageAsync(userId);
        return formatStringWithObjectParams(StaticWebAppResources.Common.ToastMessage.LockHeldBySomeoneElse, { UserName: formattedUserName });
    }

    @State.bound
    public async displayErrorNotificationAsync(error: LockAcquisitionError): Promise<void> {
        const message = await this.getLockingMessageAsync(error?.lockOwnerUserId);
        this.notificationService.error(message);
    }

    @State.bound
    public async displayWarningNotificationAsync(lockInfo: LockInfo): Promise<void> {
        const message = await this.getLockingMessageAsync(lockInfo?.preventingLockUserId);
        this.notificationService.warning(message);
    }
}