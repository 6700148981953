import React from "react";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IName from "@Toolkit/CommonWeb/Model/IName";
import { ICommonControlProps, getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface IPersonNameLabelDependencies {
    localizationService: ILocalizationService;
}

interface IPersonNameLabelProps extends ICommonControlProps {
    personName: IName;
    _dependencies?: IPersonNameLabelDependencies;
}

class PersonNameLabel extends React.PureComponent<IPersonNameLabelProps> {

    public render() {
        const htmlProps = getStandardHtmlProps(this.props);
        const name = this.props.personName ? this.props._dependencies.localizationService.localizePersonName(this.props.personName) : "";
        return (
            <p title={name} data-automation-id={"__patientName"} {...htmlProps} style={{ display: "inline" }}>{name}</p>
        );
    }
}

export default connect(
    PersonNameLabel,
    new DependencyAdapter<IPersonNameLabelProps, IPersonNameLabelDependencies>((container) => {
        return {
            localizationService: container.resolve<ILocalizationService>("ILocalizationService")
        };
    })
);