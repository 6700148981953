import { IModalService, IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import CareActivityContext from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContext";
import PatientContext from "@HisPlatform/Model/DomainModel/PatientContext/PatientContext";

export default class HisModalService implements IModalService {
    constructor(
        private readonly modalService: IModalService,
        public patientContext: PatientContext,
        public careActivityContext: CareActivityContext
    ) {}

    public showDialogAsync<TResult>(params: IModalParams): Promise<TResult> {
        return this.modalService.showDialogAsync<TResult>({
            ...params,
            type: params.type,
            _patientContext: this.patientContext,
            _careActivityContext: this.careActivityContext
        } as IModalParams);
    }

    public showModalAsync(params: IModalParams): Promise<void> {
        return this.modalService.showModalAsync({
            ...params,
            type: params.type,
            _patientContext: this.patientContext,
            _careActivityContext: this.careActivityContext
        } as IModalParams);
    }

    public showModal(params: IModalParams): void {
        this.modalService.showModal({
            ...params,
            type: params.type,
            _patientContext: this.patientContext,
            _careActivityContext: this.careActivityContext
        } as IModalParams);
    }

}