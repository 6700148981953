import IStateChangeBlockingRegistry from "@PluginInterface/BoundedContexts/Care/CareRegister/StateChange/IStateChangeBlockingRegistry";
import CareActivityId from "@Primitives/CareActivityId.g";
import ResourceId from "@Primitives/ResourceId.g";
import StateChangeBlockingData from "@Primitives/StateChangeBlockingData.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import * as PrescriptionRouteDefinition from "@HunEHealthInfrastructurePlugin/UseCases/PrescriptionsUseCase/PrescriptionRoutes";
import * as PractitionerRecommendationsRouteDefinition from "@HunEHealthInfrastructurePlugin/UseCases/PractitionerRecommendationUseCase/PractitionerRecommendationRoutes";
import IStatusChangeBlockingReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/IStatusChangeBlockingReason";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";

export default function configureStateChangeBlockings(stateChangeBlockingRegistry: IStateChangeBlockingRegistry) {
    stateChangeBlockingRegistry.register(
        StateChangeBlockings.prescriptions,
        (routingController: IRoutingController<any>, _: IActionDispatcher, careActivityId: CareActivityId) => navigateToPrescriptions(routingController, careActivityId),
        (localizationService: ILocalizationService) => getTitle(localizationService, StateChangeBlockings.prescriptions),
        (localizationService: ILocalizationService, reason: IStatusChangeBlockingReason) => getGenericMessage(localizationService, reason));

    stateChangeBlockingRegistry.register(
        StateChangeBlockings.practitionerRecommendations,
        (routingController: IRoutingController<any>, _: IActionDispatcher, careActivityId: CareActivityId) => navigateToPractitionerRecommendations(routingController, careActivityId),
        (localizationService: ILocalizationService) => getTitle(localizationService, StateChangeBlockings.practitionerRecommendations),
        (localizationService: ILocalizationService, reason: IStatusChangeBlockingReason) => getGenericMessage(localizationService, reason));
}

function getTitle(localizationService: ILocalizationService, stateChangeBlockingData: StateChangeBlockingData) {
    return localizationService.localizeReferenceData(new ResourceId("StatusChangeBlockingData." + stateChangeBlockingData.value));
}

function getGenericMessage(localizationService: ILocalizationService, reason: IStatusChangeBlockingReason) {
    if (isNullOrUndefined(reason) || isNullOrUndefined(reason.resourceId)) {
        return null;
    }

    return localizationService.localizeReferenceData(reason.resourceId);
}

function navigateToPrescriptions(routingController: IRoutingController<any>, careActivityId: CareActivityId) {
    routingController.push(PrescriptionRouteDefinition.default.prescriptions.makeRoute({ careActivityId: careActivityId.value, prescriptionId: null }));
}

function navigateToPractitionerRecommendations(routingController: IRoutingController<any>, careActivityId: CareActivityId) {
    routingController.push(PractitionerRecommendationsRouteDefinition.default.practitionerRecommendations.makeRoute({ careActivityId: careActivityId.value, practitionerRecommendationId: null, recommendationType: null, readonly: "false" }));
}

class StateChangeBlockings {
    public static readonly practitionerRecommendations = new StateChangeBlockingData("PractitionerRecommendations");
    public static readonly prescriptions = new StateChangeBlockingData("Prescriptions");
}