import styles from "./Application.css";
import React from "react";
import DeveloperPage from "@HisPlatform/Components/Pages/DeveloperPage/View/DeveloperPage";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import ToastifyToastContainer from "@Toolkit/ReactClient/Services/Implementation/ToastifyNotificationService/ToastifyToastContainer";
import RoutingFrame from "@Toolkit/ReactClient/Routing/RoutingFrame";
import OutpatientTreatmentPage from "@HisPlatform/Components/Pages/OutpatientTreatment/OutpatientTreatmentPage";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FeedbackDialog from "@HisPlatform/Components/Panels/FeedbackDialog/FeedbackDialog";
import InsurerOrganizationsConfigurationPage from "@HisPlatform/Components/Pages/FinancePages/InsurerOrganizations/InsurerOrganizationsConfigurationPage";
import HisplatformDialogContainer from "@HisPlatform/Components/HisplatformDialogContainer/HisplatformDialogContainer";
import ConfigurationEditorPage from "@HisPlatform/Components/Pages/ConfigurationPages/ConfigurationEditorPage";
import OrganizationEditorPage from "@HisPlatform/Components/Pages/OrganizationPages/OrganizationEditorPage/OrganizationEditorPage";
import WorklistPage from "@HisPlatform/Components/Pages/WorklistPage/WorklistPage";
import { LoginPage, LoginCallbackPage, LoggedOutPage } from "@HisPlatform/Components/Pages/LoginPage/LoginPage";
import ApplicationInfoModal from "@HisPlatform/Application/ApplicationInfoModal/ApplicationInfoModal";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import IAuthenticationService from "@HisPlatform/Services/Definition/Authentication/IAuthenticationService";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ProfileManagementPage from "@HisPlatform/Components/Pages/ProfileManagementPage/ProfileManagementPage";
import UserManagementPage from "@HisPlatform/Components/Pages/UserManagementPages/UserManagementPage/UserManagementPage";
import UserGroupManagementPage from "@HisPlatform/Components/Pages/UserManagementPages/UserGroupManagementPage/UserGroupManagementPage";
import HisErrorBoundary from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import HisBusinessErrorHandler from "@HisPlatform/Application/GlobalBusinessErrorHandlers";
import ErrorModal from "@Toolkit/ReactClient/Components/ErrorModal/ErrorModal";
import Log from "@Log";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import ReportingPage from "@HisPlatform/Components/Pages/ReportingPage/ReportingPage";
import EventHandler from "@Toolkit/ReactClient/Components/EventHandler/EventHandler";
import UsersApiAdapter from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Users/UsersApiAdapter";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import ModalContextStore from "@Toolkit/ReactClient/Components/ModalService/ModalContextStore";
import ModalServiceReactContext from "@Toolkit/ReactClient/Components/ModalService/ModalServiceContext";
import HisPlatformModalRenderer from "@HisPlatform/Components/HisPlatformModalRenderer/HisPlatformModalRenderer";
import ViewContextProvider from "@Toolkit/ReactClient/Components/ViewContext/ViewContextProvider";
import SearchPatientPage from "@HisPlatform/Components/Pages/OutpatientTreatment/SearchPatientPage/SearchPatientPage";
import RoleManagementPage from "@HisPlatform/Components/Pages/AuthorizationPages/RoleEditorPage/RoleManagementPage";
import DocumentTemplateManagementPage from "@HisPlatform/Components/Pages/DocumentTemplateManagementPage/DocumentTemplateManagementPage";
import AppointmentPage from "@HisPlatform/Components/Pages/AppointmentPages/AppointmentPage";
import AppointmentScheduleDefinitionsPage from "@HisPlatform/Components/Pages/AppointmentScheduleDefinitionPages/AppointmentScheduleDefinitionsPage";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import FormDefinitionPage from "./FormDefinitionPage";
import WorklistDefinitionEditorPage from "@HisPlatform/Components/Pages/WorklistDefinitionEditorPage/WorklistDefinitionEditorPage";
import HisWebWorkerInstance from "@HisWebWorker/HisWebWorkerInstance";
import { IInitializeHisWebWorkerCommandData } from "./OperationProcessing/IInitializeHisWebWorkerCommandData";
import IClientSessionRepository from "@Toolkit/CommonWeb/Repositories/Definition/ClientSessionRepository/IClientSessionRepository";
import config from "@Config";
import InitializeHisWebWorkerCommand from "./OperationProcessing/InitializeHisWebWorkerCommand";
import ExternalLocationsPage from "@HisPlatform/Components/Pages/OrganizationPages/ExternalLocationsPage/ExternalLocationsPage";
import SurgeryTypeDefinitionPage from "@HisPlatform/Components/Pages/SurgeryPages/SurgeryTypeDefinitionPage";
import SurgeryPage from "@HisPlatform/Components/Pages/SurgeryPages/SurgeryPage";
import { ActionDispatcherProvider } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import ShowScreenActionFullScreenHost from "@HisPlatform/Components/ShowScreenAction/ShowScreenActionFullScreenHost";
import { ScreenNavigationContextProvider } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import ApplicationInitializer from "./ApplicationInitializer";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import FloatingPanel from "@Toolkit/ReactClient/Components/FloatingPanel/FloatingPanel";
import UnderMaintenancePage from "@Toolkit/ReactClient/Components/UnderMaintenancePage/UnderMaintenancePage";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import IRootApplicationExtensionPointProps from "@PluginInterface/BoundedContexts/RootApplication/IRootApplicationExtensionPointProps";
import PatientMainPage from "@HisPlatform/Components/Pages/Patient/PatientMainPage/View/PatientMainPage";
import DynamicNavigation from "@HisPlatform/Application/DynamicNavigation/DynamicNavigation";
import IRoutingFrameCaseRegistry from "@HisPlatform/Services/Definition/RoutingFrameCaseRegistry/IRoutingFrameCaseRegistry";
import LogoutPage from "@HisPlatform/Components/Pages/LogoutPage/LogoutPage";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import HomePage from "@HisPlatform/Application/HomePage";
import { HomeControllerStoreProvider } from "@HisPlatform/Packages/Care/Components/HomeMenu/HomeControllerProvider";
import HomeControllerLoadingBoundaryAdapter from "@HisPlatform/Packages/Care/Components/HomeMenu/HomeControllerLoadingBoundaryAdapter";
import { nullFunction } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { BackdropPortal } from "@CommonControls/Backdrop/Backdrop";
import { GlobalApplicationRef } from "@HisPlatform/Application/GlobalApplicationRef";

interface IApplicationDependencies {
    userContext: UserContext;
    applicationContext: ApplicationContext;
    authenticationService: IAuthenticationService;
    globalRoutingStore: GlobalRoutingStore;
    notificationService: INotificationService;
    usersApiAdapter: UsersApiAdapter;
    modalContextStore: IModalService;
    clientSessionRepository: IClientSessionRepository;
    authorizationService: AuthorizationService;
    routingFrameCaseRegistry: IRoutingFrameCaseRegistry;
}

interface IApplicationProps {
    _dependencies?: IApplicationDependencies;
    isMobile: boolean;
}

/** @screen */
@State.observer
class Application extends React.Component<IApplicationProps> {

    private get userContext() { return this.props._dependencies.userContext; }
    private get applicationContext() { return this.props._dependencies.applicationContext; }
    private get authenticationService() { return this.props._dependencies.authenticationService; }
    private get globalRoutingStore() { return this.props._dependencies.globalRoutingStore; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get modalContextStore() { return this.props._dependencies.modalContextStore; }
    private get authorizationService() { return this.props._dependencies.authorizationService; }
    private get routingFrameCaseRegistry() { return this.props._dependencies.routingFrameCaseRegistry; }

    @State.observable.ref private unhandledError: Error = null;

    constructor(props: IApplicationProps) {
        super(props);
        this.initializeGlobalErrorHandlers();
    }

    private initializeGlobalErrorHandlers(): any {
        // Unhandled promise rejection (e.g. ajax calls)
        window.addEventListener("unhandledrejection", (event?: PromiseRejectionEvent) => {
            event.preventDefault();
            Log.fatal("Unhandled promise rejection!");
            Log.error(event.reason);
            State.runInAction(() => {
                this.unhandledError = event.reason;
            });
        });
    }

    public componentDidMount() {
        this.applicationContext.onCultureCodeChanged.on(() => this.setCurrentCulture());
        this.authenticationService.onLogout.on(() => {
            this.globalRoutingStore.hostRoutingAdapter.ignoreBlockerHooksOnce();
        });

        // if (this.userContext.isAuthenticated === false) {
        //     this.redirectToLoginPage();
        // }
    }

    @State.bound
    private unauthorizedErrorHandler() {
        this.userContext.setUnauthenticated();
        if (this.globalRoutingStore.currentRoute && this.globalRoutingStore.currentRoute.definition !== ApplicationRoutes.login) {
            this.redirectToLoginPage();
        }
    }

    private redirectToLoginPage() {
        this.globalRoutingStore.push(ApplicationRoutes.login.makeRoute({
            redirectTo: this.globalRoutingStore.currentPathname
        }));
    }

    public setCurrentCulture() {
        (window as any).application.showLoader();
        window.location.reload();
    }

    @State.bound
    private async showPasswordExpirationWarningAsync() {
        if (!this.userContext || !this.userContext.id) {
            return;
        }

        const daysUntilPasswordExpiration = await this.props._dependencies.usersApiAdapter.getDaysUntilPasswordExpirationByUserIdAsync(this.userContext.id);

        if (daysUntilPasswordExpiration.value && daysUntilPasswordExpiration.value <= 7) {
            this.notificationService.warning(formatString(StaticUserManagementResources.Common.Notification.UserPasswordIsExpiringSoon, daysUntilPasswordExpiration.value.toString()));
        }
    }

    @State.bound
    private async initializePermissionsAsync() {
        await this.authorizationService.loadPermissionsAsync();
    }

    @State.bound
    private initializeWebWorker() {
        if (this.userContext.isAuthenticated === true) {
            const userSession: IInitializeHisWebWorkerCommandData = {
                sessionId: this.props._dependencies.clientSessionRepository.getClientSessionId(),
                token: this.userContext.token,
                userName: this.userContext.loginName,
                config: config
            };
            HisWebWorkerInstance.postMessage(new InitializeHisWebWorkerCommand("InitializeHisWebWorkerCommand", userSession));
        } else {
            HisWebWorkerInstance.postMessage(new InitializeHisWebWorkerCommand("InitializeHisWebWorkerCommand", null));
        }
    }

    private readonly routeDefinitions = {
        ...ApplicationRoutes,
        ...this.routingFrameCaseRegistry.getRouteDefinitions("Application")
    };

    @State.bound
    private inspectToken() {
        return this.userContext.token;
    }

    public render() {

        if (!!this.unhandledError) {
            return this.renderError(this.unhandledError);
        }

        if (this.applicationContext.isUnderMaintenance) {
            return <UnderMaintenancePage />;
        }

        const rootApplicationExtensionPointProps: IRootApplicationExtensionPointProps = {};

        if (!this.userContext.isAuthenticated) {
            return (
                <HisErrorBoundary onRenderError={this.renderError}>
                    <HisBusinessErrorHandler onUnauthenticated={nullFunction}>
                        {!this.userContext.isAuthenticated && !this.userContext.isLoggingOut && (
                            <div className={styles.pageContainer2}>
                                <ViewContextProvider totalHeightLoss={30}>
                                    <RoutingFrame
                                        routeDefinitions={this.routeDefinitions}
                                        fallbackRoute={ApplicationRoutes.login}
                                    >
                                        <RoutingFrame.Case route={ApplicationRoutes.loginCallback} component={LoginCallbackPage} />
                                        <RoutingFrame.Case route={ApplicationRoutes.login} component={LoginPage} />
                                        <RoutingFrame.Case route={ApplicationRoutes.loggedOut} component={LoggedOutPage} />
                                    </RoutingFrame>
                                </ViewContextProvider>
                            </div>
                        )}
                    </HisBusinessErrorHandler>
                </HisErrorBoundary>
            );
        }

        return (
            <HisErrorBoundary onRenderError={this.renderError}>
                <HisBusinessErrorHandler onUnauthenticated={this.unauthorizedErrorHandler}>
                    <ModalServiceReactContext.Provider value={this.modalContextStore as ModalContextStore}>
                        <ActionDispatcherProvider>
                            <ScreenNavigationContextProvider>
                                <ApplicationInitializer>
                                    <HomeControllerStoreProvider>
                                        <HisErrorBoundary>
                                            <LoadingBoundary>
                                                <div>
                                                    <DynamicNavigation />
                                                </div>
                                                <HomeControllerLoadingBoundaryAdapter />
                                            </LoadingBoundary>
                                        </HisErrorBoundary>
                                        <div className={styles.pageContainer}>
                                            <ViewContextProvider totalHeightLoss={30}>
                                                <RoutingFrame
                                                    routeDefinitions={this.routeDefinitions}
                                                    fallbackRoute={ApplicationRoutes.home}
                                                >
                                                    <RoutingFrame.Case route={ApplicationRoutes.logout} component={LogoutPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.home} component={HomePage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.developer} component={DeveloperPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.searchPatient} component={SearchPatientPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.patient} component={PatientMainPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.careActivity} component={OutpatientTreatmentPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.dynamicWorklist} component={WorklistPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.insurerOrgs} component={InsurerOrganizationsConfigurationPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.configurationEditor} component={ConfigurationEditorPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.organizationEditor} component={OrganizationEditorPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.userManagement} component={UserManagementPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.userGroupManagement} component={UserGroupManagementPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.profileManagement} component={ProfileManagementPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.reporting} component={ReportingPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.roleManagement} component={RoleManagementPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.documentTemplate} component={DocumentTemplateManagementPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.appointments} component={AppointmentPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.appointmentScheduleDefinitions} component={AppointmentScheduleDefinitionsPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.formDefinitions} component={FormDefinitionPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.worklistDefinitions} component={WorklistDefinitionEditorPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.externalLocations} component={ExternalLocationsPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.surgeryTypeDefinitions} component={SurgeryTypeDefinitionPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.surgery} component={SurgeryPage} />
                                                    <RoutingFrame.Case route={ApplicationRoutes.screen} component={ShowScreenActionFullScreenHost as any} />
                                                    {this.routingFrameCaseRegistry.getAll("Application")}
                                                </RoutingFrame>
                                            </ViewContextProvider>
                                        </div>
                                    </HomeControllerStoreProvider>
                                </ApplicationInitializer>
                                <ApplicationInfoModal isOpen={this.applicationContext.isApplicationInformationModalOpen} onClose={this.applicationContext.closeApplicationInformationModal} />
                                <ToastifyToastContainer />
                                <HisplatformDialogContainer />
                                <FeedbackDialog openEvent={this.applicationContext.feedbackDialogOpenEvent} ref={GlobalApplicationRef} />
                                <HisPlatformModalRenderer />
                                <EventHandler event={this.userContext.userLoggedInEvent} onFiredAsync={this.showPasswordExpirationWarningAsync} />
                                <EventHandler event={this.userContext.userLoggedInEvent} onFiredAsync={this.initializePermissionsAsync} />
                                <State.Reaction inspector={this.inspectToken} reaction={this.initializeWebWorker} fireOnMount />

                                {this.applicationContext.spike_showAssecoRooms && (
                                    <FloatingPanel title="Videóhívás">
                                        <iframe
                                            src="https://webrtc.asseco-mo.hu/join-room"
                                            frameBorder="0"
                                            style={{ height: "100%", width: "100%" }}
                                            sandbox="allow-same-origin allow-forms allow-scripts allow-popups allow-popups-to-escape-sandbox"
                                            allow="camera *; microphone *" />
                                    </FloatingPanel>
                                )}

                                <HisPlatformExtensionPoint
                                    extensionProps={rootApplicationExtensionPointProps}
                                    type="rootApplicationExtension">
                                    <></>
                                </HisPlatformExtensionPoint>

                                <div id="__modal-portal"></div>
                                <div id="__select-box-portal"></div>

                                <BackdropPortal.Host />

                            </ScreenNavigationContextProvider>
                        </ActionDispatcherProvider>
                    </ModalServiceReactContext.Provider>
                </HisBusinessErrorHandler>
            </HisErrorBoundary>
        );
    }

    @State.bound
    private renderError(error: Error) {
        return (
            <ErrorModal
                error={error}
                onOpenFeedback={this.applicationContext.openFeedbackDialog}
                openFeedbackDialogEvent={this.applicationContext.feedbackDialogOpenEvent}
            />
        );
    }
}

export default connect(
    Application,
    new DependencyAdapter<IApplicationProps, IApplicationDependencies>(c => ({
        applicationContext: c.resolve("ApplicationContext"),
        globalRoutingStore: c.resolve("GlobalRoutingStore"),
        authenticationService: c.resolve("IAuthenticationService"),
        userContext: c.resolve("UserContext"),
        notificationService: c.resolve("INotificationService"),
        usersApiAdapter: c.resolve("UsersApiAdapter"),
        modalContextStore: c.resolve("IModalService"),
        clientSessionRepository: c.resolve("IClientSessionRepository"),
        authorizationService: c.resolve("AuthorizationService"),
        routingFrameCaseRegistry: c.resolve("IRoutingFrameCaseRegistry")
    }))
);
