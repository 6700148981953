import Portal from "@Toolkit/ReactClient/Components/Portal/Portal";
import React, { useCallback } from "react";
import Styles from "./Backdrop.less";

interface IBackdropProps {
    usePortal?: boolean;
    onClick?: () => void;
}

export const BackdropPortal = Portal.createNamedPortal("Asseco.Toolkit.Frontend.Backdrop");

export default function Backdrop(props: React.PropsWithChildren<IBackdropProps>) {

    const stopClickPropagation = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    }, [props.onClick]);

    const content = (
        <div className={Styles.container} onClick={props.onClick}>
            <div onClick={stopClickPropagation}>
                {props.children}
            </div>
        </div>
    );

    if (props.usePortal) {
        return (
            <BackdropPortal>
                {content}
            </BackdropPortal>
        );
    }

    return content;
}