import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import DocumentInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentInfo";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { getSingleDocumentDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestDocument/ServiceRequestDocumentDtoMapper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { DocumentInfoDto } from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import { getDocumentInfo } from "@HisPlatform/Packages/Patients/Screens/DocumentScreen/DocumentStoreMapper";

@Di.injectable()
export default class ServiceRequestDocumentApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IServiceRequestManagementClient") private readonly apiClient: Proxy.IServiceRequestManagementClient,
    ) {
        super();
    }

    public getSingleDocumentForServiceRequestAsync(serviceRequestId: ServiceRequestId, careActivityId: CareActivityId, documentTypeCode?: string, isPrimary?: boolean, isAutomatic?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<DocumentInfo>(),
            async target => {
                const response = await this.apiClient.getSingleDocumentForServiceRequestQueryAsync(
                    CreateRequestId(),
                    getSingleDocumentDto(serviceRequestId, careActivityId, documentTypeCode, isPrimary, isAutomatic)
                );
                
                target.operationInfo = createOperationInfo(response);
                target.value = getDocumentInfo(response.document as unknown as DocumentInfoDto);
            }
        );
    }
}