import MasterDetailScreen from "@Toolkit/ReactClient/Components/Screen/MasterDetailScreen";
import React from "react";
import NDataGrid from "@HisPlatform/BoundedContexts/Productivity/Components/NDataGrid/NDataGrid";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { ScreenNavigationContextAdapter, ScreenNavigationContextStore, useScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import { provideNDataScreenStore, useNDataScreenStore } from "./NDataScreenStoreProvider";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import NDataGlobalFrontendActionButton from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/NDataGlobalFrontendActionButton";
import IFrontendListDataWithDefinition from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/IFrontendListDataWithDefinition";
import { iconNameType } from "@CommonControls/Icon";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import INDataRow from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataRow";
import { IRowBody, RowId } from "@CommonControls/DataGrid/IDataGridProps";
import IWorklistItemDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistItemDefinition";

export interface INDataScreenProps {
    _screenNavigationContext?: ScreenNavigationContextStore;
    afterGlobalActionsToolbar?: React.ReactNode;
    beforeGlobalActionsToolbar?: React.ReactNode;
    onGetDynamicListAsync: (frontendListParameters: FrontendListParameters) => Promise<IFrontendListDataWithDefinition>;
    isMasterDetailSeparatedMode?: boolean;
    iconName?: iconNameType;
    defaultExtraFilterVisibility?: boolean;
    extraFilter?: React.ReactNode;
    subtitle?: React.ReactNode;
    hasNewRow?: boolean;
    refreshListEvent?: TypedAsyncEvent;
    defaultPageTitle?: string;
    defaultOrderingColumnName?: string;
    defaultOrderingDirection?: 'asc'|'desc';
    leftFooter?: React.ReactNode;
    rightFooter?: React.ReactNode;
    onModalCancelled?: () => void;
    modalMaxHeight?: number | string;
    modalFixedHeight?: number | string;
    hasPrintButton?: boolean;
    hasRefreshButton?: boolean;
    onRenderRowBody?: (row: INDataRow, rowId: RowId, rowIndex: number, rowBodyItemDefinition: IWorklistItemDefinition) => IRowBody;
}

function NDataScreen(props: INDataScreenProps) {

    const store = useNDataScreenStore();
    const navigationContext = useScreenNavigationContext();

    return (
        <MasterDetailScreen
            title={store.title}
            subTitle={props.subtitle ?? store.subtitle}
            iconName={props.iconName}
            onSecondarySavedNew={store.refreshList}
            onSecondarySavedExisting={store.refreshList}
            onSecondaryDeleted={store.refreshList}
            isSeparatedMode={props.isMasterDetailSeparatedMode}
            leftFooter={props.leftFooter}
            rightFooter={props.rightFooter}
            onModalCancelled={props.onModalCancelled}
            modalMaxHeight={props.modalMaxHeight}
            modalFixedHeight={props.modalFixedHeight}
            toolbar={!store.isUnauthorized && (
                <>
                    {props.beforeGlobalActionsToolbar}
                    {store.globalActions?.map(ga => <NDataGlobalFrontendActionButton key={ga.action.id.value} descriptor={ga} automationId={ga.action.id.value} />)}
                    {props.afterGlobalActionsToolbar}
                </>
            )}
        >
            <NDataGrid
                onGetDynamicListAsync={props.onGetDynamicListAsync}
                onDefinitionLoaded={store.setListDefinition}
                onDataLoaded={store.setDataLoaded}
                selectedRowId={store.selectedRowId}
                refreshListEvent={store.refreshListEvent}
                fullHeight
                defaultExtraFilterVisibility={props.defaultExtraFilterVisibility}
                extraFilter={props.extraFilter}
                hasNewRow={props.hasNewRow}
                onUnauthorized={store.setUnauthorized}
                isDetailOpen={navigationContext.isSecondaryDetailOpen}
                hasPrintButton={props.hasPrintButton}
                hasRefreshButton={props.hasRefreshButton}
                defaultOrderingColumnName={props.defaultOrderingColumnName}
                defaultOrderingDirection={props.defaultOrderingDirection}
                onRenderRowBody={props.onRenderRowBody}
            />
        </MasterDetailScreen>
    );
}

export default connect<INDataScreenProps>(
    provideNDataScreenStore(NDataScreen),
    new ScreenNavigationContextAdapter()
);