import React from "react";
import { Menu } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import MenuItem from "./MenuItem";
import ToggleMenuItem from "./ToggleMenuItem";
import MenuController from "./MenuController";
import Guid from "@Toolkit/CommonWeb/Guid";
import SubMenuItem from "@CommonControls/ContextMenu/SubMenuItem";
import MenuSeparator from "@CommonControls/ContextMenu/MenuSeparator";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import MenuProvider from "./MenuProvider";

export interface IContextMenuProps {
    id?: string;
}

export default class ContextMenu extends React.Component<IContextMenuProps, undefined> {


    public static Item = MenuItem;
    public static ToggleItem = ToggleMenuItem;
    public static SubMenuItem = SubMenuItem;
    public static Provider = MenuProvider;
    public static Controller = MenuController;
    public static Separator = MenuSeparator;


    private generatedId = Guid.newGuid();

    public get id() {
        return this.props.id || this.generatedId;
    }

    constructor(props: IContextMenuProps) {
        super(props);
    }

    public render() {
        if (isNullOrUndefined(this.props.children)) {
            return (
                <></>
            );
        }
        return (
            <Menu id={this.id} style={{ zIndex: 1100 }}>
                {this.props.children}
            </Menu>
        );
    }
}
