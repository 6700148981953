import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import IServiceRequestHeaderExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestHeaderExtensionPointProps";

@Di.injectable()
export default class EhiErrorSummaryExtensionComponentService implements IExtensionComponentService<IServiceRequestHeaderExtensionPointProps> {

    public isVisible(props: IServiceRequestHeaderExtensionPointProps) {
        return props?.extensionData !== null;
    }

    public shouldRerunIsVisible(props: IServiceRequestHeaderExtensionPointProps, prevProps: IServiceRequestHeaderExtensionPointProps) {
        return props.extensionData !== prevProps?.extensionData;
    }
}