import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import DocumentId from "@Primitives/DocumentId.g";
import TemplateId from "@Primitives/TemplateId.g";
import PatientId from "@Primitives/PatientId.g";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import { getTemplateBasedDocumentDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentDtoMapper";
import DocumentAction from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentAction.g";
import DocumentKind from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentKind.g";
import BinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/BinaryDocument";
import DocumentBase from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentBase";
import DocumentFile from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentFile";
import ITemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ITemplateBasedDocument";
import TemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TemplateBasedDocument";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { mapDocumentWithLockInfo, getNewPatientRelatedTemplateDocument, getDocumentInfo } from "@HisPlatform/Packages/Patients/Screens/DocumentScreen/DocumentStoreMapper";
import IRefreshDocumentResult from "@HisPlatform/Packages/Patients/Screens/DocumentScreen/IRefreshDocumentResult";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";

@Di.injectable()
export default class DocumentScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("IPatientsClient") private readonly patientsApiClient: Proxy.IPatientsClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader) {
        super(referenceDataLoader);
    }

    public getPatientRelatedDocumentById(documentId: DocumentId, requestLock: boolean = false) {
        return this.executeOperationAsync<DocumentBase, Proxy.GetPatientRelatedDocumentByIdCommandResponse>(
            () => this.patientsApiClient.getPatientRelatedDocumentByIdCommandAsync(CreateRequestId(), new Proxy.GetPatientRelatedDocumentByIdControllerDto({
                documentId: documentId,
                requestLock: requestLock
            })),
            response => {
                let returnDocument = null;
                if (response.document.documentKind === DocumentKind.Binary) {
                    returnDocument = new BinaryDocument();
                } else if (response.document.documentKind === DocumentKind.TemplateBased) {
                    returnDocument = new TemplateBasedDocument();
                }

                mapDocumentWithLockInfo(returnDocument, response);

                return returnDocument;
            }
        );
    }

    public getNewPatientRelatedTemplateBasedDocument(templateId: TemplateId, patientId: PatientId, description: string) {
        return this.executeOperationAsync<TemplateBasedDocument, Proxy.GetNewPatientRelatedTemplateBasedDocumentInfoQueryResponse>(
            () => this.patientsApiClient.getNewPatientRelatedTemplateBasedDocumentInfoQueryAsync(CreateRequestId(), patientId.value, templateId.value),
            response => {
                const returnDocument = getNewPatientRelatedTemplateDocument(response.newTemplateBasedDocumentInfoDto, description);
                return returnDocument;
            }
        );
    }

    public saveNewPatientRelatedTemplateBasedDocumentCommandAsync(newDocument: TemplateBasedDocument, patientId: PatientId) {
        return this.executeOperationAsync<ITemplateBasedDocument, Proxy.SaveNewPatientRelatedTemplateBasedDocumentCommandResponse>(
            () => this.patientsApiClient.saveNewPatientRelatedTemplateBasedDocumentCommandAsync(CreateRequestId(), new Proxy.SaveNewPatientRelatedTemplateBasedDocumentControllerDto({
                patientId: patientId,
                templateBasedDocumentDto: getTemplateBasedDocumentDto(newDocument)
            })),
            response => {
                return {
                    documentInfo: getDocumentInfo(response.documentInfo),
                    validationResult: mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult)
                } as ITemplateBasedDocument;
            }
        );
    }

    public refreshPatientRelatedTemplateBasedDocumentCommandAsync(document: TemplateBasedDocument) {
        return this.executeOperationAsync<IRefreshDocumentResult, Proxy.RefreshPatientRelatedTemplateBasedDocumentCommandResponse>(
            () => this.patientsApiClient.refreshPatientRelatedTemplateBasedDocumentCommandAsync(CreateRequestId(), new Proxy.RefreshPatientRelatedTemplateBasedDocumentControllerDto({
                documentId: document.id,
                templateContentToken: document.templateContentToken,
                currentDocument: document.documentFile.getBase64()
            })),
            response => {
                return {
                    hasChanges: response.hasChanges,
                    refreshedDocument: DocumentFile.create(response.refreshedDocument)
                } as IRefreshDocumentResult;
            }
        );
    }

    public updatePatientRelatedTemplateBasedDocument(document: TemplateBasedDocument, documentAction?: DocumentAction) {
        return this.executeOperationAsync<ITemplateBasedDocument, Proxy.UpdatePatientRelatedTemplateBasedDocumentCommandResponse>(
            () => this.patientsApiClient.updatePatientRelatedTemplateBasedDocumentCommandAsync(CreateRequestId(), new Proxy.UpdatePatientRelatedTemplateBasedDocumentControllerDto({
                documentId: document.id,
                content: document.documentFile.getBase64(),
                lockId: document.lockInfo?.lockId,
                rowVersion: document.rowVersion,
                releaseLockIfSuccessful: false,
                documentAction: documentAction
            })),
            response => {
                return {
                    documentInfo: getDocumentInfo(response.documentInfo),
                    validationResult: mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult),                    
                } as ITemplateBasedDocument;
            }
        );
    }
}