import ITreeViewNode from "@CommonControls/TreeView/ITreeViewNode";
import { isNullOrUndefined, isNullOrWhiteSpace } from "@Toolkit/CommonWeb/NullCheckHelpers";

export function filterTree(text: string, tree: ITreeViewNode[]) {
    if (isNullOrUndefined(text) || isNullOrWhiteSpace(text)) {
        return tree;
    } else {
        const filteredStructure: ITreeViewNode[] = [];
        for (const currentStructure of tree) {
            const result = filterTreeNode(text, currentStructure, false);

            if (result !== null) {
                filteredStructure.push(result);
            }
        }
        return filteredStructure;
    }
}

export function checkMatchForNode(text: string, node: ITreeViewNode) {
    if (!node) {
        return false;
    } 
    return node.filterableContent.toLowerCase().includes(text.toLocaleLowerCase());
}

const filterTreeNode = (text: string, currentNode: ITreeViewNode, keepElement: boolean) => {
    const newNode = Object.create(currentNode);

    const filteredChildren: ITreeViewNode[] = [];

    const elementShouldBeKept = checkMatchForNode(text, newNode) || keepElement;

    if (newNode.children) {
        for (const child of newNode.children) {
            const filteredChild = filterTreeNode(text, child, elementShouldBeKept);

            if (filteredChild !== null) {
                filteredChildren.push(filteredChild);
            }
        }
    }

    if (checkMatchForNode(text, newNode) || filteredChildren.length > 0 || elementShouldBeKept) {
        newNode.children = filteredChildren.length > 0 ? filteredChildren : null;
        return newNode;
    } else {
        return null;
    }
};