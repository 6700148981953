import React from "react";
import MedicationEquipmentClassificationTreeNode from "./MedicationEquipmentClassificationTreeNode";
import { ITableFrameColumn } from "@CommonControls/TreeGrid/TableFrame";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IMedicationEquipmentClassificationVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/IMedicationEquipmentClassificationVersion";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import MedicationsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicationsApiAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { withHisErrorBoundary } from "@HisPlatform/Components/HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import _ from "@HisPlatform/Common/Lodash";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface IMedicationEquipmentClassificationTreeGridDependencies {
    medicationsApiAdapter: MedicationsApiAdapter;
    
}

interface IMedicationEquipmentClassificationTreeGridProps {
    _dependencies?: IMedicationEquipmentClassificationTreeGridDependencies;

    medicationEquipmentClassificationStructure: MedicationEquipmentClassificationTreeNode[];
    setMedicationEquipmentClassificationStructure: (newValue: MedicationEquipmentClassificationTreeNode[]) => void;
    onCheckNode: (node: MedicationEquipmentClassificationTreeNode) => void;
    isOpen: boolean;
    setIsOpen: (newValue: boolean) => void;
    childrenOnly?: boolean;
    validOn?: LocalDate;
}

@State.observer
class MedicationEquipmentClassificationTreeGrid extends React.Component<IMedicationEquipmentClassificationTreeGridProps> {

    private get medicationsApiAdapter() { return this.props._dependencies.medicationsApiAdapter; }

    private get validOn() {
        return this.props.validOn || LocalDate.today();
    }

    @State.boundLoadingState("isLoading")
    private async initializeMedicationEquipmentClassificationsAsync() {
        let medicationEquipmentClassifications = (await this.medicationsApiAdapter.getAllMedicationEquipmentClassifications(this.validOn)).value;

        if (this.props.childrenOnly) {
            medicationEquipmentClassifications = medicationEquipmentClassifications.filter(m => m.code.length === 10);
        }

        this.props.setMedicationEquipmentClassificationStructure(this.buildMedicationEquipmentClassificationStructure(medicationEquipmentClassifications));
    }

    @State.action.bound
    private onSelectNode(node: MedicationEquipmentClassificationTreeNode) {
        if (node.children?.length > 0) {
            node.isOpen = !node.isOpen;
        }
    }

    @State.bound
    private buildMedicationEquipmentClassificationStructure(medicationEquipmentClassifications: IMedicationEquipmentClassificationVersion[]): MedicationEquipmentClassificationTreeNode[] {
        const sortedByLength = _.orderBy(medicationEquipmentClassifications, i => i.code.length);
        const structure: MedicationEquipmentClassificationTreeNode[] = [];
        while (sortedByLength.length > 0) {
            const firstItem = sortedByLength[0];
            sortedByLength.splice(0, 1);
            const children = this.getChildNodes(firstItem, sortedByLength);
            structure.push(new MedicationEquipmentClassificationTreeNode(
                firstItem.id,
                firstItem.code,
                firstItem.name,
                children.length === 0,
                children));
        }

        return _.orderBy(structure, i => i.code);
    }

    @State.bound
    private getChildNodes(parentNode: IMedicationEquipmentClassificationVersion, medicationEquipmentClassifications: IMedicationEquipmentClassificationVersion[]): MedicationEquipmentClassificationTreeNode[] {
        const childNodes: MedicationEquipmentClassificationTreeNode[] = [];
        const children = medicationEquipmentClassifications
            .filter(i => i.code.startsWith(parentNode.code) && i.code.length === parentNode.code.length + 2);

        for (const child of children) {
            const index = medicationEquipmentClassifications.indexOf(child);
            medicationEquipmentClassifications.splice(index, 1);
            const grandChildren = this.getChildNodes(child, medicationEquipmentClassifications);
            childNodes.push(new MedicationEquipmentClassificationTreeNode(
                child.id,
                child.code,
                child.name,
                grandChildren.length === 0,
                grandChildren));
        }

        return _.orderBy(childNodes, i => i.code);
    }

    private columns: ITableFrameColumn[] = [
        {
            title: StaticHunSocialSecurityMedicationRequestResources.Medication.MedicationEquipmentClassifications.Description,
            width: 100,
            widthMode: "percent",
            displayValueGetter: (r: MedicationEquipmentClassificationTreeNode) => `${r.code} - ${r.name.charAt(0).toUpperCase() + r.name.substring(1).toLowerCase()}`
        }
    ];

    public componentDidMount() {
        dispatchAsyncErrors(this.initializeMedicationEquipmentClassificationsAsync(), this);
    }

    public render() {
        return (
            <Ui.GroupBox
                style={{ margin: "10px 4px" }}
                title={StaticHunSocialSecurityMedicationRequestResources.Medication.MedicationEquipmentClassifications.Title}
                visualStyle="standard"
                isOpen={this.props.isOpen}
                onOpenStateChanged={this.props.setIsOpen}
                isCollapsible
                hasBorder>
                <Ui.TreeGrid
                    multiSelect
                    columns={this.columns}
                    data={this.props.medicationEquipmentClassificationStructure}
                    onCheckNode={this.props.onCheckNode}
                    onSelectNode={this.onSelectNode}
                    fixedHeight={300}
                />
            </Ui.GroupBox>
        );
    }
}

export default connect(
    withHisErrorBoundary(MedicationEquipmentClassificationTreeGrid),
    new DependencyAdapter<IMedicationEquipmentClassificationTreeGridProps, IMedicationEquipmentClassificationTreeGridDependencies>(container => {
        return {
            medicationsApiAdapter: container.resolve("MedicationsApiAdapter")
        };
    })
);