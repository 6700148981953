import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalConditionHistoryItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemBase";

export default class AutonomyDisabilityStatementHistoryItem extends MedicalConditionHistoryItemBase {
    @State.observable.ref public description: string;

    @State.action.bound
    public setDescription(newValue: string) {
        this.description = newValue;
    }
}
