import _ from "@HisPlatform/Common/Lodash";
import AllergyIntoleranceAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/AllergyIntoleranceAssignmentBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SubstanceId from "@Primitives/SubstanceId.g";
import AllergyIntoleranceCategoryId from "@Primitives/AllergyIntoleranceCategoryId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ISubstanceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/ISubstanceVersion";
import AllergyIntoleranceType from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/AllergiesAndIntolerances/Enum/AllergyIntoleranceType.g";

export default class SubstanceAllergyIntoleranceAssignment extends AllergyIntoleranceAssignmentBase {

    @State.observable.ref public substanceVersionSelector: IEntityVersionSelector<SubstanceId> = null;
    @State.observable.ref public type: AllergyIntoleranceType = AllergyIntoleranceType.Allergy;
    @State.observable.ref public categoryId: AllergyIntoleranceCategoryId = AllergyIntoleranceCategoryId.medication;
    @State.observable.ref public substanceVersion: ISubstanceVersion = null;

    @State.action.bound
    public setSubstance(medicationId: SubstanceId) {
        this.initializeCreatedAtIfEmpty();
        this.substanceVersionSelector = {id: medicationId, validOn: LocalDate.createFromMoment(this.createdAt)};
    }

    @State.action.bound
    public setType(newValue: AllergyIntoleranceType) {
        this.type = newValue;
    }
}