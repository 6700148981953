exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HomeMenu_worklist-definition-selectbox-container_G5xaV {\n  margin-left: -11px;\n}\n.HomeMenu_worklist-definition-selectbox-container_G5xaV > div {\n  width: 235px;\n}\n.HomeMenu_worklist-definition-selectbox-container_G5xaV .__control {\n  background: transparent;\n  border: none;\n}\n.HomeMenu_worklist-definition-selectbox-container_G5xaV .__control:hover,\n.HomeMenu_worklist-definition-selectbox-container_G5xaV .__control:focus-within {\n  border: none;\n  box-shadow: none;\n}\n.HomeMenu_worklist-definition-selectbox-container_G5xaV .__control .__single-value {\n  font-family: TitilliumWebBold;\n  font-size: 14px;\n  text-transform: uppercase;\n  color: #FFF;\n  top: 47%;\n}\n.HomeMenu_worklist-definition-selectbox-container_G5xaV .__control .__input {\n  color: #FFF;\n}\n.HomeMenu_worklist-definition-selectbox-container_G5xaV .__control .__indicators svg {\n  fill: #FFF;\n  top: 3px !important;\n}\n.HomeMenu_container_whToX {\n  height: calc(100vh - 50px);\n  display: flex !important;\n  flex-direction: column;\n  overflow-y: hidden;\n  gap: 2px;\n  width: 40vw;\n}\n.HomeMenu_search-box_3jrXE {\n  flex: 0 0 auto;\n}\n.HomeMenu_items_Wz9rM {\n  flex: 1;\n  height: auto;\n  overflow-y: auto;\n}\n.HomeMenu_selectedHomeContent_3b54b {\n  font-family: TitilliumWebBold;\n  text-transform: uppercase;\n  font-size: 14px;\n}\n", ""]);

// exports
exports.locals = {
	"worklist-definition-selectbox-container": "HomeMenu_worklist-definition-selectbox-container_G5xaV",
	"worklistDefinitionSelectboxContainer": "HomeMenu_worklist-definition-selectbox-container_G5xaV",
	"__control": "__control",
	"control": "__control",
	"__single-value": "__single-value",
	"singleValue": "__single-value",
	"__input": "__input",
	"input": "__input",
	"__indicators": "__indicators",
	"indicators": "__indicators",
	"container": "HomeMenu_container_whToX",
	"search-box": "HomeMenu_search-box_3jrXE",
	"searchBox": "HomeMenu_search-box_3jrXE",
	"items": "HomeMenu_items_Wz9rM",
	"selectedHomeContent": "HomeMenu_selectedHomeContent_3b54b"
};