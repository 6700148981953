import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import { getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import PatientAppointmentRoutes from "@HisPlatform/Components/Pages/Patient/PatientAppointmentRoutes";
import Di from "@Di";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import CreatePatientAction from "@HisPlatform/Packages/Patients/FrontendActions/CreatePatientAction.g";
import SavePatientDataAction from "@HisPlatform/Packages/Patients/FrontendActions/SavePatientDataAction.g";
import ShowNewPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowNewPatientScreenAction.g";
import ShowPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientScreenAction.g";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import PatientId from "@Primitives/PatientId.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import IFormLogicRegistry, { IFormLogicExecutor } from "@HisPlatform/Services//Definition/FormLogicRegistry/IFormLogicRegistry";
import LockAcquirerOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockAcquirerOperationInfo";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import { ActionContinuation } from "@Toolkit/ReactClient/ActionProcessing/ActionContinuation";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { IPatientAdministrativeDataScreenProps } from "./PatientAdministrativeDataScreen";
import PatientAdministrativeDataScreenApiAdapter from "./PatientAdministrativeDataScreenApiAdapter";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import CompositeArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/CompositeArrayFormFieldData";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import BooleanFormFieldData from "@Toolkit/FormEngine/Model/Data/BooleanFormFieldData";
import PatientDocumentTypeId from "@Primitives/PatientDocumentTypeId.g";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import React from "react";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import IPatientDocumentFormattingRegistry from "@PluginInterface/PatientDocumentFormatting/IPatientDocumentFormattingRegistry";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import CombinePatientsAction from "@HisPlatform/Packages/Patients/FrontendActions/CombinePatientsAction.g";
import _ from "@HisPlatform/Common/Lodash";
import { ChangeAction } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";

@Di.injectable()
export default class PatientAdministrativeDataScreenStore extends EditorScreenPanelStoreBase<IPatientAdministrativeDataScreenProps> implements ILoadablePanelStore {
    @State.observable.ref public form: IForm = null;
    private formEngineDefinition: IFormDefinition;
    private formLogicExecutor: IFormLogicExecutor = null;

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("AuthorizationService") private authorizationService: AuthorizationService,
        @Di.inject("PatientAdministrativeDataScreenApiAdapter") private patientAdministrativeDataScreenApiAdapter: PatientAdministrativeDataScreenApiAdapter,
        @Di.inject("IFormLogicRegistry") private formLogicRegistry: IFormLogicRegistry,
        @Di.inject("IFormEngineReferenceDataStore") private formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("ILocalizationService") private localizationSService2: ILocalizationService,
        @Di.inject("CommonReferenceDataDataStore") private readonly commonReferenceDataStore: CommonReferenceDataDataStore,
        @Di.inject("IPatientDocumentFormattingRegistry") private readonly patientDocumentFormattingRegistry: IPatientDocumentFormattingRegistry) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    @State.computed
    public get formDefinitionId() {
        return this.form?.definitionId;
    }

    @State.computed
    public get savePatientDataAction() {
        return ActionDescriptor.fromAction(new SavePatientDataAction());
    }

    @State.computed
    public get createPatientAction() {
        return ActionDescriptor.fromAction(new CreatePatientAction());
    }

    @State.computed
    public get combinePatientsAction() {
        return ActionDescriptor.fromAction(new CombinePatientsAction());
    }

    @State.computed
    public get isCreateNewPatientScreen() {
        return this.props.action instanceof ShowNewPatientScreenAction;
    }

    @State.computed public get isAdmissionDisabled() {
        return this.vIsReadOnly;
    }

    @State.computed
    public get showNewAdmitPatientScreenAction() {
        if (!isNullOrUndefined(this.form?.data?.Content)) {
            const idField = getField<ReferencedEntityFormFieldData>(this.form.data.Content, "PatientId");
            const patientId = new PatientId(idField?.value?.toString());
            if (this.props.action instanceof ShowPatientScreenAction) {
                return ActionDescriptor.fromAction(
                    new ShowNewAdmitPatientScreenAction(ScreenDisplayMode.Full, patientId, this.props.action.appointmentScheduleEntryId, null)
                );
            } else {
                return ActionDescriptor.fromAction(
                    new ShowNewAdmitPatientScreenAction(ScreenDisplayMode.Full, patientId, null, null)
                );
            }
        }
        return ActionDescriptor.fromAction(
            new ShowNewAdmitPatientScreenAction(ScreenDisplayMode.Full, null, null, null)
        );
    }

    @State.computed
    public get actionForSaveButton() {
        return this.isCreateNewPatientScreen ? this.createPatientAction : this.savePatientDataAction;
    }

    @State.computed
    public get isPatientAdministrativeDataScreen() {
        return this.props.action instanceof ShowPatientScreenAction;
    }

    @State.computed
    public get hasPermissionForSave() {
        return this.authorizationService.hasPermissionForDescriptor(this.actionForSaveButton);
    }

    @State.computed
    public get hasPermissionForCombine() {
        return this.authorizationService.hasPermissionForDescriptor(this.combinePatientsAction);
    }

    @State.computed
    public get vIsReadOnly() {
        return this.canAcquireLock || !this.hasPermissionForSave;
    }

    public async loadCoreAsync(): Promise<any> {
        if (this.props.action instanceof ShowNewPatientScreenAction) {
            const response = await this.patientAdministrativeDataScreenApiAdapter.getNewPatientAdministrativeScreenDataAsync();
            this.setForm(response.result, response.result.validationResults);
        }
        else if (this.props.action instanceof ShowPatientScreenAction) {
            const response = await this.patientAdministrativeDataScreenApiAdapter.getPatientAdministrativeScreenDataAsync(this.props.action.patientId, true);
            if (response.operationInfo instanceof LockAcquirerOperationInfo) {
                State.runInAction(() => this.lockInfo = (response.operationInfo as LockAcquirerOperationInfo).lockInfo);
            }
            this.setForm(response.result, response.result.validationResults);
        }
        const formEngineDefinition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(this.form.definitionId);
        this.formEngineDefinition = formEngineDefinition;

        this.formLogicExecutor = this.formLogicRegistry.getExecutorForForm(this.form, this.showScreenAction, this.formEngineDefinition.name, this.formEngineDefinition);
        await this.formLogicExecutor.formLoadedAsync();
    }

    @State.bound
    public async onValidateAsync(): Promise<any> {
        if (!this.form || this.vIsReadOnly) {
            return;
        }

        let formValidationResult: IForm;
        if (this.isCreateNewPatientScreen) {
            const operationResult = await this.patientAdministrativeDataScreenApiAdapter.createPatientAdministrativeDataAsync(this.form, true);
            formValidationResult = operationResult.result;
        } else {
            const operationResult = await this.patientAdministrativeDataScreenApiAdapter.updatePatientAdministrativeDataAsync(this.form, false, this.lockInfo, true);
            formValidationResult = operationResult.result;
        }

        await this.checkForNotValidatedPatientNonUniqueDocumentsAsync(formValidationResult.validationResults, false);
        this.vSetValidationResults(formValidationResult.validationResults);
        return formValidationResult.validationResults;
    }

    @State.bound
    private async checkForNotValidatedPatientNonUniqueDocumentsAsync(validationResults: IClientValidationResult[], isSave: boolean) {
        const isDataCleansingNeeded = getField<BooleanFormFieldData>(this.form.data.Content, "IsDataCleansingNeeded");
        if (!isDataCleansingNeeded?.value) {
            return;
        }

        const uniqueDocumentValidationProblems: IClientValidationProblem[] = [];
        for (const validationResult of validationResults) {
            uniqueDocumentValidationProblems.push(...validationResult.problems.filter(problem => problem.propertyPath.includes("Documents") && problem.ruleId === "ShouldBeUnique"));
        }

        const patientId = getField<ReferencedEntityFormFieldData>(this.form.data.Content, "PatientId");
        const documents = getField<CompositeArrayFormFieldData>(this.form.data.Content, "Documents");

        for (const validationProblem of uniqueDocumentValidationProblems) {
            const regex = new RegExp("Documents\\[(\\d+)]");
            const match = validationProblem.propertyPath.match(regex);
            const documentIndex = parseInt(match?.[1]);
            const document = documents.value[documentIndex];
            const identifierValue = getField<TextFormFieldData>(document, "IdentifierValue");
            const documentTypeId = getField<ReferencedEntityFormFieldData>(document, "DocumentTypeId");
            const response = await this.patientAdministrativeDataScreenApiAdapter.getPatientForNonUniqueDocument(identifierValue.value, new PatientDocumentTypeId(documentTypeId.value.toString()), new PatientId(patientId.value.toString()));
            const documentType = await this.commonReferenceDataStore.identifierSystemMap.getOrLoadLocalizationAsync(response.result.documentTypeIdentifierSystemId);

            const dialogResources = StaticCareResources.PatientRegister.PatientAdministrativeDataScreen.Dialog;
            const isNonUniqueIdentifierDialogShown = getField<BooleanFormFieldData>(document, "IsNonUniqueIdentifierDialogShown");
            if (isSave || !isNonUniqueIdentifierDialogShown.value) {
                const dialogResult = await this.dialogService.yesNo(
                    StaticCareResources.PatientRegister.PatientAdministrativeDataScreen.Dialog.CombineUnvalidatedPatientTitle,
                    (
                        <>
                            <p>{dialogResources.CombineUnvalidatedPatientMessage}</p>
                            <p style={{ textAlign: "center" }}><b>{this.localizationSService2.localizePersonName(response.result.patientName)}</b>{` ${documentType.Name}: ${this.patientDocumentFormattingRegistry.format(response.result.documentTypeIdentifierSystemId.value, identifierValue.value)} ${dialogResources.BirthDateLabel}: ${this.localizationSService2.localizeDate(response.result.birthDate)}`}</p>
                        </>
                    ));

                if (dialogResult.resultCode === DialogResultCode.Yes) {
                    if (this.hasPermissionForCombine) {
                        const combineResponse = await this.patientAdministrativeDataScreenApiAdapter.combinePatientsAsync(response.result.patientId, new PatientId(patientId.value.toString()));
                        if (combineResponse.operationInfo.isPersisted) {
                            this.notificationService.success(StaticCareResources.PatientRegister.CombinePatientsScreen.Messages.CombineSuccessfulMessage);

                            let appointmentScheduleEntryId = null;
                            if (this.props.action instanceof ShowPatientScreenAction) {
                                appointmentScheduleEntryId = this.props.action.appointmentScheduleEntryId;
                            }

                            await this.props._actionDispatcher.dispatchAsync(new ShowPatientScreenAction(ScreenDisplayMode.Full, response.result.patientId, appointmentScheduleEntryId));
                            break;
                        }
                    } else {
                        this.notificationService.error(this.localizationService.staticResources.common.unauthorizedOperation);
                    }
                } else {
                    State.runInAction(() => {
                        isNonUniqueIdentifierDialogShown.value = true;
                    });
                }
            }
        }
    }

    @State.bound
    public async onCreateNewAppointmentAsync() {
        if (!this.vIsReadOnly) {
            const saved = await this.updateAsync(true);
            if (saved.isPersisted) {
                const idField = getField<ReferencedEntityFormFieldData>(saved.result.data.Content, "PatientId");
                const patientId = new PatientId(idField.value.toString());
                this.props._dependencies.routingController.push(
                    PatientAppointmentRoutes.appointmentList.makeRoute({
                        patientId: patientId.value,
                        mode: "read-write",
                        appointmentId: "null",
                        useCase: getUseCaseAsUrlParam(
                            new UseCaseIdentifier(UseCases.newAppointment),
                            UseCaseDisplayMode.MasterDetail,
                            [new UseCaseArgument(new AppointmentScheduleEntryId("new"), "appointmentId")])
                    })
                );
            }
        }
    }

    @State.bound
    public showDeletingInsurerWithFinancedCareActivitiesError() {
        this.notificationService.error(StaticCareResources.PatientRegister.PatientAdministrativeDataScreen.Messages.DeletingInsurerWithFinancedCareActivitiesErrorMessage);
        return true;
    }

    @State.computed
    protected get contentToDirtyCheck() {
        return [this.form?.data.Content];
    }

    @State.bound
    public async onSaveAsync(): Promise<any> {
        await this.checkForNotValidatedPatientNonUniqueDocumentsAsync(this.validationResults, true);
        if (this.isCreateNewPatientScreen) {
            return await this.onCreateAsync();
        } else {
            return await this.onUpdateAsync();
        }
    }

    protected saveFunction: () => Promise<boolean> = this.onSaveAsync;

    protected get showScreenAction(): ShowNewPatientScreenAction | ShowPatientScreenAction {
        return this.props.action;
    }

    @State.bound
    public async beforeAdmitPatientAsync(): Promise<{ actionContinuation: ActionContinuation, action?: FrontendActionBase }> {
        if (!this.vIsReadOnly) {
            const saved = this.isCreateNewPatientScreen ? await this.createAsync() : await this.updateAsync(true);
            if (saved.isPersisted) {
                const idField = getField<ReferencedEntityFormFieldData>(saved.result.data.Content, "PatientId");
                const patientId = new PatientId(idField.value.toString());
                if (this.props.action instanceof ShowPatientScreenAction) {
                    return {
                        actionContinuation: ActionContinuation.Continue,
                        action: new ShowNewAdmitPatientScreenAction(ScreenDisplayMode.Full, patientId, this.props.action.appointmentScheduleEntryId, null)
                    };
                } else {
                    return {
                        actionContinuation: ActionContinuation.Continue,
                        action: new ShowNewAdmitPatientScreenAction(ScreenDisplayMode.Full, patientId, null, null)
                    };
                }
            } else {
                return { actionContinuation: ActionContinuation.Break };
            }
        } else {
            return { actionContinuation: ActionContinuation.Continue };
        }
    }

    private async createAsync() {
        const saveResult = await this.handleSaveResultAsync(() =>
            this.patientAdministrativeDataScreenApiAdapter.createPatientAdministrativeDataAsync(this.form));
        this.vSetValidationResults(saveResult.result.validationResults);
        return saveResult;
    }

    private async updateAsync(releaseLock: boolean) {
        const saveResult =
            await this.handleSaveResultAsync<IForm>(() => this.patientAdministrativeDataScreenApiAdapter.updatePatientAdministrativeDataAsync(this.form, releaseLock, this.lockInfo));

        this.vSetValidationResults(saveResult.result.validationResults);

        if (saveResult.isPersisted && releaseLock) {
            State.runInAction(() => this.lockInfo = null);
        }

        if (saveResult.isPersisted && !releaseLock) {
            State.runInAction(() => this.form.rowVersions = saveResult.result.rowVersions);
        }

        await this.formLogicExecutor.fieldChangedAsync("set");
        return saveResult;
    }

    @State.action.bound
    protected vSetValidationResults(newValue: IClientValidationResult[]): void {
        this.validationResults = newValue;

        if (!isNullOrUndefined(this.form)) {
            this.form.validationResults = newValue;
        }
    }

    @State.bound
    public async onCreateAsync(): Promise<any> {
        const saveResult = await this.createAsync();

        if (saveResult.isPersisted) {
            const idField = getField<ReferencedEntityFormFieldData>(saveResult.result.data.Content, "PatientId");
            const newId = new PatientId(idField.value.toString());
            this.props._screenState.savedNew(newId, new ShowPatientScreenAction(this.props.action.displayMode, newId, null));
        }

        return saveResult.isPersisted;
    }

    @State.bound
    public async onUpdateAsync(): Promise<any> {
        const saveResult = await this.updateAsync(false);

        if (saveResult.isPersisted) {
            this.props._screenState.savedExisting();
        }

        return saveResult.isPersisted;
    }

    public readonly navigateToAdmitPatientAsync = this.async(async () => {
        if (this.props.action instanceof ShowPatientScreenAction)
            await this.props._actionDispatcher.dispatchAsync(new ShowNewAdmitPatientScreenAction(ScreenDisplayMode.Full, this.props.action.patientId,
                this.props.action.appointmentScheduleEntryId,
                null));
    });

    @State.bound
    public onCancelAsync(): Promise<any> {
        this.props._screenState.cancelled();
        return Promise.resolve();
    }


    @State.action.bound
    private setForm(form: IForm, validationResults: IClientValidationResult[]) {
        this.vSetValidationResults(validationResults);
        this.form = form;
    }

    private checkForNotValidatedPatientDocumentChange(propertyIdentifier: string) {

        if (isNullOrEmptyString(propertyIdentifier)) {
            return;
        }

        const isDataCleansingNeeded = getField<BooleanFormFieldData>(this.form.data.Content, "IsDataCleansingNeeded");
        if (!isDataCleansingNeeded?.value) {
            return;
        }

        const documentIdentifierValueRegex = new RegExp("Documents\\[(\\d+)]\\.IdentifierValue");
        const documentTypeIdRegex = new RegExp("Documents\\[(\\d+)]\\.DocumentTypeId");
        const documentIdentifierValueMatch = propertyIdentifier.match(documentIdentifierValueRegex);
        const documentTypeIdMatch = propertyIdentifier.match(documentTypeIdRegex);
        if (documentIdentifierValueMatch) {
            const documentIndex = parseInt(documentIdentifierValueMatch[1]);
            const isNonUniqueIdentifierDialogShown = getField<BooleanFormFieldData>(this.form.data.Content, `Documents[${documentIndex}].IsNonUniqueIdentifierDialogShown`);
            State.runInAction(() => {
                isNonUniqueIdentifierDialogShown.value = false;
            });
        } else if (documentTypeIdMatch) {
            const documentIndex = parseInt(documentTypeIdMatch[1]);
            const isNonUniqueIdentifierDialogShown = getField<BooleanFormFieldData>(this.form.data.Content, `Documents[${documentIndex}].IsNonUniqueIdentifierDialogShown`);
            State.runInAction(() => {
                isNonUniqueIdentifierDialogShown.value = false;
            });
        }
    }

    public readonly onDataChangeAsync = this.backgroundAsync(async (propertyIdentifier: string, value: any, action: ChangeAction) => {
        await this.formLogicExecutor.fieldChangedAsync(action, propertyIdentifier, value);
        this.checkForNotValidatedPatientDocumentChange(propertyIdentifier);
    });
}