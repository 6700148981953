import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import OrganizationUnit from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnit";
import StoreMapper from "@Toolkit/CommonWeb/ApiAdapter/StoreMapper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import OrganizationUnitStoreMapper from "./OrganizationUnitStoreMapper";
import Di from "@Di";

@Di.injectable()
export default class OrganizationUnitsStoreMapper extends StoreMapper<SimpleStore<OrganizationUnit[]>, Proxy.GetOrganizationUnitsByIdsQueryResponse> {

    constructor(
        @Di.inject("OrganizationUnitStoreMapper") private readonly organizationUnitStoreMapper: OrganizationUnitStoreMapper
    ) {
        super();
    }

    protected applyToStoreCore(target: SimpleStore<OrganizationUnit[]>, response: Proxy.GetOrganizationUnitsByIdsQueryResponse) {
        const mapped: OrganizationUnit[] = [];

        for (const ou of response.organizationUnits) {
            const organizationUnit = new OrganizationUnit();
            this.organizationUnitStoreMapper.applyToStore(organizationUnit, ou);
            mapped.push(organizationUnit);
        }

        target.value = mapped;
        target.operationInfo = createOperationInfo(response);
    }
}