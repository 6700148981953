/* auto-inject-disable */
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import Di from "@Di";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import { GetTypeNameWithPrefixExt } from "@HunEHealthInfrastructurePlugin/PluginHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IMedicationSubsidyClaimType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IMedicationSubsidyClaimType";
import MedicationPricingAndSubsidies from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationPricingAndSubsidies";
import MedicationId from "@Primitives/MedicationId.g";
import MedicationSubsidyStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/MedicationSubsidyStoreMapper";
import MedicationPricingStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/MedicationPricingStoreMapper";
import MedicationPricing from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationPricing";
import MedicationSubsidy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationSubsidy";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import OrganizationRestrictionTypeId from "@Primitives/OrganizationRestrictionTypeId.g";
import MedicationSubsidizedPricingTypeId from "@Primitives/MedicationSubsidizedPricingTypeId.g";
import { IMedicationInfo } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IMedicationInfo";
import MedicationSubsidyId from "@Primitives/MedicationSubsidyId.g";
import { IMedicationEquivalenceListItem } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationInfo/IMedicationEquivalence";
import CareActivityId from "@Primitives/CareActivityId.g";
import SubsidyOptionCheckResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/SubsidyOptionCheckResult";
import SubsidyOptionRestrictionCheckResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/SubsidyOptionRestrictionCheckResult";
import SubstanceBasedRestrictionId from "@Primitives/SubstanceBasedRestrictionId.g";
import ISubstanceBasedRestriction from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/ISubstanceBasedRestriction";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IExtendedMedicationListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/IExtendedMedicationListItem";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import PackagingUnitId from "@Primitives/PackagingUnitId.g";
import PackagingUnit from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/PackagingUnit";
import PractitionerId from "@Primitives/PractitionerId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import DesignatedOrganizationId from "@Primitives/DesignatedOrganizationId.g";
import DesignatedOrganization from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/DesignatedOrganization";
import DesignatedOrganizationStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/DesignatedOrganizationStoreMapper";
import { MedicationVersionDto } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { QueryPaging } from "@HisPlatform/BoundedContexts/CommonReferenceData/Api/Proxy.g";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import MedicationSelectorExtendedQueryOrderingFields from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/ReferenceData/Enum/MedicationSelectorExtendedQueryOrderingFields.g";
import MedicationSubsidyOptionCheckType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/ReferenceData/Prescription/Enum/MedicationSubsidyOptionCheckType.g";

@Di.injectable()
export default class ReferenceDataApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject(GetTypeNameWithPrefixExt("IPrescriptionClient", "MedicationRequest")) private readonly apiClient: Proxy.IPrescriptionClient,
        @Di.inject(GetTypeNameWithPrefixExt("IReferenceDataClient", "MedicationRequest")) private readonly referenceDataApiClient: Proxy.IReferenceDataClient,
        @Di.inject("MedicationSubsidyStoreMapper") private readonly subsidyStoreMapper: MedicationSubsidyStoreMapper,
        @Di.inject("MedicationPricingStoreMapper") private readonly pricingStoreMapper: MedicationPricingStoreMapper,
        @Di.inject("DesignatedOrganizationStoreMapper") private readonly designatedOrganizationStoreMapper: DesignatedOrganizationStoreMapper
    ) {
        super();
    }

    @State.bound
    public getMedicationSubsidyClaimTypesByIdsAsync(ids: MedicationSubsidyClaimTypeId[]) {
        return this.processOperationAsync(
            new SimpleStore<IMedicationSubsidyClaimType[]>(),
            async target => {
                const result = await this.apiClient.getMedicationSubsidyClaimTypesByIdsQueryAsync(CreateRequestId(),
                    new Proxy.GetMedicationSubsidyClaimTypesByIdsControllerDto({ ids: ids })
                );
                target.operationInfo = createOperationInfo(result);
                target.value = result.medicationSubsidyClaimTypes?.map(i => ({
                    id: i.id,
                    code: i.code,
                    name: i.name,
                    medicationSubsidizedPricingTypeId: i.medicationSubsidizedPricingTypeId
                })) || [];
            }
        );
    }

    @State.bound
    public getAllMedicationSubsidyClaimTypeIds() {
        return this.processOperationAsync(
            new SimpleStore<MedicationSubsidyClaimTypeId[]>(),
            async target => {
                const result = await this.apiClient.getAllMedicationSubsidyClaimTypeIdsQueryAsync(CreateRequestId());
                target.operationInfo = createOperationInfo(result);
                target.value = result.ids;
            }
        );
    }

    @State.bound
    public getMedicationPricingAndSubsidiesAsync(medicationId: MedicationId, validOn: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<MedicationPricingAndSubsidies>(),
            async target => {
                const result = await this.apiClient.getMedicationPricingAndSubsidiesQueryAsync(CreateRequestId(), new Proxy.GetMedicationPricingAndSubsidiesControllerDto({
                    medicationId: medicationId,
                    validOn: validOn
                }));
                target.operationInfo = createOperationInfo(result);

                const pricingStore = new MedicationPricing();
                this.pricingStoreMapper.applyToStore(pricingStore, result.pricing);

                target.value = {
                    id: pricingStore.medicationId,
                    pricing: pricingStore,
                    subsidies: result?.subsidies?.map(i => {
                        const newStore = new MedicationSubsidy();
                        this.subsidyStoreMapper.applyToStore(newStore, i);
                        return newStore;
                    })
                };
            }
        );
    }

    @State.bound
    public getOrganizationRestrictionTypesAsync() {
        return this.processOperationAsync(
            new SimpleStore<OrganizationRestrictionTypeId[]>(),
            async target => {
                const result = await this.apiClient.getOrganizationRestrictionTypeIdsQueryAsync(CreateRequestId(), LocalDate.today().stringify());
                target.operationInfo = createOperationInfo(result);
                target.value = result.organizationRestrictionTypeIds;
            }
        );
    }

    @State.bound
    public getMedicationSubsidizedPricingTypesAsync() {
        return this.processOperationAsync(
            new SimpleStore<MedicationSubsidizedPricingTypeId[]>(),
            async target => {
                const result = await this.apiClient.getMedicationSubsidizedPricingTypeIdsQueryAsync(CreateRequestId(), LocalDate.today().stringify());
                target.operationInfo = createOperationInfo(result);
                target.value = result.medicationSubsidizedPricingTypeIds;
            }
        );
    }

    @State.bound
    public getMedicationInfoByMedicationIdAsync(id: MedicationId) {
        return this.processOperationAsync(
            new SimpleStore<IMedicationInfo>(),
            async target => {
                const response = await this.referenceDataApiClient.getMedicationInfoByMedicationIdQueryAsync(CreateRequestId(), this.getMedicationInfoRequestDto(id));
                target.operationInfo = createOperationInfo(response);
                target.value = response.medicationInfo;
            }
        );
    }

    private getMedicationInfoRequestDto(id: MedicationId) {
        return new Proxy.GetMedicationInfoByMedicationIdControllerDto({
            medicationId: id
        } as Proxy.IGetMedicationInfoByMedicationIdControllerDto);
    }

    @State.bound
    public getMedicationIdsForMedicationEquivalenceByMedicationIdAsync(medicationId: MedicationId) {
        return this.processOperationAsync(
            new SimpleStore<MedicationId[]>(),
            async target => {
                const response = await this.referenceDataApiClient.getMedicationIdsForMedicationEquivalenceByMedicationIdQueryAsync(CreateRequestId(), medicationId.value);

                target.operationInfo = createOperationInfo(response);
                target.value = response && response.medicationIds;
            }
        );
    }

    @State.bound
    public getMedicationSubsidyVersionBySelectorsAsync(versionSelectors: Array<EntityVersionSelector<MedicationSubsidyId>>) {
        return this.processOperationAsync(
            new SimpleStore<MedicationSubsidy[]>(),
            async target => {
                const response = await this.apiClient.getMedicationSubsidyVersionsBySelectorsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetMedicationSubsidyVersionsBySelectorsControllerDto({
                        versionSelectors: versionSelectors.map(i => new Proxy.EntityVersionSelectorOfMedicationSubsidyId({
                            entityId: i.id,
                            validOn: i.validOn
                        }))
                    })
                );

                target.value = response?.subsidies?.map(i => {
                    const newItem = new MedicationSubsidy();
                    this.subsidyStoreMapper.applyToStore(newItem, i);
                    return newItem;
                });
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    private convertMedicationEquivalence(i: Proxy.MedicationEquivalenceListItemDto): IMedicationEquivalenceListItem {
        return {
            name: i.name,
            textAmount: i.textAmount,
            grossPrice: i.grossPrice,
            orderTypeId: i.orderTypeId,
            identifier: i.identifier,
            id: i.medicationId
        } as IMedicationEquivalenceListItem;
    }

    @State.bound
    public getMedicationEquivalenceListAsync(medicationId: MedicationId) {
        return this.processOperationAsync(
            new SimpleStore<IMedicationEquivalenceListItem[]>(),
            async target => {
                const response = await this.referenceDataApiClient.getMedicationEquivalenceListQueryAsync(CreateRequestId(), new Proxy.GetMedicationEquivalenceListControllerDto({
                    medicationId: medicationId
                } as Proxy.IGetMedicationEquivalenceListControllerDto));

                target.operationInfo = createOperationInfo(response);
                target.value = response.medicationEquivalences.map(i => this.convertMedicationEquivalence(i));
            }
        );
    }

    @State.bound
    public getMedicationEquivalenceOriginalIdAsync(medicationId: MedicationId) {
        if (!medicationId?.value) {
            throw Error("MedicationId is required!");
        }
        return this.processOperationAsync(
            new SimpleStore<number>(),
            async target => {
                const response = await this.referenceDataApiClient.getMedicationEquivalenceOriginalIdQueryAsync(CreateRequestId(), medicationId.value);
                target.operationInfo = createOperationInfo(response);
                target.value = response?.originalId;
            }
        );
    }

    @State.bound
    public checkMedicationSubsidyOptionAsync(medicationId: MedicationId, careActivityId: CareActivityId, checkType: MedicationSubsidyOptionCheckType, pricingTypeId: MedicationSubsidizedPricingTypeId, prescriber?: PractitionerId) {
        return this.processOperationAsync(
            new SimpleStore<SubsidyOptionCheckResult[]>(),
            async target => {
                const response = await this.apiClient.checkMedicationSubsidyOptionsQueryAsync(CreateRequestId(), new Proxy.CheckMedicationSubsidyOptionsControllerDto({
                    medicationSubsidyOptionCheckType: checkType,
                    careActivityId: careActivityId,
                    medicationId: medicationId,
                    prescriber: isNullOrUndefined(prescriber) ? null : prescriber
                }));

                target.operationInfo = createOperationInfo(response);
                target.value = response.results.map(i => new SubsidyOptionCheckResult(
                    i.id,
                    i.medicationSubsidyId,
                    i.conditionRestrictionsMatch,
                    i.restrictionResults.map(j => new SubsidyOptionRestrictionCheckResult(
                        j.id,
                        j.organizationTypeMatches,
                        j.professionRestrictionMatches,
                        j.restrictionTypeMatches,
                        j.restrictionIsDesignated
                    ))
                ));
            }
        );
    }

    @State.bound
    public getSubstanceBasedRestrictionsByIdsAsync(ids: SubstanceBasedRestrictionId[]) {
        return this.processOperationAsync(
            new SimpleStore<ISubstanceBasedRestriction[]>(),
            async target => {
                const result = await this.apiClient.getSubstanceBasedRestrictionsByIdsQueryAsync(CreateRequestId(),
                    new Proxy.GetSubstanceBasedRestrictionsByIdsControllerDto({ ids: ids })
                );
                target.operationInfo = createOperationInfo(result);
                target.value = result.substanceBasedRestrictions?.map(i => ({
                    id: i.id,
                    originalId: i.originalId,
                    description: i.description
                } as ISubstanceBasedRestriction));
            }
        );
    }

    @State.bound
    public medicationSelectorQueryAsync(
        name: string,
        medicationTypes: string[],
        originalMedicationTypes: string[],
        ordering: IOrderingState,
        paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<IExtendedMedicationListItem>>(),
            async (target) => {
                const response = await this.referenceDataApiClient.medicationSelectorExtendedQueryAsync(
                    CreateRequestId(),
                    new Proxy.MedicationSelectorExtendedControllerDto({
                        name: name,
                        medicationTypes: medicationTypes,
                        originalMedicationTypes: originalMedicationTypes,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfMedicationSelectorExtendedQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfMedicationSelectorExtendedQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: MedicationSelectorExtendedQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results.values.map(this.mapMedicationListItem)
                };
            }
        );
    }

    @State.bound
    public searchMedicationAsync(
        freeText: string,
        maxResultCount: number,
        validOn: LocalDate,
        medicationTypes: string[],
        originalMedicationTypes: string[]
    ) {
        return this.processOperationAsync(
            new SimpleStore<IExtendedMedicationListItem[]>(),
            async target => {
                const response = await this.referenceDataApiClient.searchMedicationsExtendedQueryAsync(
                    CreateRequestId(),
                    new Proxy.SearchMedicationsExtendedControllerDto({
                        freeText: freeText,
                        maxResultCount: maxResultCount,
                        validOn: validOn,
                        medicationTypes: medicationTypes,
                        originalMedicationTypes: originalMedicationTypes
                    }));

                target.operationInfo = createOperationInfo(response);
                target.value = response.medications.map(this.mapMedicationListItem);
            }
        );
    }

    @State.bound
    public getExtendedMedicationByIdAsync(medicationVersionSelector: IEntityVersionSelector<MedicationId>) {
        return this.processOperationAsync(
            new SimpleStore<IExtendedMedicationListItem>(),
            async target => {
                const response = await this.referenceDataApiClient.getExtendedMedicationByVersionSelectorQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetExtendedMedicationByVersionSelectorControllerDto({
                        medicationVersionSelector: new Proxy.EntityVersionSelectorOfMedicationId({ entityId: medicationVersionSelector.id, validOn: medicationVersionSelector.validOn })
                    })
                );
                target.operationInfo = createOperationInfo(response);
                target.value = this.mapMedicationListItem(response.medication);
            }
        );
    }

    @State.bound
    public getAllPackagingUnitIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<PackagingUnitId[]>(),
            async target => {
                const response = await this.apiClient.getAllPackagingUnitIdsQueryAsync(CreateRequestId());
                target.operationInfo = createOperationInfo(response);
                target.value = response.ids;
            }
        );
    }

    @State.bound
    public getPackagingUnitdsByIds(ids: PackagingUnitId[]) {
        return this.processOperationAsync(
            new SimpleStore<PackagingUnit[]>(),
            async target => {
                const response = await this.apiClient.getPackagingUnitsByIdsQueryAsync(CreateRequestId(), new Proxy.GetPackagingUnitsByIdsControllerDto({
                    ids: ids
                }));
                target.operationInfo = createOperationInfo(response);
                target.value = response.packagingUnits?.map(pu => {
                    const packagingUnit = new PackagingUnit();
                    packagingUnit.id = pu.id;
                    packagingUnit.name = pu.name;
                    packagingUnit.latinName = pu.latinName;
                    return packagingUnit;
                }) || [];
            }
        );
    }

    private mapMedicationListItem(i: MedicationVersionDto): IExtendedMedicationListItem {
        return {
            id: i.id,
            name: i.name,
            efficacy: i.efficacy,
            manufacturerName: i.manufacturerName,
            amountInPackage: i.amountInPackage,
            identifiers: i.identifiers.map(id => new Identifier(id.identifierSystemId, id.value)),
            isDeleted: i.isDeleted,
            medicationTypeId: i.medicationTypeId,
            medicationFormId: i.medicationFormId,
            substanceIds: i.substances,
            substances: [],
            dosageAmount: i.dosageAmount,
            dosageModeId: i.dosageModeId,
            textAmount: i.textAmount,
            registryIdentifier: i.registryIdentifier,
            substanceAmount: i.substanceAmount,
            daysSuppliedCanBeCalculated: i.daysSuppliedCanBeCalculated,
            validity: i.validity,
            medicationSubsidyClaimTypeIds: i.extensionData?.MedicationSubsidyClaimTypes.map(MedicationSubsidyClaimTypeId.fromJS)
        } as IExtendedMedicationListItem;
    }

    @State.bound
    public getDesignatedOrganizationVersionBySelectorsAsync(versionSelectors: Array<EntityVersionSelector<DesignatedOrganizationId>>) {
        return this.processOperationAsync(
            new SimpleStore<DesignatedOrganization[]>(),
            async target => {
                const response = await this.apiClient.getDesignatedOrganizationsByVersionSelectorsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetDesignatedOrganizationsByVersionSelectorsControllerDto({
                        versionSelectors: versionSelectors.map(i => new Proxy.EntityVersionSelectorOfDesignatedOrganizationId({
                            entityId: i.id,
                            validOn: i.validOn
                        }))
                    })
                );

                target.value = response?.designatedOrganizations?.map(i => {
                    const newItem = new DesignatedOrganization();
                    this.designatedOrganizationStoreMapper.applyToStore(newItem, i);
                    return newItem;
                });
                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}