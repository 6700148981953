import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import EhiCovidStatusReportId from "@Primitives/EhiCovidStatusReportId.g";
import EhiCovidStatusReportStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiCovidStatusReportStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import EhiCovidStatusReportStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCovidStatusReportStoreMapper";
import { GetTypeNameWithPrefix } from "@HunEHealthInfrastructurePlugin/PluginHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityId from "@Primitives/CareActivityId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";

/* auto-inject-disable */
@Di.injectable()
export default class EhiCovidStatusReportApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("ICareClient")) private apiClient: Proxy.ICareClient,
        @Di.inject("EhiCovidStatusReportStoreMapper") private storeMapper: EhiCovidStatusReportStoreMapper
    ) {
        super();
    }

    @State.bound
    public getByIdAsync(ehiCovidStatusReportId: EhiCovidStatusReportId, requestLock: boolean): Promise<EhiCovidStatusReportStore> {
        return this.processOperationAsync(
            new EhiCovidStatusReportStore(false),
            async target => {
                const response = await this.apiClient.getEhiCovidStatusReportByIdCommandAsync(
                    CreateRequestId(),
                    new Proxy.GetEhiCovidStatusReportByIdControllerDto({ ehiCovidStatusReportId: ehiCovidStatusReportId, requestLock: requestLock })
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public createEhiCovidStatusReportAsync(store: EhiCovidStatusReportStore, careActivityId: CareActivityId, isValidateOnly: boolean = false, isPermissionCheckOnly: boolean = false) {
        return this.processOperationAsync(
            new EhiCovidStatusReportStore(false),
            async target => {
                const response = await this.apiClient.createEhiCovidStatusReportCommandAsync(
                    CreateRequestId(),
                    new Proxy.CreateEhiCovidStatusReportControllerDto({
                        ehiCovidStatusReport: this.getEhiCovidStatusReportCreationDto(store, careActivityId),
                        isValidateOnly: isValidateOnly
                    }), isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public updateEhiCovidStatusReportAsync(store: EhiCovidStatusReportStore, isValidateOnly: boolean = false, isPermissionCheckOnly: boolean = false) {
        return this.processOperationAsync(
            new EhiCovidStatusReportStore(false),
            async target => {
                const response = await this.apiClient.updateEhiCovidStatusReportCommandAsync(
                    CreateRequestId(),
                    new Proxy.UpdateEhiCovidStatusReportControllerDto({
                        ehiCovidStatusReport: this.getEhiCovidStatusReportModificationDto(store),
                        isValidateOnly: isValidateOnly,
                        lockId: store.lockInfo?.lockId,
                        releaseLockIfSuccessful: false
                    }), isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public deleteEhiCovidStatusReportAsync(id: EhiCovidStatusReportId, rowVersion: RowVersion, lockId: LockId, isPermissionCheckOnly: boolean = false) {
        return this.processOperationAsync(
            new SimpleStore<any>(),
            async target => {
                const response = await this.apiClient.deleteEhiCovidStatusReportCommandAsync(
                    CreateRequestId(),
                    new Proxy.DeleteEhiCovidStatusReportControllerDto({
                        ehiCovidStatusReportId: id,
                        rowVersion: rowVersion,
                        lockId: lockId,
                        releaseLockIfSuccessful: false
                    }), isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
}

    private getEhiCovidStatusReportCreationDto(store: EhiCovidStatusReportStore, careActivityId: CareActivityId) {
    return new Proxy.EhiCovidStatusReportCreationDto({
        careActivityId: careActivityId,
        bodyTemperature: store.bodyTemperature,
        respiratoryRate: store.respiratoryRate,
        statusReportAt: store.statusReportAt,
        bloodPressureSystolic: store.bloodPressureSystolic,
        bloodPressureDiastolic: store.bloodPressureDiastolic,
        pulse: store.pulse,
        bloodSugar: store.bloodSugar,
        pneumoniaConfirmedRadiological: store.pneumoniaConfirmedRadiological,
        pneumoniaConfirmedClinical: store.pneumoniaConfirmedClinical,
        symptomsSeverityId: store.symptomsSeverityId,
        breathingStatusId: store.breathingStatusId,
        ventilationStatusId: store.ventilationStatusId,
        chillsSymptomStatusId: store.chillsSymptomStatusId,
        generalWeaknessSymptomStatusId: store.generalWeaknessSymptomStatusId,
        sputumRemovalSymptomStatusId: store.sputumRemovalSymptomStatusId,
        coughSymptomStatusId: store.coughSymptomStatusId,
        headacheSymptomStatusId: store.headacheSymptomStatusId,
        muscleOrJointPainSymptomStatusId: store.muscleOrJointPainSymptomStatusId,
        vomitSymptomStatusId: store.vomitSymptomStatusId,
        diarrheaSymptomStatusId: store.diarrheaSymptomStatusId,
        soreThroatSymptomStatusId: store.soreThroatSymptomStatusId,
        urineAmountId: store.urineAmountId,
        otherSymptoms: store.otherSymptoms,
        notes: store.notes,
    } as Proxy.IEhiCovidStatusReportCreationDto);
}

    private getEhiCovidStatusReportModificationDto(store: EhiCovidStatusReportStore) {
    return new Proxy.EhiCovidStatusReportModificationDto({
        id: store.id,
        careActivityId: store.careActivityId,
        rowVersion: store.rowVersion,
        bodyTemperature: store.bodyTemperature,
        respiratoryRate: store.respiratoryRate,
        statusReportAt: store.statusReportAt,
        bloodPressureSystolic: store.bloodPressureSystolic,
        bloodPressureDiastolic: store.bloodPressureDiastolic,
        pulse: store.pulse,
        bloodSugar: store.bloodSugar,
        pneumoniaConfirmedRadiological: store.pneumoniaConfirmedRadiological,
        pneumoniaConfirmedClinical: store.pneumoniaConfirmedClinical,
        symptomsSeverityId: store.symptomsSeverityId,
        breathingStatusId: store.breathingStatusId,
        ventilationStatusId: store.ventilationStatusId,
        chillsSymptomStatusId: store.chillsSymptomStatusId,
        generalWeaknessSymptomStatusId: store.generalWeaknessSymptomStatusId,
        sputumRemovalSymptomStatusId: store.sputumRemovalSymptomStatusId,
        coughSymptomStatusId: store.coughSymptomStatusId,
        headacheSymptomStatusId: store.headacheSymptomStatusId,
        muscleOrJointPainSymptomStatusId: store.muscleOrJointPainSymptomStatusId,
        vomitSymptomStatusId: store.vomitSymptomStatusId,
        diarrheaSymptomStatusId: store.diarrheaSymptomStatusId,
        soreThroatSymptomStatusId: store.soreThroatSymptomStatusId,
        urineAmountId: store.urineAmountId,
        otherSymptoms: store.otherSymptoms,
        notes: store.notes,
    } as Proxy.EhiCovidStatusReportModificationDto);
}
}
