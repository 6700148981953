import StoreBase from "@Toolkit/CommonWeb/Model/StoreBase";
import CancerClassificationId from "@Primitives/CancerClassificationId.g";
import CancerClassificationMetastasisId from "@Primitives/CancerClassificationMetastasisId.g";
import CancerClassificationNodeId from "@Primitives/CancerClassificationNodeId.g";
import CancerClassificationTumorId from "@Primitives/CancerClassificationTumorId.g";

export default class CancerClassificationDataStore extends StoreBase {
    public id: CancerClassificationId;
    public tumorList: ICancerClassificationData[] = [];
    public nodeList: ICancerClassificationData[] = [];
    public metastasisList: ICancerClassificationData[] = [];
}

export interface ICancerClassificationData {
    id: CancerClassificationTumorId | CancerClassificationNodeId | CancerClassificationMetastasisId;
    code: string;
    value: string;
}
