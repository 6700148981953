exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Badge_label_3w_IJ {\n  font-family: 'PragatiNarrowBold' !important;\n  font-size: 14px !important;\n  font-weight: 400;\n  text-decoration: none;\n}\n.Badge_container_RJFz9 {\n  display: inline;\n  margin: 3px;\n  padding: 0px 10px 0px 10px;\n  border-radius: 3px;\n  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);\n}\n.Badge_container-small_sWoOx {\n  margin: -3px 2px 2px 2px;\n  padding: 0px 3px 0px 3px;\n  font-size: 12px !important;\n  font-weight: lighter !important;\n}\n.Badge_container-mini_1ledr {\n  display: inline-block;\n  vertical-align: top;\n  margin: -3px 2px 2px 2px;\n  padding: 1px 3px 1px 3px;\n  font-family: 'PragatiNarrow' !important;\n  font-size: 10px !important;\n  font-weight: lighter !important;\n}\n.Badge_container--shape-pill_2HOBU {\n  border-radius: 20rem;\n}\n.Badge_container--shape-circle_10Kp6 {\n  border-radius: 50%;\n}\n.Badge_container-fluid_T-b7V {\n  display: block;\n  text-align: center;\n}\n.Badge_danger_2OrHI {\n  background-color: #ce5374;\n  color: white;\n}\n.Badge_success_9aCDw {\n  background-color: #21ba45;\n  color: white;\n}\n.Badge_warning_fEXt1 {\n  background-color: #ddc00d;\n  color: white;\n}\n.Badge_secondary_20gHL {\n  background-color: #e8e8e8;\n  color: #00000099;\n}\n.Badge_slate_2izFa {\n  background-color: #8695a7;\n  color: white;\n}\n.Badge_light-blue_ND5ZT {\n  background-color: #82d4ed;\n  color: white;\n}\n", ""]);

// exports
exports.locals = {
	"label": "Badge_label_3w_IJ",
	"container": "Badge_container_RJFz9",
	"container-small": "Badge_container-small_sWoOx",
	"containerSmall": "Badge_container-small_sWoOx",
	"container-mini": "Badge_container-mini_1ledr",
	"containerMini": "Badge_container-mini_1ledr",
	"container--shape-pill": "Badge_container--shape-pill_2HOBU",
	"containerShapePill": "Badge_container--shape-pill_2HOBU",
	"container--shape-circle": "Badge_container--shape-circle_10Kp6",
	"containerShapeCircle": "Badge_container--shape-circle_10Kp6",
	"container-fluid": "Badge_container-fluid_T-b7V",
	"containerFluid": "Badge_container-fluid_T-b7V",
	"danger": "Badge_danger_2OrHI",
	"success": "Badge_success_9aCDw",
	"warning": "Badge_warning_fEXt1",
	"secondary": "Badge_secondary_20gHL",
	"slate": "Badge_slate_2izFa",
	"light-blue": "Badge_light-blue_ND5ZT",
	"lightBlue": "Badge_light-blue_ND5ZT"
};