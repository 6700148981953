import UserId from "@Primitives/UserId.g";
import IAuthenticationService from "@HisPlatform/Services/Definition/Authentication/IAuthenticationService";
import Di from "@Di";
import AuthenticationTokenParser from "./AuthenticationTokenParser";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import PractitionerId from "@Primitives/PractitionerId.g";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ILogoutHandlerExtension from "@PluginInterface/BoundedContexts/UserManagement/ILogoutHandlerExtension";
import PractitionerIdentifierStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerIdentifierStore";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ActiveSessionManager from "./ActiveSessionManager/ActiveSessionManager";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import DependencyContainer from "@DiContainer";
import { GlobalApplicationRef } from "@HisPlatform/Application/GlobalApplicationRef";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";

@Di.injectable()
export default class UserContext {
    @State.observable.ref private authenticationTokenParser: AuthenticationTokenParser = null;

    @State.computed public get isAuthenticated() {
        return this.id !== null && !this.passwordExpired;
    }

    @State.computed public get id() {
        return (this.authenticationTokenParser && new UserId(this.authenticationTokenParser.userId)) || null;
    }

    @State.computed public get loginName() {
        return (this.authenticationTokenParser && this.authenticationTokenParser.userLoginName) || null;
    }

    @State.computed public get passwordExpired() {
        return (this.authenticationTokenParser && this.authenticationTokenParser.passwordExpired) || null;
    }

    @State.computed public get token() {
        return this.authenticationTokenParser?.token;
    }

    public readonly userLoggedInEvent = new TypedAsyncEvent();
    @State.observable.ref public practitionerId: PractitionerId = null;
    @State.observable.ref public displayName: string = null;
    @State.observable.ref public identifiers: PractitionerIdentifierStore[] = [];
    @State.observable public isLoggingOut: boolean = false;

    private readonly activeSessionManager: ActiveSessionManager;

    constructor(
        @Di.inject("IAuthenticationService") private readonly authenticationService: IAuthenticationService,
        @Di.inject("LockingApiAdapter") private readonly lockingApiAdapter: LockingApiAdapter,
        @Di.inject("PractitionerApiAdapter") private readonly practitionerApiAdapter: PractitionerApiAdapter,
        @Di.inject("IModalService") private readonly modalService: IModalService
    ) {
        State.reaction(() => this.id, (newId) => {
            dispatchAsyncErrors(this.loadUserInfoAsync(newId), GlobalApplicationRef.current);
        });

        this.authenticationService.onLoggingOutAsync.on(async () => {
            State.runInAction(() => this.isLoggingOut = true);
            const logoutHandlerExtensions = DependencyContainer.getAll<ILogoutHandlerExtension>("ILogoutHandlerExtension");
            for (const logoutHandlerExtension of logoutHandlerExtensions) {
                await logoutHandlerExtension.logoutAsync();
            }
        });

        this.activeSessionManager = new ActiveSessionManager(
            this.modalService,
            this.lockingApiAdapter,
            () => this.isAuthenticated,
            this.logoutAsync,
            async () => {
                const renewed = await this.authenticationService.renewTokenAsync();
                if (renewed) {
                    this.userLoggedIn();
                }
                return renewed;
            },
            this.authenticationService.tokenWillBeExpiredIn,
        );
    }

    @State.action.bound
    private setPractitionerId(newValue: PractitionerId) {
        this.practitionerId = newValue;
    }

    @State.action.bound
    private setDisplayName(newValue: string) {
        this.displayName = newValue;
    }

    @State.action.bound
    private setIdentifiers(newValue: PractitionerIdentifierStore[]) {
        this.identifiers = newValue;
    }

    @State.bound
    public async loadUserInfoAsync(id: UserId) {
        const userInfo = await this.practitionerApiAdapter.getCurrentUserInfoAsync();
        if (userInfo.operationWasSuccessful) {
            if (userInfo.practitionerId) {
                this.setPractitionerId(userInfo.practitionerId);
                this.setIdentifiers(userInfo.identifiers);
            } else {
                this.setPractitionerId(null);
                this.setIdentifiers([]);
            }
            this.setDisplayName(userInfo.displayName);
        } else {
            this.setPractitionerId(null);
            this.setIdentifiers([]);
            this.setDisplayName('');
        }
    }

    @State.bound
    public async initializeAsync() {
        const isAuth = await this.authenticationService.isLoggedInAsync();
        if (isAuth === true) {
            this.userLoggedIn();
        }
    }

    @State.action
    public userLoggedIn() {
        this.isLoggingOut = false;
        this.setAuthenticationTokenParser();
        dispatchAsyncErrors(this.userLoggedInEvent.emitAsync(), GlobalApplicationRef.current);
    }

    @State.action
    private setAuthenticationTokenParser() {
        this.authenticationTokenParser = new AuthenticationTokenParser(this.authenticationService.getCurrentToken());
    }

    @State.bound
    public async logoutAsync() {
        if (this.isAuthenticated) {
            await this.lockingApiAdapter.releaseCurrentUserLocksAsync();
            State.runInAction(() => {
                this.isLoggingOut = true;
                this.authenticationTokenParser = null;
                this.setDisplayName('');
            });
            await this.authenticationService.logOutAsync();
        }
    }

    @State.action.bound
    public setUnauthenticated() {
        this.isLoggingOut = true;
        this.authenticationTokenParser = null;
        this.setDisplayName('');
        this.authenticationService.setUnauthenticated();
    }
}
