import Di from "@Di";
import { BillableServiceRequestListDto, IPatientsClient } from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import InvoiceId from "@Primitives/InvoiceId.g";
import PatientId from "@Primitives/PatientId.g";
import IOperationResult from "@Toolkit/CommonWeb/ApiAdapter/IOperationResult";
import { PatientServiceList } from "./PatientServiceList";
import { PatientPerformedServiceListItem } from "./PatientPerformedServiceList";
import { PatientServiceRequestListItem } from "./PatientServiceRequestList";
import Money from "@Toolkit/CommonWeb/Model/Money";

@Di.injectable()
export default class BillableServicesApiAdapter extends ApiAdapterBase2 {

    constructor(
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("IPatientsClient") private readonly apiClient: IPatientsClient,
    ) {
        super(referenceDataLoader);
    }

    public getNotBilledServicesAsync(
        patientId: PatientId,
        invoiceId?: InvoiceId
    ): Promise<IOperationResult<PatientServiceList[]>> {
        return this.executeOperationAsync(
            () => this.apiClient.getNotBilledServicesForPatientQueryAsync(CreateRequestId(), invoiceId?.value ?? "", patientId.value),
            response => {
                const lists = new Map<string, PatientServiceList>();
                for (const listDto of response.serviceRequestLists.sort((a, b) => a.wentUnderCareAt.diff(b.wentUnderCareAt))) {
                    if (!lists.has(listDto.careActivityId.value)) {
                        lists.set(listDto.careActivityId.value, BillableServicesApiAdapter.dtoToPatientServiceList(listDto));
                    }

                    const patientServiceList = lists.get(listDto.careActivityId.value);
                    for (const itemDto of listDto.billableServiceRequestListItems) {
                        patientServiceList.items.push(new PatientServiceRequestListItem(
                            itemDto.quantity,
                            itemDto.isBilled,
                            itemDto.serviceRequestId,
                            itemDto.serviceRequestIdentifier,
                            itemDto.createdAt,
                            new Money(itemDto.totalNetPrice, "HUF")
                        ));
                    }
                }

                for (const listDto of response.performedServiceLists) {
                    if (!lists.has(listDto.careActivityId.value)) {
                        lists.set(listDto.careActivityId.value, BillableServicesApiAdapter.dtoToPatientServiceList(listDto));
                    }

                    const patientServiceList = lists.get(listDto.careActivityId.value);
                    for (const itemDto of listDto.items) {
                        patientServiceList.items.push(new PatientPerformedServiceListItem(
                            itemDto.quantity,
                            itemDto.isBilled,
                            itemDto.medicalServiceFinancingId,
                            itemDto.medicalServiceId,
                            itemDto.financedServiceId
                        ));
                    }
                }

                return Array.from(lists.values());
            }
        );
    }

    private static dtoToPatientServiceList(
        dto: BillableServiceRequestListDto | BillableServiceRequestListDto
    ): PatientServiceList {
        return new PatientServiceList(
            dto.careActivityId,
            dto.pointOfCareId,
            dto.careContactType,
            dto.careActivityIdentifier,
            dto.wentUnderCareAt,
            []
        );
    }
}