import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { CareLocation } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/CareLocation";
import { SelectBox } from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import { ExternalLocationVersionSelector } from "@Primitives/ExternalLocationId.g";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { CareLocationDto, EntityVersionSelectorOfExternalLocationId, ExternalCareLocationDto, InternalCareLocationDto } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface ICareLocationColumnFilterDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface ICareLocationColumnFilterProps {
    _dependencies?: ICareLocationColumnFilterDependencies;
    value: CareLocation;
    onChange: (newValue: CareLocation) => void;
    automationId?: string;
    hoverOnlyIndicatorIcons?: boolean;
}

@State.observer
class CareLocationColumnFilter extends React.Component<ICareLocationColumnFilterProps> {

    private get dependencies() { return this.props._dependencies; }

    @State.observable.ref private items: Array<ISelectBoxItem<string>>;

    public componentDidMount() {
        this.initializeItems();
    }

    private initializeItems() {
        const pointOfCareMap = this.dependencies.organizationReferenceDataStore.allPointsOfCareMap;
        const externalLocations = this.dependencies.organizationReferenceDataStore.externalLocationStore;

        State.runInAction(() => {
            const pointOfCares = pointOfCareMap.items.map(item => ({
                text: pointOfCareMap.get(item.id).shorthand,
                value: `I${item.id.value}`
            } as ISelectBoxItem<string>));

            this.items = externalLocations.items.flatMap(items => (
                items.map(item => ({
                    text: item.name,
                    value: `E${item.id.value}`
                }) as ISelectBoxItem<string>))).concat(pointOfCares);
        });
    }

    public render() {
        return (
            <SelectBox
                value={this.props.value}
                onChange={this.props.onChange}
                items={this.items}
                automationId={this.props.automationId}
                hoverOnlyIndicatorIcons={this.props.hoverOnlyIndicatorIcons}
            />
        );
    }
}

export default connect(
    CareLocationColumnFilter,
    new DependencyAdapter<ICareLocationColumnFilterProps, ICareLocationColumnFilterDependencies>(c => ({
        organizationReferenceDataStore: c.resolve<OrganizationReferenceDataStore>("OrganizationReferenceDataStore"),
    }))
);
