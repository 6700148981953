import { IFormCustomBlockComponentProps, ICustomBlockRegistryItem } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import PreviewFormDefinition from "@Toolkit/FormEngine/Model/PreviewFormDefinition";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import _ from "@HisPlatform/Common/Lodash";
import { useFormEntityIdField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import RegionalCareObligationStatusExtension from "./RegionalCareObligationStatusExtension";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import PatientId from "@Primitives/PatientId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IRegionalCareObligationStatusFormCustomBlockProps extends IFormCustomBlockComponentProps {
    _patientId: PatientId;
}

function _RegionalCareObligationStatusFormCustomBlock(props: IRegionalCareObligationStatusFormCustomBlockProps) {
    if (props.formDefinition instanceof PreviewFormDefinition) {
        // Preview mode
        return (
            <div>RegionalCareObligationStatusFormCustomBlock</div>
        );
    }

    const [pointOfCareId] = useFormEntityIdField<PointOfCareId>(props.form.data.Content, "PointOfCareId", PointOfCareId);
    return (
        <RegionalCareObligationStatusExtension
            patientId={props._patientId}
            pointOfCareId={pointOfCareId}
        />
    );
}

const RegionalCareObligationStatusFormCustomBlock = connect(
    State.observer(_RegionalCareObligationStatusFormCustomBlock),
    new PatientContextAdapter<IRegionalCareObligationStatusFormCustomBlockProps>(p => ({
        _patientId: p.patientId
    }))
);


export const RegionalCareObligationStatusExtensionRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "RegionalCareObligationStatusExtension",
    dataElementsFactory: () => [],
    componentType: RegionalCareObligationStatusFormCustomBlock
};

export default RegionalCareObligationStatusFormCustomBlock;