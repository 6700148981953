/* auto-inject-disable */
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { GetTypeNameWithPrefix } from "@HunEHealthInfrastructurePlugin/PluginHelper";
import EhiSelfDeterminationId from "@Primitives/EhiSelfDeterminationId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";

/* auto-inject-disable */
@Di.injectable()
export default class EhiMedicalConditionApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("IMedicalConditionClient")) private medicalConditionClient: Proxy.IMedicalConditionClient,
    ) {
        super();
    }

    @State.bound
    public loadEhiSelfDeterminationIdsAsync(): Promise<SimpleStore<EhiSelfDeterminationId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.medicalConditionClient.getEhiSelfDeterminationIdsQueryAsync(...args),
            response => response.ehiSelfDeterminationIds
        );
    }
}
