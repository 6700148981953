import React, {useMemo} from "react";
import * as Ui from "@CommonControls";
import CategoryObservationSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceAdministrationPanel/CategoryObservationSelectBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import {useDependencies} from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ScalarObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/ScalarObservation";
import RequestedServiceState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceState.g";

interface IScalarObservationValueInputProps {
    row?: RequestedServiceStore;
}

const renderObservationValue = (observation: ScalarObservation, serviceCodeValue: string, isReadOnly: boolean) => {
    if (observation.rawValue) {
        return (
            <Ui.TextBox
                onChange={observation.setRawValue}
                value={observation.rawValue}
                style={{marginRight: "6px"}}
                isReadOnly={isReadOnly}
                stopClickPropagation
                propertyIdentifier="Value"
                automationId={`service_result_${serviceCodeValue}`}
            />
        );
    } else {
        return (
            <Ui.NumberBox
            nullValue={null}
            onChange={observation.setQuantityAmount}
            value={observation.quantityAmount}
            style={{marginRight: "6px"}}
            isReadOnly={isReadOnly}
            stopClickPropagation
            propertyIdentifier="Value"
            automationId={`service_result_${serviceCodeValue}`}
        />);
    }
};


const ScalarObservationValueInput: React.FC<IScalarObservationValueInputProps> = props => {

    const dependencies = useDependencies(c => ({
        careReferenceDataStore: c.resolve<CareReferenceDataStore>("CareReferenceDataStore"),
    }));

    const service = useMemo(() => {
        return dependencies.careReferenceDataStore.medicalService.get(props.row.medicalServiceVersionSelector);
    }, [dependencies.careReferenceDataStore, props.row]);

    const {
        categoryObservation,
        categoryObservationDefinition,
        scalarObservation,
        scalarObservationDefinition,
        state
    } = props.row;

    if (state === RequestedServiceState.DeletedByExecutorSideAfterCompletion ||
        state === RequestedServiceState.DeletedByExecutorSideBeforeCompletion) {
        return null;
    }

    if (scalarObservationDefinition && scalarObservation) {
        return (
            <State.Observer>
                {() => (
                    <ValidationBoundary
                        entityTypeName="Observation"
                        entityId={scalarObservation?.observationId?.value}
                    >
                        {renderObservationValue(scalarObservation, service.code.value, !props.row.isCompleteDiagnosticReportAllowed)}
                    </ValidationBoundary>
                )}
            </State.Observer>
        );
    } else if (categoryObservationDefinition && categoryObservation) {
        return (
            <State.Observer>
                {() => (
                    <ValidationBoundary
                        entityTypeName="Observation"
                        entityId={categoryObservation?.observationId?.value}>
                        <CategoryObservationSelectBox
                            categories={categoryObservationDefinition.categories}
                            currentMultiCategories={categoryObservation.categories}
                            currentSingleCategory={categoryObservation.singleCategory}
                            isMultiSelect={categoryObservationDefinition.isMultiSelect}
                            setMultipleCategories={categoryObservation.setMultiCategories}
                            setSingleCategory={categoryObservation.setSingleCategory}
                            readOnly={!props.row.isCompleteDiagnosticReportAllowed}
                            automationId={`service_result_${service.code.value}`}
                        />
                    </ValidationBoundary>
                )}
            </State.Observer>
        );
    }

    return null;
};

export default ScalarObservationValueInput;
