import React from "react";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import AppointmentSubjectColumnData from "./AppointmentSubjectColumnData";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";

interface IAppointmentSubjectColumnDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
}

interface IAppointmentSubjectColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IAppointmentSubjectColumnDependencies;
}

class AppointmentSubjectColumn extends React.Component<IAppointmentSubjectColumnProps> {

    @State.action.bound
    private getSchedulingService(value: SchedulingServiceId) {
        return this.props._dependencies.schedulingReferenceDataStore.schedulingServices.get(value);
    }

    @State.action.bound
    private getServiceRequest(value: IEntityVersionSelector<ServiceRequestDefinitionId>) {
        return this.props._dependencies.careReferenceDataStore.serviceRequestDefinition.get(value);
    }

    @State.bound
    private valueRenderer(value: AppointmentSubjectColumnData) {
        const schedulingService = value?.subjectType === "SchedulingService" ? this.getSchedulingService(value.subject as SchedulingServiceId) : null;
        const serviceRequestDef = value?.subjectType === "ServiceRequest" ? this.getServiceRequest(value.serviceRequestDefinitionId) : null;
        return (
            <>
                {value?.subjectType === "SchedulingService" && schedulingService &&
                    <div>
                        <b>{schedulingService.code}</b>{` - ${schedulingService.name}`}
                    </div>
                }
                {value?.subjectType === "ServiceRequest" && serviceRequestDef &&
                    <div>
                        <b>{value?.serviceRequestIdentifier}</b>{` - ${serviceRequestDef.shortName}`}
                    </div> 
                } 
            </>
            );   
    }

    public render() {
        const { children, ...columnProps } = this.props;
        return (
            <DataGridColumn
                {...columnProps}
                onRenderCellValue={this.valueRenderer}
            />
        );
    }
}

export default connect(
    AppointmentSubjectColumn,
    new DependencyAdapter<IAppointmentSubjectColumnProps, IAppointmentSubjectColumnDependencies>(c => {
        return {
            careReferenceDataStore: c.resolve("CareReferenceDataStore"),
            schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore")
        };
    })
);
