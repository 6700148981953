exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".IcardioProcessWidget_order-container_WPkzt {\n  position: relative;\n  z-index: 1;\n  display: flex;\n  overflow: hidden;\n  justify-content: center;\n  margin-left: -16px;\n}\n.IcardioProcessWidget_done-form-order_37Nv9 {\n  position: relative;\n  border-radius: 50%;\n  border: 1px #00b4ec;\n  font-weight: 500;\n  height: 30px;\n  width: 30px;\n  line-height: 30px;\n  color: white;\n  background-color: #00b4ec;\n  text-align: center;\n  justify-content: center;\n}\n.IcardioProcessWidget_order-container_WPkzt:after {\n  position: absolute;\n  content: \"\";\n  padding-bottom: 1000px;\n  border-left: 1px dashed #eef1f7;\n  z-index: -1;\n}\n.IcardioProcessWidget_no-margin-top_25R8g {\n  margin-top: 0px;\n}\n.IcardioProcessWidget_no-margin-top_25R8g:last-child {\n  padding-bottom: 4px;\n}\n.IcardioProcessWidget_lower-margin-bottom_2xT10 {\n  margin: 0 0 4px -8px;\n  font-size: 16px;\n}\n.IcardioProcessWidget_form-text-value_3o7oN {\n  font-size: 16px;\n  display: inline-block;\n  padding-right: 4px;\n  margin: 0 0 4px -8px;\n}\n.IcardioProcessWidget_current-step-form-order_KG-9n {\n  position: relative;\n  border-radius: 50%;\n  border: 1px white dashed;\n  font-weight: 500;\n  height: 30px;\n  width: 30px;\n  line-height: 30px;\n  color: grey;\n  background-color: white;\n  text-align: center;\n  justify-content: center;\n}\n.IcardioProcessWidget_header-text_2CaVS {\n  padding: 8px 0 0 8px;\n  margin-top: 0px;\n}\n", ""]);

// exports
exports.locals = {
	"order-container": "IcardioProcessWidget_order-container_WPkzt",
	"orderContainer": "IcardioProcessWidget_order-container_WPkzt",
	"done-form-order": "IcardioProcessWidget_done-form-order_37Nv9",
	"doneFormOrder": "IcardioProcessWidget_done-form-order_37Nv9",
	"no-margin-top": "IcardioProcessWidget_no-margin-top_25R8g",
	"noMarginTop": "IcardioProcessWidget_no-margin-top_25R8g",
	"lower-margin-bottom": "IcardioProcessWidget_lower-margin-bottom_2xT10",
	"lowerMarginBottom": "IcardioProcessWidget_lower-margin-bottom_2xT10",
	"form-text-value": "IcardioProcessWidget_form-text-value_3o7oN",
	"formTextValue": "IcardioProcessWidget_form-text-value_3o7oN",
	"current-step-form-order": "IcardioProcessWidget_current-step-form-order_KG-9n",
	"currentStepFormOrder": "IcardioProcessWidget_current-step-form-order_KG-9n",
	"header-text": "IcardioProcessWidget_header-text_2CaVS",
	"headerText": "IcardioProcessWidget_header-text_2CaVS"
};