import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientId from "@Primitives/PatientId.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import {UniversalCodeSelector} from "@HisPlatformControls";
import PatientSelectorDialog, {IPatientSelectorDialogProps} from "@HisPlatform/BoundedContexts/Care/Components/Controls/PatientRegister/PatientSelectBox/PatientSelectorDialog";
import {ICondition} from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistFilterCondition";
import FilterConditionConverters from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/FilterConditionConverters";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import {createInitialPanelLoader} from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import PatientFilterStore from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/PatientWorklist/Filters/PatientFilterStore";
import IDynamicListDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IDynamicListDefinition";
import PatientApiAdapter2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/PatientApiAdapter2";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import {FrontendListRequestMode} from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import IFrontendListDataWithDefinition from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/IFrontendListDataWithDefinition";
import IOperationResult from "@Toolkit/CommonWeb/ApiAdapter/IOperationResult";

interface IPatientSelectBoxDependencies {
    localizationService: ILocalizationService;
    worklistApiAdapter: WorklistApiAdapter;
    patientSearchApiAdapter: PatientApiAdapter2;
}

interface IPatientSelectBoxProps extends ICodeSelectorCommonProps<PatientId> {
    modalTitle: string;
    label: string;
    _dependencies?: IPatientSelectBoxDependencies;
}

@State.observer
class PatientSelectBox extends React.Component<IPatientSelectBoxProps> {
    @State.observable.ref private worklistDefinition: IWorklistDefinition = null;
    @State.observable.ref private definition: IDynamicListDefinition = null;

    private get worklistApiAdapter() {
        return this.props._dependencies.worklistApiAdapter;
    }

    private get localizationService() {
        return this.props._dependencies.localizationService;
    }

    private get patientSearchApiAdapter() {
        return this.props._dependencies.patientSearchApiAdapter;
    }

    @State.computed
    private get patientSelectorDialogProps() {
        return {
            modalTitle: this.props.modalTitle,
            value: this.props.value,
            setValue: this.props.onChange
        } as Partial<IPatientSelectorDialogProps>;
    }

    @State.bound
    private async getDisplayValueAsync(value: PatientId) {
        const result = await this.patientSearchApiAdapter.loadByIdAsync(value);
        return this.localizationService.localizePersonName(result.result.baseData.name);
    }

    @State.bound
    private getExtendedFilterDescriptors() {
        return PatientFilterStore.getFilterDescriptors();
    }

    @State.bound
    private getFilterCondition(filterId: string, value: any): ICondition {
        const descriptor = this.getExtendedFilterDescriptors().find(i => i.id === filterId);

        const conditionConverter = FilterConditionConverters[descriptor.type];

        if (!conditionConverter) {
            throw new Error(`Unknown item definition attribute type: ${descriptor.type}`);
        }

        return conditionConverter(value);
    }

    @State.bound
    private async onQuickSearchAsync(quickSearchText: string) {

        const filters = {};

        filters["PatientBased_GeneralPatientSearchFilter"] = this.getFilterCondition("PatientBased_GeneralPatientSearchFilter", quickSearchText);
        filters["PatientBased_IncludeDeletedFilter"] = this.getFilterCondition("PatientBased_IncludeDeletedFilter", false);

        const results: IOperationResult<IFrontendListDataWithDefinition> = await dispatchAsyncErrors(this.patientSearchApiAdapter.searchPatientAsync(new FrontendListParameters(
            FrontendListRequestMode.Data,
            0,
            10,
            null,
            filters)), this);

        return results?.result?.data?.items?.items?.map(i => { return new PatientId(i.id.replace("_0", "")); }) ?? [];
    }

    @State.bound
    private async loadAsync() {
        const worklistDefinition = await this.worklistApiAdapter.getPatientSelectorWorklistDefinition();
        const definition = await this.worklistApiAdapter.getWorklistByDefinitionId(worklistDefinition.value);

        State.runInAction(() => {
            this.worklistDefinition = worklistDefinition.value;
            this.definition = definition.value;
        });
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public render() {
        return (
            <UniversalCodeSelector
                {...this.props}
                automationId={"patientSelectBox"}
                getDisplayValueAsync={this.getDisplayValueAsync}
                onQuickSearchAsync={this.onQuickSearchAsync}
                customContentComponentType={PatientSelectorDialog}
                customContentComponentProps={this.patientSelectorDialogProps}
                hasComplexSearch
            />
        );
    }
}

export default connect(
    PatientSelectBox,
    new DependencyAdapter<IPatientSelectBoxProps, IPatientSelectBoxDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
        worklistApiAdapter: c.resolve("WorklistApiAdapter"),
        patientSearchApiAdapter: c.resolve("PatientApiAdapter2")
    }))
);