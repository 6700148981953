/* auto-inject-disable */
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityId from "@Primitives/CareActivityId.g";
import ICareActivityTextBlockListItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/ICareActivityTextBlockListItem";
import ICareActivityTextBlockListPanelScreenInitialData from "./ICareActivityTextBlockListPanelScreenInitialData";
import IOperationResult from "@Toolkit/CommonWeb/ApiAdapter/IOperationResult";

@Di.injectable()
export default class CareActivityTextBlockListPanelScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("ICareClient") private readonly apiClient: Proxy.ICareClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public getCareActivityTextBlockListPanelScreenDataAsync(
        careActivityId: CareActivityId
    ): Promise<IOperationResult<ICareActivityTextBlockListPanelScreenInitialData>> {
        return this.executeOperationAsync<ICareActivityTextBlockListPanelScreenInitialData, Proxy.GetCareActivityTextBlocksQueryResponse>(
            () => this.apiClient.getCareActivityTextBlocksQueryAsync(CreateRequestId(), careActivityId.value, false),
            response => {
                return Promise.resolve({
                    textBlockListItems: response?.careActivityTextBlockAndTypeIds.map(i => ({
                        careActivityTextBlockId: i.careActivityTextBlockId,
                        lastModifiedAt: i.lastModifiedAt,
                        lastModifiedBy: i.lastModifiedBy,
                        textBlockTypeId: i.textBlockTypeId
                    } as ICareActivityTextBlockListItem)) || [],
                    mandatoryTextBlockTypeIds: response?.mandatoryTextBlockTypes || [],
                } as ICareActivityTextBlockListPanelScreenInitialData);
            }
        );
    }
}