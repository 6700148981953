import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import DataGrid from "@CommonControls/DataGrid/DataGrid";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IOrderingState, IPagingState, DataGridLoadType } from "@CommonControls/DataGrid/IDataGridProps";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DocumentInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentInfo";
import DocumentId from "@Primitives/DocumentId.g";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import DocumentStateColumn from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Controls/Columns/DocumentStateColumn/DocumentStateColumn";
import { iconNameType } from "@CommonControls/Icon";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import UserColumn from "@HisPlatformControls/DataGridColumns/UserColumn";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import ListActions from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/DocumentManagementMasterDetailPanel/ListActions";
import PointOfCareColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import * as Ui from "@CommonControls";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import DocumentManagementReferenceDataStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentManagementReferenceDataStore";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import config from "@Config";
import { MasterDetailContextAdapter } from "@CommonControls/Layout/MasterDetailContext";

interface IDocumentManagementListPanelDependencies {
    referenceDataStore: DocumentManagementReferenceDataStore;
}

interface IDocumentManagementListPanelProps extends IHasMasterDetailState {
    _dependencies?: IDocumentManagementListPanelDependencies;
    _isCompact?: boolean;

    documents: IPagedItems<DocumentInfo>;
    onLoadListAsync: (ordering: IOrderingState, paging: IPagingState) => Promise<void>;

    selectedId: DocumentId;
    onSelect: (id: DocumentId) => void;

    ordering: IOrderingState;
    paging: IPagingState;

    onDownloadAsync: (doc: DocumentInfo) => Promise<void>;
    readOnly?: boolean;

    customValidationProblems?: IClientValidationProblem[];

    extraFilter?: React.ReactNode;
    isExtraFilterVisible?: boolean;
}

/** @screen */
@State.observer
class DocumentManagementListPanel extends React.Component<IDocumentManagementListPanelProps> {

    private get notCompactOrCompactDetailOpened() {
        return !this.props._isCompact || (this.props._isCompact && !this.props._masterDetailState.isDetailOpen);
    }

    private get referenceDataStore() {
        return this.props._dependencies.referenceDataStore;
    }

    public render() {
        return (
            <Ui.ToolbarLayout topToolbar={<div style={{ flex: "1 1 0" }}><Ui.ValidationResultSummary displayMode="summary" results={this.props.customValidationProblems} /></div>}>
                <DataGrid
                    extraFilter={this.props.extraFilter}
                    isExtraFilterVisible={this.props.isExtraFilterVisible}
                    dataSource={this.props.documents}
                    rowComparer="rowId"
                    rowId="id.value"
                    fixedHeight="100%"
                    onChangeAsync={this.changeAsync}
                    rowHeight={35}
                    onSelectedRowChange={this.setSelected}
                    selectedRow={this.props.selectedId?.value ?? null}
                    isSelectable
                    paging={this.props.paging}
                    ordering={this.props.ordering}
                    fixedLayout
                    actionsColumn
                    generateInitialFilterStore
                    automationId="documentManagementListPanel_dataGrid"
                >
                    <DataGridColumn
                        header={StaticDocumentManagementResources.DocumentManagementMasterDetailPanel.List.Name}
                        dataGetter="name"
                        id="Name"
                        isOrderable
                        onRenderCellValue={this.renderName}
                        hideOverflow
                    />
                    <DocumentStateColumn
                        header={StaticDocumentManagementResources.DocumentManagementMasterDetailPanel.List.State}
                        dataGetter="state"
                        id="DocumentState"
                        isOrderable
                        width={this.props._masterDetailState.isDetailOpen ? 85 : 105}
                        onCustomRenderCellValue={this.renderNewState}
                        isVisible={this.notCompactOrCompactDetailOpened}
                    />
                    <PointOfCareColumn
                        header={StaticDocumentManagementResources.DocumentManagementMasterDetailPanel.List.PointOfCare}
                        dataGetter="pointOfCareId"
                        id="PointOfCareId"
                        width={100}
                        isVisible={!this.props._masterDetailState.isDetailOpen}
                    />
                    <UserColumn
                        header={StaticDocumentManagementResources.DocumentManagementMasterDetailPanel.List.LastModifiedBy}
                        dataGetter="lastModifiedById"
                        id="LastModifiedById"
                        width={180}
                        isVisible={!this.props._masterDetailState.isDetailOpen}
                    />
                    <DataGridDateTimeColumn
                        header={StaticDocumentManagementResources.DocumentManagementMasterDetailPanel.List.LastModifiedAt}
                        dataGetter="lastModifiedAt"
                        id="LastModifiedAt"
                        width={140}
                        isVisible={!this.props._masterDetailState.isDetailOpen}
                    />
                    <DataGridColumn
                        width={40}
                        isVisible={this.notCompactOrCompactDetailOpened}
                    >
                        <ListActions
                            onDownloadAsync={this.downloadDocumentAsync}
                            readOnly={this.props.readOnly}
                        />
                    </DataGridColumn>
                </DataGrid>
            </Ui.ToolbarLayout>
        );
    }

    @State.bound
    private async downloadDocumentAsync(doc: DocumentInfo) {
        await this.props.onDownloadAsync(doc);
    }

    @State.bound
    private renderNewState(row: DocumentInfo) {
        return row.isNew ? StaticDocumentManagementResources.DocumentManagementMasterDetailPanel.NewDocumentState : null;
    }

    @State.bound
    private renderName(value: string, row: DocumentInfo) {
        if (this.notCompactOrCompactDetailOpened) {
            return (

                <SpanWithIcon iconName={this.getDocumentTypeIconName(row.documentTypeId)}>
                    <>
                        {value}
                        <HisPlatformExtensionPoint extensionProps={{ extensionData: row.extensionData }} type="documentListName">
                            <></>
                        </HisPlatformExtensionPoint>
                    </>
                </SpanWithIcon>
            );
        } else {
            return value;
        }
    }

    @State.bound
    private getDocumentTypeIconName(type: DocumentTypeId): iconNameType {
        const code = this.referenceDataStore.documentTypeMap.get(type).code;
        const icon = config.iconConfig.documentIcons.find(icon => icon.Name == code)?.Value;
        return icon ? icon : "document";
    }

    @State.bound
    private setSelected(rawId: string) {
        this.props.onSelect(new DocumentId(rawId));
    }

    @State.action.bound
    private async changeAsync(
        type: DataGridLoadType,
        paging: IPagingState,
        ordering: IOrderingState,
        filter: any,
        columns: IDataGridColumnProps[]
    ) {
        await this.props.onLoadListAsync(ordering, paging);
    }
}

export default connect(
    DocumentManagementListPanel,
    new DependencyAdapter<IDocumentManagementListPanelProps, IDocumentManagementListPanelDependencies>(c => ({
        referenceDataStore: c.resolve("DocumentManagementReferenceDataStore")
    })),
    new MasterDetailContextAdapter<IDocumentManagementListPanelProps>((_, ctx) => ({
        _isCompact: ctx.state.isCompact,
        _masterDetailState: ctx.state
    })),
);