import ITemplateInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateInfo";
import TemplateId from "@Primitives/TemplateId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import TokenUsageListStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TokenUsageListStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DocumentContentStore from "@CommonControls/DocumentEditor/DocumentContentStore";
import LargeDataToken from "@Primitives/LargeDataToken.g";

export default class TemplateStore extends EntityStoreBase<TemplateId> {
    @State.observable.ref public info: ITemplateInfo = null;
    public contentLargeDataId: LargeDataToken = null;
    public readonly documentContent = DocumentContentStore.create();
    @State.observable.ref public tokenUsages: TokenUsageListStore = null;
    
    @State.action.bound
    public setTokenUsages(newValue: TokenUsageListStore) {
        this.tokenUsages = newValue;
    }
}