import ITemplateContent, { ITemplateContentTokenUsage } from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Templating/ITemplateContent";
import Encoding from "@Toolkit/CommonWeb/Encoding";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import TokenUsageStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TokenUsageStore";
import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import { isNullOrUndefined, isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";

export function mapTemplateContentBase64(documentContent: string, tokenUsages: TokenUsageStore[]) {
    const contentBytes = Encoding.UTF8.getBytes(JSON.stringify(createContent(documentContent, tokenUsages)));
    return Base64Converter.fromByteArray(contentBytes);
}

function createContent(documentContent: string, tokenUsages: TokenUsageStore[]) {
    const content = {
        documentContent: documentContent,
        tokenUsages: tokenUsages.map(u => ({
            id: u.tokenId,
            settings: u.tokenFormatterSettings
        })),
        parameters: tokenUsages.filter(k => !isNullOrUndefined(k.providerParameterSettings)).map(k => ({
            id: k.tokenId,
            parameter: k.providerParameterSettings
        }))
    };

    return content;
}

export function createRawContent(documentContent: string, tokenUsages: TokenUsageStore[]) {
    const content = {
        documentContent: documentContent,
        tokenUsages: tokenUsages.map(u => ({
            id: u.tokenId,
            settings: JSON.stringify(u.tokenFormatterSettings)
        })),
        parameters: tokenUsages.filter(k => !isNullOrUndefined(k.providerParameterSettings)).map(k => ({
            id: k.tokenId,
            parameter: k.providerParameterSettings
        }))
    };

    return JSON.stringify(content);
}

export function mapContentStringTemplate(contentString: any) {
    const content: ITemplateContent = {
        documentContent: contentString.documentContent,
        tokenUsages: contentString.tokenUsages.map((usage: any) => {
            const parameter = getProviderParameterSettings(usage);
            const providerParameterSettings = isNullOrUndefined(parameter) ? null : JSON.parse(parameter);
            return (
                {
                    id: usage.id,
                    tokenFormatterSettings: Proxy.FormatterSettingBaseDto.fromJS(JSON.parse(usage.settings)),
                    providerParameterSettings: providerParameterSettings
                } as ITemplateContentTokenUsage);
        })
    };

    function getProviderParameterSettings(usage: any) {
        return contentString.parameters?.find((tokenProviderParameterSetting: any) => tokenProviderParameterSetting.id === usage.id)?.parameter;
    }

    return content;
}