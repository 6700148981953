import { useScreenNavigationContext, ScreenNavigationContextStore } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import { useActionDispatcher, ActionDispatcherReactContext } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import { useCallback } from "react";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import useModalService from "@Toolkit/ReactClient/Components/ModalService/useModalService";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";
import ModalServiceReactContext from "@Toolkit/ReactClient/Components/ModalService/ModalServiceContext";
import { emptyObject } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ModalContextStore from "@Toolkit/ReactClient/Components/ModalService/ModalContextStore";
import { ScreenNavigationReactContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationReactContext";

export function useHisActionDispatcher(): IActionDispatcher {

    const screenNavigationContext = useScreenNavigationContext();
    const modalService = useModalService();
    const actionDispatcher = useActionDispatcher();

    const dispatchAsync = useCallback(<TCallContextParams = any>(action: FrontendActionBase, callContextParams?: TCallContextParams) => {
        return actionDispatcher.dispatchAsync(action, { ...callContextParams, navigationContext: screenNavigationContext, modalService });
    }, [actionDispatcher, screenNavigationContext, modalService]);

    return {
        registerLocal: actionDispatcher.registerLocal,
        getDisplaySettings: actionDispatcher.getDisplaySettings,
        dispatchAsync
    };
}

export class HisActionDispatcherAdapter<TProps = { _actionDispatcher: IActionDispatcher }> implements IComponentAdapter<TProps> {
    public get usedContexts(): Array<React.Context<any>> {
        return [ActionDispatcherReactContext, ModalServiceReactContext, ScreenNavigationReactContext];
    }

    constructor(
        private readonly mapToProps: (actionDispatcher: IActionDispatcher, props: TProps) => Partial<TProps> = (actionDispatcher) => ({ _actionDispatcher: actionDispatcher } as any)
    ) { }

    public getMappedProps(props: TProps, contextValues: Map<React.Context<any>, any>): Partial<TProps> {
        const actionDispatcherCore = contextValues.get(ActionDispatcherReactContext) as IActionDispatcher;
        const screenNavigationContext = contextValues.get(ScreenNavigationReactContext) as ScreenNavigationContextStore;
        const modalService = contextValues.get(ModalServiceReactContext) as ModalContextStore;

        if (!actionDispatcherCore) {
            return emptyObject;
        }

        const dispatchAsync = <TCallContextParams = any>(action: FrontendActionBase, callContextParams?: TCallContextParams) => {
            return actionDispatcherCore.dispatchAsync(action, { ...callContextParams, navigationContext: screenNavigationContext, modalService });
        };
    
        const actionDispatcher = {
            registerLocal: actionDispatcherCore.registerLocal,
            getDisplaySettings: actionDispatcherCore.getDisplaySettings,
            dispatchAsync
        };
       
        return this.mapToProps(actionDispatcher, props);
    }
}