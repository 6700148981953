import IFormEngineApiAdapter from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import IFormDefinitionListItem from "@Toolkit/FormEngine/Model/IFormDefinitionListItem";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import FormSchemaVersion from "@Toolkit/FormEngine/Model/Schema/FormSchemaVersion";
import FormLayoutVersionStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutVersionStore";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as FormEngineProxy from "@HisPlatform/BoundedContexts/FormEngine/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import FormDefinition from "@Toolkit/FormEngine/Model/FormDefinition";
import FormSchema from "@Toolkit/FormEngine/Model/Schema/FormSchema";
import IFormLayoutBlockStore from "@Toolkit/FormEngine/Model/Layout/IFormLayoutBlockStore";
import FormLayoutGroupBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutGroupBlockStore";
import State, { IObservableValue } from "@Toolkit/ReactClient/Common/StateManaging";
import FormLayoutRowBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutRowBlockStore";
import FormLayoutRowColumnStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutRowColumnStore";
import IFormLayoutRowColumnSize from "@Toolkit/FormEngine/Model/Layout/IFormLayoutRowColumnSize";
import FormLayoutDataElementEditorStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutDataElementEditorStore";
import FormLayoutStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutStore";
import FormSchemaId from "@Toolkit/FormEngine/Model/Primitives/FormSchemaId.g";
import FormLayoutId from "@Toolkit/FormEngine/Model/Primitives/FormLayoutId.g";
import Di from "@Di";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import IFormListItem from "@Toolkit/FormEngine/Model/IFormListItem";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import Form from "@Toolkit/FormEngine/Model/Form";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import { IFormDataStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormDataStore";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import IFormScopes from "@Toolkit/FormEngine/Panels/FormPanel/IFormScopes";
import {
    getFormEnumReferenceDto,
    mapToFormInstanceContentDto,
    restoreFormDataStoreAsync,
    convertProxyFiltersToFilters,
    convertFiltersToProxyFilters,
    mapLocalizedLabelsToMultiLingualLabelMap,
    mapMultiLingualLabelMapToLocalizedLabels
} from "@HisPlatform/BoundedContexts/FormEngine/ApplicationLogic/ApiAdapter/FormEngineMappers";
import IFormToken from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/IFormToken";
import FormLayoutFragment from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/FormLayoutFragment";
import FormDefinitionDescriptor from "@Toolkit/FormEngine/Model/FormDefinitionDescriptor";
import FormDataElementDescriptor from "@Toolkit/FormEngine/Model/FormDataElementDescriptor";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import TokenDefaultValue from "@Toolkit/FormEngine/Model/Layout/TokenDefaultValue";
import moment from "moment";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IFormValidationRule from "@Toolkit/FormEngine/Model/Schema/Validation/IFormValidationRule";
import RuleTypeId from "@Primitives/RuleTypeId.g";
import FormEnumStore, { IFormEnumValue } from "@Toolkit/FormEngine/Model/Schema/FormEnumStore";
import IFormEnumsInDefinition, { INamedFormEnum } from "@Toolkit/FormEngine/Model/IFormEnumsInDefinition";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import FormLayoutCustomBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutCustomBlockStore";
import FormEditorChange from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/FormEditorChange";
import FormEditBehavior from "@Toolkit/FormEngine/Model/FormEditBehavior";
import FormLogicType from "@Primitives/FormLogicType";
import StringFormDataElement from "@Toolkit/FormEngine/Model/Schema/StringFormDataElement";
import NumberFormDataElement from "@Toolkit/FormEngine/Model/Schema/NumberFormDataElement";
import ReferencedEntityFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEntityFormDataElement";
import ReferencedExtensibleEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedExtensibleEnumFormDataElement";
import DateFormDataElement from "@Toolkit/FormEngine/Model/Schema/DateFormDataElement";
import DateTimeFormDataElement from "@Toolkit/FormEngine/Model/Schema/DateTimeFormDataElement";
import TimeFormDataElement from "@Toolkit/FormEngine/Model/Schema/TimeFormDataElement";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import EnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/EnumFormDataElement";
import FormEnumReference from "@Toolkit/FormEngine/Model/Schema/FormEnumReference";
import FormLogicToken from "@Toolkit/FormEngine/Model/FormLogicToken";
import FormLogic from "@Toolkit/FormEngine/Model/FormLogic";
import AggregateRootSchema from "@Toolkit/FormEngine/Model/Schema/AggregateRootSchema";
import ReferencedAggregateFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedAggregateFormDataElement";
import CompositeFormDataElement from "@Toolkit/FormEngine/Model/Schema/CompositeFormDataElement";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";
import { IFormDefinitionVersion } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorHandle";
import ReferencedEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEnumFormDataElement";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import BooleanFormDataElement from "@Toolkit/FormEngine/Model/Schema/BooleanFormDataElement";

@Di.injectable()
export default class FormEngineApiAdapter extends ApiAdapterBase implements IFormEngineApiAdapter {

    constructor(
        @Di.inject("IFormInstanceHandlingClient") private readonly formInstanceApiClient: FormEngineProxy.IFormInstanceHandlingClient,
        @Di.inject("IFormEditingClient") private readonly formEditingApiClient: FormEngineProxy.IFormEditingClient,
        @Di.inject("IFormTokenHandlingClient") private readonly formTokenHandlingApiClient: FormEngineProxy.IFormTokenHandlingClient,
        @Di.inject("IFormEngineReferenceDataStore") private readonly formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("ICurrentCultureProvider") private readonly cultureCodeProvider: ICurrentCultureProvider,
        @Di.inject("FormEditorRegistry") private readonly formEditorRegistry: FormEditorRegistry
    ) {
        super();
    }

    //#region FormDefinition
    public getAllFormDefinitionDescriptorsForFormBaseEntityAsync(formBaseEntity: string): Promise<SimpleStore<FormDefinitionDescriptor[]>> {
        return this.processOperationAsync(
            new SimpleStore<FormDefinitionDescriptor[]>(),
            async target => {
                const response = await this.formEditingApiClient.getAllFormDefinitionDescriptorsQueryAsync(CreateRequestId(), new FormEngineProxy.GetAllFormDefinitionDescriptorsControllerDto({
                    formBaseEntity: new FormEngineProxy.FormBaseEntity({
                        value: formBaseEntity
                    })
                }));

                target.operationInfo = createOperationInfo(response);
                target.value = response.formDefinitionDescriptors.map(i =>
                    new FormDefinitionDescriptor(i.id, i.formDefinitionName, mapLocalizedLabelsToMultiLingualLabelMap(i.formDefinitionLocalizedDisplayNames), i.schemaVersion, i.formDataElementDescriptors.map(j =>
                        new FormDataElementDescriptor(j.formDataElementName, j.formDataElementType, mapLocalizedLabelsToMultiLingualLabelMap(j.localizedLabels), j.isArray, j.isLongString))));
            }
        );
    }

    public getAllAggregateRootSchemasAsync(): Promise<SimpleStore<AggregateRootSchema[]>> {
        return this.processOperationAsync(
            new SimpleStore<AggregateRootSchema[]>(),
            async target => {
                const response = await this.formEditingApiClient.getAllAggregateRootSchemasQueryAsync(CreateRequestId());

                target.operationInfo = createOperationInfo(response);
                target.value = response.aggregateRootSchemas.map(i =>
                    new AggregateRootSchema(i.aggregateRootName, i.formDataElements.map(j => this.mapFormDataElement(j))));
            }
        );
    }

    public getAllFormDefinitionDescriptorsAsync(): Promise<SimpleStore<FormDefinitionDescriptor[]>> {
        return this.processOperationAsync(
            new SimpleStore<FormDefinitionDescriptor[]>(),
            async target => {
                const response = await this.formEditingApiClient.getAllFormDefinitionDescriptorsQueryAsync(CreateRequestId(), new FormEngineProxy.GetAllFormDefinitionDescriptorsControllerDto());

                target.operationInfo = createOperationInfo(response);
                target.value = response.formDefinitionDescriptors.map(i =>
                    new FormDefinitionDescriptor(i.id, i.formDefinitionName, mapLocalizedLabelsToMultiLingualLabelMap(i.formDefinitionLocalizedDisplayNames), i.schemaVersion, i.formDataElementDescriptors.map(j =>
                        new FormDataElementDescriptor(j.formDataElementName, j.formDataElementType, mapLocalizedLabelsToMultiLingualLabelMap(j.localizedLabels), j.isArray, j.isLongString))));
            }
        );
    }

    public getFormDefinitionListAsync(baseEntityType: string, onlyUserForms: boolean): Promise<SimpleStore<PagedItemStore<IFormDefinitionListItem>>> {
        return this.processOperationAsync(
            new SimpleStore<PagedItemStore<IFormDefinitionListItem>>(),
            async target => {
                const response = await this.formEditingApiClient.getFormDefinitionsQueryAsync(CreateRequestId(), baseEntityType, onlyUserForms.toString());

                target.operationInfo = createOperationInfo(response);
                target.value = new PagedItemStore(response.formDefinitions.map(this.mapFormDefinitionListItem));
            }
        );
    }

    public getFormDefinitionsAsync(baseEntityType: string, onlyUserForms: boolean): Promise<SimpleStore<IFormDefinition[]>> {
        return this.processOperationAsync(
            new SimpleStore<IFormDefinition[]>(),
            async target => {
                const response = await this.formEditingApiClient.getFormDefinitionsQueryAsync(CreateRequestId(), baseEntityType, onlyUserForms.toString());

                target.operationInfo = createOperationInfo(response);
                target.value = response.formDefinitions.map(this.mapFormDefinition);
            }
        );
    }

    public checkPermissionForFormDefinitionListAsync(): Promise<SimpleStore> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.formEditingApiClient.getFormDefinitionsQueryAsync(CreateRequestId(), "", String(true), true);

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public getFormDefinitionDetailAsync(id: FormDefinitionId): Promise<SimpleStore<IFormDefinition>> {
        return this.processOperationAsync(
            new SimpleStore<IFormDefinition>(),
            async target => {
                const response = await this.formEditingApiClient.getFormDefinitionQueryAsync(CreateRequestId(), id.value.toString());

                target.operationInfo = createOperationInfo(response);
                target.value = this.mapFormDefinition(response.formDefinition);
            }
        );
    }

    public hasCreatableFormInstanceLeftAsync(formDefinitionType: string, baseEntityId: string): Promise<SimpleStore<boolean>> {
        return this.processOperationAsync(
            new SimpleStore<boolean>(),
            async target => {
                const response = await this.formEditingApiClient.hasCreatableFormInstanceLeftQueryAsync(CreateRequestId(), baseEntityId, formDefinitionType);

                target.operationInfo = createOperationInfo(response);
                target.value = response.hasCreatableInstanceLeft;
            }
        );
    }

    @State.bound
    private mapFormDefinitionListItem(dto: FormEngineProxy.FormDefinitionDto): IFormDefinitionListItem {
        return {
            id: dto.id,
            name: dto.name,
            displayName: mapLocalizedLabelsToMultiLingualLabelMap(dto.localizedDisplayNames)?.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode)
        };
    }

    @State.bound
    private mapFormDefinition(dto: FormEngineProxy.FormDefinitionDto): IFormDefinition {
        return new FormDefinition(
            dto.id,
            dto.rowVersion,
            dto.name,
            mapLocalizedLabelsToMultiLingualLabelMap(dto.localizedDisplayNames),
            dto.baseEntity.value,
            new FormSchema(dto.rootSchema.id, dto.rootSchema.versions?.map(this.mapFormSchemaVersion) ?? []),
            new FormLayoutStore(dto.rootLayout.id, dto.rootLayout.versions?.map(this.mapFormLayoutVersion) ?? []),
            dto.formEnums?.map(this.mapFormEnum) ?? [],
            dto.formEditBehavior,
            dto.formLogics?.map(this.mapFormLogic) ?? [],
            dto.formLogicType,
            dto.formLogicTokens.map(this.mapFormLogicToken),
            dto.withoutDataHandling,
            dto.isUserForm,
            dto.instanceLimit
        );
    }

    @State.bound
    private mapFormLogicToken(dto: FormEngineProxy.FormLogicTokenDto) {
        return new FormLogicToken(
            dto.tokenName!,
            dto.settings as any
        );
    }

    @State.bound
    private mapFormEnum(formEnum: FormEngineProxy.FormEnumDto) {
        return new FormEnumStore(
            formEnum.name!,
            formEnum.values?.map(v => ({ value: v.value, MultiLingualDisplayValue: mapLocalizedLabelsToMultiLingualLabelMap(v.localizedDisplayValues), isDeleted: v.isDeleted } as IFormEnumValue)) ?? []
        );
    }

    @State.bound
    private mapFormLogic(formLogic: FormEngineProxy.FormLogic) {
        return new FormLogic(
            formLogic.logicName!,
            formLogic.content
        );
    }

    @State.bound
    private mapFormLayoutVersion(dto: FormEngineProxy.FormLayoutVersionDto): FormLayoutVersionStore {
        return new FormLayoutVersionStore(
            dto.versionNumber,
            dto.content?.map(this.mapFormLayoutContent) ?? []
        );
    }

    @State.bound
    private mapFormLayoutContent(dto: FormEngineProxy.FormLayoutBlockDto): IFormLayoutBlockStore {
        // TODO: mapper service!

        if (dto instanceof FormEngineProxy.FormLayoutGroupBlockDto) {
            return new FormLayoutGroupBlockStore(mapLocalizedLabelsToMultiLingualLabelMap(dto.localizedDisplayNames), dto.dataReference, dto.content?.map(this.mapFormLayoutContent) ?? []);
        }

        if (dto instanceof FormEngineProxy.FormLayoutRowBlockDto) {
            return new FormLayoutRowBlockStore(dto.content?.map(col => {
                const size: IFormLayoutRowColumnSize = {
                    xs: col.formLayoutRowColumnSize.xs as any ?? undefined,
                    sm: col.formLayoutRowColumnSize.sm as any ?? undefined,
                    md: col.formLayoutRowColumnSize.md as any ?? undefined,
                    lg: col.formLayoutRowColumnSize.lg as any ?? undefined,
                };

                if (col.formLayoutRowColumnContent instanceof FormEngineProxy.FormLayoutBlockRowColumnContentDto) {
                    return new FormLayoutRowColumnStore(col.formLayoutRowColumnContent.formLayoutBlocks?.map(this.mapFormLayoutContent) ?? [], size);
                }

                if (col.formLayoutRowColumnContent instanceof FormEngineProxy.FormLayoutDataEditorRowColumnContentDto) {
                    return new FormLayoutRowColumnStore(new FormLayoutDataElementEditorStore(
                        col.formLayoutRowColumnContent.editorType,
                        col.formLayoutRowColumnContent.dataReference,
                        mapLocalizedLabelsToMultiLingualLabelMap(col.formLayoutRowColumnContent.localizedLabels)
                    ), size);
                }

                throw new Error(`Unknown form layout row block element type: '${dto["_discriminator"]}'`);
            }) ?? []);
        }

        if (dto instanceof FormEngineProxy.FormLayoutCustomBlockDto) {
            const settings = this.formEditorRegistry.tryCreateCustomBlockSettings(dto.customBlockIdentifier);

            if (!isNullOrUndefined(dto.serializedSettings)) {
                const deserilaized = JSON.parse(dto.serializedSettings);
                Object.assign(settings, deserilaized);
            }

            return new FormLayoutCustomBlockStore(dto.customBlockIdentifier, settings);
        }

        throw new Error(`Unknown form layout element type: '${dto["_discriminator"]}'`);
    }

    @State.bound
    private mapFormSchemaVersion(dto: FormEngineProxy.FormSchemaVersionDto): FormSchemaVersion {
        return new FormSchemaVersion(
            dto.versionNumber,
            dto.name,
            dto.formElements?.map(this.mapFormDataElement) ?? [],
            dto.validationRules?.map(this.mapValidationRule) ?? [],
        );
    }

    private mapValidationRule(dto: FormEngineProxy.FormValidationRuleDto): IFormValidationRule {
        return {
            entityPath: dto.entityPath,
            ruleType: dto.ruleTypeId.value,
            ruleParameters: dto.ruleParameters
        };
    }

    @State.bound
    private mapFormDataElement(dto: FormEngineProxy.FormDataElementBase): FormDataElementBase {

        if (dto instanceof FormEngineProxy.BooleanFormDataElement) {
            return new BooleanFormDataElement(dto.name, dto.isArray, (dto.defaultValue as FormEngineProxy.BooleanDefaultValue)?.value, dto.isReadOnly, dto.isVisible);
        }

        // TODO: mapper service!
        if (dto instanceof FormEngineProxy.StringFormDataElement) {
            if (dto.defaultValue instanceof FormEngineProxy.FormDefaultValue) {
                const tokenDefaultValue = (dto.defaultValue as FormEngineProxy.TokenDefaultValue);
                return new StringFormDataElement(dto.name, dto.isArray, new TokenDefaultValue(tokenDefaultValue.symbol, tokenDefaultValue.displayAttributePath, tokenDefaultValue.providerParameterSettings), dto.isReadOnly, dto.isVisible, dto.isLong);
            } else {
                return new StringFormDataElement(dto.name, dto.isArray, (dto.defaultValue as FormEngineProxy.TextDefaultValue)?.value, dto.isReadOnly, dto.isVisible, dto.isLong);
            }
        }

        if (dto instanceof FormEngineProxy.NumberFormDataElement) {
            return new NumberFormDataElement(dto.name, dto.isArray, (dto.defaultValue as FormEngineProxy.NumberDefaultValue)?.value, dto.isReadOnly, dto.isInteger, dto.isVisible);
        }

        if (dto instanceof FormEngineProxy.DateFormDataElement) {
            return new DateFormDataElement(dto.name, dto.isArray, (dto.defaultValue as FormEngineProxy.DateDefaultValue)?.value, dto.isReadOnly, dto.isVisible);
        }

        if (dto instanceof FormEngineProxy.TimeFormDataElement) {
            return new TimeFormDataElement(dto.name, dto.isArray, (dto.defaultValue as FormEngineProxy.TimeDefaultValue)?.value, dto.isReadOnly, dto.isVisible);
        }

        if (dto instanceof FormEngineProxy.DateTimeFormDataElement) {
            return new DateTimeFormDataElement(dto.name, dto.isArray, (dto.defaultValue as FormEngineProxy.DateTimeDefaultValue)?.value, dto.isReadOnly, dto.isVisible);
        }

        if (dto instanceof FormEngineProxy.EnumFormDataElement) {
            return new EnumFormDataElement(
                dto.name,
                dto.isArray,
                (dto.defaultValue as FormEngineProxy.EnumDefaultValue)?.value,
                dto.isReadOnly,
                dto.isVisible,
                new FormEnumReference(
                    dto.formEnumReference.formEnumName,
                    dto.formEnumReference instanceof FormEngineProxy.ExternalFormEnumReference ? dto.formEnumReference.formDefinitionId : null
                ),
                dto.formEnumActiveValues
            );
        }

        if (dto instanceof FormEngineProxy.ReferencedEntityFormDataElement) {
            return new ReferencedEntityFormDataElement(
                dto.name,
                dto.isArray,
                (dto.defaultValue as FormEngineProxy.ReferencedEntityDefaultValue)?.value,
                dto.isReadOnly,
                dto.isVisible,
                dto.referencedEntity,
                dto.customParams,
                convertProxyFiltersToFilters(dto.filters)
            );
        }

        if (dto instanceof FormEngineProxy.ReferencedAggregateFormDataElement) {
            return new ReferencedAggregateFormDataElement(
                dto.name,
                dto.isArray,
                (dto.defaultValue as FormEngineProxy.ReferencedEntityDefaultValue)?.value,
                dto.isReadOnly,
                dto.isVisible,
                dto.isSchemaBased,
                dto.aggregateName
            );
        }

        if (dto instanceof FormEngineProxy.CompositeFormDataElement) {
            return new CompositeFormDataElement(
                dto.name,
                dto.isArray,
                dto.isReadOnly,
                dto.isVisible,
                dto.formDataElements.map(i => this.mapFormDataElement(i))
            );
        }

        if (dto instanceof FormEngineProxy.ReferencedExtensibleEnumFormDataElement) {
            return new ReferencedExtensibleEnumFormDataElement(
                dto.name,
                dto.isArray,
                (dto.defaultValue as FormEngineProxy.ReferencedExtensibleEnumDefaultValue)?.value,
                dto.isReadOnly,
                dto.isVisible,
                dto.extensibleEnum
            );
        }

        if (dto instanceof FormEngineProxy.ReferencedEnumFormDataElement) {
            return new ReferencedEnumFormDataElement(
                dto.name,
                dto.isArray,
                (dto.defaultValue as FormEngineProxy.ReferencedEnumDefaultValue)?.value,
                dto.isReadOnly,
                dto.isVisible,
                dto.enum
            );
        }

        throw new Error(`Unknown form data element type: '${dto["_discriminator"]}'`);
    }

    public createFormDefinitionAsync(
        baseEntityType: string,
        name: string,
        multiLingualDisplayName: MultiLingualLabel,
        rootSchemaVersion: FormSchemaVersion,
        formEnums: FormEnumStore[],
        rootLayoutVersion: FormLayoutVersionStore,
        formEditBehavior: FormEditBehavior,
        formLogicType: FormLogicType,
        formLogicTokens: FormLogicToken[],
        formLogics: FormLogic[],
        withoutDataHandling: boolean,
        isUserForm: boolean,
        instanceLimit: number): Promise<SimpleStore<IFormDefinition>> {
        return this.processOperationAsync(
            new SimpleStore<IFormDefinition>(),
            async target => {
                const response = await this.formEditingApiClient.createFormDefinitionRootLayoutCommandAsync(
                    CreateRequestId(),
                    this.getCreateFormDefinitionRootLayoutControllerDto(
                        baseEntityType,
                        name,
                        multiLingualDisplayName,
                        rootSchemaVersion,
                        formEnums,
                        rootLayoutVersion,
                        formEditBehavior,
                        formLogicType,
                        formLogicTokens,
                        formLogics,
                        withoutDataHandling,
                        isUserForm,
                        instanceLimit)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = this.mapFormDefinition(response.formDefinition);
            }
        );
    }

    public getFormDefinitionExportData(formDefinition: IFormDefinition, actualVersion: IFormDefinitionVersion) {
        return JSON.stringify(this.getCreateFormDefinitionRootLayoutControllerDto(
            actualVersion.baseEntityType,
            formDefinition.name,
            actualVersion.multiLingualDisplayName,
            actualVersion.rootSchemaVersion,
            actualVersion.enums,
            actualVersion.rootLayoutVersion,
            actualVersion.formEditBehavior,
            actualVersion.formLogicType,
            actualVersion.formLogicTokens,
            actualVersion.formLogics,
            actualVersion.withoutDataHandling,
            actualVersion.isUserForm,
            actualVersion.instanceLimit), null, "    ");
    }

    public getFormDefinitionFromImportData(importData: string): IFormDefinition {
        const data = JSON.parse(importData);
        const dto = FormEngineProxy.CreateFormDefinitionRootLayoutControllerDto.fromJS(data);

        return new FormDefinition(
            FormDefinitionId.new,
            new RowVersion(1),
            dto.name,
            mapLocalizedLabelsToMultiLingualLabelMap(dto.localizedDisplayNames),
            dto.baseEntity.value,
            new FormSchema(FormSchemaId.new, [new FormSchemaVersion(
                1,
                dto.rootFormSchemaCreationItem.name,
                dto.rootFormSchemaCreationItem.formElements?.map(this.mapFormDataElement) ?? [],
                dto.rootFormSchemaCreationItem.validationRules?.map(this.mapValidationRule) ?? [],
            )]),
            new FormLayoutStore(FormLayoutId.new, [
                new FormLayoutVersionStore(
                    1,
                    dto.rootFormLayoutCreationItem.content?.map(this.mapFormLayoutContent) ?? []
                )
            ]),
            dto.formEnums?.map(this.mapFormEnum) ?? [],
            dto.formEditBehavior ?? FormEditBehavior.ImmediatelyEditable,
            dto.formLogics?.map(this.mapFormLogic) ?? [],
            dto.formLogicType ?? FormLogicType.CSharp,
            dto.formLogicTokens.map(this.mapFormLogicToken) ?? [],
            dto.withoutDataHandling,
            dto.isUserForm,
            dto.instanceLimit
        );
    }

    private getCreateFormDefinitionRootLayoutControllerDto(
        baseEntityType: string,
        name: string,
        multiLingualDisplayName: MultiLingualLabel,
        rootSchemaVersion: FormSchemaVersion,
        formEnums: FormEnumStore[],
        rootLayoutVersion: FormLayoutVersionStore,
        formEditBehavior: FormEditBehavior,
        formLogicType: FormLogicType,
        formLogicTokens: FormLogicToken[],
        formLogics: FormLogic[],
        withoutDataHandling: boolean,
        isUserForm: boolean,
        instanceLimit: number) {
        return new FormEngineProxy.CreateFormDefinitionRootLayoutControllerDto({
            baseEntity: new FormEngineProxy.FormBaseEntity({ value: baseEntityType }),
            localizedDisplayNames: mapMultiLingualLabelMapToLocalizedLabels(multiLingualDisplayName),
            name: name,
            rootFormSchemaCreationItem: new FormEngineProxy.FormSchemaCreationItemDto({
                name: rootSchemaVersion.name,
                formElements: rootSchemaVersion.dataElements.map(this.getFormDataElementDto) ?? [],
                validationRules: rootSchemaVersion.validationRules.map(this.getFormValidationRuleDto)
            }),
            formEnums: formEnums.map(this.getFormEnumDto),
            rootFormLayoutCreationItem: new FormEngineProxy.FormLayoutCreationItemDto({
                content: rootLayoutVersion.content.map(this.getFormLayoutBlockDto) ?? []
            }),
            formEditBehavior: formEditBehavior,
            formLogicType: formLogicType,
            formLogicTokens: formLogicTokens.map(this.getFormLogicTokenDto),
            formLogics: formLogics.map(this.getFormLogicDto),
            withoutDataHandling,
            isUserForm,
            instanceLimit
        });
    }

    private getFormValidationRuleDto(rule: IFormValidationRule) {
        return new FormEngineProxy.FormValidationRuleDto({
            entityPath: rule.entityPath,
            ruleTypeId: new RuleTypeId(rule.ruleType),
            ruleParameters: rule.ruleParameters
        });
    }

    public updateFormDefinitionAsync(
        id: FormDefinitionId,
        rowVersion: RowVersion,
        multiLingualDisplayName: MultiLingualLabel,
        rootSchemaId: FormSchemaId,
        rootSchemaVersion: FormSchemaVersion,
        formEnums: FormEnumStore[],
        rootLayoutId: FormLayoutId,
        rootLayoutVersion: FormLayoutVersionStore,
        formEditorChanges: FormEditorChange[],
        formEditBehavior: FormEditBehavior,
        formLogicType: FormLogicType,
        formLogics: FormLogic[],
        formLogicTokens: FormLogicToken[],
        withoutDataHandling: boolean,
        isUserForm: boolean,
        instanceLimit: number
    ): Promise<SimpleStore<IFormDefinition>> {
        return this.processOperationAsync(
            new SimpleStore<IFormDefinition>(),
            async target => {
                const response = await this.formEditingApiClient.updateFormDefinitionRootLayoutCommandAsync(
                    CreateRequestId(),
                    new FormEngineProxy.UpdateFormDefinitionRootLayoutControllerDto({
                        formDefinitionId: id,
                        localizedDisplayNames: mapMultiLingualLabelMapToLocalizedLabels(multiLingualDisplayName),
                        rootFormSchemaUpdateItem: new FormEngineProxy.FormSchemaUpdateItemDto({
                            id: rootSchemaId,
                            name: rootSchemaVersion.name,
                            formElements: rootSchemaVersion.dataElements.map(this.getFormDataElementDto) ?? [],
                            validationRules: rootSchemaVersion.validationRules.map(this.getFormValidationRuleDto)
                        }),
                        formEnums: formEnums.map(this.getFormEnumDto),
                        rootFormLayoutUpdateItem: new FormEngineProxy.FormLayoutUpdateItemDto({
                            id: rootLayoutId,
                            content: rootLayoutVersion.content.map(this.getFormLayoutBlockDto) ?? []
                        }),
                        formEditorChanges: formEditorChanges.map(this.getFormEditorChangeDto),
                        rowVersion,
                        formEditBehavior,
                        formLogicType,
                        formLogics: formLogics.map(this.getFormLogicDto),
                        formLogicTokens: formLogicTokens.map(this.getFormLogicTokenDto),
                        withoutDataHandling,
                        isUserForm,
                        instanceLimit
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = this.mapFormDefinition(response.formDefinition);
            }
        );
    }

    @State.bound
    private getFormLogicDto(formLogic: FormLogic) {
        return new FormEngineProxy.FormLogic({
            logicName: formLogic.logicName,
            content: formLogic.content
        });
    }

    @State.bound
    private getFormLogicTokenDto(formLogicToken: FormLogicToken) {
        return new FormEngineProxy.FormLogicTokenDto({
            tokenName: formLogicToken.tokenName,
            settings: formLogicToken.settings
        });
    }

    @State.bound
    private getFormEnumDto(formEnum: FormEnumStore) {
        return new FormEngineProxy.FormEnumDto({
            name: formEnum.name,
            values: formEnum.values.map(v => new FormEngineProxy.FormEnumValueDto({ value: v.value, localizedDisplayValues: mapMultiLingualLabelMapToLocalizedLabels(v.MultiLingualDisplayValue), isDeleted: v.isDeleted }))
        });
    }

    private getFormEditorChangeDto(formEditorChange: FormEditorChange) {
        return new FormEngineProxy.FormEditorChangeDto({
            dataReference: formEditorChange.dataReference,
            formEditorAction: formEditorChange.action,
            formPropertyChange: formEditorChange.propertyChanged
        });
    }

    @State.bound
    private getFormLayoutBlockDto(element: IFormLayoutBlockStore): FormEngineProxy.FormLayoutBlockDto {

        if (element instanceof FormLayoutGroupBlockStore) {
            return new FormEngineProxy.FormLayoutGroupBlockDto({
                localizedDisplayNames: mapMultiLingualLabelMapToLocalizedLabels(element.multiLingualDisplayName),
                dataReference: element.dataReference,
                content: element.content?.map(this.getFormLayoutBlockDto) ?? []
            });
        }

        if (element instanceof FormLayoutCustomBlockStore) {
            return new FormEngineProxy.FormLayoutCustomBlockDto({
                customBlockIdentifier: element.customBlockIdentifier,
                serializedSettings: isNullOrUndefined(element.settings) ? null : JSON.stringify(element.settings)
            });
        }

        if (element instanceof FormLayoutRowBlockStore) {
            return new FormEngineProxy.FormLayoutRowBlockDto({
                content: element.content.map(col => {

                    const size = new FormEngineProxy.FormLayoutRowColumnSizeDto(col.size as any);

                    if (col.content instanceof FormLayoutDataElementEditorStore) {
                        return new FormEngineProxy.FormLayoutRowColumnDto({
                            formLayoutRowColumnSize: size,
                            formLayoutRowColumnContent: new FormEngineProxy.FormLayoutDataEditorRowColumnContentDto({
                                editorType: col.content.editorType,
                                dataReference: col.content.dataReference,
                                localizedLabels: mapMultiLingualLabelMapToLocalizedLabels(col.content.multiLingualLabel)
                            })
                        });
                    }

                    return new FormEngineProxy.FormLayoutRowColumnDto({
                        formLayoutRowColumnSize: size,
                        formLayoutRowColumnContent: new FormEngineProxy.FormLayoutBlockRowColumnContentDto({
                            formLayoutBlocks: col.content.map(this.getFormLayoutBlockDto) ?? []
                        })
                    });

                }) ?? []
            });
        }

        throw new Error(`Unknown FormLayoutBlock type: '${element}'`);
    }

    @State.bound
    private getFormElementDefaultValueDto(defaultValue: boolean | string | LocalDate | TimeOfDay | moment.Moment | number | TokenDefaultValue | null) {
        if (isNullOrUndefined(defaultValue)) {
            return null;
        }

        if (typeof (defaultValue) === "boolean") {
            return new FormEngineProxy.BooleanDefaultValue({
                value: defaultValue
            });
        } else if (defaultValue instanceof TokenDefaultValue) {
            return new FormEngineProxy.TokenDefaultValue({
                symbol: defaultValue.symbol,
                displayAttributePath: defaultValue.displayAttributePath,
                providerParameterSettings: defaultValue.providerParameterSettings
            });
        } else if (defaultValue instanceof LocalDate) {
            return new FormEngineProxy.DateDefaultValue({
                value: defaultValue
            });
        } else if (defaultValue instanceof TimeOfDay) {
            return new FormEngineProxy.TimeDefaultValue({
                value: defaultValue.valueInMinutes
            });
        } else if (moment.isMoment(defaultValue)) {
            return new FormEngineProxy.DateTimeDefaultValue({
                value: defaultValue
            });
        } else if (typeof (defaultValue) === "number") {
            return new FormEngineProxy.NumberDefaultValue({
                value: defaultValue
            });
        } else {
            return new FormEngineProxy.TextDefaultValue({
                value: defaultValue.toString()
            });
        }
    }

    @State.bound
    private getFormDataElementDto(element: FormDataElementBase): FormEngineProxy.FormDataElementBase {

        // TODO: mapper service

        const baseFields = {
            name: element.name,
            isArray: element.isArray,
            isReadOnly: element.isReadOnly,
            isVisible: element.isVisible
        };

        if (element instanceof BooleanFormDataElement) {
            return new FormEngineProxy.BooleanFormDataElement({
                ...baseFields,
                defaultValue: element.defaultValue ? this.getFormElementDefaultValueDto(element.defaultValue) : null
            });
        }

        if (element instanceof StringFormDataElement) {
            return new FormEngineProxy.StringFormDataElement({
                ...baseFields,
                defaultValue: element.defaultValue ? this.getFormElementDefaultValueDto(element.defaultValue) : null,
                isLong: element.isLong
            });
        }

        if (element instanceof NumberFormDataElement) {
            return new FormEngineProxy.NumberFormDataElement({
                ...baseFields,
                defaultValue: element.defaultValue ? this.getFormElementDefaultValueDto(element.defaultValue) : null,
                isInteger: element.isInteger
            });
        }

        if (element instanceof DateFormDataElement) {
            return new FormEngineProxy.DateFormDataElement({
                ...baseFields,
                defaultValue: element.defaultValue ? this.getFormElementDefaultValueDto(element.defaultValue) : null,
            });
        }

        if (element instanceof TimeFormDataElement) {
            return new FormEngineProxy.TimeFormDataElement({
                ...baseFields,
                defaultValue: element.defaultValue ? this.getFormElementDefaultValueDto(element.defaultValue) : null,
            });
        }

        if (element instanceof DateTimeFormDataElement) {
            return new FormEngineProxy.DateTimeFormDataElement({
                ...baseFields,
                defaultValue: element.defaultValue ? this.getFormElementDefaultValueDto(element.defaultValue) : null,
            });
        }

        if (element instanceof EnumFormDataElement) {
            return new FormEngineProxy.EnumFormDataElement({
                ...baseFields,
                defaultValue: element.defaultValue ? new FormEngineProxy.EnumDefaultValue({
                    value: element.defaultValue as number
                }) : null,
                formEnumReference: getFormEnumReferenceDto(element.enumReference),
                formEnumActiveValues: element.activeValues
            });
        }

        if (element instanceof ReferencedEntityFormDataElement) {
            return new FormEngineProxy.ReferencedEntityFormDataElement({
                ...baseFields,
                defaultValue: element.defaultValue ? this.getFormElementDefaultValueDto(element.defaultValue) : null,
                referencedEntity: element.referencedEntity,
                customParams: element.customParams,
                filters: convertFiltersToProxyFilters(element.filters)
            });
        }

        if (element instanceof ReferencedExtensibleEnumFormDataElement) {
            return new FormEngineProxy.ReferencedExtensibleEnumFormDataElement({
                ...baseFields,
                defaultValue: element.defaultValue ? this.getFormElementDefaultValueDto(element.defaultValue) : null,
                extensibleEnum: element.extensibleEnum
            });
        }

        if (element instanceof ReferencedEnumFormDataElement) {
            return new FormEngineProxy.ReferencedEnumFormDataElement({
                ...baseFields,
                defaultValue: element.defaultValue ? this.getFormElementDefaultValueDto(element.defaultValue) : null,
                isReadOnly: element.isReadOnly,
                enum: element.enumName,
                isVisible: element.isVisible
            });
        }

        if (element instanceof CompositeFormDataElement) {
            return new FormEngineProxy.CompositeFormDataElement({
                ...baseFields,
                formDataElements: element.formDataElements.map(i => this.getFormDataElementDto(i)),
            });
        }

        throw new Error(`Unknown FormDataElement type: '${element}'`);
    }

    public getAllFormTokensAsync(): Promise<SimpleStore<IFormToken[]>> {
        return this.processOperationAsync(
            new SimpleStore<IFormToken[]>(),
            async target => {
                const response = await this.formTokenHandlingApiClient.getAllFormTokensQueryAsync(CreateRequestId(), new FormEngineProxy.GetAllFormTokensControllerDto());

                target.operationInfo = createOperationInfo(response);
                target.value = response.formTokens.map(token => ({
                    id: token.id,
                    name: token.tokenName,
                    description: token.description,
                    symbol: token.symbol,
                    tokenLayout: this.mapTokenLayout(token),
                    dataElements: token.formTokenDataElements.map(dataElement => (this.mapFormDataElement(dataElement.formDataElement)))
                } as IFormToken));
            }
        );
    }

    private mapTokenLayout(dto: FormEngineProxy.FormTokenDto): FormLayoutFragment {
        if (dto.formTokenLayout instanceof FormEngineProxy.FormTokenBlockLayoutDto) {
            return new FormLayoutFragment(null, dto.formTokenLayout.formLayoutBlocks.map(this.mapFormLayoutContent));

        } else if (dto.formTokenLayout instanceof FormEngineProxy.FormTokenEditorLayoutDto) {

            const defaultValue = dto.formTokenDataElements.find(x => x.formDataElement.name === (dto.formTokenLayout as FormEngineProxy.FormTokenEditorLayoutDto).formLayoutDataEditorRowColumnContent.dataReference).formDataElement.defaultValue;
            return new FormLayoutFragment(null, new FormLayoutDataElementEditorStore(
                dto.formTokenLayout.formLayoutDataEditorRowColumnContent.editorType,
                null,
                mapLocalizedLabelsToMultiLingualLabelMap(dto.formTokenLayout.formLayoutDataEditorRowColumnContent.localizedLabels)
            ));
        }

        throw new Error("Unknown FormTokenLayoutDto subclass.");
    }

    public getAvailableFormEnumsAsync(): Promise<SimpleStore<IFormEnumsInDefinition[]>> {
        return this.processOperationAsync(
            new SimpleStore<IFormEnumsInDefinition[]>(),
            async target => {

                const response = await this.formEditingApiClient.getFormEnumsQueryAsync(CreateRequestId());

                target.operationInfo = createOperationInfo(response);
                target.value = response.formEnumsByDefinitions?.map(fe => ({
                    formDefinitionId: fe.formDefinitionId,
                    enums: fe.formEnums?.map(f => ({
                        displayName: mapLocalizedLabelsToMultiLingualLabelMap(f.localizedDisplayName),
                        enum: this.mapFormEnum(f.formEnum)
                    } as INamedFormEnum)) ?? []
                } as IFormEnumsInDefinition));
            }
        );
    }

    //#endregion

    //#region FormInstance

    public getFormInstanceListAsync(baseEntityType: string, baseEntityId: StringEntityId, formDefinitions?: FormDefinitionId[]): Promise<SimpleStore<PagedItemStore<IFormListItem>>> {
        return this.processOperationAsync(
            new SimpleStore<PagedItemStore<IFormListItem>>(),
            async target => {
                const response = await this.formInstanceApiClient.getAllFormInstancesForBaseIdentifierQueryAsync(CreateRequestId(), new FormEngineProxy.GetAllFormInstancesForBaseIdentifierControllerDto({
                    formBaseIdentifier: new FormEngineProxy.FormBaseIdentifierDto({
                        formBaseEntity: new FormEngineProxy.FormBaseEntity({
                            value: baseEntityType
                        }),
                        entityId: parseInt(baseEntityId.value, 10)
                    }),
                    formDefinitions
                }));

                target.operationInfo = createOperationInfo(response);
                target.value = new PagedItemStore(response.formInstances.map(fi => ({ id: fi.id, definitionId: fi.formDefinitionId } as IFormListItem)));
            }
        );
    }

    public getFormInstanceDetailAsync(id: FormInstanceId): Promise<SimpleStore<IForm>> {
        return this.processOperationAsync(
            new SimpleStore<IForm>(),
            async target => {
                const response = await this.formInstanceApiClient.getFormInstanceQueryAsync(CreateRequestId(), id.value);

                target.operationInfo = createOperationInfo(response);
                const form = await this.mapFormFromDtoAsync(id, response.formInstance, response.compositeValidationResult);

                target.value = form;
            }
        );
    }

    public getNewFormInstanceAsync(baseEntityType: string, baseEntityId: StringEntityId, formDefinitionId: FormDefinitionId, scopes: IFormScopes): Promise<SimpleStore<IForm>> {
        return this.processOperationAsync(
            new SimpleStore<IForm>(),
            async target => {
                const response = await this.formInstanceApiClient.getNewFormInstanceQueryAsync(
                    CreateRequestId(),
                    new FormEngineProxy.GetNewFormInstanceControllerDto({
                        formBaseIdentifier: new FormEngineProxy.FormBaseIdentifierDto({
                            formBaseEntity: new FormEngineProxy.FormBaseEntity({
                                value: baseEntityType
                            }),
                            entityId: parseInt(baseEntityId.value, 10)
                        }),
                        formDefinitionId: formDefinitionId,
                        formScopes: this.mapFormScopesToDto(scopes)
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = await this.mapFormFromDtoAsync(FormInstanceId.new, response.formInstance, null);
            }
        );
    }

    public createFormInstanceAsync(baseEntityType: string, baseEntityId: StringEntityId, formDefinitionId: FormDefinitionId, formData: IFormDataStore, scopes: IFormScopes): Promise<SimpleStore<IForm>> {
        return this.processOperationAsync(
            new SimpleStore<IForm>(),
            async target => {
                const dto = this.getCreateFormInstanceControllerDto(
                    baseEntityType,
                    baseEntityId,
                    formDefinitionId,
                    formData,
                    scopes
                );
                const response = await this.formInstanceApiClient.createFormInstanceCommandAsync(CreateRequestId(), dto);

                target.operationInfo = createOperationInfo(response);
                target.value = await this.mapFormFromDtoAsync(FormInstanceId.new, response.formInstance, response.compositeValidationResult);
            }
        );
    }

    private mapFormScopesToDto(scopes: IFormScopes) {
        return Object.keys(scopes).map(name => new FormEngineProxy.FormScopeIdentifierDto({
            scope: name,
            value: scopes[name]
        }));
    }

    public updateFormInstanceAsync(id: FormInstanceId, formDefinitionId: FormDefinitionId, formData: IFormDataStore, formScopes: IFormScopes, rowVersion: RowVersion): Promise<SimpleStore<IForm>> {
        return this.processOperationAsync(
            new SimpleStore<IForm>(),
            async target => {
                const dto = this.getUpdateFormInstanceControllerDto(
                    id,
                    formDefinitionId,
                    formData,
                    formScopes,
                    rowVersion
                );
                const response = await this.formInstanceApiClient.updateFormInstanceCommandAsync(CreateRequestId(), dto);

                target.operationInfo = createOperationInfo(response);
                target.value = await this.mapFormFromDtoAsync(id, response.formInstance, response.compositeValidationResult);
            }
        );
    }

    public deleteFormInstanceAsync(id: FormInstanceId, rowVersion: RowVersion) {
        return this.processOperationAsync(
            new SimpleStore<any>(),
            async target => {
                const dto = this.getDeleteFormInstanceControllerDto(
                    id,
                    rowVersion
                );
                const response = await this.formInstanceApiClient.deleteFormInstanceCommandAsync(
                    CreateRequestId(),
                    dto
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    private getUpdateFormInstanceControllerDto(id: FormInstanceId, formDefinitionId: FormDefinitionId, formData: IFormDataStore, formScopes: IFormScopes, rowVersion: RowVersion, isValidateOnly: boolean = false) {
        return new FormEngineProxy.UpdateFormInstanceControllerDto({
            formInstanceId: id,
            formDefinitionId,
            content: mapToFormInstanceContentDto(formData),
            formScopes: this.mapFormScopesToDto(formScopes),            
            rowVersion,
            isValidateOnly
        });
    }

    private getDeleteFormInstanceControllerDto(id: FormInstanceId, rowVersion: RowVersion, isValidateOnly: boolean = false) {
        return new FormEngineProxy.DeleteFormInstanceControllerDto({
            isValidateOnly: isValidateOnly,
            formInstanceId: id,
            rowVersion: rowVersion
        } as FormEngineProxy.IDeleteFormInstanceControllerDto);
    }

    private async mapFormFromDtoAsync(id: FormInstanceId, formInstance: FormEngineProxy.FormInstanceDto, validationResult: FormEngineProxy.CompositeValidationResult) {
        if (!formInstance) {
            return new Form(id, null, null, null, mapValidationResults(validationResult as unknown as IServerCompositeValidationResult), null, null);
        }

        const definition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(formInstance.formDefinitionId);
        const formData = await restoreFormDataStoreAsync(formInstance.content, definition, this.formEngineReferenceDataStore);
        return new Form(
            formInstance.id,
            formInstance.rowVersion,
            formInstance.formDefinitionId,
            formData,
            mapValidationResults(validationResult as unknown as IServerCompositeValidationResult),
            formInstance.createdAt,
            formInstance.createdBy
        );
    }

    public validateNewFormInstanceAsync(baseEntityType: string, baseEntityId: StringEntityId, formDefinitionId: FormDefinitionId, formData: IFormDataStore, scopes: IFormScopes): Promise<SimpleStore<IForm>> {
        return this.processOperationAsync(
            new SimpleStore<IForm>(),
            async target => {
                const dto = this.getCreateFormInstanceControllerDto(
                    baseEntityType,
                    baseEntityId,
                    formDefinitionId,
                    formData,
                    scopes,
                    true
                );
                const response = await this.formInstanceApiClient.createFormInstanceCommandAsync(CreateRequestId(), dto);

                target.operationInfo = createOperationInfo(response);
                target.value = await this.mapFormFromDtoAsync(FormInstanceId.new, response.formInstance, response.compositeValidationResult);
            }
        );
    }

    private getCreateFormInstanceControllerDto(baseEntityType: string, baseEntityId: StringEntityId, formDefinitionId: FormDefinitionId, formData: IFormDataStore, scopes: IFormScopes, isValidateOnly: boolean = false) {
        return new FormEngineProxy.CreateFormInstanceControllerDto({
            isValidateOnly,
            formDefinitionId: formDefinitionId,
            formBaseIdentifier: new FormEngineProxy.FormBaseIdentifierDto({
                formBaseEntity: new FormEngineProxy.FormBaseEntity({
                    value: baseEntityType
                }),
                entityId: parseInt(baseEntityId.value, 10)
            }),
            content: mapToFormInstanceContentDto(formData),
            formScopes: this.mapFormScopesToDto(scopes)
        });
    }

    public validateExistingFormInstanceAsync(id: FormInstanceId, formDefinitionId: FormDefinitionId, formData: IFormDataStore, formScopes: IFormScopes, rowVersion: RowVersion): Promise<SimpleStore<IForm>> {
        return this.processOperationAsync(
            new SimpleStore<IForm>(),
            async target => {
                const dto = this.getUpdateFormInstanceControllerDto(
                    id,
                    formDefinitionId,
                    formData,
                    formScopes,
                    rowVersion,
                    true
                );
                const response = await this.formInstanceApiClient.updateFormInstanceCommandAsync(CreateRequestId(), dto);

                target.operationInfo = createOperationInfo(response);
                target.value = await this.mapFormFromDtoAsync(response.formInstance.id, response.formInstance, response.compositeValidationResult);
            }
        );
    }

    //#endregion
}
