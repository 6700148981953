import AddressTypeId from "@Primitives/AddressTypeId.g";
import CountryId from "@Primitives/CountryId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import PractitionerAddressId from "@Primitives/PractitionerAddressId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class PractitionerAddressStore extends EntityStoreBase<PractitionerAddressId> {

    @State.observable public addressLine: string = "";
    @State.observable.ref public countryId: CountryId = null;
    @State.observable.ref public settlement: string = null;
    @State.observable.ref public zipCode: string = null;
    @State.observable.ref public addressTypeId: AddressTypeId = null;
    @State.observable.ref public validFrom: LocalDate = null;
    @State.observable.ref public validTo: LocalDate = null;

    @State.action.bound
    public setAddressLine(addressLine: string) {
        this.addressLine = addressLine;
    }

    @State.action.bound
    public setCountryId(countryId: CountryId) {
        this.countryId = countryId;
    }

    @State.action.bound
    public setSettlement(settlement: string) {
        this.settlement = settlement;
    }

    @State.action.bound
    public setZipCode(zipCode: string) {
        this.zipCode = zipCode;
    }

    @State.action.bound
    public setAddressTypeId(addressTypeId: AddressTypeId) {
        this.addressTypeId = addressTypeId;
    }

    @State.action.bound
    public setValidFrom(newValue: LocalDate) {
        this.validFrom = newValue;
    }

    @State.action.bound
    public setValidTo(newValue: LocalDate) {
        this.validTo = newValue;
    }

    @State.bound
    public isEmpty() {
        return !this.addressLine 
        && !this.countryId
        && !this.settlement
        && !this.zipCode
        && !this.addressTypeId
        && !this.validFrom
        && !this.validTo;
    }
}
