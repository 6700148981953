import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import moment from "moment";
import OrganizationIdTypeUnion from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnitIdTypeUnion";
import CareTypeId from "@Primitives/CareTypeId.g";
import MomentRange from "@Toolkit/CommonWeb/Model/MomentRange";
import AccessLevel from "@Primitives/AccessLevel";
import PractitionerId from "@Primitives/PractitionerId.g";
import CareActivitySource from "@Primitives/CareActivitySource.g";
import DiagnosisStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisStore";
import DocumentId from "@Primitives/DocumentId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";

export default class PatientsCareActivityStore {
    public careActivityId: CareActivityId;
    public carePeriod: MomentRange;
    public beginningOfCare: moment.Moment;
    public dischargedAt: moment.Moment;
    public primaryCareIdentifier: string;
    public organizationUnitId: PointOfCareId;
    public careActivityState: CareActivityState;
    public primaryParticipantId: PractitionerId;
    public primaryDocumentId: DocumentId;
    public careActivitySource: CareActivitySource;
    public careTypeId: CareTypeId;
    public referralWorkplaceId: EntityVersionSelector<ExternalLocationId>;
    public referralDoctorId: PractitionerId;
    public accessLevel: AccessLevel;
    public diagnoses: DiagnosisStore[];

    public referralDoctorCode: string;
    public primaryParticipantCode: string;
    public referralWorkplaceCode: string;
    public pointOfCareCode: string;

    public extensionData: {[key: string]: any};
}
