import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";

export default class ReferenceDataCollector<TId extends IStringEntityId> {
    private collected: TId[] = [];
    private rawCollected: Set<string> = new Set<string>();

    public collect(id: TId) {
        if (id?.value && !this.rawCollected.has(id.value)) {
            this.collected.push(id);
            this.rawCollected.add(id.value);
        }
    }

    public getCollectedIds() {
        return this.collected;
    }

    public clear() {
        this.collected = [];
        this.rawCollected.clear();
    }
}