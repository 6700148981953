import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import PractitionerRecommendationId from "@Primitives/PractitionerRecommendationId.g";
import DocumentInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentInfo";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { getPractitionerDocumentDto } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/PractitionerRecommendationDocument/PractitionerDocumentDtoMapper";
import { getDocumentInfo } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Common/DocumentStoreMapper";

@Di.injectable()
export default class PractitionerRecommendationDocumentApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IPractitionerRecommendationClient") private apiClient: Proxy.IPractitionerRecommendationClient
    ) {
        super();
    }

    public getSingeDocumentForPractitionerRecommendationAsync(
        practitionerRecommendationId: PractitionerRecommendationId,
        documentTypeCode?: string,
        isPrimary?: boolean,
        isAutomatic?: boolean
    ) {
        return this.processOperationAsync(
            new SimpleStore<DocumentInfo>(),
            async target => {
                const response = await this.apiClient.getSingleDocumentForPractitionerRecommendationQueryAsync(
                    CreateRequestId(),
                    getPractitionerDocumentDto(practitionerRecommendationId, documentTypeCode, isPrimary, isAutomatic)
                );

                target.operationInfo = createOperationInfo(response.document);
                target.value = getDocumentInfo(response.document);
            }
        );
    }
}