import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import {HisErrorBoundary} from "@HisPlatformControls";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import {getUseCaseAsUrlParam, parseUseCaseStateFromUrlParam} from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import IExternalLocationsRouteParams from "@HisPlatform/Application/Routes/IExternalLocationsRouteParams";
import ExternalLocationListPanel from "@HisPlatform/BoundedContexts/Organization/Components/Panels/ExternalLocation/ExternalLocationListPanel";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import UseCases from "@Primitives/UseCases";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

@State.observer
export default class ExternalLocationsPage extends React.Component<IRoutingFrameContentProps> {

    @State.computed private get routeParams(): IExternalLocationsRouteParams {
        return this.props.routingController.currentRoute.parameters as IExternalLocationsRouteParams;
    }

    @State.computed private get selectedExternalLocationId(): ExternalLocationId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments[0].value;
        }

        if (this.useCaseState?.useCase.value === UseCases.externalLocationDetail) {
            return new ExternalLocationId("new");
        }

        return null;
    }

    @State.computed private get useCaseState() {
        const useCaseState = this.routeParams?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.bound
    private setUseCaseState(selectedExternalLocationId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.replace(
            ApplicationRoutes.externalLocations.makeRoute({
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            })
        );
    }

    @State.bound
    private navigateToExternalLocation(externalLocationId: ExternalLocationId) {
        this.props.routingController.replace(
            ApplicationRoutes.externalLocations.makeRoute({
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.externalLocationDetail),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(externalLocationId, "externalLocationId")]
                )
            })
        );
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(ApplicationRoutes.externalLocations.makeRoute());
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    public render() {
        return (
            <HisErrorBoundary>
                <LoadingBoundary>
                    <ExternalLocationListPanel
                        onChange={this.setUseCaseState}
                        useCaseState={this.useCaseState}
                        selectedRowId={this.selectedExternalLocationId?.value}
                        onSelectedExternalLocationIdChange={this.navigateToExternalLocation}
                        onBack={this.goBack}
                        onCloseDetail={this.setClosedState}
                    />
                </LoadingBoundary>
            </HisErrorBoundary>
        );
    }
}
