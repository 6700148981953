import State from "@Toolkit/ReactClient/Common/StateManaging";
import NavigateToUseCaseArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/NavigateToUseCaseArgument";
import Log from "@Log";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import Action from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/Action";
import UseCaseNavigationAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/UseCaseNavigationAction";
import RefreshWorklistAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/RefreshWorklistAction";
import ExecuteClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ExecuteClientSideAction";
import DisplayPrintoutContentAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/DisplayPrintoutContentAction";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import { RowId } from "@CommonControls/DataGrid/IDataGridProps";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import ResourceId from "@Primitives/ResourceId.g";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import GetContentAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/GetContentAction";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import { IDeferredActionItem } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IDeferredActionItem";
import INDataDeferredActionTaskState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/INDataDeferredActionTaskState";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import LargeDataToken from "@Primitives/LargeDataToken.g";
import WorklistActionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistActionType.g";

export default class NDataActionProcessor {

    constructor(
        private readonly worklistApiAdapter: WorklistApiAdapter,
        private readonly notificationService: INotificationService,
        private readonly localizationService: ILocalizationService,
        private readonly fileSaverService: IFileSaverService,
        private readonly onPerformClientSideActionAsync: (clientSideAction: ClientSideActionDto, rowId: RowId) => Promise<WorkListArgument | boolean>,
        private readonly onPerformActionAsync: (action: Action, rowId: RowId) => Promise<boolean>,
        private readonly onReloadListAsync: () => Promise<void>,
        private readonly onUseCaseNavigation: (rowId: string, useCaseState: INDataUseCaseState) => void,
        private readonly selectedRowId: () => string,
        private readonly useCaseState: () => INDataUseCaseState,
        private readonly setLoadingState: (isLoading: boolean) => void,
        private readonly enterBatchActionMode: (activityReference: string) => void
    ) { }

    @State.bound
    public async processActionAsync(
        rowId: string,
        actionToken: string,
        argumentItems: { useCaseDisplayMode?: UseCaseDisplayMode },
        worklistActionType: WorklistActionType,
        activityReference: string,
        clientSideAction?: ClientSideActionDto) {

        try {
            this.setLoadingState(true);

            if (worklistActionType === WorklistActionType.Batch) {

                this.enterBatchActionMode(activityReference);
                await this.processActionTokenAsync(null, actionToken, new NavigateToUseCaseArgument(argumentItems.useCaseDisplayMode));

            } else {

                if (isNullOrUndefined(clientSideAction)) {
                    const argument = !isNullOrUndefined(argumentItems.useCaseDisplayMode)
                        ? new NavigateToUseCaseArgument(argumentItems.useCaseDisplayMode)
                        : null;

                    await this.processActionTokenAsync(rowId, actionToken, argument);
                } else {
                    if (!!this.onPerformClientSideActionAsync) {
                        const clientSideResult = await this.onPerformClientSideActionAsync(clientSideAction, rowId);
                        if (!isNullOrUndefined(clientSideResult)) {
                            await this.processActionTokenAsync(rowId, actionToken, clientSideResult);
                        }
                    } else {
                        Log.warn("Cannot perform client side action, because onPerformClientSideActionAsync is not specified.");
                    }
                }

            }
        } finally {
            this.setLoadingState(false);
        }
    }

    @State.bound
    public async enqueueDeferredActionsAsync(taskType: string, actionItems: IDeferredActionItem[], state: INDataDeferredActionTaskState) {
        try {
            this.setLoadingState(true);

            try {
                return await this.worklistApiAdapter.enqueueDeferredActionTokensAsync(
                    taskType,
                    actionItems,
                    state);
            } catch (err) {
                await this.onReloadListAsync();
                throw err;
            }

        } finally {
            this.setLoadingState(false);
        }
    }

    public async processDefaultActionAsync(
        actionData: {
            rowId: string,
            actionToken: string,
            argumentItems: { useCaseDisplayMode?: UseCaseDisplayMode },
            worklistActionType: WorklistActionType,
            activityReference: string,
            clientSideAction?: ClientSideActionDto
        },
        unavailableMessageResourceId: ResourceId,
        isAvailable: boolean
    ) {
        if (!isAvailable) {
            this.notificationService.warning(this.localizationService.localizeReferenceData(unavailableMessageResourceId));
        } else {
            await this.processActionAsync(actionData.rowId, actionData.actionToken, actionData.argumentItems, actionData.worklistActionType, actionData.activityReference, actionData.clientSideAction);
        }
    }

    @State.bound
    private async processActionTokenAsync(rowId: string, actionToken: string, tokenArgument?: WorkListArgument) {
        try {
            const result = await this.worklistApiAdapter.executeActionTokenAsync(actionToken, tokenArgument);
            await this.processActionResultAsync(result.value, rowId);
        } catch (err) {
            await this.onReloadListAsync();
            throw err;
        }
    }

    @State.bound
    private async processActionResultAsync(action: Action, rowId: string) {
        if (this.onPerformActionAsync) {
            const isHandled = await this.onPerformActionAsync(action, rowId);
            if (!!isHandled) {
                return;
            }
        }

        if (action instanceof UseCaseNavigationAction) {

            if (this.selectedRowId() === rowId && ValueWrapper.equals(this.useCaseState()?.useCase, action.useCase)) {
                this.onUseCaseNavigation(null, null);
                return;
            }

            this.onUseCaseNavigation(rowId ?? null, {
                useCase: action.useCase,
                useCaseArguments: action.useCaseArguments,
                displayMode: action.displayMode
            });

            if (action.shouldRefresh) {
                await this.onReloadListAsync();
            }

            return;
        }

        if (action instanceof RefreshWorklistAction) {
            await this.onReloadListAsync();
            return;
        }

        if (action instanceof GetContentAction) {
            await Promise.all(action.largeDataIds.map(x => this.downloadDocumentAsync(x)));
            return;
        }

        if (action instanceof ExecuteClientSideAction) {
            await this.onPerformClientSideActionAsync(action.action, rowId);
        }

        if (action instanceof DisplayPrintoutContentAction) {
            return;
        }

        if (isNullOrUndefined(action)) {
            throw new Error("There is no action command defined for this menu item.");
        }

        throw new Error(`Action ${action.constructor.name} is unknown and cannot be processed.`);
    }

    @State.bound
    private async downloadDocumentAsync(largeDataId: LargeDataToken) {
        const contentResponse = await this.worklistApiAdapter.getContentAsync(largeDataId);
        const fileName = contentResponse.value.fileName;
        const contentBytes = Base64Converter.toByteArray(contentResponse.value.content);
        this.fileSaverService.saveAs(new Blob([contentBytes], { type: contentResponse.value.mediaType }), fileName);
    }
}
