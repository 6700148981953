import Di from "@Di";
import { SyncEvent } from "ts-events";
import Guid from "@Toolkit/CommonWeb/Guid";
import IClientSessionService from "@HisPlatform/Services/Definition/ClientSession/IClientSessionService";
import IAuthenticationService from "@HisPlatform/Services/Definition/Authentication/IAuthenticationService";
import IClientSessionRepository from "@Toolkit/CommonWeb/Repositories/Definition/ClientSessionRepository/IClientSessionRepository";
import LoginResult from "@Toolkit/CommonWeb/Authentication/Definition/LoginResult";
import IHeaderProviderService from "@HisPlatform/Services/Definition/HeaderProvider/IHeaderProviderService";
import AuthenticationTokenParser from "@HisPlatform/Model/DomainModel/UserContext/AuthenticationTokenParser";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";

@Di.injectable()
export default class ClientSessionService implements IClientSessionService {

    private sessionId: string = "";
    public onSessionIdChanged = new SyncEvent<string>();
    private tempData: Map<string, any> = new Map<string, any>();

    constructor(
        @Di.inject("IAuthenticationService") private authenticationService: IAuthenticationService,
        @Di.inject("IClientSessionRepository") private clientSessionRepository: IClientSessionRepository,
        @Di.inject("IHeaderProviderService") private readonly headerProviderService: IHeaderProviderService
    ) {

    }

    public initializeAsync(): Promise<boolean> {
        this.authenticationService.onTriedLogin.on((loginResult: LoginResult) => {
            this.handleLogin(loginResult);
        });
        this.authenticationService.onLogout.on(() => {
            this.clearSessionId();
        });
        return Promise.resolve(true);
    }

    private handleLogin(loginResult: LoginResult): void {
        if (loginResult === LoginResult.Success) {
            console.log("handleLogin");
            this.generateNewSessionId();
        } else {
            this.clearSessionId();
        }
    }

    public generateNewSessionId(): void {

        const token = this.authenticationService.getCurrentToken();
        const parser = new AuthenticationTokenParser(token);

        const storedSessionId = this.clientSessionRepository.getClientSessionId();
        if (!isNullOrEmptyString(storedSessionId) && storedSessionId.split("__")[0] == `${parser.userId}`) {
            this.sessionId = storedSessionId.split("__")[1];
        } else {
            this.sessionId = Guid.newGuid();
            this.clientSessionRepository.saveClientSessionId(`${parser.userId}__${this.sessionId}`);
        }

        this.headerProviderService.setSessionId(this.sessionId);
        this.onSessionIdChanged.post(this.sessionId);
    }

    public getCurrentSessionId(): string {
        return this.sessionId;
    }

    public clearSessionId(): void {
        this.sessionId = "";
        this.headerProviderService.setSessionId("");
        this.clientSessionRepository.saveClientSessionId("");
        this.onSessionIdChanged.post("");
    }

    public setTempData(key: string, value: any) {
        this.tempData.set(key, value);
    }

    public getAndClearTempData(key: string) {
        const result = this.tempData.get(key);
        this.tempData.delete(key);
        return result;
    }
}