import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";

import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import EpisodeOfCareStatusSelectBox from "./EpisodeOfCareStatusSelectBox";
import EpisodeOfCareStatus from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/EpisodeOfCareStatus.g";

interface IEpisodeOfCareStatusColumnDependencies {
    localizationService: ILocalizationService;
}

interface IEpisodeOfCareStatusColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IEpisodeOfCareStatusColumnDependencies;
}


const EpisodeOfCareStatusColumn: React.FC<IEpisodeOfCareStatusColumnProps> = (props: IEpisodeOfCareStatusColumnProps) => {

    const valueRenderer = useCallback((value: EpisodeOfCareStatus) => {


        const localized = isNullOrUndefined(value) ? null : props._dependencies.localizationService.localizeEnum(EpisodeOfCareStatus[value], "EpisodeOfCareStatus");
        return isNullOrUndefined(localized) ? null : localized.Name;

    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<EpisodeOfCareStatus | EpisodeOfCareStatus[]>) => {
        return <EpisodeOfCareStatusSelectBox {...filterProps} maxMenuWidth={commonMenuMaxWidth}  hoverOnlyIndicatorIcons />;
    }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            showHint={false}
        />
    );
};

export default connect(
    EpisodeOfCareStatusColumn,
    new DependencyAdapter<IEpisodeOfCareStatusColumnProps, IEpisodeOfCareStatusColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);