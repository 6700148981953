import { getHashValue } from "@Toolkit/CommonWeb/EqualityHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CheckBoxGroupItemStore from "./CheckBoxGroupItemStore";

export default class CheckBoxGroupStore<TItem> {

    constructor(items: Array<CheckBoxGroupItemStore<TItem>>) {
        this.setMap(items);
    }

    @State.observable 
    private map: Map<string, CheckBoxGroupItemStore<TItem>> = new Map<string, CheckBoxGroupItemStore<TItem>>();

    @State.action.bound
    public setMap(items: Array<CheckBoxGroupItemStore<TItem>>) {
        if (items) {
            items.forEach(i => {
                this.map.set(getHashValue(i), i);
            });
        }
    }

    @State.computed
    public get selectedValues() {
        return Array.from(this.map.values()).filter(i => i.selectionState).map(i => i.value);
    }

    @State.bound
    public get(key: string) {
        return this.map.get(key);
    }

    @State.bound
    public getAll() {
        return Array.from(this.map.values());
    }

    @State.bound
    public getKeys() {
        return Array.from(this.map.keys());
    }

    @State.action.bound
    public setSelectionState(key: string, value: boolean | null) {
        const item = this.map.get(key);
        item.setSelectionState(value);
    }
}
