export { };

declare global {
    
    interface Array<T> {
        except(other: T[], comparator: (one: T, other: T) => boolean): T[];
    }
}


Array.prototype.except = function<T>(other: T[], comparator: (one: T, other: T) => boolean) {
    return (this as T[]).filter((item: T) => !other.some(otherItem => comparator(item, otherItem)));
};