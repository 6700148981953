import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import AttributeReference from "@Primitives/AttributeReference.g";
import { ICondition } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistFilterCondition";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IWorklistData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistData";
import IDynamicListDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IDynamicListDefinition";
import IWorklistItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistItem";
import IWorklistItemDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistItemDefinition";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import { mapPermissionScopesFromDto } from "./FrontendActionMapperHelpers";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import DateComparisonType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/DateComparisonType.g";
import NumericComparisonType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/NumericComparisonType.g";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import AttributeDisplayMode from "./Api/FrontendLists/Enum/AttributeDisplayMode.g";
import ItemPlacement from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/ItemPlacement.g";

export function configureFrontendListMappings(mapper: IMapperConfigurator, cultureCodeProvider: ICurrentCultureProvider) {

    mapper.registerByName<FrontendListParameters, Proxy.FilterDto[]>("FilterDtoArray", i => {
        if (!i.filter) {
            return null;
        }

        return [...Object.keys(i.filter).map(searchField => new Proxy.FilterDto({
            attributeReference: new AttributeReference(searchField),
            isNegated: false,
            condition: getFilterConditionDto(i.filter[searchField])
        }))];
    });

    mapper.registerByName<FrontendListParameters, Proxy.QueryPagingAndOrderingsOfAttributeReference>("QueryPagingsAndOrderings", i => {
        return new Proxy.QueryPagingAndOrderingsOfAttributeReference({
            paging: new Proxy.QueryPaging({ pageIndex: i.pageIndex, pageSize: i.pageSize }),
            orderings: i.orderBy && [new Proxy.QueryOrderingOfAttributeReference({
                direction: i.orderBy.ascending ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                fieldName: new AttributeReference(i.orderBy.fieldName)
            })]
        });
    });

    mapper.register<Proxy.FrontendListDto, { data: IWorklistData, definition: IDynamicListDefinition }>(Proxy.FrontendListDto, i => {
        return {
            data: {
                items: i.items && {
                    totalCount: i.items.totalCount ?? 0,
                    items: i.items.values.map(item => ({
                        id: item.id,
                        accessLevel: item.accessLevel,
                        attributes: item.attributes,
                        contextInfo: null,
                        defaultAction: null,
                        actions: null,
                        actionDescriptors: item.actionDescriptors?.map(a => {

                            if (!(a.presentation instanceof Proxy.ListItemActionPresentationDto)) {
                                throw new Error("Only ListItemActionPresentationDto supported.");
                            }

                            return new ActionDescriptor(
                                a.action.frontendAction,
                                a.presentation,
                                mapPermissionScopesFromDto(a.requiredPermissionScopes)
                            );
                        })
                    } as IWorklistItem))
                },
                someItemsAreHidden: i.items?.someItemsAreHidden ?? false,
                globalFrontendActions: i.globalActions?.map(ga => new ActionDescriptor(ga.action.frontendAction, ga.presentation, mapPermissionScopesFromDto(ga.requiredPermissionScopes)))
            },
            definition: i.listDefinition && {
                name:
                    mapLocalizedLabelsToMultiLingualLabelMap(i.listDefinition.localizedDisplayNames).getWithCurrentCultureCodeOrWithDefaultCultureCode(cultureCodeProvider.cultureCode),
                itemDefinitions: i.listDefinition.attributeDefinitions.map(itemDef => ({
                    attributeName: itemDef.attributeReference.value,
                    attributeType: itemDef.attributeType,
                    filterConditionType: itemDef.conditionType,
                    headerResourceId: itemDef.headerResourceId,
                    placement: mapDisplayModeToPlacement(itemDef.displayMode),
                    displayMode: itemDef.displayMode,
                    itemVisibility: null,
                    masterDetailDependentVisibility: itemDef.masterDetailDependentVisibility,
                    isOrderingDisabled: itemDef.isOrderingDisabled,
                    defaultColumnWidth: itemDef.defaultColumnWidth,
                    ellipsisOnOverflow: itemDef.ellipsisOnOverflow,
                    displayValue: itemDef.displayName
                } as IWorklistItemDefinition)),
                globalActions: null
            }
        };
    });
}

function getFilterConditionDto(condition: ICondition) {
    switch (condition.type) {
        case WorklistConditionType.String:
            return new Proxy.StringConditionDto({ value: condition.value, comparisonType: condition.comparisonType });
        case WorklistConditionType.EntityId:
            return new Proxy.EntityIdConditionDto({ conditionalIdList: condition.values });
        case WorklistConditionType.PersonName:
            return new Proxy.NameConditionDto({ namePart: condition.namePart });
        case WorklistConditionType.StringEnum:
            return new Proxy.StringEnumConditionDto({ enumList: condition.values });
        case WorklistConditionType.IntEnum:
            return new Proxy.IntEnumConditionDto({ enumList: condition.values });
        case WorklistConditionType.Numeric:
            return new Proxy.NumericConditionDto({

                value: condition.value,
                comparisonType: isNullOrUndefined(condition.comparisonType) ? NumericComparisonType.Equality : condition.comparisonType
            });
        case WorklistConditionType.Boolean:
            return new Proxy.BooleanConditionDto({ value: condition.value });
        case WorklistConditionType.Date:
            return new Proxy.DateConditionDto({

                from: condition.value.from,
                fromComparisonType: DateComparisonType.Inclusive,
                to: condition.value.to && condition.value.to.plusDays(1),
                toComparisonType: DateComparisonType.Exclusive
            });
        case WorklistConditionType.DateAndTime:
            return new Proxy.DateTimeConditionDto({

                from: condition.value.from.toLocalDayStartMoment(),
                fromComparisonType: DateComparisonType.Inclusive,
                to: condition.value.to && condition.value.to.plusDays(1).toLocalDayStartMoment(),
                toComparisonType: DateComparisonType.Inclusive
            });
        case WorklistConditionType.YearMonth:
            return new Proxy.YearMonthConditionDto({

                yearMonth: condition.value
            });
        case WorklistConditionType.StringEnumList: {
            return new Proxy.StringEnumListConditionDto({

                enumList: condition.values.map(item => new Proxy.StringEnumListWithNameDto({
                    name: item.name,
                    enumList: item.values.map(i => i.toString())
                }))
            });
        }
    }

    throw new Error("Unknown condition type");
}

function mapLocalizedLabelsToMultiLingualLabelMap(localizedLabels: Proxy.LocalizedLabel[]) {
    const localizedLabelMap = new Map<string, string>();
    localizedLabels.forEach(l => localizedLabelMap.set(l.cultureCode?.value, l.label));
    return new MultiLingualLabel(localizedLabelMap);
}

function mapDisplayModeToPlacement(displayMode: AttributeDisplayMode): ItemPlacement {
    switch (displayMode) {
        case AttributeDisplayMode.Column:
            return ItemPlacement.Header;
        case AttributeDisplayMode.InRow:
            return ItemPlacement.Body;
        default:
            return ItemPlacement.None;
    }
}