exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EhiCovidTestResultsPanel_positive-test-result_28FCu {\n  background-color: #ce5374;\n}\n.EhiCovidTestResultsPanel_negative-test-result_2pyGE {\n  background-color: #21ba45;\n}\n", ""]);

// exports
exports.locals = {
	"positive-test-result": "EhiCovidTestResultsPanel_positive-test-result_28FCu",
	"positiveTestResult": "EhiCovidTestResultsPanel_positive-test-result_28FCu",
	"negative-test-result": "EhiCovidTestResultsPanel_negative-test-result_2pyGE",
	"negativeTestResult": "EhiCovidTestResultsPanel_negative-test-result_2pyGE"
};