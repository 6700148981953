import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import PatientAllergyIntoleranceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceStore";
import PatientAllergyIntoleranceId from "@Primitives/PatientAllergyIntoleranceId.g";
import PatientAllergyIntoleranceHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceHistoryItem";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import AllergyIntoleranceReactionId from "@Primitives/AllergyIntoleranceReactionId.g";
import ReferencedAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/ReferencedAllergyIntoleranceAssignment";
import MedicationAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/MedicationAllergyIntoleranceAssignment";
import SubstanceAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/SubstanceAllergyIntoleranceAssignment";
import CustomAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/CustomAllergyIntoleranceAssignment";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import PatientAllergyIntoleranceListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceListStore";
import AllergyIntoleranceReactionAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/AllergyIntoleranceReactionAssignment";
import InternationalNonproprietaryNameAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/InternationalNonproprietaryNameAllergyIntoleranceAssignment";

type ResponseType = Proxy.GetPatientAllergyIntoleranceByIdCommandResponse | Proxy.AddPatientAllergyIntoleranceCommandResponse | Proxy.UpdatePatientAllergyIntoleranceCommandResponse | Proxy.DeletePatientAllergyIntoleranceHistoryItemCommandResponse;

@Di.injectable()
export default class PatientAllergyIntoleranceStoreMapper extends LockingEntityStoreMapper<Proxy.PatientAllergyIntoleranceDto, PatientAllergyIntoleranceStore, ResponseType> {

    constructor(
        @Di.inject("CareReferenceDataStore") private careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("ValidationProblemParameterMapperService") validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    protected storeEntityIdType = PatientAllergyIntoleranceId;

    protected entityDtoSelector = (response: ResponseType) => {
        return response.patientAllergyIntoleranceDto;
    };

    protected applyToStoreCore(target: PatientAllergyIntoleranceStore, response: any) {
        const dto = this.entityDtoSelector(response);
        if (dto != null) {
            this.setItem(target, dto);
        }
    }

    public setItem(target: PatientAllergyIntoleranceStore, dto: Proxy.PatientAllergyIntoleranceDto) {
        target.id = dto.id;
        target.assignment = this.getAssignment(dto);

        target.historyItems = State.observable(this.getHistoryItems(dto.historyItems));
        target.currentItemIndex = target.historyItems.length - 1;
        target.patientId = dto.patientId;
        target.extensionData = dto.extensionData;
    }

    private getHistoryItems(dtoArray: Proxy.PatientAllergyIntoleranceHistoryItemDto[]): PatientAllergyIntoleranceHistoryItem[] {
        return dtoArray.map(dto => this.getHistoryItem(dto)).sort((a, b) => a.versionNumber - b.versionNumber);
    }

    private getHistoryItem(dto: Proxy.PatientAllergyIntoleranceHistoryItemDto): PatientAllergyIntoleranceHistoryItem {
        const returnItem = new PatientAllergyIntoleranceHistoryItem();

        returnItem.historyItemDetail = this.getHistoryItemDetail(dto.historyItemDetail);
        returnItem.versionNumber = dto.versionNumber;
        returnItem.index = dto.index;
        returnItem.createdAt = dto.createdAt;
        returnItem.extensionData = dto.extensionData;

        returnItem.clinicalStatus = dto.clinicalStatus;
        returnItem.criticality = dto.criticality;
        returnItem.lastOccurrence = dto.lastOccurrence;
        returnItem.reactions = State.observable(dto.allergyIntoleranceReactionAssignments.map(x => new AllergyIntoleranceReactionAssignment(new EntityVersionSelector<AllergyIntoleranceReactionId>(x.allergyIntoleranceReactionId.entityId, x.allergyIntoleranceReactionId.validOn), x.description)));

        return returnItem;
    }

    private getHistoryItemDetail(dto: Proxy.MedicalConditionHistoryItemDetailDto): MedicalConditionHistoryItemDetail {
        const returnItem = new MedicalConditionHistoryItemDetail();

        returnItem.asserter = dto.asserter;
        returnItem.asserterType = dto.asserterType;
        returnItem.notes = dto.notes;
        returnItem.verifier = dto.verifier;

        return returnItem;
    }

    private getAssignment(dto: Proxy.PatientAllergyIntoleranceDto) {
        let assignment;

        if (dto.allergyIntoleranceAssignment instanceof Proxy.ReferencedAllergyIntoleranceAssignmentDto) {
            assignment = new ReferencedAllergyIntoleranceAssignment();
            assignment.setAllergyIntolerance(dto.allergyIntoleranceAssignment.allergyIntoleranceVersionSelector.entityId);
        } else if (dto.allergyIntoleranceAssignment instanceof Proxy.MedicationReferenceAllergyIntoleranceAssignmentDto) {
            assignment = new MedicationAllergyIntoleranceAssignment();
            assignment.setMedication(dto.allergyIntoleranceAssignment.medicationVersionSelector.entityId);
        } else if (dto.allergyIntoleranceAssignment instanceof Proxy.SubstanceReferenceAllergyIntoleranceAssignmentDto) {
            assignment = new SubstanceAllergyIntoleranceAssignment();
            assignment.setSubstance(dto.allergyIntoleranceAssignment.substanceVersionSelector.entityId);
        } else if (dto.allergyIntoleranceAssignment instanceof Proxy.InternationalNonproprietaryNameReferencedAllergyIntoleranceAssignmentDto) {
            assignment = new InternationalNonproprietaryNameAllergyIntoleranceAssignment();
            assignment.setId(dto.allergyIntoleranceAssignment.internationalNonproprietaryNameId);
        } else if (dto.allergyIntoleranceAssignment instanceof Proxy.CustomAllergyIntoleranceAssignmentDto) {
            assignment = new CustomAllergyIntoleranceAssignment();
            assignment.name = dto.allergyIntoleranceAssignment.name;
            assignment.description = dto.allergyIntoleranceAssignment.description;
            assignment.type = dto.allergyIntoleranceAssignment.type;
            assignment.categoryId = dto.allergyIntoleranceAssignment.categoryId;
        }

        return assignment;
    }

    public async resolveAllergyIntolerancesAsync(target: PatientAllergyIntoleranceListStore) {
        const referencedAllergyIntoleranceAssignments = target.patientAllergyIntolerances
            .filter(a => a.assignment instanceof ReferencedAllergyIntoleranceAssignment);
        const medicationAllergyIntoleranceAssignments = target.patientAllergyIntolerances
            .filter(a => a.assignment instanceof MedicationAllergyIntoleranceAssignment);
        const substanceAllergyIntoleranceAssignments = target.patientAllergyIntolerances
            .filter(a => a.assignment instanceof SubstanceAllergyIntoleranceAssignment);
        const innAllergyIntoleranceAssignments = target.patientAllergyIntolerances
            .filter(a => a.assignment instanceof InternationalNonproprietaryNameAllergyIntoleranceAssignment);

        await this.careReferenceDataStore.allergyIntolerances.ensureLoadedAsync(
            referencedAllergyIntoleranceAssignments.map(a => (a.assignment as ReferencedAllergyIntoleranceAssignment).allergyIntoleranceVersionSelector)
        );
        await this.careReferenceDataStore.medications.ensureLoadedAsync(
            medicationAllergyIntoleranceAssignments.map(a => (a.assignment as MedicationAllergyIntoleranceAssignment).medicationVersionSelector)
        );
        await this.careReferenceDataStore.substances.ensureLoadedAsync(
            substanceAllergyIntoleranceAssignments.map(a => (a.assignment as SubstanceAllergyIntoleranceAssignment).substanceVersionSelector)
        );
        await this.careReferenceDataStore.internationalNonproprietaryNames.ensureLoadedAsync(
            innAllergyIntoleranceAssignments.map(a => (a.assignment as InternationalNonproprietaryNameAllergyIntoleranceAssignment).id)
        );
        State.runInAction(() => {
            referencedAllergyIntoleranceAssignments.forEach(a => {
                const allergyIntolerance = (a.assignment as ReferencedAllergyIntoleranceAssignment);
                allergyIntolerance.allergyIntoleranceVersion = this.careReferenceDataStore.allergyIntolerances.get(
                    allergyIntolerance.allergyIntoleranceVersionSelector
                );
            });
            medicationAllergyIntoleranceAssignments.forEach(a => {
                const medicationAllergyIntolerance = (a.assignment as MedicationAllergyIntoleranceAssignment);
                medicationAllergyIntolerance.medicationVersion = this.careReferenceDataStore.medications.get(
                    medicationAllergyIntolerance.medicationVersionSelector
                );
            });
            substanceAllergyIntoleranceAssignments.forEach(a => {
                const substanceAllergyIntolerance = (a.assignment as SubstanceAllergyIntoleranceAssignment);
                substanceAllergyIntolerance.substanceVersion = this.careReferenceDataStore.substances.get(
                    substanceAllergyIntolerance.substanceVersionSelector
                );
            });
            innAllergyIntoleranceAssignments.forEach(a => {
                const innAllergyIntolerance = (a.assignment as InternationalNonproprietaryNameAllergyIntoleranceAssignment);
                innAllergyIntolerance.internationalNonproprietaryName = this.careReferenceDataStore.internationalNonproprietaryNames.get(
                    innAllergyIntolerance.id
                );
            });
        });
    }
} 
