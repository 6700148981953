import React from "react";
import Styles from "./Header.less";
import HeaderGroup from "@CommonControls/Header/HeaderGroup";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IHeaderProps {
    children?: React.ReactNode;
    text?: string;
    headingType?: 1 | 2 | 3 | 4 | 5 | 6;
    alternateColor?: boolean;
}

const getClassNames = (props: IHeaderProps) => {
    if (props.alternateColor) {
        return combineClasses(Styles.title, Styles.alternateColor);
    }
    return Styles.title;
};


const Separator = () => {
    return <span className={Styles.separator}>|</span>;
};

function Header(props: IHeaderProps): React.ReactElement {
    const displayValue = isNullOrUndefined(props.children) ? props.text : props.children;

    if (!isNullOrUndefined(props.headingType)) {
        return React.createElement(`h${props.headingType}`, {
            className: getClassNames(props)
        }, displayValue);
    } else {
        return <span className={getClassNames(props)}>{displayValue}</span>;
    }
}

Header.Group = HeaderGroup;
Header.Separator = Separator;

export default Header;