import React from "react";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import Styles from "./EHRWidget.less";
import ConditionId from "@Primitives/ConditionId.g";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";

interface IPatientCareActivityTooltipProps {
    item: PatientsCareActivityStore;
    careReferenceStore: CareReferenceDataStore;
    organizationReferenceStore: OrganizationReferenceDataStore;
}

const renderConditionText = (condition: IEntityVersionSelector<ConditionId>, careReferenceStore: CareReferenceDataStore, key?: number) => {
    const item = careReferenceStore.condition.get(condition);
    return (
        <span key={key}>
            {item.code} {item.name}
            <br/>
        </span>
    );
};


const PatientCareActivityTooltip: React.FC<IPatientCareActivityTooltipProps> = props => {
    const main = props.item.diagnoses.find(item => item.use.value === DiagnosisRoleId.Discharge.value);
    const preliminaries = props.item.diagnoses.filter(item => item.use.value === DiagnosisRoleId.Admission.value);
    const others = props.item.diagnoses.filter(item => item.use.value === DiagnosisRoleId.Comorbity.value);

    return (
        <div className={Styles.tooltipContainer}>
            <p>
                {StaticProductivityResources.Widgets.EHRWidget.Tooltip.PointOfCare}:
                <strong> {props.organizationReferenceStore.allPointsOfCareMap.get(props.item.organizationUnitId).name}</strong>
            </p>
            <p>
                {StaticProductivityResources.Widgets.EHRWidget.Tooltip.MainDiagnosis}:
                <strong> {main && renderConditionText(main.conditionVersionSelector, props.careReferenceStore)}</strong>
            </p>
            <p>
                {StaticProductivityResources.Widgets.EHRWidget.Tooltip.PreliminaryDiagnoses}:
                <strong> {preliminaries.map((item, key) => renderConditionText(item.conditionVersionSelector, props.careReferenceStore, key))}</strong>
            </p>
            <p>
                {StaticProductivityResources.Widgets.EHRWidget.Tooltip.OtherDiagnoses}:
                <strong> {others.map((item, key) => renderConditionText(item.conditionVersionSelector, props.careReferenceStore, key))}</strong>
            </p>
        </div>
    );
};

export default PatientCareActivityTooltip;
