import { IRowBody, RowId } from "@CommonControls/DataGrid/IDataGridProps";
import Di from "@Di";
import AccessControlModalParams from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/AccessControlModal/AccessControlModalParams";
import InputDataClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/InputDataClientSideAction";
import IWorklistItemDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistItemDefinition";
import AppointmentCancellationReasonArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/AppointmentCancellationReasonArgument";
import StatusChangeReasonArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/StatusChangeReasonArgument";
import StatusChangeReasonDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/StatusChangeReasonDialog/StatusChangeReasonDialogParams";
import MostRelevantServicesBody from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/CareActivityWorklist/RowBody/MostRelevantServicesBody";
import RequestedServicesBody from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/CareActivityWorklist/RowBody/RequestedServicesBody";
import InputFormType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/InputFormType.g";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import INDataRow from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataRow";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import AppointmentCancellationDialogParams, { IAppointmentCancellationDialogResult } from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/AppointmentCancellationDialog/AppointmentCancellationDialogParams";
import { getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import { ScreenNavigationContextStore } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import IShowScreenActionCallContextParams from "@HisPlatform/Services/Definition/ActionProcessing/IShowScreenActionCallContextParams";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import StatusChangeReasonId from "@Primitives/StatusChangeReasonId.g";
import StatusChangeReasonTypeId from "@Primitives/StatusChangeReasonTypeId.g";
import WorkflowId from "@Primitives/WorkflowId.g";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import _ from "@HisPlatform/Common/Lodash";
import React from "react";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import * as Ui from "@CommonControls";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import { ICareActivityWorklistCommonService } from "@HisPlatform/Services/Definition/CareActivityWorklistCommonService/ICareActivityWorklistCommonService";
import { CantCloseCareActivityDialogNavigateTo } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CantCloseCareActivityPanel/CantCloseCareActivityDialogParams";
import CareActivityId from "@Primitives/CareActivityId.g";
import OutpatientTreatmentRoutes from "@HisPlatform/Components/Pages/OutpatientTreatment/OutpatientTreatmentRoutes";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowDischargePatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowDischargePatientScreenAction.g";
import ShowPerformedServicesScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowPerformedServicesScreenAction.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";

@Di.injectable()
export default class CareActivityWorklistCommonService implements ICareActivityWorklistCommonService {

    constructor(
        @Di.inject("IUseCaseRegistry") private useCaseRegistry: IUseCaseRegistry,
        @Di.inject("SchedulingApiAdapter") private schedulingApiAdapter: SchedulingApiAdapter
    ) {

    }

    @State.bound
    public setUseCase(
        rowId: RowId,
        useCaseState: INDataUseCaseState,
        routingController: IRoutingController<any> | GlobalRoutingStore,
        actionDispatcher: IActionDispatcher,
        screenNavigationContext: ScreenNavigationContextStore,
        modalService: IModalService) {

        if (useCaseState) {
            const converter = this.useCaseRegistry.tryGetScreenAdapter(useCaseState.useCase);

            if (converter) {
                const action = converter(useCaseState.useCase, useCaseState.useCaseArguments);
                dispatchAsyncErrors(actionDispatcher!.dispatchAsync<IShowScreenActionCallContextParams>(
                    action.action,
                    { navigationContext: screenNavigationContext, modalService: modalService }
                ), null);
                return;
            }
        }

        if (!useCaseState) {
            routingController.push(routingController.currentRoute.definition.makeRoute({
                ...routingController.currentRoute.parameters,
                useCase: null,
                selectedRowId: null
            }));
            return;
        }

        if ([UseCaseDisplayMode.MasterDetail, UseCaseDisplayMode.Inline, UseCaseDisplayMode.Modal].includes(useCaseState?.displayMode)) {
            // new usecase
            routingController.push(routingController.currentRoute.definition.makeRoute({
                ...routingController.currentRoute.parameters,
                useCase: getUseCaseAsUrlParam(useCaseState.useCase, useCaseState.displayMode, useCaseState.useCaseArguments),
                selectedRowId: `${rowId ?? "null"}`
            }));
            return;
        }

        // old usecase

        const useCaseDescriptor = this.useCaseRegistry.get(useCaseState.useCase.value);

        if (!useCaseDescriptor) {
            throw new Error(`Cannot find use case: ${useCaseState.useCase.value}`);
        }

        const workflowId = useCaseState.useCaseArguments.find(a => a.value instanceof WorkflowId);
        const routeFactory = useCaseDescriptor.standaloneRouteFactory;

        const route = routeFactory({
            useCase: useCaseState.useCase,
            useCaseArguments: useCaseState.useCaseArguments,
            displayMode: useCaseState.displayMode,
            currentRoute: routingController.currentRoute
        });
        routingController.push(route, route.queryString);
    }

    @State.bound
    public renderRowBody(row: INDataRow, rowId: RowId, rowIndex: number, rowBodyItemDefinition: IWorklistItemDefinition): IRowBody {

        if (!!rowBodyItemDefinition) {
            const rawValue = _.get(row, rowBodyItemDefinition.attributeName);

            if (!!rawValue && rowBodyItemDefinition.attributeType === "RequestedServiceDto[]") {
                return {
                    showCells: true,
                    content: <RequestedServicesBody rawJsonValue={rawValue} />
                };
            }
            if (!!rawValue && rowBodyItemDefinition.attributeType === "CareRequestedAndMedicalServiceDto[]") {
                return {
                    showCells: true,
                    content: <MostRelevantServicesBody rawJsonValue={rawValue} />
                };
            }
        }
        return null;
    }

    @State.bound
    public cantCloseCareActivityNavigateByActions(
        careActivityId: CareActivityId,
        navigateTo: CantCloseCareActivityDialogNavigateTo,
        routingController: IRoutingController<any> | GlobalRoutingStore,
        actionDispatcher: IActionDispatcher,
        screenNavigationContext: ScreenNavigationContextStore,
        modalService: IModalService) {
        switch (navigateTo) {
            case CantCloseCareActivityDialogNavigateTo.CareDocuments:
                routingController.push(OutpatientTreatmentRoutes.documentList.makeRoute({
                    careActivityId: careActivityId.value,
                    selectedDocumentId: null
                }));
                break;
            case CantCloseCareActivityDialogNavigateTo.TextBlocks:
                routingController.push(OutpatientTreatmentRoutes.textBlocks.makeRoute({ careActivityId: careActivityId.value, textBlockTypeId: null }));
                break;
            case CantCloseCareActivityDialogNavigateTo.ClinicalData:
                routingController.push(OutpatientTreatmentRoutes.baseData.makeRoute({ careActivityId: careActivityId.value }));
                break;
            case CantCloseCareActivityDialogNavigateTo.DischargeData:
                actionDispatcher.dispatchAsync(
                    new ShowDischargePatientScreenAction(ScreenDisplayMode.Full, careActivityId),
                    { navigationContext: screenNavigationContext, modalService: modalService });
                break;
            case CantCloseCareActivityDialogNavigateTo.PerformedServices:
                actionDispatcher.dispatchAsync(
                    new ShowPerformedServicesScreenAction(ScreenDisplayMode.Full, careActivityId),
                    { navigationContext: screenNavigationContext, modalService: modalService });
                break;
            case CantCloseCareActivityDialogNavigateTo.Diagnoses:
                routingController.push(OutpatientTreatmentRoutes.diagnosisList.makeRoute({ careActivityId: careActivityId.value }));
                break;
            case CantCloseCareActivityDialogNavigateTo.ServiceRequests:
            case CantCloseCareActivityDialogNavigateTo.ServiceRequestObservations:
                routingController.push(OutpatientTreatmentRoutes.servicesRequests.makeRoute({
                    careActivityId: careActivityId.value,
                    filter: "CareActivity",
                    mode: "edit"
                }));
                break;
            default:
                throw new Error("CareActivityStatusChangeReason value not supported.");
        }
    }

    @State.bound
    private async processInputDataClientSideActionAsync(clientSideAction: InputDataClientSideAction, modalService: IModalService) {
        if (clientSideAction.inputFormType === InputFormType.GetStatusChangeReason) {
            const reasonType = clientSideAction.useCaseArguments.find(a => a.value instanceof StatusChangeReasonTypeId);
            if (isNullOrUndefined(reasonType)) {
                throw new Error(`ConfirmCareActivityDelete requires argument of type StatusChangeReasonTypeId`);
            }

            const dialogResult =
                await modalService.showDialogAsync<{ statusChangeReasonId: StatusChangeReasonId, additionalText: string }>(new StatusChangeReasonDialogParams(
                    reasonType.value,
                    null,
                    <Ui.InfoBox iconName="info_with_circle" iconVisualStyle="primary" />
                ));

            if (dialogResult && dialogResult.statusChangeReasonId) {
                return new StatusChangeReasonArgument(dialogResult.statusChangeReasonId, dialogResult.additionalText);
            } else {
                return null;
            }
        } else if (clientSideAction.inputFormType === InputFormType.EditCareActivitySecurity) {
            const careActivityId = clientSideAction.useCaseArguments[0].value.value;
            modalService.showDialogAsync(new AccessControlModalParams("CareActivity", careActivityId));
            return null;
        } else if (clientSideAction.inputFormType === InputFormType.AppointmentCancellation) {
            const appointmentScheduleEntryId = clientSideAction.useCaseArguments[0].value.value;

            await this.schedulingApiAdapter.cancelAppointmentPermissionCheckAsync();
            const dialogResult = await modalService.showDialogAsync<IAppointmentCancellationDialogResult>(new AppointmentCancellationDialogParams(new AppointmentScheduleEntryId(appointmentScheduleEntryId), false));

            if (dialogResult && dialogResult.appointmentDeleted) {
                return new AppointmentCancellationReasonArgument(dialogResult.dialogData.appointmentCancellationReasonId, dialogResult.dialogData.additionalText);
            } else {
                return null;
            }
        } else {
            throw new Error(`Cannot process inputFormType ${clientSideAction.inputFormType}`);
        }
    }

    @State.bound
    public async performClientSideActionAsync(clientSideAction: ClientSideActionDto, modalService: IModalService) {
        if (clientSideAction instanceof InputDataClientSideAction) {
            return await this.processInputDataClientSideActionAsync(clientSideAction, modalService);
        } else {
            throw new Error(`Cannot process client side action of type ${clientSideAction.constructor.name}`);
        }
    }
}