exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NoItemsMessage_message-text_1iOzv {\n  margin: 8px 0;\n  line-height: 1.3;\n}\n", ""]);

// exports
exports.locals = {
	"message-text": "NoItemsMessage_message-text_1iOzv",
	"messageText": "NoItemsMessage_message-text_1iOzv"
};