import React, { useState, useCallback } from "react";
import * as Ui from "@CommonControls";
import { iconVisualStyleType, iconNameType } from "@CommonControls/Icon";
import Styles from "./InfoButton.less";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";
import TextCard from "@CommonControls/InfoButton/TextCard";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";

export interface IInfoButtonProps {
    visualStyle?: iconVisualStyleType;
    iconName?: iconNameType;
    tooltipContent?: React.ReactNode;
    moreLabel?: React.ReactNode;
    tooltipOnClick?: () => void;
    tooltipMaxWidth?: string;
    position?: "superscript" | "baseline";
}


const InfoButton: React.FC<IInfoButtonProps> = props => {

    const [isInitialized, initialize] = useState(false);

    const enter = useCallback(() => {
        initialize(true);
    }, [initialize]);

    const icon = (
        <Ui.Icon
            iconName={props.iconName || "infoCircle"}
            size="normal"
            visualStyle={props.visualStyle}
        />
    );

    const classes = combineClasses(
        props.position === "superscript" && Styles.sup,
        props.visualStyle && Styles.container
    );

    if (!isInitialized) {
        return props.position === "superscript" ?
            <sup className={classes} onMouseEnter={enter}>{icon}</sup> :
            <span className={classes} onMouseEnter={enter}>{icon}</span>;
    }

    return (
        <Popper
            className={classes}
            tooltipClassName={Styles.infoBoxTooltip}
            tooltipStyle={{ maxWidth: props.tooltipMaxWidth }}
            tooltipPlacement="bottom-start"
            wrapperElementType={props.position === "superscript" ? "sup" : "span"}
            isOpen
            tooltipContent={(
                <TextCard
                    text={props.tooltipContent}
                    moreLabel={props.moreLabel}
                    onClick={props.tooltipOnClick}
                />
            )}
            arrowStyle={{ display: "none" }}
        >
            {icon}
        </Popper>
    );
};

InfoButton.defaultProps = {
    tooltipMaxWidth: "300px",
    position: "superscript",
    visualStyle: "secondary"
};

export default InfoButton;
