import moment from "moment";

export default class MomentRange {
    public from: moment.Moment;
    public to: moment.Moment;

    public constructor(from?: moment.Moment, to?: moment.Moment) {
        this.from = from;
        this.to = to;
    }
}