import StoreMapper from "@Toolkit/CommonWeb/ApiAdapter/StoreMapper";
import DesignatedOrganization from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/DesignatedOrganization";
import { DesignatedOrganizationVersionDto } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import Di from "@Di";

@Di.injectable()
export default class DesignatedOrganizationStoreMapper extends StoreMapper<DesignatedOrganization, DesignatedOrganizationVersionDto> {
    protected applyToStoreCore(target: DesignatedOrganization, response: DesignatedOrganizationVersionDto): void {
        target.id = response.id;
        target.instituteCode = response.instituteCode;
        target.instituteName = response.instituteName;
        target.workplaceCode = response.workplaceCode;
        target.validity = response.validity;
    }

}