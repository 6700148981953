import React from "react";
import * as Recharts from "recharts";
import { IChartProps } from "@CommonControls/Chart/IChartProps";

interface IPieChartProps extends IChartProps {
    colors?: string[];
    width: number;
    height: number;
    cx: number;
    cy: number;
    dataKey: string;
    nameKey?: string;
    renderLabel?: boolean;
    onRenderLabel?: (props: any) => string;
}

const radian = Math.PI / 180;

const defaultLabelRenderer = (props: any) => {
    const {
        percent
    } = props;

    return `${(percent * 100).toFixed(0)}%`;
};

const customizedLabelRendererFactory = (labelRenderer: (props: any) => string) => (props: any) => {

    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
        value
    } = props;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * radian);
    const y = cy + radius * Math.sin(-midAngle * radian);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
            {labelRenderer(props)}
        </text>
    );
};


const PieChart: React.FC<IPieChartProps> = (props) => {
    return (
        <Recharts.PieChart width={props.width} height={props.height}>
            {props.isTooltipEnabled && <Recharts.Tooltip />}
            {props.isLegendEnabled && <Recharts.Legend />}
            <Recharts.Pie
                data={props.data}
                nameKey={props.nameKey}
                cx={props.cx}
                cy={props.cy}
                labelLine={false}
                label={props.renderLabel ? customizedLabelRendererFactory(props.onRenderLabel) : undefined}
                outerRadius={80}
                fill="#8884d8"
                dataKey={props.dataKey}
            >
                {props.data.map((entry, index) => <Recharts.Cell key={`cell-${index}`} fill={props.colors[index % props.colors.length]} />)}
            </Recharts.Pie>
        </Recharts.PieChart>
    );
};

PieChart.defaultProps = {
    colors: ["#777777"],
    onRenderLabel: defaultLabelRenderer
};

export default PieChart;