import AlertCard from "@CommonControls/AlertCard";
import AlertCardContainer from "@CommonControls/AlertCardContainer";
import Dashboard from "@CommonControls/Dashboard";
import Table from "@CommonControls/Table";
import PatientAllergyIntoleranceHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceHistoryItem";
import PatientAllergyIntoleranceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceStore";
import PatientRegisterReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/PatientRegisterReferenceDataStore";
import { usePatientContext } from "@HisPlatform/Model/DomainModel/PatientContext/PatientContext";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import AllergyIntoleranceCriticality from "@Primitives/AllergyIntoleranceCriticality";
import GenderId from "@Primitives/GenderId.g";
import PatientAllergyIntoleranceId from "@Primitives/PatientAllergyIntoleranceId.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { emptyObject, nullFunction } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import ExtensionPoint from "@Toolkit/ReactClient/Extensibility/ExtensionPoint/ExtensionPoint";
import React, { useCallback, useMemo } from "react";
import _ from "@HisPlatform/Common/Lodash";
import Styles from "./PatientWidget.less";
import Avatar from "@CommonControls/Avatar";
import PersonNameLabel from "@CommonControls/PersonNameLabel";
import Monogram from "@CommonControls/Monogram/Monogram";
import PatientTooltip from "@HisPlatform/Components/Widgets/PatientWidget/PatientTooltip";
import { useHisActionDispatcher } from "@HisPlatform/Common/FrontendActions/HisActionDispatcher";
import ShowPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientScreenAction.g";
import { useErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import Icon from "@CommonControls/Icon";

interface IPatientWidgetProps {
}

function PatientWidget(props: IPatientWidgetProps) {
    const patientContext = usePatientContext();
    const { localizationService } = useDependencies(c => ({
        localizationService: c.resolve<ILocalizationService>("ILocalizationService")
    }));

    const patientBirthDate = useMemo(() => patientContext?.patient?.baseData?.ageInfo?.birthDate && localizationService.localizeDate(patientContext.patient.baseData.ageInfo.birthDate), [patientContext?.patient?.baseData?.ageInfo?.birthDate]);
    const patientGenderCode = useMemo(() => patientContext?.patient?.baseData?.genderId && localizationService.localizeEnumId(patientContext.patient.baseData.genderId).Shorthand, [patientContext?.patient?.baseData?.genderId]);

    const actionDispatcher = useHisActionDispatcher();
    const dispatchError = useErrorDispatcher();
    const editPatient = useCallback(() => {
        actionDispatcher.dispatchAsync(new ShowPatientScreenAction(ScreenDisplayMode.Full, patientContext!.patientId, null)).catch(dispatchError);
    }, [actionDispatcher, patientContext?.patientId?.value]);

    if (!patientContext?.patient?.baseData) {
        return null;
    }

    return (
        <PatientTooltip
            patient={patientContext!.patient}
            patientAllergies={patientContext!.patientAllergyIntoleranceList.patientAllergyIntolerances}
        >
            <Dashboard.Widget
                name="PatientWidget"
                colorStyle="light"
                isCloseByDefault={false}
                icon={patientContext.patient.baseData.genderId && (
                    <Monogram
                        gender={patientContext.patient.baseData.genderId.value === GenderId.Male.value ? "male" : "female"}
                        value={patientContext.patient.baseData.name}
                        size="small"
                        className={Styles.monogram}
                    />
                )}
                isCollapsible={false}
                title={localizationService.localizePersonName(patientContext.patient.baseData.name)}
                toolbar={<Icon iconName="expand" className={Styles.expandIcon} onClick={editPatient} visualStyle="white" style={{ marginRight: 10 }} />}
                automationId="PatientWidget"
                iconStyle={{ marginRight: 6 }}
                smallContentStyle={{ marginBottom: 6 }}
                smallContent={patientContext.patient.baseData.genderId && (
                    <Monogram
                        gender={patientContext.patient.baseData.genderId.value === GenderId.Male.value ? "male" : "female"}
                        value={patientContext.patient.baseData.name}
                    />
                )}
            >
                <div className={Styles.patientInfoContainer}>
                    {patientContext.patient.baseData.genderId && (
                        <div className={Styles.patientAvatar}>
                            <Avatar gender={patientContext.patient.baseData.genderId.value === GenderId.Male.value ? "male" : "female"} />
                        </div>
                    )}

                    <div className={Styles.patientInfo}>
                        <ExtensionPoint type="patientWidgetContent" extensionProps={emptyObject}>
                            <div style={{ margin: "0 6px" }}>
                                <section>{StaticWebAppResources.OutpatientWorkflowPage.Label.BirthName}:&nbsp;<b><PersonNameLabel personName={patientContext.patient.baseData.hasBirthName ? patientContext.patient.baseData.birthName : patientContext.patient.baseData.name} /></b></section>
                                <section>{StaticWebAppResources.OutpatientWorkflow.OutpatientHeader.BirthDate}:&nbsp;<b>{patientBirthDate ?? "n/a"} ({
                                    patientContext.patient.baseData.ageInfo.estimatedAgeInYears !== null
                                        ? patientContext.patient.baseData.ageInfo.estimatedAgeInYears
                                        : patientContext.patient.age.case({ fulfilled: (value) => (value) })
                                }/{patientGenderCode})</b></section>
                            </div>
                        </ExtensionPoint>
                    </div>
                </div>

                <div className={Styles.allergyList}>
                    {orderByAllergyCriticality(patientContext.patientAllergyIntoleranceList.patientAllergyIntolerances).map(a => <PatientAllergyIntoleranceAlertCard store={a} key={a.id.value} />)}
                </div>

            </Dashboard.Widget>
        </PatientTooltip>
    );

}

const alleryCriticalityOrder = [
    AllergyIntoleranceCriticality.High,
    AllergyIntoleranceCriticality.UnableToAssess,
    AllergyIntoleranceCriticality.Low
];
function orderByAllergyCriticality(allergies: PatientAllergyIntoleranceStore[]) {
    return _.orderBy(allergies, i => alleryCriticalityOrder.indexOf((i.latestHistoryItem as PatientAllergyIntoleranceHistoryItem).criticality));
}

function PatientAllergyIntoleranceAlertCard(props: { store: PatientAllergyIntoleranceStore, onOpenAllergy?: (id: PatientAllergyIntoleranceId) => void }) {
    const currentItem = (props.store.latestHistoryItem as PatientAllergyIntoleranceHistoryItem);

    let lowerText = currentItem.reactions[0].description;

    lowerText = _.truncate(lowerText, { length: 17 });

    if (currentItem.reactions.length > 1) {
        lowerText += " (" + currentItem.reactions.length + ")";
    }

    let toolTip = currentItem.reactions.map(x => x.description).join("\n");
    if (!!currentItem.historyItemDetail.notes) {
        toolTip += "\n\n" + currentItem.historyItemDetail.notes;
    }

    const currentVisualStyle = currentItem.criticality === AllergyIntoleranceCriticality.Low ? "warning" : "error";

    const click = useCallback(() => {
        props.onOpenAllergy?.(props.store.id);
    }, [props.store.id]);

    return (
        <AlertCard
            label=""
            innerLabel={{ lowerText: lowerText, upperText: props.store.label }}
            visualStyle={currentVisualStyle}
            background="white"
            key={props.store.id.value}
            onClick={click}
            tooltipContent={toolTip}
            tooltipTextAlign={"left"}
            tooltipPosition="bottom" />
    );
}

export default State.observer(PatientWidget);