import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientId from "@Primitives/PatientId.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { parseUseCaseStateFromUrlParam, getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import IPatientBloodTypeRouteParams from "./IPatientBloodTypeRouteParams";
import PatientBloodTypeId from "@Primitives/PatientBloodTypeId.g";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import PatientBloodTypeListPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/MedicalCondition/PatientBloodType/PatientBloodTypeListPanel";
import HunPatientRoutes from "@HunEHealthInfrastructurePlugin/UseCases/PatientMainPageUseCase/HunPatientRoutes";

interface IPatientBloodTypePageProps extends IRoutingFrameContentProps {
}

@State.observer
class PatientBloodTypePage extends React.Component<IPatientBloodTypePageProps> {

    @State.computed private get routeParams(): IPatientBloodTypeRouteParams {
        return this.props.routingController.currentRoute.parameters as IPatientBloodTypeRouteParams;
    }

    @State.computed private get selectedPatientBloodTypeId(): PatientBloodTypeId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments[0].value;
        }

        if (this.useCaseState?.useCase.value === HunEHealthInfrastructureUseCases.patientBloodTypeDetail) {
            return new PatientBloodTypeId("new");
        }

        return null;
    }

    @State.computed
    private get patientId() {
        return new PatientId(this.routeParams?.patientId);
    }

    @State.computed private get useCaseState() {
        const useCaseState = this.routeParams?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.bound
    private setUseCaseState(selectedPatientBloodTypeId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.replace(
            HunPatientRoutes.patientBloodType.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            })
        );
    }

    @State.bound
    private navigateToPatientBloodType(patientBloodTypeId: PatientBloodTypeId) {
        this.props.routingController.replace(
            HunPatientRoutes.patientBloodType.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(HunEHealthInfrastructureUseCases.patientBloodTypeDetail),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(patientBloodTypeId, "patientBloodTypeId")])
            })
        );
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(
            HunPatientRoutes.patientBloodType.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    null,
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument(new PatientBloodTypeId("null"), "patientBloodTypeId")])
            })
        );
    }

    public render() {
        return (
            <PatientBloodTypeListPanel
                _patientId={this.patientId}
                onChange={this.setUseCaseState}
                useCaseState={this.useCaseState}
                selectedRowId={this.selectedPatientBloodTypeId?.value}
                onSelectedPatientBloodTypeIdChange={this.navigateToPatientBloodType}
                onBack={this.goBack}
                onCloseDetail={this.setClosedState}
            />
        );
    }
}

export default connect(
    PatientBloodTypePage
);
