import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ISchedulingResources from "./ISchedulingResources";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";

const resources = createStaticResourceDictionary<ISchedulingResources>();

export function loadStaticSchedulingResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    resources.load(localizationService.getResourceGroup("WebApp").Scheduling);
}

export default resources;