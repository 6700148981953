import React, { useCallback } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import EditableLayoutColumnStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableLayoutColumnStore";
import { GroupBox, SelectBox, Button } from "@CommonControls";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";

const sizeOptions: ISelectBoxItem[] = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },
    { text: "5", value: 5 },
    { text: "6", value: 6 },
    { text: "7", value: 7 },
    { text: "8", value: 8 },
    { text: "9", value: 9 },
    { text: "10", value: 10 },
    { text: "11", value: 11 },
    { text: "12", value: 12 },
];

function ColumnPropertyPanel() {

    const store = useFormLayoutEditorStore();

    const setSize = useCallback((value: number) => {

        if (!(store.selectedColumn instanceof EditableLayoutColumnStore)) {
            return;
        }

        store.selectedColumn.setSize({
            xs: value as any
        });

    }, [store.selectedColumn]);

    if (!store.selectedColumn || !(store.selectedColumn instanceof EditableLayoutColumnStore)) {
        return null;
    }

    return (
        <GroupBox title={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Column.Title} style={{ margin: 4 }}>
            <SelectBox label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Column.XsWidthSelectorLabel} value={store.selectedColumn.size.xs} items={sizeOptions} onChange={setSize} automationId="RowColumnSizeXs_SelectBox" />
            <Button visualStyle="negative" text={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Column.DeleteButtonLabel} automationId="DeleteRow_Button" onClick={store.deleteSelectedColumn} />
        </GroupBox>
    );
}

export default State.observer(ColumnPropertyPanel);