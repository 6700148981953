import Dosage from "./Dosage";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class ScheduledDosage extends Dosage {
    @State.observable public morningAmount: number = 0;
    @State.observable public noonAmount: number = 0;
    @State.observable public eveningAmount: number = 0;
    @State.observable public beforeSleepAmount: number = 0;

    @State.action.bound
    public setMorningAmount(morningAmount: number) {
        this.morningAmount = morningAmount;
        this.setDailyAmountBasedOnData();
    }

    @State.action.bound
    public setNoonAmount(noonAmount: number) {
        this.noonAmount = noonAmount;
        this.setDailyAmountBasedOnData();
    }

    @State.action.bound
    public setEveningAmount(eveningAmount: number) {
        this.eveningAmount = eveningAmount;
        this.setDailyAmountBasedOnData();
    }

    @State.action.bound
    public setBeforeSleepAmount(beforeSleepAmount: number) {
        this.beforeSleepAmount = beforeSleepAmount;
        this.setDailyAmountBasedOnData();
    }

    @State.bound
    private setDailyAmountBasedOnData() {
        this.setDailyAmount((this.morningAmount || 0) + (this.noonAmount || 0) + (this.eveningAmount || 0) + (this.beforeSleepAmount || 0));
    }
}