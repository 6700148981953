exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DocumentManagementMasterDetailPanel_header-container_hLzuB {\n  height: 44px;\n  vertical-align: middle;\n}\n.DocumentManagementMasterDetailPanel_header-container_hLzuB .DocumentManagementMasterDetailPanel_header-button_3RGdi {\n  margin: 10px 4px;\n}\n", ""]);

// exports
exports.locals = {
	"header-container": "DocumentManagementMasterDetailPanel_header-container_hLzuB",
	"headerContainer": "DocumentManagementMasterDetailPanel_header-container_hLzuB",
	"header-button": "DocumentManagementMasterDetailPanel_header-button_3RGdi",
	"headerButton": "DocumentManagementMasterDetailPanel_header-button_3RGdi"
};