import React from "react";
import AppointmentScheduleDefinitionId from "@Primitives/AppointmentScheduleDefinitionId.g";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import AppointmentScheduleDefinition from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentScheduleDefinition";
import SchedulingConfigurationApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingConfigurationApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import RequestStatus from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/RequestStatus";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import AppointmentScheduleDefinitionBaseDataPanelView from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/Detail/AppointmentScheduleDefinitionBaseDataPanelView";
import AppointmentScheduleDefinitionPlanningPeriodToolBarPanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/Detail/AppointmentScheduleDefinitionPlanningPeriodToolBarPanel";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import SchedulingServiceEditorDialogParams, { ISchedulingServiceEditorDialogResult } from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/SchedulingServiceEditorDialog/SchedulingServiceEditorDialogParams";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import * as HisUi from "@HisPlatformControls";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import AppointmentScheduleServicesGrid from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/AppointmentScheduleServicesGrid/AppointmentScheduleServicesGrid";
import AppointmentScheduleDefinitionTimePhasesPanel from "./AppointmentScheduleDefinitionTimePhasesPanel";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import UseCaseFrameTab from "@HisPlatform/Components/UseCaseFrame/UseCaseFrameTab";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import _ from "@HisPlatform/Common/Lodash";
import ExtensionController from "@HisPlatform/Components/HisPlatformExtensionPoint/ExtensionController";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import CannotDeleteAppointmentScheduleDefinitionError from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/CannotDeleteAppointmentScheduleDefinitionError";
import { formatString } from "@Toolkit/CommonWeb/Formatters";

interface IAppointmentScheduleDefinitionDetailPanelDependencies {
    apiAdapter: SchedulingConfigurationApiAdapter;
    notificationService: INotificationService;
    dialogService: IDialogService;
    lockingApiAdapter: LockingApiAdapter;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
}

export interface IAppointmentScheduleDefinitionDetailPanelProps {
    _dependencies?: IAppointmentScheduleDefinitionDetailPanelDependencies;
    _modalService?: IModalService;
    onCloseDetail?: () => void;
    appointmentScheduleDefinitionId: AppointmentScheduleDefinitionId;
    onDeleteAsync: () => Promise<void>;
    onSaveAsync: () => Promise<void>;
}

@State.observer
class AppointmentScheduleDefinitionDetailPanel extends React.Component<IAppointmentScheduleDefinitionDetailPanelProps> {
    @State.observable private store: AppointmentScheduleDefinition;
    @State.observable private title: string;
    @State.observable private schedulingServices: SchedulingServiceSubjectStore[];
    @State.computed private get servicesDataSource() { return new InMemoryDataGridDataSource(() => this.schedulingServices); }
    private extensionController = new ExtensionController();
    @State.observable.ref public validationErrors: IObservableArray<IClientValidationProblem> = State.observable([]);

    public componentDidMount() {
        dispatchAsyncErrors(this.loadDataAsync(false), this);
    }

    public componentDidUpdate(prevProps: IAppointmentScheduleDefinitionDetailPanelProps) {
        if (prevProps.appointmentScheduleDefinitionId &&
            this.props.appointmentScheduleDefinitionId &&
            !wrappedValuesAreEquals(prevProps.appointmentScheduleDefinitionId, this.props.appointmentScheduleDefinitionId)) {
            dispatchAsyncErrors(this.loadDataAsync(false), this);
        }
    }

    private async loadDataAsync(forceReleaseLockNeeded: boolean) {
        if (forceReleaseLockNeeded && !!this.store?.lockInfo?.preventingLockId) {
            await this.props._dependencies.lockingApiAdapter.forceReleaseLockAsync(this.store.lockInfo.preventingLockId);
        }

        const result = await this.props._dependencies.apiAdapter.getAppointmentScheduleDefinitionByIdAsync(this.props.appointmentScheduleDefinitionId);
        let services: SchedulingServiceSubjectStore[] = [];
        if (result.ownedSchedulingServices?.length) {
            const servicesRes = await this.props._dependencies.apiAdapter.loadSchedulingServiceByIdsAsync(result.ownedSchedulingServices);
            services = servicesRes.value;

            services.forEach(service => {
                service.owner = result.id;
            });
        }
        State.runInAction(() => {
            this.store = result;

            if (result.planningPeriods?.length > 0) {
                this.store.selectedPlanningPeriod = _.nth(result.planningPeriods, -1);
            }

            this.store.takeSnapshot();
            this.title = result.name;
            this.schedulingServices = services;
        });
    }

    @State.computed
    private get isDirty() {
        return this.store.isDirty() || this.extensionController.isDirty();
    }

    @State.bound
    public async unloadAsync(): Promise<boolean> {
        if (this.store) {
            if (this.isDirty) {
                const dialogResult =
                    await this.props._dependencies.dialogService.confirmIfNotSaved(StaticCareResources.Common.Dialog.ConfirmationTitle, StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);

                if (dialogResult.resultCode === DialogResultCode.Yes) {
                    return await this.saveAsync(true);
                } else if (dialogResult.resultCode === DialogResultCode.No) {
                    if (this.store.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld) {
                        await this.props._dependencies.lockingApiAdapter.releaseLockAsync(this.store.lockInfo.lockId);
                        this.store.releaseLock();
                    }
                    return true;
                } else {
                    return false;
                }
            } else {
                if (this.store.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld) {
                    await this.props._dependencies.lockingApiAdapter.releaseLockAsync(this.store.lockInfo.lockId);
                    this.store.releaseLock();
                }
                return true;
            }
        }
        return true;
    }

    @State.bound
    private async addServiceAsync() {
        await this.editSchedulingServiceAsync(null);
        await this.props._dependencies.schedulingReferenceDataStore.schedulingServices.reloadAsync();
        await this.validateAsync();
    }

    @State.bound
    private async editSchedulingServiceAsync(service: SchedulingServiceSubjectStore) {
        const result = await this.props._modalService.showDialogAsync<ISchedulingServiceEditorDialogResult>(
            new SchedulingServiceEditorDialogParams(this.store.id, this.store.lockInfo, service?.id)
        );

        if (result?.schedulingService?.value?.store) {
            if (isNullOrUndefined(service)) {
                this.props._dependencies.notificationService.showSaveResult(true);
                State.runInAction(() => {
                    this.schedulingServices.push(result.schedulingService.value?.store);
                });
            } else {
                await this.showInvalidatedAppointmentsCountAsync(result.schedulingService.value?.invalidAppointmentCount);
                State.runInAction(() => {
                    this.schedulingServices = [...this.schedulingServices.filter(s => s !== service), result.schedulingService.value.store];
                });
            }
            await this.reloadRowVersionAsync();
            await this.validateAsync();
        }
    }

    @State.bound
    private async deleteSchedulingServiceAsync(service: SchedulingServiceSubjectStore) {
        const dialogResult = await this.props._dependencies.dialogService.yesNoCancel(
            StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.BaseData.DeleteServiceTitle,
            StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.BaseData.DeleteServiceText);

        if (dialogResult.resultCode === DialogResultCode.Yes) {
            const usageResponse = await this.props._dependencies.apiAdapter.checkSchedulingServiceUsageAsync(service.id);
            if (usageResponse.value) {
                this.props._dependencies.notificationService.error(StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.Message.SchedulingServiceUsedInTimeSlot);
                return;
            }

            const response = await this.props._dependencies.apiAdapter.deleteSchedulingServiceAsync(service, this.store.lockInfo);
            if (response.operationInfo.requestStatus === RequestStatus.Success) {
                this.props._dependencies.notificationService.showSaveResult(true);
                await this.showInvalidatedAppointmentsCountAsync(response.value);
                State.runInAction(() => {
                    this.schedulingServices = this.schedulingServices.filter(s => s !== service);
                });
                await this.reloadRowVersionAsync();
                await this.validateAsync();
            }
        }
    }

    @State.bound
    private async saveWithoutReleasingLockAsync() {
        await this.saveAsync(false);
    }

    @State.bound
    private async saveAsync(releaseLock: boolean): Promise<boolean> {
        const response = await this.props._dependencies.apiAdapter.updateAppointmentScheduleDefinitionAsync(this.store, releaseLock);
        if (response.value.store.hasValidationError) {
            this.props._dependencies.notificationService.showSaveResult(false);
            State.runInAction(() => {
                this.store.validationResults = response.value.store.validationResults;
            });
        } else {
            this.invalidateCache();
            await this.props.onSaveAsync();
            await this.loadDataAsync(false);
            this.props._dependencies.notificationService.showSaveResult(true, response.value.store.hasValidationWarning);
            await this.showInvalidatedAppointmentsCountAsync(response.value.invalidAppointmentCount);
        }

        return response.isPersistedByOperationInfo;
    }

    @State.bound
    private async reloadRowVersionAsync() {
        const response = await this.props._dependencies.apiAdapter.getAppointmentScheduleDefinitionByIdAsync(this.props.appointmentScheduleDefinitionId);
        State.runInAction(() => {
            this.store.rowVersion = response.rowVersion;
        });
    }

    @State.bound
    private async deleteAsync() {
        const dialogResult = await this.props._dependencies.dialogService.yesNoCancel(
            StaticWebAppResources.Common.DialogTitle.ConfirmationTitle,
            StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.Message.DeleteConfirmationMessage);

        if (dialogResult.resultCode !== DialogResultCode.Yes)
            return;

        const response = await this.props._dependencies.apiAdapter.deleteAppointmentScheduleDefinitionAsync(this.store);
        if (response.operationInfo.requestStatus === RequestStatus.Success) {
            this.store.takeSnapshot();
            this.props._dependencies.notificationService.showSaveResult(true);
            await this.showInvalidatedAppointmentsCountAsync(response.value);
            this.invalidateCache();
            await this.props.onDeleteAsync();
            await this.props.onSaveAsync();
        }
    }

    @State.bound
    private handleCannotDeleteAppointmentScheduleDefinitionError(error: CannotDeleteAppointmentScheduleDefinitionError) {
        dispatchAsyncErrors(this.handleCannotDeleteAppointmentScheduleDefinitionErrorAsync(error), this);
        return true;
    }

    @State.bound
    private async handleCannotDeleteAppointmentScheduleDefinitionErrorAsync(error: CannotDeleteAppointmentScheduleDefinitionError) {
        const schedulingServicesInUse = await this.props._dependencies.apiAdapter.loadSchedulingServiceByIdsAsync(error.schedulingServiceIdsInUse);
        const formattedSchedulingServiceNames = schedulingServicesInUse.value.map(i => i.name).join(", ");

        const message = formatString(
            StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.Message.CannotDeleteAppointmentScheduleDefinition,
            formattedSchedulingServiceNames);

        await this.props._dependencies.dialogService.ok(StaticWebAppResources.Common.Label.Error, message);
    }

    @State.bound
    private async showInvalidatedAppointmentsCountAsync(count: number) {
        if (count > 0) {
            const message = StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.Message.InValidAppointmentsDialogMessage.replace("{count}", count.toString());
            await this.props._dependencies.dialogService.ok(
                StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.Message.InValidAppointmentsDialogTitle,
                message);
        }
    }

    @State.bound
    private invalidateCache() {
        this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleSlotSeries.invalidate();
    }

    @State.action.bound
    private async validateAsync() {
        const result = await this.props._dependencies.apiAdapter.validateAppointmentScheduleDefinitionAsync(this.store);
        this.setClientValidationProblems(result.value);
        return result.value;
    }

    @State.bound
    private async forceLoadAsync() {
        await this.loadDataAsync(true);
    }

    private get renderDetailToolbar() {
        return (
            <Ui.Flex>
                <Ui.Flex.Item grow>
                    <ReadOnlyContext.Provider value={false}>
                        <HisUi.LockIndicatorComponent locked={!this.store.isMutable}
                            style={{ alignContent: "right" }}
                            onEditClickedAsync={this.forceLoadAsync}
                            lockedBy={this.store.lockInfo?.preventingLockUserId}
                        />
                    </ReadOnlyContext.Provider>
                </Ui.Flex.Item>
                <Ui.Flex.Item>
                    <AppointmentScheduleDefinitionPlanningPeriodToolBarPanel store={this.store}
                        onValidateAsync={this.validateAsync} />
                </Ui.Flex.Item>
                <Ui.Flex.Item>
                    <Ui.SaveButton
                        disabled={!this.store.isMutable}
                        tooltipContent={!this.store.isMutable && StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.Panel.SaveButtonLockedTooltip}
                        onClickAsync={this.saveWithoutReleasingLockAsync}
                        text={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.Panel.Save}
                        automationId="saveButton"
                    />
                    <Ui.Button iconName="trash"
                        visualStyle="negative-standard"
                        text={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.Panel.Delete}
                        onClickAsync={this.deleteAsync}
                        automationId="deleteButton"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.action.bound
    private setClientValidationProblems(validationResults: IClientValidationResult[]) {
        if (isNullOrUndefined(validationResults)) {
            this.validationErrors = State.observable([]);
            return;
        }

        const filteredResults = validationResults.filter(x => x.entityName == "AppointmentScheduleDefinitionInfo");
        if (filteredResults.length > 0) {
            this.validationErrors = State.observable(filteredResults[0].problems.filter(x => x.severity === "error"));
            return;
        }

        this.validationErrors = State.observable([]);
        return;
    }

    public render() {
        if (!this.store) {
            return (<></>);
        }
        return (
            <>
                <ReadOnlyContext.Provider value={!this.store.isMutable}>
                    <BusinessErrorHandler.Register businessErrorName="CannotDeleteAppointmentScheduleDefinitionError" handler={this.handleCannotDeleteAppointmentScheduleDefinitionError} />
                    <ValidationBoundary onValidateAsync={this.validateAsync} validationResults={this.store.validationResults} entityTypeName="AppointmentScheduleDefinitionInfo">
                        <>
                            <Ui.ValidationResultSummary displayMode="summary"
                                results={this.validationErrors}
                            />
                            <UseCaseFrame
                                hasTabs
                                initiallyActiveTabName="baseData"
                            >
                                <UseCaseFrameTab
                                    tabName="baseData"
                                    tabTitle={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.Panel.BaseData}
                                    title={this.title}
                                    toolbar={this.renderDetailToolbar}
                                >
                                    <>
                                        <AppointmentScheduleDefinitionBaseDataPanelView
                                            store={this.store}
                                            extensionController={this.extensionController}
                                            onValidateAsync={this.validateAsync}
                                        />
                                        <AppointmentScheduleServicesGrid
                                            schedulingServices={this.servicesDataSource}
                                            onEditSchedulingServiceAsync={this.editSchedulingServiceAsync}
                                            onDeleteSchedulingServiceAsync={this.deleteSchedulingServiceAsync}
                                            onAddServiceAsync={this.addServiceAsync}
                                        />
                                    </>
                                </UseCaseFrameTab>

                                <UseCaseFrameTab
                                    tabName="slots"
                                    tabTitle={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.Panel.Slots}
                                    title={this.title}
                                    toolbar={this.renderDetailToolbar}
                                >
                                    <>
                                        <AppointmentScheduleDefinitionTimePhasesPanel
                                            selectedPlanningPeriod={this.store.selectedPlanningPeriod}
                                            schedulingServices={this.schedulingServices}
                                            validateAsync={this.validateAsync}
                                            validationResults={this.store.validationResults}
                                            isReadOnly={!this.store.isMutable}
                                        />
                                    </>
                                </UseCaseFrameTab>
                            </UseCaseFrame>
                        </>
                    </ValidationBoundary>
                </ReadOnlyContext.Provider>
                <NavigateAwayHook isEnabled onNavigateAwayAsync={this.unloadAsync} />
            </>
        );
    }
}

export default connect(
    AppointmentScheduleDefinitionDetailPanel,
    new DependencyAdapter<IAppointmentScheduleDefinitionDetailPanelProps, IAppointmentScheduleDefinitionDetailPanelDependencies>(c => ({
        apiAdapter: c.resolve("SchedulingConfigurationApiAdapter"),
        notificationService: c.resolve("INotificationService"),
        dialogService: c.resolve("IDialogService"),
        lockingApiAdapter: c.resolve("LockingApiAdapter"),
        schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore")
    })),
    new HisModalServiceAdapter()
);
