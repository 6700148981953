import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import React from "react";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { ITextBlockHistoryDialogResult, ITextBlockHistoryDialogParams } from "./TextBlockHistoryDialogParams";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import CareActivityTextBlockApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityTextBlock/CareActivityTextBlockApiAdapter";
import ICareActivityTextBlockHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/ICareActivityTextBlockHistoryItem";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import TextBlockHistoryGrid from "./TextBlockHistoryGrid";
import { ContextAwareModal, HisDocumentEditor } from "@HisPlatformControls";
import DocumentContentStore from "@CommonControls/DocumentEditor/DocumentContentStore";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessModal from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessModal";

interface ITextBlockHistoryDialogDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    apiAdapter: CareActivityTextBlockApiAdapter;
}

interface ITextBlockHistoryDialogProps extends IModalComponentParams<ITextBlockHistoryDialogResult>, ITextBlockHistoryDialogParams {
    _dependencies: ITextBlockHistoryDialogDependencies;
}

/** @screen */
@State.observer
class TextBlockHistoryDialog extends React.Component<ITextBlockHistoryDialogProps> {

    private get apiAdapter() { return this.props._dependencies.apiAdapter; }
    
    @State.observable private history: ICareActivityTextBlockHistoryItem[] = [];
    @State.observable.ref public selectedItem: ICareActivityTextBlockHistoryItem = null;
    private readonly selectedContent = DocumentContentStore.create();

    @State.action.bound
    public setSelectedItem(selectedItem: ICareActivityTextBlockHistoryItem) {
        this.selectedItem = selectedItem;
        dispatchAsyncErrors(this.loadContentAsync(selectedItem?.version), this);
    }

    @State.action.bound
    public setHistory(history: ICareActivityTextBlockHistoryItem[]) {
        this.history = history;
    }

    @State.action.bound
    private async loadAsync() {
        if (this.props.textBlockId) {
            const result = await this.apiAdapter.getCareActivityTextBlockHistoryAsync(this.props.textBlockId);
            if (result.operationWasSuccessful) {
                this.setHistory(result.value);
            }
        }
    }

    @State.bound
    private async loadContentAsync(version: number) {
        if (isNullOrUndefined(version)) {
            this.selectedContent.clearContent();
        } else {
            const content = await this.apiAdapter.getCareActivityTextBlockHistoryItemContentAsync(this.props.textBlockId, version);
            if (content.operationWasSuccessful) {
                this.selectedContent.setContent(content.value);
            }
        }
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);
    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessModal title={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.ChangeHistory} />;
        }

        return (
            <ContextAwareModal isOpen onClose={this.cancel} size="normal" fixedHeight={694}>
                <Ui.Modal.Body>
                    <MasterDetailLayout onSelectedItemChange={this.setSelectedItem} selectedItem={this.selectedItem}>
                        <MasterDetail.Master title={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.ChangeHistory}>
                            <TextBlockHistoryGrid history={this.history} />
                        </MasterDetail.Master>
                        <MasterDetail.Detail title={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.Content}>
                            <HisDocumentEditor contentStore={this.selectedContent.forEditor} mode="TextBlock" isReadOnly />
                        </MasterDetail.Detail>
                    </MasterDetailLayout>
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Button onClick={this.ok} text={StaticWebAppResources.Common.Button.Close} visualStyle="primary" float="right" automationId="okButton" />
                </Ui.Modal.Footer>
            </ContextAwareModal>
        );
    }

    @State.bound
    private cancel() {
        this.props.onClose(null);
    }
 
    @State.bound
    private ok() {
        this.props.onClose({});
    }
}

export default connect(
    TextBlockHistoryDialog,
    new DependencyAdapter<ITextBlockHistoryDialogProps, ITextBlockHistoryDialogDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        apiAdapter: c.resolve("CareActivityTextBlockApiAdapter")
    }))
);