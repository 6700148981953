import React from "react";
import { components } from "react-select";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import { iconNameType } from "@CommonControls/Icon";
import ISelectBoxSectionItem from "@CommonControls/SelectBox/ISelectBoxSectionItem";
import Styles from "@CommonControls/SelectBox/Option.less";
import { Flex, Icon } from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";

interface IOptionProps {
    isMulti: boolean;
    innerProps: any;
    isSelected: boolean;
    data: any;

    selectOption: (opt: any) => void;

    iconName: iconNameType;
    onIconClick: (data: ISelectBoxItem<any>) => void;
}

export default class Option extends React.Component<IOptionProps> {

    @State.bound
    private getOnClickHandler(item: ISelectBoxSectionItem<any>) {
        return (event: React.MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();
            this.props.onIconClick(item);
        };
    }

    @State.bound
    private renderIcon(data: any) {
        return (
            <div onMouseDown={this.getOnClickHandler(data)} className={Styles.iconContainer}>
                <Icon iconName={this.props.iconName} size="largeish" className={Styles.icon} />
            </div>
        );
    }

    @State.bound
    private mouseDown(e: React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        this.props.selectOption(this.props.data);
    }

    public render() {

        return (
            <components.Option {...this.props}
                innerProps={{ ...this.props.innerProps, onMouseDown: this.mouseDown, onClick: null }}>
                <Flex>
                    <Flex.Item className={this.props.iconName && Styles.optionText} grow={!this.props.iconName}>
                        {this.props.isMulti ? (
                            <SpanWithIcon style={{ display: "flex" }} iconName={this.props.isSelected ? "checkedBox" : "uncheckedBox"}>
                                {this.props.children}
                            </SpanWithIcon>
                        ) :
                            this.props.children}
                    </Flex.Item>
                    <Flex.Item>
                        {this.props.iconName && this.props.onIconClick && this.renderIcon(this.props.data)}
                    </Flex.Item>
                </Flex>
            </components.Option>
        );
    }
}
