import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import { addFieldsForPatientIdentifier, usePatientIdentifierCustomBlockHelper } from "@HisPlatform/Packages/Patients/Components/PatientIdentifier/PatientIdentifierFormCustomBlock";
import PatientDocumentTypeId from "@Primitives/PatientDocumentTypeId.g";
import { useErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import { useFormDateField, useFormEntityIdField, useFormTextField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { getCompositeArrayFields, getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import { useFormLayoutContext } from "@Toolkit/FormEngine/Panels/FormLayoutContext";
import { ICustomBlockRegistryItem, IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React, { useEffect, useMemo, useState } from "react";
import PatientAdditionalHunSocialSecurityIdentifier from "./PatientAdditionalHunSocialSecurityIdentifier";

interface IPatientAdditionalHunSocialSecurityIdentifierFormCustomBlockDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IPatientAdditionalHunSocialSecurityIdentifierFormCustomBlockProps extends IFormCustomBlockComponentProps {
    _dependencies?: IPatientAdditionalHunSocialSecurityIdentifierFormCustomBlockDependencies;
}

function _PatientAdditionalHunSocialSecurityIdentifierFormCustomBlock(props: IPatientAdditionalHunSocialSecurityIdentifierFormCustomBlockProps) {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext.compositeDataReferences.join(".");

    const fields = getCompositeArrayFields(props.form.data.Content, compositeFieldName);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        addFieldsForPatientAdditionalHunSocialSecurityIdentifier(props.form.data.Content, fields, compositeFieldName);
        addFieldsForPatientIdentifier(props.form.data.Content, fields, compositeFieldName);
        setIsLoaded(true);
    }, []);

    return isLoaded && (
        <PatientAdditionalHunSocialSecurityIdentifierFormCustomBlockCore {...props} />
    );
}

const PatientAdditionalHunSocialSecurityIdentifierFormCustomBlockCore = State.observer((props: IPatientAdditionalHunSocialSecurityIdentifierFormCustomBlockProps) => {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext.compositeDataReferences.join(".");

    const [identifierTypeId, setIdentifierTypeId] = useFormEntityIdField<PatientDocumentTypeId>(props.form.data.Content, compositeFieldName + ".DocumentTypeId", PatientDocumentTypeId);
    const [identifierValue, setIdentifierValue] = useFormTextField(props.form.data.Content, compositeFieldName + ".IdentifierValue");
    const [validFrom, setValidForm] = useFormDateField(props.form.data.Content, compositeFieldName + ".ValidFrom");
    const [validTo, setValidTo] = useFormDateField(props.form.data.Content, compositeFieldName + ".ValidTo");
    const [notes, setNotes] = useFormTextField(props.form.data.Content, compositeFieldName + ".Notes");

    const errorDispatcher = useErrorDispatcher();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        props._dependencies.careReferenceDataStore.patientDocumentType.ensureAllLoadedAsync()
            .catch(errorDispatcher)
            .then(() => setIsLoaded(true));
    }, []);

    const patientIdentifierTypeIdentifierSystemId = useMemo(() => {
        const patientDocumentType = props._dependencies.careReferenceDataStore.patientDocumentType.items.find(i => i.Entity.id.value === identifierTypeId?.value);
        return patientDocumentType?.Entity.identifierSystemId.value;
    }, [isLoaded, identifierTypeId]);

    usePatientIdentifierCustomBlockHelper(compositeFieldName, patientIdentifierTypeIdentifierSystemId, ["NewbornHunSocialSecurityNumber", "ReplacementHunSocialSecurityNumber"], "PatientAdditionalHunSocialSecurityIdentifierFormCustomBlock");

    return (patientIdentifierTypeIdentifierSystemId === "NewbornHunSocialSecurityNumber" || patientIdentifierTypeIdentifierSystemId === "ReplacementHunSocialSecurityNumber") && (
        <PatientAdditionalHunSocialSecurityIdentifier
            identifierTypeId={identifierTypeId}
            onIdentifierTypeIdChange={setIdentifierTypeId}
            identifierValue={identifierValue}
            onIdentifierValueChange={setIdentifierValue}
            validFrom={validFrom}
            onValidFromChange={setValidForm}
            validTo={validTo}
            onValidToChange={setValidTo}
            notes={notes}
            onNotesChange={setNotes}
        />
    );
});

function addFieldsForPatientAdditionalHunSocialSecurityIdentifier(formFields: FormFieldDataBase[], array: IObservableArray<FormFieldDataBase>, compositeFieldName: string) {
    State.runInAction(() => {
        const notesField = getField<TextFormFieldData>(formFields, compositeFieldName + ".Notes");
        if (!notesField) {
            array.push(new TextFormFieldData("Notes", false, true, null));
        }
    });
}

const PatientAdditionalHunSocialSecurityIdentifierFormCustomBlock = connect(_PatientAdditionalHunSocialSecurityIdentifierFormCustomBlock,
    new DependencyAdapter<IPatientAdditionalHunSocialSecurityIdentifierFormCustomBlockProps, IPatientAdditionalHunSocialSecurityIdentifierFormCustomBlockDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore")
        };
    }));

export const PatientAdditionalHunSocialSecurityIdentifierRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Patient additional hun social security identifier",
    dataElementsFactory: () => [
    ],
    componentType: PatientAdditionalHunSocialSecurityIdentifierFormCustomBlock
};

export default PatientAdditionalHunSocialSecurityIdentifierFormCustomBlock;
