import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import DayOfWeek from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/DayOfWeek.g";
import React from "react";

interface IDayOfWeekSelectorProps extends IUniversalEnumSelectorBaseProps {
    value?: DayOfWeek | DayOfWeek[];
    onChange?: (newValue: DayOfWeek | DayOfWeek[]) => void;
}


export const DayOfWeekSelector: React.FC<IDayOfWeekSelectorProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumOrigin="server"
        enumName={"DayOfWeek"}
        enumType={DayOfWeek}
    />
);