import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCases from "@Primitives/UseCases";
import ProcedureStatementPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/ProcedureStatement/ProcedureStatementPanel";
import ProcedureStatementId from "@Primitives/ProcedureStatementId.g";

export default function configureProcedureStatementUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2({
        identifiers: [UseCases.procedureStatementDetail],
        component: ProcedureStatementPanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));
            const id = map.get("procedureStatementId") ?? new ProcedureStatementId("new");
            return {
                id: id,
            };
        }
    });
}
    