exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ColumnStyles_truncated-string_t30cd {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n", ""]);

// exports
exports.locals = {
	"truncated-string": "ColumnStyles_truncated-string_t30cd",
	"truncatedString": "ColumnStyles_truncated-string_t30cd"
};