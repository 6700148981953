import { ScreenNavigationContextProvider, useScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import ShowScreenActionHost from "@HisPlatform/Components/ShowScreenAction/ShowScreenActionHost";
import { provideTabbedScreenStore, useTabbedScreenStore } from "@HisPlatform/Packages/ActionModel/Screens/TabbedScreen/TabbedScreenStoreProvider";
import ShowCareActivityTabbedScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityTabbedScreenAction.g";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import { useScreenStateContext } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import React from "react";

function TabbedScreen(props: IScreenPropsBase<ShowCareActivityTabbedScreenAction>) {

    const store = useTabbedScreenStore();
    const navigationContext = useScreenNavigationContext();
    const screenState = useScreenStateContext();

    return (
        <Screen
            tabs={store.tabs}
            selectedTab={store.selectedTab}
            onTabSelect={store.selectTabAsync.fireAndForget}
        >
            <ScreenNavigationContextProvider>
                <ShowScreenActionHost
                    screenRaw={navigationContext.currentSecondaryScreenRaw}
                    onClose={screenState.cancelled}
                    onDeleted={screenState.deleted}
                    onCancelled={screenState.cancelled}
                    onSavedNew={screenState.savedNew}
                    onSavedExisting={screenState.savedExisting}
                />
            </ScreenNavigationContextProvider>
        </Screen>
    );
}

export default provideTabbedScreenStore(TabbedScreen);