import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DynamicPropertiesApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/DynamicProperties/DynamicPropertiesApiAdapter";
import PropertyGroup from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyGroup";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import PropertyGroupGrid from "./PropertyGroupGrid";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import * as Ui from "@CommonControls";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import OrganizationUnit from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnit";
import _ from "@HisPlatform/Common/Lodash";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import PropertyBase from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyBase";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

interface IPropertyGroupsPanelDependencies {
    dynamicPropertiesApiAdapter: DynamicPropertiesApiAdapter;
    structureApiAdapter: StructureApiAdapter;
    notificationService: INotificationService;
    localizationService: ILocalizationService;
}

export interface IPropertyGroupsPanelController {
    isDirty(): boolean;

    externalSaveAsync: () => Promise<void>;
}

interface IPropertyGroupsPanelProps {
    _dependencies?: IPropertyGroupsPanelDependencies;
    organizationUnitId: OrganizationUnitId;
    controller?: IPropertyGroupsPanelController;
    onCancel?: () => void;
}

/** @screen */
@State.observer
class PropertyGroupsPanel extends React.Component<IPropertyGroupsPanelProps> {

    private get notificationService() {
        return this.props._dependencies.notificationService;
    }

    @State.observable.ref public propertyGroups: PropertyGroup[] = null;
    @State.observable.ref public filterValue: string = "";
    @State.observable.ref public originatingOrganizationUnits: SimpleStore<OrganizationUnit[]> = null;
    @State.observable.ref public currentOrganizationUnitId: OrganizationUnitId = null;
    @State.observable private isLoading: boolean = false;

    @State.computed
    public get visiblePropertyGroups() {
        return this.propertyGroups && this.propertyGroups.filter(x => x.isVisible) || [];
    }

    @State.computed
    public get noVisiblePropertyGroups() {
        return !this.visiblePropertyGroups || this.visiblePropertyGroups.length === 0;
    }

    constructor(props: IPropertyGroupsPanelProps) {
        super(props);

        if (this.props.controller) {
            this.props.controller.isDirty = () => this.propertyGroups && this.propertyGroups.some(x => x.isDirty());
            this.props.controller.externalSaveAsync = this.saveAsync;
        }
    }

    @State.bound
    private async saveAsync() {
        if (!this.propertyGroups) {
            return;
        }

        const newStore = await this.props._dependencies.dynamicPropertiesApiAdapter.updatePropertyGroupsOfOrganizationUnitAsync(this.props.organizationUnitId, this.propertyGroups);
        this.notificationService.showSaveResult(newStore.isPersistedByOperationInfo);
        const newPropertyGroups = newStore.value;
        this.setPropertyGroups(newPropertyGroups);
    }

    @State.action.bound
    public setPropertyGroups(propertyGroups: PropertyGroup[]) {
        this.propertyGroups = propertyGroups;
        this.propertyGroups.forEach(x => x.takeSnapshot());
        this.currentOrganizationUnitId = this.props.organizationUnitId;

        this.filterProperties();
    }

    @State.action.bound
    public filterProperties() {
        const filterValue = this.filterValue && this.filterValue.toLowerCase();
        for (const propertyGroup of this.propertyGroups) {
            for (const property of propertyGroup.properties) {
                if (isNullOrUndefined(this.filterValue) || this.filterValue === "") {
                    property.isVisible = true;
                } else {
                    const rowNameSelector = `${propertyGroup.name}.${property.name}`;
                    let localizedName = this.props._dependencies.localizationService.localizeProperty(rowNameSelector);

                    if (!localizedName) {
                        localizedName = property.name;
                    }

                    property.isVisible = localizedName.toLowerCase().includes(filterValue);
                }
            }
        }
    }

    @State.action.bound
    public setFilterValue(value: string) {
        this.filterValue = value;
        this.filterProperties();
    }

    @State.boundLoadingState("isLoading")
    private async loadAsync() {
        if (!this.props.organizationUnitId) {
            return;
        }
        const results = await this.props._dependencies.dynamicPropertiesApiAdapter.getEditablePropertiesOfOrganizationUnitAsync(this.props.organizationUnitId);

        const originatingOrganizationUnitIds = results.value.map(x => x.properties.map(y => y.originatingOrganizationUnitId));
        const deflatedList = _.union.apply(null, originatingOrganizationUnitIds).filter((x: OrganizationUnitId) => x !== null && x.value !== null);
        const filteredIds = _.uniqBy(deflatedList, "value") as unknown as OrganizationUnitId[];

        const orgUnits = await this.props._dependencies.structureApiAdapter.getOrganizationUnitsByIdsAsync(filteredIds);

        State.runInAction(() => {
            this.originatingOrganizationUnits = orgUnits;
            this.setPropertyGroups(results.value);
        });
    }

    @State.bound
    public async validatePropertyVersionAsync(property: PropertyBase, propertyGroup: PropertyGroup): Promise<IClientValidationResult[]> {
        const propertyGroupWithVersion = new PropertyGroup();
        propertyGroupWithVersion.properties = [property];
        propertyGroupWithVersion.definitionId = propertyGroup.definitionId;
        const result = await this.props._dependencies.dynamicPropertiesApiAdapter.validatePropertyGroup(this.props.organizationUnitId, propertyGroupWithVersion);
        return result.value;
    }

    public render() {

        if (this.isLoading) {
            return <></>;
        }

        return (
            <>
                <Ui.Flex verticalSpacing="none">
                    <Ui.Flex.Item xs={12}>
                        <Ui.TextBox
                            label={StaticWebAppResources.Common.Label.Filter}
                            onChange={this.setFilterValue}
                            value={this.filterValue}
                            automationId="filterValueTextBox"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex verticalSpacing="regular">
                    <Ui.Flex.Item xs={12}>
                        <Ui.ScrollView height="calc(100vh - 250px)">
                            {this.visiblePropertyGroups.map((pg: PropertyGroup) =>
                                <PropertyGroupGrid
                                    key={pg.name}
                                    propertyGroup={pg}
                                    filterValue={this.filterValue}
                                    originatingOrganizationUnits={this.originatingOrganizationUnits}
                                    currentOrganizationUnitId={this.currentOrganizationUnitId}
                                    validateVersionsAsync={this.validatePropertyVersionAsync}
                                />)
                            }
                            {this.noVisiblePropertyGroups && !this.isLoading &&
                                <>
                                    <br />
                                    <Ui.NoItemsMessage message={StaticWebAppResources.Common.Label.NoFilterMatch} />
                                </>
                            }
                        </Ui.ScrollView>
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex xsJustify="end">
                    <Ui.Flex.Item>
                        {this.props.onCancel && <Ui.Button
                            text={StaticWebAppResources.Common.Button.Cancel}
                            onClick={this.props.onCancel}
                            automationId="cancelButton"
                        />}
                        <Ui.SaveButton
                            onClickAsync={this.saveAsync}
                            visualStyle="primary"
                            disabled={this.noVisiblePropertyGroups}
                            automationId="saveButton"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </>
        );
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IPropertyGroupsPanelProps) {
        if (this.props.organizationUnitId !== prevProps.organizationUnitId) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }
}

export default connect(
    PropertyGroupsPanel,
    new DependencyAdapter<IPropertyGroupsPanelProps, IPropertyGroupsPanelDependencies>(container => {
        return {
            dynamicPropertiesApiAdapter: container.resolve("DynamicPropertiesApiAdapter"),
            structureApiAdapter: container.resolve("StructureApiAdapter"),
            notificationService: container.resolve("INotificationService"),
            localizationService: container.resolve("ILocalizationService")
        };
    })
);
