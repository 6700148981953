import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import MomentRange from "@Toolkit/CommonWeb/Model/MomentRange";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class DateWithTimeRange {
    @State.observable.ref public date: LocalDate = null;
    @State.observable.ref public timeIntervalStart: TimeOfDay = null;
    @State.observable.ref public timeIntervalEnd: TimeOfDay = null;

    constructor(value?: MomentRange) {
        if (value) {
            this.date = LocalDate.createFromMoment(value.from);
            this.timeIntervalStart = new TimeOfDay(value.from.hour(), value.from.minute());
            this.timeIntervalEnd = new TimeOfDay(value.to.hour(), value.to.minute());
        }
    }

    @State.action.bound public setDateValue(newValue: LocalDate) {
        this.date = newValue;

        if (!this.timeIntervalStart && !this.timeIntervalEnd) {
            this.setTimeInterval(TimeOfDay.startOfDay, TimeOfDay.endOfDay);
        }
    }

    @State.action.bound public setTimeInterval(from: TimeOfDay, to: TimeOfDay) {
        this.timeIntervalStart = from;
        this.timeIntervalEnd = to;
    }

    @State.computed public get toMomentRange() {
        if (!this.date) {
            return null;
        }

        if (!this.timeIntervalStart || !this.timeIntervalEnd) {
            return new MomentRange(this.date.toUtcDayStartMoment(), null);
        }

        const from = this.date.toUtcDayStartMoment();
        from.hour(this.timeIntervalStart.hours).minute(this.timeIntervalStart.minutes);
        const to = this.date.toUtcDayStartMoment();
        to.hour(this.timeIntervalEnd.hours).minute(this.timeIntervalEnd.minutes);

        return new MomentRange(from, to);
    }
}