import React from "react";
import * as Ui from "@CommonControls";
import AllergyIntoleranceCriticality from "@Primitives/AllergyIntoleranceCriticality";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";

interface IAllergyIntoleranceCriticalityRadioButtonGroupProps extends IUniversalEnumSelectorBaseProps {
    value: AllergyIntoleranceCriticality;
    onChange?: (value: AllergyIntoleranceCriticality) => void;
}


const AllergyIntoleranceCriticalityRadioButtonGroup: React.FC<IAllergyIntoleranceCriticalityRadioButtonGroupProps> = props => (
    <Ui.UniversalEnumSelector
        {...props}
        enumName={"AllergyIntoleranceCriticality"}
        enumOrigin="server"
        enumType={AllergyIntoleranceCriticality}
        displayMode="groupedRadioButtons"
    />
);

export default AllergyIntoleranceCriticalityRadioButtonGroup;