import { IBusinessErrorHandler, HandlerType } from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import IBusinessError from "@Toolkit/CommonWeb/Model/IBusinessError";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";

export default class UniversalBusinessErrorHandler implements IBusinessErrorHandler {

    private readonly _handlers = new Map<string, HandlerType[]>();

    public handle(error: IBusinessError) {
        if (!error) {
            throw new Error("Business error cannot be null or undefined.");
        }

        const handlers = this._handlers.get(error.name);

        if (handlers) {
            for (const handler of handlers) {
                const isHandled = handler(error);
                if (isHandled) {
                    return true;
                }
            }
        }

        return false;
    }

    public registerHandler(name: string, handler: (error: IBusinessError) => boolean) {
        let handlers = this._handlers.get(name);

        if (!handlers) {
            handlers = [handler];
            this._handlers.set(name, handlers);
        } else {
            this._handlers.set(name, [
                handler,
                ...handlers
            ]);
        }
    }

    public removeHandler(name: string, handler: (error: IBusinessError) => boolean) {
        const handlers = this._handlers.get(name);

        if (handlers) {
            this._handlers.set(name, [
                ...handlers.filter(h => h !== handler)
            ]);
        }
    }
}
