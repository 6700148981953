import React from "react";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import State from "@Toolkit/ReactClient/Common/StateManaging";import NeakPerformanceStatementFilterStore from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/NeakPerformanceStatementWorklistPanel/Filters/NeakPerformanceStatementFilterStore";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { RowId } from "@CommonControls/DataGrid/IDataGridProps";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";

interface INeakPerformanceStatementWorklistPanelView {
    worklistDefinition: IWorklistDefinition;
    filterStore: NeakPerformanceStatementFilterStore;
    onFilterStoreCreatedAsync?: (filterStore: any) => Promise<void>;

    selectedRowId: string;
    useCaseState: INDataUseCaseState;
    onChange: (rowId: string, useCaseState: INDataUseCaseState) => void;
    onPerformClientSideActionAsync?: (clientSideAction: ClientSideActionDto, rowId: RowId) => Promise<WorkListArgument | boolean>;
    refreshListEvent: TypedAsyncEvent;

    onGetPanelProps: (useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) => object;
}


const NeakPerformanceStatementWorklistPanelView: React.FC<INeakPerformanceStatementWorklistPanelView> = (props) => {

    return props.worklistDefinition && (
        <NDataPanel
            definition={props.worklistDefinition}
            onFilterStoreCreatedAsync={props.onFilterStoreCreatedAsync}
            defaultPageSize={10}
            selectedRowId={props.selectedRowId}
            useCaseState={props.useCaseState}
            onChange={props.onChange}
            onPerformClientSideActionAsync={props.onPerformClientSideActionAsync}
            hasRefreshButton
            refreshListEvent={props.refreshListEvent}
            onGetPanelProps={props.onGetPanelProps}
        />
    );
};

export default State.observer(NeakPerformanceStatementWorklistPanelView);
