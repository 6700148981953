import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import UserGroupListToolbar from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserGroupManagementPanel/Master/UserGroupListToolbar";
import UserGroupList from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserGroupManagementPanel/Master/UserGroupList";
import UserGroupBaseDataView from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserGroupManagementPanel/Detail/UserGroupBaseDataView";
import UserGroup from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroup";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import SaveButton from "@CommonControls/SaveButton";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import AccessRuleBase from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/AccessRuleBase";
import PermissionConfigurationPanel from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/RoleManagement/PermissionConfigurationPanel/PermissionConfigurationPanel";

interface IUserGroupManagementPanelViewProps {
    userGroup: UserGroup;
    onSelectedItemChange: (selectedUserGroupId: string) => void;
    activeTab: "BaseData" | "Permissions";
    onActiveTabChange: (activeTab: string) => void;
    isLoading: boolean;
    addNewUserGroup: () => void;
    dataSource: InMemoryDataGridDataSource<UserGroup>;
    saveUserGroupAsync: () => Promise<void>;
    onValidateAllAsync: () => Promise<IClientValidationResult[]>;
    validationResults?: IClientValidationResult[];
    backToPreviousPage: () => void;
    onAccessRulesChange: (newValue: AccessRuleBase[]) => void;
    accessRules: AccessRuleBase[];
}

const getTabTitle = (tab: string) => {
    switch (tab) {
        case "Permissions":
            return StaticUserManagementResources.UserGroupManagementPanel.Tab.Permissions;
        case "BaseData":
        default:
            return StaticUserManagementResources.UserGroupManagementPanel.Tab.BaseData;
    }
};

const getTitle = (tab: string, userGroup: UserGroup) =>
    `${userGroup?.displayName}: ${getTabTitle(tab)}`;


const UserGroupManagementPanelView: React.FC<IUserGroupManagementPanelViewProps> = (props) => {
    const saveButton = () => {
        return (
            <SaveButton
                onClickAsync={props.saveUserGroupAsync}
                visualStyle="primary"
                automationId="save"
            />
        );
    };

    return (
        <ValidationBoundary
            validationResults={props.validationResults}
            onValidateAsync={props.onValidateAllAsync}
            validateOnMount
        >
            <SingleLayout>
                <MasterDetailLayout
                    selectedItem={props.userGroup?.id.value}
                    onSelectedItemChange={props.onSelectedItemChange}
                    activeTabName={props.activeTab}
                    onActiveTabNameChange={props.onActiveTabChange}
                    isLoading={props.isLoading}
                >
                    <MasterDetail.Master
                        title={StaticUserManagementResources.UserGroupManagementPanel.Title}
                        toolbar={(<UserGroupListToolbar onAddUserGroup={props.addNewUserGroup} />)}
                    >
                        <UserGroupList
                            dataSource={props.dataSource}
                            onBack={props.backToPreviousPage}
                        />
                    </MasterDetail.Master>
                    <MasterDetail.Detail
                        hasSidePadding
                        title={StaticUserManagementResources.UserGroupManagementPanel.Tab.BaseData}
                    >
                        <MasterDetail.Tab
                            tabName={"BaseData"}
                            tabTitle={StaticUserManagementResources.UserGroupManagementPanel.Tab.BaseData}
                            title={getTitle(props.activeTab, props.userGroup)}
                            toolbar={saveButton()}
                            propertyPathRegexPattern="(UserGroup)"
                            automationId="baseDataTab"
                        >
                            <UserGroupBaseDataView
                                userGroup={props.userGroup}
                                onSaveAsync={props.saveUserGroupAsync}
                            />
                        </MasterDetail.Tab>
                        <MasterDetail.Tab
                            tabName={"Permissions"}
                            tabTitle={StaticUserManagementResources.UserGroupManagementPanel.Tab.Permissions}
                            title={getTitle(props.activeTab, props.userGroup)}
                            toolbar={saveButton()}
                            automationId="permissionsTab"
                        >
                            <PermissionConfigurationPanel
                                subjectId={props.userGroup?.id}
                                accessRules={props.accessRules}
                                onAccessRulesChange={props.onAccessRulesChange}
                            />
                        </MasterDetail.Tab>
                    </MasterDetail.Detail>
                </MasterDetailLayout>
            </SingleLayout>
        </ValidationBoundary>
    );
};

export default State.observer(UserGroupManagementPanelView);
