import AssecoRoomsCallContext from "@AssecoRoomsPlugin/AssecoRoomsCallContext";
import LogoutHandlerExtension from "@AssecoRoomsPlugin/Packages/Care/Extensions/LogoutHandlerExtension";
import DependencyContainer from "@DiContainer";
import ILogoutHandlerExtension from "@PluginInterface/BoundedContexts/UserManagement/ILogoutHandlerExtension";

DependencyContainer
    .bind("AssecoRoomsCallContext")
    .to(AssecoRoomsCallContext)
    .inSingletonScope();

DependencyContainer
    .bind("ILogoutHandlerExtension")
    .to(LogoutHandlerExtension);