import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import MedicationId from "@Primitives/MedicationId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

export interface IMedicationEquipmentSelectorDialogParams extends IModalParams {
    alreadyAddedMedications: MedicationId[];
    careActivityId: CareActivityId;
    validOn: LocalDate;
}

export interface IMedicationEquipmentSelectorDialogResult {
    medicationId: MedicationId;
}

export default class MedicationEquipmentSelectorDialogParams implements IMedicationEquipmentSelectorDialogParams {
    public static type = "MedicationEquipmentSelectorDialog";
    public get type() { return MedicationEquipmentSelectorDialogParams.type; }

    constructor(public alreadyAddedMedications: MedicationId[], public careActivityId: CareActivityId, public validOn: LocalDate) { }
}
