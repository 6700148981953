import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import InvalidAppointmentsPanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/InvalidAppointmentsPanel/InvalidAppointmentsPanel";
import {HisErrorBoundary} from "@HisPlatformControls";
import IAppointmentScheduleDefinitionsRouteParams from "@HisPlatform/Components/Pages/AppointmentScheduleDefinitionPages/IAppointmentScheduleDefinitionsRouteParams";
import {getUseCaseAsUrlParam, parseUseCaseStateFromUrlParam} from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import StandaloneAppointmentRoutes from "@HisPlatform/Components/Pages/AppointmentPages/StandaloneAppointmentRoutes";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import PatientEHRRoutes from "@HisPlatform/Components/Pages/Patient/PatientEHRRoutes";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

@State.observer
export default class InvalidAppointmentsPage extends React.Component<IRoutingFrameContentProps> {
    @State.computed
    private get selectedAppointmentId(): string {
        const id = this.useCaseState?.useCaseArguments?.find(item => item.name === "appointmentId")?.value;
        return id?.value;
    }

    @State.computed
    private get useCaseState() {
        const useCaseState = (this.props.routingController.currentRoute.parameters as IAppointmentScheduleDefinitionsRouteParams)?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState) as INDataUseCaseState;
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setUseCaseState(selectedAppointmentId: string, useCaseState: INDataUseCaseState) {
        if (useCaseState) {
            if (useCaseState.useCase.value === UseCases.patientRegisterPatientData) {
                const patientId = useCaseState.useCaseArguments[0].value;
                this.props.routingController.push(
                    ApplicationRoutes.patient.makeRoute({
                        patientId: patientId?.value,
                        appointmentId: "null",
                        mode: "read-write"
                    })
                );
            } else if (useCaseState.useCase.value === UseCases.careRegisterViewPatientCareActivities) {
                const patientId = useCaseState.useCaseArguments[0].value;
                this.props.routingController.push(
                    PatientEHRRoutes.careActivities.makeRoute({
                        patientId: patientId?.value,
                        appointmentId: "null",
                        mode: "read-write",
                    })
                );
            } else if (useCaseState.useCase.value === UseCases.patientRegisterViewAppointments) {
                const patientId = useCaseState.useCaseArguments[0].value;
                this.props.routingController.push(
                    StandaloneAppointmentRoutes.registeredPatientAppointments.makeRoute({
                        patientId: patientId?.value,
                        careActivityId: null,
                        useCase: getUseCaseAsUrlParam(
                            new UseCaseIdentifier(UseCases.patientRegisterViewAppointments),
                            UseCaseDisplayMode.Full,
                            [new UseCaseArgument(new AppointmentScheduleEntryId("null"), "appointmentId")])
                    })
                );
            } else if (useCaseState.useCase.value === UseCases.editAppointmentFromList) {
                const appointmentId = useCaseState.useCaseArguments[0].value;
                this.props.routingController.replace(
                    StandaloneAppointmentRoutes.invalidAppointments.makeRoute({
                        mode: "read-write",
                        useCase: getUseCaseAsUrlParam(
                            new UseCaseIdentifier(UseCases.editAppointmentFromList),
                            UseCaseDisplayMode.MasterDetail,
                            [new UseCaseArgument(appointmentId, "appointmentId")])
                    })
                );
            }
        } else if (!useCaseState && !selectedAppointmentId) {
            this.setClosedState();
        }
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(StandaloneAppointmentRoutes.invalidAppointments.makeRoute());
    }

    public render() {
        return (
            <HisErrorBoundary>
                <LoadingBoundary>
                    <InvalidAppointmentsPanel
                        onChange={this.setUseCaseState}
                        useCaseState={this.useCaseState}
                        selectedRowId={this.selectedAppointmentId}
                        onBack={this.goBack}
                        onCloseDetail={this.setClosedState}
                    />
                </LoadingBoundary>
            </HisErrorBoundary>
        );
    }
}
