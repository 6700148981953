import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";

export interface IInsurerOrganizationDetailPanelModalParams extends IModalParams {
    insurerOrganizationId?: InsurerOrganizationId;
    readOnly?: boolean;
}

export interface IInsurerOrganizationDetailPanelModalResult {
    operationWasSuccesful: boolean;
    insurerOrganizationId?: InsurerOrganizationId;
}

export default class InsurerOrganizationDetailPanelModalParams implements IInsurerOrganizationDetailPanelModalParams {

    public static type = "InsurerOrganizationDetailPanelModalParams";
    public get type() { return InsurerOrganizationDetailPanelModalParams.type; }

    constructor(
        public readonly insurerOrganizationId?: InsurerOrganizationId,
        public readonly readOnly?: boolean
    ) { }

}