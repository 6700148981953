import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import React, { useCallback } from "react";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import CareTypeId from "@Primitives/CareTypeId.g";
import _ from "@HisPlatform/Common/Lodash";
import * as Ui from "@CommonControls";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import CareActivityStateIcon from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/CareActivityStateIcon/CareActivityStateIcon";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export interface ICareActivityStateColumnProps<TRecord = any, TValue = any, TFilterValue = any> extends IDataGridColumnBaseProps<TRecord, TValue, TFilterValue> {
    careActivityStateDataGetter: string | ((row: TRecord, key: string | number, index: number) => TValue);
}

const careTypeValuesToIcons = {
    Inpatient: "patientInBed",
    Outpatient: "patientWalking",
    TelemetrySession: "cardiology",
    Dentistry: "careType_dentistry",
    FamilyDoctor: "careType_familyDoctor",
    Radiology: "careType_radiology",
    Emergency: "careType_emergency",
    Ambulance: "careType_ambulance",
    ChildFamilyDoctor: "paediatrics",
    HomeQuarantine: "house_user_solid",
    Laboratory: "laboratory_medicine",
    SchoolHealthcare: "graduation_cap_solid",
    Dialysis: "kidney",
    PregnantCare: "pregnant",
    NonConventional: "acupuncture",
    Spa: "bathrobe",
    OccupationalHealthcare: "doctor",
    LawEnforcement: "commander",
    Autopsy: "autopsy",
    Therapy: "vaccine",
    Psychology: "psychiatry",
    RehabilitationalAuthorityExamination: "rehabilitation",
    CovidVaccination: "covid_vaccination",
    MicroBiologyLaboratory: "mlabor",
    SameDaySurgery: "surgery",
    EmergencyTriage: "emergency_triage",
    EmergencyCare: "emergency_care",
    CovidRapidTest: "covid_rapid",
    PostMortem: "dead_body",
    Pathology: "pathology"
};

const getIconNameByValue = (value: any) => {
    if (value && value.value && careTypeValuesToIcons[value.value]) {
        return careTypeValuesToIcons[value.value];
    } else {
        return "remove";
    }
};

const renderIcon = (value: any) => {
    return <Ui.Icon iconName={getIconNameByValue(value)} size="largeish" />;
};

const renderCareActivityStateIcon = (state: CareActivityState) => {
    if (!isNullOrUndefined(state)) {
        return <CareActivityStateIcon state={state} isIndex />;
    }
    return "";
};


const CareActivitiyStateColumn2: React.FC<ICareActivityStateColumnProps> = (props) => {
    const { children, ...columnProps } = props;

    const dependencies = useDependencies(container => ({
        careReferenceDataStore: container.resolve<CareReferenceDataStore>("CareReferenceDataStore")
    }));

    const referenceDataStore = dependencies.careReferenceDataStore;

    const renderCellValue = useCallback((value: CareTypeId, row: any, rowId: any, rowIndex: number) => {
        const stateValue = typeof columnProps.careActivityStateDataGetter === "function" ? columnProps.careActivityStateDataGetter(row, row.id, rowIndex) : _.get(row, columnProps.careActivityStateDataGetter);
        if (value) {
            return (
                <>
                    {renderIcon(value)}
                    {renderCareActivityStateIcon(stateValue)}
                </>
            );
        }
        return "";
    }, []);

    const renderHintValue = useCallback((value: any, row?: any) => {
        return referenceDataStore.careType.get(value)?.displayValue?.Name;
    }, []);

    return (
        
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={renderCellValue}
            onRenderHintValue={renderHintValue}
            cellTextAlign="center"
        />
    );
};

export default CareActivitiyStateColumn2;