import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import AuthorizationReferenceDataStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/AuthorizationReferenceDataStore";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import RoleId from "@Primitives/RoleId.g";
import Role from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/Role";
import { ISelectBoxProps } from "@CommonControls/SelectBox";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

interface IRoleSelectBoxDependencies {
    authorizationReferenceDataStore: AuthorizationReferenceDataStore;
}

interface IRoleSelectBoxProps extends ISelectBoxProps {
    _dependencies?: IRoleSelectBoxDependencies;
    excludedIds?: RoleId[];
}


@State.observer
class RoleSelectBox extends React.Component<IRoleSelectBoxProps> {
    private get referenceDataStore() {
        return this.props._dependencies.authorizationReferenceDataStore.roleMap;
    }

    @State.computed private get items(): Array<ISelectBoxItem<RoleId>> {
        const items = this.referenceDataStore.items
            .filter(r => !this.props.excludedIds || this.props.excludedIds.every(e => !ValueWrapper.equals(r.id, e)));
        return items.map(this.toSelectBoxItem);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private async loadAsync() {
        await this.referenceDataStore.ensureAllLoadedAsync();
    }

    @State.bound
    private toSelectBoxItem(item: Role) {
        return {
            text: item.name,
            value: item.id
        } as ISelectBoxItem<InsurerOrganizationId>;
    }

    public render() {
        return (
            <Ui.SelectBox
                {...this.props}
                loading={!this.items}
                items={this.items}
            />
        );
    }
}

export default connect(
    RoleSelectBox,
    new DependencyAdapter<IRoleSelectBoxProps, IRoleSelectBoxDependencies>((containerService) => {
        return {
            authorizationReferenceDataStore: containerService.resolve("AuthorizationReferenceDataStore")
        };
    })
);
