import State from "@Toolkit/ReactClient/Common/StateManaging";
import CountryId from "@Primitives/CountryId.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import Di from "@Di";
import PatientAllergyIntoleranceListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceListStore";
import PractitionerId from "@Primitives/PractitionerId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import AllergyIntoleranceReactionId from "@Primitives/AllergyIntoleranceReactionId.g";
import PatientRegisterReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/PatientRegisterReferenceDataStore";
import AllergyIntoleranceId from "@Primitives/AllergyIntoleranceId.g";
import PatientAllergyIntoleranceHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceHistoryItem";
import ReferencedAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/ReferencedAllergyIntoleranceAssignment";
import MedicationId from "@Primitives/MedicationId.g";
import SubstanceId from "@Primitives/SubstanceId.g";
import MedicationAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/MedicationAllergyIntoleranceAssignment";
import SubstanceAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/SubstanceAllergyIntoleranceAssignment";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import InternationalNonproprietaryNameAllergyIntoleranceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/InternationalNonproprietaryNameAllergyIntoleranceAssignment";
import InternationalNonproprietaryNameId from "@Primitives/InternationalNonproprietaryNameId.g";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";

@Di.injectable()
export default class PatientLoader {

    constructor(
        @Di.inject("OrganizationReferenceDataStore") private readonly organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("FinanceReferenceDataStore") private readonly financeReferenceDataStore: FinanceReferenceDataStore,
        @Di.inject("CommonReferenceDataDataStore") private readonly commonReferenceDataStore: CommonReferenceDataDataStore,
        @Di.inject("CareReferenceDataStore") private readonly careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("PatientRegisterReferenceDataStore") private readonly patientRegisterReferenceDataStore: PatientRegisterReferenceDataStore,
    ) { }

    @State.bound
    public async loadPatientRelatedReferenceDataAsync(
        patient?: PatientAdministrativeData,
        patientAllergyIntoleranceList?: PatientAllergyIntoleranceListStore
    ) {
        // Warning! Not complete. Add your reference data loading here as needed.

        await this.organizationReferenceDataStore.allPointsOfCareMap.ensureAllLoadedAsync();
        await this.commonReferenceDataStore.telecomType.ensureLoadedAsync();
        await this.commonReferenceDataStore.telecomUse.ensureLoadedAsync();
        await this.commonReferenceDataStore.gender.ensureLoadedAsync();
        await this.commonReferenceDataStore.identifierSystemMap.ensureAllLoadedAsync();
        await this.financeReferenceDataStore.insurerOrganizationMap.ensureAllLoadedAsync();
        await this.careReferenceDataStore.patientDocumentType.ensureAllLoadedAsync();
        await this.patientRegisterReferenceDataStore.allergyIntoleranceCategory.ensureLoadedAsync();
        await this.patientRegisterReferenceDataStore.allergyIntoleranceClassificationType.ensureLoadedAsync();
        await this.patientRegisterReferenceDataStore.medicationStatementSubstanceCategory.ensureLoadedAsync();

        const countryIds: CountryId[] = [];
        const practitionerIds: PractitionerId[] = [];
        const allergyIntoleranceVersionSelectors: Array<IEntityVersionSelector<AllergyIntoleranceId>> = [];
        const medicationVersionSelectors: Array<IEntityVersionSelector<MedicationId>> = [];
        const substanceVersionSelectors: Array<IEntityVersionSelector<SubstanceId>> = [];
        const allergyIntoleranceReactionVersionSelectors: Array<IEntityVersionSelector<AllergyIntoleranceReactionId>> = [];
        const internationalNonproprietaryNameIds: InternationalNonproprietaryNameId[] = [];

        if (patient?.baseData?.birthLocationInfo?.countryId) {
            countryIds.push(patient.baseData.birthLocationInfo.countryId);
        }

        if (patient?.addresses?.length > 0) {
            countryIds.push(...patient.addresses.map(i => i.countryId));
        }

        if (patient?.insurances?.length > 0) {
            await this.financeReferenceDataStore.insurerOrganizationMap.ensureLoadedAsync(
                patient.insurances.map(i => i.insurerOrganizationId));
        }

        if (!!patientAllergyIntoleranceList) {
            patientAllergyIntoleranceList.patientAllergyIntolerances?.forEach(a => {
                practitionerIds.push(a.latestHistoryItem.historyItemDetail.asserter);
                practitionerIds.push(a.latestHistoryItem.historyItemDetail.verifier);

                allergyIntoleranceReactionVersionSelectors.push(...(a.latestHistoryItem as PatientAllergyIntoleranceHistoryItem).reactions.map(x => new EntityVersionSelector<AllergyIntoleranceReactionId>(x.allergyIntoleranceReactionId.id, x.allergyIntoleranceReactionId.validOn)));

                if (a.assignment instanceof ReferencedAllergyIntoleranceAssignment) {
                    allergyIntoleranceVersionSelectors.push(a.assignment.allergyIntoleranceVersionSelector);
                } else if (a.assignment instanceof MedicationAllergyIntoleranceAssignment) {
                    medicationVersionSelectors.push(a.assignment.medicationVersionSelector);
                } else if (a.assignment instanceof SubstanceAllergyIntoleranceAssignment) {
                    substanceVersionSelectors.push(a.assignment.substanceVersionSelector);
                } else if (a.assignment instanceof InternationalNonproprietaryNameAllergyIntoleranceAssignment) {
                    internationalNonproprietaryNameIds.push(a.assignment.id);
                }
            });
        }

        await this.commonReferenceDataStore.countryMap.ensureLoadedAsync(countryIds);
        await this.organizationReferenceDataStore.doctorMap.ensureLoadedAsync(practitionerIds);
        await this.careReferenceDataStore.allergyIntolerances.ensureLoadedAsync(allergyIntoleranceVersionSelectors);
        await this.careReferenceDataStore.medications.ensureLoadedAsync(medicationVersionSelectors);
        await this.careReferenceDataStore.substances.ensureLoadedAsync(substanceVersionSelectors);
        await this.careReferenceDataStore.internationalNonproprietaryNames.ensureLoadedAsync(internationalNonproprietaryNameIds);
        await this.patientRegisterReferenceDataStore.allergyIntoleranceReaction.ensureLoadedAsync(
            allergyIntoleranceReactionVersionSelectors);
    }

}
