import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import AsserterType from "@Primitives/AsserterType";

interface IAsserterTypeSelectProps extends ISelectBoxBaseProps {
    value?: AsserterType;
    onChange?: (newValue: AsserterType) => void;
}


const AsserterTypeSelectBox: React.FC<IAsserterTypeSelectProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"AsserterType"}
        enumOrigin="server"
        enumType={AsserterType}
        displayMode="selectBox"
    />
);

export default AsserterTypeSelectBox;