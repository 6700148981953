import React from "react";
import EntityProtectionLevel from "@Primitives/EntityProtectionLevel";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";

interface IEntityProtectionLevelSelectBoxProps extends IUniversalEnumSelectorBaseProps {
    value: EntityProtectionLevel;
    onChange: (newValue: EntityProtectionLevel) => void;
}


const EntityProtectionLevelSelector: React.FC<IEntityProtectionLevelSelectBoxProps> = props => (
    <UniversalEnumSelector
        displayMode="groupedRadioButtons"
        {...props}
        enumName={"EntityProtectionLevel"}
        enumOrigin="server"
        enumType={EntityProtectionLevel}
    />
);

export default EntityProtectionLevelSelector;