import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IDataGridColumnChildProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import * as Ui from "@CommonControls";
import TableTokenValueFormatterSettingStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TableTokenValueFormatterSettingStore";

interface ITableTokenValueFormatterSettingColumnWidthCellProps extends IDataGridColumnChildProps<TableTokenValueFormatterSettingStore, any> {
}

/** @screen */
@State.observer
export class TableTokenValueFormatterSettingColumnWidthCell extends React.Component<ITableTokenValueFormatterSettingColumnWidthCellProps> {
    @State.observable.ref private currentValidValue: string = null; 

    public componentDidMount() {
        this.setCurrentValidValue(this.props.row.columnWidth);
    }

    public render() {
        return <Ui.TextBox value={this.props.row.columnWidth} onChange={this.props.row.setColumnWidth} onBlur={this.validateValue} automationId="tableTokenValueFormatterSettingColumnTextBox" />;
    }

    @State.bound
    public validateValue() {
        const regex = new RegExp("^[1-9][0-9]*(%|pt)$");
        const regexNumber = new RegExp("^[1-9][0-9]*$");
        if (regexNumber.test(this.props.row.columnWidth)) {
            this.setCurrentValidValue(this.props.row.columnWidth.concat("pt"));
            this.props.row.setColumnWidth(this.currentValidValue);
        } else if (this.props.row.columnWidth === "*" || regex.test(this.props.row.columnWidth)) {
            this.setCurrentValidValue(this.props.row.columnWidth);
        } else {
            this.props.row.setColumnWidth(this.currentValidValue);
        }
    }

    @State.action.bound
    public setCurrentValidValue(newValue: string) {
        this.currentValidValue = newValue;
    }

}
