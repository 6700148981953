exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DocumentTemplateManagement_detail-sidebar_3gP7U {\n  flex-basis: 300px;\n  padding: 8px 8px 0 4px;\n  overflow: hidden;\n}\n.DocumentTemplateManagement_detail-sidebar_3gP7U .DocumentTemplateManagement_toolbox_1u9y9 {\n  flex: 1;\n  max-width: calc(300px - 12px);\n  overflow: hidden;\n}\n.DocumentTemplateManagement_detail-sidebar_3gP7U .DocumentTemplateManagement_token-usages_3sd7z {\n  flex: 1;\n  overflow: hidden;\n}\n.DocumentTemplateManagement_token-tree-item_3YbsY {\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n}\n.DocumentTemplateManagement_token-tree-item_3YbsY .DocumentTemplateManagement_token-tree-item__name_3cRJ3 {\n  flex: 1;\n  user-select: none;\n  line-height: 30px;\n}\n.DocumentTemplateManagement_token-tree-item_3YbsY .DocumentTemplateManagement_token-tree-item__buttons_1_Lqe {\n  flex: 0 0 auto;\n  padding-top: 1px;\n}\n", ""]);

// exports
exports.locals = {
	"detail-sidebar": "DocumentTemplateManagement_detail-sidebar_3gP7U",
	"detailSidebar": "DocumentTemplateManagement_detail-sidebar_3gP7U",
	"toolbox": "DocumentTemplateManagement_toolbox_1u9y9",
	"token-usages": "DocumentTemplateManagement_token-usages_3sd7z",
	"tokenUsages": "DocumentTemplateManagement_token-usages_3sd7z",
	"token-tree-item": "DocumentTemplateManagement_token-tree-item_3YbsY",
	"tokenTreeItem": "DocumentTemplateManagement_token-tree-item_3YbsY",
	"token-tree-item__name": "DocumentTemplateManagement_token-tree-item__name_3cRJ3",
	"tokenTreeItemName": "DocumentTemplateManagement_token-tree-item__name_3cRJ3",
	"token-tree-item__buttons": "DocumentTemplateManagement_token-tree-item__buttons_1_Lqe",
	"tokenTreeItemButtons": "DocumentTemplateManagement_token-tree-item__buttons_1_Lqe"
};