import StoreBase from "@Toolkit/CommonWeb/Model/StoreBase";
import DesignatedOrganizationId from "@Primitives/DesignatedOrganizationId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";

export default class DesignatedOrganization extends StoreBase {

    public id: DesignatedOrganizationId;
    public instituteCode: string;
    public instituteName: string;
    public workplaceCode: string;
    public validity: LocalDateRange;

    constructor() {
        super();
    }

}