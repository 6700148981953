import MedicationSubsidyId from "@Primitives/MedicationSubsidyId.g";
import MedicationId from "@Primitives/MedicationId.g";
import Money from "@Toolkit/CommonWeb/Model/Money";
import MedicationSubsidyTypeId from "@Primitives/MedicationSubsidyTypeId.g";
import StoreBase from "@Toolkit/CommonWeb/Model/StoreBase";
import MedicationSubsidyOption from "./MedicationSubsidyOption";
import MedicationSubsidizedPricingTypeId from "@Primitives/MedicationSubsidizedPricingTypeId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";

export default class MedicationSubsidy extends StoreBase {
    public id: MedicationSubsidyId;
    public medicationId: MedicationId;
    public dailyTherapyCost: Money;
    public grossSupport: Money;
    public netSupport: Money;
    public price: Money;
    public supportPercentage: number;
    public supportPeriodMonths: number;
    public medicationSubsidyTypeId: MedicationSubsidyTypeId;
    public options: MedicationSubsidyOption[];
    public pricingType: MedicationSubsidizedPricingTypeId;
    public supportGroupdId: number;
    public maxPrescribableAmount: number;
    public validity: LocalDateRange;

    constructor(
    ) {
        super();
    }
}