import DocumentInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentInfo";
import ScopeIdentifier from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ScopeIdentifier";
import { DocumentInfoDto as CareDocumentInfoDto } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import { DocumentInfoDto as DocumentManagementDocumentInfoDto } from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";

export function getDocumentInfo(dto: CareDocumentInfoDto | DocumentManagementDocumentInfoDto) {
    if (!dto) {
        return null;
    }
    
    const resp = new DocumentInfo(
        false,
        dto.id,
        dto.scopeIdentifiers?.map(si => new ScopeIdentifier(si.scope, si.identifier)),
        dto.documentTypeId ? new DocumentTypeId(dto.documentTypeId.value.toString()) : null,
        dto.documentKind,
        dto.createdById,
        dto.createdAt,
        dto.lastModifiedById,
        dto.lastModifiedAt,
        dto.documentState,
        dto.name,
        dto.possibleActions,
        dto.isPrimary,
        dto.metaProperties as any,
        dto.extensionData);

    resp.rowVersion = dto.rowVersion;
    return resp;
}
