import Di from "@Di";
import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import AppointmentCancellationReasonId from "@Primitives/AppointmentCancellationReasonId.g";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import AppointmentScheduleSlotSeriesId from "@Primitives/AppointmentScheduleSlotSeriesId.g";
import AppointmentScheduleSlotSeries from "./AppointmentSchedule";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import SchedulingConfigurationApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingConfigurationApiAdapter";
import AppointmentScheduleDefinitionId from "@Primitives/AppointmentScheduleDefinitionId.g";
import AppointmentScheduleDefinition from "./AppointmentScheduleDefinition";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";

@Di.injectable()
export default class SchedulingReferenceDataStore extends ReferenceDataStoreBase {
    public appointmentCancellationReasons = new ExtensibleEnumStore<AppointmentCancellationReasonId>(this.schedulingApiAdapter.loadAppointmentCancellationReasonIds, this.localizationService.localizeEnumId);
    public appointmentScheduleSlotSeries = new ReferenceDataStore<AppointmentScheduleSlotSeriesId, AppointmentScheduleSlotSeries>(this.schedulingApiAdapter.getAppointmentSchedulesByIds, this.schedulingApiAdapter.getAllAppointmentScheduleIdsAsync);
    public schedulingServices = new ReferenceDataStore<SchedulingServiceId, SchedulingServiceSubjectStore>(this.configurationApiAdapter.loadSchedulingServiceByIdsAsync, this.configurationApiAdapter.getAllSchedulingServiceIdsAsync);
    public appointmentScheduleDefinitions = new ReferenceDataStore<AppointmentScheduleDefinitionId, AppointmentScheduleDefinition>(this.configurationApiAdapter.getAppointmentScheduleDefinitionsByIdsAsync, this.configurationApiAdapter.getAllAppointmentScheduleDefinitionIdsAsync);

    constructor(
        @Di.inject("SchedulingApiAdapter") private readonly schedulingApiAdapter: SchedulingApiAdapter,
        @Di.inject("ILocalizationService") private localizationService: ILocalizationService,
        @Di.inject("SchedulingConfigurationApiAdapter") private configurationApiAdapter: SchedulingConfigurationApiAdapter,
        @Di.inject("IReferenceDataLoader") private readonly referenceDataLoader: IReferenceDataLoader
    ) {
        super();
        referenceDataLoader.register<AppointmentScheduleDefinitionId>(AppointmentScheduleDefinitionId.typeName, refs => this.appointmentScheduleDefinitions.ensureLoadedAsync(refs));
    }
    
    public getSchedulingServicesByIds(ids: SchedulingServiceId[]): SchedulingServiceSubjectStore[] {
        return ids.map(i => this.schedulingServices.get(i));
    }
}
