import LifeStyleRiskId from "@Primitives/LifeStyleRiskId.g";

export class LifeStyleRiskCodeSelectorItem {
    public id: LifeStyleRiskId;
    public name?: string;
    public code?: string;
    public text?: string;

    constructor({ id = null, name = "", code = "",  text = "" }: { id?: LifeStyleRiskId, name?: string, code?: string, text?: string }) {
        this.id = id;
        this.name = name;
        this.text = text;
        this.code = code;
    }
}