import Di from "@Di";
import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import CustomerSelector from "@HisPlatform/Components/HisPlatformControls/CustomerSelector/CustomerSelector";
import CustomerId from "@Primitives/CustomerId.g";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import CustomerReferenceDataStore from "./ApplicationLogic/Model/Invoicing/CustomerReferenceDataStore";
import DependencyContainer from "@DiContainer";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";

@Di.injectable()
export default class FinanceBoundedContext implements IBoundedContext {
    initializeAsync(): Promise<void> {
        return Promise.resolve();
    }

    public configureFormEngine(registry: FormEditorRegistry): void {

        registry.registerEntityReferenceEditor(
            "Customer",
            {
                displayName: StaticFinanceResources.Customers.CustomerSelector.Title,
                componentType: CustomerSelector,
                entityIdCtor: CustomerId,
                loadReferenceDataAsync: (id: CustomerId | CustomerId[]) => {
                    if (Array.isArray(id)) {
                        DependencyContainer.get<CustomerReferenceDataStore>("CustomerReferenceDataStore").customers.ensureLoadedAsync(id);
                    } else {
                        DependencyContainer.get<CustomerReferenceDataStore>("CustomerReferenceDataStore").customers.ensureLoadedAsync([id]);
                    }
                    return Promise.resolve();
                }
            }
        );
    }

    public configureActivities(activityRegistry: IActivityRegistry) {
        activityRegistry.register({
            reference: "Invoicing_ViewInvoices",
            iconName: "fileInvoiceSolid"
        });
    }
}