import Di from "@Di";
import _ from "@HisPlatform/Common/Lodash";
import IServiceRequestClinicalQuestionExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestClinicalQuestionExtensionPointProps";
import IServiceRequestHeaderExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestHeaderExtensionPointProps";
import IServiceRequestReferralExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestReferralExtensionPointProps";
import CareActivityId from "@Primitives/CareActivityId.g";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import State, { IObservableArray, IReactionDisposer } from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import moment, { Moment } from "moment";
import { IServiceRequestScreenProps } from "./ServiceRequestScreen";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import MomentHelper from "@Toolkit/CommonWeb/MomentHelper";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import ServiceRequestScreenApiAdapter from "./ServiceRequestScreenApiAdapter";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import LockAcquirerOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockAcquirerOperationInfo";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import RequestedServiceId from "@Primitives/RequestedServiceId.g";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import ServiceRequestDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestDefinition";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import CareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CareActivityStore";
import ExtensionController from "@HisPlatform/Components/HisPlatformExtensionPoint/ExtensionController";
import ServiceProviderProfile from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/ServiceProviderProfile";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import IClientSessionService from "@HisPlatform/Services/Definition/ClientSession/IClientSessionService";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import DiagnosisListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisListStore";
import SuspectedDiagnosis from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/SuspectedDiagnosis";
import DiagnosisStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisStore";
import ReferralStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/ReferralStore";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ServiceRequestWithAppointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ServiceRequestWithAppointment";
import IOperationResult from "@Toolkit/CommonWeb/ApiAdapter/IOperationResult";
import { ITargetDataPageBoxProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestPanel/TargetDataPageBox";
import { IReferralDataPageBoxProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestPanel/ReferralDataPageBox";
import HisPermissionScopes from "@HisPlatform/Common/FrontendActions/HisPermissionScopes";
import SaveServiceRequestAction from "@HisPlatform/Packages/ServiceRequest/FrontendActions/SaveServiceRequestAction.g";
import ServiceRequestSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ServiceRequestSubjectStore";
import ShowServiceRequestScreenAction from "@HisPlatform/Packages/ServiceRequest/FrontendActions/ShowServiceRequestScreenAction.g";
import ShowCreateNewServiceRequestScreenAction from "@HisPlatform/Packages/ServiceRequest/FrontendActions/ShowCreateNewServiceRequestScreenAction.g";
import IServiceRequestDefinitionDialogResult from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestDefinitionDialog/IServiceRequestDefinitionDialogResult";
import ServiceRequestDefinitionDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestDefinitionDialog/ServiceRequestDefinitionDialogParams";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import CareActivityIsAlreadyAServiceRequestExecutingCareActivityError from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/CareActivityIsAlreadyAServiceRequestExecutingCareActivityError";
import ServiceRequestAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestAction.g";
import ServiceRequestAppointmentModalParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestAppointmentModal/ServiceRequestAppointmentModalParams";
import MedicalServicePriority from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalServices/Enum/MedicalServicePriority.g";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";
import ServiceRequestState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestState.g";
import RequestedServiceAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceAction.g";
import AppointmentStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentStatus.g";
import PropertyGroup from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyGroup";

@Di.injectable()
export default class ServiceRequestScreenStore extends EditorScreenPanelStoreBase<IServiceRequestScreenProps> implements ILoadablePanelStore {
    @State.observable.ref public serviceRequestDefinition: ServiceRequestDefinition = null;
    @State.observable.ref public serviceRequest: ServiceRequestStore = null;
    @State.observable.ref public medicalServiceIds: Array<IEntityVersionSelector<MedicalServiceId>> = null;
    @State.observable.ref public currentCareActivity: CareActivityStore = null;
    @State.observable public isSuspectedDiagnosisLoading: boolean = false;
    @State.observable public isDeleteAllowed: boolean = false;
    @State.observable public isEditingAllowed: boolean = false;
    @State.observable public isRenewAllowed: boolean = false;
    @State.observable public isSubmitAllowed: boolean = false;
    @State.observable public textBoxHeight: string = "auto";
    public readonly validateAllEvent = new TypedEvent();
    private referralExtensionController = new ExtensionController();
    private requestingPointOfCareDynamicProperties: PropertyGroup[] = [];
    @State.observable.ref private targetCompletionDate: moment.Moment = DateTimeService.now();
    @State.observable.ref private previousTargetCompletionDate: moment.Moment = DateTimeService.now();
    @State.observable.ref private availablePriorities: MedicalServicePriority[] = null;
    @State.observable.ref private priority: MedicalServicePriority = null;
    @State.observable.ref private tempExtensionData: any = null;
    @State.observable.ref private serviceProviderProfile: ServiceProviderProfile = null;
    @State.observable.ref private appointmentStore: Appointment = null;
    private reactionDisposer: IReactionDisposer = null;

    constructor(
        @Di.inject("IDialogService") protected readonly dialogService: IDialogService,
        @Di.inject("IToolkitLocalizationService") public readonly localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") public readonly lockingApiAdapter: LockingApiAdapter,
        @Di.inject("INotificationService") protected readonly notificationService: INotificationService,
        @Di.inject("AuthorizationService") public readonly authorizationService: AuthorizationService,
        @Di.inject("IClientSessionService") public readonly clientSessionService: IClientSessionService,
        @Di.inject("IDateTimeFormatProvider") public readonly dateTimeFormatProvider: IDateTimeFormatProvider,
        @Di.inject("CareReferenceDataStore") public readonly careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("OrganizationReferenceDataStore") public readonly organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("ServiceRequestScreenApiAdapter") public readonly apiAdapter: ServiceRequestScreenApiAdapter) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    public readonly onValidateAsync = this.backgroundAsync(async () => {
        if (this.isLoading) { return []; }
        let validationResults: IClientValidationResult[];
        if (this.isAppointmentRequired && !!this.appointmentStore) {
            const response = await this.apiAdapter.validateServiceRequestWithAppointmentAsync(
                this.serviceRequest,
                this.appointmentStore,
                this.isServiceRequestEmpty
            );
            validationResults = response.result.validationResults;
        } else {
            const response = await this.apiAdapter.validateServiceRequestAsync(
                this.serviceRequest,
                this.isServiceRequestEmpty
            );
            validationResults = response.result;
        }
        return validationResults;
    });
    
    private readonly createEmptyServiceRequest = (
        serviceRequestDefinitionVersion: IEntityVersionSelector<ServiceRequestDefinitionId>,
        direction: ServiceRequestDirection,
        diagnosisList: DiagnosisListStore
    ) => {
        const serviceRequest = new ServiceRequestStore(true);
        serviceRequest.direction = direction;
        serviceRequest.isExternal = direction === ServiceRequestDirection.InternalToExternal;
        if (serviceRequest.isExternal) {
            serviceRequest.targetExternalLocationVersionSelector = new EntityVersionSelector(null, LocalDate.today());
        }

        if (direction === ServiceRequestDirection.ExternalToInternal) {
            serviceRequest.requesterDoctorId = this.props._careActivityBaseData.referral.referralDoctorId;
            serviceRequest.targetDoctorId = this.props._careActivityBaseData.primaryParticipant;
        } else {
            serviceRequest.requesterDoctorId = this.props._careActivityBaseData.primaryParticipant;
        }

        serviceRequest.setServiceRequestDefinitionVersion(serviceRequestDefinitionVersion);
        serviceRequest.setStarterCareActivityId(this.props._careActivityId);
        serviceRequest.setReferralCareIdentifier(this.props._careActivityBaseData.primaryCareIdentifier);
        serviceRequest.setCreatedAt(DateTimeService.now());

        this.initializeSuspectedDiagnosisListAsync(serviceRequest, diagnosisList);
        return serviceRequest;
    };

    private readonly initializeSuspectedDiagnosisListAsync = (serviceRequest: ServiceRequestStore, recorderDiagnosisStore: DiagnosisListStore) => {
        if (!this.props._careActivityId) {
            return;
        }

        this.isSuspectedDiagnosisLoading = true;

        const diagnosisRoleOrderMap = {
            [DiagnosisRoleId.Discharge.value]: 0,
            [DiagnosisRoleId.Comorbity.value]: 1,
            [DiagnosisRoleId.Admission.value]: 2,
        };

        let observableSuspectedDiagnosisList: IObservableArray<SuspectedDiagnosis> = State.observable<SuspectedDiagnosis>([]);
        if (!isNullOrUndefined(recorderDiagnosisStore?.diagnoses)) {
            const suspectedDiagnosisList = _
                .sortBy<DiagnosisStore>(recorderDiagnosisStore.diagnoses, r => diagnosisRoleOrderMap[r.use.value])
                .slice(0, 4)
                .map(i => {
                    const diagnosis = new SuspectedDiagnosis();
                    diagnosis.lateralityId = i.lateralityId;
                    diagnosis.conditionId = i.conditionVersionSelector.id;
                    return diagnosis;
                });
            observableSuspectedDiagnosisList = State.observable<SuspectedDiagnosis>(suspectedDiagnosisList);
        }

        State.runInAction(() => {
            serviceRequest.setSuspectedDiagnosisList(observableSuspectedDiagnosisList);

            if (serviceRequest.suspectedDiagnosisList.length === 0) {
                serviceRequest.suspectedDiagnosisList.push(new SuspectedDiagnosis());
            }

            this.isSuspectedDiagnosisLoading = false;
        });
    };

    private readonly processAppointmentResponseAsync = this.async(async (
        appointmentResponse: Appointment,
        referralLocationId?: ExternalLocationId,
        isInitialLoad?: boolean
    ) => {
        if (!isInitialLoad && !appointmentResponse.isPersistedByOperationInfo) {
            this.appointmentStore.validationResults = appointmentResponse.validationResults;
        } else if (appointmentResponse.isNew) {
            await this.setNewAppointmentStoreAsync(referralLocationId);
        } else {
            State.runInAction(() => {
                this.appointmentStore = appointmentResponse;
            });
        }
        this.resetCompletionDate(this.appointmentStore.intervalFrom);
    });

    private readonly getNewAppointmentReferralDataAsync = this.async(async (referralLocationId: ExternalLocationId) => {
        const newReferralData = new ReferralStore();

        newReferralData.setCreatedAt(LocalDate.createFromMoment(this.serviceRequest.createdAt));
        newReferralData.setReferralCareIdentifier(this.serviceRequest.referralCareIdentifier);
        newReferralData.setDoctorId(this.serviceRequest.requesterDoctorId);
        newReferralData.setWorkPlace(referralLocationId ?? await this.getAppointmentReferralLocationAsync());
        this.setDiagnosesForReferral(newReferralData);

        return newReferralData;
    });

    private readonly getAppointmentReferralLocationAsync = this.async(async () => {
        const organizationUnitId = new OrganizationUnitId((this.currentCareActivity?.pointOfCareId ?? this.props._careActivity.pointOfCareId).value);
        const externalLocationIds = await this.apiAdapter.getExternalLocationsOfOrganizationUnitsAsync([organizationUnitId], LocalDate.today());
        const referralLocationId = externalLocationIds.result.length ? externalLocationIds.result[0] : null;
        return referralLocationId;
    });

    private readonly setNewAppointmentStoreAsync = this.async(async (referralLocationId?: ExternalLocationId) => {
        const newAppointment = new Appointment(true);

        const schedulingService = await this.getServiceRequestAsSchedulingServiceAsync();
        newAppointment.patientId = this.props._patientId;
        newAppointment.setPointOfCareId(this.serviceRequest.targetPointOfCareId);
        newAppointment.setSubjectService(schedulingService.id);

        const newReferralData = await this.getNewAppointmentReferralDataAsync(referralLocationId);
        newAppointment.referral = newReferralData;

        State.runInAction(() => {
            this.appointmentStore = newAppointment;
        });
    });

    private readonly ensureAllLoadedAsync = this.async(async (serviceRequest: ServiceRequestStore) => {
        if (!serviceRequest) {
            return;
        }

        if (serviceRequest.isExternal) {
            if (serviceRequest.targetExternalLocationVersionSelector) {
                await this.organizationReferenceDataStore.externalLocationStore.ensureLoadedAsync([serviceRequest.targetExternalLocationVersionSelector]);
            }
        } else {
            if (serviceRequest.targetPointOfCareId) {
                await this.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync([serviceRequest.targetPointOfCareId]);
            }
        }
        if (serviceRequest.referralLocation instanceof ExternalCareLocation) {
            await this.organizationReferenceDataStore.externalLocationStore.ensureLoadedAsync([serviceRequest.referralLocation.externalLocationSelector]);
        } else if (serviceRequest.referralLocation instanceof InternalCareLocation) {
            await this.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync([serviceRequest.referralLocation.pointOfCareId]);
        }
        const definition = await this.careReferenceDataStore.serviceRequestDefinition.getOrLoadAsync(serviceRequest.serviceRequestDefinitionVersion);
        await this.organizationReferenceDataStore.externalLocationStore.ensureLoadedAsync(definition.externalLocations);
    });

    private readonly loadServiceProviderProfileAsync = this.async(async () => {
        if (this.serviceRequest.targetCareLocation) {
            const response = await this.apiAdapter.getServiceProviderProfileByLocationAsync(this.serviceRequest.targetCareLocation);
            this.processServiceProviderProfile(response.result);
        }
    });

    private readonly onValidateAppointmentAsync = this.async(async () => {
        const response = await this.apiAdapter.validateAppointmentAsync(this.appointmentStore);
        return response.result;
    });

    public readonly openForEditAsync = this.async(() => this.loadServiceRequestAsync(true));

    public readonly renewServiceRequestAsync = this.async(async () => {
        const extensionDataArray = await this.props._formExtension.invokeCallbackAsync<any>("ExtendServiceRequestForDelete", this.serviceRequest);
        let extensionDataForDelete = {};
        for (const extensionData of extensionDataArray) {
            extensionDataForDelete = { ...extensionDataForDelete, ...extensionData };
        }

        let response;
        if (this.appointmentStore?.id) {
            response = await this.apiAdapter.renewSubmittedServiceRequestWithAppointmentAsync(
                this.serviceRequest.id,
                this.props._careActivityId,
                this.serviceRequest.rowVersion,
                true,
                this.lockInfo.lockId,
                extensionDataForDelete,
                this.appointmentStore.id,
                StaticWebAppResources.NewServiceRequestPage.AppointmentCancellationNote
            );
        } else {
            response = await this.apiAdapter.renewSubmittedServiceRequestAsync(
                this.serviceRequest.id,
                this.props._careActivityId,
                this.serviceRequest.rowVersion,
                true,
                this.lockInfo.lockId,
                extensionDataForDelete
            );
        }

        this.setTempServiceRequestExtensionData(response.result.extensionData);

        if (response.result.operationWasSuccessful) {
            this.notificationService.info(StaticWebAppResources.NewServiceRequestPage.ServiceRequestRenew);

            this.props._screenState.savedExisting();
        }
    });

    public readonly deleteServiceRequestAsync = this.async(async () => {
        const dialogResult = await this.dialogService.yesNo(
            StaticWebAppResources.Common.DialogTitle.ConfirmationTitle,
            StaticWebAppResources.NewServiceRequestPage.ServiceRequestDeleteConfirmation
        );

        if (dialogResult.resultCode === DialogResultCode.Yes) {
            let hasErrors = false;
            if (this.serviceRequest.state === ServiceRequestState.UnderRecording) {
                hasErrors = await this.deleteDraftServiceRequestAsync();
            } else {
                hasErrors = await this.executeDeleteForRequestedServicesAsync();
            }

            this.props._screenState.savedExisting();
        }
    });

    private processServiceProviderProfile(serviceProviderProfile: ServiceProviderProfile) {
        if (!serviceProviderProfile) {
            return;
        }

        this.setServiceProviderProfile(serviceProviderProfile);
        this.setAvailablePriorities(serviceProviderProfile.getAvailablePriorities());

        if (!isNullOrUndefined(this.priority)) {
            State.runInAction(() => {
                const idsByPriority = serviceProviderProfile.getMedicalServiceIdsByPriority(this.priority);
                const filteredIds = this.serviceRequestDefinition.medicalServiceIds.filter(i => idsByPriority.some(j => ValueWrapper.equals(i, j)));

                this.medicalServiceIds = filteredIds.map(i => new EntityVersionSelector(i, LocalDate.today()));
            });
        }

        if (isNullOrUndefined(this.priority)) {
            if (this.availablePriorities.some(i => i === MedicalServicePriority.Normal)) {
                this.resetPriority(MedicalServicePriority.Normal);
            }
        }
    }

    private readonly changePriorityAsync = this.async(async (priority: MedicalServicePriority) => {
        State.runInAction(() => {
            this.priority = priority;
        });
        await this.loadServiceProviderProfileAsync();
    });

    private readonly saveAndNotifyAsync: (shouldSubmitServiceRequest: boolean) => Promise<boolean> = this.async(async (shouldSubmitServiceRequest) => {
        const isNew = this.serviceRequest.isNew;
        const isSaved = await this.saveAsync(shouldSubmitServiceRequest);
        if (isSaved) {
            if (isNew) {
                this.props._screenState.savedNew(this.serviceRequest.id);
            } else {
                this.props._screenState.savedExisting();
            }
        }

        return isSaved;
    });

    private readonly saveAsync: (shouldSubmitServiceRequest: boolean) => Promise<boolean> = this.async(async (shouldSubmitServiceRequest) => {
        if (!this.serviceRequest) {
            return true;
        }

        this.setPriorityAndExpectedCompletionTimeOfServices(this.serviceRequest.requestedServices);

        if (this.isAppointmentRequired) {
            const result = await this.handleSaveResultAsync<ServiceRequestWithAppointment>(
                () => this.saveWithAppointmentAsync(shouldSubmitServiceRequest)
            );
            return result.isPersisted;
        }

        const result = await this.handleSaveResultAsync<ServiceRequestStore>(
            () => this.saveWithoutAppointmentAsync(shouldSubmitServiceRequest)
        );
        return result.isPersisted;
    });

    private readonly saveWithoutAppointmentAsync = this.async(async (shouldSubmitServiceRequest: boolean) => {
        let response: IOperationResult<ServiceRequestStore>;

        if (!this.serviceRequest.isPersistedByOperationInfo) {
            response = await this.apiAdapter.createServiceRequestAsync(
                this.serviceRequest,
                shouldSubmitServiceRequest,
                !shouldSubmitServiceRequest,
                this.isServiceRequestEmpty
            );
            this.setTempServiceRequestExtensionData(response.result.extensionData);
        } else if (!this.appointmentStore?.id) {
            response = await this.apiAdapter.modifyServiceRequestAsync(
                this.serviceRequest,
                shouldSubmitServiceRequest,
                this.props._careActivityId,
                this.lockInfo.lockId,
                false,
                this.isServiceRequestEmpty
            );
        } else {
            response = await this.apiAdapter.modifyServiceRequestWithDeleteAppointmentAsync(
                this.props._careActivityId,
                this.serviceRequest,
                this.appointmentStore.id,
                this.appointmentStore.rowVersion,
                shouldSubmitServiceRequest,
                this.lockInfo.lockId,
                false
            );
        }
        this.processServiceRequestResponse(response.result, true, true);

        if (response.result.hasValidationError) {
            State.runInAction(() => {
                this.serviceRequest.validationResults = response.result.validationResults;
            });
        }

        if (shouldSubmitServiceRequest && this.serviceRequest.state === ServiceRequestState.UnderRecording) {
            this.notificationService.error(StaticCareResources.ServiceRequestManagement.Messages.SubmitFailedBecauseErrors);
        }

        return response;
    });

    private readonly saveWithAppointmentAsync = (async (shouldSubmitServiceRequest: boolean) => {
        let response: IOperationResult<ServiceRequestWithAppointment>;

        if (!this.serviceRequest.isPersistedByOperationInfo) {
            response = await this.apiAdapter.createServiceRequestWithAppointmentAsync(
                this.serviceRequest,
                this.appointmentStore,
                shouldSubmitServiceRequest,
                !shouldSubmitServiceRequest,
                this.isServiceRequestEmpty
            );
            this.setTempServiceRequestExtensionData(response.result.serviceRequest.extensionData);
        } else if (this.appointmentStore.isNew) {
            response = await this.apiAdapter.modifyServiceRequestWithCreateAppointmentAsync(
                this.props._careActivityId,
                this.serviceRequest,
                this.appointmentStore,
                shouldSubmitServiceRequest,
                this.lockInfo?.lockId,
                false
            );
        } else {
            response = await this.apiAdapter.modifyServiceRequestWithUpdateAppointmentAsync(
                this.props._careActivityId,
                this.serviceRequest,
                this.appointmentStore,
                shouldSubmitServiceRequest,
                this.lockInfo?.lockId,
                false
            );
        }
        this.processServiceRequestResponse(response.result.serviceRequest, true, true);
        await this.processAppointmentResponseAsync(response.result.appointment, null, false);

        if (shouldSubmitServiceRequest && this.serviceRequest.state === ServiceRequestState.UnderRecording) {
            this.notificationService.error(StaticCareResources.ServiceRequestManagement.Messages.SubmitFailedBecauseErrors);
        }

        return response;
    });

    private readonly deleteDraftServiceRequestAsync = this.async(async () => {
        if (!!this.appointmentStore?.id) {
            const deleteRequestAndAppointmentResponse = await this.apiAdapter.deleteDraftServiceRequestWithAppointmentAsync(
                this.appointmentStore.id,
                this.appointmentStore.rowVersion,
                this.serviceRequest.id,
                this.props._careActivityId,
                this.serviceRequest.rowVersion,
                this.lockInfo.lockId,
                true
            );
            this.setLockInfo(deleteRequestAndAppointmentResponse.result.serviceRequest.lockInfo); // always NULL
            this.serviceRequest.takeSnapshot();
        } else {
            const deleteRequestResponse = await this.apiAdapter.deleteDraftServiceRequestAsync(
                this.serviceRequest.id,
                this.props._careActivityId,
                this.serviceRequest.rowVersion,
                this.lockInfo.lockId,
                true
            );
            this.setLockInfo(deleteRequestResponse.result.lockInfo); // always NULL
            this.serviceRequest.takeSnapshot();
        }

        this.notificationService.showSavedSuccessfully();
        return true;
    });

    private readonly executeDeleteForRequestedServicesAsync = this.async(async () => {
        const extensionDataArray =
            await this.props._formExtension.invokeCallbackAsync<any>("ExtendServiceRequestForDelete", this.serviceRequest);

        let extensionDataForDelete = {};
        for (const extensionData of extensionDataArray) {
            extensionDataForDelete = { ...extensionDataForDelete, ...extensionData };
        }

        const response = await this.apiAdapter.executeRequestedServiceActionsAsync(
            this.props._careActivityId,
            this.serviceRequest.id,
            this.serviceRequest.rowVersion,
            this.serviceRequest.requestedServices?.length ? this.serviceRequest.requestedServices.map(i => ({
                id: i.id,
                action: RequestedServiceAction.DeleteForRequester
            })) : [{
                id: new RequestedServiceId("0"),
                action: RequestedServiceAction.DeleteForRequester
            }],
            this.lockInfo,
            true,
            extensionDataForDelete
        );

        this.setServiceRequestStore(response.result);
        this.setLockInfo(response.result.lockInfo); // always NULL
        this.serviceRequest.takeSnapshot();

        const hasErrorsArray = await this.props._formExtension.invokeCallbackAsync<boolean>("CheckServiceRequestForErrors", this.serviceRequest);

        this.notificationService.showSaveResult(response.operationInfo.isPersisted);

        if (hasErrorsArray?.length) {
            return true;
        } else {
            return !response.operationInfo.isPersisted;
        }
    });

    @State.computed
    public get serviceRequestId(): ServiceRequestId {
        return this.showScreenAction instanceof ShowServiceRequestScreenAction
            ? this.showScreenAction.serviceRequestId
            : null;
    }

    @State.computed
    public get careActivityId(): CareActivityId {
        return this.props._careActivityId;
    }

    @State.computed
    public get medicalServiceItems() {
        return this.serviceRequest?.requestedServices;
    }

    @State.computed
    public get clinicalQuestionValue() {
        return this.serviceRequest?.clinicalQuestion;
    }

    @State.computed
    public get onClinicalQuestionChange() {
        return this.serviceRequest?.setClinicalQuestion;
    }

    @State.computed
    public get isClinicalQuestionAllowed() {
        return this.serviceRequestDefinition?.clinicalQuestionAllowed;
    }

    @State.computed
    public get isAdministration() {
        return this.serviceRequest.executingCareActivityId
               && ValueWrapper.equals(this.props._careActivityId, this.serviceRequest.executingCareActivityId);
    }

    @State.computed
    public get remarkValue() {
        return this.serviceRequest?.remark;
    }

    @State.computed
    public get onRemarkChange() {
        return this.serviceRequest?.setRemark;
    }

    @State.computed
    public get serviceRequestCreatedAt() {
        return !!this.serviceRequest
            ? this.localizationService.localizeDateTime(this.serviceRequest.createdAt)
            : "";
    }

    @State.computed
    public get currentPatientGenderId() {
        return this.props._patient?.baseData.genderId;
    }

    @State.computed
    public get canAddNewMedicalService() {
        return !isNullOrUndefined(this.priority) && !isNullOrUndefined(this.serviceRequest.targetCareLocation);
    }

    @State.computed
    public get headerExtensionPanelProps(): IServiceRequestHeaderExtensionPointProps {
        return {
            extensionData: this.extensionData,
            initializeExtensionData: this.initializeExtensionData
        } as IServiceRequestHeaderExtensionPointProps;
    }

    @State.computed
    public get referralExtensionPanelProps(): IServiceRequestReferralExtensionPointProps {
        return {
            serviceRequest: this.serviceRequest,
            patientId: this.props._patientId,
            extensionController: this.referralExtensionController,
            initializeExtensionData: this.initializeExtensionData,
            externalLocationId: this.serviceRequest?.targetExternalLocationVersionSelector?.id,
            requestingPointOfCareDynamicProperties: this.requestingPointOfCareDynamicProperties,
            serviceRequestDefinition: this.serviceRequestDefinition,
            onExtensionDataChange: this.serviceRequest.setExtensionData,
            executingPointOfCareId: this.serviceRequest?.targetPointOfCareId
        } as unknown as IServiceRequestReferralExtensionPointProps;
    }

    @State.computed
    public get referralDataViewProps(): IReferralDataPageBoxProps {
        return {
            careIdentifier: this.serviceRequest.referralCareIdentifier,
            serviceRequestDefinitionDirection: this.serviceRequest.direction,
            requesterDoctorId: this.serviceRequest.requesterDoctorId,
            onRequesterDoctorIdChange: this.serviceRequest.setRequesterDoctorId,
            referralCreationDateTime: this.serviceRequest.createdAt,
            requesterPointOfCareId: this.getReferralPointOfCare() ?? this.currentCareActivity?.pointOfCareId ?? this.props._careActivity?.pointOfCareId,
            referral: this.serviceRequest.referralLocation
        };
    }

    @State.computed
    public get targetDataViewProps(): ITargetDataPageBoxProps {
        return {
            availableExternalLocationVersionSelectors: this.isExternalCareLocation ? this.serviceRequestDefinition.externalLocations : null,
            availablePointOfCareIds: this.isExternalCareLocation ? null : this.serviceRequestDefinition.internalLocationsAsPointOfCareIds,
            externalLocationVersionSelector: this.serviceRequest.targetExternalLocationVersionSelector,
            isExternalCareLocation: this.isExternalCareLocation,
            onExternalLocationVersionSelectorChange: this.setTargetExternalLocationVersionSelector,
            pointOfCareId: this.serviceRequest.targetPointOfCareId,
            onPointOfCareIdChange: this.setTargetPointOfCareId,
            targetCompletionAt: this.targetCompletionDate,
            onTargetCompletionDateChange: this.changeTargetCompletionDate,
            targetDoctorId: this.serviceRequest.targetDoctorId,
            onTargetDoctorIdChange: this.serviceRequest.setTargetDoctorId,
            priority: this.priority,
            onPriorityChange: this.changePriorityAsync,
            availablePriorities: this.availablePriorities,
            readOnlyMode: this.vIsReadOnly,
            isTargetCareLocationEditingAllowed: !this.isEditingAllowed || this.serviceRequest.isNew,

            isAppointmentRequired: this.isAppointmentRequired,
            onOpenAppointmentModal: this.isAppointmentEditingAllowed ? this.openAppointmentModal : null,
            dateTimeFormatProvider: this.dateTimeFormatProvider
        };
    }

    @State.computed
    public get clinicalQuestionExtensionPanelProps(): IServiceRequestClinicalQuestionExtensionPointProps {
        const referralPointOfCare = this.getReferralPointOfCare();

        return {
            serviceRequest: this.serviceRequest,
            textBoxHeight: this.textBoxHeight,
            onTextBoxHeightChange: this.onTextBoxHeightChange,
            pointOfCareId: referralPointOfCare ?? this.currentCareActivity?.pointOfCareId ?? this.props._careActivity?.pointOfCareId,
            requestingPointOfCareDynamicProperties: this.requestingPointOfCareDynamicProperties,
        } as unknown as IServiceRequestClinicalQuestionExtensionPointProps;
    }

    @State.computed
    public get vIsReadOnly(): boolean {
        return (!this.lockInfo || this.lockInfo.lockState !== EntityLockState.LockingRequiredAndLockHeld)
                && this.serviceRequest?.isNew !== true;
    }

    @State.computed
    public get saveServiceRequestAction() {
        return ActionDescriptor.fromAction(
            new SaveServiceRequestAction(),
            HisPermissionScopes.pointOfCare(
                this.currentCareActivity?.pointOfCareId?.value
                ?? this.props._careActivity?.pointOfCareId?.value
            )
        );
    }

    @State.computed
    public get hasPermissionForEditing() {
        return this.authorizationService.hasPermissionForDescriptor(this.saveServiceRequestAction);
    }

    @State.computed
    private get extensionData() {
        if (!isNullOrUndefined(this.tempExtensionData)) {
            return this.tempExtensionData;
        }
        return this.serviceRequest?.extensionData;
    }

    @State.computed
    private get isAppointmentRequired() {
        if (isNullOrUndefined(this.serviceProviderProfile) ||
            isNullOrUndefined(this.serviceRequest) ||
            this.serviceRequest.isExternal
        ) {
            return false;
        }
        const executionCapabilities = this.serviceRequest.requestedServices
            .map(rs => this.serviceProviderProfile.GetMedicalServiceExecutionCapabilityByMedicalServiceId(rs.medicalServiceVersionSelector.id)
            );
        return executionCapabilities.some(e => e?.appointmentNeeded);
    }

    @State.computed
    private get isServiceRequestEmpty() {
        return !this.serviceRequestDefinition?.medicalServiceIds.length;
    }

    @State.computed
    private get isExternalCareLocation() {
        if (isNullOrUndefined(this.serviceRequest?.direction)) {
            return this.serviceRequest.targetCareLocation instanceof ExternalCareLocation;
        } else {
            return this.serviceRequest?.direction === ServiceRequestDirection.InternalToExternal;
        }
    }

    @State.computed
    private get isAppointmentEditingAllowed() {
        return (
            !!this.appointmentStore &&

            (this.appointmentStore.isNew ||
                this.appointmentStore.status === AppointmentStatus.Booked) &&

            !this.vIsReadOnly &&
            this.isEditingAllowed
        );
    }

    @State.bound
    private async getServiceRequestAsSchedulingServiceAsync() {
        const store = new ServiceRequestSubjectStore();
        store.id = this.serviceRequest.id;
        store.setDefinitionId(this.serviceRequestDefinition.id);
        store.setCode(this.serviceRequestDefinition.code.value);
        store.setName(this.serviceRequestDefinition.shortName);
        store.setDurationInMinutes(await this.getServiceRequestDurationAsync());

        return store;
    }

    protected get contentToDirtyCheck(): any[] {
        return [
            this.priority,
            this.serviceRequest.isDirty(),
            this.referralExtensionController.isDirty(),
            !MomentHelper.areEquals(this.targetCompletionDate, this.previousTargetCompletionDate)
        ];
    }

    protected saveFunction: () => Promise<boolean> = () => this.saveAndNotifyAsync(false);

    protected get showScreenAction(): ShowServiceRequestScreenAction | ShowCreateNewServiceRequestScreenAction {
        return this.props.action;
    }

    public async loadCoreAsync(): Promise<void | { loadedSignals?: string[]; }> {
        await this.loadServiceRequestAsync(false);
    }

    @State.bound
    public async onSaveAsync() {
        await this.saveAndNotifyAsync(false);
    }

    @State.bound
    public async onSaveAndSubmitAsync() {
        await this.saveAndNotifyAsync(true);
    }

    @State.bound
    public unload() {
        if (this.reactionDisposer) {
            this.reactionDisposer();
            this.reactionDisposer = null;
        }

        super.unload();
    }

    public readonly loadServiceRequestAsync = this.async(async (forceReleaseLock: boolean) => {
        const response = await this.apiAdapter.getServiceRequestScreenDataAsync(
            this.careActivityId,
            this.serviceRequestId,
            forceReleaseLock
        );
        if (response.operationInfo instanceof LockAcquirerOperationInfo) {
            this.setLockInfo(this.lockInfo = (response.operationInfo as LockAcquirerOperationInfo).lockInfo);
        }

        let serviceRequest = response.result.serviceRequest;
        if (!!serviceRequest) {
            this.resetCompletionDate(serviceRequest.expectedCompletionTime);
        } else {
            const result = await this.modalService.showDialogAsync<IServiceRequestDefinitionDialogResult>(new ServiceRequestDefinitionDialogParams());
            if (!result) {
                this.props._screenState.cancelled();
                return;
            }

            serviceRequest = this.createEmptyServiceRequest(
                result.definitionId,
                result.direction,
                response.result.diagnosisList
            );
        }
        await this.ensureAllLoadedAsync(serviceRequest);
        this.processServiceRequestResponse(serviceRequest);
        this.processServiceProviderProfile(response.result.serviceProviderProfileVersion);
        this.setPriorityAndExpectedCompletionTimeOfServices(this.serviceRequest.requestedServices);
        this.vSetValidationResults(this.serviceRequest.validationResults);

        if (this.serviceRequest.isNew) {
            State.runInAction(() => {
                this.currentCareActivity = response.result.starterCareActivity;
                this.tempExtensionData = this.clientSessionService.getAndClearTempData("serviceRequestExtensionData");
            });
        }
        State.runInAction(() => {
            this.requestingPointOfCareDynamicProperties = response.result.dynamicProperties;
        });
        if (this.isAppointmentRequired) {
            await this.processAppointmentResponseAsync(response.result.appointment, response.result.referralLocationId, true);
        }
    });

    @State.bound
    public checkAppointmentRequired() {
        return this.isAppointmentRequired;
    }

    @State.bound
    public checkSuspectedDiagnosesList() {
        return this.serviceRequest.suspectedDiagnosisList.map(d => d.conditionId);
    }

    @State.bound
    public checkRequesterDoctor() {
        return this.serviceRequest.requesterDoctorId;
    }

    @State.bound
    public handleCareActivityIsAlreadyAServiceRequestExecutingCareActivityError() {
        this.notificationService.error(StaticWebAppResources.NewServiceRequestPage.CareActivityIsAlreadyAServiceRequestExecutingCareActivityError);
        return true;
    }

    @State.bound
    public setAppointmentStore() {
        if (this.isAppointmentRequired) {
            if (!this.appointmentStore) {
                this.setNewAppointmentStore();
            }
            this.changeTargetCompletionDate(this.appointmentStore?.intervalFrom);
        } else if (!!this.appointmentStore) {
            this.deleteAppointmentStore();
            this.changeTargetCompletionDate(DateTimeService.now());
        }
    }

    @State.bound
    public setAppointmentReferralDoctorId() {
        if (!!this.appointmentStore?.referral) {
            this.appointmentStore.referral.setDoctorId(this.serviceRequest.requesterDoctorId);
        }
    }

    @State.bound
    public setAppointmentReferralDiagnoses() {
        if (!!this.appointmentStore?.referral) {
            this.setDiagnosesForReferral(this.appointmentStore.referral);
        }
    }

    @State.action.bound
    public onTextBoxHeightChange(newValue: string) {
        const height1 = parseInt(this.textBoxHeight, 10);
        const height2 = parseInt(newValue, 10);

        const array: number[] = [];
        if (!Number.isNaN(height1)) {
            array.push(height1);
        }
        if (!Number.isNaN(height2)) {
            array.push(height2);
        }

        if (array.length) {
            const height = Math.max(...array);
            this.textBoxHeight = height + "px";
        }

    }

    @State.action.bound
    private resetCompletionDate(date: Moment) {
        this.targetCompletionDate = date;
        this.previousTargetCompletionDate = date;
    }

    @State.action.bound
    private initializeExtensionData() {
        this.serviceRequest?.setExtensionData({});
    }

    private setPriorityAndExpectedCompletionTimeOfServices(services: RequestedServiceStore[]) {
        services.forEach(s => {
            s.setPriority(this.priority);
            s.setExpectedCompletionTime(this.targetCompletionDate);
        });
        if (this.isServiceRequestEmpty) {
            this.serviceRequest.setExpectedCompletionTime(this.targetCompletionDate);
            this.serviceRequest.setPriority(this.priority);
        }
    }

    public getReferralPointOfCare() {
        const referralPointOfCare = this.serviceRequest.referralLocation instanceof InternalCareLocation
            ? this.serviceRequest.referralLocation?.pointOfCareId
            : null;

        return referralPointOfCare;
    }

    @State.action.bound
    private setNewAppointmentStore() {
        this.setNewAppointmentStoreAsync();
    }
    
    private setDiagnosesForReferral(referral: ReferralStore) {
        referral.setReferralDiagnosisId1(
            this.serviceRequest.suspectedDiagnosisList.length ?
                this.serviceRequest.suspectedDiagnosisList[0].conditionId : null);
        referral.setReferralDiagnosisId2(
            this.serviceRequest.suspectedDiagnosisList.length && this.serviceRequest.suspectedDiagnosisList.length > 1 ?
                this.serviceRequest.suspectedDiagnosisList[1].conditionId : null);
    }

    @State.action.bound
    private changeTargetCompletionDate(from: moment.Moment) {
        this.targetCompletionDate = from;
    }

    @State.action.bound
    private deleteAppointmentStore() {
        this.appointmentStore = null;
    }

    @State.action
    private processServiceRequestResponse(serviceRequest: ServiceRequestStore, keepLock: boolean = false, withValidation: boolean = false) {

        if (serviceRequest.operationInfo && serviceRequest.operationInfo.businessError instanceof CareActivityIsAlreadyAServiceRequestExecutingCareActivityError) {
            this.dialogService.ok(
                StaticWebAppResources.NewServiceRequestPage.CareActivityIsAlreadyAServiceRequestExecutingCareActivityError,
                StaticWebAppResources.NewServiceRequestPage.NotifyError);
            return;
        }

        if (withValidation && !serviceRequest.isPersistedByOperationInfo) {
            if (serviceRequest.validationResults) {
                this.serviceRequest.validationResults = serviceRequest.validationResults;
            }
            return;
        }

        if (serviceRequest.serviceRequestDefinitionVersion) {
            this.serviceRequestDefinition = this.careReferenceDataStore.serviceRequestDefinition.get(serviceRequest.serviceRequestDefinitionVersion);
        }

        this.setServiceRequestStore(serviceRequest);

        this.serviceRequest.takeSnapshot();

        if (serviceRequest.requestedServices && serviceRequest.requestedServices.length > 0) {
            this.resetPriority(serviceRequest.requestedServices[0].priority);

            if (!this.isAppointmentRequired) {
                this.resetCompletionDate(serviceRequest.requestedServices[0].expectedCompletionTime);
            }
        } else {
            this.resetPriority(serviceRequest.medicalServicePriority);
        }

        const isModifyAllowed = this.serviceRequest.possibleActions && this.serviceRequest.possibleActions.some(j => j === ServiceRequestAction.Modify);
        if (isModifyAllowed && this.serviceRequest.isNew) {
            this.initializeDefaultRequesterDoctorAndExpectedCompletionTimeIfNeeded();
        }

        this.isEditingAllowed = this.serviceRequest.possibleActions?.some(j => j === ServiceRequestAction.Modify) || this.serviceRequest.isNew;
        this.isDeleteAllowed = this.serviceRequest.possibleActions?.some(j => j === ServiceRequestAction.Delete) ?? false;
        this.isRenewAllowed = this.serviceRequest.possibleActions?.some(j => j === ServiceRequestAction.Renew) ?? false;
        this.isSubmitAllowed = this.serviceRequest.possibleActions?.some(j => j === ServiceRequestAction.Submit) || this.serviceRequest.isNew;

        this.setTempExtensionData(null);
    }

    private async getServiceRequestDurationAsync() {
        if (this.serviceRequest.targetCareLocation && !arrayIsNullOrEmpty(this.serviceRequest.requestedServices)) {
            const profile = await this.apiAdapter.getServiceProviderProfileByLocationAsync(this.serviceRequest.targetCareLocation);

            const medicalServiceDurations = this.serviceRequest.requestedServices
                .map(s => profile.result.GetMedicalServiceExecutionCapabilityByMedicalServiceId(s.id)?.durationInMinutes)
                .filter(d => !isNullOrUndefined(d));
            const result = medicalServiceDurations.length ? _.sum(medicalServiceDurations) : null;
            return result;
        }
        return null;
    }

    @State.action.bound
    private setServiceRequestStore(serviceRequestStore: ServiceRequestStore) {
        if (this.reactionDisposer) {
            this.reactionDisposer();
        }

        this.serviceRequest = serviceRequestStore;
        this.reactionDisposer = State.reaction(() => {
            // Using slice() is required here because of how Mobx works:
            // https://github.com/mobxjs/mobx/issues/385
            return serviceRequestStore.requestedServices.slice();
        }, _ => {
            this.setPriorityAndExpectedCompletionTimeOfServices(serviceRequestStore.requestedServices);
        }, {
            fireImmediately: true
        });
    }

    @State.action.bound
    private setTempExtensionData(tempExtensionData: any) {
        this.tempExtensionData = tempExtensionData;
    }

    @State.action
    private setServiceProviderProfile(serviceProviderProfile: ServiceProviderProfile) {
        this.serviceProviderProfile = serviceProviderProfile;
    }

    @State.action.bound
    private setAvailablePriorities(availablePriorities: MedicalServicePriority[]) {
        this.availablePriorities = availablePriorities;
    }

    @State.action.bound
    private resetPriority(priority: MedicalServicePriority) {
        this.changePriorityAsync(priority);
    }

    @State.action
    private initializeDefaultRequesterDoctorAndExpectedCompletionTimeIfNeeded() {
        if (this.serviceRequest.requestedServices.every(i => isNullOrUndefined(i.expectedCompletionTime))) {
            this.serviceRequest.requestedServices.forEach(i => i.setExpectedCompletionTime(DateTimeService.now()));
            this.targetCompletionDate = this.getTargetCompletionDate();
        }
        if (isNullOrUndefined(this.serviceRequest.requesterDoctorId)) {
            this.serviceRequest.requesterDoctorId = this.getDefaultRequesterDoctor();
        }
        requestAnimationFrame(() => {
            this.validateAllEvent.emit();
        });
    }

    private getTargetCompletionDate() {
        if (!this.isAppointmentRequired) {
            return this.serviceRequest.requestedServices[0].expectedCompletionTime;
        } else {
            return this.appointmentStore?.intervalFrom;
        }
    }

    private getDefaultRequesterDoctor() {
        if (this.serviceRequest.direction === ServiceRequestDirection.ExternalToInternal) {
            return this.props._careActivityBaseData.referral.referralDoctorId;
        } else {
            return this.props._careActivityBaseData.primaryParticipant;
        }
    }

    @State.bound
    private setTargetExternalLocationVersionSelector(newValue: ExternalLocationId) {
        this.serviceRequest.setTargetExternalLocationVersionSelector(newValue);
        if (isNullOrUndefined(newValue) || isNullOrUndefined(newValue.value)) {
            this.setAvailablePriorities(null);
            this.changePriorityAsync(null);
        } else {
            this.loadServiceProviderProfileAsync();
        }
    }

    @State.bound
    private setTargetPointOfCareId(newValue: PointOfCareId) {
        this.serviceRequest.setTargetPointOfCareId(newValue);
        if (isNullOrUndefined(newValue)) {
            this.setAvailablePriorities(null);
            this.changePriorityAsync(null);
        } else {
            this.loadServiceProviderProfileAsync();
        }
    }

    private readonly openAppointmentModalAsync = this.async(async () => {
        this.modalService.showModal(
            new ServiceRequestAppointmentModalParams(
                this.appointmentStore,

                await this.getServiceRequestAsSchedulingServiceAsync(),
                this.serviceRequest.targetPointOfCareId,

                this.props._patient.baseData.name,

                this.onValidateAppointmentAsync,
                this.setAppointment,
                this.setTargetDoctorId
            )
        );
    });

    @State.bound
    private openAppointmentModal() {
        this.openAppointmentModalAsync();
    }

    @State.action.bound
    private setAppointment(appointment: Appointment) {
        this.appointmentStore = appointment;
        this.targetCompletionDate = this.appointmentStore.intervalFrom;
    }

    @State.action.bound
    private setTargetDoctorId() {
        this.serviceRequest.targetDoctorId = this.appointmentStore.practitionerIds[0];
    }

    @State.bound
    private setTempServiceRequestExtensionData(extensionData: any) {
        this.clientSessionService.setTempData("serviceRequestExtensionData", extensionData);
    }

    @State.action.bound
    private setLockInfo(lockInfo: LockInfo): void {
        this.lockInfo = lockInfo;
    }
}