exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FieldValidationResult_no-text-selection_2lr3l {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.FieldValidationResult_common_yscLj,\n.FieldValidationResult_error_2WEQJ,\n.FieldValidationResult_warning_1gB4Z {\n  padding: 3px 4px 0 4px;\n  font-family: PragatiNarrowBold;\n  line-height: 1.1;\n}\n.FieldValidationResult_error_2WEQJ {\n  color: #cc0044;\n}\n.FieldValidationResult_warning_1gB4Z {\n  color: #fe8443;\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "FieldValidationResult_no-text-selection_2lr3l",
	"noTextSelection": "FieldValidationResult_no-text-selection_2lr3l",
	"common": "FieldValidationResult_common_yscLj",
	"error": "FieldValidationResult_error_2WEQJ",
	"warning": "FieldValidationResult_warning_1gB4Z"
};