import ConditionId from "@Primitives/ConditionId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FamilyDoctorDocumentEquipmentReference from "./FamilyDoctorDocumentEquipmentReference";
import SpaService from "./SpaService";

export default class CurativeItem {
    @State.observable public conditionId: ConditionId = null;
    @State.observable public monthlyAmount: string = "";
    @State.observable public practitionerId: PractitionerId = null;
    @State.observable public isMedicationEquipment: boolean = false;

    @State.observable.ref public detail: SpaService | FamilyDoctorDocumentEquipmentReference
        = new SpaService();
    @State.action.bound
    public setConditionId(conditionId: ConditionId) {
        this.conditionId = conditionId;
    }

    @State.action.bound
    public setMonthlyAmount(monthlyAmount: string) {
        this.monthlyAmount = monthlyAmount;
    }

    @State.action.bound
    public setPractitionerId(practitionerId: PractitionerId) {
        this.practitionerId = practitionerId;
    }
    @State.action.bound
    public setIsMedicationEquipment(isMedicationEquipment: boolean) {
        this.isMedicationEquipment = isMedicationEquipment;

        if (isMedicationEquipment) {
            if (!(this.detail instanceof FamilyDoctorDocumentEquipmentReference)) {
                this.detail = new FamilyDoctorDocumentEquipmentReference();
            }
        } else {
            if (!(this.detail instanceof SpaService)) {
                this.detail = new SpaService();
            }
        }
    }
}