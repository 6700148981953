import React, { useMemo } from "react";
import Styles from "./ServiceRequestStateBadge.less";
import EnumBadge from "@CommonControls/EnumBadge";
import ServiceRequestState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestState.g";

interface IServiceRequestStateBadgeProps {
    state: ServiceRequestState;
    isIndex?: boolean;
}

function ServiceRequestStateBadge(props: IServiceRequestStateBadgeProps): React.ReactElement {
    const className = useMemo(() => {
        switch (props.state) {
            case ServiceRequestState.UnderRecording:
                return Styles.stateUnderRecording;
            case ServiceRequestState.Submitted:
            case ServiceRequestState.WaitingForSubmission:
                return Styles.stateSubmitted;
            case ServiceRequestState.RegisteredAsReceived:
                return Styles.stateRegisteredAsReceived;
            case ServiceRequestState.ActivityCompleted:
                return Styles.stateActivityCompleted;
            case ServiceRequestState.NonValidatedCompleted:
                return Styles.stateNonValidatedCompleted;
            case ServiceRequestState.ValidatedCompleted:
                return Styles.stateValidatedCompleted;
            case ServiceRequestState.Deleted:
            case ServiceRequestState.WaitingForDelete:
            case ServiceRequestState.HasError:
                return Styles.stateDeleted;
            default:
                return null;
        }
    }, [props.state]);

    return (
        <EnumBadge
            value={props.state}
            enumName={"ServiceRequestState"}
            enumType={ServiceRequestState}
            className={className}
            shape="circle"
            isIndex={props.isIndex}/>
    );
}

export default ServiceRequestStateBadge;
