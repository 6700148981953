import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, {IExtensibleEnumSelectBoxPropsBase} from "@HisPlatformControls/ExtensibleEnumSelectBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import EhiEReferralQueryScenarioCodeId from "@Primitives/EhiEReferralQueryScenarioCodeId.g";

interface IEhiEReferralListQueryScenarioCodeSelectBoxDependencies {
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
}

interface IEhiEReferralListQueryScenarioCodeSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IEhiEReferralListQueryScenarioCodeSelectBoxDependencies;

    limitScenarioCodes?: "requestorSide" | "executingSide";
}

@State.observer
class EhiEReferralListQueryScenarioCodeSelectBox extends React.Component<IEhiEReferralListQueryScenarioCodeSelectBoxProps> {
    private get dependencies() {
        return this.props._dependencies;
    }

    private get extensibleEnumStore() {
        return this.dependencies.hunEhiCareReferenceDataStore.ehiEReferralQueryScenarioCodes;
    }

    @State.computed
    private get idFilter() {
        if (this.props.limitScenarioCodes === "requestorSide") {
            return [
                EhiEReferralQueryScenarioCodeId[5],
                EhiEReferralQueryScenarioCodeId[6],
                EhiEReferralQueryScenarioCodeId[8]
            ] as IStringEntityId[];
        }
        if (this.props.limitScenarioCodes === "executingSide") {
            return [
                EhiEReferralQueryScenarioCodeId[0], 
                EhiEReferralQueryScenarioCodeId[2],
                EhiEReferralQueryScenarioCodeId[3],
                EhiEReferralQueryScenarioCodeId[4]
            ] as IStringEntityId[];
        }
        return null;
    }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
                idFilter={this.idFilter}
                clearable={false}
            />
        );
    }
}

export default connect(
    EhiEReferralListQueryScenarioCodeSelectBox,
    new DependencyAdapter<IEhiEReferralListQueryScenarioCodeSelectBoxProps, IEhiEReferralListQueryScenarioCodeSelectBoxDependencies>((containerService) => {
        return {
            hunEhiCareReferenceDataStore: containerService.resolve("HunEhiCareReferenceDataStore")
        };
    })
);
