import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";
import IActionPresentation from "./IActionPresentation";

export default class ActionDescriptor<TPresentation = IActionPresentation> {
    constructor(
        public readonly action: FrontendActionBase,
        public readonly presentation: TPresentation,
        public readonly requiredPermissionScopes: FrontendActionPermissionScope[]
    ) { }

    public static fromAction<TPresentation = IActionPresentation>(action: FrontendActionBase, ...requiredPermissionScopes: FrontendActionPermissionScope[]) {
        return new ActionDescriptor<TPresentation>(action, null, requiredPermissionScopes);
    }

}