import Di from "@Di";
import IClientSessionRepository from "@Toolkit/CommonWeb/Repositories/Definition/ClientSessionRepository/IClientSessionRepository";

const storageKey = "Client-Session-Id";

@Di.injectable()
export default class ClientSessionRepository implements IClientSessionRepository {
    public saveClientSessionId(sessionId: string): void {
        sessionStorage.setItem(storageKey, sessionId);
    }

    public getClientSessionId(): string {
        return sessionStorage.getItem(storageKey);
    }

}