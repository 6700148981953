import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import TemplateId from "@Primitives/TemplateId.g";
import DocumentKind from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentKind.g";
import PatientId from "@Primitives/PatientId.g";
import DocumentId from "@Primitives/DocumentId.g";

export interface ICreateNewDocumentDialogParams extends IModalParams {
    scope?: string;
    patientId?: PatientId;
}


export interface ICreateNewDocumentDialogResult {
    documentKind: DocumentKind;
    templateId: TemplateId;
    description: string;
    newId?: DocumentId;
}

export default class CreateNewDocumentDialogParams implements ICreateNewDocumentDialogParams {
    public static type = "CreateNewDialog";
    public get type() { return CreateNewDocumentDialogParams.type; }

    constructor(
        public readonly scope: string,
        public readonly patientId: PatientId
    ) { }
}