exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "td.Calendar_highlight-week_3ARuV {\n  background-color: rgba(143, 209, 230, 0.1);\n  border-bottom: 1px solid #00B4EC;\n  border-top: 1px solid #00B4EC;\n  border-radius: 0px!important;\n  padding: 0px;\n}\ntd.Calendar_highlight-week_3ARuV:first-child {\n  border-left: 1px solid #00B4EC;\n  border-radius: 3px 0px 0px 3px!important;\n}\ntd.Calendar_highlight-week_3ARuV:last-child {\n  border-right: 1px solid #00B4EC;\n  border-radius: 0px 3px 3px 0px!important;\n}\ntd.Calendar_highlight-week_3ARuV.Calendar_selected_2dWow {\n  border: 3px solid #00b4ec;\n  background-color: rgba(143, 209, 230, 0.1);\n}\n.Calendar_highlight-week-container_1kKSn table {\n  border-spacing: 0px 0px;\n}\n.Calendar_disabled-entry_2YrX1 {\n  pointer-events: none;\n}\n.Calendar_today_1LtNa {\n  font-weight: bold;\n}\n.Calendar_selected_2dWow {\n  border: 3px solid #00b4ec;\n  background-color: rgba(143, 209, 230, 0.1);\n}\n.Calendar_month_28WTZ {\n  text-transform: capitalize;\n}\n.Calendar_multiple-calendar_1j0xL {\n  line-height: 20px;\n}\n", ""]);

// exports
exports.locals = {
	"highlight-week": "Calendar_highlight-week_3ARuV",
	"highlightWeek": "Calendar_highlight-week_3ARuV",
	"selected": "Calendar_selected_2dWow",
	"highlight-week-container": "Calendar_highlight-week-container_1kKSn",
	"highlightWeekContainer": "Calendar_highlight-week-container_1kKSn",
	"disabled-entry": "Calendar_disabled-entry_2YrX1",
	"disabledEntry": "Calendar_disabled-entry_2YrX1",
	"today": "Calendar_today_1LtNa",
	"month": "Calendar_month_28WTZ",
	"multiple-calendar": "Calendar_multiple-calendar_1j0xL",
	"multipleCalendar": "Calendar_multiple-calendar_1j0xL"
};