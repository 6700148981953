import React from "react";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { NestedPermissionCheckContextProvider } from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import ehiStandaloneRouteDefinitions from "@HunEHealthInfrastructurePlugin/UseCases/EhiUseCase/EhiRoutes";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import covidVaccinationStandaloneRouteDefinitions from "@HunEHealthInfrastructurePlugin/UseCases/CovidVaccinationUseCase/CovidVaccinationRoutes";

interface IHunEHealthInfrastructureReportingToolbarDependencies {
    ehiCareApiAdapter: EhiCareApiAdapter;
    globalRoutingStore: GlobalRoutingStore;
}

interface IHunEHealthInfrastructureReportingToolbarProps {
    _dependencies?: IHunEHealthInfrastructureReportingToolbarDependencies;
}

@State.observer
class HunEHealthInfrastructureReportingToolbar extends React.Component<IHunEHealthInfrastructureReportingToolbarProps> {

    @State.bound
    private navigateToEReferrals() {
        this.props._dependencies.globalRoutingStore.push(ehiStandaloneRouteDefinitions.eReferrals.makeRoute());
    }

    @State.bound
    private navigateToCovidVaccinations() {
        this.props._dependencies.globalRoutingStore.push(covidVaccinationStandaloneRouteDefinitions.covidVaccinations.makeRoute());
    }

    private get permissionCheckedOperations() {
        const res = {};
        res["EReferralList"] = async () => {
            await this.props._dependencies.ehiCareApiAdapter.getEReferralsForOrganizationUnitPermissionCheckAsync();
        };

        return res;
    }

    public render() {

        return (
            <NestedPermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                {this.navBarItems}
            </NestedPermissionCheckContextProvider>
        );
    }

    private get navBarItems() {
        const navBarItems: JSX.Element[] = [];

        const eReferralNavBarItem = (
            <Ui.NavBar.Item
                key={"eReferrals"}
                onClick={this.navigateToEReferrals}
                automationId="eReferrals"
                permissionCheckOperationNames={"EReferralList"}
                permissionDeniedStyle="disabled"
            >
                {StaticHunEHealthInfrastructureCareResources.Navbar.EReferral.EReferralList}
            </Ui.NavBar.Item>
        );

        navBarItems.push(eReferralNavBarItem);

        const covidVaccinationNavBarItem = (
            <Ui.NavBar.Item
                key={"covidVaccinations"}
                onClick={this.navigateToCovidVaccinations}
                automationId="covidVaccinations"
            >
                {StaticHunEHealthInfrastructureCareResources.Navbar.CovidVaccinations.CovidVaccinations}
            </Ui.NavBar.Item>
        );

        navBarItems.push(covidVaccinationNavBarItem);

        return navBarItems as JSX.Element[];
    }
}
export default connect(
    HunEHealthInfrastructureReportingToolbar,
    new DependencyAdapter<IHunEHealthInfrastructureReportingToolbarProps, IHunEHealthInfrastructureReportingToolbarDependencies>(c => ({
        ehiCareApiAdapter: c.resolve("EhiCareApiAdapter"),
        globalRoutingStore: c.resolve("GlobalRoutingStore")
    }))
);