import React from "react";
import Di from "@Di";
import { ConstructorType } from "@Toolkit/CommonWeb/Reflection/Reflection";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import FormDefinition from "@Toolkit/FormEngine/Model/FormDefinition";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import CustomBlockSettingsBase, { ICustomBlockSettingsBase } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/CustomBlockSettingsBase";
import FormFieldDependencyStore from "./FormFieldDependencyStore";

export interface IFormEditorComponentProps {
    label: string;
    value: any;
    onChange: any;
    automationId: string;
    style?: React.CSSProperties;
    isReadOnly?: boolean;
    propertyIdentifier?: string;
    dataElement?: FormDataElementBase;
    filters: FilterBase[];
}
export type FormEditorComponentType = React.ComponentType<IFormEditorComponentProps>;

export interface IFormCustomBlockComponentProps {
    form: IForm;
    formDefinition: FormDefinition;
    settings?: ICustomBlockSettingsBase;
}

export type FormCustomBlockComponentType = React.ComponentType<IFormCustomBlockComponentProps>;
export type FormCustomBlockPropertyPanelComponentType = React.ComponentType;

export interface IEditorRegistryItem<TProps = any, TPlaceholderProps = any> {
    dataElementFactory: (propertyName: string) => FormDataElementBase;
    dependencyStoreFactory?: () => FormFieldDependencyStore;
    toolboxNewItemDisplayName: string;

    componentType: FormEditorComponentType;
    placeholderComponentType?: FormEditorComponentType;

    props?: Partial<TProps>;
    placeholderProps?: Partial<TPlaceholderProps>;
}

export interface IEntityReferenceEditorRegistryItem<TProps extends { value?: TId } = any, TId extends IStringEntityId = IStringEntityId> {
    displayName: string;
    entityIdCtor: ConstructorType<TId>;
    componentType: FormEditorComponentType;
    loadReferenceDataAsync: (id: TId | TId[]) => Promise<void>;
    props?: Partial<TProps>;
}

export interface IEntityReferenceEnumEditorRegistryItem<TProps extends { value?: number } = any> {
    displayName: string;
    enumType: any;
    componentType: FormEditorComponentType;
    props?: Partial<TProps>;
}

export interface IToolboxEditorItem {
    displayName: string;
    editorType: string;
}

export interface IToolboxCustomBlockItem {
    displayName: string;
    customBlockIdentifier: string;
}

export interface IEntityReferenceEditorItem {
    displayName: string;
    entityName: string;
}

export interface ICustomBlockRegistryItem<TProps = any> {
    dataElementsFactory: () => FormDataElementBase[];
    settingsFactory?: () => CustomBlockSettingsBase;

    toolboxNewItemDisplayName: string;

    componentType: FormCustomBlockComponentType;
    properyPanelComponentType?: FormCustomBlockPropertyPanelComponentType;
    props?: Partial<TProps>;
}

@Di.injectable()
export default class FormEditorRegistry {
    private readonly editorMap = new Map<string, IEditorRegistryItem>();
    private readonly entityReferenceEditorMap = new Map<string, IEntityReferenceEditorRegistryItem>();
    private readonly extensibleEnumEditorMap = new Map<string, IEntityReferenceEditorRegistryItem>();
    private readonly enumEditorMap = new Map<string, IEntityReferenceEnumEditorRegistryItem>();
    private readonly customBlockMap = new Map<string, ICustomBlockRegistryItem>();

    public registerEditor<TProps = unknown, TPlaceholderProps = unknown>(editorType: string, item: IEditorRegistryItem<TProps, TPlaceholderProps>) {
        this.editorMap.set(editorType, item);
    }

    public registerEntityReferenceEditor<TProps = unknown>(entityName: string, item: IEntityReferenceEditorRegistryItem<TProps>) {
        this.entityReferenceEditorMap.set(entityName, item);
    }

    public registerExtensibleEnumEditor<TProps = unknown>(entityName: string, item: IEntityReferenceEditorRegistryItem<TProps>) {
        this.extensibleEnumEditorMap.set(entityName, item);
    }

    public registerEnumEditor<TProps = unknown>(entityName: string, item: IEntityReferenceEnumEditorRegistryItem<TProps>) {
        this.enumEditorMap.set(entityName, item);
    }

    public registerCustomBlock(customBlockIdentifier: string, item: ICustomBlockRegistryItem) {
        this.customBlockMap.set(customBlockIdentifier, item);
    }

    public getNewToolboxEditorItems(): IToolboxEditorItem[] {
        return Array.from(this.editorMap).map(i => ({ displayName: i[1].toolboxNewItemDisplayName, editorType: i[0] } as IToolboxEditorItem));
    }

    public getNewToolboxCustomBlockItems(): IToolboxCustomBlockItem[] {
        return Array.from(this.customBlockMap).map(i => ({ displayName: i[1].toolboxNewItemDisplayName, customBlockIdentifier: i[0] } as IToolboxCustomBlockItem));
    }

    public tryGetEditorComponent(editorType: string): { componentType: FormEditorComponentType, props: any, dataElementFactory: (propertyName: string) => FormDataElementBase } | null {
        const res = this.editorMap.get(editorType) ?? null;
        return res ? { componentType: res.componentType, props: res.props, dataElementFactory: res.dataElementFactory } : null;
    }

    public tryCreateFormFieldDependencyStore(editorType: string): FormFieldDependencyStore | null {
        const res = this.editorMap.get(editorType) ?? null;
        return res ? res.dependencyStoreFactory() : null;
    }

    public tryGetEntityReferenceEditorComponent(entityName: string): { componentType: FormEditorComponentType, props: any, entityIdCtor: ConstructorType<IStringEntityId> } | null {
        const res = this.entityReferenceEditorMap.get(entityName) ?? null;
        return res ? { componentType: res.componentType, props: res.props, entityIdCtor: res.entityIdCtor } : null;
    }

    public tryGetExtensibleEnumEditorComponent(entityName: string): { componentType: FormEditorComponentType, props: any, entityIdCtor: ConstructorType<IStringEntityId> } | null {
        const res = this.extensibleEnumEditorMap.get(entityName) ?? null;
        return res ? { componentType: res.componentType, props: res.props, entityIdCtor: res.entityIdCtor } : null;
    }

    public tryGetEnumEditorComponent(entityName: string): { componentType: FormEditorComponentType, props: any, enumType: any } | null {
        const res = this.enumEditorMap.get(entityName) ?? null;
        return res ? { componentType: res.componentType, props: res.props, enumType: res.enumType } : null;
    }

    public tryGetCustomBlockComponent(customBlockIdentifier: string): { componentType: FormCustomBlockComponentType, props: any } | null {
        const res = this.customBlockMap.get(customBlockIdentifier) ?? null;
        return res ? { componentType: res.componentType, props: res.props } : null;
    }

    public tryGetCustomBlockPropertyPanelComponent(customBlockIdentifier: string): FormCustomBlockPropertyPanelComponentType | null {
        const res = this.customBlockMap.get(customBlockIdentifier) ?? null;
        return res ? res.properyPanelComponentType : null;
    }

    public getEntityReferenceEditors(): IEntityReferenceEditorItem[] {
        return Array.from(this.entityReferenceEditorMap).map(([key, value]) => ({
            displayName: value.displayName,
            entityName: key
        } as IEntityReferenceEditorItem));
    }

    public getExtensibleEnumEditors(): IEntityReferenceEditorItem[] {
        return Array.from(this.extensibleEnumEditorMap).map(([key, value]) => ({
            displayName: value.displayName,
            entityName: key
        } as IEntityReferenceEditorItem));
    }

    public getEnumEditors(): IEntityReferenceEditorItem[] {
        return Array.from(this.enumEditorMap).map(([key, value]) => ({
            displayName: value.displayName,
            entityName: key
        } as IEntityReferenceEditorItem));
    }

    public getEntityReferenceLoader(entityName: string): { loader: (id: IStringEntityId | IStringEntityId[]) => Promise<void>, entityIdCtor: ConstructorType<IStringEntityId> } {
        const entry = this.entityReferenceEditorMap.get(entityName);
        return !entry ? null : {
            entityIdCtor: entry.entityIdCtor,
            loader: entry.loadReferenceDataAsync
        };
    }

    public tryGetPlaceholderEditorComponent(editorType: string): { componentType: FormEditorComponentType, props: any } | null {
        const res = this.editorMap.get(editorType) ?? null;
        return res ? { componentType: res.placeholderComponentType ?? res.componentType, props: res.placeholderProps ?? res.props } : null;
    }

    public tryCreateDataElement(editorType: string, propertyName: string): FormDataElementBase | null {
        return this.editorMap.get(editorType)?.dataElementFactory?.(propertyName) ?? null;
    }

    public tryCreateCustomBlockDataElements(customBlockIdentifier: string): FormDataElementBase[] | null {
        const newDataElements = this.customBlockMap.get(customBlockIdentifier)?.dataElementsFactory?.() ?? null;
        return newDataElements;
    }

    public tryCreateCustomBlockSettings(customBlockIdentifier: string): CustomBlockSettingsBase | null {
        const newSettings = this.customBlockMap.get(customBlockIdentifier)?.settingsFactory?.() ?? null;
        return newSettings;
    }
}
