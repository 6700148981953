import { DataGridLoadType, IPagingState, IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDataGridDataSource from "@CommonControls/DataGrid/DataSource/IDataGridDataSource";
import { isNullOrUndefined, arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { equals } from "@Toolkit/CommonWeb/EqualityHelper";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";

export default class InMemoryDataGridDataSource<TRecord = any, TFilterStore extends IFilterStore = any> implements IDataGridDataSource<TRecord, TFilterStore> {

    
    public __dataGridDataSource: true = true;

    @State.observable.ref public paging: IPagingState = { pageSize: 10, currentPage: 0 };
    @State.observable.ref public ordering: IOrderingState | IOrderingState[] = null;
    @State.observable.ref public filterStore: TFilterStore = null;
    @State.observable.ref public columns: IDataGridColumnProps[] = null;

    public readonly explicitRefreshEvent = new TypedEvent();

    @State.computed public get data(): IPagedItems<TRecord> {
        return this.paginateRecords(this.dataGetter());
    }

    @State.computed public get orderedData(): TRecord[] {
        return this.getOrderedData(this.dataGetter());
    }

    @State.computed private get currentPage(): number {
        const pageCount = this.orderedData.length / this.paging.pageSize;
        const maxCurrentPage = (pageCount % 1 === 0) ? (pageCount - 1) : Math.floor(pageCount);
        return Math.min(this.paging.currentPage, maxCurrentPage);
    }

    constructor(
        private readonly dataGetter: () => TRecord[],
        private defaultOrdering?: IOrderingState,
    ) {
        this.ordering = defaultOrdering;
    }

    @State.bound
    public onChangeAsync(
        type: DataGridLoadType,
        paging: IPagingState,
        ordering: IOrderingState | IOrderingState[],
        filterStore: TFilterStore,
        columns: IDataGridColumnProps[]
    ): Promise<void> {
        return new Promise((resolve: () => void) => {
            State.runInAction(() => {
                this.columns = columns;
                this.paging = paging;
                this.ordering = ordering;
                this.filterStore = filterStore;
            });

            if (type === "explicit-refresh") {
                this.explicitRefreshEvent.emit();
            }

            resolve();
        });
    }

    @State.bound
    public async reloadAsync(): Promise<void> {
        await this.onChangeAsync("changed", this.paging, this.ordering, this.filterStore, this.columns);
    }

    @State.bound
    private paginateRecords(dataIn: TRecord[]) {
        if (arrayIsNullOrEmpty(dataIn)) {
            return {
                items: [],
                totalCount: 0
            } as IPagedItems<TRecord>;
        }

        let data = this.orderedData;
        const recordCountAfterFilter = data.length;

        if (this.paging && !isNullOrUndefined(this.paging.pageSize)) {
            const skip = this.currentPage * this.paging.pageSize;
            data = data.slice(skip, skip + this.paging.pageSize);
        }

        return {
            items: data,
            totalCount: recordCountAfterFilter
        } as IPagedItems<TRecord>;
    }

    @State.bound
    private getOrderedData(dataIn: TRecord[]) {
        let data = dataIn;

        if (this.filterStore) {
            data = data.filter(row => {

                let valueEquals = true;
                this.columns.forEach((column) => {
                    if (column.isFilterable) {

                        const filterValue = this.filterStore[column.id];

                        const value = typeof column.dataGetter === "function" ?
                            column.dataGetter(row, null, null, null) : // fixme: ??
                            _.get(row, column.dataGetter);

                        if (column.clientSideFilterPredicate) {
                            valueEquals = valueEquals && column.clientSideFilterPredicate(filterValue, value, row);
                        } else {

                            const filterableValue = typeof column.clientSideFilterableValueGetter === "function" ?
                                column.clientSideFilterableValueGetter(value, row, null, null) : // fixme: ???
                                _.get(row, column.clientSideFilterableValueGetter);

                            if (!isNullOrUndefined(filterValue) && filterValue !== "") {
                                if (isNullOrUndefined(filterableValue)) {
                                    valueEquals = false;
                                } else {
                                    valueEquals = valueEquals && equals(column.clientSideFilterableValueGetter ? filterableValue : value, filterValue, {
                                        stringContains: true,
                                        stringIgnoreCase: true,
                                        checkRangeInclusions: true
                                    });
                                }
                            }

                        }
                    }
                });
                return valueEquals;

            });
        }

        if (this.ordering && this.columns && this.columns.length > 0) {
            const singleOrdering: IOrderingState = Array.isArray(this.ordering) ? (this.ordering.length > 0 ? this.ordering[0] : null) : this.ordering;

            if (singleOrdering) {
                const column = this.columns.find(c => c.id === singleOrdering.columnId);
                data = _.orderBy(data, [this.clientSideOrderableValueGetterFactory(column.dataGetter, column.clientSideOrderableValueGetter)], [singleOrdering.direction]) as TRecord[];
            }
        }

        return data;
    }

    private clientSideOrderableValueGetterFactory(
        dataGetter: string | ((row: any, key: string | number, index: number, isUnderEditing: boolean) => any),
        clientSideOrderableValueGetter: string | ((value: any, row: any, rowId: string | number, rowIndex: number) => any)
    ): string | ((row: any, key: string | number, index: number, isUnderEditing: boolean) => any) {
        if (!clientSideOrderableValueGetter) { return dataGetter; }
        if (typeof clientSideOrderableValueGetter === "string") { return clientSideOrderableValueGetter; }
        return (row: any) => {
            const value = typeof dataGetter === "function"
                ? dataGetter(row, null, null, null) // fixme: ??
                : _.get(row, dataGetter);
            return clientSideOrderableValueGetter(value, row, null, null);
        };
    }
}
