import Di from "@Di";
import { IFormDefinitionMasterDetailPanelProps } from "./FormDefinitionMasterDetailPanel";
import MasterDetailCrudPanelStoreBase from "@Toolkit/CommonWeb/Model/PanelStore/MasterDetailCrudPanelStoreBase";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import ISaveResult from "@Toolkit/CommonWeb/Model/PanelStore/ISaveResult";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import IFormDefinitionListItem from "@Toolkit/FormEngine/Model/IFormDefinitionListItem";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import FormDefinition from "@Toolkit/FormEngine/Model/FormDefinition";
import PreviewFormDefinition from "@Toolkit/FormEngine/Model/PreviewFormDefinition";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FormSchema from "@Toolkit/FormEngine/Model/Schema/FormSchema";
import FormSchemaId from "@Toolkit/FormEngine/Model/Primitives/FormSchemaId.g";
import FormLayoutStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutStore";
import FormLayoutId from "@Toolkit/FormEngine/Model/Primitives/FormLayoutId.g";
import FormLayoutVersionStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutVersionStore";
import FormSchemaVersion from "@Toolkit/FormEngine/Model/Schema/FormSchemaVersion";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import { createEmptyFormDataStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormDataStore";
import FormSchemaReference from "@Toolkit/FormEngine/Model/Schema/FormSchemaReference";
import FormLayoutReference from "@Toolkit/FormEngine/Model/Layout/FormLayoutReference";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import IFormEngineApiAdapter from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import FormLayoutEditorHandle, { IFormDefinitionVersion } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorHandle";
import FormDefinitionImportDialogParams, { IFormDefinitionImportDialogResult } from "./ImportDialog/FormDefinitionImportDialogParams";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import Form from "@Toolkit/FormEngine/Model/Form";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import { getAllEditors } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/GetAllEditors";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import FormEditBehavior from "@Toolkit/FormEngine/Model/FormEditBehavior";
import FormLogicType from "@Primitives/FormLogicType";
import ReferencedEntityFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEntityFormDataElement";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import EnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/EnumFormDataElement";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import ReferencedExtensibleEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedExtensibleEnumFormDataElement";
import ReferencedEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEnumFormDataElement";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";

@Di.injectable()
export default class FormDefinitionMasterDetailPanelStore
    extends MasterDetailCrudPanelStoreBase<IFormDefinitionMasterDetailPanelProps, FormDefinitionId, IFormDefinitionListItem, IFormDefinition> {

    public readonly editorHandle = new FormLayoutEditorHandle();
    @State.observable.ref public isPreviewMode = false;
    @State.observable.ref public previewFormDefinition: IFormDefinition | null = null;
    @State.observable.ref public previewForm: IForm | null = null;

    constructor(
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("IFileSaverService") private readonly fileSaverService: IFileSaverService,
        @Di.inject("IFormEngineApiAdapter") private readonly formEngineApiAdapter: IFormEngineApiAdapter,
        @Di.inject("IFormEngineReferenceDataStore") private readonly formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("ICurrentCultureProvider") public readonly cultureCodeProvider: ICurrentCultureProvider
    ) {
        super(notificationService);
    }

    protected getIdFromListItem(item: IFormDefinitionListItem): FormDefinitionId { return item.id; }
    protected getIdFromDetailItem(detail: IFormDefinition): FormDefinitionId { return detail.id; }
    protected getSelectedIdFromProps(props: IFormDefinitionMasterDetailPanelProps): FormDefinitionId { return props.selectedId; }
    protected getSelectedIdMutatorFromProps(props: IFormDefinitionMasterDetailPanelProps): (value: FormDefinitionId) => void { return props.onSelectedIdChange; }
    protected getNewId(): FormDefinitionId { return new FormDefinitionId("new"); }

    protected getNewDetailItemAsync(): Promise<IFormDefinition> {
        return Promise.resolve(new FormDefinition(
            this.getNewId(),
            RowVersion.initial,
            null,
            this.createNewMultiLingualLabel(),
            "CareActivity",
            new FormSchema(new FormSchemaId("new"), [
                new FormSchemaVersion(1, "Main", [], [])
            ]),
            new FormLayoutStore(new FormLayoutId("new"), [
                new FormLayoutVersionStore(1, [])
            ]),
            [],
            FormEditBehavior.ImmediatelyEditable,
            [],
            FormLogicType.CSharp,
            [],
            false,
            true,
            null
        ));
    }

    protected async loadListCoreAsync(): Promise<PagedItemStore<IFormDefinitionListItem>> {
        const result = await this.formEngineApiAdapter.getFormDefinitionListAsync("", false);
        // const result = await this.formEngineApiAdapter.getFormDefinitionListAsync("CareActivity");
        return result.value;
    }

    protected async loadDetailCoreAsync(id: FormDefinitionId): Promise<IFormDefinition> {
        const result = await this.formEngineApiAdapter.getFormDefinitionDetailAsync(id);
        return result.value;
    }

    protected vSetDetailLoadedStateCore(formDefinition: IFormDefinition) {
        this.isPreviewMode = false;
        this.editorHandle.setDefinition(formDefinition ? {
            id: FormDefinitionId.isNew(formDefinition.id) ? null : formDefinition.id,
            name: formDefinition.name,
            multiLingualDisplayName: formDefinition.multiLingualDisplayName,
            baseEntityType: formDefinition.baseEntityType,
            rootSchemaVersion: formDefinition.rootSchema.getLatestVersionOrNull(),
            rootLayoutVersion: formDefinition.rootLayout.getLatestVersionOrNull(),
            enums: formDefinition.formEnums,
            formEditBehavior: formDefinition.formEditBehavior,
            formLogics: formDefinition.formLogics,
            formLogicType: formDefinition.formLogicType,
            formLogicTokens: formDefinition.formLogicTokens,
            withoutDataHandling: formDefinition.withoutDataHandling,
            isUserForm: formDefinition.isUserForm,
            instanceLimit: formDefinition.instanceLimit
        } : null);
    }

    @State.bound
    private createNewMultiLingualLabel() {
        const newTitleLabel = StaticFormEngineResources.FormDefinitionEditor.Default.NewFormTitle;
        const map = new Map<string, string>();
        map.set(this.cultureCodeProvider.cultureCode, newTitleLabel);
        const multiLingualLabel = new MultiLingualLabel(map);
        return multiLingualLabel;
    }

    protected async saveCoreAsync(): Promise<ISaveResult<FormDefinitionId>> {

        const dataToSave = this.editorHandle.getDefinition();
        const editorChanges = this.editorHandle.getEditorChanges();

        let result: SimpleStore<IFormDefinition>;

        const referencedEntitiesHasValidationProblems = this.checkReferencedEntitiesForValidationProblems(dataToSave);
        const enumsHasValidationProblems = this.checkEnumsForValidationProblems(dataToSave);
        const referencedExtensibleEnumsValidationProblems = this.checkReferencedExtensibleEnumsForValidationProblems(dataToSave);
        const referencedEnumsValidationProblems = this.checkReferencedEnumsForValidationProblems(dataToSave);

        if (referencedEntitiesHasValidationProblems || enumsHasValidationProblems || referencedExtensibleEnumsValidationProblems || referencedEnumsValidationProblems) {
            return {
                id: this.selectedId,
                isPersisted: false
            };
        }

        if (this.isNewDetail) {
            result = await this.formEngineApiAdapter.createFormDefinitionAsync(
                dataToSave.baseEntityType,
                this.selectedDetail.name,
                dataToSave.multiLingualDisplayName,
                dataToSave.rootSchemaVersion,
                dataToSave.enums,
                dataToSave.rootLayoutVersion,
                dataToSave.formEditBehavior,
                dataToSave.formLogicType,
                dataToSave.formLogicTokens,
                dataToSave.formLogics,
                dataToSave.withoutDataHandling,
                dataToSave.isUserForm,
                dataToSave.instanceLimit
            );
        } else {
            result = await this.formEngineApiAdapter.updateFormDefinitionAsync(
                this.selectedId,
                this.selectedDetail.rowVersion,
                dataToSave.multiLingualDisplayName,
                this.selectedDetail.rootSchema.id,
                dataToSave.rootSchemaVersion,
                dataToSave.enums,
                this.selectedDetail.rootLayout.id,
                dataToSave.rootLayoutVersion,
                editorChanges,
                dataToSave.formEditBehavior,
                dataToSave.formLogicType,
                dataToSave.formLogics,
                dataToSave.formLogicTokens,
                dataToSave.withoutDataHandling,
                dataToSave.isUserForm,
                dataToSave.instanceLimit
            );
        }

        if (result.isPersistedByOperationInfo && !this.isNewDetail) {
            this.formEngineReferenceDataStore.invalidateSingleDefinitionCache(this.selectedId);
            this.editorHandle.resetEditorChanges();
        }

        return {
            id: result.value?.id,
            isPersisted: result.isPersistedByOperationInfo,
            returnedStore: result.value
        };
    }

    @State.bound
    public export() {
        const dataToSave = this.editorHandle.getDefinition();

        const serializedExportData = this.formEngineApiAdapter.getFormDefinitionExportData(this.selectedDetail, dataToSave);
        this.fileSaverService.saveAs(new Blob([serializedExportData]), this.selectedDetail.multiLingualDisplayName.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode) + ".json");
    }

    @State.bound
    public async importAsync() {

        const importDialogResult = await this.modalService.showDialogAsync<IFormDefinitionImportDialogResult>(new FormDefinitionImportDialogParams());

        if (importDialogResult?.dataToImport) {
            const importedDefinition = this.formEngineApiAdapter.getFormDefinitionFromImportData(importDialogResult!.dataToImport);

            this.setDetailLoadedState(importedDefinition);

            this.editorHandle.setDefinition({
                id: importedDefinition.id,
                baseEntityType: importedDefinition.baseEntityType,
                name: importedDefinition.name,
                multiLingualDisplayName: importedDefinition.multiLingualDisplayName,
                enums: importedDefinition.formEnums,
                rootSchemaVersion: importedDefinition.rootSchema.getLatestVersionOrNull(),
                rootLayoutVersion: importedDefinition.rootLayout.getLatestVersionOrNull(),
                formEditBehavior: importedDefinition.formEditBehavior,
                formLogics: importedDefinition.formLogics,
                formLogicType: importedDefinition.formLogicType,
                formLogicTokens: importedDefinition.formLogicTokens,
                withoutDataHandling: importedDefinition.withoutDataHandling,
                isUserForm: importedDefinition.isUserForm,
                instanceLimit: importedDefinition.instanceLimit
            });
        }
    }

    @State.bound
    private checkEnumsForValidationProblems(formDefinition: IFormDefinitionVersion) {

        const enumsWithoutValues = formDefinition.rootSchemaVersion.dataElements.filter(dataElement =>
            dataElement instanceof EnumFormDataElement && isNullOrUndefined(dataElement.enumReference)) as EnumFormDataElement[];

        if (!arrayIsNullOrEmpty(enumsWithoutValues)) {

            this.notifyErrorFields(formDefinition, enumsWithoutValues, StaticFormEngineResources.FormDefinitionEditor.ValidationText.EnumValuesShouldBeFilled);
            return true;
        }
        return false;
    }

    @State.bound
    private checkReferencedEntitiesForValidationProblems(formDefinition: IFormDefinitionVersion) {

        const referencedEntityEditorsWithoutReferencedEntity = formDefinition.rootSchemaVersion.dataElements.filter(dataElement =>
            dataElement instanceof ReferencedEntityFormDataElement && isNullOrUndefined(dataElement.referencedEntity)) as ReferencedEntityFormDataElement[];

        if (!arrayIsNullOrEmpty(referencedEntityEditorsWithoutReferencedEntity)) {

            this.notifyErrorFields(formDefinition, referencedEntityEditorsWithoutReferencedEntity, StaticFormEngineResources.FormDefinitionEditor.ValidationText.ReferencedEntityShouldBeFilled);
            return true;
        }
        return false;
    }

    @State.bound
    private checkReferencedExtensibleEnumsForValidationProblems(formDefinition: IFormDefinitionVersion) {

        const referencedExtensibleEnumEditorsWithoutReferencedExtensibleEnum = formDefinition.rootSchemaVersion.dataElements.filter(dataElement =>
            dataElement instanceof ReferencedExtensibleEnumFormDataElement && isNullOrUndefined(dataElement.extensibleEnum)) as ReferencedExtensibleEnumFormDataElement[];

        if (!arrayIsNullOrEmpty(referencedExtensibleEnumEditorsWithoutReferencedExtensibleEnum)) {
            this.notifyErrorFields(formDefinition, referencedExtensibleEnumEditorsWithoutReferencedExtensibleEnum, StaticFormEngineResources.FormDefinitionEditor.ValidationText.ReferencedExtensibleEnumShouldBeFilled);
            return true;
        }
        return false;
    }

    @State.bound
    private checkReferencedEnumsForValidationProblems(formDefinition: IFormDefinitionVersion) {

        const referencedEnumEditorsWithoutReferencedEnum = formDefinition.rootSchemaVersion.dataElements.filter(dataElement =>
            dataElement instanceof ReferencedEnumFormDataElement && isNullOrUndefined(dataElement.enumName)) as ReferencedEnumFormDataElement[];

        if (!arrayIsNullOrEmpty(referencedEnumEditorsWithoutReferencedEnum)) {
            this.notifyErrorFields(formDefinition, referencedEnumEditorsWithoutReferencedEnum, StaticFormEngineResources.FormDefinitionEditor.ValidationText.ReferencedExtensibleEnumShouldBeFilled);
            return true;
        }
        return false;
    }

    private notifyErrorFields(formDefinition: IFormDefinitionVersion, formDataElementsWithErrors: FormDataElementBase[], errorMessageResource: string) {
        const allEditors = getAllEditors(formDefinition.rootLayoutVersion.content);

        let nameOfElementsWithErrors = "";
        formDataElementsWithErrors.forEach(elementWithError => {
            allEditors.forEach(editor => {
                if (editor.dataReference === elementWithError.name) {
                    nameOfElementsWithErrors = nameOfElementsWithErrors.concat(editor.multiLingualLabel.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode), ", ");
                }
            });
        });

        nameOfElementsWithErrors = nameOfElementsWithErrors.trimEnd();
        nameOfElementsWithErrors = nameOfElementsWithErrors.substring(0, nameOfElementsWithErrors.length - 1);

        this.notificationService.error(formatStringWithObjectParams(errorMessageResource, { Fields: nameOfElementsWithErrors }));
    }

    @State.action.bound
    public setPreviewMode(isPreview: boolean) {
        const dataToPreview = this.editorHandle.getDefinition();
        const referencedEntitiesHasValidationProblems = this.checkReferencedEntitiesForValidationProblems(dataToPreview);
        const enumsHasValidationProblems = this.checkEnumsForValidationProblems(dataToPreview);
        const referencedExtensibleEnumsValidationProblems = this.checkReferencedExtensibleEnumsForValidationProblems(dataToPreview);
        const referencedEnumsValidationProblems = this.checkReferencedEnumsForValidationProblems(dataToPreview);

        if (referencedEntitiesHasValidationProblems || enumsHasValidationProblems || referencedExtensibleEnumsValidationProblems || referencedEnumsValidationProblems) {
            return;
        }

        this.isPreviewMode = isPreview;

        if (isPreview) {

            this.previewFormDefinition = new PreviewFormDefinition(
                this.selectedId,
                this.selectedDetail.rowVersion,
                this.selectedDetail.name,
                dataToPreview.multiLingualDisplayName,
                dataToPreview.baseEntityType,
                new FormSchema(new FormSchemaId("new"), [dataToPreview.rootSchemaVersion]),
                new FormLayoutStore(new FormLayoutId("new"), [dataToPreview.rootLayoutVersion]),
                dataToPreview.enums,
                dataToPreview.formEditBehavior,
                dataToPreview.formLogics,
                dataToPreview.formLogicType,
                dataToPreview.formLogicTokens,
                dataToPreview.withoutDataHandling,
                dataToPreview.isUserForm,
                dataToPreview.instanceLimit);

            this.previewForm = new Form(
                FormInstanceId.new,
                RowVersion.initial,

                this.selectedId,
                createEmptyFormDataStore(
                    new FormSchemaReference(new FormSchemaId("new"), -1),
                    new FormLayoutReference(new FormLayoutId("new"), -1),
                    this.previewFormDefinition
                ),
                []
            );
        } else {
            this.previewFormDefinition = null;
            this.previewForm = null;
        }
    }
}
