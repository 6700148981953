import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { getUseCaseAsUrlParam, parseUseCaseStateFromUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import PerformanceReportScorePlanId from "@Primitives/PerformanceReportScorePlanId.g";
import HunSocialSecurityUseCases from "@HunSocialSecurityPlugin/UseCases/HunSocialSecurityUseCases";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import performanceReportScorePlanStandaloneRouteDefinitions from "@HunSocialSecurityPlugin/UseCases/PerformanceReportScorePlansUseCase/PerformanceReportScorePlanRoutes";
import { HisErrorBoundary } from "@HisPlatformControls";
import PageBoxContainer from "@CommonControls/PageBoxContainer";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import PerformanceReportScorePlanWorklistPanel from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Panels/PerformanceReportScorePlanWorklistPanel/PerformanceReportScorePlanWorklistPanel";

interface IPerformanceReportScorePlanPageDependencies {
    routingStore: GlobalRoutingStore;
    applicationContext: ApplicationContext;
    useCaseRegistry: IUseCaseRegistry;
}

interface IPerformanceReportScorePlanPageProps extends IRoutingFrameContentProps {
    _dependencies?: IPerformanceReportScorePlanPageDependencies;
}

@State.observer
class PerformanceReportScorePlanPage extends React.Component<IPerformanceReportScorePlanPageProps> {

    @State.computed
    private get selectedPerformanceReportScorePlanId(): PerformanceReportScorePlanId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments[0].value;
        }

        if (this.useCaseState?.useCase.value === HunSocialSecurityUseCases.performanceReportScorePlanDetail) {
            return new PerformanceReportScorePlanId("new");
        }

        return null;
    }

    @State.computed
    private get useCaseState() {
        if (!this.props.routingController.currentRoute.parameters.useCase) {
            return null;
        }
        const useCaseState = this.props.routingController.currentRoute.parameters.useCase;

        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.bound
    private onBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setUseCaseState(rowId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.push(performanceReportScorePlanStandaloneRouteDefinitions.performanceReportScorePlan.makeRoute({
            ...this.props.routingController.currentRoute.parameters,
            useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments),
            selectedRowId: rowId
        }));
    }

    @State.bound
    private navigateToPerformanceReportScorePlan(performanceReportScorePlanId: PerformanceReportScorePlanId) {
        this.props.routingController.replace(
            performanceReportScorePlanStandaloneRouteDefinitions.performanceReportScorePlan.makeRoute({
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(HunSocialSecurityUseCases.performanceReportScorePlanDetail),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(performanceReportScorePlanId, "performanceReportScorePlanId")])
            })
        );
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(
            performanceReportScorePlanStandaloneRouteDefinitions.performanceReportScorePlan.makeRoute({
                useCase: getUseCaseAsUrlParam(
                    null,
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument(new PerformanceReportScorePlanId("null"), "performanceReportScorePlanId")])
            })
        );
    }

    public render() {
        return (
            <div>
                <PageBoxContainer>
                    <HisErrorBoundary>
                        <SingleLayout>
                            <PerformanceReportScorePlanWorklistPanel
                                useCaseState={this.useCaseState}
                                onChange={this.setUseCaseState}
                                selectedRowId={this.selectedPerformanceReportScorePlanId?.value}
                                onBack={this.onBack}
                                onCloseDetail={this.setClosedState}
                                onSelectedPerformanceReportScorePlanIdChange={this.navigateToPerformanceReportScorePlan}
                            />
                        </SingleLayout>
                    </HisErrorBoundary>
                </PageBoxContainer>
            </div>
        );
    }
}

export default connect(
    withHisErrorBoundary(PerformanceReportScorePlanPage),
    new DependencyAdapter<IPerformanceReportScorePlanPageProps, IPerformanceReportScorePlanPageDependencies>(container => {
        return {
            routingStore: container.resolve("GlobalRoutingStore"),
            applicationContext: container.resolve("ApplicationContext"),
            useCaseRegistry: container.resolve("IUseCaseRegistry")
        };
    })
);
