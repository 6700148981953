import React from "react";
import IWidgetComponentProps from "@PluginInterface/Dashboard/IWidgetComponentProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import IRelevantServiceDisplayData from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/RelevantServicesWidget/IRelevantServiceDisplayData";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import RelevantServicesWidgetApiAdapter from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/RelevantServicesWidget/RelevantServicesWidgetApiAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import CareActivityId from "@Primitives/CareActivityId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import PatientId from "@Primitives/PatientId.g";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import DocumentPreviewModalParams from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Modals/DocumentPreviewModal/DocumentPreviewModalParams";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import CareActivityDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentApiAdapter";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";

interface IRelevantServicesWidgetDependencies {
    apiAdapter: RelevantServicesWidgetApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    localizationService: ILocalizationService;
    careDocumentApiAdapter: CareActivityDocumentApiAdapter;
}

interface IRelevantServicesWidgetProps extends IWidgetComponentProps {
    _dependencies?: IRelevantServicesWidgetDependencies;
    _patientId?: PatientId;
    _modalService?: IModalService;
}

/** @screen */
@State.observer
class RelevantServicesWidget extends React.Component<IRelevantServicesWidgetProps> {

    private get apiAdapter() { return this.props._dependencies.apiAdapter; }
    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get organizationReferenceDataStore() { return this.props._dependencies.organizationReferenceDataStore; }
    private get localizationService() { return this.props._dependencies.localizationService; }
    private get modalService() { return this.props._modalService!; }
    private get documentApiAdapter() { return this.props._dependencies.careDocumentApiAdapter; }

    @State.observable.ref private dateRange: LocalDateRange = null;
    @State.observable.ref private displayData: IRelevantServiceDisplayData[] = null;
    @State.observable.ref private serviceFilters: Identifier[] = null;

    @State.action.bound
    private setDateRange(newValue: LocalDateRange) {
        this.dateRange = newValue;
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.initializeAsync);
    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.action.bound
    private async openFinalDocumentAsync(careActivityId: CareActivityId) {
        const documentResponse = await this.documentApiAdapter.getSingleDocumentForCareActivityAsync(careActivityId);
        if (documentResponse.value) {
            await this.modalService.showModalAsync(new DocumentPreviewModalParams(documentResponse.value.id));
        }
    }

    @State.bound
    private async initializeAsync() {
        this.setInitialValues();
        await this.loadAsync();
    }

    @State.action.bound
    private setInitialValues() {
        this.dateRange = new LocalDateRange(LocalDate.create(2019, 1, 1), LocalDate.today());
        this.serviceFilters = isNullOrUndefined(this.props.configuration.ServiceFilters) ? [] : this.props.configuration.ServiceFilters.map((c: string) => {
            return new Identifier(new IdentifierSystemId("H"), c);
        });
    }

    @State.action.bound
    private async loadAsync() {
        await State.when(() => !!this.props._patientId);

        const response = await this.apiAdapter.getDataAsync(
            this.dateRange,
            this.props._patientId,
            this.serviceFilters
        );

        await this.careReferenceDataStore.medicalService.ensureLoadedAsync(response.value.map(m => m.service));
        await this.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync(response.value.map(o => o.pointOfCareId));

        State.runInAction(() => {
            this.displayData = response.value.map(d => {
                const medicalService = this.careReferenceDataStore.medicalService.get(d.service);
                const pointOfCare = this.organizationReferenceDataStore.allPointsOfCareMap.get(d.pointOfCareId);
                return {
                    service: medicalService.code.value + " " + medicalService.name,
                    pointOfCare: pointOfCare.shorthand,
                    createdAt: this.localizationService.localizeDateTime(d.createdAt),
                    careActivitiyId: d.careActivityId
                } as IRelevantServiceDisplayData;
            });
        });
    }

    private renderWidgetContent() {
        if (isNullOrUndefined(this.displayData) || this.displayData.length === 0) {
            return (
                <div style={{ textAlign: "center" }}>
                    {StaticProductivityResources.Widgets.RelevantServicesWidget.NotFoundMessage}
                </div>
            );
        }
        return (
            <Ui.Table visualStyle="dark" style={{ width: "98%", padding: "3px", margin: "auto", marginTop: "10px" }} automationId="relevantServicesWidgetContentTable">
                <thead>
                    <tr>
                        <th>{StaticProductivityResources.Widgets.RelevantServicesWidget.DateColumn}</th>
                        <th>{StaticProductivityResources.Widgets.RelevantServicesWidget.ServiceColumn}</th>
                        <th>{StaticProductivityResources.Widgets.RelevantServicesWidget.PointOfCareColumn}</th>
                        <th>{""}</th>
                    </tr>
                    {this.displayData && this.renderCells()}
                </thead>
            </Ui.Table>
        );
    }

    @State.bound
    private renderCells() {
        let i: number = 0;
        return this.displayData.map(d => {
            const openFinalDocumentAsync = async () => { await this.openFinalDocumentAsync(d.careActivitiyId); };
            return (
                <tr key={d.service + "_" + i++}>
                    <td>{d.createdAt}</td>
                    <td>{d.service}</td>
                    <td>{d.pointOfCare}</td>
                    <td><Ui.Button
                        visualStyle="secondary"
                        visualMode="dark"
                        iconName="document"
                        onClickAsync={openFinalDocumentAsync}
                        automationId={d.careActivitiyId + "_openFinalDocumentButton"} /></td>
                </tr>
            );
        });
    }

    private renderFilters() {
        return (
            <Ui.DateRangePicker
                value={this.dateRange}
                onChange={this.setDateRange}
                visualMode="dark"
                label={StaticProductivityResources.Widgets.RelevantServicesWidget.DateRangePickerTitle}
                clearable={false}
                automationId="relevantServicesWidgetDateRangePicker"
            />
        );
    }

    public render() {
        return (
            <Ui.Dashboard.Widget
                name={this.props.name}
                title={StaticProductivityResources.Widgets.RelevantServicesWidget.Title}
                icon={"chart"}
                isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                isCollapsible
                automationId="RelevantServicesWidget"
            >
                <>
                    {this.initialLoadPanelAsync.isUnauthorizedAccess ?
                        <UnauthorizedAccessContent visualStyle="dark" /> :
                        <>
                            {this.renderFilters()}
                            {this.renderWidgetContent()}
                        </>
                    }
                </>
            </Ui.Dashboard.Widget>
        );
    }
}

export default connect(
    withLoadingBoundary(RelevantServicesWidget),
    new DependencyAdapter<IRelevantServicesWidgetProps, IRelevantServicesWidgetDependencies>(c => ({
        apiAdapter: c.resolve("RelevantServicesWidgetApiAdapter"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService"),
        careDocumentApiAdapter: c.resolve("CareActivityDocumentApiAdapter")
    })),
    new PatientContextAdapter<IRelevantServicesWidgetProps>(c => ({
        _patientId: c.patientId
    })),
    new HisModalServiceAdapter()
);