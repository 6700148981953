import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalConditionItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionItemBase";
import MedicationStatementId from "@Primitives/MedicationStatementId.g";
import MedicationStatementSubjectAssignmentBase from "./MedicationStatementSubjectAssignmentBase";
import ReferencedMedicationAssignment from "./ReferencedMedicationAssignment";
import ReferencedInternationalNonproprietaryNameAssignment from "./ReferencedInternationalNonproprietaryNameAssignment";
import CustomSubjectAssignment from "./CustomSubjectAssignment";
import ReferencedSubstanceAssignment from "./ReferencedSubstanceAssignment";
import { MedicationStatementSubstanceCodeSelectorItem } from "@HisPlatformControls/MedicationStatementSubstanceCodeSelector/MedicationStatementSubstanceCodeSelectorItem";

export default class MedicationStatementStore extends MedicalConditionItemBase<MedicationStatementId> {

    @State.observable.ref public medicationStatementSubjectAssignment: MedicationStatementSubjectAssignmentBase;

    @State.observable public substanceName: string;

    constructor(isNew: boolean) {
        super(isNew);
    }

    @State.computed public get substanceCodeSelectorItem() {
        if (!this.medicationStatementSubjectAssignment) {
            return null;
        }
        if (this.medicationStatementSubjectAssignment instanceof ReferencedMedicationAssignment) {
            return new MedicationStatementSubstanceCodeSelectorItem ({ id: this.medicationStatementSubjectAssignment.medicationId });
        } else if (this.medicationStatementSubjectAssignment instanceof ReferencedSubstanceAssignment) {
            return new MedicationStatementSubstanceCodeSelectorItem({ id: this.medicationStatementSubjectAssignment.substanceId });
        } else if (this.medicationStatementSubjectAssignment instanceof ReferencedInternationalNonproprietaryNameAssignment) {
            return new MedicationStatementSubstanceCodeSelectorItem({ id: this.medicationStatementSubjectAssignment.internationalNonproprietaryNameId });
        } else if (this.medicationStatementSubjectAssignment instanceof CustomSubjectAssignment) {
            return new MedicationStatementSubstanceCodeSelectorItem({ text: this.medicationStatementSubjectAssignment.name });
        }
        return null;
    }

    @State.action.bound
    public setMedicationStatementSubjectAssignment(newValue: MedicationStatementSubjectAssignmentBase) {
        this.medicationStatementSubjectAssignment = newValue;
    }

    @State.action.bound
    public setSubstanceName(newValue: string) {
        this.substanceName = newValue;
    }
}