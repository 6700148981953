import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import FinancingClassId from "@Primitives/FinancingClassId.g";
import LateralityId from "@Primitives/LateralityId.g";
import AdmissionReasonId from "@Primitives/AdmissionReasonId.g";
import DischargeReasonId from "@Primitives/DischargeReasonId.g";
import DeathPlaceTypeId from "@Primitives/DeathPlaceTypeId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import PatientPractitionerTypeId from "@Primitives/PatientPractitionerTypeId.g";
import PractitionSpecialtyId from "@Primitives/PractitionSpecialtyId.g";
import PatientRelativeTypeId from "@Primitives/PatientRelativeTypeId.g";
import CareTypeId from "@Primitives/CareTypeId.g";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import MedicationFormId from "@Primitives/MedicationFormId.g";
import MedicationTypeId from "@Primitives/MedicationTypeId.g";
import DosageModeId from "@Primitives/DosageModeId.g";
import DosageFormId from "@Primitives/DosageFormId.g";
import SpecimenTypeId from "@Primitives/SpecimenTypeId.g";

@Di.injectable()
export default class CareExtensibleEnumApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("ICareRegisterClient") private readonly careRegisterApiClient: Proxy.ICareRegisterClient,
        @Di.inject("IPatientRegisterClient") private readonly patientRegisterApiClient: Proxy.IPatientRegisterClient,
        @Di.inject("IReferenceDataClient") private readonly referenceDataApiClient: Proxy.IReferenceDataClient,
        @Di.inject("IMedicationClient") private readonly medicationsApiClient: Proxy.IMedicationClient,
        @Di.inject("IMedicationStatementClient") private readonly medicationStatementClient: Proxy.IMedicationStatementClient,
        @Di.inject("IMedicalServicesClient") private readonly medicalServicesApiClient : Proxy.IMedicalServicesClient
    ) {
        super();
    }

    @State.bound
    public loadDiagnosisRoleIdsAsync(): Promise<SimpleStore<DiagnosisRoleId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterApiClient.getDiagnosisRoleIdsQueryAsync(...args),
            response => response.diagnosisRoleIds
        );
    }

    @State.bound
    public loadLateralityIdsAsync(): Promise<SimpleStore<LateralityId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterApiClient.getLateralityIdsQueryAsync(...args),
            response => response.lateralityIds
        );
    }

    @State.bound
    public loadFinancingClassIdsAsync(): Promise<SimpleStore<FinancingClassId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterApiClient.getFinancingClassIdsQueryAsync(...args),
            response => response.financingClassIds
        );
    }

    @State.bound
    public loadAdmissionReasonIdsAsync(): Promise<SimpleStore<AdmissionReasonId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterApiClient.getAdmissionReasonIdsQueryAsync(...args),
            response => response.admissionReasonIds
        );
    }

    @State.bound
    public loadDischargeReasonIdsAsync(): Promise<SimpleStore<DischargeReasonId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterApiClient.getDischargeReasonIdsQueryAsync(...args),
            response => response.dischargeReasonIds
        );
    }

    @State.bound
    public loadDeathPlaceTypeIdsAsync(): Promise<SimpleStore<DeathPlaceTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.patientRegisterApiClient.getDeathPlaceTypeIdsQueryAsync(...args),
            response => response.deathPlaceTypeIds
        );
    }

    @State.bound
    public loadPatientPractitionerTypeIdsAsync(): Promise<SimpleStore<PatientPractitionerTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.patientRegisterApiClient.getPatientPractitionerTypeIdsQueryAsync(...args),
            response => response.patientPractitionerTypeIds
        );
    }

    @State.bound
    public loadPractitionSpecialtyIdsAsync(): Promise<SimpleStore<PractitionSpecialtyId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.patientRegisterApiClient.getPractitionSpecialtyIdsQueryAsync(...args),
            response => response.practitionSpecialtyIds
        );
    }

    @State.bound
    public loadPatientRelativeTypeIdsAsync(): Promise<SimpleStore<PatientRelativeTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.patientRegisterApiClient.getPatientRelativeTypeIdsQueryAsync(...args),
            response => response.patientRelativeTypeIds
        );
    }

    @State.bound
    public loadCareTypeIdsAsync(): Promise<SimpleStore<CareTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.careRegisterApiClient.getCareTypeIdsQueryAsync(...args),
            response => response.careTypeIds
        );
    }

    @State.bound
    public loadTextBlockTypeIdsAsync(): Promise<SimpleStore<TextBlockTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.referenceDataApiClient.getTextBlockTypeIdsQueryAsync(...args),
            response => response.textBlockTypeIds
        );
    }

    @State.bound
    public loadMedicationFormIdsAsync(): Promise<SimpleStore<MedicationFormId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.medicationsApiClient.getMedicationFormIdsQueryAsync(...args),
            response => response.medicationFormIds
        );
    }

    @State.bound
    public searchMedicationFormIdsAsync(medicationFormIdPrefix: string): Promise<SimpleStore<MedicationFormId[]>> {
        return this.loadExtensibleEnumAsync(
            (requestId, validOn, isPermissionCheckOnly) => this.medicationsApiClient.searchMedicationFormIdsQueryAsync(requestId, medicationFormIdPrefix, isPermissionCheckOnly),
            response => response.medicationFormIds
        );
    }

    @State.bound
    public loadMedicationTypeIdsAsync(): Promise<SimpleStore<MedicationTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.medicationsApiClient.getMedicationTypeIdsQueryAsync(...args),
            response => response.medicationTypeIds
        );
    }

    @State.bound
    public loadDosageModeIdsAsync(): Promise<SimpleStore<DosageModeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.medicationsApiClient.getDosageModeIdsQueryAsync(...args),
            response => response.dosageModeIds
        );
    }

    @State.bound
    public loadDosageFormIdsAsync(): Promise<SimpleStore<DosageFormId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.medicationStatementClient.getDosageFormIdsQueryAsync(...args),
            response => response.dosageFormIds
        );
    }

    @State.bound
    public loadSpecimenTypeIdsAsync(): Promise<SimpleStore<SpecimenTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.medicalServicesApiClient.getSpecimenTypeIdsQueryAsync(...args),
            response => response.specimenTypeIds
        );
    }
}
