import IWidgetRegistry, { WidgetComponent } from "@PluginInterface/Dashboard/IWidgetRegistry";
import WidgetName from "@Primitives/WidgetName.g";
import Di from "@Di";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

@Di.injectable()
export default class WidgetRegistry implements IWidgetRegistry {
    private widgetMap = new Map<string, WidgetComponent>();
    private factoryMap = new Map<string, () => object>();
    public createStore(widgetName: WidgetName): object {
        if (!isNullOrUndefined(this.factoryMap.get(widgetName.value))) {
            return this.factoryMap.get(widgetName.value)();
        }
        return null;
    }

    public register(widgetName: WidgetName, widgetComponent: WidgetComponent, storeFactory?: () => object): void {
        if (this.widgetMap.has(widgetName.value)) {
            throw new Error(`Widget with the same name is already added: '${widgetName.value}'.`);
        }
        this.widgetMap.set(widgetName.value, widgetComponent);
        if (!isNullOrUndefined(storeFactory)) {
            this.factoryMap.set(widgetName.value, storeFactory);
        }

    }

    public get(widgetName: WidgetName): WidgetComponent {
        return this.widgetMap.get(widgetName.value);
    }
}