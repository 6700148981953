import React from "react";
import Styles from "./TextBox.less";
import { ICommonControlProps, getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export interface ITextBoxGroupProps extends ICommonControlProps {
    onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLDivElement>) => void;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    embedded?: boolean;
    fluid?: boolean;
}

export default class TextBoxGroup extends React.PureComponent<ITextBoxGroupProps> {

    private thisElement: HTMLDivElement;

    public static defaultProps: Partial<ITextBoxGroupProps> = {
        fluid: true
    };

    @State.bound
    private onBlurHandler(event: React.FocusEvent<HTMLDivElement>) {
        const relatedTarget = event.relatedTarget as HTMLElement;
        if (!relatedTarget || relatedTarget.parentElement.parentElement !== this.thisElement) {
            this.props.onBlur(event);
        }
    }

    public render() {
        const classes = new CompositeClassName(Styles.textBoxGroup);
        classes.addIf(!this.props.embedded, Styles.textBoxGroupFocusable);
        classes.addIf(!this.props.embedded, Styles.textBoxGroupStandard);
        classes.addIf(this.props.embedded, Styles.textBoxGroupEmbedded);
        classes.addIf(this.props.fluid, Styles.textBoxGroupFluid);
        const htmlProps = getStandardHtmlProps(this.props, classes.classNames);
        return (
            <div onBlur={this.onBlurHandler} onFocus={this.props.onFocus} {...htmlProps} ref={(me) => this.thisElement = me}>
                {this.props.children}
            </div>
        );
    }
}