import AppointmentScheduleSlotId from "@Primitives/AppointmentScheduleSlotId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { lazyResolve } from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import AppointmentScheduleSlotSeriesId from "@Primitives/AppointmentScheduleSlotSeriesId.g";
import moment from "moment";
import SlotStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/SlotStatus.g";

export default class SlotStore extends EntityStoreBase<AppointmentScheduleSlotId> {

    @lazyResolve("ILocalizationService") private localizationService: ILocalizationService;

    public from: moment.Moment;
    public to: moment.Moment;
    @State.observable public status: SlotStatus;
    public isOverBooked: boolean;

    public appointmentScheduleSlotSeriesId: AppointmentScheduleSlotSeriesId;

    constructor() {
        super();
    }

    public get getLocalizedValue() {
        return this.localizationService.localizeEnum(SlotStatus[this.status], "SlotStatus").Name;
    }

    public get interval() {
        return `${this.from.toDate().toLocaleString()} - ${this.to.toDate().toLocaleString()}`;
    }
}