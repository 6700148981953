import React from "react";
import PatientId from "@Primitives/PatientId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import * as Ui from "@CommonControls";
import EhiErrorSummary from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiErrorSummary";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import PractitionerColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerColumn/PractitionerColumn";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import EhiCareActivityBaseDataPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCareActivityBaseDataPanel/EhiCareActivityBaseDataPanel";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import ViewContextProvider from "@Toolkit/ReactClient/Components/ViewContext/ViewContextProvider";
import CareActivitiyStateColumn2 from "@HisPlatform/BoundedContexts/Care/Components/Controls/DataGridColumns/CareActivitiyStateColumn";
import DataGridDateTimeRangeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeRangeColumn";
import OrganizationUnitOrExternalLocationColumn from "@HisPlatformControls/DataGridColumns/OrganizationUnitOrExternalLocationColumn";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import EhiCareService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareService";
import EhiServiceCallStatus from "@HunEHealthInfrastructurePlugin/Model/EhiServiceCallStatus";

interface IEhiEmergencyEventsPanelDependencies {
    ehiCareReferenceDataStore: HunEhiCareReferenceDataStore;
}

interface IEhiEmergencyEventsPanelProps {
    _dependencies?: IEhiEmergencyEventsPanelDependencies;
    ehiCareService: EhiCareService;
    ehiId: string;
    patientId: PatientId;
    isEmergency: boolean;
    emergencyReason: string;
}

/** @screen */
@State.observer
class EhiEmergencyEventsPanel extends React.Component<IEhiEmergencyEventsPanelProps> {

    @State.observable.ref private isLoading = false;
    @State.observable.ref private events: PatientsCareActivityStore[] = [];
    @State.observable.ref private errors: EhiError[] = [];

    @State.observable.ref private selectedEvent: PatientsCareActivityStore | null = null;
    @State.computed private get isDetailOpen() {
        return !isNullOrUndefined(this.selectedEvent);
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);
    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IEhiEmergencyEventsPanelProps) {
        if (this.props.ehiId !== prevProps.ehiId || this.props.patientId !== this.props.patientId) {
            dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        }
    }

    @State.boundLoadingState()
    private async loadAsync() {

        if (this.props.patientId) {
            const ehiServiceCallResult = await this.props.ehiCareService.getEventEmergencyEventsAsync(
                this.props.patientId,
                this.props.ehiId,
                this.props.isEmergency,
                this.props.emergencyReason);

            if (ehiServiceCallResult.ehiServiceCallStatus === EhiServiceCallStatus.Success &&
                ehiServiceCallResult.result &&
                ehiServiceCallResult.result.operationWasSuccessful) {

                const emergencyEvents = ehiServiceCallResult.result.value;

                State.runInAction(() => {
                    this.events = emergencyEvents?.value;
                    this.errors = emergencyEvents?.errors;
                });
            }
        }
    }

    public render() {
        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }

        return (
            <SingleLayout>
                <MasterDetailLayout
                    isLoading={this.isLoading}
                    selectedItem={this.selectedEvent}
                    onClose={this.close}
                    isCompact
                    compactListSize={210}
                    master={(
                        <MasterDetail.Master title={null} hideHeader>
                            <Ui.DataGrid
                                dataSource={this.events}
                                extraFilter={!this.isDetailOpen && <EhiErrorSummary errors={this.errors} />}
                                onRowClick={this.select}
                                isExtraFilterVisible
                                fixedLayout
                                fixedHeight="100%"
                                rowHeight={49}
                                selectedRow={this.selectedEvent}
                                isSelectable
                                automationId="ehiEmergencyEventsPanel_dataGrid"
                            >
                                <CareActivitiyStateColumn2
                                    dataGetter="careTypeId"
                                    careActivityStateDataGetter="careActivityState"
                                    header=""
                                    width="80px"
                                />
                                <DataGridDateTimeRangeColumn
                                    id="carePeriod"
                                    dataGetter="carePeriod"
                                    header={StaticCareResources.EHR.PatientsCareActivities.ColumnHeaders.CareDateRange}
                                    isOrderable
                                />
                                <DataGridColumn
                                    id="careIdentifier"
                                    isVisible={!this.isDetailOpen}
                                    dataGetter="careIdentifier"
                                    header={StaticCareResources.EHR.PatientsCareActivities.ColumnHeaders.CareIdentifier}
                                    isOrderable
                                />
                                <OrganizationUnitOrExternalLocationColumn
                                    id="organizationUnitId"
                                    isVisible={!this.isDetailOpen}
                                    dataGetter="organizationUnitId"
                                    fallbackValueGetter="pointOfCareCode"
                                    showName={!this.isDetailOpen}
                                    header={StaticCareResources.EHR.PatientsCareActivities.ColumnHeaders.OrganizationUnit}
                                    isOrderable
                                />
                                <PractitionerColumn
                                    isVisible={!this.isDetailOpen}
                                    id="practitionerId"
                                    dataGetter="practitionerId"
                                    fallbackValueGetter="leadDoctorCode"
                                    header={StaticCareResources.EHR.PatientsCareActivities.ColumnHeaders.LeadDoctor}
                                    isOrderable
                                    isCodeBold
                                />
                                <OrganizationUnitOrExternalLocationColumn
                                    isVisible={!this.isDetailOpen}
                                    id="referralWorkplaceId"
                                    dataGetter="referralWorkplaceId"
                                    fallbackValueGetter="referralWorkplaceCode"
                                    showName={this.isDetailOpen}
                                    header={StaticCareResources.EHR.PatientsCareActivities.ColumnHeaders.ReferralWorkplace}
                                    isOrderable
                                />
                                <PractitionerColumn
                                    isVisible={!this.isDetailOpen}
                                    id="referralDoctorId"
                                    dataGetter="referralDoctorId"
                                    fallbackValueGetter="referralDoctorCode"
                                    header={StaticCareResources.EHR.PatientsCareActivities.ColumnHeaders.ReferralDoctor}
                                    isCodeBold
                                />
                            </Ui.DataGrid>
                        </MasterDetail.Master>
                    )}
                    detail={(
                        <MasterDetail.Detail
                            title={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.EmergencyEvents.EmrgencyEventDetailsTitle}
                            hasSidePadding
                            hasVerticalPadding
                        >
                            <EhiCareActivityBaseDataPanel
                                isEmergency={this.props.isEmergency}
                                emergencyReason={this.props.emergencyReason}
                                patientId={this.props.patientId}
                                ehiId={this.selectedEvent?.extensionData?.EhiId}
                                ehiCareService={this.props.ehiCareService}
                            />
                        </MasterDetail.Detail>
                    )}
                />
            </SingleLayout>
        );
    }

    @State.action.bound
    private close() {
        this.selectedEvent = null;
    }

    @State.action.bound
    private select(row: PatientsCareActivityStore) {
        this.selectedEvent = row;
    }

}

export default connect(
    EhiEmergencyEventsPanel,
    new DependencyAdapter<IEhiEmergencyEventsPanelProps, IEhiEmergencyEventsPanelDependencies>(container => {
        return {
            ehiCareReferenceDataStore: container.resolve("HunEhiCareReferenceDataStore")
        };
    })
);