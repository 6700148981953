import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class VaccineDetail {
    @State.observable.ref public manufacturer: string;
    @State.observable.ref public modelName: string;
    @State.observable.ref public modelType: string;
    @State.observable.ref public serialNumber: string;

    constructor(manufacturer: string, modelName: string, modelType: string, serialNumber: string) {
        this.manufacturer = manufacturer;
        this.modelName = modelName;
        this.modelType = modelType;
        this.serialNumber = serialNumber;
    }

    @State.action.bound
    public setManufacturer(newValue: string) {
        this.manufacturer = newValue;
    }
    @State.action.bound
    public setModelName(newValue: string) {
        this.modelName = newValue;
    }
    @State.action.bound
    public setModelType(newValue: string) {
        this.modelType = newValue;
    }

    @State.action.bound
    public setSerialNumber(newValue: string) {
        this.serialNumber = newValue;
    }
}
