import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IHunSocialSecurityReportingResources from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/StaticResources/IHunSocialSecurityReportingResources";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";

const resources = createStaticResourceDictionary<IHunSocialSecurityReportingResources>();

export function loadStaticHunSocialSecurityReportingResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    resources.load(localizationService.getResourceGroup("WebApp").Reporting);
}

export default resources as IHunSocialSecurityReportingResources;