import React, { ReactNode } from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import IDataGridDataSource from "@CommonControls/DataGrid/DataSource/IDataGridDataSource";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IPagingState, DataGridLoadType, IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MultiSelectorStore from "./MultiSelectorStore";


interface IMultiSelectorBaseDependencies {

}

export interface IMultiSelectorBaseProps {
    orientation?: "horizontal" | "vertical";
    chosenItemsTableTitle?: string;
    allItemsTableTitle?: string;
    pageSize?: number;
    paging?: IPagingState;
    addButtonText?: string;
    removeButtonText?: string;
    automationId?: string;
    selectedItemsFilterStore?: any;
    selectedItemsPaging?: IPagingState;
    selectedItemsOnChange?: (
        type: DataGridLoadType,
        paging: IPagingState,
        ordering: IOrderingState | IOrderingState[],
        filter: any,
        columns: IDataGridColumnProps[]
    ) => Promise<void>;
    generateInitialFilterStore?: boolean;
    changeOnMount?: boolean;
    availablePageSizes?: number[];
    hidePager?: boolean;
    ignoreDisable?: boolean;
    rowHeight?: number | string;
}

export interface IMultiSelectorProps extends IMultiSelectorBaseProps {
    _dependencies?: IMultiSelectorBaseDependencies;
    allItemsDataSource: IDataGridDataSource;
    allItemsOnChange?: (
        type: DataGridLoadType,
        paging: IPagingState,
        ordering: IOrderingState | IOrderingState[],
        filter: any,
        columns: IDataGridColumnProps[]
    ) => Promise<void>;
    allItemsIsLoading?: boolean;
    selectedItemsDataSource: IDataGridDataSource;
    renderColumns: (isSelectedGrid?: boolean) => React.ReactNode;
    renderSelectedColumns?: () => React.ReactNode;
    renderExtraRemoveButtons?: (value: any, row: any) => ReactNode;
    canRemove?: (entity: any) => Promise<boolean>;
    onAdded?: (entity: any) => void;
    onRemoved?: (entity: any) => void;
    fixedHeight?: number | string;
}

@State.observer
class MultiSelector extends React.Component<IMultiSelectorProps> {

    public static defaultProps: Partial<IMultiSelectorProps> = {
        orientation: "horizontal"
    };

    private store: MultiSelectorStore = new MultiSelectorStore();

    constructor(props: IMultiSelectorProps) {
        super(props);
        this.store.reInitDataSources(this.props);
    }

    public componentDidUpdate(prevProps: IMultiSelectorProps) {
        if (prevProps.selectedItemsDataSource !== this.props.selectedItemsDataSource || prevProps.allItemsDataSource !== this.props.allItemsDataSource) {
            this.store.reInitDataSources(this.props);
        }
    }

    public render() {
        if (this.props.orientation === "horizontal") {
            return (
                <Ui.Flex automationId={this.props.automationId}>
                    <Ui.Flex.Item xs={6}>
                        {this.renderAllItemsTable()}
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        {this.renderChosenItemsTable()}
                    </Ui.Flex.Item>
                </Ui.Flex>
            );
        } else {
            return (
                <div data-automation-id={this.props.automationId || undefined}>
                    <div>{this.renderAllItemsTable()}</div>
                    <div>{this.renderChosenItemsTable()}</div>
                </div>
            );
        }
    }

    private renderAllItemsTable() {
        const columns = this.props.renderColumns(false);

        return (
            <Ui.DataGrid
                dataSource={this.store.allItemsDataSource}
                onChangeAsync={this.props.allItemsOnChange}
                automationId="__allItemsGrid"
                fixedLayout
                rowHeight={this.props.rowHeight || 44}
                generateInitialFilterStore={this.props.generateInitialFilterStore}
                changeOnMount={this.props.changeOnMount}
                availablePageSizes={this.props.availablePageSizes}
                fixedHeight={this.props.fixedHeight}
                hidePager={this.props.hidePager}
                paging={this.props.paging}
                isLoading={this.props.allItemsIsLoading}
            >
                {columns}
                <DataGridColumn
                    id="add"
                    onRenderCellValue={this.renderAddColumn}
                    width="46px"
                    automationId="MoveButton"
                    cellTextAlign="center"
                />
            </Ui.DataGrid>
        );
    }

    private renderChosenItemsTable() {
        const renderFunc = this.props.renderSelectedColumns ? this.props.renderSelectedColumns : this.props.renderColumns;
        const columns = renderFunc(true);

        return (
            <Ui.DataGrid
                dataSource={this.store.selectedItemsDataSource}
                automationId="__chosenItemsGrid"
                filterStore={this.props.selectedItemsFilterStore}
                paging={this.props.selectedItemsPaging}
                fixedLayout
                rowHeight={this.props.rowHeight || 44}
                onChangeAsync={this.props.selectedItemsOnChange}
                generateInitialFilterStore={this.props.generateInitialFilterStore}
                changeOnMount={this.props.changeOnMount}
                availablePageSizes={this.props.availablePageSizes}
                fixedHeight={this.props.fixedHeight}
                hidePager={this.props.hidePager}
            >
                {columns}
                <DataGridColumn
                    id="remove"
                    onRenderCellValue={this.renderRemoveColumn}
                    width="46px"
                    automationId="MoveButton"
                    cellTextAlign="right"
                />
            </Ui.DataGrid>
        );
    }

    @State.bound
    private renderAddColumn(value: any, row: any) {
        return (
            <Ui.Button
                onClickAsync={this.addAsyncHandlerFactory(row)}
                visualStyle="standard"
                disabled={!this.props.ignoreDisable && this.store.isItemSelected(row)}
                float="right"
                iconName="plus"
                size="compact"
                text={this.props.addButtonText}
                automationId="__moveItemButton"
                style={{ marginRight: 10 }}
            />
        );
    }

    @State.bound
    private renderRemoveColumn(value: any, row: any) {
        return (
            <div>
                <Ui.Button
                    onClickAsync={this.removeAsyncHandlerFactory(row)}
                    visualStyle="negative-standard"
                    iconName="minus"
                    float="right"
                    size="compact"
                    text={this.props.removeButtonText}
                    automationId="__moveItemButton"
                    style={{ marginRight: 10 }}
                />
                {this.props.renderExtraRemoveButtons && this.props.renderExtraRemoveButtons(value, row)}
            </div>
        );
    }

    @State.bound
    private addAsyncHandlerFactory(row: any) {
        return async () => {
            if (this.props.onAdded) {
                this.props.onAdded(row);
                this.props.allItemsOnChange ? await this.props.allItemsOnChange("changed", this.props.paging, null, null, null) : await this.store.reloadAsync();
            }
        };
    }

    @State.bound
    private removeAsyncHandlerFactory(row: any) {
        return async () => {
            if (this.props.canRemove) {
                const canRemove = await this.props.canRemove(row);
                if (canRemove && this.props.onRemoved) {
                    this.props.onRemoved(row);
                    this.props.allItemsOnChange ? await this.props.allItemsOnChange("changed", this.props.paging, null, null, null) : await this.store.reloadAsync();
                }
            } else {
                this.props.onRemoved(row);
                this.props.allItemsOnChange ? await this.props.allItemsOnChange("changed", this.props.paging, null, null, null) : await this.store.reloadAsync();
            }
        };
    }
}

export default connect(
    MultiSelector,
    new DependencyAdapter<IMultiSelectorProps, IMultiSelectorBaseDependencies>(container => {
        return {};
    })
);
