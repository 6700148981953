import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import PerformedServicesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/PerformedServices/PerformedServicesApiAdapter";
import { IMultiEntitySelectorPublicProps } from "@CommonControls/MultiEntitySelectorBase";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import MedicalServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServiceStore";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import CareActivityId from "@Primitives/CareActivityId.g";
import _ from "@HisPlatform/Common/Lodash";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import IMedicalServiceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalServiceVersion";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface ICareActivityMedicalServiceMultiSelectBoxDependencies {
    performedServicesApiAdapter: PerformedServicesApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
}

interface ICareActivityMedicalServiceMultiSelectBoxProps extends IMultiEntitySelectorPublicProps<MedicalServiceId, MedicalServiceStore> {
    _dependencies?: ICareActivityMedicalServiceMultiSelectBoxDependencies;
    careActivityId: CareActivityId;
    onChange?: (allIds: MedicalServiceId[]) => void;
    selectedIds: MedicalServiceId[];
    fixedHeight?: string | number;
    filterIdSet?: MedicalServiceId[];
}

@State.observer
class CareActivityMedicalServiceMultiSelectBox extends React.Component<ICareActivityMedicalServiceMultiSelectBoxProps> {
    @State.observable.ref private allItemsDataGridDataSource = new InMemoryDataGridDataSource(() => this.allItemsDataSource);
    @State.observable.ref private selectedItemsDataGridDataSource = new InMemoryDataGridDataSource(() => this.selectedItemsDataSource);
    @State.observable.ref private allItems: IMedicalServiceVersion[] = [];

    private get dependencies() {
        return this.props._dependencies;
    }

    @State.computed
    private get allItemsDataSource() {
        return this.allItems.filter(item => !this.props.selectedIds.some(id => wrappedValuesAreEquals(id, item.id)));
    }

    @State.computed
    private get selectedItemsDataSource() {
        return this.allItems.filter(item => this.props.selectedIds.some(id => wrappedValuesAreEquals(id, item.id)));
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.reloadAsync(), this);
    }

    public componentDidUpdate(prevProps: ICareActivityMedicalServiceMultiSelectBoxProps) {
        if (prevProps.filterIdSet !== this.props.filterIdSet) {
            dispatchAsyncErrors(this.reloadAsync(), this);
        }
    }

    @State.bound
    private async reloadAsync() {
        const response = await this.dependencies.performedServicesApiAdapter.loadByIdAsync(this.props.careActivityId, false);
        const selectors = response.getAllPerformedServices.map(item => item.medicalServiceId);
        await this.dependencies.careReferenceDataStore.medicalService.ensureLoadedAsync(selectors);
        const filterRawIdSet = this.props.filterIdSet ? new Set(this.props.filterIdSet?.map(i => i.value)) : null;
        const items = selectors.filter(i => filterRawIdSet?.has(i.id.value) ?? true).map(item => this.dependencies.careReferenceDataStore.medicalService.get(item));
        this.setAllItems(items);
        this.setOrdering();
    }

    @State.action.bound
    private setAllItems(items: IMedicalServiceVersion[]) {
        this.allItems = items;
    }

    @State.action.bound
    private addNewItem(item: IMedicalServiceVersion) {
        const ids = [...this.selectedItemsDataSource, item].map(i => i.id);
        this.props.onChange(ids);
    }

    @State.action.bound
    private removeItem(item: IMedicalServiceVersion) {
        const ids = [...this.selectedItemsDataSource].filter(i => i !== item).map(i => i.id);
        this.props.onChange(ids);
    }

    @State.action.bound
    private setOrdering() {
        this.allItemsDataGridDataSource.ordering = { direction: "asc", columnId: 0 } as IOrderingState;
        this.selectedItemsDataGridDataSource.ordering = { direction: "asc", columnId: 0 } as IOrderingState;
    }

    private renderCell(_: any, row: IMedicalServiceVersion) {
        return (
            <>
                <b>{row.code.value}</b> {row.name}
            </>
        );
    }

    private renderColumns(header: string, automationId: string) {
        return (
            <DataGridColumn
                id={0}
                onRenderCellValue={this.renderCell}
                header={header}
                automationId={automationId}
                isOrderable
                isFilterable
                clientSideFilterableValueGetter={this.getFilterValue}
                clientSideOrderableValueGetter={this.getOrderValue}
            />
        );
    }

    @State.action.bound
    private renderAllItemsColumn() {
        return this.renderColumns(StaticCareResources.CareRegister.CareActivityMedicalServiceMultiSelectBox.AllItems, "allServices");
    }

    @State.action.bound
    private renderChosenItemsColumn() {
        return this.renderColumns(StaticCareResources.CareRegister.CareActivityMedicalServiceMultiSelectBox.SelectedItems, "selectedServices");
    }

    private getFilterValue(_: any, row: IMedicalServiceVersion) {
        return `${row.code.value}|${row.name}`;
    }

    private getOrderValue(_: any, row: IMedicalServiceVersion) {
        return row.code.value;
    }

    public render() {
        return (
            <Ui.MultiEntitySelectorBase
                {...this.props}
                renderColumns={this.renderAllItemsColumn}
                renderSelectedColumns={this.renderChosenItemsColumn}
                allItemsDataSource={this.allItemsDataGridDataSource}
                selectedItemsDataSource={this.selectedItemsDataGridDataSource}
                onAdded={this.addNewItem}
                onRemoved={this.removeItem}
                automationId="__multiEntitySelector"
                generateInitialFilterStore
                changeOnMount
                fixedHeight={this.props.fixedHeight}
                hidePager={this.props.hidePager}
            />
        );
    }
}

export default connect(
    CareActivityMedicalServiceMultiSelectBox,
    new DependencyAdapter<ICareActivityMedicalServiceMultiSelectBoxProps, ICareActivityMedicalServiceMultiSelectBoxDependencies>(container => {
        return {
            performedServicesApiAdapter: container.resolve("PerformedServicesApiAdapter"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore")
        };
    })
);
