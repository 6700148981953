import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ReferenceDataApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/ReferenceDataApiAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IMedicationInfo } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IMedicationInfo";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ISubstanceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/ISubstanceVersion";
import ReactHtmlParser from "react-html-parser";
import MedicationPricingAndSubsidies from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationPricingAndSubsidies";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import Styles from "./MedicationInfoPanel.less";
import MedicationEquivalenceListPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/MedicationEquivalenceListPanel/MedicationEquivalenceListPanel";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import MedicationSubsidizedPricingTypeId from "@Primitives/MedicationSubsidizedPricingTypeId.g";
import _ from "@HisPlatform/Common/Lodash";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import MedicationId from "@Primitives/MedicationId.g";
import MedicationsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicationsApiAdapter";
import MedicationVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/MedicationVersion";
import ITitleGroup from "@CommonControls/TitleGroup/ITitleGroup";
import { IMedicationEquivalenceListItem } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationInfo/IMedicationEquivalence";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";

interface IMedicationInfoPanelDependencies {
    apiAdapter: ReferenceDataApiAdapter;
    referenceDataStore: CareReferenceDataStore;
    localizationService: ILocalizationService;
    commonReferenceDataStore: CommonReferenceDataDataStore;
    medicationRequestReferenceDataStore: MedicationRequestReferenceDataStore;
    medicationsApiAdapter: MedicationsApiAdapter;
}

export interface IMedicationInfoPanelProps {
    _dependencies?: IMedicationInfoPanelDependencies;
    _isInlineDetail?: boolean;

    medicationId: MedicationId;
    onClose?: () => void;
    onClick?: (medication: IMedicationEquivalenceListItem) => void;
}

@State.observer
class MedicationInfoPanel extends React.Component<IMedicationInfoPanelProps> {

    private get apiAdapter() { return this.props._dependencies.apiAdapter; }
    private get referenceDataStore() { return this.props._dependencies.referenceDataStore; }
    private get localizationService() { return this.props._dependencies.localizationService; }
    private get commonReferenceDataStore() { return this.props._dependencies.commonReferenceDataStore; }
    private get hunSocialSecurityReferenceDataStore() { return this.props._dependencies.medicationRequestReferenceDataStore; }
    private get medicationsApiAdapter() { return this.props._dependencies.medicationsApiAdapter; }
    private get resources() { return StaticCareResources.ReferenceData.MedicationInfoPanel; }

    private scrollViewRef = React.createRef<Ui.ScrollView>();

    @State.observable private medicationInfo: IMedicationInfo = null;
    @State.observable private substances: ISubstanceVersion[] = null;
    @State.observable private medicationPricingAndSubsidies: MedicationPricingAndSubsidies = null;
    @State.observable private medication: MedicationVersion = null;
    @State.observable private currentlySelectedTitleId: string = null;

    private readonly claimTypeCodesToDisplay = ["HM", "ÜB", "KGY"];

    private panelIds = [
        "ingredients",
        "medicationForm",
        "clinicalProperties",
        "therapyRecommendations",
        "dosage",
        "counterRecommendations",
        "warnings",
        "interactions",
        "pregnancyApplication",
        "drivingEffects",
        "sideEffects",
        "overdose",
        "pharmacologyProperties",
        "pharmacodynamicProperties",
        "pharmacokineticProperties",
        "preClinicalStudyResults",
        "pharmaData",
        "auxiliarySubstances",
        "incompatibility",
        "expiration",
        "storageInstructions",
        "packaging",
        "handlingInstruction"
    ];

    private titles = [
        {
            label: this.resources.Label.Ingredients,
            elementId: "ingredients"
        } as ITitleGroup,
        {
            label: this.resources.Label.MedicationForm,
            elementId: "medicationForm"
        } as ITitleGroup,
        {
            label: this.resources.Label.ClinicalProperties,
            elementId: "clinicalProperties",
            childrenTitles: [
                {
                    label: this.resources.Label.TherapyRecommendations,
                    elementId: "therapyRecommendations"
                } as ITitleGroup,
                {
                    label: this.resources.Label.Dosage,
                    elementId: "dosage"
                } as ITitleGroup,
                {
                    label: this.resources.Label.CounterRecommendations,
                    elementId: "counterRecommendations"
                } as ITitleGroup,
                {
                    label: this.resources.Label.Warnings,
                    elementId: "warnings"
                } as ITitleGroup,
                {
                    label: this.resources.Label.Interactions,
                    elementId: "interactions"
                } as ITitleGroup,
                {
                    label: this.resources.Label.PregnancyApplication,
                    elementId: "pregnancyApplication"
                } as ITitleGroup,
                {
                    label: this.resources.Label.DrivingEffects,
                    elementId: "drivingEffects"
                } as ITitleGroup,
                {
                    label: this.resources.Label.SideEffects,
                    elementId: "sideEffects"
                } as ITitleGroup,
                {
                    label: this.resources.Label.Overdose,
                    elementId: "overdose"
                } as ITitleGroup
            ]
        } as ITitleGroup,
        {
            label: this.resources.Label.PharmacologyProperties,
            elementId: "pharmacologyProperties",
            childrenTitles: [
                {
                    label: this.resources.Label.PharmacodynamicProperties,
                    elementId: "pharmacodynamicProperties"
                } as ITitleGroup,
                {
                    label: this.resources.Label.PharmacokineticProperties,
                    elementId: "pharmacokineticProperties"
                } as ITitleGroup,
                {
                    label: this.resources.Label.PreClinicalStudyResults,
                    elementId: "preClinicalStudyResults"
                } as ITitleGroup,
            ]
        } as ITitleGroup,
        {
            label: this.resources.Label.PharmaData,
            elementId: "pharmaData",
            childrenTitles: [
                {
                    label: this.resources.Label.AuxiliarySubstances,
                    elementId: "auxiliarySubstances"
                } as ITitleGroup,
                {
                    label: this.resources.Label.Incompatibility,
                    elementId: "incompatibility"
                } as ITitleGroup,
                {
                    label: this.resources.Label.Expiration,
                    elementId: "expiration"
                } as ITitleGroup,
                {
                    label: this.resources.Label.StorageInstructions,
                    elementId: "storageInstructions"
                } as ITitleGroup,
                {
                    label: this.resources.Label.Packaging,
                    elementId: "packaging"
                } as ITitleGroup,
                {
                    label: this.resources.Label.HandlingInstruction,
                    elementId: "handlingInstruction"
                } as ITitleGroup
            ]
        } as ITitleGroup
    ];

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
        this.setSelectedTitleId("ingredients");
    }

    public componentDidUpdate(prevProps: IMedicationInfoPanelProps) {
        if (!ValueWrapper.equals(prevProps.medicationId, this.props.medicationId)) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.action.bound
    private async loadAsync() {
        await this.loadMedicationByIdAsync();
        if (this.medication.id) {
            await this.loadMedicationRelatedDataAsync();
            await this.loadSubstancesAsync();
            await this.loadReferenceDataAsync();
            await this.loadIdentifiersAsync();
        }
    }

    @State.bound
    private async loadReferenceDataAsync() {
        await this.hunSocialSecurityReferenceDataStore.medicationSubsidyClaimTypes.ensureAllLoadedAsync();
    }

    @State.action.bound
    private async loadMedicationByIdAsync() {
        const response = await this.medicationsApiAdapter.getMedicationVersionsBySelectorsAsync([new EntityVersionSelector<MedicationId>(this.props.medicationId, LocalDate.today())]);
        State.runInAction(() => {
            this.medication = response.value[0];
        });
    }

    @State.action.bound
    private async loadMedicationRelatedDataAsync() {
        const response = await this.apiAdapter.getMedicationInfoByMedicationIdAsync(this.medication.id);
        if (!isNullOrUndefined(response.value)) {
            State.runInAction(() => {
                this.medicationInfo = response.value;
            });
        }
        const pricingAndSubsidiesResponse = await this.apiAdapter.getMedicationPricingAndSubsidiesAsync(this.medication.id, LocalDate.today());
        if (!isNullOrUndefined(pricingAndSubsidiesResponse.value)) {
            State.runInAction(() => {
                this.medicationPricingAndSubsidies = pricingAndSubsidiesResponse.value;
            });
        }
    }

    @State.action.bound
    private async loadSubstancesAsync() {
        const validOn = LocalDate.today();
        await this.referenceDataStore.substances.ensureLoadedAsync(this.medication.substanceIds.map(i => ({id: i, validOn: validOn})));
        const substances = this.medication.substanceIds.map(i => this.referenceDataStore.substances.get({id: i, validOn: validOn}));
        State.runInAction(() => {
            this.substances = substances;
        });
    }

    @State.bound
    private renderOrderTypeAndClaimTypes(pricingAndSubsidies: MedicationPricingAndSubsidies) {
        const parts: string[] = [];
        const claimTypes = pricingAndSubsidies?.pricing?.claimTypes;
        if (pricingAndSubsidies?.pricing?.orderTypeId?.value) {
            parts.push(pricingAndSubsidies.pricing.orderTypeId.value);
        }

        if (claimTypes) {
            for (const claimType of claimTypes) {
                const localized = this.hunSocialSecurityReferenceDataStore.medicationSubsidyClaimTypes.get(claimType);
                if (this.claimTypeCodesToDisplay.some(i => i === localized?.code)) {
                    parts.push(localized?.code);
                }
            }
            return parts.join(", ");
        }
        return "";
    }

    @State.bound
    private async loadIdentifiersAsync() {
        await this.commonReferenceDataStore.identifierSystemMap.ensureLoadedAsync([new IdentifierSystemId("ATC_OEP")]);
    }

    private renderSection(title: string, id: string, content: string, isChild: boolean) {
        const noDataMessage = `<i>${StaticCareResources.ReferenceData.MedicationInfoPanel.Common.NoInfoMessage}</i>`;
        return (
            <>
                <p className={isChild ? Styles.childTitleStyle : Styles.titleStyle} id={id}>{title}</p>
                <div>{ReactHtmlParser(isNullOrUndefined(content) ? noDataMessage : content)}</div>
            </>
        );
    }

    @State.action.bound
    private setSelectedTitleId(id: string) {
        this.currentlySelectedTitleId = id;
    }

    @State.action.bound
    private selectTitleId(id: string) {
        this.setSelectedTitleId(id);
        this.scrollViewRef?.current?.scrollToElement(this.currentlySelectedTitleId);
    }

    public render() {
        
        const Resources = StaticCareResources.ReferenceData.MedicationInfoPanel;

        const eeSubsidy = this.medicationPricingAndSubsidies?.subsidies?.find(i => ValueWrapper.equals(i.pricingType, MedicationSubsidizedPricingTypeId.EE));
        const ekSubsidy = this.medicationPricingAndSubsidies?.subsidies?.find(i => ValueWrapper.equals(i.pricingType, MedicationSubsidizedPricingTypeId.EK));
        const normativeSubsidy = this.medicationPricingAndSubsidies?.subsidies?.find(i => ValueWrapper.equals(i.pricingType, MedicationSubsidizedPricingTypeId.N));

        const scrollHeight = this.props._isInlineDetail ? "400px" : "100%";

        return (
            <UseCaseFrame title={this.medication?.name} rightFooter={<></>}>
                <Ui.Tab contentStyle={{ padding: "0px", height: "calc(100% - 48px)" }} visualStyle="menu" tabsGrow={false} childContainerClassName={Styles.tabContainer}>
                    <Ui.TabPane title={Resources.Tab.Product} automationId="productTab">
                        <div className={Styles.contentContainer}>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={4} style={{ padding: "10px" }}>
                                    <p className={Styles.titleStyle}>Termék adatok</p>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><b>{Resources.Label.Manufacturer}:</b></td>
                                                <td>{this.medication?.manufacturerName}</td>
                                            </tr>
                                            <tr>
                                                <td><b>{Resources.Label.Substance}:</b></td>
                                                <td>{this.substances?.map(s => s.name).join(", ")}</td>
                                            </tr>
                                            <tr>
                                                <td><b>{this.commonReferenceDataStore.identifierSystemMap.getLocalization(new IdentifierSystemId("ATC_OEP"))?.Name}:</b></td>
                                                <td>{this.substances?.map(s => s.identifier.value).join(", ")}</td>
                                            </tr>
                                            <tr>
                                                <td><b>{Resources.Label.Amount}:</b></td>
                                                <td>{this.medication?.textAmount}</td>
                                            </tr>
                                            <tr>
                                                <td><b>{Resources.Label.OrderType}:</b></td>
                                                <td>{this.renderOrderTypeAndClaimTypes(this.medicationPricingAndSubsidies)}</td>
                                            </tr>
                                            <tr>
                                                <td><b>{Resources.Label.MedicationGrossPrice}:</b></td>
                                                <td>{this.localizationService.localizeMoney(this.medicationPricingAndSubsidies?.pricing?.grossPrice)}</td>
                                            </tr>
                                            <tr>
                                                <td><b>{Resources.Label.NormativeSupport}:</b></td>
                                                <td>{this.localizationService.localizeMoney(normativeSubsidy?.grossSupport)}</td>
                                            </tr>
                                            <tr>
                                                <td><b>{Resources.Label.NormativePrice}:</b></td>
                                                <td>{this.localizationService.localizeMoney(normativeSubsidy?.price)}</td>
                                            </tr>
                                            <tr>
                                                <td><b>{Resources.Label.EESupport}:</b></td>
                                                <td>{this.localizationService.localizeMoney(eeSubsidy?.grossSupport)}</td>
                                            </tr>
                                            <tr>
                                                <td><b>{Resources.Label.EEPrice}:</b></td>
                                                <td>{this.localizationService.localizeMoney(eeSubsidy?.price)}</td>
                                            </tr>
                                            <tr>
                                                <td><b>{Resources.Label.EKSupport}:</b></td>
                                                <td>{this.localizationService.localizeMoney(ekSubsidy?.grossSupport)}</td>
                                            </tr>
                                            <tr>
                                                <td><b>{Resources.Label.EKPrice}:</b></td>
                                                <td>{this.localizationService.localizeMoney(ekSubsidy?.price)}</td>
                                            </tr>
                                            <tr>
                                                <td><b>{Resources.Label.RegistryIdentifier}:</b></td>
                                                <td>{this.medication?.registryIdentifier}</td>
                                            </tr>
                                            <tr>
                                                <td><b>{this.commonReferenceDataStore.identifierSystemMap.getLocalization(new IdentifierSystemId("TTT"))?.Name}:</b></td>
                                                <td>{this.medication?.identifiers?.find(i => ValueWrapper.equals(i.identifierSystemId, new IdentifierSystemId("TTT")))?.value}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={8} style={{ padding: "10px" }}>
                                    <p className={Styles.titleStyle}>{StaticCareResources.ReferenceData.MedicationInfoPanel.Tab.EquivalentProducts}</p>
                                    <MedicationEquivalenceListPanel onClick={this.props.onClick} medicationId={this.medication?.id} />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </div>
                    </Ui.TabPane>
                    <Ui.TabPane title={StaticCareResources.ReferenceData.MedicationInfoPanel.Tab.ApplicationSuggestion} automationId="applicationSuggestionTab">
                        <div className={Styles.container}>
                            <div className={Styles.titleContainer}>
                                <Ui.ScrollView height={scrollHeight}>
                                    <Ui.TitleGroup titles={this.titles} onClick={this.selectTitleId} currentlySelectedId={this.currentlySelectedTitleId} containerWidth="400px" />
                                </Ui.ScrollView>
                            </div>
                            <div className={Styles.contentContainerFixed}>
                                <Ui.ScrollView ref={this.scrollViewRef} height={scrollHeight} childElementIds={this.panelIds} onCurrentElementChanged={this.setSelectedTitleId}>
                                    {this.renderSection(Resources.Label.Ingredients, "ingredients", this.medicationInfo?.ingredients, false)}
                                    {this.renderSection(Resources.Label.MedicationForm, "medicationForm", this.medicationInfo?.medicationForm, false)}
                                    {this.renderSection(Resources.Label.ClinicalProperties, "clinicalProperties", this.medicationInfo?.clinicalProperties, false)}
                                    {this.renderSection(Resources.Label.TherapyRecommendations, "therapyRecommendations", this.medicationInfo?.therapyRecommendations, true)}
                                    {this.renderSection(Resources.Label.Dosage, "dosage", this.medicationInfo?.dosage, true)}
                                    {this.renderSection(Resources.Label.CounterRecommendations, "counterRecommendations", this.medicationInfo?.counterRecommendations, true)}
                                    {this.renderSection(Resources.Label.Warnings, "warnings", this.medicationInfo?.warnings, true)}
                                    {this.renderSection(Resources.Label.Interactions, "interactions", this.medicationInfo?.interactions, true)}
                                    {this.renderSection(Resources.Label.PregnancyApplication, "pregnancyApplication", this.medicationInfo?.pregnancyApplication, true)}
                                    {this.renderSection(Resources.Label.DrivingEffects, "drivingEffects", this.medicationInfo?.drivingEffects, true)}
                                    {this.renderSection(Resources.Label.SideEffects, "sideEffects", this.medicationInfo?.sideEffects, true)}
                                    {this.renderSection(Resources.Label.Overdose, "overdose", this.medicationInfo?.overdose, true)}
                                    {this.renderSection(Resources.Label.PharmacologyProperties, "pharmacologyProperties", this.medicationInfo?.pharmacologyProperties, false)}
                                    {this.renderSection(Resources.Label.PharmacodynamicProperties, "pharmacodynamicProperties", this.medicationInfo?.pharmacodynamicProperties, true)}
                                    {this.renderSection(Resources.Label.PharmacokineticProperties, "pharmacokineticProperties", this.medicationInfo?.pharmacokineticProperties, true)}
                                    {this.renderSection(Resources.Label.PreClinicalStudyResults, "preClinicalStudyResults", this.medicationInfo?.preClinicalStudyResults, true)}
                                    {this.renderSection(Resources.Label.PharmaData, "pharmaData", this.medicationInfo?.pharmaData, false)}
                                    {this.renderSection(Resources.Label.AuxiliarySubstances, "auxiliarySubstances", this.medicationInfo?.auxiliarySubstances, true)}
                                    {this.renderSection(Resources.Label.Incompatibility, "incompatibility", this.medicationInfo?.incompatibility, true)}
                                    {this.renderSection(Resources.Label.Expiration, "expiration", this.medicationInfo?.expiration, true)}
                                    {this.renderSection(Resources.Label.StorageInstructions, "storageInstructions", this.medicationInfo?.storageInstructions, true)}
                                    {this.renderSection(Resources.Label.Packaging, "packaging", this.medicationInfo?.packaging, true)}
                                    {this.renderSection(Resources.Label.HandlingInstruction, "handlingInstruction", this.medicationInfo?.handlingInstruction, true)}
                                </Ui.ScrollView>
                            </div>
                        </div>
                    </Ui.TabPane>
                    <Ui.TabPane title={StaticCareResources.ReferenceData.MedicationInfoPanel.Tab.PatientManual} automationId="patientManualTab">
                        <Ui.ScrollView height={scrollHeight}>
                            <div className={Styles.contentContainer}>{ReactHtmlParser(this.medicationInfo?.patientManual)}</div>
                        </Ui.ScrollView>
                    </Ui.TabPane>
                </Ui.Tab>
            </UseCaseFrame>
        );
    }
}

export default connect(
    MedicationInfoPanel,
    new DependencyAdapter<IMedicationInfoPanelProps, IMedicationInfoPanelDependencies>(c => ({
        apiAdapter: c.resolve("ReferenceDataApiAdapter"),
        referenceDataStore: c.resolve("CareReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService"),
        commonReferenceDataStore: c.resolve("CommonReferenceDataDataStore"),
        medicationRequestReferenceDataStore: c.resolve("MedicationRequestReferenceDataStore"),
        medicationsApiAdapter: c.resolve("MedicationsApiAdapter")
    }))
);
