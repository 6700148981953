import EhiCovidStatusReportStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiCovidStatusReportStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import * as Ui from "@CommonControls";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { LockIndicatorComponent } from "@HisPlatformControls";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import EhiCovidStatusReportStatus from "@Primitives/EhiCovidStatusReportStatus";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import _ from "@HisPlatform/Common/Lodash";
import EhiCovidSymptomsSeveritySelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiCovidSymptomsSeveritySelectBox/EhiCovidSymptomsSeveritySelectBox";
import EhiCovidBreathingStatusSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiCovidBreathingStatusSelectBox/EhiCovidBreathingStatusSelectBox";
import EhiCovidVentilationStatusSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiCovidVentilationStatusSelectBox/EhiCovidVentilationStatusSelectBox";
import EhiCovidSymptomStatusSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiCovidSymptomStatusSelectBox/EhiCovidSymptomStatusSelectBox";
import EhiCovidUrineAmountSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiCovidUrineAmountSelectBox/EhiCovidUrineAmountSelectBox";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import { IPermissionCheckOperation } from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContext";

interface IEhiCovidStatusReportDetailPanelViewProps {
    store: EhiCovidStatusReportStore;
    operationsToChecks: IPermissionCheckOperation;
    onSaveAsync: () => Promise<void>;
    onDeleteAsync: () => Promise<void>;
    onLockEditClickedAsync: () => Promise<void>;
    onValidateAsync?: (dirtyFields?: string[]) => Promise<IClientValidationResult[]>;
    onNavigateAwayAsync: () => Promise<boolean>;
    readonly: boolean;
}

@State.observer
export default class EhiCovidStatusReportDetailPanelView extends React.Component<IEhiCovidStatusReportDetailPanelViewProps> {

    private get resources() { return StaticHunEHealthInfrastructureCareResources.EhiCovidStatusReportDetailPanel; }
    private get labels() { return this.resources.Label; }

    @State.bound
    private renderDetailToolbar() {
        return (
            <>
                <PermissionCheckContextProvider operationsToCheck={this.props.operationsToChecks}>
                    <LockIndicatorComponent
                        locked={!this.props.store.isMutable}
                        lockedBy={this.props.store.lockInfo?.preventingLockUserId}
                        onEditClickedAsync={this.props.onLockEditClickedAsync}
                        permissionCheckOperationNames="SaveEhiCovidStatusReport"
                    />
                    {
                        (this.props.store.status === EhiCovidStatusReportStatus.Saved && !this.props.readonly) &&
                        <Ui.Button
                            onClickAsync={this.props.onDeleteAsync}
                            iconName="trash"
                            visualStyle="negative-standard"
                            automationId="deleteButton"
                            text={StaticWebAppResources.Common.Button.Delete}
                            disabled={!this.props.store.isMutable}
                            permissionCheckOperationNames="DeleteEhiCovidStatusReport"
                            permissionDeniedStyle="disabled"
                        />
                    }
                    {
                        (this.props.store.status !== EhiCovidStatusReportStatus.Deleted && !this.props.readonly) &&
                        <Ui.SaveButton
                            onClickAsync={this.props.onSaveAsync}
                            visualStyle="primary"
                            automationId="saveButton"
                            disabled={!this.props.store.isMutable}
                            permissionCheckOperationNames="SaveEhiCovidStatusReport"
                            permissionDeniedStyle="disabled"
                        />
                    }
                </PermissionCheckContextProvider>
            </>
        );
    }

    public render() {
        const prefix = this.props.store.validationPropertyPrefix;

        return (
            <UseCaseFrame
                toolbar={this.renderDetailToolbar()}
                title={this.resources.Title}>
                <>
                    <ValidationBoundary
                        validationResults={this.props.store.validationResults}
                        entityTypeName="EhiCovidStatusReport"
                        onValidateAsync={this.props.onValidateAsync}
                        validateOnMount
                    >
                        <ReadOnlyContext.Provider value={!this.props.store.isMutable || this.props.store.status === EhiCovidStatusReportStatus.Deleted || this.props.readonly }>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={4}>
                                    <Ui.DateTimePicker
                                        label={this.labels.StatusReportAt}
                                        value={this.props.store.statusReportAt}
                                        onChange={this.props.store.setStatusReportAt}
                                        propertyIdentifier={`${prefix}.StatusReportAt`}
                                        automationId="statusReportAt"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={2}>
                                    <Ui.Flex noWrap verticalSpacing="none">
                                        <Ui.Flex.Item>
                                            <Ui.NumberBox
                                                label={this.labels.BodyTemperature}
                                                value={this.props.store.bodyTemperature}
                                                onChange={this.props.store.setBodyTemperature}
                                                propertyIdentifier={`${prefix}.BodyTemperature`}
                                                automationId="bodyTemperature"
                                                style={{ width: 80 }}
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item style={{ alignSelf: "flex-end" }}>
                                            <div style={{ paddingBottom: 4 }}>°C</div>
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={2}>

                                    <Ui.Flex noWrap verticalSpacing="none">
                                        <Ui.Flex.Item>
                                            <Ui.NumberBox
                                                label={this.labels.RespiratoryRate}
                                                value={this.props.store.respiratoryRate}
                                                onChange={this.props.store.setRespiratoryRate}
                                                propertyIdentifier={`${prefix}.RespiratoryRate`}
                                                automationId="respiratoryRate"
                                                style={{ width: 80 }}
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item style={{ alignSelf: "flex-end" }}>
                                            <div style={{ paddingBottom: 4 }}>/min</div>
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={2}>
                                    <Ui.Flex noWrap verticalSpacing="none">
                                        <Ui.Flex.Item>
                                            <Ui.NumberBox
                                                label={this.labels.Pulse}
                                                value={this.props.store.pulse}
                                                onChange={this.props.store.setPulse}
                                                propertyIdentifier={`${prefix}.Pulse`}
                                                automationId="pulse"
                                                style={{ width: 80 }}
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item style={{ alignSelf: "flex-end" }}>
                                            <div style={{ paddingBottom: 4 }}>/min</div>
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={2}>
                                    <Ui.Flex noWrap verticalSpacing="none">
                                        <Ui.Flex.Item>
                                            <Ui.NumberBox
                                                label={this.labels.BloodSugar}
                                                value={this.props.store.bloodSugar}
                                                onChange={this.props.store.setBloodSugar}
                                                propertyIdentifier={`${prefix}.BloodSugar`}
                                                automationId="bloodSugar"
                                                style={{ width: 80 }}
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item style={{ alignSelf: "flex-end" }}>
                                            <div style={{ paddingBottom: 4 }}>mmol/l</div>
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={4}>
                                    <Ui.Flex noWrap verticalSpacing="none">
                                        <Ui.Flex.Item>
                                            <Ui.NumberBox
                                                label={this.labels.BloodPressure}
                                                value={this.props.store.bloodPressureSystolic}
                                                onChange={this.props.store.setBloodPressureSystolic}
                                                propertyIdentifier={`${prefix}.BloodPressureSystolic`}
                                                automationId="bloodPressureSystolic"
                                                style={{ width: 80 }}
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item style={{ alignSelf: "flex-end" }}>
                                            <div style={{ paddingBottom: 4 }}>/</div>
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item style={{ alignSelf: "flex-end" }}>
                                            <Ui.NumberBox
                                                label=" "
                                                value={this.props.store.bloodPressureDiastolic}
                                                onChange={this.props.store.setBloodPressureDiastolic}
                                                propertyIdentifier={`${prefix}.BloodPressureDiastolic`}
                                                automationId="bloodPressureDiastolic"
                                                style={{ width: 80 }}
                                            />
                                        </Ui.Flex.Item>
                                        <Ui.Flex.Item style={{ alignSelf: "flex-end" }}>
                                            <div style={{ paddingBottom: 4 }}>Hgmm</div>
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <Ui.CheckBox
                                        label={this.labels.PneumoniaConfirmedClinical}
                                        value={this.props.store.pneumoniaConfirmedClinical}
                                        onChange={this.props.store.setPneumoniaConfirmedClinical}
                                        propertyIdentifier={`${prefix}.PneumoniaConfirmedClinical`}
                                        automationId="pneumoniaConfirmedClinical"
                                        verticalAlign="inlineInput"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <Ui.CheckBox
                                        label={this.labels.PneumoniaConfirmedRadiological}
                                        value={this.props.store.pneumoniaConfirmedRadiological}
                                        onChange={this.props.store.setPneumoniaConfirmedRadiological}
                                        propertyIdentifier={`${prefix}.PneumoniaConfirmedRadiological`}
                                        automationId="pneumoniaConfirmedRadiological"
                                        verticalAlign="inlineInput"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>

                            <Ui.Flex style={{ paddingTop: 20 }}>
                                <Ui.Flex.Item xs={4}>
                                    <EhiCovidSymptomsSeveritySelectBox
                                        label={this.labels.SymptomsSeverityId}
                                        value={this.props.store.symptomsSeverityId}
                                        onChange={this.props.store.setSymptomsSeverityId}
                                        propertyIdentifier={`${prefix}.SymptomsSeverityId`}
                                        automationId="symptomsSeverityId"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <EhiCovidBreathingStatusSelectBox
                                        label={this.labels.BreathingStatusId}
                                        value={this.props.store.breathingStatusId}
                                        onChange={this.props.store.setBreathingStatusId}
                                        propertyIdentifier={`${prefix}.BreathingStatusId`}
                                        automationId="breathingStatusId"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <EhiCovidVentilationStatusSelectBox
                                        label={this.labels.VentilationStatusId}
                                        value={this.props.store.ventilationStatusId}
                                        onChange={this.props.store.setVentilationStatusId}
                                        propertyIdentifier={`${prefix}.VentilationStatusId`}
                                        automationId="ventilationStatusId"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>

                            <Ui.Flex>
                                <Ui.Flex.Item xs={4}>
                                    <EhiCovidSymptomStatusSelectBox
                                        label={this.labels.ChillsSymptomStatusId}
                                        value={this.props.store.chillsSymptomStatusId}
                                        onChange={this.props.store.setChillsSymptomStatusId}
                                        propertyIdentifier={`${prefix}.ChillsSymptomStatusId`}
                                        automationId="chillsSymptomStatusId"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <EhiCovidSymptomStatusSelectBox
                                        label={this.labels.GeneralWeaknessSymptomStatusId}
                                        value={this.props.store.generalWeaknessSymptomStatusId}
                                        onChange={this.props.store.setGeneralWeaknessSymptomStatusId}
                                        propertyIdentifier={`${prefix}.GeneralWeaknessSymptomStatusId`}
                                        automationId="generalWeaknessSymptomStatusId"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <EhiCovidSymptomStatusSelectBox
                                        label={this.labels.SputumRemovalSymptomStatusId}
                                        value={this.props.store.sputumRemovalSymptomStatusId}
                                        onChange={this.props.store.setSputumRemovalSymptomStatusId}
                                        propertyIdentifier={`${prefix}.SputumRemovalSymptomStatusId`}
                                        automationId="sputumRemovalSymptomStatusId"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>

                            <Ui.Flex>
                                <Ui.Flex.Item xs={4}>
                                    <EhiCovidSymptomStatusSelectBox
                                        label={this.labels.CoughSymptomStatusId}
                                        value={this.props.store.coughSymptomStatusId}
                                        onChange={this.props.store.setCoughSymptomStatusId}
                                        propertyIdentifier={`${prefix}.CoughSymptomStatusId`}
                                        automationId="coughSymptomStatusId"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <EhiCovidSymptomStatusSelectBox
                                        label={this.labels.HeadacheSymptomStatusId}
                                        value={this.props.store.headacheSymptomStatusId}
                                        onChange={this.props.store.setHeadacheSymptomStatusId}
                                        propertyIdentifier={`${prefix}.HeadacheSymptomStatusId`}
                                        automationId="headacheSymptomStatusId"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <EhiCovidSymptomStatusSelectBox
                                        label={this.labels.MuscleOrJointPainSymptomStatusId}
                                        value={this.props.store.muscleOrJointPainSymptomStatusId}
                                        onChange={this.props.store.setMuscleOrJointPainSymptomStatusId}
                                        propertyIdentifier={`${prefix}.MuscleOrJointPainSymptomStatusId`}
                                        automationId="muscleOrJointPainSymptomStatusId"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>

                            <Ui.Flex>
                                <Ui.Flex.Item xs={4}>
                                    <EhiCovidSymptomStatusSelectBox
                                        label={this.labels.VomitSymptomStatusId}
                                        value={this.props.store.vomitSymptomStatusId}
                                        onChange={this.props.store.setVomitSymptomStatusId}
                                        propertyIdentifier={`${prefix}.VomitSymptomStatusId`}
                                        automationId="vomitSymptomStatusId"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <EhiCovidSymptomStatusSelectBox
                                        label={this.labels.DiarrheaSymptomStatusId}
                                        value={this.props.store.diarrheaSymptomStatusId}
                                        onChange={this.props.store.setDiarrheaSymptomStatusId}
                                        propertyIdentifier={`${prefix}.DiarrheaSymptomStatusId`}
                                        automationId="diarrheaSymptomStatusId"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <EhiCovidSymptomStatusSelectBox
                                        label={this.labels.SoreThroatSymptomStatusId}
                                        value={this.props.store.soreThroatSymptomStatusId}
                                        onChange={this.props.store.setSoreThroatSymptomStatusId}
                                        propertyIdentifier={`${prefix}.SoreThroatSymptomStatusId`}
                                        automationId="soreThroatSymptomStatusId"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>

                            <Ui.Flex>
                                <Ui.Flex.Item xs={4}>
                                    <EhiCovidUrineAmountSelectBox
                                        label={this.labels.UrineAmountId}
                                        value={this.props.store.urineAmountId}
                                        onChange={this.props.store.setUrineAmountId}
                                        propertyIdentifier={`${prefix}.UrineAmountId`}
                                        automationId="urineAmountId"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>

                            <Ui.Flex style={{ paddingTop: 20 }}>
                                <Ui.Flex.Item xs={12}>
                                    <Ui.TextBox
                                        label={this.labels.OtherSymptoms}
                                        value={this.props.store.otherSymptoms}
                                        onChange={this.props.store.setOtherSymptoms}
                                        propertyIdentifier={`${prefix}.OtherSymptoms`}
                                        automationId="otherSymptoms"
                                        multiline
                                        multilineMaxLineCount={6}
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={12}>
                                    <Ui.TextBox
                                        label={this.labels.Notes}
                                        value={this.props.store.notes}
                                        onChange={this.props.store.setNotes}
                                        propertyIdentifier={`${prefix}.Notes`}
                                        automationId="notes"
                                        multiline
                                        multilineMaxLineCount={6}
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </ReadOnlyContext.Provider>
                    </ValidationBoundary>
                    <NavigateAwayHook onNavigateAwayAsync={this.props.onNavigateAwayAsync} />
                </>
            </UseCaseFrame>
        );
    }
}
