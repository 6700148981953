import State from "@Toolkit/ReactClient/Common/StateManaging";
import VaccineAssignmentBase from "./VaccineAssignmentBase";

export default class CustomVaccineAssignment extends VaccineAssignmentBase  {
    @State.observable.ref public name: string;

    constructor(name: string) {
        super();
        this.name = name;
    }

    @State.action.bound
    public setName(newValue: string) {
        return this.name = newValue;
    }
}