import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import React from "react";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SelectBox, { SelectBoxCore } from "@CommonControls/SelectBox";
import TimeEditor from "@CommonControls/DateTimePicker/TimeEditor";
import { TextBox, Button, Flex } from "@CommonControls";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import { components } from "react-select";

interface ITimeRangePickerDependencies {
    toolkitLocalizationService: IToolkitLocalizationService;
}

interface ITimeRangePickerProps extends ISelectBoxBaseProps {
    _dependencies?: ITimeRangePickerDependencies;
    from?: TimeOfDay;
    to?: TimeOfDay;
    stepsInSeconds?: number[];
    valueFrom: TimeOfDay;
    valueTo: TimeOfDay;
    onTimePickerChange: (from: TimeOfDay, to: TimeOfDay, isFinal: boolean) => void;
    onBack?: () => void;
    highlightCurrentDate?: boolean;
    initialSelectionState?: "from" | "to";
    forwardedRef?: React.Ref<SelectBoxCore>;
}

const valueOnClear = { __clear: true };
const dummyItems = [{} as any];

@State.observer
class TimeRangePicker extends React.Component<ITimeRangePickerProps> {
    private readonly stepsInSeconds = [60, 5];
    private get toolkitLocalizationService() { return this.props._dependencies.toolkitLocalizationService; }
    @State.observable.ref private selectionState: "from" | "to" = this.props.initialSelectionState ? this.props.initialSelectionState : "from";
    @State.observable.ref private menuIsOpen = false;
    @State.observable.ref private displayedText = "";

    @State.action.bound
    private pickingFromDate() {
        this.selectionState = "from";
    }

    @State.action.bound
    private pickingToDate() {
        this.selectionState = "to";
    }

    @State.action.bound private setFrom(newValue: TimeOfDay, isFinal: boolean) {
        this.props.onTimePickerChange(newValue, null, isFinal);
        if (isFinal) {
            this.selectionState = "to";
        }
    }

    @State.action.bound private setTo(newValue: TimeOfDay, isFinal: boolean) {
        this.props.onTimePickerChange(this.props.valueFrom, newValue, isFinal);
        if (isFinal) {
            this.closeMenu();
        }
    }
    @State.action.bound private focusInput() {
        this.selectionState = "from";
    }

    @State.bound
    private selectBoxChangeHandler(newValue: any) {
        if (newValue === valueOnClear) {
            this.clearValueAndClose();
        }
    }

    @State.action.bound
    private clearValueAndClose() {
        this.props.onTimePickerChange(null, null, true);
        this.closeMenu();
    }

    @State.action.bound
    private closeMenu() {
        this.menuIsOpen = false;
    }

    @State.computed
    private get selectBoxText() {
        let res = "";
        if (this.props.valueFrom) {
            res = `${this.props.valueFrom.toString()}`;

            if (this.props.valueTo) {
                res += ` - ${this.props.valueTo.toString()}`;
            }
        }

        return res;
    }

    @State.bound
    private textValueGetter() {
        return this.selectBoxText;
    }

    @State.action.bound
    private openMenu() {
        this.menuIsOpen = true;
        this.selectionState = "from";
    }

    private dummyOptionIsSelected() {
        return true;
    }
    public render() {
        if (this.props.isReadOnly) {
            return (
                <TextBox
                    label={this.props.label}
                    id={this.props.id}
                    automationId={this.props.automationId}
                    disabled={this.props.disabled}
                    name={this.props.name}
                    tabIndex={this.props.tabIndex}
                    value={this.displayedText}
                    isReadOnly
                />
            );
        }
        return (
            <SelectBox
                {...this.props}
                onFocus={this.focusInput}
                onChange={this.selectBoxChangeHandler}
                valueOnClear={valueOnClear}
                value={this.props.valueFrom ? true : null}
                items={dummyItems}
                onRenderMenu={this.renderMenu}
                menuIsOpen={this.menuIsOpen}
                onMenuOpen={this.openMenu}
                onMenuClose={this.closeMenu}
                getOptionText={this.textValueGetter}
                inputValue={this.selectBoxText}
                isOptionSelected={this.dummyOptionIsSelected}
                dropdownIconName="none"
                ref={this.props.forwardedRef}
                clearable
            />
        );
    }

    private  renderMenu = State.observer((menuProps: any) => {
        const props = {
            ...menuProps,
            innerProps: {
                ...menuProps.innerProps,
            }
        };

        return (
            <components.Menu {...props}>
                <Flex>
                    <Flex.Item xs={6}> 
                        <Button
                            text={this.toolkitLocalizationService.staticResources.dateRangePicker.labels.from}
                            visualStyle={this.selectionState === "from" ? "primary" : "standard"}
                            fluid
                            size="compact"
                            onClick={this.pickingFromDate}
                            automationId="__from"
                        />
                    </Flex.Item>
                    <Flex.Item xs={6}>
                    <Button
                        text={this.toolkitLocalizationService.staticResources.dateRangePicker.labels.to}
                        visualStyle={this.selectionState === "to" ? "primary" : "standard"}
                        fluid
                        size="compact"
                        onClick={this.pickingToDate}
                        automationId="__to"
                    />
                    </Flex.Item>
                </Flex>                    
                {this.renderTimePicker()}
            </components.Menu>
        );
    });

    @State.bound
    private renderTimePicker() {
        switch (this.selectionState) {
            case "from":
                return (
                    <TimeEditor
                        key="from"
                        value={this.props.valueFrom}
                        from={this.props.from}
                        to={this.props.to}
                        stepsInSeconds={this.stepsInSeconds}
                        onChange={this.setFrom}
                    />
                );
            case "to":
                return (
                    <TimeEditor
                        key="to"
                        value={this.props.valueTo}
                        from={this.props.valueFrom?.withMinutes(0) ?? this.props.from}
                        stepsInSeconds={this.stepsInSeconds}
                        to={this.props.to}
                        onChange={this.setTo}
                    />
                );
        }
    }
}

export default connect(
    TimeRangePicker,
    new DependencyAdapter<ITimeRangePickerProps, ITimeRangePickerDependencies>(c => {
        return {
            toolkitLocalizationService: c.resolve("IToolkitLocalizationService")
        };
    })
);