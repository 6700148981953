import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { DisplayMode } from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";
import CareActivityEReferralStore from "@HunEHealthInfrastructurePlugin/Model/CareActivityEReferralStore";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import EhiEReferralListColumnStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralListColumnStore";

interface IEhiEReferralPractitionerColumnDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    localizationService: ILocalizationService;
}

interface IEhiEReferralPractitionerColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IEhiEReferralPractitionerColumnDependencies;
    displayMode?: DisplayMode;
    noBoldShorthand?: boolean;
}


const EhiEReferralPractitionerColumn: React.FC<IEhiEReferralPractitionerColumnProps> = (props: IEhiEReferralPractitionerColumnProps) => {
    const resolveFromStore = (row: CareActivityEReferralStore) => {
        if (isNullOrUndefined(row?.referralDoctor)) {
            return null;
        }
        return props._dependencies.organizationReferenceDataStore.doctorMap.get(row.referralDoctor);
    };

    const dataGetter = useCallback((row: CareActivityEReferralStore) => {
        const resolveCode = () => {
            const resolvedCode = resolveFromStore(row)?.code;
            return !isNullOrUndefined(resolvedCode) ? resolvedCode : "";
        };
        const resolveName = () => {
            const resolvedName = resolveFromStore(row)?.name;
            return !isNullOrUndefined(resolvedName) ? resolvedName : null;
        };

        const code = !isNullOrUndefined(row.referralDoctorCode) ? row.referralDoctorCode : resolveCode();

        const nameStore = !isNullOrUndefined(row.referralDoctorName) ? row.referralDoctorName : resolveName();
        const name = props._dependencies.localizationService.localizePersonName(nameStore);
        
        return new EhiEReferralListColumnStore(name, code);
    }, []);

    const renderValue = useCallback((value: EhiEReferralListColumnStore) => {
        switch (props.displayMode) {
            case "name":
                return <>{value.Name}</>;
            case "shorthand":
                return <>{value.Shorthand}</>;
            case "shorthand-name":
            default:
                return value.getDisplayValue(props.noBoldShorthand);
        }
    }, []);

    const getStringValue = (value: EhiEReferralListColumnStore) => {
        switch (props.displayMode) {
            case "name":
                return value.Name;
            case "shorthand":
                return value.Shorthand;
            case "shorthand-name":
            default:
                return value.getShorthandAndName();
        }
    };
    return (
        <DataGridColumn
            width={undefined}
            {...props}
            dataGetter={dataGetter}
            onRenderCellValue={renderValue}
            showHint={false}
            clientSideOrderableValueGetter={getStringValue}
            clientSideFilterableValueGetter={getStringValue}
        />
    );
};

export default connect(
    EhiEReferralPractitionerColumn,
    new DependencyAdapter<IEhiEReferralPractitionerColumnProps, IEhiEReferralPractitionerColumnDependencies>(c => ({
        organizationReferenceDataStore: c.resolve<OrganizationReferenceDataStore>("OrganizationReferenceDataStore"),
        localizationService: c.resolve<ILocalizationService>("ILocalizationService")
    }))
);
