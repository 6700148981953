import Di from "@Di";
import ValueWrapperMap from "@Toolkit/CommonWeb/Model/ValueWrapperMap";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import FrontendActionId from "@Toolkit/ReactClient/ActionProcessing/FrontendActionId";
import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";
import { IActionPermissionProvider } from "./IActionPermissionProvider";
import { PermittedFrontendAction } from "./PermittedFrontendAction";

@Di.injectable()
export default class AuthorizationService {

    private readonly actionPermissions = new ValueWrapperMap<FrontendActionId, PermittedFrontendAction>();
    private isUnrestrictedUser = false;

    constructor(
        @Di.inject("IActionPermissionProvider") private readonly actionPermissionProvider: IActionPermissionProvider
    ) {}

    public async loadPermissionsAsync(): Promise<void> {
        const actionPermissions = await this.actionPermissionProvider.getFrontendActionPermissionsAsync();
        this.isUnrestrictedUser = actionPermissions.isUnresctrictedUser;
        actionPermissions.permittedActions?.forEach(pa => this.actionPermissions.set(pa.actionId, pa));
    }

    public hasPermissionFor(actionId: FrontendActionId, ...scopes: FrontendActionPermissionScope[]): boolean {

        if (this.isUnrestrictedUser) {
            return true;
        }

        const permittedAction = this.actionPermissions.getOrNull(actionId);

        if (!permittedAction) {
            return false;
        }

        return permittedAction.areScopesFit(scopes);
    }

    public hasPermissionForDescriptor(actionDescriptor: ActionDescriptor) {
        return this.hasPermissionFor(actionDescriptor.action.id, ...actionDescriptor.requiredPermissionScopes);
    }
}
