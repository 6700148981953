import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import HunSocialSecurityUseCases from "@HunSocialSecurityPlugin/UseCases/HunSocialSecurityUseCases";
import OutpatientNeakReportPanel from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/OutpatientNeakReportPanel/OutpatientNeakReportPanel";


interface ICreateNeakRevisionPerformanceStatementPageDependencies {
    applicationContext: ApplicationContext;
}

interface ICreateNeakRevisionPerformanceStatementPageProps extends IRoutingFrameContentProps {
    _dependencies?: ICreateNeakRevisionPerformanceStatementPageDependencies;
}

@State.observer
class CreateNeakRevisionPerformanceStatementPage extends React.Component<ICreateNeakRevisionPerformanceStatementPageProps> {

    public componentWillMount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(new UseCaseIdentifier(HunSocialSecurityUseCases.newNeakRevisionPerformanceStatement));
    }

    public componentWillUnmount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(null);
    }

    public render() {
        return (
            <div>
                <OutpatientNeakReportPanel revisionMode />
            </div>
        );
    }
}

export default connect(
    withHisErrorBoundary(CreateNeakRevisionPerformanceStatementPage),
    new DependencyAdapter<ICreateNeakRevisionPerformanceStatementPageProps, ICreateNeakRevisionPerformanceStatementPageDependencies>(container => {
        return {
            applicationContext: container.resolve("ApplicationContext")
        };
    })
);
