import React from "react";
import * as Ui from "@CommonControls";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import IdentifierSystemSelectBox from "@HisPlatform/BoundedContexts/CommonReferenceData/Components/Controls/IdentifierSystemSelectBox/IdentifierSystemSelectBox";
import PractitionerIdentifierStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerIdentifierStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IdentifierTypeId from "@Primitives/IdentifierTypeId.g";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";

interface IIdentifierAssignmentViewProps {
    identifier: PractitionerIdentifierStore;
    index: number;
}


const PractitionerIdentifierView: React.FC<IIdentifierAssignmentViewProps> = props =>
    (
        <>
            <Ui.Flex>
                <Ui.Flex.Item xs={4}>
                    <IdentifierSystemSelectBox
                        label={StaticUserManagementResources.UserPanel.Label.IdentifierSystem}
                        value={props.identifier.identifier.identifierSystemId}
                        onChange={props.identifier.identifier.setIdentifierSystemId}
                        propertyIdentifier="Identifier.IdentifierSystemId"
                        identifierTypeIds={[IdentifierTypeId.EHealthInfrastructure, IdentifierTypeId.MDLicence]}
                        automationId="identifierSystemId"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={4}>
                    <Ui.TextBox
                        label={StaticUserManagementResources.UserPanel.Label.Value}
                        value={props.identifier.identifier.value}
                        onChange={props.identifier.identifier.setValue}
                        propertyIdentifier="Identifier.Value"
                        automationId="identifierValue"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={4}>
                    <Ui.TextBox
                        label={StaticUserManagementResources.UserPanel.Label.Name}
                        value={props.identifier.name}
                        onChange={props.identifier.setName}
                        propertyIdentifier="Name"
                        automationId="identifierName"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </>
    );

export default State.observer(PractitionerIdentifierView);
