import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import ServiceRequestStateSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/ServiceRequestManagement/ServiceRequestStateSelectBox/ServiceRequestStateSelectBox";
import ServiceRequestStateBadge from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ServiceRequestStateBadge/ServiceRequestStateBadge";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import ServiceRequestState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestState.g";

interface IServiceRequestStateColumnDependencies {
    localizationService: ILocalizationService;
}

interface IServiceRequestStateColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IServiceRequestStateColumnDependencies;
    displayMode?: "name" | "badge";
}


const ServiceRequestStateColumn: React.FC<IServiceRequestStateColumnProps> = (props: IServiceRequestStateColumnProps) => {

    const valueRenderer = useCallback((value: ServiceRequestState) => {

        switch (props.displayMode) {
            case "name":
                const localized = isNullOrUndefined(value) ? null : props._dependencies.localizationService.localizeEnum(ServiceRequestState[value], "ServiceRequestState");
                return isNullOrUndefined(localized) ? null : localized.Name;
            case "badge":
            default:
                return <ServiceRequestStateBadge state={value} />;
        }
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<ServiceRequestState | ServiceRequestState[]>) => {
        return <ServiceRequestStateSelectBox {...filterProps} multiSelect maxMenuWidth={commonMenuMaxWidth} hoverOnlyIndicatorIcons />;
    }, []);

    return (
        <DataGridColumn
            width={props.displayMode === "badge" ? 40 : undefined}
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            showHint={false}
        />
    );
};

export default connect(
    ServiceRequestStateColumn,
    new DependencyAdapter<IServiceRequestStateColumnProps, IServiceRequestStateColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);