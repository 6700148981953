import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import _ from "@HisPlatform/Common/Lodash";
import PractitionerId from "@Primitives/PractitionerId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import AppointmentScheduleSlotSeries from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentSchedule";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import SchedulingServiceFilter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceFilter";
import SchedulingServiceFilterPanelView from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/SchedulingServiceFilterPanel/SchedulingServiceFilterPanelView";

interface ISchedulingServiceFilterDependencies {
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
}

interface ISchedulingServiceFilterProps {
    _dependencies?: ISchedulingServiceFilterDependencies;
    readonly: boolean;
    schedulingServiceFilter: SchedulingServiceFilter;
    index: number;
}

@State.observer
class SchedulingServiceFilterPanel extends React.Component<ISchedulingServiceFilterProps> {
    @State.observable private isLoading: boolean;

    private get schedules() {
        return this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleSlotSeries.items;
    }

    @State.action.bound
    private setLoadingState(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    @State.computed
    public get explicitIdsForSchedulingService() {
        const explicitPointOfCareIds: PointOfCareId[] = [];
        const explicitPractitionerIds: PractitionerId[] = [];
        const explicitSchedulingServiceIds: SchedulingServiceId[] = [];

        const selectedDoctors = !!this.props.schedulingServiceFilter.practitionerId ? [this.props.schedulingServiceFilter.practitionerId] : null;
        const selectedPointsOfCare = !!this.props.schedulingServiceFilter.pointOfCareId ? [this.props.schedulingServiceFilter.pointOfCareId] : null;
        const selectedSchedulingService = this.props.schedulingServiceFilter.schedulingServiceId;

        // Selectable items of each filter are defined by the selected values of the other two filters as:
        // All schedules satisfying two filters should be selectable through the third one.
        // So if a schedule satisfies two filters,
        // its appropriate resources are added to the selectable items of the third one.
        this.schedules.forEach(s => {

            if ((arrayIsNullOrEmpty(selectedPointsOfCare) || this.intersects(s.locationParticipants, selectedPointsOfCare)) &&
                (arrayIsNullOrEmpty(selectedDoctors) || this.intersects(s.practitionerParticipants, selectedDoctors))) {
                const serviceIds = this.getAllowedServices(s);
                explicitSchedulingServiceIds.push(...serviceIds);
            }

            if ((arrayIsNullOrEmpty(selectedDoctors) || this.intersects(s.practitionerParticipants, selectedDoctors)) &&
            (isNullOrUndefined(selectedSchedulingService) || this.isAvailableForService(s, selectedSchedulingService))) {
                explicitPointOfCareIds.push(...s.locationParticipants);
            }

            if ((arrayIsNullOrEmpty(selectedPointsOfCare) || this.intersects(s.locationParticipants, selectedPointsOfCare)) &&
            (isNullOrUndefined(selectedSchedulingService) || this.isAvailableForService(s, selectedSchedulingService))) {
                explicitPractitionerIds.push(...s.practitionerParticipants);
            }
        });

        return {
            explicitPointOfCareIds: explicitPointOfCareIds,
            explicitPractitionerIds: explicitPractitionerIds,
            explicitSchedulingServiceIds: explicitSchedulingServiceIds
        };
    }

    @State.computed
    private get explicitIds() {
        return this.explicitIdsForSchedulingService;
    }

    @State.bound
    private isAvailableForService(appointmentSchedule: AppointmentScheduleSlotSeries, serviceId: SchedulingServiceId) {
        const serviceIds = this.getAllowedServices(appointmentSchedule);
        if (serviceIds?.length > 0) {
            return this.intersects(serviceIds, [serviceId]);
        }
        return true;
    }

    @State.bound
    private intersects(first: IStringEntityId[], second: IStringEntityId[]) {
        return !arrayIsNullOrEmpty(_.intersectionWith(first, second, ValueWrapper.equals));
    }

    @State.bound
    private getAllowedServices(schedule: AppointmentScheduleSlotSeries) {
        if (schedule?.allowedSchedulingServices?.length > 0) {
            return schedule.allowedSchedulingServices;
        }
        // if the allowed services are empty it means that the slotSeries allows every service
        // defined in the definitions
        const appointmentScheduleDefinition = this.props._dependencies.schedulingReferenceDataStore
            .appointmentScheduleDefinitions.get(schedule.appointmentScheduleDefinitionId);

        if (appointmentScheduleDefinition?.ownedSchedulingServices?.length > 0) {
            return appointmentScheduleDefinition.ownedSchedulingServices;
        }
        return [] as SchedulingServiceId[];
    }

    public render() {
        return (
            <SchedulingServiceFilterPanelView
                readonly={this.props.readonly}
                setLoadingState={this.setLoadingState}
                schedulingServiceFilter={this.props.schedulingServiceFilter}
                explicitIds={this.explicitIds}
            />
        );
    }
}

export default connect(
    SchedulingServiceFilterPanel,
    new DependencyAdapter<ISchedulingServiceFilterProps, ISchedulingServiceFilterDependencies>(c => ({
        schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore")
    }))
);
