import IFormDefinition from "./IFormDefinition";
import FormSchema from "./Schema/FormSchema";
import FormLayoutStore from "./Layout/FormLayoutStore";
import FormDefinitionId from "./Primitives/FormDefinitionId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import FormEnumStore from "./Schema/FormEnumStore";
import FormEditBehavior from "@Toolkit/FormEngine/Model/FormEditBehavior";
import FormLogicType from "@Primitives/FormLogicType";
import FormLogicToken from "./FormLogicToken";
import FormLogic from "./FormLogic";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";

export default class FormDefinition extends EntityStoreBase<FormDefinitionId> implements IFormDefinition {

    constructor(
        id: FormDefinitionId,
        rowVersion: RowVersion,
        public readonly name: string,
        public readonly multiLingualDisplayName: MultiLingualLabel,
        public readonly baseEntityType: string,
        public readonly rootSchema: FormSchema,
        public readonly rootLayout: FormLayoutStore,
        public readonly formEnums: FormEnumStore[],
        public readonly formEditBehavior: FormEditBehavior,
        public readonly formLogics: FormLogic[],
        public readonly formLogicType: FormLogicType,
        public readonly formLogicTokens: FormLogicToken[],
        public readonly withoutDataHandling: boolean,
        public readonly isUserForm: boolean,
        public readonly instanceLimit: number
    ) {
        super(id.value === "new");
        this.id = id;
        this.rowVersion = rowVersion;
    }
}