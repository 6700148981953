import ICareActivityCoverageDataExtensionPointProps from "@PluginInterface/BoundedContexts/Care/PatientRegister/ExtensionPoints/ICareActivityCoverageDataExtensionPointProps";
import WellKnownReferenceCodes from "@HunSocialSecurityPlugin/Common/WellKnownReferenceCodes";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import Di from "@Di";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

@Di.injectable()
export default class HeaderCareActivityCoverageDataExtensionComponentService implements IExtensionComponentService<ICareActivityCoverageDataExtensionPointProps> {

    public isVisible(props: ICareActivityCoverageDataExtensionPointProps) {
        return props.insurerOrganizationCode === WellKnownReferenceCodes.HunSocialSecurityInsuranceCode;
    }

    public shouldRerunIsVisible(props: ICareActivityCoverageDataExtensionPointProps, prevProps: ICareActivityCoverageDataExtensionPointProps) {
        return !ValueWrapper.equals(props.insuranceId, prevProps?.insuranceId);
    }
}
