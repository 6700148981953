import React from "react";
import RssParser, { Item } from "rss-parser";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import ShowMoreOrLessItemsButton from "./ShowMoreOrLessItemsButton";
import FeedItem from "./FeedItem";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import IWidgetComponentProps from "@PluginInterface/Dashboard/IWidgetComponentProps";
import Log from "@Log";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";

/** @screen */
@State.observer
class RssReaderWidget extends React.Component<IWidgetComponentProps> {

    @State.observable.ref private items: Item[] = [];
    @State.observable.ref private limit = 3;
    @State.observable.ref private errorState = false;
    @State.observable private feedUrl: string = null;

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.bound
    private async loadAsync() {
        this.setFeedUrl(this.props.configuration);
        try {
            const rssParser = new RssParser();
            const url = `${window.location.protocol}//${window.location.host}/http-rss/${this.feedUrl}`;
            const feedResponse = await fetch(url);
            if (!feedResponse.ok) {
                throw new Error(`Error reading from ${this.feedUrl}. Status is ${feedResponse.status} ${feedResponse.statusText}`);
            }
            const feedXml = await feedResponse.text();
            const feed = await rssParser.parseString(feedXml);
            this.setItems(feed.items);
        } catch (error) {
            Log.error(error);
            this.setError();
        }
    }

    @State.action.bound
    private setFeedUrl(configuration: any) {
        this.feedUrl = configuration.FeedUrl;
    }

    @State.action
    private setError() {
        this.errorState = true;
        this.items = [];
    }

    @State.action
    private setItems(items: Item[]) {
        this.items = items;
    }

    @State.action.bound
    private showMoreItems() {
        this.limit += 10;
    }

    @State.action.bound
    private showLessItems() {
        this.limit = 3;
    }

    public render() {
        return (
            this.renderContent()
        );
    }

    @State.bound
    private renderContent() {
        if (this.errorState) {
            return (
                <Ui.Dashboard.Widget
                    name={this.props.name}
                    containerHeight={this.props.configuration.InitialLayout.Height}
                    icon={"rss"}
                    isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                    isCollapsible
                    title={StaticProductivityResources.Widgets.RssReader.Title}
                    automationId="RssWidget"
                >
                    <SpanWithIcon visualStyle="warning" iconName="exclamation">{StaticWebAppResources.Common.Messages.ExternalServiceIsNotAvailable}</SpanWithIcon>
                </Ui.Dashboard.Widget>
            );
        }

        return (
            <Ui.Dashboard.Widget
                name={this.props.name}
                containerHeight={this.props.configuration.InitialLayout.Height}
                icon={"rss"}
                isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                isCollapsible
                title={StaticProductivityResources.Widgets.RssReader.Title}
                automationId="RssWidget"
            >
                {this.items.slice(0, this.limit).map(i => <FeedItem creator={i.creator} title={i.title} dateTime={i.isoDate} link={i.link} key={i.guid} />)}
                {this.items.length > this.limit &&
                    <ShowMoreOrLessItemsButton onClick={this.showMoreItems} content={<Ui.Icon iconName="chevronDown" visualStyle="white" />} />
                }
                {this.limit > 3 &&
                    <ShowMoreOrLessItemsButton onClick={this.showLessItems} content={<Ui.Icon iconName="chevronUp" visualStyle="white" />} />
                }

            </Ui.Dashboard.Widget>
        );
    }
}

export default withLoadingBoundary(withHisErrorBoundary(RssReaderWidget));