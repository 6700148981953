import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IWidgetComponentProps from "@PluginInterface/Dashboard/IWidgetComponentProps";
import * as Ui from "@CommonControls";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import _ from "@HisPlatform/Common/Lodash";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import GenderId from "@Primitives/GenderId.g";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import { IBarProps, IAxisProps } from "@CommonControls/Chart/BarChart";
import * as Styles from "./StateStatisticsWidget.less";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";

interface IStateStaticticsWidgetDependencies {
    localizationService: ILocalizationService;
    careApiAdapter: CareActivityApiAdapter2;
    referenceDataStore: CommonReferenceDataDataStore;
    applicationContext: ApplicationContext;
}

interface IStateStaticticsWidgetProps extends IWidgetComponentProps {
    _dependencies?: IStateStaticticsWidgetDependencies;
    pointOfCareId: PointOfCareId;
}

interface IStateChartData {
    state: CareActivityState;
    count: number;
}

interface IGenderData {
    genderId: GenderId;
    count: number;
}

/** @screen */
@State.observer
class StateStaticticsWidget extends React.Component<IStateStaticticsWidgetProps> {

    private readonly colorsByState: { [state: string]: string } = {
        Admitted: "#ddc00d",
        Waiting: "#fe8443",
        AdmissionDeleted: "#e8e8e8",
        Deleted: "#8695a7",
        DidNotAttend: "#B3B3B3",
        InProgress: "#21c313",
        Suspended: "#ce5374",
        Discharged: "#82d4ed",
        Closed: "#0082aa"
    };

    private readonly barSettings: IBarProps[] = [{
        dataKey: "count",
        nameKey: "name",
        getFill: this.getFill,
        label: {
            fill: "white",
            position: "right"
        }
    }];

    private readonly xAxisSettings: IAxisProps = {
        type: "number",
        domain: [0, dataMax => Math.ceil((dataMax * 1.2) / 5) * 5],
        allowDecimals: false
    };

    private readonly yAxisSettings: IAxisProps = {
        type: "category",
        dataKey: "name"
    };

    constructor(props: IStateStaticticsWidgetProps) {
        super(props);
        for (const enumItem in CareActivityState) {
            if (isNaN(Number(enumItem))) {
                const key = this.props._dependencies.localizationService.localizeEnum(enumItem, "CareActivityState").Name;
                this.colorsByLocalizedNames[key] = this.colorsByState[enumItem];
            }
        }
    }

    @State.observable.ref
    private data: IStateChartData[] = [];

    @State.observable.ref
    private genderData: IGenderData[] = [];

    private colorsByLocalizedNames: { [id: string]: string } = {};

    @State.observable.ref public date: LocalDate = LocalDate.today();

    @State.action.bound
    public setDate(date: LocalDate) {
        this.date = date;
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.action.bound
    private setData(data: IStateChartData[]) {
        this.data = data;
    }

    @State.action.bound
    private setGenderData(data: IGenderData[]) {
        this.genderData = data;
    }

    @State.bound
    private async loadStateStatisticsAsync() {
        const results = await this.props._dependencies.careApiAdapter.getcareActivityStateStatisticsAsync(this.props.pointOfCareId, this.date);
        this.setData(_.sortBy(results.value, i => i.state));
    }

    @State.bound
    private async loadGenderStatisticsAsync() {
        const results = await this.props._dependencies.careApiAdapter.getFinishedCareActivityGenderStatisticsAsync(this.props.pointOfCareId, this.date);
        this.setGenderData(results.value);
    }

    @State.bound
    private async loadReferenceDataAsync() {
        await this.props._dependencies.referenceDataStore.gender.ensureLoadedAsync();
    }

    @State.bound
    private async loadAsync() {
        if (this.props.pointOfCareId) {
            await this.loadReferenceDataAsync();
            await this.loadStateStatisticsAsync();
            await this.loadGenderStatisticsAsync();
        }
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IStateStaticticsWidgetProps) {
        if (prevProps.pointOfCareId !== this.props.pointOfCareId) {
            dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        }
    }

    @State.computed
    private get validData() {
        return this.data.filter(i => i.count > 0).map(i => {
            const name = this.props._dependencies.localizationService.localizeEnum(CareActivityState[i.state], "CareActivityState").Name;
            return {
                count: i.count,
                name: name
            };
        });
    }

    public renderTick(props: any) {
        return props && props.value;
    }

    @State.bound
    private getFill(label: string) {
        return this.colorsByLocalizedNames[label];
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return null;
        }

        const hasValidData = this.validData.length > 0;
        const height = (this.validData.length + 1) * 36;
        return (
            <Ui.Dashboard.Widget
                name={this.props.name}
                title={StaticCareResources.CareActivityStateStatisticsWidget.Title}
                icon="chart"
                isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                containerHeight={this.props.configuration.InitialLayout.Height}
                isCollapsible
                automationId="StateStaticticsWidget"
            >
                <Ui.DatePicker
                    value={this.date}
                    onChange={this.setDate}
                    label={StaticCareResources.CareActivityStateStatisticsWidget.DateLabel}
                    visualMode="dark"
                    clearable={false}
                    automationId="stateStaticticsWidgetDatePicker"
                />
                {hasValidData && (
                    <>
                        <div style={{ height: height }}>
                            <Ui.Chart.Bar
                                width={200}
                                height={height}
                                data={this.validData}
                                barSettings={this.barSettings}
                                margin={{ top: 10, left: 10, bottom: 0, right: 10 }}
                                YAxisProps={this.yAxisSettings}
                                XAxisProps={this.xAxisSettings}
                                theme="dark"
                                layout="vertical"
                            />
                        </div>
                        <p className={Styles.text}>
                            {StaticCareResources.CareActivityStateStatisticsWidget.SumLabel}: {this.genderData.map(i => i.count).reduce((a, b) => a + b, 0)}
                            {this.genderData && this.genderData.length > 0 && (" (" + this.genderData.map(i => `${i.count} ${this.props._dependencies.referenceDataStore.gender.get(i.genderId).displayValue.Name}`).join(" / ") + ")")}
                        </p>
                    </>
                )}
                {!hasValidData && <p className={Styles.text}>{StaticCareResources.CareActivityStateStatisticsWidget.NoDataMessage}</p>}
            </Ui.Dashboard.Widget>
        );
    }
}


const ConnectedStateStatisticsWidget: React.FC<IStateStaticticsWidgetProps> = State.observer(props => (
    <StateStaticticsWidget {...props}
        pointOfCareId={props._dependencies.applicationContext.contextualOrganizationUnitId}
    />
));

export default connect(
    withLoadingBoundary(withHisErrorBoundary(ConnectedStateStatisticsWidget)),
    new DependencyAdapter<IStateStaticticsWidgetProps, IStateStaticticsWidgetDependencies>(container => {
        return {
            localizationService: container.resolve("ILocalizationService"),
            careApiAdapter: container.resolve("CareActivityApiAdapter2"),
            applicationContext: container.resolve("ApplicationContext"),
            referenceDataStore: container.resolve("CommonReferenceDataDataStore")
        };
    })
);