import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/Api/Proxy.g";
import EhiToken from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiToken";
import StoreMapper from "@Toolkit/CommonWeb/ApiAdapter/StoreMapper";
import Di from "@Di";

@Di.injectable()
export default class EhiTokenStoreMapper extends StoreMapper<EhiToken, Proxy.LoginToEhiWithEidCommandResponse | Proxy.LoginToEhiWithOtpCommandResponse | Proxy.LoginToEhiWithMobileCommandResponse> {
    protected applyToStoreCore(target: EhiToken, response: Proxy.LoginToEhiWithEidCommandResponse | Proxy.LoginToEhiWithOtpCommandResponse | Proxy.LoginToEhiWithMobileCommandResponse) {
        const dto = response.result && response.result.token;
        if (dto) {
            target.tokenValue = dto.tokenValue;
            target.validFrom = dto.validFrom;
            target.validTo = dto.validTo;
            target.userId = response.result.loggedInUserId;
            target.ehiUserIdentifierValue = response.result.loggedInUserEhiIdentifier;
        }
    }
}