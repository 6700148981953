import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import PatientId from "@Primitives/PatientId.g";
import HunFinanceApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/HunFinanceApiAdapter";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import HunFinancingClassId from "@Primitives/HunFinancingClassId.g";

interface IPatientFinancingSelectBoxDependencies {
    localizationService: ILocalizationService;
}

interface IPatientFinancingSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    patientId: PatientId;
    financeClassIds: HunFinancingClassId[];
    _dependencies?: IPatientFinancingSelectBoxDependencies;
}

@State.observer
class PatientFinancingClassSelectBox extends React.Component<IPatientFinancingSelectBoxProps> {

    private get dependencies() {
        return this.props._dependencies;
    }

    public componentDidUpdate(prevProps: Readonly<IPatientFinancingSelectBoxProps>): void {
        if (this.props.financeClassIds !== prevProps.financeClassIds) {
            this.extensibleEnumStore.reload();
        }
    }

    @State.bound
    private getFinancingClassIds() {
        const store = new SimpleStore([], createOperationInfo({ isPersisted: "true" }));

        if (!this.props.financeClassIds) {
            // TODO: simple store without operation info never stops loading in extensible enum select box
            return Promise.resolve<SimpleStore<HunFinancingClassId[]>>(store);
        }
        store.value = this.props.financeClassIds;
        return Promise.resolve<SimpleStore<HunFinancingClassId[]>>(store);
    }

    private extensibleEnumStore = new ExtensibleEnumStore(this.getFinancingClassIds, this.dependencies.localizationService.localizeEnumId);

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    PatientFinancingClassSelectBox,
    new DependencyAdapter<IPatientFinancingSelectBoxProps, IPatientFinancingSelectBoxDependencies>(container => {
        return {
            financeApiAdapter: container.resolve("HunFinanceApiAdapter"),
            localizationService: container.resolve("ILocalizationService")
        };
    })
);
