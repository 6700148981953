import React from "react";
import * as Ui from "@CommonControls";
import LockToolbarComponentStore from "./LockToolbarComponentStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { ILockingClient } from "@HisPlatform/BoundedContexts/Locking/Api/Proxy.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";

const emptyFunc = () => { alert("Comming soon..."); };

interface ILockToolbarComponentDependencies {
    localizationService: ILocalizationService;
    lockingClient: ILockingClient;
}
interface ILockToolbarComponentProps {
    _dependencies?: ILockToolbarComponentDependencies;
}
@State.observer
class LockToolbarComponent extends React.Component<ILockToolbarComponentProps> {

    private store: LockToolbarComponentStore = new LockToolbarComponentStore(this.dependencies.lockingClient);
    private get dependencies() { return this.props._dependencies; }

    constructor(props: ILockToolbarComponentProps) {
        super(props);
        this.store.initialize();
    }

    public render() {
        return (
            <>
                <Ui.NavBar.Item automationId="releaseAll" onClick={this.store.releaseAllLocksAsync}>{StaticWebAppResources.Navbar.LockMenu.ReleaseAll}</Ui.NavBar.Item>
                <Ui.NavBar.Item automationId="releaseMine" onClick={this.store.releaseMyLocksAsync}>{StaticWebAppResources.Navbar.LockMenu.ReleaseMine}</Ui.NavBar.Item>
            </>
        );
    }
}
export default connect(
    LockToolbarComponent,
    new DependencyAdapter<ILockToolbarComponentProps, ILockToolbarComponentDependencies>((container) => {
        return {
            localizationService: container.resolve("ILocalizationService"),
            lockingClient: container.resolve("ILockingClient")
        };
    })
);