import React from "react";
import TextBox, { ITextBoxProps } from "@CommonControls/TextBox";
import { AsYouType } from "libphonenumber-js";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IPhoneTextBoxDependencies {
    currentCultureProvider: ICurrentCultureProvider;
}

interface IPhoneTextBoxProps extends ITextBoxProps {
    _dependencies?: IPhoneTextBoxDependencies;
}

class PhoneTextBox extends React.PureComponent<IPhoneTextBoxProps> {

    private readonly asYouType = new AsYouType(this.props._dependencies.currentCultureProvider.cultureCode.substring(0, 2).toUpperCase() as any);

    public render() {
        return <TextBox {...this.props} value={this.props.value} onChange={this.emitFormattedValue} />;
    }

    @State.bound
    private emitFormattedValue(newValue: string) {
        if (this.props.onChange) {
            this.asYouType.reset();
            this.asYouType.input(newValue);
            const number = this.asYouType.getNumber();
            if (number) {
                this.props.onChange(number.formatInternational());
            } else {
                this.props.onChange(newValue);
            }
        }
    }
}

export default connect(
    PhoneTextBox,
    new DependencyAdapter<IPhoneTextBoxProps, IPhoneTextBoxDependencies>(container => {
        return {
            currentCultureProvider: container.resolve("ICurrentCultureProvider")
        };
    })
);