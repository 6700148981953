import React from "react";

export function joinReactNodes(separator: React.ReactNode, items: React.ReactNode[]) {
    const result: React.ReactNode[] = [];

    let index = 0;
    items.map(i => {
        if (!!index) {
            result.push(<React.Fragment key={index}>{separator}</React.Fragment>);
            index++;
        }
        result.push(<React.Fragment key={index}>{i}</React.Fragment>);
        index++;
    });
    
    return result;
}