import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import PermanentPrescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PermanentPrescription";
import PermanentPrescriptionId from "@Primitives/PermanentPrescriptionId.g";
import MedicationRequestCoverage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationRequestCoverage";
import { mapMedicationReferenceBase } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/MedicationRequestMapperHelpers";

type ResponseType = Proxy.CreatePermanentPrescriptionCommandResponse;

export default class PermanentPrescriptionStoreMapper extends LockingEntityStoreMapper<Proxy.PermanentPrescriptionDto, PermanentPrescription, ResponseType> {
    protected storeEntityIdType = PermanentPrescriptionId;

    protected applyToStoreCore(target: PermanentPrescription, response: ResponseType): void {
        const dto = this.entityDtoSelector(response);
        if (!dto) {
            return;
        }

        target.conditionId = dto.conditionId;
        target.instruction = dto.instruction;
        target.insubstitutableReason = dto.insubstitutableReason;
        target.isEmergency = dto.isEmergency;
        target.isForcedPrescription = dto.isForcedPrescription;
        target.isSubstitubable = dto.isSubstitutable;
        target.notes = dto.notes;
        target.organizationUnitId = dto.organizationUnitId;
        target.registryNumber = dto.registryNumber;
        target.temporaryPermission.permissionIdentifier = dto.temporaryPermission?.permissionIdentifier;
        target.temporaryPermission.validTo = dto.temporaryPermission?.validTo;
        target.temporaryPermission.validFrom = dto.temporaryPermission?.validFrom;
        target.medicationRequestCoverage = new MedicationRequestCoverage();
        target.medicationRequestCoverage.claimTypeId = dto.medicationRequestCoverage?.medicationSubsidyClaimTypeId;
        target.medicationRequestCoverage.optionId = dto.medicationRequestCoverage?.medicationSubsidyOptionId;
        target.medicationRequestCoverage.subsidyId = dto.medicationRequestCoverage?.medicationSubsidyId;
        target.medicationReferenceBase = mapMedicationReferenceBase(dto.medicationReferenceBase);
    }

    protected entityDtoSelector(response: ResponseType) {
        return response.permanentPrescription;
    }

    protected vGetStoreIdentifier(): string {
        return "PermanentPrescription";
    }
}