import Base64Converter from "@Toolkit/CommonWeb/Base64";
import Encoding from "@Toolkit/CommonWeb/Encoding";
import DocumentContentStore from "@CommonControls/DocumentEditor/DocumentContentStore";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";

export default class DocumentFile {

    public readonly contentStore = DocumentContentStore.create();
    public metadata: object;
    
    public constructor(content: string, metadata: object) {
        this.contentStore.setContent(content);
        this.metadata = metadata;
    }

    public static create(base64Content: string) {
        const contentBytes = Base64Converter.toByteArray(base64Content);
        const content = Encoding.UTF8.getString(contentBytes);
        const json = JSON.parse(content);
        return new DocumentFile(json.documentContent, json.metadata ?? {});
    }   

    public getBase64(): string {
        const docFormat = {
            documentContent: this.contentStore.getContent(),
            metadata: this.metadata,
        };

        const jsonString = JSON.stringify(docFormat, null, "    ");
        const contentBytes = Encoding.UTF8.getBytes(jsonString);
        const contentBase64 = Base64Converter.fromByteArray(contentBytes);
        return contentBase64;
    }
}