import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import AutonomyDisabilityStatementId from "@Primitives/AutonomyDisabilityStatementId.g";
import AutonomyDisabilityAssignmentBase from "./AutonomyDisabilityAssignmentBase";
import { AutonomyDisabilityCodeSelectorItem } from "@HisPlatformControls/AutonomyDisabilityCodeSelector/AutonomyDisabilityCodeSelectorItem";
import MedicalConditionItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionItemBase";
import ReferencedAutonomyDisabilityAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/ReferencedAutonomyDisabilityAssignment";
import CustomAutonomyDisabilityAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/CustomAutonomyDisabilityAssignment";

export default class AutonomyDisabilityStatementStore extends MedicalConditionItemBase<AutonomyDisabilityStatementId> {

    @State.observable.ref public autonomyDisabilityAssignment: AutonomyDisabilityAssignmentBase;
    @State.observable public autonomyDisabilityName: string;

    constructor(isNew: boolean) {
        super(isNew);
    }

    @State.computed public get autonomyDisabilityCodeSelectorItem() {
        if (!this.autonomyDisabilityAssignment) {
            return null;
        }
        if (this.autonomyDisabilityAssignment instanceof ReferencedAutonomyDisabilityAssignment) {
            return new AutonomyDisabilityCodeSelectorItem({ id: this.autonomyDisabilityAssignment.id });
        } else if (this.autonomyDisabilityAssignment instanceof CustomAutonomyDisabilityAssignment) {
            return new AutonomyDisabilityCodeSelectorItem({ text: this.autonomyDisabilityAssignment.name });
        }
        return null;
    }

    @State.action.bound
    public setAutonomyDisabilityAssignment(newValue: AutonomyDisabilityAssignmentBase) {
        this.autonomyDisabilityAssignment = newValue;
    }

    @State.action.bound
    public setAutonomyDisabilityName(newValue: string) {
        this.autonomyDisabilityName = newValue;
    }
}