import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import { iconNameType } from "@CommonControls/Icon";
import DocumentKind from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentKind.g";

interface IDocumentKindSelectorProps extends ISelectBoxBaseProps {
    value?: DocumentKind | DocumentKind[];
    onChange?: (newValue: DocumentKind | DocumentKind[]) => void;
    displayMode?: "selectBox" | "groupedRadioButtons";
}


const DocumentKindSelector: React.FC<IDocumentKindSelectorProps> = props => {

    const getIconName = (value: DocumentKind): iconNameType => {
        switch (value) {
            case DocumentKind.TemplateBased:
                return "document_template";
            case DocumentKind.Binary:
                return "document_attached";
            default:
                return "document_empty";
        }
    };

    return (
        <UniversalEnumSelector
            {...props}
            enumName={"DocumentKind"}
            enumOrigin="server"
            enumType={DocumentKind}
            displayMode={props.displayMode ?? "groupedRadioButtons"}
            getIconName={getIconName}
        />
    );
};

export default DocumentKindSelector;