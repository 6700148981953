exports = module.exports = require("../../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CopyPointOfCareRolesModal_user-select-box-margin_3v_qK {\n  margin-bottom: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"user-select-box-margin": "CopyPointOfCareRolesModal_user-select-box-margin_3v_qK",
	"userSelectBoxMargin": "CopyPointOfCareRolesModal_user-select-box-margin_3v_qK"
};