import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ReportingApiAdapter from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/ReportingApiAdapter";
import { IReportDefinition } from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/Model/IReportDefinition";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import DocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/DocumentApiAdapter";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import { IOncologyDataReportFilters } from "./IOncologyDataReportFilters";
import OncologyDataReportPanelView from "./OncologyDataReportPanelView";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";

interface IOncologyDataReportPanelDependencies {
    reportingApiAdapter: ReportingApiAdapter;
    documentApiAdapter: DocumentApiAdapter;
    fileSaverService: IFileSaverService;
    notificationService: INotificationService;
}

interface IOncologyDataReportPanelProps {
    _dependencies?: IOncologyDataReportPanelDependencies;
    definition: IReportDefinition;
}

/** @screen */
@State.observer
class OncologyDataReportPanel extends React.Component<IOncologyDataReportPanelProps> {

    private get reportingApiAdapter() { return this.props._dependencies.reportingApiAdapter; }
    private get documentApiAdapter() { return this.props._dependencies.documentApiAdapter; }
    private get fileSaverService() { return this.props._dependencies.fileSaverService; }
    private get notificationService() { return this.props._dependencies.notificationService; }

    @State.observable.ref private dateRange: LocalDateRange = null;
    @State.observable.ref public pointOfCares: PointOfCareId[] = [];
    
    @State.action.bound
    public onPointOfCareChange(newValue: PointOfCareId, checkedValue: boolean) {
        if (this.pointOfCares) {
            if (checkedValue === false) {
                this.pointOfCares = this.pointOfCares.filter(x => x.value !== newValue.value);
            } else if (!this.pointOfCares.some(x => x.value === newValue.value)) {
                this.pointOfCares = [...this.pointOfCares, newValue];
            }
        }
    }

    @State.action.bound
    private onDateRangeChange(newValue: LocalDateRange) {
        this.dateRange = newValue;
    }

    @State.action.bound
    private async onCreateAsync() {
        const filters = {
            startDate: this.dateRange && this.dateRange.from,
            endDate: this.dateRange && this.dateRange.to,
            organizationUnitIds: this.pointOfCares && this.pointOfCares.map(x => new OrganizationUnitId(x.value))
        } as IOncologyDataReportFilters;

        // TODO: temporal solution, replace this with metadata service
        if (!this.isInvalid(filters)) {
            const filtersAsString = JSON.stringify(filters);
            const response = await this.reportingApiAdapter.runReportAsync(this.props.definition.reportDefinitionIdentifier, JSON.parse(filtersAsString));
            for (const largeDataId of response.value.largeDataIds) {
                const documentResponse = await this.documentApiAdapter.getDocumentContentAsync(largeDataId);
                const fileName = documentResponse.value.fileName;
                const contentBytes = Base64Converter.toByteArray(documentResponse.value.content);
                this.fileSaverService.saveAs(new Blob([contentBytes], {type: documentResponse.value.mediaType}), fileName);
            }
        }
    }

    // TODO: remove this, and move logic to metadata service
    @State.action
    private isInvalid(filters: IOncologyDataReportFilters): boolean {
        let i = 0;

        if (isNullOrUndefined(filters.startDate) && isNullOrUndefined(filters.endDate)) {
            this.notificationService.error("Időszak megadása kötelező!");
            i++;
        } else if (isNullOrUndefined(filters.startDate) || isNullOrUndefined(filters.endDate)) {
            this.notificationService.error("Nem megfelelő időszak!");
            i++;
        }

        if (filters.organizationUnitIds.length === 0) {
            this.notificationService.error("Legalább egy munkahely kiválasztása kötelező!");
            i++;
        }

        return i > 0;
    }
    
    private readonly loadAsync = createInitialPanelLoader(this._loadAsync);

    @State.bound
    private async _loadAsync() {
        await this.reportingApiAdapter.runReportAsync(this.props.definition.reportDefinitionIdentifier, {} as JSON, true);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }
    public render() {
        if (this.loadAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={this.props.definition.name}/>;
        }

        return (
            <OncologyDataReportPanelView
                definition={this.props.definition}
                dateRange={this.dateRange}
                onDateRangeChange={this.onDateRangeChange}
                onCreateAsync={this.onCreateAsync}
                pointOfCares={this.pointOfCares}
                onPointOfCareChange={this.onPointOfCareChange}
            />
        );
    }
}

export default connect(
    OncologyDataReportPanel,
    new DependencyAdapter<IOncologyDataReportPanelProps, IOncologyDataReportPanelDependencies>((c) => {
        return {
            reportingApiAdapter: c.resolve("ReportingApiAdapter"),
            fileSaverService: c.resolve("IFileSaverService"),
            notificationService: c.resolve("INotificationService"),
            documentApiAdapter: c.resolve("DocumentApiAdapter"),
        };
    })
);