import * as Proxy from "@HisPlatform/BoundedContexts/Scheduling/Api/Proxy.g";
import Di from "@Di";
import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";

type ResponseType =
    Proxy.SchedulingServiceDto 
    | Proxy.CreateSchedulingServiceCommandResponse
    | Proxy.UpdateSchedulingServiceCommandResponse;

@Di.injectable()
export default class SchedulingServiceStoreMapper extends EntityStoreMapper<Proxy.SchedulingServiceDto, SchedulingServiceSubjectStore, ResponseType> {
    protected entityDtoSelector(response: ResponseType) {
        if (response instanceof Proxy.SchedulingServiceDto) {
            return response;
        } else if (response instanceof Proxy.CreateSchedulingServiceCommandResponse || response instanceof Proxy.UpdateSchedulingServiceCommandResponse) {
            return response.schedulingService;
        }

        return null;
    }

    protected applyToStoreCore(target: SchedulingServiceSubjectStore, response: any): void {
        const dto = this.entityDtoSelector(response);
        if (!dto) {
            return;
        }

        target.id = dto.id;
        target.code = dto.code;
        target.isTelemedicineConsultation = dto.isTelemedicineConsultation;
        target.name = dto.name;
        target.rowVersion = dto.rowVersion;
        target.durationInMinutes = dto.durationInMinutes;
        target.careLocationId = dto.careLocationId;
        target.careLocationDescription = dto.careLocationDescription;
        target.participantOptions = dto.participantOptions;
    }

    protected storeEntityIdType = SchedulingServiceId;
}