import IStatusChangeBlockingReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/IStatusChangeBlockingReason";
import performanceStatementStandaloneRouteDefinitions from "@HunSocialSecurityPlugin/UseCases/PerformanceStatementUseCase/PerformanceStatementRoutes";
import IStateChangeBlockingRegistry from "@PluginInterface/BoundedContexts/Care/CareRegister/StateChange/IStateChangeBlockingRegistry";
import CareActivityId from "@Primitives/CareActivityId.g";
import ResourceId from "@Primitives/ResourceId.g";
import StateChangeBlockingData from "@Primitives/StateChangeBlockingData.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";

export default function configureStateChangeBlockings(stateChangeBlockingRegistry: IStateChangeBlockingRegistry) {
    stateChangeBlockingRegistry.register(
        StateChangeBlockings.performanceStatement,
        (routingController: IRoutingController<any>, _: IActionDispatcher, careActivityId: CareActivityId) => navigateToPerformanceStatements(routingController, careActivityId),
        (localizationService: ILocalizationService) => getTitle(localizationService, StateChangeBlockings.performanceStatement),
        (localizationService: ILocalizationService, reason: IStatusChangeBlockingReason) => getGenericMessage(localizationService, reason));
}

function getTitle(localizationService: ILocalizationService, stateChangeBlockingData: StateChangeBlockingData) {
    return localizationService.localizeReferenceData(new ResourceId("StatusChangeBlockingData." + stateChangeBlockingData.value));
}

function getGenericMessage(localizationService: ILocalizationService, reason: IStatusChangeBlockingReason) {
    if (isNullOrUndefined(reason) || isNullOrUndefined(reason.resourceId)) {
        return null;
    }

    return localizationService.localizeReferenceData(reason.resourceId);
}

function navigateToPerformanceStatements(routingController: IRoutingController<any>, careActivityId: CareActivityId) {
    routingController.push(performanceStatementStandaloneRouteDefinitions.neakPerformanceStatement.makeRoute());
}

class StateChangeBlockings {
    public static readonly performanceStatement = new StateChangeBlockingData("PerformanceStatement");
}