// tslint:disable:variable-name
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import HomeControllerStore from "./HomeControllerStore";

const { 
    ContextComponent: HomeControllerStoreContext, 
    StoreProvider: HomeControllerStoreProvider, 
    useStore: useHomeControllerStore,
    provideStore: provideHomeControllerStore,
} = createPanelStoreProvider(HomeControllerStore);

export {
    HomeControllerStoreContext,
    HomeControllerStoreProvider,
    useHomeControllerStore,
    provideHomeControllerStore
};