import React from "react";
import PortalHost, { IPortalHostProps } from "@Toolkit/ReactClient/Components/Portal/PortalHost";
import usePortalRenderer from "@Toolkit/ReactClient/Components/Portal/UsePortalRendererHook";

export interface IPortalProps {
    name: string;
}

function Portal(props: React.PropsWithChildren<IPortalProps>): React.ReactElement {
    const renderPortalContent = usePortalRenderer(`portal_${props.name}`, props.children);
    return renderPortalContent();
}

interface IPortalWithHost {
    Host: React.FC<IPortalHostProps>;
}

interface INamedPortalHostProps {
    className?: string;
    style?: React.CSSProperties;
    elementType?: keyof React.ReactHTML;
}

interface INamedPortalProps {
    onContentChanged?: (content: React.ReactNode, portalHost: HTMLElement) => void;
}

interface INamedPortal extends React.FC<INamedPortalProps> {
    Host: React.FC<INamedPortalHostProps>;
}

function createNamedPortal(name: string): INamedPortal {

    const namedPortal: React.FC<IPortalProps> = props => (
        <Portal {...props} name={name} />
    );

    (namedPortal as any).Host = (props: INamedPortalHostProps) => (
        <PortalHost {...props} name={name} />
    );

    return namedPortal as unknown as INamedPortal;
}

Portal.Host = PortalHost;
Portal.createNamedPortal = createNamedPortal;

interface INamedPortalCreator {
    createNamedPortal: (name: string) => INamedPortal;
}

export default Portal as IPortalWithHost & INamedPortalCreator;