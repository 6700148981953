import EhiErrorSeverity from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiErrorSeverity";
import IEhiError from "@HunEHealthInfrastructurePlugin/Common/Model/IEhiError";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { EhiErrorDto } from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";

export function mapEhiErrors(errors: EhiErrorDto[]) {
    if (isNullOrUndefined(errors)) {
        return [];
    }

    return errors.map(i => ({
        code: i.code,
        message: i.message,
        moduleName: i.moduleName,
        severity: i.ehiErrorSeverity as any as EhiErrorSeverity
    } as IEhiError));
}