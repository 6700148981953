import Di from "@Di";
import { IValidationProblemParameterMapper } from "@Toolkit/CommonWeb/ApiAdapter/IValidationProblemParameterMapper";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import HunFinanceReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/Model/HunFinanceReferenceDataStore";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import _ from "@HisPlatform/Common/Lodash";
import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import CalendarUnit from "@Toolkit/CommonWeb/Model/CalendarUnit";
import Age from "@Toolkit/CommonWeb/Model/Age";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import moment from "moment";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import FinancedServiceLimitConditionId from "@Primitives/FinancedServiceLimitConditionId.g";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import ConditionId from "@Primitives/ConditionId.g";

interface IRelatedCareActivity {
    wentUnderCareAt: moment.Moment;
    pointOfCareId: PointOfCareId;
    serviceId: FinancedServiceId;
}

@Di.injectable()
export default class HunCarePerformedServiceListValidationParameterMapper implements IValidationProblemParameterMapper {

    constructor(
        @Di.inject("FinanceReferenceDataStore") private financeReferenceDataStore: FinanceReferenceDataStore,
        @Di.inject("OrganizationReferenceDataStore") private organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("CareReferenceDataStore") private careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("ILocalizationService") private localizationService: ILocalizationService,
        @Di.inject("HunFinanceReferenceDataStore") private hunFinanceReferenceDataStore: HunFinanceReferenceDataStore
    ) { }

    private getServiceId(problem: IClientValidationProblem, dtoName: string) {
        const rawId = _.get(problem.parameters, dtoName + ".ServiceId.Value");
        return rawId ? new FinancedServiceId(rawId.toString()) : null;
    }

    public getRawRelatedCareActivities(problem: IClientValidationProblem, dtoName: string) {
        return _.get(problem?.parameters || {}, dtoName + ".RelatedCareActivities") || [];
    }

    private getMinAgeDisplay(problem: IClientValidationProblem) {
        const calendarUnit = _.get(
            problem.parameters,
            "PerformedServiceAgeRuleViolationParametersDto.MinAge.CalendarUnit") as unknown as CalendarUnit;
        const numberOfUnits = _.get(
            problem.parameters,
            "PerformedServiceAgeRuleViolationParametersDto.MinAge.NumberOfUnits"
        );

        return calendarUnit && !isNullOrUndefined(numberOfUnits) ? new Age(calendarUnit, numberOfUnits, false) : null;
    }

    private getMaxAgeDisplay(problem: IClientValidationProblem) {
        const calendarUnit = _.get(
            problem.parameters,
            "PerformedServiceAgeRuleViolationParametersDto.MaxAge.CalendarUnit") as unknown as CalendarUnit;
        const numberOfUnits = _.get(
            problem.parameters,
            "PerformedServiceAgeRuleViolationParametersDto.MaxAge.NumberOfUnits"
        );

        return calendarUnit && !isNullOrUndefined(numberOfUnits) ? new Age(calendarUnit, numberOfUnits, false) : null;
    }

    private getLimitConditionId(problem: IClientValidationProblem) {
        const limitConditionIdValue = _.get(problem.parameters, "PerformedServiceConditionalLimitRuleViolationParametersDto.FinancedServiceLimitConditionId.Value");
        return limitConditionIdValue ? new FinancedServiceLimitConditionId(limitConditionIdValue.toString()) : null;
    }

    private getMaxCount(problem: IClientValidationProblem, dtoName: string) {
        return _.get(problem.parameters, `${dtoName}.MaxCount`);
    }

    private getRawRule(problem: IClientValidationProblem) {
        return _.get(problem.parameters, "PerformedServiceConditionalLimitRuleViolationParametersDto.RawRule");
    }

    private getRelatedServiceIds(problem: IClientValidationProblem) {
        const ids = _.get(problem.parameters, "PerformedServiceExcludeRuleViolationParametersDto.RelatedServiceIds") as any[];
        return ids?.map(i => new FinancedServiceId(i?.Value?.toString())) || [];
    }

    private getServiceIds(problem: IClientValidationProblem, dtoName: string) {
        const ids = _.get(problem.parameters, dtoName + ".ServiceIds") as any[];
        return ids?.map(i => new FinancedServiceId(i?.Value?.toString())) || [];
    }

    private getFinancedServiceIds(problem: IClientValidationProblem) {
        const ids = _.get(problem.parameters, "FinancedServiceIds") as any[];
        return ids?.map(i => new FinancedServiceId(i?.Value?.toString())) || [];
    }

    protected getRelatedCareActivities(problem: IClientValidationProblem, dtoName: string): IRelatedCareActivity[] {
        const careActivities = this.getRawRelatedCareActivities(problem, dtoName);
        return careActivities.map((careActivity: any) => {
            const serviceIdValue = careActivity?.Service?.Value?.toString();
            const pointOfCareIdValue = careActivity?.PointOfCare?.Value?.toString();
            return {
                pointOfCareId: pointOfCareIdValue ? new PointOfCareId(pointOfCareIdValue) : null,
                wentUnderCareAt: moment(careActivity?.WentUnderCareAt),
                serviceId: serviceIdValue ? new FinancedServiceId(serviceIdValue) : null
            };
        });
    }

    private getRelatedCareActivity(problem: IClientValidationProblem, dtoName: string): IRelatedCareActivity {
        const careActivity = _.get(problem.parameters, dtoName + ".RelatedCareActivity");
        const serviceIdValue = careActivity?.Service?.Value?.toString();
        const pointOfCareIdValue = careActivity?.PointOfCare?.Value?.toString();
        return careActivity ? {
            pointOfCareId: pointOfCareIdValue ? new PointOfCareId(pointOfCareIdValue) : null,
            wentUnderCareAt: moment(careActivity?.WentUnderCareAt),
            serviceId: serviceIdValue ? new FinancedServiceId(serviceIdValue) : null
        } : null;
    }

    public getDuration(problem: IClientValidationProblem) {
        const rawDuration = _.get(problem.parameters, "PerformedServiceRepeatRuleViolationParametersDto.RepeatInterval");
        const parsedDuration = moment.duration(rawDuration);
        return parsedDuration;
    }

    private getConditionIds(problem: IClientValidationProblem) {
        const ids = _.get(problem.parameters, "PerformedServiceDiagnosisRuleViolationParametersDto.Conditions") as any[];
        return ids?.map(i => new ConditionId(i?.Value?.toString())) || [];
    }

    private getMissingServiceIds(problem: IClientValidationProblem) {
        const ids = _.get(problem.parameters, "PerformedServiceTogetherRuleViolationParametersDto.MissingServiceIds") as any[];
        return ids?.map(i => new FinancedServiceId(i?.Value?.toString())) || [];
    }

    public collectValidationProblemParameterReferenceData(vp: IClientValidationProblem): void {
        for (const key in vp.parameters) {
            if (key) {
                const serviceId = this.getServiceId(vp, key);
                if (serviceId) {
                    this.financeReferenceDataStore.financedServiceMap.collectVersionSelector({
                        id: serviceId,
                        validOn: LocalDate.today()
                    });
                }

                const serviceIds = this.getServiceIds(vp, key);
                if (serviceIds && serviceIds.length > 0) {
                    for (const serviceId2 of serviceIds) {
                        this.financeReferenceDataStore.financedServiceMap.collectVersionSelector({ id: serviceId2, validOn: LocalDate.today() });
                    }
                }

                const missingServiceIds = this.getMissingServiceIds(vp);
                if (missingServiceIds && missingServiceIds.length > 0) {
                    for (const serviceId2 of missingServiceIds) {
                        this.financeReferenceDataStore.financedServiceMap.collectVersionSelector({ id: serviceId2, validOn: LocalDate.today() });
                    }
                }

                const relatedServiceIds = this.getRelatedServiceIds(vp);
                if (relatedServiceIds && relatedServiceIds.length > 0) {
                    for (const serviceId2 of relatedServiceIds) {
                        this.financeReferenceDataStore.financedServiceMap.collectVersionSelector({ id: serviceId2, validOn: LocalDate.today() });
                    }
                }

                const relatedCareActivities = this.getRelatedCareActivities(vp, key);
                if (relatedCareActivities && relatedCareActivities?.length > 0) {
                    for (const careActivity of relatedCareActivities) {
                        this.collectRelatedCareActivityReferenceData(careActivity);
                    }
                }

                const relatedCareActivity = this.getRelatedCareActivity(vp, key);
                if (relatedCareActivity) {
                    this.collectRelatedCareActivityReferenceData(relatedCareActivity);
                }

                const conditionIds = this.getConditionIds(vp);
                if (conditionIds && conditionIds.length > 0) {
                    for (const conditionId of conditionIds) {
                        this.careReferenceDataStore.condition.collectVersionSelector({ id: conditionId, validOn: LocalDate.today() });
                    }
                }

                const financedServiceIds = this.getFinancedServiceIds(vp);
                if (financedServiceIds && financedServiceIds.length > 0) {
                    for (const financedServiceId of financedServiceIds) {
                        this.financeReferenceDataStore.financedServiceMap.collectVersionSelector({ id: financedServiceId, validOn: LocalDate.today() });
                    }
                }
            }
        }
    }

    private collectRelatedCareActivityReferenceData(careActivity: IRelatedCareActivity) {
        if (careActivity?.serviceId) {
            this.financeReferenceDataStore.financedServiceMap.collectVersionSelector({ id: careActivity.serviceId, validOn: LocalDate.today() });
        }

        if (careActivity?.pointOfCareId) {
            this.organizationReferenceDataStore.pointOfCareMap.collectId(careActivity.pointOfCareId);
        }
    }

    public async loadCollectedValidationProblemParameterReferenceDataAsync(): Promise<void> {
        await this.financeReferenceDataStore.financedServiceMap.loadCollectedAsync();
        await this.careReferenceDataStore.condition.loadCollectedAsync();
        await this.hunFinanceReferenceDataStore.financedServiceLimitCondition.ensureLoadedAsync();
        await this.organizationReferenceDataStore.pointOfCareMap.loadCollectedAsync();
    }

    public resolveValidationProblemParameters(vp: IClientValidationProblem): { [id: string]: string; } {
        let results = {};
        for (const key in vp.parameters) {
            if (key) {

                const serviceId = this.getServiceId(vp, key);

                if (serviceId) {
                    results = {
                        ...results, ServiceCode: this.financeReferenceDataStore.financedServiceMap.get({
                            id: serviceId,
                            validOn: LocalDate.today()
                        }).code.value
                    };
                }
                switch (key) {
                    case "PerformedServiceAgeRuleViolationParametersDto":
                        this.resolveAgeRule(vp, key, results);
                        break;
                    case "PerformedServiceConditionalLimitRuleViolationParametersDto":
                        this.resolveConditionalLimitRule(vp, key, results);
                        break;
                    case "PerformedServiceExcludeRuleViolationParametersDto":
                        this.resolveExcludeRule(vp, key, results);
                        break;
                    case "PerformedServiceRepeatRuleViolationParametersDto":
                        this.resolveRepeatRule(vp, key, results);
                        break;
                    case "PerformedServiceThreeRuleViolationParametersDto":
                        this.resolveThreeRule(vp, key, results);
                        break;
                    case "FinancedServiceIds":
                        this.resolveFinanceServiceIDsRule(vp, key, results);
                        break;
                }
            }
        }

        return results;
    }

    private resolveAgeRule(vp: IClientValidationProblem, key: string, results: any) {
        const maxAge = this.getMaxAgeDisplay(vp);
        const minAge = this.getMinAgeDisplay(vp);

        if (maxAge && minAge) {
            results.AgeLimit = minAge.numberOfUnits + " - " + this.localizationService.localizeAge(maxAge);
        }
    }

    private resolveConditionalLimitRule(vp: IClientValidationProblem, key: string, results: any) {
        const limitId = this.getLimitConditionId(vp);
        const relatedCareActivities = this.getRelatedCareActivities(vp, key);

        if (limitId) {
            const maxCount = this.getMaxCount(vp, key);

            const limit = formatStringWithObjectParams(StaticCareResources.OutpatientWorkflow.PerformedServicesStep.MaximumFormat, {
                ConditionalLimit: this.localizationService.localizeEnumId(limitId)?.Name?.toLocaleLowerCase() || "",
                MaxCount: maxCount
            });

            results.Condition = ValueWrapper.equals(limitId, FinancedServiceLimitConditionId.NotProcessed) ? this.getRawRule(vp) : limit;
            results.AlreadyRecordedInOtherCareActivity = relatedCareActivities?.length > 0 ? StaticCareResources.OutpatientWorkflow.PerformedServicesStep.AlreadyRecordedInOtherCareActivity : "";

            if (maxCount || maxCount === 0) {
                results.MaxCount = maxCount;
            }
        }
    }

    private resolveExcludeRule(vp: IClientValidationProblem, key: string, results: any) {
        const relatedServiceIds = this.getRelatedServiceIds(vp);
        if (relatedServiceIds && relatedServiceIds.length > 0) {
            const services = relatedServiceIds.map(i => this.financeReferenceDataStore.financedServiceMap.get({ id: i, validOn: LocalDate.today() }).code.value);
            results.ServiceCode2 = services.join(", ");
        }

        const relatedCareActivity = this.getRelatedCareActivity(vp, key);

        let relatedCareActivityObjectParams = null;
        if (relatedCareActivity) {
            const service = this.financeReferenceDataStore.financedServiceMap.get({ id: relatedCareActivity.serviceId, validOn: null });
            relatedCareActivityObjectParams = {
                OtherServiceCode: service?.code?.value
            };
        }

        results.ServiceInOtherCareActivity = relatedCareActivity && formatStringWithObjectParams(StaticCareResources.OutpatientWorkflow.PerformedServicesStep.RecordedInOtherCareActivity, relatedCareActivityObjectParams) || "";
    }

    private resolveRepeatRule(vp: IClientValidationProblem, key: string, results: any) {
        const maxCount = this.getMaxCount(vp, key);
        if (maxCount || maxCount === 0) {
            results.MaxCount = maxCount;
        }

        const duration = this.getDuration(vp);
        if (duration) {
            results.Duration = duration.humanize();
        }

        const relatedCareActivities = this.getRelatedCareActivities(vp, key);
        results.AlreadyRecordedInOtherCareActivity = relatedCareActivities?.length > 0 ? StaticCareResources.OutpatientWorkflow.PerformedServicesStep.AlreadyRecordedInOtherCareActivity : "";
    }

    private resolveThreeRule(vp: IClientValidationProblem, key: string, results: any) {
        const serviceIds = this.getServiceIds(vp, key);
        const relatedCareActivities = this.getRelatedCareActivities(vp, key);
        const allServiceCodes: string[] = [];
        const codesInOtherCareActivity: string[] = [];
        if (serviceIds) {
            for (const serviceId of serviceIds) {
                const serviceCode = this.financeReferenceDataStore.financedServiceMap.get({ id: serviceId, validOn: LocalDate.today() })?.code?.value;
                allServiceCodes.push(serviceCode);
            }
        }

        if (relatedCareActivities.length > 0) {
            for (const relatedCareActivity of relatedCareActivities) {
                const serviceCode = this.financeReferenceDataStore.financedServiceMap.get({ id: relatedCareActivity.serviceId, validOn: LocalDate.today() })?.code?.value;
                codesInOtherCareActivity.push(serviceCode);
            }
        }

        results.ServiceCodes = allServiceCodes.join(", ");
        results.ServiceInOtherCareActivity = relatedCareActivities.length > 0
            ? formatStringWithObjectParams(
                StaticCareResources.OutpatientWorkflow.PerformedServicesStep.RecordedInOtherCareActivity,
                { OtherServiceCode: codesInOtherCareActivity.join(", ") }
            )
            : "";
    }

    private resolveFinanceServiceIDsRule(vp: IClientValidationProblem, key: string, results: any) {
        const financedServiceIds = this.getFinancedServiceIds(vp);
        const serviceIds: string[] = [];

        for (const financedServiceId of financedServiceIds) {
            const service = this.financeReferenceDataStore.financedServiceMap.get({ id: financedServiceId, validOn: LocalDate.today() });            
            serviceIds.push(service.code.value);
        }      
        results.ServiceCodes = serviceIds.join(", ");
    }
}