exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ToggleMenuItem_toggle-on_2wZe_,\n.ToggleMenuItem_toggle-on_2wZe_:hover,\n.ToggleMenuItem_toggle-on_2wZe_:visited {\n  background-color: #4393e6;\n  color: white;\n}\n.ToggleMenuItem_toggle-on_2wZe_ svg,\n.ToggleMenuItem_toggle-on_2wZe_:hover svg,\n.ToggleMenuItem_toggle-on_2wZe_:visited svg {\n  fill: white;\n}\n.ToggleMenuItem_white-text_3GS9D {\n  color: white;\n}\n", ""]);

// exports
exports.locals = {
	"toggle-on": "ToggleMenuItem_toggle-on_2wZe_",
	"toggleOn": "ToggleMenuItem_toggle-on_2wZe_",
	"white-text": "ToggleMenuItem_white-text_3GS9D",
	"whiteText": "ToggleMenuItem_white-text_3GS9D"
};