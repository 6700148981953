import IPropertyValueVersion from "@HisPlatform/Model/DomainModel/Common/IPropertyValueVersion";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class PropertyValueVersion<T> implements IPropertyValueVersion<T> {
    @State.observable.ref public validFrom?: LocalDate;
    @State.observable.ref public validTo?: LocalDate;
    @State.observable.ref public value: T;

    constructor(
        value: T,
        validFrom?: LocalDate,
        validTo?: LocalDate,
    ) {
        this.validFrom = validFrom;
        this.validTo = validTo;
        this.value = value;
    }

    @State.action.bound
    public setValidFrom(value: LocalDate) {
        this.validFrom = value;
    }

    @State.action.bound
    public setValidTo(value: LocalDate) {
        this.validTo = value;
    }

    @State.action.bound
    public setValue(value: T) {
        this.value = value;
    }
}
