// tslint:disable:variable-name
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import CareActivityTextBlockListPanelScreenStore from "@HisPlatform/Packages/Care/Screens/CareActivityTextBlockListPanelScreen/CareActivityTextBlockListPanelScreenStore";

const { 
    ContextComponent: CareActivityTextBlockListPanelScreenStoreContext, 
    StoreProvider: CareActivityTextBlockListPanelScreenStoreProvider, 
    useStore: useCareActivityTextBlockListPanelScreenStore,
    provideStore: provideCareActivityTextBlockListPanelScreenStore,
} = createPanelStoreProvider(CareActivityTextBlockListPanelScreenStore);

export {
    CareActivityTextBlockListPanelScreenStoreContext,
    CareActivityTextBlockListPanelScreenStoreProvider,
    useCareActivityTextBlockListPanelScreenStore,
    provideCareActivityTextBlockListPanelScreenStore
};