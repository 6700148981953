import DischargeReasonId from "@Primitives/DischargeReasonId.g";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import ForwardingNeededAtDischargeApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ForwardingNeededAtDischargeApiAdapter";
import IForwardingNeededAtDischarge from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IForwardingNeededAtDischarge";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class ForwardingNeededAtDischargeReferenceDataStore {
    @State.observable.ref private _map: Map<string, IForwardingNeededAtDischarge> = null;
    public get map() { return new Map<string, IForwardingNeededAtDischarge>(this._map); }

    constructor(
        private readonly dischargeReasonStore: ExtensibleEnumStore<DischargeReasonId>,
        private readonly forwardingNeededAtDischargeApiAdapter: ForwardingNeededAtDischargeApiAdapter
    ) {
    }

    public async ensureLoadedAsync() {
        if (this._map !== null) {
            return;
        }

        await this.dischargeReasonStore.ensureLoadedAsync();

        const result =
            await this.forwardingNeededAtDischargeApiAdapter.getByIdsAsync(this.dischargeReasonStore.items.map(i => i.id));

        State.runInAction(() => {
            this._map = new Map(
                result.value.map(i => [i.id.value, i])
            );
        });
    }

    @State.bound
    public tryGetById(id: DischargeReasonId) {
        if (this._map.has(id.value)) {
            return this._map.get(id.value);
        } else {
            return null;
        }
    }
}