import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import _ from "@HisPlatform/Common/Lodash";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import EhiEReferralId from "@Primitives/EhiEReferralId.g";
import EhiEReferralDetailPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralDetailDialog/EhiEReferralDetailPanel";
import CareActivityEReferralStore from "@HunEHealthInfrastructurePlugin/Model/CareActivityEReferralStore";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import EhiCareService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareService";
import PatientId from "@Primitives/PatientId.g";
import EhiServiceCallStatus from "@HunEHealthInfrastructurePlugin/Model/EhiServiceCallStatus";
import * as Ui from "@CommonControls";

interface IEhiEReferralPanelDependencies {
    careApiAdapter: EhiCareApiAdapter;
}

interface IEhiEReferralPanelProps {
    _dependencies?: IEhiEReferralPanelDependencies;
    ehiEReferralId: EhiEReferralId;
    ehiCareService: EhiCareService;
    isEmergency: boolean;
    emergencyReason?: string;
    patientId: PatientId;
}

/** @screen */
@State.observer
class EhiEReferralPanel extends React.Component<IEhiEReferralPanelProps> {

    @State.observable.ref private eReferralStore: CareActivityEReferralStore = null;
    @State.observable private isLoading = false;

    public componentDidMount(): void {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }

    @State.action.bound
    private setEReferralStore(newValue: CareActivityEReferralStore) {
        this.eReferralStore = newValue;
    }

    @State.boundLoadingState()
    private async initializeAsync() {
        const ehiServiceCallResult = await this.props.ehiCareService.getEReferralByIdAsync(
            this.props.patientId,
            this.props.ehiEReferralId,
            this.props.isEmergency,
            this.props.emergencyReason);

        if (ehiServiceCallResult.ehiServiceCallStatus === EhiServiceCallStatus.Success &&
            ehiServiceCallResult.result &&
            ehiServiceCallResult.result.operationWasSuccessful) {

            this.setEReferralStore(ehiServiceCallResult.result.value.value);
        }
    }

    public render() {
        if (this.isLoading) {
            return <></>;
        }
        return (
            <>
                {
                    !!this.eReferralStore
                        ? <Ui.ScrollView height="calc(100vh - 170px)">
                            <EhiEReferralDetailPanel careActivityEReferralStore={this.eReferralStore} />
                        </Ui.ScrollView>
                        : <Ui.Button automationId="reloadButton" onClickAsync={this.initializeAsync} text={StaticWebAppResources.Common.Button.Reload} iconName="sync" />
                }
            </>
        );
    }
}

export default connect(
    EhiEReferralPanel,
    new DependencyAdapter<IEhiEReferralPanelProps, IEhiEReferralPanelDependencies>((c) => {
        return {
            careApiAdapter: c.resolve<EhiCareApiAdapter>("EhiCareApiAdapter")
        };
    })
);
