import State from "@Toolkit/ReactClient/Common/StateManaging";
import React, { useEffect } from "react";
import FamilyDoctorDocumentMedicationReference from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentMedicationReference";
import FamilyDoctorDocumentMedicationReferenceListItemView from "./FamilyDoctorDocumentMedicationReferenceListItemView";
import MedicationPricingAndSubsidies from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationPricingAndSubsidies";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FamilyDoctorDocumentSingleMedication from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentSingleMedication";
import PricingAndSubsidyLoader from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/PricingAndSubsidyLoader";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import MedicationId from "@Primitives/MedicationId.g";
import ReferenceDataApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/ReferenceDataApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import FamilyDoctorDocumentMediactionFormulaItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentMediactionFormulaItem";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import MedicationSubsidyId from "@Primitives/MedicationSubsidyId.g";
import MedicationSubsidyOptionId from "@Primitives/MedicationSubsidyOptionId.g";

interface IFamilyDoctorDocumentMedicationReferenceListItemDependecies {
    referenceDataStore: MedicationRequestReferenceDataStore;
    pricingAndSubsidyLoader: PricingAndSubsidyLoader;
    referenceApiAdapter: ReferenceDataApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
}
interface IFamilyDoctorDocumentMedicationReferenceListItemProps {
    item: FamilyDoctorDocumentMedicationReference;
    index: number;
    _dependencies?: IFamilyDoctorDocumentMedicationReferenceListItemDependecies;
}

@State.observer
class FamilyDoctorDocumentMedicationReferenceListItem extends React.Component<IFamilyDoctorDocumentMedicationReferenceListItemProps>{

    @State.observable.ref public pricingAndSubsidies: MedicationPricingAndSubsidies = null;

    private get pricingAndSubsidyLoader() {
        return this.props._dependencies.pricingAndSubsidyLoader;
    }
    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }
    public async loadAsync() {
        await this.props._dependencies.referenceDataStore.medicationSubsidyClaimTypes.ensureAllLoadedAsync();
        if (this.props.item.detail instanceof FamilyDoctorDocumentSingleMedication) {
            if (!isNullOrUndefined(this.props.item.detail.medicationVersionSelector)) {
                this.loadMedicationPricingAndSubsidiesAsync(this.props.item.detail.medicationVersionSelector);
                await this.props._dependencies.careReferenceDataStore.medications.ensureLoadedAsync([this.props.item.detail.medicationVersionSelector]);

            }
        }
    }
    public componentDidUpdate(prevProps: IFamilyDoctorDocumentMedicationReferenceListItemProps) {
        if (this.props.item.detail instanceof FamilyDoctorDocumentSingleMedication && prevProps.item.detail instanceof FamilyDoctorDocumentSingleMedication) {
            if (!(ValueWrapper.equals(this.props.item.detail.medicationVersionSelector?.id, prevProps.item.detail.medicationVersionSelector?.id))) {
                dispatchAsyncErrors(this.loadMedicationPricingAndSubsidiesAsync(this.props.item.detail.medicationVersionSelector), this);
            }
        }
    }

    @State.computed private get possibleSubsidyByPricingType() {
        if (!(this.props.item.detail instanceof FamilyDoctorDocumentSingleMedication)) {
            return null;
        }
        if (isNullOrUndefined(this.props.item.detail.medicationSubsidyClaimTypeId)) {
            return null;
        }
        const claimType = this.props._dependencies.referenceDataStore.medicationSubsidyClaimTypes.get(this.props.item.detail.medicationSubsidyClaimTypeId);
        if (isNullOrUndefined(claimType?.medicationSubsidizedPricingTypeId)) {
            return null;
        }
        const subsidy = this.pricingAndSubsidies?.subsidies?.find(i => ValueWrapper.equals(i.pricingType, claimType.medicationSubsidizedPricingTypeId));
        return subsidy;
    }

    @State.bound
    private async loadSubstancesAsync(id: IEntityVersionSelector<MedicationId>) {
        if (this.props.item.detail instanceof FamilyDoctorDocumentSingleMedication) {
            const medication = this.props._dependencies.careReferenceDataStore.medications.get(id);
            if (medication.substanceIds && medication.substanceIds.length > 0) {
                const substanceVersionSelectors = medication.substanceIds.map(i => ({ id: i, validOn: id.validOn }));
                await this.props._dependencies.careReferenceDataStore.substances.ensureLoadedAsync(substanceVersionSelectors);
                const substance = this.props._dependencies.careReferenceDataStore.substances.get(substanceVersionSelectors[0]);
                this.props.item.detail.setSubstanceName(substance.name);
            }
        }

    }

    @State.bound
    private async loadMedicationPricingAndSubsidiesAsync(medicationVersionSelector: EntityVersionSelector<MedicationId>) {
        const pricingAndSubsidies = await this.props._dependencies.referenceApiAdapter.getMedicationPricingAndSubsidiesAsync(medicationVersionSelector.id, medicationVersionSelector.validOn);
        this.setPricingAndSubsidies(pricingAndSubsidies.value);
        await this.pricingAndSubsidyLoader.loadReferenceDataAsync(pricingAndSubsidies.value, medicationVersionSelector.validOn);
    }

    @State.action.bound
    public setPricingAndSubsidies(pricingAndSubsidies: MedicationPricingAndSubsidies) {
        this.pricingAndSubsidies = pricingAndSubsidies;
    }

    @State.bound
    private async setMedicationAsync(value: MedicationId) {
        this.setPricingAndSubsidies(null);
        const medicationVersionSelector = new EntityVersionSelector<MedicationId>(value, LocalDate.today());
        if (this.props.item.detail instanceof FamilyDoctorDocumentSingleMedication) {
            if (!isNullOrUndefined(value)) {
                await this.loadMedicationPricingAndSubsidiesAsync(medicationVersionSelector);
                await this.props._dependencies.careReferenceDataStore.medications.ensureLoadedAsync([medicationVersionSelector]);
                await this.loadSubstancesAsync(medicationVersionSelector);
                const medication = this.props._dependencies.careReferenceDataStore.medications.get(medicationVersionSelector);
                if (!isNullOrUndefined(medication.dosageModeId?.value)) {
                    this.props.item.setMedicationDosageForm(medication.dosageModeId?.value);
                }

                this.props.item.detail.setMedicationVersionSelector(medicationVersionSelector);
            }
        }
    }
    @State.bound
    private setSubsidyAndOptionId(newValue: { subsidyId: MedicationSubsidyId, optionId: MedicationSubsidyOptionId }) {
        if (this.props.item.detail instanceof FamilyDoctorDocumentSingleMedication) {
            this.props.item.detail.setMedicationSubsidyId(newValue?.subsidyId);
            this.props.item.detail.setSubsidyOptionId(newValue?.optionId);
        }
    }
    @State.bound
    private familyDoctorDocumentMedicationFormulaListItemFactory() {
        return Promise.resolve(new FamilyDoctorDocumentMediactionFormulaItem());
    }

    public render() {
        return (
            <FamilyDoctorDocumentMedicationReferenceListItemView
                pricingAndSubsidies={this.pricingAndSubsidies}
                index={this.props.index}
                item={this.props.item}
                possibleSubsidyByPricingType={this.possibleSubsidyByPricingType}
                setMedicationAsync={this.setMedicationAsync}
                familyDoctorDocumentMedicationFormulaListItemFactory={this.familyDoctorDocumentMedicationFormulaListItemFactory}
                setSubsidyAndOptionId={this.setSubsidyAndOptionId}
            />
        );
    }
}
export default connect(
    FamilyDoctorDocumentMedicationReferenceListItem,
    new DependencyAdapter<IFamilyDoctorDocumentMedicationReferenceListItemProps, IFamilyDoctorDocumentMedicationReferenceListItemDependecies>(c => ({
        referenceDataStore: c.resolve("MedicationRequestReferenceDataStore"),
        pricingAndSubsidyLoader: c.resolve("PricingAndSubsidyLoader"),
        referenceApiAdapter: c.resolve("ReferenceDataApiAdapter"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore")
    }))
);