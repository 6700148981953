import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import UserGroupId from "@Primitives/UserGroupId.g";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import UserId from "@Primitives/UserId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class UserGroup extends EntityStoreBase<UserGroupId> implements IDirtyChecked {

    @State.observable public shortName: string;
    @State.observable public name: string;
    @State.observable public description: string;
    @State.observable public userIds: UserId[] = [];
    @State.observable public isVisible: boolean = true;
    @State.observable public displayName: string;

    constructor(id?: UserGroupId, shortName?: string, name?: string, description?: string, userIds?: UserId[], displayName?: string) {
        super();
        this.id = id;
        this.shortName = shortName;
        this.name = name;
        this.description = description;
        this.userIds = userIds ?? [];
        this.displayName = displayName;
    }

    @State.computed get numberOfUsers() {
        return this.userIds.length;
    }

    @State.action.bound
    public setShortName(newValue: string) {
        this.shortName = newValue;
    }

    @State.action.bound
    public setName(newValue: string) {
        this.name = newValue;
    }

    @State.action.bound
    public setDisplayName(newValue: string) {
        this.displayName = newValue;
    }

    @State.action.bound
    public setDescription(newValue: string) {
        this.description = newValue;
    }

    @State.action.bound
    public setUserIds(newValue: UserId[]) {
        this.userIds = newValue;
    }

    @State.action.bound
    public setIsVisible(newValue: boolean) {
        this.isVisible = newValue;
    }

    private _dirtyChecker = new StoreDirtyChecker();

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "operationInfo"
    ];

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }
}
