import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import IMedicalServiceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalServiceVersion";
import Di from "@Di";
import {CreateRequestId} from "@HisPlatform/Common/RequestHelper";
import {createOperationInfo} from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalServiceCategoryId from "@Primitives/MedicalServiceCategoryId.g";
import IMedicalServiceCategory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalServiceCategory";
import MedicalServiceCategorization from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServiceCategorization";
import MedicalServicePanelId from "@Primitives/MedicalServicePanelId.g";
import IMedicalServicePanel from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServices/IMedicalServicePanel";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import MedicalServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServiceStore";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import CareTypeId from "@Primitives/CareTypeId.g";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import IncludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierFilter";
import ExcludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierFilter";
import IncludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierSystemIdFilter";
import ExcludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierSystemIdFilter";
import CodeStartsWithFilter from "@Toolkit/CommonWeb/Model/Filtering/CodeStartsWithFilter";
import CodeDoesNotStartWithFilter from "@Toolkit/CommonWeb/Model/Filtering/CodeDoesNotStartWithFilter";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import ConditionSelectorQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/Conditions/Enum/ConditionSelectorQueryOrderingFields.g";

@Di.injectable()
export default class MedicalServiceApiAdapter extends ApiAdapterBase {

    constructor(@Di.inject("IMedicalServicesClient") private readonly apiClient: Proxy.IMedicalServicesClient) {
        super();
    }

    @State.bound
    public loadVersionsAsync(versionSelectors: Array<IEntityVersionSelector<MedicalServiceId>>): Promise<SimpleStore<IMedicalServiceVersion[]>> {
        return this.processOperationAsync(
            new SimpleStore<IMedicalServiceVersion[]>(),
            async target => {
                const response = await this.apiClient.getMedicalServiceVersionsBySelectorsQueryAsync(
                    CreateRequestId(),
                    this.getControllerDto(versionSelectors));

                target.operationInfo = createOperationInfo(response);
                target.value = response.medicalServices.map(m => {
                    return {
                        id: m.id,
                        code: new Identifier(m.code.identifierSystemId, m.code.value),
                        name: m.name,
                        alternativeName: m.alternativeName,
                        hasLaterality: m.hasLaterality,
                        allowedGender: m.allowedGender,
                        connectedServiceIds: m.connectedServices.map(s => s.id),
                        category: m.category ? new MedicalServiceCategorization(m.category.medicalServiceCategoryId, m.category.order) : null,
                        validity: m.validity,
                        description: m.description
                    } as IMedicalServiceVersion;
                });
            });
    }

    public searchMedicalServicesByCodeAsync(searchString: string, maxResultCount: number, careTypeId: CareTypeId, validOn?: LocalDate, excludedIds?: MedicalServiceId[], useAlternativeName?: boolean, filters?: FilterBase[]) {
        return this.processOperationAsync(
            new SimpleStore<MedicalServiceStore[]>(),
            async target => {
                const response = await this.apiClient.searchMedicalServicesQueryAsync(
                    CreateRequestId(),
                    new Proxy.SearchMedicalServicesControllerDto({
                        careTypeId: careTypeId,
                        value: isNullOrUndefined(searchString) ? "" : searchString,
                        validOn: validOn,
                        excludedIds: excludedIds,
                        maxResultCount: maxResultCount,
                        useAlternativeName: useAlternativeName || false,
                        filters: this.convertFiltersToProxyFilters(filters)
                    }));

                target.operationInfo = createOperationInfo(response);

                target.value = response.results.map(m => {
                    const service = new MedicalServiceStore(false);
                    service.id = m.id;
                    service.code = m.code;
                    service.name = m.name;
                    service.alternativeName = m.alternativeName;
                    return service;
                });
            }
        );
    }

    public getMedicalServicesAsync(filterText: string, ordering: IOrderingState, paging: IPagingState, careTypeId: CareTypeId, validOn?: LocalDate, excludedIds?: MedicalServiceId[], useAlternativeName?: boolean, filters?: FilterBase[]) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new PagedItemStore<MedicalServiceStore>([], 0),
            async target => {
                const response = await this.apiClient.getMedicalServicesQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetMedicalServicesControllerDto({
                        filterText: filterText,
                        validOn: validOn,
                        excludedIds: excludedIds,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfGetMedicalServicesQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfGetMedicalServicesQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: ConditionSelectorQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({pageIndex: paging.currentPage || 0, pageSize: paging.pageSize || 20})
                        }),
                        useAlternativeName: useAlternativeName || false,
                        careTypeId: careTypeId,
                        filters: this.convertFiltersToProxyFilters(filters)
                    }));

                target.operationInfo = createOperationInfo(response);
                target.items = response.results.values.map(m => {
                    const service = new MedicalServiceStore(false);
                    service.id = m.id;
                    service.code = m.code;
                    service.name = m.name;
                    service.alternativeName = m.alternativeName;
                    return service;
                });
                target.totalCount = response.results.totalCount;
            }
        );
    }

    public getMedicalServiceCategoriesByIdsAsync(categoryIds: MedicalServiceCategoryId[]) {
        return this.processOperationAsync(
            new SimpleStore<IMedicalServiceCategory[]>(),
            async target => {
                const dto = this.getMedicalServiceCategoriesControllerDtoFactory(categoryIds);
                const response = await this.apiClient.getMedicalServiceCategoriesQueryAsync(
                    CreateRequestId(),
                    dto,
                    false
                );
                target.operationInfo = createOperationInfo(response);
                target.value = response.categories.map(c => {
                    return {
                        id: c.id,
                        code: c.code,
                        name: c.name,
                        shortName: c.shortName,
                        order: c.order
                    } as IMedicalServiceCategory;
                });
            }
        );
    }

    public getMedicalServicePanelsByIdsAsync(medicalServicePanelIds: MedicalServicePanelId[]) {
        return this.processOperationAsync(
            new SimpleStore<IMedicalServicePanel[]>(),
            async target => {
                const dto = this.getMedicalServicePanelsControllerDtoFactory(medicalServicePanelIds);
                const response = await this.apiClient.getMedicalServicePanelsQueryAsync(
                    CreateRequestId(),
                    dto,
                    false
                );
                target.operationInfo = createOperationInfo(response);
                target.value = response.panels.map(p => {
                    return {
                        id: p.id,
                        name: p.name,
                        shortName: p.shortName,
                        code: p.code.value,
                        medicalServices: p.medicalServices,
                        isAtomic: p.isAtomic
                    } as IMedicalServicePanel;
                });
            }
        );
    }

    @State.bound
    public getAllIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<MedicalServiceId[]>(),
            async target => {
                const response = await this.apiClient.getAllMedicalServiceIdsQueryAsync(CreateRequestId());

                target.operationInfo = createOperationInfo(response);
                target.value = response && response.ids;
            }
        );
    }

    private convertFiltersToProxyFilters(filters: FilterBase[]): Proxy.FilterBase[] {
        if (!filters) {
            return [];
        }

        return filters.map((filter: FilterBase) => this.mapFilterToProxyFilter(filter));
    }

    private mapFilterToProxyFilter(filter: FilterBase): Proxy.FilterBase {
        if (filter instanceof IncludeIdentifierFilter) {
            return new Proxy.IncludeIdentifierFilter({
                value: new Identifier(
                    new IdentifierSystemId(filter.value.identifierSystemId.value),
                    filter.value.value
                )
            });
        } else if (filter instanceof ExcludeIdentifierFilter) {
            return new Proxy.ExcludeIdentifierFilter({
                value: new Identifier(
                    new IdentifierSystemId(filter.value.identifierSystemId.value),
                    filter.value.value
                )
            });
        } else if (filter instanceof IncludeIdentifierSystemIdFilter) {
            return new Proxy.IncludeIdentifierSystemIdFilter({
                value: filter.value
            });
        } else if (filter instanceof ExcludeIdentifierSystemIdFilter) {
            return new Proxy.ExcludeIdentifierSystemIdFilter({
                value: filter.value
            });
        } else if (filter instanceof CodeStartsWithFilter) {
            return new Proxy.CodeStartsWithFilter({
                value: filter.value
            });
        } else if (filter instanceof CodeDoesNotStartWithFilter) {
            return new Proxy.CodeDoesNotStartWithFilter({
                value: filter.value
            });
        } else if (filter instanceof ExplicitIdentifierFilter) {
            return new Proxy.ExplicitIdentifierFilter({
                type: filter.type,
                value: filter.value
            });
        } else {
            throw new Error(`Unsupported filter: ${typeof filter}`);
        }
    }

    private getControllerDto(versionSelectors: Array<IEntityVersionSelector<MedicalServiceId>>): Proxy.GetMedicalServiceVersionsBySelectorsControllerDto {
        const selectors = versionSelectors.map(v => new Proxy.EntityVersionSelectorOfMedicalServiceId(
            {entityId: v.id, validOn: v.validOn}));
        return new Proxy.GetMedicalServiceVersionsBySelectorsControllerDto({
            medicalServiceVersionSelectors: selectors
        });
    }

    private getMedicalServiceCategoriesControllerDtoFactory(categoryIds: MedicalServiceCategoryId[]) {
        const ids = categoryIds;
        return new Proxy.GetMedicalServiceCategoriesControllerDto({
            medicalServiceCategoryIds: ids
        });
    }

    private getMedicalServicePanelsControllerDtoFactory(panelIds: MedicalServicePanelId[]) {
        const ids = panelIds;
        return new Proxy.GetMedicalServicePanelsControllerDto({
            medicalServicePanelIds: ids
        });
    }
}
