import EntityProtectionLevel from "@Primitives/EntityProtectionLevel";
import AccessControlEntry from "./AccessControlEntry";
import _ from "@HisPlatform/Common/Lodash";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";

export default class AccessControlList {

    @State.bound
    public equals(other: AccessControlList): boolean {
        const levelEquals = other instanceof AccessControlList && this.entityProtectionLevel === other.entityProtectionLevel;
        if (!levelEquals) {
            return false;
        }

        const entriesAreEqual = other instanceof AccessControlList
            && this.entries.length === other.entries.length
            && this.entries.every(entry => {
                return other.entries.some(otherEntry => otherEntry.permission.value === entry.permission.value && otherEntry.subject.equals(entry.subject));
            });

        return levelEquals && entriesAreEqual;
    }

    @State.bound
    public isEmpty(): boolean {
        return this.entries.length === 0;
    }

    @State.observable.ref public entityProtectionLevel: EntityProtectionLevel = null;
    public entries: IObservableArray<AccessControlEntry> = State.observable([]);

    @State.action.bound
    public setEntityProtectionLevel(entityProtectionLevel: EntityProtectionLevel) {
        this.entityProtectionLevel = entityProtectionLevel;
    }

    @State.action.bound
    public setEntries(entries: AccessControlEntry[]) {
        this.entries.replace(entries);
    }

    @State.bound
    public hasEntries(objectPermissionId: string) {
        return this.entries.some(e => e.permission.value === objectPermissionId);
    }
}