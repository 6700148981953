import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import prescriptionStandaloneRouteDefinitions from "./PrescriptionRoutes";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import PrescriptionsStandaloneHost from "./PrescriptionsStandaloneHost";

export default UseCaseDescriptorBuilder.create(
    HunEHealthInfrastructureUseCases.prescriptions,
    builder => {
        builder.standalone(b => b
            .routeDefinition(prescriptionStandaloneRouteDefinitions.prescriptions)
            .component(PrescriptionsStandaloneHost)
        );
    }, false
);