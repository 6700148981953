import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import AppointmentScheduleSlotSeriesId from "@Primitives/AppointmentScheduleSlotSeriesId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import SlotStore from "./SlotStore";
import moment from "moment";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import AppointmentScheduleDefinitionId from "@Primitives/AppointmentScheduleDefinitionId.g";
import SlotStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/SlotStatus.g";

export default class AppointmentScheduleSlotSeries extends EntityStoreBase<AppointmentScheduleSlotSeriesId> {
    public locationParticipants: PointOfCareId[] = [];
    public practitionerParticipants: PractitionerId[] = [];
    public allowedSchedulingServices: SchedulingServiceId[] = [];
    public allowedServiceRequestDefinitions: ServiceRequestDefinitionId[] = [];
    public appointmentScheduleDefinitionId: AppointmentScheduleDefinitionId = null;
    
    @State.observable.ref public sortedSlots: SlotStore[] = [];

    public get slotDuration() {
        return this.sortedSlots.length ? moment.duration(this.sortedSlots[0].to.diff(this.sortedSlots[0].from)).asMinutes() : 0; 
    }

    public bookableSlotsForDate(start: moment.Moment, end: moment.Moment): SlotStore[] {
        let slotIndex = this.sortedSlots.findIndex(s => s.from.isSame(start) && s.status === SlotStatus.Free);
        const result: SlotStore[] = [];
        const eventDuration = moment.duration(end.diff(start)).asMinutes();

        if (slotIndex < 0) {
            return result;
        }

        result.push(this.sortedSlots[slotIndex]);

        if (eventDuration <= this.slotDuration) {
            return result;
        }

        const slotsToOccupy = Math.ceil(eventDuration / this.slotDuration);
        let canPlace = true;
        let lastSlotInterval = result[0].interval;
        while (canPlace && slotsToOccupy > result.length) {
            const nextSlot = this.sortedSlots[++slotIndex];
            if (!nextSlot) {
                canPlace = false;
                continue;
            }

            if (lastSlotInterval === nextSlot.interval) {
                continue;
            }

            if (nextSlot && nextSlot.from.isBefore(end) && nextSlot?.status === SlotStatus.Free) {
                result.push(this.sortedSlots[slotIndex]);
                lastSlotInterval = this.sortedSlots[slotIndex].interval;
            } else {
                if (nextSlot.interval === this.sortedSlots[slotIndex + 1]?.interval) {
                    continue;
                }
                canPlace = false;
            }
        }

        return canPlace ? result : [];
    }

    public canBookSlotsForDate(start: moment.Moment, end: moment.Moment): boolean {
        return !!this.bookableSlotsForDate(start, end).length;
    }

    public firstAvailableSlotsForDuration(start: moment.Moment, eventDuration: number): SlotStore[] {
        const result: SlotStore[] = [];
        const groupedSlots = _.groupBy(this.sortedSlots, a => a.interval);
        const uniqueSlots: SlotStore[] = [];
        Object.keys(groupedSlots).forEach(key => {
            const slotsForKey = groupedSlots[key];
            const firstFree = slotsForKey.find(s => s.status === SlotStatus.Free);
            if (firstFree) {
                uniqueSlots.push(firstFree);
            } else {
                uniqueSlots.push(slotsForKey[0]);
            }
        });
        let slotIndex = uniqueSlots.findIndex(s => s.from.isSameOrAfter(start) && s.status === SlotStatus.Free);

        if (slotIndex < 0) {
            return result;
        }
    
        const slotsToOccupy = !isNullOrUndefined(eventDuration) ? Math.ceil(eventDuration / this.slotDuration) : 1;
        
        let canPlace = false;
        while (!canPlace && slotIndex < uniqueSlots.length) {
            let isConsequtiveFree = true;
            for (let j = 0; j < slotsToOccupy && isConsequtiveFree; j++) {   
                isConsequtiveFree = (slotIndex + j) < uniqueSlots.length && uniqueSlots[slotIndex + j].status === SlotStatus.Free;
            }

            if (isConsequtiveFree) {
                canPlace = true;
                result.push(...uniqueSlots.slice(slotIndex, slotIndex + slotsToOccupy));
            }
            slotIndex++;
        }

        return canPlace ? result : [];
    }
}
