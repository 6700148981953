import _ from "@HisPlatform/Common/Lodash";
import ServerValidationProblemSeverity from "@Toolkit/CommonWeb/ApiAdapter/ServerValidationProblemSeverity";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import IClientCheckedRule from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientCheckedRule";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import { emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IServerValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerValidationResult";
import IServerValidationProblem from "@Toolkit/CommonWeb/ApiAdapter/IServerValidationProblem";

export function mapValidationResults(results: IServerCompositeValidationResult) {
    const res = results?.items?.map(mapSingleValidationResult).filter(Boolean) ?? emptyArray;
    return res;
}

export function mapSingleValidationResult(
    result: IServerValidationResult
) {
    return result && {
        entityName: result.subjectName,
        checkedRules: result.checkedRules?.map(cr => ({
            propertyPath: cr.entityPath.substring(result.subjectName.length + 1),
            ruleId: cr.ruleId
        } as IClientCheckedRule)) ?? [],
        entityId: result.subjectId,
        problems: result.problems.map(p => mapValidationProblem(p, result.subjectName))
    } || null as IClientValidationResult;
}

export function mapValidationProblem(p: IServerValidationProblem, subjectName: string): IClientValidationProblem {
    return ({
        message: `${p.entityPath}.${p.ruleId}`,
        propertyPath: p.entityPath.substring(subjectName.length + 1),
        ruleId: p.ruleId,
        severity: p.severity === ServerValidationProblemSeverity.Error ? "error" : "warning",
        parameters: p.parameters,
        resolvedParameters: null
    } as IClientValidationProblem);
}
