import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import configureCarePermissionDefinitions from "./CarePermissionDefinitionsConfiguration";
import configureDocumentPermissionDefinition from "./DocumentPermissionDefinitionConfiguration";
import configureProductivityPermissionDefinitions from "./ProductivityPermissionDefinitionsConfiguration";
import configureUserManagementPermissionDefinitions from "./UserManagementPermissionDefinitionsConfiguration";
import configureAuthorizationPermissionDefinitions from "./AuthorizationPermissionDefinitionsConfiguration";
import configureOrganizationPermissionDefinitions from "./OrganizationDefinitionConfiguration";
import configureFinancePermissionDefinitionsConfiguration from "./FinancePermissionDefinitionsConfiguration";
import configureLockingPermissionDefinitions from "./LockingPermissionDefinitionsConfiguration";
import configureCommonReferenceDataPermissionDefinitions from "./CommonReferenceDataPermissionDefinitionsConfiguration";
import configureLocalizationPermissionDefinitions from "./LocalizationPermissionDefinitionsConfiguration";
import configureReportingPermissionDefinitions from "./ReportingPermissionDefintionsConfiguration";
import configureConfigurationPermissionDefinitions from "./ConfigurationPermissionDefinitionsConfiguration";
import configureSchedulingPermissionDefinitions from "./SchedulingPermissionDefinitionsConfiguration";
import configureFormEnginePermissionDefinitions from "./FormEnginePermissionDefinitionsConfiguration";
import configureWebAppPermissionDefinitions from "./WebAppPermissionDefinitionsConfiguration";

export default function configureHisPlatformPermissionDeifinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry) {
    configureCarePermissionDefinitions(permissionDefinitionRegistry);
    configureProductivityPermissionDefinitions(permissionDefinitionRegistry);
    configureDocumentPermissionDefinition(permissionDefinitionRegistry);
    configureUserManagementPermissionDefinitions(permissionDefinitionRegistry);
    configureAuthorizationPermissionDefinitions(permissionDefinitionRegistry);
    configureOrganizationPermissionDefinitions(permissionDefinitionRegistry);
    configureFinancePermissionDefinitionsConfiguration(permissionDefinitionRegistry);
    configureLockingPermissionDefinitions(permissionDefinitionRegistry);
    configureLocalizationPermissionDefinitions(permissionDefinitionRegistry);
    configureCommonReferenceDataPermissionDefinitions(permissionDefinitionRegistry);
    configureReportingPermissionDefinitions(permissionDefinitionRegistry);
    configureConfigurationPermissionDefinitions(permissionDefinitionRegistry);
    configureSchedulingPermissionDefinitions(permissionDefinitionRegistry);
    configureFormEnginePermissionDefinitions(permissionDefinitionRegistry);
    configureWebAppPermissionDefinitions(permissionDefinitionRegistry);
}