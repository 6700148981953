import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";

export interface ITelemetrySessionMeasurementsDialogParams extends IModalParams {
    externalPatientId: string;
    url: string;
}
 
export interface ITelemetrySessionMeasurementsDialogResult {
    isCancelled: boolean;
}
 
export default class TelemetrySessionMeasurementsDialogParams implements ITelemetrySessionMeasurementsDialogParams {
 
    public static type = "TelemetrySessionMeasurementsDialog";
    public get type() { return TelemetrySessionMeasurementsDialogParams.type; }
 
    constructor(
        public readonly externalPatientId: string,
        public readonly url: string
    ) {}
}