import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { IModalComponentParams, IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { provideServiceRequestDefinitionDialogStore, useServiceRequestDefinitionDialogStore } from "./ServiceRequestDefinitionDialogStoreProvider";
import ClientSideValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidationBoundary";
import { RadioButtonGroupFactory } from "@CommonControls/RadioButtonGroup";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IServiceRequestDefinitionDialogResult from "./IServiceRequestDefinitionDialogResult";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";
import CareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CareActivityStore";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import ServiceRequestDefinitionSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ServiceRequestDefinitionSelectBox";

const displayValueFactory = (props: IServiceRequestDefinitionDialogProps) => (item: ServiceRequestDirection) => {
    return props._dependencies.localizationService.localizeEnum(ServiceRequestDirection[item], "ServiceRequestDirection").Name;
};

interface IServiceRequestDefinitionDialogDependencies {
    localizationService: ILocalizationService;
    notificationService: INotificationService;
}

export interface IServiceRequestDefinitionDialogProps extends IModalComponentParams<IServiceRequestDefinitionDialogResult>, IModalParams {
    _dependencies?: IServiceRequestDefinitionDialogDependencies;
    _careActivity?: CareActivityStore;
}

function ServiceRequestDefinitionDialog(props: IServiceRequestDefinitionDialogProps) {
    const store = useServiceRequestDefinitionDialogStore();
    const ServiceRequestDefinitionDirectionRadioButtonGroup = RadioButtonGroupFactory<ServiceRequestDirection>();

    return (
        <HisUi.ContextAwareModal
            automationId="__serviceRequestDefinitionSelectorModal"
            title={StaticCareResources.ServiceRequestManagement.ServiceRequestDefinitionDialog.SelectServiceRequestDefinitionField}
            closeButton
            isOpen
            onClose={() => props.onClose(null)}
            size="compact"
            closeOnOutsideClick={false}
            closeOnEscape={false}>
            <Ui.Modal.Body>
                <ClientSideValidationBoundary validator={store.clientSideValidator} entityTypeName="CreateServiceRequest">
                    {StaticCareResources.ServiceRequestManagement.ServiceRequestDefinitionDialog.ServiceRequestDefinitionDirectionField}
                    
                    <ServiceRequestDefinitionDirectionRadioButtonGroup
                        direction="row"
                        displayType="groupedButtons"
                        items={[
                            ServiceRequestDirection.InternalToInternal,
                            ServiceRequestDirection.InternalToExternal,
                            ServiceRequestDirection.ExternalToInternal]}
                        value={store.serviceRequestDefinitionDirection}
                        onChange={store.setDefinitionDirection}
                        getDisplayValue={displayValueFactory(props)}
                        automationId="__serviceRequestDirection" />

                    <br />

                    <ServiceRequestDefinitionSelectBox
                        value={store.definitionId}
                        onChange={store.setDefinitionId}
                        direction={store.serviceRequestDefinitionDirection}
                        required
                        label={StaticCareResources.ServiceRequestManagement.ServiceRequestDefinitionDialog.ServiceRequestDefinitionField}
                        automationId="__serviceRequestDefinition"
                        propertyIdentifier="ServiceRequest.ServiceRequestDefinitionId"
                    />
                    <br />
                </ClientSideValidationBoundary>
            </Ui.Modal.Body>
            <Ui.Modal.Footer>
            <Ui.Flex xsJustify="between">
                <Ui.Flex.Item>
                    <Ui.Button
                        visualStyle="link"
                        text={StaticCareResources.ServiceRequestManagement.ServiceRequestDefinitionDialog.CancelField}
                        onClick={() => props.onClose(null)}
                        automationId="__cancelRequestCreation"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item>
                    <Ui.Button
                        visualStyle="primary"
                        text={StaticCareResources.ServiceRequestManagement.ServiceRequestDefinitionDialog.CreateField}
                        onClick={store.createServiceRequest}
                        automationId="__createRequest"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
            </Ui.Modal.Footer>
        </HisUi.ContextAwareModal>
    );
}

export default connect<IServiceRequestDefinitionDialogProps>(
    provideServiceRequestDefinitionDialogStore(ServiceRequestDefinitionDialog),
    new DependencyAdapter<IServiceRequestDefinitionDialogProps, IServiceRequestDefinitionDialogDependencies>((container) => ({
        localizationService: container.resolve("ILocalizationService"),
        notificationService: container.resolve("INotificationService")
    })),
    new CareActivityContextAdapter<IServiceRequestDefinitionDialogProps>(ctx => ({
        _careActivity: ctx.careActivity
    }))
);