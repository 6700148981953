import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareActivityEReferralStore from "@HunEHealthInfrastructurePlugin/Model/CareActivityEReferralStore";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import _ from "@HisPlatform/Common/Lodash";
import EhiEReferralId from "@Primitives/EhiEReferralId.g";
import { isNullOrUndefined, arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import PractitionerId from "@Primitives/PractitionerId.g";
import EhiEReferralDetailDialogParams from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralDetailDialog/EhiEReferralDetailDialogParams";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import DataGridDateColumn from "@CommonControls/DataGrid/Column/DataGridDateColumn";
import EhiErrorSummary from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiErrorSummary";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import CareActivityEhiEReferralPanelListButtons from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/CareActivityEhiEReferralPanel/CareActivityEhiEReferralPanelListButtons";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import EhiEReferralStatusColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralStatusColumn/EhiEReferralStatusColumn";
import EhiEReferralLocationColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralLocationColumn/EhiEReferralLocationColumn";
import EhiEReferralServiceColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralServiceColumn/EhiEReferralServiceColumn";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import EhiEReferralPractitionerColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralPractitionerColumn/EhiEReferralPractitionerColumn";
import EhiCareServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareServiceFactory";
import EhiCareService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareService";
import { dataGridDefaultProps } from "@CommonControls/DataGrid/DataGrid";
import PatientId from "@Primitives/PatientId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";

interface ICareActivityEhiEReferralPanelCoreDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    careApiAdapter: EhiCareApiAdapter;
    schedulingApiAdapter: SchedulingApiAdapter;
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    ehiCareServiceFactory: EhiCareServiceFactory;
}

interface ICareActivityEhiEReferralPanelCoreProps {
    _dependencies?: ICareActivityEhiEReferralPanelCoreDependencies;
    _modalService?: IModalService;
    onSelectEReferral: (value: CareActivityEReferralStore) => void;
    selectedEhiEReferralId: EhiEReferralId;

    isMutable: boolean;
    careActivityId: CareActivityId;
    patientId: PatientId;
    pointOfCareId?: PointOfCareId;
    initialServiceRequestId?: ServiceRequestId;
    serviceRequestIdForExecutingCareActivity?: ServiceRequestId;
        
    careActivityIsNew: boolean;
    showOnlySelectedEReferral?: boolean;
    
}

/** @screen */
@State.observer
class CareActivityEhiEReferralPanelCore extends React.Component<ICareActivityEhiEReferralPanelCoreProps> {

    @State.observable private isLoading: boolean = false;
    @State.observable.ref private hideAllEReferrals: boolean = true;

    @State.observable.ref private eReferrals: CareActivityEReferralStore[];
    @State.observable.ref private selectedEReferralStore: CareActivityEReferralStore = null;
    @State.observable.ref private ehiErrors: EhiError[] = [];

    @State.observable.ref private dataSource = new InMemoryDataGridDataSource(() => this.referralItems || []);

    @State.observable.ref private hasLinkedEReferralFromServiceRequest: boolean = false;

    private disposers: IDisposable[] = [];
    private ehiCareService: EhiCareService = null;

    private get dependencies() {
        return this.props._dependencies;
    }

    private get modalService() {
        return this.props._modalService;
    }

    @State.computed
    private get referralItems() {
        if (!!this.selectedEReferralStore && ((!this.selectedEReferralStore?.isNew && this.hideAllEReferrals) || this.props.showOnlySelectedEReferral)) {
            return [this.selectedEReferralStore];
        }
        return this.eReferrals;
    }

    @State.action.bound
    private setHideAllEReferrals(newValue: boolean) {
        this.hideAllEReferrals = newValue;
    }

    @State.action.bound
    private setEhiErrors(newValue: EhiError[]) {
        this.ehiErrors = newValue;
    }

    constructor(props: ICareActivityEhiEReferralPanelCoreProps) {
        super(props);
        this.dataSource.paging.pageSize = 5;
    }

    private readonly initializeAsync = createInitialPanelLoader(this._initializeAsync);

    public componentDidMount(): void {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }

    public componentDidUpdate(prevProps: ICareActivityEhiEReferralPanelCoreProps) {
        if (this.props.pointOfCareId) {
            if (
                !ValueWrapper.equals(this.props.patientId, prevProps.patientId) ||
                !ValueWrapper.equals(this.props.pointOfCareId, prevProps.pointOfCareId) ||
                (!this.props.careActivityIsNew && !ValueWrapper.equals(this.props.careActivityId, prevProps.careActivityId))                 
            ) {
                this.dispose();
                dispatchAsyncErrors(this.reloadAsync(), this);
            }
        }
    }

    public componentWillUnmount() {
        this.dispose();
    }

    private dispose() {
        this.disposers.forEach(d => d.dispose());
    }

    @State.bound
    private async reloadAsync() {
        await this.initializeStoresAsync();
    }

    @State.bound
    private async _initializeAsync() {
        await Promise.all([
            this.dependencies.hunEhiCareReferenceDataStore.ehiEReferralServiceTypes.ensureAllLoadedAsync(),
            this.dependencies.hunEhiCareReferenceDataStore.ehiEReferralStatuses.ensureLoadedAsync()
        ]);

        this.ehiCareService = this.props._dependencies.ehiCareServiceFactory.getEhiCareService();
        await this.initializeStoresAsync();
    }

    @State.boundLoadingState()
    private async initializeStoresAsync() {
        if (this.props.pointOfCareId) {
            let eReferrals: CareActivityEReferralStore[] = [];
            let referralDoctorIds: PractitionerId[] = [];
            let referralLocationIds: Array<EntityVersionSelector<ExternalLocationId>> = [];

            State.runInAction(() => {
                this.isLoading = true;
            });

            const ehiServiceCallResult = await this.ehiCareService?.getEReferralsForPatientAsync(
                this.props.patientId,
                true,
                null,
                this.props.pointOfCareId);

            this.setEhiErrors(ehiServiceCallResult?.result?.value?.errors);

            ehiServiceCallResult?.result?.value?.value?.forEach(i => {
                eReferrals.push(i);

                if (!isNullOrUndefined(i.referralDoctor)) {
                    referralDoctorIds.push(i.referralDoctor);
                }
                if (!isNullOrUndefined(i.referralLocation)) {
                    referralLocationIds.push(new EntityVersionSelector<ExternalLocationId>(i.referralLocation, i.validAt));
                }
            });

            if (!!this.selectedEReferralStore && !this.selectedEReferralStore.isNew) {
                if (!eReferrals.some(i => ValueWrapper.equals(i.ehiEReferralId, this.selectedEReferralStore.ehiEReferralId))) {
                    eReferrals = [...eReferrals, this.selectedEReferralStore];
                } else {
                    eReferrals = [...eReferrals.map(
                        i => ValueWrapper.equals(i.ehiEReferralId, this.selectedEReferralStore.ehiEReferralId)
                            ? this.selectedEReferralStore : i)];
                }

                if (!isNullOrUndefined(this.selectedEReferralStore.referralDoctor)) {
                    referralDoctorIds.push(this.selectedEReferralStore.referralDoctor);
                }
                if (!isNullOrUndefined(this.selectedEReferralStore.referralLocation)) {
                    referralLocationIds.push(new EntityVersionSelector<ExternalLocationId>(this.selectedEReferralStore.referralLocation, this.selectedEReferralStore.validAt));
                }
            }

            referralDoctorIds = _.uniqBy(referralDoctorIds, id => id.value);
            referralLocationIds = _.uniqBy(referralLocationIds, id => id.getHashCode());

            if (referralDoctorIds?.length > 0) {
                await this.dependencies.organizationReferenceDataStore.doctorMap.ensureLoadedAsync(referralDoctorIds);
            }
            if (referralLocationIds?.length > 0) {
                await this.dependencies.organizationReferenceDataStore.externalLocationStore.ensureLoadedAsync(referralLocationIds);
            }

            State.runInAction(() => {
                this.eReferrals = _.sortBy(eReferrals, item => item.createdAt);
                this.isLoading = false;
            });            
        }
    }   

    @State.action.bound
    private onShowAllReferrals() {
        this.setHideAllEReferrals(false);
    }    

    @State.action.bound
    private showDetail(eReferral: CareActivityEReferralStore) {
        this.modalService.showModal(new EhiEReferralDetailDialogParams(eReferral));
    }

    public render() {
        const resources = StaticHunEHealthInfrastructureCareResources.CareActivityEhiEReferral;

        const showGrid = this.isLoading || !arrayIsNullOrEmpty(this.referralItems);
        const showPanel = showGrid || !arrayIsNullOrEmpty(this.ehiErrors);
        const showMoreButton = !!this.selectedEReferralStore && this.hideAllEReferrals && this.eReferrals?.length > 1 && !this.props.showOnlySelectedEReferral;
        const hidePager = (!!this.selectedEReferralStore && this.hideAllEReferrals && this.eReferrals?.length > 1) || this.props.showOnlySelectedEReferral;

        const titleWithInfoTooltip = (
            <>
                {resources.Panel.Title + " "}
                <Ui.InfoButton position="baseline"
                    tooltipContent={resources.Panel.TitleHint} />
            </>
        );

        if (this.props.showOnlySelectedEReferral && !this.selectedEReferralStore && arrayIsNullOrEmpty(this.ehiErrors)) {
            return null;
        }

        return (this.initializeAsync.isUnauthorizedAccess
            ? (
                <>
                    <Ui.GroupBox title={titleWithInfoTooltip} style={{ padding: "0px 4px" }}>
                        <UnauthorizedAccessContent />
                    </Ui.GroupBox>
                </>
            ) : (showPanel && (
                <>
                    <Ui.GroupBox title={titleWithInfoTooltip} style={{ padding: "0px 4px" }}>
                        <EhiErrorSummary
                            errors={this.ehiErrors}
                        />
                        {showGrid &&
                            <ReadOnlyContext.Provider value={!this.props.isMutable || this.hasLinkedEReferralFromServiceRequest || this.props.showOnlySelectedEReferral}>
                                <Ui.DataGrid
                                    dataSource={this.dataSource}
                                    selectedRow={this.selectedEReferralStore}
                                    isSelectable
                                    rowHeight="40px"
                                    isLoading={this.isLoading}
                                    hidePager={hidePager}
                                    availablePageSizes={[...dataGridDefaultProps.availablePageSizes, 5]}
                                    fixedLayout
                                    footer={this.props.showOnlySelectedEReferral ? null : undefined}
                                    automationId="careActivityEhiReferralPanel_dataGrid"
                                >
                                    <EhiEReferralStatusColumn
                                        id="Status"
                                        header={resources.List.Status}
                                        width="10%"
                                        displayMode="name"
                                    />
                                    <EhiEReferralLocationColumn
                                        id="Location"
                                        header={resources.List.Location}
                                        width="25%"
                                    />
                                    <EhiEReferralServiceColumn
                                        header={resources.List.Service}
                                        id="Service"
                                        width="25%"
                                    />
                                    <DataGridDateColumn
                                        header={resources.List.CreationDate}
                                        dataGetter={"createdAt"}
                                        id="CreationDate"
                                        width="10%"
                                    />
                                    <EhiEReferralPractitionerColumn
                                        header={resources.List.Practitioner}
                                        id="Practitioner"
                                        width="30%"
                                    />
                                    <DataGridColumn
                                        id="Buttons"
                                        width="60px"
                                        cellTextAlign="center"
                                    >
                                        <CareActivityEhiEReferralPanelListButtons
                                            onSelectEReferral={this.props.onSelectEReferral}
                                            onShowDetail={this.showDetail}
                                            selectedEhiEReferralId={this.props.selectedEhiEReferralId}
                                        />
                                    </DataGridColumn>
                                </Ui.DataGrid>
                            </ReadOnlyContext.Provider>}
                        {
                            showMoreButton &&
                            (
                                <ReadOnlyContext.Provider value={false}>
                                    <Ui.Button
                                        text={resources.Panel.ShowAllReferrals}
                                        visualStyle="link"
                                        onClick={this.onShowAllReferrals}
                                        automationId="showReferralsButton"
                                    />
                                </ReadOnlyContext.Provider>
                            )
                        }
                    </Ui.GroupBox>
                </>
            ))
        );
    }
}

export default connect(
    CareActivityEhiEReferralPanelCore,
    new DependencyAdapter<ICareActivityEhiEReferralPanelCoreProps, ICareActivityEhiEReferralPanelCoreDependencies>((container) => {
        return {
            organizationReferenceDataStore: container.resolve<OrganizationReferenceDataStore>("OrganizationReferenceDataStore"),
            hunEhiCareReferenceDataStore: container.resolve<HunEhiCareReferenceDataStore>("HunEhiCareReferenceDataStore"),
            careApiAdapter: container.resolve<EhiCareApiAdapter>("EhiCareApiAdapter"),
            schedulingApiAdapter: container.resolve<SchedulingApiAdapter>("SchedulingApiAdapter"),
            ehiCareServiceFactory: container.resolve<EhiCareServiceFactory>("EhiCareServiceFactory"),
        };
    }),
    new HisModalServiceAdapter<ICareActivityEhiEReferralPanelCoreProps>()
);
