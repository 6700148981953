import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientId from "@Primitives/PatientId.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { parseUseCaseStateFromUrlParam, getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import PatientRoutes from "@HisPlatform/Components/Pages/Patient/PatientRoutes";
import IProcedureStatementRouteParams from "@HisPlatform/Application/Routes/IProcedureStatementRouteParams";
import ProcedureStatementId from "@Primitives/ProcedureStatementId.g";
import ProcedureStatementListPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/ProcedureStatement/ProcedureStatementListPanel";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";


interface IProcedureStatementPageProps extends IRoutingFrameContentProps {
}

@State.observer
class ProcedureStatementPage extends React.Component<IProcedureStatementPageProps> {

    @State.computed private get routeParams(): IProcedureStatementRouteParams {
        return this.props.routingController.currentRoute.parameters as IProcedureStatementRouteParams;
    }

    @State.computed private get selectedProcedureStatementId(): ProcedureStatementId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments[0].value;
        }

        if (this.useCaseState?.useCase.value === UseCases.procedureStatementDetail) {
            return new ProcedureStatementId("new");
        }

        return null;
    }

    @State.computed
    private get patientId() {
        return new PatientId(this.routeParams?.patientId);
    }

    @State.computed private get useCaseState() {
        const useCaseState = this.routeParams?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.bound
    private setUseCaseState(selectedProcedureStatementId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.replace(
            PatientRoutes.procedureStatement.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            })
        );
    }

    @State.bound
    private navigateToProcedureStatement(procedureStatementId: ProcedureStatementId) {
        this.props.routingController.replace(
            PatientRoutes.procedureStatement.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.procedureStatementDetail),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(procedureStatementId, "procedureStatementId")])
            })
        );
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(
            PatientRoutes.procedureStatement.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    null,
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument(new ProcedureStatementId("null"), "procedureStatementId")])
            })
        );
    }

    public render() {
        
        return (
            <LoadingBoundary>
                <ProcedureStatementListPanel
                    _patientId={this.patientId}
                    onChange={this.setUseCaseState}
                    useCaseState={this.useCaseState}
                    selectedRowId={this.selectedProcedureStatementId?.value}
                    onSelectedProcedureStatementIdChange={this.navigateToProcedureStatement}
                    onBack={this.goBack}
                    onCloseDetail={this.setClosedState}
                />
            </LoadingBoundary>
        );
     }
}

export default connect(
    ProcedureStatementPage
);
