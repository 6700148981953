import React, { useMemo } from "react";
import * as Ui from "@CommonControls";
import Styles from "./OutpatientHeader.less";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import moment from "moment";
import CareActivityMenu, { ICareActivityMenuProps } from "@HisPlatformControls/OutpatientHeader/CareActivityMenu";
import PractitionerId from "@Primitives/PractitionerId.g";
import CareActivityStateIcon from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/CareActivityStateIcon/CareActivityStateIcon";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import ITelecommunicationCallControlExtensionPointProps from "@PluginInterface/BoundedContexts/Telecommunication/ITelecommunicationCallControlExtensionPointProps";
import CareActivityId from "@Primitives/CareActivityId.g";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";

export interface ICareActivityPaneDependencies {
    localizationService: ILocalizationService;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

export interface ICareActivityPaneProps {
    _dependencies?: ICareActivityPaneDependencies;

    careActivityState: CareActivityState;
    pointOfCareId: PointOfCareId;
    careIdentifier: string;
    wentUnderCareAt: moment.Moment;
    dischargedAt: moment.Moment;
    doctorId: PractitionerId;

    showCareMenu: boolean;
    showCareDetails: boolean;

    menuProps: ICareActivityMenuProps;
    careActivityId: CareActivityId;
}


const CareActivityPane: React.SFC<ICareActivityPaneProps> = props => {

    const wentUnderCareAt = useMemo(() => props._dependencies.localizationService.localizeDateTime(props.wentUnderCareAt), [props.wentUnderCareAt]);
    const dischargedAt = useMemo(() => props._dependencies.localizationService.localizeDateTime(props.dischargedAt), [props.dischargedAt]);

    let pointOfCareShorthand = "";

    if (props.pointOfCareId) {
        const pointOfCare = props._dependencies.organizationReferenceDataStore.allPointsOfCareMap.get(props.pointOfCareId);
        if (pointOfCare) {
            pointOfCareShorthand = pointOfCare.shorthand;
        }
    }

    const doctor = props.doctorId ? props._dependencies.organizationReferenceDataStore.doctorMap.get(props.doctorId) : null;

    const telecommunicationCallControlExtensionPointProps: ITelecommunicationCallControlExtensionPointProps = {
        careActivityId: props.careActivityId
    };

    return (
        <>
            <div className={Styles.columnWithSeparator}>
                <Ui.Icon className={Styles.pointOfCareIcon} iconName="pin" size="compact" visualStyle="white" />
                <Ui.Icon className={Styles.hashmarkIcon} iconName="hashtag" size="compact" visualStyle="white" />
            </div>
            <div className={Styles.column}>
                <div className={Styles.textBold}>
                    <span>&nbsp;{pointOfCareShorthand}</span>
                </div>
                <div className={Styles.bottomRow}>
                    <span>&nbsp;{props.careIdentifier}</span>
                </div>
            </div>
            {
                props.showCareDetails &&
                <div className={Styles.columnWithSeparator}>
                    {doctor && <div>
                        <span>{doctor.code}</span>&nbsp;
                        <Ui.PersonNameLabel personName={doctor.name}
                            className={Styles.textBold} />
                    </div>}
                    <div className={doctor && Styles.bottomRow}>
                        {wentUnderCareAt} - {dischargedAt}
                    </div>
                </div>
            }
            {props.showCareDetails &&
                <div className={Styles.statusIcon}>
                    <CareActivityStateIcon state={props.careActivityState} hasBorder />
                </div>
            }
            {
                props.showCareMenu &&
                <div className={combineClasses(Styles.careMenu)}>
                    <CareActivityMenu {...props.menuProps} />
                </div>
            }
            {
                <div className={Styles.columnWithSeparator}>
                    <HisPlatformExtensionPoint
                        extensionProps={telecommunicationCallControlExtensionPointProps}
                        type="telecommunicationCallControlExtension">
                        <></>
                    </HisPlatformExtensionPoint>
                </div>
            }
        </>
    );
};

export default connect(
    CareActivityPane,
    new DependencyAdapter<ICareActivityPaneProps, ICareActivityPaneDependencies>(container => {
        return {
            localizationService: container.resolve("ILocalizationService"),
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore")
        };
    })
);
