import _ from "@HisPlatform/Common/Lodash";
import AllergyIntoleranceAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/AllergyIntoleranceAssignmentBase";
import AllergyIntoleranceCategoryId from "@Primitives/AllergyIntoleranceCategoryId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import AllergyIntoleranceType from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/AllergiesAndIntolerances/Enum/AllergyIntoleranceType.g";

export default class CustomAllergyIntoleranceAssignment extends AllergyIntoleranceAssignmentBase {

    @State.observable.ref public name: string = "";    
    @State.observable.ref public description: string = "";
    @State.observable.ref public type: AllergyIntoleranceType = AllergyIntoleranceType.Allergy;
    @State.observable.ref public categoryId: AllergyIntoleranceCategoryId = null;

    @State.action.bound
    public setName(newValue: string) {
        this.name = newValue;
    }

    @State.action.bound
    public setDescription(newValue: string) {
        this.description = newValue;
    }

    @State.action.bound
    public setType(newValue: AllergyIntoleranceType) {
        this.type = newValue;
    }

    @State.action.bound
    public setCategoryId(newValue: AllergyIntoleranceCategoryId) {
        this.categoryId = newValue;
    }
}