import * as LocalizationProxy from "@HisPlatform/BoundedContexts/Localization/Api/Proxy.g";
import * as CareProxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import Di from "@Di";
import moment from "moment";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import {formatString} from "@Toolkit/CommonWeb/Formatters";
import {CreateRequestId} from "@HisPlatform/Common/RequestHelper";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ILocalizedObject from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalization";
import IResourceFetcher from "@Toolkit/CommonWeb/Abstractions/Localization/IResourceFetcher";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ResourceId from "@Primitives/ResourceId.g";
import Age from "@Toolkit/CommonWeb/Model/Age";
import Money from "@Toolkit/CommonWeb/Model/Money";
import _ from "@HisPlatform/Common/Lodash";
import {isNullOrUndefined, isNullOrEmptyString} from "@Toolkit/CommonWeb/NullCheckHelpers";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import { objectToMap } from "@HisPlatform/Utils/ObjectToMap";
import { money } from "@CommonControls/Icon/SvgImports";

@Di.injectable()
export default class LocalizationService implements ILocalizationService, IResourceFetcher {

    private clientResourceGroupId: string = "WebApp";
    private referenceDataResourceGroupId: string = "ReferenceData";
    private validationMessagesResourceGroupId: string = "ValidationMessages";
    private dynamicPropertyResourceGroupId: string = "DynamicProperty";
    private invalidDate: string = "n/a";

    private cachedResourceGroups = new Map<string, Map<string, string>>();
    private cachedResourceGroups2 = new Map<string, object>();

    constructor(
        @Di.inject("ICurrentCultureProvider") public readonly currentCultureProvider: ICurrentCultureProvider,
        @Di.inject("ILocalizationClient") private localizationClient: LocalizationProxy.ILocalizationClient,
        @Di.inject("IDateTimeFormatProvider") private dateTimeFormatProvider: IDateTimeFormatProvider
    ) {
        this.currentCultureProvider.onCultureCodeChanged.on(() => {
            this.invalidateCache();
        });
    }

    public async fetchResourceGroupAsync(resourceGroupId: string, targetResourceGroupId: string, cultureCode: string): Promise<any> {
        const response = await this.localizationClient.getResourceGroupQueryAsync(CreateRequestId(), cultureCode, resourceGroupId);
        const localizedResourceGroup = objectToMap(response.localizedTexts);

        if (!this.cachedResourceGroups.has(targetResourceGroupId)) {
            this.cachedResourceGroups.set(targetResourceGroupId, localizedResourceGroup);

            if (targetResourceGroupId === this.clientResourceGroupId) {
                const newDict = {};
                this.fillDictionary(newDict, response.localizedTexts);
                this.cachedResourceGroups2.set(targetResourceGroupId, newDict);
            }

        } else {
            const group = this.cachedResourceGroups.get(targetResourceGroupId);
            localizedResourceGroup.forEach((value, key) => {
                group.set(key, value);
            });

            if (targetResourceGroupId === this.clientResourceGroupId) {
                const dict = this.cachedResourceGroups2.get(targetResourceGroupId);
                this.fillDictionary(dict, response.localizedTexts);
            }
        }
    }

    private fillDictionary(target: object, resources: { [key: string]: string }) {
        _.forIn(resources, (value: string, key: string) => {
            this.createNestedObject(target, key.split("."), value);
        });
    }

    private createNestedObject(base: object, names: string[], value: string) {
        const lastName = !isNullOrUndefined(value) ? names.pop() : false;
        let current: any = base;

        
        for (let i = 0; i < names.length; i++) {
            current = current[names[i]] = current[names[i]] || {};
        }

        if (lastName) { 
            current = current[lastName] = value; 
        }
        return current;
    }

    public getResourceGroup(groupKey: string) {
        return this.cachedResourceGroups2.get(groupKey);
    }

    public localizeWebAppResource(resourceKey: string): string {
        const ret = this.getResource(this.clientResourceGroupId + "." + resourceKey);
        if (ret) {
            return ret;
        }
        return resourceKey;
    }

    public localizeValidationMessage(resourceId: { value: string }): string {
        let resKey = this.removeArrayIndex(resourceId.value);

        const paramStart = resKey.indexOf("[");
        resKey = (paramStart > -1) ? resKey.slice(0, paramStart) : resKey;
        const isGroupSpecified = resKey[0] === "!";

        const resourceKey = isGroupSpecified ? resKey.substr(1) : resKey;

        return this.getResource(isGroupSpecified ? resourceKey : `${this.validationMessagesResourceGroupId}.${resourceKey}`);
    }

    private removeArrayIndex(resourceKey: string): string {
        return resourceKey.replace(/\[\d+\]\./g, ".");
    }

    @State.bound
    public localizeEnum(enumValueName: string, typeName: string): ILocalizedObject {
        if (enumValueName == null || typeName == null) {
            return null;
        }

        const resourceKey = typeName + "." + enumValueName;
        const shortHandResourceKey = resourceKey + ".Shorthand";
        const name = this.getResource(this.referenceDataResourceGroupId + "." + resourceKey);
        const shorthand = this.getResource(this.referenceDataResourceGroupId + "." + shortHandResourceKey) || "";
        return {
            Name: name,
            Shorthand: shorthand,
            getShorthandAndName: (separator: string = " - ") => `${shorthand}${separator}${name}`
        };
    }

    @State.bound
    public localizeClientEnum(enumValueName: string, typeName: string, boundedContextName: string = ""): ILocalizedObject {
        if (enumValueName == null || typeName == null) {
            return null;
        }

        const resourceKey = [boundedContextName, "Enum", typeName, enumValueName].filter(i => !isNullOrEmptyString(i)).join(".");
        const shortHandResourceKey = resourceKey + ".Shorthand";
        const name = this.getResource(this.clientResourceGroupId + "." + resourceKey);
        const shorthand = this.getResource(this.clientResourceGroupId + "." + shortHandResourceKey) || "";
        return {
            Name: name,
            Shorthand: shorthand,
            getShorthandAndName: (separator: string = " - ") => `${shorthand}${separator}${name}`
        };
    }

    @State.bound
    public localizeEnumId(enumId: IStringEntityId): ILocalizedObject {
        if (!enumId) {
            return null;
        }

        if (enumId.constructor && (enumId.constructor as any).entityName) {
            return this.localizeEnum(enumId.value, (enumId.constructor as any).entityName);
        }

        throw new Error("ILocalizationService.localizeEnumId works only for extensible enum ids.");
    }

    public localizeReferenceData(resourceId: ResourceId): string {
        if (resourceId === null) {
            return null;
        }
        return this.getResource(this.referenceDataResourceGroupId + "." + resourceId.value);
    }

    public localizePersonName(personName: CareProxy.IPersonName, withoutLocalization?: boolean, useNbsp?: boolean): string {
        if (isNullOrUndefined(personName)) {
            return null;
        }

        if (personName.familyName === null || personName.givenName1 === null) {
            return "n/a";
        }

        let nameParts: string[] = [];

        if (this.currentCultureProvider.cultureCode === "hu-HU" || withoutLocalization) {
            nameParts = [
                personName.prefix,
                personName.familyName,
                personName.givenName1,
                personName.givenName2,
                personName.postfix
            ];
        } else {
            nameParts = [
                personName.prefix,
                personName.givenName1,
                personName.givenName2,
                personName.familyName,
                personName.postfix
            ];
        }
        return nameParts.filter((np: string) => np && np !== "").join(useNbsp ? String.fromCharCode(160) : " ");
    }

    public localizeDateTime(dateTime: moment.Moment, showSeconds: boolean = false, useNbsp?: boolean, useTodayString?: boolean): string {
        if (dateTime === null || !moment.isMoment(dateTime) || !dateTime.isValid()) {
            return this.invalidDate;
        }

        let result = dateTime.format(
            showSeconds ? this.dateTimeFormatProvider.getDateTimeFormat() : this.dateTimeFormatProvider.getDateTimeWithoutSecondsFormat()
        );

        if (useNbsp) {
            result = result.replace(/ /g, String.fromCharCode(160));
        }

        if (useTodayString) {
            result = result.replace(DateTimeService.now().format(this.dateTimeFormatProvider.getDateFormat()), this.dateTimeFormatProvider.getTodayString());
        }

        return result;
    }

    public localizeDate(date: LocalDate): string {
        if (date === null || date === undefined) {
            return this.invalidDate;
        }

        const dateValue = date.toUtcDayStartMoment();

        if (dateValue && dateValue.isValid()) {
            return dateValue.format(this.dateTimeFormatProvider.getDateFormat());
        } else {
            return this.invalidDate;
        }
    }

    public invalidateCache() {
        this.cachedResourceGroups = new Map<string, Map<string, string>>();
    }

    public localizeBoolean(value: boolean): string {
        if (isNullOrUndefined(value)) {
            return null;
        }

        switch (this.currentCultureProvider.cultureCode) {
            case "hu-HU":
                return value ? "Igen" : "Nem";
            case "en-US":
                return value ? "Yes" : "No";
            case "sk-SK":
                return value ? "Áno" : "Nie";
            case "cs-CZ":
                return value ? "Ano" : "Ne";
            default:
                return value ? "Yes" : "No";
        }

    }

    public localizeMoney(value: Money): string {
        if (!value) {
            return "";
        }

        if (isNullOrEmptyString(value.currencyCode)) {
            return value.amount.toLocaleString();
        }

        const format = new Intl.NumberFormat(this.currentCultureProvider.cultureCode, {
            style: 'currency',
            currency: value.currencyCode,
            minimumFractionDigits: 0
        });

        return format.format(value.amount);
    }

    public localizeAge(age: Age) {
        if (age == null) {
            return null;
        }

        const resourceKey = this.getAgeResourceKey(age);
        if (resourceKey == null) {
            return null;
        }

        const localizedText = this.localizeWebAppResource(resourceKey);
        if (localizedText == null) {
            return null;
        }

        return (age.isEstimated ? "~" : "") + formatString(localizedText, age.numberOfUnits.toString());
    }

    private getAgeResourceKey(age: Age): string {
        switch (age.calendarUnit) {
            case CareProxy.CalendarUnit.Day: return "Common.Label.Age.InDays";
            case CareProxy.CalendarUnit.Month: return "Common.Label.Age.InMonths";
            case CareProxy.CalendarUnit.Year: return "Common.Label.Age.InYears";
        }
        return null;
    }

    private getResource(resourceId: string): string {
        if (resourceId == null) {
            return null;
        }

        const separatorIndex = resourceId.indexOf(".");
        const resourceGroupId = resourceId.substring(0, separatorIndex);
        const resourceKey = resourceId.substring(separatorIndex + 1);

        const resourceGroupDictionary = this.cachedResourceGroups.get(resourceGroupId);
        if (resourceGroupDictionary == null) {
            return null;
        }

        const res = resourceGroupDictionary.get(resourceKey);
        return res;
    }

    public localizeValidationResultSummaryMessage(errorCount: number, warningCount: number, validText?: string): string {
        switch (this.currentCultureProvider.cultureCode) {
            case "hu-HU":
                if (errorCount > 0 && warningCount === 0) {
                    return `${errorCount} hiba:`;
                } else if (warningCount > 0 && errorCount === 0) {
                    return `${warningCount} figyelmeztetés:`;
                } else if (errorCount > 0 && warningCount > 0) {
                    return `${errorCount} hiba, ${warningCount} figyelmeztetés:`;
                } else {
                    return validText ? validText : "Minden adat érvényes!";
                }
            case "en-US":
                if (errorCount > 0 && warningCount === 0) {
                    return `${errorCount} error(s):`;
                } else if (warningCount > 0 && errorCount === 0) {
                    return `${warningCount} warning(s):`;
                } else if (errorCount > 0 && warningCount > 0) {
                    return `${errorCount} error(s), ${warningCount} warning(s):`;
                } else {
                    return validText ? validText : "All data is valid!";
                }
            case "sk-SK":
                if (errorCount > 0 && warningCount === 0) {
                    return `${errorCount} chyba:`;
                } else if (warningCount > 0 && errorCount === 0) {
                    return `${warningCount} varovanie:`;
                } else if (errorCount > 0 && warningCount > 0) {
                    return `${errorCount} chyba, ${warningCount} varovanie:`;
                } else {
                    return validText ? validText : "Všetky údaje sú platné!";
                }
            case "cs-CZ":
                if (errorCount > 0 && warningCount === 0) {
                    return `${errorCount} chyba:`;
                } else if (warningCount > 0 && errorCount === 0) {
                    return `${warningCount} varování:`;
                } else if (errorCount > 0 && warningCount > 0) {
                    return `${errorCount} chyba, ${warningCount} varování:`;
                } else {
                    return validText ? validText : "Všetky údaje sú platné!";
                }
        }
        throw new Error("Current culture code is out of range");
    }

    public localizeProperty(selector: string) {
        return this.getResource(`${this.dynamicPropertyResourceGroupId}.${selector}`);
    }

    public localizeReferenceDataWithDefault(resourceId: ResourceId, defaultValue: string) {
        const res = this.localizeReferenceData(resourceId);
        if (res === undefined || res === null || res === "") {
            return defaultValue;
        }
        return res;
    }
}
