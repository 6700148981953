import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import PerformanceReportCareActivityState from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportCareActivity";

interface IPerformanceReportCareActivityStateMultiSelectProps extends ISelectBoxBaseProps {
    value?: PerformanceReportCareActivityState | PerformanceReportCareActivityState[];
    onChange?: (newValue: PerformanceReportCareActivityState | PerformanceReportCareActivityState[]) => void;
}


const PerformanceReportCareActivityStateSelectBox: React.FC<IPerformanceReportCareActivityStateMultiSelectProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"PerformanceReportCareActivityState"}
        enumOrigin="server"
        enumType={PerformanceReportCareActivityState}
        displayMode="selectBox"
    />
);

export default PerformanceReportCareActivityStateSelectBox;