import IDateTimeFormatConfiguration from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatConfiguration";


const SkDateTimeFormatConfiguration = {
    dateFormat: {
        normal: "DD.MM.YYYY",
        parseFormats: ["DD.MM.YYYY"],
        partialDateRegex: {
            // eslint-disable-next-line no-useless-escape
            pattern: "^(?<day>[0-9]{2})?\.(?<month>[0-9]{2})?\.(?<year>[0-9]{4})"
        }
    },
    dateTimeFormat: {
        normal: "DD.MM.YYYY H:mm:ss",
        withoutSeconds: "DD.MM.YYYY H:mm",
        parseFormats: ["DDMMYYYYHHmm"]
    },
    timeFormat: {
        normal: "H:mm:ss",
        withoutSeconds: "H:mm",
        parseFormats: ["H:mm:ss", "H:mm", "HH:mm:ss", "HH:mm"]
    },
    todayString: "today",
    monthFormat: {
        normal: "YYYY.MM",
        parseFormats: ["YYYY.MM"]
    }
} as IDateTimeFormatConfiguration;

export default SkDateTimeFormatConfiguration;