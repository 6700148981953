import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import MedicalServiceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicalServiceApiAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import * as HisUi from "@HisPlatformControls";
import MedicalServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServiceStore";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import _ from "@HisPlatform/Common/Lodash";
import { IUniversalCodeSelectorProps } from "@HisPlatformControls/UniversalCodeSelector";
import CareTypeId from "@Primitives/CareTypeId.g";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import EntityCollectionOwner from "@HisPlatform/BoundedContexts/Productivity/Api/Personalization/Enum/EntityCollectionOwner.g";

interface IMedicalServiceCodeSelectorDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    medicalServiceApiAdapter: MedicalServiceApiAdapter;
}

interface IMedicalServiceCodeSelectorProps extends ICodeSelectorCommonProps<IEntityVersionSelector<MedicalServiceId>> {
    _dependencies?: IMedicalServiceCodeSelectorDependencies;
    filters?: FilterBase[];
    filteredIds?: MedicalServiceId[];
    showFavoritesAndGroup?: boolean;
    pointOfCareId?: PointOfCareId;
    useAlternativeName?: boolean;
    careTypeId?: CareTypeId;
}

@State.observer
class MedicalServiceCodeSelector extends React.Component<IMedicalServiceCodeSelectorProps> {

    public static defaultProps: Partial<IMedicalServiceCodeSelectorProps> = {
        filteredIds: []
    };

    private get dependencies() {
        return this.props._dependencies;
    }

    @State.bound
    private async getDisplayValueAsync(value: IEntityVersionSelector<MedicalServiceId>) {
        const item = await this.dependencies.careReferenceDataStore.medicalService.getOrLoadAsync(value);
        return item?.alternativeName && this.props.useAlternativeName && item.name !== item.alternativeName
            ? <><b>{item?.code.value}</b>&nbsp;{item?.name}<br /> <i style={{ paddingLeft: "24px" }}>{item?.alternativeName}</i> </>
            : <><b>{item?.code.value}</b>&nbsp;{item?.name}</>;
    }

    @State.bound
    private async getTextValueAsync(value: IEntityVersionSelector<MedicalServiceId>) {
        const item = await this.dependencies.careReferenceDataStore.medicalService.getOrLoadAsync(value);
        return this.props.useAlternativeName && item?.alternativeName && item.alternativeName !== item.name
            ? `${item?.code.value} ${item?.name} - ${item?.alternativeName}`
            : `${item?.code.value} ${item?.name}`;
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const result = await this.dependencies.medicalServiceApiAdapter.searchMedicalServicesByCodeAsync(
            text,
            10,
            this.props.careTypeId,
            LocalDate.today(),
            this.props.filteredIds,
            this.props.useAlternativeName,
            this.props.filters
        );

        if (result.operationWasSuccessful) {
            const ids = result.value.map(r => r.id);
            const versionSelectors = this.mapToVersionSelectors(ids);

            await this.dependencies.careReferenceDataStore.medicalService.ensureLoadedAsync(versionSelectors);

            return versionSelectors;
        }

        return null;
    }

    @State.bound
    private getDisplayValue(id: IEntityVersionSelector<MedicalServiceId>) {
        const item = this.dependencies.careReferenceDataStore.medicalService.get(id);
        return (<> <b>{item?.code.value}</b> {item?.name}</>);
    }

    private getEntityById(id: MedicalServiceId): IEntityVersionSelector<MedicalServiceId> {
        return ({ id: id, validOn: LocalDate.today() });
    }

    private getEntitiesByIds(ids: MedicalServiceId[]): Array<IEntityVersionSelector<MedicalServiceId>> {
        return ids.map(item => ({ id: item, validOn: LocalDate.today() }));
    }

    @State.bound
    private getComplexSearchEntitiesByIds(ids: MedicalServiceId[]) {
        return this.dependencies.careReferenceDataStore.medicalService.getAll(
            ids.map(id => ({ id: id, validOn: LocalDate.today() }))
        );
    }

    @State.bound
    private onComplexSearchSingleSelect(item: MedicalServiceStore) {
        const selector = { id: item.id, validOn: LocalDate.today() };
        this.props.onChange(selector);
    }

    @State.bound
    private onComplexSearchMultiSelect(items: MedicalServiceStore[]) {
        const selectors = items.map(item => ({ id: item.id, validOn: LocalDate.today() }));
        this.props.onChange(selectors);
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: MedicalServiceStore[]): Promise<IPagedItems<MedicalServiceStore>> {
        let excludedIds = selectedItems.map(item => item.id);
        if (this.props.multiSelect) {
            const items = (this.props.value as Array<IEntityVersionSelector<MedicalServiceId>>).map(item => item.id);
            excludedIds = _.unionWith(excludedIds, items, (one, other) => wrappedValuesAreEquals(one, other));
        }
        const results = await this.dependencies.medicalServiceApiAdapter.getMedicalServicesAsync(
            filterText,
            ordering,
            paging,
            this.props.careTypeId,
            LocalDate.today(),
            excludedIds,
            this.props.useAlternativeName,
            this.props.filters);

        return {
            items: results.items,
            totalCount: results.totalCount
        } as IPagedItems<MedicalServiceStore>;
    }

    private idMatchesEntity(id: MedicalServiceId, item: IEntityVersionSelector<MedicalServiceId>) {
        return id && item?.id && wrappedValuesAreEquals(id, item.id);
    }

    private get entityCollectionProps(): Partial<IUniversalCodeSelectorProps<IEntityVersionSelector<MedicalServiceId>, MedicalServiceStore>> {
        return this.props.showFavoritesAndGroup && {
            entityName: "MedicalService",
            ownerType: EntityCollectionOwner.PointOfCare,
            ownerId: this.props.pointOfCareId,
            currentPointOfCareId: this.props.pointOfCareId,
            getEntityDisplayValue: this.getDisplayValue,
            getEntityById: this.getEntityById,
            getEntitiesById: this.getEntitiesByIds,
            idMatchesEntity: this.idMatchesEntity
        };
    }

    private renderNameWithAlternativeName(value: any, store: MedicalServiceStore) {
        return store.name !== store.alternativeName ? <>{store.name}<br /> <i>{store.alternativeName} </i> </> : store.name;
    }

    public render() {
        return (
            <HisUi.UniversalCodeSelector
                {...this.props}
                getDisplayValueAsync={this.getDisplayValueAsync}
                getTextValueAsync={this.getTextValueAsync}
                onQuickSearchAsync={this.quickSearchAsync}
                hasComplexSearch
                complexSearchLoadAsync={this.complexSearchLoadAsync}
                complexSearchModalTitle={StaticCareResources.ReferenceData.MedicalServices.CodeSelectorComplexSearchTitle}
                codeGetter={"code.value"}
                nameGetter={!this.props.useAlternativeName && "name"}
                customNameRender={this.props.useAlternativeName && this.renderNameWithAlternativeName}
                getComplexSearchEntitiesByIds={this.getComplexSearchEntitiesByIds}
                onComplexSearchSingleSelect={this.onComplexSearchSingleSelect}
                onComplexSearchMultiSelect={this.onComplexSearchMultiSelect}
                showMenuSections={this.props.showFavoritesAndGroup}
                selectedItemsSearchPlaceHolder={StaticCareResources.ReferenceData.MedicalServices.SelectedService}
                selectableItemsSearchPlaceHolder={StaticCareResources.ReferenceData.MedicalServices.SelectableService}
                {...this.entityCollectionProps}
            />
        );
    }

    private mapToVersionSelectors(ids: MedicalServiceId[]) {
        return ids.map(id => ({
            id,
            validOn: LocalDate.today()
        } as IEntityVersionSelector<MedicalServiceId>));
    }
}

export default connect(
    MedicalServiceCodeSelector,
    new DependencyAdapter<IMedicalServiceCodeSelectorProps, IMedicalServiceCodeSelectorDependencies>((container) => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            medicalServiceApiAdapter: container.resolve("MedicalServiceApiAdapter"),
        };
    })
);
