import React, { useCallback } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import { UniversalEnumSelector } from "@CommonControls";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import TokenDefaultValue from "@Toolkit/FormEngine/Model/Layout/TokenDefaultValue";
import DefaultValueEditor from "./DefaultValueEditor";

enum DefaultValueType {
    Scalar,
    Token
}

function DefaultValuePanel() {
    const store = useFormLayoutEditorStore();

    const setType = useCallback((t: DefaultValueType) => {
        switch (t) {
            case DefaultValueType.Scalar:
                store.selectedDataElement.setDefaultValue(null);
                break;
            case DefaultValueType.Token:                
                store.selectedDataElement.setDefaultValue(new TokenDefaultValue("", "", {}));
                break;
        }
    }, [store]);

    return (
        <>
            <h4 style={{ marginBottom: 0 }}>{StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.DefaultValueTitle}</h4>
            <UniversalEnumSelector
                automationId=""
                enumOrigin="client"
                enumName={"DefaultValueType"}
                enumType={DefaultValueType}
                displayMode="radioButtons"
                boundedContextNameForLocalization="FormEngine"
                value={store.selectedDataElement.defaultValue instanceof TokenDefaultValue ? DefaultValueType.Token : DefaultValueType.Scalar}
                onChange={setType}
            />

            <DefaultValueEditor />
        </>
    );
}

export default State.observer(DefaultValuePanel);