import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import PatientId from "@Primitives/PatientId.g";

export interface IPatientsCareActivityDetailsModalParams extends IModalParams {
    selectedPatientsCareActivityStore: PatientsCareActivityStore;
    patientId: PatientId;
    showPrimaryDocument: boolean;
}

export default class PatientsCareActivityDetailsModalParams implements IPatientsCareActivityDetailsModalParams {

    public static type = "PatientsCareActivityDetailsModalParams";
    public get type() { return PatientsCareActivityDetailsModalParams.type; }

    constructor(
        public readonly selectedPatientsCareActivityStore: PatientsCareActivityStore,
        public readonly patientId: PatientId,
        public readonly showPrimaryDocument: boolean,
    ) { }

}