import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import * as Styles from "./PerformedServicesScreen.less";
import _ from "@HisPlatform/Common/Lodash";
import React, { useMemo } from "react";
import ShowPerformedServicesScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowPerformedServicesScreenAction.g";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import { ScreenStateContextAdapter, ScreenStateContextStore } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import { providePerformedServicesScreenStore, usePerformedServicesScreenStore } from "./PerformedServicesScreenStoreProvider";
import StaticResources from "@StaticResources";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import ShowReadOnlyPerformedServicesScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyPerformedServicesScreenAction.g";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import MedicalServiceColumn from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/MedicalServiceColumn/MedicalServiceColumn";
import LateralityColumn from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/LateralityColumn/LateralityColumn";
import DataGridNumberColumn from "@CommonControls/DataGrid/Column/DataGridNumberColumn";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import PerformedServicesCostCell from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PerformedServicesPanel/PerformedServicesCostCell";
import PerformedServiceActionsCell from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/PerformedServicesPanel/PerformedServiceActionsCell";
import MedicalServiceFinancingStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/MedicalServiceFinancingStore";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";
import ToolbarButtons from "@Toolkit/ReactClient/Components/ToolbarButtons/ToolbarButtons";
import { IEmptyStateSettings } from "@CommonControls/DataGrid/IDataGridProps";
import config from "@Config";

export interface IPerformedServicesScreenProps extends IScreenPropsBase<ShowPerformedServicesScreenAction | ShowReadOnlyPerformedServicesScreenAction> {
    _screenState?: ScreenStateContextStore;
    _modalService?: IModalService;
    _patient?: PatientAdministrativeData;
    _pointOfCareId?: PointOfCareId;
}

const medicalServiceColumnDataGetter = (row: MedicalServiceFinancingStore) => row.performedServices.map(ps => ps.medicalServiceId);
const lateralityColumnDataGetter = (row: MedicalServiceFinancingStore) => row.performedServices.map(ps => ps.lateralityId);
const medicalServiceQuantityColumnDataGetter = (row: MedicalServiceFinancingStore) => row.performedServices.map(ps => ps.medicalServiceQuantity);
const financedServiceQuantityColumnDataGetter = (row: MedicalServiceFinancingStore) => row.financedServicesWithQuantities.map(fs => fs.quantity);
const performedAtColumnDataGetter = (row: MedicalServiceFinancingStore) => row.performedServices.map(ps => ps.performedAt);
const getFinancedServiceData = (row: MedicalServiceFinancingStore) => row.financedServicesWithQuantities;

function PerformedServicesScreen(props: IPerformedServicesScreenProps) {
    const store = usePerformedServicesScreenStore();

    const emptyStateSettings = useMemo(() => {
        return {
            message: StaticCareResources.PerformedServiceList.NoRecordFound
        } as IEmptyStateSettings;
    }, [StaticCareResources.PerformedServiceList.NoRecordFound]);

    const renderDataGrid = () => (
        <ValidationBoundary
            problemFilterPredicate={store.filterValidationProblems}
        >
            <ReadOnlyContext.Provider value={store.vIsReadOnly}>
                <Ui.DataGrid
                    dataSource={store.performedServices.financings}
                    isSelectable={false}
                    selectedRow={store.editedService}
                    hasHeader
                    onRenderHeader={store.renderNewRow}
                    rowHeight="44px"
                    fixedHeight="100%"
                    propertyIdentifier=".*"
                    onMapValidationProblem={store.mapValidationProblems}
                    automationId="__performedServicesList"
                    rowUnderEditing={store.editedService}
                    emptyStateSettings={emptyStateSettings}
                    isExtraFilterVisible
                    extraFilter={(
                        <Ui.ValidationResultSummary
                            results={_.flatten(store.performedServices.validationResults?.map(i => i.problems))}
                            highlightedId={store.highlightedValidationProblem}
                            setHighlightedId={store.setHighlightedValidationProblem}
                            infoRenderer={store.renderValidationInfo}
                            highlightableRuleIds={store.highlightableRuleIds} />
                    )}
                >
                    <MedicalServiceColumn
                        header={StaticCareResources.OutpatientWorkflow.PerformedServicesStep.MedicalService}
                        automationId="MedicalService"
                        leftPadding="small"
                        dataGetter={medicalServiceColumnDataGetter}
                        displayMode="code-name" />
                    <LateralityColumn
                        width="60px"
                        header={StaticCareResources.OutpatientWorkflow.PerformedServicesStep.Laterality}
                        automationId="Laterality"
                        dataGetter={lateralityColumnDataGetter}
                        dataSetter={"setPerformedServiceLaterality"}
                        isObserver />
                    <DataGridNumberColumn
                        width="60px"
                        header={StaticCareResources.OutpatientWorkflow.PerformedServicesStep.Quantity}
                        automationId="Quantity"
                        integerColumn
                        dataGetter={medicalServiceQuantityColumnDataGetter}
                        dataSetter={"setPerformedServiceQuantity"}
                        isObserver
                        nullValue={1} />
                    <DataGridDateTimeColumn
                        width="180px"
                        header={StaticCareResources.OutpatientWorkflow.PerformedServicesStep.PerformedAt}
                        automationId="PerformedAt"
                        dataGetter={performedAtColumnDataGetter}
                        dataSetter={"setPerformedServicePerformedAt"}
                        isObserver />
                    <DataGridColumn
                        isVisible={store.showFinanceData}
                        header={StaticCareResources.OutpatientWorkflow.PerformedServicesStep.FinancedServices}
                        automationId="FinancedServices"
                        onRenderCellValue={store.renderFinancedServices} />
                    <DataGridNumberColumn
                        width="60px"
                        isVisible={store.showFinanceData}
                        header={StaticCareResources.OutpatientWorkflow.PerformedServicesStep.FinancedServicesQuantity}
                        automationId="FinancedServicesQuantity"
                        dataGetter={financedServiceQuantityColumnDataGetter}
                        dataSetter={"setFinancedServiceQuantity"}
                        isObserver
                        nullValue={1} />
                    <DataGridColumn
                        width="70px"
                        isVisible={store.showFinanceData}
                        header={StaticCareResources.OutpatientWorkflow.PerformedServicesStep.Money}
                        automationId="Money">
                        <PerformedServicesCostCell dataGetter={getFinancedServiceData} />
                    </DataGridColumn>
                    <DataGridColumn
                        width="100px"
                        rightPadding="small"
                        header=""
                        cellTextAlign="right"
                        automationId="Actions">
                        <PerformedServiceActionsCell
                            onEdit={store.setEditedService}
                            onDeleteAsync={store.deleteFinancingAsync}
                            onSaveChangesAsync={store.updateServiceAsync}
                            readonly={store.vIsReadOnly}
                        />
                    </DataGridColumn>
                    <HisPlatformExtensionPoint
                        extensionProps={store.extraPerformedServiceColumnExtensionPointProps}
                        type="performedService">
                        <></>
                    </HisPlatformExtensionPoint>
                </Ui.DataGrid>
            </ReadOnlyContext.Provider>
        </ValidationBoundary>
    );

    return (
        <Screen
            title={StaticResources.OutpatientWorkflow.PerformedServicesStep.Title}
            subTitle={store.insurerName}
            iconName="editService"
            toolbar={
                <ToolbarButtons
                    beforeButtons={<>
                        {config.features.performedServicesFinanceDataViewEnabled &&
                            <Ui.Flex.Item>
                                <Ui.CheckBox
                                    label={StaticCareResources.OutpatientWorkflow.PerformedServicesStep.ShowFinanceData}
                                    value={store.showFinanceData}
                                    onChange={store.setShowFinanceData}
                                    displayMode="switch"
                                    verticalAlign="noPadding"
                                    automationId="__showFinanceData"
                                />
                            </Ui.Flex.Item>}
                        {store.canAcquireLock && store.isAllowedToRequestLock &&
                            <HisUi.LockIndicatorComponent
                                locked={!store.performedServices.isMutable}
                                lockedBy={store.performedServices?.lockInfo?.preventingLockUserId}
                                style={{ alignContent: "right" }}
                                onEditClickedAsync={store.takeLockAsync}
                            />}
                    </>}
                    saveButtonVisible={!store.canAcquireLock && !store.vIsReadOnly}
                    saveActionsDescriptor={store.savePerformedServicesAction}
                    onExecuteSaveAsync={store.onSaveAsync}
                    onCancelAsync={store.onCancelAsync}
                />
            }>
            <ValidationBoundary
                validationResults={store.performedServices.validationResults}
                entityTypeName="PerformedServiceList">
                <ReadOnlyContext.Provider value={store.vIsReadOnly || !(store.performedServices && store.performedServices.isMutable)}>
                    {renderDataGrid()}
                </ReadOnlyContext.Provider>
            </ValidationBoundary>
            <NavigateAwayHook isEnabled onNavigateAwayAsync={store.navigateAwayAsync} />
        </Screen>
    );
}

export default connect<IPerformedServicesScreenProps>(
    providePerformedServicesScreenStore(PerformedServicesScreen),
    new ScreenStateContextAdapter(),
    new HisModalServiceAdapter(),
    new PatientContextAdapter<IPerformedServicesScreenProps>(c => ({
        _patient: c.patient
    })),
    new CareActivityContextAdapter<IPerformedServicesScreenProps>(c => ({
        _pointOfCareId: c.careActivity.pointOfCareId
    }))
);