import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import RmcDrawer from "rmc-drawer";
import "rmc-drawer/assets/index.css";

export interface IDrawerProps {
    isOpen: boolean;
    isDocked?: boolean;
    position: "top" | "left" | "right" | "bottom";
    content: React.ReactNode;
    containerStyle?: React.CSSProperties;
    contentStyle?: React.CSSProperties;
    onClose?: () => void;
    transitions?: boolean;
}

@State.observer
export default class Drawer extends React.Component<IDrawerProps, undefined> {

    public static defaultProps: Partial<IDrawerProps> = {
        contentStyle: { backgroundColor: "white", padding: "10px" },
        containerStyle: { position: "relative" },
        transitions: true
    };

    constructor(props: IDrawerProps) {
        super(props);
    }

    public render() {
        return (
            <div style={this.props.containerStyle}>
                <RmcDrawer
                    sidebar={this.props.content}
                    open={this.props.isOpen}
                    docked={this.props.isDocked && this.props.isOpen}
                    position={this.props.position}
                    transitions={this.props.transitions}
                    sidebarStyle={this.props.contentStyle}
                    onOpenChange={this.props.onClose}>
                    {this.props.children}
                </RmcDrawer>
            </div>
        );
    }
}