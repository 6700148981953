import HealthcareProfession from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/HealthcareProfession";
import _ from "@HisPlatform/Common/Lodash";

export function getOrganizationUnitTypeIconNameByTag(tags: string[]) {
    const tag = tags.find(t => t === "InPatient" || t === "OutPatient");
    switch (tag) {
        case "InPatient":
            return "patientInBed";
        case "OutPatient":
            return "patientWalking";  
    }
    return null;
}

export function getIconNameByHealthcareProfessions(healthcareProfessions: HealthcareProfession[]) {
    if (!healthcareProfessions || !healthcareProfessions.length) {
        return null;
    }

    const identifiers = _.flatten(healthcareProfessions.map(item => item?.codes));

    for (const identifier of identifiers) {
        if (!identifier) {
            continue;
        }
        switch (identifier.value) {
            case "01":
                return "internal_medicine";
            case "02":
                return "surgery";
            case "04":
                return "obstetrics_and_gynaecology";
            case "05":
                return "paediatrics";
            case "06":
                return "otorhinolaryngology";
            case "07":
                return "ophthalmology";
            case "08":
                return "dermatology";
            case "11":
                return "urology";
            case "15":
                return "anesthesiology";
            case "18":
                return "psychiatry";
            case "19":
                return "pulmonology";
            case "40":
                return "cardiology";
            case "50":
                return "laboratory_medicine";
            case "51":
                return "radiology";
            case "53":
                return "ultrasound";
        }
    }
    return null;
}
