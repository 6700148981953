import React, {useMemo} from "react";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import AppointmentStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentStatus.g";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import ServiceRequestState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestState.g";

function CareActivityBasedWorklistStateSelectBox(props: IUniversalEnumSelectorBaseProps): React.ReactElement {
    const enums = useMemo(() => [
        {
            enumName: "CareActivityState",
            displayName: StaticProductivityResources.Worklist.Columns.CareActivityStateFilter,
            enumType: CareActivityState,
        },
        {
            enumName: "AppointmentStatus",
            displayName: StaticProductivityResources.Worklist.Columns.AppointmentStatusFilter,
            enumType: AppointmentStatus
        },
        {
            enumName: "ServiceRequestState",
            displayName: StaticProductivityResources.Worklist.Columns.ServiceRequestStateFilter,
            enumType: ServiceRequestState
        }
    ], []);

    return (
        <UniversalEnumSelector
            {...props}
            enumOrigin="server"
            isMultiEnum
            enums={enums}
            searchable={false}
            clearable
            maxMenuWidth={commonMenuMaxWidth}
        />
    );
}

export default CareActivityBasedWorklistStateSelectBox;
