import PointOfCareId from "@Primitives/PointOfCareId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

export default class InternalCareLocation {
    constructor(
        public readonly pointOfCareId: PointOfCareId
    ) { }

    public equals(other: InternalCareLocation): boolean {
        return ValueWrapper.equals(this.pointOfCareId, other && other.pointOfCareId);
    }

    public getHashCode() {
        return this.pointOfCareId && this.pointOfCareId.value;
    }
}