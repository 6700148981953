import { Placement, ContentAlignment } from "@Toolkit/ReactClient/Components/Tooltip";
import { combineClasses } from "./CompositeClassName";

export interface ICommonControlProps {
    className?: string;
    style?: React.CSSProperties;
    id?: string;
    name?: string;
    tabIndex?: number;
    tooltipContent?: string | HTMLElement;
    tooltipPosition?: Placement;
    tooltipTextAlign?: ContentAlignment;
    tooltipType?: "native" | "external";
}

export function getStandardHtmlProps(props: ICommonControlProps, className?: string) {
    return {
        className: combineClasses(className, props.className),
        style: props.style,
        id: props.id,
        name: props.name,
        tabIndex: props.tabIndex,
    };
}