import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientId from "@Primitives/PatientId.g";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { CompositeValidationResult, FormFieldData } from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import { IFormDataStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormDataStore";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import Form from "@Toolkit/FormEngine/Model/Form";
import { mapFormFieldDataToProxy, restoreCustomFormDataStoreAsync } from "@HisPlatform/BoundedContexts/FormEngine/ApplicationLogic/ApiAdapter/FormEngineMappers";
import InvoiceId from "@Primitives/InvoiceId.g";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import ICustomerData from "./ICustomerData";
import InvoiceState from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/InvoiceState";
import CustomerId from "@Primitives/CustomerId.g";
import IOperationResult from "@Toolkit/CommonWeb/ApiAdapter/IOperationResult";

@Di.injectable()
export default class InvoiceScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("IPatientsClient") private readonly apiClient: Proxy.IPatientsClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("IFormEngineReferenceDataStore") private formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("ValidationProblemParameterMapperService") private validationProblemParameterMapperService: ValidationProblemParameterMapperService
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public getNewInvoiceDataAsync(patientId: PatientId) {
        return this.executeOperationAsync<{ form: IForm, extensionData: { [key: string]: any; } }, Proxy.GetNewInvoiceScreenDataQueryResponse>(
            () => this.apiClient.getNewInvoiceScreenDataQueryAsync(CreateRequestId(), new Proxy.GetNewInvoiceScreenDataControllerDto({
                patientId: patientId
            })),
            async response => {
                const form = await this.mapToFormAsync(null, response.data, response.initialFormDefinitionId, [] as any, response.formLogic);
                return {
                    form: form,
                    extensionData: response.extensionData || {}
                };
            }
        );
    }

    @State.bound
    public getInvoiceScreenDataAsync(invoiceId: InvoiceId, requestLock: boolean) {
        return this.executeOperationAsync<{
            form: IForm,
            extensionData: { [key: string]: any; },
            invoiceState: InvoiceState,
        }, Proxy.GetInvoiceScreenDataCommandResponse>(
            () => this.apiClient.getInvoiceScreenDataCommandAsync(CreateRequestId(), new Proxy.GetInvoiceScreenDataControllerDto({
                invoiceId: invoiceId,
                requestLock: requestLock
            })),
            async response => {
                const form = await this.mapToFormAsync(invoiceId, response.data, response.formDefinitionId, response.compositeValidationResult, response.formLogic, response.rowVersion);
                return {
                    form: form,
                    extensionData: response.extensionData || {},
                    invoiceState: response.invoiceState
                };
            }
        );
    }

    @State.bound
    public createInvoiceAsync(
        patientId: PatientId,
        form: IForm,
        shouldIssue: boolean = false,
        isValidateOnly: boolean = false
    ) {
        return this.executeOperationAsync<{ form: IForm, createdInvoiceId: InvoiceId, invoiceState: InvoiceState, issueErrorMessage?: string }, Proxy.CreateInvoiceCommandResponse>(
            () => {
                const mappedFormFieldData: FormFieldData[] = [];
                mapFormFieldDataToProxy(form.data.Content, mappedFormFieldData as any);

                return this.apiClient.createInvoiceCommandAsync(CreateRequestId(), new Proxy.CreateInvoiceControllerDto({
                    patientId: patientId,
                    data: mappedFormFieldData,
                    shouldIssue: shouldIssue,
                    isValidateOnly: isValidateOnly
                }));
            },
            async response => {
                // TODO Is passing existingData needed?
                const resultForm = await this.mapToFormAsync(response.createdInvoiceId, response.data, form.definitionId, response.compositeValidationResult, form.formLogic, response.rowVersion, form.data);
                return {
                    form: resultForm,
                    createdInvoiceId: response.createdInvoiceId,
                    invoiceState: response.invoiceState,
                    errorMessage: response.issueErrorMessage
                };
            }
        );
    }

    @State.bound
    public updateInvoiceAsync(
        invoiceId: InvoiceId,
        form: IForm,
        lockId: LockId,
        shouldIssue: boolean = false,
        isValidateOnly: boolean = false
    ) {
        return this.executeOperationAsync<{ form: IForm, invoiceState: InvoiceState, issueErrorMessage?: string }, Proxy.UpdateInvoiceCommandResponse>(
            () => {
                const mappedFormFieldData: FormFieldData[] = [];
                mapFormFieldDataToProxy(form.data.Content, mappedFormFieldData as any);

                return this.apiClient.updateInvoiceCommandAsync(CreateRequestId(), new Proxy.UpdateInvoiceControllerDto({
                    invoiceId: invoiceId,
                    data: mappedFormFieldData,
                    rowVersion: form.rowVersion,
                    lockId: lockId,
                    shouldIssue: shouldIssue,
                    isValidateOnly: isValidateOnly,
                    releaseLockIfSuccessful: false
                }));
            },
            async response => {
                const resultForm = await this.mapToFormAsync(invoiceId, response.data, form.definitionId, response.compositeValidationResult, form.formLogic, response.rowVersion, form.data);
                return {
                    form: resultForm,
                    invoiceState: response.invoiceState,
                    issueErrorMessage: response.issueErrorMessage,
                };
            }
        );
    }

    public getNewCustomerDataAsync(patientId: PatientId) {
        return this.executeOperationAsync<ICustomerData, Proxy.GetNewCustomerDataQueryResponse>(
            () => this.apiClient.getNewCustomerDataQueryAsync(CreateRequestId(), new Proxy.GetNewCustomerDataControllerDto({
                patientId: patientId
            })),
            response => {
                return {
                    customerId: null,
                    customerName: response.customerData.patientName,
                    countryId: response.customerData.countryId,
                    zipCode: response.customerData.zipCode,
                    settlement: response.customerData.settlement,
                    addressLine: response.customerData.addressLine,
                    emailAddress: response.customerData.emailAddress,
                    phoneNumber: response.customerData.phoneNumber
                } as ICustomerData;
            }
        );
    }

    @State.bound
    public getCustomerDataAsync(customerId: CustomerId, requestLock: boolean) {
        return this.executeOperationAsync<ICustomerData, Proxy.GetCustomerDataByIdCommandResponse>(
            () => this.apiClient.getCustomerDataByIdCommandAsync(CreateRequestId(), new Proxy.GetCustomerDataByIdControllerDto({
                customerId: customerId,
                requestLock: requestLock
            })),
            response => {
                return this.mapToCustomer(response.customer);
            }
        );
    }

    @State.bound
    public getCustomerDataByPatientIdAsync(patientId: PatientId, requestLock: boolean) {
        return this.executeOperationAsync<ICustomerData, Proxy.GetCustomerDataByPatientIdCommandResponse>(
            () => this.apiClient.getCustomerDataByPatientIdCommandAsync(CreateRequestId(), new Proxy.GetCustomerDataByPatientIdControllerDto({
                patientId: patientId,
                requestLock: requestLock
            })),
            response => {
                return this.mapToCustomer(response.customer);
            }
        );
    }

    @State.bound
    public deleteInvoiceAsync(invoiceId: InvoiceId): Promise<IOperationResult<boolean>> {
        return this.executeOperationAsync(
            () => this.apiClient.deleteInvoiceCommandAsync(CreateRequestId(), new Proxy.DeleteInvoiceControllerDto({
                invoiceId: invoiceId
            })),
            response => response.isPersisted
        );
    }

    private async mapToFormAsync(
        invoiceId: InvoiceId,
        data: FormFieldData[],
        definitionId: FormDefinitionId,
        validationResults: CompositeValidationResult,
        formLogic: string,
        rowVersion: RowVersion = null,
        existingData: IFormDataStore = null
    ) {
        const definition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(definitionId);

        const mappedValidationResults = mapValidationResults(validationResults as unknown as IServerCompositeValidationResult);

        mappedValidationResults.forEach(i => {
            i.problems.forEach((j: IClientValidationProblem) => {
                this.validationProblemParameterMapperService.resolveValidationProblemParameters("InvoiceScreenApiAdapter", j);
            });
        });

        const form = new Form(
            invoiceId ? new FormInstanceId(`invoice-${invoiceId.value}`) : FormInstanceId.new,
            rowVersion ?? RowVersion.initial,
            definitionId,
            existingData ?? await restoreCustomFormDataStoreAsync(data as any, definition, this.formEngineReferenceDataStore),
            mappedValidationResults,
            null,
            null,
            formLogic);

        return form;
    }

    private mapToCustomer(dto: Proxy.CustomerExtendedDataDto): ICustomerData {
        if (!dto) {
            return null;
        }

        return {
            customerId: dto.customerId,
            customerName: dto.customerName,
            countryId: dto.countryId,
            zipCode: dto.zipCode,
            settlement: dto.settlement,
            addressLine: dto.addressLine,
            emailAddress: dto.emailAddress,
            phoneNumber: dto.phoneNumber,
            taxPayerInfoType: dto.taxPayerInfoType,
            taxNumber: dto.taxNumber,
            groupIdentifier: dto.groupIdentifier,
            groupMemberTaxNumber: dto.groupMemberTaxNumber,
            communityTaxNumber: dto.communityTaxNumber
        } as ICustomerData;
    }
}