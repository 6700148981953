import BooleanFormDataElement from "@Toolkit/FormEngine/Model/Schema/BooleanFormDataElement";
import BooleanFormFieldData from "@Toolkit/FormEngine/Model/Data/BooleanFormFieldData";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import StringFormDataElement from "@Toolkit/FormEngine/Model/Schema/StringFormDataElement";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import TextArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/TextArrayFormFieldData";
import NumberFormDataElement from "@Toolkit/FormEngine/Model/Schema/NumberFormDataElement";
import NumberFormFieldData from "@Toolkit/FormEngine/Model/Data/NumberFormFieldData";
import NumberArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/NumberArrayFormFieldData";
import DateTimeFormFieldData from "@Toolkit/FormEngine/Model/Data/DateTimeFormFieldData";
import DateTimeFormDataElement from "@Toolkit/FormEngine/Model/Schema/DateTimeFormDataElement";
import DateTimeArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/DateTimeArrayFormFieldData";
import DateFormDataElement from "@Toolkit/FormEngine/Model/Schema/DateFormDataElement";
import DateFormFieldData from "@Toolkit/FormEngine/Model/Data/DateFormFieldData";
import DateArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/DateArrayFormFieldData";
import TimeFormDataElement from "@Toolkit/FormEngine/Model/Schema/TimeFormDataElement";
import TimeFormFieldData from "@Toolkit/FormEngine/Model/Data/TimeFormFieldData";
import TimeArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/TimeArrayFormFieldData";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import ReferencedEntityArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityArrayFormFieldData";
import ReferencedExtensibleEnumFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedExtensibleEnumFormFieldData";
import ReferencedExtensibleEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedExtensibleEnumFormDataElement";
import ReferencedExtensibleEnumArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedExtensibleEnumArrayFormFieldData";
import ReferencedEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEnumFormDataElement";
import ReferencedEnumFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEnumFormFieldData";
import ReferencedEnumArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEnumArrayFormFieldData";
import CompositeFormDataElement from "@Toolkit/FormEngine/Model/Schema/CompositeFormDataElement";
import EnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/EnumFormDataElement";
import EnumFormFieldData from "@Toolkit/FormEngine/Model/Data/EnumFormFieldData";
import EnumArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/EnumArrayFormFieldData";
import CompositeFormFieldData from "@Toolkit/FormEngine/Model/Data/CompositeFormFieldData";
import CompositeArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/CompositeArrayFormFieldData";
import ReferencedEntityFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEntityFormDataElement";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import IFormLayoutBlockStore from "@Toolkit/FormEngine/Model/Layout/IFormLayoutBlockStore";
import FormLayoutRowColumnStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutRowColumnStore";
import FormLayoutRowBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutRowBlockStore";
import FormLayoutDataElementEditorStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutDataElementEditorStore";
import FormLayoutGroupBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutGroupBlockStore";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IncludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierFilter";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";

export function getEmptyFormFieldDataFor(formDataElements: FormDataElementBase[], formFieldData: FormFieldDataBase[], onlySimpleElementsNeeded: boolean = false) {
    formDataElements.forEach(i => {
        if (i instanceof BooleanFormDataElement) {
            formFieldData.push(new BooleanFormFieldData(i.name, i.isReadOnly, i.isVisible, false));
        } else if (i instanceof StringFormDataElement) {
            if (!i.isArray || onlySimpleElementsNeeded) {
                formFieldData.push(new TextFormFieldData(i.name, i.isReadOnly, i.isVisible, null));
            } else {
                formFieldData.push(new TextArrayFormFieldData(i.name, i.isReadOnly, i.isVisible, State.createObservableShallowArray([])));
            }
        } else if (i instanceof NumberFormDataElement) {
            if (!i.isArray || onlySimpleElementsNeeded) {
                formFieldData.push(new NumberFormFieldData(i.name, i.isReadOnly, i.isVisible, null));
            } else {
                formFieldData.push(new NumberArrayFormFieldData(i.name, i.isReadOnly, i.isVisible, State.createObservableShallowArray([])));
            }
        } else if (i instanceof DateTimeFormDataElement) {
            if (!i.isArray || onlySimpleElementsNeeded) {
                formFieldData.push(new DateTimeFormFieldData(i.name, i.isReadOnly, i.isVisible, null));
            } else {
                formFieldData.push(new DateTimeArrayFormFieldData(i.name, i.isReadOnly, i.isVisible, State.createObservableShallowArray([])));
            }
        } else if (i instanceof DateFormDataElement) {
            if (!i.isArray || onlySimpleElementsNeeded) {
                formFieldData.push(new DateFormFieldData(i.name, i.isReadOnly, i.isVisible, null));
            } else {
                formFieldData.push(new DateArrayFormFieldData(i.name, i.isReadOnly, i.isVisible, State.createObservableShallowArray([])));
            }
        } else if (i instanceof TimeFormDataElement) {
            if (!i.isArray || onlySimpleElementsNeeded) {
                formFieldData.push(new TimeFormFieldData(i.name, i.isReadOnly, i.isVisible, null));
            } else {
                formFieldData.push(new TimeArrayFormFieldData(i.name, i.isReadOnly, i.isVisible, State.createObservableShallowArray([])));
            }
        } else if (i instanceof EnumFormDataElement) {
            if (!i.isArray || onlySimpleElementsNeeded) {
                formFieldData.push(new EnumFormFieldData(i.name, null, i.isReadOnly, i.isVisible, null));
            } else {
                formFieldData.push(new EnumArrayFormFieldData(i.name, null, i.isReadOnly, i.isVisible, State.createObservableShallowArray([])));
            }
        } else if (i instanceof ReferencedEntityFormDataElement) {
            if (!i.isArray || onlySimpleElementsNeeded) {
                formFieldData.push(new ReferencedEntityFormFieldData(i.name, i.isReadOnly, i.isVisible, State.createObservableShallowArray(i.filters), null));
            } else {
                formFieldData.push(new ReferencedEntityArrayFormFieldData(i.name, i.isReadOnly, i.isVisible, State.createObservableShallowArray(i.filters), []));
            }
        } else if (i instanceof ReferencedExtensibleEnumFormDataElement) {
            if (!i.isArray || onlySimpleElementsNeeded) {
                formFieldData.push(new ReferencedExtensibleEnumFormFieldData(i.name, i.isReadOnly, i.isVisible, null));
            } else {
                formFieldData.push(new ReferencedExtensibleEnumArrayFormFieldData(i.name, i.isReadOnly, i.isVisible, State.createObservableShallowArray([])));
            }
        } else if (i instanceof ReferencedEnumFormDataElement) {
            if (!i.isArray || onlySimpleElementsNeeded) {
                formFieldData.push(new ReferencedEnumFormFieldData(i.name, i.isReadOnly, i.isVisible, null));
            } else {
                formFieldData.push(new ReferencedEnumArrayFormFieldData(i.name, i.isReadOnly, i.isVisible, State.createObservableShallowArray([])));
            }
        } else if (i instanceof CompositeFormDataElement) {
            if (!i.isArray) {
                const compositeFormFieldData: FormFieldDataBase[] = [];
                getEmptyFormFieldDataFor(i.formDataElements, compositeFormFieldData);

                formFieldData.push(new CompositeFormFieldData(i.name, i.isReadOnly, i.isVisible, State.createObservableShallowArray(compositeFormFieldData)));
            } else {
                formFieldData.push(new CompositeArrayFormFieldData(i.name, i.isReadOnly, i.isVisible, State.createObservableShallowArray([])));
            }
        }
    });
}

export function hasVisibleItemInTreeBelow(blockStore: IFormLayoutBlockStore, data: FormFieldDataBase[], parentPath: string) {
    let result = false;

    hasVisibleItemInTreeBelowCore(blockStore, data, parentPath);
    return result;

    function hasVisibleItemInTreeBelowCore(_blockStore: IFormLayoutBlockStore, _data: FormFieldDataBase[], _parentPath: string) {
        if (_blockStore instanceof FormLayoutRowBlockStore) {
            _blockStore.content.forEach(i => {
                hasVisibleItemInTreeBelowCore(i, _data, _parentPath);
            });
        } else if (_blockStore instanceof FormLayoutGroupBlockStore) {
            let changedPath = _parentPath;

            if (!isNullOrUndefined(_blockStore.dataReference)) {
                const field = getField(_data, isNullOrUndefined(_parentPath)
                    ? _blockStore.dataReference
                    : _parentPath + "." + _blockStore.dataReference);

                if (field instanceof CompositeArrayFormFieldData) {
                    result = true;
                    return;
                }

                changedPath = isNullOrUndefined(_parentPath) ? _blockStore.dataReference : _parentPath + "." + _blockStore.dataReference;
            }

            _blockStore.content.forEach(i => {
                hasVisibleItemInTreeBelowCore(i, _data, changedPath);
            });
        } else if (_blockStore instanceof FormLayoutRowColumnStore) {
            if (Array.isArray(_blockStore.content)) {
                _blockStore.content.forEach(i => {
                    hasVisibleItemInTreeBelowCore(i, _data, _parentPath);
                });

                return;
            }

            const dataElementEditorNode = (_blockStore.content as FormLayoutDataElementEditorStore);
            const isVisible = getField(_data, isNullOrUndefined(_parentPath)
                ? dataElementEditorNode.dataReference
                : _parentPath + "." + dataElementEditorNode.dataReference)?.isVisible;
            if (isVisible) {
                result = true;
            }
        } else if (_blockStore instanceof FormLayoutDataElementEditorStore) {
            const isVisible = getField(_data, isNullOrUndefined(_parentPath)
                ? _blockStore.dataReference
                : _parentPath + "." + _blockStore.dataReference)?.isVisible;
            if (isVisible) {
                result = true;
            }
        } else {
            result = true;
        }
    }
}

export function getField<T extends FormFieldDataBase>(formFields: FormFieldDataBase[], path: string) {
    const pathParts = path.split(".");

    let actualFields = formFields;
    let resultField: FormFieldDataBase;
    for (const pathPart of pathParts) {

        let pathPartToCheck = pathPart;
        let arrayIndex: number = null;
        if (pathPart.endsWith("]")) {
            const indexOfStart = pathPart.indexOf("[");
            const indexOfEnd = pathPart.indexOf("]");

            arrayIndex = +pathPart.substring(indexOfStart + 1, indexOfEnd);
            pathPartToCheck = pathPart.substring(0, indexOfStart);
        }

        resultField = actualFields.find(i => i.fieldName === pathPartToCheck);

        if (resultField instanceof CompositeFormFieldData) {
            actualFields = resultField.value;
        } else if (resultField instanceof CompositeArrayFormFieldData && arrayIndex >= 0) {
            actualFields = resultField.value[arrayIndex];
        }
    }

    return resultField as T;
}

export function getCompositeArrayFields(formFields: FormFieldDataBase[], path: string): IObservableArray<FormFieldDataBase> {
    const pathParts = path?.split(".");

    let actualFields = formFields;
    let result: IObservableArray<FormFieldDataBase> = null;
    if(pathParts) {
        for (const pathPart of pathParts) {

            let pathPartToCheck = pathPart;
            let arrayIndex: number = null;
            if (pathPart.endsWith("]")) {
                const indexOfStart = pathPart.indexOf("[");
                const indexOfEnd = pathPart.indexOf("]");
    
                arrayIndex = +pathPart.substring(indexOfStart + 1, indexOfEnd);
                pathPartToCheck = pathPart.substring(0, indexOfStart);
            }
    
            const actualField = actualFields.find(i => i.fieldName === pathPartToCheck);
    
            if (actualField instanceof CompositeFormFieldData) {
                actualFields = actualField.value;
                result = actualField.value;
            } else if (actualField instanceof CompositeArrayFormFieldData && arrayIndex >= 0) {
                actualFields = actualField.value[arrayIndex];
                result = actualField.value[arrayIndex];
            }
        }
    }

    return result;
}

export function getAllReferencedEntityFormFieldData(formFields: FormFieldDataBase[], formDataElements: FormDataElementBase[]) {
    const result: Array<{ field: ReferencedEntityFormFieldData | ReferencedEntityArrayFormFieldData, dataElement: FormDataElementBase }> = [];
    getAllReferencedEntityFormFieldDataCore(formFields, formDataElements);

    return result;

    function getAllReferencedEntityFormFieldDataCore(_formFields: FormFieldDataBase[], _formDataElements: FormDataElementBase[]) {
        for (const formField of _formFields) {
            const actualElement = _formDataElements.find(i => i.name === formField.fieldName);

            if (isNullOrUndefined(actualElement)) {
                continue;
            }

            if (formField instanceof ReferencedEntityFormFieldData || formField instanceof ReferencedEntityArrayFormFieldData) {
                result.push({ field: formField, dataElement: actualElement });
            } else if (formField instanceof CompositeFormFieldData) {
                const compositeElement = actualElement as CompositeFormDataElement;

                getAllReferencedEntityFormFieldDataCore(formField.value, compositeElement.formDataElements);
            } else if (formField instanceof CompositeArrayFormFieldData) {
                const compositeElement = actualElement as CompositeFormDataElement;

                for (const i of formField.value) {
                    getAllReferencedEntityFormFieldDataCore(i, compositeElement.formDataElements);
                }
            }
        }
    }
}

export function replaceDetailContent(sourceItems: FormFieldDataBase[], newItems: FormFieldDataBase[], parentPath: string = null) {
    newItems.forEach(i => {
        if (i instanceof CompositeFormFieldData) {
            replaceDetailContent(sourceItems, i.value, isNullOrUndefined(parentPath) ? i.fieldName : parentPath + "." + i.fieldName);
        } else if (i instanceof CompositeArrayFormFieldData) {
            i.value.forEach((j, index) => {
                replaceDetailContent(sourceItems, j, isNullOrUndefined(parentPath) ? i.fieldName + `[${index}]` : parentPath + "." + i.fieldName + `[${index}]`);
            });
        } else if (i.isArray) {
            const itemToReplace = getField(sourceItems, isNullOrUndefined(parentPath) ? i.fieldName : parentPath + "." + i.fieldName);

            State.runInAction(() => {
                if (i instanceof ReferencedEntityArrayFormFieldData) {
                    (itemToReplace as any).value = (i as any).value;
                } else {
                    ((itemToReplace as any).value as IObservableArray<any>).replace((i as any).value);
                }

                itemToReplace.isReadOnly = i.isReadOnly;
                itemToReplace.isVisible = i.isVisible;

                if (i instanceof ReferencedEntityArrayFormFieldData) {
                    ((itemToReplace as any).filters as IObservableArray<any>).replace(i.filters);
                }
            });
        } else {
            const itemToReplace = getField(sourceItems, isNullOrUndefined(parentPath) ? i.fieldName : parentPath + "." + i.fieldName);

            State.runInAction(() => {
                (itemToReplace as any).value = (i as any).value;
                itemToReplace.isReadOnly = i.isReadOnly;
                itemToReplace.isVisible = i.isVisible;

                if (i instanceof ReferencedEntityFormFieldData) {
                    ((itemToReplace as any).filters as IObservableArray<any>).replace(i.filters);
                }
            });
        }
    });
}

export function setIncludeIdentifierFilter(formField: ReferencedEntityFormFieldData | ReferencedEntityArrayFormFieldData, identifierSystem: string, filterValue: string) {
    formField.filters.clear();
    formField.filters.push(new IncludeIdentifierFilter(new Identifier(new IdentifierSystemId(identifierSystem), filterValue)));
}

export function setExplicitIdentifierFilter(formField: ReferencedEntityFormFieldData | ReferencedEntityArrayFormFieldData, filterType: string, filterValue: string) {
    formField.filters.clear();
    formField.filters.push(new ExplicitIdentifierFilter(filterType, filterValue));
}