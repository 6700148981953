import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import IdentifierTypeId from "@Primitives/IdentifierTypeId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import PatientDocumentType from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/PatientDocumentType";
import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import PatientDocumentTypeId from "@Primitives/PatientDocumentTypeId.g";
import _ from "@HisPlatform/Common/Lodash";
import { buildQueryStringArray } from "@Toolkit/CommonWeb/QueryStringBuilder";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class PatientDocumentApiAdapter extends ApiAdapterBase {

    constructor(@Di.inject("IPatientRegisterClient") private apiClient: Proxy.IPatientRegisterClient) {
        super();
    }

    @State.bound
    public loadPatientDocumentTypesAsync(documentTypeIds: PatientDocumentTypeId[]) {
        const normalizedIds = _.uniq(documentTypeIds.filter(id => !!id).map(id => id.value.toString()));

        return this.processOperationAsync(
            new SimpleStore<PatientDocumentType[]>(),
            async target => {
                const response = await this.apiClient.getPatientDocumentTypesByIdsQueryAsync(
                    CreateRequestId(),
                    buildQueryStringArray(normalizedIds)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.patientDocumentTypes.map(idt => {
                    const identifierType = new PatientDocumentType();
                    identifierType.id = idt.id;
                    identifierType.category = idt.patientDocumentCategory;
                    identifierType.identifierSystemId = idt.identifierSystemId;
                    identifierType.isSelectable = idt.isSelectable;
                    return identifierType;
                });
            }
        );
    }

    @State.bound
    public loadAllPatientDocumentTypeIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<IdentifierTypeId[]>(),
            async target => {
                const response = await this.apiClient.getAllPatientDocumentTypeIdsQueryAsync(CreateRequestId(), new Proxy.GetAllPatientDocumentTypeIdsControllerDto());

                target.operationInfo = createOperationInfo(response);
                target.value = response.documentTypes;
            }
        );
    }
}
