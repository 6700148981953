import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import IDoctor from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IDoctor";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import PractitionerId from "@Primitives/PractitionerId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import MedicalServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServiceStore";
import * as HisUi from "@HisPlatformControls";
import StaticWebAppResources from "@StaticResources";
import { IUniversalCodeSelectorProps } from "@HisPlatformControls/UniversalCodeSelector";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import { ExplicitIdentifierFilter } from "@HisPlatform/BoundedContexts/Surgery/Api/Proxy.g";
import PractitionerType from "@HisPlatform/BoundedContexts/Organization/Api/Practitioners/Enum/PractitionerType.g";
import EntityCollectionOwner from "@HisPlatform/BoundedContexts/Productivity/Api/Personalization/Enum/EntityCollectionOwner.g";

interface IDoctorCodeSelectorDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    practitionerApiAdapter: PractitionerApiAdapter;
    localizationService: ILocalizationService;
}

interface IDoctorCodeSelectorProps extends ICodeSelectorCommonProps<PractitionerId> {
    _dependencies?: IDoctorCodeSelectorDependencies;
    filters?: FilterBase[];
    isExternal?: boolean;
    showFavoritesAndGroup?: boolean;
    showCreateNewDoctor?: boolean;
    favoritesAndGroupPointOfCare?: PointOfCareId;
}

@State.observer
class DoctorCodeSelector extends React.Component<IDoctorCodeSelectorProps> {
    @State.computed
    private get pointOfCareIds() {
        if (!this.props.filters) {
            return null;
        }

        const pointOfCareIdFilters = this.props.filters
            .filter(filter => filter instanceof ExplicitIdentifierFilter && filter.type === "PointOfCare")
            .map(filter => filter as ExplicitIdentifierFilter);

        if (pointOfCareIdFilters.length === 0) {
            return null;
        }

        return pointOfCareIdFilters.map(filter => new PointOfCareId(filter.value));
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.initializePointOfCareAsync(), this);
    }

    public componentDidUpdate(prevProps: IDoctorCodeSelectorProps) {
        if (!this.props.filters) {
            return;
        }

        if (prevProps.filters !== this.props.filters) {
            dispatchAsyncErrors(this.initializePointOfCareAsync(), this);
        }
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const result = await this.dependencies.practitionerApiAdapter.searchDoctorsAsync(
            this.props.isExternal,
            text,
            5,
            this.props.filters
        );

        if (result.operationWasSuccessful) {
            const ids = result.value.map(r => r);
            await this.dependencies.organizationReferenceDataStore.doctorMap.ensureLoadedAsync(ids);
            return ids;
        }

        return null;
    }

    @State.bound
    private async initializePointOfCareAsync() {
        await this.dependencies.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync(this.pointOfCareIds);
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: MedicalServiceStore[]): Promise<IPagedItems<IDoctor>> {

        const today = LocalDate.today();
        const isExternal = this.pointOfCareIds ? false : this.props.isExternal;
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;

        const results = await this.dependencies.practitionerApiAdapter.searchPractitionersAsync(
            PractitionerType.Doctor,
            today,
            isExternal,
            null,
            null,
            filterText,
            customOrdering,
            paging,
            null,
            this.props.filters
        );

        const practitionerIds = results?.value?.values?.map(x => x.id);
        let orderedDoctors = [] as IDoctor[];

        if (practitionerIds && practitionerIds.length > 0) {
            await this.dependencies.organizationReferenceDataStore.doctorMap.ensureLoadedAsync(practitionerIds);
            const doctorStore = await this.dependencies.practitionerApiAdapter.getDoctorsByIdsAsync(practitionerIds);
            const doctors = doctorStore.value;
            orderedDoctors = practitionerIds.map(x => doctors.find(y => y.id.value === x.value)).filter(z => !isNullOrUndefined(z));
        }

        return {
            items: orderedDoctors,
            totalCount: results.value.totalCount
        };
    }

    @State.bound
    private onComplexSearchSingleSelect(item: IDoctor) {
        this.props.onChange(item.id);
    }

    @State.bound
    private onComplexSearchMultiSelect(items: IDoctor[]) {
        this.props.onChange(items.map(item => item.id));
    }

    @State.bound
    private getNameValueString(value: any) {
        return this.props._dependencies.localizationService.localizePersonName(value);
    }

    @State.bound
    private async getDisplayValueAsync(value: IStringEntityId) {
        if (value instanceof PractitionerId) {
            const item = await this.dependencies.organizationReferenceDataStore.doctorMap.getOrLoadAsync(value as PractitionerId);
            return <><b>{item.code}</b>{` ${this.dependencies.localizationService.localizePersonName(item.name)}`}</>;
        } else {
            return null;
        }
    }

    @State.bound
    private async getTextValueAsync(value: IStringEntityId) {
        if (value instanceof PractitionerId) {
            const item = await this.dependencies.organizationReferenceDataStore.doctorMap.getOrLoadAsync(value as PractitionerId);
            return `${item.code} ${this.dependencies.localizationService.localizePersonName(item.name, true)}`;
        } else {
            return null;
        }
    }

    @State.bound
    private getComplexSearchEntitiesByIds(ids: PractitionerId[]) {
        return ids.map(this.dependencies.organizationReferenceDataStore.doctorMap.get);
    }

    @State.bound
    private async createDoctorAsync(code: string, name: string): Promise<{ item: IDoctor, validationResults: IClientValidationResult[] }> {
        const response = await this.dependencies.practitionerApiAdapter.createDoctorAsync(code, name, false);
        await this.dependencies.organizationReferenceDataStore.doctorMap.ensureLoadedAsync([response.value.id]);
        return {
            item: response.value,
            validationResults: response.value.validationResults
        };
    }

    private get entityCollectionProps(): Partial<IUniversalCodeSelectorProps<PractitionerId, IDoctor>> {
        return this.props.showFavoritesAndGroup && {
            entityName: "Practitioner",
            ownerType: EntityCollectionOwner.PointOfCare,
            ownerId: this.props.favoritesAndGroupPointOfCare,
            currentPointOfCareId: this.props.favoritesAndGroupPointOfCare,
            getEntityDisplayValue: this.getDisplayValue,
            getEntityId: (id: PractitionerId) => id
        };
    }

    @State.bound
    private getDisplayValue(value: PractitionerId) {
        const item = this.dependencies.organizationReferenceDataStore.doctorMap.get(value);
        return (<> <b>{item?.code}</b> {item?.name && this.dependencies.localizationService.localizePersonName(item.name)}</>);
    }

    private get permissionCheckedOperations(): any {
        const res = {};
        if (!!this.props.showCreateNewDoctor) {
            res["CreateDoctor"] = async () => { await this.dependencies.practitionerApiAdapter.createDoctorAsync("test", "test", false, true); };
        }
        return res;
    }

    @State.bound
    private async validateAllAsync(code: string, name: string): Promise<IClientValidationResult[]> {
        const response = await this.dependencies.practitionerApiAdapter.createDoctorAsync(code, name, true);
        return response.value.validationResults;
    }

    public render() {
        return (
            <PermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                <HisUi.UniversalCodeSelector
                    {...this.props}
                    getDisplayValueAsync={this.getDisplayValueAsync}
                    getTextValueAsync={this.getTextValueAsync}
                    onQuickSearchAsync={this.quickSearchAsync}
                    hasComplexSearch
                    complexSearchLoadAsync={this.complexSearchLoadAsync}
                    complexSearchModalTitle={StaticWebAppResources.Selectors.DoctorSelector.Title}
                    codeGetter={"code"}
                    nameGetter={"name"}
                    getComplexSearchEntitiesByIds={this.getComplexSearchEntitiesByIds}
                    onComplexSearchSingleSelect={this.onComplexSearchSingleSelect}
                    onComplexSearchMultiSelect={this.onComplexSearchMultiSelect}
                    showMenuSections={this.props.showFavoritesAndGroup}
                    getNameValueString={this.getNameValueString}
                    createNewItemAsync={this.props.showCreateNewDoctor && this.createDoctorAsync}
                    onValidateAllAsync={this.validateAllAsync}
                    createNewItemNamePlaceHolder={StaticWebAppResources.Selectors.DoctorSelector.Columns.DoctorName}
                    createNewItemCodePlaceHolder={StaticWebAppResources.Selectors.DoctorSelector.Columns.DoctorCode}
                    createNewItemCodePropertyIdentifier="Identifiers[0].Identifier.Value"
                    createNewItemNamePropertyIdentifier="Identifiers[0].Name"
                    createNewItemValidationEntityName="Practitioner"
                    createNewItemButtonText={StaticWebAppResources.Selectors.DoctorSelector.CreateNewItemButtonText}
                    dropDownNewItemText={StaticWebAppResources.Selectors.DoctorSelector.DropDownNewItemText}
                    newItemPermissionIdentifier={"CreateDoctor"}
                    {...this.entityCollectionProps}
                />
            </PermissionCheckContextProvider>
        );
    }

    private get dependencies() {
        return this.props._dependencies;
    }
}

export default connect(
    DoctorCodeSelector,
    new DependencyAdapter<IDoctorCodeSelectorProps, IDoctorCodeSelectorDependencies>((container) => {
        return {
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            practitionerApiAdapter: container.resolve("PractitionerApiAdapter"),
            localizationService: container.resolve("ILocalizationService")
        };
    })
);
