import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import Di from "@Di";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import DateTimeFormFieldData from "@Toolkit/FormEngine/Model/Data/DateTimeFormFieldData";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import ReferencedExtensibleEnumFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedExtensibleEnumFormFieldData";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import { getField, setExplicitIdentifierFilter } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";
import IForm from "@Toolkit/FormEngine/Model/IForm";


@Di.injectable()
class AdmitPatientAndCareActivityBaseDataScreenFormInpatientBaseFormLogic implements IFormLogic {

    public executeAsync(form: IForm, showScreenAction?: ShowScreenFrontendActionBase): Promise<void> {
        State.runInAction(() => {
            const formData = form.data.Content;
            const concatenatedIdentifierField = getField<TextFormFieldData>(formData, "ConcatenatedIdentifier");
            concatenatedIdentifierField.isReadOnly = true;

            const registrationNumberField = getField<TextFormFieldData>(formData, "RegistrationNumber");
            const serialNumberField = getField<TextFormFieldData>(formData, "SerialNumber");
            if (registrationNumberField.value !== null && serialNumberField.value !== null) {
                concatenatedIdentifierField.value = registrationNumberField.value + "/" + serialNumberField.value;
            }

            const admittedAtField = getField<DateTimeFormFieldData>(formData, "AdmittedAt");
            const wentUnderCareAtField = getField<DateTimeFormFieldData>(formData, "WentUnderCareAt");
            wentUnderCareAtField.value = admittedAtField.value;

            getField<DateTimeFormFieldData>(formData, "PlannedStartOfCare").isReadOnly = true;

            const pointOfCareIdField = getField<ReferencedEntityFormFieldData>(formData, "PointOfCareId");

            if (!isNullOrUndefined(showScreenAction) && !(showScreenAction instanceof ShowNewAdmitPatientScreenAction)) {
                pointOfCareIdField.isReadOnly = true;
            }

            const inpatientAdmissionTypeField = getField<ReferencedExtensibleEnumFormFieldData>(formData, "InpatientAdmissionTypeId");
            const inpatientAdmissionTypeByCareAspectField = getField<ReferencedExtensibleEnumFormFieldData>(formData, "InpatientAdmissionTypeByCareAspectId");
            const inpatientAdmissionTypeByLegalAspectField = getField<ReferencedExtensibleEnumFormFieldData>(formData, "InpatientAdmissionTypeByLegalAspectId");
            if (getField<TextFormFieldData>(formData, "IsHunCoverageNeeded").value === "1") {
                inpatientAdmissionTypeField.isVisible = true;
                inpatientAdmissionTypeByCareAspectField.isVisible = true;
                inpatientAdmissionTypeByLegalAspectField.isVisible = true;
            } else {
                inpatientAdmissionTypeField.isVisible = false;
                inpatientAdmissionTypeByCareAspectField.isVisible = false;
                inpatientAdmissionTypeByLegalAspectField.isVisible = false;
            }

            const admissionDoctorField = getField<ReferencedEntityFormFieldData>(formData, "AdmissionDoctor");
            const careDoctorField = getField<ReferencedEntityFormFieldData>(formData, "CareDoctor");
            const leadDoctorField = getField<ReferencedEntityFormFieldData>(formData, "LeadDoctor");

            if (careDoctorField.value === null && leadDoctorField.value !== null) {
                careDoctorField.value = leadDoctorField.value;
            }

            setExplicitIdentifierFilter(admissionDoctorField, "PointOfCare", pointOfCareIdField.value.toString());
            setExplicitIdentifierFilter(careDoctorField, "PointOfCare", pointOfCareIdField.value.toString());
            setExplicitIdentifierFilter(leadDoctorField, "PointOfCare", pointOfCareIdField.value.toString());

            const dischargedAtField = getField<DateTimeFormFieldData>(formData, "DischargedAt");

            dischargedAtField.isReadOnly = true;

            if (dischargedAtField.value === null) {
                dischargedAtField.isVisible = false;
            }
        });
        return Promise.resolve();
    }
}

export default AdmitPatientAndCareActivityBaseDataScreenFormInpatientBaseFormLogic;
