import React from "react";
import DocumentEditor from "@CommonControls/DocumentEditor";
import { IDocumentContentStore } from "@CommonControls/DocumentEditor/DocumentContentStore";
import { HisErrorBoundary } from "@HisPlatform/Components/HisPlatformControls";

interface IDocumentSnippetDetailPanelProps {
    contentStore: IDocumentContentStore;
}


const DocumentSnippetDetailPanel: React.FC<IDocumentSnippetDetailPanelProps> = props => {
    return !!props.contentStore && (
        <HisErrorBoundary>
            <DocumentEditor contentStore={props.contentStore.forEditor} mode="TextBlock" />
        </HisErrorBoundary>
    );
};

export default DocumentSnippetDetailPanel;