import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import { withHisErrorBoundary } from "@HisPlatform/Components/HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import HunEHealthWorklistApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/HunEHealthWorklistApiAdapter";
import NDataGrid from "@HisPlatform/BoundedContexts/Productivity/Components/NDataGrid/NDataGrid";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import * as Ui from "@CommonControls";
import { PrescriptionType } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationSelectorDialog/MedicationSelectorDialog";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import ExtendedPermanentPrescriptionFilter from "./Filter/ExtendedPermanentPrescriptionFilter";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import ExtendedPermanentPrescriptionFilterStore from "./Filter/ExtendedPermanentPrescriptionFilterStore";
import OrganizationSelector from "@HisPlatform/BoundedContexts/Organization/Components/OrganizationSelector";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import HealthcareProfessionId from "@Primitives/HealthcareProfessionId.g";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import SelectItemClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/SelectItemClientSideAction";
import PermanentPrescriptionId from "@Primitives/PermanentPrescriptionId.g";
import { IPrescriptionsToBeCreatedListItem } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionsToBeCreated/IPrescriptionsToBeCreatedListItem";
import PrescriptionsToBeCreated from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionsToBeCreated/PrescriptionsToBeCreated";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";
import PatientId from "@Primitives/PatientId.g";

interface IPermanentPrescriptionListPanelDependencies {
    careActivityApiAdapter: CareActivityApiAdapter2;
    prescriptionApiAdapter: PrescriptionApiAdapter;
    structureApiAdapter: StructureApiAdapter;
    worklistApiAdapter: HunEHealthWorklistApiAdapter;
}

export interface IPermanentPrescriptionListPanelProps {
    _dependencies?: IPermanentPrescriptionListPanelDependencies;
    _careActivityId?: CareActivityId;
    _patientId?: PatientId;

    selectedItems: IPrescriptionsToBeCreatedListItem[];
    selectPrescription: (permanentPrescriptionId: PermanentPrescriptionId) => void;
    removeSelectedPrescription: (item: IPrescriptionsToBeCreatedListItem) => void;
    useCaseState: INDataUseCaseState;
    onChange: (rowId: string, useCaseState: INDataUseCaseState) => void;
    fullHeight?: boolean;
    validOn?: LocalDate;
    prescriptionType?: PrescriptionType;
    setPrescriptionType?: (prescriptionType: PrescriptionType) => void;
    onFilterStoreCreated?: (store: ExtendedPermanentPrescriptionFilterStore) => void;
}

@State.observer
class PermanentPrescriptionListPanel extends React.Component<IPermanentPrescriptionListPanelProps> {
    @State.observable private definition: IWorklistDefinition = null;
    @State.observable.ref private filterStore: ExtendedPermanentPrescriptionFilterStore = null;
    @State.observable.ref private currentOrganizationUnitId: OrganizationUnitId = null;
    @State.observable.ref private currentHealthcareProfessionIds: HealthcareProfessionId[] = [];
    @State.observable.ref private relatedOrganizationUnitIds: OrganizationUnitId[] = [];

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadPanelAsync);

    private get careActivityApiAdapter() { return this.props._dependencies.careActivityApiAdapter; }
    private get structureApiAdapter() { return this.props._dependencies.structureApiAdapter; }
    private get worklistApiAdapter() { return this.props._dependencies.worklistApiAdapter; }

    private get validOn() {
        return this.props.validOn || LocalDate.today();
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IPermanentPrescriptionListPanelProps) {
        if (prevProps._careActivityId === this.props._careActivityId)
            return;
        
        dispatchAsyncErrors(this.loadOrganizationDataAsync(), this);
    }

    public componentWillUnmount() {
        if (this.filterStore) {
            this.filterStore.clearAllFilters();
            this.filterStore.setPatientId(this.props._patientId);
        }
    }

    public render() {
        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }

        return (
            <>
                {this.definition && <NDataGrid
                    definition={this.definition}
                    defaultExtraFilterVisibility={true}
                    useCaseState={this.props.useCaseState}
                    onChange={this.props.onChange}
                    defaultPageSize={10}
                    onGetExtendedFilterDescriptors={ExtendedPermanentPrescriptionFilterStore.getFilterDescriptors}
                    onFilterStoreCreatedAsync={this.onFilterStoreCreatedAsync}
                    extraFilter={this.filterStore && this.renderExtraFilter()}
                    rowBodyHeight={400}
                    onPerformClientSideActionAsync={this.performClientSideActionAsync}
                    getRowIndicatorStyle={() => null}
                    fullHeight={this.props.fullHeight}
                    actionsOnLeftSide
                    horizontalScroll
                    referenceDate={this.validOn}
                    hasPrintButton={false}
                />}
            </>
        );
    }
    
    @State.action.bound
    private async loadPanelAsync() {
        await this.loadDefinitionAsync();
        await this.loadOrganizationDataAsync();
    }
    
    @State.action.bound
    private async loadDefinitionAsync() {
        const response = await this.worklistApiAdapter.getPermanentPrescriptionBoundWorklistDefinition();
        State.runInAction(() => {
            this.definition = response.value;
        });
    }

    @State.action.bound
    private async loadOrganizationDataAsync() {
        this.currentOrganizationUnitId = null;
        this.currentHealthcareProfessionIds = [];
        this.relatedOrganizationUnitIds = [];

        if (!this.props._careActivityId)
            return;

        const careActivity = await this.careActivityApiAdapter.loadByIdAsync(this.props._careActivityId);
        if (!careActivity)
            return;

        this.currentOrganizationUnitId = careActivity.pointOfCareId;
        if (this.currentOrganizationUnitId) {
            const healthcareProfessionIdsStore = await this.structureApiAdapter.getHealthCareProfessionIdsByOrganizationUnitIdAsync(this.currentOrganizationUnitId);
            if (healthcareProfessionIdsStore)
                this.currentHealthcareProfessionIds = healthcareProfessionIdsStore.value;
        }

        const organizationUnitIdsStore = await this.structureApiAdapter.getPointOfCareIdsByHealthCareProfessionIdsQueryAsync(this.currentHealthcareProfessionIds);
        this.relatedOrganizationUnitIds = organizationUnitIdsStore?.value ?? [];
    }

    @State.action.bound
    private onFilterStoreCreatedAsync(filterStore: IFilterStore) {
        this.filterStore = new ExtendedPermanentPrescriptionFilterStore(filterStore);
        this.filterStore.setPatientId(this.props._patientId);
        this.selectOrganizationUnitByPointOfCare();
        if (this.props.onFilterStoreCreated) {
            this.props.onFilterStoreCreated(this.filterStore);
        }
        return Promise.resolve(null);
    }

    @State.bound
    private performClientSideActionAsync(action: ClientSideActionDto) {
        if (action instanceof SelectItemClientSideAction) {
            this.props.selectPrescription(new PermanentPrescriptionId(action.entityId.value));
        }
        return Promise.resolve(null);
    }

    @State.action.bound
    private onOrganizationUnitIdsChanged(newValue: OrganizationUnitId, checkedValue: boolean) {
        if (!this.filterStore)
            return;

        if (checkedValue) {
            this.filterStore.addOrganizationUnitId(newValue);
            return;
        }

        this.filterStore.removeOrganizationUnitId(newValue);
    }

    @State.action.bound
    private selectOrganizationUnitByPointOfCare() {
        if (!this.filterStore)
            return;

        this.filterStore.setOrganizationUnitIds([this.currentOrganizationUnitId]);
    }

    @State.action.bound
    private selectOrganizationUnitByProfession() {
        if (!this.filterStore)
            return;

        this.filterStore.setOrganizationUnitIds(this.relatedOrganizationUnitIds);
    }

    @State.action.bound
    private selectFilteredPrescriptions() {
        dispatchAsyncErrors(this.selectFilteredPrescriptionsAsync(), this);
    }

    @State.action.bound
    private async selectFilteredPrescriptionsAsync() {
        const filterStore = this.filterStore;
        if (!filterStore)
            return;

        const prescriptionsToAdd = await this.props._dependencies.prescriptionApiAdapter.searchPermanentPrescriptionsAsync(
            filterStore.generalSearchValue,
            filterStore.medicationSubsidyClaimTypeId,
            filterStore.organizationUnitIds,
            filterStore.patientId,
            LocalDate.today()
        );
        if (!prescriptionsToAdd.value)
            return;

        for (const permanentPrescriptionId of prescriptionsToAdd.value) {
            this.props.selectPrescription(permanentPrescriptionId);
        }
    }

    private renderExtraFilter() {
        return (
            <>
                <Ui.Flex>
                    <Ui.Flex.Item xs={8}>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={8}>
                                <Ui.UniversalEnumSelector
                                    enumOrigin="server"
                                    displayMode="groupedRadioButtons"
                                    enumName={"PrescriptionType"}
                                    enumType={PrescriptionType}
                                    value={this.props.prescriptionType}
                                    onChange={this.props.setPrescriptionType}
                                    automationId="prescriptionTypeSelector" />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <ExtendedPermanentPrescriptionFilter
                            generalSearchValue={this.filterStore.generalSearchValue}
                            medicationSubsidyClaimTypeId={this.filterStore.medicationSubsidyClaimTypeId}
                            onGeneralSearchValueChanged={this.filterStore.setGeneralSearchValue}
                            onMedicationSubsidyClaimTypeIdChanged={this.filterStore.setMedicationSubsidyClaimTypeId}/>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <PrescriptionsToBeCreated
                            items={this.props.selectedItems}
                            removeMedicationItem={this.props.removeSelectedPrescription} />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        <OrganizationSelector
                            value={this.filterStore && this.filterStore.organizationUnitIds}
                            onChange={this.onOrganizationUnitIdsChanged}
                            pointOfCareMode={true} 
                            automationId="organization"
                            />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.Button text={StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionPanel.CareOrganizationUnit} automationId="selectByPointOfCareButton" onClick={this.selectOrganizationUnitByPointOfCare} />
                        <Ui.Button text={StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionPanel.IdenticalProfessionUnits} automationId="selectByProfessionButton" onClick={this.selectOrganizationUnitByProfession} />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        &nbsp;
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3} horizontalContentAlignment="right">
                        <Ui.Button text={StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionPanel.PrescribeFiltered} automationId="selectFilteredPrescriptionsButton" onClick={this.selectFilteredPrescriptions} />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </>
        );
    }
}

export default connect(
    withHisErrorBoundary(PermanentPrescriptionListPanel),
    new DependencyAdapter<IPermanentPrescriptionListPanelProps, IPermanentPrescriptionListPanelDependencies>(container => ({
        careActivityApiAdapter: container.resolve("CareActivityApiAdapter2"),
        prescriptionApiAdapter: container.resolve("PrescriptionApiAdapter"),
        structureApiAdapter: container.resolve("StructureApiAdapter"),
        worklistApiAdapter: container.resolve("HunEHealthWorklistApiAdapter")
    })),
    new CareActivityContextAdapter<IPermanentPrescriptionListPanelProps>(careActivityContext => ({
        _careActivityId: careActivityContext.careActivityId,
        _patientId: careActivityContext.patientId
    }))
);