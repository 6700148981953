exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ContagiousPatientReportFilter_search-container_1SQ9C {\n  background: #f4f7fc;\n  border: solid 1px #00b4ec;\n  padding: 5px 4px 8px;\n  margin: 8px 8px 0px;\n}\n.ContagiousPatientReportFilter_search-item_20tIm {\n  padding: 0px 4px 4px;\n}\n.ContagiousPatientReportFilter_outer-container_4Xm6U {\n  margin-top: 10px;\n}\n.ContagiousPatientReportFilter_outer-container_4Xm6U label {\n  margin: 0px 4px;\n  font-family: PragatiNarrow;\n  font-size: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"search-container": "ContagiousPatientReportFilter_search-container_1SQ9C",
	"searchContainer": "ContagiousPatientReportFilter_search-container_1SQ9C",
	"search-item": "ContagiousPatientReportFilter_search-item_20tIm",
	"searchItem": "ContagiousPatientReportFilter_search-item_20tIm",
	"outer-container": "ContagiousPatientReportFilter_outer-container_4Xm6U",
	"outerContainer": "ContagiousPatientReportFilter_outer-container_4Xm6U"
};