import IStore from "@Toolkit/CommonWeb/Model/IStore";
import { createOperationInfo } from "./OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default abstract class StoreMapper<TStore extends IStore = any, TResponse = any> {
    protected applyOperationInfo(target: TStore, response: TResponse) {
        target.operationInfo = createOperationInfo(response);
    }

    @State.action
    public applyToStore(target: TStore, response: TResponse, isPermissionCheckOnly: boolean = false) {
        this.applyOperationInfo(target, response);
        if (isPermissionCheckOnly === false) {
            this.applyToStoreCore(target, response); // no need to map the empty stores that get returned from a permission check
        }
    }

    protected abstract applyToStoreCore(target: TStore, response: TResponse): void;
}