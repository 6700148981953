import React from "react";
import PropertyBase from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import CheckBox from "@CommonControls/CheckBox";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import PropertyVersionValidity from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PropertyGroupEditableColumn/PropertyVersionValidity";

interface INumericPropertyEditorProps {
    row: PropertyBase;
}

@State.observer
export class NumericPropertyEditor extends React.Component<INumericPropertyEditorProps> {

    @State.computed private get currentValue() {
        return this.props.row.getValue(this.props.row.isTemporal ? LocalDate.today() : undefined);
    }

    private get hasValue() {
        return this.props.row.hasValue;
    }

    @State.action.bound
    private setEnabled(isEnabled: boolean) {
        if (isEnabled) {
            this.setCurrentVersionValue(this.currentValue ?? 0);
        } else {
            this.props.row.clearValue();
        }
    }

    public render() {
        return (
            <Ui.Flex>
                <Ui.Flex.Item sm={2}>
                    <CheckBox value={this.hasValue} onChange={this.setEnabled} automationId={this.props.row.name + "_checkBox"} />
                </Ui.Flex.Item>
                <Ui.Flex.Item sm={10}>
                    <Ui.NumberBox value={this.currentValue} onChange={this.setCurrentVersionValue} disabled={!this.hasValue} automationId={this.props.row.name + "_numberBox"} />
                    <PropertyVersionValidity property={this.props.row} />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.bound
    private setCurrentVersionValue(newValue: number) {
        this.props.row.replaceVersion(newValue, this.props.row.isTemporal ? LocalDate.today() : undefined);
    }
}
