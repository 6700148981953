import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";
import { PermittedFrontendActionPermissionScope } from "./PermittedFrontendActionPermissionScope";

export class PermittedFrontendActionPermissionScopeSet {
    constructor(
        public readonly scopes: PermittedFrontendActionPermissionScope[]
    ) { }

    public areScopesFit(scopes: FrontendActionPermissionScope[]) {

        if (this.scopes.length === 0) {
            return true;
        }

        return this.scopes.every(expectedScope => {
            const actualScope = scopes.find(s => s.type === expectedScope.type);
            return actualScope && expectedScope.isSatisfiedBy(actualScope);
        });
    }
}
