import React from "react";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import { IUniversalCodeSelectorProps } from "@HisPlatformControls/UniversalCodeSelector";
import * as HisUi from "@HisPlatformControls";
import { IPagingState, IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import { IComplexSearchItemGroup } from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchPanel";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicationId from "@Primitives/MedicationId.g";
import IColumnDescriptor from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/IColumnDescriptor";
import SubstanceId from "@Primitives/SubstanceId.g";
import _ from "@HisPlatform/Common/Lodash";
import ReferenceDataApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/ReferenceDataApiAdapter";
import IExtendedMedicationListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/IExtendedMedicationListItem";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";

interface IMedicationCodeSelectorDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    apiAdapter: ReferenceDataApiAdapter;
    medicationRequestReferenceDataStore: MedicationRequestReferenceDataStore;
}

interface IMedicationCodeSelectorProps extends ICodeSelectorCommonProps<MedicationId> {
    _dependencies?: IMedicationCodeSelectorDependencies;
    validOn?: LocalDate;
    medicationTypes?: string[];
    originalMedicationTypes?: string[];
}

@State.observer
class MedicationCodeSelector extends React.Component<IMedicationCodeSelectorProps> {

    @State.observable private readonly medicationSubsidyClaimTypeToMedicationMap = new Map<string, MedicationSubsidyClaimTypeId[]>();

    private get validOn() {
        return this.props.validOn || LocalDate.today();
    }

    private columnDescriptors: IColumnDescriptor[] = [
        {
            columnName: "textAmount"
        } as IColumnDescriptor,
        {
            columnName: "identifiers",
            valueRenderer: this.getIdentifierValue
        } as IColumnDescriptor,
        {
            columnName: "substanceIds",
            valueRenderer: this.getSubstances
        } as IColumnDescriptor,
        {
            columnName: "medicationSubsidyClaimTypeIds",
            valueRenderer: this.getMedicationSubsidyClaimTypes
        } as IColumnDescriptor
    ];

    @State.bound
    private getSubstances(value: SubstanceId | SubstanceId[]) {
        if (Array.isArray(value)) {
            const substanceNames = value.map(i => this.props._dependencies.careReferenceDataStore.substances.get({id: i, validOn: this.validOn})?.name);
            return substanceNames.join(", ");
        }
        return this.props._dependencies.careReferenceDataStore.substances.get({id: value, validOn: this.validOn})?.name;
    }

    @State.bound
    private getIdentifierValue(value: Identifier | Identifier[]) {
        if (Array.isArray(value)) {
            return value.map(i => i.identifierSystemId.value === "TTT" && i.value);
        }
        return value.identifierSystemId.value === "TTT" ? value.value : null;
    }

    @State.bound
    private getMedicationSubsidyClaimTypes(value: MedicationSubsidyClaimTypeId[]) {
        const medicationSubsidyClaimTypeCodes = value.map(i => this.props._dependencies.medicationRequestReferenceDataStore.medicationSubsidyClaimTypes.get(i)?.code);
        return medicationSubsidyClaimTypeCodes.join(", ");
    }

    @State.bound
    private async getDisplayValueAsync(value: MedicationId) {
        const versionSelector = { id: value, validOn: this.validOn };
        await this.props._dependencies.careReferenceDataStore.medications.ensureLoadedAsync([versionSelector]);
        const item = this.props._dependencies.careReferenceDataStore.medications.get(versionSelector);
        const subsidyClaimTypeIds = await this.getOrLoadMedicationClaimTypeIdsAsync(value);
        const subsidyClaimTypeCodes = subsidyClaimTypeIds.map(i => this.props._dependencies.medicationRequestReferenceDataStore.medicationSubsidyClaimTypes.get(i).code);
        return item.name + " " + `(${subsidyClaimTypeCodes.join(", ")})`;
    }

    @State.bound
    private async quickSearchAsync(freeText: string) {
        const result = await this.props._dependencies.apiAdapter.searchMedicationAsync(
            freeText,
            10,
            this.validOn,
            this.props.medicationTypes,
            this.props.originalMedicationTypes
        );
        result.value.forEach(i => this.medicationSubsidyClaimTypeToMedicationMap.set(i.id.value, i.medicationSubsidyClaimTypeIds));
        return result.value.map(i => i.id);
    }

    @State.bound
    private async loadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: IExtendedMedicationListItem[], selectedGroup: IComplexSearchItemGroup) {
        const defaultOrdering = {
            columnId: "name",
            direction: "asc"
        } as IOrderingState;

        const response = await this.props._dependencies.apiAdapter.medicationSelectorQueryAsync(
            filterText,
            this.props.medicationTypes,
            this.props.originalMedicationTypes,
            ordering.columnId === "code" ? defaultOrdering : ordering,
            paging);

        response.value.items.forEach(i => this.medicationSubsidyClaimTypeToMedicationMap.set(i.id.value, i.medicationSubsidyClaimTypeIds));

        const substanceVersionSelectors = _.flatten(response.value.items.map(i => i.substanceIds)).map(i => ({id: i, validOn: this.validOn}));
        const medicationSubsidyClaimTypeIds = _.flatten(response.value.items.map(i => i.medicationSubsidyClaimTypeIds));

        await this.props._dependencies.careReferenceDataStore.substances.ensureLoadedAsync(substanceVersionSelectors);
        await this.props._dependencies.medicationRequestReferenceDataStore.medicationSubsidyClaimTypes.ensureLoadedAsync(medicationSubsidyClaimTypeIds);

        return {
            totalCount: response.value.totalCount,
            items: response.value.items
        };
    }

    @State.bound
    private async getOrLoadMedicationClaimTypeIdsAsync(medicationId: MedicationId) {
        if (!this.medicationSubsidyClaimTypeToMedicationMap.has(medicationId.value)) {
            if (this.props.value instanceof MedicationId) {
                const medication = await this.getMedicationByIdAsync(this.props.value);
                this.medicationSubsidyClaimTypeToMedicationMap.set(medication.id.value, medication.medicationSubsidyClaimTypeIds);
            }
        }
        return this.medicationSubsidyClaimTypeToMedicationMap.get(medicationId.value);
    }

    @State.bound
    private onComplexSearchSingleSelect(item: IExtendedMedicationListItem) {
        this.props.onChange(item.id);
    }

    @State.bound
    private async getMedicationByIdAsync(medicationId: MedicationId) {
        const response = await this.props._dependencies.apiAdapter.getExtendedMedicationByIdAsync({id: medicationId, validOn: this.validOn});
        return response.value;
    }

    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            nameGetter: "name",
            onQuickSearchAsync: this.quickSearchAsync,
            complexSearchLoadAsync: this.loadAsync,
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            hasComplexSearch: true,
            columnDescriptors: this.columnDescriptors
        } as IUniversalCodeSelectorProps<MedicationId, IExtendedMedicationListItem>;
        return (
            <>
                <HisUi.UniversalCodeSelector
                    {...props}
                    automationId={this.props.automationId}
                />
            </>
        );
    }
}

export default connect(
    MedicationCodeSelector,
    new DependencyAdapter<IMedicationCodeSelectorProps, IMedicationCodeSelectorDependencies>((container) => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            apiAdapter: container.resolve("ReferenceDataApiAdapter"),
            medicationRequestReferenceDataStore: container.resolve("MedicationRequestReferenceDataStore")
        };
    })
);