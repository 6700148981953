import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import prescriptionStandaloneRouteDefinitions from "./PrescriptionRoutes";
import PrescriptionsStandaloneHost from "./PrescriptionsStandaloneHost";

export default function configurePrescriptionUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            HunEHealthInfrastructureUseCases.recordPrescription,
            builder => {
                builder.standalone(b => b
                    .routeDefinition(prescriptionStandaloneRouteDefinitions.prescriptions)
                    .component(PrescriptionsStandaloneHost)
                );
            }
        )
    );
}
