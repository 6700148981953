import {IModalParams} from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import OrganizationUnitDefinitionId from "@Primitives/OrganizationUnitDefinitionId.g";

export interface IOrganizationUnitDefinitionSelectorDialogResult  {
    result: OrganizationUnitDefinitionId;
}

export interface IOrganizationUnitDefinitionSelectorDialogParams extends IModalParams {
    organizationUnitDefinitionIds: OrganizationUnitDefinitionId[];
}

export default class OrganizationUnitDefinitionSelectorDialogParams implements IOrganizationUnitDefinitionSelectorDialogParams {
    public static type = "OrganizationUnitDefinitionSelectorDialog";

    public get type() { return OrganizationUnitDefinitionSelectorDialogParams.type; }

    constructor(public organizationUnitDefinitionIds: OrganizationUnitDefinitionId[]) {
    }
}
