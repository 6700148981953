import React from "react";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import MedicationFilterStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/MedicationListPanel/Filter/MedicationFilterStore";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import ExtendedMedicationFilter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/MedicationListPanel/Filter/ExtendedMedicationFilter";
import SelectItemClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/SelectItemClientSideAction";
import { IRowIndicatorStyle } from "@CommonControls/DataGrid/IDataGridProps";
import MedicationId from "@Primitives/MedicationId.g";
import Styles from "./MedicationListPanel.less";
import NDataGrid from "@HisPlatform/BoundedContexts/Productivity/Components/NDataGrid/NDataGrid";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IWorklistRow from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/IWorklistRow";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import { getMedicationRowStyle } from "./MedicationRowStylesHelper";
import { IMedicationEquivalenceListItem } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationInfo/IMedicationEquivalence";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { PrescriptionType } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationSelectorDialog/MedicationSelectorDialog";
import * as Ui from "@CommonControls";
import PrescriptionsToBeCreated from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionsToBeCreated/PrescriptionsToBeCreated";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import { IPrescriptionsToBeCreatedListItem } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionsToBeCreated/IPrescriptionsToBeCreatedListItem";

interface IMedicationListPanelDependencies {
    apiAdapter: WorklistApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
    medicationRequestReferenceDataStore: MedicationRequestReferenceDataStore;
}

export interface IMedicationListPanelProps {
    _dependencies?: IMedicationListPanelDependencies;

    selectedRowId: string;
    useCaseState: INDataUseCaseState;
    onChange: (rowId: string, useCaseState: INDataUseCaseState) => void;
    onRowClick?: (row: any, rowId: React.ReactText, rowIndex: number) => void;
    onSelectMedication?: (id: MedicationId) => void;
    onFilterStoreCreated?: (store: MedicationFilterStore) => void;
    fullHeight?: boolean;
    variant: "prescription" | "standalone" | "equipment";
    debug?: boolean;
    navigateToEquivalentMedication?: (medicationId: MedicationId, useCase: UseCaseIdentifier) => void;
    prescriptionType?: PrescriptionType;
    setPrescriptionType?: (prescriptionType: PrescriptionType) => void;
    selectedMedications?: IPrescriptionsToBeCreatedListItem[];
    onRemoveMedicationFromSelectedMedications?: (row: IPrescriptionsToBeCreatedListItem) => void;
    validOn?: LocalDate;
    addMedicationFormulaPrescription?: () => void;
}

@State.observer
class MedicationListPanel extends React.Component<IMedicationListPanelProps> {

    @State.observable private definition: IWorklistDefinition = null;
    @State.observable.ref private filterStore: MedicationFilterStore = null;
    @State.observable.ref private isMedicationEquipmentClassificationTreeGridOpen = false;
    private get apiAdapter() { return this.props._dependencies.apiAdapter; }
    @State.observable.ref private searching: boolean = false;
    @State.observable.ref private selectedMedicationEquivalentId: MedicationId = null;
    @State.observable private medicationType: PrescriptionType.Medication | PrescriptionType.Equipment = PrescriptionType.Medication;

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadPanelAsync);

    private get validOn() {
        return this.props.validOn || LocalDate.today();
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IMedicationListPanelProps) {
        if (this.props.selectedRowId !== prevProps.selectedRowId) {
            dispatchAsyncErrors(this.loadMedicationAsync(), this);
        }
    }

    public componentWillUnmount() {
        if (this.filterStore) {
            this.filterStore.clearAllFilters();
        }
    }

    @State.computed private get medicationName() {
        return this.props.selectedRowId && this.props._dependencies.careReferenceDataStore.medications.get({ id: new MedicationId(this.props.selectedRowId), validOn: this.validOn })?.name;
    }

    private async loadMedicationAsync() {
        if (this.props.selectedRowId) {
            await this.props._dependencies.careReferenceDataStore.medications.ensureLoadedAsync([{ id: new MedicationId(this.props.selectedRowId), validOn: this.validOn }]);
        }
    }
    @State.action.bound
    private async loadPanelAsync() {
        if (this.props.variant === "equipment") {
            this.setMedicationType(PrescriptionType.Equipment);
        }
        await this.loadDefinitionAsync();
        await this.loadMedicationAsync();
    }
    @State.action.bound
    private async loadDefinitionAsync() {
        let response: SimpleStore<IWorklistDefinition> = null;
        if (this.props.variant === "prescription" || this.props.variant === "equipment") {
            response = await this.apiAdapter.getMedicationBoundWorklistDefinition();
        } else {
            response = await this.apiAdapter.getStandaloneMedicationBoundWorklistDefinition();
        }
        State.runInAction(() => {
            this.definition = response.value;
        });

        await this.loadReferenceDataAsync();
    }

    @State.bound
    private async loadReferenceDataAsync() {
        await this.props._dependencies.medicationRequestReferenceDataStore.orderTypes.ensureLoadedAsync();
        await this.props._dependencies.medicationRequestReferenceDataStore.targetValues.ensureLoadedAsync();
    }

    @State.bound
    private getExtendedFilterDescriptors() {
        return MedicationFilterStore.getFilterDescriptors();
    }

    @State.action.bound
    private onFilterStoreCreatedAsync(filterStore: IFilterStore) {
        this.filterStore = new MedicationFilterStore(filterStore);
        if (this.props.onFilterStoreCreated) {
            this.props.onFilterStoreCreated(this.filterStore);
        }
        return Promise.resolve();
    }

    @State.action.bound
    private setIsMedicationEquipmentClassificationTreeGridOpenOpen() {
        this.isMedicationEquipmentClassificationTreeGridOpen = !this.isMedicationEquipmentClassificationTreeGridOpen;
    }

    @State.action.bound
    private setMedicationType(value: PrescriptionType.Medication | PrescriptionType.Equipment) {
        this.medicationType = value;
        this.props.setPrescriptionType(value);
    }

    private getPrescriptionTypeDisplayValue(item: PrescriptionType) {
        switch (item) {
            case PrescriptionType.Medication:
                return StaticHunSocialSecurityMedicationRequestResources.MedicationListPanel.Medication;
            case PrescriptionType.Equipment:
                return StaticHunSocialSecurityMedicationRequestResources.MedicationListPanel.Equipment;
            default:
                return null;
        }
    }

    @State.bound
    private renderExtendedFilter() {
        return (
            <ExtendedMedicationFilter
                generalSearch={this.filterStore.generalSearch}
                onGeneralSearchChange={this.filterStore.setGeneralSearch}
                onIsTypeGySeChange={this.filterStore.setIsTypeGyse}

                medicationEquipmentClassificationStructure={this.filterStore.medicationEquipmentClassificationStructure}
                setMedicationEquipmentClassificationStructure={this.filterStore.setMedicationEquipmentClassificationStructure}
                onNodeCheck={this.filterStore.setIsoCodes}

                isMedicationEquipmentClassificationTreeGridOpen={this.isMedicationEquipmentClassificationTreeGridOpen}
                setIsMedicationEquipmentClassificationTreeGridOpen={this.setIsMedicationEquipmentClassificationTreeGridOpenOpen}
                medicationSubsidyClaimTypeId={this.filterStore.medicationSubsidyClaimTypeId}

                onMedicationSubsidyClaimTypeIdChange={this.filterStore.setMedicationSubsidyClaimTypeId}
                prescriptionType={this.props.prescriptionType}
                medicationTypeStore={this.props._dependencies.careReferenceDataStore.medicationType}

                setMedicationType={this.filterStore.setMedicationType}
                MedicationType={this.filterStore.medicationType}
            />
        );
    }

    @State.bound
    private renderExtraFilter() {
        if (this.props.variant === "prescription") {
            return (
                <Ui.Flex>
                    <Ui.Flex.Item xs={8}>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={8}>
                                <Ui.UniversalEnumSelector
                                    enumOrigin="server"
                                    displayMode="groupedRadioButtons"
                                    enumName={"PrescriptionType"}
                                    enumType={PrescriptionType}
                                    value={this.props.prescriptionType}
                                    onChange={this.props.setPrescriptionType}
                                    automationId="prescriptionTypeSelector"
                                />
                            </Ui.Flex.Item>
                            {this.props.addMedicationFormulaPrescription &&
                                <Ui.Flex.Item xs={this.props.prescriptionType === PrescriptionType.Equipment ? 2 : 4}>
                                    <Ui.AddButton
                                        size={"compact"}
                                        automationId={"addMedicationFormulaPrescription"}
                                        onClick={this.props.addMedicationFormulaPrescription}
                                        text={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Buttons.AddMedicationFormula}
                                        style={{ margin: "0", padding: "4px 3px 4px 3px", height: "100%" }} />
                                </Ui.Flex.Item>
                            }
                            {this.props.prescriptionType === PrescriptionType.Equipment &&
                                <Ui.Flex.Item xs={2}>
                                    <Ui.CheckBox
                                        automationId="showInvalidatedMedicationsCheckBox"
                                        displayMode={"switch"}
                                        verticalAlign={"normal"}
                                        label={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Labels.IncludeInvalidated}
                                        value={this.filterStore.includeInvalidatedProducts}
                                        onChange={this.filterStore.setIncludeInvalidatedProducts} />
                                </Ui.Flex.Item>
                            }
                        </Ui.Flex>
                        {this.renderExtendedFilter()}
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <PrescriptionsToBeCreated
                            items={this.props.selectedMedications}
                            removeMedicationItem={this.props.onRemoveMedicationFromSelectedMedications} />
                    </Ui.Flex.Item>
                </Ui.Flex >
            );
        }

        return (
            <Ui.Flex>
                <Ui.Flex.Item xs={12}>
                    {this.props.variant === "standalone" && <Ui.RadioButtonGroup
                        items={[PrescriptionType.Medication, PrescriptionType.Equipment]}
                        value={this.medicationType}
                        onChange={this.setMedicationType}
                        displayType="groupedButtons"
                        getDisplayValue={this.getPrescriptionTypeDisplayValue}
                    />}
                    {this.renderExtendedFilter()}
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.action.bound
    private onDataLoaded(data: IPagedItems<IWorklistRow>) {
        if (this.props.selectedRowId && !data.items.some(i => i.__id === this.props.selectedRowId) && this.props.onRowClick) {
            this.props.onRowClick(null, null, null);
        }
        if (this.searching) {
            if (!isNullOrUndefined(this.props.navigateToEquivalentMedication)) {
                this.props.navigateToEquivalentMedication(this.selectedMedicationEquivalentId, new UseCaseIdentifier(HunEHealthInfrastructureUseCases.medicationInfo));
                this.searching = false;
            }
        }
    }

    @State.bound
    private performClientSideActionAsync(action: ClientSideActionDto) {
        if (action instanceof SelectItemClientSideAction) {
            this.props.onSelectMedication(new MedicationId(action.entityId.value));
        }
        return Promise.resolve(null);
    }

    @State.bound
    private getRowIndicatorStyle(row: any): IRowIndicatorStyle {
        if (this.filterStore.medicationSubsidyClaimTypeId) {
            return getMedicationRowStyle(row["MedicationBased_MedicationSubsidyClassification"], Styles.rowBold);
        }
        return null;
    }

    @State.action.bound
    private onClick(medication: IMedicationEquivalenceListItem) {
        this.filterStore.setGeneralSearch(medication.identifier.value);
        this.searching = true;
        this.selectedMedicationEquivalentId = medication.id;
    }

    @State.bound
    private getPanelProps() {
        return {
            onClick: this.onClick
        };
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }

        return this.props.variant === "prescription" || this.props.variant === "equipment" ? (
            <>
                {this.definition && <NDataGrid
                    definition={this.definition}
                    defaultExtraFilterVisibility={true}
                    useCaseState={this.props.useCaseState}
                    onChange={this.props.onChange}
                    selectedRowId={this.props.selectedRowId}
                    onRowClick={this.props.onRowClick}
                    defaultPageSize={10}
                    onGetExtendedFilterDescriptors={this.getExtendedFilterDescriptors}
                    onFilterStoreCreatedAsync={this.onFilterStoreCreatedAsync}
                    extraFilter={this.filterStore && this.renderExtraFilter()}
                    rowBodyHeight={400}
                    onPerformClientSideActionAsync={this.performClientSideActionAsync}
                    getRowIndicatorStyle={this.getRowIndicatorStyle}
                    onDataLoaded={this.onDataLoaded}
                    fullHeight={this.props.fullHeight}
                    detailTitle={this.medicationName}
                    actionsOnLeftSide
                    horizontalScroll
                    referenceDate={this.validOn}
                    hasPrintButton={false}
                />}
            </>
        ) : (
            this.definition && <NDataPanel
                onGetPanelProps={this.getPanelProps}
                definition={this.definition}
                defaultExtraFilterVisibility={true}
                useCaseState={this.props.useCaseState}
                onChange={this.props.onChange}
                selectedRowId={this.props.selectedRowId}
                onRowClick={this.props.onRowClick}
                defaultPageSize={10}
                onGetExtendedFilterDescriptors={this.getExtendedFilterDescriptors}
                onFilterStoreCreatedAsync={this.onFilterStoreCreatedAsync}
                extraFilter={this.filterStore && this.renderExtraFilter()}
                rowBodyHeight={400}
                onPerformClientSideActionAsync={this.performClientSideActionAsync}
                getRowIndicatorStyle={this.getRowIndicatorStyle}
                onDataLoaded={this.onDataLoaded}
                detailTitle={this.medicationName}
                actionsOnLeftSide
                horizontalScroll
                referenceDate={this.validOn}
                disableDetailStrictMode
                hasPrintButton={false}
                iconName="search_medicine"
            />
        );
    }
}

export default connect(
    withHisErrorBoundary(MedicationListPanel),
    new DependencyAdapter<IMedicationListPanelProps, IMedicationListPanelDependencies>(container => {
        return {
            apiAdapter: container.resolve("WorklistApiAdapter"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            medicationRequestReferenceDataStore: container.resolve("MedicationRequestReferenceDataStore")
        };
    })
);
