import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IMedicationSelectorDialogResult, IMedicationSelectorDialogParams } from "./MedicationSelectorDialogParams";
import MedicationId from "@Primitives/MedicationId.g";
import MedicationListPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/MedicationListPanel/MedicationListPanel";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import MedicationSubsidyOptionList from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/PrescriptionListPanel/MedicationSubsidyOptionList";
import ReferenceDataApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/ReferenceDataApiAdapter";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import MedicationSubsidy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationSubsidy";
import MedicationPricingAndSubsidies from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationPricingAndSubsidies";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import MedicationEquivalenceListNDataPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/MedicationEquivalenceListNDataPanel/MedicationEquivalenceListNDataPanel";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IExtensionPointProps } from "@PluginInterface/ExtensionPoint/IExtensionPointProps";
import MedicationFilterStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/MedicationListPanel/Filter/MedicationFilterStore";
import _ from "@HisPlatform/Common/Lodash";
import MedicationsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicationsApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import TextualPrescriptionTemplateList from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/TextualPrescriptionTemplateMasterDetailPanel/TextualPrescriptionTemplateList";
import ITextualPrescriptionTemplateListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/ITextualPrescriptionTemplateListItem";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";
import PrescriptionTemplateType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionTemplateType";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import SubsidyOptionCheckResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/SubsidyOptionCheckResult";
import PricingAndSubsidyLoader from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/PricingAndSubsidyLoader";
import ISearchParametersService from "@Toolkit/CommonWeb/SearchParametersService/Definition/ISearchParametersService";
import PermanentPrescriptionListPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/PermanentPrescriptionListPanel/PermanentPrescriptionListPanel";
import PermanentPrescriptionId from "@Primitives/PermanentPrescriptionId.g";
import { IPrescriptionsToBeCreatedListItem } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionsToBeCreated/IPrescriptionsToBeCreatedListItem";
import { MedicationBasedPrescriptionsToBeCreatedListItem } from "./MedicationBasedPrescriptionsToBeCreatedListItem";
import { PermanentPrescriptionBasedPrescriptionsToBeCreatedListItem } from "./PermanentPrescriptionBasedPrescriptionsToBeCreatedListItem";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import MedicationSubsidyOptionCheckType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/ReferenceData/Prescription/Enum/MedicationSubsidyOptionCheckType.g";

interface IMedicationSelectorDialogDependencies {
    referenceDataAdapter: ReferenceDataApiAdapter;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    medicationRequestReferenceDataStore: MedicationRequestReferenceDataStore;
    careReferenceDataStore: CareReferenceDataStore;
    medicationsApiAdapter: MedicationsApiAdapter;
    prescriptionApiAdapter: PrescriptionApiAdapter;
    notificationService: INotificationService;
    pricingAndSubsidyLoader: PricingAndSubsidyLoader;
    searchParametersService: ISearchParametersService;
}

interface IMedicationSelectorDialogProps extends IModalComponentParams<IMedicationSelectorDialogResult>, IMedicationSelectorDialogParams {
    _dependencies?: IMedicationSelectorDialogDependencies;
}

export enum PrescriptionType {
    Medication,
    Equipment,
    TextualFormula,
    OtherTextual,
    Permanent
}

/** @screen */
@State.observer
class MedicationSelectorDialog extends React.Component<IMedicationSelectorDialogProps> {

    @State.observable private selectedRowId: string = null;
    @State.observable private useCaseState: INDataUseCaseState = null;
    @State.observable private equivalenceListSelectedRowId: string = null;
    @State.observable private equivalenceListUseCaseState: INDataUseCaseState = null;
    @State.observable.ref public medicationReferenceData: MedicationPricingAndSubsidies = null;
    @State.observable.ref public filterStore: MedicationFilterStore = null;
    @State.observable private selectedItems: IPrescriptionsToBeCreatedListItem[] = [];

    private get referenceDataAdapter() { return this.props._dependencies.referenceDataAdapter; }
    private get organizationReferenceDataStore() { return this.props._dependencies.organizationReferenceDataStore; }
    private get medicationRequestReferenceDataStore() { return this.props._dependencies.medicationRequestReferenceDataStore; }
    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get prescriptionApiAdapter() { return this.props._dependencies.prescriptionApiAdapter; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get pricingAndSubsidyLoader() { return this.props._dependencies.pricingAndSubsidyLoader; }

    private get validOn() {
        return this.props.validOn || LocalDate.today();
    }

    @State.observable public prescriptionType: PrescriptionType = PrescriptionType.Medication;

    @State.observable.ref public textualPrescriptionTemplateList: ITextualPrescriptionTemplateListItem[] = [];

    @State.observable.ref private subsidyOptionCheckResults: SubsidyOptionCheckResult[] = [];

    @State.computed
    private get selectedMedications(): MedicationBasedPrescriptionsToBeCreatedListItem[] {
        const items = [];
        for (const item of this.selectedItems) {
            if (item instanceof MedicationBasedPrescriptionsToBeCreatedListItem) {
                items.push(item);
            }
        }

        return items;
    }

    @State.computed
    private get selectedPermanentPrescriptions(): PermanentPrescriptionBasedPrescriptionsToBeCreatedListItem[] {
        const items = [];
        for (const item of this.selectedItems) {
            if (item instanceof PermanentPrescriptionBasedPrescriptionsToBeCreatedListItem) {
                items.push(item);
            }
        }

        return items;
    }

    @State.action.bound
    public setTextualPrescriptionTemplateList(textualPrescriptionTemplateList: ITextualPrescriptionTemplateListItem[]) {
        this.textualPrescriptionTemplateList = textualPrescriptionTemplateList;
    }

    @State.action.bound
    public setPrescriptionType(prescriptionType: PrescriptionType) {
        this.prescriptionType = prescriptionType;
        this.loadTextualPrescriptionTemplates(prescriptionType);
    }

    @State.action.bound
    private setSubsidyOptionCheckResults(optionCheckResults: SubsidyOptionCheckResult[]) {
        this.subsidyOptionCheckResults = optionCheckResults;
    }

    @State.computed.valueWrapper
    public get selectedMedicationId() {
        return this.selectedRowId ? new MedicationId(this.selectedRowId) : null;
    }

    @State.computed.valueWrapper
    public get selectedClaimTypeId() {
        return this.filterStore?.medicationSubsidyClaimTypeId;
    }

    @State.action.bound
    public selectMedicationId(row: any, rowId: string) {
        this.selectedRowId = rowId;
        if (rowId) {
            this.loadMedicationReferenceDataAsync(new MedicationId(rowId));
        }
    }

    @State.action.bound
    public selectEquivalenceListMedicationId(row: any, rowId: string) {
        this.equivalenceListSelectedRowId = rowId;
        if (rowId) {
            this.loadMedicationReferenceDataAsync(new MedicationId(rowId));
        }
    }

    @State.action.bound
    public onSelectMedications(id: MedicationId) {
        if (this.selectedMedications.find((medication) => ValueWrapper.equals(medication.medicationId, id))) {
            return;
        }

        dispatchAsyncErrors(this.addMedicationToSelectedAsync(id), this);
    }

    @State.bound
    private loadTextualPrescriptionTemplates(type: PrescriptionType) {
        if (type === PrescriptionType.TextualFormula || type === PrescriptionType.OtherTextual) {
            dispatchAsyncErrors(this.loadTextualPrescriptionTemplatesAsync(type), this);
        } else {
            this.setTextualPrescriptionTemplateList([]);
        }
    }

    @State.bound
    private async loadTextualPrescriptionTemplatesAsync(type: PrescriptionType) {
        let typeFilter: PrescriptionTemplateType = null;
        if (type === PrescriptionType.TextualFormula) {
            typeFilter = PrescriptionTemplateType.Formula;
        } else if (type === PrescriptionType.OtherTextual) {
            typeFilter = PrescriptionTemplateType.Other;
        }
        const textualPrescriptionTemplates = await this.prescriptionApiAdapter.getTextualPrescriptionTemplatesAsync();
        this.setTextualPrescriptionTemplateList(textualPrescriptionTemplates.value?.filter(i => i.type === typeFilter) || []);
    }

    @State.action.bound
    private async addMedicationToSelectedAsync(id: MedicationId) {
        const medicationResponse = await this.props._dependencies.medicationsApiAdapter.getMedicationVersionsBySelectorsAsync([{ id, validOn: this.validOn }]);
        const pricingResponse = await this.referenceDataAdapter.getMedicationPricingAndSubsidiesAsync(id, this.validOn);
        const hasNormativeClaimType = pricingResponse?.value?.pricing?.claimTypes?.find(i => MedicationSubsidyClaimTypeId.N.value === this.medicationRequestReferenceDataStore.medicationSubsidyClaimTypes.get(i).code);
        State.runInAction(() => {
            this.selectedItems.push(new MedicationBasedPrescriptionsToBeCreatedListItem(
                medicationResponse.value[0].name,
                (isNullOrUndefined(this.filterStore.medicationSubsidyClaimTypeId) && !isNullOrUndefined(hasNormativeClaimType)) ?
                    hasNormativeClaimType :
                    this.filterStore.medicationSubsidyClaimTypeId,
                medicationResponse.value[0].id,
                null,
                false
            ));
        });
    }

    @State.action.bound
    private addTextualPrescription(textualPrescriptionTemplate: ITextualPrescriptionTemplateListItem) {
        this.selectedItems.push(new MedicationBasedPrescriptionsToBeCreatedListItem(
            textualPrescriptionTemplate.name,
            null,
            null,
            textualPrescriptionTemplate.id,
            false
        ));
    }
    @State.action.bound
    private addMedicationFormulaPrescription() {
        this.selectedItems.push(new MedicationBasedPrescriptionsToBeCreatedListItem(
            StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.MedicationFormulaName,
            new MedicationSubsidyClaimTypeId("1"),
            null,
            null,
            true
        ));
    }

    @State.action.bound
    private async addPermanentPrescriptionToSelectedAsync(id: PermanentPrescriptionId) {
        const store = await this.props._dependencies.prescriptionApiAdapter.getPermanentPrescriptionInfoQueryAsync(id);
        if (!store.value) {
            return;
        }

        State.runInAction(() => this.selectedItems.push(new PermanentPrescriptionBasedPrescriptionsToBeCreatedListItem(
            id,
            store.value.displayName,
            store.value.claimTypeId
        )));
    }

    @State.action.bound
    private setUseCaseState(rowId: string, useCaseState: INDataUseCaseState) {
        if (this.selectedRowId === rowId && _.isEqual(this.useCaseState, useCaseState)) {
            this.useCaseState = null;
            this.selectedRowId = null;
        } else {
            this.useCaseState = useCaseState;
            this.selectedRowId = rowId;
        }
    }

    @State.action.bound
    private setEquivalenceListUseCaseState(rowId: string, useCaseState: INDataUseCaseState) {
        if (this.equivalenceListSelectedRowId === rowId && _.isEqual(this.equivalenceListUseCaseState, useCaseState)) {
            this.equivalenceListUseCaseState = null;
            this.equivalenceListSelectedRowId = null;
        } else {
            this.equivalenceListUseCaseState = useCaseState;
            this.equivalenceListSelectedRowId = rowId;
        }
    }

    @State.action.bound
    public setMedicationReferenceData(medicationReferenceData: MedicationPricingAndSubsidies) {
        this.medicationReferenceData = medicationReferenceData;
    }

    @State.action.bound
    public setFilterStore(filterStore: MedicationFilterStore) {
        this.filterStore = filterStore;
    }

    @State.bound
    private async loadMedicationReferenceDataAsync(medicationId: MedicationId) {
        const medicationReferenceData = await this.referenceDataAdapter.getMedicationPricingAndSubsidiesAsync(medicationId, this.validOn);
        await this.organizationReferenceDataStore.professionalExamMap.ensureAllLoadedAsync();
        await this.medicationRequestReferenceDataStore.medicationSubsidyClaimTypes.ensureAllLoadedAsync();
        await this.medicationRequestReferenceDataStore.organizationRestrictionTypes.ensureLoadedAsync();
        await this.medicationRequestReferenceDataStore.medicationSubsidizedPricingTypes.ensureLoadedAsync();
        this.setMedicationReferenceData(medicationReferenceData.value);
        await this.pricingAndSubsidyLoader.loadReferenceDataAsync(medicationReferenceData.value, this.validOn);
    }

    @State.bound
    private ok() {
        const selectedMedications = this.selectedMedications;
        const medicationAndClaimTypeIdsIds = selectedMedications.filter(i => !isNullOrUndefined(i.medicationId)).map(medication => ({ medicationId: medication.medicationId, claimTypeId: medication.claimType }));
        const templateIds = selectedMedications.filter(i => !isNullOrUndefined(i.templateId)).map(medication => medication.templateId);
        const medicalFormulas = selectedMedications.filter(i => i.isMedicalFormula).map(medication => ({ name: medication.name, claimTypeId: medication.claimType, isMedicalFormula: medication.isMedicalFormula }));
        const permanentPrescriptionIds = this.selectedPermanentPrescriptions.map(i => i.permanentPrescriptionId);

        if (medicationAndClaimTypeIdsIds.length === 0 && templateIds.length === 0 && medicalFormulas.length === 0 && permanentPrescriptionIds.length === 0) {
            this.props._dependencies.notificationService.error(StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Messages.MedicationNeededError);
        } else {
            this.props.onClose({
                medicationAndClaimTypeIds: medicationAndClaimTypeIdsIds,
                templateIds: templateIds,
                medicalFormulas: medicalFormulas,
                permanentPrescriptionIds: permanentPrescriptionIds
            } as IMedicationSelectorDialogResult);
        }
    }

    @State.bound
    private onFilterStoreCreated(store: MedicationFilterStore) {
        this.setFilterStore(store);

        const searchTerm = this.props._dependencies.searchParametersService.get("searchTerm", null);
        if (!!searchTerm) {
            store.setGeneralSearch(searchTerm);
        }
    }

    @State.action.bound
    public removeSelectedItem(row: IPrescriptionsToBeCreatedListItem) {
        this.selectedItems = this.selectedItems.filter(i => i !== row);
    }

    @State.action.bound
    public renderHeaderToolbar() {
        return <Ui.Button onClick={this.ok} text={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Buttons.createPrescriptions} visualStyle="primary" float="right" automationId="okButton" />;
    }
    public render() {
        return (
            <>
                <HisUi.ContextAwareModal
                    toolbar={this.renderHeaderToolbar}
                    title={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.ModalTitle}
                    isOpen
                    onClose={this.cancel}
                    size="fullscreen">
                    <LoadingBoundary>
                        <Ui.Modal.Body>
                            {(this.prescriptionType === PrescriptionType.Medication || this.prescriptionType === PrescriptionType.Equipment) && (
                                <>
                                    <MedicationListPanel
                                        selectedRowId={this.selectedRowId}
                                        useCaseState={this.useCaseState}
                                        onChange={this.setUseCaseState}
                                        onRowClick={this.selectMedicationId}
                                        onSelectMedication={this.onSelectMedications}
                                        onFilterStoreCreated={this.onFilterStoreCreated}
                                        variant="prescription"
                                        fullHeight
                                        prescriptionType={this.prescriptionType}
                                        setPrescriptionType={this.setPrescriptionType}
                                        selectedMedications={this.selectedItems}
                                        onRemoveMedicationFromSelectedMedications={this.removeSelectedItem}
                                        validOn={this.validOn}
                                        addMedicationFormulaPrescription={this.addMedicationFormulaPrescription}
                                    />
                                    {!!this.selectedRowId && <Ui.GroupBox title={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.EquivalentProducts}>
                                        <MedicationEquivalenceListNDataPanel
                                            medicationId={this.selectedMedicationId}
                                            claimTypeId={this.selectedClaimTypeId}
                                            subsidies={this.medicationReferenceData?.subsidies}
                                            onSelectMedication={this.onSelectMedications}
                                            selectedRowId={this.equivalenceListSelectedRowId}
                                            useCaseState={this.equivalenceListUseCaseState}
                                            onChange={this.setEquivalenceListUseCaseState}
                                            onRowClick={this.selectEquivalenceListMedicationId}
                                            validOn={this.validOn}
                                        />
                                    </Ui.GroupBox>}
                                    {!!this.selectedRowId && <Ui.GroupBox title={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.GroupTitles.SubsidyOptionRestrictions}>
                                        {this.medicationReferenceData?.subsidies?.some(s => s.options?.length > 0) ?
                                            this.medicationReferenceData.subsidies.map(s => this.renderSubsidy(s))
                                            : StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.NoSubsidyOptionsMessage}
                                    </Ui.GroupBox>}
                                </>
                            )}
                            {(this.prescriptionType === PrescriptionType.TextualFormula || this.prescriptionType === PrescriptionType.OtherTextual) && (
                                <TextualPrescriptionTemplateList
                                    isTypeFilterable={false}
                                    onBack={undefined}
                                    list={this.textualPrescriptionTemplateList}
                                    onSelect={this.addTextualPrescription}
                                    variant="prescription"
                                    prescriptionType={this.prescriptionType}
                                    setPrescriptionType={this.setPrescriptionType}
                                    selectedMedications={this.selectedItems}
                                    onRemoveMedicationFromSelectedMedications={this.removeSelectedItem}
                                    medicationSubsidyClaimTypeId={this.filterStore.medicationSubsidyClaimTypeId}
                                    onMedicationSubsidyClaimTypeIdChange={this.filterStore.setMedicationSubsidyClaimTypeId}
                                    generalSearchValue={this.filterStore.generalSearch}
                                    setGeneralSearch={this.filterStore.setGeneralSearch}
                                    addMedicationFormulaPrescription={this.addMedicationFormulaPrescription}
                                />
                            )}
                            {(this.prescriptionType === PrescriptionType.Permanent) && (
                                <PermanentPrescriptionListPanel
                                    useCaseState={this.equivalenceListUseCaseState}
                                    onChange={this.setEquivalenceListUseCaseState}
                                    fullHeight={false}
                                    validOn={this.validOn}
                                    prescriptionType={this.prescriptionType}
                                    setPrescriptionType={this.setPrescriptionType}
                                    selectedItems={this.selectedItems}
                                    selectPrescription={this.selectPermanentPrescription}
                                    removeSelectedPrescription={this.removeSelectedItem} />
                            )}
                        </Ui.Modal.Body>
                        <Ui.Modal.Footer>
                            <Ui.Button onClick={this.cancel} visualStyle={"flat"} text={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Buttons.cancel} float="left" automationId="cancelButton" />
                        </Ui.Modal.Footer>
                    </LoadingBoundary>
                </HisUi.ContextAwareModal>
                <HisUi.HisHeaderPortal>
                    <HisPlatformExtensionPoint
                        type="curativeSupplyPassInfoExtension"
                        extensionProps={{} as IExtensionPointProps}
                    >
                        <></>
                    </HisPlatformExtensionPoint>
                </HisUi.HisHeaderPortal>
            </>
        );
    }

    @State.bound
    private renderSubsidy(subsidy: MedicationSubsidy) {
        return (
            <MedicationSubsidyOptionList
                key={subsidy.id.value}
                subsidy={subsidy}
                medicationId={this.selectedMedicationId}
                careActivityId={this.props.careActivityId}
                checkType={MedicationSubsidyOptionCheckType.Prescription}
                optionCheckResults={this.subsidyOptionCheckResults}
                setOptionCheckResults={this.setSubsidyOptionCheckResults}
                claimTypeId={this.filterStore?.medicationSubsidyClaimTypeId}
            />
        );
    }

    @State.bound
    private cancel() {
        this.props.onClose(null);
    }

    @State.action.bound
    private selectPermanentPrescription(permanentPrescriptionId: PermanentPrescriptionId): void {
        if (this.selectedPermanentPrescriptions.find((item) => ValueWrapper.equals(item.permanentPrescriptionId, permanentPrescriptionId))) {
            return;
        }

        dispatchAsyncErrors(this.addPermanentPrescriptionToSelectedAsync(permanentPrescriptionId), this);
    }
}

export default connect(
    MedicationSelectorDialog,
    new DependencyAdapter<IMedicationSelectorDialogProps, IMedicationSelectorDialogDependencies>(c => ({
        referenceDataAdapter: c.resolve("ReferenceDataApiAdapter"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        medicationRequestReferenceDataStore: c.resolve("MedicationRequestReferenceDataStore"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        medicationsApiAdapter: c.resolve("MedicationsApiAdapter"),
        prescriptionApiAdapter: c.resolve("PrescriptionApiAdapter"),
        notificationService: c.resolve("INotificationService"),
        pricingAndSubsidyLoader: c.resolve("PricingAndSubsidyLoader"),
        searchParametersService: c.resolve("ISearchParametersService")
    }))
);
