import React, { useMemo } from "react";
import * as Ui from "@CommonControls";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import * as HisUi from "@HisPlatformControls";
import ExternalLocationCodeSelector from "@HisPlatform/BoundedContexts/Organization/Components/Controls/ExternalLocationCodeSelector";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ConditionCodeSelector from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ConditionCodeSelector";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ReferralStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/ReferralStore";
import ReadOnlyNestedProvider from "@Toolkit/ReactClient/Components/ReadOnlyContext/ReadOnlyNestedProvider";
import moment from "moment";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import ExcludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierSystemIdFilter";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";

interface IReferralDataProps {
    referral: ReferralStore;
    pointOfCareId?: PointOfCareId;
    equalWidth?: boolean;
    readOnly?: boolean;
    wentUnderCareAt?: moment.Moment;
}


const ReferralData: React.FC<IReferralDataProps> = props => {
    const filters: FilterBase[] = [new ExcludeIdentifierSystemIdFilter(IdentifierSystemId.OncologyMorphology)];

    const validOn = useMemo(() => props.wentUnderCareAt ? LocalDate.createFromMoment(props.wentUnderCareAt) : props.referral.referralCreationDate,
        [props.wentUnderCareAt, props.referral.referralCreationDate]);

    return (
        <ReadOnlyNestedProvider value={props.readOnly}>
            <Ui.GroupBox title={StaticWebAppResources.OutpatientWorkflow.CareActivityBaseDataStep.ReferralData}>
                <Ui.Flex>
                    <Ui.Flex.Item xs={props.equalWidth ? 6 : 4}>
                        <Ui.DatePicker
                            label={StaticWebAppResources.OutpatientWorkflow.CareActivityBaseDataStep.ReferralCreatedAt}
                            value={props.referral.referralCreationDate}
                            onChange={props.referral.setCreatedAt}
                            propertyIdentifier="ReferralCreationDate"
                            automationId="ReferralCreationDate"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={props.equalWidth ? 6 : 8}>
                        <ExternalLocationCodeSelector
                            label={StaticWebAppResources.OutpatientWorkflow.CareActivityBaseDataStep.ReferralWorkplace}
                            value={props.referral.referralWorkplace && props.referral.referralWorkplace.id}
                            validOn={validOn}
                            onChange={props.referral.setWorkPlace}
                            disabled={isNullOrUndefined(props.referral.referralCreationDate)}
                            showFavoritesAndGroup={!!props.pointOfCareId}
                            pointOfCareId={props.pointOfCareId}
                            propertyIdentifier="ReferralWorkplaceId"
                            automationId="ReferralWorkplaceId"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={props.equalWidth ? 6 : 4}>
                        <HisUi.DoctorCodeSelector
                            label={StaticWebAppResources.OutpatientWorkflow.CareActivityBaseDataStep.ReferralDoctor}
                            value={props.referral.referralDoctorId}
                            onChange={props.referral.setDoctorId}
                            propertyIdentifier="ReferralDoctorId"
                            automationId="ReferralDoctorId"
                            showFavoritesAndGroup={!!props.pointOfCareId}
                            favoritesAndGroupPointOfCare={props.pointOfCareId}
                            showCreateNewDoctor
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={props.equalWidth ? 6 : 4}>
                        <Ui.TextBox
                            label={StaticWebAppResources.OutpatientWorkflow.CareActivityBaseDataStep.ReferralCareIdentifier}
                            value={props.referral.referralCareIdentifier}
                            onChange={props.referral.setReferralCareIdentifier}
                            propertyIdentifier="ReferralCareIdentifier"
                            automationId="ReferralCareIdentifier"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={props.equalWidth ? 6 : 4}>
                        <ConditionCodeSelector
                            label={StaticWebAppResources.OutpatientWorkflow.CareActivityBaseDataStep.ReferralDiagnosis1}
                            value={props.referral.referralDiagnosisId1}
                            onChange={props.referral.setReferralDiagnosisId1}
                            showFavoritesAndGroup={!!props.pointOfCareId}
                            validOn={validOn}
                            pointOfCareId={props.pointOfCareId}
                            filters={filters}
                            propertyIdentifier="ReferralDiagnosisId1"
                            automationId="ReferralDiagnosisId1"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={props.equalWidth ? 6 : 4}>
                        <ConditionCodeSelector
                            label={StaticWebAppResources.OutpatientWorkflow.CareActivityBaseDataStep.ReferralDiagnosis2}
                            value={props.referral.referralDiagnosisId2}
                            validOn={validOn}
                            isReadOnly={props.referral.referralDiagnosisId1 === null && props.referral.referralDiagnosisId2 === null}
                            showFavoritesAndGroup={!!props.pointOfCareId}
                            pointOfCareId={props.pointOfCareId}
                            onChange={props.referral.setReferralDiagnosisId2}
                            filters={filters}
                            propertyIdentifier="ReferralDiagnosisId2"
                            automationId="ReferralDiagnosisId2"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>
        </ReadOnlyNestedProvider>
    );
};

export default State.observer(ReferralData);
