import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";


export interface IAutoLogoutDialogParams extends IModalParams {
    logoutTimeoutInMs: number;
}

export interface IAutoLogoutDialogResult {
    doAutoLogout: boolean;
}
 
export default class AutoLogoutDialogParams implements IAutoLogoutDialogParams {
 
    public static type = "AutoLogoutDialog";
    public get type() { return AutoLogoutDialogParams.type; }

    constructor(
        public readonly logoutTimeoutInMs: number
    ) {}

}