import React from "react";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import MedicalServicePriority from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalServices/Enum/MedicalServicePriority.g";

const MedicalServicePrioritySelectBox: React.FC<IUniversalEnumSelectorBaseProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumOrigin="server"
        enumName={"MedicalServicePriority"}
        enumType={MedicalServicePriority}
        displayMode="selectBox"
    />
);

export default MedicalServicePrioritySelectBox;