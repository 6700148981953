import { iconNameType } from "@CommonControls/Icon";
import { ITab } from "@CommonControls/Tab/Tabbar";
import { ITabbedScreenTabDefinition } from "@HisPlatform/Packages/ActionModel/Model/ITabbedScreenDefinition";


export class TabbedScreenTab implements ITab {

    public readonly title: string;
    public readonly iconName?: iconNameType;
    public readonly isDisabled?: boolean;
    public readonly automationId?: string;

    constructor(
        cultureCode: string,
        public readonly tabDef: ITabbedScreenTabDefinition
    ) {
        this.title = tabDef.displayName.getWithCurrentCultureCodeOrWithDefaultCultureCode(cultureCode);
    }
}
