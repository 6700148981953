import React, { useEffect, useState, useMemo } from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import { SelectBox } from "@CommonControls";
import { useErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import IFormEngineApiAdapter from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import IFormDefinitionListItem from "@Toolkit/FormEngine/Model/IFormDefinitionListItem";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";

interface IFormDefinitionSelectBox extends ISelectBoxBaseProps {
    apiAdapter: IFormEngineApiAdapter;
    cultureCodeProvider: ICurrentCultureProvider;

    baseEntityType: string;
    value?: FormDefinitionId;
    formDefinitions?: IFormDefinition[];
    onChange?: (value?: FormDefinitionId) => void;
}

// TODO: use cache!!

export default function FormDefinitionSelectBox(props: IFormDefinitionSelectBox) {

    const errorDispatcher = useErrorDispatcher();
    const [definitions, setDefinitions] = useState<IFormDefinitionListItem[]>();

    useEffect(() => {
        if (!isNullOrUndefined(props.formDefinitions)) {
            setDefinitions(props.formDefinitions.map(x => {
                return {
                    id: x.id,
                    displayName: x.multiLingualDisplayName.getWithCurrentCultureCodeOrWithDefaultCultureCode(props.cultureCodeProvider.cultureCode)
                } as IFormDefinitionListItem;
            }));
        } else {
            props.apiAdapter.getFormDefinitionListAsync(props.baseEntityType, true)
                .catch(errorDispatcher)
                .then((response: SimpleStore<PagedItemStore<IFormDefinitionListItem>>) => {
                    setDefinitions(response.value.items);
                });
        }
    }, [props.baseEntityType]);

    const items = useMemo(() => definitions?.map(d => ({ text: d.displayName, value: d.id } as ISelectBoxItem)) ?? [], [definitions]);

    return (
        <SelectBox
            {...props}
            items={items}
        />
    );
}