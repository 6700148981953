import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import AppointmentSubjectColumnData from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentSubjectColumn/AppointmentSubjectColumnData";

export function appointmentSubjectBaseDtoConverter(rawValue: any) {
    let subjectService: SchedulingServiceId | IEntityVersionSelector<ServiceRequestId>;
    let subjectType: "ServiceRequest" | "SchedulingService";

    if (rawValue.ServiceRequestId) {
        subjectService = new EntityVersionSelector<ServiceRequestId>(new ServiceRequestId(rawValue.ServiceRequestId.Value.toString()), LocalDate.today());
        subjectType = "ServiceRequest";
    } else {
        subjectService = new SchedulingServiceId(rawValue.SchedulingServiceId.Value.toString());
        subjectType = "SchedulingService";
    }

    return new AppointmentSubjectColumnData(
        subjectService,
        subjectType
    );
}