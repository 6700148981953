import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import { TaxPayerInfoRegistryItem } from "@HisPlatform/Packages/Finance/Components/TaxPayerInfoFormCustomBlock/TaxPayerInfoFormCustomBlock";
import { FinancedServiceCodeSelectorRegistryItem } from "@HisPlatform/Packages/Finance/Components/FinancedServiceCodeSelectorFormCustomBlock/FinancedServiceCodeSelectorFormCustomBlock";

class FinancePackageInitializer implements IPackageInitializer {
    public configureFormEngine(registry: FormEditorRegistry) {
        registry.registerCustomBlock("FinancedServiceCodeSelector", FinancedServiceCodeSelectorRegistryItem);
        registry.registerCustomBlock("TaxPayerInfo", TaxPayerInfoRegistryItem);
    }
}

export default new FinancePackageInitializer();