import * as FormEngineProxy from "@HisPlatform/BoundedContexts/FormEngine/Api/Proxy.g";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import { IFormDataStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormDataStore";
import Form from "@Toolkit/FormEngine/Model/Form";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import FormSchemaReference from "@Toolkit/FormEngine/Model/Schema/FormSchemaReference";
import FormLayoutReference from "@Toolkit/FormEngine/Model/Layout/FormLayoutReference";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormEnumReference from "@Toolkit/FormEngine/Model/Schema/FormEnumReference";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import IncludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierFilter";
import ExcludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierFilter";
import IncludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierSystemIdFilter";
import ExcludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierSystemIdFilter";
import CodeStartsWithFilter from "@Toolkit/CommonWeb/Model/Filtering/CodeStartsWithFilter";
import CodeDoesNotStartWithFilter from "@Toolkit/CommonWeb/Model/Filtering/CodeDoesNotStartWithFilter";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import NumberFormFieldData from "@Toolkit/FormEngine/Model/Data/NumberFormFieldData";
import NumberArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/NumberArrayFormFieldData";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import TextArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/TextArrayFormFieldData";
import DateFormFieldData from "@Toolkit/FormEngine/Model/Data/DateFormFieldData";
import DateArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/DateArrayFormFieldData";
import DateTimeFormFieldData from "@Toolkit/FormEngine/Model/Data/DateTimeFormFieldData";
import DateTimeArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/DateTimeArrayFormFieldData";
import TimeFormFieldData from "@Toolkit/FormEngine/Model/Data/TimeFormFieldData";
import TimeArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/TimeArrayFormFieldData";
import EnumFormFieldData from "@Toolkit/FormEngine/Model/Data/EnumFormFieldData";
import EnumArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/EnumArrayFormFieldData";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import ReferencedEntityArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityArrayFormFieldData";
import ReferencedExtensibleEnumFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedExtensibleEnumFormFieldData";
import ReferencedExtensibleEnumArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedExtensibleEnumArrayFormFieldData";
import ReferencedEnumFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEnumFormFieldData";
import ReferencedEnumArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEnumArrayFormFieldData";
import CompositeFormFieldData from "@Toolkit/FormEngine/Model/Data/CompositeFormFieldData";
import CompositeArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/CompositeArrayFormFieldData";
import EmbeddedFormFormFieldData from "@Toolkit/FormEngine/Model/Data/EmbeddedFormFormFieldData";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";
import BooleanFormFieldData from "@Toolkit/FormEngine/Model/Data/BooleanFormFieldData";

export function mapToFormInstanceUpsertDto(forms: IForm[]) {
    return forms?.map(form => {
        return new FormEngineProxy.FormInstanceUpsertDto({
            isNew: FormInstanceId.isNew(form.id),
            formDefinitionId: form.definitionId,
            id: form.id,
            existingRowVersion: form.rowVersion,
            content: mapToFormInstanceContentDto(form.data)
        });
    });
}

export async function mapProxyFormFieldDataAsync(formFieldData: FormEngineProxy.FormFieldData[], mappedFormFieldData: FormFieldDataBase[], formEngineReferenceDataStore: IFormEngineReferenceDataStore) {

    const promises = formFieldData.map(async (i) => {
        if (i["_discriminator"] === "BooleanFormFieldData") {

            const castedValue = i as FormEngineProxy.BooleanFormFieldData;
            mappedFormFieldData.push(new BooleanFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, castedValue.value));
        } else if (i["_discriminator"] === "NumberFormFieldData") {

            const castedValue = i as FormEngineProxy.NumberFormFieldData;
            mappedFormFieldData.push(new NumberFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, castedValue.value));
        } else if (i["_discriminator"] === "NumberArrayFormFieldData") {

            const castedValue = i as FormEngineProxy.NumberArrayFormFieldData;
            mappedFormFieldData.push(new NumberArrayFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, State.createObservableShallowArray(castedValue.value)));
        } else if (i["_discriminator"] === "TextFormFieldData") {

            const castedValue = i as FormEngineProxy.TextFormFieldData;
            mappedFormFieldData.push(new TextFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, castedValue.value));
        } else if (i["_discriminator"] === "TextArrayFormFieldData") {

            const castedValue = i as FormEngineProxy.TextArrayFormFieldData;
            mappedFormFieldData.push(new TextArrayFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, State.createObservableShallowArray(castedValue.value)));
        } else if (i["_discriminator"] === "DateFormFieldData") {

            const castedValue = i as FormEngineProxy.DateFormFieldData;
            mappedFormFieldData.push(new DateFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, castedValue.value));
        } else if (i["_discriminator"] === "DateArrayFormFieldData") {

            const castedValue = i as FormEngineProxy.DateArrayFormFieldData;
            mappedFormFieldData.push(new DateArrayFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, State.createObservableShallowArray(castedValue.value)));
        } else if (i["_discriminator"] === "TimeFormFieldData") {

            const castedValue = i as FormEngineProxy.TimeFormFieldData;
            mappedFormFieldData.push(new TimeFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, isNullOrUndefined(castedValue.value) ? null : TimeOfDay.createFromTotalMinutes(castedValue.value)));
        } else if (i["_discriminator"] === "TimeArrayFormFieldData") {

            const castedValue = i as FormEngineProxy.TimeArrayFormFieldData;
            mappedFormFieldData.push(new TimeArrayFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, State.createObservableShallowArray(castedValue.value.map(j => isNullOrUndefined(j) ? null : TimeOfDay.createFromTotalMinutes(j)))));
        } else if (i["_discriminator"] === "DateTimeFormFieldData") {

            const castedValue = i as FormEngineProxy.DateTimeFormFieldData;
            mappedFormFieldData.push(new DateTimeFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, castedValue.value));
        } else if (i["_discriminator"] === "DateTimeArrayFormFieldData") {

            const castedValue = i as FormEngineProxy.DateTimeArrayFormFieldData;
            mappedFormFieldData.push(new DateTimeArrayFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, State.createObservableShallowArray(castedValue.value)));
        } else if (i["_discriminator"] === "EnumFormFieldData") {

            const castedValue = i as FormEngineProxy.EnumFormFieldData;
            let formEnumReference: FormEnumReference;
            if (castedValue.formEnumReference["_discriminator"] === "SelfFormEnumReference") {
                const castedFormEnumReference = castedValue.formEnumReference as FormEngineProxy.SelfFormEnumReference;
                formEnumReference = new FormEnumReference(castedFormEnumReference.formEnumName);
            } else {
                const castedFormEnumReference = castedValue.formEnumReference as FormEngineProxy.ExternalFormEnumReference;
                formEnumReference = new FormEnumReference(castedFormEnumReference.formEnumName, castedFormEnumReference.formDefinitionId);
            }
            mappedFormFieldData.push(new EnumFormFieldData(i.fieldName, formEnumReference, i.isReadOnly, i.isVisible, castedValue.value));
        } else if (i["_discriminator"] === "EnumArrayFormFieldData") {

            const castedValue = i as FormEngineProxy.EnumArrayFormFieldData;
            let formEnumReference: FormEnumReference;
            if (castedValue.formEnumReference["_discriminator"] === "SelfFormEnumReference") {
                const castedFormEnumReference = castedValue.formEnumReference as FormEngineProxy.SelfFormEnumReference;
                formEnumReference = new FormEnumReference(castedFormEnumReference.formEnumName);
            } else {
                const castedFormEnumReference = castedValue.formEnumReference as FormEngineProxy.ExternalFormEnumReference;
                formEnumReference = new FormEnumReference(castedFormEnumReference.formEnumName, castedFormEnumReference.formDefinitionId);
            }
            mappedFormFieldData.push(new EnumArrayFormFieldData(i.fieldName, formEnumReference, i.isReadOnly, i.isVisible, State.createObservableShallowArray(castedValue.value)));
        } else if (i["_discriminator"] === "ReferencedEntityFormFieldData") {

            const castedValue = i as FormEngineProxy.ReferencedEntityFormFieldData;
            mappedFormFieldData.push(new ReferencedEntityFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, State.createObservableShallowArray(convertProxyFiltersToFilters(castedValue.filters)), castedValue.value));
        } else if (i["_discriminator"] === "ReferencedEntityArrayFormFieldData") {

            const castedValue = i as FormEngineProxy.ReferencedEntityArrayFormFieldData;
            mappedFormFieldData.push(new ReferencedEntityArrayFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, State.createObservableShallowArray(convertProxyFiltersToFilters(castedValue.filters)), castedValue.value));
        } else if (i["_discriminator"] === "ReferencedExtensibleEnumFormFieldData") {

            const castedValue = i as FormEngineProxy.ReferencedExtensibleEnumFormFieldData;
            mappedFormFieldData.push(new ReferencedExtensibleEnumFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, isNullOrUndefined(castedValue.value) ? null : castedValue.value));
        } else if (i["_discriminator"] === "ReferencedExtensibleEnumArrayFormFieldData") {

            const castedValue = i as FormEngineProxy.ReferencedExtensibleEnumArrayFormFieldData;
            mappedFormFieldData.push(new ReferencedExtensibleEnumArrayFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, State.createObservableShallowArray(castedValue.value)));
        } else if (i["_discriminator"] === "ReferencedEnumFormFieldData") {

            const castedValue = i as FormEngineProxy.ReferencedEnumFormFieldData;
            mappedFormFieldData.push(new ReferencedEnumFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, castedValue.value));
        } else if (i["_discriminator"] === "ReferencedEnumArrayFormFieldData") {

            const castedValue = i as FormEngineProxy.ReferencedEnumArrayFormFieldData;
            mappedFormFieldData.push(new ReferencedEnumArrayFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, State.createObservableShallowArray(castedValue.value)));
        } else if (i["_discriminator"] === "CompositeFormFieldData") {

            const castedValue = i as FormEngineProxy.CompositeFormFieldData;
            const mappedCompositeFormFieldData: FormFieldDataBase[] = [];
            await mapProxyFormFieldDataAsync(castedValue.values.values, mappedCompositeFormFieldData, formEngineReferenceDataStore);

            mappedFormFieldData.push(new CompositeFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, State.createObservableShallowArray(mappedCompositeFormFieldData)));
        } else if (i["_discriminator"] === "CompositeArrayFormFieldData") {

            const castedValue = i as FormEngineProxy.CompositeArrayFormFieldData;
            const mappedCompositeFormFieldDataArray: IObservableArray<IObservableArray<FormFieldDataBase>> = State.createObservableShallowArray([]);
            const innerPromises = castedValue.values.map(async (j) => {
                const mappedCompositeFormFieldData: FormFieldDataBase[] = [];
                await mapProxyFormFieldDataAsync(j.values, mappedCompositeFormFieldData, formEngineReferenceDataStore);

                mappedCompositeFormFieldDataArray.push(State.createObservableShallowArray(mappedCompositeFormFieldData));
            });

            await Promise.all(innerPromises);

            mappedFormFieldData.push(new CompositeArrayFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, mappedCompositeFormFieldDataArray));
        } else if (i["_discriminator"] === "EmbeddedFormFormFieldData") {

            const castedValue = i as FormEngineProxy.EmbeddedFormFormFieldData;
            const forms = await mapToFormsAsync(formEngineReferenceDataStore, castedValue.value);
            mappedFormFieldData.push(new EmbeddedFormFormFieldData(i.fieldName, i.isReadOnly, i.isVisible, State.createObservableShallowArray(forms)));
        }
    });

    await Promise.all(promises);
}

export function mapFormFieldDataToProxy(formFieldData: FormFieldDataBase[], mappedFormFieldData: FormEngineProxy.FormFieldData[]) {
    formFieldData.forEach(i => {
        const baseFields = {
            fieldName: i.fieldName,
            isVisible: i.isVisible,
            isReadOnly: i.isReadOnly
        };

        if (i instanceof BooleanFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.BooleanFormFieldData({
                ...baseFields,
                value: i.value,
            }));
        } else if (i instanceof NumberFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.NumberFormFieldData({
                ...baseFields,
                value: i.value,
            }));
        } else if (i instanceof NumberArrayFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.NumberArrayFormFieldData({
                ...baseFields,
                value: i.value,
            }));
        } else if (i instanceof TextFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.TextFormFieldData({
                ...baseFields,
                value: i.value,
            }));
        } else if (i instanceof TextArrayFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.TextArrayFormFieldData({
                ...baseFields,
                value: i.value,
            }));
        } else if (i instanceof DateFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.DateFormFieldData({
                ...baseFields,
                value: i.value,
            }));
        } else if (i instanceof DateArrayFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.DateArrayFormFieldData({
                ...baseFields,
                value: i.value,
            }));
        } else if (i instanceof DateTimeFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.DateTimeFormFieldData({
                ...baseFields,
                value: i.value,
            }));
        } else if (i instanceof DateTimeArrayFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.DateTimeArrayFormFieldData({
                ...baseFields,
                value: i.value,
            }));
        } else if (i instanceof TimeFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.TimeFormFieldData({
                ...baseFields,
                value: i.value?.valueInMinutes ?? null,
            }));
        } else if (i instanceof TimeArrayFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.TimeArrayFormFieldData({
                ...baseFields,
                value: i.value.map(v => v?.valueInMinutes ?? null),
            }));
        } else if (i instanceof EnumFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.EnumFormFieldData({
                ...baseFields,
                formEnumReference: i.formEnumReference.isExternal
                    ? new FormEngineProxy.ExternalFormEnumReference({ formEnumName: i.formEnumReference.enumName, formDefinitionId: i.formEnumReference.formDefinitionId })
                    : new FormEngineProxy.SelfFormEnumReference({ formEnumName: i.formEnumReference.enumName }),
                value: i.value,
            }));
        } else if (i instanceof EnumArrayFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.EnumArrayFormFieldData({
                ...baseFields,
                formEnumReference: i.formEnumReference.isExternal
                    ? new FormEngineProxy.ExternalFormEnumReference({ formEnumName: i.formEnumReference.enumName, formDefinitionId: i.formEnumReference.formDefinitionId })
                    : new FormEngineProxy.SelfFormEnumReference({ formEnumName: i.formEnumReference.enumName }),
                value: i.value,
            }));
        } else if (i instanceof ReferencedEntityFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.ReferencedEntityFormFieldData({
                ...baseFields,
                filters: convertFiltersToProxyFilters(i.filters),
                value: i.value,
            }));
        } else if (i instanceof ReferencedEntityArrayFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.ReferencedEntityArrayFormFieldData({
                ...baseFields,
                filters: convertFiltersToProxyFilters(i.filters),
                value: i.value,
            }));
        } else if (i instanceof ReferencedExtensibleEnumFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.ReferencedExtensibleEnumFormFieldData({
                ...baseFields,
                value: i.value,
            }));
        } else if (i instanceof ReferencedExtensibleEnumArrayFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.ReferencedExtensibleEnumArrayFormFieldData({
                ...baseFields,
                value: i.value,
            }));
        } else if (i instanceof ReferencedEnumFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.ReferencedEnumFormFieldData({
                ...baseFields,
                value: i.value,
            }));
        } else if (i instanceof ReferencedEnumArrayFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.ReferencedEnumArrayFormFieldData({
                ...baseFields,
                value: i.value,
            }));
        } else if (i instanceof CompositeFormFieldData) {
            const mappedCompositeFormFieldData: FormEngineProxy.FormFieldData[] = [];
            mapFormFieldDataToProxy(i.value, mappedCompositeFormFieldData);

            mappedFormFieldData.push(new FormEngineProxy.CompositeFormFieldData({
                ...baseFields,
                values: new FormEngineProxy.CompositeFormFieldDataValues({ values: mappedCompositeFormFieldData }),
            }));
        } else if (i instanceof CompositeArrayFormFieldData) {
            const mappedCompositeFormFieldDataArray: FormEngineProxy.CompositeFormFieldDataValues[] = [];
            i.value.forEach(j => {
                const mappedCompositeFormFieldData: FormEngineProxy.FormFieldData[] = [];
                mapFormFieldDataToProxy(j, mappedCompositeFormFieldData);

                mappedCompositeFormFieldDataArray.push(new FormEngineProxy.CompositeFormFieldDataValues({ values: mappedCompositeFormFieldData }));
            });

            mappedFormFieldData.push(new FormEngineProxy.CompositeArrayFormFieldData({
                ...baseFields,
                values: mappedCompositeFormFieldDataArray
            }));
        } else if (i instanceof EmbeddedFormFormFieldData) {
            mappedFormFieldData.push(new FormEngineProxy.EmbeddedFormFormFieldData({
                ...baseFields,
                value: mapToFormInstanceDtos(i.value),
            }));
        }
    });
}

export function mapToFormInstanceContentDto(formData: IFormDataStore) {

    const result: FormEngineProxy.FormFieldData[] = [];
    mapFormFieldDataToProxy(formData.Content, result);

    return new FormEngineProxy.FormInstanceContentDto({
        layoutReferenceDto: new FormEngineProxy.LayoutReferenceDto({
            id: formData.LayoutReference.formLayoutId,
            versionNumber: formData.LayoutReference.versionNumber
        }),
        schemaReferenceDto: new FormEngineProxy.SchemaReferenceDto({
            id: formData.SchemaReference.formSchemaId,
            versionNumber: formData.SchemaReference.versionNumber
        }),
        data: result
    });
}

export function mapLocalizedLabelsToMultiLingualLabelMap(localizedLabels: FormEngineProxy.LocalizedLabel[]) {
    const localizedLabelMap = new Map<string, string>();
    localizedLabels.forEach(l => localizedLabelMap.set(l.cultureCode?.value, l.label));
    return new MultiLingualLabel(localizedLabelMap);
}

export function mapMultiLingualLabelMapToLocalizedLabels(multiLingualLabel: MultiLingualLabel) {
    const localizedLabels: FormEngineProxy.LocalizedLabel[] = [];
    multiLingualLabel.localizedLabels.forEach((label: string, key: string) => localizedLabels.push(new FormEngineProxy.LocalizedLabel({ cultureCode: new FormEngineProxy.CultureCode({ value: key }), label: label })));
    return localizedLabels;
}

export function convertProxyFiltersToFilters(filters: FormEngineProxy.FilterBase[]): FilterBase[] {
    if (!filters) {
        return [];
    }
    return filters.map((filter: FormEngineProxy.FilterBase) => mapProxyFilterToFilter(filter));
}

export function convertFiltersToProxyFilters(filters: FilterBase[]): FormEngineProxy.FilterBase[] {
    if (!filters) {
        return [];
    }
    return filters.map((filter: FilterBase) => mapFilterToProxyFilter(filter));
}

export function mapProxyFilterToFilter(filter: FormEngineProxy.FilterBase): FilterBase {
    if (filter["_discriminator"] === "IncludeIdentifierFilter") {
        return new IncludeIdentifierFilter(new Identifier(
            new IdentifierSystemId((filter as FormEngineProxy.IncludeIdentifierFilter).value.identifierSystemId.value),
            (filter as FormEngineProxy.IncludeIdentifierFilter).value.value));
    } else if (filter["_discriminator"] === "ExcludeIdentifierFilter") {
        return new ExcludeIdentifierFilter(new Identifier(
            new IdentifierSystemId((filter as FormEngineProxy.ExcludeIdentifierFilter).value.identifierSystemId.value),
            (filter as FormEngineProxy.ExcludeIdentifierFilter).value.value));
    } else if (filter["_discriminator"] === "IncludeIdentifierSystemIdFilter") {
        return new IncludeIdentifierSystemIdFilter(new IdentifierSystemId(
            (filter as FormEngineProxy.IncludeIdentifierSystemIdFilter).value.value));
    } else if (filter["_discriminator"] === "ExcludeIdentifierSystemIdFilter") {
        return new ExcludeIdentifierSystemIdFilter(new IdentifierSystemId(
            (filter as FormEngineProxy.ExcludeIdentifierSystemIdFilter).value.value));
    } else if (filter["_discriminator"] === "CodeStartsWithFilter") {
        return new CodeStartsWithFilter((filter as FormEngineProxy.CodeStartsWithFilter).value);
    } else if (filter["_discriminator"] === "CodeDoesNotStartWithFilter") {
        return (new CodeDoesNotStartWithFilter((filter as FormEngineProxy.CodeDoesNotStartWithFilter).value));
    } else if (filter["_discriminator"] === "ExplicitIdentifierFilter") {
        const explicitIdentifierFilter = filter as FormEngineProxy.ExplicitIdentifierFilter;
        return (new ExplicitIdentifierFilter(explicitIdentifierFilter.type, explicitIdentifierFilter.value));
    } else {
        throw new Error(`Unsupported filter: ${typeof filter}`);
    }
}

export function mapFilterToProxyFilter(filter: FilterBase): FormEngineProxy.FilterBase {
    if (filter instanceof IncludeIdentifierFilter) {
        return new FormEngineProxy.IncludeIdentifierFilter({
            value: new Identifier(
                new IdentifierSystemId(filter.value.identifierSystemId.value),
                filter.value.value
            )
        });
    } else if (filter instanceof ExcludeIdentifierFilter) {
        return new FormEngineProxy.ExcludeIdentifierFilter({
            value: new Identifier(
                new IdentifierSystemId(filter.value.identifierSystemId.value),
                filter.value.value
            )
        });
    } else if (filter instanceof IncludeIdentifierSystemIdFilter) {
        return new FormEngineProxy.IncludeIdentifierSystemIdFilter({
            value: filter.value
        });
    } else if (filter instanceof ExcludeIdentifierSystemIdFilter) {
        return new FormEngineProxy.ExcludeIdentifierSystemIdFilter({
            value: filter.value
        });
    } else if (filter instanceof CodeStartsWithFilter) {
        return new FormEngineProxy.CodeStartsWithFilter({
            value: filter.value
        });
    } else if (filter instanceof CodeDoesNotStartWithFilter) {
        return new FormEngineProxy.CodeDoesNotStartWithFilter({
            value: filter.value
        });
    } else if (filter instanceof ExplicitIdentifierFilter) {
        return new FormEngineProxy.ExplicitIdentifierFilter({
            type: filter.type,
            value: filter.value
        });
    } else {
        throw new Error(`Unsupported filter: ${typeof filter}`);
    }
}

export function getFormEnumReferenceDto(enumReference: FormEnumReference): FormEngineProxy.FormEnumReferenceBase {
    return enumReference ? (enumReference.isLocal
        ? new FormEngineProxy.SelfFormEnumReference({ formEnumName: enumReference.enumName })
        : new FormEngineProxy.ExternalFormEnumReference({ formEnumName: enumReference.enumName, formDefinitionId: enumReference.formDefinitionId })
    ) : null;
}

function mapToFormInstanceDtos(forms: IForm[]) {

    return forms?.map(form => {
        return new FormEngineProxy.FormInstanceDto({
            id: form.id,
            rowVersion: form.rowVersion,
            createdAt: form.createdAt,
            createdBy: form.createById,
            formDefinitionId: form.definitionId,
            content: mapToFormInstanceContentDto(form.data)
        });

    });
}

export async function mapToFormsAsync(formEngineReferenceDataStore: IFormEngineReferenceDataStore, formInstances: FormEngineProxy.FormInstanceDto[]) {
    if (!formInstances)
        return [];

    return await Promise.all(formInstances.map(async formInstance => {
        const definition = await formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(formInstance.formDefinitionId);

        const formData = await restoreFormDataStoreAsync(formInstance.content, definition, formEngineReferenceDataStore);
        return new Form(
            formInstance.id,
            formInstance.rowVersion,
            formInstance.formDefinitionId,
            formData,
            [], // todo: beordogh
            formInstance.createdAt,
            formInstance.createdBy
        );
    }));
}

export async function mapToFormAsync(formEngineReferenceDataStore: IFormEngineReferenceDataStore, formInstance: FormEngineProxy.FormInstanceDto, formInstanceId: FormInstanceId) {
    const definition = await formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(formInstance.formDefinitionId);
    const formData = await restoreFormDataStoreAsync(formInstance.content, definition, formEngineReferenceDataStore);

    return new Form(
        formInstanceId,
        formInstance.rowVersion,
        formInstance.formDefinitionId,
        formData,
        [], // todo: beordogh
        formInstance.createdAt,
        formInstance.createdBy
    );
}

export async function restoreFormDataStoreAsync(content: FormEngineProxy.FormInstanceContentDto, formDefinition: IFormDefinition, formEngineReferenceDataStore: IFormEngineReferenceDataStore): Promise<IFormDataStore> {
    const dataStore: IFormDataStore = {
        SchemaReference: new FormSchemaReference(content.schemaReferenceDto.id, content.schemaReferenceDto.versionNumber),
        LayoutReference: new FormLayoutReference(content.layoutReferenceDto.id, content.layoutReferenceDto.versionNumber),
        Content: []
    };

    await addSchemaElementsToStoreAsync(dataStore, content.data!, formEngineReferenceDataStore);

    return dataStore;
}

export async function restoreCustomFormDataStoreAsync(data: FormEngineProxy.FormFieldData[], formDefinition: IFormDefinition, formEngineReferenceDataStore: IFormEngineReferenceDataStore): Promise<IFormDataStore> {
    const dataStore: IFormDataStore = {
        SchemaReference: new FormSchemaReference(formDefinition.rootSchema.id, formDefinition.rootSchema.getLatestVersionOrNull()!.versionNumber),
        LayoutReference: new FormLayoutReference(formDefinition.rootLayout.id, formDefinition.rootLayout.getLatestVersionOrNull()!.versionNumber),
        Content: []
    };

    await addSchemaElementsToStoreAsync(dataStore, data, formEngineReferenceDataStore);

    return dataStore;
}

async function addSchemaElementsToStoreAsync(target: IFormDataStore, data: FormEngineProxy.FormFieldData[], formEngineReferenceDataStore: IFormEngineReferenceDataStore) {
    const mappedFormFieldData: FormFieldDataBase[] = [];
    await mapProxyFormFieldDataAsync(data, mappedFormFieldData, formEngineReferenceDataStore);

    target.Content = State.createObservableShallowArray(mappedFormFieldData);
}
