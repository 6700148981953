import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import {dispatchAsyncErrors} from "@Toolkit/CommonWeb/AsyncHelpers";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import {IEmptyStateSettings} from "@CommonControls/DataGrid/IDataGridProps";
import AppointmentCancellationDialogParams, {IAppointmentCancellationDialogResult} from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/AppointmentCancellationDialog/AppointmentCancellationDialogParams";
import AppointmentCancellationReasonArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/AppointmentCancellationReasonArgument";
import {IModalService} from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import ModalServiceAdapter from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAdapter";
import InputDataClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/InputDataClientSideAction";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import AppointmentsMasterDetailCalendarPortal from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/AppointmentsMasterDetailCalendarPortal";
import ScrollView from "@CommonControls/ScrollView/ScrollView";
import Styles from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/AppointmentsMasterDetailPanel.less";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";

interface IInvalidAppointmentsPanelDependencies {
    worklistApiAdapter: WorklistApiAdapter;
}

interface IInvalidAppointmentsPanelProps {
    _dependencies?: IInvalidAppointmentsPanelDependencies;
    _modalService?: IModalService;
    onBack: () => void;
    useCaseState: INDataUseCaseState;
    selectedRowId: string;
    onChange: (selectedRowId: string, useCaseState: INDataUseCaseState) => void;
    onCloseDetail: () => void;
}

@State.observer
class InvalidAppointmentsPanel extends React.Component<IInvalidAppointmentsPanelProps> {
    @State.observable.ref private worklistDefinition: IWorklistDefinition = null;
    @State.computed private get isDetailOpen() { return !!this.props.selectedRowId; }

    public componentDidMount() {
        dispatchAsyncErrors(this.initializePanelAsync(), this);
    }

    @State.bound
    private async initializePanelAsync() {
        const resp = await this.props._dependencies.worklistApiAdapter.getInvalidAppointmentScheduleEntryBoundWorklistDefinition();
        State.runInAction(() => {
            this.worklistDefinition = resp.value;
        });
    }

    @State.bound
    private async onPerformActionAsync(clientSideAction: ClientSideActionDto) {
        if (clientSideAction instanceof InputDataClientSideAction) {
            const appointmentId = clientSideAction.useCaseArguments[0].value;
            const dialogResult =
                await this.props._modalService.showDialogAsync<IAppointmentCancellationDialogResult>(new AppointmentCancellationDialogParams(appointmentId, false));
            if (dialogResult && dialogResult.appointmentDeleted) {
                return new AppointmentCancellationReasonArgument(dialogResult.dialogData.appointmentCancellationReasonId, dialogResult.dialogData.additionalText);
            }
        }
        return null;
    }

    @State.bound
    private getPanelProps() {
        return {
            onCancelAppointment: this.props.onCloseDetail,
            onCloseDetail: this.props.onCloseDetail,
            onRenderCalendar: this.renderCalendar
        };
    }

    private get emptyStateSettings() {
        return {
            message: StaticSchedulingResources.InvalidAppointmentsPanel.NoItemMessage
        } as IEmptyStateSettings;
    }

    public render() {
        return (
            <SingleLayout>
                <NDataPanel
                    onGetPanelProps={this.getPanelProps}
                    definition={this.worklistDefinition}
                    hasRefreshButton
                    onChange={this.props.onChange}
                    useCaseState={this.props.useCaseState}
                    selectedRowId={this.props.selectedRowId}
                    iconName="appointments"
                    onPerformClientSideActionAsync={this.onPerformActionAsync}
                    emptyStateSettings={this.emptyStateSettings}
                    onRenderMasterList={this.renderMasterList}
                    disableDetailStrictMode
                />
            </SingleLayout>
        );
    }

    @State.bound
    public renderMasterList(list: React.ReactNode) {
        if (this.isDetailOpen) {
            return (
                <>
                    <ScrollView height="50%" className={Styles.additionalContentContainer}>
                        <AppointmentsMasterDetailCalendarPortal.Host />
                    </ScrollView>
                    <div className={Styles.masterContainer}>
                        {list}
                    </div>
                </>
            );
        }
        return list;
    }

    @State.bound
    public renderCalendar(calendar: React.ReactNode) {
        return (
            <AppointmentsMasterDetailCalendarPortal>
                {calendar}
            </AppointmentsMasterDetailCalendarPortal>
        );
    }
}

export default connect(
    InvalidAppointmentsPanel,
    new DependencyAdapter<IInvalidAppointmentsPanelProps, IInvalidAppointmentsPanelDependencies>(c => ({
        worklistApiAdapter: c.resolve("WorklistApiAdapter")
    })),
    new ModalServiceAdapter()
);
