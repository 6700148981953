import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCases from "@Primitives/UseCases";
import ConditionStatementPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/ConditionStatement/ConditionStatementPanel";
import ConditionStatementId from "@Primitives/ConditionStatementId.g";
import ConditionStatementClinicalStatus from "@HisPlatform/BoundedContexts/Care/Api/MedicalCondition/ConditionStatement/Enum/ConditionStatementClinicalStatus.g";

export default function configureConditionStatementUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2({
        identifiers: [UseCases.conditionStatementDetail],
        component: ConditionStatementPanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));
            const id = map.get("conditionStatementId") ?? new ConditionStatementId("new");
            const clinicalStatus = map.get("clinicalStatus") ?? ConditionStatementClinicalStatus.Active;
            return {
                id: id,
                clinicalStatus: clinicalStatus
            };
        }
    });
}
