import React from "react";
import { IFormCustomBlockComponentProps, ICustomBlockRegistryItem } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityCoverageDataPanelCore from "./CareActivityCoverageDataPanelCore";
import CareActivityId from "@Primitives/CareActivityId.g";
import StringFormDataElement from "@Toolkit/FormEngine/Model/Schema/StringFormDataElement";
import PatientId from "@Primitives/PatientId.g";
import InsuranceId from "@Primitives/InsuranceId.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import PreviewFormDefinition from "@Toolkit/FormEngine/Model/PreviewFormDefinition";
import _ from "@HisPlatform/Common/Lodash";
import AccidentTypeId from "@Primitives/AccidentTypeId.g";
import HunFinancingClassId from "@Primitives/HunFinancingClassId.g";
import IdentifierTypeId from "@Primitives/IdentifierTypeId.g";
import { useFormEntityIdField, useFormExtensibleEnumIdField, useFormEntityIdentifierField, useFormTextField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import ReferencedEnumFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEnumFormFieldData";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import ReferencedExtensibleEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedExtensibleEnumFormDataElement";
import ReferencedEntityFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEntityFormDataElement";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import Urgency from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/Urgency.g";

interface ICareActivityCoverageDataPanelFormCustomBlockProps extends IFormCustomBlockComponentProps {
    _patientId?: PatientId;
    _careActivityId?: CareActivityId;
}

function _CareActivityCoverageDataPanelFormCustomBlock(props: ICareActivityCoverageDataPanelFormCustomBlockProps) {

    if (props.formDefinition instanceof PreviewFormDefinition) {
        // Preview mode
        return (
            <div>CareActivityCoverageDataPanelFormCustomBlock</div>
        );
    }

    const urgencyField = getField<ReferencedEnumFormFieldData>(props.form.data.Content, "Urgency");

    const [pointOfCareId, setPointOfCareId] = useFormEntityIdField<PointOfCareId>(props.form.data.Content, "PointOfCareId", PointOfCareId);
    const [insuranceId, setInsuranceId] = useFormEntityIdField<InsuranceId>(props.form.data.Content, "InsuranceId", InsuranceId);
    const [accidentTypeId, setAccidentTypeId] = useFormExtensibleEnumIdField<AccidentTypeId>(props.form.data.Content, "AccidentTypeId", AccidentTypeId);
    const [financingClassId, setFinancingClassId] = useFormExtensibleEnumIdField<HunFinancingClassId>(props.form.data.Content, "HunFinancingClassId", HunFinancingClassId);
    const [identifierTypeId, setIdentifierTypeId] = useFormExtensibleEnumIdField<IdentifierTypeId>(props.form.data.Content, "IdentifierTypeId", IdentifierTypeId);
    const [identifier, setIdentifier] = useFormEntityIdentifierField(props.form.data.Content, "Identifier");
    const [isHunCoverageNeeded, setIsHunCoverageNeeded] = useFormTextField(props.form.data.Content, "IsHunCoverageNeeded");

    return (
        <CareActivityCoverageDataPanelCore
            careActivityId={props._careActivityId}
            patientId={props._patientId}
            urgency={urgencyField.value as unknown as Urgency}
            accidentTypeId={accidentTypeId}
            onAccidentTypeIdChange={setAccidentTypeId}
            financingClassId={financingClassId}
            onFinancingClassIdChange={setFinancingClassId}
            identifier={identifier}
            onIdentifierChange={setIdentifier}
            identifierTypeId={identifierTypeId}
            onIdentifierTypeIdChange={setIdentifierTypeId}
            insuranceId={insuranceId}
            onInsuranceIdChange={setInsuranceId}
            onIsHunCoverageNeededChange={setIsHunCoverageNeeded}
            pointOfCareId={pointOfCareId}
        />
    );
}

const CareActivityCoverageDataPanelFormCustomBlock = connect(
    State.observer(_CareActivityCoverageDataPanelFormCustomBlock),
    new PatientContextAdapter<ICareActivityCoverageDataPanelFormCustomBlockProps>(p => ({
        _patientId: p.patientId
    })),
    new CareActivityContextAdapter<ICareActivityCoverageDataPanelFormCustomBlockProps>(p => ({
        _careActivityId: p.careActivityId
    }))
);


export const CareActivityCoverageDataPanelRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Care Activity Coverage Data Panel",
    dataElementsFactory: () => [
        new ReferencedEntityFormDataElement("InsuranceId", false, null, false, true, "Insurance"),
        new ReferencedExtensibleEnumFormDataElement("AccidentTypeId", false, null, false, true, "AccidentType"),
        new ReferencedExtensibleEnumFormDataElement("HunFinancingClassId", false, null, false, true, "HunFinancingClass"),
        new ReferencedExtensibleEnumFormDataElement("IdentifierTypeId", false, null, false, true, "IdentifierType"),
        new StringFormDataElement("Identifier", false, null, false, true, false),
        new StringFormDataElement("IsHunCoverageNeeded", false, null, false, false, false)
    ],
    componentType: CareActivityCoverageDataPanelFormCustomBlock
};

export default CareActivityCoverageDataPanelFormCustomBlock;