import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import * as CareProxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import * as WebAppBackendProxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import CareActivityTextBlock from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/CareActivityTextBlock";
import CareActivityTextBlockId from "@Primitives/CareActivityTextBlockId.g";
import Di from "@Di";

type ResponseType = CareProxy.TryGetCareActivityTextBlockCommandResponse
                    | CareProxy.GetCareActivityTextBlockCommandResponse
                    | CareProxy.CreateCareActivityTextBlockCommandResponse
                    | CareProxy.SaveCareActivityTextBlockContentCommandResponse
                    | WebAppBackendProxy.GetCareActivityTextBlockDataCommandResponse;

@Di.injectable()
export default class CareActivityTextBlockStoreMapper extends LockingEntityStoreMapper<CareProxy.CareActivityTextBlockDto, CareActivityTextBlock, ResponseType> {

    protected storeEntityIdType = CareActivityTextBlockId;

    protected vGetStoreIdentifier() {
        return "CareActivityTextBlock";
    }

    protected entityDtoSelector(response: ResponseType) {
        return response.textBlock;
    }

    protected applyToStoreCore(target: CareActivityTextBlock, response: any): void {
        const entity = this.entityDtoSelector(response);
        target.careActivityId = entity?.careActivityId;
        target.contentStore.setContent(entity?.content);
        target.textBlockTypeId = entity?.textBlockTypeId;
        target.lastModifiedBy = entity?.lastModifiedBy;
        target.lastModifiedAt = entity?.lastModifiedAt;
    }    
}