import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import PerformanceStatementCreateType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementCreateType.g";

interface IPerformanceStatementCreateTypeMultiSelectProps extends ISelectBoxBaseProps {
    value?: PerformanceStatementCreateType | PerformanceStatementCreateType[];
    onChange?: (newValue: PerformanceStatementCreateType | PerformanceStatementCreateType[]) => void;
}


const PerformanceStatementCreateTypeSelectBox: React.FC<IPerformanceStatementCreateTypeMultiSelectProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"PerformanceStatementCreateType"}
        enumOrigin="server"
        enumType={PerformanceStatementCreateType}
        displayMode="selectBox"
    />
);

export default PerformanceStatementCreateTypeSelectBox;