import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";
import ViewReactContext from "@Toolkit/ReactClient/Components/ViewContext/ViewReactContext";
import ViewContext from "@Toolkit/ReactClient/Components/ViewContext/ViewContext";

export default class ViewContextAdapter<TProps, TViewContextSettings> implements IComponentAdapter<TProps> {
    public get usedContexts(): Array<React.Context<any>> {
        return [ViewReactContext];
    }

    constructor(
        private readonly mapToProps: (props: TProps, settings: TViewContextSettings) => Partial<TProps>
    ) { }

    public getMappedProps(props: TProps, contextValues?: Map<React.Context<any>, any>): Partial<TProps> {
        const contextValue: ViewContext<TViewContextSettings> = contextValues.get(ViewReactContext);
        if (!contextValue) {
            return null;
        }
        return this.mapToProps(props, contextValue.getAll());
    }
}