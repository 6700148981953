import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PdfViewer2 from "@CommonControls/PdfViewer2";
import LargeDataToken from "@Primitives/LargeDataToken.g";

interface IBinaryDocumentViewer {
    downloadFileName: string;
    documentPath: LargeDataToken;
    printPermissionCheckOperationName?: string;
    isReadOnly?: boolean;
}

/** @screen */
@State.observer
export default class BinaryDocumentViewer extends React.Component<IBinaryDocumentViewer> {
    public render() {
        return (
            <PdfViewer2
                isReadOnly={this.props.isReadOnly}        
                documentPath={this.props.documentPath}
                downloadFileName={this.props.downloadFileName}
                printPermissionCheckOperationName={this.props.printPermissionCheckOperationName}
            />
        );
    }
}