import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import IReportingExtensionProps from "@PluginInterface/BoundedContexts/Reporting/IReportingExtensionProps";

@Di.injectable()
export default class ReportingExtensionComponentService implements IExtensionComponentService<IReportingExtensionProps> {

    public isVisible(props: IReportingExtensionProps) {
        return [
            "HunSocialSecurity_OutpatientActivityLog",
            "HunSocialSecurity_FinancedServiceMinimumTimeReport",
            "HunSocialSecurity_PerformanceReport"].includes(props.definition.reportDefinitionIdentifier.value);
    }

    public shouldRerunIsVisible(props: IReportingExtensionProps, prevProps: IReportingExtensionProps) {
        return props.definition.reportDefinitionIdentifier !== prevProps?.definition?.reportDefinitionIdentifier;
    }
}