import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/Proxy.g";
import { IOutpatientNeakReportFilter } from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/OutpatientNeakReportPanel/IOutpatientNeakReportFilter";
import Di from "@Di";

@Di.injectable()
export default class NeakPerformanceStatementDtoMapper {

    public getCreateNeakPerformanceReportDto(filter: IOutpatientNeakReportFilter) {
        return new Proxy.CreateNeakPerformanceStatementControllerDto(
            {
                filter: this.getOutpatientNeakReportFilterDto(filter)
            } as Proxy.ICreateNeakPerformanceStatementControllerDto);
    }

    public hasFinalizedNeakPerformanceStatementForMonthDto(filter: IOutpatientNeakReportFilter) {
        return new Proxy.HasFinalizedNeakPerformanceStatementForMonthControllerDto(
            {
                filter: this.getOutpatientNeakReportFilterDto(filter)
            } as Proxy.IHasFinalizedNeakPerformanceStatementForMonthControllerDto);
    }

    public getOutpatientNeakReportFilterDto(filter: IOutpatientNeakReportFilter) {
        return new Proxy.OutpatientNeakReportFilterDto(
            {
                reportValidityDate: filter.reportValidityDate,
                organizationUnitIds: filter.organizationUnitIds,
                financingClassIds: filter.financingClassIds,
                containsOnlyClosedCareActivities: filter.containsOnlyClosedCareActivities
            } as Proxy.IOutpatientNeakReportFilterDto);
    }
}