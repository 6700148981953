import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import PatientAgeStore from "./PatientAgeStore";

const { 
    ContextComponent: PatientAgeStoreContext, 
    StoreProvider: PatientAgeStoreProvider, 
    useStore: usePatientAgeStore,
    provideStore: providePatientAgeStore,
} = createPanelStoreProvider<PatientAgeStore>(PatientAgeStore);

export {
    PatientAgeStoreContext,
    PatientAgeStoreProvider,
    usePatientAgeStore,
    providePatientAgeStore
};