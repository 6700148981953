import IObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/IObservation";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import ObservationId from "@Primitives/ObservationId.g";
import ObservationDefinitionId from "@Primitives/ObservationDefinitionId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import CareContentId from "@Primitives/CareContentId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DocumentContentStore from "@CommonControls/DocumentEditor/DocumentContentStore";
import PatientId from "@Primitives/PatientId.g";

export default class TextualObservation implements IObservation {

    @State.observable.ref public isDirty: boolean = false;
    public readonly contentStore = DocumentContentStore.create();
    @State.observable.ref public isContentLoading = false;

    protected get propertyNamesExcludedFromDirtyCheck() {
        return [
            "contentStore",            
        ];
    }

    constructor(
        public readonly observationId: ObservationId,
        public readonly observationDefinitionId: ObservationDefinitionId,
        public readonly rowVersion: RowVersion,
        public readonly patientId: PatientId,
        public readonly careActivityId: CareActivityId,
        public readonly careContentId: CareContentId,
        public readonly stringContent: string = null
    ) {
    }

    @State.action.bound
    public startContentLoading() {
        this.isContentLoading = true;
    }

    @State.action.bound
    public initializeContent(newValue: string) {
        this.contentStore.setContent(newValue);
        this.isDirty = false;
        this.isContentLoading = false;
    }

    @State.action.bound
    public setContent(newValue: string) {
        this.contentStore.setContent(newValue);
        this.isDirty = true;
    }
}