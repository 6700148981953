import ValidationProblemProcessorService, { IValidationProblemProcessor } from "@Toolkit/CommonWeb/Validation/ValidationProblemProcessorService";
import DiagnosisStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisStore";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import _ from "@HisPlatform/Common/Lodash";
import DiagnosisId from "@Primitives/DiagnosisId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

abstract class DiagnosisValidationProblemProcessorBase implements IValidationProblemProcessor<DiagnosisStore> {

    constructor(public ruleId: string, public isRuleHighlightable: boolean) { }

    public abstract mapValidationProblem(problem: IClientValidationProblem, rows: DiagnosisStore[]): DiagnosisStore[];

    public renderInfo = (problem: IClientValidationProblem) => undefined as React.ReactNode;

    protected getDiagnosisId(problem: IClientValidationProblem) {
        const rawId = _.get(problem.parameters, "DiagnosisId.Value");
        return rawId ? new DiagnosisId(rawId.toString()) : null;
    }
}


class DiagnosisValidationProblemProcessorWithDiagnosisId extends DiagnosisValidationProblemProcessorBase {
    constructor(ruleId: string, isHighlightable: boolean, private dtoName: string = "") {
        super(ruleId, isHighlightable);
    }

    protected getDiagnosisId(problem: IClientValidationProblem) {
        const rawId = _.get(problem.parameters, this.dtoName === "" ? "DiagnosisId.Value" : this.dtoName + ".DiagnosisId.Value");
        return rawId ? new DiagnosisId(rawId.toString()) : null;
    }

    public mapValidationProblem(problem: IClientValidationProblem, rows: DiagnosisStore[]) {
        const diagnosisId = this.getDiagnosisId(problem);
        return rows.filter(i => ValueWrapper.equals(i.id, diagnosisId));
    }
}


class DiagnosisValidationProblemProcessorWithDiagnosisIds extends DiagnosisValidationProblemProcessorBase {
    private getDiagnosisIds(problem: IClientValidationProblem) {
        const ids = _.get(problem.parameters, "DiagnosisIds") as any[];
        const parsedIds = ids?.length > 0 && ids.map(i => new DiagnosisId(i?.Value?.toString()));
        return parsedIds;
    }

    public mapValidationProblem(problem: IClientValidationProblem, rows: DiagnosisStore[]) {
        const diagnosisIds = this.getDiagnosisIds(problem);
        return rows.filter(i => diagnosisIds.some(j => ValueWrapper.equals(i.id, j)));
    }
}


export default class DiagnosisValidationProblemProcessor extends ValidationProblemProcessorService<DiagnosisStore> {
    constructor() {
        super([
            new DiagnosisValidationProblemProcessorWithDiagnosisIds("OnlyOneDischargeDiagnosisShouldBeSelected", true),
            new DiagnosisValidationProblemProcessorWithDiagnosisIds("DischargeDiagnosisShouldNotBeAddedAsComorbityDiagnosis", true),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("PatientsAgeShouldBeAtMostTheAgeHighLimit", true, "DiagnosisListAgeRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("PatientsAgeShouldBeAtLeastTheAgeLowLimit", true, "DiagnosisListAgeRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("DischargeDiagnosisCodeShouldStartWithCorrectLetters", true, ""),
            new DiagnosisValidationProblemProcessorWithDiagnosisIds("OneDiagnosisShouldBeAddedOnlyOnceForOneRole", true),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("ContagiousPatientReportShouldBeFilled", true, "DiagnosisListContagiousPatientReportMissingViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("ShouldBeSetAccordingToRestriction", true, "DiagnosisListLateralityRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("LateralityShouldBeFilled", true, "DiagnosisListLateralityMissingViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("PatientsGenderShouldConformGenderDependence", true, "DiagnosisListGenderRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("DiagnosisShouldHaveOncologyData", true, "DiagnosisShouldHaveOncologyDataRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("CancerClassificationRequired1", true, "CancerClassificationRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("CancerClassificationRequired2", true, "CancerClassificationRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("CancerClassificationRequired3", true, "CancerClassificationRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("CancerClassificationRequired4", true, "CancerClassificationRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("OncologyDataHasError", true, "OncologyDataHasErrorParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("OncologyDataHasRequiredFieldsMissing", true),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("ExaminationModeMatchesFurtherTreatment", true, "OncologyRegisterExaminationModeRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("ExaminationModeMatchesExtension", true, "OncologyRegisterExaminationModeRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("CancerClassificationExtensionRequired1", true, "CancerClassificationRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("CancerClassificationExtensionRequired2", true, "CancerClassificationRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("CancerClassificationExtensionRequired3", true, "CancerClassificationRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("CancerClassificationDiagnosisRequired1", true, "CancerClassificationRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("CancerClassificationDiagnosisRequired2", true, "CancerClassificationRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("CancerClassificationDiagnosisRequired3", true, "CancerClassificationRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("CancerClassificationDiagnosisRequired4", true, "CancerClassificationRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("DiagnosisMatchesMorphology1", true, "DiagnosisMatchesMorphologyRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("DiagnosisMatchesMorphology2", true, "DiagnosisMatchesMorphologyRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("DiagnosisMatchesMorphology3", true, "DiagnosisMatchesMorphologyRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("DiagnosisMatchesMorphology4", true, "DiagnosisMatchesMorphologyRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("CancerClassificationMatchesMorphology", true, "CancerClassificationRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("DiagnosisCodeMatchesExtension1", true, "DiagnosisCodeMatchesExtensionRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("DiagnosisCodeMatchesExtension2", true, "DiagnosisCodeMatchesExtensionRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("DiagnosisCodeMatchesExtension3", true, "DiagnosisCodeMatchesExtensionRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("DiagnosisCodeMatchesExtension4", true, "DiagnosisCodeMatchesExtensionRuleViolationParameterDto"),
            new DiagnosisValidationProblemProcessorWithDiagnosisId("DiagnosisCodeMatchesExtension5", true, "DiagnosisCodeMatchesExtensionRuleViolationParameterDto")
        ]);
    }
}
