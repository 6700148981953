import RoleId from "@Primitives/RoleId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IPermissionDefinition from "@PluginInterface/OperationAccessControl/IPermissionDefinition";
import PermissionDefinitionsStore from "./PermissionDefinitionsStore";

export default class Role extends EntityStoreBase<RoleId> implements IDirtyChecked {
    @State.observable public shortName: string;
    @State.observable public name: string;
    @State.observable public description: string;
    public displayName: string = "-";
    @State.observable.ref public permissions: PermissionDefinitionsStore;

    constructor(id?: RoleId, shortName?: string, name?: string, description?: string) {
        super();
        this.id = id;
        this.shortName = shortName;
        this.name = name;
        this.description = description;
        this.permissions = new PermissionDefinitionsStore();
    }

    @State.action.bound
    public setShortName(newValue: string) {
        this.shortName = newValue;
    }

    @State.action.bound
    public setName(newValue: string) {
        this.name = newValue;
    }

    @State.action.bound
    public setDescription(newValue: string) {
        this.description = newValue;
    }

    public setDisplayName() {
        this.displayName = this.shortName
            ? `${this.shortName} - ${this.name}`
            : this.name;
    }

    @State.action.bound
    public setPermissions(definitions: IPermissionDefinition[]) {
        this.permissions.setDefinitions(definitions);
    }

    @State.computed
    public get numberOfPermissions() {
        return this.permissions.definitions.length;
    }

    private _dirtyChecker = new StoreDirtyChecker();

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "operationInfo"
    ];

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }
}
