import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IHunEHealthInfrastructureEesztIntegrationResources from "./IHunEHealthInfrastructureEesztIntegrationResources";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";

const resources = createStaticResourceDictionary<IHunEHealthInfrastructureEesztIntegrationResources>();

export function loadStaticHunEHealthInfrastructureEesztIntegrationResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    resources.load(localizationService.getResourceGroup("WebApp").EesztIntegration);
}

export default resources as IHunEHealthInfrastructureEesztIntegrationResources;
