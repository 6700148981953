import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import IScreenRegistry from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import PatientScreenContextData from "@HisPlatform/Services/Definition/ActionProcessing/PatientScreenContextData";
import TelemetrySessionScreen from "@AssecoMedPlugin/Packages/Care/Screens/TelemetrySessionScreen/TelemetrySessionScreen";
import CareScreenContextData from "@HisPlatform/Services/Definition/ActionProcessing/CareScreenContextData";
import ShowCreateNewTelemetrySessionScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowCreateNewTelemetrySessionScreenAction.g";
import ShowEditTelemetrySessionScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowEditTelemetrySessionScreenAction.g";
import ShowReadOnlyTelemetrySessionScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowReadOnlyTelemetrySessionScreenAction.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import { configureFrontendActionMappings } from "@AssecoMedPlugin/Packages/Care/FrontendActions/MapperConfigurations.g";
import ModalServiceRegistry from "@Toolkit/ReactClient/Components/ModalService/ModalServiceRegistry";
import TelemetrySessionMeasurementsDialogParams from "@AssecoMedPlugin/Packages/Care/Screens/TelemetrySessionMeasurementsDialog/TelemetrySessionMeasurementsDialogParams";
import TelemetrySessionMeasurementsDialog from "@AssecoMedPlugin/Packages/Care/Screens/TelemetrySessionMeasurementsDialog/TelemetrySessionMeasurementsDialog";
import React from "react";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import PatientNameColumn from "@HisPlatform/BoundedContexts/Care/Components/Controls/DataGridColumns/PatientNameColumn";
import ShowTelemetrySessionListScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowTelemetrySessionListScreenAction.g";
import TelemetrySessionListScreen from "@AssecoMedPlugin/Packages/Care/Screens/TelemetrySessionListScreen/TelemetrySessionListScreen";
import ShowCreateNewDecisionSupportScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowCreateNewDecisionSupportScreenAction.g";
import DecisionSupportScreen from "@AssecoMedPlugin/Packages/Care/Screens/DecisionSupportScreen/DecisionSupportScreen";
import ShowEditDecisionSupportScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowEditDecisionSupportScreenAction.g";
import ShowReadOnlyDecisionSupportScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowReadOnlyDecisionSupportScreenAction.g";
import DecisionSupportModalParams from "@AssecoMedPlugin/Packages/Care/Screens/DecisionSupportScreen/DecisionSupportModal/DecisionSupportModalParams";
import DecisionSupportModal from "@AssecoMedPlugin/Packages/Care/Screens/DecisionSupportScreen/DecisionSupportModal/DecisionSupportModal";
import StaticAssecoMedWebAppResources from "@AssecoMedPlugin/StaticResources/StaticAssecoMedWebAppResources";
import TelemedicineAppointmentDialogParams from "@AssecoMedPlugin/Packages/Care/Screens/TelemedicineScreen/TelemedicineAppointmentDialog/TelemedicineAppointmentDialogParams";
import TelemedicineAppointmentDialog from "@AssecoMedPlugin/Packages/Care/Screens/TelemedicineScreen/TelemedicineAppointmentDialog/TelemedicineAppointmentDialog";
import ShowEditTelemedicineScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowEditTelemedicineScreenAction.g";
import TelemedicineScreen from "@AssecoMedPlugin/Packages/Care/Screens/TelemedicineScreen/TelemedicineScreen";

class CarePackageInitializer implements IPackageInitializer {
    public configureMapper(configurator: IMapperConfigurator): void {
        configureFrontendActionMappings(configurator);
    }

    public configureNData(configurator: INDataAttributeConfigurator): void {
        configurator.registerColumn("PatientNameDisplayDto", <PatientNameColumn />);
    }

    public configureModals(modalServiceRegistry: ModalServiceRegistry): void {
        modalServiceRegistry.register(TelemetrySessionMeasurementsDialogParams.type, TelemetrySessionMeasurementsDialog);
        modalServiceRegistry.register(DecisionSupportModalParams.type, DecisionSupportModal);
        modalServiceRegistry.register(TelemedicineAppointmentDialogParams.type, TelemedicineAppointmentDialog);
    }

    public configureScreens(registry: IScreenRegistry): void {
        registry.register<ShowCreateNewTelemetrySessionScreenAction>(
            ShowCreateNewTelemetrySessionScreenAction.id,
            {
                component: TelemetrySessionScreen as any,
                getScreenContextData: (a: ShowCreateNewTelemetrySessionScreenAction) => {
                    return new PatientScreenContextData(a.patientId);
                }
            },
            {
                displayName: StaticAssecoMedWebAppResources.TelemetrySessionScreen.Buttons.CreateNew,
                iconName: "plus"
            }
        );

        registry.register<ShowEditTelemetrySessionScreenAction>(
            ShowEditTelemetrySessionScreenAction.id,
            {
                component: TelemetrySessionScreen as any,
                getScreenContextData: (a: ShowEditTelemetrySessionScreenAction) => {
                    return new CareScreenContextData(a.careActivityId);
                }
            },
            {
                displayName: StaticWebAppResources.Common.Button.Edit,
                iconName: "pen"
            }
        );

        registry.register<ShowEditTelemedicineScreenAction>(
            ShowEditTelemedicineScreenAction.id,
            {
                component: TelemedicineScreen as any
            },
            {
                displayName: StaticWebAppResources.Common.Button.Edit,
                iconName: "pen"
            }
        );

        registry.register<ShowReadOnlyTelemetrySessionScreenAction>(
            ShowReadOnlyTelemetrySessionScreenAction.id,
            {
                component: TelemetrySessionScreen as any,
                getScreenContextData: (a: ShowReadOnlyTelemetrySessionScreenAction) => {
                    return new CareScreenContextData(a.careActivityId);
                }
            },
            {
                displayName: StaticWebAppResources.Common.Button.Edit,
                iconName: "pen"
            }
        );
        
        registry.register(ShowTelemetrySessionListScreenAction.id, {
            component: TelemetrySessionListScreen,
        });

        registry.register<ShowCreateNewDecisionSupportScreenAction>(
            ShowCreateNewDecisionSupportScreenAction.id,
            {
                component: DecisionSupportScreen as any,
                getScreenContextData: (a: ShowCreateNewDecisionSupportScreenAction) => {
                    return new PatientScreenContextData(a.patientId);
                }
            },
            {
                displayName: StaticAssecoMedWebAppResources.DecisionSupportScreen.Buttons.CreateNew,
                iconName: "cardiology"
            }
        );

        registry.register<ShowEditDecisionSupportScreenAction>(
            ShowEditDecisionSupportScreenAction.id,
            {
                component: DecisionSupportScreen as any,
                getScreenContextData: (a: ShowEditDecisionSupportScreenAction) => {
                    return new CareScreenContextData(a.careActivityId);
                }
            },
            {
                displayName: StaticWebAppResources.Common.Button.Edit,
                iconName: "pen"
            }
        );

        registry.register<ShowReadOnlyDecisionSupportScreenAction>(
            ShowReadOnlyDecisionSupportScreenAction.id,
            {
                component: DecisionSupportScreen as any,
                getScreenContextData: (a: ShowReadOnlyDecisionSupportScreenAction) => {
                    return new CareScreenContextData(a.careActivityId);
                }
            },
            {
                displayName: StaticWebAppResources.Common.Button.Edit,
                iconName: "pen"
            }
        );
    }
}

export default new CarePackageInitializer();