import Dashboard from "@CommonControls/Dashboard";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "@HisPlatform/Common/Lodash";
import Styles from "./CareActivityWidget.less";
import { useCareActivityContext } from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContext";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { getOrganizationUnitTypeIconNameByTag } from "@HisPlatform/BoundedContexts/Organization/Components/Helpers/IconTypeNameHelper";
import Icon, { iconNameType } from "@CommonControls/Icon";
import PersonNameLabel from "@CommonControls/PersonNameLabel";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import ITelecommunicationCallControlExtensionPointProps from "@PluginInterface/BoundedContexts/Telecommunication/ITelecommunicationCallControlExtensionPointProps";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import CareActivityStateIcon from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/CareActivityStateIcon/CareActivityStateIcon";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import CareActivityMenu2 from "@HisPlatformControls/CareActivityMenu/CareActivityMenu";
import { useAsyncMemo } from "@Toolkit/ReactClient/Common/Hooks";
import { usePatientContext } from "@HisPlatform/Model/DomainModel/PatientContext/PatientContext";
import PatientName from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/PatientName";
import Button from "@CommonControls/Button";
import Shortcut from "@Toolkit/ReactClient/Components/Shortcut/Shortcut";
import config from "@Config";

interface ICareActivityWidgetProps {
}

interface ICareActivityMenuItemProps {
    careActivityId: CareActivityId;
    careActivityState: CareActivityState;
    finalDocumentTypeId: DocumentTypeId;
}

function CareActivityWidget(props: ICareActivityWidgetProps) {
    const careContext = useCareActivityContext();
    const { localizationService, organizationReferenceDataStore, globalRoutingStore, dynamicNavigationRegistry } = useDependencies(c => ({
        localizationService: c.resolve<ILocalizationService>("ILocalizationService"),
        organizationReferenceDataStore: c.resolve<OrganizationReferenceDataStore>("OrganizationReferenceDataStore"),
        globalRoutingStore: c.resolve<GlobalRoutingStore>("GlobalRoutingStore"),
        dynamicNavigationRegistry: c.resolve<IDynamicNavigationRegistry>("IDynamicNavigationRegistry"),
    }));

    const iconName = useAsyncMemo<iconNameType>("add_healthcare", async () => {
        if (careContext?.careActivity?.pointOfCareId) {
            const organizationUnit = await organizationReferenceDataStore.organizationUnitMap.getOrLoadAsync(careContext.careActivity.pointOfCareId);
            const organizationDefinition = await organizationReferenceDataStore.organizationUnitDefinitionMap.getOrLoadAsync(organizationUnit.organizationUnitDefinitionId);
            return getOrganizationUnitTypeIconNameByTag(organizationDefinition?.tags);
        }
        return "add_healthcare";
    }, [careContext?.careActivity?.pointOfCareId?.value]);

    const pointOfCareShorthand = useMemo(() => {
        if (careContext?.careActivity?.pointOfCareId) {
            const pointOfCare = organizationReferenceDataStore.allPointsOfCareMap.get(careContext.careActivity.pointOfCareId);
            return pointOfCare?.shorthand ?? "";
        }
        return "";
    }, [careContext?.careActivity?.pointOfCareId?.value]);

    const doctor = useMemo(() => careContext?.baseData?.primaryParticipant ? organizationReferenceDataStore.doctorMap.get(careContext.baseData.primaryParticipant) : null,
        [careContext?.baseData?.primaryParticipant?.value]);

    const wentUnderCareAt = useMemo(() => careContext?.baseData?.wentUnderCareAt && localizationService.localizeDateTime(careContext.baseData.wentUnderCareAt), [careContext?.baseData?.wentUnderCareAt]);
    const dischargedAt = useMemo(() => careContext?.dischargeData?.dischargedAt && localizationService.localizeDateTime(careContext.dischargeData.dischargedAt), [careContext?.dischargeData?.dischargedAt]);

    const [isMenuOpen, setMenuOpen] = useState(false);

    const openMenu = useCallback(() => {
        setMenuOpen(true);
    }, [setMenuOpen]);

    const closeMenu = useCallback(() => {
        setMenuOpen(false);
    }, [setMenuOpen]);

    const telecommunicationCallControlExtensionPointProps: ITelecommunicationCallControlExtensionPointProps = {
        careActivityId: careContext?.careActivityId
    };

    const patientContext = usePatientContext();

    if (!careContext?.baseData || !patientContext?.patient || iconName.isLoading) {
        return null;
    }

    return (
        <>
            {isMenuOpen && (
                <CareActivityMenu2
                    onClose={closeMenu}
                    pointOfCareId={careContext.careActivity.pointOfCareId}
                    careActivityState={careContext.careActivity.state}
                    primaryCareIdentifier={careContext.baseData.primaryCareIdentifier}
                    primaryParticipant={careContext.baseData.primaryParticipant}
                    wentUnderCareAt={careContext.baseData.wentUnderCareAt}
                    dischargedAt={careContext.dischargeData?.dischargedAt}
                    careActivityId={careContext.careActivityId}
                    patientBirthDate={patientContext.patient.baseData.ageInfo.birthDate}
                    patientName={new PatientName(patientContext.patient.baseData.name, false, patientContext.patient.baseData.genderId, false, false)}
                />
            )}
            <Dashboard.Widget
                name="CareActivityWidget"
                colorStyle="light"
                isCloseByDefault={false}
                icon={<CareActivityStateIcon state={careContext.careActivity.state} className={Styles.statusBadge} />}
                isCollapsible={false}
                title={`# ${careContext?.baseData.primaryCareIdentifier}`}
                automationId="CareActivityWidget"
                iconStyle={{ marginRight: 6 }}
                toolbar={(
                    <Icon iconName={iconName.value} visualStyle="white" style={{ marginRight: 10 }} />
                )}
                smallContent={(
                    <CareActivityStateIcon state={careContext.careActivity.state} />
                )}
            >
                <div className={Styles.careInfoContainer}>
                    <div className={Styles.row}>
                        <div className={Styles.info}>
                            <section style={{ flex: "1 0 0" }}><Icon iconName="pin" visualStyle="white" size="compact" />&nbsp;{pointOfCareShorthand}</section>
                            {doctor && <section style={{ flex: "3 0 0" }}><b><PersonNameLabel personName={doctor.name} /></b>&nbsp;({doctor.code})</section>}
                        </div>
                        <Button visualStyle="primary" iconName="angleRight" className={Styles.openMenuButton} automationId="standaloneFunctionsMenuOpenButton" onClick={openMenu} />
                    </div>
                    <section><Icon iconName="clock" size="compact" visualStyle="white" />&nbsp;{wentUnderCareAt} - {dischargedAt}</section>
                </div>

                <HisPlatformExtensionPoint
                    extensionProps={telecommunicationCallControlExtensionPointProps}
                    type="telecommunicationCallControlExtension">
                    <></>
                </HisPlatformExtensionPoint>
            </Dashboard.Widget>

            <Shortcut keys={config.keyboardShortcuts.openCareMenu} onPressed={openMenu} />
        </>
    );

}

export default State.observer(CareActivityWidget);