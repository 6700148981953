import StoreMapper from "@Toolkit/CommonWeb/ApiAdapter/StoreMapper";
import IPatientInCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/IPatientInCareActivityStore";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import Di from "@Di";

@Di.injectable()
export default class CareActivityDetailStoreMapper extends StoreMapper<IPatientInCareActivityStore, Proxy.GetCareActivityDetailsQueryResponse> {

    protected applyToStoreCore(target: IPatientInCareActivityStore, response: Proxy.GetCareActivityDetailsQueryResponse): void {
        target.patientId = response.careActivity.patientData.id;
    }

}