import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ReadOnlyContextAdapter from "@Toolkit/ReactClient/Components/ReadOnlyContext/ReadOnlyContextAdapter";
import React, { CSSProperties, ReactNode } from "react";
import { MenuProvider as ContextifyMenuProvider } from "react-contexify";

interface IMenuProviderProps {
    id: string | number;
    children: ReactNode;
    event?: "onClick" | "onContextMenu";
    isReadOnly?: boolean;
    className?: string;
    style?: CSSProperties;
}

function MenuProvider(props: IMenuProviderProps) {
    return (
        <ContextifyMenuProvider
            id={props.id}
            event={props.isReadOnly ? null : props.event}
            className={props.className}
            style={props.style} >
            {props.children}
        </ContextifyMenuProvider>
    );
}

export default connect<IMenuProviderProps>(
    MenuProvider,
    new ReadOnlyContextAdapter()
);