import React, { useMemo } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import EditableLayoutCustomBlockStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableLayoutCustomBlockStore";

function CustomBlockPropertyPanel() {

    const store = useFormLayoutEditorStore();

    if (!store.selectedCustomBlock || !(store.selectedCustomBlock instanceof EditableLayoutCustomBlockStore)) {
        return null;
    }

    const panelComponent = store.formEditorRegistry.tryGetCustomBlockPropertyPanelComponent(store.selectedCustomBlock.customBlockIdentifier);
    return panelComponent && React.createElement(panelComponent);
}

export default State.observer(CustomBlockPropertyPanel);