import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IPatientPractitionerTypeSceletBoxDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IPatientPractitionerTypeSceletBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IPatientPractitionerTypeSceletBoxDependencies;
}

@State.observer
class PatientPractitionerTypeSelectBox extends React.Component<IPatientPractitionerTypeSceletBoxProps> {
    private get dependencies() { return this.props._dependencies; }
    private get extensibleEnumStore() { return this.dependencies.careReferenceDataStore.patientPractitionerType; }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                clearable={false}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    PatientPractitionerTypeSelectBox,
    new DependencyAdapter<IPatientPractitionerTypeSceletBoxProps, IPatientPractitionerTypeSceletBoxDependencies>( (containerService) => {
        return {
            careReferenceDataStore: containerService.resolve("CareReferenceDataStore")
        };
    })
);
