import React, { useMemo } from "react";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import moment from "moment";

interface ILocalizedDateTimeDependencies {
    localizationService: IToolkitLocalizationService;
}

interface ILocalizedDateTimeProps {
    _dependencies?: ILocalizedDateTimeDependencies;
    value: moment.Moment | string;
    useTodayString?: boolean;
    showSeconds?: boolean; 
}


const LocalizedDateTime: React.FC<ILocalizedDateTimeProps> = props => {
    const localized = useMemo(() => {
        const toLocalize = typeof props.value === "string" ? moment(props.value) : props.value;
        return props._dependencies.localizationService.localizeDateTime(toLocalize, props.showSeconds ?? true, props.useTodayString);
    }, [props.value, props.useTodayString]);
    return <>{localized}</>;
};

export default connect(
    LocalizedDateTime,
    new DependencyAdapter<ILocalizedDateTimeProps, ILocalizedDateTimeDependencies>( c => ({
        localizationService: c.resolve("IToolkitLocalizationService")
    }))
);