import React, { useMemo } from "react";
import * as Ui from "@CommonControls";
import ToolboxItem from "./ToolboxItem";
import IDragData from "./IDragData";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { useFormLayoutEditorStore } from "./FormLayoutEditorStoreProvider";
import {GroupBox, TextBox, Button, UniversalEnumSelector, CheckBox, NumberBox} from "@CommonControls";
import EditableDataElementEditorStore from "./Model/EditableDataElementEditorStore";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import ColumnPropertyPanel from "./PropertyPanels/ColumnPropertyPanel";
import EditorPropertyPanel from "./PropertyPanels/EditorPropertyPanel";
import GroupPropertyPanel from "./PropertyPanels/GroupPropertyPanel";
import EditableLayoutCustomBlockStore from "./Model/EditableLayoutCustomBlockStore";
import FormEditBehavior from "@Toolkit/FormEngine/Model/FormEditBehavior";
import FormLogicGroupBoxPanel from "./ToolBoxPanels/FormLogicGroupBoxPanel";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import Language from "@Primitives/Language";
import CustomBlockPropertyPanel from "./PropertyPanels/CustomBlockPropertyPanel";

const row: IDragData = { isNew: true, dragItemType: "block", type: "row" };
const group: IDragData = { isNew: true, dragItemType: "block", type: "group" };

function SideToolbox() {

    const store = useFormLayoutEditorStore();

    const editorNewItems = useMemo(() => {
        return store.formEditorRegistry.getNewToolboxEditorItems().map(i => ({
            editorType: i.editorType,
            dragData: { isNew: true, dragItemType: "editor", type: "editor", store: new EditableDataElementEditorStore(i.editorType, store.cultureCodeProvider.cultureCode) } as IDragData,
            displayName: i.displayName,
        }));
    }, []);

    const editorTokenItems = useMemo(() => {
        return store.tokens?.map(i => {
            return {
                symbol: i.symbol,
                dragData: {
                    isNew: true,
                    dragItemType: "fragment",
                    type: "fragment",
                    store: i.tokenLayout
                } as IDragData,
                displayName: i.name,
                description: i.description
            };
        });
    }, [store.tokens]);

    const editorCustomBlocks = useMemo(() => {
        return store.formEditorRegistry.getNewToolboxCustomBlockItems().map(i => ({
            customBlockIdentifier: i.customBlockIdentifier,
            dragData: { isNew: true, dragItemType: "block", type: "fragment", store: new EditableLayoutCustomBlockStore(i.customBlockIdentifier) } as IDragData,
            displayName: i.displayName,
        }));
    }, []);

    return (
        <div style={{ height: "calc(100% - 20px)", overflowY: "auto" }}>

            <CustomBlockPropertyPanel />
            <EditorPropertyPanel />
            <GroupPropertyPanel />
            <ColumnPropertyPanel />

            {store.isSomethingSelected && (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <Button
                        visualStyle="primary"
                        automationId="propertyPanelClose_Button"
                        text={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.OkButtonLabel}
                        onClick={store.clearSelection}
                    />
                </div>
            )}

            {!store.isSomethingSelected && (
                <>
                    <GroupBox title={StaticFormEngineResources.FormDefinitionEditor.SideToolbox.GroupTitle.FormSettings} isCollapsible defaultIsOpen={false}>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={7}>
                                <Ui.TextBox
                                    label={StaticFormEngineResources.FormDefinitionEditor.SideToolbox.FormSettings.NameEditorLabel}
                                    automationId="FormTitle_TextBox"
                                    value={store.localizedLabel}
                                    onChange={store.setLocalizedLabel}
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={5}>
                                <Ui.UniversalEnumSelector
                                    label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.LanguageSelectorLabel}
                                    enumName={"Language"}
                                    enumType={Language}
                                    enumOrigin="client"
                                    value={store.currentLabelLanguage}
                                    onChange={store.setCurrentLabelLanguage}
                                    automationId="LanguageSelector"
                                    clearable={false}
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <TextBox
                            label={StaticFormEngineResources.FormDefinitionEditor.SideToolbox.FormSettings.TechnicalNameEditorLabel}
                            automationId="Form_Name"
                            value={store.name}
                            isReadOnly
                        />
                        <TextBox
                            label={StaticFormEngineResources.FormDefinitionEditor.SideToolbox.FormSettings.BaseEntityEditorLabel}
                            automationId="FormBaseEntityType_TextBox"
                            value={store.baseEntityType}
                            onChange={store.setBaseEntityType}
                        />
                        <UniversalEnumSelector
                            label={StaticFormEngineResources.FormDefinitionEditor.SideToolbox.FormSettings.FormEditBehaviorLabel}
                            enumName={"FormEditBehavior"}
                            enumType={FormEditBehavior}
                            boundedContextNameForLocalization={"FormEngine"}
                            automationId={"FormEditBehavior_SelectBox"}
                            value={store.formEditBehavior}
                            onChange={store.setFormEditBehavior}
                            enumOrigin={"client"}
                            displayMode={"selectBox"}
                            clearable={false}
                        />
                        <CheckBox
                            label={StaticFormEngineResources.FormDefinitionEditor.SideToolbox.FormSettings.WithoutDataHandlingLabel}
                            automationId="WithoutDataHandling_CheckBox"
                            value={store.withoutDataHandling}
                            onChange={store.setWithoutDataHandling}
                        />
                        <CheckBox
                            label={StaticFormEngineResources.FormDefinitionEditor.SideToolbox.FormSettings.IsUserFormLabel}
                            automationId="IsUserForm_CheckBox"
                            value={store.isUserForm}
                            onChange={store.setIsUserForm}
                        />
                        <NumberBox 
                            label={StaticFormEngineResources.FormDefinitionEditor.SideToolbox.FormSettings.InstanceLimitLabel}
                            automationId="InstanceLimit_NumberBox"
                            value={store.instanceLimit}
                            onChange={store.setInstanceLimit}
                            clearable
                        />
                    </GroupBox>

                    <GroupBox title={StaticFormEngineResources.FormDefinitionEditor.SideToolbox.GroupTitle.NewFields} isCollapsible>
                        {editorNewItems.map(i => (
                            <ToolboxItem key={i.editorType} data={i.dragData} displayName={i.displayName} />
                        ))}
                    </GroupBox>

                    <GroupBox title={StaticFormEngineResources.FormDefinitionEditor.SideToolbox.GroupTitle.NewTokens} isCollapsible defaultIsOpen={false}>
                        {editorTokenItems?.map(i => (
                            <ToolboxItem key={i.symbol} data={i.dragData} displayName={i.displayName} tooltip={i.description} />
                        ))}
                    </GroupBox>

                    <GroupBox title={StaticFormEngineResources.FormDefinitionEditor.SideToolbox.GroupTitle.CustomBlocks} isCollapsible defaultIsOpen={false}>
                        {editorCustomBlocks.map(i => (
                            <ToolboxItem key={i.customBlockIdentifier} data={i.dragData} displayName={i.displayName} />
                        ))}
                    </GroupBox>

                    <GroupBox title={StaticFormEngineResources.FormDefinitionEditor.SideToolbox.GroupTitle.BuildingBlocks} isCollapsible defaultIsOpen={false}>
                        <ToolboxItem data={group} displayName={StaticFormEngineResources.FormDefinitionEditor.SideToolbox.GenericBuildingBlockLabel.Group} />
                        <ToolboxItem data={row} displayName={StaticFormEngineResources.FormDefinitionEditor.SideToolbox.GenericBuildingBlockLabel.Row} />
                    </GroupBox>

                    <FormLogicGroupBoxPanel />

                </>
            )}
        </div>
    );
}

export default State.observer(SideToolbox);
