import State from "@Toolkit/ReactClient/Common/StateManaging";
import ConditionAssignmentBase from "./ConditionAssignmentBase";

export default class CustomConditionAssignment extends ConditionAssignmentBase  {
    @State.observable.ref public name: string;

    constructor(name: string) {
        super();
        this.name = name;
    }

    @State.action.bound
    public setName(newValue: string) {
        return this.name = newValue;
    }
}