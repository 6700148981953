import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientId from "@Primitives/PatientId.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { parseUseCaseStateFromUrlParam, getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import IDeviceUseStatementRouteParams from "@HisPlatform/Application/Routes/IDeviceUseStatementRouteParams";
import DeviceUseStatementId from "@Primitives/DeviceUseStatementId.g";
import DeviceUseStatementListPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/DeviceUseStatement/DeviceUseStatementListPanel";
import PatientRoutes from "@HisPlatform/Components/Pages/Patient/PatientRoutes";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";


interface IDeviceUseStatementPageProps extends IRoutingFrameContentProps {
}

@State.observer
class DeviceUseStatementPage extends React.Component<IDeviceUseStatementPageProps> {

    @State.computed private get routeParams(): IDeviceUseStatementRouteParams {
        return this.props.routingController.currentRoute.parameters as IDeviceUseStatementRouteParams;
    }

    @State.computed private get selectedDeviceUseStatementId(): DeviceUseStatementId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments[0].value;
        }

        if (this.useCaseState?.useCase.value === UseCases.deviceUseStatementDetail) {
            return new DeviceUseStatementId("new");
        }

        return null;
    }

    @State.computed
    private get patientId() {
        return new PatientId(this.routeParams?.patientId);
    }

    @State.computed private get useCaseState() {
        const useCaseState = this.routeParams?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.bound
    private setUseCaseState(selectedDeviceUseStatementId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.replace(
            PatientRoutes.deviceUseStatement.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            })
        );
    }

    @State.bound
    private navigateToDeviceUseStatement(deviceUseStatementId: DeviceUseStatementId) {
        this.props.routingController.replace(
            PatientRoutes.deviceUseStatement.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.deviceUseStatementDetail),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(deviceUseStatementId, "deviceUseStatementId")])
            })
        );
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(
            PatientRoutes.deviceUseStatement.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    null,
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument(new DeviceUseStatementId("null"), "deviceUseStatementId")])
            })
        );
    }

    public render() {
        return (
            <LoadingBoundary>
                <DeviceUseStatementListPanel
                    _patientId={this.patientId}
                    onChange={this.setUseCaseState}
                    useCaseState={this.useCaseState}
                    selectedRowId={this.selectedDeviceUseStatementId?.value}
                    onSelectedDeviceUseStatementIdChange={this.navigateToDeviceUseStatement}
                    onBack={this.goBack}
                    onCloseDetail={this.setClosedState}
                />
            </LoadingBoundary>
        );
    }
}

export default connect(
    DeviceUseStatementPage
);
