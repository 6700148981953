import React from "react";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";

interface IAssecoRoomsSpikeMenuDependencies {
    applicationContext: ApplicationContext;
}

interface IAssecoRoomsSpikeMenuProps {
    _dependencies?: IAssecoRoomsSpikeMenuDependencies;
}

class AssecoRoomsSpikeMenu extends React.Component<IAssecoRoomsSpikeMenuProps> {

    private get applicationContext() { return this.props._dependencies.applicationContext; }

    public render() {
        return (
            <Ui.NavBar.Item onClick={this.applicationContext.spike_toggleAssecoRooms} automationId="asseco-rooms">SPIKE: Asseco Rooms</Ui.NavBar.Item>
        );
    }
}

export default connect(
    AssecoRoomsSpikeMenu,
    new DependencyAdapter<IAssecoRoomsSpikeMenuProps, IAssecoRoomsSpikeMenuDependencies>(c => ({
        applicationContext: c.resolve("ApplicationContext"),
    }))
);
