import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DeviceUseStatementId from "@Primitives/DeviceUseStatementId.g";
import DeviceAssignmentBase from "./DeviceAssignmentBase";
import ReferencedDeviceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/ReferencedDeviceAssignment";
import { DeviceCodeSelectorItem } from "@HisPlatformControls/DeviceCodeSelector/DeviceCodeSelectorItem";
import CustomDeviceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/CustomDeviceAssignment";
import MedicalConditionItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionItemBase";

export default class DeviceUseStatementStore extends MedicalConditionItemBase<DeviceUseStatementId> {

    @State.observable.ref public deviceAssignment: DeviceAssignmentBase;
    @State.observable public extendedDeviceName: string;
    @State.observable public deviceName: string;

    constructor(isNew: boolean) {
        super(isNew);
    }

    @State.computed public get deviceCodeSelectorItem() {
        if (!this.deviceAssignment) {
            return null;
        }
        if (this.deviceAssignment instanceof ReferencedDeviceAssignment) {
            return new DeviceCodeSelectorItem({ id: this.deviceAssignment.deviceId });
        } else if (this.deviceAssignment instanceof CustomDeviceAssignment) {
            return new DeviceCodeSelectorItem({ text: this.deviceAssignment.name });
        }
        return null;
    }    

    @State.action.bound
    public setDeviceAssignment(newValue: DeviceAssignmentBase) {
        this.deviceAssignment = newValue;
    }

    @State.action.bound
    public setExtendedDeviceName(newValue: string) {
        this.extendedDeviceName = newValue;
    }

    @State.action.bound
    public setDeviceName(newValue: string) {
        this.deviceName = newValue;
    }
}