import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import UseCases from "@Primitives/UseCases";
import StandaloneAppointmentRoutes from "@HisPlatform/Components/Pages/AppointmentPages/StandaloneAppointmentRoutes";
import IUseCaseDescriptor2 from "@PluginInterface/UseCases/IUseCaseDescriptor2";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import AppointmentDetailPanel, { IAppointmentDetailPanelProps } from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/AppointmentDetailPanel";
import { getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import PatientId from "@Primitives/PatientId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import moment from "moment";
import ScheduleAppointmentsDialog, { IScheduleAppointmentsDialogProps } from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/ScheduleAppointmentsDialog/ScheduleAppointmentsDialog";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ComplexScheduleAppointmentsDialog, { IComplexScheduleAppointmentsDialogProps } from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/ComplexScheduleAppointmentsDialog/ComplexScheduleAppointmentsDialog";
import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";

export default function configureAppointmentUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            UseCases.patientRegisterViewAppointments,
            builder => {
                builder.standalone(b => b.routeFactory((params) => {
                    const patientId = params.useCaseArguments.find(item => item.value instanceof PatientId);
                    const careActivityId = params.useCaseArguments.find(item => item.value instanceof CareActivityId);
                    return StandaloneAppointmentRoutes.registeredPatientAppointments.makeRoute({
                        patientId: patientId?.value.value.toString(),
                        careActivityId: careActivityId?.value.value.toString(),
                        useCase: getUseCaseAsUrlParam(
                            new UseCaseIdentifier(UseCases.patientRegisterViewAppointments),
                            UseCaseDisplayMode.Full,
                            [new UseCaseArgument(new AppointmentScheduleEntryId("null"), "appointmentId")])
                    });
                }));
            }
        ),
    );

    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            UseCases.patientRegisterCreateNewAppointment,
            builder => {
                builder
                    .standalone(b => b.routeDefinition(StandaloneAppointmentRoutes.registeredPatientAppointments)
                        .additionalRouteParams({
                            useCase: getUseCaseAsUrlParam(
                                new UseCaseIdentifier(UseCases.newAppointment),
                                UseCaseDisplayMode.MasterDetail,
                                [new UseCaseArgument(new AppointmentScheduleEntryId("new"), "appointmentId")])
                        }));
            }
        ),
    );

    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            UseCases.newUnregisteredPatientAppointment,
            builder => {
                builder
                    .standalone(b => b.routeDefinition(StandaloneAppointmentRoutes.unregisteredPatientAppointments)
                        .additionalRouteParams({
                            appointmentId: "new"
                        }));
            }
        ),
    );

    useCaseRegistry.add2<IAppointmentDetailPanelProps>(
        {
            identifiers: [UseCases.editAppointmentFromList],
            component: AppointmentDetailPanel,
            mapUseCaseArgumentsToProps: args => {
                const appointmentId = args.find(a => a.value instanceof AppointmentScheduleEntryId).value;

                return {
                    appointmentId: appointmentId
                };
            }
        } as IUseCaseDescriptor2<IAppointmentDetailPanelProps>
    );

    useCaseRegistry.add2<IAppointmentDetailPanelProps>(
        {
            identifiers: [UseCases.newAppointment],
            component: AppointmentDetailPanel,
            mapUseCaseArgumentsToProps: args => {
                const appointmentId = new AppointmentScheduleEntryId("new");
                const serviceId = args.find(arg => arg.name === "serviceId")?.value;
                const afterDate = args.find(arg => arg.name === "afterDate")?.value;
                const proposeAfterDate = args.find(arg => arg.name === "proposeAfterDate")?.value;

                return {
                    appointmentId: appointmentId,
                    serviceId: serviceId,
                    afterDate: !!afterDate ? moment.unix(parseInt(afterDate, 10)) : null,
                    proposeAfterDate: !!proposeAfterDate
                };
            }
        } as IUseCaseDescriptor2<IAppointmentDetailPanelProps>
    );

    useCaseRegistry.add2<IScheduleAppointmentsDialogProps>(
        {
            identifiers: [UseCases.scheduleAppointments],
            component: ScheduleAppointmentsDialog,
        } as IUseCaseDescriptor2<IScheduleAppointmentsDialogProps>
    );

    useCaseRegistry.add2<IComplexScheduleAppointmentsDialogProps>(
        {
            identifiers: [UseCases.complexScheduleAppointments],
            component: ComplexScheduleAppointmentsDialog,
        } as IUseCaseDescriptor2<IComplexScheduleAppointmentsDialogProps>
    );

    useCaseRegistry.addScreenAdapter(UseCases.schedulingAdmitPatientWithAppointment, (identifier, args) => {
        return new ActionDescriptor(
            new ShowNewAdmitPatientScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "patientId")?.value,
                args.find(a => a.name === "appointmentScheduleEntryId")?.value,
                args.find(a => a.name === "serviceRequestId")?.value),
            null,
            []
        );
    });

    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            UseCases.editAppointment,
            builder => {
                builder
                    .standalone(b => b.routeFactory((params) => {
                        const patientId = params.useCaseArguments.find(item => item.value instanceof PatientId);
                        const appointmentId = params.useCaseArguments.find(item => item.value instanceof AppointmentScheduleEntryId);
                        return patientId
                            ? StandaloneAppointmentRoutes.registeredPatientAppointments.makeRoute({
                                patientId: patientId.value.value.toString(),
                                useCase: getUseCaseAsUrlParam(
                                    new UseCaseIdentifier(UseCases.editAppointmentFromList),
                                    UseCaseDisplayMode.MasterDetail,
                                    [new UseCaseArgument(appointmentId?.value, "appointmentId")])
                            })
                            : StandaloneAppointmentRoutes.unregisteredPatientAppointments.makeRoute({ appointmentId: appointmentId?.value.value.toString() });
                    }
                    ));
            }
        ),
    );
}
