import Di from "@Di";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import { IDocumentScreenProps } from "./DocumentScreen";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import DocumentScreenApiAdapter from "./DocumentScreenApiAdapter";
import LockAcquirerOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockAcquirerOperationInfo";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import DocumentAction from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentAction.g";
import DocumentKind from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentKind.g";
import DocumentState from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentState.g";
import BinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/BinaryDocument";
import DocumentBase from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentBase";
import DocumentInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentInfo";
import TemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TemplateBasedDocument";
import TokenLockInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TokenLockInfo";
import { DocumentHistoryModalParams } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Modals/DocumentHistoryModal/DocumentHistoryModal";
import DocumentPreviewModalParams from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Modals/DocumentPreviewModal/DocumentPreviewModalParams";
import CreateNewDocumentDialogParams, { ICreateNewDocumentDialogResult } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/CreateNewDocumentDialog/CreateNewDocumentDialogParams";
import { ITokenLockService } from "@HisPlatform/BoundedContexts/DocumentManagement/Services/Definition/ITokenLockService";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import ShowCreateNewPatientRelatedDocumentScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowCreateNewPatientRelatedDocumentScreenAction.g";
import ShowPatientRelatedDocumentScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientRelatedDocumentScreenAction.g";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import UpdatePatientRelatedDocumentAction from "@HisPlatform/Packages/Patients/FrontendActions/UpdatePatientRelatedDocumentAction.g";
import PrintPatientRelatedDocumentPreviewAction from "@HisPlatform/Packages/Patients/FrontendActions/PrintPatientRelatedDocumentPreviewAction.g";

@Di.injectable()
export default class DocumentScreenStore extends EditorScreenPanelStoreBase<IDocumentScreenProps> {
    @State.observable.ref public document: DocumentBase = null;
    @State.observable.ref public createDocumentDialogResult: ICreateNewDocumentDialogResult = null;
    public readonly screenResources = StaticDocumentManagementResources.DocumentManagementMasterDetailPanel;

    @State.computed
    protected get showScreenAction() {
        return this.props.action;
    }

    @State.computed
    protected get contentToDirtyCheck() {
        return this.isBinaryDocument ? [] : [this.templateDocument?.documentFile.contentStore.getContent()];
    }

    @State.computed
    public get isCreateNewDocumentScreen() {
        return this.props.action instanceof ShowCreateNewPatientRelatedDocumentScreenAction;
    }

    @State.computed
    public get isDocumentScreen() {
        return this.props.action instanceof ShowPatientRelatedDocumentScreenAction;
    }

    @State.computed
    public get selectedDocumentName() {
        return this.document?.info?.name ?? "";
    }

    @State.computed
    public get selectedDocumentStatusValue() {
        if (!this.document) {
            return "";
        } else if (this.document.isNew) {
            return this.screenResources.NewDocumentState;
        }

        return this.localizationService.localizeEnum(DocumentState[this.document.info.state], "DocumentState")?.Name;
    }

    @State.action.bound
    private setDocument(newValue: DocumentBase) {
        this.document = newValue;
    }

    @State.action.bound
    private setDocumentInfo(newValue: DocumentInfo) {
        newValue.lockInfo = this.document.info.lockInfo;
        this.document.info = newValue;
    }

    @State.action.bound
    private setCreateDocumentDialogResult(newValue: ICreateNewDocumentDialogResult) {
        this.createDocumentDialogResult = newValue;
    }

    @State.computed
    public get isBinaryDocument() {
        return !isNullOrUndefined(this.document) && this.document instanceof BinaryDocument;
    }

    @State.computed
    public get binaryDocument() {
        return this.document as BinaryDocument;
    }

    @State.computed
    public get isTemplateDocument() {
        return !isNullOrUndefined(this.document) && this.document instanceof TemplateBasedDocument;
    }

    @State.computed
    public get templateDocument() {
        return this.document as TemplateBasedDocument;
    }

    @State.computed
    public get patientId() {
        return (this.props.action as ShowCreateNewPatientRelatedDocumentScreenAction).patientId;
    }

    get vIsReadOnly(): boolean {
        return this.isReadOnlyDocument && this.hasPermissionForEdit;
    }

    @State.computed
    public get isReadOnlyDocument(): boolean {
        return !!this.document &&
            //(this.props._careActivityState === CareActivityState.Closed || 
            ((!this.document?.isNew && !this.document.info.possibleActions.some(d => d === DocumentAction.Save)) ||
                this.hasAnyRequiredAndNotHeldTokenLock ||
                this.document?.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockNotHeld);
    }

    @State.computed
    public get hasPermissionForEdit() {
        return this.authorizationService.hasPermissionForDescriptor(this.checkEditPatientRelatedDocumentsPermission);
    }

    @State.computed
    public get hasAnyRequiredAndNotHeldTokenLock(): boolean {
        return this.isTemplateDocument &&
            (this.templateDocument.tokenLockInfos.some(it => !it.isNew && it.acquiredLockInfo?.lockState === EntityLockState.LockingRequiredAndLockNotHeld));
    }

    @State.computed
    public get isSaveable() {
        return this.isTemplateDocument && !this.isReadOnlyDocument;
    }

    @State.computed
    public get isRefreshable() {
        return this.isTemplateDocument && !this.templateDocument.isNew && this.templateDocument.info.possibleActions.some(x => x === DocumentAction.DataRefresh);
    }

    @State.computed
    public get isDocumentHistorized(): boolean {
        return this.isTemplateDocument && !this.templateDocument.isNew && this.document.info.possibleActions.some(d => d === DocumentAction.ViewHistory);
    }

    @State.computed
    public get isPrintable(): boolean {
        return !!this.document && this.document.info.possibleActions.some(d => d === DocumentAction.Print);
    }

    @State.computed
    public get isPublishable(): boolean {
        return this.isAvailableAction(DocumentAction.Publish);
    }

    @State.computed
    public get isPublishCancellable(): boolean {
        return this.isAvailableAction(DocumentAction.CancelPublish);
    }

    @State.computed
    public get isFinalizable(): boolean {
        return this.isAvailableAction(DocumentAction.Finalize);
    }

    @State.computed
    public get isFinalizeCancellable(): boolean {
        return this.isAvailableAction(DocumentAction.CancelFinalize);
    }

    @State.computed
    public get isDeletable() {
        return this.isAvailableAction(DocumentAction.Delete);
    }

    @State.computed
    public get isEditable() {
        //Original logic: {this.props._careActivityState === CareActivityState.Closed || !this.currentDocument?.info.possibleActions.some(a => a === DocumentAction.Edit)}
        return this.isTemplateDocument && this.templateDocument.info.possibleActions.some(x => x === DocumentAction.Edit);
    }

    private isAvailableAction(action: DocumentAction): boolean {
        return this.isTemplateDocument && !this.isReadOnlyDocument && this.templateDocument.info.possibleActions.some(x => x === action);
    }

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("DocumentScreenApiAdapter") private documentScreenApiAdapter: DocumentScreenApiAdapter,
        @Di.inject("ITokenLockService") private tokenLockService: ITokenLockService,
        @Di.inject("AuthorizationService") private authorizationService: AuthorizationService) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    @State.bound
    public async loadCoreAsync() {
        if (this.isCreateNewDocumentScreen) {
            const dialogResult = await this.modalService.showDialogAsync<ICreateNewDocumentDialogResult>(new CreateNewDocumentDialogParams("Patient", this.patientId));

            this.setCreateDocumentDialogResult(dialogResult);

            if (dialogResult == null) {
                await this.onCancelAsync();
            } else {
                await this.onSaveAsync();
            }
        } else if (this.isDocumentScreen) {
            const requestLock = true;
            const response = await this.documentScreenApiAdapter.getPatientRelatedDocumentById((this.props.action as ShowPatientRelatedDocumentScreenAction).documentId, requestLock);
            if (response.operationInfo instanceof LockAcquirerOperationInfo) {
                State.runInAction(() => this.lockInfo = (response.operationInfo as LockAcquirerOperationInfo).lockInfo);
            }
            this.setDocument(response.result);
        }

        if (this.isBinaryDocument) {
            this.dirtyChecker.takeSnapshot(this.contentToDirtyCheck);
        } else {
            const contentToDirtyCheck = await this.templateDocument?.documentFile?.contentStore.getContentAsync();
            this.dirtyChecker.takeSnapshot([contentToDirtyCheck]);
        }
    }

    @State.bound
    public async onSaveAsync(): Promise<any> {
        if (this.isCreateNewDocumentScreen) {
            await this.onCreateAsync();
        } else {
            await this.onUpdateAsync();
        }
    }

    @State.bound
    public async onCreateAsync(): Promise<any> {
        if (this.createDocumentDialogResult.documentKind === DocumentKind.Binary) {
            if (this.createDocumentDialogResult.newId) {
                this.props._screenState.savedNew(this.createDocumentDialogResult.newId, new ShowPatientRelatedDocumentScreenAction(this.props.action.displayMode, this.createDocumentDialogResult.newId));
            } else {
                this.props._screenState.savedExisting();
            }
            return;
        }

        if (isNullOrUndefined(this.templateDocument)) {
            const saveResult = await this.handleSaveResultAsync(() => this.documentScreenApiAdapter.getNewPatientRelatedTemplateBasedDocument(this.createDocumentDialogResult.templateId, this.patientId, this.createDocumentDialogResult.description));

            const templateBasedDocument = saveResult.result;
            this.handleMissingLocks(templateBasedDocument.tokenLockInfos);

            this.setDocument(saveResult.result);
        } else {
            const saveResult = await this.handleSaveResultAsync(() => this.documentScreenApiAdapter.saveNewPatientRelatedTemplateBasedDocumentCommandAsync(this.templateDocument, this.patientId));
            if (saveResult.isPersisted) {
                this.props._screenState.savedNew(saveResult.result.documentInfo.id, new ShowPatientRelatedDocumentScreenAction(this.props.action.displayMode, saveResult.result.documentInfo.id));
            }
        }
    }


    @State.action
    private handleMissingLocks(locks: TokenLockInfo[]) {
        const lockDetails = this.tokenLockService.getMissingLockDetails(locks);
        if (lockDetails !== "") {
            const message = formatStringWithObjectParams(this.screenResources.Message.TextBlocksLocked, {
                LockInfo: lockDetails
            });
            this.notificationService.warning(message);
        }
    }

    @State.bound
    public async refreshTemplateBasedDocumentAsync() {
        if (!this.isTemplateDocument) {
            return;
        }

        const response = await this.documentScreenApiAdapter.refreshPatientRelatedTemplateBasedDocumentCommandAsync(
            this.templateDocument);

        if (response.operationInfo.isPersisted) {
            const result = response.result;
            State.runInAction(() => {
                if (result.hasChanges) {
                    this.templateDocument.documentFile = result.refreshedDocument;
                }
                this.templateDocument.dataChangedSinceLastRefresh = [];
            });
        }
    }

    @State.bound
    private async handleUpdateAsync(action: DocumentAction) {
        if (this.templateDocument.dataChangedSinceLastRefresh && this.templateDocument.dataChangedSinceLastRefresh.length > 0) {
            const changedSymbols = this.templateDocument.dataChangedSinceLastRefresh
                .map(it => this.localizationService.localizeReferenceDataWithDefault(it.nameResourceId, it.symbol)).join(", ");
            const message = formatStringWithObjectParams(this.screenResources.Message.TextBlocksChangedNotificationOnSaveMessage, {
                ChangedSymbols: changedSymbols
            });
            this.notificationService.warning(message);
        }
        const saveResult =
            await this.handleSaveResultAsync(() => this.documentScreenApiAdapter.updatePatientRelatedTemplateBasedDocument(this.templateDocument, action));

        this.setDocumentInfo(saveResult.result.documentInfo);

        if (saveResult.isPersisted) {
            this.props._screenState.savedExisting();
        }
    }

    @State.bound
    public async onUpdateAsync(): Promise<any> {
        await this.handleUpdateAsync(DocumentAction.Save);
    }

    @State.bound
    public async onDeleteAsync(): Promise<any> {
        const message = this.screenResources.Message.DeleteConfirmationMessage;
        const canDeleteAnswer = await this.dialogService.yesNo(this.screenResources.Message.DeleteConfirmationTitle, message);
        if (canDeleteAnswer.resultCode === DialogResultCode.Yes) {
            const saveResult =
                await this.handleSaveResultAsync(() => this.documentScreenApiAdapter.updatePatientRelatedTemplateBasedDocument(this.templateDocument, DocumentAction.Delete));

            if (saveResult.isPersisted) {
                this.props._screenState.savedExisting();
                return;
            }
        }
    }

    @State.bound
    public async onPublishAsync(): Promise<any> {
        await this.handleUpdateAsync(DocumentAction.Publish);
    }

    @State.bound
    public async onCancelPublishAsync(): Promise<any> {
        await this.handleUpdateAsync(DocumentAction.CancelPublish);
    }

    @State.bound
    public async onFinalizeAsync(): Promise<any> {
        await this.handleUpdateAsync(DocumentAction.Finalize);
    }

    @State.computed
    public get checkEditPatientRelatedDocumentsPermission() {
        return ActionDescriptor.fromAction(new UpdatePatientRelatedDocumentAction());
    }

    @State.computed
    public get checkViewPatientRelatedDocumentsPrintPreviewPermission() {
        return ActionDescriptor.fromAction(new PrintPatientRelatedDocumentPreviewAction());
    }

    @State.bound
    public async onEditAsync(): Promise<any> {
        const dialogResult = await this.dialogService.yesNoCancel(this.screenResources.Message.CancelFinalizeConfirmationTitle,
            this.screenResources.Message.CancelFinalizeConfirmationMessage);
        if (dialogResult.resultCode === DialogResultCode.Yes) {
            await this.handleUpdateAsync(DocumentAction.Edit);
        }
    }

    @State.bound
    public onCancelAsync(): Promise<any> {
        this.props._screenState.cancelled();
        return Promise.resolve();
    }

    @State.bound
    public async onPreviewAsync() {
        await this.modalService.showModalAsync(new DocumentPreviewModalParams(this.document.id));
    }

    @State.bound
    public async onDocumentHistoryAsync() {
        await this.modalService.showDialogAsync(new DocumentHistoryModalParams(this.document));
    }

    protected saveFunction: () => Promise<boolean> = this.onSaveAsync;
}