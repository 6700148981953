import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ICareResources from "./ICareResources";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";


const StaticCareResources = createStaticResourceDictionary<ICareResources>();

export function loadStaticCareResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    StaticCareResources.load(localizationService.getResourceGroup("WebApp").Care);
}

export default StaticCareResources;