import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import PerformanceReportOutputColumnType from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/PerformanceReportOutputColumnType.g";

interface IPerformanceReportOutputColumnTypeSelectBoxProps extends ISelectBoxBaseProps {
    value?: PerformanceReportOutputColumnType | PerformanceReportOutputColumnType[];
    onChange?: (newValue: PerformanceReportOutputColumnType | PerformanceReportOutputColumnType[]) => void;
}


const PerformanceReportOutputColumnTypeSelectBox: React.FC<IPerformanceReportOutputColumnTypeSelectBoxProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"PerformanceReportOutputColumnType"}
        enumOrigin="server"
        enumType={PerformanceReportOutputColumnType}
        displayMode="selectBox"
        clearable={false}
    />
);

export default PerformanceReportOutputColumnTypeSelectBox;