import IFormValidationRule, { IFormValidationRuleParameters } from "@Toolkit/FormEngine/Model/Schema/Validation/IFormValidationRule";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import RuleTypeId from "@Primitives/RuleTypeId.g";

export default class EditableShouldBeWithinRangeValidationRule implements IFormValidationRule {
    public get ruleType() { return RuleTypeId.ShouldBeWithinRange.value; }

    @State.observable.ref public minValue: number | null;
    @State.observable.ref public maxValue: number | null;
    
    public get ruleParameters() {
        return {
            minValue: this.minValue,
            maxValue: this.maxValue,
        };
    }

    constructor(
        public entityPath: string,
        ruleParameters: IFormValidationRuleParameters & { minValue: number | null, maxValue: number | null }
    ) {
        this.minValue = ruleParameters.minValue;
        this.maxValue = ruleParameters.maxValue;
    }

    @State.action.bound
    public setMinValue(value: number | null) {
        this.minValue = value;
    }

    @State.action.bound
    public setMaxValue(value: number | null) {
        this.maxValue = value;
    }

    @State.action.bound
    public clearMinValue() {
        this.minValue = null;
    }

    @State.action.bound
    public clearMaxValue() {
        this.maxValue = null;
    }
}