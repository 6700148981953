import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityBasedFormDetailPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareFormDetailPanel/CareActivityBasedFormDetailPanel";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import StaticHunEHealthInfrastructureWebAppResources from "@HunEHealthInfrastructurePlugin/StaticResources/StaticHunEHealthInfrastructureWebAppResources";

export interface IPregnancyRiskFormDetailPanelProps {
    selectedId: FormInstanceId | null;
    onSaveAsync: () => Promise<void>;
    onClose: () => void;
    onUseCaseChange: (selectedId: string, useCaseState: INDataUseCaseState) => void;
    readonly: boolean;
}

/** @screen */
function PregnancyRiskFormDetailPanel(props: IPregnancyRiskFormDetailPanelProps) {
    const useCaseIdentifier = props.readonly ?
        HunEHealthInfrastructureUseCases.readOnlyPregnancyRiskFormDetail :
        HunEHealthInfrastructureUseCases.editablePregnancyRiskFormDetail;

    const title = StaticHunEHealthInfrastructureWebAppResources.PregnancyRiskForm.Title;

    return (
        <CareActivityBasedFormDetailPanel
            selectedId={props.selectedId}
            onSaveAsync={props.onSaveAsync}
            onUseCaseChange={props.onUseCaseChange}
            readonly={props.readonly}
            formDefinitionType={"PregnancyRiskForm"}
            title={title}
            useCaseIdentifier={useCaseIdentifier}
            onClose={props.onClose}
        />
    );
}

export default State.observer(PregnancyRiskFormDetailPanel);