import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import IIdentifierWithSourceTypeDisplayExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IIdentifierWithSourceTypeDisplayExtensionPointProps";

@Di.injectable()
export default class HunSocialSecurityIdentifierWithSourceTypeDisplayExtensionComponentService implements IExtensionComponentService<IIdentifierWithSourceTypeDisplayExtensionPointProps> {

    public isVisible(props: IIdentifierWithSourceTypeDisplayExtensionPointProps) {
        return true;
    }

    public shouldRerunIsVisible(props: IIdentifierWithSourceTypeDisplayExtensionPointProps, prevProps: IIdentifierWithSourceTypeDisplayExtensionPointProps) {
        return prevProps?.identifiersWithSourceTypes !== props?.identifiersWithSourceTypes;
    }
}