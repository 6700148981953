import IFormValidationRule from "./Validation/IFormValidationRule";
import FormDataElementBase from "./FormDataElementBase";

export default class FormSchemaVersion {
    constructor(
        public readonly versionNumber: number,
        public readonly name: string,
        public readonly dataElements: FormDataElementBase[],
        public readonly validationRules: IFormValidationRule[]
    ) { }
}