import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React, { useMemo, useCallback } from "react";
import * as Ui from "@CommonControls";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import IEhiCovidSelfExaminationResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidSelfExaminationResultPanel/IEhiCovidSelfExaminationResult";
import IEhiStructuredDocumentQuantity from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidSelfExaminationResultPanel/IEhiStructuredDocumentQuantity";
import EhiCovidSelfExaminationGeneralStatusId from "@Primitives/EhiCovidSelfExaminationGeneralStatusId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import EhiCovidBreathingStatusId from "@Primitives/EhiCovidBreathingStatusId.g";
import EhiCovidSymptomStatusId from "@Primitives/EhiCovidSymptomStatusId.g";
import EhiCovidUrineAmountId from "@Primitives/EhiCovidUrineAmountId.g";
import EhiCovidSelfExaminationTimeCodeId from "@Primitives/EhiCovidSelfExaminationTimeCodeId.g";
import IExtensibleEnumValue from "@Toolkit/CommonWeb/Model/IExtensibleEnumValue";
import IPropertyListItem from "@CommonControls/PropertyList/IPropertyListItem";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { Moment } from "moment";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import formatCodeName from "@HisPlatformControls/CodeNameFormatter/CodeNameFormatter";
import formatScalarQuantity from "@HisPlatformControls/ScalarQuantityFormatter/ScalarQuantityFormatter";

interface IEhiCovidSelfExaminationResultPanelViewDependencies {
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    localizationService: ILocalizationService;
}

interface IEhiCovidSelfExaminationResultPanelViewProps {
    _dependencies?: IEhiCovidSelfExaminationResultPanelViewDependencies;
    store: IEhiCovidSelfExaminationResult;
}


const EhiCovidSelfExaminationResultPanelView: React.FC<IEhiCovidSelfExaminationResultPanelViewProps> = props => {

    const resources = StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.SelfExamination;

    const resolveTimeCodeId = useCallback((id: EhiCovidSelfExaminationTimeCodeId) => {
        if (isNullOrUndefined(id) || id.value === "") {
            return null;
        }
        const resolved = props._dependencies.hunEhiCareReferenceDataStore.ehiCovidSelfExaminationTimeCodes.get(id);
        return isNullOrUndefined(resolved) ? null : resolved;
    }, []);

    const resolveGeneralStatusId = useCallback((id: EhiCovidSelfExaminationGeneralStatusId) => {
        if (isNullOrUndefined(id) || id.value === "") {
            return null;
        }
        const resolved = props._dependencies.hunEhiCareReferenceDataStore.ehiCovidSelfExaminationGeneralStatusIds.get(id);
        return isNullOrUndefined(resolved) ? null : resolved;
    }, []);

    const resolveEhiCovidBreathingStatusId = useCallback((id: EhiCovidBreathingStatusId) => {
        if (isNullOrUndefined(id) || id.value === "") {
            return null;
        }
        const resolved = props._dependencies.hunEhiCareReferenceDataStore.ehiCovidBreathingStatuses.get(id);
        return isNullOrUndefined(resolved) ? null : resolved;
    }, []);

    const resolveEhiCovidSymptomStatusId = useCallback((id: EhiCovidSymptomStatusId) => {
        if (isNullOrUndefined(id) || id.value === "") {
            return null;
        }
        const resolved = props._dependencies.hunEhiCareReferenceDataStore.ehiCovidSymptomStatuses.get(id);
        return isNullOrUndefined(resolved) ? null : resolved;
    }, []);

    const resolveEhiCovidUrineAmountId = useCallback((id: EhiCovidUrineAmountId) => {
        if (isNullOrUndefined(id) || id.value === "") {
            return null;
        }
        const resolved = props._dependencies.hunEhiCareReferenceDataStore.ehiCovidUrineAmounts.get(id);
        return isNullOrUndefined(resolved) ? null : resolved;
    }, []);

    const resolveMoment = useCallback((moment: Moment) => {
        return isNullOrUndefined(moment)
            ? ""
            : props._dependencies.localizationService.localizeDateTime(moment, false, true, false);
    }, []);

    const executionProperties = useMemo(() => {
        if (isNullOrUndefined(props.store)) {
            return null;
        }
        const labels = resources.ExecutedAt.Labels;

        return [
            {
                name: labels.Time,
                value: resolveMoment(props.store.executedAt),
                automationId: "Time"
            },
            {
                name: labels.TimeCode,
                value: resolveEnumProperty(resolveTimeCodeId(props.store.timeCodeId)),
                automationId: "TimeCode"
            }
        ] as IPropertyListItem[];
    }, [props.store]);

    const statusProperties = useMemo(() => {
        if (isNullOrUndefined(props.store)) {
            return null;
        }
        const labels = resources.Status.Labels;
        const status = props.store?.status;

        return [
            {
                name: labels.GeneralStatusChange,
                value: resolveEnumProperty(resolveGeneralStatusId(status?.generalStatusChangeId)),
                automationId: "GeneralStatusChange"
            },
            {
                name: labels.GeneralStatus,
                value: resolveEnumProperty(resolveGeneralStatusId(status?.generalStatusId)),
                automationId: "GeneralStatus"
            },
            {
                name: labels.RespiratoryRate,
                value: resolveQuantityProperty(status?.respiratoryRate),
                automationId: "RespiratoryRate"
            },
            {
                name: labels.BreathingStatus,
                value: resolveEnumProperty(resolveEhiCovidBreathingStatusId(status?.breathingStatusId)),
                automationId: "BreathingStatus"
            },
            {
                name: labels.BloodPressure.BloodPressure + separator + labels.BloodPressure.Systolic,
                value: resolveQuantityProperty(status?.bloodPressure?.systolic),
                automationId: "SystolicBloodPressure"
            },
            {
                name: labels.BloodPressure.BloodPressure + separator + labels.BloodPressure.Diastolic,
                value: resolveQuantityProperty(status?.bloodPressure?.diastolic),
                automationId: "DiastolicBloodPressure"
            },
            {
                name: labels.Pulse,
                value: resolveQuantityProperty(status?.pulse),
                automationId: "Pulse"
            },
            {
                name: labels.BodyTemperature,
                value: resolveQuantityProperty(status?.bodyTemperature),
                automationId: "BodyTemperature"
            },
            {
                name: labels.CoughSymptomStatus,
                value: resolveEnumProperty(resolveEhiCovidSymptomStatusId(status?.coughSymptomStatusId)),
                automationId: "CoughSymptomStatus"
            },
            {
                name: labels.SoreThroatSymptomStatus,
                value: resolveEnumProperty(resolveEhiCovidSymptomStatusId(status?.soreThroatSymptomStatusId)),
                automationId: "SoreThroatSymptomStatus"
            },
            {
                name: labels.BloodSugar,
                value: resolveQuantityProperty(status?.bloodSugar),
                automationId: "BloodSugar"
            },
            {
                name: labels.MuscleOrJointPainSymptomStatus,
                value: resolveEnumProperty(resolveEhiCovidSymptomStatusId(status?.muscleOrJointPainSymptomStatusId)),
                automationId: "MuscleOrJointPainSymptomStatus"
            },
            {
                name: labels.HeadacheSymptomStatus,
                value: resolveEnumProperty(resolveEhiCovidSymptomStatusId(status?.headacheSymptomStatusId)),
                automationId: "HeadacheSymptomStatus"
            },
            {
                name: labels.VomitSymptomStatus,
                value: resolveEnumProperty(resolveEhiCovidSymptomStatusId(status?.vomitSymptomStatusId)),
                automationId: "VomitSymptomStatus"
            },
            {
                name: labels.DiarrheaSymptomStatus,
                value: resolveEnumProperty(resolveEhiCovidSymptomStatusId(status?.diarrheaSymptomStatusId)),
                automationId: "DiarrheaSymptomStatus"
            },
            {
                name: labels.UrineAmount,
                value: resolveEnumProperty(resolveEhiCovidUrineAmountId(status?.urineAmountId)),
                automationId: "UrineAmount"
            },
            {
                name: labels.KnownChronicConditionStatus,
                value: resolveEnumProperty(resolveEhiCovidSymptomStatusId(status?.knownChronicConditionStatusId)),
                automationId: "KnownChronicConditionStatus"
            },
            {
                name: labels.Notes,
                value: resolveStringProperty(status?.notes),
                automationId: "Notes"
            }
        ] as IPropertyListItem[];
    }, [props.store]);

    return (
        <Ui.ScrollView height="calc(100vh - 170px)">
            {props.store && (
                <ReadOnlyContext.Provider value>
                    <Ui.PropertyList
                        properties={executionProperties}
                        title={resources.ExecutedAt.Title}
                        nameWidth={200}
                        hasColonAfterNames
                        hideIfEmpty
                        hidePropertyIfNoValue
                    />
                    <Ui.PropertyList
                        properties={statusProperties}
                        title={resources.Status.Title}
                        nameWidth={200}
                        hasColonAfterNames
                        hideIfEmpty
                        hidePropertyIfNoValue
                    />
                </ReadOnlyContext.Provider>
            )}
        </Ui.ScrollView>
    );
};

const separator = " - ";
const emptyFieldSpaceHolder = null as string;

const resolveString = (string: string) => {
    return isNullOrUndefined(string) ? "" : string;
};

const resolveEnumProperty = (extEnum: IExtensibleEnumValue<any>) => {
    return isNullOrUndefined(extEnum?.displayValue)
        ? emptyFieldSpaceHolder
        : formatCodeName(resolveString(extEnum.displayValue.Shorthand), resolveString(extEnum.displayValue.Name));
};

const resolveQuantityProperty = (quantity: IEhiStructuredDocumentQuantity) => {
    return isNullOrUndefined(quantity)
        ? emptyFieldSpaceHolder
        : formatScalarQuantity(resolveString(quantity.value.toString()), resolveString(quantity.measurementUnit), resolveString(quantity.comparator), false);
};

const resolveStringProperty = (string: string) => {
    return isNullOrUndefined(string)
        ? emptyFieldSpaceHolder
        : resolveString(string);
};

export default connect(EhiCovidSelfExaminationResultPanelView,
    new DependencyAdapter<IEhiCovidSelfExaminationResultPanelViewProps, IEhiCovidSelfExaminationResultPanelViewDependencies>((c) => {
        return {
            hunEhiCareReferenceDataStore: c.resolve<HunEhiCareReferenceDataStore>("HunEhiCareReferenceDataStore"),
            localizationService: c.resolve<ILocalizationService>("ILocalizationService")
        };
    })
);
