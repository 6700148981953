import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";

export function getNavigationMenuItems(): INavMenuItem[] {
    const leftTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const rightTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const childMenuItems: INavMenuItem[] = [
    ];

    return [...leftTopLevelMenuItems, ...rightTopLevelMenuItems , ...childMenuItems];
}