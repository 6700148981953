import IPermissionDefinition from "./IPermissionDefinition";
import IPermissionScope from "./IPermissionScope";
import PermissionId from "@Primitives/PermissionId.g";
import Permission from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/Permission";
import PermissionScope from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/PermissionScope";

export default class PermissionDefinitionBuilder {
    private permissionDefinition: IPermissionDefinition;

    private constructor(
        ...definitionName: string[]
    ) {
        this.permissionDefinition = new Permission(definitionName.map(name => new PermissionId(name)), []);
    }

    public static create(...definitionName: string[]): PermissionDefinitionBuilder {
        return new PermissionDefinitionBuilder(...definitionName);
    }

    public static createPositivePermissionScope(type: string, includedValues: string[] = []): IPermissionScope {
        return PermissionScope.createPositive(type, includedValues);
    }

    public static createNegativePermissionScope(type: string, excludedValues: string[] = []): IPermissionScope {
        return PermissionScope.createNegative(type, excludedValues);
    }

    public static createAllPermissionScope(type: string): IPermissionScope {
        return PermissionScope.createAll(type);
    }

    public static createWillBeBoundPermissionScope(type: string): IPermissionScope {
        return PermissionScope.createPositive(type, []);
    }

    public withPermissionScope(scope: IPermissionScope): PermissionDefinitionBuilder {
        this.permissionDefinition.ids.forEach(id => {
            this.permissionDefinition.setPermissionScopesForId(id, scope);
        });
        return this;
    }

    public withPermissionScopes(...scopes: IPermissionScope[]): PermissionDefinitionBuilder {
        this.permissionDefinition.setPermissionScopesForAllIds(...scopes);
        return this;
    }

    public withPermissionScopesFor(permissionId: string, ...scopes: IPermissionScope[]): PermissionDefinitionBuilder {
        this.permissionDefinition.setPermissionScopesForId(new PermissionId(permissionId), ...scopes);
        return this;
    }

    public withPermissionScopesForIds(permissionIds: string[], ...scopes: IPermissionScope[]): PermissionDefinitionBuilder {
        permissionIds?.forEach(permissionId => {
            this.permissionDefinition.setPermissionScopesForId(new PermissionId(permissionId), ...scopes);
        });
        return this;
    }

    public withAlias(alias: string, ...aliasedScopeTypes: string[]): PermissionDefinitionBuilder {
        this.permissionDefinition.setAlias(alias, ...aliasedScopeTypes);
        return this;
    }

    public build(): IPermissionDefinition {
        return this.permissionDefinition;
    }
}