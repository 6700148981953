import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as HisUi from "@HisPlatformControls";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import IMedicalConditionReferenceDataResolver from "@HisPlatform/BoundedContexts/Care/Services/Definition/MedicalConditionReferenceDataResolver/IMedicalConditionReferenceDataResolver";
import ConditionsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ConditionsApiAdapter";
import { ConditionExtendedCodeSelectorItem } from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ConditionExtendedCodeSelector/ConditionExtendedCodeSelectorItem";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ConditionId from "@Primitives/ConditionId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";

interface IConditionExtendedCodeSelectorDependencies {
    conditionsApiAdapter: ConditionsApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
    medicalConditionReferenceDataResolver: IMedicalConditionReferenceDataResolver;
}

interface IConditionExtendedCodeSelectorProps extends ICodeSelectorCommonProps<ConditionExtendedCodeSelectorItem> {
    _dependencies?: IConditionExtendedCodeSelectorDependencies;
    filters?: FilterBase[];
}

@State.observer
class ConditionExtendedCodeSelector extends React.Component<IConditionExtendedCodeSelectorProps> {

    private get dependencies() { return this.props._dependencies; }
    private get apiAdapter() { return this.dependencies.conditionsApiAdapter; }
    private get referenceDataStore() { return this.dependencies.careReferenceDataStore.condition; }
    private get medicalConditionReferenceDataResolver() { return this.dependencies.medicalConditionReferenceDataResolver; }

    @State.bound
    public async getDisplayValueAsync(value: ConditionExtendedCodeSelectorItem) {
        return await this.medicalConditionReferenceDataResolver.getConditionNameAsync(value);
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const results = await this.apiAdapter.searchConditionsByCode(
            text,
            10,
            this.props.validOn ? this.props.validOn : LocalDate.today(),
            this.props.filters
        );

        if (results.operationWasSuccessful) {
            const conditions = results.value.map(x => {
                const textOfQuickSearch = `${x.name} (${x.code})`;
                if (x.id) {
                    return new ConditionExtendedCodeSelectorItem({
                        id: x.id,
                        code: x.code,
                        text: textOfQuickSearch
                    });
                }
                return null;
            });

            const referenceConditions = conditions.filter(s => s.id instanceof ConditionId);

            await this.referenceDataStore.ensureLoadedAsync(referenceConditions.map(s => ({
                id: s.id,
                validOn: this.props.validOn ? this.props.validOn : LocalDate.today()
            } as IEntityVersionSelector<ConditionId>)));

            return conditions;
        }
        return null;
    }

    @State.bound
    private addNewItem(text: string): ConditionExtendedCodeSelectorItem {
        return new ConditionExtendedCodeSelectorItem({ text: text });
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState) {
        const validOn = this.props.validOn ? this.props.validOn : LocalDate.today();
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;

        const results = await this.apiAdapter.searchConditions(
            filterText,
            validOn,
            customOrdering,
            paging,
            this.props.filters);
        const versionSelectors = results.value.values.map(x => new EntityVersionSelector<ConditionId>(x.id, validOn));
        await this.referenceDataStore.ensureLoadedAsync(versionSelectors);
        const newStore = new PagedItemStore(results.value.values, results.value.totalCount, results.operationInfo);
        return newStore;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: ConditionExtendedCodeSelectorItem) {
        this.props.onChange({ id: item.id });
    }

    @State.bound
    private onComplexSearchMultiSelect(items: ConditionExtendedCodeSelectorItem[]) {
        this.props.onChange(items.map(item => ({ id: item.id, text: item.code })));
    }
    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticCareResources.MedicalCondition.Conditions.Title,
            codeGetter: "code",
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect,
            hideAllgroup: true
        };
        return (
            <HisUi.UniversalCodeSelector
                {...props}
                isCreatable
                addNewItem={this.addNewItem}
            />
        );
    }
}

export default connect(
    ConditionExtendedCodeSelector,
    new DependencyAdapter<IConditionExtendedCodeSelectorProps, IConditionExtendedCodeSelectorDependencies>((container) => {
        return {
            conditionsApiAdapter: container.resolve("ConditionsApiAdapter"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            medicalConditionReferenceDataResolver: container.resolve("IMedicalConditionReferenceDataResolver"),
        };
    })
);
