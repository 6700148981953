import { IScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React, { useMemo } from "react";
import { ScreenNavigationReactContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationReactContext";
import ScreenRenderer from "@HisPlatform/Components/ShowScreenAction/ScreenRenderer";

export class InMemoryScreenNavigationContextStore implements IScreenNavigationContext {
    public secondaryLevel: number = 1;
    public isSecondaryScreenOpen: boolean = false;
    public isSecondaryDetailOpen: boolean = false;
    public isSecondaryModalOpen: boolean = false;

    @State.computed public get currentPrimaryScreenRaw(): string {
        return this.getScreenUrlState(this.currentPrimaryScreen.action, this.currentPrimaryScreen.screenState);
    }

    @State.observable.ref public currentPrimaryScreen: { screenState: string; action: ShowScreenFrontendActionBase; };

    @State.computed public get currentSecondaryScreenRaw(): string {
        return this.getScreenUrlState(this.currentSecondaryScreen.action, this.currentSecondaryScreen.screenState);
    }

    @State.observable.ref public currentSecondaryScreen: { screenState: string; action: ShowScreenFrontendActionBase; } = { screenState: null, action: null };

    constructor(
        primaryState: string,
        primaryAction: ShowScreenFrontendActionBase
    ) {
        this.currentPrimaryScreen = {
            screenState: primaryState,
            action: primaryAction
        };
    }

    public showAbsolutePrimary(action: FrontendActionBase, primaryScreenState?: string): void {
        throw new Error("NotSupported");
    }

    @State.action.bound
    public setPrimaryScreenState(state: string): void {
        this.currentPrimaryScreen = {
            action: this.currentPrimaryScreen.action,
            screenState: state
        };
    }

    public showPrimary(action: FrontendActionBase, primaryScreenState?: string): void {
        throw new Error("NotSupported");
    }

    public showSecondary(action: FrontendActionBase, screenStates?: { primaryScreenState?: string; secondaryScreenState?: string; }): void {
        throw new Error("NotSupported");
    }

    public clearAllNotPrimary(primaryScreenState?: string): void {
        throw new Error("NotSupported");
    }

    private getScreenUrlState(action: FrontendActionBase, state?: string) {

        if (action.id.value.includes(";")) {
            throw new Error("Id of a ShowScreenFrontendAction base cannot contains ';' character.");
        }

        const actionUrlParams = (action as any).toUrl() as string[];

        if (actionUrlParams.some(p => p.includes(";"))) {
            throw new Error("ShowScreenFrontendAction.toUrl cannot return any value that contains ';' character.");
        }

        if (state?.includes(";")) {

            throw new Error("Screen state cannot contains ';' character.");
        }

        return encodeURIComponent(`${state ?? "null"};${action.id.value};${actionUrlParams.join(";")}`);
    }
}


function DetachedScreenRenderer(props: {
    defaultPrimaryState: string,
    primaryAction: ShowScreenFrontendActionBase,
    onClose?: () => void
}) {
    const store = useMemo(() => new InMemoryScreenNavigationContextStore(props.defaultPrimaryState, props.primaryAction), []);

    return (
        <ScreenNavigationReactContext.Provider value={store}>
            <ScreenRenderer
                action={store.currentPrimaryScreen.action}
                screenState={store.currentPrimaryScreen.screenState}
                onClose={props.onClose}
            />
        </ScreenNavigationReactContext.Provider>
    );
}

export default State.observer(DetachedScreenRenderer);