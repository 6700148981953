import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import {IDataGridColumnBaseProps} from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import React from "react";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import EnumBadge from "@CommonControls/EnumBadge";
import MedicalServicePriority from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalServices/Enum/MedicalServicePriority.g";

interface IMedicalServicePriorityBadgeDependencies {
    localizationService: ILocalizationService;
}

interface IMedicalServicePriorityBadgeProps extends IDataGridColumnBaseProps {
    _dependencies?: IMedicalServicePriorityBadgeDependencies;
    value: MedicalServicePriority;
}


const MedicalServicePriorityBadge: React.FC<IMedicalServicePriorityBadgeProps> = props => {
    const localized = isNullOrUndefined(props.value) ? null : props._dependencies.localizationService.localizeEnum(MedicalServicePriority[props.value], "MedicalServicePriority");

    if (isNullOrUndefined(localized)) {
        return null;
    }

    return (
        <EnumBadge
            value={props.value}
            enumName={"MedicalServicePriority"}
            enumType={MedicalServicePriority}
            visualStyle={getVisualStyleByPriorityValue(props.value)}
        />
    );
};

const getVisualStyleByPriorityValue = (value: MedicalServicePriority) => {
    switch (value) {
        case MedicalServicePriority.Normal:
        case MedicalServicePriority.Ambulant:
            return null;
        case MedicalServicePriority.Urgent:
            return "warning";
        case MedicalServicePriority.Immediate:
        case MedicalServicePriority.Emergency:
            return "danger";
    }
};
export default connect(
    MedicalServicePriorityBadge,
    new DependencyAdapter<IMedicalServicePriorityBadgeProps, IMedicalServicePriorityBadgeDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
