import ResourceBlocking from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ResourceBlocking";
import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";

export function CreateCreateResourceBlockingControllerDto(store: ResourceBlocking, isValidateOnly: boolean) {
    return new Proxy.CreateResourceBlockingControllerDto({
        resource: store.resource instanceof PractitionerId
            ? new Proxy.PractitionerResource({  practitionerId: store.resource })
            : new Proxy.OrganizationUnitResource({  organizationUnitId: store.resource }),
        description: store.description,
        blockingInterval: new Proxy.ResourceBlockingIntervalDto({
            from: store.dateWithTimeRange.toMomentRange?.from,
            to: store.dateWithTimeRange.toMomentRange?.to
        }),
        isValidateOnly: isValidateOnly
    });
}

export function CreateModifyResourceBlockingControllerDto(store: ResourceBlocking) {
    return new Proxy.ModifyResourceBlockingControllerDto({
        resourceBlockingId: store.id,
        rowVersion: store.rowVersion,
        description: store.description,
        blockingInterval: new Proxy.ResourceBlockingIntervalDto({
            from: store.dateWithTimeRange.toMomentRange?.from,
            to: store.dateWithTimeRange.toMomentRange?.to
        })
    });
}

export function CreateGetResourceBlockingsControllerDto(practitionerId: PractitionerId, dateRange: LocalDateRange) {
    return new Proxy.GetResourceBlockingsForResourceControllerDto({
        resource: new Proxy.PractitionerResource({  practitionerId: practitionerId }),
        from: dateRange.from,
        to: dateRange.to
    });
}