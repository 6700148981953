import * as Ui from "@CommonControls";
import NDataScreen from "@HisPlatform/BoundedContexts/Productivity/Components/NDataScreen/NDataScreen";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import React, { useCallback, useState } from "react";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import TelemetrySessionListScreenApiAdapter from "./TelemetrySessionListScreenApiAdapter";
import { RegisterLocalActionProcessor } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import TelemetrySessionMeasurementsDialogParams from "@AssecoMedPlugin/Packages/Care/Screens/TelemetrySessionMeasurementsDialog/TelemetrySessionMeasurementsDialogParams";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import config from "@Config";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import RefreshTelemetrySessionAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/RefreshTelemetrySessionAction.g";
import StaticAssecoMedWebAppResources from "@AssecoMedPlugin/StaticResources/StaticAssecoMedWebAppResources";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import INDataRow from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataRow";
import { IRowBody, RowId } from "@CommonControls/DataGrid/IDataGridProps";
import IWorklistItemDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistItemDefinition";
import Styles from "./RowBody.less";
import _ from "@HisPlatform/Common/Lodash";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Device from "@AssecoMedPlugin/BoundedContexts/Care/ApplicationLogic/Model/Worklist/Device";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { iconNameType } from "@CommonControls/Icon";
import ShowTelemetrySessionMeasurementsAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowTelemetrySessionMeasurementsAction.g";

export default function TelemetrySessionListScreen(props: IScreenPropsBase) {
    const {
        localizationService,
        telemetrySessionListScreenApiAdapter
    } = useDependencies(c => ({
        localizationService: c.resolve<ILocalizationService>("ILocalizationService"),
        telemetrySessionListScreenApiAdapter: c.resolve<TelemetrySessionListScreenApiAdapter>("TelemetrySessionListScreenApiAdapter"),
    }));

    const { modalService } = useDependencies(c => ({
        modalService: c.resolve<IModalService>("IModalService"),
    }));

    const [refreshListEvent, setRefreshListEvent] = useState(new TypedAsyncEvent());

    const getDynamicListAsync = useCallback(async (args: FrontendListParameters) => {
        const response = await telemetrySessionListScreenApiAdapter.getTelemetrySessionListAsync(args);
        return response.result;
    }, []);

    const openTelemetrySessionMeasurementsDialog = useCallback(async (action: FrontendActionBase) => {
        if (!(action instanceof ShowTelemetrySessionMeasurementsAction)) {
            return;
        }

        await modalService.showModalAsync(new TelemetrySessionMeasurementsDialogParams(action.externalPatientId, config.telemetrySession.hubs));
    }, []);

    const refreshTelemetrySession = useCallback(async (action: FrontendActionBase) => {
        if (!(action instanceof RefreshTelemetrySessionAction)) {
            return;
        }

        await telemetrySessionListScreenApiAdapter.refreshTelemetrySession(action.careActivityId);
        await refreshListEvent.emitAsync();
    }, []);

    const renderDeviceData = useCallback((device: Device, index: number) => {
        return (
            <div className={Styles.rowBody} key={index}>
                <Ui.Icon iconName={(`sk_tc_device_${device.deviceType}`) as iconNameType} />&nbsp;
                <b>{device.name}</b> ({localizationService.localizeDateTime(device.lastMeasuredAt)})<br/>
                <Ui.Flex>
                    {device.measurements.map((measurement, i) => (
                        <Ui.Flex.Item key={i} className={Styles.flexItem}>
                            <b>{measurement.name}:</b> {measurement.value} {measurement.metric}
                        </Ui.Flex.Item>
                    ))}
                </Ui.Flex>
            </div>
        );
    }, []);

    const renderRowBody = useCallback((row: INDataRow, rowId: RowId, rowIndex: number, rowBodyItemDefinition: IWorklistItemDefinition): IRowBody => {
        const rawValue = _.get(row, "TelemetrySessionBased_LastMeasuredResults");
        if (isNullOrUndefined(rawValue)) {
            return null;
        }

        const deviceList = rawValue as Device[];

        if (deviceList.length === 0) {
            return null;
        }

        return {
            showCells: true,
            content: (
                <>
                    {deviceList.map((device, index) => renderDeviceData(device, index))}
                </>
            )
        } as IRowBody;
    }, []);

    return (
        <>
            <NDataScreen
                iconName="episode_of_care"
                onGetDynamicListAsync={getDynamicListAsync}
                hasRefreshButton={true}
                refreshListEvent={refreshListEvent}
                onRenderRowBody={renderRowBody}
            />

            <RegisterLocalActionProcessor
                actionId={ShowTelemetrySessionMeasurementsAction.id}
                processAsync={openTelemetrySessionMeasurementsDialog}
                displaySettings={{
                    displayName: StaticAssecoMedWebAppResources.TelemetrySessionScreen.Buttons.Measurements,
                    iconName: "edit_patient"
                }}
            />

            <RegisterLocalActionProcessor
                actionId={RefreshTelemetrySessionAction.id}
                processAsync={refreshTelemetrySession}
                displaySettings={{
                    displayName: StaticWebAppResources.Common.Button.Refresh,
                    iconName: "sync"
                }}
            />
        </>
    );
}
