import EhiHealthcareProviderOrganizationUnitId from "@Primitives/EhiHealthcareProviderOrganizationUnitId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import EhiHealthcareProviderId from "@Primitives/EhiHealthcareProviderId.g";
import HealthcareProfessionId from "@Primitives/HealthcareProfessionId.g";

export default class EhiHealthcareProviderOrganizationUnit extends EntityStoreBase<EhiHealthcareProviderOrganizationUnitId> {
    public code: string;
    public name: string;
    public ehiHealthcareProviderId: EhiHealthcareProviderId;
    public healthcareProfessionIds: HealthcareProfessionId[];
}
