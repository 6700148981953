import React, { useCallback } from "react";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IEnumReferenceEditorDialogParams, IEnumReferenceEditorDialogResult } from "./EnumReferenceEditorDialogParams";
import * as Ui from "@CommonControls";
import { provideEnumReferenceEditorDialogStore, useEnumReferenceEditorDialogStore } from "./EnumReferenceEditorDialogStoreProvider";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import { emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EditableFormEnumValueStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableFormEnumValueStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import { IFormEnumOption } from "./EnumReferenceEditorDialogStore";
import StaticFormEditingResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import Language from "@Primitives/Language";

function getItemKey(item: EditableFormEnumValueStore, index: number) {
    return `${item.value}_${index}`;
}

function renderSelectedValue(props: any) {
    return props.data.longDisplayValue;
}

function EnumReferenceEditorDialog(props: IModalComponentParams<IEnumReferenceEditorDialogResult> & IEnumReferenceEditorDialogParams) {

    const store = useEnumReferenceEditorDialogStore();

    const renderItemEditor = useCallback((item: EditableFormEnumValueStore, _index: number) => <EnumItemEditor enum={store.selected} item={item} isMyEnum={store.isMyEnum} />, [store.selected]);
    const createNewEnumValueAsync = useCallback(() => Promise.resolve(
        store.selected.formEnum.createNewItem()
    ), [store.selected]);

    return (
        <Ui.Modal
            isOpen
            onClose={props.onClose as any}
            title="Enum"
            size="compact"
            fixedHeight={"75%"}
        >
            <Ui.Modal.Body>
                <Ui.SelectBox
                    items={store.options}
                    automationId="EnumReference_SelectBox"
                    value={store.selected}
                    onChange={store.select}
                    customSelectedValueRenderer={renderSelectedValue}
                />

                <ValidationBoundary validationResults={emptyArray}>
                    {store.selected && (
                        <ListPanel
                            isReadOnly={!store.isMyEnum}
                            alwaysEdit
                            items={store.selected.formEnum.activeValues}
                            onAddNewItem={store.selected.formEnum.addNewItem}
                            getItemKey={getItemKey}
                            renderItemEditor={renderItemEditor}
                            onCreateNewAsync={createNewEnumValueAsync}
                            onRemoveItem={store.selected.formEnum.removeValue}
                        />
                    )}
                </ValidationBoundary>
            </Ui.Modal.Body>

            <Ui.Modal.Footer
                left={(
                    <Ui.Modal.Button automationId="EnumReferenceCancel_Button" closeModalOnClick text={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Enum.CancelButton} visualStyle="link" />
                )}
                right={(
                    <Ui.SaveButton automationId="EnumReferenceSave_Button" onClick={store.save} />
                )} />
        </Ui.Modal>
    );
}


const EnumItemEditor = State.observer((props: { item: EditableFormEnumValueStore, enum: IFormEnumOption, isMyEnum: boolean }) => {
    return (
        <Ui.Flex>
            <Ui.Flex.Item>
                <Ui.TextBox
                    automationId={`EnumItem_${props.item.value}_DisplayValue_TextBox`}
                    label={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Enum.DisplayValueLabel}
                    value={props.item.localizedDisplayValue}
                    onChange={props.item.setLocalizedValue}
                    isReadOnly={!props.isMyEnum}
                />
            </Ui.Flex.Item>
            <Ui.Flex.Item>
                <Ui.UniversalEnumSelector
                    label={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.LanguageSelectorLabel}
                    enumName={"Language"}
                    enumType={Language}
                    enumOrigin="client"
                    value={props.item.currentLabelLanguage}
                    onChange={props.item.setCurrentLabelLanguage}
                    automationId="LanguageSelector"
                    clearable={false}
                />
            </Ui.Flex.Item>
            <Ui.Flex.Item>
                <Ui.NumberBox
                    automationId={`EnumItem_${props.item.value}_Value_NumberBox`}
                    label={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Enum.ValueLabel}
                    value={props.item.value}
                    onChange={props.item.setValue}
                    isReadOnly={!props.isMyEnum}
                />
            </Ui.Flex.Item>
            <Ui.Flex.Item>
                {!props.isMyEnum && (
                    <Ui.CheckBox
                        automationId={`EnumItem_${props.item.value}_IsActive_CheckBox`}
                        label={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Enum.IsActiveLabel}
                        value={props.item.isActive}
                        onChange={props.item.setActive}
                        verticalAlign="inlineInput"
                    />
                )}
            </Ui.Flex.Item>
        </Ui.Flex>
    );
});

export default provideEnumReferenceEditorDialogStore(EnumReferenceEditorDialog);