import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ProcedureStatementId from "@Primitives/ProcedureStatementId.g";
import ProcedureAssignmentBase from "./ProcedureAssignmentBase";
import MedicalConditionItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionItemBase";
import ReferencedMedicalServiceAssignment from "./ReferencedMedicalServiceAssignment";
import { MedicalServiceExtensibleCodeSelectorItem } from "@HisPlatformControls/MedicalServiceExtensibleCodeSelector/MedicalServiceExtensibleCodeSelectorItem";
import CustomProcedureAssignment from "./CustomProcedureAssignment";

export default class ProcedureStatementStore extends MedicalConditionItemBase<ProcedureStatementId> {

    @State.observable.ref public procedureAssignment: ProcedureAssignmentBase;
    @State.observable public procedureName: string;

    constructor(isNew: boolean) {
        super(isNew);
    }

    @State.computed public get procedureCodeSelectorItem() {
        if (!this.procedureAssignment) {
            return null;
        }
        if (this.procedureAssignment instanceof ReferencedMedicalServiceAssignment) {
            return new  MedicalServiceExtensibleCodeSelectorItem({ id: this.procedureAssignment.medicalServiceId });
        } else if (this.procedureAssignment instanceof CustomProcedureAssignment) {
            return new MedicalServiceExtensibleCodeSelectorItem({ text: this.procedureAssignment.name });
        }
        return null;
    }

    @State.action.bound
    public setProcedureAssignment(newValue: ProcedureAssignmentBase) {
        this.procedureAssignment = newValue;
    }

    @State.action.bound
    public setProcedureName(newValue: string) {
        this.procedureName = newValue;
    }
}