
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import InvoiceScreenStore from "./InvoiceScreenStore";
import { editorScreenRenderFunc } from "@Toolkit/CommonWeb/PanelStore/EditorScreenRenderFunc";
import { IInvoiceScreenProps } from "./InvoiceScreen";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ShowEditPatientAdmissionDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEditPatientAdmissionDataScreenAction.g";
import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";

const {
    ContextComponent: InvoiceScreenStoreContext,
    StoreProvider: InvoiceScreenStoreProvider,
    useStore: useInvoiceScreenStore,
    provideStore: provideInvoiceScreenStore,
} = createPanelStoreProvider<InvoiceScreenStore>(InvoiceScreenStore, (props: IInvoiceScreenProps) => ({
    unauthorizedPageTitle: (props.action instanceof ShowNewAdmitPatientScreenAction || props.action instanceof ShowEditPatientAdmissionDataScreenAction) ?
        StaticCareResources.PatientAdmission.Titles.Admission :
        StaticWebAppResources.OutpatientWorkflow.CareActivityBaseDataStep.Title
}), editorScreenRenderFunc);

export {
    InvoiceScreenStoreContext,
    InvoiceScreenStoreProvider,
    useInvoiceScreenStore,
    provideInvoiceScreenStore
};