import UseCaseArgument from "@Primitives/UseCaseArgument";
import { arrayIsNullOrEmpty, isNullOrEmptyString, isNullOrUndefinedOrGivenString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import Reflection from "@Toolkit/CommonWeb/Reflection/Reflection";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";

export function parseUseCaseStateFromUrlParam(url: string): INDataUseCaseState {
    if (isNullOrUndefinedOrGivenString(url, "null")) {
        return null;
    }

    const splitUrl = decodeURIComponent(url).split(";");
    const useCase = splitUrl[0];
    const useCaseDisplayMode = splitUrl[1];
    splitUrl.splice(0, 2);
    const useCaseParams = parseUseCaseArgumentsFromUrlParams(splitUrl);

    return {
        useCase: new UseCaseIdentifier(useCase),
        useCaseArguments: useCaseParams,
        displayMode: parseInt(useCaseDisplayMode, 10)
    };
}

export function parseUseCaseArgumentsFromUrlParams(urlParams: string[]) {
    return urlParams.map(a => {
        if (!a) {
            return null;
        }
        const unescaped = decodeURIComponent(a);
        const keyValue = unescaped.split(":");

        if (keyValue.length !== 3) {
            return null;
        }

        const [key, typeName, value] = keyValue;

        if (value === "null") {
            return null;
        }
        
        if (typeName === "string") {

            return new UseCaseArgument(value, key);

        } else {
            const type = Reflection.getTypeInfo(typeName)?.constructor;
            if (type == null) {
                throw new Error(`Unknown UseCaseArgument type: '${typeName}'`);
            }

            if (!("fromUrl" in type)) {
                throw new Error(`Type: '${typeName}' does not have a static fromUrl method!`);
            }

            return new UseCaseArgument((type as any).fromUrl(value), key);
        }

    }).filter(a => !!a);
}

export function getUseCaseArgumentsAsUrlParams(useCaseArguments: UseCaseArgument[]) {
    return useCaseArguments.map(a => {
        if (!a) {
            return null;
        }

        let typeName = "";
        if (typeof a.value === "string") {
            typeName = "string";
        } else {
            if (!a.value?.typeName) {
                throw new Error(`Cannot determine UseCaseArgument.Value's type name. Value as JSON: '${JSON.stringify(a.value)}'`);
            }
            typeName = a.value.typeName;
        }

        return `${a.name}:${typeName}:${getUrlValue(a.value)}`;

    }).filter(a => !!a);
}

function getUrlValue(value: any) {
    if (typeof value === "number") {
        return `${value}`;
    } else if (typeof value === "string") {
        return value;
    } else if (typeof value === "object" && "toUrl" in value) {
        return value.toUrl();
    }

    throw new Error(`Cannot convert value to url string! Value=${value} Type=${typeof value}`);
}

export function getUseCaseAsUrlParam(useCaseIdentifier: UseCaseIdentifier, displayMode: UseCaseDisplayMode, useCaseArguments: UseCaseArgument[]) {

    if (!useCaseIdentifier?.value) {
        return "null";
    }

    if (arrayIsNullOrEmpty(useCaseArguments)) {
        return `${useCaseIdentifier.value};${displayMode}`;
    }
    return `${useCaseIdentifier.value};${displayMode};${getUseCaseArgumentsAsUrlParams(useCaseArguments).join(";")}`;
}

export function getUseCaseFromNDataUseCaseStateAsUrlParam(useCaseState: INDataUseCaseState) {
    
    if (!useCaseState) {
        return undefined;
    }

    return  getUseCaseAsUrlParam(useCaseState.useCase, useCaseState.displayMode, useCaseState.useCaseArguments);
}
