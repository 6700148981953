import RouteDefinition from "./RouteDefinition";
import RoutingController from "./RoutingController";
import Route from "./Abstractions/Route";
import { IRoutingController } from "./Abstractions/IRoutingController";
import IHostRoutingAdapter from "./Abstractions/IHostRoutingAdapter";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import GlobalRoutingStore from "./Abstractions/GlobalRoutingStore";
import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class RoutingFrameStore {
    @State.observable.ref public currentRoute: Route = null;
    private currentPathname: string = null;
    @State.observable.ref public currentController: IRoutingController = null;

    private nextPathname: string = null;
    private nextRoute: Route = null;

    private disposeHistoryListener: IDisposable;
    private disposeBlockerHook: IDisposable;

    private get routes() {
        return this._routes();
    }

    constructor(
        private readonly routingAdapter: IHostRoutingAdapter,
        private readonly _routes: () => { [id: string]: RouteDefinition },
        private readonly currentRouteProviderStore: GlobalRoutingStore,
        private readonly onRoutingControllerChanged: (c: IRoutingController) => void,
        private readonly onRouteChanging: (route: Route) => boolean,
        private readonly fallbackRoute?: Route | RouteDefinition,
        private readonly parentRouteDefinition?: RouteDefinition
    ) { }

    public initialize() {
        this.disposeBlockerHook = this.routingAdapter.addBlockerHook(({ pathname }) => {
            if (this.currentController && this.currentController.navigatingAwayHookAsync) {

                this.nextRoute = this.getRouteFromPathname(pathname);
                this.nextPathname = pathname;

                if (this.nextRoute !== this.currentRoute) {
                    return this.currentController.navigatingAwayHookAsync();
                }
            }
            return Promise.resolve(true);
        });

        this.disposeHistoryListener = this.routingAdapter.addNavigationEventHandler(({ pathname }) => {
            const newRoute = this.nextPathname === pathname ? this.nextRoute : this.getRouteFromPathname(pathname);
            if (newRoute !== this.currentRoute) {
                const shouldReact = this.onRouteChanging ? this.onRouteChanging(newRoute) : true;
                if (shouldReact) {
                    this.setCurrentRouteAndPathname(newRoute, pathname);
                }
            }
        });

        this.setCurrentRouteAndPathname(this.getRouteFromPathname(this.routingAdapter.currentPathname), this.routingAdapter.currentPathname);
    }

    public dispose() {
        if (this.disposeHistoryListener) {
            this.disposeHistoryListener.dispose();
        }

        if (this.disposeBlockerHook) {
            this.disposeBlockerHook.dispose();
        }
    }

    private getRouteFromPathname(pathname: string): Route {
        if (this.currentPathname === pathname) {
            return this.currentRoute;
        }

        for (const routeKey in this.routes) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.routes.hasOwnProperty(routeKey)) {

                const element = this.routes[routeKey];
                const route = element.parsePathname(pathname);

                if (route) {

                    if (this.currentRoute && route.definition === this.currentRoute.definition) {

                        if (route.parameters && this.currentRoute.parameters) {
                            if (_.isEqual(route.parameters, this.currentRoute.parameters)) {
                                return this.currentRoute;
                            }
                        } else {
                            return this.currentRoute;
                        }

                    }

                    return route;
                }
            }
        }

        if (this.fallbackRoute) {
            let navigateTo: Route = null;
            if (this.fallbackRoute instanceof RouteDefinition) {

                if (this.parentRouteDefinition) {
                    try {
                        const parentRoute = this.parentRouteDefinition.parsePathname(this.currentPathname || this.routingAdapter.currentPathname);
                        if (parentRoute) {
                            const params = parentRoute.parameters;

                            if (!params || this.fallbackRoute.requiredParameterKeys.every(key => !!params[key])) {
                                navigateTo = this.fallbackRoute.makeRoute(params);
                            }
                        }
                    } catch (e) {
                        navigateTo = null;
                    }
                } else {
                    navigateTo = this.fallbackRoute.makeRoute();
                }
            } else {
                navigateTo = this.fallbackRoute;
            }

            if (navigateTo &&
                (!navigateTo.definition.parent ||
                    !this.currentRouteProviderStore.currentRoute ||
                    !!this.parentRouteDefinition.parsePathname(pathname))) {
                this.routingAdapter.replace(navigateTo.pathname);
            }
        }

        return null;
    }

    @State.action
    private setCurrentRouteAndPathname(route: Route, pathname: string) {
        this.currentRoute = route;
        this.currentPathname = pathname;
        this.currentController = new RoutingController(this.routingAdapter, route);

        if (this.currentRoute) {
            if (route && !route.definition.hasChild) {
                this.currentRouteProviderStore.setCurrentRoute(this.currentRoute);
            }
        }

        if (this.onRoutingControllerChanged) {
            this.onRoutingControllerChanged(this.currentController);
        }
    }
}