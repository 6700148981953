import HunEHealthInfrastructureReportingToolbar from "@HunEHealthInfrastructurePlugin/BoundedContexts/Reporting/Components/Panels/ReportingToolbar/HunEHealthInfrastructureReportingToolbar";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import NavMenuCustomItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuCustomItem";

export function getNavigationMenuItems(): INavMenuItem[] {
    const leftTopLevelMenuItems: INavMenuItem[] = [
    ];

    const rightTopLevelMenuItems: INavMenuItem[] = [
    ];

    const childMenuItems: INavMenuItem[] = [
        NavMenuCustomItem.createChild("HunEHealthInfrastructureReportingItems", "ReportingToolbar", HunEHealthInfrastructureReportingToolbar, 3),
    ];

    return [...leftTopLevelMenuItems, ...rightTopLevelMenuItems, ...childMenuItems];
}