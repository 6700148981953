import LocalDate from "./LocalDate";

export function arrayIsNullOrEmpty(array: any[]) {
    return array === null || array === undefined || !Array.isArray(array) || array.length === 0;
}

export function nullFunction() {
    // nop
}

export function isNullOrUndefined(value: any) {
    return value === null || value === undefined;
}

export function isNullOrUndefinedOrGivenString(value: string, stringToCheck: string) {
    return value === null || value === undefined || value.toLowerCase() === stringToCheck?.toLowerCase();
}

export function isNullOrEmptyString(value: string) {
    return value === null || value === undefined || value === "";
}

export function isNullOrWhiteSpace(value: string) {
    return isNullOrEmptyString(value) || !/\S/.test(value);
}
export function isNullOrEmpty(value: LocalDate) {
    return !value || value.isEmpty();
}

export function assertNotNull(value: any, errorMessage: string) {
    if (isNullOrUndefined(value)) {
        throw new Error(errorMessage);
    }
}

const emptyArray: any[] = [];
emptyArray.pop = nullFunction;
emptyArray.shift = nullFunction;
emptyArray.unshift = nullFunction as any;
emptyArray.push = nullFunction as any;

const emptyObject: any = {};

export {
    emptyArray,
    emptyObject
};
