import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import React from "react";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import CareActivityTextBlockApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityTextBlock/CareActivityTextBlockApiAdapter";
import ICareActivityTextBlockHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/ICareActivityTextBlockHistoryItem";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { ContextAwareModal, HisDocumentEditor } from "@HisPlatformControls";
import DocumentContentStore from "@CommonControls/DocumentEditor/DocumentContentStore";
import { IAutomaticInsertTextBlockContentDialogResult, IAutomaticInsertTextBlockContentDialogParams } from "./AutomaticInsertTextBlockContentDialogParams";
import DocumentTypesApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/ReferenceData/DocumentTypesApiAdapter";
import ICareActivityTextBlockAutomicFill from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/ICareActivityTextBlockAutomicFill";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import { formatReactString } from "@Toolkit/ReactClient/Common/LocalizedStrings";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";

interface IAutomaticInsertTextBlockContentDialogDependencies {
    dateTimeFormatProvider: IDateTimeFormatProvider;
}

interface IAutomaticInsertTextBlockContentDialogProps extends IModalComponentParams<IAutomaticInsertTextBlockContentDialogResult>, IAutomaticInsertTextBlockContentDialogParams {
    _dependencies?: IAutomaticInsertTextBlockContentDialogDependencies;
}

/** @screen */
@State.observer
class AutomaticInsertTextBlockContentDialog extends React.Component<IAutomaticInsertTextBlockContentDialogProps> {
    private get dateTimeFormatProvider() { return this.props._dependencies.dateTimeFormatProvider; }

    @State.observable.ref public previousItem: ICareActivityTextBlockAutomicFill = null;
    private readonly actualContent = DocumentContentStore.create();
    constructor(props: IAutomaticInsertTextBlockContentDialogProps) {
        super(props);
    }

    public componentDidMount() {
        this.load();
    }
    @State.action.bound
    private load() {
        this.previousItem = {
            modifiedAt: this.props.previous.lastModifiedAt,
            modifiedBy: this.props.previous.lastModifiedBy,
            pointOfCareName: this.props.previous.pointOfCareName
        };
        this.actualContent.setContent(this.props.previous.actualContent);
    }
    @State.bound
    public getSubtitle() {
        return formatStringWithObjectParams(StaticCareResources.CareRegister.CareActivityTextBlockListPanel.AutomaticInsertQuestion, {
            TextBlockTypeId: this.props.textBlockTypeId.value,
            LastModifiedAt: this.previousItem?.modifiedAt.format(this.dateTimeFormatProvider.getDateTimeWithoutSecondsFormat()),
            PointOfCareName: this.previousItem?.pointOfCareName,
            LastModifiedBy: this.previousItem?.modifiedBy
        });
    }
    public render() {
        return (
            <ContextAwareModal
                title={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.AutomaticInsertTextBlock}
                isOpen
                onClose={this.cancel}
                size="compact"
                fixedHeight={479}>
                <Ui.Modal.Body >
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <div>
                                <p style={{ paddingLeft: "9px", paddingRight: "9px" }}>{this.getSubtitle()} </p>
                            </div>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={12}>
                            <HisDocumentEditor contentStore={this.actualContent.forEditor} mode="TextBlock" isReadOnly />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Button onClick={this.cancel} text={StaticWebAppResources.Common.Button.Close} float="left" automationId="automaticTextBlockInsertDialogCancelButton" />
                    <Ui.Button onClickAsync={this.okAsync} text={StaticDocumentManagementResources.Common.Buttons.Insert} visualStyle="primary" float="right" automationId="automaticTextBlockInsertDialogOkButton" />
                </Ui.Modal.Footer>
            </ContextAwareModal>
        );
    }

    @State.bound
    private cancel() {
        this.props.onClose(null);
    }

    @State.bound
    private async okAsync() {
        const result: IAutomaticInsertTextBlockContentDialogResult = {
            actualContent: await this.actualContent.getContentAsDocumentFragmentAsync()
        };
        this.props.onClose(result);
    }
}

export default connect(
    AutomaticInsertTextBlockContentDialog,
    new DependencyAdapter<IAutomaticInsertTextBlockContentDialogProps, IAutomaticInsertTextBlockContentDialogDependencies>(c => ({
        dateTimeFormatProvider: c.resolve("IDateTimeFormatProvider"),
    }))
);