import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import PatientId from "@Primitives/PatientId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import EhiDocumentTypeId from "@Primitives/EhiDocumentTypeId.g";
import EhiTokenProvider from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Services/EhiTokenProvider/EhiTokenProvider";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import EhiOrganizationUnitProviderService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Organization/Services/Implementation/EhiOrganizationUnitProviderService";
import {IModalService} from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import EhiServiceBase from "@HunEHealthInfrastructurePlugin/Services/EhiServiceBase";
import EhiUserType from "@HunEHealthInfrastructurePlugin/Model/EhiUserType";
import EhiEReferralId from "@Primitives/EhiEReferralId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import EhiEReferralListQuerySettingsStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralListQuerySettingsStore";
import EhiCovidTestResultsApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/PatientRegister/ApiAdapter/EhiCovidTestResultsApiAdapter";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";

export default class EhiCareService extends EhiServiceBase {

    constructor(
        private readonly ehiCareApiAdapter: EhiCareApiAdapter,
        private readonly ehiCovidTestResultsApiAdapter: EhiCovidTestResultsApiAdapter,
        ehiTokenProvider: EhiTokenProvider,
        ehiOrganizationUnitProviderService: EhiOrganizationUnitProviderService,
        userContext: UserContext,
        modalService: IModalService
    ) {
        super(ehiTokenProvider,
            ehiOrganizationUnitProviderService,
            userContext,
            modalService);
    }

    @State.bound
    public async getCovidTestResultsAsync(patientId: PatientId) {
        return await this.performEhiOperationAsync(patientId, EhiUserType.Doctor, async () => {
            return await this.ehiCovidTestResultsApiAdapter.getCovidTestResultsAsync(
                this.ehiToken,
                patientId,
                this.organizationUnitId);
        });
    }

    @State.bound
    public async getEhiDocumentsForPatientAsync(
        patientId: PatientId,
        isEmergency: boolean,
        dateRange?: LocalDateRange,
        documentType?: EhiDocumentTypeId,
        emergencyReason?: string) {

        return await this.performEhiOperationAsync(patientId, EhiUserType.Doctor, async () => {
            return await this.ehiCareApiAdapter.getEhiDocumentsForPatientAsync(
                this.ehiToken,
                this.organizationUnitId,
                patientId,
                isEmergency,
                dateRange,
                documentType,
                emergencyReason);
        });
    }

    @State.bound
    public async loadEhiCareDocumentAsync(
        patientId: PatientId,
        documentEhiId: string,
        isEmergency: boolean,
        emergencyReason: string) {

        return await this.performEhiOperationAsync(patientId, EhiUserType.Doctor, async () => {
            return await this.ehiCareApiAdapter.loadEhiCareDocumentAsync(
                this.ehiToken,
                documentEhiId,
                this.organizationUnitId,
                isEmergency,
                emergencyReason);
        });
    }

    @State.bound
    public async getEhiDocumentsAsync(
        ehiEventCatalogIdentifier: string,
        patientId: PatientId,
        isEmergency: boolean,
        emergencyReason: string) {

        return await this.performEhiOperationAsync(patientId, EhiUserType.Doctor, async () => {
            return await this.ehiCareApiAdapter.getEhiDocumentsAsync(
                ehiEventCatalogIdentifier,
                this.ehiToken,
                this.organizationUnitId,
                patientId,
                isEmergency,
                emergencyReason);
        });
    }

    @State.bound
    public async getEReferralByIdAsync(
        patientId: PatientId,
        ehiEReferralId: EhiEReferralId,
        isEmergency: boolean,
        emergencyReason?: string) {

        return await this.performEhiOperationAsync(patientId, EhiUserType.Technical, async () => {
            return await this.ehiCareApiAdapter.getEReferralByIdAsync(
                ehiEReferralId,
                new PointOfCareId(this.organizationUnitId.value),
                isEmergency,
                emergencyReason,
                this.ehiToken);
        });
    }

    @State.bound
    public async getEReferralByIdWithSpecifiedPointOfCareAsync(
        ehiEReferralId: EhiEReferralId,
        ehiServiceCallPointOfCareId: PointOfCareId,
        isEmergency: boolean,
        emergencyReason?: string) {

        return await this.performEhiOperationWithSpecifiedPointOfCareIdAsync(EhiUserType.Technical, async () => {
            return await this.ehiCareApiAdapter.getEReferralByIdAsync(
                ehiEReferralId,
                new PointOfCareId(this.organizationUnitId.value),
                isEmergency,
                emergencyReason,
                this.ehiToken);
        }, ehiServiceCallPointOfCareId);
    }

    @State.bound
    public async getEReferralsForPatientAsync(
        patientId: PatientId,
        isEmergency: boolean,
        emergencyReason?: string,
        ehiServiceCallPointOfCareId?: PointOfCareId) {

        return await this.performEhiOperationWithSpecifiedPointOfCareIdAsync(EhiUserType.Technical, async () => {
            return await this.ehiCareApiAdapter.getEReferralsForPatientAsync(
                patientId,
                new PointOfCareId(this.organizationUnitId.value),
                isEmergency,
                emergencyReason,
                this.ehiToken);
        }, ehiServiceCallPointOfCareId);
    }

    @State.bound
    public async getEReferralsForOrganizationUnitAsync(querySettings: EhiEReferralListQuerySettingsStore) {

        return await this.performEhiOperationWithSpecifiedPointOfCareIdAsync(EhiUserType.Technical, async () => {
            return await this.ehiCareApiAdapter.getEReferralsForOrganizationUnitAsync(
                querySettings,
                this.ehiToken);
        }, new PointOfCareId(querySettings.institute.value));
    }

    @State.bound
    public async getEventDetailsAsync(
        patientId: PatientId,
        careIdentifier: string,
        isEmergency: boolean,
        emergencyReason: string) {

        return await this.performEhiOperationAsync(patientId, EhiUserType.Doctor, async () => {
            return await this.ehiCareApiAdapter.getEventDetailsAsync(
                this.ehiToken,
                careIdentifier,
                this.organizationUnitId,
                isEmergency,
                emergencyReason);
        });
    }

    @State.bound
    public async getEventEmergencyEventsAsync(
        patientId: PatientId,
        ehiId: string,
        isEmergency: boolean,
        emergencyReason: string) {

        return await this.performEhiOperationAsync(patientId, EhiUserType.Doctor, async () => {
            return await this.ehiCareApiAdapter.getEventEmergencyEventsAsync(
                this.ehiToken,
                patientId,
                ehiId,
                this.organizationUnitId,
                isEmergency,
                emergencyReason);
        });
    }
    
    @State.bound
    public async getEhiCovidVaccinationEventEntriesAsync(date: LocalDate, pointOfCareId: PointOfCareId) {
        return await this.performEhiOperationWithSpecifiedPointOfCareIdAsync(EhiUserType.Doctor, async () => {
            return await this.ehiCareApiAdapter.getEhiCovidVaccinationEventEntriesAsync(this.ehiToken, date, pointOfCareId);
        }, pointOfCareId);
    }

    @State.bound
    public async loadEhiCareDocumentWithSpecifiedPointOfCareIdAsync(documentEhiId: string, pointOfCareId: PointOfCareId, isEmergency: boolean, emergencyReason: string) {

        return await this.performEhiOperationWithSpecifiedPointOfCareIdAsync(EhiUserType.Doctor, async () => {
            return await this.ehiCareApiAdapter.loadEhiCareDocumentAsync(
                this.ehiToken,
                documentEhiId,
                new OrganizationUnitId(pointOfCareId.value),
                isEmergency,
                emergencyReason
            );
        }, pointOfCareId);
    }
}
