import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FormExtensionBase, { IReactionParams } from "@PluginInterface/FormExtension/FormExtensionBase";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import EhiUserContext from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiUserContext";
import IPrescriptionToBeCreated from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionsToBeCreated/IPrescriptionToBeCreated";

@Di.injectable()
export default class ManagePrescriptionsExtension extends FormExtensionBase<IPrescriptionToBeCreated[]> {

    constructor(
        @Di.inject("PractitionerApiAdapter") private readonly practitionerApiAdapter: PractitionerApiAdapter,
        @Di.inject("OrganizationReferenceDataStore") private readonly organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("EhiUserContext") private readonly ehiUserContext: EhiUserContext
    ) {
        super();

        this.registerAsyncEvent("NewPrescriptionCreating", this.getEESZTLoggedInDoctorAsync);
    }

    @State.action.bound
    private async getEESZTLoggedInDoctorAsync(params: IReactionParams<IPrescriptionToBeCreated[]>) {
        const pointOfCareId = params.entity.map(i => i.pointOfCareId)[0];

        if (!isNullOrUndefined(this.ehiUserContext.token)) {
            const eesztLoggedInPractitioner = await this.practitionerApiAdapter.getPractitionerByUserIdAsync(this.ehiUserContext.token.userId);
            const assignments = await this.practitionerApiAdapter.getPointOfCareIdsByPractitionerIdsAsync([eesztLoggedInPractitioner.id]);
            const loggedInDoctor = await this.organizationReferenceDataStore.doctorMap.getOrLoadAsync(eesztLoggedInPractitioner.id);
            const isPractitionerAssignedToPointOfCare = assignments.value.map(v => v.value).includes(pointOfCareId.value);

            if (!isNullOrUndefined(loggedInDoctor) && isPractitionerAssignedToPointOfCare) {
                params.entity.forEach(e => {
                    e.prescriber = loggedInDoctor.id;
                });
            }
        }
    }
}