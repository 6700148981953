import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import PermissionDefinitionBuilder from "@PluginInterface/OperationAccessControl/PermissionDefinitionBuilder";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";
import { PermissionDefinitions } from "@HisPlatform/PermissionDefinitions/PermissionDefinitions.g";

export default function configureDocumentPermissionDefinition(permissionDefinitionRegistry: IPermissionDefinitionRegistry) {
    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.DocumentManagement.Documents.Delete)
            .withPermissionScopes(
                PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare),
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.documentAction, ["Delete"]))
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.DocumentManagement.Documents.Publish)
            .withPermissionScopes(
                PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare),
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.documentAction, ["Publish", "CancelPublish"]))
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.DocumentManagement.Documents.Finalize)
            .withPermissionScopes(
                PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare),
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.documentAction, ["Finalize", "Edit"]))
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(PermissionDefinitions.DocumentManagement.Documents.PrintPreview)
            .withPermissionScope(
                PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare))
            .build());

    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(PermissionDefinitions.DocumentManagement.Templating.Edit).build());
}
