import React from "react";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import * as Ui from "@CommonControls";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import IWidgetComponentProps from "@PluginInterface/Dashboard/IWidgetComponentProps";
import WeatherWidgetApiAdapter from "./WeatherWidgetApiAdapter";
import IWeatherWidgetApiAdapter from "./IWeatherWidgetApiAdapter";
import Styles from "./WeatherWidget.less";
import IWeatherData from "./IWeatherData";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Icon, { iconNameType } from "@CommonControls/Icon";
import { createAsyncErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import Log from "@Log";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

interface IWeatherWidgetDependencies {
    localizationService: ILocalizationService;
}

interface IWeatherWidgetProps extends IWidgetComponentProps {
    _dependencies?: IWeatherWidgetDependencies;
}

/** @screen */
@State.observer
class WeatherWidget extends React.Component<IWeatherWidgetProps> {
    private get localizationService() { return this.props._dependencies.localizationService; }

    @State.observable.ref private weatherData: IWeatherData = null;
    @State.observable.ref private isErrorState = false;

    private dateString = this.localizationService.localizeDate(LocalDate.today());
    private runAsync = createAsyncErrorDispatcher(this);

    public componentDidMount() {
        this.runAsync(this.loadAsync());
    }

    private async loadAsync() {
        try {
            this.clearError();
            const adapter: IWeatherWidgetApiAdapter = new WeatherWidgetApiAdapter();
            const result = await adapter.getWeatherDataAsync(this.props.configuration.LocationCode);
            this.setWeatherData(result);
        } catch (err) {
            Log.error(err);
            this.setError();
        }
    }

    @State.action
    private clearError() {
        this.isErrorState = false;
    }

    @State.action
    private setError() {
        this.isErrorState = true;
    }

    @State.action
    private setWeatherData(weatherData: IWeatherData) {
        this.weatherData = weatherData;
        this.weatherData.icon = this.weatherData.icon.replace(/-/g, "") as iconNameType;
    }

    public render() {
        return (
            <Ui.Dashboard.Widget
                name={this.props.name}
                isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                isCollapsible
                title={StaticProductivityResources.Widgets.Weather.Title}
                icon={this.weatherData && this.weatherData.icon as any}
                automationId="WeatherWidget"
            >
                <>
                    {this.isErrorState ? this.renderErrorState() : this.renderWidgetBody()}
                </>
            </Ui.Dashboard.Widget>

        );
    }

    private renderErrorState() {
        return <SpanWithIcon visualStyle="warning" iconName="exclamation">{StaticWebAppResources.Common.Messages.ExternalServiceIsNotAvailable}</SpanWithIcon>;
    }

    private renderWidgetBody() {
        return (
            <Ui.Flex className={Styles.weatherWidget}>
                <Ui.Flex.Item lg={5} md={12} className={Styles.locationDateContainer}>
                    <p className={Styles.upFlexItem}>{this.props.configuration.LocationCode.toUpperCase()}</p>
                    <p className={Styles.downFlexItem}>{this.dateString}</p>
                </Ui.Flex.Item>
                <Ui.Flex.Item lg={2} md={6}>
                    <Icon iconName={this.weatherData && this.weatherData.icon as any} size={"extra large"} visualStyle={"white"} />
                </Ui.Flex.Item>
                <Ui.Flex.Item lg={5} md={6} className={Styles.locationDateContainer}>
                    <p className={Styles.upFlexItem}> {this.weatherData && `${this.weatherData.temp}°C`}</p>
                    <p className={Styles.downFlexItem}> {this.weatherData && this.weatherData.summary}</p>
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }
}

export default connect(
    withLoadingBoundary(withHisErrorBoundary(WeatherWidget)),
    new DependencyAdapter<IWeatherWidgetProps, IWeatherWidgetDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
