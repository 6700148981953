import { createNestedRoutes } from "@Toolkit/ReactClient/Routing/CreateNestedRoutes";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import IOutpatientTreatmentRouteParams from "@HisPlatform/Application/Routes/IOutpatientTreatmentRouteParams";

export interface IPrescriptionRouteParams extends IOutpatientTreatmentRouteParams {
    prescriptionId: string;
}

const prescriptionStandaloneRouteDefinitions = createNestedRoutes(ApplicationRoutes.careActivity, {
    prescriptions: new RouteDefinition<IPrescriptionRouteParams>("/prescriptions/:prescriptionId?"),
});

export default prescriptionStandaloneRouteDefinitions;