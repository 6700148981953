import React, { useCallback } from "react";
import TokenUsageStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TokenUsageStore";
import TokenUsageListStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TokenUsageListStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ITemplateToken from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateToken";

interface IHasSelectTokenCallback {
    onSelectToken: (tokenUsage: TokenUsageStore) => void;
}

interface IUsedTokenListProps extends IHasSelectTokenCallback {
    tokenUsages: TokenUsageListStore;
    tokenMap: Map<string, ITemplateToken>;
}

function UsedTokenList(props: React.PropsWithChildren<IUsedTokenListProps>) {
    return (
        <ul>
            {props.tokenUsages.items.map(tokenUsage => {
                const token = props.tokenMap?.get(tokenUsage.symbol);
                return (
                    <UsedTokenItem key={tokenUsage.tokenId} tokenUsage={tokenUsage} onSelectToken={props.onSelectToken} token={token} />
                );
            })}
        </ul>
    );
}

function UsedTokenItem(props: React.PropsWithChildren<{ tokenUsage: TokenUsageStore, token: ITemplateToken } & IHasSelectTokenCallback>) {
    const select = useCallback(() => {
        props.onSelectToken(props.tokenUsage);
    }, [props.onSelectToken, props.tokenUsage]);

    return (
        <li key={props.tokenUsage.tokenId}><a onClick={select}>{props.tokenUsage.formatDisplayName(props.token?.displayName)}</a></li>
    );
}

export default State.observer(UsedTokenList);