import React from "react";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import FormPanel from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";
import SaveButton from "@CommonControls/SaveButton";
import StaticSurgeryResources from "@HisPlatform/BoundedContexts/Surgery/StaticResources/StaticSurgeryResources";
import SurgeryId from "@Primitives/SurgeryId.g";
import { useSurgeryPanelStore, provideSurgeryPanelStore } from "./SurgeryPanelStoreProvider";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import PatientId from "@Primitives/PatientId.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
export interface ISurgeryPanelProps {
    selectedId: SurgeryId | null;
    onSaveAsync: () => Promise<void>;
    onClose: () => void;
    onUseCaseChange: (selectedId: string, useCaseState: INDataUseCaseState) => void;
    _patientId?: PatientId;
}

function SurgeryPanel(props: ISurgeryPanelProps) {

    const store = useSurgeryPanelStore();

    return (
        <UseCaseFrame
            title={StaticSurgeryResources.SurgeryList.EditorTitle}
            toolbar={(
                <>
                    <SaveButton automationId="Save_Button" onClickAsync={store.saveAsync} />
                </>
            )} >
            <FormPanel form={store.form} definitionId={store.form?.definitionId} />
        </UseCaseFrame>
    );
}

export default connect<ISurgeryPanelProps>(provideSurgeryPanelStore(SurgeryPanel),
    new PatientContextAdapter<ISurgeryPanelProps>(patientContext => ({
        _patientId: patientContext?.patientId
    })));