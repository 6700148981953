import React from "react";
import Styles from "./Pager.less";
import PagerButton from "@CommonControls/DataGrid/Paging/PagerButton";
import { IPagingState } from "@CommonControls/DataGrid/IDataGridProps";

interface ICompactPagerProps {
    pageCount: number;
    pagingState: IPagingState;
    onPagingStateChange?: (pagingState: IPagingState) => void;
}


const CompactPager: React.FC<ICompactPagerProps> = props => {

    const currentPage = props.pagingState.currentPage;

    const isPrevButtonEnabled = currentPage > 0;
    const isNextButtonEnabled = currentPage < props.pageCount - 1;

    return (
        <div className={Styles.compactPager} data-automation-id="__pager">
            <div>
                <PagerButton
                    targetPage={currentPage - 1}
                    pagingState={props.pagingState}
                    iconName="angleLeft"
                    onPagingStateChange={props.onPagingStateChange}
                    automationId="__prevPageButton"
                    isDisabled={!isPrevButtonEnabled}
                />
            </div>
            <div className={Styles.pages}>
                {currentPage} / {props.pageCount}
            </div>
            <div>
                <PagerButton
                    targetPage={currentPage + 1}
                    pagingState={props.pagingState}
                    iconName="angleRight"
                    onPagingStateChange={props.onPagingStateChange}
                    automationId="__nextPageButton"
                    isDisabled={!isNextButtonEnabled}
                />
            </div>
        </div>
    );
};

export default CompactPager;