import CreateServiceRequestCommandFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/UseCases/ServiceRequestManagement/CreateServiceRequestCommandFactory";
import CreateServiceRequestCommandProcessor from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/UseCases/ServiceRequestManagement/CreateServiceRequestCommandProcessor";
import DependencyContainer from "@DiContainer";
import ICommandFactory from "@Toolkit/CommonWeb/CommandProcessing/Definition/ICommandFactory";
import IGenericCommandProcessor from "@Toolkit/CommonWeb/CommandProcessing/Definition/IGenericCommandProcessor";

DependencyContainer
    .bind("ICommandFactory")
    .to(CreateServiceRequestCommandFactory);

DependencyContainer
    .bind("IGenericCommandProcessor")
    .to(CreateServiceRequestCommandProcessor);