import CareActivityId from "@Primitives/CareActivityId.g";
import BusinessErrorBase from "@HisPlatform/Model/DomainModel/BusinessError/BusinessErrorBase";
import ResourceId from "@Primitives/ResourceId.g";
import IStatusChangeBlockingReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/IStatusChangeBlockingReason";

export default class CantChangeCareActivityStateError extends BusinessErrorBase {
    constructor(
        public readonly careActivityId: CareActivityId,
        public readonly activityReferenceResourceId: ResourceId,
        public readonly reasons: IStatusChangeBlockingReason[],
    ) {
        super();
    }

    public get name(): string {
        return "CantChangeCareActivityStateError";
    }
}