import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as Proxy from "@HisPlatform/BoundedContexts/UserManagement/Api/Proxy.g";
import UserGroup from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroup";
import UserId from "@Primitives/UserId.g";
import UserGroupId from "@Primitives/UserGroupId.g";
import Di from "@Di";

type ResponseType = Proxy.UpdateUserGroupCommandResponse | Proxy.AddUserGroupCommandResponse | Proxy.UserGroupDto;

@Di.injectable()
export default class UserGroupStoreMapper extends EntityStoreMapper<Proxy.UserGroupDto, UserGroup, ResponseType> {
    protected storeEntityIdType = UserGroupId;

    protected entityDtoSelector(response: ResponseType) {
        if (response instanceof Proxy.UserGroupDto) {
            return response;
        } else {
            return response.validatedResult;
        }
    }

    public applyToStoreCore(target: UserGroup, response: Proxy.UserGroupDto) {      
        const entity = response;

        target.shortName = entity.shortName; 
        target.name = entity.name;
        target.userIds = entity.userIds.map(item => new UserId(item.value));
        target.description = entity.description;
        target.displayName =  entity.shortName ? `${entity.shortName} - ${entity.name}` : entity.name;
        target.rowVersion = entity.rowVersion;
    }
}
