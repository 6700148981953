import ITokenSettingsPanelRegistry from "@PluginInterface/BoundedContexts/DocumentManagement/TokenSettingsPanelRegistry/ITokenSettingsPanelRegistry";
import DateTokenFormatterSettingsPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TokenSettingsPanels/DateTokenFormatterSettingsPanel";
import ReferenceDataTokenFormatterSettingsPanel from "./ReferenceDataTokenFormatterSettingsPanel";
import ExtensibleEnumTokenFormatterSettingsPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TokenSettingsPanels/ExtensibleEnumTokenFormatterSettingsPanel";
import BooleanTokenFormatterSettingsPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TokenSettingsPanels/BooleanTokenFormatterSettingsPanel";
import DateTimeTokenFormatterSettingsPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TokenSettingsPanels/DateTimeTokenFormatterSettingsPanel";
import TableTokenValueFormatterSettingsPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TokenSettingsPanels/TableTokenValueFormatterSettingsPanel";
import DoctorTokenFormatterSettingsPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TokenSettingsPanels/DoctorTokenFormatterSettingsPanel";
import FormTokenPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TokenSettingsPanels/DynamicTokenValueFormatterSettings/FormTokenPanel";
import AggregateTokenPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TokenSettingsPanels/DynamicTokenValueFormatterSettings/AggregateTokenPanel";

export function configureHisPlatformTemplateTokenFormatterSettingsPanels(registry: ITokenSettingsPanelRegistry) {
    registry.register("DateTokenValueFormatterSettingsDto", DateTokenFormatterSettingsPanel);
    registry.register("ReferenceDataTokenValueFormatterSettingsDto", ReferenceDataTokenFormatterSettingsPanel);
    registry.register("ExtensibleEnumTokenValueFormatterSettingsDto", ExtensibleEnumTokenFormatterSettingsPanel);
    registry.register("BooleanTokenValueFormatterSettingsDto", BooleanTokenFormatterSettingsPanel);
    registry.register("DateTimeTokenValueFormatterSettingsDto", DateTimeTokenFormatterSettingsPanel);
    registry.register("TableTokenValueFormatterSettingsDto", TableTokenValueFormatterSettingsPanel);
    registry.register("DoctorTokenValueFormatterSettingsDto", DoctorTokenFormatterSettingsPanel);
    registry.register("DynamicTokenValueFormatterSettingsDto", FormTokenPanel);
    registry.register("DynamicAggregateDataElement", AggregateTokenPanel);
}