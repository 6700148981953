import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import HisUseCaseIdentifierSetter from "@HisPlatform/Components/HisUseCaseIdentifierSetter";
import UseCaseFrameType from "@HisPlatform/Components/UseCaseFrame/UseCaseFrameType";
import { UseCaseFrameContext, IUseCaseFrameContext } from "@HisPlatform/Components/UseCaseFrame/UseCaseFrameContext";

interface IHisUseCaseHostDependencies {
    useCaseRegistry: IUseCaseRegistry;
}

interface IHisUseCaseHostProps {
    _dependencies?: IHisUseCaseHostDependencies;

    useCaseIdentifier: UseCaseIdentifier;
    useCaseArguments: UseCaseArgument[];
    onGetPanelProps?: (useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) => object;

    state?: string;
    onStateChange?: (newState: string) => void;

    frameType: UseCaseFrameType;
    onClose?: (wasSuccessful: boolean) => void;
}

@State.observer
class HisUseCaseHost extends React.Component<IHisUseCaseHostProps> {

    private get useCaseRegistry() { return this.props._dependencies!.useCaseRegistry; }

    @State.computed private get useCaseDescriptor() {
        return this.props.useCaseIdentifier === null ? null : this.useCaseRegistry.tryGet2(this.props.useCaseIdentifier);
    }

    @State.computed private get useCaseComponentProps() {
        if (!this.useCaseDescriptor) {
            return null;
        }

        const mappedFromArguments = this.useCaseDescriptor.mapUseCaseArgumentsToProps?.(this.props.useCaseArguments) ?? {};
        const mappedFromState = this.useCaseDescriptor.mapUseCaseStateToProps?.(this.props.state, this.props.onStateChange) ?? {};
        const additionalProps = this.props.onGetPanelProps?.(this.props.useCaseIdentifier, this.props.useCaseArguments) ?? {};

        return {
            ...mappedFromArguments,
            ...mappedFromState,
            ...additionalProps,
        };
    }

    @State.computed private get frameContext(): IUseCaseFrameContext {
        return {
            frameType: this.props.frameType,
            onClose: this.props.onClose
        };
    }

    public render() {
        return (
            <>
                <HisUseCaseIdentifierSetter value={this.props.useCaseIdentifier?.value} />
                <UseCaseFrameContext.Provider value={this.frameContext}>
                    {this.useCaseDescriptor && React.createElement(this.useCaseDescriptor.component, this.useCaseComponentProps)}
                </UseCaseFrameContext.Provider>
            </>
        );
    }
}

export default connect(
    HisUseCaseHost,
    new DependencyAdapter<IHisUseCaseHostProps, IHisUseCaseHostDependencies>(c => ({
        useCaseRegistry: c.resolve("IUseCaseRegistry"),
    }))
);
