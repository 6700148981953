import Button, { ISimpleButtonProps } from "@Toolkit/ReactClient/Components/CommonControls/Button";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface ISaveButtonDependencies {
    localizationService: IToolkitLocalizationService;
}
class SaveButton extends React.PureComponent<ISimpleButtonProps> {
    public static defaultProps: Partial<ISimpleButtonProps> = {
        visualStyle: "primary",
    };

    public render() {
        return <Button text={this.props._dependencies.localizationService.staticResources.button.save} {...this.props} iconName="save" />;
    }
}

export default connect(
    SaveButton,
    new DependencyAdapter<ISimpleButtonProps, ISaveButtonDependencies>(c => ({
        localizationService: c.resolve("IToolkitLocalizationService"),
    }))
);
