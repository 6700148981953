import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import IScreenRegistry, { IScreenEventArgs, IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import React, { useMemo, useCallback } from "react";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import { ScreenStateContextProvider } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import { ActionDispatcherProvider } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import ScreenContextWrapper from "@Toolkit/ReactClient/Components/Screen/ScreenContextWrapper";
import { nullFunction } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import { ScreenContextDataBase } from "@HisPlatform/Services/Definition/ActionProcessing/ScreenContextData";
import { useScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import { GenericScreenCancelledEventHandlers, GenericScreenDeletedEventHandlers, GenericScreenSavedEventHandlers, GenericScreenSavedNewEventHandlers } from "@HisPlatform/Components/ShowScreenAction/GenericScreenEventHandlers";
import { HisErrorBoundary } from "@HisPlatform/Components/HisPlatformControls";

interface IScreenRendererProps {
    action: ShowScreenFrontendActionBase;
    screenState: string;

    screenContextData?: ScreenContextDataBase;

    onClose: () => void;

    onSavedExisting?: () => void;
    onSavedNew?: (newId: StringEntityId) => void;
    onCancelled?: () => void;
    onDeleted?: () => void;
}

export default function ScreenRenderer(props: IScreenRendererProps) {

    const { registry, authorizationService } = useDependencies(c => ({
        registry: c.resolve<IScreenRegistry>("IScreenRegistry"),
        authorizationService: c.resolve<AuthorizationService>("AuthorizationService"),
    }));

    const descriptor = useMemo(() => registry.getDescriptorOrNull(props.action.id), [props.action]);
    const navigationContext = useScreenNavigationContext();

    if (!descriptor) {
        throw new Error(`Component is not registered for this action: '${props.action.id.value}'`);
    }

    if (props.action.displayMode === ScreenDisplayMode.ContentOnly) {
        return (
            <ScreenStateContextProvider
                action={props.action}
                screenState={props.screenState}
                displayMode={props.action.displayMode}
                screenContextData={null}
                key="none"
            >
                {
                    React.createElement<IScreenPropsBase<ShowScreenFrontendActionBase>>(descriptor.component, {
                        action: props.action,
                        screenState: props.screenState
                    })
                }
            </ScreenStateContextProvider>
        );
    }

    const { globalRoutingStore } = useDependencies(c => ({
        globalRoutingStore: c.resolve<GlobalRoutingStore>("GlobalRoutingStore"),
    }));

    const cancelled = useCallback(() => {
        const e: IScreenEventArgs<() => void> = {
            close: props.onClose,
            continue: props.onCancelled ?? nullFunction,
            action: props.action,
            temporary_routingStore: globalRoutingStore,
            screenDescriptor: descriptor,
            screenNavigationContext: navigationContext
        };

        if (descriptor.onCancelled) {
            descriptor.onCancelled(e);
        } else {
            GenericScreenCancelledEventHandlers.default(e);
        }

    }, [descriptor, navigationContext, props.action, props.onClose, props.onCancelled]);

    const savedExisting = useCallback(() => {
        const e: IScreenEventArgs<() => void> = {
            close: props.onClose,
            continue: props.onSavedExisting ?? nullFunction,
            action: props.action,
            temporary_routingStore: globalRoutingStore,
            screenDescriptor: descriptor,
            screenNavigationContext: navigationContext
        };

        if (descriptor.onSavedExisting) {
            descriptor.onSavedExisting(e);
        } else {
            GenericScreenSavedEventHandlers.default(e);
        }

    }, [descriptor, navigationContext, props.action, props.onClose, props.onSavedExisting]);

    const savedNew = useCallback((newId: StringEntityId, showCreatedEntityScreenAction?: ShowScreenFrontendActionBase) => {
        const e: IScreenEventArgs<(newId: StringEntityId, showCreatedEntityScreenAction?: ShowScreenFrontendActionBase) => void> = {
            close: props.onClose,
            continue: props.onSavedNew ?? nullFunction,
            action: props.action,
            temporary_routingStore: globalRoutingStore,
            screenDescriptor: descriptor,
            screenNavigationContext: navigationContext
        };

        if (descriptor.onSavedNew) {
            descriptor.onSavedNew(newId, e, showCreatedEntityScreenAction);
        } else {
            GenericScreenSavedNewEventHandlers.default(newId, e, showCreatedEntityScreenAction);
        }

    }, [descriptor, navigationContext, props.action, props.onClose, props.onSavedNew]);

    const deleted = useCallback(() => {
        const e: IScreenEventArgs<() => void> = {
            close: props.onClose,
            continue: props.onDeleted ?? nullFunction,
            action: props.action,
            temporary_routingStore: globalRoutingStore,
            screenDescriptor: descriptor,
            screenNavigationContext: navigationContext
        };

        if (descriptor.onDeleted) {
            descriptor.onDeleted(e);
        } else {
            GenericScreenDeletedEventHandlers.default(e);
        }

    }, [descriptor, navigationContext, props.action, props.onClose, props.onDeleted]);

    const contextData = useMemo(() => descriptor.getScreenContextData?.(props.action) ?? null, [props.action]);
    const contextHashValue = useMemo(() => contextData?.getHashValue() ?? "none", [contextData]);

    return (
        <HisErrorBoundary>
            <ScreenStateContextProvider
                action={props.action}
                screenState={props.screenState}
                displayMode={props.action.displayMode}
                screenContextData={props.screenContextData ?? contextData}
                key={contextHashValue}
                onSavedExisting={savedExisting}
                onSavedNew={savedNew}
                onCancelled={cancelled}
                onDeleted={deleted}
            >
                <ActionDispatcherProvider>
                    <ScreenContextWrapper
                        sidebarComponent={props.action.displayMode == ScreenDisplayMode.WithoutSideBar ? null : descriptor.sidebarComponent}
                        topToolbarComponent={props.action.displayMode == ScreenDisplayMode.WithoutSideBar ? null : descriptor.topToolbarComponent}
                    >
                        {React.createElement<IScreenPropsBase<ShowScreenFrontendActionBase>>(descriptor.component, {
                            action: props.action,
                            screenState: props.screenState,
                            key: props.action.id.value
                        })}
                    </ScreenContextWrapper>
                </ActionDispatcherProvider>
            </ScreenStateContextProvider>
        </HisErrorBoundary>
    );
}
