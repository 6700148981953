import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import { getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import StandaloneAppointmentRoutes from "@HisPlatform/Components/Pages/AppointmentPages/StandaloneAppointmentRoutes";
import { getNavigationMenuItems } from "@HisPlatform/Packages/Scheduling/Configuration/NavigationMenuItems";
import { configureFrontendActionMappings } from "@HisPlatform/Packages/Scheduling/FrontendActions/MapperConfigurations.g";
import ShowAppointmentScheduleDefinitionsScreenAction from "@HisPlatform/Packages/Scheduling/FrontendActions/ShowAppointmentScheduleDefinitionsScreenAction.g";
import ShowInvalidAppointmentsScreenAction from "@HisPlatform/Packages/Scheduling/FrontendActions/ShowInvalidAppointmentsScreenAction.g";
import ShowPatientAppointmentsScreenAction from "@HisPlatform/Packages/Scheduling/FrontendActions/ShowPatientAppointmentsScreenAction.g";
import ShowPractitionerAppointmentsScreenAction from "@HisPlatform/Packages/Scheduling/FrontendActions/ShowPractitionerAppointmentsScreenAction.g";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import NavigateToRouteActionProcessor from "@HisPlatform/Services/Implementation/ActionProcessing/NavigateToRouteActionProcessor";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import GlobalActionRegistry from "@Toolkit/ReactClient/ActionProcessing/GlobalActionRegistry";

class SchedulingPackageInitializer implements IPackageInitializer {

    public configureActionProcessor(registry: GlobalActionRegistry) {

        registry.register(
            ShowAppointmentScheduleDefinitionsScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => ApplicationRoutes.appointmentScheduleDefinitions.makeRoute()));

        registry.register(
            ShowPractitionerAppointmentsScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => StandaloneAppointmentRoutes.practitionerAppointments.makeRoute()));

        registry.register(
            ShowInvalidAppointmentsScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => StandaloneAppointmentRoutes.invalidAppointments.makeRoute()));

        registry.register(
            ShowPatientAppointmentsScreenAction.id,
            NavigateToRouteActionProcessor.createFactory((a: ShowPatientAppointmentsScreenAction) => StandaloneAppointmentRoutes.registeredPatientAppointments.makeRoute({
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.patientRegisterViewAppointments),
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument(new AppointmentScheduleEntryId("null"), "appointmentId")]),
                patientId: a.patientId.toUrl(),
                careActivityId: a.careActivityId.toUrl()
            })));
    }

    public configureDynamicNavigation(registry: IDynamicNavigationRegistry): void {
        getNavigationMenuItems().forEach(navigationItem => registry.register(navigationItem));
    }

    public configureMapper(configurator: IMapperConfigurator): void {
        configureFrontendActionMappings(configurator);
    }
}

export default new SchedulingPackageInitializer();