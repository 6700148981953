import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import CareActivityId from "@Primitives/CareActivityId.g";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

export interface ICantCloseCareActivityDialogParams extends IModalParams {
    careActivityId: CareActivityId;
    validationResult: IClientValidationResult[];
}

export interface ICantCloseCareActivityDialogResult {
    navigateTo: CantCloseCareActivityDialogNavigateTo;
}

enum CantCloseCareActivityDialogNavigateTo {
    Diagnoses = 0,
    PerformedServices = 1,
    CareDocuments = 2,
    ClinicalData = 3,
    DischargeData = 4,
    ServiceRequests = 5,
    ServiceRequestObservations = 6,
    TextBlocks = 7
}

export {
    CantCloseCareActivityDialogNavigateTo
};

export default class CantCloseCareActivityDialogParams implements ICantCloseCareActivityDialogParams {
    public static type = "CantCloseCareActivityDialog";
    public get type() { return CantCloseCareActivityDialogParams.type; }

    constructor(
        public readonly careActivityId: CareActivityId,
        public readonly validationResult: IClientValidationResult[]
    ) {}
}