import Button, { IButtonProps } from "@CommonControls/Button";
import IShowScreenActionCallContextParams from "@HisPlatform/Services/Definition/ActionProcessing/IShowScreenActionCallContextParams";
import { useScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import { useActionDispatcher } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";
import { usePermissionScopes } from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/PermissionScopeProvider";
import React, { useCallback, useMemo } from "react";
import useModalService from "@Toolkit/ReactClient/Components/ModalService/useModalService";
import ShowScreenFrontendActionBase from "./ShowScreenFrontendActionBase";
import _ from "@HisPlatform/Common/Lodash";
import { ActionContinuation } from "./ActionContinuation";
import ActionDescriptor from "./ActionDescriptor";
import { actionVisualStyleToButton } from "./ActionVisualStyle";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export interface IActionBoundButtonProps extends IButtonProps {
    actionDescriptor: ActionDescriptor;

    callContextParams?: any;

    onBeforeExecute?: (action: FrontendActionBase) => { actionContinuation: ActionContinuation, action?: FrontendActionBase };
    onBeforeExecuteAsync?: (action: FrontendActionBase) => Promise<{ actionContinuation: ActionContinuation, action?: FrontendActionBase }>;

    onExecute?: (action: FrontendActionBase) => void;
    onExecuteAsync?: (action: FrontendActionBase) => Promise<void>;
}

export default function ActionBoundButton(props: IActionBoundButtonProps) {

    const { authorizationService } = useDependencies(c => ({
        authorizationService: c.resolve<AuthorizationService>("AuthorizationService")
    }));

    const contextScopes = usePermissionScopes();
    const isPermitted = useMemo(() => {
        const scopes = _.uniqBy([...contextScopes, ...props.actionDescriptor.requiredPermissionScopes], s => s.type);
        return authorizationService.hasPermissionFor(props.actionDescriptor.action.id, ...scopes);
    }, [props.actionDescriptor, contextScopes]);

    const actionDispatcher = useActionDispatcher();
    const showScreenActionNavigationContext = useScreenNavigationContext();
    const modalService = useModalService();

    const clickAsync = useCallback(async () => {
        let continuationResult: { actionContinuation: ActionContinuation, action?: FrontendActionBase };
        if (props.onBeforeExecute) {
            continuationResult = props.onBeforeExecute(props.actionDescriptor.action);
        }

        if (continuationResult?.actionContinuation === ActionContinuation.Break) {
            return;
        }

        if (props.onBeforeExecuteAsync) {
            continuationResult = await props.onBeforeExecuteAsync(props.actionDescriptor.action);
        }

        if (continuationResult?.actionContinuation === ActionContinuation.Break) {
            return;
        }

        const actionToExecute = continuationResult?.action ?? props.actionDescriptor.action;

        if (props.onExecute) {
            props.onExecute(actionToExecute);
            return;
        }

        if (props.onExecuteAsync) {
            await props.onExecuteAsync(actionToExecute);
            return;
        }

        if (actionToExecute instanceof ShowScreenFrontendActionBase) {
            await actionDispatcher.dispatchAsync<IShowScreenActionCallContextParams>(actionToExecute, { ...props.callContextParams, navigationContext: showScreenActionNavigationContext, modalService });
        } else {
            await actionDispatcher.dispatchAsync(actionToExecute);
        }
    }, [props.actionDescriptor, showScreenActionNavigationContext, props.onExecute, props.onExecuteAsync]);

    const displaySettings = useMemo(() => actionDispatcher.getDisplaySettings(props.actionDescriptor.action), [props.actionDescriptor]);

    return (
        <Button
            text={displaySettings?.displayName}
            iconName={displaySettings?.iconName}
            visualStyle={isNullOrUndefined(props.visualStyle) ? actionVisualStyleToButton(props.actionDescriptor.presentation?.visualStyle) : props.visualStyle}
            disabled={props.actionDescriptor.presentation?.isEnabled === false}
            {...props}
            isNotPermitted={!isPermitted}
            onClickAsync={clickAsync}
        />
    );
}
