import Di from "@Di";
import IWeatherWidgetApiAdapter from "./IWeatherWidgetApiAdapter";
import IWeatherData from "./IWeatherData";

const apiBaseUrl = `${window.location.protocol}//${window.location.host}/weather/hu/47d5019d04/`;
@Di.injectable()
export default  class WeatherWidgetApiAdapter implements IWeatherWidgetApiAdapter {
     public async getWeatherDataAsync(locationCode: string): Promise<IWeatherData> {
         const response = await fetch(`${apiBaseUrl}${locationCode}/?format=json`);
         const responseJson = await response.json();
         return{
             temp: responseJson.current.temp,
             icon: responseJson.current.icon,
             summary: responseJson.current.summary
         };
     }
}