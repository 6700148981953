import React from "react";
import Message from "@CommonControls/Message";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import EhiErrorSeverity from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiErrorSeverity";
import IEhiError from "@HunEHealthInfrastructurePlugin/Common/Model/IEhiError";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Styles from "./EhiErrorSummary.less";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import StaticHunEHealthInfrastructureWebAppResources from "@HunEHealthInfrastructurePlugin/StaticResources/StaticHunEHealthInfrastructureWebAppResources";

interface IEhiErrorSummaryDependencies {
    localizationService: ILocalizationService;
    notificationService: INotificationService;
    dialogService: IDialogService;
}

interface IEhiErrorSummaryProps {
    _dependencies?: IEhiErrorSummaryDependencies;
    errors: IEhiError[];
    isFilterOpen?: boolean;
    codeFilter?: string[];
    codesBeingShownInToast?: string[];
    codesBeingShownInDialog?: string[];
    notConfiguredCodesDisplayMode?: "toast" | "message" | "dialog";
}

class EhiErrorSummary extends React.Component<IEhiErrorSummaryProps> {

    private get localizationService() { return this.props._dependencies.localizationService; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get dialogService() { return this.props._dependencies.dialogService; }

    public static defaultProps: Partial<IEhiErrorSummaryProps> = {
        notConfiguredCodesDisplayMode: "message",
        codesBeingShownInDialog: ["IAM-1001"]
    };

    private readonly codesWithModuleName = ["DOR-1016", "DOR-1017"];

    @State.computed
    private get errors() {
        return this.props.errors || [];
    }

    @State.computed
    private get notConfiguredErrors() {
        return this.errors.filter(e =>
            (!this.props.codeFilter?.find(cf => cf === e.code))
            && (!this.props.codesBeingShownInToast?.find(ct => ct === e.code)
            && (!this.props.codesBeingShownInDialog?.find(cd => cd === e.code)))) || [];
    }

    @State.computed
    private get errorsToShowInToast() {
        return this.errors.filter(e => this.props.codesBeingShownInToast?.find(i => i === e.code))
            ?.concat(this.props.notConfiguredCodesDisplayMode === "toast" ? this.notConfiguredErrors : [])
                || [];
    }

    @State.computed
    private get errorsToRender() {
        return this.errors.filter(e => this.props.codeFilter?.find(i => i === e.code))
            ?.concat(this.props.notConfiguredCodesDisplayMode === "message" ? this.notConfiguredErrors : []) || [];
    }

    @State.computed
    private get errorsToShownInDialog() {
        return this.errors.filter(e => this.props.codesBeingShownInDialog?.find(i => i === e.code))
            ?.concat(this.props.notConfiguredCodesDisplayMode === "dialog" ? this.notConfiguredErrors : []) || [];
    }

    @State.bound
    private getErrorMessage(e: IEhiError) {
        const localizedSeverity = this.localizationService.localizeEnum(EhiErrorSeverity[e.severity], "EhiErrorSeverity");
        let localizedMessage = `${e.code} ${localizedSeverity.Name}: ${e.message}`;
        if (e.moduleName && this.codesWithModuleName.includes(e.code)) {
            localizedMessage = localizedMessage + ` (${e.moduleName})`;
        }
        return localizedMessage;
    }

    public componentDidMount() {
        this.showToastErrors();
        this.showDialogErrors();
    }

    public componentDidUpdate(prevProps: IEhiErrorSummaryProps) {
        if (prevProps.errors !== this.props.errors
            || prevProps.codesBeingShownInToast !== this.props.codesBeingShownInToast
            || prevProps.codesBeingShownInDialog !== this.props.codesBeingShownInDialog) {
            this.showToastErrors();
            this.showDialogErrors();
        }
    }

    @State.bound
    private showToastErrors() {
        this.errorsToShowInToast.forEach((e: IEhiError) => {
            const message = this.getErrorMessage(e);

            switch (e?.severity) {
                case EhiErrorSeverity.Error:
                    this.notificationService.error(message);
                    break;
                case EhiErrorSeverity.Warning:
                    this.notificationService.warning(message);
                    break;
                case EhiErrorSeverity.Info:
                default:
                    this.notificationService.info(message);
                    break;
            }
        });
    }

    @State.bound
    private showDialogErrors() {
        if (this.errorsToShownInDialog.length > 0) {
            const messages: string[] = [];
            this.errorsToShownInDialog.forEach((e: IEhiError) => {
                messages.push(this.getErrorMessage(e));
            });
            const messageListToRender = (
                <>
                    {messages.map((message, index) => {
                        return (
                            <p key={index}>{message}</p>
                        );
                    })}
                </>
            );
            this.dialogService.ok(StaticHunEHealthInfrastructureWebAppResources.EhiErrorSummaryDialog.Title, messageListToRender);
        }
    }

    @State.bound
    private renderError(e: IEhiError, key: number) {
        return <li key={key}>{this.getErrorMessage(e)}</li>;
    }

    public render() {

        const className = new CompositeClassName(Styles.container);

        className.addIf(this.props.isFilterOpen, Styles.someMarginTop);

        if (this.errorsToRender?.length > 0) {
            return (
                <Message type="warning" className={className.classNames}>
                    <ul className={Styles.noMargin}>{this.errorsToRender.map(this.renderError)}</ul>
                </Message>
            );
        }
        return <></>;
    }

}

export default connect(
    EhiErrorSummary,
    new DependencyAdapter<IEhiErrorSummaryProps, IEhiErrorSummaryDependencies>(container => {
        return {
            localizationService: container.resolve("ILocalizationService"),
            notificationService: container.resolve("INotificationService"),
            dialogService: container.resolve("IDialogService")
        };
    })
);