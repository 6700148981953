/* auto-inject-disable */
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import * as Proxy from "@AssecoMedPlugin/BoundedContexts/CommonReferenceData/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import WorkingStatusId from "@Primitives/WorkingStatusId.g";
import { GetTypeNameWithPrefix } from "@AssecoMedPlugin/PluginHelper";

@Di.injectable()
export default class CommonReferenceDataExtensibleEnumApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("ICommonReferenceDataClient")) private readonly commonReferenceDataApiClient: Proxy.ICommonReferenceDataClient
    ) {
        super();
    }

    @State.bound
    public loadWorkingStatusIdsAsync(): Promise<SimpleStore<WorkingStatusId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.commonReferenceDataApiClient.getWorkingStatusIdsQueryAsync(...args),
            response => response.workingStatusIds
        );
    }

    @State.bound
    public loadWorkplaceTypeIdsAsync(): Promise<SimpleStore<WorkingStatusId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.commonReferenceDataApiClient.getWorkplaceTypeIdsQueryAsync(...args),
            response => response.workplaceTypeIds
        );
    }
}