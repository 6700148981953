import React from "react";
import * as Ui from "@CommonControls";
import Prescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Prescription";
import MedicationVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/MedicationVersion";
import MedicationEquipmentList from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationEquipmentList";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicationEquipmentPrescriptionType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationEquipmentPrescriptionType";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import MedicationEquipmentListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationEquipmentListItem";
import MedicationEquipmentItemDetail from "./MedicationEquipmentItemDetail";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import MedicationPricingAndSubsidies from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationPricingAndSubsidies";
import IMedicationSubsidyClaimType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IMedicationSubsidyClaimType";
import { isNullOrUndefined, isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface IMedicationEquipmentListDetailViewDependecies {
    notificationService: INotificationService;
}

interface IMedicationEquipmentListDetailViewProps {
    _dependencies?: IMedicationEquipmentListDetailViewDependecies;
    prescription: Prescription;
    medications: MedicationVersion[];
    pricingsAndSubsidies: MedicationPricingAndSubsidies[];
    selectedClaimType: IMedicationSubsidyClaimType;
    onCreateNewMedicationEquipmentListItemAsync: () => Promise<MedicationEquipmentListItem>;
    ePrescriptionNotAllowedIsoPrefixes: string[];
    prescribeWithIsoGroupAllowedIsoCodes: string[];
    isClaimTypeTemporaryPermission: boolean;
    onTypeChanged: (type: MedicationEquipmentPrescriptionType) => void;
}

@State.observer
class MedicationEquipmentListDetailView extends React.Component<IMedicationEquipmentListDetailViewProps> {

    @State.computed
    private get equipmentList() {
        return this.props.prescription.medicationReferenceBase as MedicationEquipmentList;
    }

    @State.computed
    private get isRepair() {
        return this.equipmentList?.type === MedicationEquipmentPrescriptionType.Repair;
    }

    @State.computed
    private get addingNewEnabled() {
        return (this.equipmentList?.prescribeToIsoGroup === false && this.equipmentList?.type === MedicationEquipmentPrescriptionType.Prescription)
            || this.equipmentList?.items?.length === 0;
    }

    @State.computed
    private get cannotBePrescribedToIsoGroup() {
        if (this.equipmentList?.type !== MedicationEquipmentPrescriptionType.Prescription
            || this.equipmentList?.items.length !== 1
            || isNullOrUndefined(this.equipmentList.items[0].medicationVersionSelector?.id)) {
            return true;
        }
        const medication = this.props.medications?.find(i => ValueWrapper.equals(i.id, this.equipmentList.items[0].medicationVersionSelector?.id));
        if (isNullOrUndefined(medication?.isoCode)) {
            return true;
        }
        return !this.props.prescribeWithIsoGroupAllowedIsoCodes.some(i => !!i && medication.isoCode.startsWith(i));
    }

    @State.computed
    private get isRepairAllowedOnly(): boolean {
        const firstItem = this.equipmentList.items[0];
        const medication = this.props.medications?.find(i => ValueWrapper.equals(i.id, firstItem.medicationVersionSelector?.id));
        return !isNullOrUndefined(medication) && !medication.validity.includes(LocalDate.today());
    }

    @State.bound
    private renderItemEditor(item: MedicationEquipmentListItem, index: number) {
        const medication = this.props.medications?.find(i => ValueWrapper.equals(i.id, item.medicationVersionSelector?.id));
        const pricingAndSubsidies = this.props.pricingsAndSubsidies?.find(i => ValueWrapper.equals(i.id, item.medicationVersionSelector?.id));
        return (
            <MedicationEquipmentItemDetail
                item={item}
                index={index}
                medication={medication}
                pricingAndSubsidies={pricingAndSubsidies}
                selectedClaimType={this.props.selectedClaimType}
                isRepair={this.isRepair}
                isClaimTypeTemporaryPermission={this.props.isClaimTypeTemporaryPermission}
                equipmentList={this.equipmentList}
            />
        );
    }

    @State.bound
    public onDeleteConfirmationAsync() {
        if (this.equipmentList.items.length > 1) {
            return Promise.resolve(true);
        } else {
            this.props._dependencies.notificationService.error(StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Messages.CannotRemoveLastItem);
            return Promise.resolve(false);
        }
    }

    public render() {
        const equipmentList = this.props.prescription.medicationReferenceBase as MedicationEquipmentList;
        return (
            <>
                <Ui.Flex>
                    <Ui.Flex.Item xs={9}>
                        <Ui.UniversalEnumSelector
                            enumName={"MedicationEquipmentPrescriptionType"}
                            enumOrigin="server"
                            value={equipmentList.type}
                            onChange={this.props.onTypeChanged}
                            enumType={MedicationEquipmentPrescriptionType}
                            displayMode="groupedRadioButtons"
                            automationId="equipmentPrescriptionTypeSelector"
                            propertyIdentifier="MedicationReference.Type"
                            disabled={this.isRepairAllowedOnly}
                        />
                    </Ui.Flex.Item>
                    {this.equipmentList?.type === MedicationEquipmentPrescriptionType.Prescription && <Ui.Flex.Item xs={3}>
                        <Ui.CheckBox
                            value={equipmentList.prescribeToIsoGroup}
                            onChange={equipmentList.setPrescribeToIsoGroup}
                            disabled={this.cannotBePrescribedToIsoGroup}
                            tooltipContent={this.cannotBePrescribedToIsoGroup && StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Messages.NotPrescribableToIsoGroup || undefined}
                            automationId="prescribeToIsoGroupCheckbox"
                            label={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.Labels.PrescribeToIsoGroup}
                            propertyIdentifier="MedicationReference.PrescribeToIsoGroup"
                            forceShowValidationsResults
                        />
                    </Ui.Flex.Item>}
                </Ui.Flex>
                <ListPanel<MedicationEquipmentListItem>
                    items={equipmentList.items}
                    renderItemEditor={this.renderItemEditor}
                    alwaysEdit
                    onCreateNewAsync={this.props.onCreateNewMedicationEquipmentListItemAsync}
                    observerItems
                    allowCreatingNew={this.addingNewEnabled}
                    onDeleteItemConfirmationAsync={this.onDeleteConfirmationAsync}
                    propertyIdentifier="MedicationReference.Items"
                />
            </>
        );
    }
}

export default connect(MedicationEquipmentListDetailView,
    new DependencyAdapter<IMedicationEquipmentListDetailViewProps, IMedicationEquipmentListDetailViewDependecies>(c => ({
        notificationService: c.resolve("INotificationService")
    }))
);
