import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import * as Ui from "@CommonControls";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import {dispatchAsyncErrors} from "@Toolkit/CommonWeb/AsyncHelpers";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import HealthcareProfessionId from "@Primitives/HealthcareProfessionId.g";
import HealthcareProfession from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/HealthcareProfession";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IHealthCareProfessionSelectBoxDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IHealthCareProfessionSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IHealthCareProfessionSelectBoxDependencies;

    identifierSystemId: string;
    codeFilter?: (code: string) => boolean;
    value: HealthcareProfessionId | HealthcareProfessionId[];
    onChange: (value: HealthcareProfessionId | HealthcareProfessionId[]) => void;
}

@State.observer
class HealthCareProfessionSelectBox extends React.Component<IHealthCareProfessionSelectBoxProps> {
    @State.computed
    private get items(): Array<ISelectBoxItem<HealthcareProfessionId>> {
        return this.props._dependencies.organizationReferenceDataStore.healthCareProfession.items
            .filter(item => item.codes
                .some(code => code.identifierSystemId.value === this.props.identifierSystemId)
            )
            .map(this.toSelectBoxItem)
            .filter(item => !isNullOrUndefined(item));
    }

    public componentDidMount(): void {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private async loadAsync(): Promise<void> {
        await this.props._dependencies.organizationReferenceDataStore.healthCareProfession.ensureAllLoadedAsync();
    }

    @State.bound
    private toSelectBoxItem(item: HealthcareProfession): ISelectBoxItem<HealthcareProfessionId> {
        const codeValue = item.getCodeValueByIdentifierSystem(this.props.identifierSystemId);
        if (!isNullOrUndefined(this.props.codeFilter) && !this.props.codeFilter(codeValue)) {
            return null;
        }

        return {
            text: `${codeValue} - ${item.name}`,
            value: item.id
        } as ISelectBoxItem<HealthcareProfessionId>;
    }

    public render() {
        const { _dependencies, ...rest } = this.props;
        return (
            <Ui.SelectBox
                {...rest}
                multiSelect
                loading={!this.items}
                items={this.items}
            />
        );
    }
}

export default connect(
    HealthCareProfessionSelectBox,
    new DependencyAdapter<IHealthCareProfessionSelectBoxProps, IHealthCareProfessionSelectBoxDependencies>(container => {
        return {
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore")
        };
    })
);