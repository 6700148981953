import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/CommonReferenceData/Api/Proxy.g";
import Di from "@Di";
import CountryId from "@Primitives/CountryId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import ICountry from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/ICountry";
import {arrayIsNullOrEmpty, isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import {CreateRequestId} from "@HisPlatform/Common/RequestHelper";
import {buildQueryStringArray} from "@Toolkit/CommonWeb/QueryStringBuilder";
import {createOperationInfo} from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import {IOrderingState, IPagingState} from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import CountrySelectorQueryOrderingFields from "@HisPlatform/BoundedContexts/CommonReferenceData/Api/CommonReferenceData/Enum/CountrySelectorQueryOrderingFields.g";

@Di.injectable()
export default class CountryApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("ICommonReferenceDataClient") private readonly apiClient: Proxy.ICommonReferenceDataClient,
    ) {
        super();
    }

    public getAllIdsAsync(validOn: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<CountryId[]>(),
            async target => {
                const response = await this.apiClient.getCountryIdsQueryAsync(
                    CreateRequestId(),
                    isNullOrUndefined(validOn) ? "" : validOn.stringify()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.countries;
            }
        );
    }

    public searchCountriesByTextAsync(text: string, maxResultCount: number, validOn: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<CountryId[]>(),
            async target => {
                const response = await this.apiClient.searchCountriesQueryAsync(
                    CreateRequestId(),
                    maxResultCount.toString(),
                    text,
                    isNullOrUndefined(validOn) ? "" : validOn.stringify()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.countryIds;
            }
        );
    }

    @State.bound
    public getCountriesByIdsAsync(ids: CountryId[]): Promise<SimpleStore<ICountry[]>> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {

                if (arrayIsNullOrEmpty(ids)) {
                    target.value = [];
                }

                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value));

                const response = await this.apiClient.getCountriesByIdsQueryAsync(
                    CreateRequestId(),
                    buildQueryStringArray(normalizedIds)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.countries.map(this.mapCountry);
            }
        );
    }

    @State.bound
    public searchCountries(isoAlpha3: string, name: string, citizenship: string, validOn: LocalDate, ordering: IOrderingState, paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<ICountry>>(),
            async (target) => {
                const response = await this.apiClient.countrySelectorQueryAsync(
                    CreateRequestId(),
                    new Proxy.CountrySelectorControllerDto({
                        isoAlpha3: isoAlpha3,
                        countryName: name,
                        citizenshipName: citizenship,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfCountrySelectorQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfCountrySelectorQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: CountrySelectorQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        }),
                        validOn: validOn
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results && response.results.values.map(this.mapCountry),
                };
            }
        );
    }

    private mapCountry(country: Proxy.CountryDto) {
        return {
            id: country.id,
            citizenshipName: country.citizenshipName,
            countryName: country.countryName,
            isoNumericCode: country.isoNumericCode,
            isoAlpha2: country.isoAlpha2,
            isoAlpha3: country.isoAlpha3,
            rowVersion: country.rowVersion,
            validity: country.validity,
            zipCodeMask: country.zipCodeMask
        } as ICountry;
    }
}
