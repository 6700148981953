
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import DecisionSupportScreenStore from "./DecisionSupportScreenStore";
import { editorScreenRenderFunc } from "@Toolkit/CommonWeb/PanelStore/EditorScreenRenderFunc";
import { IDecisionSupportScreenProps } from "./DecisionSupportScreen";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ShowEditPatientAdmissionDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEditPatientAdmissionDataScreenAction.g";
import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";

const {
    ContextComponent: DecisionSupportScreenStoreContext,
    StoreProvider: DecisionSupportScreenStoreProvider,
    useStore: useDecisionSupportScreenStore,
    provideStore: provideDecisionSupportScreenStore,
} = createPanelStoreProvider<DecisionSupportScreenStore>(DecisionSupportScreenStore, (props: IDecisionSupportScreenProps) => ({
    // TODO admit patient?
    unauthorizedPageTitle: (props.action instanceof ShowNewAdmitPatientScreenAction || props.action instanceof ShowEditPatientAdmissionDataScreenAction) ?
        StaticCareResources.PatientAdmission.Titles.Admission :
        StaticWebAppResources.OutpatientWorkflow.CareActivityBaseDataStep.Title
}), editorScreenRenderFunc);

export {
    DecisionSupportScreenStoreContext,
    DecisionSupportScreenStoreProvider,
    useDecisionSupportScreenStore,
    provideDecisionSupportScreenStore
};