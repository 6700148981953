import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";

@Di.injectable()
export default class ApplicationVersionApiAdapter extends ApiAdapterBase2 {

    constructor(
        @Di.inject("IDiagnosticsClient") private readonly apiClient: Proxy.IDiagnosticsClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader
    ) {
        super(referenceDataLoader);
    }

    public getAsync() {
        return this.executeOperationAsync(
            () => this.apiClient.getApplicationVersionQueryAsync(CreateRequestId()),
            response => response.version
        );
    }

}