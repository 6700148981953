import Button, { ISimpleButtonProps } from "@Toolkit/ReactClient/Components/CommonControls/Button";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface IDeleteButtonDependencies {
    localizationService: IToolkitLocalizationService;
}
class DeleteButton extends React.PureComponent<ISimpleButtonProps> {
    public static defaultProps: Partial<ISimpleButtonProps> = {
        visualStyle: "primary",
    };

    public render() {
        return <Button text={this.props._dependencies.localizationService.staticResources.button.delete} {...this.props} iconName="remove" />;
    }
}

export default connect(
    DeleteButton,
    new DependencyAdapter<ISimpleButtonProps, IDeleteButtonDependencies>(c => ({
        localizationService: c.resolve("IToolkitLocalizationService"),
    }))
);
