import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import IDateTimeFormatConfiguration from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatConfiguration";
import Di from "@Di";

@Di.injectable()
export default class DateTimeFormatProvider implements IDateTimeFormatProvider {

    private configuration: IDateTimeFormatConfiguration;
    
    public getDateTimeFormat(): string {
        return this.configuration.dateTimeFormat.normal;
    }
    
    public getDateFormat(): string {
        return this.configuration.dateFormat.normal;
    }

    public getMonthFormat(): string {
        return this.configuration.monthFormat.normal;
    }
    
    public getDateTimeWithoutSecondsFormat(): string {
        return this.configuration.dateTimeFormat.withoutSeconds;
    }
    
    public getDateTimeFormatsToParse(): string[] {
        return this.configuration.dateTimeFormat.parseFormats;
    }

    public getTimeFormatsToParse(): string[] {
        return this.configuration.timeFormat.parseFormats;
    }

    public getDateFormatsToParse(): string[] {
        return this.configuration.dateFormat.parseFormats;
    }

    public getMonthFormatsToParse(): string[] {
        return this.configuration.monthFormat.parseFormats;
    }
    
    public getPartialDateRegexPattern(): string {
        return this.configuration.dateFormat.partialDateRegex.pattern;
    }
    
    public loadConfiguration(data: IDateTimeFormatConfiguration): void {
        this.configuration = data;
    }

    public getTodayString() {
        return this.configuration.todayString;
    }

    public getTimeFormat(): string {
        return this.configuration.timeFormat.normal;
    }

    public getTimeWithoutSecondsFormat(): string {
        return this.configuration.timeFormat.withoutSeconds;
    }
}