import Di from "@Di";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import moment from "moment";
import TherapyRecommendationStore from "./TherapyRecommendationStore";
import TherapyRecommendationId from "@Primitives/TherapyRecommendationId.g";
import ReferencedEhiTherapyAssignment from "./ReferencedTherapyAssignment";
import TherapyRecommendationHistoryItem from "./TherapyRecommendationHistoryItem";

@Di.injectable()
export default class TherapyRecommendationFactory {

    public createNewStore() {
        const store = new TherapyRecommendationStore(true);
        store.id = new TherapyRecommendationId("new");
        store.therapyAssignment = new ReferencedEhiTherapyAssignment(null);
        store.historyItems.push(this.createNewHistoryItem());
        store.currentItemIndex = 0;

        return store;
    }

    private createNewHistoryItem() {
        const historyItem = new TherapyRecommendationHistoryItem();

        historyItem.isNew = true;
        historyItem.versionNumber = 1;
        historyItem.index = 0;
        historyItem.createdAt = moment(moment.now());        
        historyItem.historyItemDetail = new MedicalConditionHistoryItemDetail();
        historyItem.description = "";
        historyItem.endDate = LocalDate.today();
        historyItem.startDate = LocalDate.today();
        return historyItem;
    }
}
