exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Pager_row-flex_1ISKT {\n  display: flex;\n  flex-direction: row;\n}\n.Pager_page-size-chooser_2Lz3y {\n  width: 60px;\n  margin: 2px 5px;\n}\n.Pager_items-label_3ORGj {\n  margin: 5px 0;\n}\n.Pager_size-select_2ndPl {\n  text-align: start;\n}\n.Pager_compact-pager_1C6E- {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 0 2px;\n}\n.Pager_compact-pager_1C6E- .Pager_pages_37YrQ {\n  line-height: 28px;\n}\n", ""]);

// exports
exports.locals = {
	"row-flex": "Pager_row-flex_1ISKT",
	"rowFlex": "Pager_row-flex_1ISKT",
	"page-size-chooser": "Pager_page-size-chooser_2Lz3y",
	"pageSizeChooser": "Pager_page-size-chooser_2Lz3y",
	"items-label": "Pager_items-label_3ORGj",
	"itemsLabel": "Pager_items-label_3ORGj",
	"size-select": "Pager_size-select_2ndPl",
	"sizeSelect": "Pager_size-select_2ndPl",
	"compact-pager": "Pager_compact-pager_1C6E-",
	"compactPager": "Pager_compact-pager_1C6E-",
	"pages": "Pager_pages_37YrQ"
};