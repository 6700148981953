exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PatientCareActivitiesMasterDetailPanel_careactivities-container_379dY {\n  margin-top: 11px;\n}\n.PatientCareActivitiesMasterDetailPanel_title-button_36y8C {\n  max-height: 26px;\n  margin: auto 8px auto 0px;\n}\n.PatientCareActivitiesMasterDetailPanel_pagebox-header-button-container_3sMOe {\n  margin-top: 14px;\n}\n.PatientCareActivitiesMasterDetailPanel_header-option_1OHuX {\n  text-align: center;\n}\n.PatientCareActivitiesMasterDetailPanel_filter-text-container_7JGFx {\n  margin: -10px 8px 4px 25px;\n  overflow: hidden;\n}\n.PatientCareActivitiesMasterDetailPanel_filter-text_vMr7E {\n  font-family: PragatiNarrow;\n  font-size: 12px;\n  font-weight: normal;\n  color: #8695a7;\n  line-height: 13px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n.PatientCareActivitiesMasterDetailPanel_shield-icon_1ZFUi {\n  margin-left: 3px;\n}\n.PatientCareActivitiesMasterDetailPanel_flip-icon_W9Pwa svg {\n  transform: rotate(180deg);\n}\nhr.PatientCareActivitiesMasterDetailPanel_header-line_1gCMr {\n  width: 100%;\n  height: 1px;\n  border: 0;\n  background-color: rgba(0, 0, 0, 0.08);\n  margin: 0px;\n}\n.PatientCareActivitiesMasterDetailPanel_careactivity-tooltip_3raT7 {\n  text-align: left;\n}\n.PatientCareActivitiesMasterDetailPanel_document-viewer_27mbI {\n  margin: 0;\n  width: 100%;\n  min-height: calc(100vh - 206px);\n}\n.PatientCareActivitiesMasterDetailPanel_source-rectangle_ocw4C {\n  border-radius: 2px;\n  background-color: #82d4ed;\n  width: fit-content;\n  padding-left: 4px;\n  padding-right: 4px;\n  font-size: 12px;\n  line-height: 14px;\n  color: white;\n}\n", ""]);

// exports
exports.locals = {
	"careactivities-container": "PatientCareActivitiesMasterDetailPanel_careactivities-container_379dY",
	"careactivitiesContainer": "PatientCareActivitiesMasterDetailPanel_careactivities-container_379dY",
	"title-button": "PatientCareActivitiesMasterDetailPanel_title-button_36y8C",
	"titleButton": "PatientCareActivitiesMasterDetailPanel_title-button_36y8C",
	"pagebox-header-button-container": "PatientCareActivitiesMasterDetailPanel_pagebox-header-button-container_3sMOe",
	"pageboxHeaderButtonContainer": "PatientCareActivitiesMasterDetailPanel_pagebox-header-button-container_3sMOe",
	"header-option": "PatientCareActivitiesMasterDetailPanel_header-option_1OHuX",
	"headerOption": "PatientCareActivitiesMasterDetailPanel_header-option_1OHuX",
	"filter-text-container": "PatientCareActivitiesMasterDetailPanel_filter-text-container_7JGFx",
	"filterTextContainer": "PatientCareActivitiesMasterDetailPanel_filter-text-container_7JGFx",
	"filter-text": "PatientCareActivitiesMasterDetailPanel_filter-text_vMr7E",
	"filterText": "PatientCareActivitiesMasterDetailPanel_filter-text_vMr7E",
	"shield-icon": "PatientCareActivitiesMasterDetailPanel_shield-icon_1ZFUi",
	"shieldIcon": "PatientCareActivitiesMasterDetailPanel_shield-icon_1ZFUi",
	"flip-icon": "PatientCareActivitiesMasterDetailPanel_flip-icon_W9Pwa",
	"flipIcon": "PatientCareActivitiesMasterDetailPanel_flip-icon_W9Pwa",
	"header-line": "PatientCareActivitiesMasterDetailPanel_header-line_1gCMr",
	"headerLine": "PatientCareActivitiesMasterDetailPanel_header-line_1gCMr",
	"careactivity-tooltip": "PatientCareActivitiesMasterDetailPanel_careactivity-tooltip_3raT7",
	"careactivityTooltip": "PatientCareActivitiesMasterDetailPanel_careactivity-tooltip_3raT7",
	"document-viewer": "PatientCareActivitiesMasterDetailPanel_document-viewer_27mbI",
	"documentViewer": "PatientCareActivitiesMasterDetailPanel_document-viewer_27mbI",
	"source-rectangle": "PatientCareActivitiesMasterDetailPanel_source-rectangle_ocw4C",
	"sourceRectangle": "PatientCareActivitiesMasterDetailPanel_source-rectangle_ocw4C"
};