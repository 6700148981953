import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import Calendar from "@CommonControls/DateTimePicker/Calendar";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import moment from "moment";
import { ISchedulerEvent, ShowNewEntryOn, ISchedulerEntryEventHandlers } from "@CommonControls/Scheduler/ISchedulerProps";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import Style from "./AppointmentScheduler.less";
import _ from "@HisPlatform/Common/Lodash";
import ICalendarDayStyle from "@CommonControls/DateTimePicker/ICalendarDayStyle";
import SchedulerScale from "@CommonControls/Scheduler/SchedulerScale";
import DayOfWeek from "@CommonControls/Scheduler/DayOfWeek";
import SchedulerViewMode from "@CommonControls/Scheduler/SchedulerViewMode";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import SchedulerToolbar from "@CommonControls/Scheduler/SchedulerToolbar";
import IAppointmentSchedulerBaseProps from "./AppointmentSchedulerBaseProps";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import AppointmentSchedulerEventEntry from "./AppointmentSchedulerEventEntry";
import IRect from "@CommonControls/Scheduler/IRect";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { iconNameType } from "@CommonControls/Icon";

interface IAppointmentSchedulerProps extends IAppointmentSchedulerBaseProps {
    hideCalendar?: boolean;
    onRenderCalendar?: (calendar: React.ReactNode) => React.ReactNode;
    calendarStyles?: ICalendarDayStyle[];
    onGetEntryClassName: (event: ISchedulerEvent, isNewEntryPlaceable?: boolean) => string;
    onGetInvalidEntryClassName?: (invalidEntry: ISchedulerEvent, viewMode: SchedulerViewMode) => string;
    onGetEntryAutomationIdPrefix?: (entry: ISchedulerEvent) => string;
    onGetNewEntryIconName?: (event: ISchedulerEvent, isNewEntryPlaceable?: boolean) => iconNameType;
    onGetNewEntryIconClass?: (event: ISchedulerEvent, isNewEntryPlaceable?: boolean) => string;
    onGetNewEntryClassName?: (event: ISchedulerEvent, isNewEntryPlaceable?: boolean) => string;
    onGetNewEntryOffset?: (event: ISchedulerEvent) => IRect; scrollToTargets?: TimeOfDay[];
    leftSidebarClassName?: string;
    schedulerSlotClassName?: string;
    hideCalendarWithoutData?: boolean;
    hideUnavailableWorkHours?: boolean;
    disableDynamicScale?: boolean;
    showNewEntry?: boolean;
    renderSchedulerPlaceholder?: () => React.ReactNode;
}

@State.observer
export default class AppointmentScheduler extends React.Component<IAppointmentSchedulerProps> {
    @State.observable public schedulerScale: SchedulerScale = SchedulerScale.Week;
    @State.observable public scheduleViewMode: SchedulerViewMode = SchedulerViewMode.Calendar;
    private readonly defaultDayStart = new TimeOfDay(8, 0);
    private readonly defaultDayEnd = new TimeOfDay(18, 0);

    @State.action.bound
    private setCurrentDate(value: LocalDate) {
        this.props.setSelectedMoment(value.toUtcDayStartMoment());
    }

    @State.computed
    private get showNewEntryOn() {
        return !!this.props.showNewEntry ? ShowNewEntryOn.Entries : null;
    }

    @State.computed private get events() {
        return this.props.events;
    }

    @State.computed private get invalidEvents() {
        return isNullOrUndefined(this.props.invalidEvents)
            ? []
            : this.props.invalidEvents;
    }

    @State.computed
    private get timeSlotCount() {
        if (this.props.disableDynamicScale) {
            return 2;
        }
        const smallestTimeSlotDuration = Math.min(...this.events.map(e => moment.duration(e.endTime.diff(e.startTime)).asMinutes()));

        return this.events?.length ? Math.ceil(30 / smallestTimeSlotDuration) : 2;
    }

    @State.computed
    private get dayStart() {

        if (!this.allFilteredEvents.length) {
            return this.defaultDayStart;
        }

        let startTime = DateTimeService.now();
        if (this.schedulerScale === SchedulerScale.Day) {
            startTime = this.allFilteredEvents[0].startTime;
        }

        if (this.schedulerScale === SchedulerScale.Week) {
            startTime = moment.min(this.allFilteredEvents.map(e => moment(e.startTime.format("LT"), "LT")));
        }

        const res = new TimeOfDay(startTime.hour(), startTime.minute());

        if (this.props.hideUnavailableWorkHours) {
            return res;
        } else {
            return this.defaultDayStart.lessThan(res) ? this.defaultDayStart : res;
        }
    }

    @State.computed
    private get dayEnd() {

        if (!this.allFilteredEvents.length) {
            return this.defaultDayEnd;
        }

        let endTime = DateTimeService.now();
        if (this.schedulerScale === SchedulerScale.Day) {
            endTime = this.allFilteredEvents[this.allFilteredEvents.length - 1].endTime;
        }

        if (this.schedulerScale === SchedulerScale.Week) {
            endTime = moment.max(this.allFilteredEvents.map(e => moment(e.endTime.format("LT"), "LT")));
        }

        const res = new TimeOfDay(endTime.hour(), endTime.minute());

        if (this.props.hideUnavailableWorkHours) {
            return res;
        } else {
            return this.defaultDayEnd.greaterThan(res) ? this.defaultDayEnd : res;
        }
    }

    @State.computed
    private get workDays() {
        if (!this.filteredEvents.length || !this.isWeekScale || this.scheduleViewMode !== SchedulerViewMode.Timeline) {
            return [
                DayOfWeek.Monday,
                DayOfWeek.Tuesday,
                DayOfWeek.Wednesday,
                DayOfWeek.Thursday,
                DayOfWeek.Friday,
                DayOfWeek.Saturday,
                DayOfWeek.Sunday
            ];
        }

        const days = _.uniq(this.filteredEvents.map(e => e.startTime.day())).sort();
        const min = days[0];
        const max = days[days.length - 1];

        return Array.from(new Array(max - min + 1), (x, i) => i + min);
    }

    public componentDidMount() {
        State.runInAction(() => {
            this.schedulerScale = SchedulerScale.Week;
        });
    }

    private getBlockClassName(): string {
        return Style.block;
    }

    @State.action.bound
    private setEvent(e: ISchedulerEvent, newStart: moment.Moment, newEnd: moment.Moment) {
        e.startTime = newStart;
        e.endTime = newEnd;
    }

    @State.bound
    private renderEntry(entryData: { event: ISchedulerEvent, handlers: ISchedulerEntryEventHandlers, isPlaceable?: boolean, isReadOnly?: boolean }) {
        return (
            <AppointmentSchedulerEventEntry
                key={entryData.event.id}
                event={entryData.event}
                handlers={entryData.handlers}
                isPlaceable={entryData.isPlaceable}
                suppressEditIcon={this.props.showNewEntry}
                editIconClass={this.props.entryEditIconClassName}
                onGetNewEntryIconName={this.props.onGetNewEntryIconName}
                onGetNewEntryIconClass={this.props.onGetNewEntryIconClass}
                onRenderTooltip={this.props.onRenderTooltip}
                onRenderAdditionalEntryComponent={this.props.onRenderAdditionalEntryComponent}
                className={this.props.entryClassName}
                onGetAutomationIdPrefix={this.props.onGetEntryAutomationIdPrefix}
                isReadOnly={entryData.isReadOnly}
            />
        );
    }

    @State.computed
    private get filteredEvents() {
        return this.events.filter(s => s.startTime.isSame(this.props.currentMoment, this.intervalFilter as any));
    }

    @State.computed
    private get filteredInvalidEvents() {
        return this.invalidEvents.filter(s => s.startTime.isSame(this.props.currentMoment, this.intervalFilter as any));
    }

    @State.computed
    private get allFilteredEvents() {
        return this.filteredEvents.concat(this.filteredInvalidEvents).sort((e, f) => (e as ISchedulerEvent).startTime.diff((f as ISchedulerEvent).startTime));
    }

    @State.computed
    private get intervalFilter() {
        switch (this.schedulerScale) {
            case SchedulerScale.Day:
                return "day";
                break;
            case SchedulerScale.Month:
                return "month";
            case SchedulerScale.Week:
            default:
                return "week";
        }

    }

    @State.action.bound
    private setSchedulerScale(scale: SchedulerScale) {
        this.schedulerScale = scale;
        this.props.onScheduleScaleChanged?.(scale);
    }

    @State.computed
    private get isWeekScale() {
        return this.schedulerScale === SchedulerScale.Week;
    }

    @State.action.bound
    private onTimePickerChange(value: TimeOfDay) {
        this.props.setSelectedMoment(this.props.currentMoment.clone().minute(value.minutes).hour(value.hours));
    }

    private * renderAvailableTimeSlots() {
        const clickHandler = (value: TimeOfDay) => () => {
            this.onTimePickerChange(value);
        };

        for (const entry of this.props.scrollToTargets) {
            yield (
                <div key={entry.hours}>
                    <Ui.Button
                        text={entry.toString()}
                        size="compact"
                        style={{ width: 42 }}
                        onClick={clickHandler(entry)}
                        visualStyle={"flat"}
                        automationId={entry.toString() + "_button"}
                    />
                </div>
            );
        }
    }

    @State.action.bound
    private renderTimePicker() {
        return [
            ...this.renderAvailableTimeSlots()
        ];
    }

    @State.bound
    private renderToolbar() {
        return (
            <SchedulerToolbar
                scale={this.schedulerScale}
                viewMode={this.scheduleViewMode}
                onScaleChange={this.setSchedulerScale}
                onViewModeChange={this.setScheduleViewMode}
                useSelectBox={true}
            />
        );
    }

    @State.bound
    private renderStandaloneCalendar() {
        return (
            <>
                {this.props.onRenderAdditionalSidebarComponent?.()}
                {(!this.props.hideCalendarWithoutData || !!this.props.events.length) &&
                    <>
                        {this.renderToolbar()}
                        <Ui.Flex>
                            <Ui.Flex.Item xs={9}>
                                <div style={{ float: "right" }}>
                                    <Calendar
                                        value={this.currentDate}
                                        onChange={this.setCurrentDate}
                                        calendarDayStyles={this.props.calendarStyles}
                                        calendarCount={2}
                                        highlightWeek={this.isWeekScale}
                                        onSelectedMonthChange={this.props.onSelectedMonthChange}
                                        onNavigateForward={this.props.onSelectedMonthChange}
                                        onNavigateBack={this.props.onSelectedMonthChange}
                                    />

                                </div>
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={3}>
                                <div className={Style.standaloneTimePicker}>
                                    {this.renderTimePicker()}
                                </div>
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </>
                }
            </>
        );
    }

    @State.computed
    private get currentDate() {
        return LocalDate.createFromMoment(this.props.currentMoment);
    }

    @State.computed
    private get isNewEntryPlaceable() {
        return isNullOrUndefined(this.props.isNewEntryPlaceable)
            ? false
            : this.props.isNewEntryPlaceable;
    }

    @State.bound
    private setScheduleViewMode(viewMode: SchedulerViewMode) {
        this.scheduleViewMode = viewMode;
    }

    @State.bound
    private renderScheduler() {
        return (
            <>
                {!!this.props.events.length && <Ui.Scheduler
                    events={this.filteredEvents}
                    invalidEvents={this.filteredInvalidEvents}
                    onGetEntryClassName={this.props.onGetEntryClassName}
                    onGetNewEntryClassName={this.props.onGetNewEntryClassName}
                    onGetBlockClassName={this.getBlockClassName}
                    onGetInvalidClassName={this.props.onGetInvalidEntryClassName}
                    onEntryChange={this.setEvent}
                    blockingEvents={[]}
                    currentMoment={this.props.currentMoment}
                    timeSlotMinorCount={this.timeSlotCount}
                    workHoursStart={this.dayStart}
                    workHoursEnd={this.dayEnd}

                    isNewEntryPlaceable={this.isNewEntryPlaceable}
                    onPlaceNewEntry={this.props.onPlaceNewEntry}
                    onGetNewEntryOffset={this.props.onGetNewEntryOffset}

                    isEntryEditable={this.props.isEntryEditable}
                    onEditEntry={this.props.onEditEntry}

                    listView={this.props.listView}
                    entryComponent={this.renderEntry}
                    disableFreeSpace
                    showFreeSpaceValue={false}
                    hideMajorTime={false}
                    useExplicitToolbar={!this.props.hideCalendar}
                    useSelectBoxForScale={this.props.hideCalendar}
                    workDaysOfWeek={this.workDays}
                    noItemsMessage={StaticSchedulingResources.AppointmentSchedulePanel.CannotFindSlotsMessage}
                    viewMode={this.scheduleViewMode}
                    scale={this.schedulerScale}
                    onScheduleViewModeChange={this.setScheduleViewMode}
                    onSchedulerScaleChange={this.setSchedulerScale}
                    className={this.props.schedulerClassName}
                    onRenderAdditionToolbarComponent={this.props.onRenderAdditionToolbarComponent}
                    showNewEntryOn={this.showNewEntryOn}
                    newEntryDuration={this.props.newEventDuration}
                />}
                {this.props.events.length === 0 && this.props.renderSchedulerPlaceholder?.()}
            </>
        );
    }

    @State.bound
    private leftSideBarClassName() {
        return combineClasses(Style.leftSidebar, !!this.props.leftSidebarClassName && this.props.leftSidebarClassName);
    }

    @State.bound
    private renderBody() {
        return (
            <>
                {this.props.additionalBodyComponent}
                {this.renderScheduler()}
            </>);
    }

    public render() {

        if (this.props.hideCalendar) {
            return this.renderBody();
        }

        if (!!this.props.onRenderCalendar) {
            return (
                <>
                    {this.renderBody()}
                    {this.props.onRenderCalendar(this.renderStandaloneCalendar())}
                </>
            );
        }

        return (
            <Ui.SidebarLayout
                className={Style.layoutContainer}
                leftSidebarClassName={this.leftSideBarClassName()}
                leftSidebar={this.renderStandaloneCalendar()}
            >
                {this.renderBody()}
            </Ui.SidebarLayout>

        );
    }
}