import Di from "@Di";
import { ILoggingConfiguration } from "@HisPlatform/Services/Definition/Logger/ILogger";
import Log from "@HisPlatform/Services/Definition/Logger/Log";

export default class LoggingConfigurationApiAdapter {

    public async getLoggingConfigurationAsync(): Promise<ILoggingConfiguration | null> {
        const address = `${window.location.protocol}//${window.location.host}/api/logging`;

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        const response = await fetch(address, { method: "GET", headers: headers });

        if (response.ok) {
            const responseData = await response.json();
            return {
                isDebugLogEnabled: responseData.isDebugLogEnabled,
                isTraceLogEnabled: responseData.isTraceLogEnabled,
                isInfoLogEnabled: responseData.isInfoLogEnabled,
                isWarnLogEnabled: responseData.isWarnLogEnabled,
                isErrorLogEnabled: responseData.isErrorLogEnabled,
                isFatalLogEnabled: responseData.isFatalLogEnabled,
            };
        }

        Log.error("Cannot get logging configuration.");

        return null;
    }
}