import React from "react";
import * as Ui from "@CommonControls";
import Styles from "./MasterDetailView.less";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import { width } from "@CommonControls/Flex/FlexItem";

export interface IMasterDetailViewProps {
    isDetailOpen: boolean;
    list: React.ReactNode;
    detail: React.ReactNode;
    className?: string;
    masterSizeSmall?: width;
    masterSizeBig?: width;
    detailSize?: width;
    alignCenter?: boolean;

    noMarginCorrection?: boolean;
}


const MasterDetailView: React.SFC<IMasterDetailViewProps> = props => {
    return (
        <Ui.Flex
            innerSpacing="none"
            outerSpacing="none"
            verticalSpacing="none"
            className={combineClasses(Styles.outerDiv, props.className)}
            noWrap
            style={{ margin: props.alignCenter ? "0 auto" : undefined }}
        >
            <Ui.Flex.Item
                xs={props.isDetailOpen ? (props.masterSizeSmall ? props.masterSizeSmall : 4) : (props.masterSizeBig ? props.masterSizeBig : 12)}
                style={props.isDetailOpen && !props.noMarginCorrection ? { marginRight: -18 } : undefined}
            >
                {props.list}
            </Ui.Flex.Item>
            {props.isDetailOpen && <Ui.Flex.Item xs={props.detailSize ? props.detailSize : 8} className={Styles.innerSecondDiv}>
                {props.detail}
            </Ui.Flex.Item>}
        </Ui.Flex >
    );
};

export default MasterDetailView;