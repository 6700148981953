import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import ContagiousPatientReportType from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReportType";
import ContagiousPatientReportTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/ContagiousPatientReportTypeSelectBox/ContagiousPatientReportTypeSelectBox";

interface IContagiousPatientReportTypeColumnDependencies {
    localizationService: ILocalizationService;
}

interface IContagiousPatientReportTypeColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IContagiousPatientReportTypeColumnDependencies;
    displayMode?: "name";
}


const ContagiousPatientReportTypeColumn: React.FC<IContagiousPatientReportTypeColumnProps> = (props: IContagiousPatientReportTypeColumnProps) => {

    const valueRenderer = useCallback((value: ContagiousPatientReportType) => {

        switch (props.displayMode) {
            case "name":
                const localized = isNullOrUndefined(value) ? null : props._dependencies.localizationService.localizeEnum(ContagiousPatientReportType[value], "ContagiousPatientReportType");
                return isNullOrUndefined(localized) ? null : localized.Name;
        }
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<ContagiousPatientReportType | ContagiousPatientReportType[]>) => {
        return <ContagiousPatientReportTypeSelectBox {...filterProps} />;
    }, []);

    return (
        <DataGridColumn
            width={undefined}
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            showHint={false}
        />
    );
};

export default connect(
    ContagiousPatientReportTypeColumn,
    new DependencyAdapter<IContagiousPatientReportTypeColumnProps, IContagiousPatientReportTypeColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
