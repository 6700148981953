import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import { createAsyncErrorDispatcher, dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import DynamicValueConverter from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ValueConverters/DynamicValueConverter";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import _ from "@HisPlatform/Common/Lodash";
import NeakPerformanceStatementFilterStore from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/NeakPerformanceStatementWorklistPanel/Filters/NeakPerformanceStatementFilterStore";
import NeakPerformanceStatementWorklistPanelView from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/NeakPerformanceStatementWorklistPanel/NeakPerformanceStatementWorklistPanelView";
import HunWorklistApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/HunWorklistApiAdapter";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import StaticHunSocialSecurityPerformanceStatementResources from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/StaticResources/StaticHunSocialSecurityPerformanceStatementResources";
import TextualBusinessError from "@Toolkit/CommonWeb/Model/TextualBusinessError";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import InputDataClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/InputDataClientSideAction";
import NeakPerformanceStatementId from "@Primitives/NeakPerformanceStatementId.g";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import ModalServiceAdapter from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAdapter";
import NeakPerformanceStatementApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/NeakPerformanceStatementApiAdapter";
import Log from "@Log";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import ResourceId from "@Primitives/ResourceId.g";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import EuPerformanceStatementId from "@Primitives/EuPerformanceStatementId.g";
import EuPerformanceStatementApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/EuPerformanceStatementApiAdapter";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import HunSocialSecurityUseCases from "@HunSocialSecurityPlugin/UseCases/HunSocialSecurityUseCases";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import InputFormType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/InputFormType.g";
import PerformanceStatementCreateType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementCreateType.g";

interface INeakPerformanceStatementWorklistPanelDependencies {
    applicationContext: ApplicationContext;
    worklistApiAdapter: HunWorklistApiAdapter;
    useCaseRegistry: IUseCaseRegistry;
    dynamicValueConverter: DynamicValueConverter;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    dialogService: IDialogService;
    notificationService: INotificationService;
    neakPerformanceStatementApiAdapter: NeakPerformanceStatementApiAdapter;
    euPerformanceStatementApiAdapter: EuPerformanceStatementApiAdapter;
    toolkitLocalizationService: IToolkitLocalizationService;
}

interface INeakPerformanceStatementWorklistPanelProps extends IRoutingFrameContentProps {
    _dependencies?: INeakPerformanceStatementWorklistPanelDependencies;
    _modalService?: IModalService;
    selectedRowId: string;
    useCaseState: INDataUseCaseState;
    onChange: (rowId: string, useCaseState: INDataUseCaseState) => void;
    onNavigateToPerformanceStatementCreation: (performanceStatementCreateType: PerformanceStatementCreateType) => void;
}

@State.observer
class NeakPerformanceStatementWorklistPanel extends React.Component<INeakPerformanceStatementWorklistPanelProps> {

    private isFileOpenDialogDisabledOnce = false; // little help for UI tests
    private runAsync = createAsyncErrorDispatcher(this);
    @State.observable.ref private worklistDefinition: IWorklistDefinition = null;
    @State.observable.ref private filterStore: NeakPerformanceStatementFilterStore = null;

    private readonly currentUseCaseIdentifier = new UseCaseIdentifier("PerformanceStatement_NeakPerformanceStatement");

    private selectedPerformanceStatementId: NeakPerformanceStatementId | EuPerformanceStatementId = null;

    private get modalService() {
        return this.props._modalService;
    }

    private get notificationService() {
        return this.props._dependencies.notificationService;
    }

    private get localizator() {
        return this.props._dependencies.toolkitLocalizationService.getValidationResultSummaryLocalizator();
    }

    private readonly inputOpenFileRef = React.createRef<HTMLInputElement>();
    private readonly refreshListEvent = new TypedAsyncEvent();

    public componentDidMount() {
        (window as any).disableFileOpenDialogOnce = () => this.isFileOpenDialogDisabledOnce = true;

        this.props._dependencies.applicationContext.setCurrentUseCase(this.currentUseCaseIdentifier);
        this.runAsync(this.initializeAsync());
    }

    public componentWillUnmount() {
        delete (window as any).disableFileOpenDialogOnce;

        if (this.filterStore) {
            this.filterStore.clearAllFilters();
        }
        this.props._dependencies.applicationContext.setCurrentUseCase(null);
    }

    private readonly initializeAsync = createInitialPanelLoader(this._initializeAsync);

    @State.bound
    private async _initializeAsync() {
        const [worklistDefinition] = await Promise.all(
            [
                this.props._dependencies.worklistApiAdapter.getNeakPerformanceStatementBoundWorklistDefinitionAsync()
            ]);
        State.runInAction(() => {
            this.worklistDefinition = worklistDefinition.value;
        });
    }

    @State.action.bound
    private onFilterStoreCreatedAsync(filterStore: IFilterStore) {
        this.filterStore = new NeakPerformanceStatementFilterStore(filterStore);
        return Promise.resolve();
    }

    @State.bound
    private handleBusinessError(err: TextualBusinessError) {
        if (err.message && err.message === "AlreadyExistsFinalizedPerformanceStatement") {
            this.props._dependencies.dialogService.ok(
                StaticHunSocialSecurityPerformanceStatementResources.NeakPerformanceStatementWorkListPanel.ErrorMessages.AlreadyExistsFinalizedPerformanceStatementTitle,
                StaticHunSocialSecurityPerformanceStatementResources.NeakPerformanceStatementWorkListPanel.ErrorMessages.AlreadyExistsFinalizedPerformanceStatementMessage
            );
        }

        return true;
    }

    @State.bound
    public showOpenFileDialog(): void {
        if (!this.isFileOpenDialogDisabledOnce) {
            this.inputOpenFileRef.current.click();
        }
        this.isFileOpenDialogDisabledOnce = false;
    }

    @State.bound
    private uploadErrorListSync(event: React.ChangeEvent<HTMLInputElement>) {
        dispatchAsyncErrors(this.uploadErrorListAsync(event), this);
    }

    @State.bound
    private async uploadErrorListAsync(event: React.ChangeEvent<HTMLInputElement>) {
        event.persist();

        const file = event.target.files[0];
        // eslint-disable-next-line no-useless-escape
        const extension = file.name.match(/\.([^\.]+)$/)[1].toLowerCase();

        try {
            if (extension === "rep") {
                const bytes = await file.toUint8ArrayAsync();

                if (this.selectedPerformanceStatementId instanceof (NeakPerformanceStatementId)) {
                    const neakPerformanceStatement = await this.props._dependencies.neakPerformanceStatementApiAdapter.getNeakPerformanceStatementByIdAsync(this.selectedPerformanceStatementId);
                    const response = await this.props._dependencies.neakPerformanceStatementApiAdapter.setPerformanceStatementErrorListAsync(
                        neakPerformanceStatement,
                        file.name,
                        bytes
                    );

                    if (response.isValid) {
                        const neakErrorList = await this.props._dependencies.neakPerformanceStatementApiAdapter.getNeakErrorListById(this.selectedPerformanceStatementId);
                        
                        if (!neakErrorList.hasProcessError) {
                            this.notificationService.success(StaticHunSocialSecurityPerformanceStatementResources.NeakPerformanceStatementWorkListPanel.SuccessfulErrorListUpload);
                        } else {
                            this.notificationService.error(StaticHunSocialSecurityPerformanceStatementResources.NeakPerformanceStatementWorkListPanel.ProcessErrorInErrorList);
                        }
                    } else {
                        const problems = _.flatten(response.validationResults.map(i => i.problems));
                        this.notificationService.error(this.getMessage(problems));
                    }
                }

                if (this.selectedPerformanceStatementId instanceof (EuPerformanceStatementId)) {
                    const euPerformanceStatement = await this.props._dependencies.euPerformanceStatementApiAdapter.getEuPerformanceStatementByIdAsync(this.selectedPerformanceStatementId);
                    const response = await this.props._dependencies.euPerformanceStatementApiAdapter.setEuPerformanceStatementErrorListAsync(
                        euPerformanceStatement,
                        file.name,
                        bytes
                    );

                    if (response.isValid) {
                        const euErrorList = await this.props._dependencies.euPerformanceStatementApiAdapter.getEuErrorListById(this.selectedPerformanceStatementId);
                        
                        if (!euErrorList.hasProcessError) {
                            this.notificationService.success(StaticHunSocialSecurityPerformanceStatementResources.NeakPerformanceStatementWorkListPanel.SuccessfulErrorListUpload);
                        } else {
                            this.notificationService.error(StaticHunSocialSecurityPerformanceStatementResources.NeakPerformanceStatementWorkListPanel.ProcessErrorInErrorList);
                        }
                    } else {
                        const problems = _.flatten(response.validationResults.map(i => i.problems));
                        this.notificationService.error(this.getMessage(problems));
                    }
                }
            } else {
                await this.props._dependencies.dialogService.ok(
                    StaticHunSocialSecurityPerformanceStatementResources.NeakPerformanceStatementWorkListPanel.ErrorMessages.FileFormatErrorMessageTitle,
                    StaticHunSocialSecurityPerformanceStatementResources.NeakPerformanceStatementWorkListPanel.ErrorMessages.FileFormatErrorMessage);
            }
        } catch (error) {
            Log.error(error.message);
            this.notificationService.error(StaticHunSocialSecurityPerformanceStatementResources.NeakPerformanceStatementWorkListPanel.ErrorMessages.FileUploadErrorMessage);
        } finally {
            this.inputOpenFileRef.current.value = null;
            this.selectedPerformanceStatementId = null;
            await this.refreshListEvent.emitAsync();
        }
    }

    @State.bound
    private getMessage(problems: IClientValidationProblem[]): string {
        const messages = problems.map(i => {
            const validationResultId = new ResourceId(i.message);
            const localizedMessage = i.rawMessage ?? this.localizator.getValidationMessage(validationResultId);
            const message = localizedMessage === "(missing)" && !!i.rawMessage ? i.message : localizedMessage;
            const parameters = i.resolvedParameters || i.parameters;

            return formatStringWithObjectParams(message, parameters);
        });

        return messages.join(", ");
    }

    @State.bound
    private performClientSideActionAsync(action: ClientSideActionDto): Promise<WorkListArgument | boolean> {
        this.selectedPerformanceStatementId = null;

        if (action instanceof InputDataClientSideAction) {
            if (action.inputFormType === InputFormType.ReadPerformanceStatementErrorList || action.inputFormType === InputFormType.ReadEuPerformanceStatementErrorList) {
                if (action.inputFormType === InputFormType.ReadPerformanceStatementErrorList) {
                    this.selectedPerformanceStatementId = new NeakPerformanceStatementId(action.useCaseArguments[0].value.value);
                }
                if (action.inputFormType === InputFormType.ReadEuPerformanceStatementErrorList) {
                    this.selectedPerformanceStatementId = new EuPerformanceStatementId(action.useCaseArguments[0].value.value);
                }
                
                this.showOpenFileDialog();
            }
        }
        
        return Promise.resolve(null);
    }

    @State.bound
    private getPanelProps(useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) {
        if (useCaseIdentifier.value === HunSocialSecurityUseCases.selectPerformanceStatementForCreation) {
            return {
                onNavigateToPerformanceStatementCreation: this.props.onNavigateToPerformanceStatementCreation
            };
        }
        return null;
    }

    public render() {

        if (this.initializeAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title="" />;
        }

        return (
            <>
                <input type="file" onChange={this.uploadErrorListSync} ref={this.inputOpenFileRef} style={{ display: "none" }} accept=".rep" />
                <BusinessErrorHandler.Register businessErrorName="TextualBusinessError" handler={this.handleBusinessError} />
                <NeakPerformanceStatementWorklistPanelView
                    worklistDefinition={this.worklistDefinition}
                    filterStore={this.filterStore && this.filterStore}
                    onFilterStoreCreatedAsync={this.onFilterStoreCreatedAsync}
                    selectedRowId={this.props.selectedRowId}
                    useCaseState={this.props.useCaseState}
                    onChange={this.props.onChange}
                    onPerformClientSideActionAsync={this.performClientSideActionAsync}
                    refreshListEvent={this.refreshListEvent}
                    onGetPanelProps={this.getPanelProps}
                />
            </>
        );
    }
}

export default connect(
    withHisErrorBoundary(NeakPerformanceStatementWorklistPanel),
    new DependencyAdapter<INeakPerformanceStatementWorklistPanelProps, INeakPerformanceStatementWorklistPanelDependencies>(c => {
        return {
            applicationContext: c.resolve("ApplicationContext"),
            worklistApiAdapter: c.resolve("HunWorklistApiAdapter"),
            useCaseRegistry: c.resolve("IUseCaseRegistry"),
            dynamicValueConverter: c.resolve("DynamicValueConverter"),
            organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
            dialogService: c.resolve("IDialogService"),
            notificationService: c.resolve("INotificationService"),
            neakPerformanceStatementApiAdapter: c.resolve("NeakPerformanceStatementApiAdapter"),
            euPerformanceStatementApiAdapter: c.resolve("EuPerformanceStatementApiAdapter"),
            toolkitLocalizationService: c.resolve("IToolkitLocalizationService")
        };
    }),
    new ModalServiceAdapter()
);
