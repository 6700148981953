import MedicationReferenceBase from "./MedicationReferenceBase";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import MedicationEquipmentListItem from "./MedicationEquipmentListItem";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import MedicationEquipmentPrescriptionType from "./MedicationEquipmentPrescriptionType";

export default class MedicationEquipmentList extends MedicationReferenceBase {
    
    @State.observable public countersignMandatory: boolean = false;
    @State.observable public countersignApprovalNumber: string = "";
    @State.observable.ref public countersignValidFrom: LocalDate = null;
    @State.observable public stateChangeDescription: string = "";
    @State.observable public sizeChangeDescription: string = "";
    @State.observable public patientStatementAvailable: boolean = false;
    @State.observable public represcribeWithinSupportPeriod: boolean = false;
    @State.observable public prescribeToIsoGroup: boolean = false;
    @State.observable public type: MedicationEquipmentPrescriptionType = MedicationEquipmentPrescriptionType.Prescription;
    @State.observable public items: IObservableArray<MedicationEquipmentListItem> = State.observable([]);

    @State.action.bound
    public setCountersignMandatory(countersignMandatory: boolean) {
        this.countersignMandatory = countersignMandatory;
        if (countersignMandatory === true) {
            for (const item of this.items) {
                item.setIsInsubstitutable(true);
            }
        }
    }

    @State.action.bound
    public setCountersignApprovalNumber(countersignApprovalNumber: string) {
        this.countersignApprovalNumber = countersignApprovalNumber;
    }

    @State.action.bound
    public setCountersignValidFrom(countersignValidFrom: LocalDate) {
        this.countersignValidFrom = countersignValidFrom;
    }

    @State.action.bound
    public setStateChangeDescription(stateChangeDescription: string) {
        this.stateChangeDescription = stateChangeDescription;
    }

    @State.action.bound
    public setSizeChangeDescription(sizeChangeDescription: string) {
        this.sizeChangeDescription = sizeChangeDescription;
    }

    @State.action.bound
    public setPatientStatementAvailable(patientStatementAvailable: boolean) {
        this.patientStatementAvailable = patientStatementAvailable;
    }

    @State.action.bound
    public setReprescribeWithinSupportPeriod(represcribeWithinSupportPeriod: boolean) {
        this.represcribeWithinSupportPeriod = represcribeWithinSupportPeriod;
    }

    @State.action.bound
    public setPrescribeToIsoGroup(prescribeToIsoGroup: boolean) {
        this.prescribeToIsoGroup = prescribeToIsoGroup;
    }

    @State.action.bound
    public setType(type: MedicationEquipmentPrescriptionType) {
        this.type = type;
        if (type !== MedicationEquipmentPrescriptionType.Prescription) {
            this.setReprescribeWithinSupportPeriod(false);
            this.setSizeChangeDescription("");
            this.setStateChangeDescription("");
        }
    }
}