import IMapperService from "@HisPlatform/Services/Definition/MapperService/IMapperService";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import IClientSideActionDto from "./IClientSideActionDto";

export default abstract class ClientSideActionDto implements IClientSideActionDto {

    private static mapperServiceLocator: () => IMapperService;

    public static setMapperServiceLocator(mapperServiceLocator: () => IMapperService & IMapperConfigurator) {
        ClientSideActionDto.mapperServiceLocator = mapperServiceLocator;
    }

    public static fromJS(data: any): ClientSideActionDto {
        const mapperService = ClientSideActionDto.mapperServiceLocator();
        const nameMappingResult = mapperService.tryMapByName<any, ClientSideActionDto>(data._discriminator, data);
        let mapped: ClientSideActionDto;

        if (nameMappingResult.mapperFound) {
            mapped = nameMappingResult.result;
        } else {
            mapped = mapperService.mapByName<any, ClientSideActionDto>("UnknownClientSideAction", data);
        }

        return mapped;
    }

    public readonly extensionData: any | null;

    // Only for the generated proxy:

    constructor(_?: any) {
        return;
    }

    public toJSON(data?: any): object {
        return data;
    }

    public init(_?: any) {
        return;
    }
}