import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Authorization/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import Di from "@Di";
import EntityPermissionStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/DataAccessControl/EntityPermissionStore";
import { getSetEntityPermissionsDto } from "./EntityPermissionsDtoMapper";
import EntityPermissionStoreMapper from "./EntityPermissionStoreMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";

@Di.injectable()
export default class EntityPermissionsApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IDataAccessControlClient") private dataAccessClient: Proxy.IDataAccessControlClient,
        @Di.inject("EntityPermissionStoreMapper") private entityPermissionStoreMapper: EntityPermissionStoreMapper
    ) {
        super();
    }

    @State.bound
    public getEntityPermissionsAsync(entityType: string, entityId: string): Promise<EntityPermissionStore> {
        return this.processOperationAsync(
            new EntityPermissionStore(),
            async target => {
                const response = await this.dataAccessClient.getEntityPermissionQueryAsync(CreateRequestId(), entityId, entityType);
                this.entityPermissionStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public setEntityPermissionsAsync(permissionStore: EntityPermissionStore, entityType: string, entityId: string, isPermissionCheckOnly: boolean = false) {
        return this.processOperationAsync(
            new EntityPermissionStore(),
            async target => {
                const dto = getSetEntityPermissionsDto(permissionStore, entityType, entityId);
                
                const response = await this.dataAccessClient.setEntityPermissionCommandAsync(
                    CreateRequestId(),
                    dto,
                    isPermissionCheckOnly
                );
                if (!isPermissionCheckOnly) {
                    this.entityPermissionStoreMapper.applyToStore(target, response);
                } else {
                    target.operationInfo = createOperationInfo(response);
                }
            }
        );
    }

    @State.bound
    public clearEntityPermissionsAsync(permissionStore: EntityPermissionStore, entityType: string, entityId: string) {
        return this.processOperationAsync(
            new EntityPermissionStore(),
            async target => {

                const emptyPermissionStore = new EntityPermissionStore();
                emptyPermissionStore.rowVersion = permissionStore.rowVersion;

                const dto = getSetEntityPermissionsDto(emptyPermissionStore, entityType, entityId);
                const response = await this.dataAccessClient.setEntityPermissionCommandAsync(
                    CreateRequestId(),
                    dto
                );
                this.entityPermissionStoreMapper.applyToStore(target, response);
            }
        );
    }
}