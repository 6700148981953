import "./ApiClientConfiguration";
import "./StoreConfiguration";
import "./ApiAdapterConfiguration";

import DependencyContainer from "@DiContainer";
import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import SurgeryBoundedContext from "@HisPlatform/BoundedContexts/Surgery/SurgeryBoundedContext";
import SurgeryReferenceDataStore from "@HisPlatform/BoundedContexts/Surgery/ApplicationLogic/Model/SurgeryReferenceDataStore";

DependencyContainer
    .bind("IBoundedContext")
    .to(SurgeryBoundedContext);

DependencyContainer
    .bind("SurgeryReferenceDataStore")
    .to(SurgeryReferenceDataStore)
    .inSingletonScope();    