import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import DocumentSnippetDialogStoreMapper from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Dialog/DocumentSnippetDialogStoreMapper";
import DocumentSnippetId from "@Primitives/DocumentSnippetId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import IDocumentSnippet from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentSnippet/IDocumentSnippet";

@Di.injectable()
export default class DocumentSnippetSelectorDialogApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IDocumentsClient") private readonly apiClient: Proxy.IDocumentsClient,
        @Di.inject("DocumentSnippetDialogStoreMapper") private readonly documentSnippetStoreMapper: DocumentSnippetDialogStoreMapper
    ) {
        super();
    }

    public getDocumentSnippetsByDocumentSnippetCategoryIdAsync(documentSnippetCategoryId: TextBlockTypeId) {
        return this.processOperationAsync(
            new SimpleStore<IDocumentSnippet[]>(),
            async target => {
                const response = await this.apiClient.getDocumentSnippetsByDocumentSnippetCategoryIdQueryAsync(CreateRequestId(), documentSnippetCategoryId.value);
                this.documentSnippetStoreMapper.applyToStore(target, response);
            }
        );
    }

    public getDocumentSnippetContentByIdAsync(documentSnippetId: DocumentSnippetId) {
        return this.processOperationAsync(
            new SimpleStore<string>(),
            async target => {
                const response = await this.apiClient.getDocumentSnippetContentByIdQueryAsync(CreateRequestId(), documentSnippetId.value);

                target.operationInfo = createOperationInfo(response);
                target.value = response.content;
            }
        );
    }
}