import React from "react";
import ReactPortal from "@Toolkit/ReactClient/Components/Portal/Portal";


const HisPanelButtonPortal = ReactPortal.createNamedPortal("hisPanelButton");

HisPanelButtonPortal.defaultProps = {
    onContentChanged: (children: React.ReactNode, hostElement: HTMLElement) => {
        if (React.Children.count(children) === 0) {
            hostElement.parentElement.style.display = "none";
        } else {
            hostElement.parentElement.style.display = "flex";
        }
    }
};

export default HisPanelButtonPortal;