import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import RiskAssessmentStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/RiskAssessmentStore";
import RiskAssessmentId from "@Primitives/RiskAssessmentId.g";
import ReferencedLifeStyleRiskAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/ReferencedLifeStyleRiskAssignment";
import CustomRiskAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/CustomRiskAssignment";
import RiskAssessmentHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/RiskAssessmentHistoryItem";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import RiskDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/RiskDetail";

type ResponseType = Proxy.GetRiskAssessmentByIdCommandResponse | Proxy.AddRiskAssessmentCommandResponse | Proxy.UpdateRiskAssessmentCommandResponse | Proxy.DeleteRiskAssessmentHistoryItemCommandResponse;

@Di.injectable()
export default class RiskAssessmentStoreMapper extends LockingEntityStoreMapper<Proxy.RiskAssessmentDto, RiskAssessmentStore, ResponseType> {

    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    protected storeEntityIdType = RiskAssessmentId;

    protected entityDtoSelector = (response: ResponseType) => {
        return response.riskAssessmentDto;
    };

    protected applyToStoreCore(target: RiskAssessmentStore, response: any) {
        const dto = this.entityDtoSelector(response);
        if (dto != null) {
            target.id = dto.id;
            target.riskAssignment = this.getRiskAssignment(dto);

            target.historyItems = State.observable(this.getHistoryItems(dto.historyItems));
            target.currentItemIndex = target.historyItems.length - 1;
            target.extensionData = dto.extensionData;
        }
    }

    private getHistoryItems(dtoArray: Proxy.RiskAssessmentHistoryItemDto[]): RiskAssessmentHistoryItem[] {
        return dtoArray.map(dto => this.getHistoryItem(dto)).sort((a, b) => a.versionNumber - b.versionNumber);
    }

    private getHistoryItem(dto: Proxy.RiskAssessmentHistoryItemDto): RiskAssessmentHistoryItem {
        const returnItem = new RiskAssessmentHistoryItem();

        returnItem.historyItemDetail = this.getHistoryItemDetail(dto.historyItemDetail);
        returnItem.riskDetail = this.getRiskDetail(dto);
        returnItem.versionNumber = dto.versionNumber;
        returnItem.index = dto.index;
        returnItem.createdAt = dto.createdAt;
        returnItem.extensionData = dto.extensionData;

        return returnItem;
    }

    private getHistoryItemDetail(dto: Proxy.MedicalConditionHistoryItemDetailDto): MedicalConditionHistoryItemDetail {
        const returnItem = new MedicalConditionHistoryItemDetail();

        returnItem.asserter = dto.asserter;
        returnItem.asserterType = dto.asserterType;
        returnItem.notes = dto.notes;
        returnItem.verifier = dto.verifier;

        return returnItem;
    }

    private getRiskAssignment(dto: Proxy.RiskAssessmentDto) {
        let riskAssignment;

        if (dto.riskAssignment instanceof Proxy.ReferencedLifestyleRiskAssignmentDto) {
            riskAssignment = new ReferencedLifeStyleRiskAssignment(dto.riskAssignment.lifeStyleRiskId);
        } else if (dto.riskAssignment instanceof Proxy.CustomRiskAssignmentDto) {
            riskAssignment = new CustomRiskAssignment(dto.riskAssignment.name);
        }

        return riskAssignment;
    }

    private getRiskDetail(dto: Proxy.RiskAssessmentHistoryItemDto) {
        return new RiskDetail(dto.riskDetail.description, dto.riskDetail.periodDescription);
    }
}
