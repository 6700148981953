import Di from "@Di";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import NavMenuGroupItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuGroupItem";
import _ from "@HisPlatform/Common/Lodash";

@Di.injectable()
export default class DynamicNavigationRegistry implements IDynamicNavigationRegistry {

    @State.observable
    private readonly navMenuItems = State.createObservableShallowArray<INavMenuItem>([]);

    @State.action
    public register(navMenuNode: INavMenuItem) {
        if (!this.navMenuItems.find(i => i.identifier === navMenuNode.identifier)) {
            this.navMenuItems.push(navMenuNode);
        }
    }

    @State.action
    public remove(identifier: string) {
        const toRemove = this.navMenuItems.find(i => i.identifier === identifier);

        if (toRemove) {
            this.navMenuItems.remove(toRemove);
        }
    }

    @State.computed
    public get tree(): INavMenuItem[] {
        return this.buildTree();
    }

    private buildTree() {
        const lookupTree = _.cloneDeep(this.navMenuItems);

        for (const node of lookupTree) {
            const parent = lookupTree.find(i => i.identifier === node.parentIdentifier);
            if (!!parent && parent instanceof NavMenuGroupItem) {
                parent.children.push(node);
            }
        }

        return lookupTree.filter(i => i.parentIdentifier == null);
    }
}