import React from "react";
import { Modal } from "@CommonControls";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import { ContextAwareModal } from "@HisPlatformControls";

interface IUnauthorizedAccessModalProps {
    title: string;
}

export default function UnauthorizedAccessModal(props: React.PropsWithChildren<IUnauthorizedAccessModalProps>) {

    return (
        <ContextAwareModal
            isOpen
            title={props.title}>
            <Modal.Body>
                <UnauthorizedAccessContent />
            </Modal.Body>
        </ContextAwareModal>
    );
}