import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import ProcedureAssignmentBase from "./ProcedureAssignmentBase";

export default class ReferencedMedicalServiceAssignment extends ProcedureAssignmentBase  {
    @State.observable.ref public medicalServiceId: MedicalServiceId = null;

    constructor(medicalServiceId: MedicalServiceId) {
        super();
        this.medicalServiceId = medicalServiceId;
    }
}