import DependencyContainer from "@DiContainer";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/EesztIntegration/Api/Proxy.g";
import EesztIntegrationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/EesztIntegration/ApplicationLogic/ApiAdapter/EesztIntegrationApiAdapter";
import { GetTypeNameWithPrefix } from "@HunEHealthInfrastructurePlugin/PluginHelper";

DependencyContainer
    .bind("EesztIntegrationApiAdapter")
    .to(EesztIntegrationApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind(GetTypeNameWithPrefix("ICommunicationLogsClient"))
    .to(Proxy.CommunicationLogsClient)
    .inSingletonScope();
