import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import CareActivityId from "@Primitives/CareActivityId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ICareActivityStore from "./ICareActivityStore";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientId from "@Primitives/PatientId.g";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";

export default class CareActivityStore extends EntityStoreBase<CareActivityId> implements ICareActivityStore {
    @State.observable.ref public id: CareActivityId = null;
    @State.observable.ref public patientId: PatientId = null;
    @State.observable.ref public pointOfCareId: PointOfCareId = null;
    @State.observable.ref public state: CareActivityState = null;
    @State.observable.ref public finalDocumentTypeId: DocumentTypeId = null;

    @State.action.bound
    public setCareActivityId(newValue: CareActivityId) {
        this.id = newValue;
    }

    @State.action.bound
    public setPatientId(newValue: PatientId) {
        this.patientId = newValue;
    }

    @State.action.bound
    public setPointOfCareId(newValue: PointOfCareId) {
        this.pointOfCareId = newValue;
    }

    @State.action.bound
    public setCareActivityState(newValue: CareActivityState) {
        this.state = newValue;
    }

    @State.action.bound
    public setFinalDocumentType(newValue: DocumentTypeId) {
        this.finalDocumentTypeId = newValue;
    }
}