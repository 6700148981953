import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import PreferredMedicationNameColumnData from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/PreferredMedicationNameColumnData";


interface IPreferredMedicationNameColumnProps extends IDataGridColumnBaseProps {
}


const PreferredMedicationNameColumn: React.FC<IPreferredMedicationNameColumnProps> = (props: IPreferredMedicationNameColumnProps) => {
    const valueRenderer = useCallback((value: PreferredMedicationNameColumnData) => {
        return (
            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ flexBasis: "auto", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                    {value.displayName}
                </div>
            </div>
        );
    }, []);

    const hintRenderer = useCallback((value: PreferredMedicationNameColumnData) => {
        return value.displayName;
    }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderHintValue={hintRenderer}
        />
    );
};

export default PreferredMedicationNameColumn;
