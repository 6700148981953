import { createNestedRoutes } from "@Toolkit/ReactClient/Routing/CreateNestedRoutes";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import IOutpatientTreatmentRouteParams from "@HisPlatform/Application/Routes/IOutpatientTreatmentRouteParams";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";

export interface IFamilyDoctorDocumentStandaloneRouteParams extends IOutpatientTreatmentRouteParams {
    useCase?: string;
}

const standaloneRouteDefinitions = createNestedRoutes(ApplicationRoutes.careActivity, {
    familyDoctorDocument: new RouteDefinition<IFamilyDoctorDocumentStandaloneRouteParams>("/familyDoctorDocument/:useCase?"),
});

export default standaloneRouteDefinitions;