import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import PerformanceStatementCareActivityStatusReason from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementCareActivityStatusReason.g";

interface IPerformanceStatementCareActivityStatusReasonColumnDependencies {
    localizationService: ILocalizationService;
}

interface IPerformanceStatementCareActivityStatusReasonColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IPerformanceStatementCareActivityStatusReasonColumnDependencies;
}


const PerformanceStatementCareActivityStatusReasonColumn: React.FC<IPerformanceStatementCareActivityStatusReasonColumnProps> = (props: IPerformanceStatementCareActivityStatusReasonColumnProps) => {

    const valueRenderer = useCallback((value: PerformanceStatementCareActivityStatusReason) => {
        return isNullOrUndefined(value) ? null :
            props._dependencies.localizationService.localizeEnum(PerformanceStatementCareActivityStatusReason[value], "PerformanceStatementCareActivityStatusReason").Name;
    }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
        />
    );
};

export default connect(
    PerformanceStatementCareActivityStatusReasonColumn,
    new DependencyAdapter<IPerformanceStatementCareActivityStatusReasonColumnProps, IPerformanceStatementCareActivityStatusReasonColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);