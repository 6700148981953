import React, { useContext } from "react";
import { iconNameType } from "@CommonControls/Icon";
import PageBox2 from "@CommonControls/PageBox2/PageBox2";
import IMasterDetailState from "@CommonControls/Layout/IMasterDetailState";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MasterDetailDetailTab, { IMasterDetailDetailTabProps } from "@CommonControls/Layout/MasterDetailDetailTab";
import Styles from "./Layout.less";
import Button from "@CommonControls/Button";
import MasterDetailStateContext from "@CommonControls/Layout/MasterDetailStateContext";
import { MasterDetailContext } from "@CommonControls/Layout/MasterDetailContext";

export interface IMasterDetailDetailProps {
    _masterDetailState?: IMasterDetailState;
    _masterChildren?: React.ReactNode;
    _compactListSize?: string | number;

    iconName?: iconNameType;
    title?: string;
    subTitle?: React.ReactNode;
    toolbar?: React.ReactNode;
    hasSidePadding?: boolean;
    hasVerticalPadding?: boolean;
    hasFooter?: boolean;
    footerLeftToolbar?: React.ReactNode;
    footerRightToolbar?: React.ReactNode;
    initiallyActiveTabName?: string;
    passStateToChild?: boolean;

    onClose?: () => void;
}

class MasterDetailDetailCore extends React.PureComponent<IMasterDetailDetailProps> {

    public static defaultProps: Partial<IMasterDetailDetailProps> = {
        passStateToChild: true
    };

    public render() {
        const { hasTabs, activeTabName, onActiveTabNameChange } = this.props._masterDetailState!;
        return (
            <PageBox2
                fullHeight
                hasTabs={hasTabs}
                activeTabName={activeTabName}
                onActiveTabChange={onActiveTabNameChange}
                initiallyActiveTabName={this.props.initiallyActiveTabName}
            >
                {hasTabs ? this.renderTabs() : this.renderSingle()}
            </PageBox2>
        );
    }

    private renderTabs() {
        return React.Children.map(this.props.children, this.renderTab);
    }

    @State.bound
    private renderTab(child: React.ReactElement) {
        if (child?.type !== MasterDetailDetailTab) {
            throw new Error("MasterDetail's detail should contain only MasterDetail.Tab components. (Tab detail mode)");
        }

        const tabProps = (child as React.ReactElement<IMasterDetailDetailTabProps>).props;

        const sidebar = this.props._masterDetailState!.isCompact && (
            <>
                {this.props._masterChildren}
                {this.props._masterDetailState!.showCompactModeSwitcher && (
                    <Button automationId="expandButton" iconName="expand" className={Styles.masterDetailExpandButton} size="compact" onClick={this.props._masterDetailState!.onExpand} />
                )}
            </>
        );

        return (
            <PageBox2.Tab
                tabName={tabProps.tabName}
                tabTitle={tabProps.tabTitle}
                disabled={tabProps.disabled}
                disablingTooltip={tabProps.disablingTooltip}
                propertyPathRegexPattern={tabProps.propertyPathRegexPattern}
                automationId={tabProps.automationId}
                iconName={tabProps.tabPaneIconName}
            >
                {!this.props._masterDetailState!.hideDetailHeader && <PageBox2.Header
                    title={tabProps.title}
                    iconName={tabProps.iconName}
                    subTitle={tabProps.subTitle}
                    toolbar={tabProps.toolbar ?? this.props.toolbar}
                    hasDetailCloseButton={this.props._masterDetailState!.isMasterVisible}
                    onCloseDetail={this.close}
                />}
                <PageBox2.Body
                    hasSidePadding={this.props.hasSidePadding}
                    hasVerticalPadding={this.props.hasVerticalPadding}
                    splitContentSize={this.props._compactListSize}
                    splitContent={sidebar}
                >
                    {this.renderChildNodes(tabProps.children)}
                </PageBox2.Body>
                {this.props.hasFooter && (
                    <PageBox2.Footer
                        leftToolbar={tabProps.footerLeftToolbar || this.props.footerLeftToolbar}
                        rightToolbar={tabProps.footerRightToolbar || this.props.footerRightToolbar}
                    />
                )}
            </PageBox2.Tab>
        );
    }

    private renderSingle() {
        return (
            <>
                {!this.props._masterDetailState!.hideDetailHeader && <PageBox2.Header
                    title={this.props.title}
                    iconName={this.props.iconName}
                    subTitle={this.props.subTitle}
                    toolbar={this.props.toolbar}
                    hasDetailCloseButton={this.props._masterDetailState!.showDetailCloseButton}
                    onCloseDetail={this.close}
                    automationId="detail"
                />}
                <PageBox2.Body
                    hasSidePadding={this.props.hasSidePadding}
                    hasVerticalPadding={this.props.hasVerticalPadding}
                >
                    <MasterDetailStateContext.Provider value={this.props._masterDetailState}>
                        {this.renderChildNodes(this.props.children)}
                    </MasterDetailStateContext.Provider>
                </PageBox2.Body>
                {this.props.hasFooter && (
                    <PageBox2.Footer
                        leftToolbar={this.props.footerLeftToolbar}
                        rightToolbar={this.props.footerRightToolbar} />
                )}
            </>
        );
    }

    private renderChildNodes(nodes: React.ReactNode) {
        if (this.props.passStateToChild) {
            return React.cloneElement(React.Children.only(nodes) as React.ReactElement, {
                _masterDetailState: this.props._masterDetailState
            });
        }

        return nodes;
    }

    @State.bound
    private close() {
        if (this.props.onClose) {
            this.props.onClose();
        } else {
            this.props._masterDetailState!.onSelectedItemChange?.(null);
        }
    }
}


const MasterDetailDetail: React.FC<IMasterDetailDetailProps> = props => {

    const ctx = useContext(MasterDetailContext);

    if (ctx == null) {
        return null;
    }

    return <MasterDetailDetailCore {...props} _compactListSize={ctx.compactListSize} _masterChildren={ctx.detailChildren} _masterDetailState={ctx.state} />;
};

export default MasterDetailDetail;