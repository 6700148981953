import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import IPrescriptionListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IPrescriptionListItem";
import Prescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Prescription";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import DataGridDateColumn from "@CommonControls/DataGrid/Column/DataGridDateColumn";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import Dosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Dosage";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import PrescriptionFilterStore from "./PrescriptionFilterStore";
import { DataGridLoadType, IOrderingState, IPagingState, IRowIndicatorStyle } from "@CommonControls/DataGrid/IDataGridProps";
import * as Styles from "./PrescriptionList.less";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import PrescriptionSearchBase from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionSearchBase";
import IPrescriptionProduct from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IPrescriptionProduct";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import IPrescriptionStatusColumnData from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IPrescriptionStatusColumnData";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";
import PrescriptionDataSource from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionDataSource";
import OrganizationSelector from "@HisPlatform/BoundedContexts/Organization/Components/OrganizationSelector";
import EhiPrescriptionSearchMode from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/EhiPrescriptionSearchMode";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PrescriptionDataSourceSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionDataSourceSelector/PrescriptionDataSourceSelector";
import EhiPrescriptionSearchModeSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/EhiPrescriptionSearchModeSelector/EhiPrescriptionSearchModeSelector";
import PrescriptionStatusSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionStatusSelector/PrescriptionStatusSelector";
import EhiPrescriptionStatusSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/EhiPrescriptionStatusSelector/EhiPrescriptionStatusSelector";
import MedicationUpdateDateWarning from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationUpdateDateWarning/MedicationUpdateDateWarning";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import DataGridExtensibleEnumColumn from "@CommonControls/DataGrid/Column/DataGridExtensibleEnumColumn";
import EhiQueryButtonWithEmergencyCheckBox from "@HunEHealthInfrastructurePlugin/Common/Components/EhiQueryButtonWithEmergencyCheckBox";
import PrescriptionId from "@Primitives/PrescriptionId.g";
import { DoctorCodeSelector } from "@HisPlatform/Components/HisPlatformControls";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import EhiServiceCallStatus from "@HunEHealthInfrastructurePlugin/Model/EhiServiceCallStatus";
import ClientSideValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidationBoundary";
import EhiDigitalPrescriptionListFilterValidator from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/EhiDigitalPrescriptionMasterDetailPanel/EhiDigitalPrescriptionListFilterValidator";
import IDosageLocalizationService from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/IDosageLocalizationService";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";
import PrescriptionStatus from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/PrescriptionStatus.g";
import PrescriptionItemType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/PrescriptionItemType.g";
import HealthCareProfessionSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/HealthCareProfessionSelectBox/HealthCareProfessionSelectBox";
import WellKnownReferenceCodes from "@HunEHealthInfrastructurePlugin/Common/WellKnownReferenceCodes";

interface IPrescriptionListViewDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    medicationRequestReferenceDataStore: MedicationRequestReferenceDataStore;
    localizationService: ILocalizationService;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    dosageLocalizationService: IDosageLocalizationService;
}

interface IPrescriptionListViewProps {
    _dependencies?: IPrescriptionListViewDependencies;
    prescriptionList: PagedItemStore<IPrescriptionListItem>;
    selectedPrescription: Prescription;
    isLoading: boolean;
    onRowSelected: (row: IPrescriptionListItem) => void;
    showExtendedFilter: boolean;
    filterStore: PrescriptionFilterStore;
    onBack?: () => void;
    onGridChangeAsync?: (type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState | IOrderingState[]) => Promise<void>;
    paging: IPagingState;
    ordering: IOrderingState;
    isCompact?: boolean;
    readonly?: boolean;
    onLoadAsync: () => Promise<any>;
    getExternalPrescriptionDetailAsyncFactory?: (identifier: string, prescriptionId: PrescriptionId, itemType: PrescriptionItemType) => () => Promise<void>;
    externalPrescriptionSelected: boolean;
    hideDetailButton?: boolean;
    hideEmergencyPanel?: boolean;
    ehiServiceCallStatus?: EhiServiceCallStatus;
    isInitialLoad?: boolean;
    hideUpdateDateWarning?: boolean;
    errorExtensionProps?: any;
}

/** @screen */
@State.observer
class PrescriptionListView extends React.Component<IPrescriptionListViewProps> {
    
    private clientSideValidator = new EhiDigitalPrescriptionListFilterValidator();
    
    private get localizationService() {
        return this.props._dependencies.localizationService;
    }

    private get hunReferenceDataStore() {
        return this.props._dependencies.medicationRequestReferenceDataStore;
    }

    private get resources() {
        return StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel;
    }

    private get isCompactAndDetailOpen(): boolean {
        return this.props.isCompact && !!this.props.selectedPrescription;
    }
    private get selectedListItem(): IPrescriptionListItem {
        return isNullOrUndefined(this.props.selectedPrescription) ?
            null :
            this.props.prescriptionList.items.find(prescription => ValueWrapper.equals(prescription.id, this.props.selectedPrescription.id));
    }

    @State.bound
    public getSubtitle(value: IPrescriptionProduct) {
        return formatStringWithObjectParams(this.isCompactAndDetailOpen
            ? this.resources.Badge.SuppliedMonthsCompact
            : this.resources.Badge.SuppliedMonths, {
            suppliedMonths: value.suppliedMonths.toString(),
        });
    }

    @State.bound
    private renderMedicationName(value: IPrescriptionProduct[]) {
        const firstItem = value[0];
        return (
            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ flexBasis: "auto", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                    {firstItem.name}
                </div>
                <div style={{ flexBasis: "auto", flexGrow: 0, flexShrink: 0, textAlign: "right" }}>
                    {(firstItem.suppliedMonths === 2 || firstItem.suppliedMonths === 3) && <Ui.Badge upperCase={false} text={this.getSubtitle(firstItem)} visualStyle="slate" />}
                </div>
                <div style={{ flexBasis: "auto", flexGrow: 0, flexShrink: 0, textAlign: "right" }}>
                    {value.length > 1 && <Ui.Badge upperCase={false} text={this.resources.Badge.MoreItemsAvailable} visualStyle="slate" />}
                </div>
            </div>
        );
    }

    @State.bound
    private renderMedicationNameHint(value: IPrescriptionProduct[]) {
        return value.map(item => {
            if (item.medicationVersionSelector?.id) {
                const medication = this.props._dependencies.careReferenceDataStore.medications.get(item.medicationVersionSelector, true);
                if (item.amount) {
                    return `${medication.name} ${item.amount} ${medication.textAmount}`;
                }
                return medication.name;
            }
            return item.name;
        }).join(", ");
    }

    @State.bound
    private renderPrescriptionStatus(value: IPrescriptionStatusColumnData, row: IPrescriptionListItem) {
        return (
            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ flexBasis: "auto", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                    {this.localizationService.localizeEnum(PrescriptionStatus[value.status], "PrescriptionStatus")?.Name || ""}
                </div>
                {value.status === PrescriptionStatus.Submitted &&
                    <div style={{ flexBasis: "17%", flexGrow: 0, flexShrink: 0, textAlign: "right", }}>
                        {(!value.digitalPrescriptionIdentifier) &&
                            <Popper
                                wrapperElementType="div"
                                tooltipContent={StaticHunSocialSecurityMedicationRequestResources.PaperPrescription}>
                                <Ui.Icon iconName={"scroll_solid"} />
                            </Popper>}
                        {(value.digitalPrescriptionIdentifier) &&
                            <HisPlatformExtensionPoint extensionProps={{ extensionData: row.extensionData }} type="prescriptionListName">
                                <></>
                            </HisPlatformExtensionPoint>
                        }
                    </div>
                }
            </div>
        );
    }

    @State.bound
    private renderAmount(value: number, row: IPrescriptionListItem) {
        const packagingUnit = this.hunReferenceDataStore.packagingUnits.get(row.packagingUnitId);
        return `${value || ""} ${isNullOrUndefined(packagingUnit) ? "" : packagingUnit.name}`;
    }

    @State.bound
    private renderClaimTypeHint(value: MedicationSubsidyClaimTypeId) {
        const claimType = this.hunReferenceDataStore.medicationSubsidyClaimTypes.get(value);
        return isNullOrUndefined(claimType) ? "" : claimType.name;
    }

    private renderPointOfCareHint(value: string, row: IPrescriptionListItem) {
        return row.pointOfCareName;
    }

    @State.computed private get emptyStateSettings() {
        return this.props.isInitialLoad === false
            && !isNullOrUndefined(this.props.ehiServiceCallStatus)
            && this.props.ehiServiceCallStatus !== EhiServiceCallStatus.Success
            ? {
                title: this.resources.CannotQueryData,
                message: this.resources.PressLoadButtonToQueryDataAndSelectOrganizationUnit,
                content: (
                    <>
                        <Ui.Button automationId="reloadList" text={this.resources.Load} visualStyle="primary" onClickAsync={this.props.onLoadAsync} />
                    </>
                )
            }
            : undefined;
    }
    
    @State.bound
    private async validateAndLoadEhiPrescriptionsAsync() {
        if (this.clientSideValidator.isValid()) {
            await this.props.onLoadAsync?.();
        }
    }

    private static filterHealthcareProfessionCode(code: string): boolean {
        return code.length === 2 || code.toUpperCase() === "GYS";
    }

    public render() {
        return (
            <Ui.ToolbarLayout
                topToolbar={this.renderToolbar()}
            >
                <HisPlatformExtensionPoint
                    type="prescriptionHeader"
                    extensionProps={this.props.errorExtensionProps}>
                    <></>
                </HisPlatformExtensionPoint>
                <Ui.DataGrid
                    dataSource={this.props.prescriptionList}
                    isLoading={this.props.isLoading}
                    isSelectable
                    rowComparer={this.prescriptionListRowComparer}
                    onSelectedRowChange={this.props.onRowSelected}
                    selectedRow={this.selectedListItem}
                    rowHeight={42}
                    fixedLayout
                    onBack={this.props.onBack}
                    hasBackButton={!!this.props.onBack}
                    getRowIndicatorStyle={this.getClassNameByRowState}
                    onChangeAsync={this.props.onGridChangeAsync}
                    paging={this.props.paging}
                    ordering={this.props.ordering}
                    fixedHeight="100%"
                    hidePager={this.isCompactAndDetailOpen}
                    emptyStateSettings={this.emptyStateSettings}
                    automationId="prescriptionListView_dataGrid"
                >
                    <DataGridColumn
                        dataGetter={"statusColumn"}
                        onRenderCellValue={this.renderPrescriptionStatus}
                        header={this.resources.Columns.Status}
                        isVisible={!this.props.selectedPrescription && this.props.filterStore.prescriptionDataSource === PrescriptionDataSource.His}
                        width={130}
                        id="Status"
                        showHint={false}
                        isOrderable
                    />
                    <DataGridExtensibleEnumColumn
                        dataGetter={"ehiStatus"}
                        extensibleEnumStore={this.hunReferenceDataStore.ehiPrescriptionStatuses}
                        header={this.resources.Columns.Status}
                        isVisible={!this.props.selectedPrescription && this.props.filterStore.prescriptionDataSource === PrescriptionDataSource.Ehi}
                        width={130}
                        id={"EhiStatus"}
                        showHint={false}
                        isOrderable
                    />
                    <DataGridDateColumn
                        dataGetter={"prescribedOn"}
                        header={this.resources.Columns.PrescribedOn}
                        id="PrescribedOn"
                        isVisible={!this.isCompactAndDetailOpen}
                        isOrderable
                        width={110}
                    />
                    <DataGridColumn
                        dataGetter={"products"}
                        header={(!this.isCompactAndDetailOpen) && this.resources.Columns.MedicationName}
                        onRenderCellValue={this.renderMedicationName}
                        onRenderHintValue={this.renderMedicationNameHint}
                        id="MedicationName"
                        isOrderable={!this.isCompactAndDetailOpen}
                        hideOverflow
                    />
                    <DataGridColumn
                        dataGetter={"textAmount"}
                        header={this.resources.Columns.MedicationAmount}
                        isVisible={!this.props.selectedPrescription}
                        id="MedicationAmount"
                        width={80}
                        hideOverflow
                    />
                    <DataGridColumn
                        dataGetter={"amount"}
                        onRenderCellValue={this.renderAmount}
                        onRenderHintValue={this.renderAmount}
                        header={this.resources.Columns.Amount}
                        isVisible={!this.props.selectedPrescription}
                        id="Amount"
                        width={30}
                        hideOverflow
                    />
                    <DataGridColumn
                        dataGetter={"dosage"}
                        header={this.resources.Columns.Dosage}
                        onRenderCellValue={this.renderDosage}
                        isVisible={!this.props.selectedPrescription}
                        id="Dosage"
                        width={80}
                    />
                    <DataGridColumn
                        dataGetter={"subsidyClaimTypeId"}
                        onRenderCellValue={this.renderClaimType}
                        onRenderHintValue={this.renderClaimTypeHint}
                        header={this.resources.Columns.ClaimType}
                        isVisible={!this.props.selectedPrescription}
                        id="ClaimType"
                        width={50}
                    />
                    <DataGridColumn
                        dataGetter={"pointOfCareCode"}
                        onRenderHintValue={this.renderPointOfCareHint}
                        header={this.resources.Columns.PointOfCare}
                        isVisible={!this.isCompactAndDetailOpen}
                        id="PointOfCare"
                        width={60}
                    />
                    {!this.props.hideDetailButton && <DataGridColumn
                        onRenderCellValue={this.renderGetExternalPrescriptionDetailButton}
                        isVisible={this.props.filterStore.prescriptionDataSource === PrescriptionDataSource.His}
                        width={45}
                    />}
                </Ui.DataGrid>
            </Ui.ToolbarLayout>
        );
    }

    @State.bound
    private prescriptionListRowComparer(left: IPrescriptionListItem, right: IPrescriptionListItem): boolean {
        if (this.props.filterStore.prescriptionDataSource === PrescriptionDataSource.His) {
            return ValueWrapper.equals(left?.id, right?.id);
        } else {
            return left?.extensionData?.EhiIdentifier === right?.extensionData?.EhiIdentifier;
        }
    }

    @State.bound
    private renderGetExternalPrescriptionDetailButton(value: any, row: IPrescriptionListItem): React.ReactNode {
        let identifier: string;
        if (!isNullOrUndefined(row?.extensionData?.EhiIdentifier)) {
            identifier = row.extensionData.EhiIdentifier;
        } else if (!isNullOrUndefined(row?.statusColumn?.digitalPrescriptionIdentifier)) {
            identifier = row.statusColumn.digitalPrescriptionIdentifier;
        }

        return !isNullOrUndefined(identifier) && (
            <Ui.Button
                onClickAsync={this.props.getExternalPrescriptionDetailAsyncFactory(identifier, row.id, row.itemType)}
                visualStyle="standard"
                float="right"
                iconName="eeszt"
                size="compact"
                style={{ marginRight: 10 }}
                stopPropagation
                automationId="externalPrescriptionDetailButton"
            />
        );
    }

    private getClassNameByRowState(row: IPrescriptionListItem): IRowIndicatorStyle {
        switch (row.statusColumn.status) {
            case PrescriptionStatus.Submitted:
                return { className: Styles.submitted };
            case PrescriptionStatus.UnderRecording:
                return { className: Styles.underRecording };
            case PrescriptionStatus.Deleted:
                return { className: Styles.deleted };
            case PrescriptionStatus.WaitingForDelete:
                return { className: Styles.waitingForDelete };
            case PrescriptionStatus.WaitingForSubmissionCancellation:
                return { className: Styles.waitingForSubmissionCancellation };
        }
    }

    @State.bound
    private renderClaimType(value: MedicationSubsidyClaimTypeId) {
        return value
            ? this.hunReferenceDataStore.medicationSubsidyClaimTypes.get(value)?.code
            : "";
    }

    @State.bound
    private renderDosage(dosage: Dosage) {
        return this.props._dependencies.dosageLocalizationService.localize(dosage);
    }

    private renderToolbar() {
        const filterStore = this.props.filterStore;
        const pointOfCareIdFilters: FilterBase[] = filterStore.pointOfCareIds
            ? filterStore.pointOfCareIds.map(id => new ExplicitIdentifierFilter("PointOfCare", id.value))
            : [];

        return (!this.isCompactAndDetailOpen) ? (
            <ClientSideValidationBoundary validator={this.clientSideValidator} entityTypeName={"Filter"}>
                <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        {this.props.selectedPrescription && !this.props.readonly &&
                        <Ui.Flex>
                            <Ui.Flex.Item>
                                <Ui.UniversalEnumSelector
                                    enumName={"PrescriptionSearchBase"}
                                    enumOrigin="server"
                                    value={filterStore.prescriptionSearchBase}
                                    onChange={filterStore.setPrescriptionSearchBase}
                                    enumType={PrescriptionSearchBase}
                                    displayMode="groupedRadioButtons"
                                    automationId="prescriptionSearchBaseSelector"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        }
                        <Ui.Flex outerSpacing={"regular"} innerSpacing={"small"}>
                            <Ui.Flex.Item grow>
                                <Ui.TextBox
                                    value={filterStore.medicationNameFilter}
                                    onChange={filterStore.setMedicationNameFilter}
                                    label={this.resources.Labels.MedicationName}
                                    style={{ margin: 0 }}
                                    automationId="medicationNameFilterTextBox"
                                />
                            </Ui.Flex.Item>
                            {filterStore.prescriptionDataSource === PrescriptionDataSource.His && <Ui.Flex.Item>
                                <Ui.CheckBox
                                    displayMode={"switch"}
                                    value={filterStore.includeDeleted}
                                    onChange={filterStore.setIncludeDeleted}
                                    label={this.resources.Labels.IncludeDeleted}
                                    verticalAlign={"inlineInput"}
                                    automationId="includeDeletedCheckBox"
                                />
                            </Ui.Flex.Item>}
                            {!this.props.hideEmergencyPanel && filterStore.prescriptionDataSource === PrescriptionDataSource.Ehi && <Ui.Flex.Item style={{ marginTop: "18px" }}>
                                <EhiQueryButtonWithEmergencyCheckBox
                                    onQueryAsync={this.validateAndLoadEhiPrescriptionsAsync}
                                    isEmergency={filterStore.isEmergency}
                                    onIsEmergencyChange={filterStore.setIsEmergency}
                                    onReasonChange={filterStore.setEmergencyReason}
                                />
                            </Ui.Flex.Item>}
                        </Ui.Flex>
                        {this.props.showExtendedFilter && !this.props.selectedPrescription && !this.props.externalPrescriptionSelected &&
                        <Ui.Flex outerSpacing={"regular"}>
                            <Ui.Flex.Item xs={12}>
                                <Ui.GroupBox hasBorder
                                             visualStyle={"primary"}
                                             style={{ borderLeft: "1px solid #00B4EC", backgroundColor: "#F4F7FC" }}>
                                    <Ui.Flex>
                                        <Ui.Flex.Item xs={4}>
                                            <PrescriptionDataSourceSelector
                                                value={filterStore.prescriptionDataSource}
                                                onChange={filterStore.setPrescriptionDataSource}
                                                label={this.resources.Labels.DataSource}
                                            />
                                        </Ui.Flex.Item>
                                        {filterStore.prescriptionDataSource === PrescriptionDataSource.His && <Ui.Flex.Item xs={4}>
                                            <Ui.DateRangePicker value={filterStore.dateRangeFilter}
                                                onChange={filterStore.setDateRangeFilter}
                                                label={this.resources.Labels.DateRange}
                                                automationId="dateRangeFilterPicker"
                                            />
                                        </Ui.Flex.Item>}
                                        {filterStore.prescriptionDataSource === PrescriptionDataSource.Ehi && <>
                                            <Ui.Flex.Item xs={4}>
                                                <Ui.DatePicker
                                                    value={filterStore.ehiPrescriptionSearchStartDate}
                                                    onChange={filterStore.setEhiPrescriptionSearchStartDate}
                                                    label={this.resources.Labels.EhiPrescriptionSearchStartDate}
                                                    automationId="ehiPrescriptionSearchStartDatePicker"
                                                    propertyIdentifier={"ehiPrescriptionSearchStartDate"}
                                                />
                                            </Ui.Flex.Item>
                                            <Ui.Flex.Item xs={4}>
                                                <Ui.NumberBox
                                                    value={filterStore.ehiPrescriptionSearchMonths}
                                                    onChange={filterStore.setEhiPrescriptionSearchMonths}
                                                    label={this.resources.Labels.EhiPrescriptionSearchMonths}
                                                    propertyIdentifier={"ehiPrescriptionSearchMonths"}
                                                />
                                            </Ui.Flex.Item>
                                        </>}
                                    </Ui.Flex>
                                    <Ui.Flex>
                                        {filterStore.prescriptionDataSource === PrescriptionDataSource.His && <>
                                            <Ui.Flex.Item xs={4}>
                                                <HealthCareProfessionSelectBox
                                                    identifierSystemId={WellKnownReferenceCodes.OthInsuranceCode}
                                                    codeFilter={PrescriptionListView.filterHealthcareProfessionCode}
                                                    value={filterStore.healthCareProfessionIds}
                                                    label={this.resources.Labels.ProfessionCode}
                                                    onChange={filterStore.setHealthCareProfessionIds}
                                                    automationId="insuranceCodeHealthcareProfessionsSelectBox"
                                                />
                                            </Ui.Flex.Item>
                                            <Ui.Flex.Item xs={4}>
                                                <DoctorCodeSelector
                                                    label={this.resources.Labels.Practitioner}
                                                    onChange={filterStore.setPractitionerId}
                                                    filters={pointOfCareIdFilters}
                                                    value={filterStore.practitionerId}
                                                    automationId="practitionerSelectBox"
                                                />
                                            </Ui.Flex.Item>
                                        </>}
                                        {filterStore.prescriptionDataSource === PrescriptionDataSource.Ehi && <>
                                            <Ui.Flex.Item xs={4}>
                                                <EhiPrescriptionSearchModeSelector
                                                    value={filterStore.ehiPrescriptionSearchMode}
                                                    onChange={filterStore.setEhiPrescriptionSearchMode}
                                                    label={this.resources.Labels.EhiPrescriptionSearchMode}
                                                    propertyIdentifier={"ehiPrescriptionSearchMode"}
                                                />
                                            </Ui.Flex.Item>
                                            {filterStore.ehiPrescriptionSearchMode === EhiPrescriptionSearchMode.SearchByIdentifier && <Ui.Flex.Item xs={4}>
                                                <Ui.TextBox
                                                    value={filterStore.ehiPrescriptionIdentifier}
                                                    onChange={filterStore.setEhiPrescriptionIdentifier}
                                                    label={this.resources.Labels.EhiPrescriptionIdentifier}
                                                    automationId="ehiPrescriptionIdentifierTextBox"
                                                    propertyIdentifier={"ehiPrescriptionIdentifier"}
                                                />
                                            </Ui.Flex.Item>}
                                        </>}
                                        {filterStore.prescriptionDataSource === PrescriptionDataSource.His &&
                                        <Ui.Flex.Item xs={4}>
                                            <PrescriptionStatusSelector
                                                value={filterStore.statuses}
                                                onChange={filterStore.setStatuses}
                                                label={this.resources.Labels.Status}
                                            />
                                        </Ui.Flex.Item>}
                                        {filterStore.prescriptionDataSource === PrescriptionDataSource.Ehi &&
                                        <Ui.Flex.Item xs={4}>
                                            <EhiPrescriptionStatusSelector
                                                value={filterStore.ehiPrescriptionStatuses}
                                                onChange={filterStore.setEhiPrescriptionStatuses}
                                                label={this.resources.Labels.Status}
                                                automationId="ehiPrescriptionStatusSelector"
                                                propertyIdentifier={"ehiPrescriptionStatuses"}
                                            />
                                        </Ui.Flex.Item>}
                                    </Ui.Flex>
                                    {filterStore.prescriptionDataSource === PrescriptionDataSource.His && <Ui.Flex>
                                        <Ui.Flex.Item xs={12}>
                                            <OrganizationSelector
                                                value={filterStore.pointOfCareIds}
                                                onChange={filterStore.setPointOfCareIds}
                                                pointOfCareMode={true}
                                                automationId="organization"
                                            />
                                        </Ui.Flex.Item>
                                    </Ui.Flex>}
                                </Ui.GroupBox>
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        }
                        {!this.props.hideUpdateDateWarning && <Ui.Flex>
                            <Ui.Flex.Item xs={12}>
                                <MedicationUpdateDateWarning />
                            </Ui.Flex.Item>
                        </Ui.Flex>}
                    </Ui.Flex.Item>
                </Ui.Flex>
            </ClientSideValidationBoundary>
        ) : (
            <Ui.Flex xsJustify="center">
                <Ui.Flex.Item>
                    <Ui.Button
                        iconName="angleDoubleRight"
                        visualStyle="standard"
                        onClick={this.props.onBack}
                        size="compact"
                        style={{ marginTop: 12 }}
                        automationId="backButton"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }
}

export default connect(
    PrescriptionListView,
    new DependencyAdapter<IPrescriptionListViewProps, IPrescriptionListViewDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService"),
        medicationRequestReferenceDataStore: c.resolve("MedicationRequestReferenceDataStore"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        dosageLocalizationService: c.resolve("IDosageLocalizationService")
    }))
);
