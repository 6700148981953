import DependencyContainer from "@DiContainer";
import * as ReportingProxy from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Proxy.g";
import { GetTypeNameWithPrefix } from "@HunSocialSecurityPlugin/PluginHelper";
import HunReportingApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/HunReportingApiAdapter";
import PerformanceReportStoreMapper from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/PerformanceReportStoreMapper";
import PerformanceReportScorePlanApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/PerformanceReportScorePlanApiAdapter";
import PerformanceReportScorePlanStoreMapper from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/PerformanceReportScorePlanStroreMapper";

DependencyContainer
    .bind(GetTypeNameWithPrefix("IReportingClient"))
    .to(ReportingProxy.ReportingClient)
    .inSingletonScope();

DependencyContainer
    .bind("HunReportingApiAdapter")
    .to(HunReportingApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PerformanceReportStoreMapper")
    .to(PerformanceReportStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("PerformanceReportScorePlanApiAdapter")
    .to(PerformanceReportScorePlanApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PerformanceReportScorePlanStoreMapper")
    .to(PerformanceReportScorePlanStoreMapper)
    .inSingletonScope();