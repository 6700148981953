import IPermissionScope from "@PluginInterface/OperationAccessControl/IPermissionScope";
import _ from "@HisPlatform/Common/Lodash";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import { defaultPermissionScopeTypeValues } from "@Primitives/DefaultPermissionScopeTypeValues";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";

export default class PermissionScope implements IPermissionScope {

    @State.observable private _mode: "positive" | "negative";
    @State.observable private _includedValues: string[] | null;
    @State.observable private _excludedValues: string[] | null;

    public get mode() { return this._mode; }
    public get includedValues() { return this._includedValues; }
    public get excludedValues() { return this._excludedValues; }

    public get isAll() {
        return this.mode === "negative" && arrayIsNullOrEmpty(this.excludedValues);
    }

    private constructor(
        public type: string,
        mode: "positive" | "negative",
        includedValues: string[] | null,
        excludedValues: string[] | null
    ) {
        this._mode = mode;
        this._includedValues = includedValues;
        this._excludedValues = excludedValues;
    }

    public static createPositive(type: string, includedValues: string[]) {
        return new PermissionScope(type, "positive", includedValues ?? [], null);
    }

    public static createNegative(type: string, excludedValues: string[]) {
        return new PermissionScope(type, "negative", null, excludedValues ?? []);
    }

    public static createAll(type: string) {
        return new PermissionScope(type, "negative", null, []);
    }

    public containsScope(scope: IPermissionScope): boolean {
        if (this.mode === "negative" && (!this.excludedValues.length || !_.intersection(this.excludedValues, scope.includedValues).length)) {
            return true;
        }

        if (scope.mode === "negative" && (!scope.excludedValues.length || !_.intersection(scope.excludedValues, this.includedValues).length)) {
            return false;
        }

        const notIncludedValues = _.difference(scope.includedValues, this.includedValues);

        return (!notIncludedValues.length ||
            notIncludedValues.length === 1 && notIncludedValues[0] === defaultPermissionScopeTypeValues.get(scope.type)) &&
            !_.difference(scope.excludedValues, this.excludedValues).length;
    }

    public union(scope: IPermissionScope): void {

        if (this.mode === "positive" && scope.mode === "positive") {
            this._includedValues = _.union(this.includedValues, scope.includedValues);
            this._excludedValues = null;
            return;
        }

        if (this.mode === "negative" && scope.mode === "negative") {
            this._excludedValues = _.intersection(this.excludedValues, scope.excludedValues);
            this._includedValues = null;
            return;
        }

        if (this.mode === "positive" && scope.mode === "negative") {
            this._mode = "negative";
            this._excludedValues = _.xor(this.includedValues, scope.excludedValues);
            this._includedValues = null;
            return;
        }

        if (this.mode === "negative" && scope.mode === "positive") {
            this._excludedValues = _.xor(this.excludedValues, scope.includedValues);
            this._includedValues = null;
            return;
        }

        throw new Error("Cannot union scopes: unknown mode combination");
    }

    public setPositive(includedValues: string[]): void {
        this._mode = "positive";
        this._excludedValues = null;
        this._includedValues = includedValues ?? [];
    }

    public setNegative(excludedValues: string[]): void {
        this._mode = "negative";
        this._includedValues = null;
        this._excludedValues = excludedValues ?? [];
    }

    public setFrom(scopeToCopyFrom: IPermissionScope): void {
        this._mode = scopeToCopyFrom.mode;
        this._includedValues = scopeToCopyFrom.includedValues;
        this._excludedValues = scopeToCopyFrom.excludedValues;
    }

}
