import * as Proxy from "@HisPlatform/BoundedContexts/Authorization/Api/Proxy.g";
import EntityPermissionStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/DataAccessControl/EntityPermissionStore";
import UserSubject from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/DataAccessControl/UserSubject";
import UserGroupSubject from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/DataAccessControl/UserGroupSubject";

export function getSetEntityPermissionsDto(permissionStore: EntityPermissionStore, entityType: string, entityId: string): Proxy.SetEntityPermissionControllerDto {
    const entries = permissionStore.accessControlList.entries.map(entry => {
        let subject: Proxy.AccessControlSubjectBase = null;
        if (entry.subject instanceof UserSubject) {
            subject = new Proxy.UserSubject({
                userId: entry.subject.userId
            });
        } else { // entry.subject instanceof UserGroupSubject
            subject = new Proxy.UserGroupSubject({
                userGroupId: (entry.subject as UserGroupSubject).userGroupId
            });
        }
        return new Proxy.AccessControlEntry({
            permission: new Proxy.ObjectPermission({ id: entry.permission.value }),
            subject: subject
        });
    });

    return new Proxy.SetEntityPermissionControllerDto({
        accessControlList: new Proxy.AccessControlList({
            entries: entries,
            protectionLevel: permissionStore.accessControlList.entityProtectionLevel
        }),
        entityType: entityType,
        entityId: entityId,
        rowVersion: permissionStore.rowVersion
    });
}