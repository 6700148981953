import DependencyContainer from "@DiContainer";
import BinaryDocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/BinaryDocumentApiAdapter";
import DocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/DocumentApiAdapter";
import TemplateBasedDocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/TemplateBasedDocumentApiAdapter";

DependencyContainer
    .bind("BinaryDocumentApiAdapter")
    .to(BinaryDocumentApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("TemplateBasedDocumentApiAdapter")
    .to(TemplateBasedDocumentApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("DocumentApiAdapter")
    .to(DocumentApiAdapter)
    .inSingletonScope();