import GenderId from "@Primitives/GenderId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import Di from "@Di";
import INewPatientBaseDataRepository, { INewPatientBaseData } from "@HisPlatform/BoundedContexts/Care/Services/Definition/NewPatientBaseDataRepository/INewPatientBaseDataRepository";

@Di.injectable()
export default class NewPatientBaseDataRepository implements INewPatientBaseDataRepository {

    private readonly sessionStoreKey = "newPatientBaseData";

    public getNewPatientBaseData(): INewPatientBaseData {
        const newPatientBaseData: any = sessionStorage.getItem(this.sessionStoreKey);
        if (newPatientBaseData) {
            const result: INewPatientBaseData = {birthDate: null, name: "", genderId: null, mothersName: ""};

            const baseData = JSON.parse(newPatientBaseData);

            if (baseData) {
                if (baseData.genderValue) {
                    result.genderId = new GenderId(baseData.genderValue);
                }

                if (baseData.birthDate) {
                    result.birthDate = LocalDate.parse(baseData.birthDate);
                }
                
                result.mothersName = baseData.mothersName;
                result.name = baseData.name;
            }

            return result;
        }
        return null;
    }
    
    public setNewPatientBaseData(newPatientBaseData: INewPatientBaseData): void {
        sessionStorage.setItem(this.sessionStoreKey, newPatientBaseData && JSON.stringify({
            name: newPatientBaseData.name,
            genderValue: newPatientBaseData.genderId && newPatientBaseData.genderId.value,
            birthDate: newPatientBaseData.birthDate && newPatientBaseData.birthDate.stringify(),
            mothersName: newPatientBaseData.mothersName
        }));
    }
}