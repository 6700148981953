import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import * as Ui from "@CommonControls";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { emptyObject, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import AsyncMessageQueue from "@Toolkit/CommonWeb/AsyncMessageQueue";

interface ISchedulingServiceSelectBoxDependencies {
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
}

interface ISchedulingServiceSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: ISchedulingServiceSelectBoxDependencies;
    explicitIds?: SchedulingServiceId[];
    defaultId?: SchedulingServiceId;
    shouldSelectOnlyItem?: boolean;
    setLoadingState?: (state: boolean) => void;
}

@State.observer
class SchedulingServiceSelectBox extends React.Component<ISchedulingServiceSelectBoxProps> {

    private readonly loadQueue = new AsyncMessageQueue(this.loadAsync);

    private get referenceDataStore() {
        return this.props._dependencies.schedulingReferenceDataStore;
    }

    @State.observable.ref private items: Array<ISelectBoxItem<SchedulingServiceId>> = null;
    public componentDidMount() {
        dispatchAsyncErrors(this.loadQueue.enqueueAndProcessAsync(emptyObject), this);
    }

    public componentDidUpdate(prevProps: ISchedulingServiceSelectBoxProps) {
        if (prevProps.explicitIds !== this.props.explicitIds || prevProps.shouldSelectOnlyItem !== this.props.shouldSelectOnlyItem) {
            dispatchAsyncErrors(this.loadQueue.enqueueAndProcessAsync(emptyObject), this);
        }
    }

    @State.bound
    private async loadAsync() {
        this.startLoading();
        try {
            const explicitIds = !isNullOrUndefined(this.props.explicitIds) ? [...this.props.explicitIds] : null;

            if (!isNullOrUndefined(explicitIds)) {
                await this.referenceDataStore.schedulingServices.ensureLoadedAsync(this.props.explicitIds);
                await this.referenceDataStore.appointmentScheduleDefinitions.ensureAllLoadedAsync();
                this.initializeExplicitItems(explicitIds);
            } else {
                await this.referenceDataStore.schedulingServices.ensureAllLoadedAsync();
                await this.referenceDataStore.appointmentScheduleDefinitions.ensureAllLoadedAsync();
                this.initializeAllItems();
            }
        } finally {
            this.stopLoading();
        }
    }

    @State.action
    private initializeExplicitItems(explicitIds: PointOfCareId[]) {
        this.items = this.referenceDataStore.schedulingServices.items
            .filter(i => explicitIds.some(s => ValueWrapper.equals(s, i.id)))
            .map(this.toSelectBoxItem);
        this.selectItem();
    }

    @State.action
    private initializeAllItems() {
        this.items = this.referenceDataStore.schedulingServices.items
            .map(this.toSelectBoxItem);
        this.selectItem();
    }

    private selectItem() {
        if (!isNullOrUndefined(this.props.defaultId)) {
            this.props.onChange(this.props.defaultId);
        } else if (this.props.shouldSelectOnlyItem && this.items.length === 1) {
            this.props.onChange(this.items[0].value);
        }
    }

    @State.action.bound private startLoading() {
        this.props.setLoadingState(true);
    }

    @State.action.bound private stopLoading() {
        this.props.setLoadingState(false);
    }

    @State.bound
    private toSelectBoxItem(item: SchedulingServiceSubjectStore) {
        const scheduleDefinition = this.referenceDataStore.appointmentScheduleDefinitions.items.find(a => a.ownedSchedulingServices.some(o => o.value === item.id.value));
    
        return {
            text: item.code,
            name: item.name,
            value: item.id,
            scheduleName: scheduleDefinition?.name
        } as ISelectBoxItem<SchedulingServiceId>;
    }

    @State.bound
    private renderValue(props: any) {
        return (
            <>
                <b>{props.data.text}</b> {props.data.name} - {props.data.scheduleName}
            </>
        );
    }

    private getText(value: any) {
        return `${value.code} ${value.text} ${value?.scheduleName}`;
    }

    public render() {
        return (
            <Ui.SelectBox
                {...this.props}
                loading={!this.items}
                items={this.items}
                customValueRenderer={this.renderValue}
                getOptionText={this.getText}
                orderItems
            />
        );
    }
}

export default connect(
    SchedulingServiceSelectBox,
    new DependencyAdapter<ISchedulingServiceSelectBoxProps, ISchedulingServiceSelectBoxDependencies>(container => {
        return {
            schedulingReferenceDataStore: container.resolve("SchedulingReferenceDataStore")
        };
    })
);
