import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";

export default interface ILogger {
    readonly configuration: ILoggingConfiguration;
    configure(configuration: ILoggingConfiguration): void;

    log(value: any, level: logLevel, additionalMessage?: string): void;
    logEvent: TypedEvent<string>;

    initializeUserName?(userName: string): void;
}

export type logLevel = "trace" | "debug" | "info" | "warn" | "error" | "fatal";

export interface ILoggingConfiguration {
    isDebugLogEnabled: boolean;
    isTraceLogEnabled: boolean;
    isInfoLogEnabled: boolean;
    isWarnLogEnabled: boolean;
    isErrorLogEnabled: boolean;
    isFatalLogEnabled: boolean;
}

export const defaultLoggingConfiguration: ILoggingConfiguration = {
    isDebugLogEnabled: false,
    isTraceLogEnabled: false,
    isInfoLogEnabled: false,
    isWarnLogEnabled: true,
    isErrorLogEnabled: true,
    isFatalLogEnabled: true
};

export const fullLoggingConfiguration: ILoggingConfiguration = {
    isDebugLogEnabled: true,
    isTraceLogEnabled: true,
    isInfoLogEnabled: true,
    isWarnLogEnabled: true,
    isErrorLogEnabled: true,
    isFatalLogEnabled: true
};