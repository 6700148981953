import { IValidationProblemParameterMapper } from "@Toolkit/CommonWeb/ApiAdapter/IValidationProblemParameterMapper";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import Di from "@Di";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";

@Di.injectable()
export default class CareActivityTextBlockValidationProblemMapper implements IValidationProblemParameterMapper {

    constructor(
        @Di.inject("CareReferenceDataStore") private careReferenceDataStore: CareReferenceDataStore
    ) { }

    public collectValidationProblemParameterReferenceData(vp: IClientValidationProblem) {
        return;
    }
    
    @State.bound
    public async loadCollectedValidationProblemParameterReferenceDataAsync(): Promise<void> {
        await this.careReferenceDataStore.textBlockType.ensureLoadedAsync();
    }
    
    @State.bound
    public resolveValidationProblemParameters(vp: IClientValidationProblem): { [id: string]: string; } {
        const results: { [id: string]: string; } = {};
        for (const key in vp.parameters) {
            if (key) {
                switch (key) {
                    case "TextBlockRuleOutputDto":
                        const rawIds = _.get(vp.parameters, key + ".TextBlockTypeIds") as any[];
                        const typeIds = rawIds?.map(i => new TextBlockTypeId(i?.Value?.toString())) || [];

                        const localizedValues: string[] = [];
                        typeIds.forEach(i => {
                            const localizedEnum = this.careReferenceDataStore.textBlockType.get(i);
                            localizedValues.push(localizedEnum.displayValue.Name);
                        });

                        results.TextBlockTypes = localizedValues.join(", ");
                        break;
                    default:
                        const enumValue = this.careReferenceDataStore.textBlockType.get(new TextBlockTypeId(vp.parameters?.TextBlockTypeId.Value));
                        results.TextBlock = enumValue.displayValue.Name;
                        break;
                }
            }
        }

        return results;
    }
}