exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RssReader_item_2Izyv {\n  background-color: #505c71;\n  padding: 6px 0 6px 15px;\n  font-family: PragatiNarrow;\n  font-size: 14px;\n  color: #f4f7fc;\n  line-height: 1.2;\n  margin-bottom: 2px;\n}\n.RssReader_item_2Izyv > a {\n  font-family: PragatiNarrowBold;\n  font-size: 15px;\n  color: #f4f7fc;\n  display: block;\n  margin: 0;\n  line-height: 1.1;\n}\n.RssReader_load-more-items_3GRV1 {\n  background-color: #505c71;\n  padding: 6px 0 6px 15px;\n  font-family: PragatiNarrow;\n  font-size: 14px;\n  color: #f4f7fc;\n  line-height: 1.2;\n  margin-bottom: 2px;\n  padding: 0;\n  text-align: center;\n}\n.RssReader_load-more-items_3GRV1 > a {\n  font-family: PragatiNarrowBold;\n  font-size: 15px;\n  color: #f4f7fc;\n  display: block;\n  margin: 0;\n  line-height: 1.1;\n}\n", ""]);

// exports
exports.locals = {
	"item": "RssReader_item_2Izyv",
	"load-more-items": "RssReader_load-more-items_3GRV1",
	"loadMoreItems": "RssReader_load-more-items_3GRV1"
};