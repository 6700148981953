import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import IOrganizationIdTypeUnion from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/IOrganizationUnitIdTypeUnion";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import * as ColumnStyles from "./ColumnStyles.less";
import _ from "@HisPlatform/Common/Lodash";
import { iconNameType } from "@CommonControls/Icon";
import * as Ui from "@CommonControls";
import { getIconNameByHealthcareProfessions } from "@HisPlatform/BoundedContexts/Organization/Components/Helpers/IconTypeNameHelper";

interface IOrganizationUnitOrExternalLocationColumnDependencies {
    organizationReferenceStore: OrganizationReferenceDataStore;
}

interface IOrganizationUnitOrExternalLocationColumnProps extends IDataGridColumnProps {
    _dependencies?: IOrganizationUnitOrExternalLocationColumnDependencies;
    showName: boolean;
    fallbackValueGetter?: string;
}


const OrganizationUnitOrExternalLocationColumn: React.FC<IOrganizationUnitOrExternalLocationColumnProps> = props => {

    const organizationReferenceDataStore = props._dependencies.organizationReferenceStore;

    const { children, ...columnsProps } = props;

    const renderValue = useCallback((value: any, row: any) => {
        
        const valueDefined = value !== null && value !== undefined;
        if (valueDefined) {
            const idTypeUnion = value as IOrganizationIdTypeUnion;
            if (!isNullOrUndefined(idTypeUnion.organizationUnitId)) {
                const poc = organizationReferenceDataStore.allPointsOfCareMap.get(idTypeUnion.organizationUnitId);
                let iconName: iconNameType = null;

                if (poc) {
                    iconName = getIconNameByHealthcareProfessions(poc.healthcareProfessionIds.map(organizationReferenceDataStore.healthCareProfession.get));
                }
                return poc ? (
                    <>
                        {iconName &&
                            <Ui.Icon
                                iconName={iconName}
                                style={{ marginRight: 5 }}
                            />}
                        {poc.shorthand}
                    </>
                ) : "";
            } else if (!isNullOrUndefined(idTypeUnion.externalLocationVersionSelector) && !isNullOrUndefined(idTypeUnion.externalLocationVersionSelector.id)) {
                const externalLocation = organizationReferenceDataStore.externalLocationStore.get(idTypeUnion.externalLocationVersionSelector);
                return externalLocation ? (
                    <div className={ColumnStyles.truncatedString}>
                        {props.showName && (externalLocation.shortName ? externalLocation.shortName : externalLocation.name)}
                        {props.showName && <br />}
                        {externalLocation.identifierAssignments[0].identifier.value}
                    </div>
                ) : "";
            } else {
                return (
                    <div>
                        {value.name}
                    </div>
                );
            }
        } else if (!valueDefined && props.fallbackValueGetter) {
            const fallbackValue = _.get(row, props.fallbackValueGetter);
            return fallbackValue;
        } else {
            return null;
        }
    }, [props.fallbackValueGetter]);

    const renderHint = useCallback((value: any, row: any) => {
        const valueDefined = !isNullOrUndefined(value);
        if (valueDefined) {
            const idTypeUnion = value as IOrganizationIdTypeUnion;
            if (!isNullOrUndefined(idTypeUnion.organizationUnitId)) {
                const poc = organizationReferenceDataStore.allPointsOfCareMap.get(idTypeUnion.organizationUnitId);
                return poc?.name;
            } else if (!isNullOrUndefined(idTypeUnion.externalLocationVersionSelector) && !isNullOrUndefined(idTypeUnion.externalLocationVersionSelector.id)) {
                const externalLocation = organizationReferenceDataStore.externalLocationStore.get(idTypeUnion.externalLocationVersionSelector);
                return externalLocation?.name;
            } else {
                return value?.name || undefined;
            }
        } else if (!valueDefined && props.fallbackValueGetter) {
            const fallbackValue = _.get(row, props.fallbackValueGetter);
            return fallbackValue;
        } else {
            return undefined;
        }
    }, [props.fallbackValueGetter]);

    return (
        <DataGridColumn
            {...columnsProps}
            onRenderCellValue={renderValue}
            onRenderHintValue={renderHint}
        />
    );
};

export default connect(
    OrganizationUnitOrExternalLocationColumn,
    new DependencyAdapter<IOrganizationUnitOrExternalLocationColumnProps, IOrganizationUnitOrExternalLocationColumnDependencies>(container => {
        return {
            organizationReferenceStore: container.resolve("OrganizationReferenceDataStore")
        };
    })
);
