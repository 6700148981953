import {
    IDataGridColumnBaseProps,
    IDataGridColumnFilterProps
} from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import React, {useCallback} from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import CareContactType from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareContactType.g";
import CareContactTypeSelectBox from "@HisPlatform/Packages/Care/Components/CareContactTypeSelectBox";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import {commonMenuMaxWidth} from "@CommonControls/SelectBox/SelectBox";
import {Icon} from "@CommonControls";
import { iconNameType } from "@CommonControls/Icon";

interface ICareContactTypeColumnDependencies {
    localizationService: ILocalizationService;
}

interface ICareContactTypeColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: ICareContactTypeColumnDependencies;
}

const CareContactTypeColumn: React.FC<ICareContactTypeColumnProps> = (props: ICareContactTypeColumnProps) => {
    const valueRenderer = useCallback(
        (value: CareContactType) => {
            const localized = isNullOrUndefined(value) ? null : props._dependencies.localizationService.localizeEnum(CareContactType[value], "CareContactType");
            const tooltip = isNullOrUndefined(localized) ? null : localized.Name;
            const iconName = getIconName(value);
            return <Icon iconName={iconName} tooltipContent={tooltip} tooltipPosition={"bottom"} tooltipTextAlign={"center"} size={"largeish"} />;
        },
        [],
    );
    
    const filterRenderer = useCallback(
        (filterProps: IDataGridColumnFilterProps<CareContactType>) => {
            return (
                <CareContactTypeSelectBox
                    {...filterProps}
                    maxMenuWidth={commonMenuMaxWidth}
                    hoverOnlyIndicatorIcons
                    multiSelect={true}
                />
            );
        },
        [],
    );

    const getIconName = useCallback((careContactType: CareContactType): iconNameType => {
        switch (careContactType) {
            case CareContactType.Ambulatory:
                return "patientWalking";
            case CareContactType.TelemetrySession:
                return "briefcaseSolid";
            case CareContactType.DecisionSupport:
                return "cardiology";
            case CareContactType.Telemedicine:
                return "telemedicine";
            case CareContactType.Inpatient:
            default:
                return "patientInBed";
        }
    }, []);
    
    
    
    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            showHint={false}
        />
    );
};

export default connect(
    CareContactTypeColumn,
    new DependencyAdapter<ICareContactTypeColumnProps, ICareContactTypeColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService")
    }))
);