import CareActivityId from "@Primitives/CareActivityId.g";
import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import MedicalServiceFinancingId from "@Primitives/MedicalServiceFinancingId.g";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";

export class PatientServiceToBill {
    constructor(
        public careActivityId: CareActivityId,
        public quantity: number
    ) {
    }
}

export class PatientPerformedServiceToBill extends PatientServiceToBill {
    constructor(
        careActivityId: CareActivityId,
        quantity: number,
        public medicalServiceFinancingId: MedicalServiceFinancingId,
        public medicalServiceId: MedicalServiceId,
        public financedServiceId: FinancedServiceId
    ) {
        super(careActivityId, quantity);
    }
}

export class PatientServiceRequestToBill extends PatientServiceToBill {
    constructor(
        careActivityId: CareActivityId,
        quantity: number,
        public serviceRequestId: ServiceRequestId,
        public totalNetPrice: number,
        public readonly serviceName: string
    ) {
        super(careActivityId, quantity);
    }
}