import DependencyContainer from "@DiContainer";
import PatientApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/Patient/PatientApiAdapter";
import AllergyIntoleranceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/ReferenceData/AllergyIntoleranceApiAdapter";
import MedicationStatementSubstanceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/ReferenceData/MedicationStatementSubstanceApiAdapter";

DependencyContainer
    .bind("PatientApiAdapter")
    .to(PatientApiAdapter)
    .inSingletonScope();
    
DependencyContainer
    .bind("AllergyIntoleranceApiAdapter")
    .to(AllergyIntoleranceApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("MedicationStatementSubstanceApiAdapter")
    .to(MedicationStatementSubstanceApiAdapter)
    .inSingletonScope();