import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import { Container } from "@DiContainer";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import IScreenRegistry, { IShowScreenActionDescriptor } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import UseCases from "@Primitives/UseCases";
import ShowSampleScreenAction from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/FrontendActions/Screens/ShowSampleScreenAction";
import SampleScreen from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/FrontendActions/Screens/SampleScreen";
import ShowSampleMdScreenAction from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/FrontendActions/Screens/ShowSampleMdScreenAction";
import SampleMdScreen from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/FrontendActions/Screens/SampleMdScreen";
import GlobalActionRegistry from "@Toolkit/ReactClient/ActionProcessing/GlobalActionRegistry";
import PatientScreenContextData from "@HisPlatform/Services/Definition/ActionProcessing/PatientScreenContextData";
import CareScreenContextData from "@HisPlatform/Services/Definition/ActionProcessing/CareScreenContextData";
import createHisDashboardScreenSidebar from "@HisPlatform/Components/ShowScreenAction/ScreenDashboardFactory";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import { configureFrontendActionMappings } from "@HisPlatform/Packages/Care/FrontendActions/MapperConfigurations.g";
import { configureFrontendListMappings } from "@HisPlatform/BoundedContexts/WebAppBackend/FrontendListMapper";
import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import ShowEditPatientAdmissionDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEditPatientAdmissionDataScreenAction.g";
import ShowCareActivityBaseDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityBaseDataScreenAction.g";
import ShowReadOnlyCareActivityBaseDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyCareActivityBaseDataScreenAction.g";
import ShowEhrCareActivityBaseDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEhrCareActivityBaseDataScreenAction.g";
import PatientMainMenuSideBar from "@HisPlatform/Components/Pages/Patient/PatientMainPage/View/PatientMainMenuSideBar";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ShowCreateNewEpisodeOfCareScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCreateNewEpisodeOfCareScreenAction.g";
import EpisodeOfCareScreen from "@HisPlatform/Packages/Care/Screens/EpisodeOfCareScreen/EpisodeOfCareScreen";
import ShowEpisodeOfCareScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEpisodeOfCareScreenAction.g";
import SaveCareActivityBaseDataAction from "@HisPlatform/Packages/Care/FrontendActions/SaveCareActivityBaseDataAction.g";
import AdmitPatientAction from "@HisPlatform/Packages/Care/FrontendActions/AdmitPatientAction.g";
import DischargePatientAction from "@HisPlatform/Packages/Care/FrontendActions/DischargePatientAction.g";
import AdmitPatientAndCareActivityBaseDataScreen from "@HisPlatform/Packages/Care/Screens/AdmitPatientAndCareActivityBaseDataScreen/AdmitPatientAndCareActivityBaseDataScreen";
import ShowDischargePatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowDischargePatientScreenAction.g";
import ShowReadOnlyDischargePatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyDischargePatientScreenAction.g";
import ShowEhrDischargePatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEhrDischargePatientScreenAction.g";
import DischargePatientScreen from "@HisPlatform/Packages/Care/Screens/DischargePatientScreen/DischargePatientScreen";
import ShowCareActivityTextBlockListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityTextBlockListScreenAction.g";
import CareActivityTextBlockListPanelScreen from "@HisPlatform/Packages/Care/Screens/CareActivityTextBlockListPanelScreen/CareActivityTextBlockListPanelScreen";
import ShowCareActivityTextBlockScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityTextBlockScreenAction.g";
import CareActivityTextBlockScreen from "@HisPlatform/Packages/Care/Screens/CareActivityTextBlockScreen/CareActivityTextBlockScreen";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowNewCareWorklistScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewCareWorklistScreenAction.g";
import CareWorklistScreen from "@HisPlatform/Packages/Care/Screens/CareWorklistScreen/CareWorklistScreen";
import ModalServiceRegistry from "@Toolkit/ReactClient/Components/ModalService/ModalServiceRegistry";
import EpisodeOfCareDialogParams from "@HisPlatform/Packages/Care/Screens/EpisodeOfCareScreen/Modal/EpisodeOfCareDialogParams";
import EpisodeOfCareDialog from "@HisPlatform/Packages/Care/Screens/EpisodeOfCareScreen/Modal/EpisodeOfCareDialog";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import { EpisodeOfCareSelectBoxRegistryItem } from "@HisPlatformControls/EpisodeOfCareSelectBox/EpisodeOfCareSelectBoxFormCustomBlock";
import ShowCareActivitiesForEpisodeOfCareScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivitiesForEpisodeOfCareScreenAction.g";
import ShowPerformedServicesScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowPerformedServicesScreenAction.g";
import PerformedServicesScreen from "@HisPlatform/Packages/Care/Screens/PerformedServicesScreen/PerformedServicesScreen";
import ShowReadOnlyPerformedServicesScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyPerformedServicesScreenAction.g";
import SavePerformedServicesAction from "@HisPlatform/Packages/Care/FrontendActions/SavePerformedServicesAction.g";
import { PatientIdentifierRegistryItem } from "@HisPlatform/Packages/Patients/Components/PatientIdentifier/PatientIdentifierFormCustomBlock";
import IFormLogicRegistry from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";
import AdmitPatientAndCareActivityBaseDataScreenFormOutpatientBaseFormLogic from "@HisPlatform/Packages/Care/FormLogics/AdmitPatientAndCareActivityBaseDataScreenFormOutpatientBaseFormLogic";
import EpisodeOfCareScreenFormBaseFormLogic from "@HisPlatform/Packages/Care/FormLogics/EpisodeOfCareScreenFormBaseFormLogic";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import React from "react";
import EpisodeOfCareStatusColumn from "@HisPlatform/Packages/Care/Components/EpisodeOfCareStatusColumn";
import ShowGlobalEpisodeOfCareListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowGlobalEpisodeOfCareListScreenAction.g";
import GlobalEpisodeOfCareListScreen from "@HisPlatform/Packages/Care/Screens/GlobalEpisodeOfCareListScreen/GlobalEpisodeOfCareListScreen";
import PatientEpisodeOfCareListScreen from "@HisPlatform/Packages/Care/Screens/PatientEpisodeOfCareListScreen/PatientEpisodeOfCareListScreen";
import ShowSurgeryListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowSurgeryListScreenAction.g";
import ShowSurgeryTypeDefinitionsScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowSurgeryTypeDefinitionsScreenAction.g";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import NavigateToRouteActionProcessor from "@HisPlatform/Services/Implementation/ActionProcessing/NavigateToRouteActionProcessor";
import { getNavigationMenuItems } from "@HisPlatform/Packages/Care/Configuration/NavigationMenuItems";
import ShowPatientMovedCareActivitiesAction from "@HisPlatform/Packages/Care/FrontendActions/ShowPatientMovedCareActivitiesAction.g";
import PatientMovedCareActivitiesScreen from "@HisPlatform/Packages/Care/Screens/PatientMovedCareActivitiesScreen/PatientMovedCareActivitiesScreen";
import ShowPatientEpisodeOfCareListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowPatientEpisodeOfCareListScreenAction.g";
import CareActivitiesForEpisodeOfCareScreen from "@HisPlatform/Packages/Care/Screens/CareActivitiesForEpisodeOfCareScreen/CareActivitiesForEpisodeOfCareScreen";
import CareContactTypeColumn from "@HisPlatform/Packages/Care/Components/CareContactTypeColumn";
import CareActivityGuidanceHeader from "@HisPlatform/Packages/Guidance/Components/CareActivityGuidanceHeader/CareActivityGuidanceHeader";
import ShowDiagnosisScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowDiagnosisScreenAction.g";
import OutpatientTreatmentRoutes from "@HisPlatform/Components/Pages/OutpatientTreatment/OutpatientTreatmentRoutes";
import ShowCareActivityDocumentListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityDocumentListScreenAction.g";
import IFormExtensionRegistry from "@PluginInterface/FormExtension/IFormExtensionRegistry";
import AdmitPatientAndCareActivitiyBaseDataScreenExtension from "@HisPlatform/Packages/Care/FormExtensions/AdmitPatientAndCareActivitiyBaseDataScreenExtension";
import ShowReadOnlyDiagnosisScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyDiagnosisScreenAction.g";
import DependencyContainer from "@DiContainer";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import CareActivitiesForEpisodeOfCareListScreenApiAdapter from "@HisPlatform/Packages/Care/Screens/CareActivitiesForEpisodeOfCareScreen/CareActivitiesForEpisodeOfCareListScreenApiAdapter";
import DiagnosisListScreen from "@HisPlatform/Packages/Care/Screens/DiagnosisListScreen/DiagnosisListScreen";
import CareActivityDocumentListScreen from "@HisPlatform/Packages/Care/Screens/CareActivityDocumentListScreen/CareActivityDocumentListScreen";
import ShowCareActivityTabbedScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityTabbedScreenAction.g";
import ShowReadOnlyCareActivityDocumentListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyCareActivityDocumentListScreenAction.g";
import HomeMenuApiAdapter from "@HisPlatform/Packages/Care/Components/HomeMenu/HomeMenuApiAdapter";
import ShowCareWorklistScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareWorklistScreenAction.g";
import { ShowCareWorklistScreenActionProcessor } from "@HisPlatform/Packages/Care/Configuration/ShowCareWorklistScreenActionProcessor";
import PatientId from "@Primitives/PatientId.g";

class CarePackageInitializer implements IPackageInitializer {
    public configureDependencies(container: Container): void {
        // Moved DI registrations to WebAppBackendDependencyConfiguration to mitigate a DI error
        container.bind("CareActivitiesForEpisodeOfCareListScreenApiAdapter")
            .to(CareActivitiesForEpisodeOfCareListScreenApiAdapter)
            .inSingletonScope();

        container.bind("HomeMenuApiAdapter")
            .to(HomeMenuApiAdapter)
            .inSingletonScope();
    }

    public configureNData?(configurator: INDataAttributeConfigurator): void {
        configurator.registerColumn("EpisodeOfCareStatus", <EpisodeOfCareStatusColumn />);
        configurator.registerColumn("CareContactType", <CareContactTypeColumn />);
    }

    public configureFormLogics(registry: IFormLogicRegistry): void {
        registry.register("AdmitPatientAndCareActivityBaseDataScreenForm_Outpatient",
            "AdmitPatientAndCareActivityBaseDataScreenFormOutpatientBaseFormLogic");

        registry.register("EpisodeOfCareScreenForm",
            "EpisodeOfCareScreenFormBaseFormLogic");
    }

    public configureMapper(configurator: IMapperConfigurator): void {
        const currentCultureProvider = DependencyContainer.get<ICurrentCultureProvider>("ICurrentCultureProvider");

        configureFrontendActionMappings(configurator);
        configureFrontendListMappings(configurator, currentCultureProvider);
    }

    public configureModals(modalServiceRegistry: ModalServiceRegistry) {
        modalServiceRegistry.register(EpisodeOfCareDialogParams.type, EpisodeOfCareDialog);
    }

    public configureFormEngine(registry: FormEditorRegistry) {
        registry.registerCustomBlock("EpisodeOfCareSelectBox", EpisodeOfCareSelectBoxRegistryItem);
        registry.registerCustomBlock("PatientIdentifier", PatientIdentifierRegistryItem);

        registry.registerEntityReferenceEditor(
            "EpisodeOfCare",
            {
                displayName: "EpisodeOfCare - DO NOT USE",
                componentType: null,
                entityIdCtor: EpisodeOfCareId,
                loadReferenceDataAsync: async (id: EpisodeOfCareId | EpisodeOfCareId[]) => { }
            }
        );

        registry.registerEntityReferenceEditor(
            "Patient",
            {
                displayName: "Patient - DO NOT USE",
                componentType: null,
                entityIdCtor: PatientId,
                loadReferenceDataAsync: async (id: PatientId | PatientId[]) => { }
            }
        );
    }

    public configureFormExtensions(formExtensionRegistry: IFormExtensionRegistry): void {
        formExtensionRegistry.register("AdmitPatientAndCareActivityBaseDataScreen", "AdmitPatientAndCareActivitiyBaseDataScreenExtension");
    }

    public configureScreens(registry: IScreenRegistry): void {
        registry.register<ShowNewAdmitPatientScreenAction>(
            ShowNewAdmitPatientScreenAction.id,
            this.getAdmitPatientAndCareActivityBaseDataScreenDescriptor(UseCases.patientRegisterAdmitPatient)
        );
        registry.register<ShowEditPatientAdmissionDataScreenAction>(
            ShowEditPatientAdmissionDataScreenAction.id,
            this.getAdmitPatientAndCareActivityBaseDataScreenDescriptor(UseCases.careRegisterEditAdmissionData)
        );
        registry.register<ShowCareActivityBaseDataScreenAction>(
            ShowCareActivityBaseDataScreenAction.id,
            this.getAdmitPatientAndCareActivityBaseDataScreenDescriptor(UseCases.careRegisterRecordCareBaseData),
            { iconName: "pen" }
        );
        registry.register<ShowReadOnlyCareActivityBaseDataScreenAction>(
            ShowReadOnlyCareActivityBaseDataScreenAction.id,
            this.getAdmitPatientAndCareActivityBaseDataScreenDescriptor(UseCases.careRegisterViewReadOnlyCareBaseData),
            { iconName: "pen" }
        );
        registry.register<ShowEhrCareActivityBaseDataScreenAction>(
            ShowEhrCareActivityBaseDataScreenAction.id,
            this.getAdmitPatientAndCareActivityBaseDataScreenDescriptor(UseCases.careRegisterRecordCareBaseData),
            { iconName: "pen" }
        );

        registry.register<ShowDischargePatientScreenAction>(
            ShowDischargePatientScreenAction.id,
            this.getDischargePatientScreenDescriptor(UseCases.careRegisterDischargePatient)
        );
        registry.register<ShowReadOnlyDischargePatientScreenAction>(
            ShowReadOnlyDischargePatientScreenAction.id,
            this.getDischargePatientScreenDescriptor(UseCases.careRegisterViewReadOnlyDischargePatient)
        );
        registry.register<ShowEhrDischargePatientScreenAction>(
            ShowEhrDischargePatientScreenAction.id,
            this.getDischargePatientScreenDescriptor(UseCases.careRegisterDischargePatient)
        );

        registry.register<ShowPatientEpisodeOfCareListScreenAction>(
            ShowPatientEpisodeOfCareListScreenAction.id,
            {
                component: PatientEpisodeOfCareListScreen,
                getScreenContextData: (a: ShowPatientEpisodeOfCareListScreenAction) => new PatientScreenContextData(a.patientId),
                sidebarComponent: PatientMainMenuSideBar
            },
            {
                displayName: StaticWebAppResources.PatientBaseData.Menu.EpisodesOfCare,
                iconName: "episode_of_care"
            }
        );

        registry.register<ShowGlobalEpisodeOfCareListScreenAction>(
            ShowGlobalEpisodeOfCareListScreenAction.id,
            {
                component: GlobalEpisodeOfCareListScreen,
            },
            {
                displayName: StaticWebAppResources.PatientBaseData.Menu.EpisodesOfCare,
                iconName: "episode_of_care"
            }
        );

        registry.register<ShowCreateNewEpisodeOfCareScreenAction>(
            ShowCreateNewEpisodeOfCareScreenAction.id,
            {
                component: EpisodeOfCareScreen as any
            },
            {
                displayName: StaticWebAppResources.Common.Button.Add,
                iconName: "plus"
            }
        );

        registry.register<ShowEpisodeOfCareScreenAction>(
            ShowEpisodeOfCareScreenAction.id,
            {
                component: EpisodeOfCareScreen
            } as any,
            {
                iconName: "pen"
            }
        );

        registry.register<ShowCareActivitiesForEpisodeOfCareScreenAction>(
            ShowCareActivitiesForEpisodeOfCareScreenAction.id,
            {
                component: CareActivitiesForEpisodeOfCareScreen
            },
            {
                iconName: "road"
            }
        );

        registry.register<ShowSampleScreenAction>(
            ShowSampleScreenAction.id,
            {
                component: SampleScreen,
                getScreenContextData: _ => null,
                onSavedExisting: (e) => {
                    console.log("ShowSampleScreenAction saving");
                    e.continue?.();
                    console.log("ShowSampleScreenAction saved");
                    e.close();
                    console.log("ShowSampleScreenAction closed");
                }
            }
        );

        registry.register<ShowSampleMdScreenAction>(
            ShowSampleMdScreenAction.id,
            {
                component: SampleMdScreen,
                getScreenContextData: _ => null,
                onSavedExisting: (e) => {
                    console.log("ShowSampleMdScreenAction saving");
                    e.continue?.();
                    console.log("ShowSampleMdScreenAction saved");
                    e.close();
                    console.log("ShowSampleMdScreenAction closed");
                }
            }
        );

        registry.register<ShowCareActivityTextBlockListScreenAction>(
            ShowCareActivityTextBlockListScreenAction.id,
            {
                topToolbarComponent: CareActivityGuidanceHeader as any,
                component: CareActivityTextBlockListPanelScreen,
                getScreenContextData: (a: ShowCareActivityTextBlockListScreenAction) => new CareScreenContextData(a.careActivityId),
                sidebarComponent: createHisDashboardScreenSidebar(UseCases.careRegisterEditTextBlocks)
            },
            { iconName: "document" }
        );

        registry.register<ShowCareActivityTextBlockScreenAction>(
            ShowCareActivityTextBlockScreenAction.id,
            {
                topToolbarComponent: CareActivityGuidanceHeader as any,
                component: CareActivityTextBlockScreen,
                getScreenContextData: (a: ShowCareActivityTextBlockScreenAction) =>
                    a.displayMode !== ScreenDisplayMode.Detail
                        ? new CareScreenContextData(a.careActivityId)
                        : null,
                sidebarComponent: createHisDashboardScreenSidebar(UseCases.careRegisterEditTextBlocks)
            },
            { iconName: "document" }
        );

        registry.register<ShowNewCareWorklistScreenAction>(
            ShowNewCareWorklistScreenAction.id,
            {
                component: CareWorklistScreen,
                sidebarComponent: createHisDashboardScreenSidebar(UseCases.careRegisterDailyPatientList)
            }
        );

        registry.register<ShowPerformedServicesScreenAction>(
            ShowPerformedServicesScreenAction.id,
            {
                topToolbarComponent: CareActivityGuidanceHeader as any,
                component: PerformedServicesScreen,
                getScreenContextData: (a: ShowPerformedServicesScreenAction) => new CareScreenContextData(a.careActivityId),
                sidebarComponent: createHisDashboardScreenSidebar(UseCases.careRegisterEditTextBlocks)
            }
        );

        registry.register<ShowReadOnlyPerformedServicesScreenAction>(
            ShowReadOnlyPerformedServicesScreenAction.id,
            {
                topToolbarComponent: CareActivityGuidanceHeader as any,
                component: PerformedServicesScreen,
                getScreenContextData: (a: ShowReadOnlyPerformedServicesScreenAction) => new CareScreenContextData(a.careActivityId),
                sidebarComponent: createHisDashboardScreenSidebar(UseCases.careRegisterEditTextBlocks)
            }
        );

        registry.register<ShowPatientMovedCareActivitiesAction>(
            ShowPatientMovedCareActivitiesAction.id,
            {
                component: PatientMovedCareActivitiesScreen,
                getScreenContextData: (a: ShowPatientMovedCareActivitiesAction) => new PatientScreenContextData(a.keptPatientId)
            }
        );

        registry.register<ShowDiagnosisScreenAction>(
            ShowDiagnosisScreenAction.id,
            {
                topToolbarComponent: CareActivityGuidanceHeader as any,
                component: DiagnosisListScreen,
                getScreenContextData: (a: ShowDiagnosisScreenAction) => new CareScreenContextData(a.careActivityId),
                sidebarComponent: createHisDashboardScreenSidebar(UseCases.careRegisterRecordDiagnosis)
            }
        );

        registry.register<ShowDiagnosisScreenAction>(
            ShowReadOnlyDiagnosisScreenAction.id,
            {
                topToolbarComponent: CareActivityGuidanceHeader as any,
                component: DiagnosisListScreen,
                getScreenContextData: (a: ShowDiagnosisScreenAction) => new CareScreenContextData(a.careActivityId),
                sidebarComponent: createHisDashboardScreenSidebar(UseCases.careRegisterRecordDiagnosis)
            });

        registry.register<ShowCareActivityDocumentListScreenAction>(
            ShowCareActivityDocumentListScreenAction.id,
            {
                topToolbarComponent: CareActivityGuidanceHeader as any,
                component: CareActivityDocumentListScreen,
                getScreenContextData: (a: ShowCareActivityDocumentListScreenAction) => new CareScreenContextData(a.careActivityId),
                sidebarComponent: createHisDashboardScreenSidebar(UseCases.careRegisterEditDocuments)
            }
        );

        registry.register<ShowReadOnlyCareActivityDocumentListScreenAction>(
            ShowReadOnlyCareActivityDocumentListScreenAction.id,
            {
                topToolbarComponent: CareActivityGuidanceHeader as any,
                component: CareActivityDocumentListScreen,
                getScreenContextData: (a: ShowReadOnlyCareActivityDocumentListScreenAction) => new CareScreenContextData(a.careActivityId),
                sidebarComponent: createHisDashboardScreenSidebar(UseCases.careRegisterEditDocuments)
            }
        );
    }

    public configureActionProcessor(registry: GlobalActionRegistry) {
        registry.register(SaveCareActivityBaseDataAction.id, null);
        registry.register(AdmitPatientAction.id, null);
        registry.register(DischargePatientAction.id, null);
        registry.register(SavePerformedServicesAction.id, null);

        registry.register(
            ShowSurgeryListScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => ApplicationRoutes.surgery.makeRoute()));

        registry.register(
            ShowSurgeryTypeDefinitionsScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => ApplicationRoutes.surgeryTypeDefinitions.makeRoute()));

        registry.registerConditionalDisplaySettings<ShowCareActivityTabbedScreenAction>(ShowCareActivityTabbedScreenAction.id, action => ({
            iconName: action.definitionCode === "CareActivityOfEpisodeOfCare" ? "pen" : undefined
        }));

        registry.register(
            ShowCareWorklistScreenAction.id,
            ShowCareWorklistScreenActionProcessor.createFactory()
        );
    }

    private getAdmitPatientAndCareActivityBaseDataScreenDescriptor<TAction extends ShowNewAdmitPatientScreenAction | ShowEditPatientAdmissionDataScreenAction | ShowCareActivityBaseDataScreenAction | ShowEhrCareActivityBaseDataScreenAction>(useCase: string) {

        return {
            component: AdmitPatientAndCareActivityBaseDataScreen as any,
            getScreenContextData: (a: TAction) => {

                if (a instanceof ShowNewAdmitPatientScreenAction) {
                    return new PatientScreenContextData(a.patientId);
                }
                return new CareScreenContextData((a as any).careActivityId);
            },
            sidebarComponent: createHisDashboardScreenSidebar(useCase),
            topToolbarComponent: CareActivityGuidanceHeader as any,
            onSavedNew: (newId: StringEntityId, e: any) => {
                e.temporary_routingStore.push(ApplicationRoutes.home.makeRoute());
            }
        } as IShowScreenActionDescriptor<TAction>;
    }

    private getDischargePatientScreenDescriptor<TAction extends ShowDischargePatientScreenAction |
        ShowReadOnlyDischargePatientScreenAction |
        ShowEhrDischargePatientScreenAction>(useCase: string): IShowScreenActionDescriptor<TAction> {

        return {
            topToolbarComponent: CareActivityGuidanceHeader as any,
            component: DischargePatientScreen as any,
            getScreenContextData: (a: TAction) => {
                return new CareScreenContextData((a as any).careActivityId);
            },
            sidebarComponent: createHisDashboardScreenSidebar(useCase)
        };
    }

    public configureDynamicNavigation(registry: IDynamicNavigationRegistry): void {
        getNavigationMenuItems().forEach(navigationItem => registry.register(navigationItem));
    }
}

export default new CarePackageInitializer();
