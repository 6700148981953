import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/Api/Proxy.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import EhiToken from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiToken";
import EhiTokenStoreMapper from "./EhiTokenStoreMapper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IEhiLoginResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/IEhiLoginResult";
import Di from "@Di";
import { GetTypeNameWithPrefix } from "@HunEHealthInfrastructurePlugin/PluginHelper";
import { lazyResolve } from "@DiContainer";
import EidRidToken from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EidRidToken";
import moment from "moment";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";

@Di.injectable()
export default class HunEHealthInfrastructureUserManagementApiAdapter extends ApiAdapterBase {

    @lazyResolve(GetTypeNameWithPrefix("IUserManagementClient")) private apiClient: Proxy.IUserManagementClient;
    @lazyResolve(GetTypeNameWithPrefix("EhiTokenStoreMapper")) private ehiTokenStoreMapper: EhiTokenStoreMapper;
    
    @State.bound
    public getEidRidTokenAsync() {
        return this.processOperationAsync(
            new SimpleStore<EidRidToken>(),
            async target => {
                const response = await this.apiClient.getEidRidTokenQueryAsync(CreateRequestId());
                target.operationInfo = createOperationInfo(response);
                if (response.ridToken) {
                    target.value = new EidRidToken(
                        response.ridToken.eidGuid,
                        response.ridToken.rid,
                        response.ridToken.requestedAt
                    );
                }
            }
        );
    }

    @State.bound
    public loginWithOtpAsync(userIdentifier: string, password: string, otp: string) {
        return this.processOperationAsync(
            new SimpleStore<IEhiLoginResult>(),
            async target => {
                const response = await this.apiClient.loginToEhiWithOtpCommandAsync(CreateRequestId(), new Proxy.LoginToEhiWithOtpControllerDto({
                    ehiUserIdentifier: userIdentifier,
                    oneTimePassword: otp,
                    password: password
                }));

                if (response && response.result) {
                    target.value = {
                        wasSuccessful: response.result.wasSuccessful,
                        error: response.result.error,
                        token: null
                    };
                    if (response.result.token) {
                        target.value.token = new EhiToken();
                        this.ehiTokenStoreMapper.applyToStore(target.value.token, response);
                    }
                }

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public loginWithMobileAsync(userIdentifier: string, otp: string) {
        return this.processOperationAsync(
            new SimpleStore<IEhiLoginResult>(),
            async target => {
                const response = await this.apiClient.loginToEhiWithMobileCommandAsync(CreateRequestId(), new Proxy.LoginToEhiWithMobileControllerDto({                    
                    ehiUserIdentifier: userIdentifier,
                    oneTimePassword: otp
                }));

                if (response && response.result) {
                    target.value = {
                        wasSuccessful: response.result.wasSuccessful,
                        error: response.result.error,
                        token: null
                    };
                    if (response.result.token) {
                        target.value.token = new EhiToken();
                        this.ehiTokenStoreMapper.applyToStore(target.value.token, response);
                    }
                }

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public loginWithEidAsync(eidGuid: string, requestedAt: moment.Moment, token: string, userIdentifier: string) {
        return this.processOperationAsync(
            new SimpleStore<IEhiLoginResult>(),
            async target => {
                const response = await this.apiClient.loginToEhiWithEidCommandAsync(CreateRequestId(), new Proxy.LoginToEhiWithEidControllerDto({
                    eidGuid: eidGuid,
                    requestedAt: requestedAt,
                    token: token,
                    ehiUserIdentifier: userIdentifier
                }));

                if (response && response.result) {
                    target.value = {
                        wasSuccessful: response.result.wasSuccessful,
                        error: response.result.error,
                        token: null
                    };
                    if (response.result.token) {
                        target.value.token = new EhiToken();
                        this.ehiTokenStoreMapper.applyToStore(target.value.token, response);
                    }
                }

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public logoutAsync(tokenValue?: string) {
        return this.processOperationAsync(
            new SimpleStore<boolean>(),
            async target => {
                const result = await this.apiClient.logoutOfEhiCommandAsync(CreateRequestId(), new Proxy.LogoutOfEhiControllerDto({
                    ehiTokenValue: tokenValue
                }));
                target.operationInfo = createOperationInfo(result);
            }
        );
    }
}