import UserGroupId from "@Primitives/UserGroupId.g";
import UserId from "@Primitives/UserId.g";
import * as Proxy from "@HisPlatform/BoundedContexts/Authorization/Api/Proxy.g";
import RoleAccessRule from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/RoleAccessRule";
import AccessRuleBase from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/AccessRuleBase";
import PermissionAssignmentAccessRule from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/PermissionAssignmentAccessRule";
import IPermissionDefinition from "@PluginInterface/OperationAccessControl/IPermissionDefinition";
import IPermissionScope from "@PluginInterface/OperationAccessControl/IPermissionScope";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import UserGroup from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroup";

export function getAccessRuleDto(entityId: UserGroupId | UserId) {
    const result = new Proxy.GetAccessRulesControllerDto({
        subject: mapSubject(entityId)
    });

    return result;
}

export function getSetAccessRuleDto(accessRules: AccessRuleBase[], isValidateOnly?: boolean) {
    return new Proxy.SetAccessRulesControllerDto({
        accessRules: accessRules.map(mapAccessRule),
        isValidateOnly: isValidateOnly
    });
}

export function getDeleteAccessRuleDto(accessRule: AccessRuleBase) {
    return new Proxy.DeleteAccessRulesControllerDto({
        accessRule: mapAccessRule(accessRule)
    });
}

function mapAccessRule(accessRule: AccessRuleBase) {
    if (accessRule instanceof RoleAccessRule) {
        return mapRoleAccessRule(accessRule);
    } else if (accessRule instanceof PermissionAssignmentAccessRule) {
        return mapPermissionAssignmentAccessRule(accessRule);
    } else {
        throw new Error(`Cannot parse type: ${typeof accessRule}!`);
    }
}

function mapRoleAccessRule(accessRule: RoleAccessRule) {
    return new Proxy.RoleAssignmentRuleDto({
        roleId: accessRule.roleId,
        assignmentScopes: accessRule.assignedScopes.map(mapPermissionScope),
        accessRuleId: accessRule.id,
        rowVersion: accessRule.rowVersion ?? new RowVersion(-1),
        subject: mapSubject(accessRule.subject)
    });
}

function mapPermissionAssignmentAccessRule(accessRule: PermissionAssignmentAccessRule) {
    if (accessRule.isProhibition) {
        return mapPermissionProhibition(accessRule);
    } else {
        return mapPermissionAssignment(accessRule);
    }
}

function mapPermissionProhibition(accessRule: PermissionAssignmentAccessRule) {
    return new Proxy.PermissionProhibitionRuleDto({
        subject: mapSubject(accessRule.subject),
        rowVersion: accessRule.rowVersion ?? new RowVersion(-1),
        accessRuleId: accessRule.id,
        permissions: mapPermission(accessRule.permissionStore.getPermissionsWithUnitedScopes())
    });
}

function mapPermissionAssignment(accessRule: PermissionAssignmentAccessRule) {
    return new Proxy.PermissionAssignmentRuleDto({
        subject: mapSubject(accessRule.subject),
        rowVersion: accessRule.rowVersion ?? new RowVersion(-1),
        accessRuleId: accessRule.id,
        permissions: mapPermission(accessRule.permissionStore.getPermissionsWithUnitedScopes())
    });
}

function mapSubject(subject: UserId | UserGroupId) {
    if (subject instanceof UserId) {
        if (subject.value === "new") {
            return new Proxy.UserSubjectDto({
                userId: new UserId("-1")
            });
        }
        
        return new Proxy.UserSubjectDto({ userId: subject });
    } else {
        if (subject.value === "new") {
            return new Proxy.UserGroupSubjectDto({
                userGroupId: new UserGroupId("-1")
            });
        }
        
        return new Proxy.UserGroupSubjectDto({ userGroupId: subject });
    }
}

function mapPermission(permissions: IPermissionDefinition[]) {
    const result: Proxy.Permission[] = [];
    permissions.forEach(p => {
        const scopes = p.scopes.map(mapPermissionScope);
        result.push(
            new Proxy.Permission({
                id: p.ids[0],
                scopes: scopes
            })
        );
    });

    return result;
}

function mapPermissionScope(permissionScope: IPermissionScope) {
    return new Proxy.PermissionScope({
        type: new Proxy.PermissionScopeType({ value: permissionScope.type }),
        values: new Proxy.SetSpecificationOfString({
            mode: permissionScope.mode === "positive" ? Proxy.SetSpecificationMode.Positive : Proxy.SetSpecificationMode.Negative,
            includedItems: permissionScope.mode === "positive" ? permissionScope.includedValues : null,
            excludedItems: permissionScope.mode === "negative" ? permissionScope.excludedValues : null,
        })
    });
}
