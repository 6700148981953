import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import MedicalAlertStatementId from "@Primitives/MedicalAlertStatementId.g";
import MedicalAlertStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalAlertStatement/MedicalAlertStatementStore";
import MedicalAlertStatementStoreMapper from "./MedicalAlertStatementStoreMapper";
import { getAddMedicalAlertStatementControllerDto, getUpdateMedicalAlertStatementControllerDto, getDeleteMedicalAlertStatementControllerDto, getDeleteMedicalAlertStatementHistoryItemControllerDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalAlertStatement/MedicalAlertStatementDtoMapper";
import PatientId from "@Primitives/PatientId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";

@Di.injectable()
export default class MedicalAlertStatementApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IMedicalAlertStatementClient") private readonly apiClient: Proxy.IMedicalAlertStatementClient,
        @Di.inject("MedicalAlertStatementStoreMapper") private readonly storeMapper: MedicalAlertStatementStoreMapper
    ) {
        super();
    }

    public getByIdAsync(medicalAlertStatementId: MedicalAlertStatementId, requestLock: boolean): Promise<MedicalAlertStatementStore> {
        return this.processOperationAsync(
            new MedicalAlertStatementStore(false),
            async target => {
                const response = await this.apiClient.getMedicalAlertStatementByIdCommandAsync(
                    CreateRequestId(),
                    new Proxy.GetMedicalAlertStatementByIdControllerDto({ medicalAlertStatementId: medicalAlertStatementId, requestLock: requestLock })
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public addMedicalAlertStatementAsync(store: MedicalAlertStatementStore, patientId: PatientId, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new MedicalAlertStatementStore(false),
            async target => {
                const response = await this.apiClient.addMedicalAlertStatementCommandAsync(
                    CreateRequestId(),
                    getAddMedicalAlertStatementControllerDto(store, patientId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public updateMedicalAlertStatementAsync(store: MedicalAlertStatementStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new MedicalAlertStatementStore(false),
            async target => {
                const response = await this.apiClient.updateMedicalAlertStatementCommandAsync(
                    CreateRequestId(),
                    getUpdateMedicalAlertStatementControllerDto(store, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deleteMedicalAlertStatementHistoryItemAsync(store: MedicalAlertStatementStore, versionNumber: number, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new MedicalAlertStatementStore(false),
            async target => {
                const response = await this.apiClient.deleteMedicalAlertStatementHistoryItemCommandAsync(
                    CreateRequestId(),
                    getDeleteMedicalAlertStatementHistoryItemControllerDto(store, versionNumber, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deleteMedicalAlertStatementAsync(store: MedicalAlertStatementStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<any>(),
            async target => {
                const response = await this.apiClient.deleteMedicalAlertStatementCommandAsync(
                    CreateRequestId(),
                    getDeleteMedicalAlertStatementControllerDto(store),
                    isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public validateAsync(store: MedicalAlertStatementStore, patientId: PatientId) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response =
                    store.isNew ?
                        await this.apiClient.addMedicalAlertStatementCommandAsync(CreateRequestId(), getAddMedicalAlertStatementControllerDto(store, patientId, true)) :
                        await this.apiClient.updateMedicalAlertStatementCommandAsync(CreateRequestId(), getUpdateMedicalAlertStatementControllerDto(store, true, store.lockInfo?.lockId, false));

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    public async checkPermissionForAddNewItemAsync(store: MedicalAlertStatementStore, patientId: PatientId): Promise<MedicalAlertStatementStore> {
        return await this.addMedicalAlertStatementAsync(store, patientId, true);        
    }

    public async checkPermissionForUpdateItemAsync(store: MedicalAlertStatementStore): Promise<MedicalAlertStatementStore> {
        return await this.updateMedicalAlertStatementAsync(store, true);
    }

    public async checkPermissionForDeleteItemAsync(store: MedicalAlertStatementStore): Promise<SimpleStore> {
        return await this.deleteMedicalAlertStatementAsync(store, true);
    }
}
