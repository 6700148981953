import BusinessErrorBase from "./BusinessErrorBase";

export default class UnknownBusinessError extends BusinessErrorBase {

    public get name() {
        return this._name;
    }

    constructor(
        private readonly _name: string
    ) {
        super();
    }
}