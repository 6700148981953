import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import * as Ui from "@CommonControls";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import ResourceBlocking from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ResourceBlocking";

interface IResourceBlockingTooltipProps {
    resourceBlocking: ResourceBlocking;
}

@State.observer
export default class ResourceBlockingTooltip extends React.Component<IResourceBlockingTooltipProps> {
    @State.computed private get interval() {
        const duration =
            this.props.resourceBlocking.dateWithTimeRange.toMomentRange.to.diff(this.props.resourceBlocking.dateWithTimeRange.toMomentRange.from, "minute");
        return formatStringWithObjectParams(StaticSchedulingResources.PractitionerAppointments.tooltipDate,
            { Interval: `${this.props.resourceBlocking.dateWithTimeRange.toMomentRange.from.format("LLLL")} - ${this.props.resourceBlocking.dateWithTimeRange.toMomentRange.to.format("LT")}`, Duration: duration.toString() });
    }

    private renderTooltipRow(label: string, data: string, code?: string) {
        return (
            <Ui.Flex verticalSpacing="none">
                <Ui.Flex.Item xs={5}>{label}:</Ui.Flex.Item>
                <Ui.Flex.Item xs={7}>{!!code && <b>{code} </b>}{data}</Ui.Flex.Item>
            </Ui.Flex>
        );
    }
    public render() {
        return (
            <>
                {this.props.resourceBlocking &&
                    <div>
                        {this.renderTooltipRow(StaticSchedulingResources.ResourceBlockingEditorDialog.Description, this.props.resourceBlocking.description)}
                        {this.renderTooltipRow(StaticSchedulingResources.RegisteredPatientAppointmentListPanel.IntervalColumn, this.interval)}
                    </div>
                }
            </>
        );
    }
}
