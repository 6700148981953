import React from "react";
import ScalarProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/ScalarProperty";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface IScalarPropertyEditorProps {
    row: ScalarProperty;
}

@State.observer
export class ScalarPropertyEditor extends React.Component<IScalarPropertyEditorProps> {

    @State.computed private get currentValue() {
        return this.props.row.getValue(this.props.row.isTemporal ? LocalDate.today() : undefined);
    }

    public render() {
        return (
            <Ui.Flex>
                <Ui.Flex.Item sm={12}>
                    <Ui.TextBox
                        value={this.currentValue}
                        onChange={this.setCurrentVersionValue}
                        automationId={this.props.row.name} />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.bound
    private setCurrentVersionValue(newValue: string) {
        this.props.row.replaceVersion(newValue, this.props.row.isTemporal ? LocalDate.today() : undefined);
    }
}