// tslint:disable:variable-name
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import CareActivityMenuStore from "./CareActivityMenuStore";

const { 
    ContextComponent: CareActivityMenuStoreContext, 
    StoreProvider: CareActivityMenuStoreProvider, 
    useStore: useCareActivityMenuStore,
    provideStore: provideCareActivityMenuStore,
} = createPanelStoreProvider(CareActivityMenuStore);

export {
    CareActivityMenuStoreContext,
    CareActivityMenuStoreProvider,
    useCareActivityMenuStore,
    provideCareActivityMenuStore
};