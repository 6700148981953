import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";
import ShowRolesScreenAction from "@HisPlatform/Packages/Authorization/FrontendActions/ShowRolesScreenAction.g";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import NavMenuActionItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuActionItem";

export function getNavigationMenuItems(): INavMenuItem[] {

    const leftTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const rightTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const childMenuItems: INavMenuItem[] = [
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "Roles",
            "Settings",
            new ShowRolesScreenAction(),
            "UpdateRoles",
            {
                automationId: "roleManagement",
                text: StaticAuthorizationResources.RoleManagement.Title,
                order: 7
            }
        )
    ];

    return [...leftTopLevelMenuItems, ...rightTopLevelMenuItems , ...childMenuItems];
}