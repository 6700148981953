exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PageBoxButton_no-text-selection_2fT-3 {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.PageBoxButton_page-box-button_14BUk {\n  font-family: PragatiNarrowBold;\n  text-transform: uppercase;\n  text-align: center;\n  color: #4f8090;\n  border: dashed 2px #bbb8b8;\n  background: rgba(0, 0, 255, 0.03);\n  margin: 5px 8px 5px 8px;\n  padding: 5px;\n  font-size: 18px;\n  border-radius: 3px;\n  cursor: pointer;\n}\n.PageBoxButton_page-box-button_14BUk:hover {\n  background-color: #f7f7f7;\n  border-color: grey;\n  color: grey;\n}\n.PageBoxButton_page-box-button_14BUk:active {\n  border-color: #505050;\n  color: #636262;\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "PageBoxButton_no-text-selection_2fT-3",
	"noTextSelection": "PageBoxButton_no-text-selection_2fT-3",
	"page-box-button": "PageBoxButton_page-box-button_14BUk",
	"pageBoxButton": "PageBoxButton_page-box-button_14BUk"
};