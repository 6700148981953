import React from "react";
import SurgeryTypeDefinitionId from "@Primitives/SurgeryTypeDefinitionId.g";
import { useSurgeryTypeDefinitionPanelStore, provideSurgeryTypeDefinitionPanelStore } from "./SurgeryTypeDefinitionPanelStoreProvider";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import FormPanel from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";
import SaveButton from "@CommonControls/SaveButton";
import StaticSurgeryResources from "@HisPlatform/BoundedContexts/Surgery/StaticResources/StaticSurgeryResources";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";

export interface ISurgeryTypeDefinitionPanelProps {
    selectedId: SurgeryTypeDefinitionId | null;
    onSaveAsync: () => Promise<void>;
    onUseCaseChange: (selectedId: string, useCaseState: INDataUseCaseState) => void;
    onClose: () => Promise<void>;
}

function SurgeryTypeDefinitionPanel(props: ISurgeryTypeDefinitionPanelProps) {

    const store = useSurgeryTypeDefinitionPanelStore();

    return (
            <ValidationBoundary 
                validationResults={store.form?.validationResults ?? []}>
                <UseCaseFrame 
                    title={StaticSurgeryResources.SurgeryTypeDefinitionList.EditorTitle}
                    toolbar={(
                        <>
                            <SaveButton automationId="Save_Button" onClickAsync={store.saveAsync} />
                        </>
                    )} >
                        <FormPanel form={store.form} definitionId={store.form?.definitionId} onChange={store.onChange}/> 
                </UseCaseFrame>
            </ValidationBoundary>
        );
    }

export default provideSurgeryTypeDefinitionPanelStore(SurgeryTypeDefinitionPanel);