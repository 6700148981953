import { IValidationProblemProcessor } from "@Toolkit/CommonWeb/Validation/ValidationProblemProcessorService";
import MedicalServiceFinancingStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/MedicalServiceFinancingStore";
import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import _ from "@HisPlatform/Common/Lodash";
import FinancedServiceWithQuantityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/FinancedServiceWithQuantityStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { FinancedServiceRuleValidationProblemProcessorBase } from "./FinancedServiceRuleValidationProblemProcessorBase";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import moment from "moment";

export class RepeatRuleValidationProblemProcessor extends FinancedServiceRuleValidationProblemProcessorBase implements  IValidationProblemProcessor<MedicalServiceFinancingStore>  {
    public getRawRelatedCareActivities(problem: IClientValidationProblem) {
        return _.get(problem?.parameters || {}, "PerformedServiceRepeatRuleViolationParametersDto.RelatedCareActivities") || [];
    }

    public ruleId = "ShouldBeCompliantWithFinancedServiceRepeatRules";

    constructor(
        financeReferenceDataStore: FinanceReferenceDataStore,
        localizationService: ILocalizationService,
        organizationReferenceDataStore: OrganizationReferenceDataStore
    ) {
        super(localizationService, organizationReferenceDataStore, financeReferenceDataStore);
    }

    public getServiceId(problem: IClientValidationProblem) {
        const serviceIdValue = _.get(problem.parameters, "PerformedServiceRepeatRuleViolationParametersDto.ServiceId.Value");
        return serviceIdValue ? new FinancedServiceId(serviceIdValue.toString()) : null;
    }

    public getMaxCount(problem: IClientValidationProblem) {
        return _.get(problem.parameters, "PerformedServiceRepeatRuleViolationParametersDto.MaxRepeatCount");
    }

    public getDuration(problem: IClientValidationProblem) {
        const rawDuration = _.get(problem.parameters, "PerformedServiceRepeatRuleViolationParametersDto.RepeatInterval");
        const parsedDuration = moment.duration(rawDuration);
        return parsedDuration;
    }

    public mapValidationProblem(problem: IClientValidationProblem, items: MedicalServiceFinancingStore[]) {
        const serviceId = this.getServiceId(problem);
        if (serviceId) {
            return items.filter(i => i.financedServicesWithQuantities.some((j: FinancedServiceWithQuantityStore) => {
                return ValueWrapper.equals(serviceId, j.financedServiceId.id);
            }));
        }
        return [];
    }

    public renderInfo = (problem: IClientValidationProblem) => {
        const relatedCareActivities = this.getRelatedCareActivities(problem);
        if (relatedCareActivities?.length > 0) {
            return this.renderRelatedCareActivitiesInfo(problem);
        } else {
            return undefined;
        }
    };
}