import React from "react";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import Address from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/Address";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";

interface IAddressViewDependencies {
    commonReferenceDataDataStore: CommonReferenceDataDataStore;
}

interface IAddressViewProps {
    _dependencies?: IAddressViewDependencies;
    address: Address;

    loadMode?: "debounced" | "preloaded";
    showCountry?: boolean;
}

@State.observer
class AddressView extends React.Component<IAddressViewProps> {
    private get commonReferenceDataDataStore() { return this.props._dependencies.commonReferenceDataDataStore; }

    public static defaultProps: Partial<IAddressViewProps> = {
        loadMode: "debounced"
    };

    @State.computed private get country() {
        return this.commonReferenceDataDataStore.countryMap.get(this.props.address.countryId);
    }

    public componentDidMount() {
        if (this.props.loadMode === "debounced") {
            dispatchAsyncErrors(Promise.all([
                this.commonReferenceDataDataStore.countryMap.debouncedEnsureLoaded([this.props.address.countryId])
            ]), this);
        }
    }

    public render() {
        if (!this.country) {
            return this.props.loadMode === "debounced" ? null : "N/A";
        }

        const parts: string[] = [];

        if (this.props.showCountry) {
            parts.push(this.country.isoAlpha2);
        }

        parts.push(this.props.address.zipCode, this.props.address.settlement + ",", this.props.address.addressLine);

        return parts.join(" ");
    }
}

export default connect(
    AddressView,
    new DependencyAdapter<IAddressViewProps, IAddressViewDependencies>(c => ({
        commonReferenceDataDataStore: c.resolve("CommonReferenceDataDataStore"),
    }))
);
