import DependencyContainer from "@DiContainer";
import UserGroupsApiAdapter from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Groups/UserGroupsApiAdapter";
import UserGroupStoreMapper from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Groups/UserGroupStoreMapper";

DependencyContainer
    .bind("UserGroupsApiAdapter")
    .to(UserGroupsApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("UserGroupStoreMapper")
    .to(UserGroupStoreMapper)
    .inSingletonScope();