import Di from "@Di";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import moment from "moment";
import MedicationStatementStore from "./MedicationStatementStore";
import MedicationStatementId from "@Primitives/MedicationStatementId.g";
import ReferencedMedicationAssignment from "./ReferencedMedicationAssignment";
import MedicationStatementHistoryItem from "./MedicationStatementHistoryItem";
import StatementSubjectDetail from "./StatementSubjectDetail";
import TreatmentDetail from "./TreatmentDetail";
import DosageModeId from "@Primitives/DosageModeId.g";

@Di.injectable()
export default class MedicationStatementFactory {

    public createNewStore() {
        const store = new MedicationStatementStore(true);
        store.id = new MedicationStatementId("new");
        store.medicationStatementSubjectAssignment = new ReferencedMedicationAssignment(null);
        store.historyItems.push(this.createNewHistoryItem());
        store.currentItemIndex = 0;

        return store;
    }

    private createNewHistoryItem() {
        const historyItem = new MedicationStatementHistoryItem();

        historyItem.isNew = true;
        historyItem.versionNumber = 1;
        historyItem.index = 0;
        historyItem.createdAt = moment(moment.now());
        historyItem.historyItemDetail = new MedicalConditionHistoryItemDetail();
        historyItem.statementSubjectDetail = new StatementSubjectDetail("", null, "", "");
        historyItem.treatmentDetail = new TreatmentDetail("", LocalDate.today());

        return historyItem;
    }
}
