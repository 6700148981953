import State from "@Toolkit/ReactClient/Common/StateManaging";
import Dosage from "./Dosage";
import MedicationReferenceBase from "./MedicationReferenceBase";
import MedicationId from "@Primitives/MedicationId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import PackagingUnitId from "@Primitives/PackagingUnitId.g";

export default class SingleMedicationReference extends MedicationReferenceBase {
    @State.observable.ref public medicationVersionSelector: IEntityVersionSelector<MedicationId>;
    @State.observable public amount: number = 0;
    @State.observable public daysSupplied: number = 0;
    @State.observable public isDaysSuppliedFilledManually: boolean = false;
    @State.observable public isOverdose: boolean = false;
    @State.observable public isUnpackaged: boolean = false;
    @State.observable public dosage: Dosage;
    @State.observable.ref public packagingUnitId: PackagingUnitId = null;

    @State.computed
    public get propertyNamesExcludedFromDirtyCheck(): string[] {
        if (this.isDaysSuppliedFilledManually) {
            return [];
        }

        return ["daysSupplied"];
    }

    @State.action.bound
    public setMedicationId(medicationId: MedicationId) {
        this.medicationVersionSelector = new EntityVersionSelector(medicationId, LocalDate.today()); // TODO: Fixme! (guitarsolo from Welcome home)
    }

    @State.action.bound
    public setDaysSupplied(daysSupplied: number) {
        this.daysSupplied = daysSupplied;
    }

    @State.action.bound
    public setIsDaysSuppliedFilledManually(isDaysSuppliedFilledManually: boolean) {
        this.isDaysSuppliedFilledManually = isDaysSuppliedFilledManually;
    }

    @State.action.bound
    public setAmount(amount: number) {
        this.amount = amount;
    }

    @State.action.bound
    public setIsOverdose(isOverdose: boolean) {
        this.isOverdose = isOverdose;
    }

    @State.action.bound
    public setIsUnpackaged(isUnpackaged: boolean) {
        this.isUnpackaged = isUnpackaged;
    }

    @State.action.bound
    public setDosage(dosage: Dosage) {
        this.dosage = dosage;
    }

    @State.action.bound
    public setPackagingUnitId(packagingUnitId: PackagingUnitId) {
        this.packagingUnitId = packagingUnitId;
    }
}