exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DataGridColumn_hide-overflow_32yAB {\n  width: 100%;\n  max-width: 0;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n.DataGridColumn_inline-input-container_2NrB7 {\n  height: 44px;\n  line-height: 26px;\n  padding: 6px 0;\n}\n", ""]);

// exports
exports.locals = {
	"hide-overflow": "DataGridColumn_hide-overflow_32yAB",
	"hideOverflow": "DataGridColumn_hide-overflow_32yAB",
	"inline-input-container": "DataGridColumn_inline-input-container_2NrB7",
	"inlineInputContainer": "DataGridColumn_inline-input-container_2NrB7"
};