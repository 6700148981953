import UserGroupId from "@Primitives/UserGroupId.g";
import AccessControlSubjectBase from "./AccessControlSubjectBase";

export default class UserGroupSubject extends AccessControlSubjectBase {
    public userGroupId: UserGroupId;

    constructor(userGroupId?: UserGroupId) {
        super();
        this.userGroupId = userGroupId;
    }

    public equals(other: AccessControlSubjectBase): boolean {
        return other && other instanceof UserGroupSubject && other.userGroupId.value === this.userGroupId.value;
    }
}