import React from "react";
import State, { IObservableValue } from "@Toolkit/ReactClient/Common/StateManaging";
import OrganizationUnitTreeNode from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnitTreeNode";
import OrganizationUnitTypeIcon from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/OrganizationUnitTypeIcon";

interface IOrganizationUnitTreeNodeProps {
    node: OrganizationUnitTreeNode;
}

interface INodeNameProps extends IOrganizationUnitTreeNodeProps {
    filterValueBox?: IObservableValue<string>;
}


const OrganizationUnitType: React.FC<INodeNameProps> = State.observer(props => {
    return (
        <>
            <OrganizationUnitTypeIcon
                tags={props.node.tags}
            />
        </>
    );
});

export default OrganizationUnitType;

