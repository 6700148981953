/* tslint:disable */
import StringEntityId from "./StringEntityId";
import IStringEntityId from "./IStringEntityId";

export default class LockId extends StringEntityId implements IStringEntityId {
    public static readonly entityNameStatic = "Lock";
    public get typeName() { return LockId.entityNameStatic; }
    public get entityName() { return LockId.entityNameStatic; }

    public static fromJS(data: any) { 
        if (data === undefined || data === null) {
            return null;
        }
        return new LockId(data["Value"] + "");
    }
    
    public toJSON(target?: any) {
        target = typeof target === "object" ? target : {};
        target["Value"] = parseInt(this.value);
        return target;
    }
}
