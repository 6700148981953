import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import TimePhaseStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseStore";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

export interface ITimePhaseEditorDialogParams {
    timePhase: TimePhaseStore;
    possibleSchedulingServices: SchedulingServiceSubjectStore[];
    onValidateAsync: () => Promise<IClientValidationResult[]>;
    validationResults: IClientValidationResult[];
    isNew?: boolean;
}

export interface ITimePhaseEditorDialogResult {
    saved: boolean;
}

export default class TimePhaseEditorDialogParams implements ITimePhaseEditorDialogParams {
    public static type = "TimePhaseEditorDialog";

    public get type() { return TimePhaseEditorDialogParams.type; }

    constructor(
        public readonly possibleSchedulingServices: SchedulingServiceSubjectStore[],
        public readonly timePhase: TimePhaseStore,
        public readonly onValidateAsync: () => Promise<IClientValidationResult[]>,
        public readonly validationResults: IClientValidationResult[],
        public readonly isNew?: boolean
    ) {
        
    }
}