import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import { mapPermissionScopesFromDto } from "@HisPlatform/BoundedContexts/WebAppBackend/FrontendActionMapperHelpers";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { ITabbedScreenDefinition, ITabbedScreenTabDefinition } from "@HisPlatform/Packages/ActionModel/Model/ITabbedScreenDefinition";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import CareActivityId from "@Primitives/CareActivityId.g";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";

@Di.injectable()
export default class TabbedScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("IActionModelClient") private readonly apiClient: Proxy.IActionModelClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader
    ) {
        super(referenceDataLoader);
    }

    public getTabbedScreenDefinitionForCareActivityAsync(careActivityId: CareActivityId, code: string) {
        return this.executeOperationAsync<ITabbedScreenDefinition, Proxy.GetCareActivityBasedTabbedScreenQueryResponse>(
            () => this.apiClient.getCareActivityBasedTabbedScreenQueryAsync(CreateRequestId(), careActivityId.value, code),
            response => Promise.resolve({
                defaultTabFrontendActionId: response.defaultTabFrontendActionId,
                tabs: response.tabs!.map<ITabbedScreenTabDefinition>(tab => ({
                    displayName: new MultiLingualLabel(new Map(tab.localizedDisplayName.map(ldn => [ldn.cultureCode.value, ldn.label]))),
                    actionDescriptor: new ActionDescriptor(
                        tab.actionDescriptor.action.frontendAction,
                        tab.actionDescriptor.presentation,
                        mapPermissionScopesFromDto(tab.actionDescriptor.requiredPermissionScopes)
                    ),
                }))
            })
        );
    }
}