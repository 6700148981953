import { useHomeControllerStore } from "@HisPlatform/Packages/Care/Components/HomeMenu/HomeControllerProvider";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React, { useEffect } from "react";

function HomePage() {

    const homeController = useHomeControllerStore();

    useEffect(() => {
        if (homeController.isLoading) return;
        homeController.showSelectedItemAsync.fireAndForget();
    }, [homeController.isLoading]);

    return (
        <p></p>
    );
}

export default State.observer(HomePage);