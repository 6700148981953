exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PatientWidget_allergy-list_1jHUq {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n.PatientWidget_patient-info-container_AiW7R {\n  display: flex;\n  flex-direction: row;\n  padding-top: 0.2rem;\n}\n.PatientWidget_patient-info-container_AiW7R .PatientWidget_patient-avatar_1oxto {\n  flex: auto 0 0;\n  margin: 8px 0 0 8px;\n}\n.PatientWidget_patient-info-container_AiW7R .PatientWidget_patient-avatar_1oxto > svg {\n  top: 0px;\n  width: 30px;\n  height: 30px;\n}\n.PatientWidget_patient-info-container_AiW7R .PatientWidget_patient-info_MagvK {\n  flex: 1;\n}\n.PatientWidget_patient-info-container_AiW7R .PatientWidget_patient-info_MagvK section {\n  margin: 0 0.2rem;\n  line-height: 1.2;\n}\n.PatientWidget_monogram_3UNTS {\n  border: 1px solid #00607e;\n  position: relative;\n  top: 1px;\n  left: -3px;\n}\n.PatientWidget_tooltip_yOHFO {\n  width: 500px;\n  text-align: left;\n}\n.PatientWidget_expand-icon_3E0DM:hover {\n  fill: white !important;\n}\n", ""]);

// exports
exports.locals = {
	"allergy-list": "PatientWidget_allergy-list_1jHUq",
	"allergyList": "PatientWidget_allergy-list_1jHUq",
	"patient-info-container": "PatientWidget_patient-info-container_AiW7R",
	"patientInfoContainer": "PatientWidget_patient-info-container_AiW7R",
	"patient-avatar": "PatientWidget_patient-avatar_1oxto",
	"patientAvatar": "PatientWidget_patient-avatar_1oxto",
	"patient-info": "PatientWidget_patient-info_MagvK",
	"patientInfo": "PatientWidget_patient-info_MagvK",
	"monogram": "PatientWidget_monogram_3UNTS",
	"tooltip": "PatientWidget_tooltip_yOHFO",
	"expand-icon": "PatientWidget_expand-icon_3E0DM",
	"expandIcon": "PatientWidget_expand-icon_3E0DM"
};