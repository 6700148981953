import PatientId from "@Primitives/PatientId.g";
import * as CareProxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import DeviceUseStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/DeviceUseStatementStore";
import ReferencedDeviceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/ReferencedDeviceAssignment";
import CustomDeviceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/CustomDeviceAssignment";
import DeviceDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/DeviceDetail";
import UsageDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/UsageDetail";
import DeviceAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/DeviceAssignmentBase";
import DeviceUseStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/DeviceUseStatementHistoryItem";
import { getHistoryItemDetailDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalConditionDtoMapper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";

export function getAddDeviceUseStatementControllerDto(source: DeviceUseStatementStore, patientId: PatientId, isValidateOnly: boolean) {
    return new CareProxy.AddDeviceUseStatementControllerDto({
        patientId: patientId,
        extendedDeviceName: source.extendedDeviceName,
        historyItemDtos: [getDeviceUseStatementHistoryItemDto(source.latestHistoryItem as DeviceUseStatementHistoryItem)],
        deviceAssignmentBaseDto: getDeviceAssignmentDto(source.deviceAssignment),
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData
    });
}

export function getUpdateDeviceUseStatementControllerDto(source: DeviceUseStatementStore, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.UpdateDeviceUseStatementControllerDto({
        deviceUseStatementId: source.id,
        historyItemDtos: [getDeviceUseStatementHistoryItemDto(source.latestHistoryItem as DeviceUseStatementHistoryItem)],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteDeviceUseStatementHistoryItemControllerDto(source: DeviceUseStatementStore, versionNumber: number, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.DeleteDeviceUseStatementHistoryItemControllerDto({
        deviceUseStatementId: source.id,
        versionNumbers: [versionNumber],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteDeviceUseStatementControllerDto(store: DeviceUseStatementStore) {
    return new CareProxy.DeleteDeviceUseStatementControllerDto({
        deviceUseStatementId: store.id,
        lockId: store.lockInfo?.lockId,
        extensionData: store.extensionData,
        isValidateOnly: false,
        releaseLockIfSuccessful: true
    });
}

function getDeviceAssignmentDto(source: DeviceAssignmentBase) {
    let dto;

    if (source instanceof ReferencedDeviceAssignment) {
        dto = new CareProxy.ReferencedDeviceAssignmentDto({ deviceId: source.deviceId });
    } else if (source instanceof CustomDeviceAssignment) {
        dto = new CareProxy.CustomDeviceAssignmentDto({ name: source.name });
    }

    return dto;
}

function getDeviceUseStatementHistoryItemDto(source: DeviceUseStatementHistoryItem) {
    return new CareProxy.DeviceUseStatementHistoryItemDto({
        versionNumber: source.versionNumber,
        index: source.index,
        createdAt: source.createdAt,
        historyItemDetail: getHistoryItemDetailDto(source.historyItemDetail),
        deviceDetail: getDeviceDetailDto(source.deviceDetail),
        usageDetail: getUsageDetailDto(source.usageDetail),
        extensionData: source.extensionData
    });
}

function getDeviceDetailDto(source: DeviceDetail) {
    return new CareProxy.DeviceDetailDto({
        manufacturer: source.manufacturer,
        modelName: source.modelName,
        modelType: source.modelType,
        serialNumber: source.serialNumber
    });
}

function getUsageDetailDto(source: UsageDetail) {
    return new CareProxy.UsageDetailDto({
        endOfUse: source.endOfUse,
        startOfUse: source.startOfUse
    });
}