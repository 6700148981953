import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import * as Ui from "@CommonControls";
import FamilyDoctorDocumentMedicationReference from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentMedicationReference";
import FamilyDoctorDocumentSingleMedication from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentSingleMedication";
import MedicationCodeSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/MedicationCodeSelector";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import MedicationId from "@Primitives/MedicationId.g";
import ConditionCodeSelector from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ConditionCodeSelector";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import SubsidyOptionSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/SubsidyOptionSelector/SubsidyOptionSelector";
import SubsidyClaimTypeSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/SubsidyClaimTypeSelector/SubsidyClaimTypeSelector";
import MedicationPricingAndSubsidies from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationPricingAndSubsidies";
import MedicationSubsidy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationSubsidy";
import * as HisUi from "@HisPlatformControls";
import FamilyDoctorDocumentMedicationFormula from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentMedicationFormula";
import FamilyDoctorDocumentMediactionFormulaItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentMediactionFormulaItem";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import FamilyDoctorDocumentMedicationFormulaListItem from "./FamilyDoctorDocumentMedicationFormulaListItem";
import MedicationSubsidyId from "@Primitives/MedicationSubsidyId.g";
import MedicationSubsidyOptionId from "@Primitives/MedicationSubsidyOptionId.g";
import MedicationTypeId from "@Primitives/MedicationTypeId.g";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import ExcludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierSystemIdFilter";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";

interface IFamilyDoctorDocumentMedicationReferenceListItemView {
    item: FamilyDoctorDocumentMedicationReference;
    index: number;
    pricingAndSubsidies: MedicationPricingAndSubsidies;
    possibleSubsidyByPricingType: MedicationSubsidy;
    setMedicationAsync: (value: MedicationId) => Promise<void>;
    familyDoctorDocumentMedicationFormulaListItemFactory(): Promise<FamilyDoctorDocumentMediactionFormulaItem>;
    setSubsidyAndOptionId: (newValue: { subsidyId: MedicationSubsidyId, optionId: MedicationSubsidyOptionId }) => void;
}


const FamilyDoctorDocumentMedicationReferenceListItemView: React.FC<IFamilyDoctorDocumentMedicationReferenceListItemView> = props => {

    const filters: FilterBase[] = [new ExcludeIdentifierSystemIdFilter(IdentifierSystemId.OncologyMorphology)];

    const renderFamilyDoctorDocumentMedicationFormulaListItem = (item: FamilyDoctorDocumentMediactionFormulaItem, index: number) =>
        <FamilyDoctorDocumentMedicationFormulaListItem index={index} item={item} />;

    return (
        <>
            {props.item.detail instanceof FamilyDoctorDocumentSingleMedication &&
                <>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={10}>
                            <MedicationCodeSelector
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.MedicationCodeSelector}
                                value={props.item.detail.medicationVersionSelector?.id}
                                onChange={props.setMedicationAsync}
                                propertyIdentifier="MedicationVersionSelector"
                                automationId="medicationSelector"
                                medicationTypes={
                                    [
                                        MedicationTypeId.G1.value,
                                        MedicationTypeId.G3.value,
                                        MedicationTypeId.G4.value,
                                        MedicationTypeId.G5.value,
                                        MedicationTypeId.G6.value,
                                        MedicationTypeId.G7.value,
                                        MedicationTypeId.H1.value,
                                        MedicationTypeId.T1.value
                                    ]
                                }
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <Ui.CheckBox
                                verticalAlign="noPadding"
                                align="center"
                                displayMode="check"
                                value={props.item.isMagistral}
                                onChange={props.item.setIsMagistral}
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.IsMagistral}
                                labelPosition={"left"}
                                automationId="isMagistral"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={8}>
                            <ConditionCodeSelector
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.ConditionCodeSelector}
                                value={props.item.conditionId}
                                onChange={props.item.setConditionId}
                                propertyIdentifier="ConditionId"
                                automationId="conditionSelector"
                                filters={filters}
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.TextBox
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.MedicationDosageForm}
                                value={props.item.medicationDosageForm}
                                onChange={props.item.setMedicationDosageForm}
                                propertyIdentifier="MedicationDosageForm"
                                automationId="medicationDosageForm"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.TextBox
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.SubstanceName}
                                value={props.item.detail.substanceName}
                                onChange={props.item.detail.setSubstanceName}
                                propertyIdentifier="SubstanceName"
                                automationId="substanceName"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.TextBox
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.SubstanceDailyAmount}
                                value={props.item.detail.substanceDailyAmount}
                                onChange={props.item.detail.setSubstanceDailyAmount}
                                propertyIdentifier="SubstanceDailyAmount"
                                automationId="substanceDailyAmount"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.TextBox
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.DailyDosage}
                                value={props.item.dailyDosage}
                                onChange={props.item.setDailyDosage}
                                propertyIdentifier="DailyDosage"
                                automationId="dailyDosage"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <SubsidyClaimTypeSelector
                                value={props.item.detail.medicationSubsidyClaimTypeId}
                                onChange={props.item.detail.setMedicationSubsidyClaimTypeId}
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.MedicationSubsidyClaimType}
                                idFilter={props.pricingAndSubsidies?.pricing?.claimTypes}
                                propertyIdentifier="MedicationSubsidyClaimType"
                                automationId="subsidyClaimTypeSelector"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <SubsidyOptionSelector
                                subsidy={props.possibleSubsidyByPricingType}
                                value={!isNullOrUndefined(props.item.detail.medicationSubsidyId) ?
                                    ({ subsidyId: props.item.detail.medicationSubsidyId, optionId: props.item.detail.subsidyOptionId }) : null}
                                onChange={props.setSubsidyAndOptionId}
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.SubsidyAndOptionIdSelector}
                                automationId="subsidyAndOptionIdSelector"
                                propertyIdentifier="SubsidyOption"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <HisUi.DoctorCodeSelector
                                value={props.item.detail.practitionerId}
                                onChange={props.item.detail.setPractitionerId}
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.PractitionerSelector}
                                propertyIdentifier="PractitionerId"
                                automationId="practitionerSelector"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={12}>
                            <Ui.TextBox
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.Notes}
                                value={props.item.notes}
                                onChange={props.item.setNotes}
                                propertyIdentifier="Notes"
                                automationId="notes"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>

                </>}
            {props.item.detail instanceof FamilyDoctorDocumentMedicationFormula &&
                <>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={10}>
                            <ListPanel<FamilyDoctorDocumentMediactionFormulaItem>
                                title={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.AddMedicationFormulaItem}
                                alwaysEdit
                                items={props.item.detail.items}
                                renderItemEditor={renderFamilyDoctorDocumentMedicationFormulaListItem}
                                onRemoveItem={props.item.detail.removeFamilyDoctorDocumentMedicationFormulaListItem}
                                onCreateNewAsync={props.familyDoctorDocumentMedicationFormulaListItemFactory}
                                noItemsMessage={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Detail.MedicationPrescribedMonthly.NoItemMessage}
                                automationId="__familyDoctorDocumentMedicationReferenceListItemPanel"
                                propertyIdentifier="FamilyDoctorDocumentMedicationFormulaListItems"
                                isCompactEmptyState
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <Ui.CheckBox
                                verticalAlign="noPadding"
                                align="center"
                                displayMode="check"
                                value={props.item.isMagistral}
                                onChange={props.item.setIsMagistral}
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.IsMagistral}
                                labelPosition={"left"}
                                automationId="isMagistral"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={8}>
                            <ConditionCodeSelector
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.ConditionCodeSelector}
                                value={props.item.conditionId}
                                onChange={props.item.setConditionId}
                                propertyIdentifier="ConditionId"
                                automationId="conditionSelector"
                                filters={filters}
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.TextBox
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.MedicationDosageForm}
                                value={props.item.medicationDosageForm}
                                onChange={props.item.setMedicationDosageForm}
                                propertyIdentifier="MedicationDosageForm"
                                automationId="medicationDosageForm"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={8}>
                            <Ui.TextBox
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.Notes}
                                value={props.item.notes}
                                onChange={props.item.setNotes}
                                propertyIdentifier="Notes"
                                automationId="notes"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.TextBox
                                label={StaticHunSocialSecurityMedicationRequestResources.FamilyDoctorDocumentListPanel.Labels.DailyDosage}
                                value={props.item.dailyDosage}
                                onChange={props.item.setDailyDosage}
                                propertyIdentifier="DailyDosage"
                                automationId="dailyDosage"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </>}
        </>
    );
};

export default State.observer(FamilyDoctorDocumentMedicationReferenceListItemView);