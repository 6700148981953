import Di from "@Di";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import FormEditorRegistry, { FormEditorComponentType } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import FormFieldDependencyStore from "@Toolkit/FormEngine/Panels/FormPanel/FormFieldDependencyStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IFormFieldDependencyDialogParams, IFormFieldDependencyDialogResult } from "./FormFieldDependencyDialogParams";
import FormFieldData from "./FormFieldData";
import { getAllEditors } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/GetAllEditors";
import EditableDataElementEditorStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableDataElementEditorStore";
import FormLogic from "@Toolkit/FormEngine/Model/FormLogic";
import { generateFormFieldDependencyLogic } from "./dependency-generator";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import IMapperService from "@HisPlatform/Services/Definition/MapperService/IMapperService";
import { flattenDataElements } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FlattenDataElements";
import { composeFullyQualifiedName } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/ComposeFullyQualifiedName";

@Di.injectable()
export default class FormFieldDependencyDialogStore extends PanelStoreBase<IModalComponentParams<IFormFieldDependencyDialogResult> & IFormFieldDependencyDialogParams> implements ILoadablePanelStore {

    private sourceFieldPath: string;
    @State.observable public targetFieldData: FormFieldData;
    @State.observable.ref public targetFieldValues = State.createObservableShallowArray<FormFieldDependencyStore>([]);

    constructor(
        @Di.inject("FormEditorRegistry") private readonly formEditorRegistry: FormEditorRegistry,
        @Di.inject("IMapperService") private readonly mapperService: IMapperService,
        @Di.inject("ICurrentCultureProvider") private readonly cultureCodeProvider: ICurrentCultureProvider
    ) {
        super();
    }

    public initialize() {
        this.sourceFieldPath = composeFullyQualifiedName(this.props.selectedEditor);

        if (this.props.targetFieldData) {
            State.runInAction(() => {
                this.targetFieldData = this.formFieldList.find(ff => ff.fullyQualifiedDataReferencePath === this.props.targetFieldData.fullyQualifiedDataReferencePath);
                this.props.targetFieldValues.forEach(value => {
                    const store = this.formEditorRegistry.tryCreateFormFieldDependencyStore(this.targetFieldData.editorType);
                    const parsedValue = this.mapperService.tryMapByName<string, any>(`FormFieldDependency_${this.targetFieldData.editorType}_Deserializer`, value);
                    store.setValue(parsedValue.result);
                    this.targetFieldValues.push(store);
                });
            });
        }
    }
    
    @State.computed
    public get getDependencyData(): { componentType: FormEditorComponentType, dataElement: FormDataElementBase, props: any } | null {
        const componentTypeData = this.targetFieldData.editorType
            ? this.formEditorRegistry.tryGetEditorComponent(this.targetFieldData.editorType)
            : this.formEditorRegistry.tryGetEditorComponent("TextBox");

        return {
            componentType: componentTypeData.componentType,
            dataElement: this.targetFieldData.dataElement,
            props: componentTypeData.props
        };
    }

    @State.computed
    public get formFieldList(): FormFieldData[] {
        const dataElementInfoList = flattenDataElements(this.props.dataElements);
        const formFieldDataList = getAllEditors(this.props.rootBlocks)
            .map(editor => {
                const fullyQualifiedName = composeFullyQualifiedName(editor);
                const label = editor instanceof EditableDataElementEditorStore
                    ? editor.localizedLabel
                    : this.getLocalizedName(editor.multiLingualLabel);
                const dataElementInfo = dataElementInfoList.find(dei => dei.fullyQualifiedName === fullyQualifiedName);
                return new FormFieldData(fullyQualifiedName, label, editor.editorType, dataElementInfo.dataElement);
            })
            .filter(editor => editor.fullyQualifiedDataReferencePath !== this.sourceFieldPath);
        return formFieldDataList;
    }

    @State.action.bound
    public setTargetFieldData(targetFieldData: FormFieldData) {
        this.targetFieldData = targetFieldData;
        this.targetFieldValues.clear();
        this.targetFieldValues.push(this.createNewDependency());
    }

    @State.action.bound
    public addNewDependency(item: FormFieldDependencyStore) {
        this.targetFieldValues.push(item);
    }

    @State.action.bound
    public removeDependency(item: FormFieldDependencyStore) {
        this.targetFieldValues.remove(item);
    }

    @State.action.bound
    public saveDependency() {
        const targetFieldValues = this.targetFieldValues.map(targetFieldValue => this.mapperService.tryMapByName<any, string>(`FormFieldDependency_${this.targetFieldData.editorType}_Serializer`, targetFieldValue.value).result);
        const formLogicContent = generateFormFieldDependencyLogic(this.sourceFieldPath, this.targetFieldData, targetFieldValues);
        this.props.onClose({
            formLogic: new FormLogic(
                `FormFieldDependency_${this.sourceFieldPath}`,
                Base64Converter.fromString(formLogicContent))
        });
    }

    @State.action.bound
    public createNewDependencyAsync(): Promise<FormFieldDependencyStore> {
        return Promise.resolve(this.createNewDependency());
    }

    private createNewDependency() {
        if (!this.targetFieldData) {
            return null;
        }

        return this.formEditorRegistry.tryCreateFormFieldDependencyStore(this.targetFieldData.editorType)
            ?? new FormFieldDependencyStore<string>();
    }

    private getLocalizedName(multiLingualLabel: MultiLingualLabel): string {
        return multiLingualLabel.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode);
    }
}