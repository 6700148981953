import IObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/IObservation";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import ObservationId from "@Primitives/ObservationId.g";
import ObservationDefinitionId from "@Primitives/ObservationDefinitionId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import Quantity from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/Quantity";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Unit from "@Toolkit/CommonWeb/Model/Unit";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import PatientId from "@Primitives/PatientId.g";

export default class ScalarObservation implements IObservation {

    @State.observable.ref public isDirty: boolean = false;
    @State.observable.ref public quantityAmount: number = null;
    @State.observable.ref public remarks: string = null;
    private readonly units: Unit;

    @State.computed
    public get quantity() {
        if (isNullOrUndefined(this.quantityAmount)) {
            return null;
        }
        return new Quantity(this.quantityAmount, this.units);
    }

    constructor(
        public readonly observationId: ObservationId,
        public readonly observationDefinitionId: ObservationDefinitionId,
        public readonly rowVersion: RowVersion,
        public readonly patientId: PatientId,
        public readonly careActivityId: CareActivityId,
        remarks: string,
        quantity: Quantity,
        public readonly customLowerRange: number,
        public readonly customUpperRange: number,
        public rawValue: string
    ) {
        if (!isNullOrUndefined(quantity)) {
            this.units = quantity.units;
            this.quantityAmount = quantity.amount;
        }
        this.remarks = remarks;
    }

    @State.action.bound
    public setQuantityAmount(newValue: number) {
        if (this.quantityAmount !== newValue) {
            this.quantityAmount = newValue;
            this.isDirty = true;
        }
    }

    @State.action.bound
    public setRawValue(newValue: string) {
        if (this.rawValue !== newValue) {
            this.rawValue = newValue;
            this.isDirty = true;
        }
    }

    @State.action.bound
    public setRemarks(newValue: string) {
        if (this.remarks !== newValue) {
            this.remarks = newValue;
            this.isDirty = true;
        }
    }
}
