import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import { PageBoxContainer } from "@CommonControls";
import { HisErrorBoundary } from "@HisPlatformControls";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import EhiCovidVaccinationsListPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidVaccinationsListPanel/EhiCovidVaccinationsListPanel";

interface ICovidVaccinationsListPageDependencies {
    applicationContext: ApplicationContext;
}

interface ICovidVaccinationsListPageProps extends IRoutingFrameContentProps {
    _dependencies?: ICovidVaccinationsListPageDependencies;
}

@State.observer
class CovidVaccinationsListPage extends React.Component<ICovidVaccinationsListPageProps> {

    public componentWillMount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(new UseCaseIdentifier("CovidVaccinationsList"));
    }

    public componentWillUnmount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(null);
    }

    public render() {
        return (
            <div>
                <PageBoxContainer>
                    <HisErrorBoundary>
                        <SingleLayout>
                            <EhiCovidVaccinationsListPanel
                                routingController={this.props.routingController}
                            />
                        </SingleLayout>
                    </HisErrorBoundary>
                </PageBoxContainer>
            </div>
        );
    }
}

export default connect(
    withHisErrorBoundary(CovidVaccinationsListPage),
    new DependencyAdapter<ICovidVaccinationsListPageProps, ICovidVaccinationsListPageDependencies>(container => {
        return {
            applicationContext: container.resolve("ApplicationContext")
        };
    })
);
