import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import PerformanceStatementCareActivityStatusTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityStatusTypeSelectBox/PerformanceStatementCareActivityStatusTypeSelectBox";
import PerformanceStatementCareActivityStatusTypeIcon from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityStatusTypeIcon/PerformanceStatementCareActivityStatusTypeIcon";
import PerformanceStatementCareActivityStatusType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementCareActivityStatusType.g";

interface IPerformanceStatementCareActivityStatusTypeColumnDependencies {
    localizationService: ILocalizationService;
}

interface IPerformanceStatementCareActivityStatusTypeColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IPerformanceStatementCareActivityStatusTypeColumnDependencies;
    displayMode?: "name" | "badge";
}


const PerformanceStatementCareActivityStatusTypeColumn: React.FC<IPerformanceStatementCareActivityStatusTypeColumnProps> = (props: IPerformanceStatementCareActivityStatusTypeColumnProps) => {

    const valueRenderer = useCallback((value: PerformanceStatementCareActivityStatusType) => {

        switch (props.displayMode) {
            case "name":
                const localized = isNullOrUndefined(value) ? null : props._dependencies.localizationService.localizeEnum(PerformanceStatementCareActivityStatusType[value], "PerformanceStatementCareActivityStatusType");
                return isNullOrUndefined(localized) ? null : localized.Name;
            case "badge":
            default:
                return <PerformanceStatementCareActivityStatusTypeIcon state={value} />;
        }
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<PerformanceStatementCareActivityStatusType | PerformanceStatementCareActivityStatusType[]>) => {
        return (
            <PerformanceStatementCareActivityStatusTypeSelectBox
                {...filterProps}
                multiSelect
                maxMenuWidth={commonMenuMaxWidth}
                hoverOnlyIndicatorIcons
            />
        );
    }, []);

    return (
        <DataGridColumn
            width={props.displayMode === "badge" ? 60 : undefined}
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            showHint={false}
        />
    );
};

export default connect(
    PerformanceStatementCareActivityStatusTypeColumn,
    new DependencyAdapter<IPerformanceStatementCareActivityStatusTypeColumnProps, IPerformanceStatementCareActivityStatusTypeColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
