import React, { useMemo, useCallback } from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import IFormDefinitionRouteParams from "./Routes/IFormDefinitionRouteParams";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import FormDefinitionMasterDetailPanel from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormDefinitionMasterDetailPanel/FormDefinitionMasterDetailPanel";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

function FormDefinitionPage(props: IRoutingFrameContentProps<IFormDefinitionRouteParams>) {

    const selectedId = useMemo(() => {
        const routeParameters = props.routingController.currentRoute.parameters;
        return isNullOrUndefined(routeParameters.selectedId) ? null : new FormDefinitionId(routeParameters.selectedId);
    }, [props.routingController.currentRoute.parameters.selectedId]);

    const setSelectedId = useCallback((id: FormDefinitionId | null) => {
        props.routingController.replace(props.routingController.currentRoute.definition.makeRoute({
            selectedId: id?.value ?? undefined
        }));
    }, [props.routingController]);

    return (
        <FormDefinitionMasterDetailPanel
            selectedId={selectedId}
            onSelectedIdChange={setSelectedId}
        />
    );
}
export default withLoadingBoundary(FormDefinitionPage);