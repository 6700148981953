exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InfoButton_no-text-selection_1Iz2c {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.InfoButton_container_3OTQj {\n  top: 0;\n}\n.InfoButton_container_3OTQj svg {\n  fill: rgba(134, 149, 167, 0.5);\n}\n.InfoButton_info-box-tooltip_3r21a {\n  background-color: #ffffff;\n  color: #4f5b72;\n  font-family: PragatiNarrow;\n  border-radius: 0px;\n  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);\n}\n.InfoButton_text-card-container_1vvjH {\n  margin: 5px;\n}\n.InfoButton_text-card-text_1888c {\n  text-align: left;\n}\n.InfoButton_text-card-more-label_7NKii {\n  text-align: right;\n}\n.InfoButton_sup_1EREA {\n  top: -0.3em;\n  padding-left: 2px;\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "InfoButton_no-text-selection_1Iz2c",
	"noTextSelection": "InfoButton_no-text-selection_1Iz2c",
	"container": "InfoButton_container_3OTQj",
	"info-box-tooltip": "InfoButton_info-box-tooltip_3r21a",
	"infoBoxTooltip": "InfoButton_info-box-tooltip_3r21a",
	"text-card-container": "InfoButton_text-card-container_1vvjH",
	"textCardContainer": "InfoButton_text-card-container_1vvjH",
	"text-card-text": "InfoButton_text-card-text_1888c",
	"textCardText": "InfoButton_text-card-text_1888c",
	"text-card-more-label": "InfoButton_text-card-more-label_7NKii",
	"textCardMoreLabel": "InfoButton_text-card-more-label_7NKii",
	"sup": "InfoButton_sup_1EREA"
};