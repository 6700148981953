import Di from "@Di";
import ICareActivitySourceProvider from "@HisPlatform/BoundedContexts/Care/Services/Definition/CareActivitySourceRegistry/ICareActivitySourceProvider";
import CareActivitySource from "@Primitives/CareActivitySource.g";

@Di.injectable()
export default class EhiCareActivitySourceProvider implements ICareActivitySourceProvider {
    getCareActivitySources(): CareActivitySource[] {
        return [
            new CareActivitySource("HunEHealthInfrastructure")
        ];
    }
}