exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EHRWidget_reload-button-flex_25Gf- {\n  padding-right: 4px;\n}\n.EHRWidget_reload-button_3GpeQ {\n  margin-top: 22px;\n}\n.EHRWidget_date-range-flex_1FEU6 {\n  width: inherit;\n}\n.EHRWidget_more-button_2dHTl {\n  margin-top: 3px;\n}\n.EHRWidget_tooltip-container_1Tq7m {\n  text-align: left;\n  padding: 0 0.5em;\n}\n.EHRWidget_tooltip-container_1Tq7m p {\n  line-height: 1.14;\n}\n", ""]);

// exports
exports.locals = {
	"reload-button-flex": "EHRWidget_reload-button-flex_25Gf-",
	"reloadButtonFlex": "EHRWidget_reload-button-flex_25Gf-",
	"reload-button": "EHRWidget_reload-button_3GpeQ",
	"reloadButton": "EHRWidget_reload-button_3GpeQ",
	"date-range-flex": "EHRWidget_date-range-flex_1FEU6",
	"dateRangeFlex": "EHRWidget_date-range-flex_1FEU6",
	"more-button": "EHRWidget_more-button_2dHTl",
	"moreButton": "EHRWidget_more-button_2dHTl",
	"tooltip-container": "EHRWidget_tooltip-container_1Tq7m",
	"tooltipContainer": "EHRWidget_tooltip-container_1Tq7m"
};