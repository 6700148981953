import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { buildQueryStringArray } from "@Toolkit/CommonWeb/QueryStringBuilder";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import _ from "@HisPlatform/Common/Lodash";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import OrganizationUnit from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnit";
import OrganizationUnitStoreMapper from "./OrganizationUnitStoreMapper";
import OrganizationUnitDefinitionId from "@Primitives/OrganizationUnitDefinitionId.g";
import OrganizationUnitDefinition from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnitDefinition";
import { getUpdateOrganizationUnitDto, getAddUpdateOrganizationUnitDto, getUpdateOrganizationUnitPermissionCheckDto } from "./OrganizationUnitDtoMapper";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import OrganizationUnitTreeNode from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnitTreeNode";
import OrganizationUnitsStoreMapper from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/OrganizationUnitsStoreMapper";
import PointOfCare from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/PointOfCare";
import Address from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/Address";
import HealthcareProfessionId from "@Primitives/HealthcareProfessionId.g";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import HealthcareProfessionsStoreMapper from "./HealthcareProfessionsStoreMapper";
import HealthcareProfession from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/HealthcareProfession";
import { convertToPropertyValueBase } from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/DynamicProperties/PropertyValueHelper";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

@Di.injectable()
export default class StructureApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IStructureClient") private readonly structureApiClient: Proxy.IStructureClient,
        @Di.inject("OrganizationUnitStoreMapper") private readonly organizationUnitStoreMapper: OrganizationUnitStoreMapper,
        @Di.inject("OrganizationUnitsStoreMapper") private readonly organizationUnitsStoreMapper: OrganizationUnitsStoreMapper,
        @Di.inject("HealthcareProfessionsStoreMapper") private readonly healthcareProfessionsStoreMapper: HealthcareProfessionsStoreMapper
    ) {
        super();
    }

    @State.bound
    public getPointsOfCareByIdsAsync(ids: PointOfCareId[]): Promise<SimpleStore<PointOfCare[]>> {
        return this.processOperationAsync(
            new SimpleStore<PointOfCare[]>(),
            async target => {
                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value));

                const response = await this.structureApiClient.getOrganizationUnitsByIdsQueryAsync(
                    CreateRequestId(),
                    buildQueryStringArray(normalizedIds),
                    LocalDate.createFromMoment(DateTimeService.now()).stringify()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.organizationUnits.map((item) => (
                    {
                        name: item.naturalIdentifier.name,
                        id: new PointOfCareId(item.id.value + ""),
                        shorthand: item.code,
                        healthcareProfessionIds: item.healthcareProfessionIds
                    } as PointOfCare
                ));
            }
        );
    }

    @State.bound
    public getAllPointOfCareIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<PointOfCareId[]>(),
            async target => {
                const response = await this.structureApiClient.getOrganizationUnitsByTagsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetOrganizationUnitsByTagsControllerDto({
                        includeDeleted: true,
                        tags: ["PointOfCare"],
                        validOn: LocalDate.createFromMoment(DateTimeService.now())
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.organizationUnits.map(ou => new PointOfCareId(ou.id.value.toString()));
            }
        );
    }

    @State.bound
    public getNotDeletedPointsOfCareIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<PointOfCareId[]>(),
            async target => {
                const response = await this.structureApiClient.getOrganizationUnitsByTagsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetOrganizationUnitsByTagsControllerDto({
                        includeDeleted: false,
                        tags: ["PointOfCare"],
                        validOn: LocalDate.createFromMoment(DateTimeService.now())
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.organizationUnits.map(ou => new PointOfCareId(ou.id.value.toString()));
            }
        );
    }

    @State.bound
    public getOrganizationStructureAsync(isOpenMap?: Map<string, boolean>, includeDeleted?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<OrganizationUnitTreeNode[]>(),
            async target => {
                const response = await this.structureApiClient.getOrganizationUnitStructureQueryAsync(CreateRequestId(),
                    new Proxy.GetOrganizationUnitStructureControllerDto({ includeDeleted: includeDeleted }));
                target.operationInfo = createOperationInfo(response);
                target.value = response.organizationUnits.map(ou => this.mapOrganizationUnit(ou, isOpenMap));
            }
        );
    }

    @State.bound
    public getFilteredOrganizationStructureAsync(propertyGroupName: string, propertyName: string, propertyValue: any, isOpenMap?: Map<string, boolean>, includeDeleted?: boolean) {
        const propertyValueBase = convertToPropertyValueBase(propertyValue);

        return this.processOperationAsync(
            new SimpleStore<OrganizationUnitTreeNode[]>(),
            async target => {
                const response = await this.structureApiClient.getOrganizationUnitStructureQueryAsync(CreateRequestId(),
                    new Proxy.GetOrganizationUnitStructureControllerDto(
                        {
                            includeDeleted: includeDeleted,
                            filteringPropertyGroupName: propertyGroupName,
                            filteringPropertyName: propertyName,
                            filteringPropertyValue: propertyValueBase
                        }));
                target.operationInfo = createOperationInfo(response);
                target.value = response.organizationUnits.map(ou => this.mapOrganizationUnit(ou, isOpenMap));
            }
        );
    }
    
    private flattenOrganizationUnitStructure(organizationStructure: Proxy.OrganizationStructureDto[]): OrganizationUnitId[] {
        return organizationStructure.flatMap(o => [o.id, ...this.flattenOrganizationUnitStructure(o.children)]);
    }

    @State.bound
    public getAllIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<OrganizationUnitId[]>(),
            async target => {
                const response = await this.structureApiClient.getOrganizationUnitStructureQueryAsync(CreateRequestId(),
                    new Proxy.GetOrganizationUnitStructureControllerDto({ includeDeleted: false }));
                target.operationInfo = createOperationInfo(response);
                target.value = this.flattenOrganizationUnitStructure(response.organizationUnits);
            }
        );
    }

    @State.bound
    public getChildrenByParentIdAsync(id: OrganizationUnitId) {
        return this.processOperationAsync(
            new SimpleStore<OrganizationUnitId[]>(),
            async target => {
                const response = await this.structureApiClient.getOrganizationUnitIdsByParentIdAndTagsQueryAsync(CreateRequestId(),
                    new Proxy.GetOrganizationUnitIdsByParentIdAndTagsControllerDto({
                        parentId: id,
                        tags: ["PointOfCare"]
                    }));
                target.operationInfo = createOperationInfo(response);
                target.value = response.organizationUnitIds;
            }
        );
    }

    @State.bound
    private mapOrganizationUnit(ou: Proxy.OrganizationStructureDto, isOpenMap?: Map<string, boolean>) {
        const node: OrganizationUnitTreeNode = new OrganizationUnitTreeNode(
            ou.id,
            ou.parentId,
            ou.code,
            ou.name,
            ou.healthcareProfessionIds,
            ou.definitionId,
            ou.isLeaf,
            ou.rowVersion,
            ou.definitionCode,
            new Address(
                ou.address.countryId,
                ou.address.settlement,
                ou.address.zipCode,
                ou.address.addressLine
            ),
            ou.managerId,
            isOpenMap && isOpenMap.has(ou.id.value) && isOpenMap.get(ou.id.value),
            ou.children.length > 0 ? ou.children.map((os1: any) => this.mapOrganizationUnit(os1, isOpenMap)) : [],
            ou.tags
        );

        return node;
    }

    @State.bound
    public getOrganizationUnitByIdAsync(organizationUnitId: OrganizationUnitId) {
        return this.processOperationAsync(
            new OrganizationUnit(),
            async target => {
                const response = await this.structureApiClient.getOrganizationUnitsByIdsQueryAsync(CreateRequestId(), organizationUnitId.value, "");
                this.organizationUnitStoreMapper.applyToStore(target, response.organizationUnits[0]);
            }
        );
    }

    @State.bound
    public getOrganizationUnitsByIdsAsync(organizationUnitIds: OrganizationUnitId[]) {
        return this.processOperationAsync(
            new SimpleStore<OrganizationUnit[]>(),
            async target => {
                const response = await this.structureApiClient.getOrganizationUnitsByIdsQueryAsync(CreateRequestId(), organizationUnitIds.map(x => x.value).join(";"), "");
                this.organizationUnitsStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public getChildOrganizationUnitDefinitionIdsAsync(organizationUnitDefinitionId: OrganizationUnitDefinitionId) {
        return this.processOperationAsync(
            new SimpleStore<OrganizationUnitDefinitionId[]>(),
            async target => {
                const response = await this.structureApiClient.getChildOrganizationUnitDefinitionIdsQueryAsync(CreateRequestId(), organizationUnitDefinitionId.value);
                target.value = response.childOrganizationUnitDefinitionIds;
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getOrganizationUnitDefinitionsByIdsAsync(organizationUnitDefinitionIds: OrganizationUnitDefinitionId[]) {
        return this.processOperationAsync(
            new SimpleStore<OrganizationUnitDefinition[]>(),
            async target => {
                const response = await this.structureApiClient.getOrganizationUnitDefinitionsByIdsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetOrganizationUnitDefinitionsByIdsControllerDto({
                        organizationUnitDefinitionIds: organizationUnitDefinitionIds
                    })
                );
                target.value = response.organizationUnitDefinitions.map(oud =>
                    new OrganizationUnitDefinition(oud.id, oud.parentId, oud.code, oud.name, oud.tags));
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public updateOrganizationUnitAsync(organizationUnit: OrganizationUnit) {
        return this.processOperationAsync(
            new OrganizationUnit(),
            async target => {
                const response = await this.structureApiClient.updateOrganizationUnitCommandAsync(CreateRequestId(), getUpdateOrganizationUnitDto(organizationUnit));
                target.operationInfo = createOperationInfo(response);
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);

                if (!target.hasValidationError) {
                    this.organizationUnitStoreMapper.applyToStore(target, response.organizationUnitDto);
                }
            }
        );
    }

    @State.bound
    public validateOrganizationUnitAsync(organizationUnit: OrganizationUnit) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                let response = null;

                if (organizationUnit.isNew) {
                    const dto = getAddUpdateOrganizationUnitDto(organizationUnit);
                    dto.isValidateOnly = true;
                    response = await this.structureApiClient.addOrganizationUnitCommandAsync(CreateRequestId(), dto);
                } else {
                    const dto = getUpdateOrganizationUnitDto(organizationUnit);
                    dto.isValidateOnly = true;
                    response = await this.structureApiClient.updateOrganizationUnitCommandAsync(CreateRequestId(), dto);
                }

                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public updateOrganizationUnitPermissionCheckAsync() {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.structureApiClient.updateOrganizationUnitCommandAsync(CreateRequestId(),
                    getUpdateOrganizationUnitPermissionCheckDto()
                    , true);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public addOrganizationUnitAsync(organizationUnit: OrganizationUnit) {
        return this.processOperationAsync(
            new OrganizationUnit(),
            async target => {
                const response = await this.structureApiClient.addOrganizationUnitCommandAsync(CreateRequestId(), getAddUpdateOrganizationUnitDto(organizationUnit));

                target.operationInfo = createOperationInfo(response);
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);

                if (!target.hasValidationError) {
                    this.organizationUnitStoreMapper.applyToStore(target, response.organizationUnitDto);
                }
            }
        );
    }

    @State.bound
    public deleteOrganizationUnitAsync(organizationUnitId: OrganizationUnitId, rowVersion: RowVersion) {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.structureApiClient.deleteOrganizationUnitCommandAsync(CreateRequestId(), new Proxy.DeleteOrganizationUnitControllerDto({
                    id: organizationUnitId,
                    rowVersion: rowVersion
                }));
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getHealthCareProfessionsByIdsAsync(ids: HealthcareProfessionId[]) {
        return this.processOperationAsync(
            new SimpleStore<HealthcareProfession[]>(),
            async target => {
                const response = await this.structureApiClient.getHealthCareProfessionsByIdsQueryAsync(CreateRequestId(), new Proxy.GetHealthCareProfessionsByIdsControllerDto({
                    ids: ids
                }));
                target.operationInfo = createOperationInfo(response);
                this.healthcareProfessionsStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public getAllHealthCareProfessionIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<HealthcareProfessionId[]>(),
            async target => {
                const response = await this.structureApiClient.getAllHealthCareProfessionIdsQueryAsync(CreateRequestId());
                target.operationInfo = createOperationInfo(response);
                target.value = response?.healthCareProfessionIds;
            }
        );
    }

    @State.bound
    public getHealthcareProfessionIdsByIdentifierSystemIdAsync(identifierSystemId: IdentifierSystemId) {
        return this.processOperationAsync(
            new SimpleStore<HealthcareProfessionId[]>(),
            async target => {
                const response = await this.structureApiClient.getHealthcareProfessionIdsByIdentifierSystemIdQueryAsync(CreateRequestId(),
                    new Proxy.GetHealthcareProfessionIdsByIdentifierSystemIdControllerDto({ identifierSystemId: identifierSystemId }));
                target.operationInfo = createOperationInfo(response);
                target.value = response?.healthcareProfessionIds;
            }
        );
    }

    @State.bound
    public getExternalLocationsOfOrganizationUnitsAsync(ids: OrganizationUnitId[], validOn: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<ExternalLocationId[]>(),
            async target => {
                const response = await this.structureApiClient.getExternalLocationAssignmentsByOrganizationUnitIdsQueryAsync(CreateRequestId(),
                    new Proxy.GetExternalLocationAssignmentsByOrganizationUnitIdsControllerDto({
                        validOn: validOn,
                        organizationUnitIds: ids
                    }));
                target.operationInfo = createOperationInfo(response);
                target.value = response?.externalLocationAssignments.map(item => item.externalLocationId);
            }
        );
    }

    @State.bound
    public getHealthCareProfessionIdsByOrganizationUnitIdAsync(organizationUnitId: OrganizationUnitId) {
        return this.processOperationAsync(
            new SimpleStore<HealthcareProfessionId[]>(),
            async target => {
                const response = await this.structureApiClient.getHealthCareProfessionIdsByOrganizationUnitIdQueryAsync(CreateRequestId(), organizationUnitId.value);
                target.operationInfo = createOperationInfo(response);
                target.value = response?.healthCareProfessionIds;
            }
        );
    }

    @State.bound
    public getHealthCareProfessionIdsByOrganizationUnitIdAndIdentifierSystemIdAsync(organizationUnitId: OrganizationUnitId, identifierSystemId: IdentifierSystemId) {
        return this.processOperationAsync(
            new SimpleStore<HealthcareProfessionId[]>(),
            async target => {
                const response = await this.structureApiClient.getHealthCareProfessionIdsByOrganizationUnitIdAndIdentifierSystemIdQueryAsync(CreateRequestId(),
                    new Proxy.GetHealthCareProfessionIdsByOrganizationUnitIdAndIdentifierSystemIdControllerDto({
                        organizationUnitId,
                        identifierSystemId
                    }));
                target.operationInfo = createOperationInfo(response);
                target.value = response?.healthCareProfessionIds;
            }
        );
    }

    @State.bound
    public getPointOfCareIdsByHealthCareProfessionIdsQueryAsync(healthcareProfessionIds: HealthcareProfessionId[]) {
        return this.processOperationAsync(
            new SimpleStore<OrganizationUnitId[]>(),
            async target => {
                const response = await this.structureApiClient.getOrganizationUnitIdsByHealthCareProfessionIdsAndTagsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetOrganizationUnitIdsByHealthCareProfessionIdsAndTagsControllerDto({
                        healthCareProfessionIds: healthcareProfessionIds,
                        tags: ["PointOfCare"]
                    }));

                target.operationInfo = createOperationInfo(response);
                target.value = response.organizationUnitIds;
            }
        );
    }

    @State.bound
    public getRootOrganizationUnitIdAsync() {
        return this.processOperationAsync(
            new SimpleStore<OrganizationUnitId>(),
            async target => {
                const response = await this.structureApiClient.getRootOrganizationUnitIdQueryAsync(CreateRequestId());
                target.operationInfo = createOperationInfo(response);
                target.value = response.rootOrganizationUnitId;
            }
        );
    }
}
