import React, { useMemo } from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StaticWebAppResources from "@StaticResources";
import CountrySelector from "@HisPlatformControls/CountrySelector";
import GenderRadioButtonGroup from "@HisPlatform/BoundedContexts/Care/Components/Controls/PatientRegister/GenderRadioButtonGroup";
import * as Styles from "./ExtendedPatientFilter.less";
import PatientFilterStore from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/PatientWorklist/Filters/PatientFilterStore";
import SearchPatientQueryVitalStatus from "@HisPlatform/BoundedContexts/Care/Api/PatientRegister/Enum/SearchPatientQueryVitalStatus.g";

interface IExtendedPatientFilterProps {
    filterStore: PatientFilterStore;
}

function ExtendedPatientFilter(props: IExtendedPatientFilterProps) {
    const setVitalStatus = (isDeceasedOnly: boolean) => {
        props.filterStore.setVitalStatus(isDeceasedOnly ? SearchPatientQueryVitalStatus.Deceased : SearchPatientQueryVitalStatus.Unspecified);
    };

    const isDeceasedOnly = useMemo(() => {
        return props.filterStore.vitalStatus === SearchPatientQueryVitalStatus.Deceased;
    }, [props.filterStore.vitalStatus]);

    return (
        <div className={Styles.outerContainer}>
            <div className={Styles.searchContainer}>
                <Ui.Flex>
                    <Ui.Flex.Item xs={3}>
                        <Ui.TextBox label={StaticWebAppResources.SearchPatient.Label.PatientIdentifier}
                            value={props.filterStore.patientIdentifier}
                            onChange={props.filterStore.setPatientIdentifier}
                            automationId="patientIdentifierTextBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <Ui.TextBox label={StaticWebAppResources.SearchPatient.Label.CareIdentifier}
                            value={props.filterStore.careIdentifier}
                            onChange={props.filterStore.setCareIdentifier}
                            automationId="careIdentifierTextBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <Ui.TextBox label={StaticWebAppResources.SearchPatient.Label.ServiceRequestIdentifier}
                            value={props.filterStore.serviceRequestIdentifier}
                            onChange={props.filterStore.setServiceRequestIdentifier}
                            automationId="serviceRequestIdentifierTextBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <Ui.TextBox label={StaticWebAppResources.SearchPatient.Label.OtherIdentifiers}
                            value={props.filterStore.otherIdentifier}
                            onChange={props.filterStore.setDocument}
                            automationId="otherIdentifierTextBox"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={3}>
                        <CountrySelector label={StaticWebAppResources.SearchPatient.Label.Citizenship}
                            value={props.filterStore.citizenshipCountryId}
                            onChange={props.filterStore.setCitizenshipCountryId}
                            variant="citizenship"
                            automationId="citizenshipCountrySelector" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <GenderRadioButtonGroup label={StaticWebAppResources.SearchPatient.Label.Gender}
                            value={props.filterStore.genderId}
                            onChange={props.filterStore.setGenderId}
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <Ui.CheckBox label={StaticWebAppResources.SearchPatient.Label.SearchAmongDeceased}
                            value={isDeceasedOnly}
                            onChange={setVitalStatus}
                            verticalAlign="inlineInput"
                            automationId="searchAmongDeceasedCheckBox" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <></>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </div>
        </div>
    );
}

export default State.observer(ExtendedPatientFilter);
