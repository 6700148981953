import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import IExternalLocationVersion from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/External/IExternalLocation";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import IIdentifier from "@HisPlatform/Model/DomainModel/Common/IIdentifier";
import IIdentifierAssignment from "@HisPlatform/Model/DomainModel/Common/IIdentifierAssignment";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import {IOrderingState, IPagingState} from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import _ from "@HisPlatform/Common/Lodash";
import {buildQueryStringArray} from "@Toolkit/CommonWeb/QueryStringBuilder";
import EditableExternalLocationStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/External/EditableExternalLocationStore";
import EditableExternalLocationStoreMapper from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/External/EditableExternalLocationStoreMapper";
import ExternalLocationSelectorQueryOrderingFields from "@HisPlatform/BoundedContexts/Organization/Api/External/Enum/ExternalLocationSelectorQueryOrderingFields.g";

@Di.injectable()
export default class ExternalApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IExternalClient") private readonly externalApiClient: Proxy.IExternalClient,
        @Di.inject("EditableExternalLocationStoreMapper") private readonly editableExternalLocationStoreMapper: EditableExternalLocationStoreMapper
    ) {
        super();
    }

    @State.bound
    public getExternalLocationVersionsBySelectorsAsync(selectors: Array<IEntityVersionSelector<ExternalLocationId>>): Promise<SimpleStore<IExternalLocationVersion[]>> {
        return this.processOperationAsync(
            new SimpleStore<IExternalLocationVersion[]>(),
            async target => {
                const response = await this.externalApiClient.getExternalLocationVersionsBySelectorsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetExternalLocationVersionsBySelectorsControllerDto({
                        externalLocationSelectors: selectors.map(item => new Proxy.EntityVersionSelectorOfExternalLocationId(
                            {
                                entityId: item.id,
                                validOn: item.validOn || LocalDate.today()
                            }
                        ))
                    })
                );
                target.operationInfo = createOperationInfo(response);
                target.value = response.externalLocationVersions.map(this.mapExternalLocationVersion);
            }
        );
    }

    @State.bound
    public getAllExternalLocationIdsAsync(): Promise<SimpleStore<ExternalLocationId[]>> {
        return this.processOperationAsync(
            new SimpleStore<ExternalLocationId[]>(),
            async target => {
                const response = await this.externalApiClient.getAllExternalLocationIdsQueryAsync(CreateRequestId());
                target.operationInfo = createOperationInfo(response);
                target.value = response.externalLocationIds;
            }
        );
    }

    @State.bound
    public searchExternalLocationVersionSelectorsByTextAsync(searchString: string, maxResultCount: number, validOn?: LocalDate, explicitIds?: ExternalLocationId[]): Promise<SimpleStore<Array<IEntityVersionSelector<ExternalLocationId>>>> {
        return this.processOperationAsync(
            new SimpleStore<Array<IEntityVersionSelector<ExternalLocationId>>>(),
            async target => {
                const normalizedIds = _.uniq((explicitIds || []).filter(id => !!id).map(id => {
                    return id.value.toString();
                }));

                const response = await this.externalApiClient.searchExternalLocationIdsByTextQueryAsync(
                    CreateRequestId(),
                    buildQueryStringArray(normalizedIds),
                    maxResultCount.toString(),
                    searchString,
                    validOn ? validOn.stringify() : "");

                target.operationInfo = createOperationInfo(response);
                target.value = response.externalLocationIds.map(m => new EntityVersionSelector(m, response.validOn));
            }
        );
    }

    private mapExternalLocationVersion(dto: Proxy.ExternalLocationVersionDto): IExternalLocationVersion {
        return {
            id: dto.id,
            name: dto.baseData.name,
            shortName: dto.baseData.shortName,
            validity: new LocalDateRange(dto.baseData.validity.from, dto.baseData.validity.to),
            identifierAssignments: dto.identifiers && dto.identifiers.map(assignment => {
                return {
                    identifier: {
                        Id: new IdentifierSystemId(assignment.identifier.value),
                        value: assignment.identifier.value
                    } as IIdentifier,
                    validity: new LocalDateRange(assignment.validity.from, assignment.validity.to)
                } as IIdentifierAssignment;
            })
        } as IExternalLocationVersion;
    }

    @State.bound
    public searchExternalLocation(filter: string, validOn: LocalDate, ordering: IOrderingState, paging: IPagingState, explicitIds?: ExternalLocationId[]) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<IExternalLocationVersion>>(),
            async (target) => {
                const response = await this.externalApiClient.externalLocationSelectorQueryAsync(
                    CreateRequestId(),
                    new Proxy.ExternalLocationSelectorControllerDto({
                        filterText: filter,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfExternalLocationSelectorQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfExternalLocationSelectorQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: ExternalLocationSelectorQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        }),
                        validOn: validOn,
                        explicitExternalLocationId: explicitIds
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results && response.results.values.map(this.mapExternalLocationVersion)
                };
            }
        );
    }

    @State.bound
    public getEditableExternalLocationByIdAsync(externalLocationId: ExternalLocationId): Promise<EditableExternalLocationStore> {
        return this.processOperationAsync(
            new EditableExternalLocationStore(false),
            async target => {
                const response = await this.externalApiClient.getEditableExternalLocationByIdQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetEditableExternalLocationByIdControllerDto({ id: externalLocationId })
                );

                this.editableExternalLocationStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public addExternalLocationAsync(store: EditableExternalLocationStore, isValidateOnly: boolean = false) {
        return this.processOperationAsync(
            new EditableExternalLocationStore(false),
            async target => {
                const response = await this.externalApiClient.addExternalLocationCommandAsync(
                    CreateRequestId(),
                    new Proxy.AddExternalLocationControllerDto({
                        shortName: store.shortName,
                        name: store.name,
                        externalLocationTypeIds: store.externalLocationTypes,
                        identifiers: store.identifiers,
                        isValidateOnly: isValidateOnly
                    })
                );

                this.editableExternalLocationStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public updateExternalLocationAsync(store: EditableExternalLocationStore, isValidateOnly: boolean = false) {
        return this.processOperationAsync(
            new EditableExternalLocationStore(false),
            async target => {
                const response = await this.externalApiClient.updateExternalLocationCommandAsync(
                    CreateRequestId(),
                    new Proxy.UpdateExternalLocationControllerDto({
                        id: store.id,
                        rowVersion: store.rowVersion,
                        shortName: store.shortName,
                        name: store.name,
                        externalLocationTypeIds: store.externalLocationTypes,
                        identifiers: store.identifiers,
                        isValidateOnly: isValidateOnly
                    })
                );

                this.editableExternalLocationStoreMapper.applyToStore(target, response);
            }
        );
    }
}
