import React from "react";
import Styles from "./RssReader.less";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";

interface ILoadMoreItemsProps {
    onClick: () => void;
    content: React.ReactNode;
    className?: string;
}

const getClassName = (className?: string) => {
    const classNames = new CompositeClassName(Styles.loadMoreItems);
    if (className) {
        classNames.add(className);

    }
    return classNames.classNames;
};

const ShowMoreOrLessItemsButton: React.FC<ILoadMoreItemsProps> = props => {
    return (
        <div className={getClassName(props.className)}>
            <a onClick={props.onClick}>{props.content}</a>
        </div>
    );
};

export default ShowMoreOrLessItemsButton;
