import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientId from "@Primitives/PatientId.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { parseUseCaseStateFromUrlParam, getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import ConditionStatementId from "@Primitives/ConditionStatementId.g";
import PatientRoutes from "@HisPlatform/Components/Pages/Patient/PatientRoutes";
import IConditionStatementRouteParams from "@HisPlatform/Application/Routes/IConditionStatementRouteParams";
import CurrentConditionStatementListPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/ConditionStatement/CurrentConditionStatementListPanel";
import FormerConditionStatementListPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/ConditionStatement/FormerConditionStatementListPanel";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";


interface IConditionStatementPageProps extends IRoutingFrameContentProps {
}

@State.observer
class ConditionStatementPage extends React.Component<IConditionStatementPageProps> {

    @State.computed private get routeParams(): IConditionStatementRouteParams {
        return this.props.routingController.currentRoute.parameters as IConditionStatementRouteParams;
    }

    @State.computed private get selectedConditionStatementId(): ConditionStatementId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments[0].value;
        }

        if (this.useCaseState?.useCase.value === UseCases.conditionStatementDetail) {
            return new ConditionStatementId("new");
        }

        return null;
    }

    @State.computed
    private get patientId() {
        return new PatientId(this.routeParams?.patientId);
    }

    @State.computed private get useCaseState() {
        const useCaseState = this.routeParams?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.computed private get clinicalStatus() {
        return this.routeParams?.clinicalStatus;
    }

    @State.bound
    private setUseCaseState(selectedConditionStatementId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.replace(
            PatientRoutes.conditionStatement.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                clinicalStatus: this.clinicalStatus,
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            })
        );
    }

    @State.bound
    private navigateToConditionStatement(conditionStatementId: ConditionStatementId) {
        this.props.routingController.replace(
            PatientRoutes.conditionStatement.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                clinicalStatus: this.clinicalStatus,
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.conditionStatementDetail),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(conditionStatementId, "conditionStatementId"), new UseCaseArgument(this.clinicalStatus, "clinicalStatus")])
            })
        );
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(
            PatientRoutes.conditionStatement.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                clinicalStatus: this.clinicalStatus,
                useCase: getUseCaseAsUrlParam(
                    null,
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument(new ConditionStatementId("null"), "conditionStatementId"), new UseCaseArgument(this.clinicalStatus, "clinicalStatus")])
            })
        );
    }

    public render() {
        return (
            <LoadingBoundary>
                {this.clinicalStatus === 'active' &&
                    <CurrentConditionStatementListPanel
                        _patientId={this.patientId}
                        onChange={this.setUseCaseState}
                        useCaseState={this.useCaseState}
                        selectedRowId={this.selectedConditionStatementId?.value}
                        onSelectedConditionStatementIdChange={this.navigateToConditionStatement}
                        onBack={this.goBack}
                        onCloseDetail={this.setClosedState}
                    />}
                {this.clinicalStatus === 'inactive' &&
                    <FormerConditionStatementListPanel
                        _patientId={this.patientId}
                        onChange={this.setUseCaseState}
                        useCaseState={this.useCaseState}
                        selectedRowId={this.selectedConditionStatementId?.value}
                        onSelectedConditionStatementIdChange={this.navigateToConditionStatement}
                        onBack={this.goBack}
                        onCloseDetail={this.setClosedState}
                    />}
            </LoadingBoundary>
        );
    }
}

export default connect(
    ConditionStatementPage
);
