import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import PregnancyStatementId from "@Primitives/PregnancyStatementId.g";
import PregnancyStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/PregnancyStatementStore";
import PregnancyStatementStoreMapper from "./PregnancyStatementStoreMapper";
import { getAddPregnancyStatementControllerDto, getUpdatePregnancyStatementControllerDto, getDeletePregnancyStatementControllerDto, getDeletePregnancyStatementHistoryItemControllerDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/PregnancyStatement/PregnancyStatementDtoMapper";
import PatientId from "@Primitives/PatientId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";

@Di.injectable()
export default class PregnancyStatementApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IPregnancyStatementClient") private readonly apiClient: Proxy.IPregnancyStatementClient,
        @Di.inject("PregnancyStatementStoreMapper") private readonly storeMapper: PregnancyStatementStoreMapper
    ) {
        super();
    }

    public getByIdAsync(pregnancyStatementId: PregnancyStatementId, requestLock: boolean): Promise<PregnancyStatementStore> {
        return this.processOperationAsync(
            new PregnancyStatementStore(false),
            async target => {
                const response = await this.apiClient.getPregnancyStatementByIdCommandAsync(
                    CreateRequestId(),
                    new Proxy.GetPregnancyStatementByIdControllerDto({ pregnancyStatementId: pregnancyStatementId, requestLock: requestLock })
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public addPregnancyStatementAsync(store: PregnancyStatementStore, patientId: PatientId, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new PregnancyStatementStore(false),
            async target => {
                const response = await this.apiClient.addPregnancyStatementCommandAsync(
                    CreateRequestId(),
                    getAddPregnancyStatementControllerDto(store, patientId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public updatePregnancyStatementAsync(store: PregnancyStatementStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new PregnancyStatementStore(false),
            async target => {
                const response = await this.apiClient.updatePregnancyStatementCommandAsync(
                    CreateRequestId(),
                    getUpdatePregnancyStatementControllerDto(store, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deletePregnancyStatementHistoryItemAsync(store: PregnancyStatementStore, versionNumber: number, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new PregnancyStatementStore(false),
            async target => {
                const response = await this.apiClient.deletePregnancyStatementHistoryItemCommandAsync(
                    CreateRequestId(),
                    getDeletePregnancyStatementHistoryItemControllerDto(store, versionNumber, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deletePregnancyStatementAsync(store: PregnancyStatementStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<any>(),
            async target => {
                const response = await this.apiClient.deletePregnancyStatementCommandAsync(
                    CreateRequestId(),
                    getDeletePregnancyStatementControllerDto(store),
                    isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public validateAsync(store: PregnancyStatementStore, patientId: PatientId) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response =
                    store.isNew ?
                        await this.apiClient.addPregnancyStatementCommandAsync(CreateRequestId(), getAddPregnancyStatementControllerDto(store, patientId, true)) :
                        await this.apiClient.updatePregnancyStatementCommandAsync(CreateRequestId(), getUpdatePregnancyStatementControllerDto(store, true, store.lockInfo?.lockId, false));

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    public async checkPermissionForAddNewItemAsync(store: PregnancyStatementStore, patientId: PatientId): Promise<PregnancyStatementStore> {
        return await this.addPregnancyStatementAsync(store, patientId, true);        
    }

    public async checkPermissionForUpdateItemAsync(store: PregnancyStatementStore): Promise<PregnancyStatementStore> {
        return await this.updatePregnancyStatementAsync(store, true);
    }

    public async checkPermissionForDeleteItemAsync(store: PregnancyStatementStore): Promise<SimpleStore> {
        return await this.deletePregnancyStatementAsync(store, true);
    }
}
