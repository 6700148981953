import YearMonth from "@Toolkit/CommonWeb/YearMonth";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import moment from "moment";

export default class LocalDate {

    public constructor(
        private value: number = null
    ) {
    }

    public static createFromMoment(value: moment.Moment): LocalDate {
        if (!value) { return null; }
        return LocalDate.create(value.year(), value.month() + 1, value.date());
    }

    public static createFromDate(value: Date): LocalDate {
        if (!value) { return null; }
        return LocalDate.create(value.getUTCFullYear(), value.getUTCMonth() + 1, value.getUTCDate());
    }

    public static create(year: number, month: number, day: number): LocalDate {
        if (year === null || year === undefined || month === null || month === undefined || day === null || day === undefined) {
            return null;
        }
        return new LocalDate(moment.utc({ year, month: month - 1, day }).unix());
    }

    public stringify(): string {
        if (this.isEmpty()) {
            return "";
        }
        return moment.unix(this.value).format("YYYY-MM-DD");
    }

    public static parse(value: string): LocalDate {
        if (!value || value === "null" || value === "undefined") {
            return null;
        }

        const regexMatch = /(\d{4})-(\d{2})-(\d{2})/g.exec(value);

        if (value.length < 10 || !regexMatch || regexMatch.length < 4) {
            throw new Error("Cannot parse date string: " + value);
        }

        return LocalDate.create(parseInt(regexMatch[1], 10), parseInt(regexMatch[2], 10), parseInt(regexMatch[3], 10));
    }

    public toJSON() {
        if (this.isEmpty()) {
            return null;
        }

        const dateAsUnix = moment.unix(this.value);
        return {
            Year: dateAsUnix.year(),
            Month: dateAsUnix.month() + 1,
            Day: dateAsUnix.date()
        };
    }

    public getYear(): number {
        if (this.isEmpty()) {
            return null;
        }

        const dateAsUnix = moment.unix(this.value);
        return dateAsUnix.year();
    }

    public getMonth(): number {
        if (this.isEmpty()) {
            return null;
        }

        const dateAsUnix = moment.unix(this.value);
        return dateAsUnix.month() + 1;
    }

    public getDay(): number {
        if (this.isEmpty()) {
            return null;
        }

        const dateAsUnix = moment.unix(this.value);
        return dateAsUnix.day();
    }

    public static fromJS(data: any) {
        return data ? this.create(data.Year, data.Month, data.Day) : this.createEmpty();
    }

    public static isLocalDate(value: any) {
        if (value === null || value === undefined) {
            return false;
        }
        return value instanceof LocalDate;
    }

    /** Convert date to UTC day start moment. e.g.: 1991-08-08 will be 1991-08-08 00:00:00Z */
    public toUtcDayStartMoment(): moment.Moment {
        if (this.isEmpty()) {
            return null;
        }
        return moment.unix(this.value);
    }

    /** Convert date to local day start moment. e.g.: 1991-08-08 will be 1991-08-07 22:00:00Z (if time offset is +02:00) */
    public toLocalDayStartMoment(): moment.Moment {
        const utcMoment = this.toUtcDayStartMoment();
        return utcMoment ? moment({ year: utcMoment.year(), month: utcMoment.month(), day: utcMoment.date() }).utc() : null;
    }

    public toYearMonth(): YearMonth {
        if (this.isEmpty()) {
            return null;
        }
        const dateAsUnix = moment.unix(this.value);

        return new YearMonth(dateAsUnix.year(), dateAsUnix.month() + 1);
    }
   
    public static fromYearMonth(yearMonth: YearMonth): LocalDate {

        if (!yearMonth || yearMonth.isEmpty()) {
            return null;
        }
        return LocalDate.create(yearMonth.year, yearMonth.month, 1);
    }

    public static today() {
        return LocalDate.createFromMoment(DateTimeService.now());
    }

    public static createEmpty() {
        return new LocalDate(null);
    }

    public isEmpty(): boolean {
        return this.value === null || this.value === undefined;
    }

    public toUnix(): number {
        return this.value;
    }

    public equals(other: LocalDate) {
        return (other !== null && this.value === other.value);
    }

    public greaterThan(other: LocalDate) {
        return this.value > other.value;
    }

    public greaterThanOrEquals(other: LocalDate) {
        return this.value >= other.value;
    }

    public lessThan(other: LocalDate) {
        return this.value < other.value;
    }

    public lessThanOrEquals(other: LocalDate) {
        return this.value <= other.value;
    }

    public clone(): LocalDate {
        return new LocalDate(this.value);
    }

    public static isNullOrEmpty(localDate: LocalDate) {
        return localDate === null || localDate === undefined || localDate.isEmpty();
    }

    public static areEquals(one: LocalDate, two: LocalDate) {
        return (LocalDate.isNullOrEmpty(one) && LocalDate.isNullOrEmpty(two)) || (one && one.equals(two));
    }

    public plusDays(days: number): LocalDate {
        return LocalDate.createFromMoment(this.toUtcDayStartMoment().add(days, "days"));
    }

    public minusDays(days: number): LocalDate {
        return LocalDate.createFromMoment(this.toUtcDayStartMoment().subtract(days, "days"));
    }

    public plusMonths(months: number): LocalDate {
        return LocalDate.createFromMoment(this.toUtcDayStartMoment().add(months, "months"));
    }

    public firstDayOfMonth(): LocalDate {
        return LocalDate.create(this.getYear(), this.getMonth(), 1);
    }

    public getHashCode() {
        return this.value.toString();
    }
}