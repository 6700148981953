import DependencyContainer from "@DiContainer";
import CareExtensibleEnumApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/CareExtensibleEnumApiAdapter";
import MedicalServiceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicalServiceApiAdapter";
import ServiceProviderProfileApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ServiceProviderProfileApiAdapter";
import ObservationDefinitionApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ObservationDefinitionApiAdapter";
import StatusChangeReasonApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/StatusChangeReasonApiAdapter";
import PatientDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/Patient/PatientDocumentApiAdapter";
import ConditionsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ConditionsApiAdapter";
import ForwardingNeededAtDischargeApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ForwardingNeededAtDischargeApiAdapter";
import MedicationsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicationsApiAdapter";
import VaccinesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/VaccinesApiAdapter";
import AutonomyDisabilitiesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/AutonomyDisabilitiesApiAdapter";
import DevicesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/DevicesApiAdapter";
import InternationalNonproprietaryNamesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/InternationalNonproprietaryNamesApiAdapter";
import LifeStyleRisksApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/LifeStyleRisksApiAdapter";
import MedicalAlertsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/MedicalAlertsApiAdapter";

DependencyContainer
    .bind("CareExtensibleEnumApiAdapter")
    .to(CareExtensibleEnumApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("MedicalServiceApiAdapter")
    .to(MedicalServiceApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("ServiceProviderProfileApiAdapter")
    .to(ServiceProviderProfileApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("ObservationDefinitionApiAdapter")
    .to(ObservationDefinitionApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PatientDocumentApiAdapter")
    .to(PatientDocumentApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("StatusChangeReasonApiAdapter")
    .to(StatusChangeReasonApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("ConditionsApiAdapter")
    .to(ConditionsApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("ForwardingNeededAtDischargeApiAdapter")
    .to(ForwardingNeededAtDischargeApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("MedicationsApiAdapter")
    .to(MedicationsApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("VaccinesApiAdapter")
    .to(VaccinesApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("AutonomyDisabilitiesApiAdapter")
    .to(AutonomyDisabilitiesApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("DevicesApiAdapter")
    .to(DevicesApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("InternationalNonproprietaryNamesApiAdapter")
    .to(InternationalNonproprietaryNamesApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("LifeStyleRisksApiAdapter")
    .to(LifeStyleRisksApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("MedicalAlertsApiAdapter")
    .to(MedicalAlertsApiAdapter)
    .inSingletonScope();