import React from "react";
import { Treebeard, TreeNode, TreeDecorators, decorators } from "react-treebeard";
import ITreeViewNode from "@CommonControls/TreeView/ITreeViewNode";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import treeViewStyle from "@CommonControls/TreeView/TreeViewStyle";
import Icon from "@CommonControls/Icon";

interface ITreeViewProps {
    data?: ITreeViewNode[];
    css?: any;

    isSelectionControlled?: boolean;
    selectedNode?: ITreeViewNode;
    onSelectedNodeChange?: (selected: ITreeViewNode) => void;

    onNodeOpenStateChange?: (node: ITreeViewNode, isOpen: boolean) => void;
    onRenderDisplayValue?: (node: ITreeViewNode, treeLevel: number) => React.ReactNode;

    toggleRenderer?: () => React.ReactElement;
}

type ITreeNode = TreeNode & { ref?: ITreeViewNode };

@State.observer
export default class TreeView extends React.Component<ITreeViewProps> {

    public static defaultProps: Partial<ITreeViewProps> = {
        onNodeOpenStateChange: (node: ITreeViewNode, isOpen: boolean) => {
            node.isOpen = isOpen;
        },
        css: treeViewStyle,
        toggleRenderer: () => <Icon iconName="caretRight" style={{ top: 4, position: "absolute" }} />
    };

    @State.observable.ref private internalSelectedNode: ITreeViewNode = null;
    
    @State.computed private get selectedNode() {
        return this.props.isSelectionControlled ? this.props.selectedNode : this.internalSelectedNode;
    }

    @State.computed private get data(): ITreeNode[] {
        return (this.props.data || []).map((c) => this.mapNode(c));
    }

    private customizedDecorators: TreeDecorators = {
        ...decorators,
        Toggle: this.props.toggleRenderer
    };

    private mapNode(node: ITreeViewNode, treeLevel: number = 0): ITreeNode {
        return {
            id: node.key,
            active: node === this.selectedNode,
            children: !!node.children && node.children.map(c => this.mapNode(c, treeLevel + 1)),
            loading: node.isLoading,
            name: this.props.onRenderDisplayValue ? this.props.onRenderDisplayValue(node, treeLevel) : node.displayValue as any,
            toggled: !!node.children && node.isOpen,
            ref: node
        } as ITreeNode;
    }

    @State.action.bound
    private toggle(node: ITreeNode, isToggled: boolean) {
        this.internalSelectedNode = node.ref;
        
        if (node.ref!.isOpen !== isToggled && this.props.onNodeOpenStateChange) {
            this.props.onNodeOpenStateChange(node.ref, isToggled);
        }

        if (this.props.onSelectedNodeChange) {
            this.props.onSelectedNodeChange(node.ref);
        }
    }

    public render() {
        return (
            <Treebeard
                data={this.data}
                onToggle={this.toggle}
                style={this.props.css}
                decorators={this.customizedDecorators}
            />
        );
    }
}
