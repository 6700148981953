import React from "react";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IWorklistItemDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistItemDefinition";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import NDataRowActions from "./NDataRowActions";
import ItemVisibility from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/ItemVisibility.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import INDataAttributeService from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/INDataAttributeService";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import WorklistActionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistActionType.g";
import AttributeDisplayMode from "@HisPlatform/BoundedContexts/WebAppBackend/Api/FrontendLists/Enum/AttributeDisplayMode.g";
import MasterDetailDependentVisibility from "@HisPlatform/BoundedContexts/WebAppBackend/Api/FrontendLists/Enum/MasterDetailDependentVisibility.g";
import ItemPlacement from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/ItemPlacement.g";

interface INDataColumnsDependencies {
    localizationService: ILocalizationService;
    activityRegistry: IActivityRegistry;
    nDataAttributeService: INDataAttributeService;
}

interface INDataColumnsProps {
    _dependencies?: INDataColumnsDependencies;

    itemDefinitions: IWorklistItemDefinition[];
    isDetailOpen: boolean;

    hasExtraFilter: boolean;
    hasRefreshButton: boolean;
    hasPrintButton: boolean;
    orderingEnabled?: boolean;
    numberOfMaxPrimaryButtons: number;

    onActionAsync: (rowId: string, commandToken: string, argumentItems: WorkListArgument, worklistActionType: WorklistActionType, activityReference: string, clientSideAction?: ClientSideActionDto) => Promise<void>;

    actionsOnLeftSide?: boolean;
    referenceDate?: LocalDate;

    currentBatchActivityReference: string;
}

@State.observer
class NDataColumns extends React.Component<INDataColumnsProps> {

    private get localizationService() { return this.props._dependencies!.localizationService; }
    private get activityRegistry() { return this.props._dependencies!.activityRegistry; }

    @State.computed
    private get computedColumnWidth() {
        const buttonsCount = this.props.numberOfMaxPrimaryButtons > 3 ? 3 : this.props.numberOfMaxPrimaryButtons;

        const buttonWidth = 44;
        const primaryButtonsWidth = buttonWidth * buttonsCount;
        const headerCompactButtonsWidth =
            (this.props.hasRefreshButton ? buttonWidth : 0) +
            (this.props.hasPrintButton ? buttonWidth : 0) +
            (this.props.hasExtraFilter ? buttonWidth : 0);

        if (this.props.actionsOnLeftSide) {
            return primaryButtonsWidth;
        }
        return primaryButtonsWidth > headerCompactButtonsWidth ? primaryButtonsWidth : headerCompactButtonsWidth;
    }

    public render() {
        const actionsColumn = (
            <DataGridColumn
                width={this.computedColumnWidth}
                id="Actions"
                isFilterable={false}
            >
                <NDataRowActions
                    _activityRegistry={this.activityRegistry}
                    onActionAsync={this.props.onActionAsync}
                    currentBatchActivityReference={this.props.currentBatchActivityReference}
                />
            </DataGridColumn>
        );

        return (
            <>
                {this.props.itemDefinitions && this.props.actionsOnLeftSide && actionsColumn}
                {this.props.itemDefinitions?.filter(d => d.placement === ItemPlacement.Header || d.displayMode === AttributeDisplayMode.Column).map((d, index) => this.renderColumn(d, index)) ?? null}
                {this.props.itemDefinitions && !this.props.actionsOnLeftSide && actionsColumn}
            </>
        );
    }

    @State.action.bound
    private isColumnVisible(def: IWorklistItemDefinition) {
        if (this.props.isDetailOpen) {
            return def.itemVisibility === ItemVisibility.MasterDetail || def.itemVisibility === ItemVisibility.Detail ||
                def.masterDetailDependentVisibility === MasterDetailDependentVisibility.Always || def.masterDetailDependentVisibility === MasterDetailDependentVisibility.DetailOnly;
        }

        return def.itemVisibility === ItemVisibility.MasterDetail || def.itemVisibility === ItemVisibility.Master ||
            def.masterDetailDependentVisibility === MasterDetailDependentVisibility.Always || def.masterDetailDependentVisibility === MasterDetailDependentVisibility.MasterOnly;
    }

    private renderColumn(def: IWorklistItemDefinition, index: number) {
        const localizedHeader = isNullOrUndefined(def.displayValue) ? this.localizationService.localizeReferenceData(def.headerResourceId) : def.displayValue;
        const commonProps: IDataGridColumnBaseProps & { key: any, validOn: LocalDate } = {
            key: index,
            id: def.attributeName,
            filterStoreValueGetter: def.attributeName,
            filterStoreValueSetter: "set_" + def.attributeName,
            header: isNullOrUndefined(localizedHeader) || localizedHeader === "" ? def.headerResourceId.value : localizedHeader,
            dataGetter: def.attributeName,
            isOrderable: isNullOrUndefined(this.props.orderingEnabled) ? !def.isOrderingDisabled : this.props.orderingEnabled,
            isFilterable: def.filterConditionType !== WorklistConditionType.None,
            isVisible: this.isColumnVisible(def),
            validOn: this.props.referenceDate
        };

        if (!!def.defaultColumnWidth) {
            commonProps.width = def.defaultColumnWidth;
        }

        if (!!def.ellipsisOnOverflow) {
            commonProps.hideOverflow = def.ellipsisOnOverflow;
        }

        return this.props._dependencies!.nDataAttributeService.renderColumn(def.attributeType, def.attributeName, commonProps);
    }
}

export default connect(
    NDataColumns,
    new DependencyAdapter<INDataColumnsProps, INDataColumnsDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
        activityRegistry: c.resolve("IActivityRegistry"),
        nDataAttributeService: c.resolve("INDataAttributeService"),
    }))
);