import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import * as Ui from "@CommonControls";
import SchedulingServiceSelectBox from "@HisPlatform/BoundedContexts/Scheduling/Components/Controls/SchedulingServiceSelectBox/SchedulingServiceSelectBox";
import PointOfCareSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareSelectBox";
import PractitionerSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerSelectBox";
import ComplexSchedulingServiceFilter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ComplexSchedulingServiceFilter";
import SchedulingServiceFilterType from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/SchedulingServiceFilterType.g";
import SeparatedTimeRangePicker from "@CommonControls/SeparatedTimeRangePicker/SeparatedTimeRangePicker";

interface IComplexSchedulingServiceFilterPanelViewProps {
    readonly: boolean;
    setLoadingState?: (state: boolean) => void;
    complexSchedulingServiceFilter: ComplexSchedulingServiceFilter;
    explicitIds: any;
    isFirstCheckedInList: boolean;
    isLastCheckedInList: boolean;
}

@State.observer
export default class ComplexSchedulingServiceFilterPanelView extends React.Component<IComplexSchedulingServiceFilterPanelViewProps> {

    private readonly resources = StaticSchedulingResources.SchedulingServiceFilterPanel;
    private readonly complexSchedulingServiceFilterPanelResources = StaticSchedulingResources.ComplexSchedulingServiceFilterPanel;

    public render() {
        return (
            <>
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <SchedulingServiceSelectBox
                            propertyIdentifier="SchedulingServiceId"
                            value={this.props.complexSchedulingServiceFilter.schedulingServiceId}
                            label={this.resources.SchedulingService}
                            onChange={this.props.complexSchedulingServiceFilter.setSchedulingServiceId}
                            isReadOnly={this.props.readonly}
                            explicitIds={this.props.explicitIds.explicitSchedulingServiceIds}
                            setLoadingState={this.props.setLoadingState}
                            automationId="schedulingServiceSelectBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4} />
                    <Ui.Flex.Item xs={2}>
                        <Ui.CheckBox
                            label={this.complexSchedulingServiceFilterPanelResources.IsFirst}
                            value={this.props.complexSchedulingServiceFilter.isFirst}
                            onChange={this.props.complexSchedulingServiceFilter.setIsFirst}
                            disabled={!this.props.complexSchedulingServiceFilter.isFirst && this.props.isFirstCheckedInList}
                            propertyIdentifier="IsFirst"
                            automationId="isFirst"
                            verticalAlign="inlineInput"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={2}>
                        <Ui.CheckBox
                            label={this.complexSchedulingServiceFilterPanelResources.IsLast}
                            value={this.props.complexSchedulingServiceFilter.isLast}
                            onChange={this.props.complexSchedulingServiceFilter.setIsLast}
                            disabled={!this.props.complexSchedulingServiceFilter.isLast && this.props.isLastCheckedInList}
                            propertyIdentifier="IsLast"
                            automationId="isLast"
                            verticalAlign="inlineInput"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <PointOfCareSelectBox
                            label={this.resources.PointOfCare}
                            value={this.props.complexSchedulingServiceFilter.pointOfCareId}
                            onChange={this.props.complexSchedulingServiceFilter.setPointOfCareId}
                            explicitIds={this.props.explicitIds.explicitPointOfCareIds}
                            isReadOnly={this.props.readonly}
                            automationId="pointOfCareSelectBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <Ui.UniversalEnumSelector
                            label={this.resources.PointOfCareFilterType}
                            enumOrigin="server"
                            displayMode="groupedRadioButtons"
                            enumName={"SchedulingServiceFilterType"}
                            enumType={SchedulingServiceFilterType}
                            value={this.props.complexSchedulingServiceFilter.pointOfCareFilterType}
                            onChange={this.props.complexSchedulingServiceFilter.setPointOfCareFilterType}
                            automationId="pointOfCareFilterTypeSelector"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <PractitionerSelectBox
                            label={this.resources.Practitioner}
                            onChange={this.props.complexSchedulingServiceFilter.setPractitionerId}
                            value={this.props.complexSchedulingServiceFilter.practitionerId}
                            explicitIds={this.props.explicitIds.explicitPractitionerIds}
                            isReadOnly={this.props.readonly}
                            automationId="practitionerSelectBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <Ui.UniversalEnumSelector
                            label={this.resources.PractitionerFilterType}
                            enumOrigin="server"
                            displayMode="groupedRadioButtons"
                            enumName={"SchedulingServiceFilterType"}
                            enumType={SchedulingServiceFilterType}
                            value={this.props.complexSchedulingServiceFilter.practitionerFilterType}
                            onChange={this.props.complexSchedulingServiceFilter.setPractitionerFilterType}
                            automationId="practitionerFilterTypeSelector"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <SeparatedTimeRangePicker
                    label={this.complexSchedulingServiceFilterPanelResources.IntraDayTimePeriodConstraintLabel}
                    onTimeRangePickerChange={this.props.complexSchedulingServiceFilter.setIntraDayTimePeriodConstraint}
                    initFrom={this.props.complexSchedulingServiceFilter.intraDayTimePeriodConstraintStart}
                    initTo={this.props.complexSchedulingServiceFilter.intraDayTimePeriodConstraintEnd}
                />
                {this.props.complexSchedulingServiceFilter.recurring &&
                    <>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={3}>
                                <Ui.NumberBox
                                    label={this.complexSchedulingServiceFilterPanelResources.NumberOfRepetitions}
                                    value={this.props.complexSchedulingServiceFilter.numberOfRepetitions}
                                    onChange={this.props.complexSchedulingServiceFilter.setNumberOfRepetitions}
                                    propertyIdentifier="NumberOfRepetitions"
                                    automationId="numberOfRepetitions"
                                    integerOnly
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <Ui.NumberBox
                                    label={this.complexSchedulingServiceFilterPanelResources.MinimumTimeBetweenRepetitions}
                                    value={this.props.complexSchedulingServiceFilter.minimumTimeBetweenRepetitions}
                                    onChange={this.props.complexSchedulingServiceFilter.setMinimumTimeBetweenRepetitions}
                                    propertyIdentifier="MinimumTimeBetweenRepetitions"
                                    automationId="minimumTimeBetweenRepetitions"
                                    integerOnly
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={5}>
                                <Ui.NumberBox
                                    label={this.complexSchedulingServiceFilterPanelResources.MaximumTimeBetweenRepetitions}
                                    value={this.props.complexSchedulingServiceFilter.maximumTimeBetweenRepetitions}
                                    onChange={this.props.complexSchedulingServiceFilter.setMaximumTimeBetweenRepetitions}
                                    propertyIdentifier="MaximumTimeBetweenRepetitions"
                                    automationId="maximumTimeBetweenRepetitions"
                                    integerOnly
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <Ui.Flex>
                            <Ui.NumberBox
                                label={this.complexSchedulingServiceFilterPanelResources.MaxRepetitionPeriodInDays}
                                value={this.props.complexSchedulingServiceFilter.maxRepetitionPeriodInDays}
                                onChange={this.props.complexSchedulingServiceFilter.setMaxRepetitionPeriodInDays}
                                propertyIdentifier="MaxRepetitionPeriodInDays"
                                automationId="maxRepetitionPeriodInDays"
                                integerOnly
                            />
                        </Ui.Flex>
                    </>
                }
            </>
        );
    }
}
