import IPrescriptionExtensionDataProvider from "@PluginInterface/BoundedContexts/MedicationRequest/Prescription/IPrescriptionExtensionDataProvider";
import Di from "@Di";
import PatientId from "@Primitives/PatientId.g";

@Di.injectable()
export default class DefaultPrescriptionExtensionDataProvider implements IPrescriptionExtensionDataProvider {
    
    public getExtensionDataOrEmpty() {
        return {};
    }
    public getExtensionDataOrLogin() {
        return null as any;
    }
}