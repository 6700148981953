import Di from "@Di";
import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import RoleApiAdapter from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/OperationAccessControl/RoleApiAdapter";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";

@Di.injectable()
export default class AuthorizationReferenceDataStore extends ReferenceDataStoreBase {

    constructor(@Di.inject("RoleApiAdapter") private readonly permissionApiAdapter: RoleApiAdapter) {
        super();
    }

    public roleMap = new ReferenceDataStore(this.permissionApiAdapter.getRolesByIdsAsync, this.permissionApiAdapter.getAllRoleIdsAsync);
}
