import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientId from "@Primitives/PatientId.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { parseUseCaseStateFromUrlParam, getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import PatientAllergyIntoleranceId from "@Primitives/PatientAllergyIntoleranceId.g";
import PatientAllergyIntoleranceListPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceListPanel";
import PatientRoutes from "@HisPlatform/Components/Pages/Patient/PatientRoutes";
import IPatientAllergyIntoleranceRouteParams from "@HisPlatform/Application/Routes/IPatientAllergyIntoleranceRouteParams";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";


interface IPatientAllergyIntolerancePageProps extends IRoutingFrameContentProps {
}

@State.observer
class PatientAllergyIntolerancePage extends React.Component<IPatientAllergyIntolerancePageProps> {

    @State.computed private get routeParams(): IPatientAllergyIntoleranceRouteParams {
        return this.props.routingController.currentRoute.parameters as IPatientAllergyIntoleranceRouteParams;
    }

    @State.computed private get selectedPatientAllergyIntoleranceId(): PatientAllergyIntoleranceId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments[0].value;
        }

        if (this.useCaseState?.useCase.value === UseCases.patientAllergyIntoleranceDetail) {
            return new PatientAllergyIntoleranceId("new");
        }

        return null;
    }

    @State.computed
    private get patientId() {
        return new PatientId(this.routeParams?.patientId);
    }

    @State.computed private get useCaseState() {
        const useCaseState = this.routeParams?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.bound
    private setUseCaseState(selectedPatientAllergyIntoleranceId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.replace(
            PatientRoutes.patientAllergyIntolerance.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            })
        );
    }

    @State.bound
    private navigateToPatientAllergyIntolerance(patientAllergyIntoleranceId: PatientAllergyIntoleranceId) {
        this.props.routingController.replace(
            PatientRoutes.patientAllergyIntolerance.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.patientAllergyIntoleranceDetail),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(patientAllergyIntoleranceId, "patientAllergyIntoleranceId")])
            })
        );
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(
            PatientRoutes.patientAllergyIntolerance.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    null,
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument(new PatientAllergyIntoleranceId("null"), "patientAllergyIntoleranceId")])
            })
        );
    }

    public render() {
        return (
            <LoadingBoundary>
                <PatientAllergyIntoleranceListPanel
                    _patientId={this.patientId}
                    onChange={this.setUseCaseState}
                    useCaseState={this.useCaseState}
                    selectedRowId={this.selectedPatientAllergyIntoleranceId?.value}
                    onSelectedPatientAllergyIntoleranceIdChange={this.navigateToPatientAllergyIntolerance}
                    onBack={this.goBack}
                    onCloseDetail={this.setClosedState}
                />
            </LoadingBoundary>
        );
    }
}

export default connect(
    PatientAllergyIntolerancePage
);
