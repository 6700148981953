import DependencyContainer from "@DiContainer";
import IMedioWebAppResources from "@MedioPlugin/StaticResources/IMedioWebAppResources";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import {createStaticResourceDictionary} from "@Toolkit/ReactClient/Common/LocalizedStrings";

const resources = createStaticResourceDictionary<IMedioWebAppResources>();

export function loadStaticMedioWebAppResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    resources.load(localizationService.getResourceGroup("WebApp"));
}

export default resources as IMedioWebAppResources;
