import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import * as Ui from "@CommonControls";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import RiskAssessmentStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/RiskAssessmentStore";
import RiskAssessmentId from "@Primitives/RiskAssessmentId.g";
import RiskAssessmentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/RiskAssessment/RiskAssessmentApiAdapter";
import { LifeStyleRiskCodeSelectorItem } from "@HisPlatformControls/LifeStyleRiskCodeSelector/LifeStyleRiskCodeSelectorItem";
import RiskAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/RiskAssignmentBase";
import ReferencedLifeStyleRiskAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/ReferencedLifeStyleRiskAssignment";
import LifeStyleRiskId from "@Primitives/LifeStyleRiskId.g";
import CustomRiskAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/CustomRiskAssignment";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import { LifeStyleRiskCodeSelector } from "@HisPlatformControls";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import MedicalConditionHistoryItemDetailPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicalConditionHistoryItemDetailPanel";
import RiskAssessmentFactory from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/RiskAssessmentFactory";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import PatientId from "@Primitives/PatientId.g";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import RiskAssessmentHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/RiskAssessmentHistoryItem";
import MedicalConditionItemHeaderPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicalConditionItemHeaderPanel";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import { EntityLockState } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import IMedicalConditionReferenceDataResolver from "@HisPlatform/BoundedContexts/Care/Services/Definition/MedicalConditionReferenceDataResolver/IMedicalConditionReferenceDataResolver";
import IServiceRequestHeaderExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestHeaderExtensionPointProps";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import FormExtensionRegistryAdapter from "@PluginInterface/FormExtension/FormExtensionRegistryAdapter";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";

interface IRiskAssessmentPanelDependencies {
    dialogService: IDialogService;
    notificationService: INotificationService;
    riskAssessmentApiAdapter: RiskAssessmentApiAdapter;
    riskAssessmentFactory: RiskAssessmentFactory;
    lockingApiAdapter: LockingApiAdapter;
    medicalConditionReferenceDataResolver: IMedicalConditionReferenceDataResolver;
}

interface IRiskAssessmentPanelProps extends IRoutingFrameContentProps {
    _dependencies?: IRiskAssessmentPanelDependencies;
    _patientId?: PatientId;
    _formExtension?: IFormExtension<PatientId>;

    id: RiskAssessmentId;
    onRiskAssessmentCreatedAsync?: (riskAssessmentId: RiskAssessmentId) => Promise<void>;
    onCloseDetailAsync: () => Promise<void>;
    onRefreshListAsync: () => Promise<void>;
}

@State.observer
class RiskAssessmentPanel extends React.Component<IRiskAssessmentPanelProps> {

    private get riskAssessmentId() { return this.props.id; }
    private get patientId() { return this.props._patientId; }
    @State.observable.ref private store: RiskAssessmentStore = this.riskAssessmentFactory.createNewStore();
    @State.computed private get currentHistoryItem() { return this.store.currentHistoryItem as RiskAssessmentHistoryItem; }
    @State.computed private get isNew() { return this.riskAssessmentId.value === "new"; }

    private get dialogService() { return this.props._dependencies.dialogService; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get riskAssessmentApiAdapter() { return this.props._dependencies.riskAssessmentApiAdapter; }
    private get riskAssessmentFactory() { return this.props._dependencies.riskAssessmentFactory; }
    private get lockingApiAdapter() { return this.props._dependencies.lockingApiAdapter; }
    private get medicalConditionReferenceDataResolver() { return this.props._dependencies.medicalConditionReferenceDataResolver; }

    private get resources() { return StaticCareResources.MedicalCondition.RiskAssessments; }
    private get medicalConditionResources() { return StaticCareResources.MedicalCondition; }
    private get labels() { return this.resources.Label; }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(
        this.loadAsync,
        this.isNew
            ? async () => await this.props._dependencies.riskAssessmentApiAdapter.checkPermissionForAddNewItemAsync(this.riskAssessmentFactory.createNewStore(), new PatientId("1"))
            : () => Promise.resolve([])
    );

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IRiskAssessmentPanelProps) {
        if (prevProps.id.value !== this.props.id.value) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.action.bound
    private async loadAsync(forceReleaseLockNeeded: boolean = false) {
        if (forceReleaseLockNeeded && !!this.store?.lockInfo?.preventingLockId) {
            await this.lockingApiAdapter.forceReleaseLockAsync(this.store.lockInfo.preventingLockId);
        }

        let store = null;
        if (this.isNew) {
            store = this.riskAssessmentFactory.createNewStore();
        } else {
            store = await this.riskAssessmentApiAdapter.getByIdAsync(this.riskAssessmentId, true);
        }
        this.store.setExtensionData(store.extensionData);
        await this.setStoreAsync(store);
    }

    @State.action.bound
    private async setStoreAsync(newValue: RiskAssessmentStore) {
        this.store = newValue;
        const riskName = await this.medicalConditionReferenceDataResolver.getLifeStyleRiskNameAsync(this.store.lifeStyleRiskCodeSelectorItem);
        this.store.setLifeStyleRiskName(riskName);
        this.store.takeSnapshot();
    }

    @State.bound
    private async navigateAwayAsync() {
        if (this.store?.isMutable === true && this.isUnsavedOrDirty()) {
            const dialogResult = await this.dialogService.confirmIfNotSaved(StaticCareResources.Common.Dialog.ConfirmationTitle, StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);
            if (dialogResult.resultCode === DialogResultCode.Yes) {
                await this.saveStoreAsync();
                await this.releaseLockAsync();
            } else if (dialogResult.resultCode === DialogResultCode.No) {
                await this.releaseLockAsync();
                return true;
            }
            return false;
        }
        await this.releaseLockAsync();
        return true;
    }

    @State.action.bound
    private async releaseLockAsync() {
        if (this.store.isMutable && this.store.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld) {
            await this.lockingApiAdapter.releaseLockAsync(this.store.lockInfo.lockId);
            this.store.releaseLock();
            return true;
        }
        return false;
    }

    private isUnsavedOrDirty() {
        return this.store.isDirty() && this.currentHistoryItem.isNew;
    }

    @State.action.bound
    private async validateAsync(): Promise<IClientValidationResult[]> {
        if (!this.store || !this.store.isMutable || !this.currentHistoryItem.isNew) {
            return [];
        }
        const validationResultResponse = await this.riskAssessmentApiAdapter.validateAsync(this.store, this.patientId);
        return validationResultResponse.value;
    }

    @State.action.bound
    private async validateBeforeSaveAsync() {
        const newValue = await this.validateAsync();
        this.store.setValidationResults(newValue);
    }

    @State.action.bound
    private async saveStoreAsync() {
        await this.validateBeforeSaveAsync();

        if (this.store.hasValidationError) {
            return false;
        }

        await this.setExtensionDataAsync();

        let newStore = null;

        if (this.isNew) {
            newStore = await this.riskAssessmentApiAdapter.addRiskAssessmentAsync(this.store, this.patientId);
        } else {
            newStore = await this.riskAssessmentApiAdapter.updateRiskAssessmentAsync(this.store);
        }

        if (newStore.operationWasSuccessful && newStore.isPersistedByOperationInfo && this.isNew) {
            this.store.setExtensionData(newStore.extensionData);
            this.store.takeSnapshot();
            await this.props.onRiskAssessmentCreatedAsync?.(newStore.id);
            return true;
        }

        await this.replaceStoreAsync(newStore);
        await this.props.onRefreshListAsync();
        return true;
    }

    @State.action.bound
    private async setExtensionDataAsync() {
        const extensionDataArray = await this.props._formExtension.invokeCallbackAsync<any>("ExtendStore", this.props._patientId);

        let extensionDataForStore = this.store.extensionData;
        for (const extensionData of extensionDataArray) {
            extensionDataForStore = { ...extensionData, ...extensionDataForStore };
        }

        this.store.setExtensionData(extensionDataForStore);
    }

    @State.action.bound
    private async replaceStoreAsync(newStore: RiskAssessmentStore) {
        if (newStore.operationWasSuccessful) {
            if (newStore.isPersistedByOperationInfo) {
                newStore.lockInfo = this.store.lockInfo;
                await this.setStoreAsync(newStore);
                this.notificationService.showSavedSuccessfully();
            } else {
                State.runInAction(() => {
                    this.store.validationResults = newStore.validationResults;
                });
            }
        }
    }

    @State.action.bound
    private async deleteHistoryItemAsync() {
        const message = formatString(this.resources.Dialog.DeleteVersionConfirmationMessage, (this.store.currentItemIndex + 1).toString());
        const dialogResult = await this.dialogService.yesNo(StaticCareResources.Common.Dialog.ConfirmationTitle, message);

        if (dialogResult.resultCode === DialogResultCode.No) {
            return false;
        }

        await this.setExtensionDataAsync();

        if (this.store.historyItems.length === 1) {
            await this.riskAssessmentApiAdapter.deleteRiskAssessmentAsync(this.store);
            this.store.releaseLock();
            this.props.onCloseDetailAsync();
            return true;
        } else {
            const newStore = await this.riskAssessmentApiAdapter.deleteRiskAssessmentHistoryItemAsync(this.store, this.store.currentHistoryItem.versionNumber);

            await this.replaceStoreAsync(newStore);
            return true;
        }
    }

    @State.action.bound
    private async forceLoadAsync() {
        await this.loadAsync(true);
    }

    @State.computed
    private get errorExtensionProps() {
        return {
            extensionData: this.store.currentHistoryItem.extensionData
        } as IServiceRequestHeaderExtensionPointProps;
    }

    @State.bound
    private showNoEhiCompatibleIdentifierFoundError() {
        this.notificationService.error(this.medicalConditionResources.Common.Messages.NoEhiCompatibleIdentifierFoundErrorMessage);
        return true;
    }

    @State.computed
    public get renderErrorExtension() {
        return (
            <HisPlatformExtensionPoint
                type="ServiceRequestHeader"
                extensionProps={this.errorExtensionProps}>
                <></>
            </HisPlatformExtensionPoint>
        );
    }

    public render() {
        const prefix = this.store.validationPropertyPrefix;
        const riskName = this.store.isNew ?
            StaticCareResources.MedicalCondition.Common.Label.NewEntry : this.store.lifeStyleRiskName;

        return (
            <PermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                <BusinessErrorHandler.Register businessErrorName="NoEhiCompatibleIdentifierFoundError" handler={this.showNoEhiCompatibleIdentifierFoundError} />
                <UseCaseFrame
                    toolbar={(
                        <MedicalConditionItemHeaderPanel
                            store={this.store}
                            onDeleteHistoryItemAsync={this.deleteHistoryItemAsync}
                            onSaveStoreAsync={this.saveStoreAsync}
                            onLockEditClickedAsync={this.forceLoadAsync} />
                    )}
                    title={riskName}>
                    {this.initialLoadPanelAsync.isUnauthorizedAccess ? <UnauthorizedAccessContent /> :
                        <>
                            {this.renderErrorExtension}
                            <ValidationBoundary
                                validationResults={this.store.validationResults}
                                entityTypeName="RiskAssessment"
                                onValidateAsync={this.validateAsync}
                                validateOnMount
                            >
                                <ReadOnlyContext.Provider value={!this.store.isMutable || !this.currentHistoryItem.isNew}>
                                    <Ui.Flex>
                                        <Ui.Flex.Item sm={6}>
                                            <LifeStyleRiskCodeSelector
                                                label={this.labels.RiskAssignment}
                                                value={this.store.lifeStyleRiskCodeSelectorItem}
                                                onChange={this.setRiskAssignment}
                                                isReadOnly={!this.store.isNew}
                                                propertyIdentifier="LifestyleRiskIdOrName"
                                                automationId="__riskId"
                                            />
                                        </Ui.Flex.Item>
                                    </Ui.Flex>
                                    <Ui.TextBox
                                        label={this.labels.Description}
                                        value={this.currentHistoryItem.riskDetail.description}
                                        onChange={this.currentHistoryItem.riskDetail.setDescription}
                                        propertyIdentifier={`${prefix}.RiskDetail.Description`}
                                        automationId="__riskDescription"
                                    />
                                    <Ui.TextBox
                                        label={this.labels.PeriodDescription}
                                        value={this.currentHistoryItem.riskDetail.periodDescription}
                                        onChange={this.currentHistoryItem.riskDetail.setPeriodDescription}
                                        propertyIdentifier={`${prefix}.RiskDetail.PeriodDescription`}
                                        automationId="__riskPeriodDescription"
                                    />
                                    <br />
                                    <MedicalConditionHistoryItemDetailPanel store={this.store} prefix={prefix} />
                                </ReadOnlyContext.Provider>
                            </ValidationBoundary>
                            <NavigateAwayHook onNavigateAwayAsync={this.navigateAwayAsync} />
                        </>
                    }
                </UseCaseFrame>
            </PermissionCheckContextProvider>
        );
    }

    @State.computed private get permissionCheckedOperations() {
        const res = {};

        const store = this.riskAssessmentFactory.createNewStore();
        store.id = new RiskAssessmentId("1");

        if (this.props.id) {
            res["AddNewVersion"] = async () => await this.props._dependencies.riskAssessmentApiAdapter.checkPermissionForUpdateItemAsync(store);
            res["DeleteVersion"] = async () => await this.props._dependencies.riskAssessmentApiAdapter.checkPermissionForDeleteItemAsync(store);
        }

        return res;
    }

    @State.bound
    private setRiskAssignment(newValue: LifeStyleRiskCodeSelectorItem) {
        let riskAssignment: RiskAssignmentBase;
        if (!newValue) {
            riskAssignment = new ReferencedLifeStyleRiskAssignment(null);
        } else if (newValue.id instanceof LifeStyleRiskId) {
            riskAssignment = new ReferencedLifeStyleRiskAssignment(newValue.id);
        } else {
            riskAssignment = new CustomRiskAssignment(newValue.text);
        }
        this.store.setRiskAssignment(riskAssignment);
    }
}

export default connect(
    RiskAssessmentPanel,
    new DependencyAdapter<IRiskAssessmentPanelProps, IRiskAssessmentPanelDependencies>(c => ({
        dialogService: c.resolve<IDialogService>("IDialogService"),
        notificationService: c.resolve<INotificationService>("INotificationService"),
        riskAssessmentApiAdapter: c.resolve<RiskAssessmentApiAdapter>("RiskAssessmentApiAdapter"),
        riskAssessmentFactory: c.resolve<RiskAssessmentFactory>("RiskAssessmentFactory"),
        lockingApiAdapter: c.resolve<LockingApiAdapter>("LockingApiAdapter"),
        medicalConditionReferenceDataResolver: c.resolve("IMedicalConditionReferenceDataResolver"),
    })),
    new PatientContextAdapter<IRiskAssessmentPanelProps>(c => ({
        _patientId: c.patientId
    })),
    new FormExtensionRegistryAdapter("ManageMedicalCondition")
);
