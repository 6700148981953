import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import IdentifierWithSourceType from "@Toolkit/CommonWeb/Model/IdentifierWithSourceType";

interface IMergedPatientIdentifierColumnDependencies {
    localizationService: ILocalizationService;
    commonReferenceDataStore: CommonReferenceDataDataStore;
}

interface IMergedPatientIdentifierColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IMergedPatientIdentifierColumnDependencies;
}


const MergedPatientIdentifierColumn: React.FC<IMergedPatientIdentifierColumnProps> = (props: IMergedPatientIdentifierColumnProps) => {

    const renderStringValue = useCallback((value: IdentifierWithSourceType[]) => {
        if (isNullOrUndefined(value) || !value.length) {
            return "";
        }
        return value.map(i => {
            if (i.identifier.identifierSystemId.value === "InternalIdentifier") {
                return `${StaticCareResources.Common.InternalIdentifier}: ${i.identifier.value}`;
            }
            const identifierSystemString = props._dependencies.commonReferenceDataStore.identifierSystemMap.getLocalization(i.identifier.identifierSystemId);
            return `${identifierSystemString?.Name}: ${i.identifier.value}`;
        }).join(", ");
    }, []);

    const valueRenderer = useCallback((value: IdentifierWithSourceType[]) => {
        return (
            <HisPlatformExtensionPoint
                extensionProps={{ identifiersWithSourceTypes: value }}
                type="identifierWithSourceTypeDisplay">
                <>
                    {value?.map(i => i.identifier.value)}
                </>
            </HisPlatformExtensionPoint>
        );
    }, []);

    return (
        <DataGridColumn
            {...props}
            isOrderable={false}
            onRenderCellValue={valueRenderer}
            onRenderHintValue={renderStringValue}
        />
    );
};

export default connect(
    MergedPatientIdentifierColumn,
    new DependencyAdapter<IMergedPatientIdentifierColumnProps, IMergedPatientIdentifierColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
        commonReferenceDataStore: c.resolve("CommonReferenceDataDataStore")
    }))
);
