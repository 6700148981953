exports = module.exports = require("../../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ExtendedPatientFilter_search-container_3T63n {\n  background: #f4f7fc;\n  border: solid 1px #00b4ec;\n  padding: 5px 4px 8px;\n  margin: 8px 8px 0px;\n}\n.ExtendedPatientFilter_outer-container_17Cf7 {\n  margin-top: 10px;\n}\n.ExtendedPatientFilter_outer-container_17Cf7 label {\n  margin: 0px 4px;\n  font-family: PragatiNarrow;\n  font-size: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"search-container": "ExtendedPatientFilter_search-container_3T63n",
	"searchContainer": "ExtendedPatientFilter_search-container_3T63n",
	"outer-container": "ExtendedPatientFilter_outer-container_17Cf7",
	"outerContainer": "ExtendedPatientFilter_outer-container_17Cf7"
};