import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PerformanceStatementCareActivityApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/PerformanceStatementCareActivityApiAdapter";
import CareActivityContextProvider from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextProvider";
import PatientContextProvider from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextProvider";
import PerformanceStatementCareActivityStatusTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityStatusTypeSelectBox/PerformanceStatementCareActivityStatusTypeSelectBox";
import PerformanceStatementCareActivity from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/PerformanceStatementCareActivity";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import StaticHunSocialSecurityPerformanceStatementResources from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/StaticResources/StaticHunSocialSecurityPerformanceStatementResources";
import CareActivityId from "@Primitives/CareActivityId.g";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";
import PerformanceStatementCareActivityStatusType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementCareActivityStatusType.g";

interface IPerformanceStatementCareActivityEuStatusDialogDependencies {
    performanceStatementCareActivityApiAdapter: PerformanceStatementCareActivityApiAdapter;
}

export interface IPerformanceStatementCareActivityEuStatusDialogProps {
    _dependencies?: IPerformanceStatementCareActivityEuStatusDialogDependencies;
    careActivityId: CareActivityId;
}

/** @screen */
@State.observer
class PerformanceStatementCareActivityEuStatusDialog extends React.Component<IPerformanceStatementCareActivityEuStatusDialogProps> {

    @State.observable.ref private performanceStatementCareActivity: PerformanceStatementCareActivity = null;
    @State.observable.ref private newValidity: YearMonth = null;
    @State.observable.ref private newType: PerformanceStatementCareActivityStatusType;
    @State.observable.ref private closeEvent = new TypedEvent<boolean>();

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.bound
    private async loadAsync() {
        const result = await this.props._dependencies.performanceStatementCareActivityApiAdapter.getPerformanceStatementCareActivityByIdAsync(this.props.careActivityId);
        this.setPerformanceStatementCareActivity(result);
        this.setNewType(result.actualEuStatus.type);
    }

    @State.action.bound
    public async saveAsync() {
        const result = await this.props._dependencies.performanceStatementCareActivityApiAdapter.setPerformanceStatementCareActivityManualEuStatusAsync(this.props.careActivityId, this.performanceStatementCareActivity.rowVersion, this.newValidity, this.newType);
        if (!result.hasValidationError) {
            this.closeEvent.emit(true);
        } else {
            State.runInAction(() => {
                this.performanceStatementCareActivity.validationResults = result.validationResults;
            });
        }
    }

    @State.action.bound
    public async validateAsync() {
        const result = await this.props._dependencies.performanceStatementCareActivityApiAdapter.validatePerformanceStatementCareActivityManualEuStatusAsync(this.props.careActivityId, this.performanceStatementCareActivity.rowVersion, this.newValidity, this.newType);
        return result.value;
    }

    @State.bound
    private close() {
        this.closeEvent.emit(false);
    }

    @State.action.bound
    private setPerformanceStatementCareActivity(performanceStatementCareActivity: PerformanceStatementCareActivity) {
        this.performanceStatementCareActivity = performanceStatementCareActivity;
    }

    @State.action.bound
    public setNewValidity(newValidity: YearMonth) {
        this.newValidity = newValidity;
    }

    @State.action.bound
    public setNewType(newType: PerformanceStatementCareActivityStatusType) {
        this.newType = newType;
    }

    public render() {
        const resources = StaticHunSocialSecurityPerformanceStatementResources.PerformanceStatementCareActivityEuStatusDialog;

        return (
            this.performanceStatementCareActivity && (
                <CareActivityContextProvider careActivityId={this.props.careActivityId}>
                    <PatientContextProvider>
                        <UseCaseFrame
                            modalCloseEvent={this.closeEvent}
                            title={resources.Title}
                            modalSize="compact"
                            leftFooter={<Ui.Button text={StaticWebAppResources.Common.Button.Close} onClick={this.close} visualStyle="link" automationId="closeButton" />}
                            rightFooter={<Ui.SaveButton visualStyle="primary" onClickAsync={this.saveAsync} automationId="saveButton" />}
                        >
                            <Ui.GroupBox title={resources.ActualData}>
                                <PerformanceStatementCareActivityStatusTypeSelectBox
                                    label={resources.Type}
                                    value={this.performanceStatementCareActivity.actualEuStatus.type}
                                    isReadOnly
                                    automationId="readOnlyStatusTypeSelectBox"
                                />
                                <Ui.MonthPicker
                                    label={resources.ValidAt}
                                    value={this.performanceStatementCareActivity.actualEuStatus.validAt}
                                    onChange={null}
                                    isReadOnly
                                    automationId="readOnlyValidAtPicker"
                                />
                            </Ui.GroupBox>
                            <Ui.GroupBox title={resources.NewData}>
                                <ValidationBoundary
                                    validationResults={this.performanceStatementCareActivity.validationResults}
                                    entityTypeName="PerformanceStatementCareActivity"
                                    onValidateAsync={this.validateAsync}
                                    validateOnMount
                                >
                                    <PerformanceStatementCareActivityStatusTypeSelectBox
                                        label={resources.NewType}
                                        value={this.newType}
                                        onChange={this.setNewType}
                                        propertyIdentifier="ActualEuStatus.Type"
                                        clearable={false}
                                        automationId="actualEuStatusTypeSelectBox"
                                    />
                                    <Ui.MonthPicker
                                        label={resources.NewValidAt}
                                        value={this.newValidity}
                                        onChange={this.setNewValidity}
                                        propertyIdentifier="ActualEuStatus.ValidAt"
                                        automationId="actualEuStatusValidAtPicker"
                                    />
                                </ValidationBoundary>
                            </Ui.GroupBox>
                        </UseCaseFrame>
                    </PatientContextProvider>
                </CareActivityContextProvider>)
        );
    }
}

export default connect(
    PerformanceStatementCareActivityEuStatusDialog,
    new DependencyAdapter<IPerformanceStatementCareActivityEuStatusDialogProps, IPerformanceStatementCareActivityEuStatusDialogDependencies>(c => ({
        performanceStatementCareActivityApiAdapter: c.resolve("PerformanceStatementCareActivityApiAdapter"),
    }))
);