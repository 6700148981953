import Di from "@Di";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import CareExtensibleEnumApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/CareExtensibleEnumApiAdapter";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import DeathPlaceTypeId from "@Primitives/DeathPlaceTypeId.g";
import IAllergyIntoleranceVersion from "./IAllergyIntoleranceVersion";
import AllergyIntoleranceId from "@Primitives/AllergyIntoleranceId.g";
import TemporalReferenceDataStore from "@Toolkit/CommonWeb/TemporalData/TemporalReferenceDataStore";
import AllergyIntoleranceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/ReferenceData/AllergyIntoleranceApiAdapter";
import AllergyIntoleranceClassificationTypeId from "@Primitives/AllergyIntoleranceClassificationTypeId.g";
import AllergyIntoleranceReactionId from "@Primitives/AllergyIntoleranceReactionId.g";
import IAllergyIntoleranceReactionVersion from "./IAllergyIntoleranceReactionVersion";
import AllergyIntoleranceCategoryId from "@Primitives/AllergyIntoleranceCategoryId.g";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import MedicationStatementSubstanceCategoryId from "@Primitives/MedicationStatementSubstanceCategoryId.g";
import MedicationStatementSubstanceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/ReferenceData/MedicationStatementSubstanceApiAdapter";

@Di.injectable()
export default class PatientRegisterReferenceDataStore extends ReferenceDataStoreBase {

    public deathPlaceType = new ExtensibleEnumStore<DeathPlaceTypeId>(this.careExtEnumApiAdapter.loadDeathPlaceTypeIdsAsync, this.localizationService.localizeEnumId);

    public allergyIntoleranceReaction = new TemporalReferenceDataStore<AllergyIntoleranceReactionId, IAllergyIntoleranceReactionVersion>(this.allergyIntoleranceApiAdapter.loadReactionVersionsAsync);
    public allergyIntoleranceClassificationType = new ExtensibleEnumStore<AllergyIntoleranceClassificationTypeId>(this.allergyIntoleranceApiAdapter.loadAllergyIntoleranceClassificationTypeIdsAsync, this.localizationService.localizeEnumId);
    public allergyIntoleranceCategory = new ExtensibleEnumStore<AllergyIntoleranceCategoryId>(this.allergyIntoleranceApiAdapter.loadAllergyIntoleranceCategoryIdsAsync, this.localizationService.localizeEnumId);
    public medicationStatementSubstanceCategory = new ExtensibleEnumStore<MedicationStatementSubstanceCategoryId>(this.medicationStatementSubstanceApiAdapter.loadMedicationStatementSubstanceCategoryIdsAsync, this.localizationService.localizeEnumId);

    constructor(
        @Di.inject("CareExtensibleEnumApiAdapter") private readonly careExtEnumApiAdapter: CareExtensibleEnumApiAdapter,
        @Di.inject("ILocalizationService") private readonly localizationService: ILocalizationService,
        @Di.inject("AllergyIntoleranceApiAdapter") private readonly allergyIntoleranceApiAdapter: AllergyIntoleranceApiAdapter,
        @Di.inject("IReferenceDataLoader") private readonly referenceDataLoader: IReferenceDataLoader,
        @Di.inject("MedicationStatementSubstanceApiAdapter") private readonly medicationStatementSubstanceApiAdapter: MedicationStatementSubstanceApiAdapter
    ) {
        super();

        referenceDataLoader.register(DeathPlaceTypeId.typeName, () => this.deathPlaceType.ensureLoadedAsync());

        referenceDataLoader.register<IEntityVersionSelector<AllergyIntoleranceReactionId>>(
            AllergyIntoleranceReactionId.typeName, 
            ids => this.allergyIntoleranceReaction.ensureLoadedAsync(ids)
        );
        referenceDataLoader.register<AllergyIntoleranceClassificationTypeId>(
            AllergyIntoleranceClassificationTypeId.typeName, 
            () => this.allergyIntoleranceClassificationType.ensureLoadedAsync()
        );
        referenceDataLoader.register<AllergyIntoleranceCategoryId>(
            AllergyIntoleranceCategoryId.typeName, 
            () => this.allergyIntoleranceCategory.ensureLoadedAsync()
        );

        referenceDataLoader.register<MedicationStatementSubstanceCategoryId>(
            MedicationStatementSubstanceCategoryId.typeName, 
            () => this.medicationStatementSubstanceCategory.ensureLoadedAsync()
        );
    }
}