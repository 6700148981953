import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import PerformanceStatementType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementType.g";

interface IPerformanceStatementTypeMultiSelectProps extends ISelectBoxBaseProps {
    value?: PerformanceStatementType | PerformanceStatementType[];
    onChange?: (newValue: PerformanceStatementType | PerformanceStatementType[]) => void;
}


const PerformanceStatementTypeSelectBox: React.FC<IPerformanceStatementTypeMultiSelectProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"PerformanceStatementType"}
        enumOrigin="server"
        enumType={PerformanceStatementType}
        displayMode="selectBox"
    />
);

export default PerformanceStatementTypeSelectBox;