import IToolkitViewContextSettings from "@Toolkit/ReactClient/Components/ViewContext/IToolkitViewContextSettings";

export default class ViewContext<TSettings = IToolkitViewContextSettings> {
    private readonly _valueMap: TSettings;

    constructor(
        parentContext: ViewContext<TSettings>,
        settings: TSettings
    ) {
        this._valueMap = {
            ...parentContext?._valueMap,
            ...settings
        };
    }

    public getByKey(key: keyof TSettings) {
        return this._valueMap[key];
    }

    public getAll() {
        return { ...this._valueMap };
    }
}