// tslint:disable:variable-name
import createPanelStoreProvider, { IStoreProviderSettings } from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import PerformedServicesScreenStore from "./PerformedServicesScreenStore";
import StaticResources from "@StaticResources";

const { 
    ContextComponent: PerformedServicesScreenStoreContext, 
    StoreProvider: PerformedServicesScreenStoreProvider, 
    useStore: usePerformedServicesScreenStore,
    provideStore: providePerformedServicesScreenStore,
} = createPanelStoreProvider(
    PerformedServicesScreenStore,
    () => ({
        unauthorizedPageTitle: StaticResources.OutpatientWorkflow.PerformedServicesStep.Title
    } as IStoreProviderSettings)
);

export {
    PerformedServicesScreenStoreContext,
    PerformedServicesScreenStoreProvider,
    usePerformedServicesScreenStore,
    providePerformedServicesScreenStore
};