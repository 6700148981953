exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageViewer_container_1YPgh {\n  background-color: #E6E6E6;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.ImageViewer_container_1YPgh .ImageViewer_image_-_OrS {\n  overflow: auto;\n  padding: 25px;\n  flex: 1;\n  max-width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"container": "ImageViewer_container_1YPgh",
	"image": "ImageViewer_image_-_OrS"
};