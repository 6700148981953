
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import SurgeryTypeDefinitionPanelStore from "./SurgeryTypeDefinitionPanelStore";

const { 
    ContextComponent: SurgeryTypeDefinitionPanelStoreContext, 
    StoreProvider: SurgeryTypeDefinitionPanelStoreProvider, 
    useStore: useSurgeryTypeDefinitionPanelStore,
    provideStore: provideSurgeryTypeDefinitionPanelStore,
} = createPanelStoreProvider(SurgeryTypeDefinitionPanelStore);

export {
    SurgeryTypeDefinitionPanelStoreContext,
    SurgeryTypeDefinitionPanelStoreProvider,
    useSurgeryTypeDefinitionPanelStore,
    provideSurgeryTypeDefinitionPanelStore
};