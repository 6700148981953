import React from "react";
import Styles from "./NavBar.less";
import NavBarItem from "@CommonControls/NavBar/NavBarItem";
import NavBarItemGroup from "@CommonControls/NavBar/NavBarItemGroup";
import NavbarSeparator from "./NavbarSeparator";

interface INavBarProps {
    automationId?: string;
}

export default class NavBar extends React.PureComponent<INavBarProps> {

    
    public static Item = NavBarItem;
    public static LeftItems = NavBarItemGroup;
    public static RightItems = NavBarItemGroup;
    public static Separator = NavbarSeparator;
    

    public render() {
        return (<div className={Styles.navbar} data-automation-id={this.props.automationId || undefined}>{this.props.children}</div>);
    }
}