import IEhrDocumentsExtensionPointProps from "@PluginInterface/BoundedContexts/Care/PatientRegister/ExtensionPoints/IEhrDocumentsExtensionPointProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import { HisErrorBoundary } from "@HisPlatformControls";
import PatientDocumentsMasterDetailPanel from "./PatientDocumentsMasterDetailPanel";

@State.observer
export default class PatientDocumentsExtension extends React.Component<IEhrDocumentsExtensionPointProps> {
    public static shouldOverride = (props: IEhrDocumentsExtensionPointProps) => true;

    public render() {
        return (
            <HisErrorBoundary>
                <PatientDocumentsMasterDetailPanel {...this.props} />
            </HisErrorBoundary>
        );
    }
}
