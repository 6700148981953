import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import moment from "moment";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import _ from "@HisPlatform/Common/Lodash";
import React from "react";

export interface IRelatedCareActivity {
    wentUnderCareAt: moment.Moment;
    pointOfCareId: PointOfCareId;
    serviceId: FinancedServiceId;
    financedServiceQuantity: number;
}

export interface IFinancedServiceRuleValidationProblemReferenceDataCollection {
    financingServiceIds: FinancedServiceId[];
    pointOfCareIds: PointOfCareId[];
}

export abstract class FinancedServiceRuleValidationProblemProcessorBase {
    public abstract getServiceId(problem: IClientValidationProblem): FinancedServiceId;
    public abstract getRawRelatedCareActivities(problem: IClientValidationProblem): any[];
    public isRuleHighlightable = true;

    constructor(
        protected localizationService: ILocalizationService,
        protected organizationReferenceDataStore: OrganizationReferenceDataStore,
        protected financeReferenceDataStore: FinanceReferenceDataStore
    ) { }

    protected renderRelatedCareActivitiesInfo(problem: IClientValidationProblem) {
        const careActivities = this.getRelatedCareActivities(problem);

        return careActivities.map((careActivity: IRelatedCareActivity, i: number) => {

            let pointOfCareCode = "";
            let serviceCode = "";

            if (careActivity.pointOfCareId) {
                const pointOfCare = this.organizationReferenceDataStore.allPointsOfCareMap.get(careActivity.pointOfCareId);
                pointOfCareCode = pointOfCare.shorthand;
            }
    
            if (careActivity.serviceId) {
                const service = this.financeReferenceDataStore.financedServiceMap.get({id: careActivity.serviceId, validOn: null});
                serviceCode = service?.code.value;
            }
    
            return (
                <div key={i}>
                    {i > 0 && <br/>}
                    {formatStringWithObjectParams(StaticCareResources.OutpatientWorkflow.PerformedServicesStep.OtherCareActivityFormat, {
                        OtherServiceCode: serviceCode,
                        WentUnderCareAt: this.localizationService.localizeDateTime(careActivity.wentUnderCareAt),
                        PointOfCareCode: pointOfCareCode,
                        FinancedServiceQuantity: careActivity.financedServiceQuantity?.toString()
                    })}
                </div>
            );
        });
    }

    protected getServiceCode(problem: IClientValidationProblem) {
        const serviceId = this.getServiceId(problem);
        return this.getServiceCodeById(serviceId);
    }

    protected getServiceCodeById(id: FinancedServiceId) {
        let serviceCode = "";

        if (id) {
            const service = this.financeReferenceDataStore.financedServiceMap.get({id: id, validOn: null});
            serviceCode = service?.code.value || "";
        }

        return serviceCode;
    }

    protected getRelatedCareActivities(problem: IClientValidationProblem): IRelatedCareActivity[] {
        const careActivities = this.getRawRelatedCareActivities(problem);
        return careActivities.map(careActivity => {
            const serviceIdValue = careActivity?.Service?.Value?.toString();
            const pointOfCareIdValue = careActivity?.PointOfCare?.Value?.toString();
            return {
                pointOfCareId: pointOfCareIdValue ? new PointOfCareId(pointOfCareIdValue) : null,
                wentUnderCareAt: moment(careActivity?.WentUnderCareAt),
                serviceId: serviceIdValue ? new FinancedServiceId(serviceIdValue) : null,
                financedServiceQuantity : careActivity?.FinancedServiceQuantity
            };
        });
    }
}
