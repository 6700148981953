import React from "react";
import * as Ui from "@CommonControls";
import * as Styles from "./PractitionerEhiLogin.less";
import StaticHunEHealthInfrastructureUserManagementResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/StaticResources/StaticHunEHealthInfrastructureUserManagementResources";
import EhiToken from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiToken";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IPractitionerEhiLoginMenuViewProps {
    ehiIdentifier: string;
    ehiToken: EhiToken;
    logoutAsync: () => Promise<void>;
    openDialogAsync: () => Promise<void>;
    localizationService: ILocalizationService;
    loginName?: string;
}

const renderTooltipAndContent = (ehiToken: EhiToken, localizationService: ILocalizationService, openDialog: () => void, logoutAsync: () => Promise<void>, loginName?: string) => {
    if (ehiToken) {
        return (
            <Ui.NavBar.Item hasNestedItems content={renderIconWithDot(loginName)}>
                <Ui.NavBar.Item passive>
                    <span className={Styles.loggedIn}>{StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Labels.LoggedIn} <span className={Styles.ehiToken}> ({ehiToken.ehiUserIdentifierValue})</span></span><br />
                    <Ui.Icon className={Styles.eesztIcon} iconName="eeszt" visualStyle="primary" />
                    <span className={Styles.expiry}>{StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Labels.Expiry}: {localizationService.localizeDateTime(ehiToken.validTo)}</span>
                </Ui.NavBar.Item>
                <hr />
                <Ui.NavBar.Item onClickAsync={logoutAsync} >
                    <Ui.Icon iconName="enter" visualStyle="primary" /><span className={Styles.logout}>{StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Labels.LogoutfromEESZT}</span>
                </Ui.NavBar.Item>
            </Ui.NavBar.Item>

        );
    } else {
        return (
            <Ui.NavBar.Item hasNestedItems onClick={openDialog} content={<Ui.Icon iconName="eeszt" visualStyle="white" />}>
                <Ui.NavBar.Item passive>{StaticHunEHealthInfrastructureUserManagementResources.LoginExtension.Labels.LoggedOutStatus}</Ui.NavBar.Item>
            </Ui.NavBar.Item>
        );
    }
};

const renderIconWithDot = (loginName?: string) => {
    return (<><Ui.Icon iconName="eeszt" visualStyle="white" /> {!isNullOrUndefined(loginName) && loginName}<div className={Styles.greenDot} /></>);
};


const PractitionerEhiLoginMenuView: React.FC<IPractitionerEhiLoginMenuViewProps> = (props: IPractitionerEhiLoginMenuViewProps) => {
    return (
        <>
            {renderTooltipAndContent(props.ehiToken, props.localizationService, props.openDialogAsync, props.logoutAsync, props.loginName)}
        </>
    );
};

export default PractitionerEhiLoginMenuView; 