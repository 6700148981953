import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DocumentKind from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentKind.g";
import DocumentKindSelector from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Controls/DocumentKindSelector/DocumentKindSelector";

interface IDocumentKindColumnDependencies {
    localizationService: ILocalizationService;
}

interface IDocumentKindColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IDocumentKindColumnDependencies;
    onCustomRenderCellValue?: (row: any) => any;
}


const DocumentKindColumn: React.FC<IDocumentKindColumnProps> = props => {

    const { children, ...columnProps } = props;

    const valueRenderer = useCallback((value: DocumentKind, row: any) => {
        const customCellValue = props.onCustomRenderCellValue && props.onCustomRenderCellValue(row);
        if (customCellValue) {
            return customCellValue;
        }

        const localized = isNullOrUndefined(value) ? null : props._dependencies.localizationService.localizeEnum(DocumentKind[value], "DocumentKind");

        if (isNullOrUndefined(localized)) {
            return null;
        }

        return localized.Name;
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<DocumentKind | DocumentKind[]>) => {
        return <DocumentKindSelector {...filterProps} multiSelect hoverOnlyIndicatorIcons displayMode="selectBox" />;
    }, []);

    return (
        <DataGridColumn
            width={50}
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
        />
    );
};

const filterValueSerializer: IDataGridFilterValueSerializer<DocumentKind | DocumentKind[]> = {
    serialize: value => JSON.stringify(value),
    deserialize: value => JSON.parse(value)
};

export default connect(
    DocumentKindColumn,
    new DependencyAdapter<IDocumentKindColumnProps, IDocumentKindColumnDependencies>( c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);