import Di from "@Di";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import SendInitializationPayloadCommand from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/ExternalDecisionSupport/Commands/SendInitializationPayloadCommand";
import ICardioFrameApiAdapter from "@ICardioIntegrationPlugin/Components/ICardioFrame/ICardioFrameApiAdapter";
import IICardioData from "@ICardioIntegrationPlugin/Model/IICardioData";
import IExternalDecisionSupportAccessor from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/ExternalDecisionSupport/IExternalDecisionSupportAccessor";
import IProcessData from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/ExternalDecisionSupport/IProcessData";
import PatientId from "@Primitives/PatientId.g";
import IMessagingSubscription from "@Toolkit/CommonWeb/CommandProcessing/Definition/IMessagingSubscription";
import UnspecifiedCommand from "@Toolkit/CommonWeb/CommandProcessing/Definition/UnspecifiedCommand";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

@Di.injectable()
export default class ICardioDecisionSupportAccessor implements IExternalDecisionSupportAccessor {
    constructor(
        @Di.inject("UserContext") public readonly userContext: UserContext,
        @Di.inject("OrganizationReferenceDataStore") public readonly organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("ICardioFrameApiAdapter") public readonly adapter: ICardioFrameApiAdapter) {
    }

    public async getInitializationPayloadAsync(
        patientId: PatientId,
        diagnosisCodes: string[],
        externalDecisionSupportId: string,
        mode: "patient" | "user",
        hideFrame: boolean,
        startNewCase: boolean
    ): Promise<object> {
        const username = `imi_${this.userContext.loginName}`;
        const name = `imi_${this.userContext.displayName}`;
        const stampCode = await this.getStampCodeAsync();
        const accessToken = await this.adapter.getICardioAuthTokenAsync(username, name, stampCode);

        return {
            access_token: accessToken,
            hisPatientId: patientId.value,
            mode: mode,
            icd10s: diagnosisCodes,
            hideFrame: hideFrame,
            startNewCase: startNewCase && isNullOrUndefined(externalDecisionSupportId),
            caseId: externalDecisionSupportId
        };
    }

    public registerProcessUpdatedEventHandler(subscription: IMessagingSubscription, handler: (data: IProcessData) => Promise<void>): void {
        subscription.registerCallback<UnspecifiedCommand>("UnspecifiedCommand", command => this.onProcessDataUpdatedAsync(command, handler));
    }
    
    public registerPageLoadedEventHandler(subscription: IMessagingSubscription, handler: () => Promise<void>): void {
        subscription.registerCallback<SendInitializationPayloadCommand>("SendInitializationPayloadCommand", command => handler());
    }

    private async getStampCodeAsync(): Promise<string> {
        const practitionerId = this.userContext.practitionerId;
        if (!practitionerId) {
            return null;
        }

        const result = await this.organizationReferenceDataStore.doctorMap.getOrLoadAsync(practitionerId);

        return result?.code;
    }

    private onProcessDataUpdatedAsync(
        command: UnspecifiedCommand,
        handler: (data: IProcessData) => Promise<void>
    ): Promise<void> {
        if (!command.data) {
            return Promise.resolve();
        }

        const data = command.data as IICardioData;

        return handler({
            caseId: data.CaseId,
            isFinished: data.Finished,
            currentStep: data.CurrentStep,
            workflowName: data.WorkflowName,
            forms: data.WorkflowForms?.map(form => ({
                answers: form.Answers,
                formId: form.FormId,
                order: form.Order,
                title: form.FormTitle
            })) || []
        });
    }
}