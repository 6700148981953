import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import InsuranceId from "@Primitives/InsuranceId.g";
import InsurancePlanId from "@Primitives/InsurancePlanId.g";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class InsuranceStore extends EntityStoreBase<InsuranceId> {

    @State.observable.ref public insurancePlanId: InsurancePlanId = null;
    @State.observable.ref public insurerOrganizationId: InsurerOrganizationId = null;
    @State.observable.ref public validFrom: LocalDate = null;
    @State.observable.ref public validTo: LocalDate = null;
    @State.observable public comment: string = null;
    @State.observable public isActive: boolean = true;
    @State.observable public isDefault: boolean = false;

    @State.action.bound
    public setInsurancePlanId(insurancePlanId: InsurancePlanId) {
        this.insurancePlanId = insurancePlanId;
    }

    @State.action.bound
    public setInsurerOrganizationId(insurerOrganizationId: InsurerOrganizationId) {
        this.insurerOrganizationId = insurerOrganizationId;
    }

    @State.action.bound
    public setValidFrom(validFrom: LocalDate) {
        this.validFrom = validFrom;
    }

    @State.action.bound
    public setValidTo(validTo: LocalDate) {
        this.validTo = validTo;
    }

    @State.action.bound
    public setComment(comment: string) {
        this.comment = comment;
    }

    @State.action.bound
    public setIsActive(isActive: boolean) {
        this.isActive = isActive;
    }

    @State.action.bound
    public setIsDefault(isDefault: boolean) {
        this.isDefault = isDefault;
    }

    public isEmpty() {
        return !this.insurerOrganizationId &&
            !this.insurancePlanId &&
            (!this.validFrom || this.validFrom.isEmpty()) &&
            (!this.validTo || this.validTo.isEmpty()) &&
            this.comment === "";
    }
}
