import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import CareActivityId from "@Primitives/CareActivityId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import { IServiceRequestScreenData } from "./IServiceRequestScreenData";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import { mapCareLocation, mapCreateServiceRequestCommandControllerDto, mapCreateServiceRequestWithAppointmentCommandControllerDto, mapMedicalServiceAvailability } from "./ServiceRequestScreenApiAdapterMappingHelper";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import { CreateAppointmentDto, CreateServiceRequestWithAppointmentControllerDto, DeleteDraftServiceRequestWithAppointmentControllerDto, getCreateServiceRequestCommandDto, getModifyServiceRequestCommandDto, ModifyServiceRequestWithCreateAppointmentControllerDto, ModifyServiceRequestWithDeleteAppointmentControllerDto, ModifyServiceRequestWithUpdateAppointmentControllerDto } from "./ServiceRequestScreenMappingHelper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import RequestedServiceId from "@Primitives/RequestedServiceId.g";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import CareActivityStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityStoreMapper";
import DiagnosisListStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/DiagnosisList/DiagnosisListStoreMapper";
import PropertyGroupStoreMapper from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/DynamicProperties/PropertyGroupStoreMapper";
import SchedulingStoreMapper from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingStoreMapper";
import ServiceRequestStoreMapper from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestStoreMapper";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import IOperationResult from "@Toolkit/CommonWeb/ApiAdapter/IOperationResult";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import CareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CareActivityStore";
import DiagnosisListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisListStore";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import PropertyGroup from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyGroup";
import ServiceProviderProfile from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/ServiceProviderProfile";
import { CareLocation } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/CareLocation";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import ServiceRequestWithAppointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ServiceRequestWithAppointment";
import RequestedServiceAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceAction.g";
import IObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/IObservation";
import { getObservation } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/Observations/ObservationStoreMapper";

@Di.injectable()
export default class ServiceRequestScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("IServiceRequestClient") private readonly apiClient: Proxy.IServiceRequestClient,
        @Di.inject("CareActivityStoreMapper") private readonly careActivityStoreMapper: CareActivityStoreMapper,
        @Di.inject("DiagnosisListStoreMapper") private readonly diagnosisListStoreMapper: DiagnosisListStoreMapper,
        @Di.inject("PropertyGroupStoreMapper") private readonly propertyGroupStoreMapper: PropertyGroupStoreMapper,
        @Di.inject("SchedulingStoreMapper") private readonly schedulingStoreMapper: SchedulingStoreMapper,
        @Di.inject("ServiceRequestStoreMapper") private readonly serviceRequestStoreMapper: ServiceRequestStoreMapper,
        @Di.inject("CareReferenceDataStore") private readonly careReferenceData: CareReferenceDataStore,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader
    ) {
        super(referenceDataLoader);
    }

    public getExternalLocationsOfOrganizationUnitsAsync(
        organizationUnitIds: OrganizationUnitId[],
        validOn: LocalDate
    ) {
        return this.executeOperationAsync<ExternalLocationId[], Proxy.GetExternalLocationAssignmentsByOrganizationUnitIdsQueryResponse>(
            () => this.apiClient.getExternalLocationAssignmentsByOrganizationUnitIdsQueryAsync(
                CreateRequestId(),
                new Proxy.GetExternalLocationAssignmentsByOrganizationUnitIdsControllerDto({
                    organizationUnitIds: organizationUnitIds,
                    validOn: validOn
                })
            ),
            response => {
                return Promise.resolve(response.externalLocationAssignments.map(i => i.externalLocationId));
            }
        );
    }

    public getServiceRequestScreenDataAsync(
        careActivityId: CareActivityId,
        serviceRequestId: ServiceRequestId,
        forceReleaseLock: boolean
    ): Promise<IOperationResult<IServiceRequestScreenData>> {
        return this.executeOperationAsync<IServiceRequestScreenData, Proxy.GetServiceRequestScreenDataCommandResponse>(
            () => this.apiClient.getServiceRequestScreenDataCommandAsync(
                CreateRequestId(),
                new Proxy.GetServiceRequestScreenDataControllerDto({
                    careActivityId: careActivityId,
                    forceReleaseLock: forceReleaseLock,
                    serviceRequestId: serviceRequestId,
                    requestLock: true
                }),
                false
            ),
            async response => {
                let serviceRequest = null;
                if (!!response.serviceRequest) {
                    serviceRequest = new ServiceRequestStore();
                    this.serviceRequestStoreMapper.applyToStore(serviceRequest, response);
                    await this.mapServiceRequestAsync(serviceRequest);
                }

                let starterCareActivity = null;
                if (!!response.starterCareActivity) {
                    starterCareActivity = new CareActivityStore();
                    this.careActivityStoreMapper.applyToStore(starterCareActivity, response);
                }

                let diagnosisList = null;
                if (!!response.diagnosisList) {
                    diagnosisList = new DiagnosisListStore();
                    await this.diagnosisListStoreMapper.applyToStoreAndResolveValidationProblemsAsync(diagnosisList, response);
                }

                const appointment = new Appointment(true);
                if (!!response.appointment) {
                    this.schedulingStoreMapper.applyToStore(appointment, response);
                    appointment.isNew = false;
                }

                const propertyGroups = new SimpleStore<PropertyGroup[]>();
                this.propertyGroupStoreMapper.applyToStore(propertyGroups, response);

                return {
                    appointment: appointment,
                    diagnosisList: diagnosisList,
                    dynamicProperties: propertyGroups.value,
                    referralLocationId: response.externalLocationAssignment?.externalLocationId,
                    serviceProviderProfileVersion: response.serviceProviderProfileVersion !== null
                        ? new ServiceProviderProfile(
                            response.serviceProviderProfileVersion.id,
                            response.serviceProviderProfileVersion.medicalServiceExecutionCapabilities.map(mapMedicalServiceAvailability)
                        )
                        : null,
                    serviceRequest: serviceRequest,
                    starterCareActivity: starterCareActivity
                } as IServiceRequestScreenData;
            }
        );
    }

    public getServiceProviderProfileByLocationAsync(
        location: CareLocation
    ): Promise<IOperationResult<ServiceProviderProfile>> {
        return this.executeOperationAsync<ServiceProviderProfile, Proxy.GetServiceProviderProfileVersionByLocationQueryResponse>(
            () => this.apiClient.getServiceProviderProfileVersionByLocationQueryAsync(
                CreateRequestId(),
                new Proxy.GetServiceProviderProfileVersionByLocationControllerDto({
                    careLocation: mapCareLocation(location),
                    validOn: location instanceof ExternalCareLocation
                        ? location.externalLocationSelector.validOn
                        : LocalDate.today()
                })
            ),
            response => {
                return Promise.resolve(new ServiceProviderProfile(
                    response.serviceProviderProfile.id,
                    response.serviceProviderProfile.medicalServiceExecutionCapabilities.map(mapMedicalServiceAvailability)
                ));
            }
        );
    }

    public validateServiceRequestAsync(
        serviceRequest: ServiceRequestStore,
        isServiceRequestEmpty: boolean
    ): Promise<IOperationResult<IClientValidationResult[]>> {
        return this.executeOperationAsync<IClientValidationResult[], Proxy.CreateServiceRequestCommandResponse>(
            () => this.apiClient.createServiceRequestCommandAsync(
                CreateRequestId(),
                mapCreateServiceRequestCommandControllerDto(serviceRequest, false, false, true, isServiceRequestEmpty)
            ),
            response => {
                return Promise.resolve(
                    mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult)
                );
            }
        );
    }

    public validateServiceRequestWithAppointmentAsync(
        serviceRequest: ServiceRequestStore,
        appointment: Appointment,
        isServiceRequestEmpty: boolean
    ): Promise<IOperationResult<ServiceRequestWithAppointment>> {
        return this.executeOperationAsync<ServiceRequestWithAppointment, Proxy.CreateServiceRequestWithAppointmentCommandResponse>(
            () => this.apiClient.createServiceRequestWithAppointmentCommandAsync(
                CreateRequestId(),
                mapCreateServiceRequestWithAppointmentCommandControllerDto(appointment, serviceRequest, isServiceRequestEmpty)
            ),
            response => {
                const result = new ServiceRequestWithAppointment();
                result.appointment = new Appointment();
                result.serviceRequest = new ServiceRequestStore();
                result.setValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);

                return Promise.resolve(result);
            }
        );
    }

    public validateAppointmentAsync(
        appointment: Appointment
    ): Promise<IOperationResult<IClientValidationResult[]>> {
        return this.executeOperationAsync<IClientValidationResult[], Proxy.CreateAppointmentCommandResponse>(
            () => this.apiClient.createAppointmentCommandAsync(
                CreateRequestId(),
                CreateAppointmentDto(appointment, true)
            ),
            response => {
                return Promise.resolve(
                    mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult)
                );
            }
        );
    }

    public createServiceRequestWithAppointmentAsync(
        serviceRequest: ServiceRequestStore,
        appointment: Appointment,
        shouldSubmitServiceRequest: boolean,
        requestLock: boolean,
        isEmpty: boolean
    ): Promise<IOperationResult<ServiceRequestWithAppointment>> {
        return this.executeOperationAsync<ServiceRequestWithAppointment, Proxy.CreateServiceRequestWithAppointmentCommandResponse>(
            () => this.apiClient.createServiceRequestWithAppointmentCommandAsync(
                CreateRequestId(),
                CreateServiceRequestWithAppointmentControllerDto(
                    serviceRequest,
                    appointment,
                    shouldSubmitServiceRequest,
                    requestLock,
                    false,
                    isEmpty
                )
            ),
            response => {
                const result = new ServiceRequestWithAppointment();
                result.appointment = new Appointment(true);
                result.serviceRequest = new ServiceRequestStore(true);

                this.schedulingStoreMapper.applyToStore(result.appointment, response);
                this.serviceRequestStoreMapper.applyToStore(result.serviceRequest, response);

                result.setValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                result.appointment.isNew = !response.isPersisted;
                result.serviceRequest.isNew = !response.isPersisted;

                return Promise.resolve(result);
            }
        );
    }

    public modifyServiceRequestWithCreateAppointmentAsync(
        careActivityId: CareActivityId,
        serviceRequest: ServiceRequestStore,
        appointment: Appointment,
        shouldSubmitServiceRequest: boolean,
        lockId: LockId,
        releaseLockIfSuccessful: boolean,
        extensionData?: any
    ): Promise<IOperationResult<ServiceRequestWithAppointment>> {
        return this.executeOperationAsync<ServiceRequestWithAppointment, Proxy.ModifyServiceRequestWithCreateAppointmentCommandResponse>(
            () => this.apiClient.modifyServiceRequestWithCreateAppointmentCommandAsync(
                CreateRequestId(),
                ModifyServiceRequestWithCreateAppointmentControllerDto(
                    careActivityId,
                    serviceRequest,
                    shouldSubmitServiceRequest,
                    appointment,
                    lockId,
                    releaseLockIfSuccessful,
                    false,
                    extensionData
                )
            ),
            response => {
                const result = new ServiceRequestWithAppointment();
                result.appointment = new Appointment(true);
                result.serviceRequest = new ServiceRequestStore(false);

                this.schedulingStoreMapper.applyToStore(result.appointment, response);
                this.serviceRequestStoreMapper.applyToStore(result.serviceRequest, response);

                result.setValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                result.appointment.isNew = !response.isPersisted;

                return Promise.resolve(result);
            }
        );
    }

    public modifyServiceRequestWithDeleteAppointmentAsync(
        careActivityId: CareActivityId,
        serviceRequest: ServiceRequestStore,
        appointmentScheduleEntryId: AppointmentScheduleEntryId,
        rowVersion: RowVersion,
        shouldSubmitServiceRequest: boolean,
        lockId: LockId,
        releaseLockIfSuccessful: boolean,
        extensionData?: any
    ): Promise<IOperationResult<ServiceRequestStore>> {
        return this.executeOperationAsync<ServiceRequestStore, Proxy.ModifyServiceRequestWithDeleteAppointmentCommandResponse>(
            () => this.apiClient.modifyServiceRequestWithDeleteAppointmentCommandAsync(
                CreateRequestId(),
                ModifyServiceRequestWithDeleteAppointmentControllerDto(
                    careActivityId,
                    serviceRequest,
                    shouldSubmitServiceRequest,
                    appointmentScheduleEntryId,
                    rowVersion,
                    lockId,
                    releaseLockIfSuccessful,
                    false,
                    extensionData
                )
            ),
            response => {
                const result = new ServiceRequestStore(false);

                this.serviceRequestStoreMapper.applyToStore(serviceRequest, response);
                result.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);

                return Promise.resolve(result);
            }
        );
    }

    public modifyServiceRequestWithUpdateAppointmentAsync(
        careActivityId: CareActivityId,
        serviceRequest: ServiceRequestStore,
        appointment: Appointment,
        shouldSubmitServiceRequest: boolean,
        lockId: LockId,
        releaseLockIfSuccessful: boolean,
        extensionData?: any
    ): Promise<IOperationResult<ServiceRequestWithAppointment>> {
        return this.executeOperationAsync<ServiceRequestWithAppointment, Proxy.ModifyServiceRequestWithUpdateAppointmentCommandResponse>(
            () => this.apiClient.modifyServiceRequestWithUpdateAppointmentCommandAsync(
                CreateRequestId(),
                ModifyServiceRequestWithUpdateAppointmentControllerDto(
                    careActivityId,
                    serviceRequest,
                    shouldSubmitServiceRequest,
                    appointment.id,
                    appointment,
                    appointment.rowVersion,
                    lockId,
                    releaseLockIfSuccessful,
                    false,
                    extensionData
                )
            ),
            response => {
                const result = new ServiceRequestWithAppointment();
                result.appointment = new Appointment(true);
                result.serviceRequest = new ServiceRequestStore(false);

                this.schedulingStoreMapper.applyToStore(result.appointment, response);
                this.serviceRequestStoreMapper.applyToStore(result.serviceRequest, response);

                result.setValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                result.appointment.isNew = !response.isPersisted;

                return Promise.resolve(result);
            }
        );
    }

    public deleteDraftServiceRequestWithAppointmentAsync(
        appointmentScheduleEntryId: AppointmentScheduleEntryId,
        appointmentRowVersion: RowVersion,
        serviceRequestId: ServiceRequestId,
        activeCareActivityId: CareActivityId,
        serviceRequestRowVersion: RowVersion,
        lockId: LockId,
        releaseLockIfSuccessful: boolean
    ): Promise<IOperationResult<ServiceRequestWithAppointment>> {
        return this.executeOperationAsync<ServiceRequestWithAppointment, Proxy.ModifyServiceRequestWithUpdateAppointmentCommandResponse>(
            () => this.apiClient.deleteDraftServiceRequestWithAppointmentCommandAsync(
                CreateRequestId(),
                DeleteDraftServiceRequestWithAppointmentControllerDto(
                    appointmentScheduleEntryId,
                    appointmentRowVersion,
                    serviceRequestId,
                    activeCareActivityId,
                    serviceRequestRowVersion,
                    lockId,
                    releaseLockIfSuccessful
                )
            ),
            response => {
                const result = new ServiceRequestWithAppointment();
                result.serviceRequest = new ServiceRequestStore();

                return Promise.resolve(result);
            }
        );
    }

    public renewSubmittedServiceRequestAsync(
        id: ServiceRequestId,
        activeCareActivityId: CareActivityId,
        rowVersion: RowVersion,
        requestLock: boolean,
        lockId: LockId,
        extensionData: any
    ): Promise<IOperationResult<ServiceRequestStore>> {
        return this.executeOperationAsync<ServiceRequestStore, Proxy.RenewSubmittedServiceRequestCommandResponse>(
            () => this.apiClient.renewSubmittedServiceRequestCommandAsync(
                CreateRequestId(),
                new Proxy.RenewSubmittedServiceRequestControllerDto({
                    activeCareActivityId: activeCareActivityId,
                    serviceRequestId: id,
                    serviceRequestRowVersion: rowVersion,
                    requestLock: requestLock,
                    lockId: lockId,
                    releaseLockIfSuccessful: false,
                    extensionData: extensionData
                })
            ),
            response => {
                const store = new ServiceRequestStore();
                this.serviceRequestStoreMapper.applyToStore(store, response);
                return this.mapServiceRequestAsync(store);
            }
        );
    }

    public renewSubmittedServiceRequestWithAppointmentAsync(
        id: ServiceRequestId,
        activeCareActivityId: CareActivityId,
        rowVersion: RowVersion,
        requestLock: boolean,
        lockId: LockId,
        extensionData: any,
        appointmentId: AppointmentScheduleEntryId,
        cancellationNote: string
    ): Promise<IOperationResult<ServiceRequestStore>> {
        return this.executeOperationAsync<ServiceRequestStore, Proxy.RenewSubmittedServiceRequestWithAppointmentCommandResponse>(
            () => this.apiClient.renewSubmittedServiceRequestWithAppointmentCommandAsync(
                CreateRequestId(),
                new Proxy.RenewSubmittedServiceRequestWithAppointmentControllerDto({
                    activeCareActivityId: activeCareActivityId,
                    serviceRequestId: id,
                    serviceRequestRowVersion: rowVersion,
                    requestLock: requestLock,
                    lockId: lockId,
                    releaseLockIfSuccessful: false,
                    extensionData: extensionData,
                    appointmentScheduleEntryId: appointmentId,
                    appointmentCancellationNote: cancellationNote
                })
            ),
            response => {
                const store = new ServiceRequestStore();
                this.serviceRequestStoreMapper.applyToStore(store, response);
                return this.mapServiceRequestAsync(store);
            }
        );
    }

    public modifyServiceRequestAsync(
        store: ServiceRequestStore,
        shouldSaveWithValidationProblems: boolean,
        careActivityId: CareActivityId,
        lockId: LockId,
        releaseLockIfSuccessful: boolean,
        isEmpty: boolean
    ): Promise<IOperationResult<ServiceRequestStore>> {
        return this.executeOperationAsync<ServiceRequestStore, Proxy.ModifyServiceRequestCommandResponse>(
            () => this.apiClient.modifyServiceRequestCommandAsync(
                CreateRequestId(),
                getModifyServiceRequestCommandDto(
                    store,
                    shouldSaveWithValidationProblems,
                    careActivityId,
                    lockId,
                    releaseLockIfSuccessful,
                    isEmpty
                )
            ),
            response => {
                const store = new ServiceRequestStore();
                this.serviceRequestStoreMapper.applyToStore(store, response);
                return this.mapServiceRequestAsync(store);
            }
        );
    }

    public createServiceRequestAsync(
        store: ServiceRequestStore,
        shouldSaveWithValidationProblems: boolean,
        requestLock: boolean,
        isEmpty: boolean
    ): Promise<IOperationResult<ServiceRequestStore>> {
        return this.executeOperationAsync<ServiceRequestStore, Proxy.CreateServiceRequestCommandResponse>(
            () => this.apiClient.createServiceRequestCommandAsync(
                CreateRequestId(),
                getCreateServiceRequestCommandDto(
                    store,
                    shouldSaveWithValidationProblems,
                    requestLock,
                    false,
                    isEmpty
                )
            ),
            response => {
                const store = new ServiceRequestStore();
                this.serviceRequestStoreMapper.applyToStore(store, response);
                return this.mapServiceRequestAsync(store);
            }
        );
    }

    public executeRequestedServiceActionsAsync(
        activeCareActivityId: CareActivityId,
        serviceRequestId: ServiceRequestId,
        rowVersion: RowVersion,
        actionsForRequestedServices: Array<{ id: RequestedServiceId, action: RequestedServiceAction }>,
        lockInfo: LockInfo,
        releaseLockIfSuccessful: boolean,
        extensionData: any
    ): Promise<IOperationResult<ServiceRequestStore>> {
        return this.executeOperationAsync<ServiceRequestStore, Proxy.ExecuteRequestedServiceActionsCommandResponse>(
            () => this.apiClient.executeRequestedServiceActionsCommandAsync(
                CreateRequestId(),
                new Proxy.ExecuteRequestedServiceActionsControllerDto({
                    activeCareActivityId: activeCareActivityId,
                    serviceRequestId: serviceRequestId,
                    serviceRequestRowVersion: rowVersion,
                    actionsToExecute: actionsForRequestedServices.map(i => {
                        const action = new Proxy.ActionForRequestedServiceDto();
                        action.requestedServiceId = i.id;
                        action.action = i.action as unknown as RequestedServiceAction;

                        return action;
                    }),
                    lockId: lockInfo.lockId,
                    releaseLockIfSuccessful: releaseLockIfSuccessful,
                    extensionData: extensionData
                })
            ),
            response => {
                const store = new ServiceRequestStore();
                this.serviceRequestStoreMapper.applyToStore(store, response);
                return this.mapServiceRequestAsync(store);
            }
        );
    }

    public deleteDraftServiceRequestAsync(
        id: ServiceRequestId,
        activeCareActivityId: CareActivityId,
        rowVersion: RowVersion,
        lockId: LockId,
        releaseLockIfSuccessful: boolean
    ): Promise<IOperationResult<ServiceRequestStore>> {
        return this.executeOperationAsync<ServiceRequestStore, Proxy.DeleteDraftServiceRequestCommandResponse>(
            () => this.apiClient.deleteDraftServiceRequestCommandAsync(
                CreateRequestId(),
                new Proxy.DeleteDraftServiceRequestControllerDto({
                    activeCareActivityId: activeCareActivityId,
                    serviceRequestId: id,
                    rowVersion: rowVersion,
                    lockId: lockId,
                    releaseLockIfSuccessful: releaseLockIfSuccessful
                })
            ),
            _ => {
                return new ServiceRequestStore();
            }
        );
    }

    private getObservationsAsync(
        serviceRequestId: ServiceRequestId
    ): Promise<IOperationResult<IObservation[]>> {
        return this.executeOperationAsync<IObservation[], Proxy.GetObservationsForRequestedServicesQueryResponse>(
            () => this.apiClient.getObservationsForRequestedServicesQueryAsync(
                CreateRequestId(),
                serviceRequestId.value
            ),
            response => {
                return Promise.resolve(
                    response.observations.map(i => getObservation(i))
                );
            }
        );
    }

    private async mapServiceRequestAsync(store: ServiceRequestStore) {
        await this.initializeObservationDefinitionsAsync(store);

        if (!isNullOrUndefined(store.executingCareActivityId)) {
            const observationsResponse = await this.getObservationsAsync(store.id);
            store.requestedServices.forEach(i => i.initializeObservations(store.patientId, store.executingCareActivityId, observationsResponse.result));
        }

        return store;
    }

    private async initializeObservationDefinitionsAsync(store: ServiceRequestStore) {
        const medicalServices = store.requestedServices.map(i => i.medicalServiceVersionSelector);
        const observationDefinitions = await this.careReferenceData.observationDefinition.loadObservationDefinitionsForMedicalServicesAsync(medicalServices);
        store.requestedServices.forEach(i => i.initializeObservationDefinitions(observationDefinitions.value));
    }
}