import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import FinancingClassId from "@Primitives/FinancingClassId.g";
import HealthcareProfessionId from "@Primitives/HealthcareProfessionId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import HunLocationTypeId from "@Primitives/HunLocationTypeId.g";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import PerformanceReportGroupingStore from "./PerformanceReportGroupingStore";
import PerformanceReportOutputColumnStore from "./PerformanceReportOutputColumnStore";
import PerformanceReportCareActivityState from "./PerformanceReportCareActivity";
import PerformanceReportId from "@Primitives/PerformanceReportId.g";
import ExternalLocationTypeId from "@Primitives/ExternalLocationTypeId.g";

export default class PerformanceReport extends EntityStoreBase<PerformanceReportId> implements IDirtyChecked {

    @State.observable.ref public name: string = "";
    @State.observable.ref public wentUnderCareAtDateRange: LocalDateRange = null;
    @State.observable.ref public pointOfCares: PointOfCareId[] = [];
    @State.observable.ref public financingClasses: FinancingClassId[] = [];
    @State.observable.ref public healthcareProfessions: HealthcareProfessionId[] = [];
    @State.observable.ref public referralWorkPlaceTypes: ExternalLocationTypeId[] = [];
    @State.observable.ref public insurerOrganization: InsurerOrganizationId = null;
    @State.observable.ref public doctors: PractitionerId[] = [];
    @State.observable.ref public assistants: PractitionerId[] = [];
    @State.observable.ref public referralWorkplaces: ExternalLocationId[] = [];
    @State.observable.ref public isExternal: boolean = null;
    @State.observable.ref public careActivityStates: PerformanceReportCareActivityState[] = [];
    @State.observable.ref public isWithoutFinancedServices: boolean = false;

    public groupings: IObservableArray<PerformanceReportGroupingStore> = State.observable([]);
    public outputColumns: IObservableArray<PerformanceReportOutputColumnStore> = State.observable([]);

    @State.action.bound
    public onNameChange(newValue: string) {
        this.name = newValue;
    }

    @State.action.bound
    public onWentUnderCareAtDateRangeChange(newValue: LocalDateRange) {
        this.wentUnderCareAtDateRange = newValue;
    }

    @State.action.bound
    public onPointOfCareChange(newValue: PointOfCareId, checkedValue: boolean) {
        if (this.pointOfCares) {
            if (checkedValue === false) {
                this.pointOfCares = this.pointOfCares.filter(x => x.value !== newValue.value);
            } else if (!this.pointOfCares.some(x => x.value === newValue.value)) {
                this.pointOfCares = [...this.pointOfCares, newValue];
            }
        }
    }

    @State.action.bound
    public onHealthcareProfessionChange(newValue: HealthcareProfessionId[]) {
        this.healthcareProfessions = newValue;
    }

    @State.action.bound
    public onFinancingClassChange(newValue: FinancingClassId[]) {
        this.financingClasses = newValue;
    }

    @State.action.bound
    public onReferralWorkPlaceTypeChange(newValue: ExternalLocationTypeId[]) {
        this.referralWorkPlaceTypes = newValue;
    }

    @State.action.bound
    public onInsurerOrganizationChange(newValue: InsurerOrganizationId) {
        this.insurerOrganization = newValue;
    }

    @State.action.bound
    public onDoctorChange(newValue: PractitionerId[]) {
        this.doctors = newValue;
    }

    @State.action.bound
    public onAssistantChange(newValue: PractitionerId[]) {
        this.assistants = newValue;
    }

    @State.action.bound
    public onReferralWorkplaceChange(newValue: ExternalLocationId[]) {
        this.referralWorkplaces = newValue;
    }

    @State.action.bound
    public onIsExternalChange(newValue: boolean) {
        this.isExternal = newValue;
    }

    @State.action.bound
    public onCareActivityStateChange(newValue: PerformanceReportCareActivityState[]) {
        this.careActivityStates = newValue;
    }

    @State.action.bound
    public onIsWithoutFinancedServicesChange(newValue: boolean) {
        this.isWithoutFinancedServices = newValue;
    }

    private _dirtyChecker = new StoreDirtyChecker();

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "operationInfo"
    ];

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }
}