import DependencyContainer from "@DiContainer";
import * as ProductivityProxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Productivity/Api/Proxy.g";
import HunEHealthWorklistApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/HunEHealthWorklistApiAdapter";

DependencyContainer
    .bind("IHunEHealthWorklistPackageClient")
    .to(ProductivityProxy.HunEHealthWorklistPackageClient)
    .inSingletonScope();

DependencyContainer
    .bind("HunEHealthWorklistApiAdapter")
    .to(HunEHealthWorklistApiAdapter)
    .inSingletonScope();
