import Di from "@Di";
import IScreenRegistry, { IShowScreenActionDescriptor, IScreenPropsBase, ISidebarPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import ValueWrapperMap from "@Toolkit/CommonWeb/Model/ValueWrapperMap";
import FrontendActionId from "@Toolkit/ReactClient/ActionProcessing/FrontendActionId";
import GlobalActionRegistry from "@Toolkit/ReactClient/ActionProcessing/GlobalActionRegistry";
import IFrontendActionDisplaySettings from "@Toolkit/ReactClient/ActionProcessing/IFrontendActionDisplaySettings";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import ShowScreenActionProcessor from "./ShowScreenActionProcessor";

@Di.injectable()
export default class ScreenRegistry implements IScreenRegistry {
    private readonly map = new ValueWrapperMap<FrontendActionId, IShowScreenActionDescriptor<ShowScreenFrontendActionBase>>();

    constructor(
        @Di.inject("GlobalActionRegistry") private readonly actionRegistry: GlobalActionRegistry
    ) { }

    public register<
        TAction extends ShowScreenFrontendActionBase,
        TScreenProps extends IScreenPropsBase<TAction> = IScreenPropsBase<TAction>,
        TSidebarProps extends ISidebarPropsBase<TAction> = ISidebarPropsBase<TAction>
    >(
        actionId: FrontendActionId,
        descriptor: IShowScreenActionDescriptor<TAction, TScreenProps, TSidebarProps>,
        actionDisplaySettings?: IFrontendActionDisplaySettings
    ) {
        this.actionRegistry.register(actionId, _ => new ShowScreenActionProcessor(), actionDisplaySettings);
        this.map.set(actionId, descriptor);
    }

    public getDescriptorOrNull(id: FrontendActionId) {
        return this.map.getOrNull(id);
    }
}