exports = module.exports = require("../../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".UniquePermissionConfigurationPanel_new-element-panel_3ZTV3 {\n  background-color: #82d4ed;\n  width: 100%;\n  margin: 0;\n  height: 58px;\n}\n.UniquePermissionConfigurationPanel_new-element-panel_3ZTV3 th:first-child {\n  padding-left: 16px;\n}\n.UniquePermissionConfigurationPanel_margin-right_2ODdS {\n  margin-right: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"new-element-panel": "UniquePermissionConfigurationPanel_new-element-panel_3ZTV3",
	"newElementPanel": "UniquePermissionConfigurationPanel_new-element-panel_3ZTV3",
	"margin-right": "UniquePermissionConfigurationPanel_margin-right_2ODdS",
	"marginRight": "UniquePermissionConfigurationPanel_margin-right_2ODdS"
};