import React, { useCallback, useMemo } from "react";
import * as Ui from "@CommonControls";
import IActionProps from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/IActionProps";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import WorklistActionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistActionType.g";

interface INDataPrimaryActionButtonProps extends IActionProps {
    _activityRegistry?: IActivityRegistry;
    currentBatchActivityReference: string | null;
}


const NDataPrimaryActionButton: React.FC<INDataPrimaryActionButtonProps> = props => {

    const callbackAsync = useCallback(async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        await props.onActionAsync(
            props.rowId,
            props.commandToken,
            { useCaseDisplayMode: props.useCaseDisplayMode },
            props.worklistActionType,
            props.activityReference,
            props.clientSideAction
        );
    },
        [props.rowId, props.commandToken, props.useCaseDisplayMode, props.clientSideAction, props.worklistActionType, props.onActionAsync]
    );

    const dependencies = useDependencies(c => ({
        localizationService: c.resolve<ILocalizationService>("ILocalizationService"),
    }));

    const icon = useMemo(() => props._activityRegistry?.getByReference(props.activityReference)?.iconName, [props.activityReference]);
    const cellMode = useMemo(() => props.cellMode && !!icon, [props.cellMode]);
    const title = useMemo(() => {
        const localizedTitle = dependencies.localizationService.localizeReferenceData(props.titleResourceId);
        return isNullOrUndefined(localizedTitle) || localizedTitle === "" ? props.titleResourceId.value : localizedTitle;
    }, [props.titleResourceId?.value]);

    const automationId = useMemo(
        () => props.titleResourceId.value.split(".")[props.titleResourceId.value.split(".").length - 2] + "_" + props.titleResourceId.value.split(".").pop(),
        [props.titleResourceId?.value]
    );

    const isActive = useMemo(
        () => props.worklistActionType === WorklistActionType.Batch && props.currentBatchActivityReference === props.activityReference,
        [props.worklistActionType, props.currentBatchActivityReference, props.activityReference]
    );

    return (
        <Ui.Button
            disabled={props.disabled}
            text={cellMode ? undefined : title}
            tooltipContent={cellMode ? title : undefined}
            tooltipPosition="bottom"
            onClickAsync={callbackAsync}
            iconName={icon}
            automationId={automationId}
            size={props.size}
            visualStyle={props.worklistActionType === WorklistActionType.Main ? "primary" : "standard"}
            stopPropagation
            active={isActive}
        />
    );
};

export default NDataPrimaryActionButton;
