import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IFormDefinitionImportDialogResult, IFormDefinitionImportDialogParams } from "./FormDefinitionImportDialogParams";
import React from "react";
import { Modal, TextBox, Button } from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IFormDefinitionImportDialogProps extends IModalComponentParams<IFormDefinitionImportDialogResult>, IFormDefinitionImportDialogParams {
    // this is usually empty, or contains only a _dependencies prop
}

@State.observer
export default class FormDefinitionImportDialog extends React.Component<IFormDefinitionImportDialogProps> {

    @State.observable.ref private dataToImport = "";

    public render() {
        return (
            <Modal isOpen onClose={this.cancel} title="FormDefinition JSON">
                <TextBox
                    automationId="dataToImport_TextBox"
                    value={this.dataToImport}
                    onChange={this.setDataToImport}
                    multiline
                    multilineMinLineCount={20}
                    style={{ fontFamily: "Courier New" }}
                />

                <Modal.Footer 
                    right={(
                        <Button automationId="ok_Button" onClick={this.ok} visualStyle="primary" text="OK" />
                    )}
                />
            </Modal>
        );
    }

    @State.action.bound
    private setDataToImport(value: string) {
        this.dataToImport = value;
    }

    @State.bound
    private cancel() {
        this.props.onClose({
            dataToImport: null
        });
    }

    @State.bound
    private ok() {
        this.props.onClose({
            dataToImport: this.dataToImport
        });
    }
}