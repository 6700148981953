import FormLogicToken from "@Toolkit/FormEngine/Model/FormLogicToken";
import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";

export interface IFormLogicTokenEditorDialogParams extends IModalParams {
    readonly formLogicTokens: FormLogicToken[];
}

export interface IFormLogicTokenEditorDialogResult {
    formLogicTokens: FormLogicToken[];

}

export default class FormLogicTokenEditorDialogParams implements IFormLogicTokenEditorDialogParams {

    public static type = "FormLogicTokenEditorDialog";
    public get type() { return FormLogicTokenEditorDialogParams.type; }

    constructor(
        public readonly formLogicTokens: FormLogicToken[],
    ) { }

}