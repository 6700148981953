import Di from "@Di";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import { IValidationProblemParameterMapper } from "@Toolkit/CommonWeb/ApiAdapter/IValidationProblemParameterMapper";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import ConditionId from "@Primitives/ConditionId.g";
import GenderId from "@Primitives/GenderId.g";
import _ from "@HisPlatform/Common/Lodash";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

@Di.injectable()
export default class HunCareDiagnosisListValidationParameterMapper implements IValidationProblemParameterMapper {
    constructor(
        @Di.inject("CareReferenceDataStore") private careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("CommonReferenceDataDataStore") private commonReferenceDataStore: CommonReferenceDataDataStore
    ) { }

    protected getConditionIdFromParameterDto(problem: IClientValidationProblem, parameterDtoName: string): ConditionId {
        const rawId = _.get(problem.parameters, parameterDtoName + ".ConditionId.Value");
        return rawId ? new ConditionId(rawId.toString()) : null;
    }

    @State.bound
    public collectValidationProblemParameterReferenceData(vp: IClientValidationProblem): void {
        for (const key in vp.parameters) {
            if (key) {
                const conditionId = this.getConditionIdFromParameterDto(vp, key);
                if (conditionId) {
                    this.careReferenceDataStore.condition.collectVersionSelector({
                        id: conditionId,
                        validOn: LocalDate.today()
                    });
                }
            }
        }
    }

    @State.bound
    public async loadCollectedValidationProblemParameterReferenceDataAsync(): Promise<void> {
        await this.careReferenceDataStore.condition.loadCollectedAsync();
        await this.commonReferenceDataStore.gender.ensureLoadedAsync();
    }

    @State.bound
    public resolveValidationProblemParameters(vp: IClientValidationProblem): { [id: string]: string; } {
        let results = {};

        for (const key in vp.parameters) {
            if (key) {
                const conditionId = this.getConditionIdFromParameterDto(vp, key);
                if (conditionId) {
                    results = { ...results , ConditionCode: this.careReferenceDataStore.condition.get({
                        id: conditionId,
                        validOn: LocalDate.today()})?.code
                    };
                }

                if (vp.parameters[key]?.GenderId?.Value) {
                    results = {
                        ...results ,
                        Gender: this.commonReferenceDataStore.gender.get(new GenderId(
                            vp.parameters[key].GenderId.Value + ""))?.displayValue?.Name?.toLocaleLowerCase()
                    };
                }

                if (!isNullOrUndefined(vp.parameters[key]?.MinAgeInYears)) {
                    results = {
                        ...results,
                        MinAgeInYears: vp.parameters[key].MinAgeInYears
                    };
                }

                if (!isNullOrUndefined(vp.parameters[key]?.MaxAgeInYears)) {
                    results = {
                        ...results,
                        MaxAgeInYears: vp.parameters[key].MaxAgeInYears
                    };
                }
            }
        }

        return results;
    }
}