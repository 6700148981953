import React, { Fragment } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import IMedicalServiceVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalServiceVersion";
import * as Ui from "@CommonControls";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";
import MedicalServiceId, { IMedicalServiceVersionSelector } from "@Primitives/MedicalServiceId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import * as Styles from "./RequestedServicesBody.less";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";

interface IMostRelevantServicesBodyDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
}

interface IMostRelevantServicesBodyProps {
    _dependencies?: IMostRelevantServicesBodyDependencies;
    rawJsonValue: string;
}

interface IMostRelevantServiceItem {
    identifier: string;
    medicalServiceVersionSelector?: IMedicalServiceVersionSelector;
    schedulingServiceId?: SchedulingServiceId;
}

interface IMedicalServiceItem {
    identifier: string;
    medicalService?: IMedicalServiceVersion;
}

interface ISchedulingServiceItem {
    schedulingService?: SchedulingServiceSubjectStore;
}

@State.observer
class MostRelevantServicesBody extends React.Component<IMostRelevantServicesBodyProps> {

    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get schedulingReferenceDataStore() { return this.props._dependencies.schedulingReferenceDataStore; }

    @State.observable.ref private medicalServices: IMedicalServiceItem[] = null;
    @State.observable.ref private schedulingServices: ISchedulingServiceItem[] = null;

    private initializePanelAsync = createInitialPanelLoader(this.loadAsync);
    public componentDidMount() {
        dispatchAsyncErrors(this.initializePanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IMostRelevantServicesBodyProps) {
        if (this.props.rawJsonValue !== prevProps.rawJsonValue) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.bound
    private async loadAsync() {
        const rawValue = JSON.parse(this.props.rawJsonValue);

        const mostRelevantServices: IMostRelevantServiceItem[] = rawValue && rawValue.map((it: any) => ({
            identifier: it.RequestedServiceIdentifier,
            medicalServiceVersionSelector: it.MedicalServiceVersionSelector ? new EntityVersionSelector<MedicalServiceId>(MedicalServiceId.fromJS(it.MedicalServiceVersionSelector.EntityId), LocalDate.fromJS(it.MedicalServiceVersionSelector.ValidOn)) : null,
            schedulingServiceId: it.SchedulingServiceId ? SchedulingServiceId.fromJS(it.SchedulingServiceId) : null,
        } as IMostRelevantServiceItem));

        await this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleDefinitions.ensureAllLoadedAsync();

        if (!!mostRelevantServices) {
            const medicalServiceVersionSelectors: Array<{ selector: IMedicalServiceVersionSelector, identifier: string }> = [];
            const schedulingServiceIds: SchedulingServiceId[] = [];

            mostRelevantServices.forEach(i => {
                if (i.medicalServiceVersionSelector) {
                    medicalServiceVersionSelectors.push({ selector: i.medicalServiceVersionSelector, identifier: i.identifier });
                }
                if (i.schedulingServiceId) {
                    schedulingServiceIds.push(i.schedulingServiceId);
                }
            });

            if (medicalServiceVersionSelectors.length > 0) {
                await this.careReferenceDataStore.medicalService.ensureLoadedAsync(medicalServiceVersionSelectors.map(i => i.selector));

                const medicalServices: IMedicalServiceItem[] = [];
                medicalServiceVersionSelectors.forEach(i => {
                    medicalServices.push({
                        identifier: i.identifier,
                        medicalService: this.careReferenceDataStore.medicalService.get(i.selector)
                    });
                });

                this.setMedicalServices(medicalServices);
            }
            if (schedulingServiceIds.length > 0) {
                await this.schedulingReferenceDataStore.schedulingServices.ensureLoadedAsync(schedulingServiceIds);

                const schedulingServices: ISchedulingServiceItem[] = [];
                schedulingServiceIds.forEach(i => {
                    schedulingServices.push({ schedulingService: this.schedulingReferenceDataStore.schedulingServices.get(i) });
                });

                this.setSchedulingServices(schedulingServices);
            }
        }
    }

    @State.action
    private setMedicalServices(services: IMedicalServiceItem[]) {
        this.medicalServices = services;
    }

    @State.action
    private setSchedulingServices(services: ISchedulingServiceItem[]) {
        this.schedulingServices = services.filter(s => !!s.schedulingService);
    }

    private get medicalServicesIconName() {
        return this.medicalServices.every(item => !item.identifier) ? "editService" : "stethoscope";
    }

    public render() {
        if (this.initializePanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }

        if (!this.medicalServices && !this.schedulingServices) {
            return null;
        }

        if (this.medicalServices) {
            const providedServiceNames = this.medicalServices.map(s => `${s.identifier || s.medicalService.code.value} ${s.medicalService.name}`).join(", ");

            return (
                <div className={Styles.rowBody}>
                    <Ui.Icon iconName={this.medicalServicesIconName} />&nbsp;
                    <Popper
                        tooltipPlacement="bottom-start"
                        tooltipContent={providedServiceNames}
                        tooltipStyle={{ textAlign: "left" }}
                    >
                        {this.medicalServices.map((s, i) => (
                            <Fragment key={i}>{i > 0 && ", "}<b>{s.identifier || s.medicalService.code.value}</b> {(s.medicalService.alternativeName || s.medicalService.name)}</Fragment>
                        ))}
                    </Popper>
                </div>
            );
        }

        if (this.schedulingServices) {
            const providedServiceNames = this.schedulingServices.map(s => `${s.schedulingService.code} ${s.schedulingService.name}`).join(", ");
            return (
                <div className={Styles.rowBody}>
                    <Ui.Icon iconName="editService" />&nbsp;
                    <Popper
                        tooltipPlacement="bottom-start"
                        tooltipContent={providedServiceNames}
                        tooltipStyle={{ textAlign: "left" }}
                    >
                        {this.schedulingServices.map((s, i) => {
                            const scheduleDefinition = this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleDefinitions.items.find(a =>
                                a.ownedSchedulingServices.some(o => o.value === s.schedulingService.id.value));

                            return (
                                <Fragment key={i}>
                                    {i > 0 && ", "}
                                    <b>{s.schedulingService.code}</b> {s.schedulingService.name} ({scheduleDefinition?.name})
                                </Fragment>
                            );
                        })}
                    </Popper>
                </div>
            );
        }

        return null;
    }
}

export default connect(
    MostRelevantServicesBody,
    new DependencyAdapter<IMostRelevantServicesBodyProps, IMostRelevantServicesBodyDependencies>(c => ({
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore")
    }))
);
