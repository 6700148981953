import ResourceBlockingId from "@Primitives/ResourceBlockingId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DateWithTimeRange from "./DateWithTimeRange";
import PractitionerId from "@Primitives/PractitionerId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";

export default class ResourceBlocking extends EntityStoreBase<ResourceBlockingId> {
    @State.observable.ref public resource: PractitionerId | OrganizationUnitId;
    @State.observable public description: string;
    @State.observable.ref public dateWithTimeRange: DateWithTimeRange = null;

    constructor(isNew?: boolean) {
        super(isNew);
        
        this.validationResults = [];
        this.dateWithTimeRange = new DateWithTimeRange();
    }

    @State.action.bound public setResource(resource: PractitionerId | OrganizationUnitId) {
        this.resource = resource;
    }

    @State.action.bound public setDescription(newValue: string) {
        this.description = newValue;
    }
}