import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import React from "react";
import * as Ui from "@CommonControls";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import ScheduledAppointmentStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ScheduledAppointmentStore";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import PointOfCareColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";
import SchedulingServiceColumn from "@HisPlatform/BoundedContexts/Scheduling/Components/Controls/SchedulingServiceColumn";
import PractitionerColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerColumn/PractitionerColumn";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import { IRowCheckState } from "@CommonControls/DataGrid/IDataGridProps";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import PatientId from "@Primitives/PatientId.g";
import DurationBox from "@CommonControls/DurationBox/DurationBox";
import ComplexSchedulePlan from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ComplexSchedulePlan";
import ComplexSchedulingServiceFilter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ComplexSchedulingServiceFilter";
import ComplexSchedulingServiceFilterPanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/ComplexSchedulingServiceFilterPanel/ComplexSchedulingServiceFilterPanel";
import ScheduleMode from "@Primitives/ScheduleMode";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import SchedulingServiceSubject from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubject";

interface IComplexScheduleAppointmentsDialogDependencies {
    schedulingApiAdapter: SchedulingApiAdapter;
    dialogService: IDialogService;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
}

export interface IComplexScheduleAppointmentsDialogProps {
    _dependencies?: IComplexScheduleAppointmentsDialogDependencies;
    _patientId?: PatientId;
    onScheduledAppointmentsCreated: () => Promise<void>;
}

/** @screen */
@State.observer
class ComplexScheduleAppointmentsDialog extends React.Component<IComplexScheduleAppointmentsDialogProps> {

    private readonly resources = StaticSchedulingResources.ScheduleAppointmentsDialog;
    private readonly complexScheduleAppointmentsDialogResources = StaticSchedulingResources.ComplexScheduleAppointmentsDialog;

    private createIdentifier = () => Promise.resolve(new ComplexSchedulingServiceFilter());

    @State.observable.ref private complexSchedulePlan: ComplexSchedulePlan = new ComplexSchedulePlan();
    @State.observable.ref public validationResults: IClientValidationResult[] = [];
    @State.observable.ref private closeEvent = new TypedEvent<boolean>();
    private scheduledAppointments = State.createObservableShallowArray<ScheduledAppointmentStore>([]);
    @State.observable private scheduledAppointmentsCreated: boolean = false;
    @State.observable private isLoading: boolean = false;

    @State.computed public get hasSelectedItems() {
        return !!this.scheduledAppointments && this.scheduledAppointments.some(i => i.isSelectedForCreation);
    }

    @State.computed private get isFirstCheckedInList() {
        return this.complexSchedulePlan.schedulingServiceFilters.some(i => i.isFirst === true);
    }

    @State.computed private get isLastCheckedInList() {
        return this.complexSchedulePlan.schedulingServiceFilters.some(i => i.isLast === true);
    }

    private get schedulingApiAdapter() {
        return this.props._dependencies.schedulingApiAdapter;
    }

    public get hasValidationError() {
        return !arrayIsNullOrEmpty(this.validationResults) && this.validationResults.some(
            r => r.problems && r.problems.some(p => p.severity === "error"));
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.initializeAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.action.bound
    private async initializeAsync() {
        await this.loadAllSchedulesAsync();
    }

    @State.action.bound
    private async loadAllSchedulesAsync() {
        const dataStore = this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleSlotSeries;
        await dataStore.ensureAllLoadedAsync();
    }

    @State.action.bound
    private async validateAsync() {
        const response = await this.schedulingApiAdapter.scheduleAppointments(this.complexSchedulePlan, true);
        return response.value.validationResults;
    }

    @State.action.bound
    private async reScheduleAppointmentsAsync() {
        for (let scheduleItemIndex = 0; scheduleItemIndex < this.scheduledAppointments.length; scheduleItemIndex++) {
            const scheduledAppointments = this.scheduledAppointments
                .filter(appointment => appointment.scheduleItemId === scheduleItemIndex);
            const currentSchedulingServiceFilter = this.complexSchedulePlan.schedulingServiceFilters[scheduleItemIndex];
            for (let scheduledAppointmentIndex = 0; scheduledAppointmentIndex < scheduledAppointments.length; scheduledAppointmentIndex++) {
                currentSchedulingServiceFilter.preDesignatedAppointmentSlots.push(scheduledAppointments[scheduledAppointmentIndex].slotsToBook);
            }
        }

        const notSelectedAppointments = this.scheduledAppointments
            .filter(scheduledAppointment => !scheduledAppointment.isSelectedForCreation);
        if (notSelectedAppointments.length !== 0) {
            await this.schedulingApiAdapter.cancelScheduledAppointmentsSlotBookingsAsync(notSelectedAppointments);
        }

        State.runInAction(() => {
            this?.scheduledAppointments?.clear();
            this.scheduledAppointmentsCreated = false;
        });
    }

    @State.boundLoadingState()
    private async scheduleAppointmentsAsync() {
        const response = await this.schedulingApiAdapter.complexScheduleAppointmentsAsync(this.complexSchedulePlan, false);

        State.runInAction(() => {
            this.scheduledAppointments.replace(response.value.scheduledAppointments);
            this.validationResults = response.value.validationResults;
        });
    }

    @State.boundLoadingState()
    private async createScheduledAppointmentsAsync() {
        const scheduledAppointmentsForCreation = this.scheduledAppointments.filter(i => i.isSelectedForCreation);
        const scheduledAppointmentsForCancellation = this.scheduledAppointments.filter(i => i.isBooked && !i.isSelectedForCreation);

        await this.schedulingApiAdapter.createScheduledAppointmentsAsync(scheduledAppointmentsForCreation, scheduledAppointmentsForCancellation, this.props._patientId);

        State.runInAction(() => {
            this.scheduledAppointmentsCreated = true;
        });

        this.props.onScheduledAppointmentsCreated();
        await this.closeAsync();
    }

    @State.action.bound
    private async cancelScheduledAppointmentsSlotBookingsAsync() {
        if (!!this.scheduledAppointments) {
            await this.schedulingApiAdapter.cancelScheduledAppointmentsSlotBookingsAsync(this.scheduledAppointments);
        }
    }

    @State.action.bound
    private cancelScheduledAppointmentsSlotBookings() {
        if (!this.scheduledAppointmentsCreated) {
            dispatchAsyncErrors(this.cancelScheduledAppointmentsSlotBookingsAsync(), this);
        }
    }

    @State.bound
    private async removeSchedulingServiceFilterAsync(identifier: ComplexSchedulingServiceFilter) {
        await this.releaseSlotAsync(identifier);
        this.complexSchedulePlan.removeSchedulingServiceFilter(identifier);
    }

    @State.bound
    private async closeAsync() {
        await this.releaseAllSlotsAsync();
        this.closeEvent.emit(false);
    }

    private async releaseAllSlotsAsync() {
        for (let i = 0; i < this.complexSchedulePlan.schedulingServiceFilters.length; i++) {
            await this.releaseSlotAsync(this.complexSchedulePlan.schedulingServiceFilters[i]);
        }
    }

    private async releaseSlotAsync(identifier: ComplexSchedulingServiceFilter) {
        const appointment = new SchedulingServiceSubject();
        appointment.schedulingServiceId = identifier.schedulingServiceId;
        if (identifier.preDesignatedAppointmentSlots && identifier.preDesignatedAppointmentSlots.length !== 0) {
            await this.schedulingApiAdapter.cancelSlotBookingAsync(appointment as Appointment, identifier.preDesignatedAppointmentSlots.flat());
        }
    }

    @State.bound
    private rowCheckState(row: ScheduledAppointmentStore): IRowCheckState {
        const isCreatable = row.isBooked;
        return { isChecked: row.isSelectedForCreation, isVisible: isCreatable, isDisabled: false } as IRowCheckState;
    }

    @State.action.bound
    private checkRow(isChecked: boolean, row: ScheduledAppointmentStore) {
        row.isSelectedForCreation = isChecked;
    }

    @State.bound
    private handleAppointmentSchedulerError() {
        dispatchAsyncErrors(this.handleAppointmentSchedulerErrorAsync(), this);
        return true;
    }

    @State.bound
    private async handleAppointmentSchedulerErrorAsync() {
        await this.props._dependencies.dialogService.ok(StaticWebAppResources.Common.Label.Error, StaticSchedulingResources.ComplexScheduleAppointmentsDialog.SchedulerError);
    }

    @State.bound
    public renderSchedulingServiceFilterItem(complexSchedulingServiceFilter: ComplexSchedulingServiceFilter, index: number) {
        return (
            <ComplexSchedulingServiceFilterPanel
                complexSchedulingServiceFilter={complexSchedulingServiceFilter}
                index={index}
                readonly={false}
                isFirstCheckedInList={this.isFirstCheckedInList}
                isLastCheckedInList={this.isLastCheckedInList}
            />
        );
    }

    @State.bound
    public renderSchedulePlan() {
        return (
            <>
                <BusinessErrorHandler.Register businessErrorName="AppointmentSchedulerError" handler={this.handleAppointmentSchedulerError} />
                <ValidationBoundary
                    entityTypeName="ComplexScheduledAppointments"
                    validationResults={this.validationResults}
                    validateOnMount
                    onValidateAsync={this.validateAsync}>

                    <Ui.GroupBox title={this.resources.GeneralSettingsTitle}>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={4}>
                                <Ui.DateTimePicker
                                    label={this.resources.IntervalFrom}
                                    value={this.complexSchedulePlan.from}
                                    onChange={this.complexSchedulePlan.setFrom}
                                    propertyIdentifier="From"
                                    automationId="From"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <Ui.DateTimePicker
                                    label={this.resources.IntervalTo}
                                    value={this.complexSchedulePlan.to}
                                    onChange={this.complexSchedulePlan.setTo}
                                    propertyIdentifier="To"
                                    automationId="To"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <DurationBox
                                    label={this.resources.ScheduleLength}
                                    from={this.complexSchedulePlan.from}
                                    to={this.complexSchedulePlan.to}
                                    automationId="scheduleLength"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={12}>
                                <Ui.UniversalEnumSelector
                                    label={this.complexScheduleAppointmentsDialogResources.ScheduleMode}
                                    enumOrigin="server"
                                    displayMode="groupedRadioButtons"
                                    enumName={"ScheduleMode"}
                                    enumType={ScheduleMode}
                                    value={this.complexSchedulePlan.scheduleMode}
                                    onChange={this.complexSchedulePlan.setScheduleMode}
                                    automationId="scheduleMode"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.GroupBox>
                    <Ui.GroupBox title={this.resources.SchedulingServiceFilterTitle}>
                        <ListPanel<ComplexSchedulingServiceFilter>
                            alwaysEdit
                            items={this.complexSchedulePlan.schedulingServiceFilters}
                            renderItemEditor={this.renderSchedulingServiceFilterItem}
                            onRemoveItem={this.removeSchedulingServiceFilterAsync}
                            onCreateNewAsync={this.createIdentifier}
                            propertyIdentifier="ComplexSchedulingServiceFilters"
                            isCompactEmptyState
                            automationId="complexSchedulingServiceFilters"
                        />
                    </Ui.GroupBox>
                </ValidationBoundary>
            </>
        );
    }

    @State.bound
    public renderScheduledAppointmentsList() {
        return this.scheduledAppointments && (
            <Ui.DataGrid
                dataSource={this.scheduledAppointments}
                isSelectable={true}
                hasHeader
                fixedLayout
                automationId="scheduledAppointmentsList"
                rowCheckState={this.rowCheckState}
                onRowChecked={this.checkRow}
            >
                <DataGridDateTimeColumn
                    header={this.resources.ScheduledAppointmentsList.FromColumn}
                    id={"from"}
                    dataGetter={"from"}
                    nullValue={this.resources.ScheduledAppointmentsList.FromColumnNoValue}
                />
                <SchedulingServiceColumn
                    header={this.resources.ScheduledAppointmentsList.SchedulingServiceColumn}
                    id={"schedulingServiceId"}
                    dataGetter={"schedulingServiceId"}
                    displayScheduleDefinitionName
                />
                <PointOfCareColumn
                    header={this.resources.ScheduledAppointmentsList.OrganizationUnitColumn}
                    id={"organizationUnitId"}
                    dataGetter={"organizationUnitId"}
                />
                <PractitionerColumn
                    header={this.resources.ScheduledAppointmentsList.PractitionerColumn}
                    id={"practitionerId"}
                    dataGetter={"practitionerId"}
                />
            </Ui.DataGrid>
        );
    }

    public render() {
        return (
            <UseCaseFrame
                title={this.resources.Title}
                modalCloseEvent={this.closeEvent}
                modalOnClose={this.cancelScheduledAppointmentsSlotBookings}
                modalSize="compact"
                modalMaxHeight={694}
                isLoading={this.isLoading}
                rightFooter={
                    (!this.scheduledAppointments || !this.scheduledAppointments.length) ?
                        <Ui.Button
                            onClickAsync={this.scheduleAppointmentsAsync}
                            text={this.resources.ScheduleAppointmentsButton}
                            visualStyle="primary"
                            automationId="scheduleAppointmentsButton" /> :
                        <>
                            <Ui.Button
                                onClickAsync={this.reScheduleAppointmentsAsync}
                                text={this.resources.ReScheduleAppointmentsButton}
                                visualStyle="secondary"
                                automationId="reScheduleAppointmentsButton" />
                            <Ui.Button
                                onClickAsync={this.createScheduledAppointmentsAsync}
                                text={this.resources.CreateAppointmentsButton}
                                visualStyle="primary"
                                automationId="createAppointmentsButton"
                                disabled={!this.hasSelectedItems} />
                        </>
                }
                leftFooter={<Ui.Button onClickAsync={this.closeAsync} text={StaticWebAppResources.Common.Button.Cancel} automationId="closeButton" />}
            >
                {(!this.scheduledAppointments || !this.scheduledAppointments.length) ? this.renderSchedulePlan() : this.renderScheduledAppointmentsList()}
            </UseCaseFrame>
        );
    }
}

export default connect(
    ComplexScheduleAppointmentsDialog,
    new DependencyAdapter<IComplexScheduleAppointmentsDialogProps, IComplexScheduleAppointmentsDialogDependencies>(c => ({
        schedulingApiAdapter: c.resolve("SchedulingApiAdapter"),
        dialogService: c.resolve("IDialogService"),
        schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore"),
    })),
    new PatientContextAdapter<IComplexScheduleAppointmentsDialogProps>(c => ({
        _patientId: c.patientId
    })),
);