import React, { useCallback } from "react";
import Styles from "./ServiceRequestListPanel.less";
import { RadioButtonGroupFactory } from "@CommonControls/RadioButtonGroup";
import ServiceRequestFilter from "@Primitives/ServiceRequestFilter";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import * as Ui from "@CommonControls";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";

interface IServiceRequestListToolbarProps {
    isServiceRequestSelected: boolean;
    serviceRequestFilter: ServiceRequestFilter;
    onFilterServiceRequest: (serviceRequestFilter: ServiceRequestFilter) => void;
    isListReadOnly: boolean;
    onCreateNewRequestAsync: () => Promise<void>;
    showServiceRequestFilter: boolean;
}


const ServiceRequestFilterRadioButtonGroup = RadioButtonGroupFactory<ServiceRequestFilter>();

export default function ServiceRequestListToolbar(props: IServiceRequestListToolbarProps) {

    const dependencies = useDependencies(c => ({
        localizationService: c.resolve<ILocalizationService>("ILocalizationService"),
    }));

    const serviceRequestFilterDisplayValueGetter = useCallback((item: ServiceRequestFilter) => {
        return dependencies.localizationService.localizeEnum(ServiceRequestFilter[item], "ServiceRequestFilter").Name;
    }, []);

    return (
        <>
            {!props.isServiceRequestSelected && !!props.onFilterServiceRequest && props.showServiceRequestFilter && (
                <ServiceRequestFilterRadioButtonGroup
                    inline
                    direction="row"
                    displayType="groupedButtons"
                    items={[
                        ServiceRequestFilter.CareActivity,
                        ServiceRequestFilter.Patient
                    ]}
                    value={props.serviceRequestFilter}
                    onChange={props.onFilterServiceRequest}
                    getDisplayValue={serviceRequestFilterDisplayValueGetter}
                    automationId="__serviceRequestFilter" />
            )}
            {!props.isServiceRequestSelected && !props.isListReadOnly && (
                <Ui.Button
                    visualStyle="primary"
                    iconName="plus"
                    permissionCheckOperationNames="CreateNew1,CreateNew2,CreateNew3"
                    permissionDeniedStyle="disabled"
                    text={StaticCareResources.ServiceRequestManagement.NewServiceRequest}
                    onClickAsync={props.onCreateNewRequestAsync}
                    automationId="__createNewRequest"
                />
            )}
        </>
    );
}