import State from "@Toolkit/ReactClient/Common/StateManaging";
import DosageModeId from "@Primitives/DosageModeId.g";
import DosageFormId from "@Primitives/DosageFormId.g";

export default class StatementSubjectDetail {
    @State.observable.ref public dosageAmount: string;
    @State.observable.ref public dosageFormId: DosageFormId;
    @State.observable.ref public efficacy: string;
    @State.observable.ref public intakeFrequency: string;

    constructor(dosageAmount: string, dosageFormId: DosageFormId, efficacy: string, intakeFrequency: string) {
        this.dosageAmount = dosageAmount;
        this.dosageFormId = dosageFormId;
        this.efficacy = efficacy;
        this.intakeFrequency = intakeFrequency;
    }

    @State.action.bound
    public setDosageAmount(newValue: string) {
        this.dosageAmount = newValue;
    }

    @State.action.bound
    public setDosageFormId(newValue: DosageFormId) {
        this.dosageFormId = newValue;
    }

    @State.action.bound
    public setEfficacy(newValue: string) {
        this.efficacy = newValue;
    }

    @State.action.bound
    public setIntakeFrequency(newValue: string) {
        this.intakeFrequency = newValue;
    }
}