import React from "react";
import { TextBox } from "@CommonControls";
import { iconNameType } from "@CommonControls/Icon";
import { Tooltip } from "@Toolkit/ReactClient/Components/Tooltip";
import { ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ICommonEditorProps from "@CommonControls/ICommonEditorProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import Money from "@Toolkit/CommonWeb/Model/Money";

interface IMonexBoxDependencies {
    localizationService: IToolkitLocalizationService;
}

export interface IMoneyBoxProps extends ICommonControlProps, ICommonEditorProps<number | null> {
    _dependencies?: IMonexBoxDependencies;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
    nullValue?: number;
    label?: string;
    iconName?: iconNameType;
    required?: boolean;
    innerLabel?: { upperText: string, lowerText: string };
    size?: "compact" | "standard";
    embedded?: boolean;
    propertyIdentifier?: string;
    automationId: string;
    onlyNumbersAllowedMessage?: string;
    textAlign?: "left" | "center" | "right";
    stopClickPropagation?: boolean;
    visualMode?: "normal" | "dark";
    clearable?: boolean;
    forceShowValidationResults?: boolean;
    currency?: string;
    allowNegative?: boolean;
}

@State.observer
class MoneyBox extends React.Component<IMoneyBoxProps, undefined> {
    @State.observable.ref private internalText: string = null;
    private isFocused = false;

    @State.computed private get displayValue() {
        return isNullOrUndefined(this.props.value) ? null : this.localizationService.localizeMoney(new Money(this.props.value, this.props.currency));
    }

    private get localizationService() {
        return this.props._dependencies!.localizationService;
    }

    public static defaultProps: Partial<IMoneyBoxProps> = {
        nullValue: 0,
        size: "standard",
        disabled: false,
        required: false,
        textAlign: "right",
        clearable: true,
        allowNegative: true
    };

    public componentDidMount() {
        this.updateInternalState();
    }

    public componentDidUpdate(prevProps: IMoneyBoxProps) {
        if (prevProps.value !== this.props.value && !this.isFocused) {
            this.updateInternalState();
        }
    }

    @State.action.bound
    private updateInternalState() {
        this.internalText = this.displayValue;
    }

    @State.action.bound
    private changeHandler(newValue: string | null) {
        if (isNullOrUndefined(newValue)) {
            this.internalText = this.localizationService.localizeMoney(new Money(this.props.nullValue, this.props.currency));
            this.props.onChange?.(this.props.nullValue);
            return;
        }

        // eslint-disable-next-line no-useless-escape
        this.internalText = newValue.replace(/([^\d,\.-]+)/g, "");

        if (this.props.onChange) {
            if (!isNullOrUndefined(this.internalText)) {
                const numValue = parseFloat(this.internalText.replace(",", "."));
                this.props.onChange(isNaN(numValue) ? this.props.nullValue : numValue);
            } else if (this.internalText === "") {
                this.props.onChange(this.props.nullValue);
            }
        }
    }

    @State.action.bound
    private blur(event: React.FocusEvent<HTMLInputElement>) {
        this.isFocused = false;
        if (this.displayValue !== this.internalText) {
            this.updateInternalState();
        }

        this.props.onBlur?.(event);
    }

    @State.bound
    private focusHandler(event: React.FocusEvent<HTMLInputElement>) {
        this.isFocused = true;
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }

        event.target.select();
    }

    public render() {
        const htmlProps = {
            name: this.props.name,
            id: this.props.id,
            style: this.props.style,
            className: this.props.className,
            placeholder: this.props.placeholder,
            onClick: this.props.onClick,
            onBlur: this.props.onBlur,
            onFocus: this.focusHandler,
            onKeyDown: this.props.onKeyDown,
            disabled: this.props.disabled,
            isReadOnly: this.props.isReadOnly,
            textAlign: this.props.textAlign
        };

        return (
            <TextBox
                {...htmlProps}
                label={this.props.label}
                value={this.internalText}
                onChange={this.changeHandler}
                iconName={this.props.iconName}
                innerLabel={this.props.innerLabel}
                size={this.props.size}
                embedded={this.props.embedded}
                propertyIdentifier={this.props.propertyIdentifier}
                automationId={this.props.automationId}
                textAlign={this.props.textAlign}
                required={this.props.required}
                onBlur={this.blur}
                stopClickPropagation={this.props.stopClickPropagation}
                visualMode={this.props.visualMode}
                valueOnClear={null}
                clearable={this.props.clearable}
                forceShowValidationResults={this.props.forceShowValidationResults}
                tooltipContent={this.props.onlyNumbersAllowedMessage}
                tooltipPosition={this.props.tooltipPosition}
                tooltipTextAlign={this.props.tooltipTextAlign}
                onEnterKeyDown={this.updateInternalState}
            />
        );

    }
}

export default connect(
    MoneyBox,
    new DependencyAdapter<IMoneyBoxProps, IMonexBoxDependencies>(c => ({
        localizationService: c.resolve<IToolkitLocalizationService>("IToolkitLocalizationService"),
    })),
);