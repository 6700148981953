exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BMICalculator_result_2Xh6Y {\n  font-size: 24px;\n  font-family: PragatiNarrowBold;\n  margin: 16px;\n}\n.BMICalculator_result-category_8uLij {\n  font-size: 18px;\n  font-family: PragatiNarrowBold;\n  line-height: 1.5;\n}\n.BMICalculator_button-container_12Uu7 {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  margin-bottom: -3px;\n}\n", ""]);

// exports
exports.locals = {
	"result": "BMICalculator_result_2Xh6Y",
	"result-category": "BMICalculator_result-category_8uLij",
	"resultCategory": "BMICalculator_result-category_8uLij",
	"button-container": "BMICalculator_button-container_12Uu7",
	"buttonContainer": "BMICalculator_button-container_12Uu7"
};