import State from "@Toolkit/ReactClient/Common/StateManaging";
import ExtendedFilterStoreBase from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ExtendedFilterStoreBase";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import { IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { createFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterHelpers";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import FinancingClassId from "@Primitives/FinancingClassId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";

export default class PerformanceStatementCareActivityFilterStore extends ExtendedFilterStoreBase {

    constructor(
        filterStore: IFilterStore
    ) {
        super(filterStore);
    }

    public static financingClassIdFilterName: string = "CareActivityBased_FinancingClassIdFilter";
    public static pointOfCareIdFilterName: string = "CareActivityBased_PointOfCareIdFilter";

    @State.observable.ref public financingClassIds: FinancingClassId[] = [];
    @State.observable.ref public pointOfCareIds: PointOfCareId[] = [];  
    
    @State.action.bound
    public setFinancingClassIds(newValue: FinancingClassId[]) {
        const newFinancingClassIds = (newValue === null || newValue === undefined) ? [] : newValue;
        this.financingClassIds = newFinancingClassIds;
        this.setFilter(PerformanceStatementCareActivityFilterStore.financingClassIdFilterName, newFinancingClassIds);
    }
    
    @State.action.bound
    public onPointOfCareChange(newValue: PointOfCareId, checkedValue: boolean) {
        if (this.pointOfCareIds) {
            if (checkedValue === false) {
                this.pointOfCareIds = this.pointOfCareIds.filter(x => x.value !== newValue.value);
            } else if (!this.pointOfCareIds.some(x => x.value === newValue.value)) {
                this.pointOfCareIds = [...this.pointOfCareIds, newValue];
            }
        }
        this.setFilter(PerformanceStatementCareActivityFilterStore.pointOfCareIdFilterName, this.pointOfCareIds);
    }

    @State.action.bound
    public clearFilters() {
        this.financingClassIds = [];
        this.pointOfCareIds = [];
    }

    @State.action.bound
    public clearAllFilters() {
        this.clearFilters();
        this.filterStore.__reset();
    }

    public static getFilterDescriptors() {
        const descriptors: IDataGridFilterDescriptor[] = [];

        // Create value serializers
        const financingClassIdFilterValueSerializer: IDataGridFilterValueSerializer<FinancingClassId | FinancingClassId[]> = {
            deserialize: (value: string) => {
                if (!value) {
                    return null;
                }
                const items = value.split(",");
                if (items.length === 1) {
                    return new FinancingClassId(items[0]);
                }
                
                return items.map(i => new FinancingClassId(i));
            },
            serialize: (value: FinancingClassId | FinancingClassId[]) => {
                return Array.isArray(value) ? value.map(v => v.value).join(",") : value.value;
            }
        };

        const pointOfCareIdFilterValueSerializer: IDataGridFilterValueSerializer<PointOfCareId | PointOfCareId[]> = {
            deserialize: (value: string) => {
                if (!value) {
                    return null;
                }
                const items = value.split(",");
                if (items.length === 1) {
                    return new PointOfCareId(items[0]);
                }
                
                return items.map(i => new PointOfCareId(i));
            },
            serialize: (value: PointOfCareId | PointOfCareId[]) => {
                return Array.isArray(value) ? value.map(v => v.value).join(",") : value.value;
            }
        };

        // Create descriptors
        const financingClassIdFilterDescriptor = createFilterDescriptor(this.financingClassIdFilterName,
            financingClassIdFilterValueSerializer,
            WorklistConditionType.EntityId);

        const pointOfCareIdFilterDescriptor = createFilterDescriptor(this.pointOfCareIdFilterName,
            pointOfCareIdFilterValueSerializer,
            WorklistConditionType.EntityId);

        // Fill descriptors array
        descriptors.push(financingClassIdFilterDescriptor);
        descriptors.push(pointOfCareIdFilterDescriptor);

        return descriptors;
    }
}
