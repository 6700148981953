import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import PointOfCareSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareSelectBox";
import CoverageCheckListPanelFilterStore from "./CoverageCheckListPanelFilterStore";
import ICoverageCheckListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CheckCoverageEligibility/ICoverageCheckListItem";
import PersonNameColumn from "@HisPlatformControls/DataGridColumns/PersonNameColumn";
import CoverageEligibilityCheckResultColumn from "./CoverageEligibilityCheckResultColumn";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import { IPagingState, IOrderingState, DataGridLoadType } from "@CommonControls/DataGrid/IDataGridProps";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import DataGridDateColumn from "@CommonControls/DataGrid/Column/DataGridDateColumn";

interface ICoverageCheckListPanelViewProps {
    filterStore: CoverageCheckListPanelFilterStore;
    results: IPagedItems<ICoverageCheckListItem>;
    paging: IPagingState;
    ordering: IOrderingState;
    onChangeAsync: (
        type: DataGridLoadType,
        paging: IPagingState,
        ordering: IOrderingState | IOrderingState[],
        filter: any,
        columns: IDataGridColumnProps[]) => Promise<void>;
}

@State.observer
export default class CoverageCheckListPanelView extends React.Component<ICoverageCheckListPanelViewProps> {

    private get filterStore() {
        return this.props.filterStore;
    }

    public render() {
        return (
            <Ui.PageBoxContainer>
                <Ui.PageBox title={StaticHunEHealthInfrastructureCareResources.CareRegister.CoverageCheckListPanel.Title}>
                    <Ui.PageBox.Body hasNoGaps>
                        <Ui.Flex outerSpacing="regular">
                            <Ui.Flex.Item xs={3}>
                                <PointOfCareSelectBox multiSelect
                                    label={StaticHunEHealthInfrastructureCareResources.CareRegister.CoverageCheckListPanel.Labels.PointOfCare}
                                    value={this.filterStore.pointOfCareIds}
                                    onChange={this.filterStore.setPointOfCareIds}
                                    automationId="pointOfCareIds"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <Ui.DataGrid
                            dataSource={this.props.results}
                            onChangeAsync={this.props.onChangeAsync}
                            actionsColumn={false}
                            changeOnMount
                            paging={this.props.paging}
                            ordering={this.props.ordering}
                            rowHeight={40}
                            isSelectable={false}
                            filterStore={this.filterStore}
                            fixedHeight={513}
                            fixedLayout
                            automationId="coverageCheckGrid"
                        >
                            <DataGridDateTimeColumn
                                dataGetter={"checkedAt"}
                                header={StaticHunEHealthInfrastructureCareResources.CareRegister.CoverageCheckListPanel.Labels.CheckedAt}
                                isOrderable
                                leftPadding="small"
                                id="CheckedAt"
                                isFilterable
                                filterStoreValueGetter={this.filterStore.getCheckedAtRange}
                                filterStoreValueSetter={this.filterStore.setCheckedAtRange}
                                showSeconds
                                width={160}
                                automationId="checkedAt"
                            />
                            <PersonNameColumn
                                dataGetter={"patientName"}
                                header={StaticHunEHealthInfrastructureCareResources.CareRegister.CoverageCheckListPanel.Labels.PatientName}
                                isFilterable
                                filterStoreValueGetter={this.filterStore.getPatientName}
                                filterStoreValueSetter={this.filterStore.setPatientName}
                                automationId="patientName"
                            />
                            <DataGridColumn
                                dataGetter={"identiferValue"}
                                header={StaticHunEHealthInfrastructureCareResources.CareRegister.CoverageCheckListPanel.Labels.PatientIdentifier}
                                isFilterable
                                filterStoreValueGetter={this.filterStore.getPatientIdentifier}
                                filterStoreValueSetter={this.filterStore.setPatientIdentifier}
                                automationId="identiferValue"
                            />
                            <DataGridDateColumn
                                dataGetter={"patientBirthDate"}
                                header={StaticHunEHealthInfrastructureCareResources.CareRegister.CoverageCheckListPanel.Labels.PatientBirthDate}
                                isFilterable
                                filterStoreValueGetter={this.filterStore.getPatientBirthDate}
                                filterStoreValueSetter={this.filterStore.setPatientBirthDate}
                                automationId="patientBirthDate"
                            />
                            <CoverageEligibilityCheckResultColumn
                                dataGetter={"coverageEligibilityCheckResult"}
                                header={StaticHunEHealthInfrastructureCareResources.CareRegister.CoverageCheckListPanel.Labels.CoverageEligibilityCheckResult}
                                isOrderable
                                id="CoverageEligibilityCheckResult"
                                isFilterable
                                filterStoreValueGetter={this.filterStore.getCoverageCheckResult}
                                filterStoreValueSetter={this.filterStore.setCoverageCheckResult}
                                automationId="coverageEligibilityCheckResult"
                            />
                            <DataGridColumn
                                dataGetter={"errorCode"}
                                header={StaticHunEHealthInfrastructureCareResources.CareRegister.CoverageCheckListPanel.Labels.ErrorCode}
                                isFilterable
                                filterStoreValueGetter={this.filterStore.getErrorCode}
                                filterStoreValueSetter={this.filterStore.setErrorCode}
                                width={60}
                                automationId="errorCode"
                            />
                            <DataGridColumn
                                dataGetter={"transactionCode"}
                                isOrderable
                                header={StaticHunEHealthInfrastructureCareResources.CareRegister.CoverageCheckListPanel.Labels.TransactionCode}
                                id="TransactionCode"
                                isFilterable
                                filterStoreValueGetter={this.filterStore.getTransactionCode}
                                filterStoreValueSetter={this.filterStore.setTransactionCode}
                                width={330}
                                automationId="transactionCode"
                            />
                        </Ui.DataGrid>
                    </Ui.PageBox.Body>
                </Ui.PageBox>
            </Ui.PageBoxContainer>
        );
    }
}