exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ValidationResultSummary_container_JXdVP {\n  padding: 4px 10px;\n  margin: 0;\n  display: flex;\n  flex-direction: row;\n}\n.ValidationResultSummary_container_JXdVP svg {\n  margin-right: 5px;\n}\n.ValidationResultSummary_container_JXdVP ul {\n  margin: 0px;\n}\n.ValidationResultSummary_container_JXdVP ul.ValidationResultSummary_no-padding_28LS2 {\n  padding: 4px;\n}\n.ValidationResultSummary_container_JXdVP ul li a {\n  padding-left: 4px;\n  text-decoration: underline;\n}\n.ValidationResultSummary_container_JXdVP .ValidationResultSummary_icon-container_Jl6OF {\n  flex-basis: content;\n}\n.ValidationResultSummary_container_JXdVP .ValidationResultSummary_errors-container_2S7da {\n  flex: 1;\n  margin-left: 15px;\n}\n.ValidationResultSummary_error_3uy1H {\n  color: #ce5374;\n  border-radius: 2px;\n  background-color: rgba(206, 83, 116, 0.1);\n  border: 1px solid #ce5374;\n}\n.ValidationResultSummary_warning_3kGeH {\n  color: #fe8443;\n  border-radius: 2px;\n  background-color: rgba(254, 132, 67, 0.1);\n  border: 1px solid #fe8443;\n}\n.ValidationResultSummary_title_mdcJM {\n  font-size: 16px;\n  font-family: PragatiNarrowBold;\n  margin: 0px;\n  margin-bottom: 10px;\n}\n.ValidationResultSummary_more-button_ySt-M {\n  background-color: transparent;\n  border: none;\n  font-size: 16px;\n  margin-top: 0;\n  margin-bottom: 0;\n  padding-top: 0;\n}\n.ValidationResultSummary_more-button_ySt-M svg {\n  fill: #00b7f2;\n  stroke: #00b7f2;\n}\n.ValidationResultSummary_bold_2AS_k {\n  font-family: PragatiNarrowBold;\n}\n.ValidationResultSummary_tab-content_1KCuJ {\n  padding: 0;\n}\n.ValidationResultSummary_tab-content_1KCuJ a {\n  padding-left: 4px;\n}\n.ValidationResultSummary_tab-content_1KCuJ .ValidationResultSummary_no-padding_28LS2 {\n  margin: 4px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "ValidationResultSummary_container_JXdVP",
	"no-padding": "ValidationResultSummary_no-padding_28LS2",
	"noPadding": "ValidationResultSummary_no-padding_28LS2",
	"icon-container": "ValidationResultSummary_icon-container_Jl6OF",
	"iconContainer": "ValidationResultSummary_icon-container_Jl6OF",
	"errors-container": "ValidationResultSummary_errors-container_2S7da",
	"errorsContainer": "ValidationResultSummary_errors-container_2S7da",
	"error": "ValidationResultSummary_error_3uy1H",
	"warning": "ValidationResultSummary_warning_3kGeH",
	"title": "ValidationResultSummary_title_mdcJM",
	"more-button": "ValidationResultSummary_more-button_ySt-M",
	"moreButton": "ValidationResultSummary_more-button_ySt-M",
	"bold": "ValidationResultSummary_bold_2AS_k",
	"tab-content": "ValidationResultSummary_tab-content_1KCuJ",
	"tabContent": "ValidationResultSummary_tab-content_1KCuJ"
};