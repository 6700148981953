import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import PatientId from "@Primitives/PatientId.g";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PatientsCareActivitiesStoreMapper from "./PatientsCareActivitiesStoreMapper";
import CareTypeId from "@Primitives/CareTypeId.g";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import { getQueryPagingAndOrderings } from "@HisPlatform/Common/PagedQueries";
import CareActivitySource from "@Primitives/CareActivitySource.g";
import IListQueryOrderBy from "@Toolkit/CommonWeb/Model/IListQueryOrderBy";
import ListQueryOptions from "@Toolkit/CommonWeb/Model/ListQueryOptions";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import _ from "@HisPlatform/Common/Lodash";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import SearchCareActivitiesOfPatientQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/SearchCareActivitiesOfPatientQueryOrderingFields.g";

@Di.injectable()
export default class PatientsCareActivitiesApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("ICareRegisterClient") private readonly apiClient: Proxy.ICareRegisterClient,
        @Di.inject("PatientsCareActivitiesStoreMapper") private readonly patientsCareActivitiesStoreMapper: PatientsCareActivitiesStoreMapper
    ) {
        super();
    }

    public searchPatientCareActivities(
        patientId: PatientId,
        episodeOfCareIds: EpisodeOfCareId[],
        practitionerIds: PractitionerId[],
        pointOfCareIds: PointOfCareId[],
        dateRange: LocalDateRange,
        careActivitySource: CareActivitySource,
        careTypeIds: CareTypeId[],
        careActivityStates: CareActivityState[],
        careIdentifier: string,
        options: ListQueryOptions,
        extensionData?: any
    ) {
        const orderingFieldsMap = {
            careIdentifier: "CareIdentifier",
            referralWorkplaceId: "ReferralWorkplace",
            carePeriod: "CareDateRange",
            organizationUnitId: "PointOfCare",
            practitionerId: "LeadDoctor",
            referralDoctorId: "ReferralDoctor"
        };

        const newOptions = options && ListQueryOptions.create({
            currentPage: options.currentPage,
            pageSize: options.pageSize,
            orderBy: options.orderBy && options.orderBy.map(ob => {
                return {
                    ascending: ob.ascending,
                    fieldName: orderingFieldsMap[ob.fieldName]
                } as IListQueryOrderBy;
            })
        });

        return this.processOperationAsync(
            new SimpleStore<{isFiltered: boolean, totalCount: number, results: PatientsCareActivityStore[], pageIndex: number, extensionData: any}>(),
            async target => {

                const result = await this.apiClient.searchCareActivitiesOfPatientQueryAsync(
                    CreateRequestId(),
                    new Proxy.SearchCareActivitiesOfPatientControllerDto({
                        patientId: patientId,
                        episodeOfCareIds: episodeOfCareIds,
                        practitionerIds: practitionerIds,
                        pointOfCareIds: pointOfCareIds,
                        searchDateRange: dateRange,
                        careActivitySource: careActivitySource,
                        careTypeIds: careTypeIds,
                        careActivityStates: careActivityStates,
                        careIdentifier: careIdentifier,
                        pagingAndOrderings: newOptions && getQueryPagingAndOrderings(newOptions, SearchCareActivitiesOfPatientQueryOrderingFields),
                        extensionData: extensionData
                    })
                );

                const mapper = this.patientsCareActivitiesStoreMapper;

                mapper.applyToStore(target, result);
            }
        );
    }

    public searchPatientCareActivitiesPermissionCheckAsync() {
        const patientId = new PatientId("-1");
        const searchDateRange = new LocalDateRange();

        return this.processOperationAsync(
            new SimpleStore<{isFiltered: boolean, totalCount: number, results: PatientsCareActivityStore[], pageIndex: number, extensionData: any}>(),
            async target => {
                const result = await this.apiClient.searchCareActivitiesOfPatientQueryAsync(
                    CreateRequestId(),
                    new Proxy.SearchCareActivitiesOfPatientControllerDto({
                        patientId: patientId,
                        searchDateRange: searchDateRange,
                    }),
                    true
                );

                target.operationInfo = createOperationInfo(result);
            }
        );
   
    }
}