import React from "react";
import Styles from "./DocumentViewer.less";
import { getStandardHtmlProps, ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";

type paperWidthType = "A4-portrait" | "fitToWitdth";

export interface IDocumentViewerProps extends ICommonControlProps {
    htmlContent: string;
    noContentString?: string;
    onLoad?: () => void;
    automationId?: string;
    paperWidth?: paperWidthType;
}

const getPaperWidth = (w: paperWidthType) => {
    switch (w) {
        case "A4-portrait":
            return "210mm";
        case "fitToWitdth":
            return "calc(100% - 32px)";
    }
    throw new Error("Cannot handle paper width: " + w);
};

const getIframeContent = (props: IDocumentViewerProps) => {
    let content = props.htmlContent;
    let bodyStyle = ` style="
    padding: 16px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
    outline: 1px solid #cdcdcd;
    background-color: white;
    width: ${getPaperWidth(props.paperWidth)};
    margin: 0 auto;"
    `;

    const responsiveStyle = props.paperWidth === "A4-portrait" ? `
        <style>
            @media (max-width: 800px) {
                body {
                    transform-origin: left top;
                    transform: scale(0.8);
                }
            }
        </style>
    ` : "";

    if (content === null || content === undefined || content === "") {
        content = props.noContentString;
        bodyStyle = " style=\"color: #cdcdcd;user-select: none;\"";
    }

    return `
<!DOCTYPE html>
<html style="cursor: text;background-color: #E6E6E6;padding: 8px;">
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="stylesheet" type="text/css" href="/3rdParty/tinymce/skins/lightgray/content.min.css" />
    ${responsiveStyle}
</head>
<body spellcheck="false"${bodyStyle}>
    ${content}
</body>
</html>`;
};


const DocumentViewer = React.forwardRef((props: IDocumentViewerProps, ref: React.Ref<HTMLIFrameElement>) => {
    const htmlProps = getStandardHtmlProps(props, Styles.content);
    return (
        <iframe
            data-automation-id={props.automationId}
            {...htmlProps}
            ref={ref}
            frameBorder="0"
            srcDoc={getIframeContent(props)}
            onLoad={props.onLoad}
        />
    );
});

DocumentViewer.defaultProps = {
    paperWidth: "A4-portrait"
};

export default DocumentViewer;