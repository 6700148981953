import ResourceId from "@Primitives/ResourceId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import IStatusChangeBlockingReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/IStatusChangeBlockingReason";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";

export default class ShowCareActivityStateChangeNotPossibleClientSideAction extends ClientSideActionDto {
    constructor(
        public title: ResourceId,
        public careActivityId: CareActivityId,
        public activityReferenceResourceId: ResourceId,
        public reasons: IStatusChangeBlockingReason[]) {
        super();
    }
}