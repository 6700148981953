import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import * as Ui from "@CommonControls";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import OrganizationUnit from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnit";
import OrganizationUnitDefinitionId from "@Primitives/OrganizationUnitDefinitionId.g";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import Contact from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/Contact";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ContactPanel from "./ContactPanel";
import RequestStatus from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/RequestStatus";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { CountrySelector, SettlementAndZipCodeSelector } from "@HisPlatformControls";
import OrganizationUnitCodeIsNotAvailableError from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/OrganizationUnitCodeIsNotAvailableError";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import ContactTypeId from "@Primitives/ContactTypeId.g";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

interface IOrganizationUnitPanelDependencies {
    structureApiAdapter: StructureApiAdapter;
    localizationService: ILocalizationService;
    dialogService: IDialogService;
    notificationService: INotificationService;
}

export interface IOrganizationUnitPanelController {
    isDirty(): boolean;

    externalSaveAsync: () => Promise<boolean>;
    setIsLoading: (newValue: boolean) => void;
}

interface IOrganizationUnitPanelProps {
    _dependencies?: IOrganizationUnitPanelDependencies;
    organizationUnitId: OrganizationUnitId;
    parentOrganizationUnitId: OrganizationUnitId;
    newItemDefinitionId: OrganizationUnitDefinitionId;
    onSuccessfulSave?: (id: OrganizationUnitId) => void;
    onCancel?: () => void;
    controller?: IOrganizationUnitPanelController;
}

/** @screen */
@State.observer
class OrganizationUnitPanel extends React.Component<IOrganizationUnitPanelProps> {

    private get notificationService() {
        return this.props._dependencies.notificationService;
    }

    @State.observable.ref public organizationUnit: OrganizationUnit = null;
    @State.observable private isLoading: boolean = true;

    private get structureApiAdapter() {
        return this.props._dependencies.structureApiAdapter;
    }

    constructor(props: IOrganizationUnitPanelProps) {
        super(props);

        if (this.props.controller) {
            this.props.controller.isDirty = () => this.organizationUnit && this.organizationUnit.isDirty();
            this.props.controller.externalSaveAsync = this.saveAsync;
        }
    }

    @State.action.bound
    public setOrganizationUnit(organizationUnit: OrganizationUnit) {
        this.organizationUnit = organizationUnit;
        this.organizationUnit.takeSnapshot();
    }

    @State.boundLoadingState("isLoading")
    private async loadAsync() {
        if (!this.props.organizationUnitId || this.props.organizationUnitId.value === "new") {
            this.initializeNewOrganizationUnit();
        } else {
            const newStore = await this.structureApiAdapter.getOrganizationUnitByIdAsync(this.props.organizationUnitId);
            this.setOrganizationUnit(newStore);
        }
    }

    @State.bound
    private async saveAsync() {
        if (!this.organizationUnit) {
            this.notificationService.showCannotSaveBecauseOfErrors();
            return false;
        }

        let newStore: OrganizationUnit = null;

        if (this.organizationUnit.isNew) {
            newStore = await this.structureApiAdapter.addOrganizationUnitAsync(this.organizationUnit);
        } else {
            newStore = await this.structureApiAdapter.updateOrganizationUnitAsync(this.organizationUnit);
        }

        if (!(newStore.operationInfo.requestStatus === RequestStatus.Success)) {
            if (newStore.operationInfo.businessError.name === OrganizationUnitCodeIsNotAvailableError.name) {
                this.props._dependencies.dialogService.ok(
                    StaticOrganizationResources.OrganizationUnitPanel.Messages.NotifyError,
                    StaticOrganizationResources.OrganizationUnitPanel.Messages.OrganizationUnitCodeIsNotAvailableError);
            }

            return false; // just display error and keep current store
        }

        this.notificationService.showSaveResult(newStore.isPersistedByOperationInfo, newStore.hasValidationWarning);

        if (newStore.hasValidationError) {
            this.setValidationResults(newStore.validationResults);
            return false;
        }

        this.setOrganizationUnit(newStore);

        if (this.props.onSuccessfulSave && newStore && newStore.operationInfo && newStore.operationInfo.requestStatus === RequestStatus.Success) {
            this.props.onSuccessfulSave(newStore.id);
            return true;
        }

        return false;
    }

    @State.action
    private setValidationResults(validationResults: IClientValidationResult[]) {
        this.organizationUnit.validationResults = validationResults;
    }

    private deleteConfirmationAsync() {
        return Promise.resolve(true);
    }

    @State.bound
    private createNewPhoneContactAsync() {
        return Promise.resolve(new Contact(ContactTypeId.Phone));
    }

    @State.bound
    private createNewFaxContactAsync() {
        return Promise.resolve(new Contact(ContactTypeId.Fax));
    }

    @State.bound
    private createNewEmailContactAsync() {
        return Promise.resolve(new Contact(ContactTypeId.Email));
    }

    @State.bound
    private async validateOrganizationUnitAsync(dirtyFields: string[]) {
        const result = await this.structureApiAdapter.validateOrganizationUnitAsync(this.organizationUnit);

        return result.value;
    }

    @State.bound
    private initializeNewOrganizationUnit() {
        const newStore = new OrganizationUnit(true);
        newStore.organizationUnitDefinitionId = this.props.newItemDefinitionId;
        newStore.parentId = this.props.parentOrganizationUnitId;
        this.setOrganizationUnit(newStore);
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }

        if (this.isLoading) {
            return <></>;
        }

        return (
            <>
                {this.organizationUnit &&

                    <ValidationBoundary
                        validationResults={this.organizationUnit.validationResults}
                        validateOnMount
                        onValidateAsync={this.validateOrganizationUnitAsync}
                        entityTypeName="OrganizationUnit"
                    >
                        <Ui.Flex>
                            <Ui.Flex.Item xs={4}>
                                <Ui.TextBox
                                    label={StaticOrganizationResources.OrganizationUnitPanel.Labels.Code}
                                    value={this.organizationUnit.code}
                                    onChange={this.organizationUnit.setCode}
                                    propertyIdentifier="Code"
                                    automationId="code"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <Ui.TextBox
                                    label={StaticOrganizationResources.OrganizationUnitPanel.Labels.ShortName}
                                    value={this.organizationUnit.baseData.naturalIdentifier.shortName}
                                    onChange={this.organizationUnit.baseData.naturalIdentifier.setShortName}
                                    propertyIdentifier="BaseData.OrganizationUnitNaturalIdentifier.ShortName"
                                    automationId="shortName"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <Ui.TextBox
                                    label={StaticOrganizationResources.OrganizationUnitPanel.Labels.Name}
                                    value={this.organizationUnit.baseData.naturalIdentifier.name}
                                    onChange={this.organizationUnit.baseData.naturalIdentifier.setName}
                                    propertyIdentifier="BaseData.OrganizationUnitNaturalIdentifier.Name"
                                    automationId="name"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <Ui.Flex>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={3}>
                                    <CountrySelector
                                        label={StaticWebAppResources.Common.Address.Country}
                                        value={this.organizationUnit.baseData.address.countryId}
                                        onChange={this.organizationUnit.baseData.address.setCountryId}
                                        propertyIdentifier="BaseData.Address.CountryId"
                                        automationId="countryId"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={6}>
                                    <SettlementAndZipCodeSelector
                                        countryId={this.organizationUnit.baseData.address.countryId}
                                        settlement={this.organizationUnit.baseData.address.settlement}
                                        setSettlement={this.organizationUnit.baseData.address.setSettlement}
                                        zipCode={this.organizationUnit.baseData.address.zipCode}
                                        setZipCode={this.organizationUnit.baseData.address.setZipCode}
                                        propertyIdentifierPrefix="BaseData.Address"
                                        automationIdPrefix="BaseData.Address"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={3}>
                                    <Ui.TextBox
                                        label={StaticWebAppResources.Common.Address.AddressLine}
                                        value={this.organizationUnit.baseData.address.addressLine}
                                        onChange={this.organizationUnit.baseData.address.setAddressLine}
                                        propertyIdentifier="BaseData.Address.AddressLine"
                                        automationId="addressLine"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </Ui.Flex>

                        <ValidationBoundary pathPrefix="BaseData">
                            <Ui.Flex>
                                <Ui.Flex.Item xs={4}>
                                    <Ui.GroupBox
                                        title={StaticOrganizationResources.OrganizationUnitPanel.Labels.TelephoneNumbers}>
                                        <ListPanel<Contact>
                                            items={this.organizationUnit.baseData.contacts}
                                            filter={Contact.isPhone}
                                            onDeleteItemConfirmationAsync={this.deleteConfirmationAsync}
                                            onCreateNewAsync={this.createNewPhoneContactAsync}
                                            renderItemEditor={this.renderContactEditor}
                                            isItemInInitialState={this.isInInitialState}
                                            actionButtonsOrientation="vertical"
                                            alwaysEdit
                                            propertyIdentifier="Contacts"
                                            automationId="phoneContacts"
                                        />
                                    </Ui.GroupBox>
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <Ui.GroupBox title={StaticOrganizationResources.OrganizationUnitPanel.Labels.Faxes}>
                                        <ListPanel<Contact>
                                            items={this.organizationUnit.baseData.contacts}
                                            filter={Contact.isFax}
                                            onDeleteItemConfirmationAsync={this.deleteConfirmationAsync}
                                            onCreateNewAsync={this.createNewFaxContactAsync}
                                            renderItemEditor={this.renderContactEditor}
                                            isItemInInitialState={this.isInInitialState}
                                            actionButtonsOrientation="vertical"
                                            alwaysEdit
                                            propertyIdentifier="Contacts"
                                            automationId="faxContacts"
                                        />
                                    </Ui.GroupBox>
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4}>
                                    <Ui.GroupBox title={StaticOrganizationResources.OrganizationUnitPanel.Labels.Emails}>
                                        <ListPanel<Contact>
                                            items={this.organizationUnit.baseData.contacts}
                                            filter={Contact.isEmail}
                                            onDeleteItemConfirmationAsync={this.deleteConfirmationAsync}
                                            onCreateNewAsync={this.createNewEmailContactAsync}
                                            renderItemEditor={this.renderContactEditor}
                                            isItemInInitialState={this.isInInitialState}
                                            actionButtonsOrientation="vertical"
                                            alwaysEdit
                                            propertyIdentifier="Contacts"
                                            automationId="emailContacts"
                                        />
                                    </Ui.GroupBox>
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </ValidationBoundary>
                        {/* We don't want people to play with validity yet
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <Ui.DateRangePicker
                                label={StaticOrganizationResources.OrganizationUnitPanel.Labels.Validity}
                                value={this.organizationUnit.validity}
                                onChange={this.organizationUnit.setValidity} />
                        </Ui.Flex.Item>
                    </Ui.Flex> */}
                        <Ui.Flex xsJustify="end" verticalSpacing="large">
                            <Ui.Flex.Item>
                                {this.props.onCancel && <Ui.Button
                                    text={StaticWebAppResources.Common.Button.Cancel}
                                    onClick={this.props.onCancel}
                                    automationId="cancel"
                                />}
                                <Ui.SaveButton
                                    onClickAsync={this.saveAsync}
                                    visualStyle="primary"
                                    automationId="save"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </ValidationBoundary>}
            </>
        );
    }

    private isInInitialState(item: any) {
        return !item || (item && !item.value);
    }

    private renderContactEditor(contact: Contact, index: number) {
        return (
            <ContactPanel contact={contact} index={index} />
        );
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IOrganizationUnitPanelProps) {
        if (this.props.organizationUnitId !== prevProps.organizationUnitId) {
            dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        }
    }
}

export default connect(
    OrganizationUnitPanel,
    new DependencyAdapter<IOrganizationUnitPanelProps, IOrganizationUnitPanelDependencies>(container => {
        return {
            structureApiAdapter: container.resolve("StructureApiAdapter"),
            localizationService: container.resolve("ILocalizationService"),
            dialogService: container.resolve("IDialogService"),
            notificationService: container.resolve("INotificationService"),
        };
    })
);
