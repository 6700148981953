import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import * as Ui from "@CommonControls";
import PractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/PractitionerRecommendation";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import CareActivityId from "@Primitives/CareActivityId.g";
import PractitionerRecommendationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/PractitionerRecommendation/PractitionerRecommendationApiAdapter";
import MedicationSubsidyClaimTypeColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/MedicationSubsidyClaimTypeColumn";
import MedicationSubsidyOptionColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/MedicationSubsidyOptionColumn";
import DataGridDateColumn from "@CommonControls/DataGrid/Column/DataGridDateColumn";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import { IOrderingState, IRowIndicatorStyle, RowId } from "@CommonControls/DataGrid/IDataGridProps";
import PractitionerRecommendationStatus from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/PractitionerRecommendationStatus";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import * as Styles from "./PractitionerRecommendationList.less";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import MedicationSubsidyOptionId from "@Primitives/MedicationSubsidyOptionId.g";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import PractitionerRecommendationFilterStore from "./PractitionerRecommendationFilterStore";
import PractitionerRecommendationId from "@Primitives/PractitionerRecommendationId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import PointOfCareColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { RecommendationType } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/RecommendationType";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import MedicationPractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/MedicationPractitionerRecommendation";
import EquipmentPractitionerRecommendation from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/EquipmentPractitionerRecommendation";
import MedicationEquipmentClassificationId from "@Primitives/MedicationEquipmentClassificationId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import _ from "@HisPlatform/Common/Lodash";
import OrganizationSelector from "@HisPlatform/BoundedContexts/Organization/Components/OrganizationSelector";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import MedicationSubsidyId from "@Primitives/MedicationSubsidyId.g";
import IPractitionerRecommendationStatusColumnData from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/IPractitionerRecommendationStatusColumnData";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import EquipmentSupportTypeId from "@Primitives/EquipmentSupportTypeId.g";

interface IPractitionerRecommendationListViewDependencies {
    apiAdapter: PractitionerRecommendationApiAdapter;
    referenceDataStore: MedicationRequestReferenceDataStore;
    localizationService: ILocalizationService;
    careReferenceDataStore: CareReferenceDataStore;
    dialogService: IDialogService;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    careActivityApiAdapter: CareActivityApiAdapter2;
}

interface IPractitionerRecommendationListViewProps extends IHasMasterDetailState {
    _dependencies?: IPractitionerRecommendationListViewDependencies;
    careActivityId: CareActivityId;
    practitionerRecommendationList: PractitionerRecommendation[];
    filterStore: PractitionerRecommendationFilterStore;
    showFilter: boolean;
    onOperationSuccessful: () => void;
    onPractitionerRecommendationSelected: (id: PractitionerRecommendationId, type: RecommendationType, readonly: boolean) => void;
    isReadonly?: boolean;
    pointOfCareId: PointOfCareId;
}

/** @screen */
@State.observer
class PractitionerRecommendationListView extends React.Component<IPractitionerRecommendationListViewProps> {

    @State.observable.ref private dataSource = new InMemoryDataGridDataSource(() => this.props.practitionerRecommendationList || [], { columnId: "RecordedOn", direction: "desc" } as IOrderingState);

    private selectId(row: PractitionerRecommendation) {
        return row.id?.value || "";
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadReferenceDataAsync(), this);
    }

    public componentDidUpdate(prevProps: IPractitionerRecommendationListViewProps) {
        if (this.props.practitionerRecommendationList !== prevProps.practitionerRecommendationList) {
            dispatchAsyncErrors(this.loadReferenceDataAsync(), this);
        }
    }

    @State.bound
    private async loadReferenceDataAsync() {
        await this.loadEquipmentAsync();
        await this.loadMedicationsAsync();
    }

    @State.bound
    private async loadEquipmentAsync() {
        const equipmentVersionSelectors: Array<IEntityVersionSelector<MedicationEquipmentClassificationId>> = [];
        for (const recommendation of this.props.practitionerRecommendationList || []) {
            if (recommendation instanceof EquipmentPractitionerRecommendation) {
                if (recommendation.recommendedEquipment1?.id) {
                    equipmentVersionSelectors.push(recommendation.recommendedEquipment1);
                }
                if (recommendation.recommendedEquipment2?.id) {
                    equipmentVersionSelectors.push(recommendation.recommendedEquipment2);
                }
            }
        }
        await this.props._dependencies.referenceDataStore.equipmentSupportType.ensureLoadedAsync();
    }

    @State.bound
    private async loadMedicationsAsync() {
        const medicationVersionSelectors = this.props.practitionerRecommendationList
            ?.filter(i => i instanceof MedicationPractitionerRecommendation)
            .map(i => (i as MedicationPractitionerRecommendation).medicationVersionSelector);
        if (medicationVersionSelectors) {
            await this.props._dependencies.careReferenceDataStore.medications.ensureLoadedAsync(medicationVersionSelectors);
        }
    }

    @State.bound
    private onRowSelected(newIdValue: any) {
        const id = new PractitionerRecommendationId(newIdValue);
        const row = this.props.practitionerRecommendationList.find(i => ValueWrapper.equals(id, i.id));
        if (row) {
            this.props.onPractitionerRecommendationSelected(id, row.type, row.statusColumn.status !== PractitionerRecommendationStatus.UnderRecording);
        } else {
            this.props.onPractitionerRecommendationSelected(null, null, false);
        }
    }

    private getClassNameByRowState(row: PractitionerRecommendation): IRowIndicatorStyle {
        switch (row.statusColumn.status) {
            case PractitionerRecommendationStatus.Final:
                return { className: Styles.submitted };
            case PractitionerRecommendationStatus.UnderRecording:
                return { className: Styles.underRecording };
            case PractitionerRecommendationStatus.Deleted:
                return { className: Styles.deleted };
        }
    }

    @State.bound
    public renderStatus(value: IPractitionerRecommendationStatusColumnData, row: PractitionerRecommendation) {
        return (
            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ flexBasis: "auto", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                    {this.props._dependencies.localizationService.localizeEnum(PractitionerRecommendationStatus[value.status], "PractitionerRecommendationStatus")?.Name || ""}
                </div>
                {(value.digitalPractitionerRecommendationIdentifier) &&
                    <div style={{ flexBasis: "17%", flexGrow: 0, flexShrink: 0, textAlign: "right", }}>
                        <HisPlatformExtensionPoint extensionProps={{ extensionData: row.extensionData }} type="practitionerRecommendationListName">
                            <></>
                        </HisPlatformExtensionPoint>
                    </div>
                }
            </div >
        );
    }

    public recordedOnOrderableValueGetter(date: LocalDate): number {
        return date.toUnix();
    }

    private renderValidTo(row: any, key: string | number, index: number, isUnderEditing: boolean) {
        if (row instanceof MedicationPractitionerRecommendation) {
            return row.validTo;
        } else if (row instanceof EquipmentPractitionerRecommendation) {
            return row.validTo;
        }
        return null;
    }

    public validToOrderableValueGetter(value: LocalDate): number {
        return value.toUnix();
    }

    private nameOrderableValueGetter(value: any, row: PractitionerRecommendation, rowId: React.ReactText, rowIndex: number) {
        if (typeof value === "string") {
            return value;
        }
        if (row instanceof MedicationPractitionerRecommendation) {
            const medication = this.props._dependencies.careReferenceDataStore.medications.get(row.medicationVersionSelector);
            if (row.useMedicationName) {
                return medication.name;
            }
            const substanceNames: string[] = [];
            for (const substanceId of medication.substanceIds) {
                const substance = this.props._dependencies.careReferenceDataStore.substances.get({ id: substanceId, validOn: row.recordedOn || LocalDate.today() });
                if (!isNullOrUndefined(substance)) {
                    substanceNames.push(substance.name);
                }
            }
            return substanceNames.join(", ").toLowerCase();
        } else if (row instanceof EquipmentPractitionerRecommendation) {
            return this.props._dependencies.careReferenceDataStore.medicationEquipmentClassifications.get(value).name;
        }
        return null;
    }

    public claimTypeOrderableValueGetter(claimTypeId: MedicationSubsidyClaimTypeId): string {
        if (isNullOrUndefined(claimTypeId)) {
            return null;
        }
        return claimTypeId.value;
    }

    @State.bound
    public subsidyPercentageOrderableValueGetter(row: PractitionerRecommendation): number {
        if (row instanceof MedicationPractitionerRecommendation) {
            if (row?.medicationSubsidyId) {
                const subsidy = this.props._dependencies.referenceDataStore.medicationSubsidies.get({ id: row.medicationSubsidyId, validOn: row.recordedOn });
                return subsidy?.supportPercentage ? subsidy.supportPercentage : null;
            }

        }
        return null;
    }

    @State.bound
    public subsidyOptionOrderableValueGetter(row: PractitionerRecommendation): string {
        if (row instanceof MedicationPractitionerRecommendation) {
            return row.subsidyOptionId.value;
        }
        return null;
    }

    @State.bound
    public pointOfCareOrderableValueGetter(row: PractitionerRecommendation): string {
        const item = isNullOrUndefined(row.pointOfCareId)
            ? null
            : this.props._dependencies.organizationReferenceDataStore.allPointsOfCareMap.get(row.pointOfCareId);
        return item?.shorthand;
    }

    @State.bound
    public renderMedicationSubsidyPercentage(value: { subsidyId: MedicationSubsidyId, optionId: MedicationSubsidyOptionId, equipmentSupportTypeId: EquipmentSupportTypeId }, row: PractitionerRecommendation) {
        if (value?.subsidyId) {
            const subsidy = this.props._dependencies.referenceDataStore.medicationSubsidies.get({ id: value.subsidyId, validOn: row.recordedOn });
            return subsidy?.supportPercentage ? `${subsidy.supportPercentage}%` : "";
        } else if (value?.equipmentSupportTypeId) {
            const equipmentSupportType = this.props._dependencies.referenceDataStore.equipmentSupportType.get(value.equipmentSupportTypeId);
            return equipmentSupportType?.displayValue?.Name || "";
        }

        return "";
    }

    @State.bound
    private renderType(value: RecommendationType) {
        switch (value) {
            case RecommendationType.GY:
                return "GY";
            case RecommendationType.GYSE:
                return "GYSE";
        }
    }

    @State.bound
    private getMedicationOrEquipmentName(row: PractitionerRecommendation, key: React.ReactText, index: number, isUnderEditing: boolean) {
        if (row instanceof MedicationPractitionerRecommendation) {
            const medication = this.props._dependencies.careReferenceDataStore.medications.get(row.medicationVersionSelector);
            if (row.useMedicationName) {
                return medication.name;
            }
            const substanceNames: string[] = [];
            if (medication?.substanceIds) {
                for (const substanceId of medication.substanceIds) {
                    const substance = this.props._dependencies.careReferenceDataStore.substances.get({ id: substanceId, validOn: row.recordedOn || LocalDate.today() });
                    if (!isNullOrUndefined(substance)) {
                        substanceNames.push(substance.name);
                    }
                }
            }
            return substanceNames.join(", ").toLowerCase();
        } else if (row instanceof EquipmentPractitionerRecommendation) {
            const equipment1 = !isNullOrUndefined(row.recommendedEquipment1.id) ? this.props._dependencies.careReferenceDataStore.medicationEquipmentClassifications.get(row.recommendedEquipment1) : null;
            const equipment2 = !isNullOrUndefined(row.recommendedEquipment2.id) ? this.props._dependencies.careReferenceDataStore.medicationEquipmentClassifications.get(row.recommendedEquipment2) : null;
            const equipmentNames: string[] = [];
            if (!isNullOrUndefined(equipment1)) { equipmentNames.push(equipment1.name); }
            if (!isNullOrUndefined(equipment2)) { equipmentNames.push(equipment2.name); }
            return equipmentNames.join(", ");
        }
        return null;
    }

    @State.bound
    private getSubsidyOptionId(row: any, key: React.ReactText, index: number, isUnderEditing: boolean) {
        if (row instanceof MedicationPractitionerRecommendation) {
            return { subsidyId: row.medicationSubsidyId, optionId: row.subsidyOptionId };
        } else if (row instanceof EquipmentPractitionerRecommendation) {
            return { equipmentSupportTypeId: row.equipmentSupportTypeId };
        }
        return null;
    }

    public render() {
        const resources = StaticHunSocialSecurityMedicationRequestResources.PractitionerRecommendationList;
        const headers = resources.Columns;
        const filterStore = this.props.filterStore;
        return (
            <>
                {!this.props._masterDetailState.isDetailOpen && this.props.showFilter && (<div className={Styles.searchContainer}>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <Ui.DateRangePicker
                                value={filterStore.dateRangeFilter}
                                onChange={filterStore.setDateRangeFilter}
                                label={resources.Labels.DateRange}
                                automationId="dateRangeFilterPicker"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item>
                            <Ui.CheckBox
                                value={filterStore.includeDeleted}
                                onChange={filterStore.setIncludeDeleted}
                                label={resources.Labels.IncludeDeleted}
                                verticalAlign="inlineInput"
                                automationId="includeDeletedCheckBox"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item>
                            <Ui.CheckBox
                                value={filterStore.includeExpired}
                                onChange={filterStore.setIncludeExpired}
                                label={resources.Labels.IncludeExpired}
                                verticalAlign="inlineInput"
                                automationId="includeExpiredCheckBox"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <OrganizationSelector
                                value={filterStore.pointOfCareIds}
                                onChange={filterStore.setPointOfCareIds}
                                pointOfCareMode={true}
                                automationId="organization"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </div>)}
                <Ui.DataGrid
                    dataSource={this.dataSource}
                    onSelectedRowChange={this.onRowSelected}
                    isSelectable
                    selectedRow={this.props._masterDetailState.selectedItem?.id?.value || null}
                    rowHeight={42}
                    selectOnRowClick
                    rowComparer="rowId"
                    rowId={this.selectId}
                    getRowIndicatorStyle={this.getClassNameByRowState}
                    actionsColumn
                    fixedHeight="100%"
                    changeOnMount
                    automationId="practitionerRecommendationListView_dataGrid">
                    <DataGridColumn
                        dataGetter={"statusColumn"}
                        onRenderCellValue={this.renderStatus}
                        isVisible={!this.props._masterDetailState.selectedItem}
                        width={130}
                        id="Status"
                        isOrderable
                        clientSideOrderableValueGetter={"statusColumn"}
                        header={headers.Status}
                    />
                    <DataGridColumn
                        dataGetter={"type"}
                        onRenderCellValue={this.renderType}
                        header={headers.RecommendationType}
                        width={100}
                        id={"Type"}
                        isOrderable
                    />
                    <DataGridDateColumn
                        dataGetter={"recordedOn"}
                        id={"RecordedOn"}
                        isOrderable
                        clientSideOrderableValueGetter={this.recordedOnOrderableValueGetter}
                        header={headers.RecordedOn}
                    />
                    <DataGridDateColumn
                        dataGetter={this.renderValidTo}
                        header={headers.ValidTo}
                        id={"ValidTo"}
                        isOrderable
                        clientSideOrderableValueGetter={this.validToOrderableValueGetter}
                        isVisible={!this.props._masterDetailState.selectedItem}
                        width={120}
                    />
                    <DataGridColumn
                        dataGetter={this.getMedicationOrEquipmentName}
                        id={"Name"}
                        header={headers.Name}
                        isOrderable
                        clientSideOrderableValueGetter={this.nameOrderableValueGetter}
                    />
                    <MedicationSubsidyClaimTypeColumn
                        dataGetter={"claimTypeId"}
                        header={headers.ClaimType}
                        isVisible={!this.props._masterDetailState.selectedItem}
                        id={"ClaimType"}
                        isOrderable
                        clientSideOrderableValueGetter={this.claimTypeOrderableValueGetter}
                        width={130}
                    />
                    <DataGridColumn
                        dataGetter={this.getSubsidyOptionId}
                        onRenderCellValue={this.renderMedicationSubsidyPercentage}
                        header={headers.SupportPercentage}
                        isVisible={!this.props._masterDetailState.selectedItem}
                        width={100}
                        id={"SubsidyPercentage"}
                        isOrderable
                        clientSideOrderableValueGetter={this.subsidyPercentageOrderableValueGetter}
                    />
                    <MedicationSubsidyOptionColumn
                        dataGetter={this.getSubsidyOptionId}
                        header={headers.SubsidyOption}
                        isVisible={!this.props._masterDetailState.selectedItem}
                        width={150}
                        id={"SubsidyOption"}
                        isOrderable
                        clientSideOrderableValueGetter={this.subsidyOptionOrderableValueGetter}
                    />
                    <PointOfCareColumn
                        dataGetter={"pointOfCareId"}
                        header={headers.PointOfCare}
                        isVisible={!this.props._masterDetailState.selectedItem}
                        id={"PointOfCare"}
                        isOrderable
                        clientSideOrderableValueGetter={this.pointOfCareOrderableValueGetter}
                    />
                </Ui.DataGrid>
            </>
        );
    }
}

export default connect(
    PractitionerRecommendationListView,
    new DependencyAdapter<IPractitionerRecommendationListViewProps, IPractitionerRecommendationListViewDependencies>(c => ({
        apiAdapter: c.resolve("PractitionerRecommendationApiAdapter"),
        referenceDataStore: c.resolve("MedicationRequestReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        dialogService: c.resolve("IDialogService"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        careActivityApiAdapter: c.resolve("CareActivityApiAdapter2")
    }))
);
