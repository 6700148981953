exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AppointmentSchedulePanelView_message_AAlZ6 {\n  margin: 10px 5px 10px 10px;\n  border-radius: 0px;\n  padding: 7px 15px;\n}\n.AppointmentSchedulePanelView_book-first-button_36zVL {\n  border-radius: 0px;\n}\n.AppointmentSchedulePanelView_dismiss-message_2uBlc {\n  margin-left: 15px;\n}\n", ""]);

// exports
exports.locals = {
	"message": "AppointmentSchedulePanelView_message_AAlZ6",
	"book-first-button": "AppointmentSchedulePanelView_book-first-button_36zVL",
	"bookFirstButton": "AppointmentSchedulePanelView_book-first-button_36zVL",
	"dismiss-message": "AppointmentSchedulePanelView_dismiss-message_2uBlc",
	"dismissMessage": "AppointmentSchedulePanelView_dismiss-message_2uBlc"
};