import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import RequestedServiceId from "@Primitives/RequestedServiceId.g";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import LateralityId from "@Primitives/LateralityId.g";
import moment from "moment";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import MedicalServiceCategoryId from "@Primitives/MedicalServiceCategoryId.g";
import IObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/IObservation";
import ObservationId from "@Primitives/ObservationId.g";
import IObservationDefinitionsForMedicalService from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServices/IObservationDefinitionsForMedicalService";
import IScalarObservationDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServices/IScalarObservationDefinition";
import ScalarObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/ScalarObservation";
import ITextualObservationDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServices/ITextualObservationDefinition";
import TextualObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/TextualObservation";
import ICategoryObservationDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServices/ICategoryObservationDefinition";
import CategoryObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/CategoryObservation";
import IStatusChangeReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/IStatusChangeReason";
import IRequestedServiceActionToExecute from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/IRequestedServiceActionToExecute";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import CareActivityId from "@Primitives/CareActivityId.g";
import Quantity from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/Quantity";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import PatientId from "@Primitives/PatientId.g";
import MedicalServicePriority from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalServices/Enum/MedicalServicePriority.g";
import RequestedServiceState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceState.g";
import RequestedServiceAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceAction.g";

export default class RequestedServiceStore extends EntityStoreBase<RequestedServiceId> {
    @State.observable.ref public id: RequestedServiceId;
    @State.observable.ref public requestedServiceIdentifier: string;
    @State.observable.ref public expectedCompletionTime: moment.Moment;
    @State.observable.ref public submitTime: moment.Moment;
    @State.observable.ref public priority: MedicalServicePriority;
    @State.observable.ref public medicalServiceVersionSelector?: IEntityVersionSelector<MedicalServiceId>;
    @State.observable.ref public lateralityId?: LateralityId = LateralityId.NotApplicable;
    @State.observable.ref public state: RequestedServiceState;
    @State.observable.ref public possibleActions: RequestedServiceAction[];
    @State.observable.ref public categoryId: MedicalServiceCategoryId;
    @State.observable.ref public attachedObservations: ObservationId[];
    @State.observable.ref public administrationAction: RequestedServiceAction = null;
    @State.observable.ref public administrationActionChangeReason: IStatusChangeReason = null;
    @State.observable public isCompleteDiagnosticReportAllowed: boolean;
    @State.observable public index: number;

    private _newObservations: IObservation[] = [];
    public observations: IObservation[] = [];

    public scalarObservationDefinitions: IScalarObservationDefinition[] = [];
    public scalarObservations: ScalarObservation[] = [];
    public textualObservationDefinitions: ITextualObservationDefinition[] = [];
    public textualObservations: TextualObservation[] = [];
    public categoryObservationDefinitions: ICategoryObservationDefinition[] = [];
    public categoryObservations: CategoryObservation[] = [];

    // UI only supports one observation for one service
    @State.computed
    public get scalarObservationDefinition(): IScalarObservationDefinition {
        return this.scalarObservationDefinitions && this.scalarObservationDefinitions[0];
    }

    @State.computed
    public get scalarObservation(): ScalarObservation {
        return this.scalarObservations && this.scalarObservations[0];
    }

    // UI only supports one observation for one service
    @State.computed
    public get textualObservationDefinition(): ITextualObservationDefinition {
        return this.textualObservationDefinitions && this.textualObservationDefinitions[0];
    }

    @State.computed
    public get textualObservation(): TextualObservation {
        return this.textualObservations && this.textualObservations[0];
    }

    // UI only supports one observation for one service
    @State.computed
    public get categoryObservationDefinition(): ICategoryObservationDefinition {
        return this.categoryObservationDefinitions && this.categoryObservationDefinitions[0];
    }

    @State.computed
    public get categoryObservation(): CategoryObservation {
        return this.categoryObservations && this.categoryObservations[0];
    }

    public get modifiedObservations(): IObservation[] {
        if (!this.observations) {
            return [];
        }
        return this.observations.filter(o => o.isDirty === true && !isNullOrUndefined(o.rowVersion));
    }

    public get newObservations(): IObservation[] {
        if (!this._newObservations) {
            return [];
        }
        return this._newObservations;
    }

    @State.action.bound
    public setAdministrationAction(action: RequestedServiceAction, reason: IStatusChangeReason) {
        this.administrationAction = action;
        this.administrationActionChangeReason = reason;
    }

    @State.bound
    public getMedicalServiceActions(): IRequestedServiceActionToExecute[] {
        return this.possibleActions.map(s => ({
            requestedServiceId: this.id,
            action: s,
            reason: this.administrationActionChangeReason,
        } as IRequestedServiceActionToExecute));
    }

    @State.bound
    public initializeObservations(patientId: PatientId, executingCareActivityId: CareActivityId, observations: IObservation[]) {
        if (this.state === RequestedServiceState.DeletedByExecutorSideAfterCompletion ||
            this.state === RequestedServiceState.DeletedByExecutorSideBeforeCompletion) {
            return;
        }

        this.attachedObservations.forEach(i => {
            let existingObservation = observations.find(o => ValueWrapper.equals(i, o.observationId));
            if (isNullOrUndefined(existingObservation)) {
                existingObservation = observations.find(o => isNullOrUndefined(o.observationId));
                if (!isNullOrUndefined(existingObservation)) {
                    existingObservation.observationId = i;
                }
            }

            this.setExistingObservation(existingObservation);
        });

        if (this.isCompleteDiagnosticReportAllowed) {
            this.createEmptyObservation(patientId, executingCareActivityId);
        }
    }

    @State.bound
    public initializeObservationDefinitions(observationDefinitionsForMedicalService: IObservationDefinitionsForMedicalService[]) {
        const observationDefinitionsForService = observationDefinitionsForMedicalService
            .find(def => EntityVersionSelector.areEquals(def.medicalService, this.medicalServiceVersionSelector)).observationDefinitions;

        const scalarObservationDefinition = observationDefinitionsForService.find(def =>
            def.type === "scalar") as IScalarObservationDefinition || null;
        if (!isNullOrUndefined(scalarObservationDefinition)) {
            this.scalarObservationDefinitions.push(scalarObservationDefinition);
        }
        const textualObservationDefinition = observationDefinitionsForService.find(def =>
            def.type === "textual") as ITextualObservationDefinition || null;
        if (!isNullOrUndefined(textualObservationDefinition)) {
            this.textualObservationDefinitions.push(textualObservationDefinition);
        }
        const categoryObservationDefinition = observationDefinitionsForService.find(def =>
            def.type === "category") as ICategoryObservationDefinition || null;
        if (!isNullOrUndefined(categoryObservationDefinition)) {
            this.categoryObservationDefinitions.push(categoryObservationDefinition);
        }
    }

    @State.action.bound
    public setExpectedCompletionTime(newValue: moment.Moment) {
        this.expectedCompletionTime = newValue;
    }

    @State.action.bound
    public setPriority(newValue: MedicalServicePriority) {
        this.priority = newValue;
    }

    @State.action.bound
    public setServiceId(newValue: IEntityVersionSelector<MedicalServiceId>) {
        this.medicalServiceVersionSelector = newValue;
    }

    @State.action.bound
    public setLateralityId(newValue: LateralityId) {
        this.lateralityId = newValue;
    }

    private setExistingObservation(observation: IObservation) {
        if (observation instanceof ScalarObservation) {
            if (this.scalarObservationDefinitions.some(i => ValueWrapper.equals(i.id, observation.observationDefinitionId))) {
                this.scalarObservations.push(observation);
            }
        } else if (observation instanceof TextualObservation) {
            if (this.textualObservationDefinitions.some(i => ValueWrapper.equals(i.id, observation.observationDefinitionId))) {
                this.textualObservations.push(observation);
            }
        } else if (observation instanceof CategoryObservation) {
            if (this.categoryObservationDefinitions.some(i => ValueWrapper.equals(i.id, observation.observationDefinitionId))) {
                this.categoryObservations.push(observation);
            }
        }

        this.observations.push(observation);
    }

    private createEmptyObservation(patientId: PatientId, careActivityId: CareActivityId) {
        this.createEmptyScalarObservations(patientId, careActivityId);
        this.createEmptyTextualObservation(patientId, careActivityId);
        this.createEmptyCategoryObservation(patientId, careActivityId);
    }

    private createEmptyScalarObservations(patientId: PatientId, careActivityId: CareActivityId) {
        this.scalarObservationDefinitions.forEach(s => {
            const newObservation = new ScalarObservation(
                null,
                s.id,
                null,
                patientId,
                careActivityId,
                "",
                new Quantity(null, s.units),
                null,
                null,
                null
            );

            this._newObservations.push(newObservation);
            this.scalarObservations.push(newObservation);
        });
    }

    private createEmptyTextualObservation(patientId: PatientId, careActivityId: CareActivityId) {
        this.textualObservationDefinitions.forEach(s => {
            const newObservation = new TextualObservation(
                null,
                s.id,
                null,
                patientId,
                careActivityId,
                null,
            );

            this._newObservations.push(newObservation);
            this.textualObservations.push(newObservation);
        });
    }

    private createEmptyCategoryObservation(patientId: PatientId, careActivityId: CareActivityId) {
        this.categoryObservationDefinitions.forEach(s => {
            const newObservation = new CategoryObservation(
                null,
                s.id,
                null,
                patientId,
                careActivityId,
                [],
            );

            this._newObservations.push(newObservation);
            this.categoryObservations.push(newObservation);
        });
    }
}
