import React from "react";
import { getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import ISelectBoxItem from "./ISelectBoxItem";
import Styles from "./SelectBox.less";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import * as Ui from "@CommonControls";
import FieldValidationResult from "@CommonControls/FieldValidationResult";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import { Tooltip } from "@Toolkit/ReactClient/Components/Tooltip";
import { iconVisualStyleType, iconNameType } from "@CommonControls/Icon";
import { getHashValue } from "@Toolkit/CommonWeb/EqualityHelper";
import { nullFunction, arrayIsNullOrEmpty, emptyArray, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import ISelectBoxGroup from "@CommonControls/SelectBox/ISelectBoxGroup";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ReadOnlyContextAdapter from "@Toolkit/ReactClient/Components/ReadOnlyContext/ReadOnlyContextAdapter";
import ValidationBoundaryAdapter from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryAdapter";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import EventHandler from "@Toolkit/ReactClient/Components/EventHandler/EventHandler";
import StaticToolkitResources from "@Toolkit/ReactClient/Services/Definition/LocalizationService/StaticToolkitResources";
import Menu from "@CommonControls/SelectBox/Menu";
import Option from "./Option";
import ISelectBoxSection from "@CommonControls/SelectBox/ISelectBoxSection";
import ISelectBoxSectionItem from "@CommonControls/SelectBox/ISelectBoxSectionItem";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import MenuList from "@CommonControls/SelectBox/MenuList";
import LoadingContextAdapter from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingContextAdapter";

export type inputChangeActionType = "set-value" | "input-change" | "input-blur" | "menu-close";

export interface ISelectBoxProps extends ISelectBoxBaseProps {
    getOptionValue?: (value: any) => string;
    getOptionText?: (value: any) => string;
    items?: ISelectBoxItem[] | IObservableArray<ISelectBoxItem> | ISelectBoxGroup[] | IObservableArray<ISelectBoxGroup>;
    loading?: boolean;
    inputValue?: string;
    onInputChange?: (newValue: string, action?: inputChangeActionType) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    isOptionSelected?: (value: any, values: any[]) => boolean;
    customValueRenderer?: (props: any, isSelectedValue: boolean) => React.ReactNode;
    customSelectedValueRenderer?: (props: any) => React.ReactNode;
    hideDropdownIndicator?: boolean;
    additionalIndicatorRenderer?: () => React.ReactNode;
    menuPlacement?: "auto" | "bottom" | "top";
    tabSelectsValue?: boolean;
    onBlur?: () => void;
    onRenderMenu?: (props: any) => React.ReactElement;
    dropdownIconName?: iconNameType | "none";
    children?: React.ReactNode;
    isCreatable?: boolean;
    hideSelectedOptions?: boolean;
    onAddNewOption?: (text: string) => void;
    showMultipleValidationProblems?: boolean;
    onFocus?: () => void;

    menuSections?: Array<ISelectBoxSection<any>>;
    equalityComparer?: (one: any, other: any) => boolean;
    getOptionIconName?: (data: any) => iconNameType;
    onOptionIconClick?: (data: ISelectBoxItem<any>) => void;
    menuSectionMaxCount?: number;

    onShowAllItemClick?: () => void;
    onNewItemClick?: () => void;
    newItemPermissionIdentifier?: string;
    newItemText?: string;

    autoSelectSingleItem?: boolean;
}

interface ISelectBoxCoreProps extends ISelectBoxProps {
    validationContext: IValidationBoundaryStore;
    _isLoading: boolean;
}

const commonMenuMaxWidth = "300px";
export {
    commonMenuMaxWidth
};

@State.observer
export class SelectBoxCore extends React.Component<ISelectBoxCoreProps> {

    private selectElement: any;

    private get isGroupedValue() {
        return this.props.items && "options" in this.props.items[0];
    }

    public static defaultProps: Partial<ISelectBoxProps> = {
        closeOnSelect: true,
        disabled: false,
        isReadOnly: false,
        clearable: true,
        getOptionText: SelectBoxCore.genericGetOptionText,
        getOptionValue: SelectBoxCore.genericGetOptionValue,
        valueOnClear: null,
        size: "standard",
        required: false,
        placeholder: "",
        hideDropdownIndicator: false,
        menuPlacement: "auto",
        tabSelectsValue: false,
        onBlur: nullFunction,
        orderItems: true,
        dropdownIconName: "chevronDown",
        isCreatable: false,
        maxMenuHeight: 900,
        equalityComparer: (one, other) => wrappedValuesAreEquals(one?.id, other?.id)
    };

    private static genericGetOptionText(option: ISelectBoxItem | any) {
        if (option.__isNew__) {
            return `${StaticToolkitResources.selectBox.newItem} "${option.value}"`;
        }
        return option.text;
    }

    private static genericGetOptionValue(option: ISelectBoxItem) {
        return getHashValue(option);
    }

    private getContainerClassName() {

        const classes = new CompositeClassName(Styles.selectBoxContainer);

        classes.addIf(this.props.multiSelect, Styles.multiContainer);
        classes.addIf(!this.props.multiSelect, Styles.singleContainer);

        const validationProblemSeverity = this.validationResult && this.validationResult.severity;

        classes.addIf(!this.props.disabled && !this.validationResult, Styles.normalEnabled);

        classes.addIf(!this.props.isReadOnly && this.isRequired, Styles.required);

        if (!this.props.disabled && validationProblemSeverity !== null && validationProblemSeverity !== undefined) {
            classes.addIf(validationProblemSeverity === "warning", Styles.warning);
            classes.addIf(validationProblemSeverity === "error", Styles.error);
        }
        classes.addIf(this.props.visualMode === "dark", Styles.darkMode);
        classes.addIf(this.props.disabled, Styles.disabled);
        classes.addIf(this.props.size === "compact", Styles.compact);
        classes.addIf(this.props.visualStyle === "slate", Styles.slate);
        classes.addIf(this.props.isReadOnly, Styles.readonly);
        classes.add(this.props.className);

        return classes.classNames;
    }

    @State.computed
    private get validationProblems() {
        if (this.props.validationContext) {
            const problems = this.props.validationContext.getValidationProblems(this.props.propertyIdentifier);
            return arrayIsNullOrEmpty(problems) ? emptyArray : problems;
        }
        return emptyArray;
    }

    @State.computed
    private get validationResult() {
        return this.validationProblems.length === 0 ? null : this.validationProblems[0];
    }

    @State.observable.ref private _searchText = "";
    @State.observable.ref private _isMultiselectMenuOpen = false;

    @State.computed
    private get searchText() {
        return this.props.inputValue ?? this._searchText;
    }

    @State.computed
    private get menuIsOpen() {
        if (this.props._isLoading) {
            return false;
        }
        if (this.props.isReadOnly) {
            return false;
        }

        if (this.props.menuIsOpen === false) {
            return false;
        }

        if (this._isMultiselectMenuOpen === true) {
            return true;
        }

        return this.props.menuIsOpen;
    }

    @State.computed
    private get isMultipleSelected() {
        return this.props.multiSelect &&
            !isNullOrUndefined(this.props.value) &&
            Array.isArray(this.props.value) &&
            this.props.value.length > 1;
    }

    private getIconVisualStyle(): iconVisualStyleType {
        const validationProblemSeverity = this.validationResult && this.validationResult.severity;

        if (this.props.visualStyle === "slate") {
            return "slate";
        }

        if (validationProblemSeverity !== null && validationProblemSeverity !== undefined && !this.props.disabled) {
            if (validationProblemSeverity === "warning") {
                return "warning";
            }
            if (validationProblemSeverity === "error") {
                return "error";
            }

        }

        if (this.props.visualMode === "dark") {
            return "dark";
        }

        return "secondary";
    }

    private clearHandler() {
        if (this.props.onChange) {
            this.props.onChange(this.props.valueOnClear);
        }
    }

    @State.bound
    private singleValueChangeHandler(newValue: { label: string, value: any }, actionType: { action: string }) {
        switch (actionType.action) {
            case "select-option":
                if (this.props.onChange) {
                    this.props.onChange(newValue.value);
                }
                break;
            case "clear":
                this.clearHandler();
                break;
        }

    }

    @State.bound
    private multiValueChangeHandler(newValue: Array<{ label: string, value: any }>, actionType: { action: string }) {
        switch (actionType.action) {
            case "remove-value":
            case "select-option":
            case "deselect-option":
                if (arrayIsNullOrEmpty(newValue)) {
                    this.clearHandler();
                } else {
                    if (this.props.onChange) {
                        this.props.onChange(newValue.map((i) => i.value));
                    }
                }
                break;
            case "clear":
                this.clearHandler();
                break;
        }

    }

    @State.computed get options() {
        if (!this.props.items || this.props.items.length === 0) {
            return [];
        }

        if (this.isGroupedValue) {
            const groupedItems = this.props.items.slice() as ISelectBoxGroup[];
            if (this.props.orderItems) {
                return groupedItems.map((group: any) => {
                    return {
                        label: group.label,
                        options: _.sortBy(group.options, (i: ISelectBoxItem<any>) => _.deburr(i.text))
                    } as ISelectBoxGroup as any;
                });
            }

            return groupedItems;
        } else {
            return this.props.orderItems ? _.sortBy(this.props.items.slice(), (i: any) => _.deburr(i.text?.toLowerCase())) : this.props.items.slice();
        }
    }

    private getSingleValueOption(value: any) {
        if (this.options.length === 0) {
            return null;
        }

        const valueKey = this.props.getOptionValue({ value: value });

        if (this.isGroupedValue) {
            return _.flatten(this.options.map((o: ISelectBoxGroup) => o.options)).find(i => {
                if (this.props.isOptionSelected) {
                    return this.props.isOptionSelected(i, null);
                } else {
                    return this.props.getOptionValue(i) === valueKey;
                }
            });
        }

        const option =
            this.props.isOptionSelected ?
                this.options.find((i) => this.props.isOptionSelected(i, null)) :
                this.options.find((i) => this.props.getOptionValue(i) === valueKey);
        return option ? option : null;
    }

    private getMultiValueOptions(value: any[]) {
        if (this.options.length === 0 || !value) {
            return null;
        }

        return value.map((v) => this.getSingleValueOption(v));
    }

    @State.bound
    private renderDropdownIndicator(props: any) {
        if (this.props.dropdownIconName === "none") {
            return null;
        }

        return (
            <components.DropdownIndicator {...props}>
                <Ui.Icon
                    size="compact"
                    iconName={this.props.dropdownIconName}
                    visualStyle={this.getIconVisualStyle()}
                    disabled={this.props.disabled}
                    style={{ top: 5 }}
                    onClick={nullFunction}
                />
            </components.DropdownIndicator>
        );
    }

    @State.bound
    private renderClearIndicator(props: any) {

        return (
            <components.ClearIndicator {...props}>
                <Ui.Icon
                    size="compact"
                    iconName="remove"
                    visualStyle={this.getIconVisualStyle()}
                    disabled={this.props.disabled}
                    style={{ top: 4, right: -2 }}
                    onClick={nullFunction}
                    automationId={this.props.automationId + "__clearButton"} />
            </components.ClearIndicator>
        );
    }

    @State.bound
    private renderSingleValue(props: any) {
        const innerProps = {
            ...props.innerProps,
            "data-automation-id": "__option__" + this.props.getOptionValue(this.props.value)
        };
        if (this.props.customValueRenderer || this.props.customSelectedValueRenderer) {
            return (
                <components.SingleValue {...props} innerProps={innerProps}>
                    {this.props.customSelectedValueRenderer ? this.props.customSelectedValueRenderer(props) : this.props.customValueRenderer(props, true)}
                </components.SingleValue>
            );
        }
        return <components.SingleValue {...props} innerProps={innerProps} />;
    }

    @State.bound
    private renderMultiValue(props: any) {
        const innerProps = {
            ...props.innerProps,
            "data-automation-id": `${this.props.automationId}__selectedOption__${this.props.getOptionValue(props.data.value.id)}`
        };

        return <components.MultiValue {...props} innerProps={innerProps} />;
    }

    @State.bound
    private renderOption(props: any) {
        const innerProps = { ...props.innerProps, "data-automation-id": "__option__" + props.value };
        const iconName = this.props.getOptionIconName && this.props.getOptionIconName(props.data.value);
        if (this.props.customValueRenderer) {
            return (
                <Option {...props}
                    innerProps={innerProps}
                    iconName={iconName}
                    onIconClick={this.props.onOptionIconClick}>
                    {this.props.customValueRenderer(props, false)}
                </Option>
            );
        }
        return (
            <Option {...props}
                innerProps={innerProps}
                iconName={iconName}
                onIconClick={this.props.onOptionIconClick} />
        );
    }

    @State.bound
    private renderIndicatorContainer(props: any) {
        return (
            <components.IndicatorsContainer {...props} className={this.props.hoverOnlyIndicatorIcons && Styles.hideIndicators}>
                {props.children} {this.props.additionalIndicatorRenderer ? this.props.additionalIndicatorRenderer() : this.props.children}
            </components.IndicatorsContainer>
        );
    }

    private renderNothing(): React.ReactNode {
        return null;
    }

    private renderValidationResults(): React.ReactNode {
        if (!this.props.disabled) {
            return (
                <FieldValidationResult problems={this.validationProblems}
                    showMultipleProblems={this.props.showMultipleValidationProblems} />
            );
        }
        return null;
    }

    @State.bound
    private keyDownHandler(e: React.KeyboardEvent<HTMLInputElement>) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    }

    public componentDidMount() {
        this.setAutomationId();

        if (this.props.autoSelectSingleItem) {
            this.autoSelectSingleItem();
        }
    }

    public componentDidUpdate(prevProps: ISelectBoxProps) {
        this.setAutomationId();

        if (!_.isEqual(this.props.value, prevProps.value)) {
            this.validate();
        }

        if (!_.isEqual(this.props.items, prevProps.items) && this.props.autoSelectSingleItem) {
            this.autoSelectSingleItem();
        }
    }

    private autoSelectSingleItem() {
        if (this.props.items?.length === 1 && (isNullOrUndefined(this.props.value) || this.props.getOptionValue(this.props.items[0]) !== this.props.getOptionValue(this.props.value))) {
            this.props.onChange?.((this.props.items[0] as ISelectBoxItem)?.value);
        }
    }

    @State.bound
    private setAutomationId() {
        if (this.props.automationId && this.selectElement) {
            const inputElement = ((this.selectElement.select.select ? this.selectElement.select.select.inputRef : this.selectElement.select.inputRef) as HTMLInputElement);
            inputElement.setAttribute("data-automation-id", `${this.props.automationId}`);
        }
    }

    private setSelectReference = (ref: any) => {
        if (ref) {
            this.selectElement = ref;
        }
    };

    @State.action.bound
    private inputChange(value: string, e?: { action: "set-value" | "input-change" | "input-blur" }) {
        this._searchText = value;
        if (this.props.onInputChange) {
            this.props.onInputChange(value, e?.action ?? "input-change");
        }
    }

    @State.bound
    private getNoOptionsMessage() {
        return this.props.noOptionsMessage;
    }

    @State.bound
    public isValidNewOption(inputValue: string, selectValue: ISelectBoxItem[], selectOptions: ISelectBoxItem[]) {
        const isValid = !inputValue ||
            selectValue.some(x => this.compareOption(inputValue, x)) ||
            selectOptions.some(x => this.compareOption(inputValue, x));

        return !isValid;
    }

    @State.bound
    public compareOption(inputValue: string, option: ISelectBoxItem) {
        return option && option.text.toLowerCase() === inputValue.toLowerCase();

    }

    private static nameIndex = 0;

    private renderInput(props: any) {
        return (
            <components.Input name={`select_${(SelectBoxCore.nameIndex++)}`} {...props} autoComplete="off" />
        );
    }

    @State.bound
    private renderMenu(menuProps: any) {
        const maxWidthStyles = this.props.maxMenuWidth && {
            style: {
                width: "max-content",
                maxWidth: this.props.maxMenuWidth
            }
        } || {
            style: {
                minWidth: "fit-content"
            }
        };

        const props = {
            ...menuProps,
            innerProps: {
                ...menuProps.innerProps,
                ...maxWidthStyles
            }
        };
        return (
            <Menu
                {...props}
            />
        );
    }

    @State.action.bound
    private renderMenuList(menuListProps: any): any {

        const props = {
            ...menuListProps,
            innerProps: {
                ...menuListProps.innerProps,
            }
        };

        if (this.props.visualMode === "dark") {
            props.className += " __dark-mode";
        }

        return (
            <MenuList
                {...props}
                searchText={this.searchText}
                onSearchTextChange={this.inputChange}
                onSearchFocus={this.focusMultiselectSearch}
                onSearchBlur={this.blurMultiselectSearch}

                menuSections={this.props.menuSections}
                renderSectionItem={this.renderOption}
                onItemSelected={this.onSelectedMenuSectionItem}
                onShowAllItemClick={this.props.onShowAllItemClick}
                onNewItemClick={this.props.onNewItemClick}
                newItemText={this.props.newItemText}
                newItemPermissionIdentifier={this.props.newItemPermissionIdentifier}
                menuSectionMaxCount={this.props.menuSectionMaxCount}
            />
        );
    }

    @State.action.bound
    private onSelectedMenuSectionItem(item: ISelectBoxSectionItem<any>) {
        if (this.props.multiSelect) {
            if (item.value.length) {
                const result = item.isSelected
                    ? this.props.value.filter((i: any) => !item.value.some((j: any) => this.props.equalityComparer(i, j)))
                    : _.unionWith(this.props.value || [], item.value, this.props.equalityComparer);
                this.props.onChange(result);
            } else {
                const result = item.isSelected
                    ? this.props.value.filter((i: any) => !this.props.equalityComparer(i, item.value))
                    : (this.props.value || []).concat([item.value]);
                this.props.onChange(result);
            }
        } else {
            this.props.onChange(item.value);
        }
        this.closeMenu();
    }

    @State.action.bound
    private focusMultiselectSearch() {
        this._isMultiselectMenuOpen = true;
    }

    @State.action.bound
    private blurMultiselectSearch() {
        this._isMultiselectMenuOpen = false;
        this.closeMenu();
    }

    @State.action.bound
    private openMenu() {
        if (!this.menuIsOpen) {
            if (this.props.multiSelect) {
                this._isMultiselectMenuOpen = true;
            }

            this.props.onMenuOpen?.();
        }
    }

    @State.action.bound
    private closeMenu() {
        if (this.props.multiSelect) {
            if (!this._isMultiselectMenuOpen) {
                this.props.onMenuClose?.();
            }
        } else {
            this.props.onMenuClose?.();
        }
    }

    @State.bound
    private renderValueContainer(props: any) {
        return (
            <>
                <components.ValueContainer {...props}>
                    {props.children}
                </components.ValueContainer>
                {this.isMultipleSelected && (
                    <div className={Styles.multipleValuesIndicatorContainer}>
                        <svg width={8} height={8}>
                            <polygon points="8,0 8,8 0,8" style={{ fill: "black", strokeWidth: 1 }} />
                        </svg>
                    </div>
                )}
            </>
        );
    }

    @State.bound
    private filterOption(option: any, input: string) {
        if (input) {
            return option.label?.toLowerCase().includes(input?.toLowerCase());
        }
        return true;
    }

    public render() {
        const htmlProps = getStandardHtmlProps(this.props);
        const containerClassName = this.getContainerClassName();

        const readOnly = this.props.isReadOnly;

        const controlComponents = {
            DropdownIndicator: (
                readOnly === false &&
                this.props.hideDropdownIndicator === false &&
                (!this.props.hoverOnlyIndicatorIcons || this.props.value === null || this.props.value === undefined || !this.props.clearable)
            ) ?
                this.renderDropdownIndicator :
                this.renderNothing,
            ClearIndicator: this.props.value !== null && this.props.value !== undefined ?
                this.renderClearIndicator :
                this.renderNothing,
            IndicatorSeparator: this.renderNothing,
            Option: this.renderOption,
            Input: this.renderInput,
            Menu: this.renderMenu,
            ValueContainer: this.renderValueContainer,
            MenuList: this.renderMenuList
        };

        if (this.props.onRenderMenu) {
            controlComponents["Menu"] = this.props.onRenderMenu;
        }

        // if (this.props.customValueRenderer) {
        controlComponents["SingleValue"] = this.renderSingleValue;
        controlComponents["MultiValue"] = this.renderMultiValue;
        // }

        if (this.props.additionalIndicatorRenderer || this.props.children || this.props.hoverOnlyIndicatorIcons) {
            controlComponents["IndicatorsContainer"] = this.renderIndicatorContainer;
        }

        const selectProps = {
            ref: this.setSelectReference,
            components: controlComponents,
            options: this.options,
            classNamePrefix: " ",
            instanceId: htmlProps.id,
            isClearable: readOnly ? false : this.props.clearable,
            closeOnSelect: this.props.closeOnSelect,
            isDisabled: this.props.disabled || readOnly,
            isLoading: this.props.loading,
            isMulti: this.props.multiSelect,
            isSearchable: readOnly ? false : (this.props.multiSelect ? false : this.props.searchable),
            menuIsOpen: this.menuIsOpen,
            onMenuOpen: this.openMenu,
            onMenuClose: this.closeMenu,
            onChange: readOnly ? nullFunction : (this.props.multiSelect ? this.multiValueChangeHandler : this.singleValueChangeHandler),
            getOptionValue: this.props.getOptionValue,
            getOptionLabel: this.props.getOptionText,
            value: this.props.multiSelect ? this.getMultiValueOptions(this.props.value) : this.getSingleValueOption(this.props.value),
            placeholder: this.props.placeholder,
            onKeyDown: this.keyDownHandler,
            isOptionSelected: this.props.isOptionSelected,
            inputValue: this.searchText,
            onInputChange: this.inputChange,
            menuPlacement: this.props.menuPlacement,
            menuPortalTarget: document.getElementById("__select-box-portal"),
            tabSelectsValue: this.props.tabSelectsValue,
            onFocus: this.props.onFocus,
            onBlur: this.lostFocus,
            styles: { menuPortal: (base: any) => ({ ...base, zIndex: 1090, direction: this.props.expandMenuToLeft && "rtl" }) },
            required: this.props.required,
            noOptionsMessage: this.getNoOptionsMessage,
            maxMenuHeight: this.props.maxMenuHeight,
            hideSelectedOptions: !isNullOrUndefined(this.props.hideSelectedOptions) ? this.props.hideSelectedOptions : !this.props.multiSelect,
            filterOption: this.filterOption,
            ...htmlProps
        };

        const labelClasses = new CompositeClassName(Styles.label);
        labelClasses.addIf(this.props.visualMode === "dark", Styles.darkLabel);
        return (
            <Tooltip
                content={this.props.tooltipContent}
                contentAlignment={this.props.tooltipTextAlign}
                placement={this.props.tooltipPosition}
                >
                <div className={containerClassName}
                    data-automation-id={`${this.props.automationId}__container`}
                >
                    {this.props.label && <label htmlFor={this.props.automationId} className={labelClasses.classNames}>{this.props.label}{this.isRequired &&
                        <span className={Styles.requiredStar} />}</label>} {this.props.infoLabel &&
                            <Ui.InfoButton tooltipContent={this.props.infoLabel} />}
                    {!this.props.isCreatable ?
                        <Select {...selectProps} /> :
                        (
                            <CreatableSelect
                                {...selectProps}
                                onCreateOption={this.props.onAddNewOption}
                                isValidNewOption={this.isValidNewOption}
                            />
                        )}
                    {this.renderValidationResults()}
                    <EventHandler event={this.props.validationContext?.validateAllEvent} onFired={this.validate} />
                </div>
            </Tooltip>
        );
    }

    @State.bound
    private lostFocus() {
        this.props.onBlur();
    }

    @State.bound
    private validate() {
        if (this.props.validationContext) {
            this.props.validationContext.changed(this.props.propertyIdentifier, this.props.value);
        }
    }

    private get isRequired() {
        return !!(this.props.required || this.props.validationContext && this.props.validationContext.isRequired(this.props.propertyIdentifier));
    }
}


const SelectBox = connect(
    SelectBoxCore,
    new ReadOnlyContextAdapter<ISelectBoxCoreProps>((props, isReadOnly) => ({ isReadOnly: props.isReadOnly || isReadOnly })),
    new ValidationBoundaryAdapter<ISelectBoxCoreProps>((props, validationStore) => ({ validationContext: validationStore })),
    new LoadingContextAdapter<ISelectBoxCoreProps>()
);

export default SelectBox;
