import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ShowMedicationsScreenAction from "@HunEHealthInfrastructurePlugin/Packages/MedicationRequest/FrontendActions/ShowMedicationsScreenAction.g";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import NavMenuActionItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuActionItem";

export function getNavigationMenuItems(): INavMenuItem[] {
    const leftTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const rightTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const childMenuItems: INavMenuItem[] = [
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "Medications",
            "Settings",
            new ShowMedicationsScreenAction(),
            "ViewMedications",
            {
                automationId: "medications",
                text: StaticCareResources.ReferenceData.MedicationListPanel.Title,
                order: 9
            }
        )
    ];

    return [...leftTopLevelMenuItems, ...rightTopLevelMenuItems , ...childMenuItems];
}