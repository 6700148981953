import React, {useCallback} from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import PropertyBase from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyBase";
import IPropertyValueVersion from "@HisPlatform/Model/DomainModel/Common/IPropertyValueVersion";
import PropertyValueVersion from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyValueVersion";
import PropertyVersionValueEditor from "@HisPlatform/BoundedContexts/Organization/Components/Panels/PropertyGroupsPanel/PropertyVesrionsModal/PropertyVersionValueEditor";
import StaticOrganizationResources from "@HisPlatform/BoundedContexts/Organization/StaticResources/StaticOrganizationResources";
import { DynamicPropertyInputType } from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";

interface IPropertyVersionColumnProps {
    property: PropertyBase;
}


const PropertyVersionColumn: React.FC<IPropertyVersionColumnProps> = props => {
    const valueRenderer = useCallback((_: any, row: IPropertyValueVersion<any>) => {
            return <PropertyVersionValueEditor version={row} property={props.property}/>;
    }, []);

    const hintRenderer = useCallback((value: any, row: PropertyValueVersion<any>) => {
        if (props.property.inputType === DynamicPropertyInputType.File) {
            return StaticOrganizationResources.PropertyGroupsPanel.FileInputTypeValue;
        }

        return `${value}`;
    }, []);

    return (
        <DataGridColumn onRenderCellValue={valueRenderer}
                        onRenderHintValue={hintRenderer}
                        header={StaticOrganizationResources.PropertyVersionModal.Value}
                        automationId="Value"
                        dataGetter={"value"}
                        dataSetter={"setValue"}
                        isObserver
        />);
};
export default PropertyVersionColumn;
