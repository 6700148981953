exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Layout_single-layout_2lmYz {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  padding: 8px;\n  overflow: hidden;\n}\n.Layout_single-layout_2lmYz .Layout_page-box-container_IH5vY {\n  flex-basis: 1200px;\n  max-width: 1200px;\n}\n.Layout_master-detail__expand-button_36Cct {\n  position: absolute;\n  top: 4px;\n  right: 4px;\n  z-index: 200;\n}\n", ""]);

// exports
exports.locals = {
	"single-layout": "Layout_single-layout_2lmYz",
	"singleLayout": "Layout_single-layout_2lmYz",
	"page-box-container": "Layout_page-box-container_IH5vY",
	"pageBoxContainer": "Layout_page-box-container_IH5vY",
	"master-detail__expand-button": "Layout_master-detail__expand-button_36Cct",
	"masterDetailExpandButton": "Layout_master-detail__expand-button_36Cct"
};