import React from "react";
import IPatientsCareActivityListWarningExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientsCareActivityListWarningExtensionPointProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiPatientsCareActivitiesListFilter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiPatientsCareActivitiesListFilter/EhiPatientsCareActivitiesListFilter";

@State.observer
export default class PatientsCareActivityListExtraFilterExtension extends React.Component<IPatientsCareActivityListWarningExtensionPointProps> {

    public render() {
        return (
            <>
                <EhiPatientsCareActivitiesListFilter
                    filterStore={this.props.filterStore}
                    onQueryAsync={this.props.onQueryAsync}
                 />
            </>
        );
    }
}