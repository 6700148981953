import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiStructuredDocumentContactTypeSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiStructuredDocumentContactTypeSelectBox/EhiStructuredDocumentContactTypeSelectBox";
import EhiStructuredDocumentContactStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiStructuredDocumentContactStore";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";

interface IContactViewProps {
    contactStore: EhiStructuredDocumentContactStore;
    index: number;
    contactPerson: "practitioner" | "patient";
}


const ContactView: React.FC<IContactViewProps> = props => {

    const renderValue = () => {
        if (props.contactStore.isPhone) {
            return (
                <Ui.PhoneTextBox
                    label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.PhoneNumber}
                    value={props.contactStore.value}
                    onChange={props.contactStore.setValue}
                    propertyIdentifier="Value"
                    automationId={`${props.contactPerson}PhoneNumber${props.index}`}
                />
            );
        }

        return (
            <Ui.TextBox
                label={props.contactStore.isEmail ? StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.Email : StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.Contact}
                value={props.contactStore.value}
                onChange={props.contactStore.setValue}
                propertyIdentifier="Value"
                automationId={props.contactStore.isEmail ? `${props.contactPerson}Email${props.index}` : `${props.contactPerson}Other${props.index}`}
            />
        );
    };

    return (
        <>
            <Ui.Flex>
                <Ui.Flex.Item xs={6}>
                    <EhiStructuredDocumentContactTypeSelectBox
                        label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.ContactType}
                        value={props.contactStore.contactTypeId}
                        onChange={props.contactStore.setContactTypeId}
                        propertyIdentifier="EhiStructuredDocumentContactTypeId"
                        automationId={`${props.contactPerson}ContactTypeId${props.index}`}
                        clearable={false}
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={6}>
                    {renderValue()}
                </Ui.Flex.Item>
            </Ui.Flex>
        </>
    );
};

export default State.observer(ContactView);
