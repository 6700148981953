import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IServiceRequestAppointmentWarningDialogParams } from "./ServiceRequestAppointmentWarningDialogParams";
import React from "react";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ServiceRequestManagementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestManagementApiAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ServiceRequestAppointmentWarningDialogView from "./ServiceRequestAppointmentWarningDialogView";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";

interface IServiceRequestAppointmentWarningDialogDependencies {
    serviceRequestManagementApiAdapter: ServiceRequestManagementApiAdapter;
}

interface IServiceRequestAppointmentWarningDialogProps extends IModalComponentParams, IServiceRequestAppointmentWarningDialogParams {
    _dependencies?: IServiceRequestAppointmentWarningDialogDependencies;
}

@State.observer
class ServiceRequestAppointmentWarningDialog extends React.Component<IServiceRequestAppointmentWarningDialogProps> {
    @State.observable private serviceRequestIdentifier: string;

    public componentDidMount() {
        dispatchAsyncErrors(this.getServiceRequestIdentifierAsync(), this);
    }

    @State.action.bound
    private async getServiceRequestIdentifierAsync() {
        const patientServiceRequests = await this.props._dependencies.serviceRequestManagementApiAdapter.getServiceRequestsForPatientAsync(this.props.patientId, null);
        const serviceRequest = patientServiceRequests.items.find(i => i.id.value === this.props.serviceRequestId.id.value);
    
        State.runInAction(() => {
            this.serviceRequestIdentifier = serviceRequest.serviceRequestIdentifier;
        });
    }

    @State.action.bound
    private close() {
        this.props.onClose({
            close: true
        });
    }

    public render() {
        return (
            <ServiceRequestAppointmentWarningDialogView
                onClose={this.close}
                identifier={this.serviceRequestIdentifier}
            />
        );
    }
}

export default connect(
    ServiceRequestAppointmentWarningDialog,
    new DependencyAdapter<IServiceRequestAppointmentWarningDialogProps, IServiceRequestAppointmentWarningDialogDependencies>(c => ({
        serviceRequestManagementApiAdapter: c.resolve("ServiceRequestManagementApiAdapter")
    }))
);
