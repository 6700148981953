exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WorklistPanel_full-height_1U-t0 {\n  height: 100%;\n}\n.WorklistPanel_care-activity-state-admitted_YF_eA {\n  background-color: #ddc00d;\n}\n.WorklistPanel_care-activity-state-waiting_2x0A9 {\n  background-color: #fe8443;\n}\n.WorklistPanel_care-activity-state-admission-deleted_-v_2E {\n  background-color: #e8e8e8;\n}\n.WorklistPanel_care-activity-state-deleted_psjLM {\n  background-color: #8695a7;\n}\n.WorklistPanel_care-activity-state-did-not-attend_j5me4 {\n  background-color: #B3B3B3;\n}\n.WorklistPanel_care-activity-state-under-care_3B8hy {\n  background-color: #21c313;\n}\n.WorklistPanel_care-activity-state-suspended_3ed0d {\n  background-color: #ce5374;\n}\n.WorklistPanel_care-activity-state-discharged_1n41O {\n  background-color: #82d4ed;\n}\n.WorklistPanel_care-activity-state-closed_3fE4u {\n  background-color: #0082aa;\n}\n.WorklistPanel_service-requset-state-under-recording_1t8DM {\n  background-color: #E8E8E8;\n}\n.WorklistPanel_service-requset-state-submitted_26deZ {\n  background-color: #E8E8E8;\n}\n.WorklistPanel_service-requset-state-registered-as-received_J_3_2 {\n  background-color: #82d4ed;\n}\n.WorklistPanel_service-requset-state-activity-completed_kGHHG {\n  background-color: #ddc00d;\n}\n.WorklistPanel_service-requset-state-non-validated-completed_sBsfK {\n  background-color: #fe8443;\n}\n.WorklistPanel_service-requset-state-validated-completed_2faBp {\n  background-color: #21c313;\n}\n.WorklistPanel_service-requset-state-deleted_1m23F {\n  background-color: #ce5374;\n}\n", ""]);

// exports
exports.locals = {
	"full-height": "WorklistPanel_full-height_1U-t0",
	"fullHeight": "WorklistPanel_full-height_1U-t0",
	"care-activity-state-admitted": "WorklistPanel_care-activity-state-admitted_YF_eA",
	"careActivityStateAdmitted": "WorklistPanel_care-activity-state-admitted_YF_eA",
	"care-activity-state-waiting": "WorklistPanel_care-activity-state-waiting_2x0A9",
	"careActivityStateWaiting": "WorklistPanel_care-activity-state-waiting_2x0A9",
	"care-activity-state-admission-deleted": "WorklistPanel_care-activity-state-admission-deleted_-v_2E",
	"careActivityStateAdmissionDeleted": "WorklistPanel_care-activity-state-admission-deleted_-v_2E",
	"care-activity-state-deleted": "WorklistPanel_care-activity-state-deleted_psjLM",
	"careActivityStateDeleted": "WorklistPanel_care-activity-state-deleted_psjLM",
	"care-activity-state-did-not-attend": "WorklistPanel_care-activity-state-did-not-attend_j5me4",
	"careActivityStateDidNotAttend": "WorklistPanel_care-activity-state-did-not-attend_j5me4",
	"care-activity-state-under-care": "WorklistPanel_care-activity-state-under-care_3B8hy",
	"careActivityStateUnderCare": "WorklistPanel_care-activity-state-under-care_3B8hy",
	"care-activity-state-suspended": "WorklistPanel_care-activity-state-suspended_3ed0d",
	"careActivityStateSuspended": "WorklistPanel_care-activity-state-suspended_3ed0d",
	"care-activity-state-discharged": "WorklistPanel_care-activity-state-discharged_1n41O",
	"careActivityStateDischarged": "WorklistPanel_care-activity-state-discharged_1n41O",
	"care-activity-state-closed": "WorklistPanel_care-activity-state-closed_3fE4u",
	"careActivityStateClosed": "WorklistPanel_care-activity-state-closed_3fE4u",
	"service-requset-state-under-recording": "WorklistPanel_service-requset-state-under-recording_1t8DM",
	"serviceRequsetStateUnderRecording": "WorklistPanel_service-requset-state-under-recording_1t8DM",
	"service-requset-state-submitted": "WorklistPanel_service-requset-state-submitted_26deZ",
	"serviceRequsetStateSubmitted": "WorklistPanel_service-requset-state-submitted_26deZ",
	"service-requset-state-registered-as-received": "WorklistPanel_service-requset-state-registered-as-received_J_3_2",
	"serviceRequsetStateRegisteredAsReceived": "WorklistPanel_service-requset-state-registered-as-received_J_3_2",
	"service-requset-state-activity-completed": "WorklistPanel_service-requset-state-activity-completed_kGHHG",
	"serviceRequsetStateActivityCompleted": "WorklistPanel_service-requset-state-activity-completed_kGHHG",
	"service-requset-state-non-validated-completed": "WorklistPanel_service-requset-state-non-validated-completed_sBsfK",
	"serviceRequsetStateNonValidatedCompleted": "WorklistPanel_service-requset-state-non-validated-completed_sBsfK",
	"service-requset-state-validated-completed": "WorklistPanel_service-requset-state-validated-completed_2faBp",
	"serviceRequsetStateValidatedCompleted": "WorklistPanel_service-requset-state-validated-completed_2faBp",
	"service-requset-state-deleted": "WorklistPanel_service-requset-state-deleted_1m23F",
	"serviceRequsetStateDeleted": "WorklistPanel_service-requset-state-deleted_1m23F"
};