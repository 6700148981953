import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";

export interface IValidationProblemProcessor<TRow> {
    ruleId: string;
    isRuleHighlightable: boolean;

    /**
     * Renders validation problem info (used in ValidationResultSummary)
     */
    renderInfo: (problem: IClientValidationProblem) => React.ReactNode;

    /**
     * Mapping validation problem to a collection (used in DataGrid)
     */
    mapValidationProblem: (problem: IClientValidationProblem, items: TRow[]) => TRow[];
}

/**
 * Provides validation-related functions to components (Grid, ValidationBoundary, ValidationResultSummary)
 */
export default class ValidationProblemProcessorService<TRow> {
    constructor(
        private processors: Array<IValidationProblemProcessor<TRow>> = []
    ) { }

    @State.bound
    private getProcessorForProblem(problem: IClientValidationProblem) {
        return this.processors.find(i => i.ruleId === problem?.ruleId);
    }
    
    @State.bound
    private getProcessorForRuleId(ruleId: string) {
        return this.processors.find(i => i.ruleId === ruleId);
    }

    @State.bound
    public mapValidationProblems(problem: IClientValidationProblem, items: TRow[]) {
        return this.getProcessorForProblem(problem)?.mapValidationProblem(problem, items);
    }

    @State.bound
    public renderInfo(problem: IClientValidationProblem) {
        return this.getProcessorForProblem(problem)?.renderInfo(problem);
    }

    public get highlightableRuleIds() {
        return this.processors.filter(i => i.isRuleHighlightable).map(i => i.ruleId);
    }
}