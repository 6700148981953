import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import InsurerOrganization from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/InsurerOrganizations/InsurerOrganization";
import * as Proxy from "@HisPlatform/BoundedContexts/Finance/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import InsurerOrganizationStoreMapper from "./InsurerOrganizationStoreMapper";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import Di from "@Di";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import { getUpdateInsurerOrganizationDto, getCreateInsurerOrganizationDto } from "./InsurerOrganizationDtoMapper";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class InsurerOrganizationsApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IFinanceClient") private readonly apiClient: Proxy.IFinanceClient,
        @Di.inject("InsurerOrganizationStoreMapper") private mapper: InsurerOrganizationStoreMapper
    ) {
        super();
    }

    public getInsurerOrganizationsAsync(isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<InsurerOrganization[]>(),
            async (target) => {
                const result = await this.apiClient.getInsurerOrganizationsQueryAsync(CreateRequestId(), isPermissionCheckOnly);

                if (!isPermissionCheckOnly) {
                    const mappedResults: InsurerOrganization[] = [];
                    for (const insurerOrganization of result.insurerOrganizations) {
                        const store = new InsurerOrganization();
                        this.mapper.applyToStore(store, insurerOrganization);
                        mappedResults.push(store);
                    }
                    target.value = mappedResults;
                }
                
                target.operationInfo = createOperationInfo(result);
            }
        );
    }

    public getInsurerOrganizationByIdAsync(insurerOrganizationId: InsurerOrganizationId) {
        return this.processOperationAsync(
            new SimpleStore<InsurerOrganization>(),
            async (target) => {
                const result = await this.apiClient.getInsurerOrganizationByIdQueryAsync(CreateRequestId(), insurerOrganizationId.value);
                target.value = new InsurerOrganization();
                this.mapper.applyToStore(target.value, result.insurerOrganization);
                target.operationInfo = createOperationInfo(result);
            }
        );
    }

    public deleteInsurerOrganizationAsync(insurerOrganizationId: InsurerOrganizationId, rowVersion: RowVersion) {
        return this.processOperationAsync(
            new SimpleStore<any>(),
            async (target) => {
                const result = await this.apiClient.deleteInsurerOrganizationCommandAsync(CreateRequestId(), new Proxy.DeleteInsurerOrganizationControllerDto({
                    insurerOrganizationId: insurerOrganizationId,
                    rowVersion: rowVersion
                }));
                target.operationInfo = createOperationInfo(result);
            }
        );
    }

    public updateInsurerOrganizationAsync(insurerOrganization: InsurerOrganization) {
        return this.processOperationAsync(
            new SimpleStore<InsurerOrganization>(),
            async (target) => {
                target.value = new InsurerOrganization();
                const result = await this.apiClient.updateInsurerOrganizationCommandAsync(CreateRequestId(), getUpdateInsurerOrganizationDto(insurerOrganization));
                this.mapper.applyToStore(target.value, result.insurerOrganization);

                target.value.validationResults = mapValidationResults(result.compositeValidationResult as unknown as IServerCompositeValidationResult);

                target.operationInfo = createOperationInfo(result);
            }
        );
    }

    @State.bound
    public updateInsurerOrganizationPermissionCheckAsync() {
        return this.processOperationAsync(
            new SimpleStore(),
            async (target) => {
                const result = await this.apiClient.updateInsurerOrganizationCommandAsync(CreateRequestId(), new Proxy.UpdateInsurerOrganizationControllerDto({
                    address: new Proxy.AddressDto(),
                    insurerOrganizationId: new InsurerOrganizationId("-1"),
                    rowVersion: new RowVersion(0),
                    isActive: false
                }), true);

                target.operationInfo = createOperationInfo(result);
            }
        );
    }

    public validateAsync(insurerOrganization: InsurerOrganization) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async (target) => {

                if (insurerOrganization.isNew) {
                    const dto = getCreateInsurerOrganizationDto(insurerOrganization);
                    dto.isValidateOnly = true;

                    const result = await this.apiClient.createInsurerOrganizationCommandAsync(CreateRequestId(), dto);

                    target.value = mapValidationResults(result.compositeValidationResult as unknown as IServerCompositeValidationResult);
                    target.operationInfo = createOperationInfo(result);
                } else {
                    const dto = getUpdateInsurerOrganizationDto(insurerOrganization);
                    dto.isValidateOnly = true;

                    const result = await this.apiClient.updateInsurerOrganizationCommandAsync(CreateRequestId(), dto);

                    target.value = mapValidationResults(result.compositeValidationResult as unknown as IServerCompositeValidationResult);
                    target.operationInfo = createOperationInfo(result);
                }
            }
        );
    }

    public createInsurerOrganizationAsync(insurerOrganization: InsurerOrganization, isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new SimpleStore<InsurerOrganization>(),
            async (target) => {
                target.value = new InsurerOrganization();
                const result = await this.apiClient.createInsurerOrganizationCommandAsync(CreateRequestId(), getCreateInsurerOrganizationDto(insurerOrganization), isPermissionCheckOnly);
                if (!isPermissionCheckOnly) {
                    this.mapper.applyToStore(target.value, result.insurerOrganization);

                    target.value.validationResults = mapValidationResults(result.compositeValidationResult as unknown as IServerCompositeValidationResult);
                }
                target.operationInfo = createOperationInfo(result);
            }
        );
    }
}