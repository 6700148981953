import React from "react";
import Di from "@Di";
import Reflection from "@Toolkit/CommonWeb/Reflection/Reflection";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import INDataAttributeConfigurator, { ValueConverterFunction } from "@PluginInterface/NData/INDataAttributeConfigurator";
import INDataAttributeService from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/INDataAttributeService";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { mapSingleOrMultiple } from "@Toolkit/CommonWeb/ArrayHelpers";

@Di.injectable()
export default class NDataAttributeService implements INDataAttributeConfigurator, INDataAttributeService {

    private readonly _columnMap = new Map<string, React.ReactElement>();
    private readonly _valueConverterMap = new Map<string, ValueConverterFunction>();

    public registerColumn(attributeType: string, element: React.ReactElement) {
        this._columnMap.set(attributeType, element);
    }
    
    public registerColumnWithName(attributeName: string, element: React.ReactElement) {
        this._columnMap.set(attributeName, element);
    }

    public renderColumn(attributeType: string, attributeName: string, props: object) {
        let element = this._columnMap.get(attributeName);

        if (!element) {
            const normalizedAttributeType = attributeType.replace(/\[\]$/, "");
            element = this._columnMap.get(normalizedAttributeType);
        }

        if (!element) {
            return <DataGridColumn {...props} />;
        }

        return React.cloneElement(element, props);
    }

    public registerValueConverter(attributeType: string, converterFunction: ValueConverterFunction) {
        this._valueConverterMap.set(attributeType, converterFunction);
    }

    public registerValueConverterWithName(attributeName: string, converterFunction: ValueConverterFunction) {
        this._valueConverterMap.set(attributeName, converterFunction);
    }

    public convertValue(attributeType: string, attributeName: string, rawValue: any) {
        let converterFunction = this._valueConverterMap.get(attributeName);

        if (!converterFunction) {
            const normalizedAttributeType = attributeType.replace(/\[\]$/, "");
            converterFunction = this._valueConverterMap.get(normalizedAttributeType);
        }

        if (!converterFunction) {
            converterFunction = this.tryGetPrimitiveConverter(attributeType);
        }

        if (!converterFunction) {

            if (isNullOrUndefined(rawValue)) {
                return null;
            }

            const valueToTypeCheck = Array.isArray(rawValue) && rawValue.length > 0 ? rawValue[0] : rawValue;
            switch (typeof valueToTypeCheck) {
                case "boolean":
                case "number":
                case "string":
                    return rawValue;
            }

            return JSON.stringify(rawValue);
        }

        return mapSingleOrMultiple(rawValue, converterFunction);
    }

    private tryGetPrimitiveConverter(attributeType: string): ValueConverterFunction {
        const singularAttributeType = attributeType.endsWith("[]") ? attributeType.replace("[]", "") : attributeType;

        const reflectedType = Reflection.getTypeInfo(singularAttributeType);

        if (!reflectedType || !reflectedType.attributes?.includes("Primitive")) {
            // Not registered or not Primitive
            return null;
        }

        return rawValue => {
            if (isNullOrUndefined(rawValue)) {
                return null;
            }

            return mapSingleOrMultiple(rawValue, i => (reflectedType.constructor as any).fromJS(i));
        };
    }
}
