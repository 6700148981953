import {
    IDataGridColumnBaseProps,
    IDataGridColumnFilterProps,
} from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import React from "react";
import CareActivityBasedWorklistEntityType from "@Primitives/CareActivityBasedWorklistEntityType";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareActivityBasedWorklistValidationContextSelectBox from "./CareActivityBasedWorklistValidationContextSelectBox";
import ValidationContext from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/ValidationContext.g";

export interface ICareActivityBasedWorklistValidationContext {
    value: ValidationContext;
}

interface ICareActivityBasedWorklistValidationContextColumnDependencies {
    localizationService: ILocalizationService;
}

interface ICareActivityBasedWorklistValidationContextColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: ICareActivityBasedWorklistValidationContextColumnDependencies;
}

class CareActivityBasedWorklistValidationContextColumn extends React.Component<ICareActivityBasedWorklistValidationContextColumnProps> {    
    @State.bound
    private valueRenderer(value: string) {
        const cellValue = this.getCellValue(value);
        return (
            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ flexBasis: "auto", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                    {cellValue}
                </div>                
            </div>
        );
    }

    @State.bound
    private getCellValue(value: string) {
        if (!isNullOrUndefined(value)) {
            const split = value.split(";");
            const result: string[] = [];
            split.map(key => {
                const localized = isNullOrUndefined(key) ? null : this.props._dependencies.localizationService.localizeEnum(key, "ValidationContext");
                if (!isNullOrUndefined(localized)) {
                    result.push(localized.Name);
                }
            });
    
            return result.join(", ");
            } else {
                return null;
            }
    }

    @State.bound
    private hintRenderer(value: string) {
        return this.getCellValue(value);
    }

    public render() {
        const {children, ...columnProps} = this.props;
        return (
            <DataGridColumn
                onRenderCellValue={this.valueRenderer}
                onRenderFilter={this.filterRenderer}
                onRenderHintValue={this.hintRenderer}
                {...columnProps}
            />
        );
    }

    private filterRenderer(filterProps: IDataGridColumnFilterProps<CareActivityBasedWorklistEntityType | CareActivityBasedWorklistEntityType[]>) {
        return (
            <CareActivityBasedWorklistValidationContextSelectBox
                {...filterProps}
                displayMode="selectBox"
                multiSelect
                hoverOnlyIndicatorIcons
            />
        );
    }
}

export default connect(
    CareActivityBasedWorklistValidationContextColumn,
    new DependencyAdapter<ICareActivityBasedWorklistValidationContextColumnProps, ICareActivityBasedWorklistValidationContextColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
