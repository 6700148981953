import React from "react";
import Styles from "./Modal.less";
import Flex from "@CommonControls/Flex";


interface IModalFooterProps {
    left?: React.ReactNode;
    right?: React.ReactNode;
}


const ModalFooter: React.SFC<IModalFooterProps> = (props) => {

    if (!!props.left || !!props.right) {
        return (
            <div className={Styles.modalFooter} data-automation-id="__modalFooter">
                <Flex xsJustify="between">
                    <Flex.Item>{props.left}</Flex.Item>
                    <Flex.Item>{props.right}</Flex.Item>
                </Flex>
            </div>
        );
    }

    return (
        <div className={Styles.modalFooter} data-automation-id="__modalFooter">
            {props.children}
        </div>
    );
};

export default ModalFooter;