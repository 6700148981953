import ResourceId from "@Primitives/ResourceId.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";

export function createHomeMenuLocalizationHelpers(localizationService: ILocalizationService) {
    return {
        getLocalizedText: (localizedTexts: MultiLingualLabel | null, resourceId: ResourceId | null) => {
            let res: string | null = null;

            if (localizedTexts) {
                res = localizedTexts.getWithCurrentCultureCodeOrWithDefaultCultureCode(localizationService.currentCultureProvider.cultureCode) ?? null;
            }

            if (res === null && resourceId) {
                res = localizationService.localizeReferenceData(resourceId) ?? null;
            }

            return res ?? "";
        }
    };
}