import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import EhiSyncStatus from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiSyncStatus";

interface IEhiSyncStatusMultiSelectProps extends ISelectBoxBaseProps {
    value?: EhiSyncStatus | EhiSyncStatus[];
    onChange?: (newValue: EhiSyncStatus | EhiSyncStatus[]) => void;
}


const EhiSyncStatusSelectBox: React.FC<IEhiSyncStatusMultiSelectProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"EhiSyncStatus"}
        enumOrigin="server"
        enumType={EhiSyncStatus}
        displayMode="selectBox"
    />
);

export default EhiSyncStatusSelectBox;