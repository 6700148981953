import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import * as Ui from "@CommonControls";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import MedicalConditionHistoryItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemBase";

interface IMedicalConditionHistoryItemSelectorViewProps extends ISelectBoxBaseProps {
    items: Array<ISelectBoxItem<number>>;
    renderVersionExtension: () => JSX.Element;
    renderSelfDeterminationExtension: () => JSX.Element;
}

@State.observer
export class MedicalConditionHistoryItemSelectorView extends React.Component<IMedicalConditionHistoryItemSelectorViewProps> {

    constructor(props: IMedicalConditionHistoryItemSelectorViewProps) {
        super(props);
    }

    public render() {
        return (
            <ReadOnlyContext.Provider value={false} >
                <Ui.Flex style={{ paddingTop: 10 }} xsJustify={"end"}>
                    {this.props.renderSelfDeterminationExtension()}
                    {this.props.renderVersionExtension()}
                    <Ui.Flex.Item xs={4}>
                        <Ui.SelectBox
                            {...this.props}
                            orderItems={false}
                            items={this.props.items}
                            searchable={true}
                            clearable={false}
                            onChange={this.props.onChange}
                            valueOnClear={null}
                            value={this.props.value} />
                    </Ui.Flex.Item>
                </Ui.Flex >
            </ReadOnlyContext.Provider>
        );
    }
}
