import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

/** 
 * Maps a single value OR array of values.
 * @description
 * If source is not an array, then the result will be a single mapped value. Otherwise the result will be a mapped array of the source array.
 * When the wrapSingleInArray is true, the result will always be an array.
 */
export function mapSingleOrMultiple<TSource, TDest>(source: TSource | TSource[], mapperFunction: (value: TSource) => TDest, wrapSingleInArray: boolean = false): TDest | TDest[] {
    if (isNullOrUndefined(source)) {
        return null;
    }

    if (Array.isArray(source)) {
        return source.map(mapperFunction);
    }

    return wrapSingleInArray ? [mapperFunction(source)] : mapperFunction(source);
}