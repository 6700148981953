import State from "@Toolkit/ReactClient/Common/StateManaging";
import ExtendedFilterStoreBase from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ExtendedFilterStoreBase";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import { IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { createFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterHelpers";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";

export default class ContagiousPatientReportFilterStore extends ExtendedFilterStoreBase {

    constructor(filterStore: IFilterStore
    ) {
        super(filterStore);
    }

    public static pointOfCareIdFilterName: string = "ContagiousPatientReportBased_PointOfCareIdFilter";
    public static onlyItemsWithoutEndReportsFilterName: string = "ContagiousPatientReportBased_OnlyItemsWithoutEndReportsFilter";

    @State.observable.ref public pointOfCareIds: PointOfCareId[] = [];
       
    @State.action.bound
    public onPointOfCareChange(newValue: PointOfCareId, checkedValue: boolean) {
        if (this.pointOfCareIds) {
            if (checkedValue === false) {
                this.pointOfCareIds = this.pointOfCareIds.filter(x => x.value !== newValue.value);
            } else if (!this.pointOfCareIds.some(x => x.value === newValue.value)) {
                this.pointOfCareIds = [...this.pointOfCareIds, newValue];
            }
        }
        this.setFilter(ContagiousPatientReportFilterStore.pointOfCareIdFilterName, this.pointOfCareIds);
    }

    @State.action.bound
    public setOnlyItemsWithoutEndReports(newValue: boolean | null) {
        this.setFilter(ContagiousPatientReportFilterStore.onlyItemsWithoutEndReportsFilterName, newValue); 
    }

    @State.computed public get onlyItemsWithoutEndReports() {
        const onlyItemsWithoutEndReports = this.getFilter(ContagiousPatientReportFilterStore.onlyItemsWithoutEndReportsFilterName);
        return onlyItemsWithoutEndReports;
    }

    @State.action.bound
    public clearFilters() {
        this.pointOfCareIds = [];

        this.setOnlyItemsWithoutEndReports(null);
    }

    @State.action.bound
    public clearAllFilters() {
        this.clearFilters();
        this.filterStore.__reset();
    }

    public static getFilterDescriptors() {
        const descriptors: IDataGridFilterDescriptor[] = [];

        const pointOfCareIdFilterValueSerializer: IDataGridFilterValueSerializer<PointOfCareId | PointOfCareId[]> = {
            deserialize: (value: string) => {
                if (!value) {
                    return null;
                }
                const items = value.split(",");
                if (items.length === 1) {
                    return new PointOfCareId(items[0]);
                }
                
                return items.map(i => new PointOfCareId(i));
            },
            serialize: (value: PointOfCareId | PointOfCareId[]) => {
                return Array.isArray(value) ? value.map(v => v.value).join(",") : value.value;
            }
        };

        const onlyItemsWithoutEndReportsFilterValueSerializer: IDataGridFilterValueSerializer<boolean> = {
            serialize: (value: boolean) => value.toString(),
            deserialize: (value: string) => value === "true" ? true : false
        };

        // Create descriptors
        const pointOfCareIdFilterDescriptor = createFilterDescriptor(this.pointOfCareIdFilterName,
            pointOfCareIdFilterValueSerializer,
            WorklistConditionType.EntityId);
        const onlyItemsWithoutEndReportsFilterDescriptor = createFilterDescriptor(this.onlyItemsWithoutEndReportsFilterName,
            onlyItemsWithoutEndReportsFilterValueSerializer,
            WorklistConditionType.Boolean);

        // Fill descriptors array
        descriptors.push(pointOfCareIdFilterDescriptor);
        descriptors.push(onlyItemsWithoutEndReportsFilterDescriptor);

        return descriptors;
    }
}
