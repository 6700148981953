import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";
import ValidationBoundaryContext from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryContext";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";

export default class ValidationBoundaryAdapter<TProps> implements IComponentAdapter<TProps> {

    public get usedContexts(): Array<React.Context<any>> {
        return [ValidationBoundaryContext];
    }

    constructor(
        private readonly mapToProps: (props: TProps, store: IValidationBoundaryStore) => Partial<TProps> = (props, store) => ({ _validationBoundary: store } as any)
    ) { }

    public getMappedProps(props: TProps, contextValues?: Map<React.Context<any>, any>): Partial<TProps> {
        const context: IValidationBoundaryStore = contextValues.get(ValidationBoundaryContext);
        return this.mapToProps(props, context);
    }
}