import EhiErrorSeverity from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiErrorSeverity";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/Care/Api/Proxy.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export function mapEhiErrors(errors: Proxy.EhiErrorDto[]) {
    if (isNullOrUndefined(errors)) {
        return [];
    }

    return errors.map(i => new EhiError(i.code, i.moduleName, i.message, i.ehiErrorSeverity as any as EhiErrorSeverity));
}