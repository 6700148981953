import React from "react";
import IClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientSideValidator";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import { emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IClientSideValidationBoundaryProps {
    validator: IClientSideValidator;

    mapEntityId?: boolean;
    entityTypeName?: string;
    entityId?: string;
    pathPrefix?: string;

    children?: React.ReactNode;
}


const ClientSideValidationBoundary: React.FC<IClientSideValidationBoundaryProps> = State.observer(props => (
    <ValidationBoundary
        validationResults={props.validator.validationResults}
        isRequired={props.validator.isRequired}
        onChanged={props.validator.changeProperty}
        onValidateAsync={props.validator.validateAsync}
        mapEntityId={props.mapEntityId}
        entityTypeName={props.entityTypeName}
        entityId={props.entityId}
        pathPrefix={props.pathPrefix}
        children={props.children}
        validateAllEvent={props.validator.validateAllEvent}
    />
));

export default ClientSideValidationBoundary;