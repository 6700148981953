import React from "react";
import _ from "@HisPlatform/Common/Lodash";
import Styles from "./Table.less";
import { ICommonControlProps, getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";

interface ITableProps extends ICommonControlProps {
    scrollable?: boolean;
    fixedHeight?: string;
    automationId: string;
    fixedLayout?: boolean;
    visualStyle?: "normal" | "dark";
    outerDivStyle?: React.CSSProperties;
}

export default class Table extends React.PureComponent<ITableProps> {

    private getClassNameByVisualStyle() {
        if (this.props.visualStyle && this.props.visualStyle === "dark") {
            return Styles.dark;
        }
        return Styles.table;
    }

    public render() {
        const htmlProps = getStandardHtmlProps(this.props);

        const tableClasses = new CompositeClassName(this.getClassNameByVisualStyle());
        tableClasses.add(htmlProps.className);

        const divClasses = new CompositeClassName();
        divClasses.addIf(this.props.scrollable, Styles.scrollable);

        return (
            <div style={{ height: this.props.fixedHeight, ...this.props.outerDivStyle }}
                className={divClasses.classNames}
                data-automation-id={this.props.automationId || undefined}
            >
                <table {...htmlProps} style={{ tableLayout: this.props.fixedLayout ? "fixed" : undefined }} className={tableClasses.classNames}>
                    {this.props.children}
                </table>
            </div>
        );
    }
}