import PatientId from "@Primitives/PatientId.g";
import * as CareProxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import { getHistoryItemDetailDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalConditionDtoMapper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import PatientBloodTypeStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/PatientBloodType/PatientBloodTypeStore";
import PatientBloodTypeHistoryItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/PatientBloodType/PatientBloodTypeHistoryItem";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { Category, CategoryObservationDto, TextualObservationDto } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";

export function getAddPatientBloodTypeControllerDto(source: PatientBloodTypeStore, patientId: PatientId, isValidateOnly: boolean) {
    let categoryObservation = null;
    if (!isNullOrUndefined(source.categoryObservation)) {
        categoryObservation = new CategoryObservationDto({
            
            patientId: patientId,
            rowVersion: source.categoryObservation.rowVersion,
            careActivityId: source.categoryObservation.careActivityId,
            observationDefinitionId: source.categoryObservation.observationDefinitionId,
            observationId: source.categoryObservation.observationId,
            observedAt: source.observedAt?.toUtcDayStartMoment(),
            observerBy: source.historyItems[0].historyItemDetail.asserter,
            categories: source.categoryObservation.categories.map(c => new Category({ key: c.key, value: c.value }))            
        });
    }

    return new CareProxy.AddPatientBloodTypeControllerDto({
        patientId: patientId,
        categoryObservationDto: categoryObservation,
        historyItemDtos: [getPatientBloodTypeHistoryItemDto(source.latestHistoryItem as PatientBloodTypeHistoryItem)],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData
    });
}

export function getUpdatePatientBloodTypeControllerDto(source: PatientBloodTypeStore, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.UpdatePatientBloodTypeControllerDto({
        patientBloodTypeId: source.id,
        historyItemDtos: [getPatientBloodTypeHistoryItemDto(source.latestHistoryItem as PatientBloodTypeHistoryItem)],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeletePatientBloodTypeHistoryItemControllerDto(source: PatientBloodTypeStore, versionNumber: number, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.DeletePatientBloodTypeHistoryItemControllerDto({
        patientBloodTypeId: source.id,
        versionNumbers: [versionNumber],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeletePatientBloodTypeControllerDto(store: PatientBloodTypeStore) {
    return new CareProxy.DeletePatientBloodTypeControllerDto({
        patientBloodTypeId: store.id,
        lockId: store.lockInfo?.lockId,
        extensionData: store.extensionData,
        isValidateOnly: false,
        releaseLockIfSuccessful: true
    });
}

function getPatientBloodTypeHistoryItemDto(source: PatientBloodTypeHistoryItem) {
    let textualObservation = null;
    if (!isNullOrUndefined(source.textualObservation)) {
        textualObservation = new TextualObservationDto({
            
            patientId: source.textualObservation?.patientId,
            rowVersion: source.textualObservation?.rowVersion,
            careActivityId: source.textualObservation?.careActivityId,
            observationDefinitionId: source.textualObservation?.observationDefinitionId,
            observationId: source.textualObservation?.observationId,
            observedAt: source.createdAt,
            observerBy: source.historyItemDetail.asserter,
            contentId: source.textualObservation?.careContentId,
            stringContent: source.textualObservation?.contentStore.getContent()            
        });
    }

    return new CareProxy.PatientBloodTypeHistoryItemDto({
        
        versionNumber: source.versionNumber,
        index: source.index,
        createdAt: source.createdAt,
        historyItemDetail: getHistoryItemDetailDto(source.historyItemDetail),
        textualObservationDto: textualObservation,
        extensionData: source.extensionData
    });
}