import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import { getEntityFieldValue } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";

export default class EpisodeOfCare {
    constructor(private formFields: FormFieldDataBase[]) { 
    }

    public get id(): EpisodeOfCareId {
        const id = getEntityFieldValue<EpisodeOfCareId>(this.formFields, "EpisodeOfCareId", EpisodeOfCareId);
        return id;
    }

    public get name(): string {
        const nameField = getField<TextFormFieldData>(this.formFields, "Name");
        return nameField.value;
    }
}