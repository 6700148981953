import React from "react";
import Styles from "./OrganizationSelector.less";
import * as Ui from "@CommonControls";
import { ITableFrameColumn } from "@CommonControls/TreeGrid/TableFrame";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import OrganizationUnitTreeNode from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnitTreeNode";
import { OrganizationUnitName } from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/TreeColumnRenderers";
import AddressView from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/ValueRenderers/AddressView";
import PractitionerView from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/ValueRenderers/PractitionerView";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { arrayIsNullOrEmpty, emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ReadOnlyContextAdapter from "@Toolkit/ReactClient/Components/ReadOnlyContext/ReadOnlyContextAdapter";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import ValidationBoundaryAdapter from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryAdapter";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import _ from "@HisPlatform/Common/Lodash";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import OrganizationUnitType from "@HisPlatform/BoundedContexts/Organization/Components/Panels/OrganizationUnitTreePanel/OrganizationUnitTypeColumnRenderers";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import FieldValidationResult from "@CommonControls/FieldValidationResult";
import EventHandler from "@Toolkit/ReactClient/Components/EventHandler/EventHandler";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";

interface IOrganizationSelectorDependencies {
    apiAdapter: StructureApiAdapter;
    referenceDataStore: OrganizationReferenceDataStore;
}

export interface IOrganizationSelectorProps {
    _dependencies?: IOrganizationSelectorDependencies;
    value?: OrganizationUnitId[];
    onChange: (newValue: OrganizationUnitId, isChecked: boolean) => void;
    pointOfCareMode: boolean;

    filteringPropertyGroup?: string;
    filteringPropertyName?: string;
    filteringPropertyValue?: any;

    label?: string;
    isRequired?: boolean;
    readOnly?: boolean;
    validationContext?: IValidationBoundaryStore;
    propertyIdentifier?: string;
    isOpen?: boolean;
    isAllChecked?: boolean;
    rootOrganizationUnitIsNotSelectable?: boolean;
    automationId: string;
}


@State.observer
class OrganizationSelector extends React.Component<IOrganizationSelectorProps> {

    private get dependencies() {
        return this.props._dependencies;
    }

    private get apiAdapter() { return this.props._dependencies.apiAdapter; }

    private filterValueBox = State.observable.box("");

    @State.observable.ref private rootOrganizationUnitId: OrganizationUnitId = null;

    @State.computed
    private get singleDisplayValue() {
        const organizationUnitId = this.props.value?.length > 0 && this.props.value[0];
        if (!organizationUnitId) {
            return null;
        }

        const item = this.props._dependencies.referenceDataStore.organizationUnitMap.get(organizationUnitId);
        return item && `${item.code} - ${item.baseData.naturalIdentifier.name}`;
    }

    @State.computed
    private get validationResult() {
        return this.validationProblems.length === 0 ? null : this.validationProblems[0];
    }

    @State.action.bound
    public onFilterValueChange(newValue: string) {
        this.filterValueBox.set(newValue);
    }

    @State.observable.ref private organizationStructure: OrganizationUnitTreeNode[] = [];

    @State.action.bound
    private setOrganizationStructure(newValue: OrganizationUnitTreeNode[]) {
        this.organizationStructure = newValue;
    }

    @State.action.bound
    private onCheckNode(node?: OrganizationUnitTreeNode) {
        if (node?.isLeaf && this.props.onChange) {
            this.props.onChange(node.organizationUnitId, node.isChecked);
        }
    }

    @State.action.bound
    private onSelectNode(node?: OrganizationUnitTreeNode) {
        if (this.props.onChange) {
            if (this.props.rootOrganizationUnitIsNotSelectable && node.organizationUnitId.value === this.rootOrganizationUnitId.value) {
                return;
            }

            for (const child of this.organizationStructure) {
                this.setAllUnchecked(child);
            }

            node.isChecked = true;

            this.props.onChange(new OrganizationUnitId(node.organizationUnitId.value), node.isChecked);
            this.setIsOpen();
        }
    }

    @State.computed
    private get validationProblems() {
        if (this.props.validationContext) {
            const problems = this.props.validationContext.getValidationProblems(this.props.propertyIdentifier);
            return arrayIsNullOrEmpty(problems) ? emptyArray : problems;
        }
        return emptyArray;
    }

    @State.computed private get isRequired() {
        return this.props.isRequired || (
            this.props.validationContext && this.props.validationContext.isRequired(this.props.propertyIdentifier)
        );
    }

    @State.observable public isOpen: boolean = this.props.isOpen ?? false;

    @State.action.bound
    public setIsOpen() {
        this.isOpen = !this.isOpen;
    }

    @State.computed private get selectedElementCounter() {
        if (!this.organizationStructure || this.organizationStructure.length === 0) {
            return "0";
        }

        return this.calculateElementCounter(this.organizationStructure[0]).toString();
    }

    @State.bound
    private calculateElementCounter(organizationUnit: OrganizationUnitTreeNode) {
        let elementCounter = 0;
        if (organizationUnit.children && organizationUnit.children.length > 0) {
            for (const child of organizationUnit.children) {
                elementCounter += this.calculateElementCounter(child);
            }
        }

        if (organizationUnit.isChecked && organizationUnit.isLeaf) {
            elementCounter++;
        }
        return elementCounter;
    }

    private columns: ITableFrameColumn[] = [
        {
            title: StaticWebAppResources.Organization.OrganizationSelector.Name,
            width: 50,
            widthMode: "percent",
            displayValueGetter: (r: OrganizationUnitTreeNode) => (
                <OrganizationUnitName
                    node={r}
                    filterValueBox={this.filterValueBox} />
            )
        },
        {
            title: StaticWebAppResources.Organization.OrganizationSelector.Type,
            width: 3,
            widthMode: "percent",
            displayValueGetter: (r: OrganizationUnitTreeNode) => r.tags && (
                <OrganizationUnitType node={r} />
            )
        },
        {
            title: StaticWebAppResources.Organization.OrganizationSelector.Address,
            width: 25,
            widthMode: "percent",
            displayValueGetter: (r: OrganizationUnitTreeNode) => r.address && (
                <AddressView address={r.address} />
            )
        },
        {
            title: StaticWebAppResources.Organization.OrganizationSelector.Manager,
            width: 25,
            widthMode: "percent",
            displayValueGetter: (r: OrganizationUnitTreeNode) => (
                <PractitionerView practitionerId={r.managerId} />
            )
        }
    ];

    public componentDidMount() {
        dispatchAsyncErrors(this.initializeOrganizationsAsync(), this);
    }

    public componentDidUpdate(prevProps: IOrganizationSelectorProps) {
        const shouldUpdateStructure = prevProps.isAllChecked !== this.props.isAllChecked
            || !OrganizationSelector.isSameOrganizationUnits(prevProps.value, this.props.value);
        if (shouldUpdateStructure) {
            this.onOrganizationStructureLoaded(this.organizationStructure);
        }

        if (!OrganizationSelector.isSameOrganizationUnits(prevProps.value, this.props.value)) {
            this.validate();
        }
    }

    @State.boundLoadingState("isLoading")
    private async initializeOrganizationsAsync() {
        let organizationStructureStore: SimpleStore<OrganizationUnitTreeNode[]>;

        if (this.props.filteringPropertyGroup !== null && this.props.filteringPropertyGroup !== undefined) {
            organizationStructureStore = await this.apiAdapter.getFilteredOrganizationStructureAsync(
                this.props.filteringPropertyGroup,
                this.props.filteringPropertyName,
                this.props.filteringPropertyValue,
                null,
                false);
        } else {
            organizationStructureStore = await this.apiAdapter.getOrganizationStructureAsync(null, false);
        }

        if (this.props.rootOrganizationUnitIsNotSelectable) {
            const rootOrganizationUnitId = await this.apiAdapter.getRootOrganizationUnitIdAsync();
            State.runInAction(() => {
                this.rootOrganizationUnitId = rootOrganizationUnitId.value;
            });
        }

        await this.dependencies.referenceDataStore.organizationUnitMap.ensureAllLoadedAsync();

        const organizationStructure = organizationStructureStore.value;
        await this.loadHealthcareProfessionsAsync(organizationStructure);
        this.onOrganizationStructureLoaded(organizationStructure);

        this.setOrganizationStructure(organizationStructure);
    }

    @State.bound
    private async loadHealthcareProfessionsAsync(nodes: OrganizationUnitTreeNode[]) {
        const ids = _.flatten(nodes.map(item => item.getHealthCareProfessionIds()));
        const uniqIds = _.uniqBy(ids, item => item.value);
        await this.dependencies.referenceDataStore.healthCareProfession.ensureLoadedAsync(uniqIds);
    }

    @State.action.bound
    private onOrganizationStructureLoaded(organizationUnits: OrganizationUnitTreeNode[]) {
        if (this.props.pointOfCareMode) {
            for (const organizationUnit of organizationUnits) {
                this.setOrganizationUnitCheckedStatus(organizationUnit);
            }
        } else {
            for (const organizationUnit of organizationUnits) {
                this.setOrganizationUnitCheckedStatusForSingleSelection(organizationUnit);
            }
        }
    }

    @State.action.bound
    private setOrganizationUnitCheckedStatusForSingleSelection(organizationUnitTreeNode: OrganizationUnitTreeNode) {
        if (this.props.value?.length > 0 && this.props.value[0].value === organizationUnitTreeNode.organizationUnitId.value) {
            organizationUnitTreeNode.setIsChecked(true);
        }

        for (const organizationUnit of organizationUnitTreeNode.children) {
            this.setOrganizationUnitCheckedStatusForSingleSelection(organizationUnit);
        }
    }

    @State.action.bound
    private setAllUnchecked(organizationUnitTreeNode: OrganizationUnitTreeNode) {
        organizationUnitTreeNode.setIsChecked(false);
        for (const organizationUnit of organizationUnitTreeNode.children) {
            this.setAllUnchecked(organizationUnit);
        }
    }

    @State.action.bound
    private setOrganizationUnitCheckedStatus(organizationUnit: OrganizationUnitTreeNode): boolean {
        if (!organizationUnit.children || !organizationUnit.children.length) {
            let isChecked = true;
            if (!this.props.isAllChecked) {
                isChecked = this.props.value && this.props.value.some(x => x.value === organizationUnit.organizationUnitId.value);
            }
            organizationUnit.isChecked = isChecked;
            return isChecked;
        }

        let allChildrenChecked = true;
        let someChildrenChecked = false;
        for (const child of organizationUnit.children) {
            const childRes = this.setOrganizationUnitCheckedStatus(child);
            if (childRes !== false) {
                someChildrenChecked = true;
            } else {
                allChildrenChecked = false;
            }
        }

        if (allChildrenChecked) {
            organizationUnit.isChecked = true;
        } else if (someChildrenChecked) {
            organizationUnit.isChecked = null;
        } else {
            organizationUnit.isChecked = false;
        }

        return organizationUnit.isChecked;
    }

    private getClassNames() {

        const classes = new CompositeClassName();
        const validationProblemSeverity = this.validationResult && this.validationResult.severity;

        if (validationProblemSeverity !== null && validationProblemSeverity !== undefined) {
            classes.addIf(validationProblemSeverity === "warning", Styles.warning);
            classes.addIf(validationProblemSeverity === "error", Styles.error);
        }

        return classes.classNames;
    }

    public renderTitle() {
        if (this.props.pointOfCareMode) {
            return (
                <Ui.Flex xsJustify={"between"}>
                    <Ui.Flex.Item>
                        {StaticWebAppResources.Organization.OrganizationSelector.Title}
                    </Ui.Flex.Item>
                    <Ui.Flex.Item className={Styles.selectedItemCounter}>
                        {this.selectedElementCounter}{StaticWebAppResources.Organization.OrganizationSelector.ElementCounter}
                    </Ui.Flex.Item>
                </Ui.Flex>
            );
        } else {
            return (
                <Ui.Flex xsJustify={"between"}>
                    <Ui.Flex.Item>
                        {this.singleDisplayValue}
                    </Ui.Flex.Item>
                </Ui.Flex>
            );
        }

    }

    private renderValidationResults(): React.ReactNode {
        return <FieldValidationResult problems={this.validationProblems} />;
    }

    public render() {
        return (
            <div style={{ marginLeft: 4, marginRight: 4 }}>
                {this.props.label && <label className={Styles.label}>{this.props.label}{this.isRequired && <span className={Styles.requiredStar} />}</label>}
                <Ui.GroupBox
                    title={this.renderTitle()}
                    visualStyle="standard"
                    isOpen={this.isOpen}
                    onOpenStateChanged={this.setIsOpen}
                    isCollapsible
                    hasBorder
                    automationId={this.props.automationId + "__groupBox"}
                    className={this.getClassNames()}>
                    <Ui.TreeGrid
                        {...this.props}
                        multiSelect={this.props.pointOfCareMode}
                        filterable
                        onFilterValueChange={this.onFilterValueChange}
                        onCheckNode={this.props.pointOfCareMode ? this.onCheckNode : undefined}
                        onSelectNode={this.props.pointOfCareMode ? undefined : this.onSelectNode}
                        columns={this.columns}
                        data={this.organizationStructure}
                        automationId={this.props.automationId + "__treeGrid"}
                    />
                </Ui.GroupBox>
                {this.renderValidationResults()}
                <EventHandler event={this.props.validationContext?.validateAllEvent} onFired={this.validate} />
            </div>
        );
    }

    @State.bound
    private validate() {
        if (this.props.validationContext) {
            this.props.validationContext.changed(this.props.propertyIdentifier, this.props.value);
        }
    }

    private static isSameOrganizationUnits(previousOrganizationUnitIds?: OrganizationUnitId[], currentOrganizationUnitIds?: OrganizationUnitId[]) {
        if (!previousOrganizationUnitIds && !currentOrganizationUnitIds) {
            return true;
        }
        if (!previousOrganizationUnitIds) {
            return false;
        }
        if (!currentOrganizationUnitIds) {
            return false;
        }

        const previousSet = new Set(previousOrganizationUnitIds.map(poc => poc.value));
        const currentSet = new Set(currentOrganizationUnitIds.map(poc => poc.value));
        if (previousOrganizationUnitIds.length !== currentOrganizationUnitIds.length) {
            return false;
        }

        for (const organizationUnit of currentSet) {
            if (!previousSet.has(organizationUnit)) {
                return false;
            }
        }

        return true;
    }
}

export default connect(
    OrganizationSelector,
    new ReadOnlyContextAdapter<IOrganizationSelectorProps>((props, isReadOnly) => ({ readOnly: props.readOnly || isReadOnly })),
    new ValidationBoundaryAdapter<IOrganizationSelectorProps>((props, validationStore) => ({ validationContext: validationStore })),
    new DependencyAdapter<IOrganizationSelectorProps, IOrganizationSelectorDependencies>(container => {
        return {
            apiAdapter: container.resolve("StructureApiAdapter"),
            referenceDataStore: container.resolve("OrganizationReferenceDataStore")
        };
    })
);
