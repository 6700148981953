import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import PregnancyStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/PregnancyStatementStore";
import PregnancyStatementId from "@Primitives/PregnancyStatementId.g";
import PregnancyStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/PregnancyStatementHistoryItem";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import CustomPregnancyOutcomeAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/CustomPregnancyOutcomeAssignment";
import TextualObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/TextualObservation";
import CategoryObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/CategoryObservation";
import ReferencedPregnancyOutcomeAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/ReferencedPregnancyOutcomeAssignment";

type ResponseType = Proxy.GetPregnancyStatementByIdCommandResponse | Proxy.AddPregnancyStatementCommandResponse | Proxy.UpdatePregnancyStatementCommandResponse | Proxy.DeletePregnancyStatementHistoryItemCommandResponse;

@Di.injectable()
export default class PregnancyStatementStoreMapper extends LockingEntityStoreMapper<Proxy.PregnancyStatementDto, PregnancyStatementStore, ResponseType> {

    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    protected storeEntityIdType = PregnancyStatementId;

    protected entityDtoSelector = (response: ResponseType) => {
        return response.pregnancyStatementDto;
    };

    protected applyToStoreCore(target: PregnancyStatementStore, response: any) {
        const dto = this.entityDtoSelector(response);
        if (dto != null) {
            target.id = dto.id;
            target.expectedAt = dto.expectedAt;
            target.historyItems = State.observable(this.getHistoryItems(dto.historyItems));
            target.currentItemIndex = target.historyItems.length - 1;
            target.patientId = dto.patientId;
            target.extensionData = dto.extensionData;
        }
    }

    private getHistoryItems(dtoArray: Proxy.PregnancyStatementHistoryItemDto[]): PregnancyStatementHistoryItem[] {
        return dtoArray.map(dto => this.getHistoryItem(dto)).sort((a, b) => a.versionNumber - b.versionNumber);
    }

    private getHistoryItem(dto: Proxy.PregnancyStatementHistoryItemDto): PregnancyStatementHistoryItem {
        const returnItem = new PregnancyStatementHistoryItem();

        returnItem.historyItemDetail = this.getHistoryItemDetail(dto.historyItemDetail);
        returnItem.pregnancyOutcomeAssignment = this.getPregnancyOutcomeAssignment(dto);
        returnItem.versionNumber = dto.versionNumber;
        returnItem.index = dto.index;
        returnItem.createdAt = dto.createdAt;
        returnItem.extensionData = dto.extensionData;

        return returnItem;
    }

    private getHistoryItemDetail(dto: Proxy.MedicalConditionHistoryItemDetailDto): MedicalConditionHistoryItemDetail {
        const returnItem = new MedicalConditionHistoryItemDetail();

        returnItem.asserter = dto.asserter;
        returnItem.asserterType = dto.asserterType;
        returnItem.notes = dto.notes;
        returnItem.verifier = dto.verifier;

        return returnItem;
    }

    private getPregnancyOutcomeAssignment(dto: Proxy.PregnancyStatementHistoryItemDto) {
        let pregnancyOutcomeAssignment;

        if (dto.pregnancyOutcomeAssignment instanceof Proxy.CustomPregnancyOutcomeDto) {
            pregnancyOutcomeAssignment = new CustomPregnancyOutcomeAssignment(
                new TextualObservation
                    (dto.pregnancyOutcomeAssignment.textualObservationDto.observationId,
                        dto.pregnancyOutcomeAssignment.textualObservationDto.observationDefinitionId,
                        dto.pregnancyOutcomeAssignment.textualObservationDto.rowVersion,
                        dto.pregnancyOutcomeAssignment.textualObservationDto.patientId,
                        dto.pregnancyOutcomeAssignment.textualObservationDto.careActivityId,
                        dto.pregnancyOutcomeAssignment.textualObservationDto.contentId,
                        dto.pregnancyOutcomeAssignment.textualObservationDto.stringContent));
        } else if (dto.pregnancyOutcomeAssignment instanceof Proxy.ReferencedPregnancyOutcomeDto) {
            pregnancyOutcomeAssignment = new ReferencedPregnancyOutcomeAssignment(
                new CategoryObservation(
                    dto.pregnancyOutcomeAssignment.categoryObservationDto.observationId,
                    dto.pregnancyOutcomeAssignment.categoryObservationDto.observationDefinitionId,
                    dto.pregnancyOutcomeAssignment.categoryObservationDto.rowVersion,
                    dto.pregnancyOutcomeAssignment.categoryObservationDto.patientId,
                    dto.pregnancyOutcomeAssignment.categoryObservationDto.careActivityId,
                    dto.pregnancyOutcomeAssignment.categoryObservationDto.categories));
        }

        return pregnancyOutcomeAssignment;
    }
}
