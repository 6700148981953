exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AccessControlModal_rights-panel_3ydYq:first-child {\n  padding-right: 5px;\n}\n.AccessControlModal_rights-panel_3ydYq:last-child {\n  border-left: solid 1px rgba(0, 0, 0, 0.3);\n  padding-left: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"rights-panel": "AccessControlModal_rights-panel_3ydYq",
	"rightsPanel": "AccessControlModal_rights-panel_3ydYq"
};