exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OrganizationSelector_required-star_1tQPj {\n  color: #CE5374;\n  position: relative;\n  top: 2px;\n  left: 2px;\n  font-size: 19px;\n}\n.OrganizationSelector_required-star_1tQPj::after {\n  content: \"*\";\n}\n.OrganizationSelector_warning_1lc6e,\n.OrganizationSelector_warning_1lc6e:hover {\n  border: solid 1px #fe8443;\n  border-bottom: solid #fe8443 3px;\n}\n.OrganizationSelector_error_26q4I,\n.OrganizationSelector_error_26q4I:hover {\n  border: solid 1px #ce5374;\n  border-bottom: solid #ce5374 3px;\n}\n.OrganizationSelector_disabled_2x127,\n.OrganizationSelector_disabled_2x127:hover {\n  background-color: white;\n  border: solid 1px rgba(134, 149, 167, 0.75);\n  cursor: default;\n}\n.OrganizationSelector_label_2zRK8 {\n  font-family: PragatiNarrow;\n  font-size: 16px;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 1.14;\n  letter-spacing: normal;\n  text-align: left;\n  color: #8695a7;\n}\n.OrganizationSelector_selected-item-counter_7kV3L {\n  font-family: PragatiNarrow;\n}\n", ""]);

// exports
exports.locals = {
	"required-star": "OrganizationSelector_required-star_1tQPj",
	"requiredStar": "OrganizationSelector_required-star_1tQPj",
	"warning": "OrganizationSelector_warning_1lc6e",
	"error": "OrganizationSelector_error_26q4I",
	"disabled": "OrganizationSelector_disabled_2x127",
	"label": "OrganizationSelector_label_2zRK8",
	"selected-item-counter": "OrganizationSelector_selected-item-counter_7kV3L",
	"selectedItemCounter": "OrganizationSelector_selected-item-counter_7kV3L"
};