import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";


export default class ServiceRequestDefinitionDialogParams implements IModalParams {
    public static type = "ServiceRequestDefinitionDialogParams";

    public get type() {
        return ServiceRequestDefinitionDialogParams.type;
    }
}
