import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import MedicalServiceFinancingId from "@Primitives/MedicalServiceFinancingId.g";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import { PatientServiceListItem } from "./PatientServiceList";

export class PatientPerformedServiceListItem extends PatientServiceListItem {
    constructor(
        quantity: number,
        isBilled: boolean,
        public medicalServiceFinancingId: MedicalServiceFinancingId,
        public medicalServiceId: MedicalServiceId,
        public financedServiceId: FinancedServiceId
    ) {
        super(quantity, isBilled);
    }
}
