import React, {useMemo} from "react";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import MedicalServicePriority from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalServices/Enum/MedicalServicePriority.g";
import Urgency from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/Urgency.g";

function CareActivityBasedWorklistPrioritySelectBox(props: IUniversalEnumSelectorBaseProps): React.ReactElement {
    const enums = useMemo(() => [
        {
            enumName: "Urgency",
            displayName: StaticProductivityResources.Worklist.Columns.UrgencyFilter,
            enumType: Urgency,
        },
        {
            enumName: "MedicalServicePriority",
            displayName: StaticProductivityResources.Worklist.Columns.MedicalServicePriorityFilter,
            enumType: MedicalServicePriority
        }
    ], []);

    return (
        <UniversalEnumSelector
            {...props}
            enumOrigin="server"
            isMultiEnum
            enums={enums}
            searchable={false}
            clearable
            maxMenuWidth={commonMenuMaxWidth}
        />
    );
}

export default CareActivityBasedWorklistPrioritySelectBox;
