import State from "@Toolkit/ReactClient/Common/StateManaging";
import RecurrenceType from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/RecurrenceType";
import DayOfWeek from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/DayOfWeek.g";
import WeekOfMonth from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/WeekOfMonth.g";

export default class TimePhaseRecurrenceElement {
    @State.observable public recurrenceType: RecurrenceType = null;
    @State.observable public dayOfWeek: DayOfWeek = null;
    @State.observable public isEvenWeek?: boolean = null;
    @State.observable public weekOfMonth?: WeekOfMonth = null;

    @State.action public setRecurrenceType(value: RecurrenceType) {
        this.recurrenceType = value;
    }

    @State.action public setWeekOfMonth(value: WeekOfMonth) {
        this.weekOfMonth = value;
    }

    @State.action public setDayOfWeek(value: DayOfWeek) {
        this.dayOfWeek = value;
    }

    @State.action public setEvenWeek(value: boolean) {
        this.isEvenWeek = value;
    }
}