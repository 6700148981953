/* auto-inject-disable */
import * as Proxy from "@AssecoMedPlugin/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import { GetTypeNameWithPrefix } from "@AssecoMedPlugin/PluginHelper";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import StatusChangeReasonId from "@Primitives/StatusChangeReasonId.g";

@Di.injectable()
export default class TelemedicineScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject(GetTypeNameWithPrefix("ICareClient")) private readonly apiClient: Proxy.ICareClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("IFormEngineReferenceDataStore") private formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("ValidationProblemParameterMapperService") private validationProblemParameterMapperService: ValidationProblemParameterMapperService
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public createTelemedicineAsync(episodeOfCareId: EpisodeOfCareId, appointmentScheduleEntryId: AppointmentScheduleEntryId) {
        return this.executeOperationAsync<CareActivityId, Proxy.CreateTelemedicine2CommandResponse>(
            () => this.apiClient.createTelemedicine2CommandAsync(CreateRequestId(),
                new Proxy.CreateTelemedicine2ControllerDto({
                    episodeOfCareId: episodeOfCareId,
                    appointmentScheduleEntryId: appointmentScheduleEntryId
                })),
            response => response.careActivityId
        );
    }

    @State.bound
    public deleteTelemedicineAsync(careActivityId: CareActivityId, statusChangeReasonId: StatusChangeReasonId, additionalText: string) {
        return this.executeOperationAsync<boolean, Proxy.DeleteTelemedicine2CommandResponse>(
            () => this.apiClient.deleteTelemedicine2CommandAsync(CreateRequestId(),
                new Proxy.DeleteTelemedicine2ControllerDto({
                    careActivityId: careActivityId,
                    statusChangeReasonId: statusChangeReasonId,
                    additionalText: additionalText
                })),
            response => true
        );
    }
}