import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import NewBinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/Creation/NewBinaryDocument";
import TemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TemplateBasedDocument";
import PatientId from "@Primitives/PatientId.g";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import TemplateId from "@Primitives/TemplateId.g";
import Encoding from "@Toolkit/CommonWeb/Encoding";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import LargeDataToken from "@Primitives/LargeDataToken.g";
import GetDocumentsForPatientQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/GetDocumentsForPatientQueryOrderingFields.g";
import GetDocumentsForCareActivityQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/GetDocumentsForCareActivityQueryOrderingFields.g";
import * as WebAppBackendProxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";

export function mapPagingAndOrderings(paging: IPagingState, ordering: IOrderingState) {
    const orderingFieldName = GetDocumentsForCareActivityQueryOrderingFields[`${ordering?.columnId}`];
    return new Proxy.QueryPagingAndOrderingsOfGetDocumentsForCareActivityQueryOrderingFields({
        orderings: ordering ? [new Proxy.QueryOrderingOfGetDocumentsForCareActivityQueryOrderingFields({
            fieldName: orderingFieldName,
            direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending
        })] : null,
        paging: paging ? new Proxy.QueryPaging({
            pageIndex: paging.currentPage,
            pageSize: paging.pageSize
        }) : null
    });
}

export function getSingleDocumentDto(careActivityId: CareActivityId, documentTypeCode?: string, isPrimary?: boolean, isAutomatic?: boolean) {
    return new Proxy.GetSingleDocumentForCareActivityControllerDto({
        careActivityId: careActivityId,
        documentTypeCode: documentTypeCode,
        isAutomatic: isAutomatic,
        isPrimary: isPrimary
    });
}

export function getDocumentsForCareActivityDto(careActivityId: CareActivityId, ordering: IOrderingState, paging: IPagingState, showDeleted: boolean) {
    const pagingAndOrderings = mapPagingAndOrderings(paging, ordering);
    return new Proxy.GetDocumentsForCareActivityControllerDto({
        careActivityId,
        showDeleted,
        pagingAndOrderings
    });
}

export async function getBinaryDocumentDtoAsync(binaryDocument: NewBinaryDocument) {
    return new WebAppBackendProxy.BinaryDocumentDto({
        content: await binaryDocument.getContentAsBase64Async(),
        contentType: binaryDocument.contentType,
        documentTypeId: binaryDocument.type,
        name: binaryDocument.name,
        description: binaryDocument.description
    });
}

export function getTemplateBasedDocumentDto(templateBasedDocument: TemplateBasedDocument) {
    return new WebAppBackendProxy.TemplateBasedDocumentDto({
        documentTypeId: templateBasedDocument.info.documentTypeId,
        templateId: templateBasedDocument.templateId,
        templateContent: templateBasedDocument.templateContentToken,
        name: templateBasedDocument.info.name,
        content: templateBasedDocument.documentFile.getBase64(),
        description: templateBasedDocument.description,
    });
}

export function getSaveTemplatePermissionCheckDocumentDto(careActivityId: CareActivityId) {
    const jsonString = JSON.stringify("testContent", null, "    ");
    const contentBytes = Encoding.UTF8.getBytes(jsonString);
    const contentBase64 = Base64Converter.fromByteArray(contentBytes);

    return  new WebAppBackendProxy.SaveNewCareActivityRelatedTemplateBasedDocumentControllerDto({
        careActivityId: careActivityId,
        templateBasedDocumentDto: new WebAppBackendProxy.TemplateBasedDocumentDto({
            content: contentBase64,
            documentTypeId: new DocumentTypeId("-1"),
            templateId: new TemplateId("-1"),
            templateContent: new LargeDataToken("-1"),
            name: "testName",
            description: "testDescription"
        })
    });
}

export function mapPatientPagingAndOrderings(paging: IPagingState, ordering: IOrderingState) {
    const orderingFieldName = GetDocumentsForPatientQueryOrderingFields[`${ordering?.columnId}`];
    return new Proxy.QueryPagingAndOrderingsOfGetDocumentsForPatientQueryOrderingFields({
        orderings: ordering ? [new Proxy.QueryOrderingOfGetDocumentsForPatientQueryOrderingFields({
            fieldName: orderingFieldName,
            direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending
        })] : null,
        paging: paging ? new Proxy.QueryPaging({
            pageIndex: paging.currentPage,
            pageSize: paging.pageSize
        }) : null
    });
}

export function getDocumentsForPatientDto(patientId: PatientId, isCareActivityRelated: boolean, ordering: IOrderingState, paging: IPagingState, showDeleted: boolean, dateRange?: LocalDateRange) {
    const pagingAndOrderings = mapPatientPagingAndOrderings(paging, ordering);
    return new Proxy.GetDocumentsForPatientControllerDto({
        patientId,
        dateRange,
        isCareActivityRelated,
        showDeleted,
        pagingAndOrderings
    });
}