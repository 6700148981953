import { WorkflowValidation } from "@CommonControls/WorkflowStepsIndicator/WorkflowValidation";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class WorkflowStepStore {
    @State.observable.ref public title: string;
    @State.observable.ref public disabled: boolean;
    @State.observable.ref public validation: WorkflowValidation;
    @State.observable.ref public tooltipContent: string;

    constructor(
        public readonly id: any,
        title: string,
        disabled?: boolean,
        validation?: WorkflowValidation,
        tooltipContent?: string
    ) {
        this.title = title;
        this.disabled = disabled;
        this.validation = validation === null || validation === undefined ? WorkflowValidation.NotValidated : validation;
        this.tooltipContent = tooltipContent;
    }

    @State.action.bound
    public setValidation(validation: WorkflowValidation) {
        this.validation = validation;
    }

    @State.action.bound
    public setTooltipContent(content: string) {
        this.tooltipContent = content;
    }
}