// tslint:disable:variable-name
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import CareActivityGuidanceHeaderStore from "./CareActivityGuidanceHeaderStore";

const { 
    ContextComponent: CareActivityGuidanceHeaderStoreContext, 
    StoreProvider: CareActivityGuidanceHeaderStoreProvider, 
    useStore: useCareActivityGuidanceHeaderStore,
    provideStore: provideCareActivityGuidanceHeaderStore,
} = createPanelStoreProvider(CareActivityGuidanceHeaderStore);

export {
    CareActivityGuidanceHeaderStoreContext,
    CareActivityGuidanceHeaderStoreProvider,
    useCareActivityGuidanceHeaderStore,
    provideCareActivityGuidanceHeaderStore
};
