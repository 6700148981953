import React from "react";
import PermissionCheckContextAdapter from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import NewMenuItemSection from "./NewMenuItemSection";

export interface INewMenuItemSectionWrapperProps {
    _permissionDenied?: boolean;
    onNewItemClick?: () => void;
    newItemText?: string;
    permissionCheckOperationNames?: string;
}


class NewMenuItemSectionWrapper extends React.Component<INewMenuItemSectionWrapperProps> {
    public render() {
        if(!this.props.onNewItemClick || this.props._permissionDenied) {
             return null;
        }

        return (
            <NewMenuItemSection onNewItemClick={this.props.onNewItemClick} newItemText={this.props.newItemText} />
        );
    }
}

export default connect(
    NewMenuItemSectionWrapper,
    new PermissionCheckContextAdapter()
);
