import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiMedicalConditionHistoryItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/EhiMedicalConditionHistoryItem";
import IEhiMedicalConditionVersionPanelExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IEhiMedicalConditionVersionPanelExtensionPointProps";
import EhiSyncStatusLabel from "@HunEHealthInfrastructurePlugin/Common/Components/EhiSyncStatusLabel";
import EhiSyncStatus from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiSyncStatus";

interface IEhiMedicalConditionVersionPanelDependencies {
}

interface IEhiMedicalConditionVersionPanelProps extends IEhiMedicalConditionVersionPanelExtensionPointProps {
    _dependencies?: IEhiMedicalConditionVersionPanelDependencies;
}

@State.observer
export class EhiMedicalConditionVersionPanel extends React.Component<IEhiMedicalConditionVersionPanelProps> {

    @State.computed
    private get historyItem() {
        return EhiMedicalConditionHistoryItem.createFromExtensionDto(this.props.store?.extensionData);
    }

    @State.computed
    private get shouldRender() {
        return !this.props.store?.isNew;
    }

    @State.computed
    private get needsUpload() {
        return this.historyItem && isNullOrUndefined(this.historyItem.ehiVersion);
    }

    @State.computed
    private get ehiSyncStatus() {
        return this.needsUpload ? EhiSyncStatus.NotInSync : EhiSyncStatus.InSync;
    }

    public render() {
        return (
            <Ui.Flex.Item style={{ paddingTop: 8, paddingLeft: 60 }} xs={2}>
                {this.shouldRender &&
                    <EhiSyncStatusLabel value={this.ehiSyncStatus} size="extra large" /> || <></>
                }
            </Ui.Flex.Item>
        );
    }
}

export default connect(
    EhiMedicalConditionVersionPanel,
    new DependencyAdapter<IEhiMedicalConditionVersionPanelProps, IEhiMedicalConditionVersionPanelDependencies>(c => {
        return {
        };
    })
);