import IFormLayoutBlockStore from "@Toolkit/FormEngine/Model/Layout/IFormLayoutBlockStore";
import FormLayoutDataElementEditorStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutDataElementEditorStore";
import IEditableLayoutStore from "./IEditableLayoutStore";

export default class FormLayoutFragment implements IEditableLayoutStore {
    constructor(
        public readonly id: string,
        public readonly content: IFormLayoutBlockStore[] | FormLayoutDataElementEditorStore
    ) {

    }
}