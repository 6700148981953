import React from "react";
import config from "@Config";
import Modal from "@CommonControls/Modal";
import TraceLogPanel from "@HisPlatform/Components/Panels/TraceLogPanel/TraceLogPanel";
import { Button } from "@CommonControls";
import FeedbackDialog from "@HisPlatform/Components/Panels/FeedbackDialog/FeedbackDialog";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import StaticWebAppResources from "@StaticResources";
import DialogContainer from "@Toolkit/ReactClient/Services/Implementation/DialogService/DialogContainer";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { mapStackAsync } from "@Toolkit/CommonWeb/StackTraceMapper";

interface IErrorModalProps {
    error: Error;
    openFeedbackDialogEvent?: TypedEvent;
    onOpenFeedback?: () => void;
}

@State.observer
export default class ErrorModal extends React.PureComponent<IErrorModalProps> {

    private stackAsync = State.promisedComputed("Loading stacktrace...", async () => {
        if (this.props.error) {
            return await mapStackAsync(this.props.error);
        }
        return "N/A";
    });

    private renderMessage() {

        if (config.developerMode) {
            return (
                <>
                    <p>{StaticWebAppResources.Common.Messages.Informing}</p>
                    {this.props.openFeedbackDialogEvent && (
                        <>
                            <Button iconName="feedback" visualStyle="primary" onClick={this.props.onOpenFeedback} text={StaticWebAppResources.Common.Messages.SendFeedback} disabled={this.stackAsync.busy} automationId="openFeedbackButton" />
                            <FeedbackDialog openEvent={this.props.openFeedbackDialogEvent} isBugOnly />
                        </>
                    )}
                    <TraceLogPanel />
                    <pre style={{ overflow: "auto" }}>
                        <h2>{StaticWebAppResources.Common.Messages.TechnicalInformations}</h2>
                        <b>{StaticWebAppResources.Common.Messages.Type} {this.props.error.name}</b><br />
                        <b>{StaticWebAppResources.Common.Messages.Message} {this.props.error.message}</b><br />
                        {this.stackAsync.get()}
                    </pre>
                </>
            );
        } else {

            return (
                <p>The application has encountered an unknown error.
                It doesn't appear to have affected your data, but our technical staff have been automatically notified.</p>
            );

        }
    }

    public render() {
        return (
            <Modal title={StaticWebAppResources.Common.Messages.Header} size="compact" isOpen={true} automationId="applicationErrorModal">
                <Modal.Body>
                    {this.renderMessage()}
                </Modal.Body>
                <DialogContainer />
            </Modal>
        );
    }
}