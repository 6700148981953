import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import CareActivityId from "@Primitives/CareActivityId.g";
import ResourceId from "@Primitives/ResourceId.g";
import IStatusChangeBlockingReason from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/IStatusChangeBlockingReason";
import StateChangeBlockingData from "@Primitives/StateChangeBlockingData.g";

export interface ICareActivityStatusChangeNotPossibleDialogParams extends IModalParams {
    careActivityId: CareActivityId;
    actionTitleResourceId: ResourceId;
    activityReferenceResourceId: ResourceId;
    reasons: IStatusChangeBlockingReason[];
}

export interface ICareActivityStatusChangeNotPossibleDialogResult {
    navigateTo: StateChangeBlockingData;
}

export default class CareActivityStatusChangeNotPossibleDialogParams implements ICareActivityStatusChangeNotPossibleDialogParams {
    public static type = "CareActivityStatusChangeNotPossibleDialog";
    public get type() { return CareActivityStatusChangeNotPossibleDialogParams.type; }

    constructor(
        public readonly careActivityId: CareActivityId,
        public readonly activityReferenceResourceId: ResourceId,
        public readonly reasons: IStatusChangeBlockingReason[],
        public readonly actionTitleResourceId: ResourceId
    ) {}
}