exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Header_group_1sjSN {\n  display: inline;\n  padding: 5px;\n}\n.Header_inline_3rLaw {\n  display: inline;\n}\n.Header_title_2rqPt {\n  font-family: TitilliumWebBold;\n  color: #00b4ec;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: -0.4px;\n  text-transform: uppercase;\n  display: inline;\n}\n.Header_alternateColor_obACI {\n  color: #004358;\n  text-transform: none;\n  font-family: PragatiNarrow;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 15px;\n  padding-left: 15px;\n}\n.Header_separator_2J5Dv {\n  color: #004358;\n  text-transform: none;\n  font-family: PragatiNarrow;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 15px;\n  padding-left: 15px;\n  margin: 0px 15px;\n}\n", ""]);

// exports
exports.locals = {
	"group": "Header_group_1sjSN",
	"inline": "Header_inline_3rLaw",
	"title": "Header_title_2rqPt",
	"alternateColor": "Header_alternateColor_obACI",
	"separator": "Header_separator_2J5Dv"
};