import SingleLayout from "@CommonControls/Layout/SingleLayout";
import { getUseCaseAsUrlParam, parseUseCaseStateFromUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import standaloneRouteDefinitions, { IFamilyDoctorDocumentStandaloneRouteParams } from "@HunEHealthInfrastructurePlugin/UseCases/FamilyDoctorDocumentUseCase/FamilyDoctorDocumentRoutes";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";
import FamilyDoctorDocumentId from "@Primitives/FamilyDoctorDocumentId.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import FamilyDoctorDocumentListPanel from "./FamilyDoctorDocumentListPanel";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";

interface IFamilyDoctorDocumentPageDependencies {
}
interface IFamilyDoctorDocumentPageProps extends IRoutingFrameContentProps {
    _dependencies?: IFamilyDoctorDocumentPageDependencies;
    _careActivityId?: CareActivityId;
}
class FamilyDoctorDocumentPage extends React.Component<IFamilyDoctorDocumentPageProps> {

    @State.computed private get routeParams(): IFamilyDoctorDocumentStandaloneRouteParams {
        return this.props.routingController.currentRoute.parameters as IFamilyDoctorDocumentStandaloneRouteParams;
    }

    @State.computed private get selectedFamilyDoctorDocument(): FamilyDoctorDocumentId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments.find(a => a.value instanceof FamilyDoctorDocumentId).value;
        }

        if (this.useCaseState?.useCase.value === HunEHealthInfrastructureUseCases.createFamilyDoctorDocument) {
            return new FamilyDoctorDocumentId("new");
        }
        return null;
    }

    @State.bound
    private setUseCaseState(selectedFamilyDoctorDocument: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.replace(
            standaloneRouteDefinitions.familyDoctorDocument.makeRoute({
                careActivityId: this.props._careActivityId.value,
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            })
        );
    }

    @State.computed private get useCaseState() {
        const useCaseState = this.routeParams?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.bound
    private navigateToFamilyDoctorDocument(familyDoctorDocumentId: FamilyDoctorDocumentId) {
        this.props.routingController.replace(
            standaloneRouteDefinitions.familyDoctorDocument.makeRoute({
                careActivityId: this.props._careActivityId.value,
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(HunEHealthInfrastructureUseCases.modifyFamilyDoctorDocument),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(familyDoctorDocumentId, "familyDoctorDocumentId")])
            })
        );
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(
            standaloneRouteDefinitions.familyDoctorDocument.makeRoute({
                careActivityId: this.props._careActivityId.value,
                useCase: getUseCaseAsUrlParam(
                    null,
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument(new FamilyDoctorDocumentId("null"), "familyDoctorDocumentId")])
            })
        );
    }

    public render() {
        return (
            <SingleLayout>
                <FamilyDoctorDocumentListPanel
                    onChange={this.setUseCaseState}
                    useCaseState={this.useCaseState}
                    selectedRowId={this.selectedFamilyDoctorDocument?.value}
                    onSelectedFamilyDoctorDocumentIdChange={this.navigateToFamilyDoctorDocument}
                    onBack={this.goBack}
                    onCloseDetail={this.setClosedState}
                />
            </SingleLayout>
        );
    }
}

export default connect(
    FamilyDoctorDocumentPage,
    new DependencyAdapter<IFamilyDoctorDocumentPageProps, IFamilyDoctorDocumentPageDependencies>(() => {
        return {
        };
    }),
    new CareActivityContextAdapter<IFamilyDoctorDocumentPageProps>(c => ({
        _careActivityId: c.careActivityId
    }))
);