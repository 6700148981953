import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";

export default class ExternalCareLocation {
    constructor(
        public readonly externalLocationSelector: EntityVersionSelector<ExternalLocationId>
    ) { }

    public equals(other: ExternalCareLocation): boolean {
        return EntityVersionSelector.areEquals(this.externalLocationSelector, other && other.externalLocationSelector);
    }

    public getHashCode() {
        return this.externalLocationSelector && this.externalLocationSelector.getHashCode();
    }
}