import React, { useContext } from "react";
import { Item } from "react-contexify";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { BusinessErrorHandlerContext, IBusinessErrorHandler } from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import IPermissionCheckContextModel from "@Toolkit/ReactClient/Components/PermissionCheckContext/IPermissionCheckContextModel";
import PermissionCheckReactContext from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckReactContext";
import PermissionCheckContext from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContext";
import LoadingContext from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingContext";
import LoadingContextStore from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingContextStore";

interface IMenuItemProps {
    onClick?: () => void;
    onClickAsync?: () => Promise<void>;
    children?: React.ReactNode;
    disabled?: boolean;
    _businessErrorHandler?: IBusinessErrorHandler;
    _loadingContextStore?: LoadingContextStore;
    permissionCheckOperationNames?: string;
    permissionDeniedStyle?: "invisible" | "disabled";
}

class MenuItemCore extends React.Component<IMenuItemProps> {

    @State.observable private isLoading = false;

    @State.bound private unAuthorized(checkedPermissions: IPermissionCheckContextModel[]) {
        return this.props.permissionCheckOperationNames &&
        checkedPermissions.some(permission => !permission.isAuthorized &&
            this.props.permissionCheckOperationNames.includes(permission.operationName));
    }

    private renderCore(checkedPermissions: IPermissionCheckContextModel[]) {
        const disabled = this.props.disabled || this.unAuthorized(checkedPermissions);
        return (
            <Item onClick={this.clickHandler} disabled={disabled} className={combineClasses("__context-menu-item", disabled && "__disabled")}>
                {this.props.children}
            </Item>
        );
    }

    public render() {
        return (
            <PermissionCheckReactContext.Consumer>
                {
                    (permissionCheckedContext: PermissionCheckContext) =>
                        <State.Observer>
                            {() => this.renderCore(permissionCheckedContext?.checkedPermissions ?? [])}
                        </State.Observer>
                }
            </PermissionCheckReactContext.Consumer>
        );
    }

    @State.bound
    private clickHandler() {
        if (this.props.onClickAsync && !this.isLoading) {
            this.setLoadingState();
            dispatchAsyncErrors(
                this.props.onClickAsync()
                    .then(() => this.setLoadingState(false))
                    .finally(() => {
                        this.setLoadingState(false);
                    }), this);
        } else {
            this.props.onClick();
        }
    }

    @State.action
    private setLoadingState(loading = true) {
        this.isLoading = loading;
    }
}


const MenuItem: React.FC<IMenuItemProps> = props => {
    const businessErrorHandler = useContext(BusinessErrorHandlerContext);
    const loadingContextStore = useContext(LoadingContext);

    return (
        <MenuItemCore
            {...props}
            _businessErrorHandler={businessErrorHandler}
            _loadingContextStore={loadingContextStore}
        />
    );
};

export default MenuItem;