import React from "react";
import * as Ui from "@CommonControls";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import PrescriptionMonth from "@Primitives/PrescriptionMonth";

interface IPrescriptionMonthSelectBoxProps extends ISelectBoxBaseProps {
    value?: PrescriptionMonth;
    onChange?: (newValue: PrescriptionMonth) => void;
}


const PrescriptionMonthSelectBox: React.FC<IPrescriptionMonthSelectBoxProps> = props => (
    <Ui.UniversalEnumSelector
        {...props}
        enumName={"PrescriptionMonth"}
        enumOrigin="server"
        enumType={PrescriptionMonth}
        displayMode="selectBox"
        clearable
    />
);

export default PrescriptionMonthSelectBox;