import React from "react";
import * as Ui from "@CommonControls";
import { ContextAwareModal } from "@HisPlatformControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import StaticHunEHealthInfrastructureEesztIntegrationResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/EesztIntegration/StaticResources/StaticHunEHealthInfrastructureEesztIntegrationResources";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import EesztIntegrationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/EesztIntegration/ApplicationLogic/ApiAdapter/EesztIntegrationApiAdapter";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ModalButton from "@CommonControls/Modal/ModalButton";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface ICommunicationLogDownloaderModalDependencies {
    eesztIntegrationApiAdapter: EesztIntegrationApiAdapter;
    fileSaverService: IFileSaverService;
    notificationService: INotificationService;
}

interface ICommunicationLogDownloaderModalProps extends IModalComponentParams {
    _dependencies?: ICommunicationLogDownloaderModalDependencies;
}

@State.observer
export class CommunicationLogDownloaderModal extends React.Component<ICommunicationLogDownloaderModalProps> {
    private readonly panelResources = StaticHunEHealthInfrastructureEesztIntegrationResources.CommunicationLogs.DownloadPanel;

    private get eesztIntegrationApiAdapter() { return this.props._dependencies.eesztIntegrationApiAdapter; }
    private get fileSaverService() { return this.props._dependencies.fileSaverService; }
    private get notificationService() { return this.props._dependencies.notificationService; }

    @State.observable.ref private dateRange: LocalDateRange = new LocalDateRange(LocalDate.today(), LocalDate.today());

    public render() {
        return (
            <>
                <ContextAwareModal
                    title={this.panelResources.Title}
                    onClose={this.props.onClose as () => void}
                    size="tiny"
                    isOpen
                    closeOnOutsideClick
                    closeOnEscape
                    closeButton>
                    <Ui.Modal.Body>
                        <div style={{marginBottom: 8}}>
                            <Ui.Flex>
                                <Ui.Flex.Item grow>
                                    <Ui.DateRangePicker
                                        label={this.panelResources.Labels.LogDateRange}
                                        value={this.dateRange}
                                        clearable={false}
                                        onChange={this.setDateRange}
                                        required
                                        automationId="communicationLogDownloaderModalDateRangePicker"/>
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </div>
                    </Ui.Modal.Body>
                    <Ui.Modal.Footer>
                        <Ui.Flex xsJustify="end">
                            <Ui.Flex.Item>
                                <ModalButton
                                    text={this.panelResources.Labels.CloseButton}
                                    closeModalOnClick
                                    automationId="communicationLogDownloaderModalCloseButton"/>
                            </Ui.Flex.Item>
                            <Ui.Flex.Item>
                                <ModalButton
                                    text={this.panelResources.Labels.DownloadButton}
                                    visualStyle="primary"
                                    onClickAsync={this.downloadLogAsync}
                                    automationId="communicationLogDownloaderModalDateRangePicker"/>
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Modal.Footer>
                </ContextAwareModal>
            </>
        );
    }

    @State.action.bound
    private setDateRange(newValue: LocalDateRange) {
        this.dateRange = newValue;
    }

    @State.bound
    private async downloadLogAsync() {
        if (!this.dateRange) {
            this.notificationService.error(this.panelResources.Errors.InvalidDateRange);
            return;
        }

        const fileData = await this.eesztIntegrationApiAdapter.getCommunicationLogsFileAsync(this.dateRange.from, this.dateRange.to);
        this.fileSaverService.saveAs(
            new Blob([fileData.value.content], { type: fileData.value.mediaType }),
            fileData.value.fileName
        );
    }
}

export default connect(
    CommunicationLogDownloaderModal,
    new DependencyAdapter<ICommunicationLogDownloaderModalProps, ICommunicationLogDownloaderModalDependencies>( c => ({
        eesztIntegrationApiAdapter: c.resolve<EesztIntegrationApiAdapter>("EesztIntegrationApiAdapter"),
        fileSaverService: c.resolve<IFileSaverService>("IFileSaverService"),
        notificationService: c.resolve<INotificationService>("INotificationService")
    }))
);
