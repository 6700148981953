// #region imports
import React from "react";
import OutpatientHeaderView, { IOutpatientHeaderViewProps } from "@HisPlatformControls/OutpatientHeader/OutpatientHeaderView";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import PatientRegisterReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/PatientRegisterReferenceDataStore";
import PatientAllergyIntoleranceId from "@Primitives/PatientAllergyIntoleranceId.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { ICareActivityPaneProps } from "@HisPlatformControls/OutpatientHeader/CareActivityPane";
import { ICareActivityMenuProps } from "@HisPlatformControls/OutpatientHeader/CareActivityMenu";
import AllergyIntoleranceClinicalStatus from "@Primitives/AllergyIntoleranceClinicalStatus";
import AllergyIntoleranceCriticality from "@Primitives/AllergyIntoleranceCriticality";
import _ from "@HisPlatform/Common/Lodash";
import PatientRoutes from "@HisPlatform/Components/Pages/Patient/PatientRoutes";
import CareActivityId from "@Primitives/CareActivityId.g";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import CareActivityDischargeDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityDischargeData/CareActivityDischargeDataStore";
import CareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CareActivityStore";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PatientAllergyIntoleranceListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceListStore";
import PatientId from "@Primitives/PatientId.g";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import PatientAllergyIntoleranceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceStore";
import PatientAllergyIntoleranceHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceHistoryItem";
import { getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCases from "@Primitives/UseCases";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import CareActivityBaseData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareActivityBaseData";
import { ActionDispatcherAdapter } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";
import ShowPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientScreenAction.g";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import { ScreenNavigationContextAdapter, ScreenNavigationContextStore } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
// #endregion

interface IOutpatientHeaderDependencies {
    localizationService: ILocalizationService;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    patientRegisterReferenceDataStore: PatientRegisterReferenceDataStore;
    routingStore: GlobalRoutingStore;
    financeReferenceDataStore: FinanceReferenceDataStore;
    commonReferenceDataStore: CommonReferenceDataDataStore;
}

interface IOutpatientHeaderProps {
    _dependencies?: IOutpatientHeaderDependencies;
    _careActivityId?: CareActivityId;
    _careActivity?: CareActivityStore;
    _careActivityBaseData?: CareActivityBaseData;
    _careActivityDischargeData?: CareActivityDischargeDataStore;
    _patientId?: PatientId;
    _patient?: PatientAdministrativeData;
    _patientAllergyIntoleranceList?: PatientAllergyIntoleranceListStore;
    _patientUnauthorizedAccess?: boolean;
    _actionDispatcher?: IActionDispatcher;
    _screenNavigationContext?: ScreenNavigationContextStore;
    _modalService?: IModalService;

    showReducedData?: boolean;
    showAsHeader?: boolean;
    showEditPatientButton?: boolean;
    isInModal?: boolean;
}

@State.observer
class OutpatientHeader extends React.Component<IOutpatientHeaderProps> {

    public static defaultProps: Partial<IOutpatientHeaderProps> = {
        showEditPatientButton: true
    };

    // #region dependencies

    private get organizationReferenceDataStore() {
        return this.props._dependencies.organizationReferenceDataStore;
    }

    private get routingStore() {
        return this.props._dependencies.routingStore;
    }

    // #endregion

    @State.computed private get isPatientDataAvailable() {
        return !!this.props._patientId;
    }

    @State.computed private get isCareActivityDataAvailable() {
        return !!this.props._careActivityId;
    }

    @State.computed
    private get patientAllergies() {

        if (this.props._patient.isNew) {
            return null;
        }

        const filteredList = this.props._patientAllergyIntoleranceList?.patientAllergyIntolerances
            .filter(x => (x.currentHistoryItem as PatientAllergyIntoleranceHistoryItem).clinicalStatus === AllergyIntoleranceClinicalStatus.Active);

        const orderMap = {
            [AllergyIntoleranceCriticality.High]: 0,
            [AllergyIntoleranceCriticality.UnableToAssess]: 1,
            [AllergyIntoleranceCriticality.Low]: 2,
        };

        const orderedList = _.sortBy(filteredList, (i: PatientAllergyIntoleranceStore) => orderMap[(i.currentHistoryItem as PatientAllergyIntoleranceHistoryItem).criticality]);

        return orderedList;
    }

    @State.bound
    private editPatient() {
        this.props._actionDispatcher.dispatchAsync(new ShowPatientScreenAction(ScreenDisplayMode.Full, this.props._patientId, null),
            { navigationContext: this.props._screenNavigationContext, modalService: this.props._modalService });
    }

    @State.bound
    private navigateToAllergy(patientAllergyIntoleranceId: PatientAllergyIntoleranceId) {
        this.routingStore.push(PatientRoutes.patientAllergyIntolerance.makeRoute({
            mode: "read-write",
            patientId: this.props._patientId?.value,
            appointmentId: "null",
            useCase: getUseCaseAsUrlParam(
                new UseCaseIdentifier(UseCases.patientAllergyIntoleranceDetail),
                UseCaseDisplayMode.MasterDetail,
                [new UseCaseArgument(patientAllergyIntoleranceId, "patientAllergyIntoleranceId")])
        }));
    }

    public render() {

        const isPatientDataLoading = !this.props._patient;
        const isCareActivityDataLoading = !this.props._careActivity;

        const viewProps: IOutpatientHeaderViewProps & ICareActivityPaneProps & ICareActivityMenuProps = {
            isPatientDataLoading,
            isCareActivityDataLoading
        } as IOutpatientHeaderViewProps & ICareActivityPaneProps & ICareActivityMenuProps;

        if (!isPatientDataLoading && this.isPatientDataAvailable) {
            viewProps.patient = this.props._patient;

            viewProps.patientAllergies = this.patientAllergies;
            viewProps.showPatientEditButton = !this.props.showReducedData && !this.props._patient.isNew && this.props.showEditPatientButton;
        }

        if (!isCareActivityDataLoading && this.isCareActivityDataAvailable) {
            viewProps.dischargedAt = this.props._careActivityDischargeData?.dischargedAt;
            viewProps.careIdentifier = this.props._careActivityBaseData?.primaryCareIdentifier;
            viewProps.wentUnderCareAt = this.props._careActivityBaseData?.wentUnderCareAt;
            viewProps.doctorId = this.props._careActivityBaseData?.primaryParticipant;
            viewProps.careActivityState = this.props._careActivity?.state;
            viewProps.pointOfCareId = this.props._careActivity?.pointOfCareId;
            viewProps.documentTypeId = this.props._careActivity?.finalDocumentTypeId;
            viewProps.careActivityId = this.props._careActivityId;
        }

        const menuProps = {
            careActivityId: this.props._careActivityId,
            documentTypeId: viewProps.documentTypeId,
            isStandaloneMode: true,
            routingStore: this.routingStore,
        } as ICareActivityMenuProps;

        return (
            <>
                <OutpatientHeaderView
                    {...viewProps}
                    menuProps={menuProps}
                    routingStore={this.routingStore}
                    careActivityId={this.props._careActivityId}
                    patientId={this.props._patientId}
                    isStandaloneMode={true}

                    isPatientDataLoading={isPatientDataLoading}
                    isPatientDataAvailable={this.isPatientDataAvailable}
                    isCareActivityDataLoading={isCareActivityDataLoading}
                    isCareActivityDataAvailable={this.isCareActivityDataAvailable}

                    onEditPatient={this.editPatient}
                    onOpenAllergy={this.navigateToAllergy}
                    showCareDetails={!this.props.showReducedData}
                    showCareMenu={!this.props.showReducedData}

                    showAsHeader={this.props.showAsHeader}
                    isInModal={this.props.isInModal}

                    isUnauthorized={this.props._patientUnauthorizedAccess}
                />
            </>
        );
    }
}

export default connect(
    withHisErrorBoundary(OutpatientHeader),
    new DependencyAdapter<IOutpatientHeaderProps, IOutpatientHeaderDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
        routingStore: c.resolve("GlobalRoutingStore"),
        patientRegisterReferenceDataStore: c.resolve("PatientRegisterReferenceDataStore"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        financeReferenceDataStore: c.resolve("FinanceReferenceDataStore"),
        commonReferenceDataStore: c.resolve("CommonReferenceDataDataStore")
    })),
    new CareActivityContextAdapter<IOutpatientHeaderProps>(c => ({
        _careActivity: c?.careActivity,
        _careActivityBaseData: c?.baseData,
        _careActivityDischargeData: c?.dischargeData,
        _careActivityId: c?.careActivityId
    })),
    new PatientContextAdapter<IOutpatientHeaderProps>(c => ({
        _patientId: c.patientId,
        _patient: c.patient,
        _patientAllergyIntoleranceList: c.patientAllergyIntoleranceList,
        _patientUnauthorizedAccess: c.isUnauthorizedAccess
    })),
    new ActionDispatcherAdapter(),
    new ScreenNavigationContextAdapter(),
    new HisModalServiceAdapter()
);
