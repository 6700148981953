import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";
import ContainerContext from "@Toolkit/ReactClient/Components/DependencyInjection/ContainerContext";
import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import CompositeFormExtension from "./CompositeFormExtension";
import IFormExtensionRegistry from "@PluginInterface/FormExtension/IFormExtensionRegistry";

interface IReadOnlyState<TFormData> {
    extensions: Array<IFormExtension<TFormData>>;
    compositeExtension: IFormExtension<TFormData>;
}

export default class FormExtensionRegistryAdapter<TProps, TFormData = any> implements IComponentAdapter<TProps, IReadOnlyState<TFormData>> {
    public get usedContexts(): Array<React.Context<any>> {
        return [ContainerContext];
    }

    constructor(
        private readonly formName: string,
        private readonly mapToProps: (props: TProps, formExtensions: Array<IFormExtension<TFormData>>, compositeExtension: IFormExtension<TFormData>) => Partial<TProps> =
            (_, __, formExtension) => ({ _formExtension: formExtension } as any)) {

    }

    public getMemoizedState(_props: TProps, contextValues?: Map<React.Context<any>, any>): IReadOnlyState<TFormData> {
        const container: IContainerService = contextValues.get(ContainerContext);
        const registry = container.resolve<IFormExtensionRegistry>("IFormExtensionRegistry");
        const extensions = registry.resolveAll(container, this.formName);

        return {
            extensions,
            compositeExtension: new CompositeFormExtension<TFormData>(extensions)
        };
    }

    public getMappedProps(props: TProps, _contextValues: Map<React.Context<any>, any>, readonlyState: IReadOnlyState<TFormData>): Partial<TProps> {
        return this.mapToProps(props, readonlyState.extensions, readonlyState.compositeExtension);
    }

    public dispose(_props: TProps, _contextValues: Map<React.Context<any>, any>, readonlyState: IReadOnlyState<TFormData>) {
        readonlyState.compositeExtension.dispose?.();
    }
}