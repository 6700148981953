import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as HisUi from "@HisPlatformControls";
import Styles from "./OutpatientTreatmentPage.less";
import RoutingFrame from "@Toolkit/ReactClient/Routing/RoutingFrame";
import OutpatientTreatmentRoutes from "@HisPlatform/Components/Pages/OutpatientTreatment/OutpatientTreatmentRoutes";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import ServiceRequestMainPanel from "@HisPlatform/Components/Panels/ServiceRequestPanel/ServiceRequestMainPanel";
import { ToolbarLayout, ScrollView } from "@CommonControls";
import DashboardLayout from "@HisPlatformControls/DashboardLayout/DashboardLayout";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import CareActivityContextProvider from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextProvider";
import CareActivityId from "@Primitives/CareActivityId.g";
import PatientContextProvider from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextProvider";
import ViewContextProvider from "@Toolkit/ReactClient/Components/ViewContext/ViewContextProvider";
import CareActivityGuidanceHeader from "@HisPlatform/Packages/Guidance/Components/CareActivityGuidanceHeader/CareActivityGuidanceHeader";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

interface IOutpatientTreatmentPageDependencies {
    useCaseRegistry: IUseCaseRegistry;
    applicationContext: ApplicationContext;
}

interface IOutpatientTreatmentPageProps extends IRoutingFrameContentProps {
    _dependencies?: IOutpatientTreatmentPageDependencies;
}

@State.observer
class OutpatientTreatmentPage extends React.Component<IOutpatientTreatmentPageProps> {

    private get useCaseRegistry() { return this.props._dependencies.useCaseRegistry; }
    private get applicationContext() { return this.props._dependencies.applicationContext; }

    @State.observable private showToolbar: boolean = true;

    private readonly routeDefinitions = {
        ...OutpatientTreatmentRoutes,
        ...this.useCaseRegistry.getStandaloneRouteDefinitions()
    };

    @State.computed.valueWrapper private get careActivityId() {
        return this.props.routingController.currentRoute.parameters.careActivityId &&
            new CareActivityId(this.props.routingController.currentRoute.parameters.careActivityId);
    }

    @State.computed private get currentActionDescriptor() {
        const adapter = this.useCaseRegistry.tryGetScreenAdapter(this.applicationContext.currentUseCaseIdentifier);
        return adapter?.(this.applicationContext.currentUseCaseIdentifier, []);
    }

    public render() {
        return (
            <CareActivityContextProvider careActivityId={this.careActivityId}>
                <PatientContextProvider>
                    <DashboardLayout>
                        <div className={Styles.workingArea}>
                            <ToolbarLayout
                                body={this.renderBody()}
                                topToolbar={(
                                    <CareActivityGuidanceHeader action={this.currentActionDescriptor?.action as any ?? null} screenState={null} />
                                )}
                                topToolbarHeight="auto"
                                //bottomToolbar={this.renderToolbar()}
                                //isBottomToolbarVisible={this.showToolbar}
                            />
                        </div>
                    </DashboardLayout>
                </PatientContextProvider>
            </CareActivityContextProvider>
        );
    }

    private renderToolbar() {
        return (
            <HisUi.HisPanelButtonPortal.Host style={{ width: "100%" }} />
        );
    }

    @State.bound
    private setRoute(newController: IRoutingController) {
        if (newController.currentRoute) {

            // special cases:
            if (newController.currentRoute.definition === OutpatientTreatmentRoutes.servicesRequests) {
                this.applicationContext.setCurrentUseCase(new UseCaseIdentifier("CareRegister_CreateServiceRequests"));
                this.showToolbar = false;
                return;
            }

            const useCase = this.useCaseRegistry.getUseCaseForRouteDefinition(newController.currentRoute.definition);
            if (useCase) {
                this.showToolbar = useCase.showToolbar;
                this.applicationContext.setCurrentUseCase(new UseCaseIdentifier(useCase.name));
                return;
            }
        }

        this.applicationContext.setCurrentUseCase(null);
    }

    // old: totalHeightLoss={this.showToolbar ? 192 : 142}
    private renderBody() {
        return (
            <HisUi.HisErrorBoundary>
                <LoadingBoundary>
                    <ScrollView>
                        <RoutingFrame
                            routeDefinitions={this.routeDefinitions}
                            fallbackRoute={OutpatientTreatmentRoutes.baseData}
                            parentRouteDefinition={ApplicationRoutes.careActivity}
                            onRoutingControllerChanged={this.setRoute}
                            >
                            <RoutingFrame.Case route={OutpatientTreatmentRoutes.servicesRequests} component={ServiceRequestMainPanel} />
                            {this.useCaseRegistry.renderStandaloneRoutingCases()}
                        </RoutingFrame>
                    </ScrollView>
                </LoadingBoundary>
            </HisUi.HisErrorBoundary>
        );
    }
}

export default connect(
    OutpatientTreatmentPage,
    new DependencyAdapter<IOutpatientTreatmentPageProps, IOutpatientTreatmentPageDependencies>(container => {
        return {
            useCaseRegistry: container.resolve("IUseCaseRegistry"),
            applicationContext: container.resolve("ApplicationContext")
        };
    })
);
