import React from "react";
import IPatientsCareActivityListConditionColumnExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientsCareActivityListCondtionColumnExtensionPointProps";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import CareActivitySource from "@Primitives/CareActivitySource.g";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ConditionColumn from "@HisPlatform/BoundedContexts/Care/Components/Controls/DataGridColumns/ConditionColumn";
import ConditionId from "@Primitives/ConditionId.g";
import EhiConditionCovidStatus from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiConditionCovidStatus";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";

@State.observer
export default class PatientsCareActivityListConditionColumnExtension extends React.Component<IPatientsCareActivityListConditionColumnExtensionPointProps> {
    public render() {
        return (
            <ConditionColumn
                header={this.props.header}
                displayOrder={this.props.displayOrder}
                width={this.props.width}
                dataGetter={this.conditionDataGetter}
                validOnGetter={this.validOnGetter}
                badgeRenderer={this.badgeRenderer}
                isVisible={this.props.isFullGrid}
            />
        );
    }
    
    private isCareActivityFromEhi(row: PatientsCareActivityStore) {
        return ValueWrapper.equals(row.careActivitySource, new CareActivitySource("HunEHealthInfrastructure"));
    }

    @State.bound
    private conditionDataGetter(row: PatientsCareActivityStore): string | ConditionId {
        if (this.isCareActivityFromEhi(row)) {
            return isNullOrUndefined(row.extensionData.ConditionId) ? row.extensionData.DiagnosisOfCare as string : ConditionId.fromJS(row.extensionData.ConditionId);
        } else {
            const conditionVersionSelector = row.diagnoses?.find(i => ValueWrapper.equals(i.use, DiagnosisRoleId.Discharge))?.conditionVersionSelector;
            return conditionVersionSelector?.id;
        }
    }

    @State.bound
    private validOnGetter(row: PatientsCareActivityStore) {
        if (this.isCareActivityFromEhi(row)) {
            return LocalDate.today();
        } else {
            const conditionVersionSelector = row.diagnoses?.find(i => ValueWrapper.equals(i.use, DiagnosisRoleId.Discharge))?.conditionVersionSelector;
            return conditionVersionSelector?.validOn ?? LocalDate.today();
        }
    }
    
    @State.bound
    private badgeRenderer(row: PatientsCareActivityStore) {
        if (this.isCareActivityFromEhi(row)) {
            const covidStatus = parseInt(row.extensionData.EhiConditionCovidStatus, 10) as EhiConditionCovidStatus;
            return covidStatus !== EhiConditionCovidStatus.NotApplicable && (
                <Ui.Icon
                    iconName={"covid"}
                    style={{
                        fill: covidStatus === EhiConditionCovidStatus.Positive ? "#CE5374" : "#21C313"
                    }}
                />
            );
        } else {
            return null;
        }
    }
}