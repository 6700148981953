import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/IOperationInfo";

export default class ExtensionController<TSaveParams = any> {
    private saveContentAsync: (params: TSaveParams) => Promise<IOperationInfo> = null;
    private checkDirty: () => boolean = null;

    public setSaveContentAsyncHook(save: (params: TSaveParams) => Promise<IOperationInfo>): IDisposable {
        this.saveContentAsync = save;
        return {
            dispose: () => {
                if (this.saveContentAsync === save) {
                    this.saveContentAsync = null;
                }
            }
        };
    }

    public setIsDirtyHook(isDirty: () => boolean): IDisposable {
        this.checkDirty = isDirty;
        return {
            dispose: () => {
                if (this.checkDirty === isDirty) {
                    this.checkDirty = null;
                }
            }
        };
    }

    public isDirty() {
        if (this.checkDirty) {
            return this.checkDirty();
        }
        return null;
    }

    @State.bound
    public saveAsync(params: TSaveParams = null) {
        if (this.saveContentAsync) {
            return this.saveContentAsync(params);
        }
        return Promise.resolve(null);
    }
}
