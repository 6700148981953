import { provideCareActivityMenuStore, useCareActivityMenuStore } from "./CareActivityMenuStoreProvider";
import React from "react";
import LargeMenu from "@CommonControls/LargeMenu/LargeMenu";
import CareActivityStateIcon from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/CareActivityStateIcon/CareActivityStateIcon";
import Icon from "@CommonControls/Icon";
import PersonNameLabel from "@CommonControls/PersonNameLabel";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import Styles from "./CareActivityMenu.less";
import moment from "moment";
import CareActivityId from "@Primitives/CareActivityId.g";
import Backdrop from "@CommonControls/Backdrop/Backdrop";
import LoadingIndicator from "@CommonControls/LoadingIndicator";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import INDataAction from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataAction";
import IWorklistAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistAction";
import PatientName from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/PatientName";
import Monogram from "@CommonControls/Monogram/Monogram";
import GenderId from "@Primitives/GenderId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import Flex from "@CommonControls/Flex";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";

export interface ICareActivityMenuProps {
    pointOfCareId: PointOfCareId | null;
    careActivityState: CareActivityState;
    primaryCareIdentifier: string | null;
    primaryParticipant: PractitionerId | null;
    wentUnderCareAt: moment.Moment | null;
    dischargedAt: moment.Moment | null;
    patientName: PatientName | null;
    patientBirthDate: LocalDate | null;

    careActivityId?: CareActivityId;
    actions?: (INDataAction[]) | (IWorklistAction[]);

    onClose?: () => void;
    onActionProcessed?: () => void;
    hideGroupHeaders?: boolean;

    onPerformClientSideActionAsync?: (clientSideAction: ClientSideActionDto) => Promise<WorkListArgument | boolean>;
}

function CareActivityMenu(props: ICareActivityMenuProps) {

    const store = useCareActivityMenuStore();

    return (
        <Backdrop onClick={props.onClose} usePortal>
            {store.isLoading ? (
                <LoadingIndicator />
            ) : (
                <LargeMenu
                    automationId="CareActivityMenu"
                    hideGroupHeaders={props.hideGroupHeaders}
                    itemGroups={store.actions}
                    onItemSelected={store.selectItem}
                    onEscapeKeyDown={props.onClose}
                    headerContent={(
                        <>
                            <Flex>
                                <Flex.Item xs={12} sm={4} md={3} className={Styles.monogramConatiner}>
                                    <Monogram
                                        gender={props.patientName.genderId.value === GenderId.Male.value ? "male" : "female"}
                                        value={props.patientName.name}
                                        size="small"
                                        className={Styles.icon}
                                    />
                                    <b>
                                        {store.patientName}
                                    </b>
                                </Flex.Item>
                                <Flex.Item xs={12} sm={8} md={9}>
                                   {StaticCareResources.CareActivityMenu.BirthDate} {store.patientBirthDate}
                                </Flex.Item>
                            </Flex>
                            <Flex>
                                <Flex.Item xs={12} md={3} sm={4} className={Styles.careStatusConatiner}>
                                    <CareActivityStateIcon className={combineClasses(Styles.noMargin, Styles.icon)} state={props.careActivityState} />
                                    <b>
                                        {`# ${props.primaryCareIdentifier}`}
                                    </b>
                                </Flex.Item>
                                <Flex.Item xs={12} md={5} sm={8}>
                                    <Icon iconName="pin" size="compact" visualStyle="white" />&nbsp;{store.pointOfCareShorthand.get()}
                                    {store.doctor && <>&nbsp;-&nbsp;<b><PersonNameLabel personName={store.doctor.name} /></b>&nbsp;({store.doctor.code})</>}
                                </Flex.Item>
                                <Flex.Item sm={12} md={4}>
                                    <Icon iconName="clock" size="compact" visualStyle="white" />&nbsp;{store.wentUnderCareAt} - {store.dischargedAt}
                                </Flex.Item>
                            </Flex>
                        </>
                    )}
                />
            )}
        </Backdrop>
    );
}

export default provideCareActivityMenuStore(CareActivityMenu);