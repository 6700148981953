import LoadingStateStore from "./PanelStore/LoadingStateStore";

export default class AppStatusStore {
    public static readonly instance = new AppStatusStore();

    private readonly _loadingStates = new Set<string>();

    public setLoadingState(name: string) {
        this._loadingStates.add(name);
    }

    public clearLoadingState(name: string) {
        this._loadingStates.delete(name);
    }

    public isLoading() {
        return this._loadingStates.size > 0;
    }
}