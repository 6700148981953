import Di from "@Di";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import EhiCovidStatusReportStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiCovidStatusReportStore";
import EhiCovidStatusReportId from "@Primitives/EhiCovidStatusReportId.g";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";

type ResponseType = Proxy.GetEhiCovidStatusReportByIdCommandResponse | Proxy.CreateEhiCovidStatusReportCommandResponse | Proxy.UpdateEhiCovidStatusReportCommandResponse | Proxy.DeleteEhiCovidStatusReportCommandResponse;

@Di.injectable()
export default class EhiCovidStatusReportStoreMapper extends LockingEntityStoreMapper<Proxy.EhiCovidStatusReportDto, EhiCovidStatusReportStore, ResponseType> {

    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    protected storeEntityIdType = EhiCovidStatusReportId;

    protected entityDtoSelector = (response: ResponseType) => {
        return response.ehiCovidStatusReport;
    };

    protected applyToStoreCore(target: EhiCovidStatusReportStore, response: ResponseType) {
        if (!response.ehiCovidStatusReport) {
            return;
        }

        const dto = this.entityDtoSelector(response);

        target.id = dto.id;
        target.careActivityId = dto.careActivityId;
        target.status = dto.status;
        target.bodyTemperature = dto.bodyTemperature;
        target.respiratoryRate = dto.respiratoryRate;
        target.statusReportAt = dto.statusReportAt;
        target.bloodPressureSystolic = dto.bloodPressureSystolic;
        target.bloodPressureDiastolic = dto.bloodPressureDiastolic;
        target.pulse = dto.pulse;
        target.bloodSugar = dto.bloodSugar;
        target.pneumoniaConfirmedRadiological = dto.pneumoniaConfirmedRadiological;
        target.pneumoniaConfirmedClinical = dto.pneumoniaConfirmedClinical;
        target.symptomsSeverityId = dto.symptomsSeverityId;
        target.breathingStatusId = dto.breathingStatusId;
        target.ventilationStatusId = dto.ventilationStatusId;
        target.chillsSymptomStatusId = dto.chillsSymptomStatusId;
        target.generalWeaknessSymptomStatusId = dto.generalWeaknessSymptomStatusId;
        target.sputumRemovalSymptomStatusId = dto.sputumRemovalSymptomStatusId;
        target.coughSymptomStatusId = dto.coughSymptomStatusId;
        target.headacheSymptomStatusId = dto.headacheSymptomStatusId;
        target.muscleOrJointPainSymptomStatusId = dto.muscleOrJointPainSymptomStatusId;
        target.vomitSymptomStatusId = dto.vomitSymptomStatusId;
        target.diarrheaSymptomStatusId = dto.diarrheaSymptomStatusId;
        target.soreThroatSymptomStatusId = dto.soreThroatSymptomStatusId;
        target.urineAmountId = dto.urineAmountId;
        target.otherSymptoms = dto.otherSymptoms;
        target.notes = dto.notes;
    }
}