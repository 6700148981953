import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";
import ITextualPrescriptionTemplateListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/ITextualPrescriptionTemplateListItem";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import * as Ui from "@CommonControls";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import TextualPrescriptionTemplateDetail from "./TextualPrescriptionTemplateDetail";
import TextualPrescriptionTemplate from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/TextualPrescriptionTemplate";
import TextualPrescriptionTemplateId from "@Primitives/TextualPrescriptionTemplateId.g";
import PrescriptionTemplateType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionTemplateType";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import TextualPrescriptionTemplateList from "./TextualPrescriptionTemplateList";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";
import { Prescription } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/ApiConstraints.g";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";

interface ITextualPrescriptionTemplateMasterDetailPanelDependencies {
    apiAdapter: PrescriptionApiAdapter;
    dialogService: IDialogService;
    notificationService: INotificationService;
    userManagementReferenceDataStore: UserManagementDataProviderStore;
}

interface ITextualPrescriptionTemplateMasterDetailPanelProps {
    _dependencies?: ITextualPrescriptionTemplateMasterDetailPanelDependencies;
    selectedId: TextualPrescriptionTemplateId;
    onSelectedIdChanged: (id: TextualPrescriptionTemplateId) => void;
    onBack: () => void;
}

/** @screen */
@State.observer
class TextualPrescriptionTemplateMasterDetailPanel extends React.Component<ITextualPrescriptionTemplateMasterDetailPanelProps> {

    //#region 
    private get apiAdapter() { return this.props._dependencies.apiAdapter; }
    private get dialogService() { return this.props._dependencies.dialogService; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get userManagementReferenceDataStore() { return this.props._dependencies.userManagementReferenceDataStore; }
    //#endregion

    @State.observable private isLoading = false;
    @State.observable.ref private list: ITextualPrescriptionTemplateListItem[] = [];
    @State.observable.ref private currentTemplate: TextualPrescriptionTemplate = null;

    private validator = new ClientSideValidator(Prescription, {
        shouldBeFilled: StaticHunSocialSecurityMedicationRequestResources.Validation.ShouldBeFilled,
        shouldNotBeLongerThan: StaticHunSocialSecurityMedicationRequestResources.Validation.ShouldNotBeLongerThan,
        
    });

    @State.action.bound
    private setList(list: ITextualPrescriptionTemplateListItem[]) {
        this.list = list;
    }

    @State.action.bound
    public setCurrentTemplate(currentTemplate: TextualPrescriptionTemplate) {
        this.currentTemplate = currentTemplate;
    }

    @State.boundLoadingState("isLoading")
    private async loadAllAsync() {
        await this.loadListAsync();
        await this.loadOrCreateSelectedAsync();
    }

    @State.bound
    private async loadListAsync() {
        const list = await this.apiAdapter.getTextualPrescriptionTemplatesAsync();
        await this.loadReferenceDataAsync(list.value);
        this.setList(list?.value || []);
    }

    @State.bound
    private async loadReferenceDataAsync(list: ITextualPrescriptionTemplateListItem[]) {
        const userIds = list?.map(i => i.lastModifiedBy);
        await this.userManagementReferenceDataStore.users.ensureLoadedAsync(userIds);
    }

    @State.bound
    private async loadOrCreateSelectedAsync() {
        if (this.props.selectedId) {
            if (!this.props.selectedId.value.startsWith("new_")) {
                const response = await this.apiAdapter.getTextualPrescriptionTemplateByIdAsync(this.props.selectedId);
                this.setCurrentTemplate(response);
                this.currentTemplate.takeSnapshot();
            } else {
                const newTemplate = new TextualPrescriptionTemplate(true);
                const templateTypeString = this.props.selectedId.value.substring(4);
                const templateType = PrescriptionTemplateType[templateTypeString];
                newTemplate.setType(templateType);
                this.setCurrentTemplate(newTemplate);
                this.currentTemplate.takeSnapshot();
            }
        } else {
            this.setCurrentTemplate(null);
        }
    }

    @State.bound
    private async deleteAsync() {
        const dialogResult = await this.dialogService.yesNo(StaticWebAppResources.Common.DialogTitle.ConfirmationTitle, StaticHunSocialSecurityMedicationRequestResources.TextualPrescriptionTemplatesPanel.Messages.DeleteConfirmationMessage);
        if (dialogResult.resultCode === DialogResultCode.Yes) {
            const response = await this.apiAdapter.deleteTextualPrescriptionTemplateAsync(this.currentTemplate.id, this.currentTemplate.rowVersion);
            if (response.operationWasSuccessful) {
                this.notificationService.showSavedSuccessfully();
                this.currentTemplate.takeSnapshot();
                this.props.onSelectedIdChanged(null);
            }
        }
    }

    @State.bound
    private async saveAsync() {
        if (!this.validator.isValid()) {
            this.notificationService.showCannotSaveBecauseOfErrors();
            return false;
        }

        if (this.currentTemplate.isNew) {
            const response = await this.apiAdapter.createTextualPrescriptionTemplateAsync(this.currentTemplate);
            if (response.operationWasSuccessful) {
                this.currentTemplate.takeSnapshot();
                this.notificationService.showSavedSuccessfully();
                this.props.onSelectedIdChanged(response.id);
            }
            return response.isPersistedByOperationInfo;
        } else {      
            const response = await this.apiAdapter.updateTextualPrescriptionTemplateAsync(this.currentTemplate);
            if (response.operationWasSuccessful) {
                this.setCurrentTemplate(response);
                this.notificationService.showSavedSuccessfully();
                this.currentTemplate.takeSnapshot();
                this.loadListAsync();
            }
            return response.isPersistedByOperationInfo;
        }
    }

    @State.bound
    private renderDetailToolbar(): React.ReactNode {
        return (
            <Ui.Flex>
                {this.currentTemplate?.isNew === false && <Ui.Button text={StaticWebAppResources.Common.Button.Delete} visualStyle="negative-standard" onClickAsync={this.deleteAsync} automationId="deleteButton" />}
                <Ui.SaveButton onClickAsync={this.saveAsync} automationId="saveButton" />
            </Ui.Flex>
        );
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAllAsync, this.apiAdapter.createTextualPrescriptionTemplatePermissionCheckAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: ITextualPrescriptionTemplateMasterDetailPanelProps) {
        if (!ValueWrapper.equals(this.props.selectedId, prevProps.selectedId)) {
            dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        }
    }    

    @State.bound
    private addTemplate() {
        this.props.onSelectedIdChanged(new TextualPrescriptionTemplateId("new_"));
    }

    @State.bound
    private renderToolbar() {
        const hasSelected = !!this.props.selectedId;
        return (
            <Ui.Flex>
                <Ui.Flex.Item>
                    {!hasSelected &&
                        <Ui.Button visualStyle="primary" onClick={this.addTemplate} automationId="addTemplateButton">
                            <Ui.Icon iconName="plus" />
                            {StaticHunSocialSecurityMedicationRequestResources.TextualPrescriptionTemplatesPanel.Buttons.New}
                        </Ui.Button>
                    }
                </Ui.Flex.Item>                
            </Ui.Flex>
        );
    }

    @State.bound
    private onSelectedItemChange(item: TextualPrescriptionTemplateId) {
        this.props.onSelectedIdChanged(item);
    }

    @State.bound
    private onSuccesfulSave(wasNew: boolean, newTemplate: TextualPrescriptionTemplate) {
        if (wasNew) {
            this.props.onSelectedIdChanged(newTemplate.id);
        } else {
            this.setCurrentTemplate(newTemplate);
        }
    }

    @State.bound
    private async onNavigatingAwayAsync() {
        if (this.currentTemplate.isDirty()) {
            const dialogResult = await this.dialogService.confirmIfNotSaved(StaticWebAppResources.Common.DialogTitle.ConfirmationTitle, StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);
            switch (dialogResult.resultCode) {
                case DialogResultCode.Yes:
                    return await this.saveAsync();
                case DialogResultCode.No:
                    return true;
                case DialogResultCode.Cancel:
                case DialogResultCode.None:
                default:
                    return false;
            }
        } else {
            return true;
        }
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticHunSocialSecurityMedicationRequestResources.TextualPrescriptionTemplatesPanel.MasterTitle} />;
        }

        return (
            <SingleLayout>
                <MasterDetailLayout isLoading={this.isLoading} selectedItem={this.currentTemplate} onSelectedItemChange={this.onSelectedItemChange}>
                    <MasterDetail.Master title={StaticHunSocialSecurityMedicationRequestResources.TextualPrescriptionTemplatesPanel.MasterTitle} toolbar={this.renderToolbar()}>
                        <TextualPrescriptionTemplateList list={this.list} onBack={this.props.onBack} />
                    </MasterDetail.Master>
                    <MasterDetail.Detail toolbar={this.renderDetailToolbar()}>
                        <TextualPrescriptionTemplateDetail
                            onSuccesfulSave={this.onSuccesfulSave}
                            onNavigatingAway={this.onNavigatingAwayAsync}
                            validator={this.validator}
                        />
                    </MasterDetail.Detail>
                </MasterDetailLayout>
            </SingleLayout>
        );
    }
}

export default connect(
    TextualPrescriptionTemplateMasterDetailPanel,
    new DependencyAdapter<ITextualPrescriptionTemplateMasterDetailPanelProps, ITextualPrescriptionTemplateMasterDetailPanelDependencies>(c => ({
        apiAdapter: c.resolve("PrescriptionApiAdapter"),
        dialogService: c.resolve("IDialogService"),
        notificationService: c.resolve("INotificationService"),
        userManagementReferenceDataStore: c.resolve("UserManagementDataProviderStore")
    }))
);