import React from "react";
import IDataGridColumnProps, { IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import TextBox from "@CommonControls/TextBox";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ColumnContainerContext from "@CommonControls/DataGrid/ColumnContainerContext";
import IColumnContainerContext from "@CommonControls/DataGrid/IColumnContainerContext";
import Styles from "./DataGridColumn.less";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ViewContextAdapter from "@Toolkit/ReactClient/Components/ViewContext/ViewContextAdapter";
import IToolkitViewContextSettings from "@Toolkit/ReactClient/Components/ViewContext/IToolkitViewContextSettings";

const filterValueSerializer: IDataGridFilterValueSerializer<any> = {
    serialize: value => value === "" ? null : JSON.stringify(value),
    deserialize: value => JSON.parse(value)
};

const defaultCellRenderer = (props: React.HTMLProps<HTMLTableDataCellElement>, displayValue: React.ReactNode, value: any, row: any, rowId: any, rowIndex: number, columnProps: IDataGridColumnProps) => {
    const className: string = columnProps.hideOverflow ? combineClasses(Styles.hideOverflow, props.className) : props.className;
    return <td title={getTitle(columnProps, value, displayValue, row)} {...props} className={className}>{displayValue}</td>;
};

const getTitle = (columnProps: IDataGridColumnProps, value: any, displayValue: React.ReactNode, row: any) => {
    if (!columnProps.showHint) {
        return null;
    }
    if (columnProps.onRenderHintValue) {
        return columnProps.onRenderHintValue(value, row);
    }
    return getDisplayValue(value, displayValue);
};

const getDisplayValue = (value: any, displayValue: React.ReactNode) => {
    if (typeof value === "object") {
        return displayValue;
    }

    return value;
};

let index: number = 0;


class DataGridColumn extends React.PureComponent<IDataGridColumnProps> {

    private observableProps: IDataGridColumnProps = null;
    private columnContainerContext: IColumnContainerContext = null;

    public static defaultProps: Partial<IDataGridColumnProps> = {
        onRenderCell: defaultCellRenderer,
        onRenderCellValue: (value) => isNullOrUndefined(value) ? null : `${value}`,
        onRenderFilter: (filterProps: IDataGridColumnFilterProps<string>) => <TextBox {...filterProps} />,
        isVisible: true,
        defaultFilterValue: null,
        clientSideFilterableValueGetter: (value) => value,
        clientSideOrderableValueGetter: (value) => value,
        filterValueSerializer: filterValueSerializer,
        showHint: true
    };

    public componentDidMount() {
        this.observableProps = State.observable(this.props);
        this.setColumnId(this.observableProps);
        this.columnContainerContext.attach(this.observableProps);
    }

    public componentWillUnmount() {
        this.columnContainerContext.detach(this.observableProps);
    }

    public componentDidUpdate(prevProps: IDataGridColumnProps) {
        State.runInAction(() => {
            Object.assign(this.observableProps, this.props);
        });
    }

    @State.action.bound
    private setColumnId(props: IDataGridColumnProps<any, any, any>) {
        if (isNullOrUndefined(props.id)) {
            props.id = `_aid_${index++}`;
        }
    }

    @State.bound
    private consumeContext(ctx: IColumnContainerContext): any {
        this.columnContainerContext = ctx;
        return null;
    }

    public render(): React.ReactNode {
        return (
            <ColumnContainerContext.Consumer>
                {this.consumeContext}
            </ColumnContainerContext.Consumer>
        );
    }
}

export default connect(DataGridColumn,
    new ViewContextAdapter<IDataGridColumnProps, IToolkitViewContextSettings>((props, ctx) => ({
        hideOverflow: props.hideOverflow === undefined ? ctx.isDataGridColumnEllipsisEnabled : props.hideOverflow
    }))
);