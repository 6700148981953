import State from "@Toolkit/ReactClient/Common/StateManaging";
import PropertyDefinitionId from "@Primitives/PropertyDefinitionId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import {isInCloseInterval} from "@Toolkit/CommonWeb/TemporalData/TemporalUtils";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import PropertyValueVersion from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyValueVersion";
import IPropertyValueVersion from "@HisPlatform/Model/DomainModel/Common/IPropertyValueVersion";
import DynamicPropertyInputType from "./DynamicPropertyInputType";

export default abstract class PropertyBase<T = any> {
    @State.observable.ref private valueVersions: Array<IPropertyValueVersion<T>>;
    @State.observable.ref private effectiveValueVersions: Array<IPropertyValueVersion<T>>;

    public originatingOrganizationUnitId: OrganizationUnitId = null;
    @State.observable.ref public isVisible: boolean = true;

    public get versions() {
        return this.valueVersions;
    }

    public get effectiveVersions() {
        return this.effectiveValueVersions;
    }

    @State.computed
    public get hasValue() {
        return this.valueVersions?.length > 0;
    }

    constructor(
        valueVersions: Array<IPropertyValueVersion<T>>,
        effectiveValueVersions: Array<IPropertyValueVersion<T>>,
        public readonly isOverridable: boolean,
        public readonly isTemporal: boolean,
        public readonly name: string,
        public readonly definitionId: PropertyDefinitionId,
        originatingOrganizationUnitId: OrganizationUnitId,
        public readonly defaultValue: T,
        public readonly effectiveDefaultValue: T,
        public readonly inputType: DynamicPropertyInputType
    ) {
        this.valueVersions = valueVersions;
        this.effectiveValueVersions = effectiveValueVersions;
        this.originatingOrganizationUnitId = originatingOrganizationUnitId;
    }

    public propertyNamesExcludedFromDirtyCheck = [
        "isVisible",
        "definitionId"
    ];

    public getValue(validOn?: LocalDate) {
        if (this.valueVersions?.length > 0) {
            return this.getVersion(validOn)?.value;
        }
        return this.defaultValue;
    }

    public getVersion(validOn?: LocalDate) {
        if (this.isTemporal) {
            if (isNullOrUndefined(validOn)) {
                throw new Error(`ScalarProperty.getVersion: validOn must be specified when the property is temporal.`);
            }
            return this.getVersionCore(validOn);
        } else {
            if (!isNullOrUndefined(validOn)) {
                throw new Error(`ScalarProperty.getVersion: validOn must NOT be specified when the property is temporal.`);
            }
            return this.getVersionCore(LocalDate.today());
        }
    }

    private getVersionCore(validOn: LocalDate) {
        return this.valueVersions.find(version => isInCloseInterval(new LocalDateRange(version.validFrom, version.validTo), validOn));
    }

    public getEffectiveValue(validOn?: LocalDate) {
        if (this.effectiveValueVersions?.length > 0) {
            return this.getEffectiveVersion(validOn)?.value;
        }
        return this.effectiveDefaultValue;
    }

    public getEffectiveVersion(validOn?: LocalDate) {
        if (this.isTemporal) {
            if (isNullOrUndefined(validOn)) {
                throw new Error(`ScalarProperty.getEffectiveVersion: validOn must be specified when the property is temporal.`);
            }
            return this.getEffectiveVersionCore(validOn);
        } else {
            if (!isNullOrUndefined(validOn)) {
                throw new Error(`ScalarProperty.getEffectiveVersion: validOn must NOT be specified when the property is not temporal.`);
            }
            return this.getEffectiveVersionCore(LocalDate.today());
        }
    }

    private getEffectiveVersionCore(validOn: LocalDate) {
        const result = this.effectiveValueVersions.find(version => isInCloseInterval(new LocalDateRange(version.validFrom, version.validTo), validOn));
        return result;
    }

    @State.action.bound
    public replaceAllVersion(valueVersions: Array<PropertyValueVersion<T>>) {
        this.valueVersions = valueVersions;
    }

    @State.action.bound
    public replaceVersion(value: T, validOn?: LocalDate) {
        if (this.valueVersions?.length > 0) {
            const versionToReplace = this.getVersion(validOn);
            this.valueVersions = [
                ...this.valueVersions.map(version => {
                    if (version === versionToReplace) {
                        return new PropertyValueVersion(value, version.validFrom, version.validTo);
                    } else {
                        return version;
                    }
                })
            ];
        } else {
            this.valueVersions = [new PropertyValueVersion(value, null, null)];
        }
    }

    @State.action.bound
    public clearValue() {
        this.valueVersions = null;
    }

    @State.action.bound
    public addVersion(value: IPropertyValueVersion<T>) {
        this.valueVersions = this.valueVersions.concat([value]);
    }

    @State.action.bound
    public removeVersion(value: IPropertyValueVersion<T>) {
        this.valueVersions = this.valueVersions.filter(item => item !== value);
    }
}
