import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { SelectBox } from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import TemplateApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Templating/TemplateApiAdapter";
import TemplateId from "@Primitives/TemplateId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import DocumentTypesApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/ReferenceData/DocumentTypesApiAdapter";
import DocumentManagementReferenceDataStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentManagementReferenceDataStore";
import ITemplateInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateInfo";

interface ITemplateSelectBoxDependencies {
    documentManagementReferenceDataStore: DocumentManagementReferenceDataStore;
    templateApiAdapter: TemplateApiAdapter;
    documentTypesApiAdapter: DocumentTypesApiAdapter;
}

interface ITemplateSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: ITemplateSelectBoxDependencies;
    _pointOfCareId?: PointOfCareId;
    scope?: string;
    validOn?: LocalDate;

    value: TemplateId;
    onChange: (value: TemplateId) => void;
}

@State.observer
class TemplateSelectBox extends React.Component<ITemplateSelectBoxProps> {
    private get dependencies() { return this.props._dependencies; }
    @State.observable.ref private isLoading = true;
    @State.observable.ref private items: Array<ISelectBoxItem<TemplateId>> = null;
    private templates: ITemplateInfo[] = [];

    @State.computed.valueWrapper private get pointOfCareId() { return this.props._pointOfCareId; }
    @State.computed.valueWrapper private get scope() { return this.props.scope; }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private get documentTypeStore() {
        return this.props._dependencies.documentManagementReferenceDataStore.documentTypeMap;
    }

    private async loadAsync() {
        const availableItems = await this.dependencies.templateApiAdapter.getAvailableIdsAsync(this.pointOfCareId, this.scope);

        if (availableItems.operationWasSuccessful) {
            await this.dependencies.documentManagementReferenceDataStore.template.ensureLoadedAsync(availableItems.value);
            this.templates = availableItems.value.map(item => this.dependencies.documentManagementReferenceDataStore.template.get(item));

            await this.documentTypeStore.ensureLoadedAsync(this.templates.map(t => t.documentTypeId));

            this.setLoadedItems(this.mapDefinitionsToSelectBoxItems(this.templates));
        }
    }

    private mapDefinitionsToSelectBoxItems(templates: ITemplateInfo[]): Array<ISelectBoxItem<TemplateId>> {
        return templates.map(template => {
            const typeName = this.documentTypeStore.get(template.documentTypeId).name;
            return {
                text: `${typeName} - ${template.name}`,
                value: template.id,
                longDisplayValue: <><b>{typeName}</b>{` - ${template.name}`}</>
            } as ISelectBoxItem<TemplateId>;
        });
    }

    @State.action
    private setLoadedItems(items: Array<ISelectBoxItem<TemplateId>>) {
        this.items = items;
        this.isLoading = false;
    }

    private renderCustomValue(props: any): React.ReactNode {
        return props.data.longDisplayValue ?? props.data.text;
    }

    public render() {
        const { _dependencies, _pointOfCareId, ...rest } = this.props;
        return (
            <SelectBox
                {...rest}
                loading={this.isLoading}
                items={this.items}
                customValueRenderer={this.renderCustomValue}
            />
        );
    }
}

export default connect(
    TemplateSelectBox,
    new CareActivityContextAdapter<ITemplateSelectBoxProps>(c => ({ _pointOfCareId: c.careActivity?.pointOfCareId })),
    new DependencyAdapter<ITemplateSelectBoxProps, ITemplateSelectBoxDependencies>(c => ({
        documentManagementReferenceDataStore: c.resolve<DocumentManagementReferenceDataStore>("DocumentManagementReferenceDataStore"),
        templateApiAdapter: c.resolve<TemplateApiAdapter>("TemplateApiAdapter"),
        documentTypesApiAdapter: c.resolve("DocumentTypesApiAdapter"),
    }))
);