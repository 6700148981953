import DependencyContainer from "@DiContainer";
import * as DocumentManagementProxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";

DependencyContainer
    .bind("IDocumentsClient")
    .to(DocumentManagementProxy.DocumentsClient);

DependencyContainer
    .bind("DocumentManagementProxy.IReferenceDataClient")
    .to(DocumentManagementProxy.ReferenceDataClient);

DependencyContainer
    .bind("ITemplatingClient")
    .to(DocumentManagementProxy.TemplatingClient);