import State from "@Toolkit/ReactClient/Common/StateManaging";
import CountryId from "@Primitives/CountryId.g";
import SettlementWithZipCodeId from "@Primitives/SettlementWithZipCodeId.g";

export default class InsurerAddress {
    @State.observable.ref public countryId: CountryId = null;
    @State.observable.ref public settlement: string = null;
    @State.observable.ref public zipCode: string = null;
    @State.observable public addressLine: string = "";

    constructor(countryId?: CountryId, settlement?: string, zipCode?: string, addressLine?: string) {
        if (countryId) {
            this.countryId = countryId;
        }

        if (settlement) {
            this.settlement = settlement;
        }
        if (zipCode) {
            this.zipCode = zipCode;
        }

        if (addressLine) {
            this.addressLine = addressLine;
        }
    }

    @State.action.bound
    public setAddressLine(addressLine: string) {
        this.addressLine = addressLine;
    }

    @State.action.bound
    public setCountryId(countryId: CountryId) {
        this.countryId = countryId;
    }

    @State.action.bound
    public setSettlement(settlement: string) {
        this.settlement = settlement;
    }

    @State.action.bound
    public setZipCode(zipCode: string) {
        this.zipCode = zipCode;
    }
}
