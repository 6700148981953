import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import HunFinanceReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/Model/HunFinanceReferenceDataStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import IFinancingClassGroup from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/Model/IFinancingClassGroup";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CarActivityCoverageDataPanelView from "@HunSocialSecurityPlugin/BoundedContexts/Care/Extensions/CareActivityCoverageData/CareActivityCoverageDataPanelView";
import HunCareApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/HunCareApiAdapter";
import AccidentTypeId from "@Primitives/AccidentTypeId.g";
import HunFinancingClassId from "@Primitives/HunFinancingClassId.g";
import IdentifierTypeId from "@Primitives/IdentifierTypeId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import PatientId from "@Primitives/PatientId.g";
import WellKnownReferenceCodes from "@HunSocialSecurityPlugin/Common/WellKnownReferenceCodes";
import * as Ui from "@CommonControls";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import PatientInsuranceSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/PatientRegister/PatientInsuranceSelectBox";
import InsuranceId from "@Primitives/InsuranceId.g";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import PatientApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/Patient/PatientApiAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import InsuranceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Insurance/InsuranceStore";
import ReactionComponent from "@Toolkit/ReactClient/Common/ReactionComponent";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { LoadedSignalAdapter } from "@Toolkit/CommonWeb/PanelStore/LoadedSignal";
import Urgency from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/Urgency.g";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";

interface ICareActivityCoverageDataPanelCoreDependencies {
    hunFinanceReferenceDataStore: HunFinanceReferenceDataStore;
    hunCareApiAdapter: HunCareApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
    financeStore: FinanceReferenceDataStore;
    patientApiAdapter: PatientApiAdapter;
}

interface ICareActivityCoverageDataPanelCoreProps {
    _dependencies?: ICareActivityCoverageDataPanelCoreDependencies;
    _onLoaded?: () => void;

    careActivityId: CareActivityId;
    pointOfCareId: PointOfCareId;
    patientId: PatientId;
    urgency: Urgency;

    accidentTypeId: AccidentTypeId;
    onAccidentTypeIdChange: (id: AccidentTypeId) => void;

    financingClassId: HunFinancingClassId;
    onFinancingClassIdChange: (id: HunFinancingClassId) => void;

    identifierTypeId: IdentifierTypeId;
    onIdentifierTypeIdChange: (id: IdentifierTypeId) => void;
    identifier: Identifier;
    onIdentifierChange: (identifier: Identifier) => void;

    insuranceId: InsuranceId;
    onInsuranceIdChange: (id: InsuranceId) => void;

    onIsHunCoverageNeededChange: (value: string) => void;
}

/** @screen */
@State.observer
class CareActivityCoverageDataPanelCore extends React.Component<ICareActivityCoverageDataPanelCoreProps> {

    private disposers: IDisposable[] = [];

    @State.observable.ref private possibleHunFinancingClassGroups: IFinancingClassGroup[] = [];
    @State.observable.ref private selectedHunFinancingClassGroup: IFinancingClassGroup;
    @State.observable private isLoaded: boolean = false;
    @State.observable.ref private patientInsurances: InsuranceStore[] = [];

    @State.computed public get isHunCoverageNeeded() {
        const insurance = this.findHunSocialSecurityInsurance();
        if (isNullOrUndefined(this.props.insuranceId) && isNullOrUndefined(insurance)) {
            return false;
        }
        const isHunCoverageNeeded = this.props.insuranceId?.value === insurance?.id?.value;

        return isHunCoverageNeeded;
    }

    private get dependencies() {
        return this.props._dependencies;
    }

    public componentDidMount(): void {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }

    public componentDidUpdate(prevProps: ICareActivityCoverageDataPanelCoreProps) {
        if (
            !ValueWrapper.equals(this.props.patientId, prevProps.patientId) ||
            !ValueWrapper.equals(this.props.careActivityId, prevProps.careActivityId) ||
            this.props.financingClassId?.value !== prevProps.financingClassId?.value ||
            this.props.identifierTypeId?.value !== prevProps.identifierTypeId?.value
        ) {
            this.dispose();
            dispatchAsyncErrors(this.initializeStoreAsync(), this);
        }
    }

    public componentWillUnmount() {
        this.dispose();
    }

    private dispose() {
        this.disposers.forEach(d => d.dispose());
    }

    private async initializeAsync() {
        await this.dependencies.hunFinanceReferenceDataStore.identifierTypeMap.ensureLoadedAsync();
        await this.dependencies.hunFinanceReferenceDataStore.financingClassMap.ensureLoadedAsync();
        await this.dependencies.careReferenceDataStore.patientDocumentType.ensureAllLoadedAsync();
        await this.dependencies.financeStore.insurerOrganizationMap.ensureAllLoadedAsync();

        await this.initializeStoreAsync();

        State.runInAction(() => {
            this.isLoaded = true;
        });

        this.props._onLoaded?.();
    }

    @State.action.bound
    private async initializeStoreAsync() {

        const result = await this.dependencies.hunCareApiAdapter.getPossibleFinancingClassIdsAsync(this.props.patientId, this.props.pointOfCareId, this.props.urgency);
        State.runInAction(() => {
            this.possibleHunFinancingClassGroups = result;

            if (!this.props.identifierTypeId) {
                this.selectedHunFinancingClassGroup = this.possibleHunFinancingClassGroups.length && this.possibleHunFinancingClassGroups[0];

                this.props.onIdentifierTypeIdChange(this.possibleHunFinancingClassGroups.length && this.possibleHunFinancingClassGroups[0].IdentifierTypeId);
                this.props.onFinancingClassIdChange(this.possibleHunFinancingClassGroups.length && this.possibleHunFinancingClassGroups[0].DefaultFinancingClassId);
                this.props.onIdentifierChange(this.possibleHunFinancingClassGroups.length && this.possibleHunFinancingClassGroups[0].Identifier);
            } else {
                const currentValidHunFinancingClassGroup = this.possibleHunFinancingClassGroups.find(item => item.IdentifierTypeId.value === this.props.identifierTypeId.value);

                if (!!currentValidHunFinancingClassGroup) {
                    this.setFinancingGroup(currentValidHunFinancingClassGroup);
                } else {
                    const currentInvalidFinancingClassGroup = {
                        IdentifierTypeId: this.props.identifierTypeId,
                        PossibleFinancingClassIds: [this.props.financingClassId],
                        DefaultFinancingClassId: this.props.financingClassId,
                        Identifier: this.props.identifier,
                    } as IFinancingClassGroup;

                    this.possibleHunFinancingClassGroups.push(currentInvalidFinancingClassGroup);
                    this.setFinancingGroup(currentInvalidFinancingClassGroup);
                }
            }
        });

        const insurancesResult = await this.dependencies.patientApiAdapter.loadInsurancesAsync(this.props.patientId);

        State.runInAction(() => {
            this.patientInsurances = insurancesResult.value;
        });

        if (isNullOrUndefined(this.props.insuranceId?.value) && isNullOrUndefined(this.props.careActivityId?.value)) {
            const maybeInsurance = this.findHunSocialSecurityInsurance();

            if (!isNullOrUndefined(maybeInsurance)) {
                State.runInAction(() => {
                    this.props.onInsuranceIdChange(maybeInsurance.id);
                });
            }
        }

        this.reactionIsHunCoverageNeeded(this.isHunCoverageNeeded);
    }

    @State.bound
    private findHunSocialSecurityInsurance() {
        return this.patientInsurances.find(i => {
            const org = this.props._dependencies.financeStore.insurerOrganizationMap.get(i.insurerOrganizationId);
            if (org.code === WellKnownReferenceCodes.HunSocialSecurityInsuranceCode) {
                return true;
            }
            else {
                return false;
            }
        });
    }

    @State.bound
    private setFinancingGroup(newValue: IFinancingClassGroup) {
        State.runInAction(() => {
            this.selectedHunFinancingClassGroup = newValue;
            this.props.onIdentifierTypeIdChange(this.selectedHunFinancingClassGroup?.IdentifierTypeId);
            this.props.onIdentifierChange(this.selectedHunFinancingClassGroup?.Identifier);
        });
    }

    @State.bound
    private inspectIsHunCoverageNeeded() {
        return this.isHunCoverageNeeded;
    }

    @State.bound
    private reactionIsHunCoverageNeeded(value: boolean) {
        this.props.onIsHunCoverageNeededChange(value ? "1" : "0");
    }

    public render() {
        return (

            this.isLoaded && (
                <Ui.GroupBox title={StaticCareResources.OutpatientWorkflow.CareActivityCoverage.Title}>
                    <ReactionComponent inspector={this.inspectIsHunCoverageNeeded} reaction={this.reactionIsHunCoverageNeeded} />
                    <Ui.Flex verticalSpacing="tiny">
                        <Ui.Flex.Item xs={4}>
                            <PatientInsuranceSelectBox
                                patientId={this.props.patientId}
                                label={StaticCareResources.PatientRegister.PatientDocuments.Labels.Insurance}
                                value={this.props.insuranceId}
                                onChange={this.props.onInsuranceIdChange}
                                propertyIdentifier="InsuranceId"
                                automationId="InsuranceId"
                            />
                        </Ui.Flex.Item>
                        {
                            this.isHunCoverageNeeded && (
                                <CarActivityCoverageDataPanelView
                                    patientId={this.props.patientId}
                                    careActivityId={this.props.careActivityId}
                                    careReferenceDataStore={this.dependencies.careReferenceDataStore}
                                    hunFinanceReferenceDataStore={this.dependencies.hunFinanceReferenceDataStore}
                                    possibleHunFinancingClassGroups={this.possibleHunFinancingClassGroups}
                                    selectedHunFinancingClassGroup={this.selectedHunFinancingClassGroup}
                                    setFinancingGroup={this.setFinancingGroup}
                                    accidentTypeId={this.props.accidentTypeId}
                                    onAccidentTypeIdChange={this.props.onAccidentTypeIdChange}
                                    financingClassId={this.props.financingClassId}
                                    onFinancingClassIdChange={this.props.onFinancingClassIdChange}
                                    identifier={this.props.identifier}
                                    pointOfCareId={this.props.pointOfCareId}
                                />
                            )
                        }
                    </Ui.Flex>
                </Ui.GroupBox>
            )
        );
    }
}

export default connect(
    CareActivityCoverageDataPanelCore,
    new DependencyAdapter<ICareActivityCoverageDataPanelCoreProps, ICareActivityCoverageDataPanelCoreDependencies>((container) => {
        return {
            careReferenceDataStore: container.resolve<CareReferenceDataStore>("CareReferenceDataStore"),
            hunCareApiAdapter: container.resolve<HunCareApiAdapter>("HunCareApiAdapter"),
            hunFinanceReferenceDataStore: container.resolve<HunFinanceReferenceDataStore>("HunFinanceReferenceDataStore"),
            financeStore: container.resolve("FinanceReferenceDataStore"),
            patientApiAdapter: container.resolve("PatientApiAdapter"),
        };
    }),
    new LoadedSignalAdapter("CustomBlock:HunCareActivityCoverageDataPanel"),
);
