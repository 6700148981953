import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import Di from "@Di";
import EditableExternalLocationStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/External/EditableExternalLocationStore";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import State from "@Toolkit/ReactClient/Common/StateManaging";

type ResponseType = Proxy.GetEditableExternalLocationByIdQueryResponse | Proxy.AddExternalLocationCommandResponse;

@Di.injectable()
export default class EditableExternalLocationStoreMapper extends EntityStoreMapper<Proxy.EditableExternalLocationDto, EditableExternalLocationStore, ResponseType> {
    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }
    
    protected storeEntityIdType = ExternalLocationId;

    protected entityDtoSelector(response: ResponseType) {
        if (response instanceof Proxy.EditableExternalLocationDto) {
            return response;
        }
        return response.externalLocation;
    }

    protected applyToStoreCore(target: EditableExternalLocationStore, response: ResponseType) {
        if (!response.externalLocation) {
            return;
        }

        const dto = this.entityDtoSelector(response);

        target.shortName = dto.baseData?.shortName;
        target.name = dto.baseData?.name;
        target.externalLocationTypes = dto.externalLocationTypeIds;
        target.identifiers = State.observable(dto.identifiers.map(i => new Identifier(i.identifier.identifierSystemId, i.identifier.value)));
    }
}
