import { ResponseBase } from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import ApiBusinessError from "@Toolkit/CommonWeb/ApiAdapter/ApiBusinessError";
import ApiTechnicalError from "@Toolkit/CommonWeb/ApiAdapter/ApiTechnicalError";
import HttpStatusCode from "@Toolkit/CommonWeb/ApiAdapter/HttpStatusCode";
import UnauthenticatedUserBusinessError from "@Toolkit/CommonWeb/Model/UnauthenticatedUserBusinessError";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import ReferenceDataCollector from "@HisPlatform/Services/Definition/ReferenceDataLoader/ReferenceDataCollector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import moment from "moment";
import ServiceUnavailableError from "@Toolkit/CommonWeb/Model/ServiceUnavailableError";
import IOperationResult from "@Toolkit/CommonWeb/ApiAdapter/IOperationResult";

export default abstract class ApiAdapterBase2 {

    constructor(
        private readonly referenceDataLoader: IReferenceDataLoader
    ) { }

    protected async executeOperationAsync<TResult, TResponse extends ResponseBase>(
        fetchAsync: () => Promise<TResponse>,
        mapAsync: ((response: TResponse) => Promise<TResult>) | ((response: TResponse) => TResult)
    ): Promise<IOperationResult<TResult>> {

        try {
            const response = await fetchAsync();

            const mapResult = mapAsync(response);
            const result = await Promise.resolve(mapResult);

            await this.ensureLoadedReferenceDataDeepAsync(result);

            const operationResult: IOperationResult<TResult> = {
                result,
                operationInfo: createOperationInfo(response)
            };

            return operationResult;

        } catch (error) {

            if ("businessError" in error) {
                throw new ApiBusinessError(error.businessError._discriminator, error.businessError);

            } else if ("status" in error && error.status === HttpStatusCode.InternalServerError) {
                throw new ApiTechnicalError(error.message);

            } else if ("status" in error && error.status === HttpStatusCode.Unauthorized) {
                throw new ApiBusinessError(UnauthenticatedUserBusinessError.businessErrorName, new UnauthenticatedUserBusinessError());

            } else if ("status" in error && error.status === HttpStatusCode.ServiceUnavailable) {
                throw new ApiBusinessError(ServiceUnavailableError.businessErrorName, new ServiceUnavailableError());

            } else {
                throw error;
            }
        }
    }

    private async ensureLoadedReferenceDataDeepAsync(rootTarget: any) {

        const referenceDataCollectors = new Map<string, ReferenceDataCollector<any>>();

        const getOrCreateCollector = (typeName: string) => {
            let collector = referenceDataCollectors.get(typeName);
            if (!collector) {
                collector = this.referenceDataLoader.tryCreateCollector(typeName);
                if (collector) {
                    referenceDataCollectors.set(typeName, collector);
                }
            }
            return collector;
        };

        let infiniteLoopDetectCounter = 10000;

        const collectReferenceDataDeepCore = (target: any) => {

            if (--infiniteLoopDetectCounter <= 0) {
                throw new Error("ensureLoadedReferenceDataDeepAsync possible infinite (recoursive) loop detected!");
            }

            if (!!target && Array.isArray(target)) {
                for (const targetItem of target) {
                    collectReferenceDataDeepCore(targetItem);
                }
                return;
            }

            if (!(typeof target === "object")) {
                return;
            }

            if (
                target instanceof Date ||
                target instanceof LocalDate ||
                moment.isMoment(target)
            ) {
                return;
            }

            if (target instanceof StringEntityId) {
                getOrCreateCollector(target.typeName)?.add(target);
                return;
            }

            for (const key in target) {
                if (Object.prototype.hasOwnProperty.call(target, key)) {
                    const value = target[key];
                    collectReferenceDataDeepCore(value);
                }
            }
        };

        collectReferenceDataDeepCore(rootTarget);

        for (const collector of Array.from(referenceDataCollectors.values())) {
            await this.referenceDataLoader.loadCollectedAsync(collector);
        }
    }
}
