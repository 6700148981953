import { ConstructorType } from "@Toolkit/CommonWeb/Reflection/Reflection";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import IMapperService from "@HisPlatform/Services/Definition/MapperService/IMapperService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class MapperService implements IMapperConfigurator, IMapperService {

    private readonly mappers = new Map<ConstructorType | string, (source: any, mapper: IMapperService) => any>();

    public register<TSource, TDestination>(source: ConstructorType, mapper: (source: TSource, mapper: IMapperService) => TDestination): void {
        this.mappers.set(source, mapper);
    }

    public registerByName<TSource, TDestination>(mapperName: string, mapper: (source: TSource, mapper: IMapperService) => TDestination): void {
        this.mappers.set(mapperName, mapper);
    }

    @State.bound
    public map<TSource = any, TDestination = any>(source: TSource): TDestination {

        if (isNullOrUndefined(source)) {
            return source as unknown as TDestination;
        }

        if (typeof source !== "object" || !source.constructor) {
            throw new Error(`Source should be a class instance.`);
        }

        const mapper = this.mappers.get(source.constructor as ConstructorType);

        if (!mapper) {
            throw new Error(`Mapper for ${source.constructor.name} not found.`);
        }

        return mapper(source, this) as unknown as TDestination;
    }

    public tryMapByName<TSource = any, TDestination = any>(mapperName: string, source: TSource): { result: TDestination, mapperFound: boolean } {

        if (isNullOrUndefined(source)) {
            return { mapperFound: true, result: source as unknown as TDestination };
        }

        const mapper = this.mappers.get(mapperName);

        if (!mapper) {
            return { mapperFound: false, result: undefined };
        }

        return { mapperFound: true, result: mapper(source, this) as unknown as TDestination };
    }

    public mapByName<TSource = any, TDestination = any>(mapperName: string, source: TSource): TDestination {
        const mapped = this.tryMapByName<TSource, TDestination>(mapperName, source);
        if (!mapped.mapperFound) {
            throw new Error(`Mapper by name '${mapperName}' not found.`);
        }
        return mapped.result;
    }
}