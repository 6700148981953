import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import PerformanceStatementStatusSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementStatusSelectBox/PerformanceStatementStatusSelectBox";
import PerformanceStatementStatus from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementStatus.g";

interface IPerformanceStatementStatusColumnDependencies {
    localizationService: ILocalizationService;
}

interface IPerformanceStatementStatusColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IPerformanceStatementStatusColumnDependencies;
    displayMode?: "name";
}


const PerformanceStatementStatusColumn: React.FC<IPerformanceStatementStatusColumnProps> = (props: IPerformanceStatementStatusColumnProps) => {

    const valueRenderer = useCallback((value: PerformanceStatementStatus) => {

        switch (props.displayMode) {
            case "name":
                const localized = isNullOrUndefined(value) ? null : props._dependencies.localizationService.localizeEnum(PerformanceStatementStatus[value], "PerformanceStatementStatus");
                return isNullOrUndefined(localized) ? null : localized.Name;
        }
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<PerformanceStatementStatus | PerformanceStatementStatus[]>) => {
        return (
            <PerformanceStatementStatusSelectBox
                {...filterProps}
                multiSelect
                maxMenuWidth={commonMenuMaxWidth}
                hoverOnlyIndicatorIcons
            />
        );
    }, []);

    return (
        <DataGridColumn
            width={undefined}
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            showHint={false}
        />
    );
};

export default connect(
    PerformanceStatementStatusColumn,
    new DependencyAdapter<IPerformanceStatementStatusColumnProps, IPerformanceStatementStatusColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
