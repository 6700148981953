import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import DateRangePicker from "@CommonControls/DateRangePicker/DateRangePicker";
import MomentRange from "@Toolkit/CommonWeb/Model/MomentRange";
import * as Ui from "@CommonControls";

import MomentLabel from "@CommonControls/MomentLabel";

interface IAppointmentDateColumnProps extends IDataGridColumnBaseProps {

}


const AppointmentDateColumn: React.FC<IAppointmentDateColumnProps> = props => {

    const { children, ...columnProps } = props;

    const valueRenderer = useCallback((value: MomentRange) => {
        return (
            <> 
                <Ui.Icon iconName="appointments" size="normal" visualStyle="primary" />
                <MomentLabel from={value?.from} to={value?.to}/>
            </>
        );
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<LocalDateRange>) => {
        return <DateRangePicker {...filterProps} hoverOnlyIndicatorIcons />;
    }, []);

    return (
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
            showHint={false}
        />
    );
};
const filterValueSerializer: IDataGridFilterValueSerializer<LocalDateRange> = {
    serialize: value => JSON.stringify(value),
    deserialize: value => LocalDateRange.parse(value)
};

export default AppointmentDateColumn;
