import PatientId from "@Primitives/PatientId.g";
import PatientAllergyIntoleranceListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceListStore";
import React, { useContext } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import _ from "@HisPlatform/Common/Lodash";
import ApiBusinessError from "@Toolkit/CommonWeb/ApiAdapter/ApiBusinessError";
import UnauthorizedOperationBusinessError from "@Toolkit/CommonWeb/Model/UnauthorizedOperationBusinessError";
import PatientAllergyIntoleranceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PatientLoader from "@HisPlatform/Model/DomainModel/PatientContext/PatientLoader";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";
import PatientApiAdapter2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/PatientApiAdapter2";

export default class PatientContext implements IDisposable {
    @State.observable.ref public isLoading = true;
    @State.observable.ref public isUnauthorizedAccess = false;
    @State.observable.ref public patientId: PatientId = null;
    @State.observable.ref public patient: PatientAdministrativeData = null;
    @State.observable.ref public patientAllergyIntoleranceList: PatientAllergyIntoleranceListStore = null;

    public readonly patientSavedEvent = new TypedEvent<PatientAdministrativeData>();
    public readonly patientAllergyIntoleranceListSavedEvent = new TypedEvent();

    constructor(
        private readonly patientApiAdapter: PatientApiAdapter2,
        private readonly patientAllergyIntoleranceApiAdapter: PatientAllergyIntoleranceApiAdapter,
        private readonly patientLoader: PatientLoader,
    ) {
        this.patientSavedEvent.on(this.setPatientFromEvent);
        this.patientAllergyIntoleranceListSavedEvent.on(this.setPatientAllergyIntoleranceListFromEvent);
    }

    public async loadAsync(patientId: PatientId) {
        try {
            const [
                patientData,
                patientAllergyIntoleranceList
            ] = await Promise.all([
                this.patientApiAdapter.loadByIdAsync(patientId),
                this.patientAllergyIntoleranceApiAdapter.getByPatientIdAsync(patientId),
            ]);

            this.setLoadedState(
                patientId,
                patientData.result,
                patientAllergyIntoleranceList
            );
        } catch (err) {
            if (err instanceof ApiBusinessError && err.error instanceof UnauthorizedOperationBusinessError) {
                this.setUnauthorizedState(patientId);
            } else {
                throw err;
            }
        }
    }

    @State.action
    private setLoadedState(
        patientId: PatientId,
        patient: PatientAdministrativeData,
        patientAllergyIntoleranceList: PatientAllergyIntoleranceListStore
    ) {
        this.patientId = patientId;
        this.patient = patient;
        this.patientAllergyIntoleranceList = patientAllergyIntoleranceList;
        this.isLoading = false;
    }

    @State.action
    private setUnauthorizedState(
        patientId: PatientId
    ) {
        this.patientId = patientId;
        this.patient = null;
        this.patientAllergyIntoleranceList = null;
        this.isUnauthorizedAccess = true;
        this.isLoading = false;
    }

    @State.action.bound
    private setPatientFromEvent(patient: PatientAdministrativeData) {
        this.patient = patient;
    }

    @State.action.bound
    private async reloadPatientAllergyIntolerancesAsync() {
        const newList = await this.patientAllergyIntoleranceApiAdapter.getByPatientIdAsync(this.patientId);

        await this.patientLoader.loadPatientRelatedReferenceDataAsync(null, newList);

        this.setPatientAllergyIntoleranceList(newList);
    }

    @State.action.bound
    private setPatientAllergyIntoleranceList(newValue: PatientAllergyIntoleranceListStore) {
        this.patientAllergyIntoleranceList = newValue;
    }

    @State.action.bound
    private setPatientAllergyIntoleranceListFromEvent() {
        dispatchAsyncErrors(this.reloadPatientAllergyIntolerancesAsync(), null);
    }

    public dispose() {
        this.patientSavedEvent.dispose();
    }
}

const PatientReactContext = React.createContext<PatientContext>(null);

function usePatientContext(): PatientContext {
    return useContext(PatientReactContext);
}

export {
    PatientReactContext,
    usePatientContext
};