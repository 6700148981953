import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import IDocumentFragment from "@CommonControls/DocumentEditor/IDocumentFragment";
import IAutomaticFillDocumentContent from "@HisPlatform/Packages/Care/Screens/CareActivityTextBlockScreen/IAutomaticFillDocumentContent";

export interface IAutomaticInsertTextBlockContentDialogParams extends IModalParams {
    previous: IAutomaticFillDocumentContent;
    textBlockTypeId: TextBlockTypeId;
}


export interface IAutomaticInsertTextBlockContentDialogResult {
    actualContent: IDocumentFragment;
}

export default class AutomaticInsertTextBlockContentDialogParams implements IAutomaticInsertTextBlockContentDialogParams {
    public static type = "AutomaticInsertTextBlockContentDialog";
    public get type() { return AutomaticInsertTextBlockContentDialogParams.type; }

    constructor(
        public previous: IAutomaticFillDocumentContent,
        public textBlockTypeId: TextBlockTypeId
    ) { }
}