import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import * as Ui from "@CommonControls";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import IInsurerOrganization from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/IInsurerOrganization";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";

interface IInsurerOrganizationSelectBoxDependencies {
    financeReferenceDataStore: FinanceReferenceDataStore;
}

interface IInsurerOrganizationSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IInsurerOrganizationSelectBoxDependencies;
    showOnlyClaimSettlementPartners?: boolean;
}

@State.observer
class InsurerOrganizationSelectBox extends React.Component<IInsurerOrganizationSelectBoxProps> {

    public static defaultProps: Partial<IInsurerOrganizationSelectBoxProps> = {
        showOnlyClaimSettlementPartners: false
    };

    private get referenceDataStore() {
        return this.props._dependencies.financeReferenceDataStore;
    }

    @State.computed private get items(): Array<ISelectBoxItem<InsurerOrganizationId>> {
        let items = this.referenceDataStore.insurerOrganizationMap.items;

        if (this.props.showOnlyClaimSettlementPartners) {
            items = items.filter(x => x.isClaimSettlementPartner === true);
        }

        return items.map(this.toSelectBoxItem);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private async loadAsync() {
        await this.referenceDataStore.insurerOrganizationMap.ensureAllLoadedAsync();
    }

    @State.bound
    private toSelectBoxItem(item: IInsurerOrganization) {
        const currentItem = this.referenceDataStore.insurerOrganizationMap.get(item.id);
        return {
            text: `${currentItem.displayCode} - ${currentItem.name}`,
            value: item.id,
            longDisplayValue: <><b>{item.displayCode}</b>{` - ${item.name}`}</>
        } as ISelectBoxItem<InsurerOrganizationId>;
    }

    private renderCustomValue(props: any): React.ReactNode {
        return props.data.longDisplayValue ?? props.data.text;
    }

    public render() {
        return (
            <Ui.SelectBox
                {...this.props}
                loading={!this.items}
                items={this.items}
                customValueRenderer={this.renderCustomValue}
            />
        );
    }
}

export default connect(
    InsurerOrganizationSelectBox,
    new DependencyAdapter<IInsurerOrganizationSelectBoxProps, IInsurerOrganizationSelectBoxDependencies>(container => {
        return {
            financeReferenceDataStore: container.resolve("FinanceReferenceDataStore")
        };
    })
);
