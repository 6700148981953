import React from "react";
import { Submenu } from "react-contexify";

interface IMenuItemProps {
    label: React.ReactNode;
    children?: React.ReactNode;
}

export default function SubMenuItem(props: IMenuItemProps) {
    return (
        <Submenu
            label={props.label}
        >{props.children}</Submenu>
    );
}