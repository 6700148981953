import React, { useCallback } from "react";
import ReactDOM from "react-dom";

const usePortalRenderer = (portalTargetElementId: string, content: React.ReactNode) => {
    const [numberOfRetires, retry] = React.useState(0);

    return useCallback(() => {
        const portalHost = document.getElementById(portalTargetElementId);

        if (!portalHost) {
            requestAnimationFrame(() => {
                if (numberOfRetires >= 10) {
                    throw new Error(`Portal not found '${portalTargetElementId}'`);
                }

                retry(numberOfRetires + 1);
            });
            return null;
        } else {
            return ReactDOM.createPortal(content, portalHost);
        }
    }, [numberOfRetires, retry, portalTargetElementId, content]);
};

export default usePortalRenderer;