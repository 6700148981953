import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";
import IHunSocialSecurityPerformanceStatementResources from "./IHunSocialSecurityPerformanceStatementResources";

const resources = createStaticResourceDictionary<IHunSocialSecurityPerformanceStatementResources>();

export function loadStaticPerformanceStatementResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    resources.load(localizationService.getResourceGroup("WebApp").PerformanceStatement);
}

export default resources;