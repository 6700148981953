import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import IDiagnosisExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IDiagnosisExtensionPointProps";
import ITelecommunicationCallControlExtensionPointProps from "@PluginInterface/BoundedContexts/Telecommunication/ITelecommunicationCallControlExtensionPointProps";
import TelecommunicationApiAdapter from "@HisPlatform/Packages/Care/ApiAdapter/TelecommunicationApiAdapter";

@Di.injectable()
export default class AssecoRoomsCallControlExtensionComponentService implements IExtensionComponentService<ITelecommunicationCallControlExtensionPointProps> {

    constructor(@Di.inject("TelecommunicationApiAdapter") private telecommunicationApiAdapter: TelecommunicationApiAdapter) {
    }

    public async isVisibleAsync(props: IDiagnosisExtensionPointProps) {
        const call = await this.telecommunicationApiAdapter.tryGetCallByCareActivityIdAsync(props.careActivityId);
        return !!call.result;

    }

    public shouldRerunIsVisible(props: ITelecommunicationCallControlExtensionPointProps, prevProps: ITelecommunicationCallControlExtensionPointProps) {
        return !ValueWrapper.equals(props.careActivityId, prevProps?.careActivityId);
    }
}
