import React from "react";
import styles from "@CommonControls/WorkflowStepsIndicator/WorkflowStepsIndicator.less";
import { WorkflowValidation } from "@CommonControls/WorkflowStepsIndicator/WorkflowValidation";
import WorkflowStepStore from "@CommonControls/WorkflowStepsIndicator/WorkflowStepStore";
import WorkflowStep from "./Step";
import * as Ui from "@CommonControls";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import SizeMeasurer from "@Toolkit/ReactClient/Components/SizeMeasurer/SizeMeasurer";
import _ from "@HisPlatform/Common/Lodash";

interface IWorkflowStepsIndicatorProps {
    steps: WorkflowStepStore[] | IObservableArray<WorkflowStepStore>;
    selectedStep?: WorkflowStepStore;
    onStepSelected?: (selected: WorkflowStepStore) => void;
}

@State.observer
export default class WorkflowStepsIndicator extends React.Component<IWorkflowStepsIndicatorProps, undefined> {

    @State.observable.ref private isScroll: boolean = false;
    private containerWidth: number = 0;
    @State.observable.ref private containerWidthStyle: string;

    private container: Ui.ScrollView;

    @State.action.bound
    private checkOverflow() {
        if (this.container) {
            this.containerWidth = this.container.getClientWidth();
            this.isScroll = this.container.getScrollWidth() > this.container.getClientWidth();
            this.updateItemWithDebounced();
        }
    }

    private updateItemWithDebounced = _.debounce(() => {
        State.runInAction(() => {
            if ((this.props.steps.length * 180) < this.containerWidth) {
                this.containerWidthStyle = "100%";
            } else {
                this.containerWidthStyle = "fit-content";
            }
        });
    }, 100);

    private getType(step: WorkflowStepStore): "correct" | "incorrect" | "inactive" | "notvalidated" {
        if (step.disabled) {
            return "inactive";
        }
        switch (step.validation) {
            case WorkflowValidation.Correct:
                return "correct";
            case WorkflowValidation.InCorrect:
                return "incorrect";
            case WorkflowValidation.NotValidated:
            default:
                return "notvalidated";
        }
    }
    @State.bound
    private onStepSelectedHandlerFactory(stepStore: WorkflowStepStore) {
        return () => {
            if (this.props.onStepSelected && stepStore !== this.props.selectedStep && !stepStore.disabled) {
                this.props.onStepSelected(stepStore);
            }
        };
    }

    private getReversedArray() {
        return this.props.steps ? this.props.steps.slice(0).reverse() : [];
    }

    @State.bound
    private scrollLeft(): void {
        this.container.scrollHorizontal(this.container.getScrollLeft() - 80);
    }

    @State.bound
    private scrollRight(): void {
        this.container.scrollHorizontal(this.container.getScrollLeft() + 80);
    }

    public render() {
        return (
            <SizeMeasurer onResize={this.checkOverflow} className={styles.workflowStepContainerOuterDiv}>
                {this.isScroll && <Ui.Button iconName="angleLeft" className={styles.workflowStepButtonScrollingLeft} onClick={this.scrollLeft} automationId="scrollLeftButton" />}
                <Ui.ScrollView
                    ref={sc => this.container = sc}
                    style={{ width: "100%", height: "54px" }}
                    wheelMode="horizontal"
                >
                    <div className={styles.workflowStepContainer} style={{ width: this.containerWidthStyle }}>
                        {this.getReversedArray().map((stepStore, index) => {
                            return <WorkflowStep
                                number={this.props.steps.length - index}
                                title={stepStore.title}
                                key={index}
                                isSelected={stepStore === this.props.selectedStep}
                                type={this.getType(stepStore)}
                                onStepSelected={this.onStepSelectedHandlerFactory(stepStore)}
                                automationId={"workflowStep_" + (this.props.steps.length - index)}
                                tooltipContent={stepStore.tooltipContent}
                                tooltipTextAlign="center"
                            />;
                        })}
                    </div>
                </Ui.ScrollView>
                {this.isScroll && <Ui.Button iconName="angleRight" className={styles.workflowStepButtonScrollingRight} onClick={this.scrollRight} automationId="scrollRightButton" />}
            </SizeMeasurer>
        );
    }
}
