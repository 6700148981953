import React, { useRef, useEffect } from "react";
import IEditableLayoutBlockStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/IEditableLayoutBlockStore";
import EditableLayoutRowBlock from "./EditableLayoutRowBlock";
import EditableLayoutRowBlockStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableLayoutRowBlockStore";
import { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import IEditableLayoutStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/IEditableLayoutStore";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import { useDrag } from "react-dnd";
import IDragData from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/IDragData";
import EditableLayoutGroupBlock from "./EditableLayoutGroupBlock";
import EditableLayoutGroupBlockStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableLayoutGroupBlockStore";
import EditableLayoutCustomBlockStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableLayoutCustomBlockStore";
import EditableLayoutCustomBlock from "./EditableLayoutCustomBlock";

export default function EditableLayoutAnyBlock(props: { block: IEditableLayoutBlockStore, parentCollection: IObservableArray<IEditableLayoutStore> }) {

    const store = useFormLayoutEditorStore();
    const ref = useRef(null);

    const [{ opacity, isDragging }, drag] = useDrag({
        type: "block",
        item: {
            isNew: false,
            type: "row",
            store: props.block,
            sourceCollection: props.parentCollection,
            dragItemType: "block"
        } as IDragData,
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
            isDragging: monitor.isDragging()
        })
    }, [props.block, props.parentCollection]);

    useEffect(() => {
        if (isDragging) {
            store.startDraggingElement();
        } else {
            store.stopDraggingElement();
        }
    }, [isDragging]);

    drag(ref);

    if (props.block instanceof EditableLayoutGroupBlockStore) {
        return <EditableLayoutGroupBlock ref={ref} opacity={opacity} block={props.block} parentCollection={props.parentCollection} />;
    }

    if (props.block instanceof EditableLayoutRowBlockStore) {
        return <EditableLayoutRowBlock ref={ref} opacity={opacity} block={props.block} parentCollection={props.parentCollection} />;
    }

    if (props.block instanceof EditableLayoutCustomBlockStore) {
        return <EditableLayoutCustomBlock ref={ref} opacity={opacity} block={props.block} parentCollection={props.parentCollection} />;
    }

    return null;
}
