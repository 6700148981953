import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import React from "react";
import { ScreenStateContextStore, ScreenStateContextAdapter } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { provideDocumentScreenStore, useDocumentScreenStore } from "./DocumentScreenStoreProvider";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import ImageViewer, { ValidMimeTypesForImageAndPdfViewer } from "@CommonControls/ImageViewer/ImageViewer";
import { isNullOrUndefined, nullFunction } from "@Toolkit/CommonWeb/NullCheckHelpers";
import DocumentScreenStore from "./DocumentScreenStore";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import { Button, ContextMenu, SaveButton } from "@CommonControls";
import BinaryDocumentViewer from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/BinaryDocumentViewer/BinaryDocumentViewer";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import { HisDocumentEditor } from "@HisPlatform/Components/HisPlatformControls";
import ShowCreateNewPatientRelatedDocumentScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowCreateNewPatientRelatedDocumentScreenAction.g";
import ShowPatientRelatedDocumentScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientRelatedDocumentScreenAction.g";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ActionBoundButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundButton";
import ActionBoundSaveButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundSaveButton";

export interface IDocumentScreenProps extends IScreenPropsBase<ShowCreateNewPatientRelatedDocumentScreenAction | ShowPatientRelatedDocumentScreenAction> {
    _screenState?: ScreenStateContextStore;
}

function renderToolbar(store: DocumentScreenStore) {
    return (
        <>
            <Button
                text={StaticWebAppResources.Common.Button.Cancel}
                onClickAsync={store.onCancelAsync}
                automationId="__cancel" />
            {store.isTemplateDocument &&
                <>
                    <Button
                        text={store.screenResources.Button.RefreshTemplateBasedDocument}
                        disabled={!store.isRefreshable}
                        onClickAsync={store.refreshTemplateBasedDocumentAsync}
                        automationId="__refresh"
                        iconName="sync" />
                    <ActionBoundButton
                        text={store.screenResources.Button.PrintPreview}
                        disabled={!store.isPrintable}
                        onExecuteAsync={store.onPreviewAsync}
                        actionDescriptor={store.checkViewPatientRelatedDocumentsPrintPreviewPermission}
                        automationId="__printPreview"
                        iconName="print" />
                    {store.isPublishable && <Button
                        text={store.screenResources.Button.Publish}
                        disabled={!store.isPublishable}
                        onClickAsync={store.onPublishAsync}
                        automationId="__publish"
                        iconName="checkDouble" />}
                    {store.isFinalizable && <ActionBoundButton
                        text={store.screenResources.Button.Finalize}
                        disabled={!store.isFinalizable}
                        onExecuteAsync={store.onFinalizeAsync}
                        actionDescriptor={store.checkEditPatientRelatedDocumentsPermission}
                        automationId="__finalize"
                        iconName="checkDouble" />}
                    <ActionBoundSaveButton
                        disabled={!store.isSaveable}
                        onExecuteAsync={store.onSaveAsync}
                        actionDescriptor={store.checkEditPatientRelatedDocumentsPermission}
                        automationId="__save" />
                    {renderContextMenu(store)}
                </>
            }
        </>
    );
}

function renderContextMenu(store: DocumentScreenStore) {
    if (isNullOrUndefined(store.document)) {
        return null;
    }

    return (<>
        <ContextMenu.Provider key={"document_actions_menu_provider"} id="document_actions_menu" event="onClick">
            <Button
                key={"document_actions_menu_provider_button"}
                iconName="more"
                onClick={nullFunction}
                tooltipContent={StaticWebAppResources.Common.Button.FurtherActions}
                tooltipPosition="bottom"
                disabled={false}
                automationId="moreButton"
            />
        </ContextMenu.Provider>
        <ContextMenu id="document_actions_menu" key="document_actions_menu">
            <ContextMenu.Item
                onClickAsync={store.refreshTemplateBasedDocumentAsync}
                disabled={!store.isRefreshable}>
                <SpanWithIcon iconName="sync">{store.screenResources.Button.RefreshTemplateBasedDocument}</SpanWithIcon>
            </ContextMenu.Item>
            <ContextMenu.Item
                onClickAsync={store.onPreviewAsync}
                disabled={!store.isPrintable}>
                <SpanWithIcon iconName="print">{store.screenResources.Button.PrintPreview}</SpanWithIcon>
            </ContextMenu.Item>

            <ContextMenu.Item onClickAsync={store.onSaveAsync}
                disabled={!store.isSaveable}>
                <SpanWithIcon iconName="check">{StaticWebAppResources.Common.Button.Save}</SpanWithIcon>
            </ContextMenu.Item>

            <ContextMenu.Item onClickAsync={store.onPublishAsync}
                disabled={!store.isPublishable} >
                <SpanWithIcon iconName="checkDouble">{store.screenResources.Button.Publish}</SpanWithIcon>
            </ContextMenu.Item>

            <ContextMenu.Item onClickAsync={store.onCancelPublishAsync}
                disabled={!store.isPublishCancellable} >
                <SpanWithIcon iconName="circleArrowCcw">{store.screenResources.Button.CancelPublish}</SpanWithIcon>
            </ContextMenu.Item>

            <ContextMenu.Item onClickAsync={store.onFinalizeAsync}
                disabled={!store.isFinalizable} >
                <SpanWithIcon iconName="checkDouble">{store.screenResources.Button.Finalize}</SpanWithIcon>
            </ContextMenu.Item>

            <ContextMenu.Item onClickAsync={store.onEditAsync}
                disabled={!store.isEditable} >
                <SpanWithIcon iconName="circleArrowCcw" >{store.screenResources.Button.EditDocument}</SpanWithIcon>
            </ContextMenu.Item>

            <ContextMenu.Separator />

            <ContextMenu.Item onClickAsync={store.onDocumentHistoryAsync}
                disabled={!store.isDocumentHistorized} >
                <SpanWithIcon iconName="document_history">{store.screenResources.Button.ViewHistory}</SpanWithIcon>
            </ContextMenu.Item>

            <ContextMenu.Item onClickAsync={store.onDeleteAsync}
                disabled={!store.isDeletable} >
                <SpanWithIcon iconName="trash">{store.screenResources.Button.Delete}</SpanWithIcon>
            </ContextMenu.Item>
        </ContextMenu>
    </>
    );
}

function renderDocument(store: DocumentScreenStore) {
    if (isNullOrUndefined(store.document)) {
        return null;
    }

    if (store.isBinaryDocument) {
        if (store.binaryDocument.content.type !== ValidMimeTypesForImageAndPdfViewer.Pdf) {
            return (
                <ImageViewer
                    alt={StaticDocumentManagementResources.DocumentManagementMasterDetailPanel.AttachedImage.Alt}
                    data={store.binaryDocument.content}
                />
            );
        }
        return (
            <BinaryDocumentViewer
                isReadOnly={store.vIsReadOnly}
                documentPath={store.document.largeDataToken}
                downloadFileName={store.document.downloadFileName}
                printPermissionCheckOperationName="PrintPreview"
            />
        );
    } else if (store.isTemplateDocument) {
        return (
            <HisDocumentEditor
                isReadOnly={store.vIsReadOnly}
                mode="Document"
                contentStore={store.templateDocument.documentFile.contentStore.forEditor}
            />
        );
    } else {
        return null;
    }
}

function DocumentScreen(props: IDocumentScreenProps) {

    const store = useDocumentScreenStore();

    return (
        <Screen
            title={store.selectedDocumentName}
            subTitle={store.selectedDocumentStatusValue}
            toolbar={renderToolbar(store)}
        >
            <>{renderDocument(store)}</>
            <NavigateAwayHook isEnabled onNavigateAwayAsync={store.navigateAwayAsync} />
        </Screen>
    );
}

export default connect<IDocumentScreenProps>(provideDocumentScreenStore(DocumentScreen),
    new ScreenStateContextAdapter());
