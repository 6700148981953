import PointOfCareId from "@Primitives/PointOfCareId.g";
import RoleId from "@Primitives/RoleId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import {wrappedValuesAreEquals} from "@HisPlatform/Common/ValueWrapperHelpers";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import PointOfCareRoleAssignmentRule from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Actions/PointOfCareRoleAssignmentRule";

export default class PointOfCareRoles {
    public readonly id: PointOfCareId;
    @State.observable.ref public roleIds: RoleId[];

    constructor(
        id: PointOfCareId,
        roleIds: RoleId[]
    ) {
        this.id = id;
        this.roleIds = roleIds;
    }

    @State.bound
    public setRoleIds(roleIds: RoleId[]) {
        this.roleIds = roleIds;
    }

    public static mapToPointOfCareRoles(roleAssignmentRules: PointOfCareRoleAssignmentRule[]) {
        const newSet: PointOfCareRoles[] = [];
        for (const role of roleAssignmentRules) {
            for (const id of role.pointOfCareIds) {
                const pointOfCareRole = newSet.find(item => wrappedValuesAreEquals(item.id, id));
                if (pointOfCareRole && !pointOfCareRole.roleIds.some(item => wrappedValuesAreEquals(item, role.roleId))) {
                    pointOfCareRole.roleIds.push(role.roleId);
                } else {
                    const newItem = new PointOfCareRoles(id, [role.roleId]);
                    newSet.push(newItem);
                }
            }
        }
        return State.observable(newSet);
    }

    public static mergeRoleAssignments(pointOfCareRoles: PointOfCareRoles[], roleAssignmentRules: PointOfCareRoleAssignmentRule[]) {
        const resultArray: PointOfCareRoleAssignmentRule[] = [];
        for (const item of pointOfCareRoles) {
            for (const role of item.roleIds) {
                const roleItem = resultArray.find(i => wrappedValuesAreEquals(i.roleId, role));
                if (roleItem && !roleItem.pointOfCareIds.some(id => wrappedValuesAreEquals(id, item.id))) {
                    roleItem.pointOfCareIds.push(item.id);
                } else {
                    const existingItem = roleAssignmentRules.find(i => wrappedValuesAreEquals(i.roleId, role));
                    resultArray.push(new PointOfCareRoleAssignmentRule(role, [item.id], existingItem ? existingItem.rowVersion : new RowVersion(0)));
                }
            }
        }

        for (const rule of roleAssignmentRules) {
            const existingRule = resultArray.find(item => wrappedValuesAreEquals(rule.roleId, item.roleId));
            if (!existingRule) {
                resultArray.push(new PointOfCareRoleAssignmentRule(rule.roleId, [], rule.rowVersion));
            }
        }

        return resultArray;
    }
}
