import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import INewPatientBaseDataRepository from "@HisPlatform/BoundedContexts/Care/Services/Definition/NewPatientBaseDataRepository/INewPatientBaseDataRepository";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import { createAsyncErrorDispatcher, dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import AvailablePointOfCareListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Actions/AvailablePointOfCareListStore";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";

import PatientWorklistPanelView from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/PatientWorklist/PatientWorklistPanelView";
import IUseCaseNavigatorParameters from "@PluginInterface/UseCases/IUseCaseNavigatorParameters";
import Route from "@Toolkit/ReactClient/Routing/Abstractions/Route";
import PatientFilterStore from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/PatientWorklist/Filters/PatientFilterStore";
import { IPagingState, IRowBody, RowId } from "@CommonControls/DataGrid/IDataGridProps";
import { ICondition } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistFilterCondition";
import FilterConditionConverters from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/FilterConditionConverters";
import DynamicValueConverter from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ValueConverters/DynamicValueConverter";
import NavigateToUseCaseArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/NavigateToUseCaseArgument";
import Action from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/Action";
import UseCaseNavigationAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/UseCaseNavigationAction";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import IWorklistItemDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistItemDefinition";
import _ from "@HisPlatform/Common/Lodash";
import PatientAppointmentsBody from "./RowBody/PatientAppointmentsBody";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import INDataRow from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataRow";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import PatientApiAdapter2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/PatientApiAdapter2";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import { ScreenNavigationContextAdapter, ScreenNavigationContextStore } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import { ActionDispatcherAdapter } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import IShowScreenActionCallContextParams from "@HisPlatform/Services/Definition/ActionProcessing/IShowScreenActionCallContextParams";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import FrontendListRequestMode from "@HisPlatform/BoundedContexts/Productivity/Components/NDataGrid/FrontendListRequestMode";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import SelectItemClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/SelectItemClientSideAction";
import PatientId from "@Primitives/PatientId.g";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";

interface IPatientWorklistPanelDependencies {
    availablePointOfCareIdListStore: AvailablePointOfCareListStore;
    routingStore: GlobalRoutingStore;
    applicationContext: ApplicationContext;
    worklistApiAdapter: WorklistApiAdapter;
    useCaseRegistry: IUseCaseRegistry;
    newPatientBaseDataRepository: INewPatientBaseDataRepository;
    dynamicValueConverter: DynamicValueConverter;
    patientSearchApiAdapter: PatientApiAdapter2;
}

interface IPatientWorklistPanelProps extends IRoutingFrameContentProps {
    _dependencies?: IPatientWorklistPanelDependencies;
    _modalService?: IModalService;
    _actionDispatcher?: IActionDispatcher;
    _screenNavigationContext?: ScreenNavigationContextStore;
    smartSearch?: string;
    luckyMode?: boolean;
    restoreFilter?: boolean;
    navigate?: (useCase: UseCaseIdentifier, useCaseArguments: UseCaseArgument[], displayMode: UseCaseDisplayMode, routeFactory: (params: IUseCaseNavigatorParameters) => Route) => void;
    navigateToNewPatient?: () => void;
    navigateToNewAppointment?: () => void;
    editUnregisteredPatientAppointment?: (appointment: any) => void;
    editPatientAppointment?: (appointment: any) => void;
    editPatientServiceRequest?: (appointment: any) => void;
    mode: "List" | "Selector";
    onSelectPatient?: (patientId: PatientId) => void;
    clearFiltersEvent?: TypedEvent;
    onFilterStoreCreated?: () => void;
}

@State.observer
class PatientWorklistPanel extends React.Component<IPatientWorklistPanelProps> {

    private runAsync = createAsyncErrorDispatcher(this);
    @State.observable.ref private worklistDefinition: IWorklistDefinition = null;
    @State.observable.ref private filterStore: PatientFilterStore = null;
    @State.observable private isFilterDetailVisible: boolean = false;
    @State.observable.ref public paging: IPagingState = { currentPage: 0, pageSize: 10 };
    private clearFiltersEventHandler: IDisposable = null;

    private readonly currentUseCaseIdentifier = new UseCaseIdentifier("PatientRegister_SearchPatient");

    private readonly editPatientActionReference: string = "PatientRegister_EditPatientData";

    private get useCaseRegistry() {
        return this.props._dependencies.useCaseRegistry;
    }
    private get dynamicValueConverter() {
        return this.props._dependencies.dynamicValueConverter;
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);
    public componentDidMount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(this.currentUseCaseIdentifier);
        this.runAsync(this.initialLoadPanelAsync());
    }

    public componentWillUnmount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(null);
        if (this.clearFiltersEventHandler) {
            this.clearFiltersEventHandler.dispose();
        }
    }

    public componentDidUpdate(prevProps: IPatientWorklistPanelProps) {
        if (prevProps.smartSearch !== this.props.smartSearch || prevProps.restoreFilter !== this.props.restoreFilter) {
            if (this.filterStore) {
                if (this.props.restoreFilter === false) {
                    this.filterStore.clearAllFilters();
                }
                if (!isNullOrUndefined(this.props.smartSearch)) {
                    this.filterStore.setGeneralSearch(this.props.smartSearch);
                    if (!!this.props.luckyMode) {
                        this.runAsync(this.loadPatientDataAsync());
                    }
                }
            }
        }
    }

    @State.bound
    private async loadAsync() {
        const [worklistDefinition] = await Promise.all([this.props.mode === "List"
            ? this.props._dependencies.worklistApiAdapter.getPatientBoundWorklistDefinition()
            : this.props._dependencies.worklistApiAdapter.getPatientSelectorWorklistDefinition(),
        this.props._dependencies.availablePointOfCareIdListStore.loadAvailablePointOfCareListAsync()]);

        State.runInAction(() => {
            this.worklistDefinition = worklistDefinition.value;
        });
    }

    @State.action.bound
    private setIsExtendedFilterVisible(newValue: boolean) {
        this.isFilterDetailVisible = newValue;
        if (!newValue) {
            this.filterStore.clearFilters();
        }
    }

    private get worklistApiAdapter() {
        return this.props._dependencies.worklistApiAdapter;
    }

    @State.action.bound
    private async onFilterStoreCreatedAsync(filterStore: IFilterStore) {
        this.filterStore = new PatientFilterStore(filterStore);

        if (this.props.clearFiltersEvent) {
            this.clearFiltersEventHandler = this.props.clearFiltersEvent.on(() => {
                this.filterStore.clearAllFilters();
            });
        }

        if (this.props.onFilterStoreCreated) {
            this.props.onFilterStoreCreated();
        }

        if (this.props.smartSearch) {
            this.filterStore.setGeneralSearch(this.props.smartSearch);
            if (!!this.props.luckyMode) {
                await this.loadPatientDataAsync();
            }
        }
    }

    @State.bound
    private getExtendedFilterDescriptors() {
        return PatientFilterStore.getFilterDescriptors();
    }

    private async loadPatientDataAsync() {

        const filters = {};

        filters["PatientBased_GeneralPatientSearchFilter"] = this.getGeneralSearchFilterCondition();

        const results = await this.worklistApiAdapter.getWorklistDataByDefinitionId(
            this.worklistDefinition.worklistToken,
            this.paging.currentPage,
            this.paging.pageSize,
            null,
            filters,
            null
        );

        if (results.operationWasSuccessful) {
            if (results.value.items?.items.length === 1) {

                const matchedPatient = results.value.items.items[0];
                const editPatientAction = matchedPatient.actions.find(a => a.activityReference === this.editPatientActionReference);

                if (!editPatientAction) {
                    return;
                }

                const argument = new NavigateToUseCaseArgument(editPatientAction.useCaseDisplayMode);

                await this.processActionTokenAsync(matchedPatient.id, editPatientAction.commandToken, argument);
            }
        }
    }

    private async processActionTokenAsync(rowId: any, actionToken: any, argument: NavigateToUseCaseArgument) {
        const result = await this.worklistApiAdapter.executeActionTokenAsync(actionToken, argument);
        this.processClientAction(result.value, rowId);
    }

    private processClientAction(action: Action, rowId: any) {
        if (action instanceof UseCaseNavigationAction) {
            this.navigateToUseCase(rowId, { useCase: action.useCase, useCaseArguments: action.useCaseArguments, displayMode: action.displayMode });
        }
    }

    @State.bound
    private getGeneralSearchFilterCondition(): ICondition {
        const descriptor = this.getExtendedFilterDescriptors().find(i => i.id === "PatientBased_GeneralPatientSearchFilter");

        const conditionConverter = FilterConditionConverters[descriptor.type];

        if (!conditionConverter) {
            throw new Error(`Unknown item definition attribute type: ${descriptor.type}`);
        }

        return conditionConverter(this.filterStore.generalSearch);
    }

    @State.action.bound
    private navigateToUseCase(rowId: RowId, useCaseState: INDataUseCaseState) {

        if (!useCaseState) {
            return;
        }

        const converter = this.useCaseRegistry.tryGetScreenAdapter(useCaseState.useCase);

        if (converter) {
            const action = converter(useCaseState.useCase, useCaseState.useCaseArguments);
            dispatchAsyncErrors(this.props._actionDispatcher!.dispatchAsync<IShowScreenActionCallContextParams>(
                action.action,
                { navigationContext: this.props._screenNavigationContext, modalService: this.props._modalService }
            ), this);
            return;
        }

        const useCase = useCaseState.useCase;
        const useCaseArguments = useCaseState.useCaseArguments;
        const useCaseDescriptor = this.useCaseRegistry.get(useCase.value);

        if (!useCaseDescriptor) {
            throw new Error(`Cannot find use case: ${useCase.value}`);
        }

        const routeFactory = useCaseState.displayMode === UseCaseDisplayMode.MasterDetail ?
            (useCaseDescriptor.masterDetailRouteFactory || useCaseDescriptor.standaloneRouteFactory) :
            useCaseDescriptor.standaloneRouteFactory;

        this.props.navigate(useCase, useCaseArguments, useCaseState.displayMode, routeFactory);
    }

    @State.bound
    private performClientSideActionAsync(action: ClientSideActionDto) {
        if (action instanceof SelectItemClientSideAction) {
            this.props.onSelectPatient(new PatientId(action.entityId.value));
        }
        return Promise.resolve(null);
    }

    @State.bound
    private renderRowBody(row: INDataRow, rowId: RowId, rowIndex: number, rowBodyItemDefinition: IWorklistItemDefinition): IRowBody {

        if (!!rowBodyItemDefinition) {
            const rawValue = _.get(row, rowBodyItemDefinition.attributeName);

            if (!!rawValue && rowBodyItemDefinition.attributeType === "PatientWorklistRelatedAppointmentInfoDto") {
                return {
                    showCells: true,
                    content: (
                        <PatientAppointmentsBody
                            rawJsonValue={rawValue}
                            editUnregisteredPatientAppointment={this.props.editUnregisteredPatientAppointment}
                            editPatientAppointment={this.props.editPatientAppointment}
                            editPatientServiceRequest={this.props.editPatientServiceRequest}
                        />
                    )
                };
            }
        }
        return null;
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticWebAppResources.SearchPatient.Label.Main} />;
        }

        return (
            <PatientWorklistPanelView
                handleNewPatientClick={this.props.navigateToNewPatient}
                handleNewAppointmentClick={this.props.navigateToNewAppointment}
                navigateToUseCase={this.navigateToUseCase}
                worklistDefinition={this.worklistDefinition}
                filterStore={this.filterStore && this.filterStore}
                isExtendedFilterVisible={this.isFilterDetailVisible}
                onExtraFilterVisibilityChanged={this.setIsExtendedFilterVisible}
                onClearFilter={this.filterStore && this.filterStore.clearFilters}
                onFilterStoreCreatedAsync={this.onFilterStoreCreatedAsync}
                onGetExtendedFilterDescriptors={this.getExtendedFilterDescriptors}
                onRenderRowBody={this.renderRowBody}
                onGetDynamicListAsync={this.getDynamicListAsync}
                mode={this.props.mode}
                onPerformClientSideActionAsync={this.performClientSideActionAsync}
            />
        );
    }

    @State.bound
    private async getDynamicListAsync(requestMode: FrontendListRequestMode, pageIndex: number, pageSize: number, orderBy: { fieldName: string, ascending: boolean }, filter: { [fieldName: string]: ICondition }) {
        const result = await this.props._dependencies.patientSearchApiAdapter.searchPatientAsync(new FrontendListParameters(
            requestMode,
            pageIndex,
            pageSize,
            orderBy,
            filter));

        return result.result;
    }
}

export default connect(
    withHisErrorBoundary(PatientWorklistPanel),
    new DependencyAdapter<IPatientWorklistPanelProps, IPatientWorklistPanelDependencies>(container => {
        return {
            newPatientBaseDataRepository: container.resolve("INewPatientBaseDataRepository"),
            availablePointOfCareIdListStore: container.resolve("AvailablePointOfCareListStore"),
            routingStore: container.resolve("GlobalRoutingStore"),
            applicationContext: container.resolve("ApplicationContext"),
            worklistApiAdapter: container.resolve("WorklistApiAdapter"),
            useCaseRegistry: container.resolve("IUseCaseRegistry"),
            dynamicValueConverter: container.resolve("DynamicValueConverter"),
            patientSearchApiAdapter: container.resolve("PatientApiAdapter2"),
        };
    }),
    new HisModalServiceAdapter(),
    new ActionDispatcherAdapter(),
    new ScreenNavigationContextAdapter()
);