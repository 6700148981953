import React from "react";
import * as HisUi from "@HisPlatformControls";
import * as Ui from "@CommonControls";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import ReferralData from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityBaseDataPanel/ReferralData";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import BookSlotDialogSettings from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/BookAppointmentDialog/BookSlotDialogSettings";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import BookSlotDialogPractitionerSelectBox from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/BookAppointmentDialog/BookSlotDialogPractitionerSelectBox";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import BookSlotDialogPointOfCareSelectBox from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/BookAppointmentDialog/BookSlotDialogPointOfCareSelectBox";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import AppointmentInvalidationReason from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentInvalidationReason.g";
import PractitionerSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerSelectBox";

interface IBookSlotDialogViewDependencies {
    localizationService: ILocalizationService;
}

interface IBookSlotDialogViewProps {
    _dependencies?: IBookSlotDialogViewDependencies;
    onClose: () => void;
    saveAsync: () => Promise<void>;
    pointOfCareIds: PointOfCareId[];
    practitionerOptions: PractitionerId[][];
    isPointOfCareReadOnly: boolean;
    isPractitionersReadOnly: boolean;
    bookableInterval: string;
    bookDate: string;
    serviceName: string;
    serviceCode: string;
    appointment: Appointment;
    settings: BookSlotDialogSettings;
    index: number;
    onValidateAsync: () => Promise<IClientValidationResult[]>;
    isTelemedicineConsultation: boolean;
    isAdditionalParticipantsAllowed: boolean;
}

@State.observer
class BookSlotDialogView extends React.Component<IBookSlotDialogViewProps> { // TODO add appointment length to message
    public render() {
        return (
            <HisUi.ContextAwareModal
                title={StaticSchedulingResources.BookSlotDialog.CreateAppointment}
                isOpen
                onClose={this.props.onClose}
                size="compact"
                closeButton
                closeOnOutsideClick={false}
                closeOnEscape={false}
                index={this.props.index}>
                <Ui.Modal.Body>
                    <ValidationBoundary
                        entityTypeName={"Appointment"}
                        onValidateAsync={this.props.onValidateAsync}
                        validationResults={this.props.appointment?.validationResults || []}
                        validateOnMount
                    >
                        <Ui.InfoBox iconName="info_with_circle">
                            <div>
                                {StaticSchedulingResources.BookSlotDialog.SelectedDate} <b data-automation-id="__bookSlotDialogDate">{this.props.bookDate}</b>
                            </div>
                            <div>
                                {StaticSchedulingResources.BookSlotDialog.selectedServiceName}<b>{this.props.serviceCode}</b> {this.props.serviceName}
                            </div>
                            {
                                this.props.isTelemedicineConsultation &&
                                <SpanWithIcon iconName="telemedicine" automationId="telemedicine">{StaticSchedulingResources.AppointmentSchedulePanel.TelemedicineConsultation}</SpanWithIcon>
                            }
                            {
                                !!this.props.settings.invalidationReason &&
                                <div>
                                    {StaticSchedulingResources.BookSlotDialog.InvalidationReason}
                                    {this.props._dependencies.localizationService.localizeEnum(AppointmentInvalidationReason[this.props.settings.invalidationReason], "AppointmentInvalidationReason").Name}
                                </div>
                            }
                        </Ui.InfoBox>
                        <div style={{ marginBottom: 8 }}>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={6}>
                                    <BookSlotDialogPointOfCareSelectBox
                                        label={StaticSchedulingResources.BookSlotDialog.WorkPlace}
                                        value={this.props.appointment.pointOfCareId}
                                        explicitIds={this.props.pointOfCareIds}
                                        onChange={this.props.appointment.setPointOfCareId}
                                        isReadOnly={this.props.settings.readOnly || this.props.isPointOfCareReadOnly}
                                        automationId="__bookSlotDialogPointOfCareSelectBox"
                                        reselectOnUpdate={!isNullOrUndefined(this.props.appointment.pointOfCareId)}
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={6}>
                                    <BookSlotDialogPractitionerSelectBox
                                        label={StaticSchedulingResources.BookSlotDialog.Practitioner}
                                        value={this.props.appointment.practitionerIds}
                                        explicitIds={this.props.practitionerOptions}
                                        onChange={this.props.appointment.setPractitionerIds}
                                        isReadOnly={this.props.settings.readOnly || this.props.isPractitionersReadOnly}
                                        automationId="__bookSlotDialogPractitionerSelectBox"
                                        reselectOnUpdate={!arrayIsNullOrEmpty(this.props.appointment.practitionerIds)}
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            {this.props.isAdditionalParticipantsAllowed && <Ui.Flex>
                                <Ui.Flex.Item xs={6}>
                                    <HisUi.PractitionerCodeSelector
                                        label={StaticSchedulingResources.BookSlotDialog.AdditionalPractitioners}
                                        value={this.props.appointment.additionalPractitionerIds}
                                        onChange={this.props.appointment.setAdditionalPractitionerIds}
                                        isReadOnly={this.props.settings.readOnly}
                                        automationId="__additionalPractitioners"
                                        isExternal={false}
                                        multiSelect
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={6}>
                                    <Ui.TextBox
                                        label={StaticSchedulingResources.BookSlotDialog.AdditionalParticipants}
                                        value={this.props.appointment.additionalParticipants}
                                        onChange={this.props.appointment.setAdditionalParticipants}
                                        propertyIdentifier="AdditionalParticipants"
                                        isReadOnly={this.props.settings.readOnly}
                                        automationId="__additionalParticipants"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>}
                            <Ui.Flex>
                                <Ui.Flex.Item xs={this.props.appointment.unregisteredPatient ? 12 : 6}>
                                    <Ui.TextBox
                                        label={StaticSchedulingResources.BookSlotDialog.Note}
                                        value={this.props.appointment.description}
                                        onChange={this.props.appointment.setDescription}
                                        propertyIdentifier="Description"
                                        isReadOnly={this.props.settings.readOnly}
                                        automationId="__bookSlotDialogNote"
                                    />
                                </Ui.Flex.Item>
                                {
                                    !this.props.appointment.unregisteredPatient && (
                                        <HisUi.EpisodeOfCareSelectBox
                                            hasAddNew={true}
                                            patientId={this.props.appointment.patientId}
                                            value={this.props.appointment.episodeOfCareIds}
                                            onChange={this.props.appointment.setEpisodeOfCareIds}
                                            width={6}
                                            multiSelect
                                            automationId="__episodeOfCareSelectBox" />
                                    )
                                }
                            </Ui.Flex>
                        </div>
                        <ValidationBoundary pathPrefix="Referral">
                            <ReferralData
                                referral={this.props.appointment.referral}
                                readOnly={this.props.settings.readOnly || this.props.settings.referralReadOnly}
                                equalWidth
                            />
                        </ValidationBoundary>
                    </ValidationBoundary>
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Flex xsJustify="between" verticalSpacing="none">
                        <Ui.Flex.Item>
                            <Ui.Button
                                text={StaticSchedulingResources.BookSlotDialog.Cancel}
                                onClick={this.props.onClose}
                                visualStyle="link"
                                automationId="__bookSlotDialogCancelButton"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item>
                            {!this.props.settings.readOnly &&
                                <Ui.Button
                                    text={StaticSchedulingResources.BookSlotDialog.BookAppointment}
                                    disabled={!this.props.appointment.hasPractitionerOrPointOfCare}
                                    visualStyle="primary"
                                    onClickAsync={this.props.saveAsync}
                                    automationId="__bookSlotDialogBookSlotButton"
                                />
                            }
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.Modal.Footer>
            </HisUi.ContextAwareModal>);
    }
}

export default connect(
    BookSlotDialogView,
    new DependencyAdapter<IBookSlotDialogViewProps, IBookSlotDialogViewDependencies>(c => {
        return {
            localizationService: c.resolve("ILocalizationService")
        };
    })
);
