import Di from "@Di";
import IPatientCareActivitiesTabComponentService from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabComponentService";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import HisPermissionScopes from "@HisPlatform/Common/FrontendActions/HisPermissionScopes";
import CareActivityId from "@Primitives/CareActivityId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowReadOnlyPerformedServicesScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyPerformedServicesScreenAction.g";

@Di.injectable()
export default class PerformedServicesTabComponentService implements IPatientCareActivitiesTabComponentService {

    constructor(@Di.inject("AuthorizationService") private authorizationService: AuthorizationService) {
    }

    public isTabAllowedAsync(careActivityId: CareActivityId, organizationUnitId: OrganizationUnitId): Promise<boolean> {
        const ehrPerformedServicesAction = new ShowReadOnlyPerformedServicesScreenAction(
            ScreenDisplayMode.ContentOnly,
            careActivityId);

        const ehrPerformedServicesActionHasPermission = this.authorizationService.hasPermissionFor(ehrPerformedServicesAction.id,
            HisPermissionScopes.pointOfCare(organizationUnitId?.value));

        return Promise.resolve(ehrPerformedServicesActionHasPermission);
    }
}