import StoreMapper from "@Toolkit/CommonWeb/ApiAdapter/StoreMapper";
import MedicationSubsidy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationSubsidy";
import { MedicationSubsidyVersionDto, MedicationSubsidyOptionDto } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import MedicationSubsidyOption from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationSubsidyOption";
import SubsidyOptionRestriction from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/SubsidyOptionRestriction";
import Di from "@Di";

@Di.injectable()
export default class MedicationSubsidyStoreMapper extends StoreMapper<MedicationSubsidy, MedicationSubsidyVersionDto> {
    protected applyToStoreCore(target: MedicationSubsidy, response: MedicationSubsidyVersionDto): void {
        target.id = response.id;
        target.medicationId = response.medicationId;
        target.dailyTherapyCost = response.dailyTherapyCost;
        target.grossSupport = response.grossSupport;
        target.netSupport = response.netSupport;
        target.price = response.price;
        target.medicationSubsidyTypeId = response.medicationSubsidyTypeId;
        target.pricingType = response.medicationSubsidizedPricingTypeId;
        target.supportPeriodMonths = response.supportPeriodMonths;
        target.supportPercentage = response.supportPercentage;
        target.options = response?.options?.map(i => this.mapSubsidyOption(i));
        target.supportGroupdId = response.supportGroupId;
        target.maxPrescribableAmount = response.maxPrescribableAmount;
        target.validity = response.validity;
    }

    public mapSubsidyOption(i: MedicationSubsidyOptionDto): MedicationSubsidyOption {
        return new MedicationSubsidyOption(
            i.id,
            i.code,
            i.description,
            i.name,
            i.indicationText,
            i.restrictionConditions,
            i.optionsRestrictions?.map(j => new SubsidyOptionRestriction(
                j.id,
                j.professionalExamId,
                j.prescriptionRestrictionType,
                j.organizationRestrictionTypeId,
                j.practitionerPrescriptionValidityDurationInMonths,
                j.designatedOrganizationId
            ))
        );
    }
}