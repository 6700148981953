import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import ISaveResult from "./ISaveResult";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

export default abstract class MasterDetailCrudPanelStoreBase<TProps, TId extends StringEntityId, TListItem, TDetail> extends PanelStoreBase<TProps> implements ILoadablePanelStore<TProps> {

    protected abstract getIdFromListItem(item: TListItem): TId;
    protected abstract getIdFromDetailItem(detail: TDetail): TId;
    protected abstract getSelectedIdFromProps(props: TProps): TId;
    protected abstract getSelectedIdMutatorFromProps(props: TProps): (value: TId) => void;
    protected abstract getNewDetailItemAsync(): Promise<TDetail>;
    protected abstract getNewId(): TId;

    protected abstract loadListCoreAsync(): Promise<PagedItemStore<TListItem>>;
    protected abstract loadDetailCoreAsync(id: TId): Promise<TDetail>;
    protected abstract saveCoreAsync(): Promise<ISaveResult<TId, TDetail>>;

    // ---

    @State.observable.ref public listItems: PagedItemStore<TListItem> = new PagedItemStore([], 0);
    @State.observable.ref public selectedDetail: TDetail | null = null;

    @State.computed public get selectedId(): TId | null {
        return this.selectedDetail ? this.getIdFromDetailItem(this.selectedDetail) : null;
    }

    @State.computed public get selectedListItem(): TListItem | null {
        return this.listItems.items.find(i => ValueWrapper.equals(this.getIdFromListItem(i), this.selectedId));
    }

    @State.computed public get isNewDetail(): boolean {
        return ValueWrapper.equals(this.selectedId, this.getNewId());
    }

    constructor(
        protected readonly notificationService: INotificationService
    ) {
        super();
    }

    public initialize() {
        this.reaction(() => {
            return this.getSelectedIdFromProps(this.props);
        }, selectedId => {
            this.loadDetailAsync.fireAndForget(selectedId);
        }, true);
    }

    public readonly loadAsync = this.async(async () => {
        const list = await this.loadListCoreAsync();
        this.setListLoadedState(list);
    });

    @State.action
    private setListLoadedState(listItems: PagedItemStore<TListItem>) {
        this.listItems = listItems;
        this.vSetListLoadedStateCore(listItems);
    }

    /** Override this method to customize setting the list loaded state.  */
    
    protected vSetListLoadedStateCore(listItems: PagedItemStore<TListItem>): void { }

    public readonly loadDetailAsync = this.async(async (id: TId) => {
        if (isNullOrUndefined(id)) {
            this.setDetailLoadedState(null);
            return;
        }

        if (id.value === "new") {
            this.setDetailLoadedState(await this.getNewDetailItemAsync());
            return;
        }

        const detail = await this.loadDetailCoreAsync(id);
        this.setDetailLoadedState(detail);
    });

    @State.action
    protected setDetailLoadedState(detail: TDetail) {
        this.selectedDetail = detail;
        this.vSetDetailLoadedStateCore(detail);
    }

    /** Override this method to customize setting the detail loaded state.  */
    
    protected vSetDetailLoadedStateCore(detail: TDetail) { }

    /** Override this method to customize setting the validation results after a failed save.  */
    
    protected vSetDetailValidationResultsCore(validationResults: IClientValidationResult[]) { }

    public readonly saveAsync = this.async(async () => {

        const saveResult = await this.saveCoreAsync();
        this.notificationService.showSaveResult(saveResult.isPersisted, saveResult.hasWarning);

        if (!saveResult.isPersisted) {
            if (saveResult.validationResults) {
                this.vSetDetailValidationResultsCore(saveResult.validationResults!);
            }
            return;
        }

        await this.loadAsync();

        if (ValueWrapper.equals(saveResult.id, this.getSelectedIdFromProps(this.props))) {
            // update
            if (saveResult.returnedStore) {
                this.setDetailLoadedState(saveResult.returnedStore);
            } else {
                await this.loadDetailAsync(saveResult.id);
            }
        } else {
            // create
            const mutator = this.getSelectedIdMutatorFromProps(this.props);
            mutator?.(saveResult.id);
        }
    });

    @State.bound
    public selectListItem(row: TListItem) {
        this.getSelectedIdMutatorFromProps(this.props)?.(this.getIdFromListItem(row));
    }

    @State.bound
    public createNew() {
        this.getSelectedIdMutatorFromProps(this.props)?.(this.getNewId());
    }

}