import React from "react";
import Styles from "./PageBox.less";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import { FlexProperty } from "csstype";

export interface IPageBoxBodyProps {
    hasNoGaps?: boolean;
    isFlexContainer?: boolean;
    flex?: FlexProperty<string>;
}

export default class PageBoxBody extends React.PureComponent<IPageBoxBodyProps> {
    public render() {
        const classes = new CompositeClassName();
        classes.addIf(this.props.hasNoGaps, Styles.content, Styles.contentGap);
        classes.addIf(this.props.isFlexContainer, Styles.flexContent);
        const style: React.CSSProperties = {};

        if (this.props.flex) {
            style.flex = this.props.flex;
        }
        
        return <div className={classes.classNames} style={style}>{this.props.children}</div>;
    }
}