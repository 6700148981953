import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/Proxy.g";
import Di from "@Di";
import EuPerformanceStatementId from "@Primitives/EuPerformanceStatementId.g";
import EuErrorList from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/EuErrorList";

@Di.injectable()
export default class EuErrorListStoreMapper extends EntityStoreMapper<Proxy.EuErrorListDto, EuErrorList> {
    protected storeEntityIdType = EuPerformanceStatementId;

    public applyToStoreCore(target: EuErrorList, response: Proxy.EuErrorListDto) {
        const entity = response;

        if (!!entity) {
            target.id = entity.id;
            target.rowVersion = entity.rowVersion;
            target.processLog = entity.processLog;
            target.hasProcessError = entity.hasProcessError;
        }
    }
}
