import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IDebug extends React.FC<any> {
    Floating: typeof FloatingDebug;
}


const Debug: IDebug = State.observer((props: any) => {
    return (
        <>
            <pre>{JSON.stringify(props, null, "    ")}</pre>
            {props.children}
        </>
    );
}) as any;

const floatingStyle: React.CSSProperties = {
    position: "fixed",
    right: 10,
    bottom: 10,
    width: "30vw",
    height: "10vw",
    overflow: "auto",
    opacity: 0.7,
    borderRadius: 10,
    backgroundColor: "black",
    color: "white"
};


const FloatingDebug: React.FC<any> = props => {
    return (
        <div style={floatingStyle}>
            <Debug {...props} />
        </div>
    );
};

Debug.Floating = FloatingDebug;

export default Debug;