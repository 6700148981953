import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCases from "@Primitives/UseCases";
import RiskAssessmentPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/RiskAssessment/RiskAssessmentPanel";
import RiskAssessmentId from "@Primitives/RiskAssessmentId.g";

export default function configureRiskAssessmentUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2({
        identifiers: [UseCases.riskAssessmentDetail],
        component: RiskAssessmentPanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));
            const id = map.get("riskAssessmentId") ?? new RiskAssessmentId("new");
            return {
                id: id,
            };
        }
    });
}
