import React from "react";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import SchedulerViewMode from "./SchedulerViewMode";
import SchedulerScale from "./SchedulerScale";
import { iconNameType } from "@CommonControls/Icon";
import * as Ui from "@CommonControls";

interface ISchedulerToolbarProps {
    scale: SchedulerScale;
    viewMode: SchedulerViewMode;

    onScaleChange: (scale: SchedulerScale) => void;
    onViewModeChange: (viewMode: SchedulerViewMode) => void;
    useSelectBox: boolean;
}


const SchedulerToolbar: React.FC<ISchedulerToolbarProps> = props => {

    const getIcon = (value: SchedulerViewMode): iconNameType => {
        switch (value) {
            case SchedulerViewMode.Calendar: 
                return "calendar"; 
            case SchedulerViewMode.List:
                return "list_ul";
            case SchedulerViewMode.Timeline:
                return "align_right";
        }
    };

    return (
        <div style={{display: "flex"}}>
            <UniversalEnumSelector
                enumName={"SchedulerViewMode"}
                enumType={SchedulerViewMode}
                displayMode="groupedRadioButtons"
                enumOrigin="client"
                value={props.viewMode}
                onChange={props.onViewModeChange}
                automationId="_schedulerViewMode"
                getIconName={getIcon}
            />
            <UniversalEnumSelector
                enumName={"SchedulerScale"}
                enumType={SchedulerScale}
                displayMode={props.useSelectBox ? "selectBox" : "groupedRadioButtons"}
                enumOrigin="client"
                value={props.scale}
                onChange={props.onScaleChange}
                automationId="_schedulerScale"
                clearable={false}
            />
        </div>
    );
};

export default SchedulerToolbar;
