import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocationClassRadioButtonGroup from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Controls/LocationClassRadioButtonGroup";
import PerformanceReportGroupingSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Controls/PerformanceReportGroupingSelectBox/PerformanceReportGroupingSelectBox";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import PerformanceReportGroupingType from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportGroupingType";
import IntervalGroupingResolutionSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Controls/IntervalGroupingResolutionSelectBox/IntervalGroupingResolutionSelectBox";
import PerformanceReportIntervalGrouping from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportIntervalGrouping";
import PerformanceReportEnumBasedGroupingControl from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Controls/PerformanceReportEnumBasedGroupingControl/PerformanceReportEnumBasedGroupingControl";
import PerformanceReportEnumBasedGrouping from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportEnumBasedGrouping";
import PerformanceReportGroupingStore from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportGroupingStore";
import PerformanceReportOutputColumnStore from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportOutputColumnStore";
import PerformanceReportOutputColumnTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Controls/PerformanceReportOutputColumnTypeSelectBox/PerformanceReportOutputColumnTypeSelectBox";
import PerformanceReport from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReport";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import PerformanceReportCareActivityStateSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Controls/PerformanceReportCareActivityStateSelectBox/PerformanceReportCareActivityStateSelectBox";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";
import StaticHunSocialSecurityReportingResources from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/StaticResources/StaticHunSocialSecurityReportingResources";
import InsurerOrganizationSelectBox from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Components/Controls/InsurerOrganizationSelectBox";
import OrganizationSelector from "@HisPlatform/BoundedContexts/Organization/Components/OrganizationSelector";
import { AssistantSelector, DoctorCodeSelector } from "@HisPlatform/Components/HisPlatformControls";
import ExternalLocationCodeSelector from "@HisPlatform/BoundedContexts/Organization/Components/Controls/ExternalLocationCodeSelector";
import ExternalLocationTypeSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/ExternalLocationTypeSelectBox";
import { FinancingClassSelectBox } from "@HisPlatform/BoundedContexts/Care/Components";
import PerformanceReportOutputColumnType from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/PerformanceReportOutputColumnType.g";
import HealthCareProfessionSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/HealthCareProfessionSelectBox/HealthCareProfessionSelectBox";
import WellKnownReferenceCodes from "@HunSocialSecurityPlugin/Common/WellKnownReferenceCodes";


interface IPerformanceReportDetailProps {
    performanceReport: PerformanceReport;
    pointOfCareIds: PointOfCareId[];
    validationResults: IClientValidationResult[];
    onValidateAsync: (dirtyFields: string[]) => Promise<IClientValidationResult[]>;
}

@State.observer
class PerformanceReportDetail extends React.Component<IPerformanceReportDetailProps> {

    public render() {
        const performanceReport = this.props.performanceReport;
        const pointOfCareIdFilters: FilterBase[] = this.props.pointOfCareIds
            ? this.props.pointOfCareIds.map(id => new ExplicitIdentifierFilter("PointOfCare", id.value))
            : [];

        return (
            <ValidationBoundary
            entityTypeName="PerformanceReport"
            validationResults={this.props.validationResults}
            onValidateAsync={this.props.onValidateAsync}
            validateOnMount
        >
                    <div>
                        <Ui.Header headingType={5} text={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.FilterHeader} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
                        <div style={{ width: "66%" }}>
                            <Ui.TextBox
                                value={performanceReport.name}
                                onChange={performanceReport.onNameChange}
                                label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.Name}
                                propertyIdentifier="Name"
                                automationId="performanceReportNameTextBox"
                                required />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
                        <div style={{ width: "33%" }}>
                            <Ui.DateRangePicker
                                value={performanceReport.wentUnderCareAtDateRange}
                                onChange={performanceReport.onWentUnderCareAtDateRangeChange}
                                label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.Period}
                                initialSelectionState={"from"}
                                propertyIdentifier="WentUnderCareAt"
                                automationId="wentUnderCareAtSelectBox"
                                required />
                        </div>
                        <div style={{ width: "33%" }}>
                            <InsurerOrganizationSelectBox
                                value={performanceReport.insurerOrganization}
                                onChange={performanceReport.onInsurerOrganizationChange}
                                label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.InsurerOrganization}
                                propertyIdentifier="InsurerOrganizationId"
                                automationId="insurerOrganizationSelectBox"
                                required
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
                        <div style={{ width: "100%" }}>
                            <OrganizationSelector
                                value={performanceReport.pointOfCares}
                                onChange={performanceReport.onPointOfCareChange}
                                label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.PointOfCare}
                                pointOfCareMode={true}
                                automationId="organization"
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
                        <div style={{ width: "33%" }}>
                            <HealthCareProfessionSelectBox
                                identifierSystemId={WellKnownReferenceCodes.HunSocialSecurityInsuranceCode}
                                value={performanceReport.healthcareProfessions}
                                onChange={performanceReport.onHealthcareProfessionChange}
                                label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.HunSocialSecurityInsuranceCodeHealthcareProfession}
                                automationId="healthcareProfessionsSelectBox" />
                        </div>
                        <div style={{ width: "33%" }}>
                            <DoctorCodeSelector
                                value={performanceReport.doctors}
                                onChange={performanceReport.onDoctorChange}
                                filters={pointOfCareIdFilters}
                                multiSelect
                                label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.Doctor}
                                automationId="doctorsCodeSelector" />
                        </div>
                        <div style={{ width: "33%" }}>
                            <AssistantSelector
                                value={performanceReport.assistants}
                                onChange={performanceReport.onAssistantChange}
                                filters={pointOfCareIdFilters}
                                multiSelect
                                label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.Assistant}
                                automationId="assistantsSelector" />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
                        <div style={{ width: "33%" }}>
                            <ExternalLocationCodeSelector
                                value={performanceReport.referralWorkplaces}
                                onChange={performanceReport.onReferralWorkplaceChange}
                                multiSelect
                                label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.ExternalLocation}
                                automationId="referralWorkplacesCodeSelector"
                            />
                        </div>
                        <div style={{ width: "33%" }}>
                            <ExternalLocationTypeSelectBox
                                value={performanceReport.referralWorkPlaceTypes}
                                onChange={performanceReport.onReferralWorkPlaceTypeChange}
                                multiSelect
                                label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.HunLocationType}
                                propertyIdentifier="HunLocationType"
                                automationId="referralWorkplaceTypesSelectBox"
                            />
                        </div>
                        <div style={{ width: "33%" }}>
                            <LocationClassRadioButtonGroup
                                value={performanceReport.isExternal}
                                onChange={performanceReport.onIsExternalChange}
                                label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.LocationClass}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
                        <div style={{ width: "33%" }}>
                            <FinancingClassSelectBox
                                value={performanceReport.financingClasses}
                                onChange={performanceReport.onFinancingClassChange}
                                multiSelect
                                label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.FinancingClass}
                                propertyIdentifier="FinancingClass"
                                automationId="financingClassesSelectBox"
                            />
                        </div>
                        <div style={{ width: "33%" }}>
                            <PerformanceReportCareActivityStateSelectBox
                                value={performanceReport.careActivityStates}
                                onChange={performanceReport.onCareActivityStateChange}
                                multiSelect
                                label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.CareActivityState}
                                automationId="careActivityStatesSelectBox"
                            />
                        </div>
                        <div style={{ width: "33%" }}>
                            <Ui.CheckBox
                                value={performanceReport.isWithoutFinancedServices}
                                onChange={performanceReport.onIsWithoutFinancedServicesChange}
                                labelPosition="right"
                                label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.WithoutFinancedServices}
                                verticalAlign="inlineInput"
                                automationId="isWithoutFinancedServicesCheckBox"
                            />
                        </div>
                    </div>
                    <div style={{ paddingTop: 10 }}>
                        <Ui.Header headingType={5} text={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.GroupingHeader} />
                    </div>
                    <div>
                        <ListPanel<PerformanceReportGroupingStore>
                            items={performanceReport.groupings}
                            renderItemEditor={this.renderGroupingEditor}
                            onCreateNewAsync={this.createNewGroupingAsync}
                            propertyIdentifier="Groupings"
                            alwaysEdit
                            isCompactEmptyState />
                    </div>
                    <div style={{ paddingTop: 10 }}>
                        <Ui.Header headingType={5} text={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.OutputColumnHeader} />
                    </div>
                    <div>
                        <ListPanel<PerformanceReportOutputColumnStore>
                            items={performanceReport.outputColumns}
                            renderItemEditor={this.renderOutputColumnEditor}
                            onCreateNewAsync={this.createNewOutputColumnAsync}
                            propertyIdentifier="OutputColumns"
                            alwaysEdit
                            isCompactEmptyState />
                    </div>
                    </ValidationBoundary>  
        );
    }

    private createNewGroupingAsync = () => {
        const store = new PerformanceReportGroupingStore();
        store.setType(PerformanceReportGroupingType.Ambulance);
        return Promise.resolve(store);
    };

    @State.bound
    private renderGroupingEditor(item: PerformanceReportGroupingStore) {
        return (
            <State.Observer>{() => (
                <Ui.Flex>
                    <Ui.Flex.Item xs={2}>
                        <PerformanceReportGroupingSelectBox
                            label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.PerformanceReportGroupingTypeLabel}
                            value={item.type}
                            onChange={item.setType}
                            propertyIdentifier={"Type"}
                            required
                            automationId="performanceReportGroupingTypeSelectBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={2}>
                        <Ui.CheckBox
                            label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.IsSummaryNeededLabel}
                            value={item.isSummaryNeeded}
                            onChange={item.setIsSummaryNeeded}
                            labelPosition="right"
                            verticalAlign="inlineInput"
                            automationId="isSummaryNeededCheckBox" />
                    </Ui.Flex.Item>
                    {this.renderSelectedItem(item)}
                </Ui.Flex>
            )}</State.Observer>
        );
    }

    private createNewOutputColumnAsync = () => {
        const store = new PerformanceReportOutputColumnStore();
        store.setType(PerformanceReportOutputColumnType.CareActivity);
        return Promise.resolve(store);
    };

    @State.bound
    private renderOutputColumnEditor(item: PerformanceReportOutputColumnStore) {
        return (
            <State.Observer>{() => (
                <Ui.Flex>
                    <Ui.Flex.Item xs={2}>
                        <PerformanceReportOutputColumnTypeSelectBox
                            label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.PerformanceReportOutputColumnTypeLabel}
                            value={item.type}
                            onChange={item.setType}
                            required
                            automationId="performanceReportOutputColumnTypeSelectBox"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            )}</State.Observer>
        );
    }

    @State.bound
    private renderSelectedItem(item: PerformanceReportGroupingStore) {
        const type = item && item.type;

        if (type === null || type === undefined) {
            return <></>;
        }

        return (
            <>
                {type === PerformanceReportGroupingType.Interval &&
                    <Ui.Flex.Item xs={2}>
                        <IntervalGroupingResolutionSelectBox
                            label={StaticHunSocialSecurityReportingResources.PerformanceReportPanel.IntervalGroupingResolutionLabel}
                            value={(item.grouping as PerformanceReportIntervalGrouping).intervalGroupingResolution}
                            onChange={(item.grouping as PerformanceReportIntervalGrouping).setIntervalGrouping}
                            propertyIdentifier={"IntervalResolution"}
                            automationId="intervalGroupingResolutionSelectBox"
                            required />
                    </Ui.Flex.Item>}
                {type !== PerformanceReportGroupingType.Interval &&
                    <Ui.Flex.Item xs={8}>
                        <PerformanceReportEnumBasedGroupingControl
                            type={item.type}
                            columnValues={(item.grouping as PerformanceReportEnumBasedGrouping).selectedColumns}
                            onColumnValueChange={(item.grouping as PerformanceReportEnumBasedGrouping).setSelectedColumn}
                            orderValue={(item.grouping as PerformanceReportEnumBasedGrouping).selectedOrdering}
                            onOrderValueChange={(item.grouping as PerformanceReportEnumBasedGrouping).setSelectedOrdering} />
                    </Ui.Flex.Item>}
            </>
        );
    }
}

export default PerformanceReportDetail;