import React from "react";
import ActionBoundButton, { IActionBoundButtonProps } from "./ActionBoundButton";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";

interface IActionBoundSaveButtonProps extends IActionBoundButtonProps {
}

export default function ActionBoundSaveButton(props: IActionBoundSaveButtonProps) {
    const { localizationService } = useDependencies(c => ({
        localizationService: c.resolve<IToolkitLocalizationService>("IToolkitLocalizationService")
    }));

    return <ActionBoundButton text={localizationService.staticResources.button.save} {...props} visualStyle="primary" iconName="save"></ActionBoundButton>;
}