import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import ResourceBlocking from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ResourceBlocking";

interface IResourceBlockingEditorDialogViewProps {
    resourceBlocking: ResourceBlocking;
    onClose: () => void;
    saveAsync: () => Promise<void>;
    onValidateAsync: () => Promise<IClientValidationResult[]>;
    onDeleteAsync: () => Promise<void>;
}

@State.observer
export default class ResourceBlockingEditorDialogView extends React.Component<IResourceBlockingEditorDialogViewProps> {
    public render() {
        return (
            <ValidationBoundary
                validationResults={this.props.resourceBlocking.validationResults}
                entityTypeName={"ResourceBlocking"}
                onValidateAsync={this.props.onValidateAsync}
                validateOnMount
            >
                <Ui.Modal
                    title={this.props.resourceBlocking.isNew
                        ? StaticSchedulingResources.ResourceBlockingEditorDialog.AddResourceBlocking
                        : StaticSchedulingResources.ResourceBlockingEditorDialog.EditResourceBlocking}
                    isOpen
                    onClose={this.props.onClose}
                    size="compact"
                    closeButton
                    closeOnOutsideClick={false}
                    closeOnEscape={true}>
                    <Ui.Modal.Body>
                        <Ui.Flex>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={6}>
                                    <Ui.DatePicker
                                        label={StaticSchedulingResources.ResourceBlockingEditorDialog.Date}
                                        value={this.props.resourceBlocking.dateWithTimeRange.date}
                                        onChange={this.props.resourceBlocking.dateWithTimeRange.setDateValue}
                                        propertyIdentifier="BlockingInterval.From"
                                        automationId="resourceBlockingDatePicker"
                                    />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={6}>
                                    <Ui.TimeRangePicker
                                        label={StaticSchedulingResources.ResourceBlockingEditorDialog.TimeInterval}
                                        onTimePickerChange={this.props.resourceBlocking.dateWithTimeRange.setTimeInterval}
                                        valueFrom={this.props.resourceBlocking.dateWithTimeRange.timeIntervalStart}
                                        valueTo={this.props.resourceBlocking.dateWithTimeRange.timeIntervalEnd}
                                        propertyIdentifier="BlockingInterval.To"
                                        automationId="resourceBlockingIntervalPicker"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </Ui.Flex>

                        <Ui.Flex>
                            <Ui.Flex.Item xs={12}>
                                <Ui.TextBox
                                    label={StaticSchedulingResources.ResourceBlockingEditorDialog.Description}
                                    value={this.props.resourceBlocking.description}
                                    onChange={this.props.resourceBlocking.setDescription}
                                    propertyIdentifier="Description"
                                    automationId="descriptionTextBox"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>

                    </Ui.Modal.Body>
                    <Ui.Modal.Footer>
                        <Ui.Flex xsJustify="between" verticalSpacing="none" >
                            <Ui.Flex.Item>
                                <Ui.Button
                                    text={StaticSchedulingResources.ResourceBlockingEditorDialog.Cancel}
                                    onClick={this.props.onClose}
                                    visualStyle="link"
                                    automationId="closeButton"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item>
                                {this.renderDeleteButton(this.props.resourceBlocking)}
                                <Ui.Button
                                    text={this.props.resourceBlocking.isNew
                                        ? StaticSchedulingResources.ResourceBlockingEditorDialog.AddButton
                                        : StaticSchedulingResources.ResourceBlockingEditorDialog.SaveButton}
                                    visualStyle="primary"
                                    onClickAsync={this.props.saveAsync}
                                    automationId="saveButton"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Modal.Footer>
                </Ui.Modal>
            </ValidationBoundary>
        );
    }

    private renderDeleteButton(resourceBlocking: ResourceBlocking) {
        if (resourceBlocking.isNew) {
            return (<></>);
        } else {
            return (
                <Ui.Button
                    visualStyle="negative-standard"
                    iconName="trash"
                    onClickAsync={this.props.onDeleteAsync}
                    size="standard"
                    automationId="__deleteResourceBlocking"
                    text={StaticSchedulingResources.PractitionerAppointments.DeleteResourceBlocking}/>
            );
        }
    }
}