import IPermissionDefinition from "@PluginInterface/OperationAccessControl/IPermissionDefinition";
import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import IPermissionScope from "@PluginInterface/OperationAccessControl/IPermissionScope";
import _ from "@HisPlatform/Common/Lodash";

export default class PermissionDefinitionRegistryCore implements IPermissionDefinitionRegistry {

    private readonly permissionDefinitions: IPermissionDefinition[] = [];

    public register(permissionDefinition: IPermissionDefinition): void {
        this.permissionDefinitions.push(permissionDefinition);
    }
    public getAll(): IPermissionDefinition[] {
        return this.permissionDefinitions;
    }
    public getPermissionDefinition(permissionDefinition: string): IPermissionDefinition {
        return this.permissionDefinitions.find(def => def.ids.some(id => id.value === permissionDefinition));
    }
    public addScopeTypeToPermissionDefinition(permissionDefinitionName: string, scopeTypesToAdd: IPermissionScope[]): void {
        const permissionDefinition = this.getPermissionDefinition(permissionDefinitionName);
        permissionDefinition.scopes.push(...scopeTypesToAdd);
    }

    public resolveMergedPermissionDefinitions(permissionDefinitions: IPermissionDefinition[]): IPermissionDefinition[] {
        const resolvedAliasedPermissions: IPermissionDefinition[] = [];
        const alreadyResolvedPermissions: string[] = [];
        permissionDefinitions.forEach(merged => {
            this.permissionDefinitions.forEach(aliased => {

                const aliasedString = JSON.stringify(aliased);
                const alreadyResolved = alreadyResolvedPermissions.includes(aliasedString);

                if (!alreadyResolved && _.intersectionBy(merged.ids, aliased.ids, x => x.value).length && merged.includesPermission(aliased)) {

                    // keep scopes from merged that are not aliased
                    const mergedScopes = merged.scopes.filter(s => !aliased.aliasedScopeTypes.some(t => s.type === t));

                    // lift scopes that are aliased
                    const aliasedScopes = aliased.scopes.filter(a => aliased.aliasedScopeTypes.includes(a.type));

                    const resolvedPermission = aliased.clone();

                    resolvedPermission.setScopes([...mergedScopes, ...aliasedScopes]);
                    resolvedAliasedPermissions.push(resolvedPermission);
                    alreadyResolvedPermissions.push(aliasedString);
                }
            });
        });

        return resolvedAliasedPermissions;
    }

}