import Di from "@Di";
import ValueWrapperMap from "@Toolkit/CommonWeb/Model/ValueWrapperMap";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import { IActionRegistryItem } from "./ActionDispatcher";
import { ActionProcessorFactory } from "./ActionProcessorFactory";
import FrontendActionId from "./FrontendActionId";
import IFrontendActionDisplaySettings from "./IFrontendActionDisplaySettings";

@Di.injectable()
export default class GlobalActionRegistry {
    private readonly registry = new ValueWrapperMap<FrontendActionId, IActionRegistryItem>();

    public register(actionId: FrontendActionId, processorFactory: ActionProcessorFactory, displaySettings?: IFrontendActionDisplaySettings): void {
        const item = this.registry.getOrNull(actionId);

        if (!item) {
            this.registry.set(actionId, {
                processorFactory,
                displaySettings
            });
        } else {
            item.processorFactory = processorFactory;
            item.displaySettings = displaySettings;
        }
    }

    public getOrNull(actionId: FrontendActionId): IActionRegistryItem {
        return this.registry.getOrNull(actionId);
    }

    public registerConditionalDisplaySettings<TAction extends FrontendActionBase>(actionId: FrontendActionId, displaySettingsFactory: (action: TAction) => IFrontendActionDisplaySettings) {
        const item = this.registry.getOrNull(actionId);

        if (!item) {
            this.registry.set(actionId, {
                processorFactory: null,
                displaySettings: null,
                conditionalDisplaySettings: [displaySettingsFactory]
            });
        } else {
            item.conditionalDisplaySettings ??= [];
            item.conditionalDisplaySettings.push(displaySettingsFactory);
        }
    }
}