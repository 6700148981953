exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ScrollView_scrollbar-outer_36Cst {\n  width: 100%;\n  height: 100%;\n  max-height: 100%;\n  flex-basis: 100%;\n  overflow: hidden;\n}\n.ScrollView_scrollbar-wrapper_1yDfK {\n  overflow: overlay;\n  position: relative;\n  width: 100%;\n  height: 100%;\n  max-height: 100%;\n  flex-basis: 100%;\n}\n.ScrollView_horizontal-only_1tmsB {\n  overflow-y: hidden;\n}\n", ""]);

// exports
exports.locals = {
	"scrollbar-outer": "ScrollView_scrollbar-outer_36Cst",
	"scrollbarOuter": "ScrollView_scrollbar-outer_36Cst",
	"scrollbar-wrapper": "ScrollView_scrollbar-wrapper_1yDfK",
	"scrollbarWrapper": "ScrollView_scrollbar-wrapper_1yDfK",
	"horizontal-only": "ScrollView_horizontal-only_1tmsB",
	"horizontalOnly": "ScrollView_horizontal-only_1tmsB"
};