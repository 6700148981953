import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import React from "react";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import Styles from "./SeparatedTimeRangePicker.less";

interface ISeparatedTimeRangePickerDependencies {
    toolkitLocalizationService: IToolkitLocalizationService;
}

interface ISeparatedTimeRangePickerProps {
    _dependencies?: ISeparatedTimeRangePickerDependencies;
    label: string;
    initFrom?: TimeOfDay;
    initTo?: TimeOfDay;
    onTimeRangePickerChange: (from: TimeOfDay, to: TimeOfDay) => void;
}

@State.observer
class SeparatedTimeRangePicker extends React.Component<ISeparatedTimeRangePickerProps> {
    @State.observable.ref private from: TimeOfDay | null;
    @State.observable.ref private to: TimeOfDay | null;
    private validFrom: TimeOfDay | null;
    private validTo: TimeOfDay | null;

    private get toolkitLocalizationService() { return this.props._dependencies.toolkitLocalizationService; }

    constructor(props: ISeparatedTimeRangePickerProps) {
        super(props);

        this.from = this.props.initFrom ?? null;
        this.to = this.props.initTo ?? null;

        if (this.isRangeSpecified() && this.from.greaterThan(this.to)) {
            throw new Error("Invalid range: 'To' cannot be earlier than 'From'.");
        }

        this.validFrom = this.from;
        this.validTo = this.to;
    }

    @State.action.bound
    private onFromChange(newValue: TimeOfDay) {
        this.from = newValue;
    }

    @State.action.bound
    private onToChange(newValue: TimeOfDay) {
        this.to = newValue;
    }

    @State.action.bound
    private onFromPickerClose() {
        if (!this.handleTimeChange()) {
            this.from = this.validFrom;
        }
    }

    @State.action.bound
    private onToPickerClose() {
        if (!this.handleTimeChange()) {
            this.to = this.validTo;
        }
    }

    private handleTimeChange(): boolean {
        if (!this.isRangeSpecified()) {
            return true;
        }
        if (this.from.lessThan(this.to)) {
            this.validTo = this.to;
            this.validFrom = this.from;
            this.props.onTimeRangePickerChange(this.validFrom, this.validTo);
            return true;
        }
        return false;
    }

    private isRangeSpecified(): boolean {
        return this.from && !this.from.isEmpty() && this.to && !this.to.isEmpty();
    }

    public render() {
        return (
            <>
                <div className={Styles.outerContainer}>
                    <Ui.TimePicker
                        label={this.props.label}
                        value={this.from}
                        onChange={this.onFromChange}
                        onMenuClose={this.onFromPickerClose}
                        automationId="timeRangePickerFrom"
                        propertyIdentifier="IntraDayTimePeriodConstraintStart" />
                    <label className={Styles.fromToLabel}>{this.toolkitLocalizationService.staticResources.separatedTimeRangePicker.labels.from}</label>
                    <Ui.TimePicker
                        value={this.to}
                        onChange={this.onToChange}
                        onMenuClose={this.onToPickerClose}
                        automationId="timeRangePickerTo"
                        propertyIdentifier="IntraDayTimePeriodConstraintEnd"
                        className={Styles.toTimePicker} />
                    <label className={Styles.fromToLabel}>{this.toolkitLocalizationService.staticResources.separatedTimeRangePicker.labels.to}</label>
                </div>
            </>
        );
    }
}

export default connect(
    SeparatedTimeRangePicker,
    new DependencyAdapter<ISeparatedTimeRangePickerProps, ISeparatedTimeRangePickerDependencies>(c => {
        return {
            toolkitLocalizationService: c.resolve("IToolkitLocalizationService")
        };
    })
);