import "./ApiClientDependencyConfiguration";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";
import DependencyContainer from "@DiContainer";
import PrescriptionStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionStoreMapper";
import PractitionerRecommendationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/PractitionerRecommendation/PractitionerRecommendationApiAdapter";
import PractitionerRecommendationStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/PractitionerRecommendation/PractitionerRecommendationStoreMapper";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import MedicationPricingStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/MedicationPricingStoreMapper";
import MedicationSubsidyStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/MedicationSubsidyStoreMapper";
import ReferenceDataApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/ReferenceDataApiAdapter";
import TextualPrescriptionTemplateStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/TextualPrescriptionTemplateStoreMapper";
import IPrescriptionExtensionDataProvider from "@PluginInterface/BoundedContexts/MedicationRequest/Prescription/IPrescriptionExtensionDataProvider";
import DefaultPrescriptionExtensionDataProvider from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/DefaultPrescriptionExtensionDataProvider";
import PractitionerRecommendationDocumentApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/PractitionerRecommendationDocument/PractitionerRecommendationDocumentApiAdapter";
import PricingAndSubsidyLoader from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/PricingAndSubsidyLoader";
import DesignatedOrganizationStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/DesignatedOrganizationStoreMapper";
import FamilyDoctorDocumentApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/FamilyDoctorDocument/FamilyDoctorDocumentApiAdapter";
import FamilyDoctorDocumentStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/FamilyDoctorDocument/FamilyDoctorDocumentStoreMapper";
import PermanentPrescriptionStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PermanentPrescriptionStoreMapper";
import PermanentPrescriptionReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PermanentPrescriptionReferenceDataStore";
import DosageLocalizationService from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/DosageLocalizationService";
import IDosageLocalizationService from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/IDosageLocalizationService";

DependencyContainer
    .bind("PrescriptionApiAdapter")
    .to(PrescriptionApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PrescriptionStoreMapper")
    .to(PrescriptionStoreMapper);

DependencyContainer
    .bind("TextualPrescriptionTemplateStoreMapper")
    .to(TextualPrescriptionTemplateStoreMapper);

DependencyContainer
    .bind("PractitionerRecommendationApiAdapter")
    .to(PractitionerRecommendationApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PractitionerRecommendationStoreMapper")
    .to(PractitionerRecommendationStoreMapper);

DependencyContainer
    .bind("MedicationRequestReferenceDataStore")
    .to(MedicationRequestReferenceDataStore)
    .inSingletonScope();

DependencyContainer
    .bind("MedicationPricingStoreMapper")
    .to(MedicationPricingStoreMapper);

DependencyContainer
    .bind("MedicationSubsidyStoreMapper")
    .to(MedicationSubsidyStoreMapper);

DependencyContainer
    .bind("ReferenceDataApiAdapter")
    .to(ReferenceDataApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("IPrescriptionExtensionDataProvider")
    .to(DefaultPrescriptionExtensionDataProvider);

DependencyContainer
    .bind("PractitionerRecommendationDocumentApiAdapter")
    .to(PractitionerRecommendationDocumentApiAdapter);

DependencyContainer
    .bind("PricingAndSubsidyLoader")
    .to(PricingAndSubsidyLoader);

DependencyContainer
    .bind("DesignatedOrganizationStoreMapper")
    .to(DesignatedOrganizationStoreMapper);

DependencyContainer
    .bind("FamilyDoctorDocumentApiAdapter")
    .to(FamilyDoctorDocumentApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("FamilyDoctorDocumentStoreMapper")
    .to(FamilyDoctorDocumentStoreMapper);

DependencyContainer
    .bind("PermanentPrescriptionStoreMapper")
    .to(PermanentPrescriptionStoreMapper);

DependencyContainer
    .bind("PermanentPrescriptionReferenceDataStore")
    .to(PermanentPrescriptionReferenceDataStore)
    .inSingletonScope();

DependencyContainer
    .bind("IDosageLocalizationService")
    .to(DosageLocalizationService);