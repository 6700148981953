import State from "@Toolkit/ReactClient/Common/StateManaging";
import moment from "moment";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

class DateTimeService {
    private lastSynchronizationPerfValue: number = 0;
    private lastSynchronizationDateTime: moment.Moment = null;

    @State.bound
    public synchronize(now: moment.Moment) {
        this.lastSynchronizationPerfValue = performance.now();
        this.lastSynchronizationDateTime = now;
    }

    @State.bound
    public now(): moment.Moment {
        const diff = performance.now() - this.lastSynchronizationPerfValue;
        const modifiedLastSynchronizationDateTime = this.lastSynchronizationDateTime?.clone()?.add(diff, "millisecond");
        return modifiedLastSynchronizationDateTime ?? moment();
    }

    @State.bound
    public today(): LocalDate {
        return LocalDate.createFromMoment(this.now());
    }
}

export default new DateTimeService();
