import React from "react";
import * as Ui from "@CommonControls";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiEReferralCovidData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralCovidData";
import EhiEReferralCovidQuickTestResultSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralCovidQuickTestResultSelectBox";
import EhiEReferralCovidQuickTestResultId from "@Primitives/EhiEReferralCovidQuickTestResultId.g";

interface IQuickTestViewProps {
    store: EhiEReferralCovidData;
}


const QuickTestView: React.FC<IQuickTestViewProps> = (props: IQuickTestViewProps) => {
    if (!props.store) {
        return null;
    }
    return (
        <Ui.GroupBox title={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.QuickTestTitle}>
            <Ui.Flex>
                <Ui.Flex.Item sm={4}>
                    <EhiEReferralCovidQuickTestResultSelectBox value={props.store.quickTestResultId}
                                                               onChange={props.store.setQuickTestResultId}
                                                               label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.QuickTestResultId}
                                                               propertyIdentifier="QuickTestResultId"
                                                               automationId="__quickTestResultId"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item sm={4}>
                    {
                        props.store.quickTestResultId &&
                        props.store.quickTestResultId.value !== EhiEReferralCovidQuickTestResultId.NotPerformed.value &&
                        <Ui.DateTimePicker value={props.store.quickTestDate}
                                           onChange={props.store.setQuickTestDate}
                                           label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.QuickTestDate}
                                           propertyIdentifier="QuickTestDate"
                                           automationId="__quickTestDate"
                        />
                    }
                </Ui.Flex.Item>
                <Ui.Flex.Item sm={4}>
                    <></>
                </Ui.Flex.Item>
            </Ui.Flex>
        </Ui.GroupBox>
    );
};

export default State.observer(QuickTestView);
