import ILocalizedObject from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalization";
import React from "react";

export default class EhiEReferralListColumnStore implements ILocalizedObject {
    
    public readonly Name: string;
    
    public readonly Shorthand: string;

    constructor(name: string, shorthand: string) {
        this.Name = name;
        this.Shorthand = shorthand;
    }

    public getShorthandAndName(separator: string = " - ") {
        return `${this.Shorthand}${separator}${this.Name}`;
    }

    public getDisplayValue(noBoldShorthand: boolean, separator: string = " - ") {
        return noBoldShorthand
            ? (<>{this.getShorthandAndName(separator)}</>)
            : (<><b>{this.Shorthand}</b>{separator}{this.Name}</>);
    }
}