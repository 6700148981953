import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import PropertyGroup from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/PropertyGroup";
import Di from "@Di";
import * as ConfigurationProxy from "@HisPlatform/BoundedContexts/Configuration/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import ScalarProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/ScalarProperty";
import BoolProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/BoolProperty";
import NumericProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/NumericProperty";
import NumericRangeProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/NumericRangeProperty";
import StringProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/StringProperty";
import ConfigurationPropertyGroupStoreMapper from "./ConfigurationPropertyGroupStoreMapper";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";

/* auto-inject-disable */
@Di.injectable()
export default class ConfigurationDynamicPropertiesApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("ConfigurationProxy.IDynamicPropertiesClient") private readonly apiClient: ConfigurationProxy.IDynamicPropertiesClient,
        @Di.inject("ConfigurationPropertyGroupStoreMapper") private readonly propertyGroupStoreMapper: ConfigurationPropertyGroupStoreMapper
    ) {
        super();
    }

    public getAllPropertyGroupsAsync() {
        return this.processOperationAsync(
            new SimpleStore<PropertyGroup[]>(),
            async (target) => {
                const result = await this.apiClient.getAllPropertyGroupsQueryAsync(CreateRequestId());
                this.propertyGroupStoreMapper.applyToStore(target, result);
            }
        );
    }

    public updatePropertyGroupsAsync(propertyGroups: PropertyGroup[], isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new SimpleStore<PropertyGroup[]>(),
            async (target) => {
                const result = await this.apiClient.updatePropertyGroupsCommandAsync(CreateRequestId(),
                    new ConfigurationProxy.UpdatePropertyGroupsControllerDto({
                        propertyGroups: propertyGroups.map(x => new ConfigurationProxy.UpdatePropertyGroupDto({
                            propertyGroupDefinitionId: x.definitionId,
                            properties: x.properties.filter(y => this.validItem(y)).map(y => new ConfigurationProxy.UpdatePropertyDto({
                                propertyDefinitionId: y.definitionId,
                                valueVersions: y.versions?.map(valueVersion => new ConfigurationProxy.PropertyVersionDto({
                                    validFrom: valueVersion.validFrom,
                                    validTo: valueVersion.validTo,
                                    value: this.getValue(y, valueVersion.value)
                                })) ?? null
                            }))
                        }))
                    }), isPermissionCheckOnly
                );

                if (!isPermissionCheckOnly) {
                    this.propertyGroupStoreMapper.applyToStore(target, result);
                }

                target.operationInfo = createOperationInfo(result);
            }
        );
    }

    @State.bound
    public updatePropertyGroupsPermissionCheckAsync() {
        return this.processOperationAsync(
            new SimpleStore(),
            async (target) => {
                const result = await this.apiClient.updatePropertyGroupsCommandAsync(
                    CreateRequestId(),
                    new ConfigurationProxy.UpdatePropertyGroupsControllerDto({
                        propertyGroups: []
                    }),
                    true);

                target.operationInfo = createOperationInfo(result);
            }
        );
    }

    public validItem(property: ScalarProperty) {
        return property.versions?.every(version => {
            if (property instanceof NumericRangeProperty) {
                return !isNullOrUndefined(version.value) && (!isNullOrUndefined(version.value.from) || !isNullOrUndefined(version.value.to));
            }
            return !isNullOrUndefined(version.value);
        }) ?? true;
    }

    public getValue(property: ScalarProperty, value: any) {
        if (property instanceof BoolProperty) {
            return new ConfigurationProxy.BoolPropertyValue({ value: value });
        } else if (property instanceof NumericProperty) {
            return new ConfigurationProxy.NumericPropertyValue({ value: value });
        } else if (property instanceof NumericRangeProperty) {
            return new ConfigurationProxy.NumericRangePropertyValue({ value: new ConfigurationProxy.NumericRange({ from: value.from, to: value.to, isEmpty: false }) });
        } else if (property instanceof StringProperty) {
            return new ConfigurationProxy.StringPropertyValue({ value: value });
        }
        return null;
    }

    public async getPropertyValueQueryAsync(groupName: string, propertyName: string) {
        const response = await this.apiClient.getPropertyValueQueryAsync(CreateRequestId(), groupName, propertyName, "");
        const valueForType = response.propertyValue.value;

        if (!valueForType) {
            return null;
        }

        if (valueForType instanceof ConfigurationProxy.StringPropertyValue) {
            return valueForType.value;
        } else if (valueForType instanceof ConfigurationProxy.BoolPropertyValue) {
            return valueForType.value;
        }
        throw new Error(`Unknown PropertyType.`);
    }
}