import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import PointOfCareRoles from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Actions/PointOfCareRoles";
import RoleId from "@Primitives/RoleId.g";
import UserGroup from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroup";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import UserId from "@Primitives/UserId.g";

export interface ICopyPointOfCareRolesModalParams extends IModalParams {
    user: User;
    practitioner: PractitionerStore;
    copyPointOfCareRoles: (pointOfCareRoles: PointOfCareRoles[]) => void;

}
export interface IRoleEditorModalResult {
    pointOfCareRoles: PointOfCareRoles[];
    selectedUserId: UserId;
}

export default class CopyPointOfCareRolesModalParams implements ICopyPointOfCareRolesModalParams {

    public static type = "CopyPoinfOdacareRolesModalParams";
    public get type() { return CopyPointOfCareRolesModalParams.type; }

    constructor(
        public readonly user: User,
        public readonly practitioner: PractitionerStore,
        public readonly copyPointOfCareRoles: (pointOfCareRoles: PointOfCareRoles[]) => void,

    ) { }

}