exports = module.exports = require("../../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AppointmentScheduleServicesGrid_actions-column_2byF0 {\n  float: right;\n  padding-right: 5px;\n}\n.AppointmentScheduleServicesGrid_service-column_2cg7X {\n  padding-left: 5px;\n}\n.AppointmentScheduleServicesGrid_add-button_1LDxv {\n  width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"actions-column": "AppointmentScheduleServicesGrid_actions-column_2byF0",
	"actionsColumn": "AppointmentScheduleServicesGrid_actions-column_2byF0",
	"service-column": "AppointmentScheduleServicesGrid_service-column_2cg7X",
	"serviceColumn": "AppointmentScheduleServicesGrid_service-column_2cg7X",
	"add-button": "AppointmentScheduleServicesGrid_add-button_1LDxv",
	"addButton": "AppointmentScheduleServicesGrid_add-button_1LDxv"
};