import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import * as Ui from "@CommonControls";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import {dispatchAsyncErrors} from "@Toolkit/CommonWeb/AsyncHelpers";
import UserId from "@Primitives/UserId.g";
import {wrappedValuesAreEquals} from "@HisPlatform/Common/ValueWrapperHelpers";
import _ from "@HisPlatform/Common/Lodash";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import UserWithIdentifiers from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/UserWithIdentifiers";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";

interface IUserSelectBoxDependencies {
    userManagementDataProviderStore: UserManagementDataProviderStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IUserSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IUserSelectBoxDependencies;
    excludedIds?: UserId[];
    excludeUsersWithoutPractitioners?: boolean;
}

@State.observer
class UserSelectBox extends React.Component<IUserSelectBoxProps> {

    private get referenceDataStore() {
        return this.props._dependencies.userManagementDataProviderStore;
    }

    @State.computed
    private get items(): Array<ISelectBoxItem<UserId>> {
        let items = this.referenceDataStore.usersWithIdentifiers.items;

        if (this.props.excludedIds) {
            items = items.filter(item => !this.props.excludedIds.some(id => wrappedValuesAreEquals(id, item.user.id)));
        }

        if (this.props.excludeUsersWithoutPractitioners) {
            const practitioners = this.props._dependencies.organizationReferenceDataStore.practitionersWithUserIdMap.items;
            items = items.filter(item => practitioners.some(practitioner => wrappedValuesAreEquals(practitioner.userId, item.id)));
        }

        return items.map(this.toSelectBoxItem);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private async loadAsync() {
        await Promise.all([this.referenceDataStore.usersWithIdentifiers.ensureAllLoadedAsync(),
            this.props._dependencies.organizationReferenceDataStore.practitionersWithUserIdMap.ensureAllLoadedAsync()]);
    }

    @State.bound
    private getFullDisplayName(item: UserWithIdentifiers): string {
        return  `${item.user.displayName} (${_.join([item.user.loginName].concat(item.identifiers), ", ")})`;
    }

    @State.bound
    private toSelectBoxItem(item: UserWithIdentifiers) {
        return {
            text: this.getFullDisplayName(item),
            value: item.id
        } as ISelectBoxItem<UserId>;
    }

    public render() {
        return (
            <Ui.SelectBox
                {...this.props}
                loading={!this.items}
                items={this.items}
                clearable={false}
            />
        );
    }
}

export default connect(
    UserSelectBox,
    new DependencyAdapter<IUserSelectBoxProps, IUserSelectBoxDependencies>(container => {
        return {
            userManagementDataProviderStore: container.resolve("UserManagementDataProviderStore"),
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore")
        };
    })
);
