import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import Di from "@Di";
import IPrescriptionListNameExtensionPointProps from "@PluginInterface/BoundedContexts/MedicationRequest/ExtensionPoints/IPrescriptionListNameExtensionPointProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

@Di.injectable()
export default class EhiPrescriptionListNameExtensionComponentService implements IExtensionComponentService<IPrescriptionListNameExtensionPointProps>  {

    public isVisible(props: IPrescriptionListNameExtensionPointProps) {
        return !isNullOrUndefined(props.extensionData?.PrescriptionSyncStatus);
    }

    public shouldRerunIsVisible(props: IPrescriptionListNameExtensionPointProps, prevProps: IPrescriptionListNameExtensionPointProps) {
        return props.extensionData !== prevProps?.extensionData;
    }
}