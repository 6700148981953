import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React, { useCallback, useMemo, useState } from "react";
import IPatientCareActivitiesTabProps from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabProps";
import WorklistTypeBoundNDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/WorklistTypeBoundNDataPanel";
import FilterConditionConverters from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/FilterConditionConverters";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import UseCases from "@Primitives/UseCases";

function WrappedEhrFormInstanceListForPatientPanel(props: IPatientCareActivitiesTabProps) {

    return props.inModalView ? (
        <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 153px)" }}>
            {renderContent(props)}
        </div>
    ) : (
        renderContent(props)
    );
}

function renderContent(props: IPatientCareActivitiesTabProps) {
    const [useCaseState, setUseCaseState] = useState<INDataUseCaseState>();
    const [selectedId, setSelectedId] = useState<FormInstanceId>();

    const dynamicFormFilters = useMemo(() => {
        const dynamicFilters = {};
        dynamicFilters["FormInstanceBased_IsUserForm"] = FilterConditionConverters[WorklistConditionType.Boolean](true);
        dynamicFilters["FormInstanceBased_FormBaseEntity"] = FilterConditionConverters[WorklistConditionType.String]("CareActivity");
        if (!isNullOrUndefined(props.careActivityId)) {
            dynamicFilters["FormInstanceBased_FormBaseEntityId"] = FilterConditionConverters[WorklistConditionType.Numeric](parseInt(props.careActivityId.value, 10));
        }

        return dynamicFilters;
    }, [props.careActivityId]);

    const onFormChange = useCallback((id: string, newUseCaseState: INDataUseCaseState) => {
        let useCaseId = id;
        if (newUseCaseState?.useCase?.value === UseCases.readonlyFormInstanceDetail) {
            useCaseId = newUseCaseState.useCaseArguments.find(it => it.name === "formInstanceId")?.value?.value ?? "new";
        }
        setSelectedId(isNullOrUndefined(useCaseId) ? null : new FormInstanceId(useCaseId));
        setUseCaseState(newUseCaseState);
    }, []);

    return (
        <WorklistTypeBoundNDataPanel
            worklistDefinitionReference="FormInstanceForPatient"
            cacheKey={`CAreActivityId_${props.careActivityId?.value}`}
            dynamicFilters={dynamicFormFilters}
            onChange={onFormChange}
            useCaseState={useCaseState}
            selectedRowId={selectedId?.value}
            disableDetailStrictMode
            hasPrintButton={false}
            hasRefreshButton={false}
            isCompact
        />
    );
}

export default connect<IPatientCareActivitiesTabProps>(
    WrappedEhrFormInstanceListForPatientPanel
);