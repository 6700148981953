import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DiagnosisListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisListStore";
import DiagnosisStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisStore";
import CareActivityId from "@Primitives/CareActivityId.g";
import DiagnosisListApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/DiagnosisList/DiagnosisListApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import { formatString, formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import { HisPanelButtonPortal } from "@HisPlatformControls";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import PanelController from "@Toolkit/ReactClient/Components/PanelController";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import { IDefaultDetailPanelButtonsProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/Common/DefaultDetailPanelButtons";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import ActionIdentifiers from "@Primitives/ActionIdentifiers";
import DiagnosisListPanelView from "./DiagnosisListPanelView";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import DiagnosisValidationProblemProcessor from "./DiagnosisValidationProblemProcessor";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import { VoidSyncEvent } from "ts-events";
import ExtensionController from "@HisPlatform/Components/HisPlatformExtensionPoint/ExtensionController";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import _ from "@HisPlatform/Common/Lodash";
import CareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CareActivityStore";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import LockDoesNotExistsError from "@Toolkit/CommonWeb/Model/LockDoesNotExistsError";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import PatientId from "@Primitives/PatientId.g";

interface IDiagnosisListPanelDependencies {
    diagnosisListAdapter: DiagnosisListApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
    localizationService: ILocalizationService;
    dialogService: IDialogService;
    lockingApiAdapter: LockingApiAdapter;
    notificationService: INotificationService;
    careActivityApiAdapter: CareActivityApiAdapter2;
    commonReferenceDataStore: CommonReferenceDataDataStore;
}

interface IDiagnosisListPanelProps {
    _dependencies?: IDiagnosisListPanelDependencies;
    _careActivityId?: CareActivityId;
    _careActivity?: CareActivityStore;
    _patientId?: PatientId;
    _diagnosisListChangedEvent?: VoidSyncEvent;

    readonly?: boolean;
    isLoading?: boolean;

    onBack?: () => void;
}

@State.observer
class DiagnosisListPanel extends React.Component<IDiagnosisListPanelProps> {

    public static defaultProps: Partial<IDiagnosisListPanelProps> = {
    };

    private get notificationService() {
        return this.props._dependencies.notificationService;
    }

    private get apiAdapter() {
        return this.props._dependencies.diagnosisListAdapter;
    }

    private get referenceDataStore() {
        return this.props._dependencies.careReferenceDataStore;
    }

    private get dialogService() {
        return this.props._dependencies.dialogService;
    }

    @State.observable.ref private diagnosisListStore: DiagnosisListStore = null;
    @State.observable public isReadonlyByAction: boolean = false;
    @State.observable.ref private newDiagnosis = new DiagnosisStore(true);
    private extensionController = new ExtensionController();

    private validationProblemProcessor = new DiagnosisValidationProblemProcessor();

    private disposables: IDisposable[] = [];

    @State.observable private _isLoading = false;
    @State.observable.ref public selectedDiagnosis: DiagnosisStore = null;
    @State.observable.ref public selectedDiagnosisForDetail: DiagnosisStore = null;
    private extensionDataSnapShot: any = null;
    private validationResultDataSnapShot: IClientValidationResult[] = null;

    @State.observable.ref public highlightedValidationProblem: IClientValidationProblem = null;

    @State.computed
    private get isLoading() {
        return this._isLoading || this.props.isLoading;
    }

    @State.computed
    private get permissionCheckedOperations() {
        const res = {};
        if (this.props._careActivityId) {
            res["Save"] = async () => await this.apiAdapter.updateDiagnosisListPermissionCheckAsync(this.props._careActivityId);
        }
        return res;
    }

    @State.action.bound
    public setHighlightedValidationProblem(highlightedValidationProblem: IClientValidationProblem) {
        this.highlightedValidationProblem = highlightedValidationProblem;
    }

    @State.action.bound
    public setSelectedDiagnosis(selectedDiagnosis: DiagnosisStore) {
        this.selectedDiagnosis = selectedDiagnosis;
    }

    @State.action.bound
    public setSelectedDiagnosisForDetailChange(selectedDiagnosis: DiagnosisStore) {
        this.trySetSelectedDiagnosisForDetailChangeAsync(selectedDiagnosis);
    }

    @State.action.bound
    public async trySetSelectedDiagnosisForDetailChangeAsync(selectedDiagnosis: DiagnosisStore) {
        let index = 0;
        if (selectedDiagnosis) {
            index = this.diagnosisListStore.diagnoses.indexOf(selectedDiagnosis);
        }
        if (this.extensionController.isDirty && this.extensionController.isDirty()) {
            const canUnload = await this.unloadAsync(false);
            if (canUnload) {
                if (!selectedDiagnosis) {
                    this.setSelectedDiagnosisForDetail(null);
                } else {
                    this.setSelectedDiagnosisForDetail(null);
                    this.setSelectedDiagnosisForDetailByIndex(index);
                }
            }
        } else {
            this.setSelectedDiagnosisForDetail(selectedDiagnosis);
        }
    }

    @State.action.bound
    private setSelectedDiagnosisForDetailByIndex(index: number) {
        const diagnosisStore = index < this.diagnosisListStore.diagnoses.length && this.diagnosisListStore.diagnoses[index];
        this.setSelectedDiagnosisForDetail(diagnosisStore);
    }

    @State.action.bound
    private setSelectedDiagnosisForDetail(diagnosis: DiagnosisStore) {
        this.selectedDiagnosisForDetail = diagnosis;
        this.extensionDataSnapShot = this.selectedDiagnosisForDetail ? _.cloneDeep(this.selectedDiagnosisForDetail.extensionData) : null;
        this.validationResultDataSnapShot = _.cloneDeep(this.diagnosisListStore.validationResults);
    }

    @State.action.bound
    private rollbackExtensionData() {
        if (this.selectedDiagnosisForDetail) {
            this.selectedDiagnosisForDetail.extensionData = this.extensionDataSnapShot;
            this.diagnosisListStore.validationResults = this.validationResultDataSnapShot;
        }
    }

    @State.action.bound
    private setIsReadonlyByAction(newValue: boolean) {
        this.isReadonlyByAction = newValue;
    }

    @State.action.bound
    private setValidationStore(newValidation: IClientValidationResult[]) {
        this.diagnosisListStore.validationResults = newValidation;
    }

    @State.action.bound
    private setDiagnosisListStore(diagnosisListStore: DiagnosisListStore) {
        this.diagnosisListStore = diagnosisListStore;
        this.diagnosisListStore.takeSnapshot();
    }

    @State.action.bound
    private initializeExtensionData() {
        this.selectedDiagnosisForDetail?.setExtensionData({});
    }

    @State.bound
    private async saveAsync(releaseLock: boolean) {
        if (this.diagnosisListStore) {
            if (!this.diagnosisListStore.isDirty() && (!this.extensionController.isDirty || !this.extensionController.isDirty())) {
                return true;
            }
            if (this.extensionController.isDirty?.()) {
                const extensionSaveResult = await this.extensionController.saveAsync();

                if (extensionSaveResult?.hasValidationError) {
                    return false;
                }
            }

            let selectedDiagnosisForDetailIndex: number = null;
            if (this.selectedDiagnosisForDetail) {
                selectedDiagnosisForDetailIndex = this.diagnosisListStore.diagnoses.indexOf(this.selectedDiagnosisForDetail);
            }
            const newList = await this.apiAdapter.updateDiagnosisListAsync(this.diagnosisListStore, releaseLock);

            this.getWarningForMissingRequiredOncologyData(newList);

            if (newList.hasValidationError) {
                this.setValidationStore(newList.validationResults);
                this.notificationService.showCannotSaveBecauseOfErrors();
                return false;
            }

            this.setDiagnosisListStore(newList);

            if (selectedDiagnosisForDetailIndex !== null) {
                this.setSelectedDiagnosisForDetailByIndex(selectedDiagnosisForDetailIndex);
            }
            this.notificationService.showSaveResult(newList.isPersistedByOperationInfo, newList.hasValidationWarning, StaticCareResources.DiagnosisList.SuccessFulSaveMessage);

            return newList.operationWasSuccessful;
        }
        return true;
    }

    @State.bound
    private async saveWithoutReleaseLockAsync() {
        const result = await this.saveAsync(false);
        setTimeout(() => { // TODO: this is cheating
            this.props._diagnosisListChangedEvent?.post();
        }, 3000);
        return result;
    }

    @State.bound
    private async saveAndReleaseLockAsync() {
        try {
            const result = await this.saveAsync(true);
            this.props._diagnosisListChangedEvent?.post();
            return result;
        } catch (error) {
            if (error.error instanceof LockDoesNotExistsError) {
                const result = await this.dialogService.yesNo(StaticCareResources.Common.Dialog.LockDoesNotExistsErrorTitle, StaticCareResources.Common.Dialog.LockDoesNotExistsErrorMessage);
                if (result.resultCode === DialogResultCode.Yes) {
                    this.diagnosisListStore.releaseLock();
                    return true;
                }
            }
            throw error;
        }
    }

    @State.bound
    private async onDeleteDiagnosisAsync(store: DiagnosisStore) {
        const condition = this.props._dependencies.careReferenceDataStore.condition.get(store.conditionVersionSelector);
        const dialogResult = await this.props._dependencies.dialogService.yesNo(StaticWebAppResources.Common.DialogTitle.ConfirmationTitle, formatString(StaticCareResources.OutpatientWorkflow.RecordedDiagnosesStep.Dialog.DiagnosisDeleteConfirmation, condition.code));

        if (dialogResult.resultCode === DialogResultCode.Yes) {
            this.diagnosisListStore.removeDiagnosis(store);
        }
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);
    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentWillUnmount() {
        this.disposables.forEach(d => d.dispose());
    }

    public componentDidUpdate(prevProps: IDiagnosisListPanelProps) {
        if (!ValueWrapper.equals(this.props._careActivityId, prevProps._careActivityId)) {
            dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        }
    }

    @State.bound
    private async forceReleaseLockAndLoadAsync() {
        await this.forceReleaseLockAsync();
        await this.loadAsync();
    }

    @State.boundLoadingState("_isLoading")
    private async forceReleaseLockAsync() {
        if (this.diagnosisListStore.lockInfo && this.diagnosisListStore.lockInfo.preventingLockId) {
            const response = await this.props._dependencies.lockingApiAdapter.forceReleaseLockAsync(this.diagnosisListStore.lockInfo.preventingLockId);
            return response.operationWasSuccessful;
        }
        return true;
    }

    @State.boundLoadingState("_isLoading")
    private async loadAsync() {
        if (this.props._careActivityId) {
            const list = await this.apiAdapter.getDiagnosisListAsync(this.props._careActivityId, !this.props.readonly);
            this.getWarningForMissingRequiredOncologyData(list);
            await this.loadReferenceDataAsync(list);
            await this.loadIsReadonlyByActionAsync();
            this.setDiagnosisListStore(list);
            this.newDiagnosis.reset(this.getInitialDiagnosisUse());
        }
    }

    private getWarningForMissingRequiredOncologyData(diagnosisList: DiagnosisListStore) {

        const validationResults = diagnosisList.validationResults;
        const addedConditionCodes = new Set<string>();

        validationResults.forEach(result => {
            result.problems.forEach(problem => {
                if (problem.ruleId === "ShouldBeFilled") {
                    const diagnosisPath = problem.propertyPath.split(".")[0];
                    const diagnosis = (_.get(diagnosisList, diagnosisPath.toLowerCase()) as DiagnosisStore);

                    if (!!diagnosis) {
                        const conditionId = diagnosis.conditionVersionSelector.id;

                        this.props._dependencies.careReferenceDataStore.condition.ensureLoadedAsync([diagnosis?.conditionVersionSelector]);

                        const conditionCode = this.props._dependencies.careReferenceDataStore.condition.get({
                            id: conditionId,
                            validOn: LocalDate.today()
                        })?.code;

                        if (conditionCode !== null && !addedConditionCodes.has(conditionCode)) {
                            const diagnosisId = diagnosis.id.value;
                            const newProblem: IClientValidationProblem = {
                                parameters: { DiagnosisId: { Value: diagnosisId } },
                                propertyPath: diagnosisPath,
                                ruleId: "OncologyDataHasRequiredFieldsMissing",
                                message: null,
                                rawMessage: formatStringWithObjectParams(StaticCareResources.HunEHealthInfrastructure.OncologyData.ValidationMessages.OncologyDataHasRequiredFieldsMissing, {
                                    ConditionCode: conditionCode
                                }),
                                severity: problem.severity,
                            };
                            addedConditionCodes.add(conditionCode);
                            result.problems.push(newProblem);
                        }
                    }
                }
            });
        });
    }

    @State.bound
    private async loadIsReadonlyByActionAsync() {
        if (this.props._careActivityId) {
            const possibleActions = await this.props._dependencies.careActivityApiAdapter.getPossibleActionsAsync([{ careActivityId: this.props._careActivityId }]);
            this.setIsReadonlyByAction(possibleActions.value[0].actions.every(i => i.value !== ActionIdentifiers.recordDiagnosis));
        }
    }

    @State.bound
    private async loadReferenceDataAsync(diagnosisListStore: DiagnosisListStore) {
        const conditionVersionSelectors = diagnosisListStore.diagnoses.map(d => d.conditionVersionSelector);
        await this.referenceDataStore.condition.ensureLoadedAsync(conditionVersionSelectors);
        await this.referenceDataStore.diagnosisRole.ensureLoadedAsync();
        await this.referenceDataStore.laterality.ensureLoadedAsync();
    }

    @State.bound
    private getInitialDiagnosisUse() {
        if (this.diagnosisListStore && this.diagnosisListStore.diagnoses && this.diagnosisListStore.diagnoses.some(i => ValueWrapper.equals(i.use, DiagnosisRoleId.Discharge))) {
            return DiagnosisRoleId.Comorbity;
        }
        return DiagnosisRoleId.Discharge;
    }

    @State.bound
    private add() {
        this.diagnosisListStore.addDiagnosis(this.newDiagnosis);
        this.newDiagnosis.reset(this.getInitialDiagnosisUse());
    }

    @State.bound
    private async releaseLockAsync() {
        const lockInfo = this?.diagnosisListStore?.lockInfo;
        if (lockInfo && lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld && !!lockInfo.lockId) {
            await this.props._dependencies.lockingApiAdapter.releaseLockAsync(lockInfo.lockId);
        }
    }

    @State.bound
    public async unloadAsync(releaseLock: boolean = true): Promise<boolean> {
        if (this.diagnosisListStore && this.diagnosisListStore.isMutable) {
            if (this.diagnosisListStore.isDirty() || (this.extensionController.isDirty && this.extensionController.isDirty())) {
                const dialogResult = await this.dialogService.confirmIfNotSaved(StaticCareResources.Common.Dialog.ConfirmationTitle, StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);
                if (dialogResult.resultCode === DialogResultCode.Yes) {
                    const persisted = releaseLock ? await this.saveAndReleaseLockAsync() : await this.saveWithoutReleaseLockAsync();
                    return persisted;
                } else if (dialogResult.resultCode === DialogResultCode.No) {
                    this.rollbackExtensionData();
                    return true;
                } else {
                    return false;
                }
            }
        }
        await this.releaseLockAsync();
        return true;
    }

    @State.bound
    private mapValidationProblems(validationProblem: IClientValidationProblem): any[] {
        return this.validationProblemProcessor.mapValidationProblems(validationProblem, this.diagnosisListStore.diagnoses);
    }

    @State.bound
    private filterValidationProblems(problem: IClientValidationProblem) {
        return problem === this.highlightedValidationProblem;
    }

    @State.bound
    private renderValidationProblemInfo() {
        return undefined as React.ReactNode;
    }

    @State.bound
    private async onDetailViewSaveAsync() {
        await this.saveAsync(false);
    }

    @State.bound
    private async validateAsync() {
        const result = await this.apiAdapter.validateAsync(this.diagnosisListStore);
        return result.value;
    }

    @State.computed
    public get canAcquireLock() {
        return this.diagnosisListStore?.lockInfo &&
            this.diagnosisListStore?.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockNotHeld &&
            this.diagnosisListStore?.lockInfo?.wasLockRequested;
    }
    

    public render() {

        if (!this.props.readonly && this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticCareResources.OutpatientWorkflow.RecordedDiagnosesStep.Title} />;
        }

        return (
            <PermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                <DiagnosisListPanelView
                    canAcquireLock={this.canAcquireLock}
                    diagnosisListStore={this.diagnosisListStore}
                    forceReleaseLockAndLoadAsync={this.forceReleaseLockAndLoadAsync}
                    isLoading={this.isLoading}
                    newDiagnosis={this.newDiagnosis}
                    onAddDiagnosis={this.add}
                    onDeleteDiagnosisAsync={this.onDeleteDiagnosisAsync}
                    selectedDiagnosis={this.selectedDiagnosis}
                    setSelectedDiagnosis={this.setSelectedDiagnosis}
                    readonly={this.props.readonly || this.isReadonlyByAction || !!this.diagnosisListStore?.lockInfo?.preventingLockId}
                    highlightedValidationProblem={this.highlightedValidationProblem}
                    setHighlightedValidationProblem={this.setHighlightedValidationProblem}
                    mapValidationProblems={this.mapValidationProblems}
                    problemFilterPredicate={this.filterValidationProblems}
                    validationProblemInfoRenderer={this.renderValidationProblemInfo}
                    highlightableRuleIds={this.validationProblemProcessor.highlightableRuleIds}
                    selectedDiagnosisForDetail={this.selectedDiagnosisForDetail}
                    onSelectedDiagnosisForDetailChange={this.setSelectedDiagnosisForDetailChange}
                    initializeExtensionData={this.initializeExtensionData}
                    extensionController={this.extensionController}
                    saveAsync={this.onDetailViewSaveAsync}
                    validateAsync={this.validateAsync}
                    pointOfCareId={this.props._careActivity.pointOfCareId}
                    patientId={this.props._patientId}
                    permissionCheckOperationNames="Save"
                    onNavigateBack={this.props.onBack}
                />
                <NavigateAwayHook isEnabled onNavigateAwayAsync={this.unloadAsync} />
            </PermissionCheckContextProvider>
        );
    }
}

export default connect(
    DiagnosisListPanel,
    new DependencyAdapter<IDiagnosisListPanelProps, IDiagnosisListPanelDependencies>(container => {
        return {
            diagnosisListAdapter: container.resolve("DiagnosisListApiAdapter"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            localizationService: container.resolve("ILocalizationService"),
            dialogService: container.resolve("IDialogService"),
            lockingApiAdapter: container.resolve("LockingApiAdapter"),
            notificationService: container.resolve("INotificationService"),
            careActivityApiAdapter: container.resolve("CareActivityApiAdapter2"),
            commonReferenceDataStore: container.resolve("CommonReferenceDataDataStore")
        };
    }),
    new CareActivityContextAdapter<IDiagnosisListPanelProps>((c, props) => ({
        _careActivityId: props._careActivityId ?? c.careActivityId,
        _diagnosisListChangedEvent: c.diagnosisListChangedEvent,
        _careActivity: c.careActivity,
    })),
    new PatientContextAdapter<IDiagnosisListPanelProps>(c => ({
        _patientId: c.patientId,
    }))
);
