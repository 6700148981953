import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { Calculator } from "@asseco/react-mac-calculator";
import "./GeneralCalculator.global.css";


const GeneralCalculator: React.FC = () => {

    return (
        <Calculator />
    );

};

export default GeneralCalculator;