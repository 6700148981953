import React from "react";

interface IStopPropagationBoundaryProps {}

export default class StopPropagationBoundary extends React.Component<IStopPropagationBoundaryProps> {

    private onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
    }

    public render() {
        return (
            <div onClick={this.onClick}>{this.props.children}</div>
        );
    }
}