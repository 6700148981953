import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";
import CareActivityContext, { CareActivityReactContext } from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContext";
import { emptyObject } from "@Toolkit/CommonWeb/NullCheckHelpers";

export default class CareActivityContextAdapter<TProps> implements IComponentAdapter<TProps> {
    
    public get usedContexts(): Array<React.Context<any>> {
        return [CareActivityReactContext];
    }

    constructor(
        private readonly mapStateToProps: (careActivityContext: CareActivityContext, props: TProps) => Partial<TProps>
    ) {}

    public getMappedProps(props: TProps, contextValues: Map<React.Context<any>, any>): Partial<TProps> {
        const careActivityContext = contextValues.get(CareActivityReactContext) as CareActivityContext;
        if (!careActivityContext) {
            return emptyObject;
        }
        return this.mapStateToProps(careActivityContext, props);
    }
}