exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WeatherWidget_weatherWidget_1kAHR {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-top: 5px;\n  padding-bottom: 5px;\n}\n.WeatherWidget_weatherWidget_1kAHR .WeatherWidget_locationDateContainer_2cUTk {\n  padding-right: 10px;\n  padding-left: 10px;\n}\n.WeatherWidget_weatherWidget_1kAHR .WeatherWidget_locationDateContainer_2cUTk .WeatherWidget_upFlexItem_1Tvvg {\n  margin: 0;\n  text-align: center;\n  font-size: 20px;\n}\n.WeatherWidget_weatherWidget_1kAHR .WeatherWidget_locationDateContainer_2cUTk .WeatherWidget_downFlexItem_3B4mF {\n  margin: 0;\n  text-align: center;\n  font-size: 14px;\n}\n", ""]);

// exports
exports.locals = {
	"weatherWidget": "WeatherWidget_weatherWidget_1kAHR",
	"locationDateContainer": "WeatherWidget_locationDateContainer_2cUTk",
	"upFlexItem": "WeatherWidget_upFlexItem_1Tvvg",
	"downFlexItem": "WeatherWidget_downFlexItem_3B4mF"
};