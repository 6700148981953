import ExtensionController from "@HisPlatform/Components/HisPlatformExtensionPoint/ExtensionController";
import HunCareContagiousPatientReportApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ContagiousPatientReport/HunCareContagiousPatientReportApiAdapter";
import HunCareApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/HunCareApiAdapter";
import ContagiousPatientReport from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReport";
import ContagiousPatientReportDetailPanelView from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Panels/ContagiousPatientReportPanels/ContagiousPatientReportDetailPanelView";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import ActionIdentifier from "@Primitives/ActionIdentifier.g";
import ContagiousPatientReportId from "@Primitives/ContagiousPatientReportId.g";
import IOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/IOperationInfo";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import React from "react";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";

import StaticHunSocialSecurityCareResources from "@HunSocialSecurityPlugin/BoundedContexts/Care/StaticResources/StaticHunSocialSecurityCareResources";
import ContagiousPatientReportType from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReportType";
import OnsetReportData from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientOnsetReport";
import EndReportData from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientEndReportData";
import ContagiousPatientReportState from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReportState";
import PermissionCheckContext, { IPermissionCheckOperation } from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContext";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";

interface IContagiousPatientReportDetailPanelDependencies {
    contagiousPatientReportApiAdapter: HunCareContagiousPatientReportApiAdapter;
    hunCareApiAdapter: HunCareApiAdapter;
    ehiCareApiAdapter: EhiCareApiAdapter;
    notificationService: INotificationService;
    lockingApiAdapter: LockingApiAdapter;
    dialogService: IDialogService;
}
export interface IContagiousPatientReportDetailPanelProps {
    _dependencies: IContagiousPatientReportDetailPanelDependencies;
    contagiousPatientReportId: ContagiousPatientReportId;
    isReadonly?: boolean;
    isNew?: boolean;
    extensionController?: ExtensionController;
    onSaveAsync?: () => Promise<void>;
}

@State.observer
class ContagiousPatientReportDetailPanel extends React.Component<IContagiousPatientReportDetailPanelProps> {

    public get apiAdapter() {
        return this.props._dependencies.contagiousPatientReportApiAdapter;
    }
    public get notificationService() {
        return this.props._dependencies.notificationService;
    }
    public get dialogService() {
        return this.props._dependencies.dialogService;
    }
    public get hunCareApiAdapter() {
        return this.props._dependencies.hunCareApiAdapter;
    }
    public get ehiCareApiAdapter() {
        return this.props._dependencies.ehiCareApiAdapter;
    }
    public get lockingApiAdapter() {
        return this.props._dependencies.lockingApiAdapter;
    }

    @State.computed private get isReportTypeSelectorDisabled() {
        return !this.props.isNew &&
            (this.initialReportType === ContagiousPatientReportType.EndReport ||
                this.contagiousReport?.state !== ContagiousPatientReportState.UnderRecording);
    }

    @State.computed private get isReadonly() {
        return this.props.isReadonly || !this.contagiousReport?.isMutable || this.contagiousReport?.state === ContagiousPatientReportState.Deleted;
    }

    @State.observable.ref private contagiousReport: ContagiousPatientReport = null;
    @State.observable.ref private updateAction: ActionIdentifier;
    @State.observable.ref private isLoading: boolean = false;
    @State.observable.ref private initialReportType: ContagiousPatientReportType;
    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        if (this.props.extensionController) {
            this.props.extensionController.setSaveContentAsyncHook(this.updateContagiousPatientReportAsync);
        }
    }

    public componentDidUpdate(prevProps: IContagiousPatientReportDetailPanelProps) {
        if (!ValueWrapper.equals(prevProps.contagiousPatientReportId, this.props.contagiousPatientReportId)) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.bound
    private async loadPanelAsync() {
        await this.loadAsync();
        this.setInitialReportType();
    }

    @State.action.bound
    private setInitialReportType() {
        this.initialReportType = this.contagiousReport.type;
    }

    @State.bound
    private async loadAsync() {
        const report = await this.apiAdapter.getContagiousPatientReportById(this.props.contagiousPatientReportId, true);
        await this.setAddressCountryIdToDefaultValueAsync(report.value);
        this.setCurrentReport(report.value);
    }

    @State.bound
    private async setAddressCountryIdToDefaultValueAsync(report: ContagiousPatientReport) {
        if (!report.onsetData.onsetLocation.countryId || !report.onsetData.workPlaceLocation.countryId) {
            const defaultData = await this.ehiCareApiAdapter.getHungarianPatientDefaultDataAsync();
            report.onsetData.onsetLocation.setCountryId(defaultData.value.countryId);
            report.onsetData.workPlaceLocation.setCountryId(defaultData.value.countryId);
        }
    }

    @State.action
    private setCurrentReport(report: ContagiousPatientReport) {
        this.contagiousReport = report;
        this.contagiousReport.takeSnapshot();
        this.updateAction = null;
        this.props.extensionController?.setIsDirtyHook(() => this.contagiousReport.isDirty() || !!this.updateAction);
    }

    @State.bound
    private async validateAsync() {
        if (this.isReadonly || !this.contagiousReport.isMutable) {
            return [];
        }

        const result = await this.apiAdapter.validateAsync(this.contagiousReport, this.updateAction);
        return result.value;
    }

    @State.bound
    private async updateContagiousPatientReportAsync(): Promise<IOperationInfo> {
        const result = await this.apiAdapter.updateContagiousPatientReportAsync(this.contagiousReport, this.updateAction, false);

        if (result.isPersistedByOperationInfo) {
            this.setCurrentReport(result);
            this.notificationService.showSavedSuccessfully(
                true,
                StaticHunSocialSecurityCareResources.ContagiousPatientReport.SuccessfulSaveMessage
            );
            if (!this.props.extensionController) {
                this.props.onSaveAsync?.();
            }
        } else {
            this.notificationService.showCannotSaveBecauseOfErrors();
            this.setValidationResults(result.validationResults);
        }
        return result.operationInfo;
    }

    @State.action.bound
    private setValidationResults(validationResults: IClientValidationResult[]) {
        this.contagiousReport.validationResults = validationResults;
    }

    @State.action.bound
    private setAction(key: string, newSelectionState: boolean | null) {
        if (key === this.updateAction?.value) {
            if (!newSelectionState) {
                this.updateAction = null;
            }
        } else {
            this.updateAction = new ActionIdentifier(key);
        }
    }
    @State.bound
    private async forceReleaseLockAsync() {
        if (this.contagiousReport.lockInfo && this.contagiousReport.lockInfo.preventingLockId) {
            const response = await this.lockingApiAdapter.forceReleaseLockAsync(this.contagiousReport.lockInfo.preventingLockId);
            return response.operationWasSuccessful;
        }
        return true;
    }

    @State.bound
    private async reloadAsync() {
        await this.forceReleaseLockAsync();
        await this.loadAsync();
    }

    @State.bound
    private async getMostRelevantReportAsync() {
        const res = await this.apiAdapter.getMostRelevantOnsetReportForNewEndReportAsync(
            this.contagiousReport.conditionId,
            this.contagiousReport.patientId
        );

        return res.value;
    }

    @State.loadingState.bound("isLoading")
    private async setReportTypeAsync(reportType: ContagiousPatientReportType) {
        this.contagiousReport.setType(reportType);

        if (this.props.isNew) {
            if (reportType === ContagiousPatientReportType.EndReport) {
                await this.setMostRelevantOnsetReportDataForEndReportAsync();
            } else {
                this.contagiousReport.setEndData(new EndReportData());
                this.contagiousReport.setOnsetData(new OnsetReportData());
            }
        } else {
            if (reportType === ContagiousPatientReportType.OnsetReport) {
                this.contagiousReport.setEndData(new EndReportData());
            }
        }

        await this.setAddressCountryIdToDefaultValueAsync(this.contagiousReport);
    }

    @State.action.bound
    private setReportType(reportType: ContagiousPatientReportType) {
        dispatchAsyncErrors(this.setReportTypeAsync(reportType), this);
    }

    @State.bound
    private async setMostRelevantOnsetReportDataForEndReportAsync() {
        const res = await this.getMostRelevantReportAsync();
        this.contagiousReport.setOnsetData(res.reportData);
        this.contagiousReport.endReportData.contagiousPatientOnsetReportId = res.id;
    }

    @State.bound
    private async navigateAwayAsync() {
        if (this.contagiousReport?.isMutable === true && this.contagiousReport.isDirty()) {
            const dialogResult = await this.dialogService.confirmIfNotSaved(StaticCareResources.Common.Dialog.ConfirmationTitle, StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);
            if (dialogResult.resultCode === DialogResultCode.Yes) {
                await this.updateContagiousPatientReportAsync();
                await this.releaseLockAsync();
            } else if (dialogResult.resultCode === DialogResultCode.No) {
                await this.releaseLockAsync();
                return true;
            }
            return false;
        }
        await this.releaseLockAsync();
        return true;
    }

    @State.action.bound
    private async releaseLockAsync() {
        if (this.contagiousReport.isMutable && this.contagiousReport.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld) {
            await this.lockingApiAdapter.releaseLockAsync(this.contagiousReport.lockInfo.lockId);
            this.contagiousReport.releaseLock();
            return true;
        }
        return false;
    }

    @State.bound
    private async deleteReportAsync() {
        const dialogResult = await this.dialogService.yesNo(
            StaticCareResources.Common.Dialog.ConfirmationTitle,
            StaticHunSocialSecurityCareResources.ContagiousPatientReport.DeleteMessage
        );
        if (dialogResult.resultCode === DialogResultCode.Yes) {
            const result = await this.apiAdapter.deleteContagiousPatientReportAsync(
                this.contagiousReport.id,
                this.contagiousReport.lockInfo?.lockId
            );
            if (result.isPersistedByOperationInfo) {
                this.notificationService.showSavedSuccessfully();
                await this.loadAsync();
                await this.props.onSaveAsync();
            }
        }
    }

    @State.bound
    private operationsToCheck(): IPermissionCheckOperation {
        const result = {};
        if (this.contagiousReport) {
            result["Save"] = async () => { await this.apiAdapter.updateContagiousPatientReportAsync(this.contagiousReport, this.updateAction, false, true); };
        }
        return result;
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadPanelAsync);

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticHunSocialSecurityCareResources.ContagiousPatientReport.Title} />;
        }

        return (
            <PermissionCheckContextProvider operationsToCheck={this.operationsToCheck()}> 
                {!this.props.extensionController && <NavigateAwayHook onNavigateAwayAsync={this.navigateAwayAsync} />}
                <ContagiousPatientReportDetailPanelView
                    report={this.contagiousReport}
                    isReadonly={this.isReadonly}
                    onValidateAsync={this.validateAsync}
                    action={this.updateAction}
                    onActionChange={this.setAction}
                    onReloadAsync={this.reloadAsync}
                    onReportTypeChange={this.setReportType}
                    reportTypeSelectorDisabled={this.isReportTypeSelectorDisabled}
                    isLoading={this.isLoading}
                    onSave={this.updateContagiousPatientReportAsync}
                    shouldDisplayStandaloneButtons={!this.props.extensionController}
                    onDeleteAsync={this.deleteReportAsync}
                />
            </PermissionCheckContextProvider>
        );
    }
}

export default connect(
    ContagiousPatientReportDetailPanel,
    new DependencyAdapter<IContagiousPatientReportDetailPanelProps, IContagiousPatientReportDetailPanelDependencies>(c => ({
        contagiousPatientReportApiAdapter: c.resolve("HunCareContagiousPatientReportApiAdapter"),
        hunCareApiAdapter: c.resolve("HunCareApiAdapter"),
        ehiCareApiAdapter: c.resolve("EhiCareApiAdapter"),
        notificationService: c.resolve("INotificationService"),
        lockingApiAdapter: c.resolve("LockingApiAdapter"),
        dialogService: c.resolve("IDialogService")
    }))
);
