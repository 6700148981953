import Di from "@Di";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import AvailablePointOfCareIdListApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Actions/AvailablePointOfCareIdListApiAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class AvailablePointOfCareListStore {

    @State.observable.ref public pointOfCareIdList: PointOfCareId[] = null;

    constructor(
        @Di.inject("AvailablePointOfCareIdListApiAdapter") private readonly availablePointOfCareIdListApiAdapter: AvailablePointOfCareIdListApiAdapter
    ) { }

    public async loadAvailablePointOfCareListAsync() {
        const availablePointOfCareIds = await this.availablePointOfCareIdListApiAdapter.getAvailableIdsAsync();
        if (availablePointOfCareIds.operationWasSuccessful) {
            this.setAvailablePointOfCareList(availablePointOfCareIds.value);
        }
    }

    @State.action
    private setAvailablePointOfCareList(pointOfCareIdList: PointOfCareId[]) {
        this.pointOfCareIdList = pointOfCareIdList;
    }
}
