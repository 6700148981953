import React from "react";
import ITokenGroup from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITokenGroup";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ITreeViewNode from "@CommonControls/TreeView/ITreeViewNode";
import ITemplateToken from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateToken";
import Styles from "./DocumentTemplateManagement.less";
import Button from "@CommonControls/Button";
import { HighlightableLabel } from "@CommonControls";

export abstract class BaseTokenTreeItem implements ITreeViewNode {
    public key?: string = null;
    public displayValue: React.ReactNode = null;
    public children?: BaseTokenTreeItem[] = [];
    public filterableContent?: string = null;
    @State.observable.ref public filterValue?: string = null;
    @State.observable.ref public isActive?: boolean = null;
    @State.observable.ref public isLoading?: boolean = null;
    @State.observable.ref public isOpen?: boolean = null;

    @State.action.bound
    public setFilterValue(newValue: string) {
        this.filterValue = newValue;
    }
}


export class TokenGroupTreeItem extends BaseTokenTreeItem {
    
    constructor(tokenGroup: ITokenGroup) {
        super();
        const text = `${tokenGroup.code} - ${tokenGroup.name}`;
        this.displayValue = (
            <State.Observer>
                {() => (
                    <HighlightableLabel label={text} filterValue={this.filterValue} automationId={`tokenGroup_${tokenGroup.code}`} />)}
            </State.Observer>);
        this.key = tokenGroup.id.value;
        this.filterableContent = text;
    }

    @State.action.bound
    public setIsOpen(newValue: boolean) {
        this.isOpen = newValue;
    }
}


export class TokenTreeItem extends BaseTokenTreeItem {
    public readonly symbol: string;
    public readonly settingsType: string;

    constructor(
        token: ITemplateToken,
        private readonly onUseTokenAsync: (item: TokenTreeItem) => Promise<void>
    ) {
        super();
        this.displayValue = (
            <State.Observer>
                {() => (
                    <div className={Styles.tokenTreeItem}>
                        <div className={Styles.tokenTreeItemName}>
                            <HighlightableLabel label={token.displayName} filterValue={this.filterValue} automationId={`token_${token.displayName}`} />
                        </div>
                        <div className={Styles.tokenTreeItemButtons}>
                            <Button iconName="plus" onClickAsync={this.useTokenAsync} size="compact" automationId={`token_${token.displayName}_AddButton`} />
                        </div>
                    </div>)}
            </State.Observer>
        );
        this.key = token.symbol;
        this.symbol = token.symbol;
        this.settingsType = token.tokenFormatterSettingsType;
        this.filterableContent = token.displayName;
        this.children = null;
    }

    @State.bound
    private async useTokenAsync() {
        await this.onUseTokenAsync?.(this);
    }
}

export function buildTokenTree(groups: ITokenGroup[], tokens: ITemplateToken[], onUseTokenAsync: (item: TokenTreeItem) => Promise<void>) {
    if (!groups || !tokens) {
        return [];
    }

    const groupMap = new Map<string, TokenGroupTreeItem>(groups.map(g => ([g.id.value, new TokenGroupTreeItem(g)])));
    const tree: BaseTokenTreeItem[] = [];

    groups.forEach(group => {
        const groupTreeItem = groupMap.get(group.id.value);

        if (!group.parentId) {
            tree.push(groupTreeItem);
        } else {
            const parent = groupMap.get(group.parentId.value);
            parent.children.push(groupTreeItem);
        }
    });

    tokens.forEach(token => {
        const tokenTreeItem = new TokenTreeItem(token, onUseTokenAsync);

        if (!token.groupId) {
            tree.push(tokenTreeItem);
        } else {
            const parent = groupMap.get(token.groupId.value);
            parent.children.push(tokenTreeItem);
        }
    });

    return tree;
}