import IDataGridColumnProps, { IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import _ from "@HisPlatform/Common/Lodash";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";

export function getFilterValue(filterStore: any, column: IDataGridColumnProps) {
    if (isNullOrUndefined(column.filterStoreValueGetter)) {
        return filterStore?.[`${column.id}`];
    }

    return typeof column.filterStoreValueGetter === "function" ?
        column.filterStoreValueGetter(filterStore) : _.get(filterStore, column.filterStoreValueGetter);
}

export function getFilterMutator(filterStore: any, column: IDataGridColumnProps) {
    if (isNullOrUndefined(column.filterStoreValueSetter)) {
        return filterStore?.[`set_${column.id}`];
    }

    return typeof column.filterStoreValueSetter === "function" ?
        column.filterStoreValueSetter : _.get(filterStore, column.filterStoreValueSetter);
}

export function createFilterDescriptorsByColumns(columns: IDataGridColumnProps[]) {
    if (!isNullOrUndefined(columns)) {
        return columns.map(c => {
            return {
                id: c.id,
                isFilterable: c.isFilterable,
                defaultFilterValue: c.defaultFilterValue,
                filterValueSerializer: c.filterValueSerializer
            } as IDataGridFilterDescriptor;
        });
    }
    return null;
}

export function createFilterDescriptor(
    identifier: string,
    filterValueSerializer: IDataGridFilterValueSerializer<any>,
    type: WorklistConditionType,
    defaultValue?: any,
    customerConverter?: any) {
    return {
        id: identifier,
        isFilterable: true,
        defaultFilterValue: defaultValue ?? null,
        filterValueSerializer: filterValueSerializer,
        type: type,
        customConverter: customerConverter
    } as IDataGridFilterDescriptor;
}