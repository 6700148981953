import React from "react";
import Styles from "./Dashboard.less";
import DashboardWidget from "@CommonControls/Dashboard/DashboardWidget";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import { Button } from "@CommonControls";
import { DashboardContextStore, DashboardReactContext } from "@CommonControls/Dashboard/DashboardContext";
import { DashboardMode } from "@CommonControls/Dashboard/DashboardMode";
import { ObservableMap } from "@Toolkit/ReactClient/Common/StateManaging";

interface IDashboardProps {
    orientation: "horizontal" | "vertical";
    mode?: DashboardMode;
    onToggleMode?: () => void;

    isOpenMap?: ObservableMap<string, boolean>;
    onOpenStateChanged?: (name: string, isOpen: boolean) => void;

    noGap?: boolean;
}

export default class Dashboard extends React.Component<IDashboardProps, undefined> {
    public static Widget = DashboardWidget;

    private readonly contextStore = new DashboardContextStore(this.props.isOpenMap ?? null, this.props.onOpenStateChanged);

    public componentDidMount(): void {
        this.contextStore.setMode(this.props.mode);
    }

    public componentDidUpdate(prevProps: Readonly<IDashboardProps>, prevState: undefined, snapshot?: any): void {
        if (prevProps.mode !== this.props.mode) {
            this.contextStore.setMode(this.props.mode);
        }

        if (this.props.isOpenMap !== prevProps.isOpenMap) {
            throw new Error("Not supported");
        }

        if (this.props.onOpenStateChanged !== prevProps.onOpenStateChanged) {
            throw new Error("Not supported");
        }
    }

    public render() {
        const classes = new CompositeClassName(Styles.dashboard);
        if (!this.props.mode) {
            classes.addIf(this.props.orientation === "horizontal", Styles.dashboard_horizontal);
            classes.addIf(this.props.orientation === "vertical", Styles.dashboard_vertical);
        } else {
            classes.addIf(this.props.mode === "normal" && this.props.orientation === "horizontal", Styles.dashboard_horizontal);
            classes.addIf(this.props.mode === "normal" && this.props.orientation === "vertical", Styles.dashboard_vertical);
            classes.addIf(this.props.mode === "small" && this.props.orientation === "horizontal", Styles.dashboard_horizontal_small);
            classes.addIf(this.props.mode === "small" && this.props.orientation === "vertical", Styles.dashboard_vertical_small);
        }
        classes.addIf(this.props.noGap, Styles.noGap);
        return (
            <div className={classes.classNames}>
                <DashboardReactContext.Provider value={this.contextStore}>
                    {this.props.children}
                </DashboardReactContext.Provider>
                <div style={{ minHeight: 40 }}></div>
                <div className={Styles.collapseButtonContainer}>
                    <Button
                        iconName={this.props.mode === "normal" ? "angleDoubleLeft" : "angleDoubleRight"}
                        visualMode="dark"
                        onClick={this.props.onToggleMode}
                        automationId="modeChangerButton"
                    />
                </div>
            </div>
        );
    }
}