import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import UserGroup from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroup";
import UsersWithIdentifiersMultiSelector from "@HisPlatform/BoundedContexts/UserManagement/Components/Controls/UsersWithIdentifiersMultiSelector/UsersWithIdentifiersMultiSelector";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

interface IUserGroupBaseDataViewProps {
    onSaveAsync: () => Promise<void>;
    userGroup: UserGroup;
    onValidateAsync?: () => Promise<IClientValidationResult[]>;
}

/** @screen */

const UserGroupBaseDataView: React.FC<IUserGroupBaseDataViewProps> = props => {
    if (!props.userGroup) {
        return (<></>);
    }
    return (
        <ValidationBoundary
            entityTypeName={"UserGroup"}
            validateOnMount>
            <Ui.GroupBox>
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <Ui.TextBox
                            label={StaticUserManagementResources.UserGroupManagementPanel.Label.ShortName}
                            value={props.userGroup.shortName}
                            onChange={props.userGroup.setShortName}
                            propertyIdentifier={"ShortName"}
                            automationId="shortName"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={8}>
                        <Ui.TextBox
                            label={StaticUserManagementResources.UserGroupManagementPanel.Label.Name}
                            value={props.userGroup.name}
                            onChange={props.userGroup.setName}
                            propertyIdentifier={"Name"}
                            automationId="name"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        <Ui.TextBox
                            label={StaticUserManagementResources.UserGroupManagementPanel.Label.Description}
                            value={props.userGroup.description}
                            onChange={props.userGroup.setDescription}
                            propertyIdentifier={"Description"}
                            multiline
                            multilineMinLineCount={2}
                            automationId="description"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>
            <Ui.GroupBox
                title={StaticUserManagementResources.UserGroupManagementPanel.UserSelectorTitle}>                
                <UsersWithIdentifiersMultiSelector
                    allItemsTableTitle={StaticUserManagementResources.UserGroupManagementPanel.UserGroupsPanel.AllItemsTableTitle}
                    chosenItemsTableTitle={StaticUserManagementResources.UserGroupManagementPanel.UserGroupsPanel.ChosenItemsTableTitle}
                    selectedUserIds={props.userGroup.userIds}
                    setUserIds={props.userGroup.setUserIds}
                />
            </Ui.GroupBox>
        </ValidationBoundary>
    );
};
export default State.observer(UserGroupBaseDataView);