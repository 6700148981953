import Di from "@Di";
import IPatientCareActivitiesTabComponentService from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabComponentService";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import CareActivityId from "@Primitives/CareActivityId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowEhrCareActivityBaseDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEhrCareActivityBaseDataScreenAction.g";
import HisPermissionScopes from "@HisPlatform/Common/FrontendActions/HisPermissionScopes";

@Di.injectable()
export default class CareActivityBaseDataTabComponentService implements IPatientCareActivitiesTabComponentService {

    constructor(@Di.inject("AuthorizationService") private authorizationService: AuthorizationService) {
    }

    public isTabAllowedAsync(careActivityId: CareActivityId, organizationUnitId: OrganizationUnitId): Promise<boolean> {
        const ehrCareActivityBaseDataAction =
            new ShowEhrCareActivityBaseDataScreenAction(
                ScreenDisplayMode.ContentOnly,
                careActivityId);

        const ehrCareActivityActionHasPermission = this.authorizationService.hasPermissionFor(ehrCareActivityBaseDataAction.id,
            HisPermissionScopes.pointOfCare(organizationUnitId?.value));

        return Promise.resolve(ehrCareActivityActionHasPermission);
    }
}