import IScreenRouteParams from "@HisPlatform/Application/Routes/IScreenRouteParams";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import ShowScreenActionHost from "./ShowScreenActionHost";

interface IShowScreenActionFullScreenHostProps extends IRoutingFrameContentProps<IScreenRouteParams> {
}

export default function ShowScreenActionFullScreenHost(props: IShowScreenActionFullScreenHostProps) {
    return (
        <ShowScreenActionHost
            onClose={props.routingController.goBack}
            screenRaw={props.routingController.currentRoute.parameters.screen1}
        />
    );
}