import DeviceId from "@Primitives/DeviceId.g";

export class DeviceCodeSelectorItem {
    public id: DeviceId;
    public name?: string;
    public code?: string;
    public text?: string;

    constructor({ id = null, name = "", code = "",  text = "" }: { id?: DeviceId, name?: string, code?: string, text?: string }) {
        this.id = id;
        this.name = name;
        this.text = text;
        this.code = code;
    }
}