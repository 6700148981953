import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import Money from "@Toolkit/CommonWeb/Model/Money";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React, { useCallback } from "react";
import DataGridColumn from "./DataGridColumn";
import { IDataGridColumnBaseProps } from "./IDataGridColumnProps";

interface IDataGridMoneyColumnDependencies {
    localizationService: ILocalizationService;
}


interface IDataGridMoneyColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IDataGridMoneyColumnDependencies;
}


const DataGridMoneyColumn: React.FC<IDataGridMoneyColumnProps> = (props: IDataGridMoneyColumnProps) => {

    const renderValue = useCallback(
        (value: Money) => {
            if (!isNullOrUndefined(value) && !isNullOrUndefined(value.amount) && !isNullOrUndefined(value.currencyCode)) {
                return props._dependencies.localizationService.localizeMoney(value);
            }
            return "";
        }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={renderValue}
        />
    );
};

export default connect(
    DataGridMoneyColumn,
    new DependencyAdapter<IDataGridMoneyColumnProps, IDataGridMoneyColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService")
    }))
);