import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import AppointmentStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentStatus.g";
import {
    IDataGridColumnBaseProps,
    IDataGridColumnFilterProps,
} from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import React from "react";
import CareActivityBasedWorklistEntityType from "@Primitives/CareActivityBasedWorklistEntityType";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import CareActivityStateIcon from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/CareActivityStateIcon/CareActivityStateIcon";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareActivityBasedWorklistStateSelectBox from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/CustomColumns/CareActivityBasedWorklistStateSelectBox";
import ServiceRequestStateBadge from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ServiceRequestStateBadge/ServiceRequestStateBadge";
import AppointmentStatusBadge from "@HisPlatform/BoundedContexts/Scheduling/Components/Controls/AppointmentStatusBadge";
import ServiceRequestState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestState.g";

export interface ICareActivityBasedWorklistState {
    value: CareActivityState | AppointmentStatus | ServiceRequestState;
    type: CareActivityBasedWorklistEntityType;
    additionalNote: string;
}

interface ICareActivityBasedWorklistStateColumnDependencies {
    localizationService: ILocalizationService;
}

interface ICareActivityBasedWorklistStateColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: ICareActivityBasedWorklistStateColumnDependencies;
    displayMode?: "name" | "badge";
}

class CareActivityBasedWorklistStateColumn extends React.Component<ICareActivityBasedWorklistStateColumnProps> {

    private renderCareActivityState(value: CareActivityState) {
        switch (this.props.displayMode) {
            case "name":
                const localized = isNullOrUndefined(value) ? null : this.props._dependencies.localizationService.localizeEnum(CareActivityState[value], "CareActivityState");
                return isNullOrUndefined(localized) ? null : localized.Name;
            case "badge":
            default:
                return <CareActivityStateIcon state={value}/>;
        }
    }

    private renderAppointmentState(value: AppointmentStatus, cancellationReasonNote: string) {
        switch (this.props.displayMode) {
            case "badge":
                return <AppointmentStatusBadge status={value} cancellationReasonNote={cancellationReasonNote}/>;
            case "name":
            default:
                const localized = isNullOrUndefined(value) ? null : this.props._dependencies.localizationService.localizeEnum(AppointmentStatus[value], "AppointmentStatus");
                return isNullOrUndefined(localized) ? null : localized.Name.substr(0, 2);
        }
    }

    private renderServiceRequestState(value: ServiceRequestState) {
        switch (this.props.displayMode) {
            case "name":
                const localized = isNullOrUndefined(value) ? null : this.props._dependencies.localizationService.localizeEnum(ServiceRequestState[value], "ServiceRequestState");
                return isNullOrUndefined(localized) ? null : localized.Name.substr(0, 2);
            case "badge":
            default:
                return <ServiceRequestStateBadge state={value} />;
        }
    }

    @State.bound
    private valueRenderer(value: ICareActivityBasedWorklistState) {
        switch (value.type) {
            case 1:
                return this.renderCareActivityState(value.value as CareActivityState);
            case 2:
                return this.renderAppointmentState(value.value as AppointmentStatus, value.additionalNote);
            case 3:
                return this.renderServiceRequestState(value.value as ServiceRequestState);
            default:
                return <></>;
        }
    }

    public render() {
        const {children, ...columnProps} = this.props;
        return (
            <DataGridColumn
                width={this.props.displayMode === "badge" ? 40 : undefined}
                onRenderCellValue={this.valueRenderer}
                onRenderFilter={this.filterRenderer}
                showHint={false}
                {...columnProps}
            />
        );
    }

    private filterRenderer(filterProps: IDataGridColumnFilterProps<CareActivityBasedWorklistEntityType | CareActivityBasedWorklistEntityType[]>) {
        return (
            <CareActivityBasedWorklistStateSelectBox
                {...filterProps}
                displayMode="selectBox"
                multiSelect
                hoverOnlyIndicatorIcons
            />
        );
    }
}

export default connect(
    CareActivityBasedWorklistStateColumn,
    new DependencyAdapter<ICareActivityBasedWorklistStateColumnProps, ICareActivityBasedWorklistStateColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
