import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import WorklistDefinitionReferenceDataStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/WorklistDefinition/WorklistDefinitionReferenceDataStore";
import ShowCareWorklistScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareWorklistScreenAction.g";
import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import { ActionProcessorFactory } from "@Toolkit/ReactClient/ActionProcessing/ActionProcessorFactory";
import IActionProcessor from "@Toolkit/ReactClient/ActionProcessing/IActionProcessor";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";

export class ShowCareWorklistScreenActionProcessor implements IActionProcessor<ShowCareWorklistScreenAction> {
    public static createFactory(): ActionProcessorFactory {
        return (containerService: IContainerService) => new ShowCareWorklistScreenActionProcessor(
            containerService.resolve<WorklistDefinitionReferenceDataStore>("WorklistDefinitionReferenceDataStore"),
            containerService.resolve<GlobalRoutingStore>("GlobalRoutingStore"),
        );
    }

    constructor(
        private readonly worklistDefinitionReferenceDataStore: WorklistDefinitionReferenceDataStore,
        private readonly routingStore: GlobalRoutingStore,
    ) {}

    public async processAsync(action: ShowCareWorklistScreenAction, _: any): Promise<void> {
        await this.worklistDefinitionReferenceDataStore.initializeAsync();
        const worklistToken = action.worklistToken;
        await this.routingStore.pushAsync(
            ApplicationRoutes.dynamicWorklist.makeRoute({
                worklistDefinitionId: this.worklistDefinitionReferenceDataStore.getLocalIdByToken(worklistToken)
            })
        );
    }
}