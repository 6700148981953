import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientId from "@Primitives/PatientId.g";
import EhiTokenProvider from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Services/EhiTokenProvider/EhiTokenProvider";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import EhiOrganizationUnitProviderService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Organization/Services/Implementation/EhiOrganizationUnitProviderService";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import EhiServiceBase from "@HunEHealthInfrastructurePlugin/Services/EhiServiceBase";
import EhiUserType from "@HunEHealthInfrastructurePlugin/Model/EhiUserType";
import IEhiMedicalConditionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/IEhiMedicalConditionApiAdapter";

export default class EhiMedicalConditionService extends EhiServiceBase {

    constructor(
        private readonly ehiMedicalConditionApiAdapter: IEhiMedicalConditionApiAdapter,
        ehiTokenProvider: EhiTokenProvider,
        ehiOrganizationUnitProviderService: EhiOrganizationUnitProviderService,
        userContext: UserContext,
        modalService: IModalService
    ) {
        super(ehiTokenProvider,
            ehiOrganizationUnitProviderService,
            userContext,
            modalService);
    }

    @State.bound
    public async synchronizeItemsAsync(
        patientId: PatientId,
        deleteLocalEntities: boolean,
        isSelfDeterminationAccessOverride: boolean,
        selfDeterminationAccessOverrideReason: string) {

        return await this.performEhiOperationAsync(patientId, EhiUserType.Doctor, async () => {
            return await this.ehiMedicalConditionApiAdapter.synchronizeItemsAsync(
                patientId,
                this.ehiToken,
                this.organizationUnitId,
                deleteLocalEntities,
                isSelfDeterminationAccessOverride,
                selfDeterminationAccessOverrideReason);
        });
    }

    @State.bound
    public async hasDesynchronizedItemsAsync(patientId: PatientId) {

        return await this.performEhiOperationAsync(patientId, EhiUserType.Doctor, async () => {
            return await this.ehiMedicalConditionApiAdapter.hasDesynchronizedItemsAsync(
                patientId,
                this.ehiToken,
                this.organizationUnitId);
        });
    }

    @State.bound
    public async getLastSynchronizationDateAsync(patientId: PatientId) {
        return await this.ehiMedicalConditionApiAdapter.getLastSynchronizationDateAsync(patientId);
    }
}
