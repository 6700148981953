// tslint:disable:variable-name
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import PatientAdministrativeDataScreenStore from "./PatientAdministrativeDataScreenStore";

const { 
    ContextComponent: PatientAdministrativeDataScreenStoreContext, 
    StoreProvider: PatientAdministrativeDataScreenStoreProvider, 
    useStore: usePatientAdministrativeDataScreenStore,
    provideStore: providePatientAdministrativeDataScreenStore,
} = createPanelStoreProvider(PatientAdministrativeDataScreenStore);

export {
    PatientAdministrativeDataScreenStoreContext,
    PatientAdministrativeDataScreenStoreProvider,
    usePatientAdministrativeDataScreenStore,
    providePatientAdministrativeDataScreenStore
};