import DocumentInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentInfo";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { EntityLockState } from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import LargeDataToken from "@Primitives/LargeDataToken.g";

export default abstract class DocumentBase {
    @State.observable.ref public info: DocumentInfo = null;
    public description: string;
    public largeDataToken: LargeDataToken;
    public downloadFileName: string;

    constructor(
        public readonly isNew: boolean = false
    ) { }

    public get id() {
        return this.info.id;
    }

    public get rowVersion() {
        return this.info.rowVersion;
    }

    public get lockInfo() {
        return this.info.lockInfo;
    }

    @State.action
    public releaseLock() {
        if (this.lockInfo.lockState === EntityLockState.LockingRequiredAndLockHeld) {
            this.info.lockInfo = new LockInfo(EntityLockState.LockingRequiredAndLockNotHeld, null, false, false, null, null, null);
        }
    }
}