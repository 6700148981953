import CareActivityId from "@Primitives/CareActivityId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React, { useContext } from "react";
import * as Ui from "@CommonControls";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PerformanceStatementCareActivityApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/PerformanceStatementCareActivityApiAdapter";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import StaticHunSocialSecurityPerformanceStatementResources from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/StaticResources/StaticHunSocialSecurityPerformanceStatementResources";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import PerformanceStatementCareActivityStatus from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/PerformanceStatementCareActivityStatus";
import UserColumn from "@HisPlatformControls/DataGridColumns/UserColumn";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import PerformanceStatementCareActivityStatusTypeColumn from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityStatusTypeColumn/PerformanceStatementCareActivityStatusTypeColumn";
import PerformanceStatementCareActivityStatusReasonColumn from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityStatusReasonColumn/PerformanceStatementCareActivityStatusReasonColumn";
import INeakPerformanceStatementListItem from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/INeakPerformanceStatementListItem";
import NeakPerformanceStatementApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/NeakPerformanceStatementApiAdapter";
import * as HisUi from "@HisPlatformControls";
import CareActivityContextProvider from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextProvider";
import PatientContextProvider from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextProvider";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import DataGridMonthDateColumn from "@CommonControls/DataGrid/Column/DataGridMonthDateColumn";
import IMasterDetailState from "@CommonControls/Layout/IMasterDetailState";
import { MasterDetailContext } from "@CommonControls/Layout/MasterDetailContext";
import IEuPerformanceStatementListItem from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/IEuPerformanceStatementListItem";
import EuPerformanceStatementApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/EuPerformanceStatementApiAdapter";
import LargeDataToken from "@Primitives/LargeDataToken.g";

interface IPerformanceStatementCareActivityDetailPanelDependencies {
    performanceStatementCareActivityApiAdapter: PerformanceStatementCareActivityApiAdapter;
    neakPerformanceStatementApiAdapter: NeakPerformanceStatementApiAdapter;
    euPerformanceStatementApiAdapter: EuPerformanceStatementApiAdapter;
    userManagementReferenceDataStore: UserManagementDataProviderStore;
}

export interface IPerformanceStatementCareActivityDetailPanelProps {
    _dependencies?: IPerformanceStatementCareActivityDetailPanelDependencies;
    _masterDetailState?: IMasterDetailState;
    careActivityId: CareActivityId;
    initiallyActiveTabName: string;
}

@State.observer
class PerformanceStatementCareActivityDetailPanel extends React.Component<IPerformanceStatementCareActivityDetailPanelProps> {

    @State.observable.ref private neakPerformanceStatementCareActivityPdf: LargeDataToken = null;
    @State.observable.ref private euPerformanceStatementCareActivityPdf: LargeDataToken = null;
    @State.observable.ref private neakStatusHistory: PerformanceStatementCareActivityStatus[] = [];
    @State.observable.ref private neakStatusHistoryDataSource = new InMemoryDataGridDataSource<PerformanceStatementCareActivityStatus>(() => this.neakStatusHistory);
    @State.observable.ref private euStatusHistory: PerformanceStatementCareActivityStatus[] = [];
    @State.observable.ref private euStatusHistoryDataSource = new InMemoryDataGridDataSource<PerformanceStatementCareActivityStatus>(() => this.euStatusHistory);

    @State.observable.ref private neakPerformanceStatements: INeakPerformanceStatementListItem[] = [];
    @State.observable.ref private euPerformanceStatements: IEuPerformanceStatementListItem[] = [];
    @State.observable.ref private neakPerformanceStatementsDataSource = new InMemoryDataGridDataSource<INeakPerformanceStatementListItem>(() => this.neakPerformanceStatements);
    @State.observable.ref private euPerformanceStatementDataSource = new InMemoryDataGridDataSource<IEuPerformanceStatementListItem>(() => this.euPerformanceStatements);

    private get performanceStatementCareActivityApiAdapter() {
        return this.props._dependencies.performanceStatementCareActivityApiAdapter;
    }

    private get neakPerformanceStatementApiAdapter() {
        return this.props._dependencies.neakPerformanceStatementApiAdapter;
    }

    private get euPerformanceStatementApiAdapter() {
        return this.props._dependencies.euPerformanceStatementApiAdapter;
    }

    private get userManagementReferenceDataStore() { return this.props._dependencies.userManagementReferenceDataStore; }

    @State.action.bound
    public setStatusHistory(statusHistory: PerformanceStatementCareActivityStatus[]) {
        this.neakStatusHistory = statusHistory;
    }

    @State.action.bound
    public setNeakPerformanceStatements(neakPerformanceStatements: INeakPerformanceStatementListItem[]) {
        this.neakPerformanceStatements = neakPerformanceStatements;
    }

    @State.action.bound
    public setEuStatusHistory(statusHistory: PerformanceStatementCareActivityStatus[]) {
        this.euStatusHistory = statusHistory;
    }

    @State.action.bound
    public setEuPerformanceStatements(euPerformanceStatements: IEuPerformanceStatementListItem[]) {
        this.euPerformanceStatements = euPerformanceStatements;
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadDataAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IPerformanceStatementCareActivityDetailPanelProps) {
        if (!!this.props.careActivityId.value && !ValueWrapper.equals(prevProps.careActivityId, this.props.careActivityId)) {
            dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        }
    }

    @State.action.bound
    private setNeakPerformanceStatementCareActivityPdf(newValue: LargeDataToken) {
        this.neakPerformanceStatementCareActivityPdf = newValue;
    }
    
    @State.action.bound
    private setEuPerformanceStatementCareActivityPdf(newValue: LargeDataToken) {
        this.euPerformanceStatementCareActivityPdf = newValue;
    }

    @State.action.bound
    private async loadDataAsync() {
        const neakPdfResult = await this.performanceStatementCareActivityApiAdapter.CreatePerformanceStatementCareActivityPdfAsync(this.props.careActivityId);
        this.setNeakPerformanceStatementCareActivityPdf(neakPdfResult.value);

        const euPdfResult = await this.performanceStatementCareActivityApiAdapter.CreateEuPerformanceStatementCareActivityPdfAsync(this.props.careActivityId);
        this.setEuPerformanceStatementCareActivityPdf(euPdfResult.value);

        if (this.props.careActivityId) {
            const statusHistoryResult = await this.performanceStatementCareActivityApiAdapter.getPerformanceStatementCareActivityStatusHistoryByIdAsync(this.props.careActivityId);
            if (statusHistoryResult.operationWasSuccessful) {
                const userIds = statusHistoryResult.value.map(i => i.modifiedBy);
                await this.userManagementReferenceDataStore.users.ensureLoadedAsync(userIds);
                this.setStatusHistory(statusHistoryResult.value);
            }

            const neakPerformanceStatementsResult = await this.neakPerformanceStatementApiAdapter.getPerformanceStatementsByCareActivityIdAsync(this.props.careActivityId);
            if (neakPerformanceStatementsResult.operationWasSuccessful) {
                this.setNeakPerformanceStatements(neakPerformanceStatementsResult.value);
            }

            const euStatusHistoryResult = await this.performanceStatementCareActivityApiAdapter.getEuPerformanceStatementCareActivityStatusHistoryByIdAsync(this.props.careActivityId);
            if (euStatusHistoryResult.operationWasSuccessful) {
                const userIds = statusHistoryResult.value.map(i => i.modifiedBy);
                await this.userManagementReferenceDataStore.users.ensureLoadedAsync(userIds);
                this.setEuStatusHistory(euStatusHistoryResult.value);
            }

            const euPerformanceStatementsResult = await this.euPerformanceStatementApiAdapter.getPerformanceStatementsByCareActivityIdAsync(this.props.careActivityId);
            if (euPerformanceStatementsResult.operationWasSuccessful) {
                this.setEuPerformanceStatements(euPerformanceStatementsResult.value);
            }
        }
    }

    @State.bound
    private close() {
        this.props._masterDetailState?.onSelectedItemChange(null);
    }

    private renderHeader() {
        return (
            <Ui.PageBox2.Header
                title={StaticHunSocialSecurityPerformanceStatementResources.PerformanceStatementCareActivityWorkListPanel.Detail.Title}
                toolbar={null}
                hasDetailCloseButton={true}
                onCloseDetail={this.close} />
        );
    }

    public render() {
        const resources = StaticHunSocialSecurityPerformanceStatementResources.PerformanceStatementCareActivityWorkListPanel.Detail;

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={resources.CareActivityPdf} />;
        }

        return (
            <CareActivityContextProvider careActivityId={this.props.careActivityId}>
                <PatientContextProvider>
                    <Ui.PageBox2
                        hasTabs
                        initiallyActiveTabName={this.props.initiallyActiveTabName}
                    >
                        <Ui.PageBox2.Tab tabName="PerformanceStatementCareActivityPdf"
                            tabTitle={resources.CareActivityPdf}
                            automationId="careActivityPdfTab">
                            {this.renderHeader()}
                            <Ui.PageBox2.Body>
                                {
                                    this.neakPerformanceStatementCareActivityPdf &&
                                    <Ui.PdfViewer2
                                        documentPath={this.neakPerformanceStatementCareActivityPdf}
                                        downloadFileName={"NeakPerformanceStatement"}
                                    />
                                }
                            </Ui.PageBox2.Body>
                        </Ui.PageBox2.Tab>
                        {this.euPerformanceStatementCareActivityPdf &&
                            <Ui.PageBox2.Tab tabName="EuPerformanceStatementCareActivityPdf"
                                tabTitle={resources.EDataSheet}
                                automationId="eDataSheetTab">
                                {this.renderHeader()}
                                <Ui.PageBox2.Body>
                                {
                                    this.euPerformanceStatementCareActivityPdf &&
                                    <Ui.PdfViewer2
                                        documentPath={this.euPerformanceStatementCareActivityPdf}
                                        downloadFileName={"EuPerformanceStatement"}
                                    />
                                }
                                </Ui.PageBox2.Body>
                            </Ui.PageBox2.Tab>
                        }
                        <Ui.PageBox2.Tab tabName="PerformanceStatementCareActivityStatusHistory"
                            tabTitle={resources.StatusHistory}
                            automationId="statusHistoryTab">
                            {this.renderHeader()}
                            <Ui.PageBox2.Body>
                                <HisUi.OutpatientHeader showReducedData />
                                <Ui.GroupBox title={resources.NeakStatusHistory}>
                                    <Ui.DataGrid
                                        dataSource={this.neakStatusHistoryDataSource}
                                        actionsColumn={false}
                                        changeOnMount
                                        rowHeight={40}
                                        automationId="neakStatusHistoryDataGrid">
                                        <PerformanceStatementCareActivityStatusTypeColumn
                                            header={resources.StatusHistoryColumns.Type}
                                            dataGetter={"type"}
                                            displayMode="name"
                                        />
                                        <DataGridMonthDateColumn
                                            header={resources.StatusHistoryColumns.ValidAt}
                                            dataGetter={"validAt"}
                                        />
                                        <DataGridDateTimeColumn
                                            id="modifiedAt"
                                            header={resources.StatusHistoryColumns.ModifiedAt}
                                            dataGetter={"modifiedAt"}
                                        />
                                        <UserColumn
                                            header={resources.StatusHistoryColumns.ModifiedBy}
                                            dataGetter={"modifiedBy"}
                                        />
                                        <PerformanceStatementCareActivityStatusReasonColumn
                                            header={resources.StatusHistoryColumns.Reason}
                                            dataGetter={"reason"}
                                        />
                                    </Ui.DataGrid>
                                </Ui.GroupBox>
                                <Ui.GroupBox title={resources.SentNeakPerformanceStatementRecords}>
                                    <Ui.DataGrid
                                        dataSource={this.neakPerformanceStatementsDataSource}
                                        actionsColumn={false}
                                        changeOnMount
                                        rowHeight={40}
                                        fixedLayout
                                        horizontalScroll
                                        automationId="neakPerformanceStatementsDataGrid">
                                        <DataGridDateTimeColumn header={resources.NeakPerformanceStatementsColumns.FinalizedAt} dataGetter={"finalizedAt"} width={125} />
                                        <DataGridMonthDateColumn header={resources.NeakPerformanceStatementsColumns.ReportValidityDate} dataGetter={"reportValidityDate"} width={100} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.PointOfCare} dataGetter={"pointOfCare"} width={80} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.LeadDoctor} dataGetter={"leadDoctor"} width={80} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.ReferralWorkplace} dataGetter={"referralWorkplace"} width={80} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.ReferralDoctor} dataGetter={"referralDoctor"} width={80} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.ReferralCareIdentifier} dataGetter={"referralCareIdentifier"} width={80} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.ReferralCreationDate} dataGetter={"referralCreationDate"} width={80} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.CareIdentifier} dataGetter={"careIdentifier"} width={80} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.WentUnderCareAtDate} dataGetter={"wentUnderCareAtDate"} width={80} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.WentUnderCareAtHour} dataGetter={"wentUnderCareAtHour"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.CitizenshipCountryCode} dataGetter={"citizenshipCountryCode"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.IdentifierValue} dataGetter={"identifierValue"} width={80} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.IdentifierTypeId} dataGetter={"identifierTypeId"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.GenderId} dataGetter={"genderId"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.BirthDate} dataGetter={"birthDate"} width={80} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.ZipCode} dataGetter={"zipCode"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.FinancingClassId} dataGetter={"financingClassId"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.PartiallyReimbursedCost} dataGetter={"partiallyReimbursedCost"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.AdmissionReasonId} dataGetter={"admissionReasonId"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.Diagnoses} dataGetter={"diagnoses"} width={250} hideOverflow />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.Services} dataGetter={"services"} width={250} hideOverflow />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.DischargeReasonId} dataGetter={"dischargeReasonId"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.LaboratoryExaminationRequestTypeId} dataGetter={"laboratoryExaminationRequestTypeId"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.ImagingExaminationRequestTypeId} dataGetter={"imagingExaminationRequestTypeId"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.CtMrExaminationRequestTypeId} dataGetter={"ctMrExaminationRequestTypeId"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.PhysiotherapyExaminationRequestTypeId} dataGetter={"physiotherapyExaminationRequestTypeId"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.EarningCapacityConsiderationTypeId} dataGetter={"earningCapacityConsiderationTypeId"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.TravelExpenseClaimTypeId} dataGetter={"travelExpenseClaimTypeId"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.AccidentTypeId} dataGetter={"accidentTypeId"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.NumberOfPrescriptions} dataGetter={"numberOfPrescriptions"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.NumberOfGySePrescriptions} dataGetter={"numberOfGySePrescriptions"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.NumberOfTherapyPrescriptions} dataGetter={"numberOfTherapyPrescriptions"} width={60} />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.FinanceStatement} dataGetter={"financeStatement"} width={80} hideOverflow />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.ReimbursementFree} dataGetter={"reimbursementFree"} width={80} hideOverflow />
                                        <DataGridColumn header={resources.NeakPerformanceStatementsColumns.ReimbursementType} dataGetter={"reimbursementType"} width={80} hideOverflow />
                                    </Ui.DataGrid>
                                </Ui.GroupBox>
                                {!!this.euStatusHistory.length && <>
                                    <Ui.GroupBox title={resources.EuStatusHistory}>
                                        <Ui.DataGrid
                                            dataSource={this.euStatusHistoryDataSource}
                                            actionsColumn={false}
                                            changeOnMount
                                            rowHeight={40}
                                            automationId="euStatusHistory_dataGrid">
                                            <PerformanceStatementCareActivityStatusTypeColumn
                                                header={resources.StatusHistoryColumns.Type}
                                                dataGetter={"type"}
                                                displayMode="name"
                                            />
                                            <DataGridMonthDateColumn
                                                header={resources.StatusHistoryColumns.ValidAt}
                                                dataGetter={"validAt"}
                                            />
                                            <DataGridDateTimeColumn
                                                id="modifiedAt"
                                                header={resources.StatusHistoryColumns.ModifiedAt}
                                                dataGetter={"modifiedAt"}
                                            />
                                            <UserColumn
                                                header={resources.StatusHistoryColumns.ModifiedBy}
                                                dataGetter={"modifiedBy"}
                                            />
                                            <PerformanceStatementCareActivityStatusReasonColumn
                                                header={resources.StatusHistoryColumns.Reason}
                                                dataGetter={"reason"}
                                            />
                                        </Ui.DataGrid>
                                    </Ui.GroupBox>
                                    <Ui.GroupBox title={resources.SentEuPerformanceStatementRecords}>
                                        <Ui.DataGrid
                                            dataSource={this.euPerformanceStatementDataSource}
                                            actionsColumn={false}
                                            changeOnMount
                                            rowHeight={40}
                                            fixedLayout
                                            horizontalScroll
                                            automationId="euPerformanceStatement_dataGrid">
                                            <DataGridDateTimeColumn header={resources.NeakPerformanceStatementsColumns.FinalizedAt} dataGetter={"finalizedAt"} width={125} />
                                            <DataGridMonthDateColumn header={resources.NeakPerformanceStatementsColumns.ReportValidityDate} dataGetter={"reportValidityDate"} width={100} />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.FinalizedAt} dataGetter={"coveredPeriod"} width={125} />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.Identifier1} dataGetter={"identifierValue"} width={100} />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.PointOfCareNeak} dataGetter={"pointOfCareNEAK"} width={80} />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.PointOfCareFormOfCare} dataGetter={"pointOfCareFormOfCare"} width={80} />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.CareIdentifier} dataGetter={"careIdentifier"} width={100} />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.WentUnderCareAt} dataGetter={"wentUnderCareAt"} width={100} />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.DischargedAt} dataGetter={"dischargedAt"} width={100} />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.InsuranceNumber} dataGetter={"insuranceNumber"} width={100} hideOverflow />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.Gender} dataGetter={"genderId"} width={60} />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.BirthDate} dataGetter={"birthDate"} width={100} />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.PatientFamilyName} dataGetter={"patientFamilyName"} width={125} hideOverflow />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.PatientGivenName} dataGetter={"patientGivenName"} width={125} hideOverflow />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.InsurerCountryCode} dataGetter={"insurerCountryCode"} width={80} />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.InsurerOrganizationCode} dataGetter={"insurerOrganizationCode"} width={150} hideOverflow />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.CardIdentifierValue} dataGetter={"cardValue"} width={150} hideOverflow />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.CardExpiration} dataGetter={"cardExpiration"} width={100} />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.ValidFrom} dataGetter={"validFrom"} width={125} />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.ValidTo} dataGetter={"validTo"} width={125} />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.InsurerName} dataGetter={"insurerName"} width={125} hideOverflow />
                                            <DataGridColumn header={resources.EuPerformanceStatementsColumns.InsurerAddress} dataGetter={"insurerAddress"} width={125} hideOverflow />
                                        </Ui.DataGrid>
                                    </Ui.GroupBox>
                                </>}
                            </Ui.PageBox2.Body>
                        </Ui.PageBox2.Tab>
                    </ Ui.PageBox2>
                </PatientContextProvider>
            </CareActivityContextProvider>
        );
    }
}

const masterDetailConnectedPanel = (props: IPerformanceStatementCareActivityDetailPanelProps) => {
    const ctx = useContext(MasterDetailContext);

    if (ctx == null) {
        return null;
    }

    return <PerformanceStatementCareActivityDetailPanel {...props} _masterDetailState={ctx?.state} />;
};

export default connect(
    masterDetailConnectedPanel,
    new DependencyAdapter<IPerformanceStatementCareActivityDetailPanelProps, IPerformanceStatementCareActivityDetailPanelDependencies>(c => ({
        performanceStatementCareActivityApiAdapter: c.resolve("PerformanceStatementCareActivityApiAdapter"),
        neakPerformanceStatementApiAdapter: c.resolve("NeakPerformanceStatementApiAdapter"),
        userManagementReferenceDataStore: c.resolve("UserManagementDataProviderStore"),
        euPerformanceStatementApiAdapter: c.resolve("EuPerformanceStatementApiAdapter")
    }))
);
