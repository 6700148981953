import { IInsertServiceRequestResultDialogResult, IInsertServiceRequestResultDialogParams } from "./InsertServiceRequestResultDialogParams";
import ServiceRequestManagementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestManagementApiAdapter";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import InsertServiceRequestResultDialogView from "./InsertServiceRequestResultDialogView";
import ExternalLocationId, { ExternalLocationVersionSelector } from "@Primitives/ExternalLocationId.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import CareActivityTextBlockApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityTextBlock/CareActivityTextBlockApiAdapter";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import { IDocumentContentStore } from "@CommonControls/DocumentEditor/DocumentContentStore";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import DocumentFile from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentFile";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ServiceRequestState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestState.g";

interface IInsertServiceRequestResultDialogDependencies {
    serviceRequestManagementApiAdapter: ServiceRequestManagementApiAdapter;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    careReferenceDataStore: CareReferenceDataStore;
    careActivityTextBlockAdapter: CareActivityTextBlockApiAdapter;
}

interface IInsertServiceRequestResultDialogProps extends IModalComponentParams<IInsertServiceRequestResultDialogResult>, IInsertServiceRequestResultDialogParams {
    _dependencies?: IInsertServiceRequestResultDialogDependencies;
}

/** @screen */
@State.observer
class InsertServiceRequestResultDialog extends React.Component<IInsertServiceRequestResultDialogProps> {

    @State.observable.ref private serviceRequestList: ServiceRequestStore[];
    @State.observable.ref private externalLocationVersionSelectors: ExternalLocationVersionSelector[] = null;
    private observationResultContent = new Map<string, IDocumentContentStore>();
    @State.observable.ref private selected: ServiceRequestStore = null;
    @State.observable.ref private selectedContent: IDocumentContentStore = null;

    private get organizationReferenceDataStore() {
        return this.props._dependencies.organizationReferenceDataStore;
    }
    private get careReferenceDataStore() {
        return this.props._dependencies.careReferenceDataStore;
    }

    private get apiAdapter() { return this.props._dependencies.serviceRequestManagementApiAdapter; }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }
    @State.action.bound
    public setSelected(row: ServiceRequestStore) {
        this.selected = row;
        dispatchAsyncErrors(this.rowSelectedAsync(row), this);
    }
    @State.action.bound
    private async loadObservationResultContentAsync(id: ServiceRequestId) {
        const cachedContent = this.observationResultContent.get(id.value);

        if (cachedContent) {
            return cachedContent;
        } else {
            const result = await this.apiAdapter.getObservationsResultAsync(this.props.careActivityId, id);
            if (result.operationWasSuccessful) {
                const base64Content = Base64Converter.fromString(result.value.observationsResult);
                const templatedContent = DocumentFile.create(base64Content);
                this.observationResultContent.set(id.value, templatedContent.contentStore);
                return templatedContent.contentStore;
            }
        }
        return null;
    }
    @State.bound
    private async onSaveAsync() {
        if (this.selectedContent) {
            const isSelected = await this.selectedContent.isTextSelectedAsync();
            const result: IInsertServiceRequestResultDialogResult = {
                selected: isSelected ?
                    await this.selectedContent.getSelectedContentAsDocumentFragmentAsync() :
                    await this.selectedContent.getContentAsDocumentFragmentAsync()
            };
            this.props.onClose(result);
        } else {
            this.props.onClose(null);
        }
    }

    @State.bound
    public async rowSelectedAsync(row: ServiceRequestStore) {
        if (isNullOrUndefined(row)) {
            return;
        }
        const content = await this.loadObservationResultContentAsync(row.id);
        State.runInAction(() => {
            this.selectedContent = content;
        });
    }

    @State.action.bound
    private async loadAsync() {
        if (this.props.careActivityId) {
            const response = await this.apiAdapter.getServiceRequestsByCareActivityIdAsync(this.props.careActivityId);
            const filteredResponse = response.items.filter(item =>
                item.state === ServiceRequestState.ValidatedCompleted ||
                item.state === ServiceRequestState.ActivityCompleted ||
                item.state === ServiceRequestState.NonValidatedCompleted);
            await this.initializeServiceRequestStoresAsync(filteredResponse);
            if (response.operationWasSuccessful) {
                State.runInAction(() => {
                    this.serviceRequestList = filteredResponse;
                    this.externalLocationVersionSelectors = this.serviceRequestList.filter(s => s.isExternal).map(s => s.targetExternalLocationVersionSelector);
                });

            }
        }
    }
    private async initializeServiceRequestStoresAsync(items: ServiceRequestStore[]) {
        const serviceVersionSelectors = items.map((i) => i.serviceRequestDefinitionVersion) as Array<IEntityVersionSelector<ServiceRequestDefinitionId>>;
        await this.careReferenceDataStore.serviceRequestDefinition.ensureLoadedAsync(serviceVersionSelectors);

        const internalLocations = items
            .filter(item => item.targetCareLocation instanceof InternalCareLocation)
            .map(item => (item.targetCareLocation as InternalCareLocation).pointOfCareId);
        await this.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync(internalLocations);

        const externalLocations = items
            .filter(item => item.targetCareLocation instanceof ExternalCareLocation)
            .map(item => {
                return {
                    id: (item.targetCareLocation as ExternalCareLocation).externalLocationSelector.id,
                    validOn: (item.targetCareLocation as ExternalCareLocation).externalLocationSelector.validOn
                } as IEntityVersionSelector<ExternalLocationId>;
            });
        await this.organizationReferenceDataStore.externalLocationStore.ensureLoadedAsync(externalLocations);

        const doctorIds = items.map(it => it.requesterDoctorId).concat(items.map(it => it.targetDoctorId));
        await this.organizationReferenceDataStore.ensurePractitionerIdsLoadedAsync(doctorIds);
    }

    @State.bound
    private onClose() {
        this.props.onClose(null);
    }
    public render() {
        return (
            <InsertServiceRequestResultDialogView
                serviceRequestList={this.serviceRequestList}
                onClose={this.onClose}
                selected={this.selected}
                setSelected={this.setSelected}
                displayMode={this.props.displayMode}
                externalLocationVersionSelectors={this.externalLocationVersionSelectors}
                selectedContent={this.selectedContent}
                onSaveAsync={this.onSaveAsync}
            />
        );
    }

}
export default connect(
    InsertServiceRequestResultDialog,
    new DependencyAdapter<IInsertServiceRequestResultDialogProps, IInsertServiceRequestResultDialogDependencies>(container => {
        return {
            serviceRequestManagementApiAdapter: container.resolve("ServiceRequestManagementApiAdapter"),
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            careActivityTextBlockAdapter: container.resolve("CareActivityTextBlockApiAdapter"),
        };
    })
);