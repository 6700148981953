import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import UserManagementPanel from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserManagementPanel/UserManagementPanel";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PanelController from "@Toolkit/ReactClient/Components/PanelController";
import { HisErrorBoundary } from "@HisPlatformControls";
import IUserManagementRouteParams from "@HisPlatform/Application/Routes/IUserManagementRouteParams";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import UserId from "@Primitives/UserId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";


interface IUserManagementPageDependencies {

}

interface IUserManagementPageProps extends IRoutingFrameContentProps {
    _dependencies?: IUserManagementPageDependencies;
}

@State.observer
class UserManagementPage extends React.Component<IUserManagementPageProps> {
    @State.observable.ref private controller = PanelController.createFromRoutingController(this.props.routingController);

    private get routeParams(): IUserManagementRouteParams {
        return (this.props.routingController.currentRoute.parameters as IUserManagementRouteParams);
    }

    @State.bound
    private updateRoute(newType: string, newId: string, newTab: string) {
        this.props.routingController.replace(ApplicationRoutes.userManagement.makeRoute({ type: newType, id: newId, tab: newTab }));
    }

    @State.computed
    private get userId() {
        return this.routeParams && this.routeParams.type && this.routeParams.type === "user" && this.routeParams.id && new UserId(this.routeParams.id);
    }

    @State.computed
    private get practitionerId() {
        return this.routeParams && this.routeParams.type && this.routeParams.type === "practitioner" && this.routeParams.id && new PractitionerId(this.routeParams.id);
    }

    @State.bound
    public back() {
        this.props.routingController.goBack();
    }

    public render() {
        return (
            <HisErrorBoundary>
                <LoadingBoundary>
                    <UserManagementPanel
                        navigationController={this.controller}
                        tab={this.routeParams && this.routeParams.tab}
                        userId={this.userId}
                        practitionerId={this.practitionerId}
                        onNavigationChanged={this.updateRoute}
                        onBack={this.back}
                    />
                </LoadingBoundary>
            </HisErrorBoundary>
        );
    }
}

export default UserManagementPage;
