import React from "react";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ReportingReferenceDataStore from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/Model/ReportingReferenceDataStore";
import ReportingApiAdapter from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/ReportingApiAdapter";
import { NestedPermissionCheckContextProvider } from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import { IReportingToolbarExtensionProps } from "@PluginInterface/Common/IReportingToolbarExtensionProps";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import IReportingRouteParams from "@HisPlatform/Application/Routes/IReportingRouteParams";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";

interface IReportingToolbarDependencies {
    reportingReferenceDataStore: ReportingReferenceDataStore;
    reportingApiAdapter: ReportingApiAdapter;
    worklistApiAdapter: WorklistApiAdapter;
    globalRoutingStore: GlobalRoutingStore;
    localizationService: ILocalizationService;
}

interface IReportingToolbarProps {
    _dependencies?: IReportingToolbarDependencies;
}

@State.observer
class ReportingToolbar extends React.Component<IReportingToolbarProps> {

    private get globalRoutingStore() { return this.props._dependencies.globalRoutingStore; }
    private get reportingReferenceDataStore() { return this.props._dependencies.reportingReferenceDataStore; }
    private get localizationService() { return this.props._dependencies.localizationService; }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private async loadAsync() {
        await this.reportingReferenceDataStore.reportingDefinitions.ensureLoadedAsync();
    }

    private reportDefinitionChangeHandler = (reportDefinitionId: string) => () => {
        this.navigateToReportDefinition(reportDefinitionId);
    };

    @State.bound
    private navigateToReportDefinition(reportDefinitionId: string) {
        this.globalRoutingStore.push(ApplicationRoutes.reporting.makeRoute({ reportDefinitionId: reportDefinitionId } as IReportingRouteParams));
    }

    private get permissionCheckedOperations() {
        const res = {};
        this.reportingReferenceDataStore.reportingDefinitions.items?.forEach((item) => {
            res[item.reportDefinitionIdentifier.value] = (async () => await this.props._dependencies.reportingApiAdapter.runReportAsync(item.reportDefinitionIdentifier, {} as JSON, true));
        });

        return res;
    }

    public render() {

        if (!this.reportingReferenceDataStore.reportingDefinitions || !this.reportingReferenceDataStore.reportingDefinitions.items)
            return null; 


        if (this.reportingReferenceDataStore.reportingDefinitions.items.length === 0) {
            return (
                <Ui.NavBar.Item
                    automationId="noreport"
                >
                    {this.localizationService.localizeWebAppResource("Navbar.Reporting.NoReports")}
                </Ui.NavBar.Item>
            );
        }

        return (
            <NestedPermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                {this.navBarItems}
            </NestedPermissionCheckContextProvider>
        );
    }

    private get navBarItems() {
        const navBarItems: JSX.Element[] = this.reportingReferenceDataStore.reportingDefinitions.items?.map((item, index) => {
            return (
                <Ui.NavBar.Item
                    key={index}
                    onClick={this.reportDefinitionChangeHandler(item.reportDefinitionIdentifier.value)}
                    automationId={item.reportDefinitionIdentifier.value}
                    permissionCheckOperationNames={item.reportDefinitionIdentifier.value}
                    permissionDeniedStyle="disabled"
                >
                    {item.name}
                </Ui.NavBar.Item>
            );
        });

        return navBarItems as JSX.Element[];
    }
}
export default connect(
    ReportingToolbar,
    new DependencyAdapter<IReportingToolbarProps, IReportingToolbarDependencies>(c => ({
        reportingReferenceDataStore: c.resolve("ReportingReferenceDataStore"),
        reportingApiAdapter: c.resolve("ReportingApiAdapter"),
        worklistApiAdapter: c.resolve("WorklistApiAdapter"),
        globalRoutingStore: c.resolve("GlobalRoutingStore"),
        localizationService:  c.resolve("ILocalizationService")
    }))
);