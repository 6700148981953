import React from "react";
import { ITokenSettingsPanelPropsBase } from "@PluginInterface/BoundedContexts/DocumentManagement/TokenSettingsPanelRegistry/ITokenSettingsPanelRegistry";
import TextBox from "@CommonControls/TextBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import * as Ui from "@CommonControls";

interface IDateTimeTokenFormatterSettingsPanelProps extends ITokenSettingsPanelPropsBase<Proxy.DateTimeTokenValueFormatterSettingsDto> {
}

/** @screen */
export default class DateTimeTokenFormatterSettingsPanel extends React.Component<IDateTimeTokenFormatterSettingsPanelProps> {
    public render() {
        return (
            <TextBox
                label={StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.DateFormat}
                infoLabel={this.renderInfo()} value={this.props.tokenFormatterSettings?.formatString ?? ""}
                placeholder="yyyy-MM-dd HH:mm:ss"
                onChange={this.setFormatString} 
                automationId="dateTimeFormatTextBox" />
        );
    }

    private renderInfo() {
        return (
            <Ui.Table automationId="dateTimeTokenFormatterSettingsPanel_table">
                <thead>
                    <tr>
                        <th>{StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.DateFormat}</th>
                        <th>{StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.Examples}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>"mm"</td>
                        <td>2009-06-01T13:45:30 -{">"} 45</td>
                    </tr>
                    <tr>
                        <td>"hh"</td>
                        <td>2009-06-15T13:45:30 -{">"} 13</td>
                    </tr>
                    <tr>
                        <td>"hh:mm:ss"</td>
                        <td>2009-06-15T13:45:30 -{">"} 13:45:30</td>
                    </tr>
                    <tr>
                        <td>"yy.MM hh:mm"</td>
                        <td>2009-06-15T13:45:30 -{">"} 09.06 13:45</td>
                    </tr>
                </tbody>
            </Ui.Table>
        );
    }

    @State.bound
    private setFormatString(value: string) {
        this.props.onSettingsChange(new Proxy.DateTimeTokenValueFormatterSettingsDto({
            formatString: value
        }), null);
    }
}