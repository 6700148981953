import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import IFormEngineApiAdapter from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";


export interface IFormDefinitionSelectorDialogParams {
    cultureCodeProvider: ICurrentCultureProvider;
    apiAdapter: IFormEngineApiAdapter;
    baseEntityType: string;
    formDefinitions?: IFormDefinition[];
}

export class FormDefinitionSelectorDialogParams implements IFormDefinitionSelectorDialogParams {
    public static type = "FormDefinitionSelectorDialog";
    public get type() { return FormDefinitionSelectorDialogParams.type; }

    constructor(
        public readonly cultureCodeProvider: ICurrentCultureProvider,
        public readonly apiAdapter: IFormEngineApiAdapter,
        public readonly baseEntityType: string,
        public readonly formDefinitions?: IFormDefinition[]
    ) { }
}

export interface IFormDefinitionSelectorDialogResult {
    formDefinitionId: FormDefinitionId;
}