import Di from "@Di";
import EhiTokenProvider from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Services/EhiTokenProvider/EhiTokenProvider";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import EhiOrganizationUnitProviderService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Organization/Services/Implementation/EhiOrganizationUnitProviderService";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import EhiPrescriptionService from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/EhiPrescriptionService";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";

@Di.injectable()
export default class EhiPrescriptionServiceFactory {

    constructor(
        @Di.inject("PrescriptionApiAdapter") private readonly prescriptionApiAdapter: PrescriptionApiAdapter,
        @Di.inject("EhiTokenProvider") private readonly ehiTokenProvider: EhiTokenProvider,
        @Di.inject("EhiOrganizationUnitProviderService") private readonly ehiOrganizationUnitProviderService: EhiOrganizationUnitProviderService,
        @Di.inject("IModalService") private readonly modalService: IModalService,
        @Di.inject("UserContext") private readonly userContext: UserContext
    ) { }

    public getEhiPrescriptionService() {
        return new EhiPrescriptionService(
            this.prescriptionApiAdapter,
            this.ehiTokenProvider,
            this.ehiOrganizationUnitProviderService,
            this.userContext,
            this.modalService
        );
    }
}
