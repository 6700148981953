import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";

export default class TokenUsageStore {

    @State.observable.ref public tokenFormatterSettings: object;
    @State.observable.ref public providerParameterSettings: object;

    public formatDisplayName(name: string) {
        return `${name} (${this.index})`;
    }

    public get tokenId() {
        return `${this.symbol}#${this.index}`;
    }

    constructor(
        public readonly symbol: string,
        public readonly index: number,
        tokenFormatterSettings: object,
        providerParameterSettings: object
    ) {
        this.tokenFormatterSettings = tokenFormatterSettings;
        this.providerParameterSettings = providerParameterSettings;
    }

    public static parse(tokenId: string, tokenFormatterSettings: object, providerParameterSettings: object) {
        if (!tokenId) {
            throw new Error("Cannot parse empty/null/undefined tokenId!");
        }

        try {

            const parts = tokenId.split("#");
            const symbol = parts[0];
            const index = parseInt(parts[1], 10);
            return new TokenUsageStore(symbol, index, tokenFormatterSettings, providerParameterSettings);

        } catch (err) {
            throw new Error(`Cannot parse tokenId: '${tokenId}', error: ${err}`);
        }
    }

    @State.action.bound
    public setSettings(tokenFormatterSettings: object, providerParameterSettings: object) {
        this.tokenFormatterSettings = tokenFormatterSettings;
        this.providerParameterSettings = providerParameterSettings;
    }
}