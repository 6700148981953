import React from "react";
import * as Ui from "@CommonControls";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import PaperSize from "@HisPlatform/BoundedContexts/DocumentManagement/Api/PdfTemplating/Enum/PaperSize.g";

interface IPrintPaperSizeSelectBoxProps extends ISelectBoxBaseProps {
    value?: PaperSize;
    onChange?: (newValue: PaperSize) => void;
}


const PrintPaperSizeSelectBox: React.FC<IPrintPaperSizeSelectBoxProps> = props => (
    <Ui.UniversalEnumSelector
        {...props}
        enumName={"PaperSize"}
        enumOrigin="server"
        enumType={PaperSize}
        displayMode="selectBox"
    />
);
export default PrintPaperSizeSelectBox;