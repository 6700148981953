import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React, { useCallback, useState } from "react";
import IPatientCareActivitiesTabProps from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabProps";
import PrescriptionMasterDetailPanel from "./PrescriptionMasterDetailPanel";
import PrescriptionId from "@Primitives/PrescriptionId.g";

function WrappedEhrPrescriptionMasterDetailPanel(props: IPatientCareActivitiesTabProps) {

    const [prescriptionId, setPrescriptionId] = useState<PrescriptionId>();

    const resetPrescriptionId = useCallback(() => {
        setPrescriptionId(null);
    }, []);

    return (
        <PrescriptionMasterDetailPanel
            prescriptionId={prescriptionId}
            onNavigateToPrescription={setPrescriptionId}
            readonly
            isCompact
            showDetailCloseButton={false}
            onBack={resetPrescriptionId}
            hideUpdateDateWarning
            careActivityStartedAt={props.beginningOfCare}
            careActivityDischargedAt={props.dischargedAt}
        />
    );
}

export default connect<IPatientCareActivitiesTabProps>(
    WrappedEhrPrescriptionMasterDetailPanel
);