
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import AdmitPatientAndCareActivityBaseDataScreenStore from "./AdmitPatientAndCareActivityBaseDataScreenStore";
import { editorScreenRenderFunc } from "@Toolkit/CommonWeb/PanelStore/EditorScreenRenderFunc";
import { IAdmitPatientAndCareActivityBaseDataScreenProps } from "./AdmitPatientAndCareActivityBaseDataScreen";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ShowEditPatientAdmissionDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEditPatientAdmissionDataScreenAction.g";
import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";

const {
    ContextComponent: AdmitPatientAndCareActivityBaseDataScreenStoreContext,
    StoreProvider: AdmitPatientAndCareActivityBaseDataScreenStoreProvider,
    useStore: useAdmitPatientAndCareActivityBaseDataScreenStore,
    provideStore: provideAdmitPatientAndCareActivityBaseDataScreenStore,
} = createPanelStoreProvider<AdmitPatientAndCareActivityBaseDataScreenStore>(AdmitPatientAndCareActivityBaseDataScreenStore, (props: IAdmitPatientAndCareActivityBaseDataScreenProps) => ({
    unauthorizedPageTitle: (props.action instanceof ShowNewAdmitPatientScreenAction || props.action instanceof ShowEditPatientAdmissionDataScreenAction) ?
        StaticCareResources.PatientAdmission.Titles.Admission :
        StaticWebAppResources.OutpatientWorkflow.CareActivityBaseDataStep.Title
}), editorScreenRenderFunc);

export {
    AdmitPatientAndCareActivityBaseDataScreenStoreContext,
    AdmitPatientAndCareActivityBaseDataScreenStoreProvider,
    useAdmitPatientAndCareActivityBaseDataScreenStore,
    provideAdmitPatientAndCareActivityBaseDataScreenStore
};