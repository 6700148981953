import * as Proxy from "@HisPlatform/BoundedContexts/Productivity/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import UserId from "@Primitives/UserId.g";
import EntityCollectionId from "@Primitives/EntityCollectionId.g";
import EntityCollectionOwner from "@HisPlatform/BoundedContexts/Productivity/Api/Personalization/Enum/EntityCollectionOwner.g";
import EntityCollectionStore, { ClientEntityCollectionType } from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/Personalization/EntityCollectionStore";
import EntityCollectionType from "@HisPlatform/BoundedContexts/Productivity/Api/Personalization/Enum/EntityCollectionType.g";

@Di.injectable()
export default class PersonalizationApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IPersonalizationClient") private readonly apiClient: Proxy.IPersonalizationClient
    ) {
        super();
    }

    @State.bound
    public getEntityCollections(entityName: string, ownerId: PointOfCareId | UserId, ownerType: EntityCollectionOwner, createId: (value: string) => IStringEntityId) {
        return this.processOperationAsync(
            new SimpleStore<EntityCollectionStore[]>(),
            async target => {
                const response = await this.apiClient.getEntityCollectionsQueryAsync(
                    CreateRequestId(),
                    ownerType.toString(),
                    entityName,
                    ownerId.value
                );
                target.operationInfo = createOperationInfo(response);
                target.value = response.entityCollections.map(item => new EntityCollectionStore(item.id, item.idList.map(id => createId(id.toString())), EntityCollectionType[item.entityCollectionType] as ClientEntityCollectionType, item.code, item.name));
            }
        );
    }

    @State.bound
    public setEntityCollectionAsync(
        entityCollectionId: EntityCollectionId,
        entityName: string,
        entityCollectionOwner: EntityCollectionOwner,
        ownerId: IStringEntityId,
        idList: IStringEntityId[],
        createId: (value: string) => IStringEntityId
    ) {
        return this.processOperationAsync(
            new SimpleStore<EntityCollectionStore>(),
            async target => {
                const response = await this.apiClient.setEntityCollectionCommandAsync(
                    CreateRequestId(),
                    new Proxy.SetEntityCollectionControllerDto({
                        entityCollectionId: entityCollectionId,
                        entityCollectionOwner: entityCollectionOwner,
                        entityName: entityName,
                        idList: idList.map(item => item.value),
                        ownerId: ownerId.value

                    } as Proxy.ISetEntityCollectionControllerDto)
                );
                target.operationInfo = createOperationInfo(response);
                target.value = new EntityCollectionStore(response.entityCollection.id,
                    response.entityCollection.idList.map(id => createId(id.toString())),
                    EntityCollectionType[response.entityCollection.entityCollectionType] as ClientEntityCollectionType,
                    response.entityCollection.code,
                    response.entityCollection.name);
            }
        );
    }

}
