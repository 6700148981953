import FormFieldDataBase from "./FormFieldDataBase";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import FormEnumReference from "@Toolkit/FormEngine/Model/Schema/FormEnumReference";

export default class EnumArrayFormFieldData extends FormFieldDataBase {
    public isArray: boolean = true;
    @State.observable.ref public value: IObservableArray<number> = State.createObservableShallowArray([]);

    constructor(fieldName: string, public formEnumReference: FormEnumReference, isReadonly: boolean, isVisible: boolean, value: IObservableArray<number>) {
        super(fieldName, isReadonly, isVisible);

        this.value = value;
    }
}