import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import IMedicalServiceExecutionCapability from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IMedicalServiceExecutionCapability";
import { CareLocation } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/CareLocation";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";
import ServiceRequestCreationType from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestCreationType.g";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import SuspectedDiagnosis from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/SuspectedDiagnosis";
import ServiceRequestSubject from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ServiceRequestSubject";
import SchedulingServiceSubject from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubject";
import UnregisteredPatientStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/UnregisteredPatientStore";
import { mapToNameDto } from "@HisPlatform/Common/PersonNameMapper";
import SlotStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SlotStore";
import ReferralStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/ReferralStore";
import TelecomTypeId from "@Primitives/TelecomTypeId.g";
import TelecomUseId from "@Primitives/TelecomUseId.g";

export function mapCreateServiceRequestWithAppointmentCommandControllerDto(
    appointment: Appointment,
    serviceRequest: ServiceRequestStore,
    isServiceRequestEmpty: boolean
) {
    return new Proxy.CreateServiceRequestWithAppointmentControllerDto({
        appointment: AppointmentCreationDto(appointment),
        serviceRequest: ServiceRequestCreationDto(serviceRequest, false, isServiceRequestEmpty),
        isValidateOnly: true
    });
}

export function mapCreateServiceRequestCommandControllerDto(store: ServiceRequestStore, shouldSubmitServiceRequest: boolean, requestLock: boolean, isValidateOnly: boolean, emptyServiceRequest: boolean) {
    return new Proxy.CreateServiceRequestControllerDto({
        serviceRequest: new Proxy.ServiceRequestCreationDto({
            clinicalQuestion: store.clinicalQuestion,
            remark: store.remark,
            requestedServices: getProxyRequestedServices(store.requestedServices),
            requesterDoctorId: store.requesterDoctorId,
            serviceRequestDefinitionId: store.serviceRequestDefinitionVersion && store.serviceRequestDefinitionVersion.id,
            starterCareActivityId: store.starterCareActivityId,
            suspectedDiagnosisList: getProxySuspectedDiganoses(store.suspectedDiagnosisList),
            targetCareLocation: store.targetCareLocation && getProxyCareLocationDto(store.targetCareLocation),
            targetDoctorId: store.targetDoctorId,
            shouldSubmitServiceRequest: shouldSubmitServiceRequest,
            direction: store.direction,
            extensionData: store.extensionData,
            expectedCompletionTime: emptyServiceRequest && store.expectedCompletionTime,
            priority: emptyServiceRequest && store.medicalServicePriority,
            serviceRequestCreationType: emptyServiceRequest ? ServiceRequestCreationType.Empty : ServiceRequestCreationType.WithRequestedServices,
            selectedMedicalServicePanelIds: store.selectedMedicalServicePanelIds
        }),
        requestLock: requestLock,
        isValidateOnly: isValidateOnly
    } as Proxy.ICreateServiceRequestControllerDto);
}

export function mapMedicalServiceAvailability(a: Proxy.IMedicalServiceExecutionCapabilityDto) {
    return {
        medicalServiceId: a.medicalServiceId,
        priorities: a.priorities,
        targetDoctorSelectionAllowed: a.targetDoctorSelectionAllowed,
        durationInMinutes: a.durationInMinutes,
        appointmentNeeded: a.appointmentNeeded
    } as IMedicalServiceExecutionCapability;
}

export function mapCareLocation(location: CareLocation) {
    if (location instanceof ExternalCareLocation) {
        return new Proxy.ExternalCareLocationDto({
            externalLocationSelector: new Proxy.EntityVersionSelectorOfExternalLocationId({
                entityId: location.externalLocationSelector.id,
                validOn: location.externalLocationSelector.validOn
            })
        });
    } else if (location instanceof InternalCareLocation) {
        return new Proxy.InternalCareLocationDto({
            pointOfCareId: location.pointOfCareId
        });
    }

    throw new Error("Failed to map care location, because the received type isn't supported.");
}

export function createServiceRequestWithAppointmentControllerDto(
    serviceRequest: ServiceRequestStore,
    shouldSubmitServiceRequest: boolean,
    requestLock: boolean,
    appointment: Appointment,
    isValidateOnly: boolean,
    isEmpty: boolean
) {
    return new Proxy.CreateServiceRequestWithAppointmentControllerDto({
        serviceRequest: ServiceRequestCreationDto(serviceRequest, shouldSubmitServiceRequest, isEmpty),
        appointment: AppointmentCreationDto(appointment),
        requestLock: requestLock,
        isValidateOnly: isValidateOnly
    } as Proxy.ICreateServiceRequestWithAppointmentControllerDto);
}

export function ServiceRequestCreationDto(serviceRequest: ServiceRequestStore, shouldSubmitServiceRequest: boolean, emptyServiceRequest: boolean) {
    return new Proxy.ServiceRequestCreationDto({
        clinicalQuestion: serviceRequest.clinicalQuestion,
        remark: serviceRequest.remark,
        requestedServices: getProxyRequestedServices(serviceRequest.requestedServices),
        requesterDoctorId: serviceRequest.requesterDoctorId,
        serviceRequestDefinitionId: serviceRequest.serviceRequestDefinitionVersion && serviceRequest.serviceRequestDefinitionVersion.id,
        starterCareActivityId: serviceRequest.starterCareActivityId,
        suspectedDiagnosisList: getProxySuspectedDiganoses(serviceRequest.suspectedDiagnosisList),
        targetCareLocation: serviceRequest.targetCareLocation && getProxyCareLocationDto(serviceRequest.targetCareLocation),
        targetDoctorId: serviceRequest.targetDoctorId,
        shouldSubmitServiceRequest: shouldSubmitServiceRequest,
        direction: serviceRequest.direction as unknown as ServiceRequestDirection,
        expectedCompletionTime: emptyServiceRequest && serviceRequest.expectedCompletionTime,
        priority: emptyServiceRequest && serviceRequest.medicalServicePriority,
        serviceRequestCreationType: emptyServiceRequest ? ServiceRequestCreationType.Empty : ServiceRequestCreationType.WithRequestedServices,
        extensionData: serviceRequest.extensionData,
        selectedMedicalServicePanelIds: serviceRequest.selectedMedicalServicePanelIds
    });
}

export function getProxyCareLocationDto(careLocation: CareLocation): Proxy.CareLocationDto {
    if (careLocation instanceof ExternalCareLocation) {
        return new Proxy.ExternalCareLocationDto({
            
            externalLocationSelector: new Proxy.EntityVersionSelectorOfExternalLocationId({
                entityId: careLocation.externalLocationSelector.id,
                validOn: careLocation.externalLocationSelector.validOn
            })
        });
    } else if (careLocation instanceof InternalCareLocation) {
        return new Proxy.InternalCareLocationDto({ 
            pointOfCareId: careLocation.pointOfCareId
        });
    }
    throw new Error("Unknown CareLocation type.");
}

export function getProxyRequestedServices(items: RequestedServiceStore[]): Proxy.RequestedServiceCreationDto[] {
    return items.map(s => {
        return new Proxy.RequestedServiceCreationDto({
            serviceId: s.medicalServiceVersionSelector.id,
            expectedCompletionTime: s.expectedCompletionTime,
            lateralityId: s.lateralityId,
            priority: s.priority
        });
    });
}

export function getProxySuspectedDiganoses(items: SuspectedDiagnosis[]): Proxy.SuspectedDiagnosisCreationDto[] {
    return items.map(d => {
        return new Proxy.SuspectedDiagnosisCreationDto({
            conditionId: d.conditionId,
            lateralityId: d.lateralityId
        });
    });
}

export function AppointmentCreationDto(appointment: Appointment) {
    return new Proxy.AppointmentCreationDto({
        appointmentScheduleSlotSeriesId: appointment.appointmentScheduleSlotSeriesId,
        subject: getSubject(appointment),
        slotsToBook: GetSlotsToBookDto(appointment.slotsToBook),
        participants: GetAppointmentParticipantDto(appointment),
        referral: mapReferral(appointment.referral, null),
        description: appointment.description
    });
}

export function getSubject(appointment: Appointment) {
    let subject: Proxy.AppointmentSubjectBaseDto;
    if (appointment.subjectService instanceof ServiceRequestSubject) {
        subject = new Proxy.ServiceRequestSubjectDto({ serviceRequestId: appointment.subjectService.versionedId.id });
    } else if (appointment.subjectService instanceof SchedulingServiceSubject) {
        subject = new Proxy.SchedulingServiceSubjectDto({ schedulingServiceId: appointment.subjectService.schedulingServiceId });
    }
    return subject;
}

export function GetAppointmentParticipantDto(appointment: Appointment) {
    const result: Proxy.AppointmentParticipantDto[] = [];

    if (appointment.pointOfCareId && appointment.pointOfCareId.value) {
        result.push(new Proxy.LocationParticipantDto({

            organizationUnitId: appointment.pointOfCareId,
            isExternal: false
        }));
    }

    if (!!appointment?.practitionerIds?.length) {
        const dtos = appointment.practitionerIds
            .filter(p => !!p.value)
            .map(i => new Proxy.PractitionerParticipantDto({ practitionerId: i, isExternal: false }));
        result.push(...dtos);
    }

    if (!!appointment?.additionalPractitionerIds?.length) {
        const dtos = appointment.additionalPractitionerIds
            .filter(p => !!p.value)
            .map(i => new Proxy.PractitionerParticipantDto({ practitionerId: i, isExternal: true }));
        result.push(...dtos);
    }

    if (appointment.patientId?.value) {
        let patientParticipant: Proxy.AppointmentParticipantDto;
        if (appointment.patientId.value === "unregistered") {
            patientParticipant = GetUnregisteredPatientParticipantDto(appointment.unregisteredPatient);
        } else {
            patientParticipant = new Proxy.RegisteredPatientParticipantDto({

                patientId: appointment.patientId,
                patientName: null,
                isExternal: false,
            });
        }

        result.push(patientParticipant);
    }
    return result;
}

export function GetUnregisteredPatientParticipantDto(unregisteredPatientStore: UnregisteredPatientStore) {
    const telecoms = new Array<Proxy.TelecomDto>();

    if (!isNullOrEmptyString(unregisteredPatientStore?.phoneNumber)){
        telecoms.push(getUnregisteredPatientTelecom(unregisteredPatientStore.phoneNumber, TelecomTypeId.Phone));
    }

    if (!isNullOrEmptyString(unregisteredPatientStore?.email)){
        telecoms.push(getUnregisteredPatientTelecom(unregisteredPatientStore.email, TelecomTypeId.Email));
    }
    
    return new Proxy.UnregisteredPatientParticipantDto({

        name: mapToNameDto(Proxy.PersonName, unregisteredPatientStore?.nameStore),
        dateOfBirth: unregisteredPatientStore?.birthDate,
        telecoms: telecoms,
        gender: unregisteredPatientStore?.genderId,
        insuranceDocumentNumber: unregisteredPatientStore?.insuranceDocumentNumber,
        mailingAddress: unregisteredPatientStore?.address,
        note: unregisteredPatientStore?.note,
        isExternal: false,
    });
}

function getUnregisteredPatientTelecom(value: string, typeId: TelecomTypeId) {
    return new Proxy.TelecomDto({
        telecomTypeId: typeId,
        telecomUseId: new TelecomUseId("Private"),
        value: value,
        isPrimary: false
    });
}

export function GetSlotsToBookDto(usedSlotIds: SlotStore[]) {
    return usedSlotIds
        .map(s => new Proxy.AppointmentScheduleSlotBookingItemDto({
            id: s.id,
            rowVersion: s.rowVersion
        }));
}

export function mapReferral(store: ReferralStore, referralExtensionData: any) {
    return new Proxy.ReferralDto({
        referralCareIdentifier: store.referralCareIdentifier,
        referralCreationDate: store.referralCreationDate,
        referralDoctorId: store.referralDoctorId,
        referralWorkplace: createEntityVersionSelectorOfExternalLocationId(store.referralWorkplace, store.referralCreationDate),
        referralDiagnosisId1: store.referralDiagnosisId1,
        referralDiagnosisLateralityId1: store.referralDiagnosisLateralityId1,
        referralDiagnosisId2: store.referralDiagnosisId2,
        referralDiagnosisLateralityId2: store.referralDiagnosisLateralityId2,
        extensionData: referralExtensionData
    });
}

export function createEntityVersionSelectorOfExternalLocationId(referral: EntityVersionSelector<ExternalLocationId>, referralCreationDate: LocalDate) {
    let validOn: LocalDate;

    if (!referral?.id) {
        return null;
    }

    if (isNullOrUndefined(referral) || referral.validOn?.isEmpty() === true) {
        validOn = referralCreationDate ?? LocalDate.today();
    } else {
        validOn = referral.validOn;
    }

    const ret = new Proxy.EntityVersionSelectorOfExternalLocationId({
        entityId: referral?.id,
        validOn
    });

    return ret;
}