import React from "react";
import * as Ui from "@CommonControls";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";
import OrganizationSelector from "@HisPlatform/BoundedContexts/Organization/Components/OrganizationSelector";
import { IReportDefinition } from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/Model/IReportDefinition";
import SingleLayout from "@CommonControls/Layout/SingleLayout";

interface IFinancedServiceMinimumTimeReportPanelViewProps {
    definition: IReportDefinition;
    reportDate: YearMonth;
    pointOfCares: PointOfCareId[];
    onReportDateChange: (newValue: YearMonth) => void;
    onPointOfCareChange: (newValue: PointOfCareId, checkedValue: boolean) => void;

    onCreateAsync: () => Promise<void>;
}


const FinancedServiceMinimumTimeReportPanelView: React.FC<IFinancedServiceMinimumTimeReportPanelViewProps> = (props) => {
    return (
        <SingleLayout>
            <Ui.PageBox2>
                <Ui.PageBox2.Header title={props.definition.name} toolbar={(
                    <div style={{ textAlign: "right" }}>
                        <Ui.Button visualStyle="primary"
                            text={StaticWebAppResources.Reporting.FinancedServiceMinimumTimeReportPanel.Create}
                            onClickAsync={props.onCreateAsync}
                            automationId="createButton" />
                    </div>
                )} />
                <Ui.PageBox2.Body>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <Ui.MonthPicker
                                value={props.reportDate}
                                onChange={props.onReportDateChange}
                                label={StaticWebAppResources.Reporting.FinancedServiceMinimumTimeReportPanel.YearMonth}
                                automationId="yearMonthPicker" />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <OrganizationSelector
                                value={props.pointOfCares}
                                onChange={props.onPointOfCareChange}
                                label={StaticWebAppResources.Reporting.FinancedServiceMinimumTimeReportPanel.OrganizationUnits}
                                pointOfCareMode={true}
                                automationId="organization"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.PageBox2.Body>
            </Ui.PageBox2>
        </SingleLayout>
    );
};

export default FinancedServiceMinimumTimeReportPanelView;