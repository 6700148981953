import State from "@Toolkit/ReactClient/Common/StateManaging";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import IdentifierAssignmentId from "@Primitives/IdentifierAssignmentId.g";

export default class PractitionerIdentifierStore extends EntityStoreBase<IdentifierAssignmentId> {
    @State.observable.ref public identifier: Identifier;
    @State.observable.ref public name: string;

    constructor() {
        super();
        this.name = "";
        this.identifier = new Identifier(null, "");
    }

    @State.action.bound
    public setName(newValue: string) {
        this.name = newValue;
    }

    public get isEmpty() {
        return !this.identifier.value &&
            !this.identifier.identifierSystemId &&
            !this.name;
    }
}
