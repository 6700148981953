import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import IIdentifierDisplayExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IIdentifierDisplayExtensionPointProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IIdentifierColumnDependencies {
    localizationService: ILocalizationService;
}

interface IIdentifierColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IIdentifierColumnDependencies;
}


const IdentifierColumn: React.FC<IIdentifierColumnProps> = (props: IIdentifierColumnProps) => {

    const renderStringValue = useCallback((value: Identifier[]) => {
        if (isNullOrUndefined(value) || !value.length) {
            return "";
        }
        
        return value.map(i => i.value).join(", ");
    }, []);

    const valueRenderer = useCallback((value: Identifier[]) => {
        return (
            value?.map((i, idx) =>
                (
                    <HisPlatformExtensionPoint
                        key={idx}
                        extensionProps={{ identifier: i }}
                        type="identifierDisplay">
                        <>
                            {i.value}
                        </>
                    </HisPlatformExtensionPoint>
                )
            )
        );
    }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderHintValue={renderStringValue}
        />
    );
};

export default connect(
    IdentifierColumn,
    new DependencyAdapter<IIdentifierColumnProps, IIdentifierColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
