import pathToRegexp, { Key } from "path-to-regexp";
import IRouteDefinition from "./Abstractions/IRouteDefinition";
import Route from "./Abstractions/Route";
import { buildQueryString } from "@Toolkit/CommonWeb/QueryStringBuilder";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export default class RouteDefinition<TParameters = any> implements IRouteDefinition {

    private pathCompiler: (parameters: TParameters) => string;
    private pathRegex: RegExp;
    private _keys: Key[];
    private _hasChild = false;

    public get hasChild() { return this._hasChild; }
    public get requiredParameterKeys() { return this._keys.filter(k => !k.optional).map(k => k.name as string); }

    constructor(
        public readonly pathname: string,
        public readonly parent?: RouteDefinition
    ) {
        if (!pathname?.startsWith("/")) {
            throw new Error(`Pathname (${pathname}) should start with a '/'!`);
        }
        this.initialize();
    }

    public setHasChild() {
        this.initialize(false);
    }

    private initialize(exact: boolean = true) {
        this._hasChild = !exact;
        this._keys = [];
        this.pathRegex = pathToRegexp(this.pathname, this._keys, {
            end: exact,
            strict: false,
            sensitive: false
        });
        this.pathCompiler = pathToRegexp.compile(this.pathname);
    }

    public makePathname(parameters?: TParameters): string {
        return this.pathCompiler(parameters);
    }

    public makeRoute(parameters?: TParameters, queryParameters?: object): Route<TParameters> {
        return new Route(
            this,
            parameters,
            this.makePathname(parameters),
            buildQueryString(queryParameters)
        );
    }

    public parsePathname(pathname: string): Route<TParameters> {
        const groups = this.pathRegex.exec(pathname);
        if (!groups) {
            return null;
        }

        const parameters: any = {};
        for (let index = 0; index < groups.length; index++) {
            const part = groups[index + 1];
            const key = this._keys[index];

            if (part && key) {
                parameters[key.name] = part;
            }
        }

        return new Route(
            this,
            parameters,
            pathname
        );
    }

    public isHierarchyMatch(routeDefinition: IRouteDefinition): boolean {
        if (this === routeDefinition) {
            return true;
        }

        if (this.parent) {
            return this.parent.isHierarchyMatch(routeDefinition);
        }

        return false;
    }
}