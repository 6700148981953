import React, { useMemo, useCallback } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import moment from "moment";
import "./Calendar.global.css";
import ICalendarDayStyle from "./ICalendarDayStyle";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Style from "./Calendar.less";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

interface ICalendarProps {
    value: LocalDate;
    onChange: (newValue: LocalDate) => void;
    onSelectedMonthChange?: (newValue: LocalDate) => void;
    onNavigateForward?: (value: LocalDate) => void;
    onNavigateBack?: (value: LocalDate) => void;
    visualMode?: "normal" | "dark";
    calendarDayStyles?: ICalendarDayStyle[];
    calendarCount?: number;
    highlightWeek?: boolean;
    monthOnlyMode?: boolean;
}

@State.observer
class Calendar extends React.Component<ICalendarProps> {

    @State.observable.ref private displayedMoment: moment.Moment;
    private today: moment.Moment = DateTimeService.now();

    constructor(props: ICalendarProps) {
        super(props);
        this.displayedMoment = this.props.value?.toUtcDayStartMoment().clone() ?? DateTimeService.now();
    }

    public componentDidUpdate(prevProps: ICalendarProps) {
        if (!!this.props.value && !this.props.value.equals(prevProps.value)) {
            const newValue = this.props.value.toUtcDayStartMoment().clone();
            if (!newValue.isSame(this.displayedMoment, "day")) {
                State.runInAction(() => this.displayedMoment = newValue);
            }
        }
    }

    @State.action.bound
    private changeHandler(newValue: moment.Moment) {
        if (this.props.onChange) {
            this.props.onChange(LocalDate.createFromMoment(newValue));
        }
        if (this.props.onSelectedMonthChange) {
            this.props.onSelectedMonthChange(LocalDate.createFromMoment(newValue));
        }
    }

    @State.action.bound
    private navigateBackHandler(amount: number, type: string) {
        this.displayedMoment = this.displayedMoment.clone().subtract(amount, type as any);
        if (this.props.onNavigateBack) {
            this.props.onNavigateBack(LocalDate.createFromMoment(this.displayedMoment));
        }
    }

    @State.action.bound
    private navigateForwardHandler(amount: number, type: string) {
        this.displayedMoment = this.displayedMoment.clone().add(amount, type as any);
        if (this.props.onNavigateForward) {
            this.props.onNavigateForward(LocalDate.createFromMoment(this.displayedMoment));
        }
    }

    private multipleCalendarStyle(currentDate: moment.Moment) {
        return combineClasses("rdtDay",
            currentDate.isSame(this.today, "day") && Style.today,
            currentDate.isSame(this.props.value.toUtcDayStartMoment(), "day") && Style.selected,
            !!(this.props.calendarCount && this.props.calendarCount > 1) && Style.multipleCalendar);
    }

    @State.action.bound
    private renderDay(calendarProps: any, currentDate: moment.Moment, selectedDate: moment.Moment) {

        let element: JSX.Element = null;

        if (this.props.calendarCount && this.props.calendarCount !== 1) {
            if (calendarProps.className.includes("rdtNew") || calendarProps.className.includes("rdtOld")) {

                if (currentDate.isSame(selectedDate, "month")) {
                    element = <td key={currentDate.unix()} className={Style.disabledEntry} />;
                } else if (currentDate.isSame(selectedDate.startOf("month"), "week") ||
                    currentDate.isSame(selectedDate.endOf("month"), "week")) {
                    element = <td key={currentDate.unix()} className={Style.disabledEntry} />;
                }

                return element;
            }

            calendarProps.className = this.multipleCalendarStyle(currentDate);
        }

        if (this.props.highlightWeek) {
            calendarProps.className = combineClasses(calendarProps.className, currentDate.isSame(this.props.value.toUtcDayStartMoment(), "week") && Style.highlightWeek);
        }

        if (this.props.calendarDayStyles.length > 0) {
            const style = this.props.calendarDayStyles.find(s => currentDate.isSame(s.day));
            if (style) {
                element = <td key={currentDate.unix()} {...calendarProps} className={combineClasses(calendarProps.className, style.className)}>{currentDate.date()}</td>;
            } else {
                element = <td key={currentDate.unix()} {...calendarProps}>{currentDate.date()}</td>;
            }
        } else {
            element = <td key={currentDate.unix()} {...calendarProps}>{currentDate.date()}</td>;
        }

        return element;
    }

    private calendarClassName(hideMonthSelectors?: boolean) {
        return combineClasses(this.props.visualMode === "dark" ? "rdtPicker--dark-mode" : undefined, this.props.highlightWeek && Style.highlightWeekContainer, hideMonthSelectors && "rdtPicker--hide-month-selector");
    }

    @State.action.bound
    private renderMonth(props: any, month: number, year: number, selectedDate: moment.Moment) {
        props.className = combineClasses(props.className, Style.month);

        const originalOnClick = props.onClick;
        const clickHandler = (e: any) => {
            this.setDisplayedMoment(this.displayedMoment.clone().month(month).year(year));

            if (this.props.onSelectedMonthChange) {
                this.props.onSelectedMonthChange(LocalDate.create(year, month + 1, 1));
            }

            originalOnClick(e);
        };

        props.onClick = clickHandler;

        return <td {...props}> {moment.monthsShort()[month].substr(0, 3)}</td>;
    }

    @State.action.bound
    private setDisplayedMoment(value: moment.Moment) {
        this.displayedMoment = value;
    }

    private renderCalendar(index: number, value?: moment.Moment, hideMonthSelectors?: boolean) {
        return (
            <Datetime
                key={index}
                timeFormat={false}
                input={false}
                value={value}
                onChange={this.changeHandler}
                onNavigateBack={this.navigateBackHandler}
                onNavigateForward={this.navigateForwardHandler}
                className={this.calendarClassName(hideMonthSelectors)}
                renderDay={this.props.calendarDayStyles && this.renderDay}
                renderMonth={this.renderMonth}
                viewMode={this.props.monthOnlyMode ? "months" : "days"}
                closeOnSelect={this.props.monthOnlyMode}
            />
        );
    }

    private renderCalendars() {
        const result = [];
        const value = (this.props.calendarCount && this.props.calendarCount !== 1) ? this.displayedMoment : this.props.value?.toUtcDayStartMoment();
        result.push((
            this.renderCalendar(0, value)
        ));

        if (!this.props.calendarCount || this.props.calendarCount === 1) {
            return result;
        }

        for (let i = 1; i < this.props.calendarCount; i++) {
            result.push(this.renderCalendar(i, this.displayedMoment.clone().add(i, "month"), true));
        }

        return result;
    }

    public render() {
        return (
            <>
                {this.renderCalendars()}
            </>
        );
    }
}

export default Calendar;