import IAuthenticationTokenPayload from "./IAuthenticationTokenPayload";
import Base64Converter from "@Toolkit/CommonWeb/Base64";

export default class AuthenticationTokenParser {

    public readonly tokenPayload: IAuthenticationTokenPayload;

    constructor(
        private _token: string
    ) {
        if (!_token) {
            throw new Error("Token value is invalid.");
        }

        const payloadBase64 = _token.split(".")[1];

        if (payloadBase64) {
            this.tokenPayload = JSON.parse(Base64Converter.toString(payloadBase64));
        }
    }
    
    public get token() {
        return this._token;
    }

    public get userId() {
        return this.tokenPayload["his_id"];
    }

    public get userLoginName() {
        return this.tokenPayload["preferred_username"];
    }
    
    public get userDisplayName() {
        return `${this.tokenPayload["family_name"]} ${this.tokenPayload["given_name"]}`;
    }
    
    public get passwordExpired() {
        return this.tokenPayload.pwe && this.tokenPayload.pwe === "true";
    }
}