import React from "react";
import Log from "@Log";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { mapStackAsync } from "@Toolkit/CommonWeb/StackTraceMapper";

interface IErrorBoundaryProps {
    onRenderError: (technicalError: Error, reload: () => void) => React.ReactNode;
}

interface IErrorBoundaryState {
    hasError: boolean;
    technicalError: Error;
}

const initialState: IErrorBoundaryState = {
    hasError: false,
    technicalError: null,
};

export default class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {

    constructor(props: any) {
        super(props);
        this.state = initialState;
    }

    public static getDerivedStateFromError(error: Error) {
        return { hasError: true, technicalError: error };
    }

    public componentDidCatch(error: Error, errorInfo: any) {
        Log.error(error);
    }

    @State.bound
    private reload() {
        this.setState(initialState);
    }

    public render() {
        if (this.state.hasError) {
            return this.props.onRenderError(this.state.technicalError, this.reload);
        }

        return this.props.children;
    }
}
