import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import DocumentId from "@Primitives/DocumentId.g";

export interface IDocumentPreviewModalParams extends IModalParams {
    documentId: DocumentId;
}

export default class DocumentPreviewModalParams implements IDocumentPreviewModalParams {

    public static type = "DocumentPreviewModalParams";
    public get type() { return DocumentPreviewModalParams.type; }

    constructor(
        public readonly documentId: DocumentId
    ) { }

}