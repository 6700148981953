import React, { useMemo } from "react";
import Styles from "./DateTimePicker.less";
import { Button, Flex, NumberBox } from "@CommonControls";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface ITimePickerProps {
    from?: TimeOfDay;
    to?: TimeOfDay;
    stepsInSeconds?: number[];
    variant?: "buttons" | "hour-minute";

    value: TimeOfDay | null;
    onChange: (newValue: TimeOfDay, isFinal: boolean) => void;

    onBack?: () => void;
    highlightCurrentDate?: boolean;
}

@State.observer
export default class TimeEditor extends React.Component<ITimePickerProps> {

    @State.observable.ref private referenceValueInMinutes = 0;
    @State.observable.ref private resolutionLevel = 0;

    public static defaultProps: Partial<ITimePickerProps> = {
        from: TimeOfDay.startOfDay,
        to: TimeOfDay.endOfDay,
        stepsInSeconds: [60, 10],
        variant: "buttons"
    };

    @State.computed private get buttons() {
        if (this.resolutionLevel > 0 || this.props.onBack) {
            return [
                (
                    <Button
                        key={-1}
                        size="compact"
                        iconName="angleLeft"
                        style={{ width: 42 }}
                        onClick={this.back}
                        automationId="backButton"
                    />
                ),
                ...this.getButtons()
            ];
        }
        return [...this.getButtons()];
    }

    private *getButtons() {

        const currentStepsInMinutes = this.props.stepsInSeconds[this.resolutionLevel];
        const fromInMinutes = this.resolutionLevel === 0 ? this.props.from.valueInMinutes : this.referenceValueInMinutes;
        const toInMinutes = this.resolutionLevel === 0 ? this.props.to.valueInMinutes : (fromInMinutes + this.props.stepsInSeconds[this.resolutionLevel - 1]);

        const numberOfButtons = Math.ceil((toInMinutes - fromInMinutes) / currentStepsInMinutes);
        let currentTimeInMinutes = fromInMinutes;

        const now = TimeOfDay.createFromMoment(DateTimeService.now()).valueInMinutes;

        for (let index = 0; index < numberOfButtons; index++) {

            const clickHandlerFactory = (value: number) => () => {
                this.setValue(value);
            };

            const isCurrentSlot = this.props.highlightCurrentDate && now >= currentTimeInMinutes && now < (currentTimeInMinutes + currentStepsInMinutes);

            yield (
                <Button
                    key={index}
                    text={`${Math.floor(currentTimeInMinutes / 60).toString().padStart(2, "0")}:${(currentTimeInMinutes % 60).toString().padStart(2, "0")}`}
                    size="compact"
                    style={{ width: 42 }}
                    onClick={clickHandlerFactory(currentTimeInMinutes)}
                    visualStyle={isCurrentSlot ? "primary" : "flat"}
                    automationId={`${Math.floor(currentTimeInMinutes / 60).toString().padStart(2, "0")}:${(currentTimeInMinutes % 60).toString().padStart(2, "0")}` + "_button"}
                />
            );

            currentTimeInMinutes += currentStepsInMinutes;
        }
    }

    @State.action
    private setValue(valueInMinutes: number) {
        const timeOfDay = TimeOfDay.createFromTotalMinutes(valueInMinutes);

        if (this.resolutionLevel === this.props.stepsInSeconds.length - 1) {
            this.resolutionLevel = 0;
            this.props.onChange(timeOfDay, true);
        } else {
            this.referenceValueInMinutes = valueInMinutes;
            this.resolutionLevel++;
            this.props.onChange(timeOfDay, false);
        }
    }

    @State.action.bound
    private back() {
        if (this.resolutionLevel > 0) {
            this.referenceValueInMinutes = 0;
            this.resolutionLevel = 0;
        } else if (this.props.onBack) {
            this.props.onBack();
        }
    }

    public render() {
        return (
            <div className={combineClasses(Styles.timePickerContainer, this.props.variant === "hour-minute" && Styles.timePickerContainerCenter)}>
                {this.props.variant === "buttons" ? this.buttons : this.renderHourMinute()}
            </div>
        );
    }

    private renderHourMinute() {
        return (
            <div className={Styles.timeEditorContainer}>
                <Flex>
                    <Flex.Item className={Styles.timeEditorButtonContainer}>
                        <Button
                            automationId="timeEditorHourInc_Button"
                            iconName="chevronUp"
                            onClick={this.increaseHour}
                        />
                    </Flex.Item>
                    <div className={Styles.timeEditorHourMinuteSeparator} />
                    <Flex.Item className={Styles.timeEditorButtonContainer}>
                        <Button
                            automationId="timeEditorMinuteInc_Button"
                            iconName="chevronUp"
                            onClick={this.increaseMinute}
                        />
                    </Flex.Item>
                </Flex>
                <Flex>
                    <Flex.Item className={Styles.timeEditorHourMinute}>
                        <NumberBox
                            automationId="timeEditorHour_NumberBox"
                            nullValue={0}
                            value={(this.props.value?.hours ?? 0)}
                            clearable={false}
                            onChange={this.setHour}
                            textAlign="center"
                            leadingZerosToLength={2}
                        />
                    </Flex.Item>
                    <div className={Styles.timeEditorHourMinuteSeparator}>
                        :
                    </div>
                    <Flex.Item className={Styles.timeEditorHourMinute}>
                        <NumberBox
                            automationId="timeEditorMinute_NumberBox"
                            nullValue={0}
                            value={this.props.value?.minutes ?? 0}
                            clearable={false}
                            onChange={this.setMinute}
                            textAlign="center"
                            leadingZerosToLength={2}
                        />
                    </Flex.Item>
                </Flex>
                <Flex>
                    <Flex.Item className={Styles.timeEditorButtonContainer}>
                        <Button
                            automationId="timeEditorHourDec_Button"
                            iconName="chevronDown"
                            onClick={this.decreaseHour}
                        />
                    </Flex.Item>
                    <div className={Styles.timeEditorHourMinuteSeparator} />
                    <Flex.Item className={Styles.timeEditorButtonContainer}>
                        <Button
                            automationId="timeEditorMinuteDec_Button"
                            iconName="chevronDown"
                            onClick={this.decreaseMinute}
                        />
                    </Flex.Item>
                </Flex>
            </div>
        );
    }

    @State.bound
    private increaseHour() {
        const newValue = isNullOrUndefined(this.props.value) ? 0 : this.props.value.hours + 1;
        this.setHour(newValue === 24 ? 0 : newValue);
    }

    @State.bound
    private decreaseHour() {
        const newValue = isNullOrUndefined(this.props.value) ? 0 : this.props.value.hours - 1;
        this.setHour(newValue === -1 ? 23 : newValue);
    }

    @State.bound
    private increaseMinute() {
        const newValue = isNullOrUndefined(this.props.value) ? 0 : this.props.value.minutes + 1;
        this.setMinute(newValue === 60 ? 0 : newValue);
    }

    @State.bound
    private decreaseMinute() {
        const newValue = isNullOrUndefined(this.props.value) ? 0 : this.props.value.minutes - 1;
        this.setMinute(newValue === -1 ? 59 : newValue);
    }

    @State.bound
    private setHour(hour: number) {
        if (isNullOrUndefined(this.props.value)) {
            this.props.onChange?.(new TimeOfDay(hour, 0), true);

        } else if (0 <= hour && hour <= 23) {
            this.props.onChange?.(this.props.value.withHours(hour), true);
        }
    }

    @State.bound
    private setMinute(minute: number) {
        if (isNullOrUndefined(this.props.value)) {
            this.props.onChange?.(new TimeOfDay(0, minute), true);

        } else if (0 <= minute && minute <= 59) {
            this.props.onChange?.(this.props.value.withMinutes(minute), true);
        }
    }
}