import AuthorizationReferenceDataStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/AuthorizationReferenceDataStore";
import PermissionAssignmentAccessRule from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/PermissionAssignmentAccessRule";
import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import UserGroupId from "@Primitives/UserGroupId.g";
import UserId from "@Primitives/UserId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import IPermissionDefinition from "@PluginInterface/OperationAccessControl/IPermissionDefinition";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";
import UniquePermissionConfigurationPanelView from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/RoleManagement/PermissionConfigurationPanel/UniquePermissionConfigurationPanel/UniquePermissionConfigurationPanelView";
import UniquePermissionConfigurationStore from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/RoleManagement/PermissionConfigurationPanel/UniquePermissionConfigurationPanel/UniquePermissionConfigurationStore";
import PermissionId from "@Primitives/PermissionId.g";
import PermissionScope from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/PermissionScope";
import IPermissionScope from "@PluginInterface/OperationAccessControl/IPermissionScope";
import _ from "@HisPlatform/Common/Lodash";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";
import { defaultPermissionScopeTypeValues } from "@Primitives/DefaultPermissionScopeTypeValues";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
interface IUniquePermissionConfigurationPanelDependencies {
    permissionDefinitionRegistry: IPermissionDefinitionRegistry;
    authorizationReferenceDataStore: AuthorizationReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;

}
interface IUniquePermissionConfigurationPanelProps {
    _dependencies?: IUniquePermissionConfigurationPanelDependencies;
    subjectId: UserId | UserGroupId;
    prohibitionAccessRule?: PermissionAssignmentAccessRule;
    assignmentAccessRule?: PermissionAssignmentAccessRule;
    onAssignmentRuleChange: (newValue: PermissionAssignmentAccessRule) => void;
    onProhibitionRuleChange: (newValue: PermissionAssignmentAccessRule) => void;
}

@State.observer
class UniquePermissionConfigurationPanel extends React.Component<IUniquePermissionConfigurationPanelProps> {
    @State.observable.ref private store: UniquePermissionConfigurationStore = new UniquePermissionConfigurationStore();

    private get authorizationReferenceDataStore() {
        return this.props._dependencies.authorizationReferenceDataStore;
    }

    private get organizationReferenceDataStore() {
        return this.props._dependencies.organizationReferenceDataStore;
    }

    @State.computed
    private get permissionDefinitionRegistry() {
        return this.props._dependencies.permissionDefinitionRegistry;
    }
    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IUniquePermissionConfigurationPanelProps) {
        if (!ValueWrapper.equals(this.props.subjectId, prevProps.subjectId) ||
            this.props.prohibitionAccessRule !== prevProps.prohibitionAccessRule ||
            this.props.assignmentAccessRule !== prevProps.assignmentAccessRule) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }
    private async loadAsync() {
        await this.authorizationReferenceDataStore.roleMap.ensureAllLoadedAsync();
        await this.organizationReferenceDataStore.pointOfCareMap.ensureAllLoadedAsync();
        if (this.props.subjectId) {
            this.initializeStore();
            this.resolveAliasedPermissions();
        }
    }

    @State.computed
    private get grantedPermissions() {
        if (this.props.assignmentAccessRule && !this.props.assignmentAccessRule.isDeleted) {
            return this.props.assignmentAccessRule?.permissionStore.definitions;
        }

        return [];
    }

    private get prohibitedPermissions() {
        if (this.props.prohibitionAccessRule && !this.props.prohibitionAccessRule.isDeleted) {
            return this.props.prohibitionAccessRule?.permissionStore.definitions;
        }

        return [];
    }

    @State.bound
    private resolveAliasedPermissions() {
        this.resolveAccessRuleAliasedPermissions(this.props.prohibitionAccessRule);
        this.resolveAccessRuleAliasedPermissions(this.props.assignmentAccessRule);
    }

    @State.bound
    private resolveAccessRuleAliasedPermissions(accessRule: PermissionAssignmentAccessRule) {
        if (accessRule && !accessRule.isDeleted) {
            if (accessRule.permissionStore.definitions.some(a => !!a.alias)) {
                return;
            }

            const resolvedAliasedPermissions = this.permissionDefinitionRegistry.resolveMergedPermissionDefinitions(accessRule.permissionStore.definitions);
            accessRule.setPermissions(resolvedAliasedPermissions);

            if (!accessRule.isNew) {
                accessRule.takeSnapshot();
            }
        }
    }

    @State.computed
    private get permissions(): IPermissionDefinition[] {
        const permissions = [];
        if (this.props.assignmentAccessRule && !this.props.assignmentAccessRule.isDeleted) {
            permissions.push(...this.grantedPermissions);
        }

        if (this.props.prohibitionAccessRule && !this.props.prohibitionAccessRule.isDeleted) {
            permissions.push(...this.prohibitedPermissions);
        }

        return permissions.sort((a, b) => {
            return a.aliasedId.value > b.aliasedId.value ? -1 : 1;
        });
    }

    @State.action.bound
    private initializeStore() {
        this.store = new UniquePermissionConfigurationStore();
    }

    @State.bound
    private addPermission() {
        if (!this.store.permission?.ids?.length) {
            return;
        }

        let accessRule: PermissionAssignmentAccessRule;
        if (this.store.isPermissionProhibition && this.props.prohibitionAccessRule) {
            accessRule = this.props.prohibitionAccessRule;
        } else if (!this.store.isPermissionProhibition && this.props.assignmentAccessRule) {
            accessRule = this.props.assignmentAccessRule;
        } else {
            accessRule = new PermissionAssignmentAccessRule(true);
            accessRule.takeSnapshot();
            accessRule.isProhibition = this.store.isPermissionProhibition;
            accessRule.subject = this.props.subjectId;
        }

        // if the scope is needed but no value is selected default to all
        if (this.permissionDefinitionHasScopeType(PermissionScopeType.pointOfCare) && !this.store.pointOfCareTypeScopeValues.length) {
            this.store.setPointOfCareScope(PermissionScope.createAll(PermissionScopeType.pointOfCare));
        }

        accessRule.permissionStore.definitions.push(this.store.permission);
        accessRule.isDeleted = false;

        if (this.store.isPermissionProhibition) {
            this.props.onProhibitionRuleChange(accessRule);
        } else {
            this.props.onAssignmentRuleChange(accessRule);
        }
        this.initializeStore();
    }

    @State.bound
    private removePermission(row: IPermissionDefinition) {
        const accessRule = this.grantedPermissions
            .find(p => ValueWrapper.equals(p.aliasedId, row.aliasedId)) ? this.props.assignmentAccessRule : this.props.prohibitionAccessRule;
        const permissions = accessRule.permissionStore.definitions;
        accessRule.setPermissions(permissions.filter(p => !(ValueWrapper.equals(p.aliasedId, row.aliasedId))));

        if (!accessRule.permissionStore.definitions.length) {
            accessRule.isDeleted = true;
        }
    }

    @State.bound
    private getScopeTypeForPermission(permission: IPermissionDefinition, scopeType: string) {
        return permission.scopes.find(s => s.type === scopeType);
    }

    @State.bound
    public getPointOfCareScopeValue(permission: IPermissionDefinition): IPermissionScope {
        const pointOfCareScopeForPermission = this.getScopeTypeForPermission(permission, PermissionScopeType.pointOfCare);
        return pointOfCareScopeForPermission;
    }

    @State.bound
    private setPointOfCareTypeRowValue(value: IPermissionScope, row: IPermissionDefinition) {
        const pointOfCareScope = row.scopes.find(s => s.type === PermissionScopeType.pointOfCare);

        if (isNullOrUndefined(value)) {
            pointOfCareScope.setPositive([]);
        } else {
            pointOfCareScope.setFrom(value!);
        }
    }

    @State.bound
    private getPermissionRowProhibitionValue(row: IPermissionDefinition) {
        return this.prohibitedPermissions
            .some(p => ValueWrapper.equals(p.aliasedId, row.aliasedId)) ? true : false;
    }

    @State.action.bound
    private permissionAssignmentTypeChanged(newValue: boolean, permission: IPermissionDefinition) {
        this.store.setPermission(permission);
        this.store.setIsPermissionProhibition(newValue);
        this.removePermission(permission);
        this.addPermission();
    }

    @State.bound
    private permissionDefinitionHasScopeType(scopeType: string) {
        const permissionDefinition = this.permissionDefinitionRegistry.getPermissionDefinition(this.store.permission?.ids?.[0].value);
        return permissionDefinition?.scopes.some(s => s.type === scopeType) ?? true;
    }

    private permissionDefinitionHasAnyScopeType() {
        const permissionDefinition = this.permissionDefinitionRegistry.getPermissionDefinition(this.store.permission?.ids?.[0].value);
        return permissionDefinition?.scopes?.length > 0;
    }

    @State.bound
    private setPermissionId(id: PermissionId) {
        // We need to lift the predefined scopes in case of aliased permissions
        const permissionDefinition = this.permissionDefinitionRegistry.getAll().find(p => ValueWrapper.equals(p.aliasedId, id)).clone();
        // But not the predefined placeholders TODO: move this to registry logic
        permissionDefinition.scopes?.forEach(s => {
            if (s.mode === "positive") {
                s.setPositive(s.includedValues.filter(v => v !== defaultPermissionScopeTypeValues.get(s.type)));
            } else {
                s.setNegative(s.excludedValues.filter(v => v !== defaultPermissionScopeTypeValues.get(s.type)));
            }
        });

        this.store.setPermission(permissionDefinition);
    }

    @State.bound
    private getPointOfCareNames(scope: IPermissionScope) {
        if (!scope || (arrayIsNullOrEmpty(scope.includedValues) && !scope.isAll)) {
            return "";
        }

        if (scope.isAll) {
            return StaticAuthorizationResources.PermissionConfiguration.PointOfCareScopeSelector.AllValue;
        }

        const pocNames =
            this.organizationReferenceDataStore.pointOfCareMap.items
                .filter(item => scope.includedValues.some(id => item.id.value === id)).map(d => d.name);
        return pocNames.join(", ");
    }

    public render() {
        return (
            <UniquePermissionConfigurationPanelView
                permissions={this.permissions}
                addPermission={this.addPermission}
                removePermission={this.removePermission}
                getPermissionRowProhibitionValue={this.getPermissionRowProhibitionValue}
                getPointOfCareScopeValue={this.getPointOfCareScopeValue}
                onPointOfCareTypeRowValueChange={this.setPointOfCareTypeRowValue}
                onPermissionAssignmentTypeChanged={this.permissionAssignmentTypeChanged}
                permissionDefinitionHasScopeType={this.permissionDefinitionHasScopeType}
                store={this.store}
                setPermissionId={this.setPermissionId}
                getPointOfCareNames={this.getPointOfCareNames}
            />
        );
    }
}

export default connect(
    UniquePermissionConfigurationPanel,
    new DependencyAdapter<IUniquePermissionConfigurationPanelProps, IUniquePermissionConfigurationPanelDependencies>(c => ({
        permissionDefinitionRegistry: c.resolve("IPermissionDefinitionRegistry"),
        authorizationReferenceDataStore: c.resolve("AuthorizationReferenceDataStore"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore")
    }))
);