import * as CareProxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";

export function getHistoryItemDetailDto(source: MedicalConditionHistoryItemDetail) {
    return new CareProxy.MedicalConditionHistoryItemDetailDto({
        asserter: source.asserter,
        asserterType: source.asserterType,
        notes: source.notes,
        verifier: source.verifier
    });
}