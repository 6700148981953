import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import * as HisUi from "@HisPlatformControls";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import FinancedServiceApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/Finance/FinancedServiceApiAdapter";
import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import { IUniversalCodeSelectorProps } from "@HisPlatformControls/UniversalCodeSelector";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import { FinancedServiceCodeSelectorItem } from "./FinancedServiceCodeSelectorItem";

interface IFinancedServiceCodeSelectorDependencies {
    financedServiceApiAdapter: FinancedServiceApiAdapter;
    financedServiceReferenceData: FinanceReferenceDataStore;
}

interface IFinancedServiceCodeSelectorProps extends ICodeSelectorCommonProps<FinancedServiceCodeSelectorItem> {
    _dependencies?: IFinancedServiceCodeSelectorDependencies;
}

@State.observer
class FinancedServiceCodeSelector extends React.Component<IFinancedServiceCodeSelectorProps> {

    private get dependencies() {
        return this.props._dependencies;
    }

    @State.bound
    private async getDisplayValueAsync(value: FinancedServiceCodeSelectorItem) {
        if (value.name)
            return <>{value.name}</>;

        if (!value.id)
            return <></>;

        const item = await this.dependencies.financedServiceReferenceData.financedServiceMap.getOrLoadAsync({
            id: value.id,
            validOn: this.props.validOn ?? LocalDate.today()
        }, true);

        return item ? <><b>{item.code?.value}</b>{` ${item.name}`}</> : <></>;
    }

    @State.bound
    private async getTextValueAsync(value: FinancedServiceCodeSelectorItem) {
        if (value.name)
            return value.name;

        if (!value.id)
            return "";

        const item = await this.dependencies.financedServiceReferenceData.financedServiceMap.getOrLoadAsync({
            id: value.id,
            validOn: this.props.validOn ?? LocalDate.today()
        }, true);

        return item ? `${item.code?.value} ${item.name}` : "";
    }

    @State.bound
    private async quickSearchAsync(quickSearchText: string) {
        const result = await this.dependencies.financedServiceApiAdapter.searchFinancedServicesByCodeAsync(
            quickSearchText,
            10,
            this.props.validOn ?? LocalDate.today()
        );

        if (result.operationWasSuccessful) {

            await this.dependencies.financedServiceReferenceData.financedServiceMap.ensureLoadedAsync(result.value.map(financedService => ({
                id: financedService.id,
                validOn: this.props.validOn ?? LocalDate.today()
            } as IEntityVersionSelector<FinancedServiceId>)));

            return result.value.map(i => new FinancedServiceCodeSelectorItem({
                id: i.id,
                name: i.name,
                code: i.code.value
            }));
        }

        return null;
    }

    @State.bound
    private getEntityDisplayValue(value: FinancedServiceCodeSelectorItem) {
        if (value.name)
            return value.name;

        if (!value.id)
            return "";

        const item = this.dependencies.financedServiceReferenceData.financedServiceMap.get({
            id: value.id,
            validOn: this.props.validOn ?? LocalDate.today()
        }, true);

        return item ? `${item.code?.value} ${item.name}` : "";
    }

    @State.bound
    private async complexSearchLoadAsync(
        filterText: string,
        paging: IPagingState,
        ordering: IOrderingState): Promise<IPagedItems<FinancedServiceCodeSelectorItem>> {

        const today = this.props.validOn ?? LocalDate.today();

        const results = await this.dependencies.financedServiceApiAdapter.searchFinancedServices(
            filterText,
            today,
            ordering,
            paging
        );

        return {
            items: results.value.values.map(i => new FinancedServiceCodeSelectorItem({
                id: i.id,
                name: i.name,
                code: i.code.value
            })),
            totalCount: results.value.totalCount
        } as IPagedItems<FinancedServiceCodeSelectorItem>;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: FinancedServiceCodeSelectorItem) {
        this.props.onChange(item, this.props.validOn);
    }

    @State.bound
    private onComplexSearchMultiSelect(items: FinancedServiceCodeSelectorItem[]) {
        this.props.onChange(items, this.props.validOn);
    }

    @State.bound
    private addNewItem(text: string): FinancedServiceCodeSelectorItem {
        return new FinancedServiceCodeSelectorItem({ name: text });
    }

    public render() {
        const props: IUniversalCodeSelectorProps<FinancedServiceCodeSelectorItem, FinancedServiceCodeSelectorItem> = {
            ...this.props,
            isCreatable: true,
            addNewItem: this.addNewItem,
            hideAllgroup: true,
            getDisplayValueAsync: this.getDisplayValueAsync,
            getTextValueAsync: this.getTextValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            getEntityDisplayValue: this.getEntityDisplayValue,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticFinanceResources.FinancedServiceCodeSelector.Title,
            codeGetter: "code",
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect
        };
        return (
            <HisUi.UniversalCodeSelector
                {...props}
            />
        );
    }
}

export default connect(
    FinancedServiceCodeSelector,
    new DependencyAdapter<IFinancedServiceCodeSelectorProps, IFinancedServiceCodeSelectorDependencies>((container) => {
        return {
            financedServiceApiAdapter: container.resolve("FinancedServiceApiAdapter"),
            financedServiceReferenceData: container.resolve("FinanceReferenceDataStore"),
        };
    })
);
