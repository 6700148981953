import React from "react";
import { IEmptyStateSettings } from "@CommonControls/DataGrid/IDataGridProps";
import NoItemsMessage from "@CommonControls/NoItemsMessage";
import Styles from "./DataGrid.less";

interface IEmptyStateBodyProps extends IEmptyStateSettings {
    colSpan: number;
}


const EmptyStateBody: React.FC<IEmptyStateBodyProps> = props => (
    <tr className={Styles.nonClickable}>
        <td colSpan={props.colSpan}>
            <NoItemsMessage showEmptyIcon={props.showEmptyIcon} message={props.title}>
                {props.message && <p>{props.message}</p>}
                {props.content}
            </NoItemsMessage>
        </td>
    </tr>
);

export default EmptyStateBody;