import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import InsurerOrganizationsListPanel from "@HisPlatform/BoundedContexts/Finance/Components/Panels/InsurerOrganizationsListPanel/InsurerOrganizationsListPanel";
import * as Ui from "@CommonControls";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import { HisErrorBoundary } from "@HisPlatformControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";


interface IInsurerOrganizationsConfigurationPageDependencies {

}

interface IInsurerOrganizationsConfigurationPageProps extends IRoutingFrameContentProps {
    _dependencies?: IInsurerOrganizationsConfigurationPageDependencies;
}

@State.observer
class InsurerOrganizationsConfigurationPage extends React.Component<IRoutingFrameContentProps> {

    public render() {

        return (
            <Ui.PageBoxContainer>
                <Ui.PageBox title={StaticFinanceResources.InsurerOganizations.Title}>
                    <Ui.PageBox.Body>
                        <HisErrorBoundary>
                            <LoadingBoundary>
                                <InsurerOrganizationsListPanel />
                            </LoadingBoundary>
                        </HisErrorBoundary>
                    </Ui.PageBox.Body>
                </Ui.PageBox>
            </Ui.PageBoxContainer>
        );
    }
}

export default InsurerOrganizationsConfigurationPage;