import CareActivityId from "@Primitives/CareActivityId.g";
import { ScreenContextDataBase } from "./ScreenContextData";

export default class CareScreenContextData extends ScreenContextDataBase {
    constructor(public readonly careActivityId: CareActivityId, public readonly isReducedDataSet: boolean = false) {
        super();
    }

    public getHashValue(): string {
        return `CareContext_${this.careActivityId.value}${this.isReducedDataSet}`;
    }
}
