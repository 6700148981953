import React from "react";
import FormLayoutCustomBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutCustomBlockStore";
import { useFormPanelStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanelStoreProvider";
import State from "@Toolkit/ReactClient/Common/StateManaging";

function FormLayoutCustomBlock(props: { block: FormLayoutCustomBlockStore }) {

    const store = useFormPanelStore();

    const component = store.formEditorRegistry.tryGetCustomBlockComponent(props.block.customBlockIdentifier);
    const settings = props.block.settings;
    
    if (!component) {
        return <span>Missing custom block: {props.block.customBlockIdentifier}</span>;
    }

    return React.createElement(component.componentType, {
        ...component.props,
        form: store.props.form,
        formDefinition: store.definition.get(),
        settings: settings
    });
}

export default State.observer(FormLayoutCustomBlock);