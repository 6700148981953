import React from "react";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import Urgency from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/Urgency.g";

function UrgencySelectBox(props: IUniversalEnumSelectorBaseProps): React.ReactElement {
    return (
        <UniversalEnumSelector
            {...props}
            enumOrigin="server"
            enumName={"Urgency"} 
            enumType={Urgency}
        />
    );
}

(UrgencySelectBox as React.FC<IUniversalEnumSelectorBaseProps>).defaultProps = {
    displayMode: "groupedRadioButtons"
};

export default UrgencySelectBox;