import React, { useMemo } from "react";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import Styles from "./EnumBadge.less";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import Badge, { IBadgeProps } from "@CommonControls/Badge";

interface IEnumBadgeDependencies {
    localizationService: ILocalizationService;
}

interface IEnumBadgeProps {
    _dependencies?: IEnumBadgeDependencies;
    value: any;
    enumName: string;
    enumType: any;
    isIndex?: boolean;
    shape?: "rounded-rect" | "pill" | "circle";
    className?: string;
    visualStyle?: "warning" | "danger" | "positive" | "secondary";
    bordered?: boolean;
    rectangle?: boolean;
    additionalTooltipNote?: string;
}


const EnumBadge: React.FC<IEnumBadgeProps> = (props) => {
    const labelProps = useMemo(() => {

        if (isNullOrUndefined(props.value)) {
            return null;
        }

        const localized = props._dependencies.localizationService.localizeEnum(props.enumType[props.value], props.enumName);

        return {
            fluid: true,
            shape: props.shape ? props.shape : "rounded-rect",
            upperCase: false,
            text: localized.Shorthand,
            visualStyle: props.visualStyle,
            className: combineClasses(props.rectangle ? Styles.mainRectangle : Styles.main, props.className, props.isIndex && Styles.upperIndex, props.bordered && Styles.border),
            tooltipPosition: "bottom",
            tooltipContent: props.additionalTooltipNote ? `${localized.Name} - ${props.additionalTooltipNote}` : localized.Name,
            size: "small"
        } as IBadgeProps;
    }, [props.value]);

    return labelProps === null ? null : <Badge {...labelProps} />;
};

export default connect(
    EnumBadge,
    new DependencyAdapter<IEnumBadgeProps, IEnumBadgeDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService")
    }))
);
