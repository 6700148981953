import * as React from "react";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import Styles from "./CheckBox.less";
import CheckBoxStore from "@CommonControls/CheckBox/CheckBoxStore";
import { getStandardHtmlProps, ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import { Tooltip } from "@Toolkit/ReactClient/Components/Tooltip";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Button, { IButtonProps } from "@CommonControls/Button";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export interface ICheckBoxBaseProps extends ICommonControlProps {
    label?: string;
    disabled?: boolean;
    value?: boolean;
    onChange?: (newValue: boolean) => void;
    canBeNull?: boolean;
    align?: "left" | "right" | "center";
    automationId: string;
    containerStyle?: "normal" | "grouped";
    verticalAlign?: "normal" | "noPadding" | "inlineInput";
    stopClickPropagation?: boolean;
    displayMode?: "check" | "switch" | "toggle-button";
    labelPosition?: "left" | "right";
    visualMode?: "dark" | "light";
    isReadOnly?: boolean;
    toggleButtonProps?: IButtonProps;
    permissionCheckOperationNames?: string;
    permissionDeniedStyle?: "disabled" | "invisible";
    _permissionDenied?: boolean;
}

interface ICheckBoxCoreProps extends ICheckBoxBaseProps {
    validationProblemSeverity?: string;
    validationResults?: React.ReactNode;
    renderValidationEventHandler?: () => JSX.Element;
}

@State.observer
export default class CheckBoxCore extends React.Component<ICheckBoxCoreProps> {

    private store = new CheckBoxStore();

    @State.computed
    private get tooltipProps() {
        return {
            tooltipContent: this.props.tooltipContent,
            tooltipPosition: this.props.tooltipPosition,
            tooltipTextAlign: this.props.tooltipTextAlign
        };
    }

    public static defaultProps: Partial<ICheckBoxCoreProps> = {
        canBeNull: false,
        displayMode: "check",
        containerStyle: "normal",
        verticalAlign: "normal",
        align: "left",
        labelPosition: "right",
        visualMode: "light",
        tooltipPosition: "top"
    };

    private htmlElement: HTMLInputElement;

    private calculateNextValue() {
        if (this.props.value === true) {
            return null;
        } else if (this.props.value === false) {
            return true;
        } else { // == null
            return false;
        }
    }

    @State.bound
    private onMouseEnterHandler(e: React.MouseEvent<HTMLDivElement>) {
        this.store.setHover(true);
    }

    @State.bound
    private onMouseLeaveHandler(e: React.MouseEvent<HTMLDivElement>) {
        this.store.setHover(false);
    }

    @State.bound
    private clickHandler(e: React.MouseEvent<HTMLInputElement>) {
        if (this.props.stopClickPropagation) {
            e.stopPropagation();
        }
    }

    @State.bound
    private labelClickHandler(e: React.MouseEvent<HTMLLabelElement>) {
        if (this.props.stopClickPropagation) {
            e.stopPropagation();
        }
        e.preventDefault();
        this.changeHandler();
    }

    @State.bound
    private changeHandler() {
        if (this.props.canBeNull) {
            this.props.onChange(this.calculateNextValue());
        } else {
            this.props.onChange(!this.props.value);
        }
    }

    private getDivContainerClassName() {
        const classes = this.props.containerStyle === "grouped" ? new CompositeClassName(Styles.mainContainerGrouped) : new CompositeClassName(Styles.mainContainer);

        if (this.props.validationProblemSeverity !== null && this.props.validationProblemSeverity !== undefined) {
            classes.addIf(this.props.validationProblemSeverity === "warning", Styles.warning);
            classes.addIf(this.props.validationProblemSeverity === "error", Styles.error);
        }

        if (this.props.align) {
            classes.addIf(this.props.align === "center", Styles.center);
            classes.addIf(this.props.align === "left", Styles.left);
            classes.addIf(this.props.align === "right", Styles.right);
        }

        if (this.props.verticalAlign !== null && this.props.verticalAlign !== undefined) {
            classes.addIf(this.props.verticalAlign === "normal", Styles.normalTopPadding);
            classes.addIf(this.props.verticalAlign === "noPadding", Styles.noTopPadding);
            classes.addIf(this.props.verticalAlign === "inlineInput", Styles.inlineInputTopPadding);
        }

        return classes.classNames;
    }
    private getCheckboxContainerClassName() {
        const classes = new CompositeClassName(Styles.container);
        if (this.props.visualMode) {
            classes.addIf(this.props.visualMode === "dark", Styles.dark);
            classes.addIf(this.props.visualMode === "light", Styles.light);
        }
        return classes.classNames;
    }

    private renderWrapper(children: React.ReactNode) {
        if (this.props.displayMode === "switch") {
            return (
                <label className={Styles.switchCheckbox}>
                    {children}
                </label>
            );
        } else {
            return (
                <div className={this.getCheckboxContainerClassName()}>
                    {children}
                </div>
            );
        }
    }

    private setIndeterminate() {
        if (this.props.displayMode !== "toggle-button") {
            this.htmlElement.indeterminate = (this.props.canBeNull && this.props.value === null);
        }
    }

    public componentDidUpdate(prevProps: ICheckBoxBaseProps) {
        this.setIndeterminate();
    }

    public componentDidMount() {
        this.setIndeterminate();
    }

    private renderLabel() {
        return !!this.props.label && (
            <label htmlFor={this.props.id ?? this.props.automationId} onClick={this.props.isReadOnly || this.props.disabled ? undefined : this.labelClickHandler} className={this.props.displayMode === "switch" ? Styles.switchLabel : Styles.label}>
                {this.props.label}
            </label>
        );
    }

    public render() {
        const htmlProps = getStandardHtmlProps(this.props, this.getDivContainerClassName());
        return (
            <div onMouseEnter={this.onMouseEnterHandler}
                onMouseLeave={this.onMouseLeaveHandler}
                {...htmlProps}
            >
                {this.renderContent()}
                {!!this.props.validationResults && this.props.validationResults}
            </div>
        );
    }

    private renderContent() {
        if (this.props.displayMode === "toggle-button") {
            return this.renderButton();
        } else {
            return this.renderCheckBox();
        }
    }

    private renderButton() {
        return (
            <Button
                size="compact"
                active={!!this.props.value}
                disabled={this.props.isReadOnly || this.props.disabled || (this.props._permissionDenied && this.props.permissionDeniedStyle === "disabled")}
                text={this.props.label}
                onClick={this.changeHandler}
                automationId={this.props.automationId || undefined}
                {...this.tooltipProps}
                {...this.props.toggleButtonProps} />
        );
    }

    private renderCheckBox() {
        return (
            <>
                <Tooltip
                    content={this.props.tooltipContent}
                    contentAlignment={this.props.tooltipTextAlign}
                    placement={this.props.tooltipPosition}>
                    <div style={{ display: "flex", float: "left" }}>
                        {this.props.labelPosition === "left" && this.renderLabel()}
                            {this.renderWrapper(
                                <>
                                    <input
                                        type="checkbox"
                                        id={this.props.id ?? this.props.automationId}
                                        ref={(ref) => this.htmlElement = ref}
                                        onClick={this.clickHandler}
                                        onChange={this.changeHandler}
                                        disabled={this.props.isReadOnly || this.props.disabled}
                                        checked={this.props.canBeNull && isNullOrUndefined(this.props.value) ? false : this.props.value}
                                        value={isNullOrUndefined(this.props.value) ? "null" : this.props.value.toString()}
                                        data-automation-id={this.props.automationId || undefined}
                                    />
                                    {this.props.displayMode === "switch" ? <span className={Styles.slider}>{this.props.value}</span> : <span className={Styles.checkmark} />}
                                </>
                            )}
                        {this.props.labelPosition === "right" && this.renderLabel()}
                    </div>
                </Tooltip>
            </>
        );
    }
}
