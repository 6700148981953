import React from "react";
import { IUncontrolledSearchBoxProps } from "@CommonControls/SearchBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import { CSSTransition } from "react-transition-group";
import { TextBoxCore } from "@CommonControls/TextBox";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Styles from "./SearchBox.less";

@State.observer
export default class UncontrolledSearchBox extends React.Component<IUncontrolledSearchBoxProps> {

    private ref: React.RefObject<TextBoxCore> = React.createRef();
    @State.observable private value: string = null;
    @State.observable private isOpen: boolean = false;

    public static defaultProps: Partial<IUncontrolledSearchBoxProps> = {
        focusOnRender: true,
        visualMode: "dark",
        iconName: "search",
        iconVisualStyle: "white",
        size: "compact",
    };

    @State.bound
    private focusInput() {
        if (!isNullOrUndefined(this.ref.current)) {
            this.ref.current.focus();
        }
    }

    @State.action.bound
    private onChange(value: string) {
        this.value = value;
    }

    @State.action.bound
    private search() {
        this.props.onSearch(this.value);
        this.value = null;
    }

    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "row" }} className={Styles.searchboxContainer}>
                <Ui.Icon onClick={this.search} iconName={this.props.iconName} visualStyle={this.props.iconVisualStyle} automationId={this.props.automationId + "Icon"}/>
                <div style={{ flex: "1" }}>
                    <Ui.TextBox
                        ref={this.ref}
                        placeholder={this.props.placeholder}
                        value={this.value}
                        onChange={this.onChange}
                        onEnterKeyDown={this.search}
                        visualMode={this.props.visualMode}
                        size={this.props.size}
                        automationId={this.props.automationId + "TextBox"}
                        style={this.props.style}
                        stopClickPropagation={this.props.stopClickPropagation}
                        required={this.props.isRequired}
                    />
                </div>
            </div>
        );
    }
}