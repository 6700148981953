import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import EhiErrorNotification from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Notifications/EhiErrorNotification";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import NotificationHandler from "@Toolkit/CommonWeb/Notifications/NotificationHandler";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";

interface IEhiErrorNotificationHandlerDependencies {
    localizationService: ILocalizationService;
    notificationService: INotificationService;
    userContext: UserContext;
}

interface IEhiErrorNotificationHandlerProps {
    _dependencies?: IEhiErrorNotificationHandlerDependencies;
}

class EhiErrorNotificationHandler extends React.Component<IEhiErrorNotificationHandlerProps> {

    private get localizationService() { return this.props._dependencies!.localizationService; }
    private get notificationService() { return this.props._dependencies!.notificationService; }
    private get userContext() { return this.props._dependencies.userContext; }

    @State.bound
    private onNotificationReceived(message: any) {
        const notification = EhiErrorNotification.fromJS(message);

        if (ValueWrapper.equals(this.userContext.id, notification.userId)) {
            const errorMessage = this.localizationService.localizeReferenceData(notification.errorMessageResourceId);
            const formattedMessage = formatStringWithObjectParams(errorMessage, notification.parameters);
    
            this.notificationService.warning(formattedMessage);
        }
    }

    public render() {
        return (
            <NotificationHandler groupId="EhiErrorNotificationGroup" onReceiveMessage={this.onNotificationReceived} />
        );
    }
}

export default connect(
    EhiErrorNotificationHandler,
    new DependencyAdapter(c => ({
        localizationService: c.resolve("ILocalizationService"),
        notificationService: c.resolve("INotificationService"),
        userContext: c.resolve("UserContext")
    }))
);
