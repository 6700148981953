import PatientId from "@Primitives/PatientId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import DocumentScope from "@HisPlatform/BoundedContexts/DocumentManagement/Api/ReferenceData/Enum/DocumentScope.g";

export default class ScopeIdentifier {
    constructor(
        public readonly documentScope: DocumentScope,
        public readonly identifier?: number,
    ) {}

    public static createPatientScopeIdentifier(id: PatientId) {
        return new ScopeIdentifier(DocumentScope.Patient, parseInt(id.value, 10));
    }

    public static createCareActivityScopeIdentifier(id: CareActivityId) {
        return new ScopeIdentifier(DocumentScope.CareActivity, parseInt(id.value, 10));
    }

    public static createOrganizationScopeIdentifier(id: OrganizationUnitId) {
        return new ScopeIdentifier(DocumentScope.Organization, parseInt(id.value, 10));
    }

    public static createServiceRequestScopeIdentifier(id: ServiceRequestId) {
        return new ScopeIdentifier(DocumentScope.ServiceRequest, parseInt(id.value, 10));
    }
}