import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalConditionHistoryItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemBase";
import AllergyIntoleranceReactionId from "@Primitives/AllergyIntoleranceReactionId.g";
import AllergyIntoleranceClinicalStatus from "@Primitives/AllergyIntoleranceClinicalStatus";
import AllergyIntoleranceCriticality from "@Primitives/AllergyIntoleranceCriticality";
import AllergyIntoleranceReactionAssignment from "./AllergyIntoleranceReactionAssignment";

export default class PatientAllergyIntoleranceHistoryItem extends MedicalConditionHistoryItemBase {
    @State.observable.ref public lastOccurrence: LocalDate = null;
    public reactions: IObservableArray<AllergyIntoleranceReactionAssignment> = State.observable([]);
    @State.observable.ref public clinicalStatus: AllergyIntoleranceClinicalStatus = AllergyIntoleranceClinicalStatus.Active;
    @State.observable.ref public criticality: AllergyIntoleranceCriticality = null;

    @State.action.bound
    public setLastOccurrence(newValue: LocalDate) {
        this.lastOccurrence = newValue;
    }

    @State.action.bound
    public setAllergyIntoleranceReaction(newValue: AllergyIntoleranceReactionId, index: number) {
        const versionSelector = { id: newValue, validOn: LocalDate.createFromMoment(this.createdAt) };

        if (this.reactions.length > index) {
            const reaction = new AllergyIntoleranceReactionAssignment(versionSelector, "");
            this.reactions[index].allergyIntoleranceReactionId = versionSelector;
        } else {
            this.reactions[index].allergyIntoleranceReactionId = versionSelector;
        }
    }

    @State.action.bound
    public setAllergyIntoleranceReactionDescription(newValue: string, index: number) {
        this.reactions[index].description = newValue;
    }

    @State.action.bound
    public addNewAllergyIntoleranceReaction() {
        return Promise.resolve(new AllergyIntoleranceReactionAssignment({ id: null as AllergyIntoleranceReactionId, validOn: LocalDate.createFromMoment(this.createdAt) }, ""));
    }

    @State.action.bound
    public deletePatientAllergyIntoleranceReactionAsync() {
        if (this.reactions.length < 2) { // At least one item should remain in the list
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }

    @State.action.bound
    public setClinicalStatus(newValue: AllergyIntoleranceClinicalStatus) {
        this.clinicalStatus = newValue;
    }

    @State.action.bound
    public setCriticality(newValue: AllergyIntoleranceCriticality) {
        this.criticality = newValue;
    }

    @State.action.bound
    public setReactions(newValue: AllergyIntoleranceReactionAssignment[]) {
        this.reactions = State.observable(newValue);
    }
}
