import Di from "@Di";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import CompositeArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/CompositeArrayFormFieldData";
import DateFormFieldData from "@Toolkit/FormEngine/Model/Data/DateFormFieldData";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import { ChangeAction } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

@Di.injectable()
class PatientAdministrativeDataAssecoMedFormLogic implements IFormLogic {

    constructor(
    ) { }

    public onFieldChangedAsync(
        form: IForm,
        showScreenAction: ShowScreenFrontendActionBase,
        dataElements: FormDataElementBase[],
        propertyIdentifier: string,
        newValue: any,
        action: ChangeAction
    ): Promise<void> {

        const formData = form.data.Content;
        const insurances = getField<CompositeArrayFormFieldData>(formData, "Insurances");

        if (action !== "add" || insurances.value.length < 1 || propertyIdentifier != "Insurances")
            return Promise.resolve();

        const lastIndex = insurances.value.length - 1;
        const validFrom = getField<DateFormFieldData>(formData, `Main.Insurances[${lastIndex}].ValidFrom`);
        const year = DateTimeService.today().getYear();
        State.runInAction(() => {
            validFrom.value = LocalDate.create(year, 1, 1);
        });

        return Promise.resolve();
    }
}

export default PatientAdministrativeDataAssecoMedFormLogic;