import React from "react";
import * as Ui from "@CommonControls";
import IWidgetComponentProps from "@PluginInterface/Dashboard/IWidgetComponentProps";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { ICareRegisterClient, ScalarObservationValueDto } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientContext from "@HisPlatform/Model/DomainModel/PatientContext/PatientContext";
import ObservationDefinitionId from "@Primitives/ObservationDefinitionId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import IWidgetCommonConfig from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/IWidgetCommonConfig";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import _ from "@HisPlatform/Common/Lodash";
import PatientId from "@Primitives/PatientId.g";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import ScalarValueChartWidgetApiAdapter from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/ScalarValueChartWidget/ScalarValueChartWidgetApiAdapter";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import { DashboardContextAdapter } from "@CommonControls/Dashboard/DashboardContext";

interface IScalarValueChartWidgetDependencies {
    localizationService: ILocalizationService;
    scalarValueChartWidgetApiAdapter: ScalarValueChartWidgetApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
}

interface IScalarValueChartWidgetProps extends IWidgetComponentProps {
    _dependencies?: IScalarValueChartWidgetDependencies;
    _patientId?: PatientId;
}

/** @screen */
@State.observer
class ScalarValueChartWidget extends React.Component<IScalarValueChartWidgetProps> {

    private get localizationService() { return this.props._dependencies.localizationService; }
    private get apiAdapter() { return this.props._dependencies.scalarValueChartWidgetApiAdapter; }
    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }

    @State.observable private values: any[] = [];
    @State.observable private unitName: string = "";
    @State.observable private lowReferenceValue: number = 0;
    @State.observable private highReferenceValue: number = 0;

    @State.observable.ref private definitionId: ObservationDefinitionId = null;

    @State.computed private get patientIdValue() { return this.props._patientId?.value; }

    private disposer: () => void = null;

    @State.computed private get height(): number {
        const config: IWidgetCommonConfig = this.props.configuration;
        switch (this.props._mode!) {
            case "normal":
                return 180;
            case "small":
                return 140;
        }
    }
    @State.observable.ref private items: Array<ISelectBoxItem<ObservationDefinitionId>> = null;

    private async loadDefinitionsAndValuesAsync() {
        const response = await this.apiAdapter.getScalarObservationDefinitionsByPatientIdQueryAsync(this.patientIdValue);
        const definitions = response.value;
        const items = definitions.map(i => {
            return { text: i.name, value: i.id } as ISelectBoxItem<ObservationDefinitionId>;
        });

        State.runInAction(() => {
            this.items = items;
        });

        await this.careReferenceDataStore.observationDefinition.ensureLoadedAsync(definitions.map(i => i.id));

        if (this.props.configuration && !isNullOrUndefined(this.props.configuration.ObservationDefinitionCode)) {
            const definitionCode = this.props.configuration.ObservationDefinitionCode;
            const defId = this.careReferenceDataStore.observationDefinition.getIdByCode(definitionCode);
            if (!isNullOrUndefined(defId)) {
                State.runInAction(() => {
                    this.definitionId = defId;
                });
            } else {
                this.setFirstValue();
            }
        } else {
            this.setFirstValue();
        }

        await this.loadValuesAsync();
    }

    private async loadValuesAsync() {
        if (!isNullOrUndefined(this.definitionId) && !isNullOrUndefined(this.patientIdValue)) {
            const response = await this.apiAdapter.getScalarObservationValuesByDefinitionQueryAsync(this.definitionId.value, this.patientIdValue);
            this.setValues(response.value);
        }
    }

    @State.bound
    private async loadAsync() {
        if (!isNullOrUndefined(this.patientIdValue) && this.patientIdValue !== "new") {
            await this.loadDefinitionsAndValuesAsync();
        }
    }

    @State.action.bound
    private setFirstValue() {
        if (!isNullOrUndefined(this.items) && this.items.length > 0) {
            this.definitionId = this.items[0].value;
        }
    }

    @State.action.bound
    private setValues(dto: ScalarObservationValueDto) {

        const orderedValues = _.sortBy(dto.values, v => v.observedAt);
        this.values = orderedValues.map(v => ({
            amount: v.amount,
            observedAt: this.localizationService.localizeDateTime(v.observedAt, false, false, true)
        }));
        this.unitName = dto.unitShortCode;
        this.lowReferenceValue = dto.lowReferenceBound;
        this.highReferenceValue = dto.highReferenceBound;
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);

    public componentDidMount() {
        this.disposer = State.autorun(() => {
            dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        });
    }

    public componentWillUnmount() {
        this.disposer();
    }

    @State.action.bound
    private onDefinitionChange(newValue: ObservationDefinitionId) {
        this.definitionId = newValue;
        if (!isNullOrUndefined(this.definitionId) && !isNullOrUndefined(this.definitionId.value)) {
            dispatchAsyncErrors(this.loadValuesAsync(), this);
        }
    }

    private renderReferenceValueLabels() {
        if (!isNullOrUndefined(this.definitionId)) {
            if (!isNullOrUndefined(this.lowReferenceValue) && !isNullOrUndefined(this.highReferenceValue)) {
                return (
                    <div style={{ color: "lightcoral", textAlign: "center" }}>{StaticProductivityResources.Widgets.ScalarValueChartWidget.NormalRange}: {this.lowReferenceValue + " " + this.unitName} - {this.highReferenceValue + " " + this.unitName}</div>
                );
            } else if (isNullOrUndefined(this.lowReferenceValue) && !isNullOrUndefined(this.highReferenceValue)) {
                return (
                    <div style={{ color: "lightcoral", textAlign: "center" }}>{StaticProductivityResources.Widgets.ScalarValueChartWidget.NormalRange}: MAX {this.highReferenceValue + " " + this.unitName}</div>
                );
            } else if (!isNullOrUndefined(this.lowReferenceValue) && isNullOrUndefined(this.highReferenceValue)) {
                return (
                    <div style={{ color: "lightcoral", textAlign: "center" }}>{StaticProductivityResources.Widgets.ScalarValueChartWidget.NormalRange}: MIN {this.lowReferenceValue + " " + this.unitName}</div>
                );
            } else {
                return null;
            }
        }
        return null;
    }

    @State.bound
    private getWidth() {
        switch (this.props._mode!) {
            case "normal":
                return 320;
            case "small":
                return 200;
        }
    }

    public render() {

        if (isNullOrUndefined(this.items) || this.items.length === 0) {
            return (
                <Ui.Dashboard.Widget
                    name={this.props.name}
                    isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                    icon={"chart"}
                    isCollapsible title={StaticProductivityResources.Widgets.ScalarValueChartWidget.Title}
                    automationId="ScalarValueChartWidget"
                >
                    {this.initialLoadPanelAsync.isUnauthorizedAccess ?
                        <UnauthorizedAccessContent visualStyle="dark" /> :
                        <div style={{ textAlign: "center" }}>{StaticProductivityResources.Widgets.ScalarValueChartWidget.NoObservationsMessage}</div>
                    }
                </Ui.Dashboard.Widget>
            );
        }
        return (

            <Ui.Dashboard.Widget
                name={this.props.name}
                isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                icon={"chart"}
                isCollapsible
                title={StaticProductivityResources.Widgets.ScalarValueChartWidget.Title}
                automationId="ScalarValueChartWidget"
            >
                <>
                    {this.initialLoadPanelAsync.isUnauthorizedAccess ?
                        <UnauthorizedAccessContent visualStyle="dark" /> :
                        <div style={{ marginLeft: "5px", marginRight: "5px", height: "100%" }}>
                            <Ui.SelectBox
                                visualMode="dark"
                                items={this.items}
                                onChange={this.onDefinitionChange}
                                value={this.definitionId}
                                size="compact"
                                clearable={false}
                                automationId="definitionSelectBox"
                            />
                            <div style={{ fontSize: "12px", height: this.height }}>
                                <Ui.Chart.Line
                                    width={this.getWidth()}
                                    height={this.height}
                                    data={this.values}
                                    XAxisDataKey={"observedAt"}
                                    XAxisTick={false}
                                    margin={{ top: 10, left: -30, bottom: 0, right: 30 }}
                                    lineSettings={[{ dataKey: "amount", lineColor: "#00b4ec", pointColor: "#00b4ec", label: StaticProductivityResources.Widgets.ScalarValueChartWidget.Value + " ", unit: " " + this.unitName }]}
                                    theme="dark"
                                    lowReferenceValue={this.lowReferenceValue}
                                    highReferenceValue={this.highReferenceValue}
                                    isTooltipEnabled
                                />
                            </div>
                            {this.renderReferenceValueLabels()}
                        </div>
                    }
                </>
            </Ui.Dashboard.Widget>
        );
    }
}

export default connect(
    withLoadingBoundary(withHisErrorBoundary(ScalarValueChartWidget)),
    new DependencyAdapter<IScalarValueChartWidgetProps, IScalarValueChartWidgetDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
        scalarValueChartWidgetApiAdapter: c.resolve("ScalarValueChartWidgetApiAdapter"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore")
    })),
    new PatientContextAdapter<IScalarValueChartWidgetProps>(c => ({
        _patientId: c.patientId
    })),
    new DashboardContextAdapter<IScalarValueChartWidgetProps>(d => ({
        _mode: d.mode
    })),
);
