import React, { useMemo, useRef, useCallback } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import CareActivitySourceRadioButtonGroup from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/CareActivitySourceRadioButtonGroup";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import CareTypeSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/CareTypeSelectBox";
import CareActivityStateSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/CareRegister/CareActivityStateSelectBox/CareActivityStateSelectBox";
import PointOfCareSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareSelectBox";
import { DoctorCodeSelector } from "@HisPlatformControls";
import EhiQueryButtonWithEmergencyCheckBox from "@HunEHealthInfrastructurePlugin/Common/Components/EhiQueryButtonWithEmergencyCheckBox";
import { IntervalOption } from "@CommonControls/DateRangePicker/DateRangePicker";
import EhiPatientsCareActivitiesFilterStore from "./EhiPatientsCareActivitiesFilterStore";
import IPatientsCareActivitiesFilterStore from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientsCareActivitiesFilterStore";
import ClientSideValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidationBoundary";
import EhiPatientsCareActivitiesListFilterValidator from "./EhiPatientsCareActivitiesListFilterValidator";
import ICareActivitySourceRegistry from "@HisPlatform/BoundedContexts/Care/Services/Definition/CareActivitySourceRegistry/ICareActivitySourceRegistry";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface IEhiPatientsCareActivitiesListFilterDependencies {
    careActivitySourceRegistry: ICareActivitySourceRegistry;
}

interface IEhiPatientsCareActivitiesListFilterProps {
    _dependencies?: IEhiPatientsCareActivitiesListFilterDependencies;
    filterStore: IPatientsCareActivitiesFilterStore;
    onQueryAsync: () => Promise<void>;
}

function EhiPatientsCareActivitiesListFilter(props: IEhiPatientsCareActivitiesListFilterProps) {

    const filterStore = useMemo(() => {
        if (props.filterStore && !(props.filterStore instanceof EhiPatientsCareActivitiesFilterStore)) {
            throw new Error("EhiPatientsCareActivitiesListFilter props.filterStore is not an EhiPatientsCareActivitiesFilterStore");
        }

        return props.filterStore as EhiPatientsCareActivitiesFilterStore;
    }, [props.filterStore]);

    const clientSideValidator = useRef(new EhiPatientsCareActivitiesListFilterValidator());

    const queryAsync = useCallback(async () => {
        if (clientSideValidator.current.isValid()) {
            await props.onQueryAsync?.();
        }
    }, [props.onQueryAsync]);

    return (
        <ClientSideValidationBoundary validator={clientSideValidator.current} entityTypeName="Filter">
            <Ui.Flex outerSpacing={"regular"}>
                <Ui.Flex.Item xs={12}>
                    <Ui.Flex>
                        {props._dependencies.careActivitySourceRegistry.careActivitySources.length > 1 && <Ui.Flex.Item xs={3}>
                            <CareActivitySourceRadioButtonGroup
                                label={StaticCareResources.EHR.PatientsCareActivities.Labels.CareActivitySource}
                                items={props._dependencies.careActivitySourceRegistry.careActivitySources}
                                value={filterStore.careActivitySource}
                                onChange={filterStore.setCareActivitySource}
                            />
                        </Ui.Flex.Item>}
                        <Ui.Flex.Item xs={3}>
                            <Ui.DateRangePicker
                                label={StaticCareResources.EHR.PatientsCareActivities.Labels.DateRange}
                                value={filterStore.dateRange}
                                onChange={filterStore.setDateRange}
                                intervalOptions={[
                                    IntervalOption.Today,
                                    IntervalOption.ThisWeek,
                                    IntervalOption.LastWeek,
                                    IntervalOption.ThisMonth,
                                    IntervalOption.Last30Days,
                                    IntervalOption.LastYear,
                                    IntervalOption.Last5Years
                                ]}
                                automationId="dateRange"
                                propertyIdentifier="dateRange"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <CareTypeSelectBox
                                label={StaticCareResources.EHR.PatientsCareActivities.Labels.CareType}
                                value={filterStore.careTypeIds}
                                onChange={filterStore.setCareTypeIds}
                                multiSelect
                                automationId="careTypeIds"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.TextBox
                                value={filterStore.careIdentifier}
                                onChange={filterStore.setCareIdentifier}
                                label={StaticCareResources.EHR.PatientsCareActivities.Labels.CareIdentifier}
                                automationId="careIdentifier"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <CareActivityStateSelectBox
                                value={filterStore.careActivityStates}
                                onChange={filterStore.setCareActivityStates}
                                label={StaticCareResources.EHR.PatientsCareActivities.Labels.CareActivityState}
                                multiSelect
                                automationId="careActivityStates"
                            />

                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <PointOfCareSelectBox
                                label={StaticCareResources.EHR.PatientsCareActivities.Labels.PointOfCare}
                                value={filterStore.pointOfCareIds}
                                onChange={filterStore.setPointOfCareIds}
                                multiSelect
                                automationId="pointOfCareIds"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <DoctorCodeSelector
                                label={StaticCareResources.EHR.PatientsCareActivities.Labels.LeadDoctor}
                                value={filterStore.practitionerIds}
                                onChange={filterStore.setPractitionerId}
                                multiSelect={true}
                                automationId="practitionerId"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3} style={{ paddingTop: 19 }}>
                            <EhiQueryButtonWithEmergencyCheckBox
                                isFullWidth
                                onQueryAsync={queryAsync}
                                isEmergency={filterStore.isEmergency}
                                onIsEmergencyChange={filterStore.setIsEmergency}
                                onReasonChange={filterStore.setEmergencyReason}
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.Flex.Item>
            </Ui.Flex>
        </ClientSideValidationBoundary>
    );

}


export default connect(
    State.observer(EhiPatientsCareActivitiesListFilter),
    new DependencyAdapter<IEhiPatientsCareActivitiesListFilterProps, IEhiPatientsCareActivitiesListFilterDependencies>(c => ({
        careActivitySourceRegistry: c.resolve("ICareActivitySourceRegistry")
    }))
);