import { IFormCustomBlockComponentProps, ICustomBlockRegistryItem } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import PreviewFormDefinition from "@Toolkit/FormEngine/Model/PreviewFormDefinition";
import React, { useCallback } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import AppointmentsPanelCore from "./AppointmentsPanelCore";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import _ from "@HisPlatform/Common/Lodash";
import ReferralStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/ReferralStore";
import { useFormEntityIdField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import DateFormFieldData from "@Toolkit/FormEngine/Model/Data/DateFormFieldData";
import ReferencedEntityFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEntityFormDataElement";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IAppointmentsPanelFormCustomBlockProps extends IFormCustomBlockComponentProps {
}

function _AppointmentsPanelFormCustomBlock(props: IAppointmentsPanelFormCustomBlockProps) {
    if (props.formDefinition instanceof PreviewFormDefinition) {
        // Preview mode
        return (
            <div>AppointmentsPanelFormCustomBlock</div>
        );
    }

    const selectAppointment = useCallback(State.action((appointment: Appointment) => {
        getField<ReferencedEntityFormFieldData>(props.form.data.Content, "AppointmentScheduleEntryId").value = +appointment?.id.value;

        props.form.rowVersions.set("Appointment", appointment?.rowVersion);
    }), [props.form.data.Content]);

    const setReferralData = useCallback(State.action((referral: ReferralStore) => {
        getField<TextFormFieldData>(props.form.data.Content, "Referral.ReferralCareIdentifier").value = referral?.referralCareIdentifier;
        getField<ReferencedEntityFormFieldData>(props.form.data.Content, "Referral.ReferralDoctorId").value = !isNullOrUndefined(referral?.referralDoctorId?.value)
            ? +referral?.referralDoctorId?.value
            : null;
        getField<ReferencedEntityFormFieldData>(props.form.data.Content, "Referral.ReferralDiagnosisId1").value = !isNullOrUndefined(referral?.referralDiagnosisId1?.value)
            ? +referral?.referralDiagnosisId1?.value
            : null;
        getField<ReferencedEntityFormFieldData>(props.form.data.Content, "Referral.ReferralDiagnosisId2").value = !isNullOrUndefined(referral?.referralDiagnosisId2?.value)
            ? +referral?.referralDiagnosisId2?.value
            : null;
        getField<DateFormFieldData>(props.form.data.Content, "Referral.ReferralCreationDate").value = referral?.referralCreationDate;
        getField<ReferencedEntityFormFieldData>(props.form.data.Content, "Referral.ReferralWorkplaceId").value = !isNullOrUndefined(referral?.referralWorkplace?.id?.value)
            ? +referral?.referralWorkplace?.id?.value
            : null;
    }), [props.form.data.Content]);

    const [appointmentScheduleEntryId, setAppointmentScheduleEntryId] = useFormEntityIdField<AppointmentScheduleEntryId>(props.form.data.Content, "AppointmentScheduleEntryId", AppointmentScheduleEntryId);
    const hasServiceRequest = !isNullOrUndefined(getField<ReferencedEntityFormFieldData>(props.form.data.Content, "ServiceRequestId").value);

    return (
        <AppointmentsPanelCore
            hasServiceRequest={hasServiceRequest}
            initialAppointmentScheduleEntryId={appointmentScheduleEntryId}
            onSelectAppointment={selectAppointment}
            onSetReferralData={setReferralData}
        />
    );
}

const AppointmentsPanelFormCustomBlock = connect(
    _AppointmentsPanelFormCustomBlock
);


export const AppointmentsPanelRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Appointments Panel",
    dataElementsFactory: () => [
        new ReferencedEntityFormDataElement("AppointmentScheduleEntryId", false, null, false, true, "AppointmentScheduleEntry")
    ],
    componentType: AppointmentsPanelFormCustomBlock
};

export default AppointmentsPanelFormCustomBlock;