import React from "react";
import Styles from "./HeaderWithIcon.less";
import * as Ui from "@CommonControls";
import { iconVisualStyleType, iconNameType } from "@CommonControls/Icon";
import { ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";

export interface IHeaderWithIconProps extends ICommonControlProps {
    visualStyle?: iconVisualStyleType;
    iconName?: iconNameType;
    title?: string;
    subTitle?: React.ReactNode;
}


const HeaderWithIcon: React.FC<IHeaderWithIconProps> = (props) => {

    const subtitleClassName = new CompositeClassName(Styles.subTitle);
    subtitleClassName.addIf(!props.iconName, Styles.subTitleNoIcon);

    return (
        <>
            <div className={props.subTitle ? Styles.subTitleContainer : Styles.container}>
                {props.iconName && <div className={Styles.iconContainer}>
                    <Ui.Icon iconName={props.iconName} size="normal" visualStyle="primary" />
                </div>}
                <h1 className={Styles.titleContainer}>{props.title}</h1>
            </div>
            {props.subTitle && <div className={subtitleClassName.classNames}>
                {props.subTitle}
            </div>}
        </>
    );
};
export default HeaderWithIcon;