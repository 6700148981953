import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import NDataScreen from "@HisPlatform/BoundedContexts/Productivity/Components/NDataScreen/NDataScreen";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import React, { useCallback } from "react";
import ShowNewCareWorklistScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewCareWorklistScreenAction.g";
import CareWorklistApiAdapter from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/CareWorklistApiAdapter";

export default function CareWorklistScreen(props: IScreenPropsBase<ShowNewCareWorklistScreenAction>) {

    const { apiAdapter } = useDependencies(c => ({
        apiAdapter: c.resolve<CareWorklistApiAdapter>("CareWorklistApiAdapter"),
    }));

    const getDynamicListAsync = useCallback(async (args: FrontendListParameters) => {
        const response = await apiAdapter.getCareWorklistAsync(props.action.pointOfCareId, props.action.worklistCode, args);
        return response.result;
    }, []);

    return (
        <NDataScreen
            onGetDynamicListAsync={getDynamicListAsync}
         />
    );
}