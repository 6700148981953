import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class FormerConditionDetail {
    @State.observable.ref public inactivationDescription: string;
    @State.observable.ref public inactivationDate: LocalDate;

    constructor(inactivationDescription: string, inactivationDate: LocalDate) {
        this.inactivationDescription = inactivationDescription;
        this.inactivationDate = inactivationDate;
    }

    @State.action.bound
    public setInactivationDescription(newValue: string) {
        this.inactivationDescription = newValue;
    }

    @State.action.bound
    public setInactivationDate(newValue: LocalDate) {
        this.inactivationDate = newValue;
    }
}
