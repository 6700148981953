import FormFieldDataBase from "./FormFieldDataBase";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import IForm from "@Toolkit/FormEngine/Model/IForm";

export default class EmbeddedFormFormFieldData extends FormFieldDataBase {

    public isArray: boolean = false;
    @State.observable.ref public value: IObservableArray<IForm> = State.createObservableShallowArray([]);

    constructor(fieldName: string, isReadonly: boolean, isVisible: boolean, value: IObservableArray<IForm>) {
        super(fieldName, isReadonly, isVisible);

        this.value = value;
    }
}