import React, { useEffect, useCallback } from "react";
import { useDrag } from "react-dnd";
import Styles from "./FormLayoutEditor.less";
import IDragData from "./IDragData";
import { useFormLayoutEditorStore } from "./FormLayoutEditorStoreProvider";

interface IToolboxItemProps {
    displayName: string;
    data: IDragData;
    tooltip?: string;
}

function ToolboxItem(props: IToolboxItemProps) {

    const store = useFormLayoutEditorStore();

    const [{ opacity, isDragging }, drag] = useDrag({
        type: props.data.dragItemType,
        item: props.data,
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
            isDragging: monitor.isDragging()
        }),
    });

    useEffect(() => {
        if (isDragging) {
            store.startDraggingElement();
        } else {
            store.stopDraggingElement();
        }
    }, [isDragging]);

    const click = useCallback(() => {
        store.addItemToForm(props.data);
    }, [props.data]);

    return (
        <div className={Styles.toolboxItem} ref={drag} style={{ opacity }} onClick={click}>
            <span title={props.tooltip}>{props.displayName}</span>
        </div>
    );
}

export default ToolboxItem;
