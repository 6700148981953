import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import TextualPrescriptionTemplate from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/TextualPrescriptionTemplate";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import PrescriptionTemplateType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionTemplateType";
import ClientSideValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidationBoundary";
import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";

interface ITextualPrescriptionTemplateDetailDependencies {
}

interface ITextualPrescriptionTemplateDetailProps extends IHasMasterDetailState {
    _dependencies?: ITextualPrescriptionTemplateDetailDependencies;
    onSuccesfulSave: (wasNew: boolean, saveReponse: TextualPrescriptionTemplate) => void;
    onNavigatingAway: () => Promise<boolean>;
    validator: ClientSideValidator;
}

@State.observer
class TextualPrescriptionTemplateDetail extends React.Component<ITextualPrescriptionTemplateDetailProps> {

    //#region 
    private get template() { return this.props._masterDetailState.selectedItem as TextualPrescriptionTemplate; }
    //#endregion

    @State.observable public title: string = "Default title";

    @State.action.bound
    public setSelectedTemplateType(prescriptionTemplateType: PrescriptionTemplateType) {
        this.template.setType(prescriptionTemplateType);
    }

    @State.action.bound
    public setTitle(title: string) {
        this.title = title;
    }

    @State.bound
    private updateTitle() {
        this.setTitle(
            this.template?.isNew
                ? StaticHunSocialSecurityMedicationRequestResources.TextualPrescriptionTemplatesPanel.DetailTitles.New
                : this.template?.name
        );
    }

    public componentDidMount() {
        this.updateTitle();
    }

    public componentDidUpdate(prevProps: ITextualPrescriptionTemplateDetailProps) {
        if (this.template !== prevProps._masterDetailState?.selectedItem) {
            this.updateTitle();
        }
    }

    @State.bound
    private fillDisplayName() {
        if (this.template.name && !this.template.displayName) {
            this.template.setDisplayName(this.template.name);
        }
    }

    public render() {
        const isFormulaType = this.template && this.template.type === PrescriptionTemplateType.Formula;
        return (
            <ClientSideValidationBoundary validator={this.props.validator} entityTypeName="CreateTextualPrescriptionTemplate">
                <Ui.PageBox2>
                    <Ui.PageBox2.Header title={this.title} automationId="prescription"/>
                    <Ui.PageBox2.Body>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={12}>
                                <Ui.UniversalEnumSelector
                                    enumName={"PrescriptionTemplateType"}
                                    enumOrigin="server"
                                    value={this.template.type}
                                    onChange={this.setSelectedTemplateType}
                                    enumType={PrescriptionTemplateType}
                                    displayMode="groupedRadioButtons"
                                    propertyIdentifier="Type"
                                    required
                                    automationId="prescriptionTemplateTypeSelector"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={6}>
                                <Ui.TextBox
                                    value={this.template?.name}
                                    onChange={this.template?.setName}
                                    onBlur={this.fillDisplayName}
                                    propertyIdentifier="Name"
                                    required
                                    label={StaticHunSocialSecurityMedicationRequestResources.TextualPrescriptionTemplatesPanel.Labels.Name}
                                    automationId="templateNameTextBox"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={6}>
                                <Ui.TextBox
                                    value={this.template?.displayName}
                                    onChange={this.template?.setDisplayName}
                                    propertyIdentifier="DisplayName"
                                    label={StaticHunSocialSecurityMedicationRequestResources.TextualPrescriptionTemplatesPanel.Labels.DisplayName}
                                    required
                                    automationId="displayNameTextBox"
                                />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                        <Ui.TextBox
                            multiline
                            value={this.template?.content}
                            onChange={this.template?.setContent}
                            propertyIdentifier="Content"
                            label={StaticHunSocialSecurityMedicationRequestResources.TextualPrescriptionTemplatesPanel.Labels.Content}
                            autoResize
                            automationId="contentTextBox"
                        />
                        {isFormulaType && <>
                            <Ui.TextBox
                                multiline
                                value={this.template?.preparation}
                                onChange={this.template?.setPreparation}
                                propertyIdentifier="Preparation"
                                label={StaticHunSocialSecurityMedicationRequestResources.TextualPrescriptionTemplatesPanel.Labels.Preparation}
                                autoResize
                                automationId="preparationTextBox"
                            />
                        </>}
                    </Ui.PageBox2.Body>
                </Ui.PageBox2>
                <NavigateAwayHook onNavigateAwayAsync={this.props.onNavigatingAway} />
            </ClientSideValidationBoundary>
        );
    }
}

export default connect(
    TextualPrescriptionTemplateDetail,
    new DependencyAdapter<ITextualPrescriptionTemplateDetailProps, ITextualPrescriptionTemplateDetailDependencies>(c => ({
    }))
);