import * as React from "react";
import * as HisUi from "@HisPlatformControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import CountryId from "@Primitives/CountryId.g";
import SettlementApiAdapter from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/SettlementApiAdapter";
import SettlementWithZipCodeId from "@Primitives/SettlementWithZipCodeId.g";
import {IClientSideSettlementWithZipCode} from "@HisPlatformControls/SettlementWithZipCodeSelector/IClientSideSettlementWithZipCode";
import StaticWebAppResources from "@StaticResources";
import {IOrderingState, IPagingState} from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import {IUniversalCodeSelectorProps} from "@HisPlatformControls/UniversalCodeSelector";

interface ISettlementWithZipCodeSelectorDependencies {
    commonReferenceDataDataStore: CommonReferenceDataDataStore;
    settlementApiAdapter: SettlementApiAdapter;
}

interface ISettlementWithZipCodeSelectorProps extends ICodeSelectorCommonProps<SettlementWithZipCodeId> {
    _dependencies?: ISettlementWithZipCodeSelectorDependencies;
    countryId?: CountryId;
}

@State.observer
class SettlementWithZipCodeSelector extends React.Component<ISettlementWithZipCodeSelectorProps> {

    public static defaultProps: Partial<ISettlementWithZipCodeSelectorProps> = {
        maxResultCount: 10
    };

    @State.bound
    private async getDisplayValueAsync(value: SettlementWithZipCodeId) {
        const item = await this.dependencies.commonReferenceDataDataStore.settlementMap.getOrLoadAsync(value);
        return <><b>{item.zipCode}</b>{` - ${item.settlementName}`}</>;
    }

    @State.bound
    private async getTextValueAsync(value: SettlementWithZipCodeId) {
        const item = await this.dependencies.commonReferenceDataDataStore.settlementMap.getOrLoadAsync(value);
        return `${item.zipCode} - ${item.settlementName}`;
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const result = await this.dependencies.settlementApiAdapter.searchSettlementsWithZipCodesByTextAsync(
            text,
            this.props.countryId,
            this.props.maxResultCount,
            this.props.validOn
        );

        if (result.operationWasSuccessful) {
            await this.dependencies.commonReferenceDataDataStore.settlementMap.ensureLoadedAsync(result.value);
            return result.value;
        }

        return null;
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: IClientSideSettlementWithZipCode[]): Promise<IPagedItems<IClientSideSettlementWithZipCode>> {

        const today = LocalDate.today();
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;

        const results = await this.props._dependencies.settlementApiAdapter.searchSettlements(
            filterText,
            this.props.countryId,
            today,
            customOrdering,
            paging);

        return results.value;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: IClientSideSettlementWithZipCode) {
        this.props.onChange(item.id);
    }

    @State.bound
    private onComplexSearchMultiSelect(items: IClientSideSettlementWithZipCode[]) {
        this.props.onChange(items.map(item => item.id));
    }

    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            getTextValueAsync: this.getTextValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticWebAppResources.Selectors.SettlementSelector.Title,
            codeGetter: "zipCode",
            nameGetter: "settlementName",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect
        } as IUniversalCodeSelectorProps<SettlementWithZipCodeId, IClientSideSettlementWithZipCode>;

        return (
            <HisUi.UniversalCodeSelector
                {...props}
                quickSearchMinimumCharacters={2}
                automationId={this.props.automationId}
            />
        );
    }

    private get dependencies() {
        return this.props._dependencies;
    }
}

export default connect(
    SettlementWithZipCodeSelector,
    new DependencyAdapter<ISettlementWithZipCodeSelectorProps, ISettlementWithZipCodeSelectorDependencies>((container) => {
        return {
            commonReferenceDataDataStore: container.resolve("CommonReferenceDataDataStore"),
            settlementApiAdapter: container.resolve("SettlementApiAdapter"),
        };
    })
);
