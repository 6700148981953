import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import { PatientServiceListItem } from "./PatientServiceList";
import Money from "@Toolkit/CommonWeb/Model/Money";
import moment from "moment";

export class PatientServiceRequestListItem extends PatientServiceListItem {
    constructor(
        quantity: number,
        isBilled: boolean,
        public serviceRequestId: ServiceRequestId,
        public serviceRequestIdentifier: string,
        public createdAt: moment.Moment,
        public totalNetPrice: Money
    ) {
        super(quantity, isBilled);
    }
}