import React, { useMemo, useCallback, useEffect } from "react";
import Styles from "./DataGrid.less";
import { IRowData } from "@CommonControls/DataGrid/ViewModel";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { DataGridRow, CheckableDataGridRow, IDataGridRowProps } from "@CommonControls/DataGrid/DataGridRow";
import { isNullOrUndefined, arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridFooterProps, RowId, IRowCheckState, IEmptyStateSettings, IPagingState, IOrderingState, IRowIndicatorStyle } from "@CommonControls/DataGrid/IDataGridProps";
import EmptyStateBody from "@CommonControls/DataGrid/EmptyStateBody";
import Pager from "@CommonControls/DataGrid/Paging/Pager";
import DataGridHeader from "@CommonControls/DataGrid/DataGridHeader";
import DataGridFilterRow from "@CommonControls/DataGrid/Filter/DataGridFilterRow";
import CompositeClassName, { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CompactPager from "@CommonControls/DataGrid/Paging/CompactPager";
import LoadingIndicator from "@CommonControls/LoadingIndicator";

interface IDataGridViewProps {
    hasHeader: boolean;
    onRenderHeader?: (columns: IDataGridColumnProps[]) => React.ReactNode;
    footer: React.ReactElement<IDataGridFooterProps> | ((props: IDataGridFooterProps) => React.ReactNode);
    emptyStateSettings: IEmptyStateSettings;
    rowHeight: string | number | ((row: any, rowId: RowId, rowIndex: number) => (string | number));

    columns: IDataGridColumnProps[];
    rows: IRowData[];
    selectedRow: IRowData;
    totalRowCount: number;
    showLoadingIndicator: boolean;
    isLoading: boolean;
    adjacentButtonsCount: number;
    isCompactPagerMode: boolean;
    paging: IPagingState;
    pageCount: number;
    orderingMap: Map<string | number, IOrderingState>;
    filterStore: any;
    fixedLayout: boolean;
    availablePageSizes: number[];
    hidePager: boolean;
    automationId: string;
    fixedHeight?: number | string;
    maxHeight?: number | string;
    actionsColumn: boolean;
    rowIndicatorsState: { [key: number]: IRowIndicatorStyle };
    hasRowIndicator: boolean;
    rowUnderEditing: IRowData | "all";

    onRenderRow: (props: React.HTMLProps<HTMLTableRowElement>, row: any, rowId: RowId, rowIndex: number) => React.ReactElement<React.HTMLAttributes<HTMLTableRowElement>>;
    rowBody: string | ((row: any, rowId: RowId, rowIndex: number) => React.ReactNode);
    rowBodyHeight?: React.ReactText;
    rowBodyPadding?: React.ReactText;
    rowCheckState: string | ((row: any, rowId: RowId, rowIndex: number) => IRowCheckState);
    onRowClick: (row: any, rowId: RowId, rowIndex: number, event: React.MouseEvent) => void;
    onRowChecked: (isChecked: boolean, row: any, rowId: RowId, rowIndex: number) => void;
    onPagingStateChange: (pagingState: IPagingState) => void;
    onColumnClick: (column: IDataGridColumnProps) => void;
    onRowRightClick: (row: any, rowId: RowId, rowIndex: number, event: React.MouseEvent) => void;
    onFilterChanged: () => void;
    disableOnRowHover?: boolean;
    showSeparatorLines: boolean;

    hasBackButton?: boolean;
    onBack?: () => void;
    backButtonText?: string;

    hasRefreshButton?: boolean;
    onRefreshAsync?: () => Promise<void>;
    extraFilter?: React.ReactNode;
    alwaysVisibleExtraFilter?: React.ReactNode;
    hasExtraFilterButton?: boolean;
    onExtraFilterVisibilityChanged?: (newValue: boolean) => void;
    isExtraFilterVisible?: boolean;

    hasFilterClearButton?: boolean;
    onClearFilter?: () => void;

    onMouseOver?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;

    horizontalScroll?: boolean;
    hidePointerCursorForRows?: boolean;

    hasPrintButton?: boolean;
    onPrintAsync?: () => Promise<void>;

    showCheckAll: boolean;
    checkAllValue: boolean;
    onCheckAllValueChanged: (value: boolean) => void;
}


const DataGridView: React.FC<IDataGridViewProps> = props => {
    const colSpan = props.columns.length;
    const fullColSpan = colSpan + (props.hasRowIndicator ? 1 : 0);

    const columnHeaderRef = React.createRef<HTMLTableRowElement>();
    const headerRef = React.createRef<HTMLTableSectionElement>();
    const containerRef = React.createRef<HTMLDivElement>();
    // const extraFilterContainerRef = React.createRef<HTMLDivElement>();

    const footer = useMemo(() => {
        if (isNullOrUndefined(props.footer)) {
            return null;
        }

        const pager = props.isCompactPagerMode ? (
            <CompactPager pageCount={props.pageCount} pagingState={props.paging} onPagingStateChange={props.onPagingStateChange} />
        ) : (
            <Pager
                adjacentButtonsCount={props.adjacentButtonsCount}
                pagingState={props.paging}
                onPagingStateChange={props.onPagingStateChange}
                pageCount={props.pageCount}
                availablePageSizes={props.availablePageSizes}
            />
        );

        const footerProps = ({
            colSpan: colSpan,
            totalRowCount: props.totalRowCount,
            rows: props.rows.map(r => r.data),
            pager: !props.hidePager && props.paging && props.totalRowCount > Math.min(...props.availablePageSizes) && pager,
            hasBackButton: props.hasBackButton,
            onBack: props.onBack,
            backButtonText: props.backButtonText,
            isCompact: props.isCompactPagerMode
        } as IDataGridFooterProps);

        if (typeof props.footer === "function") {
            return props.footer(footerProps);
        }

        return React.cloneElement(props.footer, footerProps);

    }, [props.footer, props.rows, props.totalRowCount, colSpan, props.adjacentButtonsCount, props.paging, props.onPagingStateChange, props.pageCount]);

    useEffect(() => {
        if (headerRef.current && containerRef.current) {
            containerRef.current.style.setProperty("--headerHeight", headerRef.current.clientHeight + "px");
        }

        if (columnHeaderRef.current && containerRef.current) {
            containerRef.current.style.setProperty("--columnHeaderHeight", columnHeaderRef.current.getBoundingClientRect().height + "px");
        }
    });

    const renderEmptyBody = useCallback(() => <EmptyStateBody {...props.emptyStateSettings} colSpan={fullColSpan} />, [props.emptyStateSettings, colSpan]);
    const renderBody = useCallback(() => {
        if (arrayIsNullOrEmpty(props.columns)) {
            return null;
        }
        return props.rows.map((row, rowIndex) => {

            const rowProps = {
                key: row.id,
                headers: props.columns,
                row: row,
                rowIndex: rowIndex,
                onRenderRow: props.onRenderRow,
                rowBody: props.rowBody,
                isSelected: row === props.selectedRow,
                isUnderEditing: row === props.rowUnderEditing || props.rowUnderEditing === "all",
                onClick: props.onRowClick,
                colSpan,
                rowHeight: props.rowHeight,
                onRightClick: props.onRowRightClick,
                hasRowIndicator: props.hasRowIndicator,
                rowIndicator: props.rowIndicatorsState?.[rowIndex],
                rowBodyHeight: props.rowBodyHeight,
                rowBodyPadding: props.rowBodyPadding,
                disableOnRowHover: props.disableOnRowHover,
                showSeparatorLines: props.showSeparatorLines,
                hidePointerCursor: props.hidePointerCursorForRows
            } as IDataGridRowProps;

            if (isNullOrUndefined(props.rowCheckState)) {
                return <DataGridRow {...rowProps} />;
            } else {
                return <CheckableDataGridRow {...rowProps} onRowChecked={props.onRowChecked} rowCheckState={props.rowCheckState} />;
            }
        });
    }, [props]);

    const table = (
        <table
            className={combineClasses(Styles.table, props.fixedLayout && Styles.fixedLayout, props.actionsColumn && Styles.actionsColumn)}
            style={{ height: props.fixedHeight || props.maxHeight ? "100%" : undefined }}
        >
            <colgroup>
                {props.hasRowIndicator && <col style={{ width: 5 }} data-automation-id="__col_RowIndicator" />}
                {!isNullOrUndefined(props.rowCheckState) && <col style={{ width: 32 }} data-automation-id="__col_CheckState" />}
                {props.columns.map((column, headerIndex) => (
                    <col
                        key={isNullOrUndefined(column.id) ? headerIndex : column.id}
                        style={{
                            width: column.width
                        }}
                        data-automation-id={`__col_${isNullOrUndefined(column.automationId) ? (isNullOrUndefined(column.id) ? headerIndex : column.id) : column.automationId}`}
                    />
                ))}
            </colgroup>
            {props.hasHeader && (
                <thead ref={headerRef}>
                    <DataGridHeader
                        columnHeaderRef={columnHeaderRef}
                        hasCheckCell={!isNullOrUndefined(props.rowCheckState)}
                        hasRowIndicator={props.hasRowIndicator}
                        columns={props.columns}
                        onClick={props.onColumnClick}
                        orderingMap={props.orderingMap}
                    />
                    {(props.filterStore || props.hasExtraFilterButton || props.hasFilterClearButton || props.hasRefreshButton) && (
                        <DataGridFilterRow
                            showCheckAll={props.showCheckAll}
                            checkAllValue={props.checkAllValue}
                            onCheckAllValueChanged={props.onCheckAllValueChanged}
                            hasCheckCell={!isNullOrUndefined(props.rowCheckState)}
                            hasRowIndicator={props.hasRowIndicator}
                            filterStore={props.filterStore}
                            columns={props.columns}
                            onFilterChanged={props.onFilterChanged}
                            hasRefreshButton={props.hasRefreshButton}
                            onRefreshAsnyc={props.onRefreshAsync}
                            hasExtraFilterButton={props.hasExtraFilterButton}
                            onExtraFilterVisibilityChanged={props.onExtraFilterVisibilityChanged}
                            isExtraFilterVisible={props.isExtraFilterVisible}
                            hasFilterClearButton={props.hasFilterClearButton}
                            onClearFilter={props.onClearFilter}
                            hasPrintButton={props.hasPrintButton}
                            onPrintAsync={props.onPrintAsync}
                        />
                    )}
                    {props.onRenderHeader && (
                        <State.Observer>{() => props.onRenderHeader(props.columns) as any}</State.Observer>
                    )}
                </thead>
            )}
            <tbody>
                {props.showLoadingIndicator && props.isLoading && <LoadingIndicator mode="parent" size="medium" />}
                {(!props.showLoadingIndicator || !props.isLoading) && 
                    <>
                        {arrayIsNullOrEmpty(props.rows) ? renderEmptyBody() : renderBody()}
                    </>
                }
            </tbody>
        </table>
    );

    const tableContainerClassNames = new CompositeClassName(Styles.tableContainer);
    tableContainerClassNames.addIf(props.horizontalScroll, Styles.horizontalScrollbarContainer);
    tableContainerClassNames.addIf(!props.horizontalScroll, Styles.verticalScrollbarContainer);

    const renderer = () => (
        <>
            <div ref={containerRef} className={Styles.wrapper} style={{ height: props.fixedHeight, maxHeight: props.maxHeight }}>
                <div className={Styles.extraFilterContainer}>
                    {props.alwaysVisibleExtraFilter &&
                        <div>
                            {props.alwaysVisibleExtraFilter}
                        </div>
                    }
                    {props.isExtraFilterVisible &&
                        <div>
                            {props.extraFilter}
                        </div>
                    }
                </div>
                {props.horizontalScroll ?
                    <div className={tableContainerClassNames.classNames} data-automation-id={props.automationId || undefined}
                        onMouseOver={props.onMouseOver}
                        onMouseLeave={props.onMouseLeave}>
                        <div className={Styles.verticalScrollbarContainer} onMouseOver={props.onMouseOver}
                            onMouseLeave={props.onMouseLeave}
                            onScroll={props.onScroll}>
                            {table}
                        </div>
                    </div>
                    :
                    <div className={tableContainerClassNames.classNames} data-automation-id={props.automationId || undefined}
                        onMouseOver={props.onMouseOver}
                        onMouseLeave={props.onMouseLeave}
                        onScroll={props.onScroll}>
                        {table}
                    </div>
                }
                <div className={Styles.footerContainer}>
                    <table className={Styles.table}>
                        {props.footer &&
                            <tfoot>
                                {footer}
                            </tfoot>}
                    </table>
                </div>
            </div>

        </>
    );

    return (
        <>
            <State.Observer>{renderer}</State.Observer>
        </>
    );
};

export default DataGridView;