import React from "react";
import styles from "./flexboxgrid.less";
import FlexStyles from "./flex.less";
import _ from "@HisPlatform/Common/Lodash";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import { isNullOrWhiteSpace } from "@Toolkit/CommonWeb/NullCheckHelpers";

export type width = boolean | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type offset = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface IFlexItemProps {
    xs?: width;
    sm?: width;
    md?: width;
    lg?: width;
    xsOffset?: offset;
    smOffset?: offset;
    mdOffset?: offset;
    lgOffset?: offset;
    content?: React.ReactNode;
    sidePadding?: number | string;
    className?: string;
    style?: React.CSSProperties;
    automationId?: string;
    grow?: boolean;
    noShrink?: boolean;
    horizontalContentAlignment?: "left" | "center" | "right";
    verticalContentAlignment?: "top" | "center" | "bottom";

    onClick?: () => void;
}

const getWidthName = (baseName: string, propvalue: width): string => {
    return (styles as any)[baseName + (propvalue === true ? "" : propvalue)];
};

const getClassName = (props: IFlexItemProps) => {
    const className = new CompositeClassName(props.className);

    className.addIf(!!props.xs, getWidthName("colXs", props.xs));
    className.addIf(!!props.sm, getWidthName("colSm", props.sm));
    className.addIf(!!props.md, getWidthName("colMd", props.md));
    className.addIf(!!props.lg, getWidthName("colLg", props.lg));

    className.addIf(!!props.xsOffset, (styles as any)["colXsOffset" + props.xsOffset]);
    className.addIf(!!props.smOffset, (styles as any)["colSmOffset" + props.smOffset]);
    className.addIf(!!props.mdOffset, (styles as any)["colMdOffset" + props.mdOffset]);
    className.addIf(!!props.lgOffset, (styles as any)["colLgOffset" + props.lgOffset]);

    className.addIf(props.grow, styles.grow);
    className.addIf(props.noShrink, styles.noShrink);

    className.addIf(!isNullOrWhiteSpace(props.horizontalContentAlignment) || !isNullOrWhiteSpace(props.verticalContentAlignment), FlexStyles.itemAlign);
    className.addIf(!isNullOrWhiteSpace(props.horizontalContentAlignment) && isNullOrWhiteSpace(props.verticalContentAlignment), FlexStyles.verticalAlignCenter);

    className.addIf(props.horizontalContentAlignment === "left", FlexStyles.horizontalAlignLeft);
    className.addIf(props.horizontalContentAlignment === "center", FlexStyles.horizontalAlignCenter);
    className.addIf(props.horizontalContentAlignment === "right", FlexStyles.horizontalAlignRight);

    className.addIf(props.verticalContentAlignment === "top", FlexStyles.verticalAlignTop);
    className.addIf(props.verticalContentAlignment === "center", FlexStyles.verticalAlignCenter);
    className.addIf(props.verticalContentAlignment === "bottom", FlexStyles.verticalAlignBottom);

    return className.classNames;
};


const FlexItem: React.SFC<IFlexItemProps> = props => (
    <div
        style={props.style}
        className={getClassName(props)}
        data-automation-id={props.automationId || undefined}
        onClick={props.onClick}
    >
        {props.children || props.content}
    </div>
);

export default FlexItem;