import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import { IExtensionPointProps } from "./IExtensionPointProps";

@Di.injectable()
export default class AlwaysVisibleExtensionComponentService implements IExtensionComponentService<IExtensionPointProps> {

    public isVisible(props: IExtensionPointProps) {
        return true;
    }

    public shouldRerunIsVisible(props: IExtensionPointProps, prevProps: IExtensionPointProps) {
        return true;
    }
}