import LifeStyleRiskId from "@Primitives/LifeStyleRiskId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import RiskAssignmentBase from "./RiskAssignmentBase";

export default class ReferencedLifeStyleRiskAssignment extends RiskAssignmentBase  {
    @State.observable.ref public lifeStyleRiskId: LifeStyleRiskId = null;

    constructor(lifeStyleRiskId: LifeStyleRiskId) {
        super();
        this.lifeStyleRiskId = lifeStyleRiskId;
    }
}