import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import * as Ui from "@CommonControls";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import { IPermanentPrescriptionListItem } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IPermanentPrescriptionListItem";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { DataGridLoadType, IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import PermanentPrescriptionReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PermanentPrescriptionReferenceDataStore";
import IDosageLocalizationService from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/IDosageLocalizationService";
import PermanentPrescriptionId from "@Primitives/PermanentPrescriptionId.g";
import MedicationSubsidyColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/MedicationSubsidyColumn";
import ConditionColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/ConditionColumn";
import OrganizationUnitOrExternalLocationColumn from "@HisPlatform/Components/HisPlatformControls/DataGridColumns/OrganizationUnitOrExternalLocationColumn";
import IOrganizationIdTypeUnion from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/IOrganizationUnitIdTypeUnion";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import OrganizationSelector from "@HisPlatform/BoundedContexts/Organization/Components/OrganizationSelector";
import PermanentPrescriptionFilterStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/PermanentPrescriptionListPanel/Filter/PermanentPrescriptionFilterStore";

interface IPermanentPrescriptionListViewDependencies {
    dialogService: IDialogService;
    dosageLocalizationService: IDosageLocalizationService;
    notificationService: INotificationService;
    prescriptionApiAdapter: PrescriptionApiAdapter;
    permanentPrescriptionReferenceDataStore: PermanentPrescriptionReferenceDataStore;
}

interface IPermanentPrescriptionListViewProps {
    _dependencies?: IPermanentPrescriptionListViewDependencies;
    filterStore: PermanentPrescriptionFilterStore;
    isLoading: boolean;
    permanentPrescriptions: PagedItemStore<IPermanentPrescriptionListItem>;
    managedOrganizationUnitIds: OrganizationUnitId[];
    paging: IPagingState;
    ordering: IOrderingState;
    onGridChangeAsync: (type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState | IOrderingState[]) => Promise<void>;
    onBack: () => void;
    onSelectOrganizationUnitByPointOfCare: () => void;
    onSelectOrganizationUnitByProfession: () => void;
    onPermanentPrescriptionDeleted?: (item: IPermanentPrescriptionListItem) => Promise<void>;
}

@State.observer
class PermanentPrescriptionListView extends React.Component<IPermanentPrescriptionListViewProps> {
    public render() {
        return (
            <Ui.ToolbarLayout topToolbar={this.renderToolbar()}>
                <Ui.DataGrid
                    dataSource={this.props.permanentPrescriptions}
                    isLoading={this.props.isLoading}
                    fixedLayout
                    fixedHeight="100%"
                    rowHeight={42}
                    hidePager={false}
                    hasHeader={true}
                    hasBackButton={true}
                    isSelectable={false}
                    onChangeAsync={this.props.onGridChangeAsync}
                    onBack={this.props.onBack}
                    rowComparer={this.comparePermanentPrescriptions}
                    paging={this.props.paging}
                    ordering={this.props.ordering}
                    automationId="permanentPrescriptionListViewDataGrid">
                    <DataGridColumn
                        dataGetter={"medicationName"}
                        header={StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionListPanel.Columns.MedicationName}
                        width={190}
                        id="MedicationName"
                        hideOverflow
                        isOrderable />
                    <DataGridColumn
                        dataGetter={"isAvailable"}
                        header={StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionListPanel.Columns.IsAvailable}
                        width={50}
                        id="IsAvailable"
                        showHint={true}
                        onRenderCellValue={this.renderAvailability}
                        onRenderHintValue={() => StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionListPanel.Columns.IsAvailableHint} />
                    <DataGridColumn
                        dataGetter={"textAmount"}
                        header={StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionListPanel.Columns.TextAmount}
                        width={75}
                        id="TextAmount"
                        hideOverflow
                        isOrderable />
                    <DataGridColumn
                        dataGetter={"quantity"}
                        header={StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionListPanel.Columns.Quantity}
                        width={50}
                        id="Quantity"
                        isOrderable />
                    <DataGridColumn
                        dataGetter={"id"}
                        header={StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionListPanel.Columns.Dosage}
                        width={150}
                        id="Dosage"
                        onRenderCellValue={this.renderDosage}
                        hideOverflow />
                    <ConditionColumn
                        dataGetter={"conditionId"}
                        header={StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionListPanel.Columns.Condition}
                        width={75}
                        id="Condition"
                        isOrderable />
                    <MedicationSubsidyColumn
                        dataGetter={"claimTypeId"}
                        header={StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionListPanel.Columns.SubsidyClaimType}
                        width={75}
                        id="SubsidyClaimType"
                        isOrderable />
                    <OrganizationUnitOrExternalLocationColumn
                        dataGetter={(i: IPermanentPrescriptionListItem) => ({
                                organizationUnitId: i.organizationUnitId
                            }) as IOrganizationIdTypeUnion}
                        header={StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionListPanel.Columns.OrganizationUnit}
                        width={75}
                        id="OrganizationUnit"
                        showName={false}
                        isOrderable />
                    <DataGridColumn
                        width={45}
                        id="Controls"
                        onRenderCellValue={(_, row) => this.renderControls(row)} />
                </Ui.DataGrid>
            </Ui.ToolbarLayout>
        );
    }

    @State.bound
    private comparePermanentPrescriptions(left: IPermanentPrescriptionListItem, right: IPermanentPrescriptionListItem): boolean {
        return ValueWrapper.equals(left?.id, right?.id);
    }

    @State.bound
    private renderToolbar() {
        return (
            <Ui.Flex>
                <Ui.Flex.Item xs={7}>
                    <OrganizationSelector
                        value={this.props.filterStore && this.props.filterStore.organizationUnitIds}
                        onChange={this.onOrganizationUnitIdsChanged}
                        pointOfCareMode={true} 
                        automationId="organization"
                        />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={5} horizontalContentAlignment="right">
                    <Ui.Button
                        text={StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionListPanel.Buttons.CareOrganizationUnit}
                        onClick={this.props.onSelectOrganizationUnitByPointOfCare}
                        automationId="selectByPointOfCareButton" />
                    <Ui.Button
                        text={StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionListPanel.Buttons.IdenticalProfessionUnits}
                        onClick={this.props.onSelectOrganizationUnitByProfession}
                        automationId="selectByProfessionButton" />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.bound
    private renderAvailability(isAvailable: boolean) {
        return isAvailable
            ? StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionListPanel.ProductAvailable
            : StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionListPanel.ProductUnavailable;
    }

    @State.bound
    private renderDosage(id: PermanentPrescriptionId) {
        const dosage = this.props._dependencies.permanentPrescriptionReferenceDataStore.dosages.get(id);
        return this.props._dependencies.dosageLocalizationService.localize(dosage?.dosage);
    }

    @State.bound
    private renderControls(item: IPermanentPrescriptionListItem) {
        const isDisabled = isNullOrUndefined(this.props.managedOrganizationUnitIds.find(id => ValueWrapper.equals(id, item.organizationUnitId)));

        return (
            <Ui.Button
                disabled={isDisabled}
                iconName="trash"
                visualStyle="negative-standard"
                float="right"
                style={{ marginRight: 10 }}
                stopPropagation
                onClickAsync={() => this.deletePermanentPrescriptionAsync(item)}
                automationId="permanentPrescriptionListViewDeleteButton" />
        );
    }

    @State.action.bound
    private onOrganizationUnitIdsChanged(newValue: OrganizationUnitId, checkedValue: boolean) {
        if (!this.props.filterStore)
            return;

        if (checkedValue) {
            this.props.filterStore.addOrganizationUnitId(newValue);
            return;
        }

        this.props.filterStore.removeOrganizationUnitId(newValue);
    }

    @State.bound
    private async deletePermanentPrescriptionAsync(item: IPermanentPrescriptionListItem): Promise<void> {
        const dialogResult = await this.props._dependencies.dialogService.yesNo(
            StaticWebAppResources.Common.DialogTitle.ConfirmationTitle,
            StaticHunSocialSecurityMedicationRequestResources.PermanentPrescriptionListPanel.DeletionConfirmationMessage);
        if (dialogResult.resultCode !== DialogResultCode.Yes)
            return;

        await this.props._dependencies.prescriptionApiAdapter.deletePermanentPrescriptionAsync(item.id);
        this.props._dependencies.notificationService.showSavedSuccessfully();
        if (!!this.props.onPermanentPrescriptionDeleted) {
            this.props.onPermanentPrescriptionDeleted(item);
        }
    }
}

export default connect(
    PermanentPrescriptionListView,
    new DependencyAdapter<IPermanentPrescriptionListViewProps, IPermanentPrescriptionListViewDependencies>(container => ({
        dialogService: container.resolve("IDialogService"),
        dosageLocalizationService: container.resolve("IDosageLocalizationService"),
        notificationService: container.resolve("INotificationService"),
        prescriptionApiAdapter: container.resolve("PrescriptionApiAdapter"),
        permanentPrescriptionReferenceDataStore: container.resolve("PermanentPrescriptionReferenceDataStore")
    }))
);