import React from "react";
import IAppointmentSchedulerBaseProps from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduler/AppointmentSchedulerBaseProps";
import AppointmentScheduler from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduler/AppointmentScheduler";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { ISchedulerEvent } from "@CommonControls/Scheduler/ISchedulerProps";
import Styles from "./AppointmentScheduleDefinitionScheduler.less";
import * as Ui from "@CommonControls";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

export default class AppointmentScheduleDefinitionScheduler extends React.Component<IAppointmentSchedulerBaseProps> {

    @State.observable private selectedDate: LocalDate = LocalDate.today();

    @State.action.bound
    private getEntryClassName(entry: ISchedulerEvent): string {
        return Styles.entry;
    }
    
    @State.action.bound private setSelectedDate(value: LocalDate) {
        this.props.setSelectedMoment(value.toUtcDayStartMoment());
        this.selectedDate = value;
    }

    @State.bound private renderDatePicker() {
        return (
            <div className={Styles.calendar}>
                <Ui.DatePicker
                    onChange={this.setSelectedDate}
                    value={this.selectedDate}
                    automationId="selectedDatePicker"
                />
            </div>
        );
    }

    public render() {
        return (
            <AppointmentScheduler
                {...this.props}
                hideCalendar
                onGetEntryClassName={this.getEntryClassName}
                scrollToTargets={[]}
                schedulerClassName={Styles.scheduler}
                onRenderAdditionToolbarComponent={this.renderDatePicker}
                entryEditIconClassName={Styles.editIcon}
                disableDynamicScale
            />
        );
    }
}