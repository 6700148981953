import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import ListQueryOptions from "@Toolkit/CommonWeb/Model/ListQueryOptions";

export function getQueryPagingAndOrderings(options: ListQueryOptions, serverEnum: any): any {
    return new QueryPagingAndOrderings(
        new Proxy.QueryPaging({
            pageIndex: options.currentPage,
            pageSize: options.pageSize
        }),
        getQueryOrderings(options, serverEnum)
    );
}

export function getQueryOrderings(options: ListQueryOptions, serverEnum: any) {
    return options.orderBy && options.orderBy.length > 0 ? [
        new OrderingFields(
            serverEnum[options.orderBy[0].fieldName],
            options.orderBy[0].ascending ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending
        )
    ] : null;
}

class QueryPagingAndOrderings {
    constructor(
        public readonly paging?: Proxy.QueryPaging,
        public readonly orderings?: OrderingFields[]
    ) { }

    public toJSON(dataIn?: any) {
        const data = typeof dataIn === "object" ? dataIn : {};
        data["Paging"] = this.paging ? this.paging.toJSON() : null as any;
        if (this.orderings && this.orderings.constructor === Array) {
            data["Orderings"] = [];
            for (const item of this.orderings) {
                data["Orderings"].push(item.toJSON());
            }
        }
        return data;
    }
}


class OrderingFields {
    constructor(
        public readonly fieldName: number,
        public readonly direction: Proxy.OrderingDirection
    ) { }

    public toJSON(dataIn?: any) {
        const data = typeof dataIn === "object" ? dataIn : {};
        data["FieldName"] = this.fieldName !== undefined ? this.fieldName : null as any;
        data["Direction"] = this.direction !== undefined ? this.direction : null as any;
        return data;
    }
}