import React from "react";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

export default function NavBarLogo() {

    const isDecember = DateTimeService.now().month() === 11;
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", margin: "0 8px", marginLeft: 12 }}>
            <img src="/images/logo.svg" />
            {isDecember && (
                <svg viewBox="0 0 410.43536 285.17389" xmlns="http://www.w3.org/2000/svg" style={{
                    position: "absolute",
                    left: -87,
                    top: -74,
                    transform: "scale(0.1)"
                }}>
                    <path d="m225.2 4.3978c-28.101 7.734-56.904 23.114-77.167 38.006s-73.191 52.416-85.275 64.307c-11.716 11.529-25.555 15.55-31.611 29.756-2.3354 5.4786 15.837 22.005 19.398 23.721 9.2413 4.4528 24.305-3.4428 31.178-3.9143 22.307-1.5303 45.167-4.4987 67.76 10.793-4.1805 4.2205 2.458-0.32326-6.0877 36.693-3.0135 13.053-9.5987 46.919-1.5071 53.38 8.0394 6.4196 12.949-18.606 20.937-14.774-4e-3 0.00595-0.00168 0.02106-0.00453 0.02643-0.00174 0.00479-0.00391 0.02219-0.00453 0.02643s-0.00612 0.02331-0.00455 0.0264l0.02486 0.00482c0.02431 0.00726 0.07765 0.00366 0.12878-0.00229 0.01503 0.00723 0.03465 0.00238 0.04971 0.00964l0.16487 0.08658 0.04753-0.12726c3.7814-0.9773 33.263-13.966 60.176-8.743 15.171 2.9441 39.369-5.3096 53.29-9.6152 22.076-6.828 53.068-12.998 72.728-13.351 14.483-0.25954 27.587-8.459 41.521-9.4148 8.2204-0.56393 0.59821-27.066 0.42828-29.866-0.81659-13.455-17.565-34.13-26.191-52.504s-23.082-41.697-39.004-59.986-37.402-41.971-52.525-49.259-17.953-13.675-48.451-5.2817z" fill="#d00000" fillRule="evenodd" stroke="#000" strokeWidth=".83349" />
                    <path d="m158.05 68.958c-18.233 4.4166-33.848 62.691-56.319 72.822-14.697 6.6258-54.749 16.693-51.188 18.408 9.2413 4.4528 24.305-3.4428 31.178-3.9143 22.307-1.5303 45.167-4.4987 67.76 10.793-4.1805 4.2205 2.458-0.32326-6.0877 36.693-3.0135 13.053-9.5987 46.919-1.5071 53.38 8.0394 6.4196 12.949-18.606 20.937-14.774-4e-3 0.00595-0.00168 0.02106-0.00453 0.02643-0.00174 0.00479-0.00391 0.02219-0.00453 0.02643s-0.00612 0.02331-0.00455 0.0264l0.02486 0.00482c0.02431 0.00726 0.07765 0.00366 0.12878-0.00229 0.01503 0.00723 0.03465 0.00238 0.04971 0.00964l0.16487 0.08658 0.04753-0.12726c3.7814-0.9773 33.263-13.966 60.176-8.743 15.171 2.9441 39.369-5.3096 53.29-9.6152 22.076-6.828 53.068-12.998 72.728-13.351 14.483-0.25954 27.587-8.459 41.521-9.4148 8.2204-0.56393-51.085 4.5206-27.45-12.576 10.485-7.5843-104.98 15.808-149.31-34.777-43.895-50.088-37.455-89.506-56.127-84.983z" fill="#0e0000" fillRule="evenodd" opacity=".10112" />
                    <path d="m33.021 130.25c3.5509 2.3529 27.398 32.517 30.215 30.406 0.34059-3.3309-2.4407-4.6421-4.3971-5.0224 2.6196-3.3292 3.1135-6.8106-1.4325-7.1096-5.0402-0.88997-10.543-1.5143-5.1325-6.9359 2.4978-3.0387-0.16794-8.4351-1.7017-11.18-4.0295-2.1764-7.066-6.1144-8.7176-8.7913" fillRule="evenodd" opacity=".26966" stroke="#000" strokeWidth=".83016" />
                    <path d="m356.69 195.2c-7.4512-2.6426-16.801-5.6948-23.39-5.2874-4.525-3.8225-7.9982 0.88788-11.919 2.1416-0.65796-7.3597-11.511 3.1123-16.291 3.1725-3.3945 1.9483-1.0548-5.8005-7.1335-2.8531-7.5576 0.71399-12.232 1.2332-17.839 3.6605-1.3466 8.0128-13.449 12.527-15.26 3.7443-6.5985 2.8714-18.869 8.4412-18.948-0.64163-10.564 8.194-10.9 15.598-21.214 19.553 0.9126-7.0922-22.371-10.908-25.544-3.4647-6.3896 2.5731 1.3292-9.321-7.9046-6.0523-10.401-3.0239-4.347 17.935-25.554 9.366-11.808-0.37982-5.2687 16.719-13.061 23.882-2.0761 7.5323-11.084-2.624-14.729-2.0601 0.84795 11.927 3.4077 7.8823 0.71924 15.997-1.6041 10.165-3.7513 15.255 7.4318 17.372 3.6212-4.9036-0.18205-2.829 0.62793 6.2175 6.4576-1.8798 14.946-12.64 14.075-1.5814 4.382-6.6733 18.254-10.151 18.08-17.339 7.0218 0.73709 14.902 8.5794 20.83-0.95297 5.9508-7.0412 14.504-10.307 14.594-4.8686 7.2338 6.7565 7.9572 5.3873 15.478 3.6678-1.9372-8.7117 17.599-0.59857 7.2827-9.2717 4.0976-4.1035 15.187-6.5014 21.596-5.0754 5.6695 8.2542 22.944 8.9372 25.632-1.747 0.564-8.4043 13.682-11.279 18.587-5.4682 8.5297-7.4786 21.984 3.3885 29.324-2.3442-6.5976-8.563 9.3244-14.158 12.298-7.593 5.8417-3.1366 11.278-14.423 17.668-6.3428 7.3499-2.3952 16.325-5.7256 22.542-8.2218 4.6667 7.6932 16.747-0.2211 11.511-9.5323-2.8348-6.6507 9.3098 3.2744 6.0035-5.1637-3.7609-9.0769-9.0426-21.692-15.514-21.275-8.8124-2.2019-14.038 3.7449-14.403 12.16-0.11142-6.1411-10.522-0.155-15.983 2.0719l-2.9939 0.71227-3.5769-2.5229" fillRule="evenodd" opacity=".29405" stroke="#000" strokeWidth=".60143" />
                    <path d="m361.62 197.45c-7.4512-2.6426-14.356-3.0141-20.945-2.6067-4.525-3.8225-9.8949-2.5689-13.816-1.3152-0.65796-7.3597-9.614 6.5691-14.395 6.6293-3.3945 1.9483-1.0548-5.8005-7.1335-2.8531-7.5575 0.71399-16.319-3.9722-21.925-1.545-1.3467 8.0128-13.449 12.527-15.26 3.7443-6.5986 2.8714-14.782 13.647-14.862 4.5638-12.385-0.54289-17.69 11.633-28.004 15.588 0.91261-7.0922-15.581-6.943-18.755 0.50038-6.3896 2.5731-1.6992-13.439-10.933-10.17-10.401-3.0239-10.117 17.697-22.525 13.484-11.808-0.37982-10.159 11.358-17.951 18.521-2.0761 7.5323-6.1937 2.7373-9.8394 3.3013 5.9991 8.5138-18.393 6.7404-11.152 19.87-1.6041 10.165 0.84851 17.472 12.032 19.589 3.6212-4.9036 7.0889-8.9191 7.8989 0.12732 6.4576-1.8798 14.946-12.64 14.075-1.5814 4.382-6.6733 18.254-10.151 18.08-17.339 7.0218 0.73706 14.902 8.5794 20.83-0.953 5.9508-7.0412 6.9335-1.296 7.0233 4.1423 7.2338 6.7565 15.528-3.6235 23.049-5.343-1.9372-8.7116 17.599-0.59857 7.2827-9.2717 4.0976-4.1034 15.187-6.5014 21.596-5.0754 5.6232 5.3341 22.944 8.9372 25.632-1.747 0.564-8.4043 13.682-11.279 18.587-5.4682 8.5297-7.4786 21.984 3.3885 29.324-2.3442-6.5976-8.563 9.3245-14.158 12.298-7.593 5.8417-3.1366 11.278-14.423 17.668-6.3428 7.3498-2.3952 16.325-5.7256 22.542-8.2218 4.6667 7.6932 16.747-0.2211 11.511-9.5323-2.8348-6.6507 9.3097 3.2744 6.0035-5.1637 0.07385-9.6565-3.5631-24.6-15.514-21.275-8.8124-2.2018-14.038 3.7449-14.403 12.16-0.11145-6.1411-14.026-3.9235-19.486-1.6966l-1.7889 0.94611-1.2786 1.0118" fill="#fff" fillRule="evenodd" stroke="#000" strokeWidth=".60143" />
                    <path d="m146.78 186.5c3.1401-40.847 23.785-59.45 20.151-92.121-2.1481-19.31-19.537-4.6727-13.869 13.442" fill="none" stroke="#000" strokeWidth=".83349" />
                    <path d="m155.08 242.23c-2.0761 7.5323-6.1937 2.7373-9.8394 3.3013 5.9992 8.5138-18.393 6.7404-11.152 19.87-1.6041 10.165 0.84853 17.472 12.032 19.589 3.6212-4.9036 7.0889-8.9191 7.8989 0.12735 6.4576-1.8798 14.946-12.64 14.075-1.5814 4.382-6.6733 18.254-10.151 18.08-17.339 7.0218 0.73706 14.566 7.3497 20.493-2.1828 5.9508-7.0412 7.27-0.06625 7.3598 5.372 7.2338 6.7565 15.528-3.6235 23.049-5.343-1.9372-8.7117 8.8664-1.5453 7.2827-9.2717-1.0372-5.0602 33.645-3.562 28.657-8.5104-14.906-14.787-38.191 7.4921-41.56 6.0701s-7.0912-0.55957-2.3849-2.7193-6.7425 1.3925-16.277-1.9944-16.514 0.89493-17.151 9.3428c-7.1818-4.4667-7.6963 0.60684-18.885 3.718 7.1295-10.423-12.6-2.7688-12.6-2.7688s3.4373-11.428-2.7394-9.9196c3.3207-5.9642-0.3649-7.8016-0.3649-7.8016s1.8191-5.1215-5.9734 2.0414z" fillRule="evenodd" opacity=".11236" />
                    <path d="m38.042 121.86c-1.3017-2.3183-5.3575-2.9566-5.8794-4.9226 0.90649-2.2834-2.6591-0.62718-3.3617-1.8824-4.1944 3.4336-9.9845 4.9199-13.093 9.6425 1.6528-0.36246 1.6488-2.7786-0.15705-0.99614-3.4011 1.6896-6.6882 4.6577-5.4093 8.8928 0.30978 2.1732-2.4256 4.0433 0.91357 4.558-0.43541 3.4793-5.655 3.3328-5.6581 6.4037 1.1553 3.3495 0.64712 6.8402-2.1007 9.1896-2.718 2.5418-1.5986 6.2831-1.4996 9.3708-1.367 2.0653-2.1364 3.9857-0.33471 5.953 0.38145 3.5482 3.327 5.8976 3.3489 9.5893 1.24 1.9349 3.7267 1.8152 4.9968 4.0018 3.7105 0.3154 3.9827 5.4965 7.5172 6.2151 3.0362 0.99028 6.6286 0.30362 9.3644 1.0298 2.2437 1.819 4.8192 3.0869 7.6439 3.5977 3.5509 2.26 4.7578-3.3648 6.9067-5.176 0.11142-2.832 2.146-3.0523 3.5085-0.66771 3.101 2.1024 6.314-1.5181 8.5372-3.4338 2.1735-2.369 4.6119-4.42 7.0321-6.4972 3.4359-3.8786 4.6558-9.4297 4.4636-14.535 0.34059-3.1993-2.6341-3.3763-4.5905-3.7416 0.88458-2.6823 3.1134-6.5416-1.4325-6.8289-2.607-0.68089-8.5173-3.5936-5.1325-6.662 2.4978-2.9187-0.93976-6.8477-2.4735-9.4842-2.6562-2.1141-4.1995-5.0589-5.8512-7.6301-3.1977-0.50952-6.1943-2.4527-7.0095-5.8451-0.85364-2.694-0.2364-0.69904 0.03297 0.61246" fill="#fff" fillRule="evenodd" stroke="#000" strokeWidth=".8136" />
                    <path d="m11.502 157.46c-1.6013-1.9684 4.7214-1.0412 4.1995-3.0071 0.90649-2.2834-0.17827-2.3785-0.88086-3.6337-4.1944 3.4336-3.2066-4.7009-6.3148 0.02177 1.6528-0.36247 1.381-6.3336-0.42488-4.5512-3.4011 1.6896-2.6805-5.8059-2.6836-2.735 1.1553 3.3495 0.64712 6.8402-2.1007 9.1896-2.718 2.5418-1.5986 6.2831-1.4996 9.3708-1.367 2.0653-2.1364 3.9857-0.33471 5.953 0.38145 3.5482 3.327 5.8976 3.3489 9.5893 1.24 1.9349 3.7267 1.8152 4.9968 4.0018 3.7105 0.3154 3.9827 5.4965 7.5172 6.2151 3.0362 0.99028 6.6286 0.30362 9.3644 1.0298 2.2437 1.819 4.8192 3.0869 7.6439 3.5977 3.5509 2.26 4.7578-3.3648 6.9067-5.176 0.11142-2.832 2.146-3.0523 3.5085-0.66771 3.101 2.1024 6.314-1.5181 8.5372-3.4338 2.1735-2.369-8.346 0.35931-5.9258-1.7178 3.4359-3.8786-7.7525 5.4138-7.9447 0.30847 0.34058-3.1993-12.37 4.0706-9.6785-1.5539 0.88459-2.6823 1.5359-0.74791-3.0101-1.0352-2.607-0.68091-9.8035-5.4936-6.4187-8.5621 2.4978-2.9187-1.3421 0.45637-2.8758-2.1801-2.6562-2.1141-0.95965-0.95001-2.6113-3.5212-3.1977-0.50952-1.7918-1.8166-2.607-5.209-1.3834 3.9801-0.98151-3.6051-0.71214-2.2936z" fillRule="evenodd" opacity=".09551" />
                    <path d="m148.77 167.98s13.145-33.512 16.303-46.882c2.955-12.509 2.5726-31.603-1.8531-35.137s-10.247 2.5783-10.861 9.816-1.1274 9.0616 1.8435 22.867c1.3468 6.2585 0.67416 21.093-1.1569 28.095s-5.8267 22.946-4.2758 21.241z" fillOpacity=".22346" fillRule="evenodd" />
                </svg>
            )}
        </div>
    );
}