import PerformanceReportGroupingType from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportGroupingType";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PerformanceReportIntervalGrouping from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportIntervalGrouping";
import PerformanceReportEnumBasedGrouping from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportEnumBasedGrouping";

export default class PerformanceReportGroupingStore {
    @State.observable public type: PerformanceReportGroupingType;
    @State.observable public isSummaryNeeded: boolean = true;

    @State.action.bound
    public setType(newValue: PerformanceReportGroupingType) {
        this.type = newValue;

        if (newValue === PerformanceReportGroupingType.Interval) {
            this.grouping = new PerformanceReportIntervalGrouping();
        } else {
            this.grouping = new PerformanceReportEnumBasedGrouping();            
            this.grouping.setSelectedColumns([true, true, true]);
            this.grouping.selectedOrdering = 0;
        }
    }

    @State.action.bound
    public setIsSummaryNeeded(newValue: boolean) {
        this.isSummaryNeeded = newValue;
    }

    @State.observable public grouping: PerformanceReportIntervalGrouping | PerformanceReportEnumBasedGrouping;    
}