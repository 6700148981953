/* auto-inject-disable */
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { mapFormFieldDataToProxy, mapProxyFormFieldDataAsync, restoreCustomFormDataStoreAsync } from "@HisPlatform/BoundedContexts/FormEngine/ApplicationLogic/ApiAdapter/FormEngineMappers";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import PatientId from "@Primitives/PatientId.g";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import Form from "@Toolkit/FormEngine/Model/Form";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import { IFormDataStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormDataStore";
import StatusChangeReasonId from "@Primitives/StatusChangeReasonId.g";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import _ from "@HisPlatform/Common/Lodash";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";

@Di.injectable()
export default class AdmitPatientAndCareActivityBaseDataScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("ICareClient") private readonly apiClient: Proxy.ICareClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("IFormEngineReferenceDataStore") private formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("ValidationProblemParameterMapperService") private validationProblemParameterMapperService: ValidationProblemParameterMapperService
    ) {
        super(referenceDataLoader);
    }

    @State.bound
    public getNewAdmitPatientScreenDataAsync(
        patientId: PatientId,
        appointmentId?: AppointmentScheduleEntryId,
        serviceRequestId?: ServiceRequestId,
        organizationId?: OrganizationUnitId) {
        return this.executeOperationAsync<{ form: IForm, extensionData: { [key: string]: any; } }, Proxy.GetNewAdmitPatientScreenDataQueryResponse>(
            () => this.apiClient.getNewAdmitPatientScreenDataQueryAsync(CreateRequestId(), new Proxy.GetNewAdmitPatientScreenDataControllerDto({
                patientId: patientId,
                appointmentScheduleEntryId: appointmentId,
                serviceRequestId: serviceRequestId,
                organizationUnitId: organizationId
            })),
            async response => {
                const form = await this.mapToFormAsync(response.data, response.initialFormDefinitionId, [] as any, response.formLogic);
                return {
                    form: form,
                    extensionData: response.extensionData || {}
                };
            }
        );
    }

    @State.bound
    public resetAdmitPatientScreenDataAsync(
        patientId: PatientId,
        pointOfCareId: PointOfCareId,
        appointmentId?: AppointmentScheduleEntryId,
        serviceRequestId?: ServiceRequestId) {
        return this.executeOperationAsync<IForm, Proxy.GetNewAdmitPatientScreenDataQueryResponse>(
            () => this.apiClient.getNewAdmitPatientScreenDataQueryAsync(CreateRequestId(), new Proxy.GetNewAdmitPatientScreenDataControllerDto({
                patientId: patientId,
                appointmentScheduleEntryId: appointmentId,
                serviceRequestId: serviceRequestId,
                pointOfCareId: pointOfCareId
            })),
            async response => {
                const form = await this.mapToFormAsync(response.data, response.initialFormDefinitionId, [] as any, response.formLogic);
                return form;
            }
        );
    }

    @State.bound
    public getAdmitPatientAScreenDataAsync(careActivityId: CareActivityId, requestLock: boolean) {
        return this.executeOperationAsync<{ form: IForm, extensionData: { [key: string]: any; } }, Proxy.GetAdmitPatientScreenDataCommandResponse>(
            () => this.apiClient.getAdmitPatientScreenDataCommandAsync(CreateRequestId(), new Proxy.GetAdmitPatientScreenDataControllerDto({
                careActivityId: careActivityId,
                requestLock: requestLock
            })),
            async response => {
                const form = await this.mapToFormAsync(response.data, response.formDefinitionId, response.compositeValidationResult, response.formLogic, response.rowVersions);
                return {
                    form: form,
                    extensionData: response.extensionData || {}
                };
            }
        );
    }

    @State.bound
    public getCareActivityBaseDataScreenDataAsync(careActivityId: CareActivityId, requestLock: boolean) {
        return this.executeOperationAsync<{ form: IForm, extensionData: { [key: string]: any; } }, Proxy.GetCareActivityBaseDataScreenDataCommandResponse>(
            () => this.apiClient.getCareActivityBaseDataScreenDataCommandAsync(CreateRequestId(), new Proxy.GetCareActivityBaseDataScreenDataControllerDto({
                careActivityId: careActivityId,
                requestLock: requestLock
            })),
            async response => {
                const form = await this.mapToFormAsync(response.data, response.formDefinitionId, response.compositeValidationResult, response.formLogic, response.rowVersions);
                return {
                    form: form,
                    extensionData: response.extensionData || {}
                };
            }
        );
    }

    @State.bound
    public getAdmitPatientAndCareActivityBaseDataScreenFormDefinitionAsync(pointOfCareId: PointOfCareId) {
        return this.executeOperationAsync<FormDefinitionId, Proxy.GetAdmitPatientAndCareActivityBaseDataScreenFormDefinitionQueryResponse>(
            () => this.apiClient.getAdmitPatientAndCareActivityBaseDataScreenFormDefinitionQueryAsync(CreateRequestId(), new Proxy.GetAdmitPatientAndCareActivityBaseDataScreenFormDefinitionControllerDto({
                pointOfCareId: pointOfCareId
            })),
            response => response.formDefinitionId
        );
    }

    @State.bound
    public admitPatientAsync(form: IForm, isValidateOnly: boolean = false) {
        return this.executeOperationAsync<IForm, Proxy.AdmitPatientCompositeCommandResponse>(
            () => {
                const mappedFormFieldData: Proxy.FormFieldData[] = [];

                mapFormFieldDataToProxy(form.data.Content, mappedFormFieldData as any);

                return this.apiClient.admitPatientCompositeCommandAsync(CreateRequestId(), new Proxy.AdmitPatientCompositeControllerDto({
                    data: mappedFormFieldData,
                    formDefinitionId: form.definitionId,
                    rowVersions: Object.fromEntries(form.rowVersions),
                    isValidateOnly: isValidateOnly
                }));
            },
            async response => {
                const resultForm =
                    await this.mapToFormAsync(response.data, form.definitionId, response.compositeValidationResult, form.formLogic, response.rowVersions, form.data);
                return resultForm;
            }
        );
    }

    @State.bound
    public deleteAdmissionAsync(careActivityId: CareActivityId, statusChangeReasonId: StatusChangeReasonId, additionalText: string) {
        return this.executeOperationAsync<any, Proxy.DeleteAdmissionCommandResponse>(
            () => {
                return this.apiClient.deleteAdmissionCommandAsync(CreateRequestId(), new Proxy.DeleteAdmissionControllerDto({
                    careActivityId: careActivityId,
                    statusChangeReasonId: statusChangeReasonId,
                    additionalText: additionalText,
                }));
            },
            i => {
                return;
            });
    }

    @State.bound
    public updateCareActivityBaseDataAsync(form: IForm, releaseLockIfSuccessful: boolean, lockInfo: LockInfo, isValidateOnly: boolean = false) {
        return this.executeOperationAsync<IForm, Proxy.UpdateCareActivityBaseDataCompositeCommandResponse>(
            () => {
                const mappedFormFieldData: Proxy.FormFieldData[] = [];
                mapFormFieldDataToProxy(form.data.Content, mappedFormFieldData as any);

                return this.apiClient.updateCareActivityBaseDataCompositeCommandAsync(CreateRequestId(), new Proxy.UpdateCareActivityBaseDataCompositeControllerDto({
                    data: mappedFormFieldData,
                    formDefinitionId: form.definitionId,
                    rowVersions: Object.fromEntries(form.rowVersions),
                    releaseLockIfSuccessful: releaseLockIfSuccessful,
                    lockId: lockInfo.lockId,
                    isValidateOnly: isValidateOnly
                }));
            },
            async response => {
                const resultForm =
                    await this.mapToFormAsync(response.data, form.definitionId, response.compositeValidationResult, form.formLogic, response.rowVersions, form.data);
                return resultForm;
            }
        );
    }

    private async mapToFormAsync(data: Proxy.FormFieldData[], definitionId: FormDefinitionId, validationResults: Proxy.CompositeValidationResult, formLogic: string, rowVersions: { [key: string]: RowVersion } = null, existingData: IFormDataStore = null) {
        const definition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(definitionId);

        const mappedValidationResults = mapValidationResults(validationResults as unknown as IServerCompositeValidationResult);

        mappedValidationResults.forEach(i => {
            i.problems.forEach((j: IClientValidationProblem) => {
                this.validationProblemParameterMapperService.resolveValidationProblemParameters("AdmitPatientAndCareActivityBaseDataScreenApiAdapter", j);
            });
        });

        const form = new Form(
            FormInstanceId.new,
            RowVersion.initial,
            definitionId,
            existingData ?? await restoreCustomFormDataStoreAsync(data as any, definition, this.formEngineReferenceDataStore),
            mappedValidationResults,
            null,
            null,
            formLogic);

        if (!isNullOrUndefined(rowVersions)) {
            form.rowVersions = new Map(Object.entries(rowVersions));
        }

        return form;
    }
}