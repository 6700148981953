import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import CareActivityBasedWorklistEntityType from "@Primitives/CareActivityBasedWorklistEntityType";
import Icon from "@CommonControls/Icon";
import CareActivityBasedWorklistEntityTypeSelectBox from "./CareActivityBasedWorklistEntityTypeSelectBox";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";

interface ICareActivityBasedWorklistEntityTypeColumnDependencies {
    localizationService: ILocalizationService;
}

interface ICareActivityBasedWorklistEntityTypeColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: ICareActivityBasedWorklistEntityTypeColumnDependencies;
}


const CareActivityBasedWorklistEntityTypeColumn: React.FC<ICareActivityBasedWorklistEntityTypeColumnProps> = props => {

    const { children, ...columnProps } = props;

    const valueRenderer = useCallback((value: []) => {
        return (
            <>
                {
                    value.map((i, idx) => {
                        switch (i) {
                            case 1:
                                return (<Icon iconName="healthcare" key={idx * 100 + i} />);
                            case 2:
                                return (<Icon iconName="appointments" key={idx * 100 + i} />);
                            case 3:
                                return (<Icon iconName="stethoscope" key={idx * 100 + i} />);
                            default:
                                return null;
                        }
                    })
                }
            </>
        );
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<CareActivityBasedWorklistEntityType | CareActivityBasedWorklistEntityType[]>) => {
        return (
            <CareActivityBasedWorklistEntityTypeSelectBox
                {...filterProps}
                displayMode="selectBox"
                multiSelect
                maxMenuWidth={commonMenuMaxWidth}
                hoverOnlyIndicatorIcons
            />
        );
    }, []);

    return (
        <DataGridColumn
            width={60}
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
            showHint={false}
        />
    );
};

const filterValueSerializer: IDataGridFilterValueSerializer<CareActivityBasedWorklistEntityType | CareActivityBasedWorklistEntityType[]> = {
    serialize: value => JSON.stringify(value),
    deserialize: value => JSON.parse(value)
};

export default connect(
    CareActivityBasedWorklistEntityTypeColumn,
    new DependencyAdapter<ICareActivityBasedWorklistEntityTypeColumnProps, ICareActivityBasedWorklistEntityTypeColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
