import React, { useContext } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Flex from "@CommonControls/Flex";
import MasterDetailMaster, { IMasterDetailMasterProps } from "@CommonControls/Layout/MasterDetailMaster";
import MasterDetailDetail, { IMasterDetailDetailProps } from "@CommonControls/Layout/MasterDetailDetail";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IMasterDetailState from "@CommonControls/Layout/IMasterDetailState";
import MasterDetailDetailTab from "@CommonControls/Layout/MasterDetailDetailTab";
import { getHashValue } from "@Toolkit/CommonWeb/EqualityHelper";
import { IMasterDetailContext, MasterDetailContext } from "@CommonControls/Layout/MasterDetailContext";

interface IMasterDetailLayoutProps {
    children?: React.ReactNode;
    isLoading?: boolean;
    isMasterVisible?: boolean;

    selectedItem?: any;
    onSelectedItemChange?: (item: any) => void;
    onClose?: () => void;

    hasTabs?: boolean;
    initiallyActiveTabName?: string;
    activeTabName?: string;
    onActiveTabNameChange?: (activeTabName: string) => void;

    isSeparatedMode?: boolean;

    showCompactModeSwitcher?: boolean;
    isCompact?: boolean;
    defaultIsCompact?: boolean;
    onCompactModeChange?: (isCompact: boolean) => void;
    compactListSize?: string | number;
    showDetailCloseButton?: boolean;

    master?: React.ReactNode;
    detail?: React.ReactNode;

    style?: React.CSSProperties;
    className?: string;
    hideDetailHeader?: boolean;
    noInnerSpacing?: boolean;
}

@State.observer
class _MasterDetailLayout extends React.Component<IMasterDetailLayoutProps & { parentContext: IMasterDetailContext | null }> {

    public static defaultProps: Partial<IMasterDetailLayoutProps> = {
        isMasterVisible: true,
        defaultIsCompact: false
    };

    @State.observable.ref private _isCompact = !this.props.isSeparatedMode && this.getMasterDetailLevel() > 0;

    @State.computed private get isDetailOpen() {
        return !isNullOrUndefined(this.props.selectedItem);
    }

    @State.computed private get isCompact() {
        return this.props.isCompact === undefined ? this._isCompact : this.props.isCompact;
    }

    private getMasterDetailLevel(): number {
        let current = this.props.parentContext;
        let result = 0;

        while (!!current) {
            if (current.state.isMasterVisible) {
                result++;
            }
            current = current.parent;
        }

        return result;
    }

    @State.computed private get isMasterVisible() {
        if (this.props.isSeparatedMode) {
            return !this.isDetailOpen;
        }
        return this.props.isMasterVisible;
    }

    @State.computed private get showDetailCloseButton() {
        return this.props.showDetailCloseButton === undefined || this.props.showDetailCloseButton === null
            ? this.isDetailOpen || this.props.isSeparatedMode
            : this.props.showDetailCloseButton;
    }

    @State.observable.ref private _internalActiveTabName = this.props.initiallyActiveTabName;
    @State.computed private get activeTabName() {
        return this.props.activeTabName ?? this._internalActiveTabName;
    }

    @State.computed private get masterDetailState(): IMasterDetailState {
        return {
            showCompactModeSwitcher: this.props.showCompactModeSwitcher ?? false,
            isCompact: this.isCompact,
            isDetailOpen: this.isDetailOpen,
            isMasterVisible: this.isMasterVisible,
            showDetailCloseButton: this.showDetailCloseButton,
            onSelectedItemChange: this.selectItem,
            selectedItem: this.props.selectedItem,
            hasTabs: !isNullOrUndefined(this.props.activeTabName) || this.props.hasTabs,
            activeTabName: this.activeTabName,
            onActiveTabNameChange: this.setActiveTab,
            onExpand: this.expand,
            onLessen: this.lessen,
            hideDetailHeader: this.props.hideDetailHeader
        };
    }

    @State.computed private get masterDetailContext(): IMasterDetailContext {
        return {
            parent: this.props.parentContext,
            state: this.masterDetailState,
            compactListSize: this.props.compactListSize ?? "calc(100% - 90px)",
            detailChildren: this.isCompact && this.isDetailOpen && (this.props.detail ?? React.Children.map(this.props.children, this.renderDetailChild))
        };
    }

    public render() {
        return (
            <>
                <MasterDetailContext.Provider value={this.masterDetailContext}>
                    <Flex
                        innerSpacing={this.props.noInnerSpacing ? 0 : 8}
                        verticalSpacing={0}
                        className={this.props.className}
                        style={{ height: "100%", position: "relative", ...this.props.style }}
                    >

                        <Flex.Item xs={this.isDetailOpen ? (this.isCompact ? 12 : 4) : 12} style={{ display: this.isMasterVisible ? "block" : "none" }}>
                            {this.props.master ?? React.Children.map(this.props.children, this.renderMasterChild)}
                        </Flex.Item>
                        {this.isDetailOpen && !this.isCompact && (
                            <Flex.Item xs={this.isMasterVisible ? 8 : 12} key={getHashValue(this.props.selectedItem)}>
                                {this.props.detail ? this.renderDetail() : React.Children.map(this.props.children, this.renderDetailChild)}
                            </Flex.Item>
                        )}
                    </Flex>
                </MasterDetailContext.Provider>
            </>
        );
    }

    @State.bound
    private renderMasterChild(child: React.ReactElement) {
        if (child?.type === MasterDetailMaster) {
            return child && React.cloneElement<IMasterDetailMasterProps>(child);
        }
        return null;
    }

    private renderDetail() {
        if (this.isDetailOpen) {
            return this.props.detail;
        }
        return null;
    }

    @State.bound
    private renderDetailChild(child: React.ReactElement) {
        if ((child?.type !== MasterDetailMaster) || child?.type === MasterDetailDetail) {
            return child && React.cloneElement<IMasterDetailDetailProps>(child);
        }
        return null;
    }

    @State.bound
    private expand() {
        this._isCompact = false;
        this.props.onCompactModeChange?.(false);
    }

    @State.bound
    private lessen() {
        this._isCompact = true;
        this.props.onCompactModeChange?.(true);
    }

    @State.bound
    private selectItem(item: any) {
        if (isNullOrUndefined(item)) {
            this.props.onClose?.();
        }
        this.props.onSelectedItemChange?.(item);
    }

    @State.action.bound
    private setActiveTab(name: string) {
        this._internalActiveTabName = name;
        this.props.onActiveTabNameChange?.(name);
    }
}


export const MasterDetail = {
    Master: MasterDetailMaster,
    Detail: MasterDetailDetail,
    Tab: MasterDetailDetailTab
};

export default function MasterDetailLayout(props: IMasterDetailLayoutProps) {
    const parentContext = useContext(MasterDetailContext) ?? null;

    return (
        <_MasterDetailLayout {...props} parentContext={parentContext} />
    );
}
