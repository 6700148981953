import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import GenderId from "@Primitives/GenderId.g";
import GenderSelectBox from "@HisPlatform/BoundedContexts/CommonReferenceData/Components/Controls/GenderSelectBox";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";

interface IGenderColumnDependencies {
    commonReferenceDataStore: CommonReferenceDataDataStore;
}

interface IGenderColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IGenderColumnDependencies;
}


const GenderColumn: React.SFC<IGenderColumnProps> = (props: IGenderColumnProps) => {

    const valueRenderer = useCallback((value: GenderId) => {
        const item = isNullOrUndefined(value) ? null : props._dependencies.commonReferenceDataStore.gender.get(value);

        if (isNullOrUndefined(item)) {
            return null;
        }

        return item.displayValue.Shorthand;
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<GenderId>) => {
        return <GenderSelectBox {...filterProps} maxMenuWidth={commonMenuMaxWidth} hoverOnlyIndicatorIcons />;
    }, []);

    return (
        <DataGridColumn
            width={50}
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
        />
    );
};

const filterValueSerializer: IDataGridFilterValueSerializer<GenderId> = {
    deserialize: (value: string) => {
        if (!value) {
            return null;
        }
        
        return new GenderId(value);
    },
    serialize: (value: GenderId) => {
        return value.value;
    }
};

export default connect(
    GenderColumn,
    new DependencyAdapter<IGenderColumnProps, IGenderColumnDependencies>(c => ({
        commonReferenceDataStore: c.resolve("CommonReferenceDataDataStore"),
    }))
);