import React from "react";
import AppointmentSchedulePanelView from "./AppointmentSchedulePanelView";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import SlotStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SlotStore";
import { IRowCheckState } from "@CommonControls/DataGrid/IDataGridProps";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import AppointmentSchedulerEvent from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduler/AppointmentSchedulerEvent";
import moment from "moment";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ServiceProviderProfileApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ServiceProviderProfileApiAdapter";
import AppointmentScheduleSlotSeries from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentSchedule";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import _ from "@HisPlatform/Common/Lodash";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { ISchedulerEvent } from "@CommonControls/Scheduler/ISchedulerProps";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import PatientApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/PatientRegister/Patient/PatientApiAdapter";
import AppointmentSchedulerEntryTooltip from "@HisPlatform/BoundedContexts/Scheduling/Components/Controls/AppointmentSchedulerEntryTooltip/AppointmentSchedulerEntryTooltip";
import { formatReactString } from "@Toolkit/ReactClient/Common/LocalizedStrings";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import AppointmentFilterStore from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/DoctorPointOfCareListPanel/AppointmentFilterStore";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import ModalServiceAdapter from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAdapter";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import BookSlotDialogParams, { IBookSlotDialogResult } from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/BookAppointmentDialog/BookSlotDialogParams";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import BookSlotDialogSettings from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/BookAppointmentDialog/BookSlotDialogSettings";
import { IAppointmentSubjectStore } from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/IAppointmentSubjectStore";
import EventHandler from "@Toolkit/ReactClient/Components/EventHandler/EventHandler";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import NameStore from "@Primitives/NameStore";
import ServiceRequestSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ServiceRequestSubjectStore";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import InvalidEvent from "@CommonControls/Scheduler/InvalidEvent";
import ConfigurationDynamicPropertiesApiAdapter from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/ApiAdapter/DynamicProperties/ConfigurationDynamicPropertiesApiAdapter";
import SlotStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/SlotStatus.g";
import config from "@Config";
import { hasFlags } from "@Toolkit/CommonWeb/EnumHelpers";
import AppointmentParticipantOptions from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/AppointmentParticipantOptions.g";

interface IAppointmentSchedulePanelDependencies {
    schedulingApiAdapter: SchedulingApiAdapter;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    localizationService: ILocalizationService;
    dialogService: IDialogService;
    notificationService: INotificationService;
    dateTimeFormatProvider: IDateTimeFormatProvider;
    configurationDynamicPropertiesApiAdapter: ConfigurationDynamicPropertiesApiAdapter;
}

interface IAppointmentSchedulePanelProps {
    _dependencies?: IAppointmentSchedulePanelDependencies;
    _modalService?: IModalService;
    _patientName?: NameStore;
    appointment: Appointment;
    subjectService: IAppointmentSubjectStore;
    readOnly?: boolean;
    referralReadOnly?: boolean;
    onRenderCalendar?: (calendar: React.ReactNode) => React.ReactNode;
    onSetSelectedService: (id: SchedulingServiceId) => void;
    onSaveAsync: (appointment: Appointment) => Promise<boolean>;
    onValidateAsync?: () => Promise<IClientValidationResult[]>;
    proposeAfterDate?: boolean;
}

@State.observer
class AppointmentSchedulePanel extends React.Component<IAppointmentSchedulePanelProps> {

    @State.observable public showFirstSlotMessage = true;
    @State.observable.ref private dataSource = new InMemoryDataGridDataSource(() => this.availableSlots);
    @State.observable.ref public selectedMomentValue: moment.Moment = null;
    @State.observable private displayedMoment: moment.Moment = DateTimeService.now().startOf("month");
    @State.observable.ref private availableSchedules: AppointmentScheduleSlotSeries[] = [];
    @State.observable.ref private appointmentFilterStore: AppointmentFilterStore = new AppointmentFilterStore();
    @State.observable.ref private allSlots: SlotStore[] = [];
    @State.observable.ref private availableSlots: SlotStore[] = [];
    @State.observable.ref private slotsToBook: SlotStore[] = [];
    @State.observable.ref private filtersChangedEvent = new TypedAsyncEvent<void>();

    @State.observable private isLoading: boolean = false;

    @State.observable private isTelemedicineConsultationFeatureEnabled: boolean = false;

    @State.computed
    private get appointment() {
        return this.props.appointment;
    }

    @State.computed
    private get subjectService() {
        return this.props.subjectService;
    }

    private get schedulingApiAdapter() {
        return this.props._dependencies.schedulingApiAdapter;
    }

    private get modalService() {
        return this.props._modalService;
    }

    private get schedules() {
        return this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleSlotSeries.items;
    }

    private get dateTimeFormatProvider() { return this.props._dependencies.dateTimeFormatProvider; }

    private get localizedPatientName() {
        return this.props._patientName ?
            this.props._dependencies.localizationService.localizePersonName(this.props._patientName) : "";
    }

    @State.computed
    private get serviceDuration() {
        return this.subjectService?.durationInMinutes;
    }

    @State.computed
    private get serviceRequestDefinitionId() {
        if (this.subjectService instanceof ServiceRequestSubjectStore) {
            return this.subjectService.definitionId;
        }

        return null;
    }

    @State.computed
    private get isTelemedicineConsultation() {
        if (!!this.props.appointment.schedulingServiceId) {
            const schedulingService = this.props._dependencies.schedulingReferenceDataStore.schedulingServices.get(this.props.appointment.schedulingServiceId);
            return this.isTelemedicineConsultationFeatureEnabled && schedulingService.isTelemedicineConsultation;
        }

        return false;
    }

    @State.computed
    private get isAdditionalParticipantsAllowed() {
        if (!this.props.appointment.schedulingServiceId) {
            return false;
        }

        const schedulingService = this.props._dependencies.schedulingReferenceDataStore.schedulingServices.get(this.props.appointment.schedulingServiceId);
        return this.isTelemedicineConsultationFeatureEnabled
               && hasFlags(schedulingService.participantOptions, AppointmentParticipantOptions.AdditionalParticipantsAllowed);
    }

    @State.action.bound
    private setAvailableSlots() {
        const usedSlots: SlotStore[] = [];
        const slotMap = this.allSlots.reduce((map, slot) => {
            const key = slot.interval;
            if (this.isCurrentSlotEntry(slot) || usedSlots.some(u => u.interval === key)) {
                usedSlots.push(slot);
                if (map.has(key)) {
                    map.delete(key);
                }
                return map;
            }

            const sameObj = map.get(key);
            if (sameObj && sameObj.status === SlotStatus.Free) {
                return map;
            }

            map.set(key, slot);
            return map;
        }, new Map<string, SlotStore>());

        const filteredSlots = [...slotMap.values()];

        if (usedSlots.length) {
            const mergedSlot = new SlotStore();

            mergedSlot.id = usedSlots[0].id;
            mergedSlot.from = usedSlots[0].from;
            mergedSlot.to = usedSlots[usedSlots.length - 1].to;
            mergedSlot.status = SlotStatus.Busy;
            mergedSlot.appointmentScheduleSlotSeriesId = usedSlots[0].appointmentScheduleSlotSeriesId;

            filteredSlots.push(mergedSlot);
        }

        this.availableSlots = filteredSlots;
    }

    @State.computed
    private get schedulerEvents() {
        return this.availableSlots.map((s) => {
            return new AppointmentSchedulerEvent(parseInt(s.id.value, 10), s.from, s.to, s.status);
        });
    }

    @State.computed
    private get schedulerInvalidEvents() {
        return !!this.appointment.isInvalid
            ? [this.appointment].map(a => new InvalidEvent(parseInt(a.id.value, 10), a.intervalFrom, a.intervalTo))
            : [] as InvalidEvent[];
    }

    @State.computed
    private get firstAvailableSlots() {
        let res: SlotStore[] = [];
        this.availableSchedules.forEach(schedule => {
            const startTime = !!this.props.proposeAfterDate ? this.appointment.intervalFrom : DateTimeService.now();
            const sortedSlots = schedule.firstAvailableSlotsForDuration(startTime, this.serviceDuration);
            if (!sortedSlots.length) {
                return;
            }

            if (!res.length) {
                res.push(...sortedSlots);
            } else {
                if (res[0].from.isAfter(sortedSlots[0].from)) {
                    res = sortedSlots;
                }
            }
        });
        return res;
    }

    @State.computed private get freeSlotAvailable() {
        return !!this.firstAvailableSlots.length;
    }

    private selectedMoment = State.computed(() => this.selectedMomentValue ?? (this.freeSlotAvailable ? this.firstAvailableSlots[0].from : DateTimeService.now()), { keepAlive: true });

    private onSelectedMomentChangedDisposer: any = null;

    @State.computed
    private get firstFreeSlotStart() {
        return this.freeSlotAvailable ? this.firstAvailableSlots[0].from : null;
    }

    @State.computed
    private get firstSlotDisplayText() {
        const text = formatReactString(StaticSchedulingResources.AppointmentSchedulePanel.FirstAvailableSlotMessage,
            this.formattedServiceName,
            this.formattedFirstAvailableDate);
        return (
            <>{text}</>
        );
    }

    @State.computed
    private get formattedFirstAvailableDate() {
        return (
            <b data-automation-id="_firstAvailableSlotDate">
                {this.firstFreeSlotStart?.format(this.dateTimeFormatProvider.getDateTimeWithoutSecondsFormat()) ?? StaticSchedulingResources.AppointmentSchedulePanel.NoFreeSlotAvailableMessage}
            </b>
        );
    }

    @State.computed
    private get formattedServiceName() {
        return (
            <><b>{this.subjectService?.code ?? ""}</b> {this.subjectService?.name ?? ""}</>
        );
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.initializeAsync);

    public componentDidMount() {
        this.initializeFilterStore();
        this.onSelectedMomentChangedDisposer = State.observe(this.selectedMoment, (change) => {
            if (!change.oldValue?.isSame(change.newValue, "month")) {
                dispatchAsyncErrors(this.setSchedulesAndSlotsAsync(), this);
            }
        });
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentWillUnmount() {
        this.onSelectedMomentChangedDisposer?.();
    }

    @State.action.bound
    private async initializeAsync() {
        this.setLoadingState(true);

        if (this.appointment.usedSlotIds?.length) {
            this.dismissFirstFreeSlotMessage();
        }
        this.setInitialMoment();

        await this.loadAllSchedulesAsync();
        await this.setSchedulesAndSlotsAsync();
        this.setLoadingState(false);

        State.runInAction(() => {
            this.isTelemedicineConsultationFeatureEnabled = config.features.telemedicineConsultationEnabled;
        });
    }

    @State.action.bound
    private async loadAllSchedulesAsync() {
        const dataStore = this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleSlotSeries;
        await dataStore.ensureAllLoadedAsync();
    }

    public componentDidUpdate(prevProps: IAppointmentSchedulePanelProps) {
        if (!ValueWrapper.equals(this.appointment.id, prevProps.appointment.id)) {
            this.initializeFilterStore();
            dispatchAsyncErrors(this.initializeAsync(), this);
        }
        if (!!this.appointment.intervalFrom && !this.appointment.intervalFrom?.isSame(prevProps.appointment.intervalFrom)) {
            this.dismissFirstFreeSlotMessage();
            this.setInitialMoment();
        }
    }

    @State.action.bound
    private initializeFilterStore() {
        if (this.appointment.isInvalid) {
            this.appointmentFilterStore.setPointOfCareIds(null);
            this.appointmentFilterStore.setDoctorIds(null);
            this.appointmentFilterStore.setSchedulingServiceId(null);
            this.appointment.setAppointmentScheduleId(null);
        } else {
            if (!!this.appointment.pointOfCareId) {
                this.appointmentFilterStore.setPointOfCareIds([this.appointment.pointOfCareId]);
            } else {
                this.appointmentFilterStore.setPointOfCareIds(null);
            }

            if (this.appointment.practitionerIds.length) {
                this.appointmentFilterStore.setDoctorIds(this.appointment.practitionerIds);
            } else {
                this.appointmentFilterStore.setDoctorIds(null);
            }

            this.appointmentFilterStore.setSchedulingServiceId(this.props.appointment.schedulingServiceId);
        }

        this.allSlots = [];
        this.availableSlots = [];
    }

    @State.action.bound
    private setInitialMoment() {
        if (this.appointment.intervalFrom) {
            this.selectedMomentValue = this.appointment.intervalFrom;
        }
    }

    @State.action.bound
    private dismissFirstFreeSlotMessage() {
        this.showFirstSlotMessage = false;
    }

    private getAvailableSchedules() {
        if (isNullOrUndefined(this.props.appointment.schedulingServiceId) &&
            isNullOrUndefined(this.serviceRequestDefinitionId)) {
            return [];
        } else {
            return this.schedules.filter(s =>
                (arrayIsNullOrEmpty(this.appointmentFilterStore.doctorIds) || this.hasSomeSelectedPractitionersForSchedule(s)) &&
                (arrayIsNullOrEmpty(this.appointmentFilterStore.pointOfCareIds) || this.hasSomeSelectedPointsOfCareForSchedule(s))
            );
        }
    }

    @State.action.bound
    private async getSlotsAsync(availableSchedules: AppointmentScheduleSlotSeries[]) {
        const serviceId = !!this.subjectService
            ? (this.subjectService instanceof ServiceRequestSubjectStore
                ? (this.subjectService as ServiceRequestSubjectStore).definitionId
                : this.props.appointment.schedulingServiceId)
            : this.props.appointment.schedulingServiceId;

        if (arrayIsNullOrEmpty(availableSchedules)) {
            return [] as SlotStore[];
        } else {
            const displayed = this.displayedMoment;
            const selected = this.selectedMoment.get() ?? DateTimeService.now();
            const momentToLoad = displayed > selected ? displayed.clone() : selected.clone();

            const currentMonth = LocalDate.createFromMoment(DateTimeService.now().startOf("month"));
            const selectedMonth = LocalDate.createFromMoment(momentToLoad.add(1, "month").endOf("month"));
            const dateRange = new LocalDateRange(currentMonth.lessThan(selectedMonth) ? currentMonth : selectedMonth, selectedMonth.greaterThan(currentMonth) ? selectedMonth : currentMonth);
            const slots = await this.schedulingApiAdapter.getSlots(
                this.appointmentFilterStore,
                dateRange,
                serviceId
            );

            return slots.value;
        }
    }

    @State.action.bound
    private addSlotsToSchedules(schedules: AppointmentScheduleSlotSeries[], allSlots: SlotStore[]) {
        if (!arrayIsNullOrEmpty(allSlots)) {
            const groupedSlots = _.groupBy(allSlots, (x => x.appointmentScheduleSlotSeriesId.value));

            schedules.forEach(s => {
                const slotsForSchedule = groupedSlots[parseInt(s.id.value, 10)];
                if (isNullOrUndefined(slotsForSchedule) || arrayIsNullOrEmpty(slotsForSchedule)) {
                    s.sortedSlots = [] as SlotStore[];
                } else {
                    s.sortedSlots = slotsForSchedule.sort((a: SlotStore, b: SlotStore) => {
                        return a.from.unix() - b.from.unix();
                    });
                }
            });
        }
    }

    @State.bound
    private getDialogSettings(isEdit?: boolean) {
        return new BookSlotDialogSettings(
            this.subjectService,
            this.props.readOnly,
            this.props.referralReadOnly,
            this.appointment.invalidationReason,
            isEdit
        );
    }

    private hasSomeSelectedPractitionersForSchedule(schedule: AppointmentScheduleSlotSeries) {
        return !!_.intersectionWith(this.appointmentFilterStore.doctorIds, schedule.practitionerParticipants, ValueWrapper.equals).length;
    }

    private hasSomeSelectedPointsOfCareForSchedule(schedule: AppointmentScheduleSlotSeries) {
        return !!_.intersectionWith(this.appointmentFilterStore.pointOfCareIds, schedule.locationParticipants, ValueWrapper.equals).length;
    }

    @State.action.bound
    private async bookFirstAvailableAsync() {
        if (this.freeSlotAvailable) {
            await this.bookSlotsAsync(this.firstAvailableSlots[0].from, this.firstAvailableSlots[this.firstAvailableSlots.length - 1].to);
        }
    }

    @State.bound
    private rowCheckState(row: SlotStore): IRowCheckState {

        const isSlotSelectedToBook = this.appointment.slotsToBook.some(i => ValueWrapper.equals(i.id, row.id));
        const isChecked = isSlotSelectedToBook || this.appointment.usedSlotIds.some(i => ValueWrapper.equals(i, row.id));

        const isDisabled = row.status === SlotStatus.Busy || !isSlotSelectedToBook && row.status === SlotStatus.BookingInProgress;

        return { isChecked: isChecked, isVisible: true, isDisabled: isDisabled } as IRowCheckState;
    }

    @State.action.bound
    private selectRow(isChecked: boolean, row: SlotStore) {
        if (isChecked) {
            dispatchAsyncErrors(this.bookSlotsAsync(row.from, row.to), this);
        } else {
            dispatchAsyncErrors(this.cancelSlotBookingAsync([row]), this);
        }
    }

    @State.action.bound
    private async cancelSlotBookingAsync(slotsToCancel: SlotStore[]) {
        const filteredSlots = this.appointment.slotsToBook.filter(item => !slotsToCancel.some(s => ValueWrapper.equals(s.id, item.id)));
        this.appointment.setSlotsToBook(filteredSlots);
        await this.schedulingApiAdapter.cancelSlotBookingAsync(this.appointment, slotsToCancel);
    }

    @State.action.bound
    public async bookSlotsAsync(start: moment.Moment, end: moment.Moment) {
        if (this.props.readOnly) {
            return;
        }
        let availableSlots: SlotStore[] = [];
        const appointmentSchedules = this.availableSchedules.filter(s => {
            const slots = s.bookableSlotsForDate(start, end);
            if (slots.length) {
                availableSlots.push(...slots);
                return true;
            }
            return false;
        });

        let hasError = false;

        if (this.props.onValidateAsync) {
            const validationResults = await this.props.onValidateAsync();
            hasError = validationResults.some(x => x.problems.some(
                y => y.severity === "error" &&
                    y.message.includes("Participants.")));
        }

        if (hasError) {
            this.props._dependencies.notificationService.showCannotSaveBecauseOfErrors();
            return;
        }

        // book all available slots
        const res = await this.schedulingApiAdapter.bookSlots(this.appointment, availableSlots);

        if (res === null) {
            return;
        }

        availableSlots = res.value;

        const dialogSettings = this.getDialogSettings();

        const dialogResult = await this.modalService.showDialogAsync<IBookSlotDialogResult>(new BookSlotDialogParams(
            start,
            end,
            this.appointment,
            dialogSettings,
            appointmentSchedules,
            this.isTelemedicineConsultation,
            this.isAdditionalParticipantsAllowed
        ));

        if (dialogResult.isBooked) {
            // release slots that are not booked
            const slotsToCancel: SlotStore[] = [];
            const slotsToBook: SlotStore[] = [];

            availableSlots.forEach(s => {
                if (ValueWrapper.equals(
                    s.appointmentScheduleSlotSeriesId,
                    dialogResult.updatedAppointment.appointmentScheduleSlotSeriesId)) {
                    slotsToBook.push(s);
                } else {
                    slotsToCancel.push(s);
                }
            });

            if (slotsToCancel.length) {
                await this.schedulingApiAdapter.cancelSlotBookingAsync(this.appointment, slotsToCancel);
            }

            // release previously booked slots
            if (this.appointment.slotsToBook.length) {
                // handle version mismatch, allSlots will contain the newest after loading
                const allSlots = await this.getSlotsAsync(this.availableSchedules);
                const bookedSlotsToCancel = allSlots.filter(slot => this.appointment.slotsToBook.some(s => ValueWrapper.equals(s.id, slot.id)));

                await this.schedulingApiAdapter.cancelSlotBookingAsync(this.appointment, bookedSlotsToCancel);
            }

            dialogResult.updatedAppointment.setSlotsToBook(slotsToBook);
            dialogResult.updatedAppointment.setIntervalFrom(slotsToBook[0].from);
            dialogResult.updatedAppointment.setIntervalTo(slotsToBook[slotsToBook.length - 1].to);
            dialogResult.updatedAppointment.usedSlotIds = slotsToBook.map(s => s.id);

            State.runInAction(() => {
                this.slotsToBook = slotsToBook;
            });

            const saveResult = await this.props.onSaveAsync(dialogResult.updatedAppointment);
            if (!saveResult) {
                await this.releaseAllSlotsAndReloadAsync(availableSlots);
            }

            if (!dialogResult.updatedAppointment.isNew) {
                await this.setSchedulesAndSlotsAsync();
            }
        } else {
            await this.releaseAllSlotsAndReloadAsync(availableSlots);
        }
    }

    @State.action.bound
    private async releaseAllSlotsAndReloadAsync(availableSlots: SlotStore[]) {
        // release all booked slots
        await this.schedulingApiAdapter.cancelSlotBookingAsync(this.appointment, availableSlots);
        // updates available slots (row versions must be updated)
        await this.setSchedulesAndSlotsAsync();
    }

    @State.bound
    private async editAppointmentAsync(event: ISchedulerEvent) {
        const appointmentSchedules = this.availableSchedules.filter(s => s.sortedSlots.some(item => item.id.value === event.id.toString()));

        const dialogSettings = this.getDialogSettings(true);

        const result = await this.modalService.showDialogAsync<IBookSlotDialogResult>(new BookSlotDialogParams(
            this.appointment.intervalFrom,
            this.appointment.intervalTo,
            this.props.appointment,
            dialogSettings,
            appointmentSchedules,
            this.isTelemedicineConsultation,
            this.isAdditionalParticipantsAllowed));

        if (result.isBooked) {
            const saveResult = await this.props.onSaveAsync(result.updatedAppointment);
            if (!saveResult) {

                await this.releaseAllSlotsAndReloadAsync(result.updatedAppointment.slotsToBook);
                return;
            }
       
            await this.loadSlotsAndAddToSchedulesAsync(this.availableSchedules);
        }
    }

    @State.action.bound
    private isAppointmentPlaceable(event: ISchedulerEvent) {
        const endTime = this.getAppointmentEndTime(event);

        if (this.isCurrentSchedulerEntry(event)) {
            if (endTime.diff(event.endTime) > 0) {
                const currentSchedule = this.availableSchedules.find(s => ValueWrapper.equals(s.id, this.appointment.appointmentScheduleSlotSeriesId));

                return currentSchedule?.canBookSlotsForDate(event.endTime, endTime) === true ||
                    this.availableSchedules.some(schedule => schedule.canBookSlotsForDate(event.startTime, endTime) === true);
            }
            return true;
        }
        return this.availableSchedules.some(schedule => schedule.canBookSlotsForDate(event.startTime, endTime) === true);
    }

    private getAppointmentEndTime(event: ISchedulerEvent) {
        return isNullOrUndefined(this.serviceDuration)
            ? event.endTime
            : event.startTime.clone().add(this.serviceDuration, "minutes");
    }

    @State.bound
    private handleSlotVersionMismatchError() {
        dispatchAsyncErrors(this.showErrorDialogAndReloadAsync(
            StaticSchedulingResources.BusinessErrors.SlotVersionMismatchDialogTitle,
            StaticSchedulingResources.BusinessErrors.SlotVersionMismatchDialogMessage), this);
        return true;
    }

    @State.bound
    private handleSlotNotFoundError() {
        dispatchAsyncErrors(this.showErrorDialogAndReloadAsync(
            StaticSchedulingResources.BusinessErrors.SlotNotFoundErrorDialogTitle,
            StaticSchedulingResources.BusinessErrors.SlotNotFoundErrorDialogMessage), this);
        return true;
    }

    @State.bound
    private handleTelecommunicationCallCreationError() {
        dispatchAsyncErrors(this.handleTelecommunicationCallCreationErrorAsync(), this);
        return true;
    }

    @State.bound
    private async handleTelecommunicationCallCreationErrorAsync() {
        await this.schedulingApiAdapter.cancelSlotBookingAsync(this.appointment, this.slotsToBook);
        this.props._dependencies.notificationService.showCannotSaveBecauseOfErrors(StaticSchedulingResources.BusinessErrors.TelecommunicationCallCreationErrorMessage);
        return true;
    }

    @State.bound
    private isCurrentSchedulerEntry(event: ISchedulerEvent) {
        return this.isCurrentEntry(event.id);
    }

    @State.bound
    private isCurrentSlotEntry(slot: SlotStore) {
        return this.isCurrentEntry(parseInt(slot.id.value, 10));
    }

    @State.bound
    private isCurrentEntry(id: number) {
        if (this.appointment.isInvalid) {
            return false;
        }
        return this.appointment.slotsToBook.some(s => (s.id.value as any as number) === id) ||
            this.appointment.usedSlotIds.some(i => i.value === id.toString());
    }

    @State.action.bound
    private placeAppointment(event: ISchedulerEvent) {
        if (this.isCurrentEntry(event.id)) {
            dispatchAsyncErrors(this.editAppointmentAsync(event), this);
        } else {
            const endTime = this.getAppointmentEndTime(event);
            dispatchAsyncErrors(this.bookSlotsAsync(event.startTime, endTime), this);
        }
    }

    @State.action.bound
    private setSelectedMoment(value: moment.Moment) {
        if (value.isSame(this.selectedMoment.get(), "day")) {
            this.selectedMomentValue = value;
            return;
        }

        const firstSlotOnSelectedDay = this.allSlots.find(s => s.from.isSame(value, "day"));

        if (firstSlotOnSelectedDay) {
            this.selectedMomentValue = firstSlotOnSelectedDay.from;
        } else {
            this.selectedMomentValue = value;
        }
    }

    @State.action.bound
    private setDisplayedMoment(value: LocalDate) {
        const previousMoment = this.displayedMoment.clone();
        this.displayedMoment = value.toUtcDayStartMoment();
        if (!value.toUtcDayStartMoment().isSame(previousMoment, "month")) {
            this.filtersChangedEvent.emitAsync();
        }
    }

    @State.action.bound
    private setLoadingState(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    @State.bound
    private currentEntryTooltip() {
        return (
            <AppointmentSchedulerEntryTooltip
                patientName={this.localizedPatientName}
                appointmentInterval={this.appointment.interval}
                description={this.appointment.description}
                pointOfCareId={this.appointment.pointOfCareId}
                practitionerIds={this.appointment.practitionerIds}
                schedulingServiceId={this.appointment.schedulingServiceId}
            />
        );
    }

    @State.action.bound
    private async setSchedulesAndSlotsAsync() {
        this.setLoadingState(true);
        this.emptyScheduleSlots();
        this.props.onSetSelectedService(this.appointmentFilterStore.schedulingServiceId);
        const schedules = this.getAvailableSchedules();

        if (schedules.length) {
            await this.loadSlotsAndAddToSchedulesAsync(schedules);

            State.runInAction(() => {
                this.availableSchedules = schedules;
            });
        }

        this.setLoadingState(false);
    }

    @State.action.bound
    private emptyScheduleSlots() {
        this.allSlots = [];
        this.availableSlots = [];
        this.slotsToBook = [];
    }

    @State.action.bound
    private async loadSlotsAndAddToSchedulesAsync(schedules: AppointmentScheduleSlotSeries[]) {
        const allSlots = await this.getSlotsAsync(schedules);
        State.runInAction(() => {
            this.allSlots = allSlots;
            this.addSlotsToSchedules(schedules, allSlots);
        });
        this.setAvailableSlots();
    }

    @State.bound
    private async showErrorDialogAndReloadAsync(title: string, message: string) {
        const dialogResult = await this.props._dependencies.dialogService.ok(title, message);

        if (dialogResult.resultCode === DialogResultCode.Ok) {
            await this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleSlotSeries.reloadAsync();
            await this.setSchedulesAndSlotsAsync();

        }
    }

    public render() {
        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }

        return (
            <>
                <EventHandler event={this.filtersChangedEvent} onFiredAsync={this.setSchedulesAndSlotsAsync} />
                <BusinessErrorHandler.Register businessErrorName="SlotVersionMismatchError" handler={this.handleSlotVersionMismatchError} />
                <BusinessErrorHandler.Register businessErrorName="SlotNotFoundError" handler={this.handleSlotNotFoundError} />
                <BusinessErrorHandler.Register businessErrorName="TelecommunicationCallCreationError" handler={this.handleTelecommunicationCallCreationError} />
                <AppointmentSchedulePanelView
                    firstSlotDisplayText={this.firstSlotDisplayText}
                    freeSlotAvailable={this.freeSlotAvailable}

                    showFirstSlotMessage={this.showFirstSlotMessage}
                    bookFirstAsync={this.bookFirstAvailableAsync}
                    dismissFirstFreeSlotMessage={this.dismissFirstFreeSlotMessage}

                    dataSource={this.dataSource}
                    rowCheckState={this.rowCheckState}
                    selectRow={this.selectRow}

                    events={this.schedulerEvents}
                    invalidEvents={this.schedulerInvalidEvents}
                    isAppointmentPlaceable={this.isAppointmentPlaceable}
                    placeAppointment={this.placeAppointment}
                    isCurrentEntry={this.isCurrentSchedulerEntry}

                    selectedMoment={this.selectedMoment.get()}
                    setSelectedMoment={this.setSelectedMoment}
                    newEventDuration={this.serviceDuration}
                    onSelectedMonthChange={this.setDisplayedMoment}

                    onRenderCurrentEntryTooltip={this.currentEntryTooltip}
                    readonly={this.props.readOnly}

                    onRenderCalendar={this.props.onRenderCalendar}

                    appointmentFilterStore={this.appointmentFilterStore}
                    filtersChangedEvent={this.filtersChangedEvent}
                    serviceRequestDefinitionId={this.serviceRequestDefinitionId}
                    isTelemedicineConsultation={this.isTelemedicineConsultation}
                    invalidationInfo={this.appointment.invalidationInfo}
                    invalidationReason={this.appointment.invalidationReason}
                />
            </>
        );
    }
}

export default connect(
    AppointmentSchedulePanel,
    new DependencyAdapter<IAppointmentSchedulePanelProps, IAppointmentSchedulePanelDependencies>(c => ({
        schedulingApiAdapter: c.resolve("SchedulingApiAdapter"),
        serviceProviderProfileApiAdapter: c.resolve("ServiceProviderProfileApiAdapter"),
        schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService"),
        patientApiAdapter: c.resolve("PatientApiAdapter"),
        dialogService: c.resolve("IDialogService"),
        notificationService: c.resolve("INotificationService"),
        dateTimeFormatProvider: c.resolve("IDateTimeFormatProvider"),
        configurationDynamicPropertiesApiAdapter: c.resolve("ConfigurationDynamicPropertiesApiAdapter")
    })),
    new ModalServiceAdapter(),
    new PatientContextAdapter<IAppointmentSchedulePanelProps>(c => ({
        _patientName: c.patient?.baseData?.name
    })),
);