import React, { useCallback } from "react";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import CheckBox from "@CommonControls/CheckBox";
import TextBox from "@CommonControls/TextBox";

interface IDataGridStringColumnProps extends IDataGridColumnBaseProps {
    nullValue?: string;
    clearable?: boolean;
}


const DataGridStringColumn: React.FC<IDataGridStringColumnProps> = props => {

    const { children, ...columnProps } = props;

    const renderCellValue = useCallback((value: any, row: any, rowId: any, rowIndex: number, isUnderEditing: boolean, dataSetter: (newValue: any) => void) => {
        if (isUnderEditing) {
            const automationId = `textBox_column_${props.id}_row_${rowId}`;
            return (
                <TextBox
                    automationId={automationId}
                    value={value}
                    onChange={dataSetter}
                    propertyIdentifier={props.propertyIdentifierFactory(value, row, rowId, rowIndex, isUnderEditing)} />
            );
        } else {
            return value;
        }
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<boolean | null>) => {
        return <CheckBox {...filterProps} canBeNull />;
    }, []);

    return (
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={renderCellValue}
            onRenderFilter={filterRenderer}
            showHint={false}
        />
    );
};

export default DataGridStringColumn;