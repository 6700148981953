import ICareActivityTextBlockListItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/ICareActivityTextBlockListItem";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import AddTextBlockDialogParams, { IAddTextBlockDialogResult } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/AddTextBlockDialog/AddTextBlockDialogParams";
import Di from "@Di";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";
import PatientId from "@Primitives/PatientId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import ShowCareActivityTextBlockListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityTextBlockListScreenAction.g";
import ShowCareActivityTextBlockScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityTextBlockScreenAction.g";
import { ICareActivityTextBlockListPanelScreenProps } from "@HisPlatform/Packages/Care/Screens/CareActivityTextBlockListPanelScreen/CareActivityTextBlockListPanelScreen";
import CareActivityTextBlockListPanelScreenApiAdapter from "./CareActivityTextBlockListPanelScreenApiAdapter";

@Di.injectable()
export default class CareActivityTextBlockListPanelScreenStore extends EditorScreenPanelStoreBase<ICareActivityTextBlockListPanelScreenProps> implements ILoadablePanelStore {
    @State.observable public idList: ICareActivityTextBlockListItem[] = [];
    @State.observable public mandatoryTextBlockTypes: TextBlockTypeId[] = [];

    public readonly reloadScreenDataAsync: () => Promise<void> = this.async(async () => {
        const screenData = await this.apiAdapter.getCareActivityTextBlockListPanelScreenDataAsync(this.careActivityId);
        this.setIdList(screenData.result.textBlockListItems);
        this.setMandatoryTextBlockTypes(screenData.result.mandatoryTextBlockTypeIds);
    });

    @State.computed
    public get selectedItem(): ICareActivityTextBlockListItem {
        if (this.textBlockTypeId) {
            return this.idList.find(i => ValueWrapper.equals(i.textBlockTypeId, this.textBlockTypeId));
        } else {
            return null;
        }
    }

    @State.computed
    protected get contentToDirtyCheck(): any[] {
        return [];
    }

    protected saveFunction: () => Promise<boolean> = () => Promise.resolve(true);

    @State.computed
    protected get showScreenAction(): ShowCareActivityTextBlockListScreenAction {
        return this.props.action;
    }

    @State.computed
    private get careActivityId(): CareActivityId {
        return this.props.action.careActivityId;
    }

    @State.computed
    private get patientId(): PatientId {
        return this.props._patientId;
    }

    @State.computed
    private get pointOfCareId(): PointOfCareId {
        return this.props.action.pointOfCareId;
    }

    @State.computed
    private get textBlockTypeId(): TextBlockTypeId {
        return this.props.action.textBlockTypeId;
    }

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("CareActivityTextBlockListPanelScreenApiAdapter") public readonly apiAdapter: CareActivityTextBlockListPanelScreenApiAdapter,
        @Di.inject("CareReferenceDataStore") public readonly careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("IModalService") public readonly modalService: IModalService,
        @Di.inject("UserManagementDataProviderStore") public readonly userManagementReferenceDataStore: UserManagementDataProviderStore) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    @State.bound
    public async addTextBlockAsync(): Promise<void> {
        const dialogResult = await this.modalService.showDialogAsync<IAddTextBlockDialogResult>(new AddTextBlockDialogParams(this.idList.map(i => i.textBlockTypeId)));
        if (dialogResult?.textBlockTypeId) {
            const newItem = {
                careActivityTextBlockId: null,
                textBlockTypeId: dialogResult.textBlockTypeId
            } as ICareActivityTextBlockListItem;
            this.addTextBlockItem(newItem);
            await this.openDetailView(newItem);
        }
    }

    public async loadCoreAsync(): Promise<any> {
        await this.loadReferenceDataAsync();
        await this.reloadScreenDataAsync();
        return true;
    }

    @State.bound
    public openDetailView(item: ICareActivityTextBlockListItem): Promise<void> {
        return this.props._actionDispatcher.dispatchAsync(new ShowCareActivityTextBlockScreenAction(
            ScreenDisplayMode.Detail,
            this.pointOfCareId,
            this.patientId,
            this.careActivityId,
            item.textBlockTypeId
        ));
    }

    @State.bound
    public tryRemoveSelectedItem(): void {
        const selectedItem = this.selectedItem;
        if (isNullOrUndefined(selectedItem)
            || !isNullOrUndefined(selectedItem.careActivityTextBlockId)
            || this.mandatoryTextBlockTypes.some(i => ValueWrapper.equals(i, selectedItem.textBlockTypeId))) {
            return;
        }

        this.setIdList(this.idList.filter(i => i === selectedItem));
    }

    @State.action.bound
    private addTextBlockItem(item: ICareActivityTextBlockListItem): void {
        this.idList.push(item);
    }

    @State.bound
    private async loadReferenceDataAsync(): Promise<void> {
        await this.careReferenceDataStore.textBlockType.ensureLoadedAsync();
        await this.userManagementReferenceDataStore.users.ensureAllLoadedAsync();
    }

    @State.action.bound
    private setIdList(items: ICareActivityTextBlockListItem[]): void {
        this.idList = items;
    }

    @State.action.bound
    private setMandatoryTextBlockTypes(items: TextBlockTypeId[]): void {
        this.mandatoryTextBlockTypes = items;
    }
}