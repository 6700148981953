import React from "react";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";

interface ICultureMenuDependencies {
    applicationContext: ApplicationContext;
}

interface ICultureMenuProps {
    _dependencies?: ICultureMenuDependencies;
}

class CultureMenu extends React.Component<ICultureMenuProps> {

    private get applicationContext() { return this.props._dependencies.applicationContext; }
    
    @State.bound
    private switchToHungarian() {
        this.applicationContext.setCurrentCulture("hu-HU");
    }

    @State.bound
    private switchToEnglish() {
        this.applicationContext.setCurrentCulture("en-US");
    }

    @State.bound
    private switchToSlovak() {
        this.applicationContext.setCurrentCulture("sk-SK");
    }

    @State.bound
    private switchToCzech() {
        this.applicationContext.setCurrentCulture("cs-CZ");
    }

    public render() {
        return (
            <Ui.NavBar.Item hasNestedItems automationId="cultureCodeContext" content={<Ui.Icon iconName="language" visualStyle="white" />}>
                <Ui.NavBar.Item onClick={this.switchToHungarian} automationId="switchToHungarian">{StaticWebAppResources.Navbar.Languages.Hungarian}</Ui.NavBar.Item>
                <Ui.NavBar.Item onClick={this.switchToEnglish} automationId="switchToEnglish">{StaticWebAppResources.Navbar.Languages.English}</Ui.NavBar.Item>
                <Ui.NavBar.Item onClick={this.switchToSlovak} automationId="switchToSlovak">{StaticWebAppResources.Navbar.Languages.Slovak}</Ui.NavBar.Item>
                <Ui.NavBar.Item onClick={this.switchToCzech} automationId="switchToCzech">{StaticWebAppResources.Navbar.Languages.Czech}</Ui.NavBar.Item>
            </Ui.NavBar.Item>
        );
    }
}

export default connect(
    CultureMenu,
    new DependencyAdapter<ICultureMenuProps, ICultureMenuDependencies>(c => ({
        applicationContext: c.resolve("ApplicationContext")
    }))
);
