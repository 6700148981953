import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiEReferralList from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiEReferralListPanel/Master/EhiEReferralList";
import React from "react";
import EhiErrorSummary from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiErrorSummary";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import EhiEReferralDetailPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralDetailDialog/EhiEReferralDetailPanel";
import IEhiError from "@HunEHealthInfrastructurePlugin/Common/Model/IEhiError";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import EhiEReferralListQuerySettingsStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralListQuerySettingsStore";
import CareActivityEReferralStore from "@HunEHealthInfrastructurePlugin/Model/CareActivityEReferralStore";
import * as Ui from "@CommonControls";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";

interface IEhiEReferralListPanelViewProps {
    ehiErrors?: IEhiError[];
    dataSource?: InMemoryDataGridDataSource<any, any>;
    selectedEReferral?: CareActivityEReferralStore;
    isLoading?: boolean;
    backToPreviousPage?: () => void;
    onSelectEReferral?: (row: CareActivityEReferralStore) => void;
    querySettingsStore?: EhiEReferralListQuerySettingsStore;
    onQueryAsync?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>;
    queryDisabled?: boolean;
    
    localizationService: ILocalizationService;
}


const EhiEReferralListPanelView: React.FC<IEhiEReferralListPanelViewProps> = (props) => {
    return (
        <SingleLayout>
            <MasterDetailLayout
                selectedItem={props.selectedEReferral?.ehiEReferralId?.value}
                onSelectedItemChange={props.onSelectEReferral}
                isLoading={props.isLoading}
            >
                <MasterDetail.Master
                    title={StaticHunEHealthInfrastructureCareResources.EReferralListPanel.List.Title}
                    toolbar={
                        <Ui.Button
                            disabled={props.queryDisabled}
                            text={StaticHunEHealthInfrastructureCareResources.EReferralListPanel.Query.Button}
                            iconName="search"
                            visualStyle="primary"
                            onClickAsync={props.onQueryAsync}
                            automationId="queryButton"
                        />
                    }
                >
                    <EhiEReferralList
                        dataSource={props.dataSource}
                        selectedEReferral={props.selectedEReferral}
                        isLoading={props.isLoading}
                        onBack={props.backToPreviousPage}
                        showDetail={props.onSelectEReferral}
                        querySettingsStore={props.querySettingsStore}
                        localizationService={props.localizationService}
                        ehiErrors={props.ehiErrors}

                    />
                </MasterDetail.Master>
                <MasterDetail.Detail
                    hasSidePadding
                    title={StaticHunEHealthInfrastructureCareResources.EReferralListPanel.Detail.Title}
                >
                    <EhiEReferralDetailPanel
                        careActivityEReferralStore={props.selectedEReferral}
                    />
                </MasterDetail.Detail>
            </MasterDetailLayout>
        </SingleLayout>
    );
};

export default State.observer(EhiEReferralListPanelView);
