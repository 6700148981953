import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalConditionHistoryItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemBase";
import TextualObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/TextualObservation";

export default class PatientBloodTypeHistoryItem extends MedicalConditionHistoryItemBase {   
    @State.observable.ref public textualObservation: TextualObservation = null;     
      
    @State.action.bound
    public setTextualObservation(newValue: TextualObservation) {
        this.textualObservation = newValue;
    }

    @State.computed public get textualObservationValue() {        
        return this.textualObservation?.contentStore.getContent();
    }
}