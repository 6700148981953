import Di from "@Di";
import { buildQueryString } from "@Toolkit/CommonWeb/QueryStringBuilder";
import config from "@Config";

const tokenUrl = `${window.location.protocol}//${window.location.host}/icardio/${config.cardioIntegration.tokenIssuerAddress}`;

@Di.injectable()
export default class ICardioFrameApiAdapter {
    public async getICardioAuthTokenAsync(username: string, name: string, stampCode: string): Promise<string> {
        const data = buildQueryString({
            grant_type: "appKey",
            appKey: config.cardioIntegration.appKey,
            username,
            name,
            stampCode,
        });

        const response = await fetch(tokenUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: data
        });
        const body = await response.json();
        
        return body.access_token;
    }
}