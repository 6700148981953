import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import PermissionDefinitionBuilder from "@PluginInterface/OperationAccessControl/PermissionDefinitionBuilder";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";
import { HunEHealthInfrastructurePermissionDefinitions } from "@HunEHealthInfrastructurePlugin/PermissionDefinitions/HunEHealthInfrastructurePermissionDefinitions.g";
import { PermissionDefinitions } from "@HisPlatform/PermissionDefinitions/PermissionDefinitions.g";

export default function configureMedicationRequestPermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry) {
    permissionDefinitionRegistry.register(PermissionDefinitionBuilder.create(HunEHealthInfrastructurePermissionDefinitions.MedicationRequest.Prescription.EditTemplates).build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder
            .create(HunEHealthInfrastructurePermissionDefinitions.MedicationRequest.PractitionerRecommendation.View)
            .withPermissionScope(
                PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare)
            )
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder
            .create(HunEHealthInfrastructurePermissionDefinitions.MedicationRequest.PractitionerRecommendation.Edit)
            .withPermissionScope(
                PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare)
            )
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder
            .create(HunEHealthInfrastructurePermissionDefinitions.MedicationRequest.Prescription.ViewPatientPrescriptions)
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder
            .create(HunEHealthInfrastructurePermissionDefinitions.MedicationRequest.Prescription.ViewCareActivityPrescriptions)
            .withPermissionScope(
                PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare)
            )
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(HunEHealthInfrastructurePermissionDefinitions.MedicationRequest.Prescription.EditPrescriptions)
            .withPermissionScopes(
                PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare),
                PermissionDefinitionBuilder.createPositivePermissionScope(
                    PermissionScopeType.level,
                    ["Basic"])
            )
            .withAlias("Basic", PermissionScopeType.level)
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(HunEHealthInfrastructurePermissionDefinitions.MedicationRequest.Prescription.EditPrescriptions)
            .withPermissionScopes(
                PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare),
                PermissionDefinitionBuilder.createPositivePermissionScope(
                    PermissionScopeType.level,
                    ["Restricted"])
            )
            .withAlias("Restricted", PermissionScopeType.level)
            .build());

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(HunEHealthInfrastructurePermissionDefinitions.MedicationRequest.FamilyDoctorDocument.View, PermissionDefinitions.Productivity.Worklist.ViewWorklists)
            .withPermissionScopesFor(
                PermissionDefinitions.Productivity.Worklist.ViewWorklists,
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType, ["FamilyDoctorDocumentBased"])
            )
            .withAlias(null, PermissionScopeType.worklistType)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(HunEHealthInfrastructurePermissionDefinitions.MedicationRequest.MedicationRequest.ViewPermanentPrescriptions, PermissionDefinitions.Productivity.Worklist.ViewWorklists)
            .withPermissionScopesFor(
                PermissionDefinitions.Productivity.Worklist.ViewWorklists,
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType, ["PermanentPrescriptionBased"])
            )
            .withAlias(null, PermissionScopeType.worklistType)
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(HunEHealthInfrastructurePermissionDefinitions.MedicationRequest.FamilyDoctorDocument.Edit)
            .withPermissionScope(
                PermissionDefinitionBuilder.createWillBeBoundPermissionScope(PermissionScopeType.pointOfCare)
            )
            .build());
}