import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class LoadingContextStore {
    private readonly loadingStates = State.createObservableShallowMap<string, boolean>();

    @State.computed public get isLoading() {
        return Array.from(this.loadingStates.values()).some(Boolean);
    }

    @State.action.bound
    public setLoadingState(key: string) {
        this.loadingStates.set(key, true);
    }

    @State.action.bound
    public clearLoadingState(key: string) {
        this.loadingStates.delete(key);
    }

    @State.action.bound
    public setLoadingStateWithClear() {
        const key = `AutoKey_${index++}`;
        this.setLoadingState(key);
        return () => this.clearLoadingState(key);
    }
}

let index = 0;