import ContagiousPatientReportType from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReportType";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

export default class ContagiousPatientReportOnsetDateWithTypeData {

    public readonly type: ContagiousPatientReportType;
    public readonly onsetDate?: LocalDate;
    constructor(
        type: number,
        onsetDate?: any,
    ) {
        this.type = type;

        if (onsetDate) {
            this.onsetDate = LocalDate.create(onsetDate.Year, onsetDate.Month, onsetDate.Day);
        }
    }
}