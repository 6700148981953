import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class VaccineDetail {
    @State.observable.ref public brand: string;
    @State.observable.ref public serialNumber: string;

    constructor(brand: string, serialNumber: string) {
        this.brand = brand;
        this.serialNumber = serialNumber;
    }

    @State.action.bound
    public setBrand(newValue: string) {
        this.brand = newValue;
    }

    @State.action.bound
    public setSerialNumber(newValue: string) {
        this.serialNumber = newValue;
    }
}
