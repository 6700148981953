import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/DocumentApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { IModalComponentParams, IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import * as HisUi from "@HisPlatformControls";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import IDocumentHistoryItem from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/IDocumentHistoryItem";
import UserColumn from "@HisPlatformControls/DataGridColumns/UserColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import _ from "@HisPlatform/Common/Lodash";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import MasterDetailLayout from "@CommonControls/Layout/MasterDetailLayout";
import MasterDetailMaster from "@CommonControls/Layout/MasterDetailMaster";
import MasterDetailDetail from "@CommonControls/Layout/MasterDetailDetail";
import DocumentBase from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentBase";
import TemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TemplateBasedDocument";
import BinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/BinaryDocument";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import DocumentFile from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentFile";
import { ValidMimeTypesForImageAndPdfViewer } from "@CommonControls/ImageViewer/ImageViewer";
import BinaryDocumentViewer from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Documents/BinaryDocumentViewer/BinaryDocumentViewer";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import CareActivityDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentApiAdapter";
import DocumentAction from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentAction.g";
import DocumentState from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentState.g";

interface IDocumentHistoryModalDependencies {
    apiAdapter: DocumentApiAdapter;
    careDocumentApiAdapter: CareActivityDocumentApiAdapter;
    localizationService: ILocalizationService;
}

export class DocumentHistoryModalParams implements IDocumentHistoryModalParams {
    public static type = "DocumentHistoryModal";
    public get type() { return DocumentHistoryModalParams.type; }

    constructor(
        public readonly document: DocumentBase
    ) { }
}

interface IDocumentHistoryModalParams extends IModalParams {
    document: DocumentBase;
}

interface IDocumentHistoryModalProps extends IModalComponentParams, IDocumentHistoryModalParams {
    _dependencies?: IDocumentHistoryModalDependencies;
}

/** @screen */

@State.observer
class DocumentHistoryModal extends React.Component<IDocumentHistoryModalProps> {

    //#region deps
    private get localizationService() { return this.props._dependencies.localizationService; }
    private get apiAdapter() { return this.props._dependencies.apiAdapter; }
    private get careActivityApiAdapter() { return this.props._dependencies.careDocumentApiAdapter; }
    //#endregion

    @State.observable private isLoading: boolean = false;
    @State.observable private isDetailLoading: boolean = false;

    @State.observable.ref private history: IDocumentHistoryItem[] = [];
    @State.observable.ref private pagedHistory: InMemoryDataGridDataSource<IDocumentHistoryItem> = new InMemoryDataGridDataSource<IDocumentHistoryItem>(() => this.history, { columnId: "createdAt", direction: "desc" });
    @State.observable.ref private binaryContent: Blob = null;
    @State.observable.ref private templatedContent: DocumentFile = null;

    @State.observable.ref private selectedVersion: IDocumentHistoryItem = null;

    @State.action.bound
    private setSelectedVersion(version: IDocumentHistoryItem) {
        this.selectedVersion = version;
    }

    @State.action.bound
    private setContents(binary: Blob, templated: DocumentFile) {
        this.binaryContent = binary;
        this.templatedContent = templated;
    }

    @State.action.bound
    private setHistory(history: IDocumentHistoryItem[]) {
        this.history = history;
    }

    @State.boundLoadingState("isLoading")
    private async loadAsync() {
        if (this.props.document?.id) {
            const history = await this.apiAdapter.getDocumentHistoryAsync(this.props.document.id);
            this.setHistory(history.value);
        }
    }

    @State.boundLoadingState("isDetailLoading")
    private async loadVersionAsync(version: IDocumentHistoryItem) {
        const largeData = await this.careActivityApiAdapter.getCareDocumentContentById(this.props.document.id, version.contentId);
        let binaryContent: Blob = null;
        let templatedContent: DocumentFile = null;
        if (this.props.document instanceof BinaryDocument) {
            binaryContent = new Blob([Base64Converter.toByteArray(largeData.value.content)], { type: largeData.value.mediaType });
        } else if (this.props.document instanceof TemplateBasedDocument) {
            templatedContent = DocumentFile.create(largeData.value.content);
        }
        this.setContents(binaryContent, templatedContent);
    }

    @State.bound
    private cancel() {
        this.props.onClose(null);
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IDocumentHistoryModalProps) {
        if (this.props.document?.id !== prevProps.document?.id) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.bound
    public renderDocumentAction(value: DocumentAction) {
        if (isNullOrUndefined(value)) {
            return "";
        }
        return this.localizationService.localizeEnum(DocumentAction[value], "DocumentAction").Name;
    }

    @State.bound
    public renderDocumentState(value: DocumentState) {
        if (isNullOrUndefined(value)) {
            return "";
        }
        return this.localizationService.localizeEnum(DocumentState[value], "DocumentState").Name;
    }

    @State.bound
    public onRowSelected(historyItem: IDocumentHistoryItem) {
        dispatchAsyncErrors(this.loadVersionAsync(historyItem), this);
        this.setSelectedVersion(historyItem);
    }

    @State.bound
    private onOpenClickedFactory(row: IDocumentHistoryItem) {
        return () => {
            this.onRowSelected(row);
        };
    }

    @State.bound
    private renderActions(value: any, row: IDocumentHistoryItem) {
        return <Ui.Button size="compact" onClick={this.onOpenClickedFactory(row)} iconName="angleRight" automationId={row.documentVersion.toString() + "_openButton"} />;
    }

    public render() {

        return (
            <HisUi.ContextAwareModal isOpen onClose={this.cancel} fixedHeight={800} size="fullscreen">
                <Ui.Modal.Body>
                    <MasterDetailLayout selectedItem={this.selectedVersion} onSelectedItemChange={this.setSelectedVersion}>
                        <MasterDetailMaster title={StaticDocumentManagementResources.DocumentHistoryModal.Title} subTitle={this.props.document?.info?.name}>
                            <Ui.DataGrid dataSource={this.pagedHistory} fixedLayout onSelectedRowChange={this.onRowSelected} selectOnRowClick selectedRow={this.selectedVersion} isSelectable isLoading={this.isLoading} changeOnMount rowHeight={35} actionsColumn automationId="documentHistoryModal_dataGrid">
                                <DataGridColumn
                                    id="version"
                                    dataGetter={"documentVersion"}
                                    isOrderable
                                    clientSideOrderableValueGetter={"documentVersion"}
                                    header={StaticDocumentManagementResources.DocumentHistoryModal.Columns.Version}
                                    isVisible={!this.selectedVersion} />
                                <DataGridDateTimeColumn
                                    id="createdAt"
                                    dataGetter={"createdAt"}
                                    isOrderable
                                    clientSideOrderableValueGetter={"createdAt"}
                                    header={StaticDocumentManagementResources.DocumentHistoryModal.Columns.CreatedAt} />
                                <DataGridColumn
                                    dataGetter={"documentAction"}
                                    onRenderCellValue={this.renderDocumentAction}
                                    header={StaticDocumentManagementResources.DocumentHistoryModal.Columns.DocumentAction} />
                                <DataGridColumn
                                    dataGetter={"documentState"}
                                    onRenderCellValue={this.renderDocumentState}
                                    header={StaticDocumentManagementResources.DocumentHistoryModal.Columns.DocumentState} />
                                <UserColumn
                                    dataGetter={"createdBy"}
                                    header={StaticDocumentManagementResources.DocumentHistoryModal.Columns.User}
                                    isVisible={!this.selectedVersion} />
                                <DataGridColumn onRenderCellValue={this.renderActions} width={45} />
                            </Ui.DataGrid>
                        </MasterDetailMaster>
                        <MasterDetailDetail title={this.selectedVersion?.createdAt ? this.localizationService.localizeDateTime(this.selectedVersion.createdAt, true) : StaticDocumentManagementResources.DocumentHistoryModal.Content} hasSidePadding={false} hasVerticalPadding={false}>
                            {!this.isDetailLoading ?
                                <>
                                    {this.props.document instanceof BinaryDocument && this.props.document.content.type !== ValidMimeTypesForImageAndPdfViewer.Pdf && this.binaryContent && (
                                        <Ui.ImageViewer alt={StaticDocumentManagementResources.DocumentManagementMasterDetailPanel.AttachedImage.Alt} data={this.binaryContent} />
                                    )}
                                    {this.props.document instanceof BinaryDocument && this.props.document.content.type === ValidMimeTypesForImageAndPdfViewer.Pdf && this.binaryContent && (
                                        <BinaryDocumentViewer documentPath={this.props.document.largeDataToken} downloadFileName={this.props.document.downloadFileName} />
                                    )}
                                    {this.props.document instanceof TemplateBasedDocument && this.templatedContent && (
                                        <HisUi.HisDocumentEditor mode="Document" contentStore={this.templatedContent.contentStore.forEditor} isReadOnly />
                                    )}
                                </> : <></>}
                        </MasterDetailDetail>
                    </MasterDetailLayout>
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Button float="right" text={StaticWebAppResources.Common.Button.Close} onClick={this.props.onClose} visualStyle="primary" automationId="closeDocumentHistoryModalButton" />
                </Ui.Modal.Footer>
            </HisUi.ContextAwareModal>
        );
    }
}

export default connect(
    DocumentHistoryModal,
    new DependencyAdapter<IDocumentHistoryModalProps, IDocumentHistoryModalDependencies>(c => ({
        apiAdapter: c.resolve("DocumentApiAdapter"),
        localizationService: c.resolve("ILocalizationService"),
        careDocumentApiAdapter: c.resolve("CareActivityDocumentApiAdapter")
    }))
);