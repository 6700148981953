import State from "@Toolkit/ReactClient/Common/StateManaging";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import CareActivitySource from "@Primitives/CareActivitySource.g";
import CareTypeId from "@Primitives/CareTypeId.g";
import { VoidSyncEvent } from "ts-events";
import _ from "@HisPlatform/Common/Lodash";
import IEhiPatientsCareActivitiesListExtensionData from "./IEhiPatientsCareActivitiesListExtensionData";
import IPatientsCareActivitiesFilterStore from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientsCareActivitiesFilterStore";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";

export default class EhiPatientsCareActivitiesFilterStore implements IPatientsCareActivitiesFilterStore {

    public onChangeDebounced = new VoidSyncEvent();

    @State.observable.ref public pointOfCareIds: PointOfCareId[] = [];
    @State.observable.ref public practitionerIds: PractitionerId[] = null;
    @State.observable.ref public dateRange: LocalDateRange = new LocalDateRange();
    @State.observable.ref public careActivitySource: CareActivitySource = new CareActivitySource("InHouse");
    @State.observable.ref public careTypeIds: CareTypeId[] = [];
    @State.observable public careIdentifier: string = "";
    @State.observable.ref public careActivityStates: CareActivityState[] = [];
    @State.observable.ref public episodeOfCareIds: EpisodeOfCareId[] = null;

    @State.observable public isEmergency: boolean = false;
    @State.observable public emergencyReason: string = "";

    private get isAutoReloadEnabled() {
        return this.careActivitySource && this.careActivitySource.value === "InHouse";
    }

    @State.action.bound
    public setPointOfCareIds(newValue: PointOfCareId[]) {
        this.pointOfCareIds = newValue;
        this.debounceChange();
    }

    @State.action.bound
    public setEpisodeOfCareIds(value: EpisodeOfCareId[]) {
        this.episodeOfCareIds = value;
        this.debounceChange();
    }

    @State.action.bound
    public setPractitionerId(newValue: PractitionerId[]) {
        this.practitionerIds = newValue;
        this.debounceChange();
    }

    @State.action.bound
    public setDateRange(newValue: LocalDateRange) {
        this.dateRange = newValue;
        this.debounceChange();
    }

    @State.action.bound
    public setCareActivitySource(newvalue: CareActivitySource) {
        this.careActivitySource = newvalue;
        this.debounceChange();
    }

    @State.action.bound
    public setCareTypeIds(newvalue: CareTypeId[]) {
        this.careTypeIds = newvalue;
        this.debounceChange();
    }

    @State.action.bound
    public setCareIdentifier(newvalue: string) {
        this.careIdentifier = newvalue;
        this.debounceChange();
    }

    @State.action.bound
    public setCareActivityStates(newValue: CareActivityState[]) {
        this.careActivityStates = newValue;
        this.debounceChange();
    }

    @State.action.bound
    public setIsEmergency(isEmergency: boolean) {
        this.isEmergency = isEmergency;
    }

    @State.action.bound
    public setEmergencyReason(newValue: string) {
        this.emergencyReason = newValue;
    }

    @State.action.bound
    public __reset() {
        this.careIdentifier = "";
        this.careActivitySource = CareActivitySource.InHouse;
        this.careTypeIds = [];
        this.pointOfCareIds = [];
        this.practitionerIds = null;
        this.dateRange = null;
        this.careActivityStates = [];
        this.episodeOfCareIds = null;
        this.debounceChange();
    }

    public getExtensionData(): IEhiPatientsCareActivitiesListExtensionData {
        return {
            EmergencyAccess: {
                IsEmergency: this.isEmergency,
                EmergencyReason: this.emergencyReason
            }
        };
    }

    private debounceChange = _.debounce(() => {
        if (this.isAutoReloadEnabled) {
            this.onChangeDebounced.post();
        }
    }, 1000);
}
