import html2canvas from "html2canvas";
import IScreenshotService from "@HisPlatform/Services/Definition/ScreenshotService/IScreenshotService";
import Di from "@Di";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

@Di.injectable()
export default class ScreenshotService implements IScreenshotService {

    private lastScreenshot: File;

    public takeScreenshotAsync(): Promise<File> {
        return new Promise((resolve: (value: File) => void, reject: (reason: any) => void) => {
            html2canvas(document.body, {
                logging: false
            }).then(canvas => {
                canvas.toBlob(blob => {
                    // Extended ISO 8601 format (that includes colons) causes attachment to be
                    // displayed invalid in Jira hence basic format is ought to be used.
                    // 'Z' is appended separately as it would add an '+' character to the string.
                    const fileDateTime = `${DateTimeService.now().format("YYYYMMDDTHHmmss")}Z`;
                    this.lastScreenshot = blobToFile(blob, `screenshot-${fileDateTime}.jpg`);
                    resolve(this.lastScreenshot);
                }, "image/jpeg", 0.98);
            }).catch(err => reject(err));
        });
    }

    public getLastScreenshot(): File {
        return this.lastScreenshot;
    }
}

const blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    b.lastModifiedDate = DateTimeService.now().toDate();
    b.name = fileName;
    return theBlob as File;
};