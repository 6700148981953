import DependencyContainer from "@DiContainer";
import * as ConfigurationProxy from "@HisPlatform/BoundedContexts/Configuration/Api/Proxy.g";

DependencyContainer
    .bind("ConfigurationProxy.IDynamicPropertiesClient")
    .to(ConfigurationProxy.DynamicPropertiesClient).inSingletonScope();

DependencyContainer
    .bind("IStatusClient")
    .to(ConfigurationProxy.StatusClient)
    .inSingletonScope();
