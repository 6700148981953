import React from "react";
import * as Ui from "@CommonControls";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiEReferralCovidData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralCovidData";
import EhiEReferralCovidPreviousResultSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralCovidPreviousResultSelectBox";

interface IPreviousResultViewProps {
    store: EhiEReferralCovidData;
}


const PreviousResultView: React.FC<IPreviousResultViewProps> = (props: IPreviousResultViewProps) => {
    if (!props.store) {
        return null;
    }
    return (
        <Ui.GroupBox title={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.PreviousResultTitle}>
            <Ui.Flex>
                <Ui.Flex.Item sm={4}>
                    <Ui.CheckBox value={props.store.isReExamination}
                        onChange={props.store.setIsReExamination}
                        label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.IsReExamination}
                        verticalAlign="inlineInput"
                        automationId="__isReExamination"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item sm={4}>
                    {
                        props.store.isReExamination &&
                        <EhiEReferralCovidPreviousResultSelectBox value={props.store.previousResultId}
                            onChange={props.store.setPreviousResultId}
                            label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.PreviousResultId}
                            propertyIdentifier="PreviousResultId"
                            automationId="__previousResultId"
                        />
                    }
                </Ui.Flex.Item>
                <Ui.Flex.Item sm={4}>
                    <></>
                </Ui.Flex.Item>
            </Ui.Flex>
        </Ui.GroupBox>
    );
};

export default State.observer(PreviousResultView);
