import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import React from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import CareActivitySource from "@Primitives/CareActivitySource.g";
import EhiHealthcareProviderOrganizationUnitId from "@Primitives/EhiHealthcareProviderOrganizationUnitId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";

interface IPatientsCareActivityListReferralOrganizationUnitColumnProps extends IDataGridColumnProps {
    _dependencies?: IPatientsCareActivityListReferralOrganizationUnitColumnDependencies;
    showName: boolean;
}

interface IPatientsCareActivityListReferralOrganizationUnitColumnDependencies {
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

@State.observer
class PatientsCareActivityListReferralOrganizationUnitColumn extends React.Component<IPatientsCareActivityListReferralOrganizationUnitColumnProps> {
    private get hunEhiCareReferenceDataStore() {
        return this.props._dependencies.hunEhiCareReferenceDataStore;
    }
    
    private get organizationReferenceDataStore() {
        return this.props._dependencies.organizationReferenceDataStore;
    }
    
    private isCareActivityFromEhi(row: PatientsCareActivityStore) {
        return ValueWrapper.equals(row.careActivitySource, new CareActivitySource("HunEHealthInfrastructure"));
    }
    
    @State.bound
    private dataGetter(row: PatientsCareActivityStore) {
        if (isNullOrUndefined(row)) {
            return null;
        } else {
            if (this.isCareActivityFromEhi(row)) {
                if (isNullOrUndefined(row.extensionData?.ReferralOrganizationUnitId)) {
                    return row.referralWorkplaceCode;
                } else {
                    return EhiHealthcareProviderOrganizationUnitId.fromJS(row.extensionData.ReferralOrganizationUnitId);
                }
            } else {
                if (isNullOrUndefined(row.referralWorkplaceId)) {
                    return row.referralWorkplaceCode;
                } else {
                    return row.referralWorkplaceId;
                }
            }
        }
    }

    @State.bound
    private cellValueRenderer(value: string | EntityVersionSelector<ExternalLocationId> | EhiHealthcareProviderOrganizationUnitId, row: PatientsCareActivityStore): React.ReactNode {
        const noItem = isNullOrUndefined(value);
        if (!noItem) {
            if (value instanceof EhiHealthcareProviderOrganizationUnitId) {
                return this.ehiHealthcareProviderOrganizationUnitValueRenderer(value, row);
            } else if (value instanceof EntityVersionSelector) {
                return this.externalLocationValueRenderer(value, row);
            } else if (typeof(value) === "string") {
                return value;
            }
        }

        return "";
    }

    @State.bound
    private ehiHealthcareProviderOrganizationUnitValueRenderer(value: EhiHealthcareProviderOrganizationUnitId, row: PatientsCareActivityStore) {
        const orgUnit = this.hunEhiCareReferenceDataStore.ehiHealthcareProviderOrganizationUnits.get(value);
        if (isNullOrUndefined(orgUnit)) {
            return row.pointOfCareCode;
        }

        return (
            <>
                {this.props.showName && orgUnit.name}
                {this.props.showName && <br />}
                {orgUnit.code}
            </>
        );
    }

    @State.bound
    private externalLocationValueRenderer(value: EntityVersionSelector<ExternalLocationId>, row: PatientsCareActivityStore) {
        const noId = isNullOrUndefined(value.id);
        
        if (!noId) {
            const externalLocation = this.organizationReferenceDataStore.externalLocationStore.get(value);
            
            if (isNullOrUndefined(externalLocation)) {
                return row.referralWorkplaceCode;
            }
            
            return (
                <>
                    {this.props.showName && (externalLocation.shortName ? externalLocation.shortName : externalLocation.name)}
                    {this.props.showName && <br />}
                    {externalLocation.identifierAssignments[0].identifier.value}
                </>
            );
        }
        
        return row.referralWorkplaceCode;
    }
    
    @State.bound
    private hintValueRenderer(value: string | EntityVersionSelector<ExternalLocationId> | EhiHealthcareProviderOrganizationUnitId) {
        const noItem = isNullOrUndefined(value);
        
        if (!noItem) {
            if (value instanceof EntityVersionSelector) {
                if (!isNullOrUndefined(value.id)) {
                    const externalLocation = this.organizationReferenceDataStore.externalLocationStore.get(value);
                    return externalLocation?.name;
                }
            }
        }
        
        return undefined;
    }
    
    public render() {
        return (
            <DataGridColumn
                dataGetter={this.dataGetter}
                onRenderCellValue={this.cellValueRenderer}
                onRenderHintValue={this.hintValueRenderer}
                {...this.props}
            />
        );
    }
}

export default connect(
    PatientsCareActivityListReferralOrganizationUnitColumn,
    new DependencyAdapter<IPatientsCareActivityListReferralOrganizationUnitColumnProps, IPatientsCareActivityListReferralOrganizationUnitColumnDependencies>(c => ({
        hunEhiCareReferenceDataStore: c.resolve("HunEhiCareReferenceDataStore"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore")
    }))
);