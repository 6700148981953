import React, { useMemo } from "react";
import IBusinessError from "@Toolkit/CommonWeb/Model/IBusinessError";
import HandlerRegistrator, { IHandlerRegistratorProps } from "@Toolkit/ReactClient/Components/BusinessErrorHandler/HandlerRegistrator";
import UniversalBusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/UniversalBusinessErrorHandler";

export type HandlerType = (error: IBusinessError) => boolean;

export interface IBusinessErrorHandler {
    handle: HandlerType;
    registerHandler: (name: string, handler: HandlerType) => void;
    removeHandler: (name: string, handler: HandlerType) => void;
}


const BusinessErrorHandlerContext = React.createContext<IBusinessErrorHandler>(null);

interface IBusinessErrorHandlerComponent extends React.FC {
    Register: React.FC<IHandlerRegistratorProps>;
}


const BusinessErrorHandler: IBusinessErrorHandlerComponent = props => {
    const handler = useMemo(() => new UniversalBusinessErrorHandler(), []);
    return (
        <BusinessErrorHandlerContext.Provider value={handler}>
            {props.children}
        </BusinessErrorHandlerContext.Provider>
    );
};

BusinessErrorHandler.Register = HandlerRegistrator;

export {
    BusinessErrorHandlerContext
};

export default BusinessErrorHandler;