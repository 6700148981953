import React from "react";
import * as Ui from "@CommonControls";
import Role from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/Role";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PermissionDefinitionMultiSelector from "@HisPlatform/BoundedContexts/Authorization/Components/Controls/PermissionDefinitionMultiSelector";

interface IRoleDetailsViewProps {
    onSaveAsync: () => Promise<void>;
    role: Role;
    onValidateAsync: () => Promise<IClientValidationResult[]>;
}

/** @screen */

const RoleDetailView: React.FC<IRoleDetailsViewProps> = props =>
    props.role && (
        <ValidationBoundary validationResults={props.role.validationResults || []}
                            entityTypeName={"Role"}
                            onValidateAsync={props.onValidateAsync}
                            validateOnMount
        >
            <Ui.GroupBox>
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <Ui.TextBox
                            value={props.role.shortName}
                            label={StaticAuthorizationResources.RoleManagement.Label.ShortName}
                            onChange={props.role.setShortName}
                            propertyIdentifier={"ShortName"}
                            automationId="shortNameTextBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={8}>
                        <Ui.TextBox
                            label={StaticAuthorizationResources.RoleManagement.Label.Name}
                            value={props.role.name}
                            onChange={props.role.setName}
                            propertyIdentifier={"Name"}
                            automationId="nameTextBox"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        <Ui.TextBox
                            label={StaticAuthorizationResources.RoleManagement.Label.Description}
                            value={props.role.description}
                            onChange={props.role.setDescription}
                            propertyIdentifier={"Description"}
                            multiline
                            multilineMinLineCount={2}
                            automationId="descriptionTextBox"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>
            <Ui.GroupBox>
                <PermissionDefinitionMultiSelector
                    selectedItems={props.role.permissions.definitions}
                    onChange={props.role.setPermissions}
                    allItemsTableTitle={StaticAuthorizationResources.RoleManagement.PermissionSelector.AllItems}
                    chosenItemsTableTitle={StaticAuthorizationResources.RoleManagement.PermissionSelector.SelectedItems}
                    fixedHeight="calc(100vh - 277px)"
                />
            </Ui.GroupBox>
        </ValidationBoundary>
    );

export default State.observer(RoleDetailView);
