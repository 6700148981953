import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Configuration/Api/Proxy.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import moment from "moment";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";

@Di.injectable()
export default class ConfigurationStatusApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IStatusClient") private apiClient: Proxy.IStatusClient
    ) {
        super();
    }

    @State.bound
    public getCurrentDateTimeAsync() {
        return this.processOperationAsync(
            new SimpleStore<moment.Moment>(),
            async target => {
                const response = await this.apiClient.getCurrentDateTimeQueryAsync(CreateRequestId());
                target.operationInfo = createOperationInfo(response);
                target.value = response.currentDateTime;
            }
        );
    }
}