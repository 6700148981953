import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import * as Proxy from "@HisPlatform/BoundedContexts/Productivity/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import IDynamicListDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IDynamicListDefinition";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IWorklistItemDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistItemDefinition";
import IWorklistItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistItem";
import { ICondition } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistFilterCondition";
import IWorklistItemAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistAction";
import ActionPlacement from "@Primitives/ActionPlacement";
import Action from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/Action";
import UseCaseNavigationAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/UseCaseNavigationAction";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import RefreshWorklistAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/RefreshWorklistAction";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import StatusChangeReasonArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/StatusChangeReasonArgument";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import IWorklistDefinitionGroup from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinitionGroup";
import ExecuteClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ExecuteClientSideAction";
import DisplayPrintoutContentAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/DisplayPrintoutContentAction";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import NavigateToUseCaseArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/NavigateToUseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import AppointmentCancellationReasonArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/AppointmentCancellationReasonArgument";
import IWorklistData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistData";
import Reflection from "@Toolkit/CommonWeb/Reflection/Reflection";
import CreateAppointmentScheduleDefinitionWorkListArgument from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/CreateNewItemModal/CreateAppointmentScheduleDefinitionWorkListArgument";
import IDefaultWorklistAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IDefaultWorklistAction";
import GetContentAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/GetContentAction";
import ForceClientRefreshArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/ForceClientRefreshArgument";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IDeferredActionItem } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IDeferredActionItem";
import IMapperService from "@HisPlatform/Services/Definition/MapperService/IMapperService";
import IDeferredActionTaskStatus, { IDeferredActionTaskItemResult } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IDeferredActionTaskStatus";
import NDataDeferredActionTaskId from "@Primitives/NDataDeferredActionTaskId.g";
import INDataDeferredActionTaskState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/INDataDeferredActionTaskState";
import { getUseCaseArgumentsAsUrlParams, parseUseCaseArgumentsFromUrlParams } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import PatientId from "@Primitives/PatientId.g";
import AttributeReference from "@Primitives/AttributeReference.g";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import LargeDataToken from "@Primitives/LargeDataToken.g";
import WorklistDefinitionCache from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/Worklist/WorklistDefinitionCache";
import murmur from "murmurhash-js";
import ItemVisibility from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/ItemVisibility.g";
import UserId from "@Primitives/UserId.g";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import DateComparisonType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/DateComparisonType.g";
import NumericComparisonType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/NumericComparisonType.g";
import ActionCommandToken from "@Primitives/ActionCommandToken.g";
import ItemPlacement from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/ItemPlacement.g";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";

@Di.injectable()
export default class WorklistApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IWorklistClient") private readonly apiClient: Proxy.IWorklistClient,
        @Di.inject("IMapperService") private readonly mapper: IMapperService,
        @Di.inject("SchedulingReferenceDataStore") private readonly schedulingReferenceDataStore: SchedulingReferenceDataStore,
        @Di.inject("WorklistDefinitionCache") private readonly worklistDefinitionCache: WorklistDefinitionCache,
        @Di.inject("ICurrentCultureProvider") private readonly cultureCodeProvider: ICurrentCultureProvider
    ) {
        super();
    }

    private getFilterConditionDto(condition: ICondition) {
        switch (condition.type) {
            case WorklistConditionType.String:
                return new Proxy.StringConditionDto({ value: condition.value, comparisonType: condition.comparisonType });
            case WorklistConditionType.EntityId:
                return new Proxy.EntityIdConditionDto({ conditionalIdList: condition.values });
            case WorklistConditionType.PersonName:
                return new Proxy.NameConditionDto({ namePart: condition.namePart });
            case WorklistConditionType.StringEnum:
                return new Proxy.StringEnumConditionDto({ enumList: condition.values });
            case WorklistConditionType.IntEnum:
                return new Proxy.IntEnumConditionDto({ enumList: condition.values });
            case WorklistConditionType.Numeric:
                return new Proxy.NumericConditionDto({
                    value: condition.value,
                    comparisonType: isNullOrUndefined(condition.comparisonType) ? NumericComparisonType.Equality : condition.comparisonType
                });
            case WorklistConditionType.Boolean:
                return new Proxy.BooleanConditionDto({ value: condition.value });
            case WorklistConditionType.Date:
                return new Proxy.DateConditionDto({
                    from: condition.value.from,
                    fromComparisonType: DateComparisonType.Inclusive,
                    to: condition.value.to && condition.value.to.plusDays(1),
                    toComparisonType: DateComparisonType.Exclusive
                });
            case WorklistConditionType.DateAndTime:
                return new Proxy.DateTimeConditionDto({
                    from: condition.value.from.toLocalDayStartMoment(),
                    fromComparisonType: DateComparisonType.Inclusive,
                    to: condition.value.to && condition.value.to.plusDays(1).toLocalDayStartMoment(),
                    toComparisonType: DateComparisonType.Inclusive
                });
            case WorklistConditionType.YearMonth:
                return new Proxy.YearMonthConditionDto({
                    yearMonth: condition.value
                });
            case WorklistConditionType.StringEnumList: {
                return new Proxy.StringEnumListConditionDto({
                    enumList: condition.values.map(item => new Proxy.StringEnumListWithNameDto({
                        name: item.name,
                        enumList: item.values.map(i => i.toString())
                    }))
                });
            }
            case WorklistConditionType.ExtensibleEnum: {
                return new Proxy.ExtensibleEnumConditionDto({
                    extensibleEnumList: condition.values
                });
            }
        }

        throw new Error("Unknown condition type");
    }

    public getContentAsync(contentId: LargeDataToken) {
        return this.processOperationAsync(
            new SimpleStore<{ content: string, mediaType: string, fileName: string }>(), async target => {
                const response = await this.apiClient.getFileContentByIdQueryAsync(
                    CreateRequestId(),
                    contentId.value);

                target.value = { mediaType: response.fileDto.mediaType, content: response.fileDto.content, fileName: response.fileDto.fileName };
                target.operationInfo = createOperationInfo(response);
            });
    }

    public getCareActivityActionsAsync(careActivityId: CareActivityId) {
        return this.processOperationAsync(
            new SimpleStore<IWorklistItemAction[]>(), async target => {
                const response = await this.apiClient.getCareActivityActionsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetCareActivityActionsControllerDto({
                        careActivityId: careActivityId,
                    }));

                target.operationInfo = createOperationInfo(response);
                target.value = response.actions.map(act => this.getWorkListAction(act));
            });
    }

    public executeActionTokenAsync(token: string, argument?: WorkListArgument) {
        return this.processOperationAsync(
            new SimpleStore<Action>(), async target => {
                const response = await this.apiClient.executeWorklistActionCommandAsync(
                    CreateRequestId(),
                    new Proxy.ExecuteWorklistActionControllerDto({
                        token: new ActionCommandToken(token),
                        argument: this.getWorklistArgumentDto(argument)
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = this.mapResponse(response.response);
            });
    }

    public enqueueDeferredActionTokensAsync(taskType: string, actionItems: IDeferredActionItem[], state: INDataDeferredActionTaskState) {
        return this.processOperationAsync(
            new SimpleStore<IDeferredActionTaskStatus>(), async target => {
                const response = await this.apiClient.enqueueDeferredNDataActionsCommandAsync(
                    CreateRequestId(),
                    new Proxy.EnqueueDeferredNDataActionsControllerDto({
                        actions: actionItems.map(i => new Proxy.NDataActionItemDto({
                            rowId: i.rowId,
                            displayName: i.displayName,
                            token: new ActionCommandToken(i.token),
                            argument: this.getWorklistArgumentDto(i.argument)
                        })),
                        type: taskType,
                        state: JSON.stringify({
                            useCase: state.useCase.value,
                            useCaseArguments: getUseCaseArgumentsAsUrlParams(state.useCaseArguments),
                            multiActionActivityReference: state.multiActionActivityReference
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = this.mapDeferredActionTaskStatus(response.task);
            });
    }

    public getDeferredActionTaskStatusAsync(taskType: string) {
        return this.processOperationAsync(
            new SimpleStore<IDeferredActionTaskStatus | null>(), async target => {
                const response = await this.apiClient.getDeferredNDataActionTasksQueryAsync(CreateRequestId(), taskType);
                target.operationInfo = createOperationInfo(response);

                if (arrayIsNullOrEmpty(response.tasks)) {
                    target.value = null; // no running or completed task found
                    return;
                }

                const singleTask = response.tasks[0];
                target.value = this.mapDeferredActionTaskStatus(singleTask);
            });
    }

    private mapDeferredActionTaskStatus(task: Proxy.NDataDeferredActionTaskStatusDto): IDeferredActionTaskStatus {
        const rawState = JSON.parse(task.state);
        return {
            id: task.id,
            type: task.type,
            createdAt: task.createdAt,
            isCompleted: task.isCompleted,
            totalActions: task.totalActions,
            numberOfCompletedActions: task.numberOfCompletedActions,
            numberOfFailedActions: task.numberOfFailedActions,
            state: rawState && {
                multiActionActivityReference: rawState.multiActionActivityReference,
                useCase: new UseCaseIdentifier(rawState.useCase),
                useCaseArguments: parseUseCaseArgumentsFromUrlParams(rawState.useCaseArguments)
            },
            results: task.responses?.map(resp => ({
                rowId: resp.rowId,
                displayName: resp.displayName,
                errorName: resp.errorName,
                isSucceeded: resp.operationStatus === Proxy.OperationStatus.Success
            } as IDeferredActionTaskItemResult))
        };
    }

    public clearDeferredActionTaskAsync(taskId: NDataDeferredActionTaskId) {
        return this.processOperationAsync(
            new SimpleStore(), async target => {
                const response = await this.apiClient.clearCompletedDeferredNDataTaskCommandAsync(CreateRequestId(), new Proxy.ClearCompletedDeferredNDataTaskControllerDto({
                    taskId
                }));

                target.operationInfo = createOperationInfo(response);
            });
    }

    private getWorklistArgumentDto(argument: WorkListArgument) {
        if (!argument) {
            return null;
        }
        if (argument instanceof StatusChangeReasonArgument) {
            return new Proxy.StatusChangeReasonArgumentDto({
                statusChangeReasonId: argument.statusChageReasonId,
                additionalText: argument.additonalText
            });
        } else if (argument instanceof NavigateToUseCaseArgument) {
            return new Proxy.NavigateToUseCaseArgumentDto({ useCaseDisplayMode: argument.useCaseDisplayMode });
        } else if (argument instanceof AppointmentCancellationReasonArgument) {
            return new Proxy.AppointmentCancellationReasonArgumentDto({
                appointmentCancellationReasonId: argument.appointmentCancellationReasonId,
                additionalText: argument.additonalText
            });
        } else if (argument instanceof CreateAppointmentScheduleDefinitionWorkListArgument) {
            this.schedulingReferenceDataStore.appointmentScheduleDefinitions.invalidate();

            return new Proxy.CreateAppointmentScheduleDefinitionArgumentDto({
                description: argument.description,
                name: argument.name,
                planningPeriodInterval: new Proxy.DateIntervalDto({
                    from: argument.interval?.from,
                    to: argument.interval?.to
                })
            });
        } else if (argument instanceof ForceClientRefreshArgument) {
            return new Proxy.ForceClientRefreshArgumentDto();
        } else {
            return this.mapper.map<WorkListArgument, Proxy.WorklistActionArgumentDto>(argument);
        }

        throw new Error(`WorkListArgument of type ${argument.constructor.name} not mapped.`);
    }

    private mapResponse(inp: Proxy.ActionResponseDto): Action {
        if (inp instanceof Proxy.NavigateToUseCaseActionResponseDto) {
            return new UseCaseNavigationAction(
                inp.useCase as unknown as UseCaseIdentifier,
                this.mapUseCaseArguments(inp.arguments),
                inp.useCaseDisplayMode as unknown as UseCaseDisplayMode,
                inp.shouldRefresh);
        }

        if (inp instanceof Proxy.RefreshWorklistResponseDto) {
            return new RefreshWorklistAction();
        }

        if (inp instanceof Proxy.ExecuteClientSideActionResponseDto) {
            return new ExecuteClientSideAction(inp.action);
        }

        if (inp instanceof Proxy.DisplayPrintoutContentResponseDto) {
            return new DisplayPrintoutContentAction(inp.documentId);
        }

        if (inp instanceof Proxy.GetContentResponseDto) {
            return new GetContentAction(inp.largeDataIds);
        }

        throw new Error(`Unknown response ${inp.constructor.name}.`);
    }

    private mapUseCaseArguments(args: any[]): UseCaseArgument[] {
        return args.map(a => {
            if (a instanceof Proxy.UseCaseArgumentOfPatientId) {
                return new UseCaseArgument(a.value, "patientId");
            } else if (a instanceof Proxy.UseCaseArgumentOfCareActivityId) {
                return new UseCaseArgument(a.value, "careActivityId");
            } else if (a instanceof Proxy.UseCaseArgumentOfStatusChangeReasonTypeId) {
                return new UseCaseArgument(a.value, "statusChangeReasonTypeId");
            } else if (a instanceof Proxy.UseCaseArgumentOfServiceRequestId) {
                return new UseCaseArgument(a.value, "serviceRequestId");
            } else if (a instanceof Proxy.UseCaseArgumentOfAppointmentScheduleEntryId) {
                return new UseCaseArgument(a.value, "appointmentId");
            } else if (a instanceof Proxy.UseCaseArgumentOfAppointmentCancellationReasonId) {
                return new UseCaseArgument(a.value, "appointmentCancellationReasonId");
            } else if (a instanceof Proxy.UseCaseArgumentOfMedicationId) {
                return new UseCaseArgument(a.value, "medicationId");
            } else if (a instanceof Proxy.UseCaseArgumentOfAppointmentScheduleDefinitionId) {
                return new UseCaseArgument(a.value, "appointmentScheduleDefinitionId");
            } else if (a instanceof Proxy.UseCaseArgumentOfUntypedEntityIdDto) {
                const typeName = a.value.typeName.split(".").pop();

                const entityId = Reflection.createInstance(typeName, a.value.value);

                return new UseCaseArgument(entityId, typeName.charAt(0).toLowerCase() + typeName.slice(1));
            }
            throw new Error(`Unknown UseCaseArgument ${a.constructor.name}`);
        });
    }

    public getWorklistDefinitionList() {
        return this.processOperationAsync(new SimpleStore<IWorklistDefinitionGroup[]>(),
            async target => {
                const response = await this.apiClient.getCareLocationBoundWorklistDefinitionsQueryAsync(CreateRequestId());

                target.operationInfo = createOperationInfo(response);
                if (!arrayIsNullOrEmpty(response.definitions)) {
                    target.value = response.definitions.map((groupDef) => ({
                        definitions: groupDef.items.map((itemDef) => {
                            const name = this.mapLocalizedLabelsToMultiLingualLabelMap(itemDef.localizedNames)
                                .getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode);
                            return ({
                                localId: murmur(itemDef.worklistToken.value.concat(
                                    name,
                                    itemDef.organizationUnitId?.value)).toString(16),
                                worklistToken: itemDef.worklistToken.value,
                                name: name,
                                shortName: this.mapLocalizedLabelsToMultiLingualLabelMap(itemDef.localizedShortNames)
                                    .getWithCurrentCultureCodeOrWithDefaultCultureCode(this.cultureCodeProvider.cultureCode),
                                code: itemDef.code,
                                organizationUnitId: itemDef.organizationUnitId,
                                healthcareProfessionIds: itemDef.healthcareProfessionIds
                            } as IWorklistDefinition);
                        }),
                        name: groupDef.name,
                        shortName: groupDef.shortName
                    } as IWorklistDefinitionGroup));
                }
            });
    }

    public getWorklistByDefinitionId(activeWorklistDefinition: IWorklistDefinition) {
        return this.processOperationAsync(
            new SimpleStore<IDynamicListDefinition>(),
            async target => {
                const response = await this.apiClient.getWorklistQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetWorklistControllerDto({
                        worklistToken: new Proxy.WorklistToken({ value: activeWorklistDefinition.worklistToken })
                    } as Proxy.IGetWorklistControllerDto)
                );

                target.operationInfo = createOperationInfo(response);

                target.value = {
                    name: activeWorklistDefinition.name,
                    itemDefinitions: response.itemDefinitions.map(itemDef => ({
                        attributeName: itemDef.attributeReference.value,
                        attributeType: itemDef.attributeType,
                        filterConditionType: itemDef.conditionType,
                        headerResourceId: itemDef.headerResourceId,
                        placement: itemDef.placement as unknown as ItemPlacement,
                        itemVisibility: itemDef.itemVisibility,
                        isOrderingDisabled: itemDef.isOrderingDisabled,
                        defaultColumnWidth: itemDef.defaultColumnWidth,
                        ellipsisOnOverflow: itemDef.ellipsisOnOverflow,
                        displayValue: itemDef.displayValue
                    } as IWorklistItemDefinition)),
                    globalActions: response.globalActions && response.globalActions.map(act => this.getWorkListAction(act)),
                };
            }
        );
    }

    public getWorklistDataByDefinitionId(
        worklistToken: string,
        pageIndex: number,
        pageSize: number,
        orderBy: { fieldName: string, ascending: boolean },
        filter: { [fieldName: string]: ICondition },
        referenceDate: LocalDate,
        includedRowIds: string[] = null,
        isPermissionCheckOnly = false) {
        return this.processOperationAsync(
            new SimpleStore<IWorklistData>(),
            async target => {
                const response = await this.apiClient.getWorklistDataQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetWorklistDataControllerDto({
                        customFilters: filter && [...Object.keys(filter).map(searchField => new Proxy.FilterDto({
                            attributeReference: new AttributeReference(searchField),
                            isNegated: false,
                            condition: this.getFilterConditionDto(filter[searchField])
                        }))],
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfAttributeReference({
                            paging: new Proxy.QueryPaging({ pageIndex, pageSize }),
                            orderings: orderBy && [new Proxy.QueryOrderingOfAttributeReference({
                                direction: orderBy.ascending ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: new AttributeReference(orderBy.fieldName)
                            })]
                        }),
                        worklistToken: new Proxy.WorklistToken({ value: worklistToken }),
                        referenceDate: referenceDate,
                        includedRowIds: includedRowIds
                    } as Proxy.IGetWorklistDataControllerDto),
                    isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);

                if (!isPermissionCheckOnly) {
                    target.value = {
                        items: {
                            totalCount: response.items.totalCount,
                            items: response.items.values.map(item => ({
                                id: item.id,
                                accessLevel: item.accessLevel,
                                attributes: item.attributes,
                                contextInfo: this.mapper.map(item.contextInfo),

                                defaultAction: this.getDefaultWorklistAction(item.defaultAction),
                                actions: item.actions.map(act => this.getWorkListAction(act))
                            } as IWorklistItem))
                        },
                        someItemsAreHidden: response.someItemsAreHidden,
                        globalFrontendActions: []
                    };
                }
            }
        );
    }

    @State.bound
    public getWorklistDataByDefinitionIdPermissionCheck(activeWorklistDefinition: IWorklistDefinition) {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.getWorklistDataQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetWorklistDataControllerDto({
                        worklistToken: new Proxy.WorklistToken({ value: activeWorklistDefinition?.worklistToken }),
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfAttributeReference({
                            paging: new Proxy.QueryPaging({
                                pageIndex: 0,
                                pageSize: 10
                            })
                        }),
                        customFilters: []
                    }),
                    true
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    private getDefaultWorklistAction(dto: Proxy.DefaultActionDto): IDefaultWorklistAction {
        if (isNullOrUndefined(dto)) {
            return null;
        }

        return {
            isAvailable: dto.isAvailable,
            unavailableMessageResourceId: dto.unavailableMessageResourceId,
            commandToken: isNullOrUndefined(dto.commandToken) ? null : dto.commandToken.value,
            clientSideAction: dto.clientSideAction,
            useCaseDisplayMode: isNullOrUndefined(dto.useCaseDisplayMode) ? null : dto.useCaseDisplayMode as undefined as UseCaseDisplayMode
        };
    }

    public getWorkListAction(dto: Proxy.ActionDto): IWorklistItemAction {
        return {
            title: dto.title,
            titleResourceId: isNullOrUndefined(dto.titleResourceId) ? null : dto.titleResourceId,
            isEnabled: dto.isEnabled,
            placement: dto.placement as unknown as ActionPlacement,
            displayAwareVisibility: dto.displayAwareVisibility as unknown as ItemVisibility,
            commandToken: isNullOrUndefined(dto.commandToken) ? null : dto.commandToken.value,
            clientSideAction: dto.clientSideAction,
            order: dto.order,
            groupId: dto.groupId,
            groupTitleResourceId: dto.groupTitleResourceId,
            activityReference: dto.activityReference,
            useCaseDisplayMode: dto.useCaseDisplayMode as undefined as UseCaseDisplayMode,
            worklistActionType: dto.worklistActionType
        };
    }

    public getBoundWorklistDefinition(worklistDefinitionReference: string, cacheKey?: string, dynamicFilters?: { [fieldName: string]: ICondition }) {

        const worklistCacheKey = cacheKey ? `${worklistDefinitionReference}_${cacheKey}` : worklistDefinitionReference;
        return this.getWorklistDefinition(
            worklistCacheKey,
            () => this.apiClient.getBoundWorklistDefinitionQueryAsync(CreateRequestId(),
                new Proxy.GetBoundWorklistDefinitionControllerDto({
                    worklistDefinitionReference: worklistDefinitionReference,
                    dynamicFilters: dynamicFilters ? [...Object.keys(dynamicFilters).map(searchField => new Proxy.FilterDto({
                        attributeReference: new AttributeReference(searchField),
                        isNegated: false,
                        condition: this.getFilterConditionDto(dynamicFilters[searchField])
                    }))] : []
                }), false));
    }

    public getPatientBoundWorklistDefinition() {
        return this.getWorklistDefinition(
            "PatientBoundWorklist",
            () => this.apiClient.getPatientBoundWorklistDefinitionQueryAsync(CreateRequestId()));
    }

    public getPatientSelectorWorklistDefinition() {
        return this.getWorklistDefinition(
            "PatientSelectorWorklist",
            () => this.apiClient.getPatientSelectorWorklistDefinitionQueryAsync(CreateRequestId()));
    }

    public getMedicationBoundWorklistDefinition() {
        return this.getWorklistDefinition(
            "MedicationBoundWorklist",
            () => this.apiClient.getMedicationBoundWorklistDefinitionQueryAsync(CreateRequestId()));
    }

    public getFamilyDoctorDocumentBoundWorklistDefinition(patientId: PatientId) {
        return this.getWorklistDefinition(
            `FamilyDoctorDocumentBoundWorklist_${patientId.value}`,
            () => this.apiClient.getFamilyDoctorDocumentBoundWorklistDefinitionQueryAsync(CreateRequestId(), patientId.value));
    }

    public getPractitionerScheduleEntryBoundWorklistDefinition() {
        return this.getWorklistDefinition(
            "PractitionerScheduleEntryBoundWorklist",
            () => this.apiClient.getPractitionerScheduleEntryBoundWorklistDefinitionQueryAsync(CreateRequestId()));
    }

    public getStandaloneMedicationBoundWorklistDefinition() {
        return this.getWorklistDefinition(
            "StandaloneMedicationBoundWorklist",
            () => this.apiClient.getStandaloneMedicationBoundWorklistDefinitionQueryAsync(CreateRequestId()));
    }

    public getAppointmentScheduleEntryBoundWorklistDefinition() {
        return this.getWorklistDefinition(
            "AppointmentScheduleEntryBoundWorklist",
            () => this.apiClient.getAppointmentScheduleEntryBoundWorklistDefinitionQueryAsync(CreateRequestId()));
    }

    public getAppointmentScheduleDefinitionBoundWorklistDefinition() {
        return this.getWorklistDefinition(
            "AppointmentScheduleDefinitionBoundWorklist",
            () => this.apiClient.getAppointmentScheduleDefinitionBoundWorklistDefinitionQueryAsync(CreateRequestId()));
    }

    public getInvalidAppointmentScheduleEntryBoundWorklistDefinition() {
        return this.getWorklistDefinition(
            "InvalidAppointmentScheduleEntryBoundWorklist",
            () => this.apiClient.getInvalidAppointmentScheduleEntryBoundWorklistDefinitionQueryAsync(CreateRequestId()));
    }

    public getImmunizationBoundWorklistDefinition(patientId: PatientId, userId: UserId) {
        return this.getWorklistDefinition(
            `ImmunizationBoundWorklist_${patientId.value}_${userId.value}`,
            () => this.apiClient.getImmunizationBoundWorklistDefinitionQueryAsync(CreateRequestId(), patientId.value));
    }

    public getProcedureStatementBoundWorklistDefinition(patientId: PatientId, userId: UserId) {
        return this.getWorklistDefinition(
            `ProcedureStatementBoundWorklist_${patientId.value}_${userId.value}`,
            () => this.apiClient.getProcedureStatementBoundWorklistDefinitionQueryAsync(CreateRequestId(), patientId.value));
    }

    public getAutonomyDisabilityStatementBoundWorklistDefinition(patientId: PatientId, userId: UserId) {
        return this.getWorklistDefinition(
            `AutonomyDisabilityStatementBoundWorklist_${patientId.value}_${userId.value}`,
            () => this.apiClient.getAutonomyDisabilityStatementBoundWorklistDefinitionQueryAsync(CreateRequestId(), patientId.value));
    }

    public getConditionStatementBoundWorklistDefinition(patientId: PatientId, userId: UserId, isFormerConditionStatement: boolean = false) {
        if (isFormerConditionStatement) {
            return this.getWorklistDefinition(
                `FormerConditionStatementBoundWorklist_${patientId.value}_${userId.value}`,
                () => this.apiClient.getFormerConditionStatementBoundWorklistDefinitionQueryAsync(CreateRequestId(), patientId.value));
        }

        return this.getWorklistDefinition(
            `CurrentConditionStatementBoundWorklist_${patientId.value}_${userId.value}`,
            () => this.apiClient.getCurrentConditionStatementBoundWorklistDefinitionQueryAsync(CreateRequestId(), patientId.value));
    }

    public getDeviceUseStatementBoundWorklistDefinition(patientId: PatientId, userId: UserId) {
        return this.getWorklistDefinition(
            `DeviceUseStatementBoundWorklist_${patientId.value}_${userId.value}`,
            () => this.apiClient.getDeviceUseStatementBoundWorklistDefinitionQueryAsync(CreateRequestId(), patientId.value));
    }

    public getEpisodeOfCareCareRelatedEntitiesBoundWorklistDefinitionAsync(episodeOfCareId: EpisodeOfCareId, userId: UserId) {
        return this.getWorklistDefinition(
            `EpisodeOfCareCareRelatedEntitiesBoundWorklist_${episodeOfCareId.value}_${userId.value}`,
            () => this.apiClient.getEpisodeOfCareCareRelatedEntitiesBoundWorklistDefinitionQueryAsync(CreateRequestId(), episodeOfCareId.value));
    }


    public getPregnancyStatementBoundWorklistDefinition(patientId: PatientId, userId: UserId) {
        return this.getWorklistDefinition(
            `PregnancyStatementBoundWorklist_${patientId.value}_${userId.value}`,
            () => this.apiClient.getPregnancyStatementBoundWorklistDefinitionQueryAsync(CreateRequestId(), patientId.value));
    }

    public getMedicationStatementBoundWorklistDefinition(patientId: PatientId, userId: UserId) {
        return this.getWorklistDefinition(
            `MedicationStatementBoundWorklist_${patientId.value}_${userId.value}`,
            () => this.apiClient.getMedicationStatementBoundWorklistDefinitionQueryAsync(CreateRequestId(), patientId.value));
    }

    public getRiskAssessmentBoundWorklistDefinition(patientId: PatientId, userId: UserId) {
        return this.getWorklistDefinition(
            `RiskAssessmentBoundWorklist_${patientId.value}_${userId.value}`,
            () => this.apiClient.getRiskAssessmentBoundWorklistDefinitionQueryAsync(CreateRequestId(), patientId.value));
    }

    public getPatientAllergyIntoleranceBoundWorklistDefinition(patientId: PatientId, userId: UserId) {
        return this.getWorklistDefinition(
            `PatientAllergyIntoleranceBoundWorklist_${patientId.value}_${userId.value}`,
            () => this.apiClient.getPatientAllergyIntoleranceBoundWorklistDefinitionQueryAsync(CreateRequestId(), patientId.value));
    }

    public getMedicalAlertStatementBoundWorklistDefinition(patientId: PatientId, userId: UserId) {
        return this.getWorklistDefinition(
            `MedicalAlertStatementBoundWorklist_${patientId.value}_${userId.value}`,
            () => this.apiClient.getMedicalAlertStatementBoundWorklistDefinitionQueryAsync(CreateRequestId(), patientId.value));
    }

    public getExternalLocationBoundWorklistDefinition() {
        return this.getWorklistDefinition(
            "ExternalLocationBoundWorklist",
            () => this.apiClient.getExternalLocationBoundWorklistDefinitionQueryAsync(CreateRequestId()));
    }

    private getWorklistDefinition(key: string, getter: () => Promise<Proxy.IResponseBase & { definition?: Proxy.BoundWorklistDefinitionDto }>) {
        return this.worklistDefinitionCache.getWorklistDefinitionAsync(key, getter);
    }

    private mapLocalizedLabelsToMultiLingualLabelMap(localizedLabels: Proxy.LocalizedLabel[]) {
        if (localizedLabels == null)
            return null;
        const localizedLabelMap = new Map<string, string>();
        localizedLabels.forEach(l => localizedLabelMap.set(l.cultureCode?.value, l.label));
        return new MultiLingualLabel(localizedLabelMap);
    }
}
