import React from "react";
import Calendar from "./Calendar";
import TimeEditor from "./TimeEditor";
import DatePicker from "./DatePicker";
import SelectBox, { inputChangeActionType, SelectBoxCore } from "@CommonControls/SelectBox";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { components } from "react-select";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Styles from "./DateTimePicker.less";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import moment from "moment";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import Icon from "@CommonControls/Icon";
import { TextBox } from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ReadOnlyContextAdapter from "@Toolkit/ReactClient/Components/ReadOnlyContext/ReadOnlyContextAdapter";
import ValidationBoundaryAdapter from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryAdapter";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

export interface IDateTimePickerDependencies {
    dateTimeFormatProvider: IDateTimeFormatProvider;
}

interface IDateTimePickerProps extends ISelectBoxBaseProps {
    _dependencies?: IDateTimePickerDependencies;

    value?: moment.Moment;
    onChange?: (newValue: moment.Moment) => void;

    timePickerOptions?: {
        from?: TimeOfDay;
        to?: TimeOfDay;
        stepsInSeconds?: number[];
    };

    dateOnlyMode?: boolean;
    monthOnlyMode?: boolean;
    forwardedRef?: React.Ref<SelectBoxCore>;

    validateOnMount?: boolean;
}

interface IDateTimePickerCoreProps extends IDateTimePickerProps {
    validationContext: IValidationBoundaryStore;
}

const valueOnClear = { __clear: true };
const dummyItems = [{} as any];

@State.observer
export class DateTimePickerCore extends React.Component<IDateTimePickerCoreProps> {

    private get dateTimeFormatProvider() { return this.props._dependencies.dateTimeFormatProvider; }

    public static defaultProps: Partial<IDateTimePickerProps> = {
        clearable: true
    };

    @State.observable.ref private menuIsOpen = false;

    @State.observable.ref private dateValue: LocalDate = null;
    @State.observable.ref private timeValue: TimeOfDay = null;
    @State.observable.ref private pickingTime = false;
    @State.observable.ref private textValue = "";

    @State.computed private get momentValue() {
        if (!this.dateValue) {
            return null;
        }

        const date = this.dateValue.toUtcDayStartMoment();

        if (this.timeValue) {
            return this.dateValue.toUtcDayStartMoment().hours(this.timeValue.hours).minutes(this.timeValue.minutes).seconds(0).milliseconds(0);
        }

        return date;
    }

    constructor(props: IDateTimePickerCoreProps) {
        super(props);
        this.refreshInternalState();
    }

    private isCurrentValueChanged(prevProps: IDateTimePickerProps) {
        return prevProps.value !== this.props.value && !this.props.value?.isSame(prevProps.value);
    }

    public componentDidUpdate(prevProps: IDateTimePickerProps) {
        if (prevProps.value !== this.props.value || prevProps.dateOnlyMode !== this.props.dateOnlyMode || prevProps.monthOnlyMode !== this.props.monthOnlyMode) {
            this.refreshInternalState();
        }

        if (this.isCurrentValueChanged(prevProps)) {
            this.validate();
        }
    }

    @State.bound
    private validate() {
        if (this.props.validationContext) {
            this.props.validationContext.changed(this.props.propertyIdentifier, this.props.value);
        }
    }

    @State.action
    private refreshInternalState() {
        this.dateValue = LocalDate.createFromMoment(this.props.value);
        this.timeValue = this.props.dateOnlyMode || this.props.monthOnlyMode ? TimeOfDay.startOfDay : TimeOfDay.createFromMoment(this.props.value);
        this.textValue = this.getDisplayValue();
        this.pickingTime = false;
    }

    @State.action.bound
    private openMenu() {
        this.menuIsOpen = true;
    }

    @State.action.bound
    private closeMenu() {
        this.menuIsOpen = false;
        this.refreshInternalState();
    }

    @State.action.bound
    private setDateValue(newValue: LocalDate) {
        this.dateValue = newValue;
        if (this.props.dateOnlyMode || this.props.monthOnlyMode) {
            this.pickingTime = false;
            this.setValueAndClose();
        } else {
            this.pickingTime = true;
            this.textValue = this.getInternalDisplayValue();
        }
    }

    @State.action.bound
    private setTimeValue(newValue: TimeOfDay, isFinal: boolean) {
        this.timeValue = newValue;

        if (isFinal) {
            this.pickingTime = false;
            this.setValueAndClose();
        } else {
            this.textValue = this.getInternalDisplayValue();
        }
    }

    private setValueAndClose(newValue?: moment.Moment) {
        this.props.onChange(newValue === undefined ? this.momentValue : newValue);
        this.closeMenu();
    }

    @State.bound
    private getDisplayValue() {
        if (isNullOrUndefined(this.props.value)) {
            return "";
        }

        if (this.props.dateOnlyMode) {
            return this.props.value.format(this.dateTimeFormatProvider.getDateFormat());
        }

        if (this.props.monthOnlyMode) {
            return this.props.value.format(this.dateTimeFormatProvider.getMonthFormat());
        }

        return this.props.value.format(this.dateTimeFormatProvider.getDateTimeWithoutSecondsFormat());
    }

    @State.bound
    private getInternalDisplayValue() {
        const internalMomentValue = this.momentValue;

        if (!internalMomentValue) {
            return "";
        }

        if (this.props.dateOnlyMode) {
            return internalMomentValue.format(this.dateTimeFormatProvider.getDateFormat());
        }

        if (this.props.monthOnlyMode) {
            return internalMomentValue.format(this.dateTimeFormatProvider.getMonthFormat());
        }

        return internalMomentValue.format(this.dateTimeFormatProvider.getDateTimeWithoutSecondsFormat());
    }

    @State.action.bound
    private setTextValue(text: string, action?: inputChangeActionType) {
        if (action === "input-change") {
            // eslint-disable-next-line no-useless-escape
            this.textValue = text.replace(/([^\d\.\:\/ ]+)/g, "");

            const formatsToParse = this.getFormatsToParse();

            const parsedValue = text === "" ? null : moment(text, formatsToParse, true);
            const isValidDate = text === "" || parsedValue.isValid();

            if (isValidDate === true) {
                if (parsedValue !== null || this.props.clearable) {
                    this.setValueAndClose(parsedValue);
                }
            }
        }
    }

    @State.bound
    private getFormatsToParse() {
        if (this.props.dateOnlyMode) {
            return this.dateTimeFormatProvider.getDateFormatsToParse();
        }
        if (this.props.monthOnlyMode) {
            return this.dateTimeFormatProvider.getMonthFormatsToParse();
        }
        return this.dateTimeFormatProvider.getDateTimeFormatsToParse();
    }

    @State.action.bound
    private backToCalendar() {
        this.pickingTime = false;
    }

    @State.action.bound
    private keyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") {
            e.preventDefault();
            this.setValueAndClose();
        }
    }

    @State.action.bound
    private setCurrentDateTime() {
        this.setValueAndClose(DateTimeService.now());
    }

    @State.action.bound
    private setCurrentMonth() {
        this.setValueAndClose(DateTimeService.now().startOf("month"));
    }

    private dummyOptionIsSelected() {
        return true;
    }

    @State.bound
    private selectBoxChangeHandler(newValue: any) {
        if (newValue === valueOnClear) {
            this.setValueAndClose(null);
        }
    }

    public render() {
        if (this.props.isReadOnly) {
            return (
                <TextBox
                    tooltipContent={this.props.tooltipContent}
                    tooltipPosition={this.props.tooltipPosition}
                    tooltipTextAlign={this.props.tooltipTextAlign}
                    className={this.props.className}
                    style={this.props.style}
                    label={this.props.label}
                    id={this.props.id}
                    automationId={this.props.automationId}
                    disabled={this.props.disabled}
                    name={this.props.name}
                    tabIndex={this.props.tabIndex}
                    value={this.getDisplayValue()}
                    isReadOnly
                />
            );
        }
        return (
            <SelectBox
                {...this.props}
                onChange={this.selectBoxChangeHandler}
                valueOnClear={valueOnClear}
                value={this.props.value ? true : null}
                items={dummyItems}
                onKeyDown={this.keyDown}
                onRenderMenu={this.renderMenu}
                menuIsOpen={this.menuIsOpen}
                onMenuOpen={this.openMenu}
                onMenuClose={this.closeMenu}
                getOptionText={this.getDisplayValue}
                inputValue={this.textValue}
                onInputChange={this.setTextValue}
                isOptionSelected={this.dummyOptionIsSelected}
                dropdownIconName="none"
                ref={this.props.forwardedRef}
            >
                <Icon
                    iconName={"clock"}
                    stopClickPropagation
                    size="compact"
                    visualStyle="secondary"
                    onClick={this.props.monthOnlyMode ? this.setCurrentMonth : this.setCurrentDateTime}
                    style={{ marginRight: 8, marginLeft: 4 }}
                    automationId="_nowIcon"
                />
            </SelectBox>
        );
    }

    private renderMenu = State.observer((menuProps: any) => {
        const props = {
            ...menuProps,
            innerProps: {
                ...menuProps.innerProps,
            }
        };
        if (this.props.visualMode === "dark") {
            props.className += " __dark-mode";
        }
        return (
            <components.Menu {...props}>
                <div className={this.pickingTime ? undefined : Styles.calendarSelectBoxMenuContainer}>
                    {this.pickingTime ? this.renderTimePicker() : this.renderCalendar()}
                </div>
            </components.Menu>
        );
    });

    @State.action.bound
    private selectMonth(value: LocalDate) {
        this.props.onChange(value.toUtcDayStartMoment());
        this.closeMenu();
    }

    private renderCalendar() {
        return (
            <Calendar
                value={this.dateValue}
                onChange={this.setDateValue}
                visualMode={this.props.visualMode}
                onSelectedMonthChange={this.props.monthOnlyMode && this.selectMonth}
                monthOnlyMode={this.props.monthOnlyMode}
            />
        );
    }

    private renderTimePicker() {
        return (
            <TimeEditor
                {...this.props.timePickerOptions}
                value={this.timeValue}
                onChange={this.setTimeValue}
                onBack={this.backToCalendar}
            />
        );
    }
}


export default connect(
    DateTimePickerCore,
    new DependencyAdapter<IDateTimePickerProps, IDateTimePickerDependencies>(c => ({
        dateTimeFormatProvider: c.resolve("IDateTimeFormatProvider"),
    })),
    new ReadOnlyContextAdapter<IDateTimePickerCoreProps>((props, isReadOnly) => ({ isReadOnly: props.isReadOnly || isReadOnly })),
    new ValidationBoundaryAdapter<IDateTimePickerCoreProps>((props, validationStore) => ({ validationContext: validationStore }))
);

export {
    Calendar,
    TimeEditor,
    DatePicker,
};
