import React from "react";
import CountryId from "@Primitives/CountryId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SettlementApiAdapter from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/SettlementApiAdapter";
import * as Ui from "@CommonControls";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import _ from "@HisPlatform/Common/Lodash";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import StaticWebAppResources from "@StaticResources";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { isNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface ISettlementAndZipCodeSelectorDependencies {
    settlementApiAdapter: SettlementApiAdapter;
    notificationService: INotificationService;
}

interface ISettlementAndZipCodeSelectorProps {
    _dependencies?: ISettlementAndZipCodeSelectorDependencies;
    countryId: CountryId;
    settlement: string;
    zipCode: string;
    setSettlement: (newValue: string) => void;
    setZipCode: (newValue: string) => void;
    propertyIdentifierPrefix?: string;
    automationIdPrefix: string;
}

@State.observer
class SettlementAndZipCodeSelector extends React.Component<ISettlementAndZipCodeSelectorProps> {
    private readonly quickSearchDebounceTimeout = 500;
    private readonly quickSearchMinimumCharacters = 1;
    private readonly quickSearchMaxCount = 5;

    @State.observable.ref private zipCodes: string[] = [];
    @State.observable.ref private settlements: string[] = [];

    public componentDidMount() {
        this.initData();
    }

    public componentDidUpdate(prevProps: ISettlementAndZipCodeSelectorProps) {
        if (prevProps.countryId?.value !== this.props.countryId?.value) {
            this.resetFields();
        } else if (prevProps.zipCode !== this.props.zipCode || prevProps.settlement !== this.props.settlement) {
            this.initData();
        }
    }

    @State.action.bound
    private initData() {
        if (this.props.settlement) {
            this.settlements = [this.props.settlement];
        }
        if (this.props.zipCode) {
            this.zipCodes = [this.props.zipCode];
        }
    }

    @State.action.bound
    private resetFields() {
        this.zipCodes = [];
        this.settlements = [];
        this.props.setSettlement("");
        this.props.setZipCode("");
    }

    private get apiAdapter() {
        return this.props._dependencies.settlementApiAdapter;
    }

    @State.computed
    private get zipCodeItems(): Array<ISelectBoxItem<string>> {
        return this.zipCodes.map(item => ({
            text: item,
            value: item
        } as ISelectBoxItem<string>));
    }

    @State.computed
    private get settlementItems(): Array<ISelectBoxItem<string>> {
        return this.settlements.map(item => ({
            text: item,
            value: item
        } as ISelectBoxItem<string>));
    }

    private doZipCodeSearchDebounced = _.debounce(() => {
        if (this.props.countryId !== null && this.props.zipCode && this.props.zipCode.length >= this.quickSearchMinimumCharacters) {
            this.fireAndForget(this.searchZipCodesAsync());
        }
    }, this.quickSearchDebounceTimeout);

    private async searchZipCodesAsync() {
        const response = await this.apiAdapter.searchZipCodesBySettlementAsync(
            this.props.zipCode,
            "",
            this.props.countryId,
            this.quickSearchMaxCount,
            LocalDate.today());
        this.setZipSearchResult(response.value);
    }

    @State.action.bound
    private setZipSearchResult(items: string[]) {
        this.zipCodes = items;
    }

    private doSettlementSearchDebounced = _.debounce(() => {
        if (this.props.countryId !== null && this.props.settlement && this.props.settlement.length >= this.quickSearchMinimumCharacters) {
            this.fireAndForget(this.searchSettlementsAsync());
        }
    }, this.quickSearchDebounceTimeout);

    private async searchSettlementsAsync() {
        const response = await this.apiAdapter.searchSettlementNamesByZipCodeAsync(
            this.props.settlement,
            "",
            this.props.countryId,
            this.quickSearchMaxCount,
            LocalDate.today());
        this.setSettlementSearchResult(response.value);
    }

    @State.action.bound
    private setSettlementSearchResult(items: string[]) {
        this.settlements = items;
    }

    @State.action.bound
    private setZipCode(newValue: string) {
        this.props.setZipCode(newValue);
        if (newValue === "" || newValue === null || newValue === undefined) {
            this.setZipSearchResult([]);
        } else {
            this.fireAndForget(this.trySelectSettlementIfOneExistAsync(newValue));
        }
    }

    @State.action.bound
    private setSettlement(newValue: string) {
        this.props.setSettlement(newValue);
        if (newValue === "" || newValue === null || newValue === undefined) {
            this.setSettlementSearchResult([]);
        } else {
            this.fireAndForget(this.trySelectZipCodeIfOneExistAsync(newValue));
        }
    }

    @State.bound
    private async trySelectSettlementIfOneExistAsync(zipCode: string) {
        const items = await this.apiAdapter.searchSettlementNamesByZipCodeAsync("", zipCode, this.props.countryId, this.quickSearchMaxCount, LocalDate.today());
        this.setSettlementSearchResult(items.value);
        if (items.value.length === 1) {
            this.props.setSettlement(items.value[0]);
        }
    }

    @State.bound
    private async trySelectZipCodeIfOneExistAsync(settlement: string) {
        const items = await this.apiAdapter.searchZipCodesBySettlementAsync("", settlement, this.props.countryId, this.quickSearchMaxCount, LocalDate.today());
        this.setZipSearchResult(items.value);
        if (items.value.length === 1) {
            this.props.setZipCode(items.value[0]);
        }
    }

    private get zipCodePropertyIdentifier() {
        return this.props.propertyIdentifierPrefix ? `${this.props.propertyIdentifierPrefix}.ZipCode` : "ZipCode";
    }

    private get settlementPropertyIdentifier() {
        return this.props.propertyIdentifierPrefix ? `${this.props.propertyIdentifierPrefix}.Settlement` : "Settlement";
    }

    private get zipCodeAutomationId() {
        return this.props.automationIdPrefix ? `__${this.props.automationIdPrefix}.zipCode` : "__zipCode";
    }

    private get settlementAutomationId() {
        return this.props.automationIdPrefix ? `__${this.props.automationIdPrefix}.settlement` : "__settlement";
    }

    private fireAndForget(promise: Promise<any>) {
        promise.catch(() => this.props._dependencies.notificationService.error(StaticWebAppResources.Common.ToastMessage.SearchError));
    }

    public render() {
        return (
            <Ui.Flex verticalSpacing={"none"}>
                <Ui.Flex.Item sm={6}>
                    <Ui.AutoCompleteField
                        value={this.props.zipCode}
                        onSearchForSuggestions={this.doZipCodeSearchDebounced}
                        suggestions={this.zipCodeItems}
                        onChange={this.setZipCode}
                        propertyIdentifier={this.zipCodePropertyIdentifier}
                        label={StaticWebAppResources.Common.Address.ZipCode}
                        automationId={this.zipCodeAutomationId}
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item sm={6}>
                    <Ui.AutoCompleteField
                        value={this.props.settlement}
                        onChange={this.setSettlement}
                        onSearchForSuggestions={this.doSettlementSearchDebounced}
                        suggestions={this.settlementItems}
                        propertyIdentifier={this.settlementPropertyIdentifier}
                        label={StaticWebAppResources.Common.Address.Settlement}
                        automationId={this.settlementAutomationId}
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        );

    }
}

export default connect(
    SettlementAndZipCodeSelector,
    new DependencyAdapter<ISettlementAndZipCodeSelectorProps, ISettlementAndZipCodeSelectorDependencies>((container) => {
        return {
            settlementApiAdapter: container.resolve("SettlementApiAdapter"),
            notificationService: container.resolve("INotificationService")
        };
    })
);
