import React from "react";
import * as Ui from "@CommonControls";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import PractitionerAddressStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerAddressStore";
import PractitionerTelecomStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerTelecomStore";
import GenderRadioButtonGroup from "@HisPlatform/BoundedContexts/Care/Components/Controls/PatientRegister/GenderRadioButtonGroup";
import PractitionerIdentifierStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerIdentifierStore";
import PractitionerAddressView from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserPanel/PractitionerAddressView";
import PractitionerTelecomView from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserPanel/PractitionerTelecomView";
import PractitionerIdentifierPanel from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserPanel/PractitionerIdentifierPanel";
import JobPositionSelectBox from "@HisPlatform/BoundedContexts/UserManagement/Components/Controls/Users/JobPositionSelectBox";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import PractitionerProfessionsView from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserPanel/PractitionerProfessionView";
import PractitionerProfessionalExamStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerProfessionalExamStore";

const renderAddress = (item: PractitionerAddressStore, index: number) => <PractitionerAddressView address={item} index={index}/>;
const renderTelecom = (item: PractitionerTelecomStore, index: number) => <PractitionerTelecomView telecomStore={item} index={index}/>;
const renderIdentifiers = (item: PractitionerIdentifierStore, index: number) => <PractitionerIdentifierPanel identifier={item} index={index}/>;
const renderProfessions = (item: PractitionerProfessionalExamStore, index: number) => <PractitionerProfessionsView profession={item} index={index}/>;

interface IPractitionerBaseDataViewProps {
    practitioner: PractitionerStore;
    onDeleteAddressConfirmationAsync: (address: PractitionerAddressStore) => Promise<boolean>;
    createAddressAsync: () => Promise<PractitionerAddressStore>;
    onDeleteTelecomConfirmationAsync: (address: PractitionerTelecomStore) => Promise<boolean>;
    createPhoneAsync: () => Promise<PractitionerTelecomStore>;
    createEmailAsync: () => Promise<PractitionerTelecomStore>;
    onDeleteIdentifierConfirmationAsync: (identifier: PractitionerIdentifierStore) => Promise<boolean>;
    createIdentifierAsync: () => Promise<PractitionerIdentifierStore>;
    onDeleteProfessionConfirmationAsync: (address: PractitionerProfessionalExamStore) => Promise<boolean>;
    createProfessionAsync: () => Promise<PractitionerProfessionalExamStore>;
    onValidatePractitionerAsync?: () => Promise<IClientValidationResult[]>;
}


const PractitionerBaseDataView: React.FC<IPractitionerBaseDataViewProps> = props => {
    if (!props.practitioner) {
        return (<></>);
    }
    return (
        <ValidationBoundary
            entityTypeName="Practitioner" 
            validateOnMount>
            <Ui.GroupBox title={StaticUserManagementResources.UserPanel.Title.PractitionerBaseData}>
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <Ui.TextBox
                            label={StaticUserManagementResources.UserPanel.Label.NamePrefix}
                            value={props.practitioner.baseData.name.prefix}
                            onChange={props.practitioner.baseData.name.setNamePrefix}
                            propertyIdentifier={"BaseData.Name.Prefix"}
                            automationId="namePrefix"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <Ui.TextBox
                            label={StaticUserManagementResources.UserPanel.Label.FamilyName}
                            value={props.practitioner.baseData.name.familyName}
                            onChange={props.practitioner.baseData.name.setFamilyName}
                            propertyIdentifier={"BaseData.Name.FamilyName"}
                            automationId="familyName"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <Ui.TextBox
                            label={StaticUserManagementResources.UserPanel.Label.GivenName}
                            value={props.practitioner.baseData.name.givenName1}
                            onChange={props.practitioner.baseData.name.setGivenName1}
                            propertyIdentifier={"BaseData.Name.GivenName"}
                            automationId="givenName"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <JobPositionSelectBox
                            label={StaticUserManagementResources.UserPanel.Label.Assignment}
                            value={props.practitioner.jobPositionId}
                            onChange={props.practitioner.setJobPosition}
                            propertyIdentifier="JobPositionId"
                            automationId="jobPosition"/>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <GenderRadioButtonGroup
                            label={StaticUserManagementResources.UserPanel.Label.Gender}
                            value={props.practitioner.baseData.genderId}
                            onChange={props.practitioner.baseData.setGenderId}
                            propertyIdentifier="BaseData.GenderId"
                            automationId="genderId"/>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <Ui.TextBox
                            label={StaticUserManagementResources.UserPanel.Label.MothersName}
                            value={props.practitioner.baseData.mothersName}
                            onChange={props.practitioner.baseData.setMothersName}
                            propertyIdentifier={"BaseData.MothersName"}
                            automationId="mothersName"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>
            <ListPanel<PractitionerIdentifierStore>
                title={StaticUserManagementResources.UserPanel.Title.IdentifierAssignments}
                alwaysEdit
                items={props.practitioner.identifiers}
                renderItemEditor={renderIdentifiers}
                onDeleteItemConfirmationAsync={props.onDeleteIdentifierConfirmationAsync}
                onRemoveItem={props.practitioner.removeIdentifier}
                onCreateNewAsync={props.createIdentifierAsync}
                noItemsMessage={StaticUserManagementResources.UserPanel.Message.NoIdentifierAssignmentsMessage}
                propertyIdentifier="Identifiers"
                isCompactEmptyState
                automationId="identifierAssignments"
            />
            <ListPanel<PractitionerProfessionalExamStore>
                title={StaticUserManagementResources.UserPanel.Title.Professions}
                alwaysEdit
                items={props.practitioner.professionalExams}
                renderItemEditor={renderProfessions}
                onDeleteItemConfirmationAsync={props.onDeleteProfessionConfirmationAsync}
                onRemoveItem={props.practitioner.removeProfession}
                onCreateNewAsync={props.createProfessionAsync}
                noItemsMessage={StaticUserManagementResources.UserPanel.Message.NoProfessionsMessage}
                propertyIdentifier="Professions"
                isCompactEmptyState
                automationId="professions"
            />
            <ListPanel<PractitionerAddressStore>
                title={StaticUserManagementResources.UserPanel.Title.Addresses}
                alwaysEdit
                items={props.practitioner.addresses}
                renderItemEditor={renderAddress}
                onDeleteItemConfirmationAsync={props.onDeleteAddressConfirmationAsync}
                onRemoveItem={props.practitioner.removeAddress}
                onCreateNewAsync={props.createAddressAsync}
                noItemsMessage={StaticUserManagementResources.UserPanel.Message.NoAddressesMessage}
                propertyIdentifier="Addresses"
                isCompactEmptyState
                automationId="addresses"
            />
            <ListPanel<PractitionerTelecomStore>
                title={StaticUserManagementResources.UserPanel.Title.Phones}
                alwaysEdit
                items={props.practitioner.telecoms}
                filter={PractitionerTelecomStore.isPhone}
                renderItemEditor={renderTelecom}
                onDeleteItemConfirmationAsync={props.onDeleteTelecomConfirmationAsync}
                onCreateNewAsync={props.createPhoneAsync}
                noItemsMessage={StaticUserManagementResources.UserPanel.Message.NoPhoneNumbersMessage}
                propertyIdentifier="Telecoms"
                isCompactEmptyState
                automationId="phones"
            />
            <ListPanel<PractitionerTelecomStore>
                title={StaticUserManagementResources.UserPanel.Title.EmailAddresses}
                alwaysEdit
                items={props.practitioner.telecoms}
                filter={PractitionerTelecomStore.isEmail}
                renderItemEditor={renderTelecom}
                onDeleteItemConfirmationAsync={props.onDeleteTelecomConfirmationAsync}
                onCreateNewAsync={props.createEmailAsync}
                noItemsMessage={StaticUserManagementResources.UserPanel.Message.NoEmailAddressesMessage}
                propertyIdentifier="Telecoms"
                isCompactEmptyState
                automationId="emailAddresses"
            />
        </ValidationBoundary>
    );
};

export default State.observer(PractitionerBaseDataView);
