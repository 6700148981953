import { IScreenEventArgs } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";

class _GenericScreenCancelledEventHandlers {
    @State.bound
    public default(e: IScreenEventArgs<() => void>) {
        e.continue();
        e.close?.();
    }
}

class _GenericScreenSavedEventHandlers {
    @State.bound
    public default(e: IScreenEventArgs<() => void>) {
        e.continue();
    }

    @State.bound
    public autoClose(e: IScreenEventArgs<() => void>) {
        e.continue();
        e.close?.();
    }
}

class _GenericScreenSavedNewEventHandlers {
    @State.bound
    public default(newId: StringEntityId, e: IScreenEventArgs<(newId: StringEntityId) => void>, showCreatedEntityScreenAction?: ShowScreenFrontendActionBase) {
        e.continue(newId);

        if (showCreatedEntityScreenAction) {
            e.screenNavigationContext.showPrimary(showCreatedEntityScreenAction);
        }
        else {
            e.close?.();
        }
    }
}

class _GenericScreenDeletedEventHandlers {
    @State.bound
    public default(e: IScreenEventArgs<() => void>) {
        e.continue();
        e.close?.();
    }
}

const GenericScreenCancelledEventHandlers = new _GenericScreenCancelledEventHandlers();
const GenericScreenSavedEventHandlers = new _GenericScreenSavedEventHandlers();
const GenericScreenSavedNewEventHandlers = new _GenericScreenSavedNewEventHandlers();
const GenericScreenDeletedEventHandlers = new _GenericScreenDeletedEventHandlers();

export {
    GenericScreenCancelledEventHandlers,
    GenericScreenSavedEventHandlers,
    GenericScreenSavedNewEventHandlers,
    GenericScreenDeletedEventHandlers
};