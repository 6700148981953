import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class SingleAccordionStore {

    constructor(isOpenByDefault: boolean) {
        this.isOpen = isOpenByDefault;
    }

    @State.observable public isOpen = false;

    @State.action.bound
    public open() {
        this.isOpen = true;
    }

    @State.action.bound
    public close() {
        this.isOpen = false;
    }

    @State.action.bound
    public paneClickHandler() {
        this.isOpen = !this.isOpen;
    }

}