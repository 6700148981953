import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import CareActivityId from "@Primitives/CareActivityId.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";

@Di.injectable()
export default class CareActivityOperationApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("ICareRegisterClient") private readonly apiClient: Proxy.ICareRegisterClient,
    ) {
        super();
    }

    public callAsync(id: CareActivityId): Promise<SimpleStore> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.apiClient.callPatientCommandAsync(
                    CreateRequestId(),
                    new Proxy.CallPatientControllerDto({
                        careActivityId: id
                    })
                );
                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}
