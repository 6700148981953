import IEntityVersionSelector from "./IEntityVersionSelector";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import ITemporalEntityStore from "./ITemporalEntityStore";
import { isInInterval } from "./TemporalUtils";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import StoreBase from "@Toolkit/CommonWeb/Model/StoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Log from "@Log";
import EntityVersionSelector from "./EntityVersionSelector";
import _ from "@HisPlatform/Common/Lodash";
import AsyncMessageQueue from "@Toolkit/CommonWeb/AsyncMessageQueue";
import TemporalReferenceDataCollector from "./TemporalReferenceDataCollector";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

export default class TemporalReferenceDataStore<TId extends IStringEntityId, TStore extends ITemporalEntityStore<TId>> extends StoreBase {
    @State.observable private map = new Map<string, TStore[]>();
    private messageQueue = new AsyncMessageQueue<Array<IEntityVersionSelector<TId>>>(this._ensureLoadedAsync);

    private collector = new TemporalReferenceDataCollector<TId>();

    @State.computed public get items() {
        return Array.from(this.map.values());
    }

    @State.action.bound
    public async ensureLoadedAsync(versionSelectors: Array<IEntityVersionSelector<TId>>) {
        const notNulls = versionSelectors?.filter(Boolean);
        if (notNulls?.length > 0) {
            return await this.messageQueue.enqueueAndProcessAsync(notNulls);
        }
    }

    @State.action.bound
    private async _ensureLoadedAsync(versionSelectors: Array<IEntityVersionSelector<TId>>) {
        let toLoad = versionSelectors.filter(selector => {
            if (!selector || !selector.id) {
                return false; // do not load empty selector
            }

            const versions = this.map.get(selector.id.value);

            if (!versions) {
                return true; // no versions at all
            }

            const version = versions.some((v) => isInInterval(v.validity, selector.validOn));

            if (!version) {
                return true; // no specific version
            }

            return false; // requested version selector is already cached
        });

        toLoad = _.uniqBy(toLoad, vs => EntityVersionSelector.getHashCode(vs)); 

        if (toLoad.length === 0) {
            // all keys are already loaded
            return;
        }

        const newItemsStore = await this.loadAsync(toLoad);

        this.updateOperationInfoFromStore(newItemsStore);

        if (!this.operationWasSuccessful) {
            return;
        }

        State.runInAction(() => {
            newItemsStore.value.forEach((item) => {

                const versions = this.map.get(item.id.value);
                if (versions) {
                    versions.push(item);
                } else {
                    this.map.set(item.id.value, [item]);
                }
            });
        });
    }

    public get(versionSelector: IEntityVersionSelector<TId>, fallbackToLatest: boolean = false): TStore {
        if (isNullOrUndefined(versionSelector)) {
            return null;
        }
        
        const versions = this.map.get(versionSelector.id.value);
        if (!versions) {
            return null;
        }

        const today = LocalDate.today();
        const version = versions.find((v) => isInInterval(v.validity, versionSelector.validOn || today));

        if (!!version || !fallbackToLatest) {
            return version;
        }

        return versions.slice(-1)[0]; // latest version
    }

    public getAll(versionSelector: Array<IEntityVersionSelector<TId>>): TStore[] {
        if (isNullOrUndefined(versionSelector)) {
            return [];
        }
        
        return versionSelector.map(selector => this.get(selector)).filter(selector => !!selector);
    }

    public async getOrLoadAsync(versionSelector: IEntityVersionSelector<TId>, fallbackToLatest: boolean = false): Promise<TStore> {
        await this.ensureLoadedAsync([versionSelector]);
        return this.get(versionSelector, fallbackToLatest);
    }

    @State.bound
    public async ensureAllLoadedAsync(validOn?: LocalDate) {
        const trueValidOn = validOn || LocalDate.createFromMoment(DateTimeService.now());

        if (!this.loadAllIdsAsync) {
            Log.error("TemporalReferenceDataStore.ensureAllLoadedAsync() can only be called if loadAllIdsAsync() is available.");
        }

        const allIds = await this.loadAllIdsAsync();
        const versionSelectors = allIds.value.map(id => new EntityVersionSelector(id, trueValidOn));
        await this.ensureLoadedAsync(versionSelectors);
    }

    @State.bound
    public getValidItems(validOn?: LocalDate) {
        const trueValidOn = validOn || LocalDate.createFromMoment(DateTimeService.now());
        const items: TStore[] = [];

        this.map.forEach((versions: TStore[], id: string) => {
            const validVersion = versions.find((v) => isInInterval(v.validity, trueValidOn)); 
            if (validVersion) {
                items.push({id, ...validVersion});
            }
        });

        return items;
    }

    @State.bound
    public collectVersionSelector(id: IEntityVersionSelector<TId>) {
        this.collector.collect(id);
    }

    @State.bound
    public async loadCollectedAsync() {
        await this.ensureLoadedAsync(this.collector.getCollectedVersionSelectors());
    }

    constructor(
        private readonly loadAsync: (versionSelectors: Array<IEntityVersionSelector<TId>>) => Promise<SimpleStore<TStore[]>>,
        private readonly loadAllIdsAsync?: () => Promise<SimpleStore<TId[]>>
    ) {
        super();
    }
}