import IPermissionDefinition from "@PluginInterface/OperationAccessControl/IPermissionDefinition";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import PermissionId from "@Primitives/PermissionId.g";
import Permission from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/Permission";
import PermissionScope from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/PermissionScope";

export default class PermissionDefinitionsStore {
    @State.observable.ref public definitions: IPermissionDefinition[] = [];

    @State.bound
    public addDefinition(definition: IPermissionDefinition) {
        this.definitions.push(definition);
    }

    @State.bound
    public addDefinitions(definitions: IPermissionDefinition[]) {
        this.definitions.push(...definitions);
    }

    @State.action.bound
    public setDefinitions(definitions: IPermissionDefinition[]) {
        this.definitions = definitions;
    }

    @State.bound
    public getPermissionsWithUnitedScopes(): IPermissionDefinition[] {
        const res = this.definitions.reduce((aggregate, currentDefinition) => {
            currentDefinition.ids.forEach(id => {
                const defWithResolvedScopes = this.resolvePermissionScopeValues(id, currentDefinition);
                if (!aggregate.has(id.value)) {
                    aggregate.set(id.value, defWithResolvedScopes);
                } else {
                    const definition = aggregate.get(id.value);
                    const merged = this.mergePermissions(definition, defWithResolvedScopes);
                    aggregate.set(id.value, merged);
                }
            });

            return aggregate;
        }, new Map<string, IPermissionDefinition>());

        return Array.from(res.values());
    }

    @State.bound
    private mergePermissions(first: IPermissionDefinition, second: IPermissionDefinition): IPermissionDefinition {
        const clone = _.cloneDeep(first);
        clone.union(second);
        return clone;
    }

    @State.action.bound
    private resolvePermissionScopeValues(id: PermissionId, permission: IPermissionDefinition) {
        const aliasedScopeTypes = permission.scopeTypesForIdsMap.get(id.value) ?? [];
        const aliasedScopes = permission.scopes.filter(s => aliasedScopeTypes.includes(s.type));
        const resolved = new Permission([id], aliasedScopes as PermissionScope[]);
        return resolved;
    }
}