import Di from "@Di";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import WellKnownReferenceCodes from "@HunEHealthInfrastructurePlugin/Common/WellKnownReferenceCodes";
import PatientDocumentTypeId from "@Primitives/PatientDocumentTypeId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import CompositeArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/CompositeArrayFormFieldData";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import ReferencedExtensibleEnumFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedExtensibleEnumFormFieldData";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
class PatientAdministrativeDataHunEHealthInfrastructureFormLogic implements IFormLogic {

    constructor(
        @Di.inject("CareReferenceDataStore") private readonly careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("FinanceReferenceDataStore") private readonly financeReferenceDataStore: FinanceReferenceDataStore
    ) { }

    public async executeAsync(form: IForm, showScreenAction?: ShowScreenFrontendActionBase, dataElements?: FormDataElementBase[]): Promise<void> {
        
        const formData = form.data.Content;
        await this.financeReferenceDataStore.insurerOrganizationMap.ensureAllLoadedAsync();

        const documents = getField<CompositeArrayFormFieldData>(formData, "Documents");

        const insurerOrganization = this.financeReferenceDataStore.insurerOrganizationMap.items.find(i => i.code === WellKnownReferenceCodes.HunSocialSecurityInsuranceCode);

        for (let i = 0; i < documents.value.length; i++) {

            const documentTypeId = getField<ReferencedExtensibleEnumFormFieldData>(formData, `Main.Documents[${i}].DocumentTypeId`);
            if (!isNullOrUndefined(documentTypeId) && !isNullOrUndefined(documentTypeId.value)) {
                const documentType = await this.careReferenceDataStore.patientDocumentType.getOrLoadAsync(new PatientDocumentTypeId(documentTypeId.value.toString()));

                const insurerOrganizationId = getField<ReferencedEntityFormFieldData>(formData, `Main.Documents[${i}].InsurerOrganizationId`);

                if ((documentType.identifierSystemId.value === WellKnownReferenceCodes.DocumentIdentifierSystems.HunSocialSecurityNumber ||
                    documentType.identifierSystemId.value === WellKnownReferenceCodes.DocumentIdentifierSystems.NewbornHunSocialSecurityNumber ||
                    documentType.identifierSystemId.value === WellKnownReferenceCodes.DocumentIdentifierSystems.ReplacementHunSocialSecurityNumber) &&
                    isNullOrUndefined(insurerOrganizationId.value)) {
                    State.runInAction(() => {
                        insurerOrganizationId.value = parseInt(insurerOrganization.id.value);
                    });
                }
            }
        }
    }
}

export default PatientAdministrativeDataHunEHealthInfrastructureFormLogic;