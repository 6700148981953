import React from "react";
import Styles from "./SidebarTab.less";

interface ISidebarTabProps {
    title: string;
    name: string;
    onClick?: () => void;
    isSelected?: boolean;
    automationid?: string;
}

export default class SidebarTab extends React.Component<ISidebarTabProps, undefined> {

    private getContainerStyle() {
        if (this.props.isSelected) {
            return Styles.sidebarTabSelected;
        } else {
            return Styles.sidebarTab;
        }
    }

    private getTextStyle() {
        if (this.props.isSelected) {
            return Styles.sidebarTabLabelSelected;
        } else {
            return Styles.sidebarTabLabel;
        }
    }
    
    public render() {
        return (
            <div className={this.getContainerStyle()} onClick={this.props.onClick} data-automation-id={this.props.automationid || undefined}>
                <div className={this.getTextStyle()}>{this.props.title}</div>
            </div>
        );
    }
}