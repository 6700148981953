import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { withHisErrorBoundary } from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { getUseCaseAsUrlParam, parseUseCaseStateFromUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import HunSocialSecurityUseCases from "@HunSocialSecurityPlugin/UseCases/HunSocialSecurityUseCases";
import PerformanceStatementCreateType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementCreateType.g";
import performanceStatementStandaloneRouteDefinitions from "@HunSocialSecurityPlugin/UseCases/PerformanceStatementUseCase/PerformanceStatementRoutes";
import { HisErrorBoundary } from "@HisPlatformControls";
import PageBoxContainer from "@CommonControls/PageBoxContainer";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import NeakPerformanceStatementWorklistPanel from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/NeakPerformanceStatementWorklistPanel/NeakPerformanceStatementWorklistPanel";

interface INeakPerformanceStatementPageDependencies {
    routingStore: GlobalRoutingStore;
    applicationContext: ApplicationContext;
}

interface INeakPerformanceStatementPageProps extends IRoutingFrameContentProps {
    _dependencies?: INeakPerformanceStatementPageDependencies;
}

@State.observer
class NeakPerformanceStatementPage extends React.Component<INeakPerformanceStatementPageProps> {

    private get routingController(): IRoutingController {
        return this.props.routingController;
    }

    public componentWillMount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(new UseCaseIdentifier("PerformanceStatement_NeakPerformanceStatement"));
    }

    public componentWillUnmount() {
        this.props._dependencies.applicationContext.setCurrentUseCase(null);
    }

    @State.computed
    private get selectedRowId(): string {
        if (this.routingController.currentRoute.parameters.selectedRowId) {
            return this.routingController.currentRoute.parameters.selectedRowId;
        }
        return null;
    }

    @State.computed
    private get useCaseState() {
        if (!this.props.routingController.currentRoute.parameters.useCase) {
            return null;
        }
        const useCaseState = this.props.routingController.currentRoute.parameters.useCase;

        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.bound
    private setUseCaseState(rowId: string, useCaseState: INDataUseCaseState) {

        if (useCaseState?.useCase.value === HunSocialSecurityUseCases.errorList) {
            this.props.routingController.push(performanceStatementStandaloneRouteDefinitions.errorList.makeRoute({
                selectedPerformanceStatementRowId: rowId
            }));
        } else {
            this.props.routingController.push(performanceStatementStandaloneRouteDefinitions.neakPerformanceStatement.makeRoute({
                ...this.props.routingController.currentRoute.parameters,
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments),
                selectedRowId: rowId
            }));
        }
    }

    @State.bound
    private navigateToPerformanceStatementCreation(performanceStatementCreateType: PerformanceStatementCreateType) {
        if (performanceStatementCreateType === PerformanceStatementCreateType.NeakNormal) {
            this.props.routingController.push(performanceStatementStandaloneRouteDefinitions.createNeakPerformanceStatement.makeRoute());
        } else if (performanceStatementCreateType === PerformanceStatementCreateType.NeakRevision) {
            this.props.routingController.push(performanceStatementStandaloneRouteDefinitions.createNeakRevisionPerformanceStatement.makeRoute());
        } else if (performanceStatementCreateType === PerformanceStatementCreateType.Eu) {
            this.props.routingController.push(performanceStatementStandaloneRouteDefinitions.createEuPerformanceStatement.makeRoute());
        }
    }

    public render() {
        return (
            <div>
                <PageBoxContainer>
                    <HisErrorBoundary>
                        <SingleLayout>
                            <NeakPerformanceStatementWorklistPanel
                                useCaseState={this.useCaseState}
                                onChange={this.setUseCaseState}
                                selectedRowId={this.selectedRowId}
                                onNavigateToPerformanceStatementCreation={this.navigateToPerformanceStatementCreation}
                            />
                        </SingleLayout>
                    </HisErrorBoundary>
                </PageBoxContainer>
            </div>
        );
    }
}

export default connect(
    withHisErrorBoundary(NeakPerformanceStatementPage),
    new DependencyAdapter<INeakPerformanceStatementPageProps, INeakPerformanceStatementPageDependencies>(container => {
        return {
            routingStore: container.resolve("GlobalRoutingStore"),
            applicationContext: container.resolve("ApplicationContext")
        };
    })
);
