import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import * as Ui from "@CommonControls";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { DoctorCodeSelector } from "@HisPlatformControls";
import AsserterTypeSelectBox from "@HisPlatform/BoundedContexts/Care/Components/Controls/MedicalCondition/AsserterTypeSelectBox";
import MedicalConditionHistoryItemSelector from "@HisPlatform/BoundedContexts/Care/Components/Controls/MedicalCondition/MedicalConditionHistoryItemVersionSelector/MedicalConditionHistoryItemSelector";
import MedicalConditionItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionItemBase";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";


interface IMedicalConditionHistoryItemDetailPanelDependencies {
}

interface IMedicalConditionHistoryItemDetailPanelProps {
    _dependencies?: IMedicalConditionHistoryItemDetailPanelDependencies;

    store: MedicalConditionItemBase<StringEntityId>;
    prefix: string;
}

@State.observer
class MedicalConditionHistoryItemDetailPanel extends React.Component<IMedicalConditionHistoryItemDetailPanelProps> {

    private get store() { return this.props.store; }
    private get detailStore() { return this.props.store.currentHistoryItem.historyItemDetail; }
    private get prefix() { return this.props.prefix; }

    private get labels() {
        return StaticCareResources.MedicalCondition.MedicalConditionHistoryItemDetail.Label;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IMedicalConditionHistoryItemDetailPanelProps) {
        if (prevProps.store !== this.props.store || prevProps.store.extensionData !== this.props.store.extensionData) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    @State.action
    
    private async loadAsync() { }

    @State.computed
    private get extensionProps() {
        return {
            store: this.store.currentHistoryItem
        };
    }

    @State.action.bound
    public renderSelfDeterminationExtension() {
        return (
            <HisPlatformExtensionPoint
                type="selfDeterminationExtension"
                extensionProps={this.extensionProps}>
                <></>
            </HisPlatformExtensionPoint>
        );
    }

    @State.action.bound
    public renderMedicalConditionVersionExtension() {
        return (
            <HisPlatformExtensionPoint
                type="medicalConditionVersionExtension"
                extensionProps={this.extensionProps}>
                <></>
            </HisPlatformExtensionPoint>
        );
    }

    public render() {
        if (!this.store && !this.detailStore) {
            return null;
        }

        const prefix = `${this.prefix}.HistoryItemDetail`;

        return (
            <>
                <Ui.Flex>
                    <Ui.Flex.Item sm={4}>
                        <DoctorCodeSelector
                            label={this.labels.Asserter}
                            value={this.detailStore.asserter}
                            onChange={this.detailStore.setAsserter}
                            propertyIdentifier={`${prefix}.Asserter`}
                            automationId="__asserter"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={4}>
                        <AsserterTypeSelectBox
                            label={this.labels.AsserterType}
                            value={this.detailStore.asserterType}
                            onChange={this.detailStore.setAsserterType}
                            propertyIdentifier={`${prefix}.AsserterType`}
                            automationId="__asserterType"
                            orderItems={false}
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={4}>
                        <DoctorCodeSelector
                            label={this.labels.Verifier}
                            value={this.detailStore.verifier}
                            onChange={this.detailStore.setVerifier}
                            propertyIdentifier={`${prefix}.Verifier`}
                            automationId="__verifier"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex >
                <Ui.Flex>
                    <Ui.Flex.Item sm={12}>
                        <Ui.TextBox
                            label={this.labels.Notes}
                            value={this.detailStore.notes}
                            onChange={this.detailStore.setNotes}
                            propertyIdentifier={`${prefix}.Notes`}
                            automationId="__notes"
                            multiline
                            multilineMinLineCount={4}
                        />
                    </Ui.Flex.Item>
                </Ui.Flex >
                <MedicalConditionHistoryItemSelector
                    renderSelfDeterminationExtension={this.renderSelfDeterminationExtension}
                    renderVersionExtension={this.renderMedicalConditionVersionExtension}
                    items={this.store.historyItems}
                    value={this.store.currentItemIndex}
                    onChange={this.store.setCurrentItemIndex}
                    propertyIdentifier="VersionNumber"
                    automationId="__versionNumber"
                />
            </>
        );
    }
}

export default connect(
    MedicalConditionHistoryItemDetailPanel,
    new DependencyAdapter<IMedicalConditionHistoryItemDetailPanelProps, IMedicalConditionHistoryItemDetailPanelDependencies>(c => ({
    }))
);
