import React from "react";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";

interface ILoginMenuDependencies {
    userContext: UserContext;
}

interface ILoginMenuProps {
    _dependencies?: ILoginMenuDependencies;
}

class LoginMenu extends React.Component<ILoginMenuProps> {

    private get userContext() { return this.props._dependencies.userContext; }

    @State.bound
    private async logoutAsync() {
        await this.userContext.logoutAsync();
    }

    public render() {
        return (
            <Ui.NavBar.Item hasNestedItems content={`${this.userContext.displayName} (${this.userContext.loginName})`} automationId="loginContext">
                {/* Removed until profile is implemented */}
                {/* <Ui.NavBar.Item onClick={this.navigateToFactory(ApplicationRoutes.profileManagement, {userId: this.userContext.id.value} as IProfileManagementRouteParams)}>{StaticUserManagementResources.ProfileManagementPage.NavbarMenuItem}</Ui.NavBar.Item> */}
                <Ui.NavBar.Item onClickAsync={this.logoutAsync} automationId="logout">{StaticWebAppResources.Navbar.Logout}</Ui.NavBar.Item>
            </Ui.NavBar.Item>
        );
    }

}

export default connect(
    LoginMenu,
    new DependencyAdapter<ILoginMenuProps, ILoginMenuDependencies>(c => ({
        userContext: c.resolve("UserContext"),
    }))
);
