import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React from "react";
import ShowReadOnlyPerformedServicesScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyPerformedServicesScreenAction.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ScreenRenderer from "@HisPlatform/Components/ShowScreenAction/ScreenRenderer";
import IPatientCareActivitiesTabProps from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabProps";

function WrappedReadOnlyPerformedServiceScreen(props: IPatientCareActivitiesTabProps) {

    const action = new ShowReadOnlyPerformedServicesScreenAction(
        ScreenDisplayMode.ContentOnly,
        props.careActivityId
    );

    return (
        <ScreenRenderer
            action={action}
            screenState={null}
            onClose={null}
        />
    );
}

export default connect<IPatientCareActivitiesTabProps>(
    WrappedReadOnlyPerformedServiceScreen
);