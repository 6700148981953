exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StateStatisticsWidget_text_2baSl {\n  margin-left: 4px;\n}\n", ""]);

// exports
exports.locals = {
	"text": "StateStatisticsWidget_text_2baSl"
};