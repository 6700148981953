import React from "react";
import { contextMenu } from "react-contexify";

export default class MenuController {
    public static showOnEvent(menuId: string, event: MouseEvent | TouchEvent | React.MouseEvent | React.TouchEvent) {
        contextMenu.show({
            id: menuId,
            event
        });
    }

    public static closeAll() {
        contextMenu.hideAll();
    }
}