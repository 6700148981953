import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCases from "@Primitives/UseCases";
import PatientAllergyIntolerancePanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntolerancePanel";
import PatientAllergyIntoleranceId from "@Primitives/PatientAllergyIntoleranceId.g";

export default function configurePatientAllergyIntoleranceUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2({
        identifiers: [UseCases.patientAllergyIntoleranceDetail],
        component: PatientAllergyIntolerancePanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));
            const id = map.get("patientAllergyIntoleranceId") ?? new PatientAllergyIntoleranceId("new");
            return {
                id: id,
            };
        }
    });
}
