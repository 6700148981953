import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalConditionHistoryItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemBase";
import RiskDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/RiskDetail";

export default class RiskAssessmentHistoryItem extends MedicalConditionHistoryItemBase {    
    @State.observable.ref public riskDetail: RiskDetail;

    @State.action.bound
    public setRiskDetail(newValue: RiskDetail) {
        this.riskDetail = newValue;
    }
}