enum PerformanceReportGroupingType {
    Interval = 0,
    Institute = 1,
    Site = 2,
    Ambulance = 3,
    PointOfCare = 4,
    FinancingClass = 5,
    CareDoctor = 6,
    CareAssistant = 7,
    ReferralWorkplace = 8,
    Service = 9
}

export default PerformanceReportGroupingType;