import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import OrderTypeId from "@Primitives/OrderTypeId.g";

interface IOrderTypeColumnDependencies {
    referenceDataStore: MedicationRequestReferenceDataStore;
}

interface IOrderTypeColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IOrderTypeColumnDependencies;
}


const OrderTypeColumn: React.FC<IOrderTypeColumnProps> = (props: IOrderTypeColumnProps) => {

    const valueRenderer = (value: OrderTypeId) => {
        if (value) {
            const orderType = props._dependencies.referenceDataStore.orderTypes.get(value);
            return orderType?.id?.value ?? "";
        }
        return "";
    };

    return (
        <DataGridColumn
            onRenderCellValue={valueRenderer}
            {...props}
        />
    );
};

export default connect(
    OrderTypeColumn,
    new DependencyAdapter<IOrderTypeColumnProps, IOrderTypeColumnDependencies>(c => ({
        referenceDataStore: c.resolve("MedicationRequestReferenceDataStore")
    }))
);