

import React, { useContext } from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IExtensionComponentRegistry, { ExtensionComponent, IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ContainerContext from "@Toolkit/ReactClient/Components/DependencyInjection/ContainerContext";
import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";

interface IExtensionDependencies {
    extensionComponentRegistry: IExtensionComponentRegistry;
}

interface IExtensionProps<TExtProps, TExtensionPointType> {
    _dependencies?: IExtensionDependencies;
    _container?: IContainerService
    type: TExtensionPointType;
    extensionProps: TExtProps;
    children?: React.ReactNode;
}

@State.observer
class _ExtensionPoint<TExtProps, TExtensionPointType> extends React.Component<IExtensionProps<TExtProps, TExtensionPointType>> {

    @State.observable.ref private isLoading = true;
    @State.observable.ref private _extensionComponents: ExtensionComponent[] = null;
    @State.observable.ref private _extensionComponentData: Array<{ component: ExtensionComponent, service: IExtensionComponentService<TExtProps> }> = [];
    private reactionDisposer: () => void = null;
    private prevExtensionPointProps: TExtProps = null;

    public componentDidMount() {
        const { extensionComponentRegistry } = this.props._dependencies;
        const componentInfos = extensionComponentRegistry.get(this.props.type);

        for (const componentInfo of componentInfos) {
            const service = this.props._container.resolve(componentInfo.extensionComponentServiceName) as IExtensionComponentService<TExtProps>;
            this._extensionComponentData.push({ component: componentInfo.component, service: service });
        }
            
        this.registerReactions();
    }

    componentWillUnmount() {
        this.reactionDisposer?.();
    }

    @State.bound
    private registerReactions() {
        const settings = {
            fireImmediately: true
        };

        this.reactionDisposer = State.reaction(() => this.getShouldRerunIsVisibles(), (shouldRerunIsVisibles: boolean[]) => {
            if (shouldRerunIsVisibles.filter(i => i).length > 0) {
                dispatchAsyncErrors(this.findExtensionComponentToDisplayAsync(), this);
            }
            State.runInAction(() => this.isLoading = false);
        }, settings);
    }

    @State.bound
    private getShouldRerunIsVisibles(): boolean[] {
        const ret = this._extensionComponentData.map(i => i.service.shouldRerunIsVisible(this.props.extensionProps, this.prevExtensionPointProps));
        this.prevExtensionPointProps = this.props.extensionProps;

        return ret;
    }

    @State.action.bound
    private async findExtensionComponentToDisplayAsync() {
        const extensionComponents: ExtensionComponent[] = [];

        for (const componentData of this._extensionComponentData) {
            const isVisible = componentData.service.isVisibleAsync
                ? await componentData.service.isVisibleAsync(this.props.extensionProps)
                : componentData.service.isVisible(this.props.extensionProps);

            if (isVisible) {
                extensionComponents.push(componentData.component);
            }
        }

        State.runInAction(() => {
            this._extensionComponents = (extensionComponents.length === 0) ? null : extensionComponents;
        });
    }

    public render() {
        if (this.isLoading) {
            return null;
        }

        if (this._extensionComponents === null) {
            return (this.props.children as any) ?? null;
        }

        return this._extensionComponents.map((component, i) => {
            return React.createElement(component, { ...this.props.extensionProps, key: i });
        });
    }
}

function ExtensionPoint(props: IExtensionProps<unknown, unknown>) {

    const container = useContext(ContainerContext);

    return (
        <_ExtensionPoint {...props} _container={container} />
    );
}

export default connect(
    ExtensionPoint,
    new DependencyAdapter<IExtensionProps<unknown, unknown>, IExtensionDependencies>(c => ({
        extensionComponentRegistry: c.resolve("IExtensionComponentRegistry"),
    }))
);