import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import { IsEhiEReferralExtensionNeeded } from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/EhiEReferralExtension/EhiEReferralExtensionHelper";
import IServiceRequestClinicalQuestionExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestClinicalQuestionExtensionPointProps";

@Di.injectable()
export default class EhiEReferralClinicalQuestionExtensionComponentService implements IExtensionComponentService<IServiceRequestClinicalQuestionExtensionPointProps> {

    public isVisible(props: IServiceRequestClinicalQuestionExtensionPointProps) {
        return !!props.serviceRequest && IsEhiEReferralExtensionNeeded(props.requestingPointOfCareDynamicProperties, props.serviceRequest.direction);
    }

    public shouldRerunIsVisible(props: IServiceRequestClinicalQuestionExtensionPointProps, prevProps: IServiceRequestClinicalQuestionExtensionPointProps) {
        return true;
    }
}