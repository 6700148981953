import Di from "@Di";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import EhiTokenProvider from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Services/EhiTokenProvider/EhiTokenProvider";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import EhiOrganizationUnitProviderService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Organization/Services/Implementation/EhiOrganizationUnitProviderService";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import EhiCareService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareService";
import EhiCovidTestResultsApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/PatientRegister/ApiAdapter/EhiCovidTestResultsApiAdapter";

@Di.injectable()
export default class EhiCareServiceFactory {

    constructor(
        @Di.inject("EhiCareApiAdapter") private readonly ehiCareApiAdapter: EhiCareApiAdapter,
        @Di.inject("EhiCovidTestResultsApiAdapter") private readonly ehiCovidTestResultsApiAdapter: EhiCovidTestResultsApiAdapter,
        @Di.inject("EhiTokenProvider") private readonly ehiTokenProvider: EhiTokenProvider,
        @Di.inject("EhiOrganizationUnitProviderService") private readonly ehiOrganizationUnitProviderService: EhiOrganizationUnitProviderService,
        @Di.inject("IModalService") private readonly modalService: IModalService,
        @Di.inject("UserContext") private readonly userContext: UserContext
    ) { }

    public getEhiCareService() {
        return new EhiCareService(
            this.ehiCareApiAdapter,
            this.ehiCovidTestResultsApiAdapter,
            this.ehiTokenProvider,
            this.ehiOrganizationUnitProviderService,
            this.userContext,
            this.modalService
        );
    }
}
