import Permission from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/Permission";
import IPermissionDefinition from "@PluginInterface/OperationAccessControl/IPermissionDefinition";
import IPermissionScope from "@PluginInterface/OperationAccessControl/IPermissionScope";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class UniquePermissionConfigurationStore {
    @State.observable.ref public permission: IPermissionDefinition = null;
    @State.observable.ref public isPermissionProhibition: boolean = false;

    constructor() {
        this.permission = new Permission(null, []);
    }

    @State.computed
    public get pointOfCareTypeScopeValues() {
        if (!this.pointOfCareScope) return null;
        return (this.pointOfCareScope.includedValues ?? this.pointOfCareScope.excludedValues).map(v => new PointOfCareId(v));
    }

    @State.computed
    public get pointOfCareScope() {
        return this.permission?.scopes
            .find(s => s.type === PermissionScopeType.pointOfCare);
    }

    @State.action.bound
    public setPointOfCareScope(permissionScope: IPermissionScope) {
        if (permissionScope) {
            this.permission.scopes = [...this.permission.scopes.filter(s => s.type !== PermissionScopeType.pointOfCare), permissionScope];
        } else {
            this.permission.scopes = [...this.permission.scopes.filter(s => s.type !== PermissionScopeType.pointOfCare)];
        }
    }

    @State.action.bound
    public setIsPermissionProhibition(newValue: boolean) {
        this.isPermissionProhibition = newValue;
    }

    @State.action.bound
    public setPermission(permission: IPermissionDefinition) {
        if (permission) {
            this.permission = permission;
        } else {
            this.permission = new Permission(null, []);
            this.setPointOfCareScope(null);
        }
    }
}