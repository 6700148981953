
import React from "react";
import * as Ui from "@CommonControls";
import Styles from "./BMICalculator.less";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";

interface IInputViewProps {
    weight: number;
    height: number;
    computeIsEnabled: boolean;
    setWeight: (weight: number) => void;
    setHeight: (height: number) => void;
    showResult: () => void;
}


const InputView: React.FC<IInputViewProps> = props => {
    const resources = StaticProductivityResources.Widgets.BMICalculator;
    return (
        <>
            <Ui.Flex>
                <Ui.Flex.Item xs={12} lg={5}>
                    <Ui.NumberBox value={props.weight} onChange={props.setWeight} label={resources.Weight} visualMode="dark" automationId="weightNumberBox" />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={12} lg={5}>
                    <Ui.NumberBox
                        value={props.height}
                        onChange={props.setHeight}
                        label={resources.Height}
                        visualMode="dark"
                        automationId="heightNumberBox"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item className={Styles.buttonContainer} lg={2} xs={12}>
                    <Ui.Button
                        onClick={props.showResult}
                        disabled={!props.computeIsEnabled}
                        visualStyle="secondary"
                        iconName="angleRight"
                        visualMode="dark"
                        fluid
                        automationId="showResultButton"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>

            <Ui.Flex smJustify="center">
                <Ui.Flex.Item xs={12}>
                    {resources.Footer}
                </Ui.Flex.Item>
            </Ui.Flex>
        </>
    );
};

export default InputView;