import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EhiEReferralQueryScenarioCodeId from "@Primitives/EhiEReferralQueryScenarioCodeId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";

export default class EhiEReferralListQuerySettingsStore {

    @State.observable.ref public scenarioCode: EhiEReferralQueryScenarioCodeId = new EhiEReferralQueryScenarioCodeId("0");
    @State.observable.ref public interval: LocalDateRange = new LocalDateRange(LocalDate.today().plusMonths(-1), LocalDate.today());
    @State.observable.ref public institute: OrganizationUnitId = null;
    @State.observable.ref public breakGlass: boolean = false;
    
    @State.action.bound
    public setScenarioCode(scenarioCode: EhiEReferralQueryScenarioCodeId) {
        this.scenarioCode = scenarioCode;
    }

    @State.action.bound
    public setInterval(interval: LocalDateRange) {
        this.interval = interval;
    }

    @State.action.bound
    public setInstitute(institute: OrganizationUnitId) {
        this.institute = institute;
    }

    @State.action.bound
    public setBreakGlass(breakGlass: boolean) {
        this.breakGlass = breakGlass;
    }
}
