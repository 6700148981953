import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import * as Ui from "@CommonControls";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import DataGridDateColumn from "@CommonControls/DataGrid/Column/DataGridDateColumn";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import EhiEReferralListButtons from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiEReferralListPanel/Master/EhiEReferralListButtons";
import EhiEReferralListQuerySettingsPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiEReferralListPanel/QueryPanel/EhiEReferralListQuerySettingsPanel";
import EhiEReferralListQuerySettingsStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralListQuerySettingsStore";
import CareActivityEReferralStore from "@HunEHealthInfrastructurePlugin/Model/CareActivityEReferralStore";
import PersonNameColumn from "@HisPlatformControls/DataGridColumns/PersonNameColumn";
import EhiEReferralLocationColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralLocationColumn/EhiEReferralLocationColumn";
import EhiEReferralServiceColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralServiceColumn/EhiEReferralServiceColumn";
import NameStore from "@Primitives/NameStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiErrorSummary from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiErrorSummary";
import IEhiError from "@HunEHealthInfrastructurePlugin/Common/Model/IEhiError";
import { dataGridDefaultProps } from "@CommonControls/DataGrid/DataGrid";

interface IEhiEReferralListProps extends IHasMasterDetailState {
    dataSource: InMemoryDataGridDataSource;
    selectedEReferral: CareActivityEReferralStore;
    isLoading?: boolean;
    onBack(): void;
    showDetail?: (row: CareActivityEReferralStore) => void;
    querySettingsStore?: EhiEReferralListQuerySettingsStore;

    localizationService: ILocalizationService;
    ehiErrors: IEhiError[];
}

/** @screen */

const EhiEReferralList: React.FC<IEhiEReferralListProps> = (props) => {
    const resources = StaticHunEHealthInfrastructureCareResources.EReferralListPanel.List;
    const rowId = (row: CareActivityEReferralStore) => row.ehiEReferralId.value;
    const isDetailOpen = props._masterDetailState.isDetailOpen;

    const renderQuerySettingsPanel = () => {
        return (
            <>
                <EhiErrorSummary errors={props.ehiErrors} />
                <EhiEReferralListQuerySettingsPanel
                    store={props.querySettingsStore}
                    isCompactView={isDetailOpen}
                />

            </>
        );
    };

    const getNameValue = (name: NameStore) => !isNullOrUndefined(name) ? props.localizationService.localizePersonName(name) : "";
    const getIdentifierValue = (identifier: string) => !isNullOrUndefined(name) ? identifier : "";
    return (
        <Ui.DataGrid
            dataSource={props.dataSource}
            selectedRow={props.selectedEReferral}
            isSelectable
            rowHeight="40px"
            isLoading={props.isLoading}
            availablePageSizes={[...dataGridDefaultProps.availablePageSizes, 5]}
            rowId={rowId}
            rowComparer="rowId"
            hasBackButton
            backButtonText={StaticWebAppResources.Common.Button.Back}
            onBack={props.onBack}
            extraFilter={renderQuerySettingsPanel()}
            isExtraFilterVisible={true}
            fixedLayout
            fixedHeight="100%"
            hasFilterClearButton
            changeOnMount
            generateInitialFilterStore
            automationId="ehiReferralList_dataGrid"
        >
            <DataGridDateColumn
                header={resources.CreationDate}
                dataGetter={"createdAt"}
                id="creationDate"
                width="15%"
                isOrderable
                isFilterable
            />
            <EhiEReferralLocationColumn
                header={resources.ReferralLocation}
                isVisible={!isDetailOpen}
                id="referralLocation"
                width="30%"
                isOrderable
                isFilterable
            />
            <EhiEReferralServiceColumn
                header={resources.Service}
                id="service"
                width="20%"
                isOrderable
                isFilterable
            />
            <PersonNameColumn
                header={resources.Name}
                dataGetter={"name"}
                id="patientName"
                width="20%"
                isOrderable
                isFilterable
                clientSideOrderableValueGetter={getNameValue}
                clientSideFilterableValueGetter={getNameValue}
            />
            <DataGridColumn
                header={resources.Identifier}
                dataGetter={"patientIdentifierValue"}
                isVisible={!isDetailOpen}
                id="identifier"
                width="15%"
                isOrderable
                isFilterable
                clientSideOrderableValueGetter={getIdentifierValue}
                clientSideFilterableValueGetter={getIdentifierValue}
            />
            <DataGridColumn
                id="Buttons"
                width="45px"
                cellTextAlign="center"
            >
                <EhiEReferralListButtons
                    onShowDetail={props.showDetail}
                />
            </DataGridColumn>
        </Ui.DataGrid>
    );
};

export default State.observer(EhiEReferralList);
