import InsurancePlanSelectBox from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Components/Controls/InsurancePlanSelectBox";
import InsurancePlanId from "@Primitives/InsurancePlanId.g";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import { useFormEntityIdField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { getCompositeArrayFields, getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import { useFormLayoutContext } from "@Toolkit/FormEngine/Panels/FormLayoutContext";
import { ICustomBlockRegistryItem, IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import React from "react";
import { useEffect, useState } from "react";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";

interface IPatientInsurancePlanFormCustomBlockProps extends IFormCustomBlockComponentProps {
}

function _PatientInsurancePlanFormCustomBlock(props: IPatientInsurancePlanFormCustomBlockProps) {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext?.compositeDataReferences?.join(".");

    const fields = getCompositeArrayFields(props.form.data.Content, compositeFieldName);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        addFieldsForPatientInsurancePlan(props.form.data.Content, fields, compositeFieldName);
        setIsLoaded(true);
    }, []);

    return isLoaded && (
        <PatientInsurancePlanFormCustomBlockCore {...props} />
    );
}

const PatientInsurancePlanFormCustomBlockCore = State.observer((props: IPatientInsurancePlanFormCustomBlockProps) => {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext?.compositeDataReferences?.join(".");

    const [insurancePlanId, setInsurancePlanId] = useFormEntityIdField<InsurancePlanId>(props.form.data.Content, isNullOrEmptyString(compositeFieldName) ? "InsurancePlanId" : compositeFieldName + ".InsurancePlanId", InsurancePlanId);
    const [insurerOrganizationId, setInsurerOrganizationId] = useFormEntityIdField<InsurerOrganizationId>(props.form.data.Content, isNullOrEmptyString(compositeFieldName) ? "InsurerOrganizationId" : compositeFieldName + ".InsurerOrganizationId", InsurerOrganizationId);

    const resources = StaticCareResources.PatientRegister.InsuranceList;

    return (
        <InsurancePlanSelectBox
            value={insurancePlanId}
            onChange={setInsurancePlanId}
            insurerOrganizationId={insurerOrganizationId}
            propertyIdentifier="InsurancePlanId"
            label={resources.Label.InsurancePlan}
            automationId="__insurancePlan"
         />
    );
});

export function addFieldsForPatientInsurancePlan(formFields: FormFieldDataBase[], array: IObservableArray<FormFieldDataBase>, compositeFieldName: string) {
    State.runInAction(() => {
        const insurancePlanField = getField<TextFormFieldData>(formFields, isNullOrEmptyString(compositeFieldName) ? "InsurancePlanId" : compositeFieldName + ".InsurancePlanId");
        if (!insurancePlanField) {
            const newInsurancePlanField = new ReferencedEntityFormFieldData("InsurancePlanId", false, true, State.createObservableShallowArray([]), null);

            if (array) {
                array.push(newInsurancePlanField);
            } else {
                formFields.push(newInsurancePlanField);
            }
        }
    });
}

const PatientInsurancePlanFormCustomBlock = connect(_PatientInsurancePlanFormCustomBlock);

export const PatientInsurancePlanRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Patient insurance plan",
    dataElementsFactory: () => [
    ],
    componentType: PatientInsurancePlanFormCustomBlock
};

export default PatientInsurancePlanFormCustomBlock;
