import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import MedicationStatementId from "@Primitives/MedicationStatementId.g";
import MedicationStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/MedicationStatementStore";
import MedicationStatementStoreMapper from "./MedicationStatementStoreMapper";
import { getAddMedicationStatementControllerDto, getUpdateMedicationStatementControllerDto, getDeleteMedicationStatementControllerDto, getDeleteMedicationStatementHistoryItemControllerDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicationStatement/MedicationStatementDtoMapper";
import PatientId from "@Primitives/PatientId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";

@Di.injectable()
export default class MedicationStatementApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IMedicationStatementClient") private readonly apiClient: Proxy.IMedicationStatementClient,
        @Di.inject("MedicationStatementStoreMapper") private readonly storeMapper: MedicationStatementStoreMapper
    ) {
        super();
    }

    public getByIdAsync(medicationStatementId: MedicationStatementId, requestLock: boolean): Promise<MedicationStatementStore> {
        return this.processOperationAsync(
            new MedicationStatementStore(false),
            async target => {
                const response = await this.apiClient.getMedicationStatementByIdCommandAsync(
                    CreateRequestId(),
                    new Proxy.GetMedicationStatementByIdControllerDto({ medicationStatementId: medicationStatementId, requestLock: requestLock })
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public addMedicationStatementAsync(store: MedicationStatementStore, patientId: PatientId, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new MedicationStatementStore(false),
            async target => {
                const response = await this.apiClient.addMedicationStatementCommandAsync(
                    CreateRequestId(),
                    getAddMedicationStatementControllerDto(store, patientId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public updateMedicationStatementAsync(store: MedicationStatementStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new MedicationStatementStore(false),
            async target => {
                const response = await this.apiClient.updateMedicationStatementCommandAsync(
                    CreateRequestId(),
                    getUpdateMedicationStatementControllerDto(store, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deleteMedicationStatementHistoryItemAsync(store: MedicationStatementStore, versionNumber: number, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new MedicationStatementStore(false),
            async target => {
                const response = await this.apiClient.deleteMedicationStatementHistoryItemCommandAsync(
                    CreateRequestId(),
                    getDeleteMedicationStatementHistoryItemControllerDto(store, versionNumber, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deleteMedicationStatementAsync(store: MedicationStatementStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<any>(),
            async target => {
                const response = await this.apiClient.deleteMedicationStatementCommandAsync(
                    CreateRequestId(),
                    getDeleteMedicationStatementControllerDto(store),
                    isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public validateAsync(store: MedicationStatementStore, patientId: PatientId) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response =
                    store.isNew ?
                        await this.apiClient.addMedicationStatementCommandAsync(CreateRequestId(), getAddMedicationStatementControllerDto(store, patientId, true)) :
                        await this.apiClient.updateMedicationStatementCommandAsync(CreateRequestId(), getUpdateMedicationStatementControllerDto(store, true, store.lockInfo?.lockId, false));

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    public async checkPermissionForAddNewItemAsync(store: MedicationStatementStore, patientId: PatientId): Promise<MedicationStatementStore> {
        return await this.addMedicationStatementAsync(store, patientId, true);        
    }

    public async checkPermissionForUpdateItemAsync(store: MedicationStatementStore): Promise<MedicationStatementStore> {
        return await this.updateMedicationStatementAsync(store, true);
    }

    public async checkPermissionForDeleteItemAsync(store: MedicationStatementStore): Promise<SimpleStore> {
        return await this.deleteMedicationStatementAsync(store, true);
    }
}
