import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import OutpatientTreatmentRoutes from "@HisPlatform/Components/Pages/OutpatientTreatment/OutpatientTreatmentRoutes";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import configureServiceRequestManagementUseCases from "./ServiceRequestManagementUseCaseConfiguration";
import configureEHRUseCases from "./EHRUseCaseConfiguration";
import UseCases from "@Primitives/UseCases";
import configureAppointmentUseCases from "./AppointmentUseCaseConfiguration";
import DocumentListStandalonePage from "@HisPlatform/Components/Pages/OutpatientTreatment/StandaloneTreatmentPages/DocumentEditingPage/DocumentListStandalonePage";
import configureAppointmentScheduleDefinitionUseCases from "./AppointmentScheduleDefinitionUseCaseConfiguration";
import configureProcedureStatementUseCases from "./ProcedureStatementUseCaseConfiguration";
import configureAutonomyDisabilityStatementUseCases from "./AutonomyDisabilityStatementUseCaseConfiguration";
import configureConditionStatementUseCases from "./ConditionStatementUseCaseConfiguration";
import configureDeviceUseStatementUseCases from "./DeviceUseStatementUseCaseConfiguration";
import configurePregnancyStatementUseCases from "./PregnancyStatementUseCaseConfiguration";
import configureMedicationStatementUseCases from "./MedicationStatementUseCaseConfiguration";
import configureRiskAssessmentUseCases from "./RiskAssessmentUseCaseConfiguration";
import configurePatientAllergyIntoleranceUseCases from "./PatientAllergyIntoleranceUseCaseConfiguration";
import configureMedicalAlertStatementUseCases from "./MedicalAlertStatementUseCaseConfiguration";
import configureExternalLocationUseCases from "./ExternalLocationUseCaseConfiguration";
import configureSurgeryUseCases from "./SurgeryUseCaseConfiguration";
import configureFormUseCases from "./FormUseCaseConfiguration";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowCareActivityBaseDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityBaseDataScreenAction.g";
import ShowEditPatientAdmissionDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEditPatientAdmissionDataScreenAction.g";
import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import ShowReadOnlyCareActivityBaseDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyCareActivityBaseDataScreenAction.g";
import ShowReadOnlyDischargePatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyDischargePatientScreenAction.g";
import ShowDischargePatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowDischargePatientScreenAction.g";
import ShowCareActivityTextBlockListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityTextBlockListScreenAction.g";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";
import ShowCareActivityServiceRequestListScreenAction from "@HisPlatform/Packages/ServiceRequest/FrontendActions/ShowCareActivityServiceRequestListScreenAction.g";
import ShowPerformedServicesScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowPerformedServicesScreenAction.g";
import ShowPatientEpisodeOfCareListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowPatientEpisodeOfCareListScreenAction.g";
import ShowPatientRelatedDocumentListScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientRelatedDocumentListScreenAction.g";
import ShowNewPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowNewPatientScreenAction.g";
import ShowPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientScreenAction.g";
import ShowDiagnosisScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowDiagnosisScreenAction.g";
import ShowCareActivityDocumentListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityDocumentListScreenAction.g";
import ShowPatientInvoiceListScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientInvoiceListScreenAction.g";
import MasterDetailMode from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Care/Enum/MasterDetailMode.g";

export default function configureHisPlatformUseCases(useCaseRegistry: IUseCaseRegistry) {

    configureServiceRequestManagementUseCases(useCaseRegistry);
    configureEHRUseCases(useCaseRegistry);
    configureAppointmentUseCases(useCaseRegistry);
    configureAppointmentScheduleDefinitionUseCases(useCaseRegistry);
    configureProcedureStatementUseCases(useCaseRegistry);
    configureAutonomyDisabilityStatementUseCases(useCaseRegistry);
    configureConditionStatementUseCases(useCaseRegistry);
    configureDeviceUseStatementUseCases(useCaseRegistry);
    configurePregnancyStatementUseCases(useCaseRegistry);
    configureMedicationStatementUseCases(useCaseRegistry);
    configureRiskAssessmentUseCases(useCaseRegistry);
    configurePatientAllergyIntoleranceUseCases(useCaseRegistry);
    configureMedicalAlertStatementUseCases(useCaseRegistry);
    configureExternalLocationUseCases(useCaseRegistry);
    configureSurgeryUseCases(useCaseRegistry);
    configureFormUseCases(useCaseRegistry);


    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            UseCases.careRegisterDailyPatientList,
            builder => {
                builder.standalone(b => b.routeDefinition(ApplicationRoutes.home));
            }
        )
    );

    useCaseRegistry.addScreenAdapter(UseCases.careRegisterRecordCareBaseData, (identifier, args) => {
        return new ActionDescriptor(
            new ShowCareActivityBaseDataScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "careActivityId")?.value),
            null,
            [new FrontendActionPermissionScope("PointOfCare", [args.find(a => a.name === "pointOfCareId")?.value?.value])]
        );
    });

    useCaseRegistry.addScreenAdapter(UseCases.careRegisterViewReadOnlyCareBaseData, (identifier, args) => {
        return new ActionDescriptor(
            new ShowReadOnlyCareActivityBaseDataScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "careActivityId")?.value),
            null,
            [new FrontendActionPermissionScope("PointOfCare", [args.find(a => a.name === "pointOfCareId")?.value?.value])]
        );
    });

    useCaseRegistry.addScreenAdapter(UseCases.careRegisterCreateServiceRequests2, (identifier, args) => {
        return new ActionDescriptor(
            new ShowCareActivityServiceRequestListScreenAction(
                ScreenDisplayMode.Full,
                false,
                args.find(a => a.name === "careActivityId")?.value),
            null,
            [new FrontendActionPermissionScope("PointOfCare", [args.find(a => a.name === "pointOfCareId")?.value?.value])]
        );
    });

    useCaseRegistry.addScreenAdapter(UseCases.careRegisterDischargePatient, (identifier, args) => {
        return ActionDescriptor.fromAction(
            new ShowDischargePatientScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "careActivityId")?.value),
            new FrontendActionPermissionScope("PointOfCare", [args.find(a => a.name === "pointOfCareId")?.value?.value])
        );
    });

    useCaseRegistry.addScreenAdapter(UseCases.careRegisterViewReadOnlyDischargePatient, (identifier, args) => {
        return ActionDescriptor.fromAction(
            new ShowReadOnlyDischargePatientScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "careActivityId")?.value),
            new FrontendActionPermissionScope("PointOfCare", [args.find(a => a.name === "pointOfCareId")?.value?.value])
        );
    });

    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            UseCases.careRegisterEditDocumentChapter,
            builder => {
                builder.standalone(b => b
                    .routeDefinition(OutpatientTreatmentRoutes.documentList)
                    .component(DocumentListStandalonePage)
                );
                builder.hideFooter();
            }
        )
    );

    useCaseRegistry.addScreenAdapter(UseCases.careRegisterEditDocuments, (identifier, args) => {
        return new ActionDescriptor(
            new ShowCareActivityDocumentListScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "careActivityId")?.value,
                MasterDetailMode.Normal),
            null,
            [new FrontendActionPermissionScope("PointOfCare", [args.find(a => a.name === "pointOfCareId")?.value?.value])]
        );
    });

    useCaseRegistry.addScreenAdapter(UseCases.careRegisterEditTextBlocks, (identifier, args) => {
        return new ActionDescriptor(
            new ShowCareActivityTextBlockListScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "pointOfCareId")?.value,
                args.find(a => a.name === "careActivityId")?.value,
                args.find(a => a.name === "textBlockTypeId")?.value),
            null,
            [new FrontendActionPermissionScope("PointOfCare", [args.find(a => a.name === "pointOfCareId")?.value?.value])]
        );
    });

    // useCaseRegistry.add(
    //     UseCaseDescriptorBuilder.create(
    //         UseCases.careRegisterRecordDiagnosis,
    //         builder => {
    //             builder.standalone(b => b
    //                 .routeDefinition(OutpatientTreatmentRoutes.diagnosisList)
    //                 .component(DiagnosisListStandalonePage)
    //             );
    //         }
    //     )
    // );

    useCaseRegistry.addScreenAdapter(UseCases.careRegisterRecordDiagnosis, (identifier, args) => {
        return new ActionDescriptor(
            new ShowDiagnosisScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "careActivityId")?.value),
            null,
            [new FrontendActionPermissionScope("PointOfCare", [args.find(a => a.name === "pointOfCareId")?.value?.value])]
        );
    });

    useCaseRegistry.addScreenAdapter(UseCases.careRegisterRecordProvidedServices, (identifier, args) => {
        return new ActionDescriptor(
            new ShowPerformedServicesScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "careActivityId")?.value),
            null,
            [new FrontendActionPermissionScope("PointOfCare", [args.find(a => a.name === "pointOfCareId")?.value?.value])]
        );
    });

    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            UseCases.patientRegisterSearchPatient,
            builder => {
                builder.standalone(b => b.routeDefinition(ApplicationRoutes.searchPatient).additionalQueryParams({ restoreFilter: false }));
            }
        )
    );

    useCaseRegistry.addScreenAdapter(UseCases.patientRegisterAdmitPatient, (identifier, args) => {
        return new ActionDescriptor(
            new ShowNewAdmitPatientScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "patientId")?.value,
                args.find(a => a.name === "appointmentScheduleEntryId")?.value,
                args.find(a => a.name === "serviceRequestId")?.value),
            null,
            []
        );
    });

    useCaseRegistry.addScreenAdapter(UseCases.patientRegisterViewEpisodesOfCare, (identifier, args) => {
        return new ActionDescriptor(
            new ShowPatientEpisodeOfCareListScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "patientId")?.value),
            null,
            []
        );
    });

    useCaseRegistry.addScreenAdapter(UseCases.patientRegisterViewPatientRelatedDocuments, (identifier, args) => {
        return new ActionDescriptor(
            new ShowPatientRelatedDocumentListScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "patientId")?.value,
                false),
            null,
            []
        );
    });

    useCaseRegistry.addScreenAdapter(UseCases.patientRegisterNewPatient, (identifier, args) => {
        return new ActionDescriptor(
            new ShowNewPatientScreenAction(ScreenDisplayMode.Full),
            null,
            []
        );
    });

    useCaseRegistry.addScreenAdapter(UseCases.patientRegisterPatientData, (identifier, args) => {
        return new ActionDescriptor(
            new ShowPatientScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "patientId")?.value,
                args.find(a => a.name === "appointmentScheduleEntryId")?.value),
            null,
            []
        );
    });

    useCaseRegistry.add(
        UseCaseDescriptorBuilder.create(
            UseCases.careRegisterDailyPatientList,
            builder => {
                builder.standalone(b => b.routeDefinition(ApplicationRoutes.home));
            }
        )
    );

    useCaseRegistry.addScreenAdapter(UseCases.careRegisterEditAdmissionData, (identifier, args) => {
        return new ActionDescriptor(
            new ShowEditPatientAdmissionDataScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "careActivityId")?.value),
            null,
            [new FrontendActionPermissionScope("PointOfCare", [args.find(a => a.name === "pointOfCareId")?.value?.value])]
        );
    });

    useCaseRegistry.addScreenAdapter(UseCases.financeInvoiceList, (identifier, args) => {
        return new ActionDescriptor(
            new ShowPatientInvoiceListScreenAction(
                ScreenDisplayMode.Full,
                args.find(a => a.name === "patientId")?.value),
            null,
            []
        );
    });
}
