import React from "react";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DataGridFilterCell from "@CommonControls/DataGrid/Filter/DataGridFilterCell";
import Styles from "@CommonControls/DataGrid/DataGrid.less";
import moment from "moment";
import CheckBox from "@CommonControls/CheckBox";

interface IDataGridFilterRowProps {
    hasCheckCell: boolean;

    showCheckAll: boolean;
    checkAllValue: boolean;
    onCheckAllValueChanged: (value: boolean) => void;

    hasRowIndicator: boolean;
    columns: IDataGridColumnProps[];
    filterStore: any;
    onFilterChanged: () => void;

    hasRefreshButton?: boolean;
    onRefreshAsnyc?: () => Promise<void>;

    hasExtraFilterButton?: boolean;
    onExtraFilterVisibilityChanged?: (newValue: boolean) => void;
    isExtraFilterVisible?: boolean;

    hasFilterClearButton?: boolean;
    onClearFilter?: () => void;

    hasPrintButton?: boolean;
    onPrintAsync?: () => Promise<void>;
}


const DataGridFilterRow: React.FC<IDataGridFilterRowProps> = props => {

    return (
        <tr className={Styles.filterRow}>
            {props.hasRowIndicator && <th />}
            {props.hasCheckCell && (props.showCheckAll ? (
                <th>
                    <CheckBox
                        value={props.checkAllValue}
                        onChange={props.onCheckAllValueChanged}
                        verticalAlign="noPadding"
                        automationId={`dataGrid_checkAll_checkbox`}
                        canBeNull
                    />
                </th>
            ) : <th />)}
            {props.columns.map((column, index) => (
                <DataGridFilterCell
                    key={column.id}
                    column={column}
                    filterStore={props.filterStore}
                    onFilterChanged={props.onFilterChanged}
                    isLastColumn={index === props.columns.length - 1}

                    hasRefreshButton={props.hasRefreshButton}
                    onRefreshAsnyc={props.onRefreshAsnyc}
                    hasExtraFilterButton={props.hasExtraFilterButton}
                    onExtraFilterVisibilityChanged={props.onExtraFilterVisibilityChanged}
                    isExtraFilterVisible={props.isExtraFilterVisible}
                    hasFilterClearButton={props.hasFilterClearButton}
                    onClearFilter={props.onClearFilter}
                    hasPrintButton={props.hasPrintButton}
                    onPrintAsync={props.onPrintAsync}
                />
            ))}
        </tr>
    );
};

export default DataGridFilterRow;