import MedicationReferenceBase from "./MedicationReferenceBase";
import TextualPrescriptionTemplateId from "@Primitives/TextualPrescriptionTemplateId.g";
import Dosage from "./Dosage";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PrescriptionTemplateType from "./PrescriptionTemplateType";
import PackagingUnitId from "@Primitives/PackagingUnitId.g";

export default class TextualPrescription extends MedicationReferenceBase {
    public textualPrescriptionTemplateId: TextualPrescriptionTemplateId = null;
    @State.observable public amount: number = 0;
    @State.observable public content: string = "";
    @State.observable public preparation: string = "";
    @State.observable public displayName: string = "";
    public name: string = "";
    @State.observable.ref public dosage: Dosage = null;
    @State.observable public type: PrescriptionTemplateType = null;
    @State.observable.ref public packagingUnitId: PackagingUnitId = null;

    @State.action.bound
    public setAmount(amount: number) {
        this.amount = amount;
    }
    
    @State.action.bound
    public setContent(content: string) {
        this.content = content;
    }

    @State.action.bound
    public setPreparation(preparation: string) {
        this.preparation = preparation;
    }
    
    @State.action.bound
    public setDisplayName(displayName: string) {
        this.displayName = displayName;
    }
    
    @State.action.bound
    public setDosage(dosage: Dosage) {
        this.dosage = dosage;
    }
    
    @State.action.bound
    public setPackagingUnitId(packagingUnitId: PackagingUnitId) {
        this.packagingUnitId = packagingUnitId;
    }
}