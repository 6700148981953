import ServiceRequestManagementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestManagementApiAdapter";
import CreateServiceRequestCommand from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/UseCases/ServiceRequestManagement/CreateServiceRequestCommand";
import OutpatientTreatmentRoutes from "@HisPlatform/Components/Pages/OutpatientTreatment/OutpatientTreatmentRoutes";
import Di from "@Di";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";
import CommandProcessorBase from "@Toolkit/CommonWeb/CommandProcessing/Definition/CommandProcessorBase";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";

@Di.injectable()
export default class CreateServiceRequestCommandProcessor extends CommandProcessorBase<CreateServiceRequestCommand> {
    public get commandType(): string {
        return "CreateServiceRequestCommand";
    }

    constructor(
        @Di.inject("GlobalRoutingStore") private readonly _globalRoutingStore: GlobalRoutingStore,
        @Di.inject("ServiceRequestManagementApiAdapter") private readonly _serviceRequestManagementApiAdapter: ServiceRequestManagementApiAdapter,
        @Di.inject("INotificationService") private readonly _notificationService: INotificationService
    ) {
        super();
    }

    /** {@inheritdoc} */
    public async processAsync(command: CreateServiceRequestCommand): Promise<void> {
        const queryParameters = {
            startNewServiceRequest: true
        };

        if (!!command.serviceCode) {
            const serviceRequestId = await this.getServiceRequestIdAsync(command.serviceCode);
            if (!serviceRequestId) {
                return;
            }

            queryParameters["id"] = serviceRequestId.id.value;
            queryParameters["validity"] = serviceRequestId.validOn.toUnix();
            queryParameters["direction"] = ServiceRequestDirection.InternalToInternal;
        }

        this._globalRoutingStore.push(OutpatientTreatmentRoutes.servicesRequests.makeRoute(
            {
                careActivityId: command.careActivityId.value,
                filter: "CareActivity",
                mode: "edit"
            },
            queryParameters));
    }

    private async getServiceRequestIdAsync(serviceCode: string): Promise<EntityVersionSelector<ServiceRequestDefinitionId>> {
        if (!serviceCode) {
            return null;
        }
        
        const validOn = LocalDate.today();
        const store = await this._serviceRequestManagementApiAdapter.getActiveServiceRequestDefinitionsAsync(validOn);
        if (!store.operationWasSuccessful) {
            this._notificationService.error(StaticCareResources.ServiceRequestManagement.Messages.ResourceLoadFailed);
            return null;
        }

        const definitions = store.value.filter(
            item => item.code.value === serviceCode && ServiceRequestDirection.InternalToInternal in item.availableDirections);
        if (definitions.length === 0) {
            this._notificationService.error(StaticCareResources.ServiceRequestManagement.Messages.ServiceRequestDefinitionNotFound);
            return null;
        } else if (definitions.length > 1) {
            this._notificationService.error(StaticCareResources.ServiceRequestManagement.Messages.ServiceRequestCodeAmbiguous);
            return null;
        }

        return new EntityVersionSelector<ServiceRequestDefinitionId>(definitions[0].id, validOn);
    }
}