import moment from "moment";
import Log from "@Log";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import AutoLogoutDialogParams, { IAutoLogoutDialogResult } from "./AutoLogoutDialogParams";
import config from "@Config";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";

export default class ActiveSessionManager {
    private interaction = false;
    private lastInteractionAt: moment.Moment = moment();
    private isRunning = false;
    private lockRenewCounter = 0;

    constructor(
        private readonly modalService: IModalService,
        private readonly lockingApiAdapter: LockingApiAdapter,
        private readonly isAuthenticated: () => boolean,
        private readonly logoutAsync: () => Promise<void>,
        private readonly renewTokenAsync: () => Promise<boolean>,
        private readonly tokenWillBeExpiredIn: (secs: number) => boolean
    ) {
        window.addEventListener("mousemove", () => this.interaction = true);
        window.addEventListener("mousewheel", () => this.interaction = true);
        window.addEventListener("mouseup", () => this.interaction = true);
        window.addEventListener("keyup", () => this.interaction = true);
        window.document.addEventListener("visibilitychange", () => this.windowActivatedAsync().catch(e => Log.error(e)));
        window.setInterval(() => this.checkActiveSessionAsync().catch(e => Log.error(e)), 10000);
    }

    private async renewMyLocksAsync() {
        if (++this.lockRenewCounter >= 6) {
            await this.lockingApiAdapter.renewMyLocksAsync();
            this.lockRenewCounter = 0;
        }
    }

    private async windowActivatedAsync() {
        if (this.isRunning) {
            return;
        }

        try {
            this.isRunning = true;
            await this.handleTokenExpirationAsync();
            this.lastInteractionAt = moment();

        } catch (e) {
            Log.error(e);
        } finally {
            this.isRunning = false;
        }
    }

    private async checkActiveSessionAsync() {
        if (this.isRunning) {
            return;
        }

        try {
            this.isRunning = true;

            if (this.interaction) {
                this.lastInteractionAt = moment();
                this.interaction = false;
                await this.renewMyLocksAsync();
            }

            await this.handleTokenExpirationAsync();

        } catch (e) {
            Log.error(e);
        } finally {
            this.isRunning = false;
        }
    }

    private async handleTokenExpirationAsync() {
        if (this.isAuthenticated()) {

            if (this.tokenWillBeExpiredIn(0)) {
                await this.logoutAsync();
                return;
            }

            const durationSinceLastInteraction = moment.duration(moment().diff(this.lastInteractionAt));
            if (durationSinceLastInteraction.asSeconds() > config.loginSession.idleSecondsBeforeLogoutDialog) {
                const r = await this.modalService.showDialogAsync<IAutoLogoutDialogResult>(new AutoLogoutDialogParams(config.loginSession.logoutDialogSecondsBeforeLogout * 1000));
                if (r.doAutoLogout) {
                    await this.logoutAsync();
                }
                this.lastInteractionAt = moment();
            } else if (this.tokenWillBeExpiredIn(Math.max(20, config.loginSession.renewAccessTokenBeforeExpirationSeconds))) {
                await this.renewTokenAsync();
            }
        }
    }
}