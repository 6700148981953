import _ from "@HisPlatform/Common/Lodash";
import moment from "moment";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IDirtyChecked from "./IDirtyChecked";
import Log from "@Log";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";

export default class StoreDirtyChecker<TStore extends IDirtyChecked> {
    private _snapshot: TStore = null;

    /**
     * Has to be manually called on DomainStoreBase subtrees to be able to compare subtree only
     */
    public takeSnapshot(store: TStore) {
        this._snapshot = null;
        this._snapshot = _.cloneDeep(store);
    }

    private isValueDirty(value: any, newValue: any, key = ""): boolean {

        if (value === null) {
            if (newValue !== null) {
                return this.logAndReturn(true, key);
            }
            return false;
        }

        if (newValue === null) {
            if (value !== null) {
                return this.logAndReturn(true, key);
            }
            return false;
        }

        if (value === undefined) {
            if (newValue !== undefined) {
                return this.logAndReturn(true, key);
            }
            return false;
        }

        if (newValue === undefined) {
            if (value !== undefined) {
                return this.logAndReturn(true, key);
            }
            return false;
        }

        if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
            return this.logAndReturn(value !== newValue, key);
        }

        // IEquatable
        if ("equals" in value && typeof value.equals === "function") {
            return this.logAndReturn(!value.equals(newValue), key);
        }

        if (moment.isMoment(value)) {
            return this.logAndReturn(!(value as moment.Moment).isSame(newValue), key);
        }

        if (value instanceof ValueWrapper) {
            return this.logAndReturn(value.value !== newValue.value, key);
        }

        if (value instanceof LocalDate) {
            return this.logAndReturn(!value.equals(newValue), key);
        }

        // IDirtyChecked
        if ("isDirty" in value && typeof value.isDirty === "function") {
            return this.logAndReturn(value.isDirty(newValue), key);
        }

        if (_.isArray(value)) {
            if (value.length !== newValue.length) {
                return this.logAndReturn(true, key);
            }
            let arrayDirty = false;
            value.forEach((arrayValue, index) => {
                arrayDirty = arrayDirty || this.isValueDirty(arrayValue, newValue[index], key + `[${index}]`);
            });
            return this.logAndReturn(arrayDirty, key);
        }

        if (typeof(value) === "object") {
            return this.isDirty(newValue, value, key + ".");
        }

        return false;
    }

    private logAndReturn(returnValue: boolean, key: string) {
        if (Log.isDebugLogEnabled && returnValue === true) {
            Log.debug(`${"StoreDirtyChecker"}<${this._snapshot.constructor.name}> isDirty. Field: ${key}`);
        }
        return returnValue;
    }

    /**
     * Filters properties that are types that get compared in isValueDirty(), but should not be
     */
    private propertyShouldCheck(value: any, key: string) {
        return key !== "isLoading" && !(value instanceof LockId) && !_.isFunction(value);
    }

    public isDirty(store: TStore, snapshot: any = this._snapshot, path: string = ""): boolean {

        if (snapshot === null || snapshot === undefined) {
            throw Error(`No available snapshot for store: ${store ? store.constructor.name : "null"}. Manually call takeSnapshot() and isDirty() on subtree.`);
        }

        let dirty = false;

        _.forIn(store, (newValue, key) => {

            if (!store.propertyNamesExcludedFromDirtyCheck || !(store.propertyNamesExcludedFromDirtyCheck.indexOf(key) > -1)
                && this.propertyShouldCheck(newValue, key)) {
                const oldValue = snapshot[key];
                const valueDirty = this.isValueDirty(oldValue, newValue, path + key);
                dirty = dirty || valueDirty;
            }
        });

        return dirty;
    }
}