import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import Di from "@Di";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { mapToFormAsync } from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/FormMappers";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import CustomerId from "@Primitives/CustomerId.g";
import { mapFormFieldDataToProxy } from "@HisPlatform/BoundedContexts/FormEngine/ApplicationLogic/ApiAdapter/FormEngineMappers";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";

@Di.injectable()
export default class CustomerScreenApiAdapter extends ApiAdapterBase2 {
    constructor(
        @Di.inject("ISettingsClient") private readonly apiClient: Proxy.ISettingsClient,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("IFormEngineReferenceDataStore") private formEngineReferenceDataStore: IFormEngineReferenceDataStore) {
        super(referenceDataLoader);
    }

    @State.bound
    public getNewCustomerScreenDataAsync() {
        return this.executeOperationAsync<IForm, Proxy.GetNewEpisodeOfCareScreenDataQueryResponse>(
            () => this.apiClient.getNewCustomerScreenDataQueryAsync(CreateRequestId(), new Proxy.GetNewCustomerScreenDataControllerDto()),
            async response => {
                const form = await mapToFormAsync(this.formEngineReferenceDataStore, response.data, response.formDefinitionId, [] as any, null);
                return form;
            }
        );
    }

    @State.bound
    public getCustomerScreenDataAsync(customerId: CustomerId, requestLock: boolean) {
        return this.executeOperationAsync<IForm, Proxy.GetCustomerScreenDataCommandResponse>(
            () => this.apiClient.getCustomerScreenDataCommandAsync(CreateRequestId(), new Proxy.GetCustomerScreenDataControllerDto({
                customerId: customerId,
                requestLock: requestLock
            })),
            async response => {
                const form = await mapToFormAsync(this.formEngineReferenceDataStore, response.data, response.formDefinitionId, [] as any, null);
                return form;
            }
        );
    }

    @State.bound
    public createCustomerAsync(form: IForm, isValidateOnly: boolean = false) {
        return this.executeOperationAsync<IForm, Proxy.CreateCustomerCommandResponse>(
            () => {
                const mappedFormFieldData: Proxy.FormFieldData[] = [];
                mapFormFieldDataToProxy(form.data.Content, mappedFormFieldData as any);

                return this.apiClient.createCustomerCommandAsync(CreateRequestId(), new Proxy.CreateCustomerControllerDto({
                    data: mappedFormFieldData,
                    isValidateOnly: isValidateOnly,
                }));
            },
            async response => {
                const resultForm = await mapToFormAsync(this.formEngineReferenceDataStore, response.customer.data, form.definitionId, response.compositeValidationResult, null);
                return resultForm;
            }
        );
    }

    @State.bound
    public updateCustomerAsync(form: IForm, releaseLockIfSuccessful: boolean, lockInfo: LockInfo, isValidateOnly: boolean = false) {
        return this.executeOperationAsync<IForm, Proxy.UpdateCustomerCommandResponse>(
            () => {
                const mappedFormFieldData: Proxy.FormFieldData[] = [];
                mapFormFieldDataToProxy(form.data.Content, mappedFormFieldData as any);

                return this.apiClient.updateCustomerCommandAsync(CreateRequestId(), new Proxy.UpdateCustomerControllerDto({
                    data: mappedFormFieldData,
                    releaseLockIfSuccessful: releaseLockIfSuccessful,
                    lockId: lockInfo.lockId,
                    isValidateOnly: isValidateOnly
                }));
            },
            async response => {
                const resultForm = await mapToFormAsync(this.formEngineReferenceDataStore, response.customer.data, form.definitionId, response.compositeValidationResult, null);
                return resultForm;
            }
        );
    }

    public deleteCustomerAsync(customerId: CustomerId, form: IForm, lockInfo: LockInfo) {
        return this.executeOperationAsync<any, Proxy.DeleteCustomerCommandResponse>(
            () => {
                return this.apiClient.deleteCustomerCommandAsync(CreateRequestId(), new Proxy.DeleteCustomerControllerDto({
                    customerId: customerId,
                    releaseLockIfSuccessful: true,
                    lockId: lockInfo.lockId
                }));
            },
            i => {
                return;
            });
    }
}