import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import IEditableLayoutBlockStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/IEditableLayoutBlockStore";
import EditableDataElementEditorStore from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/EditableDataElementEditorStore";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import FormLogic from "@Toolkit/FormEngine/Model/FormLogic";
import FormFieldData from "./FormFieldData";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";

export interface IFormFieldDependencyDialogParams extends IModalParams {
    selectedEditor: EditableDataElementEditorStore;
    rootBlocks: IEditableLayoutBlockStore[];
    dataElements: FormDataElementBase[];
    targetFieldData: FormFieldData | null;
    targetFieldValues: string[];
    definitionId: FormDefinitionId;
}

export interface IFormFieldDependencyDialogResult {
    formLogic: FormLogic;
}

export default class FormFieldDependencyDialogParams implements IFormFieldDependencyDialogParams {
    public static type = "FormFieldDependencyDialog";
    public get type() { return FormFieldDependencyDialogParams.type; }

    constructor(
        public readonly selectedEditor: EditableDataElementEditorStore,
        public readonly rootBlocks: IEditableLayoutBlockStore[],
        public readonly dataElements: FormDataElementBase[],
        public readonly targetFieldData: FormFieldData | null,
        public readonly targetFieldValues: string[],
        public readonly definitionId: FormDefinitionId
    ) { }
}