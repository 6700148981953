import React from "react";
import * as Ui from "@CommonControls";
import EhiPrescriptionSearchMode from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/EhiPrescriptionSearchMode";

interface IEhiPrescriptionSearchModeSelectorProps {
    value: EhiPrescriptionSearchMode;
    onChange: (newValue: EhiPrescriptionSearchMode) => void;
    label: string;
    propertyIdentifier?: string;
}

export default class EhiPrescriptionSearchModeSelector extends React.Component<IEhiPrescriptionSearchModeSelectorProps> {
    public render() {
        return (
            <Ui.UniversalEnumSelector
                enumName={"EhiPrescriptionSearchMode"}
                enumOrigin={"server"}
                value={this.props.value}
                onChange={this.props.onChange}
                enumType={EhiPrescriptionSearchMode}
                displayMode={"selectBox"}
                label={this.props.label}
                clearable={false}
                automationId="ehiPrescriptionSearchModeSelector"
                propertyIdentifier={this.props.propertyIdentifier}
            />
        );
    }
}