import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import ServiceRequestFilter from "@Primitives/ServiceRequestFilter";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import { IPanelWithButtonPortalProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/IPanelWithButtonPortalProps";
import ServiceRequestMasterDetailPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestMasterDetailPanel/ServiceRequestMasterDetailPanel";
import { IStandaloneServiceRequestRouteParams } from "@HisPlatform/Components/Panels/ServiceRequestPanel/Routing/Standalone/StandaloneServiceRequestRouteParams";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { parseUseCaseStateFromUrlParam, getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

interface IServiceRequestPanelDependencies {
}

interface IServiceRequestPanelProps extends IRoutingFrameContentProps<IStandaloneServiceRequestRouteParams>, IPanelWithButtonPortalProps {
    _dependencies?: IServiceRequestPanelDependencies;
}

@State.observer
class ServiceRequestMainPanel extends React.Component<IServiceRequestPanelProps> {

    @State.computed private get selectedId() {
        const rawId = this.props.routingController.currentRoute.parameters.selectedId;
        return isNullOrUndefined(rawId) ? null : new ServiceRequestId(rawId);
    }

    @State.computed private get useCase() {
        return parseUseCaseStateFromUrlParam(this.props.routingController.currentRoute.parameters.useCase);
    }

    @State.computed private get serviceRequestFilter() {
        return ServiceRequestFilter[this.props.routingController.currentRoute.parameters.filter];
    }

    public render() {
        return (
            <SingleLayout>
                <LoadingBoundary>
                    <ServiceRequestMasterDetailPanel
                        selectedId={this.selectedId}
                        onCloseUseCase={this.clearUseCase}
                        useCase={this.useCase}
                        onChange={this.setUseCase}
                        mode={this.props.routingController.currentRoute.parameters.mode}
                        serviceRequestFilter={this.serviceRequestFilter}
                        onServiceRequestFilterChange={this.setServiceRequestFilter}
                        onBack={this.goBack}
                        buttons={this.props.buttons}
                    />
                </LoadingBoundary>
            </SingleLayout>
        );
    }

    @State.bound
    private setUseCase(id: ServiceRequestId, state: INDataUseCaseState) {
        this.props.routingController.replace(this.props.routingController.currentRoute.definition.makeRoute({
            ...this.props.routingController.currentRoute.parameters,
            selectedId: id.value,
            useCase: getUseCaseAsUrlParam(state.useCase, state.displayMode, state.useCaseArguments)
        }));
    }

    @State.bound
    private clearUseCase(_successfullySaved: boolean) {
        this.props.routingController.replace(this.props.routingController.currentRoute.definition.makeRoute({
            ...this.props.routingController.currentRoute.parameters,
            selectedId: null,
            useCase: null
        }));
    }

    @State.bound
    private setServiceRequestFilter(newValue: ServiceRequestFilter) {
        this.props.routingController.replace(this.props.routingController.currentRoute.definition.makeRoute({
            ...this.props.routingController.currentRoute.parameters,
            filter: ServiceRequestFilter[newValue] as any
        }));
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }
}

export default connect(
    ServiceRequestMainPanel,
    new DependencyAdapter<IServiceRequestPanelProps, IServiceRequestPanelDependencies>(() => ({
    }))
);
