import React from "react";
import Di from "@Di";
import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import ModalServiceRegistry from "@Toolkit/ReactClient/Components/ModalService/ModalServiceRegistry";
import EnumReferenceEditorDialogParams from "./Components/Panels/FormLayoutEditor/PropertyPanels/EnumReferenceEditorDialog/EnumReferenceEditorDialogParams";
import EnumReferenceEditorDialog from "./Components/Panels/FormLayoutEditor/PropertyPanels/EnumReferenceEditorDialog/EnumReferenceEditorDialog";
import FormDefinitionImportDialogParams from "./Components/Panels/FormDefinitionMasterDetailPanel/ImportDialog/FormDefinitionImportDialogParams";
import FormDefinitionImportDialog from "./Components/Panels/FormDefinitionMasterDetailPanel/ImportDialog/FormDefinitionImportDialog";
import FormLogicEditorDialog from "./Components/Panels/FormLayoutEditor/PropertyPanels/FormLogicEditorDialog/FormLogicEditorDialog";
import FormLogicEditorDialogParams from "./Components/Panels/FormLayoutEditor/PropertyPanels/FormLogicEditorDialog/FormLogicEditorDialogParams";
import FormLogicTokenEditorDialogParams from "./Components/Panels/FormLayoutEditor/PropertyPanels/FormLogicTokenEditorDialog/FormLogicTokenEditorDialogParams";
import FormLogicTokenEditorDialog from "./Components/Panels/FormLayoutEditor/PropertyPanels/FormLogicTokenEditorDialog/FormLogicTokenEditorDialog";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import { mapLocalizedLabelsToMultiLingualLabelMap } from "./ApplicationLogic/ApiAdapter/FormEngineMappers";
import MultiLingualColumn from "@HisPlatform/BoundedContexts/FormEngine/Components/MultiLingualColumn/MultiLingualColumn";
import * as FormEngineProxy from "@HisPlatform/BoundedContexts/FormEngine/Api/Proxy.g";

@Di.injectable()
export default class FormEditingBoundedContext implements IBoundedContext {
    public initializeAsync(): Promise<void> {
        return Promise.resolve();
    }

    public configureNData?(configurator: INDataAttributeConfigurator): void {
        configurator.registerValueConverter("LocalizedLabelsDto", (rawValue) => {
            const typedValue = rawValue.Values.map((element: any) => {
                return new FormEngineProxy.LocalizedLabel({ cultureCode: new FormEngineProxy.CultureCode({ value: element.CultureCode.Value }), label: element.Label });
            }) as FormEngineProxy.LocalizedLabel[];

            return mapLocalizedLabelsToMultiLingualLabelMap(typedValue);
        });

        configurator.registerColumn("LocalizedLabelsDto", <MultiLingualColumn />);
    }

    public configureModals(modalServiceRegistry: ModalServiceRegistry) {
        modalServiceRegistry.register(EnumReferenceEditorDialogParams.type, EnumReferenceEditorDialog);
        modalServiceRegistry.register(FormDefinitionImportDialogParams.type, FormDefinitionImportDialog);
        modalServiceRegistry.register(FormLogicEditorDialogParams.type, FormLogicEditorDialog);
        modalServiceRegistry.register(FormLogicTokenEditorDialogParams.type, FormLogicTokenEditorDialog);
    }
}