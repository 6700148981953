import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Reflection from "@Toolkit/CommonWeb/Reflection/Reflection";

export default function parseScreenStateFromUrl(url: string, isUriDecoded: boolean = false): { screenState: string, action: ShowScreenFrontendActionBase } {
    if (isNullOrEmptyString(url) || url === "null") {
        return { screenState: null, action: null };
    }

    const splitUrl = (isUriDecoded ? url : decodeURIComponent(url)).split(";");

    const typeName = splitUrl[1];
    const actionType = Reflection.getTypeInfo(typeName)?.constructor;
    if (!actionType) {
        throw new Error(`Action not found! Type: '${typeName}' `);
    }
    if (!("fromUrl" in actionType)) {
        throw new Error(`Type: '${typeName}' does not have a static fromUrl method!`);
    }

    return {
        screenState: splitUrl[0] ?? null,
        action: (actionType as any).fromUrl(splitUrl.slice(2)) as ShowScreenFrontendActionBase ?? null
    };
}