import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { getTokenLockInfo } from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Templating/TemplateStoreMappers";
import BinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/BinaryDocument";
import DocumentBase from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentBase";
import DocumentChange from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentChange";
import DocumentFile from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentFile";
import DocumentInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentInfo";
import ScopeIdentifier from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ScopeIdentifier";
import TemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TemplateBasedDocument";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import * as WebAppBackendProxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";

export function getDocumentInfo(dto: WebAppBackendProxy.DocumentInfoDto) {
    if (!dto) {
        return null;
    }
    
    const resp = new DocumentInfo(
        false,
        dto.id,
        dto.scopeIdentifiers?.map(si => new ScopeIdentifier(si.scope, si.identifier)),
        dto.documentTypeId ? new DocumentTypeId(dto.documentTypeId.value.toString()) : null,
        dto.documentKind,
        dto.createdById,
        dto.createdAt,
        dto.lastModifiedById,
        dto.lastModifiedAt,
        dto.documentState,
        dto.name,
        dto.possibleActions,
        dto.isPrimary,
        dto.metaProperties,
        dto.extensionData);

    resp.rowVersion = dto.rowVersion;
    return resp;
}

export function applyToDocumentWithLockInfo(target: DocumentBase, response: Proxy.GetCareActivityDocumentByIdCommandResponse): void {
    const document = response.document;
    applyToDocument(target, document);
    target.info.lockInfo = getLockInfo(response);
}

export function applyToDocument(target: DocumentBase, document: Proxy.DocumentDto) {
    target.info = getDocumentInfo(document.document as unknown as WebAppBackendProxy.DocumentInfoDto);
    target.largeDataToken = document.largeDataToken;
    target.downloadFileName = document.downloadFileName;
    if (target instanceof BinaryDocument) {
        target.content = new Blob([Base64Converter.toByteArray(document.content)], { type: document.contentType });
    } else if (target instanceof TemplateBasedDocument) {
        target.templateContentToken = document.templateContentToken;
        const documentFile = DocumentFile.create(document.content);
        target.documentFile = documentFile;
        target.dataChangedSinceLastRefresh = document.changes.map(it => getChangeInfo(it));
        target.tokenLockInfos = document.editableTemplateTokensUsed.map(it => getTokenLockInfo(it));
    }
}

function getLockInfo(response: any): LockInfo {
    if ("wasLockRequested" in response) {
        return new LockInfo(
            response.lockState as unknown as Proxy.EntityLockState,
            response.heldLockId,
            response.wasLockRequested,
            response.hasUpdatePermission,
            response.preventingLock?.owner?.name,
            response.preventingLock?.id,
            response.preventingLock?.owner?.userId
        );
    } else {
        return null;
    }
}

export function getChangeInfo(dto: Proxy.ChangedTemplateTokenDto) {
    return new DocumentChange(
        dto.tokenReference,
        dto.symbol,
        dto.nameResourceId,
        dto.isTwoWay
    );
}