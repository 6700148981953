import React from "react";
import { iconNameType } from "@CommonControls/Icon";

export interface IUseCaseFrameTabProps {
    tabName: string;
    isDisabled?: boolean;
    disablingTooltip?: string;
    propertyPathRegexPattern?: string;
    automationId?: string;

    tabTitle: string;
    title: string;
    subTitle?: string;
    iconName?: iconNameType;
    toolbar?: React.ReactNode;
}


const UseCaseFrameTab: React.FC<IUseCaseFrameTabProps> = () => {
    return null;
};

export default UseCaseFrameTab;