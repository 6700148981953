import React from "react";
import { ContextAwareModal } from "@HisPlatformControls";
import DocumentViewer, { IDocumentViewerProps } from "@CommonControls/DocumentViewer";
import { Modal } from "@CommonControls";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IModalProps } from "@CommonControls/Modal";

interface IDocumentViewerModalProps extends IModalProps, IDocumentViewerProps {
    labelsToDisplay?: Array<{ name: string; value: string; }>;
}

export default class DocumentViewerModal extends React.Component<IDocumentViewerModalProps> {

    public render() {
        return (
            <>
                <ContextAwareModal renderHeaderLabel={this.renderLabels} {...this.props}>
                    <Modal.Body>
                        <DocumentViewer {...this.props} style={{ height: "90%" }} />
                    </Modal.Body>
                </ContextAwareModal>
            </>
        );
    }

    @State.bound
    private renderLabels() {
        if (isNullOrUndefined(this.props.labelsToDisplay)) {
            return (<></>);
        }

        const labelList = this.props.labelsToDisplay.map(i => {
            return <Modal.Label key={i.name} label={i.name}>{i.value}</Modal.Label>;
        });

        return (
            <>
                {labelList}
            </>
        );
    }
}