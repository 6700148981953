exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CareActivityWidget_care-info-container_2Ih1T section {\n  margin: 0 0.4rem;\n  line-height: 1.5;\n}\n.CareActivityWidget_row_7pDz_ {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n}\n.CareActivityWidget_row_7pDz_ > .CareActivityWidget_info_8p3Fo {\n  flex: 1;\n}\n.CareActivityWidget_row_7pDz_ > .CareActivityWidget_info_8p3Fo > section {\n  flex: 1;\n  text-overflow: ellipsis;\n  min-width: 0;\n  white-space: nowrap;\n  overflow: hidden;\n  max-width: 200px;\n}\n.CareActivityWidget_row_7pDz_ > .CareActivityWidget_open-menu-button_38S7R {\n  flex: 0 0 auto;\n}\n.CareActivityWidget_status-badge_1Y8jW {\n  margin-right: 5px;\n  position: relative;\n  top: -1px;\n  left: -3px;\n  border: 1px solid #00607e;\n}\n", ""]);

// exports
exports.locals = {
	"care-info-container": "CareActivityWidget_care-info-container_2Ih1T",
	"careInfoContainer": "CareActivityWidget_care-info-container_2Ih1T",
	"row": "CareActivityWidget_row_7pDz_",
	"info": "CareActivityWidget_info_8p3Fo",
	"open-menu-button": "CareActivityWidget_open-menu-button_38S7R",
	"openMenuButton": "CareActivityWidget_open-menu-button_38S7R",
	"status-badge": "CareActivityWidget_status-badge_1Y8jW",
	"statusBadge": "CareActivityWidget_status-badge_1Y8jW"
};