import { History, Action, Location, createBrowserHistory, createHashHistory, createMemoryHistory } from "history";
import IHostRoutingAdapter from "./Abstractions/IHostRoutingAdapter";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { createManualPromise } from "@Toolkit/CommonWeb/Extensions/Async/ManualPromise";
import Log from "@Log";

export default class HostRoutingAdapter implements IHostRoutingAdapter {
    private _ignoreBlockerHooksOnce = false;
    private blockerHooks: Array<(e: { pathname: string }) => Promise<boolean>> = [];
    private navigationEvent: TypedEvent<{ pathname: string }> = new TypedEvent();
    private _currentPathname: string = null;
    private disableListenerOnce = false;
    private readonly disposeHistoryListener: () => void;

    public get currentPathname() { return this._currentPathname; }
    @State.observable.ref public currentQueryString: string = null;

    constructor(
        private readonly history: History
    ) {
        this._currentPathname = history.location.pathname;
        State.runInAction(() => {
            this.currentQueryString = history.location.search;
        });

        this.disposeHistoryListener = history.listen(async (location: Location, action: Action) => {
            try {
                State.runInAction(() => {
                    this.currentQueryString = location.search;
                });

                if (this.disableListenerOnce) {
                    this.disableListenerOnce = false;
                    return;
                }

                if (!this._ignoreBlockerHooksOnce) {
                    for (const hook of this.blockerHooks) {

                        const continueNavigation = await hook({ pathname: location.pathname });

                        if (continueNavigation === false) {
                            this.disableListenerOnce = true;
                            history.replace(this._currentPathname);
                            this.pushAsync.resolve(false);
                            return;
                        }
                    }
                }
                this._ignoreBlockerHooksOnce = false;

                this._currentPathname = location.pathname;
                State.transaction(() => {
                    this.navigationEvent.emit({ pathname: location.pathname });
                });
                this.pushAsync.resolve(true);
            } catch (err) {
                Log.error(err);
                this.pushAsync.resolve(false);
            }
        });
    }

    public static createBrowserHistoryRoutingAdapter() {
        return new HostRoutingAdapter(createBrowserHistory());
    }

    public static createHashHistoryRoutingAdapter() {
        return new HostRoutingAdapter(createHashHistory());
    }

    public static createMemoryHistoryRoutingAdapter() {
        return new HostRoutingAdapter(createMemoryHistory());
    }

    public ignoreBlockerHooksOnce() {
        this._ignoreBlockerHooksOnce = true;
    }

    public addBlockerHook(handler: (e: { pathname: string }) => Promise<boolean>): IDisposable {

        this.blockerHooks.unshift(handler);

        return {
            dispose: () => {
                const callbackIndex = this.blockerHooks.indexOf(handler);
                if (callbackIndex > -1) {
                    this.blockerHooks.splice(callbackIndex, 1);
                }
            }
        };
    }

    public addNavigationEventHandler(handler: (e: { pathname: string }) => void): IDisposable {
        return this.navigationEvent.on(handler);
    }

    public dispose() {
        this.disposeHistoryListener();
    }

    public push(pathname: string, queryString?: string): void {
        this.history.push({
            pathname,
            search: queryString
        });
    }

    public pushAsync = createManualPromise<(pathname: string, queryString?: string) => Promise<void>, boolean>((pathname: string, queryString?: string) => {
        this.push(pathname, queryString);
        return Promise.resolve();
    }) as any;

    public replace(pathname: string, queryString?: string): void {
        this.history.replace({
            pathname,
            search: queryString
        });
    }

    public goBack(): void {
        this.history.goBack();
    }

    public replaceQueryString(queryString: string) {
        this.disableListenerOnce = true;
        this.history.replace({
            search: queryString
        });
    }
}