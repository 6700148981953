import ResourceId from "@Primitives/ResourceId.g";

export default class DocumentChange {

    public tokenReference: string;
    public symbol: string;
    public nameResourceId: ResourceId;
    public isTwoWay: boolean;

    public constructor(tokenReference: string, symbol: string, nameResourceId: ResourceId, isTwoWay: boolean) {
        this.tokenReference = tokenReference;
        this.symbol = symbol;
        this.nameResourceId = nameResourceId;
        this.isTwoWay = isTwoWay;
    }
}