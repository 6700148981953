exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BadgeWithIcon_container_3CHnE {\n  display: inline;\n  padding: 0 8px;\n}\n.BadgeWithIcon_blue_1Xrfa {\n  background: #F3FBFE;\n  color: #00B4EC;\n}\n.BadgeWithIcon_red_1MPoV {\n  background: #F8E5EA;\n  color: #CE5374;\n}\n.BadgeWithIcon_gray_2xKgW {\n  background-color: rgba(79, 91, 114, 0.1);\n  color: #4F5B72;\n}\n.BadgeWithIcon_blue-fill_3J0xG {\n  fill: #00B4EC;\n}\n.BadgeWithIcon_red-fill_1CkXA {\n  fill: #CE5374;\n}\n.BadgeWithIcon_gray-fill_ERQq8 {\n  fill: #4F5B72;\n}\n", ""]);

// exports
exports.locals = {
	"container": "BadgeWithIcon_container_3CHnE",
	"blue": "BadgeWithIcon_blue_1Xrfa",
	"red": "BadgeWithIcon_red_1MPoV",
	"gray": "BadgeWithIcon_gray_2xKgW",
	"blue-fill": "BadgeWithIcon_blue-fill_3J0xG",
	"blueFill": "BadgeWithIcon_blue-fill_3J0xG",
	"red-fill": "BadgeWithIcon_red-fill_1CkXA",
	"redFill": "BadgeWithIcon_red-fill_1CkXA",
	"gray-fill": "BadgeWithIcon_gray-fill_ERQq8",
	"grayFill": "BadgeWithIcon_gray-fill_ERQq8"
};