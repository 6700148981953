import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import moment, { Moment } from "moment";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";

export function configureFormEngineDeserializer(mapperConfigurator: IMapperConfigurator) {
    mapperConfigurator.registerByName<string, string>("FormFieldDependency_TextBox_Deserializer", (source: string, _) => source);
    mapperConfigurator.registerByName<string, number>("FormFieldDependency_NumberBox_Deserializer", (source: string, _) => parseInt(source, 10));
    mapperConfigurator.registerByName<string, LocalDate>("FormFieldDependency_DatePicker_Deserializer", (source: string, _) => LocalDate.fromJS(JSON.parse(source)));
    mapperConfigurator.registerByName<string, TimeOfDay>("FormFieldDependency_TimePicker_Deserializer", (source: string, _) => TimeOfDay.createFromMinutes(parseInt(source, 10)));
    mapperConfigurator.registerByName<string, Moment>("FormFieldDependency_DateTimePicker_Deserializer", (source: string, _) => moment(source));
    mapperConfigurator.registerByName<string, number>("FormFieldDependency_EnumSelector_Deserializer", (source: string, _) => parseInt(source, 10));
    mapperConfigurator.registerByName<string, number>("FormFieldDependency_EntitySelector_Deserializer", (source: string, _) => parseInt(source, 10));
    mapperConfigurator.registerByName<string, string>("FormFieldDependency_ReferencedExtensibleEnumSelector_Deserializer", (source: string, _) => source);
    mapperConfigurator.registerByName<string, number>("FormFieldDependency_ReferencedEnumSelector_Deserializer", (source: string, _) => parseInt(source, 10));
}

export function configureFormEngineSerializer(mapperConfigurator: IMapperConfigurator) {
    mapperConfigurator.registerByName<string, string>("FormFieldDependency_TextBox_Serializer", (source: string, _) => source);
    mapperConfigurator.registerByName<number, string>("FormFieldDependency_NumberBox_Serializer", (source: number, _) => source.toString());
    mapperConfigurator.registerByName<LocalDate, any>("FormFieldDependency_DatePicker_Serializer", (source: LocalDate, _) => JSON.stringify(source.toJSON()));
    mapperConfigurator.registerByName<TimeOfDay, string>("FormFieldDependency_TimePicker_Serializer", (source: TimeOfDay, _) => source.valueInMinutes.toString());
    mapperConfigurator.registerByName<Moment, string>("FormFieldDependency_DateTimePicker_Serializer", (source: Moment, _) => source.utc().format("YYYY-MM-DDTHH:mm:ssZ"));
    mapperConfigurator.registerByName<number, string>("FormFieldDependency_EnumSelector_Serializer", (source: number, _) => source.toString());
    mapperConfigurator.registerByName<number, string>("FormFieldDependency_EntitySelector_Serializer", (source: number, _) => source.toString());
    mapperConfigurator.registerByName<string, string>("FormFieldDependency_ReferencedExtensibleEnumSelector_Serializer", (source: string, _) => source);
    mapperConfigurator.registerByName<number, string>("FormFieldDependency_ReferencedEnumSelector_Serializer", (source: number, _) => source.toString());
}